export const serviceWaterLineData = {
    "type": "FeatureCollection",
    "features": [
      {
        "id": 0,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570570539461,
            21.017135145334322,
            105.82616351553828,
            21.017362833082963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82570570539461,
              21.017362833082963
            ],
            [
              105.82593294723185,
              21.017253057433997
            ],
            [
              105.8261556516463,
              21.0171391676535
            ],
            [
              105.82616351553828,
              21.017135145334322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017362833082963,
          "to_latitude": 21.017135145334322,
          "to_longitude": 105.82616351553828,
          "from_longitude": 105.82570570539461
        }
      },
      {
        "id": 1,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82593294723185,
            21.017253057433997,
            105.82603058559233,
            21.017427997055837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593294723185,
              21.017253057433997
            ],
            [
              105.82593309185654,
              21.0172532924738
            ],
            [
              105.82599476491121,
              21.017353312528385
            ],
            [
              105.82603058559233,
              21.017427997055837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.2",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017253057433997,
          "to_latitude": 21.017427997055837,
          "to_longitude": 105.82603058559233,
          "from_longitude": 105.82593294723185
        }
      },
      {
        "id": 2,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82557016301354,
            21.016929720641315,
            105.82604679264881,
            21.017336832550928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82567263665462,
              21.017336832550928
            ],
            [
              105.82557016301354,
              21.01716013772159
            ],
            [
              105.82603296194911,
              21.016936562895427
            ],
            [
              105.82603862045507,
              21.016933829355203
            ],
            [
              105.82604679264881,
              21.016929720641315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.3",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017336832550928,
          "to_latitude": 21.016929720641315,
          "to_longitude": 105.82604679264881,
          "from_longitude": 105.82567263665462
        }
      },
      {
        "id": 3,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82551271912129,
            21.016820549618743,
            105.82598278280439,
            21.017041955684576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551271912129,
              21.017041955684576
            ],
            [
              105.82597973365127,
              21.01682198610075
            ],
            [
              105.82598278280439,
              21.016820549618743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.4",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017041955684576,
          "to_latitude": 21.016820549618743,
          "to_longitude": 105.82598278280439,
          "from_longitude": 105.82551271912129
        }
      },
      {
        "id": 4,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540220598149,
            21.016660167640534,
            105.8258900848261,
            21.017032622582942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547792510188,
              21.017032622582942
            ],
            [
              105.82540220598149,
              21.016896033820387
            ],
            [
              105.8258764920567,
              21.016666542661977
            ],
            [
              105.82587874949941,
              21.01666545054149
            ],
            [
              105.8258900848261,
              21.016660167640534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.5",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017032622582942,
          "to_latitude": 21.016660167640534,
          "to_longitude": 105.8258900848261,
          "from_longitude": 105.82547792510188
        }
      },
      {
        "id": 5,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82530899478049,
            21.01650319184694,
            105.82580330444411,
            21.016884752890807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82538705192373,
              21.016884752890807
            ],
            [
              105.82530899478049,
              21.016746646261762
            ],
            [
              105.8257830861057,
              21.016506125214917
            ],
            [
              105.82579794396908,
              21.01650397025465
            ],
            [
              105.82580330444411,
              21.01650319184694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.6",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016884752890807,
          "to_latitude": 21.01650319184694,
          "to_longitude": 105.82580330444411,
          "from_longitude": 105.82538705192373
        }
      },
      {
        "id": 6,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82522932639803,
            21.016470972923727,
            105.82563246093719,
            21.016673380070838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82522932639803,
              21.016673380070838
            ],
            [
              105.82563246093719,
              21.016470972923727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D68_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.7",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016673380070838,
          "to_latitude": 21.016470972923727,
          "to_longitude": 105.82563246093719,
          "from_longitude": 105.82522932639803
        }
      },
      {
        "id": 7,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82499665747136,
            21.015772709310884,
            105.8256600717752,
            21.016136231829357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82499665747136,
              21.016136231829357
            ],
            [
              105.82527018624292,
              21.015986349768898
            ],
            [
              105.8253326011474,
              21.01595214891146
            ],
            [
              105.8256600717752,
              21.015772709310884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D62_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.8",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016136231829357,
          "to_latitude": 21.015772709310884,
          "to_longitude": 105.8256600717752,
          "from_longitude": 105.82499665747136
        }
      },
      {
        "id": 8,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82494603130975,
            21.015625669488966,
            105.82518191067824,
            21.0159964551283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82518191067824,
              21.0159964551283
            ],
            [
              105.82517971500651,
              21.015993387583464
            ],
            [
              105.82516560331192,
              21.015975198216573
            ],
            [
              105.82508176716026,
              21.015841743997473
            ],
            [
              105.82506633040542,
              21.0158171689158
            ],
            [
              105.82494603130975,
              21.015625669488966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D61_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.9",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0159964551283,
          "to_latitude": 21.015625669488966,
          "to_longitude": 105.82494603130975,
          "from_longitude": 105.82518191067824
        }
      },
      {
        "id": 9,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82508176716026,
            21.015682973913034,
            105.82536333342749,
            21.015841743997473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82508176716026,
              21.015841743997473
            ],
            [
              105.82536333342749,
              21.015682973913034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D61_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.10",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015841743997473,
          "to_latitude": 21.015682973913034,
          "to_longitude": 105.82536333342749,
          "from_longitude": 105.82508176716026
        }
      },
      {
        "id": 10,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82527925650422,
            21.01543774852131,
            105.82552378651062,
            21.015782037126126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552378651062,
              21.015782037126126
            ],
            [
              105.82545568800953,
              21.01566860712358
            ],
            [
              105.82540953590562,
              21.01564024935671
            ],
            [
              105.82527925650422,
              21.01543774852131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D64_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.11",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015782037126126,
          "to_latitude": 21.01543774852131,
          "to_longitude": 105.82527925650422,
          "from_longitude": 105.82552378651062
        }
      },
      {
        "id": 11,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514493637315,
            21.01488585645634,
            105.82577088967655,
            21.01567846265462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82577088967655,
              21.01567846265462
            ],
            [
              105.82576322380447,
              21.01566532450419
            ],
            [
              105.82530850446365,
              21.01488585645634
            ],
            [
              105.82514493637315,
              21.01496000987323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D65_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.12",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 118.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01567846265462,
          "to_latitude": 21.01496000987323,
          "to_longitude": 105.82514493637315,
          "from_longitude": 105.82577088967655
        }
      },
      {
        "id": 12,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82460815978322,
            21.01564678916088,
            105.824913173037,
            21.016130735876313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82460815978322,
              21.01564678916088
            ],
            [
              105.824913173037,
              21.016130735876313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D54_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.13",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01564678916088,
          "to_latitude": 21.016130735876313,
          "to_longitude": 105.824913173037,
          "from_longitude": 105.82460815978322
        }
      },
      {
        "id": 13,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496109629187,
            21.016189203262716,
            105.82517514840707,
            21.016544285822473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82496109629187,
              21.016189203262716
            ],
            [
              105.82517514840707,
              21.016532226566984
            ],
            [
              105.82515744526033,
              21.016541229985727
            ],
            [
              105.82515144030128,
              21.016544285822473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D10_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.14",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016189203262716,
          "to_latitude": 21.016544285822473,
          "to_longitude": 105.82515144030128,
          "from_longitude": 105.82496109629187
        }
      },
      {
        "id": 14,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573743750802,
            21.0155309485269,
            105.8260726983158,
            21.015724106067825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8260726983158,
              21.0155309485269
            ],
            [
              105.82606474656181,
              21.0155348972024
            ],
            [
              105.82605886605741,
              21.015538881885185
            ],
            [
              105.82601994700914,
              21.01556525537421
            ],
            [
              105.82573743750802,
              21.015724106067825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D67_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.15",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0155309485269,
          "to_latitude": 21.015724106067825,
          "to_longitude": 105.82573743750802,
          "from_longitude": 105.8260726983158
        }
      },
      {
        "id": 15,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82516560331192,
            21.01581627718011,
            105.8254529347658,
            21.015975198216573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82516560331192,
              21.015975198216573
            ],
            [
              105.8254529347658,
              21.01581627718011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D61_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.16",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015975198216573,
          "to_latitude": 21.01581627718011,
          "to_longitude": 105.8254529347658,
          "from_longitude": 105.82516560331192
        }
      },
      {
        "id": 16,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552378651062,
            21.015688224949326,
            105.82568951916525,
            21.015782037126126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552378651062,
              21.015782037126126
            ],
            [
              105.82552398980995,
              21.015781922333147
            ],
            [
              105.82565937167276,
              21.015705289590297
            ],
            [
              105.82568951916525,
              21.015688224949326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D64_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.17",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015782037126126,
          "to_latitude": 21.015688224949326,
          "to_longitude": 105.82568951916525,
          "from_longitude": 105.82552378651062
        }
      },
      {
        "id": 17,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257803621077,
            21.015515827952704,
            105.82600677619514,
            21.015640762084914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257803621077,
              21.015640762084914
            ],
            [
              105.82600677619514,
              21.015515827952704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D66_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.18",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015640762084914,
          "to_latitude": 21.015515827952704,
          "to_longitude": 105.82600677619514,
          "from_longitude": 105.8257803621077
        }
      },
      {
        "id": 18,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82489669592235,
            21.016000282506017,
            105.82512744933598,
            21.01613930338692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82489669592235,
              21.01613930338692
            ],
            [
              105.8248967247053,
              21.016139289698966
            ],
            [
              105.82490997356517,
              21.016133174745878
            ],
            [
              105.824913173037,
              21.016130735876313
            ],
            [
              105.82493729641365,
              21.016112335799807
            ],
            [
              105.82512744933598,
              21.016000282506017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D54_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.19",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01613930338692,
          "to_latitude": 21.016000282506017,
          "to_longitude": 105.82512744933598,
          "from_longitude": 105.82489669592235
        }
      },
      {
        "id": 19,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82347954914488,
            21.015136019264865,
            105.82412236795386,
            21.015465659995915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82347954914488,
              21.015465659995915
            ],
            [
              105.82348547819115,
              21.01546261994037
            ],
            [
              105.82412236795386,
              21.015136019264865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D41_Ngõ 56, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.20",
          "diaChiLapD": "Ngõ 56, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015465659995915,
          "to_latitude": 21.015136019264865,
          "to_longitude": 105.82412236795386,
          "from_longitude": 105.82347954914488
        }
      },
      {
        "id": 20,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8234637961894,
            21.015087228988264,
            105.82409099860715,
            21.015441080870403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234637961894,
              21.015441080870403
            ],
            [
              105.82347139792512,
              21.01543679258923
            ],
            [
              105.82409099860715,
              21.015087228988264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D40_Ngõ 56, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.21",
          "diaChiLapD": "Ngõ 56, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015441080870403,
          "to_latitude": 21.015087228988264,
          "to_longitude": 105.82409099860715,
          "from_longitude": 105.8234637961894
        }
      },
      {
        "id": 21,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82359000740526,
            21.014756971940926,
            105.82396017264274,
            21.015029237234728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82396017264274,
              21.014756971940926
            ],
            [
              105.8239601236946,
              21.014756992048973
            ],
            [
              105.82394676516428,
              21.014762565521945
            ],
            [
              105.82392827581741,
              21.014770965650047
            ],
            [
              105.82388888770048,
              21.0147888578271
            ],
            [
              105.82380604629886,
              21.01483655100893
            ],
            [
              105.82359000740526,
              21.015029237234728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D37_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.22",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014756971940926,
          "to_latitude": 21.015029237234728,
          "to_longitude": 105.82359000740526,
          "from_longitude": 105.82396017264274
        }
      },
      {
        "id": 22,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8235598453385,
            21.014674244680773,
            105.82390362794285,
            21.014987779725335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82390362794285,
              21.014674244680773
            ],
            [
              105.8239035983172,
              21.014674280050063
            ],
            [
              105.82388859253545,
              21.014691859956304
            ],
            [
              105.8238814432293,
              21.014698299356166
            ],
            [
              105.8235598453385,
              21.014987779725335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D36_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.23",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014674244680773,
          "to_latitude": 21.014987779725335,
          "to_longitude": 105.8235598453385,
          "from_longitude": 105.82390362794285
        }
      },
      {
        "id": 23,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82325920590151,
            21.01480650479262,
            105.82368150437456,
            21.01526829020973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82325920590151,
              21.01480650479262
            ],
            [
              105.82326216706731,
              21.01480884427239
            ],
            [
              105.82354480619698,
              21.015031884817557
            ],
            [
              105.82368150437456,
              21.01526829020973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D33_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.24",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01480650479262,
          "to_latitude": 21.01526829020973,
          "to_longitude": 105.82368150437456,
          "from_longitude": 105.82325920590151
        }
      },
      {
        "id": 24,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82329173613279,
            21.014771023090933,
            105.82354276330264,
            21.01495361386935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82329173613279,
              21.014771023090933
            ],
            [
              105.82329428349459,
              21.01477333115321
            ],
            [
              105.82333248022711,
              21.014807908793806
            ],
            [
              105.82354276330264,
              21.01495361386935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D34_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.25",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014771023090933,
          "to_latitude": 21.01495361386935,
          "to_longitude": 105.82354276330264,
          "from_longitude": 105.82329173613279
        }
      },
      {
        "id": 25,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82297156461931,
            21.015694267527557,
            105.82362042196516,
            21.01592256583728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82362042196516,
              21.015694267527557
            ],
            [
              105.82361544212738,
              21.015696742089037
            ],
            [
              105.82318913711582,
              21.015908551710886
            ],
            [
              105.82316127479608,
              21.015918594919818
            ],
            [
              105.8231358176682,
              21.01592256583728
            ],
            [
              105.82297156461931,
              21.015908933235423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D43_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.26",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015694267527557,
          "to_latitude": 21.015908933235423,
          "to_longitude": 105.82297156461931,
          "from_longitude": 105.82362042196516
        }
      },
      {
        "id": 26,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82297068675194,
            21.01568007458576,
            105.82361873358498,
            21.015897838871368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82361873358498,
              21.015691257118487
            ],
            [
              105.8236167118077,
              21.015692172842332
            ],
            [
              105.82360596005562,
              21.015697049025192
            ],
            [
              105.82355672427538,
              21.01568007458576
            ],
            [
              105.82317695606893,
              21.015884364438328
            ],
            [
              105.82315372139318,
              21.015893309511828
            ],
            [
              105.8231248524454,
              21.015897838871368
            ],
            [
              105.82297068675194,
              21.015882705073764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D42_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.27",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015691257118487,
          "to_latitude": 21.015882705073764,
          "to_longitude": 105.82297068675194,
          "from_longitude": 105.82361873358498
        }
      },
      {
        "id": 27,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82235068050744,
            21.0158946261249,
            105.8229046325904,
            21.015991829065566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8229046325904,
              21.015991829065566
            ],
            [
              105.82290460468495,
              21.015991827394092
            ],
            [
              105.82288794887934,
              21.015990461891885
            ],
            [
              105.82287960816907,
              21.01599051307369
            ],
            [
              105.8228789379708,
              21.015910607862963
            ],
            [
              105.82235068050744,
              21.0158946261249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D25_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.28",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015991829065566,
          "to_latitude": 21.0158946261249,
          "to_longitude": 105.82235068050744,
          "from_longitude": 105.8229046325904
        }
      },
      {
        "id": 28,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82235663668355,
            21.015866875502187,
            105.82290533051982,
            21.015890054639176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82290533051982,
              21.015890054639176
            ],
            [
              105.82290530260944,
              21.01589005206449
            ],
            [
              105.82288922874022,
              21.01588832697278
            ],
            [
              105.82288325183843,
              21.01588808491438
            ],
            [
              105.82235663668355,
              21.015866875502187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D26_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.29",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015890054639176,
          "to_latitude": 21.015866875502187,
          "to_longitude": 105.82235663668355,
          "from_longitude": 105.82290533051982
        }
      },
      {
        "id": 29,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82318282360298,
            21.014454301236686,
            105.82353883592012,
            21.01488030399325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82318282360298,
              21.01488030399325
            ],
            [
              105.82338722713493,
              21.01463571737715
            ],
            [
              105.82339078217578,
              21.014631464039436
            ],
            [
              105.82353883592012,
              21.014454301236686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D35_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.30",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488030399325,
          "to_latitude": 21.014454301236686,
          "to_longitude": 105.82353883592012,
          "from_longitude": 105.82318282360298
        }
      },
      {
        "id": 30,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82339078217578,
            21.014631464039436,
            105.82342013430612,
            21.014652443034453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82339078217578,
              21.014631464039436
            ],
            [
              105.82339701590432,
              21.014635919293738
            ],
            [
              105.82341172480804,
              21.014646433019532
            ],
            [
              105.82342013430612,
              21.014652443034453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D35_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.31",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014631464039436,
          "to_latitude": 21.014652443034453,
          "to_longitude": 105.82342013430612,
          "from_longitude": 105.82339078217578
        }
      },
      {
        "id": 31,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82255949639446,
            21.014910691066962,
            105.8229459059802,
            21.01518748228891
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82255949639446,
              21.014910691066962
            ],
            [
              105.82291537754242,
              21.01515689315351
            ],
            [
              105.82292988817517,
              21.015178604276027
            ],
            [
              105.8229459059802,
              21.01518748228891
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D31_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.32",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014910691066962,
          "to_latitude": 21.01518748228891,
          "to_longitude": 105.8229459059802,
          "from_longitude": 105.82255949639446
        }
      },
      {
        "id": 32,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82291537754242,
            21.014922326361855,
            105.82311846899374,
            21.01515689315351
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82291537754242,
              21.01515689315351
            ],
            [
              105.82311846899374,
              21.014922326361855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D31_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.33",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01515689315351,
          "to_latitude": 21.014922326361855,
          "to_longitude": 105.82311846899374,
          "from_longitude": 105.82291537754242
        }
      },
      {
        "id": 33,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82234808438483,
            21.01560690769388,
            105.82289774243382,
            21.015652592434776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82289774243382,
              21.015652592434776
            ],
            [
              105.82289771451852,
              21.015652588956875
            ],
            [
              105.82289611955277,
              21.015652400671414
            ],
            [
              105.82288486908348,
              21.015651065022706
            ],
            [
              105.82284408566666,
              21.015622115158475
            ],
            [
              105.82234808438483,
              21.01560690769388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D27_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.34",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015652592434776,
          "to_latitude": 21.01560690769388,
          "to_longitude": 105.82234808438483,
          "from_longitude": 105.82289774243382
        }
      },
      {
        "id": 34,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82234793764819,
            21.015580143087195,
            105.82288251968875,
            21.015596822275477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82288251968875,
              21.015596822275477
            ],
            [
              105.82261037148254,
              21.015588331151385
            ],
            [
              105.82234793764819,
              21.015580143087195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D29_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.35",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015596822275477,
          "to_latitude": 21.015580143087195,
          "to_longitude": 105.82234793764819,
          "from_longitude": 105.82288251968875
        }
      },
      {
        "id": 35,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82315857077069,
            21.01509475610556,
            105.82343389521323,
            21.015427918358252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82315857077069,
              21.01509475610556
            ],
            [
              105.8232747359097,
              21.01517781473506
            ],
            [
              105.8233024144418,
              21.015223168187745
            ],
            [
              105.82335399175597,
              21.015307677364742
            ],
            [
              105.82343389521323,
              21.015427918358252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D39_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.36",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01509475610556,
          "to_latitude": 21.015427918358252,
          "to_longitude": 105.82343389521323,
          "from_longitude": 105.82315857077069
        }
      },
      {
        "id": 36,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8233024144418,
            21.01521432107996,
            105.82331846676728,
            21.015223168187745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8233024144418,
              21.015223168187745
            ],
            [
              105.82331846676728,
              21.01521432107996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D39_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.37",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015223168187745,
          "to_latitude": 21.01521432107996,
          "to_longitude": 105.82331846676728,
          "from_longitude": 105.8233024144418
        }
      },
      {
        "id": 37,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82308057612383,
            21.014988701745022,
            105.82346348400056,
            21.01539192427916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346348400056,
              21.01539192427916
            ],
            [
              105.82330250773425,
              21.015148155674368
            ],
            [
              105.82313752557046,
              21.01503463439172
            ],
            [
              105.82312681732894,
              21.015027275225776
            ],
            [
              105.82309625047942,
              21.015000029149924
            ],
            [
              105.82308057612383,
              21.014988701745022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D32_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.38",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01539192427916,
          "to_latitude": 21.014988701745022,
          "to_longitude": 105.82308057612383,
          "from_longitude": 105.82346348400056
        }
      },
      {
        "id": 38,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82377694159166,
            21.01533951811649,
            105.82402758270595,
            21.015778638930726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82402758270595,
              21.015778638930726
            ],
            [
              105.82401886640034,
              21.015764424623523
            ],
            [
              105.82402415665159,
              21.015741586211576
            ],
            [
              105.8239113348484,
              21.01555809483317
            ],
            [
              105.82389429483939,
              21.01553038146479
            ],
            [
              105.82377694159166,
              21.01533951811649
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D49_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.39",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015778638930726,
          "to_latitude": 21.01533951811649,
          "to_longitude": 105.82377694159166,
          "from_longitude": 105.82402758270595
        }
      },
      {
        "id": 39,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82261037148254,
            21.015278651031043,
            105.82262696243872,
            21.015588331151385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82261037148254,
              21.015588331151385
            ],
            [
              105.82262696243872,
              21.015278651031043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D29_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.40",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015588331151385,
          "to_latitude": 21.015278651031043,
          "to_longitude": 105.82262696243872,
          "from_longitude": 105.82261037148254
        }
      },
      {
        "id": 40,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82466747277337,
            21.018615842650714,
            105.82492988331481,
            21.01904414769078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82466747277337,
              21.018638243984288
            ],
            [
              105.82466750827048,
              21.01863822665091
            ],
            [
              105.82470681996476,
              21.01861816961347
            ],
            [
              105.82471283383288,
              21.018615842650714
            ],
            [
              105.82492988331481,
              21.01904414769078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B42_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.41",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018638243984288,
          "to_latitude": 21.01904414769078,
          "to_longitude": 105.82492988331481,
          "from_longitude": 105.82466747277337
        }
      },
      {
        "id": 41,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245736144572,
            21.018364064075868,
            105.82471283383288,
            21.018615842650714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82471283383288,
              21.018615842650714
            ],
            [
              105.8245736144572,
              21.018364064075868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B42_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.42",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018615842650714,
          "to_latitude": 21.018364064075868,
          "to_longitude": 105.8245736144572,
          "from_longitude": 105.82471283383288
        }
      },
      {
        "id": 42,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547255917065,
            21.020039311301442,
            105.8256319587746,
            21.020326346918903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256319587746,
              21.020326346918903
            ],
            [
              105.8256279713338,
              21.020319168468298
            ],
            [
              105.82547255917065,
              21.020039311301442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B45_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.43",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020326346918903,
          "to_latitude": 21.020039311301442,
          "to_longitude": 105.82547255917065,
          "from_longitude": 105.8256319587746
        }
      },
      {
        "id": 43,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82541403685478,
            21.019790972022143,
            105.82583301989752,
            21.019955924980035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82541403685478,
              21.019955924980035
            ],
            [
              105.82541406180061,
              21.019955913117055
            ],
            [
              105.82544035405527,
              21.01994346824289
            ],
            [
              105.82583301989752,
              21.019790972022143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B44_Nguyễn Hy Quang",
          "maTaiSan": "04.O12.DODV.44",
          "diaChiLapD": "Nguyễn Hy Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019955924980035,
          "to_latitude": 21.019790972022143,
          "to_longitude": 105.82583301989752,
          "from_longitude": 105.82541403685478
        }
      },
      {
        "id": 44,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82499417048174,
            21.019148251537846,
            105.82538610164377,
            21.019883619122055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82499417048174,
              21.019148251537846
            ],
            [
              105.82518532840079,
              21.019523409496056
            ],
            [
              105.82538610164377,
              21.019883619122055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B43_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.45",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019148251537846,
          "to_latitude": 21.019883619122055,
          "to_longitude": 105.82538610164377,
          "from_longitude": 105.82499417048174
        }
      },
      {
        "id": 45,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8281305469452,
            21.018952590114232,
            105.82854147653948,
            21.01920809919107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82814209012484,
              21.01920809919107
            ],
            [
              105.82813729162196,
              21.019200913007598
            ],
            [
              105.8281305469452,
              21.019190916429185
            ],
            [
              105.82854147653948,
              21.018952590114232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B39_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.46",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01920809919107,
          "to_latitude": 21.018952590114232,
          "to_longitude": 105.82854147653948,
          "from_longitude": 105.82814209012484
        }
      },
      {
        "id": 46,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82771705308568,
            21.019190916429185,
            105.8281305469452,
            21.019421698779016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281305469452,
              21.019190916429185
            ],
            [
              105.82771705308568,
              21.019421698779016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B39_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.47",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019190916429185,
          "to_latitude": 21.019421698779016,
          "to_longitude": 105.82771705308568,
          "from_longitude": 105.8281305469452
        }
      },
      {
        "id": 47,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82727112763783,
            21.01943743573161,
            105.82768808387246,
            21.019678850278154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82768808387246,
              21.01943743573161
            ],
            [
              105.82727121779095,
              21.0196626162791
            ],
            [
              105.82727112763783,
              21.019662665491715
            ],
            [
              105.82727694215782,
              21.019671981983574
            ],
            [
              105.82728117901956,
              21.019678850278154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B40_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.48",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01943743573161,
          "to_latitude": 21.019678850278154,
          "to_longitude": 105.82728117901956,
          "from_longitude": 105.82768808387246
        }
      },
      {
        "id": 48,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82685731948308,
            21.019662665491715,
            105.82727112763783,
            21.01989275818028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82727112763783,
              21.019662665491715
            ],
            [
              105.82685731948308,
              21.01989275818028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B40_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.49",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019662665491715,
          "to_latitude": 21.01989275818028,
          "to_longitude": 105.82685731948308,
          "from_longitude": 105.82727112763783
        }
      },
      {
        "id": 49,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82641068499571,
            21.019906452890943,
            105.82682833112993,
            21.02013055944027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82641068499571,
              21.02013055944027
            ],
            [
              105.82664265556562,
              21.020002282516245
            ],
            [
              105.82664736925703,
              21.019999849803273
            ],
            [
              105.82682833112993,
              21.019906452890943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B41_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.50",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02013055944027,
          "to_latitude": 21.019906452890943,
          "to_longitude": 105.82682833112993,
          "from_longitude": 105.82641068499571
        }
      },
      {
        "id": 50,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82582105108807,
            21.02013055944027,
            105.82641068499571,
            21.02040514858044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82641068499571,
              21.02013055944027
            ],
            [
              105.82619935977095,
              21.020245740171266
            ],
            [
              105.82617824674408,
              21.02024630788083
            ],
            [
              105.82582105108807,
              21.02040514858044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B41_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.51",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02013055944027,
          "to_latitude": 21.02040514858044,
          "to_longitude": 105.82582105108807,
          "from_longitude": 105.82641068499571
        }
      },
      {
        "id": 51,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82657015415383,
            21.0203805874181,
            105.826869047967,
            21.020541627731816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82657015415383,
              21.020526558902194
            ],
            [
              105.82657132964202,
              21.02052847164084
            ],
            [
              105.82657919603707,
              21.020541627731816
            ],
            [
              105.826869047967,
              21.0203805874181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C27_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.52",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020526558902194,
          "to_latitude": 21.0203805874181,
          "to_longitude": 105.826869047967,
          "from_longitude": 105.82657015415383
        }
      },
      {
        "id": 52,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82622136151285,
            21.020541627731816,
            105.82657919603707,
            21.020746059072707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82657919603707,
              21.020541627731816
            ],
            [
              105.82622136151285,
              21.020746059072707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C27_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.53",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020541627731816,
          "to_latitude": 21.020746059072707,
          "to_longitude": 105.82622136151285,
          "from_longitude": 105.82657919603707
        }
      },
      {
        "id": 53,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277951902644,
            21.019560863889026,
            105.82832395597221,
            21.019873034180716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277951902644,
              21.019873034180716
            ],
            [
              105.82779548180146,
              21.019872871084637
            ],
            [
              105.8281609015438,
              21.019665409022295
            ],
            [
              105.82832395597221,
              21.01958297076131
            ],
            [
              105.82831999519968,
              21.019575589020768
            ],
            [
              105.82831235957072,
              21.019560863889026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C26_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.54",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019873034180716,
          "to_latitude": 21.019560863889026,
          "to_longitude": 105.82831235957072,
          "from_longitude": 105.8277951902644
        }
      },
      {
        "id": 54,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82734172947552,
            21.019873034180716,
            105.8277951902644,
            21.02012532808824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277951902644,
              21.019873034180716
            ],
            [
              105.82734172947552,
              21.02012532808824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C26_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.55",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019873034180716,
          "to_latitude": 21.02012532808824,
          "to_longitude": 105.82734172947552,
          "from_longitude": 105.8277951902644
        }
      },
      {
        "id": 55,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82877315258513,
            21.018982043067865,
            105.82937986353834,
            21.019330969668218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82877315258513,
              21.019330969668218
            ],
            [
              105.82880570607317,
              21.019304467594168
            ],
            [
              105.82937986353834,
              21.018982043067865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C25_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.56",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019330969668218,
          "to_latitude": 21.018982043067865,
          "to_longitude": 105.82937986353834,
          "from_longitude": 105.82877315258513
        }
      },
      {
        "id": 56,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283399314615,
            21.019297709521734,
            105.82877315258513,
            21.01957237761894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874567024062,
              21.019297709521734
            ],
            [
              105.82876910355435,
              21.01932607133444
            ],
            [
              105.82877315258513,
              21.019330969668218
            ],
            [
              105.8283399314615,
              21.01957237761894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C25_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.57",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019297709521734,
          "to_latitude": 21.01957237761894,
          "to_longitude": 105.8283399314615,
          "from_longitude": 105.82874567024062
        }
      },
      {
        "id": 57,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82414501339822,
            21.016853156432074,
            105.82459580617765,
            21.017079473555015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82459580617765,
              21.016853156432074
            ],
            [
              105.82459578219915,
              21.016853169193467
            ],
            [
              105.82458767501308,
              21.01685756113659
            ],
            [
              105.82417805484475,
              21.017079473555015
            ],
            [
              105.82417081741555,
              21.017068143322653
            ],
            [
              105.82414501339822,
              21.017027745991303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D12_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.58",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016853156432074,
          "to_latitude": 21.017027745991303,
          "to_longitude": 105.82414501339822,
          "from_longitude": 105.82459580617765
        }
      },
      {
        "id": 58,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239891550878,
            21.016852677394,
            105.82456754925745,
            21.017162093554838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8239891550878,
              21.017162093554838
            ],
            [
              105.82401565663126,
              21.017148237054336
            ],
            [
              105.82416929721037,
              21.01706790862289
            ],
            [
              105.82417018368258,
              21.017068178005786
            ],
            [
              105.82417081741555,
              21.017068143322653
            ],
            [
              105.82417251951117,
              21.017066117269223
            ],
            [
              105.82456754925745,
              21.016852677394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.59",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017162093554838,
          "to_latitude": 21.016852677394,
          "to_longitude": 105.82456754925745,
          "from_longitude": 105.8239891550878
        }
      },
      {
        "id": 59,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82379663944305,
            21.01653637177986,
            105.82435663843731,
            21.016815857346074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82379663944305,
              21.016815857346074
            ],
            [
              105.82390360631479,
              21.016758488466373
            ],
            [
              105.82393063458049,
              21.01674561665566
            ],
            [
              105.82393291609178,
              21.01674435012134
            ],
            [
              105.82401661076777,
              21.0166978829208
            ],
            [
              105.82435663843731,
              21.01653637177986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D15_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.60",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016815857346074,
          "to_latitude": 21.01653637177986,
          "to_longitude": 105.82435663843731,
          "from_longitude": 105.82379663944305
        }
      },
      {
        "id": 60,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8237878791397,
            21.016516317846527,
            105.82435245534629,
            21.01680187175913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8237878791397,
              21.01680187175913
            ],
            [
              105.82392588499435,
              21.01673835333227
            ],
            [
              105.82396244296591,
              21.01670336683481
            ],
            [
              105.82435245534629,
              21.016516317846527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D16_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.61",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01680187175913,
          "to_latitude": 21.016516317846527,
          "to_longitude": 105.82435245534629,
          "from_longitude": 105.8237878791397
        }
      },
      {
        "id": 61,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367024067925,
            21.016322735748954,
            105.8242556944324,
            21.016611965383806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367024067925,
              21.016611965383806
            ],
            [
              105.82381399516242,
              21.016543923859707
            ],
            [
              105.823819610944,
              21.01654041201825
            ],
            [
              105.82384118539008,
              21.016526919019046
            ],
            [
              105.8242556944324,
              21.016322735748954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D17_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.62",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016611965383806,
          "to_latitude": 21.016322735748954,
          "to_longitude": 105.8242556944324,
          "from_longitude": 105.82367024067925
        }
      },
      {
        "id": 62,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82365508464409,
            21.016271581244734,
            105.82425407525524,
            21.01658978597553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82365508464409,
              21.01658978597553
            ],
            [
              105.82379517650449,
              21.01651447497015
            ],
            [
              105.82425407525524,
              21.016271581244734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.63",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01658978597553,
          "to_latitude": 21.016271581244734,
          "to_longitude": 105.82425407525524,
          "from_longitude": 105.82365508464409
        }
      },
      {
        "id": 63,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82393133494843,
            21.01674435012134,
            105.8240780468295,
            21.017036391820675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82393291609178,
              21.01674435012134
            ],
            [
              105.82393133494843,
              21.01675403494552
            ],
            [
              105.82401432226627,
              21.016908162370076
            ],
            [
              105.8240780468295,
              21.017036391820675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D15_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.64",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01674435012134,
          "to_latitude": 21.017036391820675,
          "to_longitude": 105.8240780468295,
          "from_longitude": 105.82393291609178
        }
      },
      {
        "id": 64,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.823819610944,
            21.01654041201825,
            105.82388901658685,
            21.016676551596067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.823819610944,
              21.01654041201825
            ],
            [
              105.82388901658685,
              21.016676551596067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D17_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.65",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654041201825,
          "to_latitude": 21.016676551596067,
          "to_longitude": 105.82388901658685,
          "from_longitude": 105.823819610944
        }
      },
      {
        "id": 65,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82352011107422,
            21.01612653475487,
            105.8241025159333,
            21.01649496476045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82352011107422,
              21.01649496476045
            ],
            [
              105.82363262684527,
              21.016356058431217
            ],
            [
              105.82364967197368,
              21.016347733064926
            ],
            [
              105.8241025159333,
              21.01612653475487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D19_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.66",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01649496476045,
          "to_latitude": 21.01612653475487,
          "to_longitude": 105.8241025159333,
          "from_longitude": 105.82352011107422
        }
      },
      {
        "id": 66,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82364967197368,
            21.016347733064926,
            105.82373328253612,
            21.01652383312772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82364967197368,
              21.016347733064926
            ],
            [
              105.82373328253612,
              21.01652383312772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D19_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.67",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016347733064926,
          "to_latitude": 21.01652383312772,
          "to_longitude": 105.82373328253612,
          "from_longitude": 105.82364967197368
        }
      },
      {
        "id": 67,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82345697178843,
            21.01606134927378,
            105.82414532586057,
            21.016450607649862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82345697178843,
              21.016450607649862
            ],
            [
              105.82362164586607,
              21.01634302294029
            ],
            [
              105.82414532586057,
              21.01606134927378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D20_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.68",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016450607649862,
          "to_latitude": 21.01606134927378,
          "to_longitude": 105.82414532586057,
          "from_longitude": 105.82345697178843
        }
      },
      {
        "id": 68,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82338822186081,
            21.01609598055337,
            105.8235569082084,
            21.016327985106123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82338822186081,
              21.01609598055337
            ],
            [
              105.8233907174625,
              21.016098492992857
            ],
            [
              105.82344790603726,
              21.01615599739812
            ],
            [
              105.82347484871163,
              21.01619850830639
            ],
            [
              105.8235569082084,
              21.016327985106123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D46_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.69",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01609598055337,
          "to_latitude": 21.016327985106123,
          "to_longitude": 105.8235569082084,
          "from_longitude": 105.82338822186081
        }
      },
      {
        "id": 69,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82347484871163,
            21.015930343235695,
            105.82397756122629,
            21.01619850830639
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82347484871163,
              21.01619850830639
            ],
            [
              105.82352790603747,
              21.016170205396094
            ],
            [
              105.82397756122629,
              21.015930343235695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D46_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.70",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01619850830639,
          "to_latitude": 21.015930343235695,
          "to_longitude": 105.82397756122629,
          "from_longitude": 105.82347484871163
        }
      },
      {
        "id": 70,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82485470567187,
            21.016560917545558,
            105.8251198405443,
            21.016704186816263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82485470567187,
              21.016704186816263
            ],
            [
              105.82510925886919,
              21.016566635708397
            ],
            [
              105.82511981656589,
              21.016560930307023
            ],
            [
              105.8251198405443,
              21.016560917545558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D13_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.71",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016704186816263,
          "to_latitude": 21.016560917545558,
          "to_longitude": 105.8251198405443,
          "from_longitude": 105.82485470567187
        }
      },
      {
        "id": 71,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82452010741554,
            21.016689137073996,
            105.82460009113365,
            21.016822093585656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82452010741554,
              21.016689137073996
            ],
            [
              105.8245267221357,
              21.016703555730484
            ],
            [
              105.82460009113365,
              21.016822093585656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D57_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.72",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016689137073996,
          "to_latitude": 21.016822093585656,
          "to_longitude": 105.82460009113365,
          "from_longitude": 105.82452010741554
        }
      },
      {
        "id": 72,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82448801136304,
            21.01638491856489,
            105.82497437403626,
            21.016619178792002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82448801136304,
              21.016619178792002
            ],
            [
              105.82497437403626,
              21.01638491856489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D57_Ngõ 10, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.73",
          "diaChiLapD": "Ngõ 10, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016619178792002,
          "to_latitude": 21.01638491856489,
          "to_longitude": 105.82497437403626,
          "from_longitude": 105.82448801136304
        }
      },
      {
        "id": 73,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82501824880924,
            21.016438411873306,
            105.82508183026164,
            21.016561789251558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504464484622,
              21.016438411873306
            ],
            [
              105.82504462183968,
              21.016438426436498
            ],
            [
              105.82502878651688,
              21.0164487792356
            ],
            [
              105.82501824880924,
              21.01645566956702
            ],
            [
              105.82508183026164,
              21.016561789251558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D57_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.74",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016438411873306,
          "to_latitude": 21.016561789251558,
          "to_longitude": 105.82508183026164,
          "from_longitude": 105.82504464484622
        }
      },
      {
        "id": 74,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82444799363282,
            21.016352324194738,
            105.82499180986035,
            21.01659785336623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82444799363282,
              21.01659785336623
            ],
            [
              105.82445684955233,
              21.01659317509155
            ],
            [
              105.82466533615137,
              21.016483042218187
            ],
            [
              105.82488231862166,
              21.016385207778498
            ],
            [
              105.82496761146524,
              21.016364261156827
            ],
            [
              105.82499178491514,
              21.01635233605764
            ],
            [
              105.82499180986035,
              21.016352324194738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D58_Ngõ 10, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.75",
          "diaChiLapD": "Ngõ 10, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01659785336623,
          "to_latitude": 21.016352324194738,
          "to_longitude": 105.82499180986035,
          "from_longitude": 105.82444799363282
        }
      },
      {
        "id": 75,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82442704004727,
            21.01603755992899,
            105.82466533615137,
            21.016483042218187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82466533615137,
              21.016483042218187
            ],
            [
              105.82442704004727,
              21.01603755992899
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D58_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.76",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016483042218187,
          "to_latitude": 21.01603755992899,
          "to_longitude": 105.82442704004727,
          "from_longitude": 105.82466533615137
        }
      },
      {
        "id": 76,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241724159538,
            21.01588295231697,
            105.82470370996546,
            21.01611437318718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82418763134889,
              21.016106214416904
            ],
            [
              105.82418760737545,
              21.016106228081433
            ],
            [
              105.82417507845247,
              21.016113647362996
            ],
            [
              105.82417329034348,
              21.01611437318718
            ],
            [
              105.8241724159538,
              21.01611280039001
            ],
            [
              105.8241866068289,
              21.016104279250147
            ],
            [
              105.82418966628241,
              21.016102440814482
            ],
            [
              105.82425550602034,
              21.01607541975648
            ],
            [
              105.8244064242581,
              21.016009654397237
            ],
            [
              105.82444742838653,
              21.01598785774609
            ],
            [
              105.82462000125,
              21.015896124279827
            ],
            [
              105.82466724638695,
              21.015892524432115
            ],
            [
              105.82470370996546,
              21.01588295231697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D52_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.77",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016106214416904,
          "to_latitude": 21.01588295231697,
          "to_longitude": 105.82470370996546,
          "from_longitude": 105.82418763134889
        }
      },
      {
        "id": 77,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82444742838653,
            21.01598785774609,
            105.82468426767531,
            21.01643851442881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82444742838653,
              21.01598785774609
            ],
            [
              105.82468426767531,
              21.01643851442881
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D52_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.78",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01598785774609,
          "to_latitude": 21.01643851442881,
          "to_longitude": 105.82468426767531,
          "from_longitude": 105.82444742838653
        }
      },
      {
        "id": 78,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82432745748957,
            21.016365401205384,
            105.82444832046485,
            21.016539711110905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82432745748957,
              21.016375103938035
            ],
            [
              105.82434168132538,
              21.016365401205384
            ],
            [
              105.82444832046485,
              21.016539711110905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.79",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016375103938035,
          "to_latitude": 21.016539711110905,
          "to_longitude": 105.82444832046485,
          "from_longitude": 105.82432745748957
        }
      },
      {
        "id": 79,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82424713885266,
            21.016175335445666,
            105.82434168132538,
            21.016365401205384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82434168132538,
              21.016365401205384
            ],
            [
              105.82424713885266,
              21.016175335445666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.80",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016365401205384,
          "to_latitude": 21.016175335445666,
          "to_longitude": 105.82424713885266,
          "from_longitude": 105.82434168132538
        }
      },
      {
        "id": 80,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82482684864726,
            21.01611655940444,
            105.82492957636289,
            21.01627928220469
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82484711165363,
              21.01611655940444
            ],
            [
              105.82484708864217,
              21.01611657306441
            ],
            [
              105.8248297182942,
              21.0161269649002
            ],
            [
              105.82482684864726,
              21.016128682297822
            ],
            [
              105.82492957636289,
              21.01627928220469
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D53_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.81",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01611655940444,
          "to_latitude": 21.01627928220469,
          "to_longitude": 105.82492957636289,
          "from_longitude": 105.82484711165363
        }
      },
      {
        "id": 81,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82471944826425,
            21.01593438701608,
            105.82482684864726,
            21.016128682297822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82482684864726,
              21.016128682297822
            ],
            [
              105.82471944826425,
              21.01593438701608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D53_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.82",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016128682297822,
          "to_latitude": 21.01593438701608,
          "to_longitude": 105.82471944826425,
          "from_longitude": 105.82482684864726
        }
      },
      {
        "id": 82,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82405469365267,
            21.01563864144785,
            105.82449460528441,
            21.015875574641747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82405469365267,
              21.015875574641747
            ],
            [
              105.82405615010387,
              21.01587523790943
            ],
            [
              105.824085266794,
              21.01586030563805
            ],
            [
              105.82408529173924,
              21.015860293775265
            ],
            [
              105.82449460528441,
              21.01563864144785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D47_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.83",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015875574641747,
          "to_latitude": 21.01563864144785,
          "to_longitude": 105.82449460528441,
          "from_longitude": 105.82405469365267
        }
      },
      {
        "id": 83,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.824085266794,
            21.01586030563805,
            105.82419450143237,
            21.016056828848665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.824085266794,
              21.01586030563805
            ],
            [
              105.82419450143237,
              21.016056828848665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D47_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.84",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01586030563805,
          "to_latitude": 21.016056828848665,
          "to_longitude": 105.82419450143237,
          "from_longitude": 105.824085266794
        }
      },
      {
        "id": 84,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82421605873951,
            21.015819465554458,
            105.82466474479303,
            21.016052839634305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82466474479303,
              21.015819465554458
            ],
            [
              105.82463147196258,
              21.015834808262433
            ],
            [
              105.82462552339943,
              21.0158407703891
            ],
            [
              105.82458016551941,
              21.015886175034325
            ],
            [
              105.82421605873951,
              21.016052839634305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D51_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.85",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015819465554458,
          "to_latitude": 21.016052839634305,
          "to_longitude": 105.82421605873951,
          "from_longitude": 105.82466474479303
        }
      },
      {
        "id": 85,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82453732480533,
            21.015670283976828,
            105.82462552339943,
            21.0158407703891
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82462552339943,
              21.0158407703891
            ],
            [
              105.82453732480533,
              21.015670283976828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D51_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.86",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0158407703891,
          "to_latitude": 21.015670283976828,
          "to_longitude": 105.82453732480533,
          "from_longitude": 105.82462552339943
        }
      },
      {
        "id": 86,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570855529995,
            21.01738775551137,
            105.82607351024764,
            21.0175525680979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82607351024764,
              21.01748764991599
            ],
            [
              105.82606510944,
              21.017473136128167
            ],
            [
              105.82606378664366,
              21.01747084474493
            ],
            [
              105.8260519690659,
              21.017450406128045
            ],
            [
              105.8260207422276,
              21.017461231175965
            ],
            [
              105.82580865323649,
              21.0175525680979
            ],
            [
              105.82570855529995,
              21.01738775551137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.87",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01748764991599,
          "to_latitude": 21.01738775551137,
          "to_longitude": 105.82570855529995,
          "from_longitude": 105.82607351024764
        }
      },
      {
        "id": 87,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82606378664366,
            21.017371372325083,
            105.8262618938137,
            21.01747084474493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606378664366,
              21.01747084474493
            ],
            [
              105.8262618938137,
              21.017371372325083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.88",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01747084474493,
          "to_latitude": 21.017371372325083,
          "to_longitude": 105.8262618938137,
          "from_longitude": 105.82606378664366
        }
      },
      {
        "id": 88,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82603296194911,
            21.016936562895427,
            105.82614741896371,
            21.0171256601492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82603296194911,
              21.016936562895427
            ],
            [
              105.82614741896371,
              21.0171256601492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.89",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016936562895427,
          "to_latitude": 21.0171256601492,
          "to_longitude": 105.82614741896371,
          "from_longitude": 105.82603296194911
        }
      },
      {
        "id": 89,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8258764920567,
            21.016666542661977,
            105.82595224647285,
            21.016794987551663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258764920567,
              21.016666542661977
            ],
            [
              105.82595224647285,
              21.016794987551663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.90",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016666542661977,
          "to_latitude": 21.016794987551663,
          "to_longitude": 105.82595224647285,
          "from_longitude": 105.8258764920567
        }
      },
      {
        "id": 90,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257830861057,
            21.016506125214917,
            105.82584964659486,
            21.01662470368619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257830861057,
              21.016506125214917
            ],
            [
              105.82584964659486,
              21.01662470368619
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.91",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016506125214917,
          "to_latitude": 21.01662470368619,
          "to_longitude": 105.82584964659486,
          "from_longitude": 105.8257830861057
        }
      },
      {
        "id": 91,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82551400903546,
            21.01632297337101,
            105.82576698967738,
            21.0164781292762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551400903546,
              21.016382695592938
            ],
            [
              105.82551785552643,
              21.016390152930107
            ],
            [
              105.82552643065023,
              21.016399656612467
            ],
            [
              105.82567072614061,
              21.01632297337101
            ],
            [
              105.82576698967738,
              21.0164781292762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D7_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.92",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016382695592938,
          "to_latitude": 21.0164781292762,
          "to_longitude": 105.82576698967738,
          "from_longitude": 105.82551400903546
        }
      },
      {
        "id": 92,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82523240799807,
            21.016399656612467,
            105.82552643065023,
            21.01656122508908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552643065023,
              21.016399656612467
            ],
            [
              105.82523240799807,
              21.01656122508908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D7_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.93",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016399656612467,
          "to_latitude": 21.01656122508908,
          "to_longitude": 105.82523240799807,
          "from_longitude": 105.82552643065023
        }
      },
      {
        "id": 93,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82517514840707,
            21.01640602438222,
            105.82537824755488,
            21.016532226566984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82517514840707,
              21.016532226566984
            ],
            [
              105.82537824755488,
              21.01640602438222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D10_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.94",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016532226566984,
          "to_latitude": 21.01640602438222,
          "to_longitude": 105.82537824755488,
          "from_longitude": 105.82517514840707
        }
      },
      {
        "id": 94,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82518064976267,
            21.016048769780074,
            105.82542878203786,
            21.016428003980632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82542878203786,
              21.016428003980632
            ],
            [
              105.82542693212777,
              21.01642519037481
            ],
            [
              105.82539882025162,
              21.016398962503327
            ],
            [
              105.82518064976267,
              21.016048769780074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D9_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.95",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016428003980632,
          "to_latitude": 21.016048769780074,
          "to_longitude": 105.82518064976267,
          "from_longitude": 105.82542878203786
        }
      },
      {
        "id": 95,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82520774529696,
            21.016036629058945,
            105.8254409861253,
            21.01642169534569
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254409861253,
              21.01642169534569
            ],
            [
              105.82543888011453,
              21.0164176175629
            ],
            [
              105.82542717242039,
              21.01637726132421
            ],
            [
              105.82530124814186,
              21.016181780961713
            ],
            [
              105.82520774529696,
              21.016036629058945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.96",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01642169534569,
          "to_latitude": 21.016036629058945,
          "to_longitude": 105.82520774529696,
          "from_longitude": 105.8254409861253
        }
      },
      {
        "id": 96,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82542717242039,
            21.016273993506797,
            105.82561185786952,
            21.01637726132421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82542717242039,
              21.01637726132421
            ],
            [
              105.82561185786952,
              21.016273993506797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.97",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01637726132421,
          "to_latitude": 21.016273993506797,
          "to_longitude": 105.82561185786952,
          "from_longitude": 105.82542717242039
        }
      },
      {
        "id": 97,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82530124814186,
            21.01597419494,
            105.82566690058235,
            21.016181780961713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82530124814186,
              21.016181780961713
            ],
            [
              105.82566690058235,
              21.01597419494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.98",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016181780961713,
          "to_latitude": 21.01597419494,
          "to_longitude": 105.82566690058235,
          "from_longitude": 105.82530124814186
        }
      },
      {
        "id": 98,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82506633040542,
            21.01566623615063,
            105.8253529235732,
            21.0158171689158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82506633040542,
              21.0158171689158
            ],
            [
              105.8253529235732,
              21.01566623615063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D61_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.99",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0158171689158,
          "to_latitude": 21.01566623615063,
          "to_longitude": 105.8253529235732,
          "from_longitude": 105.82506633040542
        }
      },
      {
        "id": 99,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82492030582762,
            21.0156338239096,
            105.82516938802003,
            21.016031852360804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82515315658652,
              21.01601223576199
            ],
            [
              105.82516226070221,
              21.016029252643413
            ],
            [
              105.82516370252456,
              21.016031852360804
            ],
            [
              105.82516938802003,
              21.01602895704136
            ],
            [
              105.82515783960599,
              21.016009665950445
            ],
            [
              105.82515448922044,
              21.01600408039205
            ],
            [
              105.82492030582762,
              21.0156338239096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D60_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.100",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01601223576199,
          "to_latitude": 21.0156338239096,
          "to_longitude": 105.82492030582762,
          "from_longitude": 105.82515315658652
        }
      },
      {
        "id": 100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82559196913444,
            21.015336622624485,
            105.8257880189698,
            21.015668338347233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257880189698,
              21.015668338347233
            ],
            [
              105.82577888054823,
              21.015655582183943
            ],
            [
              105.8257803621077,
              21.015640762084914
            ],
            [
              105.82559196913444,
              21.015336622624485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D66_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.101",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015668338347233,
          "to_latitude": 21.015336622624485,
          "to_longitude": 105.82559196913444,
          "from_longitude": 105.8257880189698
        }
      },
      {
        "id": 101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82531478822123,
            21.015923529896238,
            105.8253326011474,
            21.01595214891146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82531478822123,
              21.015923529896238
            ],
            [
              105.8253203988165,
              21.015932547584708
            ],
            [
              105.82532929458691,
              21.01594683682153
            ],
            [
              105.8253326011474,
              21.01595214891146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D62_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.102",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015923529896238,
          "to_latitude": 21.01595214891146,
          "to_longitude": 105.8253326011474,
          "from_longitude": 105.82531478822123
        }
      },
      {
        "id": 102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605886605741,
            21.015538881885185,
            105.82627561068853,
            21.015897360263075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605886605741,
              21.015538881885185
            ],
            [
              105.82606089207626,
              21.01554223295203
            ],
            [
              105.82627561068853,
              21.015897360263075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D67_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.103",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015538881885185,
          "to_latitude": 21.015897360263075,
          "to_longitude": 105.82627561068853,
          "from_longitude": 105.82605886605741
        }
      },
      {
        "id": 103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.825183246868,
            21.0154486078879,
            105.8255144639251,
            21.01581394366363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8255144639251,
              21.01581394366363
            ],
            [
              105.82550816288285,
              21.015804968170613
            ],
            [
              105.82525768228557,
              21.0154486078879
            ],
            [
              105.825183246868,
              21.015487819407284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D63_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.104",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01581394366363,
          "to_latitude": 21.015487819407284,
          "to_longitude": 105.825183246868,
          "from_longitude": 105.8255144639251
        }
      },
      {
        "id": 104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8242500906769,
            21.01529686398572,
            105.82440015742277,
            21.015540468635372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8242500906769,
              21.01529686398572
            ],
            [
              105.8242614286599,
              21.01531500364733
            ],
            [
              105.82440015742277,
              21.015540468635372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D38_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.105",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01529686398572,
          "to_latitude": 21.015540468635372,
          "to_longitude": 105.82440015742277,
          "from_longitude": 105.8242500906769
        }
      },
      {
        "id": 105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241702153949,
            21.01516907408752,
            105.82426981217701,
            21.01529686398572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82426981217701,
              21.01528493888219
            ],
            [
              105.82426970959006,
              21.01528500079861
            ],
            [
              105.82426839226272,
              21.015285796600782
            ],
            [
              105.8242500906769,
              21.01529686398572
            ],
            [
              105.8241702153949,
              21.01516907408752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D38_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.106",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01528493888219,
          "to_latitude": 21.01516907408752,
          "to_longitude": 105.8241702153949,
          "from_longitude": 105.82426981217701
        }
      },
      {
        "id": 106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239113348484,
            21.01540381613142,
            105.82419821819026,
            21.01555809483317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8239113348484,
              21.01555809483317
            ],
            [
              105.82419821819026,
              21.01540381613142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D49_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.107",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01555809483317,
          "to_latitude": 21.01540381613142,
          "to_longitude": 105.82419821819026,
          "from_longitude": 105.8239113348484
        }
      },
      {
        "id": 107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82389429483939,
            21.015371810935157,
            105.82418708779221,
            21.01553038146479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82389429483939,
              21.01553038146479
            ],
            [
              105.82418708779221,
              21.015371810935157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D49_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.108",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01553038146479,
          "to_latitude": 21.015371810935157,
          "to_longitude": 105.82418708779221,
          "from_longitude": 105.82389429483939
        }
      },
      {
        "id": 108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418990364158,
            21.01555219529781,
            105.82439678679378,
            21.01569826949477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82420125020819,
              21.01569826949477
            ],
            [
              105.82420060491683,
              21.0156970749428
            ],
            [
              105.82419522141203,
              21.01568683133992
            ],
            [
              105.82418990364158,
              21.015676821354024
            ],
            [
              105.82439678679378,
              21.01555219529781
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D50_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.109",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01569826949477,
          "to_latitude": 21.01555219529781,
          "to_longitude": 105.82439678679378,
          "from_longitude": 105.82420125020819
        }
      },
      {
        "id": 109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82405069135731,
            21.015676821354024,
            105.82418990364158,
            21.015749744789375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82418990364158,
              21.015676821354024
            ],
            [
              105.82405069135731,
              21.015749744789375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D50_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.110",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015676821354024,
          "to_latitude": 21.015749744789375,
          "to_longitude": 105.82405069135731,
          "from_longitude": 105.82418990364158
        }
      },
      {
        "id": 110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82372902077931,
            21.015370520359003,
            105.82400281659768,
            21.01579071850571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82400281659768,
              21.01579071850571
            ],
            [
              105.82399732306229,
              21.015780405880914
            ],
            [
              105.8239902938405,
              21.015769664435407
            ],
            [
              105.82372902077931,
              21.015370520359003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D48_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.111",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01579071850571,
          "to_latitude": 21.015370520359003,
          "to_longitude": 105.82372902077931,
          "from_longitude": 105.82400281659768
        }
      },
      {
        "id": 111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8237689658289,
            21.015769664435407,
            105.8239902938405,
            21.015888211693596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8239902938405,
              21.015769664435407
            ],
            [
              105.82398391187203,
              21.01577308244431
            ],
            [
              105.8237689658289,
              21.015888211693596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D48_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.112",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015769664435407,
          "to_latitude": 21.015888211693596,
          "to_longitude": 105.8237689658289,
          "from_longitude": 105.8239902938405
        }
      },
      {
        "id": 112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82333248022711,
            21.014483565015972,
            105.82360075545525,
            21.014807908793806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82333248022711,
              21.014807908793806
            ],
            [
              105.82360075545525,
              21.014483565015972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D34_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.113",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014807908793806,
          "to_latitude": 21.014483565015972,
          "to_longitude": 105.82360075545525,
          "from_longitude": 105.82333248022711
        }
      },
      {
        "id": 113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82362684390418,
            21.014516309848972,
            105.8238814432293,
            21.014698299356166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8238814432293,
              21.014698299356166
            ],
            [
              105.82362684390418,
              21.014516309848972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D36_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.114",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014698299356166,
          "to_latitude": 21.014516309848972,
          "to_longitude": 105.82362684390418,
          "from_longitude": 105.8238814432293
        }
      },
      {
        "id": 114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82392827581741,
            21.014770965650047,
            105.8241025702944,
            21.015057743110034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392827581741,
              21.014770965650047
            ],
            [
              105.8241025702944,
              21.015057743110034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D37_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.115",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014770965650047,
          "to_latitude": 21.015057743110034,
          "to_longitude": 105.8241025702944,
          "from_longitude": 105.82392827581741
        }
      },
      {
        "id": 115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82312681732894,
            21.014847752652624,
            105.8232832411042,
            21.015027275225776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82312681732894,
              21.015027275225776
            ],
            [
              105.8232832411042,
              21.014847752652624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D32_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.116",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015027275225776,
          "to_latitude": 21.014847752652624,
          "to_longitude": 105.8232832411042,
          "from_longitude": 105.82312681732894
        }
      },
      {
        "id": 116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82289807915271,
            21.01542711293463,
            105.82340579727008,
            21.015599619556376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82289807915271,
              21.01542711293463
            ],
            [
              105.82290110742917,
              21.015427306029345
            ],
            [
              105.82293969332244,
              21.015429705327197
            ],
            [
              105.82294875685449,
              21.015481280884906
            ],
            [
              105.8230372043158,
              21.015591230924727
            ],
            [
              105.8231147030849,
              21.015599154835556
            ],
            [
              105.82314634379287,
              21.015599619556376
            ],
            [
              105.8231823547411,
              21.01558994608528
            ],
            [
              105.82340579727008,
              21.015470720060947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D30_Ngõ 56, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.117",
          "diaChiLapD": "Ngõ 56, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01542711293463,
          "to_latitude": 21.015470720060947,
          "to_longitude": 105.82340579727008,
          "from_longitude": 105.82289807915271
        }
      },
      {
        "id": 117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82289605378303,
            21.015486914192515,
            105.82346659598244,
            21.01564210670362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82289605378303,
              21.015636379556586
            ],
            [
              105.82289751987265,
              21.015636396852372
            ],
            [
              105.82309359741276,
              21.015637935817363
            ],
            [
              105.82314722049179,
              21.01564210670362
            ],
            [
              105.82319033472186,
              21.0156359964134
            ],
            [
              105.82322216550199,
              21.015620263542246
            ],
            [
              105.82346659598244,
              21.015486914192515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D28_Ngõ 56, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.118",
          "diaChiLapD": "Ngõ 56, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015636379556586,
          "to_latitude": 21.015486914192515,
          "to_longitude": 105.82346659598244,
          "from_longitude": 105.82289605378303
        }
      },
      {
        "id": 118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82241260451248,
            21.014838282279573,
            105.82289532163443,
            21.015188361931383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82241260451248,
              21.014838282279573
            ],
            [
              105.8224178354531,
              21.01484195661036
            ],
            [
              105.82243559588342,
              21.014855159931614
            ],
            [
              105.8226902085788,
              21.015044468213993
            ],
            [
              105.82269705438766,
              21.015048801308154
            ],
            [
              105.82289532163443,
              21.015188361931383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D24_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.119",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014838282279573,
          "to_latitude": 21.015188361931383,
          "to_longitude": 105.82289532163443,
          "from_longitude": 105.82241260451248
        }
      },
      {
        "id": 119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82243559588342,
            21.014761775250268,
            105.82249764127545,
            21.014855159931614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243559588342,
              21.014855159931614
            ],
            [
              105.82245051046864,
              21.014831088195756
            ],
            [
              105.82249764127545,
              21.014761775250268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D24_Ngõ 86, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.120",
          "diaChiLapD": "Ngõ 86, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014855159931614,
          "to_latitude": 21.014761775250268,
          "to_longitude": 105.82249764127545,
          "from_longitude": 105.82243559588342
        }
      },
      {
        "id": 120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82230827916861,
            21.015017354175352,
            105.82233076564309,
            21.01568130485598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82231085928282,
              21.01568130485598
            ],
            [
              105.82230827916861,
              21.015494925663003
            ],
            [
              105.82233076564309,
              21.015017354175352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D23_Ngõ 86, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.121",
          "diaChiLapD": "Ngõ 86, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568130485598,
          "to_latitude": 21.015017354175352,
          "to_longitude": 105.82233076564309,
          "from_longitude": 105.82231085928282
        }
      },
      {
        "id": 121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82231085928282,
            21.01568130485598,
            105.82232284686286,
            21.01610484485547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82231085928282,
              21.01568130485598
            ],
            [
              105.82232245298967,
              21.01587052718958
            ],
            [
              105.82232254007184,
              21.01592220488111
            ],
            [
              105.82232284686286,
              21.01610484485547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D23_Ngõ 86, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.122",
          "diaChiLapD": "Ngõ 86, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568130485598,
          "to_latitude": 21.01610484485547,
          "to_longitude": 105.82232284686286,
          "from_longitude": 105.82231085928282
        }
      },
      {
        "id": 122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288251968875,
            21.0152560602171,
            105.82289632886939,
            21.015596822275477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82288251968875,
              21.015596822275477
            ],
            [
              105.82288259036872,
              21.015595678448758
            ],
            [
              105.82288687394393,
              21.015489783316
            ],
            [
              105.82289632886939,
              21.0152560602171
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D29_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.123",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015596822275477,
          "to_latitude": 21.0152560602171,
          "to_longitude": 105.82289632886939,
          "from_longitude": 105.82288251968875
        }
      },
      {
        "id": 123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288318527776,
            21.015659581561312,
            105.82288325183843,
            21.01588808491438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82288325183843,
              21.01588808491438
            ],
            [
              105.82288318527776,
              21.015659581561312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D26_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.124",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01588808491438,
          "to_latitude": 21.015659581561312,
          "to_longitude": 105.82288318527776,
          "from_longitude": 105.82288325183843
        }
      },
      {
        "id": 124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82239238283644,
            21.016147875124254,
            105.82290684697418,
            21.016199773847955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82290684697418,
              21.016147875124254
            ],
            [
              105.8229068190786,
              21.01614787525919
            ],
            [
              105.82288028484345,
              21.01614804605627
            ],
            [
              105.82287133390632,
              21.01614978741666
            ],
            [
              105.82280877635304,
              21.01616196470555
            ],
            [
              105.82239238283644,
              21.016199773847955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D22_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.125",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016147875124254,
          "to_latitude": 21.016199773847955,
          "to_longitude": 105.82239238283644,
          "from_longitude": 105.82290684697418
        }
      },
      {
        "id": 125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82287133390632,
            21.01599936682992,
            105.82287711240785,
            21.01614978741666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82287133390632,
              21.01614978741666
            ],
            [
              105.82287711240785,
              21.01599936682992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D22_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.126",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01614978741666,
          "to_latitude": 21.01599936682992,
          "to_longitude": 105.82287711240785,
          "from_longitude": 105.82287133390632
        }
      },
      {
        "id": 126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82350131395026,
            21.015473895943135,
            105.82376176799167,
            21.015908766792258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82374314576758,
              21.015908766792258
            ],
            [
              105.82374476238279,
              21.0159079921255
            ],
            [
              105.82376176799167,
              21.015899837650295
            ],
            [
              105.82350131395026,
              21.015473895943135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D45_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.127",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015908766792258,
          "to_latitude": 21.015473895943135,
          "to_longitude": 105.82350131395026,
          "from_longitude": 105.82374314576758
        }
      },
      {
        "id": 127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334172124892,
            21.01589864393406,
            105.82373744550826,
            21.01610853374189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82373744550826,
              21.01589864393406
            ],
            [
              105.82373616589567,
              21.015899477485185
            ],
            [
              105.82371451162159,
              21.015911557306953
            ],
            [
              105.82366149027852,
              21.015941134550992
            ],
            [
              105.82334172124892,
              21.01610853374189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D44_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.128",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01589864393406,
          "to_latitude": 21.01610853374189,
          "to_longitude": 105.82334172124892,
          "from_longitude": 105.82373744550826
        }
      },
      {
        "id": 128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82360238920663,
            21.015724004137457,
            105.82371451162159,
            21.015911557306953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82371451162159,
              21.015911557306953
            ],
            [
              105.82360238920663,
              21.015724004137457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D44_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.129",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015911557306953,
          "to_latitude": 21.015724004137457,
          "to_longitude": 105.82360238920663,
          "from_longitude": 105.82371451162159
        }
      },
      {
        "id": 129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82290698988474,
            21.0161577927634,
            105.82323547472443,
            21.016189494667792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82290698988474,
              21.0161577927634
            ],
            [
              105.82293139220367,
              21.016158627630254
            ],
            [
              105.82293423271587,
              21.0161594855037
            ],
            [
              105.82300698494254,
              21.01618149836126
            ],
            [
              105.82323547472443,
              21.016189494667792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D21_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.130",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0161577927634,
          "to_latitude": 21.016189494667792,
          "to_longitude": 105.82323547472443,
          "from_longitude": 105.82290698988474
        }
      },
      {
        "id": 130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82293423271587,
            21.015955821104974,
            105.82294047030544,
            21.0161594855037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82293423271587,
              21.0161594855037
            ],
            [
              105.82294047030544,
              21.015955821104974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D21_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.131",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0161594855037,
          "to_latitude": 21.015955821104974,
          "to_longitude": 105.82294047030544,
          "from_longitude": 105.82293423271587
        }
      },
      {
        "id": 131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82525208712546,
            21.011517842820048,
            105.82557209840144,
            21.01169472228697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82557209840144,
              21.011517842820048
            ],
            [
              105.82556622857709,
              21.01151989723662
            ],
            [
              105.82554517150506,
              21.011527264954783
            ],
            [
              105.82536754815108,
              21.01162875330296
            ],
            [
              105.82525208712546,
              21.01169472228697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT01_Tây Sơn",
          "maTaiSan": "04.O12.DODV.132",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011517842820048,
          "to_latitude": 21.01169472228697,
          "to_longitude": 105.82525208712546,
          "from_longitude": 105.82557209840144
        }
      },
      {
        "id": 132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82536754815108,
            21.01162875330296,
            105.82538643950659,
            21.011660289047562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82536754815108,
              21.01162875330296
            ],
            [
              105.82538643950659,
              21.011660289047562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT01_Tây Sơn",
          "maTaiSan": "04.O12.DODV.133",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01162875330296,
          "to_latitude": 21.011660289047562,
          "to_longitude": 105.82538643950659,
          "from_longitude": 105.82536754815108
        }
      },
      {
        "id": 133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82555209939018,
            21.011539187352483,
            105.82587178346603,
            21.011966829376746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82587178346603,
              21.011966829376746
            ],
            [
              105.8257999529395,
              21.01186817715875
            ],
            [
              105.82576834500018,
              21.011825112976698
            ],
            [
              105.82575812196644,
              21.011813437806428
            ],
            [
              105.82571243181293,
              21.011777920668763
            ],
            [
              105.82570978886055,
              21.011773971039627
            ],
            [
              105.82568303156206,
              21.011733992206814
            ],
            [
              105.82567518322776,
              21.011722266669004
            ],
            [
              105.82560716859714,
              21.011622867842682
            ],
            [
              105.82555209939018,
              21.011539187352483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT02_Tây Sơn",
          "maTaiSan": "04.O12.DODV.134",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011966829376746,
          "to_latitude": 21.011539187352483,
          "to_longitude": 105.82555209939018,
          "from_longitude": 105.82587178346603
        }
      },
      {
        "id": 134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568303156206,
            21.01171213117874,
            105.82571546719691,
            21.011733992206814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82571546719691,
              21.01171213117874
            ],
            [
              105.82571031410271,
              21.011715603800805
            ],
            [
              105.82570239519153,
              21.011720941458634
            ],
            [
              105.82568303156206,
              21.011733992206814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT02_Tây Sơn",
          "maTaiSan": "04.O12.DODV.135",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01171213117874,
          "to_latitude": 21.011733992206814,
          "to_longitude": 105.82568303156206,
          "from_longitude": 105.82571546719691
        }
      },
      {
        "id": 135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82546663751448,
            21.011965866258397,
            105.82585676748499,
            21.01219375475086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82585676748499,
              21.011965866258397
            ],
            [
              105.82569345824096,
              21.012061261857152
            ],
            [
              105.82566911059686,
              21.012075483887838
            ],
            [
              105.82546663751448,
              21.01219375475086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT03_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.136",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011965866258397,
          "to_latitude": 21.01219375475086,
          "to_longitude": 105.82546663751448,
          "from_longitude": 105.82585676748499
        }
      },
      {
        "id": 136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547459901517,
            21.012006613133202,
            105.82589257494908,
            21.012243876809748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82589257494908,
              21.012006613133202
            ],
            [
              105.82574047180012,
              21.012094631927347
            ],
            [
              105.8256798795288,
              21.012128143927633
            ],
            [
              105.82567630247067,
              21.01213012219028
            ],
            [
              105.82564335522837,
              21.012148703370094
            ],
            [
              105.82547459901517,
              21.012243876809748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT04_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.137",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012006613133202,
          "to_latitude": 21.012243876809748,
          "to_longitude": 105.82547459901517,
          "from_longitude": 105.82589257494908
        }
      },
      {
        "id": 137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82574047180012,
            21.012094631927347,
            105.82578196894872,
            21.01216070929543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574047180012,
              21.012094631927347
            ],
            [
              105.82578196894872,
              21.01216070929543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT04_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.138",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012094631927347,
          "to_latitude": 21.01216070929543,
          "to_longitude": 105.82578196894872,
          "from_longitude": 105.82574047180012
        }
      },
      {
        "id": 138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82517585231555,
            21.011787530024883,
            105.82546463876322,
            21.012236081511933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82517585231555,
              21.011787530024883
            ],
            [
              105.82519493692466,
              21.011815554915074
            ],
            [
              105.82521899564306,
              21.011828548569735
            ],
            [
              105.82524492613258,
              21.011870549296276
            ],
            [
              105.82531916153248,
              21.01199078895049
            ],
            [
              105.82531381827279,
              21.0120053342343
            ],
            [
              105.82534617045417,
              21.012053433993295
            ],
            [
              105.82545706012176,
              21.012218303942337
            ],
            [
              105.825464511205,
              21.012235800324095
            ],
            [
              105.82546463876322,
              21.012236081511933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT06_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.139",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011787530024883,
          "to_latitude": 21.012236081511933,
          "to_longitude": 105.82546463876322,
          "from_longitude": 105.82517585231555
        }
      },
      {
        "id": 139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82513753672706,
            21.012009649557836,
            105.8254437359942,
            21.012247736087502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254437359942,
              21.012247736087502
            ],
            [
              105.82544360614169,
              21.01224756239474
            ],
            [
              105.82543938069594,
              21.01224183927603
            ],
            [
              105.82536514383023,
              21.012129417116597
            ],
            [
              105.82528147645506,
              21.012009649557836
            ],
            [
              105.82518275469548,
              21.01206606156101
            ],
            [
              105.82513753672706,
              21.012091900868533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT09_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.140",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012247736087502,
          "to_latitude": 21.012091900868533,
          "to_longitude": 105.82513753672706,
          "from_longitude": 105.8254437359942
        }
      },
      {
        "id": 140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82524492613258,
            21.01178652885072,
            105.82539307192528,
            21.011870549296276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82524492613258,
              21.011870549296276
            ],
            [
              105.82539307192528,
              21.01178652885072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT06_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.141",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011870549296276,
          "to_latitude": 21.01178652885072,
          "to_longitude": 105.82539307192528,
          "from_longitude": 105.82524492613258
        }
      },
      {
        "id": 141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82534617045417,
            21.011949811641966,
            105.82551423556968,
            21.012053433993295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551423556968,
              21.011949811641966
            ],
            [
              105.82544219483007,
              21.011991933559298
            ],
            [
              105.8253488351907,
              21.012051727517886
            ],
            [
              105.82534617045417,
              21.012053433993295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT06_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.142",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011949811641966,
          "to_latitude": 21.012053433993295,
          "to_longitude": 105.82534617045417,
          "from_longitude": 105.82551423556968
        }
      },
      {
        "id": 142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545916631902,
            21.012238510643087,
            105.8256681331303,
            21.01257164627325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82545916631902,
              21.012238510643087
            ],
            [
              105.82545933324523,
              21.012238778994767
            ],
            [
              105.8256681331303,
              21.01257164627325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT07_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.143",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012238510643087,
          "to_latitude": 21.01257164627325,
          "to_longitude": 105.8256681331303,
          "from_longitude": 105.82545916631902
        }
      },
      {
        "id": 143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82544869682347,
            21.012244630212475,
            105.82564431953966,
            21.012582596933964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82544869682347,
              21.012244630212475
            ],
            [
              105.8254488823287,
              21.012244953570857
            ],
            [
              105.82564431953966,
              21.012582596933964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT08_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.144",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012244630212475,
          "to_latitude": 21.012582596933964,
          "to_longitude": 105.82564431953966,
          "from_longitude": 105.82544869682347
        }
      },
      {
        "id": 144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568842087507,
            21.012296475832834,
            105.82611524994756,
            21.012579146667292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82611524994756,
              21.012296475832834
            ],
            [
              105.82610832245186,
              21.012300535146952
            ],
            [
              105.82609493134733,
              21.01230974623257
            ],
            [
              105.82611400906393,
              21.012351673504966
            ],
            [
              105.82568842087507,
              21.012579146667292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Ngõ 112, Tây Sơn",
          "maTaiSan": "04.O12.DODV.145",
          "diaChiLapD": "Ngõ 112, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012296475832834,
          "to_latitude": 21.012579146667292,
          "to_longitude": 105.82568842087507,
          "from_longitude": 105.82611524994756
        }
      },
      {
        "id": 145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570098590558,
            21.01235383298949,
            105.82615068346593,
            21.012598103124194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82615068346593,
              21.01235383298949
            ],
            [
              105.82614363158812,
              21.012357664392724
            ],
            [
              105.82570098590558,
              21.012598103124194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Ngõ 112, Tây Sơn",
          "maTaiSan": "04.O12.DODV.146",
          "diaChiLapD": "Ngõ 112, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01235383298949,
          "to_latitude": 21.012598103124194,
          "to_longitude": 105.82570098590558,
          "from_longitude": 105.82615068346593
        }
      },
      {
        "id": 146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535458387555,
            21.01259561464641,
            105.82564460665382,
            21.012770206371336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82535458387555,
              21.012770206371336
            ],
            [
              105.8253559501055,
              21.0127692125175
            ],
            [
              105.82536064280931,
              21.01276579904188
            ],
            [
              105.82536827877674,
              21.01274136675754
            ],
            [
              105.82564460665382,
              21.01259561464641
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Ngõ 112, Tây Sơn",
          "maTaiSan": "04.O12.DODV.147",
          "diaChiLapD": "Ngõ 112, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012770206371336,
          "to_latitude": 21.01259561464641,
          "to_longitude": 105.82564460665382,
          "from_longitude": 105.82535458387555
        }
      },
      {
        "id": 147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535779728042,
            21.012615162608856,
            105.8256644537763,
            21.01277416046396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82535779728042,
              21.01277416046396
            ],
            [
              105.82535832291765,
              21.012773888752704
            ],
            [
              105.8256644537763,
              21.012615162608856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Ngõ 112, Tây Sơn",
          "maTaiSan": "04.O12.DODV.148",
          "diaChiLapD": "Ngõ 112, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01277416046396,
          "to_latitude": 21.012615162608856,
          "to_longitude": 105.8256644537763,
          "from_longitude": 105.82535779728042
        }
      },
      {
        "id": 148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82581164663465,
            21.012624339649655,
            105.82620326353164,
            21.01279165858365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82620326353164,
              21.012624339649655
            ],
            [
              105.82605636531308,
              21.012687101830593
            ],
            [
              105.82604355287168,
              21.012692576165723
            ],
            [
              105.82581164663465,
              21.01279165858365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.149",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012624339649655,
          "to_latitude": 21.01279165858365,
          "to_longitude": 105.82581164663465,
          "from_longitude": 105.82620326353164
        }
      },
      {
        "id": 149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251609440305,
            21.012235624210906,
            105.82541982344206,
            21.012387640112458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8251609440305,
              21.012387640112458
            ],
            [
              105.82530659552125,
              21.01230211169755
            ],
            [
              105.82532865028737,
              21.01228916083988
            ],
            [
              105.82536015517698,
              21.012270661683658
            ],
            [
              105.82541982344206,
              21.012235624210906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.150",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012387640112458,
          "to_latitude": 21.012235624210906,
          "to_longitude": 105.82541982344206,
          "from_longitude": 105.8251609440305
        }
      },
      {
        "id": 150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82517170532115,
            21.012265005888565,
            105.82543384535823,
            21.01240833413419
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82517170532115,
              21.01240833413419
            ],
            [
              105.82526293046573,
              21.012358454498436
            ],
            [
              105.82529963552433,
              21.01233838561372
            ],
            [
              105.8253514760242,
              21.012310042007766
            ],
            [
              105.82543384535823,
              21.012265005888565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.151",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01240833413419,
          "to_latitude": 21.012265005888565,
          "to_longitude": 105.82543384535823,
          "from_longitude": 105.82517170532115
        }
      },
      {
        "id": 151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82534399801825,
            21.012310042007766,
            105.82538131781749,
            21.012535474413095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8253514760242,
              21.012310042007766
            ],
            [
              105.8253800142891,
              21.012358317418038
            ],
            [
              105.82538131781749,
              21.01236900619965
            ],
            [
              105.82537847363827,
              21.0123812831025
            ],
            [
              105.82537235968357,
              21.012388100507504
            ],
            [
              105.82535117095566,
              21.012411250919786
            ],
            [
              105.8253457221956,
              21.012421308583537
            ],
            [
              105.82534399801825,
              21.01243119824481
            ],
            [
              105.8253485127557,
              21.012455759460526
            ],
            [
              105.82535944992028,
              21.012508065543113
            ],
            [
              105.82537331959631,
              21.012535474413095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.152",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012310042007766,
          "to_latitude": 21.012535474413095,
          "to_longitude": 105.82537331959631,
          "from_longitude": 105.8253514760242
        }
      },
      {
        "id": 152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82529512084022,
            21.01215873407978,
            105.82536015517698,
            21.012270661683658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82529512084022,
              21.01215873407978
            ],
            [
              105.82532279469635,
              21.012206383242393
            ],
            [
              105.82535988909454,
              21.012270204135284
            ],
            [
              105.82536015517698,
              21.012270661683658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.153",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01215873407978,
          "to_latitude": 21.012270661683658,
          "to_longitude": 105.82536015517698,
          "from_longitude": 105.82529512084022
        }
      },
      {
        "id": 153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613651885625,
            21.012390446302515,
            105.82624951097894,
            21.01258684615201
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624951097894,
              21.01258684615201
            ],
            [
              105.82619246992165,
              21.012489688498093
            ],
            [
              105.82618127980356,
              21.012470626951036
            ],
            [
              105.8261676799702,
              21.012445605874188
            ],
            [
              105.82613651885625,
              21.012390446302515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Tây Sơn",
          "maTaiSan": "04.O12.DODV.154",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01258684615201,
          "to_latitude": 21.012390446302515,
          "to_longitude": 105.82613651885625,
          "from_longitude": 105.82624951097894
        }
      },
      {
        "id": 154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82572764736584,
            21.01268704902376,
            105.82578828895724,
            21.012821304741365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82572764736584,
              21.01268704902376
            ],
            [
              105.82574342171428,
              21.012721795460756
            ],
            [
              105.82578189456888,
              21.012803388667454
            ],
            [
              105.82578331459837,
              21.012806402164586
            ],
            [
              105.82578828895724,
              21.012821304741365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.155",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01268704902376,
          "to_latitude": 21.012821304741365,
          "to_longitude": 105.82578828895724,
          "from_longitude": 105.82572764736584
        }
      },
      {
        "id": 155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82606429108414,
            21.012714796916207,
            105.82615953196441,
            21.01290366337836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606429108414,
              21.012714796916207
            ],
            [
              105.82613484126054,
              21.012844601166336
            ],
            [
              105.8261348938412,
              21.012862533566253
            ],
            [
              105.82615953196441,
              21.01290366337836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.156",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012714796916207,
          "to_latitude": 21.01290366337836,
          "to_longitude": 105.82615953196441,
          "from_longitude": 105.82606429108414
        }
      },
      {
        "id": 156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8258459911869,
            21.012166022510687,
            105.82599792332563,
            21.012250175664363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82599792332563,
              21.012166022510687
            ],
            [
              105.8258459911869,
              21.012250175664363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Tây Sơn",
          "maTaiSan": "04.O12.DODV.157",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012166022510687,
          "to_latitude": 21.012250175664363,
          "to_longitude": 105.8258459911869,
          "from_longitude": 105.82599792332563
        }
      },
      {
        "id": 157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82338777942053,
            21.01408576996998,
            105.82373859111115,
            21.014332820211262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82338777942053,
              21.01423233008498
            ],
            [
              105.82339160617128,
              21.01422640717277
            ],
            [
              105.82341522068033,
              21.014189975047973
            ],
            [
              105.82342197012602,
              21.014179560695762
            ],
            [
              105.82353144606749,
              21.01408576996998
            ],
            [
              105.82373534224148,
              21.01423116162807
            ],
            [
              105.82373859111115,
              21.014247881783646
            ],
            [
              105.82366734371426,
              21.014332820211262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.158",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01423233008498,
          "to_latitude": 21.014332820211262,
          "to_longitude": 105.82366734371426,
          "from_longitude": 105.82338777942053
        }
      },
      {
        "id": 158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82330055254943,
            21.013877463362768,
            105.82363141862672,
            21.014348785237786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8235694246411,
              21.013991628890082
            ],
            [
              105.82339725830228,
              21.013877463362768
            ],
            [
              105.82330055254943,
              21.014029890200476
            ],
            [
              105.82331550364029,
              21.014110831864432
            ],
            [
              105.82341522068033,
              21.014189975047973
            ],
            [
              105.82342118852335,
              21.014194712492397
            ],
            [
              105.82343440433085,
              21.01420520092793
            ],
            [
              105.82348770525546,
              21.01424750479904
            ],
            [
              105.82363141862672,
              21.014348785237786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT141_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.159",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013991628890082,
          "to_latitude": 21.014348785237786,
          "to_longitude": 105.82363141862672,
          "from_longitude": 105.8235694246411
        }
      },
      {
        "id": 159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82502065123009,
            21.012110492415978,
            105.82515162304558,
            21.01238029421255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82510466547124,
              21.012110492415978
            ],
            [
              105.8250311755775,
              21.012159379132367
            ],
            [
              105.82502065123009,
              21.012167421007934
            ],
            [
              105.82503239822341,
              21.012186499807605
            ],
            [
              105.82506041923956,
              21.012232047329885
            ],
            [
              105.82507432493108,
              21.012254650887478
            ],
            [
              105.82515162304558,
              21.01238029421255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT05_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.160",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012110492415978,
          "to_latitude": 21.01238029421255,
          "to_longitude": 105.82515162304558,
          "from_longitude": 105.82510466547124
        }
      },
      {
        "id": 160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82517055124866,
            21.01242481458282,
            105.82536827877674,
            21.01274136675754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82517055124866,
              21.01242481458282
            ],
            [
              105.82535948052168,
              21.012727282071623
            ],
            [
              105.82536827877674,
              21.01274136675754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.161",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01242481458282,
          "to_latitude": 21.01274136675754,
          "to_longitude": 105.82536827877674,
          "from_longitude": 105.82517055124866
        }
      },
      {
        "id": 161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82498301095505,
            21.01221281090484,
            105.82521132072578,
            21.012567254389683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82498301095505,
              21.01221281090484
            ],
            [
              105.8250495637374,
              21.012314379390894
            ],
            [
              105.82505061818979,
              21.012315988343765
            ],
            [
              105.825089227532,
              21.012374913689463
            ],
            [
              105.82511611564951,
              21.012415947851398
            ],
            [
              105.82521132072578,
              21.012567254389683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.162",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01221281090484,
          "to_latitude": 21.012567254389683,
          "to_longitude": 105.82521132072578,
          "from_longitude": 105.82498301095505
        }
      },
      {
        "id": 162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82472093191558,
            21.012200641247862,
            105.82496951183273,
            21.012354527717434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82496951183273,
              21.012200641247862
            ],
            [
              105.824844379988,
              21.012274298153347
            ],
            [
              105.82482566255442,
              21.01228646231903
            ],
            [
              105.82472093191558,
              21.012354527717434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.163",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012200641247862,
          "to_latitude": 21.012354527717434,
          "to_longitude": 105.82472093191558,
          "from_longitude": 105.82496951183273
        }
      },
      {
        "id": 163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82550142415899,
            21.012803388667454,
            105.82578189456888,
            21.012934758255202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578189456888,
              21.012803388667454
            ],
            [
              105.825657223578,
              21.01286676431248
            ],
            [
              105.82550142415899,
              21.012934758255202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.164",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012803388667454,
          "to_latitude": 21.012934758255202,
          "to_longitude": 105.82550142415899,
          "from_longitude": 105.82578189456888
        }
      },
      {
        "id": 164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82582603792524,
            21.01277709365068,
            105.82592170725046,
            21.01281839468713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82582603792524,
              21.01281839468713
            ],
            [
              105.8259202226859,
              21.01277773401863
            ],
            [
              105.82592048946593,
              21.012777618917124
            ],
            [
              105.82592170725046,
              21.01277709365068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.165",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01281839468713,
          "to_latitude": 21.01277709365068,
          "to_longitude": 105.82592170725046,
          "from_longitude": 105.82582603792524
        }
      },
      {
        "id": 165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591515497681,
            21.01276562106209,
            105.82604165736888,
            21.013030338724356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82604165736888,
              21.013030338724356
            ],
            [
              105.82592517414247,
              21.012786552569548
            ],
            [
              105.82592170725046,
              21.01277709365068
            ],
            [
              105.82592086837786,
              21.01277480804386
            ],
            [
              105.82591515497681,
              21.01276562106209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.166",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013030338724356,
          "to_latitude": 21.01276562106209,
          "to_longitude": 105.82591515497681,
          "from_longitude": 105.82604165736888
        }
      },
      {
        "id": 166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82579151129768,
            21.01281989000447,
            105.82594426019524,
            21.013220473721443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82579151129768,
              21.01281989000447
            ],
            [
              105.82579405701212,
              21.012825218426677
            ],
            [
              105.82587623948815,
              21.01299712532345
            ],
            [
              105.8258953482458,
              21.013068097514864
            ],
            [
              105.82592167669388,
              21.013165882156354
            ],
            [
              105.82594426019524,
              21.013220473721443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.167",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01281989000447,
          "to_latitude": 21.013220473721443,
          "to_longitude": 105.82594426019524,
          "from_longitude": 105.82579151129768
        }
      },
      {
        "id": 167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82579020558428,
            21.013068097514864,
            105.8258953482458,
            21.01330187652097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258953482458,
              21.013068097514864
            ],
            [
              105.82583618170366,
              21.013081782257856
            ],
            [
              105.82583750680043,
              21.013112981392986
            ],
            [
              105.82583355175868,
              21.01311934123847
            ],
            [
              105.82582672485884,
              21.013123335021465
            ],
            [
              105.82579020558428,
              21.01313576001856
            ],
            [
              105.82582359262237,
              21.01330187652097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.168",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013068097514864,
          "to_latitude": 21.01330187652097,
          "to_longitude": 105.82582359262237,
          "from_longitude": 105.8258953482458
        }
      },
      {
        "id": 168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568022529001,
            21.012868735532738,
            105.82579132071258,
            21.01305262887924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568022529001,
              21.012868735532738
            ],
            [
              105.82568424487638,
              21.012879520420718
            ],
            [
              105.82568467278804,
              21.01288036828019
            ],
            [
              105.82573409161124,
              21.012978609935978
            ],
            [
              105.82575476461425,
              21.01302148873023
            ],
            [
              105.8257700439098,
              21.013015921153226
            ],
            [
              105.82579132071258,
              21.01305262887924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.169",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012868735532738,
          "to_latitude": 21.01305262887924,
          "to_longitude": 105.82579132071258,
          "from_longitude": 105.82568022529001
        }
      },
      {
        "id": 169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552254905706,
            21.012929891242855,
            105.82576309678419,
            21.013314021369453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552254905706,
              21.012929891242855
            ],
            [
              105.82552337488556,
              21.01293225459249
            ],
            [
              105.82553019048626,
              21.012951728504905
            ],
            [
              105.82557815855203,
              21.01305973931708
            ],
            [
              105.82562137609273,
              21.013165539485396
            ],
            [
              105.82562873683571,
              21.013178668291875
            ],
            [
              105.8256387205062,
              21.01319647301813
            ],
            [
              105.82566040204118,
              21.013221778272904
            ],
            [
              105.82570196589059,
              21.013246617618538
            ],
            [
              105.82572590147954,
              21.01325291978525
            ],
            [
              105.825742544704,
              21.013256945080425
            ],
            [
              105.82574997753106,
              21.013257478040003
            ],
            [
              105.82576309678419,
              21.013314021369453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.170",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012929891242855,
          "to_latitude": 21.013314021369453,
          "to_longitude": 105.82576309678419,
          "from_longitude": 105.82552254905706
        }
      },
      {
        "id": 170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8252995953958,
            21.013136425981486,
            105.82562873683571,
            21.01340607263698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82562873683571,
              21.013178668291875
            ],
            [
              105.82558413809514,
              21.01319716600305
            ],
            [
              105.82556246534655,
              21.013200197616477
            ],
            [
              105.82549946961645,
              21.01316411950181
            ],
            [
              105.82545044387676,
              21.013136425981486
            ],
            [
              105.82533391406471,
              21.013379690924374
            ],
            [
              105.8252995953958,
              21.01340607263698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.171",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013178668291875,
          "to_latitude": 21.01340607263698,
          "to_longitude": 105.8252995953958,
          "from_longitude": 105.82562873683571
        }
      },
      {
        "id": 171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82550765989728,
            21.01288036828019,
            105.82568467278804,
            21.01295758595189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82550765989728,
              21.01295758595189
            ],
            [
              105.82552531973533,
              21.01294988155523
            ],
            [
              105.82552563425233,
              21.012950400287647
            ],
            [
              105.82552603503669,
              21.012950863504706
            ],
            [
              105.82552651239499,
              21.01295125770503
            ],
            [
              105.82552705184904,
              21.012951573926596
            ],
            [
              105.82552763891073,
              21.012951801400995
            ],
            [
              105.82552825816497,
              21.012951935686857
            ],
            [
              105.82552889227276,
              21.012951972352216
            ],
            [
              105.82552952487684,
              21.012951910573186
            ],
            [
              105.82553013770628,
              21.01295175134166
            ],
            [
              105.82553019048626,
              21.012951728504905
            ],
            [
              105.82553071443859,
              21.01295150015616
            ],
            [
              105.8255312387564,
              21.012951163418457
            ],
            [
              105.82553169723786,
              21.012950749322723
            ],
            [
              105.82553207648598,
              21.012950270579125
            ],
            [
              105.8255323669515,
              21.01294973987914
            ],
            [
              105.82553256101876,
              21.012949171711362
            ],
            [
              105.8255326520339,
              21.012948580559687
            ],
            [
              105.8255326381677,
              21.01294798359431
            ],
            [
              105.82553252047147,
              21.012947397068203
            ],
            [
              105.82553230095847,
              21.012946837229656
            ],
            [
              105.82568467278804,
              21.01288036828019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.172",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01295758595189,
          "to_latitude": 21.01288036828019,
          "to_longitude": 105.82568467278804,
          "from_longitude": 105.82550765989728
        }
      },
      {
        "id": 172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82490103626947,
            21.012568072203027,
            105.82523153898376,
            21.01267890462027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82490103626947,
              21.01261321577511
            ],
            [
              105.82496607693474,
              21.01267890462027
            ],
            [
              105.82504696110927,
              21.012654632027562
            ],
            [
              105.82509443866721,
              21.012658324515975
            ],
            [
              105.82522930196919,
              21.012569545376337
            ],
            [
              105.82523153898376,
              21.012568072203027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.173",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01261321577511,
          "to_latitude": 21.012568072203027,
          "to_longitude": 105.82523153898376,
          "from_longitude": 105.82490103626947
        }
      },
      {
        "id": 173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82500979341712,
            21.012663957546447,
            105.82528876676894,
            21.012769162439632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82500979341712,
              21.012742946383018
            ],
            [
              105.82503293240991,
              21.012768484915284
            ],
            [
              105.82504855771705,
              21.012769162439632
            ],
            [
              105.82512248125208,
              21.01272054005978
            ],
            [
              105.8251359760907,
              21.012723350490063
            ],
            [
              105.82514838559845,
              21.012725934053467
            ],
            [
              105.82522195466501,
              21.01270731130869
            ],
            [
              105.8252733589207,
              21.012675355127147
            ],
            [
              105.82527648589652,
              21.01267304124892
            ],
            [
              105.8252858745035,
              21.01266609686699
            ],
            [
              105.82528876676894,
              21.012663957546447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.174",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012742946383018,
          "to_latitude": 21.012663957546447,
          "to_longitude": 105.82528876676894,
          "from_longitude": 105.82500979341712
        }
      },
      {
        "id": 174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82512834660942,
            21.012723350490063,
            105.82515050153027,
            21.01279903215723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8251359760907,
              21.012723350490063
            ],
            [
              105.82515050153027,
              21.012747391679316
            ],
            [
              105.82512834660942,
              21.01277380288243
            ],
            [
              105.82513926272986,
              21.01279903215723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.175",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012723350490063,
          "to_latitude": 21.01279903215723,
          "to_longitude": 105.82513926272986,
          "from_longitude": 105.8251359760907
        }
      },
      {
        "id": 175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82510819595082,
            21.012905436165276,
            105.82545445670227,
            21.013231775003277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82510819595082,
              21.013231775003277
            ],
            [
              105.82515914855713,
              21.01316471443723
            ],
            [
              105.82520207672012,
              21.013152554749382
            ],
            [
              105.82525692857692,
              21.013090158473155
            ],
            [
              105.82536244800163,
              21.012970123590378
            ],
            [
              105.8253647488933,
              21.01296872055706
            ],
            [
              105.82541240421394,
              21.012939666520435
            ],
            [
              105.8254435006201,
              21.012913863129672
            ],
            [
              105.82545015654229,
              21.01290834012586
            ],
            [
              105.82545445670227,
              21.012905436165276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.176",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013231775003277,
          "to_latitude": 21.012905436165276,
          "to_longitude": 105.82545445670227,
          "from_longitude": 105.82510819595082
        }
      },
      {
        "id": 176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82515027549043,
            21.012920301591127,
            105.8253647488933,
            21.01299467721754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82515027549043,
              21.01299467721754
            ],
            [
              105.82528797130881,
              21.012920301591127
            ],
            [
              105.82530846514601,
              21.0129234691557
            ],
            [
              105.82533434930336,
              21.01293252941381
            ],
            [
              105.8253647488933,
              21.01296872055706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.177",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01299467721754,
          "to_latitude": 21.01296872055706,
          "to_longitude": 105.8253647488933,
          "from_longitude": 105.82515027549043
        }
      },
      {
        "id": 177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82525692857692,
            21.013090158473155,
            105.82534282989722,
            21.013168093083646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82525692857692,
              21.013090158473155
            ],
            [
              105.82532660011707,
              21.01314287514821
            ],
            [
              105.82534282989722,
              21.013168093083646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.178",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013090158473155,
          "to_latitude": 21.013168093083646,
          "to_longitude": 105.82534282989722,
          "from_longitude": 105.82525692857692
        }
      },
      {
        "id": 178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82522528170806,
            21.01258818308246,
            105.82547657388915,
            21.012961853676405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547657388915,
              21.012961853676405
            ],
            [
              105.82545845726094,
              21.012935666712888
            ],
            [
              105.8254435006201,
              21.012913863129672
            ],
            [
              105.82543780848953,
              21.012905565706188
            ],
            [
              105.82536848116328,
              21.012802804142243
            ],
            [
              105.8253612298436,
              21.012792771955485
            ],
            [
              105.82528990988017,
              21.012694096282257
            ],
            [
              105.82528650159061,
              21.01268881268089
            ],
            [
              105.82527648589652,
              21.01267304124892
            ],
            [
              105.82526992843866,
              21.012662714853384
            ],
            [
              105.82522528170806,
              21.01258818308246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.179",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012961853676405,
          "to_latitude": 21.01258818308246,
          "to_longitude": 105.82522528170806,
          "from_longitude": 105.82547657388915
        }
      },
      {
        "id": 179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82451752356342,
            21.01255469034401,
            105.82470954620324,
            21.012998759924564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82451752356342,
              21.01255469034401
            ],
            [
              105.8245316818283,
              21.01256462677583
            ],
            [
              105.82470954620324,
              21.012708600758526
            ],
            [
              105.82461870131158,
              21.012817405668923
            ],
            [
              105.82468324634632,
              21.012869057270127
            ],
            [
              105.8245959186263,
              21.01297060305163
            ],
            [
              105.82459598297638,
              21.012998759924564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.180",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01255469034401,
          "to_latitude": 21.012998759924564,
          "to_longitude": 105.82459598297638,
          "from_longitude": 105.82451752356342
        }
      },
      {
        "id": 180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82628200795752,
            21.01263810450031,
            105.82648124041613,
            21.012962653285236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82628200795752,
              21.01263810450031
            ],
            [
              105.82637340594692,
              21.012789907545258
            ],
            [
              105.82638296168983,
              21.012805454433085
            ],
            [
              105.82638463310236,
              21.012808174961837
            ],
            [
              105.82639588839929,
              21.012826486498955
            ],
            [
              105.82648124041613,
              21.012962653285236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Tây Sơn",
          "maTaiSan": "04.O12.DODV.181",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01263810450031,
          "to_latitude": 21.012962653285236,
          "to_longitude": 105.82648124041613,
          "from_longitude": 105.82628200795752
        }
      },
      {
        "id": 181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82651933786916,
            21.01303206993408,
            105.82676375131737,
            21.013433121598364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82651933786916,
              21.01303206993408
            ],
            [
              105.82662212124166,
              21.013198111071965
            ],
            [
              105.82662999031716,
              21.01321130329842
            ],
            [
              105.8266511072024,
              21.01324670291154
            ],
            [
              105.82672296978075,
              21.01336717831483
            ],
            [
              105.82674076335694,
              21.013397009415478
            ],
            [
              105.82676375131737,
              21.013433121598364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Tây Sơn",
          "maTaiSan": "04.O12.DODV.182",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01303206993408,
          "to_latitude": 21.013433121598364,
          "to_longitude": 105.82676375131737,
          "from_longitude": 105.82651933786916
        }
      },
      {
        "id": 182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82677641328836,
            21.013443274655092,
            105.82707955070366,
            21.013930659814548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82677641328836,
              21.013443274655092
            ],
            [
              105.8269106540164,
              21.013660602360027
            ],
            [
              105.82692450721308,
              21.013683030780314
            ],
            [
              105.82695459663685,
              21.013731087906486
            ],
            [
              105.82707955070366,
              21.013930659814548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.183",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013443274655092,
          "to_latitude": 21.013930659814548,
          "to_longitude": 105.82707955070366,
          "from_longitude": 105.82677641328836
        }
      },
      {
        "id": 183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82751197907655,
            21.014662816613335,
            105.82771821127322,
            21.015028339741733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751197907655,
              21.014662816613335
            ],
            [
              105.82759605912698,
              21.0148084872632
            ],
            [
              105.82761419434111,
              21.014840548467046
            ],
            [
              105.82763487369051,
              21.014877108905527
            ],
            [
              105.82767688810702,
              21.01495138632561
            ],
            [
              105.82771821127322,
              21.015028339741733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.184",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014662816613335,
          "to_latitude": 21.015028339741733,
          "to_longitude": 105.82771821127322,
          "from_longitude": 105.82751197907655
        }
      },
      {
        "id": 184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278065876577,
            21.01514767569066,
            105.82806277051834,
            21.015580229344646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278065876577,
              21.01514767569066
            ],
            [
              105.82787196923508,
              21.01526250167783
            ],
            [
              105.8279529350902,
              21.01540779708232
            ],
            [
              105.82795884801249,
              21.015418409208934
            ],
            [
              105.82800299849032,
              21.015478092657087
            ],
            [
              105.82800527271966,
              21.015481790233476
            ],
            [
              105.82806277051834,
              21.015580229344646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.185",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01514767569066,
          "to_latitude": 21.015580229344646,
          "to_longitude": 105.82806277051834,
          "from_longitude": 105.8278065876577
        }
      },
      {
        "id": 185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807356081402,
            21.015595780952346,
            105.82845247726125,
            21.016204579051855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807356081402,
              21.015595780952346
            ],
            [
              105.828160864193,
              21.015734609911732
            ],
            [
              105.8282742700238,
              21.01594731030666
            ],
            [
              105.82827889872355,
              21.01595599215681
            ],
            [
              105.82837749666062,
              21.016082629356283
            ],
            [
              105.82845247726125,
              21.016204579051855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.186",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015595780952346,
          "to_latitude": 21.016204579051855,
          "to_longitude": 105.82845247726125,
          "from_longitude": 105.82807356081402
        }
      },
      {
        "id": 186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82881684778802,
            21.0168035196939,
            105.8291019264937,
            21.01727849434638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881684778802,
              21.0168035196939
            ],
            [
              105.82890235095084,
              21.01694671595046
            ],
            [
              105.82896931332894,
              21.017058862906257
            ],
            [
              105.82897482333344,
              21.017087052817928
            ],
            [
              105.82898635341006,
              21.017105533527225
            ],
            [
              105.82900082423573,
              21.017128726483644
            ],
            [
              105.8290205296077,
              21.01714262774068
            ],
            [
              105.8291019264937,
              21.01727849434638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.187",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0168035196939,
          "to_latitude": 21.01727849434638,
          "to_longitude": 105.8291019264937,
          "from_longitude": 105.82881684778802
        }
      },
      {
        "id": 187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82846301969927,
            21.016224004869542,
            105.82879234243556,
            21.016756553937338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82846301969927,
              21.016224004869542
            ],
            [
              105.82858004265877,
              21.016422318899746
            ],
            [
              105.82857591430971,
              21.016424638618826
            ],
            [
              105.8286330831659,
              21.01651215369208
            ],
            [
              105.82864728758324,
              21.01653119765975
            ],
            [
              105.82867588831573,
              21.01656954297884
            ],
            [
              105.82879234243556,
              21.016756553937338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.188",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016224004869542,
          "to_latitude": 21.016756553937338,
          "to_longitude": 105.82879234243556,
          "from_longitude": 105.82846301969927
        }
      },
      {
        "id": 188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82493674339484,
            21.012315988343765,
            105.82505061818979,
            21.012385931761752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82493674339484,
              21.012385931761752
            ],
            [
              105.82505061818979,
              21.012315988343765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.189",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012385931761752,
          "to_latitude": 21.012315988343765,
          "to_longitude": 105.82505061818979,
          "from_longitude": 105.82493674339484
        }
      },
      {
        "id": 189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82433140421877,
            21.012765530922554,
            105.82447690224444,
            21.012915677987177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82433140421877,
              21.012765530922554
            ],
            [
              105.82434209874731,
              21.01277321524549
            ],
            [
              105.82434718749317,
              21.01277710065921
            ],
            [
              105.82447627366847,
              21.012875700966372
            ],
            [
              105.82447690224444,
              21.012889433287285
            ],
            [
              105.82445516662654,
              21.012915677987177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.190",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012765530922554,
          "to_latitude": 21.012915677987177,
          "to_longitude": 105.82445516662654,
          "from_longitude": 105.82433140421877
        }
      },
      {
        "id": 190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82402633085347,
            21.01310571866505,
            105.82425268716007,
            21.013268991432582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82402633085347,
              21.01310571866505
            ],
            [
              105.8240355876155,
              21.013113807389626
            ],
            [
              105.82403802874698,
              21.013115517116894
            ],
            [
              105.82420313403958,
              21.01323123996454
            ],
            [
              105.82425268716007,
              21.013268991432582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.191",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01310571866505,
          "to_latitude": 21.013268991432582,
          "to_longitude": 105.82425268716007,
          "from_longitude": 105.82402633085347
        }
      },
      {
        "id": 191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82420313403958,
            21.01313633086292,
            105.82427936246704,
            21.01323123996454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82420313403958,
              21.01323123996454
            ],
            [
              105.82427936246704,
              21.01313633086292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.192",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01323123996454,
          "to_latitude": 21.01313633086292,
          "to_longitude": 105.82427936246704,
          "from_longitude": 105.82420313403958
        }
      },
      {
        "id": 192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82454930327751,
            21.012367143127367,
            105.82471309622932,
            21.012546436205092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82454930327751,
              21.012546436205092
            ],
            [
              105.82460993271721,
              21.01247725780477
            ],
            [
              105.82462206146926,
              21.012463419398667
            ],
            [
              105.82471309622932,
              21.012367143127367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.193",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012546436205092,
          "to_latitude": 21.012367143127367,
          "to_longitude": 105.82471309622932,
          "from_longitude": 105.82454930327751
        }
      },
      {
        "id": 193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82434718749317,
            21.01257762032188,
            105.82452540073089,
            21.01277710065921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82452540073089,
              21.01257762032188
            ],
            [
              105.82434718749317,
              21.01277710065921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.194",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01257762032188,
          "to_latitude": 21.01277710065921,
          "to_longitude": 105.82434718749317,
          "from_longitude": 105.82452540073089
        }
      },
      {
        "id": 194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82391388587038,
            21.012823119770346,
            105.82429318035788,
            21.0132601748348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82429318035788,
              21.012823119770346
            ],
            [
              105.82424340780855,
              21.012875310873287
            ],
            [
              105.82418411035924,
              21.012944149406373
            ],
            [
              105.82418128015898,
              21.012947434602605
            ],
            [
              105.82409658204944,
              21.013045147654488
            ],
            [
              105.8240529119224,
              21.01309963856881
            ],
            [
              105.82404353331516,
              21.013109644774616
            ],
            [
              105.82403802874698,
              21.013115517116894
            ],
            [
              105.82401466971417,
              21.01314043736603
            ],
            [
              105.82391388587038,
              21.0132601748348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.195",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012823119770346,
          "to_latitude": 21.0132601748348,
          "to_longitude": 105.82391388587038,
          "from_longitude": 105.82429318035788
        }
      },
      {
        "id": 195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245965884615,
            21.01246683691779,
            105.82460993271721,
            21.01247725780477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8245965884615,
              21.01246683691779
            ],
            [
              105.82460317220108,
              21.012471979596167
            ],
            [
              105.82460975303074,
              21.012477117772242
            ],
            [
              105.82460993271721,
              21.01247725780477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.196",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01246683691779,
          "to_latitude": 21.01247725780477,
          "to_longitude": 105.82460993271721,
          "from_longitude": 105.8245965884615
        }
      },
      {
        "id": 196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82617752431699,
            21.01289952501126,
            105.82624400747555,
            21.012991102255334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82621361721014,
              21.01289952501126
            ],
            [
              105.82623913930148,
              21.012946057230984
            ],
            [
              105.82624358090054,
              21.012954165602114
            ],
            [
              105.82624400747555,
              21.01295494482136
            ],
            [
              105.82617752431699,
              21.012991102255334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.197",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01289952501126,
          "to_latitude": 21.012991102255334,
          "to_longitude": 105.82617752431699,
          "from_longitude": 105.82621361721014
        }
      },
      {
        "id": 197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82610282329001,
            21.013021922135497,
            105.82620431137855,
            21.013181650098748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82620431137855,
              21.013021922135497
            ],
            [
              105.82619364827363,
              21.013027823207036
            ],
            [
              105.82616089141439,
              21.01304658782263
            ],
            [
              105.82615561432877,
              21.013067201585987
            ],
            [
              105.82613359121052,
              21.013081364526965
            ],
            [
              105.82611112714314,
              21.01315816654278
            ],
            [
              105.82610388551949,
              21.01317663293867
            ],
            [
              105.82610282329001,
              21.013181650098748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.198",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013021922135497,
          "to_latitude": 21.013181650098748,
          "to_longitude": 105.82610282329001,
          "from_longitude": 105.82620431137855
        }
      },
      {
        "id": 198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82619546677452,
            21.013039086640788,
            105.82634161353913,
            21.01331711253717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82619546677452,
              21.013039086640788
            ],
            [
              105.82624256057561,
              21.013105461861375
            ],
            [
              105.82624551581824,
              21.013108994479975
            ],
            [
              105.82630509519372,
              21.013180218987443
            ],
            [
              105.8263169209546,
              21.0131955660792
            ],
            [
              105.82627903315509,
              21.01322061318782
            ],
            [
              105.82634161353913,
              21.01331711253717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.199",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013039086640788,
          "to_latitude": 21.01331711253717,
          "to_longitude": 105.82634161353913,
          "from_longitude": 105.82619546677452
        }
      },
      {
        "id": 199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82624358090054,
            21.012950010438793,
            105.82625145923387,
            21.012954165602114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624358090054,
              21.012954165602114
            ],
            [
              105.82624541284746,
              21.012953199286176
            ],
            [
              105.82624937695374,
              21.012951108938243
            ],
            [
              105.82625145923387,
              21.012950010438793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.200",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012954165602114,
          "to_latitude": 21.012950010438793,
          "to_longitude": 105.82625145923387,
          "from_longitude": 105.82624358090054
        }
      },
      {
        "id": 200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82599351989631,
            21.01333692176228,
            105.82633740458331,
            21.01349119253997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82633740458331,
              21.01333692176228
            ],
            [
              105.82630492671883,
              21.013355003115546
            ],
            [
              105.82628430019679,
              21.01336198948393
            ],
            [
              105.82619885280614,
              21.013402037092394
            ],
            [
              105.82617389296827,
              21.013413943588894
            ],
            [
              105.8260928339116,
              21.01345313525576
            ],
            [
              105.82599351989631,
              21.01349119253997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.201",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01333692176228,
          "to_latitude": 21.01349119253997,
          "to_longitude": 105.82599351989631,
          "from_longitude": 105.82633740458331
        }
      },
      {
        "id": 201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82546260266537,
            21.01350651243504,
            105.82595736855181,
            21.013747408662375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82595736855181,
              21.01350651243504
            ],
            [
              105.82593936557288,
              21.01351514615643
            ],
            [
              105.82590929613018,
              21.013527609419256
            ],
            [
              105.82588534263732,
              21.013539555247547
            ],
            [
              105.82586583073028,
              21.013549299125092
            ],
            [
              105.82585177295559,
              21.013550310328625
            ],
            [
              105.82584411971652,
              21.01355115405741
            ],
            [
              105.82582569910561,
              21.013558713148438
            ],
            [
              105.82566236286634,
              21.013628893511502
            ],
            [
              105.82565996301628,
              21.01363031960983
            ],
            [
              105.82565165702763,
              21.0136352563065
            ],
            [
              105.82558073381135,
              21.013678520873736
            ],
            [
              105.825559910286,
              21.013688353275057
            ],
            [
              105.82546260266537,
              21.013747408662375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.202",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01350651243504,
          "to_latitude": 21.013747408662375,
          "to_longitude": 105.82546260266537,
          "from_longitude": 105.82595736855181
        }
      },
      {
        "id": 202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547306774691,
            21.013408332782028,
            105.82566344289746,
            21.013657957572516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547306774691,
              21.013408332782028
            ],
            [
              105.82555127421483,
              21.013553781938995
            ],
            [
              105.82554761622417,
              21.013566055566137
            ],
            [
              105.82561005075561,
              21.013657957572516
            ],
            [
              105.82565938519019,
              21.013629149122906
            ],
            [
              105.82565996301628,
              21.01363031960983
            ],
            [
              105.82566097375955,
              21.013632373157698
            ],
            [
              105.82566344289746,
              21.0136365449198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT117_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.203",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013408332782028,
          "to_latitude": 21.0136365449198,
          "to_longitude": 105.82566344289746,
          "from_longitude": 105.82547306774691
        }
      },
      {
        "id": 203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547992201488,
            21.013554223874905,
            105.825559910286,
            21.013688353275057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547992201488,
              21.013554223874905
            ],
            [
              105.82552756601429,
              21.013643868251027
            ],
            [
              105.825559910286,
              21.013688353275057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.204",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013554223874905,
          "to_latitude": 21.013688353275057,
          "to_longitude": 105.825559910286,
          "from_longitude": 105.82547992201488
        }
      },
      {
        "id": 204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82524191595553,
            21.013548307081322,
            105.8254555490602,
            21.013762178971394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254555490602,
              21.013754787017024
            ],
            [
              105.82545465302773,
              21.01375347626666
            ],
            [
              105.82545377248506,
              21.013752183505655
            ],
            [
              105.8254357277059,
              21.01376144782993
            ],
            [
              105.82542595759573,
              21.013762178971394
            ],
            [
              105.8253894435313,
              21.013704606529306
            ],
            [
              105.82524191595553,
              21.013548307081322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.205",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013754787017024,
          "to_latitude": 21.013548307081322,
          "to_longitude": 105.82524191595553,
          "from_longitude": 105.8254555490602
        }
      },
      {
        "id": 205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254191669438,
            21.013762492277458,
            105.82554863422928,
            21.01399242222232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82543603453591,
              21.013762492277458
            ],
            [
              105.82543635299376,
              21.0137632024748
            ],
            [
              105.82543800168433,
              21.013766864284836
            ],
            [
              105.8254191669438,
              21.013786578252482
            ],
            [
              105.82546336350094,
              21.013861025423857
            ],
            [
              105.82554863422928,
              21.01399242222232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.206",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013762492277458,
          "to_latitude": 21.01399242222232,
          "to_longitude": 105.82554863422928,
          "from_longitude": 105.82543603453591
        }
      },
      {
        "id": 206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613115927927,
            21.013445191572302,
            105.82626701352139,
            21.013750663214033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613130176257,
              21.013445191572302
            ],
            [
              105.82613190924434,
              21.01344650642977
            ],
            [
              105.82613452696654,
              21.013452155141525
            ],
            [
              105.82613115927927,
              21.013460890336376
            ],
            [
              105.82622216519705,
              21.013612171470925
            ],
            [
              105.82626701352139,
              21.013688053196244
            ],
            [
              105.8261404937967,
              21.013750663214033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.207",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013445191572302,
          "to_latitude": 21.013750663214033,
          "to_longitude": 105.8261404937967,
          "from_longitude": 105.82613130176257
        }
      },
      {
        "id": 207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82597606991644,
            21.01350207713004,
            105.82613161202873,
            21.013805468310114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82597606991644,
              21.01350207713004
            ],
            [
              105.82597663723445,
              21.013502736440795
            ],
            [
              105.8259943893307,
              21.013523310658154
            ],
            [
              105.82613161202873,
              21.01375742002071
            ],
            [
              105.8260415190387,
              21.013805468310114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.208",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01350207713004,
          "to_latitude": 21.013805468310114,
          "to_longitude": 105.8260415190387,
          "from_longitude": 105.82597606991644
        }
      },
      {
        "id": 208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82631906871505,
            21.014718791482707,
            105.82661765272317,
            21.015132879205737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82661765272317,
              21.015132879205737
            ],
            [
              105.82660974091901,
              21.01512504422544
            ],
            [
              105.82659719862959,
              21.01511394128996
            ],
            [
              105.82657017741792,
              21.015111587797062
            ],
            [
              105.82646470083908,
              21.01491876558203
            ],
            [
              105.82642603074886,
              21.01490001819588
            ],
            [
              105.82631906871505,
              21.014718791482707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.209",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015132879205737,
          "to_latitude": 21.014718791482707,
          "to_longitude": 105.82631906871505,
          "from_longitude": 105.82661765272317
        }
      },
      {
        "id": 209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268533855102,
            21.016946261156704,
            105.82731374261961,
            21.017699682365098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8268533855102,
              21.016946261156704
            ],
            [
              105.8269411826833,
              21.01709618897462
            ],
            [
              105.82694063440128,
              21.0170965429946
            ],
            [
              105.82694015968345,
              21.01709697975376
            ],
            [
              105.82693977193198,
              21.017097487444996
            ],
            [
              105.82693948356219,
              21.017098049749936
            ],
            [
              105.82693930217984,
              21.0170986503735
            ],
            [
              105.82693924170081,
              21.017099196215838
            ],
            [
              105.82693923345683,
              21.01709927122365
            ],
            [
              105.8269392801794,
              21.017099894222298
            ],
            [
              105.82693944031907,
              21.017100500411555
            ],
            [
              105.8269397089667,
              21.017101071750794
            ],
            [
              105.82694007832725,
              21.017101590213375
            ],
            [
              105.82694053774492,
              21.017102042302717
            ],
            [
              105.8269410736681,
              21.01710241272986
            ],
            [
              105.82694166872253,
              21.017102690740533
            ],
            [
              105.82694230555369,
              21.017102869193295
            ],
            [
              105.82694296585031,
              21.017102941854585
            ],
            [
              105.82694363035893,
              21.017102906108313
            ],
            [
              105.82694427792721,
              21.01710276386374
            ],
            [
              105.82694488933677,
              21.0171025188272
            ],
            [
              105.82704747839121,
              21.01727770744965
            ],
            [
              105.82708460912129,
              21.017341114922377
            ],
            [
              105.82712808307532,
              21.01741535463951
            ],
            [
              105.82716010612667,
              21.01746641544501
            ],
            [
              105.82718426147896,
              21.017504930760314
            ],
            [
              105.82731374261961,
              21.017699682365098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.210",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 97.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016946261156704,
          "to_latitude": 21.017699682365098,
          "to_longitude": 105.82731374261961,
          "from_longitude": 105.8268533855102
        }
      },
      {
        "id": 210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82721653755567,
            21.017058921754607,
            105.82754661883058,
            21.017597579889465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754661883058,
              21.017597579889465
            ],
            [
              105.82754304767563,
              21.017591755198595
            ],
            [
              105.82721653755567,
              21.017058921754607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT96_Ngách 64/43, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.211",
          "diaChiLapD": "Ngách 64/43, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017597579889465,
          "to_latitude": 21.017058921754607,
          "to_longitude": 105.82721653755567,
          "from_longitude": 105.82754661883058
        }
      },
      {
        "id": 211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827376057034,
            21.016991398533115,
            105.82769855700107,
            21.0175121714358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.827376057034,
              21.016991398533115
            ],
            [
              105.82769685362646,
              21.01750942578877
            ],
            [
              105.82769855700107,
              21.0175121714358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_Ngách 64/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.212",
          "diaChiLapD": "Ngách 64/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016991398533115,
          "to_latitude": 21.0175121714358,
          "to_longitude": 105.82769855700107,
          "from_longitude": 105.827376057034
        }
      },
      {
        "id": 212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270588693923,
            21.01713214963544,
            105.82737926588639,
            21.017711059526217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270588693923,
              21.01713214963544
            ],
            [
              105.82723205287546,
              21.01742431851324
            ],
            [
              105.82737926588639,
              21.017653989255038
            ],
            [
              105.82733878862174,
              21.017688289205903
            ],
            [
              105.82732449734306,
              21.01770039961005
            ],
            [
              105.82732914848663,
              21.017708266665686
            ],
            [
              105.82733080209599,
              21.017711059526217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT98_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.213",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01713214963544,
          "to_latitude": 21.017711059526217,
          "to_longitude": 105.82733080209599,
          "from_longitude": 105.8270588693923
        }
      },
      {
        "id": 213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82769769373306,
            21.015814655060947,
            105.82786311178587,
            21.01590475553162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82769769373306,
              21.01590475553162
            ],
            [
              105.82786254390356,
              21.015814942340445
            ],
            [
              105.82786311178587,
              21.015814655060947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.214",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01590475553162,
          "to_latitude": 21.015814655060947,
          "to_longitude": 105.82786311178587,
          "from_longitude": 105.82769769373306
        }
      },
      {
        "id": 214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82776341366457,
            21.015912383219362,
            105.82792620996486,
            21.01601687292998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82776341366457,
              21.01601687292998
            ],
            [
              105.82791496398083,
              21.015934874073952
            ],
            [
              105.82791664387123,
              21.015918728868606
            ],
            [
              105.82792544705332,
              21.01591288912557
            ],
            [
              105.82792620996486,
              21.015912383219362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.215",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01601687292998,
          "to_latitude": 21.015912383219362,
          "to_longitude": 105.82792620996486,
          "from_longitude": 105.82776341366457
        }
      },
      {
        "id": 215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82783834599084,
            21.016057562126612,
            105.82801180157531,
            21.016144811533437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82783834599084,
              21.016144811533437
            ],
            [
              105.8280114744649,
              21.01605772720251
            ],
            [
              105.82801180157531,
              21.016057562126612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.216",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016144811533437,
          "to_latitude": 21.016057562126612,
          "to_longitude": 105.82801180157531,
          "from_longitude": 105.82783834599084
        }
      },
      {
        "id": 216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82628787150644,
            21.013345640340333,
            105.82648976913767,
            21.01367715112249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82633429750008,
              21.013345640340333
            ],
            [
              105.82633755565439,
              21.013351599357996
            ],
            [
              105.8263410116167,
              21.013356971221068
            ],
            [
              105.82635269909315,
              21.013352275488014
            ],
            [
              105.82648976913767,
              21.013565242577904
            ],
            [
              105.82628787150644,
              21.01367715112249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.217",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013345640340333,
          "to_latitude": 21.01367715112249,
          "to_longitude": 105.82628787150644,
          "from_longitude": 105.82633429750008
        }
      },
      {
        "id": 217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82652981592324,
            21.013575879528933,
            105.82679995822522,
            21.014002443268204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82652981592324,
              21.013575879528933
            ],
            [
              105.8266696376146,
              21.01379095476645
            ],
            [
              105.82668896495282,
              21.013820685385493
            ],
            [
              105.8267158274553,
              21.013862005715225
            ],
            [
              105.82679995822522,
              21.014002443268204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.218",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013575879528933,
          "to_latitude": 21.014002443268204,
          "to_longitude": 105.82679995822522,
          "from_longitude": 105.82652981592324
        }
      },
      {
        "id": 218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82713166071505,
            21.014734448332042,
            105.82743039257315,
            21.015252359655864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82713166071505,
              21.014734448332042
            ],
            [
              105.82717758156012,
              21.014814712442394
            ],
            [
              105.82720911048439,
              21.014873607543485
            ],
            [
              105.82725620028414,
              21.014961567752657
            ],
            [
              105.827286005387,
              21.01500684063672
            ],
            [
              105.82729913613021,
              21.015026785950045
            ],
            [
              105.82743039257315,
              21.015252359655864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.219",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014734448332042,
          "to_latitude": 21.015252359655864,
          "to_longitude": 105.82743039257315,
          "from_longitude": 105.82713166071505
        }
      },
      {
        "id": 219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82745723321787,
            21.015308987826447,
            105.82757001668784,
            21.015639422977472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82745723321787,
              21.015308987826447
            ],
            [
              105.82753281963848,
              21.01546842160451
            ],
            [
              105.82754948138377,
              21.015502776042577
            ],
            [
              105.82755506450015,
              21.01551428940094
            ],
            [
              105.82756755011685,
              21.015540033828092
            ],
            [
              105.82757001668784,
              21.0155451196362
            ],
            [
              105.8275614245501,
              21.015639422977472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.220",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015308987826447,
          "to_latitude": 21.015639422977472,
          "to_longitude": 105.8275614245501,
          "from_longitude": 105.82745723321787
        }
      },
      {
        "id": 220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82649702343551,
            21.01321130329842,
            105.82662999031716,
            21.013282828121675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82649702343551,
              21.013282828121675
            ],
            [
              105.82649883236373,
              21.013281873656737
            ],
            [
              105.82662999031716,
              21.01321130329842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Tây Sơn",
          "maTaiSan": "04.O12.DODV.221",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013282828121675,
          "to_latitude": 21.01321130329842,
          "to_longitude": 105.82662999031716,
          "from_longitude": 105.82649702343551
        }
      },
      {
        "id": 221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82683433329466,
            21.013941905513665,
            105.82709709188603,
            21.014052286942228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82709709188603,
              21.013941905513665
            ],
            [
              105.82709328567361,
              21.01394384879134
            ],
            [
              105.82694470507982,
              21.01401968871218
            ],
            [
              105.82688077997189,
              21.014050190635576
            ],
            [
              105.82683433329466,
              21.014052286942228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Ngõ 2, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.222",
          "diaChiLapD": "Ngõ 2, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013941905513665,
          "to_latitude": 21.014052286942228,
          "to_longitude": 105.82683433329466,
          "from_longitude": 105.82709709188603
        }
      },
      {
        "id": 222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612942107407,
            21.014065318847294,
            105.82681608616903,
            21.014466092890295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82681608616903,
              21.014065318847294
            ],
            [
              105.82679656824146,
              21.01406838621334
            ],
            [
              105.8267621668336,
              21.014073801128028
            ],
            [
              105.826552180729,
              21.014175562713927
            ],
            [
              105.82654883157917,
              21.01417718582369
            ],
            [
              105.82652759984921,
              21.014187479165027
            ],
            [
              105.82656369853194,
              21.014266280078427
            ],
            [
              105.82650059105666,
              21.0142966396974
            ],
            [
              105.82619527170944,
              21.014443342409944
            ],
            [
              105.82612942107407,
              21.014466092890295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Ngõ 2, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.223",
          "diaChiLapD": "Ngõ 2, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014065318847294,
          "to_latitude": 21.014466092890295,
          "to_longitude": 105.82612942107407,
          "from_longitude": 105.82681608616903
        }
      },
      {
        "id": 223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82745502711515,
            21.015060423464107,
            105.8277057234895,
            21.015217196488347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277057234895,
              21.015060423464107
            ],
            [
              105.8276504806496,
              21.015109426667394
            ],
            [
              105.8275974824907,
              21.015138649127493
            ],
            [
              105.82753741922821,
              21.015171767227454
            ],
            [
              105.82749171723236,
              21.015196966424167
            ],
            [
              105.82745502711515,
              21.015217196488347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.224",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015060423464107,
          "to_latitude": 21.015217196488347,
          "to_longitude": 105.82745502711515,
          "from_longitude": 105.8277057234895
        }
      },
      {
        "id": 224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746155155886,
            21.015135076699313,
            105.82775811453504,
            21.015279905532307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746155155886,
              21.015279905532307
            ],
            [
              105.82752809008106,
              21.01524459544579
            ],
            [
              105.82755618746128,
              21.01524445877641
            ],
            [
              105.8275569728734,
              21.015244021407597
            ],
            [
              105.8276192880653,
              21.015209336990765
            ],
            [
              105.82775811453504,
              21.015135076699313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.225",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015279905532307,
          "to_latitude": 21.015135076699313,
          "to_longitude": 105.82775811453504,
          "from_longitude": 105.82746155155886
        }
      },
      {
        "id": 225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82392505605642,
            21.01438207391486,
            105.8242556086446,
            21.01467163716699
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392505605642,
              21.01467163716699
            ],
            [
              105.82392507712413,
              21.014671619903673
            ],
            [
              105.82392869657855,
              21.014667537856226
            ],
            [
              105.82397073470712,
              21.014620212040043
            ],
            [
              105.8242556086446,
              21.01438207391486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT142_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.226",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01467163716699,
          "to_latitude": 21.01438207391486,
          "to_longitude": 105.8242556086446,
          "from_longitude": 105.82392505605642
        }
      },
      {
        "id": 226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82415773932543,
            21.014819802136774,
            105.82458081180614,
            21.015045863990178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82415773932543,
              21.015045863990178
            ],
            [
              105.82417724588115,
              21.015035440198073
            ],
            [
              105.82458081180614,
              21.014819802136774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT144_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.227",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015045863990178,
          "to_latitude": 21.014819802136774,
          "to_longitude": 105.82458081180614,
          "from_longitude": 105.82415773932543
        }
      },
      {
        "id": 227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82438170596275,
            21.014489152853397,
            105.8246987588662,
            21.015157934382405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82438170596275,
              21.014489152853397
            ],
            [
              105.82458081180614,
              21.014819802136774
            ],
            [
              105.82459051306401,
              21.014835930095806
            ],
            [
              105.82460280917276,
              21.014856351157615
            ],
            [
              105.8246987588662,
              21.01502338760184
            ],
            [
              105.82443926525507,
              21.015157934382405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT144_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.228",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 98.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014489152853397,
          "to_latitude": 21.015157934382405,
          "to_longitude": 105.82443926525507,
          "from_longitude": 105.82438170596275
        }
      },
      {
        "id": 228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270296936576,
            21.01408889662752,
            105.82718569079553,
            21.014199634496745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718569079553,
              21.01408889662752
            ],
            [
              105.82717088157979,
              21.01409720966071
            ],
            [
              105.82716166597675,
              21.01410238298156
            ],
            [
              105.82711611537981,
              21.014127953466073
            ],
            [
              105.82712787247057,
              21.01415192663754
            ],
            [
              105.8270296936576,
              21.014199634496745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.229",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01408889662752,
          "to_latitude": 21.014199634496745,
          "to_longitude": 105.8270296936576,
          "from_longitude": 105.82718569079553
        }
      },
      {
        "id": 229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82820158108656,
            21.0166021766826,
            105.8284439294236,
            21.0167511808269
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82820158108656,
              21.0166021766826
            ],
            [
              105.82828823532716,
              21.0167511808269
            ],
            [
              105.82844265666441,
              21.016669035645336
            ],
            [
              105.8284439294236,
              21.01666835835081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 92, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.230",
          "diaChiLapD": "Ngõ 92, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0166021766826,
          "to_latitude": 21.01666835835081,
          "to_longitude": 105.8284439294236,
          "from_longitude": 105.82820158108656
        }
      },
      {
        "id": 230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833799569288,
            21.01675467104479,
            105.8284968793038,
            21.016899524548535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284968793038,
              21.01675467104479
            ],
            [
              105.82849477515458,
              21.016755822065853
            ],
            [
              105.82833799569288,
              21.016841563679744
            ],
            [
              105.82837138846133,
              21.016899524548535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 92, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.231",
          "diaChiLapD": "Ngõ 92, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01675467104479,
          "to_latitude": 21.016899524548535,
          "to_longitude": 105.82837138846133,
          "from_longitude": 105.8284968793038
        }
      },
      {
        "id": 231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833541876543,
            21.016781601136955,
            105.82882481662487,
            21.01700757733508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833541876543,
              21.01700757733508
            ],
            [
              105.82842827022031,
              21.016956295286203
            ],
            [
              105.82844213321239,
              21.016975870222353
            ],
            [
              105.82857869732038,
              21.016901770193925
            ],
            [
              105.82864755104026,
              21.01687156877203
            ],
            [
              105.82881356744264,
              21.016787310133502
            ],
            [
              105.82882481662487,
              21.016781601136955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Ngõ 92, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.232",
          "diaChiLapD": "Ngõ 92, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01700757733508,
          "to_latitude": 21.016781601136955,
          "to_longitude": 105.82882481662487,
          "from_longitude": 105.82833541876543
        }
      },
      {
        "id": 232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82851074609692,
            21.01651215369208,
            105.8286330831659,
            21.0165739755711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8286330831659,
              21.01651215369208
            ],
            [
              105.82851074609692,
              21.0165739755711
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.233",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01651215369208,
          "to_latitude": 21.0165739755711,
          "to_longitude": 105.82851074609692,
          "from_longitude": 105.8286330831659
        }
      },
      {
        "id": 233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82849290916712,
            21.016424638618826,
            105.82857591430971,
            21.01647321550036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82857591430971,
              21.016424638618826
            ],
            [
              105.82857158104329,
              21.01642712823902
            ],
            [
              105.82849290916712,
              21.01647321550036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.234",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016424638618826,
          "to_latitude": 21.01647321550036,
          "to_longitude": 105.82849290916712,
          "from_longitude": 105.82857591430971
        }
      },
      {
        "id": 234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278930437265,
            21.016152741704435,
            105.82806888241247,
            21.016247256573084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278930437265,
              21.016247256573084
            ],
            [
              105.82806846616784,
              21.01615296592398
            ],
            [
              105.82806888241247,
              21.016152741704435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.235",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016247256573084,
          "to_latitude": 21.016152741704435,
          "to_longitude": 105.82806888241247,
          "from_longitude": 105.8278930437265
        }
      },
      {
        "id": 235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82798358966555,
            21.01629436155202,
            105.8281137077497,
            21.016380962086483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281137077497,
              21.01629436155202
            ],
            [
              105.82811196280181,
              21.01629540965856
            ],
            [
              105.8281089478279,
              21.016297281366345
            ],
            [
              105.82811136471952,
              21.01630904405089
            ],
            [
              105.82798358966555,
              21.016380962086483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.236",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629436155202,
          "to_latitude": 21.016380962086483,
          "to_longitude": 105.82798358966555,
          "from_longitude": 105.8281137077497
        }
      },
      {
        "id": 236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8281126538905,
            21.016298347934633,
            105.82838425193998,
            21.01650537818771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82811621349352,
              21.016298347934633
            ],
            [
              105.82811418728214,
              21.01629932786194
            ],
            [
              105.8281126538905,
              21.016300188874226
            ],
            [
              105.82812229545344,
              21.01632231884042
            ],
            [
              105.8281354433747,
              21.01635404476779
            ],
            [
              105.82814570760685,
              21.016373961508222
            ],
            [
              105.82815691416933,
              21.016391719467617
            ],
            [
              105.82819834423692,
              21.01637345598032
            ],
            [
              105.8282086473558,
              21.016367662210772
            ],
            [
              105.82822033688682,
              21.016388045297845
            ],
            [
              105.82825467606212,
              21.016447923653292
            ],
            [
              105.82828762533924,
              21.01650537818771
            ],
            [
              105.82838425193998,
              21.016463988882034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.237",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016298347934633,
          "to_latitude": 21.016463988882034,
          "to_longitude": 105.82838425193998,
          "from_longitude": 105.82811621349352
        }
      },
      {
        "id": 237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82825467606212,
            21.016405522673633,
            105.82835020620581,
            21.016447923653292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82825467606212,
              21.016447923653292
            ],
            [
              105.82825477873784,
              21.016447877992135
            ],
            [
              105.82835020620581,
              21.016405522673633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.238",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016447923653292,
          "to_latitude": 21.016405522673633,
          "to_longitude": 105.82835020620581,
          "from_longitude": 105.82825467606212
        }
      },
      {
        "id": 238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82822033688682,
            21.01634635668831,
            105.82831574203716,
            21.016388045297845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82822033688682,
              21.016388045297845
            ],
            [
              105.8282205480048,
              21.01638795304427
            ],
            [
              105.82831574203716,
              21.01634635668831
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.239",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016388045297845,
          "to_latitude": 21.01634635668831,
          "to_longitude": 105.82831574203716,
          "from_longitude": 105.82822033688682
        }
      },
      {
        "id": 239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570534124378,
            21.013782773541415,
            105.82587268780719,
            21.01395002372166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82587268780719,
              21.01395002372166
            ],
            [
              105.82575946777065,
              21.01380407267122
            ],
            [
              105.82573798470341,
              21.013795618866393
            ],
            [
              105.82570534124378,
              21.013782773541415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63A_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.240",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01395002372166,
          "to_latitude": 21.013782773541415,
          "to_longitude": 105.82570534124378,
          "from_longitude": 105.82587268780719
        }
      },
      {
        "id": 240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82559951339209,
            21.014536135224592,
            105.82564520311863,
            21.014613540379468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82559951339209,
              21.014536135224592
            ],
            [
              105.82564520311863,
              21.014613540379468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.241",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014536135224592,
          "to_latitude": 21.014613540379468,
          "to_longitude": 105.82564520311863,
          "from_longitude": 105.82559951339209
        }
      },
      {
        "id": 241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545229818706,
            21.01391178590801,
            105.8257014245062,
            21.014026167685568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82545229818706,
              21.01398037182132
            ],
            [
              105.82548235745239,
              21.014026167685568
            ],
            [
              105.82551493324722,
              21.014009580848235
            ],
            [
              105.82554863422928,
              21.01399242222232
            ],
            [
              105.8255497402355,
              21.013991859565166
            ],
            [
              105.8255697429723,
              21.01398232108257
            ],
            [
              105.82557825506318,
              21.013977775389616
            ],
            [
              105.8257014245062,
              21.01391178590801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.242",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01398037182132,
          "to_latitude": 21.01391178590801,
          "to_longitude": 105.8257014245062,
          "from_longitude": 105.82545229818706
        }
      },
      {
        "id": 242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82557825506318,
            21.013977775389616,
            105.82569705505917,
            21.014154630462237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82557825506318,
              21.013977775389616
            ],
            [
              105.82569705505917,
              21.014154630462237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.243",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013977775389616,
          "to_latitude": 21.014154630462237,
          "to_longitude": 105.82569705505917,
          "from_longitude": 105.82557825506318
        }
      },
      {
        "id": 243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545488386285,
            21.014009580848235,
            105.82562378019635,
            21.014272478015766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551493324722,
              21.014009580848235
            ],
            [
              105.82562378019635,
              21.01418033329401
            ],
            [
              105.82550805297743,
              21.014240734483227
            ],
            [
              105.82545488386285,
              21.014272478015766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.244",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014009580848235,
          "to_latitude": 21.014272478015766,
          "to_longitude": 105.82545488386285,
          "from_longitude": 105.82551493324722
        }
      },
      {
        "id": 244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587604369387,
            21.013543113697764,
            105.82602515433783,
            21.013797183885714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82588093064062,
              21.013543113697764
            ],
            [
              105.82588103593851,
              21.013543719251487
            ],
            [
              105.82588383072698,
              21.01355969098419
            ],
            [
              105.82588430446562,
              21.013562397460248
            ],
            [
              105.82587604369387,
              21.013566459625068
            ],
            [
              105.82602515433783,
              21.013797183885714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.245",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013543113697764,
          "to_latitude": 21.013797183885714,
          "to_longitude": 105.82602515433783,
          "from_longitude": 105.82588093064062
        }
      },
      {
        "id": 245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82586718481969,
            21.013544210795384,
            105.82601535466631,
            21.013801630174036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82587857804093,
              21.013544210795384
            ],
            [
              105.8258786174225,
              21.01354472453998
            ],
            [
              105.8258797899773,
              21.013559744920812
            ],
            [
              105.82587994832113,
              21.013561773701667
            ],
            [
              105.82586718481969,
              21.01356776533637
            ],
            [
              105.82601535466631,
              21.013801630174036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.246",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013544210795384,
          "to_latitude": 21.013801630174036,
          "to_longitude": 105.82601535466631,
          "from_longitude": 105.82587857804093
        }
      },
      {
        "id": 246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590784212871,
            21.014771700671275,
            105.82601955780208,
            21.014992900960763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590784212871,
              21.014774778592578
            ],
            [
              105.82592490333494,
              21.014771700671275
            ],
            [
              105.82599089940334,
              21.014936219046863
            ],
            [
              105.82601955780208,
              21.014992900960763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.247",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014774778592578,
          "to_latitude": 21.014992900960763,
          "to_longitude": 105.82601955780208,
          "from_longitude": 105.82590784212871
        }
      },
      {
        "id": 247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268480366144,
            21.014095685391702,
            105.82708448983763,
            21.0146469346698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8268480366144,
              21.014095685391702
            ],
            [
              105.82695082855645,
              21.014290121858014
            ],
            [
              105.82697083214308,
              21.014335885027773
            ],
            [
              105.82700146837682,
              21.014405977291414
            ],
            [
              105.82703591772461,
              21.014484791530535
            ],
            [
              105.82705696748457,
              21.014530471021537
            ],
            [
              105.82707628088905,
              21.014589743468875
            ],
            [
              105.82707712321347,
              21.014595615762282
            ],
            [
              105.82708448983763,
              21.0146469346698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Ngách 180A/3, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.248",
          "diaChiLapD": "Ngách 180A/3, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014095685391702,
          "to_latitude": 21.0146469346698,
          "to_longitude": 105.82708448983763,
          "from_longitude": 105.8268480366144
        }
      },
      {
        "id": 248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82688199239544,
            21.01456801687419,
            105.82707712321347,
            21.014623630188797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82688199239544,
              21.01456801687419
            ],
            [
              105.82692474125767,
              21.014611232597126
            ],
            [
              105.8269663852464,
              21.014623630188797
            ],
            [
              105.82705269921203,
              21.01460179423544
            ],
            [
              105.8270641235286,
              21.014598904376808
            ],
            [
              105.82707712321347,
              21.014595615762282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Ngách 180A/3, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.249",
          "diaChiLapD": "Ngách 180A/3, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01456801687419,
          "to_latitude": 21.014595615762282,
          "to_longitude": 105.82707712321347,
          "from_longitude": 105.82688199239544
        }
      },
      {
        "id": 249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82717573133911,
            21.014873607543485,
            105.82720911048439,
            21.01489121383544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82720911048439,
              21.014873607543485
            ],
            [
              105.82719002833062,
              21.014883672839286
            ],
            [
              105.82718002158902,
              21.01488895116981
            ],
            [
              105.82717573133911,
              21.01489121383544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.250",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014873607543485,
          "to_latitude": 21.01489121383544,
          "to_longitude": 105.82717573133911,
          "from_longitude": 105.82720911048439
        }
      },
      {
        "id": 250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82601863289156,
            21.01455383277611,
            105.82626952877644,
            21.014747353640686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82626952877644,
              21.014747353640686
            ],
            [
              105.82626870963352,
              21.01474603348945
            ],
            [
              105.82625769678826,
              21.014728278967848
            ],
            [
              105.82618813259779,
              21.014616131720345
            ],
            [
              105.82608866137629,
              21.01467119036176
            ],
            [
              105.82601863289156,
              21.01455383277611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.251",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014747353640686,
          "to_latitude": 21.01455383277611,
          "to_longitude": 105.82601863289156,
          "from_longitude": 105.82626952877644
        }
      },
      {
        "id": 251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578851504806,
            21.014544603397376,
            105.82595584899997,
            21.014630362718382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578851504806,
              21.014544603397376
            ],
            [
              105.82584680022914,
              21.014630362718382
            ],
            [
              105.82595584899997,
              21.01457015268642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.252",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014544603397376,
          "to_latitude": 21.01457015268642,
          "to_longitude": 105.82595584899997,
          "from_longitude": 105.82578851504806
        }
      },
      {
        "id": 252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82611925805077,
            21.014728278967848,
            105.82625769678826,
            21.014815552557256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82625769678826,
              21.014728278967848
            ],
            [
              105.82611925805077,
              21.014815552557256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.253",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014728278967848,
          "to_latitude": 21.014815552557256,
          "to_longitude": 105.82611925805077,
          "from_longitude": 105.82625769678826
        }
      },
      {
        "id": 253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82756755011685,
            21.01548666987266,
            105.82769204585401,
            21.015540033828092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82756755011685,
              21.015540033828092
            ],
            [
              105.82769204585401,
              21.01548666987266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.254",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015540033828092,
          "to_latitude": 21.01548666987266,
          "to_longitude": 105.82769204585401,
          "from_longitude": 105.82756755011685
        }
      },
      {
        "id": 254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82741705379763,
            21.01506688387287,
            105.82749171723236,
            21.015196966424167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749171723236,
              21.015196966424167
            ],
            [
              105.82741705379763,
              21.01506688387287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.255",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015196966424167,
          "to_latitude": 21.01506688387287,
          "to_longitude": 105.82741705379763,
          "from_longitude": 105.82749171723236
        }
      },
      {
        "id": 255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82811459912523,
            21.016252574094647,
            105.8282761817939,
            21.016326918300443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82811737416316,
              21.01630019299413
            ],
            [
              105.82811550725687,
              21.016301453049163
            ],
            [
              105.82811459912523,
              21.01630213940501
            ],
            [
              105.82812985477992,
              21.016326918300443
            ],
            [
              105.82815845593063,
              21.01631345288298
            ],
            [
              105.82816742622599,
              21.016309479281105
            ],
            [
              105.82823761283483,
              21.016272973347856
            ],
            [
              105.82824641481243,
              21.016268307787325
            ],
            [
              105.82825965183609,
              21.016261293021852
            ],
            [
              105.8282761817939,
              21.016252574094647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.256",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01630019299413,
          "to_latitude": 21.016252574094647,
          "to_longitude": 105.8282761817939,
          "from_longitude": 105.82811737416316
        }
      },
      {
        "id": 256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781568292421,
            21.015736487662785,
            105.82807004734525,
            21.016148389712452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807004734525,
              21.016148389712452
            ],
            [
              105.82798724625266,
              21.016012976482312
            ],
            [
              105.82794580011192,
              21.015940659104505
            ],
            [
              105.82793960679261,
              21.01592979092389
            ],
            [
              105.82791836536212,
              21.01589251881691
            ],
            [
              105.8278825147166,
              21.015841432510744
            ],
            [
              105.82784969519706,
              21.015788957640158
            ],
            [
              105.82781933868054,
              21.015741787164306
            ],
            [
              105.82781568292421,
              21.015736487662785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.257",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016148389712452,
          "to_latitude": 21.015736487662785,
          "to_longitude": 105.82781568292421,
          "from_longitude": 105.82807004734525
        }
      },
      {
        "id": 257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817921102269,
            21.016153564009777,
            105.82824641481243,
            21.016268307787325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82824641481243,
              21.016268307787325
            ],
            [
              105.82817921102269,
              21.016153564009777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.258",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016268307787325,
          "to_latitude": 21.016153564009777,
          "to_longitude": 105.82817921102269,
          "from_longitude": 105.82824641481243
        }
      },
      {
        "id": 258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82805307186213,
            21.01618374149309,
            105.8281255779923,
            21.016299914608485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82805307186213,
              21.01618374149309
            ],
            [
              105.8280673142428,
              21.016206544553203
            ],
            [
              105.82807682534224,
              21.016221772707304
            ],
            [
              105.82809018857014,
              21.016243191786636
            ],
            [
              105.8281255779923,
              21.016299914608485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.259",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01618374149309,
          "to_latitude": 21.016299914608485,
          "to_longitude": 105.8281255779923,
          "from_longitude": 105.82805307186213
        }
      },
      {
        "id": 259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859902665558,
            21.01720884189814,
            105.82878737392139,
            21.017412827412432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859902665558,
              21.01720884189814
            ],
            [
              105.82866594641318,
              21.01730023486701
            ],
            [
              105.82873344879455,
              21.01738546226108
            ],
            [
              105.82876035660041,
              21.017406034909055
            ],
            [
              105.82877644295047,
              21.01741102453808
            ],
            [
              105.82878737392139,
              21.017412827412432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A90_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.260",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01720884189814,
          "to_latitude": 21.017412827412432,
          "to_longitude": 105.82878737392139,
          "from_longitude": 105.82859902665558
        }
      },
      {
        "id": 260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82876291740416,
            21.017265392045065,
            105.82880186385768,
            21.01742835686408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82876291740416,
              21.017265392045065
            ],
            [
              105.8287986378611,
              21.017369742878184
            ],
            [
              105.82880186385768,
              21.017379422382685
            ],
            [
              105.82878737392139,
              21.017412827412432
            ],
            [
              105.82878077720345,
              21.0174280319307
            ],
            [
              105.8287806385544,
              21.01742835686408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A90_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.261",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017265392045065,
          "to_latitude": 21.01742835686408,
          "to_longitude": 105.8287806385544,
          "from_longitude": 105.82876291740416
        }
      },
      {
        "id": 261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82758762488169,
            21.016678770579166,
            105.82803395231367,
            21.01694436184019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82758762488169,
              21.01694436184019
            ],
            [
              105.8275917708598,
              21.01694157057485
            ],
            [
              105.82760257762,
              21.01693429490695
            ],
            [
              105.82759251431519,
              21.016898486707827
            ],
            [
              105.82763104667374,
              21.016872629580863
            ],
            [
              105.82779333217677,
              21.016789395362736
            ],
            [
              105.82782114216474,
              21.016775130881495
            ],
            [
              105.8278309570059,
              21.016770097316186
            ],
            [
              105.82803395231367,
              21.016678770579166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.262",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01694436184019,
          "to_latitude": 21.016678770579166,
          "to_longitude": 105.82803395231367,
          "from_longitude": 105.82758762488169
        }
      },
      {
        "id": 262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82779333217677,
            21.016789395362736,
            105.82795536637963,
            21.017072441869228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779333217677,
              21.016789395362736
            ],
            [
              105.82795536637963,
              21.017072441869228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.263",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016789395362736,
          "to_latitude": 21.017072441869228,
          "to_longitude": 105.82795536637963,
          "from_longitude": 105.82779333217677
        }
      },
      {
        "id": 263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82796888464246,
            21.017295443768305,
            105.8282954659068,
            21.017413805268447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82796888464246,
              21.017413805268447
            ],
            [
              105.8280971196477,
              21.017372592849366
            ],
            [
              105.82815795880255,
              21.017350767402856
            ],
            [
              105.8281692777558,
              21.017346706495047
            ],
            [
              105.82820159599999,
              21.01733357793846
            ],
            [
              105.8282954659068,
              21.017295443768305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.264",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017413805268447,
          "to_latitude": 21.017295443768305,
          "to_longitude": 105.8282954659068,
          "from_longitude": 105.82796888464246
        }
      },
      {
        "id": 264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82755124507854,
            21.016919090431166,
            105.8278403278883,
            21.017403187560273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82755124507854,
              21.016919090431166
            ],
            [
              105.82769962768784,
              21.017167571215307
            ],
            [
              105.82772450559499,
              21.017209232484742
            ],
            [
              105.8278403278883,
              21.017403187560273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.265",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016919090431166,
          "to_latitude": 21.017403187560273,
          "to_longitude": 105.8278403278883,
          "from_longitude": 105.82755124507854
        }
      },
      {
        "id": 265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82761349369866,
            21.016929223480343,
            105.82788586090297,
            21.017392577077054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82761349369866,
              21.016929223480343
            ],
            [
              105.82772316231913,
              21.017142227810435
            ],
            [
              105.82773855396462,
              21.01717212104286
            ],
            [
              105.82777636327657,
              21.01723921563576
            ],
            [
              105.8277949788126,
              21.017253840404774
            ],
            [
              105.82780720238105,
              21.017263442728435
            ],
            [
              105.82788586090297,
              21.017392577077054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.266",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016929223480343,
          "to_latitude": 21.017392577077054,
          "to_longitude": 105.82788586090297,
          "from_longitude": 105.82761349369866
        }
      },
      {
        "id": 266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82771424629367,
            21.017142227810435,
            105.82772316231913,
            21.017146808093784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82771424629367,
              21.017146808093784
            ],
            [
              105.82771513166023,
              21.017146353076477
            ],
            [
              105.8277216294649,
              21.01714301384908
            ],
            [
              105.8277219488876,
              21.017142849714293
            ],
            [
              105.82772203425941,
              21.017142805944086
            ],
            [
              105.82772316231913,
              21.017142227810435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.267",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017146808093784,
          "to_latitude": 21.017142227810435,
          "to_longitude": 105.82772316231913,
          "from_longitude": 105.82771424629367
        }
      },
      {
        "id": 267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82769962768784,
            21.017156350454805,
            105.82772134522196,
            21.017167571215307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82772134522196,
              21.017156350454805
            ],
            [
              105.82772084163362,
              21.017156610324328
            ],
            [
              105.82771392379252,
              21.01716018462758
            ],
            [
              105.82769962768784,
              21.017167571215307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.268",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017156350454805,
          "to_latitude": 21.017167571215307,
          "to_longitude": 105.82769962768784,
          "from_longitude": 105.82772134522196
        }
      },
      {
        "id": 268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827776006833,
            21.016739716027224,
            105.82820355860207,
            21.01726648484715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.827776006833,
              21.01726648484715
            ],
            [
              105.82778299043231,
              21.01726332118898
            ],
            [
              105.82779447920527,
              21.017257784512267
            ],
            [
              105.82779423165165,
              21.01725720132991
            ],
            [
              105.82779409454967,
              21.017256586900437
            ],
            [
              105.82779407281352,
              21.01725596016766
            ],
            [
              105.82779416749993,
              21.01725533828771
            ],
            [
              105.82779437487581,
              21.017254742052966
            ],
            [
              105.8277946892594,
              21.017254187749135
            ],
            [
              105.8277949788126,
              21.017253840404774
            ],
            [
              105.82779510113112,
              21.017253693487017
            ],
            [
              105.82779559710384,
              21.017253273783407
            ],
            [
              105.82779616377553,
              21.01725294044542
            ],
            [
              105.82779678292935,
              21.017252704400413
            ],
            [
              105.82779743632376,
              21.01725257205975
            ],
            [
              105.82779810475016,
              21.017252548936206
            ],
            [
              105.82779876703638,
              21.017252633326382
            ],
            [
              105.82779940298192,
              21.017252825328583
            ],
            [
              105.82779999522745,
              21.017253116898246
            ],
            [
              105.82780052449453,
              21.017253500903358
            ],
            [
              105.82780097436078,
              21.01725396477869
            ],
            [
              105.8278013322464,
              21.01725449503708
            ],
            [
              105.82804185132295,
              21.017139024010778
            ],
            [
              105.82819326567916,
              21.01706306542041
            ],
            [
              105.82820355860207,
              21.017017266034866
            ],
            [
              105.8280459523591,
              21.016739716027224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.269",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01726648484715,
          "to_latitude": 21.016739716027224,
          "to_longitude": 105.8280459523591,
          "from_longitude": 105.827776006833
        }
      },
      {
        "id": 269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82619152187681,
            21.017493513207224,
            105.82666344015588,
            21.01804221450634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82619152187681,
              21.017493513207224
            ],
            [
              105.82633801351936,
              21.0176759831063
            ],
            [
              105.82635291153501,
              21.017694539769995
            ],
            [
              105.82639839986798,
              21.017751201248625
            ],
            [
              105.8265920143832,
              21.01795813165864
            ],
            [
              105.82666344015588,
              21.01804221450634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Ngách 64/65, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.270",
          "diaChiLapD": "Ngách 64/65, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017493513207224,
          "to_latitude": 21.01804221450634,
          "to_longitude": 105.82666344015588,
          "from_longitude": 105.82619152187681
        }
      },
      {
        "id": 270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8266919560008,
            21.01779009768958,
            105.82714941573447,
            21.018037272577256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266919560008,
              21.018037272577256
            ],
            [
              105.82682067347059,
              21.017967723661652
            ],
            [
              105.82691477611604,
              21.017916878912573
            ],
            [
              105.82691805813903,
              21.01791510528166
            ],
            [
              105.82714941573447,
              21.01779009768958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.271",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018037272577256,
          "to_latitude": 21.01779009768958,
          "to_longitude": 105.82714941573447,
          "from_longitude": 105.8266919560008
        }
      },
      {
        "id": 271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82671124550275,
            21.01779983641471,
            105.82682067347059,
            21.017967723661652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82671124550275,
              21.01779983641471
            ],
            [
              105.82682067347059,
              21.017967723661652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.272",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01779983641471,
          "to_latitude": 21.017967723661652,
          "to_longitude": 105.82682067347059,
          "from_longitude": 105.82671124550275
        }
      },
      {
        "id": 272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82634124439292,
            21.017694539769995,
            105.82635291153501,
            21.017705256314528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82635291153501,
              21.017694539769995
            ],
            [
              105.82635203386334,
              21.017695345194646
            ],
            [
              105.82634759097353,
              21.017699426776208
            ],
            [
              105.82634124439292,
              21.017705256314528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Ngách 64/65, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.273",
          "diaChiLapD": "Ngách 64/65, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017694539769995,
          "to_latitude": 21.017705256314528,
          "to_longitude": 105.82634124439292,
          "from_longitude": 105.82635291153501
        }
      },
      {
        "id": 273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570123433521,
            21.015722482239838,
            105.82583430283387,
            21.01624945944081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82580104350725,
              21.015722482239838
            ],
            [
              105.82583430283387,
              21.015800108431204
            ],
            [
              105.8258059594454,
              21.015927157403286
            ],
            [
              105.82570123433521,
              21.01619719468958
            ],
            [
              105.82572352232742,
              21.016237696459317
            ],
            [
              105.82572998015274,
              21.01624945944081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT114_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.274",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015722482239838,
          "to_latitude": 21.01624945944081,
          "to_longitude": 105.82572998015274,
          "from_longitude": 105.82580104350725
        }
      },
      {
        "id": 274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564690399823,
            21.015734084468612,
            105.82579926038468,
            21.016270778209545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257740018696,
              21.015734084468612
            ],
            [
              105.82579926038468,
              21.015814617244324
            ],
            [
              105.82570691640362,
              21.016058255461818
            ],
            [
              105.82564690399823,
              21.016216617603554
            ],
            [
              105.82567678832548,
              21.01625622535159
            ],
            [
              105.82568725378333,
              21.01627011856221
            ],
            [
              105.82568775185302,
              21.016270778209545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.275",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015734084468612,
          "to_latitude": 21.016270778209545,
          "to_longitude": 105.82568775185302,
          "from_longitude": 105.8257740018696
        }
      },
      {
        "id": 275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367419561395,
            21.014414818980413,
            105.82397073470712,
            21.014620212040043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367419561395,
              21.014414818980413
            ],
            [
              105.82396798852712,
              21.014618310499646
            ],
            [
              105.82397073470712,
              21.014620212040043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT142_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.276",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014414818980413,
          "to_latitude": 21.014620212040043,
          "to_longitude": 105.82397073470712,
          "from_longitude": 105.82367419561395
        }
      },
      {
        "id": 276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82400725654341,
            21.01466594525928,
            105.82419932575118,
            21.014975673323438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82400725654341,
              21.01466594525928
            ],
            [
              105.82410400195302,
              21.01482195433205
            ],
            [
              105.82419932575118,
              21.014975673323438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT143_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.277",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01466594525928,
          "to_latitude": 21.014975673323438,
          "to_longitude": 105.82419932575118,
          "from_longitude": 105.82400725654341
        }
      },
      {
        "id": 277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82404145178693,
            21.01482195433205,
            105.82410400195302,
            21.0148537410685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82410400195302,
              21.01482195433205
            ],
            [
              105.8240896102469,
              21.01482926791877
            ],
            [
              105.82406537561904,
              21.01484158337708
            ],
            [
              105.82404148056976,
              21.014853727380707
            ],
            [
              105.82404145178693,
              21.0148537410685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT143_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.278",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01482195433205,
          "to_latitude": 21.0148537410685,
          "to_longitude": 105.82404145178693,
          "from_longitude": 105.82410400195302
        }
      },
      {
        "id": 278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82424762741844,
            21.015205953975084,
            105.82433366526921,
            21.01525472324763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82433366526921,
              21.015205953975084
            ],
            [
              105.82433352520258,
              21.015206022395713
            ],
            [
              105.82424864578599,
              21.01525413934576
            ],
            [
              105.82424762741844,
              21.01525472324763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT145_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.279",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015205953975084,
          "to_latitude": 21.01525472324763,
          "to_longitude": 105.82424762741844,
          "from_longitude": 105.82433366526921
        }
      },
      {
        "id": 279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82430643289707,
            21.015054319147758,
            105.82473436642891,
            21.015342667296874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82470311526792,
              21.015054319147758
            ],
            [
              105.82473436642891,
              21.01511132201283
            ],
            [
              105.82439001445638,
              21.015297483059896
            ],
            [
              105.82431002563851,
              21.015340725114157
            ],
            [
              105.82430654032333,
              21.015342610776354
            ],
            [
              105.82430645783724,
              21.015342654530862
            ],
            [
              105.82430643289707,
              21.015342667296874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT146_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.280",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015054319147758,
          "to_latitude": 21.015342667296874,
          "to_longitude": 105.82430643289707,
          "from_longitude": 105.82470311526792
        }
      },
      {
        "id": 280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82603136590492,
            21.0163936041003,
            105.82642151476179,
            21.01679540275725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82603136590492,
              21.01679540275725
            ],
            [
              105.82642151476179,
              21.016585391137458
            ],
            [
              105.82630132331383,
              21.0163936041003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT110_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.281",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01679540275725,
          "to_latitude": 21.0163936041003,
          "to_longitude": 105.82630132331383,
          "from_longitude": 105.82603136590492
        }
      },
      {
        "id": 281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82534582281072,
            21.016043402254887,
            105.82570691640362,
            21.016211012598696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82570691640362,
              21.016058255461818
            ],
            [
              105.82568962077096,
              21.0160528505005
            ],
            [
              105.82565938882539,
              21.016043402254887
            ],
            [
              105.82534582281072,
              21.016211012598696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.282",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016058255461818,
          "to_latitude": 21.016211012598696,
          "to_longitude": 105.82534582281072,
          "from_longitude": 105.82570691640362
        }
      },
      {
        "id": 282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82597610223631,
            21.015761665201516,
            105.82612738542241,
            21.016060492778664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82597610223631,
              21.015761665201516
            ],
            [
              105.82611618939518,
              21.01603778856072
            ],
            [
              105.8261262453077,
              21.016058183346818
            ],
            [
              105.82612738542241,
              21.016060492778664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT112_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.283",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015761665201516,
          "to_latitude": 21.016060492778664,
          "to_longitude": 105.82612738542241,
          "from_longitude": 105.82597610223631
        }
      },
      {
        "id": 283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257596604837,
            21.016008739553985,
            105.82626003286897,
            21.016286604162868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82626003286897,
              21.016008739553985
            ],
            [
              105.82601402627279,
              21.016145351227184
            ],
            [
              105.82600533861486,
              21.016150175989953
            ],
            [
              105.8257596604837,
              21.016286604162868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT113_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.284",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016008739553985,
          "to_latitude": 21.016286604162868,
          "to_longitude": 105.8257596604837,
          "from_longitude": 105.82626003286897
        }
      },
      {
        "id": 284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82599126678896,
            21.01612667011332,
            105.82600533861486,
            21.016150175989953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600533861486,
              21.016150175989953
            ],
            [
              105.82600234055879,
              21.01614516770653
            ],
            [
              105.82599330757674,
              21.016130081625008
            ],
            [
              105.82599126678896,
              21.01612667011332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT113_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.285",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016150175989953,
          "to_latitude": 21.01612667011332,
          "to_longitude": 105.82599126678896,
          "from_longitude": 105.82600533861486
        }
      },
      {
        "id": 285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82643691542785,
            21.016648752586082,
            105.82689151270064,
            21.01698818634932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643691542785,
              21.016648752586082
            ],
            [
              105.82651413825197,
              21.01677946982106
            ],
            [
              105.82662028454762,
              21.01695914763911
            ],
            [
              105.82662743602016,
              21.016970907212247
            ],
            [
              105.82663794336487,
              21.01698818634932
            ],
            [
              105.82684355839363,
              21.016884863374155
            ],
            [
              105.82688057558843,
              21.016857568616594
            ],
            [
              105.82689151270064,
              21.016849503843314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.286",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016648752586082,
          "to_latitude": 21.016849503843314,
          "to_longitude": 105.82689151270064,
          "from_longitude": 105.82643691542785
        }
      },
      {
        "id": 286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591063946187,
            21.016213024628676,
            105.8265218915991,
            21.016566845901508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265218915991,
              21.016213024628676
            ],
            [
              105.82650995578487,
              21.016220872939616
            ],
            [
              105.8264873306736,
              21.01623881072939
            ],
            [
              105.82643652350677,
              21.016279089424557
            ],
            [
              105.82591063946187,
              21.016566845901508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT111_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.287",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016213024628676,
          "to_latitude": 21.016566845901508,
          "to_longitude": 105.82591063946187,
          "from_longitude": 105.8265218915991
        }
      },
      {
        "id": 287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82652649680183,
            21.016295663682442,
            105.82684581572514,
            21.01684972595233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82684581572514,
              21.01684972595233
            ],
            [
              105.8267184352547,
              21.016628682564374
            ],
            [
              105.82668515787327,
              21.016570936659747
            ],
            [
              105.82652649680183,
              21.016295663682442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.288",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01684972595233,
          "to_latitude": 21.016295663682442,
          "to_longitude": 105.82652649680183,
          "from_longitude": 105.82684581572514
        }
      },
      {
        "id": 288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82634014908398,
            21.015997919978275,
            105.8264873306736,
            21.01623881072939
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8264873306736,
              21.01623881072939
            ],
            [
              105.82634014908398,
              21.015997919978275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT111_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.289",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01623881072939,
          "to_latitude": 21.015997919978275,
          "to_longitude": 105.82634014908398,
          "from_longitude": 105.8264873306736
        }
      },
      {
        "id": 289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82633801351936,
            21.01756193171958,
            105.82647442916195,
            21.0176759831063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82633801351936,
              21.0176759831063
            ],
            [
              105.82647442916195,
              21.01761600105949
            ],
            [
              105.82646908095523,
              21.017595620462927
            ],
            [
              105.82644458682297,
              21.01756193171958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Ngách 64/65, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.290",
          "diaChiLapD": "Ngách 64/65, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0176759831063,
          "to_latitude": 21.01756193171958,
          "to_longitude": 105.82644458682297,
          "from_longitude": 105.82633801351936
        }
      },
      {
        "id": 290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82878232169524,
            21.01694671595046,
            105.82890235095084,
            21.017010687299123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890235095084,
              21.01694671595046
            ],
            [
              105.82878232169524,
              21.017010687299123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.291",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01694671595046,
          "to_latitude": 21.017010687299123,
          "to_longitude": 105.82878232169524,
          "from_longitude": 105.82890235095084
        }
      },
      {
        "id": 291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82757770314755,
            21.015692896951865,
            105.82778071657485,
            21.01579639424148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757770314755,
              21.015692896951865
            ],
            [
              105.82764579075621,
              21.01579639424148
            ],
            [
              105.82778071657485,
              21.01572901380023
            ],
            [
              105.82777575196384,
              21.015719767247138
            ],
            [
              105.8277751188453,
              21.01571869187611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.292",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015692896951865,
          "to_latitude": 21.01571869187611,
          "to_longitude": 105.8277751188453,
          "from_longitude": 105.82757770314755
        }
      },
      {
        "id": 292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82805770062267,
            21.01643881155799,
            105.8281597700737,
            21.0166073822878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82805770062267,
              21.01643881155799
            ],
            [
              105.8281597700737,
              21.0166073822878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.293",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01643881155799,
          "to_latitude": 21.0166073822878,
          "to_longitude": 105.8281597700737,
          "from_longitude": 105.82805770062267
        }
      },
      {
        "id": 293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82593812070255,
            21.012669023992217,
            105.82616830723002,
            21.012770318359657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593812070255,
              21.012770318359657
            ],
            [
              105.82604206495965,
              21.01272457747285
            ],
            [
              105.82606429108414,
              21.012714796916207
            ],
            [
              105.82606662961419,
              21.01271376762662
            ],
            [
              105.82616830723002,
              21.012669023992217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.294",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012770318359657,
          "to_latitude": 21.012669023992217,
          "to_longitude": 105.82616830723002,
          "from_longitude": 105.82593812070255
        }
      },
      {
        "id": 294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82518275469548,
            21.01206606156101,
            105.82524015645205,
            21.012189952875175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82518275469548,
              21.01206606156101
            ],
            [
              105.8251837277724,
              21.012067566133496
            ],
            [
              105.82524015645205,
              21.012154795244392
            ],
            [
              105.82523442253182,
              21.012167921645176
            ],
            [
              105.82519685872647,
              21.012189952875175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT09_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.295",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01206606156101,
          "to_latitude": 21.012189952875175,
          "to_longitude": 105.82519685872647,
          "from_longitude": 105.82518275469548
        }
      },
      {
        "id": 295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82501977872728,
            21.012232047329885,
            105.82506041923956,
            21.01225347111755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82506041923956,
              21.012232047329885
            ],
            [
              105.82503218611116,
              21.01224693030628
            ],
            [
              105.82502250459362,
              21.01225203441543
            ],
            [
              105.82501977872728,
              21.01225347111755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT05_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.296",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012232047329885,
          "to_latitude": 21.01225347111755,
          "to_longitude": 105.82501977872728,
          "from_longitude": 105.82506041923956
        }
      },
      {
        "id": 296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82725753907394,
            21.014239416748314,
            105.82750017911509,
            21.014635450261505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82725753907394,
              21.014239416748314
            ],
            [
              105.82729920669117,
              21.01431053105992
            ],
            [
              105.827379845862,
              21.014442838176414
            ],
            [
              105.82741989176115,
              21.014508542790953
            ],
            [
              105.82750017911509,
              21.014635450261505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.297",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014239416748314,
          "to_latitude": 21.014635450261505,
          "to_longitude": 105.82750017911509,
          "from_longitude": 105.82725753907394
        }
      },
      {
        "id": 297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82716166597675,
            21.01410238298156,
            105.82722837579527,
            21.014222759445488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82716166597675,
              21.01410238298156
            ],
            [
              105.82716186809941,
              21.014102753224794
            ],
            [
              105.82722837579527,
              21.014222759445488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.298",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01410238298156,
          "to_latitude": 21.014222759445488,
          "to_longitude": 105.82722837579527,
          "from_longitude": 105.82716166597675
        }
      },
      {
        "id": 298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82600560665996,
            21.0139072959965,
            105.82680642350493,
            21.014143909654862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600560665996,
              21.01408273891888
            ],
            [
              105.82640853839936,
              21.0139072959965
            ],
            [
              105.82654389725948,
              21.014143909654862
            ],
            [
              105.82679616977576,
              21.014034661688008
            ],
            [
              105.82680642350493,
              21.014030222180757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Ngõ 2, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.299",
          "diaChiLapD": "Ngõ 2, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01408273891888,
          "to_latitude": 21.014030222180757,
          "to_longitude": 105.82680642350493,
          "from_longitude": 105.82600560665996
        }
      },
      {
        "id": 299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82651335259888,
            21.013590374091283,
            105.82677799116391,
            21.014011605761233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82651335259888,
              21.013590374091283
            ],
            [
              105.82665550337474,
              21.013827317775714
            ],
            [
              105.82665759753395,
              21.013830467989102
            ],
            [
              105.82677799116391,
              21.014011605761233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.300",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013590374091283,
          "to_latitude": 21.014011605761233,
          "to_longitude": 105.82677799116391,
          "from_longitude": 105.82651335259888
        }
      },
      {
        "id": 300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82665759753395,
            21.01382387794455,
            105.82666877343325,
            21.013830467989102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82665759753395,
              21.013830467989102
            ],
            [
              105.8266623676204,
              21.013827655650516
            ],
            [
              105.82666779448824,
              21.01382445534633
            ],
            [
              105.82666877343325,
              21.01382387794455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.301",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013830467989102,
          "to_latitude": 21.01382387794455,
          "to_longitude": 105.82666877343325,
          "from_longitude": 105.82665759753395
        }
      },
      {
        "id": 301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612478216663,
            21.01491876558203,
            105.82646470083908,
            21.015048351194842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82612478216663,
              21.01502177741387
            ],
            [
              105.82617540757697,
              21.014989600733067
            ],
            [
              105.82622225319996,
              21.015048351194842
            ],
            [
              105.82646470083908,
              21.01491876558203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.302",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01502177741387,
          "to_latitude": 21.01491876558203,
          "to_longitude": 105.82646470083908,
          "from_longitude": 105.82612478216663
        }
      },
      {
        "id": 302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82595711565557,
            21.014718791482707,
            105.82631906871505,
            21.01502781844894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631906871505,
              21.014718791482707
            ],
            [
              105.82626952877644,
              21.014747353640686
            ],
            [
              105.82624575029222,
              21.014761063576618
            ],
            [
              105.8262927098261,
              21.014840152313464
            ],
            [
              105.82601955780208,
              21.014992900960763
            ],
            [
              105.82595711565557,
              21.01502781844894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.303",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014718791482707,
          "to_latitude": 21.01502781844894,
          "to_longitude": 105.82595711565557,
          "from_longitude": 105.82631906871505
        }
      },
      {
        "id": 303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833385270749,
            21.01653860380885,
            105.82882063347446,
            21.016892667211213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82882063347446,
              21.016774178939357
            ],
            [
              105.82880985944801,
              21.016779531556335
            ],
            [
              105.82866190191149,
              21.01685304145681
            ],
            [
              105.82860027716845,
              21.016884451415986
            ],
            [
              105.82858153575508,
              21.016892667211213
            ],
            [
              105.8284968793038,
              21.01675467104479
            ],
            [
              105.8284439294236,
              21.01666835835081
            ],
            [
              105.8283643285506,
              21.01653860380885
            ],
            [
              105.82833385270749,
              21.016556104065533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 92, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.304",
          "diaChiLapD": "Ngõ 92, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016774178939357,
          "to_latitude": 21.016556104065533,
          "to_longitude": 105.82833385270749,
          "from_longitude": 105.82882063347446
        }
      },
      {
        "id": 304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799835299491,
            21.016297241252733,
            105.82814802476963,
            21.016468159893545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82811551863283,
              21.016297241252733
            ],
            [
              105.82811369141893,
              21.01629837285649
            ],
            [
              105.82811089755057,
              21.0163000447784
            ],
            [
              105.828115697359,
              21.01631201534741
            ],
            [
              105.82810994811798,
              21.016321650040585
            ],
            [
              105.82813554065356,
              21.01637340583839
            ],
            [
              105.82814802476963,
              21.016394142740122
            ],
            [
              105.82805770062267,
              21.01643881155799
            ],
            [
              105.82799835299491,
              21.016468159893545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.305",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016297241252733,
          "to_latitude": 21.016468159893545,
          "to_longitude": 105.82799835299491,
          "from_longitude": 105.82811551863283
        }
      },
      {
        "id": 305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589990858948,
            21.01505544585382,
            105.82614218786948,
            21.015518813262002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605481620699,
              21.01550043766771
            ],
            [
              105.82603363971755,
              21.01551398952346
            ],
            [
              105.8260320483975,
              21.015514812862936
            ],
            [
              105.82603502261394,
              21.015518813262002
            ],
            [
              105.8260578575651,
              21.01550562548261
            ],
            [
              105.82614218786948,
              21.015456923212962
            ],
            [
              105.82613851684255,
              21.015451633682993
            ],
            [
              105.8260109399425,
              21.01525791320971
            ],
            [
              105.8259307944041,
              21.015110654646268
            ],
            [
              105.82589990858948,
              21.01505544585382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT116_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.306",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01550043766771,
          "to_latitude": 21.01505544585382,
          "to_longitude": 105.82589990858948,
          "from_longitude": 105.82605481620699
        }
      },
      {
        "id": 306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82696330737532,
            21.014376691564188,
            105.82696903801671,
            21.014379580838458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82696330737532,
              21.014379580838458
            ],
            [
              105.82696594630904,
              21.014378250204974
            ],
            [
              105.82696903801671,
              21.014376691564188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Ngách 180A/3, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.307",
          "diaChiLapD": "Ngách 180A/3, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014379580838458,
          "to_latitude": 21.014376691564188,
          "to_longitude": 105.82696903801671,
          "from_longitude": 105.82696330737532
        }
      },
      {
        "id": 307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82680101803564,
            21.014084950529757,
            105.82704552211223,
            21.014563387826485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82680101803564,
              21.014084950529757
            ],
            [
              105.82693233141408,
              21.014310326605592
            ],
            [
              105.82696330737532,
              21.014379580838458
            ],
            [
              105.82696412839837,
              21.01438141581599
            ],
            [
              105.82704552211223,
              21.014563387826485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Ngách 180A/3, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.308",
          "diaChiLapD": "Ngách 180A/3, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014084950529757,
          "to_latitude": 21.014563387826485,
          "to_longitude": 105.82704552211223,
          "from_longitude": 105.82680101803564
        }
      },
      {
        "id": 308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8260050300416,
            21.013353764281785,
            105.82633590441398,
            21.01350128079549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8260050300416,
              21.01350128079549
            ],
            [
              105.8261234532681,
              21.01345756504142
            ],
            [
              105.82612786241002,
              21.01345541111579
            ],
            [
              105.82613452696654,
              21.013452155141525
            ],
            [
              105.82628388122602,
              21.01337918172028
            ],
            [
              105.82629318780299,
              21.01337463484417
            ],
            [
              105.82633590441398,
              21.013353764281785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.309",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01350128079549,
          "to_latitude": 21.013353764281785,
          "to_longitude": 105.82633590441398,
          "from_longitude": 105.8260050300416
        }
      },
      {
        "id": 309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638036878139,
            21.013372878398037,
            105.82639652143385,
            21.013381429113373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638036878139,
              21.013381429113373
            ],
            [
              105.82638156481019,
              21.013380795562085
            ],
            [
              105.82638170580117,
              21.013380720812748
            ],
            [
              105.8263849869807,
              21.01337898513345
            ],
            [
              105.82639638044287,
              21.013372953147385
            ],
            [
              105.82639652143385,
              21.013372878398037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.310",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013381429113373,
          "to_latitude": 21.013372878398037,
          "to_longitude": 105.82639652143385,
          "from_longitude": 105.82638036878139
        }
      },
      {
        "id": 310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638888137119,
            21.01336126930716,
            105.82651435848983,
            21.01355191344731
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638888137119,
              21.01336126930716
            ],
            [
              105.82639652143385,
              21.013372878398037
            ],
            [
              105.82640638677536,
              21.013387868294522
            ],
            [
              105.82651435848983,
              21.01355191344731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.311",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01336126930716,
          "to_latitude": 21.01355191344731,
          "to_longitude": 105.82651435848983,
          "from_longitude": 105.82638888137119
        }
      },
      {
        "id": 311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245274992895,
            21.01254360367037,
            105.82473759403499,
            21.012852485445332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8245274992895,
              21.01254360367037
            ],
            [
              105.8245441939034,
              21.012553673229718
            ],
            [
              105.82473759403499,
              21.012708808034294
            ],
            [
              105.82470737636177,
              21.012742066787844
            ],
            [
              105.82464057502906,
              21.012815591655265
            ],
            [
              105.82468871803337,
              21.012852485445332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.312",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01254360367037,
          "to_latitude": 21.012852485445332,
          "to_longitude": 105.82468871803337,
          "from_longitude": 105.8245274992895
        }
      },
      {
        "id": 312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82470737636177,
            21.012742066787844,
            105.82492809495034,
            21.01294364020746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82470737636177,
              21.012742066787844
            ],
            [
              105.82492809495034,
              21.01294364020746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.313",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012742066787844,
          "to_latitude": 21.01294364020746,
          "to_longitude": 105.82492809495034,
          "from_longitude": 105.82470737636177
        }
      },
      {
        "id": 313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82429487336454,
            21.01280596832702,
            105.82443754694248,
            21.01300893841188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82429487336454,
              21.01280596832702
            ],
            [
              105.82430729379716,
              21.012814742614356
            ],
            [
              105.82435601591548,
              21.012852182857067
            ],
            [
              105.82432902381112,
              21.012884982434006
            ],
            [
              105.82440829276871,
              21.012949211591124
            ],
            [
              105.82439029861972,
              21.012969784859337
            ],
            [
              105.82443754694248,
              21.01300893841188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.314",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01280596832702,
          "to_latitude": 21.01300893841188,
          "to_longitude": 105.82443754694248,
          "from_longitude": 105.82429487336454
        }
      },
      {
        "id": 314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82691477611604,
            21.017916878912573,
            105.82692421811964,
            21.017931439077266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82691477611604,
              21.017916878912573
            ],
            [
              105.82691499926152,
              21.017917222860063
            ],
            [
              105.82691974259909,
              21.01792454122101
            ],
            [
              105.82692421811964,
              21.017931439077266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.315",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017916878912573,
          "to_latitude": 21.017931439077266,
          "to_longitude": 105.82692421811964,
          "from_longitude": 105.82691477611604
        }
      },
      {
        "id": 315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82815795880255,
            21.017350767402856,
            105.82816517508823,
            21.0173672712349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82815795880255,
              21.017350767402856
            ],
            [
              105.82816358705983,
              21.017363639870183
            ],
            [
              105.82816451067802,
              21.017365757050538
            ],
            [
              105.82816517508823,
              21.0173672712349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.316",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017350767402856,
          "to_latitude": 21.0173672712349,
          "to_longitude": 105.82816517508823,
          "from_longitude": 105.82815795880255
        }
      },
      {
        "id": 316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82898635341006,
            21.017096209468914,
            105.829004530771,
            21.017105533527225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829004530771,
              21.017096209468914
            ],
            [
              105.82899493177898,
              21.017101132759148
            ],
            [
              105.82898635341006,
              21.017105533527225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.317",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017096209468914,
          "to_latitude": 21.017105533527225,
          "to_longitude": 105.82898635341006,
          "from_longitude": 105.829004530771
        }
      },
      {
        "id": 317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806076509657,
            21.016206544553203,
            105.8280673142428,
            21.01621015862328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280673142428,
              21.016206544553203
            ],
            [
              105.8280649032669,
              21.016207874997793
            ],
            [
              105.82806169054784,
              21.01620964740919
            ],
            [
              105.82806076509657,
              21.01621015862328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.318",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016206544553203,
          "to_latitude": 21.01621015862328,
          "to_longitude": 105.82806076509657,
          "from_longitude": 105.8280673142428
        }
      },
      {
        "id": 318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279350346406,
            21.01592979092389,
            105.82793960679261,
            21.015932395496883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279350346406,
              21.015932395496883
            ],
            [
              105.8279362006935,
              21.015931731370678
            ],
            [
              105.82793960679261,
              21.01592979092389
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.319",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015932395496883,
          "to_latitude": 21.01592979092389,
          "to_longitude": 105.82793960679261,
          "from_longitude": 105.8279350346406
        }
      },
      {
        "id": 319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753931496285,
            21.01551428940094,
            105.82755506450015,
            21.01552200820353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753931496285,
              21.01552200820353
            ],
            [
              105.82754341039991,
              21.01552000118583
            ],
            [
              105.82754950990294,
              21.01551701164564
            ],
            [
              105.82755506450015,
              21.01551428940094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.320",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01552200820353,
          "to_latitude": 21.01551428940094,
          "to_longitude": 105.82755506450015,
          "from_longitude": 105.82753931496285
        }
      },
      {
        "id": 320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82698174199574,
            21.014405977291414,
            105.82700146837682,
            21.014412942209994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82698174199574,
              21.014412942209994
            ],
            [
              105.8269831523245,
              21.014412443999888
            ],
            [
              105.82698765309316,
              21.014410855025847
            ],
            [
              105.82700146837682,
              21.014405977291414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Ngách 180A/3, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.321",
          "diaChiLapD": "Ngách 180A/3, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014412942209994,
          "to_latitude": 21.014405977291414,
          "to_longitude": 105.82700146837682,
          "from_longitude": 105.82698174199574
        }
      },
      {
        "id": 321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82566236286634,
            21.013628893511502,
            105.82566544456841,
            21.013635653657218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82566236286634,
              21.013628893511502
            ],
            [
              105.82566346898194,
              21.013631322338682
            ],
            [
              105.82566544456841,
              21.013635653657218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.322",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013628893511502,
          "to_latitude": 21.013635653657218,
          "to_longitude": 105.82566544456841,
          "from_longitude": 105.82566236286634
        }
      },
      {
        "id": 322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82619885280614,
            21.013402037092394,
            105.82620363384765,
            21.013410646009323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82619885280614,
              21.013402037092394
            ],
            [
              105.8261997544115,
              21.013403660327995
            ],
            [
              105.82620225521948,
              21.013408166121977
            ],
            [
              105.82620363384765,
              21.013410646009323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.323",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013402037092394,
          "to_latitude": 21.013410646009323,
          "to_longitude": 105.82620363384765,
          "from_longitude": 105.82619885280614
        }
      },
      {
        "id": 323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82819239320662,
            21.01650537818771,
            105.82828762533924,
            21.016555598713566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82828762533924,
              21.01650537818771
            ],
            [
              105.82819239320662,
              21.016555598713566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.324",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01650537818771,
          "to_latitude": 21.016555598713566,
          "to_longitude": 105.82819239320662,
          "from_longitude": 105.82828762533924
        }
      },
      {
        "id": 324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82864728758324,
            21.016519549380085,
            105.82866607443447,
            21.01653119765975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866607443447,
              21.016519549380085
            ],
            [
              105.8286571531543,
              21.0165250817366
            ],
            [
              105.82864728758324,
              21.01653119765975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.325",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016519549380085,
          "to_latitude": 21.01653119765975,
          "to_longitude": 105.82864728758324,
          "from_longitude": 105.82866607443447
        }
      },
      {
        "id": 325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282742700238,
            21.015929510674383,
            105.82831076872827,
            21.01594731030666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82831076872827,
              21.015929510674383
            ],
            [
              105.82828804785967,
              21.015940685809703
            ],
            [
              105.82828802003901,
              21.01594069949354
            ],
            [
              105.82827730553939,
              21.015945850367007
            ],
            [
              105.8282742700238,
              21.01594731030666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.326",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015929510674383,
          "to_latitude": 21.01594731030666,
          "to_longitude": 105.8282742700238,
          "from_longitude": 105.82831076872827
        }
      },
      {
        "id": 326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268963901819,
            21.013939039640327,
            105.82694470507982,
            21.01401968871218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694470507982,
              21.01401968871218
            ],
            [
              105.8268963901819,
              21.013939039640327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Ngõ 2, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.327",
          "diaChiLapD": "Ngõ 2, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01401968871218,
          "to_latitude": 21.013939039640327,
          "to_longitude": 105.8268963901819,
          "from_longitude": 105.82694470507982
        }
      },
      {
        "id": 327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82763487369051,
            21.014866018764696,
            105.8276557374287,
            21.014877108905527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276557374287,
              21.014866018764696
            ],
            [
              105.82764651069299,
              21.014870923005056
            ],
            [
              105.82763824979153,
              21.014875314030377
            ],
            [
              105.82763487369051,
              21.014877108905527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.328",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014866018764696,
          "to_latitude": 21.014877108905527,
          "to_longitude": 105.82763487369051,
          "from_longitude": 105.8276557374287
        }
      },
      {
        "id": 328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753837767528,
            21.015213504244077,
            105.8275569728734,
            21.015244021407597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753837767528,
              21.015213504244077
            ],
            [
              105.82754046085323,
              21.01521692365975
            ],
            [
              105.82754991280808,
              21.015232437555376
            ],
            [
              105.8275569728734,
              21.015244021407597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.329",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015213504244077,
          "to_latitude": 21.015244021407597,
          "to_longitude": 105.8275569728734,
          "from_longitude": 105.82753837767528
        }
      },
      {
        "id": 329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753741922821,
            21.015171767227454,
            105.82757426977847,
            21.015234667840915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753741922821,
              21.015171767227454
            ],
            [
              105.82755802236399,
              21.01520235410822
            ],
            [
              105.82757426977847,
              21.01522647430273
            ],
            [
              105.82756026591798,
              21.015234667840915
            ],
            [
              105.82755629994085,
              21.015228929067685
            ],
            [
              105.82754388770024,
              21.015210376668378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.330",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015171767227454,
          "to_latitude": 21.015210376668378,
          "to_longitude": 105.82754388770024,
          "from_longitude": 105.82753741922821
        }
      },
      {
        "id": 330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827379845862,
            21.01443524704678,
            105.82739330468303,
            21.014442838176414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82739330468303,
              21.01443524704678
            ],
            [
              105.82739328071011,
              21.01443526071174
            ],
            [
              105.8273867675417,
              21.01443893419282
            ],
            [
              105.827379845862,
              21.014442838176414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.331",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01443524704678,
          "to_latitude": 21.014442838176414,
          "to_longitude": 105.827379845862,
          "from_longitude": 105.82739330468303
        }
      },
      {
        "id": 331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82695459663685,
            21.013721468723986,
            105.82697277210299,
            21.013731087906486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82697277210299,
              21.013721468723986
            ],
            [
              105.82697042223951,
              21.013722712145853
            ],
            [
              105.82696429053838,
              21.013725957433625
            ],
            [
              105.82695459663685,
              21.013731087906486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.332",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013721468723986,
          "to_latitude": 21.013731087906486,
          "to_longitude": 105.82695459663685,
          "from_longitude": 105.82697277210299
        }
      },
      {
        "id": 332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82624551581824,
            21.01310109520379,
            105.82625772142474,
            21.013108994479975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624551581824,
              21.013108994479975
            ],
            [
              105.82624757168344,
              21.013107640495775
            ],
            [
              105.8262522642335,
              21.013104550351716
            ],
            [
              105.82625228723943,
              21.01310453578838
            ],
            [
              105.82625772142474,
              21.01310109520379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.333",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013108994479975,
          "to_latitude": 21.01310109520379,
          "to_longitude": 105.82625772142474,
          "from_longitude": 105.82624551581824
        }
      },
      {
        "id": 333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638463310236,
            21.01279077980111,
            105.826417161755,
            21.012808174961837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638463310236,
              21.012808174961837
            ],
            [
              105.8264029729086,
              21.01279836717208
            ],
            [
              105.8264128861095,
              21.012793065859608
            ],
            [
              105.826417161755,
              21.01279077980111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Tây Sơn",
          "maTaiSan": "04.O12.DODV.334",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012808174961837,
          "to_latitude": 21.01279077980111,
          "to_longitude": 105.826417161755,
          "from_longitude": 105.82638463310236
        }
      },
      {
        "id": 334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8233906733972,
            21.014194712492397,
            105.82342118852335,
            21.0142343474362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82342118852335,
              21.014194712492397
            ],
            [
              105.82340814823179,
              21.01421164245733
            ],
            [
              105.82339449187198,
              21.01422937024228
            ],
            [
              105.8233906733972,
              21.0142343474362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT141_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.335",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014194712492397,
          "to_latitude": 21.0142343474362,
          "to_longitude": 105.8233906733972,
          "from_longitude": 105.82342118852335
        }
      },
      {
        "id": 335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82417234144455,
            21.012936830665417,
            105.82418411035924,
            21.012944149406373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82417234144455,
              21.012936830665417
            ],
            [
              105.82417487417712,
              21.012938405366313
            ],
            [
              105.8241803160918,
              21.01294179139634
            ],
            [
              105.82418411035924,
              21.012944149406373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.336",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012936830665417,
          "to_latitude": 21.012944149406373,
          "to_longitude": 105.82418411035924,
          "from_longitude": 105.82417234144455
        }
      },
      {
        "id": 336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82481758449367,
            21.0122749365648,
            105.82482566255442,
            21.01228646231903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82482566255442,
              21.01228646231903
            ],
            [
              105.82482181645977,
              21.012280974894043
            ],
            [
              105.8248180140081,
              21.012275552289687
            ],
            [
              105.82481758449367,
              21.0122749365648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.337",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01228646231903,
          "to_latitude": 21.0122749365648,
          "to_longitude": 105.82481758449367,
          "from_longitude": 105.82482566255442
        }
      },
      {
        "id": 337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8253612298436,
            21.01278840958115,
            105.82536900701464,
            21.012792771955485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8253612298436,
              21.012792771955485
            ],
            [
              105.82536769995284,
              21.012789143018864
            ],
            [
              105.82536900701464,
              21.01278840958115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.338",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012792771955485,
          "to_latitude": 21.01278840958115,
          "to_longitude": 105.82536900701464,
          "from_longitude": 105.8253612298436
        }
      },
      {
        "id": 338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82606069119696,
            21.012702149353785,
            105.82606662961419,
            21.01271376762662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606069119696,
              21.012702149353785
            ],
            [
              105.82606238222229,
              21.012705463210416
            ],
            [
              105.82606252613925,
              21.012705745221545
            ],
            [
              105.82606544234528,
              21.01271144938942
            ],
            [
              105.82606662961419,
              21.01271376762662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.339",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012702149353785,
          "to_latitude": 21.01271376762662,
          "to_longitude": 105.82606662961419,
          "from_longitude": 105.82606069119696
        }
      },
      {
        "id": 339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82619246992165,
            21.012475083852603,
            105.82622193768519,
            21.012489688498093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82619246992165,
              21.012489688498093
            ],
            [
              105.82620440708095,
              21.012484404467035
            ],
            [
              105.82622193768519,
              21.012475083852603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Tây Sơn",
          "maTaiSan": "04.O12.DODV.340",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012489688498093,
          "to_latitude": 21.012475083852603,
          "to_longitude": 105.82622193768519,
          "from_longitude": 105.82619246992165
        }
      },
      {
        "id": 340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.826024723139,
            21.012195138041328,
            105.82604945476353,
            21.012208626760184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.826024723139,
              21.012208626760184
            ],
            [
              105.82603332857128,
              21.01220393336772
            ],
            [
              105.82604182658946,
              21.0121992983028
            ],
            [
              105.82604945476353,
              21.012195138041328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Tây Sơn",
          "maTaiSan": "04.O12.DODV.341",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012208626760184,
          "to_latitude": 21.012195138041328,
          "to_longitude": 105.82604945476353,
          "from_longitude": 105.826024723139
        }
      },
      {
        "id": 341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82532865028737,
            21.01228916083988,
            105.82534035576424,
            21.012304880718645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82534035576424,
              21.012304880718645
            ],
            [
              105.82533958632813,
              21.01230384935333
            ],
            [
              105.82533481943082,
              21.012297446019158
            ],
            [
              105.82532865028737,
              21.01228916083988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.342",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012304880718645,
          "to_latitude": 21.01228916083988,
          "to_longitude": 105.82532865028737,
          "from_longitude": 105.82534035576424
        }
      },
      {
        "id": 342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82525632311724,
            21.012348848215087,
            105.82526293046573,
            21.012358454498436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82526293046573,
              21.012358454498436
            ],
            [
              105.82526121705529,
              21.012355963581033
            ],
            [
              105.82525772624297,
              21.01235088992744
            ],
            [
              105.82525632311724,
              21.012348848215087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.343",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012358454498436,
          "to_latitude": 21.012348848215087,
          "to_longitude": 105.82525632311724,
          "from_longitude": 105.82526293046573
        }
      },
      {
        "id": 343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82563484276804,
            21.012134810600884,
            105.82564335522837,
            21.012148703370094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82563484276804,
              21.012134810600884
            ],
            [
              105.82563638318082,
              21.012137327644254
            ],
            [
              105.8256408344931,
              21.012144589663166
            ],
            [
              105.82564335522837,
              21.012148703370094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT04_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.344",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012134810600884,
          "to_latitude": 21.012148703370094,
          "to_longitude": 105.82564335522837,
          "from_longitude": 105.82563484276804
        }
      },
      {
        "id": 344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569345824096,
            21.012061261857152,
            105.82571437699532,
            21.012093940238966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82569345824096,
              21.012061261857152
            ],
            [
              105.82570307931125,
              21.012076290921993
            ],
            [
              105.82571228125303,
              21.01209066898742
            ],
            [
              105.82571437699532,
              21.012093940238966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT03_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.345",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012061261857152,
          "to_latitude": 21.012093940238966,
          "to_longitude": 105.82571437699532,
          "from_longitude": 105.82569345824096
        }
      },
      {
        "id": 345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82712777001592,
            21.020254954500352,
            105.82726868357636,
            21.0204706070546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82726868357636,
              21.0204706070546
            ],
            [
              105.8272679275965,
              21.020468886474163
            ],
            [
              105.82725893684727,
              21.02045682065841
            ],
            [
              105.82725635598973,
              21.02045335217981
            ],
            [
              105.82720606340612,
              21.02037123616358
            ],
            [
              105.82712777001592,
              21.020254954500352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6_Ngõ 173, La Thành",
          "maTaiSan": "04.O12.DODV.346",
          "diaChiLapD": "Ngõ 173, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0204706070546,
          "to_latitude": 21.020254954500352,
          "to_longitude": 105.82712777001592,
          "from_longitude": 105.82726868357636
        }
      },
      {
        "id": 346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82747697284321,
            21.0200612565838,
            105.82757655468077,
            21.020302162699974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757655468077,
              21.020302162699974
            ],
            [
              105.82757070119965,
              21.02029084937865
            ],
            [
              105.82756937390329,
              21.020288274418366
            ],
            [
              105.82754990766335,
              21.020245592374803
            ],
            [
              105.82751838355404,
              21.02019757492795
            ],
            [
              105.82752620919577,
              21.020154001396314
            ],
            [
              105.82747697284321,
              21.0200612565838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C8_Ngõ 163, La Thành",
          "maTaiSan": "04.O12.DODV.347",
          "diaChiLapD": "Ngõ 163, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020302162699974,
          "to_latitude": 21.0200612565838,
          "to_longitude": 105.82747697284321,
          "from_longitude": 105.82757655468077
        }
      },
      {
        "id": 347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82755530881158,
            21.02002101886433,
            105.82771247753817,
            21.020226815450453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82771247753817,
              21.020226815450453
            ],
            [
              105.82770585777598,
              21.02021505425005
            ],
            [
              105.82770489560393,
              21.020213971448754
            ],
            [
              105.82770455968655,
              21.020213588309375
            ],
            [
              105.8276609555724,
              21.020164426574922
            ],
            [
              105.82764639058108,
              21.020144758358295
            ],
            [
              105.82762065085736,
              21.020127782832546
            ],
            [
              105.82755530881158,
              21.02002101886433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C9_La Thành",
          "maTaiSan": "04.O12.DODV.348",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020226815450453,
          "to_latitude": 21.02002101886433,
          "to_longitude": 105.82755530881158,
          "from_longitude": 105.82771247753817
        }
      },
      {
        "id": 348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277442558547,
            21.01991585556988,
            105.8278670758707,
            21.020139617210734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278670758707,
              21.020139617210734
            ],
            [
              105.82786181341396,
              21.02012994308671
            ],
            [
              105.82785924091009,
              21.020125549674045
            ],
            [
              105.82777699463728,
              21.019984731569302
            ],
            [
              105.82776035308365,
              21.01994420805966
            ],
            [
              105.8277442558547,
              21.01991585556988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C11_Ngõ 145, La Thành",
          "maTaiSan": "04.O12.DODV.349",
          "diaChiLapD": "Ngõ 145, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020139617210734,
          "to_latitude": 21.01991585556988,
          "to_longitude": 105.8277442558547,
          "from_longitude": 105.8278670758707
        }
      },
      {
        "id": 349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82747287195183,
            21.019301794995513,
            105.82757590735162,
            21.019454836609665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82747808322034,
              21.01931706482703
            ],
            [
              105.82747405274384,
              21.01930907101743
            ],
            [
              105.82747287195183,
              21.019306371601274
            ],
            [
              105.82748029131922,
              21.019301794995513
            ],
            [
              105.82748698513564,
              21.01931216939632
            ],
            [
              105.82753544378414,
              21.0193872743992
            ],
            [
              105.82757590735162,
              21.019454836609665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Ngõ 62, Đông Các",
          "maTaiSan": "04.O12.DODV.350",
          "diaChiLapD": "Ngõ 62, Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01931706482703,
          "to_latitude": 21.019454836609665,
          "to_longitude": 105.82757590735162,
          "from_longitude": 105.82747808322034
        }
      },
      {
        "id": 350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82889114801887,
            21.01928472586592,
            105.829016133143,
            21.019460728015428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829016133143,
              21.019460728015428
            ],
            [
              105.82901349116086,
              21.019456995216707
            ],
            [
              105.82897007638874,
              21.01939548219608
            ],
            [
              105.82889114801887,
              21.01928472586592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C20_La Thành",
          "maTaiSan": "04.O12.DODV.351",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019460728015428,
          "to_latitude": 21.01928472586592,
          "to_longitude": 105.82889114801887,
          "from_longitude": 105.829016133143
        }
      },
      {
        "id": 351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8287544698365,
            21.019348287003055,
            105.8288978360341,
            21.019546738766884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288978360341,
              21.019546738766884
            ],
            [
              105.82888638191338,
              21.019531243754003
            ],
            [
              105.8288860717373,
              21.019530818943156
            ],
            [
              105.82888364773692,
              21.019527538499226
            ],
            [
              105.82882224624595,
              21.01944444651999
            ],
            [
              105.8287667363111,
              21.019366829162767
            ],
            [
              105.8287544698365,
              21.019348287003055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C19_La Thành",
          "maTaiSan": "04.O12.DODV.352",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019546738766884,
          "to_latitude": 21.019348287003055,
          "to_longitude": 105.8287544698365,
          "from_longitude": 105.8288978360341
        }
      },
      {
        "id": 352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865053261129,
            21.019408492930154,
            105.82879331661584,
            21.01960900968218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82879331661584,
              21.01960900968218
            ],
            [
              105.82879123953433,
              21.019603054903385
            ],
            [
              105.82878653698963,
              21.019589567373526
            ],
            [
              105.82878531218013,
              21.01958604444041
            ],
            [
              105.82873424836426,
              21.0195190390822
            ],
            [
              105.82866937552713,
              21.019432343830765
            ],
            [
              105.82865053261129,
              21.019408492930154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C18_La Thành",
          "maTaiSan": "04.O12.DODV.353",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01960900968218,
          "to_latitude": 21.019408492930154,
          "to_longitude": 105.82865053261129,
          "from_longitude": 105.82879331661584
        }
      },
      {
        "id": 353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850735380772,
            21.019489563852424,
            105.82865346710695,
            21.019694614955835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82865346710695,
              21.019694614955835
            ],
            [
              105.82865246429323,
              21.01969314126143
            ],
            [
              105.82864017438715,
              21.019675061659964
            ],
            [
              105.8285695042367,
              21.01957103823784
            ],
            [
              105.82850735380772,
              21.019489563852424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C17_La Thành",
          "maTaiSan": "04.O12.DODV.354",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019694614955835,
          "to_latitude": 21.019489563852424,
          "to_longitude": 105.82850735380772,
          "from_longitude": 105.82865346710695
        }
      },
      {
        "id": 354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82834255860486,
            21.019731036208785,
            105.82840922766466,
            21.01982161393004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840922766466,
              21.01982161393004
            ],
            [
              105.82840322810506,
              21.019813468057013
            ],
            [
              105.82834255860486,
              21.019731036208785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C16_La Thành",
          "maTaiSan": "04.O12.DODV.355",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01982161393004,
          "to_latitude": 21.019731036208785,
          "to_longitude": 105.82834255860486,
          "from_longitude": 105.82840922766466
        }
      },
      {
        "id": 355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82820310565023,
            21.01966556031606,
            105.82834705716563,
            21.01988419136331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82834705716563,
              21.01988419136331
            ],
            [
              105.82834576513991,
              21.01988016656102
            ],
            [
              105.82833960148872,
              21.019860947943766
            ],
            [
              105.82833419137394,
              21.019853454939344
            ],
            [
              105.82827827404856,
              21.019775908955637
            ],
            [
              105.82822727951469,
              21.019704350849484
            ],
            [
              105.82820310565023,
              21.01966556031606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C15_La Thành",
          "maTaiSan": "04.O12.DODV.356",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01988419136331,
          "to_latitude": 21.01966556031606,
          "to_longitude": 105.82820310565023,
          "from_longitude": 105.82834705716563
        }
      },
      {
        "id": 356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818287721717,
            21.01976880622475,
            105.82826649075173,
            21.01991033192286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82826649075173,
              21.01991033192286
            ],
            [
              105.82825850106705,
              21.019896818835363
            ],
            [
              105.82818287721717,
              21.01976880622475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C14_La Thành",
          "maTaiSan": "04.O12.DODV.357",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01991033192286,
          "to_latitude": 21.01976880622475,
          "to_longitude": 105.82818287721717,
          "from_longitude": 105.82826649075173
        }
      },
      {
        "id": 357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279253082591,
            21.019814263011114,
            105.82807531084671,
            21.020019405880383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807531084671,
              21.020019405880383
            ],
            [
              105.82807229806684,
              21.02001437151799
            ],
            [
              105.82806960548989,
              21.02001105533758
            ],
            [
              105.82806316092162,
              21.020008725679656
            ],
            [
              105.82805055031001,
              21.02000415984587
            ],
            [
              105.82798829378838,
              21.019912165923692
            ],
            [
              105.8279253082591,
              21.019814263011114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C13_Ngõ 129, La Thành",
          "maTaiSan": "04.O12.DODV.358",
          "diaChiLapD": "Ngõ 129, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020019405880383,
          "to_latitude": 21.019814263011114,
          "to_longitude": 105.8279253082591,
          "from_longitude": 105.82807531084671
        }
      },
      {
        "id": 358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82728526675545,
            21.0202963332958,
            105.82755835863313,
            21.020460107697218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82728526675545,
              21.020460107697218
            ],
            [
              105.82735663913449,
              21.020420015931645
            ],
            [
              105.82746498727018,
              21.02035528040965
            ],
            [
              105.8274650122159,
              21.02035526854638
            ],
            [
              105.82747146114882,
              21.020351414725017
            ],
            [
              105.82753230443483,
              21.02031284636555
            ],
            [
              105.82753232936555,
              21.02031283179266
            ],
            [
              105.82755835863313,
              21.0202963332958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C7_La Thành",
          "maTaiSan": "04.O12.DODV.359",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020460107697218,
          "to_latitude": 21.0202963332958,
          "to_longitude": 105.82755835863313,
          "from_longitude": 105.82728526675545
        }
      },
      {
        "id": 359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82788290461849,
            21.0200226776071,
            105.82804204873719,
            21.020110094125286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82788290461849,
              21.020110094125286
            ],
            [
              105.82790268603927,
              21.02009868495057
            ],
            [
              105.8279567229522,
              21.0200691321469
            ],
            [
              105.82796951024064,
              21.020062139457202
            ],
            [
              105.82797550584138,
              21.020058942662253
            ],
            [
              105.82801351476269,
              21.020038680755526
            ],
            [
              105.82801924380928,
              21.020035468095312
            ],
            [
              105.82804204873719,
              21.0200226776071
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C12_La Thành",
          "maTaiSan": "04.O12.DODV.360",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020110094125286,
          "to_latitude": 21.0200226776071,
          "to_longitude": 105.82804204873719,
          "from_longitude": 105.82788290461849
        }
      },
      {
        "id": 360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806872174183,
            21.019877670440433,
            105.82832806370118,
            21.020007102609974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806872174183,
              21.020007102609974
            ],
            [
              105.82813448485614,
              21.019974397313536
            ],
            [
              105.82813493377209,
              21.019974164805564
            ],
            [
              105.828156069299,
              21.01996322320301
            ],
            [
              105.82818546852896,
              21.019948003431015
            ],
            [
              105.82819403109434,
              21.019943843034337
            ],
            [
              105.82824568277158,
              21.019918744719714
            ],
            [
              105.82826649075173,
              21.01991033192286
            ],
            [
              105.8282756013762,
              21.019906647562898
            ],
            [
              105.82832806370118,
              21.019877670440433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C14_La Thành",
          "maTaiSan": "04.O12.DODV.361",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020007102609974,
          "to_latitude": 21.019877670440433,
          "to_longitude": 105.82832806370118,
          "from_longitude": 105.82806872174183
        }
      },
      {
        "id": 361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82835660343537,
            21.019686492524972,
            105.82863635314018,
            21.019855059217075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82835660343537,
              21.019855059217075
            ],
            [
              105.82840922766466,
              21.01982161393004
            ],
            [
              105.82848394186865,
              21.019774138725783
            ],
            [
              105.82853834702111,
              21.01974285246654
            ],
            [
              105.82863635314018,
              21.019686492524972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C16_La Thành",
          "maTaiSan": "04.O12.DODV.362",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019855059217075,
          "to_latitude": 21.019686492524972,
          "to_longitude": 105.82863635314018,
          "from_longitude": 105.82835660343537
        }
      },
      {
        "id": 362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82717690773114,
            21.019137401961093,
            105.82776964617086,
            21.01946291203103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82776964617086,
              21.019137401961093
            ],
            [
              105.82770503498118,
              21.019174323181847
            ],
            [
              105.82760793831062,
              21.019227450038134
            ],
            [
              105.82758552477,
              21.01923950514375
            ],
            [
              105.82751348185424,
              21.01927825625577
            ],
            [
              105.8274696308839,
              21.019302147627265
            ],
            [
              105.82746810115647,
              21.019302981519825
            ],
            [
              105.82746277635228,
              21.019305882388544
            ],
            [
              105.82745037930907,
              21.019312637399448
            ],
            [
              105.82739839916773,
              21.019340957971675
            ],
            [
              105.82731421116767,
              21.01938751684662
            ],
            [
              105.82723402166599,
              21.019431400753234
            ],
            [
              105.82717690773114,
              21.01946291203103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A39_Đông Các",
          "maTaiSan": "04.O12.DODV.363",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019137401961093,
          "to_latitude": 21.01946291203103,
          "to_longitude": 105.82717690773114,
          "from_longitude": 105.82776964617086
        }
      },
      {
        "id": 363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82751543769496,
            21.019161808653767,
            105.82780589384774,
            21.019327274719615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751543769496,
              21.019327274719615
            ],
            [
              105.82759625214274,
              21.01928109074099
            ],
            [
              105.82766625451326,
              21.01924230255527
            ],
            [
              105.82766901747422,
              21.01924077169309
            ],
            [
              105.82769041269712,
              21.019228441531673
            ],
            [
              105.82774470142193,
              21.019197153382127
            ],
            [
              105.82780589384774,
              21.019161808653767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A36_Đông Các",
          "maTaiSan": "04.O12.DODV.364",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019327274719615,
          "to_latitude": 21.019161808653767,
          "to_longitude": 105.82780589384774,
          "from_longitude": 105.82751543769496
        }
      },
      {
        "id": 364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746277635228,
            21.019305882388544,
            105.82747185130518,
            21.019320492172994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82747185130518,
              21.019320492172994
            ],
            [
              105.82746714107397,
              21.01931291083099
            ],
            [
              105.82746277635228,
              21.019305882388544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A39_Đông Các",
          "maTaiSan": "04.O12.DODV.365",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019320492172994,
          "to_latitude": 21.019305882388544,
          "to_longitude": 105.82746277635228,
          "from_longitude": 105.82747185130518
        }
      },
      {
        "id": 365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268770549497,
            21.01934315426352,
            105.82748444337268,
            21.01981563615309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82748444337268,
              21.01934315426352
            ],
            [
              105.82742967789697,
              21.019374357038664
            ],
            [
              105.82736857156415,
              21.019408600185205
            ],
            [
              105.82731235146376,
              21.019441704965793
            ],
            [
              105.82728138452387,
              21.019459940855732
            ],
            [
              105.82720725151546,
              21.019498771555558
            ],
            [
              105.82718651170259,
              21.01951030092279
            ],
            [
              105.8271768188675,
              21.01951568973254
            ],
            [
              105.82715980677109,
              21.019525147034827
            ],
            [
              105.82710459392428,
              21.01955584065824
            ],
            [
              105.82708259573839,
              21.01956839677661
            ],
            [
              105.82706059893299,
              21.019579808498868
            ],
            [
              105.82699474169218,
              21.019614124291852
            ],
            [
              105.8269408330856,
              21.0196423547495
            ],
            [
              105.8268770549497,
              21.01967690968099
            ],
            [
              105.82696139249434,
              21.01981563615309
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A41_Đông Các",
          "maTaiSan": "04.O12.DODV.366",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01934315426352,
          "to_latitude": 21.01981563615309,
          "to_longitude": 105.82696139249434,
          "from_longitude": 105.82748444337268
        }
      },
      {
        "id": 366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82888009253234,
            21.019534190011836,
            105.8288916178488,
            21.019550557193735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288916178488,
              21.019550557193735
            ],
            [
              105.828890077205,
              21.019548370767204
            ],
            [
              105.82888348797816,
              21.019539012043587
            ],
            [
              105.82888045323836,
              21.019534706705418
            ],
            [
              105.82888009253234,
              21.019534190011836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C20_La Thành",
          "maTaiSan": "04.O12.DODV.367",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019550557193735,
          "to_latitude": 21.019534190011836,
          "to_longitude": 105.82888009253234,
          "from_longitude": 105.8288916178488
        }
      },
      {
        "id": 367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853834702111,
            21.01974285246654,
            105.8285499647516,
            21.01975888683479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285499647516,
              21.01975888683479
            ],
            [
              105.82854890816967,
              21.019757431466168
            ],
            [
              105.82854169420837,
              21.019747472417798
            ],
            [
              105.82853834702111,
              21.01974285246654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C16_La Thành",
          "maTaiSan": "04.O12.DODV.368",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01975888683479,
          "to_latitude": 21.01974285246654,
          "to_longitude": 105.82853834702111,
          "from_longitude": 105.8285499647516
        }
      },
      {
        "id": 368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82813493377209,
            21.019974164805564,
            105.82814199558585,
            21.019986188481788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82813493377209,
              21.019974164805564
            ],
            [
              105.82813995143445,
              21.01998270927475
            ],
            [
              105.82814199558585,
              21.019986188481788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C14_La Thành",
          "maTaiSan": "04.O12.DODV.369",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019974164805564,
          "to_latitude": 21.019986188481788,
          "to_longitude": 105.82814199558585,
          "from_longitude": 105.82813493377209
        }
      },
      {
        "id": 369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82801924380928,
            21.020035468095312,
            105.82802613726781,
            21.020046097112964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82801924380928,
              21.020035468095312
            ],
            [
              105.82802282687048,
              21.020040995553313
            ],
            [
              105.82802613726781,
              21.020046097112964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C12_La Thành",
          "maTaiSan": "04.O12.DODV.370",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020035468095312,
          "to_latitude": 21.020046097112964,
          "to_longitude": 105.82802613726781,
          "from_longitude": 105.82801924380928
        }
      },
      {
        "id": 370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82770727174652,
            21.020212069435868,
            105.82771565419088,
            21.020225115477462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82771565419088,
              21.020225115477462
            ],
            [
              105.82771432779052,
              21.020223051739492
            ],
            [
              105.82770874953067,
              21.02021437179196
            ],
            [
              105.8277075230878,
              21.020212466535177
            ],
            [
              105.82770727174652,
              21.020212069435868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C10_La Thành",
          "maTaiSan": "04.O12.DODV.371",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020225115477462,
          "to_latitude": 21.020212069435868,
          "to_longitude": 105.82770727174652,
          "from_longitude": 105.82771565419088
        }
      },
      {
        "id": 371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8274650122159,
            21.02035526854638,
            105.82747055629127,
            21.02036333357189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82747055629127,
              21.02036333357189
            ],
            [
              105.82746603875242,
              21.020356858648533
            ],
            [
              105.8274650122159,
              21.02035526854638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C7_La Thành",
          "maTaiSan": "04.O12.DODV.372",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02036333357189,
          "to_latitude": 21.02035526854638,
          "to_longitude": 105.8274650122159,
          "from_longitude": 105.82747055629127
        }
      },
      {
        "id": 372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280692477328,
            21.019808640687174,
            105.828156069299,
            21.01996322320301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828156069299,
              21.01996322320301
            ],
            [
              105.82815256695736,
              21.019957506580795
            ],
            [
              105.82811411334761,
              21.019894620670023
            ],
            [
              105.8280692477328,
              21.019808640687174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C14_Ngõ 117, La Thành",
          "maTaiSan": "04.O12.DODV.373",
          "diaChiLapD": "Ngõ 117, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01996322320301,
          "to_latitude": 21.019808640687174,
          "to_longitude": 105.8280692477328,
          "from_longitude": 105.828156069299
        }
      },
      {
        "id": 373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82717345768661,
            21.02013035974137,
            105.82739461072218,
            21.0204694419146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82727109361477,
              21.0204694419146
            ],
            [
              105.82727009640611,
              21.020467936571787
            ],
            [
              105.82726683973921,
              21.02045321628932
            ],
            [
              105.82726511706659,
              21.02044540815548
            ],
            [
              105.82717345768661,
              21.020305176565124
            ],
            [
              105.82717547462767,
              21.02030407475743
            ],
            [
              105.82721068133631,
              21.020284827426526
            ],
            [
              105.82739461072218,
              21.020185829034734
            ],
            [
              105.827359229439,
              21.02013035974137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6A_Ngõ 173, La Thành",
          "maTaiSan": "04.O12.DODV.374",
          "diaChiLapD": "Ngõ 173, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0204694419146,
          "to_latitude": 21.02013035974137,
          "to_longitude": 105.827359229439,
          "from_longitude": 105.82727109361477
        }
      },
      {
        "id": 374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82718651170259,
            21.01951030092279,
            105.82727143946359,
            21.01969142594719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718651170259,
              21.01951030092279
            ],
            [
              105.8272317923277,
              21.019581290114083
            ],
            [
              105.82727143946359,
              21.019641592629473
            ],
            [
              105.82725025808129,
              21.01966375692186
            ],
            [
              105.82719777492764,
              21.01969142594719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Đông Các",
          "maTaiSan": "04.O12.DODV.375",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01951030092279,
          "to_latitude": 21.01969142594719,
          "to_longitude": 105.82719777492764,
          "from_longitude": 105.82718651170259
        }
      },
      {
        "id": 375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290616895378,
            21.01929086043406,
            105.82913349801824,
            21.019385512559218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82913349801824,
              21.019385512559218
            ],
            [
              105.82910318817808,
              21.019346995936512
            ],
            [
              105.8290616895378,
              21.01929086043406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C20_La Thành",
          "maTaiSan": "04.O12.DODV.376",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019385512559218,
          "to_latitude": 21.01929086043406,
          "to_longitude": 105.8290616895378,
          "from_longitude": 105.82913349801824
        }
      },
      {
        "id": 376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899507977501,
            21.01933938205711,
            105.82910444508381,
            21.019406260346614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82910444508381,
              21.019406260346614
            ],
            [
              105.82903474196824,
              21.01933938205711
            ],
            [
              105.82899507977501,
              21.019362780110654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C20_La Thành",
          "maTaiSan": "04.O12.DODV.377",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019406260346614,
          "to_latitude": 21.019362780110654,
          "to_longitude": 105.82899507977501,
          "from_longitude": 105.82910444508381
        }
      },
      {
        "id": 377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82716527709573,
            21.01932137806147,
            105.82723402166599,
            21.019431400753234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82723402166599,
              21.019431400753234
            ],
            [
              105.82716527709573,
              21.01932137806147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Đông Các",
          "maTaiSan": "04.O12.DODV.378",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019431400753234,
          "to_latitude": 21.01932137806147,
          "to_longitude": 105.82716527709573,
          "from_longitude": 105.82723402166599
        }
      },
      {
        "id": 378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82683052020671,
            21.020427700875647,
            105.82694651485131,
            21.020637634572967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694651485131,
              21.020637634572967
            ],
            [
              105.82694123133224,
              21.020629018199138
            ],
            [
              105.8269392360342,
              21.020626719255436
            ],
            [
              105.82693418053589,
              21.02062089996501
            ],
            [
              105.82692731467597,
              21.020608114769217
            ],
            [
              105.82683052020671,
              21.020427700875647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3_Ngõ 177, La Thành",
          "maTaiSan": "04.O12.DODV.379",
          "diaChiLapD": "Ngõ 177, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020637634572967,
          "to_latitude": 21.020427700875647,
          "to_longitude": 105.82683052020671,
          "from_longitude": 105.82694651485131
        }
      },
      {
        "id": 379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82690659129503,
            21.020365864788026,
            105.82702865745786,
            21.020598036127755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702865745786,
              21.020598036127755
            ],
            [
              105.82702611769261,
              21.02059451776845
            ],
            [
              105.82702177692256,
              21.020589917069508
            ],
            [
              105.82701795964786,
              21.02058587292169
            ],
            [
              105.82700121887562,
              21.02058175702713
            ],
            [
              105.82699831700023,
              21.02057572223563
            ],
            [
              105.82697042735448,
              21.02051759527831
            ],
            [
              105.82694525183769,
              21.02045779499217
            ],
            [
              105.82693203636916,
              21.020413845969895
            ],
            [
              105.82690659129503,
              21.020365864788026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4_Ngõ 175, La Thành",
          "maTaiSan": "04.O12.DODV.380",
          "diaChiLapD": "Ngõ 175, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020598036127755,
          "to_latitude": 21.020365864788026,
          "to_longitude": 105.82690659129503,
          "from_longitude": 105.82702865745786
        }
      },
      {
        "id": 380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589371424702,
            21.01952901790775,
            105.82637565891511,
            21.01977352960528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82589371424702,
              21.01977352960528
            ],
            [
              105.82595387775251,
              21.019741797102366
            ],
            [
              105.826168203286,
              21.019632982498077
            ],
            [
              105.82637565891511,
              21.01952901790775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A45_Nguyễn Hy Quang",
          "maTaiSan": "04.O12.DODV.381",
          "diaChiLapD": "Nguyễn Hy Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01977352960528,
          "to_latitude": 21.01952901790775,
          "to_longitude": 105.82637565891511,
          "from_longitude": 105.82589371424702
        }
      },
      {
        "id": 381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8269451752314,
            21.020313686725192,
            105.82715228938586,
            21.02043273898908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269451752314,
              21.02043273898908
            ],
            [
              105.8270892172337,
              21.020351229789668
            ],
            [
              105.82715228938586,
              21.020313686725192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.1_Ngõ 175, La Thành",
          "maTaiSan": "04.O12.DODV.382",
          "diaChiLapD": "Ngõ 175, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02043273898908,
          "to_latitude": 21.020313686725192,
          "to_longitude": 105.82715228938586,
          "from_longitude": 105.8269451752314
        }
      },
      {
        "id": 382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82672124379691,
            21.019473112630262,
            105.82683109820923,
            21.019676583911938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82683109820923,
              21.019676583911938
            ],
            [
              105.82682749570414,
              21.019670660908876
            ],
            [
              105.82682860216052,
              21.01965953050228
            ],
            [
              105.82682935406329,
              21.019651964140422
            ],
            [
              105.82680697502643,
              21.019604696934568
            ],
            [
              105.82672124379691,
              21.019473112630262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Ngõ 121, Đông Các",
          "maTaiSan": "04.O12.DODV.383",
          "diaChiLapD": "Ngõ 121, Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019676583911938,
          "to_latitude": 21.019473112630262,
          "to_longitude": 105.82672124379691,
          "from_longitude": 105.82683109820923
        }
      },
      {
        "id": 383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82698582691684,
            21.01927046151208,
            105.82718226202354,
            21.01948000482615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718226202354,
              21.01948000482615
            ],
            [
              105.82717801493577,
              21.01947476870678
            ],
            [
              105.82717285408872,
              21.0194672843864
            ],
            [
              105.82716088085682,
              21.01946524893227
            ],
            [
              105.82703559994965,
              21.01927046151208
            ],
            [
              105.82698582691684,
              21.01929921019692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A40_Ngõ 97, Đông Các",
          "maTaiSan": "04.O12.DODV.384",
          "diaChiLapD": "Ngõ 97, Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01948000482615,
          "to_latitude": 21.01929921019692,
          "to_longitude": 105.82698582691684,
          "from_longitude": 105.82718226202354
        }
      },
      {
        "id": 384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82585673015078,
            21.020006301511795,
            105.82594653764147,
            21.020170696907467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82594653764147,
              21.020170696907467
            ],
            [
              105.82594199384334,
              21.02016171200505
            ],
            [
              105.82593887788678,
              21.020154524814178
            ],
            [
              105.8259233664643,
              21.02011877368798
            ],
            [
              105.82591289305802,
              21.02009371125703
            ],
            [
              105.82585673015078,
              21.020006301511795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47_Ngõ 189, Đông Các",
          "maTaiSan": "04.O12.DODV.385",
          "diaChiLapD": "Ngõ 189, Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020170696907467,
          "to_latitude": 21.020006301511795,
          "to_longitude": 105.82585673015078,
          "from_longitude": 105.82594653764147
        }
      },
      {
        "id": 385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82603188493935,
            21.020650337571073,
            105.8268383728709,
            21.021023717688003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82603188493935,
              21.021023717688003
            ],
            [
              105.82611002393602,
              21.020988422213527
            ],
            [
              105.82622092756914,
              21.02093809323817
            ],
            [
              105.82623938623931,
              21.020929776094693
            ],
            [
              105.82629443213891,
              21.020904973481926
            ],
            [
              105.82631677617472,
              21.020894905069238
            ],
            [
              105.82643154699241,
              21.020842343367583
            ],
            [
              105.82649011207532,
              21.020814813015008
            ],
            [
              105.82655518244816,
              21.020784225226127
            ],
            [
              105.82666266874851,
              21.020734051672704
            ],
            [
              105.82675113105816,
              21.02069190625865
            ],
            [
              105.8268383728709,
              21.020650337571073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1_La Thành",
          "maTaiSan": "04.O12.DODV.386",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021023717688003,
          "to_latitude": 21.020650337571073,
          "to_longitude": 105.8268383728709,
          "from_longitude": 105.82603188493935
        }
      },
      {
        "id": 386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265731285534,
            21.01948577667149,
            105.82715360909381,
            21.01980211154814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82715360909381,
              21.01948577667149
            ],
            [
              105.8271368691028,
              21.01949521006766
            ],
            [
              105.82704997483907,
              21.019541403523608
            ],
            [
              105.82696026538106,
              21.019588041461116
            ],
            [
              105.82689413657351,
              21.019624228212834
            ],
            [
              105.82688427542078,
              21.01962962505036
            ],
            [
              105.8268432355035,
              21.019651669955774
            ],
            [
              105.82683904605342,
              21.019653920383764
            ],
            [
              105.82682860216052,
              21.01965953050228
            ],
            [
              105.82681605107358,
              21.019666272668704
            ],
            [
              105.82671851110923,
              21.019720594347653
            ],
            [
              105.82661701046652,
              21.01977732334771
            ],
            [
              105.8265731285534,
              21.01980211154814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A42_Đông Các",
          "maTaiSan": "04.O12.DODV.387",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 69.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01948577667149,
          "to_latitude": 21.01980211154814,
          "to_longitude": 105.8265731285534,
          "from_longitude": 105.82715360909381
        }
      },
      {
        "id": 387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82611070838904,
            21.019832042177036,
            105.82651039448028,
            21.020057971956145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82651039448028,
              21.019832042177036
            ],
            [
              105.82641946338462,
              21.019882594030115
            ],
            [
              105.82635056096063,
              21.01992174759944
            ],
            [
              105.82632021265958,
              21.019938993088584
            ],
            [
              105.82631239806717,
              21.019943433371697
            ],
            [
              105.82630418763627,
              21.019948137512742
            ],
            [
              105.82621895330323,
              21.019996968943655
            ],
            [
              105.82611070838904,
              21.020057971956145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Đông Các",
          "maTaiSan": "04.O12.DODV.388",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019832042177036,
          "to_latitude": 21.020057971956145,
          "to_longitude": 105.82611070838904,
          "from_longitude": 105.82651039448028
        }
      },
      {
        "id": 388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256590149399,
            21.020080702784583,
            105.82615082641468,
            21.020342916231684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256590149399,
              21.020342916231684
            ],
            [
              105.8258057281402,
              21.020268242488577
            ],
            [
              105.82591561682965,
              21.02021186342339
            ],
            [
              105.82593351602127,
              21.020201882579812
            ],
            [
              105.82615082641468,
              21.020080702784583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B46_Đông Các",
          "maTaiSan": "04.O12.DODV.389",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020342916231684,
          "to_latitude": 21.020080702784583,
          "to_longitude": 105.82615082641468,
          "from_longitude": 105.8256590149399
        }
      },
      {
        "id": 389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82617304825166,
            21.019700630988222,
            105.82684530240591,
            21.020070823760665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82617304825166,
              21.020070823760665
            ],
            [
              105.82635143458022,
              21.019973798049776
            ],
            [
              105.82642907953195,
              21.019931182392718
            ],
            [
              105.82652442864111,
              21.019877081971234
            ],
            [
              105.82656316265947,
              21.01985675002207
            ],
            [
              105.82666801456772,
              21.019801712765386
            ],
            [
              105.82684530240591,
              21.019700630988222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Đông Các",
          "maTaiSan": "04.O12.DODV.390",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020070823760665,
          "to_latitude": 21.019700630988222,
          "to_longitude": 105.82684530240591,
          "from_longitude": 105.82617304825166
        }
      },
      {
        "id": 390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268432355035,
            21.019651669955774,
            105.82685197555443,
            21.019665389014968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82685197555443,
              21.019665389014968
            ],
            [
              105.8268511905339,
              21.01966415721842
            ],
            [
              105.82684540718984,
              21.0196550817209
            ],
            [
              105.8268432355035,
              21.019651669955774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A42_Đông Các",
          "maTaiSan": "04.O12.DODV.391",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019665389014968,
          "to_latitude": 21.019651669955774,
          "to_longitude": 105.8268432355035,
          "from_longitude": 105.82685197555443
        }
      },
      {
        "id": 391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82630418763627,
            21.019948137512742,
            105.82631694205442,
            21.01996903490343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631694205442,
              21.01996903490343
            ],
            [
              105.8263136464264,
              21.019963635172584
            ],
            [
              105.82630713575367,
              21.01995296989984
            ],
            [
              105.82630418763627,
              21.019948137512742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Đông Các",
          "maTaiSan": "04.O12.DODV.392",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01996903490343,
          "to_latitude": 21.019948137512742,
          "to_longitude": 105.82630418763627,
          "from_longitude": 105.82631694205442
        }
      },
      {
        "id": 392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82654285644162,
            21.019826015284153,
            105.82656316265947,
            21.01985675002207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656316265947,
              21.01985675002207
            ],
            [
              105.82654833241787,
              21.019834303769787
            ],
            [
              105.8265431115822,
              21.019826404237815
            ],
            [
              105.82654285644162,
              21.019826015284153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Đông Các",
          "maTaiSan": "04.O12.DODV.393",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01985675002207,
          "to_latitude": 21.019826015284153,
          "to_longitude": 105.82654285644162,
          "from_longitude": 105.82656316265947
        }
      },
      {
        "id": 393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8271097292812,
            21.02054743795305,
            105.8271149312847,
            21.020556447620944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8271149312847,
              21.020556447620944
            ],
            [
              105.82711101678979,
              21.020549602142932
            ],
            [
              105.8271097292812,
              21.02054743795305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.2_La Thành",
          "maTaiSan": "04.O12.DODV.394",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020556447620944,
          "to_latitude": 21.02054743795305,
          "to_longitude": 105.8271097292812,
          "from_longitude": 105.8271149312847
        }
      },
      {
        "id": 394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82627041708197,
            21.020540710446923,
            105.82688170795728,
            21.02080155069775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82688170795728,
              21.020668875162393
            ],
            [
              105.8268743945766,
              21.020657099236267
            ],
            [
              105.82686236999477,
              21.020637742860448
            ],
            [
              105.82680212839168,
              21.020540710446923
            ],
            [
              105.82643778620438,
              21.02071978690946
            ],
            [
              105.82627041708197,
              21.02080155069775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2_La Thành",
          "maTaiSan": "04.O12.DODV.395",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020668875162393,
          "to_latitude": 21.02080155069775,
          "to_longitude": 105.82627041708197,
          "from_longitude": 105.82688170795728
        }
      },
      {
        "id": 395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82629443213891,
            21.020904973481926,
            105.82631182443005,
            21.020936541624284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631182443005,
              21.020936541624284
            ],
            [
              105.82630766657374,
              21.02092899640663
            ],
            [
              105.82630035750708,
              21.020915728305862
            ],
            [
              105.82629443213891,
              21.020904973481926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1_La Thành",
          "maTaiSan": "04.O12.DODV.396",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020936541624284,
          "to_latitude": 21.020904973481926,
          "to_longitude": 105.82629443213891,
          "from_longitude": 105.82631182443005
        }
      },
      {
        "id": 396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82623693853347,
            21.01976201854935,
            105.82635056096063,
            21.01992174759944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82635056096063,
              21.01992174759944
            ],
            [
              105.82630901594777,
              21.019848663505233
            ],
            [
              105.82623693853347,
              21.01976201854935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Đông Các",
          "maTaiSan": "04.O12.DODV.397",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01992174759944,
          "to_latitude": 21.01976201854935,
          "to_longitude": 105.82623693853347,
          "from_longitude": 105.82635056096063
        }
      },
      {
        "id": 397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638158156581,
            21.018905521753258,
            105.82747217143462,
            21.01951973739534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638158156581,
              21.01951973739534
            ],
            [
              105.8264186115155,
              21.01945308099635
            ],
            [
              105.82747217143462,
              21.018905521753258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B44_Nguyễn Hy Quang",
          "maTaiSan": "04.O12.DODV.398",
          "diaChiLapD": "Nguyễn Hy Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 133.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01951973739534,
          "to_latitude": 21.018905521753258,
          "to_longitude": 105.82747217143462,
          "from_longitude": 105.82638158156581
        }
      },
      {
        "id": 398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82572568160407,
            21.02006895127731,
            105.82609793527551,
            21.020260690828678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82609793527551,
              21.02006895127731
            ],
            [
              105.82594860437607,
              21.020149292167556
            ],
            [
              105.82593887788678,
              21.020154524814178
            ],
            [
              105.82592759090441,
              21.020160597811298
            ],
            [
              105.82592034183608,
              21.020164497911765
            ],
            [
              105.8258157823538,
              21.02021578762378
            ],
            [
              105.82572568160407,
              21.020260690828678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Đông Các",
          "maTaiSan": "04.O12.DODV.399",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02006895127731,
          "to_latitude": 21.020260690828678,
          "to_longitude": 105.82572568160407,
          "from_longitude": 105.82609793527551
        }
      },
      {
        "id": 399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82592759090441,
            21.020160597811298,
            105.82593789556799,
            21.0201754564176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82592759090441,
              21.020160597811298
            ],
            [
              105.8259280434473,
              21.02016137238795
            ],
            [
              105.82593273148034,
              21.020168075945804
            ],
            [
              105.82593789556799,
              21.0201754564176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Đông Các",
          "maTaiSan": "04.O12.DODV.400",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020160597811298,
          "to_latitude": 21.0201754564176,
          "to_longitude": 105.82593789556799,
          "from_longitude": 105.82592759090441
        }
      },
      {
        "id": 400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82691402390391,
            21.020362804656653,
            105.82703402253213,
            21.020595450301382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82703402253213,
              21.020595450301382
            ],
            [
              105.82703120194887,
              21.020591945952688
            ],
            [
              105.82702699176903,
              21.02058730216734
            ],
            [
              105.82702272739678,
              21.020582599935707
            ],
            [
              105.82701160495027,
              21.02057559530737
            ],
            [
              105.82700924345893,
              21.020570737501025
            ],
            [
              105.82698101491196,
              21.02051255890295
            ],
            [
              105.82695581261261,
              21.020460636680458
            ],
            [
              105.8269451752314,
              21.02043273898908
            ],
            [
              105.82691402390391,
              21.020362804656653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.1_Ngõ 175, La Thành",
          "maTaiSan": "04.O12.DODV.401",
          "diaChiLapD": "Ngõ 175, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020595450301382,
          "to_latitude": 21.020362804656653,
          "to_longitude": 105.82691402390391,
          "from_longitude": 105.82703402253213
        }
      },
      {
        "id": 401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82643756962433,
            21.017792008402903,
            105.82722716544896,
            21.018321699684556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643756962433,
              21.018321699684556
            ],
            [
              105.82658938082476,
              21.01823656558135
            ],
            [
              105.82660948658501,
              21.018217880403213
            ],
            [
              105.82662713039252,
              21.018191984090482
            ],
            [
              105.82664376768982,
              21.018166556925053
            ],
            [
              105.82667000586923,
              21.018101794591935
            ],
            [
              105.82672896087765,
              21.01806440267361
            ],
            [
              105.82673409898699,
              21.01806114415501
            ],
            [
              105.8267740726922,
              21.018035791433952
            ],
            [
              105.82687598757457,
              21.017975204479956
            ],
            [
              105.82699678533031,
              21.017905165542768
            ],
            [
              105.82707458393239,
              21.01786429751417
            ],
            [
              105.8271747417525,
              21.01781708666847
            ],
            [
              105.82722716544896,
              21.017792008402903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A66_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.402",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 103.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018321699684556,
          "to_latitude": 21.017792008402903,
          "to_longitude": 105.82722716544896,
          "from_longitude": 105.82643756962433
        }
      },
      {
        "id": 402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547668962708,
            21.01766196130459,
            105.82613572456701,
            21.01810203005253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547668962708,
              21.01779284134585
            ],
            [
              105.82558382659072,
              21.017963314738736
            ],
            [
              105.82567247431456,
              21.01810203005253
            ],
            [
              105.82588501519771,
              21.018001238829797
            ],
            [
              105.82590697983717,
              21.017990822772582
            ],
            [
              105.82591338201227,
              21.017987397367044
            ],
            [
              105.82607410573301,
              21.017901398515207
            ],
            [
              105.82613572456701,
              21.01786891463829
            ],
            [
              105.82601053123395,
              21.01768988360463
            ],
            [
              105.82599434268667,
              21.01766196130459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A74_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.403",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 118.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01779284134585,
          "to_latitude": 21.01766196130459,
          "to_longitude": 105.82599434268667,
          "from_longitude": 105.82547668962708
        }
      },
      {
        "id": 403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82567048455411,
            21.01801115642523,
            105.82589617697444,
            21.01843162699029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82588981609008,
              21.01801115642523
            ],
            [
              105.82589184745072,
              21.018013899595378
            ],
            [
              105.82589617697444,
              21.018022068124193
            ],
            [
              105.82567048455411,
              21.018148428486384
            ],
            [
              105.82569343375177,
              21.018186666259492
            ],
            [
              105.82575198927172,
              21.018281603710555
            ],
            [
              105.82584293520215,
              21.01843162699029
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.404",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01801115642523,
          "to_latitude": 21.01843162699029,
          "to_longitude": 105.82584293520215,
          "from_longitude": 105.82588981609008
        }
      },
      {
        "id": 404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8261323294247,
            21.017928564802645,
            105.82632904529065,
            21.018232416441887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82632904529065,
              21.018232416441887
            ],
            [
              105.82628371965323,
              21.018175451580326
            ],
            [
              105.82626272092422,
              21.0181385691914
            ],
            [
              105.82624893085712,
              21.01811648636633
            ],
            [
              105.82620611267178,
              21.01804791910965
            ],
            [
              105.8261323294247,
              21.017928564802645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.405",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018232416441887,
          "to_latitude": 21.017928564802645,
          "to_longitude": 105.8261323294247,
          "from_longitude": 105.82632904529065
        }
      },
      {
        "id": 405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590071670714,
            21.018027183632285,
            105.82628371965323,
            21.01829242896981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590071670714,
              21.018027183632285
            ],
            [
              105.82597813380987,
              21.01815731665605
            ],
            [
              105.82606023241556,
              21.01829242896981
            ],
            [
              105.82619594419377,
              21.018221725647116
            ],
            [
              105.82628371965323,
              21.018175451580326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.406",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018027183632285,
          "to_latitude": 21.018175451580326,
          "to_longitude": 105.82628371965323,
          "from_longitude": 105.82590071670714
        }
      },
      {
        "id": 406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82608099826903,
            21.017679449689055,
            105.82639345671274,
            21.01825282173855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82639345671274,
              21.01825282173855
            ],
            [
              105.82633749287656,
              21.018158336187884
            ],
            [
              105.82628052706757,
              21.01806216103109
            ],
            [
              105.82626578882903,
              21.01803891223199
            ],
            [
              105.8261987506293,
              21.017933155773374
            ],
            [
              105.8261014170998,
              21.01777645814765
            ],
            [
              105.82613495402421,
              21.017755573478013
            ],
            [
              105.82608099826903,
              21.017679449689055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A72_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.407",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01825282173855,
          "to_latitude": 21.017679449689055,
          "to_longitude": 105.82608099826903,
          "from_longitude": 105.82639345671274
        }
      },
      {
        "id": 407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82616232768096,
            21.01752128466772,
            105.82664135936454,
            21.01808990052302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82664127696977,
              21.01808990052302
            ],
            [
              105.82664135936454,
              21.018084778833007
            ],
            [
              105.82663740445324,
              21.018074947472
            ],
            [
              105.82637453771954,
              21.01776691858502
            ],
            [
              105.82616232768096,
              21.01752128466772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A71_Ngách 64/65, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.408",
          "diaChiLapD": "Ngách 64/65, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01808990052302,
          "to_latitude": 21.01752128466772,
          "to_longitude": 105.82616232768096,
          "from_longitude": 105.82664127696977
        }
      },
      {
        "id": 408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82620892606508,
            21.01839586955055,
            105.82622309321519,
            21.018417423965502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82622309321519,
              21.018417423965502
            ],
            [
              105.82622245102849,
              21.018416448891127
            ],
            [
              105.82621487754827,
              21.018404924385866
            ],
            [
              105.82620892606508,
              21.01839586955055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A68_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.409",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018417423965502,
          "to_latitude": 21.01839586955055,
          "to_longitude": 105.82620892606508,
          "from_longitude": 105.82622309321519
        }
      },
      {
        "id": 409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82632904529065,
            21.018219245282594,
            105.82635235399232,
            21.018232416441887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82635235399232,
              21.018219245282594
            ],
            [
              105.82635101526999,
              21.018220001463042
            ],
            [
              105.82633879704959,
              21.018226906362674
            ],
            [
              105.82632904529065,
              21.018232416441887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.410",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018219245282594,
          "to_latitude": 21.018232416441887,
          "to_longitude": 105.82632904529065,
          "from_longitude": 105.82635235399232
        }
      },
      {
        "id": 410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82631534092421,
            21.018158336187884,
            105.82633749287656,
            21.018172116822846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631534092421,
              21.018172116822846
            ],
            [
              105.82631685562103,
              21.018171174626605
            ],
            [
              105.82632810559542,
              21.01816417598031
            ],
            [
              105.82633749287656,
              21.018158336187884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A72_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.411",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018172116822846,
          "to_latitude": 21.018158336187884,
          "to_longitude": 105.82633749287656,
          "from_longitude": 105.82631534092421
        }
      },
      {
        "id": 411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573772924835,
            21.017758152392123,
            105.82588501519771,
            21.018001238829797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82573772924835,
              21.017758152392123
            ],
            [
              105.82581187804004,
              21.017882471045077
            ],
            [
              105.82588501519771,
              21.018001238829797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.412",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017758152392123,
          "to_latitude": 21.018001238829797,
          "to_longitude": 105.82588501519771,
          "from_longitude": 105.82573772924835
        }
      },
      {
        "id": 412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590967681742,
            21.018894885815044,
            105.82682162675695,
            21.01943951512663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590967681742,
              21.01943951512663
            ],
            [
              105.82625922057544,
              21.01926600513926
            ],
            [
              105.82633098515721,
              21.01938949418906
            ],
            [
              105.82647263184944,
              21.019318393283392
            ],
            [
              105.82681279897068,
              21.019138005418387
            ],
            [
              105.82682162675695,
              21.019108495679806
            ],
            [
              105.82674153210739,
              21.01897170758757
            ],
            [
              105.82675400626108,
              21.018913677943626
            ],
            [
              105.82675801600018,
              21.018894885815044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A58_Nguyễn Hy Quang",
          "maTaiSan": "04.O12.DODV.413",
          "diaChiLapD": "Nguyễn Hy Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 143.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01943951512663,
          "to_latitude": 21.018894885815044,
          "to_longitude": 105.82675801600018,
          "from_longitude": 105.82590967681742
        }
      },
      {
        "id": 413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265865107678,
            21.01845767525853,
            105.82691032967642,
            21.018649588491115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265865107678,
              21.018649588491115
            ],
            [
              105.82658986410921,
              21.018647660971233
            ],
            [
              105.82659627768633,
              21.018643858839404
            ],
            [
              105.82691032967642,
              21.01845767525853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Ngách 65, Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.414",
          "diaChiLapD": "Ngách 65, Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018649588491115,
          "to_latitude": 21.01845767525853,
          "to_longitude": 105.82691032967642,
          "from_longitude": 105.8265865107678
        }
      },
      {
        "id": 414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82642101534356,
            21.018894028873277,
            105.82675406820609,
            21.019047917660828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82675406820609,
              21.018894028873277
            ],
            [
              105.82675067574102,
              21.0189132894855
            ],
            [
              105.82672117390058,
              21.018939378021763
            ],
            [
              105.82650483390364,
              21.019047917660828
            ],
            [
              105.82642101534356,
              21.018907375630757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Ngách 66, Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.415",
          "diaChiLapD": "Ngách 66, Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018894028873277,
          "to_latitude": 21.018907375630757,
          "to_longitude": 105.82642101534356,
          "from_longitude": 105.82675406820609
        }
      },
      {
        "id": 415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82680780379602,
            21.01865819271532,
            105.82731575271943,
            21.018930597625744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82680780379602,
              21.018930597625744
            ],
            [
              105.82706095268762,
              21.018794837884624
            ],
            [
              105.82717815442948,
              21.018731984290383
            ],
            [
              105.82721376082992,
              21.018712889473818
            ],
            [
              105.82731575271943,
              21.01865819271532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A56_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.416",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018930597625744,
          "to_latitude": 21.01865819271532,
          "to_longitude": 105.82731575271943,
          "from_longitude": 105.82680780379602
        }
      },
      {
        "id": 416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8271932120831,
            21.0186767212803,
            105.82720414215339,
            21.018692996641075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82720414215339,
              21.018692996641075
            ],
            [
              105.82720337885088,
              21.018691864998384
            ],
            [
              105.82719746922776,
              21.018683087286625
            ],
            [
              105.82719743430927,
              21.018683035069348
            ],
            [
              105.8271932120831,
              21.0186767212803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A55_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.417",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018692996641075,
          "to_latitude": 21.0186767212803,
          "to_longitude": 105.8271932120831,
          "from_longitude": 105.82720414215339
        }
      },
      {
        "id": 417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82642666627532,
            21.018350753425665,
            105.82671682528604,
            21.018848011694196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82671682528604,
              21.018848011694196
            ],
            [
              105.82665898972658,
              21.018749461816732
            ],
            [
              105.82661524267048,
              21.018675795967546
            ],
            [
              105.82659627768633,
              21.018643858839404
            ],
            [
              105.82659451148982,
              21.01864088406745
            ],
            [
              105.82659431244197,
              21.01864054903472
            ],
            [
              105.82658953915626,
              21.018632510940197
            ],
            [
              105.82656827881702,
              21.01859670922071
            ],
            [
              105.82651126450345,
              21.018499995164888
            ],
            [
              105.82642666627532,
              21.018350753425665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A60_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.418",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018848011694196,
          "to_latitude": 21.018350753425665,
          "to_longitude": 105.82642666627532,
          "from_longitude": 105.82671682528604
        }
      },
      {
        "id": 418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82720247697293,
            21.018693688480226,
            105.82721376082992,
            21.018712889473818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82721376082992,
              21.018712889473818
            ],
            [
              105.82720830923068,
              21.01870361276118
            ],
            [
              105.82720298194506,
              21.018694550411478
            ],
            [
              105.82720247697293,
              21.018693688480226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A56_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.419",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018712889473818,
          "to_latitude": 21.018693688480226,
          "to_longitude": 105.82720247697293,
          "from_longitude": 105.82721376082992
        }
      },
      {
        "id": 419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82676248033766,
            21.0188337583423,
            105.82750343545048,
            21.01913228333935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.826768566997,
              21.01889580008281
            ],
            [
              105.8267633377929,
              21.018924592329533
            ],
            [
              105.82676248033766,
              21.0189336025592
            ],
            [
              105.82682458454126,
              21.019017722489924
            ],
            [
              105.82689254473208,
              21.01913228333935
            ],
            [
              105.8269970703234,
              21.01908377508999
            ],
            [
              105.82717770682738,
              21.018994586639632
            ],
            [
              105.82750343545048,
              21.0188337583423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A57_Nguyễn Hy Quang",
          "maTaiSan": "04.O12.DODV.420",
          "diaChiLapD": "Nguyễn Hy Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01889580008281,
          "to_latitude": 21.0188337583423,
          "to_longitude": 105.82750343545048,
          "from_longitude": 105.826768566997
        }
      },
      {
        "id": 420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.826584318765,
            21.01864054903472,
            105.82659431244197,
            21.018645802885025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.826584318765,
              21.018645802885025
            ],
            [
              105.82659023881189,
              21.01864269050627
            ],
            [
              105.82659431244197,
              21.01864054903472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A60_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.421",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018645802885025,
          "to_latitude": 21.01864054903472,
          "to_longitude": 105.82659431244197,
          "from_longitude": 105.826584318765
        }
      },
      {
        "id": 421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82671206622292,
            21.0180352843959,
            105.82672896087765,
            21.01806440267361
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82671206622292,
              21.0180352843959
            ],
            [
              105.82671397461313,
              21.01803857731461
            ],
            [
              105.82672131962927,
              21.018051232843185
            ],
            [
              105.82672896087765,
              21.01806440267361
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A66_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.422",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0180352843959,
          "to_latitude": 21.01806440267361,
          "to_longitude": 105.82672896087765,
          "from_longitude": 105.82671206622292
        }
      },
      {
        "id": 422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82634748137576,
            21.01889523474611,
            105.82683812392952,
            21.019395693277453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82634748137576,
              21.019395693277453
            ],
            [
              105.82682929518428,
              21.01914420536368
            ],
            [
              105.82683812392952,
              21.019114695619674
            ],
            [
              105.82677094687276,
              21.01899996926327
            ],
            [
              105.82675181781534,
              21.01896620301817
            ],
            [
              105.82675679773526,
              21.018918539988196
            ],
            [
              105.82676204564615,
              21.01889523474611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B57_Nguyễn Hy Quang",
          "maTaiSan": "04.O12.DODV.423",
          "diaChiLapD": "Nguyễn Hy Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 87.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019395693277453,
          "to_latitude": 21.01889523474611,
          "to_longitude": 105.82676204564615,
          "from_longitude": 105.82634748137576
        }
      },
      {
        "id": 423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850574392951,
            21.01740884947132,
            105.82874828237665,
            21.017563256727602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874828237665,
              21.01740884947132
            ],
            [
              105.82873941261666,
              21.017415532291174
            ],
            [
              105.8287116315793,
              21.01743417019876
            ],
            [
              105.8286327491318,
              21.017486628104567
            ],
            [
              105.82856635850847,
              21.017523936752447
            ],
            [
              105.82850574392951,
              21.017563256727602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A63_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.424",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01740884947132,
          "to_latitude": 21.017563256727602,
          "to_longitude": 105.82850574392951,
          "from_longitude": 105.82874828237665
        }
      },
      {
        "id": 424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82875219860824,
            21.01735041997153,
            105.82903891629121,
            21.017581633622463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903891629121,
              21.01735041997153
            ],
            [
              105.82893275272679,
              21.01738365935694
            ],
            [
              105.82881336127234,
              21.01742193267033
            ],
            [
              105.82879549787634,
              21.017429418019276
            ],
            [
              105.82877896369762,
              21.01743112347402
            ],
            [
              105.82875984650329,
              21.017426006804303
            ],
            [
              105.82875508993166,
              21.017424662493596
            ],
            [
              105.82875219860824,
              21.01742384561179
            ],
            [
              105.82875655350222,
              21.017486369141544
            ],
            [
              105.82877464127586,
              21.017581633622463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.425",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735041997153,
          "to_latitude": 21.017581633622463,
          "to_longitude": 105.82877464127586,
          "from_longitude": 105.82903891629121
        }
      },
      {
        "id": 425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859970606233,
            21.01723065599947,
            105.82872289689865,
            21.017382974698126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859970606233,
              21.01723065599947
            ],
            [
              105.828644826952,
              21.017294684453315
            ],
            [
              105.82866386172779,
              21.017321972109247
            ],
            [
              105.82867295418752,
              21.017334529619575
            ],
            [
              105.82867399235644,
              21.017335963400573
            ],
            [
              105.8286975303023,
              21.01735825234447
            ],
            [
              105.82872289689865,
              21.017382974698126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.426",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01723065599947,
          "to_latitude": 21.017382974698126,
          "to_longitude": 105.82872289689865,
          "from_longitude": 105.82859970606233
        }
      },
      {
        "id": 426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82800331396089,
            21.017234071660507,
            105.82849369709778,
            21.017427779497783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82800331396089,
              21.017427779497783
            ],
            [
              105.82816223356595,
              21.017372518843327
            ],
            [
              105.82833977255174,
              21.017296710228837
            ],
            [
              105.82834404074363,
              21.01729497331768
            ],
            [
              105.82840762786519,
              21.017269096985242
            ],
            [
              105.82849369709778,
              21.017234071660507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A65_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.427",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017427779497783,
          "to_latitude": 21.017234071660507,
          "to_longitude": 105.82849369709778,
          "from_longitude": 105.82800331396089
        }
      },
      {
        "id": 427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82856332787922,
            21.017294684453315,
            105.828644826952,
            21.01734589236277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828644826952,
              21.017294684453315
            ],
            [
              105.82856332787922,
              21.01734589236277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.428",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017294684453315,
          "to_latitude": 21.01734589236277,
          "to_longitude": 105.82856332787922,
          "from_longitude": 105.828644826952
        }
      },
      {
        "id": 428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840762786519,
            21.017269096985242,
            105.82842390312733,
            21.0173289710102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840762786519,
              21.017269096985242
            ],
            [
              105.82842390312733,
              21.017311253456317
            ],
            [
              105.82841706437844,
              21.0173289710102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.429",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017269096985242,
          "to_latitude": 21.0173289710102,
          "to_longitude": 105.82841706437844,
          "from_longitude": 105.82840762786519
        }
      },
      {
        "id": 429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82875508993166,
            21.017420162460958,
            105.82875920240909,
            21.017424662493596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82875508993166,
              21.017424662493596
            ],
            [
              105.82875683153743,
              21.017422834009896
            ],
            [
              105.82875920240909,
              21.017420162460958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.430",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017424662493596,
          "to_latitude": 21.017420162460958,
          "to_longitude": 105.82875920240909,
          "from_longitude": 105.82875508993166
        }
      },
      {
        "id": 430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867295418752,
            21.01732862201768,
            105.82867936275494,
            21.017334529619575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82867936275494,
              21.01732862201768
            ],
            [
              105.82867619099201,
              21.0173315422409
            ],
            [
              105.8286759967048,
              21.01733172112273
            ],
            [
              105.82867338774277,
              21.01733413008847
            ],
            [
              105.82867295418752,
              21.017334529619575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.431",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01732862201768,
          "to_latitude": 21.017334529619575,
          "to_longitude": 105.82867295418752,
          "from_longitude": 105.82867936275494
        }
      },
      {
        "id": 431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82743571529544,
            21.017649493020322,
            105.82744976434795,
            21.01767403397018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82743571529544,
              21.017649493020322
            ],
            [
              105.82743638552645,
              21.01765066846974
            ],
            [
              105.82744012116717,
              21.01765718965299
            ],
            [
              105.82744976434795,
              21.01767403397018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B66_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.432",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017649493020322,
          "to_latitude": 21.01767403397018,
          "to_longitude": 105.82744976434795,
          "from_longitude": 105.82743571529544
        }
      },
      {
        "id": 432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890572195615,
            21.01799534219309,
            105.82913131661614,
            21.01819856354093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82913131661614,
              21.01799534219309
            ],
            [
              105.82904916256523,
              21.018080887832138
            ],
            [
              105.82895663339129,
              21.01815679690044
            ],
            [
              105.82890572195615,
              21.01819856354093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A26_Đông Các",
          "maTaiSan": "04.O12.DODV.433",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01799534219309,
          "to_latitude": 21.01819856354093,
          "to_longitude": 105.82890572195615,
          "from_longitude": 105.82913131661614
        }
      },
      {
        "id": 433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82811718662012,
            21.018955974885166,
            105.82821541826668,
            21.019084042768718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82811718662012,
              21.018955974885166
            ],
            [
              105.82816735834152,
              21.019018820017415
            ],
            [
              105.82821541826668,
              21.019084042768718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33_Đông Các",
          "maTaiSan": "04.O12.DODV.434",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018955974885166,
          "to_latitude": 21.019084042768718,
          "to_longitude": 105.82821541826668,
          "from_longitude": 105.82811718662012
        }
      },
      {
        "id": 434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290356662655,
            21.01760323818329,
            105.82925628604899,
            21.017713161168697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925628604899,
              21.01760323818329
            ],
            [
              105.8292231288307,
              21.01762015915534
            ],
            [
              105.8291511175022,
              21.017656749362196
            ],
            [
              105.829076177469,
              21.017693250845113
            ],
            [
              105.8290356662655,
              21.017713161168697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A89_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.435",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01760323818329,
          "to_latitude": 21.017713161168697,
          "to_longitude": 105.8290356662655,
          "from_longitude": 105.82925628604899
        }
      },
      {
        "id": 435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82780338807655,
            21.019123842027224,
            105.82789369078574,
            21.01931731331627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82780338807655,
              21.019123842027224
            ],
            [
              105.82780374597681,
              21.019124374091735
            ],
            [
              105.82787513155677,
              21.01922970315006
            ],
            [
              105.82787976600751,
              21.019239934009097
            ],
            [
              105.82789369078574,
              21.019270673448702
            ],
            [
              105.82787921101234,
              21.01931731331627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A35_Đông Các",
          "maTaiSan": "04.O12.DODV.436",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019123842027224,
          "to_latitude": 21.01931731331627,
          "to_longitude": 105.82787921101234,
          "from_longitude": 105.82780338807655
        }
      },
      {
        "id": 436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82736783673968,
            21.019042234706845,
            105.82748352944935,
            21.019318723365043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82747506796456,
              21.019318723365043
            ],
            [
              105.82747158098677,
              21.019310727814766
            ],
            [
              105.82746810115647,
              21.019302981519825
            ],
            [
              105.82746304264289,
              21.019291729357324
            ],
            [
              105.82744040253966,
              21.019255926340843
            ],
            [
              105.82742303457228,
              21.019228248382642
            ],
            [
              105.82740571337605,
              21.019192308391823
            ],
            [
              105.82739086852156,
              21.019148392615236
            ],
            [
              105.82736783673968,
              21.01911011213864
            ],
            [
              105.82743334518305,
              21.019070518551743
            ],
            [
              105.82748352944935,
              21.019042234706845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Đông Các",
          "maTaiSan": "04.O12.DODV.437",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019318723365043,
          "to_latitude": 21.019042234706845,
          "to_longitude": 105.82748352944935,
          "from_longitude": 105.82747506796456
        }
      },
      {
        "id": 437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82770795109587,
            21.018930624626453,
            105.82779902442414,
            21.019127813970243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779902442414,
              21.019127813970243
            ],
            [
              105.82779860780099,
              21.019127274062473
            ],
            [
              105.82774017969685,
              21.019051010033095
            ],
            [
              105.82771941629235,
              21.019002647604548
            ],
            [
              105.82770795109587,
              21.018972044309155
            ],
            [
              105.8277395469761,
              21.0189574633473
            ],
            [
              105.82775758148208,
              21.018956885139197
            ],
            [
              105.8277926904117,
              21.018930624626453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Đông Các",
          "maTaiSan": "04.O12.DODV.438",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019127813970243,
          "to_latitude": 21.018930624626453,
          "to_longitude": 105.8277926904117,
          "from_longitude": 105.82779902442414
        }
      },
      {
        "id": 438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799230584503,
            21.017675285253578,
            105.82835555051207,
            21.018102257100228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832403696374,
              21.018102257100228
            ],
            [
              105.82835555051207,
              21.01807978043386
            ],
            [
              105.82831737237596,
              21.0180430072558
            ],
            [
              105.82823906263853,
              21.01808202401196
            ],
            [
              105.82818668631718,
              21.018008359946183
            ],
            [
              105.82821285132455,
              21.0179909195364
            ],
            [
              105.82822064136677,
              21.01796481723353
            ],
            [
              105.82814127435894,
              21.01786897759764
            ],
            [
              105.82799230584503,
              21.017675285253578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.439",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018102257100228,
          "to_latitude": 21.017675285253578,
          "to_longitude": 105.82799230584503,
          "from_longitude": 105.82832403696374
        }
      },
      {
        "id": 439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797756313406,
            21.01851974127111,
            105.82839072909078,
            21.01871101066054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839072909078,
              21.01860763196581
            ],
            [
              105.82839070303386,
              21.018607616737874
            ],
            [
              105.82837548061944,
              21.018599009064687
            ],
            [
              105.82836478621091,
              21.01859250462978
            ],
            [
              105.82834925809057,
              21.018583061152903
            ],
            [
              105.8282808460955,
              21.01851974127111
            ],
            [
              105.82817397505576,
              21.01857311564173
            ],
            [
              105.82797756313406,
              21.01871101066054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Đông Các",
          "maTaiSan": "04.O12.DODV.440",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01860763196581,
          "to_latitude": 21.01871101066054,
          "to_longitude": 105.82797756313406,
          "from_longitude": 105.82839072909078
        }
      },
      {
        "id": 440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.826816747511,
            21.018620026649145,
            105.82729835104075,
            21.01887971790977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.826816747511,
              21.01887971790977
            ],
            [
              105.82706888516522,
              21.018743760751306
            ],
            [
              105.82717823565694,
              21.018684796684113
            ],
            [
              105.8271932120831,
              21.0186767212803
            ],
            [
              105.82729835104075,
              21.018620026649145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A55_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.441",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01887971790977,
          "to_latitude": 21.018620026649145,
          "to_longitude": 105.82729835104075,
          "from_longitude": 105.826816747511
        }
      },
      {
        "id": 441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82738995647404,
            21.01841629423937,
            105.82765225428832,
            21.018611166667903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82738995647404,
              21.018611166667903
            ],
            [
              105.82747436495826,
              21.01854990579363
            ],
            [
              105.82753630329609,
              21.018503510185994
            ],
            [
              105.82754534103894,
              21.018496740802775
            ],
            [
              105.82755528600613,
              21.018489292296152
            ],
            [
              105.82765225428832,
              21.01841629423937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.442",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018611166667903,
          "to_latitude": 21.01841629423937,
          "to_longitude": 105.82765225428832,
          "from_longitude": 105.82738995647404
        }
      },
      {
        "id": 442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833692964157,
            21.017771862333287,
            105.82886836109725,
            21.018114378832404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833692964157,
              21.018114378832404
            ],
            [
              105.82836468965742,
              21.01808772405639
            ],
            [
              105.82845378749916,
              21.018002180146485
            ],
            [
              105.82863024532598,
              21.017964410399262
            ],
            [
              105.8287034784469,
              21.017893668020125
            ],
            [
              105.82886836109725,
              21.017771862333287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.443",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018114378832404,
          "to_latitude": 21.017771862333287,
          "to_longitude": 105.82886836109725,
          "from_longitude": 105.82833692964157
        }
      },
      {
        "id": 443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291246204448,
            21.01732959300573,
            105.82937977343191,
            21.01780483128323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937977343191,
              21.01780483128323
            ],
            [
              105.82936107922902,
              21.017774454830043
            ],
            [
              105.8293427255754,
              21.017744080327148
            ],
            [
              105.8293286033284,
              21.017720276028236
            ],
            [
              105.82931341424137,
              21.017696464283663
            ],
            [
              105.82931147476548,
              21.017693299805007
            ],
            [
              105.8292863673606,
              21.017652328161205
            ],
            [
              105.82928036837073,
              21.01764253753996
            ],
            [
              105.82925628604899,
              21.01760323818329
            ],
            [
              105.82924835687976,
              21.017555775313678
            ],
            [
              105.82920367519279,
              21.01747259470753
            ],
            [
              105.8291246204448,
              21.01732959300573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A89_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.444",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01780483128323,
          "to_latitude": 21.01732959300573,
          "to_longitude": 105.8291246204448,
          "from_longitude": 105.82937977343191
        }
      },
      {
        "id": 444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82834934641866,
            21.018437311132445,
            105.8285686928342,
            21.01871653568605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285686928342,
              21.018437311132445
            ],
            [
              105.82846667449554,
              21.01850678371538
            ],
            [
              105.82839618229401,
              21.018550834988016
            ],
            [
              105.82837434010122,
              21.018571209729817
            ],
            [
              105.82836915263852,
              21.018582770984914
            ],
            [
              105.82836710863896,
              21.018587327774757
            ],
            [
              105.82836478621091,
              21.01859250462978
            ],
            [
              105.82836322398843,
              21.018595986945034
            ],
            [
              105.82834934641866,
              21.018626918635626
            ],
            [
              105.82835047245297,
              21.01871653568605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Đông Các",
          "maTaiSan": "04.O12.DODV.445",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018437311132445,
          "to_latitude": 21.01871653568605,
          "to_longitude": 105.82835047245297,
          "from_longitude": 105.8285686928342
        }
      },
      {
        "id": 445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82782668489321,
            21.018777328358944,
            105.82837528814261,
            21.01915243315642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82782668489321,
              21.01915243315642
            ],
            [
              105.82796872662708,
              21.019057055828334
            ],
            [
              105.82807439984485,
              21.018985106256967
            ],
            [
              105.82809726814405,
              21.018969536231598
            ],
            [
              105.82811718662012,
              21.018955974885166
            ],
            [
              105.82812147718076,
              21.018953053741182
            ],
            [
              105.82822835306192,
              21.018879253836495
            ],
            [
              105.82827034593832,
              21.01885025635732
            ],
            [
              105.82837528814261,
              21.018777328358944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A33_Đông Các",
          "maTaiSan": "04.O12.DODV.446",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01915243315642,
          "to_latitude": 21.018777328358944,
          "to_longitude": 105.82837528814261,
          "from_longitude": 105.82782668489321
        }
      },
      {
        "id": 446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82869706310824,
            21.018147880136237,
            105.82907675907003,
            21.018410357707907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82869706310824,
              21.018410357707907
            ],
            [
              105.82872556023362,
              21.018391062375947
            ],
            [
              105.82874219783824,
              21.018380201328117
            ],
            [
              105.82878857761436,
              21.018349924161218
            ],
            [
              105.82880398449511,
              21.018339865748395
            ],
            [
              105.8289032020117,
              21.018272775776936
            ],
            [
              105.82901236230975,
              21.01819422052799
            ],
            [
              105.82907675907003,
              21.018147880136237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Đông Các",
          "maTaiSan": "04.O12.DODV.447",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018410357707907,
          "to_latitude": 21.018147880136237,
          "to_longitude": 105.82907675907003,
          "from_longitude": 105.82869706310824
        }
      },
      {
        "id": 447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82904883936158,
            21.01768094692273,
            105.82932915252738,
            21.017794976151436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82932915252738,
              21.01768094692273
            ],
            [
              105.8293240651864,
              21.017684501528276
            ],
            [
              105.82931147476548,
              21.017693299805007
            ],
            [
              105.82923966305229,
              21.017731720817164
            ],
            [
              105.82919377187706,
              21.01775532269125
            ],
            [
              105.829185812521,
              21.017746592073166
            ],
            [
              105.82914129549036,
              21.01774279060587
            ],
            [
              105.82904883936158,
              21.017794976151436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A88_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.448",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01768094692273,
          "to_latitude": 21.017794976151436,
          "to_longitude": 105.82904883936158,
          "from_longitude": 105.82932915252738
        }
      },
      {
        "id": 448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82876031810407,
            21.01830550663969,
            105.82876644214684,
            21.018313034993355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82876031810407,
              21.01830550663969
            ],
            [
              105.82876501066308,
              21.01831127706518
            ],
            [
              105.82876644214684,
              21.018313034993355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A28_Đông Các",
          "maTaiSan": "04.O12.DODV.449",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01830550663969,
          "to_latitude": 21.018313034993355,
          "to_longitude": 105.82876644214684,
          "from_longitude": 105.82876031810407
        }
      },
      {
        "id": 449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82767629388916,
            21.019206112046465,
            105.82769041269712,
            21.019228441531673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82769041269712,
              21.019228441531673
            ],
            [
              105.82769024286802,
              21.01922817229364
            ],
            [
              105.82768716367956,
              21.019223300826823
            ],
            [
              105.82768259775145,
              21.019216071046696
            ],
            [
              105.82768239202166,
              21.01921574598334
            ],
            [
              105.82767991550423,
              21.019211833519005
            ],
            [
              105.82767629388916,
              21.019206112046465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A36_Đông Các",
          "maTaiSan": "04.O12.DODV.450",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019228441531673,
          "to_latitude": 21.019206112046465,
          "to_longitude": 105.82767629388916,
          "from_longitude": 105.82769041269712
        }
      },
      {
        "id": 450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781981495218,
            21.018757022796958,
            105.82832950511114,
            21.019106843772164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832950511114,
              21.018757022796958
            ],
            [
              105.8282286932111,
              21.01882536124074
            ],
            [
              105.8281289907825,
              21.018894299386616
            ],
            [
              105.82809415254144,
              21.01891817684402
            ],
            [
              105.82806993836385,
              21.01893477316194
            ],
            [
              105.82802495136434,
              21.01896560697308
            ],
            [
              105.8279108678146,
              21.019044891895657
            ],
            [
              105.82781981495218,
              21.019106843772164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A32_Đông Các",
          "maTaiSan": "04.O12.DODV.451",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018757022796958,
          "to_latitude": 21.019106843772164,
          "to_longitude": 105.82781981495218,
          "from_longitude": 105.82832950511114
        }
      },
      {
        "id": 451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82809415254144,
            21.01891817684402,
            105.82810354313362,
            21.0189300139624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82810354313362,
              21.0189300139624
            ],
            [
              105.8280995443448,
              21.018924975366996
            ],
            [
              105.82809415254144,
              21.01891817684402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A32_Đông Các",
          "maTaiSan": "04.O12.DODV.452",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0189300139624,
          "to_latitude": 21.01891817684402,
          "to_longitude": 105.82809415254144,
          "from_longitude": 105.82810354313362
        }
      },
      {
        "id": 452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82836710863896,
            21.018587327774757,
            105.82839500306396,
            21.018601013994743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839500306396,
              21.018601013994743
            ],
            [
              105.8283949779839,
              21.01860100147171
            ],
            [
              105.82839226657744,
              21.018599671579576
            ],
            [
              105.82837798129233,
              21.018592664374392
            ],
            [
              105.82836710863896,
              21.018587327774757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Đông Các",
          "maTaiSan": "04.O12.DODV.453",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018601013994743,
          "to_latitude": 21.018587327774757,
          "to_longitude": 105.82836710863896,
          "from_longitude": 105.82839500306396
        }
      },
      {
        "id": 453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275245263586,
            21.01845452215964,
            105.82754534103894,
            21.018496740802775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754534103894,
              21.018496740802775
            ],
            [
              105.82753510607483,
              21.018479178599275
            ],
            [
              105.8275245263586,
              21.01846102555625
            ],
            [
              105.82753421681144,
              21.01845452215964
            ],
            [
              105.82754246446493,
              21.01847401880456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.454",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018496740802775,
          "to_latitude": 21.01847401880456,
          "to_longitude": 105.82754246446493,
          "from_longitude": 105.82754534103894
        }
      },
      {
        "id": 454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82958726118176,
            21.019099639650335,
            105.82960911278583,
            21.019132107893494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960911278583,
              21.019132107893494
            ],
            [
              105.82960147845833,
              21.01912076629659
            ],
            [
              105.82959036787203,
              21.01910425533431
            ],
            [
              105.82958726118176,
              21.019099639650335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C24_La Thành",
          "maTaiSan": "04.O12.DODV.455",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019132107893494,
          "to_latitude": 21.019099639650335,
          "to_longitude": 105.82958726118176,
          "from_longitude": 105.82960911278583
        }
      },
      {
        "id": 455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766014006214,
            21.01828249026409,
            105.82804316040762,
            21.018425005908885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766014006214,
              21.018398627620268
            ],
            [
              105.82767704638253,
              21.018389903301735
            ],
            [
              105.82771217884559,
              21.018379954041563
            ],
            [
              105.82773173652106,
              21.018379982620118
            ],
            [
              105.82775171697637,
              21.01839334435996
            ],
            [
              105.82778231814837,
              21.018419036733565
            ],
            [
              105.8277999910738,
              21.018422499506492
            ],
            [
              105.82782134589766,
              21.018425005908885
            ],
            [
              105.82782328262577,
              21.018424539451953
            ],
            [
              105.82783674364396,
              21.0184212954946
            ],
            [
              105.82784384003885,
              21.01841747012292
            ],
            [
              105.8278568867016,
              21.018410437343274
            ],
            [
              105.82787093332311,
              21.01840157608403
            ],
            [
              105.82787728703626,
              21.018397567357706
            ],
            [
              105.82790344462458,
              21.018371968194085
            ],
            [
              105.8279333232594,
              21.018354423041988
            ],
            [
              105.82804316040762,
              21.01828249026409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A52.1_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.456",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018398627620268,
          "to_latitude": 21.01828249026409,
          "to_longitude": 105.82804316040762,
          "from_longitude": 105.82766014006214
        }
      },
      {
        "id": 456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82785906602116,
            21.018360337467083,
            105.82787093332311,
            21.01840157608403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82787093332311,
              21.01840157608403
            ],
            [
              105.82785968294083,
              21.018362484141356
            ],
            [
              105.82785906602116,
              21.018360337467083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A52.1_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.457",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01840157608403,
          "to_latitude": 21.018360337467083,
          "to_longitude": 105.82785906602116,
          "from_longitude": 105.82787093332311
        }
      },
      {
        "id": 457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82822835306192,
            21.018879253836495,
            105.82837509146192,
            21.01904736026324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82822835306192,
              21.018879253836495
            ],
            [
              105.82830083775765,
              21.018966365630565
            ],
            [
              105.82832543261979,
              21.018990307791334
            ],
            [
              105.82837509146192,
              21.01904736026324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33_Đông Các",
          "maTaiSan": "04.O12.DODV.458",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018879253836495,
          "to_latitude": 21.01904736026324,
          "to_longitude": 105.82837509146192,
          "from_longitude": 105.82822835306192
        }
      },
      {
        "id": 458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82767025121755,
            21.01836836212663,
            105.82784406100916,
            21.0186078288268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278420933261,
              21.01836836212663
            ],
            [
              105.82784219033604,
              21.018371123717962
            ],
            [
              105.82784384003885,
              21.01841747012292
            ],
            [
              105.82784406100916,
              21.01842369317411
            ],
            [
              105.82781774892786,
              21.018467663783166
            ],
            [
              105.82775442953026,
              21.018503116408695
            ],
            [
              105.82773380607523,
              21.018478179351384
            ],
            [
              105.82767025121755,
              21.01852082276664
            ],
            [
              105.827736674503,
              21.0186078288268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B52_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.459",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01836836212663,
          "to_latitude": 21.0186078288268,
          "to_longitude": 105.827736674503,
          "from_longitude": 105.8278420933261
        }
      },
      {
        "id": 459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292863673606,
            21.01764197620208,
            105.82930565590762,
            21.017652328161205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82930565590762,
              21.01764197620208
            ],
            [
              105.82930182428585,
              21.017644032557232
            ],
            [
              105.82929742391578,
              21.017646394265366
            ],
            [
              105.8292863673606,
              21.017652328161205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A89_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.460",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01764197620208,
          "to_latitude": 21.017652328161205,
          "to_longitude": 105.8292863673606,
          "from_longitude": 105.82930565590762
        }
      },
      {
        "id": 460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82736158676447,
            21.018152256384177,
            105.82815255238415,
            21.018560310346274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82736158676447,
              21.018560310346274
            ],
            [
              105.8274347464996,
              21.018516380196015
            ],
            [
              105.82750660748759,
              21.018468777495634
            ],
            [
              105.8275404818511,
              21.018444726911245
            ],
            [
              105.82757194152991,
              21.018422389744398
            ],
            [
              105.8276189896832,
              21.018384536995494
            ],
            [
              105.82768038650826,
              21.018350375451707
            ],
            [
              105.82771434074105,
              21.018338191924844
            ],
            [
              105.82774377583058,
              21.018342414891126
            ],
            [
              105.8277730135577,
              21.018355845389795
            ],
            [
              105.82779564929594,
              21.018367864655794
            ],
            [
              105.82781780128123,
              21.01836962111548
            ],
            [
              105.82782756578128,
              21.018367996566173
            ],
            [
              105.82785574228994,
              21.018354380612358
            ],
            [
              105.82790931285601,
              21.018321140428913
            ],
            [
              105.82798353135043,
              21.01827284141428
            ],
            [
              105.82815255238415,
              21.018152256384177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A52_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.461",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018560310346274,
          "to_latitude": 21.018152256384177,
          "to_longitude": 105.82815255238415,
          "from_longitude": 105.82736158676447
        }
      },
      {
        "id": 461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275404818511,
            21.018444726911245,
            105.82755292761226,
            21.01846616493873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82755292761226,
              21.01846616493873
            ],
            [
              105.82755163886048,
              21.01846394746708
            ],
            [
              105.82754563691196,
              21.018453606733345
            ],
            [
              105.8275404818511,
              21.018444726911245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A52_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.462",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01846616493873,
          "to_latitude": 21.018444726911245,
          "to_longitude": 105.8275404818511,
          "from_longitude": 105.82755292761226
        }
      },
      {
        "id": 462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284500492518,
            21.018153145376846,
            105.82888776647285,
            21.018371839066916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82888776647285,
              21.018201412191456
            ],
            [
              105.82878695491098,
              21.018286234812827
            ],
            [
              105.82876031810407,
              21.01830550663969
            ],
            [
              105.82866863227184,
              21.018371839066916
            ],
            [
              105.82863224997648,
              21.01836745319938
            ],
            [
              105.8284500492518,
              21.018153145376846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A28_Đông Các",
          "maTaiSan": "04.O12.DODV.463",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018201412191456,
          "to_latitude": 21.018153145376846,
          "to_longitude": 105.8284500492518,
          "from_longitude": 105.82888776647285
        }
      },
      {
        "id": 463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82730848877748,
            21.017447429100525,
            105.82787583042118,
            21.0177531057105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82730848877748,
              21.0177531057105
            ],
            [
              105.82736586717688,
              21.017722225373213
            ],
            [
              105.82742837928696,
              21.017688170499575
            ],
            [
              105.82744976434795,
              21.01767403397018
            ],
            [
              105.82749230730371,
              21.017645911040823
            ],
            [
              105.82752575522845,
              21.017626546664474
            ],
            [
              105.82759621619378,
              21.017585753839636
            ],
            [
              105.8276735976677,
              21.017545708606143
            ],
            [
              105.82776407015105,
              21.017497797577516
            ],
            [
              105.82784279572189,
              21.01745973192197
            ],
            [
              105.82787583042118,
              21.017447429100525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B66_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.464",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0177531057105,
          "to_latitude": 21.017447429100525,
          "to_longitude": 105.82787583042118,
          "from_longitude": 105.82730848877748
        }
      },
      {
        "id": 464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82935650255935,
            21.019008170752883,
            105.82960422062207,
            21.019134956137375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960422062207,
              21.019134956137375
            ],
            [
              105.8295972110661,
              21.01912491484707
            ],
            [
              105.82958187028422,
              21.019102930196443
            ],
            [
              105.82957931624061,
              21.019099271042176
            ],
            [
              105.82954111497962,
              21.019057722920717
            ],
            [
              105.82948162425824,
              21.01908550358199
            ],
            [
              105.82943316279122,
              21.019110922704805
            ],
            [
              105.82935650255935,
              21.019008170752883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C22_La Thành",
          "maTaiSan": "04.O12.DODV.465",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019134956137375,
          "to_latitude": 21.019008170752883,
          "to_longitude": 105.82935650255935,
          "from_longitude": 105.82960422062207
        }
      },
      {
        "id": 465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82452249032433,
            21.01780055554184,
            105.82538739173538,
            21.01826800088989
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82452249032433,
              21.01826800088989
            ],
            [
              105.8245550078255,
              21.01825042456233
            ],
            [
              105.82538739173538,
              21.01780055554184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT235_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.466",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 103.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01826800088989,
          "to_latitude": 21.01780055554184,
          "to_longitude": 105.82538739173538,
          "from_longitude": 105.82452249032433
        }
      },
      {
        "id": 466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82660837199951,
            21.018745190759724,
            105.82672117390058,
            21.018939378021763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82672117390058,
              21.018939378021763
            ],
            [
              105.82660837199951,
              21.018745190759724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Ngõ Giếng",
          "maTaiSan": "04.O12.DODV.467",
          "diaChiLapD": "Ngõ Giếng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018939378021763,
          "to_latitude": 21.018745190759724,
          "to_longitude": 105.82660837199951,
          "from_longitude": 105.82672117390058
        }
      },
      {
        "id": 467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82632171410461,
            21.01707995538645,
            105.8269807417614,
            21.01735924318632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269807417614,
              21.01707995538645
            ],
            [
              105.82693924170081,
              21.017099196215838
            ],
            [
              105.82654290462783,
              21.017282949805015
            ],
            [
              105.82632171410461,
              21.01735924318632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT107_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.468",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01707995538645,
          "to_latitude": 21.01735924318632,
          "to_longitude": 105.82632171410461,
          "from_longitude": 105.8269807417614
        }
      },
      {
        "id": 468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82622339547723,
            21.016970907212247,
            105.82662743602016,
            21.01718397555857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82662743602016,
              21.016970907212247
            ],
            [
              105.82622339547723,
              21.01718397555857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.469",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016970907212247,
          "to_latitude": 21.01718397555857,
          "to_longitude": 105.82622339547723,
          "from_longitude": 105.82662743602016
        }
      },
      {
        "id": 469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82834234251646,
            21.0172907228786,
            105.82834404074363,
            21.01729497331768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82834234251646,
              21.0172907228786
            ],
            [
              105.82834276780092,
              21.017291786613864
            ],
            [
              105.82834343794998,
              21.017293468769495
            ],
            [
              105.82834404074363,
              21.01729497331768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A65_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.470",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0172907228786,
          "to_latitude": 21.01729497331768,
          "to_longitude": 105.82834404074363,
          "from_longitude": 105.82834234251646
        }
      },
      {
        "id": 470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81804148150387,
            21.020636766142285,
            105.81843160083231,
            21.021203310887138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81804148150387,
              21.020636766142285
            ],
            [
              105.81823268301073,
              21.020914435968972
            ],
            [
              105.81826081859347,
              21.020955295376655
            ],
            [
              105.81843160083231,
              21.021203310887138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT01_Láng Hạ",
          "maTaiSan": "04.O12.DODV.471",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020636766142285,
          "to_latitude": 21.021203310887138,
          "to_longitude": 105.81843160083231,
          "from_longitude": 105.81804148150387
        }
      },
      {
        "id": 471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81848717387756,
            21.021231283765395,
            105.8190518517426,
            21.02205518502617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81848717387756,
              21.021231283765395
            ],
            [
              105.8186754598366,
              21.021526668497234
            ],
            [
              105.81886374655625,
              21.021822053889395
            ],
            [
              105.8190518517426,
              21.02205518502617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT02_Láng Hạ",
          "maTaiSan": "04.O12.DODV.472",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 108.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021231283765395,
          "to_latitude": 21.02205518502617,
          "to_longitude": 105.8190518517426,
          "from_longitude": 105.81848717387756
        }
      },
      {
        "id": 472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81909015583395,
            21.022127377015423,
            105.81950421009162,
            21.02267271066848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909015583395,
              21.022127377015423
            ],
            [
              105.8191881167086,
              21.022262154373536
            ],
            [
              105.81925250414749,
              21.02235231141632
            ],
            [
              105.81930789854326,
              21.022429876516785
            ],
            [
              105.81950421009162,
              21.02267271066848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT03_Láng Hạ",
          "maTaiSan": "04.O12.DODV.473",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022127377015423,
          "to_latitude": 21.02267271066848,
          "to_longitude": 105.81950421009162,
          "from_longitude": 105.81909015583395
        }
      },
      {
        "id": 473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81923014194074,
            21.02235231141632,
            105.81925250414749,
            21.02236781281663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81923014194074,
              21.02236781281663
            ],
            [
              105.819236388484,
              21.022363482468077
            ],
            [
              105.81924735874875,
              21.022355877751295
            ],
            [
              105.81925250414749,
              21.02235231141632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT03_Láng Hạ",
          "maTaiSan": "04.O12.DODV.474",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02236781281663,
          "to_latitude": 21.02235231141632,
          "to_longitude": 105.81925250414749,
          "from_longitude": 105.81923014194074
        }
      },
      {
        "id": 474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8194988695344,
            21.022696365706928,
            105.8198745218667,
            21.023235443362672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8194988695344,
              21.022696365706928
            ],
            [
              105.81969889235141,
              21.022983407301293
            ],
            [
              105.8198745218667,
              21.023235443362672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT04_Láng Hạ",
          "maTaiSan": "04.O12.DODV.475",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022696365706928,
          "to_latitude": 21.023235443362672,
          "to_longitude": 105.8198745218667,
          "from_longitude": 105.8194988695344
        }
      },
      {
        "id": 475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81999990595283,
            21.023192199552536,
            105.82060382709302,
            21.02328566253584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81999990595283,
              21.02328566253584
            ],
            [
              105.82007117145895,
              21.023276949634504
            ],
            [
              105.82007903727728,
              21.023275652610224
            ],
            [
              105.82010918381597,
              21.023270682213635
            ],
            [
              105.82031060053602,
              21.02323981408856
            ],
            [
              105.82053499179285,
              21.023204806500928
            ],
            [
              105.82060382709302,
              21.023192199552536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT05_La Thành",
          "maTaiSan": "04.O12.DODV.476",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02328566253584,
          "to_latitude": 21.023192199552536,
          "to_longitude": 105.82060382709302,
          "from_longitude": 105.81999990595283
        }
      },
      {
        "id": 476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82063164330368,
            21.023115440621293,
            105.8210066046682,
            21.0231956005691
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063164330368,
              21.0231956005691
            ],
            [
              105.8207234368288,
              21.02317955260305
            ],
            [
              105.8207239087073,
              21.023179469035636
            ],
            [
              105.82074645823798,
              21.023175526025653
            ],
            [
              105.82082975107879,
              21.023160961476204
            ],
            [
              105.8208439562299,
              21.023157879757022
            ],
            [
              105.82091913567149,
              21.023141562320603
            ],
            [
              105.82092710011615,
              21.02313983303934
            ],
            [
              105.82094701459881,
              21.02313551072153
            ],
            [
              105.8210066046682,
              21.023115440621293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT06_La Thành",
          "maTaiSan": "04.O12.DODV.477",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0231956005691,
          "to_latitude": 21.023115440621293,
          "to_longitude": 105.8210066046682,
          "from_longitude": 105.82063164330368
        }
      },
      {
        "id": 477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8207257190284,
            21.02300313419541,
            105.82074695736205,
            21.023175526025653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82072854705703,
              21.02300313419541
            ],
            [
              105.82074597026948,
              21.023028036967222
            ],
            [
              105.82074695736205,
              21.02304000446676
            ],
            [
              105.82074156571011,
              21.02305007345664
            ],
            [
              105.82073047187036,
              21.023054780412064
            ],
            [
              105.8207267671075,
              21.02305949777529
            ],
            [
              105.8207257190284,
              21.023064858964016
            ],
            [
              105.82072799279969,
              21.023077621416384
            ],
            [
              105.82074643263861,
              21.02317541866527
            ],
            [
              105.82074645823798,
              21.023175526025653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT06_La Thành",
          "maTaiSan": "04.O12.DODV.478",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02300313419541,
          "to_latitude": 21.023175526025653,
          "to_longitude": 105.82074645823798,
          "from_longitude": 105.82072854705703
        }
      },
      {
        "id": 478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81854559075094,
            21.02051807402423,
            105.81882597319856,
            21.021047054102745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81854559075094,
              21.02051807402423
            ],
            [
              105.81857622142347,
              21.02057897391233
            ],
            [
              105.81869231680548,
              21.020796557984266
            ],
            [
              105.81870918152858,
              21.020828166521476
            ],
            [
              105.81882597319856,
              21.021047054102745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT09_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.479",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02051807402423,
          "to_latitude": 21.021047054102745,
          "to_longitude": 105.81882597319856,
          "from_longitude": 105.81854559075094
        }
      },
      {
        "id": 479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81856638454927,
            21.020379352127605,
            105.81878640298078,
            21.02079769569048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81856638454927,
              21.020379352127605
            ],
            [
              105.81867646164879,
              21.020588652786124
            ],
            [
              105.81873604423348,
              21.020701944059237
            ],
            [
              105.81875456107946,
              21.02073715303086
            ],
            [
              105.81878640298078,
              21.02079769569048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT08_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.480",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020379352127605,
          "to_latitude": 21.02079769569048,
          "to_longitude": 105.81878640298078,
          "from_longitude": 105.81856638454927
        }
      },
      {
        "id": 480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81910217092172,
            21.021422428486403,
            105.81926314594263,
            21.021750981023363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81923377410331,
              21.021750981023363
            ],
            [
              105.81923578531934,
              21.021750049142003
            ],
            [
              105.81926314594263,
              21.021737376959496
            ],
            [
              105.8192295358413,
              21.021671434402442
            ],
            [
              105.81912305647863,
              21.021462418523527
            ],
            [
              105.81910217092172,
              21.021422428486403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.481",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021750981023363,
          "to_latitude": 21.021422428486403,
          "to_longitude": 105.81910217092172,
          "from_longitude": 105.81923377410331
        }
      },
      {
        "id": 481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81867646164879,
            21.02043201536204,
            105.81886482125063,
            21.020588652786124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81867646164879,
              21.020588652786124
            ],
            [
              105.81883568321958,
              21.020526627732092
            ],
            [
              105.81884553500701,
              21.020498309308586
            ],
            [
              105.81884170952299,
              21.020458016714205
            ],
            [
              105.81884646242527,
              21.0204414214293
            ],
            [
              105.81886482125063,
              21.02043201536204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT08_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.482",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020588652786124,
          "to_latitude": 21.02043201536204,
          "to_longitude": 105.81886482125063,
          "from_longitude": 105.81867646164879
        }
      },
      {
        "id": 482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81936773553352,
            21.02146827344799,
            105.81957399410196,
            21.02187117010564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81936773553352,
              21.02146827344799
            ],
            [
              105.81955728872643,
              21.02182617869479
            ],
            [
              105.81956323975372,
              21.021846636100268
            ],
            [
              105.81956404340191,
              21.02184846848738
            ],
            [
              105.81957273361495,
              21.021868300306178
            ],
            [
              105.81957399410196,
              21.02187117010564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.483",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02146827344799,
          "to_latitude": 21.02187117010564,
          "to_longitude": 105.81957399410196,
          "from_longitude": 105.81936773553352
        }
      },
      {
        "id": 483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81948966087562,
            21.021432465645,
            105.81968921453209,
            21.021821249934447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81948966087562,
              21.021432465645
            ],
            [
              105.81956107293578,
              21.021575508781993
            ],
            [
              105.81967310981229,
              21.021795515139125
            ],
            [
              105.81967338112024,
              21.021796048541738
            ],
            [
              105.8196875745317,
              21.021818642094043
            ],
            [
              105.81968921453209,
              21.021821249934447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.484",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021432465645,
          "to_latitude": 21.021821249934447,
          "to_longitude": 105.81968921453209,
          "from_longitude": 105.81948966087562
        }
      },
      {
        "id": 484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82105501119105,
            21.023018856282093,
            105.8212620911894,
            21.02309669713466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82105501119105,
              21.02309669713466
            ],
            [
              105.82118727514549,
              21.023051095989125
            ],
            [
              105.82122636285058,
              21.02303761990116
            ],
            [
              105.82122997913957,
              21.023035721020072
            ],
            [
              105.8212620911894,
              21.023018856282093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_La Thành",
          "maTaiSan": "04.O12.DODV.485",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02309669713466,
          "to_latitude": 21.023018856282093,
          "to_longitude": 105.8212620911894,
          "from_longitude": 105.82105501119105
        }
      },
      {
        "id": 485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82118727514549,
            21.023051095989125,
            105.82119951231533,
            21.023081785430602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82119951231533,
              21.023081785430602
            ],
            [
              105.82119588878474,
              21.023072702020006
            ],
            [
              105.8211873444044,
              21.023051270880586
            ],
            [
              105.82118727514549,
              21.023051095989125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_La Thành",
          "maTaiSan": "04.O12.DODV.486",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023081785430602,
          "to_latitude": 21.023051095989125,
          "to_longitude": 105.82118727514549,
          "from_longitude": 105.82119951231533
        }
      },
      {
        "id": 486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82066836834898,
            21.022672233206922,
            105.82104674907983,
            21.02308025585257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104674907983,
              21.02308025585257
            ],
            [
              105.82101914428972,
              21.023035879029916
            ],
            [
              105.82096736880852,
              21.022993112809978
            ],
            [
              105.82091274249797,
              21.02295394614092
            ],
            [
              105.82083012575555,
              21.02285546600021
            ],
            [
              105.82081817233363,
              21.022837228841894
            ],
            [
              105.82077836965932,
              21.022776503738683
            ],
            [
              105.82073615419166,
              21.022718208204385
            ],
            [
              105.82070345036466,
              21.02269602742343
            ],
            [
              105.82066836834898,
              21.022672233206922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.487",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02308025585257,
          "to_latitude": 21.022672233206922,
          "to_longitude": 105.82066836834898,
          "from_longitude": 105.82104674907983
        }
      },
      {
        "id": 487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82110939858076,
            21.02191505488502,
            105.82160535073794,
            21.022073185015465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82110939858076,
              21.022073185015465
            ],
            [
              105.82157208869721,
              21.021923189233746
            ],
            [
              105.8215764358278,
              21.021922125912834
            ],
            [
              105.82159708974973,
              21.021917075564264
            ],
            [
              105.82160535073794,
              21.02191505488502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.488",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022073185015465,
          "to_latitude": 21.02191505488502,
          "to_longitude": 105.82160535073794,
          "from_longitude": 105.82110939858076
        }
      },
      {
        "id": 488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82120043839492,
            21.022136049958423,
            105.8217379056913,
            21.022315037717682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8217379056913,
              21.022136049958423
            ],
            [
              105.82173446423094,
              21.02213719561773
            ],
            [
              105.82120043839492,
              21.022315037717682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.489",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022136049958423,
          "to_latitude": 21.022315037717682,
          "to_longitude": 105.82120043839492,
          "from_longitude": 105.8217379056913
        }
      },
      {
        "id": 489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82039743221968,
            21.021860985149075,
            105.8209722680011,
            21.022175550083883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82039743221968,
              21.022175550083883
            ],
            [
              105.82039957657055,
              21.022174864127468
            ],
            [
              105.8209722680011,
              21.02199178706052
            ],
            [
              105.82093003763727,
              21.021860985149075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Ngách 371/9, La Thành",
          "maTaiSan": "04.O12.DODV.490",
          "diaChiLapD": "Ngách 371/9, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022175550083883,
          "to_latitude": 21.021860985149075,
          "to_longitude": 105.82093003763727,
          "from_longitude": 105.82039743221968
        }
      },
      {
        "id": 490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82099687574733,
            21.021686726361693,
            105.82146812793326,
            21.021843555436813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82099687574733,
              21.021843555436813
            ],
            [
              105.82142837717447,
              21.021699386472832
            ],
            [
              105.82144110118533,
              21.021695334569756
            ],
            [
              105.82146120991813,
              21.021688930225526
            ],
            [
              105.82146812793326,
              21.021686726361693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.491",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021843555436813,
          "to_latitude": 21.021686726361693,
          "to_longitude": 105.82146812793326,
          "from_longitude": 105.82099687574733
        }
      },
      {
        "id": 491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82029433940949,
            21.02173665352167,
            105.82093655061954,
            21.021917551813694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82029433940949,
              21.021913511026007
            ],
            [
              105.82030292110078,
              21.021911201636883
            ],
            [
              105.8203228572736,
              21.021917551813694
            ],
            [
              105.82093655061954,
              21.02173665352167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Ngách 371/17, La Thành",
          "maTaiSan": "04.O12.DODV.492",
          "diaChiLapD": "Ngách 371/17, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021913511026007,
          "to_latitude": 21.02173665352167,
          "to_longitude": 105.82093655061954,
          "from_longitude": 105.82029433940949
        }
      },
      {
        "id": 492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82021912403812,
            21.02153420365243,
            105.82076750992148,
            21.02171300704611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82021912403812,
              21.02171300704611
            ],
            [
              105.82023240643092,
              21.02170867615256
            ],
            [
              105.82076750992148,
              21.02153420365243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Ngách 371/23, La Thành",
          "maTaiSan": "04.O12.DODV.493",
          "diaChiLapD": "Ngách 371/23, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02171300704611,
          "to_latitude": 21.02153420365243,
          "to_longitude": 105.82076750992148,
          "from_longitude": 105.82021912403812
        }
      },
      {
        "id": 493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82014344013253,
            21.021453713213507,
            105.82092853613774,
            21.021516676381513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82014344013253,
              21.021516676381513
            ],
            [
              105.8201494229418,
              21.021511274239433
            ],
            [
              105.82017544384377,
              21.02148777778499
            ],
            [
              105.82022848940024,
              21.021462671523064
            ],
            [
              105.82092853613774,
              21.021453713213507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.494",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021516676381513,
          "to_latitude": 21.021453713213507,
          "to_longitude": 105.82092853613774,
          "from_longitude": 105.82014344013253
        }
      },
      {
        "id": 494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8217094077051,
            21.02165238088634,
            105.8221211781864,
            21.022255035013384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8221211781864,
              21.022255035013384
            ],
            [
              105.82210802730984,
              21.02225124270673
            ],
            [
              105.82210148117785,
              21.022250217572857
            ],
            [
              105.82206002463315,
              21.02224373317041
            ],
            [
              105.8217094077051,
              21.02165238088634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngõ 16, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.495",
          "diaChiLapD": "Ngõ 16, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022255035013384,
          "to_latitude": 21.02165238088634,
          "to_longitude": 105.8217094077051,
          "from_longitude": 105.8221211781864
        }
      },
      {
        "id": 495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8220042607568,
            21.021977017837642,
            105.82221379414992,
            21.022344503737628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8220042607568,
              21.022344503737628
            ],
            [
              105.82207727901219,
              21.022303616757693
            ],
            [
              105.82208661276044,
              21.02228302234918
            ],
            [
              105.82208757084449,
              21.022280907782402
            ],
            [
              105.82210060388,
              21.022252152910472
            ],
            [
              105.82210148117785,
              21.022250217572857
            ],
            [
              105.82210361976358,
              21.022245498718817
            ],
            [
              105.82211938732874,
              21.02221070972356
            ],
            [
              105.82221379414992,
              21.021977017837642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.496",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022344503737628,
          "to_latitude": 21.021977017837642,
          "to_longitude": 105.82221379414992,
          "from_longitude": 105.8220042607568
        }
      },
      {
        "id": 496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82208757084449,
            21.022280907782402,
            105.82210843106569,
            21.0222876868283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82208757084449,
              21.022280907782402
            ],
            [
              105.82209432444427,
              21.022283102494917
            ],
            [
              105.82210538673965,
              21.022286697155703
            ],
            [
              105.82210843106569,
              21.0222876868283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.497",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022280907782402,
          "to_latitude": 21.0222876868283,
          "to_longitude": 105.82210843106569,
          "from_longitude": 105.82208757084449
        }
      },
      {
        "id": 497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81999628192904,
            21.022296341352565,
            105.82001288088642,
            21.022306375836262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81999628192904,
              21.022306375836262
            ],
            [
              105.82001288088642,
              21.022296341352565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.498",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022306375836262,
          "to_latitude": 21.022296341352565,
          "to_longitude": 105.82001288088642,
          "from_longitude": 105.81999628192904
        }
      },
      {
        "id": 498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82108477445557,
            21.021134902347193,
            105.82134108138557,
            21.02117488459919
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82134108138557,
              21.021134902347193
            ],
            [
              105.82125846360239,
              21.02114867985777
            ],
            [
              105.82119232188512,
              21.02115970965399
            ],
            [
              105.82108477445557,
              21.02117488459919
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.499",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021134902347193,
          "to_latitude": 21.02117488459919,
          "to_longitude": 105.82108477445557,
          "from_longitude": 105.82134108138557
        }
      },
      {
        "id": 499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82134695583171,
            21.02112079177997,
            105.82141756660052,
            21.02139894615923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82134695583171,
              21.02112079177997
            ],
            [
              105.82134728356664,
              21.02112214232713
            ],
            [
              105.8213483767903,
              21.021126609824616
            ],
            [
              105.82135366581733,
              21.021129436673323
            ],
            [
              105.82135421440655,
              21.02113176796094
            ],
            [
              105.8213802788798,
              21.021242542926675
            ],
            [
              105.82141756660052,
              21.02139894615923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.500",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02112079177997,
          "to_latitude": 21.02139894615923,
          "to_longitude": 105.82141756660052,
          "from_longitude": 105.82134695583171
        }
      },
      {
        "id": 500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82147834594738,
            21.02106083552339,
            105.82192699970432,
            21.02128547025331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82192699970432,
              21.02106083552339
            ],
            [
              105.82192514167643,
              21.021061980760287
            ],
            [
              105.82187878784516,
              21.021090559724456
            ],
            [
              105.82171628684341,
              21.021162441388043
            ],
            [
              105.82154393868603,
              21.02121567096959
            ],
            [
              105.8215587302693,
              21.021257681738614
            ],
            [
              105.82147834594738,
              21.02128547025331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.501",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02106083552339,
          "to_latitude": 21.02128547025331,
          "to_longitude": 105.82147834594738,
          "from_longitude": 105.82192699970432
        }
      },
      {
        "id": 501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82119293439737,
            21.020535360533955,
            105.8213298039437,
            21.021124676281683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8213298039437,
              21.021124676281683
            ],
            [
              105.82132974010221,
              21.021123558395832
            ],
            [
              105.82132817339702,
              21.02109517033346
            ],
            [
              105.82126580825853,
              21.020857106431727
            ],
            [
              105.82122375678708,
              21.02066093541452
            ],
            [
              105.82119293439737,
              21.020535360533955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Ngách 44/21, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.502",
          "diaChiLapD": "Ngách 44/21, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021124676281683,
          "to_latitude": 21.020535360533955,
          "to_longitude": 105.82119293439737,
          "from_longitude": 105.8213298039437
        }
      },
      {
        "id": 502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82107941720375,
            21.0210659572862,
            105.82144339061558,
            21.021151164640397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82107941720375,
              21.021151164640397
            ],
            [
              105.82130300214135,
              21.021101449169436
            ],
            [
              105.82132047967798,
              21.021097480904437
            ],
            [
              105.82132817339702,
              21.02109517033346
            ],
            [
              105.821388091412,
              21.021077172342935
            ],
            [
              105.82144339061558,
              21.0210659572862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.503",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021151164640397,
          "to_latitude": 21.0210659572862,
          "to_longitude": 105.82144339061558,
          "from_longitude": 105.82107941720375
        }
      },
      {
        "id": 503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82129751876634,
            21.02073773743578,
            105.82179812438103,
            21.02086466687405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82129751876634,
              21.02086466687405
            ],
            [
              105.82176449629671,
              21.020752051651556
            ],
            [
              105.82176677566277,
              21.020751081413234
            ],
            [
              105.82179599857038,
              21.02073864190036
            ],
            [
              105.82179812438103,
              21.02073773743578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Ngõ 56, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.504",
          "diaChiLapD": "Ngõ 56, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02086466687405,
          "to_latitude": 21.02073773743578,
          "to_longitude": 105.82179812438103,
          "from_longitude": 105.82129751876634
        }
      },
      {
        "id": 504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82044999690025,
            21.02021050749763,
            105.82090129992355,
            21.020313487684444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82044999690025,
              21.020313487684444
            ],
            [
              105.8206610780424,
              21.020257253387097
            ],
            [
              105.82079065863779,
              21.020232037820897
            ],
            [
              105.82085322481171,
              21.020219862992622
            ],
            [
              105.82090129992355,
              21.02021050749763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.505",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020313487684444,
          "to_latitude": 21.02021050749763,
          "to_longitude": 105.82090129992355,
          "from_longitude": 105.82044999690025
        }
      },
      {
        "id": 505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81968516277406,
            21.019146880298695,
            105.8198387491523,
            21.019515522124426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81970469592919,
              21.019146880298695
            ],
            [
              105.81970363760412,
              21.019153361534325
            ],
            [
              105.8196995896019,
              21.019178164679474
            ],
            [
              105.81968693653143,
              21.019386826769612
            ],
            [
              105.81968691263252,
              21.019387206240005
            ],
            [
              105.81968516277406,
              21.019416061024046
            ],
            [
              105.81968523553248,
              21.019470027566868
            ],
            [
              105.81968647060464,
              21.019475291040063
            ],
            [
              105.81968851152904,
              21.019480328436263
            ],
            [
              105.81969131346904,
              21.019485032487527
            ],
            [
              105.81969481622663,
              21.019489301419267
            ],
            [
              105.8197205932726,
              21.01950782702821
            ],
            [
              105.81977462631347,
              21.019512313093557
            ],
            [
              105.8198387491523,
              21.019515522124426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.506",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019146880298695,
          "to_latitude": 21.019515522124426,
          "to_longitude": 105.8198387491523,
          "from_longitude": 105.81970469592919
        }
      },
      {
        "id": 506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81929205558784,
            21.019949989210488,
            105.81994719180537,
            21.02001617637882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994719180537,
              21.019950341130865
            ],
            [
              105.8199384799175,
              21.01995010764097
            ],
            [
              105.81993400516146,
              21.019949989210488
            ],
            [
              105.81963669090437,
              21.0199732088206
            ],
            [
              105.81936203049798,
              21.02000745196542
            ],
            [
              105.81929205558784,
              21.02001617637882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48\"_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.507",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019950341130865,
          "to_latitude": 21.02001617637882,
          "to_longitude": 105.81929205558784,
          "from_longitude": 105.81994719180537
        }
      },
      {
        "id": 507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81996338881008,
            21.019694075303793,
            105.8203736718376,
            21.019790799802887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81996338881008,
              21.01978995748897
            ],
            [
              105.81997696194577,
              21.019790799802887
            ],
            [
              105.81998592336039,
              21.01972900119236
            ],
            [
              105.81999901809883,
              21.0197194099302
            ],
            [
              105.82006454581325,
              21.019711242284043
            ],
            [
              105.8203736718376,
              21.019694075303793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.508",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01978995748897,
          "to_latitude": 21.019694075303793,
          "to_longitude": 105.8203736718376,
          "from_longitude": 105.81996338881008
        }
      },
      {
        "id": 508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82055126262843,
            21.01970418027979,
            105.82077026492479,
            21.02022079350911
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82055126262843,
              21.01970418027979
            ],
            [
              105.8207515470775,
              21.020145241319593
            ],
            [
              105.82076464977044,
              21.02020020355635
            ],
            [
              105.82077026492479,
              21.02022079350911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Ngách 82/29, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.509",
          "diaChiLapD": "Ngách 82/29, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01970418027979,
          "to_latitude": 21.02022079350911,
          "to_longitude": 105.82077026492479,
          "from_longitude": 105.82055126262843
        }
      },
      {
        "id": 509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82071613666996,
            21.019689928600606,
            105.82094206849561,
            21.020197391895156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82071613666996,
              21.019689928600606
            ],
            [
              105.82091185384697,
              21.02012654050501
            ],
            [
              105.82094068670965,
              21.02019415598111
            ],
            [
              105.82094206849561,
              21.020197391895156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Ngách 82/23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.510",
          "diaChiLapD": "Ngách 82/23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019689928600606,
          "to_latitude": 21.020197391895156,
          "to_longitude": 105.82094206849561,
          "from_longitude": 105.82071613666996
        }
      },
      {
        "id": 510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82096017009299,
            21.019603681521332,
            105.82118888555316,
            21.0201403731939
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82096017009299,
              21.019603681521332
            ],
            [
              105.82118888555316,
              21.02008690018403
            ],
            [
              105.82118221452382,
              21.020119087249423
            ],
            [
              105.82118701263909,
              21.02013943184098
            ],
            [
              105.82118716016528,
              21.0201403731939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngách 82/11, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.511",
          "diaChiLapD": "Ngách 82/11, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019603681521332,
          "to_latitude": 21.0201403731939,
          "to_longitude": 105.82118716016528,
          "from_longitude": 105.82096017009299
        }
      },
      {
        "id": 511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82118108605032,
            21.01952248905929,
            105.82144544938885,
            21.02005745813729
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82144544938885,
              21.02005745813729
            ],
            [
              105.82129403336607,
              21.0197510516647
            ],
            [
              105.82118108605032,
              21.01952248905929
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.512",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02005745813729,
          "to_latitude": 21.01952248905929,
          "to_longitude": 105.82118108605032,
          "from_longitude": 105.82144544938885
        }
      },
      {
        "id": 512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82129403336607,
            21.019740983174067,
            105.82131783785267,
            21.0197510516647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82129403336607,
              21.0197510516647
            ],
            [
              105.82131783785267,
              21.019740983174067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.513",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0197510516647,
          "to_latitude": 21.019740983174067,
          "to_longitude": 105.82131783785267,
          "from_longitude": 105.82129403336607
        }
      },
      {
        "id": 513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81991746637904,
            21.019202021089484,
            105.81997443716868,
            21.01952250401466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81991746637904,
              21.01952250401466
            ],
            [
              105.81993256077442,
              21.019490169807046
            ],
            [
              105.8199457115013,
              21.019474811177034
            ],
            [
              105.81995711861265,
              21.01946639231236
            ],
            [
              105.81995414902586,
              21.019437537695705
            ],
            [
              105.81995383881298,
              21.019423378403165
            ],
            [
              105.81994625210609,
              21.019202627531048
            ],
            [
              105.81997443716868,
              21.019202021089484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.514",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01952250401466,
          "to_latitude": 21.019202021089484,
          "to_longitude": 105.81997443716868,
          "from_longitude": 105.81991746637904
        }
      },
      {
        "id": 514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81997443716868,
            21.01918362192511,
            105.82015622084411,
            21.019202021089484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81997602673513,
              21.01918362192511
            ],
            [
              105.81997473566399,
              21.019198554873
            ],
            [
              105.81997443716868,
              21.019202021089484
            ],
            [
              105.82015622084411,
              21.019198107174162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.515",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01918362192511,
          "to_latitude": 21.019198107174162,
          "to_longitude": 105.82015622084411,
          "from_longitude": 105.81997602673513
        }
      },
      {
        "id": 515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82055822848096,
            21.019173813580714,
            105.82055848200217,
            21.01919166643801
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82055822848096,
              21.019173813580714
            ],
            [
              105.82055832034455,
              21.01918040578856
            ],
            [
              105.82055845385949,
              21.019189687604282
            ],
            [
              105.82055848200217,
              21.01919166643801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.516",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019173813580714,
          "to_latitude": 21.01919166643801,
          "to_longitude": 105.82055848200217,
          "from_longitude": 105.82055822848096
        }
      },
      {
        "id": 516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82087448261203,
            21.019171245782335,
            105.82113825000462,
            21.019422739385536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82113825000462,
              21.019422739385536
            ],
            [
              105.82108100984703,
              21.01935079731196
            ],
            [
              105.82107796695922,
              21.019339164894028
            ],
            [
              105.8210752178627,
              21.019328658284596
            ],
            [
              105.82106923330032,
              21.019305782250374
            ],
            [
              105.8210682043394,
              21.019301803084893
            ],
            [
              105.8210537358504,
              21.019245825006703
            ],
            [
              105.8210315864027,
              21.01917847986161
            ],
            [
              105.82102306436565,
              21.019171272600946
            ],
            [
              105.82100522848488,
              21.019171245782335
            ],
            [
              105.82087448261203,
              21.019177772128554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.517",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019422739385536,
          "to_latitude": 21.019177772128554,
          "to_longitude": 105.82087448261203,
          "from_longitude": 105.82113825000462
        }
      },
      {
        "id": 517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8199823150951,
            21.01939886458442,
            105.82048344168045,
            21.019413393256045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8199823150951,
              21.019413393256045
            ],
            [
              105.82019887626056,
              21.019405318475773
            ],
            [
              105.82026806576528,
              21.019402732200675
            ],
            [
              105.82031427961974,
              21.01940100456887
            ],
            [
              105.82048344168045,
              21.01939886458442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.518",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019413393256045,
          "to_latitude": 21.01939886458442,
          "to_longitude": 105.82048344168045,
          "from_longitude": 105.8199823150951
        }
      },
      {
        "id": 518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81996845759407,
            21.019429957209276,
            105.82030778408902,
            21.019441818663072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81996845759407,
              21.019441818663072
            ],
            [
              105.82030778408902,
              21.019429957209276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.519",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019441818663072,
          "to_latitude": 21.019429957209276,
          "to_longitude": 105.82030778408902,
          "from_longitude": 105.81996845759407
        }
      },
      {
        "id": 519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82050709665228,
            21.01933494743368,
            105.82105781864344,
            21.019402884108263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82050709665228,
              21.01939663241875
            ],
            [
              105.8206293402591,
              21.019395085306428
            ],
            [
              105.82071470224064,
              21.019399699931594
            ],
            [
              105.82077360721489,
              21.019402884108263
            ],
            [
              105.8208046317931,
              21.019396611435447
            ],
            [
              105.82080659780506,
              21.01939604014195
            ],
            [
              105.82094788852847,
              21.019355001247536
            ],
            [
              105.82105781864344,
              21.01933494743368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.520",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01939663241875,
          "to_latitude": 21.01933494743368,
          "to_longitude": 105.82105781864344,
          "from_longitude": 105.82050709665228
        }
      },
      {
        "id": 520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82026806576528,
            21.019402732200675,
            105.82026884795378,
            21.019421746768693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82026806576528,
              21.019402732200675
            ],
            [
              105.82026854501727,
              21.019414375190564
            ],
            [
              105.82026877608573,
              21.01942003911371
            ],
            [
              105.82026884795378,
              21.019421746768693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.521",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019402732200675,
          "to_latitude": 21.019421746768693,
          "to_longitude": 105.82026884795378,
          "from_longitude": 105.82026806576528
        }
      },
      {
        "id": 521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82071470224064,
            21.019399699931594,
            105.82071982640352,
            21.01941161315393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82071470224064,
              21.019399699931594
            ],
            [
              105.82071714495966,
              21.019405378473298
            ],
            [
              105.8207190859143,
              21.019409896080077
            ],
            [
              105.82071982640352,
              21.01941161315393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.522",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019399699931594,
          "to_latitude": 21.01941161315393,
          "to_longitude": 105.82071982640352,
          "from_longitude": 105.82071470224064
        }
      },
      {
        "id": 522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81936203049798,
            21.02000745196542,
            105.81941340830039,
            21.020226295658304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81936203049798,
              21.02000745196542
            ],
            [
              105.8193621384143,
              21.020008012349376
            ],
            [
              105.8193922229193,
              21.020163983845045
            ],
            [
              105.81941340830039,
              21.020226295658304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48\"_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.523",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02000745196542,
          "to_latitude": 21.020226295658304,
          "to_longitude": 105.81941340830039,
          "from_longitude": 105.81936203049798
        }
      },
      {
        "id": 523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81910380213806,
            21.02185936028204,
            105.81961188636991,
            21.022104770376394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81910380213806,
              21.022104770376394
            ],
            [
              105.81918703968333,
              21.022054274750367
            ],
            [
              105.81930887409703,
              21.021993280128065
            ],
            [
              105.81933068025185,
              21.02198236256201
            ],
            [
              105.81937517910521,
              21.021960983796443
            ],
            [
              105.81940698546605,
              21.021947329140524
            ],
            [
              105.81961188636991,
              21.02185936028204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.524",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022104770376394,
          "to_latitude": 21.02185936028204,
          "to_longitude": 105.81961188636991,
          "from_longitude": 105.81910380213806
        }
      },
      {
        "id": 524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8196787684193,
            21.021656947755172,
            105.82008174515857,
            21.021830580024776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8196787684193,
              21.021830580024776
            ],
            [
              105.81982939235193,
              21.021765679841668
            ],
            [
              105.8198802564306,
              21.021743764459845
            ],
            [
              105.82008174515857,
              21.021656947755172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.525",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021830580024776,
          "to_latitude": 21.021656947755172,
          "to_longitude": 105.82008174515857,
          "from_longitude": 105.8196787684193
        }
      },
      {
        "id": 525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81978516832218,
            21.02185400593738,
            105.82010246170263,
            21.022003580649866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81978516832218,
              21.022003580649866
            ],
            [
              105.81979116585154,
              21.021999457417486
            ],
            [
              105.81980520264135,
              21.021991286014412
            ],
            [
              105.8198427664062,
              21.021981500046525
            ],
            [
              105.81996325992509,
              21.021922345880093
            ],
            [
              105.82001986666307,
              21.021894554380545
            ],
            [
              105.82010246170263,
              21.02185400593738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18A_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.526",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022003580649866,
          "to_latitude": 21.02185400593738,
          "to_longitude": 105.82010246170263,
          "from_longitude": 105.81978516832218
        }
      },
      {
        "id": 526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82055626170632,
            21.022637802983134,
            105.82098866864627,
            21.023038706915568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82098866864627,
              21.023038706915568
            ],
            [
              105.82095388272907,
              21.023007521136858
            ],
            [
              105.8208812285346,
              21.022940193076572
            ],
            [
              105.82081339627238,
              21.02286098777194
            ],
            [
              105.82073380316615,
              21.022753544726115
            ],
            [
              105.82071891599055,
              21.022737819137326
            ],
            [
              105.82066110516085,
              21.022677995609904
            ],
            [
              105.82059864535944,
              21.022650384570902
            ],
            [
              105.82058755105177,
              21.02264709164399
            ],
            [
              105.82055626170632,
              21.022637802983134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.527",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023038706915568,
          "to_latitude": 21.022637802983134,
          "to_longitude": 105.82055626170632,
          "from_longitude": 105.82098866864627
        }
      },
      {
        "id": 527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81880365529904,
            21.020826114864875,
            105.81908477239094,
            21.02138470049506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81880365529904,
              21.020826114864875
            ],
            [
              105.8188903786333,
              21.021004655487086
            ],
            [
              105.81893579463346,
              21.021097528793955
            ],
            [
              105.81901639670271,
              21.021252898529635
            ],
            [
              105.81908477239094,
              21.02138470049506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.528",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020826114864875,
          "to_latitude": 21.02138470049506,
          "to_longitude": 105.81908477239094,
          "from_longitude": 105.81880365529904
        }
      },
      {
        "id": 528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81898277104021,
            21.021252898529635,
            105.81901639670271,
            21.021268257226716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81898277104021,
              21.021268257226716
            ],
            [
              105.8189842286466,
              21.021267591756196
            ],
            [
              105.81900794375034,
              21.021256759880227
            ],
            [
              105.81901639670271,
              21.021252898529635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.529",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021268257226716,
          "to_latitude": 21.021252898529635,
          "to_longitude": 105.81901639670271,
          "from_longitude": 105.81898277104021
        }
      },
      {
        "id": 529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8206925433033,
            21.02269602742343,
            105.82070345036466,
            21.022701636700802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82070345036466,
              21.02269602742343
            ],
            [
              105.82070078067777,
              21.022697400564173
            ],
            [
              105.8206965147402,
              21.022699594310833
            ],
            [
              105.82069482831754,
              21.022700461416424
            ],
            [
              105.8206925433033,
              21.022701636700802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.530",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02269602742343,
          "to_latitude": 21.022701636700802,
          "to_longitude": 105.8206925433033,
          "from_longitude": 105.82070345036466
        }
      },
      {
        "id": 530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81884272809266,
            21.021085668579175,
            105.81915275831871,
            21.02163795486177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81915275831871,
              21.02163795486177
            ],
            [
              105.81899045088893,
              21.02134882148476
            ],
            [
              105.81895467808,
              21.021285095860758
            ],
            [
              105.81884272809266,
              21.021085668579175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.531",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02163795486177,
          "to_latitude": 21.021085668579175,
          "to_longitude": 105.81884272809266,
          "from_longitude": 105.81915275831871
        }
      },
      {
        "id": 531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81996325992509,
            21.021922345880093,
            105.820017152038,
            21.022011897481505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81996325992509,
              21.021922345880093
            ],
            [
              105.820017152038,
              21.022011897481505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18A_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.532",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021922345880093,
          "to_latitude": 21.022011897481505,
          "to_longitude": 105.820017152038,
          "from_longitude": 105.81996325992509
        }
      },
      {
        "id": 532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82001986666307,
            21.021894554380545,
            105.82007148452278,
            21.021987771382864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007148452278,
              21.021987771382864
            ],
            [
              105.82001986666307,
              21.021894554380545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18A_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.533",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021987771382864,
          "to_latitude": 21.021894554380545,
          "to_longitude": 105.82001986666307,
          "from_longitude": 105.82007148452278
        }
      },
      {
        "id": 533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81971942020701,
            21.02187237149905,
            105.81994247597774,
            21.022181582634342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81971942020701,
              21.02187237149905
            ],
            [
              105.81979895048418,
              21.02198261898173
            ],
            [
              105.81980117024385,
              21.021985696413967
            ],
            [
              105.81980520264135,
              21.021991286014412
            ],
            [
              105.81980677780275,
              21.021993470552854
            ],
            [
              105.81980981276418,
              21.021997677604922
            ],
            [
              105.81983963896165,
              21.022039024451217
            ],
            [
              105.81994247597774,
              21.022181582634342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18B_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.534",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02187237149905,
          "to_latitude": 21.022181582634342,
          "to_longitude": 105.81994247597774,
          "from_longitude": 105.81971942020701
        }
      },
      {
        "id": 534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8218965970068,
            21.022370368454602,
            105.82207469767424,
            21.02237509784651
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82207469767424,
              21.022374089895813
            ],
            [
              105.82205940484246,
              21.022370368454602
            ],
            [
              105.8220493864289,
              21.0223706589402
            ],
            [
              105.82196766462803,
              21.02237303286448
            ],
            [
              105.8218965970068,
              21.02237509784651
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.535",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022374089895813,
          "to_latitude": 21.02237509784651,
          "to_longitude": 105.8218965970068,
          "from_longitude": 105.82207469767424
        }
      },
      {
        "id": 535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82098673487856,
            21.021554452153577,
            105.82138310139479,
            21.021677577849946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82138310139479,
              21.021554452153577
            ],
            [
              105.8213810961766,
              21.02155489538555
            ],
            [
              105.82136002163043,
              21.02155954309274
            ],
            [
              105.82135228225272,
              21.021561249627844
            ],
            [
              105.82129510650908,
              21.021579048304396
            ],
            [
              105.82098673487856,
              21.021677577849946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.536",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021554452153577,
          "to_latitude": 21.021677577849946,
          "to_longitude": 105.82098673487856,
          "from_longitude": 105.82138310139479
        }
      },
      {
        "id": 536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81924725085752,
            21.019630725729257,
            105.81996559624615,
            21.01977284154206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81924725085752,
              21.019631754868097
            ],
            [
              105.819256703276,
              21.019630725729257
            ],
            [
              105.81936901522195,
              21.019660766222053
            ],
            [
              105.8194805802736,
              21.019693364562716
            ],
            [
              105.81957042009319,
              21.019720461176338
            ],
            [
              105.81979583574865,
              21.01975868066606
            ],
            [
              105.81994576974397,
              21.01977118756331
            ],
            [
              105.81995102462531,
              21.019770773845554
            ],
            [
              105.81996559624615,
              21.01977284154206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.537",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019631754868097,
          "to_latitude": 21.01977284154206,
          "to_longitude": 105.81996559624615,
          "from_longitude": 105.81924725085752
        }
      },
      {
        "id": 537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8191383147259,
            21.019095219963067,
            105.81931853902034,
            21.019622697889602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8191704231024,
              21.019622697889602
            ],
            [
              105.8191697047139,
              21.01961991670097
            ],
            [
              105.8191551459952,
              21.01956173861834
            ],
            [
              105.81914102057085,
              21.019494651727662
            ],
            [
              105.8191383147259,
              21.019425820810063
            ],
            [
              105.81918790841785,
              21.019336120077895
            ],
            [
              105.81920866928667,
              21.01929857050656
            ],
            [
              105.81931853902034,
              21.019095219963067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.538",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019622697889602,
          "to_latitude": 21.019095219963067,
          "to_longitude": 105.81931853902034,
          "from_longitude": 105.8191704231024
        }
      },
      {
        "id": 538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81917646136296,
            21.019330893133617,
            105.81918790841785,
            21.019336120077895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81917646136296,
              21.019330893133617
            ],
            [
              105.81917807957481,
              21.01933163230934
            ],
            [
              105.81918463343251,
              21.019334626557473
            ],
            [
              105.81918790841785,
              21.019336120077895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.539",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019330893133617,
          "to_latitude": 21.019336120077895,
          "to_longitude": 105.81918790841785,
          "from_longitude": 105.81917646136296
        }
      },
      {
        "id": 539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82031900914302,
            21.019189839943973,
            105.82069743992486,
            21.0191948130569
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82031900914302,
              21.0191948130569
            ],
            [
              105.82055848200217,
              21.01919166643801
            ],
            [
              105.82064423955731,
              21.019190539188852
            ],
            [
              105.82069743992486,
              21.019189839943973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.540",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0191948130569,
          "to_latitude": 21.019189839943973,
          "to_longitude": 105.82069743992486,
          "from_longitude": 105.82031900914302
        }
      },
      {
        "id": 540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149943807102,
            21.020152843657886,
            105.82183616538381,
            21.020962026128714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82149943807102,
              21.020152843657886
            ],
            [
              105.82156580205239,
              21.020289225539265
            ],
            [
              105.82158370061411,
              21.02033297627512
            ],
            [
              105.82158592269813,
              21.020338407480825
            ],
            [
              105.82158670661632,
              21.020340323050345
            ],
            [
              105.82159427965551,
              21.02035883512726
            ],
            [
              105.82163874190105,
              21.020467516938
            ],
            [
              105.82164281361638,
              21.020477468888103
            ],
            [
              105.821659742074,
              21.02051884881466
            ],
            [
              105.82166588078749,
              21.02053173439379
            ],
            [
              105.82167266761567,
              21.02054597800383
            ],
            [
              105.82167663423277,
              21.020554304653324
            ],
            [
              105.82167961211142,
              21.020560555045645
            ],
            [
              105.82174373583615,
              21.020695148436822
            ],
            [
              105.8217583190086,
              21.020730550388095
            ],
            [
              105.82176677566277,
              21.020751081413234
            ],
            [
              105.82178123113374,
              21.020786174172365
            ],
            [
              105.82183616538381,
              21.020962026128714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.541",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 97.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020152843657886,
          "to_latitude": 21.020962026128714,
          "to_longitude": 105.82183616538381,
          "from_longitude": 105.82149943807102
        }
      },
      {
        "id": 541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204697567476,
            21.0194746829919,
            105.82118335135148,
            21.019682113802112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204697567476,
              21.019682113802112
            ],
            [
              105.820743851093,
              21.019631084253756
            ],
            [
              105.82101466508512,
              21.019538965167268
            ],
            [
              105.82118335135148,
              21.0194746829919
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.542",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019682113802112,
          "to_latitude": 21.0194746829919,
          "to_longitude": 105.82118335135148,
          "from_longitude": 105.8204697567476
        }
      },
      {
        "id": 542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8197576388507,
            21.02037875417589,
            105.820318104319,
            21.020583098795232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8197576388507,
              21.020583098795232
            ],
            [
              105.82003523155429,
              21.020492958223425
            ],
            [
              105.82007077868644,
              21.020478607094084
            ],
            [
              105.82022575810126,
              21.020416036692666
            ],
            [
              105.820318104319,
              21.02037875417589
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.543",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020583098795232,
          "to_latitude": 21.02037875417589,
          "to_longitude": 105.820318104319,
          "from_longitude": 105.8197576388507
        }
      },
      {
        "id": 543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227602639979,
            21.021675091881942,
            105.82231051720528,
            21.021763273277536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82230306403179,
              21.02176215138619
            ],
            [
              105.82230674620423,
              21.021762801971768
            ],
            [
              105.82230977228639,
              21.021763273277536
            ],
            [
              105.82231051720528,
              21.02175982838496
            ],
            [
              105.82230360331302,
              21.021758396778036
            ],
            [
              105.82229956514989,
              21.021757560042424
            ],
            [
              105.82229488819718,
              21.02171461619033
            ],
            [
              105.82227602639979,
              21.021675091881942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.544",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02176215138619,
          "to_latitude": 21.021675091881942,
          "to_longitude": 105.82227602639979,
          "from_longitude": 105.82230306403179
        }
      },
      {
        "id": 544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82198644748938,
            21.022654131151583,
            105.82242059924344,
            21.022791401281857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82198644748938,
              21.022791401281857
            ],
            [
              105.82203929442764,
              21.02277864702953
            ],
            [
              105.82214928841562,
              21.022739992913852
            ],
            [
              105.82219908193953,
              21.022722494274767
            ],
            [
              105.82231910347656,
              21.0226803159633
            ],
            [
              105.82234341573782,
              21.02268242757783
            ],
            [
              105.82242059924344,
              21.022654131151583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_La Thành",
          "maTaiSan": "04.O12.DODV.545",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022791401281857,
          "to_latitude": 21.022654131151583,
          "to_longitude": 105.82242059924344,
          "from_longitude": 105.82198644748938
        }
      },
      {
        "id": 545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82250816230625,
            21.022447836270988,
            105.82294044956896,
            21.02262035881857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82250816230625,
              21.02262035881857
            ],
            [
              105.8227211024611,
              21.022535375884615
            ],
            [
              105.82282822996316,
              21.02249262191358
            ],
            [
              105.82294044956896,
              21.022447836270988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_La Thành",
          "maTaiSan": "04.O12.DODV.546",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02262035881857,
          "to_latitude": 21.022447836270988,
          "to_longitude": 105.82294044956896,
          "from_longitude": 105.82250816230625
        }
      },
      {
        "id": 546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82281730131716,
            21.022288825733042,
            105.82284512409552,
            21.02233884907339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82284512409552,
              21.02233884907339
            ],
            [
              105.82281730131716,
              21.022288825733042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_La Thành",
          "maTaiSan": "04.O12.DODV.547",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02233884907339,
          "to_latitude": 21.022288825733042,
          "to_longitude": 105.82281730131716,
          "from_longitude": 105.82284512409552
        }
      },
      {
        "id": 547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299238805567,
            21.02235082890477,
            105.82318440291724,
            21.02242962521959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82299238805567,
              21.02242962521959
            ],
            [
              105.82310089764766,
              21.022385096780006
            ],
            [
              105.82310881328173,
              21.022381848408603
            ],
            [
              105.82318440291724,
              21.02235082890477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_La Thành",
          "maTaiSan": "04.O12.DODV.548",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02242962521959,
          "to_latitude": 21.02235082890477,
          "to_longitude": 105.82318440291724,
          "from_longitude": 105.82299238805567
        }
      },
      {
        "id": 548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82305865049995,
            21.022281037347618,
            105.82312250033085,
            21.022409354945943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82312250033085,
              21.022409354945943
            ],
            [
              105.82312006466836,
              21.02240446221701
            ],
            [
              105.82310881328173,
              21.022381848408603
            ],
            [
              105.82309422265436,
              21.0223525379845
            ],
            [
              105.82305865049995,
              21.022281037347618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.1_La Thành",
          "maTaiSan": "04.O12.DODV.549",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022409354945943,
          "to_latitude": 21.022281037347618,
          "to_longitude": 105.82305865049995,
          "from_longitude": 105.82312250033085
        }
      },
      {
        "id": 549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8232355522818,
            21.022174350054343,
            105.82363391227626,
            21.02233345166442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82363391227626,
              21.022174350054343
            ],
            [
              105.82353551452235,
              21.022213648963234
            ],
            [
              105.82353469095052,
              21.022213978112163
            ],
            [
              105.82352196876086,
              21.022219058936688
            ],
            [
              105.82352101944569,
              21.022219438371774
            ],
            [
              105.82351691213798,
              21.022221078645547
            ],
            [
              105.82350491561789,
              21.02222587053594
            ],
            [
              105.82340874374817,
              21.02226428066058
            ],
            [
              105.82340067311351,
              21.022267504505688
            ],
            [
              105.8232355522818,
              21.02233345166442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_La Thành",
          "maTaiSan": "04.O12.DODV.550",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022174350054343,
          "to_latitude": 21.02233345166442,
          "to_longitude": 105.8232355522818,
          "from_longitude": 105.82363391227626
        }
      },
      {
        "id": 550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82264672279062,
            21.021583254986126,
            105.82271486726431,
            21.02177014697317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82271486726431,
              21.02177014697317
            ],
            [
              105.82267981149558,
              21.021674003740728
            ],
            [
              105.82264672279062,
              21.021583254986126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84B_Ngõ 39, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.551",
          "diaChiLapD": "Ngõ 39, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02177014697317,
          "to_latitude": 21.021583254986126,
          "to_longitude": 105.82264672279062,
          "from_longitude": 105.82271486726431
        }
      },
      {
        "id": 551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82230500990488,
            21.021674003740728,
            105.82267981149558,
            21.021761764822553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82267981149558,
              21.021674003740728
            ],
            [
              105.8223591067617,
              21.021761764822553
            ],
            [
              105.82230810486878,
              21.021749359012638
            ],
            [
              105.82230500990488,
              21.02174860623333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84B_Ngõ 39, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.552",
          "diaChiLapD": "Ngõ 39, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021674003740728,
          "to_latitude": 21.02174860623333,
          "to_longitude": 105.82230500990488,
          "from_longitude": 105.82267981149558
        }
      },
      {
        "id": 552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82230407772417,
            21.021755092290064,
            105.8225601925899,
            21.02181646567211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8225601925899,
              21.021806599583854
            ],
            [
              105.82247368307549,
              21.02181646567211
            ],
            [
              105.8223073212181,
              21.02175626615788
            ],
            [
              105.82230407772417,
              21.021755092290064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84A_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.553",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021806599583854,
          "to_latitude": 21.021755092290064,
          "to_longitude": 105.82230407772417,
          "from_longitude": 105.8225601925899
        }
      },
      {
        "id": 553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82211437458072,
            21.02192451716062,
            105.82227054394122,
            21.022309339137482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82211437458072,
              21.022309339137482
            ],
            [
              105.82217199930429,
              21.022181810502616
            ],
            [
              105.82217369204373,
              21.02217806296443
            ],
            [
              105.82217561099974,
              21.022173816655183
            ],
            [
              105.8221795211434,
              21.02216516381647
            ],
            [
              105.82218107132255,
              21.022161733096443
            ],
            [
              105.82218186778871,
              21.022159968858887
            ],
            [
              105.8221831508973,
              21.022157130139647
            ],
            [
              105.82218368124491,
              21.022155956091833
            ],
            [
              105.82224502038021,
              21.02199252340502
            ],
            [
              105.82225697436165,
              21.021960672963164
            ],
            [
              105.82226057249596,
              21.02195108498561
            ],
            [
              105.82227054394122,
              21.02192451716062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT155_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.554",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022309339137482,
          "to_latitude": 21.02192451716062,
          "to_longitude": 105.82227054394122,
          "from_longitude": 105.82211437458072
        }
      },
      {
        "id": 554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82282422990093,
            21.021287458151917,
            105.8229610700605,
            21.021624995252644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8229610700605,
              21.021624995252644
            ],
            [
              105.82291172696162,
              21.021509932677382
            ],
            [
              105.82286907352035,
              21.021410471659504
            ],
            [
              105.82286678171542,
              21.021405319003883
            ],
            [
              105.82282422990093,
              21.02130964109395
            ],
            [
              105.8228286072187,
              21.02129409888292
            ],
            [
              105.82282510382073,
              21.021287458151917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.555",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021624995252644,
          "to_latitude": 21.021287458151917,
          "to_longitude": 105.82282510382073,
          "from_longitude": 105.8229610700605
        }
      },
      {
        "id": 555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82272291388173,
            21.021405319003883,
            105.82286678171542,
            21.021462385281648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82272291388173,
              21.021462385281648
            ],
            [
              105.82283340432583,
              21.021413610410836
            ],
            [
              105.82286678171542,
              21.021405319003883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.556",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021462385281648,
          "to_latitude": 21.021405319003883,
          "to_longitude": 105.82286678171542,
          "from_longitude": 105.82272291388173
        }
      },
      {
        "id": 556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288440995391,
            21.02137443242576,
            105.82300807458225,
            21.02141251191601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82300807458225,
              21.02137443242576
            ],
            [
              105.82288440995391,
              21.02141251191601
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.557",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02137443242576,
          "to_latitude": 21.02141251191601,
          "to_longitude": 105.82288440995391,
          "from_longitude": 105.82300807458225
        }
      },
      {
        "id": 557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82279193356399,
            21.021624995252644,
            105.8229610700605,
            21.021686402818922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82279193356399,
              21.021686402818922
            ],
            [
              105.82288621996084,
              21.021648905394382
            ],
            [
              105.8229610700605,
              21.021624995252644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.558",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021686402818922,
          "to_latitude": 21.021624995252644,
          "to_longitude": 105.8229610700605,
          "from_longitude": 105.82279193356399
        }
      },
      {
        "id": 558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82361935364645,
            21.021438918859822,
            105.82392174298708,
            21.02206482001307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392174298708,
              21.02206482001307
            ],
            [
              105.82391749627392,
              21.022057194778785
            ],
            [
              105.82389918324687,
              21.02202431394195
            ],
            [
              105.82388175325421,
              21.021992995922165
            ],
            [
              105.82370083548592,
              21.021824141592365
            ],
            [
              105.82364416043922,
              21.021728328176373
            ],
            [
              105.82363795849398,
              21.021715880144104
            ],
            [
              105.82361935364645,
              21.021678539653696
            ],
            [
              105.82365452092465,
              21.021632179295096
            ],
            [
              105.8236829870015,
              21.021601571123398
            ],
            [
              105.82383671576784,
              21.021438918859822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT103_La Thành",
          "maTaiSan": "04.O12.DODV.559",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02206482001307,
          "to_latitude": 21.021438918859822,
          "to_longitude": 105.82383671576784,
          "from_longitude": 105.82392174298708
        }
      },
      {
        "id": 559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367041829315,
            21.022053528327405,
            105.82386962821484,
            21.022142794305324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367041829315,
              21.022142794305324
            ],
            [
              105.82367071577477,
              21.022142660993776
            ],
            [
              105.82386962821484,
              21.022053528327405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT129_La Thành",
          "maTaiSan": "04.O12.DODV.560",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022142794305324,
          "to_latitude": 21.022053528327405,
          "to_longitude": 105.82386962821484,
          "from_longitude": 105.82367041829315
        }
      },
      {
        "id": 560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82399648847964,
            21.02175153825106,
            105.8244650368248,
            21.022004014054545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82399648847964,
              21.022004014054545
            ],
            [
              105.82416979365242,
              21.0219087620036
            ],
            [
              105.82422207039745,
              21.021880923269553
            ],
            [
              105.82422795011546,
              21.021877792196893
            ],
            [
              105.82427853198826,
              21.021850856755815
            ],
            [
              105.8244650368248,
              21.02175153825106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT104_La Thành",
          "maTaiSan": "04.O12.DODV.561",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022004014054545,
          "to_latitude": 21.02175153825106,
          "to_longitude": 105.8244650368248,
          "from_longitude": 105.82399648847964
        }
      },
      {
        "id": 561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.825224427193,
            21.020731839334687,
            105.82538943390833,
            21.02137091299158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82538943390833,
              21.02137091299158
            ],
            [
              105.82538697726218,
              21.021366761946148
            ],
            [
              105.82537703407672,
              21.02134994607184
            ],
            [
              105.8253737102075,
              21.021343959363943
            ],
            [
              105.82536819721233,
              21.021334012699153
            ],
            [
              105.82536489566253,
              21.021328061108566
            ],
            [
              105.82531941210813,
              21.021236400285087
            ],
            [
              105.8252562737178,
              21.02100878758677
            ],
            [
              105.82522567173149,
              21.02084662460567
            ],
            [
              105.825224427193,
              21.020840028064207
            ],
            [
              105.82524589378376,
              21.020819261726785
            ],
            [
              105.82522785285249,
              21.020731839334687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT107_La Thành",
          "maTaiSan": "04.O12.DODV.562",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02137091299158,
          "to_latitude": 21.020731839334687,
          "to_longitude": 105.82522785285249,
          "from_longitude": 105.82538943390833
        }
      },
      {
        "id": 562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82501241011809,
            21.020792134158956,
            105.82522567173149,
            21.02084985083076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82522567173149,
              21.02084662460567
            ],
            [
              105.82517467900591,
              21.02084985083076
            ],
            [
              105.8251575272178,
              21.02084061636196
            ],
            [
              105.82514908788188,
              21.020824016272893
            ],
            [
              105.82514290623705,
              21.020806007037823
            ],
            [
              105.82513484680135,
              21.020794287039674
            ],
            [
              105.82511279137869,
              21.020792134158956
            ],
            [
              105.82501241011809,
              21.02079816326069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT107_La Thành",
          "maTaiSan": "04.O12.DODV.563",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02084662460567,
          "to_latitude": 21.02079816326069,
          "to_longitude": 105.82501241011809,
          "from_longitude": 105.82522567173149
        }
      },
      {
        "id": 563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8222688496061,
            21.02032492654163,
            105.82243602168093,
            21.020344548948962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8222688496061,
              21.020344548948962
            ],
            [
              105.82243602168093,
              21.02032492654163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.564",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020344548948962,
          "to_latitude": 21.02032492654163,
          "to_longitude": 105.82243602168093,
          "from_longitude": 105.8222688496061
        }
      },
      {
        "id": 564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8219690612837,
            21.020422103298117,
            105.82212537976109,
            21.020560489501236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82212537976109,
              21.02053951916523
            ],
            [
              105.82200592948743,
              21.020560489501236
            ],
            [
              105.8219690612837,
              21.02043646322087
            ],
            [
              105.82197176747415,
              21.020422103298117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_Ngõ 85, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.565",
          "diaChiLapD": "Ngõ 85, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02053951916523,
          "to_latitude": 21.020422103298117,
          "to_longitude": 105.82197176747415,
          "from_longitude": 105.82212537976109
        }
      },
      {
        "id": 565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82293946412794,
            21.020023462470963,
            105.82301411125205,
            21.02073754760146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82301411125205,
              21.02073754760146
            ],
            [
              105.82299654783266,
              21.020674319146732
            ],
            [
              105.8229688224975,
              21.02057451584573
            ],
            [
              105.82293946412794,
              21.02022498475068
            ],
            [
              105.82294838065702,
              21.020043916134203
            ],
            [
              105.8229495324706,
              21.02003556384923
            ],
            [
              105.82295120337334,
              21.020023462470963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT98_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.566",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02073754760146,
          "to_latitude": 21.020023462470963,
          "to_longitude": 105.82295120337334,
          "from_longitude": 105.82301411125205
        }
      },
      {
        "id": 566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82279586409369,
            21.020445816538462,
            105.82294912306281,
            21.020454990284314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82279586409369,
              21.020452674599458
            ],
            [
              105.82288533902154,
              21.020454990284314
            ],
            [
              105.82294912306281,
              21.020445816538462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT180_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.567",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020452674599458,
          "to_latitude": 21.020445816538462,
          "to_longitude": 105.82294912306281,
          "from_longitude": 105.82279586409369
        }
      },
      {
        "id": 567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82311061665841,
            21.020051216825063,
            105.82312293790588,
            21.020241829148624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82311061665841,
              21.020241829148624
            ],
            [
              105.82311454265749,
              21.020206990785926
            ],
            [
              105.82312293790588,
              21.020051216825063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT131_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.568",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020241829148624,
          "to_latitude": 21.020051216825063,
          "to_longitude": 105.82312293790588,
          "from_longitude": 105.82311061665841
        }
      },
      {
        "id": 568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82318667720664,
            21.020332713097297,
            105.82333444613107,
            21.02035880565635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82318667720664,
              21.020332713097297
            ],
            [
              105.82319694839093,
              21.020346425844902
            ],
            [
              105.82331317911562,
              21.020340093493783
            ],
            [
              105.82333430596991,
              21.020358682592818
            ],
            [
              105.82333444613107,
              21.02035880565635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT121_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.569",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020332713097297,
          "to_latitude": 21.02035880565635,
          "to_longitude": 105.82333444613107,
          "from_longitude": 105.82318667720664
        }
      },
      {
        "id": 569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82402554676372,
            21.019971693298487,
            105.82430116524444,
            21.020388402550633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82430116524444,
              21.019971693298487
            ],
            [
              105.82405692370867,
              21.02010592640351
            ],
            [
              105.82403804674554,
              21.02011585398319
            ],
            [
              105.82403521093774,
              21.02011709249542
            ],
            [
              105.82403262656078,
              21.020118746176706
            ],
            [
              105.82403036264128,
              21.020120772241082
            ],
            [
              105.82402847851674,
              21.020123115304305
            ],
            [
              105.82402702388065,
              21.02012571551272
            ],
            [
              105.82402603778553,
              21.020128502225585
            ],
            [
              105.82402554676372,
              21.020131402153012
            ],
            [
              105.8240255628987,
              21.020134338462306
            ],
            [
              105.8240260867255,
              21.020137233473434
            ],
            [
              105.82403156846846,
              21.02014939504819
            ],
            [
              105.82409725525699,
              21.02026751502693
            ],
            [
              105.82411096244071,
              21.02028853803875
            ],
            [
              105.82418371506019,
              21.020359758064725
            ],
            [
              105.82421820540996,
              21.02038092062217
            ],
            [
              105.82423040293591,
              21.020388402550633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT182_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.570",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019971693298487,
          "to_latitude": 21.020388402550633,
          "to_longitude": 105.82423040293591,
          "from_longitude": 105.82430116524444
        }
      },
      {
        "id": 570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82429653711733,
            21.019228753508322,
            105.824861748724,
            21.019907096236263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82429653711733,
              21.019907096236263
            ],
            [
              105.824861748724,
              21.01953876031903
            ],
            [
              105.82478033674829,
              21.019396551976048
            ],
            [
              105.82477772032375,
              21.01939150027479
            ],
            [
              105.82469578094293,
              21.019289450058356
            ],
            [
              105.82464702306763,
              21.019233842689435
            ],
            [
              105.82464431217225,
              21.019228753508322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT124_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.571",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 114.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019907096236263,
          "to_latitude": 21.019228753508322,
          "to_longitude": 105.82464431217225,
          "from_longitude": 105.82429653711733
        }
      },
      {
        "id": 571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82508147987443,
            21.019955834905858,
            105.82537080682867,
            21.02050131449103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82524609298774,
              21.02050131449103
            ],
            [
              105.82537080682867,
              21.02044455945513
            ],
            [
              105.8253361077833,
              21.02038253802653
            ],
            [
              105.82529306374103,
              21.02029797733973
            ],
            [
              105.82516168235651,
              21.02008004675427
            ],
            [
              105.82509307228442,
              21.019972575062
            ],
            [
              105.82508147987443,
              21.019955834905858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT118_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.572",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02050131449103,
          "to_latitude": 21.019955834905858,
          "to_longitude": 105.82508147987443,
          "from_longitude": 105.82524609298774
        }
      },
      {
        "id": 572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82517120101208,
            21.02038253802653,
            105.8253361077833,
            21.020460626214568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82517120101208,
              21.020460626214568
            ],
            [
              105.82528530981581,
              21.02040818862252
            ],
            [
              105.8253361077833,
              21.02038253802653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT118_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.573",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020460626214568,
          "to_latitude": 21.02038253802653,
          "to_longitude": 105.8253361077833,
          "from_longitude": 105.82517120101208
        }
      },
      {
        "id": 573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82504866253745,
            21.02029797733973,
            105.82529306374103,
            21.02041780166254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504866253745,
              21.02041780166254
            ],
            [
              105.82519152492884,
              21.02034532027841
            ],
            [
              105.82529306374103,
              21.02029797733973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT118_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.574",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02041780166254,
          "to_latitude": 21.02029797733973,
          "to_longitude": 105.82529306374103,
          "from_longitude": 105.82504866253745
        }
      },
      {
        "id": 574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82443753341752,
            21.02022415151749,
            105.82487012387013,
            21.02094294854084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82487012387013,
              21.02094294854084
            ],
            [
              105.82463164357134,
              21.02051006697262
            ],
            [
              105.82443934505375,
              21.02022682170466
            ],
            [
              105.82443753341752,
              21.02022415151749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT188_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.575",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 91.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02094294854084,
          "to_latitude": 21.02022415151749,
          "to_longitude": 105.82443753341752,
          "from_longitude": 105.82487012387013
        }
      },
      {
        "id": 575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82327574133414,
            21.02082739380179,
            105.82357710073178,
            21.02110573154753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82357710073178,
              21.02109915234172
            ],
            [
              105.82357599717064,
              21.021095617039574
            ],
            [
              105.82356661401113,
              21.02107598750207
            ],
            [
              105.82356541411414,
              21.021073477827656
            ],
            [
              105.8234623464601,
              21.02110573154753
            ],
            [
              105.82343849091733,
              21.021014543541046
            ],
            [
              105.82340484289253,
              21.020827594144986
            ],
            [
              105.82340480716144,
              21.02082739380179
            ],
            [
              105.82327574133414,
              21.020832831833026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.576",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02109915234172,
          "to_latitude": 21.020832831833026,
          "to_longitude": 105.82327574133414,
          "from_longitude": 105.82357710073178
        }
      },
      {
        "id": 576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82340484289253,
            21.020786287568644,
            105.82361533405943,
            21.020827594144986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82340484289253,
              21.020827594144986
            ],
            [
              105.82345619113372,
              21.020825159761053
            ],
            [
              105.82361533405943,
              21.020786287568644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.577",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020827594144986,
          "to_latitude": 21.020786287568644,
          "to_longitude": 105.82361533405943,
          "from_longitude": 105.82340484289253
        }
      },
      {
        "id": 577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82344030180951,
            21.020569257934753,
            105.82359719790249,
            21.020639737555985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82359719790249,
              21.020569257934753
            ],
            [
              105.82344030180951,
              21.020639737555985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT120_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.578",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020569257934753,
          "to_latitude": 21.020639737555985,
          "to_longitude": 105.82344030180951,
          "from_longitude": 105.82359719790249
        }
      },
      {
        "id": 578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82315585808203,
            21.020857298751935,
            105.82322307115719,
            21.021211664937173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82315585808203,
              21.020857298751935
            ],
            [
              105.82322307115719,
              21.021190593524768
            ],
            [
              105.82320925373307,
              21.02120748388497
            ],
            [
              105.82320980962028,
              21.021211664937173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.579",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020857298751935,
          "to_latitude": 21.021211664937173,
          "to_longitude": 105.82320980962028,
          "from_longitude": 105.82315585808203
        }
      },
      {
        "id": 579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82294255165665,
            21.020987950148903,
            105.82299937294509,
            21.021259635234166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82294255165665,
              21.020987950148903
            ],
            [
              105.82299937294509,
              21.021224471276568
            ],
            [
              105.82298829870851,
              21.021257425768873
            ],
            [
              105.82298863598243,
              21.021259635234166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.580",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020987950148903,
          "to_latitude": 21.021259635234166,
          "to_longitude": 105.82298863598243,
          "from_longitude": 105.82294255165665
        }
      },
      {
        "id": 580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82157756927644,
            21.020113081295882,
            105.8224761919073,
            21.020137070810435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82157756927644,
              21.020137070810435
            ],
            [
              105.82168337157258,
              21.020117454711283
            ],
            [
              105.8218226369749,
              21.020114186949534
            ],
            [
              105.82186976829513,
              21.020113081295882
            ],
            [
              105.82193574027828,
              21.020114177882082
            ],
            [
              105.82199003579746,
              21.020115080674753
            ],
            [
              105.82203265621638,
              21.020115161033083
            ],
            [
              105.82216119767287,
              21.020130332698766
            ],
            [
              105.82224759197052,
              21.020135415745177
            ],
            [
              105.82243662655182,
              21.02012879076256
            ],
            [
              105.8224761919073,
              21.020123109649482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT177_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.581",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020137070810435,
          "to_latitude": 21.020123109649482,
          "to_longitude": 105.8224761919073,
          "from_longitude": 105.82157756927644
        }
      },
      {
        "id": 581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8234650583141,
            21.01973764330851,
            105.82393049383786,
            21.02001630325692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234650583141,
              21.02001630325692
            ],
            [
              105.82348184030731,
              21.020006931430643
            ],
            [
              105.82368619913409,
              21.019893191371207
            ],
            [
              105.82370221072586,
              21.019882798092855
            ],
            [
              105.82382867767481,
              21.019800705623148
            ],
            [
              105.82382899686942,
              21.019800498141713
            ],
            [
              105.8238826294342,
              21.019767600311628
            ],
            [
              105.82393049383786,
              21.01973764330851
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT122_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.582",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02001630325692,
          "to_latitude": 21.01973764330851,
          "to_longitude": 105.82393049383786,
          "from_longitude": 105.8234650583141
        }
      },
      {
        "id": 582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8248024817146,
            21.02009276577661,
            105.82493712136922,
            21.020337207688694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82493712136922,
              21.020337207688694
            ],
            [
              105.82488606518172,
              21.0202622382641
            ],
            [
              105.82489933146589,
              21.0202370163823
            ],
            [
              105.82486110812695,
              21.020179562390968
            ],
            [
              105.82483166556943,
              21.02013486000428
            ],
            [
              105.82480579989165,
              21.02009755213847
            ],
            [
              105.8248024817146,
              21.02009276577661
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT117_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.583",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020337207688694,
          "to_latitude": 21.02009276577661,
          "to_longitude": 105.8248024817146,
          "from_longitude": 105.82493712136922
        }
      },
      {
        "id": 583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82472689139065,
            21.01995126621395,
            105.82506699157757,
            21.020132486743766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82506699157757,
              21.01995126621395
            ],
            [
              105.82493414459917,
              21.020023236464837
            ],
            [
              105.82491303462304,
              21.020034672517564
            ],
            [
              105.82480771795996,
              21.020090014450886
            ],
            [
              105.8248024817146,
              21.02009276577661
            ],
            [
              105.82472689139065,
              21.020132486743766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT117_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.584",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01995126621395,
          "to_latitude": 21.020132486743766,
          "to_longitude": 105.82472689139065,
          "from_longitude": 105.82506699157757
        }
      },
      {
        "id": 584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82419412417609,
            21.020082647289268,
            105.82466381046623,
            21.020341141820953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82419412417609,
              21.020341141820953
            ],
            [
              105.82439435433588,
              21.02022831692223
            ],
            [
              105.82439921645279,
              21.020225577357493
            ],
            [
              105.82441754801576,
              21.020214783478266
            ],
            [
              105.82447218984595,
              21.020182613087822
            ],
            [
              105.82456120497487,
              21.02013230276762
            ],
            [
              105.82466381046623,
              21.020082647289268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT114_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.585",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020341141820953,
          "to_latitude": 21.020082647289268,
          "to_longitude": 105.82466381046623,
          "from_longitude": 105.82419412417609
        }
      },
      {
        "id": 585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299654783266,
            21.020651386697324,
            105.82311610462604,
            21.020674319146732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82299654783266,
              21.020674319146732
            ],
            [
              105.82311610462604,
              21.020651386697324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT98_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.586",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020674319146732,
          "to_latitude": 21.020651386697324,
          "to_longitude": 105.82311610462604,
          "from_longitude": 105.82299654783266
        }
      },
      {
        "id": 586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82339745692201,
            21.01808709030285,
            105.8240644645227,
            21.018346581664638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8240644645227,
              21.01808709030285
            ],
            [
              105.82373036035955,
              21.018222105019262
            ],
            [
              105.82370571655491,
              21.01823218422469
            ],
            [
              105.82364598351171,
              21.018254351394365
            ],
            [
              105.82363647664191,
              21.018257879361293
            ],
            [
              105.82350717529802,
              21.01830586331117
            ],
            [
              105.82346836443563,
              21.01832026706671
            ],
            [
              105.82339745692201,
              21.018346581664638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT127_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.587",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01808709030285,
          "to_latitude": 21.018346581664638,
          "to_longitude": 105.82339745692201,
          "from_longitude": 105.8240644645227
        }
      },
      {
        "id": 587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82181609478656,
            21.018827830577266,
            105.82197506802191,
            21.01887438322108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82181609478656,
              21.01887438322108
            ],
            [
              105.82197506802191,
              21.018827830577266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.588",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01887438322108,
          "to_latitude": 21.018827830577266,
          "to_longitude": 105.82197506802191,
          "from_longitude": 105.82181609478656
        }
      },
      {
        "id": 588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82244682935456,
            21.022235121768652,
            105.82263528287272,
            21.0222682383225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82263528287272,
              21.02225831637078
            ],
            [
              105.82260460598353,
              21.022235121768652
            ],
            [
              105.82252963660548,
              21.022250857240714
            ],
            [
              105.82248976893993,
              21.022259225133208
            ],
            [
              105.82244682935456,
              21.0222682383225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT153_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.589",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225831637078,
          "to_latitude": 21.0222682383225,
          "to_longitude": 105.82244682935456,
          "from_longitude": 105.82263528287272
        }
      },
      {
        "id": 589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8227256531678,
            21.021509932677382,
            105.82291172696162,
            21.021589421606357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8227256531678,
              21.021589421606357
            ],
            [
              105.82279582650875,
              21.02155944370596
            ],
            [
              105.8228332165403,
              21.021542599214932
            ],
            [
              105.82291172696162,
              21.021509932677382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.590",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021589421606357,
          "to_latitude": 21.021509932677382,
          "to_longitude": 105.82291172696162,
          "from_longitude": 105.8227256531678
        }
      },
      {
        "id": 590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82233970303525,
            21.02136677982626,
            105.82267116316525,
            21.021545019353162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233970303525,
              21.021545019353162
            ],
            [
              105.82242665616673,
              21.021494339826322
            ],
            [
              105.82258627877141,
              21.02141085591975
            ],
            [
              105.82266185598911,
              21.021371327423193
            ],
            [
              105.82267116316525,
              21.02136677982626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.591",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021545019353162,
          "to_latitude": 21.02136677982626,
          "to_longitude": 105.82267116316525,
          "from_longitude": 105.82233970303525
        }
      },
      {
        "id": 591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82268605943199,
            21.021190022877665,
            105.82320922136529,
            21.0213206793798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82268605943199,
              21.0213206793798
            ],
            [
              105.8227949084457,
              21.021267307828747
            ],
            [
              105.82293924375027,
              21.021231831753596
            ],
            [
              105.82295164515936,
              21.021228784784398
            ],
            [
              105.82297693639687,
              21.02122649919057
            ],
            [
              105.82299937294509,
              21.021224471276568
            ],
            [
              105.82309215553799,
              21.021216084768703
            ],
            [
              105.82320922136529,
              21.021190022877665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT186_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.592",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0213206793798,
          "to_latitude": 21.021190022877665,
          "to_longitude": 105.82320922136529,
          "from_longitude": 105.82268605943199
        }
      },
      {
        "id": 592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82297693639687,
            21.02122649919057,
            105.82298548120453,
            21.02126007682115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82297693639687,
              21.02122649919057
            ],
            [
              105.82298214921123,
              21.02124698352104
            ],
            [
              105.82298518627461,
              21.02125892211891
            ],
            [
              105.82298548120453,
              21.02126007682115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT186_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.593",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02122649919057,
          "to_latitude": 21.02126007682115,
          "to_longitude": 105.82298548120453,
          "from_longitude": 105.82297693639687
        }
      },
      {
        "id": 593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288506323775,
            21.021259817229414,
            105.82302214448943,
            21.02127930550346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82288506323775,
              21.02127930550346
            ],
            [
              105.82296653602026,
              21.02126848087451
            ],
            [
              105.82299683519042,
              21.021264071046893
            ],
            [
              105.82301663701544,
              21.021261188781732
            ],
            [
              105.82302214448943,
              21.021259817229414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT160_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.594",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02127930550346,
          "to_latitude": 21.021259817229414,
          "to_longitude": 105.82302214448943,
          "from_longitude": 105.82288506323775
        }
      },
      {
        "id": 594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82344354865751,
            21.02069099888723,
            105.82361088873863,
            21.020728479079057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82344354865751,
              21.020694780685922
            ],
            [
              105.82345624895162,
              21.020728479079057
            ],
            [
              105.82361088873863,
              21.02069099888723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT120_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.595",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020694780685922,
          "to_latitude": 21.02069099888723,
          "to_longitude": 105.82361088873863,
          "from_longitude": 105.82344354865751
        }
      },
      {
        "id": 595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82187654890411,
            21.020936029445068,
            105.82210688642618,
            21.021329563823365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82210688642618,
              21.021329563823365
            ],
            [
              105.82210498479826,
              21.021326441529563
            ],
            [
              105.82207850485061,
              21.021287902402786
            ],
            [
              105.82204402673064,
              21.02123772230897
            ],
            [
              105.8220143947894,
              21.02119065117523
            ],
            [
              105.82196076234808,
              21.021105457034952
            ],
            [
              105.82193687322295,
              21.02106343516811
            ],
            [
              105.82187654890411,
              21.020936029445068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT130_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.596",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021329563823365,
          "to_latitude": 21.020936029445068,
          "to_longitude": 105.82187654890411,
          "from_longitude": 105.82210688642618
        }
      },
      {
        "id": 596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8215435659373,
            21.02016644989838,
            105.821843679193,
            21.020878799078048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.821843679193,
              21.020878799078048
            ],
            [
              105.82182793580466,
              21.02080617807927
            ],
            [
              105.8218144653229,
              21.020744043391716
            ],
            [
              105.8217572451585,
              21.02063535372349
            ],
            [
              105.82169648960812,
              21.020507135294423
            ],
            [
              105.82169171158208,
              21.020496792047872
            ],
            [
              105.82168161816428,
              21.02047494479466
            ],
            [
              105.8216664020911,
              21.020442006280213
            ],
            [
              105.82166394094544,
              21.020436485907915
            ],
            [
              105.82166170477555,
              21.020431469352328
            ],
            [
              105.8215435659373,
              21.02016644989838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.597",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020878799078048,
          "to_latitude": 21.02016644989838,
          "to_longitude": 105.8215435659373,
          "from_longitude": 105.821843679193
        }
      },
      {
        "id": 597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82193574027828,
            21.02009364337441,
            105.82193716495223,
            21.020114177882082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82193574027828,
              21.020114177882082
            ],
            [
              105.82193608799523,
              21.020109169618657
            ],
            [
              105.821936613863,
              21.020101562815615
            ],
            [
              105.82193714357848,
              21.02009395599398
            ],
            [
              105.82193716495223,
              21.02009364337441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT177_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.598",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020114177882082,
          "to_latitude": 21.02009364337441,
          "to_longitude": 105.82193716495223,
          "from_longitude": 105.82193574027828
        }
      },
      {
        "id": 598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82244549416714,
            21.02010106146674,
            105.82271895576511,
            21.020612157300043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82271895576511,
              21.020589297357038
            ],
            [
              105.82250114670585,
              21.020612157300043
            ],
            [
              105.82249929845945,
              21.02052879485255
            ],
            [
              105.82249008455601,
              21.020472818611093
            ],
            [
              105.82244549416714,
              21.0203293074527
            ],
            [
              105.82256053871615,
              21.02028507565908
            ],
            [
              105.82253120244944,
              21.02011739900275
            ],
            [
              105.82252956524742,
              21.020105485237327
            ],
            [
              105.8225289570688,
              21.02010106146674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT178_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.599",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020589297357038,
          "to_latitude": 21.02010106146674,
          "to_longitude": 105.8225289570688,
          "from_longitude": 105.82271895576511
        }
      },
      {
        "id": 599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82353831076465,
            21.020860776579237,
            105.82390442044876,
            21.021227266403294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82390442044876,
              21.020860776579237
            ],
            [
              105.82384812477967,
              21.020927645001297
            ],
            [
              105.8238121856513,
              21.020964621909222
            ],
            [
              105.82370850318016,
              21.0210455147567
            ],
            [
              105.82368810537443,
              21.02106142902069
            ],
            [
              105.82368448654506,
              21.02106425196465
            ],
            [
              105.82366939370024,
              21.021072227374972
            ],
            [
              105.82356040361762,
              21.02111243925279
            ],
            [
              105.82354368235436,
              21.021119578020524
            ],
            [
              105.82353831076465,
              21.02112852428717
            ],
            [
              105.82354138920957,
              21.02113642977012
            ],
            [
              105.82359807605994,
              21.021227266403294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.600",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020860776579237,
          "to_latitude": 21.021227266403294,
          "to_longitude": 105.82359807605994,
          "from_longitude": 105.82390442044876
        }
      },
      {
        "id": 600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82382867767481,
            21.019800705623148,
            105.82394432318598,
            21.020003969269982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82394432318598,
              21.020003969269982
            ],
            [
              105.82382867767481,
              21.019800705623148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT122_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.601",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020003969269982,
          "to_latitude": 21.019800705623148,
          "to_longitude": 105.82382867767481,
          "from_longitude": 105.82394432318598
        }
      },
      {
        "id": 601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82333506090244,
            21.0200311773102,
            105.82363443707389,
            21.020768478116423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82362589296366,
              21.020768478116423
            ],
            [
              105.82361269720538,
              21.0207106081977
            ],
            [
              105.82361088873863,
              21.02069099888723
            ],
            [
              105.82361034143644,
              21.020685054697775
            ],
            [
              105.82362580939159,
              21.020664114378476
            ],
            [
              105.82363443707389,
              21.02062799233896
            ],
            [
              105.82361903806127,
              21.020593702768906
            ],
            [
              105.82359719790249,
              21.020569257934753
            ],
            [
              105.82356162393722,
              21.020475668098047
            ],
            [
              105.82351184095617,
              21.020378958656064
            ],
            [
              105.8234941873668,
              21.02034435934226
            ],
            [
              105.82348276238987,
              21.020295194256455
            ],
            [
              105.82348182945465,
              21.020292423159518
            ],
            [
              105.82346823451478,
              21.020252058772847
            ],
            [
              105.82343213850142,
              21.020214379312225
            ],
            [
              105.82338608286155,
              21.0201803076681
            ],
            [
              105.82337729071104,
              21.020170364160474
            ],
            [
              105.82334902996226,
              21.02013461126998
            ],
            [
              105.82333741726941,
              21.020105559214233
            ],
            [
              105.82333531382251,
              21.02003922292681
            ],
            [
              105.82333506090244,
              21.0200311773102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT120_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.602",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020768478116423,
          "to_latitude": 21.0200311773102,
          "to_longitude": 105.82333506090244,
          "from_longitude": 105.82362589296366
        }
      },
      {
        "id": 602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81994841280269,
            21.021362576023787,
            105.82024735011669,
            21.02189197620503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994841280269,
              21.021362576023787
            ],
            [
              105.8201195682533,
              21.02167278591502
            ],
            [
              105.8202215618413,
              21.021834027626564
            ],
            [
              105.82024735011669,
              21.02189197620503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.603",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021362576023787,
          "to_latitude": 21.02189197620503,
          "to_longitude": 105.82024735011669,
          "from_longitude": 105.81994841280269
        }
      },
      {
        "id": 603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82028796386777,
            21.022002221440886,
            105.82041294365986,
            21.02250124550719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82028796386777,
              21.022002221440886
            ],
            [
              105.82034534964956,
              21.022205978987
            ],
            [
              105.820362020856,
              21.022247662860686
            ],
            [
              105.82036507556805,
              21.02225530026042
            ],
            [
              105.82039987029748,
              21.022342303707227
            ],
            [
              105.8204103233533,
              21.02242391126657
            ],
            [
              105.82041294365986,
              21.02250124550719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.604",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022002221440886,
          "to_latitude": 21.02250124550719,
          "to_longitude": 105.82041294365986,
          "from_longitude": 105.82028796386777
        }
      },
      {
        "id": 604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82036507556805,
            21.022237041887653,
            105.82042380277203,
            21.02225530026042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82036507556805,
              21.02225530026042
            ],
            [
              105.8203971166214,
              21.022245338484158
            ],
            [
              105.82042262442361,
              21.02223740795883
            ],
            [
              105.82042380277203,
              21.022237041887653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.605",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225530026042,
          "to_latitude": 21.022237041887653,
          "to_longitude": 105.82042380277203,
          "from_longitude": 105.82036507556805
        }
      },
      {
        "id": 605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8201195682533,
            21.021633245593662,
            105.82019014105087,
            21.02167278591502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8201195682533,
              21.02167278591502
            ],
            [
              105.82018669139154,
              21.02163517887469
            ],
            [
              105.82019014105087,
              21.021633245593662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.606",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02167278591502,
          "to_latitude": 21.021633245593662,
          "to_longitude": 105.82019014105087,
          "from_longitude": 105.8201195682533
        }
      },
      {
        "id": 606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288174549014,
            21.021789258547194,
            105.82294537461769,
            21.021924931362577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82294537461769,
              21.021924931362577
            ],
            [
              105.82288174549014,
              21.021789258547194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77_La Thành",
          "maTaiSan": "04.O12.DODV.607",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021924931362577,
          "to_latitude": 21.021789258547194,
          "to_longitude": 105.82288174549014,
          "from_longitude": 105.82294537461769
        }
      },
      {
        "id": 607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82372226746133,
            21.02172485791112,
            105.82403542107312,
            21.022013700405832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82382415049403,
              21.02172485791112
            ],
            [
              105.82372226746133,
              21.021829756123118
            ],
            [
              105.82396877834846,
              21.02200903384352
            ],
            [
              105.82402187599796,
              21.022012751705788
            ],
            [
              105.82403542107312,
              21.022013700405832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT159_La Thành",
          "maTaiSan": "04.O12.DODV.608",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02172485791112,
          "to_latitude": 21.022013700405832,
          "to_longitude": 105.82403542107312,
          "from_longitude": 105.82382415049403
        }
      },
      {
        "id": 608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82363795849398,
            21.021622625243154,
            105.82378296837791,
            21.021715880144104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82378296837791,
              21.021622625243154
            ],
            [
              105.8236922103535,
              21.02171223303954
            ],
            [
              105.8236662118982,
              21.021689808101627
            ],
            [
              105.82363795849398,
              21.021715880144104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT103_La Thành",
          "maTaiSan": "04.O12.DODV.609",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021622625243154,
          "to_latitude": 21.021715880144104,
          "to_longitude": 105.82363795849398,
          "from_longitude": 105.82378296837791
        }
      },
      {
        "id": 609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82145955756506,
            21.01925324756509,
            105.82147284739077,
            21.019275851636383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82147284739077,
              21.019275851636383
            ],
            [
              105.8214672184871,
              21.019264527972755
            ],
            [
              105.82146161002241,
              21.01925324756509
            ],
            [
              105.82145955756506,
              21.019254193219265
            ],
            [
              105.82146156523287,
              21.019257715139368
            ],
            [
              105.82146618370375,
              21.019265107421923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A99_Ngõ 91, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.610",
          "diaChiLapD": "Ngõ 91, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019275851636383,
          "to_latitude": 21.019265107421923,
          "to_longitude": 105.82146618370375,
          "from_longitude": 105.82147284739077
        }
      },
      {
        "id": 610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82142391238919,
            21.018862356447094,
            105.82178250651003,
            21.018971321273845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82142391238919,
              21.018971321273845
            ],
            [
              105.82152930149311,
              21.01892840133327
            ],
            [
              105.82153157338547,
              21.01892747629603
            ],
            [
              105.82167508267925,
              21.01886905253798
            ],
            [
              105.82178250651003,
              21.018862356447094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.611",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018971321273845,
          "to_latitude": 21.018862356447094,
          "to_longitude": 105.82178250651003,
          "from_longitude": 105.82142391238919
        }
      },
      {
        "id": 611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82106475370622,
            21.019214114640054,
            105.82124120838968,
            21.019416599832034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82124120838968,
              21.019382059607896
            ],
            [
              105.8211892091031,
              21.019416599832034
            ],
            [
              105.82116826610957,
              21.01941447538438
            ],
            [
              105.8211429771178,
              21.01938830996014
            ],
            [
              105.82113262020705,
              21.019374882010013
            ],
            [
              105.82111303052518,
              21.019329856796563
            ],
            [
              105.82108708928676,
              21.019259053444898
            ],
            [
              105.82106475370622,
              21.019214114640054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.612",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019382059607896,
          "to_latitude": 21.019214114640054,
          "to_longitude": 105.82106475370622,
          "from_longitude": 105.82124120838968
        }
      },
      {
        "id": 612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82152058103675,
            21.018909910144394,
            105.82152930149311,
            21.01892840133327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82152058103675,
              21.018909910144394
            ],
            [
              105.82152303074463,
              21.01891510451106
            ],
            [
              105.8215273929338,
              21.01892435687022
            ],
            [
              105.82152930149311,
              21.01892840133327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.613",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018909910144394,
          "to_latitude": 21.01892840133327,
          "to_longitude": 105.82152930149311,
          "from_longitude": 105.82152058103675
        }
      },
      {
        "id": 613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82422795011546,
            21.021877792196893,
            105.82424933308468,
            21.02191281231365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82424933308468,
              21.02191281231365
            ],
            [
              105.82424826140678,
              21.021911056216915
            ],
            [
              105.82424600644357,
              21.021907365725593
            ],
            [
              105.82424375339443,
              21.021903673418503
            ],
            [
              105.82422795011546,
              21.021877792196893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT104_La Thành",
          "maTaiSan": "04.O12.DODV.614",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02191281231365,
          "to_latitude": 21.021877792196893,
          "to_longitude": 105.82422795011546,
          "from_longitude": 105.82424933308468
        }
      },
      {
        "id": 614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82390724014451,
            21.02080495349824,
            105.82408768440844,
            21.021092813564085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82408768440844,
              21.021092813564085
            ],
            [
              105.82402791170672,
              21.0209843683069
            ],
            [
              105.82391938685515,
              21.02082296730783
            ],
            [
              105.82390724014451,
              21.02080495349824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT110_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.615",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021092813564085,
          "to_latitude": 21.02080495349824,
          "to_longitude": 105.82390724014451,
          "from_longitude": 105.82408768440844
        }
      },
      {
        "id": 615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521523866413,
            21.021408877165417,
            105.82523308684617,
            21.021443605402002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82523308684617,
              21.021443605402002
            ],
            [
              105.82523075604968,
              21.021439073485702
            ],
            [
              105.82522593590467,
              21.021429691583602
            ],
            [
              105.82521873541302,
              21.02141568858594
            ],
            [
              105.82521523866413,
              21.021408877165417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT105_La Thành",
          "maTaiSan": "04.O12.DODV.616",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021443605402002,
          "to_latitude": 21.021408877165417,
          "to_longitude": 105.82521523866413,
          "from_longitude": 105.82523308684617
        }
      },
      {
        "id": 616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82381871728077,
            21.020405876890695,
            105.82407160477844,
            21.020604911968686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82381871728077,
              21.02043683267566
            ],
            [
              105.8238837023571,
              21.020405876890695
            ],
            [
              105.82403915487296,
              21.020577997691074
            ],
            [
              105.8240634070272,
              21.020598114264644
            ],
            [
              105.82407160477844,
              21.020604911968686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT187_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.617",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02043683267566,
          "to_latitude": 21.020604911968686,
          "to_longitude": 105.82407160477844,
          "from_longitude": 105.82381871728077
        }
      },
      {
        "id": 617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82439435433588,
            21.02022831692223,
            105.82440503280937,
            21.020245229555766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82439435433588,
              21.02022831692223
            ],
            [
              105.82439927532064,
              21.020236110491968
            ],
            [
              105.82440399253034,
              21.020243583500775
            ],
            [
              105.82440503280937,
              21.020245229555766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT114_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.618",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02022831692223,
          "to_latitude": 21.020245229555766,
          "to_longitude": 105.82440503280937,
          "from_longitude": 105.82439435433588
        }
      },
      {
        "id": 618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82365897304564,
            21.020210101721904,
            105.82369200498727,
            21.020271202167958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82369200498727,
              21.020271202167958
            ],
            [
              105.82365897304564,
              21.020210101721904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT181_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.619",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020271202167958,
          "to_latitude": 21.020210101721904,
          "to_longitude": 105.82365897304564,
          "from_longitude": 105.82369200498727
        }
      },
      {
        "id": 619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82361903806127,
            21.020532383020996,
            105.82377510124813,
            21.020593702768906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82361903806127,
              21.020593702768906
            ],
            [
              105.82377510124813,
              21.020532383020996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT120_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.620",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020593702768906,
          "to_latitude": 21.020532383020996,
          "to_longitude": 105.82377510124813,
          "from_longitude": 105.82361903806127
        }
      },
      {
        "id": 620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239749806042,
            21.019242245228174,
            105.82463755643364,
            21.019691375037805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8239749806042,
              21.019691375037805
            ],
            [
              105.82406397859651,
              21.01962203589699
            ],
            [
              105.82407915965389,
              21.01961193744665
            ],
            [
              105.8244286156522,
              21.019396612219342
            ],
            [
              105.82444926591762,
              21.01938135510017
            ],
            [
              105.82463755643364,
              21.019242245228174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT123_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.621",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019691375037805,
          "to_latitude": 21.019242245228174,
          "to_longitude": 105.82463755643364,
          "from_longitude": 105.8239749806042
        }
      },
      {
        "id": 621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82368294636413,
            21.019855996017363,
            105.82370221072586,
            21.019882798092855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82370221072586,
              21.019882798092855
            ],
            [
              105.82369507528726,
              21.01987289264342
            ],
            [
              105.8236889993664,
              21.019864459740845
            ],
            [
              105.82368325398798,
              21.019856484076247
            ],
            [
              105.82368294636413,
              21.019855996017363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT122_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.622",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019882798092855,
          "to_latitude": 21.019855996017363,
          "to_longitude": 105.82368294636413,
          "from_longitude": 105.82370221072586
        }
      },
      {
        "id": 622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82443221028471,
            21.019358600598963,
            105.82444926591762,
            21.01938135510017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82444926591762,
              21.01938135510017
            ],
            [
              105.82444563773181,
              21.0193765169424
            ],
            [
              105.82444042024655,
              21.01936955397094
            ],
            [
              105.82443221028471,
              21.019358600598963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT123_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.623",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01938135510017,
          "to_latitude": 21.019358600598963,
          "to_longitude": 105.82443221028471,
          "from_longitude": 105.82444926591762
        }
      },
      {
        "id": 623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82252928540301,
            21.018505410639207,
            105.82294738170556,
            21.01866995388968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82294738170556,
              21.018505410639207
            ],
            [
              105.82289820619363,
              21.018524472668854
            ],
            [
              105.82285053852753,
              21.018543269971374
            ],
            [
              105.82252928540301,
              21.01866995388968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT126_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.624",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018505410639207,
          "to_latitude": 21.01866995388968,
          "to_longitude": 105.82252928540301,
          "from_longitude": 105.82294738170556
        }
      },
      {
        "id": 624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82361918994972,
            21.018219979687405,
            105.82363647664191,
            21.018257879361293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82361918994972,
              21.018219979687405
            ],
            [
              105.82362671744885,
              21.018236481313284
            ],
            [
              105.82362763952203,
              21.01823850278523
            ],
            [
              105.8236334709199,
              21.018251294040507
            ],
            [
              105.82363647664191,
              21.018257879361293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT127_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.625",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018219979687405,
          "to_latitude": 21.018257879361293,
          "to_longitude": 105.82363647664191,
          "from_longitude": 105.82361918994972
        }
      },
      {
        "id": 625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82195433047346,
            21.022305428066453,
            105.82229305720321,
            21.022748899842547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82195433047346,
              21.022748899842547
            ],
            [
              105.8220239600987,
              21.022570136454505
            ],
            [
              105.82203092854603,
              21.022550630126016
            ],
            [
              105.82205278513455,
              21.02248945286335
            ],
            [
              105.82205338450986,
              21.022488586482375
            ],
            [
              105.82208015312834,
              21.0224162098599
            ],
            [
              105.82210617445806,
              21.02234000897354
            ],
            [
              105.82211534788931,
              21.022328599340597
            ],
            [
              105.8221309470105,
              21.022321863552076
            ],
            [
              105.82223809188284,
              21.022305428066453
            ],
            [
              105.82226074350483,
              21.02231048591674
            ],
            [
              105.82227261639873,
              21.022330329296118
            ],
            [
              105.82229305720321,
              21.02239236248613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.626",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022748899842547,
          "to_latitude": 21.02239236248613,
          "to_longitude": 105.82229305720321,
          "from_longitude": 105.82195433047346
        }
      },
      {
        "id": 626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82225887264875,
            21.02112579992447,
            105.82263983950877,
            21.02153724857601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82225887264875,
              21.02153724857601
            ],
            [
              105.82249629747963,
              21.02141874989977
            ],
            [
              105.82249638766025,
              21.02141870520561
            ],
            [
              105.8225311001157,
              21.02140161269618
            ],
            [
              105.82262208523134,
              21.02135680983426
            ],
            [
              105.82263983950877,
              21.021341915576702
            ],
            [
              105.82263945592601,
              21.02131425523158
            ],
            [
              105.82253596700443,
              21.02112579992447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.627",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02153724857601,
          "to_latitude": 21.02112579992447,
          "to_longitude": 105.82253596700443,
          "from_longitude": 105.82225887264875
        }
      },
      {
        "id": 627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82324475695354,
            21.020853089649854,
            105.82365859892286,
            21.021185864050477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82324475695354,
              21.021185864050477
            ],
            [
              105.82329344782849,
              21.021171946314155
            ],
            [
              105.82344304515479,
              21.02111742060308
            ],
            [
              105.82345352988453,
              21.021113598876592
            ],
            [
              105.8234537834371,
              21.02111352448779
            ],
            [
              105.82346363071345,
              21.02111063978232
            ],
            [
              105.82350827334801,
              21.021097559906803
            ],
            [
              105.82356661401113,
              21.02107598750207
            ],
            [
              105.82360338004096,
              21.021062392061758
            ],
            [
              105.82363549830704,
              21.021049938216255
            ],
            [
              105.8236550526815,
              21.02103721552679
            ],
            [
              105.82365859892286,
              21.021015222868755
            ],
            [
              105.82361860996947,
              21.020853089649854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.628",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021185864050477,
          "to_latitude": 21.020853089649854,
          "to_longitude": 105.82361860996947,
          "from_longitude": 105.82324475695354
        }
      },
      {
        "id": 628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82124229652608,
            21.01923636467616,
            105.82154703597874,
            21.019407032179164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82124229652608,
              21.019407032179164
            ],
            [
              105.82133873193287,
              21.019347231821772
            ],
            [
              105.82147284739077,
              21.019275851636383
            ],
            [
              105.82154703597874,
              21.01923636467616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A99_Ngõ 91, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.629",
          "diaChiLapD": "Ngõ 91, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019407032179164,
          "to_latitude": 21.01923636467616,
          "to_longitude": 105.82154703597874,
          "from_longitude": 105.82124229652608
        }
      },
      {
        "id": 629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82456130930072,
            21.021504282939013,
            105.82501753123087,
            21.021701096090077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82456130930072,
              21.021701096090077
            ],
            [
              105.82465932022693,
              21.021652443769707
            ],
            [
              105.82468660529622,
              21.02166198231597
            ],
            [
              105.82470000926475,
              21.021666664679636
            ],
            [
              105.82476782700783,
              21.02162439094788
            ],
            [
              105.82479102538065,
              21.021609929814545
            ],
            [
              105.82486063603763,
              21.02157742025412
            ],
            [
              105.824994918652,
              21.021514706552253
            ],
            [
              105.82501753123087,
              21.021504282939013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT106_La Thành",
          "maTaiSan": "04.O12.DODV.630",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021701096090077,
          "to_latitude": 21.021504282939013,
          "to_longitude": 105.82501753123087,
          "from_longitude": 105.82456130930072
        }
      },
      {
        "id": 630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82505079709769,
            21.02119957659042,
            105.82564735897361,
            21.021490080035647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82505079709769,
              21.021490080035647
            ],
            [
              105.82521523866413,
              21.021408877165417
            ],
            [
              105.82531363048139,
              21.021360290371632
            ],
            [
              105.82536819721233,
              21.021334012699153
            ],
            [
              105.82564735897361,
              21.02119957659042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT105_La Thành",
          "maTaiSan": "04.O12.DODV.631",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021490080035647,
          "to_latitude": 21.02119957659042,
          "to_longitude": 105.82564735897361,
          "from_longitude": 105.82505079709769
        }
      },
      {
        "id": 631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82181809289064,
            21.02260326926884,
            105.82196887385861,
            21.022782357027747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82196887385861,
              21.02260326926884
            ],
            [
              105.82195424918996,
              21.02264545285816
            ],
            [
              105.82192338706393,
              21.022755178671144
            ],
            [
              105.82181809289064,
              21.022782357027747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.632",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02260326926884,
          "to_latitude": 21.022782357027747,
          "to_longitude": 105.82181809289064,
          "from_longitude": 105.82196887385861
        }
      },
      {
        "id": 632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82050908999993,
            21.02213596817341,
            105.8211669962492,
            21.02248805344632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82050908999993,
              21.02248805344632
            ],
            [
              105.82051325959465,
              21.022486712814754
            ],
            [
              105.82114015902087,
              21.022285061636843
            ],
            [
              105.82116398413639,
              21.022276402092377
            ],
            [
              105.8211669962492,
              21.022254999154082
            ],
            [
              105.82111316030498,
              21.02213596817341
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Ngách 371/3, La Thành",
          "maTaiSan": "04.O12.DODV.633",
          "diaChiLapD": "Ngách 371/3, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02248805344632,
          "to_latitude": 21.02213596817341,
          "to_longitude": 105.82111316030498,
          "from_longitude": 105.82050908999993
        }
      },
      {
        "id": 633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82176980146468,
            21.021322701498732,
            105.82223562874545,
            21.021946943246657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82176980146468,
              21.021322701498732
            ],
            [
              105.82178276534324,
              21.02137085127196
            ],
            [
              105.82179258014823,
              21.02137853185531
            ],
            [
              105.8218155074723,
              21.021373016175527
            ],
            [
              105.8218355254107,
              21.021364273774637
            ],
            [
              105.82185077940075,
              21.02136391194166
            ],
            [
              105.82220313600764,
              21.021923442119974
            ],
            [
              105.82221173462898,
              21.021935629331402
            ],
            [
              105.82222856135228,
              21.02194576437906
            ],
            [
              105.82223562874545,
              21.021946943246657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Ngõ 24, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.634",
          "diaChiLapD": "Ngõ 24, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021322701498732,
          "to_latitude": 21.021946943246657,
          "to_longitude": 105.82223562874545,
          "from_longitude": 105.82176980146468
        }
      },
      {
        "id": 634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82318493500465,
            21.021751711043525,
            105.82353087019754,
            21.022241519707837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82353087019754,
              21.022241519707837
            ],
            [
              105.8235269672388,
              21.022231674476593
            ],
            [
              105.82352196876086,
              21.022219058936688
            ],
            [
              105.8235203972293,
              21.02221509325549
            ],
            [
              105.82351542248755,
              21.02219734095535
            ],
            [
              105.82350475633409,
              21.022195466920607
            ],
            [
              105.82346932806604,
              21.022201517546637
            ],
            [
              105.82345554618207,
              21.02220387033545
            ],
            [
              105.82342343897271,
              21.0221829995831
            ],
            [
              105.82341108408433,
              21.02214845862102
            ],
            [
              105.82339864176824,
              21.02206471124153
            ],
            [
              105.82340734329118,
              21.02204499143904
            ],
            [
              105.82337791426608,
              21.021983125645498
            ],
            [
              105.8233566365057,
              21.021991757809555
            ],
            [
              105.82328511051476,
              21.022022256425277
            ],
            [
              105.82326688472041,
              21.022029241676563
            ],
            [
              105.82318493500465,
              21.021845146302066
            ],
            [
              105.82339620150749,
              21.021751711043525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT158_La Thành",
          "maTaiSan": "04.O12.DODV.635",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022241519707837,
          "to_latitude": 21.021751711043525,
          "to_longitude": 105.82339620150749,
          "from_longitude": 105.82353087019754
        }
      },
      {
        "id": 635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418901548554,
            21.02022702183984,
            105.82461949308032,
            21.02089853258327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82428635583466,
              21.02089853258327
            ],
            [
              105.82418901548554,
              21.020725031237184
            ],
            [
              105.82435226542088,
              21.02064235910787
            ],
            [
              105.82461949308032,
              21.02050834471665
            ],
            [
              105.82443433310237,
              21.0202289035451
            ],
            [
              105.82443308759042,
              21.02022702183984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT116_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.636",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 109.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02089853258327,
          "to_latitude": 21.02022702183984,
          "to_longitude": 105.82443308759042,
          "from_longitude": 105.82428635583466
        }
      },
      {
        "id": 636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82411267818826,
            21.020231325507403,
            105.82459133176332,
            21.020689034385168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82442642076337,
              21.020231325507403
            ],
            [
              105.8244274526419,
              21.020232843344896
            ],
            [
              105.82459116824268,
              21.020473422419315
            ],
            [
              105.82459133176332,
              21.02048339143282
            ],
            [
              105.8245769665646,
              21.020491419393757
            ],
            [
              105.82436258164077,
              21.02059442098192
            ],
            [
              105.82416881922381,
              21.020689034385168
            ],
            [
              105.82415784432854,
              21.020669473109415
            ],
            [
              105.82411267818826,
              21.02059723878003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.637",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 94.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020231325507403,
          "to_latitude": 21.02059723878003,
          "to_longitude": 105.82411267818826,
          "from_longitude": 105.82442642076337
        }
      },
      {
        "id": 637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82253899223848,
            21.02004133686267,
            105.82302770845658,
            21.02011595501123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82253899223848,
              21.02011595501123
            ],
            [
              105.8226167821771,
              21.020091657788388
            ],
            [
              105.82267043953186,
              21.02008074473596
            ],
            [
              105.82273157307644,
              21.020070669829387
            ],
            [
              105.82279201942828,
              21.02006070841868
            ],
            [
              105.82290779650423,
              21.02004133686267
            ],
            [
              105.82291967901884,
              21.02004209228069
            ],
            [
              105.82294838065702,
              21.020043916134203
            ],
            [
              105.82295430266574,
              21.020044292128574
            ],
            [
              105.82302770845658,
              21.020044468971278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT179_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.638",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02011595501123,
          "to_latitude": 21.020044468971278,
          "to_longitude": 105.82302770845658,
          "from_longitude": 105.82253899223848
        }
      },
      {
        "id": 638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82223850455134,
            21.018750121897497,
            105.82232134592147,
            21.018961587643606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82232134592147,
              21.018961587643606
            ],
            [
              105.8222892201465,
              21.01887629501983
            ],
            [
              105.82225577381958,
              21.018791561545836
            ],
            [
              105.82223850455134,
              21.018750121897497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT173_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.639",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018961587643606,
          "to_latitude": 21.018750121897497,
          "to_longitude": 105.82223850455134,
          "from_longitude": 105.82232134592147
        }
      },
      {
        "id": 639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81958413536292,
            21.021480672605165,
            105.81977626348352,
            21.021657269508186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81962504210388,
              21.021657269508186
            ],
            [
              105.81958413536292,
              21.021588704892846
            ],
            [
              105.81958447684623,
              21.02157145342737
            ],
            [
              105.81960900276401,
              21.021554965169344
            ],
            [
              105.81977626348352,
              21.021480672605165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.640",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021657269508186,
          "to_latitude": 21.021480672605165,
          "to_longitude": 105.81977626348352,
          "from_longitude": 105.81962504210388
        }
      },
      {
        "id": 640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82242968846346,
            21.022470090767865,
            105.82250129279497,
            21.02264753881328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82244095350093,
              21.022470090767865
            ],
            [
              105.82249193863004,
              21.022596014948515
            ],
            [
              105.82249670024983,
              21.022604396439306
            ],
            [
              105.82250129279497,
              21.022612469844304
            ],
            [
              105.82249640083808,
              21.02262539788982
            ],
            [
              105.8224685463242,
              21.022634642524434
            ],
            [
              105.82242968846346,
              21.02264753881328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_La Thành",
          "maTaiSan": "04.O12.DODV.641",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022470090767865,
          "to_latitude": 21.02264753881328,
          "to_longitude": 105.82242968846346,
          "from_longitude": 105.82244095350093
        }
      },
      {
        "id": 641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8223249242296,
            21.022365861845223,
            105.82243733575214,
            21.022677616290444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243733575214,
              21.022677616290444
            ],
            [
              105.82243571571246,
              21.02267199341376
            ],
            [
              105.82242968846346,
              21.02264753881328
            ],
            [
              105.82242506237463,
              21.02262878943802
            ],
            [
              105.82242410892965,
              21.022624909273603
            ],
            [
              105.8224091546222,
              21.022589256288004
            ],
            [
              105.8223785544631,
              21.022599351474412
            ],
            [
              105.8223249242296,
              21.022474000045882
            ],
            [
              105.82232871991829,
              21.02245219588417
            ],
            [
              105.82236346939024,
              21.022426195610326
            ],
            [
              105.82234274927745,
              21.022365861845223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_La Thành",
          "maTaiSan": "04.O12.DODV.642",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022677616290444,
          "to_latitude": 21.022365861845223,
          "to_longitude": 105.82234274927745,
          "from_longitude": 105.82243733575214
        }
      },
      {
        "id": 642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82281570854536,
            21.022314877639378,
            105.82300576963193,
            21.022457564986713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82300576963193,
              21.022457564986713
            ],
            [
              105.82300231988393,
              21.02245036490482
            ],
            [
              105.82299238805567,
              21.02242962521959
            ],
            [
              105.82298346150206,
              21.0224109887986
            ],
            [
              105.8229794566162,
              21.022405176950755
            ],
            [
              105.82291733645259,
              21.022314877639378
            ],
            [
              105.82284512409552,
              21.02233884907339
            ],
            [
              105.82281570854536,
              21.02235268518654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_La Thành",
          "maTaiSan": "04.O12.DODV.643",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022457564986713,
          "to_latitude": 21.02235268518654,
          "to_longitude": 105.82281570854536,
          "from_longitude": 105.82300576963193
        }
      },
      {
        "id": 643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82291557191014,
            21.02210225613865,
            105.82321705809957,
            21.022370407243095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82321705809957,
              21.022370407243095
            ],
            [
              105.82321420059158,
              21.022363496944358
            ],
            [
              105.82319146877022,
              21.022312647867277
            ],
            [
              105.82315498376559,
              21.02223100302373
            ],
            [
              105.82309268138266,
              21.02210225613865
            ],
            [
              105.82303678091576,
              21.02212244819624
            ],
            [
              105.82291557191014,
              21.022173726422324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_La Thành",
          "maTaiSan": "04.O12.DODV.644",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022370407243095,
          "to_latitude": 21.022173726422324,
          "to_longitude": 105.82291557191014,
          "from_longitude": 105.82321705809957
        }
      },
      {
        "id": 644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82273484778109,
            21.021828922595,
            105.8229449210689,
            21.022235965717556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8229449210689,
              21.022235965717556
            ],
            [
              105.82291557191014,
              21.022173726422324
            ],
            [
              105.82282900352358,
              21.02197305282609
            ],
            [
              105.82277385974658,
              21.021980725134764
            ],
            [
              105.8227441952646,
              21.02186257312752
            ],
            [
              105.82273484778109,
              21.021828922595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_La Thành",
          "maTaiSan": "04.O12.DODV.645",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022235965717556,
          "to_latitude": 21.021828922595,
          "to_longitude": 105.82273484778109,
          "from_longitude": 105.8229449210689
        }
      },
      {
        "id": 645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82283963156549,
            21.02185190619863,
            105.82325063268574,
            21.022369877915292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82283963156549,
              21.021961149280035
            ],
            [
              105.82294537461769,
              21.021924931362577
            ],
            [
              105.82305159865301,
              21.02188854666607
            ],
            [
              105.82317071944028,
              21.02185190619863
            ],
            [
              105.82325063268574,
              21.022036011437105
            ],
            [
              105.8232123274768,
              21.022049128321292
            ],
            [
              105.82311487148287,
              21.02208737920418
            ],
            [
              105.82310359649254,
              21.022096035187793
            ],
            [
              105.82310474049365,
              21.022106937910426
            ],
            [
              105.82311740823495,
              21.02213364460829
            ],
            [
              105.8232002314601,
              21.022309143392086
            ],
            [
              105.82321688461822,
              21.02234441595324
            ],
            [
              105.82321607561299,
              21.022363343353074
            ],
            [
              105.82321837214671,
              21.022369877915292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77_La Thành",
          "maTaiSan": "04.O12.DODV.646",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 106.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021961149280035,
          "to_latitude": 21.022369877915292,
          "to_longitude": 105.82321837214671,
          "from_longitude": 105.82283963156549
        }
      },
      {
        "id": 646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82339475679163,
            21.02192474082702,
            105.82367851388328,
            21.022176337938113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82339475679163,
              21.021981408369477
            ],
            [
              105.82352883436388,
              21.021926367890316
            ],
            [
              105.82354774851719,
              21.02192474082702
            ],
            [
              105.82356260401316,
              21.021938514445946
            ],
            [
              105.82364947396644,
              21.022129468376036
            ],
            [
              105.8236582287673,
              21.022148699011858
            ],
            [
              105.823672810722,
              21.02216643278307
            ],
            [
              105.82367851388328,
              21.022176337938113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT128_La Thành",
          "maTaiSan": "04.O12.DODV.647",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021981408369477,
          "to_latitude": 21.022176337938113,
          "to_longitude": 105.82367851388328,
          "from_longitude": 105.82339475679163
        }
      },
      {
        "id": 647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82357476316844,
            21.021761972412918,
            105.82368262428632,
            21.022175684052982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367999558937,
              21.022175684052982
            ],
            [
              105.82367424928832,
              21.022165630977877
            ],
            [
              105.82367041829315,
              21.022142794305324
            ],
            [
              105.82366226425418,
              21.022124352889904
            ],
            [
              105.82362241707138,
              21.022034152819728
            ],
            [
              105.82357650968058,
              21.021932431630404
            ],
            [
              105.82357476316844,
              21.02192217853873
            ],
            [
              105.82357947229933,
              21.021915217156128
            ],
            [
              105.82366719055656,
              21.021880580007537
            ],
            [
              105.82368262428632,
              21.02185916836458
            ],
            [
              105.82368007028336,
              21.021836761765258
            ],
            [
              105.82364696278556,
              21.021761972412918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT129_La Thành",
          "maTaiSan": "04.O12.DODV.648",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022175684052982,
          "to_latitude": 21.021761972412918,
          "to_longitude": 105.82364696278556,
          "from_longitude": 105.82367999558937
        }
      },
      {
        "id": 648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8234311925234,
            21.021761972412918,
            105.82364696278556,
            21.02184315855632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82364696278556,
              21.021761972412918
            ],
            [
              105.8234311925234,
              21.02184315855632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT129_La Thành",
          "maTaiSan": "04.O12.DODV.649",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021761972412918,
          "to_latitude": 21.02184315855632,
          "to_longitude": 105.8234311925234,
          "from_longitude": 105.82364696278556
        }
      },
      {
        "id": 649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82356040361762,
            21.02111243925279,
            105.82361940923572,
            21.021217536523945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82356040361762,
              21.02111243925279
            ],
            [
              105.82361940923572,
              21.021217536523945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.650",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02111243925279,
          "to_latitude": 21.021217536523945,
          "to_longitude": 105.82361940923572,
          "from_longitude": 105.82356040361762
        }
      },
      {
        "id": 650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82392211696789,
            21.020721612933517,
            105.8246972935913,
            21.021011501322896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392211696789,
              21.02078183020984
            ],
            [
              105.82394216048685,
              21.02079966576636
            ],
            [
              105.82400848841203,
              21.020858831817865
            ],
            [
              105.82409662238975,
              21.02100429477584
            ],
            [
              105.82411122690017,
              21.02101126646864
            ],
            [
              105.8241283743987,
              21.021011501322896
            ],
            [
              105.82414549184288,
              21.021005917740403
            ],
            [
              105.82432934694957,
              21.020909486304923
            ],
            [
              105.8246972935913,
              21.020721612933517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT183_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.651",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 101.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02078183020984,
          "to_latitude": 21.020721612933517,
          "to_longitude": 105.8246972935913,
          "from_longitude": 105.82392211696789
        }
      },
      {
        "id": 651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82381464665592,
            21.020284584575222,
            105.82409885358116,
            21.020374560872725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82381464665592,
              21.020348307346595
            ],
            [
              105.82386644904284,
              21.02033401580371
            ],
            [
              105.82388413153424,
              21.020374560872725
            ],
            [
              105.82409885358116,
              21.020284584575222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT112_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.652",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020348307346595,
          "to_latitude": 21.020284584575222,
          "to_longitude": 105.82409885358116,
          "from_longitude": 105.82381464665592
        }
      },
      {
        "id": 652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82231095810491,
            21.020632462546875,
            105.82233970566219,
            21.020804024394632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233970566219,
              21.020804024394632
            ],
            [
              105.82231095810491,
              21.02069386139819
            ],
            [
              105.8223134380635,
              21.020632462546875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.653",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020804024394632,
          "to_latitude": 21.020632462546875,
          "to_longitude": 105.8223134380635,
          "from_longitude": 105.82233970566219
        }
      },
      {
        "id": 653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8223134380635,
            21.020101866983833,
            105.82253982543031,
            21.020632462546875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223134380635,
              21.020632462546875
            ],
            [
              105.8224636657732,
              21.02060835982393
            ],
            [
              105.82248676091095,
              21.02060680750521
            ],
            [
              105.82249163459963,
              21.020528121975033
            ],
            [
              105.82248280285012,
              21.02047482195075
            ],
            [
              105.82246855789418,
              21.020451475595138
            ],
            [
              105.82243799585375,
              21.020340049647523
            ],
            [
              105.82243602168093,
              21.02032492654163
            ],
            [
              105.82243493715494,
              21.02031661216915
            ],
            [
              105.8224429287718,
              21.02030934410681
            ],
            [
              105.82248050107891,
              21.020294737011678
            ],
            [
              105.82250094769887,
              21.020280701364847
            ],
            [
              105.82253982543031,
              21.020240848192902
            ],
            [
              105.82251765873728,
              21.020131223338286
            ],
            [
              105.82252866996457,
              21.020118169055536
            ],
            [
              105.82252581489132,
              21.020101866983833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.654",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020632462546875,
          "to_latitude": 21.020101866983833,
          "to_longitude": 105.82252581489132,
          "from_longitude": 105.8223134380635
        }
      },
      {
        "id": 654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81974106994033,
            21.021633908266203,
            105.82005757644245,
            21.021776717541226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82005757644245,
              21.021633908266203
            ],
            [
              105.81984566056833,
              21.021729295337337
            ],
            [
              105.81983618932098,
              21.021733589766658
            ],
            [
              105.81980662213712,
              21.021746996159752
            ],
            [
              105.81974106994033,
              21.021776717541226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.655",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021633908266203,
          "to_latitude": 21.021776717541226,
          "to_longitude": 105.81974106994033,
          "from_longitude": 105.82005757644245
        }
      },
      {
        "id": 655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81980662213712,
            21.021746996159752,
            105.8198161567206,
            21.021766250347042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81980662213712,
              21.021746996159752
            ],
            [
              105.81981377307648,
              21.0217613491873
            ],
            [
              105.8198137905842,
              21.02176138432875
            ],
            [
              105.81981554866537,
              21.021765003212405
            ],
            [
              105.8198161567206,
              21.021766250347042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.656",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021746996159752,
          "to_latitude": 21.021766250347042,
          "to_longitude": 105.8198161567206,
          "from_longitude": 105.81980662213712
        }
      },
      {
        "id": 656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8232948146919,
            21.02159116823569,
            105.82364696278556,
            21.021761972412918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82364696278556,
              21.021761972412918
            ],
            [
              105.82361376717847,
              21.02169407870515
            ],
            [
              105.82359776951216,
              21.02168147216873
            ],
            [
              105.8234192725283,
              21.021749241032968
            ],
            [
              105.82334319580652,
              21.021600912139768
            ],
            [
              105.82333011425087,
              21.02159116823569
            ],
            [
              105.8232948146919,
              21.02159961174754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT129_La Thành",
          "maTaiSan": "04.O12.DODV.657",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021761972412918,
          "to_latitude": 21.02159961174754,
          "to_longitude": 105.8232948146919,
          "from_longitude": 105.82364696278556
        }
      },
      {
        "id": 657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82215191757126,
            21.022148808062155,
            105.82248976893993,
            21.022259225133208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82215191757126,
              21.022176302153014
            ],
            [
              105.82216708127193,
              21.02217752950042
            ],
            [
              105.82217369204373,
              21.02217806296443
            ],
            [
              105.82218194372714,
              21.02217873030266
            ],
            [
              105.82232035946006,
              21.0221647250243
            ],
            [
              105.82243288258809,
              21.022148808062155
            ],
            [
              105.822454619633,
              21.02215128708325
            ],
            [
              105.82246698834598,
              21.02217348095515
            ],
            [
              105.82248976893993,
              21.022259225133208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT153_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.658",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022176302153014,
          "to_latitude": 21.022259225133208,
          "to_longitude": 105.82248976893993,
          "from_longitude": 105.82215191757126
        }
      },
      {
        "id": 658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82252963660548,
            21.022250857240714,
            105.82257135431567,
            21.022396655639753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82257135431567,
              21.022396655639753
            ],
            [
              105.82254445812785,
              21.022325611510446
            ],
            [
              105.82252963660548,
              21.022250857240714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT153_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.659",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022396655639753,
          "to_latitude": 21.022250857240714,
          "to_longitude": 105.82252963660548,
          "from_longitude": 105.82257135431567
        }
      },
      {
        "id": 659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81935045440244,
            21.021473522247177,
            105.81957063170785,
            21.021872527598713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81935045440244,
              21.021473522247177
            ],
            [
              105.81955795239082,
              21.021847808674774
            ],
            [
              105.8195592057425,
              21.02185025308906
            ],
            [
              105.81956954951596,
              21.021870421972952
            ],
            [
              105.81957063170785,
              21.021872527598713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.660",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021473522247177,
          "to_latitude": 21.021872527598713,
          "to_longitude": 105.81957063170785,
          "from_longitude": 105.81935045440244
        }
      },
      {
        "id": 660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81937734313138,
            21.021773514770754,
            105.81971369279381,
            21.021927457345225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81971369279381,
              21.021773514770754
            ],
            [
              105.81968851546408,
              21.021787163729694
            ],
            [
              105.81967310981229,
              21.021795515139125
            ],
            [
              105.8195996773421,
              21.021835324644034
            ],
            [
              105.81956404340191,
              21.02184846848738
            ],
            [
              105.8195592057425,
              21.02185025308906
            ],
            [
              105.81955590319157,
              21.02185147119662
            ],
            [
              105.81949100805907,
              21.021875407765144
            ],
            [
              105.81947240471841,
              21.021883926305474
            ],
            [
              105.81937734313138,
              21.021927457345225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.661",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021773514770754,
          "to_latitude": 21.021927457345225,
          "to_longitude": 105.81937734313138,
          "from_longitude": 105.81971369279381
        }
      },
      {
        "id": 661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81955590319157,
            21.02185147119662,
            105.81956701089855,
            21.02187395046599
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81955590319157,
              21.02185147119662
            ],
            [
              105.81956243708132,
              21.021864694562286
            ],
            [
              105.81956589615754,
              21.021871697771125
            ],
            [
              105.81956701089855,
              21.02187395046599
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.662",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02185147119662,
          "to_latitude": 21.02187395046599,
          "to_longitude": 105.81956701089855,
          "from_longitude": 105.81955590319157
        }
      },
      {
        "id": 662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81961921167547,
            21.021570925178068,
            105.81981422784233,
            21.021819807704915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81969254182945,
              21.021819807704915
            ],
            [
              105.8196916655446,
              21.021817886249075
            ],
            [
              105.81968885071024,
              21.021787779017764
            ],
            [
              105.81968851546408,
              21.021787163729694
            ],
            [
              105.81961921167547,
              21.02165993141214
            ],
            [
              105.81962504210388,
              21.021657269508186
            ],
            [
              105.81981422784233,
              21.021570925178068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.663",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021819807704915,
          "to_latitude": 21.021570925178068,
          "to_longitude": 105.81981422784233,
          "from_longitude": 105.81969254182945
        }
      },
      {
        "id": 663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81977973617062,
            21.021843028254555,
            105.8201018725738,
            21.021995866181022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81977973617062,
              21.021995866181022
            ],
            [
              105.81978748475512,
              21.021989325604167
            ],
            [
              105.81979895048418,
              21.02198261898173
            ],
            [
              105.81980034761779,
              21.021981801150385
            ],
            [
              105.8201018725738,
              21.021843028254555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.664",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021995866181022,
          "to_latitude": 21.021843028254555,
          "to_longitude": 105.8201018725738,
          "from_longitude": 105.81977973617062
        }
      },
      {
        "id": 664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81994447589432,
            21.02219441687256,
            105.82015538501756,
            21.022509314250343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994447589432,
              21.02219441687256
            ],
            [
              105.81994731990633,
              21.022198359293817
            ],
            [
              105.82001288088642,
              21.022296341352565
            ],
            [
              105.82015538501756,
              21.022509314250343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.665",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02219441687256,
          "to_latitude": 21.022509314250343,
          "to_longitude": 105.82015538501756,
          "from_longitude": 105.81994447589432
        }
      },
      {
        "id": 665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81978676801626,
            21.021993470552854,
            105.81980677780275,
            21.022005853586432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81978676801626,
              21.022005853586432
            ],
            [
              105.81979573076377,
              21.021998987415014
            ],
            [
              105.81980677780275,
              21.021993470552854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18B_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.666",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022005853586432,
          "to_latitude": 21.021993470552854,
          "to_longitude": 105.81980677780275,
          "from_longitude": 105.81978676801626
        }
      },
      {
        "id": 666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82051016340607,
            21.02228120264596,
            105.82117391883857,
            21.022494149449372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82051016340607,
              21.022490835624318
            ],
            [
              105.82051417362104,
              21.022489903116657
            ],
            [
              105.82053639846878,
              21.022494149449372
            ],
            [
              105.82117391883857,
              21.02228120264596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Ngách 371/3, La Thành",
          "maTaiSan": "04.O12.DODV.667",
          "diaChiLapD": "Ngách 371/3, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022490835624318,
          "to_latitude": 21.02228120264596,
          "to_longitude": 105.82117391883857,
          "from_longitude": 105.82051016340607
        }
      },
      {
        "id": 667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82039922669478,
            21.021989478214,
            105.82102713631306,
            21.022179487624147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82039922669478,
              21.022179487624147
            ],
            [
              105.82040106853044,
              21.022179070481872
            ],
            [
              105.82043823297359,
              21.02217436424686
            ],
            [
              105.82102713631306,
              21.021989478214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Ngách 371/9, La Thành",
          "maTaiSan": "04.O12.DODV.668",
          "diaChiLapD": "Ngách 371/9, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022179487624147,
          "to_latitude": 21.021989478214,
          "to_longitude": 105.82102713631306,
          "from_longitude": 105.82039922669478
        }
      },
      {
        "id": 668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8213810675522,
            21.02280109085815,
            105.82177397216027,
            21.02297873748787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8214067138564,
              21.02297873748787
            ],
            [
              105.8214047827103,
              21.02297619881362
            ],
            [
              105.82138779624191,
              21.022953773358424
            ],
            [
              105.8213810675522,
              21.022944891012436
            ],
            [
              105.82148509008525,
              21.022914359062568
            ],
            [
              105.82177397216027,
              21.02280109085815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_La Thành",
          "maTaiSan": "04.O12.DODV.669",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02297873748787,
          "to_latitude": 21.02280109085815,
          "to_longitude": 105.82177397216027,
          "from_longitude": 105.8214067138564
        }
      },
      {
        "id": 669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82119458900483,
            21.02210819837774,
            105.82172139169177,
            21.022298603759264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82172139169177,
              21.02210819837774
            ],
            [
              105.82171816467026,
              21.022109679904055
            ],
            [
              105.82169094098863,
              21.022133270012727
            ],
            [
              105.82124604428141,
              21.0222829408608
            ],
            [
              105.82119458900483,
              21.022298603759264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.670",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02210819837774,
          "to_latitude": 21.022298603759264,
          "to_longitude": 105.82119458900483,
          "from_longitude": 105.82172139169177
        }
      },
      {
        "id": 670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82110954528929,
            21.021905829217715,
            105.821604121695,
            21.02206027449499
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82110954528929,
              21.02206027449499
            ],
            [
              105.82155320420067,
              21.021905829217715
            ],
            [
              105.82156910845173,
              21.021909312914214
            ],
            [
              105.82159644800659,
              21.02191530111512
            ],
            [
              105.821604121695,
              21.021913015014665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.671",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02206027449499,
          "to_latitude": 21.021913015014665,
          "to_longitude": 105.821604121695,
          "from_longitude": 105.82110954528929
        }
      },
      {
        "id": 671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131667041332,
            21.02147125251624,
            105.8216873183557,
            21.02211680273404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8216873183557,
              21.02211680273404
            ],
            [
              105.82158072017067,
              21.02192961825315
            ],
            [
              105.8215764358278,
              21.021922125912834
            ],
            [
              105.82156910845173,
              21.021909312914214
            ],
            [
              105.82156617419145,
              21.02190418050485
            ],
            [
              105.8215568809289,
              21.02188792848388
            ],
            [
              105.82151360936741,
              21.02181597242733
            ],
            [
              105.82144895065797,
              21.021708455763118
            ],
            [
              105.82144110118533,
              21.021695334569756
            ],
            [
              105.82142877018578,
              21.021674721983565
            ],
            [
              105.82140832757638,
              21.021640547789545
            ],
            [
              105.82137029336569,
              21.021577233756663
            ],
            [
              105.82136002163043,
              21.02155954309274
            ],
            [
              105.82134054059998,
              21.02152599289125
            ],
            [
              105.82131667041332,
              21.02147125251624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.672",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02211680273404,
          "to_latitude": 21.02147125251624,
          "to_longitude": 105.82131667041332,
          "from_longitude": 105.8216873183557
        }
      },
      {
        "id": 672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8209978053053,
            21.021664984534723,
            105.82145509234866,
            21.02182709236117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8209978053053,
              21.02182709236117
            ],
            [
              105.8214217657886,
              21.021677312843785
            ],
            [
              105.82142877018578,
              21.021674721983565
            ],
            [
              105.82144951360652,
              21.021667049155727
            ],
            [
              105.82145509234866,
              21.021664984534723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.673",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02182709236117,
          "to_latitude": 21.021664984534723,
          "to_longitude": 105.82145509234866,
          "from_longitude": 105.8209978053053
        }
      },
      {
        "id": 673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82164341344723,
            21.021507773442593,
            105.82212223783466,
            21.02225232021344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82212223783466,
              21.02225232021344
            ],
            [
              105.82210903445556,
              21.02224877745101
            ],
            [
              105.82210361976358,
              21.022245498718817
            ],
            [
              105.82209277592625,
              21.022238936807657
            ],
            [
              105.82205776680654,
              21.02219511890943
            ],
            [
              105.82164341344723,
              21.021507773442593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81_Ngõ 16, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.674",
          "diaChiLapD": "Ngõ 16, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 98.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225232021344,
          "to_latitude": 21.021507773442593,
          "to_longitude": 105.82164341344723,
          "from_longitude": 105.82212223783466
        }
      },
      {
        "id": 674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82187657536298,
            21.021427553258032,
            105.82223304203848,
            21.02195361975294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82187657536298,
              21.021427553258032
            ],
            [
              105.82218877195298,
              21.02192979800839
            ],
            [
              105.82220264557559,
              21.021944351463848
            ],
            [
              105.82222652010785,
              21.02195086573189
            ],
            [
              105.82223304203848,
              21.02195361975294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_Ngõ 24, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.675",
          "diaChiLapD": "Ngõ 24, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021427553258032,
          "to_latitude": 21.02195361975294,
          "to_longitude": 105.82223304203848,
          "from_longitude": 105.82187657536298
        }
      },
      {
        "id": 675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8221622133461,
            21.022091426759626,
            105.82258217214358,
            21.022160513058424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8221622133461,
              21.022149929669645
            ],
            [
              105.82217672146842,
              21.022154580702125
            ],
            [
              105.8221831508973,
              21.022157130139647
            ],
            [
              105.8221916762428,
              21.022160513058424
            ],
            [
              105.82224913398967,
              21.022153948846604
            ],
            [
              105.82231656186354,
              21.022144257315777
            ],
            [
              105.82258217214358,
              21.022091426759626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82B_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.676",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022149929669645,
          "to_latitude": 21.022091426759626,
          "to_longitude": 105.82258217214358,
          "from_longitude": 105.8221622133461
        }
      },
      {
        "id": 676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82215343446278,
            21.022137924536114,
            105.82249562882343,
            21.02224931189137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82215343446278,
              21.02217241456176
            ],
            [
              105.822169439682,
              21.0221734273896
            ],
            [
              105.82217561099974,
              21.022173816655183
            ],
            [
              105.8223385976773,
              21.02215648523192
            ],
            [
              105.82244719392081,
              21.022137924536114
            ],
            [
              105.82246847296453,
              21.02214249041567
            ],
            [
              105.8224761627185,
              21.022164705105947
            ],
            [
              105.82249562882343,
              21.02224931189137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT154_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.677",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02217241456176,
          "to_latitude": 21.02224931189137,
          "to_longitude": 105.82249562882343,
          "from_longitude": 105.82215343446278
        }
      },
      {
        "id": 677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8233815094196,
            21.02206042527591,
            105.82352921671676,
            21.02224225029436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82352921671676,
              21.02224225029436
            ],
            [
              105.82352519090065,
              21.02223245533536
            ],
            [
              105.8235121924344,
              21.022203697239515
            ],
            [
              105.82344513160115,
              21.02221119533699
            ],
            [
              105.82343903236423,
              21.02221184538156
            ],
            [
              105.8234138821713,
              21.02218606443004
            ],
            [
              105.8234017800399,
              21.022150777985548
            ],
            [
              105.8233815094196,
              21.02206042527591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79_La Thành",
          "maTaiSan": "04.O12.DODV.678",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02224225029436,
          "to_latitude": 21.02206042527591,
          "to_longitude": 105.8233815094196,
          "from_longitude": 105.82352921671676
        }
      },
      {
        "id": 678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82128878917614,
            21.020706527132027,
            105.82178171910815,
            21.02085084811354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82128878917614,
              21.02085084811354
            ],
            [
              105.82175319489549,
              21.020738506373018
            ],
            [
              105.8217583190086,
              21.020730550388095
            ],
            [
              105.82177227677684,
              21.020708878686992
            ],
            [
              105.8217793837405,
              21.020707108350614
            ],
            [
              105.82178171910815,
              21.020706527132027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT163_Ngõ 56, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.679",
          "diaChiLapD": "Ngõ 56, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02085084811354,
          "to_latitude": 21.020706527132027,
          "to_longitude": 105.82178171910815,
          "from_longitude": 105.82128878917614
        }
      },
      {
        "id": 679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82096753375366,
            21.020140537861046,
            105.82135977083038,
            21.020202844608082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82096753375366,
              21.020202844608082
            ],
            [
              105.82113685998607,
              21.020178350109465
            ],
            [
              105.82121733083326,
              21.020164699552463
            ],
            [
              105.82122278397473,
              21.020163774515076
            ],
            [
              105.8212228146778,
              21.02016375991521
            ],
            [
              105.82135977083038,
              21.020140537861046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT159_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.680",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020202844608082,
          "to_latitude": 21.020140537861046,
          "to_longitude": 105.82135977083038,
          "from_longitude": 105.82096753375366
        }
      },
      {
        "id": 680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82093367562871,
            21.019615624494033,
            105.82118140819168,
            21.02014171154404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82093367562871,
              21.019615624494033
            ],
            [
              105.82115899542927,
              21.02009166809756
            ],
            [
              105.82118119675948,
              21.020140869854526
            ],
            [
              105.82118140819168,
              21.02014171154404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT167_Ngách 82/11, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.681",
          "diaChiLapD": "Ngách 82/11, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019615624494033,
          "to_latitude": 21.02014171154404,
          "to_longitude": 105.82118140819168,
          "from_longitude": 105.82093367562871
        }
      },
      {
        "id": 681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82068069263309,
            21.0196893228337,
            105.8209398767561,
            21.02019785047334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82068069263309,
              21.0196893228337
            ],
            [
              105.82088610040414,
              21.020112548264702
            ],
            [
              105.82091858578609,
              21.02017558392151
            ],
            [
              105.82093859156053,
              21.020194334996145
            ],
            [
              105.8209398767561,
              21.02019785047334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT168_Ngách 82/23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.682",
          "diaChiLapD": "Ngách 82/23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0196893228337,
          "to_latitude": 21.02019785047334,
          "to_longitude": 105.8209398767561,
          "from_longitude": 105.82068069263309
        }
      },
      {
        "id": 682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81999072509058,
            21.01943848867129,
            105.82115888859036,
            21.01967773257846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82115888859036,
              21.01943848867129
            ],
            [
              105.82094025604606,
              21.019522595632765
            ],
            [
              105.82068668158061,
              21.0196084190355
            ],
            [
              105.82059222109274,
              21.01964280896919
            ],
            [
              105.82042328384094,
              21.019654616122434
            ],
            [
              105.81999072509058,
              21.01967773257846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT171_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.683",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 125.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01943848867129,
          "to_latitude": 21.01967773257846,
          "to_longitude": 105.81999072509058,
          "from_longitude": 105.82115888859036
        }
      },
      {
        "id": 683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82157364863629,
            21.019072843460123,
            105.82192136194696,
            21.019222839490297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82157364863629,
              21.019222839490297
            ],
            [
              105.82170470244017,
              21.019149618687315
            ],
            [
              105.82173733424713,
              21.019131387494348
            ],
            [
              105.82182925969965,
              21.01907458930081
            ],
            [
              105.82184531776757,
              21.019072843460123
            ],
            [
              105.82185777307704,
              21.01908317038713
            ],
            [
              105.82186692202649,
              21.019098744775082
            ],
            [
              105.82189018453975,
              21.01911109871146
            ],
            [
              105.82192136194696,
              21.01909938316036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT172_Ngõ 91, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.684",
          "diaChiLapD": "Ngõ 91, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019222839490297,
          "to_latitude": 21.01909938316036,
          "to_longitude": 105.82192136194696,
          "from_longitude": 105.82157364863629
        }
      },
      {
        "id": 684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82169403088474,
            21.01911262721473,
            105.82173733424713,
            21.019141537622193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82173733424713,
              21.019131387494348
            ],
            [
              105.82172710584692,
              21.01911262721473
            ],
            [
              105.82171987579437,
              21.01911632291723
            ],
            [
              105.82169403088474,
              21.01912880751271
            ],
            [
              105.8216996328754,
              21.019141537622193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT172_Ngõ 91, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.685",
          "diaChiLapD": "Ngõ 91, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019131387494348,
          "to_latitude": 21.019141537622193,
          "to_longitude": 105.8216996328754,
          "from_longitude": 105.82173733424713
        }
      },
      {
        "id": 685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82240695221073,
            21.01869698371536,
            105.82247962151523,
            21.018870507636244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82246959721014,
              21.018870507636244
            ],
            [
              105.82240695221073,
              21.01872293249163
            ],
            [
              105.82244738976219,
              21.018708493108395
            ],
            [
              105.82247962151523,
              21.01869698371536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT125_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.686",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018870507636244,
          "to_latitude": 21.01869698371536,
          "to_longitude": 105.82247962151523,
          "from_longitude": 105.82246959721014
        }
      },
      {
        "id": 686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81962512769037,
            21.019146658138784,
            105.81970337682715,
            21.019448916082222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81970337682715,
              21.019146658138784
            ],
            [
              105.81970212889335,
              21.019153472675544
            ],
            [
              105.81968794932459,
              21.01917243470023
            ],
            [
              105.8196876146811,
              21.019177736447347
            ],
            [
              105.81968065423371,
              21.019288144295658
            ],
            [
              105.81967488539485,
              21.019372105341333
            ],
            [
              105.81967250196357,
              21.019426898430584
            ],
            [
              105.81966600495721,
              21.019448916082222
            ],
            [
              105.81962512769037,
              21.019442305429237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT174_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.687",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019146658138784,
          "to_latitude": 21.019442305429237,
          "to_longitude": 105.81962512769037,
          "from_longitude": 105.81970337682715
        }
      },
      {
        "id": 687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81924731562535,
            21.019624698509524,
            105.81996601799027,
            21.019769572538316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81924731562535,
              21.01962564964638
            ],
            [
              105.81925876358507,
              21.019624698509524
            ],
            [
              105.8195445716591,
              21.019702782212978
            ],
            [
              105.8196429114349,
              21.01971949679269
            ],
            [
              105.81978885644746,
              21.019750894165682
            ],
            [
              105.81994672931968,
              21.019764240737853
            ],
            [
              105.81995138725716,
              21.01976830538558
            ],
            [
              105.81996601799027,
              21.019769572538316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT175_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.688",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01962564964638,
          "to_latitude": 21.019769572538316,
          "to_longitude": 105.81996601799027,
          "from_longitude": 105.81924731562535
        }
      },
      {
        "id": 688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81928096545117,
            21.0199390917475,
            105.81994746556822,
            21.020001632747146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994746556822,
              21.01994692561457
            ],
            [
              105.8199386655495,
              21.019947111646673
            ],
            [
              105.81993423908919,
              21.01994720524166
            ],
            [
              105.81991808466731,
              21.0199390917475
            ],
            [
              105.81984376282841,
              21.019943792690242
            ],
            [
              105.81965716569833,
              21.01995847698541
            ],
            [
              105.81944056708471,
              21.019980627163925
            ],
            [
              105.81928096545117,
              21.020001632747146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47\"_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.689",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01994692561457,
          "to_latitude": 21.020001632747146,
          "to_longitude": 105.81928096545117,
          "from_longitude": 105.81994746556822
        }
      },
      {
        "id": 689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82302387475934,
            21.020024103630494,
            105.82313559466634,
            21.02049919601289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82313559466634,
              21.020492703956513
            ],
            [
              105.8230625703778,
              21.02049915409648
            ],
            [
              105.82305950769549,
              21.02049919601289
            ],
            [
              105.82305647618276,
              21.020498782552476
            ],
            [
              105.82305355284969,
              21.02049792327814
            ],
            [
              105.823050810928,
              21.020496640416155
            ],
            [
              105.82304832081815,
              21.020494966142046
            ],
            [
              105.82304614336057,
              21.020492943516267
            ],
            [
              105.82304433462069,
              21.020490621944983
            ],
            [
              105.82304294109898,
              21.02048806081612
            ],
            [
              105.82304199585855,
              21.020485325001978
            ],
            [
              105.82304152428661,
              21.020482483024935
            ],
            [
              105.82302387475934,
              21.020261063071068
            ],
            [
              105.82304730829532,
              21.02003548639174
            ],
            [
              105.82304849156908,
              21.020024103630494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT119_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.690",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020492703956513,
          "to_latitude": 21.020024103630494,
          "to_longitude": 105.82304849156908,
          "from_longitude": 105.82313559466634
        }
      },
      {
        "id": 690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.822919102928,
            21.020023533771088,
            105.82297857693314,
            21.020663744416485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.822919102928,
              21.020023533771088
            ],
            [
              105.82291949795574,
              21.02003556734798
            ],
            [
              105.82292588152033,
              21.020241532532616
            ],
            [
              105.82294912306281,
              21.020445816538462
            ],
            [
              105.82295742814371,
              21.020518808507063
            ],
            [
              105.82295973412126,
              21.020569315686817
            ],
            [
              105.82297857693314,
              21.020663744416485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT180_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.691",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020023533771088,
          "to_latitude": 21.020663744416485,
          "to_longitude": 105.82297857693314,
          "from_longitude": 105.822919102928
        }
      },
      {
        "id": 691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334817497852,
            21.020029524155838,
            105.82374918329589,
            21.020274256666887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82334817497852,
              21.020029524155838
            ],
            [
              105.82334889049936,
              21.02003842650122
            ],
            [
              105.82335493515728,
              21.020113527584392
            ],
            [
              105.8233857191419,
              21.020155236777594
            ],
            [
              105.8235032700254,
              21.02025462502964
            ],
            [
              105.823515702106,
              21.02026716484397
            ],
            [
              105.82351850465086,
              21.0202693271471
            ],
            [
              105.82352291962565,
              21.020271695708264
            ],
            [
              105.823527695179,
              21.020273334523438
            ],
            [
              105.8235326954142,
              21.020274196379546
            ],
            [
              105.82353777974843,
              21.020274256666887
            ],
            [
              105.82354280195646,
              21.020273514286956
            ],
            [
              105.82354762074182,
              21.020271989794857
            ],
            [
              105.82356876369207,
              21.020263379039285
            ],
            [
              105.82365897304564,
              21.020210101721904
            ],
            [
              105.82374918329589,
              21.020156824353805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT181_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.692",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020029524155838,
          "to_latitude": 21.020156824353805,
          "to_longitude": 105.82374918329589,
          "from_longitude": 105.82334817497852
        }
      },
      {
        "id": 692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8212620911894,
            21.022960979740848,
            105.82136357731724,
            21.023018856282093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8212620911894,
              21.023018856282093
            ],
            [
              105.82134837348562,
              21.02296965006909
            ],
            [
              105.82136357731724,
              21.022960979740848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_La Thành",
          "maTaiSan": "04.O12.DODV.693",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023018856282093,
          "to_latitude": 21.022960979740848,
          "to_longitude": 105.82136357731724,
          "from_longitude": 105.8212620911894
        }
      },
      {
        "id": 693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82247962151523,
            21.018684110345134,
            105.82259292006432,
            21.018872545134528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82247962151523,
              21.01869698371536
            ],
            [
              105.82251467586416,
              21.018684110345134
            ],
            [
              105.82259292006432,
              21.018872545134528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT125_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.694",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01869698371536,
          "to_latitude": 21.018872545134528,
          "to_longitude": 105.82259292006432,
          "from_longitude": 105.82247962151523
        }
      },
      {
        "id": 694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82312293790588,
            21.020048448451934,
            105.82330819562007,
            21.02019968080139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82312293790588,
              21.020051216825063
            ],
            [
              105.82315115903276,
              21.02005260038867
            ],
            [
              105.82315134861952,
              21.020052614826074
            ],
            [
              105.82316816688206,
              21.02005387653512
            ],
            [
              105.82320580482977,
              21.020048448451934
            ],
            [
              105.82322743821405,
              21.020050147497393
            ],
            [
              105.82325958887195,
              21.020057644930223
            ],
            [
              105.82327491685054,
              21.02006430248806
            ],
            [
              105.82330035969352,
              21.020080381412907
            ],
            [
              105.82330819562007,
              21.020094176392906
            ],
            [
              105.82329780215954,
              21.020106024637077
            ],
            [
              105.823196152689,
              21.02013909054399
            ],
            [
              105.82319502310519,
              21.02019968080139
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT131_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.695",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020051216825063,
          "to_latitude": 21.02019968080139,
          "to_longitude": 105.82319502310519,
          "from_longitude": 105.82312293790588
        }
      },
      {
        "id": 695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82292511535141,
            21.021479551342754,
            105.8230777666422,
            21.0215117881611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82292511535141,
              21.0215117881611
            ],
            [
              105.82303133464198,
              21.02148247757218
            ],
            [
              105.8230777666422,
              21.021479551342754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.696",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0215117881611,
          "to_latitude": 21.021479551342754,
          "to_longitude": 105.8230777666422,
          "from_longitude": 105.82292511535141
        }
      },
      {
        "id": 696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8238366318785,
            21.020134370222532,
            105.82401322680262,
            21.02023438627037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82401322680262,
              21.020134370222532
            ],
            [
              105.8238366318785,
              21.02023438627037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT112_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.697",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020134370222532,
          "to_latitude": 21.02023438627037,
          "to_longitude": 105.8238366318785,
          "from_longitude": 105.82401322680262
        }
      },
      {
        "id": 697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82319359432316,
            21.020030539517013,
            105.82350196407137,
            21.02046266649283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82319359432316,
              21.02046266649283
            ],
            [
              105.82329623767907,
              21.020444835904403
            ],
            [
              105.82332185709522,
              21.020363932785983
            ],
            [
              105.82333444613107,
              21.02035880565635
            ],
            [
              105.82347724947779,
              21.02030064843055
            ],
            [
              105.82347694762944,
              21.020299635569053
            ],
            [
              105.8234768302907,
              21.02029858839496
            ],
            [
              105.82347690340671,
              21.020297538492393
            ],
            [
              105.82347716232655,
              21.020296514787095
            ],
            [
              105.82347760048022,
              21.020295547117332
            ],
            [
              105.8234782064683,
              21.020294661731825
            ],
            [
              105.82347896119074,
              21.020293884013405
            ],
            [
              105.82347984457081,
              21.02029323663987
            ],
            [
              105.82348083170206,
              21.020292738699585
            ],
            [
              105.82348182945465,
              21.020292423159518
            ],
            [
              105.82348189379574,
              21.020292402977063
            ],
            [
              105.82348300108113,
              21.020292238648686
            ],
            [
              105.82348412184886,
              21.020292252190558
            ],
            [
              105.82348522338296,
              21.020292441954588
            ],
            [
              105.8234862758384,
              21.020292803569166
            ],
            [
              105.82348724933047,
              21.020293325436995
            ],
            [
              105.82348811492156,
              21.020293993246607
            ],
            [
              105.82348884942081,
              21.020294788142483
            ],
            [
              105.82348943057963,
              21.02029568765172
            ],
            [
              105.82349231766126,
              21.02029450038404
            ],
            [
              105.82349494931657,
              21.02029287086894
            ],
            [
              105.82349724691906,
              21.02029084555162
            ],
            [
              105.82349914634105,
              21.02028848345224
            ],
            [
              105.82350059119985,
              21.020285852585726
            ],
            [
              105.82350153959162,
              21.020283029929175
            ],
            [
              105.82350196407137,
              21.02028009780901
            ],
            [
              105.8235018535571,
              21.020277140278925
            ],
            [
              105.82350120948202,
              21.02027424313844
            ],
            [
              105.8235000515462,
              21.0202714902922
            ],
            [
              105.82349841289724,
              21.02026896196682
            ],
            [
              105.82349413302138,
              21.020263517266446
            ],
            [
              105.82339185102391,
              21.020174037551474
            ],
            [
              105.82334453890304,
              21.020117157391166
            ],
            [
              105.82334056738615,
              21.020039270659222
            ],
            [
              105.82334012311851,
              21.020030539517013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT121_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.698",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02046266649283,
          "to_latitude": 21.020030539517013,
          "to_longitude": 105.82334012311851,
          "from_longitude": 105.82319359432316
        }
      },
      {
        "id": 698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82377938551156,
            21.0200415514073,
            105.82421951768328,
            21.020400724716204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82421951768328,
              21.020400724716204
            ],
            [
              105.82421072138085,
              21.020392488370817
            ],
            [
              105.82410267092055,
              21.020291281565267
            ],
            [
              105.82409885358116,
              21.020284584575222
            ],
            [
              105.82401322680262,
              21.020134370222532
            ],
            [
              105.82400642360774,
              21.020122436338912
            ],
            [
              105.82396786507638,
              21.020062036533144
            ],
            [
              105.82395051599724,
              21.0200415514073
            ],
            [
              105.82393031053195,
              21.02004989844223
            ],
            [
              105.82377938551156,
              21.020137262344033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT112_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.699",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020400724716204,
          "to_latitude": 21.020137262344033,
          "to_longitude": 105.82377938551156,
          "from_longitude": 105.82421951768328
        }
      },
      {
        "id": 699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82284031931901,
            21.021276475332456,
            105.82292511535141,
            21.0215117881611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82292511535141,
              21.0215117881611
            ],
            [
              105.82288440995391,
              21.02141251191601
            ],
            [
              105.82284031931901,
              21.02130497758173
            ],
            [
              105.82287656413776,
              21.02129191772025
            ],
            [
              105.8228734382763,
              21.02127957309815
            ],
            [
              105.82287262957156,
              21.021276475332456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.700",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0215117881611,
          "to_latitude": 21.021276475332456,
          "to_longitude": 105.82287262957156,
          "from_longitude": 105.82292511535141
        }
      },
      {
        "id": 700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82184473886035,
            21.020661513878938,
            105.82209347388512,
            21.020904171904807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184473886035,
              21.020904171904807
            ],
            [
              105.82184926251223,
              21.02090302643444
            ],
            [
              105.82206647182525,
              21.020848050585144
            ],
            [
              105.82209347388512,
              21.020843045370682
            ],
            [
              105.82208035997428,
              21.020792454021294
            ],
            [
              105.82207313044752,
              21.020761514626464
            ],
            [
              105.82205614374122,
              21.020736207937727
            ],
            [
              105.82203258731748,
              21.020661513878938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT162_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.701",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020904171904807,
          "to_latitude": 21.020661513878938,
          "to_longitude": 105.82203258731748,
          "from_longitude": 105.82184473886035
        }
      },
      {
        "id": 701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82235847479573,
            21.020766140201363,
            105.82262198861775,
            21.020834563857004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82235847479573,
              21.020834563857004
            ],
            [
              105.82243476708575,
              21.020833722601967
            ],
            [
              105.82246178581893,
              21.02083158499171
            ],
            [
              105.82262198861775,
              21.020766140201363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT136_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.702",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020834563857004,
          "to_latitude": 21.020766140201363,
          "to_longitude": 105.82262198861775,
          "from_longitude": 105.82235847479573
        }
      },
      {
        "id": 702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82283119974039,
            21.0212844394402,
            105.8234593585587,
            21.02162573764216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234593585587,
              21.021404402171452
            ],
            [
              105.82334239129878,
              21.021474891635773
            ],
            [
              105.82323610534745,
              21.021517629192903
            ],
            [
              105.82323526610482,
              21.021517906932306
            ],
            [
              105.82321609403677,
              21.02152581443436
            ],
            [
              105.82321506047006,
              21.021526091307685
            ],
            [
              105.82296724329478,
              21.02162573764216
            ],
            [
              105.82283119974039,
              21.021308298602513
            ],
            [
              105.82283514296401,
              21.021291309716798
            ],
            [
              105.82283278594247,
              21.0212844394402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Ngách 16/64, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.703",
          "diaChiLapD": "Ngách 16/64, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 98.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021404402171452,
          "to_latitude": 21.0212844394402,
          "to_longitude": 105.82283278594247,
          "from_longitude": 105.8234593585587
        }
      },
      {
        "id": 703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82184598308973,
            21.020834563857004,
            105.82235847479573,
            21.020908983701652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184598308973,
              21.020908983701652
            ],
            [
              105.82185108504196,
              21.02090769092086
            ],
            [
              105.8220689395366,
              21.020852483434716
            ],
            [
              105.82210282789262,
              21.02085002545426
            ],
            [
              105.82222698916351,
              21.020835372016542
            ],
            [
              105.82235847479573,
              21.020834563857004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT136_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.704",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020908983701652,
          "to_latitude": 21.020834563857004,
          "to_longitude": 105.82235847479573,
          "from_longitude": 105.82184598308973
        }
      },
      {
        "id": 704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82185139736616,
            21.020846829890523,
            105.82223198899915,
            21.020993351905876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82185139736616,
              21.02092992413124
            ],
            [
              105.82185807862861,
              21.020927532622135
            ],
            [
              105.82193431975018,
              21.020901550536475
            ],
            [
              105.82203741828774,
              21.02086611642486
            ],
            [
              105.82221605856914,
              21.020846829890523
            ],
            [
              105.82223198899915,
              21.020993351905876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.705",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02092992413124,
          "to_latitude": 21.020993351905876,
          "to_longitude": 105.82223198899915,
          "from_longitude": 105.82185139736616
        }
      },
      {
        "id": 705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82179038704295,
            21.020114186949534,
            105.82184809457117,
            21.020306031282338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82179160860066,
              21.020306031282338
            ],
            [
              105.82179038704295,
              21.020287099238644
            ],
            [
              105.8218098955189,
              21.020269324332595
            ],
            [
              105.82184165376174,
              21.020251912046074
            ],
            [
              105.82184809457117,
              21.0202370707256
            ],
            [
              105.8218226369749,
              21.020114186949534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT177_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.706",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020306031282338,
          "to_latitude": 21.020114186949534,
          "to_longitude": 105.8218226369749,
          "from_longitude": 105.82179160860066
        }
      },
      {
        "id": 706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81630546485385,
            21.018253665707974,
            105.81670947239746,
            21.018789455701672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81670947239746,
              21.018789455701672
            ],
            [
              105.81650746825545,
              21.01852155993751
            ],
            [
              105.81630546485385,
              21.018253665707974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Láng Hạ",
          "maTaiSan": "04.O12.DODV.707",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018789455701672,
          "to_latitude": 21.018253665707974,
          "to_longitude": 105.81630546485385,
          "from_longitude": 105.81670947239746
        }
      },
      {
        "id": 707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81648382363899,
            21.01852155993751,
            105.81650746825545,
            21.018536638097856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81650746825545,
              21.01852155993751
            ],
            [
              105.81648382363899,
              21.018536638097856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Láng Hạ",
          "maTaiSan": "04.O12.DODV.708",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01852155993751,
          "to_latitude": 21.018536638097856,
          "to_longitude": 105.81648382363899,
          "from_longitude": 105.81650746825545
        }
      },
      {
        "id": 708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81652422265881,
            21.018053511995852,
            105.81695139566408,
            21.018622385633545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81695139566408,
              21.018622385633545
            ],
            [
              105.81653673060269,
              21.01806987714889
            ],
            [
              105.81652422265881,
              21.018053511995852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.709",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018622385633545,
          "to_latitude": 21.018053511995852,
          "to_longitude": 105.81652422265881,
          "from_longitude": 105.81695139566408
        }
      },
      {
        "id": 709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81773699881099,
            21.020203445663753,
            105.81821130846892,
            21.02057235111717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81821130846892,
              21.020495810051962
            ],
            [
              105.81801097134363,
              21.02057235111717
            ],
            [
              105.81799510351841,
              21.020549582108526
            ],
            [
              105.81796864422458,
              21.020511614851426
            ],
            [
              105.8177889326396,
              21.020277710406074
            ],
            [
              105.81773699881099,
              21.020203445663753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Láng Hạ",
          "maTaiSan": "04.O12.DODV.710",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020495810051962,
          "to_latitude": 21.020203445663753,
          "to_longitude": 105.81773699881099,
          "from_longitude": 105.81821130846892
        }
      },
      {
        "id": 710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81680230000552,
            21.018174184111874,
            105.81711113198064,
            21.018352203860108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81680230000552,
              21.018352203860108
            ],
            [
              105.81706635900045,
              21.018174184111874
            ],
            [
              105.81710521949691,
              21.018193494521036
            ],
            [
              105.81711001200303,
              21.018190676915765
            ],
            [
              105.81711113198064,
              21.018190018502803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.711",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018352203860108,
          "to_latitude": 21.018190018502803,
          "to_longitude": 105.81711113198064,
          "from_longitude": 105.81680230000552
        }
      },
      {
        "id": 711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81678642155536,
            21.018136475436993,
            105.81706801783407,
            21.018331467936928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81678642155536,
              21.018331467936928
            ],
            [
              105.81705068634574,
              21.018152909804726
            ],
            [
              105.81706760440575,
              21.018136867616917
            ],
            [
              105.81706801783407,
              21.018136475436993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A26_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.712",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018331467936928,
          "to_latitude": 21.018136475436993,
          "to_longitude": 105.81706801783407,
          "from_longitude": 105.81678642155536
        }
      },
      {
        "id": 712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81657523621354,
            21.017860716798225,
            105.81686826481265,
            21.018049289315606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81657523621354,
              21.018049289315606
            ],
            [
              105.81685524537353,
              21.017860716798225
            ],
            [
              105.81686826481265,
              21.01787826631133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.713",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018049289315606,
          "to_latitude": 21.01787826631133,
          "to_longitude": 105.81686826481265,
          "from_longitude": 105.81657523621354
        }
      },
      {
        "id": 713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81707007886267,
            21.017972286204675,
            105.81734189514248,
            21.018142927225746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81734189514248,
              21.017972286204675
            ],
            [
              105.81710721553867,
              21.01812816353583
            ],
            [
              105.81707081988874,
              21.018142632827328
            ],
            [
              105.81707007886267,
              21.018142927225746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A27_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.714",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017972286204675,
          "to_latitude": 21.018142927225746,
          "to_longitude": 105.81707007886267,
          "from_longitude": 105.81734189514248
        }
      },
      {
        "id": 714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81687462085476,
            21.017702821775302,
            105.81711987195474,
            21.017874985978267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81711987195474,
              21.017702821775302
            ],
            [
              105.81687514109643,
              21.01787460051171
            ],
            [
              105.81687462085476,
              21.017874985978267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.715",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017702821775302,
          "to_latitude": 21.017874985978267,
          "to_longitude": 105.81687462085476,
          "from_longitude": 105.81711987195474
        }
      },
      {
        "id": 715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690408433668,
            21.017343527310707,
            105.81712524692833,
            21.017687913480707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81712524692833,
              21.017687913480707
            ],
            [
              105.8171246514011,
              21.017687192856126
            ],
            [
              105.8171140662644,
              21.017674339290913
            ],
            [
              105.81711505270182,
              21.017634868937364
            ],
            [
              105.81696774741837,
              21.017426920962393
            ],
            [
              105.81690408433668,
              21.017343527310707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.716",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017687913480707,
          "to_latitude": 21.017343527310707,
          "to_longitude": 105.81690408433668,
          "from_longitude": 105.81712524692833
        }
      },
      {
        "id": 716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81711505270182,
            21.017477894973577,
            105.81725410810537,
            21.017634868937364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81711505270182,
              21.017634868937364
            ],
            [
              105.81725410810537,
              21.01753713111791
            ],
            [
              105.81721175958096,
              21.017477894973577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.717",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017634868937364,
          "to_latitude": 21.017477894973577,
          "to_longitude": 105.81721175958096,
          "from_longitude": 105.81711505270182
        }
      },
      {
        "id": 717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81714314365931,
            21.01767406775031,
            105.81737545598156,
            21.017959077834554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81714314365931,
              21.01767406775031
            ],
            [
              105.8171455279334,
              21.017676994496057
            ],
            [
              105.81737545598156,
              21.017959077834554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.718",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01767406775031,
          "to_latitude": 21.017959077834554,
          "to_longitude": 105.81737545598156,
          "from_longitude": 105.81714314365931
        }
      },
      {
        "id": 718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81710233727958,
            21.01798973344256,
            105.81736096574836,
            21.018178450663132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81736096574836,
              21.01798973344256
            ],
            [
              105.81710339986348,
              21.018177675106333
            ],
            [
              105.81710233727958,
              21.018178450663132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.719",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01798973344256,
          "to_latitude": 21.018178450663132,
          "to_longitude": 105.81710233727958,
          "from_longitude": 105.81736096574836
        }
      },
      {
        "id": 719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81728345588655,
            21.018238237396066,
            105.81755138204599,
            21.01843742591302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81755138204599,
              21.018238237396066
            ],
            [
              105.81740426934178,
              21.01834471225802
            ],
            [
              105.81732781061592,
              21.018398113523663
            ],
            [
              105.8172871276145,
              21.018431495362293
            ],
            [
              105.81728345588655,
              21.01843450810435
            ],
            [
              105.8172854438007,
              21.01843742591302
            ],
            [
              105.8172872257113,
              21.01843643373749
            ],
            [
              105.81728963248857,
              21.01843501404301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.720",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018238237396066,
          "to_latitude": 21.01843501404301,
          "to_longitude": 105.81728963248857,
          "from_longitude": 105.81755138204599
        }
      },
      {
        "id": 720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81701077863372,
            21.01842982198671,
            105.81728593624932,
            21.018598951624966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81701077863372,
              21.018598951624966
            ],
            [
              105.81728420624225,
              21.01843133597821
            ],
            [
              105.81728593624932,
              21.01842982198671
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.721",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018598951624966,
          "to_latitude": 21.01842982198671,
          "to_longitude": 105.81728593624932,
          "from_longitude": 105.81701077863372
        }
      },
      {
        "id": 721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81726031503433,
            21.018539188607175,
            105.81784724540631,
            21.018950759780186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81726031503433,
              21.018539188607175
            ],
            [
              105.81756420484643,
              21.01893523772388
            ],
            [
              105.81757677571959,
              21.018950759780186
            ],
            [
              105.81760122667276,
              21.018937141725466
            ],
            [
              105.81781464228027,
              21.01880074015771
            ],
            [
              105.81783519856097,
              21.018794065836584
            ],
            [
              105.81784410497069,
              21.01879117423848
            ],
            [
              105.81784724540631,
              21.01879534108139
            ],
            [
              105.81784144509962,
              21.018799279038117
            ],
            [
              105.81784007613597,
              21.018800143685517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.722",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018539188607175,
          "to_latitude": 21.018800143685517,
          "to_longitude": 105.81784007613597,
          "from_longitude": 105.81726031503433
        }
      },
      {
        "id": 722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81725866211845,
            21.018343999466413,
            105.81780991049564,
            21.018768122988266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81780991049564,
              21.018768122988266
            ],
            [
              105.81769611673081,
              21.018613450439204
            ],
            [
              105.81762171509183,
              21.018518011789904
            ],
            [
              105.81753415968222,
              21.018402621709118
            ],
            [
              105.81753065607242,
              21.018398004088308
            ],
            [
              105.81748866649163,
              21.01834450631249
            ],
            [
              105.81748324406232,
              21.018343999466413
            ],
            [
              105.81725866211845,
              21.018497302159826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.723",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018768122988266,
          "to_latitude": 21.018497302159826,
          "to_longitude": 105.81725866211845,
          "from_longitude": 105.81780991049564
        }
      },
      {
        "id": 723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81767222573086,
            21.018530210960982,
            105.81782565958487,
            21.0187393481345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81767222573086,
              21.018530210960982
            ],
            [
              105.81767843611242,
              21.018538675960684
            ],
            [
              105.81782565958487,
              21.0187393481345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.724",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018530210960982,
          "to_latitude": 21.0187393481345,
          "to_longitude": 105.81782565958487,
          "from_longitude": 105.81767222573086
        }
      },
      {
        "id": 724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81767222573086,
            21.018331523773245,
            105.81801035158837,
            21.018530654036862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81767222573086,
              21.018530210960982
            ],
            [
              105.81769128935026,
              21.018530654036862
            ],
            [
              105.81801035158837,
              21.018331523773245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.725",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018530210960982,
          "to_latitude": 21.018331523773245,
          "to_longitude": 105.81801035158837,
          "from_longitude": 105.81767222573086
        }
      },
      {
        "id": 725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81785214342872,
            21.01855001466296,
            105.81852683475226,
            21.018815222851362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81785214342872,
              21.018815222851362
            ],
            [
              105.81785263148231,
              21.018814932375445
            ],
            [
              105.81786607066019,
              21.018806927492168
            ],
            [
              105.81787401185738,
              21.018785447603385
            ],
            [
              105.81821801421263,
              21.01855001466296
            ],
            [
              105.81832454612648,
              21.018629670843634
            ],
            [
              105.81852683475226,
              21.01878305186597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.726",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018815222851362,
          "to_latitude": 21.01878305186597,
          "to_longitude": 105.81852683475226,
          "from_longitude": 105.81785214342872
        }
      },
      {
        "id": 726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81783439757372,
            21.018538173915392,
            105.818195551376,
            21.01879306710543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81783439757372,
              21.01879306710543
            ],
            [
              105.81783579766552,
              21.018792089405032
            ],
            [
              105.8178482614612,
              21.018783387405094
            ],
            [
              105.81785631808067,
              21.018759082570977
            ],
            [
              105.818195551376,
              21.018538173915392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.727",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01879306710543,
          "to_latitude": 21.018538173915392,
          "to_longitude": 105.818195551376,
          "from_longitude": 105.81783439757372
        }
      },
      {
        "id": 727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81810474268771,
            21.01866481948535,
            105.81843322729178,
            21.01913919741412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81841467257013,
              21.01913919741412
            ],
            [
              105.81841463408588,
              21.019135142110176
            ],
            [
              105.81843322729178,
              21.01909626086177
            ],
            [
              105.81833675050403,
              21.01896391993577
            ],
            [
              105.81833427803056,
              21.01896052756754
            ],
            [
              105.81810474268771,
              21.01866481948535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.728",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01913919741412,
          "to_latitude": 21.01866481948535,
          "to_longitude": 105.81810474268771,
          "from_longitude": 105.81841467257013
        }
      },
      {
        "id": 728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81833675050403,
            21.018816394022643,
            105.81865189323369,
            21.01896391993577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81833675050403,
              21.01896391993577
            ],
            [
              105.81835170411958,
              21.01895385921025
            ],
            [
              105.81855602655834,
              21.018816394022643
            ],
            [
              105.8186103172884,
              21.01885069203871
            ],
            [
              105.81865189323369,
              21.018885060259873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.729",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01896391993577,
          "to_latitude": 21.018885060259873,
          "to_longitude": 105.81865189323369,
          "from_longitude": 105.81833675050403
        }
      },
      {
        "id": 729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8180883846441,
            21.018669064741612,
            105.81840876449736,
            21.019140309711297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8180883846441,
              21.018669064741612
            ],
            [
              105.81840598591907,
              21.019104270774932
            ],
            [
              105.81840841365722,
              21.01913577719987
            ],
            [
              105.81840876449736,
              21.019140309711297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.730",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018669064741612,
          "to_latitude": 21.019140309711297,
          "to_longitude": 105.81840876449736,
          "from_longitude": 105.8180883846441
        }
      },
      {
        "id": 730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81816204515826,
            21.019126000723865,
            105.81834419455382,
            21.01917837620208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81834419455382,
              21.019126000723865
            ],
            [
              105.8182470461926,
              21.019152303960876
            ],
            [
              105.81816204515826,
              21.01917837620208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.731",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019126000723865,
          "to_latitude": 21.01917837620208,
          "to_longitude": 105.81816204515826,
          "from_longitude": 105.81834419455382
        }
      },
      {
        "id": 731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8176187244947,
            21.01881838479275,
            105.81808418345008,
            21.01916934832801
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176187244947,
              21.018952799968883
            ],
            [
              105.81782258266577,
              21.018820740979724
            ],
            [
              105.81784167626886,
              21.01881838479275
            ],
            [
              105.81784377217848,
              21.018821170203942
            ],
            [
              105.81786989987783,
              21.01885590102223
            ],
            [
              105.81808418345008,
              21.019140728432777
            ],
            [
              105.8180841327518,
              21.01916934832801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.732",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018952799968883,
          "to_latitude": 21.01916934832801,
          "to_longitude": 105.8180841327518,
          "from_longitude": 105.8176187244947
        }
      },
      {
        "id": 732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81784377217848,
            21.018815573791876,
            105.81785241757727,
            21.018821170203942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81784377217848,
              21.018821170203942
            ],
            [
              105.8178518970691,
              21.01881591048841
            ],
            [
              105.81785241757727,
              21.018815573791876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.733",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018821170203942,
          "to_latitude": 21.018815573791876,
          "to_longitude": 105.81785241757727,
          "from_longitude": 105.81784377217848
        }
      },
      {
        "id": 733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175995604405,
            21.01897523340245,
            105.81774023408433,
            21.019294778310908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175995604405,
              21.01897523340245
            ],
            [
              105.817612632546,
              21.018992527888017
            ],
            [
              105.81767172250971,
              21.01909760259616
            ],
            [
              105.81773740639089,
              21.01928428105098
            ],
            [
              105.81773868307079,
              21.0192869556916
            ],
            [
              105.81774023408433,
              21.019294778310908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.734",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01897523340245,
          "to_latitude": 21.019294778310908,
          "to_longitude": 105.81774023408433,
          "from_longitude": 105.8175995604405
        }
      },
      {
        "id": 734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81737360007764,
            21.019667355795153,
            105.81767085776356,
            21.020104842198585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81767085776356,
              21.020104842198585
            ],
            [
              105.81761883661478,
              21.020005365156376
            ],
            [
              105.81759992458258,
              21.019979298605072
            ],
            [
              105.81753240368487,
              21.01988623487939
            ],
            [
              105.81737360007764,
              21.019667355795153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Láng Hạ",
          "maTaiSan": "04.O12.DODV.735",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020104842198585,
          "to_latitude": 21.019667355795153,
          "to_longitude": 105.81737360007764,
          "from_longitude": 105.81767085776356
        }
      },
      {
        "id": 735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175509587017,
            21.019979298605072,
            105.81759992458258,
            21.020010566126654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81759992458258,
              21.019979298605072
            ],
            [
              105.81759598844805,
              21.019981812237337
            ],
            [
              105.81757001531788,
              21.019998397485573
            ],
            [
              105.8175509587017,
              21.020010566126654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Láng Hạ",
          "maTaiSan": "04.O12.DODV.736",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019979298605072,
          "to_latitude": 21.020010566126654,
          "to_longitude": 105.8175509587017,
          "from_longitude": 105.81759992458258
        }
      },
      {
        "id": 736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81912777810179,
            21.019114863463066,
            105.81927894425283,
            21.019392668027834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81912777810179,
              21.019392668027834
            ],
            [
              105.81920937991956,
              21.019247982769393
            ],
            [
              105.81924189128763,
              21.01918915258078
            ],
            [
              105.81925204422673,
              21.01916879733775
            ],
            [
              105.81927894425283,
              21.019114863463066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.737",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019392668027834,
          "to_latitude": 21.019114863463066,
          "to_longitude": 105.81927894425283,
          "from_longitude": 105.81912777810179
        }
      },
      {
        "id": 737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81857558695363,
            21.01866251827792,
            105.8190915090046,
            21.018970461284724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8190915090046,
              21.018970461284724
            ],
            [
              105.81894672751876,
              21.018893017498705
            ],
            [
              105.81888395438348,
              21.0188582365549
            ],
            [
              105.81883532619113,
              21.01882914015154
            ],
            [
              105.81879761827034,
              21.018806578404554
            ],
            [
              105.81873758248763,
              21.018770657001195
            ],
            [
              105.81857558695363,
              21.01866251827792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.738",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018970461284724,
          "to_latitude": 21.01866251827792,
          "to_longitude": 105.81857558695363,
          "from_longitude": 105.8190915090046
        }
      },
      {
        "id": 738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81811635703134,
            21.018272748620596,
            105.81856601528501,
            21.018653850003073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81856601528501,
              21.018653850003073
            ],
            [
              105.81844092796123,
              21.018560258847668
            ],
            [
              105.81839468275882,
              21.018525176000228
            ],
            [
              105.81836750613867,
              21.01849972825737
            ],
            [
              105.81832500612502,
              21.0184599314949
            ],
            [
              105.81811635703134,
              21.018272748620596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.739",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018653850003073,
          "to_latitude": 21.018272748620596,
          "to_longitude": 105.81811635703134,
          "from_longitude": 105.81856601528501
        }
      },
      {
        "id": 739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81772510409898,
            21.01787398804094,
            105.81809492632705,
            21.018252249075193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81809492632705,
              21.018252249075193
            ],
            [
              105.81804279807703,
              21.01820589775659
            ],
            [
              105.81799210511491,
              21.018157433193544
            ],
            [
              105.81795694023043,
              21.018123813457052
            ],
            [
              105.81790525079177,
              21.01807377120348
            ],
            [
              105.81785003740902,
              21.01802031541813
            ],
            [
              105.81778913869944,
              21.017951119307483
            ],
            [
              105.81772510409898,
              21.01787398804094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.740",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018252249075193,
          "to_latitude": 21.01787398804094,
          "to_longitude": 105.81772510409898,
          "from_longitude": 105.81809492632705
        }
      },
      {
        "id": 740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81734729995088,
            21.01743243199095,
            105.81770865632453,
            21.017865114314958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81770865632453,
              21.017865114314958
            ],
            [
              105.81760744982745,
              21.0177390739838
            ],
            [
              105.81754203761457,
              21.017669126306227
            ],
            [
              105.81752557383494,
              21.01764883945851
            ],
            [
              105.81751514839188,
              21.017635993280678
            ],
            [
              105.81742779938611,
              21.017528355428045
            ],
            [
              105.81741073302746,
              21.01750851946421
            ],
            [
              105.8173745447548,
              21.017466045660758
            ],
            [
              105.81734729995088,
              21.01743243199095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.741",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017865114314958,
          "to_latitude": 21.01743243199095,
          "to_longitude": 105.81734729995088,
          "from_longitude": 105.81770865632453
        }
      },
      {
        "id": 741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81688475714977,
            21.017183219573955,
            105.81734179420717,
            21.017423338557492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81734179420717,
              21.017423338557492
            ],
            [
              105.8172498924504,
              21.01730685730133
            ],
            [
              105.81721459010704,
              21.0172820156217
            ],
            [
              105.81718779903615,
              21.017263162995423
            ],
            [
              105.8171638121436,
              21.017248210579055
            ],
            [
              105.81713982334178,
              21.01723325997478
            ],
            [
              105.81700800827676,
              21.017205357414444
            ],
            [
              105.81692292308593,
              21.017189507024085
            ],
            [
              105.81688475714977,
              21.017183219573955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.742",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017423338557492,
          "to_latitude": 21.017183219573955,
          "to_longitude": 105.81688475714977,
          "from_longitude": 105.81734179420717
        }
      },
      {
        "id": 742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81665395344874,
            21.016971358403207,
            105.81687372463209,
            21.01716875226584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81687372463209,
              21.01716875226584
            ],
            [
              105.81665395344874,
              21.016971358403207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.743",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01716875226584,
          "to_latitude": 21.016971358403207,
          "to_longitude": 105.81665395344874,
          "from_longitude": 105.81687372463209
        }
      },
      {
        "id": 743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81602274631021,
            21.01712714775212,
            105.81641576399487,
            21.017382502558263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81641576399487,
              21.01712714775212
            ],
            [
              105.81621925532204,
              21.01725482526416
            ],
            [
              105.81610728863338,
              21.017327573673143
            ],
            [
              105.81602274631021,
              21.017382502558263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.744",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01712714775212,
          "to_latitude": 21.017382502558263,
          "to_longitude": 105.81602274631021,
          "from_longitude": 105.81641576399487
        }
      },
      {
        "id": 744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81681592009171,
            21.016836853471048,
            105.81716561368648,
            21.017092275975916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81716561368648,
              21.017092275975916
            ],
            [
              105.81707119541076,
              21.017030106019778
            ],
            [
              105.8168457766092,
              21.016880228518772
            ],
            [
              105.81682614763551,
              21.016851712133928
            ],
            [
              105.81681675592397,
              21.016838071524674
            ],
            [
              105.8168159356054,
              21.01683687597725
            ],
            [
              105.81681592009171,
              21.016836853471048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.745",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017092275975916,
          "to_latitude": 21.016836853471048,
          "to_longitude": 105.81681592009171,
          "from_longitude": 105.81716561368648
        }
      },
      {
        "id": 745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81641527762979,
            21.016447120140192,
            105.81668747540294,
            21.01690181775962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81665400672135,
              21.01690181775962
            ],
            [
              105.81663098009237,
              21.01687240178885
            ],
            [
              105.81667910769956,
              21.016819121494823
            ],
            [
              105.81668747540294,
              21.01677843789381
            ],
            [
              105.81668117130022,
              21.016754679854408
            ],
            [
              105.81657503284698,
              21.016633277113268
            ],
            [
              105.81655446996682,
              21.016609012152497
            ],
            [
              105.81654826305079,
              21.01660168696241
            ],
            [
              105.81648144512685,
              21.01652284066665
            ],
            [
              105.81642089500761,
              21.016447120140192
            ],
            [
              105.81641534382034,
              21.016451221239766
            ],
            [
              105.81641530257582,
              21.016451243115156
            ],
            [
              105.81641527762979,
              21.016451254976836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B27_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.746",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01690181775962,
          "to_latitude": 21.016451254976836,
          "to_longitude": 105.81641527762979,
          "from_longitude": 105.81665400672135
        }
      },
      {
        "id": 746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81638608045053,
            21.016196189937904,
            105.81693402489493,
            21.016601741675295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81693402489493,
              21.016601741675295
            ],
            [
              105.81691676147288,
              21.01658543099497
            ],
            [
              105.81689390745767,
              21.016567043540828
            ],
            [
              105.81685727040399,
              21.016541361863233
            ],
            [
              105.81683291962302,
              21.016527215014527
            ],
            [
              105.81680603765298,
              21.016511597215523
            ],
            [
              105.81674427266567,
              21.016475488265247
            ],
            [
              105.81671152259976,
              21.016455231645978
            ],
            [
              105.81670475754498,
              21.016451047856645
            ],
            [
              105.8166622435771,
              21.01642475305631
            ],
            [
              105.81663109093425,
              21.016405485012537
            ],
            [
              105.81656782620375,
              21.016363869893354
            ],
            [
              105.8165386845069,
              21.016336911132345
            ],
            [
              105.81647074948744,
              21.01627071356806
            ],
            [
              105.81641911694072,
              21.016215891570194
            ],
            [
              105.81638608045053,
              21.016196189937904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B28.3_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.747",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016601741675295,
          "to_latitude": 21.016196189937904,
          "to_longitude": 105.81638608045053,
          "from_longitude": 105.81693402489493
        }
      },
      {
        "id": 747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81655446996682,
            21.016455231645978,
            105.81671152259976,
            21.016609012152497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81655446996682,
              21.016609012152497
            ],
            [
              105.81668891429742,
              21.016477368996263
            ],
            [
              105.81671152259976,
              21.016455231645978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B27_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.748",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016609012152497,
          "to_latitude": 21.016455231645978,
          "to_longitude": 105.81671152259976,
          "from_longitude": 105.81655446996682
        }
      },
      {
        "id": 748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81675170449836,
            21.016527215014527,
            105.81683291962302,
            21.016652485314417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81678798538469,
              21.016652485314417
            ],
            [
              105.81675170449836,
              21.01662684977545
            ],
            [
              105.81683291962302,
              21.016527215014527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B28.3_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.749",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016652485314417,
          "to_latitude": 21.016527215014527,
          "to_longitude": 105.81683291962302,
          "from_longitude": 105.81678798538469
        }
      },
      {
        "id": 749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81674315390723,
            21.01626041361757,
            105.81691092897532,
            21.01646064638221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81674315390723,
              21.01646064638221
            ],
            [
              105.81686342502935,
              21.016323773707608
            ],
            [
              105.81691092897532,
              21.01626041361757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28.4_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.750",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01646064638221,
          "to_latitude": 21.01626041361757,
          "to_longitude": 105.81691092897532,
          "from_longitude": 105.81674315390723
        }
      },
      {
        "id": 750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81668727989417,
            21.016687722843116,
            105.81698344282267,
            21.01693226841028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81668727989417,
              21.01693226841028
            ],
            [
              105.81669327736601,
              21.016927447996682
            ],
            [
              105.81681869966111,
              21.016826524364276
            ],
            [
              105.81684213567661,
              21.016806934640034
            ],
            [
              105.81687172640191,
              21.016782608641414
            ],
            [
              105.8169517589228,
              21.016716814404763
            ],
            [
              105.81698344282267,
              21.016687722843116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.751",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01693226841028,
          "to_latitude": 21.016687722843116,
          "to_longitude": 105.81698344282267,
          "from_longitude": 105.81668727989417
        }
      },
      {
        "id": 751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81687172640191,
            21.016782608641414,
            105.81687702897915,
            21.01678814344829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81687172640191,
              21.016782608641414
            ],
            [
              105.81687336720259,
              21.016784323217568
            ],
            [
              105.81687637788671,
              21.016787463734687
            ],
            [
              105.81687702897915,
              21.01678814344829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.752",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016782608641414,
          "to_latitude": 21.01678814344829,
          "to_longitude": 105.81687702897915,
          "from_longitude": 105.81687172640191
        }
      },
      {
        "id": 752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81683366867982,
            21.016574155512437,
            105.81717376470084,
            21.016852693959294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81683366867982,
              21.016852693959294
            ],
            [
              105.81699011740334,
              21.01672438801791
            ],
            [
              105.81701662022293,
              21.016702707925784
            ],
            [
              105.81717376470084,
              21.016574155512437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.753",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016852693959294,
          "to_latitude": 21.016574155512437,
          "to_longitude": 105.81717376470084,
          "from_longitude": 105.81683366867982
        }
      },
      {
        "id": 753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81718648274553,
            21.016120451692572,
            105.81774709356372,
            21.016576833007342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81718648274553,
              21.016576833007342
            ],
            [
              105.81727826859597,
              21.01649189335644
            ],
            [
              105.81732028316858,
              21.016463061912308
            ],
            [
              105.81746849992268,
              21.016335324387565
            ],
            [
              105.81748490386,
              21.016321186829217
            ],
            [
              105.81748901322251,
              21.016317645399113
            ],
            [
              105.81755096454978,
              21.016267561802444
            ],
            [
              105.81774709356372,
              21.016120451692572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.754",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016576833007342,
          "to_latitude": 21.016120451692572,
          "to_longitude": 105.81774709356372,
          "from_longitude": 105.81718648274553
        }
      },
      {
        "id": 754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.817771779499,
            21.01607785695801,
            105.81800377415261,
            21.01650002567292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.817771779499,
              21.01607785695801
            ],
            [
              105.81777242773587,
              21.016079071392546
            ],
            [
              105.81777960466741,
              21.016092457949725
            ],
            [
              105.81777708335612,
              21.016120499924977
            ],
            [
              105.81792986364027,
              21.016231227326877
            ],
            [
              105.81800377415261,
              21.016289533868875
            ],
            [
              105.81787619557501,
              21.01650002567292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.755",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01607785695801,
          "to_latitude": 21.01650002567292,
          "to_longitude": 105.81787619557501,
          "from_longitude": 105.817771779499
        }
      },
      {
        "id": 755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81728873244948,
            21.01607543503177,
            105.8177654684566,
            21.016449666882455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81728873244948,
              21.016449666882455
            ],
            [
              105.8175271012357,
              21.016262551565703
            ],
            [
              105.8175586133874,
              21.016237814282647
            ],
            [
              105.8177654684566,
              21.01607543503177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.756",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016449666882455,
          "to_latitude": 21.01607543503177,
          "to_longitude": 105.8177654684566,
          "from_longitude": 105.81728873244948
        }
      },
      {
        "id": 756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175897073976,
            21.015867774254584,
            105.81783734915386,
            21.01605607819598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175897073976,
              21.015867774254584
            ],
            [
              105.81767758939264,
              21.015951592302798
            ],
            [
              105.8177062222907,
              21.015977129825334
            ],
            [
              105.81779116317914,
              21.01605607819598
            ],
            [
              105.81779805651382,
              21.016047619754726
            ],
            [
              105.81781429734468,
              21.016029866427473
            ],
            [
              105.81783238763813,
              21.016008167943045
            ],
            [
              105.81783306549916,
              21.01600864068527
            ],
            [
              105.81783734915386,
              21.0160116630659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.757",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015867774254584,
          "to_latitude": 21.0160116630659,
          "to_longitude": 105.81783734915386,
          "from_longitude": 105.8175897073976
        }
      },
      {
        "id": 757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81789068855596,
            21.015383151492546,
            105.81833264863198,
            21.015934695541286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81789068855596,
              21.015934695541286
            ],
            [
              105.8179434428412,
              21.015840756461603
            ],
            [
              105.8180804171651,
              21.015673678215194
            ],
            [
              105.81818260630155,
              21.01555699653277
            ],
            [
              105.81833264863198,
              21.015383151492546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.758",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015934695541286,
          "to_latitude": 21.015383151492546,
          "to_longitude": 105.81833264863198,
          "from_longitude": 105.81789068855596
        }
      },
      {
        "id": 758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81791042925923,
            21.015474741017915,
            105.81829461978818,
            21.015957227385407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81791042925923,
              21.015957227385407
            ],
            [
              105.81796875509423,
              21.015873517659152
            ],
            [
              105.8180131139762,
              21.015809829169946
            ],
            [
              105.818065853359,
              21.015745103209134
            ],
            [
              105.81819148913895,
              21.01559090884016
            ],
            [
              105.81829461978818,
              21.015474741017915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B15_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.759",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015957227385407,
          "to_latitude": 21.015474741017915,
          "to_longitude": 105.81829461978818,
          "from_longitude": 105.81791042925923
        }
      },
      {
        "id": 759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81808300535697,
            21.01506776456206,
            105.81851687075908,
            21.015357895647416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81851687075908,
              21.015207124981547
            ],
            [
              105.81847584730734,
              21.015250894031286
            ],
            [
              105.81836039838444,
              21.015357895647416
            ],
            [
              105.81834039249892,
              21.015338239198698
            ],
            [
              105.81827334713824,
              21.01527242556165
            ],
            [
              105.81819115924053,
              21.01518090389943
            ],
            [
              105.81812334475337,
              21.015107735303193
            ],
            [
              105.81808300535697,
              21.01506776456206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.760",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015207124981547,
          "to_latitude": 21.01506776456206,
          "to_longitude": 105.81808300535697,
          "from_longitude": 105.81851687075908
        }
      },
      {
        "id": 760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81757391681383,
            21.01516541402332,
            105.81816964953425,
            21.015610565204415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81757391681383,
              21.01557422895549
            ],
            [
              105.81760655532068,
              21.015610565204415
            ],
            [
              105.81781287301223,
              21.015472760795117
            ],
            [
              105.81816086796314,
              21.015192740044558
            ],
            [
              105.81816127086418,
              21.015172784006843
            ],
            [
              105.81816906155144,
              21.01516589917437
            ],
            [
              105.81816964953425,
              21.01516541402332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.761",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01557422895549,
          "to_latitude": 21.01516541402332,
          "to_longitude": 105.81816964953425,
          "from_longitude": 105.81757391681383
        }
      },
      {
        "id": 761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81834040406852,
            21.014754808799896,
            105.81916578186915,
            21.01528175769359
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81834040406852,
              21.014846134662076
            ],
            [
              105.81834134528253,
              21.01484727362079
            ],
            [
              105.8183466826077,
              21.014853715056677
            ],
            [
              105.81834737372887,
              21.014868432532907
            ],
            [
              105.81834878109834,
              21.0148705591866
            ],
            [
              105.81843708552263,
              21.014955143471067
            ],
            [
              105.8184685080925,
              21.014985112231972
            ],
            [
              105.81861073028155,
              21.01509686984864
            ],
            [
              105.81869579425151,
              21.015158019089295
            ],
            [
              105.81865439517627,
              21.01521667156164
            ],
            [
              105.81874795731322,
              21.01528175769359
            ],
            [
              105.81899584982169,
              21.01497966972923
            ],
            [
              105.81916578186915,
              21.014754808799896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7B_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.762",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 142.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014846134662076,
          "to_latitude": 21.014754808799896,
          "to_longitude": 105.81916578186915,
          "from_longitude": 105.81834040406852
        }
      },
      {
        "id": 762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81864486034968,
            21.0144795677989,
            105.81892094947088,
            21.01482889058307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81864486034968,
              21.01482889058307
            ],
            [
              105.81892094947088,
              21.01458547528146
            ],
            [
              105.81882413828916,
              21.014486552807334
            ],
            [
              105.81880249937029,
              21.01448492004226
            ],
            [
              105.81879707875342,
              21.01448013825014
            ],
            [
              105.81879643499288,
              21.0144795677989
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B10_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.763",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01482889058307,
          "to_latitude": 21.0144795677989,
          "to_longitude": 105.81879643499288,
          "from_longitude": 105.81864486034968
        }
      },
      {
        "id": 763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81791827136823,
            21.01480160349294,
            105.81839416026799,
            21.015063601469507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81791827136823,
              21.014905017333657
            ],
            [
              105.81805316263623,
              21.015063601469507
            ],
            [
              105.81805513712001,
              21.01506212242448
            ],
            [
              105.81806423067721,
              21.015055312622607
            ],
            [
              105.81815635789845,
              21.01498630652324
            ],
            [
              105.81818068014765,
              21.014968088880128
            ],
            [
              105.81828361470225,
              21.014890990210354
            ],
            [
              105.81833166807107,
              21.014851301594113
            ],
            [
              105.81837606846042,
              21.01481646009335
            ],
            [
              105.81839416026799,
              21.01480160349294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.764",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014905017333657,
          "to_latitude": 21.01480160349294,
          "to_longitude": 105.81839416026799,
          "from_longitude": 105.81791827136823
        }
      },
      {
        "id": 764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81841254501188,
            21.014453694297828,
            105.8188267658428,
            21.014790014261596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81841254501188,
              21.014790014261596
            ],
            [
              105.81847578534307,
              21.014739398466595
            ],
            [
              105.8185182639821,
              21.014704075722655
            ],
            [
              105.81858103930169,
              21.014652871416487
            ],
            [
              105.81864786903233,
              21.014598301121836
            ],
            [
              105.81865052791612,
              21.0145962045824
            ],
            [
              105.81865340266285,
              21.014593827003715
            ],
            [
              105.81867453808847,
              21.01457634811197
            ],
            [
              105.81873722845334,
              21.014525812547433
            ],
            [
              105.81877815613139,
              21.01449291966058
            ],
            [
              105.81878723500209,
              21.014485370148925
            ],
            [
              105.81881681583113,
              21.014462191876657
            ],
            [
              105.8188267658428,
              21.014453694297828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.765",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014790014261596,
          "to_latitude": 21.014453694297828,
          "to_longitude": 105.8188267658428,
          "from_longitude": 105.81841254501188
        }
      },
      {
        "id": 765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8186029165598,
            21.01413962656212,
            105.81901113308086,
            21.014339468177944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81901113308086,
              21.01429856636061
            ],
            [
              105.81885796389415,
              21.01413962656212
            ],
            [
              105.8186029165598,
              21.014339468177944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B11_Thái Hà",
          "maTaiSan": "04.O12.DODV.766",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01429856636061,
          "to_latitude": 21.014339468177944,
          "to_longitude": 105.8186029165598,
          "from_longitude": 105.81901113308086
        }
      },
      {
        "id": 766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81835136569978,
            21.01449122465777,
            105.81879872741858,
            21.014853787369802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81835136569978,
              21.014853787369802
            ],
            [
              105.81844541943005,
              21.014777378936483
            ],
            [
              105.8184886052224,
              21.01474313486424
            ],
            [
              105.81855009816904,
              21.014694725001213
            ],
            [
              105.81865963617622,
              21.014608490712742
            ],
            [
              105.8186640009517,
              21.014605054607483
            ],
            [
              105.81879872741858,
              21.01449122465777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.767",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014853787369802,
          "to_latitude": 21.01449122465777,
          "to_longitude": 105.81879872741858,
          "from_longitude": 105.81835136569978
        }
      },
      {
        "id": 767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81623307759554,
            21.016045989488244,
            105.8164696512641,
            21.016199556093525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8164696512641,
              21.016045989488244
            ],
            [
              105.81631952036734,
              21.016149469233778
            ],
            [
              105.816290099644,
              21.016162783073327
            ],
            [
              105.81629007469802,
              21.016162794934978
            ],
            [
              105.81627329067697,
              21.016170389417233
            ],
            [
              105.81626729463999,
              21.016174763614632
            ],
            [
              105.81626724575423,
              21.01617479546216
            ],
            [
              105.81626717193716,
              21.016174841880925
            ],
            [
              105.81626704353165,
              21.01617493281979
            ],
            [
              105.81626698313234,
              21.016174970141922
            ],
            [
              105.81626691315311,
              21.016175014735847
            ],
            [
              105.81623307759554,
              21.016199556093525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.768",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016045989488244,
          "to_latitude": 21.016199556093525,
          "to_longitude": 105.81623307759554,
          "from_longitude": 105.8164696512641
        }
      },
      {
        "id": 768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81789684098686,
            21.0143471088891,
            105.81858896023213,
            21.014877247661488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81789684098686,
              21.014877247661488
            ],
            [
              105.81789687165471,
              21.014877226739827
            ],
            [
              105.81791674206264,
              21.01486147466899
            ],
            [
              105.81824268323844,
              21.014603164997954
            ],
            [
              105.81858896023213,
              21.0143471088891
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B14_Thái Hà",
          "maTaiSan": "04.O12.DODV.769",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014877247661488,
          "to_latitude": 21.0143471088891,
          "to_longitude": 105.81858896023213,
          "from_longitude": 105.81789684098686
        }
      },
      {
        "id": 769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753415968222,
            21.018396394065466,
            105.81754372222176,
            21.018402621709118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81753415968222,
              21.018402621709118
            ],
            [
              105.8175347355879,
              21.01840221248913
            ],
            [
              105.81753475858564,
              21.018402196120533
            ],
            [
              105.81754269079656,
              21.018397061991195
            ],
            [
              105.81754372222176,
              21.018396394065466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.770",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018402621709118,
          "to_latitude": 21.018396394065466,
          "to_longitude": 105.81754372222176,
          "from_longitude": 105.81753415968222
        }
      },
      {
        "id": 770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81764309559324,
            21.018529690716665,
            105.8176498612118,
            21.018534071371633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176498612118,
              21.018529690716665
            ],
            [
              105.81764510955831,
              21.018532767366892
            ],
            [
              105.81764309559324,
              21.018534071371633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.771",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018529690716665,
          "to_latitude": 21.018534071371633,
          "to_longitude": 105.81764309559324,
          "from_longitude": 105.8176498612118
        }
      },
      {
        "id": 771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81796974066698,
            21.020549582108526,
            105.81799510351841,
            21.02056536603085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81799510351841,
              21.020549582108526
            ],
            [
              105.81799193112552,
              21.02055155646366
            ],
            [
              105.817975736483,
              21.020561634870727
            ],
            [
              105.81796974066698,
              21.02056536603085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Láng Hạ",
          "maTaiSan": "04.O12.DODV.772",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020549582108526,
          "to_latitude": 21.02056536603085,
          "to_longitude": 105.81796974066698,
          "from_longitude": 105.81799510351841
        }
      },
      {
        "id": 772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81925204422673,
            21.01916879733775,
            105.81926874410331,
            21.019174471366245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81925204422673,
              21.01916879733775
            ],
            [
              105.8192645546791,
              21.019173048186953
            ],
            [
              105.8192677225358,
              21.01917412583433
            ],
            [
              105.81926874410331,
              21.019174471366245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.773",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01916879733775,
          "to_latitude": 21.019174471366245,
          "to_longitude": 105.81926874410331,
          "from_longitude": 105.81925204422673
        }
      },
      {
        "id": 773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81883532619113,
            21.018810782949082,
            105.8188509039833,
            21.01882914015154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81883532619113,
              21.01882914015154
            ],
            [
              105.81884187349158,
              21.018821424883747
            ],
            [
              105.81884886810167,
              21.018813180881196
            ],
            [
              105.8188509039833,
              21.018810782949082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.774",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01882914015154,
          "to_latitude": 21.018810782949082,
          "to_longitude": 105.8188509039833,
          "from_longitude": 105.81883532619113
        }
      },
      {
        "id": 774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81836750613867,
            21.018476504790915,
            105.81839109840764,
            21.01849972825737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81836750613867,
              21.01849972825737
            ],
            [
              105.81836984268934,
              21.01849742823934
            ],
            [
              105.81838114367093,
              21.01848630392139
            ],
            [
              105.81839109840764,
              21.018476504790915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.775",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01849972825737,
          "to_latitude": 21.018476504790915,
          "to_longitude": 105.81839109840764,
          "from_longitude": 105.81836750613867
        }
      },
      {
        "id": 775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81799210511491,
            21.018138591984982,
            105.81801255645979,
            21.018157433193544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81799210511491,
              21.018157433193544
            ],
            [
              105.81799801740767,
              21.018151986304368
            ],
            [
              105.81800655323784,
              21.018144122408774
            ],
            [
              105.81801255645979,
              21.018138591984982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.776",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018157433193544,
          "to_latitude": 21.018138591984982,
          "to_longitude": 105.81801255645979,
          "from_longitude": 105.81799210511491
        }
      },
      {
        "id": 776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81751514839188,
            21.017618711747346,
            105.81753847196407,
            21.017635993280678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81751514839188,
              21.017635993280678
            ],
            [
              105.81751767006753,
              21.017634125030373
            ],
            [
              105.81752943819711,
              21.01762540472047
            ],
            [
              105.81753847196407,
              21.017618711747346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.777",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017635993280678,
          "to_latitude": 21.017618711747346,
          "to_longitude": 105.81753847196407,
          "from_longitude": 105.81751514839188
        }
      },
      {
        "id": 777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81721459010704,
            21.0172534975168,
            105.81723218173846,
            21.0172820156217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81721459010704,
              21.0172820156217
            ],
            [
              105.81722570824378,
              21.017263992503697
            ],
            [
              105.81723006013202,
              21.017256935456622
            ],
            [
              105.81723218173846,
              21.0172534975168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.778",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0172820156217,
          "to_latitude": 21.0172534975168,
          "to_longitude": 105.81723218173846,
          "from_longitude": 105.81721459010704
        }
      },
      {
        "id": 778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81660778444163,
            21.016968478345568,
            105.81662403892854,
            21.01699117429841
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81662403892854,
              21.01699117429841
            ],
            [
              105.81661845274715,
              21.01698337374408
            ],
            [
              105.8166103492628,
              21.016972060879386
            ],
            [
              105.81660778444163,
              21.016968478345568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.779",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01699117429841,
          "to_latitude": 21.016968478345568,
          "to_longitude": 105.81660778444163,
          "from_longitude": 105.81662403892854
        }
      },
      {
        "id": 779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816693651398,
            21.016851712133928,
            105.81682614763551,
            21.016958863162678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816693651398,
              21.016958863162678
            ],
            [
              105.8168178948778,
              21.016858385964596
            ],
            [
              105.81682614763551,
              21.016851712133928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.780",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016958863162678,
          "to_latitude": 21.016851712133928,
          "to_longitude": 105.81682614763551,
          "from_longitude": 105.816693651398
        }
      },
      {
        "id": 780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81747283106797,
            21.01630770094594,
            105.81748490386,
            21.016321186829217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81748490386,
              21.016321186829217
            ],
            [
              105.81747796447259,
              21.01631343525717
            ],
            [
              105.81747382795338,
              21.016308817060995
            ],
            [
              105.81747283106797,
              21.01630770094594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.781",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016321186829217,
          "to_latitude": 21.01630770094594,
          "to_longitude": 105.81747283106797,
          "from_longitude": 105.81748490386
        }
      },
      {
        "id": 781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175586133874,
            21.016237814282647,
            105.81756135504516,
            21.01624118911325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175586133874,
              21.016237814282647
            ],
            [
              105.81755960315365,
              21.01623903610902
            ],
            [
              105.81756135504516,
              21.01624118911325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.782",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016237814282647,
          "to_latitude": 21.01624118911325,
          "to_longitude": 105.81756135504516,
          "from_longitude": 105.8175586133874
        }
      },
      {
        "id": 782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81794365693395,
            21.015851969000327,
            105.81796875509423,
            21.015873517659152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81796875509423,
              21.015873517659152
            ],
            [
              105.81795666445926,
              21.0158631109977
            ],
            [
              105.81794450432173,
              21.015852659508088
            ],
            [
              105.81794365693395,
              21.015851969000327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B15_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.783",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015873517659152,
          "to_latitude": 21.015851969000327,
          "to_longitude": 105.81794365693395,
          "from_longitude": 105.81796875509423
        }
      },
      {
        "id": 783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8179434428412,
            21.015840756461603,
            105.81795124682485,
            21.015846003724757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8179434428412,
              21.015840756461603
            ],
            [
              105.81794979876176,
              21.01584541184708
            ],
            [
              105.81795124682485,
              21.015846003724757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.784",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015840756461603,
          "to_latitude": 21.015846003724757,
          "to_longitude": 105.81795124682485,
          "from_longitude": 105.8179434428412
        }
      },
      {
        "id": 784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81833056941473,
            21.015338239198698,
            105.81834039249892,
            21.015346567236584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81834039249892,
              21.015338239198698
            ],
            [
              105.8183403723924,
              21.01533825645673
            ],
            [
              105.81833780818252,
              21.01534043021381
            ],
            [
              105.8183334151311,
              21.01534415457676
            ],
            [
              105.81833059626943,
              21.01534655265577
            ],
            [
              105.81833056941473,
              21.015346567236584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.785",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015338239198698,
          "to_latitude": 21.015346567236584,
          "to_longitude": 105.81833056941473,
          "from_longitude": 105.81834039249892
        }
      },
      {
        "id": 785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81815635789845,
            21.01498630652324,
            105.81816206307607,
            21.0149919387106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81815635789845,
              21.01498630652324
            ],
            [
              105.81815904172245,
              21.01498895633145
            ],
            [
              105.81816109110463,
              21.01499098054431
            ],
            [
              105.81816206307607,
              21.0149919387106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.786",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01498630652324,
          "to_latitude": 21.0149919387106,
          "to_longitude": 105.81816206307607,
          "from_longitude": 105.81815635789845
        }
      },
      {
        "id": 786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81865015171519,
            21.01459777529856,
            105.81865963617622,
            21.014608490712742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81865963617622,
              21.014608490712742
            ],
            [
              105.81865245144849,
              21.014600373656776
            ],
            [
              105.81865051856046,
              21.01459819172769
            ],
            [
              105.81865015171519,
              21.01459777529856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.787",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014608490712742,
          "to_latitude": 21.01459777529856,
          "to_longitude": 105.81865015171519,
          "from_longitude": 105.81865963617622
        }
      },
      {
        "id": 787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81865340266285,
            21.014593827003715,
            105.81865453613511,
            21.014595118584275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81865340266285,
              21.014593827003715
            ],
            [
              105.81865428738203,
              21.014594837071034
            ],
            [
              105.81865453613511,
              21.014595118584275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.788",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014593827003715,
          "to_latitude": 21.014595118584275,
          "to_longitude": 105.81865453613511,
          "from_longitude": 105.81865340266285
        }
      },
      {
        "id": 788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81608708368697,
            21.01729944022407,
            105.81610728863338,
            21.017327573673143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610728863338,
              21.017327573673143
            ],
            [
              105.81610066641122,
              21.01731835277881
            ],
            [
              105.81609095215539,
              21.017304829289642
            ],
            [
              105.81608708368697,
              21.01729944022407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.789",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017327573673143,
          "to_latitude": 21.01729944022407,
          "to_longitude": 105.81608708368697,
          "from_longitude": 105.81610728863338
        }
      },
      {
        "id": 789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81773740639089,
            21.019217606722894,
            105.81800114198653,
            21.01928428105098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81800114198653,
              21.019217606722894
            ],
            [
              105.81773740639089,
              21.01928428105098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.790",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019217606722894,
          "to_latitude": 21.01928428105098,
          "to_longitude": 105.81773740639089,
          "from_longitude": 105.81800114198653
        }
      },
      {
        "id": 790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81749933690611,
            21.018182823036422,
            105.81769655562435,
            21.018344047678852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81769655562435,
              21.018182823036422
            ],
            [
              105.81759588476612,
              21.01825442160184
            ],
            [
              105.81750035814753,
              21.018343099801893
            ],
            [
              105.81749933690611,
              21.018344047678852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.791",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018182823036422,
          "to_latitude": 21.018344047678852,
          "to_longitude": 105.81749933690611,
          "from_longitude": 105.81769655562435
        }
      },
      {
        "id": 791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81739053626866,
            21.01799238594491,
            105.81759588476612,
            21.01825442160184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81759588476612,
              21.01825442160184
            ],
            [
              105.81759584117393,
              21.018254365811273
            ],
            [
              105.81739053626866,
              21.01799238594491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.792",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01825442160184,
          "to_latitude": 21.01799238594491,
          "to_longitude": 105.81739053626866,
          "from_longitude": 105.81759588476612
        }
      },
      {
        "id": 792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81682848566346,
            21.01492014374014,
            105.81787335548685,
            21.015644840792916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81682848566346,
              21.015644840792916
            ],
            [
              105.81726342136184,
              21.015343181243868
            ],
            [
              105.81763364809538,
              21.01508640137681
            ],
            [
              105.81787335548685,
              21.01492014374014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B13_Thái Hà",
          "maTaiSan": "04.O12.DODV.793",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 135.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015644840792916,
          "to_latitude": 21.01492014374014,
          "to_longitude": 105.81787335548685,
          "from_longitude": 105.81682848566346
        }
      },
      {
        "id": 793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81833730710213,
            21.01484833536873,
            105.81868111910184,
            21.015151349824766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81868111910184,
              21.015151349824766
            ],
            [
              105.81853005516297,
              21.015042145781603
            ],
            [
              105.81845002726908,
              21.014977333123678
            ],
            [
              105.81837240933635,
              21.01489916545482
            ],
            [
              105.81834175789898,
              21.014872654133168
            ],
            [
              105.81834154563205,
              21.014854551766916
            ],
            [
              105.81833790056531,
              21.014849207741566
            ],
            [
              105.81833730710213,
              21.01484833536873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7A_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.794",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015151349824766,
          "to_latitude": 21.01484833536873,
          "to_longitude": 105.81833730710213,
          "from_longitude": 105.81868111910184
        }
      },
      {
        "id": 794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81773703769547,
            21.01517967044639,
            105.81818338487354,
            21.015681411851528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81781341615073,
              21.015681411851528
            ],
            [
              105.81778701654918,
              21.015653689541537
            ],
            [
              105.81782244992671,
              21.015622521352274
            ],
            [
              105.81773703769547,
              21.015540707505288
            ],
            [
              105.81817176281731,
              21.015197317594875
            ],
            [
              105.81817273651704,
              21.015186952893828
            ],
            [
              105.81818257533575,
              21.01518009885642
            ],
            [
              105.81818338487354,
              21.01517967044639
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.795",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015681411851528,
          "to_latitude": 21.01517967044639,
          "to_longitude": 105.81818338487354,
          "from_longitude": 105.81781341615073
        }
      },
      {
        "id": 795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81640389855049,
            21.016441235119153,
            105.81668052678972,
            21.01690432950235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81664820157378,
              21.01690432950235
            ],
            [
              105.81662331604863,
              21.01686812386055
            ],
            [
              105.81667594871672,
              21.01681171122641
            ],
            [
              105.81668052678972,
              21.016779588543653
            ],
            [
              105.81667644894192,
              21.016759597114188
            ],
            [
              105.81662748013453,
              21.016697950260113
            ],
            [
              105.81651890149777,
              21.01657587545096
            ],
            [
              105.8164339368244,
              21.016477472837767
            ],
            [
              105.81642354544479,
              21.016466610813936
            ],
            [
              105.81640389855049,
              21.016444320408993
            ],
            [
              105.8164072332973,
              21.016441257819277
            ],
            [
              105.81640725818433,
              21.016441235119153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B26_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.796",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01690432950235,
          "to_latitude": 21.016441235119153,
          "to_longitude": 105.81640725818433,
          "from_longitude": 105.81664820157378
        }
      },
      {
        "id": 796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81661794827421,
            21.01632126619196,
            105.81694157307979,
            21.01659463947999
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81669357529947,
              21.01632126619196
            ],
            [
              105.81661794827421,
              21.016384178638823
            ],
            [
              105.81672780155822,
              21.016454164460832
            ],
            [
              105.81674315390723,
              21.01646064638221
            ],
            [
              105.8167752404145,
              21.01647793275602
            ],
            [
              105.81680612481532,
              21.016494570958447
            ],
            [
              105.8169195211742,
              21.01657622469374
            ],
            [
              105.81694157307979,
              21.01659463947999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B28.4_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.797",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01632126619196,
          "to_latitude": 21.01659463947999,
          "to_longitude": 105.81694157307979,
          "from_longitude": 105.81669357529947
        }
      },
      {
        "id": 797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81686826481265,
            21.01787203800048,
            105.81704784327783,
            21.018120330502782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8168732644602,
              21.01787338926298
            ],
            [
              105.81687410155075,
              21.01787271775833
            ],
            [
              105.81687534069317,
              21.01787203800048
            ],
            [
              105.81687598075901,
              21.017872811702457
            ],
            [
              105.81687400290345,
              21.01787421036362
            ],
            [
              105.81686826481265,
              21.01787826631133
            ],
            [
              105.81704784327783,
              21.018120330502782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DODV.798",
          "diaChiLapD": "Ngõ 81, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01787338926298,
          "to_latitude": 21.018120330502782,
          "to_longitude": 105.81704784327783,
          "from_longitude": 105.8168732644602
        }
      },
      {
        "id": 798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8188839805986,
            21.01968844281332,
            105.81919825930541,
            21.019990180742493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81919825930541,
              21.019990180742493
            ],
            [
              105.81907717542026,
              21.019724571160744
            ],
            [
              105.81907585030497,
              21.019721663731804
            ],
            [
              105.81906070548455,
              21.01968844281332
            ],
            [
              105.8188839805986,
              21.019753044322478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.799",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019990180742493,
          "to_latitude": 21.019753044322478,
          "to_longitude": 105.8188839805986,
          "from_longitude": 105.81919825930541
        }
      },
      {
        "id": 799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.818927911467,
            21.019386953703368,
            105.81918220945211,
            21.01990610981991
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81918220945211,
              21.01990610981991
            ],
            [
              105.81917044758642,
              21.019870908103044
            ],
            [
              105.81909281206677,
              21.01968263491734
            ],
            [
              105.81905502149348,
              21.0196751051352
            ],
            [
              105.81904703157086,
              21.019656993264338
            ],
            [
              105.81904379001132,
              21.01964964396106
            ],
            [
              105.818927911467,
              21.019386953703368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.800",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01990610981991,
          "to_latitude": 21.019386953703368,
          "to_longitude": 105.818927911467,
          "from_longitude": 105.81918220945211
        }
      },
      {
        "id": 800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81866989501623,
            21.019180065813096,
            105.81891875220872,
            21.019259634865442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81891875220872,
              21.019180065813096
            ],
            [
              105.81866989501623,
              21.019259634865442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.801",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019180065813096,
          "to_latitude": 21.019259634865442,
          "to_longitude": 105.81866989501623,
          "from_longitude": 105.81891875220872
        }
      },
      {
        "id": 801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81882009409777,
            21.01910080196783,
            105.81897124760754,
            21.019364330138682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81890323765644,
              21.019364330138682
            ],
            [
              105.81891425008043,
              21.01936113479701
            ],
            [
              105.81892225209158,
              21.019357860915104
            ],
            [
              105.81891305919896,
              21.019334539595796
            ],
            [
              105.81896813050454,
              21.019314787345014
            ],
            [
              105.81897124760754,
              21.019299348835922
            ],
            [
              105.81891875220872,
              21.019180065813096
            ],
            [
              105.8188932253435,
              21.019122063342092
            ],
            [
              105.81887677994608,
              21.01910080196783
            ],
            [
              105.81882009409777,
              21.019117126987492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.802",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019364330138682,
          "to_latitude": 21.019117126987492,
          "to_longitude": 105.81882009409777,
          "from_longitude": 105.81890323765644
        }
      },
      {
        "id": 802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81907585030497,
            21.019713311104823,
            105.81909883606548,
            21.019721663731804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81907585030497,
              21.019721663731804
            ],
            [
              105.81908078597148,
              21.019719869641005
            ],
            [
              105.81909883606548,
              21.019713311104823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.803",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019721663731804,
          "to_latitude": 21.019713311104823,
          "to_longitude": 105.81909883606548,
          "from_longitude": 105.81907585030497
        }
      },
      {
        "id": 803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81902811096967,
            21.019656993264338,
            105.81904703157086,
            21.019663916373858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81904703157086,
              21.019656993264338
            ],
            [
              105.81904326338541,
              21.01965837166774
            ],
            [
              105.8190338808491,
              21.019661804848088
            ],
            [
              105.81902811096967,
              21.019663916373858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.804",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019656993264338,
          "to_latitude": 21.019663916373858,
          "to_longitude": 105.81902811096967,
          "from_longitude": 105.81904703157086
        }
      },
      {
        "id": 804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81861623843359,
            21.019202496633145,
            105.81892229554867,
            21.01941721188586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8189046441987,
              21.0193677050488
            ],
            [
              105.8189161556421,
              21.019364289626967
            ],
            [
              105.81892098205685,
              21.019363101228972
            ],
            [
              105.81892229554867,
              21.01936581903773
            ],
            [
              105.81890591720466,
              21.01937075995621
            ],
            [
              105.81889355613686,
              21.019374489274323
            ],
            [
              105.81888188598387,
              21.019348663488888
            ],
            [
              105.81887742194814,
              21.0193501527223
            ],
            [
              105.81870760518211,
              21.01941721188586
            ],
            [
              105.81865238119926,
              21.019283717892794
            ],
            [
              105.81861623843359,
              21.019202496633145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.805",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0193677050488,
          "to_latitude": 21.019202496633145,
          "to_longitude": 105.81861623843359,
          "from_longitude": 105.8189046441987
        }
      },
      {
        "id": 805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82209078707228,
            21.013580597192078,
            105.82243325688223,
            21.013944425488717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243325688223,
              21.013580597192078
            ],
            [
              105.82227227433435,
              21.013751619087575
            ],
            [
              105.82226933831332,
              21.013754737667732
            ],
            [
              105.8222673730029,
              21.013756826394054
            ],
            [
              105.82224443188865,
              21.01378119793734
            ],
            [
              105.82209078707228,
              21.013944425488717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.806",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013580597192078,
          "to_latitude": 21.013944425488717,
          "to_longitude": 105.82209078707228,
          "from_longitude": 105.82243325688223
        }
      },
      {
        "id": 806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82226928163298,
            21.01164295594066,
            105.8224478172971,
            21.01181499568884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82226928163298,
              21.01181499568884
            ],
            [
              105.82232730469163,
              21.011759083721145
            ],
            [
              105.82239584454612,
              21.01169303727788
            ],
            [
              105.8224478172971,
              21.01164295594066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A87_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.807",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01181499568884,
          "to_latitude": 21.01164295594066,
          "to_longitude": 105.8224478172971,
          "from_longitude": 105.82226928163298
        }
      },
      {
        "id": 807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82233893736074,
            21.011772816166516,
            105.82262229517254,
            21.012031053804414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82262229517254,
              21.012031053804414
            ],
            [
              105.82239629385975,
              21.011828523681416
            ],
            [
              105.82233900506426,
              21.01177288358134
            ],
            [
              105.82233893736074,
              21.011772816166516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A83_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.808",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012031053804414,
          "to_latitude": 21.011772816166516,
          "to_longitude": 105.82233893736074,
          "from_longitude": 105.82262229517254
        }
      },
      {
        "id": 808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82234034387854,
            21.01066943632501,
            105.82253652720354,
            21.010872927807114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82234034387854,
              21.010872927807114
            ],
            [
              105.82243843560734,
              21.010771182092746
            ],
            [
              105.82253652720354,
              21.01066943632501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Thái Hà",
          "maTaiSan": "04.O12.DODV.809",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010872927807114,
          "to_latitude": 21.01066943632501,
          "to_longitude": 105.82253652720354,
          "from_longitude": 105.82234034387854
        }
      },
      {
        "id": 809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8223485747778,
            21.01094116748606,
            105.82268787346,
            21.0112898656853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223485747778,
              21.01094116748606
            ],
            [
              105.82249066950536,
              21.011081823110985
            ],
            [
              105.82254557101616,
              21.011138938841498
            ],
            [
              105.82257523623096,
              21.011169800513887
            ],
            [
              105.8226157618082,
              21.011211959628962
            ],
            [
              105.82268787346,
              21.0112898656853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75_Ngõ 70, Thái Hà",
          "maTaiSan": "04.O12.DODV.810",
          "diaChiLapD": "Ngõ 70, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01094116748606,
          "to_latitude": 21.0112898656853,
          "to_longitude": 105.82268787346,
          "from_longitude": 105.8223485747778
        }
      },
      {
        "id": 810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82098250378931,
            21.012728169685687,
            105.82105984803182,
            21.01295380719861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82101641597664,
              21.01295380719861
            ],
            [
              105.82105984803182,
              21.012898327341745
            ],
            [
              105.82099282503867,
              21.012844867188154
            ],
            [
              105.82098250378931,
              21.012836636202927
            ],
            [
              105.82101275832139,
              21.01277163760322
            ],
            [
              105.82102576889449,
              21.012752236728847
            ],
            [
              105.82101065873684,
              21.012735131162174
            ],
            [
              105.82100794469272,
              21.01273205793082
            ],
            [
              105.82101128340166,
              21.012728169685687
            ],
            [
              105.82101252535159,
              21.012729068726586
            ],
            [
              105.8210147879762,
              21.01273086065026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.811",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01295380719861,
          "to_latitude": 21.01273086065026,
          "to_longitude": 105.8210147879762,
          "from_longitude": 105.82101641597664
        }
      },
      {
        "id": 811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82123316717114,
            21.013693674571865,
            105.82125397198436,
            21.013711197339564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82123316717114,
              21.013711197339564
            ],
            [
              105.8212527808382,
              21.013694677484047
            ],
            [
              105.82125397198436,
              21.013693674571865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.812",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013711197339564,
          "to_latitude": 21.013693674571865,
          "to_longitude": 105.82125397198436,
          "from_longitude": 105.82123316717114
        }
      },
      {
        "id": 812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82168292150621,
            21.012631800295207,
            105.82181655469844,
            21.012780077172398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82168292150621,
              21.012631800295207
            ],
            [
              105.82168317991011,
              21.012632087176765
            ],
            [
              105.82181655469844,
              21.012780077172398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Trung Liệt",
          "maTaiSan": "04.O12.DODV.813",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012631800295207,
          "to_latitude": 21.012780077172398,
          "to_longitude": 105.82181655469844,
          "from_longitude": 105.82168292150621
        }
      },
      {
        "id": 813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82132849107383,
            21.012297258531493,
            105.82135329457691,
            21.0123131639627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82132849107383,
              21.0123131639627
            ],
            [
              105.82133743210397,
              21.01230789382787
            ],
            [
              105.82133976773035,
              21.01230632900279
            ],
            [
              105.8213398032017,
              21.012306307154116
            ],
            [
              105.82135329457691,
              21.012297258531493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Trung Liệt",
          "maTaiSan": "04.O12.DODV.814",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0123131639627,
          "to_latitude": 21.012297258531493,
          "to_longitude": 105.82135329457691,
          "from_longitude": 105.82132849107383
        }
      },
      {
        "id": 814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82015895364057,
            21.01441592492017,
            105.82016791650288,
            21.0144226863302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82015895364057,
              21.0144226863302
            ],
            [
              105.82016041934703,
              21.014421580037183
            ],
            [
              105.82016446968844,
              21.014418524765635
            ],
            [
              105.82016791650288,
              21.01441592492017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.815",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0144226863302,
          "to_latitude": 21.01441592492017,
          "to_longitude": 105.82016791650288,
          "from_longitude": 105.82015895364057
        }
      },
      {
        "id": 815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82001606755502,
            21.014296200674828,
            105.82029870255737,
            21.01457528121422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82029870255737,
              21.01457528121422
            ],
            [
              105.82015895364057,
              21.0144226863302
            ],
            [
              105.8201485991561,
              21.01441137996934
            ],
            [
              105.82014060534884,
              21.014400970874764
            ],
            [
              105.82012411300585,
              21.01441451297569
            ],
            [
              105.82001606755502,
              21.014296200674828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.816",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01457528121422,
          "to_latitude": 21.014296200674828,
          "to_longitude": 105.82001606755502,
          "from_longitude": 105.82029870255737
        }
      },
      {
        "id": 816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82051433546918,
            21.01478420545977,
            105.82052260200187,
            21.01479112340136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82052260200187,
              21.01478420545977
            ],
            [
              105.82051762561161,
              21.014788369852607
            ],
            [
              105.82051484105448,
              21.014790700059244
            ],
            [
              105.82051433546918,
              21.01479112340136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.817",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01478420545977,
          "to_latitude": 21.01479112340136,
          "to_longitude": 105.82051433546918,
          "from_longitude": 105.82052260200187
        }
      },
      {
        "id": 817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82034497097568,
            21.014600864229998,
            105.82061533103169,
            21.01487257253777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82034497097568,
              21.014600864229998
            ],
            [
              105.82049311288584,
              21.014753768021116
            ],
            [
              105.82052260200187,
              21.01478420545977
            ],
            [
              105.82057712734597,
              21.014840484500603
            ],
            [
              105.82061533103169,
              21.01487257253777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.818",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014600864229998,
          "to_latitude": 21.01487257253777,
          "to_longitude": 105.82061533103169,
          "from_longitude": 105.82034497097568
        }
      },
      {
        "id": 818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82008169046283,
            21.014899354829566,
            105.8203656683296,
            21.015171324156416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82008169046283,
              21.014899354829566
            ],
            [
              105.82030156000596,
              21.015171324156416
            ],
            [
              105.82030909313121,
              21.015163973502116
            ],
            [
              105.8203575429719,
              21.015124398931988
            ],
            [
              105.8203644561547,
              21.01513156431698
            ],
            [
              105.82036564704903,
              21.015132798705316
            ],
            [
              105.8203656683296,
              21.015132820280154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.819",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014899354829566,
          "to_latitude": 21.015132820280154,
          "to_longitude": 105.8203656683296,
          "from_longitude": 105.82008169046283
        }
      },
      {
        "id": 819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82083351684686,
            21.014351362843033,
            105.82086188239897,
            21.01437454282445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82083351684686,
              21.01437454282445
            ],
            [
              105.82084846382917,
              21.014362328630334
            ],
            [
              105.82085415034874,
              21.0143576820918
            ],
            [
              105.82085983590605,
              21.014353035557743
            ],
            [
              105.82086188239897,
              21.014351362843033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.820",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01437454282445,
          "to_latitude": 21.014351362843033,
          "to_longitude": 105.82086188239897,
          "from_longitude": 105.82083351684686
        }
      },
      {
        "id": 820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82068848264726,
            21.01421436870865,
            105.8210020779214,
            21.014555563562308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82068848264726,
              21.01421436870865
            ],
            [
              105.82080999980744,
              21.014348571084113
            ],
            [
              105.82083351684686,
              21.01437454282445
            ],
            [
              105.82084293044271,
              21.014384939615937
            ],
            [
              105.8208688245946,
              21.014412700013846
            ],
            [
              105.8210020779214,
              21.014555563562308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.821",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01421436870865,
          "to_latitude": 21.014555563562308,
          "to_longitude": 105.8210020779214,
          "from_longitude": 105.82068848264726
        }
      },
      {
        "id": 821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82094763670943,
            21.014631021718017,
            105.82096015113741,
            21.014645552061317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82094763670943,
              21.014631021718017
            ],
            [
              105.82094798036645,
              21.014631418382887
            ],
            [
              105.82094802392913,
              21.0146314687534
            ],
            [
              105.82095386923636,
              21.01463825810564
            ],
            [
              105.82095976003053,
              21.0146450978189
            ],
            [
              105.82096015113741,
              21.014645552061317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.822",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014631021718017,
          "to_latitude": 21.014645552061317,
          "to_longitude": 105.82096015113741,
          "from_longitude": 105.82094763670943
        }
      },
      {
        "id": 822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8206948928214,
            21.014583328756565,
            105.82101274765512,
            21.01485291841668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82101274765512,
              21.014583328756565
            ],
            [
              105.82097805140543,
              21.01460752653866
            ],
            [
              105.82094763670943,
              21.014631021718017
            ],
            [
              105.82091992227687,
              21.014652430957135
            ],
            [
              105.82080249049976,
              21.01474314501238
            ],
            [
              105.82073499274988,
              21.01482052945039
            ],
            [
              105.8206948928214,
              21.01485291841668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.823",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014583328756565,
          "to_latitude": 21.01485291841668,
          "to_longitude": 105.8206948928214,
          "from_longitude": 105.82101274765512
        }
      },
      {
        "id": 823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82055213622003,
            21.014313533341575,
            105.8208466442589,
            21.014600086505034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82055213622003,
              21.014600086505034
            ],
            [
              105.82078076959709,
              21.01437652065439
            ],
            [
              105.82080999980744,
              21.014348571084113
            ],
            [
              105.82084530552224,
              21.014314813350587
            ],
            [
              105.8208466442589,
              21.014313533341575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A27_Ngách 178/49, Thái Hà",
          "maTaiSan": "04.O12.DODV.824",
          "diaChiLapD": "Ngách 178/49, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014600086505034,
          "to_latitude": 21.014313533341575,
          "to_longitude": 105.8208466442589,
          "from_longitude": 105.82055213622003
        }
      },
      {
        "id": 824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82073626634414,
            21.014166690787842,
            105.82087973224095,
            21.01431467073614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82073626634414,
              21.014166690787842
            ],
            [
              105.82077022945495,
              21.014201722998795
            ],
            [
              105.82087973224095,
              21.01431467073614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.825",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014166690787842,
          "to_latitude": 21.01431467073614,
          "to_longitude": 105.82087973224095,
          "from_longitude": 105.82073626634414
        }
      },
      {
        "id": 825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82087973224095,
            21.01416139557287,
            105.82104993515058,
            21.01431467073614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104993515058,
              21.01416139557287
            ],
            [
              105.82087973224095,
              21.01431467073614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.826",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01416139557287,
          "to_latitude": 21.01431467073614,
          "to_longitude": 105.82087973224095,
          "from_longitude": 105.82104993515058
        }
      },
      {
        "id": 826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82098500926405,
            21.014350167621682,
            105.82110826483618,
            21.014512127546606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82110826483618,
              21.014350167621682
            ],
            [
              105.82098644320607,
              21.014480261100687
            ],
            [
              105.8209982597821,
              21.01450015907436
            ],
            [
              105.82099503039343,
              21.014502937629253
            ],
            [
              105.82098500926405,
              21.014512127546606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.827",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014350167621682,
          "to_latitude": 21.014512127546606,
          "to_longitude": 105.82098500926405,
          "from_longitude": 105.82110826483618
        }
      },
      {
        "id": 827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.821218547231,
            21.01453464332984,
            105.82121978500929,
            21.014546022531274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.821218547231,
              21.01453464332984
            ],
            [
              105.82121887951384,
              21.014537706375084
            ],
            [
              105.821219489239,
              21.01454330343927
            ],
            [
              105.82121977592685,
              21.01454594489759
            ],
            [
              105.82121978097375,
              21.014545988228132
            ],
            [
              105.82121978500929,
              21.014546022531274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.828",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01453464332984,
          "to_latitude": 21.014546022531274,
          "to_longitude": 105.82121978500929,
          "from_longitude": 105.821218547231
        }
      },
      {
        "id": 828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82107385709753,
            21.014518899025973,
            105.82140156862366,
            21.014547090839304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82107385709753,
              21.014547090839304
            ],
            [
              105.821218547231,
              21.01453464332984
            ],
            [
              105.82123771335736,
              21.01453299455712
            ],
            [
              105.82140156862366,
              21.014518899025973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.829",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014547090839304,
          "to_latitude": 21.014518899025973,
          "to_longitude": 105.82140156862366,
          "from_longitude": 105.82107385709753
        }
      },
      {
        "id": 829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82108213855562,
            21.013852844786765,
            105.82144801000146,
            21.014158394095332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82108213855562,
              21.014158394095332
            ],
            [
              105.82108295758887,
              21.01415829711033
            ],
            [
              105.82108374705793,
              21.01415807110653
            ],
            [
              105.821084482945,
              21.014157721619213
            ],
            [
              105.82108514510442,
              21.014157258681088
            ],
            [
              105.82142001601991,
              21.01387305325777
            ],
            [
              105.82144801000146,
              21.013852844786765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 183/4, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.830",
          "diaChiLapD": "Ngõ 183/4, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014158394095332,
          "to_latitude": 21.013852844786765,
          "to_longitude": 105.82144801000146,
          "from_longitude": 105.82108213855562
        }
      },
      {
        "id": 830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82128611071549,
            21.013695444237847,
            105.82132652248396,
            21.013732116137213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82128611071549,
              21.013732116137213
            ],
            [
              105.82128757901319,
              21.013730783112322
            ],
            [
              105.8212960777383,
              21.013723070980785
            ],
            [
              105.82132652248396,
              21.013695444237847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.831",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013732116137213,
          "to_latitude": 21.013695444237847,
          "to_longitude": 105.82132652248396,
          "from_longitude": 105.82128611071549
        }
      },
      {
        "id": 831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82098427573052,
            21.013250738631367,
            105.82126734422509,
            21.013440061974233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82126734422509,
              21.01329017281481
            ],
            [
              105.82120295041719,
              21.0132519158265
            ],
            [
              105.82115753443613,
              21.013250738631367
            ],
            [
              105.82111565036824,
              21.013262516527096
            ],
            [
              105.82101224905126,
              21.013390244985892
            ],
            [
              105.82102576443073,
              21.01340155501061
            ],
            [
              105.82098666169026,
              21.01343784749041
            ],
            [
              105.82098427573052,
              21.013440061974233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33A_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.832",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01329017281481,
          "to_latitude": 21.013440061974233,
          "to_longitude": 105.82098427573052,
          "from_longitude": 105.82126734422509
        }
      },
      {
        "id": 832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82107775991199,
            21.013845663579076,
            105.82144157674469,
            21.014124487309246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82107775991199,
              21.014124487309246
            ],
            [
              105.82107862563619,
              21.014124133582456
            ],
            [
              105.82119451177803,
              21.01402759647808
            ],
            [
              105.82138054864576,
              21.013872621473826
            ],
            [
              105.82141553090784,
              21.013867321355285
            ],
            [
              105.82144157674469,
              21.013845663579076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Ngõ 183/4, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.833",
          "diaChiLapD": "Ngõ 183/4, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014124487309246,
          "to_latitude": 21.013845663579076,
          "to_longitude": 105.82144157674469,
          "from_longitude": 105.82107775991199
        }
      },
      {
        "id": 833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82096315177836,
            21.013441981194166,
            105.82135330006653,
            21.013830975155823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82096315177836,
              21.013441981194166
            ],
            [
              105.82114794282786,
              21.013626225717108
            ],
            [
              105.82123316717114,
              21.013711197339564
            ],
            [
              105.82135330006653,
              21.013830975155823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.834",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013441981194166,
          "to_latitude": 21.013830975155823,
          "to_longitude": 105.82135330006653,
          "from_longitude": 105.82096315177836
        }
      },
      {
        "id": 834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82105985150432,
            21.01388526824114,
            105.82119451177803,
            21.01402759647808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82105985150432,
              21.01388526824114
            ],
            [
              105.82119451177803,
              21.01402759647808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Ngõ 183/4, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.835",
          "diaChiLapD": "Ngõ 183/4, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01388526824114,
          "to_latitude": 21.01402759647808,
          "to_longitude": 105.82119451177803,
          "from_longitude": 105.82105985150432
        }
      },
      {
        "id": 835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82094254524866,
            21.01398877689087,
            105.82107775991199,
            21.014124487309246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82094254524866,
              21.01398877689087
            ],
            [
              105.82107775991199,
              21.014124487309246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Ngõ 185, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.836",
          "diaChiLapD": "Ngõ 185, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01398877689087,
          "to_latitude": 21.014124487309246,
          "to_longitude": 105.82107775991199,
          "from_longitude": 105.82094254524866
        }
      },
      {
        "id": 836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82126989714736,
            21.014023740337006,
            105.82160896747679,
            21.014295531671845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82126989714736,
              21.014295531671845
            ],
            [
              105.82158815166923,
              21.01404522746381
            ],
            [
              105.82160896747679,
              21.014023740337006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Ngõ 183/2, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.837",
          "diaChiLapD": "Ngõ 183/2, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014295531671845,
          "to_latitude": 21.014023740337006,
          "to_longitude": 105.82160896747679,
          "from_longitude": 105.82126989714736
        }
      },
      {
        "id": 837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82182601126306,
            21.01379337270527,
            105.82209482070911,
            21.013987829534383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82209482070911,
              21.013987829534383
            ],
            [
              105.8220947347582,
              21.01398776762703
            ],
            [
              105.82209035023133,
              21.013984597711886
            ],
            [
              105.82182601126306,
              21.01379337270527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.838",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013987829534383,
          "to_latitude": 21.01379337270527,
          "to_longitude": 105.82182601126306,
          "from_longitude": 105.82209482070911
        }
      },
      {
        "id": 838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8217655892032,
            21.01350675351105,
            105.82193244487883,
            21.01359362567514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8217655892032,
              21.01357132258545
            ],
            [
              105.82182881394765,
              21.01350675351105
            ],
            [
              105.82193222357924,
              21.013593439776233
            ],
            [
              105.82193244487883,
              21.01359362567514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Ngõ 171, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.839",
          "diaChiLapD": "Ngõ 171, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01357132258545,
          "to_latitude": 21.01359362567514,
          "to_longitude": 105.82193244487883,
          "from_longitude": 105.8217655892032
        }
      },
      {
        "id": 839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82175924902792,
            21.013565236153813,
            105.8223055388797,
            21.013769019760932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223055388797,
              21.013769019760932
            ],
            [
              105.82230546363353,
              21.01376898038263
            ],
            [
              105.82229563727678,
              21.013763841545156
            ],
            [
              105.82227227433435,
              21.013751619087575
            ],
            [
              105.8222391115652,
              21.013735937659575
            ],
            [
              105.82196109130737,
              21.013565236153813
            ],
            [
              105.82193244487883,
              21.01359362567514
            ],
            [
              105.82183687793454,
              21.013688335438033
            ],
            [
              105.82175924902792,
              21.01362784282914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Ngõ 171, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.840",
          "diaChiLapD": "Ngõ 171, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013769019760932,
          "to_latitude": 21.01362784282914,
          "to_longitude": 105.82175924902792,
          "from_longitude": 105.8223055388797
        }
      },
      {
        "id": 840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82226933831332,
            21.013754737667732,
            105.82229987959957,
            21.013774937958193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82226933831332,
              21.013754737667732
            ],
            [
              105.82228641189108,
              21.013766030391924
            ],
            [
              105.82229120331455,
              21.013769201045307
            ],
            [
              105.8222959966521,
              21.013772369882844
            ],
            [
              105.82229981009013,
              21.01377489222957
            ],
            [
              105.82229987959957,
              21.013774937958193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.841",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013754737667732,
          "to_latitude": 21.013774937958193,
          "to_longitude": 105.82229987959957,
          "from_longitude": 105.82226933831332
        }
      },
      {
        "id": 841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82165746014249,
            21.012524032293694,
            105.82203557055912,
            21.012692127839074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82167357819603,
              21.01264335794819
            ],
            [
              105.82167355809521,
              21.012643376109803
            ],
            [
              105.82166035086176,
              21.01265548080935
            ],
            [
              105.82165746014249,
              21.01265299463623
            ],
            [
              105.82167164439898,
              21.01264118870303
            ],
            [
              105.82168292150621,
              21.012631800295207
            ],
            [
              105.82180848788428,
              21.012524032293694
            ],
            [
              105.8218256761582,
              21.01252574127736
            ],
            [
              105.82203557055912,
              21.012692127839074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Trung Liệt",
          "maTaiSan": "04.O12.DODV.842",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01264335794819,
          "to_latitude": 21.012692127839074,
          "to_longitude": 105.82203557055912,
          "from_longitude": 105.82167357819603
        }
      },
      {
        "id": 842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82206282849545,
            21.012735838785474,
            105.8221793633399,
            21.01282432034577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82206282849545,
              21.012735838785474
            ],
            [
              105.8221793633399,
              21.01282432034577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Trung Liệt",
          "maTaiSan": "04.O12.DODV.843",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012735838785474,
          "to_latitude": 21.01282432034577,
          "to_longitude": 105.8221793633399,
          "from_longitude": 105.82206282849545
        }
      },
      {
        "id": 843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82181220024047,
            21.012690846337783,
            105.82206282849545,
            21.01285440490157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184409963803,
              21.012831937489352
            ],
            [
              105.82184407953221,
              21.01283195474778
            ],
            [
              105.82182683329513,
              21.012846888005292
            ],
            [
              105.82182679878561,
              21.012846909849415
            ],
            [
              105.82182258917665,
              21.01285055302582
            ],
            [
              105.82181874239305,
              21.01285440490157
            ],
            [
              105.82181220024047,
              21.01284946513866
            ],
            [
              105.82182125339163,
              21.012840954561344
            ],
            [
              105.82182128499566,
              21.01284092911834
            ],
            [
              105.82183767252323,
              21.012825521298755
            ],
            [
              105.82190112492312,
              21.01276587097973
            ],
            [
              105.82197978099636,
              21.01270316563793
            ],
            [
              105.82199702036222,
              21.012690846337783
            ],
            [
              105.82206282849545,
              21.012735838785474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Trung Liệt",
          "maTaiSan": "04.O12.DODV.844",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012831937489352,
          "to_latitude": 21.012735838785474,
          "to_longitude": 105.82206282849545,
          "from_longitude": 105.82184409963803
        }
      },
      {
        "id": 844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131419330909,
            21.012870853278677,
            105.82188294166536,
            21.013255475914857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82188294166536,
              21.012870853278677
            ],
            [
              105.82188292253628,
              21.01287087324208
            ],
            [
              105.82186563945343,
              21.012888562427122
            ],
            [
              105.82186560784943,
              21.012888587870144
            ],
            [
              105.8218626186001,
              21.012891646184276
            ],
            [
              105.82172647317103,
              21.01300879926917
            ],
            [
              105.82171125834131,
              21.012997716088435
            ],
            [
              105.82155391713349,
              21.013064708622924
            ],
            [
              105.82152760356274,
              21.013043070613808
            ],
            [
              105.82147697552308,
              21.013093460417988
            ],
            [
              105.82131419330909,
              21.013255475914857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35B_Ngõ 16, Trung Liệt",
          "maTaiSan": "04.O12.DODV.845",
          "diaChiLapD": "Ngõ 16, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012870853278677,
          "to_latitude": 21.013255475914857,
          "to_longitude": 105.82131419330909,
          "from_longitude": 105.82188294166536
        }
      },
      {
        "id": 845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82215965784677,
            21.013148402176736,
            105.82237367488693,
            21.01337489074867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82237367488693,
              21.01337489074867
            ],
            [
              105.82226587193841,
              21.013260805821453
            ],
            [
              105.82217448918777,
              21.013164098142475
            ],
            [
              105.82215965784677,
              21.013148402176736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Trung Liệt",
          "maTaiSan": "04.O12.DODV.846",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01337489074867,
          "to_latitude": 21.013148402176736,
          "to_longitude": 105.82215965784677,
          "from_longitude": 105.82237367488693
        }
      },
      {
        "id": 846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8222687743969,
            21.013302877043124,
            105.82229338366734,
            21.01332457045408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8222687743969,
              21.01332457045408
            ],
            [
              105.82227161661474,
              21.01332206471519
            ],
            [
              105.82227326795373,
              21.013320608861854
            ],
            [
              105.82227950762653,
              21.013315108764424
            ],
            [
              105.82227954213606,
              21.0133150869202
            ],
            [
              105.82228545436402,
              21.01330986659895
            ],
            [
              105.82229336356649,
              21.0133028952048
            ],
            [
              105.82229338366734,
              21.013302877043124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Trung Liệt",
          "maTaiSan": "04.O12.DODV.847",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01332457045408,
          "to_latitude": 21.013302877043124,
          "to_longitude": 105.82229338366734,
          "from_longitude": 105.8222687743969
        }
      },
      {
        "id": 847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82216007601448,
            21.01321399611034,
            105.82241206139417,
            21.013470332546994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82241206139417,
              21.013470332546994
            ],
            [
              105.82228154976558,
              21.01333756666466
            ],
            [
              105.8222687743969,
              21.01332457045408
            ],
            [
              105.82226747142214,
              21.01332324539458
            ],
            [
              105.82216007601448,
              21.01321399611034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Trung Liệt",
          "maTaiSan": "04.O12.DODV.848",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013470332546994,
          "to_latitude": 21.01321399611034,
          "to_longitude": 105.82216007601448,
          "from_longitude": 105.82241206139417
        }
      },
      {
        "id": 848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82204923680874,
            21.012200834634843,
            105.82234570311361,
            21.01273596920866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82231783004573,
              21.012200834634843
            ],
            [
              105.82221638974893,
              21.012297294530093
            ],
            [
              105.82204923680874,
              21.012456241879384
            ],
            [
              105.8221675694771,
              21.01257302371218
            ],
            [
              105.82233957657074,
              21.01273596920866
            ],
            [
              105.82234265469464,
              21.012733333167144
            ],
            [
              105.82234570311361,
              21.012730718043386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48C_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.849",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012200834634843,
          "to_latitude": 21.012730718043386,
          "to_longitude": 105.82234570311361,
          "from_longitude": 105.82231783004573
        }
      },
      {
        "id": 849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81989088123026,
            21.014053002553283,
            105.81989961442238,
            21.014060600134073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81989088123026,
              21.014060600134073
            ],
            [
              105.81989708789992,
              21.01405519963524
            ],
            [
              105.81989961442238,
              21.014053002553283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A97A_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.850",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014060600134073,
          "to_latitude": 21.014053002553283,
          "to_longitude": 105.81989961442238,
          "from_longitude": 105.81989088123026
        }
      },
      {
        "id": 850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81948713868114,
            21.013641053324637,
            105.8197218231961,
            21.013894262585502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81948713868114,
              21.013641053324637
            ],
            [
              105.81956663325388,
              21.013721339461974
            ],
            [
              105.81961024370757,
              21.013777422126157
            ],
            [
              105.8196217522696,
              21.013792222066087
            ],
            [
              105.81970136074075,
              21.01387572036559
            ],
            [
              105.8197218231961,
              21.013894262585502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A97_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.851",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013641053324637,
          "to_latitude": 21.013894262585502,
          "to_longitude": 105.8197218231961,
          "from_longitude": 105.81948713868114
        }
      },
      {
        "id": 851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81981997005342,
            21.01397004814274,
            105.81983855374101,
            21.01398180648231
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81983855374101,
              21.01397004814274
            ],
            [
              105.81982221512581,
              21.013980385726647
            ],
            [
              105.81981997005342,
              21.01398180648231
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96A_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.852",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01397004814274,
          "to_latitude": 21.01398180648231,
          "to_longitude": 105.81981997005342,
          "from_longitude": 105.81983855374101
        }
      },
      {
        "id": 852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82003466477663,
            21.014147324674095,
            105.82034268335042,
            21.01446135286374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82034268335042,
              21.01446135286374
            ],
            [
              105.82024364905529,
              21.01435113082557
            ],
            [
              105.82011720624095,
              21.014200195103683
            ],
            [
              105.8200907291713,
              21.014169941778746
            ],
            [
              105.82006792899108,
              21.014147324674095
            ],
            [
              105.82005213750934,
              21.014158505063445
            ],
            [
              105.82003466477663,
              21.014175001821016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.853",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01446135286374,
          "to_latitude": 21.014175001821016,
          "to_longitude": 105.82003466477663,
          "from_longitude": 105.82034268335042
        }
      },
      {
        "id": 853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81985514794026,
            21.013298931719874,
            105.8200153182411,
            21.013468621421882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8200153182411,
              21.013468621421882
            ],
            [
              105.81985514794026,
              21.013298931719874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94B_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.854",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013468621421882,
          "to_latitude": 21.013298931719874,
          "to_longitude": 105.81985514794026,
          "from_longitude": 105.8200153182411
        }
      },
      {
        "id": 854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81987296538884,
            21.013643671857242,
            105.8201865616669,
            21.013979978245324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8201865616669,
              21.013979978245324
            ],
            [
              105.81987296538884,
              21.013643671857242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94_Ngách 178/1, Thái Hà",
          "maTaiSan": "04.O12.DODV.855",
          "diaChiLapD": "Ngách 178/1, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013979978245324,
          "to_latitude": 21.013643671857242,
          "to_longitude": 105.81987296538884,
          "from_longitude": 105.8201865616669
        }
      },
      {
        "id": 855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82020540458693,
            21.014017571854197,
            105.82067753558904,
            21.014306754673573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82067753558904,
              21.0141286880999
            ],
            [
              105.8206762395436,
              21.014130738354396
            ],
            [
              105.82065054555395,
              21.014161177300476
            ],
            [
              105.82047511916055,
              21.014306754673573
            ],
            [
              105.82020540458693,
              21.014017571854197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.856",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0141286880999,
          "to_latitude": 21.014017571854197,
          "to_longitude": 105.82020540458693,
          "from_longitude": 105.82067753558904
        }
      },
      {
        "id": 856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82016478022847,
            21.013610650799208,
            105.82018461353084,
            21.013627485013316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82016478022847,
              21.013627485013316
            ],
            [
              105.82017754826593,
              21.013616647963982
            ],
            [
              105.82018390307937,
              21.01361125396689
            ],
            [
              105.82018461353084,
              21.013610650799208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A93A_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.857",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013627485013316,
          "to_latitude": 21.013610650799208,
          "to_longitude": 105.82018461353084,
          "from_longitude": 105.82016478022847
        }
      },
      {
        "id": 857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82007044624044,
            21.013526346524372,
            105.82034562196928,
            21.013820898250913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007044624044,
              21.013526346524372
            ],
            [
              105.82016478022847,
              21.013627485013316
            ],
            [
              105.82020325388117,
              21.013668734194322
            ],
            [
              105.82029743420289,
              21.013769708053218
            ],
            [
              105.82034562196928,
              21.013820898250913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A93A_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.858",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013526346524372,
          "to_latitude": 21.013820898250913,
          "to_longitude": 105.82034562196928,
          "from_longitude": 105.82007044624044
        }
      },
      {
        "id": 858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8206694121381,
            21.013880485160655,
            105.82077105787681,
            21.014042303937075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82077105787681,
              21.014042303937075
            ],
            [
              105.8206694121381,
              21.013928076282877
            ],
            [
              105.82073440511887,
              21.013880485160655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Ngách 178/40, Thái Hà",
          "maTaiSan": "04.O12.DODV.859",
          "diaChiLapD": "Ngách 178/40, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014042303937075,
          "to_latitude": 21.013880485160655,
          "to_longitude": 105.82073440511887,
          "from_longitude": 105.82077105787681
        }
      },
      {
        "id": 859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82064326324708,
            21.01386133330709,
            105.82099852457941,
            21.01409848962566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82099852457941,
              21.01386133330709
            ],
            [
              105.82077105787681,
              21.014042303937075
            ],
            [
              105.82070806504242,
              21.014092420784362
            ],
            [
              105.82065443476066,
              21.0140905162457
            ],
            [
              105.82064691179852,
              21.01409584363642
            ],
            [
              105.82064326324708,
              21.01409848962566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Ngách 178/40, Thái Hà",
          "maTaiSan": "04.O12.DODV.860",
          "diaChiLapD": "Ngách 178/40, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01386133330709,
          "to_latitude": 21.01409848962566,
          "to_longitude": 105.82064326324708,
          "from_longitude": 105.82099852457941
        }
      },
      {
        "id": 860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82050077230525,
            21.013928120961225,
            105.82051950644578,
            21.01394366552417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82051950644578,
              21.013928120961225
            ],
            [
              105.82051629184264,
              21.013930788338868
            ],
            [
              105.82050223644484,
              21.013942449945418
            ],
            [
              105.82050077230525,
              21.01394366552417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A93_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.861",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013928120961225,
          "to_latitude": 21.01394366552417,
          "to_longitude": 105.82050077230525,
          "from_longitude": 105.82051950644578
        }
      },
      {
        "id": 861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82041294327117,
            21.013815240087215,
            105.82065832550467,
            21.014075171521256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82065832550467,
              21.014075171521256
            ],
            [
              105.82054290336636,
              21.013952905581835
            ],
            [
              105.82051950644578,
              21.013928120961225
            ],
            [
              105.82041294327117,
              21.013815240087215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A93_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.862",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014075171521256,
          "to_latitude": 21.013815240087215,
          "to_longitude": 105.82041294327117,
          "from_longitude": 105.82065832550467
        }
      },
      {
        "id": 862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82077155135063,
            21.012207075467554,
            105.82109698186692,
            21.012529968681243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82109698186692,
              21.012207075467554
            ],
            [
              105.82098145925703,
              21.012329200968104
            ],
            [
              105.82090963757541,
              21.012405127743857
            ],
            [
              105.8209070304697,
              21.012407884326485
            ],
            [
              105.82077155135063,
              21.012529968681243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A90A_Thái Hà",
          "maTaiSan": "04.O12.DODV.863",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012207075467554,
          "to_latitude": 21.012529968681243,
          "to_longitude": 105.82077155135063,
          "from_longitude": 105.82109698186692
        }
      },
      {
        "id": 863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82118254814287,
            21.01255254525846,
            105.82119078449753,
            21.012559823454215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82118254814287,
              21.01255254525846
            ],
            [
              105.82118300054387,
              21.01255295133375
            ],
            [
              105.82119072747881,
              21.012559774955132
            ],
            [
              105.82119078449753,
              21.012559823454215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42A_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.864",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01255254525846,
          "to_latitude": 21.012559823454215,
          "to_longitude": 105.82119078449753,
          "from_longitude": 105.82118254814287
        }
      },
      {
        "id": 864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82102104115957,
            21.01238340574548,
            105.82135046396142,
            21.012705854438348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82102104115957,
              21.012705854438348
            ],
            [
              105.82114786114114,
              21.012590996850477
            ],
            [
              105.82117887940665,
              21.0125562400025
            ],
            [
              105.82118254814287,
              21.01255254525846
            ],
            [
              105.82135046396142,
              21.01238340574548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42A_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.865",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012705854438348,
          "to_latitude": 21.01238340574548,
          "to_longitude": 105.82135046396142,
          "from_longitude": 105.82102104115957
        }
      },
      {
        "id": 865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8207855638882,
            21.012705599461352,
            105.82101711837086,
            21.012892544111438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8207855638882,
              21.012892544111438
            ],
            [
              105.8209126240662,
              21.01274065773384
            ],
            [
              105.82093779680763,
              21.01275546753013
            ],
            [
              105.82098983603035,
              21.012705599461352
            ],
            [
              105.8210162680427,
              21.012727460987154
            ],
            [
              105.82101711837086,
              21.012728162304498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A40_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.866",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012892544111438,
          "to_latitude": 21.012728162304498,
          "to_longitude": 105.82101711837086,
          "from_longitude": 105.8207855638882
        }
      },
      {
        "id": 866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82122844753697,
            21.012157605316744,
            105.82164129934814,
            21.012573686240597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82122844753697,
              21.012157605316744
            ],
            [
              105.82139781514313,
              21.01232539230395
            ],
            [
              105.82141629377634,
              21.01234369822241
            ],
            [
              105.82141662363617,
              21.012344035340163
            ],
            [
              105.82164129934814,
              21.012573686240597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Trung Liệt",
          "maTaiSan": "04.O12.DODV.867",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012157605316744,
          "to_latitude": 21.012573686240597,
          "to_longitude": 105.82164129934814,
          "from_longitude": 105.82122844753697
        }
      },
      {
        "id": 867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82154681740305,
            21.012528814512827,
            105.82157234082334,
            21.012548162037657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82154681740305,
              21.012548162037657
            ],
            [
              105.82155398281486,
              21.012542730658094
            ],
            [
              105.821555993535,
              21.012541206237568
            ],
            [
              105.82155602803955,
              21.0125411834903
            ],
            [
              105.82155767855664,
              21.012539929067437
            ],
            [
              105.82157007337447,
              21.01253053346375
            ],
            [
              105.82157231975064,
              21.01252883087266
            ],
            [
              105.82157234082334,
              21.012528814512827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Trung Liệt",
          "maTaiSan": "04.O12.DODV.868",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012548162037657,
          "to_latitude": 21.012528814512827,
          "to_longitude": 105.82157234082334,
          "from_longitude": 105.82154681740305
        }
      },
      {
        "id": 868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82140608555073,
            21.01239177118825,
            105.82176172263664,
            21.012796112347715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82176172263664,
              21.012796112347715
            ],
            [
              105.82161594442064,
              21.012625027975755
            ],
            [
              105.82161516119768,
              21.012624109562914
            ],
            [
              105.82154681740305,
              21.012548162037657
            ],
            [
              105.82140608555073,
              21.01239177118825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Trung Liệt",
          "maTaiSan": "04.O12.DODV.869",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012796112347715,
          "to_latitude": 21.01239177118825,
          "to_longitude": 105.82140608555073,
          "from_longitude": 105.82176172263664
        }
      },
      {
        "id": 869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82123019505138,
            21.01221923130105,
            105.82136520081163,
            21.012351126429387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82123019505138,
              21.01221923130105
            ],
            [
              105.82130102975815,
              21.012284786151618
            ],
            [
              105.82132849107383,
              21.0123131639627
            ],
            [
              105.82136520081163,
              21.012351126429387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Trung Liệt",
          "maTaiSan": "04.O12.DODV.870",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01221923130105,
          "to_latitude": 21.012351126429387,
          "to_longitude": 105.82136520081163,
          "from_longitude": 105.82123019505138
        }
      },
      {
        "id": 870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82150810617608,
            21.01147859608213,
            105.8217794126496,
            21.01176843757569
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8217794126496,
              21.01147859608213
            ],
            [
              105.82164501152518,
              21.011622121219286
            ],
            [
              105.82160967356872,
              21.011659857977907
            ],
            [
              105.82151416766298,
              21.01176184726821
            ],
            [
              105.82150810617608,
              21.01176843757569
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A98A_Thái Hà",
          "maTaiSan": "04.O12.DODV.871",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01147859608213,
          "to_latitude": 21.01176843757569,
          "to_longitude": 105.82150810617608,
          "from_longitude": 105.8217794126496
        }
      },
      {
        "id": 871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82129743122587,
            21.01197571465465,
            105.821308752798,
            21.01198517098981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82129743122587,
              21.01197571465465
            ],
            [
              105.82130166280974,
              21.01197925023512
            ],
            [
              105.82130730909336,
              21.011983964924237
            ],
            [
              105.821308752798,
              21.01198517098981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A89_Thái Hà",
          "maTaiSan": "04.O12.DODV.872",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01197571465465,
          "to_latitude": 21.01198517098981,
          "to_longitude": 105.821308752798,
          "from_longitude": 105.82129743122587
        }
      },
      {
        "id": 872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82203967657348,
            21.01165830205173,
            105.82205337360773,
            21.011670702693948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82203967657348,
              21.011670702693948
            ],
            [
              105.82204934107362,
              21.011661953409938
            ],
            [
              105.82205324821905,
              21.0116584155609
            ],
            [
              105.82205337360773,
              21.01165830205173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A82_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.873",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011670702693948,
          "to_latitude": 21.01165830205173,
          "to_longitude": 105.82205337360773,
          "from_longitude": 105.82203967657348
        }
      },
      {
        "id": 873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82186161073116,
            21.011513676039367,
            105.82226598387817,
            21.011869520475873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82186161073116,
              21.011513676039367
            ],
            [
              105.82203967657348,
              21.011670702693948
            ],
            [
              105.82205363308148,
              21.011683010382686
            ],
            [
              105.82210677394892,
              21.011729870482505
            ],
            [
              105.82221200425896,
              21.011824265000715
            ],
            [
              105.82224392295565,
              21.01184909402245
            ],
            [
              105.82226598387817,
              21.011869520475873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A82_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.874",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011513676039367,
          "to_latitude": 21.011869520475873,
          "to_longitude": 105.82226598387817,
          "from_longitude": 105.82186161073116
        }
      },
      {
        "id": 874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82205039920568,
            21.011652532948307,
            105.82205490895346,
            21.011656516289953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82205490895346,
              21.011652532948307
            ],
            [
              105.82205053417862,
              21.011656396411897
            ],
            [
              105.82205039920568,
              21.011656516289953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A81_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.875",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011652532948307,
          "to_latitude": 21.011656516289953,
          "to_longitude": 105.82205039920568,
          "from_longitude": 105.82205490895346
        }
      },
      {
        "id": 875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82183483205057,
            21.011446773277182,
            105.82225647502717,
            21.011819793195798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82183483205057,
              21.011446773277182
            ],
            [
              105.82188472378692,
              21.01148948526753
            ],
            [
              105.82204528063922,
              21.011643308741117
            ],
            [
              105.82205490895346,
              21.011652532948307
            ],
            [
              105.82209469478819,
              21.01169065020523
            ],
            [
              105.82225647502717,
              21.011819793195798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A81_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.876",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011446773277182,
          "to_latitude": 21.011819793195798,
          "to_longitude": 105.82225647502717,
          "from_longitude": 105.82183483205057
        }
      },
      {
        "id": 876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82187834580066,
            21.0113294298945,
            105.8222213707224,
            21.01158165569548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8222213707224,
              21.011513658650497
            ],
            [
              105.82221957274164,
              21.01151538347013
            ],
            [
              105.82215046274659,
              21.01158165569548
            ],
            [
              105.82200421911932,
              21.01144065042172
            ],
            [
              105.82190189405163,
              21.01134429978812
            ],
            [
              105.82188918846718,
              21.011336278178646
            ],
            [
              105.82187834580066,
              21.0113294298945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79A_Ngõ 94, Thái Hà",
          "maTaiSan": "04.O12.DODV.877",
          "diaChiLapD": "Ngõ 94, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011513658650497,
          "to_latitude": 21.0113294298945,
          "to_longitude": 105.82187834580066,
          "from_longitude": 105.8222213707224
        }
      },
      {
        "id": 877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82217875150675,
            21.011368193127616,
            105.82232751811789,
            21.01158627801234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227910486041,
              21.011368193127616
            ],
            [
              105.82217875150675,
              21.01147315614141
            ],
            [
              105.8222213707224,
              21.011513658650497
            ],
            [
              105.82222149255867,
              21.011513774578052
            ],
            [
              105.82228568878821,
              21.01158627801234
            ],
            [
              105.82232751811789,
              21.011551279041196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79A_Ngõ 94, Thái Hà",
          "maTaiSan": "04.O12.DODV.878",
          "diaChiLapD": "Ngõ 94, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011368193127616,
          "to_latitude": 21.011551279041196,
          "to_longitude": 105.82232751811789,
          "from_longitude": 105.82227910486041
        }
      },
      {
        "id": 878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82191705777394,
            21.01128410232193,
            105.82193948396657,
            21.011301696344262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82193948396657,
              21.011301696344262
            ],
            [
              105.82192732992308,
              21.011292160974264
            ],
            [
              105.82192310533856,
              21.01128884847226
            ],
            [
              105.82191888266803,
              21.01128553415446
            ],
            [
              105.82191705777394,
              21.01128410232193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Thái Hà",
          "maTaiSan": "04.O12.DODV.879",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011301696344262,
          "to_latitude": 21.01128410232193,
          "to_longitude": 105.82191705777394,
          "from_longitude": 105.82193948396657
        }
      },
      {
        "id": 879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82182298395715,
            21.011193154459725,
            105.82203525062188,
            21.011433738223428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82182298395715,
              21.011433738223428
            ],
            [
              105.82190189405163,
              21.01134429978812
            ],
            [
              105.82190644545624,
              21.011339142053856
            ],
            [
              105.8219221330164,
              21.01132136212187
            ],
            [
              105.82193948396657,
              21.011301696344262
            ],
            [
              105.82195883768176,
              21.01127976101604
            ],
            [
              105.82203525062188,
              21.011193154459725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Thái Hà",
          "maTaiSan": "04.O12.DODV.880",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011433738223428,
          "to_latitude": 21.011193154459725,
          "to_longitude": 105.82203525062188,
          "from_longitude": 105.82182298395715
        }
      },
      {
        "id": 880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82426525802478,
            21.01084175600207,
            105.82453796394931,
            21.011182812149315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82426525802478,
              21.010846014037636
            ],
            [
              105.82427216040026,
              21.01084438821315
            ],
            [
              105.82428600125884,
              21.01084175600207
            ],
            [
              105.82431479354459,
              21.010882282506483
            ],
            [
              105.82443970978366,
              21.01105049944392
            ],
            [
              105.82453796394931,
              21.011182812149315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.881",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010846014037636,
          "to_latitude": 21.011182812149315,
          "to_longitude": 105.82453796394931,
          "from_longitude": 105.82426525802478
        }
      },
      {
        "id": 881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82360544902222,
            21.010596520321858,
            105.82426346057464,
            21.01102122517679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82360544902222,
              21.010596520321858
            ],
            [
              105.82369778255521,
              21.01069448809717
            ],
            [
              105.82364384277611,
              21.010746899711958
            ],
            [
              105.82390315866101,
              21.01102122517679
            ],
            [
              105.82396408498639,
              21.011020694421433
            ],
            [
              105.82417413171568,
              21.01084759530554
            ],
            [
              105.82425743258725,
              21.01081969307532
            ],
            [
              105.82426346057464,
              21.0108199908438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.882",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010596520321858,
          "to_latitude": 21.0108199908438,
          "to_longitude": 105.82426346057464,
          "from_longitude": 105.82360544902222
        }
      },
      {
        "id": 882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82270919981053,
            21.013256485976438,
            105.82272752310924,
            21.013270798424752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82270919981053,
              21.013256485976438
            ],
            [
              105.8227171804469,
              21.013262719394334
            ],
            [
              105.82272409263274,
              21.013268120686966
            ],
            [
              105.82272752310924,
              21.013270798424752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.883",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013256485976438,
          "to_latitude": 21.013270798424752,
          "to_longitude": 105.82272752310924,
          "from_longitude": 105.82270919981053
        }
      },
      {
        "id": 883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82254280160097,
            21.0131892836496,
            105.82277205269396,
            21.01343440063302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82254280160097,
              21.01343440063302
            ],
            [
              105.82265742675477,
              21.01331184127683
            ],
            [
              105.82270919981053,
              21.013256485976438
            ],
            [
              105.82277205269396,
              21.0131892836496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.884",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01343440063302,
          "to_latitude": 21.0131892836496,
          "to_longitude": 105.82277205269396,
          "from_longitude": 105.82254280160097
        }
      },
      {
        "id": 884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82261866365704,
            21.0129583887161,
            105.8226340780248,
            21.01297179848353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8226340780248,
              21.0129583887161
            ],
            [
              105.82262705105417,
              21.012964504117026
            ],
            [
              105.82261866365704,
              21.01297179848353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49A_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.885",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0129583887161,
          "to_latitude": 21.01297179848353,
          "to_longitude": 105.82261866365704,
          "from_longitude": 105.8226340780248
        }
      },
      {
        "id": 885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82258629324734,
            21.01295442803507,
            105.82260083057041,
            21.012967621301108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82258629324734,
              21.012967621301108
            ],
            [
              105.82259340686703,
              21.01296116857928
            ],
            [
              105.82260083057041,
              21.01295442803507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.886",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012967621301108,
          "to_latitude": 21.01295442803507,
          "to_longitude": 105.82260083057041,
          "from_longitude": 105.82258629324734
        }
      },
      {
        "id": 886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8224431651339,
            21.012828671610638,
            105.82275785546514,
            21.013134174174148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82275785546514,
              21.013134174174148
            ],
            [
              105.82258629324734,
              21.012967621301108
            ],
            [
              105.8224431651339,
              21.012828671610638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.887",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013134174174148,
          "to_latitude": 21.012828671610638,
          "to_longitude": 105.8224431651339,
          "from_longitude": 105.82275785546514
        }
      },
      {
        "id": 887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82247998005573,
            21.012813284215735,
            105.822788176297,
            21.013103493967836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.822788176297,
              21.013103493967836
            ],
            [
              105.8226340780248,
              21.0129583887161
            ],
            [
              105.82247998005573,
              21.012813284215735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49A_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.888",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013103493967836,
          "to_latitude": 21.012813284215735,
          "to_longitude": 105.82247998005573,
          "from_longitude": 105.822788176297
        }
      },
      {
        "id": 888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82283114054317,
            21.0127965837731,
            105.8230106199173,
            21.012950950607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82283114054317,
              21.0127965837731
            ],
            [
              105.82299313261522,
              21.012937878791227
            ],
            [
              105.82299465195055,
              21.012939014927422
            ],
            [
              105.82300763728385,
              21.012948723225165
            ],
            [
              105.8230106199173,
              21.012950950607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7A_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.889",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0127965837731,
          "to_latitude": 21.012950950607,
          "to_longitude": 105.8230106199173,
          "from_longitude": 105.82283114054317
        }
      },
      {
        "id": 889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82309457349467,
            21.012833828623847,
            105.8231074007171,
            21.012843472646686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82309457349467,
              21.012833828623847
            ],
            [
              105.82310085380908,
              21.01283855011881
            ],
            [
              105.82310444215665,
              21.01284124966619
            ],
            [
              105.8231074007171,
              21.012843472646686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.890",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012833828623847,
          "to_latitude": 21.012843472646686,
          "to_longitude": 105.8231074007171,
          "from_longitude": 105.82309457349467
        }
      },
      {
        "id": 890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82285856800358,
            21.012632696674256,
            105.82328006117152,
            21.013093873801452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82328006117152,
              21.012632696674256
            ],
            [
              105.8231246254431,
              21.012802553531177
            ],
            [
              105.82309457349467,
              21.012833828623847
            ],
            [
              105.82309173759073,
              21.01283677963626
            ],
            [
              105.82300719800392,
              21.01292476003237
            ],
            [
              105.82299465195055,
              21.012939014927422
            ],
            [
              105.82297416184977,
              21.012962297159458
            ],
            [
              105.82285856800358,
              21.013093873801452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.891",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012632696674256,
          "to_latitude": 21.013093873801452,
          "to_longitude": 105.82285856800358,
          "from_longitude": 105.82328006117152
        }
      },
      {
        "id": 891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82308971157528,
            21.01240628324281,
            105.82309406306105,
            21.012410533201106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82308971157528,
              21.012410533201106
            ],
            [
              105.82309204712669,
              21.012408256610012
            ],
            [
              105.82309406306105,
              21.01240628324281
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Ngõ 117, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.892",
          "diaChiLapD": "Ngõ 117, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012410533201106,
          "to_latitude": 21.01240628324281,
          "to_longitude": 105.82309406306105,
          "from_longitude": 105.82308971157528
        }
      },
      {
        "id": 892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82286136649633,
            21.01222303122414,
            105.82327808829709,
            21.01256521711187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82327808829709,
              21.01256521711187
            ],
            [
              105.82308971157528,
              21.012410533201106
            ],
            [
              105.8228905188285,
              21.012246968462875
            ],
            [
              105.82286136649633,
              21.01222303122414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Ngõ 117, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.893",
          "diaChiLapD": "Ngõ 117, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01256521711187,
          "to_latitude": 21.01222303122414,
          "to_longitude": 105.82286136649633,
          "from_longitude": 105.82327808829709
        }
      },
      {
        "id": 893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8232729946298,
            21.012154120619975,
            105.82346349462337,
            21.012449433073915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346349462337,
              21.012449433073915
            ],
            [
              105.8234608491466,
              21.012447994391557
            ],
            [
              105.82345433666441,
              21.012436374283507
            ],
            [
              105.82340155154884,
              21.012394689298688
            ],
            [
              105.82341076189176,
              21.012383469101707
            ],
            [
              105.8232729946298,
              21.012268754836683
            ],
            [
              105.82340054187284,
              21.012154120619975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A54A_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.894",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012449433073915,
          "to_latitude": 21.012154120619975,
          "to_longitude": 105.82340054187284,
          "from_longitude": 105.82346349462337
        }
      },
      {
        "id": 894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82311887664298,
            21.011761445261442,
            105.82322901864053,
            21.011952155084515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82313049827891,
              21.011952155084515
            ],
            [
              105.82319442042694,
              21.011884487697085
            ],
            [
              105.82322901864053,
              21.011847862458353
            ],
            [
              105.82311887664298,
              21.011761445261442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.895",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011952155084515,
          "to_latitude": 21.011761445261442,
          "to_longitude": 105.82311887664298,
          "from_longitude": 105.82313049827891
        }
      },
      {
        "id": 895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82319442042694,
            21.011884487697085,
            105.82326064667079,
            21.01195922269215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82319442042694,
              21.011884487697085
            ],
            [
              105.82326064667079,
              21.011947830329866
            ],
            [
              105.8232564804861,
              21.011956571142935
            ],
            [
              105.82325855332516,
              21.011958132728644
            ],
            [
              105.82325990791142,
              21.01195922269215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.896",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011884487697085,
          "to_latitude": 21.01195922269215,
          "to_longitude": 105.82325990791142,
          "from_longitude": 105.82319442042694
        }
      },
      {
        "id": 896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8229134892289,
            21.012090161047222,
            105.82332125107669,
            21.01238933515829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82332125107669,
              21.01238933515829
            ],
            [
              105.82322739904724,
              21.01230876800664
            ],
            [
              105.82322588463565,
              21.012307476493458
            ],
            [
              105.82302918735432,
              21.012139750418026
            ],
            [
              105.82298080634025,
              21.01210862800534
            ],
            [
              105.82295667939704,
              21.012125783171967
            ],
            [
              105.8229134892289,
              21.012090161047222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A54_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.897",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01238933515829,
          "to_latitude": 21.012090161047222,
          "to_longitude": 105.8229134892289,
          "from_longitude": 105.82332125107669
        }
      },
      {
        "id": 897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334402319673,
            21.01148907176715,
            105.82353801938281,
            21.01171208715979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82353801938281,
              21.01171208715979
            ],
            [
              105.82353172868753,
              21.01170424146787
            ],
            [
              105.82352916805316,
              21.011702660568847
            ],
            [
              105.82350175305659,
              21.011685752973612
            ],
            [
              105.82341001536113,
              21.01159426288633
            ],
            [
              105.8233857636224,
              21.011570077116104
            ],
            [
              105.82339993044927,
              21.01155741757895
            ],
            [
              105.82334402319673,
              21.01148907176715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A110_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.898",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01171208715979,
          "to_latitude": 21.01148907176715,
          "to_longitude": 105.82334402319673,
          "from_longitude": 105.82353801938281
        }
      },
      {
        "id": 898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82351537090247,
            21.01171327251863,
            105.82352425928443,
            21.011722965382848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82351537090247,
              21.01171327251863
            ],
            [
              105.82351966462484,
              21.01171795664659
            ],
            [
              105.82352405316776,
              21.011722741476945
            ],
            [
              105.82352425928443,
              21.011722965382848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.899",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01171327251863,
          "to_latitude": 21.011722965382848,
          "to_longitude": 105.82352425928443,
          "from_longitude": 105.82351537090247
        }
      },
      {
        "id": 899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82332287577499,
            21.011547529435653,
            105.82370459753899,
            21.01188454270548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82370459753899,
              21.011547529435653
            ],
            [
              105.82360675219245,
              21.01164256911236
            ],
            [
              105.82360468069871,
              21.01164458159302
            ],
            [
              105.82352916805316,
              21.011702660568847
            ],
            [
              105.82352326086995,
              21.011707203486733
            ],
            [
              105.82351537090247,
              21.01171327251863
            ],
            [
              105.82351210743869,
              21.011715782122494
            ],
            [
              105.82350834075326,
              21.011719142291852
            ],
            [
              105.82332287577499,
              21.01188454270548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.900",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011547529435653,
          "to_latitude": 21.01188454270548,
          "to_longitude": 105.82332287577499,
          "from_longitude": 105.82370459753899
        }
      },
      {
        "id": 900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8233222162168,
            21.011590403666027,
            105.82370966462236,
            21.01191159302609
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8233222162168,
              21.01191159302609
            ],
            [
              105.82337079843494,
              21.01186843118348
            ],
            [
              105.82337647873803,
              21.011863384464714
            ],
            [
              105.82349955955118,
              21.011754034896313
            ],
            [
              105.82349962085424,
              21.011753987631835
            ],
            [
              105.82352239683154,
              21.01173624912221
            ],
            [
              105.82370966462236,
              21.011590403666027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.901",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01191159302609,
          "to_latitude": 21.011590403666027,
          "to_longitude": 105.82370966462236,
          "from_longitude": 105.8233222162168
        }
      },
      {
        "id": 901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82324350639101,
            21.011944366026448,
            105.82353356355306,
            21.012238616815136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82353356355306,
              21.012238616815136
            ],
            [
              105.82324350639101,
              21.012000258871076
            ],
            [
              105.82324355802578,
              21.012000202621188
            ],
            [
              105.82328584048966,
              21.011953844955038
            ],
            [
              105.82327502491698,
              21.011944766565154
            ],
            [
              105.8232745494568,
              21.011944366026448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.902",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012238616815136,
          "to_latitude": 21.011944366026448,
          "to_longitude": 105.8232745494568,
          "from_longitude": 105.82353356355306
        }
      },
      {
        "id": 902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82336061106913,
            21.01186094395448,
            105.82361471337288,
            21.01211397163395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82361471337288,
              21.01211397163395
            ],
            [
              105.823405580169,
              21.011893994902305
            ],
            [
              105.82337079843494,
              21.01186843118348
            ],
            [
              105.82336145809084,
              21.011861567598498
            ],
            [
              105.82336061106913,
              21.01186094395448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.903",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01211397163395,
          "to_latitude": 21.01186094395448,
          "to_longitude": 105.82336061106913,
          "from_longitude": 105.82361471337288
        }
      },
      {
        "id": 903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82362858707786,
            21.0122425745565,
            105.82363910351572,
            21.012252525081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82362858707786,
              21.0122425745565
            ],
            [
              105.82363655370396,
              21.012250114066173
            ],
            [
              105.82363910351572,
              21.012252525081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.904",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0122425745565,
          "to_latitude": 21.012252525081,
          "to_longitude": 105.82363910351572,
          "from_longitude": 105.82362858707786
        }
      },
      {
        "id": 904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334678887783,
            21.01196564609601,
            105.82388363065262,
            21.012555986998397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82388363065262,
              21.01196564609601
            ],
            [
              105.8237793357326,
              21.01207491492281
            ],
            [
              105.82377399699733,
              21.012080852389143
            ],
            [
              105.82362858707786,
              21.0122425745565
            ],
            [
              105.82345433666441,
              21.012436374283507
            ],
            [
              105.82344733718973,
              21.01244415874713
            ],
            [
              105.82334678887783,
              21.012555986998397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.905",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01196564609601,
          "to_latitude": 21.012555986998397,
          "to_longitude": 105.82334678887783,
          "from_longitude": 105.82388363065262
        }
      },
      {
        "id": 905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82350103517606,
            21.011797328450925,
            105.82379185032292,
            21.012092269449692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82350103517606,
              21.011797328450925
            ],
            [
              105.82372983835849,
              21.012052652463826
            ],
            [
              105.82375501488578,
              21.01206514685119
            ],
            [
              105.82377399699733,
              21.012080852389143
            ],
            [
              105.82378875556174,
              21.012090291914657
            ],
            [
              105.82379185032292,
              21.012092269449692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.906",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011797328450925,
          "to_latitude": 21.012092269449692,
          "to_longitude": 105.82379185032292,
          "from_longitude": 105.82350103517606
        }
      },
      {
        "id": 906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82362444118529,
            21.011692398517045,
            105.82391809034296,
            21.011952517933945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82391809034296,
              21.011952517933945
            ],
            [
              105.82391419500095,
              21.011949440534572
            ],
            [
              105.8238450766313,
              21.011894793983096
            ],
            [
              105.82382519702756,
              21.01188924055079
            ],
            [
              105.82362444118529,
              21.011692398517045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Ngõ 91, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.907",
          "diaChiLapD": "Ngõ 91, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011952517933945,
          "to_latitude": 21.011692398517045,
          "to_longitude": 105.82362444118529,
          "from_longitude": 105.82391809034296
        }
      },
      {
        "id": 907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82435189000402,
            21.011458508712447,
            105.82436494950912,
            21.011469295002772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82435189000402,
              21.011458508712447
            ],
            [
              105.82435794192494,
              21.01146350765615
            ],
            [
              105.82436213372885,
              21.011466971093636
            ],
            [
              105.82436494950912,
              21.011469295002772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.908",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011458508712447,
          "to_latitude": 21.011469295002772,
          "to_longitude": 105.82436494950912,
          "from_longitude": 105.82435189000402
        }
      },
      {
        "id": 908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8242234639501,
            21.011318410601497,
            105.8244813533218,
            21.011597483510233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8244813533218,
              21.011318410601497
            ],
            [
              105.82436869089186,
              21.011440327555853
            ],
            [
              105.82435189000402,
              21.011458508712447
            ],
            [
              105.8242234639501,
              21.011597483510233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.909",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011318410601497,
          "to_latitude": 21.011597483510233,
          "to_longitude": 105.8242234639501,
          "from_longitude": 105.8244813533218
        }
      },
      {
        "id": 909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82477793138207,
            21.010979448315855,
            105.82479584233154,
            21.010993659578233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82477793138207,
              21.010979448315855
            ],
            [
              105.82478592703988,
              21.01098579717953
            ],
            [
              105.82478667095698,
              21.010986387896857
            ],
            [
              105.82479273432897,
              21.010991196189135
            ],
            [
              105.82479584233154,
              21.010993659578233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.910",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010979448315855,
          "to_latitude": 21.010993659578233,
          "to_longitude": 105.82479584233154,
          "from_longitude": 105.82477793138207
        }
      },
      {
        "id": 910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82472009366316,
            21.010786391549342,
            105.82495721328046,
            21.011041729746943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82495721328046,
              21.010786391549342
            ],
            [
              105.82481699026896,
              21.010937388108
            ],
            [
              105.82477793138207,
              21.010979448315855
            ],
            [
              105.82472009366316,
              21.011041729746943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.911",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010786391549342,
          "to_latitude": 21.011041729746943,
          "to_longitude": 105.82472009366316,
          "from_longitude": 105.82495721328046
        }
      },
      {
        "id": 911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82489518699697,
            21.010565823889245,
            105.82494075428733,
            21.01058613978065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82489518699697,
              21.01058613978065
            ],
            [
              105.8249076496821,
              21.010580583230016
            ],
            [
              105.82492228287556,
              21.010574058735855
            ],
            [
              105.82494075428733,
              21.010565823889245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A109_Tây Sơn",
          "maTaiSan": "04.O12.DODV.912",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01058613978065,
          "to_latitude": 21.010565823889245,
          "to_longitude": 105.82494075428733,
          "from_longitude": 105.82489518699697
        }
      },
      {
        "id": 912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82480565022752,
            21.010415600200364,
            105.82497699283262,
            21.01074193096459
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82480565022752,
              21.010415600200364
            ],
            [
              105.82489518699697,
              21.01058613978065
            ],
            [
              105.82497699283262,
              21.01074193096459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A109_Tây Sơn",
          "maTaiSan": "04.O12.DODV.913",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010415600200364,
          "to_latitude": 21.01074193096459,
          "to_longitude": 105.82497699283262,
          "from_longitude": 105.82480565022752
        }
      },
      {
        "id": 913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82295948256609,
            21.011130478516044,
            105.82373891930911,
            21.011534173462778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82295948256609,
              21.011130478516044
            ],
            [
              105.82326653384547,
              21.011438653734047
            ],
            [
              105.82347531589993,
              21.01125955962606
            ],
            [
              105.82373277492437,
              21.011528448749832
            ],
            [
              105.82373891930911,
              21.011534173462778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59B_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.914",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 117.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011130478516044,
          "to_latitude": 21.011534173462778,
          "to_longitude": 105.82373891930911,
          "from_longitude": 105.82295948256609
        }
      },
      {
        "id": 914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82337646251071,
            21.010806391620996,
            105.82375946594028,
            21.011514060293003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82375946594028,
              21.011514060293003
            ],
            [
              105.82375414415716,
              21.011508365921035
            ],
            [
              105.82340248346941,
              21.011151170782945
            ],
            [
              105.82349795677437,
              21.01105616799379
            ],
            [
              105.82337646251071,
              21.010917470202557
            ],
            [
              105.82350985916254,
              21.010806391620996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.915",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 107.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011514060293003,
          "to_latitude": 21.010806391620996,
          "to_longitude": 105.82350985916254,
          "from_longitude": 105.82375946594028
        }
      },
      {
        "id": 915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418676119418,
            21.011189017642362,
            105.8243406651048,
            21.011310494786446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8243406651048,
              21.011189017642362
            ],
            [
              105.82418676119418,
              21.011310494786446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.916",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011189017642362,
          "to_latitude": 21.011310494786446,
          "to_longitude": 105.82418676119418,
          "from_longitude": 105.8243406651048
        }
      },
      {
        "id": 916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418285765446,
            21.011074964785315,
            105.82418943344246,
            21.011080950231797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82418285765446,
              21.011074964785315
            ],
            [
              105.8241836532839,
              21.01107568893243
            ],
            [
              105.82418848991043,
              21.011080093123542
            ],
            [
              105.82418943344246,
              21.011080950231797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A60_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.917",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011074964785315,
          "to_latitude": 21.011080950231797,
          "to_longitude": 105.82418943344246,
          "from_longitude": 105.82418285765446
        }
      },
      {
        "id": 917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82392649402007,
            21.01086047208506,
            105.82427176366377,
            21.011200898194353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392649402007,
              21.01106789120963
            ],
            [
              105.82405266427612,
              21.011200898194353
            ],
            [
              105.82418285765446,
              21.011074964785315
            ],
            [
              105.82421164851071,
              21.011047115235595
            ],
            [
              105.82423512515585,
              21.01102440639632
            ],
            [
              105.82425837496515,
              21.01098632302252
            ],
            [
              105.82427176366377,
              21.010944983410493
            ],
            [
              105.82420855218385,
              21.01086047208506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A60_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.918",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01106789120963,
          "to_latitude": 21.01086047208506,
          "to_longitude": 105.82420855218385,
          "from_longitude": 105.82392649402007
        }
      },
      {
        "id": 918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82414264009365,
            21.011122220406197,
            105.8241608454926,
            21.011138909667917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8241608454926,
              21.011138909667917
            ],
            [
              105.82414878493701,
              21.011127853876502
            ],
            [
              105.82414363298439,
              21.011123132372482
            ],
            [
              105.82414264009365,
              21.011122220406197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.919",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011138909667917,
          "to_latitude": 21.011122220406197,
          "to_longitude": 105.82414264009365,
          "from_longitude": 105.8241608454926
        }
      },
      {
        "id": 919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82410589582041,
            21.01098767672857,
            105.8243014941896,
            21.01119799393836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82410589582041,
              21.01119799393836
            ],
            [
              105.8241608454926,
              21.011138909667917
            ],
            [
              105.82420369555417,
              21.01109283535762
            ],
            [
              105.8243014941896,
              21.01098767672857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.920",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01119799393836,
          "to_latitude": 21.01098767672857,
          "to_longitude": 105.8243014941896,
          "from_longitude": 105.82410589582041
        }
      },
      {
        "id": 920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82374948400759,
            21.011524015209393,
            105.82394887946155,
            21.011736589770397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82394887946155,
              21.011736589770397
            ],
            [
              105.82375420283671,
              21.01152956437126
            ],
            [
              105.82374948400759,
              21.011524015209393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.921",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011736589770397,
          "to_latitude": 21.011524015209393,
          "to_longitude": 105.82374948400759,
          "from_longitude": 105.82394887946155
        }
      },
      {
        "id": 921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82408028713515,
            21.011182102848796,
            105.8243504145333,
            21.011417997773542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82408028713515,
              21.011182102848796
            ],
            [
              105.82408187519775,
              21.01118401993415
            ],
            [
              105.82418676119418,
              21.011310494786446
            ],
            [
              105.82427392469133,
              21.011400826993583
            ],
            [
              105.82429173359172,
              21.011417997773542
            ],
            [
              105.8243504145333,
              21.01136661885983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.922",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011182102848796,
          "to_latitude": 21.01136661885983,
          "to_longitude": 105.8243504145333,
          "from_longitude": 105.82408028713515
        }
      },
      {
        "id": 922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367088958215,
            21.010459652075888,
            105.82426578424167,
            21.01080592899111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367088958215,
              21.010519452760583
            ],
            [
              105.82370594461047,
              21.010555248667934
            ],
            [
              105.82378697731035,
              21.010546629802256
            ],
            [
              105.82389492957526,
              21.010459652075888
            ],
            [
              105.82391207239469,
              21.010479398517163
            ],
            [
              105.8241789410298,
              21.010786792973125
            ],
            [
              105.82425913807701,
              21.010804465441623
            ],
            [
              105.82426578424167,
              21.01080592899111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A108_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.923",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010519452760583,
          "to_latitude": 21.01080592899111,
          "to_longitude": 105.82426578424167,
          "from_longitude": 105.82367088958215
        }
      },
      {
        "id": 923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82437908669466,
            21.010345725632053,
            105.82475553082737,
            21.010593286192872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82437908669466,
              21.010593286192872
            ],
            [
              105.8245493904821,
              21.0104764081377
            ],
            [
              105.82454909547957,
              21.01047593808277
            ],
            [
              105.82454888200854,
              21.010475426084337
            ],
            [
              105.8245487636246,
              21.010474888334773
            ],
            [
              105.82454874521159,
              21.010474338358865
            ],
            [
              105.82454882686883,
              21.010473794220633
            ],
            [
              105.82454900483319,
              21.010473271293183
            ],
            [
              105.82454927533654,
              21.010472784046378
            ],
            [
              105.82454962979646,
              21.01047234607024
            ],
            [
              105.8245500586736,
              21.010471971862604
            ],
            [
              105.82455030122006,
              21.010471823461327
            ],
            [
              105.82455054856106,
              21.010471672327153
            ],
            [
              105.82455108602737,
              21.01047145385156
            ],
            [
              105.82455165477022,
              21.01047132554709
            ],
            [
              105.82455223844262,
              21.010471288396186
            ],
            [
              105.82455282071244,
              21.010471346090917
            ],
            [
              105.82455338424599,
              21.010471495102323
            ],
            [
              105.82455391171462,
              21.010471732804717
            ],
            [
              105.82455438960226,
              21.010472050231325
            ],
            [
              105.8245548024742,
              21.0104724393279
            ],
            [
              105.82455500197158,
              21.010472684038895
            ],
            [
              105.82456192868567,
              21.010468664534137
            ],
            [
              105.82456885539943,
              21.010464645029113
            ],
            [
              105.82456864256257,
              21.010464073414656
            ],
            [
              105.8245685344366,
              21.01046347690567
            ],
            [
              105.82456853399646,
              21.01046287174583
            ],
            [
              105.82456864036963,
              21.01046227419745
            ],
            [
              105.82456885076978,
              21.01046170233854
            ],
            [
              105.82456916047185,
              21.01046117154688
            ],
            [
              105.82456955897463,
              21.01046069632497
            ],
            [
              105.82457003673376,
              21.010460290267464
            ],
            [
              105.82457042118669,
              21.010460058984886
            ],
            [
              105.82457058033752,
              21.010459963374867
            ],
            [
              105.82457117349848,
              21.01045972746802
            ],
            [
              105.8245718008464,
              21.010459586234322
            ],
            [
              105.8245724441404,
              21.010459546084768
            ],
            [
              105.82457308509974,
              21.010459606204694
            ],
            [
              105.8245737064106,
              21.01045976667802
            ],
            [
              105.82457429168623,
              21.01046002126155
            ],
            [
              105.82457482261628,
              21.010460363721407
            ],
            [
              105.82457528567974,
              21.01046078418765
            ],
            [
              105.82457566831263,
              21.010461271882438
            ],
            [
              105.82475553082737,
              21.010345725632053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A103A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.924",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010593286192872,
          "to_latitude": 21.010345725632053,
          "to_longitude": 105.82475553082737,
          "from_longitude": 105.82437908669466
        }
      },
      {
        "id": 924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82454954721322,
            21.010520329492326,
            105.82455352840189,
            21.01052538001296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82455352840189,
              21.01052538001296
            ],
            [
              105.82455043680713,
              21.01052169898966
            ],
            [
              105.82454954721322,
              21.010520329492326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A105_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.925",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01052538001296,
          "to_latitude": 21.010520329492326,
          "to_longitude": 105.82454954721322,
          "from_longitude": 105.82455352840189
        }
      },
      {
        "id": 925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82459119371134,
            21.01049667088217,
            105.82464748753232,
            21.010584390434218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82464748753232,
              21.010584390434218
            ],
            [
              105.82459410156113,
              21.010500873053797
            ],
            [
              105.82459214873201,
              21.010497868449143
            ],
            [
              105.82459119371134,
              21.01049667088217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A102_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.926",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010584390434218,
          "to_latitude": 21.01049667088217,
          "to_longitude": 105.82459119371134,
          "from_longitude": 105.82464748753232
        }
      },
      {
        "id": 926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82452775998044,
            21.00992659682444,
            105.82476000599975,
            21.010333373089214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82476000599975,
              21.010333373089214
            ],
            [
              105.82465561891853,
              21.01014536924348
            ],
            [
              105.82465338344777,
              21.010141342653014
            ],
            [
              105.82465306964376,
              21.010140776947328
            ],
            [
              105.8245521063156,
              21.0099538357904
            ],
            [
              105.82454393647724,
              21.009938709295074
            ],
            [
              105.82452775998044,
              21.00992659682444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A101_Tây Sơn",
          "maTaiSan": "04.O12.DODV.927",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010333373089214,
          "to_latitude": 21.00992659682444,
          "to_longitude": 105.82452775998044,
          "from_longitude": 105.82476000599975
        }
      },
      {
        "id": 927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82465561891853,
            21.010124657580352,
            105.8246986233502,
            21.01014536924348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82465561891853,
              21.01014536924348
            ],
            [
              105.8246638105138,
              21.010141349024405
            ],
            [
              105.82466386999141,
              21.010141319832893
            ],
            [
              105.82467196903377,
              21.01013743644923
            ],
            [
              105.82468012852006,
              21.010133524772215
            ],
            [
              105.8246986233502,
              21.010124657580352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A101_Tây Sơn",
          "maTaiSan": "04.O12.DODV.928",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01014536924348,
          "to_latitude": 21.010124657580352,
          "to_longitude": 105.8246986233502,
          "from_longitude": 105.82465561891853
        }
      },
      {
        "id": 928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82232730469163,
            21.011759083721145,
            105.82234054130315,
            21.011771841961853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82232730469163,
              21.011759083721145
            ],
            [
              105.82232784914095,
              21.011759611284205
            ],
            [
              105.82233243976164,
              21.011764065491814
            ],
            [
              105.82234048038234,
              21.011771783546518
            ],
            [
              105.82234054130315,
              21.011771841961853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A87_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.929",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011759083721145,
          "to_latitude": 21.011771841961853,
          "to_longitude": 105.82234054130315,
          "from_longitude": 105.82232730469163
        }
      },
      {
        "id": 929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8228145436233,
            21.01143806347214,
            105.82307124197926,
            21.011672426025026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8228145436233,
              21.01143806347214
            ],
            [
              105.82281571704003,
              21.011439094702553
            ],
            [
              105.82284009463451,
              21.011460466396812
            ],
            [
              105.82285147348281,
              21.01145054090595
            ],
            [
              105.82307124197926,
              21.011672426025026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A86_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.930",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01143806347214,
          "to_latitude": 21.011672426025026,
          "to_longitude": 105.82307124197926,
          "from_longitude": 105.8228145436233
        }
      },
      {
        "id": 930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82270000265338,
            21.011537108800354,
            105.82292090001282,
            21.01172631442036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82292090001282,
              21.01172631442036
            ],
            [
              105.82270028776718,
              21.01153735490605
            ],
            [
              105.82270000265338,
              21.011537108800354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A85B_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.931",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01172631442036,
          "to_latitude": 21.011537108800354,
          "to_longitude": 105.82270000265338,
          "from_longitude": 105.82292090001282
        }
      },
      {
        "id": 931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82241905549493,
            21.01075704180462,
            105.82243843560734,
            21.010771182092746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243843560734,
              21.010771182092746
            ],
            [
              105.82242553678749,
              21.01076177229284
            ],
            [
              105.82241905549493,
              21.01075704180462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Thái Hà",
          "maTaiSan": "04.O12.DODV.932",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010771182092746,
          "to_latitude": 21.01075704180462,
          "to_longitude": 105.82241905549493,
          "from_longitude": 105.82243843560734
        }
      },
      {
        "id": 932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82247226653725,
            21.01130551881529,
            105.82269960034183,
            21.011503692020636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82247226653725,
              21.011503692020636
            ],
            [
              105.82269856928951,
              21.011306417091912
            ],
            [
              105.82269960034183,
              21.01130551881529
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Ngõ 70, Thái Hà",
          "maTaiSan": "04.O12.DODV.933",
          "diaChiLapD": "Ngõ 70, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011503692020636,
          "to_latitude": 21.01130551881529,
          "to_longitude": 105.82269960034183,
          "from_longitude": 105.82247226653725
        }
      },
      {
        "id": 933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82256788874783,
            21.011169800513887,
            105.82257523623096,
            21.01117604120285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82257523623096,
              21.011169800513887
            ],
            [
              105.82257252567872,
              21.01117210149137
            ],
            [
              105.82256876960133,
              21.011175292685568
            ],
            [
              105.82256788874783,
              21.01117604120285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75_Ngõ 70, Thái Hà",
          "maTaiSan": "04.O12.DODV.934",
          "diaChiLapD": "Ngõ 70, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011169800513887,
          "to_latitude": 21.01117604120285,
          "to_longitude": 105.82256788874783,
          "from_longitude": 105.82257523623096
        }
      },
      {
        "id": 934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82295949813482,
            21.010995657353828,
            105.82297524179786,
            21.01101215903956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82295949813482,
              21.010995657353828
            ],
            [
              105.82295969937086,
              21.010995868638922
            ],
            [
              105.82296777014726,
              21.011004351552355
            ],
            [
              105.82297465778807,
              21.011011609089163
            ],
            [
              105.82297524179786,
              21.01101215903956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.935",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010995657353828,
          "to_latitude": 21.01101215903956,
          "to_longitude": 105.82297524179786,
          "from_longitude": 105.82295949813482
        }
      },
      {
        "id": 935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82311076322321,
            21.010505564525058,
            105.82312401991209,
            21.010515788148826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82311076322321,
              21.010515788148826
            ],
            [
              105.82311325937872,
              21.010513861233036
            ],
            [
              105.82311960897555,
              21.01050896212468
            ],
            [
              105.82311989920632,
              21.0105087385269
            ],
            [
              105.82312401991209,
              21.010505564525058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A71_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.936",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010515788148826,
          "to_latitude": 21.010505564525058,
          "to_longitude": 105.82312401991209,
          "from_longitude": 105.82311076322321
        }
      },
      {
        "id": 936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82312977769956,
            21.01064254523275,
            105.82322577643389,
            21.010740831617746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82322577643389,
              21.01064254523275
            ],
            [
              105.82313523764398,
              21.0107263005018
            ],
            [
              105.82312977769956,
              21.010731356081727
            ],
            [
              105.82315348028935,
              21.01073981395015
            ],
            [
              105.82315686307581,
              21.01074082184539
            ],
            [
              105.82315689679618,
              21.010740831617746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A72_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.937",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01064254523275,
          "to_latitude": 21.010740831617746,
          "to_longitude": 105.82315689679618,
          "from_longitude": 105.82322577643389
        }
      },
      {
        "id": 937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82302526533944,
            21.01042621227965,
            105.82321900831636,
            21.010629195764288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82302526533944,
              21.01042621227965
            ],
            [
              105.82311076322321,
              21.010515788148826
            ],
            [
              105.82312262533372,
              21.01052821607677
            ],
            [
              105.82321900831636,
              21.010629195764288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A71_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.938",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01042621227965,
          "to_latitude": 21.010629195764288,
          "to_longitude": 105.82321900831636,
          "from_longitude": 105.82302526533944
        }
      },
      {
        "id": 938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82282841594495,
            21.01084901499638,
            105.82313593013839,
            21.011137379851867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82282841594495,
              21.011137379851867
            ],
            [
              105.82286708557844,
              21.011075099607805
            ],
            [
              105.82287047336534,
              21.01106965212061
            ],
            [
              105.82291382807513,
              21.01103361414925
            ],
            [
              105.82295949813482,
              21.010995657353828
            ],
            [
              105.82299031667358,
              21.010970043620343
            ],
            [
              105.82307757560974,
              21.010897515143217
            ],
            [
              105.82313593013839,
              21.01084901499638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.939",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011137379851867,
          "to_latitude": 21.01084901499638,
          "to_longitude": 105.82313593013839,
          "from_longitude": 105.82282841594495
        }
      },
      {
        "id": 939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82314364251181,
            21.010094541925532,
            105.8231696804507,
            21.010123328269373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8231696804507,
              21.010123328269373
            ],
            [
              105.82315811749501,
              21.0101105448312
            ],
            [
              105.82315226634896,
              21.01010407802934
            ],
            [
              105.82314641711726,
              21.010097609411552
            ],
            [
              105.82314364251181,
              21.010094541925532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thái Hà",
          "maTaiSan": "04.O12.DODV.940",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010123328269373,
          "to_latitude": 21.010094541925532,
          "to_longitude": 105.82314364251181,
          "from_longitude": 105.8231696804507
        }
      },
      {
        "id": 940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82294607606364,
            21.01000253378416,
            105.82333183801683,
            21.010287417911794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82294607606364,
              21.010287417911794
            ],
            [
              105.8230124323696,
              21.010231531358645
            ],
            [
              105.82301696795504,
              21.01022771135189
            ],
            [
              105.82301724083698,
              21.010227481515624
            ],
            [
              105.82313681840965,
              21.01013830919215
            ],
            [
              105.8231696804507,
              21.010123328269373
            ],
            [
              105.82317451442913,
              21.01012112449485
            ],
            [
              105.823234885707,
              21.010075422696154
            ],
            [
              105.82323763590878,
              21.010073341001043
            ],
            [
              105.82324178767283,
              21.010070220136726
            ],
            [
              105.82333183801683,
              21.01000253378416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thái Hà",
          "maTaiSan": "04.O12.DODV.941",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010287417911794,
          "to_latitude": 21.01000253378416,
          "to_longitude": 105.82333183801683,
          "from_longitude": 105.82294607606364
        }
      },
      {
        "id": 941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334895180928,
            21.009958116981743,
            105.82366265279191,
            21.01023647729221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82334895180928,
              21.009958116981743
            ],
            [
              105.82335075663221,
              21.00996062463976
            ],
            [
              105.82350990509593,
              21.01018158321624
            ],
            [
              105.82355859653013,
              21.010146511004688
            ],
            [
              105.82366265279191,
              21.01023647729221
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Ngõ 2, Thái Hà",
          "maTaiSan": "04.O12.DODV.942",
          "diaChiLapD": "Ngõ 2, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009958116981743,
          "to_latitude": 21.01023647729221,
          "to_longitude": 105.82366265279191,
          "from_longitude": 105.82334895180928
        }
      },
      {
        "id": 942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82271595466518,
            21.010444737479617,
            105.82273583569099,
            21.010464450030746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82273583569099,
              21.010464450030746
            ],
            [
              105.8227324922054,
              21.010461135094733
            ],
            [
              105.82272464367722,
              21.010453354707792
            ],
            [
              105.82272024984032,
              21.010448996203174
            ],
            [
              105.82271679803551,
              21.0104455743065
            ],
            [
              105.82271595466518,
              21.010444737479617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A69_Thái Hà",
          "maTaiSan": "04.O12.DODV.943",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010464450030746,
          "to_latitude": 21.010444737479617,
          "to_longitude": 105.82271595466518,
          "from_longitude": 105.82273583569099
        }
      },
      {
        "id": 943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82256091225236,
            21.010330162098423,
            105.82288278824747,
            21.010624298898815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82256091225236,
              21.010624298898815
            ],
            [
              105.82272048843748,
              21.010478474576963
            ],
            [
              105.82273583569099,
              21.010464450030746
            ],
            [
              105.82288278824747,
              21.010330162098423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A69_Thái Hà",
          "maTaiSan": "04.O12.DODV.944",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010624298898815,
          "to_latitude": 21.010330162098423,
          "to_longitude": 105.82288278824747,
          "from_longitude": 105.82256091225236
        }
      },
      {
        "id": 944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82269442608315,
            21.010411672025256,
            105.8230340639641,
            21.010731170889247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82269442608315,
              21.010731170889247
            ],
            [
              105.82302742069717,
              21.01041792106889
            ],
            [
              105.8230340639641,
              21.010411672025256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.945",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010731170889247,
          "to_latitude": 21.010411672025256,
          "to_longitude": 105.8230340639641,
          "from_longitude": 105.82269442608315
        }
      },
      {
        "id": 945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82252869269745,
            21.01062578871119,
            105.82269138013314,
            21.010945965757305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82257686107147,
              21.010945965757305
            ],
            [
              105.82258631959199,
              21.010907205912634
            ],
            [
              105.82262882895607,
              21.010862072086017
            ],
            [
              105.82262159064598,
              21.010841555049183
            ],
            [
              105.82269138013314,
              21.010766330182793
            ],
            [
              105.82267381225445,
              21.010742545535695
            ],
            [
              105.82261875967427,
              21.010696454458753
            ],
            [
              105.82258809471614,
              21.010670781243377
            ],
            [
              105.82254051894832,
              21.010634665364844
            ],
            [
              105.82252869269745,
              21.01062578871119
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Ngõ 48, Thái Hà",
          "maTaiSan": "04.O12.DODV.946",
          "diaChiLapD": "Ngõ 48, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010945965757305,
          "to_latitude": 21.01062578871119,
          "to_longitude": 105.82252869269745,
          "from_longitude": 105.82257686107147
        }
      },
      {
        "id": 946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8232131009786,
            21.010046402624912,
            105.82343617610111,
            21.01046374487098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82331170450375,
              21.01046374487098
            ],
            [
              105.82334532142066,
              21.010437145664504
            ],
            [
              105.82343617610111,
              21.010365256260396
            ],
            [
              105.82338488909375,
              21.010283897871545
            ],
            [
              105.8234066227132,
              21.010265733584742
            ],
            [
              105.823234885707,
              21.010075422696154
            ],
            [
              105.82322369878025,
              21.0100605220854
            ],
            [
              105.8232131009786,
              21.010046402624912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Ngõ 6, Thái Hà",
          "maTaiSan": "04.O12.DODV.947",
          "diaChiLapD": "Ngõ 6, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01046374487098,
          "to_latitude": 21.010046402624912,
          "to_longitude": 105.8232131009786,
          "from_longitude": 105.82331170450375
        }
      },
      {
        "id": 947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334532142066,
            21.010437145664504,
            105.82341694491662,
            21.010562635809436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82335664682897,
              21.010562635809436
            ],
            [
              105.82341694491662,
              21.010512340486542
            ],
            [
              105.82334532142066,
              21.010437145664504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Ngõ 6, Thái Hà",
          "maTaiSan": "04.O12.DODV.948",
          "diaChiLapD": "Ngõ 6, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010562635809436,
          "to_latitude": 21.010437145664504,
          "to_longitude": 105.82334532142066,
          "from_longitude": 105.82335664682897
        }
      },
      {
        "id": 948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82312766406078,
            21.010306118874844,
            105.82323491730104,
            21.010425314647513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82323491730104,
              21.010425314647513
            ],
            [
              105.82312766406078,
              21.010306118874844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Thái Hà",
          "maTaiSan": "04.O12.DODV.949",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010425314647513,
          "to_latitude": 21.010306118874844,
          "to_longitude": 105.82312766406078,
          "from_longitude": 105.82323491730104
        }
      },
      {
        "id": 949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299573906936,
            21.010201472812724,
            105.82332481603135,
            21.010317916702792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82332481603135,
              21.010201472812724
            ],
            [
              105.8232222739869,
              21.01027050850141
            ],
            [
              105.82321819048006,
              21.010272045678068
            ],
            [
              105.82312766406078,
              21.010306118874844
            ],
            [
              105.82309631971334,
              21.010317916702792
            ],
            [
              105.8230124323696,
              21.010231531358645
            ],
            [
              105.82300079932499,
              21.010218770829443
            ],
            [
              105.82299573906936,
              21.010213217875833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Thái Hà",
          "maTaiSan": "04.O12.DODV.950",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010201472812724,
          "to_latitude": 21.010213217875833,
          "to_longitude": 105.82299573906936,
          "from_longitude": 105.82332481603135
        }
      },
      {
        "id": 950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82426504320689,
            21.010138832719583,
            105.82457514776486,
            21.010506924391773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82439275735989,
              21.010138832719583
            ],
            [
              105.82426504320689,
              21.010221415632575
            ],
            [
              105.82435787492427,
              21.010343145437325
            ],
            [
              105.82446769400592,
              21.010350037908548
            ],
            [
              105.82455030122006,
              21.010471823461327
            ],
            [
              105.8245743974372,
              21.010505866736203
            ],
            [
              105.82457514776486,
              21.010506924391773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A104_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.951",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010138832719583,
          "to_latitude": 21.010506924391773,
          "to_longitude": 105.82457514776486,
          "from_longitude": 105.82439275735989
        }
      },
      {
        "id": 951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82441909615443,
            21.010421196909395,
            105.82472601313634,
            21.010606578435546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82441909615443,
              21.010606578435546
            ],
            [
              105.8245414187727,
              21.010532695216227
            ],
            [
              105.82455352840189,
              21.01052538001296
            ],
            [
              105.82459410156113,
              21.010500873053797
            ],
            [
              105.82459526353237,
              21.010500171035112
            ],
            [
              105.82472601313634,
              21.010421196909395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A105_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.952",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010606578435546,
          "to_latitude": 21.010421196909395,
          "to_longitude": 105.82472601313634,
          "from_longitude": 105.82441909615443
        }
      },
      {
        "id": 952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82443970978366,
            21.010965798056954,
            105.82457623935481,
            21.01105049944392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82457623935481,
              21.010965798056954
            ],
            [
              105.82443970978366,
              21.01105049944392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.953",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010965798056954,
          "to_latitude": 21.01105049944392,
          "to_longitude": 105.82443970978366,
          "from_longitude": 105.82457623935481
        }
      },
      {
        "id": 953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82438870835442,
            21.01061984322226,
            105.82462473924856,
            21.01095205707479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82462473924856,
              21.01095205707479
            ],
            [
              105.82450759520322,
              21.010795060434056
            ],
            [
              105.82439157361134,
              21.010624172055486
            ],
            [
              105.82438870835442,
              21.01061984322226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A106_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.954",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01095205707479,
          "to_latitude": 21.01061984322226,
          "to_longitude": 105.82438870835442,
          "from_longitude": 105.82462473924856
        }
      },
      {
        "id": 954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82450759520322,
            21.010606027607977,
            105.82481233674278,
            21.010795060434056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82481233674278,
              21.010606027607977
            ],
            [
              105.8246378342293,
              21.010714667165256
            ],
            [
              105.82451897597713,
              21.010788665539664
            ],
            [
              105.82450759520322,
              21.010795060434056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A106_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.955",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010606027607977,
          "to_latitude": 21.010795060434056,
          "to_longitude": 105.82450759520322,
          "from_longitude": 105.82481233674278
        }
      },
      {
        "id": 955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82447309188174,
            21.01050722974313,
            105.82477716115676,
            21.010688163624646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82477716115676,
              21.01050722974313
            ],
            [
              105.82464748753232,
              21.010584390434218
            ],
            [
              105.82447309188174,
              21.010688163624646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A102_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.956",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01050722974313,
          "to_latitude": 21.010688163624646,
          "to_longitude": 105.82447309188174,
          "from_longitude": 105.82477716115676
        }
      },
      {
        "id": 956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82398537206373,
            21.010232361476504,
            105.8243295662572,
            21.01068124929375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82398537206373,
              21.010232361476504
            ],
            [
              105.82408402421935,
              21.010349043117074
            ],
            [
              105.82405547375387,
              21.01037335534677
            ],
            [
              105.82432428067219,
              21.010675313601933
            ],
            [
              105.8243295662572,
              21.01068124929375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A107_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.957",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010232361476504,
          "to_latitude": 21.01068124929375,
          "to_longitude": 105.8243295662572,
          "from_longitude": 105.82398537206373
        }
      },
      {
        "id": 957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81915239143662,
            21.013594453266098,
            105.81939971434909,
            21.013774818307912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81915239143662,
              21.013774818307912
            ],
            [
              105.81921739357192,
              21.01372741467687
            ],
            [
              105.81926084199924,
              21.01369572889969
            ],
            [
              105.81939971434909,
              21.013594453266098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A98A_Thái Hà",
          "maTaiSan": "04.O12.DODV.958",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013774818307912,
          "to_latitude": 21.013594453266098,
          "to_longitude": 105.81939971434909,
          "from_longitude": 105.81915239143662
        }
      },
      {
        "id": 958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81918886879095,
            21.0136922231823,
            105.81921739357192,
            21.01372741467687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81918886879095,
              21.0136922231823
            ],
            [
              105.81920557264789,
              21.013712831180314
            ],
            [
              105.81921238958817,
              21.013721242630623
            ],
            [
              105.81921739357192,
              21.01372741467687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A98A_Thái Hà",
          "maTaiSan": "04.O12.DODV.959",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0136922231823,
          "to_latitude": 21.01372741467687,
          "to_longitude": 105.81921739357192,
          "from_longitude": 105.81918886879095
        }
      },
      {
        "id": 959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81961024370757,
            21.01377097168634,
            105.81961765859204,
            21.013777422126157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81961024370757,
              21.013777422126157
            ],
            [
              105.81961051943257,
              21.013777182345912
            ],
            [
              105.8196166763242,
              21.013771826355107
            ],
            [
              105.81961765859204,
              21.01377097168634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A97_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.960",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013777422126157,
          "to_latitude": 21.01377097168634,
          "to_longitude": 105.81961765859204,
          "from_longitude": 105.81961024370757
        }
      },
      {
        "id": 960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81973813341907,
            21.013908654607924,
            105.81994568323933,
            21.01410845637208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81973813341907,
              21.013908654607924
            ],
            [
              105.81977933423715,
              21.013950280939383
            ],
            [
              105.81983673299322,
              21.014008275171335
            ],
            [
              105.81987349990044,
              21.01404542206185
            ],
            [
              105.81989088123026,
              21.014060600134073
            ],
            [
              105.81994568323933,
              21.01410845637208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A97A_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.961",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013908654607924,
          "to_latitude": 21.01410845637208,
          "to_longitude": 105.81994568323933,
          "from_longitude": 105.81973813341907
        }
      },
      {
        "id": 961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.819692433979,
            21.013818142890152,
            105.8199487087333,
            21.01408456407631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.819692433979,
              21.013818142890152
            ],
            [
              105.81983855374101,
              21.01397004814274
            ],
            [
              105.8199487087333,
              21.01408456407631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96A_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.962",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013818142890152,
          "to_latitude": 21.01408456407631,
          "to_longitude": 105.8199487087333,
          "from_longitude": 105.819692433979
        }
      },
      {
        "id": 962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81985362545439,
            21.013662431145715,
            105.82016658216,
            21.013998054199263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82016658216,
              21.013998054199263
            ],
            [
              105.81985362545439,
              21.013662431145715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94_Ngách 178/1, Thái Hà",
          "maTaiSan": "04.O12.DODV.963",
          "diaChiLapD": "Ngách 178/1, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013998054199263,
          "to_latitude": 21.013662431145715,
          "to_longitude": 105.81985362545439,
          "from_longitude": 105.82016658216
        }
      },
      {
        "id": 963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204012622204,
            21.012512277298594,
            105.82074778180852,
            21.012853928440865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8207181584673,
              21.012512277298594
            ],
            [
              105.82072234326671,
              21.012516403829505
            ],
            [
              105.82074778180852,
              21.012541478454793
            ],
            [
              105.82057369209927,
              21.01270826264873
            ],
            [
              105.82055553875834,
              21.012724621835115
            ],
            [
              105.8204012622204,
              21.012853928440865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A90_Thái Hà",
          "maTaiSan": "04.O12.DODV.964",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012512277298594,
          "to_latitude": 21.012853928440865,
          "to_longitude": 105.8204012622204,
          "from_longitude": 105.8207181584673
        }
      },
      {
        "id": 964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82093908749171,
            21.0122954061274,
            105.82098145925703,
            21.012329200968104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82098145925703,
              21.012329200968104
            ],
            [
              105.82096679912684,
              21.012317508071305
            ],
            [
              105.82096036951027,
              21.01231238166608
            ],
            [
              105.82095394180811,
              21.012307253444913
            ],
            [
              105.82093908749171,
              21.0122954061274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A90A_Thái Hà",
          "maTaiSan": "04.O12.DODV.965",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012329200968104,
          "to_latitude": 21.0122954061274,
          "to_longitude": 105.82093908749171,
          "from_longitude": 105.82098145925703
        }
      },
      {
        "id": 965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82091646519734,
            21.012793968944045,
            105.8212905053298,
            21.01342790822074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8212905053298,
              21.012793968944045
            ],
            [
              105.82113396102369,
              21.013021310773432
            ],
            [
              105.82091646519734,
              21.013237630926792
            ],
            [
              105.82097581232814,
              21.013367047995263
            ],
            [
              105.821006802625,
              21.01339463746693
            ],
            [
              105.8209834849782,
              21.013418082144767
            ],
            [
              105.82097115781765,
              21.01342790822074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33B_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.966",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012793968944045,
          "to_latitude": 21.01342790822074,
          "to_longitude": 105.82097115781765,
          "from_longitude": 105.8212905053298
        }
      },
      {
        "id": 966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82013928665768,
            21.013541866345598,
            105.82038919988358,
            21.013806597787582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82038919988358,
              21.013806597787582
            ],
            [
              105.82026134153475,
              21.013671158423723
            ],
            [
              105.82020794933175,
              21.013614600118483
            ],
            [
              105.82013928665768,
              21.013541866345598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A91A_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.967",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013806597787582,
          "to_latitude": 21.013541866345598,
          "to_longitude": 105.82013928665768,
          "from_longitude": 105.82038919988358
        }
      },
      {
        "id": 967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82019509946986,
            21.013614600118483,
            105.82020794933175,
            21.01362524969322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82020794933175,
              21.013614600118483
            ],
            [
              105.82020607629318,
              21.013616152763195
            ],
            [
              105.8201968039746,
              21.013623836958047
            ],
            [
              105.82019509946986,
              21.01362524969322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A91A_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.968",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013614600118483,
          "to_latitude": 21.01362524969322,
          "to_longitude": 105.82019509946986,
          "from_longitude": 105.82020794933175
        }
      },
      {
        "id": 968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82036668066424,
            21.01384326900625,
            105.82064749192321,
            21.014141574839826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82036668066424,
              21.01384326900625
            ],
            [
              105.8204837473779,
              21.013967628048754
            ],
            [
              105.82052418325712,
              21.014010583819562
            ],
            [
              105.82064749192321,
              21.014141574839826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A91_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.969",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01384326900625,
          "to_latitude": 21.014141574839826,
          "to_longitude": 105.82064749192321,
          "from_longitude": 105.82036668066424
        }
      },
      {
        "id": 969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204837473779,
            21.01394896303336,
            105.8205059995421,
            21.013967628048754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204837473779,
              21.013967628048754
            ],
            [
              105.82049848863531,
              21.01395526313507
            ],
            [
              105.82050488400864,
              21.01394989783379
            ],
            [
              105.8205059995421,
              21.01394896303336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A91_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.970",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013967628048754,
          "to_latitude": 21.01394896303336,
          "to_longitude": 105.8205059995421,
          "from_longitude": 105.8204837473779
        }
      },
      {
        "id": 970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82109953072302,
            21.01417816399398,
            105.8214731281681,
            21.01453373948299
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82109953072302,
              21.01417816399398
            ],
            [
              105.82146724609042,
              21.01452814348936
            ],
            [
              105.82147308948902,
              21.01453370263744
            ],
            [
              105.8214731281681,
              21.01453373948299
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A26_Ngõ 185, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.971",
          "diaChiLapD": "Ngõ 185, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01417816399398,
          "to_latitude": 21.01453373948299,
          "to_longitude": 105.8214731281681,
          "from_longitude": 105.82109953072302
        }
      },
      {
        "id": 971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82032997022363,
            21.01460149926901,
            105.82059917166217,
            21.014877526098772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82032997022363,
              21.01460149926901
            ],
            [
              105.8204635372537,
              21.014741980826845
            ],
            [
              105.82047184807394,
              21.01475072192196
            ],
            [
              105.82056140180946,
              21.014844910287408
            ],
            [
              105.82059917166217,
              21.014877526098772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15A_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.972",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01460149926901,
          "to_latitude": 21.014877526098772,
          "to_longitude": 105.82059917166217,
          "from_longitude": 105.82032997022363
        }
      },
      {
        "id": 972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82047184807394,
            21.014746764145407,
            105.82047630903614,
            21.01475072192196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82047630903614,
              21.014746764145407
            ],
            [
              105.82047566477944,
              21.014747335382182
            ],
            [
              105.82047339600969,
              21.01474934877773
            ],
            [
              105.82047184807394,
              21.01475072192196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15A_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.973",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014746764145407,
          "to_latitude": 21.01475072192196,
          "to_longitude": 105.82047184807394,
          "from_longitude": 105.82047630903614
        }
      },
      {
        "id": 973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81981289468342,
            21.014440771240164,
            105.82031394051906,
            21.01477268317926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82031394051906,
              21.01457545522905
            ],
            [
              105.82030745261534,
              21.014580616840508
            ],
            [
              105.82030354679642,
              21.014594972596395
            ],
            [
              105.82011043598766,
              21.01477268317926
            ],
            [
              105.81981289468342,
              21.014440771240164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.974",
          "diaChiLapD": "Ngõ 223, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01457545522905,
          "to_latitude": 21.014440771240164,
          "to_longitude": 105.81981289468342,
          "from_longitude": 105.82031394051906
        }
      },
      {
        "id": 974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82037097846145,
            21.0149172660418,
            105.82061457704997,
            21.0151147491017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82037097846145,
              21.0151147491017
            ],
            [
              105.82045101258055,
              21.0150498709447
            ],
            [
              105.8204846504186,
              21.015022597462945
            ],
            [
              105.82061457704997,
              21.0149172660418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14A_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.975",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0151147491017,
          "to_latitude": 21.0149172660418,
          "to_longitude": 105.82061457704997,
          "from_longitude": 105.82037097846145
        }
      },
      {
        "id": 975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82045101258055,
            21.0150498709447,
            105.82046216087566,
            21.015062259135224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82045101258055,
              21.0150498709447
            ],
            [
              105.82046061664607,
              21.015060617289652
            ],
            [
              105.8204621424907,
              21.015062239352886
            ],
            [
              105.82046216087566,
              21.015062259135224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14A_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.976",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0150498709447,
          "to_latitude": 21.015062259135224,
          "to_longitude": 105.82046216087566,
          "from_longitude": 105.82045101258055
        }
      },
      {
        "id": 976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82250143434017,
            21.011315712640478,
            105.82271043445488,
            21.011496449860545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82250143434017,
              21.011496449860545
            ],
            [
              105.82270863268032,
              21.011317270386435
            ],
            [
              105.82271043445488,
              21.011315712640478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77A_Ngõ 70, Thái Hà",
          "maTaiSan": "04.O12.DODV.977",
          "diaChiLapD": "Ngõ 70, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011496449860545,
          "to_latitude": 21.011315712640478,
          "to_longitude": 105.82271043445488,
          "from_longitude": 105.82250143434017
        }
      },
      {
        "id": 977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82125327264147,
            21.011784565867902,
            105.82149327135228,
            21.012045488935872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82149327135228,
              21.011784565867902
            ],
            [
              105.82137104313865,
              21.01191744981815
            ],
            [
              105.821308752798,
              21.01198517098981
            ],
            [
              105.82125327264147,
              21.012045488935872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A89_Thái Hà",
          "maTaiSan": "04.O12.DODV.978",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011784565867902,
          "to_latitude": 21.012045488935872,
          "to_longitude": 105.82125327264147,
          "from_longitude": 105.82149327135228
        }
      },
      {
        "id": 978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82159113357028,
            21.01164438670061,
            105.82160967356872,
            21.011659857977907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82159113357028,
              21.01164438670061
            ],
            [
              105.82159396589236,
              21.01164675212523
            ],
            [
              105.82159843322268,
              21.011650478044384
            ],
            [
              105.82160967356872,
              21.011659857977907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A98A_Thái Hà",
          "maTaiSan": "04.O12.DODV.979",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01164438670061,
          "to_latitude": 21.011659857977907,
          "to_longitude": 105.82160967356872,
          "from_longitude": 105.82159113357028
        }
      },
      {
        "id": 979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8210498550504,
            21.012452231220937,
            105.82136506061633,
            21.012693999639637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8210498550504,
              21.012693999639637
            ],
            [
              105.82115607896354,
              21.012599458364917
            ],
            [
              105.82116251746814,
              21.012593727928415
            ],
            [
              105.82116781361334,
              21.012587719391572
            ],
            [
              105.82119076769487,
              21.012561674247795
            ],
            [
              105.82121661742701,
              21.012532341830138
            ],
            [
              105.82129610279515,
              21.012452231220937
            ],
            [
              105.82136506061633,
              21.01252194362108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.980",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012693999639637,
          "to_latitude": 21.01252194362108,
          "to_longitude": 105.82136506061633,
          "from_longitude": 105.8210498550504
        }
      },
      {
        "id": 980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82118942299347,
            21.01256063118775,
            105.82119076769487,
            21.012561674247795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82119076769487,
              21.012561674247795
            ],
            [
              105.82118955920885,
              21.01256073801436
            ],
            [
              105.82118942299347,
              21.01256063118775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.981",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012561674247795,
          "to_latitude": 21.01256063118775,
          "to_longitude": 105.82118942299347,
          "from_longitude": 105.82119076769487
        }
      },
      {
        "id": 981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8218956624933,
            21.012945015415347,
            105.8221463797823,
            21.013200063177017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8221463797823,
              21.013200063177017
            ],
            [
              105.82201976884966,
              21.01307126687863
            ],
            [
              105.82199652525141,
              21.013047621593383
            ],
            [
              105.8218956624933,
              21.012945015415347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34A_Trung Liệt",
          "maTaiSan": "04.O12.DODV.982",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013200063177017,
          "to_latitude": 21.012945015415347,
          "to_longitude": 105.8218956624933,
          "from_longitude": 105.8221463797823
        }
      },
      {
        "id": 982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82199652525141,
            21.0130182204117,
            105.82203004008313,
            21.013047621593383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82199652525141,
              21.013047621593383
            ],
            [
              105.8219966410916,
              21.01304752077555
            ],
            [
              105.82200373579346,
              21.01304129668545
            ],
            [
              105.82200674464826,
              21.013038656468282
            ],
            [
              105.822006778186,
              21.013038632822347
            ],
            [
              105.82202057888856,
              21.013026520712934
            ],
            [
              105.82203001998231,
              21.013018238573363
            ],
            [
              105.82203004008313,
              21.0130182204117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34A_Trung Liệt",
          "maTaiSan": "04.O12.DODV.983",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013047621593383,
          "to_latitude": 21.0130182204117,
          "to_longitude": 105.82203004008313,
          "from_longitude": 105.82199652525141
        }
      },
      {
        "id": 983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82322588463565,
            21.012271338328038,
            105.82346258335345,
            21.01245042380782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346258335345,
              21.01245042380782
            ],
            [
              105.82346094280645,
              21.012449285552623
            ],
            [
              105.82345378577259,
              21.01244431270035
            ],
            [
              105.82344733718973,
              21.01244415874713
            ],
            [
              105.82338892593393,
              21.012398639691565
            ],
            [
              105.8233987303024,
              21.01238611236108
            ],
            [
              105.82326229978368,
              21.012271338328038
            ],
            [
              105.82322591141988,
              21.01230744926706
            ],
            [
              105.82322588463565,
              21.012307476493458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A54_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.984",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01245042380782,
          "to_latitude": 21.012307476493458,
          "to_longitude": 105.82322588463565,
          "from_longitude": 105.82346258335345
        }
      },
      {
        "id": 984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82456192868567,
            21.010468664534137,
            105.82457743671756,
            21.01050545458863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82456192868567,
              21.010468664534137
            ],
            [
              105.82456897512574,
              21.01048537982717
            ],
            [
              105.82457664163434,
              21.010504654569583
            ],
            [
              105.82457743671756,
              21.01050545458863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A103A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.985",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010468664534137,
          "to_latitude": 21.01050545458863,
          "to_longitude": 105.82457743671756,
          "from_longitude": 105.82456192868567
        }
      },
      {
        "id": 985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82392654073257,
            21.011603783452365,
            105.82421340809692,
            21.011916431040703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82421340809692,
              21.011603783452365
            ],
            [
              105.82414574919626,
              21.011677523636628
            ],
            [
              105.82407030301974,
              21.01175975029301
            ],
            [
              105.82392654073257,
              21.011916431040703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.986",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011603783452365,
          "to_latitude": 21.011916431040703,
          "to_longitude": 105.82392654073257,
          "from_longitude": 105.82421340809692
        }
      },
      {
        "id": 986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82414574919626,
            21.011677523636628,
            105.82416009879071,
            21.011689389372325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82414574919626,
              21.011677523636628
            ],
            [
              105.82414942791226,
              21.011680565951046
            ],
            [
              105.82415458604116,
              21.011684832197844
            ],
            [
              105.82415974608912,
              21.01168909753195
            ],
            [
              105.82416009879071,
              21.011689389372325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.987",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011677523636628,
          "to_latitude": 21.011689389372325,
          "to_longitude": 105.82416009879071,
          "from_longitude": 105.82414574919626
        }
      },
      {
        "id": 987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82286984403194,
            21.012219728673667,
            105.82328758087068,
            21.012554239426887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82286984403194,
              21.012219728673667
            ],
            [
              105.8230746422552,
              21.012383724258807
            ],
            [
              105.82328758087068,
              21.012554239426887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51A_Ngõ 117, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.988",
          "diaChiLapD": "Ngõ 117, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012219728673667,
          "to_latitude": 21.012554239426887,
          "to_longitude": 105.82328758087068,
          "from_longitude": 105.82286984403194
        }
      },
      {
        "id": 988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82307102036077,
            21.012383724258807,
            105.8230746422552,
            21.0123877069451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82307102036077,
              21.0123877069451
            ],
            [
              105.82307322144099,
              21.01238529100459
            ],
            [
              105.8230746422552,
              21.012383724258807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51A_Ngõ 117, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.989",
          "diaChiLapD": "Ngõ 117, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0123877069451,
          "to_latitude": 21.012383724258807,
          "to_longitude": 105.8230746422552,
          "from_longitude": 105.82307102036077
        }
      },
      {
        "id": 989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82303387876773,
            21.01095025276772,
            105.82304072350652,
            21.01095740483183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82303387876773,
              21.01095025276772
            ],
            [
              105.82303627646009,
              21.01095281988368
            ],
            [
              105.82304072350652,
              21.01095740483183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73A_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.990",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01095025276772,
          "to_latitude": 21.01095740483183,
          "to_longitude": 105.82304072350652,
          "from_longitude": 105.82303387876773
        }
      },
      {
        "id": 990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82291747145985,
            21.01082825570345,
            105.82317634815854,
            21.01105056545582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82291747145985,
              21.01105056545582
            ],
            [
              105.8229676435941,
              21.01100811049216
            ],
            [
              105.82296734067161,
              21.011007774150293
            ],
            [
              105.82296710676196,
              21.011007393216563
            ],
            [
              105.82296694385835,
              21.01100698032645
            ],
            [
              105.8229668578013,
              21.011006548096905
            ],
            [
              105.82296684961729,
              21.011006108264922
            ],
            [
              105.82296692033741,
              21.01100567347067
            ],
            [
              105.82296706906916,
              21.01100525636369
            ],
            [
              105.82296728913887,
              21.011004867814933
            ],
            [
              105.82296757580637,
              21.011004520492513
            ],
            [
              105.82296771175477,
              21.01100440331872
            ],
            [
              105.82296777014726,
              21.011004351552355
            ],
            [
              105.82296792045946,
              21.011004222567152
            ],
            [
              105.82296831449553,
              21.011003984015925
            ],
            [
              105.82296874447806,
              21.011003810323206
            ],
            [
              105.82296919986089,
              21.011003707862557
            ],
            [
              105.8229696671877,
              21.011003678505535
            ],
            [
              105.82297013299198,
              21.01100372231728
            ],
            [
              105.82297058477434,
              21.011003840261438
            ],
            [
              105.82297100807665,
              21.011004026988612
            ],
            [
              105.82297139326036,
              21.01100427893248
            ],
            [
              105.82297173064721,
              21.011004585301137
            ],
            [
              105.82303387876773,
              21.01095025276772
            ],
            [
              105.82308324003303,
              21.01090763820928
            ],
            [
              105.82317634815854,
              21.01082825570345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73A_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.991",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01105056545582,
          "to_latitude": 21.01082825570345,
          "to_longitude": 105.82317634815854,
          "from_longitude": 105.82291747145985
        }
      },
      {
        "id": 991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82160373416127,
            21.012878246677158,
            105.8218903206684,
            21.013404037554853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8218903206684,
              21.012878246677158
            ],
            [
              105.82189030346805,
              21.012878267534475
            ],
            [
              105.82187406544708,
              21.012897612706723
            ],
            [
              105.82187403577176,
              21.012897639043643
            ],
            [
              105.82187111615116,
              21.012901116118908
            ],
            [
              105.82173030465484,
              21.013023577430275
            ],
            [
              105.82170986753307,
              21.01303497008219
            ],
            [
              105.82165603573041,
              21.013134947220227
            ],
            [
              105.8216970589957,
              21.013165177923852
            ],
            [
              105.82160373416127,
              21.013304278466748
            ],
            [
              105.82162348878686,
              21.01335338004304
            ],
            [
              105.82168688869999,
              21.013404037554853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35A_Ngõ 16, Trung Liệt",
          "maTaiSan": "04.O12.DODV.992",
          "diaChiLapD": "Ngõ 16, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012878246677158,
          "to_latitude": 21.013404037554853,
          "to_longitude": 105.82168688869999,
          "from_longitude": 105.8218903206684
        }
      },
      {
        "id": 992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82062336689243,
            21.013913367127437,
            105.8207327574591,
            21.014093351316948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063855054125,
              21.014093351316948
            ],
            [
              105.82064154630243,
              21.014090946928416
            ],
            [
              105.82065327326731,
              21.014082874224094
            ],
            [
              105.82068866652557,
              21.014068814580625
            ],
            [
              105.8207327574591,
              21.014034169976338
            ],
            [
              105.82064205462811,
              21.013934005150972
            ],
            [
              105.82062336689243,
              21.013913367127437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Ngách 178/40, Thái Hà",
          "maTaiSan": "04.O12.DODV.993",
          "diaChiLapD": "Ngách 178/40, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014093351316948,
          "to_latitude": 21.013913367127437,
          "to_longitude": 105.82062336689243,
          "from_longitude": 105.82063855054125
        }
      },
      {
        "id": 993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82064205462811,
            21.0138708955461,
            105.8207267389928,
            21.013934005150972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82064205462811,
              21.013934005150972
            ],
            [
              105.8207267389928,
              21.0138708955461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Ngách 178/40, Thái Hà",
          "maTaiSan": "04.O12.DODV.994",
          "diaChiLapD": "Ngách 178/40, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013934005150972,
          "to_latitude": 21.0138708955461,
          "to_longitude": 105.8207267389928,
          "from_longitude": 105.82064205462811
        }
      },
      {
        "id": 994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81951114825137,
            21.013422691756503,
            105.81955971811426,
            21.013479976230578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81951114825137,
              21.013422691756503
            ],
            [
              105.81951462500284,
              21.013426806372408
            ],
            [
              105.8195216201197,
              21.013435081468145
            ],
            [
              105.81952165981419,
              21.01343512824479
            ],
            [
              105.81955971811426,
              21.013479976230578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96_Thái Hà",
          "maTaiSan": "04.O12.DODV.995",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013422691756503,
          "to_latitude": 21.013479976230578,
          "to_longitude": 105.81955971811426,
          "from_longitude": 105.81951114825137
        }
      },
      {
        "id": 995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81948517954551,
            21.013282524694006,
            105.8198202616248,
            21.013535810579434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81948517954551,
              21.013535810579434
            ],
            [
              105.81955971811426,
              21.013479976230578
            ],
            [
              105.81965392923938,
              21.013409404861754
            ],
            [
              105.81965401737392,
              21.013409338501578
            ],
            [
              105.8198202616248,
              21.013282524694006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96_Thái Hà",
          "maTaiSan": "04.O12.DODV.996",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013535810579434,
          "to_latitude": 21.013282524694006,
          "to_longitude": 105.8198202616248,
          "from_longitude": 105.81948517954551
        }
      },
      {
        "id": 996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82336465220115,
            21.01036645103511,
            105.82345376147029,
            21.010436680000684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82336465220115,
              21.010436680000684
            ],
            [
              105.82345376147029,
              21.01036645103511
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66A_Ngõ 6, Thái Hà",
          "maTaiSan": "04.O12.DODV.997",
          "diaChiLapD": "Ngõ 6, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010436680000684,
          "to_latitude": 21.01036645103511,
          "to_longitude": 105.82345376147029,
          "from_longitude": 105.82336465220115
        }
      },
      {
        "id": 997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81952989778911,
            21.013950280939383,
            105.81977933423715,
            21.014172184391445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81977933423715,
              21.013950280939383
            ],
            [
              105.81952989778911,
              21.014172184391445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A97A_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DODV.998",
          "diaChiLapD": "Ngõ 198, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013950280939383,
          "to_latitude": 21.014172184391445,
          "to_longitude": 105.81952989778911,
          "from_longitude": 105.81977933423715
        }
      },
      {
        "id": 998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82264807648671,
            21.011574478823604,
            105.82289794978523,
            21.011748774861537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82289794978523,
              21.011748774861537
            ],
            [
              105.82270207657517,
              21.011574478823604
            ],
            [
              105.82267002003425,
              21.01160345307868
            ],
            [
              105.82264921966294,
              21.011582934776538
            ],
            [
              105.82264807648671,
              21.011581804947046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A85A_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.999",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011748774861537,
          "to_latitude": 21.011581804947046,
          "to_longitude": 105.82264807648671,
          "from_longitude": 105.82289794978523
        }
      },
      {
        "id": 999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82281583896763,
            21.011428691268684,
            105.82309644238097,
            21.01165615623277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82309644238097,
              21.01165615623277
            ],
            [
              105.82287755419898,
              21.011428691268684
            ],
            [
              105.82282389435835,
              21.011442972455395
            ],
            [
              105.82281710523658,
              21.01143789573006
            ],
            [
              105.82281583896763,
              21.011436947142318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A86A_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.1000",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01165615623277,
          "to_latitude": 21.011436947142318,
          "to_longitude": 105.82281583896763,
          "from_longitude": 105.82309644238097
        }
      },
      {
        "id": 1000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82298432488818,
            21.011946404075026,
            105.8230705130493,
            21.01201541281918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8230705130493,
              21.01201541281918
            ],
            [
              105.82307035363381,
              21.01201528533209
            ],
            [
              105.82298432488818,
              21.011946404075026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1001",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01201541281918,
          "to_latitude": 21.011946404075026,
          "to_longitude": 105.82298432488818,
          "from_longitude": 105.8230705130493
        }
      },
      {
        "id": 1001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82391207239469,
            21.010378385036912,
            105.82404809182567,
            21.010479398517163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82391207239469,
              21.010479398517163
            ],
            [
              105.82404809182567,
              21.010378385036912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A108_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1002",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010479398517163,
          "to_latitude": 21.010378385036912,
          "to_longitude": 105.82404809182567,
          "from_longitude": 105.82391207239469
        }
      },
      {
        "id": 1002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8246378342293,
            21.010714667165256,
            105.82469884816854,
            21.010795023296232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8246378342293,
              21.010714667165256
            ],
            [
              105.82469884816854,
              21.010795023296232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A106_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1003",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010714667165256,
          "to_latitude": 21.010795023296232,
          "to_longitude": 105.82469884816854,
          "from_longitude": 105.8246378342293
        }
      },
      {
        "id": 1003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82444627882818,
            21.010022136637758,
            105.82457918410422,
            21.01050433244244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82457918410422,
              21.01050433244244
            ],
            [
              105.82457847207417,
              21.01050341731113
            ],
            [
              105.82457592161683,
              21.010470256858664
            ],
            [
              105.82457042118669,
              21.010460058984886
            ],
            [
              105.82449975920673,
              21.010329053230645
            ],
            [
              105.82453014788862,
              21.010229669353812
            ],
            [
              105.8244466834407,
              21.01011692562513
            ],
            [
              105.82444627882818,
              21.010048192922604
            ],
            [
              105.82448635856699,
              21.010022136637758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A103_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1004",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01050433244244,
          "to_latitude": 21.010022136637758,
          "to_longitude": 105.82448635856699,
          "from_longitude": 105.82457918410422
        }
      },
      {
        "id": 1004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82346833086429,
            21.01069512987035,
            105.8236565840505,
            21.01093190020713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346833086429,
              21.01069512987035
            ],
            [
              105.82351481492198,
              21.010745933623518
            ],
            [
              105.82348731508131,
              21.010773646745786
            ],
            [
              105.82350985916254,
              21.010806391620996
            ],
            [
              105.82358070603343,
              21.010877967278898
            ],
            [
              105.8236028611099,
              21.01087576365651
            ],
            [
              105.8236565840505,
              21.01093190020713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1005",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01069512987035,
          "to_latitude": 21.01093190020713,
          "to_longitude": 105.8236565840505,
          "from_longitude": 105.82346833086429
        }
      },
      {
        "id": 1005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82328416007205,
            21.01159426288633,
            105.82341001536113,
            21.011710730597173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82328416007205,
              21.011710730597173
            ],
            [
              105.82341001536113,
              21.01159426288633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A110_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1006",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011710730597173,
          "to_latitude": 21.01159426288633,
          "to_longitude": 105.82341001536113,
          "from_longitude": 105.82328416007205
        }
      },
      {
        "id": 1006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82325177909449,
            21.01117497462716,
            105.82338676279448,
            21.011238927346763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82338676279448,
              21.01117497462716
            ],
            [
              105.82330935100788,
              21.011238927346763
            ],
            [
              105.82325177909449,
              21.011177637432176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59B_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1007",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01117497462716,
          "to_latitude": 21.011177637432176,
          "to_longitude": 105.82325177909449,
          "from_longitude": 105.82338676279448
        }
      },
      {
        "id": 1007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82328792783892,
            21.01113417828326,
            105.82347531589993,
            21.01125955962606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82347531589993,
              21.01125955962606
            ],
            [
              105.82338676279448,
              21.01117497462716
            ],
            [
              105.82334407731263,
              21.01113417828326
            ],
            [
              105.82328792783892,
              21.01117941982811
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59B_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1008",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01125955962606,
          "to_latitude": 21.01117941982811,
          "to_longitude": 105.82328792783892,
          "from_longitude": 105.82347531589993
        }
      },
      {
        "id": 1008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8235241733319,
            21.01145027012484,
            105.8236439229008,
            21.01164256911236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82360675219245,
              21.01164256911236
            ],
            [
              105.82360643860713,
              21.01164221837173
            ],
            [
              105.8235241733319,
              21.011550294109362
            ],
            [
              105.8236439229008,
              21.01145027012484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1009",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01164256911236,
          "to_latitude": 21.01145027012484,
          "to_longitude": 105.8236439229008,
          "from_longitude": 105.82360675219245
        }
      },
      {
        "id": 1009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299267598827,
            21.011962617611175,
            105.82324355802578,
            21.01209628128907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82324355802578,
              21.012000202621188
            ],
            [
              105.82322248529624,
              21.01198213797258
            ],
            [
              105.82319228281246,
              21.012009436900538
            ],
            [
              105.82312181668378,
              21.011962617611175
            ],
            [
              105.82310926799198,
              21.01197514826626
            ],
            [
              105.8230705130493,
              21.01201541281918
            ],
            [
              105.82299267598827,
              21.01209628128907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53A_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1010",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012000202621188,
          "to_latitude": 21.01209628128907,
          "to_longitude": 105.82299267598827,
          "from_longitude": 105.82324355802578
        }
      },
      {
        "id": 1010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.822201446749,
            21.012297294530093,
            105.82229018427766,
            21.012451992882994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82221638974893,
              21.012297294530093
            ],
            [
              105.82229018427766,
              21.01236908408003
            ],
            [
              105.822201446749,
              21.012451992882994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48C_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1011",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012297294530093,
          "to_latitude": 21.012451992882994,
          "to_longitude": 105.822201446749,
          "from_longitude": 105.82221638974893
        }
      },
      {
        "id": 1011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82197799835511,
            21.012402745578456,
            105.82236336040674,
            21.012757316580846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82236336040674,
              21.012748493112714
            ],
            [
              105.82235788983077,
              21.012753206397857
            ],
            [
              105.82235311430267,
              21.012757316580846
            ],
            [
              105.82231879679448,
              21.012725371817453
            ],
            [
              105.82217957954684,
              21.01259997035982
            ],
            [
              105.82197799835511,
              21.012402745578456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48B_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1012",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012748493112714,
          "to_latitude": 21.012402745578456,
          "to_longitude": 105.82197799835511,
          "from_longitude": 105.82236336040674
        }
      },
      {
        "id": 1012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82261328271953,
            21.01264166344859,
            105.8227038727931,
            21.012715919607235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82261328271953,
              21.01264166344859
            ],
            [
              105.8227038727931,
              21.012715919607235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1013",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01264166344859,
          "to_latitude": 21.012715919607235,
          "to_longitude": 105.8227038727931,
          "from_longitude": 105.82261328271953
        }
      },
      {
        "id": 1013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8213244574055,
            21.01297759371194,
            105.82147697552308,
            21.013093460417988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82147697552308,
              21.013093460417988
            ],
            [
              105.8213244574055,
              21.01297759371194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35B_Ngõ 16, Trung Liệt",
          "maTaiSan": "04.O12.DODV.1014",
          "diaChiLapD": "Ngõ 16, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013093460417988,
          "to_latitude": 21.01297759371194,
          "to_longitude": 105.8213244574055,
          "from_longitude": 105.82147697552308
        }
      },
      {
        "id": 1014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8216970589957,
            21.013165177923852,
            105.82185750943249,
            21.013236765905937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8216970589957,
              21.013165177923852
            ],
            [
              105.82177617585917,
              21.013207959232453
            ],
            [
              105.82185750943249,
              21.013236765905937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35A_Ngõ 16, Trung Liệt",
          "maTaiSan": "04.O12.DODV.1015",
          "diaChiLapD": "Ngõ 16, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013165177923852,
          "to_latitude": 21.013236765905937,
          "to_longitude": 105.82185750943249,
          "from_longitude": 105.8216970589957
        }
      },
      {
        "id": 1015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8231594189272,
            21.010210477001003,
            105.82321819048006,
            21.010272045678068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82321819048006,
              21.010272045678068
            ],
            [
              105.82321245921636,
              21.01026604165417
            ],
            [
              105.8231594189272,
              21.010210477001003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Thái Hà",
          "maTaiSan": "04.O12.DODV.1016",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010272045678068,
          "to_latitude": 21.010210477001003,
          "to_longitude": 105.8231594189272,
          "from_longitude": 105.82321819048006
        }
      },
      {
        "id": 1016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82322302374902,
            21.010039525321027,
            105.82360250310143,
            21.010431680393193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82322302374902,
              21.010039525321027
            ],
            [
              105.82323287491168,
              21.01005343252173
            ],
            [
              105.82324178767283,
              21.010070220136726
            ],
            [
              105.82341746602297,
              21.010268144214955
            ],
            [
              105.8233954180373,
              21.010287216864217
            ],
            [
              105.82345376147029,
              21.01036645103511
            ],
            [
              105.82352951457308,
              21.010431680393193
            ],
            [
              105.82360250310143,
              21.010375102161746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66A_Ngõ 6, Thái Hà",
          "maTaiSan": "04.O12.DODV.1017",
          "diaChiLapD": "Ngõ 6, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010039525321027,
          "to_latitude": 21.010375102161746,
          "to_longitude": 105.82360250310143,
          "from_longitude": 105.82322302374902
        }
      },
      {
        "id": 1017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82282120569133,
            21.010937473665617,
            105.82291382807513,
            21.01103361414925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82291382807513,
              21.01103361414925
            ],
            [
              105.82282120569133,
              21.010937473665617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.1018",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01103361414925,
          "to_latitude": 21.010937473665617,
          "to_longitude": 105.82282120569133,
          "from_longitude": 105.82291382807513
        }
      },
      {
        "id": 1018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82277555785994,
            21.01097808399833,
            105.82286708557844,
            21.011075099607805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82286708557844,
              21.011075099607805
            ],
            [
              105.82277555785994,
              21.01097808399833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.1019",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011075099607805,
          "to_latitude": 21.01097808399833,
          "to_longitude": 105.82277555785994,
          "from_longitude": 105.82286708557844
        }
      },
      {
        "id": 1019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299021531612,
            21.01085137854665,
            105.82307757560974,
            21.010897515143217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82307757560974,
              21.010897515143217
            ],
            [
              105.82303382983078,
              21.01085137854665
            ],
            [
              105.82299021531612,
              21.010884755090284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.1020",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010897515143217,
          "to_latitude": 21.010884755090284,
          "to_longitude": 105.82299021531612,
          "from_longitude": 105.82307757560974
        }
      },
      {
        "id": 1020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82305087794722,
            21.010701673816573,
            105.82313523764398,
            21.0107263005018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82313523764398,
              21.0107263005018
            ],
            [
              105.82311195326784,
              21.01070588007516
            ],
            [
              105.82305087794722,
              21.010701673816573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A72_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DODV.1021",
          "diaChiLapD": "Ngõ 16, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0107263005018,
          "to_latitude": 21.010701673816573,
          "to_longitude": 105.82305087794722,
          "from_longitude": 105.82313523764398
        }
      },
      {
        "id": 1021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82115607896354,
            21.012599458364917,
            105.82121336007683,
            21.012650901602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82115607896354,
              21.012599458364917
            ],
            [
              105.82121336007683,
              21.012650901602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.1022",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012599458364917,
          "to_latitude": 21.012650901602,
          "to_longitude": 105.82121336007683,
          "from_longitude": 105.82115607896354
        }
      },
      {
        "id": 1022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81964248932094,
            21.013442791381078,
            105.82003977443607,
            21.013801343215828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82003977443607,
              21.013442791381078
            ],
            [
              105.8200383410061,
              21.013445401390808
            ],
            [
              105.82002531637178,
              21.01347123865876
            ],
            [
              105.82002768276347,
              21.01351295364435
            ],
            [
              105.81987296538884,
              21.013643671857242
            ],
            [
              105.81985362545439,
              21.013662431145715
            ],
            [
              105.81981320443892,
              21.01368709253634
            ],
            [
              105.81968059767779,
              21.013787727734073
            ],
            [
              105.81964394092395,
              21.013800824996178
            ],
            [
              105.81964248932094,
              21.013801343215828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94_Ngách 178/1, Thái Hà",
          "maTaiSan": "04.O12.DODV.1023",
          "diaChiLapD": "Ngách 178/1, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013442791381078,
          "to_latitude": 21.013801343215828,
          "to_longitude": 105.81964248932094,
          "from_longitude": 105.82003977443607
        }
      },
      {
        "id": 1023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8197040052092,
            21.013439421242406,
            105.82003644218086,
            21.013727696670074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82003644218086,
              21.013439421242406
            ],
            [
              105.82003485417108,
              21.013441028511608
            ],
            [
              105.8200153182411,
              21.013468621421882
            ],
            [
              105.81984330864213,
              21.01360960141629
            ],
            [
              105.8197040052092,
              21.013727696670074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94B_Ngách 178/1, Thái Hà",
          "maTaiSan": "04.O12.DODV.1024",
          "diaChiLapD": "Ngách 178/1, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013439421242406,
          "to_latitude": 21.013727696670074,
          "to_longitude": 105.8197040052092,
          "from_longitude": 105.82003644218086
        }
      },
      {
        "id": 1024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82195883768176,
            21.01127976101604,
            105.82203979628413,
            21.011334769358854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82195883768176,
              21.01127976101604
            ],
            [
              105.82203979628413,
              21.011334769358854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Thái Hà",
          "maTaiSan": "04.O12.DODV.1025",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01127976101604,
          "to_latitude": 21.011334769358854,
          "to_longitude": 105.82203979628413,
          "from_longitude": 105.82195883768176
        }
      },
      {
        "id": 1025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82249685235183,
            21.010696454458753,
            105.82261875967427,
            21.010913478100903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82261875967427,
              21.010696454458753
            ],
            [
              105.82261866113527,
              21.01069655248365
            ],
            [
              105.82258931622842,
              21.01072567529028
            ],
            [
              105.82258905131341,
              21.01073016560978
            ],
            [
              105.82256891229048,
              21.0107498946059
            ],
            [
              105.82256232960586,
              21.010751409525813
            ],
            [
              105.82249685235183,
              21.010835944736822
            ],
            [
              105.82257413774207,
              21.010913478100903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Ngõ 48, Thái Hà",
          "maTaiSan": "04.O12.DODV.1026",
          "diaChiLapD": "Ngõ 48, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010696454458753,
          "to_latitude": 21.010913478100903,
          "to_longitude": 105.82257413774207,
          "from_longitude": 105.82261875967427
        }
      },
      {
        "id": 1026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245781688311,
            21.015154666519106,
            105.82525721464178,
            21.015603635661297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8245781688311,
              21.015603635661297
            ],
            [
              105.82525721464178,
              21.015265699624464
            ],
            [
              105.82520124919684,
              21.015154666519106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1027",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015603635661297,
          "to_latitude": 21.015154666519106,
          "to_longitude": 105.82520124919684,
          "from_longitude": 105.8245781688311
        }
      },
      {
        "id": 1027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82440345368012,
            21.01524050275435,
            105.82513962172406,
            21.01551013496761
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82440345368012,
              21.01551013496761
            ],
            [
              105.8244034776584,
              21.015510122206244
            ],
            [
              105.82440360907302,
              21.015510054730704
            ],
            [
              105.82440744944431,
              21.015508011991045
            ],
            [
              105.82445833641805,
              21.01548097118873
            ],
            [
              105.82456562520788,
              21.015509328276433
            ],
            [
              105.82490009904757,
              21.015352681620023
            ],
            [
              105.82513962172406,
              21.01524050275435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT147_Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1028",
          "diaChiLapD": "Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01551013496761,
          "to_latitude": 21.01524050275435,
          "to_longitude": 105.82513962172406,
          "from_longitude": 105.82440345368012
        }
      },
      {
        "id": 1028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82168454325047,
            21.016083467777058,
            105.82178084860057,
            21.016258084993034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82168454325047,
              21.016258084993034
            ],
            [
              105.82169878678634,
              21.01624439734537
            ],
            [
              105.82173410606747,
              21.01620886090888
            ],
            [
              105.82169170790894,
              21.01615626125333
            ],
            [
              105.82173980814815,
              21.01611802746895
            ],
            [
              105.82177424818994,
              21.016087409728154
            ],
            [
              105.82177590332694,
              21.01608593850592
            ],
            [
              105.82178084860057,
              21.016083467777058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A83_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1029",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016258084993034,
          "to_latitude": 21.016083467777058,
          "to_longitude": 105.82178084860057,
          "from_longitude": 105.82168454325047
        }
      },
      {
        "id": 1029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82144355691388,
            21.01593728815709,
            105.82154492725752,
            21.016030201349142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82154492725752,
              21.016030201349142
            ],
            [
              105.82153998232566,
              21.016025358642466
            ],
            [
              105.82149954523638,
              21.015987957093756
            ],
            [
              105.8214836968112,
              21.01597329837946
            ],
            [
              105.82144355691388,
              21.01593728815709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A80_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1030",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016030201349142,
          "to_latitude": 21.01593728815709,
          "to_longitude": 105.82144355691388,
          "from_longitude": 105.82154492725752
        }
      },
      {
        "id": 1030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149954523638,
            21.015892325553548,
            105.82161787789745,
            21.015987957093756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82161787789745,
              21.015892325553548
            ],
            [
              105.82161111243144,
              21.015899676169997
            ],
            [
              105.82160979156276,
              21.015900531582147
            ],
            [
              105.82155214984554,
              21.0159378837923
            ],
            [
              105.82149954523638,
              21.015987957093756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A80_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1031",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015892325553548,
          "to_latitude": 21.015987957093756,
          "to_longitude": 105.82149954523638,
          "from_longitude": 105.82161787789745
        }
      },
      {
        "id": 1031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82156919884447,
            21.015555738328825,
            105.82184167129145,
            21.01583945542033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184167129145,
              21.015555738328825
            ],
            [
              105.82172899936995,
              21.015679164769644
            ],
            [
              105.8216551151907,
              21.01575545950515
            ],
            [
              105.82157375903019,
              21.015834996748595
            ],
            [
              105.82156919884447,
              21.01583945542033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B82_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1032",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015555738328825,
          "to_latitude": 21.01583945542033,
          "to_longitude": 105.82156919884447,
          "from_longitude": 105.82184167129145
        }
      },
      {
        "id": 1032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82157414079423,
            21.015846037755573,
            105.82207126371372,
            21.01626780091853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82207126371372,
              21.016255831059294
            ],
            [
              105.82197256908455,
              21.0162624562029
            ],
            [
              105.82192146533156,
              21.01626780091853
            ],
            [
              105.82190527749943,
              21.016245986717546
            ],
            [
              105.82183661234214,
              21.016151229440876
            ],
            [
              105.82179378373607,
              21.01608959510708
            ],
            [
              105.82175868963108,
              21.01603594495801
            ],
            [
              105.82174474557294,
              21.01600912596114
            ],
            [
              105.8217246003648,
              21.015989066857422
            ],
            [
              105.82169542037842,
              21.015960010088712
            ],
            [
              105.82168190269046,
              21.01594692620223
            ],
            [
              105.82162388050975,
              21.015890763788452
            ],
            [
              105.82157414079423,
              21.015846037755573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A82_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1033",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016255831059294,
          "to_latitude": 21.015846037755573,
          "to_longitude": 105.82157414079423,
          "from_longitude": 105.82207126371372
        }
      },
      {
        "id": 1033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82096605850124,
            21.01540533170143,
            105.8211191400746,
            21.01550115768016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8211191400746,
              21.01540533170143
            ],
            [
              105.82107291659173,
              21.015459724918717
            ],
            [
              105.82104471000248,
              21.015436234436
            ],
            [
              105.8210191761911,
              21.015463098595493
            ],
            [
              105.82099919135037,
              21.01547055001447
            ],
            [
              105.82096605850124,
              21.01550115768016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1034",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01540533170143,
          "to_latitude": 21.01550115768016,
          "to_longitude": 105.82096605850124,
          "from_longitude": 105.8211191400746
        }
      },
      {
        "id": 1034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8206558756268,
            21.015237818768604,
            105.82104899364482,
            21.015614439838696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104899364482,
              21.0152592923845
            ],
            [
              105.82099844215284,
              21.015270402156734
            ],
            [
              105.8209876160694,
              21.015271902272282
            ],
            [
              105.8209525169169,
              21.01523815637491
            ],
            [
              105.8209509778945,
              21.015237818768604
            ],
            [
              105.82085943137928,
              21.015326591571238
            ],
            [
              105.82075791200951,
              21.015429893876725
            ],
            [
              105.82071586666926,
              21.015470153922813
            ],
            [
              105.8206558756268,
              21.01553313900526
            ],
            [
              105.82073928575919,
              21.015614439838696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B78A_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1035",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0152592923845,
          "to_latitude": 21.015614439838696,
          "to_longitude": 105.82073928575919,
          "from_longitude": 105.82104899364482
        }
      },
      {
        "id": 1035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111347924037,
            21.01458006443701,
            105.82137847147045,
            21.014996453744192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82111347924037,
              21.014996453744192
            ],
            [
              105.82114450621096,
              21.014968821509992
            ],
            [
              105.82114496094512,
              21.01496824666936
            ],
            [
              105.82118431616158,
              21.014918443365495
            ],
            [
              105.82124467680123,
              21.014840999270948
            ],
            [
              105.82125235584682,
              21.014831146836645
            ],
            [
              105.82134417190159,
              21.01471334462415
            ],
            [
              105.82137847147045,
              21.01464991250585
            ],
            [
              105.82135128571895,
              21.01458006443701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1036",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014996453744192,
          "to_latitude": 21.01458006443701,
          "to_longitude": 105.82135128571895,
          "from_longitude": 105.82111347924037
        }
      },
      {
        "id": 1036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8217477835853,
            21.016034622022637,
            105.82192429578588,
            21.016290697588317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82192429578588,
              21.016290697588317
            ],
            [
              105.82189528613262,
              21.016250962992135
            ],
            [
              105.82188303869347,
              21.016234288983085
            ],
            [
              105.8218573042669,
              21.016199256109775
            ],
            [
              105.8218052436049,
              21.016130022370938
            ],
            [
              105.82177793606203,
              21.016092989210243
            ],
            [
              105.82177515656633,
              21.01608922082603
            ],
            [
              105.82177501651636,
              21.016088941502254
            ],
            [
              105.82177424818994,
              21.016087409728154
            ],
            [
              105.8217477835853,
              21.016034622022637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A84_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1037",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016290697588317,
          "to_latitude": 21.016034622022637,
          "to_longitude": 105.8217477835853,
          "from_longitude": 105.82192429578588
        }
      },
      {
        "id": 1037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82099873841834,
            21.01528094499827,
            105.82143770833622,
            21.01573389075318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82143770833622,
              21.01573389075318
            ],
            [
              105.82139453511375,
              21.01568480740538
            ],
            [
              105.82128583806026,
              21.015571699005076
            ],
            [
              105.82123517880379,
              21.015527651111874
            ],
            [
              105.82122080925869,
              21.015515156588904
            ],
            [
              105.82117353208581,
              21.015463178276605
            ],
            [
              105.8211191400746,
              21.01540533170143
            ],
            [
              105.82108742026463,
              21.015373891720426
            ],
            [
              105.8210343626752,
              21.0153178297745
            ],
            [
              105.82099873841834,
              21.01528094499827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1038",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01573389075318,
          "to_latitude": 21.01528094499827,
          "to_longitude": 105.82099873841834,
          "from_longitude": 105.82143770833622
        }
      },
      {
        "id": 1038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82167165573519,
            21.01594692620223,
            105.82168190269046,
            21.015955030456894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82168190269046,
              21.01594692620223
            ],
            [
              105.82167695512487,
              21.015951436425237
            ],
            [
              105.82167300873041,
              21.015955030456894
            ],
            [
              105.82167165573519,
              21.01595388447422
            ],
            [
              105.82167555839023,
              21.015950032330963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A82_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1039",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01594692620223,
          "to_latitude": 21.015950032330963,
          "to_longitude": 105.82167555839023,
          "from_longitude": 105.82168190269046
        }
      },
      {
        "id": 1039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149519429205,
            21.015786098740552,
            105.8215049308473,
            21.01579474176501
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8215049308473,
              21.015786098740552
            ],
            [
              105.82150008602542,
              21.01579039981699
            ],
            [
              105.82149807381111,
              21.015792186180487
            ],
            [
              105.82149606351079,
              21.015793970728275
            ],
            [
              105.82149519429205,
              21.01579474176501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B81_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1040",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015786098740552,
          "to_latitude": 21.01579474176501,
          "to_longitude": 105.82149519429205,
          "from_longitude": 105.8215049308473
        }
      },
      {
        "id": 1040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82123517880379,
            21.015516612866968,
            105.82124769667442,
            21.015527651111874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82124769667442,
              21.015516612866968
            ],
            [
              105.82124187523188,
              21.01552174600627
            ],
            [
              105.82123659658753,
              21.015526400524667
            ],
            [
              105.82123517880379,
              21.015527651111874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1041",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015516612866968,
          "to_latitude": 21.015527651111874,
          "to_longitude": 105.82123517880379,
          "from_longitude": 105.82124769667442
        }
      },
      {
        "id": 1041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82124467680123,
            21.014840999270948,
            105.8212791076232,
            21.014859329349665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82124467680123,
              21.014840999270948
            ],
            [
              105.82125200501402,
              21.014844901063448
            ],
            [
              105.82126877423657,
              21.014853829804856
            ],
            [
              105.8212791076232,
              21.014859329349665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1042",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014840999270948,
          "to_latitude": 21.014859329349665,
          "to_longitude": 105.8212791076232,
          "from_longitude": 105.82124467680123
        }
      },
      {
        "id": 1042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82132952790121,
            21.014564378231395,
            105.82151756888494,
            21.01479793297243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82132952790121,
              21.01479793297243
            ],
            [
              105.82139794488805,
              21.01470086526421
            ],
            [
              105.82140419108295,
              21.014693026711246
            ],
            [
              105.82147393482845,
              21.014605507806102
            ],
            [
              105.82151756888494,
              21.014564378231395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1043",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01479793297243,
          "to_latitude": 21.014564378231395,
          "to_longitude": 105.82151756888494,
          "from_longitude": 105.82132952790121
        }
      },
      {
        "id": 1043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82138979675685,
            21.01468512884097,
            105.82140419108295,
            21.014693026711246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82138979675685,
              21.01468512884097
            ],
            [
              105.82139470774821,
              21.014687822941138
            ],
            [
              105.82140008958983,
              21.014690777606962
            ],
            [
              105.82140419108295,
              21.014693026711246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1044",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01468512884097,
          "to_latitude": 21.014693026711246,
          "to_longitude": 105.82140419108295,
          "from_longitude": 105.82138979675685
        }
      },
      {
        "id": 1044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82069470098317,
            21.01522648861437,
            105.82104352100014,
            21.015478739133396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104352100014,
              21.015247483812775
            ],
            [
              105.82098786684435,
              21.015257437689225
            ],
            [
              105.82098173848111,
              21.0152581654566
            ],
            [
              105.82094949606544,
              21.01522648861437
            ],
            [
              105.8209102945626,
              21.01526310220502
            ],
            [
              105.82069470098317,
              21.015478739133396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1045",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015247483812775,
          "to_latitude": 21.015478739133396,
          "to_longitude": 105.82069470098317,
          "from_longitude": 105.82104352100014
        }
      },
      {
        "id": 1045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82177501651636,
            21.016084716261414,
            105.82178154996284,
            21.016088941502254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82178154996284,
              21.016084716261414
            ],
            [
              105.82177887753707,
              21.01608644439787
            ],
            [
              105.82177657990431,
              21.0160879304656
            ],
            [
              105.82177501651636,
              21.016088941502254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A84_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1046",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016084716261414,
          "to_latitude": 21.016088941502254,
          "to_longitude": 105.82177501651636,
          "from_longitude": 105.82178154996284
        }
      },
      {
        "id": 1046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82148000067613,
            21.015744374428944,
            105.82157375903019,
            21.015834996748595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82157375903019,
              21.015834996748595
            ],
            [
              105.82157229434087,
              21.015833830530223
            ],
            [
              105.82152375374815,
              21.015795169277883
            ],
            [
              105.82151791720715,
              21.015790520553274
            ],
            [
              105.82150359350818,
              21.015776043251723
            ],
            [
              105.82149502327924,
              21.015767381141448
            ],
            [
              105.82148000067613,
              21.015744374428944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B82_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1047",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015834996748595,
          "to_latitude": 21.015744374428944,
          "to_longitude": 105.82148000067613,
          "from_longitude": 105.82157375903019
        }
      },
      {
        "id": 1047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82103389943076,
            21.01476621025679,
            105.82114496094512,
            21.01496824666936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82112622559036,
              21.01476621025679
            ],
            [
              105.82110041493472,
              21.014798358737607
            ],
            [
              105.82103389943076,
              21.014879253908948
            ],
            [
              105.82112445392785,
              21.0149518147852
            ],
            [
              105.82114496094512,
              21.01496824666936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1048",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01476621025679,
          "to_latitude": 21.01496824666936,
          "to_longitude": 105.82114496094512,
          "from_longitude": 105.82112622559036
        }
      },
      {
        "id": 1048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82144611474693,
            21.015747067985092,
            105.82174375475229,
            21.016029840832655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82174375475229,
              21.016029840832655
            ],
            [
              105.8217138161778,
              21.015996115339792
            ],
            [
              105.82169314034248,
              21.01597541931056
            ],
            [
              105.82167609302837,
              21.015959302274577
            ],
            [
              105.8216637108511,
              21.01594769961355
            ],
            [
              105.82163748376665,
              21.01592403619115
            ],
            [
              105.8216143855531,
              21.0159048783038
            ],
            [
              105.82160979156276,
              21.015900531582147
            ],
            [
              105.82154326241586,
              21.01583759086017
            ],
            [
              105.82150106326206,
              21.015800393818797
            ],
            [
              105.82149519429205,
              21.01579474176501
            ],
            [
              105.82147279086831,
              21.01577316708014
            ],
            [
              105.82144611474693,
              21.015747067985092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B81_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1049",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016029840832655,
          "to_latitude": 21.015747067985092,
          "to_longitude": 105.82144611474693,
          "from_longitude": 105.82174375475229
        }
      },
      {
        "id": 1049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82104324455139,
            21.015571699005076,
            105.82128583806026,
            21.015728264147384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104324455139,
              21.015649325378426
            ],
            [
              105.8211264525218,
              21.015728264147384
            ],
            [
              105.8211682956178,
              21.015688320204394
            ],
            [
              105.82122473691027,
              21.01563024847436
            ],
            [
              105.82128583806026,
              21.015571699005076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1050",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015649325378426,
          "to_latitude": 21.015571699005076,
          "to_longitude": 105.82128583806026,
          "from_longitude": 105.82104324455139
        }
      },
      {
        "id": 1050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82236315714614,
            21.013694988047092,
            105.82354077009472,
            21.01443392772773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82236315714614,
              21.013817413463414
            ],
            [
              105.82246843533567,
              21.013708649227247
            ],
            [
              105.82253429053883,
              21.013696857178655
            ],
            [
              105.82260666525163,
              21.013694988047092
            ],
            [
              105.8228177935792,
              21.013872623597077
            ],
            [
              105.82294752940587,
              21.01397742436999
            ],
            [
              105.82301393226867,
              21.014029146194506
            ],
            [
              105.82309928053715,
              21.014095625385465
            ],
            [
              105.82310535704202,
              21.01410035870283
            ],
            [
              105.82315263481826,
              21.014150306023012
            ],
            [
              105.8232266762361,
              21.014208813777024
            ],
            [
              105.82325964699511,
              21.0142348676866
            ],
            [
              105.82327437355366,
              21.014246504051435
            ],
            [
              105.82330310396662,
              21.014264288656236
            ],
            [
              105.82337251097938,
              21.014305633075686
            ],
            [
              105.8233736700442,
              21.01430632295096
            ],
            [
              105.82354077009472,
              21.01443392772773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1051",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 157.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013817413463414,
          "to_latitude": 21.01443392772773,
          "to_longitude": 105.82354077009472,
          "from_longitude": 105.82236315714614
        }
      },
      {
        "id": 1051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82265139277068,
            21.014528306490153,
            105.82315882850389,
            21.01492681793786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82315882850389,
              21.01492681793786
            ],
            [
              105.82315611565235,
              21.01492502912637
            ],
            [
              105.82302948496515,
              21.01484144929136
            ],
            [
              105.82292450292664,
              21.014768952915034
            ],
            [
              105.82290625752253,
              21.01473643829558
            ],
            [
              105.82290060157636,
              21.01471911918394
            ],
            [
              105.82285468922541,
              21.014686456567368
            ],
            [
              105.82278463060126,
              21.014634878822438
            ],
            [
              105.82275806731346,
              21.014615323262277
            ],
            [
              105.82269265857752,
              21.01456634776765
            ],
            [
              105.82265139277068,
              21.014528306490153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1052",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01492681793786,
          "to_latitude": 21.014528306490153,
          "to_longitude": 105.82265139277068,
          "from_longitude": 105.82315882850389
        }
      },
      {
        "id": 1052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8228458035023,
            21.014820891542755,
            105.82302948496515,
            21.014865072198564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82302948496515,
              21.01484144929136
            ],
            [
              105.82291242506668,
              21.014865072198564
            ],
            [
              105.8228458035023,
              21.014820891542755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1053",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484144929136,
          "to_latitude": 21.014820891542755,
          "to_longitude": 105.8228458035023,
          "from_longitude": 105.82302948496515
        }
      },
      {
        "id": 1053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8230372128594,
            21.01427659297425,
            105.8233983621666,
            21.014820710856057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8230372128594,
              21.014820710856057
            ],
            [
              105.82311644890049,
              21.014718623306244
            ],
            [
              105.82316935062678,
              21.014609284678606
            ],
            [
              105.82324450614384,
              21.014507056040692
            ],
            [
              105.8232645922687,
              21.01443765517189
            ],
            [
              105.82336524223683,
              21.014313797291912
            ],
            [
              105.82337251097938,
              21.014305633075686
            ],
            [
              105.8233983621666,
              21.01427659297425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A91_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1054",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014820710856057,
          "to_latitude": 21.01427659297425,
          "to_longitude": 105.8233983621666,
          "from_longitude": 105.8230372128594
        }
      },
      {
        "id": 1054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82292439749641,
            21.014192587229378,
            105.82328269268383,
            21.014708705496947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82292439749641,
              21.014708705496947
            ],
            [
              105.8230029353653,
              21.01462558371571
            ],
            [
              105.82293107708793,
              21.014567368794964
            ],
            [
              105.82298632875694,
              21.014498329860317
            ],
            [
              105.8229865831076,
              21.014498052242594
            ],
            [
              105.82307287278184,
              21.01440717034592
            ],
            [
              105.82313076916914,
              21.01439642095029
            ],
            [
              105.82326358371452,
              21.014250208006967
            ],
            [
              105.82325964699511,
              21.0142348676866
            ],
            [
              105.82325777055404,
              21.01422755792065
            ],
            [
              105.82328260009298,
              21.014192717742073
            ],
            [
              105.82328269268383,
              21.014192587229378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A92_Ngõ 88, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1055",
          "diaChiLapD": "Ngõ 88, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014708705496947,
          "to_latitude": 21.014192587229378,
          "to_longitude": 105.82328269268383,
          "from_longitude": 105.82292439749641
        }
      },
      {
        "id": 1055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82277241900499,
            21.014433468151772,
            105.8229865831076,
            21.014570716652933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82277241900499,
              21.014570716652933
            ],
            [
              105.82287011256616,
              21.01446949195393
            ],
            [
              105.82290269179215,
              21.014433468151772
            ],
            [
              105.8229865831076,
              21.014498052242594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A92_Ngõ 88, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1056",
          "diaChiLapD": "Ngõ 88, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014570716652933,
          "to_latitude": 21.014498052242594,
          "to_longitude": 105.8229865831076,
          "from_longitude": 105.82277241900499
        }
      },
      {
        "id": 1056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82294565529115,
            21.014076680296004,
            105.823129880388,
            21.014371585035896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.823129880388,
              21.014076680296004
            ],
            [
              105.82312985738163,
              21.014076694858936
            ],
            [
              105.82309928053715,
              21.014095625385465
            ],
            [
              105.8230939014262,
              21.014098955411715
            ],
            [
              105.82304871103835,
              21.014155070211007
            ],
            [
              105.82297588506306,
              21.014237609800116
            ],
            [
              105.82294565529115,
              21.01427422560672
            ],
            [
              105.82298413082671,
              21.014311793471634
            ],
            [
              105.8230614806534,
              21.014371585035896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A93_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1057",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014076680296004,
          "to_latitude": 21.014371585035896,
          "to_longitude": 105.8230614806534,
          "from_longitude": 105.823129880388
        }
      },
      {
        "id": 1057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82260880897458,
            21.014634878822438,
            105.82278463060126,
            21.01482824727252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82278463060126,
              21.014634878822438
            ],
            [
              105.82260880897458,
              21.01482824727252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A96_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1058",
          "diaChiLapD": "Ngõ 84, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014634878822438,
          "to_latitude": 21.01482824727252,
          "to_longitude": 105.82260880897458,
          "from_longitude": 105.82278463060126
        }
      },
      {
        "id": 1058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8232266762361,
            21.014174131025054,
            105.82325817185193,
            21.014208813777024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82325817185193,
              21.014174131025054
            ],
            [
              105.82325815272294,
              21.014174150988598
            ],
            [
              105.8232440208141,
              21.014189713319002
            ],
            [
              105.82323313598874,
              21.014201699417423
            ],
            [
              105.8232266762361,
              21.014208813777024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A94_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1059",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014174131025054,
          "to_latitude": 21.014208813777024,
          "to_longitude": 105.8232266762361,
          "from_longitude": 105.82325817185193
        }
      },
      {
        "id": 1059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82391147991245,
            21.020769316932075,
            105.82471887611788,
            21.02108403991711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82391147991245,
              21.020798365606307
            ],
            [
              105.8239265815173,
              21.02081177581174
            ],
            [
              105.82397486837107,
              21.020854791070718
            ],
            [
              105.82400783761358,
              21.02089585277854
            ],
            [
              105.82412024901802,
              21.02108403991711
            ],
            [
              105.82413401068561,
              21.021076804336587
            ],
            [
              105.82471887611788,
              21.020769316932075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT135_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1060",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 109.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020798365606307,
          "to_latitude": 21.020769316932075,
          "to_longitude": 105.82471887611788,
          "from_longitude": 105.82391147991245
        }
      },
      {
        "id": 1060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82371937514284,
            21.020394211748652,
            105.8241730620675,
            21.020827170857114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82371937514284,
              21.020712384914308
            ],
            [
              105.82378905622501,
              21.0207788780437
            ],
            [
              105.82382891629885,
              21.020820168350753
            ],
            [
              105.82384182595405,
              21.020827170857114
            ],
            [
              105.82386318787158,
              21.02081410609965
            ],
            [
              105.82393256208088,
              21.020745593676637
            ],
            [
              105.82399937034697,
              21.020636936234805
            ],
            [
              105.82402068007282,
              21.020603352225997
            ],
            [
              105.824029289239,
              21.020591537873276
            ],
            [
              105.82403915487296,
              21.020577997691074
            ],
            [
              105.8241730620675,
              21.020394211748652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT111_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1061",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020712384914308,
          "to_latitude": 21.020394211748652,
          "to_longitude": 105.8241730620675,
          "from_longitude": 105.82371937514284
        }
      },
      {
        "id": 1061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82689058672689,
            21.02047124895925,
            105.82725295700004,
            21.020644238076315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82689058672689,
              21.020644238076315
            ],
            [
              105.82693096740172,
              21.02063029196845
            ],
            [
              105.8269392360342,
              21.020626719255436
            ],
            [
              105.82700546662583,
              21.020598097398935
            ],
            [
              105.82702177692256,
              21.020589917069508
            ],
            [
              105.82702699176903,
              21.02058730216734
            ],
            [
              105.82705252493179,
              21.020574496736426
            ],
            [
              105.8271097292812,
              21.02054743795305
            ],
            [
              105.8271767706679,
              21.020511652221636
            ],
            [
              105.82718567535383,
              21.0205070702108
            ],
            [
              105.82725295700004,
              21.02047124895925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.2_La Thành",
          "maTaiSan": "04.O12.DODV.1062",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020644238076315,
          "to_latitude": 21.02047124895925,
          "to_longitude": 105.82725295700004,
          "from_longitude": 105.82689058672689
        }
      },
      {
        "id": 1062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82759578312691,
            21.02013638804023,
            105.82784910293402,
            21.020276654193665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82759578312691,
              21.020276654193665
            ],
            [
              105.82760525944283,
              21.0202715942838
            ],
            [
              105.82763582444845,
              21.020253540933656
            ],
            [
              105.82763589157501,
              21.020253502671586
            ],
            [
              105.82768334465847,
              21.020225475921148
            ],
            [
              105.82770455968655,
              21.020213588309375
            ],
            [
              105.82770727174652,
              21.020212069435868
            ],
            [
              105.82771501088385,
              21.02020773242441
            ],
            [
              105.82772288810706,
              21.02020366480504
            ],
            [
              105.82778595286756,
              21.020171097415144
            ],
            [
              105.82780099287197,
              21.02016266938865
            ],
            [
              105.82784910293402,
              21.02013638804023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C10_La Thành",
          "maTaiSan": "04.O12.DODV.1063",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020276654193665,
          "to_latitude": 21.02013638804023,
          "to_longitude": 105.82784910293402,
          "from_longitude": 105.82759578312691
        }
      },
      {
        "id": 1063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865699097526,
            21.01932299609591,
            105.82922942642595,
            21.019673237417074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82865699097526,
              21.019673237417074
            ],
            [
              105.82870872130074,
              21.01963959858873
            ],
            [
              105.82878100252803,
              21.01959282246553
            ],
            [
              105.82878212140002,
              21.019592145917837
            ],
            [
              105.82878450296532,
              21.019590707218498
            ],
            [
              105.82878420170496,
              21.019590155009297
            ],
            [
              105.82878400507111,
              21.01958956164519
            ],
            [
              105.82878392182609,
              21.019588946051208
            ],
            [
              105.82878395206963,
              21.0195883262914
            ],
            [
              105.82878409493969,
              21.019587720434437
            ],
            [
              105.82878434765034,
              21.01958714655841
            ],
            [
              105.82878470260583,
              21.019586622764834
            ],
            [
              105.82878514738087,
              21.019586163565847
            ],
            [
              105.82878531218013,
              21.01958604444041
            ],
            [
              105.82878567051706,
              21.0195857843721
            ],
            [
              105.82878625474949,
              21.019585494299967
            ],
            [
              105.82878688378506,
              21.019585304267533
            ],
            [
              105.82878753840504,
              21.019585217981334
            ],
            [
              105.8287881993906,
              21.019585239147897
            ],
            [
              105.8287888465362,
              21.019585366962488
            ],
            [
              105.82878946058297,
              21.019585597905987
            ],
            [
              105.82879002225235,
              21.019585924846563
            ],
            [
              105.8287905160934,
              21.019586337020776
            ],
            [
              105.82879092665524,
              21.01958682366521
            ],
            [
              105.82884431138875,
              21.019554557767478
            ],
            [
              105.82885580073582,
              21.019547882950178
            ],
            [
              105.82888009253234,
              21.019534190011836
            ],
            [
              105.8288860717373,
              21.019530818943156
            ],
            [
              105.82891578460698,
              21.019514069263376
            ],
            [
              105.82891754624794,
              21.019513077066684
            ],
            [
              105.82896578236951,
              21.01948826976419
            ],
            [
              105.82897467352542,
              21.01948369766382
            ],
            [
              105.829016133143,
              21.019460728015428
            ],
            [
              105.82906984141124,
              21.019430972341404
            ],
            [
              105.82910002883834,
              21.019409414256526
            ],
            [
              105.82910187141565,
              21.019408098308894
            ],
            [
              105.82910444508381,
              21.019406260346614
            ],
            [
              105.82913349801824,
              21.019385512559218
            ],
            [
              105.82915080750833,
              21.019373150648157
            ],
            [
              105.82918625186588,
              21.019349822796514
            ],
            [
              105.82922942642595,
              21.01932299609591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C20_La Thành",
          "maTaiSan": "04.O12.DODV.1064",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019673237417074,
          "to_latitude": 21.01932299609591,
          "to_longitude": 105.82922942642595,
          "from_longitude": 105.82865699097526
        }
      },
      {
        "id": 1064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82952347915433,
            21.018993335812105,
            105.82960633876922,
            21.019133722840426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82952347915433,
              21.018993335812105
            ],
            [
              105.82958214996559,
              21.019096103160575
            ],
            [
              105.82958522389725,
              21.01910088339137
            ],
            [
              105.82959971351985,
              21.01912342135359
            ],
            [
              105.82960633876922,
              21.019133722840426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C23_La Thành",
          "maTaiSan": "04.O12.DODV.1065",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018993335812105,
          "to_latitude": 21.019133722840426,
          "to_longitude": 105.82960633876922,
          "from_longitude": 105.82952347915433
        }
      },
      {
        "id": 1065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925983946214,
            21.01904834019386,
            105.82967076118898,
            21.019300842561726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925983946214,
              21.019300842561726
            ],
            [
              105.82930388884772,
              21.01927235416002
            ],
            [
              105.82934612335121,
              21.019246539109783
            ],
            [
              105.8294546345511,
              21.01917942246978
            ],
            [
              105.82954760169417,
              21.01912384710562
            ],
            [
              105.82958187028422,
              21.019102930196443
            ],
            [
              105.82958522389725,
              21.01910088339137
            ],
            [
              105.82958726118176,
              21.019099639650335
            ],
            [
              105.82961473817099,
              21.019082868240243
            ],
            [
              105.82967076118898,
              21.01904834019386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C24_La Thành",
          "maTaiSan": "04.O12.DODV.1066",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019300842561726,
          "to_latitude": 21.01904834019386,
          "to_longitude": 105.82967076118898,
          "from_longitude": 105.82925983946214
        }
      },
      {
        "id": 1066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82214928841562,
            21.022739992913852,
            105.8221634310847,
            21.02277605539486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82214928841562,
              21.022739992913852
            ],
            [
              105.82215714414903,
              21.022760023718043
            ],
            [
              105.82216184766725,
              21.02277202201602
            ],
            [
              105.8221634310847,
              21.02277605539486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_La Thành",
          "maTaiSan": "04.O12.DODV.1067",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022739992913852,
          "to_latitude": 21.02277605539486,
          "to_longitude": 105.8221634310847,
          "from_longitude": 105.82214928841562
        }
      },
      {
        "id": 1067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81840450487202,
            21.020496895762978,
            105.8185683642809,
            21.02080989255528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8185683642809,
              21.020496895762978
            ],
            [
              105.81856451529441,
              21.02049880835225
            ],
            [
              105.81840450487202,
              21.020578293615152
            ],
            [
              105.81851603787476,
              21.02080989255528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT07_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1068",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020496895762978,
          "to_latitude": 21.02080989255528,
          "to_longitude": 105.81851603787476,
          "from_longitude": 105.8185683642809
        }
      },
      {
        "id": 1068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81834089005655,
            21.020541868154503,
            105.81840450487202,
            21.020578293615152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81840450487202,
              21.020578293615152
            ],
            [
              105.81834089005655,
              21.020541868154503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1069",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020578293615152,
          "to_latitude": 21.020541868154503,
          "to_longitude": 105.81834089005655,
          "from_longitude": 105.81840450487202
        }
      },
      {
        "id": 1069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81788183082914,
            21.018807878687117,
            105.81817095923604,
            21.019190816828015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81817095923604,
              21.019190816828015
            ],
            [
              105.8181663430607,
              21.01918293398798
            ],
            [
              105.81816204515826,
              21.01917837620208
            ],
            [
              105.818145175793,
              21.019160494862653
            ],
            [
              105.81788183082914,
              21.018807878687117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1070",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019190816828015,
          "to_latitude": 21.018807878687117,
          "to_longitude": 105.81788183082914,
          "from_longitude": 105.81817095923604
        }
      },
      {
        "id": 1070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81623349918712,
            21.016220108822164,
            105.81640050733942,
            21.016438953446563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81640050733942,
              21.016438953446563
            ],
            [
              105.81639213063998,
              21.01643045543456
            ],
            [
              105.8163715216998,
              21.016403549633043
            ],
            [
              105.81636438068115,
              21.016392676523903
            ],
            [
              105.81635857163808,
              21.016385102835823
            ],
            [
              105.81634165701055,
              21.016363048602667
            ],
            [
              105.8163121113339,
              21.016323363456685
            ],
            [
              105.8162475624905,
              21.01623949506494
            ],
            [
              105.81623349918712,
              21.016220108822164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B19_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1071",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016438953446563,
          "to_latitude": 21.016220108822164,
          "to_longitude": 105.81623349918712,
          "from_longitude": 105.81640050733942
        }
      },
      {
        "id": 1071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81628443084061,
            21.0162539757474,
            105.81641275295965,
            21.016440554813435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81641275295965,
              21.016440554813435
            ],
            [
              105.81640652739777,
              21.01643368944997
            ],
            [
              105.8163917029259,
              21.01641373061533
            ],
            [
              105.81636774810785,
              21.01638147931038
            ],
            [
              105.81634763570166,
              21.016354072541183
            ],
            [
              105.81634118519209,
              21.01634581909043
            ],
            [
              105.81628443084061,
              21.0162539757474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B25_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1072",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016440554813435,
          "to_latitude": 21.0162539757474,
          "to_longitude": 105.81628443084061,
          "from_longitude": 105.81641275295965
        }
      },
      {
        "id": 1072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81771061676046,
            21.015743392342966,
            105.81788000834452,
            21.016008167943045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81783238763813,
              21.016008167943045
            ],
            [
              105.81788000834452,
              21.015962257386114
            ],
            [
              105.81773665569365,
              21.015826352309645
            ],
            [
              105.8177588235931,
              21.015803762618987
            ],
            [
              105.81771061676046,
              21.015763154900707
            ],
            [
              105.81773277890512,
              21.015743392342966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1073",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016008167943045,
          "to_latitude": 21.015743392342966,
          "to_longitude": 105.81773277890512,
          "from_longitude": 105.81783238763813
        }
      },
      {
        "id": 1073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81892094947088,
            21.014498909770243,
            105.8190316983678,
            21.01458547528146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81892094947088,
              21.01458547528146
            ],
            [
              105.8190316983678,
              21.014498909770243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B10_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.1074",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01458547528146,
          "to_latitude": 21.014498909770243,
          "to_longitude": 105.8190316983678,
          "from_longitude": 105.81892094947088
        }
      },
      {
        "id": 1074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81762549331371,
            21.015075907127976,
            105.81763364809538,
            21.01508640137681
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81763364809538,
              21.01508640137681
            ],
            [
              105.81762960628525,
              21.01508115227499
            ],
            [
              105.81762549331371,
              21.015075907127976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B13_Thái Hà",
          "maTaiSan": "04.O12.DODV.1075",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01508640137681,
          "to_latitude": 21.015075907127976,
          "to_longitude": 105.81762549331371,
          "from_longitude": 105.81763364809538
        }
      },
      {
        "id": 1075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81726342136184,
            21.015343181243868,
            105.81738937110217,
            21.015543770099875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81726342136184,
              21.015343181243868
            ],
            [
              105.81738937110217,
              21.015543770099875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13_Thái Hà",
          "maTaiSan": "04.O12.DODV.1076",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015343181243868,
          "to_latitude": 21.015543770099875,
          "to_longitude": 105.81738937110217,
          "from_longitude": 105.81726342136184
        }
      },
      {
        "id": 1076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81603670497131,
            21.01539755736558,
            105.81716519832021,
            21.01617948635714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81603670497131,
              21.01617948635714
            ],
            [
              105.81618374175612,
              21.016078699769427
            ],
            [
              105.81618339826869,
              21.0160781558678
            ],
            [
              105.81618315937475,
              21.016077564496566
            ],
            [
              105.81618303382977,
              21.01607694367823
            ],
            [
              105.81618302365604,
              21.016076311467675
            ],
            [
              105.81618312992343,
              21.016075687730776
            ],
            [
              105.81618334984456,
              21.016075090545453
            ],
            [
              105.8161836767794,
              21.01607453710487
            ],
            [
              105.81618409928319,
              21.0160740455285
            ],
            [
              105.81618454939895,
              21.016073678465087
            ],
            [
              105.816184618426,
              21.01607363568224
            ],
            [
              105.81618467692607,
              21.016073602885374
            ],
            [
              105.81618480927752,
              21.016073529992205
            ],
            [
              105.81618491572601,
              21.016073469868488
            ],
            [
              105.81618496655037,
              21.016073440721392
            ],
            [
              105.81618518320161,
              21.016073303294622
            ],
            [
              105.8161858110671,
              21.0160730744755
            ],
            [
              105.81618647124597,
              21.016072950275763
            ],
            [
              105.81618714356742,
              21.01607293621154
            ],
            [
              105.8161878097453,
              21.016073030564122
            ],
            [
              105.81618844765045,
              21.01607323253642
            ],
            [
              105.8161890389623,
              21.0160735340873
            ],
            [
              105.8161895663221,
              21.016073927170982
            ],
            [
              105.81619001331323,
              21.016074400124268
            ],
            [
              105.81716519832021,
              21.015405945044684
            ],
            [
              105.81716149273657,
              21.01540114141441
            ],
            [
              105.81715868070434,
              21.01539755736558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B12_Thái Hà",
          "maTaiSan": "04.O12.DODV.1077",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 146.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01617948635714,
          "to_latitude": 21.01539755736558,
          "to_longitude": 105.81715868070434,
          "from_longitude": 105.81603670497131
        }
      },
      {
        "id": 1077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81648430288514,
            21.016110374064564,
            105.81668754253023,
            21.016273115236054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648430288514,
              21.016273115236054
            ],
            [
              105.81648433262592,
              21.016273100641676
            ],
            [
              105.81649513378228,
              21.01626364078458
            ],
            [
              105.81651387836683,
              21.016267554829028
            ],
            [
              105.8165553017947,
              21.0162371990501
            ],
            [
              105.81658199893208,
              21.016235489357463
            ],
            [
              105.81668754253023,
              21.016170845441387
            ],
            [
              105.81662927352146,
              21.016110374064564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28.2_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1078",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016273115236054,
          "to_latitude": 21.016110374064564,
          "to_longitude": 105.81662927352146,
          "from_longitude": 105.81648430288514
        }
      },
      {
        "id": 1078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81668754253023,
            21.016170845441387,
            105.81686203677405,
            21.016292125861636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81668754253023,
              21.016170845441387
            ],
            [
              105.8167620351909,
              21.01624958978248
            ],
            [
              105.81678749083909,
              21.016250423184065
            ],
            [
              105.81682395496914,
              21.016262914072016
            ],
            [
              105.81686203677405,
              21.016292125861636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28.2_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1079",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016170845441387,
          "to_latitude": 21.016292125861636,
          "to_longitude": 105.81686203677405,
          "from_longitude": 105.81668754253023
        }
      },
      {
        "id": 1079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81648104386376,
            21.016170931873674,
            105.81658241606002,
            21.016269059127133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648104386376,
              21.016269059127133
            ],
            [
              105.81648106880975,
              21.016269047265464
            ],
            [
              105.81648110620657,
              21.016269025408516
            ],
            [
              105.81648684204815,
              21.016265802261188
            ],
            [
              105.81649452661337,
              21.016261486791134
            ],
            [
              105.81650863457469,
              21.01626274050307
            ],
            [
              105.81654392248392,
              21.01623728799
            ],
            [
              105.81652370940574,
              21.016211534631204
            ],
            [
              105.81658241606002,
              21.016170931873674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28.1_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1080",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016269059127133,
          "to_latitude": 21.016170931873674,
          "to_longitude": 105.81658241606002,
          "from_longitude": 105.81648104386376
        }
      },
      {
        "id": 1080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637198043221,
            21.016178552794425,
            105.81648684204815,
            21.016265802261188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648684204815,
              21.016265802261188
            ],
            [
              105.81643261575742,
              21.01621197813702
            ],
            [
              105.81641948633111,
              21.01619963350703
            ],
            [
              105.81638623071073,
              21.016178552794425
            ],
            [
              105.81637198043221,
              21.016185259900006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28.1_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1081",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016265802261188,
          "to_latitude": 21.016185259900006,
          "to_longitude": 105.81637198043221,
          "from_longitude": 105.81648684204815
        }
      },
      {
        "id": 1081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816290099644,
            21.016162783073327,
            105.81630068893317,
            21.01618350288651
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81630068893317,
              21.01618350288651
            ],
            [
              105.81630025435962,
              21.01618266045387
            ],
            [
              105.81629482570102,
              21.016171979641335
            ],
            [
              105.816290099644,
              21.016162783073327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1082",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01618350288651,
          "to_latitude": 21.016162783073327,
          "to_longitude": 105.816290099644,
          "from_longitude": 105.81630068893317
        }
      },
      {
        "id": 1082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81654826305079,
            21.016451047856645,
            105.81670475754498,
            21.01660168696241
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81654826305079,
              21.01660168696241
            ],
            [
              105.81667725538442,
              21.0164775207073
            ],
            [
              105.81670475754498,
              21.016451047856645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B27_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1083",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01660168696241,
          "to_latitude": 21.016451047856645,
          "to_longitude": 105.81670475754498,
          "from_longitude": 105.81654826305079
        }
      },
      {
        "id": 1083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81635857163808,
            21.016382024951263,
            105.81636377632198,
            21.016385102835823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81635857163808,
              21.016385102835823
            ],
            [
              105.81636093234812,
              21.016383706859788
            ],
            [
              105.81636355770233,
              21.016382154258654
            ],
            [
              105.81636374755777,
              21.016382042250605
            ],
            [
              105.81636377632198,
              21.016382024951263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B19_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1084",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016385102835823,
          "to_latitude": 21.016382024951263,
          "to_longitude": 105.81636377632198,
          "from_longitude": 105.81635857163808
        }
      },
      {
        "id": 1084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816769226178,
            21.01647793275602,
            105.8167752404145,
            21.01648355712516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816769226178,
              21.01648355712516
            ],
            [
              105.81676925591886,
              21.016483542530736
            ],
            [
              105.81676927409204,
              21.0164835234757
            ],
            [
              105.81676930950113,
              21.016483489886294
            ],
            [
              105.8167694472602,
              21.016483350127867
            ],
            [
              105.8167720696367,
              21.016480897920196
            ],
            [
              105.81677469201321,
              21.016478445712487
            ],
            [
              105.8167752404145,
              21.01647793275602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B28.4_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1085",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01648355712516,
          "to_latitude": 21.01647793275602,
          "to_longitude": 105.8167752404145,
          "from_longitude": 105.816769226178
        }
      },
      {
        "id": 1085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8166622435771,
            21.016421286659355,
            105.81666521442813,
            21.01642475305631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81666521442813,
              21.016421286659355
            ],
            [
              105.81666517523529,
              21.01642133200888
            ],
            [
              105.8166622435771,
              21.01642475305631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B28.3_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.1086",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016421286659355,
          "to_latitude": 21.01642475305631,
          "to_longitude": 105.8166622435771,
          "from_longitude": 105.81666521442813
        }
      },
      {
        "id": 1086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8193489920686,
            21.019089275470105,
            105.81993124418709,
            21.019198869287358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8193489920686,
              21.019089275470105
            ],
            [
              105.81946200541869,
              21.01912821473901
            ],
            [
              105.819566666389,
              21.019148018668407
            ],
            [
              105.81958747686994,
              21.019151956729655
            ],
            [
              105.81965692924342,
              21.019165099133545
            ],
            [
              105.8196731788356,
              21.019168818902443
            ],
            [
              105.81967628002857,
              21.01917195531713
            ],
            [
              105.81967989298965,
              21.01917456449099
            ],
            [
              105.8196834021963,
              21.019176318808974
            ],
            [
              105.81968391632542,
              21.01917657555763
            ],
            [
              105.8196876146811,
              21.019177736447347
            ],
            [
              105.81968823910216,
              21.01917793214834
            ],
            [
              105.81969274182735,
              21.019178596000117
            ],
            [
              105.81969729935044,
              21.019178549651507
            ],
            [
              105.8196995896019,
              21.019178164679474
            ],
            [
              105.81970178566256,
              21.01917779461292
            ],
            [
              105.81970607677749,
              21.019176350449587
            ],
            [
              105.8197489367624,
              21.019186162501462
            ],
            [
              105.81980533523455,
              21.019188903869207
            ],
            [
              105.81993124418709,
              21.019198869287358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1087",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019089275470105,
          "to_latitude": 21.019198869287358,
          "to_longitude": 105.81993124418709,
          "from_longitude": 105.8193489920686
        }
      },
      {
        "id": 1087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82103984842139,
            21.01898222781792,
            105.82144218140341,
            21.019214114640054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82106475370622,
              21.019214114640054
            ],
            [
              105.82105269409351,
              21.01918275592099
            ],
            [
              105.82104171910852,
              21.019155703969105
            ],
            [
              105.82103984842139,
              21.019143112988253
            ],
            [
              105.82104977424345,
              21.019136842752104
            ],
            [
              105.82111776037668,
              21.01910846489094
            ],
            [
              105.82112703173456,
              21.01910459497275
            ],
            [
              105.8212304512248,
              21.019058919041058
            ],
            [
              105.82125013714656,
              21.019049497284566
            ],
            [
              105.82140013955544,
              21.01898222781792
            ],
            [
              105.82144218140341,
              21.01906605918846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1088",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019214114640054,
          "to_latitude": 21.01906605918846,
          "to_longitude": 105.82144218140341,
          "from_longitude": 105.82106475370622
        }
      },
      {
        "id": 1088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8227280235498,
            21.020051581958153,
            105.82273157307644,
            21.020070669829387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82273157307644,
              21.020070669829387
            ],
            [
              105.82273034297977,
              21.020064054228225
            ],
            [
              105.82272928241467,
              21.020058356388027
            ],
            [
              105.82272822377857,
              21.020052659441724
            ],
            [
              105.8227280235498,
              21.020051581958153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT179_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1089",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020070669829387,
          "to_latitude": 21.020051581958153,
          "to_longitude": 105.8227280235498,
          "from_longitude": 105.82273157307644
        }
      },
      {
        "id": 1089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82225685637289,
            21.0187223222062,
            105.8222643559858,
            21.018742181322107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82225685637289,
              21.0187223222062
            ],
            [
              105.82225794378833,
              21.018725202758183
            ],
            [
              105.82226169703321,
              21.01873514539632
            ],
            [
              105.8222643559858,
              21.018742181322107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT173_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1090",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0187223222062,
          "to_latitude": 21.018742181322107,
          "to_longitude": 105.8222643559858,
          "from_longitude": 105.82225685637289
        }
      },
      {
        "id": 1090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.821214259245,
            21.02013571087903,
            105.8212228146778,
            21.02016375991521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8212228146778,
              21.02016375991521
            ],
            [
              105.82121439247298,
              21.02013614920385
            ],
            [
              105.821214259245,
              21.02013571087903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT159_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1091",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02016375991521,
          "to_latitude": 21.02013571087903,
          "to_longitude": 105.821214259245,
          "from_longitude": 105.8212228146778
        }
      },
      {
        "id": 1091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82486063603763,
            21.02157742025412,
            105.82487580250886,
            21.021603291858774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82486063603763,
              21.02157742025412
            ],
            [
              105.82486248405463,
              21.021580578904313
            ],
            [
              105.82486712776722,
              21.021588493931034
            ],
            [
              105.82487214442206,
              21.021597052955293
            ],
            [
              105.82487580250886,
              21.021603291858774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT106_La Thành",
          "maTaiSan": "04.O12.DODV.1092",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02157742025412,
          "to_latitude": 21.021603291858774,
          "to_longitude": 105.82487580250886,
          "from_longitude": 105.82486063603763
        }
      },
      {
        "id": 1092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82284212884329,
            21.018522885994297,
            105.82285053852753,
            21.018543269971374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82284212884329,
              21.018522885994297
            ],
            [
              105.82284391685248,
              21.018527220057457
            ],
            [
              105.822848025212,
              21.018537184448068
            ],
            [
              105.82285053852753,
              21.018543269971374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT126_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1093",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018522885994297,
          "to_latitude": 21.018543269971374,
          "to_longitude": 105.82285053852753,
          "from_longitude": 105.82284212884329
        }
      },
      {
        "id": 1093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82243198756024,
            21.018673534134823,
            105.82244738976219,
            21.018708493108395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243198756024,
              21.018673534134823
            ],
            [
              105.82243749115612,
              21.018686030759472
            ],
            [
              105.82244467986382,
              21.018702342593773
            ],
            [
              105.82244738976219,
              21.018708493108395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT125_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1094",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018673534134823,
          "to_latitude": 21.018708493108395,
          "to_longitude": 105.82244738976219,
          "from_longitude": 105.82243198756024
        }
      },
      {
        "id": 1094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82480771795996,
            21.020090014450886,
            105.82481071582727,
            21.0200949803013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82480771795996,
              21.020090014450886
            ],
            [
              105.8248084052937,
              21.02009115279543
            ],
            [
              105.82481004695416,
              21.020093875286612
            ],
            [
              105.82481071582727,
              21.0200949803013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT117_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1095",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020090014450886,
          "to_latitude": 21.0200949803013,
          "to_longitude": 105.82481071582727,
          "from_longitude": 105.82480771795996
        }
      },
      {
        "id": 1095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8234537834371,
            21.02111352448779,
            105.82346354428755,
            21.021132739429063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234537834371,
              21.02111352448779
            ],
            [
              105.82345979632169,
              21.02112536105692
            ],
            [
              105.82346250568746,
              21.02113069774728
            ],
            [
              105.82346354428755,
              21.021132739429063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1096",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02111352448779,
          "to_latitude": 21.021132739429063,
          "to_longitude": 105.82346354428755,
          "from_longitude": 105.8234537834371
        }
      },
      {
        "id": 1096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.824029289239,
            21.020591537873276,
            105.82406621256608,
            21.02061202209286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.824029289239,
              21.020591537873276
            ],
            [
              105.8240550707262,
              21.020605841103286
            ],
            [
              105.82406095862521,
              21.020609108449612
            ],
            [
              105.82406621256608,
              21.02061202209286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT111_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1097",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020591537873276,
          "to_latitude": 21.02061202209286,
          "to_longitude": 105.82406621256608,
          "from_longitude": 105.824029289239
        }
      },
      {
        "id": 1097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367419557075,
            21.021047303990983,
            105.82368810537443,
            21.02106142902069
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82368810537443,
              21.02106142902069
            ],
            [
              105.82368309246505,
              21.021056338327814
            ],
            [
              105.8236815204886,
              21.021054741810993
            ],
            [
              105.82367925588788,
              21.021052444132003
            ],
            [
              105.82367542219674,
              21.02104854992214
            ],
            [
              105.82367419557075,
              21.021047303990983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1098",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02106142902069,
          "to_latitude": 21.021047303990983,
          "to_longitude": 105.82367419557075,
          "from_longitude": 105.82368810537443
        }
      },
      {
        "id": 1098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82167085365629,
            21.02047494479466,
            105.82168161816428,
            21.02047932234462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82167085365629,
              21.02047932234462
            ],
            [
              105.82167160712487,
              21.020479016124106
            ],
            [
              105.82167875210773,
              21.02047611025307
            ],
            [
              105.82168161816428,
              21.02047494479466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1099",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02047932234462,
          "to_latitude": 21.02047494479466,
          "to_longitude": 105.82168161816428,
          "from_longitude": 105.82167085365629
        }
      },
      {
        "id": 1099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82206235240994,
            21.021287902402786,
            105.82207850485061,
            21.021297744343528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82206235240994,
              21.021297744343528
            ],
            [
              105.8220638126218,
              21.021296854834315
            ],
            [
              105.82206386343682,
              21.021296823879037
            ],
            [
              105.82207055183505,
              21.021292748713343
            ],
            [
              105.82207566304965,
              21.02128963407368
            ],
            [
              105.82207729200495,
              21.021288641684265
            ],
            [
              105.82207850485061,
              21.021287902402786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT130_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1100",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021297744343528,
          "to_latitude": 21.021287902402786,
          "to_longitude": 105.82207850485061,
          "from_longitude": 105.82206235240994
        }
      },
      {
        "id": 1100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82273757842647,
            21.02132215625389,
            105.82274303785312,
            21.02133166339696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274303785312,
              21.02133166339696
            ],
            [
              105.82274120802636,
              21.021328480247206
            ],
            [
              105.82273757842647,
              21.02132215625389
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1101",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02133166339696,
          "to_latitude": 21.02132215625389,
          "to_longitude": 105.82273757842647,
          "from_longitude": 105.82274303785312
        }
      },
      {
        "id": 1101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8225311001157,
            21.02140161269618,
            105.8225460325849,
            21.021427165007317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8225311001157,
              21.02140161269618
            ],
            [
              105.82254172482514,
              21.021419793837357
            ],
            [
              105.82254502364042,
              21.021425442015076
            ],
            [
              105.8225460325849,
              21.021427165007317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1102",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02140161269618,
          "to_latitude": 21.021427165007317,
          "to_longitude": 105.8225460325849,
          "from_longitude": 105.8225311001157
        }
      },
      {
        "id": 1102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8219977896277,
            21.02253895441283,
            105.82203092854603,
            21.022550630126016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82203092854603,
              21.022550630126016
            ],
            [
              105.82201250754078,
              21.022544140057327
            ],
            [
              105.822001165976,
              21.02254014390196
            ],
            [
              105.8219977896277,
              21.02253895441283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1103",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022550630126016,
          "to_latitude": 21.02253895441283,
          "to_longitude": 105.8219977896277,
          "from_longitude": 105.82203092854603
        }
      },
      {
        "id": 1103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82340067311351,
            21.022267504505688,
            105.82341088612031,
            21.022291790675876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82340067311351,
              21.022267504505688
            ],
            [
              105.8234019675236,
              21.022270581852197
            ],
            [
              105.82340246949765,
              21.022271775293035
            ],
            [
              105.82340489362478,
              21.022277544202677
            ],
            [
              105.82340782260717,
              21.02228450563592
            ],
            [
              105.82341088612031,
              21.022291790675876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_La Thành",
          "maTaiSan": "04.O12.DODV.1104",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022267504505688,
          "to_latitude": 21.022291790675876,
          "to_longitude": 105.82341088612031,
          "from_longitude": 105.82340067311351
        }
      },
      {
        "id": 1104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82282822996316,
            21.02249262191358,
            105.82284374475948,
            21.02252311201923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82282822996316,
              21.02249262191358
            ],
            [
              105.82283701137449,
              21.02250987981921
            ],
            [
              105.82284198168752,
              21.02251965035496
            ],
            [
              105.82284374475948,
              21.02252311201923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_La Thành",
          "maTaiSan": "04.O12.DODV.1105",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02249262191358,
          "to_latitude": 21.02252311201923,
          "to_longitude": 105.82284374475948,
          "from_longitude": 105.82282822996316
        }
      },
      {
        "id": 1105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81870918152858,
            21.020815978306068,
            105.818738845715,
            21.020828166521476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.818738845715,
              21.020815978306068
            ],
            [
              105.81873651335565,
              21.02081693701752
            ],
            [
              105.81872840191699,
              21.020820269225656
            ],
            [
              105.81870918152858,
              21.020828166521476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT09_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1106",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020815978306068,
          "to_latitude": 21.020828166521476,
          "to_longitude": 105.81870918152858,
          "from_longitude": 105.818738845715
        }
      },
      {
        "id": 1106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82078807068388,
            21.0202184576538,
            105.82079065863779,
            21.020232037820897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82079065863779,
              21.020232037820897
            ],
            [
              105.82078987820724,
              21.020227945454806
            ],
            [
              105.82078807068388,
              21.0202184576538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1107",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020232037820897,
          "to_latitude": 21.0202184576538,
          "to_longitude": 105.82078807068388,
          "from_longitude": 105.82079065863779
        }
      },
      {
        "id": 1107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82006282341152,
            21.020462288014905,
            105.82007077868644,
            21.020478607094084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007077868644,
              21.020478607094084
            ],
            [
              105.8200665427678,
              21.02046992131546
            ],
            [
              105.82006282341152,
              21.020462288014905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1108",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020478607094084,
          "to_latitude": 21.020462288014905,
          "to_longitude": 105.82006282341152,
          "from_longitude": 105.82007077868644
        }
      },
      {
        "id": 1108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82164281361638,
            21.02046833472008,
            105.82166727552666,
            21.020477468888103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82164281361638,
              21.020477468888103
            ],
            [
              105.82165657146163,
              21.020472331718903
            ],
            [
              105.82166684544654,
              21.02046849576543
            ],
            [
              105.82166727552666,
              21.02046833472008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1109",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020477468888103,
          "to_latitude": 21.02046833472008,
          "to_longitude": 105.82166727552666,
          "from_longitude": 105.82164281361638
        }
      },
      {
        "id": 1109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82151689187066,
            21.021051682686103,
            105.82152438361086,
            21.02108116122632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82151689187066,
              21.021051682686103
            ],
            [
              105.82151852924565,
              21.021058196974646
            ],
            [
              105.821524277469,
              21.021080750771038
            ],
            [
              105.82152438361086,
              21.02108116122632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1110",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021051682686103,
          "to_latitude": 21.02108116122632,
          "to_longitude": 105.82152438361086,
          "from_longitude": 105.82151689187066
        }
      },
      {
        "id": 1110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82152011826378,
            21.0210818718921,
            105.82152305078003,
            21.021093537347063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82152305078003,
              21.021093537347063
            ],
            [
              105.82152165139159,
              21.02108797029675
            ],
            [
              105.82152020188498,
              21.021082210198056
            ],
            [
              105.82152011826378,
              21.0210818718921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1111",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021093537347063,
          "to_latitude": 21.0210818718921,
          "to_longitude": 105.82152011826378,
          "from_longitude": 105.82152305078003
        }
      },
      {
        "id": 1111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.819566666389,
            21.019120916222732,
            105.81957327413276,
            21.019148018668407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81957327413276,
              21.019120916222732
            ],
            [
              105.8195715278323,
              21.019128070966648
            ],
            [
              105.81956970351727,
              21.019135560280336
            ],
            [
              105.819566666389,
              21.019148018668407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1112",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019120916222732,
          "to_latitude": 21.019148018668407,
          "to_longitude": 105.819566666389,
          "from_longitude": 105.81957327413276
        }
      },
      {
        "id": 1112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81921505383679,
            21.01983623006454,
            105.81923187018135,
            21.019839713508702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81921505383679,
              21.019839713508702
            ],
            [
              105.81922203077923,
              21.01983826816393
            ],
            [
              105.81923008006369,
              21.019836600879913
            ],
            [
              105.81923187018135,
              21.01983623006454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1113",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019839713508702,
          "to_latitude": 21.01983623006454,
          "to_longitude": 105.81923187018135,
          "from_longitude": 105.81921505383679
        }
      },
      {
        "id": 1113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82156617419145,
            21.02189117804602,
            105.8215909643394,
            21.02190418050485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82156617419145,
              21.02190418050485
            ],
            [
              105.82157500479327,
              21.021899548558586
            ],
            [
              105.82158416824731,
              21.021894742487895
            ],
            [
              105.8215909643394,
              21.02189117804602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1114",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02190418050485,
          "to_latitude": 21.02189117804602,
          "to_longitude": 105.8215909643394,
          "from_longitude": 105.82156617419145
        }
      },
      {
        "id": 1114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81939981400849,
            21.02193449722762,
            105.81940698546605,
            21.021947329140524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81940698546605,
              21.021947329140524
            ],
            [
              105.8194047054048,
              21.021943249411976
            ],
            [
              105.81940118380916,
              21.021936951017604
            ],
            [
              105.81939981400849,
              21.02193449722762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1115",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021947329140524,
          "to_latitude": 21.02193449722762,
          "to_longitude": 105.81939981400849,
          "from_longitude": 105.81940698546605
        }
      },
      {
        "id": 1115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81895467808,
            21.021271141065686,
            105.81898430575033,
            21.021285095860758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81895467808,
              21.021285095860758
            ],
            [
              105.81897365905574,
              21.021276155559075
            ],
            [
              105.81898335583665,
              21.021271588220532
            ],
            [
              105.81898430575033,
              21.021271141065686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1116",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021285095860758,
          "to_latitude": 21.021271141065686,
          "to_longitude": 105.81898430575033,
          "from_longitude": 105.81895467808
        }
      },
      {
        "id": 1116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82058755105177,
            21.022642392309447,
            105.82058995338147,
            21.02264709164399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82058755105177,
              21.02264709164399
            ],
            [
              105.82058881139842,
              21.022644657691153
            ],
            [
              105.82058995338147,
              21.022642392309447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.1117",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02264709164399,
          "to_latitude": 21.022642392309447,
          "to_longitude": 105.82058995338147,
          "from_longitude": 105.82058755105177
        }
      },
      {
        "id": 1117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82036095384464,
            21.019414501298535,
            105.82036142290193,
            21.019427796338565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82036142290193,
              21.019427796338565
            ],
            [
              105.8203612828192,
              21.01942381830094
            ],
            [
              105.82036098942572,
              21.01941555428915
            ],
            [
              105.82036095384464,
              21.019414501298535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1118",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019427796338565,
          "to_latitude": 21.019414501298535,
          "to_longitude": 105.82036095384464,
          "from_longitude": 105.82036142290193
        }
      },
      {
        "id": 1118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81870279138472,
            21.02073715303086,
            105.81875456107946,
            21.020757027527083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81870279138472,
              21.020757027527083
            ],
            [
              105.8187039663769,
              21.02075657658083
            ],
            [
              105.81873515452517,
              21.02074460322499
            ],
            [
              105.81875456107946,
              21.02073715303086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT08_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1119",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020757027527083,
          "to_latitude": 21.02073715303086,
          "to_longitude": 105.81875456107946,
          "from_longitude": 105.81870279138472
        }
      },
      {
        "id": 1119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82092710011615,
            21.02313983303934,
            105.82093243264936,
            21.023173259183455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82093243264936,
              21.023173259183455
            ],
            [
              105.82093208701455,
              21.023171099431625
            ],
            [
              105.82093081984628,
              21.023163150835437
            ],
            [
              105.82092710011615,
              21.02313983303934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT06_La Thành",
          "maTaiSan": "04.O12.DODV.1120",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023173259183455,
          "to_latitude": 21.02313983303934,
          "to_longitude": 105.82092710011615,
          "from_longitude": 105.82093243264936
        }
      },
      {
        "id": 1120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82007903727728,
            21.023275652610224,
            105.82008420178778,
            21.02330143288227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007903727728,
              21.023275652610224
            ],
            [
              105.82007946455829,
              21.02327779209944
            ],
            [
              105.82008218425604,
              21.023291363508584
            ],
            [
              105.8200828588354,
              21.02329474645115
            ],
            [
              105.82008420178778,
              21.02330143288227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT05_La Thành",
          "maTaiSan": "04.O12.DODV.1121",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023275652610224,
          "to_latitude": 21.02330143288227,
          "to_longitude": 105.82008420178778,
          "from_longitude": 105.82007903727728
        }
      },
      {
        "id": 1121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81967864950339,
            21.022983407301293,
            105.81969889235141,
            21.022995767964044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81969889235141,
              21.022983407301293
            ],
            [
              105.81968825462756,
              21.02298990399236
            ],
            [
              105.81967864950339,
              21.022995767964044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT04_Láng Hạ",
          "maTaiSan": "04.O12.DODV.1122",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022983407301293,
          "to_latitude": 21.022995767964044,
          "to_longitude": 105.81967864950339,
          "from_longitude": 105.81969889235141
        }
      },
      {
        "id": 1122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8186523317031,
            21.021526668497234,
            105.8186754598366,
            21.021539480989052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8186754598366,
              21.021526668497234
            ],
            [
              105.81866319612695,
              21.021533462885547
            ],
            [
              105.8186523317031,
              21.021539480989052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT02_Láng Hạ",
          "maTaiSan": "04.O12.DODV.1123",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021526668497234,
          "to_latitude": 21.021539480989052,
          "to_longitude": 105.8186523317031,
          "from_longitude": 105.8186754598366
        }
      },
      {
        "id": 1123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81823569105657,
            21.020955295376655,
            105.81826081859347,
            21.020971018594057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81826081859347,
              21.020955295376655
            ],
            [
              105.81825568283801,
              21.020958509376666
            ],
            [
              105.81824277201726,
              21.020966587622947
            ],
            [
              105.81823569105657,
              21.020971018594057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT01_Láng Hạ",
          "maTaiSan": "04.O12.DODV.1124",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020955295376655,
          "to_latitude": 21.020971018594057,
          "to_longitude": 105.81823569105657,
          "from_longitude": 105.81826081859347
        }
      },
      {
        "id": 1124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82913314749622,
            21.019146034057897,
            105.82927094459001,
            21.019330808916905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82927094459001,
              21.019330808916905
            ],
            [
              105.82926433695434,
              21.01932195068468
            ],
            [
              105.82926281058884,
              21.01931991141764
            ],
            [
              105.82913314749622,
              21.019146034057897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C21_La Thành",
          "maTaiSan": "04.O12.DODV.1125",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019330808916905,
          "to_latitude": 21.019146034057897,
          "to_longitude": 105.82913314749622,
          "from_longitude": 105.82927094459001
        }
      },
      {
        "id": 1125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993248688596,
            21.019674243288886,
            105.81997613608608,
            21.020047517103954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81993939923242,
              21.020047517103954
            ],
            [
              105.81993248688596,
              21.02004722977819
            ],
            [
              105.81993842604415,
              21.019950986740167
            ],
            [
              105.8199384799175,
              21.01995010764097
            ],
            [
              105.8199386655495,
              21.019947111646673
            ],
            [
              105.81993924254527,
              21.019937753243966
            ],
            [
              105.81994880912572,
              21.019782720665134
            ],
            [
              105.81994652796378,
              21.01977755617242
            ],
            [
              105.81994576974397,
              21.01977118756331
            ],
            [
              105.81994575561015,
              21.019771065695846
            ],
            [
              105.81994615506467,
              21.019766534991955
            ],
            [
              105.81994672931968,
              21.019764240737853
            ],
            [
              105.81994839184367,
              21.019757600330685
            ],
            [
              105.81995179295811,
              21.0197557684506
            ],
            [
              105.81997613608608,
              21.019674243288886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1126",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020047517103954,
          "to_latitude": 21.019674243288886,
          "to_longitude": 105.81997613608608,
          "from_longitude": 105.81993939923242
        }
      },
      {
        "id": 1126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82024304190628,
            21.01972276941222,
            105.82049323423672,
            21.020247678691195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82049323423672,
              21.020247678691195
            ],
            [
              105.82041020648644,
              21.020086925489345
            ],
            [
              105.82038031106188,
              21.020021799816067
            ],
            [
              105.82024304190628,
              21.01972276941222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT170_Ngách 82/41, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1127",
          "diaChiLapD": "Ngách 82/41, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020247678691195,
          "to_latitude": 21.01972276941222,
          "to_longitude": 105.82024304190628,
          "from_longitude": 105.82049323423672
        }
      },
      {
        "id": 1127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82053142187891,
            21.01970642657021,
            105.82076167584827,
            21.020222613406254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82053142187891,
              21.01970642657021
            ],
            [
              105.82072287161478,
              21.020149782909005
            ],
            [
              105.82075598210447,
              21.020202252348692
            ],
            [
              105.82076167584827,
              21.020222613406254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT169_Ngách 82/29, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1128",
          "diaChiLapD": "Ngách 82/29, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01970642657021,
          "to_latitude": 21.020222613406254,
          "to_longitude": 105.82076167584827,
          "from_longitude": 105.82053142187891
        }
      },
      {
        "id": 1128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82256053871615,
            21.020277813122597,
            105.82276694081528,
            21.020427124948135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82256053871615,
              21.02028507565908
            ],
            [
              105.82259334818417,
              21.020277813122597
            ],
            [
              105.82271861850947,
              21.02028778675908
            ],
            [
              105.82276394521514,
              21.02029527203922
            ],
            [
              105.82276694081528,
              21.020427124948135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT178_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1129",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02028507565908,
          "to_latitude": 21.020427124948135,
          "to_longitude": 105.82276694081528,
          "from_longitude": 105.82256053871615
        }
      },
      {
        "id": 1129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82221464635863,
            21.020505057741786,
            105.8223134380635,
            21.020632462546875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223134380635,
              21.020632462546875
            ],
            [
              105.82231324403006,
              21.02063216361383
            ],
            [
              105.82230114023186,
              21.02059913243768
            ],
            [
              105.82224012117443,
              21.020611184707647
            ],
            [
              105.82223144704868,
              21.020585037595
            ],
            [
              105.82221464635863,
              21.020505057741786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1130",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020632462546875,
          "to_latitude": 21.020505057741786,
          "to_longitude": 105.82221464635863,
          "from_longitude": 105.8223134380635
        }
      },
      {
        "id": 1130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82164717447202,
            21.020304535105847,
            105.8222164806059,
            21.020439604523723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82219654121293,
              21.020304535105847
            ],
            [
              105.8222164806059,
              21.020429749617726
            ],
            [
              105.82207730106323,
              21.020439604523723
            ],
            [
              105.82197176747415,
              21.020422103298117
            ],
            [
              105.82197054080872,
              21.020421899677302
            ],
            [
              105.82188378749746,
              21.02040294377094
            ],
            [
              105.82172967457818,
              21.020414247967388
            ],
            [
              105.82169579007854,
              21.02042353785596
            ],
            [
              105.82166170477555,
              21.020431469352328
            ],
            [
              105.82164876354845,
              21.020434481801903
            ],
            [
              105.82164717447202,
              21.02043485076839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_Ngõ 85, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1131",
          "diaChiLapD": "Ngõ 85, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020304535105847,
          "to_latitude": 21.02043485076839,
          "to_longitude": 105.82164717447202,
          "from_longitude": 105.82219654121293
        }
      },
      {
        "id": 1131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111235577305,
            21.020329154841153,
            105.82160387237161,
            21.020445740314273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82111235577305,
              21.020445740314273
            ],
            [
              105.82139262571555,
              21.020383745422098
            ],
            [
              105.82157733148696,
              21.02033421826472
            ],
            [
              105.82158370061411,
              21.02033297627512
            ],
            [
              105.82160162411239,
              21.020329480926605
            ],
            [
              105.82160387237161,
              21.020329154841153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT165_Ngõ 74, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1132",
          "diaChiLapD": "Ngõ 74, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020445740314273,
          "to_latitude": 21.020329154841153,
          "to_longitude": 105.82160387237161,
          "from_longitude": 105.82111235577305
        }
      },
      {
        "id": 1132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111272295214,
            21.020331172128643,
            105.821604728033,
            21.020459389905255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82111272295214,
              21.020459389905255
            ],
            [
              105.82136596470151,
              21.020408328114403
            ],
            [
              105.82157218610762,
              21.020358308678716
            ],
            [
              105.82158489641648,
              21.020341242245856
            ],
            [
              105.82158670661632,
              21.020340323050345
            ],
            [
              105.82160293701779,
              21.020332081296168
            ],
            [
              105.821604728033,
              21.020331172128643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Ngõ 74, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1133",
          "diaChiLapD": "Ngõ 74, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020459389905255,
          "to_latitude": 21.020331172128643,
          "to_longitude": 105.821604728033,
          "from_longitude": 105.82111272295214
        }
      },
      {
        "id": 1133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82125074667145,
            21.020551620674894,
            105.82170197803171,
            21.020648613356176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82125074667145,
              21.020648613356176
            ],
            [
              105.82160863503344,
              21.020570387192386
            ],
            [
              105.82167130732195,
              21.020563872971202
            ],
            [
              105.82167961211142,
              21.020560555045645
            ],
            [
              105.8216997261835,
              21.020552521230968
            ],
            [
              105.82170197803171,
              21.020551620674894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ 66, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1134",
          "diaChiLapD": "Ngõ 66, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020648613356176,
          "to_latitude": 21.020551620674894,
          "to_longitude": 105.82170197803171,
          "from_longitude": 105.82125074667145
        }
      },
      {
        "id": 1134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82124853084623,
            21.02052201120618,
            105.82168905717099,
            21.02063466288507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82124853084623,
              21.02063466288507
            ],
            [
              105.82142003484516,
              21.02059882570146
            ],
            [
              105.8215388232455,
              21.020571679999613
            ],
            [
              105.82164410618178,
              21.020542018936414
            ],
            [
              105.82166588078749,
              21.02053173439379
            ],
            [
              105.82168312564896,
              21.02052358889259
            ],
            [
              105.82168905717099,
              21.02052201120618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT164_Ngõ 66, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1135",
          "diaChiLapD": "Ngõ 66, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02063466288507,
          "to_latitude": 21.02052201120618,
          "to_longitude": 105.82168905717099,
          "from_longitude": 105.82124853084623
        }
      },
      {
        "id": 1135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82184718113741,
            21.020840288629838,
            105.8223063240042,
            21.020913618689278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184718113741,
              21.020913618689278
            ],
            [
              105.82185276093178,
              21.020912103212428
            ],
            [
              105.82203502387115,
              21.02086259457583
            ],
            [
              105.82222088240542,
              21.020841659086837
            ],
            [
              105.8223063240042,
              21.020840288629838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1136",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020913618689278,
          "to_latitude": 21.020840288629838,
          "to_longitude": 105.8223063240042,
          "from_longitude": 105.82184718113741
        }
      },
      {
        "id": 1136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81930155984767,
            21.01596633076688,
            105.8195040805282,
            21.015986892141736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81930256242586,
              21.01596633076688
            ],
            [
              105.81930233727937,
              21.015967906176055
            ],
            [
              105.81930155984767,
              21.015986892141736
            ],
            [
              105.8195040805282,
              21.015980769225045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1137",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01596633076688,
          "to_latitude": 21.015980769225045,
          "to_longitude": 105.8195040805282,
          "from_longitude": 105.81930256242586
        }
      },
      {
        "id": 1137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8191250744011,
            21.015986892141736,
            105.81930155984767,
            21.016266620585363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81930155984767,
              21.015986892141736
            ],
            [
              105.81917539006282,
              21.015987672223368
            ],
            [
              105.81914540468567,
              21.01598895466916
            ],
            [
              105.81914441755559,
              21.01608861699147
            ],
            [
              105.81913200994498,
              21.01608888176528
            ],
            [
              105.81912770045328,
              21.01619932474455
            ],
            [
              105.8191250744011,
              21.016266620585363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1138",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015986892141736,
          "to_latitude": 21.016266620585363,
          "to_longitude": 105.8191250744011,
          "from_longitude": 105.81930155984767
        }
      },
      {
        "id": 1138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81912770045328,
            21.01619932474455,
            105.81926088666319,
            21.016203621364063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81912770045328,
              21.01619932474455
            ],
            [
              105.81926088666319,
              21.016203621364063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1139",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01619932474455,
          "to_latitude": 21.016203621364063,
          "to_longitude": 105.81926088666319,
          "from_longitude": 105.81912770045328
        }
      },
      {
        "id": 1139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81861713134586,
            21.015985303425957,
            105.81913737688954,
            21.016034352005597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81913737688954,
              21.015993462490123
            ],
            [
              105.8191333726387,
              21.01599281518836
            ],
            [
              105.81909251940458,
              21.015986200649102
            ],
            [
              105.81888507176953,
              21.015985303425957
            ],
            [
              105.81884058798944,
              21.015986231086966
            ],
            [
              105.81878347535391,
              21.01598742274289
            ],
            [
              105.81876063844442,
              21.016013252045944
            ],
            [
              105.81875000969139,
              21.016034352005597
            ],
            [
              105.81861713134586,
              21.0160302115647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1140",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015993462490123,
          "to_latitude": 21.0160302115647,
          "to_longitude": 105.81861713134586,
          "from_longitude": 105.81913737688954
        }
      },
      {
        "id": 1140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81883580267751,
            21.015986231086966,
            105.81884058798944,
            21.01622699126775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81883580267751,
              21.01622699126775
            ],
            [
              105.81884058798944,
              21.015986231086966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1141",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01622699126775,
          "to_latitude": 21.015986231086966,
          "to_longitude": 105.81884058798944,
          "from_longitude": 105.81883580267751
        }
      },
      {
        "id": 1141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81842366574593,
            21.01626912539968,
            105.81891527899099,
            21.016319028920606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81891527899099,
              21.016283473192694
            ],
            [
              105.81890842052837,
              21.016283156657618
            ],
            [
              105.8188847789935,
              21.01627983728968
            ],
            [
              105.81884602336427,
              21.01627439672748
            ],
            [
              105.81868016017383,
              21.0162727996214
            ],
            [
              105.81862683112772,
              21.01626912539968
            ],
            [
              105.81862528445902,
              21.016319028920606
            ],
            [
              105.81842366574593,
              21.016290633248463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1142",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016283473192694,
          "to_latitude": 21.016290633248463,
          "to_longitude": 105.81842366574593,
          "from_longitude": 105.81891527899099
        }
      },
      {
        "id": 1142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81862683112772,
            21.016164641293688,
            105.81862920537625,
            21.01626912539968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81862683112772,
              21.01626912539968
            ],
            [
              105.81862920537625,
              21.016164641293688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1143",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01626912539968,
          "to_latitude": 21.016164641293688,
          "to_longitude": 105.81862920537625,
          "from_longitude": 105.81862683112772
        }
      },
      {
        "id": 1143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81898035834769,
            21.015986200649102,
            105.81909455878143,
            21.01625053263836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909251940458,
              21.015986200649102
            ],
            [
              105.81909455878143,
              21.01611585812331
            ],
            [
              105.81899745886109,
              21.016116123273427
            ],
            [
              105.81898035834769,
              21.01625053263836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1144",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015986200649102,
          "to_latitude": 21.01625053263836,
          "to_longitude": 105.81898035834769,
          "from_longitude": 105.81909251940458
        }
      },
      {
        "id": 1144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8188960097843,
            21.016283132604727,
            105.81923308229742,
            21.016332874956017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81891407948761,
              21.01632277025208
            ],
            [
              105.8188960097843,
              21.016321612584637
            ],
            [
              105.81889610564487,
              21.01632983239375
            ],
            [
              105.81891381679945,
              21.01633138920704
            ],
            [
              105.81893071664761,
              21.016332874956017
            ],
            [
              105.81893574633057,
              21.016283132604727
            ],
            [
              105.81923308229742,
              21.016296721716063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1145",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01632277025208,
          "to_latitude": 21.016296721716063,
          "to_longitude": 105.81923308229742,
          "from_longitude": 105.81891407948761
        }
      },
      {
        "id": 1145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81883857569171,
            21.016332874956017,
            105.81893071664761,
            21.016526310118856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81893071664761,
              21.016332874956017
            ],
            [
              105.8189172289317,
              21.016416508265667
            ],
            [
              105.81885310256338,
              21.016413288930046
            ],
            [
              105.81883857569171,
              21.016526310118856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1146",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016332874956017,
          "to_latitude": 21.016526310118856,
          "to_longitude": 105.81883857569171,
          "from_longitude": 105.81893071664761
        }
      },
      {
        "id": 1146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8189172289317,
            21.016416508265667,
            105.81909329743657,
            21.016430768019642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8189172289317,
              21.016416508265667
            ],
            [
              105.81909329743657,
              21.016430768019642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1147",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016416508265667,
          "to_latitude": 21.016430768019642,
          "to_longitude": 105.81909329743657,
          "from_longitude": 105.8189172289317
        }
      },
      {
        "id": 1147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8187990510637,
            21.01627983728968,
            105.8188847789935,
            21.016494023014964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8188847789935,
              21.01627983728968
            ],
            [
              105.81888069914704,
              21.016383747021216
            ],
            [
              105.81882201742818,
              21.016388462510324
            ],
            [
              105.8187990510637,
              21.016494023014964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1148",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01627983728968,
          "to_latitude": 21.016494023014964,
          "to_longitude": 105.8187990510637,
          "from_longitude": 105.8188847789935
        }
      },
      {
        "id": 1148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81869548702474,
            21.016467141525965,
            105.81925617681682,
            21.016559496442373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81869548702474,
              21.016513747527934
            ],
            [
              105.81869689876707,
              21.016514027960355
            ],
            [
              105.81882800912756,
              21.016539951836805
            ],
            [
              105.81888926362608,
              21.01654607171811
            ],
            [
              105.81895824982806,
              21.016550353322707
            ],
            [
              105.81910557647936,
              21.016559496442373
            ],
            [
              105.81911775824533,
              21.016467141525965
            ],
            [
              105.81925617681682,
              21.01648343309561
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A69_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1149",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016513747527934,
          "to_latitude": 21.01648343309561,
          "to_longitude": 105.81925617681682,
          "from_longitude": 105.81869548702474
        }
      },
      {
        "id": 1149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81893396056586,
            21.016550353322707,
            105.81895824982806,
            21.016669953649945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81895824982806,
              21.016550353322707
            ],
            [
              105.81893396056586,
              21.016669953649945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A69_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1150",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016550353322707,
          "to_latitude": 21.016669953649945,
          "to_longitude": 105.81893396056586,
          "from_longitude": 105.81895824982806
        }
      },
      {
        "id": 1150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81840238491472,
            21.016458689828422,
            105.81869050419158,
            21.016542657615894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81869050419158,
              21.016542657615894
            ],
            [
              105.81868975323819,
              21.016542541100513
            ],
            [
              105.81868259023736,
              21.016541418537088
            ],
            [
              105.81868559852711,
              21.01651239240285
            ],
            [
              105.81840238491472,
              21.016458689828422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1151",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016542657615894,
          "to_latitude": 21.016458689828422,
          "to_longitude": 105.81840238491472,
          "from_longitude": 105.81869050419158
        }
      },
      {
        "id": 1151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81825268933403,
            21.016387827712283,
            105.8184124036144,
            21.016507203549022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81825268933403,
              21.01648062037867
            ],
            [
              105.81839552546059,
              21.016507203549022
            ],
            [
              105.81840238491472,
              21.016458689828422
            ],
            [
              105.8184124036144,
              21.016387827712283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1152",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01648062037867,
          "to_latitude": 21.016387827712283,
          "to_longitude": 105.8184124036144,
          "from_longitude": 105.81825268933403
        }
      },
      {
        "id": 1152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81841985925952,
            21.01656175702535,
            105.81867219325126,
            21.01661150932256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81867219325126,
              21.01661150932256
            ],
            [
              105.81866724930855,
              21.016610535951123
            ],
            [
              105.81841985925952,
              21.01656175702535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A71_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1153",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01661150932256,
          "to_latitude": 21.01656175702535,
          "to_longitude": 105.81841985925952,
          "from_longitude": 105.81867219325126
        }
      },
      {
        "id": 1153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8185889758697,
            21.01680707548297,
            105.81888786268763,
            21.01684464922539
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8185889758697,
              21.01680707548297
            ],
            [
              105.8185947312776,
              21.016807618628626
            ],
            [
              105.81859928569936,
              21.016808074520647
            ],
            [
              105.81876623769011,
              21.01682416595419
            ],
            [
              105.81888786268763,
              21.01684464922539
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A72_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1154",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01680707548297,
          "to_latitude": 21.01684464922539,
          "to_longitude": 105.81888786268763,
          "from_longitude": 105.8185889758697
        }
      },
      {
        "id": 1154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81859928569936,
            21.01656575637667,
            105.81868870445835,
            21.016808074520647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81859928569936,
              21.016808074520647
            ],
            [
              105.81860010054227,
              21.01680244801055
            ],
            [
              105.81861080493726,
              21.016728572131825
            ],
            [
              105.81862793273456,
              21.016691622689667
            ],
            [
              105.81864295332238,
              21.016670964959193
            ],
            [
              105.81867005018947,
              21.016650485759452
            ],
            [
              105.81868870445835,
              21.01656575637667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A72_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1155",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016808074520647,
          "to_latitude": 21.01656575637667,
          "to_longitude": 105.81868870445835,
          "from_longitude": 105.81859928569936
        }
      },
      {
        "id": 1155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81831014409,
            21.016776897825533,
            105.81856117160473,
            21.016800703323618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81831014409,
              21.01678488425273
            ],
            [
              105.81831042104983,
              21.01678222472279
            ],
            [
              105.81831091432397,
              21.016776897825533
            ],
            [
              105.81856117160473,
              21.016800703323618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1156",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01678488425273,
          "to_latitude": 21.016800703323618,
          "to_longitude": 105.81856117160473,
          "from_longitude": 105.81831014409
        }
      },
      {
        "id": 1156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81807773271971,
            21.016721363470705,
            105.81831091432397,
            21.016776897825533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81831091432397,
              21.016776897825533
            ],
            [
              105.8181763838408,
              21.016765560865156
            ],
            [
              105.81813994088566,
              21.01674923416642
            ],
            [
              105.81807773271971,
              21.016721363470705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1157",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016776897825533,
          "to_latitude": 21.016721363470705,
          "to_longitude": 105.81807773271971,
          "from_longitude": 105.81831091432397
        }
      },
      {
        "id": 1157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81813994088566,
            21.01663673616488,
            105.81836733584618,
            21.01674923416642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81813994088566,
              21.01674923416642
            ],
            [
              105.81818724653023,
              21.016659827420195
            ],
            [
              105.81820589101979,
              21.01663673616488
            ],
            [
              105.81836733584618,
              21.016663442113135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1158",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01674923416642,
          "to_latitude": 21.016663442113135,
          "to_longitude": 105.81836733584618,
          "from_longitude": 105.81813994088566
        }
      },
      {
        "id": 1158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81837429781552,
            21.016928839101848,
            105.81853851368447,
            21.017079369692038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81853714117706,
              21.017079369692038
            ],
            [
              105.81853789169531,
              21.01705631572371
            ],
            [
              105.81853851368447,
              21.017035973861717
            ],
            [
              105.81837429781552,
              21.016928839101848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1159",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017079369692038,
          "to_latitude": 21.016928839101848,
          "to_longitude": 105.81837429781552,
          "from_longitude": 105.81853714117706
        }
      },
      {
        "id": 1159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81853851368447,
            21.016928185220078,
            105.81891691940835,
            21.017091321772284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81853851368447,
              21.017035973861717
            ],
            [
              105.81866653096385,
              21.017079188757062
            ],
            [
              105.81875249633956,
              21.017091321772284
            ],
            [
              105.81880019453939,
              21.017086910261877
            ],
            [
              105.81883627928792,
              21.0170532792404
            ],
            [
              105.81891691940835,
              21.016928185220078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1160",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017035973861717,
          "to_latitude": 21.016928185220078,
          "to_longitude": 105.81891691940835,
          "from_longitude": 105.81853851368447
        }
      },
      {
        "id": 1160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81916057587726,
            21.016526177097795,
            105.81928625175286,
            21.016675792269755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81917875194812,
              21.016675792269755
            ],
            [
              105.81917038705484,
              21.016670895509158
            ],
            [
              105.81916057587726,
              21.01666520635621
            ],
            [
              105.81928625175286,
              21.016526177097795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1161",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016675792269755,
          "to_latitude": 21.016526177097795,
          "to_longitude": 105.81928625175286,
          "from_longitude": 105.81917875194812
        }
      },
      {
        "id": 1161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81900955447989,
            21.01666520635621,
            105.81916057587726,
            21.016838427274493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81916057587726,
              21.01666520635621
            ],
            [
              105.81900955447989,
              21.016838427274493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1162",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01666520635621,
          "to_latitude": 21.016838427274493,
          "to_longitude": 105.81900955447989,
          "from_longitude": 105.81916057587726
        }
      },
      {
        "id": 1162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81948004752765,
            21.01608059890053,
            105.81960391186986,
            21.016270189323016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81952434277004,
              21.016270189323016
            ],
            [
              105.81950111665049,
              21.016256809703147
            ],
            [
              105.81948004752765,
              21.016244720336903
            ],
            [
              105.81960391186986,
              21.01608059890053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1163",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016270189323016,
          "to_latitude": 21.01608059890053,
          "to_longitude": 105.81960391186986,
          "from_longitude": 105.81952434277004
        }
      },
      {
        "id": 1163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81932110075695,
            21.016244720336903,
            105.81948004752765,
            21.016444932893755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81948004752765,
              21.016244720336903
            ],
            [
              105.81932110075695,
              21.016444932893755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1164",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016244720336903,
          "to_latitude": 21.016444932893755,
          "to_longitude": 105.81932110075695,
          "from_longitude": 105.81948004752765
        }
      },
      {
        "id": 1164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81998735007114,
            21.015527443094715,
            105.82019416167296,
            21.01575499465209
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81998735007114,
              21.015527443094715
            ],
            [
              105.81998877761737,
              21.01552797634407
            ],
            [
              105.82000021046775,
              21.01553223776201
            ],
            [
              105.82019416167296,
              21.01567901297184
            ],
            [
              105.82013449533983,
              21.01575499465209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1165",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015527443094715,
          "to_latitude": 21.01575499465209,
          "to_longitude": 105.82013449533983,
          "from_longitude": 105.81998735007114
        }
      },
      {
        "id": 1165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8200778207724,
            21.015717529220517,
            105.82014746020201,
            21.015809186261396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8201152392472,
              21.015809186261396
            ],
            [
              105.82014746020201,
              21.01576639952244
            ],
            [
              105.82013449533983,
              21.01575499465209
            ],
            [
              105.8200778207724,
              21.015717529220517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1166",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015809186261396,
          "to_latitude": 21.015717529220517,
          "to_longitude": 105.8200778207724,
          "from_longitude": 105.8201152392472
        }
      },
      {
        "id": 1166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82000021046775,
            21.01544156321378,
            105.82007938921159,
            21.01553223776201
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82000021046775,
              21.01553223776201
            ],
            [
              105.82007938921159,
              21.01544156321378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A79_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1167",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01553223776201,
          "to_latitude": 21.01544156321378,
          "to_longitude": 105.82007938921159,
          "from_longitude": 105.82000021046775
        }
      },
      {
        "id": 1167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82009883218646,
            21.01546589455803,
            105.82041120197758,
            21.01582178460085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82041120197758,
              21.01582178460085
            ],
            [
              105.82038842009274,
              21.01579799597821
            ],
            [
              105.82027383294091,
              21.015694592490746
            ],
            [
              105.82025626034262,
              21.015673856930015
            ],
            [
              105.82019092033534,
              21.01559675635776
            ],
            [
              105.82009883218646,
              21.01546589455803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A80_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1168",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01582178460085,
          "to_latitude": 21.01546589455803,
          "to_longitude": 105.82009883218646,
          "from_longitude": 105.82041120197758
        }
      },
      {
        "id": 1168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82025626034262,
            21.015637519628104,
            105.82030394215725,
            21.015673856930015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82025626034262,
              21.015673856930015
            ],
            [
              105.82025906042033,
              21.01567172265271
            ],
            [
              105.82030394215725,
              21.015637519628104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A80_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1169",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015673856930015,
          "to_latitude": 21.015637519628104,
          "to_longitude": 105.82030394215725,
          "from_longitude": 105.82025626034262
        }
      },
      {
        "id": 1169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81987239512631,
            21.015669920282043,
            105.8200311896883,
            21.015786309827917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81987239512631,
              21.015669920282043
            ],
            [
              105.81987350941608,
              21.01567069078421
            ],
            [
              105.8198870401192,
              21.01568002907455
            ],
            [
              105.8200311896883,
              21.015786309827917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1170",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015669920282043,
          "to_latitude": 21.015786309827917,
          "to_longitude": 105.8200311896883,
          "from_longitude": 105.81987239512631
        }
      },
      {
        "id": 1170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8198870401192,
            21.015564289315094,
            105.81997817259582,
            21.01568002907455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8198870401192,
              21.01568002907455
            ],
            [
              105.81997817259582,
              21.015564289315094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1171",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568002907455,
          "to_latitude": 21.015564289315094,
          "to_longitude": 105.81997817259582,
          "from_longitude": 105.8198870401192
        }
      },
      {
        "id": 1171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81970794630234,
            21.01568002907455,
            105.8198870401192,
            21.01592999668508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8198870401192,
              21.01568002907455
            ],
            [
              105.81982377475053,
              21.015757899481216
            ],
            [
              105.81976993959961,
              21.01582422273206
            ],
            [
              105.81970794630234,
              21.01592999668508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1172",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568002907455,
          "to_latitude": 21.01592999668508,
          "to_longitude": 105.81970794630234,
          "from_longitude": 105.8198870401192
        }
      },
      {
        "id": 1172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81966406592312,
            21.015844078517766,
            105.82031726151938,
            21.016034482564475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81966406592312,
              21.016007539697572
            ],
            [
              105.81982483220618,
              21.01603127050875
            ],
            [
              105.81991738183913,
              21.016034482564475
            ],
            [
              105.81995243343088,
              21.01595430668372
            ],
            [
              105.8199834841751,
              21.015973178085286
            ],
            [
              105.82001608977747,
              21.01592437221756
            ],
            [
              105.82013639364271,
              21.015997846987396
            ],
            [
              105.82031726151938,
              21.015844078517766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1173",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016007539697572,
          "to_latitude": 21.015844078517766,
          "to_longitude": 105.82031726151938,
          "from_longitude": 105.81966406592312
        }
      },
      {
        "id": 1173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871584980515,
            21.018344673965775,
            105.82874219783824,
            21.018380201328117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82871584980515,
              21.018344673965775
            ],
            [
              105.828716703663,
              21.018345827740948
            ],
            [
              105.82871948712575,
              21.018349578824022
            ],
            [
              105.82874219783824,
              21.018380201328117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Đông Các",
          "maTaiSan": "04.O12.DODV.1174",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018344673965775,
          "to_latitude": 21.018380201328117,
          "to_longitude": 105.82874219783824,
          "from_longitude": 105.82871584980515
        }
      },
      {
        "id": 1174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890572195615,
            21.01819856354093,
            105.82891451477404,
            21.018209455141204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890572195615,
              21.01819856354093
            ],
            [
              105.82890779377774,
              21.018201133056962
            ],
            [
              105.82891121964528,
              21.01820537326441
            ],
            [
              105.82891451477404,
              21.018209455141204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A26_Đông Các",
          "maTaiSan": "04.O12.DODV.1175",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01819856354093,
          "to_latitude": 21.018209455141204,
          "to_longitude": 105.82891451477404,
          "from_longitude": 105.82890572195615
        }
      },
      {
        "id": 1175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82901236230975,
            21.01819422052799,
            105.82909389643372,
            21.01830764761168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82901236230975,
              21.01819422052799
            ],
            [
              105.82909389643372,
              21.01830764761168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Đông Các",
          "maTaiSan": "04.O12.DODV.1176",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01819422052799,
          "to_latitude": 21.01830764761168,
          "to_longitude": 105.82909389643372,
          "from_longitude": 105.82901236230975
        }
      },
      {
        "id": 1176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82663823367841,
            21.018100781116456,
            105.82665323145778,
            21.01810408900998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82663823367841,
              21.018100781116456
            ],
            [
              105.8266421439656,
              21.018101643663208
            ],
            [
              105.82665082172146,
              21.018103557881304
            ],
            [
              105.82665323145778,
              21.01810408900998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A71_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1177",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018100781116456,
          "to_latitude": 21.01810408900998,
          "to_longitude": 105.82665323145778,
          "from_longitude": 105.82663823367841
        }
      },
      {
        "id": 1177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573615593265,
            21.01832026572612,
            105.82634843559444,
            21.01865207720982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82573615593265,
              21.01865207720982
            ],
            [
              105.82610463814447,
              21.018452386867832
            ],
            [
              105.82620892606508,
              21.01839586955055
            ],
            [
              105.82634843559444,
              21.01832026572612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A68_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1178",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01865207720982,
          "to_latitude": 21.01832026572612,
          "to_longitude": 105.82634843559444,
          "from_longitude": 105.82573615593265
        }
      },
      {
        "id": 1178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82473436642891,
            21.01511132201283,
            105.82487637662364,
            21.01534671521696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82473436642891,
              21.01511132201283
            ],
            [
              105.82487637662364,
              21.01534671521696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT146_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1179",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01511132201283,
          "to_latitude": 21.01534671521696,
          "to_longitude": 105.82487637662364,
          "from_longitude": 105.82473436642891
        }
      },
      {
        "id": 1179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82442701179178,
            21.01488290241123,
            105.82456329845641,
            21.0149545031811
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82442701179178,
              21.0149545031811
            ],
            [
              105.82456329845641,
              21.01488290241123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT145_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1180",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0149545031811,
          "to_latitude": 21.01488290241123,
          "to_longitude": 105.82456329845641,
          "from_longitude": 105.82442701179178
        }
      },
      {
        "id": 1180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8242421388156,
            21.0149545031811,
            105.82450874385923,
            21.015483594647918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82450874385923,
              21.015483594647918
            ],
            [
              105.82450605356146,
              21.015479613619227
            ],
            [
              105.82439001445638,
              21.015297483059896
            ],
            [
              105.82437090233334,
              21.015267485585788
            ],
            [
              105.82433366526921,
              21.015205953975084
            ],
            [
              105.82425398026221,
              21.015074282029758
            ],
            [
              105.8242421388156,
              21.015048893704144
            ],
            [
              105.82434976018132,
              21.014995176758898
            ],
            [
              105.82442701179178,
              21.0149545031811
            ],
            [
              105.82450049557332,
              21.01506977721627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT145_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1181",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015483594647918,
          "to_latitude": 21.01506977721627,
          "to_longitude": 105.82450049557332,
          "from_longitude": 105.82450874385923
        }
      },
      {
        "id": 1181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82782114216474,
            21.016775130881495,
            105.8280007304767,
            21.017097467137937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82782114216474,
              21.016775130881495
            ],
            [
              105.8280007304767,
              21.017097467137937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1182",
          "diaChiLapD": "Ngách 64/33, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016775130881495,
          "to_latitude": 21.017097467137937,
          "to_longitude": 105.8280007304767,
          "from_longitude": 105.82782114216474
        }
      },
      {
        "id": 1182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82296707612365,
            21.01388420624362,
            105.8231743940272,
            21.014044847483937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82296707612365,
              21.01388420624362
            ],
            [
              105.82308040842425,
              21.013972024302298
            ],
            [
              105.8231743940272,
              21.014044847483937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140A_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1183",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01388420624362,
          "to_latitude": 21.014044847483937,
          "to_longitude": 105.8231743940272,
          "from_longitude": 105.82296707612365
        }
      },
      {
        "id": 1183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82259053524906,
            21.01353720817495,
            105.82296707612365,
            21.01388420624362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82296707612365,
              21.01388420624362
            ],
            [
              105.82263363273981,
              21.01358165082434
            ],
            [
              105.82259053524906,
              21.01353720817495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140A_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1184",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01388420624362,
          "to_latitude": 21.01353720817495,
          "to_longitude": 105.82259053524906,
          "from_longitude": 105.82296707612365
        }
      },
      {
        "id": 1184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82666269872132,
            21.013820685385493,
            105.82668896495282,
            21.013835072169258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82668896495282,
              21.013820685385493
            ],
            [
              105.82667298992489,
              21.013830403137387
            ],
            [
              105.826665315433,
              21.013835072169258
            ],
            [
              105.82666269872132,
              21.013831357268895
            ],
            [
              105.82666414295262,
              21.013830727929008
            ],
            [
              105.82667034418446,
              21.013826308119985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1185",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013820685385493,
          "to_latitude": 21.013826308119985,
          "to_longitude": 105.82667034418446,
          "from_longitude": 105.82668896495282
        }
      },
      {
        "id": 1185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82672296978075,
            21.01334997306182,
            105.82675506573007,
            21.01336717831483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82672296978075,
              21.01336717831483
            ],
            [
              105.82673844853757,
              21.01335888103569
            ],
            [
              105.82674778144174,
              21.01335387787798
            ],
            [
              105.82675506573007,
              21.01334997306182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Tây Sơn",
          "maTaiSan": "04.O12.DODV.1186",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01336717831483,
          "to_latitude": 21.01334997306182,
          "to_longitude": 105.82675506573007,
          "from_longitude": 105.82672296978075
        }
      },
      {
        "id": 1186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82628015010492,
            21.013372578288486,
            105.82628388122602,
            21.01337918172028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82628388122602,
              21.01337918172028
            ],
            [
              105.82628349851198,
              21.01337850706244
            ],
            [
              105.82628161593662,
              21.013375172461426
            ],
            [
              105.82628015010492,
              21.013372578288486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1187",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01337918172028,
          "to_latitude": 21.013372578288486,
          "to_longitude": 105.82628015010492,
          "from_longitude": 105.82628388122602
        }
      },
      {
        "id": 1187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82588445130403,
            21.01382177034156,
            105.82604134065005,
            21.014036254620823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82603804583151,
              21.01382177034156
            ],
            [
              105.82603841042769,
              21.013822124442584
            ],
            [
              105.82604134065005,
              21.013826177443953
            ],
            [
              105.82602753135863,
              21.013835109653563
            ],
            [
              105.82603501982757,
              21.013851506595127
            ],
            [
              105.82597237373864,
              21.01389227616964
            ],
            [
              105.82592517001423,
              21.01392476494905
            ],
            [
              105.82592293617897,
              21.0139264684389
            ],
            [
              105.82588445130403,
              21.01395580507477
            ],
            [
              105.82591326703437,
              21.014009658254363
            ],
            [
              105.82592853112911,
              21.014036254620823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62A_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1188",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01382177034156,
          "to_latitude": 21.014036254620823,
          "to_longitude": 105.82592853112911,
          "from_longitude": 105.82603804583151
        }
      },
      {
        "id": 1188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587268780719,
            21.013825297954362,
            105.82602977356969,
            21.01405666002398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82602977356969,
              21.01382776547393
            ],
            [
              105.82602949439163,
              21.013827550958265
            ],
            [
              105.82602659139597,
              21.013825297954362
            ],
            [
              105.82601191394455,
              21.013831344053667
            ],
            [
              105.82602029879605,
              21.01385225909784
            ],
            [
              105.82591934868026,
              21.013912231137397
            ],
            [
              105.82588042295964,
              21.01394343778421
            ],
            [
              105.82587268780719,
              21.01395002372166
            ],
            [
              105.82592207735823,
              21.01405666002398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63A_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1189",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01382776547393,
          "to_latitude": 21.01405666002398,
          "to_longitude": 105.82592207735823,
          "from_longitude": 105.82602977356969
        }
      },
      {
        "id": 1189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82542797729792,
            21.014066035939212,
            105.8259294562003,
            21.0146727815466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82592682703809,
              21.014066035939212
            ],
            [
              105.82592503550457,
              21.01408098399776
            ],
            [
              105.82590760817234,
              21.014120804227446
            ],
            [
              105.82588770833728,
              21.014146935418943
            ],
            [
              105.82589457840216,
              21.014184842990627
            ],
            [
              105.82589782092897,
              21.014209802358614
            ],
            [
              105.82589856526684,
              21.014215532425393
            ],
            [
              105.82592630626384,
              21.014284702307222
            ],
            [
              105.8259294562003,
              21.014359232080356
            ],
            [
              105.82574224393727,
              21.01446678923241
            ],
            [
              105.82577825643322,
              21.014539430735343
            ],
            [
              105.82576614437977,
              21.014546177009134
            ],
            [
              105.8257129632733,
              21.01458029435944
            ],
            [
              105.82565572074785,
              21.01461211824114
            ],
            [
              105.82564520311863,
              21.014613540379468
            ],
            [
              105.82553884514951,
              21.0146727815466
            ],
            [
              105.82551891771685,
              21.014631347896177
            ],
            [
              105.8255058455521,
              21.01460416911691
            ],
            [
              105.82542797729792,
              21.014476753952007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1190",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 122.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014066035939212,
          "to_latitude": 21.014476753952007,
          "to_longitude": 105.82542797729792,
          "from_longitude": 105.82592682703809
        }
      },
      {
        "id": 1190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82565538572591,
            21.01405361535547,
            105.82595703804097,
            21.014623603485024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593842876314,
              21.01405361535547
            ],
            [
              105.82592597001967,
              21.014105181407803
            ],
            [
              105.82589713319953,
              21.01414953521811
            ],
            [
              105.82592220009263,
              21.014209982981637
            ],
            [
              105.82594286342469,
              21.0142597976616
            ],
            [
              105.82595703804097,
              21.014357736117365
            ],
            [
              105.82585009468667,
              21.014418397476017
            ],
            [
              105.82576192419572,
              21.01447013049073
            ],
            [
              105.82576413528938,
              21.01448998080019
            ],
            [
              105.82578851504806,
              21.014544603397376
            ],
            [
              105.82565538572591,
              21.014623603485024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1191",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01405361535547,
          "to_latitude": 21.014623603485024,
          "to_longitude": 105.82565538572591,
          "from_longitude": 105.82593842876314
        }
      },
      {
        "id": 1191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82538272144947,
            21.014631347896177,
            105.82551891771685,
            21.014706662139087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551891771685,
              21.014631347896177
            ],
            [
              105.82538272144947,
              21.014706662139087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1192",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014631347896177,
          "to_latitude": 21.014706662139087,
          "to_longitude": 105.82538272144947,
          "from_longitude": 105.82551891771685
        }
      },
      {
        "id": 1192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82543947749328,
            21.01351220481781,
            105.82596755012234,
            21.013773021644692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82596755012234,
              21.01351220481781
            ],
            [
              105.82588632096476,
              21.01355965721895
            ],
            [
              105.82588383072698,
              21.01355969098419
            ],
            [
              105.8258797899773,
              21.013559744920812
            ],
            [
              105.82587177292012,
              21.01355985157775
            ],
            [
              105.82586225152713,
              21.013559979084672
            ],
            [
              105.82584969548122,
              21.013560146611223
            ],
            [
              105.82577493182416,
              21.013596674668687
            ],
            [
              105.8256795983209,
              21.01363984458661
            ],
            [
              105.82566363679366,
              21.0136481793402
            ],
            [
              105.82560557924995,
              21.01367849515295
            ],
            [
              105.82558649073545,
              21.013697885208344
            ],
            [
              105.82555529351492,
              21.013715706401758
            ],
            [
              105.8255239342376,
              21.013733621408235
            ],
            [
              105.82543947749328,
              21.013773021644692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1193",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01351220481781,
          "to_latitude": 21.013773021644692,
          "to_longitude": 105.82543947749328,
          "from_longitude": 105.82596755012234
        }
      },
      {
        "id": 1193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605636531308,
            21.012687101830593,
            105.8260633393074,
            21.01270109146445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605636531308,
              21.012687101830593
            ],
            [
              105.8260591053249,
              21.012692601821627
            ],
            [
              105.82606252032635,
              21.012699448858957
            ],
            [
              105.8260633393074,
              21.01270109146445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1194",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012687101830593,
          "to_latitude": 21.01270109146445,
          "to_longitude": 105.8260633393074,
          "from_longitude": 105.82605636531308
        }
      },
      {
        "id": 1194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82505061818979,
            21.012308622717786,
            105.82506380500352,
            21.012315988343765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82505061818979,
              21.012315988343765
            ],
            [
              105.82506151114332,
              21.01230990377726
            ],
            [
              105.82506380500352,
              21.012308622717786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1195",
          "diaChiLapD": "Ngõ 136, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012315988343765,
          "to_latitude": 21.012308622717786,
          "to_longitude": 105.82506380500352,
          "from_longitude": 105.82505061818979
        }
      },
      {
        "id": 1195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591125766429,
            21.012028352529246,
            105.82608678475914,
            21.012307454557376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82608678475914,
              21.012307454557376
            ],
            [
              105.82607525415614,
              21.012289092849244
            ],
            [
              105.826024723139,
              21.012208626760184
            ],
            [
              105.82601689762733,
              21.012196164096682
            ],
            [
              105.82599792332563,
              21.012166022510687
            ],
            [
              105.82591125766429,
              21.012028352529246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Tây Sơn",
          "maTaiSan": "04.O12.DODV.1196",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012307454557376,
          "to_latitude": 21.012028352529246,
          "to_longitude": 105.82591125766429,
          "from_longitude": 105.82608678475914
        }
      },
      {
        "id": 1196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82449059374925,
            21.011051755755876,
            105.82471259971383,
            21.011295712900687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82471259971383,
              21.011051755755876
            ],
            [
              105.82459375890016,
              21.01118234667694
            ],
            [
              105.82452623468417,
              21.01125654699844
            ],
            [
              105.82449059374925,
              21.011295712900687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1A_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1197",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011051755755876,
          "to_latitude": 21.011295712900687,
          "to_longitude": 105.82449059374925,
          "from_longitude": 105.82471259971383
        }
      },
      {
        "id": 1197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82452623468417,
            21.01125654699844,
            105.82454455327012,
            21.01127141747326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82454455327012,
              21.01127141747326
            ],
            [
              105.8245391617635,
              21.011267042175213
            ],
            [
              105.82453215568557,
              21.011261353279984
            ],
            [
              105.82452623468417,
              21.01125654699844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1A_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1198",
          "diaChiLapD": "Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01127141747326,
          "to_latitude": 21.01125654699844,
          "to_longitude": 105.82452623468417,
          "from_longitude": 105.82454455327012
        }
      },
      {
        "id": 1198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81908043490853,
            21.01377499779728,
            105.81940532819645,
            21.01420605836775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81940532819645,
              21.01420605836775
            ],
            [
              105.81908043490853,
              21.013843785626143
            ],
            [
              105.8191225034526,
              21.01381305762434
            ],
            [
              105.81911276280745,
              21.013800519851735
            ],
            [
              105.81909278369133,
              21.01377499779728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A98_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1199",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01420605836775,
          "to_latitude": 21.01377499779728,
          "to_longitude": 105.81909278369133,
          "from_longitude": 105.81940532819645
        }
      },
      {
        "id": 1199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82036676558774,
            21.014150031101344,
            105.82069724974814,
            21.01445120189222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82069724974814,
              21.014150031101344
            ],
            [
              105.82069496022864,
              21.01415211776114
            ],
            [
              105.82036676558774,
              21.01445120189222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21A_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.1200",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014150031101344,
          "to_latitude": 21.01445120189222,
          "to_longitude": 105.82036676558774,
          "from_longitude": 105.82069724974814
        }
      },
      {
        "id": 1200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82105370493979,
            21.01343081028901,
            105.82141474088637,
            21.01378101640899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141474088637,
              21.01378101640899
            ],
            [
              105.82132652248396,
              21.013695444237847
            ],
            [
              105.8212287921715,
              21.01360064630296
            ],
            [
              105.82105370493979,
              21.01343081028901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1201",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01378101640899,
          "to_latitude": 21.01343081028901,
          "to_longitude": 105.82105370493979,
          "from_longitude": 105.82141474088637
        }
      },
      {
        "id": 1201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82079161421439,
            21.013361153302025,
            105.82098480235284,
            21.013456157591456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82095701506886,
              21.01341480470001
            ],
            [
              105.82098480235284,
              21.01338460219073
            ],
            [
              105.82096069805326,
              21.013361153302025
            ],
            [
              105.82089209655334,
              21.013417242314937
            ],
            [
              105.82084202712605,
              21.013404007728397
            ],
            [
              105.82079161421439,
              21.013456157591456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33C_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1202",
          "diaChiLapD": "Ngõ 183, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01341480470001,
          "to_latitude": 21.013456157591456,
          "to_longitude": 105.82079161421439,
          "from_longitude": 105.82095701506886
        }
      },
      {
        "id": 1202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82084970514735,
            21.012844867188154,
            105.82099282503867,
            21.013000544269815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82099282503867,
              21.012844867188154
            ],
            [
              105.82084970514735,
              21.013000544269815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DODV.1203",
          "diaChiLapD": "Ngõ 42, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012844867188154,
          "to_latitude": 21.013000544269815,
          "to_longitude": 105.82084970514735,
          "from_longitude": 105.82099282503867
        }
      },
      {
        "id": 1203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82239711722607,
            21.01203522772667,
            105.82278595625608,
            21.012811672192104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8224507817324,
              21.012811672192104
            ],
            [
              105.82245802483303,
              21.012804097945345
            ],
            [
              105.82261328271953,
              21.01264166344859
            ],
            [
              105.82261591021202,
              21.012622456269963
            ],
            [
              105.8227655883667,
              21.012478918833786
            ],
            [
              105.82278595625608,
              21.012456861089987
            ],
            [
              105.82272819897328,
              21.012400233464522
            ],
            [
              105.82266647771507,
              21.01233887762255
            ],
            [
              105.8225884509771,
              21.01225628171898
            ],
            [
              105.82258961282889,
              21.012245378668574
            ],
            [
              105.82260417351753,
              21.012227336767776
            ],
            [
              105.82253367108324,
              21.012163356096277
            ],
            [
              105.82239711722607,
              21.01203522772667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1204",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 117.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012811672192104,
          "to_latitude": 21.01203522772667,
          "to_longitude": 105.82239711722607,
          "from_longitude": 105.8224507817324
        }
      },
      {
        "id": 1204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82237704362404,
            21.01205885471444,
            105.82277147872972,
            21.012794739136112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243233641495,
              21.012794739136112
            ],
            [
              105.82243925360156,
              21.01278789898253
            ],
            [
              105.82277147872972,
              21.012459197294007
            ],
            [
              105.82258548998406,
              21.0122836105207
            ],
            [
              105.82258475160877,
              21.0122566627006
            ],
            [
              105.82237704362404,
              21.01205885471444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48A_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1205",
          "diaChiLapD": "Ngõ 155, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 112.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012794739136112,
          "to_latitude": 21.01205885471444,
          "to_longitude": 105.82237704362404,
          "from_longitude": 105.82243233641495
        }
      },
      {
        "id": 1205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82277156636995,
            21.012246968462875,
            105.8228905188285,
            21.012359944875833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8228905188285,
              21.012246968462875
            ],
            [
              105.82277156636995,
              21.012359944875833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Ngõ 117, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1206",
          "diaChiLapD": "Ngõ 117, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012246968462875,
          "to_latitude": 21.012359944875833,
          "to_longitude": 105.82277156636995,
          "from_longitude": 105.8228905188285
        }
      },
      {
        "id": 1206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241789410298,
            21.010681676235095,
            105.82429643668651,
            21.010786792973125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8241789410298,
              21.010786792973125
            ],
            [
              105.82429643668651,
              21.010681676235095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A108_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.1207",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010786792973125,
          "to_latitude": 21.010681676235095,
          "to_longitude": 105.82429643668651,
          "from_longitude": 105.8241789410298
        }
      },
      {
        "id": 1207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82631906871505,
            21.0145622123799,
            105.82660845930772,
            21.014718791482707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631906871505,
              21.014718791482707
            ],
            [
              105.82657195597623,
              21.01458299231444
            ],
            [
              105.82660845930772,
              21.0145622123799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1208",
          "diaChiLapD": "Ngõ 180, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014718791482707,
          "to_latitude": 21.0145622123799,
          "to_longitude": 105.82660845930772,
          "from_longitude": 105.82631906871505
        }
      },
      {
        "id": 1208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81896405090694,
            21.01429856636061,
            105.81929475205078,
            21.014729016320896
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81896908644853,
                21.01434302690109
              ],
              [
                105.81896838475699,
                21.01434224085802
              ],
              [
                105.81896405090694,
                21.014337243387946
              ]
            ],
            [
              [
                105.81901113308086,
                21.01429856636061
              ],
              [
                105.81901598161842,
                21.01430445464375
              ],
              [
                105.81902161850802,
                21.014311320939836
              ],
              [
                105.81929475205078,
                21.014616532334724
              ],
              [
                105.81922496484202,
                21.014679423195933
              ],
              [
                105.81918790112218,
                21.014729016320896
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1209",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81896838475699,
            21.01434224085802
          ],
          "to_latitude": [
            105.81901598161842,
            21.01430445464375
          ],
          "to_longitude": [
            105.81901113308086,
            21.01429856636061
          ],
          "from_longitude": [
            105.81896908644853,
            21.01434302690109
          ]
        }
      },
      {
        "id": 1209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81970629156477,
            21.020859712809955,
            105.81994841280269,
            21.021362576023787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994841280269,
              21.021362576023787
            ],
            [
              105.81970629156477,
              21.020859712809955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.1210",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021362576023787,
          "to_latitude": 21.020859712809955,
          "to_longitude": 105.81970629156477,
          "from_longitude": 105.81994841280269
        }
      },
      {
        "id": 1210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82674048146914,
            21.017443106756954,
            105.82719448078537,
            21.017687163249434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82719448078537,
              21.017443106756954
            ],
            [
              105.827174565256,
              21.017454217528783
            ],
            [
              105.82716010612667,
              21.01746641544501
            ],
            [
              105.82715700671979,
              21.017469030901918
            ],
            [
              105.82674048146914,
              21.017687163249434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT99_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1211",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017443106756954,
          "to_latitude": 21.017687163249434,
          "to_longitude": 105.82674048146914,
          "from_longitude": 105.82719448078537
        }
      },
      {
        "id": 1211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82704747839121,
            21.017257787565956,
            105.82708540783611,
            21.01727770744965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82704747839121,
              21.01727770744965
            ],
            [
              105.82704923943115,
              21.0172767830173
            ],
            [
              105.82706827608962,
              21.01726678479108
            ],
            [
              105.82708540783611,
              21.017257787565956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1212",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01727770744965,
          "to_latitude": 21.017257787565956,
          "to_longitude": 105.82708540783611,
          "from_longitude": 105.82704747839121
        }
      },
      {
        "id": 1212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8267184352547,
            21.016609052415347,
            105.82675419122567,
            21.016628682564374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8267184352547,
              21.016628682564374
            ],
            [
              105.82674016331609,
              21.016616753748416
            ],
            [
              105.8267468085211,
              21.016613105841788
            ],
            [
              105.82675345276385,
              21.01660945793958
            ],
            [
              105.82675419122567,
              21.016609052415347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1213",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016628682564374,
          "to_latitude": 21.016609052415347,
          "to_longitude": 105.82675419122567,
          "from_longitude": 105.8267184352547
        }
      },
      {
        "id": 1213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82592313477197,
            21.016240616074942,
            105.82653812731229,
            21.016593161243506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82592313477197,
              21.016593161243506
            ],
            [
              105.82630132331383,
              21.0163936041003
            ],
            [
              105.82649158400888,
              21.016293209426525
            ],
            [
              105.82650037473802,
              21.016263448781167
            ],
            [
              105.82652473429674,
              21.016248664756972
            ],
            [
              105.82653812731229,
              21.016240616074942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT110_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1214",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016593161243506,
          "to_latitude": 21.016240616074942,
          "to_longitude": 105.82653812731229,
          "from_longitude": 105.82592313477197
        }
      },
      {
        "id": 1214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82579341787631,
            21.01603778856072,
            105.82611618939518,
            21.016211115772343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82579341787631,
              21.016211115772343
            ],
            [
              105.82611618939518,
              21.01603778856072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT112_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1215",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016211115772343,
          "to_latitude": 21.01603778856072,
          "to_longitude": 105.82611618939518,
          "from_longitude": 105.82579341787631
        }
      },
      {
        "id": 1215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612244447925,
            21.01677946982106,
            105.82651413825197,
            21.01699268477473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82612244447925,
              21.01699268477473
            ],
            [
              105.82651413825197,
              21.01677946982106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1216",
          "diaChiLapD": "Ngách 64/49, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01699268477473,
          "to_latitude": 21.01677946982106,
          "to_longitude": 105.82651413825197,
          "from_longitude": 105.82612244447925
        }
      },
      {
        "id": 1216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279529350902,
            21.015389687234983,
            105.82798452130888,
            21.01540779708232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82798452130888,
              21.015389687234983
            ],
            [
              105.82796529992518,
              21.015400845295073
            ],
            [
              105.82796526540606,
              21.015400865334026
            ],
            [
              105.82795598170728,
              21.015406084191305
            ],
            [
              105.8279529350902,
              21.01540779708232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.1217",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015389687234983,
          "to_latitude": 21.01540779708232,
          "to_longitude": 105.8279529350902,
          "from_longitude": 105.82798452130888
        }
      },
      {
        "id": 1217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82223850455134,
            21.018706807635304,
            105.82237379164953,
            21.018750121897497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82223850455134,
              21.018750121897497
            ],
            [
              105.8222643559858,
              21.018742181322107
            ],
            [
              105.82228069235515,
              21.018737163513507
            ],
            [
              105.82229118024625,
              21.018733942491735
            ],
            [
              105.82237379164953,
              21.018706807635304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT173_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1218",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018750121897497,
          "to_latitude": 21.018706807635304,
          "to_longitude": 105.82237379164953,
          "from_longitude": 105.82223850455134
        }
      },
      {
        "id": 1218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82197506802191,
            21.01875415564467,
            105.82222001761546,
            21.018827830577266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82197506802191,
              21.018827830577266
            ],
            [
              105.82197531488748,
              21.018827765255327
            ],
            [
              105.82213797038165,
              21.018775945117106
            ],
            [
              105.82213910775985,
              21.01877583213619
            ],
            [
              105.82222001761546,
              21.01875415564467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1219",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018827830577266,
          "to_latitude": 21.01875415564467,
          "to_longitude": 105.82222001761546,
          "from_longitude": 105.82197506802191
        }
      },
      {
        "id": 1219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82144339061558,
            21.020987828067348,
            105.82182337498699,
            21.0210659572862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82144339061558,
              21.0210659572862
            ],
            [
              105.82150331560737,
              21.02105380309588
            ],
            [
              105.82150506490358,
              21.021053530001975
            ],
            [
              105.82151689187066,
              21.021051682686103
            ],
            [
              105.82167316828996,
              21.021027267820145
            ],
            [
              105.82182337498699,
              21.020987828067348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1220",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0210659572862,
          "to_latitude": 21.020987828067348,
          "to_longitude": 105.82182337498699,
          "from_longitude": 105.82144339061558
        }
      },
      {
        "id": 1220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82110445494675,
            21.019081836063595,
            105.82111776037668,
            21.01910846489094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82111776037668,
              21.01910846489094
            ],
            [
              105.82111217075504,
              21.01909728192815
            ],
            [
              105.82110552871328,
              21.019083984172546
            ],
            [
              105.82110445494675,
              21.019081836063595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1221",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01910846489094,
          "to_latitude": 21.019081836063595,
          "to_longitude": 105.82110445494675,
          "from_longitude": 105.82111776037668
        }
      },
      {
        "id": 1221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82030778408902,
            21.019417475236455,
            105.82061757675893,
            21.019429957209276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82030778408902,
              21.019429957209276
            ],
            [
              105.82036142290193,
              21.019427796338565
            ],
            [
              105.82061757675893,
              21.019417475236455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1222",
          "diaChiLapD": "Ngõ 148, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019429957209276,
          "to_latitude": 21.019417475236455,
          "to_longitude": 105.82061757675893,
          "from_longitude": 105.82030778408902
        }
      },
      {
        "id": 1222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82126398953723,
            21.02115822918183,
            105.82128319781984,
            21.021356447892217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82128319781984,
              21.021356447892217
            ],
            [
              105.82127597229568,
              21.021245596401958
            ],
            [
              105.82126398953723,
              21.02115822918183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngách 44/22, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1223",
          "diaChiLapD": "Ngách 44/22, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021356447892217,
          "to_latitude": 21.02115822918183,
          "to_longitude": 105.82126398953723,
          "from_longitude": 105.82128319781984
        }
      },
      {
        "id": 1223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8219770730488,
            21.0223706589402,
            105.8220493864289,
            21.022579616606503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8219770730488,
              21.022579616606503
            ],
            [
              105.82202025665092,
              21.022455061682393
            ],
            [
              105.82203913390288,
              21.022400364112034
            ],
            [
              105.82204083839879,
              21.02239542606638
            ],
            [
              105.8220493864289,
              21.0223706589402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1224",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022579616606503,
          "to_latitude": 21.0223706589402,
          "to_longitude": 105.8220493864289,
          "from_longitude": 105.8219770730488
        }
      },
      {
        "id": 1224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82196887385861,
            21.022579616606503,
            105.8219770730488,
            21.02260326926884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82196887385861,
              21.02260326926884
            ],
            [
              105.8219770730488,
              21.022579616606503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1225",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02260326926884,
          "to_latitude": 21.022579616606503,
          "to_longitude": 105.8219770730488,
          "from_longitude": 105.82196887385861
        }
      },
      {
        "id": 1225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8217177995163,
            21.02215504168292,
            105.821846977372,
            21.02235936948166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.821846977372,
              21.02235936948166
            ],
            [
              105.8217177995163,
              21.02215504168292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1226",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02235936948166,
          "to_latitude": 21.02215504168292,
          "to_longitude": 105.8217177995163,
          "from_longitude": 105.821846977372
        }
      },
      {
        "id": 1226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.821846977372,
            21.02235936948166,
            105.8218965970068,
            21.02237509784651
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8218965970068,
              21.02237509784651
            ],
            [
              105.821846977372,
              21.02235936948166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1227",
          "diaChiLapD": "Ngõ 12, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02237509784651,
          "to_latitude": 21.02235936948166,
          "to_longitude": 105.821846977372,
          "from_longitude": 105.8218965970068
        }
      },
      {
        "id": 1227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82134108138557,
            21.021018629441546,
            105.82182888851999,
            21.021134902347193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82134108138557,
              21.021134902347193
            ],
            [
              105.82135421440655,
              21.02113176796094
            ],
            [
              105.82139995229028,
              21.02112085289429
            ],
            [
              105.82151831414802,
              21.021094614289197
            ],
            [
              105.82152305078003,
              21.021093537347063
            ],
            [
              105.82167236328527,
              21.021059600885007
            ],
            [
              105.82182888851999,
              21.021018629441546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1228",
          "diaChiLapD": "Ngõ 44, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021134902347193,
          "to_latitude": 21.021018629441546,
          "to_longitude": 105.82182888851999,
          "from_longitude": 105.82134108138557
        }
      },
      {
        "id": 1228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8220143947894,
            21.021058449196456,
            105.82231438979572,
            21.02119065117523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82231438979572,
              21.021058449196456
            ],
            [
              105.82221275003918,
              21.021089410875554
            ],
            [
              105.82207063255974,
              21.021165868535228
            ],
            [
              105.8220143947894,
              21.02119065117523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT130_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1229",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021058449196456,
          "to_latitude": 21.02119065117523,
          "to_longitude": 105.8220143947894,
          "from_longitude": 105.82231438979572
        }
      },
      {
        "id": 1229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82013437025864,
            21.020281675777607,
            105.82046222455651,
            21.02042188948165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82013437025864,
              21.02042188948165
            ],
            [
              105.82046222455651,
              21.020281675777607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66*_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1230",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02042188948165,
          "to_latitude": 21.020281675777607,
          "to_longitude": 105.82046222455651,
          "from_longitude": 105.82013437025864
        }
      },
      {
        "id": 1230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82036859495217,
            21.020084695598644,
            105.82049732265223,
            21.020281700122307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82036859495217,
              21.020084695598644
            ],
            [
              105.82046222455651,
              21.020281675777607
            ],
            [
              105.82046223334746,
              21.020281700122307
            ],
            [
              105.8204735436508,
              21.02027720801543
            ],
            [
              105.8204958889481,
              21.020267311062234
            ],
            [
              105.82049732265223,
              21.020266675500892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66*_Ngách 82/41, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1231",
          "diaChiLapD": "Ngách 82/41, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020084695598644,
          "to_latitude": 21.020266675500892,
          "to_longitude": 105.82049732265223,
          "from_longitude": 105.82036859495217
        }
      },
      {
        "id": 1231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82035270661599,
            21.020021799816067,
            105.82038031106188,
            21.020034843668206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82035270661599,
              21.020034843668206
            ],
            [
              105.8203533801749,
              21.020034525193722
            ],
            [
              105.82035620778474,
              21.020033189233146
            ],
            [
              105.82036642536305,
              21.020028360984018
            ],
            [
              105.82038031106188,
              21.020021799816067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT170_Ngách 82/41, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1232",
          "diaChiLapD": "Ngách 82/41, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020034843668206,
          "to_latitude": 21.020021799816067,
          "to_longitude": 105.82038031106188,
          "from_longitude": 105.82035270661599
        }
      },
      {
        "id": 1232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82021467203059,
            21.019729000280964,
            105.8203607489813,
            21.02003278520189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82035121103941,
              21.02003278520189
            ],
            [
              105.82035460744386,
              21.020030971401006
            ],
            [
              105.8203607489813,
              21.020027691972537
            ],
            [
              105.82021467203059,
              21.019729000280964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT170*_Ngách 82/41, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1233",
          "diaChiLapD": "Ngách 82/41, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02003278520189,
          "to_latitude": 21.019729000280964,
          "to_longitude": 105.82021467203059,
          "from_longitude": 105.82035121103941
        }
      },
      {
        "id": 1233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227054394122,
            21.021787819667388,
            105.8223218498603,
            21.02192451716062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227054394122,
              21.02192451716062
            ],
            [
              105.8223218498603,
              21.021787819667388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT155_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1234",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02192451716062,
          "to_latitude": 21.021787819667388,
          "to_longitude": 105.8223218498603,
          "from_longitude": 105.82227054394122
        }
      },
      {
        "id": 1234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82249562882343,
            21.022223722514624,
            105.82259164520332,
            21.02224931189137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82259164520332,
              21.022223722514624
            ],
            [
              105.82254990193145,
              21.02223616852492
            ],
            [
              105.82249562882343,
              21.02224931189137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT154_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1235",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022223722514624,
          "to_latitude": 21.02224931189137,
          "to_longitude": 105.82249562882343,
          "from_longitude": 105.82259164520332
        }
      },
      {
        "id": 1235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82216013293285,
            21.02208781963684,
            105.82268142339237,
            21.022192049321557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82268142339237,
              21.022192049321557
            ],
            [
              105.82265026248005,
              21.02208781963684
            ],
            [
              105.82256953581938,
              21.02210409780926
            ],
            [
              105.82231868260824,
              21.022151021257564
            ],
            [
              105.8221890356016,
              21.022164196533716
            ],
            [
              105.82218107132255,
              21.022161733096443
            ],
            [
              105.82217524145938,
              21.022159931341445
            ],
            [
              105.82216013293285,
              21.02215525701753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82A_Ngõ 23, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1236",
          "diaChiLapD": "Ngõ 23, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022192049321557,
          "to_latitude": 21.02215525701753,
          "to_longitude": 105.82216013293285,
          "from_longitude": 105.82268142339237
        }
      },
      {
        "id": 1236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227309455484,
            21.021584620869035,
            105.82234095254375,
            21.02171321929241
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82234095254375,
              21.02171321929241
            ],
            [
              105.82227309455484,
              21.021584620869035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1237",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02171321929241,
          "to_latitude": 21.021584620869035,
          "to_longitude": 105.82227309455484,
          "from_longitude": 105.82234095254375
        }
      },
      {
        "id": 1237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227309455484,
            21.021545019353162,
            105.82233970303525,
            21.021584620869035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227309455484,
              21.021584620869035
            ],
            [
              105.82233970303525,
              21.021545019353162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1238",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021584620869035,
          "to_latitude": 21.021545019353162,
          "to_longitude": 105.82233970303525,
          "from_longitude": 105.82227309455484
        }
      },
      {
        "id": 1238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8209722680011,
            21.02198211270828,
            105.82100218647697,
            21.02199178706052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8209722680011,
              21.02199178706052
            ],
            [
              105.82100218647697,
              21.02198211270828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Ngách 371/9, La Thành",
          "maTaiSan": "04.O12.DODV.1239",
          "diaChiLapD": "Ngách 371/9, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02199178706052,
          "to_latitude": 21.02198211270828,
          "to_longitude": 105.82100218647697,
          "from_longitude": 105.8209722680011
        }
      },
      {
        "id": 1239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82182793580466,
            21.02074900796528,
            105.82205269501851,
            21.02080617807927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82182793580466,
              21.02080617807927
            ],
            [
              105.82205269501851,
              21.02074900796528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Ngõ 71, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1240",
          "diaChiLapD": "Ngõ 71, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02080617807927,
          "to_latitude": 21.02074900796528,
          "to_longitude": 105.82205269501851,
          "from_longitude": 105.82182793580466
        }
      },
      {
        "id": 1240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81930821226632,
            21.019382034277328,
            105.81962512769037,
            21.019442305429237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81962512769037,
              21.019442305429237
            ],
            [
              105.81930821226632,
              21.019382034277328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT174_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1241",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019442305429237,
          "to_latitude": 21.019382034277328,
          "to_longitude": 105.81930821226632,
          "from_longitude": 105.81962512769037
        }
      },
      {
        "id": 1241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81917934733197,
            21.02001617637882,
            105.81929205558784,
            21.020061204926982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81917934733197,
              21.020061204926982
            ],
            [
              105.81929205558784,
              21.02001617637882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48\"_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1242",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020061204926982,
          "to_latitude": 21.02001617637882,
          "to_longitude": 105.81929205558784,
          "from_longitude": 105.81917934733197
        }
      },
      {
        "id": 1242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82313758965265,
            21.021254298070417,
            105.82323062083564,
            21.021377575333858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82313758965265,
              21.021254298070417
            ],
            [
              105.82315583377137,
              21.02125977907069
            ],
            [
              105.8231899147126,
              21.021280824588278
            ],
            [
              105.82323062083564,
              21.021377575333858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT160_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1243",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021254298070417,
          "to_latitude": 21.021377575333858,
          "to_longitude": 105.82323062083564,
          "from_longitude": 105.82313758965265
        }
      },
      {
        "id": 1243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82302214448943,
            21.021253066667224,
            105.82313758965265,
            21.021259817229414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82302214448943,
              21.021259817229414
            ],
            [
              105.82305477995054,
              21.021253066667224
            ],
            [
              105.82305953153349,
              21.02125313761032
            ],
            [
              105.82313758965265,
              21.021254298070417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT160_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1244",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021259817229414,
          "to_latitude": 21.021254298070417,
          "to_longitude": 105.82313758965265,
          "from_longitude": 105.82302214448943
        }
      },
      {
        "id": 1244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8221738979189,
            21.021510325916058,
            105.82227602639979,
            21.021675091881942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8221738979189,
              21.021510325916058
            ],
            [
              105.8222105489456,
              21.02157799367399
            ],
            [
              105.82223581769502,
              21.021618784954846
            ],
            [
              105.82227602639979,
              21.021675091881942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1245",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021510325916058,
          "to_latitude": 21.021675091881942,
          "to_longitude": 105.82227602639979,
          "from_longitude": 105.8221738979189
        }
      },
      {
        "id": 1245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82240704187909,
            21.02083688203533,
            105.82249744190243,
            21.021044868338503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82243272290737,
              21.02083688203533
            ],
            [
              105.82249744190243,
              21.02100935353957
            ],
            [
              105.82240704187909,
              21.021044868338503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1246",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02083688203533,
          "to_latitude": 21.021044868338503,
          "to_longitude": 105.82240704187909,
          "from_longitude": 105.82243272290737
        }
      },
      {
        "id": 1246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8223063240042,
            21.020772434540092,
            105.82262782864706,
            21.020840288629838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223063240042,
              21.020840288629838
            ],
            [
              105.82243272290737,
              21.02083688203533
            ],
            [
              105.82244018767439,
              21.0208366815548
            ],
            [
              105.82246185460934,
              21.02083518220867
            ],
            [
              105.82262782864706,
              21.020772434540092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Ngõ 69, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1247",
          "diaChiLapD": "Ngõ 69, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020840288629838,
          "to_latitude": 21.020772434540092,
          "to_longitude": 105.82262782864706,
          "from_longitude": 105.8223063240042
        }
      },
      {
        "id": 1247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204084344093,
            21.022395335003605,
            105.82055626170632,
            21.022741859003453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82055626170632,
              21.022637802983134
            ],
            [
              105.82050045453624,
              21.02253503848352
            ],
            [
              105.82047538407696,
              21.02247927139523
            ],
            [
              105.82045701849175,
              21.02243841764469
            ],
            [
              105.82043728528755,
              21.022395335003605
            ],
            [
              105.82042574243643,
              21.022401836105267
            ],
            [
              105.82041521564639,
              21.022563795613053
            ],
            [
              105.8204084344093,
              21.02261210576724
            ],
            [
              105.82046136955908,
              21.022741859003453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DODV.1248",
          "diaChiLapD": "Ngõ 371, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022637802983134,
          "to_latitude": 21.022741859003453,
          "to_longitude": 105.82046136955908,
          "from_longitude": 105.82055626170632
        }
      },
      {
        "id": 1248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82032851953164,
            21.020313487684444,
            105.82044999690025,
            21.020362745740822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82044999690025,
              21.020313487684444
            ],
            [
              105.82032851953164,
              21.020362745740822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1249",
          "diaChiLapD": "Ngõ 82, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020313487684444,
          "to_latitude": 21.020362745740822,
          "to_longitude": 105.82032851953164,
          "from_longitude": 105.82044999690025
        }
      },
      {
        "id": 1249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82254510251045,
            21.011402315587816,
            105.82284666298935,
            21.011658430350582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82254510251045,
              21.011658430350582
            ],
            [
              105.8226926462098,
              21.01153312229845
            ],
            [
              105.82269558750637,
              21.01153062420311
            ],
            [
              105.82284666298935,
              21.011402315587816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A85_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.1250",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011658430350582,
          "to_latitude": 21.011402315587816,
          "to_longitude": 105.82284666298935,
          "from_longitude": 105.82254510251045
        }
      },
      {
        "id": 1250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8226926462098,
            21.01153312229845,
            105.82269819152263,
            21.011539065817832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8226926462098,
              21.01153312229845
            ],
            [
              105.82269797769257,
              21.011538838335245
            ],
            [
              105.82269819152263,
              21.011539065817832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A85_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.1251",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01153312229845,
          "to_latitude": 21.011539065817832,
          "to_longitude": 105.82269819152263,
          "from_longitude": 105.8226926462098
        }
      },
      {
        "id": 1251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8225491600211,
            21.011657822077748,
            105.82277371297658,
            21.011911216762677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82255620927457,
              21.011657822077748
            ],
            [
              105.82255715795127,
              21.011658916719014
            ],
            [
              105.8225745117663,
              21.01167890524153
            ],
            [
              105.8225491600211,
              21.011705478800582
            ],
            [
              105.82277371297658,
              21.011911216762677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A84_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.1252",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011657822077748,
          "to_latitude": 21.011911216762677,
          "to_longitude": 105.82277371297658,
          "from_longitude": 105.82255620927457
        }
      },
      {
        "id": 1252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8225589593012,
            21.011654999747563,
            105.82278418725696,
            21.01189051834543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8225589593012,
              21.011654999747563
            ],
            [
              105.82256021166587,
              21.011656219372526
            ],
            [
              105.822587743212,
              21.011682999733992
            ],
            [
              105.8225714389883,
              21.011698476767958
            ],
            [
              105.82278418725696,
              21.01189051834543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A84A_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DODV.1253",
          "diaChiLapD": "Ngõ 98, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011654999747563,
          "to_latitude": 21.01189051834543,
          "to_longitude": 105.82278418725696,
          "from_longitude": 105.8225589593012
        }
      },
      {
        "id": 1253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81922567578108,
            21.019797750933204,
            105.81940211267684,
            21.019821207623554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81922567578108,
              21.019821207623554
            ],
            [
              105.81940211267684,
              21.019797750933204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1254",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019821207623554,
          "to_latitude": 21.019797750933204,
          "to_longitude": 105.81940211267684,
          "from_longitude": 105.81922567578108
        }
      },
      {
        "id": 1254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81918199005301,
            21.01964669963133,
            105.81927017941341,
            21.019986934101446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81927017941341,
              21.019986934101446
            ],
            [
              105.81924338231292,
              21.01988224008784
            ],
            [
              105.81924328735164,
              21.019881937964225
            ],
            [
              105.81923187018135,
              21.01983623006454
            ],
            [
              105.81923045495695,
              21.019830563713192
            ],
            [
              105.81922567578108,
              21.019821207623554
            ],
            [
              105.81921849509571,
              21.019796450438072
            ],
            [
              105.81920488950723,
              21.019737366351247
            ],
            [
              105.81918199005301,
              21.01964669963133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1255",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019986934101446,
          "to_latitude": 21.01964669963133,
          "to_longitude": 105.81918199005301,
          "from_longitude": 105.81927017941341
        }
      },
      {
        "id": 1255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82029314409253,
            21.021726758475026,
            105.82093135274823,
            21.021910423240445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82029314409253,
              21.021910423240445
            ],
            [
              105.8203016783252,
              21.02190787924141
            ],
            [
              105.82047773929233,
              21.02185680072181
            ],
            [
              105.82052920692728,
              21.021843408692995
            ],
            [
              105.82093135274823,
              21.021726758475026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Ngách 371/17, La Thành",
          "maTaiSan": "04.O12.DODV.1256",
          "diaChiLapD": "Ngách 371/17, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021910423240445,
          "to_latitude": 21.021726758475026,
          "to_longitude": 105.82093135274823,
          "from_longitude": 105.82029314409253
        }
      },
      {
        "id": 1256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82267116316525,
            21.021304005737818,
            105.82280670545951,
            21.02136677982626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82267116316525,
              21.02136677982626
            ],
            [
              105.82274303785312,
              21.02133166339696
            ],
            [
              105.82278650074531,
              21.02131042876718
            ],
            [
              105.82280670545951,
              21.021304005737818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1257",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02136677982626,
          "to_latitude": 21.021304005737818,
          "to_longitude": 105.82280670545951,
          "from_longitude": 105.82267116316525
        }
      },
      {
        "id": 1257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82210688642618,
            21.021329563823365,
            105.82223798874269,
            21.021537886049042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82223798874269,
              21.021537886049042
            ],
            [
              105.82218701619925,
              21.021457306359576
            ],
            [
              105.82215270789293,
              21.02140477617894
            ],
            [
              105.8221437647297,
              21.021390097724115
            ],
            [
              105.82210688642618,
              21.021329563823365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT130_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1258",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021537886049042,
          "to_latitude": 21.021329563823365,
          "to_longitude": 105.82210688642618,
          "from_longitude": 105.82223798874269
        }
      },
      {
        "id": 1258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82125126143602,
            21.021136233851042,
            105.82126398953723,
            21.02115822918183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82126398953723,
              21.02115822918183
            ],
            [
              105.82125846360239,
              21.02114867985777
            ],
            [
              105.82125202382264,
              21.02113755520299
            ],
            [
              105.82125126143602,
              21.021136233851042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngách 44/22, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.1259",
          "diaChiLapD": "Ngách 44/22, Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02115822918183,
          "to_latitude": 21.021136233851042,
          "to_longitude": 105.82125126143602,
          "from_longitude": 105.82126398953723
        }
      },
      {
        "id": 1259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82181239763761,
            21.018841494737448,
            105.82182465836908,
            21.018924803302383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82182465836908,
              21.018924803302383
            ],
            [
              105.82181633236544,
              21.01887578297747
            ],
            [
              105.82181609478656,
              21.01887438322108
            ],
            [
              105.8218126929945,
              21.018844134344608
            ],
            [
              105.82181239763761,
              21.018841494737448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1260",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018924803302383,
          "to_latitude": 21.018841494737448,
          "to_longitude": 105.82181239763761,
          "from_longitude": 105.82182465836908
        }
      },
      {
        "id": 1260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82180254169994,
            21.018924803302383,
            105.82185714604176,
            21.019051463617462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82182877704491,
              21.019051463617462
            ],
            [
              105.82180254169994,
              21.01900287665529
            ],
            [
              105.82185714604176,
              21.018976826637278
            ],
            [
              105.82182465836908,
              21.018924803302383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.1261",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019051463617462,
          "to_latitude": 21.018924803302383,
          "to_longitude": 105.82182465836908,
          "from_longitude": 105.82182877704491
        }
      },
      {
        "id": 1261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82092018240165,
            21.01399792970838,
            105.82108213855562,
            21.014158394095332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82092018240165,
              21.01399792970838
            ],
            [
              105.82107843402784,
              21.014156963197014
            ],
            [
              105.82107904673262,
              21.01415748230536
            ],
            [
              105.82107974542546,
              21.01415789441795
            ],
            [
              105.82108050888995,
              21.014158189701668
            ],
            [
              105.82108131398124,
              21.014158357429476
            ],
            [
              105.82108213855562,
              21.014158394095332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 185, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.1262",
          "diaChiLapD": "Ngõ 185, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01399792970838,
          "to_latitude": 21.014158394095332,
          "to_longitude": 105.82108213855562,
          "from_longitude": 105.82092018240165
        }
      },
      {
        "id": 1262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82359000740526,
            21.015029237234728,
            105.82371137493864,
            21.01522301587959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82359000740526,
              21.015029237234728
            ],
            [
              105.82371137493864,
              21.01522301587959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D37_Ngõ 26, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1263",
          "diaChiLapD": "Ngõ 26, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015029237234728,
          "to_latitude": 21.01522301587959,
          "to_longitude": 105.82371137493864,
          "from_longitude": 105.82359000740526
        }
      },
      {
        "id": 1263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82346659598244,
            21.015486914192515,
            105.82355785080027,
            21.015646278527424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346659598244,
              21.015486914192515
            ],
            [
              105.82355785080027,
              21.015646278527424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D28_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1264",
          "diaChiLapD": "Ngõ 20, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015486914192515,
          "to_latitude": 21.015646278527424,
          "to_longitude": 105.82355785080027,
          "from_longitude": 105.82346659598244
        }
      },
      {
        "id": 1264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82497437403626,
            21.01638491856489,
            105.82501824880924,
            21.01645566956702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82497437403626,
              21.01638491856489
            ],
            [
              105.82501824880924,
              21.01645566956702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D57_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.1265",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01638491856489,
          "to_latitude": 21.01645566956702,
          "to_longitude": 105.82501824880924,
          "from_longitude": 105.82497437403626
        }
      },
      {
        "id": 1265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82448801136304,
            21.016619178792002,
            105.82452010741554,
            21.01669658508286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82450687447607,
              21.01669658508286
            ],
            [
              105.82451116398529,
              21.016694170744035
            ],
            [
              105.82452010741554,
              21.016689137073996
            ],
            [
              105.82448801136304,
              21.016619178792002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D57_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1266",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01669658508286,
          "to_latitude": 21.016619178792002,
          "to_longitude": 105.82448801136304,
          "from_longitude": 105.82450687447607
        }
      },
      {
        "id": 1266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82406038146466,
            21.015924574217564,
            105.82414532586057,
            21.01606134927378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82414532586057,
              21.01606134927378
            ],
            [
              105.82406038146466,
              21.015924574217564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D20_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1267",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01606134927378,
          "to_latitude": 21.015924574217564,
          "to_longitude": 105.82406038146466,
          "from_longitude": 105.82414532586057
        }
      },
      {
        "id": 1267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82447975137302,
            21.01670181795605,
            105.82457017334609,
            21.016852677394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82456754925745,
              21.016852677394
            ],
            [
              105.82457017334609,
              21.016851259255233
            ],
            [
              105.82447975137302,
              21.01670181795605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1268",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016852677394,
          "to_latitude": 21.01670181795605,
          "to_longitude": 105.82447975137302,
          "from_longitude": 105.82456754925745
        }
      },
      {
        "id": 1268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418236878415,
            21.016132863410302,
            105.82425407525524,
            21.016271581244734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82425407525524,
              21.016271581244734
            ],
            [
              105.82418236878415,
              21.016132863410302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DODV.1269",
          "diaChiLapD": "Ngõ 25, Võ Văn Dũng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016271581244734,
          "to_latitude": 21.016132863410302,
          "to_longitude": 105.82418236878415,
          "from_longitude": 105.82425407525524
        }
      },
      {
        "id": 1269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82640246484287,
            21.01808990052302,
            105.82664127696977,
            21.018275062361955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82640246484287,
              21.018275062361955
            ],
            [
              105.82646578764538,
              21.01824611886039
            ],
            [
              105.82652449686226,
              21.018217170655213
            ],
            [
              105.82656473690054,
              21.018196842235316
            ],
            [
              105.82658783447657,
              21.018179578651488
            ],
            [
              105.82661094461204,
              21.01815551913358
            ],
            [
              105.8266349739372,
              21.01810712224613
            ],
            [
              105.82663823367841,
              21.018100781116456
            ],
            [
              105.82664119362299,
              21.01809502402411
            ],
            [
              105.82664127696977,
              21.01808990052302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A71_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.1270",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018275062361955,
          "to_latitude": 21.01808990052302,
          "to_longitude": 105.82664127696977,
          "from_longitude": 105.82640246484287
        }
      },
      {
        "id": 1270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82637565891511,
            21.01952901790775,
            105.82654660293156,
            21.019824378499344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82637565891511,
              21.01952901790775
            ],
            [
              105.82651634962656,
              21.01977210680815
            ],
            [
              105.8265444358829,
              21.019819713030795
            ],
            [
              105.82654660293156,
              21.019824378499344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A45_Ngõ 143, Đông Các",
          "maTaiSan": "04.O12.DODV.1271",
          "diaChiLapD": "Ngõ 143, Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01952901790775,
          "to_latitude": 21.019824378499344,
          "to_longitude": 105.82654660293156,
          "from_longitude": 105.82637565891511
        }
      },
      {
        "id": 1271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638158156581,
            21.01951973739534,
            105.82655030541105,
            21.019823592408212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265487062642,
              21.019823592408212
            ],
            [
              105.8265484085865,
              21.019822816177506
            ],
            [
              105.82654605036122,
              21.01981662609003
            ],
            [
              105.82655030541105,
              21.01980009625803
            ],
            [
              105.82648327174319,
              21.019706345612743
            ],
            [
              105.82638158156581,
              21.01951973739534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B44_Ngõ 143, Đông Các",
          "maTaiSan": "04.O12.DODV.1272",
          "diaChiLapD": "Ngõ 143, Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019823592408212,
          "to_latitude": 21.01951973739534,
          "to_longitude": 105.82638158156581,
          "from_longitude": 105.8265487062642
        }
      },
      {
        "id": 1272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82691402390391,
            21.02025385540912,
            105.82710675265606,
            21.020362804656653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82691402390391,
              21.020362804656653
            ],
            [
              105.82710675265606,
              21.02025385540912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.1_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.1273",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020362804656653,
          "to_latitude": 21.02025385540912,
          "to_longitude": 105.82710675265606,
          "from_longitude": 105.82691402390391
        }
      },
      {
        "id": 1273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82712777001592,
            21.020193873448097,
            105.82724505862029,
            21.020254954500352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82712777001592,
              21.020254954500352
            ],
            [
              105.82724505862029,
              21.020193873448097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.1274",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020254954500352,
          "to_latitude": 21.020193873448097,
          "to_longitude": 105.82724505862029,
          "from_longitude": 105.82712777001592
        }
      },
      {
        "id": 1274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81883259215927,
            21.01429856636061,
            105.81901113308086,
            21.01445067928416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81883259215927,
              21.01445067928416
            ],
            [
              105.81886757566485,
              21.0144189548575
            ],
            [
              105.81896405090694,
              21.014337243387946
            ],
            [
              105.81899575955417,
              21.01431038051447
            ],
            [
              105.81901113308086,
              21.01429856636061
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B11_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DODV.1275",
          "diaChiLapD": "Ngõ 278, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01445067928416,
          "to_latitude": 21.01429856636061,
          "to_longitude": 105.81901113308086,
          "from_longitude": 105.81883259215927
        }
      },
      {
        "id": 1275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81643237777509,
            21.016971358403207,
            105.81665395344874,
            21.01711812606818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81665395344874,
              21.016971358403207
            ],
            [
              105.81662779142371,
              21.01698868866786
            ],
            [
              105.81662403892854,
              21.01699117429841
            ],
            [
              105.81643237777509,
              21.01711812606818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.1276",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016971358403207,
          "to_latitude": 21.01711812606818,
          "to_longitude": 105.81643237777509,
          "from_longitude": 105.81665395344874
        }
      },
      {
        "id": 1276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80563578650118,
            21.01808238028149,
            105.80609854027078,
            21.01840283750478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80563578650118,
              21.018190770193353
            ],
            [
              105.80566491220726,
              21.018116548492355
            ],
            [
              105.80568972065974,
              21.01809157683847
            ],
            [
              105.80571410497672,
              21.01808238028149
            ],
            [
              105.8057319466131,
              21.018087074762263
            ],
            [
              105.80575804239608,
              21.018112283124978
            ],
            [
              105.80579913776704,
              21.01817110817196
            ],
            [
              105.80588535020536,
              21.018219232986972
            ],
            [
              105.80590778455404,
              21.01819623616928
            ],
            [
              105.80597994076156,
              21.01825203181183
            ],
            [
              105.80596421368972,
              21.018280491203768
            ],
            [
              105.80597551779466,
              21.018335467784468
            ],
            [
              105.80601295716943,
              21.01838020165722
            ],
            [
              105.80603641076621,
              21.018385404225885
            ],
            [
              105.80609854027078,
              21.01840283750478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_134_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1277",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018190770193353,
          "to_latitude": 21.01840283750478,
          "to_longitude": 105.80609854027078,
          "from_longitude": 105.80563578650118
        }
      },
      {
        "id": 1277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7991510645298,
            21.024630623986763,
            105.79955844772647,
            21.0246881625158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79955844772647,
              21.024630623986763
            ],
            [
              105.79955687651672,
              21.024630755115975
            ],
            [
              105.7994531178022,
              21.024639375566746
            ],
            [
              105.79932233039347,
              21.02468077835212
            ],
            [
              105.79928845056638,
              21.0246881625158
            ],
            [
              105.79915538081447,
              21.024650625505572
            ],
            [
              105.7991510645298,
              21.024664923137887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1278",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024630623986763,
          "to_latitude": 21.024664923137887,
          "to_longitude": 105.7991510645298,
          "from_longitude": 105.79955844772647
        }
      },
      {
        "id": 1278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79955866451837,
            21.024634476144744,
            105.79957585933539,
            21.02463447930559
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79957585933539,
              21.02463447930559
            ],
            [
              105.79956621142199,
              21.02463447769645
            ],
            [
              105.79956037015099,
              21.024634477158283
            ],
            [
              105.79955866451837,
              21.024634476144744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1279",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463447930559,
          "to_latitude": 21.024634476144744,
          "to_longitude": 105.79955866451837,
          "from_longitude": 105.79957585933539
        }
      },
      {
        "id": 1279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79957241078586,
            21.02454649476801,
            105.79958460717653,
            21.024857675870003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79958460717653,
              21.024857675870003
            ],
            [
              105.79957893150892,
              21.02471285886241
            ],
            [
              105.79957585933539,
              21.02463447930559
            ],
            [
              105.79957241078586,
              21.02454649476801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1280",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024857675870003,
          "to_latitude": 21.02454649476801,
          "to_longitude": 105.79957241078586,
          "from_longitude": 105.79958460717653
        }
      },
      {
        "id": 1280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79917355003552,
            21.024682371745428,
            105.79924647830867,
            21.024981371054388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79924647830867,
              21.024981371054388
            ],
            [
              105.79924434131034,
              21.024975343007775
            ],
            [
              105.79923881122889,
              21.02495973228654
            ],
            [
              105.79917749982356,
              21.024742974342217
            ],
            [
              105.79917355003552,
              21.024682371745428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_109_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1281",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024981371054388,
          "to_latitude": 21.024682371745428,
          "to_longitude": 105.79917355003552,
          "from_longitude": 105.79924647830867
        }
      },
      {
        "id": 1281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79919449285897,
            21.024422509917255,
            105.79956629599103,
            21.024524247165747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79956629599103,
              21.024524247165747
            ],
            [
              105.79955908456706,
              21.0245216707357
            ],
            [
              105.79953660549188,
              21.02452383126275
            ],
            [
              105.79919449285897,
              21.024422509917255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1282",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024524247165747,
          "to_latitude": 21.024422509917255,
          "to_longitude": 105.79919449285897,
          "from_longitude": 105.79956629599103
        }
      },
      {
        "id": 1282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79940378359086,
            21.024907490897768,
            105.79941278188068,
            21.024930350889264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79940378359086,
              21.024907490897768
            ],
            [
              105.79941115132779,
              21.02492620911726
            ],
            [
              105.79941161847263,
              21.02492739918443
            ],
            [
              105.79941208753179,
              21.024928587436122
            ],
            [
              105.79941278188068,
              21.024930350889264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1283",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024907490897768,
          "to_latitude": 21.024930350889264,
          "to_longitude": 105.79941278188068,
          "from_longitude": 105.79940378359086
        }
      },
      {
        "id": 1283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79920777982524,
            21.02496964293103,
            105.79921580678989,
            21.024990037165253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79920777982524,
              21.02496964293103
            ],
            [
              105.79921252423146,
              21.02498169771782
            ],
            [
              105.7992146979975,
              21.024987224299682
            ],
            [
              105.79921580678989,
              21.024990037165253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1284",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02496964293103,
          "to_latitude": 21.024990037165253,
          "to_longitude": 105.79921580678989,
          "from_longitude": 105.79920777982524
        }
      },
      {
        "id": 1284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79936037910076,
            21.02469423994806,
            105.79956485628882,
            21.02492068064508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79936037910076,
              21.02492068064508
            ],
            [
              105.79940378359086,
              21.024907490897768
            ],
            [
              105.7994681094726,
              21.02488794431513
            ],
            [
              105.79953004273291,
              21.024867656566602
            ],
            [
              105.79954437557406,
              21.024862961036263
            ],
            [
              105.79955990859334,
              21.024849712601352
            ],
            [
              105.79956485628882,
              21.02483151636245
            ],
            [
              105.79955950993899,
              21.02471002370757
            ],
            [
              105.79955447722948,
              21.02469423994806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1285",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02492068064508,
          "to_latitude": 21.02469423994806,
          "to_longitude": 105.79955447722948,
          "from_longitude": 105.79936037910076
        }
      },
      {
        "id": 1285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79902792684729,
            21.02492908082565,
            105.79933478940542,
            21.025027079874924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79902792684729,
              21.025027079874924
            ],
            [
              105.79918135818352,
              21.024978081321237
            ],
            [
              105.79920777982524,
              21.02496964293103
            ],
            [
              105.79923881122889,
              21.02495973228654
            ],
            [
              105.7992466199809,
              21.02495723880463
            ],
            [
              105.79933478940542,
              21.02492908082565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1286",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025027079874924,
          "to_latitude": 21.02492908082565,
          "to_longitude": 105.79933478940542,
          "from_longitude": 105.79902792684729
        }
      },
      {
        "id": 1286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8070354545353,
            21.018682295821442,
            105.80736485974936,
            21.018849098315666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80736485974936,
              21.018682295821442
            ],
            [
              105.80730405813044,
              21.018711799322087
            ],
            [
              105.8070354545353,
              21.018849098315666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1287",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018682295821442,
          "to_latitude": 21.018849098315666,
          "to_longitude": 105.8070354545353,
          "from_longitude": 105.80736485974936
        }
      },
      {
        "id": 1287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80864363164085,
            21.021628578569196,
            105.80917258839608,
            21.02194852397579
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80917258839608,
              21.021869712407074
            ],
            [
              105.80914937680276,
              21.021877744143126
            ],
            [
              105.80898642386536,
              21.02194852397579
            ],
            [
              105.80885608201918,
              21.021948515221865
            ],
            [
              105.80879084183809,
              21.021897415512402
            ],
            [
              105.80864363164085,
              21.021628578569196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Ngõ 58, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1288",
          "diaChiLapD": "Ngõ 58, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021869712407074,
          "to_latitude": 21.021628578569196,
          "to_longitude": 105.80864363164085,
          "from_longitude": 105.80917258839608
        }
      },
      {
        "id": 1288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80588049487422,
            21.017465244217092,
            105.80600301090391,
            21.01762051619799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80588049487422,
              21.01762051619799
            ],
            [
              105.80599199078691,
              21.017479210675283
            ],
            [
              105.80600301090391,
              21.017465244217092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M87_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1289",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01762051619799,
          "to_latitude": 21.017465244217092,
          "to_longitude": 105.80600301090391,
          "from_longitude": 105.80588049487422
        }
      },
      {
        "id": 1289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80564304064194,
            21.017514218947795,
            105.80595834904712,
            21.017725472021027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80564304064194,
              21.017514218947795
            ],
            [
              105.80564520088129,
              21.01751577311039
            ],
            [
              105.80565039955064,
              21.017519225923003
            ],
            [
              105.80565067184334,
              21.017519416118066
            ],
            [
              105.80585177701192,
              21.017656383986708
            ],
            [
              105.80592068234142,
              21.01770105373299
            ],
            [
              105.80595834904712,
              21.017725472021027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M85_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1290",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017514218947795,
          "to_latitude": 21.017725472021027,
          "to_longitude": 105.80595834904712,
          "from_longitude": 105.80564304064194
        }
      },
      {
        "id": 1290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80579404325367,
            21.017331571711125,
            105.80610476700737,
            21.017706691019864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80579404325367,
              21.017331571711125
            ],
            [
              105.80579933873481,
              21.017334960834713
            ],
            [
              105.80580483593863,
              21.017338475454725
            ],
            [
              105.80600301090391,
              21.017465244217092
            ],
            [
              105.80610476700737,
              21.017530335610598
            ],
            [
              105.80595914466394,
              21.017706691019864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M87_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1291",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017331571711125,
          "to_latitude": 21.017706691019864,
          "to_longitude": 105.80595914466394,
          "from_longitude": 105.80579404325367
        }
      },
      {
        "id": 1291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80546670033526,
            21.0169260327463,
            105.80615624540287,
            21.01801958739641
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.80615624540287,
                21.0169260327463
              ],
              [
                105.80613061336419,
                21.01695611612816
              ],
              [
                105.80583842617415,
                21.017299051161114
              ],
              [
                105.80580703169665,
                21.017335898076432
              ],
              [
                105.80580483593864,
                21.017338475454732
              ],
              [
                105.80565067184334,
                21.017519416118052
              ],
              [
                105.80565062211893,
                21.01751947145053
              ],
              [
                105.80564699437542,
                21.017523729292414
              ],
              [
                105.80555080125747,
                21.017636628736444
              ],
              [
                105.80548597908252,
                21.017729250224587
              ],
              [
                105.80546670033526,
                21.017756796967284
              ],
              [
                105.8054684191356,
                21.017758010898252
              ],
              [
                105.8055524105868,
                21.017819286829543
              ]
            ],
            [
              [
                105.8055524105868,
                21.017819286829543
              ],
              [
                105.80562348912788,
                21.017868736057075
              ]
            ],
            [
              [
                105.80562348912788,
                21.017868736057075
              ],
              [
                105.80552558850039,
                21.01801958739641
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M88_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1292",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 141.35,
          "chatLieu": "HDPE",
          "chieuDai": 156.708,
          "from_latitude": [
            105.80613061336419,
            21.01695611612816
          ],
          "to_latitude": [
            105.80552558850039,
            21.01801958739641
          ],
          "to_longitude": [
            105.80562348912788,
            21.017868736057075
          ],
          "from_longitude": [
            105.80615624540287,
            21.0169260327463
          ]
        }
      },
      {
        "id": 1292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80473625221315,
            21.016428662578544,
            105.80493364636602,
            21.016660894536102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80493364636602,
              21.016428662578544
            ],
            [
              105.80493222429718,
              21.016430038602913
            ],
            [
              105.80487059588238,
              21.0164896741678
            ],
            [
              105.80480823036483,
              21.016568095855956
            ],
            [
              105.80479157401358,
              21.0166031928599
            ],
            [
              105.80473625221315,
              21.016660894536102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12-12A_Ngõ 678, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1293",
          "diaChiLapD": "Ngõ 678, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016428662578544,
          "to_latitude": 21.016660894536102,
          "to_longitude": 105.80473625221315,
          "from_longitude": 105.80493364636602
        }
      },
      {
        "id": 1293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80102943162207,
            21.026667085880398,
            105.80121093412089,
            21.026727866555145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80121093412089,
              21.026667085880398
            ],
            [
              105.80119520353696,
              21.026672354463408
            ],
            [
              105.80119257616842,
              21.02667323394168
            ],
            [
              105.80102943162207,
              21.026727866555145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1294",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026667085880398,
          "to_latitude": 21.026727866555145,
          "to_longitude": 105.80102943162207,
          "from_longitude": 105.80121093412089
        }
      },
      {
        "id": 1294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120443696217,
            21.026411617634047,
            105.8012258536805,
            21.026542219783106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8012258536805,
              21.026411617634047
            ],
            [
              105.80120443696217,
              21.026542219783106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1295",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026411617634047,
          "to_latitude": 21.026542219783106,
          "to_longitude": 105.80120443696217,
          "from_longitude": 105.8012258536805
        }
      },
      {
        "id": 1295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8041222552831,
            21.017621806762165,
            105.80433698814481,
            21.01798473948694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80433698814481,
              21.01798473948694
            ],
            [
              105.80430144942245,
              21.017956724049807
            ],
            [
              105.80422840639037,
              21.017926280999536
            ],
            [
              105.8041222896048,
              21.01785891759085
            ],
            [
              105.8041222552831,
              21.01783295984086
            ],
            [
              105.80424982503904,
              21.017621806762165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D33_107_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1296",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 33,
          "chieudaiQL": 59.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01798473948694,
          "to_latitude": 21.017621806762165,
          "to_longitude": 105.80424982503904,
          "from_longitude": 105.80433698814481
        }
      },
      {
        "id": 1296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80430835375404,
            21.01750430303981,
            105.80468738618023,
            21.017947464917157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80468738618023,
              21.01750430303981
            ],
            [
              105.80455445449715,
              21.01765596890484
            ],
            [
              105.8045381471563,
              21.01767528391244
            ],
            [
              105.8045032182733,
              21.017716655329984
            ],
            [
              105.80430835375404,
              21.017947464917157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1297",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01750430303981,
          "to_latitude": 21.017947464917157,
          "to_longitude": 105.80430835375404,
          "from_longitude": 105.80468738618023
        }
      },
      {
        "id": 1297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79902253003799,
            21.023476189031644,
            105.79928135741454,
            21.023700713389445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79928135741454,
              21.023476189031644
            ],
            [
              105.79918039540948,
              21.023568803741764
            ],
            [
              105.79915971411366,
              21.023585342410673
            ],
            [
              105.7991577133155,
              21.023586994786868
            ],
            [
              105.79903922560122,
              21.02368485552942
            ],
            [
              105.79902253003799,
              21.023700713389445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1298",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023476189031644,
          "to_latitude": 21.023700713389445,
          "to_longitude": 105.79902253003799,
          "from_longitude": 105.79928135741454
        }
      },
      {
        "id": 1298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80500509994306,
            21.019236519608597,
            105.80508153174307,
            21.01928773588643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80508153174307,
              21.01928773588643
            ],
            [
              105.80504867594561,
              21.01926571891434
            ],
            [
              105.80500509994306,
              21.019236519608597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1299",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01928773588643,
          "to_latitude": 21.019236519608597,
          "to_longitude": 105.80500509994306,
          "from_longitude": 105.80508153174307
        }
      },
      {
        "id": 1299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80504867594561,
            21.019186913754698,
            105.80510941077802,
            21.01926571891434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80510941077802,
              21.019186913754698
            ],
            [
              105.80504867594561,
              21.01926571891434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1300",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019186913754698,
          "to_latitude": 21.01926571891434,
          "to_longitude": 105.80504867594561,
          "from_longitude": 105.80510941077802
        }
      },
      {
        "id": 1300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80357960656394,
            21.018974356958783,
            105.8041389720342,
            21.01918422944731
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80357960656394,
              21.01900054198728
            ],
            [
              105.80358424843256,
              21.019000256316218
            ],
            [
              105.80359054890243,
              21.019000721522552
            ],
            [
              105.80363085323326,
              21.01901130754991
            ],
            [
              105.80366424422567,
              21.01898586660039
            ],
            [
              105.8037074054971,
              21.018974356958783
            ],
            [
              105.80379959764092,
              21.01901643636238
            ],
            [
              105.80389475416801,
              21.01905986919456
            ],
            [
              105.80391377641436,
              21.019068551461704
            ],
            [
              105.8041389720342,
              21.01918422944731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M60_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1301",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01900054198728,
          "to_latitude": 21.01918422944731,
          "to_longitude": 105.8041389720342,
          "from_longitude": 105.80357960656394
        }
      },
      {
        "id": 1301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80133502345024,
            21.02277065615826,
            105.80138767216087,
            21.022957306384445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80133502345024,
              21.022957306384445
            ],
            [
              105.80133674641384,
              21.02295137398498
            ],
            [
              105.80137010514447,
              21.022836505773125
            ],
            [
              105.80138767216087,
              21.02277065615826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1302",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022957306384445,
          "to_latitude": 21.02277065615826,
          "to_longitude": 105.80138767216087,
          "from_longitude": 105.80133502345024
        }
      },
      {
        "id": 1302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8016838910833,
            21.023075547404726,
            105.80169972543469,
            21.023120528798078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8016838910833,
              21.023120528798078
            ],
            [
              105.80169026176306,
              21.02310242785993
            ],
            [
              105.80169410239466,
              21.02309152132697
            ],
            [
              105.80169814389588,
              21.02308004029646
            ],
            [
              105.80169972543469,
              21.023075547404726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1303",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023120528798078,
          "to_latitude": 21.023075547404726,
          "to_longitude": 105.80169972543469,
          "from_longitude": 105.8016838910833
        }
      },
      {
        "id": 1303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80124440717897,
            21.022923152254748,
            105.80175395717768,
            21.02309183260578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80124440717897,
              21.022923152254748
            ],
            [
              105.80133502345024,
              21.022957306384445
            ],
            [
              105.80143668448915,
              21.02299562288947
            ],
            [
              105.8014754323242,
              21.023007651963397
            ],
            [
              105.80152883250332,
              21.023024230189712
            ],
            [
              105.80169972543469,
              21.023075547404726
            ],
            [
              105.80175395717768,
              21.02309183260578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1304",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022923152254748,
          "to_latitude": 21.02309183260578,
          "to_longitude": 105.80175395717768,
          "from_longitude": 105.80124440717897
        }
      },
      {
        "id": 1304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80708900230087,
            21.020014479504084,
            105.80713670417589,
            21.02003651611045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80713670417589,
              21.020014479504084
            ],
            [
              105.80708900230087,
              21.02003651611045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1305",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020014479504084,
          "to_latitude": 21.02003651611045,
          "to_longitude": 105.80708900230087,
          "from_longitude": 105.80713670417589
        }
      },
      {
        "id": 1305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80707860174189,
            21.019997546338633,
            105.80712630553226,
            21.02001958385434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80712630553226,
              21.019997546338633
            ],
            [
              105.80707860174189,
              21.02001958385434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1306",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019997546338633,
          "to_latitude": 21.02001958385434,
          "to_longitude": 105.80707860174189,
          "from_longitude": 105.80712630553226
        }
      },
      {
        "id": 1306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80711683550474,
            21.01997959895647,
            105.80714711873264,
            21.02003276021059
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80714711873264,
              21.02003276021059
            ],
            [
              105.80713670417589,
              21.020014479504084
            ],
            [
              105.80711683550474,
              21.01997959895647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1307",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02003276021059,
          "to_latitude": 21.01997959895647,
          "to_longitude": 105.80711683550474,
          "from_longitude": 105.80714711873264
        }
      },
      {
        "id": 1307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80703958774593,
            21.019933992977798,
            105.80708736157442,
            21.019956062427635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80708736157442,
              21.019933992977798
            ],
            [
              105.80708729152747,
              21.019934025826384
            ],
            [
              105.80703958774593,
              21.019956062427635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1308",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019933992977798,
          "to_latitude": 21.019956062427635,
          "to_longitude": 105.80703958774593,
          "from_longitude": 105.80708736157442
        }
      },
      {
        "id": 1308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80702933373524,
            21.01991704749515,
            105.80707765453168,
            21.019939367925154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80707765453168,
              21.01991704749515
            ],
            [
              105.807077037518,
              21.019917332230058
            ],
            [
              105.80702933373524,
              21.019939367925154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1309",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01991704749515,
          "to_latitude": 21.019939367925154,
          "to_longitude": 105.80702933373524,
          "from_longitude": 105.80707765453168
        }
      },
      {
        "id": 1309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8070711588908,
            21.019905707567645,
            105.8070978950218,
            21.01995238151116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8070978950218,
              21.01995238151116
            ],
            [
              105.80708736157442,
              21.019933992977798
            ],
            [
              105.80707765453168,
              21.01991704749515
            ],
            [
              105.8070711588908,
              21.019905707567645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1310",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01995238151116,
          "to_latitude": 21.019905707567645,
          "to_longitude": 105.8070711588908,
          "from_longitude": 105.8070978950218
        }
      },
      {
        "id": 1310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8070312417463,
            21.019840239448197,
            105.8070615249192,
            21.019893401623516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8070615249192,
              21.019893401623516
            ],
            [
              105.80705111037332,
              21.019875119104412
            ],
            [
              105.8070312417463,
              21.019840239448197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1311",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019893401623516,
          "to_latitude": 21.019840239448197,
          "to_longitude": 105.8070312417463,
          "from_longitude": 105.8070615249192
        }
      },
      {
        "id": 1311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80715667313598,
            21.020044935558634,
            105.80718321845562,
            21.020091536327232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80718321845562,
              21.020091536327232
            ],
            [
              105.80717280484735,
              21.020073254715566
            ],
            [
              105.80715667313598,
              21.020044935558634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1312",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020091536327232,
          "to_latitude": 21.020044935558634,
          "to_longitude": 105.80715667313598,
          "from_longitude": 105.80718321845562
        }
      },
      {
        "id": 1312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80347142450447,
            21.01740451978789,
            105.8036184033073,
            21.01763544933856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80347142450447,
              21.01740451978789
            ],
            [
              105.80347864385591,
              21.017409886100552
            ],
            [
              105.80357309221364,
              21.01748006893144
            ],
            [
              105.8036184033073,
              21.017511877994675
            ],
            [
              105.80353416326881,
              21.01763544933856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_21_Ngõ 766, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1313",
          "diaChiLapD": "Ngõ 766, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01740451978789,
          "to_latitude": 21.01763544933856,
          "to_longitude": 105.80353416326881,
          "from_longitude": 105.80347142450447
        }
      },
      {
        "id": 1313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80134045706818,
            21.02705839479078,
            105.80140887898206,
            21.027205677130848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80134045706818,
              21.02705839479078
            ],
            [
              105.80136940449412,
              21.02713512497351
            ],
            [
              105.80140887898206,
              21.027205677130848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1314",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02705839479078,
          "to_latitude": 21.027205677130848,
          "to_longitude": 105.80140887898206,
          "from_longitude": 105.80134045706818
        }
      },
      {
        "id": 1314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80143609063387,
            21.02726650510134,
            105.80149663894356,
            21.027369405512225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80143609063387,
              21.02726650510134
            ],
            [
              105.80143625373597,
              21.027266782527178
            ],
            [
              105.80149663894356,
              21.027369405512225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1315",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02726650510134,
          "to_latitude": 21.027369405512225,
          "to_longitude": 105.80149663894356,
          "from_longitude": 105.80143609063387
        }
      },
      {
        "id": 1315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80119933133687,
            21.026284720021085,
            105.80151744400507,
            21.026411617634047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80119933133687,
              21.02639637776115
            ],
            [
              105.8012030565255,
              21.02639751002122
            ],
            [
              105.8012258536805,
              21.026411617634047
            ],
            [
              105.80151744400507,
              21.026284720021085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1316",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02639637776115,
          "to_latitude": 21.026284720021085,
          "to_longitude": 105.80151744400507,
          "from_longitude": 105.80119933133687
        }
      },
      {
        "id": 1316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120599451183,
            21.026374221664987,
            105.80168994846632,
            21.0266482479238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80120599451183,
              21.0266482479238
            ],
            [
              105.80121292087833,
              21.026644803794692
            ],
            [
              105.80154047532365,
              21.026475405053162
            ],
            [
              105.80157958533516,
              21.02643805193326
            ],
            [
              105.80168994846632,
              21.026374221664987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_55_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1317",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0266482479238,
          "to_latitude": 21.026374221664987,
          "to_longitude": 105.80168994846632,
          "from_longitude": 105.80120599451183
        }
      },
      {
        "id": 1317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80154047532365,
            21.026475405053162,
            105.80173616992836,
            21.026780690737507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80154047532365,
              21.026475405053162
            ],
            [
              105.80173616992836,
              21.026780690737507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1318",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026475405053162,
          "to_latitude": 21.026780690737507,
          "to_longitude": 105.80173616992836,
          "from_longitude": 105.80154047532365
        }
      },
      {
        "id": 1318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80121860584772,
            21.026660184291444,
            105.80133064214564,
            21.02700702292391
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80121860584772,
              21.026660184291444
            ],
            [
              105.80123008499223,
              21.02670867696233
            ],
            [
              105.80127242075274,
              21.026857600603286
            ],
            [
              105.80128921758065,
              21.026914302951024
            ],
            [
              105.80133064214564,
              21.02700702292391
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1319",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026660184291444,
          "to_latitude": 21.02700702292391,
          "to_longitude": 105.80133064214564,
          "from_longitude": 105.80121860584772
        }
      },
      {
        "id": 1319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120667505356,
            21.02659902005052,
            105.80153988178022,
            21.0268964254669
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80120667505356,
              21.026650842404255
            ],
            [
              105.80121515266599,
              21.026648388131406
            ],
            [
              105.80121860584772,
              21.026660184291444
            ],
            [
              105.80133425730614,
              21.02659902005052
            ],
            [
              105.80153988178022,
              21.0268964254669
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1320",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026650842404255,
          "to_latitude": 21.0268964254669,
          "to_longitude": 105.80153988178022,
          "from_longitude": 105.80120667505356
        }
      },
      {
        "id": 1320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80144509608569,
            21.02698939988388,
            105.80155568434415,
            21.02715056116004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80144509608569,
              21.02698939988388
            ],
            [
              105.80155568434415,
              21.02715056116004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1321",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02698939988388,
          "to_latitude": 21.02715056116004,
          "to_longitude": 105.80155568434415,
          "from_longitude": 105.80144509608569
        }
      },
      {
        "id": 1321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80132927123205,
            21.02688872931519,
            105.80173272367779,
            21.02706568417163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80132927123205,
              21.02706568417163
            ],
            [
              105.80133583260427,
              21.02706144420595
            ],
            [
              105.80134045706818,
              21.02705839479078
            ],
            [
              105.80144509608569,
              21.02698939988388
            ],
            [
              105.80161781577684,
              21.02688872931519
            ],
            [
              105.80173272367779,
              21.027052605089846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_52_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1322",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02706568417163,
          "to_latitude": 21.027052605089846,
          "to_longitude": 105.80173272367779,
          "from_longitude": 105.80132927123205
        }
      },
      {
        "id": 1322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80158028264778,
            21.027182139516725,
            105.80164941137771,
            21.027290460348656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80158028264778,
              21.027182139516725
            ],
            [
              105.80160433159148,
              21.027219823576935
            ],
            [
              105.80164941137771,
              21.027290460348656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1323",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027182139516725,
          "to_latitude": 21.027290460348656,
          "to_longitude": 105.80164941137771,
          "from_longitude": 105.80158028264778
        }
      },
      {
        "id": 1323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80142111579882,
            21.027084416718154,
            105.8017473038519,
            21.027277467712896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80142111579882,
              21.027277467712896
            ],
            [
              105.80142567714985,
              21.027274128656288
            ],
            [
              105.80143609063387,
              21.02726650510134
            ],
            [
              105.80158028264778,
              21.027182139516725
            ],
            [
              105.8017473038519,
              21.027084416718154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1324",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027277467712896,
          "to_latitude": 21.027084416718154,
          "to_longitude": 105.8017473038519,
          "from_longitude": 105.80142111579882
        }
      },
      {
        "id": 1324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8007965207767,
            21.02863931995644,
            105.80103674886595,
            21.028892525976055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80103674886595,
              21.028892525976055
            ],
            [
              105.80098118664186,
              21.028836478615926
            ],
            [
              105.80093155170682,
              21.02878640978978
            ],
            [
              105.80084904996403,
              21.028705160926012
            ],
            [
              105.8007965207767,
              21.02863931995644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1325",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028892525976055,
          "to_latitude": 21.02863931995644,
          "to_longitude": 105.8007965207767,
          "from_longitude": 105.80103674886595
        }
      },
      {
        "id": 1325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80044121683663,
            21.02741573193331,
            105.80048765430564,
            21.027605409130285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80048765430564,
              21.027605409130285
            ],
            [
              105.80044121683663,
              21.02741573193331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1326",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027605409130285,
          "to_latitude": 21.02741573193331,
          "to_longitude": 105.80044121683663,
          "from_longitude": 105.80048765430564
        }
      },
      {
        "id": 1326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79914478626131,
            21.02696158535325,
            105.79980289368096,
            21.02729649568711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79914478626131,
              21.026975411845093
            ],
            [
              105.79915066085653,
              21.026971179705658
            ],
            [
              105.7991639759552,
              21.02696158535325
            ],
            [
              105.79925541959402,
              21.027080889482452
            ],
            [
              105.79936219500641,
              21.027175650296787
            ],
            [
              105.79956419646692,
              21.02729649568711
            ],
            [
              105.7996033352237,
              21.02727936707713
            ],
            [
              105.79956892946437,
              21.027195108529114
            ],
            [
              105.79980289368096,
              21.027125099135926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1327",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026975411845093,
          "to_latitude": 21.027125099135926,
          "to_longitude": 105.79980289368096,
          "from_longitude": 105.79914478626131
        }
      },
      {
        "id": 1327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79856945696393,
            21.024442246210207,
            105.79860268646829,
            21.024547339470168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79856945696393,
              21.024547339470168
            ],
            [
              105.79860268646829,
              21.024442246210207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 1096, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1328",
          "diaChiLapD": "Ngõ 1096, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024547339470168,
          "to_latitude": 21.024442246210207,
          "to_longitude": 105.79860268646829,
          "from_longitude": 105.79856945696393
        }
      },
      {
        "id": 1328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80546707596979,
            21.01821883714609,
            105.80552210776537,
            21.01833954514843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80552210776537,
              21.01821883714609
            ],
            [
              105.80546707596979,
              21.01833954514843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M78_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1329",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01821883714609,
          "to_latitude": 21.01833954514843,
          "to_longitude": 105.80546707596979,
          "from_longitude": 105.80552210776537
        }
      },
      {
        "id": 1329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538199118729,
            21.018192008804817,
            105.80544860857766,
            21.018343044267482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80544860857766,
              21.018192008804817
            ],
            [
              105.80538199118729,
              21.018343044267482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M78_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1330",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018192008804817,
          "to_latitude": 21.018343044267482,
          "to_longitude": 105.80538199118729,
          "from_longitude": 105.80544860857766
        }
      },
      {
        "id": 1330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80532679298922,
            21.018058123176626,
            105.80537591492431,
            21.018131830720417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80532679298922,
              21.018131830720417
            ],
            [
              105.80537591492431,
              21.018058123176626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M76_Ngõ 157/23, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1331",
          "diaChiLapD": "Ngõ 157/23, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018131830720417,
          "to_latitude": 21.018058123176626,
          "to_longitude": 105.80537591492431,
          "from_longitude": 105.80532679298922
        }
      },
      {
        "id": 1331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80349942102504,
            21.02023017961986,
            105.80356620098792,
            21.02035856848574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80349942102504,
              21.02035856848574
            ],
            [
              105.80352701758419,
              21.020305512499075
            ],
            [
              105.80356620098792,
              21.02023017961986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M39_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1332",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02035856848574,
          "to_latitude": 21.02023017961986,
          "to_longitude": 105.80356620098792,
          "from_longitude": 105.80349942102504
        }
      },
      {
        "id": 1332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80214102057498,
            21.020926928140284,
            105.80238852578067,
            21.02103091259483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80214102057498,
              21.020983355097528
            ],
            [
              105.80214205637948,
              21.020983669052832
            ],
            [
              105.80231170749107,
              21.02103091259483
            ],
            [
              105.80237265008901,
              21.02092815013139
            ],
            [
              105.80238852578067,
              21.020926928140284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M28_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1333",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020983355097528,
          "to_latitude": 21.020926928140284,
          "to_longitude": 105.80238852578067,
          "from_longitude": 105.80214102057498
        }
      },
      {
        "id": 1333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8009978083985,
            21.025439060771294,
            105.80105371222683,
            21.0258243369981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80101597856613,
              21.025439060771294
            ],
            [
              105.80101310214197,
              21.0254642626724
            ],
            [
              105.8009978083985,
              21.025567114963867
            ],
            [
              105.80100344165655,
              21.025603798375666
            ],
            [
              105.80100587556379,
              21.025619648519843
            ],
            [
              105.80100724969346,
              21.025634664555607
            ],
            [
              105.80101063760844,
              21.02567170357594
            ],
            [
              105.80102358065787,
              21.02573061715598
            ],
            [
              105.8010395079099,
              21.025780536773585
            ],
            [
              105.80105371222683,
              21.0258243369981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1334",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025439060771294,
          "to_latitude": 21.0258243369981,
          "to_longitude": 105.80105371222683,
          "from_longitude": 105.80101597856613
        }
      },
      {
        "id": 1334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80403648012796,
            21.020127742231235,
            105.80430102153258,
            21.020239189294642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80403648012796,
              21.020127742231235
            ],
            [
              105.80413625797678,
              21.020167981819238
            ],
            [
              105.80414572981762,
              21.02018041063725
            ],
            [
              105.80428401422215,
              21.02023494956621
            ],
            [
              105.80430102153258,
              21.020239189294642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M48_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1335",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020127742231235,
          "to_latitude": 21.020239189294642,
          "to_longitude": 105.80430102153258,
          "from_longitude": 105.80403648012796
        }
      },
      {
        "id": 1335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80432615914665,
            21.01960173967055,
            105.80441354957213,
            21.01977535853533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80441354957213,
              21.01960173967055
            ],
            [
              105.80432615914665,
              21.01973831833912
            ],
            [
              105.8043970942524,
              21.01977535853533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M56_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1336",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01960173967055,
          "to_latitude": 21.01977535853533,
          "to_longitude": 105.8043970942524,
          "from_longitude": 105.80441354957213
        }
      },
      {
        "id": 1336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80335840183027,
            21.02034766143931,
            105.80353151698394,
            21.020604651632123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80347793079866,
              21.02034766143931
            ],
            [
              105.80342150125153,
              21.02042841474715
            ],
            [
              105.80335840183027,
              21.0205106998026
            ],
            [
              105.80336069442411,
              21.020512175690317
            ],
            [
              105.80341091724188,
              21.02054451435229
            ],
            [
              105.80343578411221,
              21.02056423633019
            ],
            [
              105.80353151698394,
              21.020604651632123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M38_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1337",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02034766143931,
          "to_latitude": 21.020604651632123,
          "to_longitude": 105.80353151698394,
          "from_longitude": 105.80347793079866
        }
      },
      {
        "id": 1337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80360502237255,
            21.0206314925676,
            105.8037132074808,
            21.020810903624675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80360502237255,
              21.020810903624675
            ],
            [
              105.80360790194683,
              21.02080612819559
            ],
            [
              105.8037132074808,
              21.0206314925676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1338",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020810903624675,
          "to_latitude": 21.0206314925676,
          "to_longitude": 105.8037132074808,
          "from_longitude": 105.80360502237255
        }
      },
      {
        "id": 1338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80279064921709,
            21.020341346458885,
            105.80291011578761,
            21.020523883965673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80279064921709,
              21.020523883965673
            ],
            [
              105.80284944353586,
              21.020418488629677
            ],
            [
              105.80291011578761,
              21.020341346458885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M31_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1339",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020523883965673,
          "to_latitude": 21.020341346458885,
          "to_longitude": 105.80291011578761,
          "from_longitude": 105.80279064921709
        }
      },
      {
        "id": 1339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8023715526729,
            21.020556394184705,
            105.80237688732362,
            21.020559185289912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80237688732362,
              21.020559185289912
            ],
            [
              105.80237512100614,
              21.020558261489086
            ],
            [
              105.8023727276478,
              21.02055700916085
            ],
            [
              105.8023715526729,
              21.020556394184705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M30.1_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1340",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020559185289912,
          "to_latitude": 21.020556394184705,
          "to_longitude": 105.8023715526729,
          "from_longitude": 105.80237688732362
        }
      },
      {
        "id": 1340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80231464266726,
            21.02065697168272,
            105.80246785022906,
            21.020741211241003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80231464266726,
              21.02065697168272
            ],
            [
              105.80237427101633,
              21.020689757647506
            ],
            [
              105.80246785022906,
              21.020741211241003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M30.1_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1341",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02065697168272,
          "to_latitude": 21.020741211241003,
          "to_longitude": 105.80246785022906,
          "from_longitude": 105.80231464266726
        }
      },
      {
        "id": 1341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8023094358477,
            21.020648047044418,
            105.80231453126042,
            21.020650712827806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8023094358477,
              21.020648047044418
            ],
            [
              105.80231216008868,
              21.020649472136096
            ],
            [
              105.802313468195,
              21.020650157839803
            ],
            [
              105.80231453126042,
              21.020650712827806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M30_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1342",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020648047044418,
          "to_latitude": 21.020650712827806,
          "to_longitude": 105.80231453126042,
          "from_longitude": 105.8023094358477
        }
      },
      {
        "id": 1342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80197658556793,
            21.02097163133373,
            105.80212968734224,
            21.02128652809412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80197658556793,
              21.02128652809412
            ],
            [
              105.80202005061979,
              21.021195872849553
            ],
            [
              105.8020444284013,
              21.021145028921204
            ],
            [
              105.80206977120079,
              21.021090619908367
            ],
            [
              105.80209511302051,
              21.021036210896536
            ],
            [
              105.80212968734224,
              21.02097163133373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1343",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02128652809412,
          "to_latitude": 21.02097163133373,
          "to_longitude": 105.80212968734224,
          "from_longitude": 105.80197658556793
        }
      },
      {
        "id": 1343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80206667739125,
            21.02075518138041,
            105.80220884940039,
            21.020822926947666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80220884940039,
              21.020822926947666
            ],
            [
              105.80220767761908,
              21.020822369762072
            ],
            [
              105.80220571498892,
              21.02082143333698
            ],
            [
              105.80206667739125,
              21.02075518138041
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M29_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1344",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020822926947666,
          "to_latitude": 21.02075518138041,
          "to_longitude": 105.80206667739125,
          "from_longitude": 105.80220884940039
        }
      },
      {
        "id": 1344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80186833929754,
            21.020641915717604,
            105.80193349126375,
            21.020765087380028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80193349126375,
              21.020641915717604
            ],
            [
              105.80186833929754,
              21.020765087380028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1345",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020641915717604,
          "to_latitude": 21.020765087380028,
          "to_longitude": 105.80186833929754,
          "from_longitude": 105.80193349126375
        }
      },
      {
        "id": 1345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80150437503234,
            21.020541594638843,
            105.80199657780604,
            21.02100123965317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80150437503234,
              21.02100123965317
            ],
            [
              105.80153077304651,
              21.020952722928804
            ],
            [
              105.80154242045506,
              21.0209316282126
            ],
            [
              105.80154992433914,
              21.020918038085167
            ],
            [
              105.80161904537563,
              21.02079461750915
            ],
            [
              105.80167309859753,
              21.020701372564915
            ],
            [
              105.80168957938808,
              21.02067352599859
            ],
            [
              105.80174075109896,
              21.02058705980455
            ],
            [
              105.80176032360463,
              21.020553987024307
            ],
            [
              105.80193349126375,
              21.020641915717604
            ],
            [
              105.80193603586362,
              21.020643207984357
            ],
            [
              105.80199657780604,
              21.020541594638843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_29_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1346",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02100123965317,
          "to_latitude": 21.020541594638843,
          "to_longitude": 105.80199657780604,
          "from_longitude": 105.80150437503234
        }
      },
      {
        "id": 1346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80214570918878,
            21.020571006328783,
            105.80235074543788,
            21.020936766990232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80214570918878,
              21.020936766990232
            ],
            [
              105.80218893107136,
              21.020849784479083
            ],
            [
              105.80220571498892,
              21.02082143333698
            ],
            [
              105.80224099026964,
              21.02076184620909
            ],
            [
              105.80224201985581,
              21.020760099023455
            ],
            [
              105.80226292142491,
              21.020724633587694
            ],
            [
              105.80227766158686,
              21.02070338242979
            ],
            [
              105.80230181512714,
              21.020662259176532
            ],
            [
              105.8023094358477,
              21.020648047044418
            ],
            [
              105.80235074543788,
              21.020571006328783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M30_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1347",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020936766990232,
          "to_latitude": 21.020571006328783,
          "to_longitude": 105.80235074543788,
          "from_longitude": 105.80214570918878
        }
      },
      {
        "id": 1347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80222111854046,
            21.02042318192369,
            105.80250737659662,
            21.020814134853968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80222111854046,
              21.020814134853968
            ],
            [
              105.80228541631567,
              21.02070630573664
            ],
            [
              105.80231464266726,
              21.02065697168272
            ],
            [
              105.80232584755764,
              21.020638057592624
            ],
            [
              105.80233056552554,
              21.02062969040117
            ],
            [
              105.8023607937513,
              21.02057954864294
            ],
            [
              105.80237688732362,
              21.020559185289912
            ],
            [
              105.80240492469625,
              21.020523708757384
            ],
            [
              105.80244391728081,
              21.020487743375988
            ],
            [
              105.80250737659662,
              21.02042318192369
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M30.1_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1348",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020814134853968,
          "to_latitude": 21.02042318192369,
          "to_longitude": 105.80250737659662,
          "from_longitude": 105.80222111854046
        }
      },
      {
        "id": 1348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80306314519778,
            21.020715297148122,
            105.80328755354928,
            21.02080918137002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80306314519778,
              21.020715297148122
            ],
            [
              105.80315181352853,
              21.020760958553
            ],
            [
              105.80328755354928,
              21.02080918137002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M35_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1349",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020715297148122,
          "to_latitude": 21.02080918137002,
          "to_longitude": 105.80328755354928,
          "from_longitude": 105.80306314519778
        }
      },
      {
        "id": 1349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80214781269207,
            21.020458365785966,
            105.80221184272159,
            21.02054790231319
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80221184272159,
              21.020458365785966
            ],
            [
              105.80214781269207,
              21.02054790231319
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 886, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1350",
          "diaChiLapD": "Ngõ 886, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020458365785966,
          "to_latitude": 21.02054790231319,
          "to_longitude": 105.80214781269207,
          "from_longitude": 105.80221184272159
        }
      },
      {
        "id": 1350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80271404204208,
            21.020222615638538,
            105.8027932160429,
            21.020283508045114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80271404204208,
              21.020222615638538
            ],
            [
              105.8027932160429,
              21.020283508045114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M37_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1351",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020222615638538,
          "to_latitude": 21.020283508045114,
          "to_longitude": 105.8027932160429,
          "from_longitude": 105.80271404204208
        }
      },
      {
        "id": 1351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80251676270395,
            21.02013146578979,
            105.80270155430459,
            21.020227576395186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80251676270395,
              21.020184672407943
            ],
            [
              105.80255200452753,
              21.02013146578979
            ],
            [
              105.80259960770695,
              21.020140818128073
            ],
            [
              105.80270155430459,
              21.020227576395186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1352",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020184672407943,
          "to_latitude": 21.020227576395186,
          "to_longitude": 105.80270155430459,
          "from_longitude": 105.80251676270395
        }
      },
      {
        "id": 1352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80237802724587,
            21.020237898420323,
            105.80267802873865,
            21.02053778782534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80267802873865,
              21.020237898420323
            ],
            [
              105.80257299391378,
              21.020335679076506
            ],
            [
              105.8025725434803,
              21.020336175268316
            ],
            [
              105.8025262887723,
              21.020387191131082
            ],
            [
              105.80248655428146,
              21.02043101541756
            ],
            [
              105.80241551910979,
              21.020504689153952
            ],
            [
              105.80237802724587,
              21.02053778782534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M36_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1353",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020237898420323,
          "to_latitude": 21.02053778782534,
          "to_longitude": 105.80237802724587,
          "from_longitude": 105.80267802873865
        }
      },
      {
        "id": 1353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80226095788969,
            21.019565708101425,
            105.80254643221049,
            21.0200507129406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80236984357715,
              21.0200507129406
            ],
            [
              105.80243661763465,
              21.01992088115802
            ],
            [
              105.80248484163369,
              21.01982676746384
            ],
            [
              105.80254643221049,
              21.019706065551148
            ],
            [
              105.80231735462618,
              21.019585565160284
            ],
            [
              105.80228014199947,
              21.019572464102712
            ],
            [
              105.80226095788969,
              21.019565708101425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1354",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0200507129406,
          "to_latitude": 21.019565708101425,
          "to_longitude": 105.80226095788969,
          "from_longitude": 105.80236984357715
        }
      },
      {
        "id": 1354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80256353694747,
            21.01974430746524,
            105.80268347692278,
            21.01997665766927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80268347692278,
              21.01974430746524
            ],
            [
              105.80268338069945,
              21.019744482242604
            ],
            [
              105.80267234075892,
              21.019764539065353
            ],
            [
              105.80267187107417,
              21.019765392123894
            ],
            [
              105.80256353694747,
              21.019962202166845
            ],
            [
              105.80258091149763,
              21.01997665766927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1355",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01974430746524,
          "to_latitude": 21.01997665766927,
          "to_longitude": 105.80258091149763,
          "from_longitude": 105.80268347692278
        }
      },
      {
        "id": 1355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.805911089944,
            21.016663607235454,
            105.80593862161038,
            21.016686033877217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.805911089944,
              21.016686033877217
            ],
            [
              105.80591289339955,
              21.016684564812017
            ],
            [
              105.80592126610215,
              21.01667774370258
            ],
            [
              105.80593602129984,
              21.016665724981657
            ],
            [
              105.80593862161038,
              21.016663607235454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1356",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016686033877217,
          "to_latitude": 21.016663607235454,
          "to_longitude": 105.80593862161038,
          "from_longitude": 105.805911089944
        }
      },
      {
        "id": 1356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80557859892349,
            21.016583818973718,
            105.80574940001914,
            21.016734707536717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80574940001914,
              21.016583818973718
            ],
            [
              105.80570278079738,
              21.016630136078604
            ],
            [
              105.80564429907473,
              21.01668438460529
            ],
            [
              105.8056391411646,
              21.016688335369388
            ],
            [
              105.80557859892349,
              21.016734707536717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ 100, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1357",
          "diaChiLapD": "Ngõ 100, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016583818973718,
          "to_latitude": 21.016734707536717,
          "to_longitude": 105.80557859892349,
          "from_longitude": 105.80574940001914
        }
      },
      {
        "id": 1357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80586673535714,
            21.016917304139728,
            105.80614876934763,
            21.017236726112966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80586673535714,
              21.017236726112966
            ],
            [
              105.8059697098795,
              21.01712779025703
            ],
            [
              105.80599180198124,
              21.017101820717723
            ],
            [
              105.8061228440948,
              21.016947779104914
            ],
            [
              105.80614876934763,
              21.016917304139728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M90_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1358",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017236726112966,
          "to_latitude": 21.016917304139728,
          "to_longitude": 105.80614876934763,
          "from_longitude": 105.80586673535714
        }
      },
      {
        "id": 1358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80579726070404,
            21.016574673799376,
            105.8061406365718,
            21.01691059826532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8061406365718,
              21.01691059826532
            ],
            [
              105.8059702529734,
              21.01674391253212
            ],
            [
              105.805911089944,
              21.016686033877217
            ],
            [
              105.80579726070404,
              21.016574673799376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1359",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01691059826532,
          "to_latitude": 21.016574673799376,
          "to_longitude": 105.80579726070404,
          "from_longitude": 105.8061406365718
        }
      },
      {
        "id": 1359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80555402500977,
            21.016743131803302,
            105.80598234794016,
            21.017080163619344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80555402500977,
              21.016743131803302
            ],
            [
              105.80556022012422,
              21.016747997971553
            ],
            [
              105.80564538760525,
              21.016814864478274
            ],
            [
              105.80576230946991,
              21.016908832776135
            ],
            [
              105.80591159372734,
              21.017025056799678
            ],
            [
              105.80598234794016,
              21.017080163619344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_96_Ngõ 100, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1360",
          "diaChiLapD": "Ngõ 100, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016743131803302,
          "to_latitude": 21.017080163619344,
          "to_longitude": 105.80598234794016,
          "from_longitude": 105.80555402500977
        }
      },
      {
        "id": 1360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80508302426941,
            21.017751149562066,
            105.8052944821244,
            21.01782784169694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80508302426941,
              21.017760362727635
            ],
            [
              105.80516544735113,
              21.017820897259064
            ],
            [
              105.80517490218203,
              21.01782784169694
            ],
            [
              105.80523898476042,
              21.017751149562066
            ],
            [
              105.8052944821244,
              21.017791322626614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1361",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017760362727635,
          "to_latitude": 21.017791322626614,
          "to_longitude": 105.8052944821244,
          "from_longitude": 105.80508302426941
        }
      },
      {
        "id": 1361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80512723330085,
            21.017541111352116,
            105.80521793776303,
            21.017605233133917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80521793776303,
              21.017605233133917
            ],
            [
              105.80521580533524,
              21.01760366257605
            ],
            [
              105.80512723330085,
              21.017541111352116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1362",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017605233133917,
          "to_latitude": 21.017541111352116,
          "to_longitude": 105.80512723330085,
          "from_longitude": 105.80521793776303
        }
      },
      {
        "id": 1362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80531760756095,
            21.01713741873262,
            105.80535891286341,
            21.017161794646615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80535891286341,
              21.017161794646615
            ],
            [
              105.80531760756095,
              21.01713741873262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1363",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017161794646615,
          "to_latitude": 21.01713741873262,
          "to_longitude": 105.80531760756095,
          "from_longitude": 105.80535891286341
        }
      },
      {
        "id": 1363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8053413517059,
            21.01706337633306,
            105.80541800034877,
            21.01721749916885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80541800034877,
              21.01706337633306
            ],
            [
              105.80535891286341,
              21.017161794646615
            ],
            [
              105.8053413517059,
              21.01719103944457
            ],
            [
              105.80539141909478,
              21.01721749916885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1364",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01706337633306,
          "to_latitude": 21.01721749916885,
          "to_longitude": 105.80539141909478,
          "from_longitude": 105.80541800034877
        }
      },
      {
        "id": 1364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80525118378318,
            21.016940498570914,
            105.80547503043611,
            21.017105084806673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80525118378318,
              21.016940498570914
            ],
            [
              105.80525629657576,
              21.01694426612691
            ],
            [
              105.80527128531216,
              21.01695530489952
            ],
            [
              105.80541800034877,
              21.01706337633306
            ],
            [
              105.80547503043611,
              21.017105084806673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1365",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016940498570914,
          "to_latitude": 21.017105084806673,
          "to_longitude": 105.80547503043611,
          "from_longitude": 105.80525118378318
        }
      },
      {
        "id": 1365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80598868915455,
            21.020287581332198,
            105.8063118954749,
            21.020534823211573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8063118954749,
              21.020534823211573
            ],
            [
              105.8062797088293,
              21.020519800933023
            ],
            [
              105.80623612294002,
              21.020497267511107
            ],
            [
              105.80611140021713,
              21.020432796866253
            ],
            [
              105.80598868915455,
              21.020365822477874
            ],
            [
              105.80602758118476,
              21.020287581332198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1366",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020534823211573,
          "to_latitude": 21.020287581332198,
          "to_longitude": 105.80602758118476,
          "from_longitude": 105.8063118954749
        }
      },
      {
        "id": 1366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80620594798165,
            21.020178356623262,
            105.80629244923075,
            21.020330457484608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80629244923075,
              21.020178356623262
            ],
            [
              105.80620594798165,
              21.020330457484608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1367",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.56,
          "chatLieu": "HDPE",
          "chieuDai": 18.969,
          "from_latitude": 21.020178356623262,
          "to_latitude": 21.020330457484608,
          "to_longitude": 105.80620594798165,
          "from_longitude": 105.80629244923075
        }
      },
      {
        "id": 1367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8058468672877,
            21.020060681775966,
            105.80616236209237,
            21.020222171702297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80616236209237,
              21.020107313575764
            ],
            [
              105.80609295996031,
              21.020222171702297
            ],
            [
              105.80598365994541,
              21.020173349184713
            ],
            [
              105.8059427562549,
              21.020110130260207
            ],
            [
              105.8058468672877,
              21.020060681775966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1368",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.02,
          "chatLieu": "HDPE",
          "chieuDai": 46.225,
          "from_latitude": 21.020107313575764,
          "to_latitude": 21.020060681775966,
          "to_longitude": 105.8058468672877,
          "from_longitude": 105.80616236209237
        }
      },
      {
        "id": 1368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80514458952331,
            21.018938336642123,
            105.8052222077142,
            21.01900551606899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8052222077142,
              21.019004318656066
            ],
            [
              105.80520481339846,
              21.01900551606899
            ],
            [
              105.8051997842612,
              21.018986738026086
            ],
            [
              105.80519456487016,
              21.018967794657275
            ],
            [
              105.80514458952331,
              21.018938336642123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_127_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1369",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": 12.257,
          "from_latitude": 21.019004318656066,
          "to_latitude": 21.018938336642123,
          "to_longitude": 105.80514458952331,
          "from_longitude": 105.8052222077142
        }
      },
      {
        "id": 1369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8039591780382,
            21.017496301599774,
            105.80417599188478,
            21.017654235805104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80417599188478,
              21.017654235805104
            ],
            [
              105.80404507556689,
              21.017564338380716
            ],
            [
              105.8039591780382,
              21.017496301599774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1370",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.86,
          "chatLieu": "HDPE",
          "chieuDai": 28.544,
          "from_latitude": 21.017654235805104,
          "to_latitude": 21.017496301599774,
          "to_longitude": 105.8039591780382,
          "from_longitude": 105.80417599188478
        }
      },
      {
        "id": 1370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80424749243689,
            21.01738431901159,
            105.80435172093928,
            21.017469320514895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80435172093928,
              21.017469320514895
            ],
            [
              105.80424749243689,
              21.01738431901159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1371",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017469320514895,
          "to_latitude": 21.01738431901159,
          "to_longitude": 105.80424749243689,
          "from_longitude": 105.80435172093928
        }
      },
      {
        "id": 1371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80591143412902,
            21.020905261016956,
            105.80648794101187,
            21.020929178308197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80591143412902,
              21.020905261016956
            ],
            [
              105.80594659255638,
              21.020906719341
            ],
            [
              105.80648794101187,
              21.020929178308197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1372",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020905261016956,
          "to_latitude": 21.020929178308197,
          "to_longitude": 105.80648794101187,
          "from_longitude": 105.80591143412902
        }
      },
      {
        "id": 1372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80598644227058,
            21.020600127874534,
            105.80600461694681,
            21.020702125309768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80598644227058,
              21.020702125309768
            ],
            [
              105.80598708753044,
              21.02069850391735
            ],
            [
              105.80600461694681,
              21.020600127874534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_149_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1373",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020702125309768,
          "to_latitude": 21.020600127874534,
          "to_longitude": 105.80600461694681,
          "from_longitude": 105.80598644227058
        }
      },
      {
        "id": 1373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80641372348946,
            21.021587651928538,
            105.80670488427792,
            21.02177564915107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80667229464679,
              21.02177564915107
            ],
            [
              105.80670468138798,
              21.021644530103305
            ],
            [
              105.80670488427792,
              21.021587651928538
            ],
            [
              105.80641372348946,
              21.021590466021564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1374",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02177564915107,
          "to_latitude": 21.021590466021564,
          "to_longitude": 105.80641372348946,
          "from_longitude": 105.80667229464679
        }
      },
      {
        "id": 1374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80706518328428,
            21.021333680900348,
            105.8070671897266,
            21.021787129477143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80706693605143,
              21.021787129477143
            ],
            [
              105.8070671897266,
              21.02177992683191
            ],
            [
              105.80706695699449,
              21.02155599046867
            ],
            [
              105.80706518328428,
              21.021333680900348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_158_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1375",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021787129477143,
          "to_latitude": 21.021333680900348,
          "to_longitude": 105.80706518328428,
          "from_longitude": 105.80706693605143
        }
      },
      {
        "id": 1375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80742356453685,
            21.021738461719615,
            105.80823151100007,
            21.02190959324564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80744127710128,
              21.021738461719615
            ],
            [
              105.80744150829786,
              21.02174459535027
            ],
            [
              105.80742356453685,
              21.021822499088668
            ],
            [
              105.80742672610349,
              21.02190959324564
            ],
            [
              105.80819094004241,
              21.02190803392353
            ],
            [
              105.80822722383616,
              21.021896390513106
            ],
            [
              105.80823151100007,
              21.02174729500458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_159_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1376",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021738461719615,
          "to_latitude": 21.02174729500458,
          "to_longitude": 105.80823151100007,
          "from_longitude": 105.80744127710128
        }
      },
      {
        "id": 1376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80788199295222,
            21.0209517226588,
            105.80822934489566,
            21.02100845811098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80788199295222,
              21.0209517226588
            ],
            [
              105.80799840253496,
              21.020996704178515
            ],
            [
              105.80801637408028,
              21.02100365044132
            ],
            [
              105.80802449607013,
              21.02100383405258
            ],
            [
              105.80808949314228,
              21.021005301021734
            ],
            [
              105.80822934489566,
              21.02100845811098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1377",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0209517226588,
          "to_latitude": 21.02100845811098,
          "to_longitude": 105.80822934489566,
          "from_longitude": 105.80788199295222
        }
      },
      {
        "id": 1377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80525944480071,
            21.020334926459547,
            105.80556215344063,
            21.02067035086198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80556215344063,
              21.02067035086198
            ],
            [
              105.80525944480071,
              21.020518909071765
            ],
            [
              105.80535374978665,
              21.020334926459547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_148_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1378",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02067035086198,
          "to_latitude": 21.020334926459547,
          "to_longitude": 105.80535374978665,
          "from_longitude": 105.80556215344063
        }
      },
      {
        "id": 1378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80547490198559,
            21.02078077643984,
            105.8056407377946,
            21.0208611705626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80547490198559,
              21.02078077643984
            ],
            [
              105.8056407377946,
              21.0208611705626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_150_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1379",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02078077643984,
          "to_latitude": 21.0208611705626,
          "to_longitude": 105.8056407377946,
          "from_longitude": 105.80547490198559
        }
      },
      {
        "id": 1379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80551376676836,
            21.019956114531023,
            105.8055707097055,
            21.020077516239343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80551376676836,
              21.020077516239343
            ],
            [
              105.80554310826695,
              21.020018501208792
            ],
            [
              105.8055707097055,
              21.019956114531023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_142_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1380",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020077516239343,
          "to_latitude": 21.019956114531023,
          "to_longitude": 105.8055707097055,
          "from_longitude": 105.80551376676836
        }
      },
      {
        "id": 1380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80579539944672,
            21.01998629930232,
            105.80581241325854,
            21.01999461150802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80581241325854,
              21.01999461150802
            ],
            [
              105.80580695321352,
              21.01999194394477
            ],
            [
              105.80579981108563,
              21.019988456088704
            ],
            [
              105.80579539944672,
              21.01998629930232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1381",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01999461150802,
          "to_latitude": 21.01998629930232,
          "to_longitude": 105.80579539944672,
          "from_longitude": 105.80581241325854
        }
      },
      {
        "id": 1381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80753907583792,
            21.021718837031298,
            105.80819500532999,
            21.02172326576807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80753907583792,
              21.02172326576807
            ],
            [
              105.80754866717038,
              21.021723158811156
            ],
            [
              105.80779340736147,
              21.02172044852666
            ],
            [
              105.80784775601644,
              21.02171984627379
            ],
            [
              105.80785994095427,
              21.02172011627235
            ],
            [
              105.80788705086974,
              21.021720715510572
            ],
            [
              105.8079518787643,
              21.02172214900454
            ],
            [
              105.80819500532999,
              21.021718837031298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_160_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1382",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02172326576807,
          "to_latitude": 21.021718837031298,
          "to_longitude": 105.80819500532999,
          "from_longitude": 105.80753907583792
        }
      },
      {
        "id": 1382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80762421982512,
            21.019871524026318,
            105.80794674642883,
            21.020034308168714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80794674642883,
              21.019871524026318
            ],
            [
              105.80780529065922,
              21.019942918657026
            ],
            [
              105.80762421982512,
              21.020034308168714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_170_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1383",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019871524026318,
          "to_latitude": 21.020034308168714,
          "to_longitude": 105.80762421982512,
          "from_longitude": 105.80794674642883
        }
      },
      {
        "id": 1383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80731495387926,
            21.018787352320064,
            105.80742724771554,
            21.018846823682466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80731495387926,
              21.018846823682466
            ],
            [
              105.80736774975003,
              21.01881886295322
            ],
            [
              105.80742054560072,
              21.01879090220812
            ],
            [
              105.80742724771554,
              21.018787352320064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_195_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1384",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018846823682466,
          "to_latitude": 21.018787352320064,
          "to_longitude": 105.80742724771554,
          "from_longitude": 105.80731495387926
        }
      },
      {
        "id": 1384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80722152329037,
            21.02024655302478,
            105.80724943996285,
            21.02029904296762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80722152329037,
              21.02029904296762
            ],
            [
              105.8072266143805,
              21.020289455421562
            ],
            [
              105.80722950084053,
              21.020284016931768
            ],
            [
              105.80723094931257,
              21.020281288629697
            ],
            [
              105.80723224175615,
              21.02027888261749
            ],
            [
              105.80724528525636,
              21.020254603348523
            ],
            [
              105.80724943996285,
              21.02024655302478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_188_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1385",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02029904296762,
          "to_latitude": 21.02024655302478,
          "to_longitude": 105.80724943996285,
          "from_longitude": 105.80722152329037
        }
      },
      {
        "id": 1385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80333356224493,
            21.01757982747431,
            105.80342329694857,
            21.017646935470616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80342329694857,
              21.017646935470616
            ],
            [
              105.80336644468936,
              21.017604420684158
            ],
            [
              105.80333356224493,
              21.01757982747431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_200_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1386",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017646935470616,
          "to_latitude": 21.01757982747431,
          "to_longitude": 105.80333356224493,
          "from_longitude": 105.80342329694857
        }
      },
      {
        "id": 1386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80592097128765,
            21.020919962847298,
            105.80593948368036,
            21.021132249496024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80593096635016,
              21.020921956786935
            ],
            [
              105.80592661773747,
              21.02092097209744
            ],
            [
              105.80592097128765,
              21.020919962847298
            ],
            [
              105.80592132895445,
              21.020939079798133
            ],
            [
              105.80592997708804,
              21.020939790308816
            ],
            [
              105.80593948368036,
              21.02094057081627
            ],
            [
              105.80593193481089,
              21.021007108615557
            ],
            [
              105.80592767148491,
              21.021132249496024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_192_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1387",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020921956786935,
          "to_latitude": 21.021132249496024,
          "to_longitude": 105.80592767148491,
          "from_longitude": 105.80593096635016
        }
      },
      {
        "id": 1387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80724334775724,
            21.020255786653966,
            105.80726715627019,
            21.020299977205717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80724334775724,
              21.020299977205717
            ],
            [
              105.80724843788883,
              21.020290390566835
            ],
            [
              105.80725198855961,
              21.020290039521537
            ],
            [
              105.80725177282885,
              21.020288384927117
            ],
            [
              105.8072513243432,
              21.020284951173505
            ],
            [
              105.80726514923427,
              21.0202598290953
            ],
            [
              105.80726715627019,
              21.020255786653966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_185_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1389",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020299977205717,
          "to_latitude": 21.020255786653966,
          "to_longitude": 105.80726715627019,
          "from_longitude": 105.80724334775724
        }
      },
      {
        "id": 1388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80723924459522,
            21.020253656367533,
            105.80726306893031,
            21.02029723099185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80723924459522,
              21.02029723099185
            ],
            [
              105.80724433376992,
              21.020287645260858
            ],
            [
              105.80724796306454,
              21.020286531517478
            ],
            [
              105.80724864878118,
              21.02028632142385
            ],
            [
              105.80724722214345,
              21.02028220495524
            ],
            [
              105.80726052989672,
              21.020259480694165
            ],
            [
              105.80726306893031,
              21.020253656367533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_186_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1390",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02029723099185,
          "to_latitude": 21.020253656367533,
          "to_longitude": 105.80726306893031,
          "from_longitude": 105.80723924459522
        }
      },
      {
        "id": 1389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80332625023969,
            21.01775868070018,
            105.80336067773936,
            21.017803029271832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80336067773936,
              21.01775868070018
            ],
            [
              105.80333742704498,
              21.01778863139897
            ],
            [
              105.80333823756891,
              21.01779448230583
            ],
            [
              105.80333225470955,
              21.017795295465234
            ],
            [
              105.80332625023969,
              21.017803029271832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1391",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01775868070018,
          "to_latitude": 21.017803029271832,
          "to_longitude": 105.80332625023969,
          "from_longitude": 105.80336067773936
        }
      },
      {
        "id": 1390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80631120174378,
            21.019822837305618,
            105.8063651378308,
            21.019904162291454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80631120174378,
              21.019904162291454
            ],
            [
              105.8063651378308,
              21.019822837305618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_190_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1392",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019904162291454,
          "to_latitude": 21.019822837305618,
          "to_longitude": 105.8063651378308,
          "from_longitude": 105.80631120174378
        }
      },
      {
        "id": 1391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80573010637809,
            21.019533225103594,
            105.80576681388261,
            21.019602578350938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80573010637809,
              21.019602578350938
            ],
            [
              105.80574403204349,
              21.01957466206888
            ],
            [
              105.8057552286088,
              21.01955429450848
            ],
            [
              105.80576642709991,
              21.019533927841476
            ],
            [
              105.80576681388261,
              21.019533225103594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_193_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1393",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": 8.577,
          "from_latitude": 21.019602578350938,
          "to_latitude": 21.019533225103594,
          "to_longitude": 105.80576681388261,
          "from_longitude": 105.80573010637809
        }
      },
      {
        "id": 1392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80694451474193,
            21.018140007114688,
            105.80705135891226,
            21.01819659094375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80694451474193,
              21.01819659094375
            ],
            [
              105.80698613549883,
              21.01817454891402
            ],
            [
              105.80698684628432,
              21.01817417250858
            ],
            [
              105.80702775624324,
              21.018152506874422
            ],
            [
              105.80705135891226,
              21.018140007114688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_198_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1394",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01819659094375,
          "to_latitude": 21.018140007114688,
          "to_longitude": 105.80705135891226,
          "from_longitude": 105.80694451474193
        }
      },
      {
        "id": 1393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80760502877165,
            21.020059896527734,
            105.80764469076222,
            21.02015527161492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80764469076222,
              21.02015527161492
            ],
            [
              105.8076073726853,
              21.020065536901093
            ],
            [
              105.80760502877165,
              21.020059896527734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_184_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1395",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02015527161492,
          "to_latitude": 21.020059896527734,
          "to_longitude": 105.80760502877165,
          "from_longitude": 105.80764469076222
        }
      },
      {
        "id": 1394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80478889464662,
            21.01674890206846,
            105.8049841032983,
            21.016847696634315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8049841032983,
              21.016847696634315
            ],
            [
              105.80497388825019,
              21.01684230030263
            ],
            [
              105.80497007290938,
              21.016840282476664
            ],
            [
              105.804960693772,
              21.016834343864097
            ],
            [
              105.80492874371127,
              21.01681411456897
            ],
            [
              105.80482799318087,
              21.01674890206846
            ],
            [
              105.80478889464662,
              21.0167910113345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.46_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1396",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016847696634315,
          "to_latitude": 21.0167910113345,
          "to_longitude": 105.80478889464662,
          "from_longitude": 105.8049841032983
        }
      },
      {
        "id": 1395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80451104259103,
            21.016509721400872,
            105.80453221428567,
            21.01652984548912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80453221428567,
              21.016509721400872
            ],
            [
              105.80451104259103,
              21.01652984548912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12-12A_Ngõ 678, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1397",
          "diaChiLapD": "Ngõ 678, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": 3.132,
          "from_latitude": 21.016509721400872,
          "to_latitude": 21.01652984548912,
          "to_longitude": 105.80451104259103,
          "from_longitude": 105.80453221428567
        }
      },
      {
        "id": 1396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80648705137308,
            21.018066833806937,
            105.80658423638123,
            21.018191753874365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80648705137308,
              21.018066833806937
            ],
            [
              105.80658423638123,
              21.018191753874365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1398",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018066833806937,
          "to_latitude": 21.018191753874365,
          "to_longitude": 105.80658423638123,
          "from_longitude": 105.80648705137308
        }
      },
      {
        "id": 1397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8062942947654,
            21.018190728497466,
            105.80639557955695,
            21.018333000142295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8062942947654,
              21.018190728497466
            ],
            [
              105.80639557955695,
              21.018333000142295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1399",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018190728497466,
          "to_latitude": 21.018333000142295,
          "to_longitude": 105.80639557955695,
          "from_longitude": 105.8062942947654
        }
      },
      {
        "id": 1398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80619916947667,
            21.01782071809147,
            105.80686995899417,
            21.018196314609796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80686995899417,
              21.01782071809147
            ],
            [
              105.80678380369088,
              21.017854041281144
            ],
            [
              105.80648705137308,
              21.018066833806937
            ],
            [
              105.8062942947654,
              21.018190728497466
            ],
            [
              105.80628560464643,
              21.018196314609796
            ],
            [
              105.80619916947667,
              21.018078859189384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_03_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1400",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01782071809147,
          "to_latitude": 21.018078859189384,
          "to_longitude": 105.80619916947667,
          "from_longitude": 105.80686995899417
        }
      },
      {
        "id": 1399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80699315453076,
            21.019858425500974,
            105.80704085733404,
            21.019880462084274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80704085733404,
              21.019858425500974
            ],
            [
              105.80699315453076,
              21.019880462084274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1401",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019858425500974,
          "to_latitude": 21.019880462084274,
          "to_longitude": 105.80699315453076,
          "from_longitude": 105.80704085733404
        }
      },
      {
        "id": 1400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80700340853328,
            21.019875119104412,
            105.80705111037332,
            21.019897156589312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80705111037332,
              21.019875119104412
            ],
            [
              105.80700340853328,
              21.019897156589312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1402",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019875119104412,
          "to_latitude": 21.019897156589312,
          "to_longitude": 105.80700340853328,
          "from_longitude": 105.80705111037332
        }
      },
      {
        "id": 1401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80711470143525,
            21.02005632246048,
            105.80716240523833,
            21.020078359986584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80716240523833,
              21.02005632246048
            ],
            [
              105.80711470143525,
              21.020078359986584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1403",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02005632246048,
          "to_latitude": 21.020078359986584,
          "to_longitude": 105.80711470143525,
          "from_longitude": 105.80716240523833
        }
      },
      {
        "id": 1402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80712510199281,
            21.020073254715566,
            105.80717280484735,
            21.020095290433687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80717280484735,
              21.020073254715566
            ],
            [
              105.80712510199281,
              21.020095290433687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1404",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020073254715566,
          "to_latitude": 21.020095290433687,
          "to_longitude": 105.80712510199281,
          "from_longitude": 105.80717280484735
        }
      },
      {
        "id": 1403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8027318203215,
            21.019273066234582,
            105.8029883786604,
            21.019796360065452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8027318203215,
              21.019796360065452
            ],
            [
              105.80274320564635,
              21.019775233088602
            ],
            [
              105.80275281357369,
              21.01975740135122
            ],
            [
              105.80290835767968,
              21.019430486669325
            ],
            [
              105.8029883786604,
              21.019273066234582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1405",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019796360065452,
          "to_latitude": 21.019273066234582,
          "to_longitude": 105.8029883786604,
          "from_longitude": 105.8027318203215
        }
      },
      {
        "id": 1404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80290419095292,
            21.019209194015506,
            105.80325245878066,
            21.0198885701456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80290419095292,
              21.0198885701456
            ],
            [
              105.80291269922652,
              21.01986299207487
            ],
            [
              105.80292018881407,
              21.019840476243942
            ],
            [
              105.80303397173365,
              21.019629796465104
            ],
            [
              105.80325245878066,
              21.019209194015506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1406",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0198885701456,
          "to_latitude": 21.019209194015506,
          "to_longitude": 105.80325245878066,
          "from_longitude": 105.80290419095292
        }
      },
      {
        "id": 1405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80385816859496,
            21.019349518050067,
            105.80394585101173,
            21.01950259307198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80391856959945,
              21.019349518050067
            ],
            [
              105.8038639208904,
              21.01945425194955
            ],
            [
              105.80385816859496,
              21.019467402152557
            ],
            [
              105.80394585101173,
              21.01950259307198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M54_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1407",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019349518050067,
          "to_latitude": 21.01950259307198,
          "to_longitude": 105.80394585101173,
          "from_longitude": 105.80391856959945
        }
      },
      {
        "id": 1406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8046763869606,
            21.019651669267702,
            105.80474200330045,
            21.019744080155334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8046763869606,
              21.019744080155334
            ],
            [
              105.80474200330045,
              21.019651669267702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M57_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1408",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019744080155334,
          "to_latitude": 21.019651669267702,
          "to_longitude": 105.80474200330045,
          "from_longitude": 105.8046763869606
        }
      },
      {
        "id": 1407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80379959764092,
            21.01892097811332,
            105.80385244803419,
            21.01901643636238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80379959764092,
              21.01901643636238
            ],
            [
              105.80383230711519,
              21.018957356014816
            ],
            [
              105.80385244803419,
              21.01892097811332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M60_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1409",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01901643636238,
          "to_latitude": 21.01892097811332,
          "to_longitude": 105.80385244803419,
          "from_longitude": 105.80379959764092
        }
      },
      {
        "id": 1408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8071264228318,
            21.022155624559876,
            105.8075869157559,
            21.022163680001057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8071264228318,
              21.02215613671743
            ],
            [
              105.80750482788362,
              21.022163680001057
            ],
            [
              105.807563842382,
              21.022157015794043
            ],
            [
              105.8075869157559,
              21.022155624559876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1410",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02215613671743,
          "to_latitude": 21.022155624559876,
          "to_longitude": 105.8075869157559,
          "from_longitude": 105.8071264228318
        }
      },
      {
        "id": 1409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80712394526422,
            21.022115134151,
            105.80712710061412,
            21.022305991378712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80712710061412,
              21.022115134151
            ],
            [
              105.8071264228318,
              21.02215613671743
            ],
            [
              105.80712394526422,
              21.022305991378712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1411",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022115134151,
          "to_latitude": 21.022305991378712,
          "to_longitude": 105.80712394526422,
          "from_longitude": 105.80712710061412
        }
      },
      {
        "id": 1410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80357969430764,
            21.018589798316103,
            105.80386255407751,
            21.018970240030878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80357969430764,
              21.018970240030878
            ],
            [
              105.80362169214618,
              21.018899769475016
            ],
            [
              105.80367795888894,
              21.018810478646376
            ],
            [
              105.80368879581499,
              21.01879619616364
            ],
            [
              105.80370601644755,
              21.018773500646894
            ],
            [
              105.80372026253063,
              21.018754724746685
            ],
            [
              105.80375756795124,
              21.018703309180488
            ],
            [
              105.80375845410408,
              21.018702087440143
            ],
            [
              105.80380852022931,
              21.018643909606542
            ],
            [
              105.80386255407751,
              21.018589798316103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M61_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1412",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018970240030878,
          "to_latitude": 21.018589798316103,
          "to_longitude": 105.80386255407751,
          "from_longitude": 105.80357969430764
        }
      },
      {
        "id": 1411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80375756795124,
            21.018703309180488,
            105.80389748877853,
            21.018793866047996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80375756795124,
              21.018703309180488
            ],
            [
              105.80389748877853,
              21.018793866047996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M61_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1413",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018703309180488,
          "to_latitude": 21.018793866047996,
          "to_longitude": 105.80389748877853,
          "from_longitude": 105.80375756795124
        }
      },
      {
        "id": 1412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80369415283891,
            21.018764935372744,
            105.80370601644755,
            21.018773500646894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80370601644755,
              21.018773500646894
            ],
            [
              105.80370543123333,
              21.018773077990776
            ],
            [
              105.80369905473572,
              21.018768476356705
            ],
            [
              105.80369415283891,
              21.018764935372744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M61_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1414",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018773500646894,
          "to_latitude": 21.018764935372744,
          "to_longitude": 105.80369415283891,
          "from_longitude": 105.80370601644755
        }
      },
      {
        "id": 1413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80334885585228,
            21.019409328897083,
            105.80336671085423,
            21.019419194548433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80336671085423,
              21.019419194548433
            ],
            [
              105.80335466390581,
              21.01941250522232
            ],
            [
              105.80334901505174,
              21.01940941756496
            ],
            [
              105.80334885585228,
              21.019409328897083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1415",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019419194548433,
          "to_latitude": 21.019409328897083,
          "to_longitude": 105.80334885585228,
          "from_longitude": 105.80336671085423
        }
      },
      {
        "id": 1414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80339013821732,
            21.019372078940865,
            105.80354982200986,
            21.019463333965266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80339013821732,
              21.019372078940865
            ],
            [
              105.80354982200986,
              21.019463333965266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1416",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019372078940865,
          "to_latitude": 21.019463333965266,
          "to_longitude": 105.80354982200986,
          "from_longitude": 105.80339013821732
        }
      },
      {
        "id": 1415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80333851648938,
            21.019149978970372,
            105.80352770894092,
            21.01948077349817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80333851648938,
              21.01948077349817
            ],
            [
              105.80336671085423,
              21.019419194548433
            ],
            [
              105.8033810438392,
              21.0193878904723
            ],
            [
              105.80339013821732,
              21.019372078940865
            ],
            [
              105.80340976805456,
              21.019337953447423
            ],
            [
              105.80342994519984,
              21.019305795310224
            ],
            [
              105.80352770894092,
              21.019149978970372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1417",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01948077349817,
          "to_latitude": 21.019149978970372,
          "to_longitude": 105.80352770894092,
          "from_longitude": 105.80333851648938
        }
      },
      {
        "id": 1416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80368854687168,
            21.01961270247802,
            105.80379275675895,
            21.019761771257254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80368854687168,
              21.019761771257254
            ],
            [
              105.80373855257805,
              21.019712533890512
            ],
            [
              105.80379275675895,
              21.0196251576821
            ],
            [
              105.80376860232884,
              21.01961270247802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M43_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1418",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019761771257254,
          "to_latitude": 21.01961270247802,
          "to_longitude": 105.80376860232884,
          "from_longitude": 105.80368854687168
        }
      },
      {
        "id": 1417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80349549578158,
            21.019715432032058,
            105.80359370913858,
            21.01989133835319
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80359370913858,
              21.019715432032058
            ],
            [
              105.80349549578158,
              21.01989133835319
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M42_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1419",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019715432032058,
          "to_latitude": 21.01989133835319,
          "to_longitude": 105.80349549578158,
          "from_longitude": 105.80359370913858
        }
      },
      {
        "id": 1418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80363576870525,
            21.019797499687723,
            105.80374143789187,
            21.020015963299986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80374143789187,
              21.019797499687723
            ],
            [
              105.8037399277723,
              21.01980040347963
            ],
            [
              105.80373864141515,
              21.019802878083524
            ],
            [
              105.80363576870525,
              21.020000742324406
            ],
            [
              105.80365914438514,
              21.020015963299986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M44_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1420",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019797499687723,
          "to_latitude": 21.020015963299986,
          "to_longitude": 105.80365914438514,
          "from_longitude": 105.80374143789187
        }
      },
      {
        "id": 1419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80403648012796,
            21.019974842144432,
            105.8040950629416,
            21.020127742231235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8040950629416,
              21.019974842144432
            ],
            [
              105.80408925408778,
              21.019990002306933
            ],
            [
              105.80403648012796,
              21.020127742231235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M48_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1421",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019974842144432,
          "to_latitude": 21.020127742231235,
          "to_longitude": 105.80403648012796,
          "from_longitude": 105.8040950629416
        }
      },
      {
        "id": 1420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80396582604382,
            21.019715799788,
            105.80412102885796,
            21.01989856833502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80396582604382,
              21.01989856833502
            ],
            [
              105.8040056342905,
              21.019827699778872
            ],
            [
              105.80404921530511,
              21.01974552120786
            ],
            [
              105.80406502366188,
              21.019715799788
            ],
            [
              105.80412102885796,
              21.019740983530955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M45_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1422",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01989856833502,
          "to_latitude": 21.019740983530955,
          "to_longitude": 105.80412102885796,
          "from_longitude": 105.80396582604382
        }
      },
      {
        "id": 1421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8038551171164,
            21.019898506936702,
            105.80395664852547,
            21.020152556663426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80395664852547,
              21.019898506936702
            ],
            [
              105.80395357515334,
              21.019903194770873
            ],
            [
              105.80395057334103,
              21.019907772976023
            ],
            [
              105.80392169640804,
              21.019951817237608
            ],
            [
              105.80390081442854,
              21.019987254773987
            ],
            [
              105.80387554398797,
              21.02005285109942
            ],
            [
              105.80387478038647,
              21.020109955057787
            ],
            [
              105.8038551171164,
              21.020152556663426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M46_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1423",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019898506936702,
          "to_latitude": 21.020152556663426,
          "to_longitude": 105.8038551171164,
          "from_longitude": 105.80395664852547
        }
      },
      {
        "id": 1422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8044731125219,
            21.020325268845255,
            105.80461776444137,
            21.020393607624563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8044731125219,
              21.020325268845255
            ],
            [
              105.80461776444137,
              21.020393607624563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1424",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020325268845255,
          "to_latitude": 21.020393607624563,
          "to_longitude": 105.80461776444137,
          "from_longitude": 105.8044731125219
        }
      },
      {
        "id": 1423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8043147668598,
            21.020325268845255,
            105.80452437485738,
            21.020973832153942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8044731125219,
              21.020325268845255
            ],
            [
              105.80444483918284,
              21.020383187549868
            ],
            [
              105.80440256012173,
              21.020456465960738
            ],
            [
              105.80437512752096,
              21.020499324751395
            ],
            [
              105.80435999245755,
              21.02052472828834
            ],
            [
              105.80434610697283,
              21.020545823834553
            ],
            [
              105.8043185938054,
              21.020595548442426
            ],
            [
              105.80431617165138,
              21.02060596870874
            ],
            [
              105.8043147668598,
              21.02061200892225
            ],
            [
              105.80452437485738,
              21.020750003835175
            ],
            [
              105.804467491546,
              21.020832165045817
            ],
            [
              105.80438934929484,
              21.020973832153942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1425",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020325268845255,
          "to_latitude": 21.020973832153942,
          "to_longitude": 105.80438934929484,
          "from_longitude": 105.8044731125219
        }
      },
      {
        "id": 1424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80460563596506,
            21.01999183459863,
            105.80473588134507,
            21.020056804645247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80473588134507,
              21.020056804645247
            ],
            [
              105.80473127560633,
              21.020054506942195
            ],
            [
              105.80460563596506,
              21.01999183459863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1426",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020056804645247,
          "to_latitude": 21.01999183459863,
          "to_longitude": 105.80460563596506,
          "from_longitude": 105.80473588134507
        }
      },
      {
        "id": 1425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8044546843548,
            21.019723635230363,
            105.80461707388153,
            21.01988652660959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80461707388153,
              21.019723635230363
            ],
            [
              105.8045548110256,
              21.019829883940236
            ],
            [
              105.80449406321196,
              21.01980702962091
            ],
            [
              105.8044546843548,
              21.01988652660959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M58_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1427",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019723635230363,
          "to_latitude": 21.01988652660959,
          "to_longitude": 105.8044546843548,
          "from_longitude": 105.80461707388153
        }
      },
      {
        "id": 1426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80405379102929,
            21.019271061119507,
            105.80413482789541,
            21.019391643234723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80405379102929,
              21.019391643234723
            ],
            [
              105.80413482789541,
              21.019271061119507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M55_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1428",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019391643234723,
          "to_latitude": 21.019271061119507,
          "to_longitude": 105.80413482789541,
          "from_longitude": 105.80405379102929
        }
      },
      {
        "id": 1427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8044284930029,
            21.019608955353295,
            105.80489349089287,
            21.01987602458816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80489349089287,
              21.01987602458816
            ],
            [
              105.80478790325571,
              21.019821537393742
            ],
            [
              105.80465983872898,
              21.019747581675247
            ],
            [
              105.80461707388153,
              21.019723635230363
            ],
            [
              105.8045707940403,
              21.019697721005308
            ],
            [
              105.80447805876922,
              21.019643098755026
            ],
            [
              105.80447625804872,
              21.019641859008434
            ],
            [
              105.8044284930029,
              21.019608955353295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M58_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1429",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 248,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01987602458816,
          "to_latitude": 21.019608955353295,
          "to_longitude": 105.8044284930029,
          "from_longitude": 105.80489349089287
        }
      },
      {
        "id": 1428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8049418314618,
            21.019510720550446,
            105.80511715875318,
            21.019600343780972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80511715875318,
              21.019600343780972
            ],
            [
              105.80511413757846,
              21.019598799106987
            ],
            [
              105.8049418314618,
              21.019510720550446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1430",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019600343780972,
          "to_latitude": 21.019510720550446,
          "to_longitude": 105.8049418314618,
          "from_longitude": 105.80511715875318
        }
      },
      {
        "id": 1429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80492649707129,
            21.01925766004658,
            105.80526321825954,
            21.019883740950252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80492649707129,
              21.019883740950252
            ],
            [
              105.80496692423783,
              21.01985370966166
            ],
            [
              105.80506006048209,
              21.01969823231976
            ],
            [
              105.80510842641023,
              21.019616902463152
            ],
            [
              105.80511715875318,
              21.019600343780972
            ],
            [
              105.80516857572624,
              21.019502841601035
            ],
            [
              105.80519302737495,
              21.01942569215627
            ],
            [
              105.80521212941484,
              21.019369307978806
            ],
            [
              105.80524315100477,
              21.019290143032777
            ],
            [
              105.80525547851364,
              21.01927167596319
            ],
            [
              105.80525839958494,
              21.0192672986352
            ],
            [
              105.80526321825954,
              21.01925766004658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_136_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1431",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019883740950252,
          "to_latitude": 21.01925766004658,
          "to_longitude": 105.80526321825954,
          "from_longitude": 105.80492649707129
        }
      },
      {
        "id": 1430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80314398564454,
            21.019746798060392,
            105.80316177524601,
            21.019756122103267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80316177524601,
              21.019756122103267
            ],
            [
              105.80315145417113,
              21.019750712619757
            ],
            [
              105.80314609250497,
              21.019747903601136
            ],
            [
              105.80314398564454,
              21.019746798060392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M40_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1432",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019756122103267,
          "to_latitude": 21.019746798060392,
          "to_longitude": 105.80314398564454,
          "from_longitude": 105.80316177524601
        }
      },
      {
        "id": 1431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80306129719062,
            21.019550654081993,
            105.8032841067851,
            21.019914124227732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80306129719062,
              21.019914124227732
            ],
            [
              105.80311971367749,
              21.019826810530322
            ],
            [
              105.80316177524601,
              21.019756122103267
            ],
            [
              105.80317616757586,
              21.019731933404938
            ],
            [
              105.80318676922845,
              21.019714114113693
            ],
            [
              105.80325381769292,
              21.019596341555882
            ],
            [
              105.8032841067851,
              21.019550654081993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M40_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1433",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019914124227732,
          "to_latitude": 21.019550654081993,
          "to_longitude": 105.8032841067851,
          "from_longitude": 105.80306129719062
        }
      },
      {
        "id": 1432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80317208659133,
            21.017828074852506,
            105.80321683017955,
            21.017852883057696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80321683017955,
              21.017852883057696
            ],
            [
              105.80320905757746,
              21.01784857341573
            ],
            [
              105.80320038480373,
              21.017843765827852
            ],
            [
              105.80319171394957,
              21.01783895732725
            ],
            [
              105.80317208659133,
              21.017828074852506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1434",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017852883057696,
          "to_latitude": 21.017828074852506,
          "to_longitude": 105.80317208659133,
          "from_longitude": 105.80321683017955
        }
      },
      {
        "id": 1433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8028742246113,
            21.017791008186578,
            105.803259302967,
            21.018445225209273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8028742246113,
              21.018445225209273
            ],
            [
              105.80310067769653,
              21.01802209242023
            ],
            [
              105.8031799908704,
              21.01790654986683
            ],
            [
              105.80321683017955,
              21.017852883057696
            ],
            [
              105.803259302967,
              21.017791008186578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1435",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": 82.869,
          "from_latitude": 21.018445225209273,
          "to_latitude": 21.017791008186578,
          "to_longitude": 105.803259302967,
          "from_longitude": 105.8028742246113
        }
      },
      {
        "id": 1434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80355926446838,
            21.018281947173868,
            105.80392062241249,
            21.018967431753225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80355926446838,
              21.018967431753225
            ],
            [
              105.80360437764281,
              21.018888615096497
            ],
            [
              105.80361096405295,
              21.018873327532745
            ],
            [
              105.80362529127432,
              21.018840068875544
            ],
            [
              105.80368082262503,
              21.01875303146475
            ],
            [
              105.80372178347297,
              21.01869801823434
            ],
            [
              105.80374065960092,
              21.018673702627925
            ],
            [
              105.80376930435115,
              21.018636805844505
            ],
            [
              105.80384161249968,
              21.01856867951195
            ],
            [
              105.80385153300949,
              21.018556618778096
            ],
            [
              105.80387184316935,
              21.018513778370956
            ],
            [
              105.80387816987978,
              21.018485403326512
            ],
            [
              105.8038978078879,
              21.01841569501616
            ],
            [
              105.8039043184718,
              21.018395064294424
            ],
            [
              105.80391898361165,
              21.01831808861366
            ],
            [
              105.80392062241249,
              21.018281947173868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M62_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1436",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018967431753225,
          "to_latitude": 21.018281947173868,
          "to_longitude": 105.80392062241249,
          "from_longitude": 105.80355926446838
        }
      },
      {
        "id": 1435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80345768513229,
            21.018794372277906,
            105.80361096405295,
            21.018873327532745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80361096405295,
              21.018873327532745
            ],
            [
              105.80345768513229,
              21.018794372277906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M62_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1437",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018873327532745,
          "to_latitude": 21.018794372277906,
          "to_longitude": 105.80345768513229,
          "from_longitude": 105.80361096405295
        }
      },
      {
        "id": 1436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80374065960092,
            21.018673702627925,
            105.80375848735062,
            21.018686572510628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80374065960092,
              21.018673702627925
            ],
            [
              105.8037443118792,
              21.018676340866072
            ],
            [
              105.80375132380247,
              21.018681401046493
            ],
            [
              105.80375848735062,
              21.018686572510628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M62_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1438",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018673702627925,
          "to_latitude": 21.018686572510628,
          "to_longitude": 105.80375848735062,
          "from_longitude": 105.80374065960092
        }
      },
      {
        "id": 1437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80317871398519,
            21.019669443193894,
            105.80319386032879,
            21.01967809326002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80317871398519,
              21.019669443193894
            ],
            [
              105.80318264699578,
              21.019671689019514
            ],
            [
              105.80319039144956,
              21.01967611350228
            ],
            [
              105.80319386032879,
              21.01967809326002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M41_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1439",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019669443193894,
          "to_latitude": 21.01967809326002,
          "to_longitude": 105.80319386032879,
          "from_longitude": 105.80317871398519
        }
      },
      {
        "id": 1438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80334166491373,
            21.019405333550775,
            105.80335038888273,
            21.019408187200888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80334166491373,
              21.019405333550775
            ],
            [
              105.80334817367374,
              21.019406967464075
            ],
            [
              105.80335024898987,
              21.01940811108705
            ],
            [
              105.80335038888273,
              21.019408187200888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M52_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1440",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019405333550775,
          "to_latitude": 21.019408187200888,
          "to_longitude": 105.80335038888273,
          "from_longitude": 105.80334166491373
        }
      },
      {
        "id": 1439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80327136364482,
            21.019194707266337,
            105.80343143753501,
            21.01927874286226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80343143753501,
              21.01927874286226
            ],
            [
              105.80335844853022,
              21.019240878267297
            ],
            [
              105.80327136364482,
              21.019194707266337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M52_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1441",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01927874286226,
          "to_latitude": 21.019194707266337,
          "to_longitude": 105.80327136364482,
          "from_longitude": 105.80343143753501
        }
      },
      {
        "id": 1440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80328959156881,
            21.019023081678462,
            105.80356955745542,
            21.019497429772795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80328959156881,
              21.019497429772795
            ],
            [
              105.80333618844554,
              21.01941305673545
            ],
            [
              105.80334166491373,
              21.019405333550775
            ],
            [
              105.80343143753501,
              21.01927874286226
            ],
            [
              105.80343619791834,
              21.019272027448014
            ],
            [
              105.80344426113,
              21.019258563761404
            ],
            [
              105.80350543829283,
              21.01915640496796
            ],
            [
              105.80354291851557,
              21.019097724849527
            ],
            [
              105.80356955745542,
              21.019023081678462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M52_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1442",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019497429772795,
          "to_latitude": 21.019023081678462,
          "to_longitude": 105.80356955745542,
          "from_longitude": 105.80328959156881
        }
      },
      {
        "id": 1441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80304292771967,
            21.019521439966937,
            105.80327417382749,
            21.019877354672538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80304292771967,
              21.019877354672538
            ],
            [
              105.80308356337144,
              21.019827981081587
            ],
            [
              105.8031577836339,
              21.019704317257144
            ],
            [
              105.80317871398519,
              21.019669443193894
            ],
            [
              105.80322946757033,
              21.01958487804065
            ],
            [
              105.80326815334918,
              21.0195319449555
            ],
            [
              105.80327417382749,
              21.019521439966937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M41_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1443",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019877354672538,
          "to_latitude": 21.019521439966937,
          "to_longitude": 105.80327417382749,
          "from_longitude": 105.80304292771967
        }
      },
      {
        "id": 1442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80227455123772,
            21.01923098828025,
            105.80278804146248,
            21.01966486930907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80278804146248,
              21.01923098828025
            ],
            [
              105.80254048514759,
              21.01966486930907
            ],
            [
              105.80228884995906,
              21.01954769260531
            ],
            [
              105.80227455123772,
              21.01954103278995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1444",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01923098828025,
          "to_latitude": 21.01954103278995,
          "to_longitude": 105.80227455123772,
          "from_longitude": 105.80278804146248
        }
      },
      {
        "id": 1443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80274633296352,
            21.018642137604377,
            105.80301053924084,
            21.01918900590102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80274633296352,
              21.018642137604377
            ],
            [
              105.80276379718187,
              21.018651805553862
            ],
            [
              105.80301053924084,
              21.018783105916498
            ],
            [
              105.8028122131261,
              21.01918900590102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1445",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018642137604377,
          "to_latitude": 21.01918900590102,
          "to_longitude": 105.8028122131261,
          "from_longitude": 105.80274633296352
        }
      },
      {
        "id": 1444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80276945779248,
            21.018336548522505,
            105.80324313089655,
            21.018738681899517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80276945779248,
              21.01859383846646
            ],
            [
              105.80278089510792,
              21.018598614964123
            ],
            [
              105.8030230771957,
              21.018738681899517
            ],
            [
              105.80324313089655,
              21.018336548522505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1446",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01859383846646,
          "to_latitude": 21.018336548522505,
          "to_longitude": 105.80324313089655,
          "from_longitude": 105.80276945779248
        }
      },
      {
        "id": 1445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8031979906401,
            21.018400590003626,
            105.80343137835423,
            21.0188704917523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8031979906401,
              21.0188704917523
            ],
            [
              105.8031987061892,
              21.018868487720276
            ],
            [
              105.80320560583012,
              21.018849172094857
            ],
            [
              105.80322955263506,
              21.018782122488524
            ],
            [
              105.80343137835423,
              21.018400590003626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1447",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0188704917523,
          "to_latitude": 21.018400590003626,
          "to_longitude": 105.80343137835423,
          "from_longitude": 105.8031979906401
        }
      },
      {
        "id": 1446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80326819087792,
            21.018534541817832,
            105.80357629100075,
            21.01890156646793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80326819087792,
              21.01890156646793
            ],
            [
              105.80327628842694,
              21.01888499551715
            ],
            [
              105.80327852641804,
              21.018880412801174
            ],
            [
              105.80328113580458,
              21.018873689483875
            ],
            [
              105.80332583169779,
              21.018893122754765
            ],
            [
              105.80332735041789,
              21.018893620487972
            ],
            [
              105.80339611958885,
              21.018777503498892
            ],
            [
              105.80344691227263,
              21.018774470820233
            ],
            [
              105.80357629100075,
              21.018534541817832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1448",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01890156646793,
          "to_latitude": 21.018534541817832,
          "to_longitude": 105.80357629100075,
          "from_longitude": 105.80326819087792
        }
      },
      {
        "id": 1447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80300132976156,
            21.01887744686269,
            105.80318952221204,
            21.019247584606266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80300132976156,
              21.019247584606266
            ],
            [
              105.80318820523541,
              21.01887995231709
            ],
            [
              105.80318821761483,
              21.018879928774663
            ],
            [
              105.80318952221204,
              21.01887744686269
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1449",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019247584606266,
          "to_latitude": 21.01887744686269,
          "to_longitude": 105.80318952221204,
          "from_longitude": 105.80300132976156
        }
      },
      {
        "id": 1448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80302934250079,
            21.01875144566688,
            105.80353558325808,
            21.018984025748882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80302934250079,
              21.01875144566688
            ],
            [
              105.80314018119202,
              21.018816012731957
            ],
            [
              105.80319392089284,
              21.01884324969447
            ],
            [
              105.80319912191632,
              21.018845886051146
            ],
            [
              105.80320560583012,
              21.018849172094857
            ],
            [
              105.80327628842694,
              21.01888499551715
            ],
            [
              105.8033057390349,
              21.01889992140946
            ],
            [
              105.80331688960072,
              21.018905572646865
            ],
            [
              105.80332209158547,
              21.018908208091883
            ],
            [
              105.8033505313276,
              21.018922623005842
            ],
            [
              105.8034674996917,
              21.018968834374807
            ],
            [
              105.80353558325808,
              21.018984025748882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1450",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01875144566688,
          "to_latitude": 21.018984025748882,
          "to_longitude": 105.80353558325808,
          "from_longitude": 105.80302934250079
        }
      },
      {
        "id": 1449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80302435006827,
            21.018778390117717,
            105.80331974461525,
            21.019158986526943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80320609827471,
              21.019158986526943
            ],
            [
              105.80331974461525,
              21.018943807321097
            ],
            [
              105.80322264547188,
              21.018894327178764
            ],
            [
              105.8031920851458,
              21.018878753476532
            ],
            [
              105.80318952221204,
              21.01887744686269
            ],
            [
              105.80318688983007,
              21.018876105350735
            ],
            [
              105.80306591125658,
              21.018814455748313
            ],
            [
              105.80302435006827,
              21.01879405199268
            ],
            [
              105.80302833578345,
              21.01878458720261
            ],
            [
              105.80303102014483,
              21.018778390117717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1451",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019158986526943,
          "to_latitude": 21.018778390117717,
          "to_longitude": 105.80303102014483,
          "from_longitude": 105.80320609827471
        }
      },
      {
        "id": 1450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80325944266747,
            21.02022982746085,
            105.80362562533195,
            21.020422697695807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80325944266747,
              21.02022982746085
            ],
            [
              105.80331832880918,
              21.020262331179662
            ],
            [
              105.80345756860198,
              21.020331838203916
            ],
            [
              105.80349942102504,
              21.02035856848574
            ],
            [
              105.80361951612868,
              21.0204195200945
            ],
            [
              105.80362562533195,
              21.020422697695807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M39_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1452",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02022982746085,
          "to_latitude": 21.020422697695807,
          "to_longitude": 105.80362562533195,
          "from_longitude": 105.80325944266747
        }
      },
      {
        "id": 1451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80521711235183,
            21.019236650592468,
            105.80522512995333,
            21.019249441237502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80521711235183,
              21.019249441237502
            ],
            [
              105.80522358558777,
              21.019242127858778
            ],
            [
              105.80522512995333,
              21.019236650592468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1453",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019249441237502,
          "to_latitude": 21.019236650592468,
          "to_longitude": 105.80522512995333,
          "from_longitude": 105.80521711235183
        }
      },
      {
        "id": 1452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80466447492432,
            21.01895123402892,
            105.80467019827998,
            21.018961334818542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80466447492432,
              21.018961334818542
            ],
            [
              105.80466546692547,
              21.01895958237738
            ],
            [
              105.80466827715536,
              21.01895462480695
            ],
            [
              105.80467019827998,
              21.01895123402892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1454",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018961334818542,
          "to_latitude": 21.01895123402892,
          "to_longitude": 105.80467019827998,
          "from_longitude": 105.80466447492432
        }
      },
      {
        "id": 1453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80438017423785,
            21.01882393435743,
            105.80494923864228,
            21.019111425677025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80494923864228,
              21.019111425677025
            ],
            [
              105.80476553118494,
              21.01901261992532
            ],
            [
              105.80475222181234,
              21.019005874348323
            ],
            [
              105.8047112788629,
              21.018985105893403
            ],
            [
              105.80467540001288,
              21.018966905741223
            ],
            [
              105.80466447492432,
              21.018961334818542
            ],
            [
              105.8046472654507,
              21.018952559157697
            ],
            [
              105.80463093835559,
              21.018944369130036
            ],
            [
              105.80458227153032,
              21.018919957421527
            ],
            [
              105.80457360499481,
              21.01891633220486
            ],
            [
              105.80455998960139,
              21.018910637612123
            ],
            [
              105.80455711554274,
              21.018909256619484
            ],
            [
              105.80444726920969,
              21.018856575012006
            ],
            [
              105.80438017423785,
              21.01882393435743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1455",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019111425677025,
          "to_latitude": 21.01882393435743,
          "to_longitude": 105.80438017423785,
          "from_longitude": 105.80494923864228
        }
      },
      {
        "id": 1454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80443905163712,
            21.018973676889267,
            105.80471519140768,
            21.019255326387093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80471519140768,
              21.018973676889267
            ],
            [
              105.8047112788629,
              21.018985105893403
            ],
            [
              105.80471040655009,
              21.018987650803915
            ],
            [
              105.80463470387349,
              21.019086441929804
            ],
            [
              105.80457383190331,
              21.01919100677087
            ],
            [
              105.80454784474362,
              21.019231949324503
            ],
            [
              105.80447766988021,
              21.01919492094235
            ],
            [
              105.80443905163712,
              21.019255326387093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_121_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1456",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018973676889267,
          "to_latitude": 21.019255326387093,
          "to_longitude": 105.80443905163712,
          "from_longitude": 105.80471519140768
        }
      },
      {
        "id": 1455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80442436985597,
            21.01890730116814,
            105.80458212378706,
            21.01915574433558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80458212378706,
              21.01890730116814
            ],
            [
              105.80457946178181,
              21.018910122802083
            ],
            [
              105.80457360499481,
              21.01891633220486
            ],
            [
              105.80457032890371,
              21.01891980526179
            ],
            [
              105.80449001945814,
              21.019053948832003
            ],
            [
              105.80442436985597,
              21.01915574433558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1457",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01890730116814,
          "to_latitude": 21.01915574433558,
          "to_longitude": 105.80442436985597,
          "from_longitude": 105.80458212378706
        }
      },
      {
        "id": 1456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80404811287332,
            21.018644086025372,
            105.80405901786574,
            21.01866306835506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80404811287332,
              21.01866306835506
            ],
            [
              105.80405304212576,
              21.01865448605423
            ],
            [
              105.804058000941,
              21.018645855742342
            ],
            [
              105.80405901786574,
              21.018644086025372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1458",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01866306835506,
          "to_latitude": 21.018644086025372,
          "to_longitude": 105.80405901786574,
          "from_longitude": 105.80404811287332
        }
      },
      {
        "id": 1457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80388369346332,
            21.018583750653793,
            105.80436161978271,
            21.018810460104895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80436161978271,
              21.018810460104895
            ],
            [
              105.80435038025844,
              21.018805625876805
            ],
            [
              105.80422828370212,
              21.01874670653844
            ],
            [
              105.8041118690885,
              21.018693083884884
            ],
            [
              105.80407788948897,
              21.018677432569984
            ],
            [
              105.80404811287332,
              21.01866306835506
            ],
            [
              105.80388369346332,
              21.018583750653793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1459",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018810460104895,
          "to_latitude": 21.018583750653793,
          "to_longitude": 105.80388369346332,
          "from_longitude": 105.80436161978271
        }
      },
      {
        "id": 1458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80431547215106,
            21.01880783403308,
            105.80438252164733,
            21.018920700675633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80438252164733,
              21.01880783403308
            ],
            [
              105.80437902950369,
              21.018813711601364
            ],
            [
              105.80431547215106,
              21.018920700675633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1460",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01880783403308,
          "to_latitude": 21.018920700675633,
          "to_longitude": 105.80431547215106,
          "from_longitude": 105.80438252164733
        }
      },
      {
        "id": 1459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80605677362328,
            21.019362270254447,
            105.80629332796434,
            21.01971522539971
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80614281692542,
              21.01971522539971
            ],
            [
              105.80614253394702,
              21.019711437695292
            ],
            [
              105.8061525643998,
              21.019697661078972
            ],
            [
              105.80623735332077,
              21.019544875468743
            ],
            [
              105.80624135643734,
              21.019541902936503
            ],
            [
              105.80629332796434,
              21.019503313626647
            ],
            [
              105.80623331505336,
              21.019399594874205
            ],
            [
              105.80620216410873,
              21.019362270254447
            ],
            [
              105.80605677362328,
              21.01943637371534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_164_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1461",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01971522539971,
          "to_latitude": 21.01943637371534,
          "to_longitude": 105.80605677362328,
          "from_longitude": 105.80614281692542
        }
      },
      {
        "id": 1460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80440884241996,
            21.01796786611417,
            105.80451653548874,
            21.018042296878775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80440884241996,
              21.01796786611417
            ],
            [
              105.80451653548874,
              21.018042296878775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1462",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01796786611417,
          "to_latitude": 21.018042296878775,
          "to_longitude": 105.80451653548874,
          "from_longitude": 105.80440884241996
        }
      },
      {
        "id": 1461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80518995236402,
            21.017965923445004,
            105.80525111093438,
            21.018041850258108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80518995236402,
              21.018041850258108
            ],
            [
              105.80525111093438,
              21.017965923445004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M76_Ngõ 157/23, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1463",
          "diaChiLapD": "Ngõ 157/23, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018041850258108,
          "to_latitude": 21.017965923445004,
          "to_longitude": 105.80525111093438,
          "from_longitude": 105.80518995236402
        }
      },
      {
        "id": 1462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80518995236402,
            21.018041850258108,
            105.80561252826558,
            21.018212307258807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80561252826558,
              21.018203304258048
            ],
            [
              105.80551972166674,
              21.018212307258807
            ],
            [
              105.80534059231094,
              21.01814115265459
            ],
            [
              105.80532679298922,
              21.018131830720417
            ],
            [
              105.80522732455833,
              21.01806463254733
            ],
            [
              105.80522053880794,
              21.018060492651294
            ],
            [
              105.80518995236402,
              21.018041850258108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M76_Ngõ 157/23, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1464",
          "diaChiLapD": "Ngõ 157/23, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 121,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018203304258048,
          "to_latitude": 21.018041850258108,
          "to_longitude": 105.80518995236402,
          "from_longitude": 105.80561252826558
        }
      },
      {
        "id": 1463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8059952141306,
            21.018549407247782,
            105.80599994113086,
            21.018551899440673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8059952141306,
              21.018549407247782
            ],
            [
              105.8059956221945,
              21.018549622089342
            ],
            [
              105.8059981005048,
              21.018550930867843
            ],
            [
              105.80599994113086,
              21.018551899440673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_133_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1465",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018549407247782,
          "to_latitude": 21.018551899440673,
          "to_longitude": 105.80599994113086,
          "from_longitude": 105.8059952141306
        }
      },
      {
        "id": 1464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80585929038442,
            21.018409987182284,
            105.80608906972931,
            21.018713987295232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80585929038442,
              21.018713987295232
            ],
            [
              105.80588001120813,
              21.018685018317907
            ],
            [
              105.80590881014055,
              21.018653104476353
            ],
            [
              105.80597674461049,
              21.018578964412775
            ],
            [
              105.80598787973655,
              21.01856114513805
            ],
            [
              105.8059952141306,
              21.018549407247782
            ],
            [
              105.80603043732593,
              21.01849303601998
            ],
            [
              105.80608906972931,
              21.018409987182284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_133_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1468",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018713987295232,
          "to_latitude": 21.018409987182284,
          "to_longitude": 105.80608906972931,
          "from_longitude": 105.80585929038442
        }
      },
      {
        "id": 1465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80526669331007,
            21.018662917804278,
            105.80532048886838,
            21.018743396385943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80526669331007,
              21.018743396385943
            ],
            [
              105.80532048886838,
              21.018662917804278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_129_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1469",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018743396385943,
          "to_latitude": 21.018662917804278,
          "to_longitude": 105.80532048886838,
          "from_longitude": 105.80526669331007
        }
      },
      {
        "id": 1466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80504570919832,
            21.018588329102556,
            105.80541653807161,
            21.018898120431405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80504570919832,
              21.018588329102556
            ],
            [
              105.80521160156076,
              21.018701973143475
            ],
            [
              105.80526434194334,
              21.018740647248446
            ],
            [
              105.80526669331007,
              21.018743396385943
            ],
            [
              105.8052928661332,
              21.01877398679525
            ],
            [
              105.80534277122311,
              21.01882332788477
            ],
            [
              105.80540121494802,
              21.018867336511605
            ],
            [
              105.80541296941347,
              21.018882428011157
            ],
            [
              105.80541653807161,
              21.018889551141108
            ],
            [
              105.80541512768141,
              21.018898120431405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_129_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1470",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018588329102556,
          "to_latitude": 21.018898120431405,
          "to_longitude": 105.80541512768141,
          "from_longitude": 105.80504570919832
        }
      },
      {
        "id": 1467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80545140922646,
            21.018897072837188,
            105.80573807032036,
            21.018957475568993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80545140922646,
              21.018919919468743
            ],
            [
              105.80551444114433,
              21.01895090325159
            ],
            [
              105.80552516997531,
              21.018957475568993
            ],
            [
              105.80559323103046,
              21.018909278572142
            ],
            [
              105.80562474698488,
              21.018897072837188
            ],
            [
              105.80565425128802,
              21.018904584058813
            ],
            [
              105.80569817245551,
              21.018922110241697
            ],
            [
              105.8057306942446,
              21.018927743657013
            ],
            [
              105.80573807032036,
              21.018931812234875
            ],
            [
              105.80573002368817,
              21.018944643902486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1471",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 35.249,
          "from_latitude": 21.018919919468743,
          "to_latitude": 21.018944643902486,
          "to_longitude": 105.80573002368817,
          "from_longitude": 105.80545140922646
        }
      },
      {
        "id": 1468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80522186527675,
            21.018885609034907,
            105.80540136406307,
            21.019016784019243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80522222943158,
              21.019016784019243
            ],
            [
              105.80522186527675,
              21.019009518361
            ],
            [
              105.8052222077142,
              21.019004318656066
            ],
            [
              105.8052973261108,
              21.01900138253236
            ],
            [
              105.80531472142246,
              21.018998718752155
            ],
            [
              105.80532700523123,
              21.018985375903636
            ],
            [
              105.8053616953973,
              21.018943608933643
            ],
            [
              105.80540136406307,
              21.018885609034907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_128_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1472",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019016784019243,
          "to_latitude": 21.018885609034907,
          "to_longitude": 105.80540136406307,
          "from_longitude": 105.80522222943158
        }
      },
      {
        "id": 1469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80477199579119,
            21.018708181334876,
            105.80498252640459,
            21.019005667895794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80477199579119,
              21.019005667895794
            ],
            [
              105.80477380006927,
              21.019002910833166
            ],
            [
              105.8047795258553,
              21.01899416035632
            ],
            [
              105.8047825951399,
              21.018989973819536
            ],
            [
              105.80478306899714,
              21.018989183961807
            ],
            [
              105.8047861790648,
              21.01898399284106
            ],
            [
              105.80480573889788,
              21.01895410005858
            ],
            [
              105.80485080200069,
              21.018890993192596
            ],
            [
              105.80491876109481,
              21.01880967184399
            ],
            [
              105.80498252640459,
              21.018708181334876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_123_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1473",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019005667895794,
          "to_latitude": 21.018708181334876,
          "to_longitude": 105.80498252640459,
          "from_longitude": 105.80477199579119
        }
      },
      {
        "id": 1470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80489986504844,
            21.018899084949684,
            105.80505242572043,
            21.019075792977578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80489986504844,
              21.019075792977578
            ],
            [
              105.80491216237341,
              21.01905456490281
            ],
            [
              105.8049132275335,
              21.019053180631637
            ],
            [
              105.80497470498594,
              21.018973298968344
            ],
            [
              105.80498582682303,
              21.01895763582701
            ],
            [
              105.80502955402739,
              21.01893616868543
            ],
            [
              105.80505242572043,
              21.018899084949684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_125_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1474",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019075792977578,
          "to_latitude": 21.018899084949684,
          "to_longitude": 105.80505242572043,
          "from_longitude": 105.80489986504844
        }
      },
      {
        "id": 1471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8044875908998,
            21.018506067690904,
            105.80457382397776,
            21.018845583492478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80452379778201,
              21.018845583492478
            ],
            [
              105.80452688530634,
              21.018838547887107
            ],
            [
              105.8045302380795,
              21.018830097758187
            ],
            [
              105.80457382397776,
              21.01873119992431
            ],
            [
              105.8044875908998,
              21.018683356294716
            ],
            [
              105.80455635219559,
              21.018506067690904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117_Ngõ 131, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1475.1",
          "diaChiLapD": "Ngõ 131, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": 44.993,
          "from_latitude": 21.018845583492478,
          "to_latitude": 21.018506067690904,
          "to_longitude": 105.80455635219559,
          "from_longitude": 105.80452379778201
        }
      },
      {
        "id": 1472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80405812948591,
            21.01863294696159,
            105.80406427009551,
            21.01864363590009
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80406427009551,
              21.01863294696159
            ],
            [
              105.80406381643614,
              21.01863373672196
            ],
            [
              105.80406079901046,
              21.018638986102196
            ],
            [
              105.80405812948591,
              21.01864363590009
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1476",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01863294696159,
          "to_latitude": 21.01864363590009,
          "to_longitude": 105.80405812948591,
          "from_longitude": 105.80406427009551
        }
      },
      {
        "id": 1473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80448898464286,
            21.018836983048892,
            105.80450609819185,
            21.018864988218517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80450609819185,
              21.018836983048892
            ],
            [
              105.80449286692648,
              21.018858136928266
            ],
            [
              105.80448992137146,
              21.018863332685267
            ],
            [
              105.80448898464286,
              21.018864988218517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1477",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": 3.576,
          "from_latitude": 21.018836983048892,
          "to_latitude": 21.018864988218517,
          "to_longitude": 105.80448898464286,
          "from_longitude": 105.80450609819185
        }
      },
      {
        "id": 1474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80395400421784,
            21.01857640389428,
            105.80427101602952,
            21.01873595298102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80427101602952,
              21.01873595298102
            ],
            [
              105.80423168017906,
              21.018715992423957
            ],
            [
              105.80414682662911,
              21.018674936241986
            ],
            [
              105.80406427009551,
              21.01863294696159
            ],
            [
              105.80398452056606,
              21.018592385023968
            ],
            [
              105.80395400421784,
              21.01857640389428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1478",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01873595298102,
          "to_latitude": 21.01857640389428,
          "to_longitude": 105.80395400421784,
          "from_longitude": 105.80427101602952
        }
      },
      {
        "id": 1475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80428260298119,
            21.018636443598687,
            105.80433925075792,
            21.018757141249356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80428260298119,
              21.018757141249356
            ],
            [
              105.80429407249179,
              21.018740825279124
            ],
            [
              105.80433925075792,
              21.01865324499879
            ],
            [
              105.80429922578388,
              21.018636443598687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1479",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018757141249356,
          "to_latitude": 21.018636443598687,
          "to_longitude": 105.80429922578388,
          "from_longitude": 105.80428260298119
        }
      },
      {
        "id": 1476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80566068819877,
            21.019462568598918,
            105.80566966908066,
            21.019476900218866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80566966908066,
              21.019462568598918
            ],
            [
              105.80566837191265,
              21.01946463591144
            ],
            [
              105.80566499550949,
              21.01947002611606
            ],
            [
              105.80566068819877,
              21.019476900218866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1480",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019462568598918,
          "to_latitude": 21.019476900218866,
          "to_longitude": 105.80566068819877,
          "from_longitude": 105.80566966908066
        }
      },
      {
        "id": 1477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8051201193745,
            21.01916093316732,
            105.80542980338981,
            21.01933372713355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80542980338981,
              21.01933372713355
            ],
            [
              105.80527298963597,
              21.019249011435136
            ],
            [
              105.80526230488098,
              21.01924323980085
            ],
            [
              105.80525802333096,
              21.019240760834123
            ],
            [
              105.8051201193745,
              21.01916093316732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_137_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1481",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01933372713355,
          "to_latitude": 21.01916093316732,
          "to_longitude": 105.8051201193745,
          "from_longitude": 105.80542980338981
        }
      },
      {
        "id": 1478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80581001177286,
            21.019545261397653,
            105.80581489224727,
            21.01955413525863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80581489224727,
              21.019545261397653
            ],
            [
              105.80581443115636,
              21.019546099068787
            ],
            [
              105.80581210760938,
              21.019550321832835
            ],
            [
              105.80581001177286,
              21.01955413525863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1482",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019545261397653,
          "to_latitude": 21.01955413525863,
          "to_longitude": 105.80581001177286,
          "from_longitude": 105.80581489224727
        }
      },
      {
        "id": 1479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80544484600387,
            21.01934185361515,
            105.80574095634721,
            21.019510153567836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80574095634721,
              21.019510153567836
            ],
            [
              105.80573602547747,
              21.0195066046532
            ],
            [
              105.80566966908066,
              21.019462568598918
            ],
            [
              105.8056462492422,
              21.019447026561554
            ],
            [
              105.80548388425848,
              21.019362943423566
            ],
            [
              105.80544484600387,
              21.01934185361515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1483",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019510153567836,
          "to_latitude": 21.01934185361515,
          "to_longitude": 105.80544484600387,
          "from_longitude": 105.80574095634721
        }
      },
      {
        "id": 1480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8062089019715,
            21.019728847230617,
            105.80621899913785,
            21.019747212569918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80621899913785,
              21.019728847230617
            ],
            [
              105.80621706429987,
              21.019732366348656
            ],
            [
              105.80621177706608,
              21.01974198098962
            ],
            [
              105.8062089019715,
              21.019747212569918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_165_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1484",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019728847230617,
          "to_latitude": 21.019747212569918,
          "to_longitude": 105.8062089019715,
          "from_longitude": 105.80621899913785
        }
      },
      {
        "id": 1481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80672396115934,
            21.01997579428005,
            105.80674504171812,
            21.020015499939568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80674504171812,
              21.01997579428005
            ],
            [
              105.80673025028176,
              21.020003654072916
            ],
            [
              105.80672457634691,
              21.02001433908259
            ],
            [
              105.80672396115934,
              21.020015499939568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_166_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1485",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01997579428005,
          "to_latitude": 21.020015499939568,
          "to_longitude": 105.80672396115934,
          "from_longitude": 105.80674504171812
        }
      },
      {
        "id": 1482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80601099127468,
            21.01963120037358,
            105.80657636928669,
            21.019896612347413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80657636928669,
              21.019896612347413
            ],
            [
              105.8063419430756,
              21.019786562487916
            ],
            [
              105.80629389557288,
              21.01976400629289
            ],
            [
              105.80621899913785,
              21.019728847230617
            ],
            [
              105.8061525643998,
              21.019697661078972
            ],
            [
              105.80601099127468,
              21.01963120037358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_165_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1486",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019896612347413,
          "to_latitude": 21.01963120037358,
          "to_longitude": 105.80601099127468,
          "from_longitude": 105.80657636928669
        }
      },
      {
        "id": 1483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80680264501427,
            21.019339352602568,
            105.80705858752984,
            21.01983035163016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8069614063745,
              21.01983035163016
            ],
            [
              105.80705858752984,
              21.019762287513387
            ],
            [
              105.80680264501427,
              21.019339352602568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1487",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01983035163016,
          "to_latitude": 21.019339352602568,
          "to_longitude": 105.80680264501427,
          "from_longitude": 105.8069614063745
        }
      },
      {
        "id": 1484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80643146817256,
            21.019228696800862,
            105.80712510199281,
            21.020166739002438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80643146817256,
              21.019228696800862
            ],
            [
              105.80664135693401,
              21.01958165717664
            ],
            [
              105.80695578101951,
              21.019821635965016
            ],
            [
              105.8069614063745,
              21.01983035163016
            ],
            [
              105.80698146617418,
              21.01986143231097
            ],
            [
              105.80699315453076,
              21.019880462084274
            ],
            [
              105.80700340853328,
              21.019897156589312
            ],
            [
              105.80702933373524,
              21.019939367925154
            ],
            [
              105.80703958774593,
              21.019956062427635
            ],
            [
              105.80707860174189,
              21.02001958385434
            ],
            [
              105.80708900230087,
              21.02003651611045
            ],
            [
              105.80711470143525,
              21.020078359986584
            ],
            [
              105.80712510199281,
              21.020095290433687
            ],
            [
              105.80708638676481,
              21.020140195698698
            ],
            [
              105.80707455885796,
              21.020166739002438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_168_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1488",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 132,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019228696800862,
          "to_latitude": 21.020166739002438,
          "to_longitude": 105.80707455885796,
          "from_longitude": 105.80643146817256
        }
      },
      {
        "id": 1485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8064015596087,
            21.019242629087497,
            105.80707630579946,
            21.02016052880991
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80706076636896,
              21.02016052880991
            ],
            [
              105.8070710167433,
              21.0201334673128
            ],
            [
              105.80707630579946,
              21.020059852557427
            ],
            [
              105.80694624620016,
              21.019847061023917
            ],
            [
              105.80664395222401,
              21.019620626421915
            ],
            [
              105.8064015596087,
              21.019242629087497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_167_Ngõ 39, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1489",
          "diaChiLapD": "Ngõ 39, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 132,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02016052880991,
          "to_latitude": 21.019242629087497,
          "to_longitude": 105.8064015596087,
          "from_longitude": 105.80706076636896
        }
      },
      {
        "id": 1486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8065861623246,
            21.01994912600794,
            105.80659428848034,
            21.019964432721437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8065861623246,
              21.019964432721437
            ],
            [
              105.80659303521125,
              21.019951483958707
            ],
            [
              105.80659428848034,
              21.01994912600794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_190_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1491",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019964432721437,
          "to_latitude": 21.01994912600794,
          "to_longitude": 105.80659428848034,
          "from_longitude": 105.8065861623246
        }
      },
      {
        "id": 1487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8071556055844,
            21.020210632244133,
            105.80717203303729,
            21.02024157442568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8071556055844,
              21.02024157442568
            ],
            [
              105.80715863680852,
              21.020235865184695
            ],
            [
              105.80716817804871,
              21.020217890818714
            ],
            [
              105.80717203303729,
              21.020210632244133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_172_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1492",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02024157442568,
          "to_latitude": 21.020210632244133,
          "to_longitude": 105.80717203303729,
          "from_longitude": 105.8071556055844
        }
      },
      {
        "id": 1488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80636990281576,
            21.019857788643794,
            105.80683182692268,
            21.02008399592258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80683182692268,
              21.02008399592258
            ],
            [
              105.80661185645481,
              21.019976938022253
            ],
            [
              105.8065861623246,
              21.019964432721437
            ],
            [
              105.80657023852471,
              21.01995668265341
            ],
            [
              105.80636990281576,
              21.019857788643794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_190_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1493",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02008399592258,
          "to_latitude": 21.019857788643794,
          "to_longitude": 105.80636990281576,
          "from_longitude": 105.80683182692268
        }
      },
      {
        "id": 1489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80673202016648,
            21.02155599046867,
            105.80706695699449,
            21.021595586348525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80706695699449,
              21.02155599046867
            ],
            [
              105.8067710255908,
              21.021561440848288
            ],
            [
              105.80673202016648,
              21.021595586348525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_158_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1494",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02155599046867,
          "to_latitude": 21.021595586348525,
          "to_longitude": 105.80673202016648,
          "from_longitude": 105.80706695699449
        }
      },
      {
        "id": 1490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80668752686083,
            21.021775676410694,
            105.8066876864907,
            21.021789992438713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8066876864907,
              21.021775676410694
            ],
            [
              105.80668765267397,
              21.021778688838623
            ],
            [
              105.80668761343563,
              21.021782124003305
            ],
            [
              105.8066875761212,
              21.021785559158843
            ],
            [
              105.80668752686083,
              21.021789992438713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1495",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021775676410694,
          "to_latitude": 21.021789992438713,
          "to_longitude": 105.80668752686083,
          "from_longitude": 105.8066876864907
        }
      },
      {
        "id": 1491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8064035357004,
            21.021774212132097,
            105.80704472172323,
            21.021775725263083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80704472172323,
              21.021774212132097
            ],
            [
              105.80671509359436,
              21.021775725263083
            ],
            [
              105.80671190652063,
              21.02177571960476
            ],
            [
              105.8066876864907,
              21.021775676410694
            ],
            [
              105.80667229464679,
              21.02177564915107
            ],
            [
              105.8064035357004,
              21.021775167690603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1496",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021774212132097,
          "to_latitude": 21.021775167690603,
          "to_longitude": 105.8064035357004,
          "from_longitude": 105.80704472172323
        }
      },
      {
        "id": 1492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80703451452102,
            21.0217875713132,
            105.8070347322646,
            21.021807527375678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80703451452102,
              21.021807527375678
            ],
            [
              105.80703457917201,
              21.021801487179324
            ],
            [
              105.80703470063584,
              21.021790452762477
            ],
            [
              105.80703471866497,
              21.021788797962156
            ],
            [
              105.8070347322646,
              21.0217875713132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_157_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1497",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021807527375678,
          "to_latitude": 21.0217875713132,
          "to_longitude": 105.8070347322646,
          "from_longitude": 105.80703451452102
        }
      },
      {
        "id": 1493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80672879633327,
            21.021803696757015,
            105.807371581725,
            21.021811001802313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.807371581725,
              21.021803696757015
            ],
            [
              105.80705018808368,
              21.02180734913604
            ],
            [
              105.80703451452102,
              21.021807527375678
            ],
            [
              105.80672879633327,
              21.021811001802313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_157_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1498",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021803696757015,
          "to_latitude": 21.021811001802313,
          "to_longitude": 105.80672879633327,
          "from_longitude": 105.807371581725
        }
      },
      {
        "id": 1494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80613567983619,
            21.021755143784134,
            105.80613571499403,
            21.021762578093345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80613571499403,
              21.021762578093345
            ],
            [
              105.80613571150269,
              21.021761928688427
            ],
            [
              105.80613571136193,
              21.02176190249545
            ],
            [
              105.80613569229392,
              21.021758176767236
            ],
            [
              105.80613567983619,
              21.021755143784134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_154_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1499",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021762578093345,
          "to_latitude": 21.021755143784134,
          "to_longitude": 105.80613567983619,
          "from_longitude": 105.80613571499403
        }
      },
      {
        "id": 1495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80657409476576,
            21.021787222634845,
            105.8065743108265,
            21.02180732411026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80657409476576,
              21.02180732411026
            ],
            [
              105.80657413206336,
              21.021803885341836
            ],
            [
              105.80657416872936,
              21.02180032915669
            ],
            [
              105.80657428914853,
              21.021789273066727
            ],
            [
              105.8065743108265,
              21.021787222634845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_155_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1500",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02180732411026,
          "to_latitude": 21.021787222634845,
          "to_longitude": 105.8065743108265,
          "from_longitude": 105.80657409476576
        }
      },
      {
        "id": 1496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80629776323993,
            21.021800439276237,
            105.80667490328801,
            21.021809935080906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80629776323993,
              21.021800439276237
            ],
            [
              105.80638975539144,
              21.021802549369056
            ],
            [
              105.80648863294341,
              21.0218051099919
            ],
            [
              105.80657409476576,
              21.02180732411026
            ],
            [
              105.80667490328801,
              21.021809935080906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_155_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1501",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021800439276237,
          "to_latitude": 21.021809935080906,
          "to_longitude": 105.80667490328801,
          "from_longitude": 105.80629776323993
        }
      },
      {
        "id": 1497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80592723651742,
            21.021762465522343,
            105.80629320084336,
            21.02176667924665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80592723651742,
              21.021763500111174
            ],
            [
              105.80610366540876,
              21.021762715537296
            ],
            [
              105.80613571499403,
              21.021762578093345
            ],
            [
              105.80616194376516,
              21.021762465522343
            ],
            [
              105.80629320084336,
              21.02176667924665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_154_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1502",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021763500111174,
          "to_latitude": 21.02176667924665,
          "to_longitude": 105.80629320084336,
          "from_longitude": 105.80592723651742
        }
      },
      {
        "id": 1498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80590592316015,
            21.021455068387056,
            105.80593299911105,
            21.021456948597372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80590592316015,
              21.021456948597372
            ],
            [
              105.80591157622625,
              21.021455068387056
            ],
            [
              105.80593299911105,
              21.02145517462425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_153_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1503",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021456948597372,
          "to_latitude": 21.02145517462425,
          "to_longitude": 105.80593299911105,
          "from_longitude": 105.80590592316015
        }
      },
      {
        "id": 1499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80589737891036,
            21.021164305002625,
            105.80590902880134,
            21.02174063884454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80589737891036,
              21.021164305002625
            ],
            [
              105.80590592316015,
              21.021456948597372
            ],
            [
              105.80590902880134,
              21.021563336946233
            ],
            [
              105.8059065734262,
              21.02174063884454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_153_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1504",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021164305002625,
          "to_latitude": 21.02174063884454,
          "to_longitude": 105.8059065734262,
          "from_longitude": 105.80589737891036
        }
      },
      {
        "id": 1500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80590774429486,
            21.020721311549995,
            105.80591496263236,
            21.021081205023535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80591496263236,
              21.021081205023535
            ],
            [
              105.80591191256553,
              21.020929129282763
            ],
            [
              105.80591143412902,
              21.020905261016956
            ],
            [
              105.80590774429486,
              21.020721311549995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1505",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021081205023535,
          "to_latitude": 21.020721311549995,
          "to_longitude": 105.80590774429486,
          "from_longitude": 105.80591496263236
        }
      },
      {
        "id": 1501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8057424528496,
            21.020951404393777,
            105.80577509308205,
            21.02095151114346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80577509308205,
              21.020951404393777
            ],
            [
              105.8057736751559,
              21.02095140930433
            ],
            [
              105.80575748442463,
              21.020951462518518
            ],
            [
              105.8057424528496,
              21.02095151114346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1506",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020951404393777,
          "to_latitude": 21.02095151114346,
          "to_longitude": 105.8057424528496,
          "from_longitude": 105.80577509308205
        }
      },
      {
        "id": 1502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80577267146889,
            21.020703396433998,
            105.80577642147534,
            21.021087622177998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80577642147534,
              21.021087622177998
            ],
            [
              105.80577509308205,
              21.020951404393777
            ],
            [
              105.80577454742959,
              21.02089550975526
            ],
            [
              105.80577267146889,
              21.020703396433998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1507",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021087622177998,
          "to_latitude": 21.020703396433998,
          "to_longitude": 105.80577267146889,
          "from_longitude": 105.80577642147534
        }
      },
      {
        "id": 1503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80485359192264,
            21.02031347382822,
            105.80500211605485,
            21.02052947591608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80492912333604,
              21.02052947591608
            ],
            [
              105.80500211605485,
              21.020381476920644
            ],
            [
              105.80485359192264,
              21.02031347382822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_150_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1508",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02052947591608,
          "to_latitude": 21.02031347382822,
          "to_longitude": 105.80485359192264,
          "from_longitude": 105.80492912333604
        }
      },
      {
        "id": 1504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80476844307974,
            21.020503500624724,
            105.80574109489565,
            21.02078373930414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80574109489565,
              21.020686790214576
            ],
            [
              105.80572510810846,
              21.020687376267116
            ],
            [
              105.80567811219595,
              21.020694071413033
            ],
            [
              105.80557391624444,
              21.020686426823023
            ],
            [
              105.80547490198559,
              21.02078077643984
            ],
            [
              105.80547179244391,
              21.02078373930414
            ],
            [
              105.80511452373385,
              21.020614446776573
            ],
            [
              105.80494150051022,
              21.02052937579624
            ],
            [
              105.80492912333604,
              21.02052947591608
            ],
            [
              105.8049128926621,
              21.020529606915336
            ],
            [
              105.80486752810846,
              21.020518350568256
            ],
            [
              105.80484670980387,
              21.020539096892104
            ],
            [
              105.80476844307974,
              21.020503500624724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_150_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1509",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 121,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020686790214576,
          "to_latitude": 21.020503500624724,
          "to_longitude": 105.80476844307974,
          "from_longitude": 105.80574109489565
        }
      },
      {
        "id": 1505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80501950284025,
            21.020382928318547,
            105.80525407451259,
            21.020641502202167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80501950284025,
              21.020382928318547
            ],
            [
              105.80525407451259,
              21.020508774071914
            ],
            [
              105.80518758470497,
              21.020641502202167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_148_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1510",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020382928318547,
          "to_latitude": 21.020641502202167,
          "to_longitude": 105.80518758470497,
          "from_longitude": 105.80501950284025
        }
      },
      {
        "id": 1506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80495128863886,
            21.020524367135646,
            105.805741047057,
            21.02076487896149
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.805741047057,
              21.020677349892452
            ],
            [
              105.8057242092774,
              21.02067764371692
            ],
            [
              105.80569264437635,
              21.020665535512965
            ],
            [
              105.80556215344063,
              21.02067035086198
            ],
            [
              105.80547904447259,
              21.02076487896149
            ],
            [
              105.80518758470497,
              21.020641502202167
            ],
            [
              105.80511435923619,
              21.02061050496045
            ],
            [
              105.80495128863886,
              21.020524367135646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_148_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1511",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020677349892452,
          "to_latitude": 21.020524367135646,
          "to_longitude": 105.80495128863886,
          "from_longitude": 105.805741047057
        }
      },
      {
        "id": 1507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80529611767373,
            21.020056706696764,
            105.8054226006566,
            21.020275923104396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80529611767373,
              21.020275923104396
            ],
            [
              105.80537301222671,
              21.02013698474482
            ],
            [
              105.8054226006566,
              21.020056706696764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_145_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1512",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020275923104396,
          "to_latitude": 21.020056706696764,
          "to_longitude": 105.8054226006566,
          "from_longitude": 105.80529611767373
        }
      },
      {
        "id": 1508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8049909341255,
            21.020126120135725,
            105.80573998108468,
            21.02046961498268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8049909341255,
              21.020126120135725
            ],
            [
              105.80529611767373,
              21.020275923104396
            ],
            [
              105.80530298308064,
              21.020279292162066
            ],
            [
              105.80556775974546,
              21.02040709990542
            ],
            [
              105.80569993004342,
              21.020469423547617
            ],
            [
              105.80572930587547,
              21.02046961498268
            ],
            [
              105.80573998108468,
              21.02046929344667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_145_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1513",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020126120135725,
          "to_latitude": 21.02046929344667,
          "to_longitude": 105.80573998108468,
          "from_longitude": 105.8049909341255
        }
      },
      {
        "id": 1509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80572156554237,
            21.020570715740547,
            105.80574050918452,
            21.020572412593413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80572156554237,
              21.020570715740547
            ],
            [
              105.80572479067389,
              21.020571005754977
            ],
            [
              105.80574050918452,
              21.020572412593413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_147_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1514",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020570715740547,
          "to_latitude": 21.020572412593413,
          "to_longitude": 105.80574050918452,
          "from_longitude": 105.80572156554237
        }
      },
      {
        "id": 1510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80572091255732,
            21.020499718101224,
            105.80572218644704,
            21.02064523160795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80572091255732,
              21.02064523160795
            ],
            [
              105.80572154999012,
              21.020572476207423
            ],
            [
              105.80572156554237,
              21.020570715740547
            ],
            [
              105.80572218644704,
              21.020499718101224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_147_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1515",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02064523160795,
          "to_latitude": 21.020499718101224,
          "to_longitude": 105.80572218644704,
          "from_longitude": 105.80572091255732
        }
      },
      {
        "id": 1511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80573984852563,
            21.020443447362393,
            105.8057709100808,
            21.020443548436635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8057709100808,
              21.020443447362393
            ],
            [
              105.80575985336563,
              21.020443484383932
            ],
            [
              105.80574453325225,
              21.020443533471227
            ],
            [
              105.80573984852563,
              21.020443548436635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_144_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1516",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020443447362393,
          "to_latitude": 21.020443548436635,
          "to_longitude": 105.80573984852563,
          "from_longitude": 105.8057709100808
        }
      },
      {
        "id": 1512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80576849534329,
            21.02010683710873,
            105.80577510554741,
            21.02065797687587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80576849534329,
              21.02010683710873
            ],
            [
              105.80577022820675,
              21.020392634028667
            ],
            [
              105.80577036761174,
              21.020415713577634
            ],
            [
              105.8057709100808,
              21.020443447362393
            ],
            [
              105.80577510554741,
              21.02065797687587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_144_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1517",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02010683710873,
          "to_latitude": 21.02065797687587,
          "to_longitude": 105.80577510554741,
          "from_longitude": 105.80576849534329
        }
      },
      {
        "id": 1513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80571738121357,
            21.020380015049717,
            105.80573952254586,
            21.020380086510208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80571738121357,
              21.020380086510208
            ],
            [
              105.80571831816042,
              21.020380083878557
            ],
            [
              105.80573048978802,
              21.02038004519159
            ],
            [
              105.80573952254586,
              21.020380015049717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_143_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1518",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020380086510208,
          "to_latitude": 21.020380015049717,
          "to_longitude": 105.80573952254586,
          "from_longitude": 105.80571738121357
        }
      },
      {
        "id": 1514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80571291719444,
            21.020047805604534,
            105.80572884896742,
            21.020454147536576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80571912590204,
              21.020454147536576
            ],
            [
              105.80571738121357,
              21.020380086510208
            ],
            [
              105.80571456745834,
              21.02026066858168
            ],
            [
              105.80571291719444,
              21.020190573184685
            ],
            [
              105.80572010787954,
              21.02008761527824
            ],
            [
              105.80572884896742,
              21.020047805604534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_143_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1519",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020454147536576,
          "to_latitude": 21.020047805604534,
          "to_longitude": 105.80572884896742,
          "from_longitude": 105.80571912590204
        }
      },
      {
        "id": 1515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80510580923419,
            21.01987211953073,
            105.80577967245064,
            21.020033744431448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80577967245064,
              21.020015706164365
            ],
            [
              105.80575650831848,
              21.020009101288693
            ],
            [
              105.8055707097055,
              21.019956114531023
            ],
            [
              105.80550757462208,
              21.01993586686246
            ],
            [
              105.80544995792941,
              21.020033744431448
            ],
            [
              105.80510580923419,
              21.01987211953073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_142_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1520",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020015706164365,
          "to_latitude": 21.01987211953073,
          "to_longitude": 105.80510580923419,
          "from_longitude": 105.80577967245064
        }
      },
      {
        "id": 1516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80576991818988,
            21.01970099620556,
            105.80596911389092,
            21.020076060970176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80596911389092,
              21.01970099620556
            ],
            [
              105.80586878815843,
              21.0198867770901
            ],
            [
              105.80586539824785,
              21.019893055236583
            ],
            [
              105.80581241325854,
              21.01999461150802
            ],
            [
              105.80576991818988,
              21.020076060970176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1521",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01970099620556,
          "to_latitude": 21.020076060970176,
          "to_longitude": 105.80576991818988,
          "from_longitude": 105.80596911389092
        }
      },
      {
        "id": 1517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80589749395658,
            21.01997086075341,
            105.8064688046997,
            21.020251903354488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8064688046997,
              21.020251903354488
            ],
            [
              105.80642320691656,
              21.020240949588782
            ],
            [
              105.80637157439506,
              21.020217790194557
            ],
            [
              105.80629244923075,
              21.020178356623262
            ],
            [
              105.80616236209237,
              21.020107313575764
            ],
            [
              105.80589749395658,
              21.01997086075341
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1522",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": 67.206,
          "from_latitude": 21.020251903354488,
          "to_latitude": 21.01997086075341,
          "to_longitude": 105.80589749395658,
          "from_longitude": 105.8064688046997
        }
      },
      {
        "id": 1518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80611676462364,
            21.020301664741943,
            105.80638770359396,
            21.020439658829915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80638770359396,
              21.020439658829915
            ],
            [
              105.80611676462364,
              21.020301664741943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1523",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020439658829915,
          "to_latitude": 21.020301664741943,
          "to_longitude": 105.80611676462364,
          "from_longitude": 105.80638770359396
        }
      },
      {
        "id": 1519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80591559898012,
            21.020420904220675,
            105.80625208862857,
            21.020649497372087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80591559898012,
              21.020420904220675
            ],
            [
              105.80607317875233,
              21.02047598594228
            ],
            [
              105.80604836832623,
              21.020546089922625
            ],
            [
              105.80625208862857,
              21.020649497372087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1524",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020420904220675,
          "to_latitude": 21.020649497372087,
          "to_longitude": 105.80625208862857,
          "from_longitude": 105.80591559898012
        }
      },
      {
        "id": 1520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80511331844114,
            21.01695530489952,
            105.80527128531216,
            21.017144149187082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80511331844114,
              21.017144149187082
            ],
            [
              105.80514271145404,
              21.017106984898934
            ],
            [
              105.80519642368557,
              21.017043687076377
            ],
            [
              105.80521830081435,
              21.01701545877151
            ],
            [
              105.80524006669906,
              21.0169889082875
            ],
            [
              105.80525325620728,
              21.016972820760614
            ],
            [
              105.80526225578568,
              21.01696127372881
            ],
            [
              105.80527073223031,
              21.016955670615758
            ],
            [
              105.80527128531216,
              21.01695530489952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1525",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017144149187082,
          "to_latitude": 21.01695530489952,
          "to_longitude": 105.80527128531216,
          "from_longitude": 105.80511331844114
        }
      },
      {
        "id": 1521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8053672558955,
            21.0167555178841,
            105.80554713760357,
            21.016859516203915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8053672558955,
              21.016826259632726
            ],
            [
              105.80537519584892,
              21.016833608654192
            ],
            [
              105.80540319495309,
              21.016859516203915
            ],
            [
              105.80542431482178,
              21.016842726342745
            ],
            [
              105.8054997251317,
              21.0167893849652
            ],
            [
              105.80554713760357,
              21.0167555178841
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97_Ngõ 100, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1526",
          "diaChiLapD": "Ngõ 100, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016826259632726,
          "to_latitude": 21.0167555178841,
          "to_longitude": 105.80554713760357,
          "from_longitude": 105.8053672558955
        }
      },
      {
        "id": 1522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8056316062208,
            21.016680276310485,
            105.8056391411646,
            21.016688335369388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8056391411646,
              21.016688335369388
            ],
            [
              105.80563502344818,
              21.016683929948726
            ],
            [
              105.8056349402424,
              21.016683840923054
            ],
            [
              105.8056324837557,
              21.016681216029514
            ],
            [
              105.8056316062208,
              21.016680276310485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ 100, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1527",
          "diaChiLapD": "Ngõ 100, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016688335369388,
          "to_latitude": 21.016680276310485,
          "to_longitude": 105.8056316062208,
          "from_longitude": 105.8056391411646
        }
      },
      {
        "id": 1523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80364553446185,
            21.017159503824256,
            105.8036720392287,
            21.017174243966807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8036720392287,
              21.017174243966807
            ],
            [
              105.80367133973067,
              21.01717385526969
            ],
            [
              105.80366798985577,
              21.017171993280602
            ],
            [
              105.80366464190001,
              21.01717013037915
            ],
            [
              105.80364553446185,
              21.017159503824256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1528",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017174243966807,
          "to_latitude": 21.017159503824256,
          "to_longitude": 105.80364553446185,
          "from_longitude": 105.8036720392287
        }
      },
      {
        "id": 1524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80351461442537,
            21.017049323307567,
            105.80376312461622,
            21.017394488656812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80376312461622,
              21.017049323307567
            ],
            [
              105.8036720392287,
              21.017174243966807
            ],
            [
              105.8036445667112,
              21.01721192100518
            ],
            [
              105.8035830090951,
              21.017298212802803
            ],
            [
              105.80351461442537,
              21.017394488656812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1529",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017049323307567,
          "to_latitude": 21.017394488656812,
          "to_longitude": 105.80351461442537,
          "from_longitude": 105.80376312461622
        }
      },
      {
        "id": 1525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80385398232117,
            21.016889846112157,
            105.80386915969186,
            21.016900024245466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80386915969186,
              21.016900024245466
            ],
            [
              105.80386415784886,
              21.016896669801653
            ],
            [
              105.80386077792319,
              21.016894405117775
            ],
            [
              105.80385740087375,
              21.01689213861377
            ],
            [
              105.80385398232117,
              21.016889846112157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1530",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016900024245466,
          "to_latitude": 21.016889846112157,
          "to_longitude": 105.80385398232117,
          "from_longitude": 105.80386915969186
        }
      },
      {
        "id": 1526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80377941636618,
            21.01682260313071,
            105.80393068276635,
            21.01701793768492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80393068276635,
              21.01682260313071
            ],
            [
              105.80386915969186,
              21.016900024245466
            ],
            [
              105.80385626609406,
              21.016916249368176
            ],
            [
              105.803834909577,
              21.016943124716597
            ],
            [
              105.80377941636618,
              21.01701793768492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1531",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01682260313071,
          "to_latitude": 21.01701793768492,
          "to_longitude": 105.80377941636618,
          "from_longitude": 105.80393068276635
        }
      },
      {
        "id": 1527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80400309210017,
            21.01670228223617,
            105.80401764747342,
            21.016712533748173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80401764747342,
              21.016712533748173
            ],
            [
              105.8040113865617,
              21.016708124867442
            ],
            [
              105.80401077244395,
              21.01670769241314
            ],
            [
              105.80400734652089,
              21.01670528072303
            ],
            [
              105.80400330936116,
              21.01670243566166
            ],
            [
              105.80400309210017,
              21.01670228223617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1532",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016712533748173,
          "to_latitude": 21.01670228223617,
          "to_longitude": 105.80400309210017,
          "from_longitude": 105.80401764747342
        }
      },
      {
        "id": 1528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80397766743046,
            21.01666417914473,
            105.80405916185143,
            21.016759103534955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80405916185143,
              21.01666417914473
            ],
            [
              105.80401764747342,
              21.016712533748173
            ],
            [
              105.80401483880752,
              21.016715805874696
            ],
            [
              105.80397766743046,
              21.016759103534955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1533",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01666417914473,
          "to_latitude": 21.016759103534955,
          "to_longitude": 105.80397766743046,
          "from_longitude": 105.80405916185143
        }
      },
      {
        "id": 1529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80375079102188,
            21.01702142210609,
            105.80395586845748,
            21.017255219266296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80388789663839,
              21.017255219266296
            ],
            [
              105.80395586845748,
              21.017170023378153
            ],
            [
              105.8038442316539,
              21.0170841651354
            ],
            [
              105.8037575345233,
              21.017025951555258
            ],
            [
              105.80375079102188,
              21.01702142210609
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 738, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1534",
          "diaChiLapD": "Ngõ 738, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017255219266296,
          "to_latitude": 21.01702142210609,
          "to_longitude": 105.80375079102188,
          "from_longitude": 105.80388789663839
        }
      },
      {
        "id": 1530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80341928182541,
            21.017471504786503,
            105.80344614484798,
            21.017489187799498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80344614484798,
              21.017489187799498
            ],
            [
              105.80343578625474,
              21.017482313466537
            ],
            [
              105.80343575922397,
              21.017482295529607
            ],
            [
              105.80343146732558,
              21.017479485981003
            ],
            [
              105.80342715031556,
              21.017476657583064
            ],
            [
              105.80341928182541,
              21.017471504786503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1535",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017489187799498,
          "to_latitude": 21.017471504786503,
          "to_longitude": 105.80341928182541,
          "from_longitude": 105.80344614484798
        }
      },
      {
        "id": 1531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80336679053886,
            21.01744025085321,
            105.80348400300012,
            21.017591764803665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80336679053886,
              21.017591764803665
            ],
            [
              105.80342479493416,
              21.017516785651424
            ],
            [
              105.80344614484798,
              21.017489187799498
            ],
            [
              105.80348400300012,
              21.01744025085321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1536",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017591764803665,
          "to_latitude": 21.01744025085321,
          "to_longitude": 105.80348400300012,
          "from_longitude": 105.80336679053886
        }
      },
      {
        "id": 1532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80348246530035,
            21.01727071677412,
            105.80377494641563,
            21.017478257278086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80348246530035,
              21.017388982672607
            ],
            [
              105.80349008313975,
              21.017394034585262
            ],
            [
              105.803499702201,
              21.01740041021235
            ],
            [
              105.8036105217588,
              21.017478257278086
            ],
            [
              105.80377494641563,
              21.01727071677412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 766, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1537",
          "diaChiLapD": "Ngõ 766, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017388982672607,
          "to_latitude": 21.01727071677412,
          "to_longitude": 105.80377494641563,
          "from_longitude": 105.80348246530035
        }
      },
      {
        "id": 1533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80340740138796,
            21.017850636087577,
            105.80341397689986,
            21.017858436723145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80340740138796,
              21.017858436723145
            ],
            [
              105.80340757153292,
              21.0178582344994
            ],
            [
              105.80341131858468,
              21.01785378826828
            ],
            [
              105.80341397689986,
              21.017850636087577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1538",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017858436723145,
          "to_latitude": 21.017850636087577,
          "to_longitude": 105.80341397689986,
          "from_longitude": 105.80340740138796
        }
      },
      {
        "id": 1534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80328827860075,
            21.017777102532996,
            105.80346505542825,
            21.017900839317274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80346505542825,
              21.017900839317274
            ],
            [
              105.80342124938298,
              21.01786789226542
            ],
            [
              105.80340740138796,
              21.017858436723145
            ],
            [
              105.80337715748804,
              21.017837787208713
            ],
            [
              105.80332625023969,
              21.017803029271832
            ],
            [
              105.80328827860075,
              21.017777102532996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1539",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017900839317274,
          "to_latitude": 21.017777102532996,
          "to_longitude": 105.80328827860075,
          "from_longitude": 105.80346505542825
        }
      },
      {
        "id": 1535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80352341670913,
            21.017950635097154,
            105.80377557442169,
            21.01813567353016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80377557442169,
              21.01813567353016
            ],
            [
              105.80363839463165,
              21.018030882238403
            ],
            [
              105.803634978944,
              21.01802823113764
            ],
            [
              105.80362259224631,
              21.01801861696795
            ],
            [
              105.80361679876944,
              21.0180194084686
            ],
            [
              105.8036158661633,
              21.018013396462166
            ],
            [
              105.80359703835191,
              21.01799878353596
            ],
            [
              105.80352341670913,
              21.017950635097154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1540",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01813567353016,
          "to_latitude": 21.017950635097154,
          "to_longitude": 105.80352341670913,
          "from_longitude": 105.80377557442169
        }
      },
      {
        "id": 1536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.803634978944,
            21.018019772129815,
            105.80364210885838,
            21.01802823113764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.803634978944,
              21.01802823113764
            ],
            [
              105.80363792783619,
              21.01802473254714
            ],
            [
              105.8036415228958,
              21.0180204658686
            ],
            [
              105.80364210885838,
              21.018019772129815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1541",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01802823113764,
          "to_latitude": 21.018019772129815,
          "to_longitude": 105.80364210885838,
          "from_longitude": 105.803634978944
        }
      },
      {
        "id": 1537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80352971078692,
            21.018009614101548,
            105.803671906604,
            21.018229113771106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80362840809879,
              21.018009614101548
            ],
            [
              105.80362774423762,
              21.018010850146002
            ],
            [
              105.80361679876944,
              21.0180194084686
            ],
            [
              105.80352971078692,
              21.018141312354473
            ],
            [
              105.803671906604,
              21.018229113771106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1542",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018009614101548,
          "to_latitude": 21.018229113771106,
          "to_longitude": 105.803671906604,
          "from_longitude": 105.80362840809879
        }
      },
      {
        "id": 1538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80372120261765,
            21.01805861732761,
            105.80373789039741,
            21.01807841186597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80373789039741,
              21.01805861732761
            ],
            [
              105.80373402289739,
              21.018063204135373
            ],
            [
              105.80373326967127,
              21.018064099181668
            ],
            [
              105.80372572202833,
              21.018073051523672
            ],
            [
              105.80372120261765,
              21.01807841186597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1543",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01805861732761,
          "to_latitude": 21.01807841186597,
          "to_longitude": 105.80372120261765,
          "from_longitude": 105.80373789039741
        }
      },
      {
        "id": 1539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80365503100303,
            21.017996286728174,
            105.80384969317183,
            21.01813600795469
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80384969317183,
              21.01813600795469
            ],
            [
              105.80376295715887,
              21.01807747664805
            ],
            [
              105.80375289007243,
              21.018069900956174
            ],
            [
              105.80373789039741,
              21.01805861732761
            ],
            [
              105.80365503100303,
              21.017996286728174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1544",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01813600795469,
          "to_latitude": 21.017996286728174,
          "to_longitude": 105.80365503100303,
          "from_longitude": 105.80384969317183
        }
      },
      {
        "id": 1540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8042300264902,
            21.016658453738838,
            105.80430878297692,
            21.016792708550607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8042300264902,
              21.016792708550607
            ],
            [
              105.80430878297692,
              21.016715348545294
            ],
            [
              105.80424237565086,
              21.016658453738838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14-14A_Ngõ 718, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1545",
          "diaChiLapD": "Ngõ 718, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016792708550607,
          "to_latitude": 21.016658453738838,
          "to_longitude": 105.80424237565086,
          "from_longitude": 105.8042300264902
        }
      },
      {
        "id": 1541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80441686633577,
            21.016778586462316,
            105.80457407855269,
            21.016947208666963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80441686633577,
              21.016947208666963
            ],
            [
              105.80457407855269,
              21.016778586462316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14-14A_Ngõ 718, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1546",
          "diaChiLapD": "Ngõ 718, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016947208666963,
          "to_latitude": 21.016778586462316,
          "to_longitude": 105.80457407855269,
          "from_longitude": 105.80441686633577
        }
      },
      {
        "id": 1542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8043146392232,
            21.016326066838875,
            105.80432501925088,
            21.016335331867754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80432501925088,
              21.016335331867754
            ],
            [
              105.804321606294,
              21.016332287861747
            ],
            [
              105.8043152858634,
              21.01632664455834
            ],
            [
              105.8043146392232,
              21.016326066838875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1547",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016335331867754,
          "to_latitude": 21.016326066838875,
          "to_longitude": 105.8043146392232,
          "from_longitude": 105.80432501925088
        }
      },
      {
        "id": 1543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80408356796354,
            21.016083148864073,
            105.80457304153218,
            21.016629643516914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80457304153218,
              21.016083148864073
            ],
            [
              105.80442827352725,
              21.01622392887733
            ],
            [
              105.80435519841312,
              21.016303225693186
            ],
            [
              105.80433944298211,
              21.016320320643256
            ],
            [
              105.80432501925088,
              21.016335331867754
            ],
            [
              105.80425586509834,
              21.016407305687178
            ],
            [
              105.80422963145062,
              21.016441319782047
            ],
            [
              105.80418617185147,
              21.01650056663398
            ],
            [
              105.80412745511082,
              21.016575488884094
            ],
            [
              105.80408356796354,
              21.016629643516914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1548",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016083148864073,
          "to_latitude": 21.016629643516914,
          "to_longitude": 105.80408356796354,
          "from_longitude": 105.80457304153218
        }
      },
      {
        "id": 1544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80492583614243,
            21.01587175023993,
            105.80494318699823,
            21.015942144237634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80492583614243,
              21.015942144237634
            ],
            [
              105.80492875554943,
              21.015930296311407
            ],
            [
              105.80493680963697,
              21.015897622714487
            ],
            [
              105.80494318699823,
              21.01587175023993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1549",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015942144237634,
          "to_latitude": 21.01587175023993,
          "to_longitude": 105.80494318699823,
          "from_longitude": 105.80492583614243
        }
      },
      {
        "id": 1545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8046300453709,
            21.01594207727906,
            105.80525076625712,
            21.016073634713603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80525076625712,
              21.0160566182814
            ],
            [
              105.80520568172307,
              21.016021655398703
            ],
            [
              105.80499840194918,
              21.01594207727906
            ],
            [
              105.80494831570049,
              21.015942123685207
            ],
            [
              105.80492583614243,
              21.015942144237634
            ],
            [
              105.8048023039083,
              21.015942258079498
            ],
            [
              105.8047328613791,
              21.015976004257137
            ],
            [
              105.8046300453709,
              21.016073634713603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1550",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0160566182814,
          "to_latitude": 21.016073634713603,
          "to_longitude": 105.8046300453709,
          "from_longitude": 105.80525076625712
        }
      },
      {
        "id": 1546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80452425696109,
            21.016265096770514,
            105.80476448573503,
            21.016620389238177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80476448573503,
              21.016265096770514
            ],
            [
              105.8046519602763,
              21.016371806370344
            ],
            [
              105.80452425696109,
              21.01650253048549
            ],
            [
              105.80453221428569,
              21.016509721400865
            ],
            [
              105.80465967350383,
              21.016620389238177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12-12A_Ngõ 678, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1551",
          "diaChiLapD": "Ngõ 678, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016265096770514,
          "to_latitude": 21.016620389238177,
          "to_longitude": 105.80465967350383,
          "from_longitude": 105.80476448573503
        }
      },
      {
        "id": 1547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80483056839289,
            21.01611195490382,
            105.8050674953727,
            21.01632960623267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80483056839289,
              21.01632960623267
            ],
            [
              105.80493606262922,
              21.01623271808467
            ],
            [
              105.80502535507947,
              21.01615328828919
            ],
            [
              105.8050674953727,
              21.01611195490382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12-12A_Ngõ 678, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1552",
          "diaChiLapD": "Ngõ 678, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01632960623267,
          "to_latitude": 21.01611195490382,
          "to_longitude": 105.8050674953727,
          "from_longitude": 105.80483056839289
        }
      },
      {
        "id": 1548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80520851619407,
            21.016067526750025,
            105.80540929553497,
            21.016392301849795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80540929553497,
              21.016392301849795
            ],
            [
              105.80540922047916,
              21.016391401685382
            ],
            [
              105.80539232636139,
              21.016361587480823
            ],
            [
              105.80531917853992,
              21.01627389507882
            ],
            [
              105.80528730310222,
              21.016244079520302
            ],
            [
              105.80525354424671,
              21.016205495286112
            ],
            [
              105.80520851619407,
              21.016142357701412
            ],
            [
              105.80526878596426,
              21.01608664485329
            ],
            [
              105.80528946806322,
              21.016067526750025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1553",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016392301849795,
          "to_latitude": 21.016067526750025,
          "to_longitude": 105.80528946806322,
          "from_longitude": 105.80540929553497
        }
      },
      {
        "id": 1549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80553722123562,
            21.016316431318717,
            105.80556137296442,
            21.01633610691756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80553722123562,
              21.01633610691756
            ],
            [
              105.80553877088272,
              21.016334844091123
            ],
            [
              105.80554903131957,
              21.01632648489401
            ],
            [
              105.80555087787194,
              21.016324980402604
            ],
            [
              105.80556137296442,
              21.016316431318717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1554",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01633610691756,
          "to_latitude": 21.016316431318717,
          "to_longitude": 105.80556137296442,
          "from_longitude": 105.80553722123562
        }
      },
      {
        "id": 1550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80529424790929,
            21.01609044976632,
            105.8057491801448,
            21.01653805519032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8057491801448,
              21.01653805519032
            ],
            [
              105.80554059569438,
              21.01633932179114
            ],
            [
              105.80553722123562,
              21.01633610691756
            ],
            [
              105.80552405407703,
              21.016323561987093
            ],
            [
              105.80529424790929,
              21.01609044976632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1555",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01653805519032,
          "to_latitude": 21.01609044976632,
          "to_longitude": 105.80529424790929,
          "from_longitude": 105.8057491801448
        }
      },
      {
        "id": 1551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80561265378539,
            21.016689207772693,
            105.8056217189203,
            21.016701059306417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80561265378539,
              21.016689207772693
            ],
            [
              105.80561607389686,
              21.016693040237072
            ],
            [
              105.8056217189203,
              21.01669936591703
            ],
            [
              105.80561908361898,
              21.016701059306417
            ],
            [
              105.80561357232942,
              21.016695096091286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94_Ngõ 100, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1556",
          "diaChiLapD": "Ngõ 100, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016689207772693,
          "to_latitude": 21.016695096091286,
          "to_longitude": 105.80561357232942,
          "from_longitude": 105.80561265378539
        }
      },
      {
        "id": 1552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80541840926895,
            21.016568086237278,
            105.80574106968467,
            21.01683320536095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80541840926895,
              21.01683320536095
            ],
            [
              105.80550294759881,
              21.016774864746864
            ],
            [
              105.80556333766233,
              21.016729166232064
            ],
            [
              105.80558004422147,
              21.016716523201882
            ],
            [
              105.80561265378539,
              21.016689207772693
            ],
            [
              105.8056459135776,
              21.01666134822385
            ],
            [
              105.80568107185589,
              21.01662929772585
            ],
            [
              105.80574106968467,
              21.016568086237278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94_Ngõ 100, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1557",
          "diaChiLapD": "Ngõ 100, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01683320536095,
          "to_latitude": 21.016568086237278,
          "to_longitude": 105.80574106968467,
          "from_longitude": 105.80541840926895
        }
      },
      {
        "id": 1553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80574824580674,
            21.019194676874513,
            105.80594620688662,
            21.01952423741249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80594620688662,
              21.019194676874513
            ],
            [
              105.80575449065003,
              21.019511838110038
            ],
            [
              105.80574824580674,
              21.01952423741249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_139_Ngõ 81, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1558",
          "diaChiLapD": "Ngõ 81, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019194676874513,
          "to_latitude": 21.01952423741249,
          "to_longitude": 105.80574824580674,
          "from_longitude": 105.80594620688662
        }
      },
      {
        "id": 1554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80391203346198,
            21.0184088960203,
            105.80393705128483,
            21.018417175113505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80393705128483,
              21.018417175113505
            ],
            [
              105.80392438481822,
              21.018412984376273
            ],
            [
              105.80391203346198,
              21.0184088960203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1559",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018417175113505,
          "to_latitude": 21.0184088960203,
          "to_longitude": 105.80391203346198,
          "from_longitude": 105.80393705128483
        }
      },
      {
        "id": 1555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8048715303237,
            21.019039290333485,
            105.80488487797842,
            21.01906179873578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80488487797842,
              21.019039290333485
            ],
            [
              105.80487658109863,
              21.01905328177313
            ],
            [
              105.80487226342875,
              21.019060560552678
            ],
            [
              105.8048715303237,
              21.01906179873578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1560",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019039290333485,
          "to_latitude": 21.01906179873578,
          "to_longitude": 105.8048715303237,
          "from_longitude": 105.80488487797842
        }
      },
      {
        "id": 1556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80484428746985,
            21.01695914647976,
            105.80485681562237,
            21.016970027123598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80484428746985,
              21.01695914647976
            ],
            [
              105.8048479223054,
              21.016962303231576
            ],
            [
              105.8048508680695,
              21.016964863498988
            ],
            [
              105.80485381671461,
              21.016967422849472
            ],
            [
              105.80485681562237,
              21.016970027123598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1561",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01695914647976,
          "to_latitude": 21.016970027123598,
          "to_longitude": 105.80485681562237,
          "from_longitude": 105.80484428746985
        }
      },
      {
        "id": 1557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80462339760754,
            21.018540794147313,
            105.80477174160133,
            21.018927889250946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80462339760754,
              21.018927889250946
            ],
            [
              105.80462346410488,
              21.018927732677753
            ],
            [
              105.80463088309699,
              21.01890556214492
            ],
            [
              105.80469222269063,
              21.01876554783847
            ],
            [
              105.80469265539834,
              21.018733603087384
            ],
            [
              105.80477174160133,
              21.018540794147313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120_Ngõ 127, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1562",
          "diaChiLapD": "Ngõ 127, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018927889250946,
          "to_latitude": 21.018540794147313,
          "to_longitude": 105.80477174160133,
          "from_longitude": 105.80462339760754
        }
      },
      {
        "id": 1558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80536533047305,
            21.017145228135718,
            105.80572160951284,
            21.017525033439306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80536533047305,
              21.017497768287416
            ],
            [
              105.80540629162898,
              21.017525033439306
            ],
            [
              105.80550095633237,
              21.01741232918321
            ],
            [
              105.80554030672161,
              21.017366972358847
            ],
            [
              105.80561409053642,
              21.017283177221362
            ],
            [
              105.8057215302309,
              21.01714533238258
            ],
            [
              105.80572160951284,
              21.017145228135718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M90_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1563",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017497768287416,
          "to_latitude": 21.017145228135718,
          "to_longitude": 105.80572160951284,
          "from_longitude": 105.80536533047305
        }
      },
      {
        "id": 1559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8045604619939,
            21.017572870066797,
            105.80473909549497,
            21.017781688093624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8045604619939,
              21.017781688093624
            ],
            [
              105.8046040290603,
              21.017727552918856
            ],
            [
              105.80462220618402,
              21.017706397312093
            ],
            [
              105.80464784056072,
              21.017676669106432
            ],
            [
              105.80465295137523,
              21.017670742327994
            ],
            [
              105.80467151785128,
              21.017651158300193
            ],
            [
              105.80473909549497,
              21.017572870066797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1564",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017781688093624,
          "to_latitude": 21.017572870066797,
          "to_longitude": 105.80473909549497,
          "from_longitude": 105.8045604619939
        }
      },
      {
        "id": 1560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80387188423188,
            21.01794158635668,
            105.80400748751542,
            21.018138274972614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80387188423188,
              21.018138274972614
            ],
            [
              105.80389258083369,
              21.018109573697554
            ],
            [
              105.80389890898567,
              21.01809457186075
            ],
            [
              105.80393592579806,
              21.018043372590206
            ],
            [
              105.80394608187984,
              21.018029325439564
            ],
            [
              105.80396477456337,
              21.018003470670358
            ],
            [
              105.80400748751542,
              21.01794158635668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1565",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018138274972614,
          "to_latitude": 21.01794158635668,
          "to_longitude": 105.80400748751542,
          "from_longitude": 105.80387188423188
        }
      },
      {
        "id": 1561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80401686748137,
            21.017571475610954,
            105.80422986874501,
            21.0179214749744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80401686748137,
              21.0179214749744
            ],
            [
              105.80407654222991,
              21.017829922484555
            ],
            [
              105.804116560734,
              21.01777020688936
            ],
            [
              105.80413010649445,
              21.017743559912258
            ],
            [
              105.80414569886638,
              21.017712886598144
            ],
            [
              105.80417031907602,
              21.01766353517563
            ],
            [
              105.80417354501496,
              21.017658246872184
            ],
            [
              105.80417599188478,
              21.017654235805107
            ],
            [
              105.80418643350356,
              21.017637117213955
            ],
            [
              105.80422986874501,
              21.017571475610954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1566",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0179214749744,
          "to_latitude": 21.017571475610954,
          "to_longitude": 105.80422986874501,
          "from_longitude": 105.80401686748137
        }
      },
      {
        "id": 1562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80424191225583,
            21.017320104542602,
            105.80448404150728,
            21.017555300544267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80424191225583,
              21.017555300544267
            ],
            [
              105.80434189546098,
              21.01747396892584
            ],
            [
              105.80434878411035,
              21.01747147595796
            ],
            [
              105.80435172093928,
              21.017469320514895
            ],
            [
              105.8043736748258,
              21.01745321058109
            ],
            [
              105.80437892183637,
              21.01744936059449
            ],
            [
              105.80445062049036,
              21.017363168696395
            ],
            [
              105.80448404150728,
              21.017320104542602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1567",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017555300544267,
          "to_latitude": 21.017320104542602,
          "to_longitude": 105.80448404150728,
          "from_longitude": 105.80424191225583
        }
      },
      {
        "id": 1563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80448658525803,
            21.01715859978908,
            105.80459827179719,
            21.01729488247518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80448658525803,
              21.01729488247518
            ],
            [
              105.80453653532258,
              21.017226503913594
            ],
            [
              105.80455827731518,
              21.01719941620476
            ],
            [
              105.80459827179719,
              21.01715859978908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1568",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01729488247518,
          "to_latitude": 21.01715859978908,
          "to_longitude": 105.80459827179719,
          "from_longitude": 105.80448658525803
        }
      },
      {
        "id": 1564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8046360244816,
            21.016642634239634,
            105.80514356249664,
            21.017139937541565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8046360244816,
              21.017139937541565
            ],
            [
              105.804718689578,
              21.017063877578256
            ],
            [
              105.80473332932034,
              21.017059897343692
            ],
            [
              105.80484428746985,
              21.01695914647976
            ],
            [
              105.8049027881593,
              21.016906027166037
            ],
            [
              105.80490920698526,
              21.016898269668218
            ],
            [
              105.80495184402314,
              21.016846746433107
            ],
            [
              105.80495726710387,
              21.016839145387596
            ],
            [
              105.804960693772,
              21.016834343864097
            ],
            [
              105.80496848658909,
              21.016823422172692
            ],
            [
              105.80506426400413,
              21.01672609759588
            ],
            [
              105.80514356249664,
              21.016642634239634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1569",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017139937541565,
          "to_latitude": 21.016642634239634,
          "to_longitude": 105.80514356249664,
          "from_longitude": 105.8046360244816
        }
      },
      {
        "id": 1565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80714591986583,
            21.020048668291597,
            105.8075944174175,
            21.020216821446038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8075944174175,
              21.020048668291597
            ],
            [
              105.80739869672438,
              21.02014546430103
            ],
            [
              105.80734724996118,
              21.020170907669254
            ],
            [
              105.80729840895563,
              21.02019506239313
            ],
            [
              105.80725457845035,
              21.020216821446038
            ],
            [
              105.80714591986583,
              21.020164255231485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_169_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1570",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020048668291597,
          "to_latitude": 21.020164255231485,
          "to_longitude": 105.80714591986583,
          "from_longitude": 105.8075944174175
        }
      },
      {
        "id": 1566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80659835514703,
            21.01990824826359,
            105.80704551493763,
            21.020116849673528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80704551493763,
              21.020116849673528
            ],
            [
              105.80682211410101,
              21.020011976075683
            ],
            [
              105.80674504171812,
              21.01997579428005
            ],
            [
              105.8067127621211,
              21.019960641254976
            ],
            [
              105.80659835514703,
              21.01990824826359
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_166_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1571",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020116849673528,
          "to_latitude": 21.01990824826359,
          "to_longitude": 105.80659835514703,
          "from_longitude": 105.80704551493763
        }
      },
      {
        "id": 1567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80576613933584,
            21.019521190965442,
            105.80597540676719,
            21.019624508958014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80597540676719,
              21.019624508958014
            ],
            [
              105.8058707720568,
              21.0195728504514
            ],
            [
              105.80581489224727,
              21.019545261397653
            ],
            [
              105.80576613933584,
              21.019521190965442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1572",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019624508958014,
          "to_latitude": 21.019521190965442,
          "to_longitude": 105.80576613933584,
          "from_longitude": 105.80597540676719
        }
      },
      {
        "id": 1568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80464823632462,
            21.01892194773762,
            105.80509634785402,
            21.01914920885871
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80509634785402,
              21.01914920885871
            ],
            [
              105.80508182387622,
              21.019140025821972
            ],
            [
              105.80498845569551,
              21.01909004220992
            ],
            [
              105.80492240089998,
              21.0190576759517
            ],
            [
              105.8049132275335,
              21.019053180631637
            ],
            [
              105.80488487797842,
              21.019039290333485
            ],
            [
              105.80486631520401,
              21.01903019412983
            ],
            [
              105.80480697241717,
              21.019001117240943
            ],
            [
              105.80478853508923,
              21.018991912950717
            ],
            [
              105.80478306899714,
              21.018989183961807
            ],
            [
              105.80465678662505,
              21.018926140728244
            ],
            [
              105.80464823632462,
              21.01892194773762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1573",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01914920885871,
          "to_latitude": 21.01892194773762,
          "to_longitude": 105.80464823632462,
          "from_longitude": 105.80509634785402
        }
      },
      {
        "id": 1569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8043129791446,
            21.01874622238555,
            105.80459326997939,
            21.01887985961926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80459326997939,
              21.01887985961926
            ],
            [
              105.80456376568524,
              21.01886577607522
            ],
            [
              105.80453057334873,
              21.018848875821647
            ],
            [
              105.80450609819185,
              21.018836983048892
            ],
            [
              105.80449335770058,
              21.018831036662178
            ],
            [
              105.80447357640277,
              21.018820708727244
            ],
            [
              105.8043129791446,
              21.01874622238555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1574",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 32.684,
          "from_latitude": 21.01887985961926,
          "to_latitude": 21.01874622238555,
          "to_longitude": 105.8043129791446,
          "from_longitude": 105.80459326997939
        }
      },
      {
        "id": 1570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80684581114443,
            21.02009080135575,
            105.80727187293564,
            21.020298160018594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80727187293564,
              21.020298160018594
            ],
            [
              105.80725177282885,
              21.020288384927117
            ],
            [
              105.80724796306454,
              21.020286531517478
            ],
            [
              105.80724007927238,
              21.020282697693563
            ],
            [
              105.80723529054573,
              21.02028036841184
            ],
            [
              105.80723224175615,
              21.02027888261749
            ],
            [
              105.80721312920141,
              21.020269569803933
            ],
            [
              105.80718025235718,
              21.020253568863932
            ],
            [
              105.8071556055844,
              21.02024157442568
            ],
            [
              105.8071421267272,
              21.02023501410554
            ],
            [
              105.80704358982095,
              21.020187057746853
            ],
            [
              105.80697289413352,
              21.020152651358956
            ],
            [
              105.8069539282732,
              21.02014342091637
            ],
            [
              105.80690580086622,
              21.02011999727703
            ],
            [
              105.80687474119811,
              21.020104880948995
            ],
            [
              105.80684581114443,
              21.02009080135575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_172_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1575",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020298160018594,
          "to_latitude": 21.02009080135575,
          "to_longitude": 105.80684581114443,
          "from_longitude": 105.80727187293564
        }
      },
      {
        "id": 1571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80476671899868,
            21.017150948865655,
            105.80510793915394,
            21.017545444701714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80476671899868,
              21.017545444701714
            ],
            [
              105.80487789969877,
              21.017409563458124
            ],
            [
              105.80489648586646,
              21.017386848720584
            ],
            [
              105.80492601280261,
              21.01735346926893
            ],
            [
              105.80499414338203,
              21.017276450069605
            ],
            [
              105.80510793915394,
              21.017150948865655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1576",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017545444701714,
          "to_latitude": 21.017150948865655,
          "to_longitude": 105.80510793915394,
          "from_longitude": 105.80476671899868
        }
      },
      {
        "id": 1572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80506130476145,
            21.017428392050462,
            105.80536401522377,
            21.017786080196117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80506130476145,
              21.017786080196117
            ],
            [
              105.80506645992502,
              21.01777999513065
            ],
            [
              105.80506649152817,
              21.017779968787444
            ],
            [
              105.80508302426941,
              21.017760362727635
            ],
            [
              105.80508305687326,
              21.017760343605513
            ],
            [
              105.80513231894935,
              21.01770198855801
            ],
            [
              105.80521793776303,
              21.017605233133917
            ],
            [
              105.80535684097258,
              21.017434303327576
            ],
            [
              105.80536401522377,
              21.017428392050462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1577",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017786080196117,
          "to_latitude": 21.017428392050462,
          "to_longitude": 105.80536401522377,
          "from_longitude": 105.80506130476145
        }
      },
      {
        "id": 1573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80416619579566,
            21.017817497841012,
            105.8045306025882,
            21.01824384798794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80416619579566,
              21.01824384798794
            ],
            [
              105.8042244072853,
              21.018174691617066
            ],
            [
              105.8042314557541,
              21.018172934932853
            ],
            [
              105.80428513586473,
              21.01810727288196
            ],
            [
              105.80433756051158,
              21.01804267533597
            ],
            [
              105.80437376122514,
              21.018005250468857
            ],
            [
              105.80438361379544,
              21.017995065130943
            ],
            [
              105.80440307402883,
              21.01797494761254
            ],
            [
              105.80440884241996,
              21.01796786611417
            ],
            [
              105.80448009096781,
              21.01788039464826
            ],
            [
              105.8045306025882,
              21.017817497841012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1578",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01824384798794,
          "to_latitude": 21.017817497841012,
          "to_longitude": 105.8045306025882,
          "from_longitude": 105.80416619579566
        }
      },
      {
        "id": 1574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80405419774179,
            21.016640587668284,
            105.8046365773465,
            21.01717252344602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8046365773465,
              21.01717252344602
            ],
            [
              105.80463221105312,
              21.017168221241548
            ],
            [
              105.80461298770788,
              21.01714927027936
            ],
            [
              105.80454786034518,
              21.017078013620047
            ],
            [
              105.80441686633577,
              21.016947208666963
            ],
            [
              105.80440845908257,
              21.016938814966313
            ],
            [
              105.80423840679666,
              21.016800206384378
            ],
            [
              105.8042300264902,
              21.016792708550607
            ],
            [
              105.80411789088947,
              21.016692375874204
            ],
            [
              105.80405765177132,
              21.016643397548908
            ],
            [
              105.80405419774179,
              21.016640587668284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14-14A_Ngõ 718, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1579",
          "diaChiLapD": "Ngõ 718, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01717252344602,
          "to_latitude": 21.016640587668284,
          "to_longitude": 105.80405419774179,
          "from_longitude": 105.8046365773465
        }
      },
      {
        "id": 1575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80392802353161,
            21.018257691979212,
            105.80399283748328,
            21.018528847227635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80392950498599,
              21.018528847227635
            ],
            [
              105.80392802353161,
              21.01844234381506
            ],
            [
              105.80393705128483,
              21.018417175113505
            ],
            [
              105.80394557101498,
              21.018393422366493
            ],
            [
              105.80394633702473,
              21.018390605186568
            ],
            [
              105.80399283748328,
              21.018257691979212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1580",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018528847227635,
          "to_latitude": 21.018257691979212,
          "to_longitude": 105.80399283748328,
          "from_longitude": 105.80392950498599
        }
      },
      {
        "id": 1576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80400163415217,
            21.018249516155123,
            105.8043356864659,
            21.018342008102834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80400163415217,
              21.01825168728281
            ],
            [
              105.80408335127181,
              21.018250369814083
            ],
            [
              105.80413630349479,
              21.018249516155123
            ],
            [
              105.8043356864659,
              21.018342008102834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1581",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01825168728281,
          "to_latitude": 21.018342008102834,
          "to_longitude": 105.8043356864659,
          "from_longitude": 105.80400163415217
        }
      },
      {
        "id": 1577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80435216234562,
            21.017968112446944,
            105.80438361379544,
            21.017995065130943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80438361379544,
              21.017995065130943
            ],
            [
              105.80436541844448,
              21.017979473813384
            ],
            [
              105.80436073915183,
              21.017975465729098
            ],
            [
              105.8043596509777,
              21.01797453151576
            ],
            [
              105.80435606273548,
              21.01797145582461
            ],
            [
              105.80435216234562,
              21.017968112446944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1582",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017995065130943,
          "to_latitude": 21.017968112446944,
          "to_longitude": 105.80435216234562,
          "from_longitude": 105.80438361379544
        }
      },
      {
        "id": 1578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80422250453809,
            21.01710083210096,
            105.80450323459084,
            21.017324804507254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80450323459084,
              21.017324804507254
            ],
            [
              105.8044996050684,
              21.017322279983755
            ],
            [
              105.80430410491432,
              21.017186208871948
            ],
            [
              105.80425168316688,
              21.017122492701557
            ],
            [
              105.80422250453809,
              21.01710083210096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1583",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017324804507254,
          "to_latitude": 21.01710083210096,
          "to_longitude": 105.80422250453809,
          "from_longitude": 105.80450323459084
        }
      },
      {
        "id": 1579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80402759337895,
            21.01727348529328,
            105.80424572435555,
            21.017593487323378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80424572435555,
              21.017593487323378
            ],
            [
              105.80423343716448,
              21.017583235047336
            ],
            [
              105.80423523319043,
              21.01756243601998
            ],
            [
              105.80405909506683,
              21.017422043272827
            ],
            [
              105.80407895162426,
              21.017398521452137
            ],
            [
              105.80402759337895,
              21.01734656746155
            ],
            [
              105.80408550939737,
              21.01727348529328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Ngõ 159/22, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1584",
          "diaChiLapD": "Ngõ 159/22, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017593487323378,
          "to_latitude": 21.01727348529328,
          "to_longitude": 105.80408550939737,
          "from_longitude": 105.80424572435555
        }
      },
      {
        "id": 1580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80382477189809,
            21.017689033904016,
            105.8040275100193,
            21.017941289400206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8040275100193,
              21.017941289400206
            ],
            [
              105.80402109884803,
              21.017937392459956
            ],
            [
              105.80382477189809,
              21.01781800014835
            ],
            [
              105.80386010304103,
              21.017773747468237
            ],
            [
              105.80387054441066,
              21.017753164108186
            ],
            [
              105.80392884147848,
              21.017689033904016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1585",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017941289400206,
          "to_latitude": 21.017689033904016,
          "to_longitude": 105.80392884147848,
          "from_longitude": 105.8040275100193
        }
      },
      {
        "id": 1581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80417354501496,
            21.017658246872184,
            105.8041931588508,
            21.017668213419395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80417354501496,
              21.017658246872184
            ],
            [
              105.80418148449871,
              21.01766228109904
            ],
            [
              105.80418464641049,
              21.01766388925527
            ],
            [
              105.80418781023648,
              21.017665495595928
            ],
            [
              105.8041931588508,
              21.017668213419395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1586",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017658246872184,
          "to_latitude": 21.017668213419395,
          "to_longitude": 105.8041931588508,
          "from_longitude": 105.80417354501496
        }
      },
      {
        "id": 1582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80394608187984,
            21.018029325439564,
            105.80396088463083,
            21.018038864037496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80396088463083,
              21.018038864037496
            ],
            [
              105.80395964133237,
              21.018038062426175
            ],
            [
              105.80395492975214,
              21.018035028164487
            ],
            [
              105.80395022008632,
              21.018031992087145
            ],
            [
              105.80394608187984,
              21.018029325439564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1587",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018038864037496,
          "to_latitude": 21.018029325439564,
          "to_longitude": 105.80394608187984,
          "from_longitude": 105.80396088463083
        }
      },
      {
        "id": 1583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8043736748258,
            21.01745321058109,
            105.80439208234469,
            21.017467479472472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8043736748258,
              21.01745321058109
            ],
            [
              105.80438701584139,
              21.017463553606596
            ],
            [
              105.80439208234469,
              21.017467479472472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1588",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01745321058109,
          "to_latitude": 21.017467479472472,
          "to_longitude": 105.80439208234469,
          "from_longitude": 105.8043736748258
        }
      },
      {
        "id": 1584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80453653532258,
            21.017226503913594,
            105.80457211009126,
            21.01724118024412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80453653532258,
              21.017226503913594
            ],
            [
              105.8045596730524,
              21.017232222124356
            ],
            [
              105.80456909233625,
              21.017239007797905
            ],
            [
              105.80457211009126,
              21.01724118024412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1589",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017226503913594,
          "to_latitude": 21.01724118024412,
          "to_longitude": 105.80457211009126,
          "from_longitude": 105.80453653532258
        }
      },
      {
        "id": 1585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80427943462658,
            21.017359123377357,
            105.80476299024554,
            21.01758245758402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80476299024554,
              21.01750739754395
            ],
            [
              105.80474090344008,
              21.01749119873617
            ],
            [
              105.80452505781264,
              21.017359123377357
            ],
            [
              105.80449369601952,
              21.017374688914988
            ],
            [
              105.80440450703485,
              21.017481128133063
            ],
            [
              105.80427943462658,
              21.01758245758402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D33_103_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1590",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 33,
          "chieudaiQL": 66.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01750739754395,
          "to_latitude": 21.01758245758402,
          "to_longitude": 105.80427943462658,
          "from_longitude": 105.80476299024554
        }
      },
      {
        "id": 1586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8045654818971,
            21.016894716059,
            105.80496634891162,
            21.01748388560868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80496634891162,
              21.016894716059
            ],
            [
              105.80493094271684,
              21.016936177431607
            ],
            [
              105.80485641077327,
              21.017003931756577
            ],
            [
              105.8047657715458,
              21.01708758502137
            ],
            [
              105.80462177665319,
              21.017201552793573
            ],
            [
              105.8045654818971,
              21.017292940447046
            ],
            [
              105.80456633322409,
              21.01732378351359
            ],
            [
              105.80456670596973,
              21.0173322992046
            ],
            [
              105.80475992046166,
              21.01746774222987
            ],
            [
              105.80478417713607,
              21.01748388560868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D40_102_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1591",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 40,
          "chieudaiQL": 94.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016894716059,
          "to_latitude": 21.01748388560868,
          "to_longitude": 105.80478417713607,
          "from_longitude": 105.80496634891162
        }
      },
      {
        "id": 1587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80489539487284,
            21.017261707834326,
            105.8049536960605,
            21.01729991045574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80489539487284,
              21.017261707834326
            ],
            [
              105.80490142637021,
              21.01726566163547
            ],
            [
              105.8049250648199,
              21.017281149465873
            ],
            [
              105.8049536960605,
              21.01729991045574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1592",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017261707834326,
          "to_latitude": 21.01729991045574,
          "to_longitude": 105.8049536960605,
          "from_longitude": 105.80489539487284
        }
      },
      {
        "id": 1588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80477582471775,
            21.01683055868925,
            105.80528394143896,
            21.017420465125923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80477582471775,
              21.017420465125923
            ],
            [
              105.80479491789961,
              21.017397579999855
            ],
            [
              105.80484009607729,
              21.017345243604606
            ],
            [
              105.80486512296012,
              21.01730504439357
            ],
            [
              105.80488682343808,
              21.017270050873606
            ],
            [
              105.80489539487284,
              21.017261707834326
            ],
            [
              105.80499964434345,
              21.017160228995333
            ],
            [
              105.80506811037827,
              21.017077806868883
            ],
            [
              105.80512576125977,
              21.017009028600526
            ],
            [
              105.80516319090678,
              21.016964615793764
            ],
            [
              105.80528394143896,
              21.01683055868925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1593",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017420465125923,
          "to_latitude": 21.01683055868925,
          "to_longitude": 105.80528394143896,
          "from_longitude": 105.80477582471775
        }
      },
      {
        "id": 1589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80499158785331,
            21.016682461850213,
            105.8053604897014,
            21.016869313434864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80499158785331,
              21.016869313434864
            ],
            [
              105.80502280770659,
              21.016832171232366
            ],
            [
              105.80505546948373,
              21.016798967507093
            ],
            [
              105.80511732891956,
              21.016739710138886
            ],
            [
              105.80517537837794,
              21.016684101763776
            ],
            [
              105.80518809038354,
              21.016682461850213
            ],
            [
              105.80523498290196,
              21.016694184207957
            ],
            [
              105.80534586030635,
              21.016816423589777
            ],
            [
              105.8053604897014,
              21.016832917754996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D40_98_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1594",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 40,
          "chieudaiQL": 58.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016869313434864,
          "to_latitude": 21.016832917754996,
          "to_longitude": 105.8053604897014,
          "from_longitude": 105.80499158785331
        }
      },
      {
        "id": 1590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80607803311035,
            21.019451950319546,
            105.80624135643734,
            21.019541902936503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80607803311035,
              21.019451950319546
            ],
            [
              105.80624135643734,
              21.019541902936503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_164_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1595",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019451950319546,
          "to_latitude": 21.019541902936503,
          "to_longitude": 105.80624135643734,
          "from_longitude": 105.80607803311035
        }
      },
      {
        "id": 1591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80498950554411,
            21.020134890928457,
            105.80574003341285,
            21.020480310946166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80574003341285,
              21.020479390395195
            ],
            [
              105.80572894007734,
              21.020480310946166
            ],
            [
              105.80570336106294,
              21.02047938707261
            ],
            [
              105.80535528998601,
              21.020315417549366
            ],
            [
              105.80498950554411,
              21.020134890928457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_123_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1596",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020479390395195,
          "to_latitude": 21.020134890928457,
          "to_longitude": 105.80498950554411,
          "from_longitude": 105.80574003341285
        }
      },
      {
        "id": 1592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80851691922854,
            21.0208900474586,
            105.8085451631486,
            21.020939528444803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8085451631486,
              21.020939528444803
            ],
            [
              105.80851829535379,
              21.020892459763207
            ],
            [
              105.80851691922854,
              21.0208900474586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_179_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1597",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020939528444803,
          "to_latitude": 21.0208900474586,
          "to_longitude": 105.80851691922854,
          "from_longitude": 105.8085451631486
        }
      },
      {
        "id": 1593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80786563146918,
            21.021343619096026,
            105.80792849151929,
            21.02134390039805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80786563146918,
              21.021343619096026
            ],
            [
              105.80792495713193,
              21.02134388557319
            ],
            [
              105.80792849151929,
              21.02134390039805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_181_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1598",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021343619096026,
          "to_latitude": 21.02134390039805,
          "to_longitude": 105.80792849151929,
          "from_longitude": 105.80786563146918
        }
      },
      {
        "id": 1594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80788918875461,
            21.021260303603718,
            105.80793133822299,
            21.021261096049034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80788918875461,
              21.021260303603718
            ],
            [
              105.80792315479526,
              21.02126093891915
            ],
            [
              105.80793133822299,
              21.021261096049034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_180_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1599",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021260303603718,
          "to_latitude": 21.021261096049034,
          "to_longitude": 105.80793133822299,
          "from_longitude": 105.80788918875461
        }
      },
      {
        "id": 1595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80900513893586,
            21.02278539331525,
            105.80903488999664,
            21.022869129179735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80900513893586,
              21.02278539331525
            ],
            [
              105.80903179187838,
              21.02286332803057
            ],
            [
              105.80903488999664,
              21.022869129179735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_173_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1600",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02278539331525,
          "to_latitude": 21.022869129179735,
          "to_longitude": 105.80903488999664,
          "from_longitude": 105.80900513893586
        }
      },
      {
        "id": 1596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80792824322397,
            21.02135454536031,
            105.8082371499003,
            21.02139524166134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80792824322397,
              21.02139494392269
            ],
            [
              105.80793077342524,
              21.021394980678668
            ],
            [
              105.80794941893512,
              21.02139524166134
            ],
            [
              105.80796415264739,
              21.02135454536031
            ],
            [
              105.808058338279,
              21.021354596519103
            ],
            [
              105.8082371499003,
              21.021355148752004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_162_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1601",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02139494392269,
          "to_latitude": 21.021355148752004,
          "to_longitude": 105.8082371499003,
          "from_longitude": 105.80792824322397
        }
      },
      {
        "id": 1597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80793637060162,
            21.02161336529125,
            105.80819993745588,
            21.021621082760245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80819993745588,
              21.02161336529125
            ],
            [
              105.80795539364124,
              21.021620526332903
            ],
            [
              105.80793637060162,
              21.021621082760245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_161_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1602",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02161336529125,
          "to_latitude": 21.021621082760245,
          "to_longitude": 105.80793637060162,
          "from_longitude": 105.80819993745588
        }
      },
      {
        "id": 1598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80779281570744,
            21.02168847795164,
            105.80779340736147,
            21.02172044852666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80779340736147,
              21.02172044852666
            ],
            [
              105.80779293369109,
              21.02169484334565
            ],
            [
              105.80779287570573,
              21.021691758192535
            ],
            [
              105.80779281964915,
              21.0216886739335
            ],
            [
              105.80779281570744,
              21.02168847795164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_160_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1603",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02172044852666,
          "to_latitude": 21.02168847795164,
          "to_longitude": 105.80779281570744,
          "from_longitude": 105.80779340736147
        }
      },
      {
        "id": 1599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80235643908186,
            21.022348273003164,
            105.80296660724939,
            21.02250599941223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80235643908186,
              21.022348273003164
            ],
            [
              105.80237322104557,
              21.0223553076696
            ],
            [
              105.80250039756449,
              21.022377076143552
            ],
            [
              105.80296660724939,
              21.02250599941223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1604",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022348273003164,
          "to_latitude": 21.02250599941223,
          "to_longitude": 105.80296660724939,
          "from_longitude": 105.80235643908186
        }
      },
      {
        "id": 1600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80259864182001,
            21.021553332077417,
            105.80262287224306,
            21.021628805548506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80262287224306,
              21.021553332077417
            ],
            [
              105.80259864182001,
              21.021628805548506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M20_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1605",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021553332077417,
          "to_latitude": 21.021628805548506,
          "to_longitude": 105.80259864182001,
          "from_longitude": 105.80262287224306
        }
      },
      {
        "id": 1601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80037294594489,
            21.022611960551732,
            105.80110348607751,
            21.022880616408372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80037294594489,
              21.022611960551732
            ],
            [
              105.80039831259138,
              21.022621511156718
            ],
            [
              105.80059650711942,
              21.02269611363837
            ],
            [
              105.80067308137266,
              21.022724938332697
            ],
            [
              105.80075445214632,
              21.022755567642903
            ],
            [
              105.80079110651012,
              21.022759838055254
            ],
            [
              105.80087730168285,
              21.022793166021355
            ],
            [
              105.80098305603204,
              21.02283405399224
            ],
            [
              105.80110348607751,
              21.022880616408372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_86_Phố Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1606",
          "diaChiLapD": "Phố Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022611960551732,
          "to_latitude": 21.022880616408372,
          "to_longitude": 105.80110348607751,
          "from_longitude": 105.80037294594489
        }
      },
      {
        "id": 1602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80013975943264,
            21.0240159094226,
            105.8002110280159,
            21.024130188035166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80013975943264,
              21.024130188035166
            ],
            [
              105.80015003075299,
              21.0240638679573
            ],
            [
              105.8001567719958,
              21.02402034574118
            ],
            [
              105.8002110280159,
              21.0240159094226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1607",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024130188035166,
          "to_latitude": 21.0240159094226,
          "to_longitude": 105.8002110280159,
          "from_longitude": 105.80013975943264
        }
      },
      {
        "id": 1603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80055269488886,
            21.02415004826514,
            105.80055759933957,
            21.02423901415818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80055269488886,
              21.02423901415818
            ],
            [
              105.80055759933957,
              21.02415004826514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1608",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02423901415818,
          "to_latitude": 21.02415004826514,
          "to_longitude": 105.80055759933957,
          "from_longitude": 105.80055269488886
        }
      },
      {
        "id": 1604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80215819503773,
            21.0245462374101,
            105.80215976887037,
            21.024761128161206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80215970617816,
              21.0245462374101
            ],
            [
              105.80215976887037,
              21.024594633936804
            ],
            [
              105.80215819503773,
              21.024761128161206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1609",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0245462374101,
          "to_latitude": 21.024761128161206,
          "to_longitude": 105.80215819503773,
          "from_longitude": 105.80215970617816
        }
      },
      {
        "id": 1605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80161544340986,
            21.0240783937054,
            105.8016736977591,
            21.024489941976963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80161544340986,
              21.024489941976963
            ],
            [
              105.8016736977591,
              21.0240783937054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1610",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024489941976963,
          "to_latitude": 21.0240783937054,
          "to_longitude": 105.8016736977591,
          "from_longitude": 105.80161544340986
        }
      },
      {
        "id": 1606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80157371058701,
            21.02510615422767,
            105.8016136338024,
            21.025216614938532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8016136338024,
              21.02510615422767
            ],
            [
              105.80161348084052,
              21.025109392121934
            ],
            [
              105.80160849016158,
              21.02521522081058
            ],
            [
              105.80157371058701,
              21.025216614938532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_75_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1611",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02510615422767,
          "to_latitude": 21.025216614938532,
          "to_longitude": 105.80157371058701,
          "from_longitude": 105.8016136338024
        }
      },
      {
        "id": 1607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80434868908553,
            21.028147449129815,
            105.80440899974778,
            21.02823843416404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80434868908553,
              21.028147449129815
            ],
            [
              105.80439874114295,
              21.028222913744937
            ],
            [
              105.80440899974778,
              21.02823843416404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1612",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028147449129815,
          "to_latitude": 21.02823843416404,
          "to_longitude": 105.80440899974778,
          "from_longitude": 105.80434868908553
        }
      },
      {
        "id": 1608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80122495077444,
            21.029144064738485,
            105.80129106066488,
            21.02919406016437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80122495077444,
              21.02919406016437
            ],
            [
              105.80125654502662,
              21.029170169018574
            ],
            [
              105.80129106066488,
              21.029144064738485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1613",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02919406016437,
          "to_latitude": 21.029144064738485,
          "to_longitude": 105.80129106066488,
          "from_longitude": 105.80122495077444
        }
      },
      {
        "id": 1609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80330992638655,
            21.02865678097626,
            105.80338683111046,
            21.028772803156908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80330992638655,
              21.02865678097626
            ],
            [
              105.80336901632224,
              21.0287458825987
            ],
            [
              105.80338683111046,
              21.028772803156908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_171_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1614",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02865678097626,
          "to_latitude": 21.028772803156908,
          "to_longitude": 105.80338683111046,
          "from_longitude": 105.80330992638655
        }
      },
      {
        "id": 1610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80267088196162,
            21.028966595649983,
            105.80277078980076,
            21.029118252087716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80267088196162,
              21.028966595649983
            ],
            [
              105.80276424774398,
              21.029108323974995
            ],
            [
              105.80277078980076,
              21.029118252087716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_169_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1615",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028966595649983,
          "to_latitude": 21.029118252087716,
          "to_longitude": 105.80277078980076,
          "from_longitude": 105.80267088196162
        }
      },
      {
        "id": 1611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80384995583027,
            21.02343673157796,
            105.80385694202326,
            21.023566601048607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80384995583027,
              21.023566601048607
            ],
            [
              105.80385650802364,
              21.02344477959271
            ],
            [
              105.80385694202326,
              21.02343673157796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_175_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1616",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023566601048607,
          "to_latitude": 21.02343673157796,
          "to_longitude": 105.80385694202326,
          "from_longitude": 105.80384995583027
        }
      },
      {
        "id": 1612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80055985244712,
            21.02841424120958,
            105.8006354846416,
            21.028477516226552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80055985244712,
              21.028477516226552
            ],
            [
              105.80057458545707,
              21.028465191853353
            ],
            [
              105.8006354846416,
              21.02841424120958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_166_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1617",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028477516226552,
          "to_latitude": 21.02841424120958,
          "to_longitude": 105.8006354846416,
          "from_longitude": 105.80055985244712
        }
      },
      {
        "id": 1613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80133367672116,
            21.021210900410427,
            105.80137288747385,
            21.021230976924286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80137288747385,
              21.021230976924286
            ],
            [
              105.80136715185654,
              21.0212280404506
            ],
            [
              105.80136287281118,
              21.021225849504606
            ],
            [
              105.80133367672116,
              21.021210900410427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D25_Q55_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1618",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 25,
          "chieudaiQL": 4.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021230976924286,
          "to_latitude": 21.021210900410427,
          "to_longitude": 105.80133367672116,
          "from_longitude": 105.80137288747385
        }
      },
      {
        "id": 1614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80092806857812,
            21.02878920274473,
            105.80103736075067,
            21.02888118122743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80092806857812,
              21.02888118122743
            ],
            [
              105.80094217293403,
              21.02886931139819
            ],
            [
              105.80098118664186,
              21.028836478615926
            ],
            [
              105.80098976685271,
              21.0288292566262
            ],
            [
              105.80103736075067,
              21.02878920274473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_167_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1619",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02888118122743,
          "to_latitude": 21.02878920274473,
          "to_longitude": 105.80103736075067,
          "from_longitude": 105.80092806857812
        }
      },
      {
        "id": 1615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80151402270181,
            21.02091449227984,
            105.8016297080601,
            21.02100828786858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80161681780044,
              21.02100828786858
            ],
            [
              105.8016297080601,
              21.020980532293976
            ],
            [
              105.80154242045506,
              21.0209316282126
            ],
            [
              105.8015374899992,
              21.020928867692852
            ],
            [
              105.80151402270181,
              21.02091449227984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1620",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02100828786858,
          "to_latitude": 21.02091449227984,
          "to_longitude": 105.80151402270181,
          "from_longitude": 105.80161681780044
        }
      },
      {
        "id": 1616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79909127209032,
            21.02531824022034,
            105.79913081818196,
            21.025326906462816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79913081818196,
              21.02531824022034
            ],
            [
              105.7991123480931,
              21.02532228767079
            ],
            [
              105.7990938780031,
              21.025326335119267
            ],
            [
              105.79909127209032,
              21.025326906462816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_158_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1621",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02531824022034,
          "to_latitude": 21.025326906462816,
          "to_longitude": 105.79909127209032,
          "from_longitude": 105.79913081818196
        }
      },
      {
        "id": 1617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80445455333542,
            21.023505525872952,
            105.80446463892757,
            21.023601975191465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80445455333542,
              21.023601975191465
            ],
            [
              105.80446418227345,
              21.023509883402408
            ],
            [
              105.80446463892757,
              21.023505525872952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_176_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1622",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023601975191465,
          "to_latitude": 21.023505525872952,
          "to_longitude": 105.80446463892757,
          "from_longitude": 105.80445455333542
        }
      },
      {
        "id": 1618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80308205642339,
            21.028718783688564,
            105.80318112149187,
            21.02886823546916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80308205642339,
              21.028718783688564
            ],
            [
              105.80315426757221,
              21.02882767909079
            ],
            [
              105.80318112149187,
              21.02886823546916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_170_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1623",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 90,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028718783688564,
          "to_latitude": 21.02886823546916,
          "to_longitude": 105.80318112149187,
          "from_longitude": 105.80308205642339
        }
      },
      {
        "id": 1619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80082886623183,
            21.027522564362613,
            105.8008338076997,
            21.02754265329224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80082886623183,
              21.027522564362613
            ],
            [
              105.80083280370239,
              21.0275385772317
            ],
            [
              105.8008338076997,
              21.02754265329224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_162_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1624",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027522564362613,
          "to_latitude": 21.02754265329224,
          "to_longitude": 105.8008338076997,
          "from_longitude": 105.80082886623183
        }
      },
      {
        "id": 1620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80041614614775,
            21.028274476250633,
            105.80046669563781,
            21.0283141220786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80041614614775,
              21.0283141220786
            ],
            [
              105.80043581605484,
              21.02829869627458
            ],
            [
              105.80046669563781,
              21.028274476250633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_165_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1625",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0283141220786,
          "to_latitude": 21.028274476250633,
          "to_longitude": 105.80046669563781,
          "from_longitude": 105.80041614614775
        }
      },
      {
        "id": 1621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79859066466089,
            21.02600595966708,
            105.79871330376241,
            21.026057754146315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79871330376241,
              21.02600595966708
            ],
            [
              105.79861790330067,
              21.02604625096127
            ],
            [
              105.79859066466089,
              21.026057754146315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_159_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1626",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02600595966708,
          "to_latitude": 21.026057754146315,
          "to_longitude": 105.79859066466089,
          "from_longitude": 105.79871330376241
        }
      },
      {
        "id": 1622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79854357574158,
            21.025934682956983,
            105.79860967253191,
            21.025962491255076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79860967253191,
              21.025934682956983
            ],
            [
              105.79855259937057,
              21.025958695061345
            ],
            [
              105.79854357574158,
              21.025962491255076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1627",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025934682956983,
          "to_latitude": 21.025962491255076,
          "to_longitude": 105.79854357574158,
          "from_longitude": 105.79860967253191
        }
      },
      {
        "id": 1623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80385838043944,
            21.023437210442513,
            105.803861113865,
            21.02356775891208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80385838043944,
              21.02356775891208
            ],
            [
              105.80386094521384,
              21.02344522829979
            ],
            [
              105.803861113865,
              21.023437210442513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_174_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1628",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02356775891208,
          "to_latitude": 21.023437210442513,
          "to_longitude": 105.803861113865,
          "from_longitude": 105.80385838043944
        }
      },
      {
        "id": 1624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80383485109365,
            21.026643758833607,
            105.80384098714752,
            21.02665709761288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80383485109365,
              21.026643758833607
            ],
            [
              105.80384098714752,
              21.02665709761288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1629",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026643758833607,
          "to_latitude": 21.02665709761288,
          "to_longitude": 105.80384098714752,
          "from_longitude": 105.80383485109365
        }
      },
      {
        "id": 1625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7995558577066,
            21.026497473221745,
            105.79956929750473,
            21.026530209902088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7995558577066,
              21.026497473221745
            ],
            [
              105.79955676356538,
              21.026500111811757
            ],
            [
              105.79956297355993,
              21.026515661514004
            ],
            [
              105.79956592012454,
              21.026523041495473
            ],
            [
              105.79956929750473,
              21.026530209902088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_157_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1630",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026497473221745,
          "to_latitude": 21.026530209902088,
          "to_longitude": 105.79956929750473,
          "from_longitude": 105.7995558577066
        }
      },
      {
        "id": 1626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120525011712,
            21.026383688410586,
            105.80120996814064,
            21.026395447902456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80120996814064,
              21.026395447902456
            ],
            [
              105.8012057298158,
              21.02638488383232
            ],
            [
              105.80120525011712,
              21.026383688410586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163+164_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1631",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026395447902456,
          "to_latitude": 21.026383688410586,
          "to_longitude": 105.80120525011712,
          "from_longitude": 105.80120996814064
        }
      },
      {
        "id": 1627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80165256697627,
            21.026205001797763,
            105.80165702705385,
            21.026214213580296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80165702705385,
              21.026214213580296
            ],
            [
              105.80165256697627,
              21.026205001797763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163+164_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1632",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026214213580296,
          "to_latitude": 21.026205001797763,
          "to_longitude": 105.80165256697627,
          "from_longitude": 105.80165702705385
        }
      },
      {
        "id": 1628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80127541852625,
            21.022586470092698,
            105.80129286024652,
            21.02259347737087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80129286024652,
              21.02259347737087
            ],
            [
              105.8012837402671,
              21.02258981348263
            ],
            [
              105.80127541852625,
              21.022586470092698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D25_Q52_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1633",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 25,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02259347737087,
          "to_latitude": 21.022586470092698,
          "to_longitude": 105.80127541852625,
          "from_longitude": 105.80129286024652
        }
      },
      {
        "id": 1629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8019999077413,
            21.023129941306404,
            105.80203028112143,
            21.02321042858377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80203028112143,
              21.023129941306404
            ],
            [
              105.80201415488372,
              21.02317267145027
            ],
            [
              105.8019999077413,
              21.02321042858377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_173_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1634",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023129941306404,
          "to_latitude": 21.02321042858377,
          "to_longitude": 105.8019999077413,
          "from_longitude": 105.80203028112143
        }
      },
      {
        "id": 1630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80478370806459,
            21.025759538253503,
            105.80484113285694,
            21.025884738487026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80484113285694,
              21.025884738487026
            ],
            [
              105.80482969539298,
              21.025859802088736
            ],
            [
              105.80479744728912,
              21.025789435132804
            ],
            [
              105.80478370806459,
              21.025759538253503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1635",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025884738487026,
          "to_latitude": 21.025759538253503,
          "to_longitude": 105.80478370806459,
          "from_longitude": 105.80484113285694
        }
      },
      {
        "id": 1631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80572800650488,
            21.023466179307324,
            105.80573708724329,
            21.02352051155498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80572800650488,
              21.023466179307324
            ],
            [
              105.80573352451039,
              21.023488917237543
            ],
            [
              105.80573708724329,
              21.02352051155498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1636",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023466179307324,
          "to_latitude": 21.02352051155498,
          "to_longitude": 105.80573708724329,
          "from_longitude": 105.80572800650488
        }
      },
      {
        "id": 1632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80535727271953,
            21.0254491535978,
            105.80542998240114,
            21.0257507657616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80542998240114,
              21.0257507657616
            ],
            [
              105.80535992246901,
              21.025460149598537
            ],
            [
              105.80535727271953,
              21.0254491535978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1637",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0257507657616,
          "to_latitude": 21.0254491535978,
          "to_longitude": 105.80535727271953,
          "from_longitude": 105.80542998240114
        }
      },
      {
        "id": 1633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80403896972312,
            21.02657124281351,
            105.80413261805448,
            21.02672821920665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80403896972312,
              21.02657124281351
            ],
            [
              105.80413261805448,
              21.02672821920665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1638",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02657124281351,
          "to_latitude": 21.02672821920665,
          "to_longitude": 105.80413261805448,
          "from_longitude": 105.80403896972312
        }
      },
      {
        "id": 1634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80032567266022,
            21.024633900811562,
            105.8003300406761,
            21.024757966376956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8003300406761,
              21.024633900811562
            ],
            [
              105.80032567266022,
              21.024757966376956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1639",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024633900811562,
          "to_latitude": 21.024757966376956,
          "to_longitude": 105.80032567266022,
          "from_longitude": 105.8003300406761
        }
      },
      {
        "id": 1635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80053577900156,
            21.0247957034729,
            105.80054354387518,
            21.024892914917242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80053577900156,
              21.0247957034729
            ],
            [
              105.80054354387518,
              21.024892914917242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1640",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0247957034729,
          "to_latitude": 21.024892914917242,
          "to_longitude": 105.80054354387518,
          "from_longitude": 105.80053577900156
        }
      },
      {
        "id": 1636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80027384447429,
            21.025156086376796,
            105.80029554386438,
            21.025240888820846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80029554386438,
              21.025156086376796
            ],
            [
              105.80028757134889,
              21.025172953766074
            ],
            [
              105.80027384447429,
              21.025240888820846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1641",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025156086376796,
          "to_latitude": 21.025240888820846,
          "to_longitude": 105.80027384447429,
          "from_longitude": 105.80029554386438
        }
      },
      {
        "id": 1637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7997932964348,
            21.025717901197922,
            105.80034002884656,
            21.025955357556008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80033165812989,
              21.025717901197922
            ],
            [
              105.80034002884656,
              21.025794720294527
            ],
            [
              105.80012101473757,
              21.0258202256701
            ],
            [
              105.80006069798705,
              21.025826031785368
            ],
            [
              105.80004735507345,
              21.02582677920449
            ],
            [
              105.80004637796749,
              21.025826834382052
            ],
            [
              105.80003908428041,
              21.025827243534643
            ],
            [
              105.80001754462683,
              21.02582845131991
            ],
            [
              105.8000000618359,
              21.0258316560263
            ],
            [
              105.79989414732442,
              21.025851075079633
            ],
            [
              105.79982858209114,
              21.025875321742923
            ],
            [
              105.79982099693238,
              21.02587952130425
            ],
            [
              105.79981277326615,
              21.025887737819506
            ],
            [
              105.7997932964348,
              21.025955357556008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_126_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1642",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025717901197922,
          "to_latitude": 21.025955357556008,
          "to_longitude": 105.7997932964348,
          "from_longitude": 105.80033165812989
        }
      },
      {
        "id": 1638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80136565224227,
            21.024460448215134,
            105.80140933927063,
            21.024681140607527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80139363628402,
              21.024460448215134
            ],
            [
              105.80137292535893,
              21.024599202334088
            ],
            [
              105.80136565224227,
              21.02467579883123
            ],
            [
              105.80140933927063,
              21.024681140607527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1643",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024460448215134,
          "to_latitude": 21.024681140607527,
          "to_longitude": 105.80140933927063,
          "from_longitude": 105.80139363628402
        }
      },
      {
        "id": 1639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8018770129377,
            21.025778086500758,
            105.80201081319733,
            21.025951130437697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8018770129377,
              21.025778086500758
            ],
            [
              105.80195905702536,
              21.025951130437697
            ],
            [
              105.80201081319733,
              21.025929391319963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1644",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025778086500758,
          "to_latitude": 21.025929391319963,
          "to_longitude": 105.80201081319733,
          "from_longitude": 105.8018770129377
        }
      },
      {
        "id": 1640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8010531562172,
            21.025741177070532,
            105.80216682955057,
            21.02586863216885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8010531562172,
              21.02586863216885
            ],
            [
              105.80106042486769,
              21.02586749870091
            ],
            [
              105.80113898619504,
              21.025855234906643
            ],
            [
              105.80122135346704,
              21.02584517870197
            ],
            [
              105.80142854084824,
              21.025841881147826
            ],
            [
              105.80157356987651,
              21.025818411869043
            ],
            [
              105.80164848849287,
              21.025808038307726
            ],
            [
              105.80174933708278,
              21.025794072264556
            ],
            [
              105.80175657519507,
              21.025793166524785
            ],
            [
              105.80180524809066,
              21.02578707238457
            ],
            [
              105.8018770129377,
              21.025778086500758
            ],
            [
              105.80188989810058,
              21.02577647399695
            ],
            [
              105.8020289738857,
              21.02575458686127
            ],
            [
              105.80212118654768,
              21.025741177070532
            ],
            [
              105.80216148472816,
              21.025744535781758
            ],
            [
              105.80216682955057,
              21.025743799729764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1645",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 118,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02586863216885,
          "to_latitude": 21.025743799729764,
          "to_longitude": 105.80216682955057,
          "from_longitude": 105.8010531562172
        }
      },
      {
        "id": 1641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80239000655435,
            21.026742938313486,
            105.80246568326277,
            21.02685739696533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80246568326277,
              21.02685739696533
            ],
            [
              105.8024556053739,
              21.026842153760104
            ],
            [
              105.80239000655435,
              21.026742938313486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1646",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02685739696533,
          "to_latitude": 21.026742938313486,
          "to_longitude": 105.80239000655435,
          "from_longitude": 105.80246568326277
        }
      },
      {
        "id": 1642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80198808347279,
            21.026942990037718,
            105.80208161242193,
            21.027087712256975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80208161242193,
              21.027087712256975
            ],
            [
              105.80208069378504,
              21.02708629039146
            ],
            [
              105.80198808347279,
              21.026942990037718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1648",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027087712256975,
          "to_latitude": 21.026942990037718,
          "to_longitude": 105.80198808347279,
          "from_longitude": 105.80208161242193
        }
      },
      {
        "id": 1643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80186925765088,
            21.02704179188293,
            105.80195362014857,
            21.02716469745026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80195362014857,
              21.02716469745026
            ],
            [
              105.80186925765088,
              21.02704179188293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1649",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02716469745026,
          "to_latitude": 21.02704179188293,
          "to_longitude": 105.80186925765088,
          "from_longitude": 105.80195362014857
        }
      },
      {
        "id": 1644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80182350390668,
            21.02863430069537,
            105.80205369378758,
            21.02876925538558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80182350390668,
              21.02863430069537
            ],
            [
              105.80193140811312,
              21.02876925538558
            ],
            [
              105.80205369378758,
              21.028693482138156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 3, Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1650",
          "diaChiLapD": "Ngõ 3, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02863430069537,
          "to_latitude": 21.028693482138156,
          "to_longitude": 105.80205369378758,
          "from_longitude": 105.80182350390668
        }
      },
      {
        "id": 1645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80191683911391,
            21.02847991063896,
            105.80206731708148,
            21.028685039530526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80206731708148,
              21.028685039530526
            ],
            [
              105.80191683911391,
              21.02847991063896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 3, Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1651",
          "diaChiLapD": "Ngõ 3, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028685039530526,
          "to_latitude": 21.02847991063896,
          "to_longitude": 105.80191683911391,
          "from_longitude": 105.80206731708148
        }
      },
      {
        "id": 1646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80205369378758,
            21.028693482138156,
            105.80260121884236,
            21.02919983912435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80260121884236,
              21.02919983912435
            ],
            [
              105.80259095657829,
              21.029185410615153
            ],
            [
              105.80240458320203,
              21.0289233233147
            ],
            [
              105.80227395090469,
              21.029001240715935
            ],
            [
              105.80205369378758,
              21.028693482138156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 3, Đê La Thành",
          "maTaiSan": "04.O9A.DODV.1652",
          "diaChiLapD": "Ngõ 3, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 165,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02919983912435,
          "to_latitude": 21.028693482138156,
          "to_longitude": 105.80205369378758,
          "from_longitude": 105.80260121884236
        }
      },
      {
        "id": 1647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80168181248277,
            21.028390112594106,
            105.80187482963689,
            21.028894309584683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80186051607933,
              21.028390112594106
            ],
            [
              105.80185789775639,
              21.02839196843085
            ],
            [
              105.80174064335883,
              21.02847506919335
            ],
            [
              105.80171681295022,
              21.028635493817486
            ],
            [
              105.80178376105688,
              21.028719258821386
            ],
            [
              105.80187482963689,
              21.028841952113897
            ],
            [
              105.8017993587158,
              21.028894309584683
            ],
            [
              105.80168181248277,
              21.02877021191833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_48_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1653",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028390112594106,
          "to_latitude": 21.02877021191833,
          "to_longitude": 105.80168181248277,
          "from_longitude": 105.80186051607933
        }
      },
      {
        "id": 1648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120882176915,
            21.02822906207076,
            105.80136337361876,
            21.028306054142632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80136337361876,
              21.02822906207076
            ],
            [
              105.80127560034023,
              21.028306054142632
            ],
            [
              105.80120882176915,
              21.028237720691834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1654",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02822906207076,
          "to_latitude": 21.028237720691834,
          "to_longitude": 105.80120882176915,
          "from_longitude": 105.80136337361876
        }
      },
      {
        "id": 1649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120420283727,
            21.027565707586746,
            105.80145433386798,
            21.02759709843681
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80120420283727,
              21.02759709843681
            ],
            [
              105.80136235070822,
              21.027565707586746
            ],
            [
              105.80138409263836,
              21.027571341533946
            ],
            [
              105.80145178164132,
              21.02756655244896
            ],
            [
              105.80145433386798,
              21.027566370613258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1655",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02759709843681,
          "to_latitude": 21.027566370613258,
          "to_longitude": 105.80145433386798,
          "from_longitude": 105.80120420283727
        }
      },
      {
        "id": 1650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79952635668366,
            21.027297051047444,
            105.79997889892631,
            21.027704022449093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79952635668366,
              21.027343433778917
            ],
            [
              105.79958793848755,
              21.027310815850996
            ],
            [
              105.79961781748926,
              21.027297051047444
            ],
            [
              105.79967889627996,
              21.027363106924383
            ],
            [
              105.79974645321033,
              21.02743616756351
            ],
            [
              105.79976633768092,
              21.027460785494625
            ],
            [
              105.79979653586156,
              21.027495301275444
            ],
            [
              105.7998108978848,
              21.027511716756187
            ],
            [
              105.79981407604075,
              21.027516338083657
            ],
            [
              105.79981867037411,
              21.027520975330958
            ],
            [
              105.79997889892631,
              21.027704022449093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1656",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027343433778917,
          "to_latitude": 21.027704022449093,
          "to_longitude": 105.79997889892631,
          "from_longitude": 105.79952635668366
        }
      },
      {
        "id": 1651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80005340583534,
            21.027832388956934,
            105.80008765070278,
            21.027850270528706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80008765070278,
              21.027832388956934
            ],
            [
              105.80007226564766,
              21.027840422404825
            ],
            [
              105.80005340583534,
              21.027850270528706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1657",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027832388956934,
          "to_latitude": 21.027850270528706,
          "to_longitude": 105.80005340583534,
          "from_longitude": 105.80008765070278
        }
      },
      {
        "id": 1652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80004154903101,
            21.027762148569,
            105.8002242745546,
            21.027981694756548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80004154903101,
              21.027762148569
            ],
            [
              105.80008765070278,
              21.027832388956934
            ],
            [
              105.80009589589513,
              21.02784495111224
            ],
            [
              105.80014619157866,
              21.027901409247207
            ],
            [
              105.8001582067974,
              21.027913763981584
            ],
            [
              105.8002242745546,
              21.027981694756548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1658",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027762148569,
          "to_latitude": 21.027981694756548,
          "to_longitude": 105.8002242745546,
          "from_longitude": 105.80004154903101
        }
      },
      {
        "id": 1653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80020592127975,
            21.028019535720592,
            105.80025628871907,
            21.028053993385065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80025628871907,
              21.028019535720592
            ],
            [
              105.80024152365594,
              21.02802963737045
            ],
            [
              105.80020592127975,
              21.028053993385065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1659",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028019535720592,
          "to_latitude": 21.028053993385065,
          "to_longitude": 105.80020592127975,
          "from_longitude": 105.80025628871907
        }
      },
      {
        "id": 1654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80025180266249,
            21.027947863279515,
            105.80044726758383,
            21.028223541365392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80044726758383,
              21.02796389124096
            ],
            [
              105.80042570798241,
              21.027970968362187
            ],
            [
              105.80040892431325,
              21.027947863279515
            ],
            [
              105.80025180266249,
              21.028014102231793
            ],
            [
              105.80025628871907,
              21.028019535720592
            ],
            [
              105.800277133911,
              21.02804478042181
            ],
            [
              105.80030663944143,
              21.0280805142061
            ],
            [
              105.80036398317297,
              21.02815014521166
            ],
            [
              105.80042735874878,
              21.028223541365392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1660",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02796389124096,
          "to_latitude": 21.028223541365392,
          "to_longitude": 105.80042735874878,
          "from_longitude": 105.80044726758383
        }
      },
      {
        "id": 1655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80070942650966,
            21.02857689502794,
            105.80097489000062,
            21.028679278709504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80097489000062,
              21.028679278709504
            ],
            [
              105.8009037417566,
              21.02857689502794
            ],
            [
              105.8007965207767,
              21.02863931995644
            ],
            [
              105.80077871285475,
              21.028599910134556
            ],
            [
              105.80070942650966,
              21.028643260812956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1661",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028679278709504,
          "to_latitude": 21.028643260812956,
          "to_longitude": 105.80070942650966,
          "from_longitude": 105.80097489000062
        }
      },
      {
        "id": 1656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79909320602509,
            21.026867754972663,
            105.79912884608339,
            21.026890440860882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79912884608339,
              21.026867754972663
            ],
            [
              105.79910909654602,
              21.026880325892687
            ],
            [
              105.79910298635474,
              21.026884215007335
            ],
            [
              105.79909921458763,
              21.02688661635341
            ],
            [
              105.79909320602509,
              21.026890440860882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1662",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026867754972663,
          "to_latitude": 21.026890440860882,
          "to_longitude": 105.79909320602509,
          "from_longitude": 105.79912884608339
        }
      },
      {
        "id": 1657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79904664178909,
            21.026737788085338,
            105.79932184284388,
            21.027120375370615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79904664178909,
              21.026737788085338
            ],
            [
              105.79912884608339,
              21.026867754972663
            ],
            [
              105.79913068848026,
              21.026870668261854
            ],
            [
              105.79919581222674,
              21.026966691629298
            ],
            [
              105.79925700659405,
              21.02704131335289
            ],
            [
              105.79932184284388,
              21.027120375370615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1663",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026737788085338,
          "to_latitude": 21.027120375370615,
          "to_longitude": 105.79932184284388,
          "from_longitude": 105.79904664178909
        }
      },
      {
        "id": 1658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79867646596799,
            21.02617795653428,
            105.79872074256396,
            21.026201962773325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79872074256396,
              21.02617795653428
            ],
            [
              105.79869931315011,
              21.026189576066255
            ],
            [
              105.79867646596799,
              21.026201962773325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1664",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02617795653428,
          "to_latitude": 21.026201962773325,
          "to_longitude": 105.79867646596799,
          "from_longitude": 105.79872074256396
        }
      },
      {
        "id": 1659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79888935519467,
            21.026539610901292,
            105.79892756090078,
            21.026559768918457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79892756090078,
              21.026539610901292
            ],
            [
              105.79890317414014,
              21.026552478164383
            ],
            [
              105.79889811854667,
              21.026555145667217
            ],
            [
              105.79889306295303,
              21.026557813169905
            ],
            [
              105.79888935519467,
              21.026559768918457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1665",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026539610901292,
          "to_latitude": 21.026559768918457,
          "to_longitude": 105.79888935519467,
          "from_longitude": 105.79892756090078
        }
      },
      {
        "id": 1660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79883677306425,
            21.02638457444343,
            105.79900254348631,
            21.026667657197358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79883677306425,
              21.02638457444343
            ],
            [
              105.79891965819533,
              21.026526115844256
            ],
            [
              105.79892756090078,
              21.026539610901292
            ],
            [
              105.79900254348631,
              21.026667657197358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1666",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02638457444343,
          "to_latitude": 21.026667657197358,
          "to_longitude": 105.79900254348631,
          "from_longitude": 105.79883677306425
        }
      },
      {
        "id": 1661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.798648494497,
            21.026045393749058,
            105.79881194688309,
            21.02634530393245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.798648494497,
              21.026045393749058
            ],
            [
              105.79872074256396,
              21.02617795653428
            ],
            [
              105.79877727563574,
              21.02628168704465
            ],
            [
              105.79881194688309,
              21.02634530393245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1667",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026045393749058,
          "to_latitude": 21.02634530393245,
          "to_longitude": 105.79881194688309,
          "from_longitude": 105.798648494497
        }
      },
      {
        "id": 1662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7984586853787,
            21.025744843035056,
            105.79849695961136,
            21.02576890683988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79849695961136,
              21.025744843035056
            ],
            [
              105.7984697355215,
              21.025761959703516
            ],
            [
              105.79846417161976,
              21.025765457841302
            ],
            [
              105.79845920408991,
              21.025768581048297
            ],
            [
              105.7984586853787,
              21.02576890683988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1668",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025744843035056,
          "to_latitude": 21.02576890683988,
          "to_longitude": 105.7984586853787,
          "from_longitude": 105.79849695961136
        }
      },
      {
        "id": 1663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79838749186266,
            21.02548871331709,
            105.79857804660374,
            21.02592296754884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79838749186266,
              21.02548871331709
            ],
            [
              105.79845035825545,
              21.02564247211364
            ],
            [
              105.79847788687675,
              21.025702945958113
            ],
            [
              105.79849695961136,
              21.025744843035056
            ],
            [
              105.79857804660374,
              21.02592296754884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1669",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02548871331709,
          "to_latitude": 21.02592296754884,
          "to_longitude": 105.79857804660374,
          "from_longitude": 105.79838749186266
        }
      },
      {
        "id": 1664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79855019706183,
            21.025229425078752,
            105.79860947024895,
            21.025433253239132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79858005273952,
              21.025433253239132
            ],
            [
              105.79855281135218,
              21.025317435374628
            ],
            [
              105.79855019706183,
              21.025287537200853
            ],
            [
              105.79856186624315,
              21.025272377698368
            ],
            [
              105.79860947024895,
              21.025229425078752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Ngõ 142, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1670",
          "diaChiLapD": "Ngõ 142, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025433253239132,
          "to_latitude": 21.025229425078752,
          "to_longitude": 105.79860947024895,
          "from_longitude": 105.79858005273952
        }
      },
      {
        "id": 1665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79833894932355,
            21.025392633992706,
            105.79907599656974,
            21.02555682904628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79833894932355,
              21.02547594372356
            ],
            [
              105.79834710363362,
              21.025474563245194
            ],
            [
              105.79846820906798,
              21.025454045056176
            ],
            [
              105.7985041788202,
              21.025455735018323
            ],
            [
              105.79858005273952,
              21.025433253239132
            ],
            [
              105.79859226921681,
              21.025429634439078
            ],
            [
              105.79864458440021,
              21.02555682904628
            ],
            [
              105.79874884875663,
              21.025521465681557
            ],
            [
              105.79880277345417,
              21.02550041373566
            ],
            [
              105.79885579547052,
              21.02547515244731
            ],
            [
              105.79887464249968,
              21.02544651644151
            ],
            [
              105.79907599656974,
              21.025392633992706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15_Ngõ 142, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1671",
          "diaChiLapD": "Ngõ 142, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02547594372356,
          "to_latitude": 21.025392633992706,
          "to_longitude": 105.79907599656974,
          "from_longitude": 105.79833894932355
        }
      },
      {
        "id": 1666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79828882894576,
            21.025306901353588,
            105.79832841563174,
            21.02531807045061
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79832841563174,
              21.025306901353588
            ],
            [
              105.79830448514929,
              21.025313653328283
            ],
            [
              105.79830100118954,
              21.025314636137995
            ],
            [
              105.79829751531054,
              21.02531561985988
            ],
            [
              105.79828882894576,
              21.02531807045061
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1672",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025306901353588,
          "to_latitude": 21.02531807045061,
          "to_longitude": 105.79828882894576,
          "from_longitude": 105.79832841563174
        }
      },
      {
        "id": 1667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79832300885417,
            21.02524487756531,
            105.79837722942924,
            21.0254546185111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79832300885417,
              21.02524487756531
            ],
            [
              105.79832841563174,
              21.025306901353588
            ],
            [
              105.7983293971808,
              21.02531815911669
            ],
            [
              105.7983404912217,
              21.02534981039953
            ],
            [
              105.79837722942924,
              21.0254546185111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1673",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02524487756531,
          "to_latitude": 21.0254546185111,
          "to_longitude": 105.79837722942924,
          "from_longitude": 105.79832300885417
        }
      },
      {
        "id": 1668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79869503217208,
            21.024888726281382,
            105.79872364692676,
            21.025188845810266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79869503217208,
              21.025188845810266
            ],
            [
              105.79871174126222,
              21.025001779836845
            ],
            [
              105.79871972624159,
              21.024970968398847
            ],
            [
              105.79872364692676,
              21.024888726281382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Ngõ 132, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1674",
          "diaChiLapD": "Ngõ 132, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025188845810266,
          "to_latitude": 21.024888726281382,
          "to_longitude": 105.79872364692676,
          "from_longitude": 105.79869503217208
        }
      },
      {
        "id": 1669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79827595589852,
            21.025188838412287,
            105.7987987306427,
            21.025243549394993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79827595589852,
              21.025243549394993
            ],
            [
              105.79828254981544,
              21.02524270914985
            ],
            [
              105.79860929583707,
              21.025201017519375
            ],
            [
              105.79864179288546,
              21.025188838412287
            ],
            [
              105.79869503217208,
              21.025188845810266
            ],
            [
              105.79874827145804,
              21.025188853191565
            ],
            [
              105.79875807998675,
              21.02523073052611
            ],
            [
              105.7987987306427,
              21.025238348918897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Ngõ 132, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1675",
          "diaChiLapD": "Ngõ 132, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025243549394993,
          "to_latitude": 21.025238348918897,
          "to_longitude": 105.7987987306427,
          "from_longitude": 105.79827595589852
        }
      },
      {
        "id": 1670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79823959865244,
            21.025017453100016,
            105.79827957742812,
            21.025019651340425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79827957742812,
              21.025017453100016
            ],
            [
              105.79825304929352,
              21.025018911688235
            ],
            [
              105.79824753670917,
              21.02501921575748
            ],
            [
              105.7982420241151,
              21.02501951802013
            ],
            [
              105.79823959865244,
              21.025019651340425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1676",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025017453100016,
          "to_latitude": 21.025019651340425,
          "to_longitude": 105.79823959865244,
          "from_longitude": 105.79827957742812
        }
      },
      {
        "id": 1671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79826913316083,
            21.024889172304203,
            105.79851045585909,
            21.02518763131398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79851045585909,
              21.024891477852925
            ],
            [
              105.79840966816765,
              21.024892988854315
            ],
            [
              105.79833894931542,
              21.024889172304203
            ],
            [
              105.79827067367926,
              21.024891447379833
            ],
            [
              105.79826969989196,
              21.024933318454547
            ],
            [
              105.79826913316083,
              21.024957688445948
            ],
            [
              105.79827812671876,
              21.024999565122506
            ],
            [
              105.79827957742812,
              21.025017453100016
            ],
            [
              105.798284732728,
              21.025081034337205
            ],
            [
              105.79830925231136,
              21.02518763131398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1677",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024891477852925,
          "to_latitude": 21.02518763131398,
          "to_longitude": 105.79830925231136,
          "from_longitude": 105.79851045585909
        }
      },
      {
        "id": 1672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7982288095012,
            21.024663109591646,
            105.7982675099127,
            21.02466547144097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7982675099127,
              21.02466547144097
            ],
            [
              105.79824032743443,
              21.024663812438103
            ],
            [
              105.79823603263043,
              21.02466355078841
            ],
            [
              105.79823173782165,
              21.024663288235413
            ],
            [
              105.7982288095012,
              21.024663109591646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1678",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02466547144097,
          "to_latitude": 21.024663109591646,
          "to_longitude": 105.7982288095012,
          "from_longitude": 105.7982675099127
        }
      },
      {
        "id": 1673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79826071320873,
            21.024520892910303,
            105.79830722493024,
            21.024871173940976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79830722493024,
              21.024520892910303
            ],
            [
              105.7982695510669,
              21.024640859688464
            ],
            [
              105.7982675099127,
              21.02466547144097
            ],
            [
              105.79826701327217,
              21.024671567863148
            ],
            [
              105.79826071320873,
              21.024747829120912
            ],
            [
              105.79826249453238,
              21.024871173940976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1679",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024520892910303,
          "to_latitude": 21.024871173940976,
          "to_longitude": 105.79826249453238,
          "from_longitude": 105.79830722493024
        }
      },
      {
        "id": 1674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79870340030294,
            21.024483815186056,
            105.79873663068253,
            21.024588908473515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79870340030294,
              21.024588908473515
            ],
            [
              105.79873663068253,
              21.024483815186056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 1096, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1680",
          "diaChiLapD": "Ngõ 1096, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024588908473515,
          "to_latitude": 21.024483815186056,
          "to_longitude": 105.79873663068253,
          "from_longitude": 105.79870340030294
        }
      },
      {
        "id": 1675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79884803445172,
            21.024538739823388,
            105.79899359391355,
            21.02465575822861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79884803445172,
              21.02465575822861
            ],
            [
              105.79887332818953,
              21.024538739823388
            ],
            [
              105.79899359391355,
              21.024570568285668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 1096, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1681",
          "diaChiLapD": "Ngõ 1096, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02465575822861,
          "to_latitude": 21.024570568285668,
          "to_longitude": 105.79899359391355,
          "from_longitude": 105.79884803445172
        }
      },
      {
        "id": 1676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7982809448033,
            21.02447207205181,
            105.79901129604833,
            21.02469861727481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7982809448033,
              21.02447207205181
            ],
            [
              105.79829080264844,
              21.024474645147148
            ],
            [
              105.79856945696393,
              21.024547339470168
            ],
            [
              105.79870340030294,
              21.024588908473515
            ],
            [
              105.79875337998556,
              21.024604419749494
            ],
            [
              105.79874374436092,
              21.02463579623471
            ],
            [
              105.79884803445172,
              21.02465575822861
            ],
            [
              105.79891871741324,
              21.02466928716664
            ],
            [
              105.79901129604833,
              21.02469861727481
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10_Ngõ 1096, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1682",
          "diaChiLapD": "Ngõ 1096, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02447207205181,
          "to_latitude": 21.02469861727481,
          "to_longitude": 105.79901129604833,
          "from_longitude": 105.7982809448033
        }
      },
      {
        "id": 1677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79839921997323,
            21.024252255118732,
            105.79843424538532,
            21.024268334374092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79843424538532,
              21.024268334374092
            ],
            [
              105.79841068713095,
              21.024257519503205
            ],
            [
              105.79840471584332,
              21.024254779136182
            ],
            [
              105.79839921997323,
              21.024252255118732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1683",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024268334374092,
          "to_latitude": 21.024252255118732,
          "to_longitude": 105.79839921997323,
          "from_longitude": 105.79843424538532
        }
      },
      {
        "id": 1678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79833673668706,
            21.02412604249405,
            105.79854096968845,
            21.024452873052024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79854096968845,
              21.02412604249405
            ],
            [
              105.79843424538532,
              21.024268334374092
            ],
            [
              105.79842152668381,
              21.024285292609157
            ],
            [
              105.79841499586718,
              21.02429399969358
            ],
            [
              105.79833673668706,
              21.024452873052024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1684",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02412604249405,
          "to_latitude": 21.024452873052024,
          "to_longitude": 105.79833673668706,
          "from_longitude": 105.79854096968845
        }
      },
      {
        "id": 1679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7989449230853,
            21.024095903454043,
            105.79924994069312,
            21.024296467119473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7989449230853,
              21.024296467119473
            ],
            [
              105.79903888567165,
              21.02414707137498
            ],
            [
              105.79915259940496,
              21.024207502379713
            ],
            [
              105.79924994069312,
              21.024095903454043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Ngõ 1074, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1685",
          "diaChiLapD": "Ngõ 1074, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024296467119473,
          "to_latitude": 21.024095903454043,
          "to_longitude": 105.79924994069312,
          "from_longitude": 105.7989449230853
        }
      },
      {
        "id": 1680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79887175017703,
            21.024159214088137,
            105.79892955296579,
            21.024266240133723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79887175017703,
              21.024266240133723
            ],
            [
              105.79892955296579,
              21.024159214088137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Ngõ 1074, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1686",
          "diaChiLapD": "Ngõ 1074, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024266240133723,
          "to_latitude": 21.024159214088137,
          "to_longitude": 105.79892955296579,
          "from_longitude": 105.79887175017703
        }
      },
      {
        "id": 1681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79851500884273,
            21.024097974584123,
            105.79917159834685,
            21.024399791081592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79851500884273,
              21.024097974584123
            ],
            [
              105.79852491655132,
              21.02410286963615
            ],
            [
              105.79866453785075,
              21.02417183227253
            ],
            [
              105.79880388767478,
              21.024238207408978
            ],
            [
              105.79887175017703,
              21.024266240133723
            ],
            [
              105.7989449230853,
              21.024296467119473
            ],
            [
              105.79904167456866,
              21.024336433015993
            ],
            [
              105.79917159834685,
              21.024399791081592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Ngõ 1074, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1687",
          "diaChiLapD": "Ngõ 1074, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024097974584123,
          "to_latitude": 21.024399791081592,
          "to_longitude": 105.79917159834685,
          "from_longitude": 105.79851500884273
        }
      },
      {
        "id": 1682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79888390411885,
            21.023764343196472,
            105.798912369843,
            21.02379364393505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.798912369843,
              21.02379364393505
            ],
            [
              105.79889003099962,
              21.023770649670478
            ],
            [
              105.79888724711847,
              21.023767785960317
            ],
            [
              105.7988844651518,
              21.023764920434676
            ],
            [
              105.79888390411885,
              21.023764343196472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1688",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02379364393505,
          "to_latitude": 21.023764343196472,
          "to_longitude": 105.79888390411885,
          "from_longitude": 105.798912369843
        }
      },
      {
        "id": 1683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79857221478933,
            21.02371014747292,
            105.79901218018803,
            21.024105343697514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79901218018803,
              21.02371014747292
            ],
            [
              105.798912369843,
              21.02379364393505
            ],
            [
              105.79884572835505,
              21.02384939359102
            ],
            [
              105.79878131273219,
              21.023906380128082
            ],
            [
              105.79864537487039,
              21.024026636853538
            ],
            [
              105.79857221478933,
              21.024105343697514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1689",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02371014747292,
          "to_latitude": 21.024105343697514,
          "to_longitude": 105.79857221478933,
          "from_longitude": 105.79901218018803
        }
      },
      {
        "id": 1684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79912730963903,
            21.023555436087996,
            105.7991577133155,
            21.023586994786868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7991577133155,
              21.023586994786868
            ],
            [
              105.79915754499399,
              21.023586819447992
            ],
            [
              105.79915585311613,
              21.02358506519657
            ],
            [
              105.7991541631526,
              21.0235833091297
            ],
            [
              105.79912730963903,
              21.023555436087996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1690",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023586994786868,
          "to_latitude": 21.023555436087996,
          "to_longitude": 105.79912730963903,
          "from_longitude": 105.7991577133155
        }
      },
      {
        "id": 1685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79944471322149,
            21.023287057228593,
            105.79947383979992,
            21.023316890383168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79947383979992,
              21.023316890383168
            ],
            [
              105.79947005581887,
              21.023313014346915
            ],
            [
              105.79946744998779,
              21.023310346713313
            ],
            [
              105.799464846076,
              21.023307678167434
            ],
            [
              105.79944471322149,
              21.023287057228593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1691",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023316890383168,
          "to_latitude": 21.023287057228593,
          "to_longitude": 105.79944471322149,
          "from_longitude": 105.79947383979992
        }
      },
      {
        "id": 1686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8006990555903,
            21.023700524389657,
            105.80071726246665,
            21.023758496274798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80071726246665,
              21.023700524389657
            ],
            [
              105.8006990555903,
              21.023758496274798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1692",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023700524389657,
          "to_latitude": 21.023758496274798,
          "to_longitude": 105.8006990555903,
          "from_longitude": 105.80071726246665
        }
      },
      {
        "id": 1687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80041939908865,
            21.02357548701845,
            105.80077413293252,
            21.023706693048048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80041939908865,
              21.023588069869547
            ],
            [
              105.80043877477037,
              21.02357548701845
            ],
            [
              105.80055232928565,
              21.023580677225116
            ],
            [
              105.80063966730192,
              21.023692108491716
            ],
            [
              105.80071726246665,
              21.023700524389657
            ],
            [
              105.80077413293252,
              21.023706693048048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1693",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023588069869547,
          "to_latitude": 21.023706693048048,
          "to_longitude": 105.80077413293252,
          "from_longitude": 105.80041939908865
        }
      },
      {
        "id": 1688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79987104427788,
            21.02293578887383,
            105.80061522767515,
            21.02323758880373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79987104427788,
              21.022949531849548
            ],
            [
              105.7998785473647,
              21.02295864718303
            ],
            [
              105.79988067031859,
              21.02296132250072
            ],
            [
              105.79991336777466,
              21.02293578887383
            ],
            [
              105.79994806315058,
              21.022956031458154
            ],
            [
              105.8001066707656,
              21.02306158649486
            ],
            [
              105.80017944631862,
              21.023099442605975
            ],
            [
              105.80019009722892,
              21.02310498348738
            ],
            [
              105.80031401035774,
              21.023162307409983
            ],
            [
              105.80039055197015,
              21.02314227217598
            ],
            [
              105.8004231952018,
              21.023152001692896
            ],
            [
              105.8004758898615,
              21.023182573693934
            ],
            [
              105.80052532419897,
              21.023212756376736
            ],
            [
              105.80056124179673,
              21.02323468573071
            ],
            [
              105.80059672111523,
              21.02323758880373
            ],
            [
              105.80061522767515,
              21.023224573764924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_02_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1694",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022949531849548,
          "to_latitude": 21.023224573764924,
          "to_longitude": 105.80061522767515,
          "from_longitude": 105.79987104427788
        }
      },
      {
        "id": 1689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80130754701517,
            21.023027851602308,
            105.80179006417478,
            21.023348806815488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80130754701517,
              21.023027851602308
            ],
            [
              105.80164995286026,
              21.02314227052655
            ],
            [
              105.80179006417478,
              21.023189090129
            ],
            [
              105.80178536471978,
              21.02321910852873
            ],
            [
              105.80176505992337,
              21.023348806815488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1695",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023027851602308,
          "to_latitude": 21.023348806815488,
          "to_longitude": 105.80176505992337,
          "from_longitude": 105.80130754701517
        }
      },
      {
        "id": 1690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80447845270423,
            21.022829150750812,
            105.80448644223848,
            21.02297585423784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80447845270423,
              21.022829150750812
            ],
            [
              105.80448644223848,
              21.02297585423784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_146_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1696",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022829150750812,
          "to_latitude": 21.02297585423784,
          "to_longitude": 105.80448644223848,
          "from_longitude": 105.80447845270423
        }
      },
      {
        "id": 1691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80449263279864,
            21.02300726003144,
            105.80449956351111,
            21.023140931316956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80449956351111,
              21.023140931316956
            ],
            [
              105.80449944013459,
              21.02313855550535
            ],
            [
              105.80449263279864,
              21.02300726003144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_144_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1697",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023140931316956,
          "to_latitude": 21.02300726003144,
          "to_longitude": 105.80449263279864,
          "from_longitude": 105.80449956351111
        }
      },
      {
        "id": 1692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80449059037433,
            21.023170571667748,
            105.80450023028702,
            21.023302132838342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80450023028702,
              21.023302132838342
            ],
            [
              105.80449059037433,
              21.023170571667748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1698",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023302132838342,
          "to_latitude": 21.023170571667748,
          "to_longitude": 105.80449059037433,
          "from_longitude": 105.80450023028702
        }
      },
      {
        "id": 1693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538526986129,
            21.024557885717247,
            105.80544727365046,
            21.024562531108195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80544727365046,
              21.024557885717247
            ],
            [
              105.80542311506807,
              21.02455969548944
            ],
            [
              105.80541131993581,
              21.024560579609147
            ],
            [
              105.80539952576045,
              21.024561462820266
            ],
            [
              105.80538526986129,
              21.024562531108195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1699",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024557885717247,
          "to_latitude": 21.024562531108195,
          "to_longitude": 105.80538526986129,
          "from_longitude": 105.80544727365046
        }
      },
      {
        "id": 1694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8054386839463,
            21.02434013540498,
            105.80545043876549,
            21.02463813252109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80545043876549,
              21.02463813252109
            ],
            [
              105.80544727365046,
              21.024557885717247
            ],
            [
              105.80544456182832,
              21.024489133962348
            ],
            [
              105.8054386839463,
              21.02434013540498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1700",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463813252109,
          "to_latitude": 21.02434013540498,
          "to_longitude": 105.8054386839463,
          "from_longitude": 105.80545043876549
        }
      },
      {
        "id": 1695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8053881876773,
            21.02447767316181,
            105.80629908100205,
            21.0246703573135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8053881876773,
              21.02466300779227
            ],
            [
              105.80540971685197,
              21.024663473967614
            ],
            [
              105.80572800839342,
              21.0246703573135
            ],
            [
              105.80598843670853,
              21.024662583928798
            ],
            [
              105.8060299172529,
              21.024661345918673
            ],
            [
              105.80629908100205,
              21.024653312076836
            ],
            [
              105.80628875596965,
              21.02447767316181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_07_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1701",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 130,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02466300779227,
          "to_latitude": 21.02447767316181,
          "to_longitude": 105.80628875596965,
          "from_longitude": 105.8053881876773
        }
      },
      {
        "id": 1696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80136103377728,
            21.02718199663929,
            105.80137966869438,
            21.027189072738054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80136103377728,
              21.027189072738054
            ],
            [
              105.80136588595832,
              21.027187229866186
            ],
            [
              105.80137460817657,
              21.027183918171012
            ],
            [
              105.80137966869438,
              21.02718199663929
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1702",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027189072738054,
          "to_latitude": 21.02718199663929,
          "to_longitude": 105.80137966869438,
          "from_longitude": 105.80136103377728
        }
      },
      {
        "id": 1697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80126938584355,
            21.02695843603593,
            105.80142234751122,
            21.027366061442315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80126938584355,
              21.02695843603593
            ],
            [
              105.80134569002593,
              21.02715045884289
            ],
            [
              105.80136103377728,
              21.027189072738054
            ],
            [
              105.8013803980086,
              21.027237801913902
            ],
            [
              105.80142234751122,
              21.027366061442315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1703",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02695843603593,
          "to_latitude": 21.027366061442315,
          "to_longitude": 105.80142234751122,
          "from_longitude": 105.80126938584355
        }
      },
      {
        "id": 1698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80119257616842,
            21.02667323394168,
            105.80126206099804,
            21.02693592685612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80119257616842,
              21.02667323394168
            ],
            [
              105.80121474271411,
              21.026737280446568
            ],
            [
              105.80123333744285,
              21.02680350971974
            ],
            [
              105.80126206099804,
              21.02693592685612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1704",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02667323394168,
          "to_latitude": 21.02693592685612,
          "to_longitude": 105.80126206099804,
          "from_longitude": 105.80119257616842
        }
      },
      {
        "id": 1699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80082353985752,
            21.026507768072705,
            105.80118829978109,
            21.026580807842517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80118829978109,
              21.026507768072705
            ],
            [
              105.80117592358681,
              21.02651012600097
            ],
            [
              105.8010533408725,
              21.02653997350911
            ],
            [
              105.80082353985752,
              21.026580807842517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1705",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026507768072705,
          "to_latitude": 21.026580807842517,
          "to_longitude": 105.80082353985752,
          "from_longitude": 105.80118829978109
        }
      },
      {
        "id": 1700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80118409694242,
            21.026380643388705,
            105.80120050747995,
            21.026383781746418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80118409694242,
              21.026383781746418
            ],
            [
              105.80119907170446,
              21.02638091841212
            ],
            [
              105.80120050747995,
              21.026380643388705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1706",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026383781746418,
          "to_latitude": 21.026380643388705,
          "to_longitude": 105.80120050747995,
          "from_longitude": 105.80118409694242
        }
      },
      {
        "id": 1701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80113497792368,
            21.026214449046634,
            105.80118644125743,
            21.02649254442328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80113497792368,
              21.026214449046634
            ],
            [
              105.80117944750234,
              21.026367754173183
            ],
            [
              105.80118409694242,
              21.026383781746418
            ],
            [
              105.80118644125743,
              21.02639186182799
            ],
            [
              105.8011746290331,
              21.02649254442328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1707",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026214449046634,
          "to_latitude": 21.02649254442328,
          "to_longitude": 105.8011746290331,
          "from_longitude": 105.80113497792368
        }
      },
      {
        "id": 1702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80111861045604,
            21.026095976543953,
            105.80112529614371,
            21.026097649206193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80112529614371,
              21.026095976543953
            ],
            [
              105.80112278288607,
              21.02609660528893
            ],
            [
              105.80111957790619,
              21.026097407099485
            ],
            [
              105.80111861045604,
              21.026097649206193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1708",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026095976543953,
          "to_latitude": 21.026097649206193,
          "to_longitude": 105.80111861045604,
          "from_longitude": 105.80112529614371
        }
      },
      {
        "id": 1703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80107004901483,
            21.025888242992668,
            105.80119635339926,
            21.02635074168986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80107004901483,
              21.025888242992668
            ],
            [
              105.80111096917123,
              21.026036433622068
            ],
            [
              105.80112529614371,
              21.026095976543953
            ],
            [
              105.801126857533,
              21.026102466115635
            ],
            [
              105.80113643148816,
              21.026142256128093
            ],
            [
              105.80117262940497,
              21.026271390072374
            ],
            [
              105.80119635339926,
              21.02635074168986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1709",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025888242992668,
          "to_latitude": 21.02635074168986,
          "to_longitude": 105.80119635339926,
          "from_longitude": 105.80107004901483
        }
      },
      {
        "id": 1704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80109535086842,
            21.0260377608433,
            105.80110149373681,
            21.026039299076075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80109535086842,
              21.026039299076075
            ],
            [
              105.80109637980317,
              21.026039041324974
            ],
            [
              105.80109857409599,
              21.026038491760644
            ],
            [
              105.80110149373681,
              21.0260377608433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1710",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026039299076075,
          "to_latitude": 21.0260377608433,
          "to_longitude": 105.80110149373681,
          "from_longitude": 105.80109535086842
        }
      },
      {
        "id": 1705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80137079737301,
            21.02530851623007,
            105.80164848849287,
            21.025808038307726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80164848849287,
              21.025808038307726
            ],
            [
              105.80160032195667,
              21.02554900247186
            ],
            [
              105.80159373930555,
              21.02530851623007
            ],
            [
              105.80137079737301,
              21.025323589537333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1711",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025808038307726,
          "to_latitude": 21.025323589537333,
          "to_longitude": 105.80137079737301,
          "from_longitude": 105.80164848849287
        }
      },
      {
        "id": 1706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80178060997794,
            21.025661674029315,
            105.80180524809066,
            21.02578707238457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80180524809066,
              21.02578707238457
            ],
            [
              105.80178060997794,
              21.025661674029315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1712",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02578707238457,
          "to_latitude": 21.025661674029315,
          "to_longitude": 105.80178060997794,
          "from_longitude": 105.80180524809066
        }
      },
      {
        "id": 1707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80166188657387,
            21.025793166524785,
            105.80178272982378,
            21.02594767849283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80175657519507,
              21.025793166524785
            ],
            [
              105.80178272982378,
              21.02591665824111
            ],
            [
              105.80177826255046,
              21.02592336861692
            ],
            [
              105.80166188657387,
              21.02594767849283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1713",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025793166524785,
          "to_latitude": 21.02594767849283,
          "to_longitude": 105.80166188657387,
          "from_longitude": 105.80175657519507
        }
      },
      {
        "id": 1708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80100632011325,
            21.025745342509612,
            105.80113053082671,
            21.02618533814775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80113053082671,
              21.02618533814775
            ],
            [
              105.80110890189708,
              21.026077134736088
            ],
            [
              105.80109992073865,
              21.026057002517604
            ],
            [
              105.80109535086842,
              21.026039299076075
            ],
            [
              105.80107782488344,
              21.025971409124367
            ],
            [
              105.80107112235568,
              21.025945444858436
            ],
            [
              105.80100632011325,
              21.025745342509612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1714",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02618533814775,
          "to_latitude": 21.025745342509612,
          "to_longitude": 105.80100632011325,
          "from_longitude": 105.80113053082671
        }
      },
      {
        "id": 1709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80083683399268,
            21.0262003381607,
            105.80114795988287,
            21.026219492496644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80114795988287,
              21.0262003381607
            ],
            [
              105.80113335366862,
              21.026207537060092
            ],
            [
              105.80097375362267,
              21.026219492496644
            ],
            [
              105.80083683399268,
              21.02621651627999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1715",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0262003381607,
          "to_latitude": 21.02621651627999,
          "to_longitude": 105.80083683399268,
          "from_longitude": 105.80114795988287
        }
      },
      {
        "id": 1710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80098292323406,
            21.025665302930456,
            105.80099959227705,
            21.025667218608977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80098292323406,
              21.025667218608977
            ],
            [
              105.80098561616515,
              21.02566690966635
            ],
            [
              105.80099233646332,
              21.025666136462995
            ],
            [
              105.80099959227705,
              21.025665302930456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1716",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025667218608977,
          "to_latitude": 21.025665302930456,
          "to_longitude": 105.80099959227705,
          "from_longitude": 105.80098292323406
        }
      },
      {
        "id": 1711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80097948365366,
            21.025508304882283,
            105.80098666968992,
            21.025699764718095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80098666968992,
              21.025508304882283
            ],
            [
              105.80098348407678,
              21.025597355705155
            ],
            [
              105.80097948365366,
              21.025618347528713
            ],
            [
              105.80098292323406,
              21.025667218608977
            ],
            [
              105.80098521353892,
              21.025699764718095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1717",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025508304882283,
          "to_latitude": 21.025699764718095,
          "to_longitude": 105.80098521353892,
          "from_longitude": 105.80098666968992
        }
      },
      {
        "id": 1712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80077469738745,
            21.02548776561578,
            105.80099984227425,
            21.025496930797697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80099984227425,
              21.025496930797697
            ],
            [
              105.80098660560516,
              21.025495770169133
            ],
            [
              105.80077469738745,
              21.02548776561578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1718",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025496930797697,
          "to_latitude": 21.02548776561578,
          "to_longitude": 105.80077469738745,
          "from_longitude": 105.80099984227425
        }
      },
      {
        "id": 1713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8010056513258,
            21.025300399598045,
            105.80102255060403,
            21.025302151751028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8010056513258,
              21.025300399598045
            ],
            [
              105.80100883444356,
              21.025300730542433
            ],
            [
              105.80101345077061,
              21.025301208285295
            ],
            [
              105.80102255060403,
              21.025302151751028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1719",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025300399598045,
          "to_latitude": 21.025302151751028,
          "to_longitude": 105.80102255060403,
          "from_longitude": 105.8010056513258
        }
      },
      {
        "id": 1714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80098649872751,
            21.025055349649776,
            105.80104309731779,
            21.02548134790844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80104309731779,
              21.025055349649776
            ],
            [
              105.80101314020263,
              21.025245242052037
            ],
            [
              105.8010056513258,
              21.025300399598045
            ],
            [
              105.80099507930235,
              21.025378259879762
            ],
            [
              105.80098649872751,
              21.02548134790844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1720",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025055349649776,
          "to_latitude": 21.02548134790844,
          "to_longitude": 105.80098649872751,
          "from_longitude": 105.80104309731779
        }
      },
      {
        "id": 1715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80101926028604,
            21.025330370581162,
            105.80102829562995,
            21.025331307124443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80102829562995,
              21.025331307124443
            ],
            [
              105.80102637825445,
              21.02533110841276
            ],
            [
              105.80102192363084,
              21.025330647070003
            ],
            [
              105.80101926028604,
              21.025330370581162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1721",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025331307124443,
          "to_latitude": 21.025330370581162,
          "to_longitude": 105.80101926028604,
          "from_longitude": 105.80102829562995
        }
      },
      {
        "id": 1716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8010180306508,
            21.024980490496777,
            105.80106926496767,
            21.025421079554196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80106926496767,
              21.024980490496777
            ],
            [
              105.80103978956443,
              21.025230822928872
            ],
            [
              105.80103502324846,
              21.025272491472183
            ],
            [
              105.80102829562995,
              21.025331307124443
            ],
            [
              105.80102046967554,
              21.025399717534043
            ],
            [
              105.8010180306508,
              21.025421079554196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1722",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024980490496777,
          "to_latitude": 21.025421079554196,
          "to_longitude": 105.8010180306508,
          "from_longitude": 105.80106926496767
        }
      },
      {
        "id": 1717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80084276712648,
            21.02497453099428,
            105.80105213843063,
            21.025035403504905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80105213843063,
              21.025035403504905
            ],
            [
              105.8010369080386,
              21.025032890200183
            ],
            [
              105.8008643013641,
              21.025004389704623
            ],
            [
              105.80084276712648,
              21.02497453099428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1723",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025035403504905,
          "to_latitude": 21.02497453099428,
          "to_longitude": 105.80084276712648,
          "from_longitude": 105.80105213843063
        }
      },
      {
        "id": 1718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80106900266011,
            21.024794566505054,
            105.80107685259063,
            21.024795286435886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80106900266011,
              21.024794566505054
            ],
            [
              105.80107087370907,
              21.02479473833779
            ],
            [
              105.80107478519695,
              21.024795096560222
            ],
            [
              105.80107685259063,
              21.024795286435886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1724",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024794566505054,
          "to_latitude": 21.024795286435886,
          "to_longitude": 105.80107685259063,
          "from_longitude": 105.80106900266011
        }
      },
      {
        "id": 1719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80104778133652,
            21.02463765217904,
            105.80108221618391,
            21.025003501196192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80108221618391,
              21.02463765217904
            ],
            [
              105.8010747968858,
              21.024737510219644
            ],
            [
              105.80106900266011,
              21.024794566505054
            ],
            [
              105.8010668239041,
              21.024816024849763
            ],
            [
              105.80104778133652,
              21.025003501196192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1725",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463765217904,
          "to_latitude": 21.025003501196192,
          "to_longitude": 105.80104778133652,
          "from_longitude": 105.80108221618391
        }
      },
      {
        "id": 1720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80041599446298,
            21.02450488366125,
            105.8004226032063,
            21.02463386352301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8004226032063,
              21.02463386352301
            ],
            [
              105.80041599446298,
              21.02450488366125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1726",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463386352301,
          "to_latitude": 21.02450488366125,
          "to_longitude": 105.80041599446298,
          "from_longitude": 105.8004226032063
        }
      },
      {
        "id": 1721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80019447297231,
            21.024603741492992,
            105.8010965650432,
            21.024667598499338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8010965650432,
              21.024603741492992
            ],
            [
              105.80107597282397,
              21.024603988424893
            ],
            [
              105.80102732321065,
              21.024623198030415
            ],
            [
              105.80078935682808,
              21.024610193075258
            ],
            [
              105.80072575712154,
              21.024638302958554
            ],
            [
              105.80060308618928,
              21.024652986206682
            ],
            [
              105.80053346630548,
              21.024667598499338
            ],
            [
              105.8004998326428,
              21.0246574726425
            ],
            [
              105.80045657810142,
              21.024633849039517
            ],
            [
              105.8004226032063,
              21.02463386352301
            ],
            [
              105.8003300406761,
              21.024633900811562
            ],
            [
              105.80019447297231,
              21.02463395704218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_73_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1727",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024603741492992,
          "to_latitude": 21.02463395704218,
          "to_longitude": 105.80019447297231,
          "from_longitude": 105.8010965650432
        }
      },
      {
        "id": 1722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80024768636449,
            21.02423880094027,
            105.80109742010497,
            21.02459543046559
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80109742010497,
              21.02459543046559
            ],
            [
              105.8010760079729,
              21.024592562408813
            ],
            [
              105.8007888029849,
              21.02458178641072
            ],
            [
              105.80077170613502,
              21.024581391812784
            ],
            [
              105.80077681942294,
              21.02424445054106
            ],
            [
              105.80055269488886,
              21.02423901415818
            ],
            [
              105.80054387992502,
              21.02423880094027
            ],
            [
              105.8005403480768,
              21.024292784619036
            ],
            [
              105.80038384140794,
              21.02429276161544
            ],
            [
              105.80024768636449,
              21.024313749373903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1728",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 119,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02459543046559,
          "to_latitude": 21.024313749373903,
          "to_longitude": 105.80024768636449,
          "from_longitude": 105.80109742010497
        }
      },
      {
        "id": 1723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80108441384412,
            21.02472181557047,
            105.80109592501746,
            21.024722871417506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80109592501746,
              21.024722871417506
            ],
            [
              105.8010926920773,
              21.024722575935016
            ],
            [
              105.80108693359749,
              21.024722046670277
            ],
            [
              105.80108441384412,
              21.02472181557047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1729",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024722871417506,
          "to_latitude": 21.02472181557047,
          "to_longitude": 105.80108441384412,
          "from_longitude": 105.80109592501746
        }
      },
      {
        "id": 1724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8010724692202,
            21.02444376454113,
            105.80112065969057,
            21.024948949993192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8010724692202,
              21.024948949993192
            ],
            [
              105.80108657725334,
              21.02481487141446
            ],
            [
              105.8010954762915,
              21.02472729122555
            ],
            [
              105.80109592501746,
              21.024722871417506
            ],
            [
              105.80110437531623,
              21.02463971013242
            ],
            [
              105.80111624211328,
              21.024512703551746
            ],
            [
              105.80112065969057,
              21.02444376454113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1730",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024948949993192,
          "to_latitude": 21.02444376454113,
          "to_longitude": 105.80112065969057,
          "from_longitude": 105.8010724692202
        }
      },
      {
        "id": 1725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80105857578835,
            21.02497146692038,
            105.8012790270631,
            21.024976408768453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80105857578835,
              21.02497285978393
            ],
            [
              105.80106864981089,
              21.02497266691286
            ],
            [
              105.80113107544199,
              21.02497146692038
            ],
            [
              105.8012790270631,
              21.024976408768453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1731",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02497285978393,
          "to_latitude": 21.024976408768453,
          "to_longitude": 105.8012790270631,
          "from_longitude": 105.80105857578835
        }
      },
      {
        "id": 1726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80163016327165,
            21.025090023223466,
            105.80214001545161,
            21.02511953030195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80163016327165,
              21.025090023223466
            ],
            [
              105.8017455240424,
              21.02510391753724
            ],
            [
              105.80182292202517,
              21.025112192933374
            ],
            [
              105.8019403675187,
              21.02511602163552
            ],
            [
              105.80214001545161,
              21.02511953030195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1732",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025090023223466,
          "to_latitude": 21.02511953030195,
          "to_longitude": 105.80214001545161,
          "from_longitude": 105.80163016327165
        }
      },
      {
        "id": 1727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80164898201039,
            21.024897524127752,
            105.80199803743581,
            21.024929486686716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80164898201039,
              21.024897524127752
            ],
            [
              105.80199803743581,
              21.024929486686716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1733",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024897524127752,
          "to_latitude": 21.024929486686716,
          "to_longitude": 105.80199803743581,
          "from_longitude": 105.80164898201039
        }
      },
      {
        "id": 1728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80151565406662,
            21.024885063846234,
            105.80164937406383,
            21.024892403680067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80164937406383,
              21.024892403680067
            ],
            [
              105.80151565406662,
              21.024885063846234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1734",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024892403680067,
          "to_latitude": 21.024885063846234,
          "to_longitude": 105.80151565406662,
          "from_longitude": 105.80164937406383
        }
      },
      {
        "id": 1729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80111249960584,
            21.02443594669046,
            105.80167649812938,
            21.025196152717765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80150002712143,
              21.025196152717765
            ],
            [
              105.80150607363282,
              21.025101562450665
            ],
            [
              105.8016136338024,
              21.02510615422767
            ],
            [
              105.80162478885131,
              21.025106630036955
            ],
            [
              105.80163016327165,
              21.025090023223466
            ],
            [
              105.8016355376909,
              21.02507341640982
            ],
            [
              105.80164898201039,
              21.024897524127752
            ],
            [
              105.80164937406383,
              21.024892403680067
            ],
            [
              105.8016530091232,
              21.024844842368637
            ],
            [
              105.80166205850175,
              21.024676234567345
            ],
            [
              105.80167649812938,
              21.024515216479944
            ],
            [
              105.80155466607336,
              21.024485597578124
            ],
            [
              105.80139363628402,
              21.024460448215134
            ],
            [
              105.80137316324057,
              21.02445725060777
            ],
            [
              105.80122359507214,
              21.024437739225707
            ],
            [
              105.8011200202084,
              21.02443606933008
            ],
            [
              105.80111249960584,
              21.02443594669046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_75_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1735",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025196152717765,
          "to_latitude": 21.02443594669046,
          "to_longitude": 105.80111249960584,
          "from_longitude": 105.80150002712143
        }
      },
      {
        "id": 1730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80209368954223,
            21.024544300230133,
            105.80210928799394,
            21.024788197786652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80210928799394,
              21.024544300230133
            ],
            [
              105.80209368954223,
              21.024788197786652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1736",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024544300230133,
          "to_latitude": 21.024788197786652,
          "to_longitude": 105.80209368954223,
          "from_longitude": 105.80210928799394
        }
      },
      {
        "id": 1731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8018902020538,
            21.024535268844858,
            105.80191564591159,
            21.024820179675213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80191564591159,
              21.024535268844858
            ],
            [
              105.8018902020538,
              21.024820179675213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1737",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024535268844858,
          "to_latitude": 21.024820179675213,
          "to_longitude": 105.8018902020538,
          "from_longitude": 105.80191564591159
        }
      },
      {
        "id": 1732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8011128139505,
            21.024419813376927,
            105.80221478380926,
            21.024548264597207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8011128139505,
              21.024432483131847
            ],
            [
              105.80112048493153,
              21.024433023258275
            ],
            [
              105.80114985440181,
              21.024419813376927
            ],
            [
              105.80136214749987,
              21.024442333294818
            ],
            [
              105.80161544340986,
              21.024489941976963
            ],
            [
              105.80175136976642,
              21.02451848729134
            ],
            [
              105.80191564591159,
              21.024535268844858
            ],
            [
              105.80193133508145,
              21.02453687216342
            ],
            [
              105.80210928799394,
              21.024544300230133
            ],
            [
              105.802125976641,
              21.02454499649091
            ],
            [
              105.80215970617816,
              21.0245462374101
            ],
            [
              105.80221478380926,
              21.024548264597207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76_Ngõ 1194/141, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1738",
          "diaChiLapD": "Ngõ 1194/141, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 118,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024432483131847,
          "to_latitude": 21.024548264597207,
          "to_longitude": 105.80221478380926,
          "from_longitude": 105.8011128139505
        }
      },
      {
        "id": 1733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80113866827637,
            21.024148325532703,
            105.80115168686103,
            21.024149382405543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80115168686103,
              21.024149382405543
            ],
            [
              105.80115007096981,
              21.024149251823292
            ],
            [
              105.80114352750813,
              21.02414871993498
            ],
            [
              105.80113866827637,
              21.024148325532703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1739",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024149382405543,
          "to_latitude": 21.024148325532703,
          "to_longitude": 105.80113866827637,
          "from_longitude": 105.80115168686103
        }
      },
      {
        "id": 1734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80112937337347,
            21.023859761417526,
            105.80117767831456,
            21.024398016479733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80117767831456,
              21.023859761417526
            ],
            [
              105.80115168686103,
              21.024149382405543
            ],
            [
              105.80114660602258,
              21.024205992753142
            ],
            [
              105.80112937337347,
              21.024398016479733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1740",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023859761417526,
          "to_latitude": 21.024398016479733,
          "to_longitude": 105.80112937337347,
          "from_longitude": 105.80117767831456
        }
      },
      {
        "id": 1735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.801179552069,
            21.023698957894027,
            105.8015603099099,
            21.023718300301304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8015603099099,
              21.023718300301304
            ],
            [
              105.80119750006514,
              21.0236998705107
            ],
            [
              105.801179552069,
              21.023698957894027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78_Ngõ 1194/149, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1741",
          "diaChiLapD": "Ngõ 1194/149, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023718300301304,
          "to_latitude": 21.023698957894027,
          "to_longitude": 105.801179552069,
          "from_longitude": 105.8015603099099
        }
      },
      {
        "id": 1736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80119409452958,
            21.02352595257639,
            105.8016195741377,
            21.023560247597743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8016195741377,
              21.023560247597743
            ],
            [
              105.80121119259621,
              21.023527677586294
            ],
            [
              105.80119409452958,
              21.02352595257639
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79_Ngõ 1194/155, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1742",
          "diaChiLapD": "Ngõ 1194/155, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023560247597743,
          "to_latitude": 21.02352595257639,
          "to_longitude": 105.80119409452958,
          "from_longitude": 105.8016195741377
        }
      },
      {
        "id": 1737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80121170646288,
            21.023301959947343,
            105.80161750656052,
            21.02335058078534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80121170646288,
              21.023301959947343
            ],
            [
              105.80122698280059,
              21.02330518296349
            ],
            [
              105.8012886765714,
              21.023318194177968
            ],
            [
              105.80146001314093,
              21.02333439830038
            ],
            [
              105.80161750656052,
              21.02335058078534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_80_Ngõ 1194/159, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1743",
          "diaChiLapD": "Ngõ 1194/159, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023301959947343,
          "to_latitude": 21.02335058078534,
          "to_longitude": 105.80161750656052,
          "from_longitude": 105.80121170646288
        }
      },
      {
        "id": 1738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80121760372054,
            21.02322693702883,
            105.80123766686702,
            21.023228350003336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80123766686702,
              21.023228350003336
            ],
            [
              105.80123331510919,
              21.023228044438394
            ],
            [
              105.80122289377758,
              21.0232273095291
            ],
            [
              105.80121760372054,
              21.02322693702883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1744",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023228350003336,
          "to_latitude": 21.02322693702883,
          "to_longitude": 105.80121760372054,
          "from_longitude": 105.80123766686702
        }
      },
      {
        "id": 1739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80123308333525,
            21.023045354488534,
            105.80125422605067,
            21.023279002120308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80125422605067,
              21.023045354488534
            ],
            [
              105.80124365374238,
              21.023162179213003
            ],
            [
              105.80123766686702,
              21.023228350003336
            ],
            [
              105.80123308333525,
              21.023279002120308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1745",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023045354488534,
          "to_latitude": 21.023279002120308,
          "to_longitude": 105.80123308333525,
          "from_longitude": 105.80125422605067
        }
      },
      {
        "id": 1740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79899280870627,
            21.02583317942939,
            105.79899631969944,
            21.02584340465324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79899280870627,
              21.02583317942939
            ],
            [
              105.79899389923487,
              21.025836355480152
            ],
            [
              105.79899570116463,
              21.025841606519645
            ],
            [
              105.79899631969944,
              21.02584340465324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1746",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02583317942939,
          "to_latitude": 21.02584340465324,
          "to_longitude": 105.79899631969944,
          "from_longitude": 105.79899280870627
        }
      },
      {
        "id": 1741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79863178172212,
            21.02578167446669,
            105.79916679812938,
            21.02594322574746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79863178172212,
              21.02594322574746
            ],
            [
              105.7989099503089,
              21.025857796925315
            ],
            [
              105.79891806023845,
              21.025855306558626
            ],
            [
              105.79899280870627,
              21.02583317942939
            ],
            [
              105.79916679812938,
              21.02578167446669
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1747",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02594322574746,
          "to_latitude": 21.02578167446669,
          "to_longitude": 105.79916679812938,
          "from_longitude": 105.79863178172212
        }
      },
      {
        "id": 1742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79906876121377,
            21.025275542893212,
            105.79908080972268,
            21.025278182740948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79906876121377,
              21.025278182740948
            ],
            [
              105.79907479940256,
              21.02527686004203
            ],
            [
              105.79908080972268,
              21.025275542893212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1748",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025278182740948,
          "to_latitude": 21.025275542893212,
          "to_longitude": 105.79908080972268,
          "from_longitude": 105.79906876121377
        }
      },
      {
        "id": 1743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79901654800906,
            21.025044358826975,
            105.79909000131302,
            21.025371311585047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79909000131302,
              21.025371311585047
            ],
            [
              105.79907625896499,
              21.025314439275235
            ],
            [
              105.79906876121377,
              21.025278182740948
            ],
            [
              105.7990471092823,
              21.025173479134118
            ],
            [
              105.79904450413764,
              21.025164447336554
            ],
            [
              105.79901654800906,
              21.025044358826975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1749",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025371311585047,
          "to_latitude": 21.025044358826975,
          "to_longitude": 105.79901654800906,
          "from_longitude": 105.79909000131302
        }
      },
      {
        "id": 1744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79909826486443,
            21.02539746731649,
            105.79918907216971,
            21.02573415837551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79909826486443,
              21.02539746731649
            ],
            [
              105.79910024131718,
              21.025404237670443
            ],
            [
              105.79914607693001,
              21.025574942220377
            ],
            [
              105.79914758843614,
              21.025580573076258
            ],
            [
              105.79918907216971,
              21.02573415837551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1750",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02539746731649,
          "to_latitude": 21.02573415837551,
          "to_longitude": 105.79918907216971,
          "from_longitude": 105.79909826486443
        }
      },
      {
        "id": 1745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7991331196505,
            21.02540402057947,
            105.7994399003119,
            21.02550744334617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7994399003119,
              21.02540402057947
            ],
            [
              105.79913439971006,
              21.025507012813296
            ],
            [
              105.7991331196505,
              21.02550744334617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1751",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02540402057947,
          "to_latitude": 21.02550744334617,
          "to_longitude": 105.7991331196505,
          "from_longitude": 105.7994399003119
        }
      },
      {
        "id": 1746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79915619767786,
            21.025476634950905,
            105.79946773067518,
            21.025581701399407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79946773067518,
              21.025476634950905
            ],
            [
              105.79915729720298,
              21.025581331327857
            ],
            [
              105.79915619767786,
              21.025581701399407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1752",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025476634950905,
          "to_latitude": 21.025581701399407,
          "to_longitude": 105.79915619767786,
          "from_longitude": 105.79946773067518
        }
      },
      {
        "id": 1747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79920823611907,
            21.02552937882584,
            105.79984446717495,
            21.02576010480902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79984446717495,
              21.02552937882584
            ],
            [
              105.79964527743631,
              21.025600518138276
            ],
            [
              105.79953775394121,
              21.025640565260407
            ],
            [
              105.79939786729636,
              21.025692662686193
            ],
            [
              105.79921071340539,
              21.025759224260266
            ],
            [
              105.79920823611907,
              21.02576010480902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102_Ngõ 1150/1, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1753",
          "diaChiLapD": "Ngõ 1150/1, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02552937882584,
          "to_latitude": 21.02576010480902,
          "to_longitude": 105.79920823611907,
          "from_longitude": 105.79984446717495
        }
      },
      {
        "id": 1748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79985863694289,
            21.02521336434104,
            105.79987483097781,
            21.025214020695437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79987483097781,
              21.025214020695437
            ],
            [
              105.79986823597525,
              21.02521375364789
            ],
            [
              105.79986362601493,
              21.02521356668368
            ],
            [
              105.79986260318904,
              21.02521352633279
            ],
            [
              105.79985863694289,
              21.02521336434104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1754",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025214020695437,
          "to_latitude": 21.02521336434104,
          "to_longitude": 105.79985863694289,
          "from_longitude": 105.79987483097781
        }
      },
      {
        "id": 1749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79986432872516,
            21.025144343892883,
            105.79987710955102,
            21.02544489091701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79986574812713,
              21.02544489091701
            ],
            [
              105.79986432872516,
              21.02536961884128
            ],
            [
              105.79987102343314,
              21.025300762176194
            ],
            [
              105.79987132622891,
              21.025297652739635
            ],
            [
              105.79987404521665,
              21.025238038551777
            ],
            [
              105.79987483097781,
              21.025214020695437
            ],
            [
              105.79987710955102,
              21.025144343892883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1755",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02544489091701,
          "to_latitude": 21.025144343892883,
          "to_longitude": 105.79987710955102,
          "from_longitude": 105.79986574812713
        }
      },
      {
        "id": 1750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79985525682265,
            21.025461084217152,
            105.80013747915459,
            21.02547408762853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79985525682265,
              21.0254629840741
            ],
            [
              105.79985874768116,
              21.0254629351395
            ],
            [
              105.79998879427247,
              21.025461084217152
            ],
            [
              105.80003768692168,
              21.02547111580717
            ],
            [
              105.80013747915459,
              21.02547408762853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1756",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0254629840741,
          "to_latitude": 21.02547408762853,
          "to_longitude": 105.80013747915459,
          "from_longitude": 105.79985525682265
        }
      },
      {
        "id": 1751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80020600930682,
            21.024926126381377,
            105.80044159300896,
            21.02492646653042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80044159300896,
              21.02492646653042
            ],
            [
              105.80020600930682,
              21.024926126381377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1757",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02492646653042,
          "to_latitude": 21.024926126381377,
          "to_longitude": 105.80020600930682,
          "from_longitude": 105.80044159300896
        }
      },
      {
        "id": 1752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80039837965417,
            21.024683579932145,
            105.80054331787179,
            21.025165902852056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80039837965417,
              21.025165902852056
            ],
            [
              105.80041145520002,
              21.025131304532813
            ],
            [
              105.80043523828769,
              21.0249706616453
            ],
            [
              105.80044159300896,
              21.02492646653042
            ],
            [
              105.80045051606875,
              21.02486439525662
            ],
            [
              105.80046275013979,
              21.02480210788134
            ],
            [
              105.80053577900156,
              21.0247957034729
            ],
            [
              105.80054155215757,
              21.024795197586343
            ],
            [
              105.80054331787179,
              21.024683579932145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1758",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025165902852056,
          "to_latitude": 21.024683579932145,
          "to_longitude": 105.80054331787179,
          "from_longitude": 105.80039837965417
        }
      },
      {
        "id": 1753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79993777094853,
            21.02506081983695,
            105.80061965331937,
            21.02520108624973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79993777094853,
              21.02507517016821
            ],
            [
              105.79994030503146,
              21.025075020957896
            ],
            [
              105.80016100934438,
              21.02506196161637
            ],
            [
              105.80025336422558,
              21.02506081983695
            ],
            [
              105.80027801423215,
              21.02507697100558
            ],
            [
              105.80028751303917,
              21.025135899055876
            ],
            [
              105.80029554386438,
              21.025156086376796
            ],
            [
              105.80039221627598,
              21.025160711193482
            ],
            [
              105.80039837965417,
              21.025165902852056
            ],
            [
              105.80040454303274,
              21.02517109451039
            ],
            [
              105.800418742643,
              21.02520108624973
            ],
            [
              105.80061965331937,
              21.025200537966313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1759",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02507517016821,
          "to_latitude": 21.025200537966313,
          "to_longitude": 105.80061965331937,
          "from_longitude": 105.79993777094853
        }
      },
      {
        "id": 1754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79991332484315,
            21.024974763696626,
            105.79992641460332,
            21.024976606433068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79991332484315,
              21.024976606433068
            ],
            [
              105.79991995768165,
              21.02497567290926
            ],
            [
              105.7999258897485,
              21.02497483842367
            ],
            [
              105.79992641460332,
              21.024974763696626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1760",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024976606433068,
          "to_latitude": 21.024974763696626,
          "to_longitude": 105.79992641460332,
          "from_longitude": 105.79991332484315
        }
      },
      {
        "id": 1755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79984589855901,
            21.024962430308822,
            105.79992622806061,
            21.02512365679457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7999117754181,
              21.024962430308822
            ],
            [
              105.79991332484315,
              21.024976606433068
            ],
            [
              105.79992087784733,
              21.025045721249953
            ],
            [
              105.79992622806061,
              21.025094678216924
            ],
            [
              105.79991701973769,
              21.025104049179102
            ],
            [
              105.79987692788413,
              21.02511227739279
            ],
            [
              105.79984589855901,
              21.02512365679457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1761",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024962430308822,
          "to_latitude": 21.02512365679457,
          "to_longitude": 105.79984589855901,
          "from_longitude": 105.7999117754181
        }
      },
      {
        "id": 1756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79966960370916,
            21.02481633214951,
            105.79992421354613,
            21.02498569016325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79992421354613,
              21.02495907863141
            ],
            [
              105.79991426611096,
              21.02496009910068
            ],
            [
              105.79990538815292,
              21.024954217479102
            ],
            [
              105.79969880433678,
              21.02498569016325
            ],
            [
              105.79966960370916,
              21.024877838254422
            ],
            [
              105.79973329150617,
              21.02485915083453
            ],
            [
              105.79972814534631,
              21.02481633214951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1762",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02495907863141,
          "to_latitude": 21.02481633214951,
          "to_longitude": 105.79972814534631,
          "from_longitude": 105.79992421354613
        }
      },
      {
        "id": 1757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79931450836396,
            21.02443537963701,
            105.79956955709568,
            21.02451701773969
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79956955709568,
              21.02451701773969
            ],
            [
              105.7995627999056,
              21.02451483388559
            ],
            [
              105.79931450836396,
              21.02443537963701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1763",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02451701773969,
          "to_latitude": 21.02443537963701,
          "to_longitude": 105.79931450836396,
          "from_longitude": 105.79956955709568
        }
      },
      {
        "id": 1758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79933341164313,
            21.024311236723662,
            105.79963505054819,
            21.02439734622707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79933341164313,
              21.024311236723662
            ],
            [
              105.79962857027957,
              21.024395497074806
            ],
            [
              105.79963505054819,
              21.02439734622707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1764",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024311236723662,
          "to_latitude": 21.02439734622707,
          "to_longitude": 105.79963505054819,
          "from_longitude": 105.79933341164313
        }
      },
      {
        "id": 1759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.799321085517,
            21.02433035641307,
            105.79963113844917,
            21.024409872880007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79963113844917,
              21.024404266197692
            ],
            [
              105.79962352453093,
              21.024401798243726
            ],
            [
              105.79958784183096,
              21.024409872880007
            ],
            [
              105.799321085517,
              21.02433035641307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_153_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1765",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024404266197692,
          "to_latitude": 21.02433035641307,
          "to_longitude": 105.799321085517,
          "from_longitude": 105.79963113844917
        }
      },
      {
        "id": 1760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79948957819605,
            21.024144999402527,
            105.79970334968458,
            21.02422169649149
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79970334968458,
              21.02422169649149
            ],
            [
              105.79969468045891,
              21.024219047437324
            ],
            [
              105.79969052417181,
              21.02421769949085
            ],
            [
              105.79969558769616,
              21.024202648651745
            ],
            [
              105.79948957819605,
              21.024144999402527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1766",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02422169649149,
          "to_latitude": 21.024144999402527,
          "to_longitude": 105.79948957819605,
          "from_longitude": 105.79970334968458
        }
      },
      {
        "id": 1761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79961186276441,
            21.024190149676436,
            105.79972768446304,
            21.02451896400956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79972768446304,
              21.024190149676436
            ],
            [
              105.79971853279355,
              21.024233940633064
            ],
            [
              105.79971728801301,
              21.02423719540296
            ],
            [
              105.79966615258245,
              21.02437094581727
            ],
            [
              105.79965993628223,
              21.0243872042742
            ],
            [
              105.79961186276441,
              21.02451896400956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1767",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024190149676436,
          "to_latitude": 21.02451896400956,
          "to_longitude": 105.79961186276441,
          "from_longitude": 105.79972768446304
        }
      },
      {
        "id": 1762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79999800611165,
            21.024854917013872,
            105.80000914994282,
            21.024856719893044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80000914994282,
              21.024854917013872
            ],
            [
              105.80000871357599,
              21.024854988615214
            ],
            [
              105.8000052985266,
              21.02485554029472
            ],
            [
              105.79999800611165,
              21.024856719893044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1768",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024854917013872,
          "to_latitude": 21.024856719893044,
          "to_longitude": 105.79999800611165,
          "from_longitude": 105.80000914994282
        }
      },
      {
        "id": 1763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7999656377747,
            21.02464959182778,
            105.80017289930102,
            21.02491259113022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7999656377747,
              21.02464959182778
            ],
            [
              105.79998503683008,
              21.024748628205387
            ],
            [
              105.80000325974858,
              21.024824115694095
            ],
            [
              105.80000914994282,
              21.024854917013872
            ],
            [
              105.80001095797164,
              21.024864371643673
            ],
            [
              105.80001966646864,
              21.02491259113022
            ],
            [
              105.80017289930102,
              21.024905434729337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1769",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02464959182778,
          "to_latitude": 21.024905434729337,
          "to_longitude": 105.80017289930102,
          "from_longitude": 105.7999656377747
        }
      },
      {
        "id": 1764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79990976456065,
            21.024393919759397,
            105.79992288260506,
            21.024396762502384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79992288260506,
              21.024393919759397
            ],
            [
              105.79992146433477,
              21.02439422720555
            ],
            [
              105.79991883534461,
              21.0243947968634
            ],
            [
              105.79990976456065,
              21.024396762502384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1770",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024393919759397,
          "to_latitude": 21.024396762502384,
          "to_longitude": 105.79990976456065,
          "from_longitude": 105.79992288260506
        }
      },
      {
        "id": 1765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79989497931953,
            21.024121186049168,
            105.79994955790859,
            21.024643637502475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79992363910075,
              21.024121186049168
            ],
            [
              105.79990295460675,
              21.024178414409366
            ],
            [
              105.79989497931953,
              21.024238757844877
            ],
            [
              105.79989680970803,
              21.024275486290353
            ],
            [
              105.79989810156665,
              21.024284662445663
            ],
            [
              105.79991771533587,
              21.0243794255522
            ],
            [
              105.79992286940586,
              21.024391445875867
            ],
            [
              105.79992288260506,
              21.024393919759397
            ],
            [
              105.79992295407062,
              21.02440731431675
            ],
            [
              105.79991814742806,
              21.02448763763956
            ],
            [
              105.79991736332039,
              21.02455812213101
            ],
            [
              105.7999221768659,
              21.024598994108793
            ],
            [
              105.79992992275135,
              21.024617358266994
            ],
            [
              105.79993786208216,
              21.0246305089776
            ],
            [
              105.79994955790859,
              21.024643637502475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1771",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024121186049168,
          "to_latitude": 21.024643637502475,
          "to_longitude": 105.79994955790859,
          "from_longitude": 105.79992363910075
        }
      },
      {
        "id": 1766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79992827460097,
            21.024100743130113,
            105.80020415250235,
            21.024134666772195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79992827460097,
              21.024100743130113
            ],
            [
              105.79993009736704,
              21.024101243075098
            ],
            [
              105.79994388657306,
              21.024105017846782
            ],
            [
              105.79997983746337,
              21.024111706066257
            ],
            [
              105.80004565790829,
              21.02412641986909
            ],
            [
              105.80004570990785,
              21.024126429560013
            ],
            [
              105.80011804775641,
              21.02412867881848
            ],
            [
              105.80013975943264,
              21.024130188035166
            ],
            [
              105.80020415250235,
              21.024134666772195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1772",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024100743130113,
          "to_latitude": 21.024134666772195,
          "to_longitude": 105.80020415250235,
          "from_longitude": 105.79992827460097
        }
      },
      {
        "id": 1767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80003862878038,
            21.024126429560013,
            105.8000794861586,
            21.024532834397593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80004570990785,
              21.024126429560013
            ],
            [
              105.80003862878038,
              21.024269777392163
            ],
            [
              105.8000794861586,
              21.02427092467967
            ],
            [
              105.80005960866646,
              21.024463148647996
            ],
            [
              105.80005542931292,
              21.024532834397593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1773",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024126429560013,
          "to_latitude": 21.024532834397593,
          "to_longitude": 105.80005542931292,
          "from_longitude": 105.80004570990785
        }
      },
      {
        "id": 1768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79992704378883,
            21.024072356802012,
            105.79993978657212,
            21.024075634300218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79992704378883,
              21.024072356802012
            ],
            [
              105.79993727905332,
              21.02407498944639
            ],
            [
              105.79993909973133,
              21.024075458691307
            ],
            [
              105.79993978657212,
              21.024075634300218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1774",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024072356802012,
          "to_latitude": 21.024075634300218,
          "to_longitude": 105.79993978657212,
          "from_longitude": 105.79992704378883
        }
      },
      {
        "id": 1769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7997750025086,
            21.023937379355278,
            105.79996059186568,
            21.024086269878154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79996059186568,
              21.023937379355278
            ],
            [
              105.7999444001668,
              21.024005109212474
            ],
            [
              105.79992819082482,
              21.024067912033868
            ],
            [
              105.79992704378883,
              21.024072356802012
            ],
            [
              105.79992345281114,
              21.024086269878154
            ],
            [
              105.79981034675036,
              21.02406278675427
            ],
            [
              105.7997750025086,
              21.024015629154757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1775",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023937379355278,
          "to_latitude": 21.024015629154757,
          "to_longitude": 105.7997750025086,
          "from_longitude": 105.79996059186568
        }
      },
      {
        "id": 1770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79996367529186,
            21.023473984110883,
            105.80014240879935,
            21.02392732545576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80014240879935,
              21.023483060389854
            ],
            [
              105.80014193785456,
              21.023482780797824
            ],
            [
              105.80012718299702,
              21.023473984110883
            ],
            [
              105.80003169313382,
              21.02362983847698
            ],
            [
              105.80001939356757,
              21.023652646879146
            ],
            [
              105.80001782207611,
              21.02366336025122
            ],
            [
              105.80001593678924,
              21.023681791389457
            ],
            [
              105.8000125967865,
              21.023714431751962
            ],
            [
              105.79999737666411,
              21.02380053595939
            ],
            [
              105.79996367529186,
              21.02392732545576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1776",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023483060389854,
          "to_latitude": 21.02392732545576,
          "to_longitude": 105.79996367529186,
          "from_longitude": 105.80014240879935
        }
      },
      {
        "id": 1771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8001427894701,
            21.023480124006127,
            105.80085342359935,
            21.02418921323505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8001427894701,
              21.023480124006127
            ],
            [
              105.8001431657322,
              21.02348032726908
            ],
            [
              105.80022745574614,
              21.023525600695795
            ],
            [
              105.80041939908865,
              21.023588069869547
            ],
            [
              105.80042821183211,
              21.023590937696934
            ],
            [
              105.80043027839083,
              21.02383658110151
            ],
            [
              105.80053318365707,
              21.0238509305029
            ],
            [
              105.80057145960487,
              21.023860492569415
            ],
            [
              105.80059700830552,
              21.02389076326696
            ],
            [
              105.80058703157442,
              21.024001043963793
            ],
            [
              105.80069164583281,
              21.024021765633385
            ],
            [
              105.80074359563645,
              21.02405239885194
            ],
            [
              105.80079006549212,
              21.024033419981727
            ],
            [
              105.80085168232998,
              21.024035760053565
            ],
            [
              105.80085342359935,
              21.02418921323505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1777",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 140,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023480124006127,
          "to_latitude": 21.02418921323505,
          "to_longitude": 105.80085342359935,
          "from_longitude": 105.8001427894701
        }
      },
      {
        "id": 1772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79995833243484,
            21.023255160676054,
            105.79996393022167,
            21.02326274161746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79996393022167,
              21.023255160676054
            ],
            [
              105.7999622473447,
              21.023257438407434
            ],
            [
              105.79995937061955,
              21.023261335825293
            ],
            [
              105.79995833243484,
              21.02326274161746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1778",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023255160676054,
          "to_latitude": 21.02326274161746,
          "to_longitude": 105.79995833243484,
          "from_longitude": 105.79996393022167
        }
      },
      {
        "id": 1773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79996181922986,
            21.023089887050663,
            105.80009579833406,
            21.023265005872275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79996181922986,
              21.023265005872275
            ],
            [
              105.79996461991985,
              21.023261461974762
            ],
            [
              105.79996569313944,
              21.023259590854526
            ],
            [
              105.79996742649833,
              21.023257579338424
            ],
            [
              105.7999705757944,
              21.023253923607143
            ],
            [
              105.80001069983685,
              21.023203142278625
            ],
            [
              105.80009579833406,
              21.023089887050663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1779",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023265005872275,
          "to_latitude": 21.023089887050663,
          "to_longitude": 105.80009579833406,
          "from_longitude": 105.79996181922986
        }
      },
      {
        "id": 1774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79974014830808,
            21.02310915916308,
            105.80015842905166,
            21.02346406493473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79974014830808,
              21.02310915916308
            ],
            [
              105.79981341375287,
              21.023160958091538
            ],
            [
              105.79993109079773,
              21.023232458003374
            ],
            [
              105.79995642110288,
              21.02324996719857
            ],
            [
              105.79996393022167,
              21.023255160676054
            ],
            [
              105.79996742649833,
              21.023257579338424
            ],
            [
              105.79997158707617,
              21.02326045732971
            ],
            [
              105.79998175141391,
              21.02326747638976
            ],
            [
              105.80006281043276,
              21.023324382440006
            ],
            [
              105.80015022041798,
              21.02338378038794
            ],
            [
              105.80015842905166,
              21.023399634093714
            ],
            [
              105.80015738311631,
              21.023434918258
            ],
            [
              105.80015250647821,
              21.02346406493473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1780",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02310915916308,
          "to_latitude": 21.02346406493473,
          "to_longitude": 105.80015250647821,
          "from_longitude": 105.79974014830808
        }
      },
      {
        "id": 1775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79939269043389,
            21.023764449530827,
            105.79953882233028,
            21.0238728818047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79953882233028,
              21.023764449530827
            ],
            [
              105.79939269043389,
              21.0238728818047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1781",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023764449530827,
          "to_latitude": 21.0238728818047,
          "to_longitude": 105.79939269043389,
          "from_longitude": 105.79953882233028
        }
      },
      {
        "id": 1776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79952330796057,
            21.02357422958012,
            105.79966670609836,
            21.023859861600044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79965673925601,
              21.02357422958012
            ],
            [
              105.79958091974697,
              21.02365829736827
            ],
            [
              105.79952399313913,
              21.023728182314954
            ],
            [
              105.79952330796057,
              21.02374202843335
            ],
            [
              105.79952895434009,
              21.023754556781714
            ],
            [
              105.79953882233028,
              21.023764449530827
            ],
            [
              105.79966670609836,
              21.023859861600044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1782",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02357422958012,
          "to_latitude": 21.023859861600044,
          "to_longitude": 105.79966670609836,
          "from_longitude": 105.79965673925601
        }
      },
      {
        "id": 1777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79953760781068,
            21.02316761109288,
            105.799883042521,
            21.023774381481683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79959561498386,
              21.02316761109288
            ],
            [
              105.79959900623251,
              21.023170359935666
            ],
            [
              105.79967395655758,
              21.02323108123488
            ],
            [
              105.79959317841846,
              21.023364263776607
            ],
            [
              105.79954602468676,
              21.023416627096573
            ],
            [
              105.79953760781068,
              21.023440364701173
            ],
            [
              105.7995411441915,
              21.023453551487822
            ],
            [
              105.79965673925601,
              21.02357422958012
            ],
            [
              105.79967437976897,
              21.023607858780977
            ],
            [
              105.79977594036421,
              21.02368865202308
            ],
            [
              105.79985156304608,
              21.023752721954576
            ],
            [
              105.799883042521,
              21.023774381481683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_04_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1783",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02316761109288,
          "to_latitude": 21.023774381481683,
          "to_longitude": 105.799883042521,
          "from_longitude": 105.79959561498386
        }
      },
      {
        "id": 1778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79929227708851,
            21.023201048028103,
            105.79960938912505,
            21.023472055116073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79960938912505,
              21.023201048028103
            ],
            [
              105.79947383979992,
              21.023316890383168
            ],
            [
              105.79946158789775,
              21.023327360405844
            ],
            [
              105.79929227708851,
              21.023472055116073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1784",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023201048028103,
          "to_latitude": 21.023472055116073,
          "to_longitude": 105.79929227708851,
          "from_longitude": 105.79960938912505
        }
      },
      {
        "id": 1779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79987062373847,
            21.022949864409362,
            105.79988207132911,
            21.022963732954427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79988207132911,
              21.022963732954427
            ],
            [
              105.79988191537817,
              21.022963532267642
            ],
            [
              105.79987809600522,
              21.02295861227332
            ],
            [
              105.79987062373847,
              21.022949864409362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1785",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022963732954427,
          "to_latitude": 21.022949864409362,
          "to_longitude": 105.79987062373847,
          "from_longitude": 105.79988207132911
        }
      },
      {
        "id": 1780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79973566937502,
            21.022962795397824,
            105.79988322456333,
            21.02309150221251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79988322456333,
              21.022962795397824
            ],
            [
              105.79988207132911,
              21.022963732954427
            ],
            [
              105.7998398893041,
              21.02299804721607
            ],
            [
              105.79981739197369,
              21.023017300578235
            ],
            [
              105.7997645036454,
              21.023062563537767
            ],
            [
              105.79973566937502,
              21.02309150221251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1786",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022962795397824,
          "to_latitude": 21.02309150221251,
          "to_longitude": 105.79973566937502,
          "from_longitude": 105.79988322456333
        }
      },
      {
        "id": 1781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80002265336256,
            21.02282214895637,
            105.80003629886245,
            21.022836333218642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80003629886245,
              21.022836333218642
            ],
            [
              105.80003136395854,
              21.02283130376482
            ],
            [
              105.80002265336256,
              21.02282214895637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1787",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022836333218642,
          "to_latitude": 21.02282214895637,
          "to_longitude": 105.80002265336256,
          "from_longitude": 105.80003629886245
        }
      },
      {
        "id": 1782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79993500694876,
            21.0226668442837,
            105.80022669736627,
            21.022922839562384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80022669736627,
              21.0226668442837
            ],
            [
              105.800144275401,
              21.022738087707072
            ],
            [
              105.80009135965572,
              21.022788091042447
            ],
            [
              105.80006057444108,
              21.022817182561056
            ],
            [
              105.80003629886245,
              21.022836333218642
            ],
            [
              105.80000776803543,
              21.02285884132169
            ],
            [
              105.79993500694876,
              21.022922839562384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1788",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0226668442837,
          "to_latitude": 21.022922839562384,
          "to_longitude": 105.79993500694876,
          "from_longitude": 105.80022669736627
        }
      },
      {
        "id": 1783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80017944631862,
            21.02293728782455,
            105.80030398783758,
            21.023099442605975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80017944631862,
              21.023099442605975
            ],
            [
              105.80024073544817,
              21.02300025963981
            ],
            [
              105.80025894847549,
              21.02300632584233
            ],
            [
              105.80030398783758,
              21.02293728782455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1789",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023099442605975,
          "to_latitude": 21.02293728782455,
          "to_longitude": 105.80030398783758,
          "from_longitude": 105.80017944631862
        }
      },
      {
        "id": 1784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80091532326888,
            21.025723991519495,
            105.80091641383679,
            21.025735630814278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80091641383679,
              21.025735630814278
            ],
            [
              105.80091576094027,
              21.025728665475846
            ],
            [
              105.80091550284615,
              21.02572591274552
            ],
            [
              105.80091542024978,
              21.025725037905406
            ],
            [
              105.80091532326888,
              21.025723991519495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_133_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1790",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025735630814278,
          "to_latitude": 21.025723991519495,
          "to_longitude": 105.80091532326888,
          "from_longitude": 105.80091641383679
        }
      },
      {
        "id": 1785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80080887850295,
            21.02572773658159,
            105.80098699070558,
            21.02574765790891
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80080887850295,
              21.02574765790891
            ],
            [
              105.80089810045907,
              21.02573767887437
            ],
            [
              105.80091641383679,
              21.025735630814278
            ],
            [
              105.80098699070558,
              21.02572773658159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_133_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1791",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02574765790891,
          "to_latitude": 21.02572773658159,
          "to_longitude": 105.80098699070558,
          "from_longitude": 105.80080887850295
        }
      },
      {
        "id": 1786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80065975882955,
            21.02576122596819,
            105.8008016418321,
            21.026703717063803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8007465897925,
              21.02576122596819
            ],
            [
              105.80074608394263,
              21.025769361929154
            ],
            [
              105.80074165775308,
              21.025840686394233
            ],
            [
              105.80074679713945,
              21.02591315266212
            ],
            [
              105.80074232569906,
              21.02599005387628
            ],
            [
              105.80074444469187,
              21.026027097070315
            ],
            [
              105.80074462070792,
              21.026164151335458
            ],
            [
              105.80071521523735,
              21.026183839283696
            ],
            [
              105.8006740297096,
              21.02619880099474
            ],
            [
              105.80065975882955,
              21.02621691560649
            ],
            [
              105.80069195095959,
              21.02640718642738
            ],
            [
              105.80075085432046,
              21.02643948765344
            ],
            [
              105.80077288000058,
              21.02653735720545
            ],
            [
              105.80080151607369,
              21.026572805072487
            ],
            [
              105.8008016418321,
              21.026671263680775
            ],
            [
              105.80079577425234,
              21.026685442201835
            ],
            [
              105.80068723935732,
              21.026703717063803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_132_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1792",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 125,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02576122596819,
          "to_latitude": 21.026703717063803,
          "to_longitude": 105.80068723935732,
          "from_longitude": 105.8007465897925
        }
      },
      {
        "id": 1787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8007625064784,
            21.025747048002223,
            105.80076372515315,
            21.025754577002107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8007625064784,
              21.025747048002223
            ],
            [
              105.80076315510702,
              21.0257510543826
            ],
            [
              105.80076349538965,
              21.025753165433482
            ],
            [
              105.80076372515315,
              21.025754577002107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1793",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025747048002223,
          "to_latitude": 21.025754577002107,
          "to_longitude": 105.80076372515315,
          "from_longitude": 105.8007625064784
        }
      },
      {
        "id": 1788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8003586968562,
            21.025716260633235,
            105.80096349200527,
            21.02579584728257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8003586968562,
              21.02579584728257
            ],
            [
              105.80045437076743,
              21.025790496971265
            ],
            [
              105.80060172349748,
              21.025778797497125
            ],
            [
              105.80063856953758,
              21.02577342685275
            ],
            [
              105.8006790385606,
              21.025767527915285
            ],
            [
              105.8007575180916,
              21.025749318724863
            ],
            [
              105.8007625064784,
              21.025747048002223
            ],
            [
              105.8007869311555,
              21.025735931662094
            ],
            [
              105.80096349200527,
              21.025716260633235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1794",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02579584728257,
          "to_latitude": 21.025716260633235,
          "to_longitude": 105.80096349200527,
          "from_longitude": 105.8003586968562
        }
      },
      {
        "id": 1789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80049800983134,
            21.025794546677744,
            105.80049850792432,
            21.025799728869142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80049850792432,
              21.025799728869142
            ],
            [
              105.80049833781068,
              21.025797957534067
            ],
            [
              105.80049808223157,
              21.02579531408182
            ],
            [
              105.80049800983134,
              21.025794546677744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_130_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1795",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025799728869142,
          "to_latitude": 21.025794546677744,
          "to_longitude": 105.80049800983134,
          "from_longitude": 105.80049850792432
        }
      },
      {
        "id": 1790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80028595578001,
            21.025775096549832,
            105.80071826444724,
            21.025817149040687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80028595578001,
              21.025817149040687
            ],
            [
              105.80044967973278,
              21.025804137038723
            ],
            [
              105.80048390302676,
              21.025801047684176
            ],
            [
              105.80049850792432,
              21.025799728869142
            ],
            [
              105.80057166991827,
              21.02579312419244
            ],
            [
              105.80071826444724,
              21.025775096549832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_130_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1796",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025817149040687,
          "to_latitude": 21.025775096549832,
          "to_longitude": 105.80071826444724,
          "from_longitude": 105.80028595578001
        }
      },
      {
        "id": 1791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79984727771644,
            21.02610763071586,
            105.799990228036,
            21.026122334782176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79984727771644,
              21.026122334782176
            ],
            [
              105.799990228036,
              21.02610763071586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1797",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026122334782176,
          "to_latitude": 21.02610763071586,
          "to_longitude": 105.799990228036,
          "from_longitude": 105.79984727771644
        }
      },
      {
        "id": 1792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79998681729407,
            21.02584128352881,
            105.8002414509186,
            21.025971783183326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79998681729407,
              21.02584128352881
            ],
            [
              105.79998703461108,
              21.025842166768072
            ],
            [
              105.79998825469146,
              21.02584708182215
            ],
            [
              105.7999888809755,
              21.025849608821446
            ],
            [
              105.80001289243924,
              21.025971783183326
            ],
            [
              105.8002414509186,
              21.02594286037112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_125_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1798",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02584128352881,
          "to_latitude": 21.02594286037112,
          "to_longitude": 105.8002414509186,
          "from_longitude": 105.79998681729407
        }
      },
      {
        "id": 1793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80003908428041,
            21.025827243534643,
            105.80004018997528,
            21.025831094219054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80003908428041,
              21.025827243534643
            ],
            [
              105.8000392725,
              21.025827902006597
            ],
            [
              105.80004011743884,
              21.025830841656074
            ],
            [
              105.80004018997528,
              21.025831094219054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_126_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1799",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025827243534643,
          "to_latitude": 21.025831094219054,
          "to_longitude": 105.80004018997528,
          "from_longitude": 105.80003908428041
        }
      },
      {
        "id": 1794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80001802700667,
            21.025619593522222,
            105.80004762026893,
            21.025830572576698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80004701167819,
              21.025830572576698
            ],
            [
              105.80004735507345,
              21.02582677920449
            ],
            [
              105.80004762026893,
              21.025823835235727
            ],
            [
              105.80002068739445,
              21.025675379807012
            ],
            [
              105.80001802700667,
              21.025619593522222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_127_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1800",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025830572576698,
          "to_latitude": 21.025619593522222,
          "to_longitude": 105.80001802700667,
          "from_longitude": 105.80004701167819
        }
      },
      {
        "id": 1795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8000126597461,
            21.025834797808137,
            105.80001328642258,
            21.025841905081446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80001328642258,
              21.025841905081446
            ],
            [
              105.80001327412204,
              21.025841763332306
            ],
            [
              105.80001291785481,
              21.02583773660542
            ],
            [
              105.8000126597461,
              21.025834797808137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_128_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1801",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025841905081446,
          "to_latitude": 21.025834797808137,
          "to_longitude": 105.8000126597461,
          "from_longitude": 105.80001328642258
        }
      },
      {
        "id": 1796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80026133859785,
            21.025813658288573,
            105.80064318707808,
            21.02626147025519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80026133859785,
              21.025813658288573
            ],
            [
              105.80026138484747,
              21.025814212653724
            ],
            [
              105.80026993397595,
              21.025914547391928
            ],
            [
              105.80039695922731,
              21.025909083899066
            ],
            [
              105.80041116698315,
              21.026027074513003
            ],
            [
              105.80042637554676,
              21.02609989533818
            ],
            [
              105.80045308074035,
              21.02626147025519
            ],
            [
              105.80059935061914,
              21.02623822537297
            ],
            [
              105.80059877299972,
              21.026207885010493
            ],
            [
              105.80064318707808,
              21.02620535822868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_129_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1802",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025813658288573,
          "to_latitude": 21.02620535822868,
          "to_longitude": 105.80064318707808,
          "from_longitude": 105.80026133859785
        }
      },
      {
        "id": 1797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79984047516804,
            21.025822002458998,
            105.80023904338567,
            21.0258821294475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79984047516804,
              21.0258821294475
            ],
            [
              105.79991117306086,
              21.025864437760134
            ],
            [
              105.79998825469146,
              21.02584708182215
            ],
            [
              105.79999242287737,
              21.025846143630368
            ],
            [
              105.80000993732095,
              21.02584219993422
            ],
            [
              105.80001328642258,
              21.025841905081446
            ],
            [
              105.80006838261993,
              21.02583704845007
            ],
            [
              105.80023904338567,
              21.025822002458998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_128_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1803",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0258821294475,
          "to_latitude": 21.025822002458998,
          "to_longitude": 105.80023904338567,
          "from_longitude": 105.79984047516804
        }
      },
      {
        "id": 1798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79983435268895,
            21.026140046934135,
            105.7998496194738,
            21.026141368807448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7998496194738,
              21.026140046934135
            ],
            [
              105.79984608572157,
              21.026140353491076
            ],
            [
              105.79983903743755,
              21.02614096290145
            ],
            [
              105.79983435268895,
              21.026141368807448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1804",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026140046934135,
          "to_latitude": 21.026141368807448,
          "to_longitude": 105.79983435268895,
          "from_longitude": 105.7998496194738
        }
      },
      {
        "id": 1799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79982975214465,
            21.025958132436614,
            105.79986302052988,
            21.026241424686333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79986302052988,
              21.026241424686333
            ],
            [
              105.7998496194738,
              21.026140046934135
            ],
            [
              105.79984727771644,
              21.026122334782176
            ],
            [
              105.79984479262257,
              21.026103541235543
            ],
            [
              105.79984245089541,
              21.026085834502652
            ],
            [
              105.79982975214465,
              21.025958132436614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1805",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026241424686333,
          "to_latitude": 21.025958132436614,
          "to_longitude": 105.79982975214465,
          "from_longitude": 105.79986302052988
        }
      },
      {
        "id": 1800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79984635808044,
            21.026232741896962,
            105.80006157172613,
            21.02626154029525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79984635808044,
              21.02626154029525
            ],
            [
              105.79985770940557,
              21.026260021867948
            ],
            [
              105.80006157172613,
              21.026232741896962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_123_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1806",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02626154029525,
          "to_latitude": 21.026232741896962,
          "to_longitude": 105.80006157172613,
          "from_longitude": 105.79984635808044
        }
      },
      {
        "id": 1801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79980255718968,
            21.026421664940795,
            105.79988703683237,
            21.026435806718045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79988703683237,
              21.026421664940795
            ],
            [
              105.7998138940036,
              21.02643390990851
            ],
            [
              105.79980255718968,
              21.026435806718045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1807",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026421664940795,
          "to_latitude": 21.026435806718045,
          "to_longitude": 105.79980255718968,
          "from_longitude": 105.79988703683237
        }
      },
      {
        "id": 1802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79974268431194,
            21.026279588633983,
            105.79988992707108,
            21.026492406449844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79987247293062,
              21.026279588633983
            ],
            [
              105.79988703683237,
              21.026421664940795
            ],
            [
              105.79988992707108,
              21.026449857390684
            ],
            [
              105.79974268431194,
              21.026492406449844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1808",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026279588633983,
          "to_latitude": 21.026492406449844,
          "to_longitude": 105.79974268431194,
          "from_longitude": 105.79987247293062
        }
      },
      {
        "id": 1803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80000426229634,
            21.027041885016132,
            105.80018326689506,
            21.027103388106795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80000426229634,
              21.027082065066573
            ],
            [
              105.80016089496269,
              21.027041885016132
            ],
            [
              105.80018326689506,
              21.027103388106795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_121_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1809",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027082065066573,
          "to_latitude": 21.027103388106795,
          "to_longitude": 105.80018326689506,
          "from_longitude": 105.80000426229634
        }
      },
      {
        "id": 1804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79969563136122,
            21.026492234817155,
            105.80003921509085,
            21.027213516046757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79969563136122,
              21.026492234817155
            ],
            [
              105.79970172778738,
              21.026514084169285
            ],
            [
              105.79971070545473,
              21.02652349243972
            ],
            [
              105.79976247518967,
              21.026697860365374
            ],
            [
              105.7998622378871,
              21.02667567120677
            ],
            [
              105.7999293525827,
              21.02685032999437
            ],
            [
              105.80000426229634,
              21.027082065066573
            ],
            [
              105.80003921509085,
              21.02719019148915
            ],
            [
              105.79995809241443,
              21.027213516046757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_121_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1810",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026492234817155,
          "to_latitude": 21.027213516046757,
          "to_longitude": 105.79995809241443,
          "from_longitude": 105.79969563136122
        }
      },
      {
        "id": 1805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79968919345592,
            21.026493951601275,
            105.79990085663091,
            21.027210343979785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79968992327854,
              21.026493951601275
            ],
            [
              105.79969884490527,
              21.026514675792825
            ],
            [
              105.79968919345592,
              21.026530084206012
            ],
            [
              105.79978988081591,
              21.02686947781391
            ],
            [
              105.79990085663091,
              21.027210343979785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_120_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1811",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026493951601275,
          "to_latitude": 21.027210343979785,
          "to_longitude": 105.79990085663091,
          "from_longitude": 105.79968992327854
        }
      },
      {
        "id": 1806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79948208318748,
            21.026539380360582,
            105.79948738739066,
            21.02655483045265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79948208318748,
              21.026539380360582
            ],
            [
              105.79948463173098,
              21.026546808279424
            ],
            [
              105.79948738739066,
              21.02655483045265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1812",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026539380360582,
          "to_latitude": 21.02655483045265,
          "to_longitude": 105.79948738739066,
          "from_longitude": 105.79948208318748
        }
      },
      {
        "id": 1807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79904368368959,
            21.02648621369544,
            105.79966340356783,
            21.026669582873993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79966340356783,
              21.02648621369544
            ],
            [
              105.79956297355993,
              21.026515661514004
            ],
            [
              105.79948208318748,
              21.026539380360582
            ],
            [
              105.79937661138347,
              21.02657030687593
            ],
            [
              105.7993718905955,
              21.02657171462033
            ],
            [
              105.79934315957509,
              21.026580281316846
            ],
            [
              105.79904368368959,
              21.026669582873993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1813",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02648621369544,
          "to_latitude": 21.026669582873993,
          "to_longitude": 105.79904368368959,
          "from_longitude": 105.79966340356783
        }
      },
      {
        "id": 1808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79906368423292,
            21.02651776195501,
            105.7996646916377,
            21.026702301414502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79906368423292,
              21.026702301414502
            ],
            [
              105.7991758992516,
              21.026662588397688
            ],
            [
              105.79934057921687,
              21.026613794222865
            ],
            [
              105.79948510596081,
              21.02657097195116
            ],
            [
              105.7996646916377,
              21.02651776195501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1814",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026702301414502,
          "to_latitude": 21.02651776195501,
          "to_longitude": 105.7996646916377,
          "from_longitude": 105.79906368423292
        }
      },
      {
        "id": 1809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80328359660707,
            21.02617038391117,
            105.80403896972312,
            21.02676279550846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80328359660707,
              21.02624915525349
            ],
            [
              105.80336912708314,
              21.026398881389436
            ],
            [
              105.80337738745541,
              21.02641334370383
            ],
            [
              105.8035819322475,
              21.026759173741482
            ],
            [
              105.80358407290201,
              21.02676279550846
            ],
            [
              105.80361050985957,
              21.026750028089904
            ],
            [
              105.80384098714752,
              21.02665709761288
            ],
            [
              105.80387673041987,
              21.02664268654585
            ],
            [
              105.80403896972312,
              21.02657124281351
            ],
            [
              105.80383830765169,
              21.026253193655965
            ],
            [
              105.80395503628237,
              21.026186683591167
            ],
            [
              105.80400870439678,
              21.026173138858645
            ],
            [
              105.80401961516634,
              21.02617038391117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1815",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 178,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02624915525349,
          "to_latitude": 21.02617038391117,
          "to_longitude": 105.80401961516634,
          "from_longitude": 105.80328359660707
        }
      },
      {
        "id": 1810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80336912708314,
            21.026307081680095,
            105.80355687369656,
            21.026398881389436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80336912708314,
              21.026398881389436
            ],
            [
              105.80355687369656,
              21.026307081680095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1816",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026398881389436,
          "to_latitude": 21.026307081680095,
          "to_longitude": 105.80355687369656,
          "from_longitude": 105.80336912708314
        }
      },
      {
        "id": 1811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80350481964808,
            21.026759173741482,
            105.8035819322475,
            21.0267985711373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80350481964808,
              21.0267985711373
            ],
            [
              105.8035819322475,
              21.026759173741482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1817",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0267985711373,
          "to_latitude": 21.026759173741482,
          "to_longitude": 105.8035819322475,
          "from_longitude": 105.80350481964808
        }
      },
      {
        "id": 1812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80534012780325,
            21.023889097097506,
            105.80540484935975,
            21.023894377084922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80534012780325,
              21.023889097097506
            ],
            [
              105.80535620497584,
              21.023890409230596
            ],
            [
              105.805402125722,
              21.023894155143473
            ],
            [
              105.80540484935975,
              21.023894377084922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1818",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023889097097506,
          "to_latitude": 21.023894377084922,
          "to_longitude": 105.80540484935975,
          "from_longitude": 105.80534012780325
        }
      },
      {
        "id": 1813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8053372071305,
            21.02364111820349,
            105.80534932002296,
            21.02396788872433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80534932002296,
              21.02364111820349
            ],
            [
              105.80534931241797,
              21.023641314240127
            ],
            [
              105.80534919360531,
              21.0236444471998
            ],
            [
              105.80534297536416,
              21.023812275757948
            ],
            [
              105.80534012780325,
              21.023889097097506
            ],
            [
              105.8053372071305,
              21.02396788872433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1819",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02364111820349,
          "to_latitude": 21.02396788872433,
          "to_longitude": 105.8053372071305,
          "from_longitude": 105.80534932002296
        }
      },
      {
        "id": 1814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80539325758109,
            21.02396029303475,
            105.80584777034399,
            21.023997398237334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80584777034399,
              21.02396029303475
            ],
            [
              105.80556476525518,
              21.023977028361767
            ],
            [
              105.80540795711873,
              21.023995653137455
            ],
            [
              105.80539325758109,
              21.023997398237334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1820",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02396029303475,
          "to_latitude": 21.023997398237334,
          "to_longitude": 105.80539325758109,
          "from_longitude": 105.80584777034399
        }
      },
      {
        "id": 1815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80539144330704,
            21.02392995785728,
            105.80605277965431,
            21.024013524943925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.806048337182,
              21.02392995785728
            ],
            [
              105.80605277965431,
              21.023978087915854
            ],
            [
              105.80556481282404,
              21.024013275603817
            ],
            [
              105.80540757200721,
              21.02401350275904
            ],
            [
              105.80539144330704,
              21.024013524943925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1821",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02392995785728,
          "to_latitude": 21.024013524943925,
          "to_longitude": 105.80539144330704,
          "from_longitude": 105.806048337182
        }
      },
      {
        "id": 1816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538369179267,
            21.02382573948063,
            105.80625017091437,
            21.02418442850653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80538369179267,
              21.024180656238137
            ],
            [
              105.80540117174202,
              21.024181280688808
            ],
            [
              105.80548946147412,
              21.02418442850653
            ],
            [
              105.80578424507792,
              21.024144317031556
            ],
            [
              105.80580533390415,
              21.02415576127856
            ],
            [
              105.80625017091437,
              21.024106249829135
            ],
            [
              105.80621665595763,
              21.02382573948063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1822",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024180656238137,
          "to_latitude": 21.02382573948063,
          "to_longitude": 105.80621665595763,
          "from_longitude": 105.80538369179267
        }
      },
      {
        "id": 1817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538362244442,
            21.024120207266503,
            105.80624563031334,
            21.024190160762206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80538362244442,
              21.024183147671067
            ],
            [
              105.80540069569189,
              21.024183992629037
            ],
            [
              105.80543579264777,
              21.024190160762206
            ],
            [
              105.80550600961888,
              21.024186375170064
            ],
            [
              105.80577256675481,
              21.024151177285365
            ],
            [
              105.80579696394308,
              21.024161736953168
            ],
            [
              105.80624563031334,
              21.024120207266503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1823",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024183147671067,
          "to_latitude": 21.024120207266503,
          "to_longitude": 105.80624563031334,
          "from_longitude": 105.80538362244442
        }
      },
      {
        "id": 1818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538957223024,
            21.02471068628455,
            105.80582974757796,
            21.02507860874712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80582974757796,
              21.02507860874712
            ],
            [
              105.80582033110875,
              21.02471395319063
            ],
            [
              105.80556573583105,
              21.024713411569557
            ],
            [
              105.8054107418476,
              21.024711015065126
            ],
            [
              105.80538957223024,
              21.02471068628455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1824",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 130,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02507860874712,
          "to_latitude": 21.02471068628455,
          "to_longitude": 105.80538957223024,
          "from_longitude": 105.80582974757796
        }
      },
      {
        "id": 1819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80540914998193,
            21.025050939118184,
            105.80546159059743,
            21.025054868192804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80546159059743,
              21.025054868192804
            ],
            [
              105.80542540763676,
              21.025052158257036
            ],
            [
              105.80540914998193,
              21.025050939118184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1825",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025054868192804,
          "to_latitude": 21.025050939118184,
          "to_longitude": 105.80540914998193,
          "from_longitude": 105.80546159059743
        }
      },
      {
        "id": 1820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8054536455731,
            21.02474046396794,
            105.80546904241167,
            21.02534980023162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8054536455731,
              21.02474046396794
            ],
            [
              105.80546159059743,
              21.025054868192804
            ],
            [
              105.80546904241167,
              21.02534980023162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1826",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02474046396794,
          "to_latitude": 21.02534980023162,
          "to_longitude": 105.80546904241167,
          "from_longitude": 105.8054536455731
        }
      },
      {
        "id": 1821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80541865255867,
            21.025096471141865,
            105.80583753736055,
            21.025377220706652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80541865255867,
              21.025371095847653
            ],
            [
              105.80544087949492,
              21.025371421420942
            ],
            [
              105.80583753736055,
              21.025377220706652
            ],
            [
              105.8058302880338,
              21.025096471141865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_04_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1827",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 125,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025371095847653,
          "to_latitude": 21.025096471141865,
          "to_longitude": 105.8058302880338,
          "from_longitude": 105.80541865255867
        }
      },
      {
        "id": 1822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80541899474036,
            21.02540058734701,
            105.8058665719102,
            21.025411998562838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8058665719102,
              21.02540859495994
            ],
            [
              105.80557559536233,
              21.025411998562838
            ],
            [
              105.80546604726031,
              21.025407196522863
            ],
            [
              105.80544576059594,
              21.025404348078833
            ],
            [
              105.80541899474036,
              21.02540058734701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1828",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02540859495994,
          "to_latitude": 21.02540058734701,
          "to_longitude": 105.80541899474036,
          "from_longitude": 105.8058665719102
        }
      },
      {
        "id": 1823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80541053663806,
            21.025413987959688,
            105.80592222756314,
            21.02573557399324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80541053663806,
              21.025413987959688
            ],
            [
              105.80542395273783,
              21.025427772721105
            ],
            [
              105.80544495227394,
              21.025460349363303
            ],
            [
              105.80547563939199,
              21.02573557399324
            ],
            [
              105.80592222756314,
              21.025671625911425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1829",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025413987959688,
          "to_latitude": 21.025671625911425,
          "to_longitude": 105.80592222756314,
          "from_longitude": 105.80541053663806
        }
      },
      {
        "id": 1824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80445333338756,
            21.023272716745982,
            105.80512796879735,
            21.02331543341537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80512796879735,
              21.023272716745982
            ],
            [
              105.80450023028702,
              21.023302132838342
            ],
            [
              105.8044563811534,
              21.023314569964647
            ],
            [
              105.80445333338756,
              21.02331543341537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_141_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1830",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023272716745982,
          "to_latitude": 21.02331543341537,
          "to_longitude": 105.80445333338756,
          "from_longitude": 105.80512796879735
        }
      },
      {
        "id": 1825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80512096474138,
            21.023124955482,
            105.80514247877522,
            21.023499204020084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80512096474138,
              21.023124955482
            ],
            [
              105.80512796879735,
              21.023272716745982
            ],
            [
              105.80513060521345,
              21.02332835670531
            ],
            [
              105.80514247877522,
              21.023499204020084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1831",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023124955482,
          "to_latitude": 21.023499204020084,
          "to_longitude": 105.80514247877522,
          "from_longitude": 105.80512096474138
        }
      },
      {
        "id": 1826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80443082296478,
            21.02279642840825,
            105.8051168053052,
            21.022829150750812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8051168053052,
              21.02279642840825
            ],
            [
              105.80447845270423,
              21.022829150750812
            ],
            [
              105.80444383699869,
              21.02280915802091
            ],
            [
              105.80443269077362,
              21.022809578353364
            ],
            [
              105.80443082296478,
              21.022809647703795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_146_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1832",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02279642840825,
          "to_latitude": 21.022809647703795,
          "to_longitude": 105.80443082296478,
          "from_longitude": 105.8051168053052
        }
      },
      {
        "id": 1827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80444875667008,
            21.02310939366849,
            105.8051301412935,
            21.023153428447735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80444875667008,
              21.023153428447735
            ],
            [
              105.80445027173253,
              21.023153056377293
            ],
            [
              105.80449956351111,
              21.023140931316956
            ],
            [
              105.80477012544125,
              21.023129688697445
            ],
            [
              105.80499959486566,
              21.023117892273167
            ],
            [
              105.8051301412935,
              21.02310939366849
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_144_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1833",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023153428447735,
          "to_latitude": 21.02310939366849,
          "to_longitude": 105.8051301412935,
          "from_longitude": 105.80444875667008
        }
      },
      {
        "id": 1828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80443586757728,
            21.023105632565688,
            105.80445175272136,
            21.023106207184576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80443586757728,
              21.023106207184576
            ],
            [
              105.80444290797828,
              21.023105952594104
            ],
            [
              105.80444784491901,
              21.02310577479124
            ],
            [
              105.80445175272136,
              21.023105632565688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_145_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1834",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023106207184576,
          "to_latitude": 21.023105632565688,
          "to_longitude": 105.80445175272136,
          "from_longitude": 105.80443586757728
        }
      },
      {
        "id": 1829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80384296172865,
            21.023149495471603,
            105.80443660198509,
            21.02317159156433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80384296172865,
              21.02317159156433
            ],
            [
              105.80443660198509,
              21.023149495471603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_145_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1835",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02317159156433,
          "to_latitude": 21.023149495471603,
          "to_longitude": 105.80443660198509,
          "from_longitude": 105.80384296172865
        }
      },
      {
        "id": 1830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80413485906536,
            21.02314031712346,
            105.80445094533394,
            21.023192361712148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80445094533394,
              21.023192361712148
            ],
            [
              105.80444894154401,
              21.02319200989819
            ],
            [
              105.80444570842671,
              21.02319185538324
            ],
            [
              105.80444093845514,
              21.02314031712346
            ],
            [
              105.80443644989107,
              21.023140511773242
            ],
            [
              105.80413485906536,
              21.023153578769104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_143_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1836",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023192361712148,
          "to_latitude": 21.023153578769104,
          "to_longitude": 105.80413485906536,
          "from_longitude": 105.80445094533394
        }
      },
      {
        "id": 1831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8038418445765,
            21.02317394904033,
            105.80445098969041,
            21.023194891446433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8038418445765,
              21.023191097557973
            ],
            [
              105.80443612424297,
              21.02317394904033
            ],
            [
              105.80444132607643,
              21.023194454829326
            ],
            [
              105.8044486677828,
              21.02319477507341
            ],
            [
              105.80445098969041,
              21.023194891446433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_142_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1837",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023191097557973,
          "to_latitude": 21.023194891446433,
          "to_longitude": 105.80445098969041,
          "from_longitude": 105.8038418445765
        }
      },
      {
        "id": 1832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80380843019557,
            21.022634178510504,
            105.80442772768093,
            21.022660547918615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80442772768093,
              21.022634178510504
            ],
            [
              105.80441968827353,
              21.02263452182492
            ],
            [
              105.80380843019557,
              21.022660547918615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_147_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1838",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022634178510504,
          "to_latitude": 21.022660547918615,
          "to_longitude": 105.80380843019557,
          "from_longitude": 105.80442772768093
        }
      },
      {
        "id": 1833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80396527658131,
            21.02259550315101,
            105.80442715463394,
            21.022627202737542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80396527658131,
              21.022627202737542
            ],
            [
              105.80403146571109,
              21.02262408081411
            ],
            [
              105.80441686633006,
              21.0225962472965
            ],
            [
              105.80442715463394,
              21.02259550315101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_148_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1839",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022627202737542,
          "to_latitude": 21.02259550315101,
          "to_longitude": 105.80442715463394,
          "from_longitude": 105.80396527658131
        }
      },
      {
        "id": 1834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80397270717066,
            21.022327134934862,
            105.80441710122206,
            21.02235422783792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80441710122206,
              21.02235366869385
            ],
            [
              105.8044033410996,
              21.02235422783792
            ],
            [
              105.80438798773233,
              21.022327134934862
            ],
            [
              105.80397270717066,
              21.022350946627842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_149_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1840",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02235366869385,
          "to_latitude": 21.022350946627842,
          "to_longitude": 105.80397270717066,
          "from_longitude": 105.80441710122206
        }
      },
      {
        "id": 1835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8039342846433,
            21.022286049415456,
            105.80441422479106,
            21.022315085789938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8039342846433,
              21.022315085789938
            ],
            [
              105.80439778596379,
              21.0222870448421
            ],
            [
              105.80441422479106,
              21.022286049415456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_150_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1841",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022315085789938,
          "to_latitude": 21.022286049415456,
          "to_longitude": 105.80441422479106,
          "from_longitude": 105.8039342846433
        }
      },
      {
        "id": 1836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8038813620265,
            21.021977035991853,
            105.80441012540496,
            21.022189687948067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80441012540496,
              21.022189687948067
            ],
            [
              105.80439238358717,
              21.02218916036963
            ],
            [
              105.8043855120787,
              21.02216816930894
            ],
            [
              105.80436685036165,
              21.022105549084312
            ],
            [
              105.80395095095483,
              21.021996422765937
            ],
            [
              105.8038813620265,
              21.021977035991853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1842",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022189687948067,
          "to_latitude": 21.021977035991853,
          "to_longitude": 105.8038813620265,
          "from_longitude": 105.80441012540496
        }
      },
      {
        "id": 1837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80147527281586,
            21.021872788268528,
            105.80158738910785,
            21.021927872670467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80158738910785,
              21.021927872670467
            ],
            [
              105.80147527281586,
              21.021872788268528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M09_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1843",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021927872670467,
          "to_latitude": 21.021872788268528,
          "to_longitude": 105.80147527281586,
          "from_longitude": 105.80158738910785
        }
      },
      {
        "id": 1838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80422302572686,
            21.024006541238357,
            105.8042442980392,
            21.02430986477487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8042442980392,
              21.024006541238357
            ],
            [
              105.80422302572686,
              21.02430986477487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_135_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1844",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024006541238357,
          "to_latitude": 21.02430986477487,
          "to_longitude": 105.80422302572686,
          "from_longitude": 105.8042442980392
        }
      },
      {
        "id": 1839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8043300611342,
            21.024137123710315,
            105.804654666018,
            21.02414578118151
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8043300611342,
              21.024137123710315
            ],
            [
              105.8043323394721,
              21.02413718519365
            ],
            [
              105.804654666018,
              21.02414578118151
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_137_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1845",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024137123710315,
          "to_latitude": 21.02414578118151,
          "to_longitude": 105.804654666018,
          "from_longitude": 105.8043300611342
        }
      },
      {
        "id": 1840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80436624545081,
            21.024417700471798,
            105.80437631814512,
            21.02441798612341
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80436624545081,
              21.024417700471798
            ],
            [
              105.80436876627347,
              21.02441777254964
            ],
            [
              105.80437479410686,
              21.02441794275073
            ],
            [
              105.80437631814512,
              21.02441798612341
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1846",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024417700471798,
          "to_latitude": 21.02441798612341,
          "to_longitude": 105.80437631814512,
          "from_longitude": 105.80436624545081
        }
      },
      {
        "id": 1841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80437022256228,
            21.02434745110884,
            105.80468010265889,
            21.02435134737704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80437022256228,
              21.02434745110884
            ],
            [
              105.80437153386796,
              21.024347470197306
            ],
            [
              105.8046484792595,
              21.02435134737704
            ],
            [
              105.80468010265889,
              21.024351101111485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_139_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1847",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02434745110884,
          "to_latitude": 21.024351101111485,
          "to_longitude": 105.80468010265889,
          "from_longitude": 105.80437022256228
        }
      },
      {
        "id": 1842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80436289786886,
            21.024356261216987,
            105.80436888262795,
            21.024495731785464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80436888262795,
              21.024356261216987
            ],
            [
              105.80436624545081,
              21.024417700471798
            ],
            [
              105.80436588881108,
              21.02442599741153
            ],
            [
              105.80436289786886,
              21.024495731785464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1848",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024356261216987,
          "to_latitude": 21.024495731785464,
          "to_longitude": 105.80436289786886,
          "from_longitude": 105.80436888262795
        }
      },
      {
        "id": 1843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80424835682125,
            21.024329537325833,
            105.80425372951922,
            21.024490770103107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80425372951922,
              21.024329537325833
            ],
            [
              105.80425344865337,
              21.0243379730045
            ],
            [
              105.80424835682125,
              21.024490770103107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1849",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024329537325833,
          "to_latitude": 21.024490770103107,
          "to_longitude": 105.80424835682125,
          "from_longitude": 105.80425372951922
        }
      },
      {
        "id": 1844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80413242549385,
            21.024324222584564,
            105.80414144259157,
            21.02449348423262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80414144259157,
              21.024324222584564
            ],
            [
              105.80414096798616,
              21.024333127051772
            ],
            [
              105.80413242549385,
              21.02449348423262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1850",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024324222584564,
          "to_latitude": 21.02449348423262,
          "to_longitude": 105.80413242549385,
          "from_longitude": 105.80414144259157
        }
      },
      {
        "id": 1845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80390242686845,
            21.0243131602007,
            105.80390770340247,
            21.024485220430257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80390770340247,
              21.0243131602007
            ],
            [
              105.80390739960856,
              21.02432306463782
            ],
            [
              105.80390242686845,
              21.024485220430257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1851",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0243131602007,
          "to_latitude": 21.024485220430257,
          "to_longitude": 105.80390242686845,
          "from_longitude": 105.80390770340247
        }
      },
      {
        "id": 1846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80374333995594,
            21.02430378817533,
            105.8043457671294,
            21.024332346532567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80374333995594,
              21.024305380756
            ],
            [
              105.80390770340247,
              21.0243131602007
            ],
            [
              105.80414144259157,
              21.024324222584564
            ],
            [
              105.80425372951922,
              21.024329537325833
            ],
            [
              105.8043130940877,
              21.024332346532567
            ],
            [
              105.80434220615652,
              21.024306900381685
            ],
            [
              105.8043457671294,
              21.02430378817533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_138_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1852",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024305380756,
          "to_latitude": 21.02430378817533,
          "to_longitude": 105.8043457671294,
          "from_longitude": 105.80374333995594
        }
      },
      {
        "id": 1847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80412604445516,
            21.023983795711782,
            105.80434023043746,
            21.024009521460158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80434023043746,
              21.023984173430943
            ],
            [
              105.80433757756815,
              21.023983873460057
            ],
            [
              105.80433472771989,
              21.023983795711782
            ],
            [
              105.80430512755454,
              21.024009521460158
            ],
            [
              105.8042442980392,
              21.024006541238357
            ],
            [
              105.80415779765926,
              21.024002304048167
            ],
            [
              105.80412604445516,
              21.024000576370074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_135_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1853",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023984173430943,
          "to_latitude": 21.024000576370074,
          "to_longitude": 105.80412604445516,
          "from_longitude": 105.80434023043746
        }
      },
      {
        "id": 1848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8043340676542,
            21.023987715682118,
            105.8045217521586,
            21.024127212749384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80433993925745,
              21.02398817520846
            ],
            [
              105.8043368776036,
              21.02398788710637
            ],
            [
              105.80433454576836,
              21.023987715682118
            ],
            [
              105.8043340676542,
              21.02399345344624
            ],
            [
              105.80433946167103,
              21.023994728585706
            ],
            [
              105.80438941607055,
              21.024006541399583
            ],
            [
              105.80452033826074,
              21.024015567715836
            ],
            [
              105.8045217521586,
              21.024127212749384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1854",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02398817520846,
          "to_latitude": 21.024127212749384,
          "to_longitude": 105.8045217521586,
          "from_longitude": 105.80433993925745
        }
      },
      {
        "id": 1849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80481531963441,
            21.023513467944248,
            105.80481573861121,
            21.02352215447162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80481573861121,
              21.023513467944248
            ],
            [
              105.80481531963441,
              21.02352215447162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1855",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023513467944248,
          "to_latitude": 21.02352215447162,
          "to_longitude": 105.80481531963441,
          "from_longitude": 105.80481573861121
        }
      },
      {
        "id": 1850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80453954148895,
            21.023492240041595,
            105.8051249643368,
            21.023531912457003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80453954148895,
              21.023492240041595
            ],
            [
              105.80478643559208,
              21.023511719760037
            ],
            [
              105.80481573861121,
              21.023513467944248
            ],
            [
              105.8051249643368,
              21.023531912457003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1856",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023492240041595,
          "to_latitude": 21.023531912457003,
          "to_longitude": 105.8051249643368,
          "from_longitude": 105.80453954148895
        }
      },
      {
        "id": 1851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80422383042067,
            21.023449125875608,
            105.80422927543827,
            21.02348694181619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80422927543827,
              21.023449125875608
            ],
            [
              105.80422908529845,
              21.023450443682425
            ],
            [
              105.80422524936198,
              21.023477078169794
            ],
            [
              105.80422502867857,
              21.0234786201218
            ],
            [
              105.80422383042067,
              21.02348694181619
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_134_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1857",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023449125875608,
          "to_latitude": 21.02348694181619,
          "to_longitude": 105.80422383042067,
          "from_longitude": 105.80422927543827
        }
      },
      {
        "id": 1852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8037917853724,
            21.02338864902975,
            105.80443123195303,
            21.023477043400394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8037917853724,
              21.02338864902975
            ],
            [
              105.80422927543827,
              21.023449125875608
            ],
            [
              105.80443123195303,
              21.023477043400394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_134_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1858",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02338864902975,
          "to_latitude": 21.023477043400394,
          "to_longitude": 105.80443123195303,
          "from_longitude": 105.8037917853724
        }
      },
      {
        "id": 1853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.801939625563,
            21.022583252063438,
            105.80212937275691,
            21.022667826908695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.801939625563,
              21.022583252063438
            ],
            [
              105.80212937275691,
              21.02263505080168
            ],
            [
              105.8021185150617,
              21.022667826908695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Ngõ 159, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1859",
          "diaChiLapD": "Ngõ 159, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022583252063438,
          "to_latitude": 21.022667826908695,
          "to_longitude": 105.8021185150617,
          "from_longitude": 105.801939625563
        }
      },
      {
        "id": 1854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80187749820067,
            21.022483540327556,
            105.80214793101241,
            21.02254974088211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80187749820067,
              21.022483540327556
            ],
            [
              105.8019430114043,
              21.022506923153863
            ],
            [
              105.8019589113606,
              21.02250013357822
            ],
            [
              105.80198053937377,
              21.022490899047344
            ],
            [
              105.80214793101241,
              21.02254974088211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Ngõ 159, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1860",
          "diaChiLapD": "Ngõ 159, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022483540327556,
          "to_latitude": 21.02254974088211,
          "to_longitude": 105.80214793101241,
          "from_longitude": 105.80187749820067
        }
      },
      {
        "id": 1855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80182498926264,
            21.02250013357822,
            105.8019589113606,
            21.02316066885586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80182498926264,
              21.02316066885586
            ],
            [
              105.80182622972616,
              21.02314024731557
            ],
            [
              105.80183132557077,
              21.023056454191487
            ],
            [
              105.80191038181984,
              21.02270928656778
            ],
            [
              105.801939625563,
              21.022583252063438
            ],
            [
              105.8019589113606,
              21.02250013357822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_84_Ngõ 159, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1861",
          "diaChiLapD": "Ngõ 159, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02316066885586,
          "to_latitude": 21.02250013357822,
          "to_longitude": 105.8019589113606,
          "from_longitude": 105.80182498926264
        }
      },
      {
        "id": 1856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80082363109275,
            21.022865879419243,
            105.80118552168634,
            21.022992787736143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80082363109275,
              21.022865879419243
            ],
            [
              105.8008645931455,
              21.022878590986277
            ],
            [
              105.80089612940466,
              21.02288837801313
            ],
            [
              105.8010158363076,
              21.022931566720796
            ],
            [
              105.80118552168634,
              21.022992787736143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1862",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022865879419243,
          "to_latitude": 21.022992787736143,
          "to_longitude": 105.80118552168634,
          "from_longitude": 105.80082363109275
        }
      },
      {
        "id": 1857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80061522767515,
            21.02281708709039,
            105.80081880778052,
            21.023402973320025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80081880778052,
              21.02281708709039
            ],
            [
              105.80080972837578,
              21.0228332687448
            ],
            [
              105.8007610161255,
              21.02292008709417
            ],
            [
              105.80069279156913,
              21.02308739013579
            ],
            [
              105.80066344068727,
              21.023147096295435
            ],
            [
              105.80061522767515,
              21.023224573764924
            ],
            [
              105.80063137440953,
              21.02325748731114
            ],
            [
              105.80065860707846,
              21.023316149418623
            ],
            [
              105.80071577747822,
              21.02335419075403
            ],
            [
              105.80066032034964,
              21.023402973320025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_87_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1863",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02281708709039,
          "to_latitude": 21.023402973320025,
          "to_longitude": 105.80066032034964,
          "from_longitude": 105.80081880778052
        }
      },
      {
        "id": 1858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80046455424944,
            21.022697472569924,
            105.80048030621568,
            21.022741010111986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80046455424944,
              21.022741010111986
            ],
            [
              105.80047031334128,
              21.02272509068324
            ],
            [
              105.80047385504706,
              21.022715297457673
            ],
            [
              105.8004767692144,
              21.02270724590244
            ],
            [
              105.80047739963832,
              21.022705504218465
            ],
            [
              105.80048030621568,
              21.022697472569924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_85_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1864",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022741010111986,
          "to_latitude": 21.022697472569924,
          "to_longitude": 105.80048030621568,
          "from_longitude": 105.80046455424944
        }
      },
      {
        "id": 1859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80023003264955,
            21.022678043092757,
            105.80028531911553,
            21.02285089143316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80028531911553,
              21.022678043092757
            ],
            [
              105.80023003264955,
              21.02285089143316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1865",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022678043092757,
          "to_latitude": 21.02285089143316,
          "to_longitude": 105.80023003264955,
          "from_longitude": 105.80028531911553
        }
      },
      {
        "id": 1860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80040559044129,
            21.022733898614096,
            105.80045415596075,
            21.022837386677608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80044352185102,
              21.022733898614096
            ],
            [
              105.80040559044129,
              21.022813511648746
            ],
            [
              105.80045415596075,
              21.022837386677608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1866",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022733898614096,
          "to_latitude": 21.022837386677608,
          "to_longitude": 105.80045415596075,
          "from_longitude": 105.80044352185102
        }
      },
      {
        "id": 1861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.800249653544,
            21.02266545097969,
            105.80077585108099,
            21.022854363302006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.800249653544,
              21.02266545097969
            ],
            [
              105.80028531911553,
              21.022678043092757
            ],
            [
              105.8004275246202,
              21.02272825103675
            ],
            [
              105.80044352185102,
              21.022733898614096
            ],
            [
              105.80045278208888,
              21.022737168079477
            ],
            [
              105.80046455424944,
              21.022741010111986
            ],
            [
              105.80062697851415,
              21.022794019385362
            ],
            [
              105.80068525105733,
              21.022816192130467
            ],
            [
              105.80077585108099,
              21.022854363302006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_85_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1867",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02266545097969,
          "to_latitude": 21.022854363302006,
          "to_longitude": 105.80077585108099,
          "from_longitude": 105.800249653544
        }
      },
      {
        "id": 1862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80398519350881,
            21.02025744991766,
            105.80413090878496,
            21.020469776639995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80398519350881,
              21.02025744991766
            ],
            [
              105.80413090878496,
              21.020315685087485
            ],
            [
              105.80406256055308,
              21.020469776639995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M47_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1868",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02025744991766,
          "to_latitude": 21.020469776639995,
          "to_longitude": 105.80406256055308,
          "from_longitude": 105.80398519350881
        }
      },
      {
        "id": 1863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80427676428907,
            21.01937713713035,
            105.80434576905586,
            21.019491923333526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80427676428907,
              21.019491923333526
            ],
            [
              105.80431277597185,
              21.019441248995694
            ],
            [
              105.80434576905586,
              21.01937713713035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M55_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1869",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019491923333526,
          "to_latitude": 21.01937713713035,
          "to_longitude": 105.80434576905586,
          "from_longitude": 105.80427676428907
        }
      },
      {
        "id": 1864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80205063388449,
            21.020372718664614,
            105.80211401076593,
            21.02046761465106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80211401076593,
              21.020372718664614
            ],
            [
              105.80205063388449,
              21.02045523940243
            ],
            [
              105.80206574533923,
              21.02046761465106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 886, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1870",
          "diaChiLapD": "Ngõ 886, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020372718664614,
          "to_latitude": 21.02046761465106,
          "to_longitude": 105.80206574533923,
          "from_longitude": 105.80211401076593
        }
      },
      {
        "id": 1865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80166792309039,
            21.02066212995372,
            105.80168957938808,
            21.02067352599859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80168957938808,
              21.02067352599859
            ],
            [
              105.80168790083773,
              21.020672644228686
            ],
            [
              105.80168469034466,
              21.020670953159442
            ],
            [
              105.80166792309039,
              21.02066212995372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_29_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1871",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02067352599859,
          "to_latitude": 21.02066212995372,
          "to_longitude": 105.80166792309039,
          "from_longitude": 105.80168957938808
        }
      },
      {
        "id": 1866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80214467497719,
            21.020070560860862,
            105.80235713054982,
            21.020397784102062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80214467497719,
              21.020397784102062
            ],
            [
              105.80220818832488,
              21.02032568234319
            ],
            [
              105.80226855099531,
              21.020230175582917
            ],
            [
              105.80235713054982,
              21.020070560860862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 886, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1872",
          "diaChiLapD": "Ngõ 886, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020397784102062,
          "to_latitude": 21.020070560860862,
          "to_longitude": 105.80235713054982,
          "from_longitude": 105.80214467497719
        }
      },
      {
        "id": 1867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80191371067849,
            21.02023779838006,
            105.80233752334382,
            21.020546419564013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80191371067849,
              21.02023779838006
            ],
            [
              105.80192822250486,
              21.020248321235407
            ],
            [
              105.80201464256321,
              21.020310977277962
            ],
            [
              105.80210952374135,
              21.020369050130764
            ],
            [
              105.80211401076593,
              21.020372718664614
            ],
            [
              105.80214467497719,
              21.020397784102062
            ],
            [
              105.80215567900333,
              21.020406778956374
            ],
            [
              105.80221184272159,
              21.020458365785966
            ],
            [
              105.80221543603378,
              21.020461666401296
            ],
            [
              105.80224407225415,
              21.0204911924991
            ],
            [
              105.80233752334382,
              21.020546419564013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_28_Ngõ 886, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1873",
          "diaChiLapD": "Ngõ 886, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02023779838006,
          "to_latitude": 21.020546419564013,
          "to_longitude": 105.80233752334382,
          "from_longitude": 105.80191371067849
        }
      },
      {
        "id": 1868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80187440459363,
            21.020310977277962,
            105.80201464256321,
            21.020557315083213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80187440459363,
              21.020557315083213
            ],
            [
              105.80194029388453,
              21.020438741896662
            ],
            [
              105.80201464256321,
              21.020310977277962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 886, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1874",
          "diaChiLapD": "Ngõ 886, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020557315083213,
          "to_latitude": 21.020310977277962,
          "to_longitude": 105.80201464256321,
          "from_longitude": 105.80187440459363
        }
      },
      {
        "id": 1869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80140318214795,
            21.021153418225868,
            105.80165526854285,
            21.021569017793585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80165526854285,
              21.021153418225868
            ],
            [
              105.80152436024025,
              21.021368764430587
            ],
            [
              105.80140318214795,
              21.021569017793585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1875",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021153418225868,
          "to_latitude": 21.021569017793585,
          "to_longitude": 105.80140318214795,
          "from_longitude": 105.80165526854285
        }
      },
      {
        "id": 1870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8014289639124,
            21.021043752321006,
            105.80193185003276,
            21.021299899970792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8014289639124,
              21.021043752321006
            ],
            [
              105.80143838105589,
              21.021048317130823
            ],
            [
              105.80165526854285,
              21.021153418225868
            ],
            [
              105.80170425119542,
              21.021178731596677
            ],
            [
              105.80193185003276,
              21.021299899970792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_30_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1876",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021043752321006,
          "to_latitude": 21.021299899970792,
          "to_longitude": 105.80193185003276,
          "from_longitude": 105.8014289639124
        }
      },
      {
        "id": 1871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8012471345184,
            21.021368657021704,
            105.80127667147941,
            21.021384023622602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80127667147941,
              21.021384023622602
            ],
            [
              105.80127282344316,
              21.02138202122548
            ],
            [
              105.80127088930281,
              21.021381016010043
            ],
            [
              105.80126895708152,
              21.021380009882296
            ],
            [
              105.8012471345184,
              21.021368657021704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1877",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021384023622602,
          "to_latitude": 21.021368657021704,
          "to_longitude": 105.8012471345184,
          "from_longitude": 105.80127667147941
        }
      },
      {
        "id": 1872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80117970407156,
            21.02108148714241,
            105.80145241562283,
            21.02155058973614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80117970407156,
              21.02155058973614
            ],
            [
              105.80121785862006,
              21.021487485619808
            ],
            [
              105.80127667147941,
              21.021384023622602
            ],
            [
              105.80128817062851,
              21.02136379491535
            ],
            [
              105.80130073889973,
              21.021342194584378
            ],
            [
              105.80136715185654,
              21.0212280404506
            ],
            [
              105.80145241562283,
              21.02108148714241
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1878",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02155058973614,
          "to_latitude": 21.02108148714241,
          "to_longitude": 105.80145241562283,
          "from_longitude": 105.80117970407156
        }
      },
      {
        "id": 1873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80094475927643,
            21.02186534813834,
            105.80096299787219,
            21.021875681908657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80096299787219,
              21.021875681908657
            ],
            [
              105.80095815309583,
              21.021872937227634
            ],
            [
              105.80095583540827,
              21.02187162581363
            ],
            [
              105.80095352059705,
              21.021870312579594
            ],
            [
              105.80094475927643,
              21.02186534813834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1879",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021875681908657,
          "to_latitude": 21.02186534813834,
          "to_longitude": 105.80094475927643,
          "from_longitude": 105.80096299787219
        }
      },
      {
        "id": 1874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80092077037355,
            21.021573400429624,
            105.80116386668865,
            21.021931275827736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80092077037355,
              21.021931275827736
            ],
            [
              105.80096299787219,
              21.021875681908657
            ],
            [
              105.80099689065423,
              21.02183105992131
            ],
            [
              105.80103862161492,
              21.021767704161153
            ],
            [
              105.80104173809674,
              21.021763318756005
            ],
            [
              105.80108158676254,
              21.021707229300294
            ],
            [
              105.80116386668865,
              21.021573400429624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1880",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021931275827736,
          "to_latitude": 21.021573400429624,
          "to_longitude": 105.80116386668865,
          "from_longitude": 105.80092077037355
        }
      },
      {
        "id": 1875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8011571073573,
            21.02185166180444,
            105.80131020105853,
            21.022087711310004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8011571073573,
              21.022087711310004
            ],
            [
              105.80123131235479,
              21.02197041341477
            ],
            [
              105.80131020105853,
              21.02185166180444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Ngõ 942, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1881",
          "diaChiLapD": "Ngõ 942, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022087711310004,
          "to_latitude": 21.02185166180444,
          "to_longitude": 105.80131020105853,
          "from_longitude": 105.8011571073573
        }
      },
      {
        "id": 1876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80089045325137,
            21.021935556278756,
            105.80139365956381,
            21.022213947762094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80089045325137,
              21.021935556278756
            ],
            [
              105.80090081754543,
              21.021941478727186
            ],
            [
              105.80114702578393,
              21.022082133483103
            ],
            [
              105.8011571073573,
              21.022087711310004
            ],
            [
              105.80124484861689,
              21.022136253945266
            ],
            [
              105.80139365956381,
              21.022213947762094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_33_Ngõ 942, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1882",
          "diaChiLapD": "Ngõ 942, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021935556278756,
          "to_latitude": 21.022213947762094,
          "to_longitude": 105.80139365956381,
          "from_longitude": 105.80089045325137
        }
      },
      {
        "id": 1877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8007398387147,
            21.022111604855947,
            105.80076446145665,
            21.022129759462935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80076446145665,
              21.022129759462935
            ],
            [
              105.80076134486302,
              21.022127461867985
            ],
            [
              105.80075625227529,
              21.02212370853739
            ],
            [
              105.80075116160212,
              21.02211995339116
            ],
            [
              105.8007398387147,
              21.022111604855947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1883",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022129759462935,
          "to_latitude": 21.022111604855947,
          "to_longitude": 105.8007398387147,
          "from_longitude": 105.80076446145665
        }
      },
      {
        "id": 1878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80061290834868,
            21.021976808891335,
            105.80089019548375,
            21.022283231611816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80061290834868,
              21.022283231611816
            ],
            [
              105.80067625783302,
              21.022221593278527
            ],
            [
              105.8007530686121,
              21.022143618060944
            ],
            [
              105.80076446145665,
              21.022129759462935
            ],
            [
              105.80089019548375,
              21.021976808891335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1884",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022283231611816,
          "to_latitude": 21.021976808891335,
          "to_longitude": 105.80089019548375,
          "from_longitude": 105.80061290834868
        }
      },
      {
        "id": 1879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80035837668771,
            21.022504737173637,
            105.80037473646652,
            21.022522261425046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80037473646652,
              21.022522261425046
            ],
            [
              105.80036416825774,
              21.022510941301594
            ],
            [
              105.80035837668771,
              21.022504737173637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1885",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022522261425046,
          "to_latitude": 21.022504737173637,
          "to_longitude": 105.80035837668771,
          "from_longitude": 105.80037473646652
        }
      },
      {
        "id": 1880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80035177023163,
            21.02230150516794,
            105.8005988329864,
            21.02254717760174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80035177023163,
              21.02254717760174
            ],
            [
              105.80037473646652,
              21.022522261425046
            ],
            [
              105.80043788948436,
              21.02245387697612
            ],
            [
              105.80044463158373,
              21.022447122508876
            ],
            [
              105.800460627474,
              21.022431080839148
            ],
            [
              105.8004769071637,
              21.022417204680195
            ],
            [
              105.80051917260086,
              21.02238379671661
            ],
            [
              105.8005988329864,
              21.02230150516794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1886",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02254717760174,
          "to_latitude": 21.02230150516794,
          "to_longitude": 105.8005988329864,
          "from_longitude": 105.80035177023163
        }
      },
      {
        "id": 1881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80254818415877,
            21.019688323713495,
            105.80296169252027,
            21.019888359236496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80296169252027,
              21.019888359236496
            ],
            [
              105.80291954626824,
              21.019866537447356
            ],
            [
              105.80291269922652,
              21.01986299207487
            ],
            [
              105.80278344378353,
              21.019796066762936
            ],
            [
              105.80274320564635,
              21.019775233088602
            ],
            [
              105.80273720171174,
              21.019772124506044
            ],
            [
              105.80268347692278,
              21.01974430746524
            ],
            [
              105.80265703415135,
              21.019730615539725
            ],
            [
              105.80255555773047,
              21.019688323713495
            ],
            [
              105.80255011804954,
              21.01969825690315
            ],
            [
              105.80254818415877,
              21.019701789525772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1887",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019888359236496,
          "to_latitude": 21.019701789525772,
          "to_longitude": 105.80254818415877,
          "from_longitude": 105.80296169252027
        }
      },
      {
        "id": 1882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80282830455347,
            21.020094945032113,
            105.80283316666441,
            21.02009913564962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80282830455347,
              21.020094945032113
            ],
            [
              105.80283316666441,
              21.02009913564962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1888",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020094945032113,
          "to_latitude": 21.02009913564962,
          "to_longitude": 105.80283316666441,
          "from_longitude": 105.80282830455347
        }
      },
      {
        "id": 1883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8027158883847,
            21.01998002805819,
            105.80294235330165,
            21.020205063499386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8027158883847,
              21.020205063499386
            ],
            [
              105.80277500445943,
              21.020145588661777
            ],
            [
              105.80282830455347,
              21.020094945032113
            ],
            [
              105.80285669699295,
              21.020067967948428
            ],
            [
              105.80294235330165,
              21.01998002805819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1889",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020205063499386,
          "to_latitude": 21.01998002805819,
          "to_longitude": 105.80294235330165,
          "from_longitude": 105.8027158883847
        }
      },
      {
        "id": 1884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8025725434803,
            21.020336175268316,
            105.802579471408,
            21.020342429057493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8025725434803,
              21.020336175268316
            ],
            [
              105.80257599439496,
              21.02033929048253
            ],
            [
              105.8025786120691,
              21.02034165452832
            ],
            [
              105.802579471408,
              21.020342429057493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M36_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1890",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020336175268316,
          "to_latitude": 21.020342429057493,
          "to_longitude": 105.802579471408,
          "from_longitude": 105.8025725434803
        }
      },
      {
        "id": 1885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80202005061979,
            21.021195872849553,
            105.80202870245428,
            21.02119961297295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80202005061979,
              21.021195872849553
            ],
            [
              105.80202420249856,
              21.021197667860676
            ],
            [
              105.80202773536334,
              21.021199196628082
            ],
            [
              105.80202870245428,
              21.02119961297295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1891",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021195872849553,
          "to_latitude": 21.02119961297295,
          "to_longitude": 105.80202870245428,
          "from_longitude": 105.80202005061979
        }
      },
      {
        "id": 1886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80177653787241,
            21.021605264361636,
            105.80178586072842,
            21.02161053772701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80177653787241,
              21.021605264361636
            ],
            [
              105.80178205999528,
              21.02160838879565
            ],
            [
              105.80178586072842,
              21.02161053772701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M15_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1892",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021605264361636,
          "to_latitude": 21.02161053772701,
          "to_longitude": 105.80178586072842,
          "from_longitude": 105.80177653787241
        }
      },
      {
        "id": 1887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80154263965953,
            21.021501556905434,
            105.80176814043793,
            21.021618341159886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80176814043793,
              21.021618341159886
            ],
            [
              105.80168322541492,
              21.021573357739978
            ],
            [
              105.80162411070877,
              21.02153970202059
            ],
            [
              105.80154263965953,
              21.021501556905434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M15_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1893",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021618341159886,
          "to_latitude": 21.021501556905434,
          "to_longitude": 105.80154263965953,
          "from_longitude": 105.80176814043793
        }
      },
      {
        "id": 1888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8015730937584,
            21.0213577939978,
            105.80194167843236,
            21.021705905987552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8015730937584,
              21.021634853809747
            ],
            [
              105.8017128810555,
              21.021705905987552
            ],
            [
              105.80176814043793,
              21.021618341159886
            ],
            [
              105.80177422056947,
              21.021608703040883
            ],
            [
              105.80177653787241,
              21.021605264361636
            ],
            [
              105.80178755460823,
              21.021588917267163
            ],
            [
              105.8018427412593,
              21.021507016008172
            ],
            [
              105.80191529154524,
              21.021409098095734
            ],
            [
              105.80194167843236,
              21.0213577939978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M15_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1894",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021634853809747,
          "to_latitude": 21.0213577939978,
          "to_longitude": 105.80194167843236,
          "from_longitude": 105.8015730937584
        }
      },
      {
        "id": 1889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80165134287154,
            21.02181706474506,
            105.80165698834504,
            21.021819868858323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80165134287154,
              21.02181706474506
            ],
            [
              105.80165216466163,
              21.0218174727445
            ],
            [
              105.80165546437108,
              21.021819112812135
            ],
            [
              105.80165698834504,
              21.021819868858323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M09_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1895",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02181706474506,
          "to_latitude": 21.021819868858323,
          "to_longitude": 105.80165698834504,
          "from_longitude": 105.80165134287154
        }
      },
      {
        "id": 1890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80144309666181,
            21.021727513129445,
            105.80170155669468,
            21.02218978104805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80144309666181,
              21.02218978104805
            ],
            [
              105.80149020450555,
              21.022102573555895
            ],
            [
              105.80157144259687,
              21.021956537744874
            ],
            [
              105.80158738910785,
              21.021927872670467
            ],
            [
              105.80158908037566,
              21.021924832558522
            ],
            [
              105.80165134287154,
              21.02181706474506
            ],
            [
              105.80166179429025,
              21.02179897438016
            ],
            [
              105.80170155669468,
              21.021727513129445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M09_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1896",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02218978104805,
          "to_latitude": 21.021727513129445,
          "to_longitude": 105.80170155669468,
          "from_longitude": 105.80144309666181
        }
      },
      {
        "id": 1891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80129223102665,
            21.022525598124723,
            105.80130280946373,
            21.022529818756738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80129223102665,
              21.022525598124723
            ],
            [
              105.80129765183781,
              21.022527761111395
            ],
            [
              105.801302202124,
              21.022529577746084
            ],
            [
              105.80130246819529,
              21.02252968307364
            ],
            [
              105.80130280946373,
              21.022529818756738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M01_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1897",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022525598124723,
          "to_latitude": 21.022529818756738,
          "to_longitude": 105.80130280946373,
          "from_longitude": 105.80129223102665
        }
      },
      {
        "id": 1892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80101105517421,
            21.022332092331666,
            105.8013076564631,
            21.022522683508402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80130582083046,
              21.022522683508402
            ],
            [
              105.80130557020856,
              21.022522588946824
            ],
            [
              105.80129534463555,
              21.022518693621635
            ],
            [
              105.80129298007112,
              21.02251779340293
            ],
            [
              105.8013076564631,
              21.02248347199168
            ],
            [
              105.80123165337649,
              21.022449016868688
            ],
            [
              105.80114396392759,
              21.022414526433884
            ],
            [
              105.80101105517421,
              21.022332092331666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M02_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1898",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022522683508402,
          "to_latitude": 21.022332092331666,
          "to_longitude": 105.80101105517421,
          "from_longitude": 105.80130582083046
        }
      },
      {
        "id": 1893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8011370281605,
            21.02226666387702,
            105.80140937251574,
            21.02285674683864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8011370281605,
              21.02285674683864
            ],
            [
              105.80117988077286,
              21.022767781916244
            ],
            [
              105.80120352652344,
              21.022724768937614
            ],
            [
              105.80124340588995,
              21.022633885998022
            ],
            [
              105.80129026209113,
              21.022529965482853
            ],
            [
              105.80129223102665,
              21.022525598124723
            ],
            [
              105.80129534463555,
              21.022518693621635
            ],
            [
              105.80130547929173,
              21.022496215977892
            ],
            [
              105.80131562167304,
              21.022473924362448
            ],
            [
              105.80132060113138,
              21.022462984471385
            ],
            [
              105.80140937251574,
              21.02226666387702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M01_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1899",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02285674683864,
          "to_latitude": 21.02226666387702,
          "to_longitude": 105.80140937251574,
          "from_longitude": 105.8011370281605
        }
      },
      {
        "id": 1894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80300823107564,
            21.019922205747893,
            105.80332671404217,
            21.02022982746085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80300823107564,
              21.019922205747893
            ],
            [
              105.8030174634121,
              21.019928551609134
            ],
            [
              105.80305238057862,
              21.019952545445967
            ],
            [
              105.80323362548418,
              21.020062484322693
            ],
            [
              105.80331711931211,
              21.020112688041912
            ],
            [
              105.80332671404217,
              21.020117183263554
            ],
            [
              105.80325944266747,
              21.02022982746085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M39_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1900",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019922205747893,
          "to_latitude": 21.02022982746085,
          "to_longitude": 105.80325944266747,
          "from_longitude": 105.80300823107564
        }
      },
      {
        "id": 1895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8029194355459,
            21.020021572792142,
            105.80300088551141,
            21.02007707021019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8029194355459,
              21.020021572792142
            ],
            [
              105.80300088551141,
              21.02007707021019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M37_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1901",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020021572792142,
          "to_latitude": 21.02007707021019,
          "to_longitude": 105.80300088551141,
          "from_longitude": 105.8029194355459
        }
      },
      {
        "id": 1896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8025542396854,
            21.01997253688463,
            105.80296947922902,
            21.020366821215926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8025542396854,
              21.020360916181783
            ],
            [
              105.80255480192247,
              21.020361358821198
            ],
            [
              105.80256176505608,
              21.020366821215926
            ],
            [
              105.80261545214907,
              21.02031764086241
            ],
            [
              105.80265975835836,
              21.020275166979804
            ],
            [
              105.80271404204208,
              21.020222615638538
            ],
            [
              105.8027191247408,
              21.020217696149015
            ],
            [
              105.8027985040093,
              21.020139934636372
            ],
            [
              105.80288742651693,
              21.02005293678064
            ],
            [
              105.8029194355459,
              21.020021572792142
            ],
            [
              105.80296947922902,
              21.01997253688463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M37_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1902",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020360916181783,
          "to_latitude": 21.01997253688463,
          "to_longitude": 105.80296947922902,
          "from_longitude": 105.8025542396854
        }
      },
      {
        "id": 1897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80288466281232,
            21.020707038122165,
            105.80298946467782,
            21.020828405704627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80298946467782,
              21.020707038122165
            ],
            [
              105.80295776415765,
              21.020745019533535
            ],
            [
              105.80293031745092,
              21.020761616774813
            ],
            [
              105.80288466281232,
              21.020828405704627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M34_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1903",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020707038122165,
          "to_latitude": 21.020828405704627,
          "to_longitude": 105.80288466281232,
          "from_longitude": 105.80298946467782
        }
      },
      {
        "id": 1898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80302849043652,
            21.020538349632137,
            105.80308877272397,
            21.020715297148122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80306314519778,
              21.020715297148122
            ],
            [
              105.80308877272397,
              21.02064210670359
            ],
            [
              105.80302849043652,
              21.020620520511585
            ],
            [
              105.80305939632183,
              21.020538349632137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M35_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1904",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020715297148122,
          "to_latitude": 21.020538349632137,
          "to_longitude": 105.80305939632183,
          "from_longitude": 105.80306314519778
        }
      },
      {
        "id": 1899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80289871769126,
            21.020445377259925,
            105.8029777559934,
            21.020647020578323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80289871769126,
              21.020647020578323
            ],
            [
              105.80292299867205,
              21.020584817072177
            ],
            [
              105.8029777559934,
              21.020445377259925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M33_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1905",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020647020578323,
          "to_latitude": 21.020445377259925,
          "to_longitude": 105.8029777559934,
          "from_longitude": 105.80289871769126
        }
      },
      {
        "id": 1900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80203767675965,
            21.0211764136592,
            105.80204793207629,
            21.02118084803009
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80204793207629,
              21.02118084803009
            ],
            [
              105.80204362881352,
              21.021178986894384
            ],
            [
              105.80203850501996,
              21.021176772851987
            ],
            [
              105.80203767675965,
              21.0211764136592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M28_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1906",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02118084803009,
          "to_latitude": 21.0211764136592,
          "to_longitude": 105.80203767675965,
          "from_longitude": 105.80204793207629
        }
      },
      {
        "id": 1901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8019916941879,
            21.020839279728154,
            105.80220927261192,
            21.0212976388381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8019916941879,
              21.0212976388381
            ],
            [
              105.80204104190595,
              21.02119532586864
            ],
            [
              105.80204793207629,
              21.02118084803009
            ],
            [
              105.80209444332199,
              21.021083119665164
            ],
            [
              105.80213147970478,
              21.02100529798641
            ],
            [
              105.80214102057498,
              21.020983355097528
            ],
            [
              105.80214474568899,
              21.020974786656815
            ],
            [
              105.80217174194705,
              21.020918917438966
            ],
            [
              105.80219279557967,
              21.020869791754688
            ],
            [
              105.8022033197117,
              21.02084787493506
            ],
            [
              105.80220927261192,
              21.020839279728154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M28_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1907",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0212976388381,
          "to_latitude": 21.020839279728154,
          "to_longitude": 105.80220927261192,
          "from_longitude": 105.8019916941879
        }
      },
      {
        "id": 1902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80387074178947,
            21.02082448267994,
            105.80435355917375,
            21.021005434681083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80387074178947,
              21.02082448267994
            ],
            [
              105.80393140368679,
              21.02087879979954
            ],
            [
              105.80395062023656,
              21.0209173997
            ],
            [
              105.80404552991922,
              21.020950620758402
            ],
            [
              105.80417050640318,
              21.020985670461677
            ],
            [
              105.8042644338957,
              21.020999143320733
            ],
            [
              105.80435355917375,
              21.021005434681083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1908",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02082448267994,
          "to_latitude": 21.021005434681083,
          "to_longitude": 105.80435355917375,
          "from_longitude": 105.80387074178947
        }
      },
      {
        "id": 1903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80367826457537,
            21.020880864790637,
            105.80372302866486,
            21.021002292375066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80372302866486,
              21.020880864790637
            ],
            [
              105.80367872729366,
              21.02094719049565
            ],
            [
              105.80369910362134,
              21.020959168586888
            ],
            [
              105.80367826457537,
              21.021002292375066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1909",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020880864790637,
          "to_latitude": 21.021002292375066,
          "to_longitude": 105.80367826457537,
          "from_longitude": 105.80372302866486
        }
      },
      {
        "id": 1904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80327762623472,
            21.02056500136324,
            105.803532489048,
            21.020767747669247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.803532489048,
              21.020767747669247
            ],
            [
              105.80352667632624,
              21.020763890339285
            ],
            [
              105.80340787427842,
              21.020685059464885
            ],
            [
              105.80339520238019,
              21.020645952584193
            ],
            [
              105.8033786682576,
              21.020622305552294
            ],
            [
              105.80332426269045,
              21.02059228896728
            ],
            [
              105.80327762623472,
              21.02056500136324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1910",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020767747669247,
          "to_latitude": 21.02056500136324,
          "to_longitude": 105.80327762623472,
          "from_longitude": 105.803532489048
        }
      },
      {
        "id": 1905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80311041901153,
            21.020934805557943,
            105.80337461188773,
            21.02104521757283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80337461188773,
              21.02104521757283
            ],
            [
              105.80327895104077,
              21.021010571679774
            ],
            [
              105.80323289022769,
              21.021002953683965
            ],
            [
              105.8032014756458,
              21.021009548377155
            ],
            [
              105.80311041901153,
              21.020979597309424
            ],
            [
              105.80312604307095,
              21.020934805557943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1911",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02104521757283,
          "to_latitude": 21.020934805557943,
          "to_longitude": 105.80312604307095,
          "from_longitude": 105.80337461188773
        }
      },
      {
        "id": 1906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80381243189176,
            21.020595176337043,
            105.80397699828609,
            21.020691269914114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80397699828609,
              21.020691269914114
            ],
            [
              105.8039230454177,
              21.020667127794514
            ],
            [
              105.80389129496268,
              21.02064640061251
            ],
            [
              105.80388129011669,
              21.020626603046406
            ],
            [
              105.80381243189176,
              21.020595176337043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1912",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020691269914114,
          "to_latitude": 21.020595176337043,
          "to_longitude": 105.80381243189176,
          "from_longitude": 105.80397699828609
        }
      },
      {
        "id": 1907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80318527138394,
            21.020508334118404,
            105.80409189488869,
            21.02172097485387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80318527138394,
              21.02172097485387
            ],
            [
              105.80320772683491,
              21.021658642642745
            ],
            [
              105.80324007489585,
              21.02153859342762
            ],
            [
              105.80327912852323,
              21.021395314901316
            ],
            [
              105.80331613825776,
              21.021255878420465
            ],
            [
              105.80333465856194,
              21.021172436438402
            ],
            [
              105.80335363331368,
              21.021083505189896
            ],
            [
              105.80337461188773,
              21.02104521757283
            ],
            [
              105.8033846443762,
              21.0210269086983
            ],
            [
              105.8033882644952,
              21.02102030254221
            ],
            [
              105.80343251988135,
              21.020929925034018
            ],
            [
              105.803532489048,
              21.020767747669247
            ],
            [
              105.80357741139092,
              21.02079288457973
            ],
            [
              105.80360502237255,
              21.020810903624675
            ],
            [
              105.80365074143451,
              21.020840740768875
            ],
            [
              105.80371064143895,
              21.020877480211702
            ],
            [
              105.80372302866486,
              21.020880864790637
            ],
            [
              105.80373541685333,
              21.020884249364094
            ],
            [
              105.80379294197809,
              21.020909540729374
            ],
            [
              105.80387074178947,
              21.02082448267994
            ],
            [
              105.80387589546267,
              21.02081934785268
            ],
            [
              105.80390372009818,
              21.02079161998812
            ],
            [
              105.80393786797852,
              21.02075603304241
            ],
            [
              105.80397699828609,
              21.020691269914114
            ],
            [
              105.80401301031536,
              21.02061152429883
            ],
            [
              105.80402384867034,
              21.020613459268827
            ],
            [
              105.80405480198982,
              21.020552796596586
            ],
            [
              105.80406977054083,
              21.020557147146118
            ],
            [
              105.80409189488869,
              21.020508334118404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_51_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1913",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 240,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02172097485387,
          "to_latitude": 21.020508334118404,
          "to_longitude": 105.80409189488869,
          "from_longitude": 105.80318527138394
        }
      },
      {
        "id": 1908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80275038475108,
            21.022169864451644,
            105.80303701375959,
            21.02225303380294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80303701375959,
              21.02225303380294
            ],
            [
              105.80275038475108,
              21.022169864451644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1914",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225303380294,
          "to_latitude": 21.022169864451644,
          "to_longitude": 105.80275038475108,
          "from_longitude": 105.80303701375959
        }
      },
      {
        "id": 1909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80296660724939,
            21.021743475690922,
            105.80317120364319,
            21.02250599941223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80317120364319,
              21.021743475690922
            ],
            [
              105.80316104608411,
              21.021789274022616
            ],
            [
              105.80313188389509,
              21.021890804625254
            ],
            [
              105.80309562153958,
              21.02201250172694
            ],
            [
              105.80306940412306,
              21.022126108137446
            ],
            [
              105.80303701375959,
              21.02225303380294
            ],
            [
              105.80302947732508,
              21.022280111164292
            ],
            [
              105.80296660724939,
              21.02250599941223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_50_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1915",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021743475690922,
          "to_latitude": 21.02250599941223,
          "to_longitude": 105.80296660724939,
          "from_longitude": 105.80317120364319
        }
      },
      {
        "id": 1910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80280707720901,
            21.021296777495674,
            105.80289731631682,
            21.021330504465826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80289731631682,
              21.021330504465826
            ],
            [
              105.80280707720901,
              21.021296777495674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_47_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1916",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021330504465826,
          "to_latitude": 21.021296777495674,
          "to_longitude": 105.80280707720901,
          "from_longitude": 105.80289731631682
        }
      },
      {
        "id": 1911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80280150078345,
            21.020998747177014,
            105.80299126352338,
            21.021608735871528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80280150078345,
              21.021608735871528
            ],
            [
              105.80280693895034,
              21.021603721668583
            ],
            [
              105.80280917892703,
              21.021601656249437
            ],
            [
              105.80282936641261,
              21.02156128958316
            ],
            [
              105.80286109414864,
              21.021497451319572
            ],
            [
              105.80289785606573,
              21.02138639374869
            ],
            [
              105.80289529354903,
              21.02133666410252
            ],
            [
              105.80289731631682,
              21.021330504465826
            ],
            [
              105.80292070551823,
              21.021259294555538
            ],
            [
              105.80294819799462,
              21.02118385893301
            ],
            [
              105.80297344433158,
              21.02110143659198
            ],
            [
              105.80299126352338,
              21.02104206540004
            ],
            [
              105.80289277621327,
              21.020998747177014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M23_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1917",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021608735871528,
          "to_latitude": 21.020998747177014,
          "to_longitude": 105.80289277621327,
          "from_longitude": 105.80280150078345
        }
      },
      {
        "id": 1912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8026176566215,
            21.021627813533083,
            105.80284884098992,
            21.022264525128502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80284884098992,
              21.021627813533083
            ],
            [
              105.80284572870632,
              21.02163836258684
            ],
            [
              105.80276656247727,
              21.021822539793973
            ],
            [
              105.80269978145171,
              21.02200686987193
            ],
            [
              105.802659839174,
              21.022152835962505
            ],
            [
              105.8026176566215,
              21.022264525128502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M24_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1918",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021627813533083,
          "to_latitude": 21.022264525128502,
          "to_longitude": 105.8026176566215,
          "from_longitude": 105.80284884098992
        }
      },
      {
        "id": 1913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8024027836311,
            21.02148178245804,
            105.80283113859552,
            21.02162924651448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8024027836311,
              21.02148178245804
            ],
            [
              105.80260757539274,
              21.021546607458536
            ],
            [
              105.80262287224306,
              21.021553332077417
            ],
            [
              105.8026826277247,
              21.021579596906577
            ],
            [
              105.80276072587492,
              21.02160039641413
            ],
            [
              105.80283113859552,
              21.02162924651448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M20_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1919",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02148178245804,
          "to_latitude": 21.02162924651448,
          "to_longitude": 105.80283113859552,
          "from_longitude": 105.8024027836311
        }
      },
      {
        "id": 1914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80212577266121,
            21.021373851747768,
            105.80213205247706,
            21.021389674329424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80212577266121,
              21.021389674329424
            ],
            [
              105.80212826996687,
              21.021383381494907
            ],
            [
              105.80213136999679,
              21.02137556839252
            ],
            [
              105.80213188446733,
              21.02137427434823
            ],
            [
              105.80213205247706,
              21.021373851747768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M16_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1920",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021389674329424,
          "to_latitude": 21.021373851747768,
          "to_longitude": 105.80213205247706,
          "from_longitude": 105.80212577266121
        }
      },
      {
        "id": 1915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80201272580278,
            21.02134455818554,
            105.80239109480978,
            21.021478044036034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80201272580278,
              21.02134455818554
            ],
            [
              105.80206484328589,
              21.02136566387609
            ],
            [
              105.80212577266121,
              21.021389674329424
            ],
            [
              105.80212593268882,
              21.02138973770405
            ],
            [
              105.80220661749969,
              21.021419360663373
            ],
            [
              105.80221245664113,
              21.021421504487556
            ],
            [
              105.80228444022322,
              21.02144354699703
            ],
            [
              105.80236957432365,
              21.021470127588415
            ],
            [
              105.80239109480978,
              21.021478044036034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M16_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1921",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02134455818554,
          "to_latitude": 21.021478044036034,
          "to_longitude": 105.80239109480978,
          "from_longitude": 105.80201272580278
        }
      },
      {
        "id": 1916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80179965940896,
            21.021586975166105,
            105.80180725896045,
            21.021591274843463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80180725896045,
              21.021591274843463
            ],
            [
              105.8018060866094,
              21.02159061198011
            ],
            [
              105.80180398795957,
              21.02158942599729
            ],
            [
              105.80180189122407,
              21.02158823819897
            ],
            [
              105.80179965940896,
              21.021586975166105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M14_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1922",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021591274843463,
          "to_latitude": 21.021586975166105,
          "to_longitude": 105.80179965940896,
          "from_longitude": 105.80180725896045
        }
      },
      {
        "id": 1917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80175402387037,
            21.02134651728752,
            105.80196904992177,
            21.021681225505883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80175402387037,
              21.021681225505883
            ],
            [
              105.80179299849061,
              21.021615369777503
            ],
            [
              105.80180725896045,
              21.021591274843463
            ],
            [
              105.80182640447194,
              21.021558924846214
            ],
            [
              105.8018308580613,
              21.021550038654077
            ],
            [
              105.80185620121668,
              21.02151473839286
            ],
            [
              105.80186650519659,
              21.02150038627464
            ],
            [
              105.80190222517415,
              21.021451047868542
            ],
            [
              105.80193341625333,
              21.021407668638417
            ],
            [
              105.80194733632618,
              21.021384147945582
            ],
            [
              105.80196904992177,
              21.02134651728752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M14_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1923",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021681225505883,
          "to_latitude": 21.02134651728752,
          "to_longitude": 105.80196904992177,
          "from_longitude": 105.80175402387037
        }
      },
      {
        "id": 1918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80163541925334,
            21.02185810486443,
            105.8016431201708,
            21.02186192987553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8016431201708,
              21.02186192987553
            ],
            [
              105.80164087374854,
              21.021860814135945
            ],
            [
              105.80163899193163,
              21.02185988093672
            ],
            [
              105.80163711202901,
              21.02185894592201
            ],
            [
              105.80163541925334,
              21.02185810486443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M10_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1924",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02186192987553,
          "to_latitude": 21.02185810486443,
          "to_longitude": 105.80163541925334,
          "from_longitude": 105.8016431201708
        }
      },
      {
        "id": 1919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80150241847889,
            21.02172857326662,
            105.80172537237065,
            21.022117987418074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80150241847889,
              21.022117987418074
            ],
            [
              105.8015442727238,
              21.022039389864425
            ],
            [
              105.80158961071146,
              21.02195205791188
            ],
            [
              105.80161239636654,
              21.021907454732496
            ],
            [
              105.8016431201708,
              21.02186192987553
            ],
            [
              105.80165046730333,
              21.021851042042236
            ],
            [
              105.80172537237065,
              21.02172857326662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M10_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1925",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022117987418074,
          "to_latitude": 21.02172857326662,
          "to_longitude": 105.80172537237065,
          "from_longitude": 105.80150241847889
        }
      },
      {
        "id": 1920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80188285951168,
            21.02208603360009,
            105.80194454404624,
            21.022180634378103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80193512981045,
              21.022180634378103
            ],
            [
              105.80192428899191,
              21.022173219396535
            ],
            [
              105.80193928442282,
              21.022129874071556
            ],
            [
              105.80194454404624,
              21.02211467050285
            ],
            [
              105.80193586516847,
              21.02210930107859
            ],
            [
              105.80188285951168,
              21.02208603360009
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M06_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1926",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022180634378103,
          "to_latitude": 21.02208603360009,
          "to_longitude": 105.80188285951168,
          "from_longitude": 105.80193512981045
        }
      },
      {
        "id": 1921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80132768129204,
            21.022165208552682,
            105.80147877904432,
            21.022464818776793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80132768129204,
              21.02246135330153
            ],
            [
              105.80132921881237,
              21.022461943092402
            ],
            [
              105.80133673767506,
              21.022464818776793
            ],
            [
              105.80135082019605,
              21.022428819249924
            ],
            [
              105.8013994737784,
              21.02231545781233
            ],
            [
              105.80141271415566,
              21.02228460704356
            ],
            [
              105.80143913137726,
              21.022229559893944
            ],
            [
              105.80147877904432,
              21.022165208552682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M03_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1927",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02246135330153,
          "to_latitude": 21.022165208552682,
          "to_longitude": 105.80147877904432,
          "from_longitude": 105.80132768129204
        }
      },
      {
        "id": 1922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80116059712687,
            21.022464818776793,
            105.80133673767506,
            21.02286183209928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80116059712687,
              21.02286183209928
            ],
            [
              105.80121051184113,
              21.022750393168064
            ],
            [
              105.80121918356586,
              21.022731378176626
            ],
            [
              105.8012837402671,
              21.02258981348263
            ],
            [
              105.80130315555502,
              21.022547237712942
            ],
            [
              105.80133673767506,
              21.022464818776793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M03_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1928",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02286183209928,
          "to_latitude": 21.022464818776793,
          "to_longitude": 105.80133673767506,
          "from_longitude": 105.80116059712687
        }
      },
      {
        "id": 1923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80465735888404,
            21.01910705431475,
            105.80498258475733,
            21.01944909692398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80498258475733,
              21.01910705431475
            ],
            [
              105.80497438381107,
              21.019115798446432
            ],
            [
              105.8048955326292,
              21.019199872011487
            ],
            [
              105.80483543522823,
              21.019213030091596
            ],
            [
              105.80465735888404,
              21.01944909692398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_126_Ngõ 56, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1929",
          "diaChiLapD": "Ngõ 56, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01910705431475,
          "to_latitude": 21.01944909692398,
          "to_longitude": 105.80465735888404,
          "from_longitude": 105.80498258475733
        }
      },
      {
        "id": 1924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80525148697295,
            21.019240760834123,
            105.80525802333096,
            21.01925118794382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80525802333096,
              21.019240760834123
            ],
            [
              105.80525796705659,
              21.0192408505202
            ],
            [
              105.80525731083331,
              21.019241895049895
            ],
            [
              105.80525404218793,
              21.019247111316638
            ],
            [
              105.80525148697295,
              21.01925118794382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_137_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1930",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019240760834123,
          "to_latitude": 21.01925118794382,
          "to_longitude": 105.80525148697295,
          "from_longitude": 105.80525802333096
        }
      },
      {
        "id": 1925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80166789823863,
            21.0280987541269,
            105.80195391308042,
            21.028254858934712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80195391308042,
              21.028254858934712
            ],
            [
              105.80182687691834,
              21.0280987541269
            ],
            [
              105.80173984445752,
              21.028149257559377
            ],
            [
              105.80167689577372,
              21.028124673997223
            ],
            [
              105.80167354016046,
              21.028126638081577
            ],
            [
              105.80166789823863,
              21.02812993978753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_47_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1931",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028254858934712,
          "to_latitude": 21.02812993978753,
          "to_longitude": 105.80166789823863,
          "from_longitude": 105.80195391308042
        }
      },
      {
        "id": 1926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80191253472039,
            21.028175307434985,
            105.8021733975476,
            21.028349384578313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80195964932304,
              21.028349384578313
            ],
            [
              105.80191253472039,
              21.02827791298716
            ],
            [
              105.80195391308042,
              21.028254858934712
            ],
            [
              105.80209669614169,
              21.028175307434985
            ],
            [
              105.8021733975476,
              21.028281062355138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_47_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1932",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028349384578313,
          "to_latitude": 21.028281062355138,
          "to_longitude": 105.8021733975476,
          "from_longitude": 105.80195964932304
        }
      },
      {
        "id": 1927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80193027891981,
            21.027911013645582,
            105.8020647230979,
            21.028098496832918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80193027891981,
              21.027911013645582
            ],
            [
              105.8019871849487,
              21.02799400851699
            ],
            [
              105.8020647230979,
              21.028098496832918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1933",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027911013645582,
          "to_latitude": 21.028098496832918,
          "to_longitude": 105.8020647230979,
          "from_longitude": 105.80193027891981
        }
      },
      {
        "id": 1928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80166356646168,
            21.02799400851699,
            105.8019871849487,
            21.02813931168833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8019871849487,
              21.02799400851699
            ],
            [
              105.80173629334635,
              21.02813931168833
            ],
            [
              105.8016765563161,
              21.028118223834085
            ],
            [
              105.80166926373347,
              21.028122096936784
            ],
            [
              105.80166356646168,
              21.028125122515505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_46_Đường Láng",
          "maTaiSan": "04.O9A.DODV.1934",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02799400851699,
          "to_latitude": 21.028125122515505,
          "to_longitude": 105.80166356646168,
          "from_longitude": 105.8019871849487
        }
      },
      {
        "id": 1929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8013438707682,
            21.028008485227833,
            105.80157835460824,
            21.028255061679218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80157835460824,
              21.028255061679218
            ],
            [
              105.80157171006388,
              21.028248074009394
            ],
            [
              105.8013438707682,
              21.028008485227833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1935",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028255061679218,
          "to_latitude": 21.028008485227833,
          "to_longitude": 105.8013438707682,
          "from_longitude": 105.80157835460824
        }
      },
      {
        "id": 1930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8012998504211,
            21.028106118325038,
            105.801688442234,
            21.028337602931533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.801688442234,
              21.02815278404299
            ],
            [
              105.8016870015511,
              21.0281541230976
            ],
            [
              105.80157835460824,
              21.028255061679218
            ],
            [
              105.80149139090902,
              21.028333391338222
            ],
            [
              105.80148510158813,
              21.028337602931533
            ],
            [
              105.8014604945443,
              21.0283148590924
            ],
            [
              105.80136337361876,
              21.02822906207076
            ],
            [
              105.8012998504211,
              21.028172945742767
            ],
            [
              105.8013343989638,
              21.028141162702354
            ],
            [
              105.80130353945277,
              21.028106118325038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_45_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1936",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02815278404299,
          "to_latitude": 21.028106118325038,
          "to_longitude": 105.80130353945277,
          "from_longitude": 105.801688442234
        }
      },
      {
        "id": 1931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80155707829296,
            21.027994602603624,
            105.80156272580447,
            21.0279986576251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80156272580447,
              21.027994602603624
            ],
            [
              105.80156040853265,
              21.027996266435427
            ],
            [
              105.80155766192304,
              21.027998238485544
            ],
            [
              105.80155707829296,
              21.0279986576251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1937",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027994602603624,
          "to_latitude": 21.0279986576251,
          "to_longitude": 105.80155707829296,
          "from_longitude": 105.80156272580447
        }
      },
      {
        "id": 1932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80148597914204,
            21.02781352373235,
            105.80166645348935,
            21.0281139693839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80148597914204,
              21.02781352373235
            ],
            [
              105.8015062920327,
              21.02792701986177
            ],
            [
              105.80155102580723,
              21.027980590867628
            ],
            [
              105.80156272580447,
              21.027994602603624
            ],
            [
              105.801643500755,
              21.028091334287918
            ],
            [
              105.80166645348935,
              21.0281139693839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1938",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02781352373235,
          "to_latitude": 21.0281139693839,
          "to_longitude": 105.80166645348935,
          "from_longitude": 105.80148597914204
        }
      },
      {
        "id": 1933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80147737793632,
            21.027743480957547,
            105.8018630409438,
            21.0279098629717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80147737793632,
              21.027802049909855
            ],
            [
              105.80148424169593,
              21.02780074220028
            ],
            [
              105.8015734475155,
              21.027768638266163
            ],
            [
              105.80174121631156,
              21.027743480957547
            ],
            [
              105.8018630409438,
              21.0279098629717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_43_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1939",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027802049909855,
          "to_latitude": 21.0279098629717,
          "to_longitude": 105.8018630409438,
          "from_longitude": 105.80147737793632
        }
      },
      {
        "id": 1934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80100832720638,
            21.027714674747244,
            105.80148168142328,
            21.0278155124178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80148168142328,
              21.027714674747244
            ],
            [
              105.80147877270701,
              21.027715295426447
            ],
            [
              105.80100832720638,
              21.0278155124178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1940",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027714674747244,
          "to_latitude": 21.0278155124178,
          "to_longitude": 105.80100832720638,
          "from_longitude": 105.80148168142328
        }
      },
      {
        "id": 1935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80099639282038,
            21.027735025909717,
            105.80148540550783,
            21.027837379806723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80148540550783,
              21.027735025909717
            ],
            [
              105.80148151848853,
              21.027735839974742
            ],
            [
              105.80099639282038,
              21.027837379806723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1941",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027735025909717,
          "to_latitude": 21.027837379806723,
          "to_longitude": 105.80099639282038,
          "from_longitude": 105.80148540550783
        }
      },
      {
        "id": 1936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80224140919816,
            21.02701152171142,
            105.8024500363091,
            21.027307800811457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80224140919816,
              21.02701152171142
            ],
            [
              105.80224430959443,
              21.027015641203842
            ],
            [
              105.8024500363091,
              21.027307800811457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1942",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02701152171142,
          "to_latitude": 21.027307800811457,
          "to_longitude": 105.8024500363091,
          "from_longitude": 105.80224140919816
        }
      },
      {
        "id": 1937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80149678819495,
            21.026937448714055,
            105.80257604269592,
            21.02746227300071
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80149678819495,
              21.02746227300071
            ],
            [
              105.80153417824708,
              21.027435150739652
            ],
            [
              105.80190450742245,
              21.02721579709728
            ],
            [
              105.80224140919816,
              21.02701152171142
            ],
            [
              105.80236357445952,
              21.026937448714055
            ],
            [
              105.80236646414028,
              21.02694154296453
            ],
            [
              105.80257604269592,
              21.027238516518075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_49_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1943",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 160,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02746227300071,
          "to_latitude": 21.027238516518075,
          "to_longitude": 105.80257604269592,
          "from_longitude": 105.80149678819495
        }
      },
      {
        "id": 1938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80211855321286,
            21.026873286709737,
            105.80221773819594,
            21.027005834649582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80221773819594,
              21.027005834649582
            ],
            [
              105.80211855321286,
              21.026873286709737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1944",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027005834649582,
          "to_latitude": 21.026873286709737,
          "to_longitude": 105.80211855321286,
          "from_longitude": 105.80221773819594
        }
      },
      {
        "id": 1939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80149174627931,
            21.02679539662402,
            105.80234031800575,
            21.027456519712246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80149174627931,
              21.027456519712246
            ],
            [
              105.80153212907355,
              21.027431538450383
            ],
            [
              105.80156813633172,
              21.027398231968835
            ],
            [
              105.80170135969529,
              21.02731642715322
            ],
            [
              105.80195362014857,
              21.02716469745026
            ],
            [
              105.80208161242193,
              21.027087712256975
            ],
            [
              105.80221773819594,
              21.027005834649582
            ],
            [
              105.8022952714047,
              21.026959200075662
            ],
            [
              105.80234031800575,
              21.026932104746525
            ],
            [
              105.80224668172777,
              21.02679539662402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_50_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1945",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 127,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027456519712246,
          "to_latitude": 21.02679539662402,
          "to_longitude": 105.80224668172777,
          "from_longitude": 105.80149174627931
        }
      },
      {
        "id": 1940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80109816361067,
            21.027407449977265,
            105.80141585774624,
            21.027480150245506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80109816361067,
              21.027480150245506
            ],
            [
              105.80111361627895,
              21.027476865566445
            ],
            [
              105.80115555035904,
              21.027466186940813
            ],
            [
              105.80118959219766,
              21.027457519044344
            ],
            [
              105.8011991243702,
              21.02745509141577
            ],
            [
              105.80120255599253,
              21.027454390600102
            ],
            [
              105.80133635274345,
              21.027427070649473
            ],
            [
              105.80141585774624,
              21.027407449977265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1946",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027480150245506,
          "to_latitude": 21.027407449977265,
          "to_longitude": 105.80141585774624,
          "from_longitude": 105.80109816361067
        }
      },
      {
        "id": 1941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80115555035904,
            21.027466186940813,
            105.8011598097307,
            21.02748392345231
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80115555035904,
              21.027466186940813
            ],
            [
              105.80115712268592,
              21.027472739685273
            ],
            [
              105.80115908556542,
              21.027480907369572
            ],
            [
              105.8011598097307,
              21.02748392345231
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1947",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027466186940813,
          "to_latitude": 21.02748392345231,
          "to_longitude": 105.8011598097307,
          "from_longitude": 105.80115555035904
        }
      },
      {
        "id": 1942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80116502378844,
            21.027237039822708,
            105.80134278099115,
            21.027278347454004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80116502378844,
              21.027278347454004
            ],
            [
              105.80134278099115,
              21.027237039822708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1948",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027278347454004,
          "to_latitude": 21.027237039822708,
          "to_longitude": 105.80134278099115,
          "from_longitude": 105.80116502378844
        }
      },
      {
        "id": 1943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80106284568393,
            21.02686332900335,
            105.80120870048023,
            21.027473663653225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80120870048023,
              21.027473663653225
            ],
            [
              105.80120335725762,
              21.027458152386508
            ],
            [
              105.80120255599253,
              21.027454390600102
            ],
            [
              105.80116502378844,
              21.027278347454004
            ],
            [
              105.80116101803993,
              21.027264504470665
            ],
            [
              105.80106284568393,
              21.02686332900335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_38_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1949",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027473663653225,
          "to_latitude": 21.02686332900335,
          "to_longitude": 105.80106284568393,
          "from_longitude": 105.80120870048023
        }
      },
      {
        "id": 1944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80103784017076,
            21.02686857532679,
            105.80119580465504,
            21.02747636996982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80119580465504,
              21.02747636996982
            ],
            [
              105.80119062652221,
              21.02746114786136
            ],
            [
              105.80118959219766,
              21.027457519044344
            ],
            [
              105.8011360134311,
              21.027269751709
            ],
            [
              105.80103784017076,
              21.02686857532679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_37_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1950",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02747636996982,
          "to_latitude": 21.02686857532679,
          "to_longitude": 105.80103784017076,
          "from_longitude": 105.80119580465504
        }
      },
      {
        "id": 1945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80109810821088,
            21.02749516578516,
            105.80110428520778,
            21.027520884131164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80110428520778,
              21.027520884131164
            ],
            [
              105.80110157160844,
              21.02750959119759
            ],
            [
              105.8010986690445,
              21.02749750160296
            ],
            [
              105.80109810821088,
              21.02749516578516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1951",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027520884131164,
          "to_latitude": 21.02749516578516,
          "to_longitude": 105.80109810821088,
          "from_longitude": 105.80110428520778
        }
      },
      {
        "id": 1946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80091239339995,
            21.027465347796593,
            105.80138347981548,
            21.027560711766878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80091239339995,
              21.027560711766878
            ],
            [
              105.80110428520778,
              21.027520884131164
            ],
            [
              105.80114287419765,
              21.027512874953636
            ],
            [
              105.80117969311027,
              21.0275052335921
            ],
            [
              105.80138347981548,
              21.027465347796593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1952",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027560711766878,
          "to_latitude": 21.027465347796593,
          "to_longitude": 105.80138347981548,
          "from_longitude": 105.80091239339995
        }
      },
      {
        "id": 1947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80046801428412,
            21.027563450235615,
            105.80089611815944,
            21.027661183847446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80046801428412,
              21.027661183847446
            ],
            [
              105.80059423003192,
              21.027630865308563
            ],
            [
              105.80060326335371,
              21.027628695675272
            ],
            [
              105.80061265443787,
              21.02763407083587
            ],
            [
              105.80061869359491,
              21.02762498933814
            ],
            [
              105.80065453093988,
              21.027616379029393
            ],
            [
              105.80066821120927,
              21.02761338273623
            ],
            [
              105.80089611815944,
              21.027563450235615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1953",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027661183847446,
          "to_latitude": 21.027563450235615,
          "to_longitude": 105.80089611815944,
          "from_longitude": 105.80046801428412
        }
      },
      {
        "id": 1948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80058457230422,
            21.02759159502214,
            105.80059423003192,
            21.027630865308563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80059423003192,
              21.027630865308563
            ],
            [
              105.80058876442622,
              21.02760863999256
            ],
            [
              105.80058593527663,
              21.027597139851185
            ],
            [
              105.80058457230422,
              21.02759159502214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1954",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027630865308563,
          "to_latitude": 21.02759159502214,
          "to_longitude": 105.80058457230422,
          "from_longitude": 105.80059423003192
        }
      },
      {
        "id": 1949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80064682151735,
            21.027744783966913,
            105.80081621282261,
            21.02778194198457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80064682151735,
              21.02778194198457
            ],
            [
              105.80081621282261,
              21.027744783966913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1955",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02778194198457,
          "to_latitude": 21.027744783966913,
          "to_longitude": 105.80081621282261,
          "from_longitude": 105.80064682151735
        }
      },
      {
        "id": 1950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80059422124572,
            21.02758959229816,
            105.80087292649134,
            21.027881051151045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80059422124572,
              21.02758959229816
            ],
            [
              105.80059567826093,
              21.027593111644826
            ],
            [
              105.80061265443787,
              21.02763407083587
            ],
            [
              105.80064682151735,
              21.02778194198457
            ],
            [
              105.80066972364773,
              21.027881051151045
            ],
            [
              105.80087292649134,
              21.027843218127224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_34_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1956",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02758959229816,
          "to_latitude": 21.027843218127224,
          "to_longitude": 105.80087292649134,
          "from_longitude": 105.80059422124572
        }
      },
      {
        "id": 1951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80014754758153,
            21.027590333462793,
            105.80056072864802,
            21.027679287086155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80014754758153,
              21.027679287086155
            ],
            [
              105.80027618234766,
              21.027649766692203
            ],
            [
              105.80028883961504,
              21.02764687821176
            ],
            [
              105.80030949691016,
              21.027642163686437
            ],
            [
              105.80033631350013,
              21.027636631651273
            ],
            [
              105.80036000312043,
              21.027631744783275
            ],
            [
              105.80048765430564,
              21.027605409130285
            ],
            [
              105.80056072864802,
              21.027590333462793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1957",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027679287086155,
          "to_latitude": 21.027590333462793,
          "to_longitude": 105.80056072864802,
          "from_longitude": 105.80014754758153
        }
      },
      {
        "id": 1952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80033631350013,
            21.027636631651273,
            105.80033978893455,
            21.027651342444674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80033631350013,
              21.027636631651273
            ],
            [
              105.80033635780265,
              21.02763682112089
            ],
            [
              105.8003389572556,
              21.027647826475174
            ],
            [
              105.80033978893455,
              21.027651342444674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1958",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027636631651273,
          "to_latitude": 21.027651342444674,
          "to_longitude": 105.80033978893455,
          "from_longitude": 105.80033631350013
        }
      },
      {
        "id": 1953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80023407514437,
            21.027449062324088,
            105.80031256993855,
            21.027660289040803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80030023033925,
              21.027660289040803
            ],
            [
              105.80030016060051,
              21.027658939041505
            ],
            [
              105.80028883961504,
              21.02764687821176
            ],
            [
              105.8002858751253,
              21.027643720022528
            ],
            [
              105.80023407514437,
              21.027473239038034
            ],
            [
              105.80031256993855,
              21.027449062324088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1959",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027660289040803,
          "to_latitude": 21.027449062324088,
          "to_longitude": 105.80031256993855,
          "from_longitude": 105.80030023033925
        }
      },
      {
        "id": 1954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80001069100064,
            21.027245275423166,
            105.80031648155791,
            21.027690553121563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80011605345533,
              21.027690553121563
            ],
            [
              105.80011562510322,
              21.027689424294163
            ],
            [
              105.80005738766707,
              21.02753556405394
            ],
            [
              105.80004021826144,
              21.027468463550008
            ],
            [
              105.80001069100064,
              21.027332363891325
            ],
            [
              105.80007738450664,
              21.02731446153797
            ],
            [
              105.80031648155791,
              21.027245275423166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_29_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1960",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027690553121563,
          "to_latitude": 21.027245275423166,
          "to_longitude": 105.80031648155791,
          "from_longitude": 105.80011605345533
        }
      },
      {
        "id": 1955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80043115262372,
            21.0276240810958,
            105.80054563440503,
            21.027920923956696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80043115262372,
              21.0276240810958
            ],
            [
              105.80043162488766,
              21.02762550597479
            ],
            [
              105.80046299734548,
              21.027719913739915
            ],
            [
              105.80054563440503,
              21.02788933088652
            ],
            [
              105.80047082124116,
              21.027920923956696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1961",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0276240810958,
          "to_latitude": 21.027920923956696,
          "to_longitude": 105.80047082124116,
          "from_longitude": 105.80043115262372
        }
      },
      {
        "id": 1956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80019909850304,
            21.027673901526473,
            105.80031092481443,
            21.02795141808852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80019909850304,
              21.027673901526473
            ],
            [
              105.80019954691966,
              21.027675905488238
            ],
            [
              105.80019987111623,
              21.027677346419576
            ],
            [
              105.80020776132649,
              21.02770450642254
            ],
            [
              105.80021015064553,
              21.02771273262856
            ],
            [
              105.80031092481443,
              21.02795141808852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1962",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027673901526473,
          "to_latitude": 21.02795141808852,
          "to_longitude": 105.80031092481443,
          "from_longitude": 105.80019909850304
        }
      },
      {
        "id": 1957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80007297333472,
            21.027696881515393,
            105.80008056780257,
            21.02772901699904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80008056780257,
              21.02772901699904
            ],
            [
              105.80007489163718,
              21.027704998842424
            ],
            [
              105.80007308851556,
              21.027697373233355
            ],
            [
              105.80007297333472,
              21.027696881515393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1963",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02772901699904,
          "to_latitude": 21.027696881515393,
          "to_longitude": 105.80007297333472,
          "from_longitude": 105.80008056780257
        }
      },
      {
        "id": 1958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80004800273728,
            21.027664380029588,
            105.80042299438657,
            21.027737002113348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80004800273728,
              21.027737002113348
            ],
            [
              105.80008056780257,
              21.02772901699904
            ],
            [
              105.8000987733035,
              21.02772455339019
            ],
            [
              105.80010723270021,
              21.02772299706619
            ],
            [
              105.80020243407841,
              21.027705486204123
            ],
            [
              105.80020776132649,
              21.02770450642254
            ],
            [
              105.80022641293996,
              21.027701075801453
            ],
            [
              105.80042299438657,
              21.027664380029588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1964",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027737002113348,
          "to_latitude": 21.027664380029588,
          "to_longitude": 105.80042299438657,
          "from_longitude": 105.80004800273728
        }
      },
      {
        "id": 1959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80777705154831,
            21.022727746859086,
            105.80810562214943,
            21.02305224616707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80777705154831,
              21.02273400604936
            ],
            [
              105.80797151169946,
              21.022727746859086
            ],
            [
              105.80809489331845,
              21.022728998697506
            ],
            [
              105.80810562214943,
              21.02289048573285
            ],
            [
              105.8081042831619,
              21.02305224616707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1965",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.23,
          "chatLieu": "HDPE",
          "chieuDai": 68.878,
          "from_latitude": 21.02273400604936,
          "to_latitude": 21.02305224616707,
          "to_longitude": 105.8081042831619,
          "from_longitude": 105.80777705154831
        }
      },
      {
        "id": 1960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80762653610815,
            21.0234100103164,
            105.80792139842806,
            21.02349029870433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80792139842806,
              21.0234100103164
            ],
            [
              105.80762653610815,
              21.02349029870433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1966",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0234100103164,
          "to_latitude": 21.02349029870433,
          "to_longitude": 105.80762653610815,
          "from_longitude": 105.80792139842806
        }
      },
      {
        "id": 1961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80623447877569,
            21.023250849450434,
            105.80631220047235,
            21.023261838960423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80631220047235,
              21.023250849450434
            ],
            [
              105.80623447877569,
              21.023261838960423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 69, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1967",
          "diaChiLapD": "Ngõ 69, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023250849450434,
          "to_latitude": 21.023261838960423,
          "to_longitude": 105.80623447877569,
          "from_longitude": 105.80631220047235
        }
      },
      {
        "id": 1962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80916797520467,
            21.024255061687356,
            105.80935677299567,
            21.02432870901719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80935677299567,
              21.024255061687356
            ],
            [
              105.80919980998348,
              21.02432870901719
            ],
            [
              105.80916797520467,
              21.02427819661947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.1968",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024255061687356,
          "to_latitude": 21.02427819661947,
          "to_longitude": 105.80916797520467,
          "from_longitude": 105.80935677299567
        }
      },
      {
        "id": 1963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80812542063586,
            21.022928334047897,
            105.80812634229757,
            21.02304826185363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80812634229757,
              21.022928334047897
            ],
            [
              105.80812547797652,
              21.02304069523785
            ],
            [
              105.80812542063586,
              21.02304826185363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1969",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022928334047897,
          "to_latitude": 21.02304826185363,
          "to_longitude": 105.80812542063586,
          "from_longitude": 105.80812634229757
        }
      },
      {
        "id": 1964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80681186940753,
            21.023212675909658,
            105.80682131401325,
            21.02333044642287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80681186940753,
              21.023212675909658
            ],
            [
              105.80681526721305,
              21.023255055529848
            ],
            [
              105.80681866693998,
              21.02329743423738
            ],
            [
              105.80682131401325,
              21.02333044642287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1970",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023212675909658,
          "to_latitude": 21.02333044642287,
          "to_longitude": 105.80682131401325,
          "from_longitude": 105.80681186940753
        }
      },
      {
        "id": 1965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80573743131308,
            21.023452248420877,
            105.80574746405226,
            21.023519041616254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80573743131308,
              21.023452248420877
            ],
            [
              105.80574168548394,
              21.023480577000857
            ],
            [
              105.80574367084013,
              21.023493787249535
            ],
            [
              105.8057459415854,
              21.023508906474678
            ],
            [
              105.80574746405226,
              21.023519041616254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1971",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023452248420877,
          "to_latitude": 21.023519041616254,
          "to_longitude": 105.80574746405226,
          "from_longitude": 105.80573743131308
        }
      },
      {
        "id": 1966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8082348057179,
            21.02282754225542,
            105.80823670684552,
            21.02302728486457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8082348057179,
              21.02282754225542
            ],
            [
              105.80823662157655,
              21.023018426406928
            ],
            [
              105.80823670684552,
              21.02302728486457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1972",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02282754225542,
          "to_latitude": 21.02302728486457,
          "to_longitude": 105.80823670684552,
          "from_longitude": 105.8082348057179
        }
      },
      {
        "id": 1967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80897536180555,
            21.022746743105476,
            105.80899586725812,
            21.02287845456195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80897536180555,
              21.022746743105476
            ],
            [
              105.80899419095942,
              21.022871048838567
            ],
            [
              105.80899586725812,
              21.02287845456195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1973",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022746743105476,
          "to_latitude": 21.02287845456195,
          "to_longitude": 105.80899586725812,
          "from_longitude": 105.80897536180555
        }
      },
      {
        "id": 1968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80903297618852,
            21.02286958718761,
            105.80906220936568,
            21.022991406510492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80906220936568,
              21.022991406510492
            ],
            [
              105.80905020856758,
              21.022938993267886
            ],
            [
              105.80903971411884,
              21.022893151972365
            ],
            [
              105.80903820970249,
              21.022886580014685
            ],
            [
              105.80903297618852,
              21.02286958718761
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1974",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022991406510492,
          "to_latitude": 21.02286958718761,
          "to_longitude": 105.80903297618852,
          "from_longitude": 105.80906220936568
        }
      },
      {
        "id": 1969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80935643590814,
            21.02277175241258,
            105.80940033814827,
            21.022963508365763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80940033814827,
              21.022963508365763
            ],
            [
              105.80937169789208,
              21.022838414794347
            ],
            [
              105.80935643590814,
              21.02277175241258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1975",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022963508365763,
          "to_latitude": 21.02277175241258,
          "to_longitude": 105.80935643590814,
          "from_longitude": 105.80940033814827
        }
      },
      {
        "id": 1970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80687779408537,
            21.02607976055689,
            105.80693477228662,
            21.02619264658433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80688363535522,
              21.02619264658433
            ],
            [
              105.80687779408537,
              21.02610042850955
            ],
            [
              105.8068894878461,
              21.026099457153016
            ],
            [
              105.80693477228662,
              21.02607976055689
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1976",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02619264658433,
          "to_latitude": 21.02607976055689,
          "to_longitude": 105.80693477228662,
          "from_longitude": 105.80688363535522
        }
      },
      {
        "id": 1971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8069383016614,
            21.02272447723445,
            105.80712809100376,
            21.022728026901824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80712809100376,
              21.022728026901824
            ],
            [
              105.8069383016614,
              21.02272447723445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 59, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1977",
          "diaChiLapD": "Ngõ 59, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022728026901824,
          "to_latitude": 21.02272447723445,
          "to_longitude": 105.8069383016614,
          "from_longitude": 105.80712809100376
        }
      },
      {
        "id": 1972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538002200427,
            21.024239826606273,
            105.80628704490393,
            21.024446788151348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80538002200427,
              21.024311788088667
            ],
            [
              105.80539906966098,
              21.02431288201992
            ],
            [
              105.80568861570407,
              21.02429071556136
            ],
            [
              105.80572268528567,
              21.024287755054097
            ],
            [
              105.80625843675925,
              21.024241201752783
            ],
            [
              105.80627834270145,
              21.024239826606273
            ],
            [
              105.80628704490393,
              21.024446788151348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_09_Ngách 82/23, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1978",
          "diaChiLapD": "Ngách 82/23, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 130,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024311788088667,
          "to_latitude": 21.024446788151348,
          "to_longitude": 105.80628704490393,
          "from_longitude": 105.80538002200427
        }
      },
      {
        "id": 1973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80572268528567,
            21.024287755054097,
            105.80573523120425,
            21.024612740470076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80572268528567,
              21.024287755054097
            ],
            [
              105.8057227221905,
              21.0242887150112
            ],
            [
              105.80573523120425,
              21.024612740470076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Ngách 82/23, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1979",
          "diaChiLapD": "Ngách 82/23, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024287755054097,
          "to_latitude": 21.024612740470076,
          "to_longitude": 105.80573523120425,
          "from_longitude": 105.80572268528567
        }
      },
      {
        "id": 1974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80568861570407,
            21.02429071556136,
            105.80569592313886,
            21.02461760993348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80568861570407,
              21.02429071556136
            ],
            [
              105.8056886372171,
              21.02429167559141
            ],
            [
              105.80569592313886,
              21.02461760993348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Ngách 82/23, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1980",
          "diaChiLapD": "Ngách 82/23, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02429071556136,
          "to_latitude": 21.02461760993348,
          "to_longitude": 105.80569592313886,
          "from_longitude": 105.80568861570407
        }
      },
      {
        "id": 1975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80598107418412,
            21.024342382772854,
            105.80598843670853,
            21.024662583928798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80598843670853,
              21.024662583928798
            ],
            [
              105.80598828242027,
              21.024655894446713
            ],
            [
              105.80598107418412,
              21.024342382772854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1981",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024662583928798,
          "to_latitude": 21.024342382772854,
          "to_longitude": 105.80598107418412,
          "from_longitude": 105.80598843670853
        }
      },
      {
        "id": 1976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80602023724508,
            21.02434330272428,
            105.8060299172529,
            21.024661345918673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8060299172529,
              21.024661345918673
            ],
            [
              105.80602971504368,
              21.024654690083235
            ],
            [
              105.80602023724508,
              21.02434330272428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1982",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024661345918673,
          "to_latitude": 21.02434330272428,
          "to_longitude": 105.80602023724508,
          "from_longitude": 105.8060299172529
        }
      },
      {
        "id": 1977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8072412765273,
            21.026163569445426,
            105.80768381561745,
            21.026167412087812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80768381561745,
              21.026163569445426
            ],
            [
              105.80735575414458,
              21.02616481651313
            ],
            [
              105.8072412765273,
              21.026167412087812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1983",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026163569445426,
          "to_latitude": 21.026167412087812,
          "to_longitude": 105.8072412765273,
          "from_longitude": 105.80768381561745
        }
      },
      {
        "id": 1978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80693110570112,
            21.025951858064026,
            105.8076971589784,
            21.02597603136028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8076971589784,
              21.025951858064026
            ],
            [
              105.80734493897218,
              21.02596577019047
            ],
            [
              105.80710713982563,
              21.025975162578874
            ],
            [
              105.8069484535316,
              21.02597584156088
            ],
            [
              105.8069473510986,
              21.02597584679195
            ],
            [
              105.80693110570112,
              21.02597603136028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1984",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025951858064026,
          "to_latitude": 21.02597603136028,
          "to_longitude": 105.80693110570112,
          "from_longitude": 105.8076971589784
        }
      },
      {
        "id": 1979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80692541436022,
            21.025745056011242,
            105.80749589647881,
            21.0258149793599
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80749589647881,
              21.025745056011242
            ],
            [
              105.80722284293788,
              21.025770329403876
            ],
            [
              105.80705063011155,
              21.025794779709994
            ],
            [
              105.80694763046196,
              21.02581149316271
            ],
            [
              105.80694401647597,
              21.02581208024803
            ],
            [
              105.80692541436022,
              21.0258149793599
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_35_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1985",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025745056011242,
          "to_latitude": 21.0258149793599,
          "to_longitude": 105.80692541436022,
          "from_longitude": 105.80749589647881
        }
      },
      {
        "id": 1980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80690915253616,
            21.025403326727524,
            105.80694427039828,
            21.02540370380716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80694427039828,
              21.025403326727524
            ],
            [
              105.80692891673536,
              21.025403491707866
            ],
            [
              105.80691940402869,
              21.025403594650278
            ],
            [
              105.80690989131232,
              21.025403695785748
            ],
            [
              105.80690915253616,
              21.02540370380716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1986",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025403326727524,
          "to_latitude": 21.02540370380716,
          "to_longitude": 105.80690915253616,
          "from_longitude": 105.80694427039828
        }
      },
      {
        "id": 1981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8069292343461,
            21.025922881134775,
            105.80694818770073,
            21.025923082164994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80694818770073,
              21.025922881134775
            ],
            [
              105.80694585496961,
              21.025922906655293
            ],
            [
              105.80693803813816,
              21.025922988907503
            ],
            [
              105.8069292343461,
              21.025923082164994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1987",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025922881134775,
          "to_latitude": 21.025923082164994,
          "to_longitude": 105.8069292343461,
          "from_longitude": 105.80694818770073
        }
      },
      {
        "id": 1982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80694700311022,
            21.025686363807953,
            105.80694928191873,
            21.026141176255823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80694700311022,
              21.025686363807953
            ],
            [
              105.80694758990414,
              21.02580342210802
            ],
            [
              105.80694763046196,
              21.02581149316271
            ],
            [
              105.80694814250882,
              21.02591377048654
            ],
            [
              105.80694818770073,
              21.025922881134775
            ],
            [
              105.8069484535316,
              21.02597584156088
            ],
            [
              105.80694851329488,
              21.02598783795392
            ],
            [
              105.80694928191873,
              21.026141176255823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1988",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025686363807953,
          "to_latitude": 21.026141176255823,
          "to_longitude": 105.80694928191873,
          "from_longitude": 105.80694700311022
        }
      },
      {
        "id": 1983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80693525847974,
            21.025080386806316,
            105.80694611233098,
            21.025629000398197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80693525847974,
              21.025080386806316
            ],
            [
              105.80694356739698,
              21.02531722170212
            ],
            [
              105.80694382786058,
              21.02534916945567
            ],
            [
              105.80694427039828,
              21.025403326727524
            ],
            [
              105.80694611233098,
              21.025629000398197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1989",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025080386806316,
          "to_latitude": 21.025629000398197,
          "to_longitude": 105.80694611233098,
          "from_longitude": 105.80693525847974
        }
      },
      {
        "id": 1984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80691920763603,
            21.025607547189388,
            105.80739421834726,
            21.02565736658474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80739421834726,
              21.025607547189388
            ],
            [
              105.80692597922476,
              21.025656657033554
            ],
            [
              105.80691920763603,
              21.02565736658474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_34_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1990",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025607547189388,
          "to_latitude": 21.02565736658474,
          "to_longitude": 105.80691920763603,
          "from_longitude": 105.80739421834726
        }
      },
      {
        "id": 1985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80718900712819,
            21.02293713097488,
            105.80759761839832,
            21.022937853140856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80759761839832,
              21.02293713097488
            ],
            [
              105.8075930942146,
              21.022937139811802
            ],
            [
              105.80718900712819,
              21.022937853140856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1991",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02293713097488,
          "to_latitude": 21.022937853140856,
          "to_longitude": 105.80718900712819,
          "from_longitude": 105.80759761839832
        }
      },
      {
        "id": 1986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80722028219499,
            21.02277468642538,
            105.80757578923581,
            21.022788938070917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80757578923581,
              21.022788938070917
            ],
            [
              105.80756551203483,
              21.022788527124984
            ],
            [
              105.80722028219499,
              21.02277468642538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1992",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022788938070917,
          "to_latitude": 21.02277468642538,
          "to_longitude": 105.80722028219499,
          "from_longitude": 105.80757578923581
        }
      },
      {
        "id": 1987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80757491714697,
            21.022772657026806,
            105.80758936946177,
            21.022774729912815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80758936946177,
              21.022772657026806
            ],
            [
              105.80758301275023,
              21.02277356876069
            ],
            [
              105.80757976855352,
              21.022774034875667
            ],
            [
              105.80757652434708,
              21.022774499184173
            ],
            [
              105.80757491714697,
              21.022774729912815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1993",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022772657026806,
          "to_latitude": 21.022774729912815,
          "to_longitude": 105.80757491714697,
          "from_longitude": 105.80758936946177
        }
      },
      {
        "id": 1988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80758922889007,
            21.02262536850138,
            105.80761151247403,
            21.023058592572436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80758974400578,
              21.02262536850138
            ],
            [
              105.80758936946177,
              21.022772657026806
            ],
            [
              105.80758922889007,
              21.022828002111194
            ],
            [
              105.80759226380697,
              21.022872088730196
            ],
            [
              105.80760128489244,
              21.022913800337417
            ],
            [
              105.80760846129547,
              21.022967553511517
            ],
            [
              105.80761151247403,
              21.023058592572436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1994",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02262536850138,
          "to_latitude": 21.023058592572436,
          "to_longitude": 105.80761151247403,
          "from_longitude": 105.80758974400578
        }
      },
      {
        "id": 1989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80710624964566,
            21.02249955593189,
            105.80714055601071,
            21.023285524820785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80712618207026,
              21.023285524820785
            ],
            [
              105.80712074520635,
              21.02322249895485
            ],
            [
              105.80712128503278,
              21.02321609340671
            ],
            [
              105.80714055601071,
              21.02298689083407
            ],
            [
              105.80712809100376,
              21.022728026901824
            ],
            [
              105.80712768926406,
              21.022719696525545
            ],
            [
              105.80711134782206,
              21.022592064789894
            ],
            [
              105.80710624964566,
              21.02249955593189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_21_Ngõ 59, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1995",
          "diaChiLapD": "Ngõ 59, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023285524820785,
          "to_latitude": 21.02249955593189,
          "to_longitude": 105.80710624964566,
          "from_longitude": 105.80712618207026
        }
      },
      {
        "id": 1990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80794969158488,
            21.023327690585518,
            105.8083820678141,
            21.023496845594845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80794969158488,
              21.02349588401901
            ],
            [
              105.80795415375297,
              21.0234948963673
            ],
            [
              105.80795775344868,
              21.023496845594845
            ],
            [
              105.80795974877125,
              21.023493660365435
            ],
            [
              105.80833118136637,
              21.02341155150221
            ],
            [
              105.8083820678141,
              21.023327690585518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1996",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349588401901,
          "to_latitude": 21.023327690585518,
          "to_longitude": 105.8083820678141,
          "from_longitude": 105.80794969158488
        }
      },
      {
        "id": 1991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80783256575586,
            21.02306210726949,
            105.80849092892468,
            21.023189601683217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80783256575586,
              21.023164420883198
            ],
            [
              105.80783587938386,
              21.023163627464758
            ],
            [
              105.80785094104016,
              21.02316001617333
            ],
            [
              105.80790679004342,
              21.023189601683217
            ],
            [
              105.80849092892468,
              21.02306210726949
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1997",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023164420883198,
          "to_latitude": 21.02306210726949,
          "to_longitude": 105.80849092892468,
          "from_longitude": 105.80783256575586
        }
      },
      {
        "id": 1992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80738876968076,
            21.023171246859373,
            105.80783459866203,
            21.023318706840765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80783459866203,
              21.023171246859373
            ],
            [
              105.80783149867615,
              21.02317187668193
            ],
            [
              105.80783000158979,
              21.02317451218709
            ],
            [
              105.80780060863887,
              21.023226235161996
            ],
            [
              105.80738876968076,
              21.023318706840765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1998",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023171246859373,
          "to_latitude": 21.023318706840765,
          "to_longitude": 105.80738876968076,
          "from_longitude": 105.80783459866203
        }
      },
      {
        "id": 1993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80684679693282,
            21.023325692042047,
            105.80735840357293,
            21.02340226858526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80684679693282,
              21.02340226858526
            ],
            [
              105.80686095374348,
              21.02340015019336
            ],
            [
              105.80735840357293,
              21.023325692042047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1999",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02340226858526,
          "to_latitude": 21.023325692042047,
          "to_longitude": 105.80735840357293,
          "from_longitude": 105.80684679693282
        }
      },
      {
        "id": 1994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80786390284318,
            21.023057167223524,
            105.80786921004606,
            21.02308053924133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80786390284318,
              21.023057167223524
            ],
            [
              105.80786484192276,
              21.02306130677456
            ],
            [
              105.80786921004606,
              21.02308053924133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2000",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023057167223524,
          "to_latitude": 21.02308053924133,
          "to_longitude": 105.80786921004606,
          "from_longitude": 105.80786390284318
        }
      },
      {
        "id": 1995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80764017418667,
            21.0230286323441,
            105.80809585806627,
            21.023108458291468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80764017418667,
              21.023108458291468
            ],
            [
              105.80783183974087,
              21.023063415404643
            ],
            [
              105.80786390284318,
              21.023057167223524
            ],
            [
              105.80801033023145,
              21.0230286323441
            ],
            [
              105.80809585806627,
              21.02302886458423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2001",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023108458291468,
          "to_latitude": 21.02302886458423,
          "to_longitude": 105.80809585806627,
          "from_longitude": 105.80764017418667
        }
      },
      {
        "id": 1996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80736521836744,
            21.023159377152147,
            105.8073824319855,
            21.023210428101244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80736521836744,
              21.023159377152147
            ],
            [
              105.80736714817019,
              21.023165103491728
            ],
            [
              105.8073824319855,
              21.023210428101244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2002",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023159377152147,
          "to_latitude": 21.023210428101244,
          "to_longitude": 105.8073824319855,
          "from_longitude": 105.80736521836744
        }
      },
      {
        "id": 1997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80699158035914,
            21.023127886257296,
            105.80759024768138,
            21.023240489075974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80699158035914,
              21.023240489075974
            ],
            [
              105.80712128503278,
              21.02321609340671
            ],
            [
              105.80714379878233,
              21.023211858811184
            ],
            [
              105.80736521836744,
              21.023159377152147
            ],
            [
              105.80737806474035,
              21.02315633280687
            ],
            [
              105.80759024768138,
              21.023127886257296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2003",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023240489075974,
          "to_latitude": 21.023127886257296,
          "to_longitude": 105.80759024768138,
          "from_longitude": 105.80699158035914
        }
      },
      {
        "id": 1998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8059272272725,
            21.023132900166154,
            105.8062966360942,
            21.023160660387884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8062966360942,
              21.023132900166154
            ],
            [
              105.8059272272725,
              21.023160660387884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 69, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2004",
          "diaChiLapD": "Ngõ 69, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023132900166154,
          "to_latitude": 21.023160660387884,
          "to_longitude": 105.8059272272725,
          "from_longitude": 105.8062966360942
        }
      },
      {
        "id": 1999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80592896193404,
            21.02295223829277,
            105.80627279810436,
            21.022972060736375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80627279810436,
              21.02295223829277
            ],
            [
              105.80592896193404,
              21.022972060736375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 69, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2005",
          "diaChiLapD": "Ngõ 69, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02295223829277,
          "to_latitude": 21.022972060736375,
          "to_longitude": 105.80592896193404,
          "from_longitude": 105.80627279810436
        }
      },
      {
        "id": 2000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80588189673973,
            21.02280319575453,
            105.80625467221377,
            21.022823346759367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80625467221377,
              21.02280319575453
            ],
            [
              105.80588189673973,
              21.022823346759367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 69, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2006",
          "diaChiLapD": "Ngõ 69, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02280319575453,
          "to_latitude": 21.022823346759367,
          "to_longitude": 105.80588189673973,
          "from_longitude": 105.80625467221377
        }
      },
      {
        "id": 2001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80623666352206,
            21.02265304168406,
            105.80633232996713,
            21.02340340416093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80633232996713,
              21.02340340416093
            ],
            [
              105.80632739514927,
              21.023371600492244
            ],
            [
              105.80631220047235,
              21.023250849450434
            ],
            [
              105.8062966360942,
              21.023132900166154
            ],
            [
              105.80627279810436,
              21.02295223829277
            ],
            [
              105.8062700573487,
              21.02293147702746
            ],
            [
              105.80625467221377,
              21.02280319575453
            ],
            [
              105.80623666352206,
              21.02265304168406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_18_Ngõ 69, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2007",
          "diaChiLapD": "Ngõ 69, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02340340416093,
          "to_latitude": 21.02265304168406,
          "to_longitude": 105.80623666352206,
          "from_longitude": 105.80633232996713
        }
      },
      {
        "id": 2002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80603247568285,
            21.02340187283496,
            105.80604896623419,
            21.023466308687627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80603247568285,
              21.02340187283496
            ],
            [
              105.80603435164223,
              21.023408865772502
            ],
            [
              105.80604015862032,
              21.023432023226732
            ],
            [
              105.80604728115452,
              21.02345974929865
            ],
            [
              105.80604896623419,
              21.023466308687627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2008",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02340187283496,
          "to_latitude": 21.023466308687627,
          "to_longitude": 105.80604896623419,
          "from_longitude": 105.80603247568285
        }
      },
      {
        "id": 2003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80586761307681,
            21.023356293199715,
            105.80628744406197,
            21.023431344222743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80586761307681,
              21.023431344222743
            ],
            [
              105.80603247568285,
              21.02340187283496
            ],
            [
              105.80606096462114,
              21.02339677999659
            ],
            [
              105.80628744406197,
              21.023356293199715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2009",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023431344222743,
          "to_latitude": 21.023356293199715,
          "to_longitude": 105.80628744406197,
          "from_longitude": 105.80586761307681
        }
      },
      {
        "id": 2004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80674623351595,
            21.022528929837282,
            105.80682765020863,
            21.023330007202578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80682765020863,
              21.023330007202578
            ],
            [
              105.80682550505118,
              21.023251899857765
            ],
            [
              105.80681948178577,
              21.023238446842626
            ],
            [
              105.80675297494587,
              21.022611500822727
            ],
            [
              105.80674623351595,
              21.022528929837282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 67, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2010",
          "diaChiLapD": "Ngõ 67, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023330007202578,
          "to_latitude": 21.022528929837282,
          "to_longitude": 105.80674623351595,
          "from_longitude": 105.80682765020863
        }
      },
      {
        "id": 2005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80984520707355,
            21.025272444824566,
            105.80987077913173,
            21.025282400039934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80984520707355,
              21.025282400039934
            ],
            [
              105.80986519010197,
              21.025274620218838
            ],
            [
              105.80987077913173,
              21.025272444824566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D25_37_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2011",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 25,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025282400039934,
          "to_latitude": 21.025272444824566,
          "to_longitude": 105.80987077913173,
          "from_longitude": 105.80984520707355
        }
      },
      {
        "id": 2006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80991640324646,
            21.0254831330368,
            105.80995083929292,
            21.025537290053585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80995083929292,
              21.025537290053585
            ],
            [
              105.80992651808972,
              21.02549904218986
            ],
            [
              105.80991640324646,
              21.0254831330368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2012",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025537290053585,
          "to_latitude": 21.0254831330368,
          "to_longitude": 105.80991640324646,
          "from_longitude": 105.80995083929292
        }
      },
      {
        "id": 2007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80995402260766,
            21.025379286132193,
            105.81001705693119,
            21.025473187827146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81001705693119,
              21.025473187827146
            ],
            [
              105.8099988164824,
              21.025438666631878
            ],
            [
              105.8099674412157,
              21.025379286132193
            ],
            [
              105.80996098094025,
              21.025382235229138
            ],
            [
              105.80995402260766,
              21.02538541069838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q49_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2013",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025473187827146,
          "to_latitude": 21.02538541069838,
          "to_longitude": 105.80995402260766,
          "from_longitude": 105.81001705693119
        }
      },
      {
        "id": 2008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80990774670903,
            21.025396059749784,
            105.8099354374699,
            21.025406839776604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80990774670903,
              21.025406839776604
            ],
            [
              105.80992773071222,
              21.02539905994325
            ],
            [
              105.8099354374699,
              21.025396059749784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D25_37_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2014",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 25,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025406839776604,
          "to_latitude": 21.025396059749784,
          "to_longitude": 105.8099354374699,
          "from_longitude": 105.80990774670903
        }
      },
      {
        "id": 2009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80975120047437,
            21.025804678722942,
            105.80992204419888,
            21.026084183468473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80992204419888,
              21.025804678722942
            ],
            [
              105.80992079396222,
              21.025805281710692
            ],
            [
              105.80989473856033,
              21.025817846855617
            ],
            [
              105.80987061509597,
              21.025831701637827
            ],
            [
              105.80986082674788,
              21.025830111602996
            ],
            [
              105.809858855716,
              21.025841174689976
            ],
            [
              105.80976599847729,
              21.02590519596519
            ],
            [
              105.80980774960791,
              21.025944380595696
            ],
            [
              105.80975120047437,
              21.02603068230395
            ],
            [
              105.80983782680623,
              21.026084183468473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2015",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025804678722942,
          "to_latitude": 21.026084183468473,
          "to_longitude": 105.80983782680623,
          "from_longitude": 105.80992204419888
        }
      },
      {
        "id": 2010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80851164011658,
            21.026483074334763,
            105.80872023375277,
            21.02683823898408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80851164011658,
              21.02683823898408
            ],
            [
              105.80855061504522,
              21.026752255163288
            ],
            [
              105.80864634805909,
              21.02660195988765
            ],
            [
              105.80872023375277,
              21.026483074334763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2016",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02683823898408,
          "to_latitude": 21.026483074334763,
          "to_longitude": 105.80872023375277,
          "from_longitude": 105.80851164011658
        }
      },
      {
        "id": 2011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80806597269444,
            21.027773417519377,
            105.80815252030322,
            21.027779035015598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80806597269444,
              21.027779035015598
            ],
            [
              105.80815252030322,
              21.027773417519377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2017",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027779035015598,
          "to_latitude": 21.027773417519377,
          "to_longitude": 105.80815252030322,
          "from_longitude": 105.80806597269444
        }
      },
      {
        "id": 2012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80617541621424,
            21.028277730501415,
            105.80618474347078,
            21.028349912836976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80618474347078,
              21.028277730501415
            ],
            [
              105.80617572282225,
              21.028347535893584
            ],
            [
              105.80617541621424,
              21.028349912836976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2018",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028277730501415,
          "to_latitude": 21.028349912836976,
          "to_longitude": 105.80617541621424,
          "from_longitude": 105.80618474347078
        }
      },
      {
        "id": 2013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80606629481359,
            21.027823043378255,
            105.80621536683229,
            21.028296915297897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80621536683229,
              21.028296915297897
            ],
            [
              105.80618474347078,
              21.028277730501415
            ],
            [
              105.80613383060488,
              21.028162401127847
            ],
            [
              105.80611643913494,
              21.028077422971524
            ],
            [
              105.80610394912641,
              21.028014080083775
            ],
            [
              105.80606629481359,
              21.027823043378255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2019",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028296915297897,
          "to_latitude": 21.027823043378255,
          "to_longitude": 105.80606629481359,
          "from_longitude": 105.80621536683229
        }
      },
      {
        "id": 2014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80991016394303,
            21.02478708755974,
            105.81004142915607,
            21.0250421137878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81004142915607,
              21.0250421137878
            ],
            [
              105.81004112290765,
              21.025041518212916
            ],
            [
              105.80991016394303,
              21.02478708755974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2020",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0250421137878,
          "to_latitude": 21.02478708755974,
          "to_longitude": 105.80991016394303,
          "from_longitude": 105.81004142915607
        }
      },
      {
        "id": 2015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80954719876081,
            21.024481971034888,
            105.80985358886856,
            21.024864166037844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8096848690775,
              21.024864166037844
            ],
            [
              105.8096870656301,
              21.024863153900526
            ],
            [
              105.8097013379232,
              21.024856573677706
            ],
            [
              105.80954719876081,
              21.024579402315617
            ],
            [
              105.8097344806343,
              21.024481971034888
            ],
            [
              105.80985358886856,
              21.02468114912446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_39_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2021",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024864166037844,
          "to_latitude": 21.02468114912446,
          "to_longitude": 105.80985358886856,
          "from_longitude": 105.8096848690775
        }
      },
      {
        "id": 2016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81039726897112,
            21.024803108960704,
            105.81061977831025,
            21.025084375825344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81044258805784,
              21.025084375825344
            ],
            [
              105.81044169493865,
              21.025083415434253
            ],
            [
              105.81043131119299,
              21.025072222441796
            ],
            [
              105.8104434654622,
              21.025035448316324
            ],
            [
              105.81041191049897,
              21.025000678999994
            ],
            [
              105.81039726897112,
              21.024936100246475
            ],
            [
              105.81041845534456,
              21.02490642309805
            ],
            [
              105.81061977831025,
              21.024803108960704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2022",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025084375825344,
          "to_latitude": 21.024803108960704,
          "to_longitude": 105.81061977831025,
          "from_longitude": 105.81044258805784
        }
      },
      {
        "id": 2017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81027271038668,
            21.02488973207943,
            105.81048655651819,
            21.025229344462076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81027271038668,
              21.025221661179856
            ],
            [
              105.81027525144857,
              21.02522440753047
            ],
            [
              105.81027982455561,
              21.025229344462076
            ],
            [
              105.81035170435463,
              21.025165971134914
            ],
            [
              105.81037182830187,
              21.02518337979765
            ],
            [
              105.81045815920253,
              21.025073555018537
            ],
            [
              105.81046414443851,
              21.0250791870236
            ],
            [
              105.81048655651819,
              21.02488973207943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2023",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025221661179856,
          "to_latitude": 21.02488973207943,
          "to_longitude": 105.81048655651819,
          "from_longitude": 105.81027271038668
        }
      },
      {
        "id": 2018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81008871143015,
            21.0253620240949,
            105.81010804506461,
            21.02538236572062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81008871143015,
              21.0253620240949
            ],
            [
              105.81010148925719,
              21.025375468289372
            ],
            [
              105.81010376676204,
              21.025377864542378
            ],
            [
              105.8101061468272,
              21.025380369597375
            ],
            [
              105.81010804506461,
              21.02538236572062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2024",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0253620240949,
          "to_latitude": 21.02538236572062,
          "to_longitude": 105.81010804506461,
          "from_longitude": 105.81008871143015
        }
      },
      {
        "id": 2019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81008871143015,
            21.025059475493805,
            105.81042973098242,
            21.0253620240949
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81008871143015,
              21.0253620240949
            ],
            [
              105.81012199788346,
              21.02533624186815
            ],
            [
              105.81025727238027,
              21.02521458026612
            ],
            [
              105.81042973098242,
              21.025059475493805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2025",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0253620240949,
          "to_latitude": 21.025059475493805,
          "to_longitude": 105.81042973098242,
          "from_longitude": 105.81008871143015
        }
      },
      {
        "id": 2020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80972603495391,
            21.02488535396394,
            105.80998836827865,
            21.025366042691953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80972603495391,
              21.02488535396394
            ],
            [
              105.80984867478237,
              21.025123844471874
            ],
            [
              105.80985465941187,
              21.02513595716115
            ],
            [
              105.80986008866437,
              21.025146945266627
            ],
            [
              105.80986613415334,
              21.025159282569316
            ],
            [
              105.80986714967972,
              21.02516135515721
            ],
            [
              105.80986814338883,
              21.02516330681647
            ],
            [
              105.80987740371889,
              21.025181488951187
            ],
            [
              105.80998836827865,
              21.025366042691953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2026",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02488535396394,
          "to_latitude": 21.025366042691953,
          "to_longitude": 105.80998836827865,
          "from_longitude": 105.80972603495391
        }
      },
      {
        "id": 2021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80983459839686,
            21.02499434170955,
            105.81013650535155,
            21.02514603696995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81013650535155,
              21.02499434170955
            ],
            [
              105.81004142915607,
              21.0250421137878
            ],
            [
              105.80985465941187,
              21.02513595716115
            ],
            [
              105.8098483149126,
              21.02513914505771
            ],
            [
              105.80983459839686,
              21.02514603696995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2027",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02499434170955,
          "to_latitude": 21.02514603696995,
          "to_longitude": 105.80983459839686,
          "from_longitude": 105.81013650535155
        }
      },
      {
        "id": 2022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80984871090111,
            21.025027708949196,
            105.8101444268836,
            21.02517291576189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8101444268836,
              21.025027708949196
            ],
            [
              105.80986814338883,
              21.02516330681647
            ],
            [
              105.80985012955784,
              21.025172148488547
            ],
            [
              105.80984871090111,
              21.02517291576189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2028",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025027708949196,
          "to_latitude": 21.02517291576189,
          "to_longitude": 105.80984871090111,
          "from_longitude": 105.8101444268836
        }
      },
      {
        "id": 2023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80984618660638,
            21.025159282569316,
            105.80986613415334,
            21.025168107255798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80986613415334,
              21.025159282569316
            ],
            [
              105.80985899007925,
              21.02516244266093
            ],
            [
              105.80984819911627,
              21.025167217025263
            ],
            [
              105.80984618660638,
              21.025168107255798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2029",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025159282569316,
          "to_latitude": 21.025168107255798,
          "to_longitude": 105.80984618660638,
          "from_longitude": 105.80986613415334
        }
      },
      {
        "id": 2024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80982102325659,
            21.025166550370866,
            105.8098453691524,
            21.025177320777406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80982102325659,
              21.025177320777406
            ],
            [
              105.80983582005055,
              21.025170775077655
            ],
            [
              105.80984414840535,
              21.025167090894804
            ],
            [
              105.8098453691524,
              21.025166550370866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2030",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025177320777406,
          "to_latitude": 21.025166550370866,
          "to_longitude": 105.8098453691524,
          "from_longitude": 105.80982102325659
        }
      },
      {
        "id": 2025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80967801397563,
            21.02488182155265,
            105.80995401710742,
            21.025431579210238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80967801397563,
              21.02488182155265
            ],
            [
              105.80974674323409,
              21.02500883319046
            ],
            [
              105.8098089009891,
              21.02512369967331
            ],
            [
              105.80981103851408,
              21.02514239172958
            ],
            [
              105.80981335677639,
              21.02516266447702
            ],
            [
              105.80982102325659,
              21.025177320777406
            ],
            [
              105.80987077913173,
              21.025272444824566
            ],
            [
              105.8099354374699,
              21.025396059749784
            ],
            [
              105.80995401710742,
              21.025431579210238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2031",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02488182155265,
          "to_latitude": 21.025431579210238,
          "to_longitude": 105.80995401710742,
          "from_longitude": 105.80967801397563
        }
      },
      {
        "id": 2026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80925391612134,
            21.024424159413805,
            105.8096866707095,
            21.024873337789824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80925391612134,
              21.024424159413805
            ],
            [
              105.80946914531064,
              21.024809290366584
            ],
            [
              105.80960370094395,
              21.024745463667728
            ],
            [
              105.80965292034976,
              21.02483304206989
            ],
            [
              105.80967378634212,
              21.024873337789824
            ],
            [
              105.80968474918917,
              21.024868864296586
            ],
            [
              105.8096866707095,
              21.024867870630022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_38_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2032",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024424159413805,
          "to_latitude": 21.024867870630022,
          "to_longitude": 105.8096866707095,
          "from_longitude": 105.80925391612134
        }
      },
      {
        "id": 2027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80927133927031,
            21.024094167976756,
            105.80962103676933,
            21.024730052168813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80962103676933,
              21.024726155935895
            ],
            [
              105.80961829176246,
              21.024727681006997
            ],
            [
              105.8096136985135,
              21.024730052168813
            ],
            [
              105.80939622863804,
              21.024342970255272
            ],
            [
              105.80939033386122,
              21.024318264315283
            ],
            [
              105.80935677299567,
              21.024255061687356
            ],
            [
              105.80927133927031,
              21.024094167976756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2033",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024726155935895,
          "to_latitude": 21.024094167976756,
          "to_longitude": 105.80927133927031,
          "from_longitude": 105.80962103676933
        }
      },
      {
        "id": 2028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8108485468448,
            21.02482000302764,
            105.81090774427489,
            21.024850276951177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8108485468448,
              21.024850276951177
            ],
            [
              105.81087583929349,
              21.024836319312982
            ],
            [
              105.8108904971463,
              21.024828823087162
            ],
            [
              105.81090515499757,
              21.024821326860106
            ],
            [
              105.81090774427489,
              21.02482000302764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2035",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024850276951177,
          "to_latitude": 21.02482000302764,
          "to_longitude": 105.81090774427489,
          "from_longitude": 105.8108485468448
        }
      },
      {
        "id": 2029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81075282469996,
            21.024693329626036,
            105.81090428399125,
            21.025034782702516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81082130230307,
              21.025034782702516
            ],
            [
              105.8108995700514,
              21.024946084709356
            ],
            [
              105.81090428399125,
              21.024941663514287
            ],
            [
              105.81089258719005,
              21.024922485053224
            ],
            [
              105.8108485468448,
              21.024850276951177
            ],
            [
              105.81075282469996,
              21.024693329626036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2036",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025034782702516,
          "to_latitude": 21.024693329626036,
          "to_longitude": 105.81075282469996,
          "from_longitude": 105.81082130230307
        }
      },
      {
        "id": 2030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81098790747396,
            21.025182748085342,
            105.81100713542095,
            21.025187980919522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81098790747396,
              21.025182748085342
            ],
            [
              105.81100713542095,
              21.025187980919522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2037",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025182748085342,
          "to_latitude": 21.025187980919522,
          "to_longitude": 105.81100713542095,
          "from_longitude": 105.81098790747396
        }
      },
      {
        "id": 2031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81092431820862,
            21.024975024441975,
            105.81099911037835,
            21.025326869938795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81092431820862,
              21.024975024441975
            ],
            [
              105.81099911037835,
              21.025099415272148
            ],
            [
              105.81098790747396,
              21.025182748085342
            ],
            [
              105.81097796966877,
              21.025256669555812
            ],
            [
              105.8109302935083,
              21.025326869938795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2038",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024975024441975,
          "to_latitude": 21.025326869938795,
          "to_longitude": 105.8109302935083,
          "from_longitude": 105.81092431820862
        }
      },
      {
        "id": 2032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81077391830262,
            21.0255414564562,
            105.81079685111091,
            21.025559166900408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81077391830262,
              21.0255414564562
            ],
            [
              105.81077734484465,
              21.02554410283519
            ],
            [
              105.81078878468904,
              21.02555293876468
            ],
            [
              105.81079685111091,
              21.025559166900408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2039",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0255414564562,
          "to_latitude": 21.025559166900408,
          "to_longitude": 105.81079685111091,
          "from_longitude": 105.81077391830262
        }
      },
      {
        "id": 2033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81061198629482,
            21.025368557773817,
            105.8109160302695,
            21.02573846361152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8109160302695,
              21.025368557773817
            ],
            [
              105.81077391830262,
              21.0255414564562
            ],
            [
              105.81077076730271,
              21.025545289421423
            ],
            [
              105.81061198629482,
              21.02573846361152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2040",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025368557773817,
          "to_latitude": 21.02573846361152,
          "to_longitude": 105.81061198629482,
          "from_longitude": 105.8109160302695
        }
      },
      {
        "id": 2034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81042500319018,
            21.02597141562173,
            105.81044017098837,
            21.02598312865282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81042500319018,
              21.02597141562173
            ],
            [
              105.81043076755336,
              21.0259758672573
            ],
            [
              105.81043852872835,
              21.02598186202498
            ],
            [
              105.81044017098837,
              21.02598312865282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2041",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02597141562173,
          "to_latitude": 21.02598312865282,
          "to_longitude": 105.81044017098837,
          "from_longitude": 105.81042500319018
        }
      },
      {
        "id": 2035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81031317469677,
            21.025785296297943,
            105.81057666934788,
            21.02610864658115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81057666934788,
              21.025785296297943
            ],
            [
              105.81044492216307,
              21.025946971487492
            ],
            [
              105.81042500319018,
              21.02597141562173
            ],
            [
              105.81031317469677,
              21.02610864658115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2042",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025785296297943,
          "to_latitude": 21.02610864658115,
          "to_longitude": 105.81031317469677,
          "from_longitude": 105.81057666934788
        }
      },
      {
        "id": 2036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81029623216034,
            21.025688751868127,
            105.8104698332303,
            21.025912575174193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8104698332303,
              21.025688751868127
            ],
            [
              105.81046669969622,
              21.02569279248707
            ],
            [
              105.81029623216034,
              21.025912575174193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2043",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025688751868127,
          "to_latitude": 21.025912575174193,
          "to_longitude": 105.81029623216034,
          "from_longitude": 105.8104698332303
        }
      },
      {
        "id": 2037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81042018900905,
            21.025247981012384,
            105.81069858919045,
            21.025775983400727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81061394098133,
              21.025775983400727
            ],
            [
              105.81061192668943,
              21.025774765515667
            ],
            [
              105.8104698332303,
              21.025688751868127
            ],
            [
              105.81042018900905,
              21.02565815677498
            ],
            [
              105.81044456387247,
              21.02561092013511
            ],
            [
              105.81051018296884,
              21.02553454209807
            ],
            [
              105.8105891745662,
              21.025449043635945
            ],
            [
              105.81069858919045,
              21.02529358603991
            ],
            [
              105.81061941795707,
              21.025247981012384
            ],
            [
              105.81059025465738,
              21.025281039954493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_07_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2044",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025775983400727,
          "to_latitude": 21.025281039954493,
          "to_longitude": 105.81059025465738,
          "from_longitude": 105.81061394098133
        }
      },
      {
        "id": 2038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81000613122521,
            21.02594933365481,
            105.81002571334358,
            21.0259718603915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81002571334358,
              21.02594933365481
            ],
            [
              105.81000987613061,
              21.025967552222976
            ],
            [
              105.8100063970069,
              21.02597155383462
            ],
            [
              105.81000613122521,
              21.0259718603915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2045",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02594933365481,
          "to_latitude": 21.0259718603915,
          "to_longitude": 105.81000613122521,
          "from_longitude": 105.81002571334358
        }
      },
      {
        "id": 2039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80994059656827,
            21.02580273423345,
            105.8102429862399,
            21.026113746433108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80994059656827,
              21.02580273423345
            ],
            [
              105.81001187249416,
              21.025932157867043
            ],
            [
              105.81002571334358,
              21.02594933365481
            ],
            [
              105.8100609600217,
              21.02599307346954
            ],
            [
              105.81011670078006,
              21.0260379566849
            ],
            [
              105.81021272823662,
              21.026097912496244
            ],
            [
              105.8102429862399,
              21.026113746433108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2046",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02580273423345,
          "to_latitude": 21.026113746433108,
          "to_longitude": 105.8102429862399,
          "from_longitude": 105.80994059656827
        }
      },
      {
        "id": 2040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80998521079806,
            21.025975763276747,
            105.81000972617062,
            21.026003974036154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80998521079806,
              21.026003974036154
            ],
            [
              105.8099897568786,
              21.02599874256636
            ],
            [
              105.809997606157,
              21.025989709936294
            ],
            [
              105.80999948386676,
              21.02598754950449
            ],
            [
              105.81000937624685,
              21.02597616507329
            ],
            [
              105.81000972617062,
              21.025975763276747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2047",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026003974036154,
          "to_latitude": 21.025975763276747,
          "to_longitude": 105.81000972617062,
          "from_longitude": 105.80998521079806
        }
      },
      {
        "id": 2041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80985767705921,
            21.02582546685047,
            105.81019658477565,
            21.026179971765146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81019658477565,
              21.026179971765146
            ],
            [
              105.81006726926435,
              21.02608518979799
            ],
            [
              105.8100602351709,
              21.0260861003381
            ],
            [
              105.8100592325652,
              21.026079299293546
            ],
            [
              105.81004156264167,
              21.026066347176013
            ],
            [
              105.81002992049358,
              21.026057814741495
            ],
            [
              105.81001982270699,
              21.026050413017007
            ],
            [
              105.80998521079806,
              21.026003974036154
            ],
            [
              105.80991326407381,
              21.025907443720566
            ],
            [
              105.80986082674788,
              21.025830111602996
            ],
            [
              105.80985767705921,
              21.02582546685047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2048",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026179971765146,
          "to_latitude": 21.02582546685047,
          "to_longitude": 105.80985767705921,
          "from_longitude": 105.81019658477565
        }
      },
      {
        "id": 2042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80982661661086,
            21.026054421993134,
            105.81009254234664,
            21.02639887350068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81009254234664,
              21.026054421993134
            ],
            [
              105.81009128730655,
              21.026055738554877
            ],
            [
              105.81007640366275,
              21.026071505743044
            ],
            [
              105.81007313136169,
              21.026074971660083
            ],
            [
              105.8100602351709,
              21.0260861003381
            ],
            [
              105.81002156418776,
              21.026137165157508
            ],
            [
              105.8099857938882,
              21.026151242528936
            ],
            [
              105.80992643815677,
              21.026256157827458
            ],
            [
              105.80988433594104,
              21.026323490733887
            ],
            [
              105.80982661661086,
              21.02639887350068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_27_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2049",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026054421993134,
          "to_latitude": 21.02639887350068,
          "to_longitude": 105.80982661661086,
          "from_longitude": 105.81009254234664
        }
      },
      {
        "id": 2043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80979609750685,
            21.02672650117393,
            105.80982215075925,
            21.026743603465494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80979609750685,
              21.02672650117393
            ],
            [
              105.80980882195189,
              21.02673485414592
            ],
            [
              105.80982035312698,
              21.026742425184867
            ],
            [
              105.80982215075925,
              21.026743603465494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2050",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02672650117393,
          "to_latitude": 21.026743603465494,
          "to_longitude": 105.80982215075925,
          "from_longitude": 105.80979609750685
        }
      },
      {
        "id": 2044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8100757271492,
            21.026383371788633,
            105.81009310786322,
            21.026394779856272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8100757271492,
              21.026383371788633
            ],
            [
              105.81008238092076,
              21.026387738811678
            ],
            [
              105.8100912947829,
              21.026393590813267
            ],
            [
              105.81009310786322,
              21.026394779856272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2051",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026383371788633,
          "to_latitude": 21.026394779856272,
          "to_longitude": 105.81009310786322,
          "from_longitude": 105.8100757271492
        }
      },
      {
        "id": 2045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80995410822032,
            21.02621398912369,
            105.81019696390884,
            21.026524091359946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80995410822032,
              21.026524091359946
            ],
            [
              105.81000525480019,
              21.02647269345463
            ],
            [
              105.8100757271492,
              21.026383371788633
            ],
            [
              105.81008973618832,
              21.02636561534315
            ],
            [
              105.81010933660721,
              21.026340771730762
            ],
            [
              105.81016032368203,
              21.02626700068961
            ],
            [
              105.81019696390884,
              21.02621398912369
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2052",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026524091359946,
          "to_latitude": 21.02621398912369,
          "to_longitude": 105.81019696390884,
          "from_longitude": 105.80995410822032
        }
      },
      {
        "id": 2046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80968570694074,
            21.026528451133117,
            105.80994294166383,
            21.02688045266855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80994294166383,
              21.026528451133117
            ],
            [
              105.80981715699465,
              21.026697131797853
            ],
            [
              105.80979609750685,
              21.02672650117393
            ],
            [
              105.80968570694074,
              21.02688045266855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2053",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026528451133117,
          "to_latitude": 21.02688045266855,
          "to_longitude": 105.80968570694074,
          "from_longitude": 105.80994294166383
        }
      },
      {
        "id": 2047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80931451783398,
            21.02734301294234,
            105.80934983199755,
            21.027376775520583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80931451783398,
              21.02734301294234
            ],
            [
              105.8093410157864,
              21.02736834701165
            ],
            [
              105.80934716403229,
              21.027374226664964
            ],
            [
              105.80934983199755,
              21.027376775520583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2054",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02734301294234,
          "to_latitude": 21.027376775520583,
          "to_longitude": 105.80934983199755,
          "from_longitude": 105.80931451783398
        }
      },
      {
        "id": 2048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80918647264045,
            21.02719768960444,
            105.80942788215151,
            21.0274396326777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80942788215151,
              21.02719768960444
            ],
            [
              105.80935523565329,
              21.02730105119743
            ],
            [
              105.8093388101938,
              21.027317979084202
            ],
            [
              105.80931451783398,
              21.02734301294234
            ],
            [
              105.80927612858792,
              21.02738257546239
            ],
            [
              105.80918647264045,
              21.0274396326777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2055",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02719768960444,
          "to_latitude": 21.0274396326777,
          "to_longitude": 105.80918647264045,
          "from_longitude": 105.80942788215151
        }
      },
      {
        "id": 2049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80952100660625,
            21.027082861574236,
            105.80956423658596,
            21.027108677765845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80952100660625,
              21.027082861574236
            ],
            [
              105.80954914875036,
              21.027099667639014
            ],
            [
              105.80956091269775,
              21.027106694746884
            ],
            [
              105.80956423658596,
              21.027108677765845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2056",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027082861574236,
          "to_latitude": 21.027108677765845,
          "to_longitude": 105.80956423658596,
          "from_longitude": 105.80952100660625
        }
      },
      {
        "id": 2050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80945979592,
            21.026972086705538,
            105.80959696643164,
            21.02715508599806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80959696643164,
              21.026972086705538
            ],
            [
              105.80955032764003,
              21.02704826385861
            ],
            [
              105.80953699155758,
              21.0270640001509
            ],
            [
              105.80952100660625,
              21.027082861574236
            ],
            [
              105.80945979592,
              21.02715508599806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2057",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026972086705538,
          "to_latitude": 21.02715508599806,
          "to_longitude": 105.80945979592,
          "from_longitude": 105.80959696643164
        }
      },
      {
        "id": 2051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80930658535611,
            21.027114333783175,
            105.80949818407971,
            21.027196221412947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80930658535611,
              21.027130132839787
            ],
            [
              105.80933865690588,
              21.027149357147366
            ],
            [
              105.80937600274905,
              21.027114333783175
            ],
            [
              105.80949540148755,
              21.027194357751767
            ],
            [
              105.80949818407971,
              21.027196221412947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2058",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027130132839787,
          "to_latitude": 21.027196221412947,
          "to_longitude": 105.80949818407971,
          "from_longitude": 105.80930658535611
        }
      },
      {
        "id": 2052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80902461426847,
            21.0275374970641,
            105.80905372748867,
            21.027576829603245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80902461426847,
              21.0275374970641
            ],
            [
              105.80904710186182,
              21.027567878526863
            ],
            [
              105.80905199186984,
              21.0275744867592
            ],
            [
              105.80905372748867,
              21.027576829603245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2059",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0275374970641,
          "to_latitude": 21.027576829603245,
          "to_longitude": 105.80905372748867,
          "from_longitude": 105.80902461426847
        }
      },
      {
        "id": 2053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80890778189472,
            21.02745609631251,
            105.80917326932206,
            21.027601472086815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80917326932206,
              21.02745609631251
            ],
            [
              105.80902461426847,
              21.0275374970641
            ],
            [
              105.80890778189472,
              21.027601472086815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2060",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02745609631251,
          "to_latitude": 21.027601472086815,
          "to_longitude": 105.80890778189472,
          "from_longitude": 105.80917326932206
        }
      },
      {
        "id": 2054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80867098920392,
            21.0276976585127,
            105.80868435545702,
            21.027739558005177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80867098920392,
              21.0276976585127
            ],
            [
              105.80867964854406,
              21.02772480268067
            ],
            [
              105.80868246390419,
              21.027733631891337
            ],
            [
              105.80868435545702,
              21.027739558005177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2061",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0276976585127,
          "to_latitude": 21.027739558005177,
          "to_longitude": 105.80868435545702,
          "from_longitude": 105.80867098920392
        }
      },
      {
        "id": 2055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80822922442051,
            21.027850390944398,
            105.80824434606203,
            21.027894931297546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80822922442051,
              21.027850390944398
            ],
            [
              105.80824070079915,
              21.027884194793746
            ],
            [
              105.80824329913389,
              21.0278918517508
            ],
            [
              105.80824434606203,
              21.027894931297546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2062",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027850390944398,
          "to_latitude": 21.027894931297546,
          "to_longitude": 105.80824434606203,
          "from_longitude": 105.80822922442051
        }
      },
      {
        "id": 2056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80812180501069,
            21.02777710733159,
            105.80848065227372,
            21.027880139852446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80848065227372,
              21.02777710733159
            ],
            [
              105.80836436703149,
              21.027812214037002
            ],
            [
              105.8083342788266,
              21.02782129721305
            ],
            [
              105.80822922442051,
              21.027850390944398
            ],
            [
              105.80812180501069,
              21.027880139852446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2063",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02777710733159,
          "to_latitude": 21.027880139852446,
          "to_longitude": 105.80812180501069,
          "from_longitude": 105.80848065227372
        }
      },
      {
        "id": 2057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80851443255602,
            21.027614572484612,
            105.8088821513106,
            21.027758902943564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8088821513106,
              21.027614572484612
            ],
            [
              105.80873837684688,
              21.027671297080545
            ],
            [
              105.80867098920392,
              21.0276976585127
            ],
            [
              105.80851443255602,
              21.027758902943564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2064",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027614572484612,
          "to_latitude": 21.027758902943564,
          "to_longitude": 105.80851443255602,
          "from_longitude": 105.8088821513106
        }
      },
      {
        "id": 2058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80788923322638,
            21.027926802849738,
            105.80790430103917,
            21.027971189942324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80788923322638,
              21.027926802849738
            ],
            [
              105.8078970652817,
              21.027949874723625
            ],
            [
              105.80789966163253,
              21.027957523566727
            ],
            [
              105.80790112991653,
              21.0279618520844
            ],
            [
              105.80790430103917,
              21.027971189942324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2065",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027926802849738,
          "to_latitude": 21.027971189942324,
          "to_longitude": 105.80790430103917,
          "from_longitude": 105.80788923322638
        }
      },
      {
        "id": 2059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8077884700447,
            21.02788643907284,
            105.80806343088503,
            21.02795015036416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80806343088503,
              21.02788643907284
            ],
            [
              105.80791569422077,
              21.027920671571618
            ],
            [
              105.80788923322638,
              21.027926802849738
            ],
            [
              105.8077884700447,
              21.02795015036416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2066",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02788643907284,
          "to_latitude": 21.02795015036416,
          "to_longitude": 105.8077884700447,
          "from_longitude": 105.80806343088503
        }
      },
      {
        "id": 2060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80796275211755,
            21.027472835777342,
            105.80809018980312,
            21.027934286435805
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.8080109553572,
                21.027484121591858
              ],
              [
                105.8080712082052,
                21.027472835777342
              ]
            ],
            [
              [
                105.8080109553572,
                21.027484121591858
              ],
              [
                105.80796275211755,
                21.027493151678
              ]
            ],
            [
              [
                105.80807621335931,
                21.027934286435805
              ],
              [
                105.80807417644472,
                21.027925840096753
              ],
              [
                105.80809018980312,
                21.027880076029117
              ],
              [
                105.80806597269444,
                21.027779035015598
              ],
              [
                105.80804078818824,
                21.02767395574571
              ],
              [
                105.8080064725157,
                21.0275696406331
              ],
              [
                105.80803998636462,
                21.02756090072257
              ],
              [
                105.8080109553572,
                21.027484121591858
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2067",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8080712082052,
            21.027472835777342
          ],
          "to_latitude": [
            105.80807417644472,
            21.027925840096753
          ],
          "to_longitude": [
            105.80807621335931,
            21.027934286435805
          ],
          "from_longitude": [
            105.8080109553572,
            21.027484121591858
          ]
        }
      },
      {
        "id": 2061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80767159907492,
            21.02797409277972,
            105.80768223232924,
            21.028019807023476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80767159907492,
              21.02797409277972
            ],
            [
              105.80767832844684,
              21.028003023721485
            ],
            [
              105.80768084767173,
              21.028013857715834
            ],
            [
              105.80768223232924,
              21.028019807023476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2068",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02797409277972,
          "to_latitude": 21.028019807023476,
          "to_longitude": 105.80768223232924,
          "from_longitude": 105.80767159907492
        }
      },
      {
        "id": 2062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80733155916262,
            21.027957495623102,
            105.80775134773509,
            21.0280519300125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80775134773509,
              21.027957495623102
            ],
            [
              105.80767159907492,
              21.02797409277972
            ],
            [
              105.80755319933222,
              21.02799873424854
            ],
            [
              105.8075395438162,
              21.02800201729848
            ],
            [
              105.80733155916262,
              21.0280519300125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2069",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027957495623102,
          "to_latitude": 21.0280519300125,
          "to_longitude": 105.80733155916262,
          "from_longitude": 105.80775134773509
        }
      },
      {
        "id": 2063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80717870238823,
            21.02806956950379,
            105.80719092988035,
            21.028122129907636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80717870238823,
              21.02806956950379
            ],
            [
              105.8071852583175,
              21.028097750708156
            ],
            [
              105.80718917202087,
              21.028114580943974
            ],
            [
              105.80719092988035,
              21.028122129907636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2070",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02806956950379,
          "to_latitude": 21.028122129907636,
          "to_longitude": 105.80719092988035,
          "from_longitude": 105.80717870238823
        }
      },
      {
        "id": 2064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80710650187004,
            21.028040791688515,
            105.80728621972224,
            21.028088893543202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80728621972224,
              21.028040791688515
            ],
            [
              105.8071963608138,
              21.02806484309077
            ],
            [
              105.80717870238823,
              21.02806956950379
            ],
            [
              105.80710650187004,
              21.028088893543202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2071",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028040791688515,
          "to_latitude": 21.028088893543202,
          "to_longitude": 105.80710650187004,
          "from_longitude": 105.80728621972224
        }
      },
      {
        "id": 2065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80694657673222,
            21.028130777401635,
            105.80695544115011,
            21.028168905749112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80694657673222,
              21.028130777401635
            ],
            [
              105.80695041956108,
              21.02814731352763
            ],
            [
              105.80695156886695,
              21.028152259569275
            ],
            [
              105.80695342426726,
              21.028160238009296
            ],
            [
              105.80695544115011,
              21.028168905749112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2072",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028130777401635,
          "to_latitude": 21.028168905749112,
          "to_longitude": 105.80695544115011,
          "from_longitude": 105.80694657673222
        }
      },
      {
        "id": 2066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80676253176112,
            21.02809689927407,
            105.80708053191242,
            21.028177323299346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80708053191242,
              21.02809689927407
            ],
            [
              105.80694657673222,
              21.028130777401635
            ],
            [
              105.8069231372352,
              21.02813670541838
            ],
            [
              105.80676253176112,
              21.028177323299346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2073",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02809689927407,
          "to_latitude": 21.028177323299346,
          "to_longitude": 105.80676253176112,
          "from_longitude": 105.80708053191242
        }
      },
      {
        "id": 2067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80672991520501,
            21.028172467834917,
            105.80674487205009,
            21.02821810201886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80672991520501,
              21.028172467834917
            ],
            [
              105.80674370717271,
              21.028214551536784
            ],
            [
              105.80674487205009,
              21.02821810201886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2074",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028172467834917,
          "to_latitude": 21.02821810201886,
          "to_longitude": 105.80674487205009,
          "from_longitude": 105.80672991520501
        }
      },
      {
        "id": 2068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80654743035409,
            21.028072226759317,
            105.80672991520501,
            21.028217554613917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8066998185725,
              21.028072226759317
            ],
            [
              105.80672991520501,
              21.028172467834917
            ],
            [
              105.80669018734316,
              21.0281827995558
            ],
            [
              105.80667901366404,
              21.028185519794782
            ],
            [
              105.80654743035409,
              21.028217554613917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2075",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028072226759317,
          "to_latitude": 21.028217554613917,
          "to_longitude": 105.80654743035409,
          "from_longitude": 105.8066998185725
        }
      },
      {
        "id": 2069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80621536683229,
            21.0282304712512,
            105.80649438851415,
            21.028296915297897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80649438851415,
              21.0282304712512
            ],
            [
              105.8062197797631,
              21.02829586650194
            ],
            [
              105.80621536683229,
              21.028296915297897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2076",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0282304712512,
          "to_latitude": 21.028296915297897,
          "to_longitude": 105.80621536683229,
          "from_longitude": 105.80649438851415
        }
      },
      {
        "id": 2070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80645093035483,
            21.028008429970907,
            105.80656593615129,
            21.028260883201945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80645093035483,
              21.028008429970907
            ],
            [
              105.80649131257478,
              21.028118294983642
            ],
            [
              105.8065176654837,
              21.02821024325445
            ],
            [
              105.80656208642743,
              21.02824717058982
            ],
            [
              105.80656562035182,
              21.028259762890663
            ],
            [
              105.80656593615129,
              21.028260883201945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2077",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028008429970907,
          "to_latitude": 21.028260883201945,
          "to_longitude": 105.80656593615129,
          "from_longitude": 105.80645093035483
        }
      },
      {
        "id": 2071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80833447205187,
            21.02689036107609,
            105.80964450385379,
            21.026967542051562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80964450385379,
              21.026967542051562
            ],
            [
              105.80955272040374,
              21.026918978915226
            ],
            [
              105.80945884097359,
              21.02689877802064
            ],
            [
              105.80934153672519,
              21.02689589504768
            ],
            [
              105.80833447205187,
              21.02689036107609
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2078",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026967542051562,
          "to_latitude": 21.02689036107609,
          "to_longitude": 105.80833447205187,
          "from_longitude": 105.80964450385379
        }
      },
      {
        "id": 2072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80851164011658,
            21.02683823898408,
            105.80969993177652,
            21.026901384981446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80851164011658,
              21.02683823898408
            ],
            [
              105.80855591815117,
              21.026840277500515
            ],
            [
              105.8085740455139,
              21.026840839827827
            ],
            [
              105.80889898525493,
              21.02685092266178
            ],
            [
              105.80899371998416,
              21.026853862730423
            ],
            [
              105.80929398406737,
              21.026862154576943
            ],
            [
              105.80955406223607,
              21.026874589727278
            ],
            [
              105.8096670482387,
              21.026892480370076
            ],
            [
              105.80969341808225,
              21.026899622185507
            ],
            [
              105.80969993177652,
              21.026901384981446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2079",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02683823898408,
          "to_latitude": 21.026901384981446,
          "to_longitude": 105.80969993177652,
          "from_longitude": 105.80851164011658
        }
      },
      {
        "id": 2073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80301782243245,
            21.01875144566688,
            105.80302934250079,
            21.01877182000474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80302934250079,
              21.01875144566688
            ],
            [
              105.8030280074241,
              21.018753804882387
            ],
            [
              105.80302464832808,
              21.018759747732
            ],
            [
              105.80301782243245,
              21.01877182000474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2080",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01875144566688,
          "to_latitude": 21.01877182000474,
          "to_longitude": 105.80301782243245,
          "from_longitude": 105.80302934250079
        }
      },
      {
        "id": 2074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80533562912629,
            21.024359864002985,
            105.80537944153401,
            21.024361859508815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80533562912629,
              21.024359864002985
            ],
            [
              105.80534957189099,
              21.024360499788436
            ],
            [
              105.80537944153401,
              21.024361859508815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2081",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024359864002985,
          "to_latitude": 21.024361859508815,
          "to_longitude": 105.80537944153401,
          "from_longitude": 105.80533562912629
        }
      },
      {
        "id": 2075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80533519979844,
            21.02401516274636,
            105.8053360757854,
            21.0246918477056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8053360757854,
              21.02401516274636
            ],
            [
              105.8053357835712,
              21.02424068586854
            ],
            [
              105.80533562912629,
              21.024359864002985
            ],
            [
              105.80533519979844,
              21.0246918477056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2082",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02401516274636,
          "to_latitude": 21.0246918477056,
          "to_longitude": 105.80533519979844,
          "from_longitude": 105.8053360757854
        }
      },
      {
        "id": 2076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80538117533757,
            21.024199030773747,
            105.80626018207268,
            21.024271146892172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80538117533757,
              21.024270580047528
            ],
            [
              105.80539842218452,
              21.024271146892172
            ],
            [
              105.80626018207268,
              21.024199030773747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10_Ngách 82/23, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2083",
          "diaChiLapD": "Ngách 82/23, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024270580047528,
          "to_latitude": 21.024199030773747,
          "to_longitude": 105.80626018207268,
          "from_longitude": 105.80538117533757
        }
      },
      {
        "id": 2077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80500985553246,
            21.02364111820349,
            105.80534932002296,
            21.02364915538505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80500985553246,
              21.02364915538505
            ],
            [
              105.80534932002296,
              21.02364111820349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2084",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02364915538505,
          "to_latitude": 21.02364111820349,
          "to_longitude": 105.80534932002296,
          "from_longitude": 105.80500985553246
        }
      },
      {
        "id": 2078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8059241850668,
            21.023494008616115,
            105.80594030518726,
            21.023548059106115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80594030518726,
              21.023548059106115
            ],
            [
              105.80593151775109,
              21.023518597687683
            ],
            [
              105.8059241850668,
              21.023494008616115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2085",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023548059106115,
          "to_latitude": 21.023494008616115,
          "to_longitude": 105.8059241850668,
          "from_longitude": 105.80594030518726
        }
      },
      {
        "id": 2079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80719244808633,
            21.018557855531753,
            105.80729096156605,
            21.018610028356502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80719244808633,
              21.018610028356502
            ],
            [
              105.80723218688428,
              21.018593266439375
            ],
            [
              105.80729096156605,
              21.018557855531753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_196_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2086",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018610028356502,
          "to_latitude": 21.018557855531753,
          "to_longitude": 105.80729096156605,
          "from_longitude": 105.80719244808633
        }
      },
      {
        "id": 2080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8091322111624,
            21.021897578441326,
            105.80918899241072,
            21.02192319495743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8091322111624,
              21.02192319495743
            ],
            [
              105.80914723918933,
              21.0219164115774
            ],
            [
              105.80916169431083,
              21.021909887438376
            ],
            [
              105.80916226721963,
              21.021909629099277
            ],
            [
              105.80918899241072,
              21.021897578441326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_174_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2087",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02192319495743,
          "to_latitude": 21.021897578441326,
          "to_longitude": 105.80918899241072,
          "from_longitude": 105.8091322111624
        }
      },
      {
        "id": 2081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80178536471978,
            21.02321910852873,
            105.80181006633478,
            21.023224431326142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80181006633478,
              21.023224431326142
            ],
            [
              105.80179023030513,
              21.023220156821413
            ],
            [
              105.80178536471978,
              21.02321910852873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2088",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023224431326142,
          "to_latitude": 21.02321910852873,
          "to_longitude": 105.80178536471978,
          "from_longitude": 105.80181006633478
        }
      },
      {
        "id": 2082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80430070300214,
            21.02816924700462,
            105.80436264205788,
            21.028262687457264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80430070300214,
              21.02816924700462
            ],
            [
              105.80435218269382,
              21.02824686450021
            ],
            [
              105.80436264205788,
              21.028262687457264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2089",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02816924700462,
          "to_latitude": 21.028262687457264,
          "to_longitude": 105.80436264205788,
          "from_longitude": 105.80430070300214
        }
      },
      {
        "id": 2083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80432642752785,
            21.028155629290534,
            105.80438844419305,
            21.028249188592714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80432642752785,
              21.028155629290534
            ],
            [
              105.804379349439,
              21.028235423962236
            ],
            [
              105.80438844419305,
              21.028249188592714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2090",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028155629290534,
          "to_latitude": 21.028249188592714,
          "to_longitude": 105.80438844419305,
          "from_longitude": 105.80432642752785
        }
      },
      {
        "id": 2084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80988558290029,
            21.025720715852213,
            105.80995024489472,
            21.025753338579936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80995024489472,
              21.025720715852213
            ],
            [
              105.8098941062274,
              21.025750480822094
            ],
            [
              105.8098886077448,
              21.025752325204923
            ],
            [
              105.80988558290029,
              21.025753338579936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2091",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025720715852213,
          "to_latitude": 21.025753338579936,
          "to_longitude": 105.80988558290029,
          "from_longitude": 105.80995024489472
        }
      },
      {
        "id": 2085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80988126821472,
            21.02571463988545,
            105.80994739050529,
            21.02574744569575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80994739050529,
              21.02571463988545
            ],
            [
              105.80989065839096,
              21.02574300045177
            ],
            [
              105.8098842735331,
              21.025746023251664
            ],
            [
              105.80988126821472,
              21.02574744569575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2092",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02571463988545,
          "to_latitude": 21.02574744569575,
          "to_longitude": 105.80988126821472,
          "from_longitude": 105.80994739050529
        }
      },
      {
        "id": 2086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81014615580366,
            21.026256607905534,
            105.81016032368203,
            21.02626700068961
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81014615580366,
              21.026256607905534
            ],
            [
              105.81016006290652,
              21.026266809544456
            ],
            [
              105.81016032368203,
              21.02626700068961
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2093",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026256607905534,
          "to_latitude": 21.02626700068961,
          "to_longitude": 105.81016032368203,
          "from_longitude": 105.81014615580366
        }
      },
      {
        "id": 2087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80844372085195,
            21.026737279566603,
            105.80852365564049,
            21.026827326634127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80844372085195,
              21.026827326634127
            ],
            [
              105.80845094820555,
              21.02682460426918
            ],
            [
              105.80852365564049,
              21.026737279566603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2094",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026827326634127,
          "to_latitude": 21.026737279566603,
          "to_longitude": 105.80852365564049,
          "from_longitude": 105.80844372085195
        }
      },
      {
        "id": 2088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80896031716827,
            21.022754922038718,
            105.80898816301304,
            21.022880295856964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80896031716827,
              21.022754922038718
            ],
            [
              105.80898005712277,
              21.022844602706098
            ],
            [
              105.80898641690942,
              21.022872611368008
            ],
            [
              105.80898816301304,
              21.022880295856964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2095",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022754922038718,
          "to_latitude": 21.022880295856964,
          "to_longitude": 105.80898816301304,
          "from_longitude": 105.80896031716827
        }
      },
      {
        "id": 2089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80572668064502,
            21.023425499654312,
            105.80574307493443,
            21.023519663056785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80572668064502,
              21.023425499654312
            ],
            [
              105.80573891084761,
              21.023479814272225
            ],
            [
              105.80574307493443,
              21.023519663056785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2096",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023425499654312,
          "to_latitude": 21.023519663056785,
          "to_longitude": 105.80574307493443,
          "from_longitude": 105.80572668064502
        }
      },
      {
        "id": 2090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80917093025798,
            21.02282167854207,
            105.80921377727961,
            21.0230008350205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80921377727961,
              21.0230008350205
            ],
            [
              105.80918514107596,
              21.02287575223355
            ],
            [
              105.80918513420085,
              21.022875726072648
            ],
            [
              105.80917093025798,
              21.02282167854207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2097",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0230008350205,
          "to_latitude": 21.02282167854207,
          "to_longitude": 105.80917093025798,
          "from_longitude": 105.80921377727961
        }
      },
      {
        "id": 2091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8058093066108,
            21.027690444489032,
            105.80588090936112,
            21.027798463650363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8058093066108,
              21.027690444489032
            ],
            [
              105.80586568991092,
              21.02777550345783
            ],
            [
              105.80588090936112,
              21.027798463650363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2098",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027690444489032,
          "to_latitude": 21.027798463650363,
          "to_longitude": 105.80588090936112,
          "from_longitude": 105.8058093066108
        }
      },
      {
        "id": 2092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80584834640166,
            21.02768706115753,
            105.80591765732625,
            21.027791620960826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80584834640166,
              21.02768706115753
            ],
            [
              105.80590697500405,
              21.027775506512924
            ],
            [
              105.80591765732625,
              21.027791620960826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2099",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02768706115753,
          "to_latitude": 21.027791620960826,
          "to_longitude": 105.80591765732625,
          "from_longitude": 105.80584834640166
        }
      },
      {
        "id": 2093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80490394165345,
            21.023529049979693,
            105.80492844561832,
            21.023643539096668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80490394165345,
              21.023643539096668
            ],
            [
              105.80492812745359,
              21.023530530974185
            ],
            [
              105.80492844561832,
              21.023529049979693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_177_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2100",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023643539096668,
          "to_latitude": 21.023529049979693,
          "to_longitude": 105.80492844561832,
          "from_longitude": 105.80490394165345
        }
      },
      {
        "id": 2094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7999050468659,
            21.02323284891549,
            105.7999123099969,
            21.02324058491404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.7999050468659,
              21.02324058491404
            ],
            [
              105.79990975120904,
              21.023235574251654
            ],
            [
              105.7999123099969,
              21.02323284891549
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_154_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2101",
          "diaChiLapD": "Ngõ 1002, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02324058491404,
          "to_latitude": 21.02323284891549,
          "to_longitude": 105.7999123099969,
          "from_longitude": 105.7999050468659
        }
      },
      {
        "id": 2095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79827185438073,
            21.024499985680933,
            105.79831770571433,
            21.024510555926764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79831770571433,
              21.024510555926764
            ],
            [
              105.79831106553611,
              21.02450902541148
            ],
            [
              105.79831095380743,
              21.024508999742277
            ],
            [
              105.79829167466126,
              21.0245045388623
            ],
            [
              105.79827185438073,
              21.024499985680933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_155_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2102",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024510555926764,
          "to_latitude": 21.024499985680933,
          "to_longitude": 105.79827185438073,
          "from_longitude": 105.79831770571433
        }
      },
      {
        "id": 2096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79873461983709,
            21.026250355093154,
            105.79885146157208,
            21.026299702693976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79885146157208,
              21.026250355093154
            ],
            [
              105.79877727563574,
              21.02628168704465
            ],
            [
              105.79876057614221,
              21.026288740220195
            ],
            [
              105.79874691937133,
              21.026294507355786
            ],
            [
              105.79873461983709,
              21.026299702693976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_160_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2103",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026250355093154,
          "to_latitude": 21.026299702693976,
          "to_longitude": 105.79873461983709,
          "from_longitude": 105.79885146157208
        }
      },
      {
        "id": 2097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7995922462469,
            21.027347229287145,
            105.79971649286462,
            21.027399701353996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79971649286462,
              21.027347229287145
            ],
            [
              105.79968526780168,
              21.02736041604861
            ],
            [
              105.79967889627996,
              21.027363106924383
            ],
            [
              105.79966878612181,
              21.027367377064166
            ],
            [
              105.79961665922535,
              21.027389390909867
            ],
            [
              105.7995922462469,
              21.027399701353996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_161_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2104",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027347229287145,
          "to_latitude": 21.027399701353996,
          "to_longitude": 105.7995922462469,
          "from_longitude": 105.79971649286462
        }
      },
      {
        "id": 2098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80343312703475,
            21.028751325132237,
            105.80366813027376,
            21.029135012493526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80366813027376,
              21.029135012493526
            ],
            [
              105.80345435788402,
              21.028785943113594
            ],
            [
              105.80343362394663,
              21.028752137496465
            ],
            [
              105.80343312703475,
              21.028751325132237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_172_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2105",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029135012493526,
          "to_latitude": 21.028751325132237,
          "to_longitude": 105.80343312703475,
          "from_longitude": 105.80366813027376
        }
      },
      {
        "id": 2099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80715631622552,
            21.018474680353496,
            105.8072415683649,
            21.018519830847143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80715631622552,
              21.018519830847143
            ],
            [
              105.80718302203137,
              21.018505686919344
            ],
            [
              105.80719864685693,
              21.018497411459556
            ],
            [
              105.80720972783205,
              21.01849154298748
            ],
            [
              105.8072415683649,
              21.018474680353496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_197_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2106",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018519830847143,
          "to_latitude": 21.018474680353496,
          "to_longitude": 105.8072415683649,
          "from_longitude": 105.80715631622552
        }
      },
      {
        "id": 2100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80680481694112,
            21.01788143571842,
            105.80690445529127,
            21.017934205264773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80680481694112,
              21.017934205264773
            ],
            [
              105.80684714650913,
              21.01791178687454
            ],
            [
              105.80684920500316,
              21.01791069684924
            ],
            [
              105.80689359305097,
              21.017887188422527
            ],
            [
              105.80690445529127,
              21.01788143571842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_199_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2107",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017934205264773,
          "to_latitude": 21.01788143571842,
          "to_longitude": 105.80690445529127,
          "from_longitude": 105.80680481694112
        }
      },
      {
        "id": 2101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80744234275375,
            21.0190263917836,
            105.80756920161953,
            21.01909357911275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80744234275375,
              21.01909357911275
            ],
            [
              105.80749577656972,
              21.019065280216793
            ],
            [
              105.80754793459036,
              21.01903765665362
            ],
            [
              105.80754921132207,
              21.019036980396823
            ],
            [
              105.80756920161953,
              21.0190263917836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_194_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2108",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01909357911275,
          "to_latitude": 21.0190263917836,
          "to_longitude": 105.80756920161953,
          "from_longitude": 105.80744234275375
        }
      },
      {
        "id": 2102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80723434009325,
            21.020250639955893,
            105.80725728086516,
            21.02029340922247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80723434009325,
              21.02029340922247
            ],
            [
              105.80723845648536,
              21.02028574656594
            ],
            [
              105.80724007927238,
              21.020282697693563
            ],
            [
              105.80725341104632,
              21.020257644795397
            ],
            [
              105.80725728086516,
              21.020250639955893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_187_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2109",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02029340922247,
          "to_latitude": 21.020250639955893,
          "to_longitude": 105.80725728086516,
          "from_longitude": 105.80723434009325
        }
      },
      {
        "id": 2103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80670905086889,
            21.017797957505024,
            105.80685800120351,
            21.01784295629561
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80685800120351,
              21.01779967086138
            ],
            [
              105.80678025420731,
              21.017841548392532
            ],
            [
              105.80677744613612,
              21.01784295629561
            ],
            [
              105.8067395281236,
              21.017797957505024
            ],
            [
              105.80670905086889,
              21.017817343523667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2110",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01779967086138,
          "to_latitude": 21.017817343523667,
          "to_longitude": 105.80670905086889,
          "from_longitude": 105.80685800120351
        }
      },
      {
        "id": 2104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80570476224185,
            21.02109627986783,
            105.80574319417939,
            21.021097354307965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80574319417939,
              21.02109627986783
            ],
            [
              105.80572759614822,
              21.02109671685392
            ],
            [
              105.80570476224185,
              21.021097354307965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_191_Ngõ 14, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2111",
          "diaChiLapD": "Ngõ 14, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02109627986783,
          "to_latitude": 21.021097354307965,
          "to_longitude": 105.80570476224185,
          "from_longitude": 105.80574319417939
        }
      },
      {
        "id": 2105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80701791403257,
            21.02201458548717,
            105.80701894745324,
            21.02211329533752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80701894745324,
              21.02211329533752
            ],
            [
              105.80701791403257,
              21.02201458548717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2112",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02211329533752,
          "to_latitude": 21.02201458548717,
          "to_longitude": 105.80701791403257,
          "from_longitude": 105.80701894745324
        }
      },
      {
        "id": 2106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80758015108773,
            21.022311195329365,
            105.80759480497704,
            21.02231137052577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80759480497704,
              21.02231137052577
            ],
            [
              105.8075880081184,
              21.02231128899147
            ],
            [
              105.80758368276305,
              21.02231123817372
            ],
            [
              105.80758015108773,
              21.022311195329365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2113",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02231137052577,
          "to_latitude": 21.022311195329365,
          "to_longitude": 105.80758015108773,
          "from_longitude": 105.80759480497704
        }
      },
      {
        "id": 2107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80759009585039,
            21.022152091744754,
            105.80774186196241,
            21.0225962106174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80774186196241,
              21.022154290853017
            ],
            [
              105.80759743910384,
              21.022152091744754
            ],
            [
              105.80759487393222,
              21.022307205411987
            ],
            [
              105.80759480497704,
              21.02231137052577
            ],
            [
              105.80759009585039,
              21.0225962106174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2114",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022154290853017,
          "to_latitude": 21.0225962106174,
          "to_longitude": 105.80759009585039,
          "from_longitude": 105.80774186196241
        }
      },
      {
        "id": 2108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80462743486638,
            21.01766147665957,
            105.80464784056072,
            21.017676669106432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80464784056072,
              21.017676669106432
            ],
            [
              105.8046353342507,
              21.017667358158715
            ],
            [
              105.80463159365713,
              21.01766457313048
            ],
            [
              105.80463114939067,
              21.017664243746616
            ],
            [
              105.80462743486638,
              21.01766147665957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2115",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017676669106432,
          "to_latitude": 21.01766147665957,
          "to_longitude": 105.80462743486638,
          "from_longitude": 105.80464784056072
        }
      },
      {
        "id": 2109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8035267773132,
            21.01748006893144,
            105.80357309221364,
            21.01754063427742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80357309221364,
              21.01748006893144
            ],
            [
              105.8035267773132,
              21.01754063427742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_21_Ngõ 766, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2116",
          "diaChiLapD": "Ngõ 766, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01748006893144,
          "to_latitude": 21.01754063427742,
          "to_longitude": 105.8035267773132,
          "from_longitude": 105.80357309221364
        }
      },
      {
        "id": 2110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8045381471563,
            21.01767528391244,
            105.80459217410655,
            21.017714497711058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80459217410655,
              21.017714497711058
            ],
            [
              105.80458896504094,
              21.01771216991173
            ],
            [
              105.80455872268679,
              21.01769021814656
            ],
            [
              105.8045381471563,
              21.01767528391244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2117",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017714497711058,
          "to_latitude": 21.01767528391244,
          "to_longitude": 105.8045381471563,
          "from_longitude": 105.80459217410655
        }
      },
      {
        "id": 2111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80408335127181,
            21.018229579599602,
            105.8040936020262,
            21.018250369814083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80408335127181,
              21.018250369814083
            ],
            [
              105.80408549810367,
              21.0182460151328
            ],
            [
              105.80408799512108,
              21.018240948857894
            ],
            [
              105.80409049406217,
              21.018235882573855
            ],
            [
              105.8040936020262,
              21.018229579599602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2118",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018250369814083,
          "to_latitude": 21.018229579599602,
          "to_longitude": 105.8040936020262,
          "from_longitude": 105.80408335127181
        }
      },
      {
        "id": 2112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80522536713949,
            21.018070217447054,
            105.80561705339723,
            21.018219394237555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80522536713949,
              21.018070217447054
            ],
            [
              105.80533600315027,
              21.01814641130353
            ],
            [
              105.80544860857766,
              21.018192008804817
            ],
            [
              105.80551623703914,
              21.018219394237555
            ],
            [
              105.80552210776537,
              21.01821883714609
            ],
            [
              105.80561500293747,
              21.01821002611228
            ],
            [
              105.80561705339723,
              21.018209945046642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M78_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2119",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 143,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018070217447054,
          "to_latitude": 21.018209945046642,
          "to_longitude": 105.80561705339723,
          "from_longitude": 105.80522536713949
        }
      },
      {
        "id": 2113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80513212089684,
            21.018067553871752,
            105.8052561897557,
            21.018327338023276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80523145065266,
              21.018327338023276
            ],
            [
              105.8052561897557,
              21.018270439410895
            ],
            [
              105.80513212089684,
              21.018201053495375
            ],
            [
              105.80522536713949,
              21.018070217447054
            ],
            [
              105.80522726521032,
              21.018067553871752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M78_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2120",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018327338023276,
          "to_latitude": 21.018067553871752,
          "to_longitude": 105.80522726521032,
          "from_longitude": 105.80523145065266
        }
      },
      {
        "id": 2114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80739869672438,
            21.02014546430103,
            105.80741241009794,
            21.02016663682481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80741241009794,
              21.02016663682481
            ],
            [
              105.80741004904067,
              21.020162992665473
            ],
            [
              105.80740506208795,
              21.02015529192504
            ],
            [
              105.80739869672438,
              21.02014546430103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_169_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2121",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02016663682481,
          "to_latitude": 21.02014546430103,
          "to_longitude": 105.80739869672438,
          "from_longitude": 105.80741241009794
        }
      },
      {
        "id": 2115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80780529065922,
            21.019942918657026,
            105.80781780875245,
            21.019977203574875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80781780875245,
              21.019977203574875
            ],
            [
              105.80781389350935,
              21.01996648368305
            ],
            [
              105.80780529065922,
              21.019942918657026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_170_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2122",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019977203574875,
          "to_latitude": 21.019942918657026,
          "to_longitude": 105.80780529065922,
          "from_longitude": 105.80781780875245
        }
      },
      {
        "id": 2116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80900019096066,
            21.021838824200554,
            105.80916695146058,
            21.02188080210954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80900019096066,
              21.021878305659587
            ],
            [
              105.80909124205394,
              21.021838824200554
            ],
            [
              105.80912363144459,
              21.02188080210954
            ],
            [
              105.80916695146058,
              21.021860137004587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_177_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2123",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021878305659587,
          "to_latitude": 21.021860137004587,
          "to_longitude": 105.80916695146058,
          "from_longitude": 105.80900019096066
        }
      },
      {
        "id": 2117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80802449607013,
            21.02100383405258,
            105.80802511443942,
            21.021015047406706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80802511443942,
              21.021015047406706
            ],
            [
              105.8080250100547,
              21.021013170993726
            ],
            [
              105.80802469545681,
              21.021007452342054
            ],
            [
              105.80802449607013,
              21.02100383405258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2124",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021015047406706,
          "to_latitude": 21.02100383405258,
          "to_longitude": 105.80802449607013,
          "from_longitude": 105.80802511443942
        }
      },
      {
        "id": 2118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80058022903529,
            21.02269611363837,
            105.80059650711942,
            21.022731373761296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80058022903529,
              21.022731373761296
            ],
            [
              105.8005835422851,
              21.022724197361796
            ],
            [
              105.80059453380707,
              21.022700386170463
            ],
            [
              105.80059650711942,
              21.02269611363837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_86_Phố Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2125",
          "diaChiLapD": "Phố Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022731373761296,
          "to_latitude": 21.02269611363837,
          "to_longitude": 105.80059650711942,
          "from_longitude": 105.80058022903529
        }
      },
      {
        "id": 2119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80039155815757,
            21.023212756376736,
            105.80052532419897,
            21.02334265343126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80052532419897,
              21.023212756376736
            ],
            [
              105.80047675969166,
              21.023276668052628
            ],
            [
              105.80046121164389,
              21.0232975073787
            ],
            [
              105.80044195373989,
              21.023315566861275
            ],
            [
              105.80042713325118,
              21.02332529032738
            ],
            [
              105.80039155815757,
              21.02334265343126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_02_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2126",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023212756376736,
          "to_latitude": 21.02334265343126,
          "to_longitude": 105.80039155815757,
          "from_longitude": 105.80052532419897
        }
      },
      {
        "id": 2120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80099563814451,
            21.025634664555607,
            105.80100724969346,
            21.02563554118461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80099563814451,
              21.02563554118461
            ],
            [
              105.80099732674904,
              21.02563541400507
            ],
            [
              105.80100003082597,
              21.02563521068754
            ],
            [
              105.80100273489329,
              21.025635005563554
            ],
            [
              105.80100724969346,
              21.025634664555607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2127",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02563554118461,
          "to_latitude": 21.025634664555607,
          "to_longitude": 105.80100724969346,
          "from_longitude": 105.80099563814451
        }
      },
      {
        "id": 2121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80206570327336,
            21.028327893740766,
            105.80230713727131,
            21.028682839230274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80206570327336,
              21.028682839230274
            ],
            [
              105.8021696993275,
              21.02861837483478
            ],
            [
              105.80218332262139,
              21.028609932222917
            ],
            [
              105.8022642090175,
              21.028559812556885
            ],
            [
              105.80230713727131,
              21.028531188134714
            ],
            [
              105.80215741845404,
              21.028327893740766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 3, Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2128",
          "diaChiLapD": "Ngõ 3, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028682839230274,
          "to_latitude": 21.028327893740766,
          "to_longitude": 105.80215741845404,
          "from_longitude": 105.80206570327336
        }
      },
      {
        "id": 2122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80133468828843,
            21.029177155857134,
            105.80242981691904,
            21.029354939146533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80242981691904,
              21.029177155857134
            ],
            [
              105.80241372366362,
              21.0291846665608
            ],
            [
              105.80213477392368,
              21.029313600251754
            ],
            [
              105.80168944902863,
              21.0293385931075
            ],
            [
              105.8015113844129,
              21.029351639675976
            ],
            [
              105.80146635785772,
              21.029354939146533
            ],
            [
              105.80141448076257,
              21.029301036900147
            ],
            [
              105.80133468828843,
              21.029218127130232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2129",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.68,
          "chatLieu": "HDPE",
          "chieuDai": 124.238,
          "from_latitude": 21.029177155857134,
          "to_latitude": 21.029218127130232,
          "to_longitude": 105.80133468828843,
          "from_longitude": 105.80242981691904
        }
      },
      {
        "id": 2123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8015113844129,
            21.029351639675962,
            105.80151981182084,
            21.029436018417453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8015113844129,
              21.029351639675962
            ],
            [
              105.80151715386602,
              21.029409418215437
            ],
            [
              105.80151981182084,
              21.029436018417453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Đường Láng",
          "maTaiSan": "04.O9A.DODV.2130",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029351639675962,
          "to_latitude": 21.029436018417453,
          "to_longitude": 105.80151981182084,
          "from_longitude": 105.8015113844129
        }
      },
      {
        "id": 2124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8000117044565,
            21.027684203706254,
            105.80008335801807,
            21.027707566840053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8000117044565,
              21.027707566840053
            ],
            [
              105.80005402800093,
              21.027693766896824
            ],
            [
              105.80008335801807,
              21.027684203706254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2131",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027707566840053,
          "to_latitude": 21.027684203706254,
          "to_longitude": 105.80008335801807,
          "from_longitude": 105.8000117044565
        }
      },
      {
        "id": 2125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80005402800093,
            21.027693766896824,
            105.80005872923876,
            21.02770202919598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80005872923876,
              21.02770202919598
            ],
            [
              105.8000585171663,
              21.0277015885147
            ],
            [
              105.80005402800093,
              21.027693766896824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2132",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02770202919598,
          "to_latitude": 21.027693766896824,
          "to_longitude": 105.80005402800093,
          "from_longitude": 105.80005872923876
        }
      },
      {
        "id": 2126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8048612149133,
            21.01739922184026,
            105.80487789969877,
            21.017409563458124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80487789969877,
              21.017409563458124
            ],
            [
              105.80486976626376,
              21.01740452217742
            ],
            [
              105.8048639048294,
              21.01740089002365
            ],
            [
              105.8048612149133,
              21.01739922184026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2133",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017409563458124,
          "to_latitude": 21.01739922184026,
          "to_longitude": 105.8048612149133,
          "from_longitude": 105.80487789969877
        }
      },
      {
        "id": 2127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80457546107847,
            21.016054682979384,
            105.80518403277505,
            21.016658251021987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80457546107847,
              21.016054682979384
            ],
            [
              105.80460304839642,
              21.016082243722085
            ],
            [
              105.80462847630191,
              21.016116466055767
            ],
            [
              105.8046523285726,
              21.01615176797103
            ],
            [
              105.80469404880867,
              21.01619635908453
            ],
            [
              105.80476448573503,
              21.016265096770514
            ],
            [
              105.80483056839289,
              21.01632960623267
            ],
            [
              105.80487680023278,
              21.016374729633604
            ],
            [
              105.80493364636602,
              21.016428662578544
            ],
            [
              105.80501385905063,
              21.016504791091112
            ],
            [
              105.80513317000953,
              21.016612782916443
            ],
            [
              105.80518111119848,
              21.016655895678866
            ],
            [
              105.80518403277505,
              21.016658251021987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12-12A_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2134",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016054682979384,
          "to_latitude": 21.016658251021987,
          "to_longitude": 105.80518403277505,
          "from_longitude": 105.80457546107847
        }
      },
      {
        "id": 2128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80518150702018,
            21.016419356057458,
            105.80540877033296,
            21.016654508405946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80540877033296,
              21.016419356057458
            ],
            [
              105.80540552255098,
              21.016426475332608
            ],
            [
              105.80529140767145,
              21.01651765822599
            ],
            [
              105.80518492543666,
              21.016610940155285
            ],
            [
              105.80518150702018,
              21.016648993132574
            ],
            [
              105.80518872623097,
              21.016654508405946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2135",
          "diaChiLapD": "Ngõ 159, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016419356057458,
          "to_latitude": 21.016654508405946,
          "to_longitude": 105.80518872623097,
          "from_longitude": 105.80540877033296
        }
      },
      {
        "id": 2129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80473966289026,
            21.01756351805506,
            105.80506130476145,
            21.017786080196117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80473966289026,
              21.01756351805506
            ],
            [
              105.80493331701513,
              21.017694578125123
            ],
            [
              105.80493999801242,
              21.017699099691953
            ],
            [
              105.80506130476145,
              21.017786080196117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2136",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01756351805506,
          "to_latitude": 21.017786080196117,
          "to_longitude": 105.80506130476145,
          "from_longitude": 105.80473966289026
        }
      },
      {
        "id": 2130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80524286027716,
            21.017364190702075,
            105.80536401522377,
            21.017428392050462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80536401522377,
              21.017428392050462
            ],
            [
              105.80527811437756,
              21.017364190702075
            ],
            [
              105.80524286027716,
              21.01740262921269
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2137",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017428392050462,
          "to_latitude": 21.01740262921269,
          "to_longitude": 105.80524286027716,
          "from_longitude": 105.80536401522377
        }
      },
      {
        "id": 2131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80464545696886,
            21.02005228490603,
            105.80474085809776,
            21.020258560685043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80473835917569,
              21.02005228490603
            ],
            [
              105.80473588134507,
              21.020056804645247
            ],
            [
              105.80464545696886,
              21.02022172038012
            ],
            [
              105.80466384304408,
              21.020228875496162
            ],
            [
              105.80474085809776,
              21.020258560685043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_136_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2138",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02005228490603,
          "to_latitude": 21.020258560685043,
          "to_longitude": 105.80474085809776,
          "from_longitude": 105.80473835917569
        }
      },
      {
        "id": 2132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80473835917569,
            21.019883740950252,
            105.80492649707129,
            21.02005305873885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80473835917569,
              21.02005228490603
            ],
            [
              105.80482374493171,
              21.02005305873885
            ],
            [
              105.80491898805668,
              21.019889318697036
            ],
            [
              105.80492649707129,
              21.019883740950252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_136_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2139",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02005228490603,
          "to_latitude": 21.019883740950252,
          "to_longitude": 105.80492649707129,
          "from_longitude": 105.80473835917569
        }
      },
      {
        "id": 2133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8044731125219,
            21.020191129519795,
            105.80464545696886,
            21.020325268845255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80464545696886,
              21.02022172038012
            ],
            [
              105.80461021637902,
              21.02021029684353
            ],
            [
              105.80455730035119,
              21.020191129519795
            ],
            [
              105.80454014017465,
              21.020194795601807
            ],
            [
              105.8044962790578,
              21.020277811102346
            ],
            [
              105.8044731125219,
              21.020325268845255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_136_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2140",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02022172038012,
          "to_latitude": 21.020325268845255,
          "to_longitude": 105.8044731125219,
          "from_longitude": 105.80464545696886
        }
      },
      {
        "id": 2134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80574106839498,
            21.02068150012829,
            105.80620245270796,
            21.02074953385805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80620245270796,
              21.02074953385805
            ],
            [
              105.80598644227058,
              21.020702125309768
            ],
            [
              105.8059669913964,
              21.02069680171459
            ],
            [
              105.80575978840803,
              21.0206840055246
            ],
            [
              105.80574106839498,
              21.02068150012829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_149_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.2141",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": 48.72,
          "from_latitude": 21.02074953385805,
          "to_latitude": 21.02068150012829,
          "to_longitude": 105.80574106839498,
          "from_longitude": 105.80620245270796
        }
      },
      {
        "id": 2135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80270772745922,
            21.020623921029188,
            105.80281248960995,
            21.0208337731884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80281248960995,
              21.020623921029188
            ],
            [
              105.80279257426506,
              21.020666943710996
            ],
            [
              105.80273861047334,
              21.02073417104668
            ],
            [
              105.80276167131831,
              21.020750773764032
            ],
            [
              105.80270772745922,
              21.0208337731884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M34_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2142",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020623921029188,
          "to_latitude": 21.0208337731884,
          "to_longitude": 105.80270772745922,
          "from_longitude": 105.80281248960995
        }
      },
      {
        "id": 2136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80151117786619,
            21.022141660430762,
            105.80193037981653,
            21.02228133943638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80151117786619,
              21.022141660430762
            ],
            [
              105.80155570407697,
              21.02216249855739
            ],
            [
              105.80173699445929,
              21.022230791738384
            ],
            [
              105.80174712257813,
              21.022234993691526
            ],
            [
              105.8017614832045,
              21.022240952344937
            ],
            [
              105.8018917489723,
              21.02228133943638
            ],
            [
              105.80193037981653,
              21.022191659914167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M04_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2143",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022141660430762,
          "to_latitude": 21.022191659914167,
          "to_longitude": 105.80193037981653,
          "from_longitude": 105.80151117786619
        }
      },
      {
        "id": 2137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80442679799354,
            21.02285142391668,
            105.80443660198509,
            21.023149495471603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80443660198509,
              21.023149495471603
            ],
            [
              105.80443644989107,
              21.023140511773242
            ],
            [
              105.80443586757728,
              21.023106207184576
            ],
            [
              105.8044350755385,
              21.02305952844863
            ],
            [
              105.8044330085368,
              21.022924211954695
            ],
            [
              105.80442679799354,
              21.02285142391668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_145_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2144",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023149495471603,
          "to_latitude": 21.02285142391668,
          "to_longitude": 105.80442679799354,
          "from_longitude": 105.80443660198509
        }
      },
      {
        "id": 2138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80394884396665,
            21.022343553591213,
            105.80396527658131,
            21.022627202737542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80394884396665,
              21.022343553591213
            ],
            [
              105.80396527658131,
              21.022627202737542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_148_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2145",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022343553591213,
          "to_latitude": 21.022627202737542,
          "to_longitude": 105.80396527658131,
          "from_longitude": 105.80394884396665
        }
      },
      {
        "id": 2139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80391675464863,
            21.02205245363289,
            105.8039342846433,
            21.022315085789938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80391675464863,
              21.02205245363289
            ],
            [
              105.8039342846433,
              21.022315085789938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_150_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2146",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02205245363289,
          "to_latitude": 21.022315085789938,
          "to_longitude": 105.8039342846433,
          "from_longitude": 105.80391675464863
        }
      },
      {
        "id": 2140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8051168053052,
            21.02279642840825,
            105.80512493927527,
            21.022946087432828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80512493927527,
              21.022946087432828
            ],
            [
              105.8051168053052,
              21.02279642840825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_146_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2147",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022946087432828,
          "to_latitude": 21.02279642840825,
          "to_longitude": 105.8051168053052,
          "from_longitude": 105.80512493927527
        }
      },
      {
        "id": 2141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80512182965076,
            21.022973229726556,
            105.8051301412935,
            21.02310939366849
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8051301412935,
              21.02310939366849
            ],
            [
              105.80512182965076,
              21.022973229726556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_144_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2148",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02310939366849,
          "to_latitude": 21.022973229726556,
          "to_longitude": 105.80512182965076,
          "from_longitude": 105.8051301412935
        }
      },
      {
        "id": 2142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80589170659896,
            21.02265304168406,
            105.80623666352206,
            21.022671836053284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80623666352206,
              21.02265304168406
            ],
            [
              105.80589170659896,
              21.022671836053284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 69, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2149",
          "diaChiLapD": "Ngõ 69, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02265304168406,
          "to_latitude": 21.022671836053284,
          "to_longitude": 105.80589170659896,
          "from_longitude": 105.80623666352206
        }
      },
      {
        "id": 2143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80375149592425,
            21.02417441286207,
            105.80410257829662,
            21.024181165086347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80410257829662,
              21.024181165086347
            ],
            [
              105.80375149592425,
              21.02417441286207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_135_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2150",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024181165086347,
          "to_latitude": 21.02417441286207,
          "to_longitude": 105.80375149592425,
          "from_longitude": 105.80410257829662
        }
      },
      {
        "id": 2144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80410257829662,
            21.024000576370074,
            105.80412604445516,
            21.024181165086347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80412604445516,
              21.024000576370074
            ],
            [
              105.80410257829662,
              21.024181165086347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_135_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2151",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024000576370074,
          "to_latitude": 21.024181165086347,
          "to_longitude": 105.80410257829662,
          "from_longitude": 105.80412604445516
        }
      },
      {
        "id": 2145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.803736738906,
            21.024305380756,
            105.80374333995594,
            21.02440339331692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.803736738906,
              21.02440339331692
            ],
            [
              105.80374333995594,
              21.024305380756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2152",
          "diaChiLapD": "Ngõ 106, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02440339331692,
          "to_latitude": 21.024305380756,
          "to_longitude": 105.80374333995594,
          "from_longitude": 105.803736738906
        }
      },
      {
        "id": 2146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80734493897218,
            21.02596577019047,
            105.80735575414458,
            21.02616481651313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80734493897218,
              21.02596577019047
            ],
            [
              105.80735575414458,
              21.02616481651313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2153",
          "diaChiLapD": "Ngõ 80, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02596577019047,
          "to_latitude": 21.02616481651313,
          "to_longitude": 105.80735575414458,
          "from_longitude": 105.80734493897218
        }
      },
      {
        "id": 2147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80689315114574,
            21.022303379582205,
            105.80712394526422,
            21.022305991378712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80712394526422,
              21.022305991378712
            ],
            [
              105.80689315114574,
              21.022303379582205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2154",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022305991378712,
          "to_latitude": 21.022303379582205,
          "to_longitude": 105.80689315114574,
          "from_longitude": 105.80712394526422
        }
      },
      {
        "id": 2148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80693668458949,
            21.022111896338195,
            105.80712710061412,
            21.022115134151
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80693668458949,
              21.022111896338195
            ],
            [
              105.80701894745324,
              21.02211329533752
            ],
            [
              105.80712710061412,
              21.022115134151
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2155",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022111896338195,
          "to_latitude": 21.022115134151,
          "to_longitude": 105.80712710061412,
          "from_longitude": 105.80693668458949
        }
      },
      {
        "id": 2149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80688675749813,
            21.022204824321616,
            105.80689315114574,
            21.022303379582205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80689315114574,
              21.022303379582205
            ],
            [
              105.80688675749813,
              21.022204824321616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2156",
          "diaChiLapD": "Ngõ 33, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022303379582205,
          "to_latitude": 21.022204824321616,
          "to_longitude": 105.80688675749813,
          "from_longitude": 105.80689315114574
        }
      },
      {
        "id": 2150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80851951916979,
            21.0231461197144,
            105.80860592261195,
            21.02330299259145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80851951916979,
              21.0231461197144
            ],
            [
              105.80860592261195,
              21.02330299259145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2157",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0231461197144,
          "to_latitude": 21.02330299259145,
          "to_longitude": 105.80860592261195,
          "from_longitude": 105.80851951916979
        }
      },
      {
        "id": 2151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80780048321174,
            21.023091714350155,
            105.80794969158488,
            21.02349588401901
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80780048321174,
              21.023091714350155
            ],
            [
              105.80783000158979,
              21.02317451218709
            ],
            [
              105.80785030826456,
              21.023231473570863
            ],
            [
              105.80792139842806,
              21.0234100103164
            ],
            [
              105.80794969158488,
              21.02349588401901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2158",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023091714350155,
          "to_latitude": 21.02349588401901,
          "to_longitude": 105.80794969158488,
          "from_longitude": 105.80780048321174
        }
      },
      {
        "id": 2152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80834588812067,
            21.023191867985418,
            105.8083820678141,
            21.023327690585518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8083820678141,
              21.023327690585518
            ],
            [
              105.80834588812067,
              21.023191867985418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2159",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023327690585518,
          "to_latitude": 21.023191867985418,
          "to_longitude": 105.80834588812067,
          "from_longitude": 105.8083820678141
        }
      },
      {
        "id": 2153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80834588812067,
            21.0231461197144,
            105.80851951916979,
            21.023191867985418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80834588812067,
              21.023191867985418
            ],
            [
              105.80851951916979,
              21.0231461197144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_31_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.2160",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023191867985418,
          "to_latitude": 21.0231461197144,
          "to_longitude": 105.80851951916979,
          "from_longitude": 105.80834588812067
        }
      },
      {
        "id": 2154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8089388775537,
            21.02160610007258,
            105.80901740495807,
            21.02164014952631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8089388775537,
              21.02164014952631
            ],
            [
              105.80897386660469,
              21.021624978688237
            ],
            [
              105.80900885468635,
              21.021609807847778
            ],
            [
              105.80901740495807,
              21.02160610007258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2161",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02164014952631,
          "to_latitude": 21.02160610007258,
          "to_longitude": 105.80901740495807,
          "from_longitude": 105.8089388775537
        }
      },
      {
        "id": 2155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80674799041546,
            21.021333680900348,
            105.80706518328428,
            21.021427948134328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80706518328428,
              21.021333680900348
            ],
            [
              105.80674806963448,
              21.021337357534357
            ],
            [
              105.80674799041546,
              21.021427948134328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_158_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2162",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021333680900348,
          "to_latitude": 21.021427948134328,
          "to_longitude": 105.80674799041546,
          "from_longitude": 105.80706518328428
        }
      },
      {
        "id": 2156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81047009799785,
            21.02421000223574,
            105.81051525793288,
            21.02423064665376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81047009799785,
              21.02423064665376
            ],
            [
              105.81047466788098,
              21.024228407452078
            ],
            [
              105.8105020171093,
              21.024215001476648
            ],
            [
              105.81051525793288,
              21.02421000223574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2163",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02423064665376,
          "to_latitude": 21.02421000223574,
          "to_longitude": 105.81051525793288,
          "from_longitude": 105.81047009799785
        }
      },
      {
        "id": 2157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81039384775899,
            21.024099742100486,
            105.81054779131614,
            21.02436403268205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81039384775899,
              21.024099742100486
            ],
            [
              105.81046668685643,
              21.024224791928766
            ],
            [
              105.81046853953774,
              21.024227972397235
            ],
            [
              105.81047009799785,
              21.02423064665376
            ],
            [
              105.81047081946086,
              21.024231886057095
            ],
            [
              105.81054779131614,
              21.02436403268205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2164",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024099742100486,
          "to_latitude": 21.02436403268205,
          "to_longitude": 105.81054779131614,
          "from_longitude": 105.81039384775899
        }
      },
      {
        "id": 2158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8103844897058,
            21.024067002184044,
            105.81043188797423,
            21.024091243059193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8103844897058,
              21.024091243059193
            ],
            [
              105.81043188797423,
              21.024067002184044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2165",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024091243059193,
          "to_latitude": 21.024067002184044,
          "to_longitude": 105.81043188797423,
          "from_longitude": 105.8103844897058
        }
      },
      {
        "id": 2159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80979266403838,
            21.023770712538838,
            105.81026132000086,
            21.023992723975194
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.80979266403838,
                21.023992723975194
              ],
              [
                105.81020459411486,
                21.02379972439186
              ]
            ],
            [
              [
                105.81026132000086,
                21.023770712538838
              ],
              [
                105.8102549135179,
                21.02377398926086
              ],
              [
                105.81025199232384,
                21.02377548356808
              ],
              [
                105.81022975382105,
                21.023786857279774
              ],
              [
                105.81020459411486,
                21.02379972439186
              ],
              [
                105.81026132000086,
                21.023770712538838
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2166",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.96,
          "chatLieu": "HDPE",
          "chieuDai": 61.287,
          "from_latitude": [
            105.81020459411486,
            21.02379972439186
          ],
          "to_latitude": [
            105.8102549135179,
            21.02377398926086
          ],
          "to_longitude": [
            105.81026132000086,
            21.023770712538838
          ],
          "from_longitude": [
            105.80979266403838,
            21.023992723975194
          ]
        }
      },
      {
        "id": 2160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81029708463733,
            21.02393430852389,
            105.81035540404213,
            21.0239641336803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81029708463733,
              21.0239641336803
            ],
            [
              105.8103199313846,
              21.02395244906079
            ],
            [
              105.81034277813315,
              21.023940765341514
            ],
            [
              105.81035540404213,
              21.02393430852389
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2167",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0239641336803,
          "to_latitude": 21.02393430852389,
          "to_longitude": 105.81035540404213,
          "from_longitude": 105.81029708463733
        }
      },
      {
        "id": 2161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80936973532461,
            21.0242177771126,
            105.80943007978146,
            21.024248540114286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80936973532461,
              21.024248540114286
            ],
            [
              105.80937847122554,
              21.02424408717494
            ],
            [
              105.80943007978146,
              21.0242177771126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2168",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024248540114286,
          "to_latitude": 21.0242177771126,
          "to_longitude": 105.80943007978146,
          "from_longitude": 105.80936973532461
        }
      },
      {
        "id": 2162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81044872698045,
            21.024202259602966,
            105.81051074375678,
            21.024233977533775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81044872698045,
              21.024233977533775
            ],
            [
              105.81046668685643,
              21.024224791928766
            ],
            [
              105.810471029807,
              21.024222570969933
            ],
            [
              105.81049333263,
              21.02421116440326
            ],
            [
              105.81051074375678,
              21.024202259602966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2169",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024233977533775,
          "to_latitude": 21.024202259602966,
          "to_longitude": 105.81051074375678,
          "from_longitude": 105.81044872698045
        }
      },
      {
        "id": 2163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80961177713027,
            21.023885764514244,
            105.8096508602292,
            21.023935318321247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8096508602292,
              21.023935318321247
            ],
            [
              105.80961752690571,
              21.02389305690268
            ],
            [
              105.80961177713027,
              21.023885764514244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.2170",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023935318321247,
          "to_latitude": 21.023885764514244,
          "to_longitude": 105.80961177713027,
          "from_longitude": 105.8096508602292
        }
      },
      {
        "id": 2164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80796156012799,
            21.026485572293687,
            105.80851164011658,
            21.02683823898408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80796156012799,
              21.026485572293687
            ],
            [
              105.80817944201267,
              21.026798890997245
            ],
            [
              105.80822914088763,
              21.026817506971824
            ],
            [
              105.80837597603481,
              21.02683199320471
            ],
            [
              105.80844709320249,
              21.026835267136
            ],
            [
              105.80850578288941,
              21.026837969669568
            ],
            [
              105.80851164011658,
              21.02683823898408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.2171",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026485572293687,
          "to_latitude": 21.02683823898408,
          "to_longitude": 105.80851164011658,
          "from_longitude": 105.80796156012799
        }
      },
      {
        "id": 2165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8017473038519,
            21.027084416718154,
            105.80182488519175,
            21.027198541517333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8017473038519,
              21.027084416718154
            ],
            [
              105.80182488519175,
              21.027198541517333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2172",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027084416718154,
          "to_latitude": 21.027198541517333,
          "to_longitude": 105.80182488519175,
          "from_longitude": 105.8017473038519
        }
      },
      {
        "id": 2166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80215663561548,
            21.02552310045078,
            105.80216682955057,
            21.025743799729764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80216682955057,
              21.025743799729764
            ],
            [
              105.80215663561548,
              21.02552310045078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.2173",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025743799729764,
          "to_latitude": 21.02552310045078,
          "to_longitude": 105.80215663561548,
          "from_longitude": 105.80216682955057
        }
      },
      {
        "id": 2167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8347899575952,
            21.03023030753353,
            105.83486187739345,
            21.030296689055003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8347899575952,
              21.03023030753353
            ],
            [
              105.83485002835106,
              21.030276015555646
            ],
            [
              105.83486187739345,
              21.030296689055003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7BS'_Ngách 139A/14, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2174",
          "diaChiLapD": "Ngách 139A/14, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03023030753353,
          "to_latitude": 21.030296689055003,
          "to_longitude": 105.83486187739345,
          "from_longitude": 105.8347899575952
        }
      },
      {
        "id": 2168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483214250543,
            21.03019246130805,
            105.83487409159831,
            21.030292936706953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83483214250543,
              21.03019246130805
            ],
            [
              105.83487409159831,
              21.030292936706953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7BS'_Ngách 139A/14, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2175",
          "diaChiLapD": "Ngách 139A/14, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03019246130805,
          "to_latitude": 21.030292936706953,
          "to_longitude": 105.83487409159831,
          "from_longitude": 105.83483214250543
        }
      },
      {
        "id": 2169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439161212907,
            21.030528593117833,
            105.83478889545809,
            21.030845301629217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83445443811327,
              21.030845301629217
            ],
            [
              105.83444101714478,
              21.030805971616086
            ],
            [
              105.83443591281068,
              21.030789940973545
            ],
            [
              105.83439161212907,
              21.03065083712549
            ],
            [
              105.8346319103784,
              21.03057701873425
            ],
            [
              105.83465404625673,
              21.030568465842038
            ],
            [
              105.83472965875784,
              21.0305469809141
            ],
            [
              105.83478889545809,
              21.030528593117833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y72'_Ngõ 139Y, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2176",
          "diaChiLapD": "Ngõ 139Y, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030845301629217,
          "to_latitude": 21.030528593117833,
          "to_longitude": 105.83478889545809,
          "from_longitude": 105.83445443811327
        }
      },
      {
        "id": 2170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83161990337693,
            21.031418495804864,
            105.83167944774331,
            21.03159288977223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83167944774331,
              21.03159288977223
            ],
            [
              105.83163092426814,
              21.03145694450881
            ],
            [
              105.83161990337693,
              21.031418495804864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 169, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2177",
          "diaChiLapD": "Ngõ 169, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03159288977223,
          "to_latitude": 21.031418495804864,
          "to_longitude": 105.83161990337693,
          "from_longitude": 105.83167944774331
        }
      },
      {
        "id": 2171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83166458884605,
            21.031319977620623,
            105.83178365523975,
            21.031561401248506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178365523975,
              21.031561401248506
            ],
            [
              105.83171693532077,
              21.03134616704415
            ],
            [
              105.83168844925629,
              21.03135446701135
            ],
            [
              105.83166458884605,
              21.031319977620623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Ngõ 169B, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2178",
          "diaChiLapD": "Ngõ 169B, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031561401248506,
          "to_latitude": 21.031319977620623,
          "to_longitude": 105.83166458884605,
          "from_longitude": 105.83178365523975
        }
      },
      {
        "id": 2172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83175127198089,
            21.031339507608656,
            105.8318157772511,
            21.0315512607715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318157772511,
              21.0315512607715
            ],
            [
              105.83176949908781,
              21.031401625246502
            ],
            [
              105.83175127198089,
              21.031339507608656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Ngõ 169A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2179",
          "diaChiLapD": "Ngõ 169A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0315512607715,
          "to_latitude": 21.031339507608656,
          "to_longitude": 105.83175127198089,
          "from_longitude": 105.8318157772511
        }
      },
      {
        "id": 2173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318049226338,
            21.031290922692015,
            105.83190824133221,
            21.03152207142559
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190824133221,
              21.03152207142559
            ],
            [
              105.83183503136573,
              21.031296440408102
            ],
            [
              105.8318049226338,
              21.031290922692015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Ngõ 167, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2180",
          "diaChiLapD": "Ngõ 167, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03152207142559,
          "to_latitude": 21.031290922692015,
          "to_longitude": 105.8318049226338,
          "from_longitude": 105.83190824133221
        }
      },
      {
        "id": 2174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188443269746,
            21.031323084058126,
            105.83194417497715,
            21.0315107272458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83194417497715,
              21.0315107272458
            ],
            [
              105.83188443269746,
              21.031323084058126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Ngõ 165, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2181",
          "diaChiLapD": "Ngõ 165, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0315107272458,
          "to_latitude": 21.031323084058126,
          "to_longitude": 105.83188443269746,
          "from_longitude": 105.83194417497715
        }
      },
      {
        "id": 2175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351193623542,
            21.030167773907912,
            105.83534066791701,
            21.030324890833977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351193623542,
              21.030167773907912
            ],
            [
              105.83517355209412,
              21.030208612520294
            ],
            [
              105.83520632573489,
              21.030243342138352
            ],
            [
              105.8352451129086,
              21.03030118007183
            ],
            [
              105.83525401641636,
              21.030314457067163
            ],
            [
              105.8352600833726,
              21.030323503751557
            ],
            [
              105.83532042845334,
              21.030299297186374
            ],
            [
              105.83534066791701,
              21.030324890833977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2182",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030167773907912,
          "to_latitude": 21.030324890833977,
          "to_longitude": 105.83534066791701,
          "from_longitude": 105.8351193623542
        }
      },
      {
        "id": 2176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520910291024,
            21.030284911075302,
            105.83525064029523,
            21.030388278708017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83525064029523,
              21.030388278708017
            ],
            [
              105.83524575953663,
              21.030377163235265
            ],
            [
              105.83523854315203,
              21.030360729316957
            ],
            [
              105.83523023822926,
              21.030332124396818
            ],
            [
              105.83520910291024,
              21.030284911075302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7BS_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2183",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030388278708017,
          "to_latitude": 21.030284911075302,
          "to_longitude": 105.83520910291024,
          "from_longitude": 105.83525064029523
        }
      },
      {
        "id": 2177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504445697747,
            21.030388278708017,
            105.83525064029523,
            21.030452717544836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504445697747,
              21.030452717544836
            ],
            [
              105.83525064029523,
              21.030388278708017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7BS_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2184",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030452717544836,
          "to_latitude": 21.030388278708017,
          "to_longitude": 105.83525064029523,
          "from_longitude": 105.83504445697747
        }
      },
      {
        "id": 2178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83238111475274,
            21.03106128207875,
            105.83260462496689,
            21.031112317527956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83260462496689,
              21.03106474961988
            ],
            [
              105.83257282049412,
              21.031077297583746
            ],
            [
              105.83254081694209,
              21.03106128207875
            ],
            [
              105.83246546398908,
              21.03108734231319
            ],
            [
              105.83238111475274,
              21.031112317527956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2185",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03106474961988,
          "to_latitude": 21.031112317527956,
          "to_longitude": 105.83238111475274,
          "from_longitude": 105.83260462496689
        }
      },
      {
        "id": 2179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83085454340358,
            21.031861249391312,
            105.83086645272749,
            21.03190727275463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83086645272749,
              21.03190727275463
            ],
            [
              105.83086234681056,
              21.03189132202094
            ],
            [
              105.83085454340358,
              21.031861249391312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2186",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03190727275463,
          "to_latitude": 21.031861249391312,
          "to_longitude": 105.83085454340358,
          "from_longitude": 105.83086645272749
        }
      },
      {
        "id": 2180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387751111411,
            21.029358247326865,
            105.83405412163928,
            21.0295979293458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83402907324081,
              21.029358247326865
            ],
            [
              105.83405412163928,
              21.02955086869077
            ],
            [
              105.83387751111411,
              21.0295979293458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y4_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2187",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029358247326865,
          "to_latitude": 21.0295979293458,
          "to_longitude": 105.83387751111411,
          "from_longitude": 105.83402907324081
        }
      },
      {
        "id": 2181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466003170024,
            21.029442567988532,
            105.83484848433157,
            21.02954336110885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83484848433157,
              21.02954336110885
            ],
            [
              105.83469837919306,
              21.029472490498627
            ],
            [
              105.83470290833027,
              21.029463147906046
            ],
            [
              105.83466003170024,
              21.029442567988532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X31_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2188",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02954336110885,
          "to_latitude": 21.029442567988532,
          "to_longitude": 105.83466003170024,
          "from_longitude": 105.83484848433157
        }
      },
      {
        "id": 2182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462343467195,
            21.029341905472275,
            105.83489230853903,
            21.029470146541904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489230853903,
              21.029470146541904
            ],
            [
              105.83477270781952,
              21.029411107469762
            ],
            [
              105.83462343467195,
              21.029341905472275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X21_Ngõ 38, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2189",
          "diaChiLapD": "Ngõ 38, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029470146541904,
          "to_latitude": 21.029341905472275,
          "to_longitude": 105.83462343467195,
          "from_longitude": 105.83489230853903
        }
      },
      {
        "id": 2183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83480360901227,
            21.029223865737542,
            105.83497262845694,
            21.029336901581136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83497262845694,
              21.029336901581136
            ],
            [
              105.83483283840357,
              21.02926207760438
            ],
            [
              105.83483746397216,
              21.029245351589122
            ],
            [
              105.83480360901227,
              21.029223865737542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X21_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2190",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029336901581136,
          "to_latitude": 21.029223865737542,
          "to_longitude": 105.83480360901227,
          "from_longitude": 105.83497262845694
        }
      },
      {
        "id": 2184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83393331073637,
            21.029988616350774,
            105.83448857236833,
            21.030169592805926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448857236833,
              21.030169592805926
            ],
            [
              105.8342710698938,
              21.030039793930868
            ],
            [
              105.83421584009528,
              21.030006833520186
            ],
            [
              105.8341845359694,
              21.03004163022466
            ],
            [
              105.83412696288966,
              21.030019942667128
            ],
            [
              105.83405806409573,
              21.029988616350774
            ],
            [
              105.83403261862922,
              21.030003865637006
            ],
            [
              105.83397212665079,
              21.03004249423359
            ],
            [
              105.83393331073637,
              21.030039908102793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X3_Ngõ 16, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2191",
          "diaChiLapD": "Ngõ 16, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030169592805926,
          "to_latitude": 21.030039908102793,
          "to_longitude": 105.83393331073637,
          "from_longitude": 105.83448857236833
        }
      },
      {
        "id": 2185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82911940834687,
            21.030567676372705,
            105.82915161514225,
            21.03060520080026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82911940834687,
              21.030567676372705
            ],
            [
              105.82915161514225,
              21.030590775530566
            ],
            [
              105.82913885949145,
              21.03060520080026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2BS_Ngõ 59, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2192",
          "diaChiLapD": "Ngõ 59, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030567676372705,
          "to_latitude": 21.03060520080026,
          "to_longitude": 105.82913885949145,
          "from_longitude": 105.82911940834687
        }
      },
      {
        "id": 2186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82921186431005,
            21.030446430139563,
            105.82942482612383,
            21.030610797925195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82921186431005,
              21.030446430139563
            ],
            [
              105.82922054714756,
              21.030453126748785
            ],
            [
              105.82924521705915,
              21.03047217104117
            ],
            [
              105.82925521390096,
              21.030479886399274
            ],
            [
              105.82942482612383,
              21.030610797925195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Ngách 59/9, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2193",
          "diaChiLapD": "Ngách 59/9, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030446430139563,
          "to_latitude": 21.030610797925195,
          "to_longitude": 105.82942482612383,
          "from_longitude": 105.82921186431005
        }
      },
      {
        "id": 2187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83183093336517,
            21.031173861959175,
            105.83198828942889,
            21.03122005136744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198828942889,
              21.031173861959175
            ],
            [
              105.83183093336517,
              21.03122005136744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 2, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2194",
          "diaChiLapD": "Ngõ 2, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031173861959175,
          "to_latitude": 21.03122005136744,
          "to_longitude": 105.83183093336517,
          "from_longitude": 105.83198828942889
        }
      },
      {
        "id": 2188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169967149152,
            21.03091160197081,
            105.83188861361705,
            21.030967017765402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188861361705,
              21.03091160197081
            ],
            [
              105.83186812257186,
              21.030918069950378
            ],
            [
              105.83176691043847,
              21.030947666963527
            ],
            [
              105.83174392982349,
              21.030949835118278
            ],
            [
              105.83169967149152,
              21.030967017765402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Ngõ 10, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2195",
          "diaChiLapD": "Ngõ 10, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03091160197081,
          "to_latitude": 21.030967017765402,
          "to_longitude": 105.83169967149152,
          "from_longitude": 105.83188861361705
        }
      },
      {
        "id": 2189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83149820079643,
            21.03055957390844,
            105.83178086011858,
            21.03069995446147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178086011858,
              21.03055957390844
            ],
            [
              105.83149820079643,
              21.030636118175323
            ],
            [
              105.83151481140045,
              21.03069995446147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 16, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2196",
          "diaChiLapD": "Ngõ 16, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03055957390844,
          "to_latitude": 21.03069995446147,
          "to_longitude": 105.83151481140045,
          "from_longitude": 105.83178086011858
        }
      },
      {
        "id": 2190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83167391937523,
            21.030843956794406,
            105.83187145737821,
            21.03090974387794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83187145737821,
              21.030843956794406
            ],
            [
              105.83174883388432,
              21.030878516086016
            ],
            [
              105.83172786504535,
              21.03089562003618
            ],
            [
              105.83167391937523,
              21.03090974387794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 12, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2197",
          "diaChiLapD": "Ngõ 12, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030843956794406,
          "to_latitude": 21.03090974387794,
          "to_longitude": 105.83167391937523,
          "from_longitude": 105.83187145737821
        }
      },
      {
        "id": 2191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83158687606863,
            21.030768163806535,
            105.83184817395272,
            21.03083444335796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184817395272,
              21.030768163806535
            ],
            [
              105.83170774386375,
              21.030805606562954
            ],
            [
              105.83158687606863,
              21.03083444335796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 14, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2198",
          "diaChiLapD": "Ngõ 14, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030768163806535,
          "to_latitude": 21.03083444335796,
          "to_longitude": 105.83158687606863,
          "from_longitude": 105.83184817395272
        }
      },
      {
        "id": 2192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202437526221,
            21.030997068692294,
            105.83217413149235,
            21.031111446058322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202437526221,
              21.031111446058322
            ],
            [
              105.83208157721357,
              21.0310932922441
            ],
            [
              105.8321559308967,
              21.03106969564263
            ],
            [
              105.8321431862518,
              21.03103615539734
            ],
            [
              105.83214410605208,
              21.031018152362897
            ],
            [
              105.83215297239843,
              21.031006583838636
            ],
            [
              105.83217413149235,
              21.030997068692294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2199",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031111446058322,
          "to_latitude": 21.030997068692294,
          "to_longitude": 105.83217413149235,
          "from_longitude": 105.83202437526221
        }
      },
      {
        "id": 2193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83196077431359,
            21.030860528776127,
            105.83231742383953,
            21.031014915002668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83196077431359,
              21.03092137222735
            ],
            [
              105.83202968896319,
              21.03089974964571
            ],
            [
              105.83215133968069,
              21.030866211040212
            ],
            [
              105.83215736029362,
              21.03087889534698
            ],
            [
              105.83222111433946,
              21.030860528776127
            ],
            [
              105.83222864251773,
              21.030884830186533
            ],
            [
              105.83226664163857,
              21.030877153758684
            ],
            [
              105.83231742383953,
              21.031014915002668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2200",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03092137222735,
          "to_latitude": 21.031014915002668,
          "to_longitude": 105.83231742383953,
          "from_longitude": 105.83196077431359
        }
      },
      {
        "id": 2194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202968896319,
            21.03089974964571,
            105.83207650890131,
            21.031084919779346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202968896319,
              21.03089974964571
            ],
            [
              105.83206039736093,
              21.03100255148817
            ],
            [
              105.83205163006674,
              21.031005103546878
            ],
            [
              105.83207650890131,
              21.031084919779346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2201",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03089974964571,
          "to_latitude": 21.031084919779346,
          "to_longitude": 105.83207650890131,
          "from_longitude": 105.83202968896319
        }
      },
      {
        "id": 2195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190467291506,
            21.03066390590171,
            105.83212209649582,
            21.030751288845074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190467291506,
              21.030751288845074
            ],
            [
              105.83198675538414,
              21.030726641992366
            ],
            [
              105.83197959994284,
              21.03069859669081
            ],
            [
              105.83211097315983,
              21.03066390590171
            ],
            [
              105.83212209649582,
              21.030686833082147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2202",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030751288845074,
          "to_latitude": 21.030686833082147,
          "to_longitude": 105.83212209649582,
          "from_longitude": 105.83190467291506
        }
      },
      {
        "id": 2196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187578813576,
            21.03058877807589,
            105.8320688294664,
            21.030650119202026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83187578813576,
              21.030650119202026
            ],
            [
              105.83200672559055,
              21.03060140085562
            ],
            [
              105.83204110496183,
              21.03058877807589
            ],
            [
              105.8320688294664,
              21.030595823934082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2203",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030650119202026,
          "to_latitude": 21.030595823934082,
          "to_longitude": 105.8320688294664,
          "from_longitude": 105.83187578813576
        }
      },
      {
        "id": 2197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83182607876782,
            21.030480956246155,
            105.83197252726066,
            21.03051593112835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83182607876782,
              21.03051593112835
            ],
            [
              105.83189750335836,
              21.030490524463545
            ],
            [
              105.83191533937419,
              21.03049900517595
            ],
            [
              105.8319549948196,
              21.030486436986386
            ],
            [
              105.83197252726066,
              21.030480956246155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2204",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03051593112835,
          "to_latitude": 21.030480956246155,
          "to_longitude": 105.83197252726066,
          "from_longitude": 105.83182607876782
        }
      },
      {
        "id": 2198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410420947412,
            21.028602846900665,
            105.8342659522092,
            21.02868976180423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83410420947412,
              21.02868976180423
            ],
            [
              105.8342659522092,
              21.028602846900665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X9_Ngõ 14, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2205",
          "diaChiLapD": "Ngõ 14, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02868976180423,
          "to_latitude": 21.028602846900665,
          "to_longitude": 105.8342659522092,
          "from_longitude": 105.83410420947412
        }
      },
      {
        "id": 2199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83419127475777,
            21.028741646005138,
            105.83435047345382,
            21.028827276771697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83419127475777,
              21.028827276771697
            ],
            [
              105.83429595924599,
              21.028770968368175
            ],
            [
              105.83435047345382,
              21.028741646005138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z6_Ngõ 18, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2206",
          "diaChiLapD": "Ngõ 18, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028827276771697,
          "to_latitude": 21.028741646005138,
          "to_longitude": 105.83435047345382,
          "from_longitude": 105.83419127475777
        }
      },
      {
        "id": 2200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551907095577,
            21.029942926111456,
            105.83559100996311,
            21.029995307972367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559100996311,
              21.029942926111456
            ],
            [
              105.83558629687866,
              21.02994635803593
            ],
            [
              105.83558176967209,
              21.02994965446663
            ],
            [
              105.83551907095577,
              21.029995307972367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z11'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2207",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029942926111456,
          "to_latitude": 21.029995307972367,
          "to_longitude": 105.83551907095577,
          "from_longitude": 105.83559100996311
        }
      },
      {
        "id": 2201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83544323566441,
            21.029932740408757,
            105.83553350696356,
            21.030108248608613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83544323566441,
              21.029932740408757
            ],
            [
              105.83547759824373,
              21.029978456198126
            ],
            [
              105.83549788101774,
              21.029964017871784
            ],
            [
              105.83551907095577,
              21.029995307972367
            ],
            [
              105.83553350696356,
              21.03002754441669
            ],
            [
              105.8354816517043,
              21.030066379485536
            ],
            [
              105.83552057502993,
              21.030108248608613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z11'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2208",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029932740408757,
          "to_latitude": 21.030108248608613,
          "to_longitude": 105.83552057502993,
          "from_longitude": 105.83544323566441
        }
      },
      {
        "id": 2202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82936615878948,
            21.030639130602594,
            105.82973058067219,
            21.031056791675002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82936615878948,
              21.031056791675002
            ],
            [
              105.8293823621129,
              21.031039186481056
            ],
            [
              105.82939522628565,
              21.031011513079456
            ],
            [
              105.82967518097078,
              21.030700647645432
            ],
            [
              105.82973058067219,
              21.030639130602594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4_Ngõ 45, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2209",
          "diaChiLapD": "Ngõ 45, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031056791675002,
          "to_latitude": 21.030639130602594,
          "to_longitude": 105.82973058067219,
          "from_longitude": 105.82936615878948
        }
      },
      {
        "id": 2203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82937232948642,
            21.030649050074643,
            105.82973996539178,
            21.031061520661858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937232948642,
              21.031061520661858
            ],
            [
              105.82938742484009,
              21.031043099841472
            ],
            [
              105.82941312411984,
              21.031023528069632
            ],
            [
              105.82973996539178,
              21.030649050074643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5_Ngõ 45, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2210",
          "diaChiLapD": "Ngõ 45, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031061520661858,
          "to_latitude": 21.030649050074643,
          "to_longitude": 105.82973996539178,
          "from_longitude": 105.82937232948642
        }
      },
      {
        "id": 2204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411517309368,
            21.030140407376944,
            105.83439579400029,
            21.030325602103375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439579400029,
              21.030325602103375
            ],
            [
              105.83411517309368,
              21.030140407376944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X3_Ngõ 10, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2211",
          "diaChiLapD": "Ngõ 10, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030325602103375,
          "to_latitude": 21.030140407376944,
          "to_longitude": 105.83411517309368,
          "from_longitude": 105.83439579400029
        }
      },
      {
        "id": 2205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8321624893044,
            21.031240707194378,
            105.83232550694757,
            21.03150014482404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83230858792241,
              21.031240707194378
            ],
            [
              105.83227610125981,
              21.03125671236981
            ],
            [
              105.83228535881527,
              21.031330141564048
            ],
            [
              105.83218790190578,
              21.031370064163898
            ],
            [
              105.8321624893044,
              21.031406383413596
            ],
            [
              105.83219422425749,
              21.031424437071614
            ],
            [
              105.83229759284441,
              21.031401476966533
            ],
            [
              105.83232550694757,
              21.03150014482404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2212",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031240707194378,
          "to_latitude": 21.03150014482404,
          "to_longitude": 105.83232550694757,
          "from_longitude": 105.83230858792241
        }
      },
      {
        "id": 2206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83369499965576,
            21.030667213094578,
            105.83378413397953,
            21.03097794597971
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377210771988,
              21.030667213094578
            ],
            [
              105.83369499965576,
              21.03069537975425
            ],
            [
              105.83378413397953,
              21.03097794597971
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z7_Ngõ 143, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2213",
          "diaChiLapD": "Ngõ 143, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030667213094578,
          "to_latitude": 21.03097794597971,
          "to_longitude": 105.83378413397953,
          "from_longitude": 105.83377210771988
        }
      },
      {
        "id": 2207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363136532053,
            21.030783822854524,
            105.833701078916,
            21.031003255107322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833701078916,
              21.031003255107322
            ],
            [
              105.83363136532053,
              21.030783822854524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z7_Ngõ 145, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2214",
          "diaChiLapD": "Ngõ 145, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031003255107322,
          "to_latitude": 21.030783822854524,
          "to_longitude": 105.83363136532053,
          "from_longitude": 105.833701078916
        }
      },
      {
        "id": 2208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83324735651068,
            21.030876163398432,
            105.83329881531496,
            21.03113285249788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83329881531496,
              21.03113285249788
            ],
            [
              105.83324735651068,
              21.030954542878575
            ],
            [
              105.83327124447499,
              21.030947734732873
            ],
            [
              105.83325464204245,
              21.030876163398432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z8_Ngõ 149, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2215",
          "diaChiLapD": "Ngõ 149, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03113285249788,
          "to_latitude": 21.030876163398432,
          "to_longitude": 105.83325464204245,
          "from_longitude": 105.83329881531496
        }
      },
      {
        "id": 2209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83289058909403,
            21.031026816374464,
            105.83297657772457,
            21.031230193910794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83297657772457,
              21.031230193910794
            ],
            [
              105.83295192394509,
              21.031166555258054
            ],
            [
              105.83293846323319,
              21.03117194028257
            ],
            [
              105.83289058909403,
              21.031026816374464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z8_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2216",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031230193910794,
          "to_latitude": 21.031026816374464,
          "to_longitude": 105.83289058909403,
          "from_longitude": 105.83297657772457
        }
      },
      {
        "id": 2210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299592704977,
            21.03097365055052,
            105.83306049577091,
            21.031204844773594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83306049577091,
              21.031204844773594
            ],
            [
              105.83303005933823,
              21.031104025280648
            ],
            [
              105.83304402958458,
              21.031098579946374
            ],
            [
              105.83299592704977,
              21.03097365055052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z8_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2217",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031204844773594,
          "to_latitude": 21.03097365055052,
          "to_longitude": 105.83299592704977,
          "from_longitude": 105.83306049577091
        }
      },
      {
        "id": 2211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83301954246002,
            21.030939469463018,
            105.8331773092553,
            21.03116955701095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331773092553,
              21.03116955701095
            ],
            [
              105.83309500755806,
              21.030939469463018
            ],
            [
              105.83301954246002,
              21.03096349733291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z8_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2218",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03116955701095,
          "to_latitude": 21.03096349733291,
          "to_longitude": 105.83301954246002,
          "from_longitude": 105.8331773092553
        }
      },
      {
        "id": 2212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315153111324,
            21.030904312228017,
            105.83320627083172,
            21.0311608082749
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83320627083172,
              21.0311608082749
            ],
            [
              105.83315153111324,
              21.030947282673218
            ],
            [
              105.8331657865337,
              21.030933435052656
            ],
            [
              105.83315900679987,
              21.030904312228017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z8_Ngõ 151, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2219",
          "diaChiLapD": "Ngõ 151, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0311608082749,
          "to_latitude": 21.030904312228017,
          "to_longitude": 105.83315900679987,
          "from_longitude": 105.83320627083172
        }
      },
      {
        "id": 2213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83102508025914,
            21.029596015749743,
            105.83123325002249,
            21.029652297013186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83102508025914,
              21.029652297013186
            ],
            [
              105.83111439853938,
              21.029628149032924
            ],
            [
              105.83123325002249,
              21.029596015749743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5BS_Ngách 20/34, Cát Linh",
          "maTaiSan": "04.O13.DODV.2220",
          "diaChiLapD": "Ngách 20/34, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029652297013186,
          "to_latitude": 21.029596015749743,
          "to_longitude": 105.83123325002249,
          "from_longitude": 105.83102508025914
        }
      },
      {
        "id": 2214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83045600205881,
            21.029078990214494,
            105.83060314384291,
            21.02939035777631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8305211183364,
              21.029078990214494
            ],
            [
              105.83055369729368,
              21.02924410299122
            ],
            [
              105.83059060767133,
              21.02929052363081
            ],
            [
              105.83060314384291,
              21.029347817109052
            ],
            [
              105.83045600205881,
              21.02939035777631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Cát Linh",
          "maTaiSan": "04.O13.DODV.2221",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029078990214494,
          "to_latitude": 21.02939035777631,
          "to_longitude": 105.83045600205881,
          "from_longitude": 105.8305211183364
        }
      },
      {
        "id": 2215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83525593957482,
            21.029748716530637,
            105.83547234734803,
            21.029910167730034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83547234734803,
              21.029748716530637
            ],
            [
              105.8354509444197,
              21.02976239623064
            ],
            [
              105.83527062385664,
              21.02987458538468
            ],
            [
              105.8352792248693,
              21.02988900012047
            ],
            [
              105.83525593957482,
              21.029910167730034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z1'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2222",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029748716530637,
          "to_latitude": 21.029910167730034,
          "to_longitude": 105.83525593957482,
          "from_longitude": 105.83547234734803
        }
      },
      {
        "id": 2216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528097240826,
            21.029686985092624,
            105.83540961197184,
            21.029808946193167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540961197184,
              21.029686985092624
            ],
            [
              105.83528097240826,
              21.02977308363911
            ],
            [
              105.83531110863657,
              21.029808946193167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2223",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029686985092624,
          "to_latitude": 21.029808946193167,
          "to_longitude": 105.83531110863657,
          "from_longitude": 105.83540961197184
        }
      },
      {
        "id": 2217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414720188429,
            21.03035646832559,
            105.83430423593455,
            21.030457529953615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430423593455,
              21.030457529953615
            ],
            [
              105.83414720188429,
              21.03035646832559
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X32_Ngõ 6, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2224",
          "diaChiLapD": "Ngõ 6, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030457529953615,
          "to_latitude": 21.03035646832559,
          "to_longitude": 105.83414720188429,
          "from_longitude": 105.83430423593455
        }
      },
      {
        "id": 2218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405412163928,
            21.02955086869077,
            105.83414063379217,
            21.029586898347702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405412163928,
              21.02955086869077
            ],
            [
              105.83406598747906,
              21.029571306428423
            ],
            [
              105.8340858735407,
              21.02958099078973
            ],
            [
              105.83409756040162,
              21.029586898347702
            ],
            [
              105.83414063379217,
              21.029576804885505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y4_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2225",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02955086869077,
          "to_latitude": 21.029576804885505,
          "to_longitude": 105.83414063379217,
          "from_longitude": 105.83405412163928
        }
      },
      {
        "id": 2219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83395751864836,
            21.02939504966406,
            105.83399318377471,
            21.02953197860981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83395751864836,
              21.02939504966406
            ],
            [
              105.83399318377471,
              21.02953197860981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y4_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2226",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02939504966406,
          "to_latitude": 21.02953197860981,
          "to_longitude": 105.83399318377471,
          "from_longitude": 105.83395751864836
        }
      },
      {
        "id": 2220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368238206877,
            21.029274097902693,
            105.8337969870441,
            21.029331647376573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337969870441,
              21.029318193135584
            ],
            [
              105.83374925389155,
              21.02932538923683
            ],
            [
              105.83372057707474,
              21.029331647376573
            ],
            [
              105.83370102701721,
              21.029294844752826
            ],
            [
              105.83369082279943,
              21.029290689376012
            ],
            [
              105.83368238206877,
              21.029274097902693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y5_Ngõ 31, Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2227",
          "diaChiLapD": "Ngõ 31, Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029318193135584,
          "to_latitude": 21.029274097902693,
          "to_longitude": 105.83368238206877,
          "from_longitude": 105.8337969870441
        }
      },
      {
        "id": 2221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337865218356,
            21.029252939600784,
            105.83387408817204,
            21.02939456463947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387408817204,
              21.02939456463947
            ],
            [
              105.83385256085553,
              21.02935235600443
            ],
            [
              105.83381042440148,
              21.029358450744812
            ],
            [
              105.8337969870441,
              21.029318193135584
            ],
            [
              105.8337865218356,
              21.029252939600784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y5_Ngõ 31, Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2228",
          "diaChiLapD": "Ngõ 31, Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02939456463947,
          "to_latitude": 21.029252939600784,
          "to_longitude": 105.8337865218356,
          "from_longitude": 105.83387408817204
        }
      },
      {
        "id": 2222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83348739544148,
            21.028622651612377,
            105.83361625343527,
            21.028647690648754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83361625343527,
              21.028622651612377
            ],
            [
              105.83352581077484,
              21.028647690648754
            ],
            [
              105.83348739544148,
              21.028625730105652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X7BS_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2229",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028622651612377,
          "to_latitude": 21.028625730105652,
          "to_longitude": 105.83348739544148,
          "from_longitude": 105.83361625343527
        }
      },
      {
        "id": 2223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355239427057,
            21.028328673150288,
            105.8336577102024,
            21.028366570240728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336577102024,
              21.028328673150288
            ],
            [
              105.83365017920526,
              21.028331383612624
            ],
            [
              105.83355239427057,
              21.028366570240728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X7_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2230",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028328673150288,
          "to_latitude": 21.028366570240728,
          "to_longitude": 105.83355239427057,
          "from_longitude": 105.8336577102024
        }
      },
      {
        "id": 2224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326079987232,
            21.028817810368608,
            105.83332248499045,
            21.028828312758858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83332248499045,
              21.028817810368608
            ],
            [
              105.83326079987232,
              21.028828312758858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6A_Ngõ 12, Cát Linh",
          "maTaiSan": "04.O13.DODV.2231",
          "diaChiLapD": "Ngõ 12, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028817810368608,
          "to_latitude": 21.028828312758858,
          "to_longitude": 105.83326079987232,
          "from_longitude": 105.83332248499045
        }
      },
      {
        "id": 2225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438528659539,
            21.028854068284364,
            105.83448842829249,
            21.029011228788352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448842829249,
              21.029011228788352
            ],
            [
              105.83448829731168,
              21.029011029818825
            ],
            [
              105.83438528659539,
              21.028854068284364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z6_Ngõ 9, Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2232",
          "diaChiLapD": "Ngõ 9, Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029011228788352,
          "to_latitude": 21.028854068284364,
          "to_longitude": 105.83438528659539,
          "from_longitude": 105.83448842829249
        }
      },
      {
        "id": 2226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346120187795,
            21.03042533498471,
            105.83465404625673,
            21.030568465842038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465404625673,
              21.030568465842038
            ],
            [
              105.83463825364296,
              21.030514068177542
            ],
            [
              105.8346120187795,
              21.030494674692992
            ],
            [
              105.83462143960577,
              21.03042533498471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y72'_Ngõ 139Y, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2233",
          "diaChiLapD": "Ngõ 139Y, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030568465842038,
          "to_latitude": 21.03042533498471,
          "to_longitude": 105.83462143960577,
          "from_longitude": 105.83465404625673
        }
      },
      {
        "id": 2227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440332616883,
            21.029876899897737,
            105.83459432609754,
            21.029995216860232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459432609754,
              21.029995216860232
            ],
            [
              105.83440332616883,
              21.029876899897737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X3_Ngõ 24, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2234",
          "diaChiLapD": "Ngõ 24, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029995216860232,
          "to_latitude": 21.029876899897737,
          "to_longitude": 105.83440332616883,
          "from_longitude": 105.83459432609754
        }
      },
      {
        "id": 2228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208157721357,
            21.0310932922441,
            105.83219997623705,
            21.031160771835083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83208157721357,
              21.0310932922441
            ],
            [
              105.83210340238647,
              21.031160771835083
            ],
            [
              105.83219997623705,
              21.031133533057968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2235",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0310932922441,
          "to_latitude": 21.031133533057968,
          "to_longitude": 105.83219997623705,
          "from_longitude": 105.83208157721357
        }
      },
      {
        "id": 2229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326599296079,
            21.028543433724238,
            105.83339471862045,
            21.02856736502279
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83326599296079,
              21.02856736502279
            ],
            [
              105.83339471862045,
              21.028543433724238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6A_Ngõ 12, Cát Linh",
          "maTaiSan": "04.O13.DODV.2236",
          "diaChiLapD": "Ngõ 12, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02856736502279,
          "to_latitude": 21.028543433724238,
          "to_longitude": 105.83339471862045,
          "from_longitude": 105.83326599296079
        }
      },
      {
        "id": 2230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318500033005,
            21.028249225673704,
            105.83319233520734,
            21.0282678259451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318500033005,
              21.028249225673704
            ],
            [
              105.83318915478722,
              21.02825969354923
            ],
            [
              105.83319233520734,
              21.0282678259451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6_Cát Linh",
          "maTaiSan": "04.O13.DODV.2237",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028249225673704,
          "to_latitude": 21.0282678259451,
          "to_longitude": 105.83319233520734,
          "from_longitude": 105.83318500033005
        }
      },
      {
        "id": 2231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83242234497324,
            21.0282678259451,
            105.83319233520734,
            21.02857710878797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83242234497324,
              21.02857710878797
            ],
            [
              105.83303155495291,
              21.0283271813858
            ],
            [
              105.83314131825702,
              21.02828666063803
            ],
            [
              105.83315884969352,
              21.028280188048107
            ],
            [
              105.83316016111876,
              21.028279703817375
            ],
            [
              105.83317152328956,
              21.028275509576495
            ],
            [
              105.83319233520734,
              21.0282678259451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6_Cát Linh",
          "maTaiSan": "04.O13.DODV.2238",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02857710878797,
          "to_latitude": 21.0282678259451,
          "to_longitude": 105.83319233520734,
          "from_longitude": 105.83242234497324
        }
      },
      {
        "id": 2232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83375349371342,
            21.02793612876351,
            105.83413766817414,
            21.028167334028936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375349371342,
              21.028167334028936
            ],
            [
              105.83375725059884,
              21.02816495006639
            ],
            [
              105.83378781874102,
              21.02809231921444
            ],
            [
              105.83413766817414,
              21.02793612876351
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X12_Cát Linh",
          "maTaiSan": "04.O13.DODV.2239",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028167334028936,
          "to_latitude": 21.02793612876351,
          "to_longitude": 105.83413766817414,
          "from_longitude": 105.83375349371342
        }
      },
      {
        "id": 2233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83256293023891,
            21.030956014360303,
            105.83259425817047,
            21.030968445278965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83259425817047,
              21.030956014360303
            ],
            [
              105.83258208926702,
              21.030960842064584
            ],
            [
              105.83256293023891,
              21.030968445278965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2240",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030956014360303,
          "to_latitude": 21.030968445278965,
          "to_longitude": 105.83256293023891,
          "from_longitude": 105.83259425817047
        }
      },
      {
        "id": 2234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83250057847842,
            21.030806796309726,
            105.83266283487539,
            21.03123998614078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83266283487539,
              21.03123998614078
            ],
            [
              105.83260462496689,
              21.03106474961988
            ],
            [
              105.83256293023891,
              21.030968445278965
            ],
            [
              105.83250057847842,
              21.030806796309726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2241",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03123998614078,
          "to_latitude": 21.030806796309726,
          "to_longitude": 105.83250057847842,
          "from_longitude": 105.83266283487539
        }
      },
      {
        "id": 2235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244156821202,
            21.03132153944454,
            105.8325329078109,
            21.031457709450365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8324781084278,
              21.031457709450365
            ],
            [
              105.83244453953951,
              21.031364267356185
            ],
            [
              105.83244156821202,
              21.031357932243832
            ],
            [
              105.83253287424589,
              21.03132155857658
            ],
            [
              105.8325329078109,
              21.03132153944454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2242",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031457709450365,
          "to_latitude": 21.03132153944454,
          "to_longitude": 105.8325329078109,
          "from_longitude": 105.8324781084278
        }
      },
      {
        "id": 2236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83182706719761,
            21.031547695610783,
            105.8318570201343,
            21.031638489210682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318570201343,
              21.031638489210682
            ],
            [
              105.83184319350276,
              21.031596509099515
            ],
            [
              105.83182706719761,
              21.031547695610783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2243",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031638489210682,
          "to_latitude": 21.031547695610783,
          "to_longitude": 105.83182706719761,
          "from_longitude": 105.8318570201343
        }
      },
      {
        "id": 2237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8317463415322,
            21.03148340116672,
            105.83203073569778,
            21.03157318027407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317463415322,
              21.03157318027407
            ],
            [
              105.83178365523975,
              21.031561401248506
            ],
            [
              105.8318157772511,
              21.0315512607715
            ],
            [
              105.83182706719761,
              21.031547695610783
            ],
            [
              105.83190824133221,
              21.03152207142559
            ],
            [
              105.83194417497715,
              21.0315107272458
            ],
            [
              105.83203073569778,
              21.03148340116672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2244",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03157318027407,
          "to_latitude": 21.03148340116672,
          "to_longitude": 105.83203073569778,
          "from_longitude": 105.8317463415322
        }
      },
      {
        "id": 2238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133205904431,
            21.031582941421913,
            105.83170959576805,
            21.031707523231333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133205904431,
              21.031707523231333
            ],
            [
              105.83144787678553,
              21.031669305125796
            ],
            [
              105.83154910394258,
              21.03163590112913
            ],
            [
              105.83157004768685,
              21.031628990198254
            ],
            [
              105.83167944774331,
              21.03159288977223
            ],
            [
              105.83170959576805,
              21.031582941421913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2245",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031707523231333,
          "to_latitude": 21.031582941421913,
          "to_longitude": 105.83170959576805,
          "from_longitude": 105.83133205904431
        }
      },
      {
        "id": 2239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154910394258,
            21.03163590112913,
            105.83157769200986,
            21.03171428574765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157769200986,
              21.03171428574765
            ],
            [
              105.83156345561318,
              21.031675252124618
            ],
            [
              105.83155014535045,
              21.03163876196381
            ],
            [
              105.83154910394258,
              21.03163590112913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2246",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03171428574765,
          "to_latitude": 21.03163590112913,
          "to_longitude": 105.83154910394258,
          "from_longitude": 105.83157769200986
        }
      },
      {
        "id": 2240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83365740427963,
            21.02961211259136,
            105.83403972203966,
            21.0307045106686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83365740427963,
              21.02961211259136
            ],
            [
              105.83376222186672,
              21.02990957110911
            ],
            [
              105.83379007509014,
              21.029991991842557
            ],
            [
              105.83381542922551,
              21.030067018394504
            ],
            [
              105.83387247830709,
              21.030237300585977
            ],
            [
              105.83388062734882,
              21.030261624163654
            ],
            [
              105.83403972203966,
              21.0307045106686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X4_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2247",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 129,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02961211259136,
          "to_latitude": 21.0307045106686,
          "to_longitude": 105.83403972203966,
          "from_longitude": 105.83365740427963
        }
      },
      {
        "id": 2241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83342047231372,
            21.02919357044441,
            105.83346822153499,
            21.029204620585674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83342047231372,
              21.029204620585674
            ],
            [
              105.83343813351311,
              21.029200533645067
            ],
            [
              105.83345456586335,
              21.02919673091868
            ],
            [
              105.83346822153499,
              21.02919357044441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X5_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2248",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029204620585674,
          "to_latitude": 21.02919357044441,
          "to_longitude": 105.83346822153499,
          "from_longitude": 105.83342047231372
        }
      },
      {
        "id": 2242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83383280975237,
            21.030237300585977,
            105.83387247830709,
            21.030248333801627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83383280975237,
              21.030248333801627
            ],
            [
              105.83384061513595,
              21.03024616301425
            ],
            [
              105.8338550377072,
              21.030242151522575
            ],
            [
              105.83387247830709,
              21.030237300585977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X4_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2249",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030248333801627,
          "to_latitude": 21.030237300585977,
          "to_longitude": 105.83387247830709,
          "from_longitude": 105.83383280975237
        }
      },
      {
        "id": 2243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396134450264,
            21.028568877832807,
            105.83430779376717,
            21.029253341708262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396134450264,
              21.028568877832807
            ],
            [
              105.83412807590562,
              21.02884304095097
            ],
            [
              105.83417464056909,
              21.02891960818125
            ],
            [
              105.83430779376717,
              21.029138715454923
            ],
            [
              105.83410140901576,
              21.029253341708262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y1_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2250",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028568877832807,
          "to_latitude": 21.029253341708262,
          "to_longitude": 105.83410140901576,
          "from_longitude": 105.83396134450264
        }
      },
      {
        "id": 2244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83367441863052,
            21.029279394037466,
            105.83405873910013,
            21.02950002406459
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83367441863052,
              21.02950002406459
            ],
            [
              105.8338241095335,
              21.02943062253232
            ],
            [
              105.83383103444662,
              21.029417006805673
            ],
            [
              105.83387408817204,
              21.02939456463947
            ],
            [
              105.8339417886734,
              21.02935132138611
            ],
            [
              105.83395348764628,
              21.029343848545945
            ],
            [
              105.83405873910013,
              21.029279394037466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y5_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2251",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02950002406459,
          "to_latitude": 21.029279394037466,
          "to_longitude": 105.83405873910013,
          "from_longitude": 105.83367441863052
        }
      },
      {
        "id": 2245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368029731001,
            21.029202210014162,
            105.83433434790675,
            21.02954166093276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368029731001,
              21.02954166093276
            ],
            [
              105.83395751864836,
              21.02939504966406
            ],
            [
              105.83397038635319,
              21.029388244895163
            ],
            [
              105.83402907324081,
              21.029358247326865
            ],
            [
              105.83433434790675,
              21.029202210014162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y4_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2252",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02954166093276,
          "to_latitude": 21.029202210014162,
          "to_longitude": 105.83433434790675,
          "from_longitude": 105.83368029731001
        }
      },
      {
        "id": 2246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337998805357,
            21.028217883997637,
            105.83414978653211,
            21.02876831194786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337998805357,
              21.028217883997637
            ],
            [
              105.8340285404194,
              21.028573509670235
            ],
            [
              105.83403219113022,
              21.028579187490568
            ],
            [
              105.83410420947412,
              21.02868976180423
            ],
            [
              105.83414978653211,
              21.02876831194786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X9_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2253",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028217883997637,
          "to_latitude": 21.02876831194786,
          "to_longitude": 105.83414978653211,
          "from_longitude": 105.8337998805357
        }
      },
      {
        "id": 2247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83261957718075,
            21.0308001900274,
            105.83284410113059,
            21.03127117992332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83261957718075,
              21.0308001900274
            ],
            [
              105.83276667460376,
              21.031227578515537
            ],
            [
              105.83283815041996,
              21.031253775959588
            ],
            [
              105.83284410113059,
              21.03127117992332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z9_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2254",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0308001900274,
          "to_latitude": 21.03127117992332,
          "to_longitude": 105.83284410113059,
          "from_longitude": 105.83261957718075
        }
      },
      {
        "id": 2248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83429854478221,
            21.030316342326202,
            105.83450105501178,
            21.030681033333895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450105501178,
              21.030316342326202
            ],
            [
              105.83438706533717,
              21.030517736099902
            ],
            [
              105.83432263840486,
              21.030639121172324
            ],
            [
              105.83429854478221,
              21.030681033333895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y62_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2255",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030316342326202,
          "to_latitude": 21.030681033333895,
          "to_longitude": 105.83429854478221,
          "from_longitude": 105.83450105501178
        }
      },
      {
        "id": 2249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83417764088914,
            21.0288058234581,
            105.83435773675443,
            21.029089205896163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435773675443,
              21.029089205896163
            ],
            [
              105.83419127475777,
              21.028827276771697
            ],
            [
              105.83417764088914,
              21.0288058234581
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z6_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2256",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029089205896163,
          "to_latitude": 21.0288058234581,
          "to_longitude": 105.83417764088914,
          "from_longitude": 105.83435773675443
        }
      },
      {
        "id": 2250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427958437682,
            21.030335472745115,
            105.83438304912815,
            21.030515344098653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343151497337,
              21.030515344098653
            ],
            [
              105.83430055063702,
              21.030507520660457
            ],
            [
              105.83427958437682,
              21.03049633505323
            ],
            [
              105.83430423593455,
              21.030457529953615
            ],
            [
              105.83438304912815,
              21.030335472745115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X32_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2257",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030515344098653,
          "to_latitude": 21.030335472745115,
          "to_longitude": 105.83438304912815,
          "from_longitude": 105.8343151497337
        }
      },
      {
        "id": 2251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83451291947772,
            21.030078002365126,
            105.8346411280291,
            21.030299017180706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346411280291,
              21.030078002365126
            ],
            [
              105.83456807452536,
              21.030202247023244
            ],
            [
              105.83451291947772,
              21.030299017180706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y6_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2258",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030078002365126,
          "to_latitude": 21.030299017180706,
          "to_longitude": 105.83451291947772,
          "from_longitude": 105.8346411280291
        }
      },
      {
        "id": 2252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484643191687,
            21.03054912807659,
            105.8352609742206,
            21.03066887018644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83484643191687,
              21.03066887018644
            ],
            [
              105.83496396557726,
              21.030636432769374
            ],
            [
              105.83497980195678,
              21.030630970889174
            ],
            [
              105.83500450854922,
              21.030622449585426
            ],
            [
              105.8352609742206,
              21.03054912807659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y71_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2259",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03066887018644,
          "to_latitude": 21.03054912807659,
          "to_longitude": 105.8352609742206,
          "from_longitude": 105.83484643191687
        }
      },
      {
        "id": 2253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463308774785,
            21.02998736817018,
            105.83468455665353,
            21.03007009388523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463308774785,
              21.02998736817018
            ],
            [
              105.83463869546489,
              21.02999014153876
            ],
            [
              105.83464557299992,
              21.029993595123557
            ],
            [
              105.8346644501412,
              21.030002961915866
            ],
            [
              105.83468455665353,
              21.030013000991644
            ],
            [
              105.83464809141084,
              21.03007009388523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y61_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2260",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02998736817018,
          "to_latitude": 21.03007009388523,
          "to_longitude": 105.83464809141084,
          "from_longitude": 105.83463308774785
        }
      },
      {
        "id": 2254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83497980195678,
            21.030630970889174,
            105.83500070332538,
            21.030682645501834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83500070332538,
              21.030682645501834
            ],
            [
              105.83499590596598,
              21.030670784453534
            ],
            [
              105.83498523436036,
              21.03064440494354
            ],
            [
              105.83497980195678,
              21.030630970889174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y71_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2261",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030682645501834,
          "to_latitude": 21.030630970889174,
          "to_longitude": 105.83497980195678,
          "from_longitude": 105.83500070332538
        }
      },
      {
        "id": 2255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396067920029,
            21.02937228016257,
            105.83397038635319,
            21.029388244895163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396067920029,
              21.02937228016257
            ],
            [
              105.83396462085625,
              21.029378720683283
            ],
            [
              105.83397038635319,
              21.029388244895163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y4_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2262",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02937228016257,
          "to_latitude": 21.029388244895163,
          "to_longitude": 105.83397038635319,
          "from_longitude": 105.83396067920029
        }
      },
      {
        "id": 2256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834885860282,
            21.029273079721865,
            105.83501146194192,
            21.02948089042888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.834885860282,
              21.02948089042888
            ],
            [
              105.83489230853903,
              21.029470146541904
            ],
            [
              105.83493854724829,
              21.029393107603678
            ],
            [
              105.83494548262648,
              21.02938156197045
            ],
            [
              105.8349471430121,
              21.029378786341812
            ],
            [
              105.83497262845694,
              21.029336901581136
            ],
            [
              105.83501146194192,
              21.029273079721865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X21_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2263",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02948089042888,
          "to_latitude": 21.029273079721865,
          "to_longitude": 105.83501146194192,
          "from_longitude": 105.834885860282
        }
      },
      {
        "id": 2257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466149665132,
            21.029493234748664,
            105.83487832422946,
            21.02990378987412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466149665132,
              21.02990378987412
            ],
            [
              105.83471163368301,
              21.029787181525606
            ],
            [
              105.83473018725643,
              21.029754517510085
            ],
            [
              105.83480270755487,
              21.02962683593022
            ],
            [
              105.83482410668584,
              21.029584312802875
            ],
            [
              105.83484848433157,
              21.02954336110885
            ],
            [
              105.83487832422946,
              21.029493234748664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X31_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2264",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02990378987412,
          "to_latitude": 21.029493234748664,
          "to_longitude": 105.83487832422946,
          "from_longitude": 105.83466149665132
        }
      },
      {
        "id": 2258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439094934873,
            21.02998785166431,
            105.83459880662097,
            21.030333748562622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439094934873,
              21.030333748562622
            ],
            [
              105.83439579400029,
              21.030325602103375
            ],
            [
              105.83448857236833,
              21.030169592805926
            ],
            [
              105.83449963317011,
              21.030150993544908
            ],
            [
              105.83450289851471,
              21.030145503083876
            ],
            [
              105.83452042311647,
              21.03011669638658
            ],
            [
              105.83459432609754,
              21.029995216860232
            ],
            [
              105.83459880662097,
              21.02998785166431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X3_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2265",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030333748562622,
          "to_latitude": 21.02998785166431,
          "to_longitude": 105.83459880662097,
          "from_longitude": 105.83439094934873
        }
      },
      {
        "id": 2259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83433176142242,
            21.03048670628589,
            105.83438706533717,
            21.030517736099902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83433176142242,
              21.03048670628589
            ],
            [
              105.83434360973501,
              21.030493087223945
            ],
            [
              105.83435546024418,
              21.030499516924426
            ],
            [
              105.83438706533717,
              21.030517736099902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y62_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2266",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03048670628589,
          "to_latitude": 21.030517736099902,
          "to_longitude": 105.83438706533717,
          "from_longitude": 105.83433176142242
        }
      },
      {
        "id": 2260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83195763883633,
            21.03107245981877,
            105.83202851109628,
            21.031306925816164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195763883633,
              21.03107245981877
            ],
            [
              105.83196693054292,
              21.0311031998208
            ],
            [
              105.83197028617577,
              21.03111430027511
            ],
            [
              105.83198828942889,
              21.031173861959175
            ],
            [
              105.83201156038376,
              21.0312508490429
            ],
            [
              105.83202851109628,
              21.031306925816164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_18_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2267",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03107245981877,
          "to_latitude": 21.031306925816164,
          "to_longitude": 105.83202851109628,
          "from_longitude": 105.83195763883633
        }
      },
      {
        "id": 2261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187969767317,
            21.030885465661022,
            105.83193842347006,
            21.031057617234037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193842347006,
              21.031057617234037
            ],
            [
              105.83193317969653,
              21.031042243835145
            ],
            [
              105.83191339383914,
              21.030984243506325
            ],
            [
              105.83191064776184,
              21.030976193866593
            ],
            [
              105.83188861361705,
              21.03091160197081
            ],
            [
              105.83187969767317,
              21.030885465661022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2268",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031057617234037,
          "to_latitude": 21.030885465661022,
          "to_longitude": 105.83187969767317,
          "from_longitude": 105.83193842347006
        }
      },
      {
        "id": 2262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177154367094,
            21.030533376324392,
            105.83187860419939,
            21.03086195829767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83187860419939,
              21.03086195829767
            ],
            [
              105.83187145737821,
              21.030843956794406
            ],
            [
              105.83184817395272,
              21.030768163806535
            ],
            [
              105.83181913216059,
              21.03067908547096
            ],
            [
              105.83178086011858,
              21.03055957390844
            ],
            [
              105.83177154367094,
              21.030533376324392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_21_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2269",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03086195829767,
          "to_latitude": 21.030533376324392,
          "to_longitude": 105.83177154367094,
          "from_longitude": 105.83187860419939
        }
      },
      {
        "id": 2263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197028617577,
            21.031105755806283,
            105.83199593858537,
            21.03111430027511
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83199593858537,
              21.031105755806283
            ],
            [
              105.8319838085624,
              21.031109794764227
            ],
            [
              105.83197028617577,
              21.03111430027511
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_18_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2270",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031105755806283,
          "to_latitude": 21.03111430027511,
          "to_longitude": 105.83197028617577,
          "from_longitude": 105.83199593858537
        }
      },
      {
        "id": 2264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83191339383914,
            21.030973858141866,
            105.83195347231766,
            21.030984243506325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195347231766,
              21.030973858141866
            ],
            [
              105.83193844672003,
              21.030977749587496
            ],
            [
              105.83191339383914,
              21.030984243506325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2271",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030973858141866,
          "to_latitude": 21.030984243506325,
          "to_longitude": 105.83191339383914,
          "from_longitude": 105.83195347231766
        }
      },
      {
        "id": 2265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83181913216059,
            21.030668491412488,
            105.83185516405572,
            21.03067908547096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83185516405572,
              21.030668491412488
            ],
            [
              105.83183813206503,
              21.030672971630935
            ],
            [
              105.83181913216059,
              21.03067908547096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_21_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2272",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030668491412488,
          "to_latitude": 21.03067908547096,
          "to_longitude": 105.83181913216059,
          "from_longitude": 105.83185516405572
        }
      },
      {
        "id": 2266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197429999042,
            21.03096217509908,
            105.83211103810498,
            21.031383780964887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197429999042,
              21.03096217509908
            ],
            [
              105.83202437526221,
              21.031111446058322
            ],
            [
              105.83203408805772,
              21.031140399226043
            ],
            [
              105.83211103810498,
              21.031383780964887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2273",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03096217509908,
          "to_latitude": 21.031383780964887,
          "to_longitude": 105.83211103810498,
          "from_longitude": 105.83197429999042
        }
      },
      {
        "id": 2267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188582798239,
            21.03069415551672,
            105.83196893404927,
            21.030946110429756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83196893404927,
              21.030946110429756
            ],
            [
              105.83196077431359,
              21.03092137222735
            ],
            [
              105.83192600617754,
              21.030815963653403
            ],
            [
              105.83190467291506,
              21.030751288845074
            ],
            [
              105.83189617856011,
              21.03072553705242
            ],
            [
              105.83188582798239,
              21.03069415551672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_20_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2274",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030946110429756,
          "to_latitude": 21.03069415551672,
          "to_longitude": 105.83188582798239,
          "from_longitude": 105.83196893404927
        }
      },
      {
        "id": 2268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318185721197,
            21.030495667000505,
            105.83188679224183,
            21.030679823868088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188679224183,
              21.030679823868088
            ],
            [
              105.83187578813576,
              21.030650119202026
            ],
            [
              105.83185318025673,
              21.03058908970826
            ],
            [
              105.8318379783175,
              21.030548028573072
            ],
            [
              105.83182607876782,
              21.03051593112835
            ],
            [
              105.8318185721197,
              21.030495667000505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_22_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2275",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030679823868088,
          "to_latitude": 21.030495667000505,
          "to_longitude": 105.8318185721197,
          "from_longitude": 105.83188679224183
        }
      },
      {
        "id": 2269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190460601284,
            21.030815963653403,
            105.83192600617754,
            21.03082208292482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190460601284,
              21.03082208292482
            ],
            [
              105.83191682177095,
              21.03081858819727
            ],
            [
              105.83192600617754,
              21.030815963653403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_20_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2276",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03082208292482,
          "to_latitude": 21.030815963653403,
          "to_longitude": 105.83192600617754,
          "from_longitude": 105.83190460601284
        }
      },
      {
        "id": 2270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452997878022,
            21.02845082595058,
            105.83478410400409,
            21.02885604706352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452997878022,
              21.02845082595058
            ],
            [
              105.83455886196418,
              21.028495493103446
            ],
            [
              105.83478410400409,
              21.02881051199714
            ],
            [
              105.83474363234538,
              21.02885604706352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2277",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02845082595058,
          "to_latitude": 21.02885604706352,
          "to_longitude": 105.83474363234538,
          "from_longitude": 105.83452997878022
        }
      },
      {
        "id": 2271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455886196418,
            21.02848001473738,
            105.83458017493888,
            21.028495493103446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455886196418,
              21.028495493103446
            ],
            [
              105.83456915053286,
              21.028488022628917
            ],
            [
              105.83458017493888,
              21.02848001473738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2278",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028495493103446,
          "to_latitude": 21.02848001473738,
          "to_longitude": 105.83458017493888,
          "from_longitude": 105.83455886196418
        }
      },
      {
        "id": 2272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553014969294,
            21.02986329085536,
            105.83577502514497,
            21.03023351522458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577502514497,
              21.03023351522458
            ],
            [
              105.83575357168095,
              21.03020311612906
            ],
            [
              105.83571216683276,
              21.03014444666829
            ],
            [
              105.83562920030901,
              21.03000983163539
            ],
            [
              105.83558629687866,
              21.02994635803593
            ],
            [
              105.83553014969294,
              21.02986329085536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z11_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2279",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03023351522458,
          "to_latitude": 21.02986329085536,
          "to_longitude": 105.83553014969294,
          "from_longitude": 105.83577502514497
        }
      },
      {
        "id": 2273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452265436318,
            21.03017964279458,
            105.83456807452536,
            21.030202247023244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452265436318,
              21.03017964279458
            ],
            [
              105.83453114074113,
              21.03018384538851
            ],
            [
              105.83454881902648,
              21.03019266078838
            ],
            [
              105.83455595593146,
              21.030196217877368
            ],
            [
              105.83456807452536,
              21.030202247023244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y6_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2280",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03017964279458,
          "to_latitude": 21.030202247023244,
          "to_longitude": 105.83456807452536,
          "from_longitude": 105.83452265436318
        }
      },
      {
        "id": 2274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452042311647,
            21.03011669638658,
            105.83455031124448,
            21.030130999456745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455031124448,
              21.030130999456745
            ],
            [
              105.83454083502082,
              21.03012646572295
            ],
            [
              105.83453032473403,
              21.03012143487178
            ],
            [
              105.83452042311647,
              21.03011669638658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X3_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2281",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030130999456745,
          "to_latitude": 21.03011669638658,
          "to_longitude": 105.83452042311647,
          "from_longitude": 105.83455031124448
        }
      },
      {
        "id": 2275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466149665132,
            21.02990378987412,
            105.83467858905756,
            21.029911745559385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467858905756,
              21.029911745559385
            ],
            [
              105.83467168882858,
              21.02990853505421
            ],
            [
              105.83466149665132,
              21.02990378987412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X31_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2282",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029911745559385,
          "to_latitude": 21.02990378987412,
          "to_longitude": 105.83466149665132,
          "from_longitude": 105.83467858905756
        }
      },
      {
        "id": 2276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83479768282537,
            21.029683479138797,
            105.83524805742277,
            21.03002250596889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83479768282537,
              21.029683479138797
            ],
            [
              105.83481279615701,
              21.02969278671709
            ],
            [
              105.83493384779476,
              21.02976753019287
            ],
            [
              105.83507106844186,
              21.029873715750867
            ],
            [
              105.83524805742277,
              21.03002250596889
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y8_Ngõ Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2283",
          "diaChiLapD": "Ngõ Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029683479138797,
          "to_latitude": 21.03002250596889,
          "to_longitude": 105.83524805742277,
          "from_longitude": 105.83479768282537
        }
      },
      {
        "id": 2277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8348066598254,
            21.029667992660634,
            105.83529597638397,
            21.030047590622367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8348066598254,
              21.029667992660634
            ],
            [
              105.83480850097251,
              21.0296688254253
            ],
            [
              105.83483842021725,
              21.029682336167888
            ],
            [
              105.83486405951706,
              21.029690339290816
            ],
            [
              105.83501620139207,
              21.029817991697595
            ],
            [
              105.83529597638397,
              21.030047590622367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y9_Ngõ Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2284",
          "diaChiLapD": "Ngõ Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029667992660634,
          "to_latitude": 21.030047590622367,
          "to_longitude": 105.83529597638397,
          "from_longitude": 105.8348066598254
        }
      },
      {
        "id": 2278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494548262648,
            21.02938156197045,
            105.83496788233919,
            21.02939087440217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496788233919,
              21.02939087440217
            ],
            [
              105.83495685402012,
              21.029386271537756
            ],
            [
              105.83494548262648,
              21.02938156197045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X21_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2285",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02939087440217,
          "to_latitude": 21.02938156197045,
          "to_longitude": 105.83494548262648,
          "from_longitude": 105.83496788233919
        }
      },
      {
        "id": 2279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83412807590562,
            21.028820161365953,
            105.83416803793759,
            21.02884304095097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416803793759,
              21.028820161365953
            ],
            [
              105.8341648493768,
              21.02882198706737
            ],
            [
              105.83415675954014,
              21.02882661874018
            ],
            [
              105.83412807590562,
              21.02884304095097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y1_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2286",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028820161365953,
          "to_latitude": 21.02884304095097,
          "to_longitude": 105.83412807590562,
          "from_longitude": 105.83416803793759
        }
      },
      {
        "id": 2280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337994414989,
            21.0282899450454,
            105.83383265232102,
            21.028308270813568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83383265232102,
              21.0282899450454
            ],
            [
              105.83381318311777,
              21.028300688613804
            ],
            [
              105.8337994414989,
              21.028308270813568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X7_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2287",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0282899450454,
          "to_latitude": 21.028308270813568,
          "to_longitude": 105.8337994414989,
          "from_longitude": 105.83383265232102
        }
      },
      {
        "id": 2281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83375955267263,
            21.02824640591867,
            105.83391720239086,
            21.028492650008253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83391720239086,
              21.028492650008253
            ],
            [
              105.8337994414989,
              21.028308270813568
            ],
            [
              105.83379847494466,
              21.028306763556994
            ],
            [
              105.83375955267263,
              21.02824640591867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X7_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2288",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028492650008253,
          "to_latitude": 21.02824640591867,
          "to_longitude": 105.83375955267263,
          "from_longitude": 105.83391720239086
        }
      },
      {
        "id": 2282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83181715109748,
            21.030548028573072,
            105.8318379783175,
            21.03055042465204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318379783175,
              21.030548028573072
            ],
            [
              105.83182416074277,
              21.03054960726484
            ],
            [
              105.83181715109748,
              21.03055042465204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_22_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2289",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030548028573072,
          "to_latitude": 21.03055042465204,
          "to_longitude": 105.83181715109748,
          "from_longitude": 105.8318379783175
        }
      },
      {
        "id": 2283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83296462812343,
            21.031098506558262,
            105.83341251211246,
            21.031233803926497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83296462812343,
              21.031233803926497
            ],
            [
              105.83297657772457,
              21.031230193910794
            ],
            [
              105.83306049577091,
              21.031204844773594
            ],
            [
              105.83315956062529,
              21.031174918806727
            ],
            [
              105.8331773092553,
              21.03116955701095
            ],
            [
              105.83320627083172,
              21.0311608082749
            ],
            [
              105.83329881531496,
              21.03113285249788
            ],
            [
              105.83341251211246,
              21.031098506558262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z8_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2290",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031233803926497,
          "to_latitude": 21.031098506558262,
          "to_longitude": 105.83341251211246,
          "from_longitude": 105.83296462812343
        }
      },
      {
        "id": 2284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83347721839512,
            21.030936426806715,
            105.83392038401936,
            21.031071472037496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83347721839512,
              21.031071472037496
            ],
            [
              105.83364595632311,
              21.03102005255938
            ],
            [
              105.833701078916,
              21.031003255107322
            ],
            [
              105.83378413397953,
              21.03097794597971
            ],
            [
              105.83392038401936,
              21.030936426806715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z7_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2291",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031071472037496,
          "to_latitude": 21.030936426806715,
          "to_longitude": 105.83392038401936,
          "from_longitude": 105.83347721839512
        }
      },
      {
        "id": 2285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364595632311,
            21.03102005255938,
            105.83366736462258,
            21.031085796173002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366736462258,
              21.031085796173002
            ],
            [
              105.83365756044152,
              21.031055689236215
            ],
            [
              105.83364595632311,
              21.03102005255938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z7_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2292",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031085796173002,
          "to_latitude": 21.03102005255938,
          "to_longitude": 105.83364595632311,
          "from_longitude": 105.83366736462258
        }
      },
      {
        "id": 2286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831263612727,
            21.028888651257994,
            105.83127471032435,
            21.028932138714502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831263612727,
              21.028888651257994
            ],
            [
              105.83126372582093,
              21.028889095091223
            ],
            [
              105.83126673403966,
              21.028900886423237
            ],
            [
              105.83126974418292,
              21.028912677745762
            ],
            [
              105.83127471032435,
              21.028932138714502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7_Cát Linh",
          "maTaiSan": "04.O13.DODV.2293",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028888651257994,
          "to_latitude": 21.028932138714502,
          "to_longitude": 105.83127471032435,
          "from_longitude": 105.831263612727
        }
      },
      {
        "id": 2287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83083992462342,
            21.02895474786043,
            105.83098369436503,
            21.02964666444912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83085619802883,
              21.02895474786043
            ],
            [
              105.8308598994938,
              21.028965956846594
            ],
            [
              105.8308784174644,
              21.029022011659233
            ],
            [
              105.83089685987663,
              21.029134718842577
            ],
            [
              105.83083992462342,
              21.029149335723904
            ],
            [
              105.83090439398576,
              21.02939182352037
            ],
            [
              105.83094234200155,
              21.029533590535156
            ],
            [
              105.83098369436503,
              21.02964666444912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5_Ngõ 20, Cát Linh",
          "maTaiSan": "04.O13.DODV.2294",
          "diaChiLapD": "Ngõ 20, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02895474786043,
          "to_latitude": 21.02964666444912,
          "to_longitude": 105.83098369436503,
          "from_longitude": 105.83085619802883
        }
      },
      {
        "id": 2288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83136768252187,
            21.02891411542161,
            105.83188008470476,
            21.029496928461793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188008470476,
              21.02895858171146
            ],
            [
              105.8318648832973,
              21.028963520842144
            ],
            [
              105.83185035122249,
              21.028968244439515
            ],
            [
              105.83182924708805,
              21.02891411542161
            ],
            [
              105.83144746016914,
              21.029013898438595
            ],
            [
              105.83147805696697,
              21.029110432603918
            ],
            [
              105.831436883706,
              21.02915802337059
            ],
            [
              105.83136768252187,
              21.029191341134485
            ],
            [
              105.8314137519176,
              21.029496928461793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B8_Ngõ 16, Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2295",
          "diaChiLapD": "Ngõ 16, Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 140,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02895858171146,
          "to_latitude": 21.029496928461793,
          "to_longitude": 105.8314137519176,
          "from_longitude": 105.83188008470476
        }
      },
      {
        "id": 2289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534616614386,
            21.030100881694075,
            105.8356819594851,
            21.030480763705608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534616614386,
              21.030100881694075
            ],
            [
              105.83566174147754,
              21.0303679621649
            ],
            [
              105.8356819594851,
              21.030439732240744
            ],
            [
              105.83567682900866,
              21.030461479062808
            ],
            [
              105.83567224855317,
              21.030480763705608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y85A_Ngõ Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2296",
          "diaChiLapD": "Ngõ Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030100881694075,
          "to_latitude": 21.030480763705608,
          "to_longitude": 105.83567224855317,
          "from_longitude": 105.83534616614386
        }
      },
      {
        "id": 2290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83514003478756,
            21.02930903785699,
            105.83540961197184,
            21.029686985092624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540961197184,
              21.029686985092624
            ],
            [
              105.83530651799384,
              21.029553318699985
            ],
            [
              105.8352897586611,
              21.02953158909653
            ],
            [
              105.83514003478756,
              21.02930903785699
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2297",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029686985092624,
          "to_latitude": 21.02930903785699,
          "to_longitude": 105.83514003478756,
          "from_longitude": 105.83540961197184
        }
      },
      {
        "id": 2291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530651799384,
            21.029539725461127,
            105.83532650432468,
            21.029553318699985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83530651799384,
              21.029553318699985
            ],
            [
              105.8353108938982,
              21.029550321083516
            ],
            [
              105.83532650432468,
              21.029539725461127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2298",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029553318699985,
          "to_latitude": 21.029539725461127,
          "to_longitude": 105.83532650432468,
          "from_longitude": 105.83530651799384
        }
      },
      {
        "id": 2292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83200980980625,
            21.031140399226043,
            105.83203408805772,
            21.03114883762182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200980980625,
              21.03114883762182
            ],
            [
              105.832020377588,
              21.03114516398322
            ],
            [
              105.83203408805772,
              21.031140399226043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2299",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03114883762182,
          "to_latitude": 21.031140399226043,
          "to_longitude": 105.83203408805772,
          "from_longitude": 105.83200980980625
        }
      },
      {
        "id": 2293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575357168095,
            21.0301871218775,
            105.83577911000796,
            21.03020311612906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577911000796,
              21.0301871218775
            ],
            [
              105.83576444505317,
              21.030196306576396
            ],
            [
              105.83575752432785,
              21.03020064083781
            ],
            [
              105.83575357168095,
              21.03020311612906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z11'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2300",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0301871218775,
          "to_latitude": 21.03020311612906,
          "to_longitude": 105.83575357168095,
          "from_longitude": 105.83577911000796
        }
      },
      {
        "id": 2294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82894788986756,
            21.030079205534452,
            105.8295225742707,
            21.0307356280658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82894788986756,
              21.0307356280658
            ],
            [
              105.82897367300315,
              21.03070738680701
            ],
            [
              105.82916139031411,
              21.03050173139817
            ],
            [
              105.82921087271542,
              21.030447520650586
            ],
            [
              105.82921186431005,
              21.030446430139563
            ],
            [
              105.8292513750739,
              21.030403149149357
            ],
            [
              105.82926516510409,
              21.030386677540754
            ],
            [
              105.8295225742707,
              21.030079205534452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_2_Ngõ 59, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2301",
          "diaChiLapD": "Ngõ 59, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0307356280658,
          "to_latitude": 21.030079205534452,
          "to_longitude": 105.8295225742707,
          "from_longitude": 105.82894788986756
        }
      },
      {
        "id": 2295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82963250000897,
            21.031235857278133,
            105.82965078150531,
            21.031260554815212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82963250000897,
              21.031260554815212
            ],
            [
              105.82963626230207,
              21.031255472081384
            ],
            [
              105.82964523354575,
              21.031243350397546
            ],
            [
              105.82965078150531,
              21.031235857278133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2302",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031260554815212,
          "to_latitude": 21.031235857278133,
          "to_longitude": 105.82965078150531,
          "from_longitude": 105.82963250000897
        }
      },
      {
        "id": 2296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83103301533988,
            21.03104817555471,
            105.8313599791757,
            21.031707523231333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133205904431,
              21.031707523231333
            ],
            [
              105.83128467788619,
              21.031544539701567
            ],
            [
              105.8313599791757,
              21.031523132736368
            ],
            [
              105.83124337018536,
              21.03104817555471
            ],
            [
              105.83103301533988,
              21.031120898098447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 173, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2303",
          "diaChiLapD": "Ngõ 173, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 104.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031707523231333,
          "to_latitude": 21.031120898098447,
          "to_longitude": 105.83103301533988,
          "from_longitude": 105.83133205904431
        }
      },
      {
        "id": 2297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83472016756103,
            21.03016411891778,
            105.83510384272672,
            21.030336278480657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83510384272672,
              21.03016411891778
            ],
            [
              105.83510227687141,
              21.030166422601173
            ],
            [
              105.83506767507332,
              21.03022936202276
            ],
            [
              105.83505540916603,
              21.030235401599
            ],
            [
              105.83487409159831,
              21.030292936706953
            ],
            [
              105.83486187739345,
              21.030296689055003
            ],
            [
              105.83472016756103,
              21.030336278480657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7BS'_Ngách 139A/14, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2304",
          "diaChiLapD": "Ngách 139A/14, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03016411891778,
          "to_latitude": 21.030336278480657,
          "to_longitude": 105.83472016756103,
          "from_longitude": 105.83510384272672
        }
      },
      {
        "id": 2298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83361625343527,
            21.028220119118046,
            105.83379037476672,
            21.028679238245246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83379037476672,
              21.028220119118046
            ],
            [
              105.83378936490472,
              21.028220684068238
            ],
            [
              105.8337366880015,
              21.028250139951812
            ],
            [
              105.83364853127942,
              21.028325850415886
            ],
            [
              105.83365017920526,
              21.028331383612624
            ],
            [
              105.83365631310498,
              21.028351980454314
            ],
            [
              105.83367610280995,
              21.02840923811998
            ],
            [
              105.8336679623444,
              21.028464959946806
            ],
            [
              105.83365867746484,
              21.028488579598037
            ],
            [
              105.83363815687275,
              21.028498828819227
            ],
            [
              105.83364485845918,
              21.028522555256483
            ],
            [
              105.83363785494812,
              21.028541408250895
            ],
            [
              105.83362739091977,
              21.028569578701408
            ],
            [
              105.83362125408175,
              21.028591353890175
            ],
            [
              105.83361625343527,
              21.028622651612377
            ],
            [
              105.83363345159749,
              21.028679238245246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X7BS_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2305",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028220119118046,
          "to_latitude": 21.028679238245246,
          "to_longitude": 105.83363345159749,
          "from_longitude": 105.83379037476672
        }
      },
      {
        "id": 2299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315439539258,
            21.028261467081208,
            105.83334164447889,
            21.028902747732598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315439539258,
              21.028261467081208
            ],
            [
              105.83315748256992,
              21.02827255528198
            ],
            [
              105.83316016111876,
              21.028279703817375
            ],
            [
              105.83316989983835,
              21.02830570326068
            ],
            [
              105.83321234659995,
              21.028439418033894
            ],
            [
              105.83321278444535,
              21.0284392677598
            ],
            [
              105.83321553754651,
              21.028439059173405
            ],
            [
              105.83321828681758,
              21.028439546087817
            ],
            [
              105.83322086275209,
              21.02844069410792
            ],
            [
              105.83322310045257,
              21.028442432687047
            ],
            [
              105.83322486078463,
              21.028444653216923
            ],
            [
              105.83322603233583,
              21.02844721534066
            ],
            [
              105.83322654205796,
              21.028449957739177
            ],
            [
              105.83322640080927,
              21.02845206474845
            ],
            [
              105.83322635725136,
              21.02845270896025
            ],
            [
              105.83322549234383,
              21.02845529551428
            ],
            [
              105.83322399645529,
              21.028457554580335
            ],
            [
              105.83322196885983,
              21.02845934657555
            ],
            [
              105.83321953397892,
              21.028460556180836
            ],
            [
              105.8332186393575,
              21.02846073940275
            ],
            [
              105.83323539114421,
              21.02849970457246
            ],
            [
              105.83326599296079,
              21.02856736502279
            ],
            [
              105.83332248499045,
              21.028817810368608
            ],
            [
              105.83334164447889,
              21.028902747732598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6A_Ngõ 12, Cát Linh",
          "maTaiSan": "04.O13.DODV.2306",
          "diaChiLapD": "Ngõ 12, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028261467081208,
          "to_latitude": 21.028902747732598,
          "to_longitude": 105.83334164447889,
          "from_longitude": 105.83315439539258
        }
      },
      {
        "id": 2300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83306821867127,
            21.02826279482952,
            105.8332104437747,
            21.028669947091604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315107515234,
              21.02826279482952
            ],
            [
              105.83315744523239,
              21.028276222552403
            ],
            [
              105.83315884969352,
              21.028280188048107
            ],
            [
              105.83315993654239,
              21.02828326181192
            ],
            [
              105.83315756456322,
              21.02829959558585
            ],
            [
              105.8332104437747,
              21.028460021756434
            ],
            [
              105.83311795709176,
              21.028512452714665
            ],
            [
              105.83308756521541,
              21.028539845545026
            ],
            [
              105.83306821867127,
              21.028589119040657
            ],
            [
              105.83309210656293,
              21.028669947091604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6B_Ngõ 12, Cát Linh",
          "maTaiSan": "04.O13.DODV.2307",
          "diaChiLapD": "Ngõ 12, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02826279482952,
          "to_latitude": 21.028669947091604,
          "to_longitude": 105.83309210656293,
          "from_longitude": 105.83315107515234
        }
      },
      {
        "id": 2301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332104437747,
            21.02837002682067,
            105.83336075533778,
            21.028460021756434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332104437747,
              21.028460021756434
            ],
            [
              105.83322640080927,
              21.02845206474845
            ],
            [
              105.83331933779624,
              21.028405718349255
            ],
            [
              105.83332494696116,
              21.028384540980085
            ],
            [
              105.83336075533778,
              21.02837002682067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6B_Ngõ 12, Cát Linh",
          "maTaiSan": "04.O13.DODV.2308",
          "diaChiLapD": "Ngõ 12, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028460021756434,
          "to_latitude": 21.02837002682067,
          "to_longitude": 105.83336075533778,
          "from_longitude": 105.8332104437747
        }
      },
      {
        "id": 2302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951463137009,
            21.03086318653855,
            105.82984038733915,
            21.031131452254424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82951463137009,
              21.031131452254424
            ],
            [
              105.82973958538616,
              21.03086643984376
            ],
            [
              105.8297817622424,
              21.03086318653855
            ],
            [
              105.82981045882484,
              21.030881958190204
            ],
            [
              105.82982109924984,
              21.030915549540865
            ],
            [
              105.82982738597583,
              21.030973898729396
            ],
            [
              105.82984038733915,
              21.0310592838597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2309",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031131452254424,
          "to_latitude": 21.0310592838597,
          "to_longitude": 105.82984038733915,
          "from_longitude": 105.82951463137009
        }
      },
      {
        "id": 2303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83417939542622,
            21.029950979064218,
            105.83422287744301,
            21.030006833520186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421584009528,
              21.030006833520186
            ],
            [
              105.83422287744301,
              21.029988703820987
            ],
            [
              105.83417939542622,
              21.029950979064218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X3_Ngõ 16, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2310",
          "diaChiLapD": "Ngõ 16, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030006833520186,
          "to_latitude": 21.029950979064218,
          "to_longitude": 105.83417939542622,
          "from_longitude": 105.83421584009528
        }
      },
      {
        "id": 2304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372142746592,
            21.029716504626258,
            105.834392282902,
            21.030014106971667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372142746592,
              21.030014106971667
            ],
            [
              105.83375829390141,
              21.030004169584796
            ],
            [
              105.83379007509014,
              21.029991991842557
            ],
            [
              105.8338147514453,
              21.02998253692765
            ],
            [
              105.83389907655275,
              21.02995009961446
            ],
            [
              105.83396108478736,
              21.02995731031404
            ],
            [
              105.83399221632571,
              21.02994629726604
            ],
            [
              105.83406538792431,
              21.029922040876617
            ],
            [
              105.83427426866791,
              21.029859359411578
            ],
            [
              105.83424927703706,
              21.029757198329097
            ],
            [
              105.83431263585075,
              21.029739168930455
            ],
            [
              105.834392282902,
              21.029716504626258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X4'_Ngách 17, Ngõ Hàng Cháo, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2311",
          "diaChiLapD": "Ngách 17, Ngõ Hàng Cháo, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030014106971667,
          "to_latitude": 21.029716504626258,
          "to_longitude": 105.834392282902,
          "from_longitude": 105.83372142746592
        }
      },
      {
        "id": 2305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82956779108584,
            21.03061758006554,
            105.82967518097078,
            21.030700647645432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82967518097078,
              21.030700647645432
            ],
            [
              105.82956779108584,
              21.03061758006554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4_Ngõ 45, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2312",
          "diaChiLapD": "Ngõ 45, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030700647645432,
          "to_latitude": 21.03061758006554,
          "to_longitude": 105.82956779108584,
          "from_longitude": 105.82967518097078
        }
      },
      {
        "id": 2306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83185318025673,
            21.030518722350273,
            105.83206268118029,
            21.03058908970826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83185318025673,
              21.03058908970826
            ],
            [
              105.83206268118029,
              21.030518722350273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2313",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03058908970826,
          "to_latitude": 21.030518722350273,
          "to_longitude": 105.83206268118029,
          "from_longitude": 105.83185318025673
        }
      },
      {
        "id": 2307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459689452077,
            21.029533542532157,
            105.83480270755487,
            21.02962683593022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480270755487,
              21.02962683593022
            ],
            [
              105.83459689452077,
              21.029533542532157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X31_Ngõ 40, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2314",
          "diaChiLapD": "Ngõ 40, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02962683593022,
          "to_latitude": 21.029533542532157,
          "to_longitude": 105.83459689452077,
          "from_longitude": 105.83480270755487
        }
      },
      {
        "id": 2308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83475325917823,
            21.0293011831452,
            105.83493854724829,
            21.029393107603678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83493854724829,
              21.029393107603678
            ],
            [
              105.83486528534856,
              21.029357899302646
            ],
            [
              105.83475325917823,
              21.0293011831452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X21_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2315",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029393107603678,
          "to_latitude": 21.0293011831452,
          "to_longitude": 105.83475325917823,
          "from_longitude": 105.83493854724829
        }
      },
      {
        "id": 2309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83173973374285,
            21.030976193866593,
            105.83191064776184,
            21.03102322087485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83173973374285,
              21.03102322087485
            ],
            [
              105.83191064776184,
              21.030976193866593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Ngõ 8, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2316",
          "diaChiLapD": "Ngõ 8, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03102322087485,
          "to_latitude": 21.030976193866593,
          "to_longitude": 105.83191064776184,
          "from_longitude": 105.83173973374285
        }
      },
      {
        "id": 2310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83175857589914,
            21.031042243835145,
            105.83193317969653,
            21.031093030069343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83175857589914,
              21.031093030069343
            ],
            [
              105.83193317969653,
              21.031042243835145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Ngõ 6, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2317",
          "diaChiLapD": "Ngõ 6, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031093030069343,
          "to_latitude": 21.031042243835145,
          "to_longitude": 105.83193317969653,
          "from_longitude": 105.83175857589914
        }
      },
      {
        "id": 2311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83180840154614,
            21.0311031998208,
            105.83196693054292,
            21.031150379459564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83180840154614,
              21.031150379459564
            ],
            [
              105.8318399057962,
              21.031139710906896
            ],
            [
              105.83196693054292,
              21.0311031998208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 4, Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2318",
          "diaChiLapD": "Ngõ 4, Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031150379459564,
          "to_latitude": 21.0311031998208,
          "to_longitude": 105.83196693054292,
          "from_longitude": 105.83180840154614
        }
      },
      {
        "id": 2312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82926516510409,
            21.030386677540754,
            105.82953719399772,
            21.030551233124566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926516510409,
              21.030386677540754
            ],
            [
              105.82927063555638,
              21.03039091136808
            ],
            [
              105.82927314563821,
              21.030392849186548
            ],
            [
              105.82944733555895,
              21.030527662875254
            ],
            [
              105.82947627697403,
              21.030551233124566
            ],
            [
              105.82953719399772,
              21.03048016202152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Ngách 59/11, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2319",
          "diaChiLapD": "Ngách 59/11, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030386677540754,
          "to_latitude": 21.03048016202152,
          "to_longitude": 105.82953719399772,
          "from_longitude": 105.82926516510409
        }
      },
      {
        "id": 2313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82936935636712,
            21.03028258944003,
            105.8295286843821,
            21.030398214289974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82936935636712,
              21.03028258944003
            ],
            [
              105.82938232757972,
              21.030292002795495
            ],
            [
              105.8295286843821,
              21.030398214289974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2BS_Ngách 59/17, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2320",
          "diaChiLapD": "Ngách 59/17, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03028258944003,
          "to_latitude": 21.030398214289974,
          "to_longitude": 105.8295286843821,
          "from_longitude": 105.82936935636712
        }
      },
      {
        "id": 2314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940270566425,
            21.030240777305778,
            105.82955212062748,
            21.030298997319257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82940270566425,
              21.030240777305778
            ],
            [
              105.82947225975121,
              21.030292265003748
            ],
            [
              105.82950205210473,
              21.03026674087859
            ],
            [
              105.82955212062748,
              21.030298997319257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2BS_Ngách 59/21, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2321",
          "diaChiLapD": "Ngách 59/21, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030240777305778,
          "to_latitude": 21.030298997319257,
          "to_longitude": 105.82955212062748,
          "from_longitude": 105.82940270566425
        }
      },
      {
        "id": 2315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834658054698,
            21.029721737767044,
            105.83473018725643,
            21.029754517510085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.834658054698,
              21.029721737767044
            ],
            [
              105.83473018725643,
              21.029754517510085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X31_Ngõ 34, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2322",
          "diaChiLapD": "Ngõ 34, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029721737767044,
          "to_latitude": 21.029754517510085,
          "to_longitude": 105.83473018725643,
          "from_longitude": 105.834658054698
        }
      },
      {
        "id": 2316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83422799250461,
            21.030039793930868,
            105.83429518668324,
            21.03015103849648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83429518668324,
              21.03015103849648
            ],
            [
              105.83422799250461,
              21.03011504416004
            ],
            [
              105.8342710698938,
              21.030039793930868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X3_Ngõ 16, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2323",
          "diaChiLapD": "Ngõ 16, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03015103849648,
          "to_latitude": 21.030039793930868,
          "to_longitude": 105.8342710698938,
          "from_longitude": 105.83429518668324
        }
      },
      {
        "id": 2317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353089911074,
            21.028536489742166,
            105.83364545780815,
            21.028541757557438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364545780815,
              21.028541757557438
            ],
            [
              105.83363785494812,
              21.028541408250895
            ],
            [
              105.83353089911074,
              21.028536489742166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X7_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2324",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028541757557438,
          "to_latitude": 21.028536489742166,
          "to_longitude": 105.83353089911074,
          "from_longitude": 105.83364545780815
        }
      },
      {
        "id": 2318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83111439853938,
            21.029628149032924,
            105.83113031951785,
            21.02968165496008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83113031951785,
              21.02968165496008
            ],
            [
              105.83111439853938,
              21.029628149032924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5BS_Ngách 20/34, Cát Linh",
          "maTaiSan": "04.O13.DODV.2325",
          "diaChiLapD": "Ngách 20/34, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02968165496008,
          "to_latitude": 21.029628149032924,
          "to_longitude": 105.83111439853938,
          "from_longitude": 105.83113031951785
        }
      },
      {
        "id": 2319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82896250149345,
            21.030221742228328,
            105.82941781389056,
            21.030746978096115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82896250149345,
              21.030746978096115
            ],
            [
              105.82898762118543,
              21.03071828032882
            ],
            [
              105.82911940834687,
              21.030567676372705
            ],
            [
              105.8291713871538,
              21.03050944676126
            ],
            [
              105.8291721749859,
              21.03050856408243
            ],
            [
              105.8292195692059,
              21.030454250612564
            ],
            [
              105.82922054714756,
              21.030453126748785
            ],
            [
              105.82927314563821,
              21.030392849186548
            ],
            [
              105.82932503859436,
              21.03033338253226
            ],
            [
              105.82936935636712,
              21.03028258944003
            ],
            [
              105.8293712826736,
              21.030280387016294
            ],
            [
              105.82940270566425,
              21.030240777305778
            ],
            [
              105.82941781389056,
              21.030221742228328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_2BS_Ngõ 59, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2326",
          "diaChiLapD": "Ngõ 59, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030746978096115,
          "to_latitude": 21.030221742228328,
          "to_longitude": 105.82941781389056,
          "from_longitude": 105.82896250149345
        }
      },
      {
        "id": 2320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83085952572442,
            21.028954188539476,
            105.83102508025914,
            21.029652297013186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83085975633695,
              21.028954188539476
            ],
            [
              105.83086343783981,
              21.028965614396824
            ],
            [
              105.83088155453295,
              21.029021822008207
            ],
            [
              105.83091227414944,
              21.029161748363755
            ],
            [
              105.83085952572442,
              21.029173954862333
            ],
            [
              105.83093922941009,
              21.02949446725347
            ],
            [
              105.83094755864433,
              21.02952796053883
            ],
            [
              105.83098934740872,
              21.029634548978738
            ],
            [
              105.83102508025914,
              21.029652297013186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5BS_Ngõ 20, Cát Linh",
          "maTaiSan": "04.O13.DODV.2327",
          "diaChiLapD": "Ngõ 20, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028954188539476,
          "to_latitude": 21.029652297013186,
          "to_longitude": 105.83102508025914,
          "from_longitude": 105.83085975633695
        }
      },
      {
        "id": 2321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336381797607,
            21.02824640591867,
            105.83375955267263,
            21.02856296750442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375955267263,
              21.02824640591867
            ],
            [
              105.83373483124954,
              21.02825946941794
            ],
            [
              105.8336979509704,
              21.028287251842595
            ],
            [
              105.8336577102024,
              21.028328673150288
            ],
            [
              105.83366781044262,
              21.02835981013126
            ],
            [
              105.83368218554709,
              21.028404548568528
            ],
            [
              105.8336807685154,
              21.028424271076478
            ],
            [
              105.83366703471812,
              21.028489824815733
            ],
            [
              105.83364987693732,
              21.028502768125055
            ],
            [
              105.83365336822274,
              21.02851870283742
            ],
            [
              105.83364545780815,
              21.028541757557438
            ],
            [
              105.8336381797607,
              21.02856296750442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X7_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2328",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02824640591867,
          "to_latitude": 21.02856296750442,
          "to_longitude": 105.8336381797607,
          "from_longitude": 105.83375955267263
        }
      },
      {
        "id": 2322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83196481813415,
            21.0312508490429,
            105.83201156038376,
            21.031329007557275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83201156038376,
              21.0312508490429
            ],
            [
              105.83196481813415,
              21.031265280051333
            ],
            [
              105.83198795776245,
              21.031329007557275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2329",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0312508490429,
          "to_latitude": 21.031329007557275,
          "to_longitude": 105.83198795776245,
          "from_longitude": 105.83201156038376
        }
      },
      {
        "id": 2323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83309639760114,
            21.02891736684863,
            105.83355053143609,
            21.029449440338396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83309639760114,
              21.02891736684863
            ],
            [
              105.83330196430187,
              21.02893395798217
            ],
            [
              105.83335133583326,
              21.028954128874904
            ],
            [
              105.83339444289021,
              21.028971740914493
            ],
            [
              105.83344939483949,
              21.029126807936734
            ],
            [
              105.83346822153499,
              21.02919357044441
            ],
            [
              105.83351644641343,
              21.0293645897215
            ],
            [
              105.8335354023272,
              21.02941461575967
            ],
            [
              105.83353686894607,
              21.029417991141614
            ],
            [
              105.83355053143609,
              21.029449440338396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X5_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2330",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02891736684863,
          "to_latitude": 21.029449440338396,
          "to_longitude": 105.83355053143609,
          "from_longitude": 105.83309639760114
        }
      },
      {
        "id": 2324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355053143609,
            21.02941828284219,
            105.83364060575637,
            21.029449440338396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355053143609,
              21.029449440338396
            ],
            [
              105.83364060575637,
              21.02941828284219
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X5_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2331",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029449440338396,
          "to_latitude": 21.02941828284219,
          "to_longitude": 105.83364060575637,
          "from_longitude": 105.83355053143609
        }
      },
      {
        "id": 2325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388359946606,
            21.028778570279986,
            105.83409699000683,
            21.029293890109052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388359946606,
              21.028778570279986
            ],
            [
              105.83389958614899,
              21.028853584965656
            ],
            [
              105.83391700406547,
              21.028903823536577
            ],
            [
              105.8339389616821,
              21.02895888653724
            ],
            [
              105.83396251552843,
              21.029017619630867
            ],
            [
              105.83397347515091,
              21.02904325625948
            ],
            [
              105.83398101264332,
              21.029060381437123
            ],
            [
              105.83401021165504,
              21.029126718168605
            ],
            [
              105.83408774884344,
              21.029276091361158
            ],
            [
              105.83409699000683,
              21.029293890109052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2332",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028778570279986,
          "to_latitude": 21.029293890109052,
          "to_longitude": 105.83409699000683,
          "from_longitude": 105.83388359946606
        }
      },
      {
        "id": 2326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83379026960641,
            21.028823688604625,
            105.83381538565146,
            21.028877876386495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83381538565146,
              21.028877876386495
            ],
            [
              105.83380668769273,
              21.028823688604625
            ],
            [
              105.83379026960641,
              21.02882641465161
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2333",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028877876386495,
          "to_latitude": 21.02882641465161,
          "to_longitude": 105.83379026960641,
          "from_longitude": 105.83381538565146
        }
      },
      {
        "id": 2327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362364006364,
            21.02891241276583,
            105.83366326082364,
            21.02892142879271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366326082364,
              21.02891241276583
            ],
            [
              105.83362364006364,
              21.02892142879271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2334",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02891241276583,
          "to_latitude": 21.02892142879271,
          "to_longitude": 105.83362364006364,
          "from_longitude": 105.83366326082364
        }
      },
      {
        "id": 2328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83472343298627,
            21.029411107469762,
            105.83477270781952,
            21.029438144776584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477270781952,
              21.029411107469762
            ],
            [
              105.83476391645836,
              21.029436086813106
            ],
            [
              105.8347594150595,
              21.029438144776584
            ],
            [
              105.83475243941909,
              21.029435381739603
            ],
            [
              105.83472343298627,
              21.029421315532723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X21_Ngõ 38, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2335",
          "diaChiLapD": "Ngõ 38, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029411107469762,
          "to_latitude": 21.029421315532723,
          "to_longitude": 105.83472343298627,
          "from_longitude": 105.83477270781952
        }
      },
      {
        "id": 2329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83083422950735,
            21.02939182352037,
            105.83090439398576,
            21.02954376415414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83090439398576,
              21.02939182352037
            ],
            [
              105.83083422950735,
              21.029407772270115
            ],
            [
              105.83086883703874,
              21.02954376415414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngách 20/17, Cát Linh",
          "maTaiSan": "04.O13.DODV.2336",
          "diaChiLapD": "Ngách 20/17, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02939182352037,
          "to_latitude": 21.02954376415414,
          "to_longitude": 105.83086883703874,
          "from_longitude": 105.83090439398576
        }
      },
      {
        "id": 2330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83093922941009,
            21.029480401223207,
            105.83111297705287,
            21.029503604334227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83093922941009,
              21.02949446725347
            ],
            [
              105.83102351359892,
              21.029484422471974
            ],
            [
              105.831035321076,
              21.029503604334227
            ],
            [
              105.83111297705287,
              21.029480401223207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5BS_Ngách 20/28, Cát Linh",
          "maTaiSan": "04.O13.DODV.2337",
          "diaChiLapD": "Ngách 20/28, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02949446725347,
          "to_latitude": 21.029480401223207,
          "to_longitude": 105.83111297705287,
          "from_longitude": 105.83093922941009
        }
      },
      {
        "id": 2331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089632769767,
            21.029652297013186,
            105.83103370039966,
            21.030099211589672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83102508025914,
              21.029652297013186
            ],
            [
              105.83103370039966,
              21.029691925373196
            ],
            [
              105.83090668494907,
              21.029749901710552
            ],
            [
              105.83095568829958,
              21.029865566620646
            ],
            [
              105.83090962811146,
              21.02987924341973
            ],
            [
              105.83089632769767,
              21.029888548393043
            ],
            [
              105.83094372215312,
              21.030026174921346
            ],
            [
              105.83095606586178,
              21.030099211589672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5BS_Ngõ 20, Cát Linh",
          "maTaiSan": "04.O13.DODV.2338",
          "diaChiLapD": "Ngõ 20, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029652297013186,
          "to_latitude": 21.030099211589672,
          "to_longitude": 105.83095606586178,
          "from_longitude": 105.83102508025914
        }
      },
      {
        "id": 2332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089225079813,
            21.029717470641046,
            105.83090668494907,
            21.029749901710552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83089225079813,
              21.029717470641046
            ],
            [
              105.83090668494907,
              21.029749901710552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5BS_Ngõ 20, Cát Linh",
          "maTaiSan": "04.O13.DODV.2339",
          "diaChiLapD": "Ngõ 20, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029717470641046,
          "to_latitude": 21.029749901710552,
          "to_longitude": 105.83090668494907,
          "from_longitude": 105.83089225079813
        }
      },
      {
        "id": 2333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334536176388,
            21.028679238245246,
            105.83363345159749,
            21.02873348431744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363345159749,
              21.028679238245246
            ],
            [
              105.83345964001062,
              21.02873348431744
            ],
            [
              105.8334536176388,
              21.028717718264648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X7BS_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2340",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028679238245246,
          "to_latitude": 21.028717718264648,
          "to_longitude": 105.8334536176388,
          "from_longitude": 105.83363345159749
        }
      },
      {
        "id": 2334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440748579568,
            21.0289190448347,
            105.8347977727095,
            21.029158321193055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440748579568,
              21.029158321193055
            ],
            [
              105.8344576984376,
              21.029127703905594
            ],
            [
              105.83467533871654,
              21.02899499545312
            ],
            [
              105.83470462061025,
              21.028976830606553
            ],
            [
              105.8347977727095,
              21.0289190448347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X2_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2341",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029158321193055,
          "to_latitude": 21.0289190448347,
          "to_longitude": 105.8347977727095,
          "from_longitude": 105.83440748579568
        }
      },
      {
        "id": 2335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467671912834,
            21.028926048361097,
            105.83470462061025,
            21.028976830606553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467671912834,
              21.028926048361097
            ],
            [
              105.83467869594057,
              21.028929650647097
            ],
            [
              105.83468553887089,
              21.028942101408084
            ],
            [
              105.83470462061025,
              21.028976830606553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X2_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2342",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028926048361097,
          "to_latitude": 21.028976830606553,
          "to_longitude": 105.83470462061025,
          "from_longitude": 105.83467671912834
        }
      },
      {
        "id": 2336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344576984376,
            21.029127703905594,
            105.83449673901102,
            21.029180533320766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344576984376,
              21.029127703905594
            ],
            [
              105.83449673901102,
              21.029180533320766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X2_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2343",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029127703905594,
          "to_latitude": 21.029180533320766,
          "to_longitude": 105.83449673901102,
          "from_longitude": 105.8344576984376
        }
      },
      {
        "id": 2337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83492479837136,
            21.028987357130287,
            105.83494205631051,
            21.028998543385615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494205631051,
              21.028987357130287
            ],
            [
              105.83493384336587,
              21.028992682216185
            ],
            [
              105.83492479837136,
              21.028998543385615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X2'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2344",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028987357130287,
          "to_latitude": 21.028998543385615,
          "to_longitude": 105.83492479837136,
          "from_longitude": 105.83494205631051
        }
      },
      {
        "id": 2338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83485022012376,
            21.028920237015612,
            105.83504016087485,
            21.02916961277509
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504016087485,
              21.02916961277509
            ],
            [
              105.83503597310111,
              21.029148011985956
            ],
            [
              105.83499706033996,
              21.029094671750894
            ],
            [
              105.83492479837136,
              21.028998543385615
            ],
            [
              105.8348713000686,
              21.028927340335954
            ],
            [
              105.83485022012376,
              21.028920237015612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X2'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2345",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02916961277509,
          "to_latitude": 21.028920237015612,
          "to_longitude": 105.83485022012376,
          "from_longitude": 105.83504016087485
        }
      },
      {
        "id": 2339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83400936197684,
            21.028573509670235,
            105.8340285404194,
            21.028583606933818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400936197684,
              21.028583606933818
            ],
            [
              105.83401371391297,
              21.02858131578867
            ],
            [
              105.8340285404194,
              21.028573509670235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X9_Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2346",
          "diaChiLapD": "Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028583606933818,
          "to_latitude": 21.028573509670235,
          "to_longitude": 105.8340285404194,
          "from_longitude": 105.83400936197684
        }
      },
      {
        "id": 2340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335133583326,
            21.02886603493818,
            105.83349062301127,
            21.028954128874904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335133583326,
              21.028954128874904
            ],
            [
              105.8333583877658,
              21.02890348580296
            ],
            [
              105.83349062301127,
              21.02886603493818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X5_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2347",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028954128874904,
          "to_latitude": 21.02886603493818,
          "to_longitude": 105.83349062301127,
          "from_longitude": 105.83335133583326
        }
      },
      {
        "id": 2341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366423302937,
            21.028853584965656,
            105.83389958614899,
            21.02891683830999
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83389958614899,
              21.028853584965656
            ],
            [
              105.83383161662786,
              21.028867281469527
            ],
            [
              105.83381538565146,
              21.028877876386495
            ],
            [
              105.83366423302937,
              21.02891683830999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2348",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028853584965656,
          "to_latitude": 21.02891683830999,
          "to_longitude": 105.83366423302937,
          "from_longitude": 105.83389958614899
        }
      },
      {
        "id": 2342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83365926298075,
            21.02895888653724,
            105.8339389616821,
            21.029102737010707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339389616821,
              21.02895888653724
            ],
            [
              105.83369011968021,
              21.029009993591593
            ],
            [
              105.83369546560506,
              21.02909612224293
            ],
            [
              105.83365926298075,
              21.029102737010707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2349",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02895888653724,
          "to_latitude": 21.029102737010707,
          "to_longitude": 105.83365926298075,
          "from_longitude": 105.8339389616821
        }
      },
      {
        "id": 2343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366326082364,
            21.02891241276583,
            105.83368019781105,
            21.028989488486427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366326082364,
              21.02891241276583
            ],
            [
              105.83366423302937,
              21.02891683830999
            ],
            [
              105.83368019781105,
              21.028989488486427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2350",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02891241276583,
          "to_latitude": 21.028989488486427,
          "to_longitude": 105.83368019781105,
          "from_longitude": 105.83366326082364
        }
      },
      {
        "id": 2344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358255552784,
            21.028989488486427,
            105.83368019781105,
            21.029005597461115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368019781105,
              21.028989488486427
            ],
            [
              105.83358255552784,
              21.029005597461115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2351",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028989488486427,
          "to_latitude": 21.029005597461115,
          "to_longitude": 105.83358255552784,
          "from_longitude": 105.83368019781105
        }
      },
      {
        "id": 2345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366182531844,
            21.028872253460587,
            105.83366326082364,
            21.02891241276583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366326082364,
              21.02891241276583
            ],
            [
              105.83366182531844,
              21.028872253460587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2352",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02891241276583,
          "to_latitude": 21.028872253460587,
          "to_longitude": 105.83366182531844,
          "from_longitude": 105.83366326082364
        }
      },
      {
        "id": 2346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435773675443,
            21.02887538478389,
            105.83472363570958,
            21.029089205896163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83472363570958,
              21.02887538478389
            ],
            [
              105.83448842829249,
              21.029011228788352
            ],
            [
              105.83442974599608,
              21.02904512028272
            ],
            [
              105.83442095156258,
              21.029050199811543
            ],
            [
              105.83435773675443,
              21.029089205896163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z6_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2353",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02887538478389,
          "to_latitude": 21.029089205896163,
          "to_longitude": 105.83435773675443,
          "from_longitude": 105.83472363570958
        }
      },
      {
        "id": 2347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83442974599608,
            21.02904512028272,
            105.83444703761363,
            21.029071599222515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83442974599608,
              21.02904512028272
            ],
            [
              105.83443145080525,
              21.029047731264146
            ],
            [
              105.83444510189271,
              21.0290686380206
            ],
            [
              105.83444703761363,
              21.029071599222515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z6_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2354",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02904512028272,
          "to_latitude": 21.029071599222515,
          "to_longitude": 105.83444703761363,
          "from_longitude": 105.83442974599608
        }
      },
      {
        "id": 2348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342730976577,
            21.028730673938224,
            105.83429595924599,
            21.028770968368175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83429595924599,
              21.028770968368175
            ],
            [
              105.8342730976577,
              21.028730673938224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z6_Ngõ 18, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2355",
          "diaChiLapD": "Ngõ 18, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028770968368175,
          "to_latitude": 21.028730673938224,
          "to_longitude": 105.8342730976577,
          "from_longitude": 105.83429595924599
        }
      },
      {
        "id": 2349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8339417886734,
            21.02935132138611,
            105.83395734394713,
            21.02937419780539
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83395734394713,
              21.02937419780539
            ],
            [
              105.83395431572715,
              21.02936974441183
            ],
            [
              105.83394635429889,
              21.029358038846333
            ],
            [
              105.8339417886734,
              21.02935132138611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y5_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2356",
          "diaChiLapD": "Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02937419780539,
          "to_latitude": 21.02935132138611,
          "to_longitude": 105.8339417886734,
          "from_longitude": 105.83395734394713
        }
      },
      {
        "id": 2350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098510153148,
            21.02885150297299,
            105.83169576058297,
            21.028989999432422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83098510153148,
              21.028989999432422
            ],
            [
              105.83127471032435,
              21.028932138714502
            ],
            [
              105.83137943549531,
              21.028911215666255
            ],
            [
              105.83169576058297,
              21.02885150297299
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7_Cát Linh",
          "maTaiSan": "04.O13.DODV.2357",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028989999432422,
          "to_latitude": 21.02885150297299,
          "to_longitude": 105.83169576058297,
          "from_longitude": 105.83098510153148
        }
      },
      {
        "id": 2351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83009904963895,
            21.029016517103045,
            105.83086331017886,
            21.029151785478422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83086331017886,
              21.029016517103045
            ],
            [
              105.8305812391816,
              21.029067035938162
            ],
            [
              105.8305211183364,
              21.029078990214494
            ],
            [
              105.83011086627124,
              21.029151785478422
            ],
            [
              105.83010432103355,
              21.0291212803327
            ],
            [
              105.83009904963895,
              21.029096703149317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B4_Cát Linh",
          "maTaiSan": "04.O13.DODV.2358",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029016517103045,
          "to_latitude": 21.029096703149317,
          "to_longitude": 105.83009904963895,
          "from_longitude": 105.83086331017886
        }
      },
      {
        "id": 2352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82979624839574,
            21.02910266613728,
            105.83007379256892,
            21.029199162433706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8300678005309,
              21.02910266613728
            ],
            [
              105.83007379256892,
              21.029127607416
            ],
            [
              105.83004586863277,
              21.029155014769078
            ],
            [
              105.82979624839574,
              21.029199162433706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4C_Cát Linh",
          "maTaiSan": "04.O13.DODV.2359",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02910266613728,
          "to_latitude": 21.029199162433706,
          "to_longitude": 105.82979624839574,
          "from_longitude": 105.8300678005309
        }
      },
      {
        "id": 2353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83002647123655,
            21.029155014769078,
            105.83012934936485,
            21.029597108380152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83004586863277,
              21.029155014769078
            ],
            [
              105.83006163107923,
              21.02923417224224
            ],
            [
              105.83002647123655,
              21.029267223032477
            ],
            [
              105.83012934936485,
              21.029597108380152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4C_Ngõ 32, Cát Linh",
          "maTaiSan": "04.O13.DODV.2360",
          "diaChiLapD": "Ngõ 32, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029155014769078,
          "to_latitude": 21.029597108380152,
          "to_longitude": 105.83012934936485,
          "from_longitude": 105.83004586863277
        }
      },
      {
        "id": 2354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344088959576,
            21.030798398566187,
            105.83442586309353,
            21.030853992677542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344088959576,
              21.030798398566187
            ],
            [
              105.83441403560913,
              21.030815243743454
            ],
            [
              105.83442266565525,
              21.030843517433603
            ],
            [
              105.83442586309353,
              21.030853992677542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y72_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2361",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030798398566187,
          "to_latitude": 21.030853992677542,
          "to_longitude": 105.83442586309353,
          "from_longitude": 105.8344088959576
        }
      },
      {
        "id": 2355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436423459527,
            21.02911449858503,
            105.83453898984195,
            21.02977572163443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436423459527,
              21.02911449858503
            ],
            [
              105.83436566420752,
              21.02911813607583
            ],
            [
              105.8343755772506,
              21.029203572994756
            ],
            [
              105.83446338218178,
              21.029574239489154
            ],
            [
              105.83453898984195,
              21.02977572163443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y2_Ngách 18, Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DODV.2362",
          "diaChiLapD": "Ngách 18, Ngõ Hàng Bột",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02911449858503,
          "to_latitude": 21.02977572163443,
          "to_longitude": 105.83453898984195,
          "from_longitude": 105.83436423459527
        }
      },
      {
        "id": 2356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83295858730102,
            21.030378731072638,
            105.83388864709057,
            21.030729876484134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388864709057,
              21.030378731072638
            ],
            [
              105.83388097546886,
              21.030381774623176
            ],
            [
              105.83318040739918,
              21.030659636181785
            ],
            [
              105.83295858730102,
              21.030729876484134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z10_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2363",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 125,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030378731072638,
          "to_latitude": 21.030729876484134,
          "to_longitude": 105.83295858730102,
          "from_longitude": 105.83388864709057
        }
      },
      {
        "id": 2357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999687398271,
            21.031487814152985,
            105.83010639172815,
            21.031844253365243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010639172815,
              21.031844253365243
            ],
            [
              105.83005326631138,
              21.031679491959146
            ],
            [
              105.83004518383734,
              21.03168168289639
            ],
            [
              105.830043910623,
              21.031677609250714
            ],
            [
              105.83004222998744,
              21.03167223333899
            ],
            [
              105.8300515099135,
              21.031669469331508
            ],
            [
              105.83001654981167,
              21.03159346572797
            ],
            [
              105.82999858963088,
              21.03151792648251
            ],
            [
              105.82999687398271,
              21.031487814152985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2364",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031844253365243,
          "to_latitude": 21.031487814152985,
          "to_longitude": 105.82999687398271,
          "from_longitude": 105.83010639172815
        }
      },
      {
        "id": 2358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82985542443909,
            21.031677609250714,
            105.830043910623,
            21.031729845979868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82985542443909,
              21.031729845979868
            ],
            [
              105.82991532988468,
              21.03171319077975
            ],
            [
              105.8299279155202,
              21.031709755805863
            ],
            [
              105.830043910623,
              21.031677609250714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2365",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031729845979868,
          "to_latitude": 21.031677609250714,
          "to_longitude": 105.830043910623,
          "from_longitude": 105.82985542443909
        }
      },
      {
        "id": 2359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82980562771647,
            21.031350968981727,
            105.83012713454389,
            21.032036643169292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82980562771647,
              21.031350968981727
            ],
            [
              105.82982935450976,
              21.031392154895165
            ],
            [
              105.82988227840362,
              21.03157514081222
            ],
            [
              105.82991079593238,
              21.031698276300155
            ],
            [
              105.82991774308371,
              21.031696932715498
            ],
            [
              105.82992104466581,
              21.031698123306665
            ],
            [
              105.82992392004694,
              21.03170017946003
            ],
            [
              105.82992613548436,
              21.031702933413737
            ],
            [
              105.82992751273687,
              21.031706163844806
            ],
            [
              105.82992793971731,
              21.031709608462297
            ],
            [
              105.8299279155202,
              21.031709755805863
            ],
            [
              105.82992738120957,
              21.03171298834198
            ],
            [
              105.82992588476091,
              21.031716029574973
            ],
            [
              105.82992356830066,
              21.03171848590876
            ],
            [
              105.8299206231213,
              21.03172015589393
            ],
            [
              105.82991728419633,
              21.031720908319244
            ],
            [
              105.82991498504786,
              21.031720757864793
            ],
            [
              105.82994221791243,
              21.031826970492794
            ],
            [
              105.82998503646463,
              21.03198088214428
            ],
            [
              105.83001883469224,
              21.032012973073485
            ],
            [
              105.83006300810376,
              21.032036643169292
            ],
            [
              105.83012713454389,
              21.03202701062285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2366",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031350968981727,
          "to_latitude": 21.03202701062285,
          "to_longitude": 105.83012713454389,
          "from_longitude": 105.82980562771647
        }
      },
      {
        "id": 2360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298869573741,
            21.031826970492794,
            105.82994221791243,
            21.031844880189773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298869573741,
              21.031844880189773
            ],
            [
              105.82991636998975,
              21.031835346709325
            ],
            [
              105.82994221791243,
              21.031826970492794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2367",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031844880189773,
          "to_latitude": 21.031826970492794,
          "to_longitude": 105.82994221791243,
          "from_longitude": 105.8298869573741
        }
      },
      {
        "id": 2361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82948634769454,
            21.03110976293268,
            105.8297859562039,
            21.03134249971582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82948634769454,
              21.03110976293268
            ],
            [
              105.82951463137009,
              21.031131452254424
            ],
            [
              105.82965078150531,
              21.031235857278133
            ],
            [
              105.82967758510092,
              21.031256411215104
            ],
            [
              105.82970311726599,
              21.031275990450947
            ],
            [
              105.8297859562039,
              21.03134249971582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2368",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03110976293268,
          "to_latitude": 21.03134249971582,
          "to_longitude": 105.8297859562039,
          "from_longitude": 105.82948634769454
        }
      },
      {
        "id": 2362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83219930584616,
            21.030427303493486,
            105.83238100839269,
            21.030595938667467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219930584616,
              21.030595938667467
            ],
            [
              105.83231462420993,
              21.030552358197383
            ],
            [
              105.8322805002973,
              21.030457830385057
            ],
            [
              105.83238100839269,
              21.030427303493486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2369",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030595938667467,
          "to_latitude": 21.030427303493486,
          "to_longitude": 105.83238100839269,
          "from_longitude": 105.83219930584616
        }
      },
      {
        "id": 2363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83237147236304,
            21.030389781952422,
            105.83239647246945,
            21.03039952095927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83239647246945,
              21.030389781952422
            ],
            [
              105.83238168915945,
              21.03039554011543
            ],
            [
              105.83237147236304,
              21.03039952095927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2370",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030389781952422,
          "to_latitude": 21.03039952095927,
          "to_longitude": 105.83237147236304,
          "from_longitude": 105.83239647246945
        }
      },
      {
        "id": 2364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323589222462,
            21.030370517569583,
            105.83250070376597,
            21.03077600752135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83250070376597,
              21.03077600752135
            ],
            [
              105.83238100839269,
              21.030427303493486
            ],
            [
              105.83237147236304,
              21.03039952095927
            ],
            [
              105.8323589222462,
              21.030370517569583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2371",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03077600752135,
          "to_latitude": 21.030370517569583,
          "to_longitude": 105.8323589222462,
          "from_longitude": 105.83250070376597
        }
      },
      {
        "id": 2365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83222068072205,
            21.030457830385057,
            105.8322805002973,
            21.030476814489212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8322805002973,
              21.030457830385057
            ],
            [
              105.83222068072205,
              21.030476814489212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2372",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030457830385057,
          "to_latitude": 21.030476814489212,
          "to_longitude": 105.83222068072205,
          "from_longitude": 105.8322805002973
        }
      },
      {
        "id": 2366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197583514321,
            21.03031323786514,
            105.83200181847947,
            21.03040130916753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200181847947,
              21.03031323786514
            ],
            [
              105.83197583514321,
              21.03040130916753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Ngõ 10, Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2373",
          "diaChiLapD": "Ngõ 10, Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03031323786514,
          "to_latitude": 21.03040130916753,
          "to_longitude": 105.83197583514321,
          "from_longitude": 105.83200181847947
        }
      },
      {
        "id": 2367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83192666327402,
            21.030315706998458,
            105.83237059995477,
            21.0304388303115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237059995477,
              21.030315706998458
            ],
            [
              105.83234176021097,
              21.030324720541
            ],
            [
              105.83230992894308,
              21.03033467181668
            ],
            [
              105.83208458758834,
              21.030402787576
            ],
            [
              105.83204038769583,
              21.030388696811208
            ],
            [
              105.83197583514321,
              21.03040130916753
            ],
            [
              105.83193936842996,
              21.030434944802426
            ],
            [
              105.83192666327402,
              21.0304388303115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Ngõ 10, Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2374",
          "diaChiLapD": "Ngõ 10, Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030315706998458,
          "to_latitude": 21.0304388303115,
          "to_longitude": 105.83192666327402,
          "from_longitude": 105.83237059995477
        }
      },
      {
        "id": 2368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83224902772744,
            21.03018154928317,
            105.83230992894308,
            21.03033467181668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83224902772744,
              21.03018154928317
            ],
            [
              105.83230992894308,
              21.03033467181668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2375",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03018154928317,
          "to_latitude": 21.03033467181668,
          "to_longitude": 105.83230992894308,
          "from_longitude": 105.83224902772744
        }
      },
      {
        "id": 2369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524575953663,
            21.030374889079845,
            105.83525253014481,
            21.030377163235265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83525253014481,
              21.030374889079845
            ],
            [
              105.83525026879616,
              21.030375648960124
            ],
            [
              105.83524578546543,
              21.0303771549789
            ],
            [
              105.83524575953663,
              21.030377163235265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7BS_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2376",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030374889079845,
          "to_latitude": 21.030377163235265,
          "to_longitude": 105.83524575953663,
          "from_longitude": 105.83525253014481
        }
      },
      {
        "id": 2370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83523176778826,
            21.030314457067163,
            105.83525401641636,
            21.03032365278108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523176778826,
              21.03032365278108
            ],
            [
              105.83523334189195,
              21.03032300195403
            ],
            [
              105.83524100317631,
              21.030319835555765
            ],
            [
              105.83525401641636,
              21.030314457067163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2377",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03032365278108,
          "to_latitude": 21.030314457067163,
          "to_longitude": 105.83525401641636,
          "from_longitude": 105.83523176778826
        }
      },
      {
        "id": 2371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494304559126,
            21.030575195821203,
            105.83496396557726,
            21.030636432769374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496396557726,
              21.030636432769374
            ],
            [
              105.83494304559126,
              21.030575195821203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y71_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2378",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030636432769374,
          "to_latitude": 21.030575195821203,
          "to_longitude": 105.83494304559126,
          "from_longitude": 105.83496396557726
        }
      },
      {
        "id": 2372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315956062529,
            21.031174918806727,
            105.83317786624227,
            21.03122354354573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315956062529,
              21.031174918806727
            ],
            [
              105.83317227994222,
              21.03120870567253
            ],
            [
              105.83317786624227,
              21.03122354354573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z8_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2379",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031174918806727,
          "to_latitude": 21.03122354354573,
          "to_longitude": 105.83317786624227,
          "from_longitude": 105.83315956062529
        }
      },
      {
        "id": 2373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83429363106464,
            21.029679552805487,
            105.83431263585075,
            21.029739168930455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83431263585075,
              21.029739168930455
            ],
            [
              105.83429363106464,
              21.029679552805487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X4'_Ngách 17, Ngõ Hàng Cháo, Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2380",
          "diaChiLapD": "Ngách 17, Ngõ Hàng Cháo, Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029739168930455,
          "to_latitude": 21.029679552805487,
          "to_longitude": 105.83429363106464,
          "from_longitude": 105.83431263585075
        }
      },
      {
        "id": 2374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83486405951706,
            21.029327279687095,
            105.83507523056204,
            21.029690339290816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83486405951706,
              21.029690339290816
            ],
            [
              105.83490770788944,
              21.029597123708403
            ],
            [
              105.83494264536019,
              21.02953124719378
            ],
            [
              105.83507523056204,
              21.029327279687095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y9_Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2381",
          "diaChiLapD": "Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029690339290816,
          "to_latitude": 21.029327279687095,
          "to_longitude": 105.83507523056204,
          "from_longitude": 105.83486405951706
        }
      },
      {
        "id": 2375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150681617673,
            21.031417358963544,
            105.83157004768685,
            21.031628990198254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157004768685,
              21.031628990198254
            ],
            [
              105.83150681617673,
              21.031417358963544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 171, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2382",
          "diaChiLapD": "Ngõ 171, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.031628990198254,
          "to_latitude": 21.031417358963544,
          "to_longitude": 105.83150681617673,
          "from_longitude": 105.83157004768685
        }
      },
      {
        "id": 2376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83179957974933,
            21.029079000136644,
            105.83193422997763,
            21.02912933526663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83179957974933,
              21.02912933526663
            ],
            [
              105.83183299801628,
              21.029116588076477
            ],
            [
              105.83185900609395,
              21.029107185639877
            ],
            [
              105.83190943247808,
              21.02908829098468
            ],
            [
              105.83193422997763,
              21.029079000136644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B8'_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2383",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02912933526663,
          "to_latitude": 21.029079000136644,
          "to_longitude": 105.83193422997763,
          "from_longitude": 105.83179957974933
        }
      },
      {
        "id": 2377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83200453645703,
            21.029575576646067,
            105.83211208655709,
            21.029612604001766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200453645703,
              21.029612604001766
            ],
            [
              105.83206807227256,
              21.0295927970234
            ],
            [
              105.83211208655709,
              21.029575576646067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B8''_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DODV.2384",
          "diaChiLapD": "Trịnh Hoài Đức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029612604001766,
          "to_latitude": 21.029575576646067,
          "to_longitude": 105.83211208655709,
          "from_longitude": 105.83200453645703
        }
      },
      {
        "id": 2378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023812871573,
            21.032026739625525,
            105.83025096597142,
            21.032074277726984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83025096597142,
              21.032074277726984
            ],
            [
              105.83024672539047,
              21.032058493827723
            ],
            [
              105.83023812871573,
              21.032026739625525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9B_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2385",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.032074277726984,
          "to_latitude": 21.032026739625525,
          "to_longitude": 105.83023812871573,
          "from_longitude": 105.83025096597142
        }
      },
      {
        "id": 2379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83071472837848,
            21.031897420227192,
            105.8307275657714,
            21.03194495828871
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307275657714,
              21.03194495828871
            ],
            [
              105.83072336444155,
              21.0319293214361
            ],
            [
              105.83071472837848,
              21.031897420227192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9A_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2386",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03194495828871,
          "to_latitude": 21.031897420227192,
          "to_longitude": 105.83071472837848,
          "from_longitude": 105.8307275657714
        }
      },
      {
        "id": 2380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316632975027,
            21.02996398348819,
            105.83178043866101,
            21.030298236336083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83173594713078,
              21.030298236336083
            ],
            [
              105.83175376736314,
              21.030292437179458
            ],
            [
              105.83178043866101,
              21.03028375950411
            ],
            [
              105.8316632975027,
              21.02996398348819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2387",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030298236336083,
          "to_latitude": 21.02996398348819,
          "to_longitude": 105.8316632975027,
          "from_longitude": 105.83173594713078
        }
      },
      {
        "id": 2381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316094855989,
            21.03011574900677,
            105.83175723004324,
            21.030272160692466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83172755204929,
              21.030272160692466
            ],
            [
              105.83174193448056,
              21.03026714519017
            ],
            [
              105.83175723004324,
              21.03026181270452
            ],
            [
              105.83170364153592,
              21.03011574900677
            ],
            [
              105.8316094855989,
              21.03015213796344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24'_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2388",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030272160692466,
          "to_latitude": 21.03015213796344,
          "to_longitude": 105.8316094855989,
          "from_longitude": 105.83172755204929
        }
      },
      {
        "id": 2382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82945832798389,
            21.029226346201163,
            105.82962347928311,
            21.030000570813435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82945832798389,
              21.029226346201163
            ],
            [
              105.82946347734932,
              21.02924105898912
            ],
            [
              105.8295328978054,
              21.029619266039006
            ],
            [
              105.82962347928311,
              21.030000570813435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4C''_Cát Linh",
          "maTaiSan": "04.O13.DODV.2389",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029226346201163,
          "to_latitude": 21.030000570813435,
          "to_longitude": 105.82962347928311,
          "from_longitude": 105.82945832798389
        }
      },
      {
        "id": 2383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83006967146008,
            21.029102281262986,
            105.83010060056021,
            21.02924003902485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010060056021,
              21.02924003902485
            ],
            [
              105.830075666548,
              21.029128989232806
            ],
            [
              105.83006967146008,
              21.029102281262986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4C'_Ngõ 32, Cát Linh",
          "maTaiSan": "04.O13.DODV.2390",
          "diaChiLapD": "Ngõ 32, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02924003902485,
          "to_latitude": 21.029102281262986,
          "to_longitude": 105.83006967146008,
          "from_longitude": 105.83010060056021
        }
      },
      {
        "id": 2384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83383777153293,
            21.030564008425458,
            105.83396321377681,
            21.030601718117833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83383777153293,
              21.030601718117833
            ],
            [
              105.83390550560691,
              21.030581356056548
            ],
            [
              105.83394837614506,
              21.03056846725171
            ],
            [
              105.83396321377681,
              21.030564008425458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Z10'_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2391",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030601718117833,
          "to_latitude": 21.030564008425458,
          "to_longitude": 105.83396321377681,
          "from_longitude": 105.83383777153293
        }
      },
      {
        "id": 2385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83430921603822,
            21.03067429611115,
            105.83481400121828,
            21.03083024671209
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430921603822,
              21.03083024671209
            ],
            [
              105.8343580986623,
              21.03081430160225
            ],
            [
              105.8344088959576,
              21.030798398566187
            ],
            [
              105.83443591281068,
              21.030789940973545
            ],
            [
              105.8345273717194,
              21.03076130793368
            ],
            [
              105.83455355346638,
              21.030753360226395
            ],
            [
              105.83481400121828,
              21.03067429611115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Y72_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2392",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03083024671209,
          "to_latitude": 21.03067429611115,
          "to_longitude": 105.83481400121828,
          "from_longitude": 105.83430921603822
        }
      },
      {
        "id": 2386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83486983615117,
            21.029941229907767,
            105.83494844047812,
            21.030052940243422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494844047812,
              21.030052940243422
            ],
            [
              105.83489980056521,
              21.030014822812294
            ],
            [
              105.83491777776025,
              21.029980067033424
            ],
            [
              105.83486983615117,
              21.029941229907767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7'_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2393",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030052940243422,
          "to_latitude": 21.029941229907767,
          "to_longitude": 105.83486983615117,
          "from_longitude": 105.83494844047812
        }
      },
      {
        "id": 2387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483135398123,
            21.030052940243422,
            105.83494844047812,
            21.030092691550134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494844047812,
              21.030052940243422
            ],
            [
              105.83483135398123,
              21.030092691550134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7'_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2394",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030052940243422,
          "to_latitude": 21.030092691550134,
          "to_longitude": 105.83483135398123,
          "from_longitude": 105.83494844047812
        }
      },
      {
        "id": 2388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494844047812,
            21.030052940243422,
            105.83506371348955,
            21.03013661827059
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494844047812,
              21.030052940243422
            ],
            [
              105.83498705044587,
              21.03007830553445
            ],
            [
              105.83502308471284,
              21.03010429675375
            ],
            [
              105.83504952702705,
              21.030121731873116
            ],
            [
              105.83506371348955,
              21.03013129597808
            ],
            [
              105.83505947754104,
              21.030135589026276
            ],
            [
              105.83505846159997,
              21.03013661827059
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y7'_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DODV.2395",
          "diaChiLapD": "Ngõ 139A, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030052940243422,
          "to_latitude": 21.03013661827059,
          "to_longitude": 105.83505846159997,
          "from_longitude": 105.83494844047812
        }
      },
      {
        "id": 2389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318040739918,
            21.030659636181785,
            105.83323505113826,
            21.030820784337934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318040739918,
              21.030659636181785
            ],
            [
              105.83323505113826,
              21.030820784337934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z10_Ngõ Hàng Cháo",
          "maTaiSan": "04.O13.DODV.2396",
          "diaChiLapD": "Ngõ Hàng Cháo",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030659636181785,
          "to_latitude": 21.030820784337934,
          "to_longitude": 105.83323505113826,
          "from_longitude": 105.83318040739918
        }
      },
      {
        "id": 2390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338543979584,
            21.02848386827294,
            105.83395784213845,
            21.028760202821847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338814731915,
              21.028760202821847
            ],
            [
              105.83388310087042,
              21.028722148362856
            ],
            [
              105.83388360712698,
              21.028698845427527
            ],
            [
              105.83388275755924,
              21.028688624202232
            ],
            [
              105.83386445108461,
              21.02858275774643
            ],
            [
              105.8338638716415,
              21.028579416855024
            ],
            [
              105.83385585369965,
              21.028533505587784
            ],
            [
              105.8338543979584,
              21.028525167845665
            ],
            [
              105.83388004134613,
              21.028515447153033
            ],
            [
              105.83395326652878,
              21.02848572606316
            ],
            [
              105.83395784213845,
              21.02848386827294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X8_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2397",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028760202821847,
          "to_latitude": 21.02848386827294,
          "to_longitude": 105.83395784213845,
          "from_longitude": 105.8338814731915
        }
      },
      {
        "id": 2391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373897114971,
            21.028533505587784,
            105.83385585369965,
            21.02855079105448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385585369965,
              21.028533505587784
            ],
            [
              105.83385563353379,
              21.028533530151233
            ],
            [
              105.83379355927568,
              21.028540457874424
            ],
            [
              105.83373897114971,
              21.02855079105448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X8_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2398",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028533505587784,
          "to_latitude": 21.02855079105448,
          "to_longitude": 105.83373897114971,
          "from_longitude": 105.83385585369965
        }
      },
      {
        "id": 2392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374129110055,
            21.02858275774643,
            105.83386445108461,
            21.0286245639978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386445108461,
              21.02858275774643
            ],
            [
              105.83380834020588,
              21.028592942158696
            ],
            [
              105.83379251985819,
              21.02861180587593
            ],
            [
              105.83374129110055,
              21.0286245639978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X8_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2399",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02858275774643,
          "to_latitude": 21.0286245639978,
          "to_longitude": 105.83374129110055,
          "from_longitude": 105.83386445108461
        }
      },
      {
        "id": 2393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363345159749,
            21.028679238245246,
            105.83367797687161,
            21.028830944836734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363345159749,
              21.028679238245246
            ],
            [
              105.83367797687161,
              21.028830944836734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X7BS_Ngõ 3, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.2400",
          "diaChiLapD": "Ngõ 3, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028679238245246,
          "to_latitude": 21.028830944836734,
          "to_longitude": 105.83367797687161,
          "from_longitude": 105.83363345159749
        }
      },
      {
        "id": 2394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83319233520734,
            21.02810210792903,
            105.8336663185158,
            21.0282678259451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83319233520734,
              21.0282678259451
            ],
            [
              105.8336663185158,
              21.02810210792903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_X6_Cát Linh",
          "maTaiSan": "04.O13.DODV.2401",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0282678259451,
          "to_latitude": 21.02810210792903,
          "to_longitude": 105.8336663185158,
          "from_longitude": 105.83319233520734
        }
      },
      {
        "id": 2395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83087412701447,
            21.02895192890596,
            105.83097035375037,
            21.029285524138135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83087412701447,
              21.02895192890596
            ],
            [
              105.830876160266,
              21.02896121675703
            ],
            [
              105.83091970576147,
              21.029160008568386
            ],
            [
              105.83093637178473,
              21.029182032626533
            ],
            [
              105.83097035375037,
              21.029285524138135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B6_Ngõ 20, Cát Linh",
          "maTaiSan": "04.O13.DODV.2402",
          "diaChiLapD": "Ngõ 20, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02895192890596,
          "to_latitude": 21.029285524138135,
          "to_longitude": 105.83097035375037,
          "from_longitude": 105.83087412701447
        }
      },
      {
        "id": 2396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091970576147,
            21.029131840190264,
            105.83108539291413,
            21.029160008568386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83091970576147,
              21.029160008568386
            ],
            [
              105.83100838774703,
              21.029144340514947
            ],
            [
              105.83108539291413,
              21.029131840190264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B6_Ngách 20/2, Cát Linh",
          "maTaiSan": "04.O13.DODV.2403",
          "diaChiLapD": "Ngách 20/2, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029160008568386,
          "to_latitude": 21.029131840190264,
          "to_longitude": 105.83108539291413,
          "from_longitude": 105.83091970576147
        }
      },
      {
        "id": 2397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100838774703,
            21.029144340514947,
            105.83117218743313,
            21.029377026289314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100838774703,
              21.029144340514947
            ],
            [
              105.83107363037057,
              21.029377026289314
            ],
            [
              105.83117218743313,
              21.02936339994207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B6_Ngách 20/2, Cát Linh",
          "maTaiSan": "04.O13.DODV.2404",
          "diaChiLapD": "Ngách 20/2, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029144340514947,
          "to_latitude": 21.02936339994207,
          "to_longitude": 105.83117218743313,
          "from_longitude": 105.83100838774703
        }
      },
      {
        "id": 2398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82902091558256,
            21.03075120760739,
            105.82923557933324,
            21.030909636751563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82902091558256,
              21.03075120760739
            ],
            [
              105.82921910744221,
              21.030897480207162
            ],
            [
              105.82923557933324,
              21.030909636751563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2405",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03075120760739,
          "to_latitude": 21.030909636751563,
          "to_longitude": 105.82923557933324,
          "from_longitude": 105.82902091558256
        }
      },
      {
        "id": 2399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919664782389,
            21.030897480207162,
            105.82921910744221,
            21.030926878581923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919664782389,
              21.030926878581923
            ],
            [
              105.8292014071841,
              21.030920649319793
            ],
            [
              105.82921274825553,
              21.030905802815965
            ],
            [
              105.82921910744221,
              21.030897480207162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2406",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030926878581923,
          "to_latitude": 21.030897480207162,
          "to_longitude": 105.82921910744221,
          "from_longitude": 105.82919664782389
        }
      },
      {
        "id": 2400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82923557933324,
            21.030909636751563,
            105.82937362990711,
            21.031013319195708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82923557933324,
              21.030909636751563
            ],
            [
              105.82937362990711,
              21.031013319195708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2407",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030909636751563,
          "to_latitude": 21.031013319195708,
          "to_longitude": 105.82937362990711,
          "from_longitude": 105.82923557933324
        }
      },
      {
        "id": 2401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83101551448549,
            21.02998283460803,
            105.83171932829896,
            21.030273426632384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101551448549,
              21.030010021261027
            ],
            [
              105.83121001788844,
              21.02998283460803
            ],
            [
              105.83128439002364,
              21.03024779658437
            ],
            [
              105.83134051565798,
              21.030273426632384
            ],
            [
              105.83161494959556,
              21.030225246156412
            ],
            [
              105.83167175556608,
              21.030198888637
            ],
            [
              105.83167790464839,
              21.030211852334574
            ],
            [
              105.83169198787812,
              21.03024202969662
            ],
            [
              105.83169641519737,
              21.03025296143308
            ],
            [
              105.83170649429836,
              21.03025016994526
            ],
            [
              105.83171932829896,
              21.030246620725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_24_Lý Văn Phức",
          "maTaiSan": "04.O13.DODV.2408",
          "diaChiLapD": "Lý Văn Phức",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 118,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030010021261027,
          "to_latitude": 21.030246620725,
          "to_longitude": 105.83171932829896,
          "from_longitude": 105.83101551448549
        }
      },
      {
        "id": 2402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8261464459964,
            21.027576334131574,
            105.82634089786322,
            21.027798400103492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82634089786322,
              21.027576334131574
            ],
            [
              105.82622932056151,
              21.027703756790878
            ],
            [
              105.82620783258987,
              21.027728296133706
            ],
            [
              105.8261464459964,
              21.027798400103492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2409",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027576334131574,
          "to_latitude": 21.027798400103492,
          "to_longitude": 105.8261464459964,
          "from_longitude": 105.82634089786322
        }
      },
      {
        "id": 2403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587028028661,
            21.02778230544312,
            105.82613694864214,
            21.027944778699467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613694864214,
              21.027806718879667
            ],
            [
              105.82613605212397,
              21.027807780911353
            ],
            [
              105.8260937744962,
              21.027857899404168
            ],
            [
              105.82599307252394,
              21.02778230544312
            ],
            [
              105.82587028028661,
              21.027944778699467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H9A_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2410",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027806718879667,
          "to_latitude": 21.027944778699467,
          "to_longitude": 105.82587028028661,
          "from_longitude": 105.82613694864214
        }
      },
      {
        "id": 2404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82526091859516,
            21.027238696480993,
            105.82570206646078,
            21.027546878712972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82526091859516,
              21.027238696480993
            ],
            [
              105.82532481154652,
              21.02729092428447
            ],
            [
              105.82537508096172,
              21.02726810509735
            ],
            [
              105.82556851018924,
              21.02741311826298
            ],
            [
              105.82562100683798,
              21.027475392749306
            ],
            [
              105.82570206646078,
              21.027546878712972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Ngõ 7, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2411",
          "diaChiLapD": "Ngõ 7, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027238696480993,
          "to_latitude": 21.027546878712972,
          "to_longitude": 105.82570206646078,
          "from_longitude": 105.82526091859516
        }
      },
      {
        "id": 2405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545169769048,
            21.027065813659995,
            105.82564532968378,
            21.027215538790184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82545169769048,
              21.027065813659995
            ],
            [
              105.82545779683228,
              21.027071052565443
            ],
            [
              105.82546449455235,
              21.027076853080686
            ],
            [
              105.82546673808467,
              21.02707879676903
            ],
            [
              105.82552844834512,
              21.027132093671597
            ],
            [
              105.82564532968378,
              21.027215538790184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 23, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2412",
          "diaChiLapD": "Ngõ 23, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027065813659995,
          "to_latitude": 21.027215538790184,
          "to_longitude": 105.82564532968378,
          "from_longitude": 105.82545169769048
        }
      },
      {
        "id": 2406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564125438704,
            21.027377588091447,
            105.8258667263084,
            21.02755429393705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82564125438704,
              21.027377588091447
            ],
            [
              105.8258667263084,
              21.02755429393705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 23, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2413",
          "diaChiLapD": "Ngõ 23, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027377588091447,
          "to_latitude": 21.02755429393705,
          "to_longitude": 105.8258667263084,
          "from_longitude": 105.82564125438704
        }
      },
      {
        "id": 2407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82544828935443,
            21.027132093671597,
            105.82552844834512,
            21.027219043349078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552844834512,
              21.027132093671597
            ],
            [
              105.82544828935443,
              21.027219043349078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 23, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2414",
          "diaChiLapD": "Ngõ 23, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027132093671597,
          "to_latitude": 21.027219043349078,
          "to_longitude": 105.82544828935443,
          "from_longitude": 105.82552844834512
        }
      },
      {
        "id": 2408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.825153717152,
            21.02701371910495,
            105.82554494815733,
            21.02734433853231
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825153717152,
              21.02734433853231
            ],
            [
              105.82523359443942,
              21.02726488983228
            ],
            [
              105.82525250029208,
              21.027246085069695
            ],
            [
              105.82526091859516,
              21.027238696480993
            ],
            [
              105.82527447427167,
              21.027226805673855
            ],
            [
              105.82541047496885,
              21.027106951520697
            ],
            [
              105.82545318337247,
              21.027079676370807
            ],
            [
              105.82546198648328,
              21.02707910525305
            ],
            [
              105.82546449455235,
              21.027076853080686
            ],
            [
              105.82546696720189,
              21.02707463269307
            ],
            [
              105.82546719807405,
              21.027070437898928
            ],
            [
              105.82554494815733,
              21.02701371910495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2415",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02734433853231,
          "to_latitude": 21.02701371910495,
          "to_longitude": 105.82554494815733,
          "from_longitude": 105.825153717152
        }
      },
      {
        "id": 2409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82609508974171,
            21.02735623022395,
            105.8263192959293,
            21.027541275659274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8263192959293,
              21.027541275659274
            ],
            [
              105.82619284669424,
              21.02743809869426
            ],
            [
              105.82611241466645,
              21.02737246913917
            ],
            [
              105.82609508974171,
              21.02735623022395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H8_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2416",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027541275659274,
          "to_latitude": 21.02735623022395,
          "to_longitude": 105.82609508974171,
          "from_longitude": 105.8263192959293
        }
      },
      {
        "id": 2410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8261875277786,
            21.02743809869426,
            105.82619284669424,
            21.027445614979285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8261875277786,
              21.027445614979285
            ],
            [
              105.82618864177205,
              21.027444040665525
            ],
            [
              105.82619284669424,
              21.02743809869426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H8_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2417",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027445614979285,
          "to_latitude": 21.02743809869426,
          "to_longitude": 105.82619284669424,
          "from_longitude": 105.8261875277786
        }
      },
      {
        "id": 2411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569706263357,
            21.027020407800695,
            105.82591091335175,
            21.027170994578285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82591091335175,
              21.027170994578285
            ],
            [
              105.82587419333846,
              21.02715021448537
            ],
            [
              105.82576071696388,
              21.027070523052352
            ],
            [
              105.82569706263357,
              21.027020407800695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2418",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027170994578285,
          "to_latitude": 21.027020407800695,
          "to_longitude": 105.82569706263357,
          "from_longitude": 105.82591091335175
        }
      },
      {
        "id": 2412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564112299376,
            21.026789654995444,
            105.82577500022498,
            21.02693427623058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82566451669743,
              21.02693427623058
            ],
            [
              105.82564755182779,
              21.026908460430395
            ],
            [
              105.82564470546622,
              21.026904135157373
            ],
            [
              105.82564112299376,
              21.026898730491506
            ],
            [
              105.82577500022498,
              21.026810711498932
            ],
            [
              105.82577101599237,
              21.026789654995444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K1_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2419",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02693427623058,
          "to_latitude": 21.026789654995444,
          "to_longitude": 105.82577101599237,
          "from_longitude": 105.82566451669743
        }
      },
      {
        "id": 2413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82621925368711,
            21.027695665841485,
            105.82622932056151,
            21.027703756790878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82622932056151,
              21.027703756790878
            ],
            [
              105.82622758510144,
              21.027702363418218
            ],
            [
              105.82622290822151,
              21.027698603436622
            ],
            [
              105.82621925368711,
              21.027695665841485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2420",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027703756790878,
          "to_latitude": 21.027695665841485,
          "to_longitude": 105.82621925368711,
          "from_longitude": 105.82622932056151
        }
      },
      {
        "id": 2414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256244727893,
            21.02615312897743,
            105.82577501723746,
            21.026502416355925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825647475465,
              21.02615312897743
            ],
            [
              105.82564273694699,
              21.026155283594118
            ],
            [
              105.82563832085827,
              21.026157292129266
            ],
            [
              105.8256244727893,
              21.0261637560005
            ],
            [
              105.82577501723746,
              21.026502416355925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K4_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2421",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02615312897743,
          "to_latitude": 21.026502416355925,
          "to_longitude": 105.82577501723746,
          "from_longitude": 105.825647475465
        }
      },
      {
        "id": 2415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82542253722056,
            21.02593371668628,
            105.82563832085827,
            21.026157292129266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82563832085827,
              21.026157292129266
            ],
            [
              105.8255704079409,
              21.02600861137819
            ],
            [
              105.82554157212752,
              21.026019319094015
            ],
            [
              105.82550215783529,
              21.02593371668628
            ],
            [
              105.82542253722056,
              21.02596545771293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K4_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2422",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026157292129266,
          "to_latitude": 21.02596545771293,
          "to_longitude": 105.82542253722056,
          "from_longitude": 105.82563832085827
        }
      },
      {
        "id": 2416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256661597604,
            21.026546341264492,
            105.82577101599237,
            21.026789654995444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82577101599237,
              21.026789654995444
            ],
            [
              105.8256661597604,
              21.026546341264492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K1_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2423",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026789654995444,
          "to_latitude": 21.026546341264492,
          "to_longitude": 105.8256661597604,
          "from_longitude": 105.82577101599237
        }
      },
      {
        "id": 2417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8255904144492,
            21.026384123992855,
            105.8256661597604,
            21.026546341264492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256661597604,
              21.026546341264492
            ],
            [
              105.8255904144492,
              21.026384123992855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K1_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2424",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026546341264492,
          "to_latitude": 21.026384123992855,
          "to_longitude": 105.8255904144492,
          "from_longitude": 105.8256661597604
        }
      },
      {
        "id": 2418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552824286856,
            21.026475500696197,
            105.8256661597604,
            21.026580797634633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256661597604,
              21.026546341264492
            ],
            [
              105.82558036521543,
              21.026580797634633
            ],
            [
              105.82552824286856,
              21.026475500696197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K1_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2425",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026546341264492,
          "to_latitude": 21.026475500696197,
          "to_longitude": 105.82552824286856,
          "from_longitude": 105.8256661597604
        }
      },
      {
        "id": 2419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82607380117713,
            21.026949526083026,
            105.82647524584179,
            21.027345852276017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82607831921732,
              21.02734283467508
            ],
            [
              105.82607710913908,
              21.02734443022907
            ],
            [
              105.82607652272429,
              21.027345203528522
            ],
            [
              105.82607740461756,
              21.027345852276017
            ],
            [
              105.82607793347732,
              21.027345109062566
            ],
            [
              105.82607907997478,
              21.02734349755938
            ],
            [
              105.82608032192938,
              21.027341751011992
            ],
            [
              105.82607380117713,
              21.027336298307304
            ],
            [
              105.82607749451324,
              21.027331615209675
            ],
            [
              105.82636179097747,
              21.027007790798113
            ],
            [
              105.82647524584179,
              21.026949526083026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngách 29/16, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2426",
          "diaChiLapD": "Ngách 29/16, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02734283467508,
          "to_latitude": 21.026949526083026,
          "to_longitude": 105.82647524584179,
          "from_longitude": 105.82607831921732
        }
      },
      {
        "id": 2420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82647524584179,
            21.02694896578166,
            105.82669123340003,
            21.027024836415155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82647524584179,
              21.026949526083026
            ],
            [
              105.82655675570915,
              21.027024836415155
            ],
            [
              105.82668857625347,
              21.026950173665266
            ],
            [
              105.82669123340003,
              21.02694896578166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngách 29/16, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2427",
          "diaChiLapD": "Ngách 29/16, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026949526083026,
          "to_latitude": 21.02694896578166,
          "to_longitude": 105.82669123340003,
          "from_longitude": 105.82647524584179
        }
      },
      {
        "id": 2421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82668857625347,
            21.026950173665266,
            105.82679912829765,
            21.02710919085003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82668857625347,
              21.026950173665266
            ],
            [
              105.82679912829765,
              21.02710919085003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngách 29/16, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2428",
          "diaChiLapD": "Ngách 29/16, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026950173665266,
          "to_latitude": 21.02710919085003,
          "to_longitude": 105.82679912829765,
          "from_longitude": 105.82668857625347
        }
      },
      {
        "id": 2422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82597779806751,
            21.027857899404168,
            105.8260937744962,
            21.027991365979812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8260937744962,
              21.027857899404168
            ],
            [
              105.82597779806751,
              21.027991365979812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H9A_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2429",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027857899404168,
          "to_latitude": 21.027991365979812,
          "to_longitude": 105.82597779806751,
          "from_longitude": 105.8260937744962
        }
      },
      {
        "id": 2423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82576821611804,
            21.028072450221458,
            105.82604770945586,
            21.02825717419929
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82604770945586,
              21.02825717419929
            ],
            [
              105.82583777098513,
              21.028117849937747
            ],
            [
              105.82576821611804,
              21.028072450221458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1_Ngách 149/4, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2430",
          "diaChiLapD": "Ngách 149/4, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02825717419929,
          "to_latitude": 21.028072450221458,
          "to_longitude": 105.82576821611804,
          "from_longitude": 105.82604770945586
        }
      },
      {
        "id": 2424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82620718795589,
            21.02860602684907,
            105.82644105891681,
            21.02877260562143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82620718795589,
              21.02860602684907
            ],
            [
              105.82644105891681,
              21.02877260562143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2431",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02860602684907,
          "to_latitude": 21.02877260562143,
          "to_longitude": 105.82644105891681,
          "from_longitude": 105.82620718795589
        }
      },
      {
        "id": 2425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265677280702,
            21.02862565129415,
            105.82690453659288,
            21.0288159056221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265677280702,
              21.02862565129415
            ],
            [
              105.82660315591538,
              21.02864753762981
            ],
            [
              105.82665375842488,
              21.028678793400857
            ],
            [
              105.82677712610996,
              21.028751336737344
            ],
            [
              105.8268401466087,
              21.028795870891646
            ],
            [
              105.8268562936384,
              21.02877868620559
            ],
            [
              105.82690453659288,
              21.0288159056221
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2432",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02862565129415,
          "to_latitude": 21.0288159056221,
          "to_longitude": 105.82690453659288,
          "from_longitude": 105.8265677280702
        }
      },
      {
        "id": 2426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82665375842488,
            21.02855681317475,
            105.82675848761998,
            21.028678793400857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82665375842488,
              21.028678793400857
            ],
            [
              105.82675848761998,
              21.02855681317475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2433",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028678793400857,
          "to_latitude": 21.02855681317475,
          "to_longitude": 105.82675848761998,
          "from_longitude": 105.82665375842488
        }
      },
      {
        "id": 2427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82677712610996,
            21.028691279349797,
            105.82697560950227,
            21.028778699391875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82677712610996,
              21.028751336737344
            ],
            [
              105.82681858796158,
              21.028691279349797
            ],
            [
              105.82697560950227,
              21.028778699391875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2434",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028751336737344,
          "to_latitude": 21.028778699391875,
          "to_longitude": 105.82697560950227,
          "from_longitude": 105.82677712610996
        }
      },
      {
        "id": 2428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82702416384825,
            21.028841212953083,
            105.82703809433724,
            21.028963191626826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702416384825,
              21.028963191626826
            ],
            [
              105.82703809433724,
              21.028841212953083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2435",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028963191626826,
          "to_latitude": 21.028841212953083,
          "to_longitude": 105.82703809433724,
          "from_longitude": 105.82702416384825
        }
      },
      {
        "id": 2429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82655013222644,
            21.028422557441864,
            105.82669838374063,
            21.028609864459117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82655013222644,
              21.028609864459117
            ],
            [
              105.82661895406306,
              21.028512310431964
            ],
            [
              105.82669838374063,
              21.028422557441864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D9_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2436",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028609864459117,
          "to_latitude": 21.028422557441864,
          "to_longitude": 105.82669838374063,
          "from_longitude": 105.82655013222644
        }
      },
      {
        "id": 2430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82641688285324,
            21.028620478906817,
            105.82656467687629,
            21.028802363339103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82641688285324,
              21.028802363339103
            ],
            [
              105.82642563279849,
              21.02879160856972
            ],
            [
              105.82644105891681,
              21.02877260562143
            ],
            [
              105.82656467687629,
              21.028620478906817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2437",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028802363339103,
          "to_latitude": 21.028620478906817,
          "to_longitude": 105.82656467687629,
          "from_longitude": 105.82641688285324
        }
      },
      {
        "id": 2431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82635689918501,
            21.02846878328416,
            105.82656467687629,
            21.028620478906817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656467687629,
              21.028620478906817
            ],
            [
              105.82655013222644,
              21.028609864459117
            ],
            [
              105.82635689918501,
              21.02846878328416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2438",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028620478906817,
          "to_latitude": 21.02846878328416,
          "to_longitude": 105.82635689918501,
          "from_longitude": 105.82656467687629
        }
      },
      {
        "id": 2432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590764907302,
            21.028318539198068,
            105.82600068547693,
            21.028440851471736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590764907302,
              21.028440851471736
            ],
            [
              105.8259258323825,
              21.02841696137624
            ],
            [
              105.82600068547693,
              21.028318539198068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1_Ngõ 149, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2439",
          "diaChiLapD": "Ngõ 149, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028440851471736,
          "to_latitude": 21.028318539198068,
          "to_longitude": 105.82600068547693,
          "from_longitude": 105.82590764907302
        }
      },
      {
        "id": 2433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82600068547693,
            21.028162970316547,
            105.82611990064697,
            21.028318539198068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600068547693,
              21.028318539198068
            ],
            [
              105.82604770945586,
              21.02825717419929
            ],
            [
              105.82611990064697,
              21.028162970316547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1_Ngõ 149, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2440",
          "diaChiLapD": "Ngõ 149, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028318539198068,
          "to_latitude": 21.028162970316547,
          "to_longitude": 105.82611990064697,
          "from_longitude": 105.82600068547693
        }
      },
      {
        "id": 2434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257889043577,
            21.028188758269824,
            105.82600068547693,
            21.028318539198068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600068547693,
              21.028318539198068
            ],
            [
              105.8258275650323,
              21.028198541217677
            ],
            [
              105.82580966274651,
              21.028201495897555
            ],
            [
              105.8257889043577,
              21.028188758269824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1_Ngách 149/2, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2441",
          "diaChiLapD": "Ngách 149/2, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028318539198068,
          "to_latitude": 21.028188758269824,
          "to_longitude": 105.8257889043577,
          "from_longitude": 105.82600068547693
        }
      },
      {
        "id": 2435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82432862003498,
            21.02673852234607,
            105.82433588154511,
            21.026746172637115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82433588154511,
              21.02673852234607
            ],
            [
              105.8243326027913,
              21.026741980430746
            ],
            [
              105.82432862003498,
              21.026746172637115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2442",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02673852234607,
          "to_latitude": 21.026746172637115,
          "to_longitude": 105.82432862003498,
          "from_longitude": 105.82433588154511
        }
      },
      {
        "id": 2436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82434919337534,
            21.02646108964797,
            105.82459684797273,
            21.026662641698938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82436007429324,
              21.02646108964797
            ],
            [
              105.82435671748621,
              21.026464522821097
            ],
            [
              105.82434919337534,
              21.026469551419826
            ],
            [
              105.82435410624872,
              21.026473381666726
            ],
            [
              105.82459684797273,
              21.026662641698938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2443",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02646108964797,
          "to_latitude": 21.026662641698938,
          "to_longitude": 105.82459684797273,
          "from_longitude": 105.82436007429324
        }
      },
      {
        "id": 2437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254301114092,
            21.027609525270677,
            105.82543774000578,
            21.027618643323503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82543774000578,
              21.027609525270677
            ],
            [
              105.82543357020492,
              21.027614514151374
            ],
            [
              105.8254301114092,
              21.027618643323503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT66_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2444",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027609525270677,
          "to_latitude": 21.027618643323503,
          "to_longitude": 105.8254301114092,
          "from_longitude": 105.82543774000578
        }
      },
      {
        "id": 2438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8262776490037,
            21.025443123157846,
            105.8266153651716,
            21.026515947471182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8262776490037,
              21.025443123157846
            ],
            [
              105.8266153651716,
              21.026187219683834
            ],
            [
              105.82649126721418,
              21.02630811252731
            ],
            [
              105.82655009500787,
              21.02644527575562
            ],
            [
              105.8265893140638,
              21.026503187777152
            ],
            [
              105.82659671831459,
              21.02651412054976
            ],
            [
              105.8265977096363,
              21.026515947471182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6*_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2445",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 134.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025443123157846,
          "to_latitude": 21.026515947471182,
          "to_longitude": 105.8265977096363,
          "from_longitude": 105.8262776490037
        }
      },
      {
        "id": 2439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82691055206297,
            21.02570973634478,
            105.82716608370559,
            21.02632862611157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82716608370559,
              21.02632862611157
            ],
            [
              105.82715369450592,
              21.026305350657744
            ],
            [
              105.82714783954356,
              21.026289101219344
            ],
            [
              105.82713171665849,
              21.026244353497216
            ],
            [
              105.82703219570837,
              21.02600031469637
            ],
            [
              105.82691055206297,
              21.02570973634478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4B_Ngõ 18, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2446",
          "diaChiLapD": "Ngõ 18, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02632862611157,
          "to_latitude": 21.02570973634478,
          "to_longitude": 105.82691055206297,
          "from_longitude": 105.82716608370559
        }
      },
      {
        "id": 2440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82662875221851,
            21.02570216316484,
            105.82685474876823,
            21.025781372711236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82685474876823,
              21.02570216316484
            ],
            [
              105.82662875221851,
              21.025781372711236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Ngõ 8C, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2447",
          "diaChiLapD": "Ngõ 8C, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02570216316484,
          "to_latitude": 21.025781372711236,
          "to_longitude": 105.82662875221851,
          "from_longitude": 105.82685474876823
        }
      },
      {
        "id": 2441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82662875221851,
            21.025781372711236,
            105.8269044648591,
            21.026408820400455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82662875221851,
              21.025781372711236
            ],
            [
              105.82685990195928,
              21.02635652539814
            ],
            [
              105.82688837414484,
              21.026388556211945
            ],
            [
              105.82689949275142,
              21.02640106419891
            ],
            [
              105.8269044648591,
              21.026408820400455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Ngõ 8C, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2448",
          "diaChiLapD": "Ngõ 8C, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025781372711236,
          "to_latitude": 21.026408820400455,
          "to_longitude": 105.8269044648591,
          "from_longitude": 105.82662875221851
        }
      },
      {
        "id": 2442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82610904573107,
            21.02543406420357,
            105.82651442478873,
            21.026551669754657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82650094387971,
              21.026551669754657
            ],
            [
              105.82649965528657,
              21.026547921311764
            ],
            [
              105.82649650903933,
              21.026538760741815
            ],
            [
              105.82647809013842,
              21.026485138187116
            ],
            [
              105.82651442478873,
              21.026414925543225
            ],
            [
              105.82644310377758,
              21.02625146962308
            ],
            [
              105.82610904573107,
              21.025513640513385
            ],
            [
              105.82611150765668,
              21.025489578381993
            ],
            [
              105.8262543301935,
              21.02543406420357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2449",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 138,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026551669754657,
          "to_latitude": 21.02543406420357,
          "to_longitude": 105.8262543301935,
          "from_longitude": 105.82650094387971
        }
      },
      {
        "id": 2443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589245801745,
            21.027198086570106,
            105.82590873069903,
            21.02721986919236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590873069903,
              21.027198086570106
            ],
            [
              105.82590567097621,
              21.027202182200405
            ],
            [
              105.82590042440654,
              21.027209211287236
            ],
            [
              105.82589310071224,
              21.027219008910595
            ],
            [
              105.82589245801745,
              21.02721986919236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT56_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2450",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027198086570106,
          "to_latitude": 21.02721986919236,
          "to_longitude": 105.82589245801745,
          "from_longitude": 105.82590873069903
        }
      },
      {
        "id": 2444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82689003980956,
            21.025718553105058,
            105.82716228731461,
            21.026329789866157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82716228731461,
              21.026329789866157
            ],
            [
              105.82715240300824,
              21.026306657583103
            ],
            [
              105.82713502128996,
              21.02630052161666
            ],
            [
              105.82713269015774,
              21.026294907671094
            ],
            [
              105.8269400114459,
              21.02583094045307
            ],
            [
              105.82689003980956,
              21.025718553105058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4A_Ngõ 18, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2451",
          "diaChiLapD": "Ngõ 18, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026329789866157,
          "to_latitude": 21.025718553105058,
          "to_longitude": 105.82689003980956,
          "from_longitude": 105.82716228731461
        }
      },
      {
        "id": 2445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82685990195928,
            21.026281972840415,
            105.82703903866054,
            21.02635652539814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82685990195928,
              21.02635652539814
            ],
            [
              105.82703903866054,
              21.026281972840415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E3_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2452",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02635652539814,
          "to_latitude": 21.026281972840415,
          "to_longitude": 105.82703903866054,
          "from_longitude": 105.82685990195928
        }
      },
      {
        "id": 2446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82713171665849,
            21.026189144869463,
            105.82727263737561,
            21.026244353497216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82713171665849,
              21.026244353497216
            ],
            [
              105.82727263737561,
              21.026189144869463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E4B_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2453",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026244353497216,
          "to_latitude": 21.026189144869463,
          "to_longitude": 105.82727263737561,
          "from_longitude": 105.82713171665849
        }
      },
      {
        "id": 2447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556412161884,
            21.027851441650963,
            105.82575562950326,
            21.028083899644706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556412161884,
              21.028083899644706
            ],
            [
              105.8256845996795,
              21.02795321057596
            ],
            [
              105.82575562950326,
              21.027851441650963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G9_Ngõ 173, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2454",
          "diaChiLapD": "Ngõ 173, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028083899644706,
          "to_latitude": 21.027851441650963,
          "to_longitude": 105.82575562950326,
          "from_longitude": 105.82556412161884
        }
      },
      {
        "id": 2448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82575562950326,
            21.027734989745248,
            105.82590070551407,
            21.027851441650963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82575562950326,
              21.027851441650963
            ],
            [
              105.82577092406864,
              21.027834928688197
            ],
            [
              105.82582392151062,
              21.02783125261412
            ],
            [
              105.82590070551407,
              21.027734989745248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G9_Ngõ 173, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2455",
          "diaChiLapD": "Ngõ 173, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027851441650963,
          "to_latitude": 21.027734989745248,
          "to_longitude": 105.82590070551407,
          "from_longitude": 105.82575562950326
        }
      },
      {
        "id": 2449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569998601807,
            21.027807001313775,
            105.82575562950326,
            21.027851441650963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82575562950326,
              21.027851441650963
            ],
            [
              105.82571041287501,
              21.027815304610694
            ],
            [
              105.82569998601807,
              21.027807001313775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G9_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2456",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027851441650963,
          "to_latitude": 21.027807001313775,
          "to_longitude": 105.82569998601807,
          "from_longitude": 105.82575562950326
        }
      },
      {
        "id": 2450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418875089725,
            21.02605674481386,
            105.82445952805377,
            21.026325942206174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82445952805377,
              21.02605674481386
            ],
            [
              105.82419216467568,
              21.026322547598863
            ],
            [
              105.82418875089725,
              21.026325942206174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2457",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02605674481386,
          "to_latitude": 21.026325942206174,
          "to_longitude": 105.82418875089725,
          "from_longitude": 105.82445952805377
        }
      },
      {
        "id": 2451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82580348588206,
            21.025862881531964,
            105.82581383897872,
            21.0258789502117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82581383897872,
              21.0258789502117
            ],
            [
              105.82580891640377,
              21.025871265993175
            ],
            [
              105.82580348588206,
              21.025862881531964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT51_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2458",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0258789502117,
          "to_latitude": 21.025862881531964,
          "to_longitude": 105.82580348588206,
          "from_longitude": 105.82581383897872
        }
      },
      {
        "id": 2452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8253737330721,
            21.027595535976715,
            105.82541916246117,
            21.027646001982074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82541916246117,
              21.027595535976715
            ],
            [
              105.82541399918506,
              21.027601275743
            ],
            [
              105.82541096333473,
              21.027604644152895
            ],
            [
              105.8253737330721,
              21.027646001982074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2459",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027595535976715,
          "to_latitude": 21.027646001982074,
          "to_longitude": 105.8253737330721,
          "from_longitude": 105.82541916246117
        }
      },
      {
        "id": 2453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82532386064959,
            21.027477777358406,
            105.82536254404181,
            21.027523761321973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82532386064959,
              21.027523761321973
            ],
            [
              105.8253261205699,
              21.027519944163824
            ],
            [
              105.82532753306447,
              21.02751754466556
            ],
            [
              105.8253316146933,
              21.027517159049587
            ],
            [
              105.82533362510992,
              21.027514852391135
            ],
            [
              105.82533566419723,
              21.027512511270977
            ],
            [
              105.8253351985518,
              21.027508836502275
            ],
            [
              105.82536254404181,
              21.027477777358406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2460",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027523761321973,
          "to_latitude": 21.027477777358406,
          "to_longitude": 105.82536254404181,
          "from_longitude": 105.82532386064959
        }
      },
      {
        "id": 2454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82566003518343,
            21.027747470191436,
            105.82568576081721,
            21.02777692100634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82566003518343,
              21.02777692100634
            ],
            [
              105.82566521185073,
              21.027770993296993
            ],
            [
              105.82567238081346,
              21.02776278791637
            ],
            [
              105.82568576081721,
              21.027747470191436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2461",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02777692100634,
          "to_latitude": 21.027747470191436,
          "to_longitude": 105.82568576081721,
          "from_longitude": 105.82566003518343
        }
      },
      {
        "id": 2455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82588695414948,
            21.026696864580508,
            105.82590770182684,
            21.026742966481173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590770182684,
              21.026742966481173
            ],
            [
              105.82590556034971,
              21.026738243983083
            ],
            [
              105.82590433279286,
              21.026735618850736
            ],
            [
              105.82590635285815,
              21.02673297162321
            ],
            [
              105.8259058683281,
              21.026731807913045
            ],
            [
              105.82590458045475,
              21.026728711590238
            ],
            [
              105.82589953534175,
              21.026725158418675
            ],
            [
              105.82588695414948,
              21.026696864580508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2462",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026742966481173,
          "to_latitude": 21.026696864580508,
          "to_longitude": 105.82588695414948,
          "from_longitude": 105.82590770182684
        }
      },
      {
        "id": 2456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257920467494,
            21.027148138358235,
            105.82583170906479,
            21.027197123174545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82583170906479,
              21.027148138358235
            ],
            [
              105.8258311086492,
              21.027148815079798
            ],
            [
              105.82582801954814,
              21.027152293045603
            ],
            [
              105.82581914721084,
              21.027163349153014
            ],
            [
              105.8257920467494,
              21.027197123174545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2463",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027148138358235,
          "to_latitude": 21.027197123174545,
          "to_longitude": 105.8257920467494,
          "from_longitude": 105.82583170906479
        }
      },
      {
        "id": 2457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82595863038593,
            21.02725803300362,
            105.82600220690767,
            21.02730460900163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600220690767,
              21.02725803300362
            ],
            [
              105.82600103104572,
              21.02725920387522
            ],
            [
              105.82599484557669,
              21.027265364106977
            ],
            [
              105.82597460543016,
              21.0272872978787
            ],
            [
              105.82595863038593,
              21.02730460900163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2464",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02725803300362,
          "to_latitude": 21.02730460900163,
          "to_longitude": 105.82595863038593,
          "from_longitude": 105.82600220690767
        }
      },
      {
        "id": 2458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239491938572,
            21.026126068581664,
            105.82434919337534,
            21.026469551419826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8239491938572,
              21.026126068581664
            ],
            [
              105.82402111936983,
              21.02618630663013
            ],
            [
              105.82403167306106,
              21.026195145035373
            ],
            [
              105.82403943273398,
              21.02620164407675
            ],
            [
              105.82419856592725,
              21.02634075540236
            ],
            [
              105.82434919337534,
              21.026469551419826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2465",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026126068581664,
          "to_latitude": 21.026469551419826,
          "to_longitude": 105.82434919337534,
          "from_longitude": 105.8239491938572
        }
      },
      {
        "id": 2459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256780838164,
            21.027054593982268,
            105.82589245801745,
            21.02721986919236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256780838164,
              21.027054593982268
            ],
            [
              105.82579928484205,
              21.027148035688462
            ],
            [
              105.82580408450492,
              21.02715173637687
            ],
            [
              105.82581914721084,
              21.027163349153014
            ],
            [
              105.82589245801745,
              21.02721986919236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT56_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2466",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027054593982268,
          "to_latitude": 21.02721986919236,
          "to_longitude": 105.82589245801745,
          "from_longitude": 105.8256780838164
        }
      },
      {
        "id": 2460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589245801745,
            21.02721986919236,
            105.8260266877252,
            21.02733004743213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82589245801745,
              21.02721986919236
            ],
            [
              105.82594751383132,
              21.02726506019858
            ],
            [
              105.82596969426584,
              21.027283266253463
            ],
            [
              105.82597460543016,
              21.0272872978787
            ],
            [
              105.8260266877252,
              21.02733004743213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT56_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2467",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02721986919236,
          "to_latitude": 21.02733004743213,
          "to_longitude": 105.8260266877252,
          "from_longitude": 105.82589245801745
        }
      },
      {
        "id": 2461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251343810397,
            21.02740242752962,
            105.8254301114092,
            21.027618643323503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8251343810397,
              21.02740242752962
            ],
            [
              105.82541096333473,
              21.027604644152895
            ],
            [
              105.8254301114092,
              21.027618643323503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT66_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2468",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02740242752962,
          "to_latitude": 21.027618643323503,
          "to_longitude": 105.8254301114092,
          "from_longitude": 105.8251343810397
        }
      },
      {
        "id": 2462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254301114092,
            21.027618643323503,
            105.82546915711175,
            21.027647189889777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254301114092,
              21.027618643323503
            ],
            [
              105.82546915711175,
              21.027647189889777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT66_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2469",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027618643323503,
          "to_latitude": 21.027647189889777,
          "to_longitude": 105.82546915711175,
          "from_longitude": 105.8254301114092
        }
      },
      {
        "id": 2463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82546915711175,
            21.027647189889777,
            105.8256765360071,
            21.027798810928218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82546915711175,
              21.027647189889777
            ],
            [
              105.8256765360071,
              21.027798810928218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT66_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2470",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027647189889777,
          "to_latitude": 21.027798810928218,
          "to_longitude": 105.8256765360071,
          "from_longitude": 105.82546915711175
        }
      },
      {
        "id": 2464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82546607050496,
            21.02762018440237,
            105.825695062306,
            21.027779386541486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825695062306,
              21.027779386541486
            ],
            [
              105.82567238081346,
              21.02776278791637
            ],
            [
              105.82564552852752,
              21.02774313676773
            ],
            [
              105.82547752319587,
              21.02762018440237
            ],
            [
              105.82547163033237,
              21.02762568022837
            ],
            [
              105.82546607050496,
              21.02763085921206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT71_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2471",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027779386541486,
          "to_latitude": 21.02763085921206,
          "to_longitude": 105.82546607050496,
          "from_longitude": 105.825695062306
        }
      },
      {
        "id": 2465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82515112797901,
            21.027381317384496,
            105.82547752319587,
            21.02762018440237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547752319587,
              21.02762018440237
            ],
            [
              105.8253361915226,
              21.027516730384953
            ],
            [
              105.82533362510992,
              21.027514852391135
            ],
            [
              105.82515112797901,
              21.027381317384496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT71_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2472",
          "diaChiLapD": "Ngõ 44, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02762018440237,
          "to_latitude": 21.027381317384496,
          "to_longitude": 105.82515112797901,
          "from_longitude": 105.82547752319587
        }
      },
      {
        "id": 2466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254943956332,
            21.025851882866238,
            105.82580348588206,
            21.02597382155375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8255605462619,
              21.025851882866238
            ],
            [
              105.8254943956332,
              21.025886060468416
            ],
            [
              105.82553214213466,
              21.02597382155375
            ],
            [
              105.8255491812797,
              21.025966855361172
            ],
            [
              105.8255502035142,
              21.025966437624955
            ],
            [
              105.82572824863567,
              21.025893642188915
            ],
            [
              105.82573041116223,
              21.02589275827022
            ],
            [
              105.82574192255308,
              21.02588805167083
            ],
            [
              105.82580348588206,
              21.025862881531964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT51_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2473",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025851882866238,
          "to_latitude": 21.025862881531964,
          "to_longitude": 105.82580348588206,
          "from_longitude": 105.8255605462619
        }
      },
      {
        "id": 2467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82580348588206,
            21.025753537709754,
            105.8260691227518,
            21.025862881531964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82580348588206,
              21.025862881531964
            ],
            [
              105.82593911425472,
              21.02580705209011
            ],
            [
              105.82594186991757,
              21.025805917804455
            ],
            [
              105.82594877875276,
              21.025803074317146
            ],
            [
              105.82606703129125,
              21.025754398706844
            ],
            [
              105.8260691227518,
              21.025753537709754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT51_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2474",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025862881531964,
          "to_latitude": 21.025753537709754,
          "to_longitude": 105.8260691227518,
          "from_longitude": 105.82580348588206
        }
      },
      {
        "id": 2468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82459684797273,
            21.026662641698938,
            105.82486659209061,
            21.02690209176672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82459684797273,
              21.026662641698938
            ],
            [
              105.82470345125907,
              21.026757273194622
            ],
            [
              105.82470549879356,
              21.02675909048928
            ],
            [
              105.82486659209061,
              21.02690209176672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2475",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026662641698938,
          "to_latitude": 21.02690209176672,
          "to_longitude": 105.82486659209061,
          "from_longitude": 105.82459684797273
        }
      },
      {
        "id": 2469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82600400332744,
            21.02734680165601,
            105.82611838472718,
            21.02753948885405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8260799890072,
              21.02734680165601
            ],
            [
              105.8260788955711,
              21.027348091353396
            ],
            [
              105.8260742016561,
              21.027353631955872
            ],
            [
              105.82607189837826,
              21.02736441590709
            ],
            [
              105.82600400332744,
              21.027446124462134
            ],
            [
              105.82601033105888,
              21.027451289978217
            ],
            [
              105.82611838472718,
              21.02753948885405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT59_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2476",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02734680165601,
          "to_latitude": 21.02753948885405,
          "to_longitude": 105.82611838472718,
          "from_longitude": 105.8260799890072
        }
      },
      {
        "id": 2470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82594337897393,
            21.02733804238478,
            105.8260839578148,
            21.02753130038333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606934897046,
              21.02733804238478
            ],
            [
              105.82606859873913,
              21.027338765899398
            ],
            [
              105.8260638370687,
              21.02734335844466
            ],
            [
              105.8260694338485,
              21.02734890596063
            ],
            [
              105.82607282132427,
              21.02735226395302
            ],
            [
              105.8260742016561,
              21.027353631955872
            ],
            [
              105.8260839578148,
              21.027363302358186
            ],
            [
              105.82607989313958,
              21.027368159775783
            ],
            [
              105.82601033105888,
              21.027451289978217
            ],
            [
              105.8260040921142,
              21.027458745694528
            ],
            [
              105.82599759512419,
              21.027466509760472
            ],
            [
              105.82594337897393,
              21.02753130038333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT61_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2477",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02733804238478,
          "to_latitude": 21.02753130038333,
          "to_longitude": 105.82594337897393,
          "from_longitude": 105.82606934897046
        }
      },
      {
        "id": 2471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591462630033,
            21.027512795666805,
            105.82603200139934,
            21.027622366136473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600662048019,
              21.027622366136473
            ],
            [
              105.82603200139934,
              21.027588336692762
            ],
            [
              105.82594337897393,
              21.02753130038333
            ],
            [
              105.82591462630033,
              21.027512795666805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT61_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2478",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027622366136473,
          "to_latitude": 21.027512795666805,
          "to_longitude": 105.82591462630033,
          "from_longitude": 105.82600662048019
        }
      },
      {
        "id": 2472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259851189919,
            21.027345533781027,
            105.8260867171681,
            21.027633886823722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82607844953297,
              21.027345533781027
            ],
            [
              105.82607770311832,
              21.027346426180177
            ],
            [
              105.82607282132427,
              21.02735226395302
            ],
            [
              105.8259851189919,
              21.02745712353246
            ],
            [
              105.82599759512419,
              21.027466509760472
            ],
            [
              105.82608227927562,
              21.027530221940953
            ],
            [
              105.8260867171681,
              21.0275335603785
            ],
            [
              105.82600772486363,
              21.027633886823722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT60_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2479",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027345533781027,
          "to_latitude": 21.027633886823722,
          "to_longitude": 105.82600772486363,
          "from_longitude": 105.82607844953297
        }
      },
      {
        "id": 2473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82643328158471,
            21.02803795236077,
            105.8269442959479,
            21.02817863493993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643328158471,
              21.02803795236077
            ],
            [
              105.82644185049995,
              21.02804375096632
            ],
            [
              105.82656653756314,
              21.02812852856683
            ],
            [
              105.82664342158292,
              21.028038776235295
            ],
            [
              105.82668251685833,
              21.028066307949643
            ],
            [
              105.82682850119747,
              21.02817863493993
            ],
            [
              105.8269442959479,
              21.028097275714654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H92_Ngách 29/64, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2480",
          "diaChiLapD": "Ngách 29/64, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02803795236077,
          "to_latitude": 21.028097275714654,
          "to_longitude": 105.8269442959479,
          "from_longitude": 105.82643328158471
        }
      },
      {
        "id": 2474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82699524979833,
            21.027712014879715,
            105.82715086675049,
            21.027851203621424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82715086675049,
              21.027851203621424
            ],
            [
              105.82699524979833,
              21.027712014879715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H92_Ngách 29/64, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2481",
          "diaChiLapD": "Ngách 29/64, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027851203621424,
          "to_latitude": 21.027712014879715,
          "to_longitude": 105.82699524979833,
          "from_longitude": 105.82715086675049
        }
      },
      {
        "id": 2475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82684070073768,
            21.0278568921719,
            105.8270433201139,
            21.02803760853097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270433201139,
              21.02803760853097
            ],
            [
              105.827032850172,
              21.02802761469165
            ],
            [
              105.82684070073768,
              21.0278568921719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H92_Ngách 29/64, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2482",
          "diaChiLapD": "Ngách 29/64, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02803760853097,
          "to_latitude": 21.0278568921719,
          "to_longitude": 105.82684070073768,
          "from_longitude": 105.8270433201139
        }
      },
      {
        "id": 2476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259038038108,
            21.027170994578285,
            105.826087810898,
            21.027232415395737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259038038108,
              21.027179501356883
            ],
            [
              105.82590814274096,
              21.027174309321403
            ],
            [
              105.82591091335175,
              21.027170994578285
            ],
            [
              105.82596356877467,
              21.0272007927382
            ],
            [
              105.82600858918974,
              21.0272160101764
            ],
            [
              105.826087810898,
              21.027232415395737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2483",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027179501356883,
          "to_latitude": 21.027232415395737,
          "to_longitude": 105.826087810898,
          "from_longitude": 105.8259038038108
        }
      },
      {
        "id": 2477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82669123340003,
            21.026846218317125,
            105.8269826527728,
            21.027075284690582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82669123340003,
              21.02694896578166
            ],
            [
              105.82691718069653,
              21.026846218317125
            ],
            [
              105.8269826527728,
              21.027075284690582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H7_Ngách 29/16, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2484",
          "diaChiLapD": "Ngách 29/16, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02694896578166,
          "to_latitude": 21.027075284690582,
          "to_longitude": 105.8269826527728,
          "from_longitude": 105.82669123340003
        }
      },
      {
        "id": 2478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82401193323543,
            21.026482271225515,
            105.82463855483562,
            21.02701203545062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82401193323543,
              21.026482271225515
            ],
            [
              105.82405743107397,
              21.02652018583376
            ],
            [
              105.82418693834582,
              21.026628106024273
            ],
            [
              105.82423626626318,
              21.026669212081202
            ],
            [
              105.8242382986809,
              21.026670905713132
            ],
            [
              105.82432862003498,
              21.026746172637115
            ],
            [
              105.8243489382889,
              21.026763602133055
            ],
            [
              105.82435112080017,
              21.02676547387454
            ],
            [
              105.82458090750539,
              21.02696258612319
            ],
            [
              105.82458253520957,
              21.026963981842393
            ],
            [
              105.82463855483562,
              21.02701203545062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2485",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026482271225515,
          "to_latitude": 21.02701203545062,
          "to_longitude": 105.82463855483562,
          "from_longitude": 105.82401193323543
        }
      },
      {
        "id": 2479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239424790621,
            21.025508894722957,
            105.82445952805377,
            21.02605674481386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82445952805377,
              21.02605674481386
            ],
            [
              105.82401727919809,
              21.025677206029524
            ],
            [
              105.82398673390095,
              21.02565099249479
            ],
            [
              105.8240064561882,
              21.025627237850824
            ],
            [
              105.82395960366149,
              21.025540576385026
            ],
            [
              105.8239424790621,
              21.025508894722957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2486",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02605674481386,
          "to_latitude": 21.025508894722957,
          "to_longitude": 105.8239424790621,
          "from_longitude": 105.82445952805377
        }
      },
      {
        "id": 2480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82441143020834,
            21.02563902563232,
            105.82472611247731,
            21.026005055180985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82441143020834,
              21.026005055180985
            ],
            [
              105.82456759471059,
              21.0258448995859
            ],
            [
              105.8245551196932,
              21.025796846167037
            ],
            [
              105.82463430351169,
              21.02571156085669
            ],
            [
              105.82465489777101,
              21.02570660886795
            ],
            [
              105.82472611247731,
              21.02563902563232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7BS_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2487",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026005055180985,
          "to_latitude": 21.02563902563232,
          "to_longitude": 105.82472611247731,
          "from_longitude": 105.82441143020834
        }
      },
      {
        "id": 2481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82427491014363,
            21.025398973540547,
            105.82463430351169,
            21.02571156085669
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82463430351169,
              21.02571156085669
            ],
            [
              105.82430835302607,
              21.02546333605578
            ],
            [
              105.82430563255915,
              21.02545810059965
            ],
            [
              105.82427491014363,
              21.025398973540547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7BS_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2488",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02571156085669,
          "to_latitude": 21.025398973540547,
          "to_longitude": 105.82427491014363,
          "from_longitude": 105.82463430351169
        }
      },
      {
        "id": 2482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82607415823341,
            21.02662493473808,
            105.82614132987437,
            21.026706168732773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82614132987437,
              21.026706168732773
            ],
            [
              105.82613236968444,
              21.0266953392405
            ],
            [
              105.82610682741795,
              21.026664447483434
            ],
            [
              105.82609860322295,
              21.026654500477427
            ],
            [
              105.82607415823341,
              21.02662493473808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2489",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026706168732773,
          "to_latitude": 21.02662493473808,
          "to_longitude": 105.82607415823341,
          "from_longitude": 105.82614132987437
        }
      },
      {
        "id": 2483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82620501156559,
            21.026574654197507,
            105.82622451394116,
            21.026618039788254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82622451394116,
              21.026618039788254
            ],
            [
              105.82622342699237,
              21.026615618103982
            ],
            [
              105.82622239027462,
              21.026613408433505
            ],
            [
              105.82622324986507,
              21.0266089640037
            ],
            [
              105.82622203602041,
              21.026604987582697
            ],
            [
              105.82622163239573,
              21.02660366722252
            ],
            [
              105.82621759279594,
              21.026602946204466
            ],
            [
              105.82620501156559,
              21.026574654197507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2490",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026618039788254,
          "to_latitude": 21.026574654197507,
          "to_longitude": 105.82620501156559,
          "from_longitude": 105.82622451394116
        }
      },
      {
        "id": 2484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8263633564042,
            21.026509941921052,
            105.82641058978292,
            21.026591042370622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82641058978292,
              21.026591042370622
            ],
            [
              105.82640881062817,
              21.026588526503186
            ],
            [
              105.82638500235328,
              21.02655475681677
            ],
            [
              105.82638073515035,
              21.026548697043282
            ],
            [
              105.82638221609389,
              21.026543885593746
            ],
            [
              105.82638109492224,
              21.026541189496463
            ],
            [
              105.8263803822482,
              21.02653947592921
            ],
            [
              105.82637593862506,
              21.02653823481425
            ],
            [
              105.8263633564042,
              21.026509941921052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2491",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026591042370622,
          "to_latitude": 21.026509941921052,
          "to_longitude": 105.8263633564042,
          "from_longitude": 105.82641058978292
        }
      },
      {
        "id": 2485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587964935907,
            21.02640607788482,
            105.82590938024622,
            21.02647226543219
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590938024622,
              21.02647226543219
            ],
            [
              105.8258958051993,
              21.026447092546167
            ],
            [
              105.8258894663677,
              21.026430998055964
            ],
            [
              105.82587964935907,
              21.02640607788482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2492",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02647226543219,
          "to_latitude": 21.02640607788482,
          "to_longitude": 105.82587964935907,
          "from_longitude": 105.82590938024622
        }
      },
      {
        "id": 2486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605284534806,
            21.02633659971307,
            105.82608214040059,
            21.026401895153366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82608214040059,
              21.026401895153366
            ],
            [
              105.82607265345385,
              21.02638467880262
            ],
            [
              105.82606316426916,
              21.02636164388717
            ],
            [
              105.82605284534806,
              21.02633659971307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2493",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026401895153366,
          "to_latitude": 21.02633659971307,
          "to_longitude": 105.82605284534806,
          "from_longitude": 105.82608214040059
        }
      },
      {
        "id": 2487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82621070798677,
            21.026272051601797,
            105.82624008411263,
            21.026337559780984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624008411263,
              21.026337559780984
            ],
            [
              105.82623956803016,
              21.02633643145081
            ],
            [
              105.8262344325895,
              21.026323295511556
            ],
            [
              105.82622269684688,
              21.026297945716237
            ],
            [
              105.82621406104523,
              21.02627929272349
            ],
            [
              105.82621070798677,
              21.026272051601797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2494",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026337559780984,
          "to_latitude": 21.026272051601797,
          "to_longitude": 105.82621070798677,
          "from_longitude": 105.82624008411263
        }
      },
      {
        "id": 2488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578728736208,
            21.026149416194823,
            105.8258025148796,
            21.02618607731499
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258025148796,
              21.02618607731499
            ],
            [
              105.825800761875,
              21.026181844282956
            ],
            [
              105.82579967585527,
              21.026179240139612
            ],
            [
              105.8258007244359,
              21.02617452382621
            ],
            [
              105.82579965373206,
              21.02617103264155
            ],
            [
              105.82579921486915,
              21.02616960135459
            ],
            [
              105.82579527110943,
              21.026168637796403
            ],
            [
              105.82578728736208,
              21.026149416194823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2495",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02618607731499,
          "to_latitude": 21.026149416194823,
          "to_longitude": 105.82578728736208,
          "from_longitude": 105.8258025148796
        }
      },
      {
        "id": 2489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82598121017766,
            21.026067662884206,
            105.82599634706452,
            21.026104105845985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82599634706452,
              21.026104105845985
            ],
            [
              105.82599512137892,
              21.02610112122138
            ],
            [
              105.8259935977396,
              21.026097486818752
            ],
            [
              105.82599531210074,
              21.02609418172085
            ],
            [
              105.82599461506008,
              21.026091812334457
            ],
            [
              105.82599385038377,
              21.026089214760656
            ],
            [
              105.82598919297779,
              21.02608688357764
            ],
            [
              105.82598121017766,
              21.026067662884206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2496",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026104105845985,
          "to_latitude": 21.026067662884206,
          "to_longitude": 105.82598121017766,
          "from_longitude": 105.82599634706452
        }
      },
      {
        "id": 2490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612982026392,
            21.02600927101235,
            105.82614340213367,
            21.026041924451405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82614338265869,
              21.026041924451405
            ],
            [
              105.82614280127564,
              21.026040467664217
            ],
            [
              105.8261422078498,
              21.02603909493545
            ],
            [
              105.82614340213367,
              21.026035333524383
            ],
            [
              105.82614271111753,
              21.026031613788067
            ],
            [
              105.82614240279368,
              21.026029955158428
            ],
            [
              105.82613780404147,
              21.02602849169374
            ],
            [
              105.82612982026392,
              21.02600927101235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2497",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026041924451405,
          "to_latitude": 21.02600927101235,
          "to_longitude": 105.82612982026392,
          "from_longitude": 105.82614338265869
        }
      },
      {
        "id": 2491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82592141160026,
            21.02575888236614,
            105.82594962663957,
            21.025817807218296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82594962663957,
              21.025817807218296
            ],
            [
              105.82594809708469,
              21.025813447554096
            ],
            [
              105.82594650150834,
              21.02580897801704
            ],
            [
              105.82594291478543,
              21.025808037115034
            ],
            [
              105.82594186991757,
              21.025805917804455
            ],
            [
              105.82594032206434,
              21.025802776679548
            ],
            [
              105.82594147395322,
              21.025798653282372
            ],
            [
              105.82592141160026,
              21.02575888236614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2498",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025817807218296,
          "to_latitude": 21.02575888236614,
          "to_longitude": 105.82592141160026,
          "from_longitude": 105.82594962663957
        }
      },
      {
        "id": 2492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82466716649428,
            21.026733378824268,
            105.82472161049667,
            21.026786535715942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82472161049667,
              21.026733378824268
            ],
            [
              105.82471314542789,
              21.02674205922537
            ],
            [
              105.82470499504726,
              21.026750410229226
            ],
            [
              105.82470532612747,
              21.02675603300641
            ],
            [
              105.82470345125907,
              21.026757273194622
            ],
            [
              105.82470040604137,
              21.026759288606325
            ],
            [
              105.82469542487436,
              21.02675948527882
            ],
            [
              105.82466716649428,
              21.026786535715942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2499",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026733378824268,
          "to_latitude": 21.026786535715942,
          "to_longitude": 105.82466716649428,
          "from_longitude": 105.82472161049667
        }
      },
      {
        "id": 2493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82433773384419,
            21.026463384072446,
            105.82436300756666,
            21.026497876111588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82436300756666,
              21.026463384072446
            ],
            [
              105.8243587959301,
              21.026466364357482
            ],
            [
              105.82435410624872,
              21.026473381666726
            ],
            [
              105.82433773384419,
              21.026497876111588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2500",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026463384072446,
          "to_latitude": 21.026497876111588,
          "to_longitude": 105.82433773384419,
          "from_longitude": 105.82436300756666
        }
      },
      {
        "id": 2494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82430019449028,
            21.026753881034704,
            105.82435371796986,
            21.026805991931695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82430019449028,
              21.026805991931695
            ],
            [
              105.82434087668857,
              21.02676605914541
            ],
            [
              105.82434700660345,
              21.02676570697934
            ],
            [
              105.8243489382889,
              21.026763602133055
            ],
            [
              105.82435082502431,
              21.026761545375596
            ],
            [
              105.82435047080455,
              21.02675696051524
            ],
            [
              105.8243518412785,
              21.0267556649695
            ],
            [
              105.82435371796986,
              21.026753881034704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2501",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026805991931695,
          "to_latitude": 21.026753881034704,
          "to_longitude": 105.82435371796986,
          "from_longitude": 105.82430019449028
        }
      },
      {
        "id": 2495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82418705978236,
            21.026658145716862,
            105.82424261239927,
            21.02671423518547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82418705978236,
              21.02671423518547
            ],
            [
              105.82423046878249,
              21.026669661986727
            ],
            [
              105.82423493061268,
              21.02667036745734
            ],
            [
              105.82423626626318,
              21.026669212081202
            ],
            [
              105.82423899310594,
              21.026666854995213
            ],
            [
              105.82424007248454,
              21.026660556993622
            ],
            [
              105.82424261239927,
              21.026658145716862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2502",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02671423518547,
          "to_latitude": 21.026658145716862,
          "to_longitude": 105.82424261239927,
          "from_longitude": 105.82418705978236
        }
      },
      {
        "id": 2496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241464307518,
            21.02661742799598,
            105.82419534531836,
            21.026668127347563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8241464307518,
              21.026668127347563
            ],
            [
              105.82418693834582,
              21.026628106024273
            ],
            [
              105.82419131001625,
              21.02662378638794
            ],
            [
              105.82419534531836,
              21.02661742799598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2503",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026668127347563,
          "to_latitude": 21.02661742799598,
          "to_longitude": 105.82419534531836,
          "from_longitude": 105.8241464307518
        }
      },
      {
        "id": 2497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82453672320256,
            21.02695312150686,
            105.82458494783921,
            21.02700349607457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82453672320256,
              21.02700349607457
            ],
            [
              105.82457144801367,
              21.026965902569387
            ],
            [
              105.82457845631384,
              21.02696468716848
            ],
            [
              105.82458090750539,
              21.02696258612319
            ],
            [
              105.82458204692735,
              21.026961609631208
            ],
            [
              105.82458104309453,
              21.02695680482499
            ],
            [
              105.82458251600865,
              21.026955419361414
            ],
            [
              105.82458494783921,
              21.02695312150686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2504",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02700349607457,
          "to_latitude": 21.02695312150686,
          "to_longitude": 105.82458494783921,
          "from_longitude": 105.82453672320256
        }
      },
      {
        "id": 2498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82400133292519,
            21.02618627909908,
            105.824038171742,
            21.026224829320647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.824038171742,
              21.02618627909908
            ],
            [
              105.82403522722231,
              21.026192087557174
            ],
            [
              105.82403167306106,
              21.026195145035373
            ],
            [
              105.82401588039633,
              21.02620872929292
            ],
            [
              105.82400133292519,
              21.026224829320647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2505",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02618627909908,
          "to_latitude": 21.026224829320647,
          "to_longitude": 105.82400133292519,
          "from_longitude": 105.824038171742
        }
      },
      {
        "id": 2499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82611443097984,
            21.02753518964965,
            105.82612610154303,
            21.027547251238303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82611874208493,
              21.027547251238303
            ],
            [
              105.82611443097984,
              21.027543876959907
            ],
            [
              105.82611838472718,
              21.02753948885405
            ],
            [
              105.82612225911701,
              21.02753518964965
            ],
            [
              105.82612610154303,
              21.02753870620458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT59_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2506",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027547251238303,
          "to_latitude": 21.02753870620458,
          "to_longitude": 105.82612610154303,
          "from_longitude": 105.82611874208493
        }
      },
      {
        "id": 2500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82599722115604,
            21.0276290011578,
            105.82601038801943,
            21.027639637140858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82599722115604,
              21.027632587523406
            ],
            [
              105.82600049822024,
              21.0276290011578
            ],
            [
              105.82600772486363,
              21.027633886823722
            ],
            [
              105.82601038801943,
              21.027635686659767
            ],
            [
              105.8260063433495,
              21.027639637140858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT60_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2507",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027632587523406,
          "to_latitude": 21.027639637140858,
          "to_longitude": 105.8260063433495,
          "from_longitude": 105.82599722115604
        }
      },
      {
        "id": 2501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259340982239,
            21.02734338504869,
            105.82607583833182,
            21.027503503554737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82607583833182,
              21.02734338504869
            ],
            [
              105.82607501694677,
              21.02734428955395
            ],
            [
              105.8260694338485,
              21.02734890596063
            ],
            [
              105.82606227255144,
              21.027354828870862
            ],
            [
              105.8259340982239,
              21.027503503554737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H7BS_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2508",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02734338504869,
          "to_latitude": 21.027503503554737,
          "to_longitude": 105.8259340982239,
          "from_longitude": 105.82607583833182
        }
      },
      {
        "id": 2502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82544828935443,
            21.027219043349078,
            105.82553654815389,
            21.027291347098767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82544828935443,
              21.027219043349078
            ],
            [
              105.82553654815389,
              21.027291347098767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H5_Ngõ 23, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2509",
          "diaChiLapD": "Ngõ 23, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027219043349078,
          "to_latitude": 21.027291347098767,
          "to_longitude": 105.82553654815389,
          "from_longitude": 105.82544828935443
        }
      },
      {
        "id": 2503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241904633458,
            21.026005055180985,
            105.82446620965689,
            21.026327530809038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8241904633458,
              21.026327530809038
            ],
            [
              105.82419455015516,
              21.026324243133296
            ],
            [
              105.82422410122551,
              21.026300471538306
            ],
            [
              105.82446620965689,
              21.026055606873392
            ],
            [
              105.82441143020834,
              21.026005055180985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7BS_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2510",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026327530809038,
          "to_latitude": 21.026005055180985,
          "to_longitude": 105.82441143020834,
          "from_longitude": 105.8241904633458
        }
      },
      {
        "id": 2504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82571140080888,
            21.025847137451226,
            105.8257441386038,
            21.025910333407584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257441386038,
              21.025910333407584
            ],
            [
              105.82574046938505,
              21.025904021422672
            ],
            [
              105.82573649065884,
              21.025897232231237
            ],
            [
              105.82573115986284,
              21.025894901600815
            ],
            [
              105.82573041116223,
              21.02589275827022
            ],
            [
              105.82572967809709,
              21.02589065912172
            ],
            [
              105.82573146311474,
              21.02588690749008
            ],
            [
              105.82571140080888,
              21.025847137451226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2511",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025910333407584,
          "to_latitude": 21.025847137451226,
          "to_longitude": 105.82571140080888,
          "from_longitude": 105.8257441386038
        }
      },
      {
        "id": 2505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82551463843072,
            21.02592752049535,
            105.82556942453684,
            21.02598900352351
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556942453684,
              21.02598900352351
            ],
            [
              105.82556165296936,
              21.0259824504176
            ],
            [
              105.82554882011728,
              21.025971672207167
            ],
            [
              105.82555169627587,
              21.025969009085976
            ],
            [
              105.8255502035142,
              21.025966437624955
            ],
            [
              105.82554826982624,
              21.02596310778888
            ],
            [
              105.82554091128854,
              21.025963776670935
            ],
            [
              105.82551463843072,
              21.02592752049535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2512",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02598900352351,
          "to_latitude": 21.02592752049535,
          "to_longitude": 105.82551463843072,
          "from_longitude": 105.82556942453684
        }
      },
      {
        "id": 2506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82808288688167,
            21.027507868044964,
            105.82850733457651,
            21.027605061364373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82808288688167,
              21.027507868044964
            ],
            [
              105.82808527773658,
              21.027507913305534
            ],
            [
              105.82812121301887,
              21.027519792743753
            ],
            [
              105.82817732348317,
              21.027514574331374
            ],
            [
              105.82823630311817,
              21.027509089023397
            ],
            [
              105.82830316598242,
              21.027532767445614
            ],
            [
              105.82831027280594,
              21.02753528172803
            ],
            [
              105.82842176909982,
              21.027574763180333
            ],
            [
              105.82850733457651,
              21.027605061364373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9_Ngách 35/75, Cát Linh",
          "maTaiSan": "04.O13.DODV.2513",
          "diaChiLapD": "Ngách 35/75, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027507868044964,
          "to_latitude": 21.027605061364373,
          "to_longitude": 105.82850733457651,
          "from_longitude": 105.82808288688167
        }
      },
      {
        "id": 2507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850733457651,
            21.027605061364373,
            105.82866701079733,
            21.027652062087473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82850733457651,
              21.027605061364373
            ],
            [
              105.82866701079733,
              21.027652062087473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E9_Ngách 35/75, Cát Linh",
          "maTaiSan": "04.O13.DODV.2514",
          "diaChiLapD": "Ngách 35/75, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027605061364373,
          "to_latitude": 21.027652062087473,
          "to_longitude": 105.82866701079733,
          "from_longitude": 105.82850733457651
        }
      },
      {
        "id": 2508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82842176909982,
            21.027434580088276,
            105.8284753404561,
            21.027574763180333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82842176909982,
              21.027574763180333
            ],
            [
              105.8284753404561,
              21.027434580088276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E9_Ngách 35/75, Cát Linh",
          "maTaiSan": "04.O13.DODV.2515",
          "diaChiLapD": "Ngách 35/75, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027574763180333,
          "to_latitude": 21.027434580088276,
          "to_longitude": 105.8284753404561,
          "from_longitude": 105.82842176909982
        }
      },
      {
        "id": 2509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766661307217,
            21.027942623098365,
            105.8281929276692,
            21.02806737472958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281929276692,
              21.027942623098365
            ],
            [
              105.82819196324256,
              21.027942883407967
            ],
            [
              105.8281722654094,
              21.027948183720994
            ],
            [
              105.8278479468592,
              21.02802368641855
            ],
            [
              105.82766661307217,
              21.02806737472958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N6_Ngách 35/64, Cát Linh",
          "maTaiSan": "04.O13.DODV.2516",
          "diaChiLapD": "Ngách 35/64, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027942623098365,
          "to_latitude": 21.02806737472958,
          "to_longitude": 105.82766661307217,
          "from_longitude": 105.8281929276692
        }
      },
      {
        "id": 2510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82779248174255,
            21.02781986654071,
            105.8278479468592,
            21.02802368641855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278479468592,
              21.02802368641855
            ],
            [
              105.82779248174255,
              21.02781986654071
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N6_Hẻm 35/64/9, Cát Linh",
          "maTaiSan": "04.O13.DODV.2517",
          "diaChiLapD": "Hẻm 35/64/9, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02802368641855,
          "to_latitude": 21.02781986654071,
          "to_longitude": 105.82779248174255,
          "from_longitude": 105.8278479468592
        }
      },
      {
        "id": 2511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283548447395,
            21.028431884113047,
            105.82879343966835,
            21.02855313846878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82879343966835,
              21.028431884113047
            ],
            [
              105.82876026540053,
              21.028437630435768
            ],
            [
              105.82870369198892,
              21.028452263805345
            ],
            [
              105.82868356260218,
              21.028457469636862
            ],
            [
              105.82854482992438,
              21.028493364191547
            ],
            [
              105.82849812241744,
              21.028516429794525
            ],
            [
              105.8283548447395,
              21.02855313846878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Ngách 35/31, Cát Linh",
          "maTaiSan": "04.O13.DODV.2518",
          "diaChiLapD": "Ngách 35/31, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028431884113047,
          "to_latitude": 21.02855313846878,
          "to_longitude": 105.8283548447395,
          "from_longitude": 105.82879343966835
        }
      },
      {
        "id": 2512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82782203525163,
            21.029318157144395,
            105.82800761378827,
            21.029355445305846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82800761378827,
              21.029318157144395
            ],
            [
              105.82790395126922,
              21.029338984445875
            ],
            [
              105.82782203525163,
              21.029355445305846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2_Cát Linh",
          "maTaiSan": "04.O13.DODV.2519",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029318157144395,
          "to_latitude": 21.029355445305846,
          "to_longitude": 105.82782203525163,
          "from_longitude": 105.82800761378827
        }
      },
      {
        "id": 2513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807508551805,
            21.028788101584446,
            105.8283816974156,
            21.02902359041752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281322502584,
              21.02902359041752
            ],
            [
              105.82807508551805,
              21.028833722028004
            ],
            [
              105.82817480046725,
              21.02880540445089
            ],
            [
              105.82817991387594,
              21.028826519511444
            ],
            [
              105.82835578686233,
              21.028792727672126
            ],
            [
              105.8283675763557,
              21.028790622630172
            ],
            [
              105.82837900641152,
              21.028788582564136
            ],
            [
              105.8283816974156,
              21.028788101584446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4_Ngách 35/14, Cát Linh",
          "maTaiSan": "04.O13.DODV.2520",
          "diaChiLapD": "Ngách 35/14, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02902359041752,
          "to_latitude": 21.028788101584446,
          "to_longitude": 105.8283816974156,
          "from_longitude": 105.8281322502584
        }
      },
      {
        "id": 2514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82800761378827,
            21.02924059698549,
            105.8284245406983,
            21.029318157144395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82800761378827,
              21.029318157144395
            ],
            [
              105.8284245406983,
              21.02924059698549
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2_Cát Linh",
          "maTaiSan": "04.O13.DODV.2521",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029318157144395,
          "to_latitude": 21.02924059698549,
          "to_longitude": 105.8284245406983,
          "from_longitude": 105.82800761378827
        }
      },
      {
        "id": 2515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806419527739,
            21.028287063293316,
            105.82813151898817,
            21.02856282854294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806419527739,
              21.028287063293316
            ],
            [
              105.82813151898817,
              21.02856282854294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Ngách 35/26, Cát Linh",
          "maTaiSan": "04.O13.DODV.2522",
          "diaChiLapD": "Ngách 35/26, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028287063293316,
          "to_latitude": 21.02856282854294,
          "to_longitude": 105.82813151898817,
          "from_longitude": 105.82806419527739
        }
      },
      {
        "id": 2516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82763986647213,
            21.02794473955695,
            105.82819399854323,
            21.02808504101379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819399854323,
              21.02794473955695
            ],
            [
              105.82819289873031,
              21.027945046590368
            ],
            [
              105.8281808900537,
              21.027948387393852
            ],
            [
              105.82817503966119,
              21.02796312128323
            ],
            [
              105.82763986647213,
              21.02808504101379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6_Ngách 35/64, Cát Linh",
          "maTaiSan": "04.O13.DODV.2523",
          "diaChiLapD": "Ngách 35/64, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02794473955695,
          "to_latitude": 21.02808504101379,
          "to_longitude": 105.82763986647213,
          "from_longitude": 105.82819399854323
        }
      },
      {
        "id": 2517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82811725442676,
            21.028592307900144,
            105.82816224766005,
            21.02880011940292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82816224766005,
              21.02880011940292
            ],
            [
              105.82811725442676,
              21.028592307900144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5_Ngách 35/14, Cát Linh",
          "maTaiSan": "04.O13.DODV.2524",
          "diaChiLapD": "Ngách 35/14, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02880011940292,
          "to_latitude": 21.028592307900144,
          "to_longitude": 105.82811725442676,
          "from_longitude": 105.82816224766005
        }
      },
      {
        "id": 2518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82777132450605,
            21.028245886294464,
            105.828269847516,
            21.02834656564827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82777132450605,
              21.02834656564827
            ],
            [
              105.82783114855476,
              21.02833017444241
            ],
            [
              105.82789967694394,
              21.02831569993106
            ],
            [
              105.82794840889773,
              21.028305407065922
            ],
            [
              105.82802930400506,
              21.028289188678976
            ],
            [
              105.82806419527739,
              21.028287063293316
            ],
            [
              105.8280781946759,
              21.02828621021852
            ],
            [
              105.82814793243779,
              21.02827263565298
            ],
            [
              105.82824041031552,
              21.02825190966686
            ],
            [
              105.82826559155093,
              21.028246757861947
            ],
            [
              105.828269847516,
              21.028245886294464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Ngách 35/46, Cát Linh",
          "maTaiSan": "04.O13.DODV.2525",
          "diaChiLapD": "Ngách 35/46, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02834656564827,
          "to_latitude": 21.028245886294464,
          "to_longitude": 105.828269847516,
          "from_longitude": 105.82777132450605
        }
      },
      {
        "id": 2519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278269275501,
            21.02831569993106,
            105.82792496490346,
            21.02842273213936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278269275501,
              21.02842273213936
            ],
            [
              105.82792496490346,
              21.02840719898993
            ],
            [
              105.82789967694394,
              21.02831569993106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N7_Ngách 35/46, Cát Linh",
          "maTaiSan": "04.O13.DODV.2526",
          "diaChiLapD": "Ngách 35/46, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02842273213936,
          "to_latitude": 21.02831569993106,
          "to_longitude": 105.82789967694394,
          "from_longitude": 105.8278269275501
        }
      },
      {
        "id": 2520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277932763171,
            21.02748811261382,
            105.82808204281825,
            21.027546353650777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277932763171,
              21.027546353650777
            ],
            [
              105.8279040208776,
              21.027520813264964
            ],
            [
              105.82804581140283,
              21.02748811261382
            ],
            [
              105.82806021274449,
              21.027492987641345
            ],
            [
              105.82808041337103,
              21.02749982603744
            ],
            [
              105.82808204281825,
              21.02749961487718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E82_Ngách 35/92, Cát Linh",
          "maTaiSan": "04.O13.DODV.2527",
          "diaChiLapD": "Ngách 35/92, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027546353650777,
          "to_latitude": 21.02749961487718,
          "to_longitude": 105.82808204281825,
          "from_longitude": 105.8277932763171
        }
      },
      {
        "id": 2521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279040208776,
            21.027520813264964,
            105.82791998417754,
            21.027621946365375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82791998417754,
              21.027621946365375
            ],
            [
              105.8279040208776,
              21.027520813264964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E82_Ngách 35/92, Cát Linh",
          "maTaiSan": "04.O13.DODV.2528",
          "diaChiLapD": "Ngách 35/92, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027621946365375,
          "to_latitude": 21.027520813264964,
          "to_longitude": 105.8279040208776,
          "from_longitude": 105.82791998417754
        }
      },
      {
        "id": 2522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282128694128,
            21.028126332136598,
            105.82824041031552,
            21.02825190966686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82824041031552,
              21.02825190966686
            ],
            [
              105.82821517396047,
              21.02813739444314
            ],
            [
              105.8282128694128,
              21.028126332136598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2529",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02825190966686,
          "to_latitude": 21.028126332136598,
          "to_longitude": 105.8282128694128,
          "from_longitude": 105.82824041031552
        }
      },
      {
        "id": 2523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82813797266904,
            21.02782512201764,
            105.82819797379959,
            21.02808598629716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819797379959,
              21.02808598629716
            ],
            [
              105.82817461724333,
              21.027999670509352
            ],
            [
              105.82817078268474,
              21.02797721605831
            ],
            [
              105.82817513682053,
              21.02796364287376
            ],
            [
              105.82817503966119,
              21.02796312128323
            ],
            [
              105.8281722654094,
              21.027948183720994
            ],
            [
              105.82817159513323,
              21.02794457228132
            ],
            [
              105.82816975785802,
              21.027934679180117
            ],
            [
              105.82816300521647,
              21.027931674523085
            ],
            [
              105.82816019170491,
              21.027915202573464
            ],
            [
              105.82814299767604,
              21.027844696612444
            ],
            [
              105.82813797266904,
              21.02782512201764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P1_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2530",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02808598629716,
          "to_latitude": 21.02782512201764,
          "to_longitude": 105.82813797266904,
          "from_longitude": 105.82819797379959
        }
      },
      {
        "id": 2524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278976461892,
            21.027054444449988,
            105.8281279741126,
            21.027817749818464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281279741126,
              21.027817749818464
            ],
            [
              105.82811446916256,
              21.027765129602116
            ],
            [
              105.82808515918548,
              21.027625943641922
            ],
            [
              105.82806083124092,
              21.027496282302124
            ],
            [
              105.82806021274449,
              21.027492987641345
            ],
            [
              105.82805701713261,
              21.027475956646875
            ],
            [
              105.82805491180898,
              21.027464731689435
            ],
            [
              105.82804743523148,
              21.027424885316115
            ],
            [
              105.82804401761172,
              21.027406673984594
            ],
            [
              105.82802043679817,
              21.027304974632568
            ],
            [
              105.82800839412168,
              21.027253037345726
            ],
            [
              105.82798284714583,
              21.027171044175788
            ],
            [
              105.8278976461892,
              21.027054444449988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2531",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027817749818464,
          "to_latitude": 21.027054444449988,
          "to_longitude": 105.8278976461892,
          "from_longitude": 105.8281279741126
        }
      },
      {
        "id": 2525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82804743523148,
            21.02742102850856,
            105.82807155661725,
            21.027424885316115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807155661725,
              21.02742102850856
            ],
            [
              105.82806993801715,
              21.02742128748679
            ],
            [
              105.82806310028975,
              21.027422380265456
            ],
            [
              105.82804743523148,
              21.027424885316115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2532",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02742102850856,
          "to_latitude": 21.027424885316115,
          "to_longitude": 105.82804743523148,
          "from_longitude": 105.82807155661725
        }
      },
      {
        "id": 2526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828040453199,
            21.029014241080223,
            105.82843543423753,
            21.029054626056663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828040453199,
              21.029054626056663
            ],
            [
              105.82819588718351,
              21.02902011106749
            ],
            [
              105.82820361636624,
              21.02905306910813
            ],
            [
              105.8283997866851,
              21.029020201708246
            ],
            [
              105.82840831580108,
              21.029018775513393
            ],
            [
              105.82842910132017,
              21.029015300706345
            ],
            [
              105.82843543423753,
              21.029014241080223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Ngách 35/2, Cát Linh",
          "maTaiSan": "04.O13.DODV.2533",
          "diaChiLapD": "Ngách 35/2, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029054626056663,
          "to_latitude": 21.029014241080223,
          "to_longitude": 105.82843543423753,
          "from_longitude": 105.828040453199
        }
      },
      {
        "id": 2527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833608100161,
            21.02865193061299,
            105.82843434251477,
            21.02912950052189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82843434251477,
              21.02912950052189
            ],
            [
              105.82840954541899,
              21.02903168382325
            ],
            [
              105.82840831580108,
              21.029018775513393
            ],
            [
              105.82840170606724,
              21.028949382285482
            ],
            [
              105.82837532901407,
              21.028824864933707
            ],
            [
              105.8283675763557,
              21.028790622630172
            ],
            [
              105.82836582111563,
              21.028782870677837
            ],
            [
              105.82833608100161,
              21.02865193061299
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C1_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2534",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02912950052189,
          "to_latitude": 21.02865193061299,
          "to_longitude": 105.82833608100161,
          "from_longitude": 105.82843434251477
        }
      },
      {
        "id": 2528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839204076973,
            21.028850016850043,
            105.82842875925775,
            21.028856281660175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839204076973,
              21.028856281660175
            ],
            [
              105.82839489625137,
              21.02885579536284
            ],
            [
              105.8284124664152,
              21.028852796866996
            ],
            [
              105.82842875925775,
              21.028850016850043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C3_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2535",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028856281660175,
          "to_latitude": 21.028850016850043,
          "to_longitude": 105.82842875925775,
          "from_longitude": 105.82839204076973
        }
      },
      {
        "id": 2529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853028169512,
            21.02911553273338,
            105.82913933864621,
            21.029226849552806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82853028169512,
              21.029226849552806
            ],
            [
              105.8287826733275,
              21.02918188364836
            ],
            [
              105.82878524642155,
              21.029177823759436
            ],
            [
              105.82878821771406,
              21.029177577148538
            ],
            [
              105.82879099764868,
              21.029177345921855
            ],
            [
              105.82879457845317,
              21.029180678527077
            ],
            [
              105.82879915569364,
              21.029179795444378
            ],
            [
              105.8288023609733,
              21.029175586151545
            ],
            [
              105.82880492259723,
              21.02917519340838
            ],
            [
              105.82880827913982,
              21.02917467846822
            ],
            [
              105.82881288391248,
              21.029176860793783
            ],
            [
              105.82903680587613,
              21.029134797147325
            ],
            [
              105.82903683082313,
              21.029134785283773
            ],
            [
              105.82913933864621,
              21.02911553273338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1_Cát Linh",
          "maTaiSan": "04.O13.DODV.2536",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029226849552806,
          "to_latitude": 21.02911553273338,
          "to_longitude": 105.82913933864621,
          "from_longitude": 105.82853028169512
        }
      },
      {
        "id": 2530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285436254861,
            21.02858995535473,
            105.82885000506195,
            21.028657139072624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285436254861,
              21.028657139072624
            ],
            [
              105.82868025972392,
              21.028628302600506
            ],
            [
              105.82877140416674,
              21.028607712884828
            ],
            [
              105.82885000506195,
              21.02858995535473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D12_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2537",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028657139072624,
          "to_latitude": 21.02858995535473,
          "to_longitude": 105.82885000506195,
          "from_longitude": 105.8285436254861
        }
      },
      {
        "id": 2531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883095337222,
            21.028704639371163,
            105.82886149229024,
            21.028842343301278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886149229024,
              21.028842343301278
            ],
            [
              105.82884890711078,
              21.028785627070356
            ],
            [
              105.82883095337222,
              21.028704639371163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2538",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028842343301278,
          "to_latitude": 21.028704639371163,
          "to_longitude": 105.82883095337222,
          "from_longitude": 105.82886149229024
        }
      },
      {
        "id": 2532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285436254861,
            21.02858081357727,
            105.82856961599045,
            21.028766355855893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82856961599045,
              21.028766355855893
            ],
            [
              105.8285436254861,
              21.028657139072624
            ],
            [
              105.82854369422343,
              21.02858081357727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D12_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2539",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028766355855893,
          "to_latitude": 21.02858081357727,
          "to_longitude": 105.82854369422343,
          "from_longitude": 105.82856961599045
        }
      },
      {
        "id": 2533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853365892399,
            21.02877391762866,
            105.82879401198494,
            21.029208023690085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82879401198494,
              21.029208023690085
            ],
            [
              105.82879158986802,
              21.029195196164242
            ],
            [
              105.82878821771406,
              21.029177577148538
            ],
            [
              105.82878756389225,
              21.029174161631833
            ],
            [
              105.82873209832097,
              21.028882881131583
            ],
            [
              105.82856237441304,
              21.028912118244534
            ],
            [
              105.82855615410963,
              21.028913187261974
            ],
            [
              105.82853365892399,
              21.02877391762866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D12_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2540",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029208023690085,
          "to_latitude": 21.02877391762866,
          "to_longitude": 105.82853365892399,
          "from_longitude": 105.82879401198494
        }
      },
      {
        "id": 2534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82847718511954,
            21.028680062367826,
            105.82875260624152,
            21.028776597791747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82847718511954,
              21.028680062367826
            ],
            [
              105.82849930357496,
              21.028776597791747
            ],
            [
              105.82853365892399,
              21.02877391762866
            ],
            [
              105.82856961599045,
              21.028766355855893
            ],
            [
              105.82875260624152,
              21.028732462091156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D12_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2541",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028680062367826,
          "to_latitude": 21.028732462091156,
          "to_longitude": 105.82875260624152,
          "from_longitude": 105.82847718511954
        }
      },
      {
        "id": 2535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874563471562,
            21.028842343301278,
            105.82886149229024,
            21.0292048385402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881113861698,
              21.0292048385402
            ],
            [
              105.82880830821904,
              21.029191246167354
            ],
            [
              105.82880492259723,
              21.02917519340838
            ],
            [
              105.82880443061686,
              21.029172861875118
            ],
            [
              105.828768713003,
              21.029002430465574
            ],
            [
              105.82874563471562,
              21.028879708067517
            ],
            [
              105.82886149229024,
              21.028842343301278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2542",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0292048385402,
          "to_latitude": 21.028842343301278,
          "to_longitude": 105.82886149229024,
          "from_longitude": 105.82881113861698
        }
      },
      {
        "id": 2536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82837450992092,
            21.02864253323087,
            105.82847755790263,
            21.029099616345874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82847755790263,
              21.029099616345874
            ],
            [
              105.82845744927496,
              21.02901216840615
            ],
            [
              105.82842875925775,
              21.028850016850043
            ],
            [
              105.82842126776413,
              21.02880767858117
            ],
            [
              105.82841796701541,
              21.0287930479771
            ],
            [
              105.82839301598848,
              21.02874587128983
            ],
            [
              105.82837450992092,
              21.02864253323087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C3_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2543",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029099616345874,
          "to_latitude": 21.02864253323087,
          "to_longitude": 105.82837450992092,
          "from_longitude": 105.82847755790263
        }
      },
      {
        "id": 2537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818652740434,
            21.02789758948897,
            105.82856239035408,
            21.02799746998425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818652740434,
              21.027929395838775
            ],
            [
              105.82818804924781,
              21.02792920958849
            ],
            [
              105.8282021639703,
              21.027927470782846
            ],
            [
              105.82827479980841,
              21.027918368370464
            ],
            [
              105.82836105213048,
              21.027916423586007
            ],
            [
              105.82846347924836,
              21.027900802816642
            ],
            [
              105.82848454311781,
              21.02789758948897
            ],
            [
              105.82848673446411,
              21.02790313635204
            ],
            [
              105.8285014962974,
              21.027940502176786
            ],
            [
              105.82852400104998,
              21.02799746998425
            ],
            [
              105.82856239035408,
              21.027981460251702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 35/61, Cát Linh",
          "maTaiSan": "04.O13.DODV.2544",
          "diaChiLapD": "Ngách 35/61, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027929395838775,
          "to_latitude": 21.027981460251702,
          "to_longitude": 105.82856239035408,
          "from_longitude": 105.82818652740434
        }
      },
      {
        "id": 2538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285014962974,
            21.027891377198564,
            105.82863300852239,
            21.027940502176786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285014962974,
              21.027940502176786
            ],
            [
              105.82863300852239,
              21.027891377198564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5_Ngách 35/61, Cát Linh",
          "maTaiSan": "04.O13.DODV.2545",
          "diaChiLapD": "Ngách 35/61, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027940502176786,
          "to_latitude": 21.027891377198564,
          "to_longitude": 105.82863300852239,
          "from_longitude": 105.8285014962974
        }
      },
      {
        "id": 2539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848673446411,
            21.02787027320459,
            105.82853627945254,
            21.02790313635204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82848673446411,
              21.02790313635204
            ],
            [
              105.82853627945254,
              21.02787027320459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5_Ngách 35/61, Cát Linh",
          "maTaiSan": "04.O13.DODV.2546",
          "diaChiLapD": "Ngách 35/61, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02790313635204,
          "to_latitude": 21.02787027320459,
          "to_longitude": 105.82853627945254,
          "from_longitude": 105.82848673446411
        }
      },
      {
        "id": 2540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82811556550959,
            21.02765699099895,
            105.82843870248304,
            21.027743090486226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82811556550959,
              21.027657971135287
            ],
            [
              105.82812583618728,
              21.027657660086586
            ],
            [
              105.82814786552014,
              21.02765699099895
            ],
            [
              105.82838827965753,
              21.027728165569567
            ],
            [
              105.82843870248304,
              21.027743090486226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E71_Ngách 35/71, Cát Linh",
          "maTaiSan": "04.O13.DODV.2547",
          "diaChiLapD": "Ngách 35/71, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027657971135287,
          "to_latitude": 21.027743090486226,
          "to_longitude": 105.82843870248304,
          "from_longitude": 105.82811556550959
        }
      },
      {
        "id": 2541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82843870248304,
            21.027743090486226,
            105.82848148859551,
            21.02775744924005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82843870248304,
              21.027743090486226
            ],
            [
              105.82848148859551,
              21.02775744924005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E71_Ngách 35/71, Cát Linh",
          "maTaiSan": "04.O13.DODV.2548",
          "diaChiLapD": "Ngách 35/71, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027743090486226,
          "to_latitude": 21.02775744924005,
          "to_longitude": 105.82848148859551,
          "from_longitude": 105.82843870248304
        }
      },
      {
        "id": 2542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82815777584182,
            21.027750608011726,
            105.82826976515267,
            21.028222971893747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82826976515267,
              21.028222971893747
            ],
            [
              105.82824877190188,
              21.028142520082476
            ],
            [
              105.82825390740413,
              21.028125677031092
            ],
            [
              105.8282021639703,
              21.027927470782846
            ],
            [
              105.82820095806247,
              21.02792285305037
            ],
            [
              105.8281724377355,
              21.02781360786412
            ],
            [
              105.82816894578771,
              21.02780023095569
            ],
            [
              105.82816670113394,
              21.02779025674159
            ],
            [
              105.82815777584182,
              21.027750608011726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2549",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028222971893747,
          "to_latitude": 21.027750608011726,
          "to_longitude": 105.82815777584182,
          "from_longitude": 105.82826976515267
        }
      },
      {
        "id": 2543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789978520627,
            21.028538044347776,
            105.82833402599172,
            21.028632259184338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833402599172,
              21.02854275525643
            ],
            [
              105.82833257901943,
              21.028543086562568
            ],
            [
              105.82811725442676,
              21.028592307900144
            ],
            [
              105.82799271760234,
              21.028621334306457
            ],
            [
              105.82792752885689,
              21.028632259184338
            ],
            [
              105.82789978520627,
              21.028538044347776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C5_Ngách 35/26, Cát Linh",
          "maTaiSan": "04.O13.DODV.2550",
          "diaChiLapD": "Ngách 35/26, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02854275525643,
          "to_latitude": 21.028538044347776,
          "to_longitude": 105.82789978520627,
          "from_longitude": 105.82833402599172
        }
      },
      {
        "id": 2544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82780303538048,
            21.02825978302367,
            105.82783114855476,
            21.02833017444241
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82783114855476,
              21.02833017444241
            ],
            [
              105.82780303538048,
              21.02825978302367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N7_Ngách 35/46, Cát Linh",
          "maTaiSan": "04.O13.DODV.2551",
          "diaChiLapD": "Ngách 35/46, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02833017444241,
          "to_latitude": 21.02825978302367,
          "to_longitude": 105.82780303538048,
          "from_longitude": 105.82783114855476
        }
      },
      {
        "id": 2545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573710475324,
            21.028029893217127,
            105.82577761281135,
            21.028060870409167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82577761281135,
              21.028060870409167
            ],
            [
              105.82573710475324,
              21.028029893217127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1_Ngách 149/4, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2552",
          "diaChiLapD": "Ngách 149/4, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028060870409167,
          "to_latitude": 21.028029893217127,
          "to_longitude": 105.82573710475324,
          "from_longitude": 105.82577761281135
        }
      },
      {
        "id": 2546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82660315591538,
            21.02847289557223,
            105.82673989163902,
            21.02864753762981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82660315591538,
              21.02864753762981
            ],
            [
              105.82673989163902,
              21.02847289557223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2553",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02864753762981,
          "to_latitude": 21.02847289557223,
          "to_longitude": 105.82673989163902,
          "from_longitude": 105.82660315591538
        }
      },
      {
        "id": 2547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8258957539083,
            21.02726506019858,
            105.82594751383132,
            21.027326387654174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82594751383132,
              21.02726506019858
            ],
            [
              105.8258957539083,
              21.027326387654174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT56_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2554",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02726506019858,
          "to_latitude": 21.027326387654174,
          "to_longitude": 105.8258957539083,
          "from_longitude": 105.82594751383132
        }
      },
      {
        "id": 2548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8260040921142,
            21.027458745694528,
            105.82610085115175,
            21.027531477525386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82610085115175,
              21.027531477525386
            ],
            [
              105.8260040921142,
              21.027458745694528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT61_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2555",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027531477525386,
          "to_latitude": 21.027458745694528,
          "to_longitude": 105.8260040921142,
          "from_longitude": 105.82610085115175
        }
      },
      {
        "id": 2549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82385462209653,
            21.02550045339419,
            105.82398673390095,
            21.02565099249479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82398673390095,
              21.02565099249479
            ],
            [
              105.82394421878092,
              21.025649525709493
            ],
            [
              105.82390563799102,
              21.025585345173653
            ],
            [
              105.82385462209653,
              21.02550045339419
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_K7_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2556",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02565099249479,
          "to_latitude": 21.02550045339419,
          "to_longitude": 105.82385462209653,
          "from_longitude": 105.82398673390095
        }
      },
      {
        "id": 2550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591179248467,
            21.025719934148242,
            105.82594877875276,
            21.025803074317146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82594877875276,
              21.025803074317146
            ],
            [
              105.82591657124779,
              21.025730675575797
            ],
            [
              105.82591179248467,
              21.025719934148242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT51_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2557",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025803074317146,
          "to_latitude": 21.025719934148242,
          "to_longitude": 105.82591179248467,
          "from_longitude": 105.82594877875276
        }
      },
      {
        "id": 2551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570283376181,
            21.025801128118307,
            105.82574192255308,
            21.02588805167083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574192255308,
              21.02588805167083
            ],
            [
              105.82574145603932,
              21.025887014324013
            ],
            [
              105.82570283376181,
              21.025801128118307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT51_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2558",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02588805167083,
          "to_latitude": 21.025801128118307,
          "to_longitude": 105.82570283376181,
          "from_longitude": 105.82574192255308
        }
      },
      {
        "id": 2552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82577821790349,
            21.02638264667553,
            105.82638773711604,
            21.026617865479047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82577821790349,
              21.026617865479047
            ],
            [
              105.82637983852307,
              21.026385694603313
            ],
            [
              105.82638773711604,
              21.02638264667553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT30_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2559",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026617865479047,
          "to_latitude": 21.02638264667553,
          "to_longitude": 105.82638773711604,
          "from_longitude": 105.82577821790349
        }
      },
      {
        "id": 2553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578056453475,
            21.02669097898418,
            105.82578934609288,
            21.026692489367516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578934609288,
              21.02669097898418
            ],
            [
              105.82578428781586,
              21.026691846255847
            ],
            [
              105.82578056453475,
              21.026692489367516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT30_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2560",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02669097898418,
          "to_latitude": 21.026692489367516,
          "to_longitude": 105.82578056453475,
          "from_longitude": 105.82578934609288
        }
      },
      {
        "id": 2554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82571064976926,
            21.026093418022267,
            105.82620272970749,
            21.026275976077514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82571064976926,
              21.026275976077514
            ],
            [
              105.82598864279787,
              21.026157786699784
            ],
            [
              105.82605788518156,
              21.026147154510966
            ],
            [
              105.82620272970749,
              21.026093418022267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2561",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026275976077514,
          "to_latitude": 21.026093418022267,
          "to_longitude": 105.82620272970749,
          "from_longitude": 105.82571064976926
        }
      },
      {
        "id": 2555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257577942204,
            21.026400358340016,
            105.82576886194447,
            21.026404406143154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257577942204,
              21.026404406143154
            ],
            [
              105.8257643812768,
              21.026401995966665
            ],
            [
              105.82576886194447,
              21.026400358340016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2562",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026404406143154,
          "to_latitude": 21.026400358340016,
          "to_longitude": 105.82576886194447,
          "from_longitude": 105.8257577942204
        }
      },
      {
        "id": 2556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82620738772728,
            21.0262612173325,
            105.82625218290077,
            21.02630059733576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82621605533714,
              21.02630059733576
            ],
            [
              105.82620738772728,
              21.02628245662574
            ],
            [
              105.82621406104523,
              21.02627929272349
            ],
            [
              105.82623227233809,
              21.02627065793079
            ],
            [
              105.82625218290077,
              21.0262612173325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2563",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02630059733576,
          "to_latitude": 21.0262612173325,
          "to_longitude": 105.82625218290077,
          "from_longitude": 105.82621605533714
        }
      },
      {
        "id": 2557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82562791765852,
            21.025843636271016,
            105.82608491507528,
            21.026046884538207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82562791765852,
              21.026046884538207
            ],
            [
              105.82608491507528,
              21.025843636271016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2564",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026046884538207,
          "to_latitude": 21.025843636271016,
          "to_longitude": 105.82608491507528,
          "from_longitude": 105.82562791765852
        }
      },
      {
        "id": 2558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564084585093,
            21.02613023784712,
            105.8256647347575,
            21.02613977536956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256647347575,
              21.02613023784712
            ],
            [
              105.82565126646695,
              21.026135614200115
            ],
            [
              105.82564084585093,
              21.02613977536956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2565",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02613023784712,
          "to_latitude": 21.02613977536956,
          "to_longitude": 105.82564084585093,
          "from_longitude": 105.8256647347575
        }
      },
      {
        "id": 2559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82576961816909,
            21.026161984562524,
            105.82577737160435,
            21.0261800872843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82577737160435,
              21.0261800872843
            ],
            [
              105.82576961816909,
              21.026161984562524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2566",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0261800872843,
          "to_latitude": 21.026161984562524,
          "to_longitude": 105.82576961816909,
          "from_longitude": 105.82577737160435
        }
      },
      {
        "id": 2560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82593735312945,
            21.026093819222638,
            105.82594510657586,
            21.026111921033156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82594510657586,
              21.026111921033156
            ],
            [
              105.82593735312945,
              21.026093819222638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2567",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026111921033156,
          "to_latitude": 21.026093819222638,
          "to_longitude": 105.82593735312945,
          "from_longitude": 105.82594510657586
        }
      },
      {
        "id": 2561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832983263812,
            21.02852384838573,
            105.82834768032724,
            21.028527626805207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82834768032724,
              21.02852384838573
            ],
            [
              105.82834323906425,
              21.028524788599835
            ],
            [
              105.82833188607485,
              21.028527192286937
            ],
            [
              105.82832983263812,
              21.028527626805207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2568",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02852384838573,
          "to_latitude": 21.028527626805207,
          "to_longitude": 105.82832983263812,
          "from_longitude": 105.82834768032724
        }
      },
      {
        "id": 2562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82855517396189,
            21.028912118244534,
            105.82857428413492,
            21.029030437682085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82856237441304,
              21.028912118244534
            ],
            [
              105.82855517396189,
              21.028925571629287
            ],
            [
              105.82857428413492,
              21.029030437682085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D12_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2569",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028912118244534,
          "to_latitude": 21.029030437682085,
          "to_longitude": 105.82857428413492,
          "from_longitude": 105.82856237441304
        }
      },
      {
        "id": 2563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82824895354138,
            21.02825474343521,
            105.82830333120013,
            21.028468142190064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82827204753129,
              21.02825474343521
            ],
            [
              105.82826800666314,
              21.028255543503533
            ],
            [
              105.82824895354138,
              21.02825931243045
            ],
            [
              105.82826118094408,
              21.028310002340234
            ],
            [
              105.82830333120013,
              21.028468142190064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C6_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2570",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02825474343521,
          "to_latitude": 21.028468142190064,
          "to_longitude": 105.82830333120013,
          "from_longitude": 105.82827204753129
        }
      },
      {
        "id": 2564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817159513323,
            21.027941368004775,
            105.82819229157583,
            21.02794457228132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82817159513323,
              21.02794457228132
            ],
            [
              105.82818581024046,
              21.027942371440425
            ],
            [
              105.82819141785465,
              21.02794150413085
            ],
            [
              105.82819229157583,
              21.027941368004775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P1_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2571",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02794457228132,
          "to_latitude": 21.027941368004775,
          "to_longitude": 105.82819229157583,
          "from_longitude": 105.82817159513323
        }
      },
      {
        "id": 2565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82831027280594,
            21.0274879327393,
            105.8283271792701,
            21.02753528172803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82831027280594,
              21.02753528172803
            ],
            [
              105.8283271792701,
              21.0274879327393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E9_Ngách 35/75, Cát Linh",
          "maTaiSan": "04.O13.DODV.2572",
          "diaChiLapD": "Ngách 35/75, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02753528172803,
          "to_latitude": 21.0274879327393,
          "to_longitude": 105.8283271792701,
          "from_longitude": 105.82831027280594
        }
      },
      {
        "id": 2566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82815044933682,
            21.02779025674159,
            105.82816670113394,
            21.027793829561784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82816670113394,
              21.02779025674159
            ],
            [
              105.82815873954114,
              21.02779200712594
            ],
            [
              105.82815044933682,
              21.027793829561784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2573",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02779025674159,
          "to_latitude": 21.027793829561784,
          "to_longitude": 105.82815044933682,
          "from_longitude": 105.82816670113394
        }
      },
      {
        "id": 2567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82805003287125,
            21.027290474670384,
            105.82810449853346,
            21.027506446773167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82808230420916,
              21.027502176955338
            ],
            [
              105.82808043706102,
              21.02750237301509
            ],
            [
              105.82807591475678,
              21.02750274368149
            ],
            [
              105.82807612665393,
              21.027506446773167
            ],
            [
              105.82808266824426,
              21.027505731177342
            ],
            [
              105.82810449853346,
              21.027503341519377
            ],
            [
              105.82809340624864,
              21.02745940940744
            ],
            [
              105.82806878507145,
              21.027366173827364
            ],
            [
              105.82805003287125,
              21.027290474670384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7*_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2574",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027502176955338,
          "to_latitude": 21.027290474670384,
          "to_longitude": 105.82805003287125,
          "from_longitude": 105.82808230420916
        }
      },
      {
        "id": 2568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82575337719794,
            21.02715173637687,
            105.82580408450492,
            21.027213448871926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82580408450492,
              21.02715173637687
            ],
            [
              105.82575337719794,
              21.027213448871926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT56_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2575",
          "diaChiLapD": "Ngõ 29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02715173637687,
          "to_latitude": 21.027213448871926,
          "to_longitude": 105.82575337719794,
          "from_longitude": 105.82580408450492
        }
      },
      {
        "id": 2569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840954541899,
            21.029025820434985,
            105.82843876719262,
            21.02903168382325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840954541899,
              21.02903168382325
            ],
            [
              105.82843262955684,
              21.029027052528885
            ],
            [
              105.82843876719262,
              21.029025820434985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C1_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2576",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02903168382325,
          "to_latitude": 21.029025820434985,
          "to_longitude": 105.82843876719262,
          "from_longitude": 105.82840954541899
        }
      },
      {
        "id": 2570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82790395126922,
            21.029338984445875,
            105.82790950540644,
            21.029372316186308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82790395126922,
              21.029338984445875
            ],
            [
              105.82790751568217,
              21.029360252474934
            ],
            [
              105.82790950540644,
              21.029372316186308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2_Cát Linh",
          "maTaiSan": "04.O13.DODV.2577",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029338984445875,
          "to_latitude": 21.029372316186308,
          "to_longitude": 105.82790950540644,
          "from_longitude": 105.82790395126922
        }
      },
      {
        "id": 2571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903680587613,
            21.029134797147325,
            105.8290416162595,
            21.029161977901616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903680587613,
              21.029134797147325
            ],
            [
              105.82903968101509,
              21.029150909293083
            ],
            [
              105.8290416162595,
              21.029161977901616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1_Cát Linh",
          "maTaiSan": "04.O13.DODV.2578",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029134797147325,
          "to_latitude": 21.029161977901616,
          "to_longitude": 105.8290416162595,
          "from_longitude": 105.82903680587613
        }
      },
      {
        "id": 2572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886149229024,
            21.028836937807363,
            105.82901020705837,
            21.029069604755673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886149229024,
              21.028842343301278
            ],
            [
              105.82887274174641,
              21.028840351050132
            ],
            [
              105.82889200372522,
              21.028836937807363
            ],
            [
              105.82890133061301,
              21.028866526224284
            ],
            [
              105.82896287607231,
              21.02885714427924
            ],
            [
              105.82900776359023,
              21.029058659656368
            ],
            [
              105.82901020705837,
              21.029069604755673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D11_Ngõ 33, Cát Linh",
          "maTaiSan": "04.O13.DODV.2579",
          "diaChiLapD": "Ngõ 33, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028842343301278,
          "to_latitude": 21.029069604755673,
          "to_longitude": 105.82901020705837,
          "from_longitude": 105.82886149229024
        }
      },
      {
        "id": 2573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817732348317,
            21.027453350180547,
            105.82819883352543,
            21.027514574331374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819883352543,
              21.027453350180547
            ],
            [
              105.82817732348317,
              21.027514574331374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E9_Ngách 35/75, Cát Linh",
          "maTaiSan": "04.O13.DODV.2580",
          "diaChiLapD": "Ngách 35/75, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027453350180547,
          "to_latitude": 21.027514574331374,
          "to_longitude": 105.82817732348317,
          "from_longitude": 105.82819883352543
        }
      },
      {
        "id": 2574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82373781679173,
            21.02601403085976,
            105.82392656965337,
            21.026102201498155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392656965337,
              21.026075353707032
            ],
            [
              105.82392029654063,
              21.026081852999273
            ],
            [
              105.82391116326953,
              21.026091307049914
            ],
            [
              105.82384207598284,
              21.026032684620752
            ],
            [
              105.82382107968085,
              21.02601403085976
            ],
            [
              105.82373781679173,
              21.026102201498155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17*_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2581",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026075353707032,
          "to_latitude": 21.026102201498155,
          "to_longitude": 105.82373781679173,
          "from_longitude": 105.82392656965337
        }
      },
      {
        "id": 2575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82771081201072,
            21.02909228950036,
            105.82778037114691,
            21.029396431427312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82778037114691,
              21.029396431427312
            ],
            [
              105.82777792476661,
              21.02938564077387
            ],
            [
              105.82771081201072,
              21.02909228950036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3_Hào Nam",
          "maTaiSan": "04.O13.DODV.2582",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029396431427312,
          "to_latitude": 21.02909228950036,
          "to_longitude": 105.82771081201072,
          "from_longitude": 105.82778037114691
        }
      },
      {
        "id": 2576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82661824003328,
            21.02638346365407,
            105.82690066750185,
            21.02646817818172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82690066750185,
              21.026409984154
            ],
            [
              105.82689733242842,
              21.026401824379924
            ],
            [
              105.82688265172112,
              21.0263907499695
            ],
            [
              105.82687299242264,
              21.02638346365407
            ],
            [
              105.82676857462741,
              21.026422255418122
            ],
            [
              105.82661824003328,
              21.02646817818172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6#_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2583",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026409984154,
          "to_latitude": 21.02646817818172,
          "to_longitude": 105.82661824003328,
          "from_longitude": 105.82690066750185
        }
      },
      {
        "id": 2577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521790344651,
            21.02725238044022,
            105.82523359443942,
            21.02726488983228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82521790344651,
              21.02725238044022
            ],
            [
              105.82522489994864,
              21.027257932934482
            ],
            [
              105.82523359443942,
              21.02726488983228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2584",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02725238044022,
          "to_latitude": 21.02726488983228,
          "to_longitude": 105.82523359443942,
          "from_longitude": 105.82521790344651
        }
      },
      {
        "id": 2578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552396282472,
            21.02814288641512,
            105.8259133246679,
            21.028428045609957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82588959835361,
              21.028428045609957
            ],
            [
              105.82590849604729,
              21.02840705139796
            ],
            [
              105.8259133246679,
              21.028401682661293
            ],
            [
              105.82572112201399,
              21.028272857910924
            ],
            [
              105.82552396282472,
              21.02814288641512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2585",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028428045609957,
          "to_latitude": 21.02814288641512,
          "to_longitude": 105.82552396282472,
          "from_longitude": 105.82588959835361
        }
      },
      {
        "id": 2579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82507680878919,
            21.02694929153386,
            105.8252888323644,
            21.02718977060764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82507680878919,
              21.02694929153386
            ],
            [
              105.82525301262872,
              21.027105338454305
            ],
            [
              105.82526353552845,
              21.02717024371512
            ],
            [
              105.82526372277637,
              21.027171404353734
            ],
            [
              105.8252888323644,
              21.02718977060764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2586",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02694929153386,
          "to_latitude": 21.02718977060764,
          "to_longitude": 105.8252888323644,
          "from_longitude": 105.82507680878919
        }
      },
      {
        "id": 2580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82504964454492,
            21.02693374605111,
            105.82528506239127,
            21.027192943868585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504964454492,
              21.02693374605111
            ],
            [
              105.8252272100311,
              21.027093543809535
            ],
            [
              105.82524368259675,
              21.027158032716102
            ],
            [
              105.8252605161207,
              21.02717292830124
            ],
            [
              105.82526443328405,
              21.027176395739776
            ],
            [
              105.82528506239127,
              21.027192943868585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2*_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2587",
          "diaChiLapD": "Ngõ 2, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02693374605111,
          "to_latitude": 21.027192943868585,
          "to_longitude": 105.82528506239127,
          "from_longitude": 105.82504964454492
        }
      },
      {
        "id": 2581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82644381041355,
            21.02862565129415,
            105.82657123058877,
            21.02889600710466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265677280702,
              21.02862565129415
            ],
            [
              105.82644381041355,
              21.02877716256511
            ],
            [
              105.82657123058877,
              21.028864444141124
            ],
            [
              105.82655876908628,
              21.028881988433998
            ],
            [
              105.82654879228593,
              21.02889600710466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2588",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02862565129415,
          "to_latitude": 21.02889600710466,
          "to_longitude": 105.82654879228593,
          "from_longitude": 105.8265677280702
        }
      },
      {
        "id": 2582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82616427883625,
            21.028578562288963,
            105.82620741657072,
            21.028634986777973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82616427883625,
              21.028634986777973
            ],
            [
              105.82616975940098,
              21.0286278174496
            ],
            [
              105.82620741657072,
              21.028578562288963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9*_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2589",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028634986777973,
          "to_latitude": 21.028578562288963,
          "to_longitude": 105.82620741657072,
          "from_longitude": 105.82616427883625
        }
      },
      {
        "id": 2583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420509863836,
            21.027976733872457,
            105.83433668497557,
            21.028158189863113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420509863836,
              21.027976733872457
            ],
            [
              105.83428048052613,
              21.028075301333104
            ],
            [
              105.83433668497557,
              21.028158189863113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X1A_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2590",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027976733872457,
          "to_latitude": 21.028158189863113,
          "to_longitude": 105.83433668497557,
          "from_longitude": 105.83420509863836
        }
      },
      {
        "id": 2584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83433668497557,
            21.02815124293694,
            105.83434814626004,
            21.028158189863113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83433668497557,
              21.028158189863113
            ],
            [
              105.83434170036828,
              21.028155150295444
            ],
            [
              105.83434814626004,
              21.02815124293694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X1A_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2591",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028158189863113,
          "to_latitude": 21.02815124293694,
          "to_longitude": 105.83434814626004,
          "from_longitude": 105.83433668497557
        }
      },
      {
        "id": 2585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83433668497557,
            21.028158189863113,
            105.83448309809009,
            21.028376076747403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83433668497557,
              21.028158189863113
            ],
            [
              105.83448309809009,
              21.028376076747403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_X1A_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.2592",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028158189863113,
          "to_latitude": 21.028376076747403,
          "to_longitude": 105.83448309809009,
          "from_longitude": 105.83433668497557
        }
      },
      {
        "id": 2586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8264762381447,
            21.02878656197578,
            105.82682818108593,
            21.02903307519086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8264762381447,
              21.02878656197578
            ],
            [
              105.82682818108593,
              21.02903307519086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2593",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02878656197578,
          "to_latitude": 21.02903307519086,
          "to_longitude": 105.82682818108593,
          "from_longitude": 105.8264762381447
        }
      },
      {
        "id": 2587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82682818108593,
            21.02903307519086,
            105.82699107754185,
            21.029145854471533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82682818108593,
              21.02903307519086
            ],
            [
              105.82699107754185,
              21.029145854471533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2594",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02903307519086,
          "to_latitude": 21.029145854471533,
          "to_longitude": 105.82699107754185,
          "from_longitude": 105.82682818108593
        }
      },
      {
        "id": 2588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82679586745898,
            21.02903307519086,
            105.82682818108593,
            21.029071408001233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82679586745898,
              21.029071408001233
            ],
            [
              105.82680848082751,
              21.029056457917292
            ],
            [
              105.82682818108593,
              21.02903307519086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2595",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029071408001233,
          "to_latitude": 21.02903307519086,
          "to_longitude": 105.82682818108593,
          "from_longitude": 105.82679586745898
        }
      },
      {
        "id": 2589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82690453659288,
            21.0288159056221,
            105.82704761268833,
            21.028978098201147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82690453659288,
              21.0288159056221
            ],
            [
              105.82692592488259,
              21.02883240649058
            ],
            [
              105.82695067001966,
              21.02885106238291
            ],
            [
              105.82691965056448,
              21.028896752008038
            ],
            [
              105.82702416384825,
              21.028963191626826
            ],
            [
              105.82704761268833,
              21.028978098201147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8_Ngõ 129, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2596",
          "diaChiLapD": "Ngõ 129, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0288159056221,
          "to_latitude": 21.028978098201147,
          "to_longitude": 105.82704761268833,
          "from_longitude": 105.82690453659288
        }
      },
      {
        "id": 2590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82576821611804,
            21.027975623216946,
            105.82584678652029,
            21.028072450221458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82576821611804,
              21.028072450221458
            ],
            [
              105.82577761281135,
              21.028060870409167
            ],
            [
              105.82584678652029,
              21.027975623216946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1_Ngách 149/4, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2597",
          "diaChiLapD": "Ngách 149/4, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028072450221458,
          "to_latitude": 21.027975623216946,
          "to_longitude": 105.82584678652029,
          "from_longitude": 105.82576821611804
        }
      },
      {
        "id": 2591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82491065943414,
            21.02739891032407,
            105.82516170282118,
            21.02780536179861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82510851621461,
              21.02739891032407
            ],
            [
              105.82491065943414,
              21.0276129979119
            ],
            [
              105.82516170282118,
              21.02780536179861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2598",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02739891032407,
          "to_latitude": 21.02780536179861,
          "to_longitude": 105.82516170282118,
          "from_longitude": 105.82510851621461
        }
      },
      {
        "id": 2592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82512060202143,
            21.02780536179861,
            105.82539890562374,
            21.027987119968852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82512060202143,
              21.027844231907295
            ],
            [
              105.82513233077289,
              21.02783371745772
            ],
            [
              105.82516170282118,
              21.02780536179861
            ],
            [
              105.82522358776446,
              21.027852781573074
            ],
            [
              105.82539890562374,
              21.027987119968852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2599",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027844231907295,
          "to_latitude": 21.027987119968852,
          "to_longitude": 105.82539890562374,
          "from_longitude": 105.82512060202143
        }
      },
      {
        "id": 2593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564125438704,
            21.027215538790184,
            105.8256965377412,
            21.027377588091447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82564532968378,
              21.027215538790184
            ],
            [
              105.82565541285298,
              21.027254021408226
            ],
            [
              105.8256965377412,
              21.027294155237456
            ],
            [
              105.82564125438704,
              21.027377588091447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 23, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2600",
          "diaChiLapD": "Ngõ 23, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027215538790184,
          "to_latitude": 21.027377588091447,
          "to_longitude": 105.82564125438704,
          "from_longitude": 105.82564532968378
        }
      },
      {
        "id": 2594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82576691568588,
            21.027384084503403,
            105.8259340982239,
            21.027503503554737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259340982239,
              21.027503503554737
            ],
            [
              105.82576691568588,
              21.027384084503403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H7BS_Ngách 29/29, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2601",
          "diaChiLapD": "Ngách 29/29, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027503503554737,
          "to_latitude": 21.027384084503403,
          "to_longitude": 105.82576691568588,
          "from_longitude": 105.8259340982239
        }
      },
      {
        "id": 2595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82636879462828,
            21.027737797650413,
            105.8264935969263,
            21.027889968904333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82636879462828,
              21.027889968904333
            ],
            [
              105.82641595696823,
              21.027833684678573
            ],
            [
              105.8264935969263,
              21.027737797650413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H91_Ngách 29/46, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2602",
          "diaChiLapD": "Ngách 29/46, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027889968904333,
          "to_latitude": 21.027737797650413,
          "to_longitude": 105.8264935969263,
          "from_longitude": 105.82636879462828
        }
      },
      {
        "id": 2596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82653513302287,
            21.0278546533552,
            105.82665114901029,
            21.027993938159486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82653513302287,
              21.027993938159486
            ],
            [
              105.82665114901029,
              21.0278546533552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H91_Ngách 29/48, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2603",
          "diaChiLapD": "Ngách 29/48, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 255,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027993938159486,
          "to_latitude": 21.0278546533552,
          "to_longitude": 105.82665114901029,
          "from_longitude": 105.82653513302287
        }
      },
      {
        "id": 2597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82641629803624,
            21.027993938159486,
            105.82653513302287,
            21.02805610991463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82641629803624,
              21.028025896463575
            ],
            [
              105.8264193768369,
              21.028020724087845
            ],
            [
              105.82642226205105,
              21.02801586065252
            ],
            [
              105.82647973652315,
              21.02805610991463
            ],
            [
              105.8265262709248,
              21.02800388418978
            ],
            [
              105.82653513302287,
              21.027993938159486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H91_Ngách 29/48, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2604",
          "diaChiLapD": "Ngách 29/48, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028025896463575,
          "to_latitude": 21.027993938159486,
          "to_longitude": 105.82653513302287,
          "from_longitude": 105.82641629803624
        }
      },
      {
        "id": 2598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82636879462828,
            21.027889968904333,
            105.8265262709248,
            21.02800388418978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265262709248,
              21.02800388418978
            ],
            [
              105.82636879462828,
              21.027889968904333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H91_Ngách 29/48, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2605",
          "diaChiLapD": "Ngách 29/48, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02800388418978,
          "to_latitude": 21.027889968904333,
          "to_longitude": 105.82636879462828,
          "from_longitude": 105.8265262709248
        }
      },
      {
        "id": 2599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8269442959479,
            21.027851203621424,
            105.82715086675049,
            21.028097275714654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269442959479,
              21.028097275714654
            ],
            [
              105.8270433201139,
              21.02803760853097
            ],
            [
              105.82711795476574,
              21.027992693065887
            ],
            [
              105.82715086675049,
              21.027851203621424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H92_Ngách 29/64, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2606",
          "diaChiLapD": "Ngách 29/64, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028097275714654,
          "to_latitude": 21.027851203621424,
          "to_longitude": 105.82715086675049,
          "from_longitude": 105.8269442959479
        }
      },
      {
        "id": 2600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82593042131903,
            21.026672407303913,
            105.82623331293817,
            21.0267975662773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593042131903,
              21.0267975662773
            ],
            [
              105.82600630658597,
              21.02677102946734
            ],
            [
              105.82623331293817,
              21.026672407303913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K3_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2607",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0267975662773,
          "to_latitude": 21.026672407303913,
          "to_longitude": 105.82623331293817,
          "from_longitude": 105.82593042131903
        }
      },
      {
        "id": 2601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82567358563233,
            21.026774743020347,
            105.82593042131903,
            21.026947561088374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82567358563233,
              21.026947561088374
            ],
            [
              105.82593042131903,
              21.0267975662773
            ],
            [
              105.8259225974714,
              21.026778915649654
            ],
            [
              105.82592068220903,
              21.026774743020347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K3_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2608",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026947561088374,
          "to_latitude": 21.026774743020347,
          "to_longitude": 105.82592068220903,
          "from_longitude": 105.82567358563233
        }
      },
      {
        "id": 2602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82657143755897,
            21.026384680548496,
            105.82702580817997,
            21.02654432354556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702580817997,
              21.026384680548496
            ],
            [
              105.82658091070799,
              21.02654432354556
            ],
            [
              105.82657592969008,
              21.026534425831585
            ],
            [
              105.82657143755897,
              21.02652560870775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K5_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2609",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026384680548496,
          "to_latitude": 21.02652560870775,
          "to_longitude": 105.82657143755897,
          "from_longitude": 105.82702580817997
        }
      },
      {
        "id": 2603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8263030894806,
            21.02654432354556,
            105.82658091070799,
            21.026644501851926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82658091070799,
              21.02654432354556
            ],
            [
              105.82645868021358,
              21.02658818298848
            ],
            [
              105.8263030894806,
              21.026644501851926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K5_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2610",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02654432354556,
          "to_latitude": 21.026644501851926,
          "to_longitude": 105.8263030894806,
          "from_longitude": 105.82658091070799
        }
      },
      {
        "id": 2604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82580190664073,
            21.0265087098259,
            105.82646206990381,
            21.02677350707129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82646206990381,
              21.0265087098259
            ],
            [
              105.82644432066569,
              21.02651582949631
            ],
            [
              105.82638331321898,
              21.026540299879024
            ],
            [
              105.82638109492224,
              21.026541189496463
            ],
            [
              105.8262241631299,
              21.02660413453932
            ],
            [
              105.82622203602041,
              21.026604987582697
            ],
            [
              105.82609860322295,
              21.026654500477427
            ],
            [
              105.82609455057951,
              21.02665612609812
            ],
            [
              105.82590783321938,
              21.026731019790343
            ],
            [
              105.8259058683281,
              21.026731807913045
            ],
            [
              105.82580190664073,
              21.02677350707129
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT30_Vũ Thạch",
          "maTaiSan": "04.O13.DODV.2611",
          "diaChiLapD": "Vũ Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0265087098259,
          "to_latitude": 21.02677350707129,
          "to_longitude": 105.82580190664073,
          "from_longitude": 105.82646206990381
        }
      },
      {
        "id": 2605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82577821790349,
            21.026617865479047,
            105.82580190664073,
            21.02677350707129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82580190664073,
              21.02677350707129
            ],
            [
              105.82578934609288,
              21.02669097898418
            ],
            [
              105.82577821790349,
              21.026617865479047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT30_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2612",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02677350707129,
          "to_latitude": 21.026617865479047,
          "to_longitude": 105.82577821790349,
          "from_longitude": 105.82580190664073
        }
      },
      {
        "id": 2606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82579993204358,
            21.026265629808382,
            105.82630363031014,
            21.02646674714675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82630363031014,
              21.026265629808382
            ],
            [
              105.82624656365248,
              21.02628841544572
            ],
            [
              105.82622269684688,
              21.026297945716237
            ],
            [
              105.82621605533714,
              21.02630059733576
            ],
            [
              105.8260691924122,
              21.026359237317187
            ],
            [
              105.82606316426916,
              21.02636164388717
            ],
            [
              105.82589585639748,
              21.026428447024955
            ],
            [
              105.8258894663677,
              21.026430998055964
            ],
            [
              105.82579993204358,
              21.02646674714675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2613",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026265629808382,
          "to_latitude": 21.02646674714675,
          "to_longitude": 105.82579993204358,
          "from_longitude": 105.82630363031014
        }
      },
      {
        "id": 2607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82571064976926,
            21.026275976077514,
            105.82579993204358,
            21.02646674714675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82579993204358,
              21.02646674714675
            ],
            [
              105.82576886194447,
              21.026400358340016
            ],
            [
              105.82573361377344,
              21.026325043369866
            ],
            [
              105.82571064976926,
              21.026275976077514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2614",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02646674714675,
          "to_latitude": 21.026275976077514,
          "to_longitude": 105.82571064976926,
          "from_longitude": 105.82579993204358
        }
      },
      {
        "id": 2608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570054462005,
            21.02602635009705,
            105.82615566340431,
            21.02621130997414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82615566340431,
              21.02602635009705
            ],
            [
              105.82614483911674,
              21.026030748999588
            ],
            [
              105.82614271111753,
              21.026031613788067
            ],
            [
              105.82599704157978,
              21.026090826872156
            ],
            [
              105.82599461506008,
              21.026091812334457
            ],
            [
              105.82594510657586,
              21.026111921033156
            ],
            [
              105.82580166271354,
              21.02617021630613
            ],
            [
              105.82579965373206,
              21.02617103264155
            ],
            [
              105.82577737160435,
              21.0261800872843
            ],
            [
              105.82570054462005,
              21.02621130997414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2615",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02602635009705,
          "to_latitude": 21.02621130997414,
          "to_longitude": 105.82570054462005,
          "from_longitude": 105.82615566340431
        }
      },
      {
        "id": 2609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82562791765852,
            21.026046884538207,
            105.82570054462005,
            21.02621130997414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82570054462005,
              21.02621130997414
            ],
            [
              105.8256647347575,
              21.02613023784712
            ],
            [
              105.82562791765852,
              21.026046884538207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DODV.2616",
          "diaChiLapD": "Ngõ 8, Vũ Thạnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02621130997414,
          "to_latitude": 21.026046884538207,
          "to_longitude": 105.82562791765852,
          "from_longitude": 105.82570054462005
        }
      },
      {
        "id": 2610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799923363591,
            21.02893573032732,
            105.828040453199,
            21.029054626056663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82799923363591,
              21.02893573032732
            ],
            [
              105.828040453199,
              21.029054626056663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Ngách 35/2, Cát Linh",
          "maTaiSan": "04.O13.DODV.2617",
          "diaChiLapD": "Ngách 35/2, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02893573032732,
          "to_latitude": 21.029054626056663,
          "to_longitude": 105.828040453199,
          "from_longitude": 105.82799923363591
        }
      },
      {
        "id": 2611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866701079733,
            21.027548690332498,
            105.82867128501151,
            21.027652062087473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866701079733,
              21.027652062087473
            ],
            [
              105.82867128501151,
              21.027617776539902
            ],
            [
              105.82866951009898,
              21.027548690332498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E9_Ngách 35/75, Cát Linh",
          "maTaiSan": "04.O13.DODV.2618",
          "diaChiLapD": "Ngách 35/75, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027652062087473,
          "to_latitude": 21.027548690332498,
          "to_longitude": 105.82866951009898,
          "from_longitude": 105.82866701079733
        }
      },
      {
        "id": 2612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82828245516367,
            21.028152823985277,
            105.82881177777554,
            21.028261103827102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82828245516367,
              21.028261103827102
            ],
            [
              105.82847464536599,
              21.028215153582416
            ],
            [
              105.82858954568775,
              21.028190215718958
            ],
            [
              105.82872349991362,
              21.028161140282194
            ],
            [
              105.82881177777554,
              21.028152823985277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Ngách 35/45, Cát Linh",
          "maTaiSan": "04.O13.DODV.2619",
          "diaChiLapD": "Ngách 35/45, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028261103827102,
          "to_latitude": 21.028152823985277,
          "to_longitude": 105.82881177777554,
          "from_longitude": 105.82828245516367
        }
      },
      {
        "id": 2613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82828245516367,
            21.028261103827102,
            105.8283548447395,
            21.02855313846878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283548447395,
              21.02855313846878
            ],
            [
              105.82834768032724,
              21.02852384838573
            ],
            [
              105.8283417954499,
              21.02849978896378
            ],
            [
              105.82829479819152,
              21.028307646467958
            ],
            [
              105.82828245516367,
              21.028261103827102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DODV.2620",
          "diaChiLapD": "Ngõ 35, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02855313846878,
          "to_latitude": 21.028261103827102,
          "to_longitude": 105.82828245516367,
          "from_longitude": 105.8283548447395
        }
      },
      {
        "id": 2614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82713948030519,
            21.025294759405085,
            105.82736234795506,
            21.02615126525836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82720385882159,
              21.025294759405085
            ],
            [
              105.8271529005128,
              21.025307101441044
            ],
            [
              105.82713948030519,
              21.025310010064672
            ],
            [
              105.82715645869123,
              21.025384644052394
            ],
            [
              105.8271598965578,
              21.025399754540977
            ],
            [
              105.8271768020254,
              21.02547407094484
            ],
            [
              105.82724027892196,
              21.02574084847132
            ],
            [
              105.82725941970912,
              21.02582031140196
            ],
            [
              105.82728361209814,
              21.02590502187807
            ],
            [
              105.82736234795506,
              21.02615126525836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I5_Hào Nam",
          "maTaiSan": "04.O13.DODV.2621",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 125,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025294759405085,
          "to_latitude": 21.02615126525836,
          "to_longitude": 105.82736234795506,
          "from_longitude": 105.82720385882159
        }
      },
      {
        "id": 2615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82672542961954,
            21.02538401496842,
            105.82722228588119,
            21.025644392505615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82722228588119,
              21.02538401496842
            ],
            [
              105.82718742808017,
              21.025392785025566
            ],
            [
              105.8271598965578,
              21.025399754540977
            ],
            [
              105.82672542961954,
              21.025509744713357
            ],
            [
              105.82675971042858,
              21.025644392505615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I52_Ngõ 137, Hào Nam",
          "maTaiSan": "04.O13.DODV.2622",
          "diaChiLapD": "Ngõ 137, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02538401496842,
          "to_latitude": 21.025644392505615,
          "to_longitude": 105.82675971042858,
          "from_longitude": 105.82722228588119
        }
      },
      {
        "id": 2616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82670922914252,
            21.025368262287063,
            105.82721903397942,
            21.02549124857251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82721903397942,
              21.025368262287063
            ],
            [
              105.82718407523842,
              21.025377916188297
            ],
            [
              105.82715645869123,
              21.025384644052394
            ],
            [
              105.82671890626314,
              21.02549124857251
            ],
            [
              105.82670922914252,
              21.025444083145654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I51_Ngõ 137, Hào Nam",
          "maTaiSan": "04.O13.DODV.2623",
          "diaChiLapD": "Ngõ 137, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025368262287063,
          "to_latitude": 21.025444083145654,
          "to_longitude": 105.82670922914252,
          "from_longitude": 105.82721903397942
        }
      },
      {
        "id": 2617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82698304874289,
            21.024689544470867,
            105.82707324681274,
            21.025035752729394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82707324681274,
              21.025035752729394
            ],
            [
              105.82704937189466,
              21.024949599116763
            ],
            [
              105.82703736267064,
              21.024925598316038
            ],
            [
              105.82702387238784,
              21.024866968750725
            ],
            [
              105.82702104561413,
              21.024854684186515
            ],
            [
              105.82698304874289,
              21.024689544470867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I6_Hào Nam",
          "maTaiSan": "04.O13.DODV.2624",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025035752729394,
          "to_latitude": 21.024689544470867,
          "to_longitude": 105.82698304874289,
          "from_longitude": 105.82707324681274
        }
      },
      {
        "id": 2618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82702387238784,
            21.024849314029503,
            105.82708763717997,
            21.024866968750725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702387238784,
              21.024866968750725
            ],
            [
              105.82704955792717,
              21.02485985739804
            ],
            [
              105.82708763717997,
              21.024849314029503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I6_Hào Nam",
          "maTaiSan": "04.O13.DODV.2625",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024866968750725,
          "to_latitude": 21.024849314029503,
          "to_longitude": 105.82708763717997,
          "from_longitude": 105.82702387238784
        }
      },
      {
        "id": 2619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8267722916527,
            21.023811005934252,
            105.82696078463478,
            21.024605366488604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82696078463478,
              21.024605366488604
            ],
            [
              105.82695304652552,
              21.02457557601377
            ],
            [
              105.82688529642628,
              21.024314528377488
            ],
            [
              105.82688272038311,
              21.02430304826724
            ],
            [
              105.8267722916527,
              21.023811005934252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I8_Hào Nam",
          "maTaiSan": "04.O13.DODV.2626",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024605366488604,
          "to_latitude": 21.023811005934252,
          "to_longitude": 105.8267722916527,
          "from_longitude": 105.82696078463478
        }
      },
      {
        "id": 2620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82688272038311,
            21.024292627228885,
            105.82694320405265,
            21.02430304826724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82688272038311,
              21.02430304826724
            ],
            [
              105.82689013608285,
              21.024301771181115
            ],
            [
              105.82694320405265,
              21.024292627228885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I8_Hào Nam",
          "maTaiSan": "04.O13.DODV.2627",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02430304826724,
          "to_latitude": 21.024292627228885,
          "to_longitude": 105.82694320405265,
          "from_longitude": 105.82688272038311
        }
      },
      {
        "id": 2621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82671833537633,
            21.024664168543655,
            105.82692078403005,
            21.024708923965054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82692078403005,
              21.024664168543655
            ],
            [
              105.8268669418324,
              21.024674415481282
            ],
            [
              105.8267384077027,
              21.024704263298347
            ],
            [
              105.82671833537633,
              21.024708923965054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_K1_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DODV.2628",
          "diaChiLapD": "Ngõ 101, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024664168543655,
          "to_latitude": 21.024708923965054,
          "to_longitude": 105.82671833537633,
          "from_longitude": 105.82692078403005
        }
      },
      {
        "id": 2622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82671481217366,
            21.02449492645999,
            105.82685259869764,
            21.024661753005244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82682071410261,
              21.02449492645999
            ],
            [
              105.82685259869764,
              21.024635384607187
            ],
            [
              105.8268448522222,
              21.02463686652588
            ],
            [
              105.8268240018018,
              21.024640856278634
            ],
            [
              105.82671481217366,
              21.024661753005244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DODV.2629",
          "diaChiLapD": "Ngõ 101, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02449492645999,
          "to_latitude": 21.024661753005244,
          "to_longitude": 105.82671481217366,
          "from_longitude": 105.82682071410261
        }
      },
      {
        "id": 2623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82659791531695,
            21.025008781129287,
            105.82677815802623,
            21.025050163306304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82677815802623,
              21.025008781129287
            ],
            [
              105.82677430722433,
              21.025009143978515
            ],
            [
              105.82675325746887,
              21.025016595851003
            ],
            [
              105.82659791531695,
              21.025050163306304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M8_Ngách 101/24, Hào Nam",
          "maTaiSan": "04.O13.DODV.2630",
          "diaChiLapD": "Ngách 101/24, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025008781129287,
          "to_latitude": 21.025050163306304,
          "to_longitude": 105.82659791531695,
          "from_longitude": 105.82677815802623
        }
      },
      {
        "id": 2624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650418473825,
            21.024895165758934,
            105.82677672111147,
            21.025004291363746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82677672111147,
              21.02500273199155
            ],
            [
              105.82677286149621,
              21.025003415528438
            ],
            [
              105.82676790530148,
              21.025004291363746
            ],
            [
              105.82674370475114,
              21.024895165758934
            ],
            [
              105.82650418473825,
              21.024946304493188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M7_Ngách 101/22, Hào Nam",
          "maTaiSan": "04.O13.DODV.2631",
          "diaChiLapD": "Ngách 101/22, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02500273199155,
          "to_latitude": 21.024946304493188,
          "to_longitude": 105.82650418473825,
          "from_longitude": 105.82677672111147
        }
      },
      {
        "id": 2625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82635104292011,
            21.024789191470397,
            105.82675473718281,
            21.024895549177256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82675473718281,
              21.024894686708215
            ],
            [
              105.82675341762086,
              21.02489491531634
            ],
            [
              105.82674974339989,
              21.024895549177256
            ],
            [
              105.82673182084102,
              21.02480927083477
            ],
            [
              105.82672675679434,
              21.024789191470397
            ],
            [
              105.82635104292011,
              21.02486926112506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M6_Ngách 101/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2632",
          "diaChiLapD": "Ngách 101/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024894686708215,
          "to_latitude": 21.02486926112506,
          "to_longitude": 105.82635104292011,
          "from_longitude": 105.82675473718281
        }
      },
      {
        "id": 2626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82636419131802,
            21.02475329002052,
            105.82673141498093,
            21.024830482302807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82673141498093,
              21.02475329002052
            ],
            [
              105.82673010818479,
              21.0247535655341
            ],
            [
              105.82636419131802,
              21.024830482302807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5_Ngách 101/18, Hào Nam",
          "maTaiSan": "04.O13.DODV.2633",
          "diaChiLapD": "Ngách 101/18, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02475329002052,
          "to_latitude": 21.024830482302807,
          "to_longitude": 105.82636419131802,
          "from_longitude": 105.82673141498093
        }
      },
      {
        "id": 2627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82633949498984,
            21.024614051718157,
            105.82668753245127,
            21.024699940862575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82668753245127,
              21.024614051718157
            ],
            [
              105.82668626242528,
              21.0246143658913
            ],
            [
              105.82633949498984,
              21.024699940862575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M9_Ngách 101/25, Hào Nam",
          "maTaiSan": "04.O13.DODV.2634",
          "diaChiLapD": "Ngách 101/25, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024614051718157,
          "to_latitude": 21.024699940862575,
          "to_longitude": 105.82633949498984,
          "from_longitude": 105.82668753245127
        }
      },
      {
        "id": 2628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268448522222,
            21.02463686652588,
            105.82685140417954,
            21.02465776239401
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8268448522222,
              21.02463686652588
            ],
            [
              105.82684619769961,
              21.024641162948207
            ],
            [
              105.82684953778818,
              21.024651810183293
            ],
            [
              105.82685140417954,
              21.02465776239401
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DODV.2635",
          "diaChiLapD": "Ngõ 101, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463686652588,
          "to_latitude": 21.02465776239401,
          "to_longitude": 105.82685140417954,
          "from_longitude": 105.8268448522222
        }
      },
      {
        "id": 2629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82635072254469,
            21.024518426485734,
            105.8266616825763,
            21.024589477885137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266616825763,
              21.024518426485734
            ],
            [
              105.8266561878516,
              21.024519682483074
            ],
            [
              105.82635072254469,
              21.024589477885137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M2_Ngách 101/33, Hào Nam",
          "maTaiSan": "04.O13.DODV.2636",
          "diaChiLapD": "Ngách 101/33, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024518426485734,
          "to_latitude": 21.024589477885137,
          "to_longitude": 105.82635072254469,
          "from_longitude": 105.8266616825763
        }
      },
      {
        "id": 2630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8262969555509,
            21.024455294523047,
            105.82664461693481,
            21.024535700071315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82664461693481,
              21.024455294523047
            ],
            [
              105.82664151269262,
              21.02445601322375
            ],
            [
              105.8262969555509,
              21.024535700071315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M3_Ngách 101/33, Hào Nam",
          "maTaiSan": "04.O13.DODV.2637",
          "diaChiLapD": "Ngách 101/33, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024455294523047,
          "to_latitude": 21.024535700071315,
          "to_longitude": 105.8262969555509,
          "from_longitude": 105.82664461693481
        }
      },
      {
        "id": 2631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82625110918735,
            21.024354996089155,
            105.82662514737639,
            21.024433309216487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82661964996944,
              21.02436293737382
            ],
            [
              105.82662514737639,
              21.02436147091331
            ],
            [
              105.82662357922072,
              21.024354996089155
            ],
            [
              105.82659582947483,
              21.024362138182834
            ],
            [
              105.82625110918735,
              21.024433309216487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M4_Ngách 101/35, Hào Nam",
          "maTaiSan": "04.O13.DODV.2638",
          "diaChiLapD": "Ngách 101/35, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02436293737382,
          "to_latitude": 21.024433309216487,
          "to_longitude": 105.82625110918735,
          "from_longitude": 105.82661964996944
        }
      },
      {
        "id": 2632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82645780793176,
            21.02508250360794,
            105.8270453960856,
            21.02518471030185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82645780793176,
              21.02518471030185
            ],
            [
              105.82651046229435,
              21.025181272351716
            ],
            [
              105.82661984830577,
              21.025174807423117
            ],
            [
              105.82667534412762,
              21.025162770457865
            ],
            [
              105.82672145531834,
              21.02515276889982
            ],
            [
              105.8270453960856,
              21.02508250360794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I4_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2639",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02518471030185,
          "to_latitude": 21.02508250360794,
          "to_longitude": 105.8270453960856,
          "from_longitude": 105.82645780793176
        }
      },
      {
        "id": 2633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82672145531834,
            21.02515276889982,
            105.8267233157072,
            21.02516077236457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82672145531834,
              21.02515276889982
            ],
            [
              105.82672239343017,
              21.025156810787223
            ],
            [
              105.8267233157072,
              21.02516077236457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I4_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2640",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02515276889982,
          "to_latitude": 21.02516077236457,
          "to_longitude": 105.8267233157072,
          "from_longitude": 105.82672145531834
        }
      },
      {
        "id": 2634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82645126976365,
            21.02519402636201,
            105.82654284411937,
            21.025505116216088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82648230080869,
              21.02519402636201
            ],
            [
              105.82648024008975,
              21.02519697185778
            ],
            [
              105.82645126976365,
              21.025238382799788
            ],
            [
              105.82654284411937,
              21.025505116216088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I3_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2641",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02519402636201,
          "to_latitude": 21.025505116216088,
          "to_longitude": 105.82654284411937,
          "from_longitude": 105.82648230080869
        }
      },
      {
        "id": 2635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612299564134,
            21.02516691679261,
            105.826129267247,
            21.02517849909152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82612299564134,
              21.02516691679261
            ],
            [
              105.82612354380355,
              21.025167932064445
            ],
            [
              105.826125518666,
              21.025171576016508
            ],
            [
              105.826129267247,
              21.02517849909152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2642",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02516691679261,
          "to_latitude": 21.02517849909152,
          "to_longitude": 105.826129267247,
          "from_longitude": 105.82612299564134
        }
      },
      {
        "id": 2636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82615546911047,
            21.024833027474124,
            105.82629848159033,
            21.025145788708578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82620179778532,
              21.025145788708578
            ],
            [
              105.82619985557359,
              21.025136897764593
            ],
            [
              105.82619924963613,
              21.02513412690424
            ],
            [
              105.82615546911047,
              21.024972308241185
            ],
            [
              105.82629848159033,
              21.024945239283248
            ],
            [
              105.82627572854148,
              21.024833027474124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2643",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025145788708578,
          "to_latitude": 21.024833027474124,
          "to_longitude": 105.82627572854148,
          "from_longitude": 105.82620179778532
        }
      },
      {
        "id": 2637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82594257742718,
            21.024976289698998,
            105.82619713790025,
            21.025147890570754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82619713790025,
              21.025147890570754
            ],
            [
              105.82619592417177,
              21.025138346668918
            ],
            [
              105.82619549284966,
              21.025134967089798
            ],
            [
              105.8261472794345,
              21.024976289698998
            ],
            [
              105.82594257742718,
              21.025019357999795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2644",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025147890570754,
          "to_latitude": 21.025019357999795,
          "to_longitude": 105.82594257742718,
          "from_longitude": 105.82619713790025
        }
      },
      {
        "id": 2638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256998639191,
            21.02490093450958,
            105.82593406078851,
            21.025014107365386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256998639191,
              21.02494628605504
            ],
            [
              105.82570046676811,
              21.024947973965585
            ],
            [
              105.82570140091516,
              21.024950951879575
            ],
            [
              105.82589604830669,
              21.02490093450958
            ],
            [
              105.82593406078851,
              21.025014107365386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G9_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2645",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02494628605504,
          "to_latitude": 21.025014107365386,
          "to_longitude": 105.82593406078851,
          "from_longitude": 105.8256998639191
        }
      },
      {
        "id": 2639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570211481591,
            21.024870124755527,
            105.82600202442279,
            21.024939790873404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82570211481591,
              21.024939790873404
            ],
            [
              105.82585661431645,
              21.024899402653492
            ],
            [
              105.82586339241304,
              21.024897631030342
            ],
            [
              105.82600202442279,
              21.024870124755527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT24_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2646",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024939790873404,
          "to_latitude": 21.024870124755527,
          "to_longitude": 105.82600202442279,
          "from_longitude": 105.82570211481591
        }
      },
      {
        "id": 2640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257565880644,
            21.025437405741773,
            105.82606764316468,
            21.025578954256062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606764316468,
              21.025483272879526
            ],
            [
              105.82582289064635,
              21.025578954256062
            ],
            [
              105.82579297563308,
              21.025507415966302
            ],
            [
              105.82578893278254,
              21.025499637152244
            ],
            [
              105.8257565880644,
              21.025437405741773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2647",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025483272879526,
          "to_latitude": 21.025437405741773,
          "to_longitude": 105.8257565880644,
          "from_longitude": 105.82606764316468
        }
      },
      {
        "id": 2641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82577892298063,
            21.025499637152244,
            105.82578893278254,
            21.025504495430514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578893278254,
              21.025499637152244
            ],
            [
              105.82578749650564,
              21.025500334190383
            ],
            [
              105.8257822541451,
              21.02550287874056
            ],
            [
              105.82577892298063,
              21.025504495430514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2648",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025499637152244,
          "to_latitude": 21.025504495430514,
          "to_longitude": 105.82577892298063,
          "from_longitude": 105.82578893278254
        }
      },
      {
        "id": 2642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82519326233047,
            21.0251106084977,
            105.8255605950835,
            21.02528083098286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82519326233047,
              21.025268933731414
            ],
            [
              105.82519643295124,
              21.025279221436566
            ],
            [
              105.82519717774085,
              21.02528083098286
            ],
            [
              105.8252417917424,
              21.02525676393562
            ],
            [
              105.82532386446327,
              21.025204981179233
            ],
            [
              105.82540656041844,
              21.025160347096516
            ],
            [
              105.82548656245443,
              21.025128592493076
            ],
            [
              105.8255605950835,
              21.0251106084977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G6_Ngách 127/38, Hào Nam",
          "maTaiSan": "04.O13.DODV.2649",
          "diaChiLapD": "Ngách 127/38, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025268933731414,
          "to_latitude": 21.0251106084977,
          "to_longitude": 105.8255605950835,
          "from_longitude": 105.82519326233047
        }
      },
      {
        "id": 2643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82530456304306,
            21.024996477798286,
            105.82544281306232,
            21.025140023802386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82544281306232,
              21.025140023802386
            ],
            [
              105.82544152583712,
              21.02513843856739
            ],
            [
              105.82541302996742,
              21.025119828628952
            ],
            [
              105.82540697744729,
              21.025109588334402
            ],
            [
              105.82534902537274,
              21.024996477798286
            ],
            [
              105.82533273316896,
              21.02500210628025
            ],
            [
              105.82530456304306,
              21.025011032271156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G5_Ngách 127/38, Hào Nam",
          "maTaiSan": "04.O13.DODV.2650",
          "diaChiLapD": "Ngách 127/38, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025140023802386,
          "to_latitude": 21.025011032271156,
          "to_longitude": 105.82530456304306,
          "from_longitude": 105.82544281306232
        }
      },
      {
        "id": 2644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82484277133148,
            21.025279124173238,
            105.8251828403775,
            21.025568679382104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82514574077425,
              21.025279124173238
            ],
            [
              105.82514576778887,
              21.025279837590066
            ],
            [
              105.82514607680137,
              21.025287741117648
            ],
            [
              105.82514616865305,
              21.025290097186517
            ],
            [
              105.82516924946026,
              21.025286866361903
            ],
            [
              105.8251828403775,
              21.025312168399886
            ],
            [
              105.82516742171914,
              21.02534070925775
            ],
            [
              105.8250147639022,
              21.025445972018662
            ],
            [
              105.82493069706402,
              21.02550393784831
            ],
            [
              105.82484277133148,
              21.025568679382104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8_Hẻm 127/38/14, Hào Nam",
          "maTaiSan": "04.O13.DODV.2651",
          "diaChiLapD": "Hẻm 127/38/14, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025279124173238,
          "to_latitude": 21.025568679382104,
          "to_longitude": 105.82484277133148,
          "from_longitude": 105.82514574077425
        }
      },
      {
        "id": 2645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82497575858838,
            21.025391252369424,
            105.8250147639022,
            21.025445972018662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8250147639022,
              21.025445972018662
            ],
            [
              105.82497575858838,
              21.025391252369424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G8_Hẻm 127/38/14, Hào Nam",
          "maTaiSan": "04.O13.DODV.2652",
          "diaChiLapD": "Hẻm 127/38/14, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025445972018662,
          "to_latitude": 21.025391252369424,
          "to_longitude": 105.82497575858838,
          "from_longitude": 105.8250147639022
        }
      },
      {
        "id": 2646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547664582016,
            21.02467408984748,
            105.82561271731073,
            21.02505997718594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82561271731073,
              21.02505997718594
            ],
            [
              105.82559931764138,
              21.02499551854135
            ],
            [
              105.8255655256145,
              21.024861017130966
            ],
            [
              105.82556483614266,
              21.02485827286693
            ],
            [
              105.82555313027825,
              21.02481245036791
            ],
            [
              105.82553795181532,
              21.02475303763681
            ],
            [
              105.82547664582016,
              21.02467408984748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2653",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02505997718594,
          "to_latitude": 21.02467408984748,
          "to_longitude": 105.82547664582016,
          "from_longitude": 105.82561271731073
        }
      },
      {
        "id": 2647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82555313027825,
            21.024811825916203,
            105.82555729036356,
            21.02481245036791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82555313027825,
              21.02481245036791
            ],
            [
              105.82555595814037,
              21.02481202657655
            ],
            [
              105.82555729036356,
              21.024811825916203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2654",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02481245036791,
          "to_latitude": 21.024811825916203,
          "to_longitude": 105.82555729036356,
          "from_longitude": 105.82555313027825
        }
      },
      {
        "id": 2648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82548939686946,
            21.02466968344328,
            105.825580595416,
            21.024868549132584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825580595416,
              21.024868549132584
            ],
            [
              105.82557345440405,
              21.024807211454196
            ],
            [
              105.82557204339277,
              21.024800790953424
            ],
            [
              105.82557045478042,
              21.024793563831466
            ],
            [
              105.82556531898906,
              21.024770210585064
            ],
            [
              105.82548939686946,
              21.02466968344328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2655",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024868549132584,
          "to_latitude": 21.02466968344328,
          "to_longitude": 105.82548939686946,
          "from_longitude": 105.825580595416
        }
      },
      {
        "id": 2649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82557045478042,
            21.02463850269543,
            105.82579815964034,
            21.024793563831466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82557045478042,
              21.024793563831466
            ],
            [
              105.82569337562849,
              21.024734304421095
            ],
            [
              105.82579815964034,
              21.02463850269543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngách 127/93, Hào Nam",
          "maTaiSan": "04.O13.DODV.2656",
          "diaChiLapD": "Ngách 127/93, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024793563831466,
          "to_latitude": 21.02463850269543,
          "to_longitude": 105.82579815964034,
          "from_longitude": 105.82557045478042
        }
      },
      {
        "id": 2650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82555321179848,
            21.024800790953424,
            105.82557204339277,
            21.024803145849685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82557204339277,
              21.024800790953424
            ],
            [
              105.82555815690688,
              21.024802527522752
            ],
            [
              105.82555384051498,
              21.024803067830053
            ],
            [
              105.82555321179848,
              21.024803145849685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2657",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024800790953424,
          "to_latitude": 21.024803145849685,
          "to_longitude": 105.82555321179848,
          "from_longitude": 105.82557204339277
        }
      },
      {
        "id": 2651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82526231926532,
            21.02393298411612,
            105.82550076103308,
            21.02457585861601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254930360888,
              21.02457585861601
            ],
            [
              105.82550076103308,
              21.02453429805095
            ],
            [
              105.8254619998869,
              21.024427289612408
            ],
            [
              105.82545412643924,
              21.024404662293055
            ],
            [
              105.82544774986596,
              21.024387977252722
            ],
            [
              105.82542276189642,
              21.02432259753953
            ],
            [
              105.82539825097196,
              21.02422836287873
            ],
            [
              105.82537940302028,
              21.024184397732068
            ],
            [
              105.82537644968168,
              21.02417757916573
            ],
            [
              105.82537362593584,
              21.024171059841176
            ],
            [
              105.82536330630954,
              21.024147234968993
            ],
            [
              105.82530002661251,
              21.02400903516435
            ],
            [
              105.82526231926532,
              21.02393298411612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2658",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02457585861601,
          "to_latitude": 21.02393298411612,
          "to_longitude": 105.82526231926532,
          "from_longitude": 105.8254930360888
        }
      },
      {
        "id": 2652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82537362593584,
            21.024167046761995,
            105.82538219732751,
            21.024171059841176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82537362593584,
              21.024171059841176
            ],
            [
              105.82537609861608,
              21.02416990164824
            ],
            [
              105.82537934275396,
              21.024168382937322
            ],
            [
              105.82538219732751,
              21.024167046761995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2659",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024171059841176,
          "to_latitude": 21.024167046761995,
          "to_longitude": 105.82538219732751,
          "from_longitude": 105.82537362593584
        }
      },
      {
        "id": 2653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251120860785,
            21.02417444266052,
            105.82538511536478,
            21.024250262800884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82538511536478,
              21.02417444266052
            ],
            [
              105.82537644968168,
              21.02417757916573
            ],
            [
              105.82537351951986,
              21.02417864022409
            ],
            [
              105.82532337748911,
              21.02419820174115
            ],
            [
              105.8251120860785,
              21.024250262800884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H61_Ngách 127/76, Hào Nam",
          "maTaiSan": "04.O13.DODV.2660",
          "diaChiLapD": "Ngách 127/76, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02417444266052,
          "to_latitude": 21.024250262800884,
          "to_longitude": 105.8251120860785,
          "from_longitude": 105.82538511536478
        }
      },
      {
        "id": 2654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82523535983259,
            21.024387002770684,
            105.82545163769247,
            21.02444758844369
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82545163769247,
              21.024387002770684
            ],
            [
              105.82544774986596,
              21.024387977252722
            ],
            [
              105.82544369104271,
              21.0243889950162
            ],
            [
              105.82523535983259,
              21.02444758844369
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H51_Ngách 127/66, Hào Nam",
          "maTaiSan": "04.O13.DODV.2661",
          "diaChiLapD": "Ngách 127/66, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024387002770684,
          "to_latitude": 21.02444758844369,
          "to_longitude": 105.82523535983259,
          "from_longitude": 105.82545163769247
        }
      },
      {
        "id": 2655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82542032505567,
            21.024242664372082,
            105.82586826354209,
            21.02430423053909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82542032505567,
              21.02430423053909
            ],
            [
              105.82542254847442,
              21.024303751877365
            ],
            [
              105.82554304043872,
              21.024277774671646
            ],
            [
              105.8256056982705,
              21.024281845737274
            ],
            [
              105.82563884255585,
              21.02429595037598
            ],
            [
              105.82586826354209,
              21.024242664372082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngách 127/123, Hào Nam",
          "maTaiSan": "04.O13.DODV.2662",
          "diaChiLapD": "Ngách 127/123, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02430423053909,
          "to_latitude": 21.024242664372082,
          "to_longitude": 105.82586826354209,
          "from_longitude": 105.82542032505567
        }
      },
      {
        "id": 2656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8247743046288,
            21.023868607769323,
            105.82523008837806,
            21.023959192975745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82523008837806,
              21.023868607769323
            ],
            [
              105.8250729708117,
              21.023899834943776
            ],
            [
              105.82498888755201,
              21.0239165460488
            ],
            [
              105.8247743046288,
              21.023959192975745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H8_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2663",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023868607769323,
          "to_latitude": 21.023959192975745,
          "to_longitude": 105.8247743046288,
          "from_longitude": 105.82523008837806
        }
      },
      {
        "id": 2657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82481809701564,
            21.02388750549445,
            105.82522720444778,
            21.023965246273697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82481809701564,
              21.023965246273697
            ],
            [
              105.82491420490047,
              21.023946378736586
            ],
            [
              105.82500572696927,
              21.02392681623002
            ],
            [
              105.8250860757941,
              21.023912554716183
            ],
            [
              105.82522720444778,
              21.02388750549445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2664",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023965246273697,
          "to_latitude": 21.02388750549445,
          "to_longitude": 105.82522720444778,
          "from_longitude": 105.82481809701564
        }
      },
      {
        "id": 2658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82508494332853,
            21.023908497502518,
            105.8250860757941,
            21.023912554716183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8250860757941,
              21.023912554716183
            ],
            [
              105.82508601102194,
              21.023912321998278
            ],
            [
              105.82508512196507,
              21.02390914334525
            ],
            [
              105.82508494332853,
              21.023908497502518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2665",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023912554716183,
          "to_latitude": 21.023908497502518,
          "to_longitude": 105.82508494332853,
          "from_longitude": 105.8250860757941
        }
      },
      {
        "id": 2659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8250729708117,
            21.023899834943776,
            105.82507534391071,
            21.02391152290921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8250729708117,
              21.023899834943776
            ],
            [
              105.82507455485462,
              21.023907636545555
            ],
            [
              105.82507520352415,
              21.023910835332554
            ],
            [
              105.82507534391071,
              21.02391152290921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H8_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2666",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023899834943776,
          "to_latitude": 21.02391152290921,
          "to_longitude": 105.82507534391071,
          "from_longitude": 105.8250729708117
        }
      },
      {
        "id": 2660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82602107364711,
            21.02511263165763,
            105.82643729113816,
            21.02543672617327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606260361698,
              21.02543672617327
            ],
            [
              105.82603505441232,
              21.025298203202567
            ],
            [
              105.82602107364711,
              21.02523290019609
            ],
            [
              105.82604865984315,
              21.025208960806598
            ],
            [
              105.82605793046031,
              21.025198977591707
            ],
            [
              105.82610530397763,
              21.025176952658693
            ],
            [
              105.82612299564134,
              21.02516691679261
            ],
            [
              105.82613156624194,
              21.025162055550595
            ],
            [
              105.82619592417177,
              21.025138346668918
            ],
            [
              105.82619985557359,
              21.025136897764593
            ],
            [
              105.8262100561083,
              21.025133140470363
            ],
            [
              105.82628817669091,
              21.02511263165763
            ],
            [
              105.82635527503336,
              21.02513547245764
            ],
            [
              105.82639455465562,
              21.02516186802067
            ],
            [
              105.82643729113816,
              21.025180479943945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2667",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02543672617327,
          "to_latitude": 21.025180479943945,
          "to_longitude": 105.82643729113816,
          "from_longitude": 105.82606260361698
        }
      },
      {
        "id": 2661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82049895149164,
            21.023890485670922,
            105.82070496238559,
            21.024075414445406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82070496238559,
              21.024075414445406
            ],
            [
              105.82056648903102,
              21.023951111337762
            ],
            [
              105.82056314217058,
              21.023948107097386
            ],
            [
              105.82049895149164,
              21.023890485670922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2668",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024075414445406,
          "to_latitude": 21.023890485670922,
          "to_longitude": 105.82049895149164,
          "from_longitude": 105.82070496238559
        }
      },
      {
        "id": 2662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82049895149164,
            21.023704136392844,
            105.8206807628904,
            21.023890485670922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82049895149164,
              21.023890485670922
            ],
            [
              105.8206807628904,
              21.023704136392844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1_Ngõ 325, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2669",
          "diaChiLapD": "Ngõ 325, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023890485670922,
          "to_latitude": 21.023704136392844,
          "to_longitude": 105.8206807628904,
          "from_longitude": 105.82049895149164
        }
      },
      {
        "id": 2663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82053385619733,
            21.023951111337762,
            105.82056648903102,
            21.023982729048498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82056648903102,
              21.023951111337762
            ],
            [
              105.82055433904162,
              21.023962883897365
            ],
            [
              105.82053790587933,
              21.023978805767257
            ],
            [
              105.82053385619733,
              21.023982729048498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2670",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023951111337762,
          "to_latitude": 21.023982729048498,
          "to_longitude": 105.82053385619733,
          "from_longitude": 105.82056648903102
        }
      },
      {
        "id": 2664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82016831958788,
            21.0234527764959,
            105.8204387530333,
            21.02385552496849
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204387530333,
              21.02385552496849
            ],
            [
              105.82029242431959,
              21.023729257967155
            ],
            [
              105.82022182613889,
              21.023661811328665
            ],
            [
              105.8201788703176,
              21.02361819033659
            ],
            [
              105.82017354075121,
              21.023534632387477
            ],
            [
              105.82016831958788,
              21.0234527764959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2671",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02385552496849,
          "to_latitude": 21.0234527764959,
          "to_longitude": 105.82016831958788,
          "from_longitude": 105.8204387530333
        }
      },
      {
        "id": 2665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82007893497132,
            21.023534632387477,
            105.82017354075121,
            21.023543983556248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82017354075121,
              21.023534632387477
            ],
            [
              105.82016711525208,
              21.02353526853499
            ],
            [
              105.82007893497132,
              21.023543983556248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2672",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023534632387477,
          "to_latitude": 21.023543983556248,
          "to_longitude": 105.82007893497132,
          "from_longitude": 105.82017354075121
        }
      },
      {
        "id": 2666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82068986866288,
            21.023626357628782,
            105.82117630670552,
            21.024123032197895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82068986866288,
              21.024123032197895
            ],
            [
              105.82070930106337,
              21.024102670032708
            ],
            [
              105.82075487184466,
              21.02408811863159
            ],
            [
              105.8207967495038,
              21.024043690969233
            ],
            [
              105.82080087144018,
              21.023983030284995
            ],
            [
              105.82092495269924,
              21.023866645956705
            ],
            [
              105.82097102268675,
              21.023820300339782
            ],
            [
              105.82112305532291,
              21.023662819645455
            ],
            [
              105.82117630670552,
              21.023626357628782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2_Ngõ 307, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2673",
          "diaChiLapD": "Ngõ 307, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024123032197895,
          "to_latitude": 21.023626357628782,
          "to_longitude": 105.82117630670552,
          "from_longitude": 105.82068986866288
        }
      },
      {
        "id": 2667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82071680652365,
            21.02373703632189,
            105.82139885492087,
            21.024145371761698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82071680652365,
              21.024145371761698
            ],
            [
              105.8207332774741,
              21.024124403368482
            ],
            [
              105.8208064879555,
              21.024057081264033
            ],
            [
              105.82080513413025,
              21.023988495907737
            ],
            [
              105.82092763011886,
              21.023872128260518
            ],
            [
              105.82106011081916,
              21.02373703632189
            ],
            [
              105.82131370836102,
              21.023941334614218
            ],
            [
              105.82139885492087,
              21.0240099280653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M1_Ngõ 307, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2674",
          "diaChiLapD": "Ngõ 307, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024145371761698,
          "to_latitude": 21.0240099280653,
          "to_longitude": 105.82139885492087,
          "from_longitude": 105.82071680652365
        }
      },
      {
        "id": 2668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131370836102,
            21.023834739218948,
            105.821454711179,
            21.023941334614218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82131370836102,
              21.023941334614218
            ],
            [
              105.82142224026522,
              21.023834739218948
            ],
            [
              105.821454711179,
              21.023882996204136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M1_Ngõ 307, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2675",
          "diaChiLapD": "Ngõ 307, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023941334614218,
          "to_latitude": 21.023882996204136,
          "to_longitude": 105.821454711179,
          "from_longitude": 105.82131370836102
        }
      },
      {
        "id": 2669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82076648178743,
            21.024136202745307,
            105.8211181706135,
            21.02446753088597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82076648178743,
              21.024136202745307
            ],
            [
              105.82082586260125,
              21.024191345341197
            ],
            [
              105.82086467779727,
              21.02420112961764
            ],
            [
              105.82094056264154,
              21.024269798709472
            ],
            [
              105.82094596116623,
              21.024274683490482
            ],
            [
              105.82097299731132,
              21.024299148731043
            ],
            [
              105.82105539742602,
              21.024373713286202
            ],
            [
              105.82106192286119,
              21.02441468282321
            ],
            [
              105.8211181706135,
              21.02446753088597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2676",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024136202745307,
          "to_latitude": 21.02446753088597,
          "to_longitude": 105.8211181706135,
          "from_longitude": 105.82076648178743
        }
      },
      {
        "id": 2670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82091322747563,
            21.024274683490482,
            105.82094596116623,
            21.02430825905018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82094596116623,
              21.024274683490482
            ],
            [
              105.82094298989732,
              21.024277730865837
            ],
            [
              105.82092651303994,
              21.02429463117721
            ],
            [
              105.82091322747563,
              21.02430825905018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2677",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024274683490482,
          "to_latitude": 21.02430825905018,
          "to_longitude": 105.82091322747563,
          "from_longitude": 105.82094596116623
        }
      },
      {
        "id": 2671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82097299731132,
            21.024244466283243,
            105.82102281778793,
            21.024299148731043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82097299731132,
              21.024299148731043
            ],
            [
              105.82102281778793,
              21.024244466283243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2678",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024299148731043,
          "to_latitude": 21.024244466283243,
          "to_longitude": 105.82102281778793,
          "from_longitude": 105.82097299731132
        }
      },
      {
        "id": 2672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82080923132341,
            21.023656991388435,
            105.82097893549107,
            21.023866645956705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82092495269924,
              21.023866645956705
            ],
            [
              105.82080923132341,
              21.02376200574455
            ],
            [
              105.82097893549107,
              21.023656991388435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2_Ngõ 307, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2679",
          "diaChiLapD": "Ngõ 307, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023866645956705,
          "to_latitude": 21.023656991388435,
          "to_longitude": 105.82097893549107,
          "from_longitude": 105.82092495269924
        }
      },
      {
        "id": 2673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82215011544615,
            21.024955659730903,
            105.82252960243808,
            21.02538974495455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82252960243808,
              21.02538974495455
            ],
            [
              105.82252215269416,
              21.02537992498749
            ],
            [
              105.8224896599139,
              21.025345867045953
            ],
            [
              105.82235186758828,
              21.02519449974362
            ],
            [
              105.82220050290675,
              21.02502352477675
            ],
            [
              105.82215011544615,
              21.024955659730903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7_Ngách 189/2, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2680",
          "diaChiLapD": "Ngách 189/2, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02538974495455,
          "to_latitude": 21.024955659730903,
          "to_longitude": 105.82215011544615,
          "from_longitude": 105.82252960243808
        }
      },
      {
        "id": 2674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82215831259866,
            21.024915305526175,
            105.8225413627771,
            21.025378324592968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8225413627771,
              21.025378324592968
            ],
            [
              105.82252846325636,
              21.02536586737225
            ],
            [
              105.8225251453944,
              21.025358342388444
            ],
            [
              105.82244898877293,
              21.025287185169468
            ],
            [
              105.82216870021907,
              21.024953013757653
            ],
            [
              105.82215831259866,
              21.024915305526175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8_Ngách 189/2, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2681",
          "diaChiLapD": "Ngách 189/2, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025378324592968,
          "to_latitude": 21.024915305526175,
          "to_longitude": 105.82215831259866,
          "from_longitude": 105.8225413627771
        }
      },
      {
        "id": 2675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82233657994374,
            21.02484890286956,
            105.822745459713,
            21.025193246604776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.822745459713,
              21.025192105629927
            ],
            [
              105.82274412738545,
              21.02519106162406
            ],
            [
              105.82270359588006,
              21.025193246604776
            ],
            [
              105.82257964000611,
              21.025080291771037
            ],
            [
              105.82244925455045,
              21.024947037226926
            ],
            [
              105.82242539550839,
              21.02494641738203
            ],
            [
              105.82233657994374,
              21.02484890286956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT64_Ngách 189/6, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2682",
          "diaChiLapD": "Ngách 189/6, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025192105629927,
          "to_latitude": 21.02484890286956,
          "to_longitude": 105.82233657994374,
          "from_longitude": 105.822745459713
        }
      },
      {
        "id": 2676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8222933816652,
            21.024660221951137,
            105.82283629942941,
            21.025063271719493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82283629942941,
              21.025063271719493
            ],
            [
              105.82283271097502,
              21.025060080824137
            ],
            [
              105.82245393848662,
              21.024723095893144
            ],
            [
              105.82240316343388,
              21.02471171872479
            ],
            [
              105.8222933816652,
              21.024660221951137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2683",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025063271719493,
          "to_latitude": 21.024660221951137,
          "to_longitude": 105.8222933816652,
          "from_longitude": 105.82283629942941
        }
      },
      {
        "id": 2677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82119537903463,
            21.024482562617813,
            105.82178454788934,
            21.024990699614314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82119537903463,
              21.024482562617813
            ],
            [
              105.82142632363318,
              21.024711202711522
            ],
            [
              105.82144010491982,
              21.024721158342448
            ],
            [
              105.82152496962426,
              21.024782462232448
            ],
            [
              105.82178454788934,
              21.024990699614314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2684",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024482562617813,
          "to_latitude": 21.024990699614314,
          "to_longitude": 105.82178454788934,
          "from_longitude": 105.82119537903463
        }
      },
      {
        "id": 2678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82142529198009,
            21.024721158342448,
            105.82144010491982,
            21.024732903180027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82144010491982,
              21.024721158342448
            ],
            [
              105.821436232384,
              21.024724228143885
            ],
            [
              105.8214292804102,
              21.02472974069001
            ],
            [
              105.82142529198009,
              21.024732903180027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2685",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024721158342448,
          "to_latitude": 21.024732903180027,
          "to_longitude": 105.82142529198009,
          "from_longitude": 105.82144010491982
        }
      },
      {
        "id": 2679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82179860336178,
            21.02499380472841,
            105.82235131244647,
            21.025482282768685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82179860336178,
              21.02499380472841
            ],
            [
              105.82203790997185,
              21.02520885423359
            ],
            [
              105.82205565492292,
              21.02522479981338
            ],
            [
              105.82222369997552,
              21.0253758106493
            ],
            [
              105.82235131244647,
              21.025482282768685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2686",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02499380472841,
          "to_latitude": 21.025482282768685,
          "to_longitude": 105.82235131244647,
          "from_longitude": 105.82179860336178
        }
      },
      {
        "id": 2680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8220116232444,
            21.02520885423359,
            105.82203790997185,
            21.02523498425757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82203790997185,
              21.02520885423359
            ],
            [
              105.82202795557077,
              21.02521874841291
            ],
            [
              105.82201501504828,
              21.025231612379724
            ],
            [
              105.8220116232444,
              21.02523498425757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2687",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02520885423359,
          "to_latitude": 21.02523498425757,
          "to_longitude": 105.8220116232444,
          "from_longitude": 105.82203790997185
        }
      },
      {
        "id": 2681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82239913295476,
            21.02524428991479,
            105.82269176534315,
            21.025530571902348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82239913295476,
              21.025530571902348
            ],
            [
              105.82241279482815,
              21.02551505164486
            ],
            [
              105.82252311836046,
              21.02541671321287
            ],
            [
              105.82263728295537,
              21.02530451329659
            ],
            [
              105.82269176534315,
              21.025250071565083
            ],
            [
              105.82268811795879,
              21.02524683579057
            ],
            [
              105.82268525165686,
              21.02524428991479
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2688",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025530571902348,
          "to_latitude": 21.02524428991479,
          "to_longitude": 105.82268525165686,
          "from_longitude": 105.82239913295476
        }
      },
      {
        "id": 2682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82284801300167,
            21.024888090044094,
            105.8231435725627,
            21.02507763636955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8231435725627,
              21.02507763636955
            ],
            [
              105.82308103901849,
              21.024888090044094
            ],
            [
              105.8230016893658,
              21.024908557276067
            ],
            [
              105.82292069412104,
              21.024929449724468
            ],
            [
              105.82285696568006,
              21.024945594334877
            ],
            [
              105.8228498247793,
              21.024933853532904
            ],
            [
              105.82284801300167,
              21.024931163460774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ10_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2689",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02507763636955,
          "to_latitude": 21.024931163460774,
          "to_longitude": 105.82284801300167,
          "from_longitude": 105.8231435725627
        }
      },
      {
        "id": 2683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82326627536054,
            21.02481447897869,
            105.82355661495586,
            21.02490363757732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82326627536054,
              21.02490363757732
            ],
            [
              105.82327086265926,
              21.024902325564973
            ],
            [
              105.8232892079954,
              21.02489707572656
            ],
            [
              105.82355661495586,
              21.02481447897869
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ11_Ngách 189/35, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2690",
          "diaChiLapD": "Ngách 189/35, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02490363757732,
          "to_latitude": 21.02481447897869,
          "to_longitude": 105.82355661495586,
          "from_longitude": 105.82326627536054
        }
      },
      {
        "id": 2684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.823230586836,
            21.02466625906561,
            105.82346496615182,
            21.024735568315016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.823230586836,
              21.024735568315016
            ],
            [
              105.82346496615182,
              21.02466625906561
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ11_Ngách 189/33, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2691",
          "diaChiLapD": "Ngách 189/33, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024735568315016,
          "to_latitude": 21.02466625906561,
          "to_longitude": 105.82346496615182,
          "from_longitude": 105.823230586836
        }
      },
      {
        "id": 2685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82326679055303,
            21.024827676425485,
            105.82353545135372,
            21.02490495921204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82326679055303,
              21.02490495921204
            ],
            [
              105.82327162336377,
              21.024904209623923
            ],
            [
              105.82329405398293,
              21.024904857039314
            ],
            [
              105.82353545135372,
              21.024827676425485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ12_Ngách 189/35, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2692",
          "diaChiLapD": "Ngách 189/35, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02490495921204,
          "to_latitude": 21.024827676425485,
          "to_longitude": 105.82353545135372,
          "from_longitude": 105.82326679055303
        }
      },
      {
        "id": 2686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82332152090349,
            21.02496375333417,
            105.82360826214374,
            21.0250453373186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82332152090349,
              21.0250453373186
            ],
            [
              105.82332592967654,
              21.025044624104236
            ],
            [
              105.8233556326847,
              21.025043632175272
            ],
            [
              105.82360826214374,
              21.02496375333417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ13_Ngách 189/39, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2693",
          "diaChiLapD": "Ngách 189/39, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0250453373186,
          "to_latitude": 21.02496375333417,
          "to_longitude": 105.82360826214374,
          "from_longitude": 105.82332152090349
        }
      },
      {
        "id": 2687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82332754025059,
            21.02496876713448,
            105.82363642525898,
            21.025060774108564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82332754025059,
              21.025060774108564
            ],
            [
              105.82332864266681,
              21.025060417416842
            ],
            [
              105.82359715289195,
              21.024973367775758
            ],
            [
              105.82363642525898,
              21.02496876713448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ15_Ngách 189/39, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2694",
          "diaChiLapD": "Ngách 189/39, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025060774108564,
          "to_latitude": 21.02496876713448,
          "to_longitude": 105.82363642525898,
          "from_longitude": 105.82332754025059
        }
      },
      {
        "id": 2688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82308161141347,
            21.02506147780958,
            105.82333704710194,
            21.025143744368396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82333704710194,
              21.02507117749988
            ],
            [
              105.82333602380034,
              21.025071401034506
            ],
            [
              105.8233306199746,
              21.025072577001755
            ],
            [
              105.82331464851589,
              21.02506147780958
            ],
            [
              105.82308161141347,
              21.025143744368396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ14_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2695",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02507117749988,
          "to_latitude": 21.025143744368396,
          "to_longitude": 105.82308161141347,
          "from_longitude": 105.82333704710194
        }
      },
      {
        "id": 2689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82337225887566,
            21.025114390755082,
            105.82356531235068,
            21.02522393579001
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82337225887566,
              21.025114390755082
            ],
            [
              105.82338563020436,
              21.02515476793978
            ],
            [
              105.82340530809756,
              21.025211201938163
            ],
            [
              105.82340974840271,
              21.02522393579001
            ],
            [
              105.82356531235068,
              21.025172774413655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ16_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2696",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025114390755082,
          "to_latitude": 21.025172774413655,
          "to_longitude": 105.82356531235068,
          "from_longitude": 105.82337225887566
        }
      },
      {
        "id": 2690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8233882500877,
            21.025211201938163,
            105.82340530809756,
            21.025216313686272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8233882500877,
              21.025216313686272
            ],
            [
              105.82338855646157,
              21.025216221880385
            ],
            [
              105.8233942767208,
              21.025214507863193
            ],
            [
              105.82340530809756,
              21.025211201938163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ16_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2697",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025216313686272,
          "to_latitude": 21.025211201938163,
          "to_longitude": 105.82340530809756,
          "from_longitude": 105.8233882500877
        }
      },
      {
        "id": 2691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82327598343929,
            21.025218403976154,
            105.8233895092822,
            21.02528073240211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8233895092822,
              21.025218403976154
            ],
            [
              105.82338902990173,
              21.025218523716436
            ],
            [
              105.82338322250361,
              21.02521996086511
            ],
            [
              105.8233877266916,
              21.02524082343775
            ],
            [
              105.82327598343929,
              21.02528073240211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ17_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2698",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025218403976154,
          "to_latitude": 21.02528073240211,
          "to_longitude": 105.82327598343929,
          "from_longitude": 105.8233895092822
        }
      },
      {
        "id": 2692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82243446327635,
            21.02553369601467,
            105.82296152559229,
            21.0259547202375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82296152559229,
              21.0259547202375
            ],
            [
              105.82269181592973,
              21.025739272749874
            ],
            [
              105.82258082118943,
              21.02565060890734
            ],
            [
              105.8225314734843,
              21.025611188761026
            ],
            [
              105.82248082042095,
              21.025570726267652
            ],
            [
              105.82243446327635,
              21.02553369601467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2699",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0259547202375,
          "to_latitude": 21.02553369601467,
          "to_longitude": 105.82243446327635,
          "from_longitude": 105.82296152559229
        }
      },
      {
        "id": 2693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82244798293215,
            21.025570726267652,
            105.82248082042095,
            21.025604831005115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82248082042095,
              21.025570726267652
            ],
            [
              105.82246910647757,
              21.025582891562102
            ],
            [
              105.82244954211053,
              21.025603211207013
            ],
            [
              105.82244798293215,
              21.025604831005115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.2700",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025570726267652,
          "to_latitude": 21.025604831005115,
          "to_longitude": 105.82244798293215,
          "from_longitude": 105.82248082042095
        }
      },
      {
        "id": 2694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265690666564,
            21.021608735993873,
            105.82736740924047,
            21.02229499903983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265690666564,
              21.02229499903983
            ],
            [
              105.8265964309412,
              21.022290072638004
            ],
            [
              105.82669673041659,
              21.022272012013122
            ],
            [
              105.82736740924047,
              21.021905091650105
            ],
            [
              105.82721277073566,
              21.021608735993873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A31_Ngõ 32, Hào Nam",
          "maTaiSan": "04.O13.DODV.2701",
          "diaChiLapD": "Ngõ 32, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 138,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02229499903983,
          "to_latitude": 21.021608735993873,
          "to_longitude": 105.82721277073566,
          "from_longitude": 105.8265690666564
        }
      },
      {
        "id": 2695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82669891989511,
            21.022462046414482,
            105.82716797661479,
            21.022617655728634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82669891989511,
              21.022617655728634
            ],
            [
              105.8267005801261,
              21.02261710482063
            ],
            [
              105.8269720043152,
              21.022527060616802
            ],
            [
              105.8270568670929,
              21.02249890722598
            ],
            [
              105.82716797661479,
              21.022462046414482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2702",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022617655728634,
          "to_latitude": 21.022462046414482,
          "to_longitude": 105.82716797661479,
          "from_longitude": 105.82669891989511
        }
      },
      {
        "id": 2696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82673255695005,
            21.022634295831985,
            105.82687468594455,
            21.023034256627668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82687468594455,
              21.023034256627668
            ],
            [
              105.82683521529256,
              21.022921146147844
            ],
            [
              105.82681998984941,
              21.022877514002356
            ],
            [
              105.82680539731855,
              21.022838926000528
            ],
            [
              105.82680351906777,
              21.02283395836047
            ],
            [
              105.82675170626865,
              21.022696944385334
            ],
            [
              105.82673255695005,
              21.022634295831985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5_Hào Nam",
          "maTaiSan": "04.O13.DODV.2703",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023034256627668,
          "to_latitude": 21.022634295831985,
          "to_longitude": 105.82673255695005,
          "from_longitude": 105.82687468594455
        }
      },
      {
        "id": 2697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82678413657597,
            21.022921146147844,
            105.82683521529256,
            21.02293366928509
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82678413657597,
              21.02293366928509
            ],
            [
              105.82681315121533,
              21.022926555340774
            ],
            [
              105.82683185096974,
              21.022921970889705
            ],
            [
              105.82683521529256,
              21.022921146147844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5_Hào Nam",
          "maTaiSan": "04.O13.DODV.2704",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02293366928509,
          "to_latitude": 21.022921146147844,
          "to_longitude": 105.82683521529256,
          "from_longitude": 105.82678413657597
        }
      },
      {
        "id": 2698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82676075522501,
            21.022580422896432,
            105.8277324216381,
            21.022847254875323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82676075522501,
              21.022847254875323
            ],
            [
              105.82679766291878,
              21.022838743212098
            ],
            [
              105.82680539731855,
              21.022838926000528
            ],
            [
              105.8268338661585,
              21.022839601410514
            ],
            [
              105.82727031143392,
              21.02271245108743
            ],
            [
              105.8277324216381,
              21.022580422896432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT11_Ngõ 34, Hào Nam",
          "maTaiSan": "04.O13.DODV.2705",
          "diaChiLapD": "Ngõ 34, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022847254875323,
          "to_latitude": 21.022580422896432,
          "to_longitude": 105.8277324216381,
          "from_longitude": 105.82676075522501
        }
      },
      {
        "id": 2699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82769523326039,
            21.02228223883235,
            105.82777145546059,
            21.022529269359723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82777145546059,
              21.022529269359723
            ],
            [
              105.82769523326039,
              21.02228223883235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7_Ngõ 34, Hào Nam",
          "maTaiSan": "04.O13.DODV.2706",
          "diaChiLapD": "Ngõ 34, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022529269359723,
          "to_latitude": 21.02228223883235,
          "to_longitude": 105.82769523326039,
          "from_longitude": 105.82777145546059
        }
      },
      {
        "id": 2700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82761809730118,
            21.022046309011415,
            105.82776584094341,
            21.022309509519133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82761809730118,
              21.022309509519133
            ],
            [
              105.82764274325261,
              21.02230079630906
            ],
            [
              105.82767935501505,
              21.022287852870488
            ],
            [
              105.82769523326039,
              21.02228223883235
            ],
            [
              105.82776584094341,
              21.02225736271975
            ],
            [
              105.82770269935959,
              21.022046309011415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2707",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022309509519133,
          "to_latitude": 21.022046309011415,
          "to_longitude": 105.82770269935959,
          "from_longitude": 105.82761809730118
        }
      },
      {
        "id": 2701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276393258224,
            21.022294436169346,
            105.82764274325261,
            21.02230079630906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276393258224,
              21.022294436169346
            ],
            [
              105.82763969810985,
              21.022295133454012
            ],
            [
              105.82764036096809,
              21.022296363130753
            ],
            [
              105.82764274325261,
              21.02230079630906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2708",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022294436169346,
          "to_latitude": 21.02230079630906,
          "to_longitude": 105.82764274325261,
          "from_longitude": 105.8276393258224
        }
      },
      {
        "id": 2702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82719438769479,
            21.02208345398187,
            105.82737134700454,
            21.022381804659748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82737134700454,
              21.022381804659748
            ],
            [
              105.82737126346898,
              21.022381658743644
            ],
            [
              105.82727247761476,
              21.022205208289225
            ],
            [
              105.82719438769479,
              21.02208345398187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B4_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2709",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022381804659748,
          "to_latitude": 21.02208345398187,
          "to_longitude": 105.82719438769479,
          "from_longitude": 105.82737134700454
        }
      },
      {
        "id": 2703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82732891971301,
            21.021822633628037,
            105.82756667922555,
            21.022322196213196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82756667922555,
              21.022322196213196
            ],
            [
              105.82756611641133,
              21.022321314694263
            ],
            [
              105.82732891971301,
              21.021949235228654
            ],
            [
              105.82746341871072,
              21.021876887457502
            ],
            [
              105.82756270131907,
              21.021822633628037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B6_Ngách 32A/24, Hào Nam",
          "maTaiSan": "04.O13.DODV.2710",
          "diaChiLapD": "Ngách 32A/24, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022322196213196,
          "to_latitude": 21.021822633628037,
          "to_longitude": 105.82756270131907,
          "from_longitude": 105.82756667922555
        }
      },
      {
        "id": 2704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82704230611515,
            21.0221981543696,
            105.82716797661479,
            21.022462046414482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82716797661479,
              21.022462046414482
            ],
            [
              105.82716321651236,
              21.022448579896658
            ],
            [
              105.82715999654384,
              21.0224394729822
            ],
            [
              105.82714595446092,
              21.022444349139068
            ],
            [
              105.82714153069317,
              21.022435176723693
            ],
            [
              105.82709665759563,
              21.022342133330604
            ],
            [
              105.8271028136413,
              21.022315510639235
            ],
            [
              105.82704230611515,
              21.0221981543696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2711",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022462046414482,
          "to_latitude": 21.0221981543696,
          "to_longitude": 105.82704230611515,
          "from_longitude": 105.82716797661479
        }
      },
      {
        "id": 2705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82714153069317,
            21.022359559304704,
            105.82731541973091,
            21.022435176723693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82714153069317,
              21.022435176723693
            ],
            [
              105.82731541973091,
              21.022359559304704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2712",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022435176723693,
          "to_latitude": 21.022359559304704,
          "to_longitude": 105.82731541973091,
          "from_longitude": 105.82714153069317
        }
      },
      {
        "id": 2706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82668890855732,
            21.02240880646067,
            105.82711810583312,
            21.022550131009435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82668890855732,
              21.02240880646067
            ],
            [
              105.82677854799194,
              21.022542282013884
            ],
            [
              105.82680995574732,
              21.022550131009435
            ],
            [
              105.82690395957974,
              21.022519232613785
            ],
            [
              105.82702731026443,
              21.02247868863964
            ],
            [
              105.82711810583312,
              21.022448843533603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2713",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02240880646067,
          "to_latitude": 21.022448843533603,
          "to_longitude": 105.82711810583312,
          "from_longitude": 105.82668890855732
        }
      },
      {
        "id": 2707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82690395957974,
            21.022519232613785,
            105.82692429813817,
            21.02254192796296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82691957707911,
              21.022525809841383
            ],
            [
              105.82692429813817,
              21.022538654234143
            ],
            [
              105.82691343232422,
              21.02254192796296
            ],
            [
              105.82690820900883,
              21.022529413882477
            ],
            [
              105.82690395957974,
              21.022519232613785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2714",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022525809841383,
          "to_latitude": 21.022519232613785,
          "to_longitude": 105.82690395957974,
          "from_longitude": 105.82691957707911
        }
      },
      {
        "id": 2708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82705141807259,
            21.022484018765496,
            105.8270568670929,
            21.02249890722598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82705141807259,
              21.022484018765496
            ],
            [
              105.82705381394476,
              21.02249056904607
            ],
            [
              105.82705674501,
              21.022498574529543
            ],
            [
              105.8270568670929,
              21.02249890722598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2715",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022484018765496,
          "to_latitude": 21.02249890722598,
          "to_longitude": 105.8270568670929,
          "from_longitude": 105.82705141807259
        }
      },
      {
        "id": 2709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82627804068737,
            21.021702713148166,
            105.82651430224699,
            21.022126736940628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82627804068737,
              21.021702713148166
            ],
            [
              105.82636166163728,
              21.021821608521563
            ],
            [
              105.82640578762042,
              21.02191569898672
            ],
            [
              105.82651430224699,
              21.022111440762675
            ],
            [
              105.82650122460025,
              21.022117459275762
            ],
            [
              105.82647841621353,
              21.022126736940628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3_Hào Nam",
          "maTaiSan": "04.O13.DODV.2716",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021702713148166,
          "to_latitude": 21.022126736940628,
          "to_longitude": 105.82647841621353,
          "from_longitude": 105.82627804068737
        }
      },
      {
        "id": 2710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8261221834586,
            21.021147702064145,
            105.8262490406236,
            21.021610480262517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8262490406236,
              21.021610480262517
            ],
            [
              105.8261967194556,
              21.02141961011421
            ],
            [
              105.82619177458666,
              21.021401569624928
            ],
            [
              105.82618672564739,
              21.02138314757657
            ],
            [
              105.82617528191402,
              21.021341404599067
            ],
            [
              105.8261221834586,
              21.021147702064145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1_Hào Nam",
          "maTaiSan": "04.O13.DODV.2717",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021610480262517,
          "to_latitude": 21.021147702064145,
          "to_longitude": 105.8261221834586,
          "from_longitude": 105.8262490406236
        }
      },
      {
        "id": 2711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8261439819674,
            21.02141961011421,
            105.8261967194556,
            21.021430945235217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8261439819674,
              21.021430945235217
            ],
            [
              105.8261563210429,
              21.021428293046004
            ],
            [
              105.82617830404412,
              21.021423568078372
            ],
            [
              105.8261967194556,
              21.02141961011421
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1_Hào Nam",
          "maTaiSan": "04.O13.DODV.2718",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021430945235217,
          "to_latitude": 21.02141961011421,
          "to_longitude": 105.8261967194556,
          "from_longitude": 105.8261439819674
        }
      },
      {
        "id": 2712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82729998022837,
            21.020516805654136,
            105.82757449101919,
            21.021043455503342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82729998022837,
              21.020516805654136
            ],
            [
              105.82730840717493,
              21.020533387626564
            ],
            [
              105.82736153433936,
              21.020582119258254
            ],
            [
              105.82757449101919,
              21.020931546426542
            ],
            [
              105.82736981116237,
              21.021043455503342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A9_Ngõ 170, La Thành",
          "maTaiSan": "04.O13.DODV.2719",
          "diaChiLapD": "Ngõ 170, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020516805654136,
          "to_latitude": 21.021043455503342,
          "to_longitude": 105.82736981116237,
          "from_longitude": 105.82729998022837
        }
      },
      {
        "id": 2713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82736012238878,
            21.020483997922092,
            105.82761472300864,
            21.020880724000197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82761472300864,
              21.020880724000197
            ],
            [
              105.82753338946972,
              21.020759610632368
            ],
            [
              105.82736985347194,
              21.020499479742142
            ],
            [
              105.82736012238878,
              21.020483997922092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8_Ngõ 170, La Thành",
          "maTaiSan": "04.O13.DODV.2720",
          "diaChiLapD": "Ngõ 170, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020880724000197,
          "to_latitude": 21.020483997922092,
          "to_longitude": 105.82736012238878,
          "from_longitude": 105.82761472300864
        }
      },
      {
        "id": 2714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82731813956086,
            21.021002164386356,
            105.82737992668363,
            21.021077219069426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82731813956086,
              21.021007152407268
            ],
            [
              105.82734570718186,
              21.021002164386356
            ],
            [
              105.82736981116237,
              21.021043455503342
            ],
            [
              105.82737992668363,
              21.02106078435287
            ],
            [
              105.82734936409781,
              21.021077219069426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A9_Ngõ 170, La Thành",
          "maTaiSan": "04.O13.DODV.2721",
          "diaChiLapD": "Ngõ 170, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021007152407268,
          "to_latitude": 21.021077219069426,
          "to_longitude": 105.82734936409781,
          "from_longitude": 105.82731813956086
        }
      },
      {
        "id": 2715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82742041454567,
            21.020186597107685,
            105.82797702833419,
            21.020475241426404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82742041454567,
              21.020475241426404
            ],
            [
              105.82765231495189,
              21.020350182575683
            ],
            [
              105.82784807754506,
              21.02024461206209
            ],
            [
              105.82797702833419,
              21.020186597107685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2722",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020475241426404,
          "to_latitude": 21.020186597107685,
          "to_longitude": 105.82797702833419,
          "from_longitude": 105.82742041454567
        }
      },
      {
        "id": 2716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276357673342,
            21.020323195236035,
            105.82765231495189,
            21.020350182575683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276357673342,
              21.020323195236035
            ],
            [
              105.82763743890278,
              21.020325923875376
            ],
            [
              105.82763796504597,
              21.02032678570195
            ],
            [
              105.82764062283103,
              21.020331119090137
            ],
            [
              105.82765231495189,
              21.020350182575683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2723",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020323195236035,
          "to_latitude": 21.020350182575683,
          "to_longitude": 105.82765231495189,
          "from_longitude": 105.8276357673342
        }
      },
      {
        "id": 2717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832936630182,
            21.019921923938956,
            105.82835019893143,
            21.0199541976944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832936630182,
              21.019921923938956
            ],
            [
              105.82833317460675,
              21.01992782422861
            ],
            [
              105.82834233106142,
              21.019942015371935
            ],
            [
              105.82834253867765,
              21.01994233319941
            ],
            [
              105.82834443945131,
              21.01994528110247
            ],
            [
              105.82835019893143,
              21.0199541976944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2724",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019921923938956,
          "to_latitude": 21.0199541976944,
          "to_longitude": 105.82835019893143,
          "from_longitude": 105.82832936630182
        }
      },
      {
        "id": 2718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279916517484,
            21.019888735972206,
            105.82850979096567,
            21.020150981303026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279916517484,
              21.020150981303026
            ],
            [
              105.82805366107675,
              21.020121362564478
            ],
            [
              105.82813687697158,
              21.02007537708683
            ],
            [
              105.82817184312641,
              21.02004221654806
            ],
            [
              105.82818025762681,
              21.020034237133387
            ],
            [
              105.82821268070037,
              21.02001821411842
            ],
            [
              105.82830322581212,
              21.019973467326743
            ],
            [
              105.82832487581007,
              21.019964585261484
            ],
            [
              105.82835019893143,
              21.0199541976944
            ],
            [
              105.82850979096567,
              21.019888735972206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2725",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020150981303026,
          "to_latitude": 21.019888735972206,
          "to_longitude": 105.82850979096567,
          "from_longitude": 105.8279916517484
        }
      },
      {
        "id": 2719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859221992656,
            21.019454771027636,
            105.82925373757334,
            21.019835305213032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859221992656,
              21.019835305213032
            ],
            [
              105.82876854715188,
              21.01971625648163
            ],
            [
              105.82880582460061,
              21.019691088034453
            ],
            [
              105.82884578732876,
              21.019670004427528
            ],
            [
              105.82921119960955,
              21.019477213989955
            ],
            [
              105.82925373757334,
              21.019454771027636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2726",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019835305213032,
          "to_latitude": 21.019454771027636,
          "to_longitude": 105.82925373757334,
          "from_longitude": 105.82859221992656
        }
      },
      {
        "id": 2720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288271919733,
            21.019639436863518,
            105.82884578732876,
            21.019670004427528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288271919733,
              21.019639436863518
            ],
            [
              105.82883347358008,
              21.019653358375077
            ],
            [
              105.82883622479414,
              21.019659449868392
            ],
            [
              105.82884578732876,
              21.019670004427528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2727",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019639436863518,
          "to_latitude": 21.019670004427528,
          "to_longitude": 105.82884578732876,
          "from_longitude": 105.8288271919733
        }
      },
      {
        "id": 2721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925877096527,
            21.019399871952125,
            105.82955258111966,
            21.019783789315678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925877096527,
              21.019399871952125
            ],
            [
              105.8292606360537,
              21.019404192920938
            ],
            [
              105.82926263362369,
              21.019408813114588
            ],
            [
              105.8292757296795,
              21.01942706215928
            ],
            [
              105.82928286725779,
              21.0194385109665
            ],
            [
              105.82935414088949,
              21.01955278093728
            ],
            [
              105.82939280864903,
              21.019550821180164
            ],
            [
              105.82941121449659,
              21.01954988872654
            ],
            [
              105.82946807521763,
              21.019618076829914
            ],
            [
              105.82955258111966,
              21.01973951791935
            ],
            [
              105.82948112409116,
              21.019783789315678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.1_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.2728",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019399871952125,
          "to_latitude": 21.019783789315678,
          "to_longitude": 105.82948112409116,
          "from_longitude": 105.82925877096527
        }
      },
      {
        "id": 2722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910330225602,
            21.019402239321586,
            105.82936070192545,
            21.019924238415925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925450621187,
              21.019402239321586
            ],
            [
              105.82925683659157,
              21.019405984474215
            ],
            [
              105.82925976767383,
              21.01941067869763
            ],
            [
              105.82926148577008,
              21.019430690306216
            ],
            [
              105.82927086511016,
              21.01944499321529
            ],
            [
              105.8293546957841,
              21.01957275576392
            ],
            [
              105.82936070192545,
              21.019581910472734
            ],
            [
              105.8292942246989,
              21.019624736737935
            ],
            [
              105.82934807337269,
              21.01972612676429
            ],
            [
              105.82924722945361,
              21.019797748285583
            ],
            [
              105.82920513758157,
              21.01982764248685
            ],
            [
              105.82922150116812,
              21.019850535343572
            ],
            [
              105.82910330225602,
              21.019924238415925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.1_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.2729",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019402239321586,
          "to_latitude": 21.019924238415925,
          "to_longitude": 105.82910330225602,
          "from_longitude": 105.82925450621187
        }
      },
      {
        "id": 2723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82934807337269,
            21.01972612676429,
            105.8293977111567,
            21.01979891139125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82934807337269,
              21.01972612676429
            ],
            [
              105.8293977111567,
              21.01979891139125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4.1_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.2730",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01972612676429,
          "to_latitude": 21.01979891139125,
          "to_longitude": 105.8293977111567,
          "from_longitude": 105.82934807337269
        }
      },
      {
        "id": 2724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82914403698129,
            21.01969254688712,
            105.82924722945361,
            21.019797748285583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924722945361,
              21.019797748285583
            ],
            [
              105.8291994957484,
              21.019737366449803
            ],
            [
              105.82914508153515,
              21.019693391730375
            ],
            [
              105.82914403698129,
              21.01969254688712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.1_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.2731",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019797748285583,
          "to_latitude": 21.01969254688712,
          "to_longitude": 105.82914403698129,
          "from_longitude": 105.82924722945361
        }
      },
      {
        "id": 2725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298619820908,
            21.01912356194808,
            105.82989149095057,
            21.01930145965339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298619820908,
              21.01912356194808
            ],
            [
              105.82986246425405,
              21.019125514174803
            ],
            [
              105.8298656629425,
              21.0191384092685
            ],
            [
              105.82989149095057,
              21.019191838184998
            ],
            [
              105.82987265634594,
              21.01930145965339
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2732",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01912356194808,
          "to_latitude": 21.01930145965339,
          "to_longitude": 105.82987265634594,
          "from_longitude": 105.8298619820908
        }
      },
      {
        "id": 2726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82929225589504,
            21.01916092574124,
            105.82979605120141,
            21.01941883359775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82929225589504,
              21.01941883359775
            ],
            [
              105.82938544938602,
              21.019361185216013
            ],
            [
              105.8294777666333,
              21.019310351374727
            ],
            [
              105.82952210497584,
              21.019285936860093
            ],
            [
              105.82953803654905,
              21.019277164724002
            ],
            [
              105.82956679714532,
              21.019270014537206
            ],
            [
              105.82958708586142,
              21.01926497133814
            ],
            [
              105.82961883405288,
              21.019257080379457
            ],
            [
              105.82962969792409,
              21.01925437913911
            ],
            [
              105.82971593448404,
              21.019205460762315
            ],
            [
              105.82976747651973,
              21.019176809245245
            ],
            [
              105.82976750816863,
              21.019176791929585
            ],
            [
              105.82979605120141,
              21.01916092574124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2733",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01941883359775,
          "to_latitude": 21.01916092574124,
          "to_longitude": 105.82979605120141,
          "from_longitude": 105.82929225589504
        }
      },
      {
        "id": 2727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946182851127,
            21.01928715659532,
            105.8294777666333,
            21.019310351374727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946182851127,
              21.01928715659532
            ],
            [
              105.82946778191865,
              21.01929582200992
            ],
            [
              105.8294733435427,
              21.019303917593277
            ],
            [
              105.82947580091434,
              21.019307490510823
            ],
            [
              105.8294777666333,
              21.019310351374727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2734",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01928715659532,
          "to_latitude": 21.019310351374727,
          "to_longitude": 105.8294777666333,
          "from_longitude": 105.82946182851127
        }
      },
      {
        "id": 2728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82634430451554,
            21.020980018654836,
            105.82636227402732,
            21.021007225924286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82634430451554,
              21.020980018654836
            ],
            [
              105.82634610014506,
              21.020982743994736
            ],
            [
              105.82634675491921,
              21.020983731652056
            ],
            [
              105.82635202527466,
              21.02099170862495
            ],
            [
              105.82636227402732,
              21.021007225924286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2735",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020980018654836,
          "to_latitude": 21.021007225924286,
          "to_longitude": 105.82636227402732,
          "from_longitude": 105.82634430451554
        }
      },
      {
        "id": 2729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613246589189,
            21.020878754167754,
            105.82663973365354,
            21.021113634846042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82663973365354,
              21.020878754167754
            ],
            [
              105.82636227402732,
              21.021007225924286
            ],
            [
              105.82631811767608,
              21.021027671788925
            ],
            [
              105.82613246589189,
              21.021113634846042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2736",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020878754167754,
          "to_latitude": 21.021113634846042,
          "to_longitude": 105.82613246589189,
          "from_longitude": 105.82663973365354
        }
      },
      {
        "id": 2730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82619177458666,
            21.021349685812897,
            105.82628663310787,
            21.021401569624928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82619177458666,
              21.021401569624928
            ],
            [
              105.82628663310787,
              21.021364747692388
            ],
            [
              105.82628024821358,
              21.021349685812897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Hào Nam",
          "maTaiSan": "04.O13.DODV.2737",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021401569624928,
          "to_latitude": 21.021349685812897,
          "to_longitude": 105.82628024821358,
          "from_longitude": 105.82619177458666
        }
      },
      {
        "id": 2731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753338946972,
            21.020587058468806,
            105.82785209880278,
            21.020759610632368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753338946972,
              21.020759610632368
            ],
            [
              105.82775240672503,
              21.02064103263521
            ],
            [
              105.82785209880278,
              21.020587058468806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8_Ngõ 170, La Thành",
          "maTaiSan": "04.O13.DODV.2738",
          "diaChiLapD": "Ngõ 170, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020759610632368,
          "to_latitude": 21.020587058468806,
          "to_longitude": 105.82785209880278,
          "from_longitude": 105.82753338946972
        }
      },
      {
        "id": 2732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82958708586142,
            21.01926497133814,
            105.82964604453139,
            21.019350885783652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82958708586142,
              21.01926497133814
            ],
            [
              105.82959110755837,
              21.01927205378327
            ],
            [
              105.82963398698695,
              21.019347524077947
            ],
            [
              105.82964604453139,
              21.019350885783652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2739",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01926497133814,
          "to_latitude": 21.019350885783652,
          "to_longitude": 105.82964604453139,
          "from_longitude": 105.82958708586142
        }
      },
      {
        "id": 2733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946807521763,
            21.01949837661963,
            105.82972107207273,
            21.019618076829914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946807521763,
              21.019618076829914
            ],
            [
              105.82965464372616,
              21.01949837661963
            ],
            [
              105.82972107207273,
              21.019565147778835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.1_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.2740",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019618076829914,
          "to_latitude": 21.019565147778835,
          "to_longitude": 105.82972107207273,
          "from_longitude": 105.82946807521763
        }
      },
      {
        "id": 2734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694157489202,
            21.022321061869803,
            105.82702731026443,
            21.02247868863964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702731026443,
              21.02247868863964
            ],
            [
              105.82694157489202,
              21.022321061869803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2741",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02247868863964,
          "to_latitude": 21.022321061869803,
          "to_longitude": 105.82694157489202,
          "from_longitude": 105.82702731026443
        }
      },
      {
        "id": 2735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254771214396,
            21.021969287823058,
            105.8257843573576,
            21.02208533699818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257843573576,
              21.02208533699818
            ],
            [
              105.82573469676271,
              21.021969287823058
            ],
            [
              105.8254771214396,
              21.022062305804933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_L4_Ngách 39/7, Hào Nam",
          "maTaiSan": "04.O13.DODV.2742",
          "diaChiLapD": "Ngách 39/7, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02208533699818,
          "to_latitude": 21.022062305804933,
          "to_longitude": 105.8254771214396,
          "from_longitude": 105.8257843573576
        }
      },
      {
        "id": 2736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254948634053,
            21.02198800710326,
            105.82608757005411,
            21.022164383529454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82608757005411,
              21.02198800710326
            ],
            [
              105.82593114342562,
              21.022045256294387
            ],
            [
              105.82582805594116,
              21.022073404579427
            ],
            [
              105.82580628349218,
              21.022079349642926
            ],
            [
              105.8257843573576,
              21.02208533699818
            ],
            [
              105.8254948634053,
              21.022164383529454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2743",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02198800710326,
          "to_latitude": 21.022164383529454,
          "to_longitude": 105.8254948634053,
          "from_longitude": 105.82608757005411
        }
      },
      {
        "id": 2737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82404885136816,
            21.023823845391583,
            105.82458657403545,
            21.02400176859412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82404885136816,
              21.02400176859412
            ],
            [
              105.82458657403545,
              21.023823845391583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K4_Ngách 318/47, La Thành",
          "maTaiSan": "04.O13.DODV.2744",
          "diaChiLapD": "Ngách 318/47, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02400176859412,
          "to_latitude": 21.023823845391583,
          "to_longitude": 105.82458657403545,
          "from_longitude": 105.82404885136816
        }
      },
      {
        "id": 2738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82457024992615,
            21.023458160485962,
            105.82464808614107,
            21.023823845391583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82458657403545,
              21.023823845391583
            ],
            [
              105.82464808614107,
              21.02374784963026
            ],
            [
              105.82458020059482,
              21.02352714053654
            ],
            [
              105.82457024992615,
              21.02348116311927
            ],
            [
              105.82460443650348,
              21.02346356602391
            ],
            [
              105.82461500019338,
              21.023458160485962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K4_Ngách 318/47, La Thành",
          "maTaiSan": "04.O13.DODV.2745",
          "diaChiLapD": "Ngách 318/47, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023823845391583,
          "to_latitude": 21.023458160485962,
          "to_longitude": 105.82461500019338,
          "from_longitude": 105.82458657403545
        }
      },
      {
        "id": 2739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82010267401505,
            21.023315016494635,
            105.82052054654119,
            21.023436879232655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82010267401505,
              21.023436879232655
            ],
            [
              105.82013761160827,
              21.023407521200415
            ],
            [
              105.82018252240783,
              21.023383490166225
            ],
            [
              105.82021414973671,
              21.023374524865858
            ],
            [
              105.82026658628247,
              21.023359661391606
            ],
            [
              105.82028266498203,
              21.023355093004533
            ],
            [
              105.82052054654119,
              21.023315016494635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2746",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023436879232655,
          "to_latitude": 21.023315016494635,
          "to_longitude": 105.82052054654119,
          "from_longitude": 105.82010267401505
        }
      },
      {
        "id": 2740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82052517810773,
            21.023277675554098,
            105.82076671204608,
            21.023486585216528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82076671204608,
              21.023453557807976
            ],
            [
              105.82073665290704,
              21.023469048677637
            ],
            [
              105.82067199257483,
              21.023486585216528
            ],
            [
              105.82066153163593,
              21.02345208909979
            ],
            [
              105.82058832571244,
              21.023472043229074
            ],
            [
              105.820536754855,
              21.02329528680715
            ],
            [
              105.82053118477128,
              21.023286817934206
            ],
            [
              105.82052517810773,
              21.023277675554098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D6_Ngõ 508, La Thành",
          "maTaiSan": "04.O13.DODV.2747",
          "diaChiLapD": "Ngõ 508, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023453557807976,
          "to_latitude": 21.023277675554098,
          "to_longitude": 105.82052517810773,
          "from_longitude": 105.82076671204608
        }
      },
      {
        "id": 2741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82057884795739,
            21.023185291111766,
            105.82114066975008,
            21.023307653021217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82057884795739,
              21.023307653021217
            ],
            [
              105.82078765890442,
              21.023269838074228
            ],
            [
              105.82081725110619,
              21.023264479131058
            ],
            [
              105.82095051881416,
              21.023240344811434
            ],
            [
              105.82103965202687,
              21.02321385828666
            ],
            [
              105.82114066975008,
              21.023185291111766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2748",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023307653021217,
          "to_latitude": 21.023185291111766,
          "to_longitude": 105.82114066975008,
          "from_longitude": 105.82057884795739
        }
      },
      {
        "id": 2742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82103965202687,
            21.02321385828666,
            105.82107159901891,
            21.023301578929527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82103965202687,
              21.02321385828666
            ],
            [
              105.82107159901891,
              21.023301578929527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5_Ngõ 474, La Thành",
          "maTaiSan": "04.O13.DODV.2749",
          "diaChiLapD": "Ngõ 474, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02321385828666,
          "to_latitude": 21.023301578929527,
          "to_longitude": 105.82107159901891,
          "from_longitude": 105.82103965202687
        }
      },
      {
        "id": 2743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82095051881416,
            21.023240344811434,
            105.82098503833696,
            21.023356039514905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82095051881416,
              21.023240344811434
            ],
            [
              105.82098503833696,
              21.023356039514905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5_Ngõ 478, La Thành",
          "maTaiSan": "04.O13.DODV.2750",
          "diaChiLapD": "Ngõ 478, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023240344811434,
          "to_latitude": 21.023356039514905,
          "to_longitude": 105.82098503833696,
          "from_longitude": 105.82095051881416
        }
      },
      {
        "id": 2744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82125775507738,
            21.022969428236824,
            105.82178603264978,
            21.023148390807197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82125775507738,
              21.023148390807197
            ],
            [
              105.82147625181915,
              21.023080018182807
            ],
            [
              105.8214906025683,
              21.0230748953193
            ],
            [
              105.82151877311837,
              21.023064838217877
            ],
            [
              105.82178603264978,
              21.022969428236824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C8_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2751",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023148390807197,
          "to_latitude": 21.022969428236824,
          "to_longitude": 105.82178603264978,
          "from_longitude": 105.82125775507738
        }
      },
      {
        "id": 2745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82184267666644,
            21.02285422453456,
            105.82220016695827,
            21.02295948911707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184267666644,
              21.02295948911707
            ],
            [
              105.82192915429717,
              21.022934025676047
            ],
            [
              105.82196001501204,
              21.022924939115594
            ],
            [
              105.82220016695827,
              21.02285422453456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2752",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02295948911707,
          "to_latitude": 21.02285422453456,
          "to_longitude": 105.82220016695827,
          "from_longitude": 105.82184267666644
        }
      },
      {
        "id": 2746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82239742974556,
            21.022615688484038,
            105.82284610365643,
            21.02281950191494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82239742974556,
              21.02281950191494
            ],
            [
              105.8224346630496,
              21.022785769743656
            ],
            [
              105.82261686998353,
              21.022692716186224
            ],
            [
              105.82262005100797,
              21.02269109125241
            ],
            [
              105.8226683194555,
              21.022682137145026
            ],
            [
              105.82276562946839,
              21.022645766825818
            ],
            [
              105.82284610365643,
              21.022615688484038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C3_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2753",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02281950191494,
          "to_latitude": 21.022615688484038,
          "to_longitude": 105.82284610365643,
          "from_longitude": 105.82239742974556
        }
      },
      {
        "id": 2747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8228758894657,
            21.022396547720003,
            105.82337860585893,
            21.02259604012142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8228758894657,
              21.02259604012142
            ],
            [
              105.82313301678495,
              21.022494004956545
            ],
            [
              105.82315328201582,
              21.022485963009274
            ],
            [
              105.82337860585893,
              21.022396547720003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2754",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02259604012142,
          "to_latitude": 21.022396547720003,
          "to_longitude": 105.82337860585893,
          "from_longitude": 105.8228758894657
        }
      },
      {
        "id": 2748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82346423714424,
            21.02207801299284,
            105.82408645930656,
            21.022347233417353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346423714424,
              21.022347233417353
            ],
            [
              105.82374190633895,
              21.02222709299593
            ],
            [
              105.82377697472297,
              21.02221191966985
            ],
            [
              105.82408645930656,
              21.02207801299284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2755",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022347233417353,
          "to_latitude": 21.02207801299284,
          "to_longitude": 105.82408645930656,
          "from_longitude": 105.82346423714424
        }
      },
      {
        "id": 2749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82127210825838,
            21.023255758433834,
            105.82151552154211,
            21.023643162439548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82127210825838,
              21.023255758433834
            ],
            [
              105.82136861622037,
              21.023402981590547
            ],
            [
              105.82139146221766,
              21.02344033345988
            ],
            [
              105.82151552154211,
              21.023643162439548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2756",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023255758433834,
          "to_latitude": 21.023643162439548,
          "to_longitude": 105.82151552154211,
          "from_longitude": 105.82127210825838
        }
      },
      {
        "id": 2750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82416467227117,
            21.02211065123917,
            105.8245006181742,
            21.02255431438975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82416467227117,
              21.022163799046314
            ],
            [
              105.82418499284601,
              21.022156743936083
            ],
            [
              105.82426887791317,
              21.02213143821227
            ],
            [
              105.82433581365532,
              21.02211065123917
            ],
            [
              105.82446197232059,
              21.022386164860997
            ],
            [
              105.82444083708984,
              21.022403046532023
            ],
            [
              105.8245006181742,
              21.02255431438975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K8_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2757",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022163799046314,
          "to_latitude": 21.02255431438975,
          "to_longitude": 105.8245006181742,
          "from_longitude": 105.82416467227117
        }
      },
      {
        "id": 2751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82444083708984,
            21.02233698142218,
            105.82461141794961,
            21.022403046532023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82444083708984,
              21.022403046532023
            ],
            [
              105.82461141794961,
              21.02233698142218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_K8_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2758",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022403046532023,
          "to_latitude": 21.02233698142218,
          "to_longitude": 105.82461141794961,
          "from_longitude": 105.82444083708984
        }
      },
      {
        "id": 2752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82413204672999,
            21.022120747718827,
            105.824417119086,
            21.02284160978043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82413204672999,
              21.022120747718827
            ],
            [
              105.82421934303619,
              21.02230493591081
            ],
            [
              105.82433122835478,
              21.022572683310393
            ],
            [
              105.82433923211553,
              21.022592641933944
            ],
            [
              105.82439330777981,
              21.022727486128474
            ],
            [
              105.824417119086,
              21.02284160978043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2759",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022120747718827,
          "to_latitude": 21.02284160978043,
          "to_longitude": 105.824417119086,
          "from_longitude": 105.82413204672999
        }
      },
      {
        "id": 2753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82442139204177,
            21.022880767380766,
            105.82452832683408,
            21.023263269021495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82442139204177,
              21.022880767380766
            ],
            [
              105.82445315934083,
              21.023009995035892
            ],
            [
              105.82447857527077,
              21.023113434768888
            ],
            [
              105.82452832683408,
              21.023263269021495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K3_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2760",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022880767380766,
          "to_latitude": 21.023263269021495,
          "to_longitude": 105.82452832683408,
          "from_longitude": 105.82442139204177
        }
      },
      {
        "id": 2754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82431328217729,
            21.022464823987534,
            105.82455927061977,
            21.02327507951786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82431328217729,
              21.022464823987534
            ],
            [
              105.82438117503717,
              21.022606722254512
            ],
            [
              105.824421048931,
              21.022725812567828
            ],
            [
              105.824423356142,
              21.022732703834205
            ],
            [
              105.82442467315572,
              21.02274076235157
            ],
            [
              105.82444341179826,
              21.0228315911096
            ],
            [
              105.82447027806889,
              21.02294914845782
            ],
            [
              105.82450100731336,
              21.023082900873963
            ],
            [
              105.82450564444886,
              21.023103084452647
            ],
            [
              105.82451801244437,
              21.023156916349233
            ],
            [
              105.82455927061977,
              21.02327507951786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2761",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022464823987534,
          "to_latitude": 21.02327507951786,
          "to_longitude": 105.82455927061977,
          "from_longitude": 105.82431328217729
        }
      },
      {
        "id": 2755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8246209274564,
            21.02349304954117,
            105.82470155395995,
            21.02392424228656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8246209274564,
              21.02349304954117
            ],
            [
              105.82467591797689,
              21.023636878982742
            ],
            [
              105.82468550195465,
              21.023665327467338
            ],
            [
              105.82469488186815,
              21.02370442145243
            ],
            [
              105.82469983422415,
              21.023725064139594
            ],
            [
              105.82470155395995,
              21.023740224568414
            ],
            [
              105.82469823413463,
              21.023755790595274
            ],
            [
              105.82466852237143,
              21.023861828814297
            ],
            [
              105.8246726838119,
              21.02392424228656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L8_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2762",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349304954117,
          "to_latitude": 21.02392424228656,
          "to_longitude": 105.8246726838119,
          "from_longitude": 105.8246209274564
        }
      },
      {
        "id": 2756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82398254333599,
            21.023941489425788,
            105.82462386813033,
            21.024093171120537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82462386813033,
              21.023941489425788
            ],
            [
              105.82449025325215,
              21.02397157965897
            ],
            [
              105.82438693993737,
              21.0239946403348
            ],
            [
              105.82436834318558,
              21.023988779123847
            ],
            [
              105.82423680077068,
              21.024024372403144
            ],
            [
              105.82417039124736,
              21.024042342356484
            ],
            [
              105.82398254333599,
              21.024093171120537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F6_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2763",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023941489425788,
          "to_latitude": 21.024093171120537,
          "to_longitude": 105.82398254333599,
          "from_longitude": 105.82462386813033
        }
      },
      {
        "id": 2757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82398051799262,
            21.023953511083917,
            105.82463472876069,
            21.024106760928632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82398051799262,
              21.024106760928632
            ],
            [
              105.82411816308361,
              21.024069004854223
            ],
            [
              105.82424885891628,
              21.02403304363898
            ],
            [
              105.82432672230452,
              21.024013221624866
            ],
            [
              105.82436196078034,
              21.02400425069919
            ],
            [
              105.82438016813246,
              21.02400385083297
            ],
            [
              105.82438492268628,
              21.024003746496422
            ],
            [
              105.8244120350903,
              21.024003151720002
            ],
            [
              105.82463472876069,
              21.023953511083917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2764",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024106760928632,
          "to_latitude": 21.023953511083917,
          "to_longitude": 105.82463472876069,
          "from_longitude": 105.82398051799262
        }
      },
      {
        "id": 2758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82360988820847,
            21.024107516991876,
            105.82397323408915,
            21.02423199639756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82397323408915,
              21.024107516991876
            ],
            [
              105.82397206375816,
              21.024107767436025
            ],
            [
              105.82370079581888,
              21.024165662218365
            ],
            [
              105.8237094542134,
              21.024208994077764
            ],
            [
              105.82360988820847,
              21.02423199639756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F21_Ngách 318/83, La Thành",
          "maTaiSan": "04.O13.DODV.2765",
          "diaChiLapD": "Ngách 318/83, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024107516991876,
          "to_latitude": 21.02423199639756,
          "to_longitude": 105.82360988820847,
          "from_longitude": 105.82397323408915
        }
      },
      {
        "id": 2759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82395174736644,
            21.024119659279325,
            105.82399359011673,
            21.024303080496733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82399359011673,
              21.024303080496733
            ],
            [
              105.82397037096456,
              21.024201297412954
            ],
            [
              105.82396444665058,
              21.024175325865595
            ],
            [
              105.82395174736644,
              21.024119659279325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F2_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2766",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024303080496733,
          "to_latitude": 21.024119659279325,
          "to_longitude": 105.82395174736644,
          "from_longitude": 105.82399359011673
        }
      },
      {
        "id": 2760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367674439611,
            21.024312542648218,
            105.82400795274702,
            21.02437619864255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367674439611,
              21.02437619864255
            ],
            [
              105.82373293715979,
              21.024365650488264
            ],
            [
              105.82378640017743,
              21.024367781469312
            ],
            [
              105.82400772506824,
              21.02431259975131
            ],
            [
              105.82400795274702,
              21.024312542648218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_f31_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2767",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02437619864255,
          "to_latitude": 21.024312542648218,
          "to_longitude": 105.82400795274702,
          "from_longitude": 105.82367674439611
        }
      },
      {
        "id": 2761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82396757762977,
            21.024127132647973,
            105.82406947080706,
            21.02463851038885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82406947080706,
              21.02463851038885
            ],
            [
              105.82405895240097,
              21.024568513512058
            ],
            [
              105.82404612690169,
              21.024516850644062
            ],
            [
              105.82403144301495,
              21.024438790982305
            ],
            [
              105.82401058131762,
              21.024314366170042
            ],
            [
              105.82400905489102,
              21.02430688672961
            ],
            [
              105.82400422446143,
              21.02428321039966
            ],
            [
              105.82398975770138,
              21.024221193673522
            ],
            [
              105.8239765402372,
              21.024164190116874
            ],
            [
              105.82396757762977,
              21.024127132647973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_f3_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2768",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463851038885,
          "to_latitude": 21.024127132647973,
          "to_longitude": 105.82396757762977,
          "from_longitude": 105.82406947080706
        }
      },
      {
        "id": 2762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82407257844856,
            21.02466728473849,
            105.8241672712033,
            21.02531276390267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8241672712033,
              21.02531276390267
            ],
            [
              105.82413197386349,
              21.02518473476029
            ],
            [
              105.82413326721291,
              21.025120936393993
            ],
            [
              105.82413241412218,
              21.025076873064766
            ],
            [
              105.82412382078745,
              21.024995183647974
            ],
            [
              105.82411981723507,
              21.024973885122748
            ],
            [
              105.82412559034712,
              21.02490706272542
            ],
            [
              105.82411677262792,
              21.02486365308665
            ],
            [
              105.82410636202374,
              21.0248124003304
            ],
            [
              105.82407708671987,
              21.024689051400824
            ],
            [
              105.82407257844856,
              21.02466728473849
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F5_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2769",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02531276390267,
          "to_latitude": 21.02466728473849,
          "to_longitude": 105.82407257844856,
          "from_longitude": 105.8241672712033
        }
      },
      {
        "id": 2763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82404612690169,
            21.024460498349573,
            105.82423420757337,
            21.024516850644062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82404612690169,
              21.024516850644062
            ],
            [
              105.82423420757337,
              21.024460498349573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_f3_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2770",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024516850644062,
          "to_latitude": 21.024460498349573,
          "to_longitude": 105.82423420757337,
          "from_longitude": 105.82404612690169
        }
      },
      {
        "id": 2764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82411082052027,
            21.02520575754556,
            105.82443419493048,
            21.025416293157342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82413395866088,
              21.025416293157342
            ],
            [
              105.82412746751422,
              21.025401322943456
            ],
            [
              105.82411082052027,
              21.02536290182595
            ],
            [
              105.82416395072045,
              21.02535097468485
            ],
            [
              105.8241770168473,
              21.0253480418168
            ],
            [
              105.82420656959984,
              21.02534140801712
            ],
            [
              105.8242068347822,
              21.025341348022273
            ],
            [
              105.82420690960278,
              21.025341308820966
            ],
            [
              105.824394235609,
              21.02524236297536
            ],
            [
              105.82442423936521,
              21.0252113868364
            ],
            [
              105.82443419493048,
              21.02520575754556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N5_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2771",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025416293157342,
          "to_latitude": 21.02520575754556,
          "to_longitude": 105.82443419493048,
          "from_longitude": 105.82413395866088
        }
      },
      {
        "id": 2765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82416617124628,
            21.025321118072064,
            105.8241770168473,
            21.0253480418168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8241770168473,
              21.0253480418168
            ],
            [
              105.824175964369,
              21.025342958145732
            ],
            [
              105.82417094964426,
              21.025321118072064
            ],
            [
              105.82416617124628,
              21.025322492454197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N5_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2772",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0253480418168,
          "to_latitude": 21.025322492454197,
          "to_longitude": 105.82416617124628,
          "from_longitude": 105.8241770168473
        }
      },
      {
        "id": 2766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82392663954847,
            21.025132715187826,
            105.82399048083636,
            21.025220397273028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82392663954847,
              21.025220397273028
            ],
            [
              105.82399048083636,
              21.025205533392217
            ],
            [
              105.82397839905366,
              21.025132715187826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N51_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2773",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025220397273028,
          "to_latitude": 21.025132715187826,
          "to_longitude": 105.82397839905366,
          "from_longitude": 105.82392663954847
        }
      },
      {
        "id": 2767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82375923560447,
            21.025014406625427,
            105.82413125933456,
            21.025158951408432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82413125933456,
              21.025106027550397
            ],
            [
              105.82413060482128,
              21.025106138205945
            ],
            [
              105.82412062750409,
              21.02510781145744
            ],
            [
              105.82404268244328,
              21.02512530888501
            ],
            [
              105.82397839905366,
              21.025132715187826
            ],
            [
              105.82383945444568,
              21.025158951408432
            ],
            [
              105.82375923560447,
              21.025014406625427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N51_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2774",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025106027550397,
          "to_latitude": 21.025014406625427,
          "to_longitude": 105.82375923560447,
          "from_longitude": 105.82413125933456
        }
      },
      {
        "id": 2768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82375903046501,
            21.024599049775762,
            105.82406118564842,
            21.024720401405595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82406118564842,
              21.024599049775762
            ],
            [
              105.82406053655774,
              21.024599271501437
            ],
            [
              105.82404927924144,
              21.024603115078214
            ],
            [
              105.82404366499024,
              21.02460503273201
            ],
            [
              105.82392188435657,
              21.024633635389076
            ],
            [
              105.82392814353798,
              21.024665572924572
            ],
            [
              105.8238584022073,
              21.02469315477262
            ],
            [
              105.82375903046501,
              21.024720401405595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F32_Ngách 318/99, La Thành",
          "maTaiSan": "04.O13.DODV.2775",
          "diaChiLapD": "Ngách 318/99, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024599049775762,
          "to_latitude": 21.024720401405595,
          "to_longitude": 105.82375903046501,
          "from_longitude": 105.82406118564842
        }
      },
      {
        "id": 2769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82370786922289,
            21.024537894170184,
            105.82375903046501,
            21.024720401405595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82370786922289,
              21.024537894170184
            ],
            [
              105.82375903046501,
              21.024720401405595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F32_Ngách 318/99, La Thành",
          "maTaiSan": "04.O13.DODV.2776",
          "diaChiLapD": "Ngách 318/99, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024537894170184,
          "to_latitude": 21.024720401405595,
          "to_longitude": 105.82375903046501,
          "from_longitude": 105.82370786922289
        }
      },
      {
        "id": 2770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82441551183834,
            21.024179694666298,
            105.82449664013606,
            21.02439022890307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82449664013606,
              21.02439022890307
            ],
            [
              105.82447273861254,
              21.024306820811812
            ],
            [
              105.82443791415793,
              21.024232246823164
            ],
            [
              105.82441551183834,
              21.024179694666298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2777",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02439022890307,
          "to_latitude": 21.024179694666298,
          "to_longitude": 105.82441551183834,
          "from_longitude": 105.82449664013606
        }
      },
      {
        "id": 2771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82427517816323,
            21.024176101769896,
            105.82443655901636,
            21.02425213084775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82443655901636,
              21.024178945027728
            ],
            [
              105.82442455341224,
              21.0241824571591
            ],
            [
              105.82441551183834,
              21.024179694666298
            ],
            [
              105.82440374518154,
              21.024176101769896
            ],
            [
              105.82427517816323,
              21.0242071645324
            ],
            [
              105.82428800825905,
              21.02425213084775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2778",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024178945027728,
          "to_latitude": 21.02425213084775,
          "to_longitude": 105.82428800825905,
          "from_longitude": 105.82443655901636
        }
      },
      {
        "id": 2772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8242397971447,
            21.024458997613507,
            105.82451381379204,
            21.024618051580436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8242397971447,
              21.024618051580436
            ],
            [
              105.82436613321126,
              21.024564864305106
            ],
            [
              105.82451381379204,
              21.024458997613507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N3_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2779",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024618051580436,
          "to_latitude": 21.024458997613507,
          "to_longitude": 105.82451381379204,
          "from_longitude": 105.8242397971447
        }
      },
      {
        "id": 2773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82451381379204,
            21.024458997613507,
            105.82456351133789,
            21.0247108577076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82451381379204,
              21.024458997613507
            ],
            [
              105.82452912501849,
              21.024498900138493
            ],
            [
              105.8245351839184,
              21.024514688949935
            ],
            [
              105.82454402925555,
              21.02453773973216
            ],
            [
              105.82455314785028,
              21.024603653555157
            ],
            [
              105.82456351133789,
              21.0247108577076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N3_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2780",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024458997613507,
          "to_latitude": 21.0247108577076,
          "to_longitude": 105.82456351133789,
          "from_longitude": 105.82451381379204
        }
      },
      {
        "id": 2774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82453771526616,
            21.02436056836871,
            105.82481619721051,
            21.024470145859866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82453771526616,
              21.024470145859866
            ],
            [
              105.82466427402848,
              21.024434402183573
            ],
            [
              105.8247211482512,
              21.024431975815023
            ],
            [
              105.82480888124854,
              21.02441939022866
            ],
            [
              105.82481619721051,
              21.02441833952238
            ],
            [
              105.82480613149474,
              21.02436056836871
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2781",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024470145859866,
          "to_latitude": 21.02436056836871,
          "to_longitude": 105.82480613149474,
          "from_longitude": 105.82453771526616
        }
      },
      {
        "id": 2775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8247211482512,
            21.024431975815023,
            105.82485764614287,
            21.024676147518992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8247211482512,
              21.024431975815023
            ],
            [
              105.82477691252497,
              21.024599207625034
            ],
            [
              105.82481997270249,
              21.02458194680285
            ],
            [
              105.82485764614287,
              21.024676147518992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2782",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024431975815023,
          "to_latitude": 21.024676147518992,
          "to_longitude": 105.82485764614287,
          "from_longitude": 105.8247211482512
        }
      },
      {
        "id": 2776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82440632852399,
            21.0240610553588,
            105.82451475988198,
            21.024412024403418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82451475988198,
              21.024412024403418
            ],
            [
              105.82450195887544,
              21.024365540548597
            ],
            [
              105.82448515197211,
              21.024304507989893
            ],
            [
              105.82447397587353,
              21.024268359991993
            ],
            [
              105.82445380092784,
              21.02420310582266
            ],
            [
              105.82443891583748,
              21.024175609733323
            ],
            [
              105.82440632852399,
              21.0240610553588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F9_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2783",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024412024403418,
          "to_latitude": 21.0240610553588,
          "to_longitude": 105.82440632852399,
          "from_longitude": 105.82451475988198
        }
      },
      {
        "id": 2777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82441101351863,
            21.02264611837489,
            105.82488521826619,
            21.02282750939256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82441101351863,
              21.022728262887867
            ],
            [
              105.8244150032876,
              21.022727289742683
            ],
            [
              105.824421048931,
              21.022725812567828
            ],
            [
              105.82442582454384,
              21.022724646839112
            ],
            [
              105.82443268455253,
              21.022722390748896
            ],
            [
              105.82468167574355,
              21.02264611837489
            ],
            [
              105.82469508266517,
              21.02270746457582
            ],
            [
              105.82473330554855,
              21.02282750939256
            ],
            [
              105.82483263167016,
              21.022805171476413
            ],
            [
              105.82482498785554,
              21.02278368287561
            ],
            [
              105.82488521826619,
              21.02276560174705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41_Ngách 318/40, La Thành",
          "maTaiSan": "04.O13.DODV.2784",
          "diaChiLapD": "Ngách 318/40, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022728262887867,
          "to_latitude": 21.02276560174705,
          "to_longitude": 105.82488521826619,
          "from_longitude": 105.82441101351863
        }
      },
      {
        "id": 2778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82303903414928,
            21.022319355394853,
            105.82354806955199,
            21.023056942257785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8230747824858,
              21.023056942257785
            ],
            [
              105.82303903414928,
              21.02297580867337
            ],
            [
              105.82354806955199,
              21.022771527881805
            ],
            [
              105.82351749079785,
              21.0226960100139
            ],
            [
              105.82351528728363,
              21.022672786104096
            ],
            [
              105.82350498033063,
              21.022564143628518
            ],
            [
              105.82342547533814,
              21.022359372561432
            ],
            [
              105.82341793775483,
              21.022342998343316
            ],
            [
              105.8234150441658,
              21.022336701513172
            ],
            [
              105.82341122312963,
              21.022331788398695
            ],
            [
              105.82340993794698,
              21.022319355394853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B9.1_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DODV.2785",
          "diaChiLapD": "Ngõ 360, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023056942257785,
          "to_latitude": 21.022319355394853,
          "to_longitude": 105.82340993794698,
          "from_longitude": 105.8230747824858
        }
      },
      {
        "id": 2779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82304944649499,
            21.022322478498896,
            105.82350767405333,
            21.02286694486885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82304944649499,
              21.02286694486885
            ],
            [
              105.8233120822186,
              21.02276696651251
            ],
            [
              105.82346453395216,
              21.02270658757627
            ],
            [
              105.82349042989416,
              21.02269280361794
            ],
            [
              105.82350767405333,
              21.022683629158813
            ],
            [
              105.82350651723806,
              21.02267232637361
            ],
            [
              105.82349566184739,
              21.022566290552536
            ],
            [
              105.82341146063627,
              21.022358921958098
            ],
            [
              105.82341237742915,
              21.02234561300385
            ],
            [
              105.82341304544809,
              21.022336051834504
            ],
            [
              105.82340308654886,
              21.022322478498896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C1_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DODV.2786",
          "diaChiLapD": "Ngõ 360, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02286694486885,
          "to_latitude": 21.022322478498896,
          "to_longitude": 105.82340308654886,
          "from_longitude": 105.82304944649499
        }
      },
      {
        "id": 2780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8202081580048,
            21.02335087568574,
            105.82021414973671,
            21.023374524865858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82021414973671,
              21.023374524865858
            ],
            [
              105.82021404257529,
              21.023374103575996
            ],
            [
              105.82021031429214,
              21.02335940258127
            ],
            [
              105.82021020016968,
              21.02335893977657
            ],
            [
              105.8202081580048,
              21.02335087568574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2787",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023374524865858,
          "to_latitude": 21.02335087568574,
          "to_longitude": 105.8202081580048,
          "from_longitude": 105.82021414973671
        }
      },
      {
        "id": 2781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82080666858118,
            21.02322306399419,
            105.82081725110619,
            21.023264479131058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82080666858118,
              21.02322306399419
            ],
            [
              105.82080701272434,
              21.02322442439837
            ],
            [
              105.8208094958344,
              21.02323412932161
            ],
            [
              105.8208147394405,
              21.02325465421673
            ],
            [
              105.82081725110619,
              21.023264479131058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2788",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02322306399419,
          "to_latitude": 21.023264479131058,
          "to_longitude": 105.82081725110619,
          "from_longitude": 105.82080666858118
        }
      },
      {
        "id": 2782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82148174030871,
            21.023051111924143,
            105.8214906025683,
            21.0230748953193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8214906025683,
              21.0230748953193
            ],
            [
              105.82148790298368,
              21.023067650937083
            ],
            [
              105.82148780925475,
              21.02306739938972
            ],
            [
              105.8214835211827,
              21.02305589583998
            ],
            [
              105.82148174030871,
              21.023051111924143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C8_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2789",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0230748953193,
          "to_latitude": 21.023051111924143,
          "to_longitude": 105.82148174030871,
          "from_longitude": 105.8214906025683
        }
      },
      {
        "id": 2783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82274673775389,
            21.022599783757112,
            105.82276562946839,
            21.022645766825818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82276562946839,
              21.022645766825818
            ],
            [
              105.82275557929812,
              21.022621338924658
            ],
            [
              105.82275545839967,
              21.022621045057857
            ],
            [
              105.82275073834123,
              21.022609546341194
            ],
            [
              105.82274887795194,
              21.022605003985333
            ],
            [
              105.82274673775389,
              21.022599783757112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C3_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2790",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022645766825818,
          "to_latitude": 21.022599783757112,
          "to_longitude": 105.82274673775389,
          "from_longitude": 105.82276562946839
        }
      },
      {
        "id": 2784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82313257417307,
            21.022448075355683,
            105.82315328201582,
            21.022485963009274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82313257417307,
              21.022448075355683
            ],
            [
              105.82313675876797,
              21.02245578039453
            ],
            [
              105.82313788505047,
              21.022457846943844
            ],
            [
              105.82314639990888,
              21.022473506509698
            ],
            [
              105.82314642031326,
              21.02247354344322
            ],
            [
              105.82315328201582,
              21.022485963009274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2791",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022448075355683,
          "to_latitude": 21.022485963009274,
          "to_longitude": 105.82315328201582,
          "from_longitude": 105.82313257417307
        }
      },
      {
        "id": 2785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82375976998347,
            21.022176776289335,
            105.82377697472297,
            21.02221191966985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82375976998347,
              21.022176776289335
            ],
            [
              105.82376188586541,
              21.022181242428505
            ],
            [
              105.8237620503462,
              21.0221815893738
            ],
            [
              105.8237622157691,
              21.02218193270163
            ],
            [
              105.82376896910617,
              21.022196158315314
            ],
            [
              105.82377697472297,
              21.02221191966985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B7.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2792",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022176776289335,
          "to_latitude": 21.02221191966985,
          "to_longitude": 105.82377697472297,
          "from_longitude": 105.82375976998347
        }
      },
      {
        "id": 2786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8244695526763,
            21.021849398023974,
            105.82448667046023,
            21.021880251430016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82448667046023,
              21.021880251430016
            ],
            [
              105.82447839140823,
              21.02186533233842
            ],
            [
              105.8244698820858,
              21.021849997975522
            ],
            [
              105.82446967509907,
              21.021849618720836
            ],
            [
              105.8244695526763,
              21.021849398023974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B6.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2793",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021880251430016,
          "to_latitude": 21.021849398023974,
          "to_longitude": 105.8244695526763,
          "from_longitude": 105.82448667046023
        }
      },
      {
        "id": 2787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82420217437655,
            21.022189047641856,
            105.82430181069591,
            21.022428805655274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82430181069591,
              21.022428805655274
            ],
            [
              105.8242625913567,
              21.022334430709314
            ],
            [
              105.82425250918064,
              21.02231017015388
            ],
            [
              105.82420217437655,
              21.022189047641856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2794",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022428805655274,
          "to_latitude": 21.022189047641856,
          "to_longitude": 105.82420217437655,
          "from_longitude": 105.82430181069591
        }
      },
      {
        "id": 2788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569898404066,
            21.022118960360586,
            105.82570428017188,
            21.02213773077107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82569898404066,
              21.022118960360586
            ],
            [
              105.82569964730791,
              21.022121315462282
            ],
            [
              105.82570189897005,
              21.022129290850582
            ],
            [
              105.82570428017188,
              21.02213773077107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4'_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2795",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022118960360586,
          "to_latitude": 21.02213773077107,
          "to_longitude": 105.82570428017188,
          "from_longitude": 105.82569898404066
        }
      },
      {
        "id": 2789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82424860182223,
            21.022334430709314,
            105.8242625913567,
            21.02233890805218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8242625913567,
              21.022334430709314
            ],
            [
              105.8242599323084,
              21.02233528178892
            ],
            [
              105.82425226725775,
              21.02233773506347
            ],
            [
              105.82424860182223,
              21.02233890805218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2796",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022334430709314,
          "to_latitude": 21.02233890805218,
          "to_longitude": 105.82424860182223,
          "from_longitude": 105.8242625913567
        }
      },
      {
        "id": 2790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82433923211553,
            21.022582498830005,
            105.82436417925378,
            21.022592641933944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82433923211553,
              21.022592641933944
            ],
            [
              105.82435142493325,
              21.022587684646346
            ],
            [
              105.82436331156103,
              21.022582851680642
            ],
            [
              105.82436417925378,
              21.022582498830005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2797",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022592641933944,
          "to_latitude": 21.022582498830005,
          "to_longitude": 105.82436417925378,
          "from_longitude": 105.82433923211553
        }
      },
      {
        "id": 2791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82445315934083,
            21.023008217724467,
            105.82448050074858,
            21.023009995035892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82445315934083,
              21.023009995035892
            ],
            [
              105.82447312091765,
              21.023008837885616
            ],
            [
              105.82447970267425,
              21.02300828572221
            ],
            [
              105.82448050074858,
              21.023008217724467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K3_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2798",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023009995035892,
          "to_latitude": 21.023008217724467,
          "to_longitude": 105.82448050074858,
          "from_longitude": 105.82445315934083
        }
      },
      {
        "id": 2792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82449180577318,
            21.023082900873963,
            105.82450100731336,
            21.023084624576498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82449180577318,
              21.023084624576498
            ],
            [
              105.82449455038127,
              21.02308411088458
            ],
            [
              105.82449839244086,
              21.02308339045319
            ],
            [
              105.82450100731336,
              21.023082900873963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2799",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023084624576498,
          "to_latitude": 21.023082900873963,
          "to_longitude": 105.82450100731336,
          "from_longitude": 105.82449180577318
        }
      },
      {
        "id": 2793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82470538280894,
            21.0236869858749,
            105.82471456736734,
            21.02369180305461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82470538280894,
              21.02369180305461
            ],
            [
              105.82470866430974,
              21.023690081852834
            ],
            [
              105.8247124628337,
              21.02368808988597
            ],
            [
              105.82471456736734,
              21.0236869858749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L9_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2800",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02369180305461,
          "to_latitude": 21.0236869858749,
          "to_longitude": 105.82471456736734,
          "from_longitude": 105.82470538280894
        }
      },
      {
        "id": 2794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82458342113125,
            21.023327346800958,
            105.82472022041381,
            21.023806264562168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82458342113125,
              21.023327346800958
            ],
            [
              105.82470734233816,
              21.02366521162826
            ],
            [
              105.82471456736734,
              21.0236869858749
            ],
            [
              105.82472022041381,
              21.02370402129426
            ],
            [
              105.82470327375339,
              21.023773059312038
            ],
            [
              105.82469359310777,
              21.023806264562168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L9_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2801",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023327346800958,
          "to_latitude": 21.023806264562168,
          "to_longitude": 105.82469359310777,
          "from_longitude": 105.82458342113125
        }
      },
      {
        "id": 2795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82469488186815,
            21.023699852792664,
            105.82470664689038,
            21.02370442145243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82469488186815,
              21.02370442145243
            ],
            [
              105.82469796231426,
              21.023703225096536
            ],
            [
              105.82470236459847,
              21.023701515621468
            ],
            [
              105.82470664689038,
              21.023699852792664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L8_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2802",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02370442145243,
          "to_latitude": 21.023699852792664,
          "to_longitude": 105.82470664689038,
          "from_longitude": 105.82469488186815
        }
      },
      {
        "id": 2796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82417039124736,
            21.024042342356484,
            105.82417336213854,
            21.02405148937575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82417039124736,
              21.024042342356484
            ],
            [
              105.82417311453072,
              21.024050721027532
            ],
            [
              105.82417336213854,
              21.02405148937575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F6_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2803",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024042342356484,
          "to_latitude": 21.02405148937575,
          "to_longitude": 105.82417336213854,
          "from_longitude": 105.82417039124736
        }
      },
      {
        "id": 2797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82449236329691,
            21.024365540548597,
            105.82450195887544,
            21.02436734745371
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82449236329691,
              21.02436734745371
            ],
            [
              105.8244938144096,
              21.02436707487057
            ],
            [
              105.82449721730428,
              21.024366433341594
            ],
            [
              105.82450195887544,
              21.024365540548597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F9_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2804",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02436734745371,
          "to_latitude": 21.024365540548597,
          "to_longitude": 105.82450195887544,
          "from_longitude": 105.82449236329691
        }
      },
      {
        "id": 2798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82396444665058,
            21.024173572667884,
            105.82397545376372,
            21.024175325865595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82396444665058,
              21.024175325865595
            ],
            [
              105.82397189942489,
              21.024174139049144
            ],
            [
              105.82397484151147,
              21.024173670472823
            ],
            [
              105.82397545376372,
              21.024173572667884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F2_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2805",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024175325865595,
          "to_latitude": 21.024173572667884,
          "to_longitude": 105.82397545376372,
          "from_longitude": 105.82396444665058
        }
      },
      {
        "id": 2799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82400659678636,
            21.02430688672961,
            105.82400905489102,
            21.024307373735866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82400905489102,
              21.02430688672961
            ],
            [
              105.82400729251198,
              21.024307235784445
            ],
            [
              105.82400659678636,
              21.024307373735866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_f3_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2806",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02430688672961,
          "to_latitude": 21.024307373735866,
          "to_longitude": 105.82400659678636,
          "from_longitude": 105.82400905489102
        }
      },
      {
        "id": 2800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82410874344572,
            21.02486365308665,
            105.82411677262792,
            21.02486481470343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82410874344572,
              21.02486481470343
            ],
            [
              105.82411009109305,
              21.024864619398947
            ],
            [
              105.8241118645713,
              21.02486436332134
            ],
            [
              105.82411363804465,
              21.024864106340488
            ],
            [
              105.82411677262792,
              21.02486365308665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F5_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2807",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02486481470343,
          "to_latitude": 21.02486365308665,
          "to_longitude": 105.82411677262792,
          "from_longitude": 105.82410874344572
        }
      },
      {
        "id": 2801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82452912501849,
            21.024495492021508,
            105.82453886379407,
            21.024498900138493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82452912501849,
              21.024498900138493
            ],
            [
              105.82453417347345,
              21.024497133340972
            ],
            [
              105.82453679371652,
              21.02449621650893
            ],
            [
              105.82453886379407,
              21.024495492021508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N3_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2808",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024498900138493,
          "to_latitude": 21.024495492021508,
          "to_longitude": 105.82453886379407,
          "from_longitude": 105.82452912501849
        }
      },
      {
        "id": 2802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82455083046719,
            21.02456683933397,
            105.82455754853036,
            21.024567807646733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82455754853036,
              21.02456683933397
            ],
            [
              105.82455671225712,
              21.024566959904565
            ],
            [
              105.82455281830147,
              21.024567521622156
            ],
            [
              105.82455083046719,
              21.024567807646733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2809",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02456683933397,
          "to_latitude": 21.024567807646733,
          "to_longitude": 105.82455083046719,
          "from_longitude": 105.82455754853036
        }
      },
      {
        "id": 2803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82139072782337,
            21.023483116207142,
            105.82140762040773,
            21.023496101277697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82139072782337,
              21.023496101277697
            ],
            [
              105.82140150577861,
              21.023487816320003
            ],
            [
              105.82140628310243,
              21.023484144214414
            ],
            [
              105.82140762040773,
              21.023483116207142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2810",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023496101277697,
          "to_latitude": 21.023483116207142,
          "to_longitude": 105.82140762040773,
          "from_longitude": 105.82139072782337
        }
      },
      {
        "id": 2804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82138103885572,
            21.02344033345988,
            105.82139146221766,
            21.023446265607085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82139146221766,
              21.02344033345988
            ],
            [
              105.82138780555145,
              21.02344241408795
            ],
            [
              105.82138103885572,
              21.023446265607085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2811",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02344033345988,
          "to_latitude": 21.023446265607085,
          "to_longitude": 105.82138103885572,
          "from_longitude": 105.82139146221766
        }
      },
      {
        "id": 2805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82161339637605,
            21.023650650066966,
            105.82192776103771,
            21.023815376515913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82161339637605,
              21.023815376515913
            ],
            [
              105.82161666639122,
              21.023811903169932
            ],
            [
              105.82164432621735,
              21.023782530315753
            ],
            [
              105.82192776103771,
              21.023650650066966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2812",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023815376515913,
          "to_latitude": 21.023650650066966,
          "to_longitude": 105.82192776103771,
          "from_longitude": 105.82161339637605
        }
      },
      {
        "id": 2806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82194179979913,
            21.024117738058333,
            105.8219535355077,
            21.024133268121144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82194736865252,
              21.024133268121144
            ],
            [
              105.82194668004425,
              21.024131721513555
            ],
            [
              105.82194179979913,
              21.024122587292258
            ],
            [
              105.8219535355077,
              21.024117738058333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2813",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024133268121144,
          "to_latitude": 21.024117738058333,
          "to_longitude": 105.8219535355077,
          "from_longitude": 105.82194736865252
        }
      },
      {
        "id": 2807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82177240479311,
            21.023919785335515,
            105.82201985861887,
            21.0241531031738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82177240479311,
              21.023919785335515
            ],
            [
              105.82190342759262,
              21.024150450285813
            ],
            [
              105.82190508618278,
              21.0241531031738
            ],
            [
              105.82194736865252,
              21.024133268121144
            ],
            [
              105.82195888295962,
              21.024127866279404
            ],
            [
              105.82196685374032,
              21.02412412724036
            ],
            [
              105.8220089005629,
              21.02410535550527
            ],
            [
              105.82201985861887,
              21.024089913379843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2814",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023919785335515,
          "to_latitude": 21.024089913379843,
          "to_longitude": 105.82201985861887,
          "from_longitude": 105.82177240479311
        }
      },
      {
        "id": 2808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82153505575971,
            21.023155486596977,
            105.82203832924658,
            21.023335751762176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82153505575971,
              21.023335751762176
            ],
            [
              105.82172766600476,
              21.023266762301535
            ],
            [
              105.82177355701467,
              21.023250324959353
            ],
            [
              105.82203832924658,
              21.023155486596977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E23_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2815",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023335751762176,
          "to_latitude": 21.023155486596977,
          "to_longitude": 105.82203832924658,
          "from_longitude": 105.82153505575971
        }
      },
      {
        "id": 2809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82201802162828,
            21.023174249008566,
            105.82214387223823,
            21.023496979323834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82201802162828,
              21.023174249008566
            ],
            [
              105.82202148635956,
              21.023183134457856
            ],
            [
              105.822079979939,
              21.023333134983968
            ],
            [
              105.82214387223823,
              21.023496979323834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E21_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2816",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023174249008566,
          "to_latitude": 21.023496979323834,
          "to_longitude": 105.82214387223823,
          "from_longitude": 105.82201802162828
        }
      },
      {
        "id": 2810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82206516502329,
            21.023502665889687,
            105.82228277144056,
            21.023883128755735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82213624117426,
              21.023502665889687
            ],
            [
              105.82206516502329,
              21.02353766080789
            ],
            [
              105.82208263724222,
              21.02359074926344
            ],
            [
              105.82211728414322,
              21.02368756928214
            ],
            [
              105.82212916494947,
              21.023720767725283
            ],
            [
              105.82215167783386,
              21.023764869998267
            ],
            [
              105.82224090713271,
              21.02379132405765
            ],
            [
              105.82228277144056,
              21.023883128755735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E20_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2817",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023502665889687,
          "to_latitude": 21.023883128755735,
          "to_longitude": 105.82228277144056,
          "from_longitude": 105.82213624117426
        }
      },
      {
        "id": 2811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82228483931814,
            21.02389164972578,
            105.82240360608556,
            21.02418877778173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82228483931814,
              21.02389164972578
            ],
            [
              105.82229159804173,
              21.023910024790485
            ],
            [
              105.82231590833587,
              21.023976111896783
            ],
            [
              105.82240360608556,
              21.02418877778173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E19_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2818",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02389164972578,
          "to_latitude": 21.02418877778173,
          "to_longitude": 105.82240360608556,
          "from_longitude": 105.82228483931814
        }
      },
      {
        "id": 2812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82241376023006,
            21.024198478099027,
            105.82256791737436,
            21.024553811255114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82241376023006,
              21.024198478099027
            ],
            [
              105.82245612373109,
              21.024312848328634
            ],
            [
              105.82256791737436,
              21.024553811255114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E18_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2819",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024198478099027,
          "to_latitude": 21.024553811255114,
          "to_longitude": 105.82256791737436,
          "from_longitude": 105.82241376023006
        }
      },
      {
        "id": 2813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82155499432062,
            21.023265347894394,
            105.82183543026238,
            21.02344410215496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82183543026238,
              21.02344410215496
            ],
            [
              105.82178506090325,
              21.023300777771706
            ],
            [
              105.82177345753739,
              21.023265347894394
            ],
            [
              105.8217371312589,
              21.023274180829873
            ],
            [
              105.82169567506877,
              21.023289050413876
            ],
            [
              105.82155499432062,
              21.02333950951654
            ],
            [
              105.82159780117155,
              21.02343401049615
            ],
            [
              105.82170109806823,
              21.023395473008215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E24_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2820",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02344410215496,
          "to_latitude": 21.023395473008215,
          "to_longitude": 105.82170109806823,
          "from_longitude": 105.82183543026238
        }
      },
      {
        "id": 2814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82177218165052,
            21.023260731348582,
            105.82177345753739,
            21.023265347894394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82177345753739,
              21.023265347894394
            ],
            [
              105.8217723681559,
              21.02326141147983
            ],
            [
              105.82177218165052,
              21.023260731348582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E24_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2821",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023265347894394,
          "to_latitude": 21.023260731348582,
          "to_longitude": 105.82177218165052,
          "from_longitude": 105.82177345753739
        }
      },
      {
        "id": 2815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82177355701467,
            21.023250324959353,
            105.82177728259565,
            21.023260117798884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82177728259565,
              21.023260117798884
            ],
            [
              105.82177714986781,
              21.02325977160143
            ],
            [
              105.82177600006275,
              21.02325674593061
            ],
            [
              105.82177355701467,
              21.023250324959353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E23_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2822",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023260117798884,
          "to_latitude": 21.023250324959353,
          "to_longitude": 105.82177355701467,
          "from_longitude": 105.82177728259565
        }
      },
      {
        "id": 2816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82202148635956,
            21.023175372406882,
            105.8220416128305,
            21.023183134457856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82202148635956,
              21.023183134457856
            ],
            [
              105.8220279382364,
              21.023180646500215
            ],
            [
              105.82203344071353,
              21.023178524421386
            ],
            [
              105.8220389422285,
              21.023176402347026
            ],
            [
              105.8220416128305,
              21.023175372406882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E21_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2823",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023183134457856,
          "to_latitude": 21.023175372406882,
          "to_longitude": 105.8220416128305,
          "from_longitude": 105.82202148635956
        }
      },
      {
        "id": 2817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82229159804173,
            21.023904911703468,
            105.82230297826167,
            21.023910024790485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82229159804173,
              21.023910024790485
            ],
            [
              105.82229558049355,
              21.02390823521314
            ],
            [
              105.82230211747815,
              21.02390529883307
            ],
            [
              105.82230297826167,
              21.023904911703468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E19_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2824",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023910024790485,
          "to_latitude": 21.023904911703468,
          "to_longitude": 105.82230297826167,
          "from_longitude": 105.82229159804173
        }
      },
      {
        "id": 2818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82211728414322,
            21.023680904519217,
            105.82213407185127,
            21.02368756928214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82211728414322,
              21.02368756928214
            ],
            [
              105.8221257869452,
              21.02368419347102
            ],
            [
              105.82213076595059,
              21.023682216628764
            ],
            [
              105.82213407185127,
              21.023680904519217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E20_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.2825",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02368756928214,
          "to_latitude": 21.023680904519217,
          "to_longitude": 105.82213407185127,
          "from_longitude": 105.82211728414322
        }
      },
      {
        "id": 2819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82481862085982,
            21.02365770913053,
            105.8252807278486,
            21.02378260704227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82481862085982,
              21.02378260704227
            ],
            [
              105.82508802958652,
              21.02371169772514
            ],
            [
              105.8252807278486,
              21.02365770913053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T5_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2826",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02378260704227,
          "to_latitude": 21.02365770913053,
          "to_longitude": 105.8252807278486,
          "from_longitude": 105.82481862085982
        }
      },
      {
        "id": 2820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82518095303541,
            21.023396026667438,
            105.82531588738334,
            21.023802718440834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82531588738334,
              21.023802718440834
            ],
            [
              105.82530158602763,
              21.02374375032291
            ],
            [
              105.8252807278486,
              21.02365770913053
            ],
            [
              105.82522060609725,
              21.023450542635725
            ],
            [
              105.82518210363362,
              21.02339761076167
            ],
            [
              105.82518095303541,
              21.023396026667438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T5_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2827",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023802718440834,
          "to_latitude": 21.023396026667438,
          "to_longitude": 105.82518095303541,
          "from_longitude": 105.82531588738334
        }
      },
      {
        "id": 2821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82495112019387,
            21.023506628788983,
            105.82501172324915,
            21.023725277563546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82495112019387,
              21.023506628788983
            ],
            [
              105.82501172324915,
              21.023725277563546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T7_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2828",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023506628788983,
          "to_latitude": 21.023725277563546,
          "to_longitude": 105.82501172324915,
          "from_longitude": 105.82495112019387
        }
      },
      {
        "id": 2822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82481991186386,
            21.02368640562669,
            105.82515933066863,
            21.023775789558478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82481991186386,
              21.023775789558478
            ],
            [
              105.82501172324915,
              21.023725277563546
            ],
            [
              105.82515933066863,
              21.02368640562669
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T7_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2829",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023775789558478,
          "to_latitude": 21.02368640562669,
          "to_longitude": 105.82515933066863,
          "from_longitude": 105.82481991186386
        }
      },
      {
        "id": 2823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8247953148971,
            21.023211969980956,
            105.82499553773576,
            21.023267466086658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8247953148971,
              21.023267466086658
            ],
            [
              105.82499553773576,
              21.023211969980956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2830",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023267466086658,
          "to_latitude": 21.023211969980956,
          "to_longitude": 105.82499553773576,
          "from_longitude": 105.8247953148971
        }
      },
      {
        "id": 2824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82480604662763,
            21.023357371416107,
            105.82503441134493,
            21.023413447533997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82503441134493,
              21.023357371416107
            ],
            [
              105.82480604662763,
              21.023413447533997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2831",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023357371416107,
          "to_latitude": 21.023413447533997,
          "to_longitude": 105.82480604662763,
          "from_longitude": 105.82503441134493
        }
      },
      {
        "id": 2825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82508776647438,
            21.023078681686464,
            105.82569454579537,
            21.023450267641344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82508776647438,
              21.023450267641344
            ],
            [
              105.82521087971608,
              21.02336704690012
            ],
            [
              105.82524370459971,
              21.023343438998257
            ],
            [
              105.82547142252061,
              21.023282111377103
            ],
            [
              105.82556201037083,
              21.02326471001939
            ],
            [
              105.82560160236496,
              21.02325710416192
            ],
            [
              105.82563112358861,
              21.02325601247004
            ],
            [
              105.82565277142517,
              21.023249941578158
            ],
            [
              105.82569454579537,
              21.023225849371936
            ],
            [
              105.82564835557933,
              21.023078681686464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4'_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2832",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 97.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023450267641344,
          "to_latitude": 21.023078681686464,
          "to_longitude": 105.82564835557933,
          "from_longitude": 105.82508776647438
        }
      },
      {
        "id": 2826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82537624491677,
            21.022369450339287,
            105.8256295836245,
            21.023078763513556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82537624491677,
              21.022369450339287
            ],
            [
              105.82546900764116,
              21.02269949485938
            ],
            [
              105.82548242756015,
              21.022747241963984
            ],
            [
              105.82551643415256,
              21.022868235565287
            ],
            [
              105.82560331413474,
              21.022941658853647
            ],
            [
              105.8256295836245,
              21.023033303546374
            ],
            [
              105.82547574879406,
              21.023078763513556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2833",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022369450339287,
          "to_latitude": 21.023078763513556,
          "to_longitude": 105.82547574879406,
          "from_longitude": 105.82537624491677
        }
      },
      {
        "id": 2827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82664920167576,
            21.020843209589483,
            105.82685666387091,
            21.02117739084551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82664920167576,
              21.020843209589483
            ],
            [
              105.82665098064525,
              21.02084622765209
            ],
            [
              105.82673095586786,
              21.020981764441746
            ],
            [
              105.82674777115673,
              21.020991013932434
            ],
            [
              105.82676463416904,
              21.02099967157683
            ],
            [
              105.82678770066876,
              21.021050675689775
            ],
            [
              105.82680195679819,
              21.021082198506175
            ],
            [
              105.82685666387091,
              21.02117739084551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2.1_Ngõ 212, La Thành",
          "maTaiSan": "04.O13.DODV.2834",
          "diaChiLapD": "Ngõ 212, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020843209589483,
          "to_latitude": 21.02117739084551,
          "to_longitude": 105.82685666387091,
          "from_longitude": 105.82664920167576
        }
      },
      {
        "id": 2828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82674777115673,
            21.020952373973504,
            105.82684011812312,
            21.020991013932434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82674777115673,
              21.020991013932434
            ],
            [
              105.82684011812312,
              21.020952373973504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2.1_Ngõ 212, La Thành",
          "maTaiSan": "04.O13.DODV.2835",
          "diaChiLapD": "Ngõ 212, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020991013932434,
          "to_latitude": 21.020952373973504,
          "to_longitude": 105.82684011812312,
          "from_longitude": 105.82674777115673
        }
      },
      {
        "id": 2829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82664250436149,
            21.020846512717025,
            105.82678606608016,
            21.021066865820558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82664250436149,
              21.020846512717025
            ],
            [
              105.82664374126644,
              21.020848746704765
            ],
            [
              105.82671704933865,
              21.020981020033428
            ],
            [
              105.82675669020558,
              21.02100438347744
            ],
            [
              105.82678606608016,
              21.021066865820558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4_Ngõ 212, La Thành",
          "maTaiSan": "04.O13.DODV.2836",
          "diaChiLapD": "Ngõ 212, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020846512717025,
          "to_latitude": 21.021066865820558,
          "to_longitude": 105.82678606608016,
          "from_longitude": 105.82664250436149
        }
      },
      {
        "id": 2830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82670656958624,
            21.021107170261978,
            105.82673796867553,
            21.021192019283937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82670656958624,
              21.021107170261978
            ],
            [
              105.82673281033064,
              21.02115655572892
            ],
            [
              105.82673796867553,
              21.021192019283937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4_Ngõ 212, La Thành",
          "maTaiSan": "04.O13.DODV.2837",
          "diaChiLapD": "Ngõ 212, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021107170261978,
          "to_latitude": 21.021192019283937,
          "to_longitude": 105.82673796867553,
          "from_longitude": 105.82670656958624
        }
      },
      {
        "id": 2831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82655546649835,
            21.021074851071365,
            105.82660125274026,
            21.02116056685207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82660125274026,
              21.02116056685207
            ],
            [
              105.82655546649835,
              21.021074851071365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4_Ngõ 212, La Thành",
          "maTaiSan": "04.O13.DODV.2838",
          "diaChiLapD": "Ngõ 212, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02116056685207,
          "to_latitude": 21.021074851071365,
          "to_longitude": 105.82655546649835,
          "from_longitude": 105.82660125274026
        }
      },
      {
        "id": 2832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694612866622,
            21.02069826093698,
            105.82711512862531,
            21.020967213920237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711512862531,
              21.02095725290035
            ],
            [
              105.82709427317803,
              21.020967213920237
            ],
            [
              105.82696084504792,
              21.020724978151293
            ],
            [
              105.8269536554598,
              21.02071192807932
            ],
            [
              105.82694612866622,
              21.02069826093698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2_Ngõ 200, La Thành",
          "maTaiSan": "04.O13.DODV.2839",
          "diaChiLapD": "Ngõ 200, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02095725290035,
          "to_latitude": 21.02069826093698,
          "to_longitude": 105.82694612866622,
          "from_longitude": 105.82711512862531
        }
      },
      {
        "id": 2833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82670067664156,
            21.020724978151293,
            105.82696084504792,
            21.020856447281453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82696084504792,
              21.020724978151293
            ],
            [
              105.82695931600125,
              21.020725764165395
            ],
            [
              105.82687592343986,
              21.020767931105716
            ],
            [
              105.82670067664156,
              21.020856447281453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2840",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020724978151293,
          "to_latitude": 21.020856447281453,
          "to_longitude": 105.82670067664156,
          "from_longitude": 105.82696084504792
        }
      },
      {
        "id": 2834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82700802113752,
            21.020569383995568,
            105.82728349547283,
            21.020713132405426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82700802113752,
              21.020713132405426
            ],
            [
              105.82703483295236,
              21.02070018346962
            ],
            [
              105.8270712442346,
              21.020677810568124
            ],
            [
              105.82709122111598,
              21.02066553705086
            ],
            [
              105.82714215159885,
              21.020633434442672
            ],
            [
              105.82728349547283,
              21.020569383995568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2841",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020713132405426,
          "to_latitude": 21.020569383995568,
          "to_longitude": 105.82728349547283,
          "from_longitude": 105.82700802113752
        }
      },
      {
        "id": 2835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270712442346,
            21.020677810568124,
            105.82716146552616,
            21.02080599294571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270712442346,
              21.020677810568124
            ],
            [
              105.82716146552616,
              21.02080599294571
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.1_Ngõ 190, La Thành",
          "maTaiSan": "04.O13.DODV.2842",
          "diaChiLapD": "Ngõ 190, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020677810568124,
          "to_latitude": 21.02080599294571,
          "to_longitude": 105.82716146552616,
          "from_longitude": 105.8270712442346
        }
      },
      {
        "id": 2836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82621127264962,
            21.021364537921027,
            105.82702021570843,
            21.02162517946006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82621127264962,
              21.02162517946006
            ],
            [
              105.8262215875673,
              21.021623543291167
            ],
            [
              105.82626992489696,
              21.021624927062394
            ],
            [
              105.82645993148199,
              21.021582837630707
            ],
            [
              105.8265218954719,
              21.021577640154263
            ],
            [
              105.8265898411414,
              21.021571940296067
            ],
            [
              105.82690181601319,
              21.021424576498713
            ],
            [
              105.8269069309423,
              21.021421979246625
            ],
            [
              105.82702021570843,
              21.021364537921027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6_Ngõ 20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2843",
          "diaChiLapD": "Ngõ 20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02162517946006,
          "to_latitude": 21.021364537921027,
          "to_longitude": 105.82702021570843,
          "from_longitude": 105.82621127264962
        }
      },
      {
        "id": 2837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82686359868411,
            21.021342239109078,
            105.8269069309423,
            21.021421979246625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269069309423,
              21.021421979246625
            ],
            [
              105.82686359868411,
              21.021342239109078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6_Ngõ 20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2844",
          "diaChiLapD": "Ngõ 20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021421979246625,
          "to_latitude": 21.021342239109078,
          "to_longitude": 105.82686359868411,
          "from_longitude": 105.8269069309423
        }
      },
      {
        "id": 2838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8262234123121,
            21.021272195303716,
            105.82720995797209,
            21.021651279704795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8262234123121,
              21.021651279704795
            ],
            [
              105.82623235841429,
              21.021649133539448
            ],
            [
              105.82646062697935,
              21.02159435489067
            ],
            [
              105.82654877928516,
              21.02161017736896
            ],
            [
              105.82693868854888,
              21.021440280454495
            ],
            [
              105.8269546204863,
              21.021421562233254
            ],
            [
              105.8270679885063,
              21.021346778546327
            ],
            [
              105.8271410398418,
              21.02130840114843
            ],
            [
              105.82720995797209,
              21.021272195303716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2_Ngõ 20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2845",
          "diaChiLapD": "Ngõ 20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021651279704795,
          "to_latitude": 21.021272195303716,
          "to_longitude": 105.82720995797209,
          "from_longitude": 105.8262234123121
        }
      },
      {
        "id": 2839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82708329238974,
            21.021215768078143,
            105.8271410398418,
            21.02130840114843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8271410398418,
              21.02130840114843
            ],
            [
              105.82708329238974,
              21.021215768078143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2_Ngõ 20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2846",
          "diaChiLapD": "Ngõ 20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02130840114843,
          "to_latitude": 21.021215768078143,
          "to_longitude": 105.82708329238974,
          "from_longitude": 105.8271410398418
        }
      },
      {
        "id": 2840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82742107147435,
            21.022371457486802,
            105.82749685534992,
            21.022609906027526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749685534992,
              21.022609906027526
            ],
            [
              105.82742107147435,
              21.022371457486802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngách 34/14, Hào Nam",
          "maTaiSan": "04.O13.DODV.2847",
          "diaChiLapD": "Ngách 34/14, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022609906027526,
          "to_latitude": 21.022371457486802,
          "to_longitude": 105.82742107147435,
          "from_longitude": 105.82749685534992
        }
      },
      {
        "id": 2841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753001697007,
            21.022336975227006,
            105.82760897836958,
            21.022587613534387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753001697007,
              21.022336975227006
            ],
            [
              105.82758716229716,
              21.022508617946748
            ],
            [
              105.82760897836958,
              21.022587613534387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngách 34/18, Hào Nam",
          "maTaiSan": "04.O13.DODV.2848",
          "diaChiLapD": "Ngách 34/18, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022336975227006,
          "to_latitude": 21.022587613534387,
          "to_longitude": 105.82760897836958,
          "from_longitude": 105.82753001697007
        }
      },
      {
        "id": 2842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82716797661479,
            21.022462046414482,
            105.82724603699862,
            21.02268285575879
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724603699862,
              21.02268285575879
            ],
            [
              105.82716797661479,
              21.022462046414482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2_Ngõ 34, Hào Nam",
          "maTaiSan": "04.O13.DODV.2849",
          "diaChiLapD": "Ngõ 34, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02268285575879,
          "to_latitude": 21.022462046414482,
          "to_longitude": 105.82716797661479,
          "from_longitude": 105.82724603699862
        }
      },
      {
        "id": 2843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82729863700867,
            21.022413434316228,
            105.82737508117376,
            21.022645007063588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82737508117376,
              21.022645007063588
            ],
            [
              105.82729863700867,
              21.022413434316228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngõ 34, Hào Nam",
          "maTaiSan": "04.O13.DODV.2850",
          "diaChiLapD": "Ngõ 34, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022645007063588,
          "to_latitude": 21.022413434316228,
          "to_longitude": 105.82729863700867,
          "from_longitude": 105.82737508117376
        }
      },
      {
        "id": 2844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82737269963403,
            21.022380599500195,
            105.8273770664298,
            21.022388684703348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8273770664298,
              21.022388684703348
            ],
            [
              105.82737384935925,
              21.02238272009931
            ],
            [
              105.82737277837475,
              21.02238074814931
            ],
            [
              105.82737269963403,
              21.022380599500195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2851",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022388684703348,
          "to_latitude": 21.022380599500195,
          "to_longitude": 105.82737269963403,
          "from_longitude": 105.8273770664298
        }
      },
      {
        "id": 2845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8267598045614,
            21.022622852051544,
            105.82754621590894,
            21.02284372517637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8267598045614,
              21.02284372517637
            ],
            [
              105.82679584996167,
              21.022836195897977
            ],
            [
              105.82680351906777,
              21.02283395836047
            ],
            [
              105.8271066711004,
              21.022745545372327
            ],
            [
              105.82728953382663,
              21.022693105346885
            ],
            [
              105.82754621590894,
              21.022622852051544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4_Ngõ 34, Hào Nam",
          "maTaiSan": "04.O13.DODV.2852",
          "diaChiLapD": "Ngõ 34, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02284372517637,
          "to_latitude": 21.022622852051544,
          "to_longitude": 105.82754621590894,
          "from_longitude": 105.8267598045614
        }
      },
      {
        "id": 2846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590584385525,
            21.025280657861465,
            105.82603505441232,
            21.02533120631743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590584385525,
              21.025280657861465
            ],
            [
              105.82592566825225,
              21.02533120631743
            ],
            [
              105.82603505441232,
              21.025298203202567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 1278, Hào Nam",
          "maTaiSan": "04.O13.DODV.2853",
          "diaChiLapD": "Ngõ 1278, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025280657861465,
          "to_latitude": 21.025298203202567,
          "to_longitude": 105.82603505441232,
          "from_longitude": 105.82590584385525
        }
      },
      {
        "id": 2847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82565165140613,
            21.025073442225093,
            105.82573891268243,
            21.02537495587291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82573891268243,
              21.02537495587291
            ],
            [
              105.82573008500717,
              21.025350613464003
            ],
            [
              105.8257115280383,
              21.025299441023908
            ],
            [
              105.82570416978902,
              21.025268366967882
            ],
            [
              105.82565165140613,
              21.025073442225093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2854",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02537495587291,
          "to_latitude": 21.025073442225093,
          "to_longitude": 105.82565165140613,
          "from_longitude": 105.82573891268243
        }
      },
      {
        "id": 2848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82572158440375,
            21.025350613464003,
            105.82573008500717,
            21.025352992283924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82573008500717,
              21.025350613464003
            ],
            [
              105.82572736865625,
              21.02535137362003
            ],
            [
              105.82572466575303,
              21.02535213009783
            ],
            [
              105.82572196284978,
              21.025352886575575
            ],
            [
              105.82572158440375,
              21.025352992283924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2855",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025350613464003,
          "to_latitude": 21.025352992283924,
          "to_longitude": 105.82572158440375,
          "from_longitude": 105.82573008500717
        }
      },
      {
        "id": 2849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82517512478145,
            21.025412616319272,
            105.82574718552947,
            21.025731043087074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574718552947,
              21.025412616319272
            ],
            [
              105.82574660545654,
              21.025412793458248
            ],
            [
              105.82554118715551,
              21.025475456627422
            ],
            [
              105.82547985314203,
              21.025504515746672
            ],
            [
              105.82547453899929,
              21.025525822427966
            ],
            [
              105.82540313856389,
              21.02564137173487
            ],
            [
              105.82535808235389,
              21.025678266768683
            ],
            [
              105.82529712426248,
              21.025615389230566
            ],
            [
              105.82517512478145,
              21.025731043087074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2856",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025412616319272,
          "to_latitude": 21.025731043087074,
          "to_longitude": 105.82517512478145,
          "from_longitude": 105.82574718552947
        }
      },
      {
        "id": 2850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82526821333401,
            21.025408707723624,
            105.82574558150942,
            21.02550149516253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574558150942,
              21.025408707723624
            ],
            [
              105.82574439140801,
              21.025409037759932
            ],
            [
              105.82553385245699,
              21.025467347851205
            ],
            [
              105.82546314674372,
              21.02550149516253
            ],
            [
              105.82541639870831,
              21.025459639901445
            ],
            [
              105.82537590591592,
              21.025449987663585
            ],
            [
              105.8253296465974,
              21.025493229168802
            ],
            [
              105.82529306664493,
              21.025467744256215
            ],
            [
              105.82526821333401,
              21.025493258135143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G4_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2857",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025408707723624,
          "to_latitude": 21.025493258135143,
          "to_longitude": 105.82526821333401,
          "from_longitude": 105.82574558150942
        }
      },
      {
        "id": 2851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82455263921082,
            21.02519767649154,
            105.82519328808984,
            21.025394304243214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825190200122,
              21.025269603428104
            ],
            [
              105.82519328808984,
              21.0252828766936
            ],
            [
              105.82514988283297,
              21.025287281877105
            ],
            [
              105.82514607680137,
              21.025287741117648
            ],
            [
              105.82511749456216,
              21.02529119192105
            ],
            [
              105.82502376449014,
              21.02523534501063
            ],
            [
              105.8250052311013,
              21.02519767649154
            ],
            [
              105.82468887200582,
              21.025312629782718
            ],
            [
              105.82455263921082,
              21.025394304243214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT23_Ngách 127/38, Hào Nam",
          "maTaiSan": "04.O13.DODV.2858",
          "diaChiLapD": "Ngách 127/38, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025269603428104,
          "to_latitude": 21.025394304243214,
          "to_longitude": 105.82455263921082,
          "from_longitude": 105.825190200122
        }
      },
      {
        "id": 2852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82482747195557,
            21.024831948449155,
            105.82514504180963,
            21.025281680082895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82514501331514,
              21.025279264993046
            ],
            [
              105.82514504180963,
              21.02527727595291
            ],
            [
              105.82512549415384,
              21.025281680082895
            ],
            [
              105.82511506869334,
              21.025281632213346
            ],
            [
              105.82508738373328,
              21.025257066935044
            ],
            [
              105.82502882046138,
              21.025230220873443
            ],
            [
              105.82499733058205,
              21.02516068529273
            ],
            [
              105.82495494673284,
              21.025120986401706
            ],
            [
              105.82493388000849,
              21.025101248344555
            ],
            [
              105.82488468460303,
              21.0251222783091
            ],
            [
              105.82482747195557,
              21.025003788904304
            ],
            [
              105.82491996639727,
              21.024948928184582
            ],
            [
              105.82487850614984,
              21.024831948449155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G7_Ngách 127/38, Hào Nam",
          "maTaiSan": "04.O13.DODV.2859",
          "diaChiLapD": "Ngách 127/38, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025279264993046,
          "to_latitude": 21.024831948449155,
          "to_longitude": 105.82487850614984,
          "from_longitude": 105.82514501331514
        }
      },
      {
        "id": 2853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82493388000849,
            21.02501144275313,
            105.82502449866718,
            21.025101248344555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82493388000849,
              21.025101248344555
            ],
            [
              105.8250215890835,
              21.025014323704898
            ],
            [
              105.82502449866718,
              21.02501144275313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G7_Ngách 127/38, Hào Nam",
          "maTaiSan": "04.O13.DODV.2860",
          "diaChiLapD": "Ngách 127/38, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025101248344555,
          "to_latitude": 21.02501144275313,
          "to_longitude": 105.82502449866718,
          "from_longitude": 105.82493388000849
        }
      },
      {
        "id": 2854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268669418324,
            21.024674415481282,
            105.8269005136346,
            21.024817623860137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269005136346,
              21.024817623860137
            ],
            [
              105.8268669418324,
              21.024674415481282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_K1_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DODV.2861",
          "diaChiLapD": "Ngõ 101, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024817623860137,
          "to_latitude": 21.024674415481282,
          "to_longitude": 105.8268669418324,
          "from_longitude": 105.8269005136346
        }
      },
      {
        "id": 2855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82662710774902,
            21.024321022174828,
            105.82679322806807,
            21.024541973603437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82674315810314,
              21.024528776106123
            ],
            [
              105.82667300907373,
              21.024541973603437
            ],
            [
              105.82664320017435,
              21.024422016663895
            ],
            [
              105.82662866218921,
              21.024363507763407
            ],
            [
              105.82662710774902,
              21.024357252356328
            ],
            [
              105.82678594107449,
              21.024322612050167
            ],
            [
              105.82679322806807,
              21.024321022174828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DODV.2862",
          "diaChiLapD": "Ngõ 101, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024528776106123,
          "to_latitude": 21.024321022174828,
          "to_longitude": 105.82679322806807,
          "from_longitude": 105.82674315810314
        }
      },
      {
        "id": 2856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82662051611874,
            21.024363507763407,
            105.82662866218921,
            21.02436614232254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82662051611874,
              21.02436614232254
            ],
            [
              105.82662258360634,
              21.024365473822595
            ],
            [
              105.82662606846927,
              21.024364346821024
            ],
            [
              105.82662866218921,
              21.024363507763407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DODV.2863",
          "diaChiLapD": "Ngõ 101, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02436614232254,
          "to_latitude": 21.024363507763407,
          "to_longitude": 105.82662866218921,
          "from_longitude": 105.82662051611874
        }
      },
      {
        "id": 2857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82678841601306,
            21.024969527548002,
            105.82695452397579,
            21.025001665331306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82678841601306,
              21.025001665331306
            ],
            [
              105.82689292275789,
              21.024981446158534
            ],
            [
              105.82695452397579,
              21.024969527548002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15_Ngách 101/2, Hào Nam",
          "maTaiSan": "04.O13.DODV.2864",
          "diaChiLapD": "Ngách 101/2, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025001665331306,
          "to_latitude": 21.024969527548002,
          "to_longitude": 105.82695452397579,
          "from_longitude": 105.82678841601306
        }
      },
      {
        "id": 2858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259195840338,
            21.024831289851214,
            105.82622995675855,
            21.02489490499099
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259195840338,
              21.02489490499099
            ],
            [
              105.82608136612933,
              21.024861744938335
            ],
            [
              105.82609111073921,
              21.02485974754215
            ],
            [
              105.82622995675855,
              21.024831289851214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2865",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02489490499099,
          "to_latitude": 21.024831289851214,
          "to_longitude": 105.82622995675855,
          "from_longitude": 105.8259195840338
        }
      },
      {
        "id": 2859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82609022029682,
            21.024850910197113,
            105.82609111073921,
            21.02485974754215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82609111073921,
              21.02485974754215
            ],
            [
              105.82609074643153,
              21.024856133701746
            ],
            [
              105.82609046869393,
              21.024853391053046
            ],
            [
              105.82609022029682,
              21.024850910197113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2866",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02485974754215,
          "to_latitude": 21.024850910197113,
          "to_longitude": 105.82609022029682,
          "from_longitude": 105.82609111073921
        }
      },
      {
        "id": 2860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589947761781,
            21.02452433471589,
            105.82625302138655,
            21.024713489015934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82592898669084,
              21.024713489015934
            ],
            [
              105.82589947761781,
              21.02459529537835
            ],
            [
              105.82621275200026,
              21.02452433471589
            ],
            [
              105.82623133428346,
              21.024612557273503
            ],
            [
              105.82624459801151,
              21.024692455350937
            ],
            [
              105.82625302138655,
              21.024692130814962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT27_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2867",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024713489015934,
          "to_latitude": 21.024692130814962,
          "to_longitude": 105.82625302138655,
          "from_longitude": 105.82592898669084
        }
      },
      {
        "id": 2861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82602837470172,
            21.024614507500832,
            105.82624340914782,
            21.02470627505095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82602837470172,
              21.024648767140445
            ],
            [
              105.82621958751842,
              21.024614507500832
            ],
            [
              105.8262349797068,
              21.024675125835163
            ],
            [
              105.82624340914782,
              21.02470627505095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Hẻm 127/23/16, Hào Nam",
          "maTaiSan": "04.O13.DODV.2868",
          "diaChiLapD": "Hẻm 127/23/16, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024648767140445,
          "to_latitude": 21.02470627505095,
          "to_longitude": 105.82624340914782,
          "from_longitude": 105.82602837470172
        }
      },
      {
        "id": 2862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82596652151368,
            21.02470627505095,
            105.82624340914782,
            21.02476126888329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624340914782,
              21.02470627505095
            ],
            [
              105.82596652151368,
              21.02476126888329
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Hẻm 127/23/14, Hào Nam",
          "maTaiSan": "04.O13.DODV.2869",
          "diaChiLapD": "Hẻm 127/23/14, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02470627505095,
          "to_latitude": 21.02476126888329,
          "to_longitude": 105.82596652151368,
          "from_longitude": 105.82624340914782
        }
      },
      {
        "id": 2863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552229812357,
            21.024505464913787,
            105.8256225293982,
            21.024568005746815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552229812357,
              21.024568005746815
            ],
            [
              105.8256225293982,
              21.024505464913787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2870",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024568005746815,
          "to_latitude": 21.024505464913787,
          "to_longitude": 105.8256225293982,
          "from_longitude": 105.82552229812357
        }
      },
      {
        "id": 2864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545231049819,
            21.024361560166426,
            105.82552229812357,
            21.024592701217475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82545231049819,
              21.024361560166426
            ],
            [
              105.82549403026928,
              21.024485609849833
            ],
            [
              105.8255058035083,
              21.0245206150037
            ],
            [
              105.82551504763272,
              21.024548101340947
            ],
            [
              105.82552229812357,
              21.024568005746815
            ],
            [
              105.8254976550151,
              21.024592701217475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2871",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024361560166426,
          "to_latitude": 21.024592701217475,
          "to_longitude": 105.8254976550151,
          "from_longitude": 105.82545231049819
        }
      },
      {
        "id": 2865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82511455670776,
            21.02431742729312,
            105.82547775810622,
            21.02465409462854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547775810622,
              21.02463359466935
            ],
            [
              105.8254649169221,
              21.024636131833923
            ],
            [
              105.82534176700253,
              21.024649763963165
            ],
            [
              105.82530292908177,
              21.02465409462854
            ],
            [
              105.82527577831844,
              21.02464721645072
            ],
            [
              105.82525309794345,
              21.024639986899047
            ],
            [
              105.82523620368133,
              21.024615576117576
            ],
            [
              105.82511868374102,
              21.024398547611526
            ],
            [
              105.82513496525084,
              21.024383644885937
            ],
            [
              105.82511455670776,
              21.02431742729312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H4_Ngách 127/56, Hào Nam",
          "maTaiSan": "04.O13.DODV.2872",
          "diaChiLapD": "Ngách 127/56, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02463359466935,
          "to_latitude": 21.02431742729312,
          "to_longitude": 105.82511455670776,
          "from_longitude": 105.82547775810622
        }
      },
      {
        "id": 2866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496514023265,
            21.024398547611526,
            105.82511868374102,
            21.024466289211652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82511868374102,
              21.024398547611526
            ],
            [
              105.82496514023265,
              21.024466289211652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H4_Ngách 127/56, Hào Nam",
          "maTaiSan": "04.O13.DODV.2873",
          "diaChiLapD": "Ngách 127/56, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024398547611526,
          "to_latitude": 21.024466289211652,
          "to_longitude": 105.82496514023265,
          "from_longitude": 105.82511868374102
        }
      },
      {
        "id": 2867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82501691629868,
            21.02456827717925,
            105.82519482571266,
            21.02464837033587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82519482571266,
              21.02456827717925
            ],
            [
              105.82501691629868,
              21.02464837033587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29_Ngách 127/56, Hào Nam",
          "maTaiSan": "04.O13.DODV.2874",
          "diaChiLapD": "Ngách 127/56, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02456827717925,
          "to_latitude": 21.02464837033587,
          "to_longitude": 105.82501691629868,
          "from_longitude": 105.82519482571266
        }
      },
      {
        "id": 2868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82542192156349,
            21.02427274723059,
            105.8258003614653,
            21.024310797367235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82542192156349,
              21.024310797367235
            ],
            [
              105.8254225960845,
              21.024310652287173
            ],
            [
              105.82554803520536,
              21.02428355726159
            ],
            [
              105.82563289104004,
              21.02430142752284
            ],
            [
              105.82573326915123,
              21.02428427749848
            ],
            [
              105.8258003614653,
              21.02427274723059
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H52_Ngách 127/123, Hào Nam",
          "maTaiSan": "04.O13.DODV.2875",
          "diaChiLapD": "Ngách 127/123, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024310797367235,
          "to_latitude": 21.02427274723059,
          "to_longitude": 105.8258003614653,
          "from_longitude": 105.82542192156349
        }
      },
      {
        "id": 2869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573326915123,
            21.02428427749848,
            105.82576245197343,
            21.02442885686931
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82573326915123,
              21.02428427749848
            ],
            [
              105.82576245197343,
              21.02442885686931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H52_Ngách 127/123, Hào Nam",
          "maTaiSan": "04.O13.DODV.2876",
          "diaChiLapD": "Ngách 127/123, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02428427749848,
          "to_latitude": 21.02442885686931,
          "to_longitude": 105.82576245197343,
          "from_longitude": 105.82573326915123
        }
      },
      {
        "id": 2870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82477298809867,
            21.024092669950026,
            105.8250394294877,
            21.024212065193254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82477298809867,
              21.024126800959426
            ],
            [
              105.82494691332329,
              21.024092669950026
            ],
            [
              105.82496634260913,
              21.024212065193254
            ],
            [
              105.8250394294877,
              21.024195878020475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F8_Ngách 318/84, La Thành",
          "maTaiSan": "04.O13.DODV.2877",
          "diaChiLapD": "Ngách 318/84, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024126800959426,
          "to_latitude": 21.024195878020475,
          "to_longitude": 105.8250394294877,
          "from_longitude": 105.82477298809867
        }
      },
      {
        "id": 2871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82473413853734,
            21.02396810616269,
            105.82486185564693,
            21.024280774539093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82473608947024,
              21.02396810616269
            ],
            [
              105.82473858721248,
              21.02397972307743
            ],
            [
              105.82473413853734,
              21.02399101329033
            ],
            [
              105.82476409997516,
              21.02412080600066
            ],
            [
              105.82476608274403,
              21.024129393284408
            ],
            [
              105.82480048926931,
              21.024137591197427
            ],
            [
              105.824817690873,
              21.024242021787327
            ],
            [
              105.82486185564693,
              21.024280774539093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT35_Ngách 318/84, La Thành",
          "maTaiSan": "04.O13.DODV.2878",
          "diaChiLapD": "Ngách 318/84, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02396810616269,
          "to_latitude": 21.024280774539093,
          "to_longitude": 105.82486185564693,
          "from_longitude": 105.82473608947024
        }
      },
      {
        "id": 2872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82461096720498,
            21.02412080600066,
            105.82476409997516,
            21.024179076775898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82476409997516,
              21.02412080600066
            ],
            [
              105.8247095498726,
              21.024141563811487
            ],
            [
              105.82461096720498,
              21.024179076775898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngách 318/84, La Thành",
          "maTaiSan": "04.O13.DODV.2879",
          "diaChiLapD": "Ngách 318/84, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02412080600066,
          "to_latitude": 21.024179076775898,
          "to_longitude": 105.82461096720498,
          "from_longitude": 105.82476409997516
        }
      },
      {
        "id": 2873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82344583907114,
            21.022304636039703,
            105.82412286889735,
            21.022655375528828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82344583907114,
              21.022304636039703
            ],
            [
              105.82344811407054,
              21.02231758270274
            ],
            [
              105.82344993429848,
              21.022327946535228
            ],
            [
              105.82344994225203,
              21.022327993464472
            ],
            [
              105.82345424188864,
              21.02235244013144
            ],
            [
              105.8235339122651,
              21.022551761222523
            ],
            [
              105.82358822257704,
              21.022655375528828
            ],
            [
              105.82369920207313,
              21.02262722739252
            ],
            [
              105.82401649551753,
              21.02252946983879
            ],
            [
              105.8240467299659,
              21.022542825677586
            ],
            [
              105.82407874322217,
              21.022532394573613
            ],
            [
              105.82412286889735,
              21.0226418721083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B8.1_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DODV.2880",
          "diaChiLapD": "Ngõ 360, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022304636039703,
          "to_latitude": 21.0226418721083,
          "to_longitude": 105.82412286889735,
          "from_longitude": 105.82344583907114
        }
      },
      {
        "id": 2874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82356300302177,
            21.02256275717391,
            105.8239816884005,
            21.02268322205705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82356300302177,
              21.022665499065532
            ],
            [
              105.82357280541706,
              21.022664233149282
            ],
            [
              105.82365294046701,
              21.02265387890896
            ],
            [
              105.82393877898899,
              21.02256275717391
            ],
            [
              105.8239816884005,
              21.02268322205705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT56_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DODV.2881",
          "diaChiLapD": "Ngõ 360, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022665499065532,
          "to_latitude": 21.02268322205705,
          "to_longitude": 105.8239816884005,
          "from_longitude": 105.82356300302177
        }
      },
      {
        "id": 2875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82399729580538,
            21.024326726147322,
            105.82410084100503,
            21.02480751203782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82399729580538,
              21.024326726147322
            ],
            [
              105.82404690205092,
              21.024589445440615
            ],
            [
              105.82404927924144,
              21.024603115078214
            ],
            [
              105.82405084482664,
              21.024612117166253
            ],
            [
              105.82407581086754,
              21.024749912396537
            ],
            [
              105.8240897801398,
              21.02480751203782
            ],
            [
              105.82409980672769,
              21.02480605532398
            ],
            [
              105.82410084100503,
              21.024805903989673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_f4_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2882",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024326726147322,
          "to_latitude": 21.024805903989673,
          "to_longitude": 105.82410084100503,
          "from_longitude": 105.82399729580538
        }
      },
      {
        "id": 2876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82410450816216,
            21.02489936282429,
            105.82415832566105,
            21.025313132415253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82410450816216,
              21.02489936282429
            ],
            [
              105.82411873462667,
              21.025056421686124
            ],
            [
              105.82412062750409,
              21.02510781145744
            ],
            [
              105.82412093963453,
              21.025116292134488
            ],
            [
              105.82412314260687,
              21.025176141818026
            ],
            [
              105.82412403701171,
              21.02520042069808
            ],
            [
              105.82413040213729,
              21.025221342875366
            ],
            [
              105.82414396123426,
              21.025265913662622
            ],
            [
              105.82415832566105,
              21.025313132415253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT39_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2883",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02489936282429,
          "to_latitude": 21.025313132415253,
          "to_longitude": 105.82415832566105,
          "from_longitude": 105.82410450816216
        }
      },
      {
        "id": 2877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82396186263551,
            21.025265913662622,
            105.82414396123426,
            21.025295038072393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82414396123426,
              21.025265913662622
            ],
            [
              105.82396186263551,
              21.025295038072393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT39_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2884",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025265913662622,
          "to_latitude": 21.025295038072393,
          "to_longitude": 105.82396186263551,
          "from_longitude": 105.82414396123426
        }
      },
      {
        "id": 2878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82413040213729,
            21.025221342875366,
            105.82414040122124,
            21.025221877903665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82413040213729,
              21.025221342875366
            ],
            [
              105.82413762792211,
              21.025221729665724
            ],
            [
              105.8241397170439,
              21.0252218423805
            ],
            [
              105.82414040122124,
              21.025221877903665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT39_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2885",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025221342875366,
          "to_latitude": 21.025221877903665,
          "to_longitude": 105.82414040122124,
          "from_longitude": 105.82413040213729
        }
      },
      {
        "id": 2879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245702675808,
            21.024720746096055,
            105.82459386797834,
            21.024745990505846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82457811161451,
              21.024727308717114
            ],
            [
              105.82457506682057,
              21.024728169801886
            ],
            [
              105.82457075465844,
              21.02472895483784
            ],
            [
              105.8245702675808,
              21.02472365165372
            ],
            [
              105.82457728456868,
              21.024722460600465
            ],
            [
              105.82458738187714,
              21.024720746096055
            ],
            [
              105.82459386797834,
              21.024745990505846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2886",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024727308717114,
          "to_latitude": 21.024745990505846,
          "to_longitude": 105.82459386797834,
          "from_longitude": 105.82457811161451
        }
      },
      {
        "id": 2880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82468983493976,
            21.02471469899802,
            105.82472173329396,
            21.024797676270655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82468983493976,
              21.02471469899802
            ],
            [
              105.82472173329396,
              21.024797676270655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2887",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02471469899802,
          "to_latitude": 21.024797676270655,
          "to_longitude": 105.82472173329396,
          "from_longitude": 105.82468983493976
        }
      },
      {
        "id": 2881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245027419125,
            21.02473636714408,
            105.8245865964268,
            21.02517823634631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82457997864782,
              21.02473636714408
            ],
            [
              105.82457624016462,
              21.024736862173047
            ],
            [
              105.82457398201275,
              21.02473749905678
            ],
            [
              105.82457867993023,
              21.02476609768374
            ],
            [
              105.82455335751594,
              21.024824561588883
            ],
            [
              105.82458291107083,
              21.024931085598762
            ],
            [
              105.8245865964268,
              21.024944367722973
            ],
            [
              105.82451582639533,
              21.024988682560927
            ],
            [
              105.82455802354035,
              21.025140687700567
            ],
            [
              105.8245027419125,
              21.02517823634631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT38_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2888",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02473636714408,
          "to_latitude": 21.02517823634631,
          "to_longitude": 105.8245027419125,
          "from_longitude": 105.82457997864782
        }
      },
      {
        "id": 2882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82458291107083,
            21.02488216122023,
            105.82475079631352,
            21.024931085598762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82458291107083,
              21.024931085598762
            ],
            [
              105.82462535021051,
              21.02491580228491
            ],
            [
              105.82463203424692,
              21.024927708707487
            ],
            [
              105.82475079631352,
              21.02488216122023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2889",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024931085598762,
          "to_latitude": 21.02488216122023,
          "to_longitude": 105.82475079631352,
          "from_longitude": 105.82458291107083
        }
      },
      {
        "id": 2883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82451867008406,
            21.02472921532507,
            105.82457850497563,
            21.02482391144024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82457850497563,
              21.02472921532507
            ],
            [
              105.82457566201818,
              21.02472969336691
            ],
            [
              105.82456948473987,
              21.02473121363897
            ],
            [
              105.82457410909063,
              21.024758855203945
            ],
            [
              105.8245448877169,
              21.02481347401383
            ],
            [
              105.82451867008406,
              21.02482391144024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT37_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2890",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02472921532507,
          "to_latitude": 21.02482391144024,
          "to_longitude": 105.82451867008406,
          "from_longitude": 105.82457850497563
        }
      },
      {
        "id": 2884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8242866864391,
            21.024741950968306,
            105.82451867008406,
            21.02482391144024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82451867008406,
              21.02482391144024
            ],
            [
              105.82450365640102,
              21.02479613688741
            ],
            [
              105.82444302676795,
              21.024741950968306
            ],
            [
              105.8242866864391,
              21.02478841470084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2891",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02482391144024,
          "to_latitude": 21.02478841470084,
          "to_longitude": 105.8242866864391,
          "from_longitude": 105.82451867008406
        }
      },
      {
        "id": 2885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535074867235,
            21.02188082520537,
            105.82547720450513,
            21.022143175702762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82546287240083,
              21.022143175702762
            ],
            [
              105.825463187181,
              21.02214303959447
            ],
            [
              105.82547720450513,
              21.022136980470467
            ],
            [
              105.82547024681574,
              21.022117640954992
            ],
            [
              105.82543316619498,
              21.02201066856404
            ],
            [
              105.82541999484995,
              21.021976081141517
            ],
            [
              105.82539497343507,
              21.021961863861875
            ],
            [
              105.82535074867235,
              21.02188082520537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L5_Ngách 39/21, Hào Nam",
          "maTaiSan": "04.O13.DODV.2892",
          "diaChiLapD": "Ngách 39/21, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022143175702762,
          "to_latitude": 21.02188082520537,
          "to_longitude": 105.82535074867235,
          "from_longitude": 105.82546287240083
        }
      },
      {
        "id": 2886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514849740943,
            21.022145314979895,
            105.8254637124427,
            21.022254723037502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254637124427,
              21.022145314979895
            ],
            [
              105.82546345031643,
              21.02214540476815
            ],
            [
              105.82545815490835,
              21.02214720530581
            ],
            [
              105.82544173227267,
              21.022146209265436
            ],
            [
              105.82539243221116,
              21.022160447607032
            ],
            [
              105.82514849740943,
              21.022254723037502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L6_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2893",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022145314979895,
          "to_latitude": 21.022254723037502,
          "to_longitude": 105.82514849740943,
          "from_longitude": 105.8254637124427
        }
      },
      {
        "id": 2887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82453805307559,
            21.022333586731296,
            105.82469739055392,
            21.022531627243612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82464186042321,
              21.022333586731296
            ],
            [
              105.82467979824774,
              21.022427715796763
            ],
            [
              105.82469739055392,
              21.022471364678633
            ],
            [
              105.82453805307559,
              21.022531627243612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT45_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2894",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022333586731296,
          "to_latitude": 21.022531627243612,
          "to_longitude": 105.82453805307559,
          "from_longitude": 105.82464186042321
        }
      },
      {
        "id": 2888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204347300612,
            21.02342102241785,
            105.82124637146862,
            21.023905817941888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204347300612,
              21.023905817941888
            ],
            [
              105.82046209730747,
              21.023877604620655
            ],
            [
              105.82050476623189,
              21.023860158898206
            ],
            [
              105.82068746812051,
              21.023664621314378
            ],
            [
              105.820711794094,
              21.02365444921684
            ],
            [
              105.82085990448513,
              21.023568598153602
            ],
            [
              105.82101795691112,
              21.023497336654025
            ],
            [
              105.8211705732076,
              21.02342102241785
            ],
            [
              105.82124637146862,
              21.023523636845464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9_Ngõ 325, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2895",
          "diaChiLapD": "Ngõ 325, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023905817941888,
          "to_latitude": 21.023523636845464,
          "to_longitude": 105.82124637146862,
          "from_longitude": 105.8204347300612
        }
      },
      {
        "id": 2889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82046502580823,
            21.023507246801802,
            105.82109943706769,
            21.02392932367291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82046502580823,
              21.02392932367291
            ],
            [
              105.82048971659121,
              21.023904062356777
            ],
            [
              105.82049133189146,
              21.023888262558533
            ],
            [
              105.82068642935641,
              21.023685041946695
            ],
            [
              105.82086509368037,
              21.02357408549724
            ],
            [
              105.82098678880931,
              21.02351391679196
            ],
            [
              105.82100711472131,
              21.023507246801802
            ],
            [
              105.82106489506198,
              21.023600766994864
            ],
            [
              105.82109943706769,
              21.023633758567428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT58_Ngõ 325, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2896",
          "diaChiLapD": "Ngõ 325, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 91.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02392932367291,
          "to_latitude": 21.023633758567428,
          "to_longitude": 105.82109943706769,
          "from_longitude": 105.82046502580823
        }
      },
      {
        "id": 2890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82114968716232,
            21.024274751135575,
            105.82164128465618,
            21.024598688488318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82114968716232,
              21.02450435046625
            ],
            [
              105.82116580901948,
              21.02448972978362
            ],
            [
              105.82127252364016,
              21.024393216929933
            ],
            [
              105.82145628039412,
              21.024274751135575
            ],
            [
              105.82164128465618,
              21.02452335192814
            ],
            [
              105.82155503125833,
              21.024598688488318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT60_Ngõ 279, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2897",
          "diaChiLapD": "Ngõ 279, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02450435046625,
          "to_latitude": 21.024598688488318,
          "to_longitude": 105.82155503125833,
          "from_longitude": 105.82114968716232
        }
      },
      {
        "id": 2891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.821146485526,
            21.024256075179252,
            105.8216482692659,
            21.024514669796453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.821146485526,
              21.024501695085494
            ],
            [
              105.82116237527532,
              21.024486323136642
            ],
            [
              105.82117097876728,
              21.024460043785105
            ],
            [
              105.82126050591049,
              21.02438311818719
            ],
            [
              105.8214637508878,
              21.024256075179252
            ],
            [
              105.8216482692659,
              21.024514669796453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4_Ngõ 279, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2898",
          "diaChiLapD": "Ngõ 279, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024501695085494,
          "to_latitude": 21.024514669796453,
          "to_longitude": 105.8216482692659,
          "from_longitude": 105.821146485526
        }
      },
      {
        "id": 2892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82180571311173,
            21.024651336695168,
            105.8225337247768,
            21.025385741791542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8225337247768,
              21.025385741791542
            ],
            [
              105.82252043533538,
              21.025375507487933
            ],
            [
              105.82251727297239,
              21.02536774420357
            ],
            [
              105.82238375887323,
              21.025221685268733
            ],
            [
              105.82216136751816,
              21.024955581851362
            ],
            [
              105.82215141277406,
              21.02490401062456
            ],
            [
              105.82205685685888,
              21.0248346862892
            ],
            [
              105.8219592074615,
              21.02475820704305
            ],
            [
              105.82180571311173,
              21.024651336695168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT62_Ngách 189/2, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2899",
          "diaChiLapD": "Ngách 189/2, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025385741791542,
          "to_latitude": 21.024651336695168,
          "to_longitude": 105.82180571311173,
          "from_longitude": 105.8225337247768
        }
      },
      {
        "id": 2893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82233559822166,
            21.02482496219956,
            105.82274883775715,
            21.02518938503014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274883775715,
              21.02518938503014
            ],
            [
              105.82274785415046,
              21.0251882526276
            ],
            [
              105.82272344276322,
              21.025179021435104
            ],
            [
              105.8225072479838,
              21.0249888263026
            ],
            [
              105.82249832616888,
              21.024973001582804
            ],
            [
              105.82233559822166,
              21.02482496219956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1_Ngách 189/6, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2900",
          "diaChiLapD": "Ngách 189/6, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02518938503014,
          "to_latitude": 21.02482496219956,
          "to_longitude": 105.82233559822166,
          "from_longitude": 105.82274883775715
        }
      },
      {
        "id": 2894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82603795801195,
            21.025127622387696,
            105.8264324847373,
            21.02543501491502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82607759274687,
              21.02543501491502
            ],
            [
              105.82606744562635,
              21.02538230141072
            ],
            [
              105.82605459797857,
              21.025331122956732
            ],
            [
              105.82603795801195,
              21.02527084305795
            ],
            [
              105.82604422772121,
              21.025234941022177
            ],
            [
              105.82607255097457,
              21.02521225443492
            ],
            [
              105.8261219910357,
              21.025187990299866
            ],
            [
              105.82613999330779,
              21.025179155115733
            ],
            [
              105.82617813540854,
              21.02516043745536
            ],
            [
              105.82622789224304,
              21.025138848007206
            ],
            [
              105.82627797169413,
              21.025127622387696
            ],
            [
              105.82632560939868,
              21.025135038536725
            ],
            [
              105.8264324847373,
              21.02519073051708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2901",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02543501491502,
          "to_latitude": 21.02519073051708,
          "to_longitude": 105.8264324847373,
          "from_longitude": 105.82607759274687
        }
      },
      {
        "id": 2895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82563165157129,
            21.0250940768534,
            105.82570873425885,
            21.025353912738932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82570873425885,
              21.025353912738932
            ],
            [
              105.82569660619733,
              21.02531738558762
            ],
            [
              105.82568982022343,
              21.025296945131256
            ],
            [
              105.8256757507375,
              21.02522755813894
            ],
            [
              105.82566377677573,
              21.02516957502668
            ],
            [
              105.82563165157129,
              21.0250940768534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2902",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025353912738932,
          "to_latitude": 21.0250940768534,
          "to_longitude": 105.82563165157129,
          "from_longitude": 105.82570873425885
        }
      },
      {
        "id": 2896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569660619733,
            21.025313198655883,
            105.82571065816495,
            21.02531738558762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82569660619733,
              21.02531738558762
            ],
            [
              105.82570080325144,
              21.025316135017558
            ],
            [
              105.82570606733815,
              21.02531456675085
            ],
            [
              105.82571065816495,
              21.025313198655883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2903",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02531738558762,
          "to_latitude": 21.025313198655883,
          "to_longitude": 105.82571065816495,
          "from_longitude": 105.82569660619733
        }
      },
      {
        "id": 2897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82532242720026,
            21.022117640954992,
            105.82547024681574,
            21.022175341887866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82532242720026,
              21.022175341887866
            ],
            [
              105.82547024681574,
              21.022117640954992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L5_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2904",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022175341887866,
          "to_latitude": 21.022117640954992,
          "to_longitude": 105.82547024681574,
          "from_longitude": 105.82532242720026
        }
      },
      {
        "id": 2898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8271862106673,
            21.02231826817032,
            105.8275891236459,
            21.022448911552118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8271862106673,
              21.022448911552118
            ],
            [
              105.82729863700867,
              21.022413434316228
            ],
            [
              105.8273770664298,
              21.022388684703348
            ],
            [
              105.82739516537475,
              21.022382973180456
            ],
            [
              105.82742107147435,
              21.022371457486802
            ],
            [
              105.82747436353347,
              21.022354589722223
            ],
            [
              105.82753001697007,
              21.022336975227006
            ],
            [
              105.8275891236459,
              21.02231826817032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DODV.2905",
          "diaChiLapD": "Ngõ 32A, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022448911552118,
          "to_latitude": 21.02231826817032,
          "to_longitude": 105.8275891236459,
          "from_longitude": 105.8271862106673
        }
      },
      {
        "id": 2899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82585661431645,
            21.024899402653492,
            105.82585835435671,
            21.024906174714705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82585835435671,
              21.024906174714705
            ],
            [
              105.82585767210576,
              21.024903519836244
            ],
            [
              105.82585721008296,
              21.0249017255651
            ],
            [
              105.82585674998413,
              21.0248999312846
            ],
            [
              105.82585661431645,
              21.024899402653492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT24_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2906",
          "diaChiLapD": "Ngách 127/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024906174714705,
          "to_latitude": 21.024899402653492,
          "to_longitude": 105.82585661431645,
          "from_longitude": 105.82585835435671
        }
      },
      {
        "id": 2900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8243429360504,
            21.02482391144024,
            105.82451867008406,
            21.024894036204902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82451867008406,
              21.02482391144024
            ],
            [
              105.8243429360504,
              21.024894036204902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2907",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02482391144024,
          "to_latitude": 21.024894036204902,
          "to_longitude": 105.8243429360504,
          "from_longitude": 105.82451867008406
        }
      },
      {
        "id": 2901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82459386797834,
            21.024704962847025,
            105.82471969563201,
            21.024745990505846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82459386797834,
              21.024745990505846
            ],
            [
              105.82468983493976,
              21.02471469899802
            ],
            [
              105.82471969563201,
              21.024704962847025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2908",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024745990505846,
          "to_latitude": 21.024704962847025,
          "to_longitude": 105.82471969563201,
          "from_longitude": 105.82459386797834
        }
      },
      {
        "id": 2902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82251991378644,
            21.024988673820022,
            105.82275244758777,
            21.02518647905091
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82275244758777,
              21.02518647905091
            ],
            [
              105.82275059672732,
              21.025184906457028
            ],
            [
              105.82251991378644,
              21.024988673820022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT65_Ngách 189/6, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2909",
          "diaChiLapD": "Ngách 189/6, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02518647905091,
          "to_latitude": 21.024988673820022,
          "to_longitude": 105.82251991378644,
          "from_longitude": 105.82275244758777
        }
      },
      {
        "id": 2903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82194612774441,
            21.02289036118978,
            105.82196001501204,
            21.022924939115594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82196001501204,
              21.022924939115594
            ],
            [
              105.82195546063741,
              21.022913647317093
            ],
            [
              105.82195527339115,
              21.02291318306062
            ],
            [
              105.82195100733722,
              21.02290254290096
            ],
            [
              105.82195029632913,
              21.02290076969154
            ],
            [
              105.8219488099027,
              21.022897055583922
            ],
            [
              105.82194612774441,
              21.02289036118978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2910",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022924939115594,
          "to_latitude": 21.02289036118978,
          "to_longitude": 105.82194612774441,
          "from_longitude": 105.82196001501204
        }
      },
      {
        "id": 2904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82641500414125,
            21.021244466193043,
            105.8265218954719,
            21.021577640154263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265218954719,
              21.021577640154263
            ],
            [
              105.82650265643537,
              21.021558705393478
            ],
            [
              105.82651728411352,
              21.02150911679065
            ],
            [
              105.82641500414125,
              21.02135659654988
            ],
            [
              105.82646728896795,
              21.02131759684894
            ],
            [
              105.82641730575489,
              21.021244466193043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6_Ngách 20/2, Hào Nam",
          "maTaiSan": "04.O13.DODV.2911",
          "diaChiLapD": "Ngách 20/2, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021577640154263,
          "to_latitude": 21.021244466193043,
          "to_longitude": 105.82641730575489,
          "from_longitude": 105.8265218954719
        }
      },
      {
        "id": 2905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82658129849312,
            21.021066865820558,
            105.82678606608016,
            21.021250588268703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82678606608016,
              21.021066865820558
            ],
            [
              105.82670656958624,
              21.021107170261978
            ],
            [
              105.82660125274026,
              21.02116056685207
            ],
            [
              105.82658129849312,
              21.02117068330189
            ],
            [
              105.82662585701411,
              21.021250588268703
            ],
            [
              105.82666462277565,
              21.02122757807255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4_Ngõ 212, La Thành",
          "maTaiSan": "04.O13.DODV.2912",
          "diaChiLapD": "Ngõ 212, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021066865820558,
          "to_latitude": 21.02122757807255,
          "to_longitude": 105.82666462277565,
          "from_longitude": 105.82678606608016
        }
      },
      {
        "id": 2906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82511958912278,
            21.024422870688863,
            105.82547411813906,
            21.02465826735049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547411813906,
              21.02465075826088
            ],
            [
              105.82545540142175,
              21.024653357362308
            ],
            [
              105.82538826090747,
              21.02464935406577
            ],
            [
              105.82533631744646,
              21.024655208857677
            ],
            [
              105.8252970098009,
              21.02465826735049
            ],
            [
              105.82526635212292,
              21.024652358188547
            ],
            [
              105.82524491266119,
              21.024640049198066
            ],
            [
              105.82520055182138,
              21.02457934461467
            ],
            [
              105.82519482571266,
              21.02456827717925
            ],
            [
              105.82511958912278,
              21.024422870688863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29_Ngách 127/56, Hào Nam",
          "maTaiSan": "04.O13.DODV.2913",
          "diaChiLapD": "Ngách 127/56, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02465075826088,
          "to_latitude": 21.024422870688863,
          "to_longitude": 105.82511958912278,
          "from_longitude": 105.82547411813906
        }
      },
      {
        "id": 2907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367674439611,
            21.02437619864255,
            105.82370157566571,
            21.02449461227861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367674439611,
              21.02437619864255
            ],
            [
              105.82370157566571,
              21.02449461227861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_f31_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.2914",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02437619864255,
          "to_latitude": 21.02449461227861,
          "to_longitude": 105.82370157566571,
          "from_longitude": 105.82367674439611
        }
      },
      {
        "id": 2908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245865964268,
            21.024935001774544,
            105.82470123574824,
            21.025016126820425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8245865964268,
              21.024944367722973
            ],
            [
              105.82461294599045,
              21.024935001774544
            ],
            [
              105.82464851284874,
              21.025016126820425
            ],
            [
              105.82470123574824,
              21.02499944059005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2915",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024944367722973,
          "to_latitude": 21.02499944059005,
          "to_longitude": 105.82470123574824,
          "from_longitude": 105.8245865964268
        }
      },
      {
        "id": 2909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82072010284789,
            21.023981392714653,
            105.82112855542977,
            21.02414810527482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82072010284789,
              21.02414810527482
            ],
            [
              105.8207352289563,
              21.024125911370863
            ],
            [
              105.82077389883271,
              21.024095975197827
            ],
            [
              105.82082546899714,
              21.02404600281536
            ],
            [
              105.82085951856102,
              21.024040698219476
            ],
            [
              105.82093467950855,
              21.023981392714653
            ],
            [
              105.82112855542977,
              21.024130476296786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2'_Ngõ 307, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2916",
          "diaChiLapD": "Ngõ 307, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02414810527482,
          "to_latitude": 21.024130476296786,
          "to_longitude": 105.82112855542977,
          "from_longitude": 105.82072010284789
        }
      },
      {
        "id": 2910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82270962636899,
            21.025108105769817,
            105.82283784708491,
            21.025227330558707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82283784708491,
              21.025108105769817
            ],
            [
              105.82271539330212,
              21.025227330558707
            ],
            [
              105.82271081888767,
              21.0252236304952
            ],
            [
              105.82270962636899,
              21.025222469296615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9'_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2917",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025108105769817,
          "to_latitude": 21.025222469296615,
          "to_longitude": 105.82270962636899,
          "from_longitude": 105.82283784708491
        }
      },
      {
        "id": 2911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8212015388501,
            21.023192195937774,
            105.82150105060914,
            21.023744696227364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8212015388501,
              21.023192195937774
            ],
            [
              105.82137194321318,
              21.0234659266997
            ],
            [
              105.82139072782337,
              21.023496101277697
            ],
            [
              105.82143568378265,
              21.023568316559317
            ],
            [
              105.82141920263923,
              21.023580898613265
            ],
            [
              105.82147747593635,
              21.023670405049906
            ],
            [
              105.82150105060914,
              21.023744696227364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2918",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023192195937774,
          "to_latitude": 21.023744696227364,
          "to_longitude": 105.82150105060914,
          "from_longitude": 105.8212015388501
        }
      },
      {
        "id": 2912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82133250319978,
            21.023580898613265,
            105.82141920263923,
            21.023647087531426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141920263923,
              21.023580898613265
            ],
            [
              105.82133250319978,
              21.023647087531426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2919",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023580898613265,
          "to_latitude": 21.023647087531426,
          "to_longitude": 105.82133250319978,
          "from_longitude": 105.82141920263923
        }
      },
      {
        "id": 2913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82200182028849,
            21.02422490356871,
            105.82205347269915,
            21.024322581753257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82200931550841,
              21.02422490356871
            ],
            [
              105.82200372804598,
              21.024228551606797
            ],
            [
              105.82200182028849,
              21.024229932827506
            ],
            [
              105.82203502404016,
              21.024289858537376
            ],
            [
              105.82205347269915,
              21.024322581753257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2920",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02422490356871,
          "to_latitude": 21.024322581753257,
          "to_longitude": 105.82205347269915,
          "from_longitude": 105.82200931550841
        }
      },
      {
        "id": 2914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82190036551164,
            21.024272599076617,
            105.82214913545424,
            21.0243999767079
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82214913545424,
              21.024272599076617
            ],
            [
              105.82205347269915,
              21.024322581753257
            ],
            [
              105.82190036551164,
              21.0243999767079
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DODV.2921",
          "diaChiLapD": "Ngõ 466, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024272599076617,
          "to_latitude": 21.0243999767079,
          "to_longitude": 105.82190036551164,
          "from_longitude": 105.82214913545424
        }
      },
      {
        "id": 2915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8234998872573,
            21.022668846123057,
            105.82356437010716,
            21.0226741745853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234998872573,
              21.02267197821646
            ],
            [
              105.82350651723806,
              21.02267232637361
            ],
            [
              105.82351528728363,
              21.022672786104096
            ],
            [
              105.82354173022541,
              21.0226741745853
            ],
            [
              105.82356437010716,
              21.022668846123057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT57_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DODV.2922",
          "diaChiLapD": "Ngõ 360, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02267197821646,
          "to_latitude": 21.022668846123057,
          "to_longitude": 105.82356437010716,
          "from_longitude": 105.8234998872573
        }
      },
      {
        "id": 2916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82290932589049,
            21.022592850389607,
            105.8234998872573,
            21.022695563773826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234998872573,
              21.02267197821646
            ],
            [
              105.82347301123536,
              21.02260206770072
            ],
            [
              105.82340276456907,
              21.022593353856557
            ],
            [
              105.8233456564305,
              21.022607894962064
            ],
            [
              105.82329462237026,
              21.022592850389607
            ],
            [
              105.82324181939839,
              21.0226179591371
            ],
            [
              105.82315852837921,
              21.022653252974145
            ],
            [
              105.82313892819187,
              21.022606508349035
            ],
            [
              105.82302967154263,
              21.02264097217265
            ],
            [
              105.82290932589049,
              21.022695563773826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT57_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DODV.2923",
          "diaChiLapD": "Ngõ 360, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02267197821646,
          "to_latitude": 21.022695563773826,
          "to_longitude": 105.82290932589049,
          "from_longitude": 105.8234998872573
        }
      },
      {
        "id": 2917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82416307243416,
            21.022099204113072,
            105.82457698890371,
            21.022246980756336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82416307243416,
              21.022160462153725
            ],
            [
              105.82418375813302,
              21.02215087895218
            ],
            [
              105.82433648567621,
              21.022099204113072
            ],
            [
              105.82440452709885,
              21.022246980756336
            ],
            [
              105.82457698890371,
              21.02218624192117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K9_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2924",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022160462153725,
          "to_latitude": 21.02218624192117,
          "to_longitude": 105.82457698890371,
          "from_longitude": 105.82416307243416
        }
      },
      {
        "id": 2918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245598966608,
            21.022084754855356,
            105.82470416353138,
            21.022298183109058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82470416353138,
              21.02208931709537
            ],
            [
              105.82465927843265,
              21.022084754855356
            ],
            [
              105.8245598966608,
              21.022121382861695
            ],
            [
              105.82457698890371,
              21.02218624192117
            ],
            [
              105.82462637502186,
              21.022298183109058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K9_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2925",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02208931709537,
          "to_latitude": 21.022298183109058,
          "to_longitude": 105.82462637502186,
          "from_longitude": 105.82470416353138
        }
      },
      {
        "id": 2919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.823230586836,
            21.024735568315016,
            105.8232892079954,
            21.02489707572656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8232892079954,
              21.02489707572656
            ],
            [
              105.823230586836,
              21.024735568315016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ11_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DODV.2926",
          "diaChiLapD": "Ngõ 189, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02489707572656,
          "to_latitude": 21.024735568315016,
          "to_longitude": 105.823230586836,
          "from_longitude": 105.8232892079954
        }
      },
      {
        "id": 2920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82509152618721,
            21.023485976482363,
            105.82515933066863,
            21.02368640562669
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82515933066863,
              21.02368640562669
            ],
            [
              105.82509152618721,
              21.023485976482363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T7_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2927",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02368640562669,
          "to_latitude": 21.023485976482363,
          "to_longitude": 105.82509152618721,
          "from_longitude": 105.82515933066863
        }
      },
      {
        "id": 2921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82499553773576,
            21.023211969980956,
            105.82507427629511,
            21.02346613681967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82499553773576,
              21.023211969980956
            ],
            [
              105.82503441134493,
              21.023357371416107
            ],
            [
              105.8250673420881,
              21.023447221692937
            ],
            [
              105.82507427629511,
              21.02346613681967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2928",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023211969980956,
          "to_latitude": 21.02346613681967,
          "to_longitude": 105.82507427629511,
          "from_longitude": 105.82499553773576
        }
      },
      {
        "id": 2922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540841959849,
            21.022345802804594,
            105.82566175843436,
            21.02302653303639
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82549372178026,
              21.022345802804594
            ],
            [
              105.82540841959849,
              21.02236267988104
            ],
            [
              105.8254956199455,
              21.02267293371047
            ],
            [
              105.82549620866652,
              21.0226750281425
            ],
            [
              105.82554860893318,
              21.02286146507758
            ],
            [
              105.82564444388478,
              21.022966129894726
            ],
            [
              105.82566175843436,
              21.02302653303639
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2929",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022345802804594,
          "to_latitude": 21.02302653303639,
          "to_longitude": 105.82566175843436,
          "from_longitude": 105.82549372178026
        }
      },
      {
        "id": 2923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82548026591945,
            21.02267293371047,
            105.8254956199455,
            21.022677511712338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254956199455,
              21.02267293371047
            ],
            [
              105.82549110507179,
              21.022674279751755
            ],
            [
              105.82548343526351,
              21.022676566911258
            ],
            [
              105.82548026591945,
              21.022677511712338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.2930",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02267293371047,
          "to_latitude": 21.022677511712338,
          "to_longitude": 105.82548026591945,
          "from_longitude": 105.8254956199455
        }
      },
      {
        "id": 2924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547393324828,
            21.02202286635376,
            105.82608586123213,
            21.022200490386936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547393324828,
              21.022200490386936
            ],
            [
              105.82570428017188,
              21.02213773077107
            ],
            [
              105.82577933186394,
              21.022117282286683
            ],
            [
              105.82601746175465,
              21.022052401628585
            ],
            [
              105.82608586123213,
              21.02202286635376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4'_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2931",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022200490386936,
          "to_latitude": 21.02202286635376,
          "to_longitude": 105.82608586123213,
          "from_longitude": 105.82547393324828
        }
      },
      {
        "id": 2925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82582805594116,
            21.022073404579427,
            105.82583344168101,
            21.022089578680692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82582805594116,
              21.022073404579427
            ],
            [
              105.82583063729273,
              21.02208115526847
            ],
            [
              105.82583271394412,
              21.022087395505395
            ],
            [
              105.82583344168101,
              21.022089578680692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2932",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022073404579427,
          "to_latitude": 21.022089578680692,
          "to_longitude": 105.82583344168101,
          "from_longitude": 105.82582805594116
        }
      },
      {
        "id": 2926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251413310308,
            21.021913386426544,
            105.82529700199237,
            21.02219210809167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82529700199237,
              21.02219210809167
            ],
            [
              105.82529633466932,
              21.02219076281402
            ],
            [
              105.82521178074398,
              21.02201986822111
            ],
            [
              105.82519164637213,
              21.022014947584914
            ],
            [
              105.8251413310308,
              21.021913386426544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Ngách 39/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2933",
          "diaChiLapD": "Ngách 39/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02219210809167,
          "to_latitude": 21.021913386426544,
          "to_longitude": 105.8251413310308,
          "from_longitude": 105.82529700199237
        }
      },
      {
        "id": 2927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82505442787281,
            21.02201986822111,
            105.82521178074398,
            21.022083159172478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82505442787281,
              21.022083159172478
            ],
            [
              105.82521178074398,
              21.02201986822111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Ngách 39/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.2934",
          "diaChiLapD": "Ngách 39/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022083159172478,
          "to_latitude": 21.02201986822111,
          "to_longitude": 105.82521178074398,
          "from_longitude": 105.82505442787281
        }
      },
      {
        "id": 2928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82507560308309,
            21.02222344361863,
            105.82519760946911,
            21.022269541816282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82507560308309,
              21.022269541816282
            ],
            [
              105.82519760946911,
              21.02222344361863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_L5.1_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.2935",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022269541816282,
          "to_latitude": 21.02222344361863,
          "to_longitude": 105.82519760946911,
          "from_longitude": 105.82507560308309
        }
      },
      {
        "id": 2929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82486044204589,
            21.02204522189585,
            105.8250821221869,
            21.022292749281583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82486044204589,
              21.022098424300722
            ],
            [
              105.82498850919124,
              21.02204522189585
            ],
            [
              105.82503204938294,
              21.022158870411722
            ],
            [
              105.82507560308309,
              21.022269541816282
            ],
            [
              105.82508155507865,
              21.022290735130063
            ],
            [
              105.8250821221869,
              21.022292749281583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_L5.1_Ngách 39/35, Hào Nam",
          "maTaiSan": "04.O13.DODV.2936",
          "diaChiLapD": "Ngách 39/35, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022098424300722,
          "to_latitude": 21.022292749281583,
          "to_longitude": 105.8250821221869,
          "from_longitude": 105.82486044204589
        }
      },
      {
        "id": 2930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8258003614653,
            21.02427274723059,
            105.82583364824967,
            21.024404932475537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258003614653,
              21.02427274723059
            ],
            [
              105.82583364824967,
              21.024404932475537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H52_Ngách 127/123, Hào Nam",
          "maTaiSan": "04.O13.DODV.2937",
          "diaChiLapD": "Ngách 127/123, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02427274723059,
          "to_latitude": 21.024404932475537,
          "to_longitude": 105.82583364824967,
          "from_longitude": 105.8258003614653
        }
      },
      {
        "id": 2931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82586826354209,
            21.024242664372082,
            105.82590412435697,
            21.02439068328481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82586826354209,
              21.024242664372082
            ],
            [
              105.82590412435697,
              21.02439068328481
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Ngách 127/123, Hào Nam",
          "maTaiSan": "04.O13.DODV.2938",
          "diaChiLapD": "Ngách 127/123, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024242664372082,
          "to_latitude": 21.02439068328481,
          "to_longitude": 105.82590412435697,
          "from_longitude": 105.82586826354209
        }
      },
      {
        "id": 2932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82548719939724,
            21.024485609849833,
            105.82549403026928,
            21.024487403391497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82549403026928,
              21.024485609849833
            ],
            [
              105.8254902454807,
              21.02448660370373
            ],
            [
              105.82548841648685,
              21.024487084064855
            ],
            [
              105.82548719939724,
              21.024487403391497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.2939",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024485609849833,
          "to_latitude": 21.024487403391497,
          "to_longitude": 105.82548719939724,
          "from_longitude": 105.82549403026928
        }
      },
      {
        "id": 2933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82473740905014,
            21.02396858118281,
            105.82477298809867,
            21.024126800959426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82473740905014,
              21.02396858118281
            ],
            [
              105.82474006367232,
              21.02397881269226
            ],
            [
              105.82477298809867,
              21.024126800959426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F8_Ngách 318/84, La Thành",
          "maTaiSan": "04.O13.DODV.2940",
          "diaChiLapD": "Ngách 318/84, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02396858118281,
          "to_latitude": 21.024126800959426,
          "to_longitude": 105.82477298809867,
          "from_longitude": 105.82473740905014
        }
      },
      {
        "id": 2934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82600416975203,
            21.021735101764936,
            105.82609941962215,
            21.021957783630267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82607501107177,
              21.021735101764936
            ],
            [
              105.82603637533138,
              21.02175049700996
            ],
            [
              105.82600416975203,
              21.021763329278805
            ],
            [
              105.82605625927616,
              21.02187061161387
            ],
            [
              105.82609941962215,
              21.021957783630267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L7_Hào Nam",
          "maTaiSan": "04.O13.DODV.2941",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021735101764936,
          "to_latitude": 21.021957783630267,
          "to_longitude": 105.82609941962215,
          "from_longitude": 105.82607501107177
        }
      },
      {
        "id": 2935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82584113999981,
            21.02186117710904,
            105.82605625927616,
            21.021952350870702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605625927616,
              21.02187061161387
            ],
            [
              105.82588940704383,
              21.021952350870702
            ],
            [
              105.82584113999981,
              21.021876590555443
            ],
            [
              105.82586548935527,
              21.02186117710904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L7_Ngõ 33, Hào Nam",
          "maTaiSan": "04.O13.DODV.2942",
          "diaChiLapD": "Ngõ 33, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02187061161387,
          "to_latitude": 21.02186117710904,
          "to_longitude": 105.82586548935527,
          "from_longitude": 105.82605625927616
        }
      },
      {
        "id": 2936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578784073547,
            21.02138646474775,
            105.82599758013804,
            21.021758252636978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578784073547,
              21.02138646474775
            ],
            [
              105.82586106060222,
              21.0215162559126
            ],
            [
              105.82586298118143,
              21.021519660779607
            ],
            [
              105.82586490564341,
              21.021523071950302
            ],
            [
              105.82587658648457,
              21.021543777677742
            ],
            [
              105.82599758013804,
              21.021758252636978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT54_Hào Nam",
          "maTaiSan": "04.O13.DODV.2943",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02138646474775,
          "to_latitude": 21.021758252636978,
          "to_longitude": 105.82599758013804,
          "from_longitude": 105.82578784073547
        }
      },
      {
        "id": 2937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82586490564341,
            21.021492481972505,
            105.82593084606758,
            21.021523071950302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82586490564341,
              21.021523071950302
            ],
            [
              105.82589144071974,
              21.021511007887284
            ],
            [
              105.8259073907467,
              21.021503757022963
            ],
            [
              105.82593084606758,
              21.021492481972505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT54_Hào Nam",
          "maTaiSan": "04.O13.DODV.2944",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021523071950302,
          "to_latitude": 21.021492481972505,
          "to_longitude": 105.82593084606758,
          "from_longitude": 105.82586490564341
        }
      },
      {
        "id": 2938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570423303054,
            21.021496231125877,
            105.82589144071974,
            21.021562639224378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82589144071974,
              21.021511007887284
            ],
            [
              105.82588437057757,
              21.021496231125877
            ],
            [
              105.82586106060222,
              21.0215162559126
            ],
            [
              105.82578580764381,
              21.02154429520645
            ],
            [
              105.82578126084141,
              21.02154577599021
            ],
            [
              105.82577547118078,
              21.02153433313517
            ],
            [
              105.82570423303054,
              21.021562639224378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT54_Ngõ 9, Hào Nam",
          "maTaiSan": "04.O13.DODV.2945",
          "diaChiLapD": "Ngõ 9, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021511007887284,
          "to_latitude": 21.021562639224378,
          "to_longitude": 105.82570423303054,
          "from_longitude": 105.82589144071974
        }
      },
      {
        "id": 2939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578580764381,
            21.02154429520645,
            105.82582366835209,
            21.021620489940364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578580764381,
              21.02154429520645
            ],
            [
              105.82580374821026,
              21.02158040034869
            ],
            [
              105.82582366835209,
              21.021620489940364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT54_Ngõ 9, Hào Nam",
          "maTaiSan": "04.O13.DODV.2946",
          "diaChiLapD": "Ngõ 9, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02154429520645,
          "to_latitude": 21.021620489940364,
          "to_longitude": 105.82582366835209,
          "from_longitude": 105.82578580764381
        }
      },
      {
        "id": 2940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569430668514,
            21.02158040034869,
            105.82580374821026,
            21.02171915620766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82580374821026,
              21.02158040034869
            ],
            [
              105.82569430668514,
              21.02163187177391
            ],
            [
              105.82573182056184,
              21.02171915620766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT54_Ngõ 9, Hào Nam",
          "maTaiSan": "04.O13.DODV.2947",
          "diaChiLapD": "Ngõ 9, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02158040034869,
          "to_latitude": 21.02171915620766,
          "to_longitude": 105.82573182056184,
          "from_longitude": 105.82580374821026
        }
      },
      {
        "id": 2941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82536422797561,
            21.021317176446864,
            105.82568608337647,
            21.02145388095922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82536422797561,
              21.02145388095922
            ],
            [
              105.82555780117484,
              21.02136924865926
            ],
            [
              105.82560798301556,
              21.021347308708233
            ],
            [
              105.82568608337647,
              21.021317176446864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B4.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2948",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02145388095922,
          "to_latitude": 21.021317176446864,
          "to_longitude": 105.82568608337647,
          "from_longitude": 105.82536422797561
        }
      },
      {
        "id": 2942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82555780117484,
            21.02136924865926,
            105.82558552508395,
            21.02144077262928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82555780117484,
              21.02136924865926
            ],
            [
              105.82558552508395,
              21.021428790205956
            ],
            [
              105.82557859172209,
              21.02144077262928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B4.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2949",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02136924865926,
          "to_latitude": 21.02144077262928,
          "to_longitude": 105.82557859172209,
          "from_longitude": 105.82555780117484
        }
      },
      {
        "id": 2943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82560164978138,
            21.02133497790452,
            105.82560798301556,
            21.021347308708233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82560798301556,
              21.021347308708233
            ],
            [
              105.82560164978138,
              21.02133497790452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B4.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2950",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021347308708233,
          "to_latitude": 21.02133497790452,
          "to_longitude": 105.82560164978138,
          "from_longitude": 105.82560798301556
        }
      },
      {
        "id": 2944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82533796850335,
            21.021447512382906,
            105.82554331630321,
            21.021790899217287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82533796850335,
              21.021447512382906
            ],
            [
              105.82533854000356,
              21.02144910109323
            ],
            [
              105.82533997892413,
              21.02145308546483
            ],
            [
              105.82537928825855,
              21.02156198629887
            ],
            [
              105.82543734672875,
              21.02170568863792
            ],
            [
              105.82548362999442,
              21.021790899217287
            ],
            [
              105.82554331630321,
              21.02176790696104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT51_Ngõ 256, La Thành",
          "maTaiSan": "04.O13.DODV.2951",
          "diaChiLapD": "Ngõ 256, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021447512382906,
          "to_latitude": 21.02176790696104,
          "to_longitude": 105.82554331630321,
          "from_longitude": 105.82533796850335
        }
      },
      {
        "id": 2945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.824755058534,
            21.02146709846432,
            105.82532613049223,
            21.021759032577837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82532613049223,
              21.02146709846432
            ],
            [
              105.82517506740662,
              21.021535940024535
            ],
            [
              105.825153998789,
              21.0215454863621
            ],
            [
              105.82514840921398,
              21.02154801902635
            ],
            [
              105.82499731405072,
              21.021610885750885
            ],
            [
              105.82497710363467,
              21.021638467126166
            ],
            [
              105.824755058534,
              21.021759032577837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2952",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02146709846432,
          "to_latitude": 21.021759032577837,
          "to_longitude": 105.824755058534,
          "from_longitude": 105.82532613049223
        }
      },
      {
        "id": 2946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514472852287,
            21.021529982306085,
            105.825153998789,
            21.0215454863621
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825153998789,
              21.0215454863621
            ],
            [
              105.82515199872124,
              21.02154214329163
            ],
            [
              105.82514873939331,
              21.02153669639514
            ],
            [
              105.82514749659057,
              21.021534611457046
            ],
            [
              105.82514516351748,
              21.02153071451823
            ],
            [
              105.82514472852287,
              21.021529982306085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2953",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0215454863621,
          "to_latitude": 21.021529982306085,
          "to_longitude": 105.82514472852287,
          "from_longitude": 105.825153998789
        }
      },
      {
        "id": 2947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514840921398,
            21.02154801902635,
            105.82527184190157,
            21.02176658716331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82514840921398,
              21.02154801902635
            ],
            [
              105.82516782159593,
              21.021629775152412
            ],
            [
              105.8252423860664,
              21.02176658716331
            ],
            [
              105.82527184190157,
              21.02175401676572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B5.1_Ngõ 266, La Thành",
          "maTaiSan": "04.O13.DODV.2954",
          "diaChiLapD": "Ngõ 266, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02154801902635,
          "to_latitude": 21.02175401676572,
          "to_longitude": 105.82527184190157,
          "from_longitude": 105.82514840921398
        }
      },
      {
        "id": 2948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241525968036,
            21.021765170582675,
            105.82473295718611,
            21.02203468363942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82473295718611,
              21.021765170582675
            ],
            [
              105.82451501621789,
              21.021867148219787
            ],
            [
              105.82448667046023,
              21.021880251430016
            ],
            [
              105.8241525968036,
              21.02203468363942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B6.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.2955",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021765170582675,
          "to_latitude": 21.02203468363942,
          "to_longitude": 105.8241525968036,
          "from_longitude": 105.82473295718611
        }
      },
      {
        "id": 2949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82502406498615,
            21.024655806398385,
            105.82547698298474,
            21.02491358040151
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547698298474,
              21.024656877254756
            ],
            [
              105.8254541761222,
              21.024657951693506
            ],
            [
              105.82542461467307,
              21.024655806398385
            ],
            [
              105.82537778386076,
              21.024656353424614
            ],
            [
              105.82535461101108,
              21.024657699690557
            ],
            [
              105.82535461732631,
              21.0247930506681
            ],
            [
              105.82532364999032,
              21.024810233069548
            ],
            [
              105.82508961014369,
              21.024891277014408
            ],
            [
              105.82502406498615,
              21.02491358040151
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Ngách 127/56, Hào Nam",
          "maTaiSan": "04.O13.DODV.2956",
          "diaChiLapD": "Ngách 127/56, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024656877254756,
          "to_latitude": 21.02491358040151,
          "to_longitude": 105.82502406498615,
          "from_longitude": 105.82547698298474
        }
      },
      {
        "id": 2950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535461732631,
            21.0247930506681,
            105.82537709951103,
            21.024867895702418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82535461732631,
              21.0247930506681
            ],
            [
              105.82537709951103,
              21.024867895702418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Ngách 127/56, Hào Nam",
          "maTaiSan": "04.O13.DODV.2957",
          "diaChiLapD": "Ngách 127/56, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0247930506681,
          "to_latitude": 21.024867895702418,
          "to_longitude": 105.82537709951103,
          "from_longitude": 105.82535461732631
        }
      },
      {
        "id": 2951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82364240008589,
            21.024720401405595,
            105.82375903046501,
            21.024747022557726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82375903046501,
              21.024720401405595
            ],
            [
              105.82375279677068,
              21.024747022557726
            ],
            [
              105.82364240008589,
              21.024741977115944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F32_Ngách 318/99, La Thành",
          "maTaiSan": "04.O13.DODV.2958",
          "diaChiLapD": "Ngách 318/99, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024720401405595,
          "to_latitude": 21.024741977115944,
          "to_longitude": 105.82364240008589,
          "from_longitude": 105.82375903046501
        }
      },
      {
        "id": 2952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82363657165386,
            21.024741977115944,
            105.82364240008589,
            21.024791939261522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82364240008589,
              21.024741977115944
            ],
            [
              105.82363657165386,
              21.024791939261522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F32_Ngách 318/99, La Thành",
          "maTaiSan": "04.O13.DODV.2959",
          "diaChiLapD": "Ngách 318/99, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024741977115944,
          "to_latitude": 21.024791939261522,
          "to_longitude": 105.82363657165386,
          "from_longitude": 105.82364240008589
        }
      },
      {
        "id": 2953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82453771526616,
            21.024470145859866,
            105.82458044023103,
            21.024695056798038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82458044023103,
              21.024695056798038
            ],
            [
              105.82456190565433,
              21.024610787219853
            ],
            [
              105.82455754853036,
              21.02456683933397
            ],
            [
              105.82455418141333,
              21.024532881739805
            ],
            [
              105.82455284215277,
              21.024527779590244
            ],
            [
              105.82453771526616,
              21.024470145859866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.2960",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024695056798038,
          "to_latitude": 21.024470145859866,
          "to_longitude": 105.82453771526616,
          "from_longitude": 105.82458044023103
        }
      },
      {
        "id": 2954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83011441554564,
            21.02607743073995,
            105.83027280115783,
            21.02631780772103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83011441554564,
              21.02631780772103
            ],
            [
              105.83027161888816,
              21.026243468156608
            ],
            [
              105.83027280115783,
              21.026213933274956
            ],
            [
              105.8302221219339,
              21.02607743073995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S2_Ngách 33, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.2961",
          "diaChiLapD": "Ngách 33, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02631780772103,
          "to_latitude": 21.02607743073995,
          "to_longitude": 105.8302221219339,
          "from_longitude": 105.83011441554564
        }
      },
      {
        "id": 2955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284094974859,
            21.025896598411894,
            105.8290156061072,
            21.02602190911261
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284094974859,
              21.02602190911261
            ],
            [
              105.82868641093333,
              21.025954993095862
            ],
            [
              105.82875335970186,
              21.025938814356323
            ],
            [
              105.82877943749702,
              21.025932512827563
            ],
            [
              105.82884375386313,
              21.025934716661553
            ],
            [
              105.8290156061072,
              21.025896598411894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P4_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.2962",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02602190911261,
          "to_latitude": 21.025896598411894,
          "to_longitude": 105.8290156061072,
          "from_longitude": 105.8284094974859
        }
      },
      {
        "id": 2956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781581572134,
            21.026680092370025,
            105.8278673678354,
            21.026691940731713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82781581572134,
              21.026691940731713
            ],
            [
              105.82783902646219,
              21.026686604394914
            ],
            [
              105.8278673678354,
              21.026680092370025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q1_Hào Nam",
          "maTaiSan": "04.O13.DODV.2963",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026691940731713,
          "to_latitude": 21.026680092370025,
          "to_longitude": 105.8278673678354,
          "from_longitude": 105.82781581572134
        }
      },
      {
        "id": 2957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82792178375274,
            21.026040883003574,
            105.82832144904522,
            21.026147924807436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82792178375274,
              21.026147924807436
            ],
            [
              105.82799765265933,
              21.026132313513784
            ],
            [
              105.82807778969581,
              21.026109861154815
            ],
            [
              105.82823031710956,
              21.026067125663477
            ],
            [
              105.82832144904522,
              21.026040883003574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P3_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.2964",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026147924807436,
          "to_latitude": 21.026040883003574,
          "to_longitude": 105.82832144904522,
          "from_longitude": 105.82792178375274
        }
      },
      {
        "id": 2958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82777741306246,
            21.026346249542087,
            105.82807320915924,
            21.026997109205713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82777741306246,
              21.026346249542087
            ],
            [
              105.8278673678354,
              21.026680092370025
            ],
            [
              105.82790732669147,
              21.02681750952067
            ],
            [
              105.82799127858104,
              21.026952178917572
            ],
            [
              105.82807320915924,
              21.026997109205713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q1_Hào Nam",
          "maTaiSan": "04.O13.DODV.2965",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026346249542087,
          "to_latitude": 21.026997109205713,
          "to_longitude": 105.82807320915924,
          "from_longitude": 105.82777741306246
        }
      },
      {
        "id": 2959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892600634798,
            21.024653996880946,
            105.82896550630828,
            21.025003293446325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82892913358805,
              21.024653996880946
            ],
            [
              105.82892600634798,
              21.02477408860921
            ],
            [
              105.82892620102093,
              21.02478319939015
            ],
            [
              105.82892779522734,
              21.02485769952016
            ],
            [
              105.82896550630828,
              21.025003293446325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.2966",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024653996880946,
          "to_latitude": 21.025003293446325,
          "to_longitude": 105.82896550630828,
          "from_longitude": 105.82892913358805
        }
      },
      {
        "id": 2960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865666057228,
            21.025305439854545,
            105.82872482231339,
            21.02530575031421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82865666057228,
              21.02530575031421
            ],
            [
              105.82872482231339,
              21.025305439854545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H3_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2967",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02530575031421,
          "to_latitude": 21.025305439854545,
          "to_longitude": 105.82872482231339,
          "from_longitude": 105.82865666057228
        }
      },
      {
        "id": 2961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866528791057,
            21.025145114913915,
            105.82867141093969,
            21.025229194314647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866528791057,
              21.025229194314647
            ],
            [
              105.82867141093969,
              21.025145114913915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2968",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025229194314647,
          "to_latitude": 21.025145114913915,
          "to_longitude": 105.82867141093969,
          "from_longitude": 105.82866528791057
        }
      },
      {
        "id": 2962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82826502356677,
            21.024969581766005,
            105.82845970716123,
            21.025001351173668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82826502356677,
              21.025001351173668
            ],
            [
              105.82826606219609,
              21.025001117599462
            ],
            [
              105.82840583362476,
              21.024969581766005
            ],
            [
              105.82845970716123,
              21.02497684681167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H4#_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2969",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025001351173668,
          "to_latitude": 21.02497684681167,
          "to_longitude": 105.82845970716123,
          "from_longitude": 105.82826502356677
        }
      },
      {
        "id": 2963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82793343415926,
            21.023697263121115,
            105.82797720973616,
            21.02373293231996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82793343415926,
              21.02373293231996
            ],
            [
              105.82797720973616,
              21.023697263121115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F8_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2970",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02373293231996,
          "to_latitude": 21.023697263121115,
          "to_longitude": 105.82797720973616,
          "from_longitude": 105.82793343415926
        }
      },
      {
        "id": 2964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818110344105,
            21.023741406206685,
            105.82829782134617,
            21.023863932400094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82825031423722,
              21.023741406206685
            ],
            [
              105.82825118612091,
              21.023743205700665
            ],
            [
              105.82828709338216,
              21.023808336695915
            ],
            [
              105.82829782134617,
              21.023844006087007
            ],
            [
              105.82818110344105,
              21.023863932400094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F7_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2971",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023741406206685,
          "to_latitude": 21.023863932400094,
          "to_longitude": 105.82818110344105,
          "from_longitude": 105.82825031423722
        }
      },
      {
        "id": 2965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82788278994452,
            21.023746124383237,
            105.82819546839933,
            21.024046915335003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279181426816,
              21.024046915335003
            ],
            [
              105.82788463700761,
              21.023913730834717
            ],
            [
              105.82788278994452,
              21.023874698833943
            ],
            [
              105.82793662806611,
              21.023839739371248
            ],
            [
              105.82798362233571,
              21.02381536468324
            ],
            [
              105.82799432365007,
              21.023809814655547
            ],
            [
              105.82819546839933,
              21.023746124383237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F8_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2972",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024046915335003,
          "to_latitude": 21.023746124383237,
          "to_longitude": 105.82819546839933,
          "from_longitude": 105.8279181426816
        }
      },
      {
        "id": 2966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279273579602,
            21.02372295326406,
            105.82798362233571,
            21.02381536468324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82798362233571,
              21.02381536468324
            ],
            [
              105.82793343415926,
              21.02373293231996
            ],
            [
              105.8279273579602,
              21.02372295326406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F8_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2973",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02381536468324,
          "to_latitude": 21.02372295326406,
          "to_longitude": 105.8279273579602,
          "from_longitude": 105.82798362233571
        }
      },
      {
        "id": 2967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82803937203961,
            21.024059476101012,
            105.8280882160828,
            21.02426915495766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82803937203961,
              21.024059476101012
            ],
            [
              105.82803985364737,
              21.024063244719855
            ],
            [
              105.82805622294254,
              21.024190925268854
            ],
            [
              105.82807453257311,
              21.0241879683825
            ],
            [
              105.8280882160828,
              21.02426915495766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.2974",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024059476101012,
          "to_latitude": 21.02426915495766,
          "to_longitude": 105.8280882160828,
          "from_longitude": 105.82803937203961
        }
      },
      {
        "id": 2968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82823801429089,
            21.02399086336264,
            105.82851975505046,
            21.02442739656518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82851975505046,
              21.02399086336264
            ],
            [
              105.82851687186431,
              21.024003205522778
            ],
            [
              105.82850959913567,
              21.024034353431603
            ],
            [
              105.82850875254063,
              21.024088486008434
            ],
            [
              105.82848404501603,
              21.02417714768408
            ],
            [
              105.8284907741221,
              21.024220489562396
            ],
            [
              105.82850086809812,
              21.024334301802572
            ],
            [
              105.82850160626181,
              21.02435873043528
            ],
            [
              105.82847040434106,
              21.02439813748181
            ],
            [
              105.82825547373619,
              21.02442739656518
            ],
            [
              105.82823801429089,
              21.024342900916512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G5_Ngách 86/65, Hào Nam",
          "maTaiSan": "04.O13.DODV.2975",
          "diaChiLapD": "Ngách 86/65, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02399086336264,
          "to_latitude": 21.024342900916512,
          "to_longitude": 105.82823801429089,
          "from_longitude": 105.82851975505046
        }
      },
      {
        "id": 2969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850086809812,
            21.024317409976895,
            105.82869954665308,
            21.024334301802572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82850086809812,
              21.024334301802572
            ],
            [
              105.82869954665308,
              21.024317409976895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G5_Ngách 86/65, Hào Nam",
          "maTaiSan": "04.O13.DODV.2976",
          "diaChiLapD": "Ngách 86/65, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024334301802572,
          "to_latitude": 21.024317409976895,
          "to_longitude": 105.82869954665308,
          "from_longitude": 105.82850086809812
        }
      },
      {
        "id": 2970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285692809898,
            21.02434922759507,
            105.8289824603569,
            21.024558394348336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289824603569,
              21.024553694893758
            ],
            [
              105.8289809679416,
              21.024553455587995
            ],
            [
              105.82894832528078,
              21.024548190832533
            ],
            [
              105.82861029625735,
              21.024558394348336
            ],
            [
              105.8285692809898,
              21.02434922759507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M9_Ngách 99, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.2977",
          "diaChiLapD": "Ngách 99, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024553694893758,
          "to_latitude": 21.02434922759507,
          "to_longitude": 105.8285692809898,
          "from_longitude": 105.8289824603569
        }
      },
      {
        "id": 2971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280353736927,
            21.024943413245786,
            105.82821673635857,
            21.02499215554023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280353736927,
              21.02499215554023
            ],
            [
              105.82806757667653,
              21.024979285857164
            ],
            [
              105.82812922713852,
              21.02496445910354
            ],
            [
              105.8281377516188,
              21.024962408822343
            ],
            [
              105.82821673635857,
              21.024943413245786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2978",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02499215554023,
          "to_latitude": 21.024943413245786,
          "to_longitude": 105.82821673635857,
          "from_longitude": 105.8280353736927
        }
      },
      {
        "id": 2972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818513127643,
            21.024801554037875,
            105.82822645042296,
            21.02494824982176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82822067071156,
              21.02494824982176
            ],
            [
              105.82822645042296,
              21.024940767361297
            ],
            [
              105.82818513127643,
              21.024801554037875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9#_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2979",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02494824982176,
          "to_latitude": 21.024801554037875,
          "to_longitude": 105.82818513127643,
          "from_longitude": 105.82822067071156
        }
      },
      {
        "id": 2973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828268692917,
            21.02502473675803,
            105.82839797827542,
            21.0253298513117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828268692917,
              21.02502473675803
            ],
            [
              105.828342623378,
              21.025207195677712
            ],
            [
              105.82836284363155,
              21.025255421514757
            ],
            [
              105.82836940044255,
              21.02527105871863
            ],
            [
              105.82838129112294,
              21.025294802575903
            ],
            [
              105.82839797827542,
              21.0253298513117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H4_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2980",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02502473675803,
          "to_latitude": 21.0253298513117,
          "to_longitude": 105.82839797827542,
          "from_longitude": 105.828268692917
        }
      },
      {
        "id": 2974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82846628902372,
            21.025229194314647,
            105.82866528791057,
            21.025474102362374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828501118787,
              21.025474102362374
            ],
            [
              105.82847303920505,
              21.02536343426934
            ],
            [
              105.82847230753924,
              21.025360550224555
            ],
            [
              105.82846628902372,
              21.02533683105871
            ],
            [
              105.82865576096295,
              21.025313731073993
            ],
            [
              105.82865666057228,
              21.02530575031421
            ],
            [
              105.82866528791057,
              21.025229194314647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2981",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025474102362374,
          "to_latitude": 21.025229194314647,
          "to_longitude": 105.82866528791057,
          "from_longitude": 105.828501118787
        }
      },
      {
        "id": 2975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82887326028455,
            21.02515744749596,
            105.82895957675373,
            21.025439150775117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895957675373,
              21.025439150775117
            ],
            [
              105.82895573826794,
              21.025427475437347
            ],
            [
              105.82892048425667,
              21.025320201489432
            ],
            [
              105.82890389654331,
              21.02526627312399
            ],
            [
              105.82887326028455,
              21.02515744749596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.2982",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025439150775117,
          "to_latitude": 21.02515744749596,
          "to_longitude": 105.82887326028455,
          "from_longitude": 105.82895957675373
        }
      },
      {
        "id": 2976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285175508236,
            21.025408869861554,
            105.82908563531385,
            21.02550197169167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82908563531385,
              21.025408869861554
            ],
            [
              105.82898144117364,
              21.025438293618546
            ],
            [
              105.82889814579626,
              21.025460638043317
            ],
            [
              105.82888435480841,
              21.025464338029494
            ],
            [
              105.8288540621032,
              21.02546376851154
            ],
            [
              105.82884109372982,
              21.025463311458555
            ],
            [
              105.82879449110347,
              21.025461668904207
            ],
            [
              105.82870392964446,
              21.02547095279244
            ],
            [
              105.8285175508236,
              21.02550197169167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.2983",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025408869861554,
          "to_latitude": 21.02550197169167,
          "to_longitude": 105.8285175508236,
          "from_longitude": 105.82908563531385
        }
      },
      {
        "id": 2977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82803609880051,
            21.025478756153852,
            105.8284823152612,
            21.025566364468865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284823152612,
              21.025478756153852
            ],
            [
              105.8283510452191,
              21.02550239801792
            ],
            [
              105.82833166707672,
              21.025507869300423
            ],
            [
              105.82831791277285,
              21.025510725450687
            ],
            [
              105.82831376649547,
              21.025511586547715
            ],
            [
              105.82820645523253,
              21.02553387057555
            ],
            [
              105.82803609880051,
              21.025566364468865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.2984",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025478756153852,
          "to_latitude": 21.025566364468865,
          "to_longitude": 105.82803609880051,
          "from_longitude": 105.8284823152612
        }
      },
      {
        "id": 2978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866207993838,
            21.025661265723365,
            105.82906327641984,
            21.02574301647043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866207993838,
              21.02574301647043
            ],
            [
              105.82894776042188,
              21.025691581947203
            ],
            [
              105.82897734715439,
              21.02568381722308
            ],
            [
              105.82906327641984,
              21.025661265723365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DODV.2985",
          "diaChiLapD": "Ngõ 160, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02574301647043,
          "to_latitude": 21.025661265723365,
          "to_longitude": 105.82906327641984,
          "from_longitude": 105.82866207993838
        }
      },
      {
        "id": 2979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799857982945,
            21.02444640509001,
            105.82809852524024,
            21.024709735426587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82802086251242,
              21.024709735426587
            ],
            [
              105.82802060817272,
              21.024708448666377
            ],
            [
              105.82802355456307,
              21.024682807124947
            ],
            [
              105.82799857982945,
              21.024603313067587
            ],
            [
              105.82804005485009,
              21.02459173592548
            ],
            [
              105.82809852524024,
              21.02457890453858
            ],
            [
              105.82807106822675,
              21.02444640509001
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G7_Hẻm 46/58/14, Hào Nam",
          "maTaiSan": "04.O13.DODV.2986",
          "diaChiLapD": "Hẻm 46/58/14, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024709735426587,
          "to_latitude": 21.02444640509001,
          "to_longitude": 105.82807106822675,
          "from_longitude": 105.82802086251242
        }
      },
      {
        "id": 2980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82793662806611,
            21.023839739371248,
            105.82794112409945,
            21.02384508354313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82794112409945,
              21.02384508354313
            ],
            [
              105.8279389034763,
              21.023842407259338
            ],
            [
              105.82793662806611,
              21.023839739371248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F8_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2987",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02384508354313,
          "to_latitude": 21.023839739371248,
          "to_longitude": 105.82793662806611,
          "from_longitude": 105.82794112409945
        }
      },
      {
        "id": 2981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818938035693,
            21.024029890364552,
            105.8281906132457,
            21.024036786804857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818938035693,
              21.024029890364552
            ],
            [
              105.82818974174312,
              21.024031921763783
            ],
            [
              105.8281903849439,
              21.024035511659925
            ],
            [
              105.82819039189216,
              21.024035550464767
            ],
            [
              105.8281906132457,
              21.024036786804857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G4_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.2988",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024029890364552,
          "to_latitude": 21.024036786804857,
          "to_longitude": 105.8281906132457,
          "from_longitude": 105.82818938035693
        }
      },
      {
        "id": 2982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829337195295,
            21.024698390840268,
            105.82859330851585,
            21.024913154472102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859330851585,
              21.024913154472102
            ],
            [
              105.82858935748715,
              21.02490321386383
            ],
            [
              105.82853795008363,
              21.0248124870145
            ],
            [
              105.82851453961004,
              21.024770482794096
            ],
            [
              105.82850400458828,
              21.02474920898203
            ],
            [
              105.82848596792131,
              21.024712789409634
            ],
            [
              105.82842830950152,
              21.024727198521337
            ],
            [
              105.82842146953625,
              21.024728908224557
            ],
            [
              105.82833507118524,
              21.02475075354648
            ],
            [
              105.82833142146525,
              21.024745547070996
            ],
            [
              105.82829836456004,
              21.024698390840268
            ],
            [
              105.82829382916556,
              21.024704038212736
            ],
            [
              105.82829337195295,
              21.024705209212314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.2989",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024913154472102,
          "to_latitude": 21.024705209212314,
          "to_longitude": 105.82829337195295,
          "from_longitude": 105.82859330851585
        }
      },
      {
        "id": 2983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82858935748715,
            21.024845170991437,
            105.82881639502871,
            21.02490321386383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881639502871,
              21.024845170991437
            ],
            [
              105.8286610934245,
              21.024895694546423
            ],
            [
              105.82858935748715,
              21.02490321386383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.2990",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024845170991437,
          "to_latitude": 21.02490321386383,
          "to_longitude": 105.82858935748715,
          "from_longitude": 105.82881639502871
        }
      },
      {
        "id": 2984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279864785696,
            21.02471891701232,
            105.82798827073576,
            21.02472256905709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279864785696,
              21.02471891701232
            ],
            [
              105.82798751769589,
              21.024721038144413
            ],
            [
              105.82798827073576,
              21.02472256905709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G6_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.2991",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02471891701232,
          "to_latitude": 21.02472256905709,
          "to_longitude": 105.82798827073576,
          "from_longitude": 105.8279864785696
        }
      },
      {
        "id": 2985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897734715439,
            21.02568381722308,
            105.82897904656315,
            21.025696436017732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897904656315,
              21.025696436017732
            ],
            [
              105.8289789903701,
              21.025696016292255
            ],
            [
              105.8289784617331,
              21.0256920988748
            ],
            [
              105.82897793502012,
              21.025688181447965
            ],
            [
              105.82897734715439,
              21.02568381722308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DODV.2992",
          "diaChiLapD": "Ngõ 160, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025696436017732,
          "to_latitude": 21.02568381722308,
          "to_longitude": 105.82897734715439,
          "from_longitude": 105.82897904656315
        }
      },
      {
        "id": 2986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82845480833475,
            21.025360550224555,
            105.82847230753924,
            21.025363955724806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82845480833475,
              21.025363955724806
            ],
            [
              105.82845786805669,
              21.025363360949445
            ],
            [
              105.82846170999706,
              21.025362612428207
            ],
            [
              105.82847230753924,
              21.025360550224555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.2993",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025363955724806,
          "to_latitude": 21.025360550224555,
          "to_longitude": 105.82847230753924,
          "from_longitude": 105.82845480833475
        }
      },
      {
        "id": 2987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886263666047,
            21.023715405083824,
            105.82920356447815,
            21.02376642788569
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920356447815,
              21.0237266524173
            ],
            [
              105.82919606271174,
              21.023724186156418
            ],
            [
              105.82916936896305,
              21.023715405083824
            ],
            [
              105.82908134412112,
              21.023718377688702
            ],
            [
              105.82900712274422,
              21.02375960498328
            ],
            [
              105.82895561897703,
              21.02376642788569
            ],
            [
              105.82886263666047,
              21.02375194443321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M1_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.2994",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0237266524173,
          "to_latitude": 21.02375194443321,
          "to_longitude": 105.82886263666047,
          "from_longitude": 105.82920356447815
        }
      },
      {
        "id": 2988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82844809872198,
            21.023415376809922,
            105.8284486241881,
            21.02342046572872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284486241881,
              21.02342046572872
            ],
            [
              105.82844849003271,
              21.023419167544887
            ],
            [
              105.82844822479744,
              21.02341660548479
            ],
            [
              105.82844809872198,
              21.023415376809922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F6_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.2995",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02342046572872,
          "to_latitude": 21.023415376809922,
          "to_longitude": 105.82844809872198,
          "from_longitude": 105.8284486241881
        }
      },
      {
        "id": 2989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886973311172,
            21.024015797963425,
            105.82913939197088,
            21.02402946357418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82913939197088,
              21.024018874842856
            ],
            [
              105.82913697068169,
              21.024018708711814
            ],
            [
              105.82909484244762,
              21.024015797963425
            ],
            [
              105.82897937819023,
              21.02401783667143
            ],
            [
              105.82897386151815,
              21.024018421753727
            ],
            [
              105.82886973311172,
              21.02402946357418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M4_Ngõ 153, Hào Nam",
          "maTaiSan": "04.O13.DODV.2996",
          "diaChiLapD": "Ngõ 153, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024018874842856,
          "to_latitude": 21.02402946357418,
          "to_longitude": 105.82886973311172,
          "from_longitude": 105.82913939197088
        }
      },
      {
        "id": 2990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82876689078255,
            21.02393502680506,
            105.82897937819023,
            21.02401783667143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897937819023,
              21.02401783667143
            ],
            [
              105.82897191010218,
              21.023963351721353
            ],
            [
              105.82878388376545,
              21.023970074019893
            ],
            [
              105.82876689078255,
              21.02393502680506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M4_Ngõ 153, Hào Nam",
          "maTaiSan": "04.O13.DODV.2997",
          "diaChiLapD": "Ngõ 153, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02401783667143,
          "to_latitude": 21.02393502680506,
          "to_longitude": 105.82876689078255,
          "from_longitude": 105.82897937819023
        }
      },
      {
        "id": 2991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910723597745,
            21.0237230256783,
            105.82917490887762,
            21.02399669756996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82910723597745,
              21.02399669756996
            ],
            [
              105.82913282482,
              21.023929180522
            ],
            [
              105.8291515510605,
              21.02385196919754
            ],
            [
              105.82917361524892,
              21.023760990905025
            ],
            [
              105.82917490887762,
              21.0237230256783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M3_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.2998",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02399669756996,
          "to_latitude": 21.0237230256783,
          "to_longitude": 105.82917490887762,
          "from_longitude": 105.82910723597745
        }
      },
      {
        "id": 2992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291515510605,
            21.02385196919754,
            105.82916695832816,
            21.023855801432976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82916695832816,
              21.023855801432976
            ],
            [
              105.8291601881514,
              21.023854118312666
            ],
            [
              105.8291515510605,
              21.02385196919754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M3_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.2999",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023855801432976,
          "to_latitude": 21.02385196919754,
          "to_longitude": 105.8291515510605,
          "from_longitude": 105.82916695832816
        }
      },
      {
        "id": 2993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905619676977,
            21.024056526584676,
            105.82914200228618,
            21.02431718467513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914200228618,
              21.024056526584676
            ],
            [
              105.82912406941982,
              21.024080115915176
            ],
            [
              105.8291020040738,
              21.024109142027815
            ],
            [
              105.829083407939,
              21.024198931018194
            ],
            [
              105.82908277548316,
              21.02420198519416
            ],
            [
              105.82906694615289,
              21.024270922399115
            ],
            [
              105.82905619676977,
              21.02431718467513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3000",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024056526584676,
          "to_latitude": 21.02431718467513,
          "to_longitude": 105.82905619676977,
          "from_longitude": 105.82914200228618
        }
      },
      {
        "id": 2994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897243801682,
            21.024023400130858,
            105.8291045332615,
            21.024530679002027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897243801682,
              21.024530679002027
            ],
            [
              105.82900980398195,
              21.024383525058273
            ],
            [
              105.82904014321551,
              21.024231625493986
            ],
            [
              105.8290436074682,
              21.024218250817807
            ],
            [
              105.82904541965594,
              21.0242112546382
            ],
            [
              105.8290664557223,
              21.024130043423714
            ],
            [
              105.8291045332615,
              21.024023400130858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3001",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024530679002027,
          "to_latitude": 21.024023400130858,
          "to_longitude": 105.8291045332615,
          "from_longitude": 105.82897243801682
        }
      },
      {
        "id": 2995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82895852801167,
            21.024530683569818,
            105.82926478535751,
            21.024624459234953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895852801167,
              21.024620361136495
            ],
            [
              105.82896526863748,
              21.024621391376403
            ],
            [
              105.82898536244366,
              21.024624459234953
            ],
            [
              105.82926478535751,
              21.024530683569818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N1_Ngách 138, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3002",
          "diaChiLapD": "Ngách 138, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024620361136495,
          "to_latitude": 21.024530683569818,
          "to_longitude": 105.82926478535751,
          "from_longitude": 105.82895852801167
        }
      },
      {
        "id": 2996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8289743529982,
            21.024326925434213,
            105.82905185624756,
            21.024613953299923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289743529982,
              21.024613953299923
            ],
            [
              105.82901829158978,
              21.024459888359484
            ],
            [
              105.82902796165261,
              21.024425981137515
            ],
            [
              105.82902953386112,
              21.02442047012601
            ],
            [
              105.8290365869115,
              21.024394496037115
            ],
            [
              105.82903670005527,
              21.024394080905488
            ],
            [
              105.82905185624756,
              21.024326925434213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M8_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3003",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024613953299923,
          "to_latitude": 21.024326925434213,
          "to_longitude": 105.82905185624756,
          "from_longitude": 105.8289743529982
        }
      },
      {
        "id": 2997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82894239044549,
            21.024703276379874,
            105.82945052785553,
            21.024858592262614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82894239044549,
              21.024856271737807
            ],
            [
              105.82894538578196,
              21.024856561524672
            ],
            [
              105.8289594682643,
              21.024857919075014
            ],
            [
              105.82896645127383,
              21.024858592262614
            ],
            [
              105.82945052785553,
              21.024703276379874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N3_Ngách 146, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3004",
          "diaChiLapD": "Ngách 146, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024856271737807,
          "to_latitude": 21.024703276379874,
          "to_longitude": 105.82945052785553,
          "from_longitude": 105.82894239044549
        }
      },
      {
        "id": 2998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282289949774,
            21.025746511863503,
            105.82865097562285,
            21.02580266390064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8282289949774,
              21.02579803620351
            ],
            [
              105.82830919772668,
              21.02580266390064
            ],
            [
              105.82841453266902,
              21.025785156650137
            ],
            [
              105.82855816079567,
              21.025761682134515
            ],
            [
              105.82865097562285,
              21.025746511863503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H8_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DODV.3005",
          "diaChiLapD": "Ngõ 160, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02579803620351,
          "to_latitude": 21.025746511863503,
          "to_longitude": 105.82865097562285,
          "from_longitude": 105.8282289949774
        }
      },
      {
        "id": 2999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82855816079567,
            21.025761682134515,
            105.82855889093771,
            21.02578047739
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82855889093771,
              21.02578047739
            ],
            [
              105.82855871280455,
              21.025775922393183
            ],
            [
              105.82855837281487,
              21.025767146513363
            ],
            [
              105.82855816079567,
              21.025761682134515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H8_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DODV.3006",
          "diaChiLapD": "Ngõ 160, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02578047739,
          "to_latitude": 21.025761682134515,
          "to_longitude": 105.82855816079567,
          "from_longitude": 105.82855889093771
        }
      },
      {
        "id": 3000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897759672747,
            21.024949539618714,
            105.82919398715276,
            21.02562068154509
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897759672747,
              21.024949539618714
            ],
            [
              105.82904778563044,
              21.025159449183747
            ],
            [
              105.82912423682016,
              21.025397787879466
            ],
            [
              105.82913831992856,
              21.02544169089719
            ],
            [
              105.82919398715276,
              21.02562068154509
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3007",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024949539618714,
          "to_latitude": 21.02562068154509,
          "to_longitude": 105.82919398715276,
          "from_longitude": 105.82897759672747
        }
      },
      {
        "id": 3001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910978305686,
            21.025397787879466,
            105.82912423682016,
            21.02540131950224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912423682016,
              21.025397787879466
            ],
            [
              105.82912239419596,
              21.02539823853966
            ],
            [
              105.82911474124319,
              21.02540010849272
            ],
            [
              105.82910978305686,
              21.02540131950224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3008",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025397787879466,
          "to_latitude": 21.02540131950224,
          "to_longitude": 105.82910978305686,
          "from_longitude": 105.82912423682016
        }
      },
      {
        "id": 3002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897338710289,
            21.025026800290576,
            105.82916591668462,
            21.02562568588537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82916591668462,
              21.02562568588537
            ],
            [
              105.82909634967655,
              21.025415234134325
            ],
            [
              105.82909362981583,
              21.02540213618716
            ],
            [
              105.82909030808452,
              21.025386142726674
            ],
            [
              105.82907553722765,
              21.025332683328646
            ],
            [
              105.8290476703174,
              21.02524923658746
            ],
            [
              105.82903587032456,
              21.025213902160196
            ],
            [
              105.82897338710289,
              21.025026800290576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3009",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02562568588537,
          "to_latitude": 21.025026800290576,
          "to_longitude": 105.82897338710289,
          "from_longitude": 105.82916591668462
        }
      },
      {
        "id": 3003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82926846214319,
            21.025014012708287,
            105.82947716678744,
            21.02572115626285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82927252183234,
              21.02572115626285
            ],
            [
              105.82947716678744,
              21.025656325558284
            ],
            [
              105.82926846214319,
              21.025014012708287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Ngách 168/48, Hào Nam",
          "maTaiSan": "04.O13.DODV.3010",
          "diaChiLapD": "Ngách 168/48, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02572115626285,
          "to_latitude": 21.025014012708287,
          "to_longitude": 105.82926846214319,
          "from_longitude": 105.82927252183234
        }
      },
      {
        "id": 3004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82927102846527,
            21.024980188262905,
            105.82950110981129,
            21.025744398993748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82927102846527,
              21.025744398993748
            ],
            [
              105.82950110981129,
              21.025667235338872
            ],
            [
              105.82928449336596,
              21.024980188262905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N8_Ngách 168/48, Hào Nam",
          "maTaiSan": "04.O13.DODV.3011",
          "diaChiLapD": "Ngách 168/48, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 106,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025744398993748,
          "to_latitude": 21.024980188262905,
          "to_longitude": 105.82928449336596,
          "from_longitude": 105.82927102846527
        }
      },
      {
        "id": 3005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82775043964057,
            21.02579190907496,
            105.82822113427085,
            21.025876408655947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82775043964057,
              21.025876408655947
            ],
            [
              105.82796860004468,
              21.02583932785899
            ],
            [
              105.82799259061659,
              21.025834823190674
            ],
            [
              105.82822113427085,
              21.02579190907496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DODV.3012",
          "diaChiLapD": "Ngõ 160, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025876408655947,
          "to_latitude": 21.02579190907496,
          "to_longitude": 105.82822113427085,
          "from_longitude": 105.82775043964057
        }
      },
      {
        "id": 3006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82736564173338,
            21.024921206864768,
            105.82738238004599,
            21.02492564545273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82736564173338,
              21.02492564545273
            ],
            [
              105.82737418714747,
              21.024923377430554
            ],
            [
              105.82738238004599,
              21.024921206864768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A9_Hào Nam",
          "maTaiSan": "04.O13.DODV.3013",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02492564545273,
          "to_latitude": 21.024921206864768,
          "to_longitude": 105.82738238004599,
          "from_longitude": 105.82736564173338
        }
      },
      {
        "id": 3007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82752127719174,
            21.024833992081877,
            105.82766273643256,
            21.02511119370162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82752127719174,
              21.02511119370162
            ],
            [
              105.82762350072758,
              21.025075750497862
            ],
            [
              105.8276615226564,
              21.025035653764487
            ],
            [
              105.82766156338847,
              21.025033447891325
            ],
            [
              105.82766273643256,
              21.024833992081877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.3014",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02511119370162,
          "to_latitude": 21.024833992081877,
          "to_longitude": 105.82766273643256,
          "from_longitude": 105.82752127719174
        }
      },
      {
        "id": 3008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8273781561698,
            21.023927632150244,
            105.82755699722733,
            21.02393411066816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8273781561698,
              21.023927632150244
            ],
            [
              105.82755699722733,
              21.02393411066816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3015",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023927632150244,
          "to_latitude": 21.02393411066816,
          "to_longitude": 105.82755699722733,
          "from_longitude": 105.8273781561698
        }
      },
      {
        "id": 3009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82728365553731,
            21.02370440095819,
            105.82739648098767,
            21.024145502922522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82728365553731,
              21.02370440095819
            ],
            [
              105.82728801244254,
              21.02373927947568
            ],
            [
              105.8273050052731,
              21.023778898071882
            ],
            [
              105.8273437067205,
              21.02386913256543
            ],
            [
              105.82735540067002,
              21.023926484657306
            ],
            [
              105.827371206877,
              21.02411679764768
            ],
            [
              105.82739648098767,
              21.024145502922522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3016",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02370440095819,
          "to_latitude": 21.024145502922522,
          "to_longitude": 105.82739648098767,
          "from_longitude": 105.82728365553731
        }
      },
      {
        "id": 3010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82758617286609,
            21.02458760266317,
            105.82766880781175,
            21.024808590046337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766880781175,
              21.024808590046337
            ],
            [
              105.82758617286609,
              21.02466770794861
            ],
            [
              105.82758727458216,
              21.02463620623396
            ],
            [
              105.82758769827379,
              21.024624081991558
            ],
            [
              105.82758897357002,
              21.02458760266317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3017",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024808590046337,
          "to_latitude": 21.02458760266317,
          "to_longitude": 105.82758897357002,
          "from_longitude": 105.82766880781175
        }
      },
      {
        "id": 3011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82738750115965,
            21.024167146329432,
            105.82758662317367,
            21.024560894903022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82758662317367,
              21.024560894903022
            ],
            [
              105.82749304866653,
              21.02437016732401
            ],
            [
              105.82745864974358,
              21.024333400018065
            ],
            [
              105.82742480959499,
              21.024252502933027
            ],
            [
              105.82742768416269,
              21.024251655271257
            ],
            [
              105.8274147081327,
              21.0242119656661
            ],
            [
              105.82741130193382,
              21.024201546376737
            ],
            [
              105.82740479073377,
              21.024184908134753
            ],
            [
              105.82739882648048,
              21.024167146329432
            ],
            [
              105.82738750115965,
              21.024168754973232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3018",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024560894903022,
          "to_latitude": 21.024168754973232,
          "to_longitude": 105.82738750115965,
          "from_longitude": 105.82758662317367
        }
      },
      {
        "id": 3012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82775162398627,
            21.024444044675572,
            105.8277755351345,
            21.02451213712553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277755351345,
              21.02451213712553
            ],
            [
              105.82775162398627,
              21.024444044675572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 46/54, Hào Nam",
          "maTaiSan": "04.O13.DODV.3019",
          "diaChiLapD": "Ngách 46/54, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02451213712553,
          "to_latitude": 21.024444044675572,
          "to_longitude": 105.82775162398627,
          "from_longitude": 105.8277755351345
        }
      },
      {
        "id": 3013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277475037433,
            21.024423828879844,
            105.82775162398627,
            21.024444044675572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82775162398627,
              21.024444044675572
            ],
            [
              105.8277475037433,
              21.024423828879844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 46/54, Hào Nam",
          "maTaiSan": "04.O13.DODV.3020",
          "diaChiLapD": "Ngách 46/54, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024444044675572,
          "to_latitude": 21.024423828879844,
          "to_longitude": 105.8277475037433,
          "from_longitude": 105.82775162398627
        }
      },
      {
        "id": 3014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82771626071226,
            21.024308588544795,
            105.8277475037433,
            21.024423828879844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277475037433,
              21.024423828879844
            ],
            [
              105.82773575352607,
              21.02438890417032
            ],
            [
              105.82771626071226,
              21.024308588544795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 46/54, Hào Nam",
          "maTaiSan": "04.O13.DODV.3021",
          "diaChiLapD": "Ngách 46/54, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024423828879844,
          "to_latitude": 21.024308588544795,
          "to_longitude": 105.82771626071226,
          "from_longitude": 105.8277475037433
        }
      },
      {
        "id": 3015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82757435337858,
            21.02463620623396,
            105.82758727458216,
            21.024636613238833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757435337858,
              21.024636613238833
            ],
            [
              105.82757585672313,
              21.024636567084748
            ],
            [
              105.8275784324945,
              21.024636485002464
            ],
            [
              105.82758727458216,
              21.02463620623396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3022",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024636613238833,
          "to_latitude": 21.02463620623396,
          "to_longitude": 105.82758727458216,
          "from_longitude": 105.82757435337858
        }
      },
      {
        "id": 3016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82762350072758,
            21.025075750497862,
            105.8276257194474,
            21.025082087564392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276257194474,
              21.025082087564392
            ],
            [
              105.8276246057609,
              21.0250788323426
            ],
            [
              105.82762350072758,
              21.025075750497862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.3023",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025082087564392,
          "to_latitude": 21.025075750497862,
          "to_longitude": 105.82762350072758,
          "from_longitude": 105.8276257194474
        }
      },
      {
        "id": 3017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82754344421707,
            21.023863959908606,
            105.82762176286548,
            21.02410046399425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82762176286548,
              21.02410046399425
            ],
            [
              105.827621181422,
              21.024099171599207
            ],
            [
              105.82756447881309,
              21.023972834354677
            ],
            [
              105.82755699722733,
              21.02393411066816
            ],
            [
              105.82754344421707,
              21.023863959908606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3024",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02410046399425,
          "to_latitude": 21.023863959908606,
          "to_longitude": 105.82754344421707,
          "from_longitude": 105.82762176286548
        }
      },
      {
        "id": 3018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278939067472,
            21.025634034463195,
            105.82789775411607,
            21.02564367391517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278939067472,
              21.025634034463195
            ],
            [
              105.82789775411607,
              21.02564367391517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.3025",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025634034463195,
          "to_latitude": 21.02564367391517,
          "to_longitude": 105.82789775411607,
          "from_longitude": 105.8278939067472
        }
      },
      {
        "id": 3019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82749025063518,
            21.0251660638352,
            105.82766592999505,
            21.025676801763908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766592999505,
              21.025676801763908
            ],
            [
              105.8276055584067,
              21.02550128919726
            ],
            [
              105.82760023389909,
              21.025485810352404
            ],
            [
              105.8275308970622,
              21.025284233666543
            ],
            [
              105.82749025063518,
              21.0251660638352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P1_Hào Nam",
          "maTaiSan": "04.O13.DODV.3026",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025676801763908,
          "to_latitude": 21.0251660638352,
          "to_longitude": 105.82749025063518,
          "from_longitude": 105.82766592999505
        }
      },
      {
        "id": 3020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275308970622,
            21.025226201612085,
            105.82771628399992,
            21.025284233666543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8275308970622,
              21.025284233666543
            ],
            [
              105.82771628399992,
              21.025226201612085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P1_Hào Nam",
          "maTaiSan": "04.O13.DODV.3027",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025284233666543,
          "to_latitude": 21.025226201612085,
          "to_longitude": 105.82771628399992,
          "from_longitude": 105.8275308970622
        }
      },
      {
        "id": 3021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766592999505,
            21.025676801763908,
            105.82778604295532,
            21.026067449827874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82778604295532,
              21.026067449827874
            ],
            [
              105.82775926831366,
              21.025982230841905
            ],
            [
              105.82774141310354,
              21.025921175761493
            ],
            [
              105.82770167867805,
              21.0257853019686
            ],
            [
              105.82768813378584,
              21.02573984446309
            ],
            [
              105.82767413559569,
              21.02570010025208
            ],
            [
              105.82767358634317,
              21.02569854124969
            ],
            [
              105.82766592999505,
              21.025676801763908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P1_Hào Nam",
          "maTaiSan": "04.O13.DODV.3028",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026067449827874,
          "to_latitude": 21.025676801763908,
          "to_longitude": 105.82766592999505,
          "from_longitude": 105.82778604295532
        }
      },
      {
        "id": 3022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82742384675788,
            21.0240667395257,
            105.82791661484173,
            21.02415839609709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82742384675788,
              21.02415839609709
            ],
            [
              105.8275365937437,
              21.024124846404618
            ],
            [
              105.82753849965313,
              21.024124360228143
            ],
            [
              105.82762312336897,
              21.024102774145522
            ],
            [
              105.82791661484173,
              21.0240667395257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3029",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02415839609709,
          "to_latitude": 21.0240667395257,
          "to_longitude": 105.82791661484173,
          "from_longitude": 105.82742384675788
        }
      },
      {
        "id": 3023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772219230985,
            21.02558450637327,
            105.82802799120506,
            21.02570321461333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82802799120506,
              21.02558450637327
            ],
            [
              105.82793559766591,
              21.02563084002013
            ],
            [
              105.82789775411607,
              21.02564367391517
            ],
            [
              105.82772219230985,
              21.02570321461333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.3030",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02558450637327,
          "to_latitude": 21.02570321461333,
          "to_longitude": 105.82772219230985,
          "from_longitude": 105.82802799120506
        }
      },
      {
        "id": 3024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276760275683,
            21.024669420974153,
            105.82828125180961,
            21.024820085477916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276760275683,
              21.024820085477916
            ],
            [
              105.8277900519642,
              21.024782080105314
            ],
            [
              105.82791254936444,
              21.02474508386094
            ],
            [
              105.82798827073576,
              21.02472256905709
            ],
            [
              105.82800449413503,
              21.0247177454324
            ],
            [
              105.8281014257361,
              21.024687946676043
            ],
            [
              105.82816238224595,
              21.024669420974153
            ],
            [
              105.82828125180961,
              21.02470243121211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G6_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.3031",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024820085477916,
          "to_latitude": 21.02470243121211,
          "to_longitude": 105.82828125180961,
          "from_longitude": 105.8276760275683
        }
      },
      {
        "id": 3025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277475037433,
            21.024345592396624,
            105.82796387152639,
            21.024423828879844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277475037433,
              21.024423828879844
            ],
            [
              105.82796387152639,
              21.024345592396624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 46/54, Hào Nam",
          "maTaiSan": "04.O13.DODV.3032",
          "diaChiLapD": "Ngách 46/54, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024423828879844,
          "to_latitude": 21.024345592396624,
          "to_longitude": 105.82796387152639,
          "from_longitude": 105.8277475037433
        }
      },
      {
        "id": 3026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82757088529988,
            21.02449098083841,
            105.8278406790855,
            21.0245709360058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757088529988,
              21.024560961549593
            ],
            [
              105.82757475217666,
              21.02456037641114
            ],
            [
              105.82759220141543,
              21.0245709360058
            ],
            [
              105.82762210745949,
              21.02456196416179
            ],
            [
              105.8277755351345,
              21.02451213712553
            ],
            [
              105.8278406790855,
              21.02449098083841
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 46/54, Hào Nam",
          "maTaiSan": "04.O13.DODV.3033",
          "diaChiLapD": "Ngách 46/54, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024560961549593,
          "to_latitude": 21.02449098083841,
          "to_longitude": 105.8278406790855,
          "from_longitude": 105.82757088529988
        }
      },
      {
        "id": 3027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82732099326633,
            21.024704205854956,
            105.82743192659444,
            21.025096350398154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82732099326633,
              21.024704205854956
            ],
            [
              105.82738238004599,
              21.024921206864768
            ],
            [
              105.82743192659444,
              21.025096350398154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A9_Hào Nam",
          "maTaiSan": "04.O13.DODV.3034",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024704205854956,
          "to_latitude": 21.025096350398154,
          "to_longitude": 105.82743192659444,
          "from_longitude": 105.82732099326633
        }
      },
      {
        "id": 3028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8271687110782,
            21.02417551496111,
            105.82731753808791,
            21.024684388747765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82731753808791,
              21.024684388747765
            ],
            [
              105.82729247047776,
              21.02460595270428
            ],
            [
              105.82726218085554,
              21.024500607889916
            ],
            [
              105.8271687110782,
              21.02417551496111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8#_Hào Nam",
          "maTaiSan": "04.O13.DODV.3035",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024684388747765,
          "to_latitude": 21.02417551496111,
          "to_longitude": 105.8271687110782,
          "from_longitude": 105.82731753808791
        }
      },
      {
        "id": 3029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82704638210393,
            21.023626865070252,
            105.82717275017626,
            21.024110420667274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82717275017626,
              21.024110420667274
            ],
            [
              105.82711570714403,
              21.023892141129746
            ],
            [
              105.82704638210393,
              21.023626865070252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7#_Hào Nam",
          "maTaiSan": "04.O13.DODV.3036",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024110420667274,
          "to_latitude": 21.023626865070252,
          "to_longitude": 105.82704638210393,
          "from_longitude": 105.82717275017626
        }
      },
      {
        "id": 3030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82822770503184,
            21.02327337667481,
            105.82826048712103,
            21.023508823485567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82822770503184,
              21.02327337667481
            ],
            [
              105.82824483578989,
              21.02334506982354
            ],
            [
              105.82826048712103,
              21.02341381933855
            ],
            [
              105.82825663427637,
              21.023453508640323
            ],
            [
              105.82824893706352,
              21.023508823485567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F3_Hẻm 46/12/47, Hào Nam",
          "maTaiSan": "04.O13.DODV.3037",
          "diaChiLapD": "Hẻm 46/12/47, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02327337667481,
          "to_latitude": 21.023508823485567,
          "to_longitude": 105.82824893706352,
          "from_longitude": 105.82822770503184
        }
      },
      {
        "id": 3031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82798974152206,
            21.023343594259615,
            105.82808246630923,
            21.02336784567211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82808246630923,
              21.023343594259615
            ],
            [
              105.82798974152206,
              21.02336784567211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F3_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3038",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023343594259615,
          "to_latitude": 21.02336784567211,
          "to_longitude": 105.82798974152206,
          "from_longitude": 105.82808246630923
        }
      },
      {
        "id": 3032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806577221577,
            21.023217684509206,
            105.82855683084037,
            21.023307406543694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82855683084037,
              21.02325410835024
            ],
            [
              105.82854230983874,
              21.023227855505834
            ],
            [
              105.8285362796557,
              21.023223769789542
            ],
            [
              105.82852290952772,
              21.023218119314556
            ],
            [
              105.82851985153373,
              21.023217974340433
            ],
            [
              105.82851371149685,
              21.023217684509206
            ],
            [
              105.82824120922633,
              21.023273692977387
            ],
            [
              105.82822770503184,
              21.02327337667481
            ],
            [
              105.82817788786254,
              21.023272208423577
            ],
            [
              105.82806577221577,
              21.023307406543694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F3_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3039",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02325410835024,
          "to_latitude": 21.023307406543694,
          "to_longitude": 105.82806577221577,
          "from_longitude": 105.82855683084037
        }
      },
      {
        "id": 3033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82760246130894,
            21.023357666248177,
            105.82785069520527,
            21.02355209188794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82760246130894,
              21.023357666248177
            ],
            [
              105.82768435023789,
              21.023437258261332
            ],
            [
              105.82775443878255,
              21.02355209188794
            ],
            [
              105.82785069520527,
              21.02350310395025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F1#_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3040",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023357666248177,
          "to_latitude": 21.02350310395025,
          "to_longitude": 105.82785069520527,
          "from_longitude": 105.82760246130894
        }
      },
      {
        "id": 3034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82920633733443,
            21.023636257510066,
            105.82921175323075,
            21.023637145591664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920633733443,
              21.023637145591664
            ],
            [
              105.82920649111364,
              21.02363712045486
            ],
            [
              105.82920928796847,
              21.023636662432235
            ],
            [
              105.82921175323075,
              21.023636257510066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3041",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023637145591664,
          "to_latitude": 21.023636257510066,
          "to_longitude": 105.82921175323075,
          "from_longitude": 105.82920633733443
        }
      },
      {
        "id": 3035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919320153525,
            21.02360634042765,
            105.82920184667371,
            21.023607778058622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920184667371,
              21.02360634042765
            ],
            [
              105.82920050209151,
              21.023606564660255
            ],
            [
              105.82919609253709,
              21.02360729699661
            ],
            [
              105.82919320153525,
              21.023607778058622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K8_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3042",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02360634042765,
          "to_latitude": 21.023607778058622,
          "to_longitude": 105.82919320153525,
          "from_longitude": 105.82920184667371
        }
      },
      {
        "id": 3036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82916813240014,
            21.023488608074974,
            105.82921951524767,
            21.023873972201976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82921901927145,
              21.023488608074974
            ],
            [
              105.82920662662876,
              21.023525949094296
            ],
            [
              105.82919984652271,
              21.02354247142025
            ],
            [
              105.82920392060261,
              21.023581271250823
            ],
            [
              105.82920465882182,
              21.023588296546098
            ],
            [
              105.82921175323075,
              21.023636257510066
            ],
            [
              105.82921951524767,
              21.023688737664266
            ],
            [
              105.82916813240014,
              21.023873972201976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3043",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023488608074974,
          "to_latitude": 21.023873972201976,
          "to_longitude": 105.82916813240014,
          "from_longitude": 105.82921901927145
        }
      },
      {
        "id": 3037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288792620034,
            21.021969366409866,
            105.8290123772948,
            21.022452650174174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288792620034,
              21.021969366409866
            ],
            [
              105.82895717822356,
              21.0222209466843
            ],
            [
              105.82896597380203,
              21.022249344560876
            ],
            [
              105.82897644330471,
              21.022283150014466
            ],
            [
              105.8290123772948,
              21.022452650174174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3044",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021969366409866,
          "to_latitude": 21.022452650174174,
          "to_longitude": 105.8290123772948,
          "from_longitude": 105.8288792620034
        }
      },
      {
        "id": 3038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910706674289,
            21.022948059929956,
            105.82918956935308,
            21.023253849105025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918956935308,
              21.023253849105025
            ],
            [
              105.82915362213193,
              21.023139612879433
            ],
            [
              105.82912336517113,
              21.023018777040587
            ],
            [
              105.8291160911912,
              21.022987214988746
            ],
            [
              105.8291121200798,
              21.02296998546406
            ],
            [
              105.82910706674289,
              21.022948059929956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3045",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023253849105025,
          "to_latitude": 21.022948059929956,
          "to_longitude": 105.82910706674289,
          "from_longitude": 105.82918956935308
        }
      },
      {
        "id": 3039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82894476382364,
            21.0222209466843,
            105.82895717822356,
            21.022227550149633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82894476382364,
              21.022227550149633
            ],
            [
              105.82894699674648,
              21.022226362365053
            ],
            [
              105.82895124870055,
              21.022224100741337
            ],
            [
              105.82895717822356,
              21.0222209466843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3046",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022227550149633,
          "to_latitude": 21.0222209466843,
          "to_longitude": 105.82895717822356,
          "from_longitude": 105.82894476382364
        }
      },
      {
        "id": 3040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290883596384,
            21.02296998546406,
            105.8291121200798,
            21.022976875475905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290883596384,
              21.022976875475905
            ],
            [
              105.82909066950856,
              21.02297620576517
            ],
            [
              105.8290974905874,
              21.02297422787147
            ],
            [
              105.8291121200798,
              21.02296998546406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K7_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3047",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022976875475905,
          "to_latitude": 21.02296998546406,
          "to_longitude": 105.8291121200798,
          "from_longitude": 105.8290883596384
        }
      },
      {
        "id": 3041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82846599607026,
            21.022771472760763,
            105.8285833887503,
            21.023024575152267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82846599607026,
              21.022771472760763
            ],
            [
              105.8284678723749,
              21.022774859746885
            ],
            [
              105.82846980148634,
              21.022777357702484
            ],
            [
              105.82849862782932,
              21.02281470563549
            ],
            [
              105.82850833950853,
              21.02283271921249
            ],
            [
              105.82855617961988,
              21.022859993878363
            ],
            [
              105.8285833887503,
              21.022925166289703
            ],
            [
              105.82851159538683,
              21.022961468895886
            ],
            [
              105.82852707369089,
              21.02299871835996
            ],
            [
              105.82853649395615,
              21.023024575152267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1_Ngách 44/57, Hào Nam",
          "maTaiSan": "04.O13.DODV.3048",
          "diaChiLapD": "Ngách 44/57, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022771472760763,
          "to_latitude": 21.023024575152267,
          "to_longitude": 105.82853649395615,
          "from_longitude": 105.82846599607026
        }
      },
      {
        "id": 3042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829643415577,
            21.02279066388113,
            105.82832538307323,
            21.022824957444254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82829643415577,
              21.02279066388113
            ],
            [
              105.82829820398001,
              21.022795150612506
            ],
            [
              105.82830192824635,
              21.022799233116398
            ],
            [
              105.82832538307323,
              21.022824957444254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9*_Ngách 44/55, Hào Nam",
          "maTaiSan": "04.O13.DODV.3049",
          "diaChiLapD": "Ngách 44/55, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02279066388113,
          "to_latitude": 21.022824957444254,
          "to_longitude": 105.82832538307323,
          "from_longitude": 105.82829643415577
        }
      },
      {
        "id": 3043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828157297153,
            21.022822797131198,
            105.82823221791078,
            21.02306600045796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828157297153,
              21.022822797131198
            ],
            [
              105.82815791821403,
              21.02282481823423
            ],
            [
              105.82823221791078,
              21.02306600045796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C7_Ngách 44/53, Hào Nam",
          "maTaiSan": "04.O13.DODV.3050",
          "diaChiLapD": "Ngách 44/53, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022822797131198,
          "to_latitude": 21.02306600045796,
          "to_longitude": 105.82823221791078,
          "from_longitude": 105.828157297153
        }
      },
      {
        "id": 3044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850864022159,
            21.02268467219753,
            105.82899703225632,
            21.02277189006823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82899703225632,
              21.02268467219753
            ],
            [
              105.82879332145045,
              21.022731774607593
            ],
            [
              105.82876895223457,
              21.02273520909292
            ],
            [
              105.82867423409013,
              21.022748556010598
            ],
            [
              105.8286564608589,
              21.022751060537356
            ],
            [
              105.82850864022159,
              21.02277189006823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3051",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02268467219753,
          "to_latitude": 21.02277189006823,
          "to_longitude": 105.82850864022159,
          "from_longitude": 105.82899703225632
        }
      },
      {
        "id": 3045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828426498837,
            21.02268205530058,
            105.82893352018235,
            21.022770407857344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82893352018235,
              21.02268205530058
            ],
            [
              105.82875376661457,
              21.022722722843366
            ],
            [
              105.82874645904701,
              21.022723705030167
            ],
            [
              105.82863325711423,
              21.02273892224461
            ],
            [
              105.82850060386866,
              21.022756754133706
            ],
            [
              105.82847887920276,
              21.022761071694738
            ],
            [
              105.828426498837,
              21.022770407857344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3052",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02268205530058,
          "to_latitude": 21.022770407857344,
          "to_longitude": 105.828426498837,
          "from_longitude": 105.82893352018235
        }
      },
      {
        "id": 3046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275934631097,
            21.02297166896014,
            105.82765549548259,
            21.023153385910835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8275934631097,
              21.02297166896014
            ],
            [
              105.82759530094144,
              21.02297697730311
            ],
            [
              105.82765549548259,
              21.023153385910835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3053",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02297166896014,
          "to_latitude": 21.023153385910835,
          "to_longitude": 105.82765549548259,
          "from_longitude": 105.8275934631097
        }
      },
      {
        "id": 3047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82804308872248,
            21.022770049422796,
            105.82842164749746,
            21.022851057812616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82804308872248,
              21.022851057812616
            ],
            [
              105.82810283317512,
              21.022834239725775
            ],
            [
              105.82811272594846,
              21.022831055561984
            ],
            [
              105.82826088506656,
              21.022783360105095
            ],
            [
              105.82835505001759,
              21.022775563717662
            ],
            [
              105.82836375198761,
              21.02277484301409
            ],
            [
              105.82842164749746,
              21.022770049422796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C8_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3054",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022851057812616,
          "to_latitude": 21.022770049422796,
          "to_longitude": 105.82842164749746,
          "from_longitude": 105.82804308872248
        }
      },
      {
        "id": 3048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276061354611,
            21.022830502485498,
            105.82815192951405,
            21.022975351165115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276061354611,
              21.022975351165115
            ],
            [
              105.82773758333748,
              21.022926617472695
            ],
            [
              105.82784945407995,
              21.022906511848333
            ],
            [
              105.82793227956044,
              21.022891626375984
            ],
            [
              105.82799725964377,
              21.022879947479407
            ],
            [
              105.82815192951405,
              21.022830502485498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C6_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3055",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022975351165115,
          "to_latitude": 21.022830502485498,
          "to_longitude": 105.82815192951405,
          "from_longitude": 105.8276061354611
        }
      },
      {
        "id": 3049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772634177526,
            21.02286795729022,
            105.82798851440882,
            21.022916196578386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82798851440882,
              21.02286795729022
            ],
            [
              105.82782444535395,
              21.022898145968274
            ],
            [
              105.8277980627926,
              21.022903000049407
            ],
            [
              105.82772634177526,
              21.022916196578386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C5_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3056",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02286795729022,
          "to_latitude": 21.022916196578386,
          "to_longitude": 105.82772634177526,
          "from_longitude": 105.82798851440882
        }
      },
      {
        "id": 3050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277980627926,
            21.022903000049407,
            105.82779952625174,
            21.022909323630568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779952625174,
              21.022909323630568
            ],
            [
              105.82779950366745,
              21.022909066321365
            ],
            [
              105.82779881624349,
              21.022906175734263
            ],
            [
              105.8277980696908,
              21.022903029822253
            ],
            [
              105.8277980627926,
              21.022903000049407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C5_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3057",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022909323630568,
          "to_latitude": 21.022903000049407,
          "to_longitude": 105.8277980627926,
          "from_longitude": 105.82779952625174
        }
      },
      {
        "id": 3051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82793004848436,
            21.022884266920812,
            105.82793227956044,
            21.022891626375984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82793227956044,
              21.022891626375984
            ],
            [
              105.82793221194342,
              21.022891401802113
            ],
            [
              105.82793128896128,
              21.02288836062048
            ],
            [
              105.82793036790801,
              21.02288532033266
            ],
            [
              105.82793004848436,
              21.022884266920812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C6_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3058",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022891626375984,
          "to_latitude": 21.022884266920812,
          "to_longitude": 105.82793004848436,
          "from_longitude": 105.82793227956044
        }
      },
      {
        "id": 3052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82836375198761,
            21.02277484301409,
            105.8283644595881,
            21.022787304071496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283644595881,
              21.022787304071496
            ],
            [
              105.82836430093238,
              21.02278453104053
            ],
            [
              105.82836403544749,
              21.022779833757593
            ],
            [
              105.82836375198761,
              21.02277484301409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C8_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3059",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022787304071496,
          "to_latitude": 21.02277484301409,
          "to_longitude": 105.82836375198761,
          "from_longitude": 105.8283644595881
        }
      },
      {
        "id": 3053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82822137161813,
            21.022799233116398,
            105.82830192824635,
            21.022808966561936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82830192824635,
              21.022799233116398
            ],
            [
              105.82822137161813,
              21.022808966561936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C9*_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3060",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022799233116398,
          "to_latitude": 21.022808966561936,
          "to_longitude": 105.82822137161813,
          "from_longitude": 105.82830192824635
        }
      },
      {
        "id": 3054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874645904701,
            21.022723705030167,
            105.82875006018052,
            21.02273544385774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874645904701,
              21.022723705030167
            ],
            [
              105.82874880820434,
              21.02273136196252
            ],
            [
              105.82874980940647,
              21.022734628564407
            ],
            [
              105.82875006018052,
              21.02273544385774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3061",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022723705030167,
          "to_latitude": 21.02273544385774,
          "to_longitude": 105.82875006018052,
          "from_longitude": 105.82874645904701
        }
      },
      {
        "id": 3055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82876727988443,
            21.02272799686249,
            105.82876895223457,
            21.02273520909292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82876895223457,
              21.02273520909292
            ],
            [
              105.82876882116905,
              21.022734644312816
            ],
            [
              105.82876758638186,
              21.02272932130306
            ],
            [
              105.82876727988443,
              21.02272799686249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3062",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02273520909292,
          "to_latitude": 21.02272799686249,
          "to_longitude": 105.82876727988443,
          "from_longitude": 105.82876895223457
        }
      },
      {
        "id": 3056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82857993926336,
            21.02314203689355,
            105.82911272516564,
            21.023257754019088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82911272516564,
              21.02314203689355
            ],
            [
              105.82892500070554,
              21.023230319044306
            ],
            [
              105.82885040986939,
              21.023257754019088
            ],
            [
              105.8288378986484,
              21.02325730015184
            ],
            [
              105.82873992358913,
              21.023246754626015
            ],
            [
              105.82861365447046,
              21.023253941711893
            ],
            [
              105.8286049277751,
              21.023254370807823
            ],
            [
              105.82857993926336,
              21.02325559899762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F5_Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3063",
          "diaChiLapD": "Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02314203689355,
          "to_latitude": 21.02325559899762,
          "to_longitude": 105.82857993926336,
          "from_longitude": 105.82911272516564
        }
      },
      {
        "id": 3057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8287985636358,
            21.023129848109914,
            105.8288378986484,
            21.02325730015184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288378986484,
              21.02325730015184
            ],
            [
              105.8287985636358,
              21.023129848109914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F5_Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3064",
          "diaChiLapD": "Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02325730015184,
          "to_latitude": 21.023129848109914,
          "to_longitude": 105.8287985636358,
          "from_longitude": 105.8288378986484
        }
      },
      {
        "id": 3058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82860619719888,
            21.021968710421046,
            105.8287550483891,
            21.022423271302806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82868319660592,
              21.021968710421046
            ],
            [
              105.82868149038374,
              21.02197938762686
            ],
            [
              105.82867430203815,
              21.02202439060554
            ],
            [
              105.8287550483891,
              21.02238306250128
            ],
            [
              105.82860619719888,
              21.022423271302806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3065",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021968710421046,
          "to_latitude": 21.022423271302806,
          "to_longitude": 105.82860619719888,
          "from_longitude": 105.82868319660592
        }
      },
      {
        "id": 3059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839203723488,
            21.02207139831578,
            105.82853457478156,
            21.022437716844635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839203723488,
              21.02207139831578
            ],
            [
              105.82839327851353,
              21.022074591493094
            ],
            [
              105.82853456594422,
              21.02243768437159
            ],
            [
              105.82853457478156,
              21.022437716844635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D6_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3066",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02207139831578,
          "to_latitude": 21.022437716844635,
          "to_longitude": 105.82853457478156,
          "from_longitude": 105.82839203723488
        }
      },
      {
        "id": 3060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82809042434849,
            21.02239961659249,
            105.8285083311163,
            21.022513010377736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285083311163,
              21.02239961659249
            ],
            [
              105.8282943637864,
              21.022457673898703
            ],
            [
              105.8282300353264,
              21.02247512766052
            ],
            [
              105.82809042434849,
              21.022513010377736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D6BS2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3067",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02239961659249,
          "to_latitude": 21.022513010377736,
          "to_longitude": 105.82809042434849,
          "from_longitude": 105.8285083311163
        }
      },
      {
        "id": 3061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82804157520945,
            21.022110519589823,
            105.82837981978373,
            21.022461735477872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82837981978373,
              21.022110519589823
            ],
            [
              105.82836212950815,
              21.022115316064948
            ],
            [
              105.82834320398031,
              21.022120448231245
            ],
            [
              105.82804157520945,
              21.022201603061244
            ],
            [
              105.8280760045145,
              21.022317620888618
            ],
            [
              105.82811875050352,
              21.022461735477872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3068",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022110519589823,
          "to_latitude": 21.022461735477872,
          "to_longitude": 105.82811875050352,
          "from_longitude": 105.82837981978373
        }
      },
      {
        "id": 3062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82835215928434,
            21.02208392336063,
            105.82836212950815,
            21.022115316064948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82835215928434,
              21.02208392336063
            ],
            [
              105.82835286223636,
              21.02208614096735
            ],
            [
              105.82835628189088,
              21.022096904303076
            ],
            [
              105.82836212950815,
              21.022115316064948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3069",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02208392336063,
          "to_latitude": 21.022115316064948,
          "to_longitude": 105.82836212950815,
          "from_longitude": 105.82835215928434
        }
      },
      {
        "id": 3063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82812296081964,
            21.02214816222764,
            105.82812608642341,
            21.022158462742357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82812296081964,
              21.02214816222764
            ],
            [
              105.8281245618485,
              21.02215344100844
            ],
            [
              105.82812608642341,
              21.022158462742357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3070",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02214816222764,
          "to_latitude": 21.022158462742357,
          "to_longitude": 105.82812608642341,
          "from_longitude": 105.82812296081964
        }
      },
      {
        "id": 3064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82854367545066,
            21.02198600765844,
            105.82854627132552,
            21.0219981027437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82854627132552,
              21.0219981027437
            ],
            [
              105.82854528886938,
              21.021993526372558
            ],
            [
              105.82854401121342,
              21.02198758034394
            ],
            [
              105.82854367545066,
              21.02198600765844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3071",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0219981027437,
          "to_latitude": 21.02198600765844,
          "to_longitude": 105.82854367545066,
          "from_longitude": 105.82854627132552
        }
      },
      {
        "id": 3065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886153649916,
            21.021985882450927,
            105.82897947828292,
            21.022406328768614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886153649916,
              21.021985882450927
            ],
            [
              105.82893537985487,
              21.022213829833785
            ],
            [
              105.82895489713523,
              21.02228372018757
            ],
            [
              105.82895591211305,
              21.02228878233224
            ],
            [
              105.82897947828292,
              21.022406328768614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K1_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3072",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021985882450927,
          "to_latitude": 21.022406328768614,
          "to_longitude": 105.82897947828292,
          "from_longitude": 105.82886153649916
        }
      },
      {
        "id": 3066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82895591211305,
            21.0222860424816,
            105.82896469812417,
            21.02228878233224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82896469812417,
              21.0222860424816
            ],
            [
              105.82896286390202,
              21.02228661413035
            ],
            [
              105.828958371504,
              21.02228801524972
            ],
            [
              105.82895591211305,
              21.02228878233224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K1_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3073",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0222860424816,
          "to_latitude": 21.02228878233224,
          "to_longitude": 105.82895591211305,
          "from_longitude": 105.82896469812417
        }
      },
      {
        "id": 3067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82879343103367,
            21.02247975507717,
            105.82901701055391,
            21.022614003156555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82901701055391,
              21.022614003156555
            ],
            [
              105.82899621707264,
              21.022524354669287
            ],
            [
              105.82899015642413,
              21.022498225914905
            ],
            [
              105.82898587160716,
              21.02247975507717
            ],
            [
              105.82879343103367,
              21.022520479975295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K3_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3074",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022614003156555,
          "to_latitude": 21.022520479975295,
          "to_longitude": 105.82879343103367,
          "from_longitude": 105.82901701055391
        }
      },
      {
        "id": 3068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82901874310454,
            21.02270970041216,
            105.82912654851793,
            21.023122657658053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912654851793,
              21.023122657658053
            ],
            [
              105.8291142667669,
              21.023081239643602
            ],
            [
              105.82909184320049,
              21.023012578324156
            ],
            [
              105.82907282698855,
              21.02295435250825
            ],
            [
              105.82906627755443,
              21.02292664640124
            ],
            [
              105.82903998915664,
              21.022815426798548
            ],
            [
              105.82901874310454,
              21.02270970041216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3075",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023122657658053,
          "to_latitude": 21.02270970041216,
          "to_longitude": 105.82901874310454,
          "from_longitude": 105.82912654851793
        }
      },
      {
        "id": 3069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909184320049,
            21.023009942791795,
            105.82909923879737,
            21.023012578324156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82909923879737,
              21.023009942791795
            ],
            [
              105.82909517280184,
              21.023011391514007
            ],
            [
              105.82909184320049,
              21.023012578324156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3076",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023009942791795,
          "to_latitude": 21.023012578324156,
          "to_longitude": 105.82909184320049,
          "from_longitude": 105.82909923879737
        }
      },
      {
        "id": 3070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82881111144485,
            21.02292664640124,
            105.82906627755443,
            21.022993175116216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82906627755443,
              21.02292664640124
            ],
            [
              105.82881111144485,
              21.022993175116216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3077",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02292664640124,
          "to_latitude": 21.022993175116216,
          "to_longitude": 105.82881111144485,
          "from_longitude": 105.82906627755443
        }
      },
      {
        "id": 3071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905272911087,
            21.022702238503662,
            105.82912749908527,
            21.02293811197992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912749908527,
              21.022934104857722
            ],
            [
              105.82911055279897,
              21.02293811197992
            ],
            [
              105.82909640318277,
              21.02288039263532
            ],
            [
              105.82908405956391,
              21.02283004202529
            ],
            [
              105.82905272911087,
              21.022702238503662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3078",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022934104857722,
          "to_latitude": 21.022702238503662,
          "to_longitude": 105.82905272911087,
          "from_longitude": 105.82912749908527
        }
      },
      {
        "id": 3072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82906360522037,
            21.02288039263532,
            105.82909640318277,
            21.02288768618189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82906360522037,
              21.02288768618189
            ],
            [
              105.82906638674353,
              21.022887067462694
            ],
            [
              105.82907393192733,
              21.022885389523868
            ],
            [
              105.82909640318277,
              21.02288039263532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3079",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02288768618189,
          "to_latitude": 21.02288039263532,
          "to_longitude": 105.82909640318277,
          "from_longitude": 105.82906360522037
        }
      },
      {
        "id": 3073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82727229343166,
            21.023273522092257,
            105.82778002215584,
            21.023468848175785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82727229343166,
              21.023468848175785
            ],
            [
              105.8275059888079,
              21.023405325549934
            ],
            [
              105.82752689954225,
              21.02339765749275
            ],
            [
              105.8275920385643,
              21.023373770885772
            ],
            [
              105.82760246130894,
              21.023357666248177
            ],
            [
              105.82763695531915,
              21.023315322306363
            ],
            [
              105.8277321422575,
              21.023287308912682
            ],
            [
              105.82778002215584,
              21.023273522092257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F1#_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3080",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023468848175785,
          "to_latitude": 21.023273522092257,
          "to_longitude": 105.82778002215584,
          "from_longitude": 105.82727229343166
        }
      },
      {
        "id": 3074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82881866113036,
            21.02326158965278,
            105.82886394174349,
            21.02356012114041
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288199024268,
              21.02326158965278
            ],
            [
              105.8288198571235,
              21.02327149283001
            ],
            [
              105.8288192626532,
              21.02335624982345
            ],
            [
              105.82881866113036,
              21.023445125555345
            ],
            [
              105.82885834129729,
              21.023545897353962
            ],
            [
              105.82886394174349,
              21.02356012114041
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F4_Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3081",
          "diaChiLapD": "Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02326158965278,
          "to_latitude": 21.02356012114041,
          "to_longitude": 105.82886394174349,
          "from_longitude": 105.8288199024268
        }
      },
      {
        "id": 3075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291866845296,
            21.023325248151032,
            105.82921965470157,
            21.023694939220828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291997514689,
              21.023325248151032
            ],
            [
              105.82921965470157,
              21.02340329721137
            ],
            [
              105.82918845098888,
              21.023513976948976
            ],
            [
              105.8291866845296,
              21.023539675980892
            ],
            [
              105.82919320153525,
              21.023607778058622
            ],
            [
              105.82919354151961,
              21.02361132968694
            ],
            [
              105.82920154206744,
              21.023694939220828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K8_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3082",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023325248151032,
          "to_latitude": 21.023694939220828,
          "to_longitude": 105.82920154206744,
          "from_longitude": 105.8291997514689
        }
      },
      {
        "id": 3076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82704738413784,
            21.022941696995186,
            105.82713470987427,
            21.023103362557716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82706152783004,
              21.023103362557716
            ],
            [
              105.82705145075721,
              21.02301593962851
            ],
            [
              105.82704738413784,
              21.022980655043497
            ],
            [
              105.82713470987427,
              21.022941696995186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3083",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023103362557716,
          "to_latitude": 21.022941696995186,
          "to_longitude": 105.82713470987427,
          "from_longitude": 105.82706152783004
        }
      },
      {
        "id": 3077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694564126462,
            21.023095134276286,
            105.82718111693129,
            21.02311889248964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694564126462,
              21.02311889248964
            ],
            [
              105.8270257078262,
              21.02310816305783
            ],
            [
              105.82706152783004,
              21.023103362557716
            ],
            [
              105.82718111693129,
              21.023095134276286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C1_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3084",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02311889248964,
          "to_latitude": 21.023095134276286,
          "to_longitude": 105.82718111693129,
          "from_longitude": 105.82694564126462
        }
      },
      {
        "id": 3078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82718252274358,
            21.022978799454247,
            105.82758390666612,
            21.023117866130338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82758390666612,
              21.022978799454247
            ],
            [
              105.8274104063787,
              21.023017581750548
            ],
            [
              105.82734622538887,
              21.023044390977468
            ],
            [
              105.82730198750963,
              21.023062869375185
            ],
            [
              105.82730017084539,
              21.023063662211104
            ],
            [
              105.8272402727408,
              21.023089803857072
            ],
            [
              105.82718252274358,
              21.023117866130338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3085",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022978799454247,
          "to_latitude": 21.023117866130338,
          "to_longitude": 105.82718252274358,
          "from_longitude": 105.82758390666612
        }
      },
      {
        "id": 3079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270257078262,
            21.02310816305783,
            105.82702746881003,
            21.023118378099795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702746881003,
              21.023118378099795
            ],
            [
              105.82702663027271,
              21.02311337740698
            ],
            [
              105.8270257078262,
              21.02310816305783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C1_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3086",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023118378099795,
          "to_latitude": 21.02310816305783,
          "to_longitude": 105.8270257078262,
          "from_longitude": 105.82702746881003
        }
      },
      {
        "id": 3080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82719410263329,
            21.022954929533313,
            105.82763057836692,
            21.023095979764456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82719410263329,
              21.023095979764456
            ],
            [
              105.82726056092835,
              21.023063284941227
            ],
            [
              105.82730408514863,
              21.023045004218915
            ],
            [
              105.82736436774306,
              21.02301968442205
            ],
            [
              105.82740813800422,
              21.023003807765146
            ],
            [
              105.82763057836692,
              21.022954929533313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C3_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3087",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023095979764456,
          "to_latitude": 21.022954929533313,
          "to_longitude": 105.82763057836692,
          "from_longitude": 105.82719410263329
        }
      },
      {
        "id": 3081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82691965546854,
            21.023158198932304,
            105.82703869666126,
            21.023587954475627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82703869666126,
              21.023587954475627
            ],
            [
              105.82699077264058,
              21.02342965525428
            ],
            [
              105.82697158884689,
              21.023356432850182
            ],
            [
              105.82694547229448,
              21.02325674623627
            ],
            [
              105.82691965546854,
              21.023158198932304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6#_Hào Nam",
          "maTaiSan": "04.O13.DODV.3088",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023587954475627,
          "to_latitude": 21.023158198932304,
          "to_longitude": 105.82691965546854,
          "from_longitude": 105.82703869666126
        }
      },
      {
        "id": 3082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82791404773589,
            21.022062386605874,
            105.82840117242408,
            21.022185269739396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840117242408,
              21.022062386605874
            ],
            [
              105.82821342060255,
              21.022122217404625
            ],
            [
              105.82819912802603,
              21.022126316286624
            ],
            [
              105.82812296081964,
              21.02214816222764
            ],
            [
              105.82799358418458,
              21.022185269739396
            ],
            [
              105.82798325388902,
              21.02215294031369
            ],
            [
              105.82795488145214,
              21.022159769520144
            ],
            [
              105.82791404773589,
              21.02216959847346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3089",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022062386605874,
          "to_latitude": 21.02216959847346,
          "to_longitude": 105.82791404773589,
          "from_longitude": 105.82840117242408
        }
      },
      {
        "id": 3083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82791254936444,
            21.02474508386094,
            105.82798324348548,
            21.024833063611947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82791254936444,
              21.02474508386094
            ],
            [
              105.82794274263617,
              21.024833063611947
            ],
            [
              105.82798324348548,
              21.02482815341044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G6_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.3090",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02474508386094,
          "to_latitude": 21.02482815341044,
          "to_longitude": 105.82798324348548,
          "from_longitude": 105.82791254936444
        }
      },
      {
        "id": 3084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82754344421707,
            21.023745654397782,
            105.82777957351679,
            21.023863959908606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754344421707,
              21.023863959908606
            ],
            [
              105.82762931225889,
              21.02384178880468
            ],
            [
              105.82763035457664,
              21.023802839383528
            ],
            [
              105.82777957351679,
              21.023745654397782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3091",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023863959908606,
          "to_latitude": 21.023745654397782,
          "to_longitude": 105.82777957351679,
          "from_longitude": 105.82754344421707
        }
      },
      {
        "id": 3085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750627948249,
            21.02372850435235,
            105.82754344421707,
            21.023863959908606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754344421707,
              21.023863959908606
            ],
            [
              105.82753603020828,
              21.023833468786883
            ],
            [
              105.82750627948249,
              21.02372850435235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3092",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023863959908606,
          "to_latitude": 21.02372850435235,
          "to_longitude": 105.82750627948249,
          "from_longitude": 105.82754344421707
        }
      },
      {
        "id": 3086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82982640306132,
            21.021488452400497,
            105.82984572579967,
            21.021524699378016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982640306132,
              21.021488452400497
            ],
            [
              105.82983613704286,
              21.021506659986823
            ],
            [
              105.82984572579967,
              21.021524699378016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F6*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3093",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021488452400497,
          "to_latitude": 21.021524699378016,
          "to_longitude": 105.82984572579967,
          "from_longitude": 105.82982640306132
        }
      },
      {
        "id": 3087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290539126072,
            21.021833582438003,
            105.829065165457,
            21.021857352854248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829065165457,
              21.021857352854248
            ],
            [
              105.82906425929298,
              21.021855442434457
            ],
            [
              105.82905867519207,
              21.02184364372969
            ],
            [
              105.8290539126072,
              21.021833582438003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F9_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3094",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021857352854248,
          "to_latitude": 21.021833582438003,
          "to_longitude": 105.8290539126072,
          "from_longitude": 105.829065165457
        }
      },
      {
        "id": 3088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82891816220558,
            21.021923898170595,
            105.82919772895798,
            21.022375584019713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82891816220558,
              21.021923898170595
            ],
            [
              105.82891889605885,
              21.02192526568957
            ],
            [
              105.82892188192285,
              21.021930813196906
            ],
            [
              105.82894479375437,
              21.02193923791296
            ],
            [
              105.82914776711439,
              21.02228939890717
            ],
            [
              105.82919772895798,
              21.022375584019713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F9A_Ngách 10, ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3095",
          "diaChiLapD": "Ngách 10, ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021923898170595,
          "to_latitude": 21.022375584019713,
          "to_longitude": 105.82919772895798,
          "from_longitude": 105.82891816220558
        }
      },
      {
        "id": 3089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885243101772,
            21.021662732186243,
            105.82924543290233,
            21.02192943281487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920507664019,
              21.021662732186243
            ],
            [
              105.82924543290233,
              21.021742471743853
            ],
            [
              105.8290539126072,
              21.021833582438003
            ],
            [
              105.82904311491453,
              21.021838719321313
            ],
            [
              105.82885243101772,
              21.02192943281487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F9_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3096",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021662732186243,
          "to_latitude": 21.02192943281487,
          "to_longitude": 105.82885243101772,
          "from_longitude": 105.82920507664019
        }
      },
      {
        "id": 3090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961187777117,
            21.021516788009425,
            105.82964598695088,
            21.021577890991146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964598695088,
              21.021577890991146
            ],
            [
              105.82963174196504,
              21.021552323340092
            ],
            [
              105.82961187777117,
              21.021516788009425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3097",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021577890991146,
          "to_latitude": 21.021516788009425,
          "to_longitude": 105.82961187777117,
          "from_longitude": 105.82964598695088
        }
      },
      {
        "id": 3091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919207558571,
            21.021799474264785,
            105.82922343265145,
            21.0218541778526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919207558571,
              21.021799474264785
            ],
            [
              105.8292076780745,
              21.021826643978848
            ],
            [
              105.82922343265145,
              21.0218541778526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F8*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3098",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021799474264785,
          "to_latitude": 21.0218541778526,
          "to_longitude": 105.82922343265145,
          "from_longitude": 105.82919207558571
        }
      },
      {
        "id": 3092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910889795947,
            21.021370396300064,
            105.82923743176279,
            21.021658037549614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82923743176279,
              21.021658037549614
            ],
            [
              105.8291779318835,
              21.021526571495066
            ],
            [
              105.82916056283415,
              21.021488193237932
            ],
            [
              105.82910889795947,
              21.021370396300064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3099",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021658037549614,
          "to_latitude": 21.021370396300064,
          "to_longitude": 105.82910889795947,
          "from_longitude": 105.82923743176279
        }
      },
      {
        "id": 3093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909431631509,
            21.021299954161734,
            105.82929094779855,
            21.021360298871397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82909431631509,
              21.021360298871397
            ],
            [
              105.82910226310285,
              21.021357674202353
            ],
            [
              105.82920846738952,
              21.021322605418675
            ],
            [
              105.82929094779855,
              21.021299954161734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H4*_Ngách 52, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3100",
          "diaChiLapD": "Ngách 52, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021360298871397,
          "to_latitude": 21.021299954161734,
          "to_longitude": 105.82929094779855,
          "from_longitude": 105.82909431631509
        }
      },
      {
        "id": 3094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925170566635,
            21.02137291650448,
            105.82990395006985,
            21.021694062631536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925170566635,
              21.021694062631536
            ],
            [
              105.82943016954194,
              21.02160622364545
            ],
            [
              105.82961187777117,
              21.021516788009425
            ],
            [
              105.82964135226958,
              21.021502280261252
            ],
            [
              105.82972370969424,
              21.02146174463936
            ],
            [
              105.82990395006985,
              21.02137291650448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3101",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021694062631536,
          "to_latitude": 21.02137291650448,
          "to_longitude": 105.82990395006985,
          "from_longitude": 105.82925170566635
        }
      },
      {
        "id": 3095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82988840992428,
            21.021293581706068,
            105.82992021761946,
            21.02137291650448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82990395006985,
              21.02137291650448
            ],
            [
              105.82992021761946,
              21.02136311120703
            ],
            [
              105.82988840992428,
              21.021293581706068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3102",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02137291650448,
          "to_latitude": 21.021293581706068,
          "to_longitude": 105.82988840992428,
          "from_longitude": 105.82990395006985
        }
      },
      {
        "id": 3096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82725037903398,
            21.023478322526636,
            105.82728677099954,
            21.023647836693396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82728677099954,
              21.023647836693396
            ],
            [
              105.8272788005393,
              21.0236332974117
            ],
            [
              105.82727311567926,
              21.02357166821512
            ],
            [
              105.82725957916024,
              21.023478322526636
            ],
            [
              105.82725489883309,
              21.02347914825881
            ],
            [
              105.82725037903398,
              21.023479951532654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3103",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023647836693396,
          "to_latitude": 21.023479951532654,
          "to_longitude": 105.82725037903398,
          "from_longitude": 105.82728677099954
        }
      },
      {
        "id": 3097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839788496074,
            21.021949788611156,
            105.82879815686377,
            21.022016348982238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82879815686377,
              21.02195321997578
            ],
            [
              105.82873205058696,
              21.021963646461476
            ],
            [
              105.82871770882066,
              21.021949788611156
            ],
            [
              105.82857619717508,
              21.02197923943243
            ],
            [
              105.82854367545066,
              21.02198600765844
            ],
            [
              105.82839788496074,
              21.022016348982238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3104",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02195321997578,
          "to_latitude": 21.022016348982238,
          "to_longitude": 105.82839788496074,
          "from_longitude": 105.82879815686377
        }
      },
      {
        "id": 3098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807778969581,
            21.026109861154815,
            105.82809870683165,
            21.026178002435973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82809870683165,
              21.026178002435973
            ],
            [
              105.82809647720332,
              21.02617074233502
            ],
            [
              105.82808582562414,
              21.026136039022486
            ],
            [
              105.82807778969581,
              21.026109861154815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P3_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3105",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026178002435973,
          "to_latitude": 21.026109861154815,
          "to_longitude": 105.82807778969581,
          "from_longitude": 105.82809870683165
        }
      },
      {
        "id": 3099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827784300522,
            21.023142879895836,
            105.8281660311662,
            21.023264361535922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.827784300522,
              21.023264361535922
            ],
            [
              105.82780047247475,
              21.02320616391235
            ],
            [
              105.82783421060502,
              21.023194719324096
            ],
            [
              105.82796000603524,
              21.02315906457019
            ],
            [
              105.82797861346765,
              21.023153790378867
            ],
            [
              105.82801710673203,
              21.023142879895836
            ],
            [
              105.82803781138293,
              21.023154344890973
            ],
            [
              105.8280592466513,
              21.023201079968974
            ],
            [
              105.82807009388746,
              21.023211160437288
            ],
            [
              105.82814692495701,
              21.023223021434312
            ],
            [
              105.8281660311662,
              21.023253709402578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F2_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3106",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023264361535922,
          "to_latitude": 21.023253709402578,
          "to_longitude": 105.8281660311662,
          "from_longitude": 105.827784300522
        }
      },
      {
        "id": 3100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753808488758,
            21.025485810352404,
            105.82760023389909,
            21.025504652374455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753808488758,
              21.025504652374455
            ],
            [
              105.8275625797262,
              21.025497224285132
            ],
            [
              105.82760023389909,
              21.025485810352404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P1_Hào Nam",
          "maTaiSan": "04.O13.DODV.3107",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025504652374455,
          "to_latitude": 21.025485810352404,
          "to_longitude": 105.82760023389909,
          "from_longitude": 105.82753808488758
        }
      },
      {
        "id": 3101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82734316154571,
            21.02303725775828,
            105.82734622538887,
            21.023044390977468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82734316154571,
              21.02303725775828
            ],
            [
              105.82734357378234,
              21.023038221300563
            ],
            [
              105.82734488251953,
              21.023041264222062
            ],
            [
              105.82734622538887,
              21.023044390977468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3108",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02303725775828,
          "to_latitude": 21.023044390977468,
          "to_longitude": 105.82734622538887,
          "from_longitude": 105.82734316154571
        }
      },
      {
        "id": 3102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900413648981,
            21.022533360552153,
            105.82902349287745,
            21.0225350373481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900413648981,
              21.0225350373481
            ],
            [
              105.829005526854,
              21.022534917668214
            ],
            [
              105.82901124982455,
              21.02253442100006
            ],
            [
              105.82902349287745,
              21.022533360552153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3109",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0225350373481,
          "to_latitude": 21.022533360552153,
          "to_longitude": 105.82902349287745,
          "from_longitude": 105.82900413648981
        }
      },
      {
        "id": 3103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82914200228618,
            21.024056526584676,
            105.82939171343321,
            21.024200035396117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914200228618,
              21.024056526584676
            ],
            [
              105.82939171343321,
              21.024200035396117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5_Ngách 116, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3110",
          "diaChiLapD": "Ngách 116, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024056526584676,
          "to_latitude": 21.024200035396117,
          "to_longitude": 105.82939171343321,
          "from_longitude": 105.82914200228618
        }
      },
      {
        "id": 3104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276877154299,
            21.02500011522095,
            105.8280141617733,
            21.02505263698111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276877154299,
              21.02505263698111
            ],
            [
              105.82777140328399,
              21.025052421149425
            ],
            [
              105.82787295911903,
              21.02504294063085
            ],
            [
              105.8279523440262,
              21.02501939729705
            ],
            [
              105.82801045321669,
              21.02500070486285
            ],
            [
              105.82801208920463,
              21.02500011522095
            ],
            [
              105.82801378547865,
              21.025004170570313
            ],
            [
              105.8280141617733,
              21.025005069253293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.3111",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02505263698111,
          "to_latitude": 21.025005069253293,
          "to_longitude": 105.8280141617733,
          "from_longitude": 105.8276877154299
        }
      },
      {
        "id": 3105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82801045321669,
            21.02500070486285,
            105.82813075678095,
            21.025215081156002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82801045321669,
              21.02500070486285
            ],
            [
              105.82801144626055,
              21.02500284247707
            ],
            [
              105.82801271774075,
              21.025005578476875
            ],
            [
              105.8280594735384,
              21.02510621483671
            ],
            [
              105.8280764496931,
              21.025175316481544
            ],
            [
              105.82813075678095,
              21.025215081156002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E8_Ngách 126/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.3112",
          "diaChiLapD": "Ngách 126/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02500070486285,
          "to_latitude": 21.025215081156002,
          "to_longitude": 105.82813075678095,
          "from_longitude": 105.82801045321669
        }
      },
      {
        "id": 3106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290365869115,
            21.024263569760784,
            105.82925729160847,
            21.024394496037115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290365869115,
              21.024394496037115
            ],
            [
              105.82903665892924,
              21.024394472202225
            ],
            [
              105.82917440538091,
              21.02434881106451
            ],
            [
              105.82925729160847,
              21.024263569760784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M8_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3113",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024394496037115,
          "to_latitude": 21.024263569760784,
          "to_longitude": 105.82925729160847,
          "from_longitude": 105.8290365869115
        }
      },
      {
        "id": 3107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850400458828,
            21.024687940281545,
            105.82876321729418,
            21.02474920898203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82850400458828,
              21.02474920898203
            ],
            [
              105.8285573339834,
              21.024727481331546
            ],
            [
              105.82876321729418,
              21.024687940281545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G88_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.3114",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02474920898203,
          "to_latitude": 21.024687940281545,
          "to_longitude": 105.82876321729418,
          "from_longitude": 105.82850400458828
        }
      },
      {
        "id": 3108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82872599297055,
            21.024383525058273,
            105.82900980398195,
            21.02440584861374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900980398195,
              21.024383525058273
            ],
            [
              105.82872599297055,
              21.02440584861374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3115",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024383525058273,
          "to_latitude": 21.02440584861374,
          "to_longitude": 105.82872599297055,
          "from_longitude": 105.82900980398195
        }
      },
      {
        "id": 3109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82769898764415,
            21.02424716570602,
            105.82771626071226,
            21.024308588544795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82771626071226,
              21.024308588544795
            ],
            [
              105.82769898764415,
              21.02424716570602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5_Ngách 46/54, Hào Nam",
          "maTaiSan": "04.O13.DODV.3116",
          "diaChiLapD": "Ngách 46/54, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024308588544795,
          "to_latitude": 21.02424716570602,
          "to_longitude": 105.82769898764415,
          "from_longitude": 105.82771626071226
        }
      },
      {
        "id": 3110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865182244335,
            21.023445125555345,
            105.82881866113036,
            21.02353759815918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881866113036,
              21.023445125555345
            ],
            [
              105.82865182244335,
              21.023476250732326
            ],
            [
              105.82866453170806,
              21.02353759815918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F4_Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3117",
          "diaChiLapD": "Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023445125555345,
          "to_latitude": 21.02353759815918,
          "to_longitude": 105.82866453170806,
          "from_longitude": 105.82881866113036
        }
      },
      {
        "id": 3111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8281529995783,
            21.022566945965682,
            105.82833685309049,
            21.022618773218657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833685309049,
              21.022566945965682
            ],
            [
              105.8281529995783,
              21.022618773218657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6BS3_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3118",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022566945965682,
          "to_latitude": 21.022618773218657,
          "to_longitude": 105.8281529995783,
          "from_longitude": 105.82833685309049
        }
      },
      {
        "id": 3112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833685309049,
            21.02252230558807,
            105.82852457775604,
            21.022566945965682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833685309049,
              21.022566945965682
            ],
            [
              105.82852457775604,
              21.02252230558807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6BS3_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3119",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022566945965682,
          "to_latitude": 21.02252230558807,
          "to_longitude": 105.82852457775604,
          "from_longitude": 105.82833685309049
        }
      },
      {
        "id": 3113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82916056283415,
            21.02141064082203,
            105.82937096133516,
            21.021488193237932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82916056283415,
              21.021488193237932
            ],
            [
              105.82918703302444,
              21.021477842402216
            ],
            [
              105.82930787412172,
              21.021430652277285
            ],
            [
              105.82932906893087,
              21.021429365712326
            ],
            [
              105.82937096133516,
              21.02141064082203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_H5*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3120",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021488193237932,
          "to_latitude": 21.02141064082203,
          "to_longitude": 105.82937096133516,
          "from_longitude": 105.82916056283415
        }
      },
      {
        "id": 3114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867371494353,
            21.023353041959865,
            105.8288192626532,
            21.023358164844588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288192626532,
              21.02335624982345
            ],
            [
              105.82873873813206,
              21.023353041959865
            ],
            [
              105.82867371494353,
              21.023358164844588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F4_Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3121",
          "diaChiLapD": "Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02335624982345,
          "to_latitude": 21.023358164844588,
          "to_longitude": 105.82867371494353,
          "from_longitude": 105.8288192626532
        }
      },
      {
        "id": 3115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82728677099954,
            21.023582533810927,
            105.82756092865618,
            21.023647836693396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82728677099954,
              21.023647836693396
            ],
            [
              105.82756092865618,
              21.023582533810927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E2_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3122",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023647836693396,
          "to_latitude": 21.023582533810927,
          "to_longitude": 105.82756092865618,
          "from_longitude": 105.82728677099954
        }
      },
      {
        "id": 3116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82880325379766,
            21.02419932282882,
            105.82904014321551,
            21.024240339247037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82904014321551,
              21.024231625493986
            ],
            [
              105.82889002081124,
              21.0242304848343
            ],
            [
              105.82884267811967,
              21.02423863865088
            ],
            [
              105.82881472815858,
              21.024240339247037
            ],
            [
              105.82880325379766,
              21.02419932282882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3123",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024231625493986,
          "to_latitude": 21.02419932282882,
          "to_longitude": 105.82880325379766,
          "from_longitude": 105.82904014321551
        }
      },
      {
        "id": 3117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82794569503022,
            21.023980325634383,
            105.8285445594839,
            21.024055826642236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285445594839,
              21.023980325634383
            ],
            [
              105.82847607608694,
              21.023985860920444
            ],
            [
              105.82843713764548,
              21.023991969427446
            ],
            [
              105.82843311604437,
              21.023992405403398
            ],
            [
              105.82831314866192,
              21.024010979252232
            ],
            [
              105.82823254502644,
              21.024024858750856
            ],
            [
              105.82819606602366,
              21.024028813680097
            ],
            [
              105.82818938035693,
              21.024029890364552
            ],
            [
              105.82816239644018,
              21.02403423530675
            ],
            [
              105.82812672301203,
              21.024041222037138
            ],
            [
              105.82802591766738,
              21.024055826642236
            ],
            [
              105.82794569503022,
              21.024055440424565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G4_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3124",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023980325634383,
          "to_latitude": 21.024055440424565,
          "to_longitude": 105.82794569503022,
          "from_longitude": 105.8285445594839
        }
      },
      {
        "id": 3118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806300376434,
            21.02435948006641,
            105.8280724449496,
            21.02444640509001
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807106822675,
              21.02444640509001
            ],
            [
              105.8280724449496,
              21.024401313921672
            ],
            [
              105.82806300376434,
              21.02435948006641
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G7_Hẻm 46/58/14, Hào Nam",
          "maTaiSan": "04.O13.DODV.3125",
          "diaChiLapD": "Hẻm 46/58/14, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02444640509001,
          "to_latitude": 21.02435948006641,
          "to_longitude": 105.82806300376434,
          "from_longitude": 105.82807106822675
        }
      },
      {
        "id": 3119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82715468797933,
            21.02316908758197,
            105.82724459887562,
            21.023450912059303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724459887562,
              21.023450912059303
            ],
            [
              105.82720095414957,
              21.02335565706472
            ],
            [
              105.82718937709669,
              21.023333422680146
            ],
            [
              105.82717612966854,
              21.023307982743763
            ],
            [
              105.82715468797933,
              21.02323858927497
            ],
            [
              105.82715717031472,
              21.02316908758197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3126",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023450912059303,
          "to_latitude": 21.02316908758197,
          "to_longitude": 105.82715717031472,
          "from_longitude": 105.82724459887562
        }
      },
      {
        "id": 3120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867423409013,
            21.022748556010598,
            105.8286883275691,
            21.02283009099918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82867423409013,
              21.022748556010598
            ],
            [
              105.8286883275691,
              21.02283009099918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3#_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3127",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022748556010598,
          "to_latitude": 21.02283009099918,
          "to_longitude": 105.8286883275691,
          "from_longitude": 105.82867423409013
        }
      },
      {
        "id": 3121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82912749908527,
            21.02290564047574,
            105.82924864592087,
            21.02299312074578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912749908527,
              21.022934104857722
            ],
            [
              105.82922109282472,
              21.02290564047574
            ],
            [
              105.82924794384806,
              21.022990891397285
            ],
            [
              105.82924795373738,
              21.02299094012321
            ],
            [
              105.82924864592087,
              21.02299312074578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3128",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022934104857722,
          "to_latitude": 21.02299312074578,
          "to_longitude": 105.82924864592087,
          "from_longitude": 105.82912749908527
        }
      },
      {
        "id": 3122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82795515379826,
            21.02207633910361,
            105.82798325388902,
            21.02215294031369
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82798325388902,
              21.02215294031369
            ],
            [
              105.82795515379826,
              21.02207633910361
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3129",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02215294031369,
          "to_latitude": 21.02207633910361,
          "to_longitude": 105.82795515379826,
          "from_longitude": 105.82798325388902
        }
      },
      {
        "id": 3123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807021165792,
            21.02244281410023,
            105.8282300353264,
            21.022478620818397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807021165792,
              21.02244725284633
            ],
            [
              105.82807627001476,
              21.022445996771026
            ],
            [
              105.82809160758717,
              21.02244281410023
            ],
            [
              105.82809798413064,
              21.022457193105936
            ],
            [
              105.82810734134458,
              21.022478620818397
            ],
            [
              105.82822208569098,
              21.022449907685292
            ],
            [
              105.8282300353264,
              21.02247512766052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6BS2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3130",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02244725284633,
          "to_latitude": 21.02247512766052,
          "to_longitude": 105.8282300353264,
          "from_longitude": 105.82807021165792
        }
      },
      {
        "id": 3124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82776922968176,
            21.022446218726888,
            105.82811212244106,
            21.022713688468915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280707074765,
              21.022449066817835
            ],
            [
              105.82807672249058,
              21.022447801018064
            ],
            [
              105.82808429609065,
              21.022446218726888
            ],
            [
              105.82809456299432,
              21.022494060438653
            ],
            [
              105.82807464171442,
              21.02251904218058
            ],
            [
              105.82807200678953,
              21.022536102477318
            ],
            [
              105.82808594343928,
              21.02258233664643
            ],
            [
              105.82811212244106,
              21.02265629260128
            ],
            [
              105.8280244905625,
              21.02268189122438
            ],
            [
              105.8279488535165,
              21.02270398651346
            ],
            [
              105.82791963682392,
              21.022713688468915
            ],
            [
              105.8278966275516,
              21.022637626087675
            ],
            [
              105.82776922968176,
              21.022670806521404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6BS1_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3131",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022449066817835,
          "to_latitude": 21.022670806521404,
          "to_longitude": 105.82776922968176,
          "from_longitude": 105.8280707074765
        }
      },
      {
        "id": 3125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806998192143,
            21.022427014157476,
            105.82840813926741,
            21.022622028734112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840813926741,
              21.0226149014362
            ],
            [
              105.82835511087373,
              21.022622028734112
            ],
            [
              105.82833685309049,
              21.022566945965682
            ],
            [
              105.82833668187409,
              21.022566426541967
            ],
            [
              105.82830490340474,
              21.022467626690887
            ],
            [
              105.82830250596021,
              21.022460453211895
            ],
            [
              105.8282943637864,
              21.022457673898703
            ],
            [
              105.82829835977424,
              21.022447906834888
            ],
            [
              105.82829153454004,
              21.022427014157476
            ],
            [
              105.82811077223263,
              21.022470866185586
            ],
            [
              105.8280978218514,
              21.02244069920137
            ],
            [
              105.82807619793924,
              21.022445139058206
            ],
            [
              105.82806998192143,
              21.022446413965586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D6BS3_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3132",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0226149014362,
          "to_latitude": 21.022446413965586,
          "to_longitude": 105.82806998192143,
          "from_longitude": 105.82840813926741
        }
      },
      {
        "id": 3126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82858772287207,
            21.022609805889793,
            105.82863325711423,
            21.02273892224461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82863325711423,
              21.02273892224461
            ],
            [
              105.82858772287207,
              21.022609805889793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3133",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02273892224461,
          "to_latitude": 21.022609805889793,
          "to_longitude": 105.82858772287207,
          "from_longitude": 105.82863325711423
        }
      },
      {
        "id": 3127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82895558449583,
            21.024657466375313,
            105.8289742438733,
            21.024934002434595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895744313701,
              21.024657466375313
            ],
            [
              105.82895558449583,
              21.02472409095912
            ],
            [
              105.82895676874642,
              21.02475767610971
            ],
            [
              105.82895784911324,
              21.024788305512406
            ],
            [
              105.82895859860145,
              21.024826250840515
            ],
            [
              105.8289594682643,
              21.024857919075014
            ],
            [
              105.8289601151667,
              21.024881486470655
            ],
            [
              105.82896582488053,
              21.024907457056212
            ],
            [
              105.8289742438733,
              21.024934002434595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3134",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024657466375313,
          "to_latitude": 21.024934002434595,
          "to_longitude": 105.8289742438733,
          "from_longitude": 105.82895744313701
        }
      },
      {
        "id": 3128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867759467007,
            21.023545897353962,
            105.82885834129729,
            21.02359401315699
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82885834129729,
              21.023545897353962
            ],
            [
              105.82881542268454,
              21.02355712585143
            ],
            [
              105.82874697614052,
              21.0235750343375
            ],
            [
              105.82867759467007,
              21.02359401315699
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F4_Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3135",
          "diaChiLapD": "Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023545897353962,
          "to_latitude": 21.02359401315699,
          "to_longitude": 105.82867759467007,
          "from_longitude": 105.82885834129729
        }
      },
      {
        "id": 3129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82881542268454,
            21.02355712585143,
            105.82883406034885,
            21.023617528502733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881542268454,
              21.02355712585143
            ],
            [
              105.82883406034885,
              21.023617528502733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F4_Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3136",
          "diaChiLapD": "Hẻm 127/16, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02355712585143,
          "to_latitude": 21.023617528502733,
          "to_longitude": 105.82883406034885,
          "from_longitude": 105.82881542268454
        }
      },
      {
        "id": 3130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82908667108107,
            21.021410066755205,
            105.82911206575952,
            21.021417811048927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82908667108107,
              21.021417811048927
            ],
            [
              105.82910205405338,
              21.021413119683288
            ],
            [
              105.82911206575952,
              21.021410066755205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6#_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3137",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021417811048927,
          "to_latitude": 21.021410066755205,
          "to_longitude": 105.82911206575952,
          "from_longitude": 105.82908667108107
        }
      },
      {
        "id": 3131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839585480757,
            21.024523702018023,
            105.82842830950152,
            21.024727198521337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82842830950152,
              21.024727198521337
            ],
            [
              105.82842743853539,
              21.024721736448072
            ],
            [
              105.82839585480757,
              21.024523702018023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G88_Ngách 46/58, Hào Nam",
          "maTaiSan": "04.O13.DODV.3138",
          "diaChiLapD": "Ngách 46/58, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024727198521337,
          "to_latitude": 21.024523702018023,
          "to_longitude": 105.82839585480757,
          "from_longitude": 105.82842830950152
        }
      },
      {
        "id": 3132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290211898705,
            21.0274852173419,
            105.83054653975204,
            21.02770677331494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290211898705,
              21.0274852173419
            ],
            [
              105.82902799595547,
              21.02748866699412
            ],
            [
              105.82921587075346,
              21.027584535964607
            ],
            [
              105.82955394390771,
              21.02770677331494
            ],
            [
              105.82963562676068,
              21.027702789924547
            ],
            [
              105.82980195248442,
              21.02767760306534
            ],
            [
              105.8302619741558,
              21.02760007934022
            ],
            [
              105.83054653975204,
              21.027553523103435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q4_An Trạch",
          "maTaiSan": "04.O13.DODV.3139",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 165,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0274852173419,
          "to_latitude": 21.027553523103435,
          "to_longitude": 105.83054653975204,
          "from_longitude": 105.8290211898705
        }
      },
      {
        "id": 3133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83011504991846,
            21.026399534675832,
            105.8302653910024,
            21.02686150442414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302653910024,
              21.02686150442414
            ],
            [
              105.83026528313977,
              21.02686096211304
            ],
            [
              105.83025498932467,
              21.02680844924042
            ],
            [
              105.83017196026184,
              21.02657550210375
            ],
            [
              105.83011504991846,
              21.026399534675832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S2A_Ngách 33, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3140",
          "diaChiLapD": "Ngách 33, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02686150442414,
          "to_latitude": 21.026399534675832,
          "to_longitude": 105.83011504991846,
          "from_longitude": 105.8302653910024
        }
      },
      {
        "id": 3134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83048630385295,
            21.02674357581998,
            105.83085275026755,
            21.02691739747523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83048630385295,
              21.02691739747523
            ],
            [
              105.83067652368494,
              21.02682845824839
            ],
            [
              105.83085275026755,
              21.02674357581998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S5_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3141",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02691739747523,
          "to_latitude": 21.02674357581998,
          "to_longitude": 105.83085275026755,
          "from_longitude": 105.83048630385295
        }
      },
      {
        "id": 3135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83088115958394,
            21.02660529375018,
            105.83109199227312,
            21.02673083794471
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83089206622272,
              21.02673083794471
            ],
            [
              105.83088500294791,
              21.026720907184547
            ],
            [
              105.83088115958394,
              21.026715327780227
            ],
            [
              105.83109199227312,
              21.02660529375018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S7_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3142",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02673083794471,
          "to_latitude": 21.02660529375018,
          "to_longitude": 105.83109199227312,
          "from_longitude": 105.83089206622272
        }
      },
      {
        "id": 3136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284283119,
            21.02719617550147,
            105.8288730681987,
            21.027398151404046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288730681987,
              21.027398151404046
            ],
            [
              105.8284283119,
              21.02719617550147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U3_An Trạch",
          "maTaiSan": "04.O13.DODV.3143",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027398151404046,
          "to_latitude": 21.02719617550147,
          "to_longitude": 105.8284283119,
          "from_longitude": 105.8288730681987
        }
      },
      {
        "id": 3137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919998469855,
            21.026470184091576,
            105.82945733594644,
            21.02651802924891
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919998469855,
              21.02651802924891
            ],
            [
              105.82945733594644,
              21.026470184091576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P7_Ngách 168/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.3144",
          "diaChiLapD": "Ngách 168/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02651802924891,
          "to_latitude": 21.026470184091576,
          "to_longitude": 105.82945733594644,
          "from_longitude": 105.82919998469855
        }
      },
      {
        "id": 3138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82856647151357,
            21.026267528208315,
            105.82904490038658,
            21.02641682536129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82904490038658,
              21.026267528208315
            ],
            [
              105.82856647151357,
              21.02641682536129
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P6_Ngách 168/1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3145",
          "diaChiLapD": "Ngách 168/1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026267528208315,
          "to_latitude": 21.02641682536129,
          "to_longitude": 105.82856647151357,
          "from_longitude": 105.82904490038658
        }
      },
      {
        "id": 3139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900599260827,
            21.0258359704611,
            105.82967978699435,
            21.026088714147285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900599260827,
              21.025993113261556
            ],
            [
              105.82922505993898,
              21.02593567498287
            ],
            [
              105.8296053250511,
              21.0258359704611
            ],
            [
              105.82967978699435,
              21.026088714147285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P9_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3146",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 93.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025993113261556,
          "to_latitude": 21.026088714147285,
          "to_longitude": 105.82967978699435,
          "from_longitude": 105.82900599260827
        }
      },
      {
        "id": 3140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82845641515078,
            21.026029701213965,
            105.82893221776017,
            21.026165652744226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82845641515078,
              21.026165652744226
            ],
            [
              105.82860383860263,
              21.026123529782815
            ],
            [
              105.82861183754922,
              21.02612124448903
            ],
            [
              105.82893221776017,
              21.026029701213965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P5_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3147",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026165652744226,
          "to_latitude": 21.026029701213965,
          "to_longitude": 105.82893221776017,
          "from_longitude": 105.82845641515078
        }
      },
      {
        "id": 3141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910885945931,
            21.026107153658664,
            105.82968715410604,
            21.026248762615047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82910885945931,
              21.026248762615047
            ],
            [
              105.82968715410604,
              21.026107153658664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P7_Ngách 168/1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3148",
          "diaChiLapD": "Ngách 168/1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026248762615047,
          "to_latitude": 21.026107153658664,
          "to_longitude": 105.82968715410604,
          "from_longitude": 105.82910885945931
        }
      },
      {
        "id": 3142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853953169919,
            21.026302544842917,
            105.82905704022858,
            21.026463688549036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905704022858,
              21.026302544842917
            ],
            [
              105.82853953169919,
              21.026463688549036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P6_Ngách 168/1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3149",
          "diaChiLapD": "Ngách 168/1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026302544842917,
          "to_latitude": 21.026463688549036,
          "to_longitude": 105.82853953169919,
          "from_longitude": 105.82905704022858
        }
      },
      {
        "id": 3143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82921815033765,
            21.0259103678095,
            105.82922505993898,
            21.02593567498287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82921815033765,
              21.0259103678095
            ],
            [
              105.82922148237428,
              21.02592248006018
            ],
            [
              105.82922505993898,
              21.02593567498287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P9_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3150",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0259103678095,
          "to_latitude": 21.02593567498287,
          "to_longitude": 105.82922505993898,
          "from_longitude": 105.82921815033765
        }
      },
      {
        "id": 3144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807140184825,
            21.02704436026138,
            105.82841898860522,
            21.027193659375982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82841898860522,
              21.027193659375982
            ],
            [
              105.82840446700868,
              21.02718808406185
            ],
            [
              105.8283107510153,
              21.027152103590797
            ],
            [
              105.82807140184825,
              21.02704436026138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q2_An Trạch",
          "maTaiSan": "04.O13.DODV.3151",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027193659375982,
          "to_latitude": 21.02704436026138,
          "to_longitude": 105.82807140184825,
          "from_longitude": 105.82841898860522
        }
      },
      {
        "id": 3145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839522224383,
            21.02718808406185,
            105.82840446700868,
            21.027207960292852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839522224383,
              21.027207960292852
            ],
            [
              105.82839659222935,
              21.027205015429985
            ],
            [
              105.82840091132708,
              21.027195728210327
            ],
            [
              105.82840446700868,
              21.02718808406185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q2_An Trạch",
          "maTaiSan": "04.O13.DODV.3152",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027207960292852,
          "to_latitude": 21.02718808406185,
          "to_longitude": 105.82840446700868,
          "from_longitude": 105.82839522224383
        }
      },
      {
        "id": 3146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82907746349265,
            21.027253818729584,
            105.82915383526934,
            21.027358961030888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915383526934,
              21.027358961030888
            ],
            [
              105.82907746349265,
              21.027253818729584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q5_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3153",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027358961030888,
          "to_latitude": 21.027253818729584,
          "to_longitude": 105.82907746349265,
          "from_longitude": 105.82915383526934
        }
      },
      {
        "id": 3147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82912081439869,
            21.026120252946843,
            105.82971199726612,
            21.026284089049003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912081439869,
              21.026284089049003
            ],
            [
              105.82912084129414,
              21.026284081692086
            ],
            [
              105.82971199726612,
              21.026120252946843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P7_Ngách 168/1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3154",
          "diaChiLapD": "Ngách 168/1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026284089049003,
          "to_latitude": 21.026120252946843,
          "to_longitude": 105.82971199726612,
          "from_longitude": 105.82912081439869
        }
      },
      {
        "id": 3148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899676746109,
            21.027559130227356,
            105.82905808096264,
            21.027952556309117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903726023142,
              21.027559130227356
            ],
            [
              105.82901415008332,
              21.0275964132828
            ],
            [
              105.82900283796772,
              21.027626371476988
            ],
            [
              105.82899676746109,
              21.027650341934045
            ],
            [
              105.82900981597929,
              21.027714658342482
            ],
            [
              105.82905808096264,
              21.027952556309117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F1_Ngõ 74, An Trạch",
          "maTaiSan": "04.O13.DODV.3155",
          "diaChiLapD": "Ngõ 74, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027559130227356,
          "to_latitude": 21.027952556309117,
          "to_longitude": 105.82905808096264,
          "from_longitude": 105.82903726023142
        }
      },
      {
        "id": 3149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884552842053,
            21.027952556309117,
            105.82905808096264,
            21.027990528916668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905808096264,
              21.027952556309117
            ],
            [
              105.82884552842053,
              21.027990528916668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F1_Ngõ 74, An Trạch",
          "maTaiSan": "04.O13.DODV.3156",
          "diaChiLapD": "Ngõ 74, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027952556309117,
          "to_latitude": 21.027990528916668,
          "to_longitude": 105.82884552842053,
          "from_longitude": 105.82905808096264
        }
      },
      {
        "id": 3150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82974938453587,
            21.027969435189828,
            105.8302259962326,
            21.028057934488494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82974938453587,
              21.028057934488494
            ],
            [
              105.8302259962326,
              21.027969435189828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3#_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3157",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028057934488494,
          "to_latitude": 21.027969435189828,
          "to_longitude": 105.8302259962326,
          "from_longitude": 105.82974938453587
        }
      },
      {
        "id": 3151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8301891672888,
            21.027815871808873,
            105.8302259962326,
            21.027969435189828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302259962326,
              21.027969435189828
            ],
            [
              105.8301891672888,
              21.027815871808873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3#_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3158",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027969435189828,
          "to_latitude": 21.027815871808873,
          "to_longitude": 105.8301891672888,
          "from_longitude": 105.8302259962326
        }
      },
      {
        "id": 3152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297118629931,
            21.02781074188152,
            105.83017706269588,
            21.027897345481705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83017706269588,
              21.02781074188152
            ],
            [
              105.82994360471807,
              21.027854202930083
            ],
            [
              105.8298454340442,
              21.0278724791643
            ],
            [
              105.8297118629931,
              21.027897345481705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3159",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02781074188152,
          "to_latitude": 21.027897345481705,
          "to_longitude": 105.8297118629931,
          "from_longitude": 105.83017706269588
        }
      },
      {
        "id": 3153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297118629931,
            21.027897345481705,
            105.82982247960067,
            21.028244721369347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8297118629931,
              21.027897345481705
            ],
            [
              105.82974853323188,
              21.028080368407036
            ],
            [
              105.82975273100743,
              21.028103050462047
            ],
            [
              105.82975562069288,
              21.02811291763348
            ],
            [
              105.82978083651244,
              21.028199009136657
            ],
            [
              105.82979877819493,
              21.028244721369347
            ],
            [
              105.82982047957948,
              21.028240929410966
            ],
            [
              105.82982247960067,
              21.028240579135414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3160",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027897345481705,
          "to_latitude": 21.028240579135414,
          "to_longitude": 105.82982247960067,
          "from_longitude": 105.8297118629931
        }
      },
      {
        "id": 3154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961329625716,
            21.027886135053453,
            105.82982305773236,
            21.02824832501492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982305773236,
              21.028241961859898
            ],
            [
              105.82982119950398,
              21.028242380088546
            ],
            [
              105.82979475093232,
              21.02824832501492
            ],
            [
              105.82977724821743,
              21.028200231547498
            ],
            [
              105.82975090684384,
              21.028112924376934
            ],
            [
              105.82974958483392,
              21.02810854386821
            ],
            [
              105.82969931125216,
              21.028108807236865
            ],
            [
              105.82961329625716,
              21.027886135053453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3161",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028241961859898,
          "to_latitude": 21.027886135053453,
          "to_longitude": 105.82961329625716,
          "from_longitude": 105.82982305773236
        }
      },
      {
        "id": 3155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82979867978959,
            21.0282604849164,
            105.82991090580363,
            21.02854256555154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82979867978959,
              21.0282604849164
            ],
            [
              105.8298651881885,
              21.02845514023579
            ],
            [
              105.82988878474383,
              21.02854256555154
            ],
            [
              105.8299057680595,
              21.028539055832717
            ],
            [
              105.82991090580363,
              21.028538287404327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3162",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0282604849164,
          "to_latitude": 21.028538287404327,
          "to_longitude": 105.82991090580363,
          "from_longitude": 105.82979867978959
        }
      },
      {
        "id": 3156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82989633401232,
            21.028825076376563,
            105.82990091476994,
            21.02896310865222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82990091476994,
              21.028825076376563
            ],
            [
              105.82989870779559,
              21.02889143346772
            ],
            [
              105.82989633401232,
              21.02896310865222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3163",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028825076376563,
          "to_latitude": 21.02896310865222,
          "to_longitude": 105.82989633401232,
          "from_longitude": 105.82990091476994
        }
      },
      {
        "id": 3157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983288117694,
            21.028258867437422,
            105.82993443948459,
            21.028957169351635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82983288117694,
              21.028258867437422
            ],
            [
              105.82986429128614,
              21.028358993701428
            ],
            [
              105.82989610587897,
              21.028460406883926
            ],
            [
              105.82991813333845,
              21.028530619886563
            ],
            [
              105.82991977074224,
              21.02853584065902
            ],
            [
              105.82992860112535,
              21.028563988086628
            ],
            [
              105.8299300684082,
              21.02860937335246
            ],
            [
              105.8299332749302,
              21.028627115086856
            ],
            [
              105.82993443948459,
              21.028644755690895
            ],
            [
              105.82993108299713,
              21.028801825569058
            ],
            [
              105.82993017267019,
              21.028885526361755
            ],
            [
              105.82992939856794,
              21.028957169351635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3164",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028258867437422,
          "to_latitude": 21.028957169351635,
          "to_longitude": 105.82992939856794,
          "from_longitude": 105.82983288117694
        }
      },
      {
        "id": 3158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82879002425817,
            21.027714658342482,
            105.82900981597929,
            21.027743522824387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900981597929,
              21.027714658342482
            ],
            [
              105.82879002425817,
              21.027743522824387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F1_Ngõ 74, An Trạch",
          "maTaiSan": "04.O13.DODV.3165",
          "diaChiLapD": "Ngõ 74, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027714658342482,
          "to_latitude": 21.027743522824387,
          "to_longitude": 105.82879002425817,
          "from_longitude": 105.82900981597929
        }
      },
      {
        "id": 3159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83115745432343,
            21.02633064202968,
            105.83130067753531,
            21.026723018211342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83130067753531,
              21.02633064202968
            ],
            [
              105.8313005504237,
              21.026363233683863
            ],
            [
              105.83126639841309,
              21.026370550548073
            ],
            [
              105.83116562408314,
              21.026393740233107
            ],
            [
              105.83118362602157,
              21.026473190156416
            ],
            [
              105.83117944257299,
              21.026511071948924
            ],
            [
              105.83117732659242,
              21.026530236059997
            ],
            [
              105.83117530573425,
              21.02653792785763
            ],
            [
              105.83115745432343,
              21.026605870716523
            ],
            [
              105.83120165026624,
              21.02669008085736
            ],
            [
              105.83121894125321,
              21.026723018211342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S8_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3166",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02633064202968,
          "to_latitude": 21.026723018211342,
          "to_longitude": 105.83121894125321,
          "from_longitude": 105.83130067753531
        }
      },
      {
        "id": 3160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82816541475478,
            21.0271837342392,
            105.8289669139701,
            21.027539310491488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289669139701,
              21.027539310491488
            ],
            [
              105.82888863632535,
              21.02749729471332
            ],
            [
              105.82886300338065,
              21.027474313370597
            ],
            [
              105.8288546676615,
              21.027466855431918
            ],
            [
              105.82868621727314,
              21.027382130214377
            ],
            [
              105.82816541475478,
              21.0271837342392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U3#_An Trạch",
          "maTaiSan": "04.O13.DODV.3167",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027539310491488,
          "to_latitude": 21.0271837342392,
          "to_longitude": 105.82816541475478,
          "from_longitude": 105.8289669139701
        }
      },
      {
        "id": 3161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82894415500758,
            21.02597693807301,
            105.82929442048784,
            21.026884666222195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82894415500758,
              21.02597693807301
            ],
            [
              105.82895129788102,
              21.025997466064016
            ],
            [
              105.82904490038658,
              21.026267528208315
            ],
            [
              105.82905704022858,
              21.026302544842917
            ],
            [
              105.82910957940614,
              21.026286400079343
            ],
            [
              105.82927284201581,
              21.026781435361805
            ],
            [
              105.82929442048784,
              21.026884666222195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P6_Ngách 168/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.3168",
          "diaChiLapD": "Ngách 168/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02597693807301,
          "to_latitude": 21.026884666222195,
          "to_longitude": 105.82929442048784,
          "from_longitude": 105.82894415500758
        }
      },
      {
        "id": 3162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897266225658,
            21.02597001228636,
            105.82928003251638,
            21.026754560986184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897266225658,
              21.02597001228636
            ],
            [
              105.82897979857758,
              21.025990398501794
            ],
            [
              105.82907343204353,
              21.02625891867426
            ],
            [
              105.82910885945931,
              21.026248762615047
            ],
            [
              105.82912081439869,
              21.026284089049003
            ],
            [
              105.82919998469855,
              21.02651802924891
            ],
            [
              105.82928003251638,
              21.026754560986184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P7_Ngách 168/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.3169",
          "diaChiLapD": "Ngách 168/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 113,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02597001228636,
          "to_latitude": 21.026754560986184,
          "to_longitude": 105.82928003251638,
          "from_longitude": 105.82897266225658
        }
      },
      {
        "id": 3163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82927284201581,
            21.026753999691785,
            105.82943012686054,
            21.026781435361805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82927284201581,
              21.026781435361805
            ],
            [
              105.82943012686054,
              21.026753999691785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P6_Ngách 168/23, Hào Nam",
          "maTaiSan": "04.O13.DODV.3170",
          "diaChiLapD": "Ngách 168/23, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026781435361805,
          "to_latitude": 21.026753999691785,
          "to_longitude": 105.82943012686054,
          "from_longitude": 105.82927284201581
        }
      },
      {
        "id": 3164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886269126895,
            21.02701406409229,
            105.8292584577478,
            21.027412299508917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886269126895,
              21.027412299508917
            ],
            [
              105.82886620790443,
              21.027407514246846
            ],
            [
              105.8288730681987,
              21.027398151404046
            ],
            [
              105.82887817360924,
              21.02739119336892
            ],
            [
              105.8289679770808,
              21.027268815817816
            ],
            [
              105.82888176735806,
              21.02721367329985
            ],
            [
              105.8289219658443,
              21.027118483445285
            ],
            [
              105.8292584577478,
              21.02701406409229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U3_Ngõ 127, An Trạch",
          "maTaiSan": "04.O13.DODV.3171",
          "diaChiLapD": "Ngõ 127, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027412299508917,
          "to_latitude": 21.02701406409229,
          "to_longitude": 105.8292584577478,
          "from_longitude": 105.82886269126895
        }
      },
      {
        "id": 3165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82923758787682,
            21.02695145499707,
            105.82928144320422,
            21.027080170087153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82928144320422,
              21.027080170087153
            ],
            [
              105.8292584577478,
              21.02701406409229
            ],
            [
              105.82923758787682,
              21.02695145499707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U3_Ngõ 127, An Trạch",
          "maTaiSan": "04.O13.DODV.3172",
          "diaChiLapD": "Ngõ 127, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027080170087153,
          "to_latitude": 21.02695145499707,
          "to_longitude": 105.82923758787682,
          "from_longitude": 105.82928144320422
        }
      },
      {
        "id": 3166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83000708049113,
            21.025871920250996,
            105.8302895043124,
            21.026863512882905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302895043124,
              21.026863512882905
            ],
            [
              105.83028897262567,
              21.02686183096581
            ],
            [
              105.83022006648429,
              21.026643259262904
            ],
            [
              105.83013006809438,
              21.02638655790469
            ],
            [
              105.83011441554564,
              21.02631780772103
            ],
            [
              105.83009961767297,
              21.026219421285088
            ],
            [
              105.83000708049113,
              21.025871920250996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S2_Ngách 33, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3173",
          "diaChiLapD": "Ngách 33, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 108,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026863512882905,
          "to_latitude": 21.025871920250996,
          "to_longitude": 105.83000708049113,
          "from_longitude": 105.8302895043124
        }
      },
      {
        "id": 3167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100615066523,
            21.026110381450035,
            105.831136852346,
            21.026419825219715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100615066523,
              21.026419825219715
            ],
            [
              105.83112355604256,
              21.026382030505243
            ],
            [
              105.83112492734169,
              21.026381589352535
            ],
            [
              105.831136852346,
              21.026374297149598
            ],
            [
              105.83111674036833,
              21.026265623781335
            ],
            [
              105.83109114483088,
              21.026194055459083
            ],
            [
              105.8310486827242,
              21.026110381450035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3174",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026419825219715,
          "to_latitude": 21.026110381450035,
          "to_longitude": 105.8310486827242,
          "from_longitude": 105.83100615066523
        }
      },
      {
        "id": 3168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83106323788014,
            21.026044399042927,
            105.83122944307821,
            21.0263524345215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114019724296,
              21.0263524345215
            ],
            [
              105.83113147477323,
              21.026255619015192
            ],
            [
              105.8311085431703,
              21.02618720076692
            ],
            [
              105.83106360469178,
              21.026095688040247
            ],
            [
              105.83106323788014,
              21.02609494196326
            ],
            [
              105.83122944307821,
              21.026044399042927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S9_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3175",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0263524345215,
          "to_latitude": 21.026044399042927,
          "to_longitude": 105.83122944307821,
          "from_longitude": 105.83114019724296
        }
      },
      {
        "id": 3169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83111674036833,
            21.026265370848424,
            105.8311252479048,
            21.026265623781335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311252479048,
              21.026265370848424
            ],
            [
              105.83112131585357,
              21.026265484000707
            ],
            [
              105.83111674036833,
              21.026265623781335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3176",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026265370848424,
          "to_latitude": 21.026265623781335,
          "to_longitude": 105.83111674036833,
          "from_longitude": 105.8311252479048
        }
      },
      {
        "id": 3170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098084153501,
            21.02587219085919,
            105.83099554305953,
            21.02587614746368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83099554305953,
              21.02587219085919
            ],
            [
              105.83098943382271,
              21.025873832966585
            ],
            [
              105.83098084153501,
              21.02587614746368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3177",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02587219085919,
          "to_latitude": 21.02587614746368,
          "to_longitude": 105.83098084153501,
          "from_longitude": 105.83099554305953
        }
      },
      {
        "id": 3171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83101272198316,
            21.025754696827093,
            105.83126461700152,
            21.026089348961428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310605142243,
              21.026089348961428
            ],
            [
              105.83104349360256,
              21.026049283826456
            ],
            [
              105.83102655606156,
              21.02597838943778
            ],
            [
              105.83102140902476,
              21.025929219584363
            ],
            [
              105.83101272198316,
              21.025846237672145
            ],
            [
              105.83105446178563,
              21.02582982805667
            ],
            [
              105.83111150122016,
              21.025808724572723
            ],
            [
              105.83111473653156,
              21.025807527344224
            ],
            [
              105.8311604552692,
              21.02579009748534
            ],
            [
              105.83126461700152,
              21.025754696827093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3178",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026089348961428,
          "to_latitude": 21.025754696827093,
          "to_longitude": 105.83126461700152,
          "from_longitude": 105.8310605142243
        }
      },
      {
        "id": 3172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83111150122016,
            21.025808724572723,
            105.83120318313269,
            21.025967688316054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83111150122016,
              21.025808724572723
            ],
            [
              105.83113829152607,
              21.025878388480432
            ],
            [
              105.83116506884289,
              21.025887577979624
            ],
            [
              105.83120318313269,
              21.025967688316054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3179",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025808724572723,
          "to_latitude": 21.025967688316054,
          "to_longitude": 105.83120318313269,
          "from_longitude": 105.83111150122016
        }
      },
      {
        "id": 3173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83067072858435,
            21.02509560309244,
            105.83085301744512,
            21.02549516430978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83085301744512,
              21.02549516430978
            ],
            [
              105.83085185663477,
              21.025492792694862
            ],
            [
              105.83081019537894,
              21.025407578313153
            ],
            [
              105.83075544970224,
              21.025295572269766
            ],
            [
              105.83067072858435,
              21.02509560309244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3180",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02549516430978,
          "to_latitude": 21.02509560309244,
          "to_longitude": 105.83067072858435,
          "from_longitude": 105.83085301744512
        }
      },
      {
        "id": 3174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.830874678657,
            21.025511973161382,
            105.83121563635447,
            21.025646935599912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83121563635447,
              21.025620522940464
            ],
            [
              105.83108718041524,
              21.025639282968967
            ],
            [
              105.83100685722911,
              21.025646935599912
            ],
            [
              105.83097102297596,
              21.025642304643124
            ],
            [
              105.83093284102986,
              21.025584488858723
            ],
            [
              105.83093008795512,
              21.025581742960412
            ],
            [
              105.83089614658347,
              21.025547887952417
            ],
            [
              105.830874678657,
              21.025511973161382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T5_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3181",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025620522940464,
          "to_latitude": 21.025511973161382,
          "to_longitude": 105.830874678657,
          "from_longitude": 105.83121563635447
        }
      },
      {
        "id": 3175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83041215906677,
            21.025525832779326,
            105.83079617731939,
            21.025611291019093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83079617731939,
              21.025525832779326
            ],
            [
              105.83056916739669,
              21.025611291019093
            ],
            [
              105.8305587965427,
              21.025611030959674
            ],
            [
              105.83055089452287,
              21.02561083200356
            ],
            [
              105.83051681473356,
              21.02560997689156
            ],
            [
              105.83042168401951,
              21.025601062362167
            ],
            [
              105.83041215906677,
              21.025604677511428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T7_Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3182",
          "diaChiLapD": "Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025525832779326,
          "to_latitude": 21.025604677511428,
          "to_longitude": 105.83041215906677,
          "from_longitude": 105.83079617731939
        }
      },
      {
        "id": 3176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83027707052659,
            21.02613610703035,
            105.83061090154054,
            21.026408470539096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83027707052659,
              21.026408470539096
            ],
            [
              105.83038832587638,
              21.026357803796035
            ],
            [
              105.83056554256261,
              21.02626434253016
            ],
            [
              105.83060721215939,
              21.0261477233517
            ],
            [
              105.83061090154054,
              21.02613610703035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U1_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3183",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026408470539096,
          "to_latitude": 21.02613610703035,
          "to_longitude": 105.83061090154054,
          "from_longitude": 105.83027707052659
        }
      },
      {
        "id": 3177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83063094152247,
            21.026091405786516,
            105.83097656091945,
            21.026137841009795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83063094152247,
              21.02613358579889
            ],
            [
              105.83064961512349,
              21.02612944182273
            ],
            [
              105.83067825533037,
              21.026137841009795
            ],
            [
              105.83076311956629,
              21.02611857617081
            ],
            [
              105.83077559455008,
              21.026115745943326
            ],
            [
              105.83081424122474,
              21.026116972494748
            ],
            [
              105.83091173554367,
              21.026106378249537
            ],
            [
              105.83097656091945,
              21.026091405786516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U2_Ngách 20, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3184",
          "diaChiLapD": "Ngách 20, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02613358579889,
          "to_latitude": 21.026091405786516,
          "to_longitude": 105.83097656091945,
          "from_longitude": 105.83063094152247
        }
      },
      {
        "id": 3178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035278759789,
            21.025633402715965,
            105.83057593549067,
            21.026060911879757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83057593549067,
              21.026060911879757
            ],
            [
              105.83056018219531,
              21.026020194697587
            ],
            [
              105.83055186391852,
              21.025998694329196
            ],
            [
              105.83056603423543,
              21.02595389565412
            ],
            [
              105.83049684330862,
              21.025809803494752
            ],
            [
              105.83047579418516,
              21.02579043638518
            ],
            [
              105.83036528224211,
              21.025648890714734
            ],
            [
              105.83035278759789,
              21.025633402715965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T9_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3185",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026060911879757,
          "to_latitude": 21.025633402715965,
          "to_longitude": 105.83035278759789,
          "from_longitude": 105.83057593549067
        }
      },
      {
        "id": 3179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306771499217,
            21.026451199252637,
            105.83086221307484,
            21.02674661500008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83086221307484,
              21.02674661500008
            ],
            [
              105.83085477973663,
              21.0267370870782
            ],
            [
              105.83085584728373,
              21.026721241118658
            ],
            [
              105.83075611097402,
              21.02657627678337
            ],
            [
              105.8306771499217,
              21.026451199252637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S6_Ngách 11, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3186",
          "diaChiLapD": "Ngách 11, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02674661500008,
          "to_latitude": 21.026451199252637,
          "to_longitude": 105.8306771499217,
          "from_longitude": 105.83086221307484
        }
      },
      {
        "id": 3180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83038735956274,
            21.026489609959643,
            105.83056486181067,
            21.026904322660084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83039118197027,
              21.026904322660084
            ],
            [
              105.83039299578411,
              21.026901968129998
            ],
            [
              105.83041787054212,
              21.02686968014159
            ],
            [
              105.83038735956274,
              21.026745354005474
            ],
            [
              105.83039279833034,
              21.026693349607537
            ],
            [
              105.83043073493259,
              21.026649551275725
            ],
            [
              105.83041684348753,
              21.026554027285147
            ],
            [
              105.8304752184238,
              21.02652386988821
            ],
            [
              105.83053350232797,
              21.02650159535355
            ],
            [
              105.83056486181067,
              21.026489609959643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S3_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3187",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026904322660084,
          "to_latitude": 21.026489609959643,
          "to_longitude": 105.83056486181067,
          "from_longitude": 105.83039118197027
        }
      },
      {
        "id": 3181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83053350232797,
            21.02650159535355,
            105.83054930120957,
            21.026635440139977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83053350232797,
              21.02650159535355
            ],
            [
              105.83054930120957,
              21.026635440139977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S3_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3188",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02650159535355,
          "to_latitude": 21.026635440139977,
          "to_longitude": 105.83054930120957,
          "from_longitude": 105.83053350232797
        }
      },
      {
        "id": 3182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82960609769557,
            21.026274541179973,
            105.8298551991982,
            21.02669559146527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960609769557,
              21.026686755239005
            ],
            [
              105.8296544230707,
              21.02669559146527
            ],
            [
              105.82970414994635,
              21.026480180960224
            ],
            [
              105.82976690405886,
              21.026492778235987
            ],
            [
              105.82978667090974,
              21.026392237826233
            ],
            [
              105.82980797995758,
              21.026394605973806
            ],
            [
              105.82981257515485,
              21.026373869005486
            ],
            [
              105.8298246022328,
              21.0263313036799
            ],
            [
              105.8298295538418,
              21.026313780450405
            ],
            [
              105.8298551991982,
              21.026274541179973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S1_Ngách 39, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3189",
          "diaChiLapD": "Ngách 39, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026686755239005,
          "to_latitude": 21.026274541179973,
          "to_longitude": 105.8298551991982,
          "from_longitude": 105.82960609769557
        }
      },
      {
        "id": 3183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82957866030901,
            21.026798673094365,
            105.83004805993102,
            21.027137177999233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83004805993102,
              21.026798673094365
            ],
            [
              105.82985046206167,
              21.026851287437527
            ],
            [
              105.82976385921697,
              21.026888678996038
            ],
            [
              105.82972339308556,
              21.02691785097173
            ],
            [
              105.8296864789366,
              21.02694446212878
            ],
            [
              105.82966174162559,
              21.027003701555685
            ],
            [
              105.82965547435413,
              21.027017995849686
            ],
            [
              105.8296218379554,
              21.02709470820572
            ],
            [
              105.82957866030901,
              21.027137177999233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q9_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3190",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026798673094365,
          "to_latitude": 21.027137177999233,
          "to_longitude": 105.82957866030901,
          "from_longitude": 105.83004805993102
        }
      },
      {
        "id": 3184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82957717577604,
            21.027148797995423,
            105.82971566438059,
            21.027391836623462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82957717577604,
              21.027148797995423
            ],
            [
              105.82958293355573,
              21.02715890588939
            ],
            [
              105.82971566438059,
              21.027391836623462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q8_Ngách 6, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3191",
          "diaChiLapD": "Ngách 6, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027148797995423,
          "to_latitude": 21.027391836623462,
          "to_longitude": 105.82971566438059,
          "from_longitude": 105.82957717577604
        }
      },
      {
        "id": 3185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924722273718,
            21.028977861046734,
            105.8298739332107,
            21.029085242698162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924722273718,
              21.029085242698162
            ],
            [
              105.82959310639615,
              21.02902588108576
            ],
            [
              105.82969399801753,
              21.02900856808608
            ],
            [
              105.8298739332107,
              21.028977861046734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9_Cát Linh",
          "maTaiSan": "04.O13.DODV.3192",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029085242698162,
          "to_latitude": 21.028977861046734,
          "to_longitude": 105.8298739332107,
          "from_longitude": 105.82924722273718
        }
      },
      {
        "id": 3186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959310639615,
            21.02902588108576,
            105.8295974003724,
            21.029049951831304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959310639615,
              21.02902588108576
            ],
            [
              105.82959566922193,
              21.029040133199626
            ],
            [
              105.8295974003724,
              21.029049951831304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9_Cát Linh",
          "maTaiSan": "04.O13.DODV.3193",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02902588108576,
          "to_latitude": 21.029049951831304,
          "to_longitude": 105.8295974003724,
          "from_longitude": 105.82959310639615
        }
      },
      {
        "id": 3187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141009329712,
            21.028628422997663,
            105.83170979006381,
            21.02868783853273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141009329712,
              21.02868783853273
            ],
            [
              105.83142767221787,
              21.028684353750236
            ],
            [
              105.83170979006381,
              21.028628422997663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1*_Cát Linh",
          "maTaiSan": "04.O13.DODV.3194",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02868783853273,
          "to_latitude": 21.028628422997663,
          "to_longitude": 105.83170979006381,
          "from_longitude": 105.83141009329712
        }
      },
      {
        "id": 3188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128758460528,
            21.028058244468056,
            105.8315258512166,
            21.028122483758708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128758460528,
              21.028122483758708
            ],
            [
              105.8315258512166,
              21.028070269499263
            ],
            [
              105.83152353435258,
              21.028058244468056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1*_Ngõ 21B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3195",
          "diaChiLapD": "Ngõ 21B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028122483758708,
          "to_latitude": 21.028058244468056,
          "to_longitude": 105.83152353435258,
          "from_longitude": 105.83128758460528
        }
      },
      {
        "id": 3189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83054868595626,
            21.028035575391353,
            105.83086680804375,
            21.028092799677342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83054868595626,
              21.028092799677342
            ],
            [
              105.83086680804375,
              21.028035575391353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngách 25B/30, Cát Linh",
          "maTaiSan": "04.O13.DODV.3196",
          "diaChiLapD": "Ngách 25B/30, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028092799677342,
          "to_latitude": 21.028035575391353,
          "to_longitude": 105.83086680804375,
          "from_longitude": 105.83054868595626
        }
      },
      {
        "id": 3190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83074132248892,
            21.027869094755264,
            105.8307667492156,
            21.027953745330393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307667492156,
              21.027953745330393
            ],
            [
              105.83074132248892,
              21.027869094755264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngách 25B/45, Cát Linh",
          "maTaiSan": "04.O13.DODV.3197",
          "diaChiLapD": "Ngách 25B/45, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027953745330393,
          "to_latitude": 21.027869094755264,
          "to_longitude": 105.83074132248892,
          "from_longitude": 105.8307667492156
        }
      },
      {
        "id": 3191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83088793749715,
            21.02790501048424,
            105.830916187864,
            21.02800684823372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830916187864,
              21.02800684823372
            ],
            [
              105.83090881674009,
              21.027962912600987
            ],
            [
              105.83088793749715,
              21.02790501048424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngách 25B/45, Cát Linh",
          "maTaiSan": "04.O13.DODV.3198",
          "diaChiLapD": "Ngách 25B/45, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02800684823372,
          "to_latitude": 21.02790501048424,
          "to_longitude": 105.83088793749715,
          "from_longitude": 105.830916187864
        }
      },
      {
        "id": 3192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052430529617,
            21.02800715402557,
            105.83066358689466,
            21.028853938008606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052430529617,
              21.02800715402557
            ],
            [
              105.83055452153052,
              21.02815651530395
            ],
            [
              105.83054752318496,
              21.02834044317542
            ],
            [
              105.83064058190664,
              21.028752181184068
            ],
            [
              105.83066358689466,
              21.028853938008606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6*_Ngõ 25B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3199",
          "diaChiLapD": "Ngõ 25B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02800715402557,
          "to_latitude": 21.028853938008606,
          "to_longitude": 105.83066358689466,
          "from_longitude": 105.83052430529617
        }
      },
      {
        "id": 3193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034837459489,
            21.027963398441717,
            105.83036779302455,
            21.028036533410702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83036779302455,
              21.028036533410702
            ],
            [
              105.83034837459489,
              21.027963398441717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6*_Ngõ 25B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3200",
          "diaChiLapD": "Ngõ 25B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028036533410702,
          "to_latitude": 21.027963398441717,
          "to_longitude": 105.83034837459489,
          "from_longitude": 105.83036779302455
        }
      },
      {
        "id": 3194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83030069678163,
            21.028853266308367,
            105.83055660293925,
            21.028900320588075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83055660293925,
              21.028871757334553
            ],
            [
              105.83055017710733,
              21.028853266308367
            ],
            [
              105.83030069678163,
              21.028900320588075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7*_Cát Linh",
          "maTaiSan": "04.O13.DODV.3201",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028871757334553,
          "to_latitude": 21.028900320588075,
          "to_longitude": 105.83030069678163,
          "from_longitude": 105.83055660293925
        }
      },
      {
        "id": 3195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091204338452,
            21.02873439636237,
            105.8311800195834,
            21.028809599395757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83091709273587,
              21.028809599395757
            ],
            [
              105.83091353019933,
              21.028792040971354
            ],
            [
              105.83091204338452,
              21.028787093150648
            ],
            [
              105.8311800195834,
              21.02873439636237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4*_Cát Linh",
          "maTaiSan": "04.O13.DODV.3202",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028809599395757,
          "to_latitude": 21.02873439636237,
          "to_longitude": 105.8311800195834,
          "from_longitude": 105.83091709273587
        }
      },
      {
        "id": 3196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83112059512781,
            21.02787513473748,
            105.83128660675978,
            21.028736828865767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128660675978,
              21.028736828865767
            ],
            [
              105.83128383399453,
              21.028723085419404
            ],
            [
              105.83126601828968,
              21.028634783032704
            ],
            [
              105.83119737139315,
              21.028294405411923
            ],
            [
              105.83119969685114,
              21.028254723550102
            ],
            [
              105.83116934718284,
              21.028106227444333
            ],
            [
              105.83112059512781,
              21.02787513473748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2*_Ngõ 23, Cát Linh",
          "maTaiSan": "04.O13.DODV.3203",
          "diaChiLapD": "Ngõ 23, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028736828865767,
          "to_latitude": 21.02787513473748,
          "to_longitude": 105.83112059512781,
          "from_longitude": 105.83128660675978
        }
      },
      {
        "id": 3197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052107460682,
            21.02790154317692,
            105.83067720041021,
            21.02885167034876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067720041021,
              21.02885167034876
            ],
            [
              105.83065436103882,
              21.028749594782443
            ],
            [
              105.83055755701328,
              21.028316825441294
            ],
            [
              105.83056151714665,
              21.028203361169023
            ],
            [
              105.83054868595626,
              21.028092799677342
            ],
            [
              105.83053886459955,
              21.027999713511736
            ],
            [
              105.83052107460682,
              21.02790154317692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5*_Ngõ 25B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3204",
          "diaChiLapD": "Ngõ 25B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02885167034876,
          "to_latitude": 21.02790154317692,
          "to_longitude": 105.83052107460682,
          "from_longitude": 105.83067720041021
        }
      },
      {
        "id": 3198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83055755701328,
            21.028235071716487,
            105.83096137582731,
            21.028316825441294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83055755701328,
              21.028316825441294
            ],
            [
              105.83096137582731,
              21.028235071716487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngõ 25B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3205",
          "diaChiLapD": "Ngõ 25B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028316825441294,
          "to_latitude": 21.028235071716487,
          "to_longitude": 105.83096137582731,
          "from_longitude": 105.83055755701328
        }
      },
      {
        "id": 3199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83056151714665,
            21.028123550845777,
            105.83095523851065,
            21.028203361169023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83056151714665,
              21.028203361169023
            ],
            [
              105.83095523851065,
              21.028123550845777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngách 25B/1, Cát Linh",
          "maTaiSan": "04.O13.DODV.3206",
          "diaChiLapD": "Ngách 25B/1, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028203361169023,
          "to_latitude": 21.028123550845777,
          "to_longitude": 105.83095523851065,
          "from_longitude": 105.83056151714665
        }
      },
      {
        "id": 3200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8311327281732,
            21.02862994060213,
            105.83125165732702,
            21.02865053105906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83125165732702,
              21.02862994060213
            ],
            [
              105.8311327281732,
              21.02865053105906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3*_Ngách 23/2, Cát Linh",
          "maTaiSan": "04.O13.DODV.3207",
          "diaChiLapD": "Ngách 23/2, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02862994060213,
          "to_latitude": 21.02865053105906,
          "to_longitude": 105.8311327281732,
          "from_longitude": 105.83125165732702
        }
      },
      {
        "id": 3201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83101505863347,
            21.02797899378786,
            105.8311327281732,
            21.02865053105906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311327281732,
              21.02865053105906
            ],
            [
              105.8311008154162,
              21.02850745380223
            ],
            [
              105.83106536185254,
              21.028466981103193
            ],
            [
              105.83105505400805,
              21.028352322050313
            ],
            [
              105.83107856273188,
              21.02827716731653
            ],
            [
              105.8310519408669,
              21.028112163712397
            ],
            [
              105.83103401121029,
              21.028091937831704
            ],
            [
              105.83101505863347,
              21.02797899378786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3*_Ngách 23/2, Cát Linh",
          "maTaiSan": "04.O13.DODV.3208",
          "diaChiLapD": "Ngách 23/2, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02865053105906,
          "to_latitude": 21.02797899378786,
          "to_longitude": 105.83101505863347,
          "from_longitude": 105.8311327281732
        }
      },
      {
        "id": 3202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83005137572262,
            21.028900320588075,
            105.83030069678163,
            21.028945243023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005137572262,
              21.028945243023
            ],
            [
              105.83030069678163,
              21.028900320588075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7*_Cát Linh",
          "maTaiSan": "04.O13.DODV.3209",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028945243023,
          "to_latitude": 21.028900320588075,
          "to_longitude": 105.83030069678163,
          "from_longitude": 105.83005137572262
        }
      },
      {
        "id": 3203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83053886459955,
            21.02786127479311,
            105.83107013751561,
            21.027999713511736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83053886459955,
              21.027999713511736
            ],
            [
              105.8307667492156,
              21.027953745330393
            ],
            [
              105.83085244949086,
              21.027925629258977
            ],
            [
              105.83088793749715,
              21.02790501048424
            ],
            [
              105.83090589246346,
              21.027894579031344
            ],
            [
              105.83107013751561,
              21.02786127479311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngách 25B/45, Cát Linh",
          "maTaiSan": "04.O13.DODV.3210",
          "diaChiLapD": "Ngách 25B/45, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027999713511736,
          "to_latitude": 21.02786127479311,
          "to_longitude": 105.83107013751561,
          "from_longitude": 105.83053886459955
        }
      },
      {
        "id": 3204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83123931763429,
            21.02791002855158,
            105.83141009329712,
            21.028714296513183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83123931763429,
              21.02791002855158
            ],
            [
              105.83124385543661,
              21.027924942982906
            ],
            [
              105.83128758460528,
              21.028122483758708
            ],
            [
              105.83132493806116,
              21.028283673829694
            ],
            [
              105.8313332211048,
              21.02831941815985
            ],
            [
              105.83139400502564,
              21.028610758399502
            ],
            [
              105.83141009329712,
              21.02868783853273
            ],
            [
              105.83139448499854,
              21.028702149654055
            ],
            [
              105.83139479191564,
              21.028714296513183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1*_Ngõ 21B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3211",
          "diaChiLapD": "Ngõ 21B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 112,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02791002855158,
          "to_latitude": 21.028714296513183,
          "to_longitude": 105.83139479191564,
          "from_longitude": 105.83123931763429
        }
      },
      {
        "id": 3205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82956094007878,
            21.026183652874174,
            105.8298246022328,
            21.0263313036799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298246022328,
              21.0263313036799
            ],
            [
              105.82956373197112,
              21.026285727992878
            ],
            [
              105.82956094007878,
              21.026183652874174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S1_Ngách 168/1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3212",
          "diaChiLapD": "Ngách 168/1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0263313036799,
          "to_latitude": 21.026183652874174,
          "to_longitude": 105.82956094007878,
          "from_longitude": 105.8298246022328
        }
      },
      {
        "id": 3206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8304633961835,
            21.026923148933932,
            105.83046432905836,
            21.02727188928242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83046398053129,
              21.026923148933932
            ],
            [
              105.83046397109807,
              21.026927697616653
            ],
            [
              105.8304633961835,
              21.027243808085327
            ],
            [
              105.83046432905836,
              21.02727188928242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S4_Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3213",
          "diaChiLapD": "Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026923148933932,
          "to_latitude": 21.02727188928242,
          "to_longitude": 105.83046432905836,
          "from_longitude": 105.83046398053129
        }
      },
      {
        "id": 3207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83097406499917,
            21.02585268410331,
            105.8310321400066,
            21.026067501338712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310321400066,
              21.026067501338712
            ],
            [
              105.83101388745591,
              21.02599262383391
            ],
            [
              105.83100307691151,
              21.02595313712081
            ],
            [
              105.83098084153501,
              21.02587614746368
            ],
            [
              105.83097406499917,
              21.02585268410331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3214",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026067501338712,
          "to_latitude": 21.02585268410331,
          "to_longitude": 105.83097406499917,
          "from_longitude": 105.8310321400066
        }
      },
      {
        "id": 3208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83080730955508,
            21.02585268410331,
            105.83097406499917,
            21.025888281099725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83097406499917,
              21.02585268410331
            ],
            [
              105.83080730955508,
              21.025888281099725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3215",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02585268410331,
          "to_latitude": 21.025888281099725,
          "to_longitude": 105.83080730955508,
          "from_longitude": 105.83097406499917
        }
      },
      {
        "id": 3209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089963601327,
            21.02629479933772,
            105.83100615066523,
            21.026429825279376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100615066523,
              21.026419825219715
            ],
            [
              105.83098313525707,
              21.026429825279376
            ],
            [
              105.83092668590946,
              21.026343782691164
            ],
            [
              105.83089963601327,
              21.02629479933772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T1_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3216",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026419825219715,
          "to_latitude": 21.02629479933772,
          "to_longitude": 105.83089963601327,
          "from_longitude": 105.83100615066523
        }
      },
      {
        "id": 3210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82984989768764,
            21.02563788613779,
            105.8303507903031,
            21.025776832646194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303507903031,
              21.02563788613779
            ],
            [
              105.83034877910487,
              21.02563881724641
            ],
            [
              105.83033000750235,
              21.0256401950908
            ],
            [
              105.83013031392106,
              21.02566237587407
            ],
            [
              105.82987479012269,
              21.025721507008733
            ],
            [
              105.82984989768764,
              21.025776832646194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T8_Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3217",
          "diaChiLapD": "Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02563788613779,
          "to_latitude": 21.025776832646194,
          "to_longitude": 105.82984989768764,
          "from_longitude": 105.8303507903031
        }
      },
      {
        "id": 3211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83037739282727,
            21.02579043638518,
            105.83047579418516,
            21.025848090981498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83037739282727,
              21.025848090981498
            ],
            [
              105.83047174287297,
              21.02579280997153
            ],
            [
              105.83047579418516,
              21.02579043638518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T9_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3218",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025848090981498,
          "to_latitude": 21.02579043638518,
          "to_longitude": 105.83047579418516,
          "from_longitude": 105.83037739282727
        }
      },
      {
        "id": 3212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82887817360924,
            21.02739119336892,
            105.82899275105069,
            21.027465775885894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82887817360924,
              21.02739119336892
            ],
            [
              105.82899275105069,
              21.027465775885894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U3_An Trạch",
          "maTaiSan": "04.O13.DODV.3219",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02739119336892,
          "to_latitude": 21.027465775885894,
          "to_longitude": 105.82899275105069,
          "from_longitude": 105.82887817360924
        }
      },
      {
        "id": 3213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905329215205,
            21.027358961030888,
            105.82915383526934,
            21.027439623234265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915383526934,
              21.027358961030888
            ],
            [
              105.82914711202862,
              21.027364227094093
            ],
            [
              105.82914200470545,
              21.02736822798992
            ],
            [
              105.82913158602918,
              21.027376388840175
            ],
            [
              105.82905329215205,
              21.027439623234265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q5_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3220",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027358961030888,
          "to_latitude": 21.027439623234265,
          "to_longitude": 105.82905329215205,
          "from_longitude": 105.82915383526934
        }
      },
      {
        "id": 3214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915383526934,
            21.027358961030888,
            105.82916888387997,
            21.02737875949254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82916888387997,
              21.02737875949254
            ],
            [
              105.82916816097192,
              21.027377811019004
            ],
            [
              105.82915383526934,
              21.027358961030888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q5_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3221",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02737875949254,
          "to_latitude": 21.027358961030888,
          "to_longitude": 105.82915383526934,
          "from_longitude": 105.82916888387997
        }
      },
      {
        "id": 3215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82914200470545,
            21.02736822798992,
            105.82939532153513,
            21.02754019465717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82939532153513,
              21.02747404100535
            ],
            [
              105.82933049277683,
              21.02750679919293
            ],
            [
              105.82931459864247,
              21.027513627405227
            ],
            [
              105.82925722103198,
              21.02754019465717
            ],
            [
              105.829168088916,
              21.0274014812661
            ],
            [
              105.82914549545377,
              21.027372678316983
            ],
            [
              105.82914200470545,
              21.02736822798992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q5_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3222",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02747404100535,
          "to_latitude": 21.02736822798992,
          "to_longitude": 105.82914200470545,
          "from_longitude": 105.82939532153513
        }
      },
      {
        "id": 3216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124385543661,
            21.027885420952472,
            105.831437591178,
            21.027924942982906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124385543661,
              21.027924942982906
            ],
            [
              105.831437591178,
              21.027885420952472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1*_Ngõ 21B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3223",
          "diaChiLapD": "Ngõ 21B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027924942982906,
          "to_latitude": 21.027885420952472,
          "to_longitude": 105.831437591178,
          "from_longitude": 105.83124385543661
        }
      },
      {
        "id": 3217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110596700259,
            21.027876401197815,
            105.83127699608347,
            21.028738722038554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83127699608347,
              21.028738722038554
            ],
            [
              105.83127171500641,
              21.02872526724065
            ],
            [
              105.83125317809254,
              21.028637192377644
            ],
            [
              105.83125165732702,
              21.02862994060213
            ],
            [
              105.83118644126276,
              21.028293249422223
            ],
            [
              105.83118129944204,
              21.0282266590864
            ],
            [
              105.83116569410087,
              21.028145209474783
            ],
            [
              105.83110596700259,
              21.027876401197815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3*_Ngõ 23, Cát Linh",
          "maTaiSan": "04.O13.DODV.3224",
          "diaChiLapD": "Ngõ 23, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028738722038554,
          "to_latitude": 21.027876401197815,
          "to_longitude": 105.83110596700259,
          "from_longitude": 105.83127699608347
        }
      },
      {
        "id": 3218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83053020037468,
            21.026749631094738,
            105.83123070795783,
            21.027049364522345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83123070795783,
              21.026749631094738
            ],
            [
              105.831224280564,
              21.026752888827794
            ],
            [
              105.831117313307,
              21.02680709928061
            ],
            [
              105.83104523239255,
              21.026843630187134
            ],
            [
              105.8310354432116,
              21.026847835565956
            ],
            [
              105.83089086368985,
              21.02690995134395
            ],
            [
              105.83076592882418,
              21.02696313659858
            ],
            [
              105.83053020037468,
              21.027049364522345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KTBS02_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3225",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026749631094738,
          "to_latitude": 21.027049364522345,
          "to_longitude": 105.83053020037468,
          "from_longitude": 105.83123070795783
        }
      },
      {
        "id": 3219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83076089024992,
            21.026847835565956,
            105.83114039954874,
            21.027207974672812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310354432116,
              21.026847835565956
            ],
            [
              105.83114039954874,
              21.027062844518014
            ],
            [
              105.83111143325308,
              21.02707392142165
            ],
            [
              105.83076089024992,
              21.027207974672812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KTBS02_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3226",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026847835565956,
          "to_latitude": 21.027207974672812,
          "to_longitude": 105.83076089024992,
          "from_longitude": 105.8310354432116
        }
      },
      {
        "id": 3220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83112355604256,
            21.026382030505243,
            105.83115394248708,
            21.026432441768026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83115394248708,
              21.026432441768026
            ],
            [
              105.83112355604256,
              21.026382030505243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3227",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026432441768026,
          "to_latitude": 21.026382030505243,
          "to_longitude": 105.83112355604256,
          "from_longitude": 105.83115394248708
        }
      },
      {
        "id": 3221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026443018983,
            21.021266497015468,
            105.83028298795423,
            21.021296091813042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026443018983,
              21.021266497015468
            ],
            [
              105.83027225348295,
              21.021278926941914
            ],
            [
              105.83028298795423,
              21.021296091813042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F4*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3228",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021266497015468,
          "to_latitude": 21.021296091813042,
          "to_longitude": 105.83028298795423,
          "from_longitude": 105.83026443018983
        }
      },
      {
        "id": 3222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040523361994,
            21.021173446800965,
            105.83040878511862,
            21.02117933384223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040878511862,
              21.02117933384223
            ],
            [
              105.8304070236709,
              21.021176367219564
            ],
            [
              105.83040523361994,
              21.021173446800965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F3*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3229",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02117933384223,
          "to_latitude": 21.021173446800965,
          "to_longitude": 105.83040523361994,
          "from_longitude": 105.83040878511862
        }
      },
      {
        "id": 3223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82932255723502,
            21.02121332409333,
            105.82941690471117,
            21.0212529795763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82941690471117,
              21.02121332409333
            ],
            [
              105.82932255723502,
              21.0212529795763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3230",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02121332409333,
          "to_latitude": 21.0212529795763,
          "to_longitude": 105.82932255723502,
          "from_longitude": 105.82941690471117
        }
      },
      {
        "id": 3224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297129208864,
            21.02061353105813,
            105.8300431452135,
            21.020758106531062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8300431452135,
              21.020754126239748
            ],
            [
              105.83000311192089,
              21.02070443196854
            ],
            [
              105.82996207832781,
              21.02064832876801
            ],
            [
              105.82993599143887,
              21.02061353105813
            ],
            [
              105.82986876519973,
              21.02065490791696
            ],
            [
              105.82984271007503,
              21.02067417165884
            ],
            [
              105.82983581570824,
              21.020678356515628
            ],
            [
              105.82982471530781,
              21.020685094534187
            ],
            [
              105.82981572031807,
              21.02069055415302
            ],
            [
              105.82978691719129,
              21.020710578259806
            ],
            [
              105.82976856685185,
              21.02072261021771
            ],
            [
              105.82972823157587,
              21.02074680024443
            ],
            [
              105.8297129208864,
              21.020758106531062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3231",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020754126239748,
          "to_latitude": 21.020758106531062,
          "to_longitude": 105.8297129208864,
          "from_longitude": 105.8300431452135
        }
      },
      {
        "id": 3225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82976637231774,
            21.020706739520126,
            105.82977434503897,
            21.020717154551857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82976637231774,
              21.020706739520126
            ],
            [
              105.82977204178489,
              21.020714145403783
            ],
            [
              105.82977389932103,
              21.02071657414616
            ],
            [
              105.82977434503897,
              21.020717154551857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3232",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020706739520126,
          "to_latitude": 21.020717154551857,
          "to_longitude": 105.82977434503897,
          "from_longitude": 105.82976637231774
        }
      },
      {
        "id": 3226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82995597183977,
            21.020630984249475,
            105.82996520300497,
            21.020638612767574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82995597183977,
              21.020638612767574
            ],
            [
              105.8299565100026,
              21.02063816756151
            ],
            [
              105.82995779030249,
              21.020637109960763
            ],
            [
              105.82996520300497,
              21.020630984249475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3233",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020638612767574,
          "to_latitude": 21.020630984249475,
          "to_longitude": 105.82996520300497,
          "from_longitude": 105.82995597183977
        }
      },
      {
        "id": 3227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82989548177319,
            21.020386228166217,
            105.83005983255829,
            21.020539974075376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82989548177319,
              21.020539974075376
            ],
            [
              105.83002458515793,
              21.020450023326013
            ],
            [
              105.83000518532538,
              21.020421553503954
            ],
            [
              105.83005983255829,
              21.020386228166217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3234",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020539974075376,
          "to_latitude": 21.020386228166217,
          "to_longitude": 105.83005983255829,
          "from_longitude": 105.82989548177319
        }
      },
      {
        "id": 3228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83013880950914,
            21.021176049321554,
            105.83015166617764,
            21.021179369097712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83013880950914,
              21.021179369097712
            ],
            [
              105.83014060295417,
              21.021178906925947
            ],
            [
              105.83015166617764,
              21.021176049321554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3235",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021179369097712,
          "to_latitude": 21.021176049321554,
          "to_longitude": 105.83015166617764,
          "from_longitude": 105.83013880950914
        }
      },
      {
        "id": 3229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8300377866797,
            21.020940429583685,
            105.83004691327082,
            21.02094366521383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8300377866797,
              21.02094366521383
            ],
            [
              105.83003886965948,
              21.020943281477823
            ],
            [
              105.83004085319943,
              21.020942578121712
            ],
            [
              105.83004691327082,
              21.020940429583685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3236",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02094366521383,
          "to_latitude": 21.020940429583685,
          "to_longitude": 105.83004691327082,
          "from_longitude": 105.8300377866797
        }
      },
      {
        "id": 3230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83000366897322,
            21.020851476962772,
            105.83010047197762,
            21.02105059918542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010047197762,
              21.02105059918542
            ],
            [
              105.83005128032322,
              21.020949412520824
            ],
            [
              105.83004691327082,
              21.020940429583685
            ],
            [
              105.83000366897322,
              21.020851476962772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3237",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02105059918542,
          "to_latitude": 21.020851476962772,
          "to_longitude": 105.83000366897322,
          "from_longitude": 105.83010047197762
        }
      },
      {
        "id": 3231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83009856952692,
            21.02107553313479,
            105.8301031601948,
            21.02107724637528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8301031601948,
              21.02107553313479
            ],
            [
              105.83010170965373,
              21.0210760749229
            ],
            [
              105.8300993663175,
              21.02107694893869
            ],
            [
              105.83009856952692,
              21.02107724637528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3238",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02107553313479,
          "to_latitude": 21.02107724637528,
          "to_longitude": 105.83009856952692,
          "from_longitude": 105.8301031601948
        }
      },
      {
        "id": 3232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83077383737847,
            21.020564292565968,
            105.830817070167,
            21.020584091057522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830817070167,
              21.020564292565968
            ],
            [
              105.83080096246303,
              21.020571669297215
            ],
            [
              105.83078015111667,
              21.020581199707546
            ],
            [
              105.83077383737847,
              21.020584091057522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3239",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020564292565968,
          "to_latitude": 21.020584091057522,
          "to_longitude": 105.83077383737847,
          "from_longitude": 105.830817070167
        }
      },
      {
        "id": 3233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83068721050655,
            21.0203948730654,
            105.83078649706465,
            21.020613292803027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83078649706465,
              21.020613292803027
            ],
            [
              105.83077383737847,
              21.020584091057522
            ],
            [
              105.83077220912561,
              21.020580336175946
            ],
            [
              105.83074647759925,
              21.02052098359839
            ],
            [
              105.83068721050655,
              21.0203948730654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3240",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020613292803027,
          "to_latitude": 21.0203948730654,
          "to_longitude": 105.83068721050655,
          "from_longitude": 105.83078649706465
        }
      },
      {
        "id": 3234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83078649706465,
            21.020613292803027,
            105.83090915036436,
            21.020884173226108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83090915036436,
              21.020884173226108
            ],
            [
              105.83078649706465,
              21.020613292803027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3241",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020884173226108,
          "to_latitude": 21.020613292803027,
          "to_longitude": 105.83078649706465,
          "from_longitude": 105.83090915036436
        }
      },
      {
        "id": 3235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83009584783454,
            21.020855951809448,
            105.83044144439711,
            21.02106538044545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044144439711,
              21.020855951809448
            ],
            [
              105.83025093443695,
              21.020961017311315
            ],
            [
              105.8302103459404,
              21.021010010336443
            ],
            [
              105.830096769825,
              21.021064935172213
            ],
            [
              105.83009584783454,
              21.02106538044545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3242",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020855951809448,
          "to_latitude": 21.02106538044545,
          "to_longitude": 105.83009584783454,
          "from_longitude": 105.83044144439711
        }
      },
      {
        "id": 3236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83044893395966,
            21.020557937302513,
            105.83081414712564,
            21.020733950095156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044893395966,
              21.020733950095156
            ],
            [
              105.83059841097217,
              21.02066245529582
            ],
            [
              105.83077220912561,
              21.020580336175946
            ],
            [
              105.83079981175845,
              21.0205672942777
            ],
            [
              105.83081414712564,
              21.020557937302513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3243",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020733950095156,
          "to_latitude": 21.020557937302513,
          "to_longitude": 105.83081414712564,
          "from_longitude": 105.83044893395966
        }
      },
      {
        "id": 3237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999599371306,
            21.020860743566526,
            105.83016110926508,
            21.02122528859581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83016110926508,
              21.02122528859581
            ],
            [
              105.83013745172764,
              21.02118102772248
            ],
            [
              105.8301058879094,
              21.0210945688334
            ],
            [
              105.83009856952692,
              21.02107724637528
            ],
            [
              105.83008188187864,
              21.021037742181655
            ],
            [
              105.8300794190982,
              21.021032331238725
            ],
            [
              105.83005379650949,
              21.02098185039949
            ],
            [
              105.83003203469242,
              21.020935953549323
            ],
            [
              105.8300145078244,
              21.020896200539788
            ],
            [
              105.82999599371306,
              21.020860743566526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3244",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02122528859581,
          "to_latitude": 21.020860743566526,
          "to_longitude": 105.82999599371306,
          "from_longitude": 105.83016110926508
        }
      },
      {
        "id": 3238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83011909288442,
            21.021082922337197,
            105.8301758099464,
            21.021230639807637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8301758099464,
              21.021230639807637
            ],
            [
              105.83015166617764,
              21.021176049321554
            ],
            [
              105.83013370058477,
              21.021135426856418
            ],
            [
              105.83011909288442,
              21.021082922337197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9*_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3245",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021230639807637,
          "to_latitude": 21.021082922337197,
          "to_longitude": 105.83011909288442,
          "from_longitude": 105.8301758099464
        }
      },
      {
        "id": 3239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83010193146308,
            21.02073696621728,
            105.8301049443359,
            21.020740612283976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010193146308,
              21.02073696621728
            ],
            [
              105.83010321661777,
              21.020738523427603
            ],
            [
              105.8301049443359,
              21.020740612283976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3246",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02073696621728,
          "to_latitude": 21.020740612283976,
          "to_longitude": 105.8301049443359,
          "from_longitude": 105.83010193146308
        }
      },
      {
        "id": 3240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999067689212,
            21.020673858301308,
            105.83022269700231,
            21.020817813944088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83022269700231,
              21.020673858301308
            ],
            [
              105.8301049443359,
              21.020740612283976
            ],
            [
              105.83010267639253,
              21.02074189780227
            ],
            [
              105.82999067689212,
              21.020817813944088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3247",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020673858301308,
          "to_latitude": 21.020817813944088,
          "to_longitude": 105.82999067689212,
          "from_longitude": 105.83022269700231
        }
      },
      {
        "id": 3241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83030838355104,
            21.020456699517702,
            105.83037441126064,
            21.0205461884533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83037441126064,
              21.0205461884533
            ],
            [
              105.83030838355104,
              21.020456699517702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E2*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3248",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0205461884533,
          "to_latitude": 21.020456699517702,
          "to_longitude": 105.83030838355104,
          "from_longitude": 105.83037441126064
        }
      },
      {
        "id": 3242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83006201979944,
            21.02054904543167,
            105.83023671248841,
            21.020660926164688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83023671248841,
              21.020660926164688
            ],
            [
              105.83023526137185,
              21.020659451958885
            ],
            [
              105.83022143981903,
              21.020645383049647
            ],
            [
              105.83014179316048,
              21.02054904543167
            ],
            [
              105.83006201979944,
              21.020605671325278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3249",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020660926164688,
          "to_latitude": 21.020605671325278,
          "to_longitude": 105.83006201979944,
          "from_longitude": 105.83023671248841
        }
      },
      {
        "id": 3243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.830388763937,
            21.020536478267857,
            105.83040309782248,
            21.020554991237976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040309782248,
              21.020554991237976
            ],
            [
              105.83040298834769,
              21.02055485177257
            ],
            [
              105.83040042754155,
              21.020551543116742
            ],
            [
              105.830388763937,
              21.020536478267857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3250",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020554991237976,
          "to_latitude": 21.020536478267857,
          "to_longitude": 105.830388763937,
          "from_longitude": 105.83040309782248
        }
      },
      {
        "id": 3244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023312986127,
            21.020377487881323,
            105.8306270816388,
            21.020641768188785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8306270816388,
              21.020377487881323
            ],
            [
              105.83047685710011,
              21.020476881543708
            ],
            [
              105.830388763937,
              21.020536478267857
            ],
            [
              105.83037441126064,
              21.0205461884533
            ],
            [
              105.83023312986127,
              21.020641768188785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3251",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020377487881323,
          "to_latitude": 21.020641768188785,
          "to_longitude": 105.83023312986127,
          "from_longitude": 105.8306270816388
        }
      },
      {
        "id": 3245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983654893528,
            21.019765602704112,
            105.83002739213883,
            21.01986831915859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83002739213883,
              21.019765602704112
            ],
            [
              105.82983654893528,
              21.01986831915859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3_Ngách 278/11, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3252",
          "diaChiLapD": "Ngách 278/11, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019765602704112,
          "to_latitude": 21.01986831915859,
          "to_longitude": 105.82983654893528,
          "from_longitude": 105.83002739213883
        }
      },
      {
        "id": 3246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83013925663927,
            21.019966420870812,
            105.83044827670574,
            21.02006373654093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044827670574,
              21.0199914853573
            ],
            [
              105.83033451088187,
              21.02003840236534
            ],
            [
              105.83030201322067,
              21.020052899692615
            ],
            [
              105.83029739614427,
              21.02006373654093
            ],
            [
              105.83028320225303,
              21.020057292669655
            ],
            [
              105.83025402981309,
              21.020056622159927
            ],
            [
              105.83024348231,
              21.0200546188052
            ],
            [
              105.83013925663927,
              21.019966420870812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3253",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0199914853573,
          "to_latitude": 21.019966420870812,
          "to_longitude": 105.83013925663927,
          "from_longitude": 105.83044827670574
        }
      },
      {
        "id": 3247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82994160124406,
            21.019966420870812,
            105.83013925663927,
            21.020071509140667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83013925663927,
              21.019966420870812
            ],
            [
              105.82994160124406,
              21.020071509140667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3254",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019966420870812,
          "to_latitude": 21.020071509140667,
          "to_longitude": 105.82994160124406,
          "from_longitude": 105.83013925663927
        }
      },
      {
        "id": 3248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82920153703222,
            21.020071509140667,
            105.82994160124406,
            21.02071020211127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82994160124406,
              21.020071509140667
            ],
            [
              105.82988665990256,
              21.020105126972588
            ],
            [
              105.82990343900367,
              21.020134764822075
            ],
            [
              105.82990715869255,
              21.02014132757065
            ],
            [
              105.82993223609209,
              21.020185632140784
            ],
            [
              105.82975842765846,
              21.020287805662377
            ],
            [
              105.8297853542078,
              21.020332158136767
            ],
            [
              105.8295769683577,
              21.020482297011544
            ],
            [
              105.8295337249333,
              21.020486809968148
            ],
            [
              105.82934648255493,
              21.020594307173248
            ],
            [
              105.82934589807444,
              21.020626209219024
            ],
            [
              105.82920153703222,
              21.02071020211127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3255",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 135,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020071509140667,
          "to_latitude": 21.02071020211127,
          "to_longitude": 105.82920153703222,
          "from_longitude": 105.82994160124406
        }
      },
      {
        "id": 3249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040094991229,
            21.020555716174773,
            105.83040508061482,
            21.020561102712435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040094991229,
              21.020555716174773
            ],
            [
              105.83040104100513,
              21.020555836762178
            ],
            [
              105.83040508061482,
              21.020561102712435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3256",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020555716174773,
          "to_latitude": 21.020561102712435,
          "to_longitude": 105.83040508061482,
          "from_longitude": 105.83040094991229
        }
      },
      {
        "id": 3250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302543814877,
            21.020401450940565,
            105.83063215696345,
            21.020649549611456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83063215696345,
              21.020401450940565
            ],
            [
              105.83043248032405,
              21.020545285350686
            ],
            [
              105.83040508061482,
              21.020561102712435
            ],
            [
              105.83038300665193,
              21.020573845686922
            ],
            [
              105.8302543814877,
              21.020649549611456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3257",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020401450940565,
          "to_latitude": 21.020649549611456,
          "to_longitude": 105.8302543814877,
          "from_longitude": 105.83063215696345
        }
      },
      {
        "id": 3251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040480399383,
            21.019759188239007,
            105.83044723464542,
            21.019777365944776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040480399383,
              21.019777365944776
            ],
            [
              105.8304153202482,
              21.019772860804224
            ],
            [
              105.8304299861885,
              21.01976657772888
            ],
            [
              105.83044723464542,
              21.019759188239007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3258",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019777365944776,
          "to_latitude": 21.019759188239007,
          "to_longitude": 105.83044723464542,
          "from_longitude": 105.83040480399383
        }
      },
      {
        "id": 3252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83058509543041,
            21.0201458569936,
            105.83062482030715,
            21.020162096761172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83058509543041,
              21.020162096761172
            ],
            [
              105.83060141479382,
              21.02015542533923
            ],
            [
              105.8306213381771,
              21.020147280318426
            ],
            [
              105.83062482030715,
              21.0201458569936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3259",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020162096761172,
          "to_latitude": 21.0201458569936,
          "to_longitude": 105.83062482030715,
          "from_longitude": 105.83058509543041
        }
      },
      {
        "id": 3253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83050598054824,
            21.019993285564137,
            105.83066232000733,
            21.020326873406994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83066232000733,
              21.020326873406994
            ],
            [
              105.83058509543041,
              21.020162096761172
            ],
            [
              105.83057787908764,
              21.020146698595905
            ],
            [
              105.83050598054824,
              21.019993285564137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3260",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020326873406994,
          "to_latitude": 21.019993285564137,
          "to_longitude": 105.83050598054824,
          "from_longitude": 105.83066232000733
        }
      },
      {
        "id": 3254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8300845415239,
            21.020022156359254,
            105.8303352949941,
            21.020318600560795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8300845415239,
              21.020318600560795
            ],
            [
              105.83022667203228,
              21.02022977744482
            ],
            [
              105.83024300481047,
              21.02025401976483
            ],
            [
              105.83031156076215,
              21.020210311372654
            ],
            [
              105.83030052581675,
              21.020161971353833
            ],
            [
              105.8303352949941,
              21.020143878038617
            ],
            [
              105.83029739614427,
              21.02006373654093
            ],
            [
              105.83029248942628,
              21.020053360760713
            ],
            [
              105.83028936000998,
              21.02004325811113
            ],
            [
              105.83028309726589,
              21.020023041088905
            ],
            [
              105.83028282397903,
              21.020022156359254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3261",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020318600560795,
          "to_latitude": 21.020022156359254,
          "to_longitude": 105.83028282397903,
          "from_longitude": 105.8300845415239
        }
      },
      {
        "id": 3255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82977153903705,
            21.01978967907916,
            105.82993670181261,
            21.02003324443809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82993670181261,
              21.02003324443809
            ],
            [
              105.82977153903705,
              21.01978967907916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3#_Ngách 278/11, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3262",
          "diaChiLapD": "Ngách 278/11, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02003324443809,
          "to_latitude": 21.01978967907916,
          "to_longitude": 105.82977153903705,
          "from_longitude": 105.82993670181261
        }
      },
      {
        "id": 3256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83030365824456,
            21.01955826855602,
            105.8304702455264,
            21.01991912553393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8304702455264,
              21.01991912553393
            ],
            [
              105.83040480399383,
              21.019777365944776
            ],
            [
              105.83039328775445,
              21.01975242090011
            ],
            [
              105.83030365824456,
              21.01955826855602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3263",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01991912553393,
          "to_latitude": 21.01955826855602,
          "to_longitude": 105.83030365824456,
          "from_longitude": 105.8304702455264
        }
      },
      {
        "id": 3257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302422771402,
            21.01941068074951,
            105.83028717940367,
            21.01943712504991
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302422771402,
              21.01943712504991
            ],
            [
              105.83025238710432,
              21.01943117132358
            ],
            [
              105.83026818391686,
              21.019421867778266
            ],
            [
              105.83028717940367,
              21.01941068074951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3264",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01943712504991,
          "to_latitude": 21.01941068074951,
          "to_longitude": 105.83028717940367,
          "from_longitude": 105.8302422771402
        }
      },
      {
        "id": 3258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885227252537,
            21.019782086066655,
            105.82908776656137,
            21.019969036589778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82891815609939,
              21.019969036589778
            ],
            [
              105.82885227252537,
              21.019880186735257
            ],
            [
              105.8290165888711,
              21.019782086066655
            ],
            [
              105.82908776656137,
              21.019877963337734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2*_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.3265",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019969036589778,
          "to_latitude": 21.019877963337734,
          "to_longitude": 105.82908776656137,
          "from_longitude": 105.82891815609939
        }
      },
      {
        "id": 3259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82891730392323,
            21.0209796339602,
            105.82908663458541,
            21.021357275285833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82908663458541,
              21.021357275285833
            ],
            [
              105.82905612010177,
              21.02128738615361
            ],
            [
              105.829027774031,
              21.021227084114102
            ],
            [
              105.82901222040641,
              21.021193596985448
            ],
            [
              105.82899204861998,
              21.021142757024297
            ],
            [
              105.82898776539861,
              21.02113083094301
            ],
            [
              105.82897533418324,
              21.021102358652143
            ],
            [
              105.82895637706486,
              21.02105894179114
            ],
            [
              105.82894162271933,
              21.021041088293334
            ],
            [
              105.82894075039002,
              21.021038162482732
            ],
            [
              105.82893059703203,
              21.021004101672492
            ],
            [
              105.82891730392323,
              21.0209796339602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3266",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021357275285833,
          "to_latitude": 21.0209796339602,
          "to_longitude": 105.82891730392323,
          "from_longitude": 105.82908663458541
        }
      },
      {
        "id": 3260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82860684833491,
            21.01994067979984,
            105.82896783589284,
            21.02013332354585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82860684833491,
              21.02013332354585
            ],
            [
              105.82862204883413,
              21.02012870717959
            ],
            [
              105.82864163430659,
              21.02012275796771
            ],
            [
              105.82882427679982,
              21.020022621749398
            ],
            [
              105.82891815609939,
              21.019969036589778
            ],
            [
              105.82896783589284,
              21.01994067979984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2*_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.3267",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02013332354585,
          "to_latitude": 21.01994067979984,
          "to_longitude": 105.82896783589284,
          "from_longitude": 105.82860684833491
        }
      },
      {
        "id": 3261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874291533658,
            21.01989619181229,
            105.82882427679982,
            21.020022621749398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82882427679982,
              21.020022621749398
            ],
            [
              105.82875637044968,
              21.019917099145196
            ],
            [
              105.82874291533658,
              21.01989619181229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2*_Ngõ Trại Tóc, La Thành",
          "maTaiSan": "04.O13.DODV.3268",
          "diaChiLapD": "Ngõ Trại Tóc, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020022621749398,
          "to_latitude": 21.01989619181229,
          "to_longitude": 105.82874291533658,
          "from_longitude": 105.82882427679982
        }
      },
      {
        "id": 3262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82831223374394,
            21.02018164955323,
            105.82864311656837,
            21.020525200701872
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.82834772933532,
                21.020525200701872
              ],
              [
                105.82831223374394,
                21.020457981185107
              ],
              [
                105.82832410886158,
                21.02044242398263
              ],
              [
                105.82841868514255,
                21.020330866677284
              ],
              [
                105.82844582372717,
                21.020310755250506
              ],
              [
                105.8284458458012,
                21.020310738880095
              ],
              [
                105.8284610115951,
                21.020299501083702
              ]
            ],
            [
              [
                105.82864311656837,
                21.020239894490828
              ],
              [
                105.82864130940074,
                21.02024048682955
              ],
              [
                105.8284610115951,
                21.020299501083702
              ],
              [
                105.82861146199225,
                21.020188005749127
              ],
              [
                105.82862003842386,
                21.02018164955323
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3*_Ngách 9, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3269",
          "diaChiLapD": "Ngách 9, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.82831223374394,
            21.020457981185107
          ],
          "to_latitude": [
            105.82864130940074,
            21.02024048682955
          ],
          "to_longitude": [
            105.82864311656837,
            21.020239894490828
          ],
          "from_longitude": [
            105.82834772933532,
            21.020525200701872
          ]
        }
      },
      {
        "id": 3263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853716970997,
            21.019917162596478,
            105.82862862567313,
            21.020231571451006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82862862567313,
              21.020231571451006
            ],
            [
              105.82861146201978,
              21.020188005804567
            ],
            [
              105.82856974908358,
              21.02008212881746
            ],
            [
              105.82856569038621,
              21.02007182743387
            ],
            [
              105.82855819030641,
              21.02003115380287
            ],
            [
              105.82853716970997,
              21.019917162596478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3270",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020231571451006,
          "to_latitude": 21.019917162596478,
          "to_longitude": 105.82853716970997,
          "from_longitude": 105.82862862567313
        }
      },
      {
        "id": 3264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82882789781604,
            21.020695866271474,
            105.82899334288764,
            21.021090630119442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82899334288764,
              21.021090630119442
            ],
            [
              105.8289192674292,
              21.02092344650614
            ],
            [
              105.82891523162662,
              21.02091433727864
            ],
            [
              105.82891451564464,
              21.02091272128538
            ],
            [
              105.82882789781604,
              21.020695866271474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3271",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021090630119442,
          "to_latitude": 21.020695866271474,
          "to_longitude": 105.82882789781604,
          "from_longitude": 105.82899334288764
        }
      },
      {
        "id": 3265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865324633438,
            21.020256130330615,
            105.82882559922315,
            21.020689693993194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82882559922315,
              21.020689693993194
            ],
            [
              105.8287276401959,
              21.020443273813438
            ],
            [
              105.82872493797912,
              21.02043647666168
            ],
            [
              105.82865324633438,
              21.020256130330615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3272",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020689693993194,
          "to_latitude": 21.020256130330615,
          "to_longitude": 105.82865324633438,
          "from_longitude": 105.82882559922315
        }
      },
      {
        "id": 3266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82864160338205,
            21.020258694403037,
            105.8287481360835,
            21.0205482669019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8287481360835,
              21.0205482669019
            ],
            [
              105.82873989285035,
              21.02052439869685
            ],
            [
              105.82871543605386,
              21.020461963193185
            ],
            [
              105.8286969858729,
              21.020411724476396
            ],
            [
              105.82868634942932,
              21.02038927605531
            ],
            [
              105.8286826157756,
              21.02038139644472
            ],
            [
              105.82865531108328,
              21.02029414695251
            ],
            [
              105.82864160338205,
              21.020258694403037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3273",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0205482669019,
          "to_latitude": 21.020258694403037,
          "to_longitude": 105.82864160338205,
          "from_longitude": 105.8287481360835
        }
      },
      {
        "id": 3267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82856974908358,
            21.020073980164582,
            105.82859881027252,
            21.02008212881746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859881027252,
              21.020073980164582
            ],
            [
              105.82859617280175,
              21.020074720108585
            ],
            [
              105.82859154713871,
              21.02007601709192
            ],
            [
              105.82856974908358,
              21.02008212881746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3274",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020073980164582,
          "to_latitude": 21.02008212881746,
          "to_longitude": 105.82856974908358,
          "from_longitude": 105.82859881027252
        }
      },
      {
        "id": 3268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82868634942932,
            21.020385954353184,
            105.8286946184294,
            21.02038927605531
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82868634942932,
              21.02038927605531
            ],
            [
              105.8286885906484,
              21.02038837545973
            ],
            [
              105.82869220731601,
              21.0203869226152
            ],
            [
              105.8286946184294,
              21.020385954353184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3275",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02038927605531,
          "to_latitude": 21.020385954353184,
          "to_longitude": 105.8286946184294,
          "from_longitude": 105.82868634942932
        }
      },
      {
        "id": 3269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871037111937,
            21.02043647666168,
            105.82872493797912,
            21.020442436659298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872493797912,
              21.02043647666168
            ],
            [
              105.82871380719743,
              21.02044103075859
            ],
            [
              105.82871124357867,
              21.02044208015091
            ],
            [
              105.82871037111937,
              21.020442436659298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D5*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3276",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02043647666168,
          "to_latitude": 21.020442436659298,
          "to_longitude": 105.82871037111937,
          "from_longitude": 105.82872493797912
        }
      },
      {
        "id": 3270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82862060448245,
            21.02055945779114,
            105.82876316617363,
            21.02095022114767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82876316617363,
              21.02055945779114
            ],
            [
              105.82875536727585,
              21.02056203023916
            ],
            [
              105.82862060448245,
              21.020604590107432
            ],
            [
              105.82870139221853,
              21.02095022114767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D6*_Ngách 29, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3277",
          "diaChiLapD": "Ngách 29, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02055945779114,
          "to_latitude": 21.02095022114767,
          "to_longitude": 105.82870139221853,
          "from_longitude": 105.82876316617363
        }
      },
      {
        "id": 3271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82876759567736,
            21.02058776315365,
            105.82888602154036,
            21.02089853318726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82876759567736,
              21.02058776315365
            ],
            [
              105.82882902574121,
              21.02073511583885
            ],
            [
              105.82885540936186,
              21.020798403609085
            ],
            [
              105.82888602154036,
              21.02089853318726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3278",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02058776315365,
          "to_latitude": 21.02089853318726,
          "to_longitude": 105.82888602154036,
          "from_longitude": 105.82876759567736
        }
      },
      {
        "id": 3272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82882902574121,
            21.02073202982228,
            105.82883674869814,
            21.02073511583885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82882902574121,
              21.02073511583885
            ],
            [
              105.82883255414636,
              21.020733704969622
            ],
            [
              105.82883674869814,
              21.02073202982228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D7*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3279",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02073511583885,
          "to_latitude": 21.02073202982228,
          "to_longitude": 105.82883674869814,
          "from_longitude": 105.82882902574121
        }
      },
      {
        "id": 3273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82889878646338,
            21.02092344650614,
            105.8289192674292,
            21.020931519081856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289192674292,
              21.02092344650614
            ],
            [
              105.82890214604419,
              21.020930194840194
            ],
            [
              105.82889962635855,
              21.020931188021454
            ],
            [
              105.82889878646338,
              21.020931519081856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D8*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3280",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02092344650614,
          "to_latitude": 21.020931519081856,
          "to_longitude": 105.82889878646338,
          "from_longitude": 105.8289192674292
        }
      },
      {
        "id": 3274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897533418324,
            21.02109912458802,
            105.82898303902167,
            21.021102358652143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897533418324,
              21.021102358652143
            ],
            [
              105.8289792326636,
              21.021100721073086
            ],
            [
              105.82898303902167,
              21.02109912458802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3281",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021102358652143,
          "to_latitude": 21.02109912458802,
          "to_longitude": 105.82898303902167,
          "from_longitude": 105.82897533418324
        }
      },
      {
        "id": 3275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874631081121,
            21.021033595131463,
            105.82895181764452,
            21.021109535320004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895181764452,
              21.021033595131463
            ],
            [
              105.82894191586132,
              21.021037734996792
            ],
            [
              105.82894075039002,
              21.021038162482732
            ],
            [
              105.82874631081121,
              21.021109535320004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1*_Ngách 55, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3282",
          "diaChiLapD": "Ngách 55, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021033595131463,
          "to_latitude": 21.021109535320004,
          "to_longitude": 105.82874631081121,
          "from_longitude": 105.82895181764452
        }
      },
      {
        "id": 3276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82904484041305,
            21.02093724493057,
            105.82914079229779,
            21.02107449230093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82904484041305,
              21.02107449230093
            ],
            [
              105.82914079229779,
              21.021027854448374
            ],
            [
              105.82909979997561,
              21.02093724493057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3283",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02107449230093,
          "to_latitude": 21.02093724493057,
          "to_longitude": 105.82909979997561,
          "from_longitude": 105.82904484041305
        }
      },
      {
        "id": 3277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915341348165,
            21.020936032882034,
            105.82937824462563,
            21.02102369860573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915341348165,
              21.02102369860573
            ],
            [
              105.82919496618297,
              21.021016100438146
            ],
            [
              105.82926975601788,
              21.020987635595116
            ],
            [
              105.82928852954,
              21.020978706010233
            ],
            [
              105.82937824462563,
              21.020936032882034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G7*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3284",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02102369860573,
          "to_latitude": 21.020936032882034,
          "to_longitude": 105.82937824462563,
          "from_longitude": 105.82915341348165
        }
      },
      {
        "id": 3278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82914079229779,
            21.021027854448374,
            105.82914305829523,
            21.021037871005923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914079229779,
              21.021027854448374
            ],
            [
              105.82914269051976,
              21.021036250606677
            ],
            [
              105.82914305829523,
              21.021037871005923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3285",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021027854448374,
          "to_latitude": 21.021037871005923,
          "to_longitude": 105.82914305829523,
          "from_longitude": 105.82914079229779
        }
      },
      {
        "id": 3279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82928852954,
            21.020978706010233,
            105.8292932457401,
            21.020985600816186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82928852954,
              21.020978706010233
            ],
            [
              105.82929126344352,
              21.02098270299982
            ],
            [
              105.82929231667333,
              21.020984245089117
            ],
            [
              105.8292932457401,
              21.020985600816186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G7*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3286",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020978706010233,
          "to_latitude": 21.020985600816186,
          "to_longitude": 105.8292932457401,
          "from_longitude": 105.82928852954
        }
      },
      {
        "id": 3280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82941930202824,
            21.020837629909032,
            105.82957978239281,
            21.020927766882757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82941930202824,
              21.020927766882757
            ],
            [
              105.82949328979689,
              21.02088596702919
            ],
            [
              105.82949458270477,
              21.020885100853985
            ],
            [
              105.82956210777843,
              21.020844320615783
            ],
            [
              105.82957425220296,
              21.020837629909032
            ],
            [
              105.82957911617765,
              21.02084636927468
            ],
            [
              105.82957978239281,
              21.02084750860493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G5*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3287",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020927766882757,
          "to_latitude": 21.02084750860493,
          "to_longitude": 105.82957978239281,
          "from_longitude": 105.82941930202824
        }
      },
      {
        "id": 3281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290131141673,
            21.021184902944682,
            105.82902826038233,
            21.021191350825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290131141673,
              21.021191350825
            ],
            [
              105.82901412282715,
              21.021190921393156
            ],
            [
              105.82901806725303,
              21.021189242040894
            ],
            [
              105.82902826038233,
              21.021184902944682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3288",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021191350825,
          "to_latitude": 21.021184902944682,
          "to_longitude": 105.82902826038233,
          "from_longitude": 105.8290131141673
        }
      },
      {
        "id": 3282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83008707122032,
            21.019895994757356,
            105.83011553817339,
            21.019967890020567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83011553817339,
              21.019895994757356
            ],
            [
              105.83008707122032,
              21.019908245020424
            ],
            [
              105.83010413205824,
              21.019933687802183
            ],
            [
              105.83011488079194,
              21.019966136991975
            ],
            [
              105.83011546286524,
              21.019967890020567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3289",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019895994757356,
          "to_latitude": 21.019967890020567,
          "to_longitude": 105.83011546286524,
          "from_longitude": 105.83011553817339
        }
      },
      {
        "id": 3283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82968993405412,
            21.01983529890182,
            105.83005320929297,
            21.020080486272363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005320929297,
              21.019959549953608
            ],
            [
              105.82995859121314,
              21.020020706551293
            ],
            [
              105.82994170261257,
              21.020030380299414
            ],
            [
              105.82993670181261,
              21.02003324443809
            ],
            [
              105.8298542245677,
              21.020080486272363
            ],
            [
              105.82969386268638,
              21.019841204895286
            ],
            [
              105.82968993405412,
              21.01983529890182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3290",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019959549953608,
          "to_latitude": 21.01983529890182,
          "to_longitude": 105.82968993405412,
          "from_longitude": 105.83005320929297
        }
      },
      {
        "id": 3284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82966896576312,
            21.019648696474405,
            105.83029001501097,
            21.020017526553776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029001501097,
              21.020017526553776
            ],
            [
              105.83024932948933,
              21.020015066951725
            ],
            [
              105.8301454146389,
              21.01993626736443
            ],
            [
              105.83014474035552,
              21.01993575581696
            ],
            [
              105.8301399720798,
              21.019932139994477
            ],
            [
              105.83011553817339,
              21.019895994757356
            ],
            [
              105.83002739213883,
              21.019765602704112
            ],
            [
              105.8299544621964,
              21.019648696474405
            ],
            [
              105.82992348394465,
              21.01966779005285
            ],
            [
              105.82983083554397,
              21.019732846125425
            ],
            [
              105.82972241250683,
              21.019802200769266
            ],
            [
              105.82966896576312,
              21.019830888663922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3_Ngách 278/11, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3291",
          "diaChiLapD": "Ngách 278/11, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020017526553776,
          "to_latitude": 21.019830888663922,
          "to_longitude": 105.82966896576312,
          "from_longitude": 105.83029001501097
        }
      },
      {
        "id": 3285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82965509756566,
            21.020601949046807,
            105.8299283931937,
            21.02077840584958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299283931937,
              21.020601949046807
            ],
            [
              105.82980490081036,
              21.020681820364324
            ],
            [
              105.82976637231774,
              21.020706739520126
            ],
            [
              105.82970501042561,
              21.020746425774668
            ],
            [
              105.82968248464168,
              21.02076010931498
            ],
            [
              105.82965509756566,
              21.02077840584958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G3*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3292",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020601949046807,
          "to_latitude": 21.02077840584958,
          "to_longitude": 105.82965509756566,
          "from_longitude": 105.8299283931937
        }
      },
      {
        "id": 3286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874524839724,
            21.02132581681989,
            105.82919978204092,
            21.021730915616182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919978204092,
              21.02163561609031
            ],
            [
              105.82919702364227,
              21.021636585149622
            ],
            [
              105.82892825719728,
              21.021730915616182
            ],
            [
              105.82890935109909,
              21.021728412788857
            ],
            [
              105.82874524839724,
              21.02132581681989
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H6*_Ngách 79, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3293",
          "diaChiLapD": "Ngách 79, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02163561609031,
          "to_latitude": 21.02132581681989,
          "to_longitude": 105.82874524839724,
          "from_longitude": 105.82919978204092
        }
      },
      {
        "id": 3287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900121234954,
            21.02112465599186,
            105.82909893057797,
            21.021342319364734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82909893057797,
              21.021342319364734
            ],
            [
              105.82903688876596,
              21.021204123065214
            ],
            [
              105.82902826038233,
              21.021184902944682
            ],
            [
              105.82900121234954,
              21.02112465599186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H3*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3294",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021342319364734,
          "to_latitude": 21.02112465599186,
          "to_longitude": 105.82900121234954,
          "from_longitude": 105.82909893057797
        }
      },
      {
        "id": 3288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83021524503961,
            21.020056622159927,
            105.83025402981309,
            21.020093937618324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83025402981309,
              21.020056622159927
            ],
            [
              105.83024010766465,
              21.020079105444612
            ],
            [
              105.83021524503961,
              21.020093937618324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3295",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020056622159927,
          "to_latitude": 21.020093937618324,
          "to_longitude": 105.83021524503961,
          "from_longitude": 105.83025402981309
        }
      },
      {
        "id": 3289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82980251505201,
            21.02029927780324,
            105.82997312627592,
            21.020421220009332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82980251505201,
              21.020421220009332
            ],
            [
              105.82993182149698,
              21.020325208604078
            ],
            [
              105.82997312627592,
              21.02029927780324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3296",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020421220009332,
          "to_latitude": 21.02029927780324,
          "to_longitude": 105.82997312627592,
          "from_longitude": 105.82980251505201
        }
      },
      {
        "id": 3290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961075848347,
            21.020421220009332,
            105.82982365118427,
            21.02060975427983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982365118427,
              21.020446210291755
            ],
            [
              105.82980251505201,
              21.020421220009332
            ],
            [
              105.82966722556007,
              21.020522549788133
            ],
            [
              105.82963210999064,
              21.020558568255552
            ],
            [
              105.82965096467365,
              21.020573710986955
            ],
            [
              105.82961075848347,
              21.02060975427983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3297",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020446210291755,
          "to_latitude": 21.02060975427983,
          "to_longitude": 105.82961075848347,
          "from_longitude": 105.82982365118427
        }
      },
      {
        "id": 3291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299207728395,
            21.02045886225946,
            105.8301030681786,
            21.020572988193308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299207728395,
              21.020572988193308
            ],
            [
              105.8301030681786,
              21.02045886225946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3298",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020572988193308,
          "to_latitude": 21.02045886225946,
          "to_longitude": 105.8301030681786,
          "from_longitude": 105.8299207728395
        }
      },
      {
        "id": 3292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82982471530781,
            21.020685094534187,
            105.82987335469186,
            21.02076186784069
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982471530781,
              21.020685094534187
            ],
            [
              105.82987335469186,
              21.020749109040008
            ],
            [
              105.82985598381539,
              21.02076186784069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3299",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020685094534187,
          "to_latitude": 21.02076186784069,
          "to_longitude": 105.82985598381539,
          "from_longitude": 105.82982471530781
        }
      },
      {
        "id": 3293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959309894623,
            21.02083841403044,
            105.82998674101532,
            21.02096597713759
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82998674101532,
              21.02083841403044
            ],
            [
              105.82998071811754,
              21.02084258560849
            ],
            [
              105.82996362407258,
              21.02084331301772
            ],
            [
              105.82980370277708,
              21.02096597713759
            ],
            [
              105.829691729018,
              21.020848054584622
            ],
            [
              105.82959309894623,
              21.02090158386066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5A_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3300",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02083841403044,
          "to_latitude": 21.02090158386066,
          "to_longitude": 105.82959309894623,
          "from_longitude": 105.82998674101532
        }
      },
      {
        "id": 3294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82857303653861,
            21.02049517120316,
            105.82862060448245,
            21.020604590107432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82862060448245,
              21.020604590107432
            ],
            [
              105.82857303653861,
              21.02049517120316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D6*_Ngách 29, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3301",
          "diaChiLapD": "Ngách 29, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020604590107432,
          "to_latitude": 21.02049517120316,
          "to_longitude": 105.82857303653861,
          "from_longitude": 105.82862060448245
        }
      },
      {
        "id": 3295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82996032631576,
            21.02129689072048,
            105.83019018691908,
            21.02140919402924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82996032631576,
              21.02140919402924
            ],
            [
              105.83007913365168,
              21.02135317162087
            ],
            [
              105.83008278756262,
              21.021351320242758
            ],
            [
              105.83019018691908,
              21.02129689072048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F5*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3302",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02140919402924,
          "to_latitude": 21.02129689072048,
          "to_longitude": 105.83019018691908,
          "from_longitude": 105.82996032631576
        }
      },
      {
        "id": 3296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83009972717034,
            21.019159808101893,
            105.83030608732744,
            21.01954056974905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83030608732744,
              21.01954056974905
            ],
            [
              105.83026722480679,
              21.01948788736293
            ],
            [
              105.8302422771402,
              21.01943712504991
            ],
            [
              105.83013490665702,
              21.01921865585522
            ],
            [
              105.83009972717034,
              21.019159808101893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3303",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01954056974905,
          "to_latitude": 21.019159808101893,
          "to_longitude": 105.83009972717034,
          "from_longitude": 105.83030608732744
        }
      },
      {
        "id": 3297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83006515578492,
            21.020645383049647,
            105.83022143981903,
            21.020746951998493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83022143981903,
              21.020645383049647
            ],
            [
              105.83006515578492,
              21.020746951998493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E3*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3304",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020645383049647,
          "to_latitude": 21.020746951998493,
          "to_longitude": 105.83006515578492,
          "from_longitude": 105.83022143981903
        }
      },
      {
        "id": 3298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82982365118427,
            21.020446210291755,
            105.83005278537155,
            21.020745308883647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005278537155,
              21.020745308883647
            ],
            [
              105.82996520300497,
              21.020630984249475
            ],
            [
              105.82994862974407,
              21.020609350687277
            ],
            [
              105.8299207728395,
              21.020572988193308
            ],
            [
              105.82989548177319,
              21.020539974075376
            ],
            [
              105.82982365118427,
              21.020446210291755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3305",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020745308883647,
          "to_latitude": 21.020446210291755,
          "to_longitude": 105.82982365118427,
          "from_longitude": 105.83005278537155
        }
      },
      {
        "id": 3299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83025511116465,
            21.021053417159436,
            105.8306089254775,
            21.021261909864208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8306089254775,
              21.021053417159436
            ],
            [
              105.83040523361994,
              21.021173446800965
            ],
            [
              105.83025511116465,
              21.021261909864208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F3*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3306",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021053417159436,
          "to_latitude": 21.021261909864208,
          "to_longitude": 105.83025511116465,
          "from_longitude": 105.8306089254775
        }
      },
      {
        "id": 3300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83008278756262,
            21.021351320242758,
            105.83008731636608,
            21.021359109228854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83008731636608,
              21.021359109228854
            ],
            [
              105.83008502197491,
              21.021355097462955
            ],
            [
              105.83008278756262,
              21.021351320242758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F5*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3307",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021359109228854,
          "to_latitude": 21.021351320242758,
          "to_longitude": 105.83008278756262,
          "from_longitude": 105.83008731636608
        }
      },
      {
        "id": 3301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83075947440722,
            21.02096630383796,
            105.8307702745475,
            21.020985592741695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307702745475,
              21.020985592741695
            ],
            [
              105.83076609842773,
              21.020978090180247
            ],
            [
              105.83075947440722,
              21.02096630383796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F1*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3308",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020985592741695,
          "to_latitude": 21.02096630383796,
          "to_longitude": 105.83075947440722,
          "from_longitude": 105.8307702745475
        }
      },
      {
        "id": 3302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82993092747313,
            21.020056945233613,
            105.83006812126061,
            21.020304599598422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82993092747313,
              21.020056945233613
            ],
            [
              105.8299318861097,
              21.02005825565181
            ],
            [
              105.82994160124406,
              21.020071509140667
            ],
            [
              105.83006812126061,
              21.020244140252352
            ],
            [
              105.83003950416233,
              21.020271335097302
            ],
            [
              105.8300634565774,
              21.020304599598422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3309",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020056945233613,
          "to_latitude": 21.020304599598422,
          "to_longitude": 105.8300634565774,
          "from_longitude": 105.82993092747313
        }
      },
      {
        "id": 3303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8300845415239,
            21.020318600560795,
            105.83011242321672,
            21.020389680162047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8300845415239,
              21.020318600560795
            ],
            [
              105.83011242321672,
              21.020389680162047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3310",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020318600560795,
          "to_latitude": 21.020389680162047,
          "to_longitude": 105.83011242321672,
          "from_longitude": 105.8300845415239
        }
      },
      {
        "id": 3304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83057352898157,
            21.021079857586816,
            105.83070018845586,
            21.021339364146456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83070018845586,
              21.021339364146456
            ],
            [
              105.83064316286867,
              21.02122253323993
            ],
            [
              105.83058331038526,
              21.021099842444062
            ],
            [
              105.83057352898157,
              21.021079857586816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F2*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3311",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021339364146456,
          "to_latitude": 21.021079857586816,
          "to_longitude": 105.83057352898157,
          "from_longitude": 105.83070018845586
        }
      },
      {
        "id": 3305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290338947793,
            21.025845731093657,
            105.8292535649024,
            21.025889818170427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292535649024,
              21.025845731093657
            ],
            [
              105.82906378472372,
              21.025883819562612
            ],
            [
              105.8290338947793,
              21.025889818170427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P8_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3312",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025845731093657,
          "to_latitude": 21.025889818170427,
          "to_longitude": 105.8290338947793,
          "from_longitude": 105.8292535649024
        }
      },
      {
        "id": 3306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82930590404598,
            21.025800653629894,
            105.82964084396772,
            21.025862150296135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82930590404598,
              21.025862150296135
            ],
            [
              105.82964084396772,
              21.025800653629894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N9_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3313",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025862150296135,
          "to_latitude": 21.025800653629894,
          "to_longitude": 105.82964084396772,
          "from_longitude": 105.82930590404598
        }
      },
      {
        "id": 3307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82964084396772,
            21.025800653629894,
            105.82972704612715,
            21.02610864715551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964084396772,
              21.025800653629894
            ],
            [
              105.82972704612715,
              21.02610864715551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N9_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3314",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025800653629894,
          "to_latitude": 21.02610864715551,
          "to_longitude": 105.82972704612715,
          "from_longitude": 105.82964084396772
        }
      },
      {
        "id": 3308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82990882483632,
            21.02849525865711,
            105.83010021822267,
            21.028532532281538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010021822267,
              21.02849525865711
            ],
            [
              105.82993231854526,
              21.028527705497847
            ],
            [
              105.82991813333845,
              21.028530619886563
            ],
            [
              105.82990938698288,
              21.02853241753816
            ],
            [
              105.82990882483632,
              21.028532532281538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3315",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02849525865711,
          "to_latitude": 21.028532532281538,
          "to_longitude": 105.82990882483632,
          "from_longitude": 105.83010021822267
        }
      },
      {
        "id": 3309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884109372982,
            21.025449574685737,
            105.82884268589353,
            21.025463311458555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884109372982,
              21.025463311458555
            ],
            [
              105.82884254354364,
              21.02545079744232
            ],
            [
              105.82884268589353,
              21.025449574685737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.3316",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025463311458555,
          "to_latitude": 21.025449574685737,
          "to_longitude": 105.82884268589353,
          "from_longitude": 105.82884109372982
        }
      },
      {
        "id": 3310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82963964207968,
            21.02880040107278,
            105.82990091476994,
            21.028880212988376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964544326268,
              21.028880212988376
            ],
            [
              105.82963964207968,
              21.028828557908813
            ],
            [
              105.82965599240217,
              21.028808706559783
            ],
            [
              105.82968777543358,
              21.028802577559865
            ],
            [
              105.82973029245656,
              21.02880040107278
            ],
            [
              105.82990091476994,
              21.028825076376563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8*_Ngách 27/4, Cát Linh",
          "maTaiSan": "04.O13.DODV.3317",
          "diaChiLapD": "Ngách 27/4, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028880212988376,
          "to_latitude": 21.028825076376563,
          "to_longitude": 105.82990091476994,
          "from_longitude": 105.82964544326268
        }
      },
      {
        "id": 3311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900441324105,
            21.02756350158617,
            105.82906544050415,
            21.027963360925416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82904638890996,
              21.02756350158617
            ],
            [
              105.82902312816752,
              21.02760225582611
            ],
            [
              105.82900441324105,
              21.027641587642904
            ],
            [
              105.82906544050415,
              21.027963360925416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F1A_Ngõ 74, An Trạch",
          "maTaiSan": "04.O13.DODV.3318",
          "diaChiLapD": "Ngõ 74, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02756350158617,
          "to_latitude": 21.027963360925416,
          "to_longitude": 105.82906544050415,
          "from_longitude": 105.82904638890996
        }
      },
      {
        "id": 3312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886300338065,
            21.027426149424393,
            105.82889032760885,
            21.027474313370597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886300338065,
              21.027474313370597
            ],
            [
              105.82888044883963,
              21.027443573806437
            ],
            [
              105.82889032760885,
              21.027426149424393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U3#_An Trạch",
          "maTaiSan": "04.O13.DODV.3319",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027474313370597,
          "to_latitude": 21.027426149424393,
          "to_longitude": 105.82889032760885,
          "from_longitude": 105.82886300338065
        }
      },
      {
        "id": 3313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82729832992621,
            21.023778898071882,
            105.8273050052731,
            21.0237816700243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8273050052731,
              21.023778898071882
            ],
            [
              105.82729832992621,
              21.0237816700243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3320",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023778898071882,
          "to_latitude": 21.0237816700243,
          "to_longitude": 105.82729832992621,
          "from_longitude": 105.8273050052731
        }
      },
      {
        "id": 3314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82812922713852,
            21.02496445910354,
            105.82813041133373,
            21.024968808686793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82813041133373,
              21.024968808686793
            ],
            [
              105.82813002053136,
              21.02496737355924
            ],
            [
              105.82812941371978,
              21.024965147355456
            ],
            [
              105.82812922713852,
              21.02496445910354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.3321",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024968808686793,
          "to_latitude": 21.02496445910354,
          "to_longitude": 105.82812922713852,
          "from_longitude": 105.82813041133373
        }
      },
      {
        "id": 3315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82851985153373,
            21.023212817508583,
            105.82852362577016,
            21.023217974340433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82852362577016,
              21.023212817508583
            ],
            [
              105.82852167706217,
              21.023215478869616
            ],
            [
              105.82851985153373,
              21.023217974340433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F3_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3322",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023212817508583,
          "to_latitude": 21.023217974340433,
          "to_longitude": 105.82851985153373,
          "from_longitude": 105.82852362577016
        }
      },
      {
        "id": 3316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8286049277751,
            21.023254370807823,
            105.82860553126628,
            21.023260348119177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82860553126628,
              21.023260348119177
            ],
            [
              105.82860541017428,
              21.023259151033017
            ],
            [
              105.82860506321803,
              21.02325572769486
            ],
            [
              105.8286049277751,
              21.023254370807823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F5_Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3323",
          "diaChiLapD": "Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023260348119177,
          "to_latitude": 21.023254370807823,
          "to_longitude": 105.8286049277751,
          "from_longitude": 105.82860553126628
        }
      },
      {
        "id": 3317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82916772076658,
            21.027159808510735,
            105.82957491056466,
            21.027381418312437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82957491056466,
              21.027159808510735
            ],
            [
              105.82941462938771,
              21.02724770812627
            ],
            [
              105.82930457914388,
              21.02730650100935
            ],
            [
              105.8292715906993,
              21.02732474714281
            ],
            [
              105.82921738117045,
              21.02735446473174
            ],
            [
              105.8291696807035,
              21.027381418312437
            ],
            [
              105.82916844341764,
              21.027380176089455
            ],
            [
              105.82916772076658,
              21.0273794480014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q7_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3324",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027159808510735,
          "to_latitude": 21.0273794480014,
          "to_longitude": 105.82916772076658,
          "from_longitude": 105.82957491056466
        }
      },
      {
        "id": 3318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83042917361855,
            21.02066245529582,
            105.83060056020344,
            21.020751402032882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83042917361855,
              21.020751402032882
            ],
            [
              105.83060056020344,
              21.020666310602742
            ],
            [
              105.83059841097217,
              21.02066245529582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3325",
          "diaChiLapD": "Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020751402032882,
          "to_latitude": 21.02066245529582,
          "to_longitude": 105.83059841097217,
          "from_longitude": 105.83042917361855
        }
      },
      {
        "id": 3319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82904541965594,
            21.0242112546382,
            105.82906334712116,
            21.024212778599736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82906334712116,
              21.024212778599736
            ],
            [
              105.82905986211136,
              21.024212483072578
            ],
            [
              105.82904888445943,
              21.024211549360924
            ],
            [
              105.82904541965594,
              21.0242112546382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M6_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3326",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024212778599736,
          "to_latitude": 21.0242112546382,
          "to_longitude": 105.82904541965594,
          "from_longitude": 105.82906334712116
        }
      },
      {
        "id": 3320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983255468886,
            21.02067454275146,
            105.82983581570824,
            21.020678356515628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82983255468886,
              21.02067454275146
            ],
            [
              105.82983274155853,
              21.020674761323438
            ],
            [
              105.8298348048762,
              21.020677175512844
            ],
            [
              105.82983581570824,
              21.020678356515628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G1*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3327",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02067454275146,
          "to_latitude": 21.020678356515628,
          "to_longitude": 105.82983581570824,
          "from_longitude": 105.82983255468886
        }
      },
      {
        "id": 3321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83123046294841,
            21.027912058024675,
            105.83156418617536,
            21.028684353750236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83142767221787,
              21.028684353750236
            ],
            [
              105.83141087188645,
              21.028607592325546
            ],
            [
              105.83140177522385,
              21.028566002702146
            ],
            [
              105.83156418617536,
              21.02853336809871
            ],
            [
              105.83150586309422,
              21.028243814211205
            ],
            [
              105.831334988315,
              21.028272580977657
            ],
            [
              105.83133379040001,
              21.028279656367022
            ],
            [
              105.8313305881505,
              21.028281620276214
            ],
            [
              105.83132909632467,
              21.02828253530949
            ],
            [
              105.83132493806116,
              21.028283673829694
            ],
            [
              105.83132284020057,
              21.028284248600674
            ],
            [
              105.83131872533976,
              21.028283325751747
            ],
            [
              105.83131497202923,
              21.028281331718176
            ],
            [
              105.83131220543322,
              21.0282787602168
            ],
            [
              105.83131102477105,
              21.028276630767632
            ],
            [
              105.83130916509917,
              21.028276961406767
            ],
            [
              105.83127776968156,
              21.02812362193068
            ],
            [
              105.83124287860511,
              21.02797084598253
            ],
            [
              105.83123046294841,
              21.027912058024675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1*_Ngõ 21B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3328",
          "diaChiLapD": "Ngõ 21B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 126,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028684353750236,
          "to_latitude": 21.027912058024675,
          "to_longitude": 105.83123046294841,
          "from_longitude": 105.83142767221787
        }
      },
      {
        "id": 3322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052107460682,
            21.027881923982065,
            105.83063947857917,
            21.02790154317692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052107460682,
              21.02790154317692
            ],
            [
              105.83063947857917,
              21.027881923982065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5*_Ngõ 25B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3329",
          "diaChiLapD": "Ngõ 25B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02790154317692,
          "to_latitude": 21.027881923982065,
          "to_longitude": 105.83063947857917,
          "from_longitude": 105.83052107460682
        }
      },
      {
        "id": 3323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82990196454631,
            21.02853512898453,
            105.82991977074224,
            21.028537976892512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82991977074224,
              21.02853584065902
            ],
            [
              105.8299103987475,
              21.02853688446243
            ],
            [
              105.82990507629236,
              21.02853747856683
            ],
            [
              105.82990253739374,
              21.028537976892512
            ],
            [
              105.82990196454631,
              21.02853563762835
            ],
            [
              105.82990976371485,
              21.02853512898453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B2_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3330",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02853584065902,
          "to_latitude": 21.02853512898453,
          "to_longitude": 105.82990976371485,
          "from_longitude": 105.82991977074224
        }
      },
      {
        "id": 3324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82896824780933,
            21.023968398726993,
            105.82897386151815,
            21.024018421753727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82897386151815,
              21.024018421753727
            ],
            [
              105.82896824780933,
              21.023968398726993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M4_Ngõ 153, Hào Nam",
          "maTaiSan": "04.O13.DODV.3331",
          "diaChiLapD": "Ngõ 153, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024018421753727,
          "to_latitude": 21.023968398726993,
          "to_longitude": 105.82896824780933,
          "from_longitude": 105.82897386151815
        }
      },
      {
        "id": 3325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82976690405886,
            21.026492778235987,
            105.83007085049753,
            21.02683195946898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83007085049753,
              21.02683195946898
            ],
            [
              105.83007010518875,
              21.026828703370843
            ],
            [
              105.83000629190072,
              21.026549603333663
            ],
            [
              105.82997472397518,
              21.026523777063797
            ],
            [
              105.8298652824084,
              21.02651252499118
            ],
            [
              105.82976690405886,
              21.026492778235987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S1_Ngách 39, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3332",
          "diaChiLapD": "Ngách 39, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02683195946898,
          "to_latitude": 21.026492778235987,
          "to_longitude": 105.82976690405886,
          "from_longitude": 105.83007085049753
        }
      },
      {
        "id": 3326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83093873240725,
            21.028018938819894,
            105.83098345471824,
            21.028280640757846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83098345471824,
              21.028280640757846
            ],
            [
              105.83098318710915,
              21.028232368361547
            ],
            [
              105.83095523851065,
              21.028123550845777
            ],
            [
              105.83093873240725,
              21.028018938819894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5*_Ngách 25B/1, Cát Linh",
          "maTaiSan": "04.O13.DODV.3333",
          "diaChiLapD": "Ngách 25B/1, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028280640757846,
          "to_latitude": 21.028018938819894,
          "to_longitude": 105.83093873240725,
          "from_longitude": 105.83098345471824
        }
      },
      {
        "id": 3327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82926233285427,
            21.020744519112917,
            105.82938466816158,
            21.020948785658387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82938414278462,
              21.020948785658387
            ],
            [
              105.82938419282318,
              21.020947397157688
            ],
            [
              105.82938466816158,
              21.02093454917509
            ],
            [
              105.82934422527124,
              21.02083459498411
            ],
            [
              105.82929198919462,
              21.02077908997852
            ],
            [
              105.82926233285427,
              21.020744519112917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G6*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3334",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020948785658387,
          "to_latitude": 21.020744519112917,
          "to_longitude": 105.82926233285427,
          "from_longitude": 105.82938414278462
        }
      },
      {
        "id": 3328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82939308462356,
            21.020694564625966,
            105.82959220557571,
            21.0208414749883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959220557571,
              21.0208414749883
            ],
            [
              105.82958418829139,
              21.020835001838822
            ],
            [
              105.82948099278266,
              21.020751861064667
            ],
            [
              105.82939308462356,
              21.020694564625966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G4*_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3335",
          "diaChiLapD": "Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0208414749883,
          "to_latitude": 21.020694564625966,
          "to_longitude": 105.82939308462356,
          "from_longitude": 105.82959220557571
        }
      },
      {
        "id": 3329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799259061659,
            21.025834823190674,
            105.82800264025849,
            21.02586244364485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82800264025849,
              21.02586244364485
            ],
            [
              105.82800103142772,
              21.025858023870747
            ],
            [
              105.82799889899047,
              21.025852160583305
            ],
            [
              105.82799259061659,
              21.025834823190674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H7_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DODV.3336",
          "diaChiLapD": "Ngõ 160, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02586244364485,
          "to_latitude": 21.025834823190674,
          "to_longitude": 105.82799259061659,
          "from_longitude": 105.82800264025849
        }
      },
      {
        "id": 3330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82831376649547,
            21.025511586547715,
            105.82831780084396,
            21.025523374753547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82831780084396,
              21.025523374753547
            ],
            [
              105.828317446885,
              21.02552234047891
            ],
            [
              105.82831543168702,
              21.025516455850237
            ],
            [
              105.82831376649547,
              21.025511586547715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2_Ngõ 152, Hào Nam",
          "maTaiSan": "04.O13.DODV.3337",
          "diaChiLapD": "Ngõ 152, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025523374753547,
          "to_latitude": 21.025511586547715,
          "to_longitude": 105.82831376649547,
          "from_longitude": 105.82831780084396
        }
      },
      {
        "id": 3331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82836284363155,
            21.02525218262601,
            105.82837024563767,
            21.025255421514757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82837024563767,
              21.02525218262601
            ],
            [
              105.82836972837153,
              21.02525240914526
            ],
            [
              105.82836592132496,
              21.02525407517064
            ],
            [
              105.82836284363155,
              21.025255421514757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H4_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DODV.3338",
          "diaChiLapD": "Ngõ 126, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02525218262601,
          "to_latitude": 21.025255421514757,
          "to_longitude": 105.82836284363155,
          "from_longitude": 105.82837024563767
        }
      },
      {
        "id": 3332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903587032456,
            21.025207804267325,
            105.82905444455267,
            21.025213902160196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905444455267,
              21.025207804267325
            ],
            [
              105.82905247890983,
              21.025208449719695
            ],
            [
              105.82904584930428,
              21.025210626293198
            ],
            [
              105.82903587032456,
              21.025213902160196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3339",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025207804267325,
          "to_latitude": 21.025213902160196,
          "to_longitude": 105.82903587032456,
          "from_longitude": 105.82905444455267
        }
      },
      {
        "id": 3333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82858332172651,
            21.026039775286726,
            105.82861183754922,
            21.02612124448903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82858332172651,
              21.026039775286726
            ],
            [
              105.82858590706998,
              21.02604716461518
            ],
            [
              105.82859621610223,
              21.026076613699168
            ],
            [
              105.82861183754922,
              21.02612124448903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P5_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3340",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026039775286726,
          "to_latitude": 21.02612124448903,
          "to_longitude": 105.82861183754922,
          "from_longitude": 105.82858332172651
        }
      },
      {
        "id": 3334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82875335970186,
            21.025938814356323,
            105.82877111845607,
            21.026008971581994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82875335970186,
              21.025938814356323
            ],
            [
              105.82875822794021,
              21.025958046475335
            ],
            [
              105.82876830965493,
              21.02599788012508
            ],
            [
              105.82877111845607,
              21.026008971581994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P4_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3341",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025938814356323,
          "to_latitude": 21.026008971581994,
          "to_longitude": 105.82877111845607,
          "from_longitude": 105.82875335970186
        }
      },
      {
        "id": 3335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82906378472372,
            21.025883819562612,
            105.82907449304548,
            21.025945271524655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82906378472372,
              21.025883819562612
            ],
            [
              105.82907105166693,
              21.02592538846063
            ],
            [
              105.82907449304548,
              21.025945271524655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P8_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.3342",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025883819562612,
          "to_latitude": 21.025945271524655,
          "to_longitude": 105.82907449304548,
          "from_longitude": 105.82906378472372
        }
      },
      {
        "id": 3336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82795855156965,
            21.02315454559048,
            105.82796000603524,
            21.02315906457019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82796000603524,
              21.02315906457019
            ],
            [
              105.82795961452788,
              21.023157848928857
            ],
            [
              105.82795884326622,
              21.02315545552445
            ],
            [
              105.82795855156965,
              21.02315454559048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F2_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3343",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02315906457019,
          "to_latitude": 21.02315454559048,
          "to_longitude": 105.82795855156965,
          "from_longitude": 105.82796000603524
        }
      },
      {
        "id": 3337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915303611131,
            21.021526571495066,
            105.8291779318835,
            21.02153912484597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291779318835,
              21.021526571495066
            ],
            [
              105.82915390524037,
              21.02153868706119
            ],
            [
              105.82915303611131,
              21.02153912484597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H5*_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3344",
          "diaChiLapD": "Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021526571495066,
          "to_latitude": 21.02153912484597,
          "to_longitude": 105.82915303611131,
          "from_longitude": 105.8291779318835
        }
      },
      {
        "id": 3338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82930207887804,
            21.021515253310156,
            105.82943016954194,
            21.02160622364545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82943016954194,
              21.02160622364545
            ],
            [
              105.8293794656629,
              21.021515253310156
            ],
            [
              105.82930207887804,
              21.02155496246975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3345",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02160622364545,
          "to_latitude": 21.02155496246975,
          "to_longitude": 105.82930207887804,
          "from_longitude": 105.82943016954194
        }
      },
      {
        "id": 3339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950902419988,
            21.02140345420578,
            105.82964135226958,
            21.021502280261252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964135226958,
              21.021502280261252
            ],
            [
              105.82958684929174,
              21.02140345420578
            ],
            [
              105.82950902419988,
              21.021444076045277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7*_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3346",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021502280261252,
          "to_latitude": 21.021444076045277,
          "to_longitude": 105.82950902419988,
          "from_longitude": 105.82964135226958
        }
      },
      {
        "id": 3340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83048543827101,
            21.026922474209048,
            105.830534923147,
            21.027200995490055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830534923147,
              21.027200995490055
            ],
            [
              105.83048601918017,
              21.026925747365947
            ],
            [
              105.83048543827101,
              21.026922474209048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S4*_Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3347",
          "diaChiLapD": "Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027200995490055,
          "to_latitude": 21.026922474209048,
          "to_longitude": 105.83048543827101,
          "from_longitude": 105.830534923147
        }
      },
      {
        "id": 3341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82724087148699,
            21.024500607889916,
            105.82726218085554,
            21.024505972827313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82726218085554,
              21.024500607889916
            ],
            [
              105.82725318906488,
              21.02450286994817
            ],
            [
              105.82724087148699,
              21.024505972827313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8#_Hào Nam",
          "maTaiSan": "04.O13.DODV.3348",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024500607889916,
          "to_latitude": 21.024505972827313,
          "to_longitude": 105.82724087148699,
          "from_longitude": 105.82726218085554
        }
      },
      {
        "id": 3342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694244658002,
            21.023356432850182,
            105.82697158884689,
            21.023362396735095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82697158884689,
              21.023356432850182
            ],
            [
              105.82696025980111,
              21.0233587514171
            ],
            [
              105.82694244658002,
              21.023362396735095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6#_Hào Nam",
          "maTaiSan": "04.O13.DODV.3349",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023356432850182,
          "to_latitude": 21.023362396735095,
          "to_longitude": 105.82694244658002,
          "from_longitude": 105.82697158884689
        }
      },
      {
        "id": 3343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82707558761112,
            21.023892141129746,
            105.82711570714403,
            21.023900392100202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711570714403,
              21.023892141129746
            ],
            [
              105.82709657655968,
              21.023896073774992
            ],
            [
              105.82707558761112,
              21.023900392100202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7#_Hào Nam",
          "maTaiSan": "04.O13.DODV.3350",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023892141129746,
          "to_latitude": 21.023900392100202,
          "to_longitude": 105.82707558761112,
          "from_longitude": 105.82711570714403
        }
      },
      {
        "id": 3344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82718121422924,
            21.023333422680146,
            105.82718937709669,
            21.02333701115192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718937709669,
              21.023333422680146
            ],
            [
              105.82718765354957,
              21.02333418073954
            ],
            [
              105.82718466708548,
              21.02333549319877
            ],
            [
              105.82718121422924,
              21.02333701115192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DODV.3351",
          "diaChiLapD": "Ngõ 46, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023333422680146,
          "to_latitude": 21.02333701115192,
          "to_longitude": 105.82718121422924,
          "from_longitude": 105.82718937709669
        }
      },
      {
        "id": 3345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82753588249429,
            21.024118162388188,
            105.82753849965313,
            21.024124360228143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82753849965313,
              21.024124360228143
            ],
            [
              105.82753846943334,
              21.02412428811719
            ],
            [
              105.82753813219593,
              21.024123493113738
            ],
            [
              105.82753683772954,
              21.024120423931514
            ],
            [
              105.82753588249429,
              21.024118162388188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G2_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DODV.3352",
          "diaChiLapD": "Ngõ 86, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024124360228143,
          "to_latitude": 21.024118162388188,
          "to_longitude": 105.82753588249429,
          "from_longitude": 105.82753849965313
        }
      },
      {
        "id": 3346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909081586547,
            21.024075697774002,
            105.82912406941982,
            21.024080115915176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912406941982,
              21.024080115915176
            ],
            [
              105.82910035525273,
              21.024076965462037
            ],
            [
              105.82909365198465,
              21.024076075947683
            ],
            [
              105.82909081586547,
              21.024075697774002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3353",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024080115915176,
          "to_latitude": 21.024075697774002,
          "to_longitude": 105.82909081586547,
          "from_longitude": 105.82912406941982
        }
      },
      {
        "id": 3347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892620102093,
            21.024782929074803,
            105.8289458954405,
            21.02478319939015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289458954405,
              21.024782929074803
            ],
            [
              105.82894401776906,
              21.02478295538815
            ],
            [
              105.8289341570892,
              21.02478309016006
            ],
            [
              105.82892620102093,
              21.02478319939015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3354",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024782929074803,
          "to_latitude": 21.02478319939015,
          "to_longitude": 105.82892620102093,
          "from_longitude": 105.8289458954405
        }
      },
      {
        "id": 3348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83076311956629,
            21.026114916618013,
            105.83076324070103,
            21.02611857617081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83076311956629,
              21.02611857617081
            ],
            [
              105.83076317370113,
              21.02611706119945
            ],
            [
              105.83076324070103,
              21.026114916618013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U2_Ngách 20, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3355",
          "diaChiLapD": "Ngách 20, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02611857617081,
          "to_latitude": 21.026114916618013,
          "to_longitude": 105.83076324070103,
          "from_longitude": 105.83076311956629
        }
      },
      {
        "id": 3349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83119773821582,
            21.026779864586217,
            105.83129872396617,
            21.02753097933762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124828796008,
              21.026779864586217
            ],
            [
              105.83123997198305,
              21.02678743089767
            ],
            [
              105.83119773821582,
              21.02682583648276
            ],
            [
              105.83122192267739,
              21.02700115778995
            ],
            [
              105.8312250541261,
              21.027035528220274
            ],
            [
              105.83122761437278,
              21.027063521068275
            ],
            [
              105.83123002131804,
              21.02708989066879
            ],
            [
              105.8312665139639,
              21.027275294099862
            ],
            [
              105.83128877656038,
              21.02745201484049
            ],
            [
              105.83129872396617,
              21.02753097933762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M91_Ngõ 68, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3356",
          "diaChiLapD": "Ngõ 68, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026779864586217,
          "to_latitude": 21.02753097933762,
          "to_longitude": 105.83129872396617,
          "from_longitude": 105.83124828796008
        }
      },
      {
        "id": 3350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128877656038,
            21.027376812719666,
            105.83141988877435,
            21.02745201484049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128877656038,
              21.02745201484049
            ],
            [
              105.83135065891163,
              21.027443323176012
            ],
            [
              105.83134935885862,
              21.02739538008379
            ],
            [
              105.83138976020483,
              21.02738474490906
            ],
            [
              105.83141988877435,
              21.027376812719666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M91_Ngõ 68, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3357",
          "diaChiLapD": "Ngõ 68, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02745201484049,
          "to_latitude": 21.027376812719666,
          "to_longitude": 105.83141988877435,
          "from_longitude": 105.83128877656038
        }
      },
      {
        "id": 3351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118095676772,
            21.02677053101048,
            105.83127496325325,
            21.02752405124771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124285953801,
              21.02677053101048
            ],
            [
              105.83118095676772,
              21.026822163804255
            ],
            [
              105.83119495813006,
              21.026913475444207
            ],
            [
              105.83120293625821,
              21.026988632555145
            ],
            [
              105.83121198061149,
              21.027073734368503
            ],
            [
              105.83124403928247,
              21.02724525253944
            ],
            [
              105.83126409093138,
              21.027380993983872
            ],
            [
              105.83127496325325,
              21.02752405124771
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M81_Ngõ 68, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3358",
          "diaChiLapD": "Ngõ 68, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02677053101048,
          "to_latitude": 21.02752405124771,
          "to_longitude": 105.83127496325325,
          "from_longitude": 105.83124285953801
        }
      },
      {
        "id": 3352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83087007355894,
            21.027380993983872,
            105.83126409093138,
            21.027462903701625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83126409093138,
              21.027380993983872
            ],
            [
              105.83087007355894,
              21.027462903701625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M81_Ngõ 68, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3359",
          "diaChiLapD": "Ngõ 68, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027380993983872,
          "to_latitude": 21.027462903701625,
          "to_longitude": 105.83087007355894,
          "from_longitude": 105.83126409093138
        }
      },
      {
        "id": 3353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799147434193,
            21.022748960823808,
            105.82811272594846,
            21.022831055561984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82811272594846,
              21.022831055561984
            ],
            [
              105.82809077798026,
              21.022748960823808
            ],
            [
              105.82799147434193,
              21.022781535864496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C8_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3360",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022831055561984,
          "to_latitude": 21.022781535864496,
          "to_longitude": 105.82799147434193,
          "from_longitude": 105.82811272594846
        }
      },
      {
        "id": 3354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905107310748,
            21.022601530976786,
            105.82924574140307,
            21.02265553359558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924574140307,
              21.022601530976786
            ],
            [
              105.82919397382511,
              21.022615892637756
            ],
            [
              105.82905107310748,
              21.02265553359558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3361",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022601530976786,
          "to_latitude": 21.02265553359558,
          "to_longitude": 105.82905107310748,
          "from_longitude": 105.82924574140307
        }
      },
      {
        "id": 3355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290166930215,
            21.022501417730847,
            105.82905107310748,
            21.02265553359558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905107310748,
              21.02265553359558
            ],
            [
              105.82902639455577,
              21.022546993232496
            ],
            [
              105.82902349287745,
              21.022533360552153
            ],
            [
              105.8290166930215,
              21.022501417730847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3362",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02265553359558,
          "to_latitude": 21.022501417730847,
          "to_longitude": 105.8290166930215,
          "from_longitude": 105.82905107310748
        }
      },
      {
        "id": 3356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290166930215,
            21.022454344034514,
            105.82919185872306,
            21.022501417730847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290166930215,
              21.022501417730847
            ],
            [
              105.82912050530926,
              21.02248333042987
            ],
            [
              105.82917189148117,
              21.022462455691066
            ],
            [
              105.82919185872306,
              21.022454344034514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3363",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022501417730847,
          "to_latitude": 21.022454344034514,
          "to_longitude": 105.82919185872306,
          "from_longitude": 105.8290166930215
        }
      },
      {
        "id": 3357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899621707264,
            21.022522882703015,
            105.82900357001978,
            21.022524354669287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82899621707264,
              21.022524354669287
            ],
            [
              105.82900244093995,
              21.022523108593127
            ],
            [
              105.82900357001978,
              21.022522882703015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K3_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3364",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022524354669287,
          "to_latitude": 21.022522882703015,
          "to_longitude": 105.82900357001978,
          "from_longitude": 105.82899621707264
        }
      },
      {
        "id": 3358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83105406442074,
            21.026095688040247,
            105.83106360469178,
            21.026100013234732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83106360469178,
              21.026095688040247
            ],
            [
              105.83105927596735,
              21.026097650222756
            ],
            [
              105.83105406442074,
              21.026100013234732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S9_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3365",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026095688040247,
          "to_latitude": 21.026100013234732,
          "to_longitude": 105.83105406442074,
          "from_longitude": 105.83106360469178
        }
      },
      {
        "id": 3359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83038832587638,
            21.026357803796035,
            105.83045494898215,
            21.026491058094084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83045494898215,
              21.026491058094084
            ],
            [
              105.83038832587638,
              21.026357803796035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U1_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3366",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026491058094084,
          "to_latitude": 21.026357803796035,
          "to_longitude": 105.83038832587638,
          "from_longitude": 105.83045494898215
        }
      },
      {
        "id": 3360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83116464317649,
            21.026674713838787,
            105.83118714744452,
            21.026685642649152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83116464317649,
              21.026685642649152
            ],
            [
              105.83117963130857,
              21.02667836347145
            ],
            [
              105.83118714744452,
              21.026674713838787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M93_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3367",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026685642649152,
          "to_latitude": 21.026674713838787,
          "to_longitude": 105.83118714744452,
          "from_longitude": 105.83116464317649
        }
      },
      {
        "id": 3361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83111143325308,
            21.02707392142165,
            105.83112884137508,
            21.027133879962065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83112884137508,
              21.027133879962065
            ],
            [
              105.83111143325308,
              21.02707392142165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KTBS02_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3368",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027133879962065,
          "to_latitude": 21.02707392142165,
          "to_longitude": 105.83111143325308,
          "from_longitude": 105.83112884137508
        }
      },
      {
        "id": 3362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82994170261257,
            21.020030380299414,
            105.82995213803795,
            21.02004446304669
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82994170261257,
              21.020030380299414
            ],
            [
              105.82994527025079,
              21.02003519424245
            ],
            [
              105.82994669304561,
              21.020037114782365
            ],
            [
              105.82994784253397,
              21.020038668140028
            ],
            [
              105.82995213803795,
              21.02004446304669
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3#_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3369",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020030380299414,
          "to_latitude": 21.02004446304669,
          "to_longitude": 105.82995213803795,
          "from_longitude": 105.82994170261257
        }
      },
      {
        "id": 3363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82802400025606,
            21.022317620888618,
            105.8280760045145,
            21.022398451387264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82804391093396,
              21.022398451387264
            ],
            [
              105.82802400025606,
              21.022334245925176
            ],
            [
              105.82803909018567,
              21.02232942240192
            ],
            [
              105.82804530419244,
              21.022327435764257
            ],
            [
              105.8280760045145,
              21.022317620888618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3370",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022398451387264,
          "to_latitude": 21.022317620888618,
          "to_longitude": 105.8280760045145,
          "from_longitude": 105.82804391093396
        }
      },
      {
        "id": 3364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818287526231,
            21.022018068376884,
            105.82821342060255,
            21.022122217404625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82821342060255,
              21.022122217404625
            ],
            [
              105.82818287526231,
              21.022024231644494
            ],
            [
              105.82821155724967,
              21.022018068376884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3371",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022122217404625,
          "to_latitude": 21.022018068376884,
          "to_longitude": 105.82821155724967,
          "from_longitude": 105.82821342060255
        }
      },
      {
        "id": 3365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023710528028,
            21.027540511702664,
            105.83146964781473,
            21.027675837647312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83146777911708,
              21.027658199257537
            ],
            [
              105.83146964781473,
              21.027657589476565
            ],
            [
              105.83146676694358,
              21.027651931316832
            ],
            [
              105.8314649134096,
              21.02764851083873
            ],
            [
              105.83145824107676,
              21.027636405940804
            ],
            [
              105.83130756912304,
              21.027569042210988
            ],
            [
              105.83103304459836,
              21.027540511702664
            ],
            [
              105.8304163682332,
              21.02764270007411
            ],
            [
              105.83023710528028,
              21.027675837647312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KTBS01_An Trạch",
          "maTaiSan": "04.O13.DODV.3372",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 133,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027658199257537,
          "to_latitude": 21.027675837647312,
          "to_longitude": 105.83023710528028,
          "from_longitude": 105.83146777911708
        }
      },
      {
        "id": 3366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83022449744384,
            21.027243808085327,
            105.8304633961835,
            21.027494210257345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8304633961835,
              21.027243808085327
            ],
            [
              105.83029200196819,
              21.027247237267535
            ],
            [
              105.83022449744384,
              21.027494210257345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S4_Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3373",
          "diaChiLapD": "Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027243808085327,
          "to_latitude": 21.027494210257345,
          "to_longitude": 105.83022449744384,
          "from_longitude": 105.8304633961835
        }
      },
      {
        "id": 3367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282858619487,
            21.026120689130288,
            105.82857144325322,
            21.026640632722923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82857144325322,
              21.026626225475685
            ],
            [
              105.82851769947726,
              21.026640632722923
            ],
            [
              105.82837571084482,
              21.026251874793743
            ],
            [
              105.82830678469577,
              21.02618634648817
            ],
            [
              105.82828761238835,
              21.026126186661664
            ],
            [
              105.8282858619487,
              21.026120689130288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P3A_Ngách 168/1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3374",
          "diaChiLapD": "Ngách 168/1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026626225475685,
          "to_latitude": 21.026120689130288,
          "to_longitude": 105.8282858619487,
          "from_longitude": 105.82857144325322
        }
      },
      {
        "id": 3368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82982083486408,
            21.028141960041467,
            105.83022303059818,
            21.02823663916921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982083486408,
              21.02823663916921
            ],
            [
              105.82982471055527,
              21.028235372000186
            ],
            [
              105.82984874633294,
              21.02822750684771
            ],
            [
              105.82984524397868,
              21.028210193779056
            ],
            [
              105.83022303059818,
              21.028141960041467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N8*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3375",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02823663916921,
          "to_latitude": 21.028141960041467,
          "to_longitude": 105.83022303059818,
          "from_longitude": 105.82982083486408
        }
      },
      {
        "id": 3369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82723906060997,
            21.02289156149679,
            105.82736436774306,
            21.02301968442205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82723906060997,
              21.022911079575074
            ],
            [
              105.82729984269918,
              21.02289156149679
            ],
            [
              105.82732336213599,
              21.022938263054947
            ],
            [
              105.82736436774306,
              21.02301968442205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3376",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022911079575074,
          "to_latitude": 21.02301968442205,
          "to_longitude": 105.82736436774306,
          "from_longitude": 105.82723906060997
        }
      },
      {
        "id": 3370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82730408514863,
            21.023045004218915,
            105.82730900660881,
            21.023052664918666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82730408514863,
              21.023045004218915
            ],
            [
              105.82730578708767,
              21.023047653228463
            ],
            [
              105.82730834292212,
              21.023051633954303
            ],
            [
              105.82730900660881,
              21.023052664918666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C3_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3377",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023045004218915,
          "to_latitude": 21.023052664918666,
          "to_longitude": 105.82730900660881,
          "from_longitude": 105.82730408514863
        }
      },
      {
        "id": 3371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83013600461517,
            21.01990345959188,
            105.83015540972559,
            21.019959282912865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83013995014316,
              21.019959282912865
            ],
            [
              105.83013600461517,
              21.019945115214725
            ],
            [
              105.83014032992669,
              21.01994048043323
            ],
            [
              105.83014474035552,
              21.01993575581696
            ],
            [
              105.83015540972559,
              21.01992432582272
            ],
            [
              105.8301416997271,
              21.01990345959188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O1_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3378",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019959282912865,
          "to_latitude": 21.01990345959188,
          "to_longitude": 105.8301416997271,
          "from_longitude": 105.83013995014316
        }
      },
      {
        "id": 3372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987239435619,
            21.020106939795298,
            105.82989111001355,
            21.020133296717404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82989111001355,
              21.020133296717404
            ],
            [
              105.82987829988305,
              21.020113026719567
            ],
            [
              105.82987239435619,
              21.020106939795298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O2_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3379",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020133296717404,
          "to_latitude": 21.020106939795298,
          "to_longitude": 105.82987239435619,
          "from_longitude": 105.82989111001355
        }
      },
      {
        "id": 3373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806961275902,
            21.022435281128715,
            105.82823937959445,
            21.022467814045196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806961275902,
              21.02244506363507
            ],
            [
              105.82807531318201,
              21.022443445302763
            ],
            [
              105.82810405250395,
              21.022435281128715
            ],
            [
              105.82811562492728,
              21.022467814045196
            ],
            [
              105.82823937959445,
              21.022436563226368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4BS_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3380",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02244506363507,
          "to_latitude": 21.022436563226368,
          "to_longitude": 105.82823937959445,
          "from_longitude": 105.82806961275902
        }
      },
      {
        "id": 3374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818595615022,
            21.02222086600286,
            105.82830371438038,
            21.022436563226368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82823937959445,
              21.022436563226368
            ],
            [
              105.82818595615022,
              21.02224902212029
            ],
            [
              105.82830371438038,
              21.02222086600286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4BS_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3381",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022436563226368,
          "to_latitude": 21.02222086600286,
          "to_longitude": 105.82830371438038,
          "from_longitude": 105.82823937959445
        }
      },
      {
        "id": 3375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853456594422,
            21.022425466730006,
            105.82859328318821,
            21.02243768437159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82853456594422,
              21.02243768437159
            ],
            [
              105.82859328318821,
              21.022425466730006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3382",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02243768437159,
          "to_latitude": 21.022425466730006,
          "to_longitude": 105.82859328318821,
          "from_longitude": 105.82853456594422
        }
      },
      {
        "id": 3376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82788143046928,
            21.022597263580217,
            105.8278966275516,
            21.022637626087675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278966275516,
              21.022637626087675
            ],
            [
              105.82788143046928,
              21.022597263580217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6BS1_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3383",
          "diaChiLapD": "Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022637626087675,
          "to_latitude": 21.022597263580217,
          "to_longitude": 105.82788143046928,
          "from_longitude": 105.8278966275516
        }
      },
      {
        "id": 3377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83099106965835,
            21.025846237672145,
            105.83101272198316,
            21.02585256398025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101272198316,
              21.025846237672145
            ],
            [
              105.83101167221977,
              21.02584654538193
            ],
            [
              105.83099106965835,
              21.02585256398025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3384",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025846237672145,
          "to_latitude": 21.02585256398025,
          "to_longitude": 105.83099106965835,
          "from_longitude": 105.83101272198316
        }
      },
      {
        "id": 3378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100903628373,
            21.025923342263738,
            105.83105228464993,
            21.02593157423386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83105228464993,
              21.025923342263738
            ],
            [
              105.83102140902476,
              21.025929219584363
            ],
            [
              105.83101787737296,
              21.025929888969912
            ],
            [
              105.83100903628373,
              21.02593157423386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3*_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3385",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025923342263738,
          "to_latitude": 21.02593157423386,
          "to_longitude": 105.83100903628373,
          "from_longitude": 105.83105228464993
        }
      },
      {
        "id": 3379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82769682762745,
            21.022814865590913,
            105.82772634177526,
            21.022916196578386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82772634177526,
              21.022916196578386
            ],
            [
              105.82770132058867,
              21.02283029068019
            ],
            [
              105.82769682762745,
              21.022814865590913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C5_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3386",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022916196578386,
          "to_latitude": 21.022814865590913,
          "to_longitude": 105.82769682762745,
          "from_longitude": 105.82772634177526
        }
      },
      {
        "id": 3380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832538307323,
            21.022824957444254,
            105.82842587767661,
            21.023183622714367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832538307323,
              21.022824957444254
            ],
            [
              105.82842587767661,
              21.023183622714367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C9*_Ngách 44/55, Hào Nam",
          "maTaiSan": "04.O13.DODV.3387",
          "diaChiLapD": "Ngách 44/55, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022824957444254,
          "to_latitude": 21.023183622714367,
          "to_longitude": 105.82842587767661,
          "from_longitude": 105.82832538307323
        }
      },
      {
        "id": 3381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82922231494841,
            21.023406957156535,
            105.82923019812453,
            21.023408578944395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82922231494841,
              21.023408578944395
            ],
            [
              105.82922469508476,
              21.023408089534055
            ],
            [
              105.8292264535213,
              21.023407727799825
            ],
            [
              105.82922821195787,
              21.02340736606557
            ],
            [
              105.82923019812453,
              21.023406957156535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3388",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023408578944395,
          "to_latitude": 21.023406957156535,
          "to_longitude": 105.82923019812453,
          "from_longitude": 105.82922231494841
        }
      },
      {
        "id": 3382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83117185291252,
            21.026687113284535,
            105.83119435718183,
            21.026698042095877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83117185291252,
              21.026698042095877
            ],
            [
              105.83118809981069,
              21.026690151604946
            ],
            [
              105.83119435718183,
              21.026687113284535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M92_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3389",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026698042095877,
          "to_latitude": 21.026687113284535,
          "to_longitude": 105.83119435718183,
          "from_longitude": 105.83117185291252
        }
      },
      {
        "id": 3383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924899838987,
            21.023476680612116,
            105.82968595043245,
            21.023573084791153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924899838987,
              21.023478643855572
            ],
            [
              105.82926522492619,
              21.023476680612116
            ],
            [
              105.82929062287502,
              21.023524253359856
            ],
            [
              105.82936827288823,
              21.02352221098314
            ],
            [
              105.82939352848616,
              21.023573084791153
            ],
            [
              105.82942483676507,
              21.023564107606028
            ],
            [
              105.82949753048018,
              21.023543258913254
            ],
            [
              105.82959251583299,
              21.02351710520724
            ],
            [
              105.82968595043245,
              21.02349842777831
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3390",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023478643855572,
          "to_latitude": 21.02349842777831,
          "to_longitude": 105.82968595043245,
          "from_longitude": 105.82924899838987
        }
      },
      {
        "id": 3384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82922046339544,
            21.023305830069177,
            105.82949509579777,
            21.023478643855572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924899838987,
              21.023478643855572
            ],
            [
              105.82924719019499,
              21.023471748422338
            ],
            [
              105.82923257628909,
              21.02341602567937
            ],
            [
              105.82923019812453,
              21.023406957156535
            ],
            [
              105.82922657584697,
              21.023393144640735
            ],
            [
              105.82922046339544,
              21.023369839632252
            ],
            [
              105.82934360071503,
              21.02334080752169
            ],
            [
              105.82935909735357,
              21.02333715337937
            ],
            [
              105.82938298085043,
              21.023410412176187
            ],
            [
              105.82949509579777,
              21.023378786354634
            ],
            [
              105.8294703038863,
              21.023305830069177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U5_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3391",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023478643855572,
          "to_latitude": 21.023305830069177,
          "to_longitude": 105.8294703038863,
          "from_longitude": 105.82924899838987
        }
      },
      {
        "id": 3385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82922292235672,
            21.023415371013467,
            105.82958216281922,
            21.023569513602038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82922292235672,
              21.02341734719794
            ],
            [
              105.82922757966408,
              21.02341671029813
            ],
            [
              105.82923257628909,
              21.02341602567937
            ],
            [
              105.82923735759448,
              21.023415371013467
            ],
            [
              105.82925271280938,
              21.023471371947657
            ],
            [
              105.82927042209666,
              21.023470163795842
            ],
            [
              105.82929500784533,
              21.02352046824162
            ],
            [
              105.82937134575427,
              21.023516869682695
            ],
            [
              105.82939516238947,
              21.023569513602038
            ],
            [
              105.8294236889764,
              21.023560952820485
            ],
            [
              105.82956071506973,
              21.023519829091093
            ],
            [
              105.82958216281922,
              21.023509089910416
            ],
            [
              105.82955803919573,
              21.02344235179223
            ],
            [
              105.8295787013474,
              21.023435502116325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U5*_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3392",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02341734719794,
          "to_latitude": 21.023435502116325,
          "to_longitude": 105.8295787013474,
          "from_longitude": 105.82922292235672
        }
      },
      {
        "id": 3386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987925156306,
            21.020101085959162,
            105.82991162857829,
            21.02014284470232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82987925156306,
              21.020101085959162
            ],
            [
              105.82988517193402,
              21.020109159905623
            ],
            [
              105.82990064627559,
              21.020136303994477
            ],
            [
              105.82990462557335,
              21.02014284470232
            ],
            [
              105.82990715869255,
              21.02014132757065
            ],
            [
              105.82991162857829,
              21.020138652990617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O4_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3393",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020101085959162,
          "to_latitude": 21.020138652990617,
          "to_longitude": 105.82991162857829,
          "from_longitude": 105.82987925156306
        }
      },
      {
        "id": 3387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82988878474383,
            21.02854256555154,
            105.82991373399562,
            21.028825076376563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988878474383,
              21.02854256555154
            ],
            [
              105.82991373399562,
              21.028599769609954
            ],
            [
              105.82990091476994,
              21.028825076376563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8*_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3394",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02854256555154,
          "to_latitude": 21.028825076376563,
          "to_longitude": 105.82990091476994,
          "from_longitude": 105.82988878474383
        }
      },
      {
        "id": 3388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82985609348209,
            21.028354393149367,
            105.82988159178103,
            21.02836117344643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988159178103,
              21.028354393149367
            ],
            [
              105.82986429128614,
              21.028358993701428
            ],
            [
              105.82986208189703,
              21.02835958164396
            ],
            [
              105.82985609348209,
              21.02836117344643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2#_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3395",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028354393149367,
          "to_latitude": 21.02836117344643,
          "to_longitude": 105.82985609348209,
          "from_longitude": 105.82988159178103
        }
      },
      {
        "id": 3389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82974938453587,
            21.028057934488494,
            105.82983447746494,
            21.02823547908437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982035031876,
              21.02823547908437
            ],
            [
              105.82982462484573,
              21.028233791776287
            ],
            [
              105.82983447746494,
              21.028229901379966
            ],
            [
              105.82979759364636,
              21.028116937197506
            ],
            [
              105.82976617200976,
              21.028098650302947
            ],
            [
              105.82975489366862,
              21.028084984459117
            ],
            [
              105.82974938453587,
              21.028057934488494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3#_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DODV.3396",
          "diaChiLapD": "Ngõ 27, Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02823547908437,
          "to_latitude": 21.028057934488494,
          "to_longitude": 105.82974938453587,
          "from_longitude": 105.82982035031876
        }
      },
      {
        "id": 3390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83036779302455,
            21.02800715402557,
            105.83052430529617,
            21.028539852756317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83047773970509,
              21.028539852756317
            ],
            [
              105.83036779302455,
              21.028036533410702
            ],
            [
              105.83052430529617,
              21.02800715402557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6*_Ngõ 25B, Cát Linh",
          "maTaiSan": "04.O13.DODV.3397",
          "diaChiLapD": "Ngõ 25B, Cát Linh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028539852756317,
          "to_latitude": 21.02800715402557,
          "to_longitude": 105.83052430529617,
          "from_longitude": 105.83047773970509
        }
      },
      {
        "id": 3391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82965547435413,
            21.027017995849686,
            105.82966900333054,
            21.027035243749825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965547435413,
              21.027017995849686
            ],
            [
              105.82966598150014,
              21.027031391789905
            ],
            [
              105.82966779228161,
              21.02703370153201
            ],
            [
              105.82966900333054,
              21.027035243749825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q9_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3398",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027017995849686,
          "to_latitude": 21.027035243749825,
          "to_longitude": 105.82966900333054,
          "from_longitude": 105.82965547435413
        }
      },
      {
        "id": 3392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83027178183339,
            21.02686101980333,
            105.83028345633396,
            21.026910267100494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83028345633396,
              21.026910267100494
            ],
            [
              105.83027203304702,
              21.026862085284545
            ],
            [
              105.83027178183339,
              21.02686101980333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S2*_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3399",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026910267100494,
          "to_latitude": 21.02686101980333,
          "to_longitude": 105.83027178183339,
          "from_longitude": 105.83028345633396
        }
      },
      {
        "id": 3393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83041251690771,
            21.02727188928242,
            105.83056044630307,
            21.027537665019054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83041251690771,
              21.02750648379028
            ],
            [
              105.83044692622825,
              21.02742764945526
            ],
            [
              105.8304457400173,
              21.027272395545538
            ],
            [
              105.83046432905836,
              21.02727188928242
            ],
            [
              105.83049720227996,
              21.027271944610817
            ],
            [
              105.83051756025121,
              21.027329742751952
            ],
            [
              105.83056044630307,
              21.027537665019054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S4_Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3400",
          "diaChiLapD": "Ngách 2, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02750648379028,
          "to_latitude": 21.027537665019054,
          "to_longitude": 105.83056044630307,
          "from_longitude": 105.83041251690771
        }
      },
      {
        "id": 3394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83131909024276,
            21.02695479090225,
            105.8313483996346,
            21.027040800733456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8313483996346,
              21.027040800733456
            ],
            [
              105.83131909024276,
              21.02695479090225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M91_Ngách 68/8, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3401",
          "diaChiLapD": "Ngách 68/8, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027040800733456,
          "to_latitude": 21.02695479090225,
          "to_longitude": 105.83131909024276,
          "from_longitude": 105.8313483996346
        }
      },
      {
        "id": 3395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83122761437278,
            21.027040800733456,
            105.83140269957966,
            21.02722713139628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83122761437278,
              21.027063521068275
            ],
            [
              105.83123738687205,
              21.027061684013063
            ],
            [
              105.8313483996346,
              21.027040800733456
            ],
            [
              105.83140269957966,
              21.02722713139628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M91_Ngách 68/8, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3402",
          "diaChiLapD": "Ngách 68/8, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027063521068275,
          "to_latitude": 21.02722713139628,
          "to_longitude": 105.83140269957966,
          "from_longitude": 105.83122761437278
        }
      },
      {
        "id": 3396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83079760853724,
            21.027510739053152,
            105.83127496325325,
            21.02754319578411
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83127496325325,
              21.02752405124771
            ],
            [
              105.83103279392412,
              21.027510739053152
            ],
            [
              105.83079760853724,
              21.02754319578411
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M81_An Trạch",
          "maTaiSan": "04.O13.DODV.3403",
          "diaChiLapD": "An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02752405124771,
          "to_latitude": 21.02754319578411,
          "to_longitude": 105.83079760853724,
          "from_longitude": 105.83127496325325
        }
      },
      {
        "id": 3397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83067652368494,
            21.02682845824839,
            105.83068299217358,
            21.026838689986686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067652368494,
              21.02682845824839
            ],
            [
              105.83068022012539,
              21.026834259665
            ],
            [
              105.83068299217358,
              21.026838689986686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S5_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3404",
          "diaChiLapD": "Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02682845824839,
          "to_latitude": 21.026838689986686,
          "to_longitude": 105.83068299217358,
          "from_longitude": 105.83067652368494
        }
      },
      {
        "id": 3398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83115487696065,
            21.02653661415891,
            105.83117530573425,
            21.02653792785763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83117530573425,
              21.02653792785763
            ],
            [
              105.83115975415419,
              21.02653692812733
            ],
            [
              105.83115575944296,
              21.026536672168234
            ],
            [
              105.83115487696065,
              21.02653661415891
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S8_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3405",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02653792785763,
          "to_latitude": 21.02653661415891,
          "to_longitude": 105.83115487696065,
          "from_longitude": 105.83117530573425
        }
      },
      {
        "id": 3399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83055414931137,
            21.026020194697587,
            105.83056018219531,
            21.026022944672814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83055414931137,
              21.026022944672814
            ],
            [
              105.83055718926177,
              21.02602155873173
            ],
            [
              105.83056018219531,
              21.026020194697587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T9_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DODV.3406",
          "diaChiLapD": "Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026022944672814,
          "to_latitude": 21.026020194697587,
          "to_longitude": 105.83056018219531,
          "from_longitude": 105.83055414931137
        }
      },
      {
        "id": 3400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8305587965427,
            21.02560578047248,
            105.83055899063866,
            21.025611030959674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83055899063866,
              21.02560578047248
            ],
            [
              105.83055895722353,
              21.025606694698606
            ],
            [
              105.83055885252354,
              21.025609501527647
            ],
            [
              105.8305587965427,
              21.025611030959674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T7_Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3407",
          "diaChiLapD": "Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02560578047248,
          "to_latitude": 21.025611030959674,
          "to_longitude": 105.8305587965427,
          "from_longitude": 105.83055899063866
        }
      },
      {
        "id": 3401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8309194335908,
            21.025581742960412,
            105.83093008795512,
            21.02558899642367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83093008795512,
              21.025581742960412
            ],
            [
              105.83092926470222,
              21.025582303368907
            ],
            [
              105.8309194335908,
              21.02558899642367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T5_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3408",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025581742960412,
          "to_latitude": 21.02558899642367,
          "to_longitude": 105.8309194335908,
          "from_longitude": 105.83093008795512
        }
      },
      {
        "id": 3402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124834117515,
            21.02568040557722,
            105.83125356340305,
            21.025681533036398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83125356340305,
              21.02568040557722
            ],
            [
              105.83125112667234,
              21.025680929615806
            ],
            [
              105.83124834117515,
              21.025681533036398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3409",
          "diaChiLapD": "Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02568040557722,
          "to_latitude": 21.025681533036398,
          "to_longitude": 105.83124834117515,
          "from_longitude": 105.83125356340305
        }
      },
      {
        "id": 3403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83108356518598,
            21.02537324409169,
            105.8311442338607,
            21.02548951897514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109819235374,
              21.02537324409169
            ],
            [
              105.83110472386267,
              21.02538790943607
            ],
            [
              105.8311442338607,
              21.025476524958382
            ],
            [
              105.83108356518598,
              21.02548951897514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4_Ngách 99, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3410",
          "diaChiLapD": "Ngách 99, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02537324409169,
          "to_latitude": 21.02548951897514,
          "to_longitude": 105.83108356518598,
          "from_longitude": 105.83109819235374
        }
      },
      {
        "id": 3404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8311442338607,
            21.025476524958382,
            105.83126373447693,
            21.0257365869815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83126373447693,
              21.0257365869815
            ],
            [
              105.83125356340305,
              21.02568040557722
            ],
            [
              105.83124671986373,
              21.025642608388953
            ],
            [
              105.83123514953904,
              21.025575750487
            ],
            [
              105.8312384733074,
              21.025535681670405
            ],
            [
              105.8312243890535,
              21.025495847875142
            ],
            [
              105.83120382133907,
              21.025483156039513
            ],
            [
              105.83118789169887,
              21.025483352218696
            ],
            [
              105.8311442338607,
              21.025476524958382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4_Ngách 99, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DODV.3411",
          "diaChiLapD": "Ngách 99, Ngõ An Trạch 2, An Trạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0257365869815,
          "to_latitude": 21.025476524958382,
          "to_longitude": 105.8311442338607,
          "from_longitude": 105.83126373447693
        }
      },
      {
        "id": 3405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82854915473403,
            21.02093924582667,
            105.82890237885026,
            21.021112456573746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890237885026,
              21.02093924582667
            ],
            [
              105.8289010479149,
              21.02093967231221
            ],
            [
              105.82864997234887,
              21.021020101277106
            ],
            [
              105.82854915473403,
              21.021046210575854
            ],
            [
              105.82856364638104,
              21.021112456573746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9*_Ngách 53, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3412",
          "diaChiLapD": "Ngách 53, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02093924582667,
          "to_latitude": 21.021112456573746,
          "to_longitude": 105.82856364638104,
          "from_longitude": 105.82890237885026
        }
      },
      {
        "id": 3406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82864997234887,
            21.021020101277106,
            105.82872908589708,
            21.021272139229602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872908589708,
              21.021272139229602
            ],
            [
              105.82870785457864,
              21.02120450170022
            ],
            [
              105.82868473077959,
              21.021192164306683
            ],
            [
              105.82864997234887,
              21.021020101277106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9*_Ngách 53, Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DODV.3413",
          "diaChiLapD": "Ngách 53, Ngõ Cẩm Văn, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021272139229602,
          "to_latitude": 21.021020101277106,
          "to_longitude": 105.82864997234887,
          "from_longitude": 105.82872908589708
        }
      },
      {
        "id": 3407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8293773615592,
            21.020839624204903,
            105.82998751151845,
            21.021333156611618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82998751151845,
              21.020839624204903
            ],
            [
              105.82998206251978,
              21.02084389775669
            ],
            [
              105.82979468904416,
              21.02098700121768
            ],
            [
              105.829751896288,
              21.021004140021546
            ],
            [
              105.82959227769685,
              21.021074819172515
            ],
            [
              105.82957890574576,
              21.021050031255097
            ],
            [
              105.82956265316126,
              21.021056801611337
            ],
            [
              105.82955238563183,
              21.02104107957395
            ],
            [
              105.8293773615592,
              21.021112398174928
            ],
            [
              105.82941690471117,
              21.02121332409333
            ],
            [
              105.8294817591101,
              21.021333156611618
            ],
            [
              105.8296312487432,
              21.02127644114073
            ],
            [
              105.82978371538573,
              21.021206654903292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3414",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 138,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020839624204903,
          "to_latitude": 21.021206654903292,
          "to_longitude": 105.82978371538573,
          "from_longitude": 105.82998751151845
        }
      },
      {
        "id": 3408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82926034249559,
            21.021112398174928,
            105.8293773615592,
            21.02116008093467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8293773615592,
              21.021112398174928
            ],
            [
              105.82926034249559,
              21.02116008093467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3415",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021112398174928,
          "to_latitude": 21.02116008093467,
          "to_longitude": 105.82926034249559,
          "from_longitude": 105.8293773615592
        }
      },
      {
        "id": 3409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82923171634901,
            21.021102372550942,
            105.82926034249559,
            21.02116008093467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926034249559,
              21.02116008093467
            ],
            [
              105.82923171634901,
              21.021102372550942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5*_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3416",
          "diaChiLapD": "Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02116008093467,
          "to_latitude": 21.021102372550942,
          "to_longitude": 105.82923171634901,
          "from_longitude": 105.82926034249559
        }
      },
      {
        "id": 3410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280223746746,
            21.023553993193442,
            105.82829144494238,
            21.023638321104926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280223746746,
              21.023553993193442
            ],
            [
              105.82806498667425,
              21.023619265921166
            ],
            [
              105.8280778180697,
              21.023638321104926
            ],
            [
              105.82815047880216,
              21.02361406979127
            ],
            [
              105.82829144494238,
              21.023635335305105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_F6_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.3417",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023553993193442,
          "to_latitude": 21.023635335305105,
          "to_longitude": 105.82829144494238,
          "from_longitude": 105.8280223746746
        }
      },
      {
        "id": 3411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829144494238,
            21.02329832882137,
            105.82856054161843,
            21.02363568740364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82829144494238,
              21.023635335305105
            ],
            [
              105.82834062377242,
              21.02363568740364
            ],
            [
              105.8283730297354,
              21.023454503444665
            ],
            [
              105.82837996153403,
              21.02341928004322
            ],
            [
              105.82840741278547,
              21.02341770749991
            ],
            [
              105.82844809872198,
              21.023415376809922
            ],
            [
              105.82856054161843,
              21.02340893642526
            ],
            [
              105.82855860476649,
              21.02329832882137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F6_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.3418",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023635335305105,
          "to_latitude": 21.02329832882137,
          "to_longitude": 105.82855860476649,
          "from_longitude": 105.82829144494238
        }
      },
      {
        "id": 3412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829144494238,
            21.023635335305105,
            105.82865090447946,
            21.023735606206845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82829144494238,
              21.023635335305105
            ],
            [
              105.82829617182526,
              21.023638583764882
            ],
            [
              105.82830459330594,
              21.02364437126065
            ],
            [
              105.82847842214932,
              21.023645989491943
            ],
            [
              105.82847849310703,
              21.023735606206845
            ],
            [
              105.82865090447946,
              21.02372998639778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F6_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DODV.3419",
          "diaChiLapD": "Ngách 86/20, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023635335305105,
          "to_latitude": 21.02372998639778,
          "to_longitude": 105.82865090447946,
          "from_longitude": 105.82829144494238
        }
      },
      {
        "id": 3413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806577221577,
            21.023307406543694,
            105.8281241276311,
            21.023446626403654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806577221577,
              21.023307406543694
            ],
            [
              105.82808246630923,
              21.023343594259615
            ],
            [
              105.8281156841325,
              21.023415600192685
            ],
            [
              105.8281241276311,
              21.023446626403654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F3_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3420",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023307406543694,
          "to_latitude": 21.023446626403654,
          "to_longitude": 105.8281241276311,
          "from_longitude": 105.82806577221577
        }
      },
      {
        "id": 3414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82752360017773,
            21.02339195161576,
            105.82752689954225,
            21.02339765749275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82752360017773,
              21.02339195161576
            ],
            [
              105.8275254569512,
              21.023395113804423
            ],
            [
              105.82752689954225,
              21.02339765749275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F1#_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DODV.3421",
          "diaChiLapD": "Ngách 46/12, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02339195161576,
          "to_latitude": 21.02339765749275,
          "to_longitude": 105.82752689954225,
          "from_longitude": 105.82752360017773
        }
      },
      {
        "id": 3415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82843162563634,
            21.022777357702484,
            105.82846980148634,
            21.022783536550055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82846980148634,
              21.022777357702484
            ],
            [
              105.82843162563634,
              21.022783536550055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.3422",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022777357702484,
          "to_latitude": 21.022783536550055,
          "to_longitude": 105.82843162563634,
          "from_longitude": 105.82846980148634
        }
      },
      {
        "id": 3416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276055584067,
            21.02542442041876,
            105.82784046541381,
            21.02550128919726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276055584067,
              21.02550128919726
            ],
            [
              105.82784046541381,
              21.02542442041876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P1_Hào Nam",
          "maTaiSan": "04.O13.DODV.3423",
          "diaChiLapD": "Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02550128919726,
          "to_latitude": 21.02542442041876,
          "to_longitude": 105.82784046541381,
          "from_longitude": 105.8276055584067
        }
      },
      {
        "id": 3417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900268390978,
            21.024459888359484,
            105.82901829158978,
            21.02446157669506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900268390978,
              21.02446157669506
            ],
            [
              105.82900648055545,
              21.024461166188956
            ],
            [
              105.8290137085035,
              21.0244603839896
            ],
            [
              105.82901829158978,
              21.024459888359484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M8_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.3424",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02446157669506,
          "to_latitude": 21.024459888359484,
          "to_longitude": 105.82901829158978,
          "from_longitude": 105.82900268390978
        }
      },
      {
        "id": 3418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8293981536435,
            21.02256440054383,
            105.82953705125213,
            21.022609286259243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82953705125213,
              21.02256440054383
            ],
            [
              105.8293981536435,
              21.022609286259243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U8_Ngách 148, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3425",
          "diaChiLapD": "Ngách 148, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02256440054383,
          "to_latitude": 21.022609286259243,
          "to_longitude": 105.8293981536435,
          "from_longitude": 105.82953705125213
        }
      },
      {
        "id": 3419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82948542752798,
            21.02424066242804,
            105.82976149720056,
            21.024508493805598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82976149720056,
              21.024508493805598
            ],
            [
              105.82969678728026,
              21.024419941249253
            ],
            [
              105.82957780172426,
              21.02430617074631
            ],
            [
              105.82948542752798,
              21.02424066242804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_R2_Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3426",
          "diaChiLapD": "Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024508493805598,
          "to_latitude": 21.02424066242804,
          "to_longitude": 105.82948542752798,
          "from_longitude": 105.82976149720056
        }
      },
      {
        "id": 3420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82967752627269,
            21.02423866918038,
            105.8303571858467,
            21.024596042959324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303571858467,
              21.02423866918038
            ],
            [
              105.8303541200794,
              21.02423920892198
            ],
            [
              105.83033851732748,
              21.02424195322443
            ],
            [
              105.83032239360503,
              21.02424633851803
            ],
            [
              105.83029455506708,
              21.024253910675007
            ],
            [
              105.82998627319606,
              21.024366909459857
            ],
            [
              105.82995628724899,
              21.024398600909755
            ],
            [
              105.82984059288982,
              21.024465482012555
            ],
            [
              105.82976726143546,
              21.024505358589998
            ],
            [
              105.82976149720056,
              21.024508493805598
            ],
            [
              105.82974624048418,
              21.024525447700537
            ],
            [
              105.82969422396678,
              21.024577679332758
            ],
            [
              105.82967752627269,
              21.024596042959324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R2_Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3427",
          "diaChiLapD": "Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02423866918038,
          "to_latitude": 21.024596042959324,
          "to_longitude": 105.82967752627269,
          "from_longitude": 105.8303571858467
        }
      },
      {
        "id": 3421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83167578739712,
            21.02390410154581,
            105.83168326152322,
            21.023906799896743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83167578739712,
              21.023906799896743
            ],
            [
              105.83167739934852,
              21.02390621756357
            ],
            [
              105.8316802891541,
              21.02390517465979
            ],
            [
              105.83168326152322,
              21.02390410154581
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O8_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3428",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023906799896743,
          "to_latitude": 21.02390410154581,
          "to_longitude": 105.83168326152322,
          "from_longitude": 105.83167578739712
        }
      },
      {
        "id": 3422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83184305951674,
            21.024301050197128,
            105.8318574822178,
            21.02430564047466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184305951674,
              21.02430564047466
            ],
            [
              105.83184483414422,
              21.024305075408645
            ],
            [
              105.83185132287275,
              21.024303010513673
            ],
            [
              105.8318574822178,
              21.024301050197128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O5_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3429",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02430564047466,
          "to_latitude": 21.024301050197128,
          "to_longitude": 105.8318574822178,
          "from_longitude": 105.83184305951674
        }
      },
      {
        "id": 3423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971538384935,
            21.02516069398025,
            105.83035833293205,
            21.02536420420889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83035833293205,
              21.02516069398025
            ],
            [
              105.83035163656771,
              21.025162276608448
            ],
            [
              105.83034318206948,
              21.025164278787468
            ],
            [
              105.83034858915941,
              21.025187150930357
            ],
            [
              105.83033930375738,
              21.025189548262247
            ],
            [
              105.83004603793779,
              21.025271644123762
            ],
            [
              105.82971538384935,
              21.02536420420889
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R72_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3430",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02516069398025,
          "to_latitude": 21.02536420420889,
          "to_longitude": 105.82971538384935,
          "from_longitude": 105.83035833293205
        }
      },
      {
        "id": 3424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297132714414,
            21.025174504131783,
            105.83036206309272,
            21.025459081802104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83036206309272,
              21.025174504131783
            ],
            [
              105.83035742250186,
              21.025176257367566
            ],
            [
              105.83035491117404,
              21.0251772071761
            ],
            [
              105.83034972501692,
              21.025201867037637
            ],
            [
              105.83034579764357,
              21.025217409929358
            ],
            [
              105.83005310262428,
              21.02529106148247
            ],
            [
              105.8297132714414,
              21.025376571528025
            ],
            [
              105.82973496810023,
              21.025459081802104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R7_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3431",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025174504131783,
          "to_latitude": 21.025459081802104,
          "to_longitude": 105.82973496810023,
          "from_longitude": 105.83036206309272
        }
      },
      {
        "id": 3425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028500922683,
            21.024158528397596,
            105.83032882164241,
            21.0245382751606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83028500922683,
              21.0245382751606
            ],
            [
              105.83028698816855,
              21.024435982561858
            ],
            [
              105.83029594043526,
              21.024336439655762
            ],
            [
              105.83029657842509,
              21.024329351648756
            ],
            [
              105.8303022153682,
              21.02431122531807
            ],
            [
              105.83031861391275,
              21.024258493597625
            ],
            [
              105.83032100043637,
              21.02425081808704
            ],
            [
              105.83032162518676,
              21.024248808975475
            ],
            [
              105.83032239360503,
              21.02424633851803
            ],
            [
              105.83032882164241,
              21.024225668457138
            ],
            [
              105.83029454920546,
              21.024158528397596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R32_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3432",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0245382751606,
          "to_latitude": 21.024158528397596,
          "to_longitude": 105.83029454920546,
          "from_longitude": 105.83028500922683
        }
      },
      {
        "id": 3426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109289829471,
            21.02383531300037,
            105.83109827111635,
            21.023847133435602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109827111635,
              21.023847133435602
            ],
            [
              105.83109531705153,
              21.023840576010883
            ],
            [
              105.83109289829471,
              21.02383531300037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q71_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3433",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023847133435602,
          "to_latitude": 21.02383531300037,
          "to_longitude": 105.83109289829471,
          "from_longitude": 105.83109827111635
        }
      },
      {
        "id": 3427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82963901845467,
            21.025087122476243,
            105.82971538384935,
            21.02536420420889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82971538384935,
              21.02536420420889
            ],
            [
              105.82963901845467,
              21.025087122476243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R72_Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3434",
          "diaChiLapD": "Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02536420420889,
          "to_latitude": 21.025087122476243,
          "to_longitude": 105.82963901845467,
          "from_longitude": 105.82971538384935
        }
      },
      {
        "id": 3428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83147670875843,
            21.023506365376083,
            105.83193854805522,
            21.02362139463304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83147670875843,
              21.023573652564973
            ],
            [
              105.83162738980513,
              21.023506365376083
            ],
            [
              105.83168347726638,
              21.02361644073269
            ],
            [
              105.83168575195094,
              21.02362139463304
            ],
            [
              105.83170543351491,
              21.023613461110813
            ],
            [
              105.83174246709497,
              21.023598532160197
            ],
            [
              105.83187331097032,
              21.023545477225717
            ],
            [
              105.83193854805522,
              21.02351407456986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q5_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3435",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023573652564973,
          "to_latitude": 21.02351407456986,
          "to_longitude": 105.83193854805522,
          "from_longitude": 105.83147670875843
        }
      },
      {
        "id": 3429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83041989238777,
            21.022597082473894,
            105.83042038749339,
            21.022605476750737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83041989238777,
              21.022605476750737
            ],
            [
              105.83042006385206,
              21.022602565723982
            ],
            [
              105.83042015870431,
              21.02260093313407
            ],
            [
              105.83042025644237,
              21.02259930053007
            ],
            [
              105.83042038749339,
              21.022597082473894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U2_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3436",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022605476750737,
          "to_latitude": 21.022597082473894,
          "to_longitude": 105.83042038749339,
          "from_longitude": 105.83041989238777
        }
      },
      {
        "id": 3430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83014032955998,
            21.022301712316235,
            105.8302598890719,
            21.022454037438628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302598890719,
              21.022454037438628
            ],
            [
              105.83018992803535,
              21.02236456047299
            ],
            [
              105.83014032955998,
              21.022301712316235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3437",
          "diaChiLapD": "Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022454037438628,
          "to_latitude": 21.022301712316235,
          "to_longitude": 105.83014032955998,
          "from_longitude": 105.8302598890719
        }
      },
      {
        "id": 3431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8303997537177,
            21.022313215726854,
            105.83043389895853,
            21.022392549039843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83043389895853,
              21.022392549039843
            ],
            [
              105.8303997537177,
              21.022313215726854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3438",
          "diaChiLapD": "Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022392549039843,
          "to_latitude": 21.022313215726854,
          "to_longitude": 105.8303997537177,
          "from_longitude": 105.83043389895853
        }
      },
      {
        "id": 3432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098928230766,
            21.02347856173077,
            105.83109146718756,
            21.02352913037215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109146718756,
              21.02347856173077
            ],
            [
              105.83098928230766,
              21.02352913037215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_J7_Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3439",
          "diaChiLapD": "Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02347856173077,
          "to_latitude": 21.02352913037215,
          "to_longitude": 105.83098928230766,
          "from_longitude": 105.83109146718756
        }
      },
      {
        "id": 3433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83224501845851,
            21.025606029967296,
            105.83225045520877,
            21.025617727208033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83224501845851,
              21.025606029967296
            ],
            [
              105.83224825837868,
              21.025612943646088
            ],
            [
              105.83225045520877,
              21.025617727208033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N9_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3440",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025606029967296,
          "to_latitude": 21.025617727208033,
          "to_longitude": 105.83225045520877,
          "from_longitude": 105.83224501845851
        }
      },
      {
        "id": 3434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118841104572,
            21.021435401249057,
            105.83121269872971,
            21.02144533798928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83121269872971,
              21.021435401249057
            ],
            [
              105.8312013817493,
              21.02144003048257
            ],
            [
              105.83118841104572,
              21.02144533798928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ6_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3441",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021435401249057,
          "to_latitude": 21.02144533798928,
          "to_longitude": 105.83118841104572,
          "from_longitude": 105.83121269872971
        }
      },
      {
        "id": 3435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124635333942,
            21.021608399376277,
            105.83153207053259,
            21.022208749001162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124635333942,
              21.021608399376277
            ],
            [
              105.83135632140778,
              21.021841908157747
            ],
            [
              105.83138698566923,
              21.021907020759212
            ],
            [
              105.83138714239635,
              21.021907354186165
            ],
            [
              105.83139805366888,
              21.021930523656113
            ],
            [
              105.83145332141271,
              21.022048703253805
            ],
            [
              105.83147747133143,
              21.022098392606278
            ],
            [
              105.83148126246232,
              21.022106194190286
            ],
            [
              105.8315009216187,
              21.02214664202223
            ],
            [
              105.83153207053259,
              21.022208749001162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ7_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3442",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021608399376277,
          "to_latitude": 21.022208749001162,
          "to_longitude": 105.83153207053259,
          "from_longitude": 105.83124635333942
        }
      },
      {
        "id": 3436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940320333286,
            21.023134526550283,
            105.8294044988619,
            21.02313778268015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294044988619,
              21.02313778268015
            ],
            [
              105.82940320333286,
              21.023134526550283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U9_Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3443",
          "diaChiLapD": "Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02313778268015,
          "to_latitude": 21.023134526550283,
          "to_longitude": 105.82940320333286,
          "from_longitude": 105.8294044988619
        }
      },
      {
        "id": 3437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919530920125,
            21.022970429016137,
            105.82968704886356,
            21.023258312857795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919530920125,
              21.023258312857795
            ],
            [
              105.8292139830585,
              21.02325479317399
            ],
            [
              105.82926166981424,
              21.023228899154937
            ],
            [
              105.8292892337777,
              21.023191361319693
            ],
            [
              105.8292924056354,
              21.023187047407635
            ],
            [
              105.82940320333286,
              21.023134526550283
            ],
            [
              105.82941113623161,
              21.023130766579133
            ],
            [
              105.82968704886356,
              21.023033889477016
            ],
            [
              105.82966480870306,
              21.022970429016137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U9_Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3444",
          "diaChiLapD": "Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023258312857795,
          "to_latitude": 21.022970429016137,
          "to_longitude": 105.82966480870306,
          "from_longitude": 105.82919530920125
        }
      },
      {
        "id": 3438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82933567573298,
            21.022689114395614,
            105.82959701091269,
            21.022781461038157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959701091269,
              21.022689114395614
            ],
            [
              105.82933567573298,
              21.022781461038157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U8_Ngách 148, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3445",
          "diaChiLapD": "Ngách 148, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022689114395614,
          "to_latitude": 21.022781461038157,
          "to_longitude": 105.82933567573298,
          "from_longitude": 105.82959701091269
        }
      },
      {
        "id": 3439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82936869108546,
            21.022861055927965,
            105.82967606379921,
            21.02295243223108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82967606379921,
              21.022861055927965
            ],
            [
              105.82936869108546,
              21.02295243223108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U8_Ngách 144, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3446",
          "diaChiLapD": "Ngách 144, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022861055927965,
          "to_latitude": 21.02295243223108,
          "to_longitude": 105.82936869108546,
          "from_longitude": 105.82967606379921
        }
      },
      {
        "id": 3440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959110388464,
            21.022525421986447,
            105.82973795261336,
            21.02285646008609
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82973795261336,
              21.02285646008609
            ],
            [
              105.82973396903093,
              21.02284741927324
            ],
            [
              105.82959110388464,
              21.022525421986447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U7_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3447",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02285646008609,
          "to_latitude": 21.022525421986447,
          "to_longitude": 105.82959110388464,
          "from_longitude": 105.82973795261336
        }
      },
      {
        "id": 3441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82938622505048,
            21.024241568601983,
            105.83035587627892,
            21.024648428940697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83035587627892,
              21.024241568601983
            ],
            [
              105.8303516862231,
              21.024242680154433
            ],
            [
              105.83032100043637,
              21.02425081808704
            ],
            [
              105.83030672427012,
              21.02425460455356
            ],
            [
              105.83024547263888,
              21.02427708135075
            ],
            [
              105.82999073913243,
              21.02437027656322
            ],
            [
              105.82996358417752,
              21.024399680782434
            ],
            [
              105.82984941279716,
              21.024465718072996
            ],
            [
              105.82980020161968,
              21.02449606898318
            ],
            [
              105.82977012068679,
              21.024513217144417
            ],
            [
              105.82975595000646,
              21.024521296077005
            ],
            [
              105.82965003042662,
              21.02463409536171
            ],
            [
              105.82957756952004,
              21.024648428940697
            ],
            [
              105.82954980951828,
              21.024599454224955
            ],
            [
              105.82956486259857,
              21.024559948727326
            ],
            [
              105.82949489329806,
              21.02449030365485
            ],
            [
              105.82947996946243,
              21.024475448851092
            ],
            [
              105.82947250061517,
              21.024455988273502
            ],
            [
              105.82938622505048,
              21.02438311145982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R3_Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3448",
          "diaChiLapD": "Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024241568601983,
          "to_latitude": 21.02438311145982,
          "to_longitude": 105.82938622505048,
          "from_longitude": 105.83035587627892
        }
      },
      {
        "id": 3442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83031120701662,
            21.024780232972596,
            105.83060823524883,
            21.025077898936473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83060823524883,
              21.02498673018331
            ],
            [
              105.83035317018889,
              21.025077898936473
            ],
            [
              105.83033056371809,
              21.024960568470064
            ],
            [
              105.83032213584073,
              21.024916829430428
            ],
            [
              105.83031120701662,
              21.024860106104953
            ],
            [
              105.83053875003989,
              21.024780232972596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R5_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3449",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02498673018331,
          "to_latitude": 21.024780232972596,
          "to_longitude": 105.83053875003989,
          "from_longitude": 105.83060823524883
        }
      },
      {
        "id": 3443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999899425702,
            21.02342082718654,
            105.83026355519914,
            21.0240502051613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82999899425702,
              21.02342082718654
            ],
            [
              105.8300660797672,
              21.023585096202527
            ],
            [
              105.83019376948378,
              21.02387856375814
            ],
            [
              105.83019505489705,
              21.023881725995043
            ],
            [
              105.83020694737714,
              21.023910976674177
            ],
            [
              105.83023144141714,
              21.023965670186403
            ],
            [
              105.83026058353212,
              21.024042895421278
            ],
            [
              105.83026355519914,
              21.0240502051613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S1_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3450",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02342082718654,
          "to_latitude": 21.0240502051613,
          "to_longitude": 105.83026355519914,
          "from_longitude": 105.82999899425702
        }
      },
      {
        "id": 3444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82995737013039,
            21.023406770202566,
            105.83024455344727,
            21.02406522912623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82995737013039,
              21.023406770202566
            ],
            [
              105.83000801729469,
              21.023517546502436
            ],
            [
              105.8300375685801,
              21.02358218335449
            ],
            [
              105.83004220832407,
              21.023590884956516
            ],
            [
              105.83013959177518,
              21.02381804029827
            ],
            [
              105.830141414467,
              21.023822333459925
            ],
            [
              105.83015594529273,
              21.023856554354893
            ],
            [
              105.83024455344727,
              21.02406522912623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S2_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3451",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023406770202566,
          "to_latitude": 21.02406522912623,
          "to_longitude": 105.83024455344727,
          "from_longitude": 105.82995737013039
        }
      },
      {
        "id": 3445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83021883880373,
            21.023965670186403,
            105.83023144141714,
            21.023971480730395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83021883880373,
              21.023971480730395
            ],
            [
              105.83022201872332,
              21.023970014629057
            ],
            [
              105.83022924598798,
              21.023966682579495
            ],
            [
              105.83023144141714,
              21.023965670186403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S1_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3452",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023971480730395,
          "to_latitude": 21.023965670186403,
          "to_longitude": 105.83023144141714,
          "from_longitude": 105.83021883880373
        }
      },
      {
        "id": 3446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959614346571,
            21.024591447215805,
            105.83029822942152,
            21.02482153705414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959614346571,
              21.02482153705414
            ],
            [
              105.82984970719463,
              21.024731982692106
            ],
            [
              105.8302476079229,
              21.024591447215805
            ],
            [
              105.83029647755609,
              21.02459184721668
            ],
            [
              105.83029822942152,
              21.024591860341303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R4_Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3453",
          "diaChiLapD": "Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02482153705414,
          "to_latitude": 21.024591860341303,
          "to_longitude": 105.83029822942152,
          "from_longitude": 105.82959614346571
        }
      },
      {
        "id": 3447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82988154686618,
            21.024962274328566,
            105.83031937391138,
            21.025059144118323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031937391138,
              21.02497883300833
            ],
            [
              105.8303185445655,
              21.024978987895913
            ],
            [
              105.8303102789482,
              21.02498052670004
            ],
            [
              105.83028956129725,
              21.024962274328566
            ],
            [
              105.83014912642756,
              21.024990488362334
            ],
            [
              105.83001177935945,
              21.025029273904632
            ],
            [
              105.82996201491477,
              21.025040688755066
            ],
            [
              105.82988154686618,
              21.025059144118323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R6_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3454",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02497883300833,
          "to_latitude": 21.025059144118323,
          "to_longitude": 105.82988154686618,
          "from_longitude": 105.83031937391138
        }
      },
      {
        "id": 3448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162563980514,
            21.02552396449628,
            105.8325023376571,
            21.025861996066762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83162563980514,
              21.025861996066762
            ],
            [
              105.83194294857154,
              21.025736286917073
            ],
            [
              105.83211396470261,
              21.025668534875482
            ],
            [
              105.83212315879605,
              21.02566511273434
            ],
            [
              105.83219784049449,
              21.025637312773682
            ],
            [
              105.83225045520877,
              21.025617727208033
            ],
            [
              105.8325023376571,
              21.02552396449628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N9_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3455",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025861996066762,
          "to_latitude": 21.02552396449628,
          "to_longitude": 105.8325023376571,
          "from_longitude": 105.83162563980514
        }
      },
      {
        "id": 3449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959508156854,
            21.024566814528836,
            105.8302995866173,
            21.024807481738545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959508156854,
              21.024807481738545
            ],
            [
              105.8298450270565,
              21.024719116515804
            ],
            [
              105.83016182412533,
              21.024607115111156
            ],
            [
              105.83024607808025,
              21.024577327351373
            ],
            [
              105.83029898506511,
              21.02456693307839
            ],
            [
              105.8302995866173,
              21.024566814528836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R41_Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3456",
          "diaChiLapD": "Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024807481738545,
          "to_latitude": 21.024566814528836,
          "to_longitude": 105.8302995866173,
          "from_longitude": 105.82959508156854
        }
      },
      {
        "id": 3450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83013211681235,
            21.02441774827986,
            105.83016182412533,
            21.024607115111156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83016182412533,
              21.024607115111156
            ],
            [
              105.83014548840643,
              21.02453430463954
            ],
            [
              105.83013211681235,
              21.02441774827986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_R41_Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3457",
          "diaChiLapD": "Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024607115111156,
          "to_latitude": 21.02441774827986,
          "to_longitude": 105.83013211681235,
          "from_longitude": 105.83016182412533
        }
      },
      {
        "id": 3451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035735255017,
            21.02513176948393,
            105.83061406140399,
            21.025216502437388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83036620280612,
              21.025189830603562
            ],
            [
              105.83036144547594,
              21.02519152299021
            ],
            [
              105.83035735255017,
              21.025192980907317
            ],
            [
              105.83036480075396,
              21.025216502437388
            ],
            [
              105.83038592496153,
              21.025209322532437
            ],
            [
              105.83061406140399,
              21.02513176948393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R71_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3458",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025189830603562,
          "to_latitude": 21.02513176948393,
          "to_longitude": 105.83061406140399,
          "from_longitude": 105.83036620280612
        }
      },
      {
        "id": 3452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83088652252559,
            21.02375700876357,
            105.8309564995414,
            21.023789820656553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83088652252559,
              21.023789820656553
            ],
            [
              105.8309564995414,
              21.02375700876357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q8_Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3459",
          "diaChiLapD": "Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023789820656553,
          "to_latitude": 21.02375700876357,
          "to_longitude": 105.8309564995414,
          "from_longitude": 105.83088652252559
        }
      },
      {
        "id": 3453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029594043526,
            21.024336439655762,
            105.83031902619398,
            21.024338843331897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031902619398,
              21.024338843331897
            ],
            [
              105.830317278211,
              21.024338662188725
            ],
            [
              105.83031327593004,
              21.024338244567833
            ],
            [
              105.83029594043526,
              21.024336439655762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R32_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3460",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024338843331897,
          "to_latitude": 21.024336439655762,
          "to_longitude": 105.83029594043526,
          "from_longitude": 105.83031902619398
        }
      },
      {
        "id": 3454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83095882203936,
            21.02366216096686,
            105.83112072052366,
            21.023871612974016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83095882203936,
              21.023871612974016
            ],
            [
              105.83105044698887,
              21.023847350871158
            ],
            [
              105.83109289829471,
              21.02383531300037
            ],
            [
              105.83112072052366,
              21.023827423763613
            ],
            [
              105.83104527337132,
              21.02366216096686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q71_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3461",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023871612974016,
          "to_latitude": 21.02366216096686,
          "to_longitude": 105.83104527337132,
          "from_longitude": 105.83095882203936
        }
      },
      {
        "id": 3455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83030133187539,
            21.024274140783582,
            105.83034827030596,
            21.02484468550157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83030544198573,
              21.02484468550157
            ],
            [
              105.83030288944008,
              21.024772879884722
            ],
            [
              105.83030133187539,
              21.024683405953617
            ],
            [
              105.830304496711,
              21.024598789087563
            ],
            [
              105.83030459900723,
              21.024590857374797
            ],
            [
              105.83030685868535,
              21.024540822163207
            ],
            [
              105.83030777304486,
              21.02452058276312
            ],
            [
              105.83031535423832,
              21.02440640889626
            ],
            [
              105.83032092898819,
              21.024346828481363
            ],
            [
              105.83033055920353,
              21.024305433657602
            ],
            [
              105.83034827030596,
              21.024274140783582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R42_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3462",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02484468550157,
          "to_latitude": 21.024274140783582,
          "to_longitude": 105.83034827030596,
          "from_longitude": 105.83030544198573
        }
      },
      {
        "id": 3456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83032144458183,
            21.024150523935006,
            105.83056999811545,
            21.024394306929505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83032144458183,
              21.024168776947537
            ],
            [
              105.8303294691985,
              21.02416541842018
            ],
            [
              105.8303650535283,
              21.024150523935006
            ],
            [
              105.83041599494533,
              21.02415696629711
            ],
            [
              105.8304619303603,
              21.024178728290146
            ],
            [
              105.83048044180812,
              21.024220378607108
            ],
            [
              105.83056999811545,
              21.024394306929505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R31_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3463",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024168776947537,
          "to_latitude": 21.024394306929505,
          "to_longitude": 105.83056999811545,
          "from_longitude": 105.83032144458183
        }
      },
      {
        "id": 3457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83067846489524,
            21.02397500804235,
            105.83112749252096,
            21.024275142890602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067846489524,
              21.02397500804235
            ],
            [
              105.83068227598311,
              21.02398415263951
            ],
            [
              105.83068408163153,
              21.023987102783362
            ],
            [
              105.83072198301993,
              21.024049068528395
            ],
            [
              105.83083698937833,
              21.024275142890602
            ],
            [
              105.83102955169906,
              21.024213745659907
            ],
            [
              105.83112749252096,
              21.024181363372623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q81_Ngách 42, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3464",
          "diaChiLapD": "Ngách 42, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02397500804235,
          "to_latitude": 21.024181363372623,
          "to_longitude": 105.83112749252096,
          "from_longitude": 105.83067846489524
        }
      },
      {
        "id": 3458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83049721276078,
            21.024011033166353,
            105.8305413560673,
            21.024053481406597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8305413560673,
              21.024053481406597
            ],
            [
              105.83053876122992,
              21.024050987630545
            ],
            [
              105.83049721276078,
              21.024011033166353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R11_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3465",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024053481406597,
          "to_latitude": 21.024011033166353,
          "to_longitude": 105.83049721276078,
          "from_longitude": 105.8305413560673
        }
      },
      {
        "id": 3459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035420062438,
            21.023623574359114,
            105.83051899248954,
            21.024045474614212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83038573269505,
              21.024045474614212
            ],
            [
              105.83049721276078,
              21.024011033166353
            ],
            [
              105.83051899248954,
              21.02400430527295
            ],
            [
              105.83035420062438,
              21.023623574359114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R11_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3466",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024045474614212,
          "to_latitude": 21.023623574359114,
          "to_longitude": 105.83035420062438,
          "from_longitude": 105.83038573269505
        }
      },
      {
        "id": 3460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8304735224584,
            21.02393466524099,
            105.83083255333077,
            21.02437617116983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83083255333077,
              21.02393466524099
            ],
            [
              105.83070630443537,
              21.023979957218973
            ],
            [
              105.83068408163153,
              21.023987102783362
            ],
            [
              105.83060450896653,
              21.02401269047513
            ],
            [
              105.83058047304198,
              21.024034987438114
            ],
            [
              105.83056139475859,
              21.024052687161962
            ],
            [
              105.83055792440925,
              21.02405590604002
            ],
            [
              105.83055602366265,
              21.02405766938413
            ],
            [
              105.83053158957728,
              21.024080334994043
            ],
            [
              105.8304735224584,
              21.024137758329974
            ],
            [
              105.83058829096143,
              21.02437617116983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R1_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3467",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02393466524099,
          "to_latitude": 21.02437617116983,
          "to_longitude": 105.83058829096143,
          "from_longitude": 105.83083255333077
        }
      },
      {
        "id": 3461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959614346571,
            21.02482153705414,
            105.82963625828381,
            21.025079437759217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82963625828381,
              21.025079437759217
            ],
            [
              105.82959614346571,
              21.02482153705414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R4_Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3468",
          "diaChiLapD": "Hẻm 74/13, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025079437759217,
          "to_latitude": 21.02482153705414,
          "to_longitude": 105.82959614346571,
          "from_longitude": 105.82963625828381
        }
      },
      {
        "id": 3462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82953705125213,
            21.02256440054383,
            105.82968478205231,
            21.022878403183697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82968478205231,
              21.022878403183697
            ],
            [
              105.82968081607828,
              21.02287045789519
            ],
            [
              105.82967606379921,
              21.022861055927965
            ],
            [
              105.82963997200137,
              21.022789237750953
            ],
            [
              105.82959701091269,
              21.022689114395614
            ],
            [
              105.8295596640842,
              21.02261143406845
            ],
            [
              105.82953705125213,
              21.02256440054383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U8_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3469",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022878403183697,
          "to_latitude": 21.02256440054383,
          "to_longitude": 105.82953705125213,
          "from_longitude": 105.82968478205231
        }
      },
      {
        "id": 3463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83233817888163,
            21.025870628170143,
            105.8325250950838,
            21.02628952861172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325250950838,
              21.02628952861172
            ],
            [
              105.83251559421127,
              21.026269068329192
            ],
            [
              105.83233817888163,
              21.02588815667439
            ],
            [
              105.83237944190824,
              21.025870628170143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M3_Ngõ 25, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3470",
          "diaChiLapD": "Ngõ 25, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02628952861172,
          "to_latitude": 21.025870628170143,
          "to_longitude": 105.83237944190824,
          "from_longitude": 105.8325250950838
        }
      },
      {
        "id": 3464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8325103203793,
            21.025939406907177,
            105.833186014149,
            21.02620495170602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325103203793,
              21.02620495170602
            ],
            [
              105.83291145478873,
              21.026047307864353
            ],
            [
              105.83292548960347,
              21.026041792685465
            ],
            [
              105.833186014149,
              21.025939406907177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M2_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3471",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02620495170602,
          "to_latitude": 21.025939406907177,
          "to_longitude": 105.833186014149,
          "from_longitude": 105.8325103203793
        }
      },
      {
        "id": 3465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83269449234758,
            21.026578160340346,
            105.8327641223469,
            21.026604153760665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8327641223469,
              21.026578160340346
            ],
            [
              105.83275296215098,
              21.026582325562504
            ],
            [
              105.83269449234758,
              21.026604153760665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N1_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3472",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026578160340346,
          "to_latitude": 21.026604153760665,
          "to_longitude": 105.83269449234758,
          "from_longitude": 105.8327641223469
        }
      },
      {
        "id": 3466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83257148607156,
            21.026663284854703,
            105.83280078691931,
            21.02675464781801
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83280078691931,
              21.026663284854703
            ],
            [
              105.83278996135378,
              21.02666759747255
            ],
            [
              105.83272758238309,
              21.0266924525615
            ],
            [
              105.83272411048594,
              21.02669383614013
            ],
            [
              105.83257148607156,
              21.02675464781801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N21_Ngõ 32, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3473",
          "diaChiLapD": "Ngõ 32, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026663284854703,
          "to_latitude": 21.02675464781801,
          "to_longitude": 105.83257148607156,
          "from_longitude": 105.83280078691931
        }
      },
      {
        "id": 3467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83260172854536,
            21.026393474881374,
            105.83284957637876,
            21.026956312722465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83260172854536,
              21.026393474881374
            ],
            [
              105.83269449234758,
              21.026604153760665
            ],
            [
              105.83272565318059,
              21.02667489340311
            ],
            [
              105.83272470426445,
              21.026686602023148
            ],
            [
              105.83272758238309,
              21.0266924525615
            ],
            [
              105.83272938146227,
              21.026696111743473
            ],
            [
              105.8327363198045,
              21.02669911711563
            ],
            [
              105.83284957637876,
              21.026956312722465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N1_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3474",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026393474881374,
          "to_latitude": 21.026956312722465,
          "to_longitude": 105.83284957637876,
          "from_longitude": 105.83260172854536
        }
      },
      {
        "id": 3468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83216694489552,
            21.027354372406432,
            105.83224094782956,
            21.0275857214651
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83224094782956,
              21.0275857214651
            ],
            [
              105.83216694489552,
              21.027354372406432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N5_Bích Câu",
          "maTaiSan": "04.O13.DODV.3475",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0275857214651,
          "to_latitude": 21.027354372406432,
          "to_longitude": 105.83216694489552,
          "from_longitude": 105.83224094782956
        }
      },
      {
        "id": 3469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319929036045,
            21.026550494048177,
            105.8322783513753,
            21.027334909168545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200083342892,
              21.026550494048177
            ],
            [
              105.8319929036045,
              21.02659018798625
            ],
            [
              105.83208846687944,
              21.026837222727497
            ],
            [
              105.8322783513753,
              21.027334909168545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N61_Bích Câu",
          "maTaiSan": "04.O13.DODV.3476",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026550494048177,
          "to_latitude": 21.027334909168545,
          "to_longitude": 105.8322783513753,
          "from_longitude": 105.83200083342892
        }
      },
      {
        "id": 3470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249167529121,
            21.026599837597946,
            105.832658314852,
            21.026952231919513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832658314852,
              21.026952231919513
            ],
            [
              105.83257148607156,
              21.02675464781801
            ],
            [
              105.83253744034627,
              21.026677173381703
            ],
            [
              105.83252003606593,
              21.02666494042016
            ],
            [
              105.83249167529121,
              21.026599837597946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N21_Ngõ 32, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3477",
          "diaChiLapD": "Ngõ 32, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026952231919513,
          "to_latitude": 21.026599837597946,
          "to_longitude": 105.83249167529121,
          "from_longitude": 105.832658314852
        }
      },
      {
        "id": 3471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83224094782956,
            21.0275857214651,
            105.83243995119051,
            21.028090042350918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83224094782956,
              21.0275857214651
            ],
            [
              105.83233458627024,
              21.027817166757156
            ],
            [
              105.83232561524763,
              21.027827174127914
            ],
            [
              105.83232765582713,
              21.027831011873012
            ],
            [
              105.83233179337869,
              21.027838794570687
            ],
            [
              105.83233361036392,
              21.02784221250786
            ],
            [
              105.83234642467855,
              21.027840528498846
            ],
            [
              105.83240146908972,
              21.027987402328048
            ],
            [
              105.83243995119051,
              21.028090042350918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N5_Bích Câu",
          "maTaiSan": "04.O13.DODV.3478",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0275857214651,
          "to_latitude": 21.028090042350918,
          "to_longitude": 105.83243995119051,
          "from_longitude": 105.83224094782956
        }
      },
      {
        "id": 3472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213777329533,
            21.026243453803414,
            105.83249345379323,
            21.026381186700057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83213777329533,
              21.026381186700057
            ],
            [
              105.83217537136397,
              21.02636662700775
            ],
            [
              105.83249345379323,
              21.026243453803414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3479",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026381186700057,
          "to_latitude": 21.026243453803414,
          "to_longitude": 105.83249345379323,
          "from_longitude": 105.83213777329533
        }
      },
      {
        "id": 3473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217537136397,
            21.02636662700775,
            105.83220090015179,
            21.02641948790363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217537136397,
              21.02636662700775
            ],
            [
              105.83218834370255,
              21.026393431728213
            ],
            [
              105.83220090015179,
              21.02641948790363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3480",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02636662700775,
          "to_latitude": 21.02641948790363,
          "to_longitude": 105.83220090015179,
          "from_longitude": 105.83217537136397
        }
      },
      {
        "id": 3474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83231691870306,
            21.026061520809616,
            105.83249345379323,
            21.026243453803414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83249345379323,
              21.026243453803414
            ],
            [
              105.83240806804119,
              21.026061520809616
            ],
            [
              105.83231691870306,
              21.026071667565915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M5_Ngõ 25, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3481",
          "diaChiLapD": "Ngõ 25, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026243453803414,
          "to_latitude": 21.026071667565915,
          "to_longitude": 105.83231691870306,
          "from_longitude": 105.83249345379323
        }
      },
      {
        "id": 3475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353950115026,
            21.026605856970892,
            105.83379486512403,
            21.02715796049545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353950115026,
              21.026605856970892
            ],
            [
              105.83358888947544,
              21.026713261977978
            ],
            [
              105.83361647873329,
              21.02677326155795
            ],
            [
              105.83372270210246,
              21.027002338438685
            ],
            [
              105.83379486512403,
              21.02715796049545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P31_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3482",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026605856970892,
          "to_latitude": 21.02715796049545,
          "to_longitude": 105.83379486512403,
          "from_longitude": 105.83353950115026
        }
      },
      {
        "id": 3476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83391346908397,
            21.027412454365376,
            105.83395736290998,
            21.027431633701667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83395736290998,
              21.027412454365376
            ],
            [
              105.83393786701721,
              21.027420972533896
            ],
            [
              105.83391353434332,
              21.027431605381707
            ],
            [
              105.83391346908397,
              21.027431633701667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P3_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3483",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027412454365376,
          "to_latitude": 21.027431633701667,
          "to_longitude": 105.83391346908397,
          "from_longitude": 105.83395736290998
        }
      },
      {
        "id": 3477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83310881641991,
            21.027354644366234,
            105.8331591484939,
            21.027378430771915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331591484939,
              21.027354644366234
            ],
            [
              105.83313128292893,
              21.027367813503268
            ],
            [
              105.83310881641991,
              21.027378430771915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N3_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3484",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027354644366234,
          "to_latitude": 21.027378430771915,
          "to_longitude": 105.83310881641991,
          "from_longitude": 105.8331591484939
        }
      },
      {
        "id": 3478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8330204989909,
            21.027033083222516,
            105.83312135426115,
            21.02727144663761
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330204989909,
              21.027033083222516
            ],
            [
              105.83308895431921,
              21.027194872004497
            ],
            [
              105.83310135104793,
              21.02722417145998
            ],
            [
              105.83310639765939,
              21.027236097288053
            ],
            [
              105.83312135426115,
              21.02727144663761
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N31_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3485",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027033083222516,
          "to_latitude": 21.02727144663761,
          "to_longitude": 105.83312135426115,
          "from_longitude": 105.8330204989909
        }
      },
      {
        "id": 3479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83308895431921,
            21.02710647675639,
            105.83331411321844,
            21.027194872004497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83308895431921,
              21.027194872004497
            ],
            [
              105.83331411321844,
              21.02710647675639
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N31_Ngõ 19, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3486",
          "diaChiLapD": "Ngõ 19, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027194872004497,
          "to_latitude": 21.02710647675639,
          "to_longitude": 105.83331411321844,
          "from_longitude": 105.83308895431921
        }
      },
      {
        "id": 3480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288677987473,
            21.026841641743726,
            105.83293946913767,
            21.026862932277783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83288677987473,
              21.026862932277783
            ],
            [
              105.83291246748124,
              21.026852551262447
            ],
            [
              105.83293946913767,
              21.026841641743726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N2_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3487",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026862932277783,
          "to_latitude": 21.026841641743726,
          "to_longitude": 105.83293946913767,
          "from_longitude": 105.83288677987473
        }
      },
      {
        "id": 3481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300359368499,
            21.026897983222195,
            105.83324032349016,
            21.026987742914905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300359368499,
              21.026987742914905
            ],
            [
              105.83324032349016,
              21.026897983222195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N2_Ngõ 25, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3488",
          "diaChiLapD": "Ngõ 25, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026987742914905,
          "to_latitude": 21.026897983222195,
          "to_longitude": 105.83324032349016,
          "from_longitude": 105.83300359368499
        }
      },
      {
        "id": 3482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328174634428,
            21.02650031055764,
            105.83302304881167,
            21.026580187891014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328174634428,
              21.026580187891014
            ],
            [
              105.83302304881167,
              21.02650031055764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N22_Ngõ 45, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3489",
          "diaChiLapD": "Ngõ 45, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026580187891014,
          "to_latitude": 21.02650031055764,
          "to_longitude": 105.83302304881167,
          "from_longitude": 105.8328174634428
        }
      },
      {
        "id": 3483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83287197161319,
            21.0269758156387,
            105.83310968389263,
            21.027533996853293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83287197161319,
              21.0269758156387
            ],
            [
              105.83298256913402,
              21.02723551615356
            ],
            [
              105.83299970974645,
              21.027275762030587
            ],
            [
              105.83310968389263,
              21.027533996853293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3490",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0269758156387,
          "to_latitude": 21.027533996853293,
          "to_longitude": 105.83310968389263,
          "from_longitude": 105.83287197161319
        }
      },
      {
        "id": 3484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83337882509677,
            21.026713261977978,
            105.83358888947544,
            21.02680907132446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358888947544,
              21.026713261977978
            ],
            [
              105.83346220780606,
              21.026762810529004
            ],
            [
              105.83346662948516,
              21.0267718599326
            ],
            [
              105.83337882509677,
              21.02680907132446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P31_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3491",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026713261977978,
          "to_latitude": 21.02680907132446,
          "to_longitude": 105.83337882509677,
          "from_longitude": 105.83358888947544
        }
      },
      {
        "id": 3485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331342542917,
            21.026511866293742,
            105.83349497154038,
            21.02665757256129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83349497154038,
              21.026511866293742
            ],
            [
              105.83337789100099,
              21.026564040359936
            ],
            [
              105.83339069131377,
              21.026623713229846
            ],
            [
              105.83331342542917,
              21.02665757256129
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P41_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3492",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026511866293742,
          "to_latitude": 21.02665757256129,
          "to_longitude": 105.83331342542917,
          "from_longitude": 105.83349497154038
        }
      },
      {
        "id": 3486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83284858317298,
            21.02658001797137,
            105.8330701221451,
            21.026649753520047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83284858317298,
              21.026649753520047
            ],
            [
              105.8329638570366,
              21.026603241035186
            ],
            [
              105.83296980146764,
              21.026615047761993
            ],
            [
              105.8330701221451,
              21.02658001797137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N22_Ngõ 39, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3493",
          "diaChiLapD": "Ngõ 39, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026649753520047,
          "to_latitude": 21.02658001797137,
          "to_longitude": 105.8330701221451,
          "from_longitude": 105.83284858317298
        }
      },
      {
        "id": 3487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83310639765939,
            21.027156461657427,
            105.83328193764676,
            21.027236097288053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83310639765939,
              21.027236097288053
            ],
            [
              105.83328193764676,
              21.027156461657427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N31_Ngõ 17, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3494",
          "diaChiLapD": "Ngõ 17, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027236097288053,
          "to_latitude": 21.027156461657427,
          "to_longitude": 105.83328193764676,
          "from_longitude": 105.83310639765939
        }
      },
      {
        "id": 3488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83305067062847,
            21.02722417145998,
            105.83310135104793,
            21.02724343350073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83310135104793,
              21.02722417145998
            ],
            [
              105.83310132416756,
              21.027224181527128
            ],
            [
              105.83307230193755,
              21.02723521116461
            ],
            [
              105.83305067062847,
              21.02724343350073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N31_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3495",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02722417145998,
          "to_latitude": 21.02724343350073,
          "to_longitude": 105.83305067062847,
          "from_longitude": 105.83310135104793
        }
      },
      {
        "id": 3489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278565270376,
            21.026610655524024,
            105.83283109311307,
            21.026628147694755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278565270376,
              21.026628147694755
            ],
            [
              105.83280755118486,
              21.026619717247243
            ],
            [
              105.83283109311307,
              21.026610655524024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N22_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3496",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026628147694755,
          "to_latitude": 21.026610655524024,
          "to_longitude": 105.83283109311307,
          "from_longitude": 105.83278565270376
        }
      },
      {
        "id": 3490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83298256913402,
            21.02721392151942,
            105.83303795911107,
            21.02723551615356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83298256913402,
              21.02723551615356
            ],
            [
              105.83301526920441,
              21.02722276684175
            ],
            [
              105.83303795911107,
              21.02721392151942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3497",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02723551615356,
          "to_latitude": 21.02721392151942,
          "to_longitude": 105.83303795911107,
          "from_longitude": 105.83298256913402
        }
      },
      {
        "id": 3491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83379889399255,
            21.027182341324693,
            105.83398741169485,
            21.027592516336128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83379889399255,
              21.027182341324693
            ],
            [
              105.8338318141574,
              21.02725396901605
            ],
            [
              105.83387074560355,
              21.02733867480192
            ],
            [
              105.83389443227783,
              21.027390212194298
            ],
            [
              105.83391346908397,
              21.027431633701667
            ],
            [
              105.83394336960595,
              21.027496690772033
            ],
            [
              105.83398741169485,
              21.027592516336128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P3_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3498",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027182341324693,
          "to_latitude": 21.027592516336128,
          "to_longitude": 105.83398741169485,
          "from_longitude": 105.83379889399255
        }
      },
      {
        "id": 3492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372270210246,
            21.026985656994675,
            105.83376282421591,
            21.027002338438685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376282421591,
              21.026985656994675
            ],
            [
              105.83374366967656,
              21.026993619790407
            ],
            [
              105.83372270210246,
              21.027002338438685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P31_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3499",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026985656994675,
          "to_latitude": 21.027002338438685,
          "to_longitude": 105.83372270210246,
          "from_longitude": 105.83376282421591
        }
      },
      {
        "id": 3493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83323762907828,
            21.027477545228244,
            105.83337331089811,
            21.027532667269853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83323762907828,
              21.027532667269853
            ],
            [
              105.83323784024351,
              21.02753258133214
            ],
            [
              105.83337331089811,
              21.027477545228244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N3_Ngõ 1, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3500",
          "diaChiLapD": "Ngõ 1, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027532667269853,
          "to_latitude": 21.027477545228244,
          "to_longitude": 105.83337331089811,
          "from_longitude": 105.83323762907828
        }
      },
      {
        "id": 3494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83317737757703,
            21.027351237976706,
            105.8332893590628,
            21.027395995855162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83317737757703,
              21.027395995855162
            ],
            [
              105.8332893590628,
              21.027351237976706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N3_Ngõ 9, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3501",
          "diaChiLapD": "Ngõ 9, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027395995855162,
          "to_latitude": 21.027351237976706,
          "to_longitude": 105.8332893590628,
          "from_longitude": 105.83317737757703
        }
      },
      {
        "id": 3495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314020915812,
            21.027268056287294,
            105.83325917339818,
            21.02731168139078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314020915812,
              21.02731168139078
            ],
            [
              105.83325917339818,
              21.027268056287294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N3_Ngõ 11, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3502",
          "diaChiLapD": "Ngõ 11, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02731168139078,
          "to_latitude": 21.027268056287294,
          "to_longitude": 105.83325917339818,
          "from_longitude": 105.83314020915812
        }
      },
      {
        "id": 3496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288894654015,
            21.026726532175214,
            105.83300977298146,
            21.02700182099955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83288894654015,
              21.026726532175214
            ],
            [
              105.83289756070472,
              21.02674615948805
            ],
            [
              105.83291276824079,
              21.02678080763333
            ],
            [
              105.83293946913767,
              21.026841641743726
            ],
            [
              105.83300359368499,
              21.026987742914905
            ],
            [
              105.83300977298146,
              21.02700182099955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N2_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3503",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026726532175214,
          "to_latitude": 21.02700182099955,
          "to_longitude": 105.83300977298146,
          "from_longitude": 105.83288894654015
        }
      },
      {
        "id": 3497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312850203596,
            21.027285126166557,
            105.83324396007224,
            21.027547029115965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83312850203596,
              21.027285126166557
            ],
            [
              105.83314020915812,
              21.02731168139078
            ],
            [
              105.8331591484939,
              21.027354644366234
            ],
            [
              105.83317737757703,
              21.027395995855162
            ],
            [
              105.83320428179528,
              21.027457023110394
            ],
            [
              105.83323762907828,
              21.027532667269853
            ],
            [
              105.83324396007224,
              21.027547029115965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N3_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3504",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027285126166557,
          "to_latitude": 21.027547029115965,
          "to_longitude": 105.83324396007224,
          "from_longitude": 105.83312850203596
        }
      },
      {
        "id": 3498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83328384323683,
            21.02606992302245,
            105.8335295249407,
            21.02658419508308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83328384323683,
              21.02606992302245
            ],
            [
              105.8333767748443,
              21.026264451957186
            ],
            [
              105.83349497154038,
              21.026511866293742
            ],
            [
              105.8335295249407,
              21.02658419508308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P41_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3505",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02606992302245,
          "to_latitude": 21.02658419508308,
          "to_longitude": 105.8335295249407,
          "from_longitude": 105.83328384323683
        }
      },
      {
        "id": 3499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83303929897825,
            21.02605070914351,
            105.83306640961189,
            21.026107390921034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83303929897825,
              21.02605070914351
            ],
            [
              105.83304025770829,
              21.026052716829714
            ],
            [
              105.83304801400897,
              21.02606892963729
            ],
            [
              105.83306640961189,
              21.026107390921034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M1_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3506",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02605070914351,
          "to_latitude": 21.026107390921034,
          "to_longitude": 105.83306640961189,
          "from_longitude": 105.83303929897825
        }
      },
      {
        "id": 3500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83273654655575,
            21.02604655656788,
            105.83321601715424,
            21.02624152116318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83273654655575,
              21.02624152116318
            ],
            [
              105.83301120947968,
              21.02612983726876
            ],
            [
              105.83305753113592,
              21.02611100124408
            ],
            [
              105.83306640961189,
              21.026107390921034
            ],
            [
              105.83321601715424,
              21.02604655656788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M1_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3507",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02624152116318,
          "to_latitude": 21.02604655656788,
          "to_longitude": 105.83321601715424,
          "from_longitude": 105.83273654655575
        }
      },
      {
        "id": 3501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83301120947968,
            21.02612983726876,
            105.83306573551201,
            21.026234158966904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83301120947968,
              21.02612983726876
            ],
            [
              105.83306540865831,
              21.02623350753727
            ],
            [
              105.83306573551201,
              21.026234158966904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M1_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3508",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02612983726876,
          "to_latitude": 21.026234158966904,
          "to_longitude": 105.83306573551201,
          "from_longitude": 105.83301120947968
        }
      },
      {
        "id": 3502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83292548960347,
            21.026041792685465,
            105.83295136327988,
            21.02609401319628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83295136327988,
              21.02609401319628
            ],
            [
              105.83294951864895,
              21.02609029282025
            ],
            [
              105.83293204412438,
              21.026055021714082
            ],
            [
              105.83292548960347,
              21.026041792685465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M2_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3509",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02609401319628,
          "to_latitude": 21.026041792685465,
          "to_longitude": 105.83292548960347,
          "from_longitude": 105.83295136327988
        }
      },
      {
        "id": 3503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8333767748443,
            21.026245589669465,
            105.8334256706716,
            21.026264451957186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334256706716,
              21.026245589669465
            ],
            [
              105.83340165088829,
              21.02625485433021
            ],
            [
              105.8333767748443,
              21.026264451957186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P41_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3510",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026245589669465,
          "to_latitude": 21.026264451957186,
          "to_longitude": 105.8333767748443,
          "from_longitude": 105.8334256706716
        }
      },
      {
        "id": 3504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83271325878636,
            21.026347246444914,
            105.83288006821766,
            21.02672013477029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83271325878636,
              21.026347246444914
            ],
            [
              105.8328174634428,
              21.026580187891014
            ],
            [
              105.83283109311307,
              21.026610655524024
            ],
            [
              105.83284858317298,
              21.026649753520047
            ],
            [
              105.83288006821766,
              21.02672013477029
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N22_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3511",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026347246444914,
          "to_latitude": 21.02672013477029,
          "to_longitude": 105.83288006821766,
          "from_longitude": 105.83271325878636
        }
      },
      {
        "id": 3505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83130393077771,
            21.02660147151373,
            105.83180374223362,
            21.026793437206912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83130393077771,
              21.026793437206912
            ],
            [
              105.83149353685559,
              21.0267233512705
            ],
            [
              105.83152279980075,
              21.0267111411139
            ],
            [
              105.8316354624204,
              21.026664130157222
            ],
            [
              105.8316454307134,
              21.026667592239928
            ],
            [
              105.83164979713357,
              21.026666007405638
            ],
            [
              105.83165497001498,
              21.02666412959561
            ],
            [
              105.83165928792256,
              21.026657441783076
            ],
            [
              105.83180374223362,
              21.02660147151373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M6_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3512",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026793437206912,
          "to_latitude": 21.02660147151373,
          "to_longitude": 105.83180374223362,
          "from_longitude": 105.83130393077771
        }
      },
      {
        "id": 3506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83195079073462,
            21.02680790116628,
            105.83216226387604,
            21.027335680516654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195079073462,
              21.02680790116628
            ],
            [
              105.8319604027681,
              21.02683262415317
            ],
            [
              105.83202621242228,
              21.02700188787756
            ],
            [
              105.83210205104722,
              21.02719694734167
            ],
            [
              105.83216226387604,
              21.027335680516654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N6_Bích Câu",
          "maTaiSan": "04.O13.DODV.3513",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02680790116628,
          "to_latitude": 21.027335680516654,
          "to_longitude": 105.83216226387604,
          "from_longitude": 105.83195079073462
        }
      },
      {
        "id": 3507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83186201697558,
            21.02719694734167,
            105.83210205104722,
            21.027263390467844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83210205104722,
              21.02719694734167
            ],
            [
              105.83202312045516,
              21.027226158128073
            ],
            [
              105.83201410304099,
              21.027206485774872
            ],
            [
              105.83186201697558,
              21.027263390467844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N6_Ngõ 38, Bích Câu",
          "maTaiSan": "04.O13.DODV.3514",
          "diaChiLapD": "Ngõ 38, Bích Câu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02719694734167,
          "to_latitude": 21.027263390467844,
          "to_longitude": 105.83186201697558,
          "from_longitude": 105.83210205104722
        }
      },
      {
        "id": 3508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83173498899242,
            21.02683262415317,
            105.8319604027681,
            21.02690550599826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319604027681,
              21.02683262415317
            ],
            [
              105.83195996488085,
              21.026832766295147
            ],
            [
              105.83173498899242,
              21.02690550599826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N6_Ngõ 44, Bích Câu",
          "maTaiSan": "04.O13.DODV.3515",
          "diaChiLapD": "Ngõ 44, Bích Câu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02683262415317,
          "to_latitude": 21.02690550599826,
          "to_longitude": 105.83173498899242,
          "from_longitude": 105.8319604027681
        }
      },
      {
        "id": 3509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318271630623,
            21.026612703251967,
            105.83194719452263,
            21.02679971387407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318271630623,
              21.026612703251967
            ],
            [
              105.83189488075253,
              21.026642009485375
            ],
            [
              105.83192923445685,
              21.026745571272418
            ],
            [
              105.83194719452263,
              21.02679971387407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N62_Bích Câu",
          "maTaiSan": "04.O13.DODV.3516",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026612703251967,
          "to_latitude": 21.02679971387407,
          "to_longitude": 105.83194719452263,
          "from_longitude": 105.8318271630623
        }
      },
      {
        "id": 3510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83167702726375,
            21.026770843754473,
            105.8317289448206,
            21.02682215636293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171405697632,
              21.026770843754473
            ],
            [
              105.8317289448206,
              21.026803924676866
            ],
            [
              105.83167702726375,
              21.02682215636293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M61_Ngõ 40, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3517",
          "diaChiLapD": "Ngõ 40, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026770843754473,
          "to_latitude": 21.02682215636293,
          "to_longitude": 105.83167702726375,
          "from_longitude": 105.83171405697632
        }
      },
      {
        "id": 3511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160784381852,
            21.02676161245592,
            105.83169141298771,
            21.026847719033963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83169141298771,
              21.02676161245592
            ],
            [
              105.83164533503604,
              21.026780921118316
            ],
            [
              105.83166117682423,
              21.02682624961433
            ],
            [
              105.83160784381852,
              21.026847719033963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M61_Ngõ 40, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3518",
          "diaChiLapD": "Ngõ 40, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02676161245592,
          "to_latitude": 21.026847719033963,
          "to_longitude": 105.83160784381852,
          "from_longitude": 105.83169141298771
        }
      },
      {
        "id": 3512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164064434958,
            21.02664498175834,
            105.8318324861034,
            21.02682113677549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83164064434958,
              21.02664498175834
            ],
            [
              105.83164626254337,
              21.026657826127582
            ],
            [
              105.83165139032738,
              21.026669668509868
            ],
            [
              105.83169141298771,
              21.02676161245592
            ],
            [
              105.83169784518962,
              21.026776389354037
            ],
            [
              105.83171405697632,
              21.026770843754473
            ],
            [
              105.83179565455558,
              21.026742931551205
            ],
            [
              105.8318324861034,
              21.02682113677549
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M61_Ngõ 40, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3519",
          "diaChiLapD": "Ngõ 40, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02664498175834,
          "to_latitude": 21.02682113677549,
          "to_longitude": 105.8318324861034,
          "from_longitude": 105.83164064434958
        }
      },
      {
        "id": 3513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141568930156,
            21.026272727760887,
            105.83161753527366,
            21.02659370132606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161753527366,
              21.02659370132606
            ],
            [
              105.83161743995228,
              21.026593513018398
            ],
            [
              105.83152270065735,
              21.02640692853838
            ],
            [
              105.83154364242499,
              21.026396669385644
            ],
            [
              105.83151434673137,
              21.026334977014116
            ],
            [
              105.83145466294152,
              21.0263585693019
            ],
            [
              105.83143634873608,
              21.026318238665556
            ],
            [
              105.83141568930156,
              21.026272727760887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M5'_Ngõ 35, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3520",
          "diaChiLapD": "Ngõ 35, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02659370132606,
          "to_latitude": 21.026272727760887,
          "to_longitude": 105.83141568930156,
          "from_longitude": 105.83161753527366
        }
      },
      {
        "id": 3514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83192923445685,
            21.026726772721638,
            105.83198069377833,
            21.026745571272418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198069377833,
              21.026726772721638
            ],
            [
              105.83196123811683,
              21.02673387872906
            ],
            [
              105.83192923445685,
              21.026745571272418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N62_Bích Câu",
          "maTaiSan": "04.O13.DODV.3521",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026726772721638,
          "to_latitude": 21.026745571272418,
          "to_longitude": 105.83192923445685,
          "from_longitude": 105.83198069377833
        }
      },
      {
        "id": 3515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83203101661404,
            21.026837222727497,
            105.83208846687944,
            21.026857146233986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203101661404,
              21.026857146233986
            ],
            [
              105.8320460133622,
              21.026851944353478
            ],
            [
              105.83208846687944,
              21.026837222727497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N61_Bích Câu",
          "maTaiSan": "04.O13.DODV.3522",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026857146233986,
          "to_latitude": 21.026837222727497,
          "to_longitude": 105.83208846687944,
          "from_longitude": 105.83203101661404
        }
      },
      {
        "id": 3516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83149353685559,
            21.0267233512705,
            105.83163728506207,
            21.027061207726234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83149353685559,
              21.0267233512705
            ],
            [
              105.83154428983077,
              21.026827808437748
            ],
            [
              105.83163728506207,
              21.027019202955938
            ],
            [
              105.8315103220226,
              21.027061207726234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M6_Ngõ 50, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3523",
          "diaChiLapD": "Ngõ 50, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0267233512705,
          "to_latitude": 21.027061207726234,
          "to_longitude": 105.8315103220226,
          "from_longitude": 105.83149353685559
        }
      },
      {
        "id": 3517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8315134131475,
            21.02669408356262,
            105.83152279980075,
            21.0267111411139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8315134131475,
              21.02669408356262
            ],
            [
              105.83151635354349,
              21.0266993765291
            ],
            [
              105.83152279980075,
              21.0267111411139
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M6_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3524",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02669408356262,
          "to_latitude": 21.0267111411139,
          "to_longitude": 105.83152279980075,
          "from_longitude": 105.8315134131475
        }
      },
      {
        "id": 3518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194830358318,
            21.026454041051053,
            105.83197283706465,
            21.026511140262137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197283706465,
              21.026511140262137
            ],
            [
              105.83196738093315,
              21.026498380014328
            ],
            [
              105.83194830358318,
              21.026454041051053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5'_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3525",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026511140262137,
          "to_latitude": 21.026454041051053,
          "to_longitude": 105.83194830358318,
          "from_longitude": 105.83197283706465
        }
      },
      {
        "id": 3519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160067421976,
            21.026434713932005,
            105.83199407633582,
            21.026600820797565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83160067421976,
              21.026600820797565
            ],
            [
              105.83161753527366,
              21.02659370132606
            ],
            [
              105.83194830358318,
              21.026454041051053
            ],
            [
              105.83199407633582,
              21.026434713932005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M5'_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3526",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026600820797565,
          "to_latitude": 21.026434713932005,
          "to_longitude": 105.83199407633582,
          "from_longitude": 105.83160067421976
        }
      },
      {
        "id": 3520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314739196242,
            21.026686762746944,
            105.83148379634628,
            21.026705455449427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83148379634628,
              21.026705455449427
            ],
            [
              105.83148318387003,
              21.026704299607765
            ],
            [
              105.83147834416941,
              21.026695136580273
            ],
            [
              105.8314739196242,
              21.026686762746944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M7_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3527",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026705455449427,
          "to_latitude": 21.026686762746944,
          "to_longitude": 105.8314739196242,
          "from_longitude": 105.83148379634628
        }
      },
      {
        "id": 3521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124632299906,
            21.026623702017606,
            105.83161899079238,
            21.02676259190311
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161899079238,
              21.026623702017606
            ],
            [
              105.8314739196242,
              21.026686762746944
            ],
            [
              105.83146519216413,
              21.02669055650796
            ],
            [
              105.83138544711524,
              21.026727962316418
            ],
            [
              105.83136080103928,
              21.02672667919662
            ],
            [
              105.83124632299906,
              21.02676259190311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M7_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3528",
          "diaChiLapD": "Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026623702017606,
          "to_latitude": 21.02676259190311,
          "to_longitude": 105.83124632299906,
          "from_longitude": 105.83161899079238
        }
      },
      {
        "id": 3522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83346767990342,
            21.02768660271072,
            105.83405199086249,
            21.027922788308192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83346767990342,
              21.027922788308192
            ],
            [
              105.83401859268773,
              21.027700101708117
            ],
            [
              105.83405199086249,
              21.02768660271072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P2_Cát Linh",
          "maTaiSan": "04.O13.DODV.3529",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027922788308192,
          "to_latitude": 21.02768660271072,
          "to_longitude": 105.83405199086249,
          "from_longitude": 105.83346767990342
        }
      },
      {
        "id": 3523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83097282702164,
            21.020958982445457,
            105.83123432258708,
            21.021549346641464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83123432258708,
              21.021549346641464
            ],
            [
              105.83118841104572,
              21.02144533798928
            ],
            [
              105.8311229579674,
              21.021297060224057
            ],
            [
              105.83099658023238,
              21.021012485427995
            ],
            [
              105.83097282702164,
              21.020958982445457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ6_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3530",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021549346641464,
          "to_latitude": 21.020958982445457,
          "to_longitude": 105.83097282702164,
          "from_longitude": 105.83123432258708
        }
      },
      {
        "id": 3524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82973252375585,
            21.02296086682495,
            105.82995831664758,
            21.023283992314177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82995831664758,
              21.02326025442849
            ],
            [
              105.82991890381501,
              21.023279674597575
            ],
            [
              105.82991014189084,
              21.023283992314177
            ],
            [
              105.82983506714962,
              21.02314018682103
            ],
            [
              105.8298229232047,
              21.023116924812413
            ],
            [
              105.82982227468437,
              21.023115682429818
            ],
            [
              105.82973252375585,
              21.02296086682495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S4_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3531",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02326025442849,
          "to_latitude": 21.02296086682495,
          "to_longitude": 105.82973252375585,
          "from_longitude": 105.82995831664758
        }
      },
      {
        "id": 3525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82954761707211,
            21.023118142735022,
            105.82975582856706,
            21.02318798592024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82975582856706,
              21.023118142735022
            ],
            [
              105.82960944819551,
              21.023187890267955
            ],
            [
              105.82954761707211,
              21.02318798592024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S3_Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3532",
          "diaChiLapD": "Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023118142735022,
          "to_latitude": 21.02318798592024,
          "to_longitude": 105.82954761707211,
          "from_longitude": 105.82975582856706
        }
      },
      {
        "id": 3526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983506714962,
            21.023137609384843,
            105.82983980509377,
            21.02314018682103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82983980509377,
              21.023137609384843
            ],
            [
              105.82983786291908,
              21.023138665698273
            ],
            [
              105.82983580373516,
              21.02313978580832
            ],
            [
              105.82983506714962,
              21.02314018682103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S4_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3533",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023137609384843,
          "to_latitude": 21.02314018682103,
          "to_longitude": 105.82983506714962,
          "from_longitude": 105.82983980509377
        }
      },
      {
        "id": 3527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160611272173,
            21.023530899824767,
            105.8319525825414,
            21.023691280840342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83160611272173,
              21.023691280840342
            ],
            [
              105.83174554262733,
              21.023630623128465
            ],
            [
              105.8317972046678,
              21.023608148445415
            ],
            [
              105.8319525825414,
              21.023530899824767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q3_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3534",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023691280840342,
          "to_latitude": 21.023530899824767,
          "to_longitude": 105.8319525825414,
          "from_longitude": 105.83160611272173
        }
      },
      {
        "id": 3528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83012076129513,
            21.02316134797473,
            105.83037994903344,
            21.02332919493295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83037994903344,
              21.02330810859064
            ],
            [
              105.83037851830912,
              21.023305110547977
            ],
            [
              105.8303274768828,
              21.02332919493295
            ],
            [
              105.83030464474402,
              21.023301512373738
            ],
            [
              105.83025810526162,
              21.023288567844766
            ],
            [
              105.8302148571802,
              21.02330558435548
            ],
            [
              105.83017200875962,
              21.023241717856358
            ],
            [
              105.83012076129513,
              21.02316134797473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J9_Ngách 47, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3535",
          "diaChiLapD": "Ngách 47, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02330810859064,
          "to_latitude": 21.02316134797473,
          "to_longitude": 105.83012076129513,
          "from_longitude": 105.83037994903344
        }
      },
      {
        "id": 3529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83009607707739,
            21.02275943896119,
            105.83010027386267,
            21.022762803181433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83009607707739,
              21.022762803181433
            ],
            [
              105.83009681549115,
              21.02276221157833
            ],
            [
              105.83010027386267,
              21.02275943896119
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U4_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3536",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022762803181433,
          "to_latitude": 21.02275943896119,
          "to_longitude": 105.83010027386267,
          "from_longitude": 105.83009607707739
        }
      },
      {
        "id": 3530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299815965939,
            21.022654469513057,
            105.83030980221149,
            21.02276946268231
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83030980221149,
              21.022654469513057
            ],
            [
              105.83020555592104,
              21.022735588476465
            ],
            [
              105.83019390631794,
              21.02274080275258
            ],
            [
              105.83017718335968,
              21.0227429157309
            ],
            [
              105.83016501082625,
              21.02275098042469
            ],
            [
              105.83013172962144,
              21.022762195625376
            ],
            [
              105.83011069184016,
              21.02276946268231
            ],
            [
              105.83010027386267,
              21.02275943896119
            ],
            [
              105.83009545366636,
              21.02275480184752
            ],
            [
              105.8299815965939,
              21.02274722215121
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U4_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3537",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022654469513057,
          "to_latitude": 21.02274722215121,
          "to_longitude": 105.8299815965939,
          "from_longitude": 105.83030980221149
        }
      },
      {
        "id": 3531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83126879410983,
            21.022240859923667,
            105.83152940403468,
            21.02236821943638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83152940403468,
              21.022240859923667
            ],
            [
              105.83140606393762,
              21.022298480724654
            ],
            [
              105.83130989562528,
              21.022345998843733
            ],
            [
              105.83128782786196,
              21.02235798319948
            ],
            [
              105.83127990095903,
              21.022362287871076
            ],
            [
              105.83126879410983,
              21.02236821943638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L2_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3538",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022240859923667,
          "to_latitude": 21.02236821943638,
          "to_longitude": 105.83126879410983,
          "from_longitude": 105.83152940403468
        }
      },
      {
        "id": 3532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83137623418847,
            21.022366325170466,
            105.83159865150682,
            21.022464916475684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83159865150682,
              21.022366325170466
            ],
            [
              105.8314931296453,
              21.02240842371378
            ],
            [
              105.83147228629099,
              21.0223881055121
            ],
            [
              105.83137623418847,
              21.022464916475684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ8_Ngõ 200, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3539",
          "diaChiLapD": "Ngõ 200, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022366325170466,
          "to_latitude": 21.022464916475684,
          "to_longitude": 105.83137623418847,
          "from_longitude": 105.83159865150682
        }
      },
      {
        "id": 3533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83122004188236,
            21.02266706176899,
            105.8317226152956,
            21.022851599839694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317226152956,
              21.02266706176899
            ],
            [
              105.83152941579036,
              21.022768402524576
            ],
            [
              105.83150218828166,
              21.022784444128312
            ],
            [
              105.83149271483984,
              21.022790025401093
            ],
            [
              105.83144260917942,
              21.022814694439695
            ],
            [
              105.83140721458106,
              21.022832501111786
            ],
            [
              105.83137080358773,
              21.022851599839694
            ],
            [
              105.83132394971717,
              21.022777603684094
            ],
            [
              105.83127987420126,
              21.02278864786749
            ],
            [
              105.83124460179026,
              21.022730457509965
            ],
            [
              105.83122004188236,
              21.02268993786033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J1_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3540",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02266706176899,
          "to_latitude": 21.02268993786033,
          "to_longitude": 105.83122004188236,
          "from_longitude": 105.8317226152956
        }
      },
      {
        "id": 3534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83144781421515,
            21.022689826206623,
            105.83173323102763,
            21.022971125519682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83173323102763,
              21.022689826206623
            ],
            [
              105.83153705824161,
              21.022787912742192
            ],
            [
              105.83145418326605,
              21.022829350481306
            ],
            [
              105.83147591477557,
              21.02286913063211
            ],
            [
              105.83148204850191,
              21.022948254712794
            ],
            [
              105.83144781421515,
              21.022971125519682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J2_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3541",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022689826206623,
          "to_latitude": 21.022971125519682,
          "to_longitude": 105.83144781421515,
          "from_longitude": 105.83173323102763
        }
      },
      {
        "id": 3535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83043886598158,
            21.02156812516019,
            105.83127913448892,
            21.021899133907482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83127913448892,
              21.02156812516019
            ],
            [
              105.83127433978187,
              21.021569993881
            ],
            [
              105.83093398891117,
              21.02170259649081
            ],
            [
              105.83075358079226,
              21.02177447119518
            ],
            [
              105.83061334486993,
              21.021850724371664
            ],
            [
              105.83052431265108,
              21.021899133907482
            ],
            [
              105.83043886598158,
              21.02176382896647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_ĐT4_Ngõ 232, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3542",
          "diaChiLapD": "Ngõ 232, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02156812516019,
          "to_latitude": 21.02176382896647,
          "to_longitude": 105.83043886598158,
          "from_longitude": 105.83127913448892
        }
      },
      {
        "id": 3536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83082303240684,
            21.02154908479978,
            105.83126960350053,
            21.021710727829852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83126960350053,
              21.02154908479978
            ],
            [
              105.83126498053156,
              21.02155104480989
            ],
            [
              105.83088821600106,
              21.021710727829852
            ],
            [
              105.83082303240684,
              21.021595951942583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_ĐT3_Ngõ 232, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3543",
          "diaChiLapD": "Ngõ 232, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02154908479978,
          "to_latitude": 21.021595951942583,
          "to_longitude": 105.83082303240684,
          "from_longitude": 105.83126960350053
        }
      },
      {
        "id": 3537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83050214561777,
            21.021541501787926,
            105.83094121624131,
            21.021746085053437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83050214561777,
              21.021746085053437
            ],
            [
              105.83080281039338,
              21.021605269028562
            ],
            [
              105.83082303240684,
              21.021595951942583
            ],
            [
              105.83094121624131,
              21.021541501787926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_ĐT3_Ngõ 232, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3544",
          "diaChiLapD": "Ngõ 232, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021746085053437,
          "to_latitude": 21.021541501787926,
          "to_longitude": 105.83094121624131,
          "from_longitude": 105.83050214561777
        }
      },
      {
        "id": 3538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109414794643,
            21.021902181921575,
            105.83145180366792,
            21.02205485284885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109414794643,
              21.02205485284885
            ],
            [
              105.83128059905087,
              21.021960189698746
            ],
            [
              105.83138714239635,
              21.021907354186165
            ],
            [
              105.83139757233717,
              21.021902181921575
            ],
            [
              105.83142374660649,
              21.021948819328557
            ],
            [
              105.83144022403091,
              21.0219423475662
            ],
            [
              105.83145180366792,
              21.021935637809523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3545",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02205485284885,
          "to_latitude": 21.021935637809523,
          "to_longitude": 105.83145180366792,
          "from_longitude": 105.83109414794643
        }
      },
      {
        "id": 3539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135632140778,
            21.02182238893294,
            105.83139893362048,
            21.021841908157747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83139893362048,
              21.02182238893294
            ],
            [
              105.8313891290401,
              21.02182687981917
            ],
            [
              105.83137983781587,
              21.021831136066893
            ],
            [
              105.83135632140778,
              21.021841908157747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ7_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3546",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02182238893294,
          "to_latitude": 21.021841908157747,
          "to_longitude": 105.83135632140778,
          "from_longitude": 105.83139893362048
        }
      },
      {
        "id": 3540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83120709289103,
            21.022368430660336,
            105.83121298489722,
            21.022379514236423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83120709289103,
              21.022368430660336
            ],
            [
              105.83121138089909,
              21.02237649627331
            ],
            [
              105.831212464824,
              21.02237853768273
            ],
            [
              105.83121298489722,
              21.022379514236423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L3_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3547",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022368430660336,
          "to_latitude": 21.022379514236423,
          "to_longitude": 105.83121298489722,
          "from_longitude": 105.83120709289103
        }
      },
      {
        "id": 3541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83106206500472,
            21.022443983243356,
            105.83113862133679,
            21.022547409519323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83106206500472,
              21.022443983243356
            ],
            [
              105.83108582235073,
              21.02248932915633
            ],
            [
              105.83113862133679,
              21.022547409519323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4_Ngách 26, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3548",
          "diaChiLapD": "Ngách 26, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022443983243356,
          "to_latitude": 21.022547409519323,
          "to_longitude": 105.83113862133679,
          "from_longitude": 105.83106206500472
        }
      },
      {
        "id": 3542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83126879410983,
            21.02236821943638,
            105.83131953628133,
            21.022461710739584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83126879410983,
              21.02236821943638
            ],
            [
              105.83131953628133,
              21.022448833503415
            ],
            [
              105.83129385640541,
              21.022461710739584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L2_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3549",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02236821943638,
          "to_latitude": 21.022461710739584,
          "to_longitude": 105.83129385640541,
          "from_longitude": 105.83126879410983
        }
      },
      {
        "id": 3543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83095355688825,
            21.022364733980226,
            105.83125473083412,
            21.02248676714444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83125473083412,
              21.022364733980226
            ],
            [
              105.83122047378923,
              21.022379144347784
            ],
            [
              105.83117262259661,
              21.02239730178106
            ],
            [
              105.83111832901193,
              21.022418757644996
            ],
            [
              105.83107033385066,
              21.022440276651892
            ],
            [
              105.83106206500472,
              21.022443983243356
            ],
            [
              105.83105566258021,
              21.02244685426442
            ],
            [
              105.83095355688825,
              21.02248676714444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3550",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022364733980226,
          "to_latitude": 21.02248676714444,
          "to_longitude": 105.83095355688825,
          "from_longitude": 105.83125473083412
        }
      },
      {
        "id": 3544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83093493590886,
            21.022279650419673,
            105.83141435472528,
            21.02248053464251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141435472528,
              21.022279650419673
            ],
            [
              105.83131328594382,
              21.022328528363865
            ],
            [
              105.83127864366462,
              21.02234164178894
            ],
            [
              105.83120709289103,
              21.022368430660336
            ],
            [
              105.83117468273166,
              21.022380564899642
            ],
            [
              105.83116282775046,
              21.0223855083737
            ],
            [
              105.83093493590886,
              21.02248053464251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L3_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3551",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022279650419673,
          "to_latitude": 21.02248053464251,
          "to_longitude": 105.83093493590886,
          "from_longitude": 105.83141435472528
        }
      },
      {
        "id": 3545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83069963969999,
            21.022498836345772,
            105.83089007720862,
            21.022576310324098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83089007720862,
              21.022498836345772
            ],
            [
              105.83082510810324,
              21.022525961422865
            ],
            [
              105.8307938535534,
              21.02253850362307
            ],
            [
              105.83069963969999,
              21.022576310324098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L6_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3552",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022498836345772,
          "to_latitude": 21.022576310324098,
          "to_longitude": 105.83069963969999,
          "from_longitude": 105.83089007720862
        }
      },
      {
        "id": 3546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310682748705,
            21.022436049686497,
            105.83107033385066,
            21.022440276651892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310682748705,
              21.022436049686497
            ],
            [
              105.83106922847959,
              21.022438010443427
            ],
            [
              105.83107033385066,
              21.022440276651892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L4_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3553",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022436049686497,
          "to_latitude": 21.022440276651892,
          "to_longitude": 105.83107033385066,
          "from_longitude": 105.8310682748705
        }
      },
      {
        "id": 3547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83070858933019,
            21.022495793442364,
            105.83097522088192,
            21.022669743489864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83070858933019,
              21.022597550195872
            ],
            [
              105.83073330527091,
              21.02258639026804
            ],
            [
              105.83078001118747,
              21.022565861262805
            ],
            [
              105.8308127974447,
              21.022551450037273
            ],
            [
              105.83085918519174,
              21.02253096953267
            ],
            [
              105.83089402145035,
              21.022515696536892
            ],
            [
              105.83093739560107,
              21.022495793442364
            ],
            [
              105.83097522088192,
              21.022578448781083
            ],
            [
              105.83092970720037,
              21.022602506332074
            ],
            [
              105.8309660820054,
              21.022669743489864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L7_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3554",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022597550195872,
          "to_latitude": 21.022669743489864,
          "to_longitude": 105.8309660820054,
          "from_longitude": 105.83070858933019
        }
      },
      {
        "id": 3548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83077819673814,
            21.022562014260092,
            105.83078001118747,
            21.022565861262805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83077819673814,
              21.022562014260092
            ],
            [
              105.83077905141414,
              21.022563829179578
            ],
            [
              105.83078001118747,
              21.022565861262805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L7_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3555",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022562014260092,
          "to_latitude": 21.022565861262805,
          "to_longitude": 105.83078001118747,
          "from_longitude": 105.83077819673814
        }
      },
      {
        "id": 3549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8307938535534,
            21.02253850362307,
            105.83080175173082,
            21.022553490183032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83080175173082,
              21.022553490183032
            ],
            [
              105.83080103523272,
              21.022552133426785
            ],
            [
              105.8307990966447,
              21.02254845232077
            ],
            [
              105.8307938535534,
              21.02253850362307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L6_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3556",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022553490183032,
          "to_latitude": 21.02253850362307,
          "to_longitude": 105.8307938535534,
          "from_longitude": 105.83080175173082
        }
      },
      {
        "id": 3550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052487395729,
            21.022205807007406,
            105.83075487294305,
            21.02230736068881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052487395729,
              21.02230736068881
            ],
            [
              105.83053562761123,
              21.02230261321699
            ],
            [
              105.83075487294305,
              21.022205807007406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngách 49, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3557",
          "diaChiLapD": "Ngách 49, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02230736068881,
          "to_latitude": 21.022205807007406,
          "to_longitude": 105.83075487294305,
          "from_longitude": 105.83052487395729
        }
      },
      {
        "id": 3551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83006263582978,
            21.02234841311155,
            105.83055444033546,
            21.02258211462655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83054598563447,
              21.022350962830142
            ],
            [
              105.83055281215451,
              21.02234841311155
            ],
            [
              105.83055444033546,
              21.02235180489815
            ],
            [
              105.8305474229773,
              21.02235376754952
            ],
            [
              105.8305436127022,
              21.022354832992864
            ],
            [
              105.83052931865258,
              21.022358830915504
            ],
            [
              105.83043389895853,
              21.022392549039843
            ],
            [
              105.8302598890719,
              21.022454037438628
            ],
            [
              105.83024835151635,
              21.02245811401123
            ],
            [
              105.83023985983185,
              21.022463102423572
            ],
            [
              105.83011658190026,
              21.022533816954233
            ],
            [
              105.83006263582978,
              21.02258211462655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3558",
          "diaChiLapD": "Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022350962830142,
          "to_latitude": 21.02258211462655,
          "to_longitude": 105.83006263582978,
          "from_longitude": 105.83054598563447
        }
      },
      {
        "id": 3552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8301918469252,
            21.022463102423572,
            105.8302595651587,
            21.022573179737233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83023985983185,
              21.022463102423572
            ],
            [
              105.83025293590036,
              21.02250477208743
            ],
            [
              105.8302595651587,
              21.022529833113072
            ],
            [
              105.83020249392351,
              21.022566364422023
            ],
            [
              105.8301918469252,
              21.022573179737233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T3_Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3559",
          "diaChiLapD": "Ngách 70, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022463102423572,
          "to_latitude": 21.022573179737233,
          "to_longitude": 105.8301918469252,
          "from_longitude": 105.83023985983185
        }
      },
      {
        "id": 3553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83053533916184,
            21.022317227896053,
            105.83068319008335,
            21.02257861711297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068319008335,
              21.02257861711297
            ],
            [
              105.8306446484136,
              21.022531582960042
            ],
            [
              105.83061751414144,
              21.02247503361818
            ],
            [
              105.83061646112263,
              21.02247283940888
            ],
            [
              105.83058563102193,
              21.02241369959717
            ],
            [
              105.83055570176454,
              21.02234977009597
            ],
            [
              105.83053533916184,
              21.022317227896053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3560",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02257861711297,
          "to_latitude": 21.022317227896053,
          "to_longitude": 105.83053533916184,
          "from_longitude": 105.83068319008335
        }
      },
      {
        "id": 3554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83061120265937,
            21.02247503361818,
            105.83061751414144,
            21.022478173339575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83061120265937,
              21.022478173339575
            ],
            [
              105.83061387441487,
              21.02247684429374
            ],
            [
              105.83061635429969,
              21.022475610120257
            ],
            [
              105.83061751414144,
              21.02247503361818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3561",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022478173339575,
          "to_latitude": 21.02247503361818,
          "to_longitude": 105.83061751414144,
          "from_longitude": 105.83061120265937
        }
      },
      {
        "id": 3555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83064034399277,
            21.022546597509056,
            105.83079182262918,
            21.022839348721156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83079182262918,
              21.022839348721156
            ],
            [
              105.83078270819091,
              21.022815801018325
            ],
            [
              105.83072566602293,
              21.022721274484045
            ],
            [
              105.83072141446719,
              21.022714229324347
            ],
            [
              105.83066952374689,
              21.02261431155016
            ],
            [
              105.83067757245651,
              21.022604726060784
            ],
            [
              105.83064034399277,
              21.022546597509056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L8_Ngách 56, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3562",
          "diaChiLapD": "Ngách 56, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022839348721156,
          "to_latitude": 21.022546597509056,
          "to_longitude": 105.83064034399277,
          "from_longitude": 105.83079182262918
        }
      },
      {
        "id": 3556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83072566602293,
            21.022717571418386,
            105.83073247654634,
            21.022721274484045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83073247654634,
              21.022717571418386
            ],
            [
              105.83073044998166,
              21.02271867331514
            ],
            [
              105.8307283927244,
              21.022719791619828
            ],
            [
              105.8307263354671,
              21.0227209099245
            ],
            [
              105.83072566602293,
              21.022721274484045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L8_Ngách 56, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3563",
          "diaChiLapD": "Ngách 56, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022717571418386,
          "to_latitude": 21.022721274484045,
          "to_longitude": 105.83072566602293,
          "from_longitude": 105.83073247654634
        }
      },
      {
        "id": 3557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83031514836931,
            21.022536332244755,
            105.83062446252696,
            21.02264394884763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83062446252696,
              21.022536332244755
            ],
            [
              105.83057892891959,
              21.02255628012882
            ],
            [
              105.83052119960605,
              21.02258323154382
            ],
            [
              105.83051242323937,
              21.022584062913882
            ],
            [
              105.83049423572503,
              21.022592348485034
            ],
            [
              105.83045855212806,
              21.022594636265236
            ],
            [
              105.83042038749339,
              21.022597082473894
            ],
            [
              105.83041893926274,
              21.02259717535112
            ],
            [
              105.83040454353768,
              21.022603663047256
            ],
            [
              105.83031514836931,
              21.02264394884763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U2_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3564",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022536332244755,
          "to_latitude": 21.02264394884763,
          "to_longitude": 105.83031514836931,
          "from_longitude": 105.83062446252696
        }
      },
      {
        "id": 3558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83048411290282,
            21.022219297515736,
            105.83062877328324,
            21.022529233525052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83062877328324,
              21.022529233525052
            ],
            [
              105.83055852244408,
              21.022385225951027
            ],
            [
              105.83054638682933,
              21.022360349890533
            ],
            [
              105.8305436127022,
              21.022354832992864
            ],
            [
              105.83053822592407,
              21.02234412176327
            ],
            [
              105.83051259032818,
              21.02228563218256
            ],
            [
              105.83048411290282,
              21.022219297515736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3565",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022529233525052,
          "to_latitude": 21.022219297515736,
          "to_longitude": 105.83048411290282,
          "from_longitude": 105.83062877328324
        }
      },
      {
        "id": 3559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83055852244408,
            21.02238326977948,
            105.83056254807947,
            21.022385225951027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83056254807947,
              21.02238326977948
            ],
            [
              105.83056030404607,
              21.022384360090967
            ],
            [
              105.83055852244408,
              21.022385225951027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3566",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02238326977948,
          "to_latitude": 21.022385225951027,
          "to_longitude": 105.83055852244408,
          "from_longitude": 105.83056254807947
        }
      },
      {
        "id": 3560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971811901312,
            21.022382150718993,
            105.82992373822334,
            21.022785998111434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82992373822334,
              21.022785998111434
            ],
            [
              105.82992276956529,
              21.02278409703407
            ],
            [
              105.82978108693452,
              21.022505661187385
            ],
            [
              105.82971811901312,
              21.022382150718993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U6_Ngách 75, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3567",
          "diaChiLapD": "Ngách 75, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022785998111434,
          "to_latitude": 21.022382150718993,
          "to_longitude": 105.82971811901312,
          "from_longitude": 105.82992373822334
        }
      },
      {
        "id": 3561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82978108693452,
            21.022430667082812,
            105.82993164427084,
            21.022505661187385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82978108693452,
              21.022505661187385
            ],
            [
              105.82993164427084,
              21.022430667082812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U6_Ngách 75, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3568",
          "diaChiLapD": "Ngách 75, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022505661187385,
          "to_latitude": 21.022430667082812,
          "to_longitude": 105.82993164427084,
          "from_longitude": 105.82978108693452
        }
      },
      {
        "id": 3562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83117940886395,
            21.022765975674755,
            105.83135944585098,
            21.022902823046053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83135944585098,
              21.022855662963394
            ],
            [
              105.8312892563211,
              21.02288787811529
            ],
            [
              105.83126913184148,
              21.022897114395807
            ],
            [
              105.83125669425543,
              21.022902823046053
            ],
            [
              105.83117940886395,
              21.022765975674755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J3_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3569",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022855662963394,
          "to_latitude": 21.022765975674755,
          "to_longitude": 105.83117940886395,
          "from_longitude": 105.83135944585098
        }
      },
      {
        "id": 3563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83108187549482,
            21.022755766237555,
            105.83120311880005,
            21.022966585852714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83113163674697,
              21.022966585852714
            ],
            [
              105.831161197671,
              21.022951507482656
            ],
            [
              105.83119179363764,
              21.022935900179167
            ],
            [
              105.83120311880005,
              21.022930123808898
            ],
            [
              105.83117195491816,
              21.022873477743207
            ],
            [
              105.83114148628678,
              21.022857041631116
            ],
            [
              105.83108187549482,
              21.022755766237555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J4_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3570",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022966585852714,
          "to_latitude": 21.022755766237555,
          "to_longitude": 105.83108187549482,
          "from_longitude": 105.83113163674697
        }
      },
      {
        "id": 3564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83126913184148,
            21.022897114395807,
            105.83127271385469,
            21.022904327200006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83126913184148,
              21.022897114395807
            ],
            [
              105.83126989941896,
              21.022898660577336
            ],
            [
              105.83127174102572,
              21.022902371054563
            ],
            [
              105.83127271385469,
              21.022904327200006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J3_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3571",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022897114395807,
          "to_latitude": 21.022904327200006,
          "to_longitude": 105.83127271385469,
          "from_longitude": 105.83126913184148
        }
      },
      {
        "id": 3565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831161197671,
            21.022951507482656,
            105.83116283114562,
            21.02295428865708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831161197671,
              21.022951507482656
            ],
            [
              105.83116159292804,
              21.022952180259736
            ],
            [
              105.83116264955211,
              21.022953981544966
            ],
            [
              105.83116283114562,
              21.02295428865708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J4_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3572",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022951507482656,
          "to_latitude": 21.02295428865708,
          "to_longitude": 105.83116283114562,
          "from_longitude": 105.831161197671
        }
      },
      {
        "id": 3566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83068536004227,
            21.022970959951454,
            105.83111060988959,
            21.023234022262066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83111060988959,
              21.022970959951454
            ],
            [
              105.83092596370793,
              21.023075629980728
            ],
            [
              105.83092538021194,
              21.023081782886607
            ],
            [
              105.83092283098212,
              21.023108694267762
            ],
            [
              105.8309184330758,
              21.023143116862602
            ],
            [
              105.83091399666696,
              21.023189165950217
            ],
            [
              105.8308052022035,
              21.023234022262066
            ],
            [
              105.83074245658527,
              21.023187130757805
            ],
            [
              105.83068536004227,
              21.023172234558835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J6_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3573",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022970959951454,
          "to_latitude": 21.023172234558835,
          "to_longitude": 105.83068536004227,
          "from_longitude": 105.83111060988959
        }
      },
      {
        "id": 3567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83038749046393,
            21.023173373805065,
            105.83067698072426,
            21.023302451907803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067698072426,
              21.023173373805065
            ],
            [
              105.83051778185212,
              21.023233143568245
            ],
            [
              105.83049227636401,
              21.023246974544698
            ],
            [
              105.83040642969172,
              21.023293527709082
            ],
            [
              105.83038749046393,
              21.023302451907803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J8_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3574",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023173373805065,
          "to_latitude": 21.023302451907803,
          "to_longitude": 105.83038749046393,
          "from_longitude": 105.83067698072426
        }
      },
      {
        "id": 3568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8309184330758,
            21.023143116862602,
            105.8309241543815,
            21.023143705813528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8309184330758,
              21.023143116862602
            ],
            [
              105.83092010500548,
              21.02314329024255
            ],
            [
              105.83092301281685,
              21.02314358855213
            ],
            [
              105.8309241543815,
              21.023143705813528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J6_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3575",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023143116862602,
          "to_latitude": 21.023143705813528,
          "to_longitude": 105.8309241543815,
          "from_longitude": 105.8309184330758
        }
      },
      {
        "id": 3569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83049227636401,
            21.023246974544698,
            105.83049370851863,
            21.023249883160165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83049227636401,
              21.023246974544698
            ],
            [
              105.83049256727196,
              21.023247565639462
            ],
            [
              105.83049344584767,
              21.023249353346777
            ],
            [
              105.83049370851863,
              21.023249883160165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J8_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3576",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023246974544698,
          "to_latitude": 21.023249883160165,
          "to_longitude": 105.83049370851863,
          "from_longitude": 105.83049227636401
        }
      },
      {
        "id": 3570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091067294752,
            21.02319349508471,
            105.83110052361239,
            21.023560871666657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83091067294752,
              21.02319349508471
            ],
            [
              105.83091381736834,
              21.023204722157246
            ],
            [
              105.83101703902612,
              21.0233612072741
            ],
            [
              105.83109146718756,
              21.02347856173077
            ],
            [
              105.83109789557462,
              21.02348869701146
            ],
            [
              105.83107018668872,
              21.023499841813038
            ],
            [
              105.83110052361239,
              21.023560871666657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J7_Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3577",
          "diaChiLapD": "Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02319349508471,
          "to_latitude": 21.023560871666657,
          "to_longitude": 105.83110052361239,
          "from_longitude": 105.83091067294752
        }
      },
      {
        "id": 3571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.830857865441,
            21.023349860955005,
            105.83101703902612,
            21.02342260127392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101703902612,
              21.0233612072741
            ],
            [
              105.83090148965806,
              21.02342260127392
            ],
            [
              105.830857865441,
              21.023349860955005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J7_Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3578",
          "diaChiLapD": "Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0233612072741,
          "to_latitude": 21.023349860955005,
          "to_longitude": 105.830857865441,
          "from_longitude": 105.83101703902612
        }
      },
      {
        "id": 3572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83083227475224,
            21.022805484059212,
            105.83112250889047,
            21.0229628423343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83112250889047,
              21.0229628423343
            ],
            [
              105.83105853483337,
              21.022861278450193
            ],
            [
              105.83100697731702,
              21.022856244695394
            ],
            [
              105.83100489236189,
              21.022856704688124
            ],
            [
              105.83099134977299,
              21.02285969459321
            ],
            [
              105.83096030923352,
              21.022858961992924
            ],
            [
              105.83092015404098,
              21.022805484059212
            ],
            [
              105.83083227475224,
              21.022857532642448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J5_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3579",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0229628423343,
          "to_latitude": 21.022857532642448,
          "to_longitude": 105.83083227475224,
          "from_longitude": 105.83112250889047
        }
      },
      {
        "id": 3573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83027319047746,
            21.022879357281862,
            105.83079935635138,
            21.0230553150473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83079935635138,
              21.022879357281862
            ],
            [
              105.83071891274076,
              21.02292786587925
            ],
            [
              105.83064339610121,
              21.02296208846261
            ],
            [
              105.83059011261952,
              21.022973700406745
            ],
            [
              105.83053839130218,
              21.022981822775566
            ],
            [
              105.83051370612841,
              21.02298569982732
            ],
            [
              105.83047933449902,
              21.02299109823583
            ],
            [
              105.83027319047746,
              21.0230553150473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3580",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022879357281862,
          "to_latitude": 21.0230553150473,
          "to_longitude": 105.83027319047746,
          "from_longitude": 105.83079935635138
        }
      },
      {
        "id": 3574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.830504363826,
            21.022837607387956,
            105.83080271514044,
            21.022905550850407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83080271514044,
              21.022837607387956
            ],
            [
              105.83080154323159,
              21.02283826343403
            ],
            [
              105.83079785966805,
              21.022840323617746
            ],
            [
              105.83079908721398,
              21.022853528182825
            ],
            [
              105.83074030515296,
              21.02288199410391
            ],
            [
              105.83071591119251,
              21.02284327911421
            ],
            [
              105.83067881507121,
              21.022854198732034
            ],
            [
              105.830504363826,
              21.022905550850407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L9_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3581",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022837607387956,
          "to_latitude": 21.022905550850407,
          "to_longitude": 105.830504363826,
          "from_longitude": 105.83080271514044
        }
      },
      {
        "id": 3575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83017293301253,
            21.022746106809564,
            105.83032143924105,
            21.023029504703427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83017330018649,
              21.022746106809564
            ],
            [
              105.83017329280227,
              21.022746510587005
            ],
            [
              105.83017293301253,
              21.022768401088058
            ],
            [
              105.83024320278896,
              21.022921071554887
            ],
            [
              105.83024526994748,
              21.022925562232146
            ],
            [
              105.8302947356852,
              21.02296439335054
            ],
            [
              105.83032143924105,
              21.023029504703427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U5_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3582",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022746106809564,
          "to_latitude": 21.023029504703427,
          "to_longitude": 105.83032143924105,
          "from_longitude": 105.83017330018649
        }
      },
      {
        "id": 3576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83051594243386,
            21.022584805341555,
            105.83060920896305,
            21.022739439870715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83060920896305,
              21.022739439870715
            ],
            [
              105.83058612821428,
              21.022702296380245
            ],
            [
              105.83052294668722,
              21.02260062092416
            ],
            [
              105.83051618404568,
              21.02258535422552
            ],
            [
              105.83051594243386,
              21.022584805341555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U1_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3583",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022739439870715,
          "to_latitude": 21.022584805341555,
          "to_longitude": 105.83051594243386,
          "from_longitude": 105.83060920896305
        }
      },
      {
        "id": 3577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040454353768,
            21.022603663047256,
            105.83041671166941,
            21.022709447105495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83041671166941,
              21.022709447105495
            ],
            [
              105.83041242389373,
              21.02264757186719
            ],
            [
              105.83040584087153,
              21.022610889735617
            ],
            [
              105.83040454353768,
              21.022603663047256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U2_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3584",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022709447105495,
          "to_latitude": 21.022603663047256,
          "to_longitude": 105.83040454353768,
          "from_longitude": 105.83041671166941
        }
      },
      {
        "id": 3578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100489236189,
            21.022856704688124,
            105.83100509332905,
            21.02286224318175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100509332905,
              21.02286224318175
            ],
            [
              105.83100495815705,
              21.022858509009094
            ],
            [
              105.83100491632737,
              21.022857383795806
            ],
            [
              105.83100489236189,
              21.022856704688124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J5_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3585",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02286224318175,
          "to_latitude": 21.022856704688124,
          "to_longitude": 105.83100489236189,
          "from_longitude": 105.83100509332905
        }
      },
      {
        "id": 3579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83053839130218,
            21.022981822775566,
            105.83054009068269,
            21.022990599238724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83054009068269,
              21.022990599238724
            ],
            [
              105.83053980550865,
              21.022989132891468
            ],
            [
              105.83053947965355,
              21.022987442743172
            ],
            [
              105.83053839130218,
              21.022981822775566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K2_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3586",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022990599238724,
          "to_latitude": 21.022981822775566,
          "to_longitude": 105.83053839130218,
          "from_longitude": 105.83054009068269
        }
      },
      {
        "id": 3580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83064498811288,
            21.02272158773722,
            105.83067881507121,
            21.022854198732034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067881507121,
              21.022854198732034
            ],
            [
              105.83064498811288,
              21.02278981861297
            ],
            [
              105.83066838126143,
              21.022755719638482
            ],
            [
              105.8306491372133,
              21.02272158773722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_L9_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3587",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022854198732034,
          "to_latitude": 21.02272158773722,
          "to_longitude": 105.8306491372133,
          "from_longitude": 105.83067881507121
        }
      },
      {
        "id": 3581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162221888809,
            21.02240476083939,
            105.83165335991752,
            21.02241728637625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165335991752,
              21.02240476083939
            ],
            [
              105.83164960886496,
              21.022406269500927
            ],
            [
              105.83163680652855,
              21.02241141905888
            ],
            [
              105.83162221888809,
              21.02241728637625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ8_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3588",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02240476083939,
          "to_latitude": 21.02241728637625,
          "to_longitude": 105.83162221888809,
          "from_longitude": 105.83165335991752
        }
      },
      {
        "id": 3582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153249098419,
            21.02278101727624,
            105.83153705824161,
            21.022787912742192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83153705824161,
              21.022787912742192
            ],
            [
              105.83153404596447,
              21.02278334721857
            ],
            [
              105.83153249098419,
              21.02278101727624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J2_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3589",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022787912742192,
          "to_latitude": 21.02278101727624,
          "to_longitude": 105.83153249098419,
          "from_longitude": 105.83153705824161
        }
      },
      {
        "id": 3583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150218828166,
            21.022784444128312,
            105.8315081920569,
            21.02279364482655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83150218828166,
              21.022784444128312
            ],
            [
              105.83150429950676,
              21.02278768089847
            ],
            [
              105.831507707908,
              21.022792902033387
            ],
            [
              105.8315081920569,
              21.02279364482655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J1_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3590",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022784444128312,
          "to_latitude": 21.02279364482655,
          "to_longitude": 105.8315081920569,
          "from_longitude": 105.83150218828166
        }
      },
      {
        "id": 3584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83158963582967,
            21.022797291695724,
            105.83179790292614,
            21.022900033046938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83158963582967,
              21.022900033046938
            ],
            [
              105.83179790292614,
              21.022797291695724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ9_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3591",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022900033046938,
          "to_latitude": 21.022797291695724,
          "to_longitude": 105.83179790292614,
          "from_longitude": 105.83158963582967
        }
      },
      {
        "id": 3585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83161854423953,
            21.022947297864793,
            105.83186964391027,
            21.023070322905422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83186964391027,
              21.022947297864793
            ],
            [
              105.83161854423953,
              21.023070322905422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ9_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3592",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022947297864793,
          "to_latitude": 21.023070322905422,
          "to_longitude": 105.83161854423953,
          "from_longitude": 105.83186964391027
        }
      },
      {
        "id": 3586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169787754264,
            21.023096521655862,
            105.83197539071995,
            21.023206347140245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197539071995,
              21.023096521655862
            ],
            [
              105.83197075425038,
              21.02309778897894
            ],
            [
              105.83194534139747,
              21.023106267209005
            ],
            [
              105.83191244756456,
              21.023117241506984
            ],
            [
              105.83179188074848,
              21.023167309820245
            ],
            [
              105.83169787754264,
              21.023206347140245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ10'_Ngõ 172, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3593",
          "diaChiLapD": "Ngõ 172, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023096521655862,
          "to_latitude": 21.023206347140245,
          "to_longitude": 105.83169787754264,
          "from_longitude": 105.83197539071995
        }
      },
      {
        "id": 3587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319234241509,
            21.02305124521956,
            105.83209815346311,
            21.02343643843204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209815346311,
              21.02343643843204
            ],
            [
              105.83200482984914,
              21.023236506565336
            ],
            [
              105.83195554439868,
              21.023130919580886
            ],
            [
              105.83194534139747,
              21.023106267209005
            ],
            [
              105.83193321461589,
              21.02307696722208
            ],
            [
              105.8319234241509,
              21.02305124521956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ10_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3594",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02343643843204,
          "to_latitude": 21.02305124521956,
          "to_longitude": 105.8319234241509,
          "from_longitude": 105.83209815346311
        }
      },
      {
        "id": 3588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157768791762,
            21.02285592645466,
            105.83182594487648,
            21.022980674430205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157768791762,
              21.022980674430205
            ],
            [
              105.83182594487648,
              21.02285592645466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ9_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3595",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022980674430205,
          "to_latitude": 21.02285592645466,
          "to_longitude": 105.83182594487648,
          "from_longitude": 105.83157768791762
        }
      },
      {
        "id": 3589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83200482984914,
            21.023227439953327,
            105.83202718034015,
            21.023236506565336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202718034015,
              21.023227439953327
            ],
            [
              105.83201667793271,
              21.023231700355765
            ],
            [
              105.83200482984914,
              21.023236506565336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ10_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3596",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023227439953327,
          "to_latitude": 21.023236506565336,
          "to_longitude": 105.83200482984914,
          "from_longitude": 105.83202718034015
        }
      },
      {
        "id": 3590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83212935952074,
            21.024380007207483,
            105.83254974844492,
            21.02449653611279
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83254974844492,
              21.024468382946882
            ],
            [
              105.83247361000745,
              21.02449653611279
            ],
            [
              105.83241824477437,
              21.024469752839945
            ],
            [
              105.83236199196368,
              21.024420848516055
            ],
            [
              105.83234173748441,
              21.024380007207483
            ],
            [
              105.83230345350202,
              21.024390140871233
            ],
            [
              105.83212935952074,
              21.02443622081535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O1_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3597",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024468382946882,
          "to_latitude": 21.02443622081535,
          "to_longitude": 105.83212935952074,
          "from_longitude": 105.83254974844492
        }
      },
      {
        "id": 3591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83220845499865,
            21.02366644220291,
            105.83254918835192,
            21.024420877911826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220845499865,
              21.02366644220291
            ],
            [
              105.83231292118163,
              21.023904368622542
            ],
            [
              105.83254918835192,
              21.024420877911826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ11_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3598",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02366644220291,
          "to_latitude": 21.024420877911826,
          "to_longitude": 105.83254918835192,
          "from_longitude": 105.83220845499865
        }
      },
      {
        "id": 3592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83231292118163,
            21.02388196732796,
            105.83235582759885,
            21.023904368622542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83235582759885,
              21.02388196732796
            ],
            [
              105.83231292118163,
              21.023904368622542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ11_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3599",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02388196732796,
          "to_latitude": 21.023904368622542,
          "to_longitude": 105.83231292118163,
          "from_longitude": 105.83235582759885
        }
      },
      {
        "id": 3593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323984883789,
            21.02550003771843,
            105.83264262183917,
            21.02586192541446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323984883789,
              21.02586192541446
            ],
            [
              105.83264262183917,
              21.02576012920858
            ],
            [
              105.83252983738787,
              21.025523095555368
            ],
            [
              105.83251883585206,
              21.02550003771843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N8_Ngõ 18, Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3600",
          "diaChiLapD": "Ngõ 18, Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02586192541446,
          "to_latitude": 21.02550003771843,
          "to_longitude": 105.83251883585206,
          "from_longitude": 105.8323984883789
        }
      },
      {
        "id": 3594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83212315879605,
            21.02566511273434,
            105.8321860458924,
            21.025801692216106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321860458924,
              21.025801692216106
            ],
            [
              105.83212315879605,
              21.02566511273434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N9_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3601",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025801692216106,
          "to_latitude": 21.02566511273434,
          "to_longitude": 105.83212315879605,
          "from_longitude": 105.8321860458924
        }
      },
      {
        "id": 3595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202472171959,
            21.024464679415836,
            105.83202681084074,
            21.024472247245612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202681084074,
              21.024472247245612
            ],
            [
              105.83202571080098,
              21.024468173667223
            ],
            [
              105.83202472171959,
              21.024464679415836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O21_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3602",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024472247245612,
          "to_latitude": 21.024464679415836,
          "to_longitude": 105.83202472171959,
          "from_longitude": 105.83202681084074
        }
      },
      {
        "id": 3596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83310700185157,
            21.025644981074578,
            105.83315544563226,
            21.02567020337519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315544563226,
              21.025644981074578
            ],
            [
              105.83312440988435,
              21.025661140051017
            ],
            [
              105.83310700185157,
              21.02567020337519
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ14_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3603",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025644981074578,
          "to_latitude": 21.02567020337519,
          "to_longitude": 105.83310700185157,
          "from_longitude": 105.83315544563226
        }
      },
      {
        "id": 3597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83302125906008,
            21.025482601095902,
            105.83319877696357,
            21.025863579259145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83302125906008,
              21.025482601095902
            ],
            [
              105.83310700185157,
              21.02567020337519
            ],
            [
              105.83319877696357,
              21.025863579259145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ14_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3604",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025482601095902,
          "to_latitude": 21.025863579259145,
          "to_longitude": 105.83319877696357,
          "from_longitude": 105.83302125906008
        }
      },
      {
        "id": 3598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83277667951228,
            21.025400227246582,
            105.83279811748206,
            21.025446603974217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83277667951228,
              21.025400227246582
            ],
            [
              105.83278791277365,
              21.025424470767604
            ],
            [
              105.83279809702974,
              21.025446558913174
            ],
            [
              105.83279811748206,
              21.025446603974217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3605",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025400227246582,
          "to_latitude": 21.025446603974217,
          "to_longitude": 105.83279811748206,
          "from_longitude": 105.83277667951228
        }
      },
      {
        "id": 3599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83276457000846,
            21.02545930951505,
            105.83286180334747,
            21.02561198149859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83276457000846,
              21.02545930951505
            ],
            [
              105.83283298806401,
              21.02561198149859
            ],
            [
              105.83286180334747,
              21.025597693186977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N7_Ngõ 6, Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3606",
          "diaChiLapD": "Ngõ 6, Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02545930951505,
          "to_latitude": 21.025597693186977,
          "to_longitude": 105.83286180334747,
          "from_longitude": 105.83276457000846
        }
      },
      {
        "id": 3600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8325832268708,
            21.025405367883447,
            105.83290699649392,
            21.025528014173382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83290699649392,
              21.025405367883447
            ],
            [
              105.83279811748206,
              21.025446603974217
            ],
            [
              105.83276457000846,
              21.02545930951505
            ],
            [
              105.8325832268708,
              21.025528014173382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3607",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025405367883447,
          "to_latitude": 21.025528014173382,
          "to_longitude": 105.8325832268708,
          "from_longitude": 105.83290699649392
        }
      },
      {
        "id": 3601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83282036436982,
            21.024934297657794,
            105.83284062787004,
            21.024944341384593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83282036436982,
              21.024944341384593
            ],
            [
              105.83282927293932,
              21.024939925252145
            ],
            [
              105.83284062787004,
              21.024934297657794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ13_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3608",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024944341384593,
          "to_latitude": 21.024934297657794,
          "to_longitude": 105.83284062787004,
          "from_longitude": 105.83282036436982
        }
      },
      {
        "id": 3602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83219784049449,
            21.025637312773682,
            105.83230948132798,
            21.025888494933543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219784049449,
              21.025637312773682
            ],
            [
              105.83230948132798,
              21.025888494933543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N9_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3609",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025637312773682,
          "to_latitude": 21.025888494933543,
          "to_longitude": 105.83230948132798,
          "from_longitude": 105.83219784049449
        }
      },
      {
        "id": 3603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83205838168885,
            21.027815394326563,
            105.83240089294864,
            21.02807573557707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83240089294864,
              21.027815394326563
            ],
            [
              105.83238569762808,
              21.027820539405884
            ],
            [
              105.83233183562594,
              21.027838779912322
            ],
            [
              105.83233179337869,
              21.027838794570687
            ],
            [
              105.83212080731697,
              21.02791024246925
            ],
            [
              105.83208324553574,
              21.027930022613702
            ],
            [
              105.83205838168885,
              21.027944482953146
            ],
            [
              105.83207910384868,
              21.02807573557707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N51_Ngõ 10, Bích Câu",
          "maTaiSan": "04.O13.DODV.3610",
          "diaChiLapD": "Ngõ 10, Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027815394326563,
          "to_latitude": 21.02807573557707,
          "to_longitude": 105.83207910384868,
          "from_longitude": 105.83240089294864
        }
      },
      {
        "id": 3604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189473968677,
            21.027707141412844,
            105.83192815174921,
            21.02783183238476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83192815174921,
              21.02783183238476
            ],
            [
              105.83189473968677,
              21.027707141412844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N52_Ngõ 10, Bích Câu",
          "maTaiSan": "04.O13.DODV.3611",
          "diaChiLapD": "Ngõ 10, Bích Câu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02783183238476,
          "to_latitude": 21.027707141412844,
          "to_longitude": 105.83189473968677,
          "from_longitude": 105.83192815174921
        }
      },
      {
        "id": 3605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163962609464,
            21.023801858584534,
            105.8317621825759,
            21.024100281567275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317621825759,
              21.024100281567275
            ],
            [
              105.83169801493341,
              21.023938670757747
            ],
            [
              105.83168395256412,
              21.023905722163683
            ],
            [
              105.83168326152322,
              21.02390410154581
            ],
            [
              105.83163962609464,
              21.023801858584534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O8_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3612",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024100281567275,
          "to_latitude": 21.023801858584534,
          "to_longitude": 105.83163962609464,
          "from_longitude": 105.8317621825759
        }
      },
      {
        "id": 3606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153354992183,
            21.023905722163683,
            105.83168395256412,
            21.02396586083571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168395256412,
              21.023905722163683
            ],
            [
              105.83167845798492,
              21.02390768554547
            ],
            [
              105.83167641491823,
              21.023908415405927
            ],
            [
              105.83158152469078,
              21.023942325793058
            ],
            [
              105.83153354992183,
              21.02396586083571
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O8_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3613",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023905722163683,
          "to_latitude": 21.02396586083571,
          "to_longitude": 105.83153354992183,
          "from_longitude": 105.83168395256412
        }
      },
      {
        "id": 3607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118707883436,
            21.023820007354256,
            105.83161782745509,
            21.0241176204098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161782745509,
              21.02401397719257
            ],
            [
              105.83148665501591,
              21.024054364924922
            ],
            [
              105.83142891945214,
              21.02407627037191
            ],
            [
              105.83140404990027,
              21.024080406780396
            ],
            [
              105.83126567270205,
              21.0241176204098
            ],
            [
              105.83123622339383,
              21.023967210406767
            ],
            [
              105.83119363354962,
              21.02383956444897
            ],
            [
              105.83118707883436,
              21.023820007354256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q7_Ngách 34, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3614",
          "diaChiLapD": "Ngách 34, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02401397719257,
          "to_latitude": 21.023820007354256,
          "to_longitude": 105.83118707883436,
          "from_longitude": 105.83161782745509
        }
      },
      {
        "id": 3608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83198036579378,
            21.024634682669898,
            105.83206379094037,
            21.024867414860925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198036579378,
              21.024634682669898
            ],
            [
              105.8320189774842,
              21.02464198150492
            ],
            [
              105.83206379094037,
              21.024781668960713
            ],
            [
              105.83203256019218,
              21.024795443276968
            ],
            [
              105.83204899513314,
              21.024867414860925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O2_Ngách 162A/2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3615",
          "diaChiLapD": "Ngách 162A/2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024634682669898,
          "to_latitude": 21.024867414860925,
          "to_longitude": 105.83204899513314,
          "from_longitude": 105.83198036579378
        }
      },
      {
        "id": 3609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83195416129303,
            21.024437287192054,
            105.8321190894239,
            21.024482516436986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321190894239,
              21.024437287192054
            ],
            [
              105.83202472171959,
              21.024464679415836
            ],
            [
              105.83200929790561,
              21.024469156283
            ],
            [
              105.8319989665131,
              21.024471659078717
            ],
            [
              105.83195416129303,
              21.024482516436986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O21_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3616",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024437287192054,
          "to_latitude": 21.024482516436986,
          "to_longitude": 105.83195416129303,
          "from_longitude": 105.8321190894239
        }
      },
      {
        "id": 3610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318374931166,
            21.024312471187145,
            105.83184609017547,
            21.024315120844523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184609017547,
              21.024312471187145
            ],
            [
              105.83184413874142,
              21.024313072343695
            ],
            [
              105.83184197699244,
              21.024313738657824
            ],
            [
              105.8318374931166,
              21.024315120844523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O4_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3617",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024312471187145,
          "to_latitude": 21.024315120844523,
          "to_longitude": 105.8318374931166,
          "from_longitude": 105.83184609017547
        }
      },
      {
        "id": 3611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177592810274,
            21.02411391354388,
            105.83194500078206,
            21.024475855712407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83177592810274,
              21.02411391354388
            ],
            [
              105.83183217729787,
              21.024241459108186
            ],
            [
              105.83183428758647,
              21.024246429164663
            ],
            [
              105.8318574822178,
              21.024301050197128
            ],
            [
              105.83190046973884,
              21.024402283743274
            ],
            [
              105.83192918636225,
              21.024423116138188
            ],
            [
              105.83194500078206,
              21.024475855712407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O5_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3618",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02411391354388,
          "to_latitude": 21.024475855712407,
          "to_longitude": 105.83194500078206,
          "from_longitude": 105.83177592810274
        }
      },
      {
        "id": 3612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83129416743695,
            21.024082122837314,
            105.83174839462298,
            21.02421932020732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83174839462298,
              21.024082122837314
            ],
            [
              105.83174727393359,
              21.024082475156153
            ],
            [
              105.83145336003358,
              21.02417466736284
            ],
            [
              105.83129830747053,
              21.02421932020732
            ],
            [
              105.83129416743695,
              21.02420429453371
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O7_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3619",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024082122837314,
          "to_latitude": 21.02420429453371,
          "to_longitude": 105.83129416743695,
          "from_longitude": 105.83174839462298
        }
      },
      {
        "id": 3613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135575438936,
            21.023809446886997,
            105.8316375296826,
            21.023898125628598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316375296826,
              21.023809446886997
            ],
            [
              105.83160359890631,
              21.0238202943291
            ],
            [
              105.83153505415092,
              21.023839876279272
            ],
            [
              105.83142237711229,
              21.0238733343156
            ],
            [
              105.83135575438936,
              21.023898125628598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O9_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3620",
          "diaChiLapD": "Ngõ 162B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023809446886997,
          "to_latitude": 21.023898125628598,
          "to_longitude": 105.83135575438936,
          "from_longitude": 105.8316375296826
        }
      },
      {
        "id": 3614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142237711229,
            21.0238733343156,
            105.83148637798725,
            21.02404517597268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83142237711229,
              21.0238733343156
            ],
            [
              105.83148637798725,
              21.02404517597268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O9_Ngách 162B/30, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3621",
          "diaChiLapD": "Ngách 162B/30, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0238733343156,
          "to_latitude": 21.02404517597268,
          "to_longitude": 105.83148637798725,
          "from_longitude": 105.83142237711229
        }
      },
      {
        "id": 3615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8317023913371,
            21.02440436263241,
            105.83188871994243,
            21.024454288303332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188871994243,
              21.02440436263241
            ],
            [
              105.83177001452846,
              21.02443917361736
            ],
            [
              105.8317023913371,
              21.024454288303332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O4_Ngách 162A/5, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3622",
          "diaChiLapD": "Ngách 162A/5, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02440436263241,
          "to_latitude": 21.024454288303332,
          "to_longitude": 105.8317023913371,
          "from_longitude": 105.83188871994243
        }
      },
      {
        "id": 3616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141313102456,
            21.024297931041605,
            105.83197487656405,
            21.024748767109166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83194880926044,
              21.02449630233424
            ],
            [
              105.83195020897176,
              21.024503238570905
            ],
            [
              105.83197487656405,
              21.0246272127631
            ],
            [
              105.83171506202876,
              21.024715837987042
            ],
            [
              105.83159827180364,
              21.024748767109166
            ],
            [
              105.8315679668034,
              21.024711439617555
            ],
            [
              105.83150353142486,
              21.02453938155917
            ],
            [
              105.83149060605442,
              21.024504861202033
            ],
            [
              105.83146357383994,
              21.02443266858822
            ],
            [
              105.83142400592932,
              21.02432699782719
            ],
            [
              105.83141313102456,
              21.024297931041605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O3_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3623",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02449630233424,
          "to_latitude": 21.024297931041605,
          "to_longitude": 105.83141313102456,
          "from_longitude": 105.83194880926044
        }
      },
      {
        "id": 3617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83121259933941,
            21.02424909571434,
            105.83198588508981,
            21.024763385061934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319539480295,
              21.024494532167424
            ],
            [
              105.83198588508981,
              21.024630061871687
            ],
            [
              105.83198036579378,
              21.024634682669898
            ],
            [
              105.8319776971117,
              21.02463691675311
            ],
            [
              105.83175794432437,
              21.024710358858268
            ],
            [
              105.8316049488499,
              21.024761571081388
            ],
            [
              105.83159952940761,
              21.024763385061934
            ],
            [
              105.83155413596862,
              21.02471850994097
            ],
            [
              105.83144827311577,
              21.024681205012616
            ],
            [
              105.83142215488529,
              21.02467200058713
            ],
            [
              105.83139480839814,
              21.02461027064273
            ],
            [
              105.8312868888223,
              21.024366658475994
            ],
            [
              105.83128582598503,
              21.024364259288067
            ],
            [
              105.83126148769,
              21.02436567166567
            ],
            [
              105.83121259933941,
              21.02424909571434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O2_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3624",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 152,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024494532167424,
          "to_latitude": 21.02424909571434,
          "to_longitude": 105.83121259933941,
          "from_longitude": 105.8319539480295
        }
      },
      {
        "id": 3618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83175103817908,
            21.02410570664087,
            105.83193418361358,
            21.024497954521348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83175103817908,
              21.02410570664087
            ],
            [
              105.83178475561954,
              21.0241812427657
            ],
            [
              105.8318374931166,
              21.024315120844523
            ],
            [
              105.83185403672005,
              21.024357117527508
            ],
            [
              105.83188871994243,
              21.02440436263241
            ],
            [
              105.83191508670402,
              21.024422299948633
            ],
            [
              105.83193418361358,
              21.024497954521348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O4_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3625",
          "diaChiLapD": "Ngách 162B/130, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02410570664087,
          "to_latitude": 21.024497954521348,
          "to_longitude": 105.83193418361358,
          "from_longitude": 105.83175103817908
        }
      },
      {
        "id": 3619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128301547019,
            21.022349899377286,
            105.83128782786196,
            21.02235798319948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128301547019,
              21.022349899377286
            ],
            [
              105.83128400494023,
              21.02235156099039
            ],
            [
              105.83128614800286,
              21.02235516341402
            ],
            [
              105.83128782786196,
              21.02235798319948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L2_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3626",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022349899377286,
          "to_latitude": 21.02235798319948,
          "to_longitude": 105.83128782786196,
          "from_longitude": 105.83128301547019
        }
      },
      {
        "id": 3620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83115551175794,
            21.022098010647152,
            105.83152049852032,
            21.022267822363062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83152049852032,
              21.022098010647152
            ],
            [
              105.8315136427369,
              21.02209944145187
            ],
            [
              105.83148126246232,
              21.022106194190286
            ],
            [
              105.83147819248937,
              21.022106834229394
            ],
            [
              105.83126726135414,
              21.02220036717764
            ],
            [
              105.83115551175794,
              21.022267822363062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ8'_Ngõ 204, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3627",
          "diaChiLapD": "Ngõ 204, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022098010647152,
          "to_latitude": 21.022267822363062,
          "to_longitude": 105.83115551175794,
          "from_longitude": 105.83152049852032
        }
      },
      {
        "id": 3621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83086107585483,
            21.021937304228953,
            105.83093243671597,
            21.02203076723421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83093243671597,
              21.02203076723421
            ],
            [
              105.83086107585483,
              21.021937304228953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ7'_Ngõ 204, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3628",
          "diaChiLapD": "Ngõ 204, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02203076723421,
          "to_latitude": 21.021937304228953,
          "to_longitude": 105.83086107585483,
          "from_longitude": 105.83093243671597
        }
      },
      {
        "id": 3622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83094512407354,
            21.02183932781825,
            105.83100178006379,
            21.022004628579523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83097944515163,
              21.022004628579523
            ],
            [
              105.83096655589114,
              21.021963019480236
            ],
            [
              105.83098667308452,
              21.021939989227945
            ],
            [
              105.83094512407354,
              21.021882836554166
            ],
            [
              105.83100178006379,
              21.02183932781825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ7'_Ngõ 204, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3629",
          "diaChiLapD": "Ngõ 204, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022004628579523,
          "to_latitude": 21.02183932781825,
          "to_longitude": 105.83100178006379,
          "from_longitude": 105.83097944515163
        }
      },
      {
        "id": 3623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109192471123,
            21.022202879628278,
            105.83115551175794,
            21.022267822363062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83115551175794,
              21.022267822363062
            ],
            [
              105.83109192471123,
              21.022202879628278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ8'_Ngõ 204, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3630",
          "diaChiLapD": "Ngõ 204, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022267822363062,
          "to_latitude": 21.022202879628278,
          "to_longitude": 105.83109192471123,
          "from_longitude": 105.83115551175794
        }
      },
      {
        "id": 3624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294422072449,
            21.02261143406845,
            105.8295596640842,
            21.02265147512093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8295596640842,
              21.02261143406845
            ],
            [
              105.8294422072449,
              21.02265147512093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U8_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3631",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02261143406845,
          "to_latitude": 21.02265147512093,
          "to_longitude": 105.8294422072449,
          "from_longitude": 105.8295596640842
        }
      },
      {
        "id": 3625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83176060442823,
            21.024025880668177,
            105.83199828300393,
            21.024113546809993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176060442823,
              21.024113546809993
            ],
            [
              105.83176168158388,
              21.024113150445988
            ],
            [
              105.83199828300393,
              21.024025880668177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O6_Ngách 162B/10, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3632",
          "diaChiLapD": "Ngách 162B/10, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024113546809993,
          "to_latitude": 21.024025880668177,
          "to_longitude": 105.83199828300393,
          "from_longitude": 105.83176060442823
        }
      },
      {
        "id": 3626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142475256578,
            21.02374675117557,
            105.8314299729306,
            21.023760218922952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8314299729306,
              21.023760218922952
            ],
            [
              105.83142861637826,
              21.023756722850816
            ],
            [
              105.83142646535826,
              21.023751169504674
            ],
            [
              105.83142475256578,
              21.02374675117557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q61_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3633",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023760218922952,
          "to_latitude": 21.02374675117557,
          "to_longitude": 105.83142475256578,
          "from_longitude": 105.8314299729306
        }
      },
      {
        "id": 3627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8317402132978,
            21.023619367017066,
            105.83174554262733,
            21.023630623128465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317402132978,
              21.023619367017066
            ],
            [
              105.83174325191301,
              21.02362572801693
            ],
            [
              105.83174554262733,
              21.023630623128465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q3_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3634",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023619367017066,
          "to_latitude": 21.023630623128465,
          "to_longitude": 105.83174554262733,
          "from_longitude": 105.8317402132978
        }
      },
      {
        "id": 3628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83172148619977,
            21.023753924300863,
            105.83172451344417,
            21.02376170341856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83172451344417,
              21.02376170341856
            ],
            [
              105.83172334138666,
              21.02375869147815
            ],
            [
              105.83172187853565,
              21.02375493670304
            ],
            [
              105.83172148619977,
              21.023753924300863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q2_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3635",
          "diaChiLapD": "Ngõ 162B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02376170341856,
          "to_latitude": 21.023753924300863,
          "to_longitude": 105.83172148619977,
          "from_longitude": 105.83172451344417
        }
      },
      {
        "id": 3629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83178908390201,
            21.02386274173902,
            105.83194130812205,
            21.023971861923627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83194130812205,
              21.02386274173902
            ],
            [
              105.83178908390201,
              21.02391165975719
            ],
            [
              105.83179323603598,
              21.023971861923627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q1_Ngách 162B/10, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3636",
          "diaChiLapD": "Ngách 162B/10, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02386274173902,
          "to_latitude": 21.023971861923627,
          "to_longitude": 105.83179323603598,
          "from_longitude": 105.83194130812205
        }
      },
      {
        "id": 3630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83170543351491,
            21.023613461110813,
            105.8317150166849,
            21.023631988150424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317150166849,
              21.023631988150424
            ],
            [
              105.83171088944488,
              21.023623955186228
            ],
            [
              105.83170543351491,
              21.023613461110813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q5_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3637",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023631988150424,
          "to_latitude": 21.023613461110813,
          "to_longitude": 105.83170543351491,
          "from_longitude": 105.8317150166849
        }
      },
      {
        "id": 3631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208655407286,
            21.023620186210618,
            105.83217717960655,
            21.023864804086923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209863258932,
              21.023620186210618
            ],
            [
              105.83209896067267,
              21.02362175440909
            ],
            [
              105.83210850703387,
              21.02366726723388
            ],
            [
              105.83213544886561,
              21.023731640074008
            ],
            [
              105.83217717960655,
              21.023836955955804
            ],
            [
              105.83208655407286,
              21.023864804086923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q11_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3638",
          "diaChiLapD": "Ngõ 162B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023620186210618,
          "to_latitude": 21.023864804086923,
          "to_longitude": 105.83208655407286,
          "from_longitude": 105.83209863258932
        }
      },
      {
        "id": 3632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190951754095,
            21.023692195096263,
            105.83198782969879,
            21.024019063688815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198782969879,
              21.024019063688815
            ],
            [
              105.83194130812205,
              21.02386274173902
            ],
            [
              105.83190994886424,
              21.02369451246625
            ],
            [
              105.83190951754095,
              21.023692195096263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q1_Ngách 162B/10, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3639",
          "diaChiLapD": "Ngách 162B/10, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024019063688815,
          "to_latitude": 21.023692195096263,
          "to_longitude": 105.83190951754095,
          "from_longitude": 105.83198782969879
        }
      },
      {
        "id": 3633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83122551028299,
            21.023694486385676,
            105.83159798437926,
            21.023826072550467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83122551028299,
              21.023826072550467
            ],
            [
              105.8313823796468,
              21.023774303428276
            ],
            [
              105.8314299729306,
              21.023760218922952
            ],
            [
              105.83148041574657,
              21.0237452904104
            ],
            [
              105.83159798437926,
              21.023694486385676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q61_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3640",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023826072550467,
          "to_latitude": 21.023694486385676,
          "to_longitude": 105.83159798437926,
          "from_longitude": 105.83122551028299
        }
      },
      {
        "id": 3634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83205118061171,
            21.023731640074008,
            105.83213544886561,
            21.023766667518938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83213544886561,
              21.023731640074008
            ],
            [
              105.83208060439375,
              21.023748361536324
            ],
            [
              105.83208219665576,
              21.023757232439895
            ],
            [
              105.83205118061171,
              21.023766667518938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q11_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3641",
          "diaChiLapD": "Ngõ 162B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023731640074008,
          "to_latitude": 21.023766667518938,
          "to_longitude": 105.83205118061171,
          "from_longitude": 105.83213544886561
        }
      },
      {
        "id": 3635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82967303212341,
            21.023308748508203,
            105.82985559382048,
            21.023369475999658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82985559382048,
              21.023308748508203
            ],
            [
              105.82974360172399,
              21.023358377037674
            ],
            [
              105.82967303212341,
              21.023369475999658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_S3_Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3642",
          "diaChiLapD": "Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023308748508203,
          "to_latitude": 21.023369475999658,
          "to_longitude": 105.82967303212341,
          "from_longitude": 105.82985559382048
        }
      },
      {
        "id": 3636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299045414254,
            21.023517546502436,
            105.83000801729469,
            21.023563149455093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299045414254,
              21.023563149455093
            ],
            [
              105.83000801729469,
              21.023517546502436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S2_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3643",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023563149455093,
          "to_latitude": 21.023517546502436,
          "to_longitude": 105.83000801729469,
          "from_longitude": 105.8299045414254
        }
      },
      {
        "id": 3637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83125223012486,
            21.023569261286077,
            105.83142823072767,
            21.023739221152752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83142823072767,
              21.023739221152752
            ],
            [
              105.83142791095611,
              21.02373863020085
            ],
            [
              105.83137358574889,
              21.023638225997992
            ],
            [
              105.83131935206157,
              21.02364366657197
            ],
            [
              105.8312763724976,
              21.023569261286077
            ],
            [
              105.83125223012486,
              21.023577532690013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q6_Ngách 25, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3644",
          "diaChiLapD": "Ngách 25, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023739221152752,
          "to_latitude": 21.023577532690013,
          "to_longitude": 105.83125223012486,
          "from_longitude": 105.83142823072767
        }
      },
      {
        "id": 3638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83113829207484,
            21.023637130978525,
            105.83168327458021,
            21.02382201609653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168327458021,
              21.023637130978525
            ],
            [
              105.83142823072767,
              21.023739221152752
            ],
            [
              105.83142791493296,
              21.023739347341493
            ],
            [
              105.83131381503551,
              21.023771915358832
            ],
            [
              105.83113829207484,
              21.02382201609653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q6_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3645",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023637130978525,
          "to_latitude": 21.02382201609653,
          "to_longitude": 105.83113829207484,
          "from_longitude": 105.83168327458021
        }
      },
      {
        "id": 3639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83049239818324,
            21.022157470228585,
            105.83061779533642,
            21.022206920819045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83049239818324,
              21.022206920819045
            ],
            [
              105.83061779533642,
              21.022157470228585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3646",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022206920819045,
          "to_latitude": 21.022157470228585,
          "to_longitude": 105.83061779533642,
          "from_longitude": 105.83049239818324
        }
      },
      {
        "id": 3640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298582947903,
            21.021911218221433,
            105.8301805408552,
            21.022250204126177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82990008715306,
              21.022250204126177
            ],
            [
              105.8298582947903,
              21.022172141811623
            ],
            [
              105.82986889703433,
              21.022133255935817
            ],
            [
              105.83007954775775,
              21.021966675964464
            ],
            [
              105.8301805408552,
              21.021911218221433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngách 98, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3647",
          "diaChiLapD": "Ngách 98, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022250204126177,
          "to_latitude": 21.021911218221433,
          "to_longitude": 105.8301805408552,
          "from_longitude": 105.82990008715306
        }
      },
      {
        "id": 3641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8304716818974,
            21.02217711850419,
            105.8304806451135,
            21.022180683941535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8304806451135,
              21.02217711850419
            ],
            [
              105.83047913332858,
              21.022177720207598
            ],
            [
              105.83047561254236,
              21.02217912023539
            ],
            [
              105.8304716818974,
              21.022180683941535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3648",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02217711850419,
          "to_latitude": 21.022180683941535,
          "to_longitude": 105.8304716818974,
          "from_longitude": 105.8304806451135
        }
      },
      {
        "id": 3642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82990008715306,
            21.022112116961278,
            105.8301668615736,
            21.022250204126177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8301668615736,
              21.022233188841398
            ],
            [
              105.83009950590802,
              21.022112116961278
            ],
            [
              105.83004806660374,
              21.022141941383666
            ],
            [
              105.82998983448859,
              21.022197788326057
            ],
            [
              105.82990008715306,
              21.022250204126177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngách 98, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3649",
          "diaChiLapD": "Ngách 98, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022233188841398,
          "to_latitude": 21.022250204126177,
          "to_longitude": 105.82990008715306,
          "from_longitude": 105.8301668615736
        }
      },
      {
        "id": 3643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83009950590802,
            21.022067295132704,
            105.83042982782982,
            21.02213501689431
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83042982782982,
              21.0220686012173
            ],
            [
              105.83035856915492,
              21.022095681854957
            ],
            [
              105.83034074915665,
              21.02208746279714
            ],
            [
              105.83022543070018,
              21.02213501689431
            ],
            [
              105.83017486833955,
              21.022067295132704
            ],
            [
              105.83009950590802,
              21.022112116961278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngách 98, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3650",
          "diaChiLapD": "Ngách 98, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0220686012173,
          "to_latitude": 21.022112116961278,
          "to_longitude": 105.83009950590802,
          "from_longitude": 105.83042982782982
        }
      },
      {
        "id": 3644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029064119881,
            21.0222071624117,
            105.83049928568774,
            21.022274697128285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83048682015752,
              21.02221673630029
            ],
            [
              105.83049508953945,
              21.022213994377907
            ],
            [
              105.83049928568774,
              21.022212603697163
            ],
            [
              105.83049693429308,
              21.0222071624117
            ],
            [
              105.83049298487173,
              21.02220846246844
            ],
            [
              105.83048451661583,
              21.02221125052299
            ],
            [
              105.83047091017032,
              21.022215729211425
            ],
            [
              105.83032494461757,
              21.022274697128285
            ],
            [
              105.83029064119881,
              21.02221612066425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T5_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3651",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02221673630029,
          "to_latitude": 21.02221612066425,
          "to_longitude": 105.83029064119881,
          "from_longitude": 105.83048682015752
        }
      },
      {
        "id": 3645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035406739666,
            21.021926192050344,
            105.83053010512873,
            21.022301268185384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83053010512873,
              21.022301268185384
            ],
            [
              105.83050675796603,
              21.02224466600685
            ],
            [
              105.83049508953945,
              21.022213994377907
            ],
            [
              105.83049298487173,
              21.02220846246844
            ],
            [
              105.83049239818324,
              21.022206920819045
            ],
            [
              105.83048501621636,
              21.02218751585712
            ],
            [
              105.8304806451135,
              21.02217711850419
            ],
            [
              105.8304729449894,
              21.022158801674266
            ],
            [
              105.83043717676118,
              21.022085170218244
            ],
            [
              105.83042982782982,
              21.0220686012173
            ],
            [
              105.83035406739666,
              21.021926192050344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3652",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022301268185384,
          "to_latitude": 21.021926192050344,
          "to_longitude": 105.83035406739666,
          "from_longitude": 105.83053010512873
        }
      },
      {
        "id": 3646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83149060605442,
            21.024464066062436,
            105.83165337234837,
            21.024504861202033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83149060605442,
              21.024504861202033
            ],
            [
              105.83165337234837,
              21.024464066062436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O3_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3653",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024504861202033,
          "to_latitude": 21.024464066062436,
          "to_longitude": 105.83165337234837,
          "from_longitude": 105.83149060605442
        }
      },
      {
        "id": 3647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83113431957631,
            21.024366658475994,
            105.8312868888223,
            21.024418545607094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83113431957631,
              21.024418545607094
            ],
            [
              105.8312868888223,
              21.024366658475994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O2_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3654",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024418545607094,
          "to_latitude": 21.024366658475994,
          "to_longitude": 105.8312868888223,
          "from_longitude": 105.83113431957631
        }
      },
      {
        "id": 3648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150353142486,
            21.02451769726593,
            105.83157421970743,
            21.02453938155917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157421970743,
              21.02451769726593
            ],
            [
              105.83150428818853,
              21.024539149343486
            ],
            [
              105.83150353142486,
              21.02453938155917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O3_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3655",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02451769726593,
          "to_latitude": 21.02453938155917,
          "to_longitude": 105.83150353142486,
          "from_longitude": 105.83157421970743
        }
      },
      {
        "id": 3649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83146357383994,
            21.024389719000087,
            105.8315961051679,
            21.02443266858822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83146357383994,
              21.02443266858822
            ],
            [
              105.8315961051679,
              21.024389719000087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O3_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3656",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02443266858822,
          "to_latitude": 21.024389719000087,
          "to_longitude": 105.8315961051679,
          "from_longitude": 105.83146357383994
        }
      },
      {
        "id": 3650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142400592932,
            21.02429457719229,
            105.83155191163232,
            21.02432699782719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83142400592932,
              21.02432699782719
            ],
            [
              105.83155191163232,
              21.02429457719229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O3_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3657",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02432699782719,
          "to_latitude": 21.02429457719229,
          "to_longitude": 105.83155191163232,
          "from_longitude": 105.83142400592932
        }
      },
      {
        "id": 3651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83120862832305,
            21.02461027064273,
            105.83139480839814,
            21.02468047153778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83139480839814,
              21.02461027064273
            ],
            [
              105.83139468840245,
              21.024610316390564
            ],
            [
              105.83120862832305,
              21.02468047153778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O2_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3658",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02461027064273,
          "to_latitude": 21.02468047153778,
          "to_longitude": 105.83120862832305,
          "from_longitude": 105.83139480839814
        }
      },
      {
        "id": 3652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157265601386,
            21.024761571081388,
            105.83161956197186,
            21.024813500889266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316049488499,
              21.024761571081388
            ],
            [
              105.83161956197186,
              21.024795462659505
            ],
            [
              105.83157265601386,
              21.024813500889266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O2_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3659",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024761571081388,
          "to_latitude": 21.024813500889266,
          "to_longitude": 105.83157265601386,
          "from_longitude": 105.8316049488499
        }
      },
      {
        "id": 3653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164417413629,
            21.024531768854516,
            105.83171506202876,
            21.024715837987042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171506202876,
              21.024715837987042
            ],
            [
              105.83164417413629,
              21.024531768854516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O3_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3660",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024715837987042,
          "to_latitude": 21.024531768854516,
          "to_longitude": 105.83164417413629,
          "from_longitude": 105.83171506202876
        }
      },
      {
        "id": 3654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83132694416675,
            21.024681205012616,
            105.83144827311577,
            21.024767223269812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144827311577,
              21.024681205012616
            ],
            [
              105.83144020801275,
              21.024730863965278
            ],
            [
              105.83132694416675,
              21.024767223269812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O2_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3661",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024681205012616,
          "to_latitude": 21.024767223269812,
          "to_longitude": 105.83132694416675,
          "from_longitude": 105.83144827311577
        }
      },
      {
        "id": 3655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82937699776024,
            21.02449030365485,
            105.82949489329806,
            21.024596686872094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937699776024,
              21.024596686872094
            ],
            [
              105.82949489329806,
              21.02449030365485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_R3_Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3662",
          "diaChiLapD": "Ngách 76, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024596686872094,
          "to_latitude": 21.02449030365485,
          "to_longitude": 105.82949489329806,
          "from_longitude": 105.82937699776024
        }
      },
      {
        "id": 3656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83320428179528,
            21.02738372015871,
            105.83339469237934,
            21.027457023110394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339469237934,
              21.02738372015871
            ],
            [
              105.83320428179528,
              21.027457023110394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N3_Ngõ 5, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3663",
          "diaChiLapD": "Ngõ 5, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02738372015871,
          "to_latitude": 21.027457023110394,
          "to_longitude": 105.83320428179528,
          "from_longitude": 105.83339469237934
        }
      },
      {
        "id": 3657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194294857154,
            21.025736286917073,
            105.83206675482406,
            21.02602946112634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83194294857154,
              21.025736286917073
            ],
            [
              105.83206675482406,
              21.02602946112634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N9_Phan Văn Trị",
          "maTaiSan": "04.O13.DODV.3664",
          "diaChiLapD": "Phan Văn Trị",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025736286917073,
          "to_latitude": 21.02602946112634,
          "to_longitude": 105.83206675482406,
          "from_longitude": 105.83194294857154
        }
      },
      {
        "id": 3658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162687881791,
            21.023127609182886,
            105.83179188074848,
            21.023178017845424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83179188074848,
              21.023167309820245
            ],
            [
              105.83177015243726,
              21.023127609182886
            ],
            [
              105.83162687881791,
              21.023178017845424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ10'_Ngõ 172, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3665",
          "diaChiLapD": "Ngõ 172, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023167309820245,
          "to_latitude": 21.023178017845424,
          "to_longitude": 105.83162687881791,
          "from_longitude": 105.83179188074848
        }
      },
      {
        "id": 3659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83138569220863,
            21.026827808437748,
            105.83154428983077,
            21.026893056911828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83154428983077,
              21.026827808437748
            ],
            [
              105.83138569220863,
              21.026893056911828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M6_Ngõ 50, Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DODV.3666",
          "diaChiLapD": "Ngõ 50, Đoàn Thị Điểm",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026827808437748,
          "to_latitude": 21.026893056911828,
          "to_longitude": 105.83138569220863,
          "from_longitude": 105.83154428983077
        }
      },
      {
        "id": 3660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202621242228,
            21.026983147353075,
            105.83207965099405,
            21.02700188787756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83207965099405,
              21.026983147353075
            ],
            [
              105.83205573195418,
              21.026991534305328
            ],
            [
              105.83202621242228,
              21.02700188787756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N6_Bích Câu",
          "maTaiSan": "04.O13.DODV.3667",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026983147353075,
          "to_latitude": 21.02700188787756,
          "to_longitude": 105.83202621242228,
          "from_longitude": 105.83207965099405
        }
      },
      {
        "id": 3661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831596673,
            21.028074954124488,
            105.8325953866804,
            21.028561598605208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325953866804,
              21.028319269198672
            ],
            [
              105.83256668410021,
              21.028330563641386
            ],
            [
              105.83197960070336,
              21.028561598605208
            ],
            [
              105.83193855407353,
              21.028558110664957
            ],
            [
              105.83179934048262,
              21.02855054347041
            ],
            [
              105.8317919920047,
              21.02853605196624
            ],
            [
              105.8317032648327,
              21.028377916002317
            ],
            [
              105.831596673,
              21.028074954124488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_P8_Cát Linh",
          "maTaiSan": "04.O13.DODV.3668",
          "diaChiLapD": "Cát Linh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 150,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028319269198672,
          "to_latitude": 21.028074954124488,
          "to_longitude": 105.831596673,
          "from_longitude": 105.8325953866804
        }
      },
      {
        "id": 3662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338478867099,
            21.027496690772033,
            105.83394336960595,
            21.027534351034358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394336960595,
              21.027496690772033
            ],
            [
              105.8338478867099,
              21.027534351034358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P3_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3669",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027496690772033,
          "to_latitude": 21.027534351034358,
          "to_longitude": 105.8338478867099,
          "from_longitude": 105.83394336960595
        }
      },
      {
        "id": 3663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337625018056,
            21.027390212194298,
            105.83389443227783,
            21.02743931252524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83389443227783,
              21.027390212194298
            ],
            [
              105.8337625018056,
              21.02743931252524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P3_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3670",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027390212194298,
          "to_latitude": 21.02743931252524,
          "to_longitude": 105.8337625018056,
          "from_longitude": 105.83389443227783
        }
      },
      {
        "id": 3664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373040008061,
            21.02733867480192,
            105.83387074560355,
            21.02739480099868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387074560355,
              21.02733867480192
            ],
            [
              105.83373040008061,
              21.02739480099868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P3_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3671",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02733867480192,
          "to_latitude": 21.02739480099868,
          "to_longitude": 105.83373040008061,
          "from_longitude": 105.83387074560355
        }
      },
      {
        "id": 3665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83367692846805,
            21.02725396901605,
            105.8338318141574,
            21.027352233195362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338318141574,
              21.02725396901605
            ],
            [
              105.83369388520906,
              21.02731146797363
            ],
            [
              105.83370073853496,
              21.027343459970904
            ],
            [
              105.83367692846805,
              21.027352233195362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_P3_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3672",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02725396901605,
          "to_latitude": 21.027352233195362,
          "to_longitude": 105.83367692846805,
          "from_longitude": 105.8338318141574
        }
      },
      {
        "id": 3666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83287209313212,
            21.027275762030587,
            105.83299970974645,
            21.02731766443313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299970974645,
              21.027275762030587
            ],
            [
              105.83290958552651,
              21.027305354119843
            ],
            [
              105.83287209313212,
              21.02731766443313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N4_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3673",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027275762030587,
          "to_latitude": 21.02731766443313,
          "to_longitude": 105.83287209313212,
          "from_longitude": 105.83299970974645
        }
      },
      {
        "id": 3667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83286974448225,
            21.027217863398104,
            105.83290958552651,
            21.027305354119843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83290958552651,
              21.027305354119843
            ],
            [
              105.83286974448225,
              21.027217863398104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N4_Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3674",
          "diaChiLapD": "Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027305354119843,
          "to_latitude": 21.027217863398104,
          "to_longitude": 105.83286974448225,
          "from_longitude": 105.83290958552651
        }
      },
      {
        "id": 3668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83289756070472,
            21.026653188422777,
            105.83316519416569,
            21.02674615948805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83289756070472,
              21.02674615948805
            ],
            [
              105.83301008722843,
              21.026710571368884
            ],
            [
              105.83316519416569,
              21.026653188422777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N2_Ngõ 35, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3675",
          "diaChiLapD": "Ngõ 35, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02674615948805,
          "to_latitude": 21.026653188422777,
          "to_longitude": 105.83316519416569,
          "from_longitude": 105.83289756070472
        }
      },
      {
        "id": 3669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83077135734099,
            21.02226868271825,
            105.83087827897121,
            21.022402655748568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83087827897121,
              21.022395280582206
            ],
            [
              105.8308595352411,
              21.022402655748568
            ],
            [
              105.83079269042166,
              21.02226868271825
            ],
            [
              105.83077135734099,
              21.02227715168229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L5_Ngách 27, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3676",
          "diaChiLapD": "Ngách 27, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022395280582206,
          "to_latitude": 21.02227715168229,
          "to_longitude": 105.83077135734099,
          "from_longitude": 105.83087827897121
        }
      },
      {
        "id": 3670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83087329975304,
            21.022204064205038,
            105.83096329112583,
            21.02251126063218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83090108186234,
              21.02251126063218
            ],
            [
              105.83090084242345,
              21.02251075689939
            ],
            [
              105.83089496129408,
              21.022498334680296
            ],
            [
              105.8309203020419,
              21.022485704837102
            ],
            [
              105.83087827897121,
              21.022395280582206
            ],
            [
              105.83089041990407,
              21.022390379085557
            ],
            [
              105.83087329975304,
              21.022351705355337
            ],
            [
              105.83096329112583,
              21.022313348353784
            ],
            [
              105.83091972156117,
              21.022204064205038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L5_Ngách 27, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3677",
          "diaChiLapD": "Ngách 27, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02251126063218,
          "to_latitude": 21.022204064205038,
          "to_longitude": 105.83091972156117,
          "from_longitude": 105.83090108186234
        }
      },
      {
        "id": 3671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029972258342,
            21.02453960152059,
            105.83030685868535,
            21.024540822163207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029972258342,
              21.02453960152059
            ],
            [
              105.83030190549705,
              21.024539975636962
            ],
            [
              105.8303065707766,
              21.024540772988246
            ],
            [
              105.83030685868535,
              21.024540822163207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R42_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3678",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02453960152059,
          "to_latitude": 21.024540822163207,
          "to_longitude": 105.83030685868535,
          "from_longitude": 105.83029972258342
        }
      },
      {
        "id": 3672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83031264000951,
            21.024960568470064,
            105.83033056371809,
            21.02496338823271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031264000951,
              21.02496338823271
            ],
            [
              105.83031328302806,
              21.0249632875451
            ],
            [
              105.83032042156113,
              21.02496216430747
            ],
            [
              105.83033056371809,
              21.024960568470064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R5_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3679",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02496338823271,
          "to_latitude": 21.024960568470064,
          "to_longitude": 105.83033056371809,
          "from_longitude": 105.83031264000951
        }
      },
      {
        "id": 3673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83085232461477,
            21.023635358396152,
            105.83096140774745,
            21.023899385880313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83092672427651,
              21.023899385880313
            ],
            [
              105.83092083701658,
              21.023884479828684
            ],
            [
              105.83088652252559,
              21.023789820656553
            ],
            [
              105.83085232461477,
              21.023696574451883
            ],
            [
              105.83096140774745,
              21.023635358396152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q8_Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3680",
          "diaChiLapD": "Ngách 41, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023899385880313,
          "to_latitude": 21.023635358396152,
          "to_longitude": 105.83096140774745,
          "from_longitude": 105.83092672427651
        }
      },
      {
        "id": 3674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83055004028583,
            21.024046072299445,
            105.83071367159037,
            21.024322032816745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83055004028583,
              21.024046072299445
            ],
            [
              105.8305561274938,
              21.024053142819604
            ],
            [
              105.83055792440925,
              21.02405590604002
            ],
            [
              105.8306100944269,
              21.024136176422154
            ],
            [
              105.83067311380918,
              21.024237274536286
            ],
            [
              105.83071367159037,
              21.024322032816745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q9_Ngách 46, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3681",
          "diaChiLapD": "Ngách 46, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024046072299445,
          "to_latitude": 21.024322032816745,
          "to_longitude": 105.83071367159037,
          "from_longitude": 105.83055004028583
        }
      },
      {
        "id": 3675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83054837333941,
            21.024047494889324,
            105.83055602366265,
            21.02405766938413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83054837333941,
              21.024047494889324
            ],
            [
              105.83055336243719,
              21.024054131805123
            ],
            [
              105.83055602366265,
              21.02405766938413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R1_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3682",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024047494889324,
          "to_latitude": 21.02405766938413,
          "to_longitude": 105.83055602366265,
          "from_longitude": 105.83054837333941
        }
      },
      {
        "id": 3676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83159272224289,
            21.027805848127286,
            105.83239720833076,
            21.027903810152118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83239720833076,
              21.027805848127286
            ],
            [
              105.83238170197046,
              21.027811457179318
            ],
            [
              105.83232765582713,
              21.027831011873012
            ],
            [
              105.83232706825325,
              21.027831224296865
            ],
            [
              105.83212643884508,
              21.027903810152118
            ],
            [
              105.83210466065741,
              21.02783222333743
            ],
            [
              105.83196312780562,
              21.02785298999401
            ],
            [
              105.83192815174921,
              21.02783183238476
            ],
            [
              105.83177618893791,
              21.02785264268673
            ],
            [
              105.83177134980171,
              21.02783388291472
            ],
            [
              105.83160784602984,
              21.027847129921486
            ],
            [
              105.83159272224289,
              21.02784835458954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N52_Ngõ 10, Bích Câu",
          "maTaiSan": "04.O13.DODV.3683",
          "diaChiLapD": "Ngõ 10, Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027805848127286,
          "to_latitude": 21.02784835458954,
          "to_longitude": 105.83159272224289,
          "from_longitude": 105.83239720833076
        }
      },
      {
        "id": 3677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240146908972,
            21.027963782752337,
            105.83245816999514,
            21.027987402328048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83245816999514,
              21.027963782752337
            ],
            [
              105.83243186390644,
              21.027974740278697
            ],
            [
              105.83240146908972,
              21.027987402328048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N5_Bích Câu",
          "maTaiSan": "04.O13.DODV.3684",
          "diaChiLapD": "Bích Câu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027963782752337,
          "to_latitude": 21.027987402328048,
          "to_longitude": 105.83240146908972,
          "from_longitude": 105.83245816999514
        }
      },
      {
        "id": 3678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8308586292933,
            21.023026105456267,
            105.83092538021194,
            21.0230884294612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83092538021194,
              21.023081782886607
            ],
            [
              105.83089806973223,
              21.0230884294612
            ],
            [
              105.8308586292933,
              21.023026105456267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_J6_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3685",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023081782886607,
          "to_latitude": 21.023026105456267,
          "to_longitude": 105.8308586292933,
          "from_longitude": 105.83092538021194
        }
      },
      {
        "id": 3679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154386450853,
            21.022242420450645,
            105.8317308072454,
            21.022652095023776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317308072454,
              21.022652095023776
            ],
            [
              105.83163747124559,
              21.02245026800551
            ],
            [
              105.83162221888809,
              21.02241728637625
            ],
            [
              105.83159865150682,
              21.022366325170466
            ],
            [
              105.83154386450853,
              21.022242420450645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ8_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3686",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022652095023776,
          "to_latitude": 21.022242420450645,
          "to_longitude": 105.83154386450853,
          "from_longitude": 105.8317308072454
        }
      },
      {
        "id": 3680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83258312422318,
            21.024868759279954,
            105.83286945093676,
            21.025110720791698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83258312422318,
              21.024957122959293
            ],
            [
              105.83278612360841,
              21.024868759279954
            ],
            [
              105.83282036436982,
              21.024944341384593
            ],
            [
              105.83286945093676,
              21.025052692901273
            ],
            [
              105.83274995272924,
              21.02509497812026
            ],
            [
              105.83267092258774,
              21.025110720791698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ13_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3687",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024957122959293,
          "to_latitude": 21.025110720791698,
          "to_longitude": 105.83267092258774,
          "from_longitude": 105.83258312422318
        }
      },
      {
        "id": 3681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163988495328,
            21.02369427411563,
            105.83189404778973,
            21.02378782573017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189404778973,
              21.02369427411563
            ],
            [
              105.83175402936409,
              21.023740403755664
            ],
            [
              105.83172148619977,
              21.023753924300863
            ],
            [
              105.83163988495328,
              21.02378782573017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q2_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3688",
          "diaChiLapD": "Ngõ 162B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02369427411563,
          "to_latitude": 21.02378782573017,
          "to_longitude": 105.83163988495328,
          "from_longitude": 105.83189404778973
        }
      },
      {
        "id": 3682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189404778973,
            21.02358636769251,
            105.8321689672904,
            21.02369427411563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189404778973,
              21.02369427411563
            ],
            [
              105.8321689672904,
              21.02358636769251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q2_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3689",
          "diaChiLapD": "Ngõ 162B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02369427411563,
          "to_latitude": 21.02358636769251,
          "to_longitude": 105.8321689672904,
          "from_longitude": 105.83189404778973
        }
      },
      {
        "id": 3683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034146873094,
            21.022905550850407,
            105.830504363826,
            21.022936397371822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830504363826,
              21.022905550850407
            ],
            [
              105.83038726359788,
              21.022920866846967
            ],
            [
              105.83034146873094,
              21.022936397371822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L9_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3690",
          "diaChiLapD": "Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022905550850407,
          "to_latitude": 21.022936397371822,
          "to_longitude": 105.83034146873094,
          "from_longitude": 105.830504363826
        }
      },
      {
        "id": 3684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302164353703,
            21.021926192050344,
            105.83035406739666,
            21.02199222575711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83035406739666,
              21.021926192050344
            ],
            [
              105.8302164353703,
              21.02199222575711
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T6_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3691",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021926192050344,
          "to_latitude": 21.02199222575711,
          "to_longitude": 105.8302164353703,
          "from_longitude": 105.83035406739666
        }
      },
      {
        "id": 3685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052238033927,
            21.022702296380245,
            105.83058612821428,
            21.02274464284381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83058612821428,
              21.022702296380245
            ],
            [
              105.83052238033927,
              21.02274464284381
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_U1_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3692",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022702296380245,
          "to_latitude": 21.02274464284381,
          "to_longitude": 105.83052238033927,
          "from_longitude": 105.83058612821428
        }
      },
      {
        "id": 3686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8300197697854,
            21.022921071554887,
            105.83024320278896,
            21.02298473477797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83024320278896,
              21.022921071554887
            ],
            [
              105.8300197697854,
              21.02298473477797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_U5_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3693",
          "diaChiLapD": "Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022921071554887,
          "to_latitude": 21.02298473477797,
          "to_longitude": 105.8300197697854,
          "from_longitude": 105.83024320278896
        }
      },
      {
        "id": 3687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83291276824079,
            21.026691947469025,
            105.83316207814853,
            21.02678080763333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83291276824079,
              21.02678080763333
            ],
            [
              105.83316207814853,
              21.026691947469025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_N2_Ngõ 33, Đặng Trần Côn",
          "maTaiSan": "04.O13.DODV.3694",
          "diaChiLapD": "Ngõ 33, Đặng Trần Côn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02678080763333,
          "to_latitude": 21.026691947469025,
          "to_longitude": 105.83316207814853,
          "from_longitude": 105.83291276824079
        }
      },
      {
        "id": 3688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83077131316969,
            21.02199832376647,
            105.83126067674003,
            21.022196921041346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83126067674003,
              21.022196921041346
            ],
            [
              105.83117516289224,
              21.022080364154636
            ],
            [
              105.83109080760761,
              21.022129767256825
            ],
            [
              105.83097944515163,
              21.022004628579523
            ],
            [
              105.83093243671597,
              21.02203076723421
            ],
            [
              105.83083249986785,
              21.022085491355753
            ],
            [
              105.83082255630104,
              21.022069071434085
            ],
            [
              105.83077131316969,
              21.02199832376647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ7'_Ngõ 204, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3695",
          "diaChiLapD": "Ngõ 204, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022196921041346,
          "to_latitude": 21.02199832376647,
          "to_longitude": 105.83077131316969,
          "from_longitude": 105.83126067674003
        }
      },
      {
        "id": 3689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83126067674003,
            21.022086328681148,
            105.83151617986822,
            21.022196921041346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83151617986822,
              21.022086328681148
            ],
            [
              105.83150931071194,
              21.02208847038867
            ],
            [
              105.83147747133143,
              21.022098392606278
            ],
            [
              105.83147524627356,
              21.022099086321266
            ],
            [
              105.83126067674003,
              21.022196921041346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ7'_Ngõ 204, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3696",
          "diaChiLapD": "Ngõ 204, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022086328681148,
          "to_latitude": 21.022196921041346,
          "to_longitude": 105.83126067674003,
          "from_longitude": 105.83151617986822
        }
      },
      {
        "id": 3690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124460179026,
            21.022678035386733,
            105.83133586881115,
            21.022730457509965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124460179026,
              21.022730457509965
            ],
            [
              105.83133586881115,
              21.022678035386733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_J1_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3697",
          "diaChiLapD": "Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022730457509965,
          "to_latitude": 21.022678035386733,
          "to_longitude": 105.83133586881115,
          "from_longitude": 105.83124460179026
        }
      },
      {
        "id": 3691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83230345350202,
            21.024390140871233,
            105.83230699061069,
            21.02439739361162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83230699061069,
              21.02439739361162
            ],
            [
              105.83230534473037,
              21.024393944124608
            ],
            [
              105.83230345350202,
              21.024390140871233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_O1_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3698",
          "diaChiLapD": "Ngõ 162A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02439739361162,
          "to_latitude": 21.024390140871233,
          "to_longitude": 105.83230345350202,
          "from_longitude": 105.83230699061069
        }
      },
      {
        "id": 3692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971423187752,
            21.023038619831922,
            105.82997466137773,
            21.02340104295933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82971423187752,
              21.023038619831922
            ],
            [
              105.82975582856706,
              21.023118142735022
            ],
            [
              105.8298231604024,
              21.023246864257843
            ],
            [
              105.82985559382048,
              21.023308748508203
            ],
            [
              105.82985972104059,
              21.02331662345657
            ],
            [
              105.82991159039173,
              21.023348251481817
            ],
            [
              105.82993791397898,
              21.02340104295933
            ],
            [
              105.82996139812232,
              21.023388376249056
            ],
            [
              105.82997466137773,
              21.02338061695523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S3_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3699",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023038619831922,
          "to_latitude": 21.02338061695523,
          "to_longitude": 105.82997466137773,
          "from_longitude": 105.82971423187752
        }
      },
      {
        "id": 3693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8313708118597,
            21.022229821902588,
            105.83152195578107,
            21.0222696397523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83152195578107,
              21.022229821902588
            ],
            [
              105.83143675952196,
              21.0222696397523
            ],
            [
              105.83141159081366,
              21.02223278206576
            ],
            [
              105.8313708118597,
              21.022251057627383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L1_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3700",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022229821902588,
          "to_latitude": 21.022251057627383,
          "to_longitude": 105.8313708118597,
          "from_longitude": 105.83152195578107
        }
      },
      {
        "id": 3694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143675952196,
            21.0222696397523,
            105.83144308961106,
            21.02227801421503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144308961106,
              21.02227801421503
            ],
            [
              105.83144236377883,
              21.02227705673238
            ],
            [
              105.83143675952196,
              21.0222696397523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_L1_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3701",
          "diaChiLapD": "Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02227801421503,
          "to_latitude": 21.0222696397523,
          "to_longitude": 105.83143675952196,
          "from_longitude": 105.83144308961106
        }
      },
      {
        "id": 3695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83036480075396,
            21.025216502437388,
            105.83037546938978,
            21.025251946158367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83036480075396,
              21.025216502437388
            ],
            [
              105.83037546938978,
              21.025251946158367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_R71_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.3702",
          "diaChiLapD": "Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025216502437388,
          "to_latitude": 21.025251946158367,
          "to_longitude": 105.83037546938978,
          "from_longitude": 105.83036480075396
        }
      },
      {
        "id": 3696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83038224101372,
            21.018563839843733,
            105.83038776565655,
            21.01856959622499
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83038224101372,
              21.018563839843733
            ],
            [
              105.83038432799952,
              21.018566015460678
            ],
            [
              105.83038714449661,
              21.018568948935194
            ],
            [
              105.83038776565655,
              21.01856959622499
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3703",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018563839843733,
          "to_latitude": 21.01856959622499,
          "to_longitude": 105.83038776565655,
          "from_longitude": 105.83038224101372
        }
      },
      {
        "id": 3697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83031542133037,
            21.018257151932488,
            105.8304626582238,
            21.018300557978566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8304626582238,
              21.018257151932488
            ],
            [
              105.83031542133037,
              21.018300557978566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3704",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018257151932488,
          "to_latitude": 21.018300557978566,
          "to_longitude": 105.83031542133037,
          "from_longitude": 105.8304626582238
        }
      },
      {
        "id": 3698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83036316600274,
            21.018198461671716,
            105.83045036556702,
            21.018214552874028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83045036556702,
              21.018198461671716
            ],
            [
              105.83036316600274,
              21.018214552874028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3705",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018198461671716,
          "to_latitude": 21.018214552874028,
          "to_longitude": 105.83036316600274,
          "from_longitude": 105.83045036556702
        }
      },
      {
        "id": 3699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029576304524,
            21.01795291047617,
            105.83052612413236,
            21.0183846556514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052612413236,
              21.0183846556514
            ],
            [
              105.83048382803756,
              21.018298512030196
            ],
            [
              105.83045535836516,
              21.01818843227429
            ],
            [
              105.83045534002518,
              21.018188247202687
            ],
            [
              105.83043046338874,
              21.01805907468991
            ],
            [
              105.830387785837,
              21.018007903967284
            ],
            [
              105.83035815272966,
              21.017992586305418
            ],
            [
              105.83034156122629,
              21.017982033622463
            ],
            [
              105.83029576304524,
              21.01795291047617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3706",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0183846556514,
          "to_latitude": 21.01795291047617,
          "to_longitude": 105.83029576304524,
          "from_longitude": 105.83052612413236
        }
      },
      {
        "id": 3700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83045535836516,
            21.018155608872302,
            105.83053254942477,
            21.01818843227429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83045535836516,
              21.01818843227429
            ],
            [
              105.83053254942477,
              21.018155608872302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3707",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01818843227429,
          "to_latitude": 21.018155608872302,
          "to_longitude": 105.83053254942477,
          "from_longitude": 105.83045535836516
        }
      },
      {
        "id": 3701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83076298004639,
            21.018282628736145,
            105.8307664456024,
            21.018286730517413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307664456024,
              21.018286730517413
            ],
            [
              105.83076630230143,
              21.01828656231418
            ],
            [
              105.83076387572882,
              21.01828368926419
            ],
            [
              105.83076298004639,
              21.018282628736145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3708",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018286730517413,
          "to_latitude": 21.018282628736145,
          "to_longitude": 105.83076298004639,
          "from_longitude": 105.8307664456024
        }
      },
      {
        "id": 3702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83063689723724,
            21.018166921033696,
            105.83068549632193,
            21.01819692773652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068549632193,
              21.018166921033696
            ],
            [
              105.83063689723724,
              21.01819692773652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3709",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018166921033696,
          "to_latitude": 21.01819692773652,
          "to_longitude": 105.83063689723724,
          "from_longitude": 105.83068549632193
        }
      },
      {
        "id": 3703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83113147371063,
            21.01751864384829,
            105.8311522489404,
            21.01753756313948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311522489404,
              21.01751864384829
            ],
            [
              105.83115116356512,
              21.01751963185997
            ],
            [
              105.83114787873664,
              21.017522624035163
            ],
            [
              105.83113147371063,
              21.01753756313948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3710",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01751864384829,
          "to_latitude": 21.01753756313948,
          "to_longitude": 105.83113147371063,
          "from_longitude": 105.8311522489404
        }
      },
      {
        "id": 3704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310325407427,
            21.01745157170289,
            105.8313012445759,
            21.017684431662808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310325407427,
              21.01745157170289
            ],
            [
              105.83109379544331,
              21.01750481382935
            ],
            [
              105.83113147371063,
              21.01753756313948
            ],
            [
              105.83113818775492,
              21.017543399492652
            ],
            [
              105.8313012445759,
              21.017684431662808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3711",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01745157170289,
          "to_latitude": 21.017684431662808,
          "to_longitude": 105.8313012445759,
          "from_longitude": 105.8310325407427
        }
      },
      {
        "id": 3705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162776035968,
            21.017370428341977,
            105.83163816075164,
            21.017378720587253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83163816075164,
              21.017378720587253
            ],
            [
              105.83163659259534,
              21.017377473673974
            ],
            [
              105.83163517803075,
              21.01737634252583
            ],
            [
              105.83163211802068,
              21.01737390432611
            ],
            [
              105.83162905992452,
              21.017371464310514
            ],
            [
              105.83162776035968,
              21.017370428341977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3712",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017378720587253,
          "to_latitude": 21.017370428341977,
          "to_longitude": 105.83162776035968,
          "from_longitude": 105.83163816075164
        }
      },
      {
        "id": 3706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133871480013,
            21.016956665668417,
            105.83205221407009,
            21.017678070494778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133871480013,
              21.017678070494778
            ],
            [
              105.83141914294863,
              21.01759520486672
            ],
            [
              105.83141928356035,
              21.017595061469958
            ],
            [
              105.83146653878589,
              21.017546376105905
            ],
            [
              105.83152615113367,
              21.017483628535835
            ],
            [
              105.83159597619397,
              21.017407651164696
            ],
            [
              105.83162776035968,
              21.017370428341977
            ],
            [
              105.83205221407009,
              21.016956665668417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3713",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017678070494778,
          "to_latitude": 21.016956665668417,
          "to_longitude": 105.83205221407009,
          "from_longitude": 105.83133871480013
        }
      },
      {
        "id": 3707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83061212927666,
            21.016204788920184,
            105.830894840466,
            21.01642167895951
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83061212927666,
              21.01621007719761
            ],
            [
              105.8306161682124,
              21.016212289347493
            ],
            [
              105.83062422604735,
              21.016216395809508
            ],
            [
              105.83063399616881,
              21.016221376361923
            ],
            [
              105.8306470713579,
              21.016204788920184
            ],
            [
              105.83069568650984,
              21.01623702520166
            ],
            [
              105.83079526314205,
              21.016315581091266
            ],
            [
              105.8307912401939,
              21.01632841298602
            ],
            [
              105.83080733341632,
              21.016347504445704
            ],
            [
              105.8308368377196,
              21.01637661040102
            ],
            [
              105.830894840466,
              21.01642167895951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_160_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3714",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01621007719761,
          "to_latitude": 21.01642167895951,
          "to_longitude": 105.830894840466,
          "from_longitude": 105.83061212927666
        }
      },
      {
        "id": 3708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8307305498765,
            21.016611811481962,
            105.83086059840389,
            21.016664400206096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307305498765,
              21.016664400206096
            ],
            [
              105.83076444896673,
              21.016611811481962
            ],
            [
              105.83082987316914,
              21.016656009237742
            ],
            [
              105.83086059840389,
              21.016614467987182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_153_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3715",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016664400206096,
          "to_latitude": 21.016614467987182,
          "to_longitude": 105.83086059840389,
          "from_longitude": 105.8307305498765
        }
      },
      {
        "id": 3709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040921383953,
            21.016590214972275,
            105.83053810716704,
            21.01668045586149
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040921383953,
              21.01668045586149
            ],
            [
              105.83041043401299,
              21.016678969519436
            ],
            [
              105.83043819488027,
              21.01667534304369
            ],
            [
              105.8304660224666,
              21.016650931219917
            ],
            [
              105.83048345718534,
              21.01666438906826
            ],
            [
              105.83053810716704,
              21.016590214972275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_154_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3716",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01668045586149,
          "to_latitude": 21.016590214972275,
          "to_longitude": 105.83053810716704,
          "from_longitude": 105.83040921383953
        }
      },
      {
        "id": 3710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83030929457723,
            21.016840936782046,
            105.83052163027554,
            21.016927285368592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052163027554,
              21.016840936782046
            ],
            [
              105.83052083178761,
              21.016841444679855
            ],
            [
              105.83051530842117,
              21.016846852156764
            ],
            [
              105.83049284442184,
              21.0168715765145
            ],
            [
              105.83045663454675,
              21.016927285368592
            ],
            [
              105.83030929457723,
              21.01684636474241
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3717",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016840936782046,
          "to_latitude": 21.01684636474241,
          "to_longitude": 105.83030929457723,
          "from_longitude": 105.83052163027554
        }
      },
      {
        "id": 3711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188985472944,
            21.01669907413273,
            105.83210583603724,
            21.016886312243248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83210583603724,
              21.016886312243248
            ],
            [
              105.8319885263913,
              21.016784613840418
            ],
            [
              105.83197182753665,
              21.016770137327278
            ],
            [
              105.83193251479338,
              21.016733891680456
            ],
            [
              105.83190050463449,
              21.01670830630378
            ],
            [
              105.83189251575398,
              21.016701380602015
            ],
            [
              105.83188985472944,
              21.01669907413273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_177_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3718",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016886312243248,
          "to_latitude": 21.01669907413273,
          "to_longitude": 105.83188985472944,
          "from_longitude": 105.83210583603724
        }
      },
      {
        "id": 3712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319885263913,
            21.01674616890288,
            105.83211480827539,
            21.016827045708556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319885263913,
              21.016784613840418
            ],
            [
              105.83198859430672,
              21.016784544863174
            ],
            [
              105.83202640131617,
              21.01674616890288
            ],
            [
              105.83211480827539,
              21.016827045708556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_177_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3719",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016784613840418,
          "to_latitude": 21.016827045708556,
          "to_longitude": 105.83211480827539,
          "from_longitude": 105.8319885263913
        }
      },
      {
        "id": 3713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225099600726,
            21.016760202038185,
            105.83226148452323,
            21.016767908525328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83225099600726,
              21.016760202038185
            ],
            [
              105.83225155235743,
              21.01676061118699
            ],
            [
              105.83225891817673,
              21.016766023404866
            ],
            [
              105.83226148452323,
              21.016767908525328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3720",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016760202038185,
          "to_latitude": 21.016767908525328,
          "to_longitude": 105.83226148452323,
          "from_longitude": 105.83225099600726
        }
      },
      {
        "id": 3714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217412629588,
            21.016471570496563,
            105.83237720320739,
            21.016607514927493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237720320739,
              21.016607514927493
            ],
            [
              105.83223395317007,
              21.016500317420896
            ],
            [
              105.83221906939194,
              21.016510437704707
            ],
            [
              105.83220796316775,
              21.016500795012
            ],
            [
              105.83217412629588,
              21.016471570496563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_178_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3721",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016607514927493,
          "to_latitude": 21.016471570496563,
          "to_longitude": 105.83217412629588,
          "from_longitude": 105.83237720320739
        }
      },
      {
        "id": 3715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833264511936,
            21.015695827718414,
            105.83329603315944,
            21.015718593561438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83329603315944,
              21.015718593561438
            ],
            [
              105.83329514268455,
              21.015717951214057
            ],
            [
              105.83329096074925,
              21.01571493686199
            ],
            [
              105.83328883888515,
              21.015713409063082
            ],
            [
              105.83328798607035,
              21.015712792724727
            ],
            [
              105.833264511936,
              21.015695827718414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_180_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3722",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015718593561438,
          "to_latitude": 21.015695827718414,
          "to_longitude": 105.833264511936,
          "from_longitude": 105.83329603315944
        }
      },
      {
        "id": 3716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83347268649524,
            21.015183900534836,
            105.83378166359525,
            21.015492900212113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83347268649524,
              21.015492900212113
            ],
            [
              105.8334757762729,
              21.015490137465328
            ],
            [
              105.83349395193473,
              21.015472459021247
            ],
            [
              105.83354371136777,
              21.0154225080651
            ],
            [
              105.83357539363371,
              21.015389612744826
            ],
            [
              105.83359801698342,
              21.015366691858826
            ],
            [
              105.8336037605804,
              21.015359920240325
            ],
            [
              105.83361057449243,
              21.015351879765806
            ],
            [
              105.83365864319535,
              21.015304948413245
            ],
            [
              105.83378166359525,
              21.015183900534836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_181_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3723",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015492900212113,
          "to_latitude": 21.015183900534836,
          "to_longitude": 105.83378166359525,
          "from_longitude": 105.83347268649524
        }
      },
      {
        "id": 3717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366096798305,
            21.01530266089511,
            105.83367497090399,
            21.015313744394874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83367497090399,
              21.015313744394874
            ],
            [
              105.83367372551203,
              21.015312762369636
            ],
            [
              105.83367087526175,
              21.015310505629305
            ],
            [
              105.83366096798305,
              21.01530266089511
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_181_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3724",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015313744394874,
          "to_latitude": 21.01530266089511,
          "to_longitude": 105.83366096798305,
          "from_longitude": 105.83367497090399
        }
      },
      {
        "id": 3718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83378611553835,
            21.01499901070584,
            105.83396533560955,
            21.015174402985664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83378611553835,
              21.015174402985664
            ],
            [
              105.83380080656863,
              21.015161665066007
            ],
            [
              105.8338106923071,
              21.015154387205616
            ],
            [
              105.83387276301649,
              21.015093135187794
            ],
            [
              105.83389337179557,
              21.015069660526095
            ],
            [
              105.83389343490485,
              21.01506959247491
            ],
            [
              105.83390095864398,
              21.015061023736553
            ],
            [
              105.83392305169266,
              21.015035648637213
            ],
            [
              105.83393851608011,
              21.015027580200353
            ],
            [
              105.83396533560955,
              21.01499901070584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_182_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3725",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015174402985664,
          "to_latitude": 21.01499901070584,
          "to_longitude": 105.83396533560955,
          "from_longitude": 105.83378611553835
        }
      },
      {
        "id": 3719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387276301649,
            21.015093135187794,
            105.83388325555642,
            21.01510053446121
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387276301649,
              21.015093135187794
            ],
            [
              105.83387601555852,
              21.015095428791728
            ],
            [
              105.83388325555642,
              21.01510053446121
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_182_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3726",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015093135187794,
          "to_latitude": 21.01510053446121,
          "to_longitude": 105.83388325555642,
          "from_longitude": 105.83387276301649
        }
      },
      {
        "id": 3720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397324942278,
            21.014844755728667,
            105.8341319839654,
            21.014992261856687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83397324942278,
              21.014992261856687
            ],
            [
              105.83400321845193,
              21.01495766324754
            ],
            [
              105.83404878094828,
              21.01490794136883
            ],
            [
              105.83406949761991,
              21.014903014781268
            ],
            [
              105.83408102652068,
              21.014900272973986
            ],
            [
              105.8341319839654,
              21.014844755728667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_183_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3727",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014992261856687,
          "to_latitude": 21.014844755728667,
          "to_longitude": 105.8341319839654,
          "from_longitude": 105.83397324942278
        }
      },
      {
        "id": 3721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83406949761991,
            21.014903014781268,
            105.83408398526808,
            21.014915295354186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83408398526808,
              21.014915295354186
            ],
            [
              105.83407246956762,
              21.014905533756856
            ],
            [
              105.83406949761991,
              21.014903014781268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_183_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3728",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014915295354186,
          "to_latitude": 21.014903014781268,
          "to_longitude": 105.83406949761991,
          "from_longitude": 105.83408398526808
        }
      },
      {
        "id": 3722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83430835969862,
            21.014660356543228,
            105.83432773629619,
            21.014678593372235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83432773629619,
              21.014678593372235
            ],
            [
              105.83432319626786,
              21.014674320802527
            ],
            [
              105.83431801608823,
              21.014669445308105
            ],
            [
              105.83430835969862,
              21.014660356543228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_253_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3729",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014678593372235,
          "to_latitude": 21.014660356543228,
          "to_longitude": 105.83430835969862,
          "from_longitude": 105.83432773629619
        }
      },
      {
        "id": 3723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83413385739324,
            21.014745498897085,
            105.83422402268683,
            21.014818733671067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83422402268683,
              21.014745498897085
            ],
            [
              105.83420208993529,
              21.01476647093037
            ],
            [
              105.83419595943833,
              21.014772332208157
            ],
            [
              105.83414252014433,
              21.01481208772301
            ],
            [
              105.83413385739324,
              21.014818733671067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_184_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3730",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014745498897085,
          "to_latitude": 21.014818733671067,
          "to_longitude": 105.83413385739324,
          "from_longitude": 105.83422402268683
        }
      },
      {
        "id": 3724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423428317735,
            21.0146543082637,
            105.83431468057067,
            21.014731239054814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83423428317735,
              21.014731239054814
            ],
            [
              105.83431468057067,
              21.0146543082637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_253_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.3731",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014731239054814,
          "to_latitude": 21.0146543082637,
          "to_longitude": 105.83431468057067,
          "from_longitude": 105.83423428317735
        }
      },
      {
        "id": 3725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441938395876,
            21.014225565425935,
            105.83472976579017,
            21.014561992829844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441938395876,
              21.014561992829844
            ],
            [
              105.83447790678434,
              21.014503716965535
            ],
            [
              105.83448567019914,
              21.014495014252955
            ],
            [
              105.8345065091517,
              21.014474905613206
            ],
            [
              105.83452238467879,
              21.014459316666155
            ],
            [
              105.83453979092084,
              21.014442223566043
            ],
            [
              105.83458255705595,
              21.014385812436796
            ],
            [
              105.8346942113493,
              21.01426607606807
            ],
            [
              105.83472976579017,
              21.014225565425935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_254_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3732",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014561992829844,
          "to_latitude": 21.014225565425935,
          "to_longitude": 105.83472976579017,
          "from_longitude": 105.83441938395876
        }
      },
      {
        "id": 3726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452238467879,
            21.014459316666155,
            105.83452719720515,
            21.014463292539595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452719720515,
              21.014463292539595
            ],
            [
              105.83452700843314,
              21.014463077594822
            ],
            [
              105.83452380768196,
              21.014460409814006
            ],
            [
              105.83452238467879,
              21.014459316666155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_254_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3733",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014463292539595,
          "to_latitude": 21.014459316666155,
          "to_longitude": 105.83452238467879,
          "from_longitude": 105.83452719720515
        }
      },
      {
        "id": 3727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487408400005,
            21.014070052729817,
            105.83488454110368,
            21.014081022598567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83487408400005,
              21.014070052729817
            ],
            [
              105.83487691617343,
              21.01407234657138
            ],
            [
              105.83488454110368,
              21.014078521534888
            ],
            [
              105.83488232527777,
              21.014081022598567
            ],
            [
              105.83487512196666,
              21.014074382211792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_255_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3734",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014070052729817,
          "to_latitude": 21.014074382211792,
          "to_longitude": 105.83487512196666,
          "from_longitude": 105.83487408400005
        }
      },
      {
        "id": 3728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502609637239,
            21.01354914624138,
            105.83530499398786,
            21.013905170010396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83530499398786,
              21.01354914624138
            ],
            [
              105.83525694944916,
              21.01360644422892
            ],
            [
              105.83516254232796,
              21.01371354959593
            ],
            [
              105.83513484309657,
              21.013744538811185
            ],
            [
              105.8351285961551,
              21.013774393953053
            ],
            [
              105.83505137110012,
              21.01386549197545
            ],
            [
              105.83502609637239,
              21.013897089789737
            ],
            [
              105.83502865122104,
              21.013899331699665
            ],
            [
              105.83503530369141,
              21.013905170010396
            ],
            [
              105.83503703357579,
              21.013903330683803
            ],
            [
              105.83503050858371,
              21.01389706452221
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_256_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3735",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01354914624138,
          "to_latitude": 21.01389706452221,
          "to_longitude": 105.83503050858371,
          "from_longitude": 105.83530499398786
        }
      },
      {
        "id": 3729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83531973843046,
            21.013153513130217,
            105.8356789250475,
            21.013529640974905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83531973843046,
              21.013529640974905
            ],
            [
              105.83541697762237,
              21.01342519787391
            ],
            [
              105.83542619388703,
              21.0134086317387
            ],
            [
              105.8354747198854,
              21.013362327198596
            ],
            [
              105.83548639617058,
              21.013351186393063
            ],
            [
              105.83549182951818,
              21.01333968875944
            ],
            [
              105.83559553311652,
              21.013242249937623
            ],
            [
              105.8356789250475,
              21.013153513130217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_257_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3736",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013529640974905,
          "to_latitude": 21.013153513130217,
          "to_longitude": 105.8356789250475,
          "from_longitude": 105.83531973843046
        }
      },
      {
        "id": 3730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354747198854,
            21.013362327198596,
            105.83548487889594,
            21.013370472270765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8354747198854,
              21.013362327198596
            ],
            [
              105.8354843696982,
              21.013370065610392
            ],
            [
              105.83548487889594,
              21.013370472270765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_257_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3737",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013362327198596,
          "to_latitude": 21.013370472270765,
          "to_longitude": 105.83548487889594,
          "from_longitude": 105.8354747198854
        }
      },
      {
        "id": 3731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568431439046,
            21.012823598327287,
            105.83607426717272,
            21.01314845208581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568431439046,
              21.01314845208581
            ],
            [
              105.83574413787964,
              21.013089525418653
            ],
            [
              105.83585027132754,
              21.01299560702465
            ],
            [
              105.83587202421366,
              21.012980249213282
            ],
            [
              105.83593478493937,
              21.012935937581613
            ],
            [
              105.83595627814675,
              21.012920762583814
            ],
            [
              105.83607426717272,
              21.012823598327287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_258_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3738",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01314845208581,
          "to_latitude": 21.012823598327287,
          "to_longitude": 105.83607426717272,
          "from_longitude": 105.83568431439046
        }
      },
      {
        "id": 3732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593478493937,
            21.012935937581613,
            105.83595207912965,
            21.012953434426432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593478493937,
              21.012935937581613
            ],
            [
              105.83594118724932,
              21.01294401076133
            ],
            [
              105.83594865904422,
              21.012953434426432
            ],
            [
              105.83595207912965,
              21.012951273367857
            ],
            [
              105.83594340897105,
              21.012942228622343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_258_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3739",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012935937581613,
          "to_latitude": 21.012942228622343,
          "to_longitude": 105.83594340897105,
          "from_longitude": 105.83593478493937
        }
      },
      {
        "id": 3733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608878919243,
            21.01249455683772,
            105.83641580091088,
            21.0128140945933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608878919243,
              21.0128140945933
            ],
            [
              105.83610680301484,
              21.012795796162575
            ],
            [
              105.83611694194278,
              21.01278549654652
            ],
            [
              105.83623546194397,
              21.012668524567577
            ],
            [
              105.83631637363546,
              21.01259019034722
            ],
            [
              105.83632697064672,
              21.012579933628878
            ],
            [
              105.83633291376066,
              21.012573447264128
            ],
            [
              105.83633298071034,
              21.012573378289986
            ],
            [
              105.83636510573855,
              21.012538334236005
            ],
            [
              105.83636896684705,
              21.012536949581154
            ],
            [
              105.83637922919954,
              21.012533266366876
            ],
            [
              105.83641580091088,
              21.01249455683772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_295_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3740",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0128140945933,
          "to_latitude": 21.01249455683772,
          "to_longitude": 105.83641580091088,
          "from_longitude": 105.83608878919243
        }
      },
      {
        "id": 3734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610680301484,
            21.012795796162575,
            105.83611997978002,
            21.01280639759066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611997978002,
              21.01280639759066
            ],
            [
              105.83611983775269,
              21.01280628538553
            ],
            [
              105.83611040803748,
              21.012798685927432
            ],
            [
              105.83610766867056,
              21.012796473843313
            ],
            [
              105.83610680301484,
              21.012795796162575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_295_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3741",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01280639759066,
          "to_latitude": 21.012795796162575,
          "to_longitude": 105.83610680301484,
          "from_longitude": 105.83611997978002
        }
      },
      {
        "id": 3735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364255536353,
            21.012140514407964,
            105.83669578994042,
            21.012473310214094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364255536353,
              21.012473310214094
            ],
            [
              105.83643133992771,
              21.012466475069072
            ],
            [
              105.83645963876586,
              21.012433047611367
            ],
            [
              105.83648050060629,
              21.012424042871327
            ],
            [
              105.83648133930333,
              21.01242368107123
            ],
            [
              105.8364841210369,
              21.012420380561533
            ],
            [
              105.83657207557135,
              21.01231616492643
            ],
            [
              105.83665067677671,
              21.012225310576394
            ],
            [
              105.83669578994042,
              21.012140514407964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_296_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3742",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012473310214094,
          "to_latitude": 21.012140514407964,
          "to_longitude": 105.83669578994042,
          "from_longitude": 105.8364255536353
        }
      },
      {
        "id": 3736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83643133992771,
            21.012466475069072,
            105.83644190130785,
            21.01247381152761
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83643133992771,
              21.012466475069072
            ],
            [
              105.83643305804354,
              21.012467706750396
            ],
            [
              105.8364356179587,
              21.012469478034742
            ],
            [
              105.83644147547281,
              21.01247351916976
            ],
            [
              105.83644190130785,
              21.01247381152761
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_296_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3743",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012466475069072,
          "to_latitude": 21.01247381152761,
          "to_longitude": 105.83644190130785,
          "from_longitude": 105.83643133992771
        }
      },
      {
        "id": 3737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83686428859937,
            21.011914482970546,
            105.83692216587713,
            21.011949653479835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83692216587713,
              21.011949653479835
            ],
            [
              105.83692088151979,
              21.011948754766124
            ],
            [
              105.83691509799903,
              21.011944698831606
            ],
            [
              105.83686428859937,
              21.011914482970546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_298_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.3744",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011949653479835,
          "to_latitude": 21.011914482970546,
          "to_longitude": 105.83686428859937,
          "from_longitude": 105.83692216587713
        }
      },
      {
        "id": 3738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83027253634383,
            21.016987866948263,
            105.83057457847414,
            21.01707549908296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83057457847414,
              21.016987866948263
            ],
            [
              105.83057396354057,
              21.016987995498834
            ],
            [
              105.83056662465778,
              21.016989523457106
            ],
            [
              105.83048878045855,
              21.017005738836634
            ],
            [
              105.8304030266204,
              21.01705939263424
            ],
            [
              105.83038095127638,
              21.017071795089816
            ],
            [
              105.83037058588211,
              21.017072588135782
            ],
            [
              105.83036455222017,
              21.01707304932822
            ],
            [
              105.83032893759183,
              21.017072441872205
            ],
            [
              105.83027253634383,
              21.01707549908296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3745",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016987866948263,
          "to_latitude": 21.01707549908296,
          "to_longitude": 105.83027253634383,
          "from_longitude": 105.83057457847414
        }
      },
      {
        "id": 3739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83022524834277,
            21.016477975368474,
            105.83044177715448,
            21.01663733087588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044177715448,
              21.016477975368474
            ],
            [
              105.83042741735167,
              21.016504572284994
            ],
            [
              105.83041808353462,
              21.016519672803895
            ],
            [
              105.83039997957228,
              21.016549522443913
            ],
            [
              105.8303988906269,
              21.016551429048306
            ],
            [
              105.83038417028494,
              21.016577194044352
            ],
            [
              105.83037903986448,
              21.016588419982074
            ],
            [
              105.83035834395406,
              21.016623399943356
            ],
            [
              105.830350101226,
              21.01663733087588
            ],
            [
              105.83028799450815,
              21.016608004601398
            ],
            [
              105.83022524834277,
              21.01657837071459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3746",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016477975368474,
          "to_latitude": 21.01657837071459,
          "to_longitude": 105.83022524834277,
          "from_longitude": 105.83044177715448
        }
      },
      {
        "id": 3740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83036786359871,
            21.0162315087738,
            105.8306104953029,
            21.01667274229463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040385254411,
              21.01667274229463
            ],
            [
              105.83036929121174,
              21.01663631863032
            ],
            [
              105.83036786359871,
              21.016631785085437
            ],
            [
              105.83036954572181,
              21.016628079071
            ],
            [
              105.83037108909488,
              21.016624679023924
            ],
            [
              105.83037187599213,
              21.016622946410028
            ],
            [
              105.83042119751428,
              21.01653968391282
            ],
            [
              105.8304377127355,
              21.016516160086198
            ],
            [
              105.83049603098156,
              21.016417737274725
            ],
            [
              105.83050881501116,
              21.016396161848412
            ],
            [
              105.83051013973197,
              21.016393926221614
            ],
            [
              105.8306104953029,
              21.0162315087738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_158_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3747",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01667274229463,
          "to_latitude": 21.0162315087738,
          "to_longitude": 105.8306104953029,
          "from_longitude": 105.83040385254411
        }
      },
      {
        "id": 3741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83056775958687,
            21.01700321622838,
            105.83067001767117,
            21.01714587090102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067001767117,
              21.01714587090102
            ],
            [
              105.83063016124676,
              21.01710295547944
            ],
            [
              105.83062189474397,
              21.01709348668763
            ],
            [
              105.83061770776624,
              21.017088691134575
            ],
            [
              105.83060198408748,
              21.01707068082275
            ],
            [
              105.83057963651092,
              21.017033747767137
            ],
            [
              105.83056775958687,
              21.01700321622838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3748",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01714587090102,
          "to_latitude": 21.01700321622838,
          "to_longitude": 105.83056775958687,
          "from_longitude": 105.83067001767117
        }
      },
      {
        "id": 3742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83037058588211,
            21.017072588135782,
            105.83037654320982,
            21.017135830882292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83037058588211,
              21.017072588135782
            ],
            [
              105.83037654320982,
              21.017135830882292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3749",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017072588135782,
          "to_latitude": 21.017135830882292,
          "to_longitude": 105.83037654320982,
          "from_longitude": 105.83037058588211
        }
      },
      {
        "id": 3743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83062189474397,
            21.017089561504363,
            105.83062850659051,
            21.01709348668763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83062850659051,
              21.017089561504363
            ],
            [
              105.8306259504483,
              21.0170910796613
            ],
            [
              105.83062189474397,
              21.01709348668763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3750",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017089561504363,
          "to_latitude": 21.01709348668763,
          "to_longitude": 105.83062189474397,
          "from_longitude": 105.83062850659051
        }
      },
      {
        "id": 3744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83051761256276,
            21.016861495736222,
            105.83056662465778,
            21.016989523457106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83056662465778,
              21.016989523457106
            ],
            [
              105.83056547822385,
              21.01698417563892
            ],
            [
              105.83055849894554,
              21.016951626769814
            ],
            [
              105.83055388824376,
              21.016933369809752
            ],
            [
              105.83054917705367,
              21.016908604699726
            ],
            [
              105.83053231087811,
              21.01688265157685
            ],
            [
              105.83051761256276,
              21.016861495736222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3751",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016989523457106,
          "to_latitude": 21.016861495736222,
          "to_longitude": 105.83051761256276,
          "from_longitude": 105.83056662465778
        }
      },
      {
        "id": 3745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83014166813136,
            21.01632725834445,
            105.83045056515587,
            21.016482436854485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83045056515587,
              21.016482436854485
            ],
            [
              105.83044903052222,
              21.0164815077012
            ],
            [
              105.83044154665775,
              21.016469876369992
            ],
            [
              105.83038019152046,
              21.01643920516895
            ],
            [
              105.83029840518412,
              21.016400445021787
            ],
            [
              105.83028354265959,
              21.01639340104682
            ],
            [
              105.83022690641208,
              21.016368902883222
            ],
            [
              105.83014166813136,
              21.01632725834445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_157_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3752",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016482436854485,
          "to_latitude": 21.01632725834445,
          "to_longitude": 105.83014166813136,
          "from_longitude": 105.83045056515587
        }
      },
      {
        "id": 3746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026644771705,
            21.016518993043057,
            105.83038417028494,
            21.016577194044352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026644771705,
              21.016518993043057
            ],
            [
              105.83029181857768,
              21.016531781729327
            ],
            [
              105.83031847913598,
              21.016545216245657
            ],
            [
              105.83038417028494,
              21.016577194044352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3753",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016518993043057,
          "to_latitude": 21.016577194044352,
          "to_longitude": 105.83038417028494,
          "from_longitude": 105.83026644771705
        }
      },
      {
        "id": 3747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83066226947473,
            21.016508138626296,
            105.83076233501481,
            21.016605747593818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83066226947473,
              21.016605747593818
            ],
            [
              105.83069274891957,
              21.01654569958584
            ],
            [
              105.83071557076876,
              21.016508138626296
            ],
            [
              105.83073682174685,
              21.016510695757184
            ],
            [
              105.83076233501481,
              21.01652192200051
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_153_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3754",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016605747593818,
          "to_latitude": 21.01652192200051,
          "to_longitude": 105.83076233501481,
          "from_longitude": 105.83066226947473
        }
      },
      {
        "id": 3748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83049884922657,
            21.016389722632358,
            105.83050881501116,
            21.016396161848412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83049884922657,
              21.016389722632358
            ],
            [
              105.83049987448246,
              21.01639038601399
            ],
            [
              105.83050250228555,
              21.016392082991025
            ],
            [
              105.83050881501116,
              21.016396161848412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_158_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3755",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016389722632358,
          "to_latitude": 21.016396161848412,
          "to_longitude": 105.83050881501116,
          "from_longitude": 105.83049884922657
        }
      },
      {
        "id": 3749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83062422604735,
            21.016081716121032,
            105.83075348258981,
            21.016216395809508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83062422604735,
              21.016216395809508
            ],
            [
              105.83064051474679,
              21.01619227874804
            ],
            [
              105.83075348258981,
              21.016081716121032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_160_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3756",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016216395809508,
          "to_latitude": 21.016081716121032,
          "to_longitude": 105.83075348258981,
          "from_longitude": 105.83062422604735
        }
      },
      {
        "id": 3750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83077313537835,
            21.015918640753878,
            105.83091935461378,
            21.016137543920387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83082961292067,
              21.016137543920387
            ],
            [
              105.83083041567578,
              21.01613659161361
            ],
            [
              105.83084019087065,
              21.016126858591363
            ],
            [
              105.83077313537835,
              21.01607240093252
            ],
            [
              105.83077938019734,
              21.016065262958197
            ],
            [
              105.83091935461378,
              21.015918640753878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_172_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3757",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016137543920387,
          "to_latitude": 21.015918640753878,
          "to_longitude": 105.83091935461378,
          "from_longitude": 105.83082961292067
        }
      },
      {
        "id": 3751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83107990600466,
            21.01635505523191,
            105.83137080156371,
            21.016558729422577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83107990600466,
              21.016356115811465
            ],
            [
              105.8310841980846,
              21.01635505523191
            ],
            [
              105.83110486115412,
              21.01636800227798
            ],
            [
              105.83118837429492,
              21.016431747639743
            ],
            [
              105.83118969099276,
              21.016432752818723
            ],
            [
              105.83119103086176,
              21.01643377323929
            ],
            [
              105.83127388344688,
              21.016496865139246
            ],
            [
              105.83137080156371,
              21.016558729422577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_174_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3758",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016356115811465,
          "to_latitude": 21.016558729422577,
          "to_longitude": 105.83137080156371,
          "from_longitude": 105.83107990600466
        }
      },
      {
        "id": 3752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098989161887,
            21.016349700576058,
            105.8310890743183,
            21.016590214437116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310890743183,
              21.016349700576058
            ],
            [
              105.8310845890733,
              21.01635253061414
            ],
            [
              105.8310806627284,
              21.0163550155995
            ],
            [
              105.83107990600466,
              21.016356115811465
            ],
            [
              105.83102073666872,
              21.016442178721217
            ],
            [
              105.8310240898627,
              21.01644875136863
            ],
            [
              105.83101637829594,
              21.01646565134767
            ],
            [
              105.83099927864899,
              21.01649444478822
            ],
            [
              105.83103213666936,
              21.01651510091262
            ],
            [
              105.83098989161887,
              21.016590214437116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_174_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3759",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016349700576058,
          "to_latitude": 21.016590214437116,
          "to_longitude": 105.83098989161887,
          "from_longitude": 105.8310890743183
        }
      },
      {
        "id": 3753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118578579662,
            21.01631065213258,
            105.83135827567206,
            21.016436638089594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311946880976,
              21.016429941954435
            ],
            [
              105.83119103086176,
              21.01643377323929
            ],
            [
              105.83118829462059,
              21.016436638089594
            ],
            [
              105.83118578579662,
              21.016434888155043
            ],
            [
              105.83118837429492,
              21.016431747639743
            ],
            [
              105.83119173579796,
              21.01642766760648
            ],
            [
              105.83119188486083,
              21.016427487136394
            ],
            [
              105.83119458896992,
              21.01642420515303
            ],
            [
              105.83120234489424,
              21.016414792685666
            ],
            [
              105.83129054987192,
              21.016319102169515
            ],
            [
              105.83132206659829,
              21.016343514079587
            ],
            [
              105.83135827567206,
              21.01631065213258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_175_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3760",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016429941954435,
          "to_latitude": 21.01631065213258,
          "to_longitude": 105.83135827567206,
          "from_longitude": 105.8311946880976
        }
      },
      {
        "id": 3754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100377174729,
            21.01615747838271,
            105.83116465238297,
            21.01628121203585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100377174729,
              21.01628121203585
            ],
            [
              105.83100398984801,
              21.01628099148679
            ],
            [
              105.83101039898298,
              21.01627434315283
            ],
            [
              105.83111469826645,
              21.01615747838271
            ],
            [
              105.83116465238297,
              21.01619802217378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_173_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3761",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01628121203585,
          "to_latitude": 21.01619802217378,
          "to_longitude": 105.83116465238297,
          "from_longitude": 105.83100377174729
        }
      },
      {
        "id": 3755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141210303954,
            21.01622489682864,
            105.83145483519023,
            21.016259458930914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83145483519023,
              21.01622489682864
            ],
            [
              105.83141210303954,
              21.016259458930914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_162_Ngách 103, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3762",
          "diaChiLapD": "Ngách 103, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01622489682864,
          "to_latitude": 21.016259458930914,
          "to_longitude": 105.83141210303954,
          "from_longitude": 105.83145483519023
        }
      },
      {
        "id": 3756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310481452774,
            21.01601551769216,
            105.83112928248562,
            21.016120989851398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83112928248562,
              21.01601551769216
            ],
            [
              105.8310481452774,
              21.016120989851398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_162_Ngách 103, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3763",
          "diaChiLapD": "Ngách 103, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01601551769216,
          "to_latitude": 21.016120989851398,
          "to_longitude": 105.8310481452774,
          "from_longitude": 105.83112928248562
        }
      },
      {
        "id": 3757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83104246711189,
            21.015744964310592,
            105.83144201077404,
            21.01608679768299
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83104246711189,
              21.015744964310592
            ],
            [
              105.83104714452254,
              21.015748347524422
            ],
            [
              105.83106583113327,
              21.01575754952623
            ],
            [
              105.83113146531228,
              21.015809315179464
            ],
            [
              105.83115099175343,
              21.01582859396453
            ],
            [
              105.83144201077404,
              21.01608679768299
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_164_Ngách 105, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3764",
          "diaChiLapD": "Ngách 105, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015744964310592,
          "to_latitude": 21.01608679768299,
          "to_longitude": 105.83144201077404,
          "from_longitude": 105.83104246711189
        }
      },
      {
        "id": 3758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83106915003835,
            21.015720782887712,
            105.83145818804897,
            21.01605995967439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83106915003835,
              21.015720782887712
            ],
            [
              105.83107410180055,
              21.01572472295379
            ],
            [
              105.83109740456787,
              21.01575420419434
            ],
            [
              105.83117588531128,
              21.015815840742608
            ],
            [
              105.83124227093535,
              21.015870923838474
            ],
            [
              105.83145818804897,
              21.01605995967439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_165_Ngách 105, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3765",
          "diaChiLapD": "Ngách 105, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015720782887712,
          "to_latitude": 21.01605995967439,
          "to_longitude": 105.83145818804897,
          "from_longitude": 105.83106915003835
        }
      },
      {
        "id": 3759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83078076362162,
            21.01565403311945,
            105.83096181199537,
            21.015888162717413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83078076362162,
              21.01565403311945
            ],
            [
              105.83085385355821,
              21.01571099395638
            ],
            [
              105.83096181199537,
              21.015807389975297
            ],
            [
              105.83090518444175,
              21.015866227159414
            ],
            [
              105.83092840006002,
              21.015886750661267
            ],
            [
              105.83092999989148,
              21.015888162717413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3766",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01565403311945,
          "to_latitude": 21.015888162717413,
          "to_longitude": 105.83092999989148,
          "from_longitude": 105.83078076362162
        }
      },
      {
        "id": 3760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83180780268425,
            21.0159728204054,
            105.83192095479976,
            21.016085713197505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83180780268425,
              21.016085713197505
            ],
            [
              105.83190296890554,
              21.01599076511402
            ],
            [
              105.83191605294756,
              21.015977710951756
            ],
            [
              105.83192095479976,
              21.0159728204054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_190_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3767",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016085713197505,
          "to_latitude": 21.0159728204054,
          "to_longitude": 105.83192095479976,
          "from_longitude": 105.83180780268425
        }
      },
      {
        "id": 3761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190375297413,
            21.01596684659154,
            105.83191605294756,
            21.015977710951756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190375297413,
              21.01596684659154
            ],
            [
              105.83190514499205,
              21.015968077202942
            ],
            [
              105.83190984882545,
              21.015972230713942
            ],
            [
              105.83191605294756,
              21.015977710951756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_190_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3768",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01596684659154,
          "to_latitude": 21.015977710951756,
          "to_longitude": 105.83191605294756,
          "from_longitude": 105.83190375297413
        }
      },
      {
        "id": 3762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109740456787,
            21.015564545617504,
            105.83130340386852,
            21.01575420419434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109740456787,
              21.01575420419434
            ],
            [
              105.83116270850928,
              21.01567867089499
            ],
            [
              105.83130340386852,
              21.015564545617504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_165_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3769",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01575420419434,
          "to_latitude": 21.015564545617504,
          "to_longitude": 105.83130340386852,
          "from_longitude": 105.83109740456787
        }
      },
      {
        "id": 3763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83172168308361,
            21.015651864337745,
            105.83181058073075,
            21.015695947175416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83181058073075,
              21.015695947175416
            ],
            [
              105.83176698944696,
              21.015651864337745
            ],
            [
              105.83172168308361,
              21.01568914787079
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_188_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3770",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015695947175416,
          "to_latitude": 21.01568914787079,
          "to_longitude": 105.83172168308361,
          "from_longitude": 105.83181058073075
        }
      },
      {
        "id": 3764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83185134623704,
            21.01554583146457,
            105.8319094499026,
            21.01561031672032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319094499026,
              21.01561031672032
            ],
            [
              105.83185134623704,
              21.01554583146457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_188_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3771",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01561031672032,
          "to_latitude": 21.01554583146457,
          "to_longitude": 105.83185134623704,
          "from_longitude": 105.8319094499026
        }
      },
      {
        "id": 3765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135431630352,
            21.015543199303366,
            105.83164842447525,
            21.01582927014504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83135431630352,
              21.015543199303366
            ],
            [
              105.83148973492378,
              21.015674917798613
            ],
            [
              105.83150182819999,
              21.015686681006656
            ],
            [
              105.83151212547469,
              21.015696697122245
            ],
            [
              105.83164842447525,
              21.01582927014504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_186_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3772",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015543199303366,
          "to_latitude": 21.01582927014504,
          "to_longitude": 105.83164842447525,
          "from_longitude": 105.83135431630352
        }
      },
      {
        "id": 3766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83147779091207,
            21.015674917798613,
            105.83148973492378,
            21.01568574261689
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83147779091207,
              21.01568574261689
            ],
            [
              105.83148973492378,
              21.015674917798613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_186_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3773",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568574261689,
          "to_latitude": 21.015674917798613,
          "to_longitude": 105.83148973492378,
          "from_longitude": 105.83147779091207
        }
      },
      {
        "id": 3767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133205706447,
            21.015294346666966,
            105.83157528351822,
            21.015512474676953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133205706447,
              21.015512474676953
            ],
            [
              105.83138013341461,
              21.01546990558433
            ],
            [
              105.83154098686978,
              21.015325960083533
            ],
            [
              105.83157528351822,
              21.015294346666966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_193_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3774",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015512474676953,
          "to_latitude": 21.015294346666966,
          "to_longitude": 105.83157528351822,
          "from_longitude": 105.83133205706447
        }
      },
      {
        "id": 3768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153170242583,
            21.01531870017755,
            105.83154098686978,
            21.015325960083533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83153170242583,
              21.01531870017755
            ],
            [
              105.83153254391884,
              21.015319358128675
            ],
            [
              105.83153411098007,
              21.015320585178277
            ],
            [
              105.8315356799551,
              21.015321810412026
            ],
            [
              105.83154098686978,
              21.015325960083533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_193_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3775",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01531870017755,
          "to_latitude": 21.015325960083533,
          "to_longitude": 105.83154098686978,
          "from_longitude": 105.83153170242583
        }
      },
      {
        "id": 3769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83165018727175,
            21.015506700502726,
            105.83226679025447,
            21.015852265766934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165018727175,
              21.015852265766934
            ],
            [
              105.83166665674919,
              21.01583303146619
            ],
            [
              105.83168396300017,
              21.01581898210124
            ],
            [
              105.83180077488126,
              21.015717308520504
            ],
            [
              105.83186230823847,
              21.015661654413524
            ],
            [
              105.83190402155516,
              21.0156231266082
            ],
            [
              105.83194291358781,
              21.01559276780829
            ],
            [
              105.83204115012425,
              21.015506700502726
            ],
            [
              105.83205115621037,
              21.015508612467915
            ],
            [
              105.83217593058805,
              21.015624378214753
            ],
            [
              105.83226679025447,
              21.01571295018968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_189_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3776",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015852265766934,
          "to_latitude": 21.01571295018968,
          "to_longitude": 105.83226679025447,
          "from_longitude": 105.83165018727175
        }
      },
      {
        "id": 3770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83171883074696,
            21.0151421289558,
            105.83186244228088,
            21.015276481558203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171883074696,
              21.0151421289558
            ],
            [
              105.83174356266198,
              21.015163920289307
            ],
            [
              105.83176864256903,
              21.015186016110928
            ],
            [
              105.83186244228088,
              21.015276481558203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_194_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3777",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0151421289558,
          "to_latitude": 21.015276481558203,
          "to_longitude": 105.83186244228088,
          "from_longitude": 105.83171883074696
        }
      },
      {
        "id": 3771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188025744747,
            21.01514951149636,
            105.83188481396415,
            21.015153530547096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188025744747,
              21.015153530547096
            ],
            [
              105.83188320099352,
              21.01515093383371
            ],
            [
              105.83188481396415,
              21.01514951149636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_196_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3778",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015153530547096,
          "to_latitude": 21.01514951149636,
          "to_longitude": 105.83188481396415,
          "from_longitude": 105.83188025744747
        }
      },
      {
        "id": 3772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83179603501289,
            21.01506188654136,
            105.8321597172874,
            21.015403161973808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83179603501289,
              21.01506188654136
            ],
            [
              105.83185911386124,
              21.015123473437296
            ],
            [
              105.83188481396415,
              21.01514951149636
            ],
            [
              105.83190799697985,
              21.015172999409682
            ],
            [
              105.83195898764401,
              21.015218000741836
            ],
            [
              105.83197275377098,
              21.015231507090018
            ],
            [
              105.83209922715345,
              21.015345459952975
            ],
            [
              105.8321597172874,
              21.015403161973808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_196_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3779",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01506188654136,
          "to_latitude": 21.015403161973808,
          "to_longitude": 105.8321597172874,
          "from_longitude": 105.83179603501289
        }
      },
      {
        "id": 3773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157528351822,
            21.015294346666966,
            105.8316705019162,
            21.01540826998474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157528351822,
              21.015294346666966
            ],
            [
              105.8316705019162,
              21.01538166657877
            ],
            [
              105.83164300915955,
              21.01540826998474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_193_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3780",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015294346666966,
          "to_latitude": 21.01540826998474,
          "to_longitude": 105.83164300915955,
          "from_longitude": 105.83157528351822
        }
      },
      {
        "id": 3774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135654977454,
            21.01475868944568,
            105.83187735849471,
            21.01512173905996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83135654977454,
              21.01475868944568
            ],
            [
              105.83147941915014,
              21.014885703886225
            ],
            [
              105.83152937074958,
              21.014941472361212
            ],
            [
              105.8316023274908,
              21.01501294203964
            ],
            [
              105.83171338857454,
              21.01512173905996
            ],
            [
              105.83187581006877,
              21.014971169466964
            ],
            [
              105.83187735849471,
              21.014969730284033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_195_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3781",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01475868944568,
          "to_latitude": 21.014969730284033,
          "to_longitude": 105.83187735849471,
          "from_longitude": 105.83135654977454
        }
      },
      {
        "id": 3775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83117523534331,
            21.015117034487396,
            105.83134316415165,
            21.015442408686255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83134132079987,
              21.015442408686255
            ],
            [
              105.83126791790949,
              21.015348252065223
            ],
            [
              105.83125598915105,
              21.015335443906665
            ],
            [
              105.8312500668784,
              21.015329087040627
            ],
            [
              105.83123677406687,
              21.015311239226158
            ],
            [
              105.83123548025803,
              21.0153095023229
            ],
            [
              105.83122089461845,
              21.01528992030865
            ],
            [
              105.83117744321738,
              21.015224331737187
            ],
            [
              105.83117523534331,
              21.01522047039729
            ],
            [
              105.83124664167913,
              21.015176555363475
            ],
            [
              105.8313052593951,
              21.01513808149534
            ],
            [
              105.83134316415165,
              21.015117034487396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_169_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3782",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015442408686255,
          "to_latitude": 21.015117034487396,
          "to_longitude": 105.83134316415165,
          "from_longitude": 105.83134132079987
        }
      },
      {
        "id": 3776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83125598915105,
            21.015221835686948,
            105.83143281148223,
            21.015335443906665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83125598915105,
              21.015335443906665
            ],
            [
              105.83143281148223,
              21.015221835686948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_169_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3783",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015335443906665,
          "to_latitude": 21.015221835686948,
          "to_longitude": 105.83143281148223,
          "from_longitude": 105.83125598915105
        }
      },
      {
        "id": 3777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83123066183221,
            21.015311239226158,
            105.83123677406687,
            21.01531573463493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83123066183221,
              21.01531573463493
            ],
            [
              105.83123168021736,
              21.0153149854017
            ],
            [
              105.83123475262211,
              21.015312725875823
            ],
            [
              105.83123677406687,
              21.015311239226158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_169_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3784",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01531573463493,
          "to_latitude": 21.015311239226158,
          "to_longitude": 105.83123677406687,
          "from_longitude": 105.83123066183221
        }
      },
      {
        "id": 3778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83114187356158,
            21.015222559961888,
            105.83132256519967,
            21.015457549872146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83132256519967,
              21.015457549872146
            ],
            [
              105.83130906998389,
              21.015427310803926
            ],
            [
              105.83125682476268,
              21.015364548965007
            ],
            [
              105.83121790454396,
              21.01531536883564
            ],
            [
              105.8312030821866,
              21.015296639717732
            ],
            [
              105.83118849135084,
              21.015278202112242
            ],
            [
              105.83114187356158,
              21.015222559961888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_170_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3785",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015457549872146,
          "to_latitude": 21.015222559961888,
          "to_longitude": 105.83114187356158,
          "from_longitude": 105.83132256519967
        }
      },
      {
        "id": 3779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83112854127639,
            21.01487558555632,
            105.83131376846384,
            21.015095156530677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83112854127639,
              21.015095156530677
            ],
            [
              105.83131376846384,
              21.014988256276325
            ],
            [
              105.83129063457417,
              21.014950699402053
            ],
            [
              105.83126414795376,
              21.0149106389071
            ],
            [
              105.83123698977032,
              21.01487558555632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_171_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3786",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015095156530677,
          "to_latitude": 21.01487558555632,
          "to_longitude": 105.83123698977032,
          "from_longitude": 105.83112854127639
        }
      },
      {
        "id": 3780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133786773512,
            21.015139458093707,
            105.83167985087647,
            21.01545877483819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133786773512,
              21.01545877483819
            ],
            [
              105.83133991963066,
              21.015457401844866
            ],
            [
              105.83140142181618,
              21.015416242107452
            ],
            [
              105.83143752312141,
              21.015386193316655
            ],
            [
              105.83149260656305,
              21.0153369331802
            ],
            [
              105.83153058741355,
              21.015301498285154
            ],
            [
              105.83159325246967,
              21.015242642229854
            ],
            [
              105.83165148467894,
              21.01518739272204
            ],
            [
              105.83167985087647,
              21.015139458093707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_185_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3787",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01545877483819,
          "to_latitude": 21.015139458093707,
          "to_longitude": 105.83167985087647,
          "from_longitude": 105.83133786773512
        }
      },
      {
        "id": 3781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83131376846384,
            21.014972920412756,
            105.8314200511368,
            21.015032386485316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83131376846384,
              21.014988256276325
            ],
            [
              105.8313506492331,
              21.015032386485316
            ],
            [
              105.8314200511368,
              21.014972920412756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_171_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3788",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014988256276325,
          "to_latitude": 21.014972920412756,
          "to_longitude": 105.8314200511368,
          "from_longitude": 105.83131376846384
        }
      },
      {
        "id": 3782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83120783646538,
            21.014638975491085,
            105.83167039131625,
            21.01511917725429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83167039131625,
              21.01511917725429
            ],
            [
              105.8316194004926,
              21.015067532611532
            ],
            [
              105.8315661968279,
              21.015010604965294
            ],
            [
              105.83149422727327,
              21.014927375873604
            ],
            [
              105.8314817333874,
              21.014914132427588
            ],
            [
              105.83140969716571,
              21.01483777535976
            ],
            [
              105.83131613196267,
              21.014744744240378
            ],
            [
              105.83120783646538,
              21.014638975491085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_233_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3789",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01511917725429,
          "to_latitude": 21.014638975491085,
          "to_longitude": 105.83120783646538,
          "from_longitude": 105.83167039131625
        }
      },
      {
        "id": 3783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314817333874,
            21.014909949867437,
            105.83148685173231,
            21.014914132427588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83148685173231,
              21.014909949867437
            ],
            [
              105.83148590275361,
              21.014910724956426
            ],
            [
              105.8314835518571,
              21.014912645863884
            ],
            [
              105.8314817333874,
              21.014914132427588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_233_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3790",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014909949867437,
          "to_latitude": 21.014914132427588,
          "to_longitude": 105.8314817333874,
          "from_longitude": 105.83148685173231
        }
      },
      {
        "id": 3784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83050985882724,
            21.01454436878452,
            105.83110137396888,
            21.01480515337997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310991644004,
              21.01480515337997
            ],
            [
              105.83110137396888,
              21.014690395944807
            ],
            [
              105.83091741524798,
              21.014702908145708
            ],
            [
              105.8307667406977,
              21.014716364076556
            ],
            [
              105.83066171393034,
              21.014720322693393
            ],
            [
              105.83062322439514,
              21.014721406608825
            ],
            [
              105.83062695778713,
              21.0145911450913
            ],
            [
              105.83058526408594,
              21.01458336774142
            ],
            [
              105.8305515655392,
              21.01457707870557
            ],
            [
              105.83050985882724,
              21.014566081401963
            ],
            [
              105.83051392589604,
              21.01454436878452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_227_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3791",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01480515337997,
          "to_latitude": 21.01454436878452,
          "to_longitude": 105.83051392589604,
          "from_longitude": 105.8310991644004
        }
      },
      {
        "id": 3785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110591176954,
            21.014639098342453,
            105.83119396802651,
            21.015084833755456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83113756280986,
              21.015084833755456
            ],
            [
              105.83111892322474,
              21.015064660051923
            ],
            [
              105.83110745352327,
              21.015015773016472
            ],
            [
              105.8311072692118,
              21.014982508131155
            ],
            [
              105.83110651751772,
              21.014957084206085
            ],
            [
              105.83110591176954,
              21.01493959801469
            ],
            [
              105.8311091195074,
              21.014854298913168
            ],
            [
              105.8311103491901,
              21.01478352520694
            ],
            [
              105.83111448271788,
              21.014717919118187
            ],
            [
              105.8311151998233,
              21.014706544912165
            ],
            [
              105.83119396802651,
              21.014639098342453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_226_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3792",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015084833755456,
          "to_latitude": 21.014639098342453,
          "to_longitude": 105.83119396802651,
          "from_longitude": 105.83113756280986
        }
      },
      {
        "id": 3786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83111048313927,
            21.014717919118187,
            105.83111448271788,
            21.01471811659159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83111048313927,
              21.01471811659159
            ],
            [
              105.83111189871866,
              21.014718047353927
            ],
            [
              105.83111448271788,
              21.014717919118187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_226_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3793",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01471811659159,
          "to_latitude": 21.014717919118187,
          "to_longitude": 105.83111448271788,
          "from_longitude": 105.83111048313927
        }
      },
      {
        "id": 3787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110137396888,
            21.014690395944807,
            105.83112466563445,
            21.014696375322252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83110137396888,
              21.014690395944807
            ],
            [
              105.83112326114986,
              21.01469601637703
            ],
            [
              105.83112466563445,
              21.014696375322252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_227_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3794",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014690395944807,
          "to_latitude": 21.014696375322252,
          "to_longitude": 105.83112466563445,
          "from_longitude": 105.83110137396888
        }
      },
      {
        "id": 3788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83086544019095,
            21.0144102848415,
            105.83110679903734,
            21.014416676641783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83086544019095,
              21.0144102848415
            ],
            [
              105.83091806241407,
              21.014414429269188
            ],
            [
              105.83099588918867,
              21.014416676641783
            ],
            [
              105.8310302092302,
              21.014416213654975
            ],
            [
              105.83106943651693,
              21.014414962562217
            ],
            [
              105.83110679903734,
              21.014415349084924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_231_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3795",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0144102848415,
          "to_latitude": 21.014415349084924,
          "to_longitude": 105.83110679903734,
          "from_longitude": 105.83086544019095
        }
      },
      {
        "id": 3789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83070179245998,
            21.014426360189066,
            105.83119193817348,
            21.014622921559255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83119193817348,
              21.014622921559255
            ],
            [
              105.83110548287699,
              21.014535795188316
            ],
            [
              105.83109247007712,
              21.01452315579411
            ],
            [
              105.83108061977326,
              21.01451164517126
            ],
            [
              105.83102245830075,
              21.014457418717488
            ],
            [
              105.83070179245998,
              21.014426360189066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_230_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3796",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014622921559255,
          "to_latitude": 21.014426360189066,
          "to_longitude": 105.83070179245998,
          "from_longitude": 105.83119193817348
        }
      },
      {
        "id": 3790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109247007712,
            21.01450708297539,
            105.83110465813938,
            21.01452315579411
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83110465813938,
              21.01450708297539
            ],
            [
              105.83110421980815,
              21.014507659567894
            ],
            [
              105.83110259353057,
              21.014509805446423
            ],
            [
              105.83109247007712,
              21.01452315579411
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_230_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3797",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01450708297539,
          "to_latitude": 21.01452315579411,
          "to_longitude": 105.83109247007712,
          "from_longitude": 105.83110465813938
        }
      },
      {
        "id": 3791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110860821822,
            21.014510279161506,
            105.83122442286424,
            21.01462089046719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83110860821822,
              21.014510279161506
            ],
            [
              105.83119067144915,
              21.014588747897164
            ],
            [
              105.83121930241678,
              21.014616013680378
            ],
            [
              105.83122442286424,
              21.01462089046719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_229_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3798",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014510279161506,
          "to_latitude": 21.01462089046719,
          "to_longitude": 105.83122442286424,
          "from_longitude": 105.83110860821822
        }
      },
      {
        "id": 3792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83121562190503,
            21.014616013680378,
            105.83121930241678,
            21.014619455788935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83121930241678,
              21.014616013680378
            ],
            [
              105.8312180947192,
              21.01461714319297
            ],
            [
              105.83121562190503,
              21.014619455788935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_229_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3799",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014616013680378,
          "to_latitude": 21.014619455788935,
          "to_longitude": 105.83121562190503,
          "from_longitude": 105.83121930241678
        }
      },
      {
        "id": 3793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83044069157259,
            21.014405075869465,
            105.83069094304555,
            21.01442292038903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044069157259,
              21.014405075869465
            ],
            [
              105.83047826044515,
              21.014412849907007
            ],
            [
              105.83053165335792,
              21.014413553919972
            ],
            [
              105.83058563281489,
              21.014415431955396
            ],
            [
              105.83060934795547,
              21.014417467662447
            ],
            [
              105.83064048849045,
              21.01441955021088
            ],
            [
              105.83069094304555,
              21.01442292038903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_225_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3800",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014405075869465,
          "to_latitude": 21.01442292038903,
          "to_longitude": 105.83069094304555,
          "from_longitude": 105.83044069157259
        }
      },
      {
        "id": 3794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8305088544969,
            21.014238912771873,
            105.83061491607214,
            21.01424956520025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83061491607214,
              21.01424956520025
            ],
            [
              105.8305088544969,
              21.014238912771873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_225_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3801",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01424956520025,
          "to_latitude": 21.014238912771873,
          "to_longitude": 105.8305088544969,
          "from_longitude": 105.83061491607214
        }
      },
      {
        "id": 3795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83063579434666,
            21.014046420908205,
            105.83075628822866,
            21.01405284705171
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83063579434666,
              21.014046420908205
            ],
            [
              105.83075628822866,
              21.01405284705171
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_225_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3802",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014046420908205,
          "to_latitude": 21.01405284705171,
          "to_longitude": 105.83075628822866,
          "from_longitude": 105.83063579434666
        }
      },
      {
        "id": 3796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153503082004,
            21.01432685684699,
            105.83154196323584,
            21.014333413799697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83153503082004,
              21.01432685684699
            ],
            [
              105.83153695225964,
              21.014328676487185
            ],
            [
              105.8315408096025,
              21.01433232201939
            ],
            [
              105.83154196323584,
              21.014333413799697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_234_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3803",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01432685684699,
          "to_latitude": 21.014333413799697,
          "to_longitude": 105.83154196323584,
          "from_longitude": 105.83153503082004
        }
      },
      {
        "id": 3797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83129441165127,
            21.0141319246691,
            105.8317683912277,
            21.01458576801651
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83129441165127,
              21.01458576801651
            ],
            [
              105.83133094262907,
              21.014542502965732
            ],
            [
              105.83136726561831,
              21.0144950380232
            ],
            [
              105.83144498845536,
              21.01442455348427
            ],
            [
              105.83151885999469,
              21.014355691862004
            ],
            [
              105.83154196323584,
              21.014333413799697
            ],
            [
              105.83166055453722,
              21.014219058478066
            ],
            [
              105.83173228428917,
              21.01415638168415
            ],
            [
              105.8317683912277,
              21.0141319246691
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_234_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3804",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01458576801651,
          "to_latitude": 21.0141319246691,
          "to_longitude": 105.8317683912277,
          "from_longitude": 105.83129441165127
        }
      },
      {
        "id": 3798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83173254483529,
            21.013504901194,
            105.83175923402139,
            21.013523749293167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83175923402139,
              21.013523749293167
            ],
            [
              105.83175430024113,
              21.013520266188596
            ],
            [
              105.83173254483529,
              21.013504901194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_235_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3805",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013523749293167,
          "to_latitude": 21.013504901194,
          "to_longitude": 105.83173254483529,
          "from_longitude": 105.83175923402139
        }
      },
      {
        "id": 3799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83191210864801,
            21.012938753801603,
            105.83225406199936,
            21.013298074402716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83191210864801,
              21.013298074402716
            ],
            [
              105.83197158398254,
              21.013232976273752
            ],
            [
              105.83201893374415,
              21.01317906063943
            ],
            [
              105.83209192382425,
              21.01310665161199
            ],
            [
              105.83209850849028,
              21.013100118897462
            ],
            [
              105.83214073063826,
              21.013053744948092
            ],
            [
              105.83216560250213,
              21.013026427191452
            ],
            [
              105.83225406199936,
              21.012938753801603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_237_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3806",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013298074402716,
          "to_latitude": 21.012938753801603,
          "to_longitude": 105.83225406199936,
          "from_longitude": 105.83191210864801
        }
      },
      {
        "id": 3800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83214073063826,
            21.013053744948092,
            105.83217780104016,
            21.01308667497671
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217780104016,
              21.01308667497671
            ],
            [
              105.83217522580156,
              21.013084388863835
            ],
            [
              105.83215841805459,
              21.0130694567555
            ],
            [
              105.83214073063826,
              21.013053744948092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_237_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3807",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01308667497671,
          "to_latitude": 21.013053744948092,
          "to_longitude": 105.83214073063826,
          "from_longitude": 105.83217780104016
        }
      },
      {
        "id": 3801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83227101773628,
            21.012712465972456,
            105.83245884086264,
            21.01292123771283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227101773628,
              21.01292123771283
            ],
            [
              105.83234939530826,
              21.01284241913405
            ],
            [
              105.83235999693994,
              21.01282860753769
            ],
            [
              105.83238782705179,
              21.012792351412813
            ],
            [
              105.83245884086264,
              21.012712465972456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_267_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3808",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01292123771283,
          "to_latitude": 21.012712465972456,
          "to_longitude": 105.83245884086264,
          "from_longitude": 105.83227101773628
        }
      },
      {
        "id": 3802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83235999693994,
            21.01282860753769,
            105.83239452313386,
            21.01285392403567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83239452313386,
              21.01285392403567
            ],
            [
              105.83239279524076,
              21.012852658941277
            ],
            [
              105.8323762367202,
              21.01284051569746
            ],
            [
              105.83235999693994,
              21.01282860753769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_267_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3809",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01285392403567,
          "to_latitude": 21.01282860753769,
          "to_longitude": 105.83235999693994,
          "from_longitude": 105.83239452313386
        }
      },
      {
        "id": 3803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83205927805855,
            21.01319300153367,
            105.83211000883493,
            21.01323826334517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83205927805855,
              21.01319300153367
            ],
            [
              105.83206113704118,
              21.013194661602963
            ],
            [
              105.83211000883493,
              21.01323826334517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_236_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3810",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01319300153367,
          "to_latitude": 21.01323826334517,
          "to_longitude": 105.83211000883493,
          "from_longitude": 105.83205927805855
        }
      },
      {
        "id": 3804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8327249968912,
            21.01334917942456,
            105.83299270015864,
            21.013707655285064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299270015864,
              21.01334917942456
            ],
            [
              105.83291447057948,
              21.013439216527544
            ],
            [
              105.83290447854588,
              21.013450207110775
            ],
            [
              105.83290428063678,
              21.01345042485363
            ],
            [
              105.83289936158577,
              21.013455835768333
            ],
            [
              105.83286765076653,
              21.013490475229997
            ],
            [
              105.83285841504774,
              21.013500471270508
            ],
            [
              105.83283102918614,
              21.01353011639733
            ],
            [
              105.83281761689089,
              21.013544635456693
            ],
            [
              105.8327249968912,
              21.013645273616117
            ],
            [
              105.83279780207647,
              21.013707655285064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_239_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3811",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01334917942456,
          "to_latitude": 21.013707655285064,
          "to_longitude": 105.83279780207647,
          "from_longitude": 105.83299270015864
        }
      },
      {
        "id": 3805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281989269979,
            21.013521052851534,
            105.83283102918614,
            21.01353011639733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281989269979,
              21.013521052851534
            ],
            [
              105.83282288239884,
              21.013523485957446
            ],
            [
              105.8328288134917,
              21.01352831447013
            ],
            [
              105.83283102918614,
              21.01353011639733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_239_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3812",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013521052851534,
          "to_latitude": 21.01353011639733,
          "to_longitude": 105.83283102918614,
          "from_longitude": 105.83281989269979
        }
      },
      {
        "id": 3806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83266912186208,
            21.013340324095054,
            105.832973535668,
            21.013743233581362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832973535668,
              21.013340324095054
            ],
            [
              105.83284975143008,
              21.013476811246065
            ],
            [
              105.83281396812684,
              21.01351549269427
            ],
            [
              105.83280771754548,
              21.013522249606833
            ],
            [
              105.83280659020268,
              21.013523468156226
            ],
            [
              105.83276176386038,
              21.013572724558692
            ],
            [
              105.83271560076925,
              21.01362741677841
            ],
            [
              105.83270747526505,
              21.01363917679541
            ],
            [
              105.83273517143932,
              21.013670622508428
            ],
            [
              105.83266912186208,
              21.013743233581362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_240_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3813",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013340324095054,
          "to_latitude": 21.013743233581362,
          "to_longitude": 105.83266912186208,
          "from_longitude": 105.832973535668
        }
      },
      {
        "id": 3807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83302526547814,
            21.01333937805986,
            105.83340099972267,
            21.013663113630425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83302526547814,
              21.01333937805986
            ],
            [
              105.83306820059946,
              21.013375876717454
            ],
            [
              105.83322011228563,
              21.013526908076145
            ],
            [
              105.83322090254028,
              21.013527693624813
            ],
            [
              105.8333564259834,
              21.013623963994245
            ],
            [
              105.8333732936981,
              21.01363840342675
            ],
            [
              105.83340099972267,
              21.013663113630425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_241_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3814",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01333937805986,
          "to_latitude": 21.013663113630425,
          "to_longitude": 105.83340099972267,
          "from_longitude": 105.83302526547814
        }
      },
      {
        "id": 3808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83322011228563,
            21.013509596586843,
            105.83323769050938,
            21.013526908076145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83323769050938,
              21.013509596586843
            ],
            [
              105.83322590408615,
              21.013521203846423
            ],
            [
              105.83322261402647,
              21.013524443568684
            ],
            [
              105.83322011228563,
              21.013526908076145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_241_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3815",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013509596586843,
          "to_latitude": 21.013526908076145,
          "to_longitude": 105.83322011228563,
          "from_longitude": 105.83323769050938
        }
      },
      {
        "id": 3809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334052544199,
            21.013678023098723,
            105.8337508152736,
            21.013990092355638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334052544199,
              21.013678023098723
            ],
            [
              105.83349648062512,
              21.013758732790375
            ],
            [
              105.83352676167186,
              21.01378567667778
            ],
            [
              105.83359611562152,
              21.013852861992476
            ],
            [
              105.83362030745471,
              21.013876296860396
            ],
            [
              105.83371872321287,
              21.013958542809377
            ],
            [
              105.83373462339459,
              21.01397351804077
            ],
            [
              105.8337508152736,
              21.013990092355638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_242_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3816",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013678023098723,
          "to_latitude": 21.013990092355638,
          "to_longitude": 105.8337508152736,
          "from_longitude": 105.8334052544199
        }
      },
      {
        "id": 3810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359611562152,
            21.013837585658777,
            105.83360682641904,
            21.013852861992476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83360682641904,
              21.013837585658777
            ],
            [
              105.83359875919795,
              21.01384909072141
            ],
            [
              105.83359611562152,
              21.013852861992476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_242_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3817",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013837585658777,
          "to_latitude": 21.013852861992476,
          "to_longitude": 105.83359611562152,
          "from_longitude": 105.83360682641904
        }
      },
      {
        "id": 3811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376768354314,
            21.01400894399703,
            105.83385728659796,
            21.01412793016933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376768354314,
              21.01400894399703
            ],
            [
              105.8338055919516,
              21.01404698992558
            ],
            [
              105.83381115792348,
              21.014052575287575
            ],
            [
              105.83381934327964,
              21.01406145903469
            ],
            [
              105.83383343200677,
              21.014076750230124
            ],
            [
              105.8338532824322,
              21.014119338448037
            ],
            [
              105.83385728659796,
              21.01412793016933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_243_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3818",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01400894399703,
          "to_latitude": 21.01412793016933,
          "to_longitude": 105.83385728659796,
          "from_longitude": 105.83376768354314
        }
      },
      {
        "id": 3812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366166447323,
            21.01404698992558,
            105.8338055919516,
            21.01420135273631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338055919516,
              21.01404698992558
            ],
            [
              105.8337146614874,
              21.01414364884826
            ],
            [
              105.83366166447323,
              21.01420135273631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_243_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3819",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01404698992558,
          "to_latitude": 21.01420135273631,
          "to_longitude": 105.83366166447323,
          "from_longitude": 105.8338055919516
        }
      },
      {
        "id": 3813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338532824322,
            21.014101163682692,
            105.83387191108264,
            21.014119338448037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338532824322,
              21.014119338448037
            ],
            [
              105.83385764133054,
              21.01411508547644
            ],
            [
              105.83386207007081,
              21.01411076442059
            ],
            [
              105.83386649881587,
              21.01410644426783
            ],
            [
              105.83387191108264,
              21.014101163682692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_243_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.3820",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014119338448037,
          "to_latitude": 21.014101163682692,
          "to_longitude": 105.83387191108264,
          "from_longitude": 105.8338532824322
        }
      },
      {
        "id": 3814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83383436561725,
            21.012900634382632,
            105.83442715146465,
            21.013524808402224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83383436561725,
              21.013524808402224
            ],
            [
              105.83383585121288,
              21.01352314731678
            ],
            [
              105.83387368611355,
              21.01349001856848
            ],
            [
              105.83395344865674,
              21.01341472951227
            ],
            [
              105.83401466324113,
              21.013358108870122
            ],
            [
              105.83410837925524,
              21.013256692404486
            ],
            [
              105.83422171383407,
              21.01313526720473
            ],
            [
              105.83432163216646,
              21.013020903197763
            ],
            [
              105.83442715146465,
              21.012900634382632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_250_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3821",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013524808402224,
          "to_latitude": 21.012900634382632,
          "to_longitude": 105.83442715146465,
          "from_longitude": 105.83383436561725
        }
      },
      {
        "id": 3815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83381582324174,
            21.01288611002249,
            105.83440846812388,
            21.013506575549414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83381582324174,
              21.013506575549414
            ],
            [
              105.83381836495907,
              21.013504382708245
            ],
            [
              105.83382103154827,
              21.01350181170708
            ],
            [
              105.8338773016878,
              21.013447569147832
            ],
            [
              105.83402016104469,
              21.013303683393723
            ],
            [
              105.83408838459061,
              21.013228290065346
            ],
            [
              105.83418657006509,
              21.013126618616507
            ],
            [
              105.83433793746624,
              21.0129611968513
            ],
            [
              105.83440846812388,
              21.01288611002249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_249_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3822",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013506575549414,
          "to_latitude": 21.01288611002249,
          "to_longitude": 105.83440846812388,
          "from_longitude": 105.83381582324174
        }
      },
      {
        "id": 3816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338733747797,
            21.013341757909778,
            105.83430652677407,
            21.013558085317985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338733747797,
              21.013558085317985
            ],
            [
              105.8338756002549,
              21.013556453122124
            ],
            [
              105.83394025973251,
              21.013509032128617
            ],
            [
              105.83398933150048,
              21.013457856941514
            ],
            [
              105.83401892566219,
              21.013426995908063
            ],
            [
              105.83410768639024,
              21.013341757909778
            ],
            [
              105.8341958023846,
              21.013416328800865
            ],
            [
              105.8342645170309,
              21.01347145350541
            ],
            [
              105.83430652677407,
              21.01350650760498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_251_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3823",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013558085317985,
          "to_latitude": 21.01350650760498,
          "to_longitude": 105.83430652677407,
          "from_longitude": 105.8338733747797
        }
      },
      {
        "id": 3817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398933150048,
            21.013457856941514,
            105.83414185424036,
            21.013595951174697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398933150048,
              21.013457856941514
            ],
            [
              105.83403801231393,
              21.01349640730935
            ],
            [
              105.83410440439901,
              21.013552833268992
            ],
            [
              105.83413441112712,
              21.013587865867244
            ],
            [
              105.83414185424036,
              21.013595951174697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_251_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3824",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013457856941514,
          "to_latitude": 21.013595951174697,
          "to_longitude": 105.83414185424036,
          "from_longitude": 105.83398933150048
        }
      },
      {
        "id": 3818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388240803087,
            21.013628483556456,
            105.83404390282043,
            21.013811560189325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400049393408,
              21.013811560189325
            ],
            [
              105.83404390282043,
              21.013760539195818
            ],
            [
              105.83393683967256,
              21.013672299567297
            ],
            [
              105.83388240803087,
              21.013628483556456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_252_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3825",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013811560189325,
          "to_latitude": 21.013628483556456,
          "to_longitude": 105.83388240803087,
          "from_longitude": 105.83400049393408
        }
      },
      {
        "id": 3819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83352118320335,
            21.013316127275548,
            105.83386388154247,
            21.01360221012358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386388154247,
              21.01360221012358
            ],
            [
              105.83382891516452,
              21.013569767844128
            ],
            [
              105.83376730242891,
              21.01351307177327
            ],
            [
              105.83375755156342,
              21.01350409904861
            ],
            [
              105.83370891234503,
              21.013462462978307
            ],
            [
              105.83366209399468,
              21.013422370615018
            ],
            [
              105.83352118320335,
              21.013316127275548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_248_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3826",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01360221012358,
          "to_latitude": 21.013316127275548,
          "to_longitude": 105.83352118320335,
          "from_longitude": 105.83386388154247
        }
      },
      {
        "id": 3820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376730242891,
            21.013485121629706,
            105.8337938125495,
            21.01351307177327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337938125495,
              21.013485121629706
            ],
            [
              105.83379346157658,
              21.013485490962207
            ],
            [
              105.83378175241027,
              21.013497836718454
            ],
            [
              105.83376730242891,
              21.01351307177327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_248_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3827",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013485121629706,
          "to_latitude": 21.01351307177327,
          "to_longitude": 105.83376730242891,
          "from_longitude": 105.8337938125495
        }
      },
      {
        "id": 3821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315785897987,
            21.013012418898484,
            105.83350935588531,
            21.013305305870972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83350935588531,
              21.013305305870972
            ],
            [
              105.83347199232486,
              21.013272997191663
            ],
            [
              105.83343115791813,
              21.013235276219472
            ],
            [
              105.83339996335938,
              21.013210269685803
            ],
            [
              105.83335929015826,
              21.013177665578578
            ],
            [
              105.83329882098779,
              21.013129192105016
            ],
            [
              105.83322524703114,
              21.01307056911577
            ],
            [
              105.83315785897987,
              21.013012418898484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_245_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3828",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013305305870972,
          "to_latitude": 21.013012418898484,
          "to_longitude": 105.83315785897987,
          "from_longitude": 105.83350935588531
        }
      },
      {
        "id": 3822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335929015826,
            21.013146338377705,
            105.83338652991364,
            21.013177665578578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83338652991364,
              21.013146338377705
            ],
            [
              105.83338598604763,
              21.01314696246043
            ],
            [
              105.8333740613141,
              21.013160677631106
            ],
            [
              105.83335929015826,
              21.013177665578578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_245_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3829",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013146338377705,
          "to_latitude": 21.013177665578578,
          "to_longitude": 105.83335929015826,
          "from_longitude": 105.83338652991364
        }
      },
      {
        "id": 3823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265440409065,
            21.012605266286087,
            105.83314076437213,
            21.013009762220218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314076437213,
              21.013009762220218
            ],
            [
              105.83312339969501,
              21.01299779370857
            ],
            [
              105.83311789298624,
              21.01299386093331
            ],
            [
              105.83305698482636,
              21.01294443029285
            ],
            [
              105.83297060272659,
              21.01286616423352
            ],
            [
              105.83296520272862,
              21.012860832738067
            ],
            [
              105.83296101370108,
              21.01285669840997
            ],
            [
              105.83295489170347,
              21.012850572900945
            ],
            [
              105.8329455631382,
              21.01284124038192
            ],
            [
              105.83290320360459,
              21.012809277574767
            ],
            [
              105.83281156247656,
              21.01272990362511
            ],
            [
              105.83278185897026,
              21.012704354060002
            ],
            [
              105.83271270180221,
              21.012652008309363
            ],
            [
              105.83265994035747,
              21.012608116868822
            ],
            [
              105.83265440409065,
              21.012605266286087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_268_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3830",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013009762220218,
          "to_latitude": 21.012605266286087,
          "to_longitude": 105.83265440409065,
          "from_longitude": 105.83314076437213
        }
      },
      {
        "id": 3824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83295489170347,
            21.012815675027355,
            105.83298717135536,
            21.012850572900945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83298717135536,
              21.012815675027355
            ],
            [
              105.83298375874664,
              21.01281936334458
            ],
            [
              105.83296978323752,
              21.01283447317179
            ],
            [
              105.83295489170347,
              21.012850572900945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_268_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3831",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012815675027355,
          "to_latitude": 21.012850572900945,
          "to_longitude": 105.83295489170347,
          "from_longitude": 105.83298717135536
        }
      },
      {
        "id": 3825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299240962532,
            21.012813534932302,
            105.83335220163113,
            21.013113227190278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299240962532,
              21.012813534932302
            ],
            [
              105.83305014901896,
              21.01286536743403
            ],
            [
              105.83312622060562,
              21.012928785239584
            ],
            [
              105.83318751860492,
              21.012977740655927
            ],
            [
              105.83321385072352,
              21.01300034949681
            ],
            [
              105.83322031583397,
              21.013005899767762
            ],
            [
              105.83324135938814,
              21.01302315128106
            ],
            [
              105.83331106203575,
              21.01308029477408
            ],
            [
              105.83335220163113,
              21.013113227190278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_244_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3832",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012813534932302,
          "to_latitude": 21.013113227190278,
          "to_longitude": 105.83335220163113,
          "from_longitude": 105.83299240962532
        }
      },
      {
        "id": 3826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332359530617,
            21.01302315128106,
            105.83324135938814,
            21.013028979171626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83324135938814,
              21.01302315128106
            ],
            [
              105.83324031426379,
              21.013024277301593
            ],
            [
              105.83323752600619,
              21.01302728424417
            ],
            [
              105.8332359530617,
              21.013028979171626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_244_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3833",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01302315128106,
          "to_latitude": 21.013028979171626,
          "to_longitude": 105.8332359530617,
          "from_longitude": 105.83324135938814
        }
      },
      {
        "id": 3827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353327297084,
            21.013267172742644,
            105.83353846049525,
            21.013272734022358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353846049525,
              21.013267172742644
            ],
            [
              105.83353656142742,
              21.013269207979885
            ],
            [
              105.83353395571929,
              21.013272002678224
            ],
            [
              105.83353327297084,
              21.013272734022358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_247_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3834",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013267172742644,
          "to_latitude": 21.013272734022358,
          "to_longitude": 105.83353327297084,
          "from_longitude": 105.83353846049525
        }
      },
      {
        "id": 3828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83349643426044,
            21.013139632904185,
            105.83374989980464,
            21.01324122574402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83349643426044,
              21.01324122574402
            ],
            [
              105.83349969449579,
              21.01323867983963
            ],
            [
              105.83350179487411,
              21.01323703922918
            ],
            [
              105.83350723498354,
              21.013232790905455
            ],
            [
              105.83351684806128,
              21.013222337757078
            ],
            [
              105.83355907273472,
              21.013177358938066
            ],
            [
              105.83359689361113,
              21.013139632904185
            ],
            [
              105.83363750460842,
              21.013171249130192
            ],
            [
              105.83369878322236,
              21.01322549644351
            ],
            [
              105.83370351242942,
              21.013228684235397
            ],
            [
              105.83370905484183,
              21.01322328650192
            ],
            [
              105.83374989980464,
              21.01318206910399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_246_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3835",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01324122574402,
          "to_latitude": 21.01318206910399,
          "to_longitude": 105.83374989980464,
          "from_longitude": 105.83349643426044
        }
      },
      {
        "id": 3829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364556307964,
            21.013091187524715,
            105.83381313878998,
            21.01323370819022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364556307964,
              21.013091187524715
            ],
            [
              105.83368196374731,
              21.01312297701431
            ],
            [
              105.83372964190632,
              21.013164097549012
            ],
            [
              105.83374989980464,
              21.01318206910399
            ],
            [
              105.8337696892969,
              21.01319797185687
            ],
            [
              105.83380209572688,
              21.013224498832443
            ],
            [
              105.83381313878998,
              21.01323370819022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_246_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3836",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013091187524715,
          "to_latitude": 21.01323370819022,
          "to_longitude": 105.83381313878998,
          "from_longitude": 105.83364556307964
        }
      },
      {
        "id": 3830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336080620023,
            21.013120533684905,
            105.83371868162736,
            21.01341624947408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336080620023,
              21.013120533684905
            ],
            [
              105.83342079994563,
              21.01317138584985
            ],
            [
              105.83347233842456,
              21.013212712452003
            ],
            [
              105.83349628955892,
              21.01323249497666
            ],
            [
              105.83350179487411,
              21.01323703922918
            ],
            [
              105.83351630082022,
              21.01324901538224
            ],
            [
              105.83353846049525,
              21.013267172742644
            ],
            [
              105.83356357904546,
              21.013287753408278
            ],
            [
              105.83356727425245,
              21.013290780076307
            ],
            [
              105.83371868162736,
              21.01341624947408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_247_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3837",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013120533684905,
          "to_latitude": 21.01341624947408,
          "to_longitude": 105.83371868162736,
          "from_longitude": 105.83336080620023
        }
      },
      {
        "id": 3831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372883582437,
            21.01342562125677,
            105.83382103154827,
            21.01350181170708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83382103154827,
              21.01350181170708
            ],
            [
              105.83381946990865,
              21.01350052142598
            ],
            [
              105.83372883582437,
              21.01342562125677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_249_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3838",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01350181170708,
          "to_latitude": 21.01342562125677,
          "to_longitude": 105.83372883582437,
          "from_longitude": 105.83382103154827
        }
      },
      {
        "id": 3832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83310350955689,
            21.012669116250994,
            105.83346908824893,
            21.013035797428145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83310350955689,
              21.012669116250994
            ],
            [
              105.83311031412505,
              21.012676730540658
            ],
            [
              105.83311787367902,
              21.01268588203539
            ],
            [
              105.83315689722001,
              21.012729610276956
            ],
            [
              105.83319203468372,
              21.012764341545278
            ],
            [
              105.83326389035513,
              21.012829175417327
            ],
            [
              105.83324011881557,
              21.012856084198457
            ],
            [
              105.83331302486913,
              21.01291397342503
            ],
            [
              105.83334142522365,
              21.01293452269897
            ],
            [
              105.83346305668034,
              21.013032275493153
            ],
            [
              105.83346908824893,
              21.013035797428145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_269_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3839",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012669116250994,
          "to_latitude": 21.013035797428145,
          "to_longitude": 105.83346908824893,
          "from_longitude": 105.83310350955689
        }
      },
      {
        "id": 3833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363443881723,
            21.012739502335805,
            105.83388390747275,
            21.01306952882052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388390747275,
              21.012739502335805
            ],
            [
              105.83386872291433,
              21.01276049273509
            ],
            [
              105.83386872990629,
              21.012765903921817
            ],
            [
              105.8338603060852,
              21.01278055938606
            ],
            [
              105.83383729188152,
              21.012819097170755
            ],
            [
              105.83380740855738,
              21.012859703580766
            ],
            [
              105.8338011237209,
              21.01286824546877
            ],
            [
              105.83377064380637,
              21.012901896350808
            ],
            [
              105.83373683866259,
              21.012936437843496
            ],
            [
              105.83371250391724,
              21.012955112036067
            ],
            [
              105.83366584925865,
              21.0129937782799
            ],
            [
              105.8336354037099,
              21.013019948460858
            ],
            [
              105.83363443881723,
              21.013021144542165
            ],
            [
              105.83364916722722,
              21.013038189396507
            ],
            [
              105.83367732557117,
              21.01306952882052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_274_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3840",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012739502335805,
          "to_latitude": 21.01306952882052,
          "to_longitude": 105.83367732557117,
          "from_longitude": 105.83388390747275
        }
      },
      {
        "id": 3834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83380740855738,
            21.012859703580766,
            105.83395402404722,
            21.01312510219752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83380740855738,
              21.012859703580766
            ],
            [
              105.83386762482668,
              21.012915105848514
            ],
            [
              105.83388453088175,
              21.012933334073256
            ],
            [
              105.83383605658396,
              21.012977238953077
            ],
            [
              105.83387888762402,
              21.01303289621985
            ],
            [
              105.83392327276917,
              21.013092463144204
            ],
            [
              105.83395402404722,
              21.01312510219752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_274_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3841",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012859703580766,
          "to_latitude": 21.01312510219752,
          "to_longitude": 105.83395402404722,
          "from_longitude": 105.83380740855738
        }
      },
      {
        "id": 3835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83391273974867,
            21.012672077710388,
            105.83422228341942,
            21.012851258885355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83422228341942,
              21.012851258885355
            ],
            [
              105.83418289657088,
              21.012819926732412
            ],
            [
              105.83409312655313,
              21.012749008440434
            ],
            [
              105.8340020989916,
              21.012680094195737
            ],
            [
              105.83399001121478,
              21.012672077710388
            ],
            [
              105.83395966760476,
              21.01270652731073
            ],
            [
              105.83392862093595,
              21.012732518940016
            ],
            [
              105.83391273974867,
              21.012743435476118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_273_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3842",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012851258885355,
          "to_latitude": 21.012743435476118,
          "to_longitude": 105.83391273974867,
          "from_longitude": 105.83422228341942
        }
      },
      {
        "id": 3836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83386872990629,
            21.012765903921817,
            105.8340810079739,
            21.01293233851363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386872990629,
              21.012765903921817
            ],
            [
              105.83393958348849,
              21.012813943329725
            ],
            [
              105.83400638434601,
              21.012872327336375
            ],
            [
              105.8340810079739,
              21.01293233851363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_274_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3843",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012765903921817,
          "to_latitude": 21.01293233851363,
          "to_longitude": 105.8340810079739,
          "from_longitude": 105.83386872990629
        }
      },
      {
        "id": 3837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8331132393943,
            21.012425437281017,
            105.83335391484259,
            21.012675358670027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331132393943,
              21.012675358670027
            ],
            [
              105.83315956653752,
              21.012628978829746
            ],
            [
              105.83321249486886,
              21.0125747293673
            ],
            [
              105.83321597167239,
              21.01257099737652
            ],
            [
              105.83325691390986,
              21.01252703652401
            ],
            [
              105.83329234355283,
              21.012490034009282
            ],
            [
              105.83333025622143,
              21.012450321393157
            ],
            [
              105.83334608384169,
              21.01243374195158
            ],
            [
              105.83335391484259,
              21.012425437281017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_270_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3844",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012675358670027,
          "to_latitude": 21.012425437281017,
          "to_longitude": 105.83335391484259,
          "from_longitude": 105.8331132393943
        }
      },
      {
        "id": 3838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331960543578,
            21.01244158246624,
            105.83333025622143,
            21.012450321393157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83331960543578,
              21.01244158246624
            ],
            [
              105.83332205312689,
              21.01244359099311
            ],
            [
              105.8333267910038,
              21.012447479656945
            ],
            [
              105.83333025622143,
              21.012450321393157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_270_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3845",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01244158246624,
          "to_latitude": 21.012450321393157,
          "to_longitude": 105.83333025622143,
          "from_longitude": 105.83331960543578
        }
      },
      {
        "id": 3839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83337634411014,
            21.01241855865722,
            105.83386669073936,
            21.012741002066665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83337634411014,
              21.01241855865722
            ],
            [
              105.83345760535474,
              21.012479072323423
            ],
            [
              105.83349202917529,
              21.012504817226816
            ],
            [
              105.83352498029176,
              21.012533155270734
            ],
            [
              105.83355803635355,
              21.012565317048644
            ],
            [
              105.83358819962051,
              21.012592227189803
            ],
            [
              105.83363305328398,
              21.012612209872927
            ],
            [
              105.83364435878943,
              21.012617245961547
            ],
            [
              105.83365320146167,
              21.012621184955844
            ],
            [
              105.83367766822877,
              21.0126292184902
            ],
            [
              105.83369110130086,
              21.012637161568062
            ],
            [
              105.83369741222022,
              21.012640929610317
            ],
            [
              105.83375497583324,
              21.012675296164463
            ],
            [
              105.83375925702555,
              21.012677837635263
            ],
            [
              105.83380032594653,
              21.01270221133098
            ],
            [
              105.83386669073936,
              21.012741002066665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_272_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3846",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01241855865722,
          "to_latitude": 21.012741002066665,
          "to_longitude": 105.83386669073936,
          "from_longitude": 105.83337634411014
        }
      },
      {
        "id": 3840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364435878943,
            21.01260373666206,
            105.83365352774516,
            21.012617245961547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83365352774516,
              21.01260373666206
            ],
            [
              105.83365332641725,
              21.01260403119661
            ],
            [
              105.83364992408875,
              21.012609046313937
            ],
            [
              105.83364435878943,
              21.012617245961547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_272_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3847",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01260373666206,
          "to_latitude": 21.012617245961547,
          "to_longitude": 105.83364435878943,
          "from_longitude": 105.83365352774516
        }
      },
      {
        "id": 3841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340889743279,
            21.01241096747513,
            105.83382335282647,
            21.012698318490884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83340889743279,
              21.01241096747513
            ],
            [
              105.83347192934465,
              21.012467661264076
            ],
            [
              105.83351347090849,
              21.012503022257043
            ],
            [
              105.83355054248008,
              21.012537347460896
            ],
            [
              105.83359261497908,
              21.012575636797497
            ],
            [
              105.83363204402991,
              21.01259088154505
            ],
            [
              105.83364903625007,
              21.012597450544554
            ],
            [
              105.83368268227059,
              21.012614081161285
            ],
            [
              105.83369721153397,
              21.012621261061852
            ],
            [
              105.83373698241455,
              21.012645391843805
            ],
            [
              105.83378637012486,
              21.01267446309903
            ],
            [
              105.83382335282647,
              21.012698318490884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_271_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3848",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01241096747513,
          "to_latitude": 21.012698318490884,
          "to_longitude": 105.83382335282647,
          "from_longitude": 105.83340889743279
        }
      },
      {
        "id": 3842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364903625007,
            21.012493016224177,
            105.83371794477388,
            21.012597450544554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83371794477388,
              21.012493016224177
            ],
            [
              105.83369586095894,
              21.012530207239514
            ],
            [
              105.83368483540816,
              21.012550102406884
            ],
            [
              105.83364903625007,
              21.012597450544554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_271_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3849",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012493016224177,
          "to_latitude": 21.012597450544554,
          "to_longitude": 105.83364903625007,
          "from_longitude": 105.83371794477388
        }
      },
      {
        "id": 3843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362936804114,
            21.01259088154505,
            105.83363204402991,
            21.01259660574791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363204402991,
              21.01259088154505
            ],
            [
              105.8336305829337,
              21.01259400753972
            ],
            [
              105.83362962754578,
              21.012596048089847
            ],
            [
              105.83362936804114,
              21.01259660574791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_271_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3850",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01259088154505,
          "to_latitude": 21.01259660574791,
          "to_longitude": 105.83362936804114,
          "from_longitude": 105.83363204402991
        }
      },
      {
        "id": 3844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358755283058,
            21.01244362634986,
            105.83369586095894,
            21.012530207239514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369586095894,
              21.012530207239514
            ],
            [
              105.83366591707248,
              21.01251352235673
            ],
            [
              105.83359609775768,
              21.01245336099511
            ],
            [
              105.83358755283058,
              21.01244362634986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_271_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3851",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012530207239514,
          "to_latitude": 21.01244362634986,
          "to_longitude": 105.83358755283058,
          "from_longitude": 105.83369586095894
        }
      },
      {
        "id": 3845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83339169745096,
            21.012184780141574,
            105.83359731605515,
            21.012386040514393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339169745096,
              21.012386040514393
            ],
            [
              105.83343976663032,
              21.01233605723284
            ],
            [
              105.83349452800327,
              21.0122784883879
            ],
            [
              105.83353489552049,
              21.012247804986377
            ],
            [
              105.8335756904146,
              21.012206615877126
            ],
            [
              105.83359731605515,
              21.012184780141574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_275_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3852",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012386040514393,
          "to_latitude": 21.012184780141574,
          "to_longitude": 105.83359731605515,
          "from_longitude": 105.83339169745096
        }
      },
      {
        "id": 3846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368104821989,
            21.012102106872423,
            105.83378649492258,
            21.012189984312784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368104821989,
              21.012102106872423
            ],
            [
              105.83378649492258,
              21.012189984312784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_276_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3853",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012102106872423,
          "to_latitude": 21.012189984312784,
          "to_longitude": 105.83378649492258,
          "from_longitude": 105.83368104821989
        }
      },
      {
        "id": 3847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83371359988757,
            21.01215277170558,
            105.8338312297096,
            21.012257284932232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83371359988757,
              21.012257284932232
            ],
            [
              105.83378649492258,
              21.012189984312784
            ],
            [
              105.8338312297096,
              21.01215277170558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_276_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3854",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012257284932232,
          "to_latitude": 21.01215277170558,
          "to_longitude": 105.8338312297096,
          "from_longitude": 105.83371359988757
        }
      },
      {
        "id": 3848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83354827830385,
            21.012612209872927,
            105.83363305328398,
            21.01270080616183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363305328398,
              21.012612209872927
            ],
            [
              105.83357627185187,
              21.012674977686704
            ],
            [
              105.83354827830385,
              21.01270080616183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_272_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3855",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012612209872927,
          "to_latitude": 21.01270080616183,
          "to_longitude": 105.83354827830385,
          "from_longitude": 105.83363305328398
        }
      },
      {
        "id": 3849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356690214876,
            21.011695652644253,
            105.83383777334276,
            21.012166240510275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356690214876,
              21.012166240510275
            ],
            [
              105.83362694819816,
              21.01210088703157
            ],
            [
              105.8336528559837,
              21.01207138541241
            ],
            [
              105.83368564017029,
              21.012029884171525
            ],
            [
              105.83369106184877,
              21.012023020193112
            ],
            [
              105.8337327034026,
              21.01193514732295
            ],
            [
              105.833741608169,
              21.011918937767277
            ],
            [
              105.83375239273917,
              21.011899307518846
            ],
            [
              105.83375609900662,
              21.011892560331017
            ],
            [
              105.8337775419534,
              21.011848595550557
            ],
            [
              105.83382334605129,
              21.011732765509453
            ],
            [
              105.83383777334276,
              21.011695652644253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_277_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3856",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012166240510275,
          "to_latitude": 21.011695652644253,
          "to_longitude": 105.83383777334276,
          "from_longitude": 105.83356690214876
        }
      },
      {
        "id": 3850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83375239273917,
            21.011899307518846,
            105.83377699358418,
            21.011908640126737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375239273917,
              21.011899307518846
            ],
            [
              105.83376304240662,
              21.011903347848055
            ],
            [
              105.83377522957177,
              21.011907972255063
            ],
            [
              105.83377699358418,
              21.011908640126737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_277_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3857",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011899307518846,
          "to_latitude": 21.011908640126737,
          "to_longitude": 105.83377699358418,
          "from_longitude": 105.83375239273917
        }
      },
      {
        "id": 3851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337686015898,
            21.011766780578874,
            105.8338508880325,
            21.01199644155566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338032040809,
              21.01199644155566
            ],
            [
              105.83377290928395,
              21.01196644577281
            ],
            [
              105.8337686015898,
              21.011961555142964
            ],
            [
              105.83378740839082,
              21.01191438328132
            ],
            [
              105.83378716826715,
              21.011897658535496
            ],
            [
              105.8338160265124,
              21.01182391150142
            ],
            [
              105.8338508880325,
              21.011766780578874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_278_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3858",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01199644155566,
          "to_latitude": 21.011766780578874,
          "to_longitude": 105.8338508880325,
          "from_longitude": 105.8338032040809
        }
      },
      {
        "id": 3852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398808925374,
            21.012103543824114,
            105.8343104994021,
            21.012639709925597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83408007742868,
              21.012103543824114
            ],
            [
              105.83408827386086,
              21.012113917829865
            ],
            [
              105.83407233102848,
              21.012138820218006
            ],
            [
              105.83401396946041,
              21.012218878224473
            ],
            [
              105.83398808925374,
              21.01225454336928
            ],
            [
              105.83400475320279,
              21.01226937574249
            ],
            [
              105.83406622811728,
              21.01232420003757
            ],
            [
              105.83408579246912,
              21.012342876761917
            ],
            [
              105.83408809932317,
              21.012348445577672
            ],
            [
              105.83412055602643,
              21.012374177414337
            ],
            [
              105.8341435180996,
              21.012390817863913
            ],
            [
              105.83418645671152,
              21.01234646855761
            ],
            [
              105.83422208609478,
              21.012313779582737
            ],
            [
              105.83422320522452,
              21.01231281758055
            ],
            [
              105.83426153080684,
              21.012343604869
            ],
            [
              105.8343104994021,
              21.012383624211303
            ],
            [
              105.83429521664813,
              21.012397491392978
            ],
            [
              105.83427993388621,
              21.012411357670164
            ],
            [
              105.83424039223843,
              21.01245024841381
            ],
            [
              105.8342262698569,
              21.012464864093133
            ],
            [
              105.83424788377083,
              21.01248538597271
            ],
            [
              105.83427208437651,
              21.012510940580935
            ],
            [
              105.83430510948097,
              21.01254373552181
            ],
            [
              105.8343065707914,
              21.01254497480688
            ],
            [
              105.83425780842526,
              21.012584255799275
            ],
            [
              105.83422623782214,
              21.012616862559863
            ],
            [
              105.83420186098557,
              21.012639709925597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_284_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3859",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 107,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012103543824114,
          "to_latitude": 21.012639709925597,
          "to_longitude": 105.83420186098557,
          "from_longitude": 105.83408007742868
        }
      },
      {
        "id": 3853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83399491454678,
            21.01234886517572,
            105.83407454634032,
            21.01251331549602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83399491454678,
              21.01251331549602
            ],
            [
              105.83403881634385,
              21.012462951445116
            ],
            [
              105.83406693870671,
              21.012429518912732
            ],
            [
              105.8340704855361,
              21.012424891465887
            ],
            [
              105.83403463721022,
              21.012395543473854
            ],
            [
              105.83407259960067,
              21.012351146329486
            ],
            [
              105.83407454634032,
              21.01234886517572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_283_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3860",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01251331549602,
          "to_latitude": 21.01234886517572,
          "to_longitude": 105.83407454634032,
          "from_longitude": 105.83399491454678
        }
      },
      {
        "id": 3854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83429521664813,
            21.012397491392978,
            105.83439855616585,
            21.012501073203477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83429521664813,
              21.012397491392978
            ],
            [
              105.83434786520829,
              21.012447478734607
            ],
            [
              105.83436682998521,
              21.012469032426743
            ],
            [
              105.83438058745136,
              21.01248375798147
            ],
            [
              105.83439855616585,
              21.012501073203477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_284_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3861",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012397491392978,
          "to_latitude": 21.012501073203477,
          "to_longitude": 105.83439855616585,
          "from_longitude": 105.83429521664813
        }
      },
      {
        "id": 3855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396628972278,
            21.012104014753877,
            105.83429504104728,
            21.01263324511911
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83407263579362,
              21.012104014753877
            ],
            [
              105.83407255672304,
              21.012107094235613
            ],
            [
              105.83406073386135,
              21.012128899139185
            ],
            [
              105.834059715681,
              21.01213071690281
            ],
            [
              105.83405342885264,
              21.01214148796871
            ],
            [
              105.83400732049202,
              21.0122062087613
            ],
            [
              105.83397968239015,
              21.01223953865233
            ],
            [
              105.83396764492686,
              21.012249737256674
            ],
            [
              105.83396628972278,
              21.012251145704248
            ],
            [
              105.83397566120027,
              21.012261271891635
            ],
            [
              105.83399652525144,
              21.012280844578388
            ],
            [
              105.83406752877107,
              21.0123427386778
            ],
            [
              105.83407454634032,
              21.01234886517572
            ],
            [
              105.83409430090649,
              21.01236611296774
            ],
            [
              105.8341281633709,
              21.01239330023283
            ],
            [
              105.83414476753413,
              21.01240533288686
            ],
            [
              105.83416743061701,
              21.012379963998093
            ],
            [
              105.83420612972398,
              21.01234160940139
            ],
            [
              105.83422554308187,
              21.012323615011766
            ],
            [
              105.83424235768169,
              21.012337337461798
            ],
            [
              105.83428535307425,
              21.012372048941607
            ],
            [
              105.83429504104728,
              21.012380444785986
            ],
            [
              105.83426024888816,
              21.012414754572635
            ],
            [
              105.83422050209124,
              21.012456886185657
            ],
            [
              105.83421426301197,
              21.01246378941372
            ],
            [
              105.83422078220018,
              21.012470759251116
            ],
            [
              105.83423926626713,
              21.01248907364034
            ],
            [
              105.83424987314649,
              21.01249959388288
            ],
            [
              105.83429147601204,
              21.012540582374353
            ],
            [
              105.83429382172928,
              21.012543797192112
            ],
            [
              105.83425612445262,
              21.012576014902464
            ],
            [
              105.83422245128291,
              21.012607616746568
            ],
            [
              105.83419678939894,
              21.01263324511911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_283_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3862",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 108,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012104014753877,
          "to_latitude": 21.01263324511911,
          "to_longitude": 105.83419678939894,
          "from_longitude": 105.83407263579362
        }
      },
      {
        "id": 3856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83379423782813,
            21.012251145704248,
            105.83396628972278,
            21.01245843087736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396628972278,
              21.012251145704248
            ],
            [
              105.83395910629356,
              21.01225792168092
            ],
            [
              105.8339416301929,
              21.012270722034337
            ],
            [
              105.83389000090108,
              21.012312006778828
            ],
            [
              105.83383817188776,
              21.012355437646516
            ],
            [
              105.83379777621192,
              21.012391086284772
            ],
            [
              105.83379423782813,
              21.012397234715774
            ],
            [
              105.83387110417283,
              21.01245843087736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_283_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3863",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012251145704248,
          "to_latitude": 21.01245843087736,
          "to_longitude": 105.83387110417283,
          "from_longitude": 105.83396628972278
        }
      },
      {
        "id": 3857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340704855361,
            21.012424891465887,
            105.83412746413482,
            21.012472722427706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8340704855361,
              21.012424891465887
            ],
            [
              105.8341138384286,
              21.012459199301535
            ],
            [
              105.83412746413482,
              21.012472722427706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_283_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3864",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012424891465887,
          "to_latitude": 21.012472722427706,
          "to_longitude": 105.83412746413482,
          "from_longitude": 105.8340704855361
        }
      },
      {
        "id": 3858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385753182291,
            21.011960702110038,
            105.83416286391096,
            21.01210072589768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385753182291,
              21.012002641511835
            ],
            [
              105.83391333277837,
              21.01200789668274
            ],
            [
              105.83397670585666,
              21.012043594954626
            ],
            [
              105.8340274657561,
              21.012073822774454
            ],
            [
              105.83406467246294,
              21.012094216765483
            ],
            [
              105.83407654885161,
              21.01210072589768
            ],
            [
              105.8341352810924,
              21.012019012248754
            ],
            [
              105.83414611278867,
              21.01199783181168
            ],
            [
              105.8341547682323,
              21.011978136106052
            ],
            [
              105.83416286391096,
              21.011960702110038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_282_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3865",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012002641511835,
          "to_latitude": 21.011960702110038,
          "to_longitude": 105.83416286391096,
          "from_longitude": 105.83385753182291
        }
      },
      {
        "id": 3859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340570317079,
            21.012094216765483,
            105.83406467246294,
            21.012103337953572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83406467246294,
              21.012094216765483
            ],
            [
              105.8340593996144,
              21.012100510994042
            ],
            [
              105.8340570317079,
              21.012103337953572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_282_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3866",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012094216765483,
          "to_latitude": 21.012103337953572,
          "to_longitude": 105.8340570317079,
          "from_longitude": 105.83406467246294
        }
      },
      {
        "id": 3860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343555148081,
            21.011569337902948,
            105.8344755481523,
            21.011767486228806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343555148081,
              21.011767486228806
            ],
            [
              105.83435810764125,
              21.011762037906596
            ],
            [
              105.83436074135308,
              21.011756502674295
            ],
            [
              105.83436694624612,
              21.0117483016746
            ],
            [
              105.83437668357736,
              21.01173565755031
            ],
            [
              105.83442675113974,
              21.011650232491025
            ],
            [
              105.8344755481523,
              21.011569337902948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_288_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3867",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011767486228806,
          "to_latitude": 21.011569337902948,
          "to_longitude": 105.8344755481523,
          "from_longitude": 105.8343555148081
        }
      },
      {
        "id": 3861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83412207796985,
            21.012051210107405,
            105.83446534010366,
            21.01223416014737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446534010366,
              21.012184257474033
            ],
            [
              105.83442234593669,
              21.01223416014737
            ],
            [
              105.83433291165808,
              21.012169255802323
            ],
            [
              105.83429309362374,
              21.012140617392784
            ],
            [
              105.83427994485182,
              21.01213069219529
            ],
            [
              105.83421562112721,
              21.012110532364353
            ],
            [
              105.83416210686129,
              21.012076780173
            ],
            [
              105.83413336605375,
              21.012057788958337
            ],
            [
              105.83412477454567,
              21.012052653790192
            ],
            [
              105.83412207796985,
              21.012051210107405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_285_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3868",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012184257474033,
          "to_latitude": 21.012051210107405,
          "to_longitude": 105.83412207796985,
          "from_longitude": 105.83446534010366
        }
      },
      {
        "id": 3862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83413198093413,
            21.011760698154188,
            105.83439325345263,
            21.012044474082604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413198093413,
              21.012044474082604
            ],
            [
              105.83414805833547,
              21.012013156325303
            ],
            [
              105.83416131309315,
              21.011987478593113
            ],
            [
              105.83417800201183,
              21.011953175386132
            ],
            [
              105.83420008374125,
              21.011913396582084
            ],
            [
              105.83422744284519,
              21.01186935306641
            ],
            [
              105.8342592899223,
              21.011820932191142
            ],
            [
              105.83428144132951,
              21.01179040386623
            ],
            [
              105.83429734012311,
              21.011768492253534
            ],
            [
              105.83429934352283,
              21.011766542303135
            ],
            [
              105.83433992678961,
              21.011760698154188
            ],
            [
              105.83437210952003,
              21.011780596456767
            ],
            [
              105.83439325345263,
              21.0117939399875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_287_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3869",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012044474082604,
          "to_latitude": 21.0117939399875,
          "to_longitude": 105.83439325345263,
          "from_longitude": 105.83413198093413
        }
      },
      {
        "id": 3863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459510079294,
            21.011884628827797,
            105.8348770554323,
            21.012013481301253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459510079294,
              21.011908356016004
            ],
            [
              105.83465227277144,
              21.011941331419635
            ],
            [
              105.83472685376303,
              21.01198369351974
            ],
            [
              105.83477723330526,
              21.012013481301253
            ],
            [
              105.83478800055276,
              21.011999405006108
            ],
            [
              105.83481561624264,
              21.011964853939077
            ],
            [
              105.8348770554323,
              21.011884628827797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_290_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3870",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011908356016004,
          "to_latitude": 21.011884628827797,
          "to_longitude": 105.8348770554323,
          "from_longitude": 105.83459510079294
        }
      },
      {
        "id": 3864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465616177935,
            21.011898859581045,
            105.8348811987067,
            21.01224251527821
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465616177935,
              21.01224251527821
            ],
            [
              105.83478327367273,
              21.01206076710419
            ],
            [
              105.83477680883414,
              21.01203867252035
            ],
            [
              105.83481284368793,
              21.011992108805558
            ],
            [
              105.83487134241848,
              21.01191138238137
            ],
            [
              105.8348811987067,
              21.011898859581045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_291_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3871",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01224251527821,
          "to_latitude": 21.011898859581045,
          "to_longitude": 105.8348811987067,
          "from_longitude": 105.83465616177935
        }
      },
      {
        "id": 3865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83477723330526,
            21.012013481301253,
            105.83477992715248,
            21.012015298018778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477992715248,
              21.012015298018778
            ],
            [
              105.83477723330526,
              21.012013481301253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_290_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3872",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012015298018778,
          "to_latitude": 21.012013481301253,
          "to_longitude": 105.83477723330526,
          "from_longitude": 105.83477992715248
        }
      },
      {
        "id": 3866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83476798623647,
            21.012023935124354,
            105.83477680883414,
            21.01203867252035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83476970582407,
              21.012023935124354
            ],
            [
              105.83476798623647,
              21.012026584590323
            ],
            [
              105.83477680883414,
              21.01203867252035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_291_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.3873",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012023935124354,
          "to_latitude": 21.01203867252035,
          "to_longitude": 105.83477680883414,
          "from_longitude": 105.83476970582407
        }
      },
      {
        "id": 3867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488642169551,
            21.011872551011752,
            105.83529531500959,
            21.012258627147865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523262604167,
              21.012258627147865
            ],
            [
              105.83529531500959,
              21.01216360996603
            ],
            [
              105.83525760098496,
              21.012137303520593
            ],
            [
              105.83521368967543,
              21.01210828890893
            ],
            [
              105.83517842000356,
              21.012085110959298
            ],
            [
              105.83513667101157,
              21.012058325710797
            ],
            [
              105.83513413500799,
              21.012056698804788
            ],
            [
              105.83510414804584,
              21.012036087165882
            ],
            [
              105.83503572328866,
              21.011992363669723
            ],
            [
              105.83498448183934,
              21.011959120844228
            ],
            [
              105.83493012570777,
              21.01192206993504
            ],
            [
              105.83491130982212,
              21.011908431437217
            ],
            [
              105.83488734679408,
              21.011873886858393
            ],
            [
              105.83488642169551,
              21.011872551011752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_301_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3874",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012258627147865,
          "to_latitude": 21.011872551011752,
          "to_longitude": 105.83488642169551,
          "from_longitude": 105.83523262604167
        }
      },
      {
        "id": 3868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346282981555,
            21.01148883933227,
            105.83489503073524,
            21.011873833217102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346282981555,
              21.011494218075534
            ],
            [
              105.83466361346314,
              21.011505928578813
            ],
            [
              105.83471134936582,
              21.01148883933227
            ],
            [
              105.83473029991235,
              21.01149038752788
            ],
            [
              105.83480997773677,
              21.01153428356227
            ],
            [
              105.83489503073524,
              21.011583014340374
            ],
            [
              105.83483089930067,
              21.011671885690166
            ],
            [
              105.83480663358479,
              21.011708608837758
            ],
            [
              105.83479065299314,
              21.011762999059894
            ],
            [
              105.83478668982066,
              21.01178476364407
            ],
            [
              105.83481268362758,
              21.011822113627176
            ],
            [
              105.83482853788047,
              21.011841304339427
            ],
            [
              105.83486827369478,
              21.011873833217102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_304_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3875",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011494218075534,
          "to_latitude": 21.011873833217102,
          "to_longitude": 105.83486827369478,
          "from_longitude": 105.8346282981555
        }
      },
      {
        "id": 3869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463171109234,
            21.011438363044856,
            105.83500120261785,
            21.011572225803747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463171109234,
              21.01148645166426
            ],
            [
              105.83466405624887,
              21.011492324741603
            ],
            [
              105.83467091889804,
              21.011492220626646
            ],
            [
              105.83469038461531,
              21.011486433015918
            ],
            [
              105.83471890739698,
              21.01147523581638
            ],
            [
              105.83475743497948,
              21.01149428911584
            ],
            [
              105.83482738070376,
              21.01153360747062
            ],
            [
              105.83489005668639,
              21.011565727536908
            ],
            [
              105.83490211095926,
              21.011572225803747
            ],
            [
              105.83497217909078,
              21.01148194878183
            ],
            [
              105.83499115907163,
              21.011453527800292
            ],
            [
              105.83500120261785,
              21.011438363044856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_305_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3876",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01148645166426,
          "to_latitude": 21.011438363044856,
          "to_longitude": 105.83500120261785,
          "from_longitude": 105.83463171109234
        }
      },
      {
        "id": 3870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490211095926,
            21.011572225803747,
            105.83492588454624,
            21.01159659106422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83490211095926,
              21.011572225803747
            ],
            [
              105.83490830378004,
              21.01158364108382
            ],
            [
              105.8349250751545,
              21.01159599619765
            ],
            [
              105.83492588454624,
              21.01159659106422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_305_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3877",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011572225803747,
          "to_latitude": 21.01159659106422,
          "to_longitude": 105.83492588454624,
          "from_longitude": 105.83490211095926
        }
      },
      {
        "id": 3871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83491339430017,
            21.011596242683204,
            105.83548250382255,
            21.011962664435494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83492296950722,
              21.011599967171076
            ],
            [
              105.83491717923943,
              21.011596242683204
            ],
            [
              105.83491339430017,
              21.01160094176673
            ],
            [
              105.83491927839907,
              21.011604242181832
            ],
            [
              105.8349269842714,
              21.01160856423718
            ],
            [
              105.83493021144427,
              21.011610458722625
            ],
            [
              105.83494226336705,
              21.011618262157995
            ],
            [
              105.8349745489742,
              21.011639007968935
            ],
            [
              105.8350724625916,
              21.011713962099442
            ],
            [
              105.83522752478834,
              21.011833726448838
            ],
            [
              105.83533963899366,
              21.011920532374546
            ],
            [
              105.83539551929026,
              21.011962664435494
            ],
            [
              105.83548250382255,
              21.01186743938723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_303_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3878",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011599967171076,
          "to_latitude": 21.01186743938723,
          "to_longitude": 105.83548250382255,
          "from_longitude": 105.83492296950722
        }
      },
      {
        "id": 3872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483105366406,
            21.011618262157995,
            105.83494226336705,
            21.01175304059734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494226336705,
              21.011618262157995
            ],
            [
              105.83488085623061,
              21.01169056675442
            ],
            [
              105.83483105366406,
              21.01175304059734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_303_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3879",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011618262157995,
          "to_latitude": 21.01175304059734,
          "to_longitude": 105.83483105366406,
          "from_longitude": 105.83494226336705
        }
      },
      {
        "id": 3873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494113224502,
            21.01157610426502,
            105.83531703564067,
            21.011856206377068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494113224502,
              21.01157610426502
            ],
            [
              105.8349443335179,
              21.011578875908725
            ],
            [
              105.83496761952617,
              21.011588741376123
            ],
            [
              105.83501446732387,
              21.011622245127782
            ],
            [
              105.83511198475496,
              21.011698083630392
            ],
            [
              105.83522177719775,
              21.011783762971746
            ],
            [
              105.83531703564067,
              21.011856206377068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_306_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3880",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01157610426502,
          "to_latitude": 21.011856206377068,
          "to_longitude": 105.83531703564067,
          "from_longitude": 105.83494113224502
        }
      },
      {
        "id": 3874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83496761952617,
            21.011501886306473,
            105.8350326037381,
            21.011588741376123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496761952617,
              21.011588741376123
            ],
            [
              105.8350326037381,
              21.011501886306473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_306_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3881",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011588741376123,
          "to_latitude": 21.011501886306473,
          "to_longitude": 105.8350326037381,
          "from_longitude": 105.83496761952617
        }
      },
      {
        "id": 3875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504005149909,
            21.01144047923539,
            105.83550280325866,
            21.011785950124047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504005149909,
              21.01144047923539
            ],
            [
              105.83504047722298,
              21.011440752629753
            ],
            [
              105.83511180684253,
              21.011486231294715
            ],
            [
              105.83518416564621,
              21.011534974550855
            ],
            [
              105.83536655932717,
              21.01167596047184
            ],
            [
              105.83550280325866,
              21.011785950124047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_308_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3882",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01144047923539,
          "to_latitude": 21.011785950124047,
          "to_longitude": 105.83550280325866,
          "from_longitude": 105.83504005149909
        }
      },
      {
        "id": 3876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83468435226605,
            21.0111291548313,
            105.83505077980459,
            21.01142840466213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83471364696493,
              21.0111291548313
            ],
            [
              105.83468435226605,
              21.01118157900527
            ],
            [
              105.83469807606079,
              21.011189577484185
            ],
            [
              105.83474604598102,
              21.01122027220163
            ],
            [
              105.83478332086221,
              21.011244124154803
            ],
            [
              105.8348409466964,
              21.011309262021975
            ],
            [
              105.83487386379605,
              21.01133598591255
            ],
            [
              105.83494638253359,
              21.011375987079074
            ],
            [
              105.83501333149503,
              21.011417652189785
            ],
            [
              105.83503398802591,
              21.01142240565424
            ],
            [
              105.83505001645968,
              21.01142813292467
            ],
            [
              105.83505077980459,
              21.01142840466213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_309_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3883",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0111291548313,
          "to_latitude": 21.01142840466213,
          "to_longitude": 105.83505077980459,
          "from_longitude": 105.83471364696493
        }
      },
      {
        "id": 3877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83474604598102,
            21.01103849154337,
            105.8348584116889,
            21.01122027220163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8348584116889,
              21.01103849154337
            ],
            [
              105.83478432952268,
              21.01114032962086
            ],
            [
              105.83477939746186,
              21.01117276604664
            ],
            [
              105.83474604598102,
              21.01122027220163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_309_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3884",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01103849154337,
          "to_latitude": 21.01122027220163,
          "to_longitude": 105.83474604598102,
          "from_longitude": 105.8348584116889
        }
      },
      {
        "id": 3878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515459140425,
            21.01097434022652,
            105.83534514886398,
            21.011254466951623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515459140425,
              21.011254466951623
            ],
            [
              105.83515531406664,
              21.011252614503256
            ],
            [
              105.83515790314621,
              21.01124598025368
            ],
            [
              105.83516095163131,
              21.011233401432392
            ],
            [
              105.83517762289215,
              21.011205139891576
            ],
            [
              105.83521027166717,
              21.011149791693278
            ],
            [
              105.83525278268453,
              21.011082836509296
            ],
            [
              105.83527815656085,
              21.011048058827555
            ],
            [
              105.83530950930296,
              21.011013393598443
            ],
            [
              105.83534514886398,
              21.01097434022652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_317_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3885",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011254466951623,
          "to_latitude": 21.01097434022652,
          "to_longitude": 105.83534514886398,
          "from_longitude": 105.83515459140425
        }
      },
      {
        "id": 3879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505831894281,
            21.01117828002996,
            105.83516319896373,
            21.011261278375216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83516319896373,
              21.011261278375216
            ],
            [
              105.83516303472476,
              21.011261150020175
            ],
            [
              105.83515459140425,
              21.011254466951623
            ],
            [
              105.83505831894281,
              21.01117828002996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_317_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3886",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011261278375216,
          "to_latitude": 21.01117828002996,
          "to_longitude": 105.83505831894281,
          "from_longitude": 105.83516319896373
        }
      },
      {
        "id": 3880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562289171823,
            21.011292608915618,
            105.83601619097813,
            21.011811901566137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569280034743,
              21.011811901566137
            ],
            [
              105.83562289171823,
              21.01174390239603
            ],
            [
              105.83566724251274,
              21.01169156670733
            ],
            [
              105.83574901253493,
              21.011597092428207
            ],
            [
              105.83576343959386,
              21.011581092293905
            ],
            [
              105.83582434180815,
              21.011513549866297
            ],
            [
              105.83582633945962,
              21.011511263023888
            ],
            [
              105.83587991875909,
              21.01144996441226
            ],
            [
              105.83601619097813,
              21.011292608915618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_312_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3887",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011811901566137,
          "to_latitude": 21.011292608915618,
          "to_longitude": 105.83601619097813,
          "from_longitude": 105.83569280034743
        }
      },
      {
        "id": 3881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575285067117,
            21.011572938506575,
            105.83576343959386,
            21.011581092293905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575285067117,
              21.011572938506575
            ],
            [
              105.83575435096269,
              21.011574095394344
            ],
            [
              105.83575647627505,
              21.011575730630998
            ],
            [
              105.83576343959386,
              21.011581092293905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_312_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3888",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011572938506575,
          "to_latitude": 21.011581092293905,
          "to_longitude": 105.83576343959386,
          "from_longitude": 105.83575285067117
        }
      },
      {
        "id": 3882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521090472843,
            21.011170721727076,
            105.83546566490881,
            21.01142373874122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521090472843,
              21.011170721727076
            ],
            [
              105.83521351235932,
              21.01117243137761
            ],
            [
              105.8352149499676,
              21.0111732155462
            ],
            [
              105.83521831051407,
              21.011175051565377
            ],
            [
              105.8353581568615,
              21.011251468868277
            ],
            [
              105.83539086750828,
              21.011264941554618
            ],
            [
              105.835395926676,
              21.011267620971807
            ],
            [
              105.83546566490881,
              21.01130421685909
            ],
            [
              105.83544663104783,
              21.01133313402595
            ],
            [
              105.83541961148431,
              21.011376920447717
            ],
            [
              105.83538877942588,
              21.01142373874122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_318_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3889",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011170721727076,
          "to_latitude": 21.01142373874122,
          "to_longitude": 105.83538877942588,
          "from_longitude": 105.83521090472843
        }
      },
      {
        "id": 3883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549086104499,
            21.010910031687875,
            105.83592410360444,
            21.011290380385063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592410360444,
              21.010910031687875
            ],
            [
              105.83592083002077,
              21.010914826732236
            ],
            [
              105.83590973766567,
              21.01092024095999
            ],
            [
              105.83588904892048,
              21.01095027004417
            ],
            [
              105.83585264626329,
              21.01099442146255
            ],
            [
              105.8358000093044,
              21.011048210540537
            ],
            [
              105.83578877242364,
              21.011056174371134
            ],
            [
              105.83576590770087,
              21.011041807982526
            ],
            [
              105.8357206552194,
              21.01102893438487
            ],
            [
              105.83569179973634,
              21.011020436757672
            ],
            [
              105.83567521090663,
              21.01101805784402
            ],
            [
              105.83566692209239,
              21.011021155064324
            ],
            [
              105.83565508066027,
              21.01102765321164
            ],
            [
              105.8356128608069,
              21.011078285787665
            ],
            [
              105.83560574382439,
              21.011125637979017
            ],
            [
              105.8355951609164,
              21.011144808509624
            ],
            [
              105.83559364942023,
              21.011147683671084
            ],
            [
              105.83554794255768,
              21.011211953986553
            ],
            [
              105.83553152255527,
              21.011234020914944
            ],
            [
              105.83549086104499,
              21.011290380385063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_321_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3890",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010910031687875,
          "to_latitude": 21.011290380385063,
          "to_longitude": 105.83549086104499,
          "from_longitude": 105.83592410360444
        }
      },
      {
        "id": 3884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559364942023,
            21.011147683671084,
            105.8357388098392,
            21.011267330901326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559364942023,
              21.011147683671084
            ],
            [
              105.83565238878869,
              21.011193533742627
            ],
            [
              105.83565822660762,
              21.01119819642266
            ],
            [
              105.83566124109225,
              21.011212505861238
            ],
            [
              105.8357388098392,
              21.011267330901326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_321_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3891",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011147683671084,
          "to_latitude": 21.011267330901326,
          "to_longitude": 105.8357388098392,
          "from_longitude": 105.83559364942023
        }
      },
      {
        "id": 3885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83554794255768,
            21.011211953986553,
            105.83567794925354,
            21.01129916409996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554794255768,
              21.011211953986553
            ],
            [
              105.8356093487877,
              21.011251492789892
            ],
            [
              105.83567794925354,
              21.01129916409996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_321_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3892",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011211953986553,
          "to_latitude": 21.01129916409996,
          "to_longitude": 105.83567794925354,
          "from_longitude": 105.83554794255768
        }
      },
      {
        "id": 3886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358000093044,
            21.011048210540537,
            105.83594630004237,
            21.011162854498235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358000093044,
              21.011048210540537
            ],
            [
              105.83585295719406,
              21.011095985761802
            ],
            [
              105.83587558806123,
              21.011115853926135
            ],
            [
              105.83594630004237,
              21.011162854498235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_321_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3893",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011048210540537,
          "to_latitude": 21.011162854498235,
          "to_longitude": 105.83594630004237,
          "from_longitude": 105.8358000093044
        }
      },
      {
        "id": 3887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548337264949,
            21.010902209786025,
            105.83591122819668,
            21.011288318962357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548337264949,
              21.011288318962357
            ],
            [
              105.83550761143039,
              21.0112561282378
            ],
            [
              105.83553056710969,
              21.011224127165114
            ],
            [
              105.8355588427138,
              21.01118065881662
            ],
            [
              105.83559046802165,
              21.011138636335524
            ],
            [
              105.83559989919496,
              21.011124414810766
            ],
            [
              105.83560248222011,
              21.011103053503113
            ],
            [
              105.83560252517583,
              21.01108382363456
            ],
            [
              105.83564454476547,
              21.011027457469734
            ],
            [
              105.83566837474287,
              21.0110116875529
            ],
            [
              105.83568917295155,
              21.01100613625391
            ],
            [
              105.8357058993195,
              21.011013866098075
            ],
            [
              105.83575809339759,
              21.0110359781118
            ],
            [
              105.835788990906,
              21.011045146729046
            ],
            [
              105.83586493361211,
              21.01097024138823
            ],
            [
              105.8359009193842,
              21.010918772281183
            ],
            [
              105.83591122819668,
              21.010902209786025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_320_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3894",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011288318962357,
          "to_latitude": 21.010902209786025,
          "to_longitude": 105.83591122819668,
          "from_longitude": 105.83548337264949
        }
      },
      {
        "id": 3888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561856239162,
            21.0109731729928,
            105.83566837474287,
            21.0110116875529
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566837474287,
              21.0110116875529
            ],
            [
              105.83561856239162,
              21.0109731729928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_320_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3895",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0110116875529,
          "to_latitude": 21.0109731729928,
          "to_longitude": 105.83561856239162,
          "from_longitude": 105.83566837474287
        }
      },
      {
        "id": 3889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593537631518,
            21.010926629065693,
            105.83643726637463,
            21.0112976027854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83643726637463,
              21.0112976027854
            ],
            [
              105.83642731412714,
              21.011275284250274
            ],
            [
              105.8364018251705,
              21.011251245571184
            ],
            [
              105.83636732149378,
              21.01121604037244
            ],
            [
              105.83636629961427,
              21.011214772751583
            ],
            [
              105.83634490137712,
              21.01119171653578
            ],
            [
              105.83633886557145,
              21.011187259885034
            ],
            [
              105.83631699410718,
              21.011171110241307
            ],
            [
              105.83621939131622,
              21.01110277955307
            ],
            [
              105.83616667827467,
              21.011068512828317
            ],
            [
              105.83611848911757,
              21.011035801790936
            ],
            [
              105.83608259941346,
              21.01101163713502
            ],
            [
              105.83605939587265,
              21.010997233612503
            ],
            [
              105.83604216116835,
              21.010991151976754
            ],
            [
              105.83599359048374,
              21.010961083806176
            ],
            [
              105.83593537631518,
              21.010926629065693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_323_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3896",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0112976027854,
          "to_latitude": 21.010926629065693,
          "to_longitude": 105.83593537631518,
          "from_longitude": 105.83643726637463
        }
      },
      {
        "id": 3890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607188591455,
            21.010991998571853,
            105.8364510923475,
            21.01126749266377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364510923475,
              21.01126749266377
            ],
            [
              105.8364095572747,
              21.01123283507011
            ],
            [
              105.8363567985048,
              21.0111843419393
            ],
            [
              105.83635189775964,
              21.011179714419853
            ],
            [
              105.83634396836614,
              21.011172225015606
            ],
            [
              105.83633296972474,
              21.011161837796667
            ],
            [
              105.8362982647917,
              21.011138493815903
            ],
            [
              105.83627527653846,
              21.01112302978346
            ],
            [
              105.83622392356011,
              21.01108961626278
            ],
            [
              105.83618153320569,
              21.011060954806197
            ],
            [
              105.836089252553,
              21.01100173219739
            ],
            [
              105.83607188591455,
              21.010991998571853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_322_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3897",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01126749266377,
          "to_latitude": 21.010991998571853,
          "to_longitude": 105.83607188591455,
          "from_longitude": 105.8364510923475
        }
      },
      {
        "id": 3891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635189775964,
            21.011126599468028,
            105.83644527828446,
            21.011179714419853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635189775964,
              21.011179714419853
            ],
            [
              105.83639561566442,
              21.011126599468028
            ],
            [
              105.83644527828446,
              21.01115909001986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_322_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3898",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011179714419853,
          "to_latitude": 21.01115909001986,
          "to_longitude": 105.83644527828446,
          "from_longitude": 105.83635189775964
        }
      },
      {
        "id": 3892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363366270875,
            21.011172225015606,
            105.83634396836614,
            21.011182923666652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363366270875,
              21.011182923666652
            ],
            [
              105.83633688470098,
              21.011182546658972
            ],
            [
              105.83633877775763,
              21.011179789744805
            ],
            [
              105.83634396836614,
              21.011172225015606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_322_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3899",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011182923666652,
          "to_latitude": 21.011172225015606,
          "to_longitude": 105.83634396836614,
          "from_longitude": 105.8363366270875
        }
      },
      {
        "id": 3893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633886557145,
            21.011185570613886,
            105.8363400171773,
            21.011187259885034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363400171773,
              21.011185570613886
            ],
            [
              105.83633949336392,
              21.01118633731659
            ],
            [
              105.83633886557145,
              21.011187259885034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_323_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3900",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011185570613886,
          "to_latitude": 21.011187259885034,
          "to_longitude": 105.83633886557145,
          "from_longitude": 105.8363400171773
        }
      },
      {
        "id": 3894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553564234343,
            21.010615660141006,
            105.83605356386346,
            21.01098412034639
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605356386346,
              21.01098412034639
            ],
            [
              105.83597960709558,
              21.010936369823202
            ],
            [
              105.83591617060651,
              21.010897400221122
            ],
            [
              105.83585996332137,
              21.01085557611836
            ],
            [
              105.83582265102693,
              21.010829163943445
            ],
            [
              105.8358163572544,
              21.010825015636705
            ],
            [
              105.83578899349712,
              21.01080698077192
            ],
            [
              105.83573867052937,
              21.010771249782486
            ],
            [
              105.83573039439887,
              21.01076539688783
            ],
            [
              105.83568533247474,
              21.01073352932885
            ],
            [
              105.83564248313319,
              21.010703939663447
            ],
            [
              105.83558579118666,
              21.010665568164246
            ],
            [
              105.83553564234343,
              21.010615660141006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_329_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3901",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01098412034639,
          "to_latitude": 21.010615660141006,
          "to_longitude": 105.83553564234343,
          "from_longitude": 105.83605356386346
        }
      },
      {
        "id": 3895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572382854392,
            21.01076539688783,
            105.83573039439887,
            21.010773532869056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573039439887,
              21.01076539688783
            ],
            [
              105.83572583851685,
              21.010771041838538
            ],
            [
              105.83572467398587,
              21.01077248368559
            ],
            [
              105.83572382854392,
              21.010773532869056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_329_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3902",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01076539688783,
          "to_latitude": 21.010773532869056,
          "to_longitude": 105.83572382854392,
          "from_longitude": 105.83573039439887
        }
      },
      {
        "id": 3896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83552109288732,
            21.010187203834803,
            105.83585340791853,
            21.010535741980725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83552109288732,
              21.010535741980725
            ],
            [
              105.83562623707193,
              21.010424936491205
            ],
            [
              105.83567250584915,
              21.010378185057732
            ],
            [
              105.83572010308914,
              21.010330588895066
            ],
            [
              105.83576812214174,
              21.01028256974512
            ],
            [
              105.83585340791853,
              21.010187203834803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_330_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3903",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010535741980725,
          "to_latitude": 21.010187203834803,
          "to_longitude": 105.83585340791853,
          "from_longitude": 105.83552109288732
        }
      },
      {
        "id": 3897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572010308914,
            21.010330588895066,
            105.83574410692061,
            21.010350540661918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83574410692061,
              21.010350540661918
            ],
            [
              105.83574363727033,
              21.010350151872174
            ],
            [
              105.83573289115296,
              21.010341218465655
            ],
            [
              105.83572010308914,
              21.010330588895066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_330_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3904",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010350540661918,
          "to_latitude": 21.010330588895066,
          "to_longitude": 105.83572010308914,
          "from_longitude": 105.83574410692061
        }
      },
      {
        "id": 3898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586648264391,
            21.010875842886886,
            105.83586782726954,
            21.010877763425466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586782726954,
              21.010875842886886
            ],
            [
              105.83586648264391,
              21.010877763425466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_329.1_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3905",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010875842886886,
          "to_latitude": 21.010877763425466,
          "to_longitude": 105.83586648264391,
          "from_longitude": 105.83586782726954
        }
      },
      {
        "id": 3899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506247339531,
            21.01082052809442,
            105.83518104650041,
            21.01102411978477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518104650041,
              21.01082052809442
            ],
            [
              105.83518073667504,
              21.01082103000175
            ],
            [
              105.83517725237931,
              21.010826683227172
            ],
            [
              105.83517395205676,
              21.010832035678888
            ],
            [
              105.83510742084579,
              21.01095749048454
            ],
            [
              105.83508462985247,
              21.010994030303046
            ],
            [
              105.83506247339531,
              21.01102411978477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_324_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3906",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01082052809442,
          "to_latitude": 21.01102411978477,
          "to_longitude": 105.83506247339531,
          "from_longitude": 105.83518104650041
        }
      },
      {
        "id": 3900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548245925857,
            21.010591067233744,
            105.83559217719898,
            21.010718232753554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548245925857,
              21.010591067233744
            ],
            [
              105.83549641815414,
              21.01060984900062
            ],
            [
              105.83553562619736,
              21.01066043308031
            ],
            [
              105.83555673202886,
              21.010681454971884
            ],
            [
              105.83557623800581,
              21.010700884202134
            ],
            [
              105.83559217719898,
              21.010718232753554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_328_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3907",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010591067233744,
          "to_latitude": 21.010718232753554,
          "to_longitude": 105.83559217719898,
          "from_longitude": 105.83548245925857
        }
      },
      {
        "id": 3901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562148574936,
            21.010721735147545,
            105.83590005867411,
            21.01089843498266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562148574936,
              21.010721735147545
            ],
            [
              105.83566071940753,
              21.010740638456515
            ],
            [
              105.83569517485462,
              21.010760326641563
            ],
            [
              105.83573367097065,
              21.010781341682165
            ],
            [
              105.83576199185015,
              21.01079972862904
            ],
            [
              105.8358404944247,
              21.01085852503961
            ],
            [
              105.8358534165087,
              21.01086971935941
            ],
            [
              105.83586648264391,
              21.010877763425466
            ],
            [
              105.83590005867411,
              21.01089843498266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_329.1_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3908",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010721735147545,
          "to_latitude": 21.01089843498266,
          "to_longitude": 105.83590005867411,
          "from_longitude": 105.83562148574936
        }
      },
      {
        "id": 3902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83500618109925,
            21.010710331150534,
            105.83535374487516,
            21.01096232126423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535374487516,
              21.01096232126423
            ],
            [
              105.83534246462897,
              21.01095092465234
            ],
            [
              105.83531205456072,
              21.010919589322853
            ],
            [
              105.83529415058277,
              21.010897471426954
            ],
            [
              105.8352932605278,
              21.010896727925875
            ],
            [
              105.83527249454414,
              21.0108771404614
            ],
            [
              105.83525334250427,
              21.010863402485825
            ],
            [
              105.83521087798412,
              21.01083364282197
            ],
            [
              105.83519127353075,
              21.010823128863116
            ],
            [
              105.83513806714377,
              21.010792971194487
            ],
            [
              105.83511631144744,
              21.01078065139935
            ],
            [
              105.83510135766775,
              21.010772183893447
            ],
            [
              105.83509047670019,
              21.010765981557533
            ],
            [
              105.83505921611354,
              21.01074634711748
            ],
            [
              105.83500618109925,
              21.010710331150534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_325_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3909",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01096232126423,
          "to_latitude": 21.010710331150534,
          "to_longitude": 105.83500618109925,
          "from_longitude": 105.83535374487516
        }
      },
      {
        "id": 3903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453555907603,
            21.01119026483278,
            105.83503244188279,
            21.01145327734125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503244188279,
              21.01145327734125
            ],
            [
              105.83503172305937,
              21.011452709127436
            ],
            [
              105.83502565548609,
              21.011447897617916
            ],
            [
              105.83497605884683,
              21.01140648697648
            ],
            [
              105.83492323058196,
              21.011376722101172
            ],
            [
              105.83486659364672,
              21.011340818611174
            ],
            [
              105.83484176932122,
              21.011323298618308
            ],
            [
              105.83483073737673,
              21.01130604605003
            ],
            [
              105.83480734458321,
              21.011277152845047
            ],
            [
              105.83479756398557,
              21.011266796580507
            ],
            [
              105.83477615302753,
              21.011247118284757
            ],
            [
              105.83470878618046,
              21.011203796804626
            ],
            [
              105.83468702948424,
              21.01119026483278
            ],
            [
              105.83466604554846,
              21.011221921931625
            ],
            [
              105.83463986360624,
              21.011262079485252
            ],
            [
              105.83461147815552,
              21.011306429775026
            ],
            [
              105.83459821168478,
              21.011328767471714
            ],
            [
              105.83456460030929,
              21.011343402896
            ],
            [
              105.83453555907603,
              21.011384171490032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_307_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3910",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01145327734125,
          "to_latitude": 21.011384171490032,
          "to_longitude": 105.83453555907603,
          "from_longitude": 105.83503244188279
        }
      },
      {
        "id": 3904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526618748262,
            21.0108771404614,
            105.83527249454414,
            21.010883215802455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527249454414,
              21.0108771404614
            ],
            [
              105.83527106320517,
              21.01087851858296
            ],
            [
              105.83526963282797,
              21.010879896699787
            ],
            [
              105.83526618748262,
              21.010883215802455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_325_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3911",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0108771404614,
          "to_latitude": 21.010883215802455,
          "to_longitude": 105.83526618748262,
          "from_longitude": 105.83527249454414
        }
      },
      {
        "id": 3905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467872966781,
            21.0105361499959,
            105.83498106711511,
            21.01069206371731
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83469884530882,
              21.0105361499959
            ],
            [
              105.83467872966781,
              21.010577246055043
            ],
            [
              105.83469638189642,
              21.010585257782513
            ],
            [
              105.83476784347953,
              21.010621699196626
            ],
            [
              105.83478539505995,
              21.010651382487247
            ],
            [
              105.8348026247708,
              21.010662460015418
            ],
            [
              105.83482898700234,
              21.01067962292537
            ],
            [
              105.83482958628468,
              21.010678588502202
            ],
            [
              105.83484008636823,
              21.010666993771224
            ],
            [
              105.83487613844513,
              21.010621683637524
            ],
            [
              105.834892036016,
              21.010607886552176
            ],
            [
              105.83490747809017,
              21.010620444194252
            ],
            [
              105.83495914969156,
              21.010662967394552
            ],
            [
              105.83498040153071,
              21.0106834485149
            ],
            [
              105.83498106711511,
              21.010683957377477
            ],
            [
              105.83497459227212,
              21.01069168823952
            ],
            [
              105.83497427789503,
              21.01069206371731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_327_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3912",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0105361499959,
          "to_latitude": 21.01069206371731,
          "to_longitude": 105.83497427789503,
          "from_longitude": 105.83469884530882
        }
      },
      {
        "id": 3906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83514500818305,
            21.010419217545106,
            105.83527656491042,
            21.010692019879343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527656491042,
              21.010692019879343
            ],
            [
              105.83523403933606,
              21.010663725550067
            ],
            [
              105.83518875222444,
              21.010631643089823
            ],
            [
              105.83514500818305,
              21.010602526460296
            ],
            [
              105.83525571283067,
              21.01044495562006
            ],
            [
              105.83527222778312,
              21.010419217545106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_332_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3913",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010692019879343,
          "to_latitude": 21.010419217545106,
          "to_longitude": 105.83527222778312,
          "from_longitude": 105.83527656491042
        }
      },
      {
        "id": 3907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83527222778312,
            21.010399243712254,
            105.83528564286505,
            21.010419217545106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528564286505,
              21.010399243712254
            ],
            [
              105.83528554174582,
              21.010399395047212
            ],
            [
              105.83527970168075,
              21.010415745893454
            ],
            [
              105.83527222778312,
              21.010419217545106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_332_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3914",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010399243712254,
          "to_latitude": 21.010419217545106,
          "to_longitude": 105.83527222778312,
          "from_longitude": 105.83528564286505
        }
      },
      {
        "id": 3908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506994394793,
            21.0103113717925,
            105.83520575439057,
            21.010500541395228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506994394793,
              21.010500541395228
            ],
            [
              105.83512536014142,
              21.010414647312658
            ],
            [
              105.8351967298718,
              21.010323649366597
            ],
            [
              105.83520374193466,
              21.01031410850647
            ],
            [
              105.83520575439057,
              21.0103113717925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_334_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3915",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010500541395228,
          "to_latitude": 21.0103113717925,
          "to_longitude": 105.83520575439057,
          "from_longitude": 105.83506994394793
        }
      },
      {
        "id": 3909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83511412828828,
            21.01023335698777,
            105.83511826702286,
            21.010238082198754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83511826702286,
              21.01023335698777
            ],
            [
              105.83511412828828,
              21.010238082198754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_333_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3916",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01023335698777,
          "to_latitude": 21.010238082198754,
          "to_longitude": 105.83511412828828,
          "from_longitude": 105.83511826702286
        }
      },
      {
        "id": 3910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83470079448644,
            21.010128084287395,
            105.83499587262682,
            21.01050146201043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83470079448644,
              21.01050146201043
            ],
            [
              105.83471371819275,
              21.01048201992074
            ],
            [
              105.83473250614013,
              21.010453328038263
            ],
            [
              105.83475548878408,
              21.010418038291153
            ],
            [
              105.83475845323203,
              21.010413518461917
            ],
            [
              105.83477079555978,
              21.010394700639004
            ],
            [
              105.8347935258082,
              21.01036228528423
            ],
            [
              105.8348195397036,
              21.010325325046498
            ],
            [
              105.83485724717153,
              21.01027569841222
            ],
            [
              105.83486011762342,
              21.01027191968702
            ],
            [
              105.83486692342254,
              21.01026316475478
            ],
            [
              105.83489816898712,
              21.010226017160406
            ],
            [
              105.83490602085386,
              21.01021821360957
            ],
            [
              105.83493469966032,
              21.010189639369873
            ],
            [
              105.83495228691544,
              21.010171980830815
            ],
            [
              105.83499587262682,
              21.010128084287395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_337_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3917",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01050146201043,
          "to_latitude": 21.010128084287395,
          "to_longitude": 105.83499587262682,
          "from_longitude": 105.83470079448644
        }
      },
      {
        "id": 3911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83485031000211,
            21.010270351935297,
            105.83485724717153,
            21.01027569841222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83485031000211,
              21.010270351935297
            ],
            [
              105.83485724717153,
              21.01027569841222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_337_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3918",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010270351935297,
          "to_latitude": 21.01027569841222,
          "to_longitude": 105.83485724717153,
          "from_longitude": 105.83485031000211
        }
      },
      {
        "id": 3912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83475845323203,
            21.010413518461917,
            105.83487573058154,
            21.010497565354605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475845323203,
              21.010413518461917
            ],
            [
              105.83479021880817,
              21.01043472661147
            ],
            [
              105.83484184400562,
              21.010471520922092
            ],
            [
              105.83487573058154,
              21.010497565354605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_337_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3919",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010413518461917,
          "to_latitude": 21.010497565354605,
          "to_longitude": 105.83487573058154,
          "from_longitude": 105.83475845323203
        }
      },
      {
        "id": 3913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83431250977469,
            21.010496471666702,
            105.83469464461623,
            21.011029497607204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343274245009,
              21.011029497607204
            ],
            [
              105.83431250977469,
              21.010996049343394
            ],
            [
              105.83431914150192,
              21.010966877881835
            ],
            [
              105.83439947628347,
              21.010849297737302
            ],
            [
              105.83442766684904,
              21.010798101087364
            ],
            [
              105.83445184421792,
              21.010755705267904
            ],
            [
              105.8344626399711,
              21.01073794369479
            ],
            [
              105.83449996747152,
              21.01067011630345
            ],
            [
              105.83453537674887,
              21.01060744940483
            ],
            [
              105.83454320027548,
              21.01059558149598
            ],
            [
              105.83455003242193,
              21.010588617541945
            ],
            [
              105.83459136003844,
              21.010526257605772
            ],
            [
              105.83460167011091,
              21.010509726792552
            ],
            [
              105.8346271435768,
              21.010496471666702
            ],
            [
              105.83469464461623,
              21.01052248041884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_339_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3920",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011029497607204,
          "to_latitude": 21.01052248041884,
          "to_longitude": 105.83469464461623,
          "from_longitude": 105.8343274245009
        }
      },
      {
        "id": 3914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449107700918,
            21.010665004294594,
            105.83449996747152,
            21.01067011630345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83449107700918,
              21.010665004294594
            ],
            [
              105.83449996747152,
              21.01067011630345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_339_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3921",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010665004294594,
          "to_latitude": 21.01067011630345,
          "to_longitude": 105.83449996747152,
          "from_longitude": 105.83449107700918
        }
      },
      {
        "id": 3915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447014549594,
            21.010323132528725,
            105.83480562705043,
            21.010685328347705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447014549594,
              21.010685328347705
            ],
            [
              105.8345176678304,
              21.01061241818662
            ],
            [
              105.83453088746118,
              21.01059705904278
            ],
            [
              105.83455014722264,
              21.01056636964121
            ],
            [
              105.83459327968849,
              21.010499744856098
            ],
            [
              105.83462865345612,
              21.010482064143055
            ],
            [
              105.83468360399971,
              21.01050106130245
            ],
            [
              105.8347202047492,
              21.010447174193544
            ],
            [
              105.83476151758998,
              21.010385808741447
            ],
            [
              105.83480562705043,
              21.010323132528725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_338_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3922",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010685328347705,
          "to_latitude": 21.010323132528725,
          "to_longitude": 105.83480562705043,
          "from_longitude": 105.83447014549594
        }
      },
      {
        "id": 3916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83468360399971,
            21.01050106130245,
            105.834690916913,
            21.010503402719323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.834690916913,
              21.010503402719323
            ],
            [
              105.83468360399971,
              21.01050106130245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_338_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.3923",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010503402719323,
          "to_latitude": 21.01050106130245,
          "to_longitude": 105.83468360399971,
          "from_longitude": 105.834690916913
        }
      },
      {
        "id": 3917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530181559632,
            21.011927201376444,
            105.83571440749867,
            21.01221800082117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83530181559632,
              21.012172242689374
            ],
            [
              105.83530617994523,
              21.01217399610078
            ],
            [
              105.83530832434397,
              21.012174912282156
            ],
            [
              105.83533921287788,
              21.012126631403902
            ],
            [
              105.8353459868413,
              21.012118368866552
            ],
            [
              105.8353528269284,
              21.012111962131307
            ],
            [
              105.83547282717156,
              21.01221448238547
            ],
            [
              105.83547734948795,
              21.01221800082117
            ],
            [
              105.83551784310437,
              21.012176875966507
            ],
            [
              105.8356047396271,
              21.012088121087963
            ],
            [
              105.83561267106755,
              21.01207801479372
            ],
            [
              105.83559230627402,
              21.01205830527767
            ],
            [
              105.83567951407613,
              21.011964666904408
            ],
            [
              105.83571440749867,
              21.011927201376444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_300_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3924",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012172242689374,
          "to_latitude": 21.011927201376444,
          "to_longitude": 105.83571440749867,
          "from_longitude": 105.83530181559632
        }
      },
      {
        "id": 3918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83567951407613,
            21.011964666904408,
            105.83575029320292,
            21.01201053457011
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567951407613,
              21.011964666904408
            ],
            [
              105.83575029320292,
              21.01201053457011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_300_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3925",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011964666904408,
          "to_latitude": 21.01201053457011,
          "to_longitude": 105.83575029320292,
          "from_longitude": 105.83567951407613
        }
      },
      {
        "id": 3919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592288006031,
            21.0114800476313,
            105.83653079343115,
            21.011700632350816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592288006031,
              21.011565548585647
            ],
            [
              105.83597109080009,
              21.01158847132003
            ],
            [
              105.83601695275122,
              21.011613670868172
            ],
            [
              105.83602013302239,
              21.01161525936979
            ],
            [
              105.83605736967655,
              21.011557631317643
            ],
            [
              105.83612841528694,
              21.01157883948573
            ],
            [
              105.8361768999335,
              21.011611550867567
            ],
            [
              105.83620762610644,
              21.011645869635018
            ],
            [
              105.83626308828649,
              21.011700632350816
            ],
            [
              105.83649151370032,
              21.0114800476313
            ],
            [
              105.83651509256173,
              21.011499119820407
            ],
            [
              105.83653079343115,
              21.01151181693812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_316_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3926",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011565548585647,
          "to_latitude": 21.01151181693812,
          "to_longitude": 105.83653079343115,
          "from_longitude": 105.83592288006031
        }
      },
      {
        "id": 3920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587300417193,
            21.01171727308409,
            105.8363022562678,
            21.011910743139474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363022562678,
              21.011738063670922
            ],
            [
              105.83629269318462,
              21.01173275726011
            ],
            [
              105.83626479897896,
              21.01171727308409
            ],
            [
              105.83606422689294,
              21.011910743139474
            ],
            [
              105.83600974756554,
              21.01187061499767
            ],
            [
              105.83596620891629,
              21.011827527412144
            ],
            [
              105.83592892234869,
              21.011800601209643
            ],
            [
              105.83587300417193,
              21.011750994423654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_315_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3927",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011738063670922,
          "to_latitude": 21.011750994423654,
          "to_longitude": 105.83587300417193,
          "from_longitude": 105.8363022562678
        }
      },
      {
        "id": 3921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83497213192865,
            21.01259411149386,
            105.83529487138641,
            21.012762539597134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83529487138641,
              21.012762539597134
            ],
            [
              105.83521228780302,
              21.012714675764336
            ],
            [
              105.83512520466708,
              21.012667720038063
            ],
            [
              105.83502016629419,
              21.01261555406659
            ],
            [
              105.83497213192865,
              21.01259411149386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_294_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3928",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012762539597134,
          "to_latitude": 21.01259411149386,
          "to_longitude": 105.83497213192865,
          "from_longitude": 105.83529487138641
        }
      },
      {
        "id": 3922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83471145921803,
            21.012933836074843,
            105.8347891510812,
            21.012997716649707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83471145921803,
              21.012933836074843
            ],
            [
              105.83474235758351,
              21.01295882936572
            ],
            [
              105.8347891510812,
              21.012997716649707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_292_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3929",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012933836074843,
          "to_latitude": 21.012997716649707,
          "to_longitude": 105.8347891510812,
          "from_longitude": 105.83471145921803
        }
      },
      {
        "id": 3923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434686591426,
            21.012738429736633,
            105.83517106312597,
            21.013265621501805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83517106312597,
              21.012889056889215
            ],
            [
              105.83511488024894,
              21.01285863114207
            ],
            [
              105.83510020374413,
              21.012850936346723
            ],
            [
              105.835078519234,
              21.01284178201157
            ],
            [
              105.83502363974499,
              21.012813075898674
            ],
            [
              105.83497004212633,
              21.012785855604214
            ],
            [
              105.83487308808655,
              21.012738429736633
            ],
            [
              105.83486048098926,
              21.01275913570285
            ],
            [
              105.83484451539836,
              21.01278788781572
            ],
            [
              105.83482633774578,
              21.012816704070918
            ],
            [
              105.83481194133302,
              21.01283755881284
            ],
            [
              105.8347939722025,
              21.012863227206346
            ],
            [
              105.8347838273247,
              21.012877488319496
            ],
            [
              105.8347739876586,
              21.01288938058235
            ],
            [
              105.834755141758,
              21.012908399216244
            ],
            [
              105.83474462273321,
              21.012911604884344
            ],
            [
              105.83472831194273,
              21.012916703221997
            ],
            [
              105.83471035441272,
              21.012912996871968
            ],
            [
              105.83464816480213,
              21.012981531557262
            ],
            [
              105.83464144647631,
              21.013009317000293
            ],
            [
              105.83465023248534,
              21.013026576075852
            ],
            [
              105.83467073133414,
              21.01307054927657
            ],
            [
              105.83466456310013,
              21.013094681186907
            ],
            [
              105.83465819184322,
              21.01310670094478
            ],
            [
              105.83465290337658,
              21.013116586489826
            ],
            [
              105.83464708502673,
              21.01312476753914
            ],
            [
              105.83463248510377,
              21.01314052004471
            ],
            [
              105.83458380279325,
              21.013169310685015
            ],
            [
              105.83455195391078,
              21.01319634145698
            ],
            [
              105.83452004874916,
              21.01322605327058
            ],
            [
              105.83449343128933,
              21.01324798947522
            ],
            [
              105.83447203453315,
              21.01326514910835
            ],
            [
              105.83447129648137,
              21.013265621501805
            ],
            [
              105.83444729605723,
              21.013242707879495
            ],
            [
              105.83441347753066,
              21.013211878669583
            ],
            [
              105.83439708986033,
              21.01322391592698
            ],
            [
              105.83434686591426,
              21.013262117535625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_293_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3930",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 129,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012889056889215,
          "to_latitude": 21.013262117535625,
          "to_longitude": 105.83434686591426,
          "from_longitude": 105.83517106312597
        }
      },
      {
        "id": 3924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435761701504,
            21.013223293766874,
            105.83447176952576,
            21.013278724071057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447176952576,
              21.013278724071057
            ],
            [
              105.83446158482428,
              21.013268716612902
            ],
            [
              105.83441717201298,
              21.013226705051856
            ],
            [
              105.83441344867984,
              21.013223293766874
            ],
            [
              105.83438740611615,
              21.01324517294249
            ],
            [
              105.83435761701504,
              21.013270538870167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_292_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3931",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013278724071057,
          "to_latitude": 21.013270538870167,
          "to_longitude": 105.83435761701504,
          "from_longitude": 105.83447176952576
        }
      },
      {
        "id": 3925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441095731072,
            21.013027182446137,
            105.83465819184322,
            21.013109194153614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441095731072,
              21.013027182446137
            ],
            [
              105.83449140266472,
              21.0130788841195
            ],
            [
              105.83455114685393,
              21.013109194153614
            ],
            [
              105.83461267867507,
              21.01309599879859
            ],
            [
              105.83463113722911,
              21.013099849926213
            ],
            [
              105.83465819184322,
              21.01310670094478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_293_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3932",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013027182446137,
          "to_latitude": 21.01310670094478,
          "to_longitude": 105.83465819184322,
          "from_longitude": 105.83441095731072
        }
      },
      {
        "id": 3926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028991956255,
            21.01489663271886,
            105.83069589705764,
            21.015303912701896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031338847972,
              21.01489663271886
            ],
            [
              105.83028991956255,
              21.015003045140148
            ],
            [
              105.83033618683098,
              21.01500429701518
            ],
            [
              105.83040324226602,
              21.01501055605645
            ],
            [
              105.83044047896861,
              21.015012861786097
            ],
            [
              105.83048563317186,
              21.01501565724615
            ],
            [
              105.83064643197568,
              21.01501410992584
            ],
            [
              105.83064799996654,
              21.015022628717386
            ],
            [
              105.83065383234795,
              21.015051496225897
            ],
            [
              105.83069589705764,
              21.015288821935847
            ],
            [
              105.83069267759323,
              21.01529162500866
            ],
            [
              105.83069324892911,
              21.01529821576358
            ],
            [
              105.83069374316986,
              21.015303912701896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_168_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3933",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01489663271886,
          "to_latitude": 21.015303912701896,
          "to_longitude": 105.83069374316986,
          "from_longitude": 105.83031338847972
        }
      },
      {
        "id": 3927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83065635196256,
            21.0168034272823,
            105.83094102484526,
            21.017139558877922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83065635196256,
              21.017126365643804
            ],
            [
              105.83065821011915,
              21.01712509115243
            ],
            [
              105.8306635663121,
              21.017120762034725
            ],
            [
              105.83070096702626,
              21.017139558877922
            ],
            [
              105.83073214770288,
              21.017129855915915
            ],
            [
              105.83075058785388,
              21.017102001631315
            ],
            [
              105.83076902819106,
              21.017073834828082
            ],
            [
              105.83080322639208,
              21.017023133279388
            ],
            [
              105.83083004856122,
              21.016972119102427
            ],
            [
              105.83094102484526,
              21.0168034272823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3934",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017126365643804,
          "to_latitude": 21.0168034272823,
          "to_longitude": 105.83094102484526,
          "from_longitude": 105.83065635196256
        }
      },
      {
        "id": 3928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109382579295,
            21.015611389708827,
            105.8311612179147,
            21.015661778387216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109382579295,
              21.015661778387216
            ],
            [
              105.83109946622172,
              21.015656676514084
            ],
            [
              105.83112936690607,
              21.015642061678943
            ],
            [
              105.8311612179147,
              21.015611389708827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_166_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3935",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015661778387216,
          "to_latitude": 21.015611389708827,
          "to_longitude": 105.8311612179147,
          "from_longitude": 105.83109382579295
        }
      },
      {
        "id": 3929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83062489535412,
            21.01529821576358,
            105.83069324892911,
            21.015304574010294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83069324892911,
              21.01529821576358
            ],
            [
              105.83065337416832,
              21.01530192490368
            ],
            [
              105.83062489535412,
              21.015304574010294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_168_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3936",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01529821576358,
          "to_latitude": 21.015304574010294,
          "to_longitude": 105.83062489535412,
          "from_longitude": 105.83069324892911
        }
      },
      {
        "id": 3930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83085037817172,
            21.015857405670335,
            105.83091356158639,
            21.015907659635847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83091356158639,
              21.015907659635847
            ],
            [
              105.83091280409926,
              21.01590705907782
            ],
            [
              105.83085037817172,
              21.015857405670335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_161_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.3937",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015907659635847,
          "to_latitude": 21.015857405670335,
          "to_longitude": 105.83085037817172,
          "from_longitude": 105.83091356158639
        }
      },
      {
        "id": 3931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83025725182907,
            21.017271114036998,
            105.83040505979214,
            21.017533548453482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031408966586,
              21.017533548453482
            ],
            [
              105.83031097769543,
              21.0175231115215
            ],
            [
              105.83030182202322,
              21.01747240308579
            ],
            [
              105.83028941696148,
              21.01742952611551
            ],
            [
              105.83027768240821,
              21.01739290883451
            ],
            [
              105.83026091818081,
              21.01734627584915
            ],
            [
              105.83025725182907,
              21.017328832594266
            ],
            [
              105.83026360068476,
              21.017291819054964
            ],
            [
              105.83029442075154,
              21.0172845593492
            ],
            [
              105.83040505979214,
              21.017271114036998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.3938",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017533548453482,
          "to_latitude": 21.017271114036998,
          "to_longitude": 105.83040505979214,
          "from_longitude": 105.83031408966586
        }
      },
      {
        "id": 3932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82964330437959,
            21.017362397818673,
            105.83002504625733,
            21.017567549765175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83002504625733,
              21.017362397818673
            ],
            [
              105.82998521009125,
              21.017386047131446
            ],
            [
              105.82981345994926,
              21.017477505581674
            ],
            [
              105.82980730036722,
              21.01748076917367
            ],
            [
              105.82974981346499,
              21.017511225428837
            ],
            [
              105.82964330437959,
              21.017567549765175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3939",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017362397818673,
          "to_latitude": 21.017567549765175,
          "to_longitude": 105.82964330437959,
          "from_longitude": 105.83002504625733
        }
      },
      {
        "id": 3933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962934746995,
            21.017367198461262,
            105.83004892626458,
            21.017588074076336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83004892626458,
              21.017367198461262
            ],
            [
              105.82999423867535,
              21.017394342559797
            ],
            [
              105.82998464102607,
              21.017399331834216
            ],
            [
              105.82991653072263,
              21.01743592590569
            ],
            [
              105.82983148718225,
              21.017481906674853
            ],
            [
              105.82982024435111,
              21.017489460996018
            ],
            [
              105.82981889643168,
              21.017490115183563
            ],
            [
              105.82979786125063,
              21.017500331203273
            ],
            [
              105.82967914612395,
              21.017561371225963
            ],
            [
              105.82965995187803,
              21.01757172186339
            ],
            [
              105.82962934746995,
              21.017588074076336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3940",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017367198461262,
          "to_latitude": 21.017588074076336,
          "to_longitude": 105.82962934746995,
          "from_longitude": 105.83004892626458
        }
      },
      {
        "id": 3934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82981466980038,
            21.017482670644455,
            105.82981889643168,
            21.017490115183563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981466980038,
              21.017482670644455
            ],
            [
              105.8298178084482,
              21.01748819933068
            ],
            [
              105.82981882359279,
              21.017489989990565
            ],
            [
              105.82981889643168,
              21.017490115183563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3941",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017482670644455,
          "to_latitude": 21.017490115183563,
          "to_longitude": 105.82981889643168,
          "from_longitude": 105.82981466980038
        }
      },
      {
        "id": 3935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951118199846,
            21.017537063289247,
            105.8302048210624,
            21.017700245822034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82953499537408,
              21.01763534745162
            ],
            [
              105.82953585994713,
              21.017636698976784
            ],
            [
              105.82953746618745,
              21.01763909462662
            ],
            [
              105.82951118199846,
              21.017653382656633
            ],
            [
              105.82952634765796,
              21.017680432559615
            ],
            [
              105.82954437715777,
              21.017682631608487
            ],
            [
              105.82970233375472,
              21.017698709141307
            ],
            [
              105.82972782914231,
              21.017700245822034
            ],
            [
              105.82974492800686,
              21.01769367274938
            ],
            [
              105.82977007436472,
              21.017682719521787
            ],
            [
              105.82982103599922,
              21.017661750050166
            ],
            [
              105.8298662984477,
              21.017638590044687
            ],
            [
              105.8299162540457,
              21.017615743131653
            ],
            [
              105.82995246433234,
              21.017599155776505
            ],
            [
              105.8299628574391,
              21.017601972810773
            ],
            [
              105.8299779608671,
              21.017597947513277
            ],
            [
              105.83016351473945,
              21.0175484964727
            ],
            [
              105.8302048210624,
              21.017537063289247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3942",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01763534745162,
          "to_latitude": 21.017537063289247,
          "to_longitude": 105.8302048210624,
          "from_longitude": 105.82953499537408
        }
      },
      {
        "id": 3936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83013644690627,
            21.017043876529097,
            105.83027948418555,
            21.017114716629752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83013644690627,
              21.017043876529097
            ],
            [
              105.83024327462374,
              21.017067457769784
            ],
            [
              105.83026361961215,
              21.017094011749386
            ],
            [
              105.83027948418555,
              21.017114716629752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_96_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3943",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017043876529097,
          "to_latitude": 21.017114716629752,
          "to_longitude": 105.83027948418555,
          "from_longitude": 105.83013644690627
        }
      },
      {
        "id": 3937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294282816078,
            21.017252813138576,
            105.82968120139351,
            21.017639254788815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950221255146,
              21.017639254788815
            ],
            [
              105.82946027698945,
              21.017567192213296
            ],
            [
              105.8294282816078,
              21.017516581535524
            ],
            [
              105.82943482630247,
              21.01749424267131
            ],
            [
              105.82943494432017,
              21.01749384016065
            ],
            [
              105.82943452133323,
              21.017481887133314
            ],
            [
              105.82951472477058,
              21.017414219334654
            ],
            [
              105.82956898145854,
              21.017367066533343
            ],
            [
              105.82964021419576,
              21.017296889908287
            ],
            [
              105.82968120139351,
              21.017252813138576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3944",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017639254788815,
          "to_latitude": 21.017252813138576,
          "to_longitude": 105.82968120139351,
          "from_longitude": 105.82950221255146
        }
      },
      {
        "id": 3938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82941068765825,
            21.017490683331882,
            105.82943482630247,
            21.01749424267131
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82941068765825,
              21.017490683331882
            ],
            [
              105.82943372012821,
              21.01749408096799
            ],
            [
              105.82943482630247,
              21.01749424267131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3945",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017490683331882,
          "to_latitude": 21.01749424267131,
          "to_longitude": 105.82943482630247,
          "from_longitude": 105.82941068765825
        }
      },
      {
        "id": 3939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82969834024976,
            21.01679216161548,
            105.82993828403488,
            21.017235219625327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82993828403488,
              21.01679216161548
            ],
            [
              105.8299039919748,
              21.016914781863864
            ],
            [
              105.82988728758106,
              21.01695559971465
            ],
            [
              105.82986294560963,
              21.017019244097156
            ],
            [
              105.82985620856063,
              21.017035315531785
            ],
            [
              105.82984669461918,
              21.01704865019105
            ],
            [
              105.82980806322716,
              21.01709427266791
            ],
            [
              105.82974662824707,
              21.017177517305992
            ],
            [
              105.82969834024976,
              21.017235219625327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_109_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3946",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01679216161548,
          "to_latitude": 21.017235219625327,
          "to_longitude": 105.82969834024976,
          "from_longitude": 105.82993828403488
        }
      },
      {
        "id": 3940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82984528436424,
            21.017029692049807,
            105.82985620856063,
            21.017035315531785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82984528436424,
              21.017029692049807
            ],
            [
              105.82985208545847,
              21.01703319319427
            ],
            [
              105.82985578506815,
              21.01703509901711
            ],
            [
              105.82985620856063,
              21.017035315531785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_109_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3947",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017029692049807,
          "to_latitude": 21.017035315531785,
          "to_longitude": 105.82985620856063,
          "from_longitude": 105.82984528436424
        }
      },
      {
        "id": 3941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83010580985899,
            21.014559058233413,
            105.83024589698739,
            21.01499062096522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010580985899,
              21.01499062096522
            ],
            [
              105.83012844734569,
              21.01491437131909
            ],
            [
              105.83015047939146,
              21.014839272623643
            ],
            [
              105.83015453546591,
              21.01482720832141
            ],
            [
              105.8301584260672,
              21.014812707021086
            ],
            [
              105.83016874166216,
              21.014778262765603
            ],
            [
              105.83017060512677,
              21.014772042191602
            ],
            [
              105.83017137392694,
              21.014769474183257
            ],
            [
              105.83020301040634,
              21.01467361856012
            ],
            [
              105.83022182767455,
              21.014624775144473
            ],
            [
              105.83023875136136,
              21.014578450127495
            ],
            [
              105.83024589698739,
              21.014559058233413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_222_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3948",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01499062096522,
          "to_latitude": 21.014559058233413,
          "to_longitude": 105.83024589698739,
          "from_longitude": 105.83010580985899
        }
      },
      {
        "id": 3942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83015630924064,
            21.01477488575299,
            105.83016874166216,
            21.014778262765603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83015630924064,
              21.01477488575299
            ],
            [
              105.83016108278773,
              21.014776182073174
            ],
            [
              105.83016312419564,
              21.014776737531353
            ],
            [
              105.83016516559363,
              21.014777291183112
            ],
            [
              105.83016874166216,
              21.014778262765603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_222_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3949",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01477488575299,
          "to_latitude": 21.014778262765603,
          "to_longitude": 105.83016874166216,
          "from_longitude": 105.83015630924064
        }
      },
      {
        "id": 3943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83027678268138,
            21.01403685279705,
            105.83049800205539,
            21.01448416018754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83027678268138,
              21.01448416018754
            ],
            [
              105.83029234035052,
              21.014447906472732
            ],
            [
              105.83029273216955,
              21.014446746625588
            ],
            [
              105.83029517014907,
              21.014439528795315
            ],
            [
              105.83030427910985,
              21.014413889642565
            ],
            [
              105.8303263075532,
              21.014350332356926
            ],
            [
              105.8303400053771,
              21.014313309134227
            ],
            [
              105.83036668559852,
              21.01424122256363
            ],
            [
              105.83039455084925,
              21.014163220401077
            ],
            [
              105.83042431768494,
              21.014077551407368
            ],
            [
              105.83049800205539,
              21.01403685279705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_224_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3950",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01448416018754,
          "to_latitude": 21.01403685279705,
          "to_longitude": 105.83049800205539,
          "from_longitude": 105.83027678268138
        }
      },
      {
        "id": 3944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028042193645,
            21.014441978960956,
            105.83029273216955,
            21.014446746625588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029273216955,
              21.014446746625588
            ],
            [
              105.83029267722608,
              21.014446726119544
            ],
            [
              105.83028886847015,
              21.014445249868697
            ],
            [
              105.83028042193645,
              21.014441978960956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_224_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3951",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014446746625588,
          "to_latitude": 21.014441978960956,
          "to_longitude": 105.83028042193645,
          "from_longitude": 105.83029273216955
        }
      },
      {
        "id": 3945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82926578040198,
            21.017242390423707,
            105.82962534670082,
            21.017463631369193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926578040198,
              21.017242390423707
            ],
            [
              105.82927894526405,
              21.017266160559014
            ],
            [
              105.82933366637563,
              21.01735779155548
            ],
            [
              105.8293742207205,
              21.01742363948544
            ],
            [
              105.8293912000988,
              21.017450604689127
            ],
            [
              105.82940234944374,
              21.01746197522761
            ],
            [
              105.82940601444302,
              21.017462492068283
            ],
            [
              105.8294140972701,
              21.017463631369193
            ],
            [
              105.8294543039662,
              21.01743912412309
            ],
            [
              105.82948078624725,
              21.017421350493446
            ],
            [
              105.82953049224267,
              21.017379812663737
            ],
            [
              105.82954781706093,
              21.01736452961165
            ],
            [
              105.82962534670082,
              21.017284263067094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3952",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017242390423707,
          "to_latitude": 21.017284263067094,
          "to_longitude": 105.82962534670082,
          "from_longitude": 105.82926578040198
        }
      },
      {
        "id": 3946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940601444302,
            21.017462492068283,
            105.82940676869478,
            21.017474161674407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82940676869478,
              21.017474161674407
            ],
            [
              105.82940605663114,
              21.017463162958986
            ],
            [
              105.82940601444302,
              21.017462492068283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3953",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017474161674407,
          "to_latitude": 21.017462492068283,
          "to_longitude": 105.82940601444302,
          "from_longitude": 105.82940676869478
        }
      },
      {
        "id": 3947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962752040103,
            21.01689049036624,
            105.82986643788112,
            21.017280904210416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82962752040103,
              21.017280904210416
            ],
            [
              105.82964410755785,
              21.01726113120071
            ],
            [
              105.82969829767481,
              21.017188398442247
            ],
            [
              105.82976403686018,
              21.01709462380111
            ],
            [
              105.82982476236852,
              21.017001730752863
            ],
            [
              105.82982983248756,
              21.016988810674395
            ],
            [
              105.82983096887193,
              21.01698591390716
            ],
            [
              105.82986643788112,
              21.01689049036624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3954",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017280904210416,
          "to_latitude": 21.01689049036624,
          "to_longitude": 105.82986643788112,
          "from_longitude": 105.82962752040103
        }
      },
      {
        "id": 3948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82982983248756,
            21.016988810674395,
            105.8298403313638,
            21.01699319249102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982983248756,
              21.016988810674395
            ],
            [
              105.82983294772787,
              21.016990110574465
            ],
            [
              105.82983647853531,
              21.01699158547953
            ],
            [
              105.82984001125668,
              21.016993058568715
            ],
            [
              105.8298403313638,
              21.01699319249102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3955",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016988810674395,
          "to_latitude": 21.01699319249102,
          "to_longitude": 105.8298403313638,
          "from_longitude": 105.82982983248756
        }
      },
      {
        "id": 3949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987073658735,
            21.01646593661022,
            105.82995215087708,
            21.016880191596407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82987073658735,
              21.016880191596407
            ],
            [
              105.82988910077482,
              21.01680816012059
            ],
            [
              105.82991084407608,
              21.016725838874073
            ],
            [
              105.82993185800437,
              21.016632182092774
            ],
            [
              105.82993485203586,
              21.01661540272222
            ],
            [
              105.82993541372821,
              21.016612258564518
            ],
            [
              105.82993563111309,
              21.016611039053117
            ],
            [
              105.82994269860887,
              21.016556121896922
            ],
            [
              105.82994729415545,
              21.016495512932
            ],
            [
              105.82995215087708,
              21.01646593661022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3956",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016880191596407,
          "to_latitude": 21.01646593661022,
          "to_longitude": 105.82995215087708,
          "from_longitude": 105.82987073658735
        }
      },
      {
        "id": 3950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82992088604627,
            21.016610362009725,
            105.829946044483,
            21.01661540272222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82993485203586,
              21.01661540272222
            ],
            [
              105.82992088604627,
              21.016612880404026
            ],
            [
              105.82992148294558,
              21.016610362009725
            ],
            [
              105.82993541372821,
              21.016612258564518
            ],
            [
              105.829946044483,
              21.01661370605643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.3957",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01661540272222,
          "to_latitude": 21.01661370605643,
          "to_longitude": 105.829946044483,
          "from_longitude": 105.82993485203586
        }
      },
      {
        "id": 3951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919152814073,
            21.01667474129995,
            105.8297001802959,
            21.017184192648926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8297001802959,
              21.01667474129995
            ],
            [
              105.82969878851672,
              21.01673667502126
            ],
            [
              105.82968068313625,
              21.016795513611765
            ],
            [
              105.82965184954024,
              21.016869375479775
            ],
            [
              105.82963484660188,
              21.016919186371563
            ],
            [
              105.82962578138493,
              21.016920780514724
            ],
            [
              105.8296033185645,
              21.016954268748542
            ],
            [
              105.82956912035402,
              21.01700277974611
            ],
            [
              105.82953123383338,
              21.017043778532685
            ],
            [
              105.82950380152114,
              21.01706525912178
            ],
            [
              105.82948496605195,
              21.01708133534085
            ],
            [
              105.82947691937582,
              21.017076640771617
            ],
            [
              105.8294531150066,
              21.017092289602154
            ],
            [
              105.82939377102068,
              21.017142990650697
            ],
            [
              105.82938371253168,
              21.017137357498864
            ],
            [
              105.82937130747659,
              21.017127655014487
            ],
            [
              105.82936024277008,
              21.017129845988336
            ],
            [
              105.82927877113688,
              21.017157074369088
            ],
            [
              105.82921959682682,
              21.017170972615357
            ],
            [
              105.82921349937328,
              21.017173642464087
            ],
            [
              105.82919152814073,
              21.017184192648926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_101_Ngõ 71, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3958",
          "diaChiLapD": "Ngõ 71, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01667474129995,
          "to_latitude": 21.017184192648926,
          "to_longitude": 105.82919152814073,
          "from_longitude": 105.8297001802959
        }
      },
      {
        "id": 3952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950380152114,
            21.01706525912178,
            105.82958077102698,
            21.01713853098384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950380152114,
              21.01706525912178
            ],
            [
              105.82953718526282,
              21.01709784457867
            ],
            [
              105.82955931366955,
              21.01711850092063
            ],
            [
              105.82958077102698,
              21.01713853098384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101_Ngõ 71, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3959",
          "diaChiLapD": "Ngõ 71, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01706525912178,
          "to_latitude": 21.01713853098384,
          "to_longitude": 105.82958077102698,
          "from_longitude": 105.82950380152114
        }
      },
      {
        "id": 3953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82963484660188,
            21.016919186371563,
            105.82977535522183,
            21.016976928021027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82963484660188,
              21.016919186371563
            ],
            [
              105.82969725309708,
              21.0169437523274
            ],
            [
              105.8297281662995,
              21.016955769808067
            ],
            [
              105.82977535522183,
              21.016976928021027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101_Ngõ 71, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3960",
          "diaChiLapD": "Ngõ 71, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016919186371563,
          "to_latitude": 21.016976928021027,
          "to_longitude": 105.82977535522183,
          "from_longitude": 105.82963484660188
        }
      },
      {
        "id": 3954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918590372462,
            21.016759287529318,
            105.82968780795306,
            21.0171755093581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918590372462,
              21.0171755093581
            ],
            [
              105.82920738625441,
              21.017165823233032
            ],
            [
              105.82922054716134,
              21.01715878076355
            ],
            [
              105.82925865432682,
              21.017153318368837
            ],
            [
              105.82929218169257,
              21.017142990938993
            ],
            [
              105.82934415016798,
              21.017127028701456
            ],
            [
              105.82936627766382,
              21.017121082378218
            ],
            [
              105.8293733185415,
              21.017120456439738
            ],
            [
              105.82939276465498,
              21.017133914529804
            ],
            [
              105.82943065083525,
              21.017099801060997
            ],
            [
              105.82947189052231,
              21.017070068133833
            ],
            [
              105.82947893063059,
              21.017069129678852
            ],
            [
              105.82948831826533,
              21.0170706943565
            ],
            [
              105.82952788069461,
              21.01703564146832
            ],
            [
              105.82956174415321,
              21.016998084620997
            ],
            [
              105.8295942662706,
              21.01695333018334
            ],
            [
              105.82960365335059,
              21.016932361192783
            ],
            [
              105.82960868299882,
              21.016917338609172
            ],
            [
              105.82963181644672,
              21.01691483405455
            ],
            [
              105.82967473182008,
              21.016799034505222
            ],
            [
              105.82968780795306,
              21.016759287529318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102_Ngõ 71, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3961",
          "diaChiLapD": "Ngõ 71, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0171755093581,
          "to_latitude": 21.016759287529318,
          "to_longitude": 105.82968780795306,
          "from_longitude": 105.82918590372462
        }
      },
      {
        "id": 3955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290564263383,
            21.016952571133736,
            105.82913616219214,
            21.016977779464103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290564263383,
              21.016977779464103
            ],
            [
              105.82908738364036,
              21.01695880176528
            ],
            [
              105.82913616219214,
              21.016952571133736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3962",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016977779464103,
          "to_latitude": 21.016952571133736,
          "to_longitude": 105.82913616219214,
          "from_longitude": 105.8290564263383
        }
      },
      {
        "id": 3956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82917785282447,
            21.016595481465984,
            105.82933118464146,
            21.016924536852404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82917785282447,
              21.016924536852404
            ],
            [
              105.82924524353948,
              21.016855056535196
            ],
            [
              105.82925916413203,
              21.016837534752423
            ],
            [
              105.82928116203117,
              21.016808852180773
            ],
            [
              105.82932671552746,
              21.016725799835314
            ],
            [
              105.8293051165397,
              21.01669596952837
            ],
            [
              105.82930930760985,
              21.016667589627474
            ],
            [
              105.82931175640616,
              21.016651005310862
            ],
            [
              105.82933118464146,
              21.016595481465984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3963",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016924536852404,
          "to_latitude": 21.016595481465984,
          "to_longitude": 105.82933118464146,
          "from_longitude": 105.82917785282447
        }
      },
      {
        "id": 3957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900472349687,
            21.01638375786562,
            105.8292972633288,
            21.016713058979743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900472349687,
              21.01638375786562
            ],
            [
              105.82901845857074,
              21.016402114927633
            ],
            [
              105.82904436110435,
              21.016436802623534
            ],
            [
              105.82906863686547,
              21.016470072953066
            ],
            [
              105.82910779730557,
              21.016514574604034
            ],
            [
              105.82914239382197,
              21.016557422102366
            ],
            [
              105.82918339142878,
              21.01660710922952
            ],
            [
              105.82922043285339,
              21.016651624792928
            ],
            [
              105.8292559717292,
              21.016693876059737
            ],
            [
              105.82926770645098,
              21.016704830079274
            ],
            [
              105.82927915800416,
              21.01671189167368
            ],
            [
              105.82928594424338,
              21.016713058979743
            ],
            [
              105.82928944575511,
              21.01671289107238
            ],
            [
              105.8292972633288,
              21.016712516060952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3964",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01638375786562,
          "to_latitude": 21.016712516060952,
          "to_longitude": 105.8292972633288,
          "from_longitude": 105.82900472349687
        }
      },
      {
        "id": 3958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82901040884875,
            21.01637997816255,
            105.8292973268041,
            21.01671050343125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82901040884875,
              21.01637997816255
            ],
            [
              105.82903366820662,
              21.016408445581625
            ],
            [
              105.82904259189968,
              21.016427022087786
            ],
            [
              105.82905689856548,
              21.01644593545745
            ],
            [
              105.8290729553433,
              21.016464039135872
            ],
            [
              105.82907652521504,
              21.0164685821238
            ],
            [
              105.82910170510418,
              21.01650061423605
            ],
            [
              105.8291443983152,
              21.01655251414144
            ],
            [
              105.82921607433123,
              21.016639105656076
            ],
            [
              105.82923250311298,
              21.0166581974397
            ],
            [
              105.8292492670354,
              21.01667822784559
            ],
            [
              105.82927173046832,
              21.016701387239763
            ],
            [
              105.82927956039308,
              21.016709857455087
            ],
            [
              105.82928638126555,
              21.01671050343125
            ],
            [
              105.82928894124677,
              21.016710200113632
            ],
            [
              105.8292973268041,
              21.016709208140217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3965",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01637997816255,
          "to_latitude": 21.016709208140217,
          "to_longitude": 105.8292973268041,
          "from_longitude": 105.82901040884875
        }
      },
      {
        "id": 3959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82907652521504,
            21.01643358304099,
            105.82914886980741,
            21.0164685821238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82907652521504,
              21.0164685821238
            ],
            [
              105.82909950687849,
              21.01645011477652
            ],
            [
              105.82912211734495,
              21.01643358304099
            ],
            [
              105.82914886980741,
              21.016466621804245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3966",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0164685821238,
          "to_latitude": 21.016466621804245,
          "to_longitude": 105.82914886980741,
          "from_longitude": 105.82907652521504
        }
      },
      {
        "id": 3960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885907086637,
            21.016566686977274,
            105.82908360642237,
            21.016955035659304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82885907086637,
              21.016566686977274
            ],
            [
              105.8289245183004,
              21.016666535798915
            ],
            [
              105.8289988356783,
              21.016796276065715
            ],
            [
              105.82908158442594,
              21.01695033970926
            ],
            [
              105.82908360642237,
              21.016955035659304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3967",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016566686977274,
          "to_latitude": 21.016955035659304,
          "to_longitude": 105.82908360642237,
          "from_longitude": 105.82885907086637
        }
      },
      {
        "id": 3961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867321740883,
            21.0162663169912,
            105.82884826826145,
            21.01655868374062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884826826145,
              21.01655868374062
            ],
            [
              105.82883233028619,
              21.016524301532428
            ],
            [
              105.82881808599741,
              21.016500483325213
            ],
            [
              105.82877191028095,
              21.016423268437187
            ],
            [
              105.82870312232843,
              21.016318387692987
            ],
            [
              105.82867321740883,
              21.0162663169912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3968",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01655868374062,
          "to_latitude": 21.0162663169912,
          "to_longitude": 105.82867321740883,
          "from_longitude": 105.82884826826145
        }
      },
      {
        "id": 3962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82878992459293,
            21.016500483325213,
            105.82881808599741,
            21.01651553548398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881808599741,
              21.016500483325213
            ],
            [
              105.82881645455235,
              21.016501355660186
            ],
            [
              105.8288028179183,
              21.01650864428634
            ],
            [
              105.82878992459293,
              21.01651553548398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3969",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016500483325213,
          "to_latitude": 21.01651553548398,
          "to_longitude": 105.82878992459293,
          "from_longitude": 105.82881808599741
        }
      },
      {
        "id": 3963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291952871793,
            21.016459890380794,
            105.82942822410709,
            21.01688713602234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291952871793,
              21.01688713602234
            ],
            [
              105.82923082619399,
              21.016854587585645
            ],
            [
              105.82925706215798,
              21.016824463585525
            ],
            [
              105.82927516662092,
              21.016803182054137
            ],
            [
              105.8292835487226,
              21.016774075402036
            ],
            [
              105.82929193059837,
              21.016754671197575
            ],
            [
              105.82929595330988,
              21.01673933559538
            ],
            [
              105.82929058957289,
              21.016718992203394
            ],
            [
              105.82928944575511,
              21.01671289107238
            ],
            [
              105.82928894124677,
              21.016710200113632
            ],
            [
              105.82928105354618,
              21.016668141034945
            ],
            [
              105.82928427971446,
              21.016659755118937
            ],
            [
              105.82929963029736,
              21.016619370244136
            ],
            [
              105.82932906191084,
              21.016566652730834
            ],
            [
              105.82942822410709,
              21.016459890380794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3970",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01688713602234,
          "to_latitude": 21.016459890380794,
          "to_longitude": 105.82942822410709,
          "from_longitude": 105.8291952871793
        }
      },
      {
        "id": 3964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82928427971446,
            21.016659755118937,
            105.82930254823042,
            21.016660401033313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82928427971446,
              21.016659755118937
            ],
            [
              105.82928492198812,
              21.01665987392352
            ],
            [
              105.82928777802903,
              21.016660401033313
            ],
            [
              105.82930254823042,
              21.016659971355697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3971",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016659755118937,
          "to_latitude": 21.016659971355697,
          "to_longitude": 105.82930254823042,
          "from_longitude": 105.82928427971446
        }
      },
      {
        "id": 3965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82933375612714,
            21.01647025778256,
            105.82962931224365,
            21.016590126352096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82933375612714,
              21.016590126352096
            ],
            [
              105.82934917864308,
              21.016570408725563
            ],
            [
              105.82937097172326,
              21.01655131758858
            ],
            [
              105.82938572358096,
              21.01652565368288
            ],
            [
              105.82941891569064,
              21.016500302711847
            ],
            [
              105.82943820396798,
              21.016484885454183
            ],
            [
              105.82947225589375,
              21.01647151066239
            ],
            [
              105.82952677475076,
              21.016471818383632
            ],
            [
              105.8295493381108,
              21.01647025778256
            ],
            [
              105.82959702965726,
              21.01648092805731
            ],
            [
              105.82960883828125,
              21.016482459246166
            ],
            [
              105.82961920759595,
              21.01648342480825
            ],
            [
              105.82962931224365,
              21.01648436637006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3972",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016590126352096,
          "to_latitude": 21.01648436637006,
          "to_longitude": 105.82962931224365,
          "from_longitude": 105.82933375612714
        }
      },
      {
        "id": 3966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82958420756313,
            21.01648342480825,
            105.82961920759595,
            21.016617041609948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82961920759595,
              21.01648342480825
            ],
            [
              105.82960834668467,
              21.016535668841925
            ],
            [
              105.82958420756313,
              21.016617041609948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3973",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01648342480825,
          "to_latitude": 21.016617041609948,
          "to_longitude": 105.82958420756313,
          "from_longitude": 105.82961920759595
        }
      },
      {
        "id": 3967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951365658764,
            21.016198143100112,
            105.82989879735551,
            21.0164794161912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82989879735551,
              21.016198143100112
            ],
            [
              105.82988977651011,
              21.016269641260354
            ],
            [
              105.82987480216626,
              21.016321313762482
            ],
            [
              105.82986213981235,
              21.016356725058795
            ],
            [
              105.82985913692221,
              21.01636512344418
            ],
            [
              105.82984773991011,
              21.0163957378601
            ],
            [
              105.82983757010385,
              21.016423181387665
            ],
            [
              105.82983030435024,
              21.016445400042436
            ],
            [
              105.82980542987347,
              21.01646633710417
            ],
            [
              105.82978471626747,
              21.01647727503434
            ],
            [
              105.82972424017773,
              21.0164794161912
            ],
            [
              105.82962980521131,
              21.016470883327802
            ],
            [
              105.8295712039984,
              21.016463815936913
            ],
            [
              105.82955317727468,
              21.01646261403368
            ],
            [
              105.82951365658764,
              21.0164623993846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3974",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016198143100112,
          "to_latitude": 21.0164623993846,
          "to_longitude": 105.82951365658764,
          "from_longitude": 105.82989879735551
        }
      },
      {
        "id": 3968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82942830378698,
            21.016468384827004,
            105.82943820396798,
            21.016484885454183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82942830378698,
              21.016468384827004
            ],
            [
              105.82942835232735,
              21.016468464977404
            ],
            [
              105.82942862807404,
              21.016468926987635
            ],
            [
              105.82943109218515,
              21.01647303187523
            ],
            [
              105.82943820396798,
              21.016484885454183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3975",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016468384827004,
          "to_latitude": 21.016484885454183,
          "to_longitude": 105.82943820396798,
          "from_longitude": 105.82942830378698
        }
      },
      {
        "id": 3969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82988190744483,
            21.015897860612757,
            105.82990648925716,
            21.016171962519344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82990225208134,
              21.016171962519344
            ],
            [
              105.82990648925716,
              21.016103258789034
            ],
            [
              105.82990107686226,
              21.01602858123037
            ],
            [
              105.82988190744483,
              21.015897860612757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_125_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3976",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016171962519344,
          "to_latitude": 21.015897860612757,
          "to_longitude": 105.82988190744483,
          "from_longitude": 105.82990225208134
        }
      },
      {
        "id": 3970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82974229199455,
            21.015908367790004,
            105.82978315903021,
            21.016091117669717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82974229199455,
              21.015908367790004
            ],
            [
              105.8297606942713,
              21.015986858645185
            ],
            [
              105.8297726727517,
              21.016040140195905
            ],
            [
              105.82978315903021,
              21.016091117669717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_125_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3977",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015908367790004,
          "to_latitude": 21.016091117669717,
          "to_longitude": 105.82978315903021,
          "from_longitude": 105.82974229199455
        }
      },
      {
        "id": 3971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962276381113,
            21.015886249225794,
            105.82988944087168,
            21.016035538194423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988944087168,
              21.015896904381044
            ],
            [
              105.82988190744483,
              21.015897860612757
            ],
            [
              105.8298704136187,
              21.0158993211948
            ],
            [
              105.82985477519594,
              21.015886249225794
            ],
            [
              105.82978562897166,
              21.015899617329055
            ],
            [
              105.82974229199455,
              21.015908367790004
            ],
            [
              105.82971546985495,
              21.015915253827256
            ],
            [
              105.82968395670659,
              21.015923772285404
            ],
            [
              105.82966845749989,
              21.015928388386072
            ],
            [
              105.82965696216272,
              21.015942271923258
            ],
            [
              105.82963751594617,
              21.01596668338952
            ],
            [
              105.82962276381113,
              21.01598358422451
            ],
            [
              105.82962812793531,
              21.016011125408443
            ],
            [
              105.82963952728515,
              21.016035538194423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_125_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3978",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015896904381044,
          "to_latitude": 21.016035538194423,
          "to_longitude": 105.82963952728515,
          "from_longitude": 105.82988944087168
        }
      },
      {
        "id": 3972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940516952021,
            21.015768257680715,
            105.82988227880217,
            21.015927875211972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988227880217,
              21.015854578048412
            ],
            [
              105.82986312015963,
              21.015857239373
            ],
            [
              105.82986491130376,
              21.01588106133398
            ],
            [
              105.82983365643699,
              21.015886360367617
            ],
            [
              105.82981908412665,
              21.01588794434921
            ],
            [
              105.82971550320575,
              21.015910675215125
            ],
            [
              105.82970361508295,
              21.01591111074171
            ],
            [
              105.82965126191769,
              21.015927875211972
            ],
            [
              105.82964690337941,
              21.015918485761613
            ],
            [
              105.82963852221303,
              21.015900958899252
            ],
            [
              105.82962175797194,
              21.01586277640953
            ],
            [
              105.82960633544306,
              21.015829601179323
            ],
            [
              105.82959158275658,
              21.015833356471607
            ],
            [
              105.82957146629776,
              21.015838677013495
            ],
            [
              105.82953056270526,
              21.01585276084284
            ],
            [
              105.829516816401,
              21.01585620412503
            ],
            [
              105.8294946880257,
              21.01582334158044
            ],
            [
              105.82945814314122,
              21.01576857113615
            ],
            [
              105.8294457381734,
              21.015768257680715
            ],
            [
              105.82940516952021,
              21.01578828848966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_126_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3979",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015854578048412,
          "to_latitude": 21.01578828848966,
          "to_longitude": 105.82940516952021,
          "from_longitude": 105.82988227880217
        }
      },
      {
        "id": 3973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971618340443,
            21.015730869327584,
            105.82981908412665,
            21.01588794434921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981908412665,
              21.01588794434921
            ],
            [
              105.8298076847718,
              21.01582841055982
            ],
            [
              105.8298027198404,
              21.01576331403282
            ],
            [
              105.82978939568468,
              21.015733517486503
            ],
            [
              105.82974815681757,
              21.01574384648779
            ],
            [
              105.82974270779525,
              21.015730869327584
            ],
            [
              105.82971618340443,
              21.015738711330957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_126_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3980",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01588794434921,
          "to_latitude": 21.015738711330957,
          "to_longitude": 105.82971618340443,
          "from_longitude": 105.82981908412665
        }
      },
      {
        "id": 3974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82979443324521,
            21.015478587352142,
            105.82987435911367,
            21.01584813694173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82987435911367,
              21.01584813694173
            ],
            [
              105.82987142051233,
              21.015827469221623
            ],
            [
              105.829860150261,
              21.015768496612225
            ],
            [
              105.82985714373817,
              21.015752764446358
            ],
            [
              105.82984630467091,
              21.015704264436764
            ],
            [
              105.82983682673348,
              21.015661150945654
            ],
            [
              105.82983663294213,
              21.015659158472463
            ],
            [
              105.82983439839761,
              21.015636213898528
            ],
            [
              105.82982062684005,
              21.01555719104225
            ],
            [
              105.82980037139347,
              21.015503125217275
            ],
            [
              105.82979443324521,
              21.015478587352142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_127_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3981",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01584813694173,
          "to_latitude": 21.015478587352142,
          "to_longitude": 105.82979443324521,
          "from_longitude": 105.82987435911367
        }
      },
      {
        "id": 3975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983663294213,
            21.015657610395387,
            105.82984810317971,
            21.015659158472463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82984810317971,
              21.015657610395387
            ],
            [
              105.82984194944822,
              21.015658440669426
            ],
            [
              105.82983762972998,
              21.01565902444921
            ],
            [
              105.82983663294213,
              21.015659158472463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_127_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3982",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015657610395387,
          "to_latitude": 21.015659158472463,
          "to_longitude": 105.82983663294213,
          "from_longitude": 105.82984810317971
        }
      },
      {
        "id": 3976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905023152462,
            21.015976080252365,
            105.82950356182359,
            21.016467419055065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950356182359,
              21.016467419055065
            ],
            [
              105.82950308531005,
              21.01646248525187
            ],
            [
              105.82945963341334,
              21.01646109840407
            ],
            [
              105.82945538608274,
              21.016432855384004
            ],
            [
              105.82942617884915,
              21.016398107091067
            ],
            [
              105.82939210832184,
              21.016358363278755
            ],
            [
              105.82936419848214,
              21.016327910711563
            ],
            [
              105.82934269039902,
              21.016304594928318
            ],
            [
              105.82930886029077,
              21.01626222153967
            ],
            [
              105.82927935674961,
              21.01622136614979
            ],
            [
              105.8292666168844,
              21.016196327615393
            ],
            [
              105.82926465507246,
              21.01618476957001
            ],
            [
              105.82925745521491,
              21.016171421573787
            ],
            [
              105.82923999253717,
              21.016143307091294
            ],
            [
              105.82922504207231,
              21.01612277919745
            ],
            [
              105.82919433861959,
              21.016095071569694
            ],
            [
              105.8291887896706,
              21.01608993939141
            ],
            [
              105.82915299457852,
              21.016057166896612
            ],
            [
              105.82912047392848,
              21.016030655852628
            ],
            [
              105.82907968063971,
              21.01599945221583
            ],
            [
              105.82905023152462,
              21.015976080252365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_121_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3983",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016467419055065,
          "to_latitude": 21.015976080252365,
          "to_longitude": 105.82905023152462,
          "from_longitude": 105.82950356182359
        }
      },
      {
        "id": 3977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905376814823,
            21.015970607535905,
            105.8295074853681,
            21.01646737915005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905376814823,
              21.015970607535905
            ],
            [
              105.82912378919744,
              21.016027948083508
            ],
            [
              105.82916737531593,
              21.016065818352892
            ],
            [
              105.82919958044685,
              21.016086235959826
            ],
            [
              105.82924061610025,
              21.01613116831482
            ],
            [
              105.82925878780163,
              21.01616295004945
            ],
            [
              105.8292716455254,
              21.01619695342577
            ],
            [
              105.8293081908376,
              21.016252663258765
            ],
            [
              105.82934574141939,
              21.016302112442997
            ],
            [
              105.82936217076345,
              21.016318700471864
            ],
            [
              105.82937643270145,
              21.016332825131
            ],
            [
              105.8294283190015,
              21.016387291371608
            ],
            [
              105.8294316100318,
              21.01639150693731
            ],
            [
              105.82947151027899,
              21.01644261650528
            ],
            [
              105.82949374028412,
              21.016460269140616
            ],
            [
              105.82950657487079,
              21.01646207081796
            ],
            [
              105.8295074853681,
              21.01646737915005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_122_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3984",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015970607535905,
          "to_latitude": 21.01646737915005,
          "to_longitude": 105.8295074853681,
          "from_longitude": 105.82905376814823
        }
      },
      {
        "id": 3978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294316100318,
            21.016303418686558,
            105.82953503860507,
            21.01639150693731
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294316100318,
              21.01639150693731
            ],
            [
              105.82953503860507,
              21.016303418686558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3985",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01639150693731,
          "to_latitude": 21.016303418686558,
          "to_longitude": 105.82953503860507,
          "from_longitude": 105.8294316100318
        }
      },
      {
        "id": 3979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8293381903956,
            21.015989068703945,
            105.82947230746497,
            21.016318700471864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82944939312722,
              21.016045635746234
            ],
            [
              105.8294149016281,
              21.015989068703945
            ],
            [
              105.8293381903956,
              21.016028667046733
            ],
            [
              105.82936814538574,
              21.016070972613623
            ],
            [
              105.82939293098322,
              21.01611502853687
            ],
            [
              105.82941515195601,
              21.016149671802093
            ],
            [
              105.82944244236806,
              21.01618692415336
            ],
            [
              105.8294709799608,
              21.01622635261145
            ],
            [
              105.82947230746497,
              21.016228096589803
            ],
            [
              105.82942845675937,
              21.016263647272826
            ],
            [
              105.8293902196353,
              21.01629618812749
            ],
            [
              105.82936217076345,
              21.016318700471864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3986",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016045635746234,
          "to_latitude": 21.016318700471864,
          "to_longitude": 105.82936217076345,
          "from_longitude": 105.82944939312722
        }
      },
      {
        "id": 3980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919958044685,
            21.015955530044597,
            105.82927242967838,
            21.016086235959826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919958044685,
              21.016086235959826
            ],
            [
              105.82927242967838,
              21.016027173026984
            ],
            [
              105.82921110239663,
              21.015955530044597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3987",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016086235959826,
          "to_latitude": 21.015955530044597,
          "to_longitude": 105.82921110239663,
          "from_longitude": 105.82919958044685
        }
      },
      {
        "id": 3981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859434903612,
            21.015829883277057,
            105.82886096711204,
            21.016059501197248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886096711204,
              21.015829883277057
            ],
            [
              105.82884334222241,
              21.015846028494675
            ],
            [
              105.82883104002974,
              21.0158577002968
            ],
            [
              105.82882017242984,
              21.01586978239929
            ],
            [
              105.8287962360241,
              21.01589076171377
            ],
            [
              105.82878169663691,
              21.015905096281447
            ],
            [
              105.82878070642131,
              21.01590607207278
            ],
            [
              105.82876383022221,
              21.015919489506988
            ],
            [
              105.82873622043854,
              21.015943654319994
            ],
            [
              105.82871616669013,
              21.015962074839845
            ],
            [
              105.82868680549186,
              21.015987337466594
            ],
            [
              105.82868121578193,
              21.015991538499282
            ],
            [
              105.82864780544217,
              21.016016220197752
            ],
            [
              105.82860469943247,
              21.016049909110812
            ],
            [
              105.82859434903612,
              21.016059501197248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3988",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015829883277057,
          "to_latitude": 21.016059501197248,
          "to_longitude": 105.82859434903612,
          "from_longitude": 105.82886096711204
        }
      },
      {
        "id": 3982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82877717644106,
            21.015900388109518,
            105.82878169663691,
            21.015905096281447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82877717644106,
              21.015900388109518
            ],
            [
              105.82877912306486,
              21.01590241540011
            ],
            [
              105.82878155829479,
              21.015904954245748
            ],
            [
              105.82878169663691,
              21.015905096281447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3989",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015900388109518,
          "to_latitude": 21.015905096281447,
          "to_longitude": 105.82878169663691,
          "from_longitude": 105.82877717644106
        }
      },
      {
        "id": 3983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890539737289,
            21.015680761054877,
            105.82919726737512,
            21.01593018587295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890539737289,
              21.01593018587295
            ],
            [
              105.82896495752479,
              21.01587287504227
            ],
            [
              105.82898045277555,
              21.015859359543487
            ],
            [
              105.82892916503978,
              21.01580330688237
            ],
            [
              105.82895536033755,
              21.01579056118599
            ],
            [
              105.82902111170175,
              21.015751048035202
            ],
            [
              105.82903369280817,
              21.015743026601477
            ],
            [
              105.82904890898988,
              21.015733324068407
            ],
            [
              105.82906119317171,
              21.015725882143986
            ],
            [
              105.8290934524552,
              21.015706340813782
            ],
            [
              105.82914320678489,
              21.015680761054877
            ],
            [
              105.82917196161904,
              21.015737594570194
            ],
            [
              105.82919726737512,
              21.01578301818475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_133_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3990",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01593018587295,
          "to_latitude": 21.01578301818475,
          "to_longitude": 105.82919726737512,
          "from_longitude": 105.82890539737289
        }
      },
      {
        "id": 3984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905158335372,
            21.01570867479422,
            105.82906119317171,
            21.015725882143986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905889230929,
              21.015717371423662
            ],
            [
              105.82905359398225,
              21.01570867479422
            ],
            [
              105.82905280906164,
              21.015709200683556
            ],
            [
              105.82905158335372,
              21.015710034914353
            ],
            [
              105.82906119317171,
              21.015725882143986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_133_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3991",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015717371423662,
          "to_latitude": 21.015725882143986,
          "to_longitude": 105.82906119317171,
          "from_longitude": 105.82905889230929
        }
      },
      {
        "id": 3985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82913218850537,
            21.01549577993482,
            105.82952297171109,
            21.01568384959121
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82913218850537,
              21.01568384959121
            ],
            [
              105.8291672170039,
              21.015666710879714
            ],
            [
              105.8292060511358,
              21.015647523161675
            ],
            [
              105.82924311039835,
              21.01562953815022
            ],
            [
              105.8293000275996,
              21.015602279562863
            ],
            [
              105.82934540228443,
              21.01557933232
            ],
            [
              105.8293541481039,
              21.01557496233241
            ],
            [
              105.8293927383041,
              21.015555681826584
            ],
            [
              105.82952297171109,
              21.01549577993482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_137_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3992",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568384959121,
          "to_latitude": 21.01549577993482,
          "to_longitude": 105.82952297171109,
          "from_longitude": 105.82913218850537
        }
      },
      {
        "id": 3986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8293454532784,
            21.015564053888596,
            105.8293541481039,
            21.01557496233241
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8293541481039,
              21.01557496233241
            ],
            [
              105.82935219200918,
              21.015571744643413
            ],
            [
              105.82935014466591,
              21.015568377463776
            ],
            [
              105.82934751679623,
              21.015564053888596
            ],
            [
              105.8293454532784,
              21.015565068334567
            ],
            [
              105.82934791501708,
              21.01556944330027
            ],
            [
              105.8293498269056,
              21.015572842753148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_137_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3993",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01557496233241,
          "to_latitude": 21.015572842753148,
          "to_longitude": 105.8293498269056,
          "from_longitude": 105.8293541481039
        }
      },
      {
        "id": 3987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82949284393426,
            21.01547523580232,
            105.82977455263891,
            21.015640720928012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82953307714246,
              21.015640720928012
            ],
            [
              105.82949284393426,
              21.0155443252632
            ],
            [
              105.82954460788548,
              21.015527315330228
            ],
            [
              105.82957703867669,
              21.015518280296252
            ],
            [
              105.82963395096176,
              21.015506847939886
            ],
            [
              105.82970733842245,
              21.01548952025227
            ],
            [
              105.82977455263891,
              21.01547523580232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_139_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3994",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015640720928012,
          "to_latitude": 21.01547523580232,
          "to_longitude": 105.82977455263891,
          "from_longitude": 105.82953307714246
        }
      },
      {
        "id": 3988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82963183802828,
            21.015498405859873,
            105.82963395096176,
            21.015506847939886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82963183802828,
              21.015498405859873
            ],
            [
              105.82963185179972,
              21.01549846088945
            ],
            [
              105.82963190690566,
              21.01549868462065
            ],
            [
              105.82963322183515,
              21.015503895238588
            ],
            [
              105.82963395096176,
              21.015506847939886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_139_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3995",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015498405859873,
          "to_latitude": 21.015506847939886,
          "to_longitude": 105.82963395096176,
          "from_longitude": 105.82963183802828
        }
      },
      {
        "id": 3989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931412852142,
            21.015438817519005,
            105.8293694914164,
            21.015561820005072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82936386410982,
              21.015561820005072
            ],
            [
              105.82933746620394,
              21.015521205994844
            ],
            [
              105.82931412852142,
              21.015483079900484
            ],
            [
              105.8293694914164,
              21.015438817519005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3996",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015561820005072,
          "to_latitude": 21.015438817519005,
          "to_longitude": 105.8293694914164,
          "from_longitude": 105.82936386410982
        }
      },
      {
        "id": 3990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.829286269142,
            21.015474770900394,
            105.82972690438949,
            21.015599693855094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829286269142,
              21.015599693855094
            ],
            [
              105.82930241298311,
              21.015591800455017
            ],
            [
              105.82932114651938,
              21.01558271843
            ],
            [
              105.82934513371244,
              21.015570772858116
            ],
            [
              105.82934791501708,
              21.01556944330027
            ],
            [
              105.82935014466591,
              21.015568377463776
            ],
            [
              105.82936386410982,
              21.015561820005072
            ],
            [
              105.82940467854843,
              21.015542583750427
            ],
            [
              105.829436970266,
              21.015527705552035
            ],
            [
              105.829482155367,
              21.015507012745555
            ],
            [
              105.8295180302593,
              21.01549073874
            ],
            [
              105.82952831885285,
              21.015489992187785
            ],
            [
              105.82954498579385,
              21.01550520162516
            ],
            [
              105.82954680257083,
              21.01550653495966
            ],
            [
              105.8295572570273,
              21.01551421107753
            ],
            [
              105.82958910869499,
              21.015506387380817
            ],
            [
              105.82963324086431,
              21.01549463979469
            ],
            [
              105.82972690438949,
              21.015474770900394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3997",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015599693855094,
          "to_latitude": 21.015474770900394,
          "to_longitude": 105.82972690438949,
          "from_longitude": 105.829286269142
        }
      },
      {
        "id": 3991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82905226943596,
            21.015615233214618,
            105.82924489170108,
            21.015708334410263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905226943596,
              21.015708334410263
            ],
            [
              105.82911648103932,
              21.015677066144043
            ],
            [
              105.82917087126424,
              21.01565067617178
            ],
            [
              105.82921330955541,
              21.015629535274012
            ],
            [
              105.82924489170108,
              21.015615233214618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_134_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3998",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015708334410263,
          "to_latitude": 21.015615233214618,
          "to_longitude": 105.82924489170108,
          "from_longitude": 105.82905226943596
        }
      },
      {
        "id": 3992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903310707844,
            21.015644711865512,
            105.82905226943596,
            21.015708334410263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905226943596,
              21.015708334410263
            ],
            [
              105.82903310707844,
              21.01567394896125
            ],
            [
              105.82904851934329,
              21.01566474224686
            ],
            [
              105.8290371202924,
              21.015644711865512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_134_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.3999",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015708334410263,
          "to_latitude": 21.015644711865512,
          "to_longitude": 105.8290371202924,
          "from_longitude": 105.82905226943596
        }
      },
      {
        "id": 3993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82994617747066,
            21.015058721358322,
            105.8300051182704,
            21.015468797749403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82994617747066,
              21.015468797749403
            ],
            [
              105.82995111918387,
              21.015418947213256
            ],
            [
              105.82995345935718,
              21.015390396584216
            ],
            [
              105.82995875470397,
              21.015274071448584
            ],
            [
              105.82995973354512,
              21.015270172868696
            ],
            [
              105.82998152778359,
              21.015183365036908
            ],
            [
              105.82999151893335,
              21.01513078018879
            ],
            [
              105.8300051182704,
              21.015058721358322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4000",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015468797749403,
          "to_latitude": 21.015058721358322,
          "to_longitude": 105.8300051182704,
          "from_longitude": 105.82994617747066
        }
      },
      {
        "id": 3994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82995973354512,
            21.015270172868696,
            105.82997120884913,
            21.01527284914463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82995973354512,
              21.015270172868696
            ],
            [
              105.82996137865997,
              21.015270557746295
            ],
            [
              105.82996533635236,
              21.01527147960008
            ],
            [
              105.82997120884913,
              21.01527284914463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4001",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015270172868696,
          "to_latitude": 21.01527284914463,
          "to_longitude": 105.82997120884913,
          "from_longitude": 105.82995973354512
        }
      },
      {
        "id": 3995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82981407831547,
            21.014118963696603,
            105.82998037584474,
            21.014304354872724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82991212925785,
              21.014304354872724
            ],
            [
              105.82991245658451,
              21.014302665147568
            ],
            [
              105.82991279328527,
              21.014300931118637
            ],
            [
              105.82990024459825,
              21.01429235297717
            ],
            [
              105.82988985095751,
              21.01427983438873
            ],
            [
              105.82987074031487,
              21.014256360644094
            ],
            [
              105.82983419492415,
              21.01421536084291
            ],
            [
              105.82981407831547,
              21.01419313935225
            ],
            [
              105.82986001116201,
              21.01416684913236
            ],
            [
              105.8299025911019,
              21.01415245267108
            ],
            [
              105.82998037584474,
              21.014118963696603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_219_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4002",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014304354872724,
          "to_latitude": 21.014118963696603,
          "to_longitude": 105.82998037584474,
          "from_longitude": 105.82991212925785
        }
      },
      {
        "id": 3996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8295065888275,
            21.014304667742532,
            105.82989353978739,
            21.014607411072635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8295065888275,
              21.014607411072635
            ],
            [
              105.82955575939322,
              21.01457949740382
            ],
            [
              105.82959069623352,
              21.014559755059278
            ],
            [
              105.82959215433547,
              21.014558851949744
            ],
            [
              105.8296317047546,
              21.014533574715404
            ],
            [
              105.82967530014331,
              21.014479787303593
            ],
            [
              105.82967573968403,
              21.014479428386075
            ],
            [
              105.82970431083552,
              21.014456106868924
            ],
            [
              105.82977240134387,
              21.014404513136974
            ],
            [
              105.82977433632308,
              21.014402871571377
            ],
            [
              105.82979308152514,
              21.01438688789499
            ],
            [
              105.82981360023912,
              21.01436946214773
            ],
            [
              105.82982436528475,
              21.014360089258588
            ],
            [
              105.82984691835695,
              21.014342295004592
            ],
            [
              105.82989353978739,
              21.014304667742532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_217_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4003",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014607411072635,
          "to_latitude": 21.014304667742532,
          "to_longitude": 105.82989353978739,
          "from_longitude": 105.8295065888275
        }
      },
      {
        "id": 3997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82967573968403,
            21.014479428386075,
            105.82968586472312,
            21.01449016803977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82968586472312,
              21.01449016803977
            ],
            [
              105.82967830138865,
              21.014482052409175
            ],
            [
              105.8296770569713,
              21.01448071899415
            ],
            [
              105.82967617734494,
              21.01447977309041
            ],
            [
              105.82967573968403,
              21.014479428386075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_217_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4004",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01449016803977,
          "to_latitude": 21.014479428386075,
          "to_longitude": 105.82967573968403,
          "from_longitude": 105.82968586472312
        }
      },
      {
        "id": 3998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82976785364643,
            21.014987808702326,
            105.82991450170164,
            21.015230332939947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981076910782,
              21.015230332939947
            ],
            [
              105.8297986991473,
              21.015173371187988
            ],
            [
              105.82979065238905,
              21.015122043100213
            ],
            [
              105.8297829415426,
              21.01508448665833
            ],
            [
              105.82977455965278,
              21.015040043684817
            ],
            [
              105.82976785364643,
              21.015006242468484
            ],
            [
              105.82977447004023,
              21.01500402078053
            ],
            [
              105.82982430277646,
              21.014987808702326
            ],
            [
              105.8298608093436,
              21.01500002198767
            ],
            [
              105.82990569557145,
              21.01501662741983
            ],
            [
              105.82991450170164,
              21.01502003749432
            ],
            [
              105.82990028824624,
              21.015069776097885
            ],
            [
              105.82987044830755,
              21.015168050775035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_220_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4005",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015230332939947,
          "to_latitude": 21.015168050775035,
          "to_longitude": 105.82987044830755,
          "from_longitude": 105.82981076910782
        }
      },
      {
        "id": 3999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82974187675993,
            21.01502408223107,
            105.82982317498006,
            21.01547456600829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82974187675993,
              21.01547456600829
            ],
            [
              105.8298089287125,
              21.015461729303503
            ],
            [
              105.82981465047683,
              21.01544697972445
            ],
            [
              105.82982018949451,
              21.015375202286712
            ],
            [
              105.82982317498006,
              21.015341752126048
            ],
            [
              105.82981908656852,
              21.015323287559085
            ],
            [
              105.82981298417131,
              21.015298618624183
            ],
            [
              105.82980864848979,
              21.015278579134485
            ],
            [
              105.82979131393414,
              21.015208068484895
            ],
            [
              105.829784234721,
              21.015171491672184
            ],
            [
              105.82978087628797,
              21.01514900238362
            ],
            [
              105.82977504276678,
              21.015112422204222
            ],
            [
              105.82977040978578,
              21.01508645719734
            ],
            [
              105.82976271869482,
              21.015048297303228
            ],
            [
              105.82975578387199,
              21.01502408223107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_128_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4006",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01547456600829,
          "to_latitude": 21.01502408223107,
          "to_longitude": 105.82975578387199,
          "from_longitude": 105.82974187675993
        }
      },
      {
        "id": 4000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82981298417131,
            21.01529674451148,
            105.82981852320852,
            21.015298618624183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981298417131,
              21.015298618624183
            ],
            [
              105.82981852320852,
              21.01529674451148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_128_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4007",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015298618624183,
          "to_latitude": 21.01529674451148,
          "to_longitude": 105.82981852320852,
          "from_longitude": 105.82981298417131
        }
      },
      {
        "id": 4001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82964366528923,
            21.014563359351342,
            105.82996840766143,
            21.014861916547442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964366528923,
              21.014861916547442
            ],
            [
              105.82965281910073,
              21.014854796944032
            ],
            [
              105.82976894987799,
              21.014764464434304
            ],
            [
              105.82983117782291,
              21.01473239900474
            ],
            [
              105.82986228630394,
              21.014723808434834
            ],
            [
              105.82988012718398,
              21.014711973158064
            ],
            [
              105.82990699055432,
              21.014694149741757
            ],
            [
              105.82996840766143,
              21.014563359351342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_211_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4008",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014861916547442,
          "to_latitude": 21.014563359351342,
          "to_longitude": 105.82996840766143,
          "from_longitude": 105.82964366528923
        }
      },
      {
        "id": 4002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82988012718398,
            21.014711973158064,
            105.8299309650731,
            21.01478739288834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988012718398,
              21.014711973158064
            ],
            [
              105.8299309650731,
              21.01478739288834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_211_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4009",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014711973158064,
          "to_latitude": 21.01478739288834,
          "to_longitude": 105.8299309650731,
          "from_longitude": 105.82988012718398
        }
      },
      {
        "id": 4003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951126142717,
            21.014266027004055,
            105.82998238618192,
            21.014613589837694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82951126142717,
              21.014613589837694
            ],
            [
              105.82957195547142,
              21.01458002811075
            ],
            [
              105.82963749693664,
              21.01453956014665
            ],
            [
              105.82968802668326,
              21.01449460678576
            ],
            [
              105.82969747650196,
              21.01448587799537
            ],
            [
              105.82972218249263,
              21.0144630558403
            ],
            [
              105.82979591994007,
              21.014395017532806
            ],
            [
              105.82982328259804,
              21.014372515696724
            ],
            [
              105.82984274069638,
              21.014356552925094
            ],
            [
              105.82989909235907,
              21.014311158333594
            ],
            [
              105.82991017078905,
              21.01431131293982
            ],
            [
              105.82991945507997,
              21.014311441974456
            ],
            [
              105.82993675405594,
              21.01429851191709
            ],
            [
              105.82998238618192,
              21.014266027004055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_218_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4010",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014613589837694,
          "to_latitude": 21.014266027004055,
          "to_longitude": 105.82998238618192,
          "from_longitude": 105.82951126142717
        }
      },
      {
        "id": 4004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950983630387,
            21.014617499623622,
            105.82972555151467,
            21.014949524798492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82972555151467,
              21.014949524798492
            ],
            [
              105.82965517591207,
              21.01486837628527
            ],
            [
              105.82965574453169,
              21.01486126693414
            ],
            [
              105.82965281910073,
              21.014854796944032
            ],
            [
              105.82964199871584,
              21.014853129064356
            ],
            [
              105.82962064200088,
              21.014827930247417
            ],
            [
              105.82960920556636,
              21.014816099566403
            ],
            [
              105.82959142625896,
              21.014793504461682
            ],
            [
              105.829588433777,
              21.014786563313535
            ],
            [
              105.8295684881727,
              21.01474030703443
            ],
            [
              105.82951729629293,
              21.014631801957346
            ],
            [
              105.82950983630387,
              21.014617499623622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_213_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4011",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014949524798492,
          "to_latitude": 21.014617499623622,
          "to_longitude": 105.82950983630387,
          "from_longitude": 105.82972555151467
        }
      },
      {
        "id": 4005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82938406210407,
            21.01481549359496,
            105.82970178166104,
            21.01505415643118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294491433579,
              21.01505415643118
            ],
            [
              105.82945239255382,
              21.015052029752276
            ],
            [
              105.82945535133041,
              21.01505009236012
            ],
            [
              105.82942988213784,
              21.015013199637533
            ],
            [
              105.82938406210407,
              21.01493712845444
            ],
            [
              105.8295331175393,
              21.014846165915795
            ],
            [
              105.82956563935552,
              21.014829265155775
            ],
            [
              105.82958407903925,
              21.01481924969857
            ],
            [
              105.8295954792885,
              21.01481549359496
            ],
            [
              105.82962759115495,
              21.014850915966715
            ],
            [
              105.82966468057965,
              21.014893559733594
            ],
            [
              105.82970178166104,
              21.01493830704689
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_210_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4012",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01505415643118,
          "to_latitude": 21.01493830704689,
          "to_longitude": 105.82970178166104,
          "from_longitude": 105.8294491433579
        }
      },
      {
        "id": 4006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931376278698,
            21.01410151503097,
            105.82962020571627,
            21.014293450850936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8293174015596,
              21.014293450850936
            ],
            [
              105.82932477125803,
              21.014289688219076
            ],
            [
              105.82931376278698,
              21.014273652720743
            ],
            [
              105.82934527869972,
              21.014260820519755
            ],
            [
              105.82939288829205,
              21.014236095668934
            ],
            [
              105.8294740253383,
              21.01419979058062
            ],
            [
              105.82951660515134,
              21.014176316798043
            ],
            [
              105.82956287280804,
              21.01414533186444
            ],
            [
              105.82958433057098,
              21.01412811834006
            ],
            [
              105.82959841225316,
              21.014117164003384
            ],
            [
              105.82961014682678,
              21.014109339948245
            ],
            [
              105.82962020571627,
              21.01410151503097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_216_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4013",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014293450850936,
          "to_latitude": 21.01410151503097,
          "to_longitude": 105.82962020571627,
          "from_longitude": 105.8293174015596
        }
      },
      {
        "id": 4007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82932610048135,
            21.014296096448216,
            105.82950271284902,
            21.01460578068029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950271284902,
              21.01460578068029
            ],
            [
              105.8294765044241,
              21.01456078872858
            ],
            [
              105.82946314747903,
              21.014538772692667
            ],
            [
              105.8294516748678,
              21.014521437016185
            ],
            [
              105.82944050756579,
              21.014501385657514
            ],
            [
              105.82942750782259,
              21.0144794265866
            ],
            [
              105.82942207993284,
              21.014470257308183
            ],
            [
              105.82941565560436,
              21.014456064630366
            ],
            [
              105.82941352192532,
              21.01445238987163
            ],
            [
              105.82938549377167,
              21.014404126259336
            ],
            [
              105.82937368724879,
              21.014384851172714
            ],
            [
              105.82936362835353,
              21.014364669759793
            ],
            [
              105.8293450186088,
              21.014329652095707
            ],
            [
              105.82932610048135,
              21.014296096448216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_214_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4014",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01460578068029,
          "to_latitude": 21.014296096448216,
          "to_longitude": 105.82932610048135,
          "from_longitude": 105.82950271284902
        }
      },
      {
        "id": 4008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294516748678,
            21.014463274250843,
            105.82955940301215,
            21.014521437016185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294516748678,
              21.014521437016185
            ],
            [
              105.82952312857441,
              21.014483442206974
            ],
            [
              105.82955940301215,
              21.014463274250843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_214_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4015",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014521437016185,
          "to_latitude": 21.014463274250843,
          "to_longitude": 105.82955940301215,
          "from_longitude": 105.8294516748678
        }
      },
      {
        "id": 4009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82942592983999,
            21.0144794265866,
            105.82942750782259,
            21.01448022098886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82942750782259,
              21.0144794265866
            ],
            [
              105.82942633656371,
              21.014480015780297
            ],
            [
              105.82942592983999,
              21.01448022098886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_214_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4016",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0144794265866,
          "to_latitude": 21.01448022098886,
          "to_longitude": 105.82942592983999,
          "from_longitude": 105.82942750782259
        }
      },
      {
        "id": 4010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82920995077615,
            21.01441285416941,
            105.82938696760878,
            21.014589254865417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82930792343544,
              21.014564314798044
            ],
            [
              105.82926507647399,
              21.014589254865417
            ],
            [
              105.82924191342306,
              21.014558674351207
            ],
            [
              105.8292206412672,
              21.014525086811712
            ],
            [
              105.82920995077615,
              21.014508106789922
            ],
            [
              105.82924300430905,
              21.014487342210995
            ],
            [
              105.82927329222038,
              21.01446969820207
            ],
            [
              105.82929942549758,
              21.014454132890123
            ],
            [
              105.82931523883673,
              21.01444685619891
            ],
            [
              105.8293827975669,
              21.014414754110035
            ],
            [
              105.82938680449467,
              21.014412929029064
            ],
            [
              105.82938696760878,
              21.01441285416941
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_215_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4017",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014564314798044,
          "to_latitude": 21.01441285416941,
          "to_longitude": 105.82938696760878,
          "from_longitude": 105.82930792343544
        }
      },
      {
        "id": 4011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8293827975669,
            21.014414754110035,
            105.82949749073443,
            21.014612428590617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8293827975669,
              21.014414754110035
            ],
            [
              105.82942101612768,
              21.01447879797605
            ],
            [
              105.82944162874651,
              21.0145148770914
            ],
            [
              105.82948156368602,
              21.014584009475836
            ],
            [
              105.82949749073443,
              21.014612428590617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_215_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4018",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014414754110035,
          "to_latitude": 21.014612428590617,
          "to_longitude": 105.82949749073443,
          "from_longitude": 105.8293827975669
        }
      },
      {
        "id": 4012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82941413353412,
            21.014964814660097,
            105.82971405633802,
            21.015285885548206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82954171049566,
              21.015285885548206
            ],
            [
              105.82950214739517,
              21.01522141292335
            ],
            [
              105.82948538361016,
              21.015201382534734
            ],
            [
              105.82944163576694,
              21.01513997326342
            ],
            [
              105.82942260170584,
              21.015103741038068
            ],
            [
              105.82941413353412,
              21.015086644520494
            ],
            [
              105.8294439340471,
              21.01507182091927
            ],
            [
              105.82949221355751,
              21.015053042316524
            ],
            [
              105.82956975296982,
              21.01501961974582
            ],
            [
              105.82963500348434,
              21.014990863516495
            ],
            [
              105.82971405633802,
              21.014964814660097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_149_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4019",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015285885548206,
          "to_latitude": 21.014964814660097,
          "to_longitude": 105.82971405633802,
          "from_longitude": 105.82954171049566
        }
      },
      {
        "id": 4013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82908248705114,
            21.014667285306444,
            105.82958897054013,
            21.014919401538393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82958897054013,
              21.01479856546667
            ],
            [
              105.82958801195068,
              21.01479916988258
            ],
            [
              105.82958629088387,
              21.01480261775739
            ],
            [
              105.82954451724466,
              21.01482769988726
            ],
            [
              105.82952272367912,
              21.014838653886105
            ],
            [
              105.82949154355838,
              21.01485743312833
            ],
            [
              105.82946673297009,
              21.014872142989898
            ],
            [
              105.82942985296982,
              21.01489937168507
            ],
            [
              105.82940504196456,
              21.014919401538393
            ],
            [
              105.82939322400095,
              21.01491650650652
            ],
            [
              105.8293835004105,
              21.014912437909473
            ],
            [
              105.82936606639043,
              21.014896789611736
            ],
            [
              105.82933161648492,
              21.014857432393832
            ],
            [
              105.82929775334536,
              21.014814241728494
            ],
            [
              105.82927964853079,
              21.014788264693458
            ],
            [
              105.82925282692094,
              21.014754150532113
            ],
            [
              105.82921158755711,
              21.01470814341357
            ],
            [
              105.82916558433865,
              21.014667285306444
            ],
            [
              105.82908248705114,
              21.014721607416547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_212_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4020",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01479856546667,
          "to_latitude": 21.014721607416547,
          "to_longitude": 105.82908248705114,
          "from_longitude": 105.82958897054013
        }
      },
      {
        "id": 4014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940761947074,
            21.01504460693151,
            105.82952972397777,
            21.015280174188405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82944315253482,
              21.01504460693151
            ],
            [
              105.82943414347237,
              21.01504833420658
            ],
            [
              105.82943137055253,
              21.01504978656339
            ],
            [
              105.82943908426039,
              21.015067001468026
            ],
            [
              105.82940761947074,
              21.01508301911697
            ],
            [
              105.82941174767322,
              21.015093729310873
            ],
            [
              105.8294444614067,
              21.015164232769994
            ],
            [
              105.82948228047564,
              21.015219833399822
            ],
            [
              105.82950885355754,
              21.01525020666787
            ],
            [
              105.82952972397777,
              21.015280174188405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_148_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4021",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01504460693151,
          "to_latitude": 21.015280174188405,
          "to_longitude": 105.82952972397777,
          "from_longitude": 105.82944315253482
        }
      },
      {
        "id": 4015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82934593926696,
            21.015164232769994,
            105.8294444614067,
            21.015302944568617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294444614067,
              21.015164232769994
            ],
            [
              105.82940684149689,
              21.015183265587474
            ],
            [
              105.82936083527999,
              21.015208534502598
            ],
            [
              105.82934593926696,
              21.015219065565617
            ],
            [
              105.82938814912103,
              21.015276328424996
            ],
            [
              105.8294072541152,
              21.015302944568617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_148_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4022",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015164232769994,
          "to_latitude": 21.015302944568617,
          "to_longitude": 105.8294072541152,
          "from_longitude": 105.8294444614067
        }
      },
      {
        "id": 4016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290087460234,
            21.01467895671086,
            105.8293399145462,
            21.01501830239338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290087460234,
              21.014939119347183
            ],
            [
              105.82901042242727,
              21.014948821757063
            ],
            [
              105.82901645706171,
              21.01497605079743
            ],
            [
              105.82902517415839,
              21.01500077567016
            ],
            [
              105.82902919749364,
              21.015009225611614
            ],
            [
              105.82903322044606,
              21.01501830239338
            ],
            [
              105.82904604688261,
              21.015015868021106
            ],
            [
              105.82909792903074,
              21.015003905778617
            ],
            [
              105.82915699243401,
              21.014982102202175
            ],
            [
              105.82919113557044,
              21.014963531675303
            ],
            [
              105.82926981380704,
              21.014922777627476
            ],
            [
              105.8293399145462,
              21.014887087290735
            ],
            [
              105.82931284076967,
              21.01484303617844
            ],
            [
              105.829277972238,
              21.01479702873288
            ],
            [
              105.82927151822827,
              21.01478912535453
            ],
            [
              105.82920546880744,
              21.01471807981366
            ],
            [
              105.82918602268715,
              21.01469585859434
            ],
            [
              105.82916522945229,
              21.01467895671086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_209_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4023",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014939119347183,
          "to_latitude": 21.01467895671086,
          "to_longitude": 105.82916522945229,
          "from_longitude": 105.8290087460234
        }
      },
      {
        "id": 4017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291871126743,
            21.01479702873288,
            105.829277972238,
            21.014853364083738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829277972238,
              21.01479702873288
            ],
            [
              105.8291871126743,
              21.014853364083738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_209_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4024",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01479702873288,
          "to_latitude": 21.014853364083738,
          "to_longitude": 105.8291871126743,
          "from_longitude": 105.829277972238
        }
      },
      {
        "id": 4018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915699243401,
            21.014982102202175,
            105.82915952318484,
            21.014988001479754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915699243401,
              21.014982102202175
            ],
            [
              105.82915871435688,
              21.014986115873754
            ],
            [
              105.82915921233717,
              21.014987279511008
            ],
            [
              105.82915952318484,
              21.014988001479754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_209_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4025",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014982102202175,
          "to_latitude": 21.014988001479754,
          "to_longitude": 105.82915952318484,
          "from_longitude": 105.82915699243401
        }
      },
      {
        "id": 4019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285279360598,
            21.01485899757096,
            105.82877741535654,
            21.015196840439735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285279360598,
              21.015196840439735
            ],
            [
              105.82853388005293,
              21.015185556397892
            ],
            [
              105.82854847149437,
              21.015163064567048
            ],
            [
              105.82857431541848,
              21.015142099481917
            ],
            [
              105.82857506569215,
              21.015135127441958
            ],
            [
              105.82866323106323,
              21.015073702306218
            ],
            [
              105.82869165455176,
              21.01505389973622
            ],
            [
              105.82874641250856,
              21.015016094048466
            ],
            [
              105.82876007339863,
              21.015007299634032
            ],
            [
              105.8287689394001,
              21.014999787672487
            ],
            [
              105.82877716958468,
              21.014992279710995
            ],
            [
              105.82877741535654,
              21.014979134774205
            ],
            [
              105.82876667632071,
              21.014908447357875
            ],
            [
              105.828757959261,
              21.01485899757096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_145_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4026",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015196840439735,
          "to_latitude": 21.01485899757096,
          "to_longitude": 105.828757959261,
          "from_longitude": 105.8285279360598
        }
      },
      {
        "id": 4020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866323106323,
            21.015073702306218,
            105.82866716587556,
            21.01507988739791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866716587556,
              21.01507988739791
            ],
            [
              105.82866697181737,
              21.015079584859297
            ],
            [
              105.82866457598391,
              21.01507581652935
            ],
            [
              105.82866323106323,
              21.015073702306218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_145_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4027",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01507988739791,
          "to_latitude": 21.015073702306218,
          "to_longitude": 105.82866323106323,
          "from_longitude": 105.82866716587556
        }
      },
      {
        "id": 4021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82876298849745,
            21.014850860299585,
            105.82878880266401,
            21.015021674414037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82878826822113,
              21.015021674414037
            ],
            [
              105.82878843075366,
              21.015019579042026
            ],
            [
              105.82878880266401,
              21.015014813618286
            ],
            [
              105.82878350893799,
              21.014975778510138
            ],
            [
              105.82877438765028,
              21.01492315766216
            ],
            [
              105.82876734738183,
              21.014879653771256
            ],
            [
              105.82876298849745,
              21.014850860299585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_146_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4028",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015021674414037,
          "to_latitude": 21.014850860299585,
          "to_longitude": 105.82876298849745,
          "from_longitude": 105.82878826822113
        }
      },
      {
        "id": 4022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82878880266401,
            21.015001401891222,
            105.82899768170896,
            21.015014813618286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82878880266401,
              21.015014813618286
            ],
            [
              105.82879953367924,
              21.015014233858924
            ],
            [
              105.82883326178744,
              21.015014202323904
            ],
            [
              105.82888683886202,
              21.015010070080137
            ],
            [
              105.82899768170896,
              21.015001401891222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_146_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4029",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015014813618286,
          "to_latitude": 21.015001401891222,
          "to_longitude": 105.82899768170896,
          "from_longitude": 105.82878880266401
        }
      },
      {
        "id": 4023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82878350893799,
            21.014945464068383,
            105.82899400675595,
            21.014975778510138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82878350893799,
              21.014975778510138
            ],
            [
              105.82887983584325,
              21.014959735821705
            ],
            [
              105.82889037182181,
              21.014957981007164
            ],
            [
              105.82899400675595,
              21.014945464068383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_146_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4030",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014975778510138,
          "to_latitude": 21.014945464068383,
          "to_longitude": 105.82899400675595,
          "from_longitude": 105.82878350893799
        }
      },
      {
        "id": 4024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82887195308905,
            21.01488998198125,
            105.82887983584325,
            21.014959735821705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82887983584325,
              21.014959735821705
            ],
            [
              105.82887463515446,
              21.01491908890895
            ],
            [
              105.82887195308905,
              21.01488998198125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_146_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4031",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014959735821705,
          "to_latitude": 21.01488998198125,
          "to_longitude": 105.82887195308905,
          "from_longitude": 105.82887983584325
        }
      },
      {
        "id": 4025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82855152529058,
            21.015021664422328,
            105.82884482891025,
            21.015171012532367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884482891025,
              21.015021664422328
            ],
            [
              105.82878144093418,
              21.015026120832932
            ],
            [
              105.82876013257741,
              21.015027238051793
            ],
            [
              105.82875717746396,
              21.015027576704874
            ],
            [
              105.82866326740846,
              21.01508585322388
            ],
            [
              105.82865910591507,
              21.01508843504114
            ],
            [
              105.82862008837688,
              21.015115330751172
            ],
            [
              105.82857848145122,
              21.015143995837374
            ],
            [
              105.82855619468987,
              21.01515937521332
            ],
            [
              105.82855152529058,
              21.015171012532367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_144_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4032",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015021664422328,
          "to_latitude": 21.015171012532367,
          "to_longitude": 105.82855152529058,
          "from_longitude": 105.82884482891025
        }
      },
      {
        "id": 4026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866127560523,
            21.015083481119287,
            105.82866326740846,
            21.01508585322388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866326740846,
              21.01508585322388
            ],
            [
              105.82866188854481,
              21.015084211553084
            ],
            [
              105.8286615506134,
              21.0150838103604
            ],
            [
              105.82866127560523,
              21.015083481119287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_144_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4033",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01508585322388,
          "to_latitude": 21.015083481119287,
          "to_longitude": 105.82866127560523,
          "from_longitude": 105.82866326740846
        }
      },
      {
        "id": 4027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918732335807,
            21.0153694871718,
            105.82928215002649,
            21.01547476597977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918732335807,
              21.01547476597977
            ],
            [
              105.82921112791604,
              21.01545379719079
            ],
            [
              105.82923023897635,
              21.01543470559719
            ],
            [
              105.82924934983187,
              21.015417491808392
            ],
            [
              105.82926261583175,
              21.01539016328452
            ],
            [
              105.82928215002649,
              21.0153694871718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4034",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01547476597977,
          "to_latitude": 21.0153694871718,
          "to_longitude": 105.82928215002649,
          "from_longitude": 105.82918732335807
        }
      },
      {
        "id": 4028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8289120624046,
            21.01520811127447,
            105.8292744955325,
            21.015613643663468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926747305346,
              21.015613643663468
            ],
            [
              105.82926135995123,
              21.01560320598045
            ],
            [
              105.8292744955325,
              21.015595261310665
            ],
            [
              105.82927175268682,
              21.015591715971873
            ],
            [
              105.829261419789,
              21.01557836066504
            ],
            [
              105.82925002030504,
              21.0155652155885
            ],
            [
              105.82923158026561,
              21.01553986521895
            ],
            [
              105.8292074397976,
              21.01551294934116
            ],
            [
              105.82918397116399,
              21.01548384257302
            ],
            [
              105.82914943722054,
              21.015448789602562
            ],
            [
              105.82914605489815,
              21.015445629442503
            ],
            [
              105.82913736708352,
              21.015437521979212
            ],
            [
              105.82912563265481,
              21.015429385105023
            ],
            [
              105.82910920458522,
              21.015414048912525
            ],
            [
              105.82908070541724,
              21.015390575675063
            ],
            [
              105.82905388388504,
              21.0153655379997
            ],
            [
              105.82901901463609,
              21.015336118245997
            ],
            [
              105.82899688670983,
              21.015326103621277
            ],
            [
              105.82897736370306,
              21.015314347527152
            ],
            [
              105.82895832995565,
              21.015303569046548
            ],
            [
              105.82892738760542,
              21.015251739176875
            ],
            [
              105.8289120624046,
              21.01520811127447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_135_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4035",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015613643663468,
          "to_latitude": 21.01520811127447,
          "to_longitude": 105.8289120624046,
          "from_longitude": 105.82926747305346
        }
      },
      {
        "id": 4029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82906910899814,
            21.015445629442503,
            105.82914605489815,
            21.015513741828023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914605489815,
              21.015445629442503
            ],
            [
              105.82914451093032,
              21.01544701077321
            ],
            [
              105.82911355191432,
              21.015474671192084
            ],
            [
              105.82910029358068,
              21.015483901737653
            ],
            [
              105.82906910899814,
              21.015513741828023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4036",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015445629442503,
          "to_latitude": 21.015513741828023,
          "to_longitude": 105.82906910899814,
          "from_longitude": 105.82914605489815
        }
      },
      {
        "id": 4030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82851268399727,
            21.01521092823667,
            105.82888540736229,
            21.015247233632795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82851268399727,
              21.015237486708923
            ],
            [
              105.82851375462013,
              21.0152380071725
            ],
            [
              105.82853183160177,
              21.015246768041237
            ],
            [
              105.82857829468534,
              21.015247233632795
            ],
            [
              105.82860779840952,
              21.015245355747247
            ],
            [
              105.82866881896543,
              21.015220317385364
            ],
            [
              105.82869161758197,
              21.01522219524029
            ],
            [
              105.82873453342233,
              21.015239095999718
            ],
            [
              105.82874858074706,
              21.01524191518495
            ],
            [
              105.8288290808096,
              21.0152209439398
            ],
            [
              105.82888540736229,
              21.01521092823667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_142_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4037",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015237486708923,
          "to_latitude": 21.01521092823667,
          "to_longitude": 105.82888540736229,
          "from_longitude": 105.82851268399727
        }
      },
      {
        "id": 4031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874858074706,
            21.01524191518495,
            105.82876862197311,
            21.015297501417567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874858074706,
              21.01524191518495
            ],
            [
              105.82876862197311,
              21.015297501417567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_142_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4038",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01524191518495,
          "to_latitude": 21.015297501417567,
          "to_longitude": 105.82876862197311,
          "from_longitude": 105.82874858074706
        }
      },
      {
        "id": 4032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82872522932963,
            21.01543760081597,
            105.82888683244194,
            21.015830456049756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82885445217875,
              21.015830456049756
            ],
            [
              105.82885317994759,
              21.015829312441884
            ],
            [
              105.82885166987802,
              21.01582795141217
            ],
            [
              105.82887025982741,
              21.015813067814133
            ],
            [
              105.82888683244194,
              21.01578875804568
            ],
            [
              105.82888012675886,
              21.015759338338075
            ],
            [
              105.82887342134369,
              21.01571865179323
            ],
            [
              105.82886269214451,
              21.015677964980682
            ],
            [
              105.82882111794912,
              21.015621003281304
            ],
            [
              105.82878893189601,
              21.015594087699572
            ],
            [
              105.8287453460753,
              21.015574683770268
            ],
            [
              105.82873394610952,
              21.015554653371506
            ],
            [
              105.82872522932963,
              21.015540255964897
            ],
            [
              105.82876143966323,
              21.01552147764433
            ],
            [
              105.82877417973263,
              21.015496439588514
            ],
            [
              105.8287393105307,
              21.01543760081597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_132_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4039",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015830456049756,
          "to_latitude": 21.01543760081597,
          "to_longitude": 105.8287393105307,
          "from_longitude": 105.82885445217875
        }
      },
      {
        "id": 4033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82878893189601,
            21.015499555539247,
            105.82887674326456,
            21.015594087699572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82878893189601,
              21.015594087699572
            ],
            [
              105.82881358522472,
              21.015566377670023
            ],
            [
              105.82887674326456,
              21.015499555539247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_132_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4040",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015594087699572,
          "to_latitude": 21.015499555539247,
          "to_longitude": 105.82887674326456,
          "from_longitude": 105.82878893189601
        }
      },
      {
        "id": 4034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82830035621043,
            21.01551068044966,
            105.82868097265985,
            21.01567806180415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82830035621043,
              21.01567806180415
            ],
            [
              105.8283140955009,
              21.015671778009356
            ],
            [
              105.82831648132523,
              21.015671294909563
            ],
            [
              105.82833999754592,
              21.015666541446066
            ],
            [
              105.82851132372187,
              21.015576717788264
            ],
            [
              105.82853244601553,
              21.015590488469194
            ],
            [
              105.82857837815574,
              21.015566389809916
            ],
            [
              105.82868097265985,
              21.01551068044966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4041",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01567806180415,
          "to_latitude": 21.01551068044966,
          "to_longitude": 105.82868097265985,
          "from_longitude": 105.82830035621043
        }
      },
      {
        "id": 4035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829678011973,
            21.01538736805708,
            105.82864174565586,
            21.01567186231276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82829678011973,
              21.01567186231276
            ],
            [
              105.8283106853724,
              21.015665670742482
            ],
            [
              105.8283143175896,
              21.015663743639127
            ],
            [
              105.82833932617403,
              21.015660281748293
            ],
            [
              105.82851836384607,
              21.01556638944101
            ],
            [
              105.82848450089737,
              21.0155091155201
            ],
            [
              105.82847880139333,
              21.015490649730033
            ],
            [
              105.82848081350154,
              21.01547719180512
            ],
            [
              105.8285170229301,
              21.01545371857356
            ],
            [
              105.82864174565586,
              21.01538736805708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4042",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01567186231276,
          "to_latitude": 21.01538736805708,
          "to_longitude": 105.82864174565586,
          "from_longitude": 105.82829678011973
        }
      },
      {
        "id": 4036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82842839933019,
            21.01554620306882,
            105.82870477773547,
            21.015887850183397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82870477773547,
              21.01554620306882
            ],
            [
              105.82865046267993,
              21.015575935365426
            ],
            [
              105.82862263479757,
              21.01559909502863
            ],
            [
              105.82860050666933,
              21.015635713892078
            ],
            [
              105.8285860051242,
              21.01567500799532
            ],
            [
              105.82858567047602,
              21.01571560058369
            ],
            [
              105.82858030643422,
              21.01574846257179
            ],
            [
              105.82856488343593,
              21.015776629808745
            ],
            [
              105.82854311679843,
              21.01580886626041
            ],
            [
              105.8285146756465,
              21.015845875342155
            ],
            [
              105.82844100212861,
              21.015882180686265
            ],
            [
              105.82842839933019,
              21.015887850183397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_129_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4043",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01554620306882,
          "to_latitude": 21.015887850183397,
          "to_longitude": 105.82842839933019,
          "from_longitude": 105.82870477773547
        }
      },
      {
        "id": 4037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817990189453,
            21.015430067860315,
            105.8283143175896,
            21.015663743639127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82817990189453,
              21.015430067860315
            ],
            [
              105.82821326111133,
              21.01548411436336
            ],
            [
              105.8282479823724,
              21.01554581087199
            ],
            [
              105.82826086248943,
              21.01557068171138
            ],
            [
              105.82827374261085,
              21.01559555254958
            ],
            [
              105.82830791709678,
              21.015652987577443
            ],
            [
              105.8283143175896,
              21.015663743639127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4044",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015430067860315,
          "to_latitude": 21.015663743639127,
          "to_longitude": 105.8283143175896,
          "from_longitude": 105.82817990189453
        }
      },
      {
        "id": 4038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82831648132523,
            21.015671294909563,
            105.82843191027925,
            21.015869713477972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82831648132523,
              21.015671294909563
            ],
            [
              105.82831809146738,
              21.015673882037447
            ],
            [
              105.82835460356655,
              21.01573254851154
            ],
            [
              105.82840612389238,
              21.015825183567888
            ],
            [
              105.82843191027925,
              21.015869713477972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4045",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015671294909563,
          "to_latitude": 21.015869713477972,
          "to_longitude": 105.82843191027925,
          "from_longitude": 105.82831648132523
        }
      },
      {
        "id": 4039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82749184759778,
            21.01403022063689,
            105.82785175705098,
            21.014209759762895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749184759778,
              21.014209759762895
            ],
            [
              105.82752889221892,
              21.014191062552143
            ],
            [
              105.82762648760145,
              21.01414155169883
            ],
            [
              105.82765593274767,
              21.014127349751227
            ],
            [
              105.82769677622844,
              21.014107651315175
            ],
            [
              105.8277905627866,
              21.01406241851291
            ],
            [
              105.82785175705098,
              21.01403022063689
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_200_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4046",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014209759762895,
          "to_latitude": 21.01403022063689,
          "to_longitude": 105.82785175705098,
          "from_longitude": 105.82749184759778
        }
      },
      {
        "id": 4040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82769087791293,
            21.014096360782144,
            105.82769677622844,
            21.014107651315175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82769087791293,
              21.014096360782144
            ],
            [
              105.82769362925028,
              21.014101631269067
            ],
            [
              105.82769670429013,
              21.01410751437481
            ],
            [
              105.82769677622844,
              21.014107651315175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_200_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4047",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014096360782144,
          "to_latitude": 21.014107651315175,
          "to_longitude": 105.82769677622844,
          "from_longitude": 105.82769087791293
        }
      },
      {
        "id": 4041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807161283104,
            21.014350813861956,
            105.8282308211048,
            21.01443057320403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8282308211048,
              21.014350813861956
            ],
            [
              105.82816070572399,
              21.014383509621887
            ],
            [
              105.82807161283104,
              21.01443057320403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4048",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014350813861956,
          "to_latitude": 21.01443057320403,
          "to_longitude": 105.82807161283104,
          "from_longitude": 105.8282308211048
        }
      },
      {
        "id": 4042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82823577193074,
            21.013717478516813,
            105.82844531429224,
            21.014091241752368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82829768961744,
              21.014091241752368
            ],
            [
              105.82836485287291,
              21.014059278377278
            ],
            [
              105.82841838799874,
              21.014031235397532
            ],
            [
              105.82841869876201,
              21.01403107220698
            ],
            [
              105.82844531429224,
              21.01401713048346
            ],
            [
              105.82840358252008,
              21.013931170452953
            ],
            [
              105.82834794131459,
              21.013822733613296
            ],
            [
              105.82834499273704,
              21.013817806420157
            ],
            [
              105.82823577193074,
              21.013717478516813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_340_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4049",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014091241752368,
          "to_latitude": 21.013717478516813,
          "to_longitude": 105.82823577193074,
          "from_longitude": 105.82829768961744
        }
      },
      {
        "id": 4043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82841838799874,
            21.014031235397532,
            105.82842333940495,
            21.014040184837118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82841838799874,
              21.014031235397532
            ],
            [
              105.82842076848519,
              21.014035538516442
            ],
            [
              105.82842292745852,
              21.014039444391305
            ],
            [
              105.82842333940495,
              21.014040184837118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_340_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4050",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014031235397532,
          "to_latitude": 21.014040184837118,
          "to_longitude": 105.82842333940495,
          "from_longitude": 105.82841838799874
        }
      },
      {
        "id": 4044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82872163275711,
            21.012420227336982,
            105.828931594117,
            21.012769769121622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872163275711,
              21.012420227336982
            ],
            [
              105.82872549526259,
              21.01242413213786
            ],
            [
              105.82873447970184,
              21.01243321096013
            ],
            [
              105.82874017139288,
              21.012438962077393
            ],
            [
              105.82876653168819,
              21.01248387033375
            ],
            [
              105.82879900735426,
              21.01254156918573
            ],
            [
              105.8288580617301,
              21.012644520238528
            ],
            [
              105.82889768151075,
              21.01271229317051
            ],
            [
              105.8289137113092,
              21.01273917185418
            ],
            [
              105.828931594117,
              21.012769769121622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_262_Ngõ 12, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4051",
          "diaChiLapD": "Ngõ 12, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012420227336982,
          "to_latitude": 21.012769769121622,
          "to_longitude": 105.828931594117,
          "from_longitude": 105.82872163275711
        }
      },
      {
        "id": 4045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285952604002,
            21.012466086080387,
            105.82871126320157,
            21.012676883418557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8286055027343,
              21.012466086080387
            ],
            [
              105.82859865150517,
              21.012471925381078
            ],
            [
              105.8285952604002,
              21.012476042540857
            ],
            [
              105.82860847245637,
              21.012502535752244
            ],
            [
              105.8286126910151,
              21.0125109946933
            ],
            [
              105.82862454422336,
              21.012534762262213
            ],
            [
              105.8286391606789,
              21.012555158178973
            ],
            [
              105.82866970144445,
              21.01260163034896
            ],
            [
              105.8287016133873,
              21.012656015319084
            ],
            [
              105.82871126320157,
              21.012676883418557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_261_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4052",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012466086080387,
          "to_latitude": 21.012676883418557,
          "to_longitude": 105.82871126320157,
          "from_longitude": 105.8286055027343
        }
      },
      {
        "id": 4046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82869341876157,
            21.013035473433657,
            105.82879038054989,
            21.01308089565016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82869341876157,
              21.01308089565016
            ],
            [
              105.82870103478932,
              21.013077328749542
            ],
            [
              105.82879038054989,
              21.013035473433657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_341_Ngõ 14, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4053",
          "diaChiLapD": "Ngõ 14, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01308089565016,
          "to_latitude": 21.013035473433657,
          "to_longitude": 105.82879038054989,
          "from_longitude": 105.82869341876157
        }
      },
      {
        "id": 4047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82873492126302,
            21.01312240853301,
            105.82907761091528,
            21.01334403946202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82873492126302,
              21.01313546963873
            ],
            [
              105.82873560781991,
              21.013135075197898
            ],
            [
              105.82875763895552,
              21.01312240853301
            ],
            [
              105.82879563360441,
              21.013178941527386
            ],
            [
              105.82884007432021,
              21.01325257188525
            ],
            [
              105.82889464457188,
              21.01334403946202
            ],
            [
              105.82895547677893,
              21.01331448219085
            ],
            [
              105.82907761091528,
              21.013256132134817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_207_Ngõ 14, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4054",
          "diaChiLapD": "Ngõ 14, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01313546963873,
          "to_latitude": 21.013256132134817,
          "to_longitude": 105.82907761091528,
          "from_longitude": 105.82873492126302
        }
      },
      {
        "id": 4048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885124672129,
            21.01334403946202,
            105.82890151074464,
            21.01339823216865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82889464457188,
              21.01334403946202
            ],
            [
              105.82890151074464,
              21.013372235430612
            ],
            [
              105.82887149802524,
              21.01338829648152
            ],
            [
              105.82885124672129,
              21.01339823216865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_207_Ngõ 14, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4055",
          "diaChiLapD": "Ngõ 14, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01334403946202,
          "to_latitude": 21.01339823216865,
          "to_longitude": 105.82885124672129,
          "from_longitude": 105.82889464457188
        }
      },
      {
        "id": 4049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82795931148952,
            21.013139717684627,
            105.82873933590344,
            21.0137504220026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82808543937352,
              21.0137504220026
            ],
            [
              105.828049378982,
              21.01369801982365
            ],
            [
              105.82799871942082,
              21.013638113328025
            ],
            [
              105.8279628570029,
              21.01358462177777
            ],
            [
              105.82795931148952,
              21.013577501739967
            ],
            [
              105.82810424812698,
              21.013498318691077
            ],
            [
              105.82811594640562,
              21.013491929237027
            ],
            [
              105.82823130124889,
              21.013427281158773
            ],
            [
              105.82834684976933,
              21.013366195287826
            ],
            [
              105.82845438053891,
              21.013301969025225
            ],
            [
              105.82857750889947,
              21.01323567332023
            ],
            [
              105.82864317354952,
              21.013200319206952
            ],
            [
              105.82869060990556,
              21.01317596391578
            ],
            [
              105.82873911652742,
              21.013139881333807
            ],
            [
              105.82873933590344,
              21.013139717684627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_208_Ngõ 14, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4056",
          "diaChiLapD": "Ngõ 14, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 117,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0137504220026,
          "to_latitude": 21.013139717684627,
          "to_longitude": 105.82873933590344,
          "from_longitude": 105.82808543937352
        }
      },
      {
        "id": 4050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82711077389865,
            21.013318649144367,
            105.82713332190855,
            21.0133557307693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82713332190855,
              21.0133557307693
            ],
            [
              105.82711960697712,
              21.013339013694875
            ],
            [
              105.82711900655194,
              21.013338282290903
            ],
            [
              105.82711077389865,
              21.013318649144367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_203_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4057",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0133557307693,
          "to_latitude": 21.013318649144367,
          "to_longitude": 105.82711077389865,
          "from_longitude": 105.82713332190855
        }
      },
      {
        "id": 4051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.826996354459,
            21.013224141679455,
            105.8270090227694,
            21.01324626333772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270090227694,
              21.01324626333772
            ],
            [
              105.826996354459,
              21.013224141679455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_203_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4058",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01324626333772,
          "to_latitude": 21.013224141679455,
          "to_longitude": 105.826996354459,
          "from_longitude": 105.8270090227694
        }
      },
      {
        "id": 4052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278949984313,
            21.011902096146095,
            105.828236010103,
            21.012099289706274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828236010103,
              21.011902096146095
            ],
            [
              105.82823115198522,
              21.01190584740725
            ],
            [
              105.82812843069037,
              21.011966063266083
            ],
            [
              105.8278949984313,
              21.012099289706274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_266_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4059",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011902096146095,
          "to_latitude": 21.012099289706274,
          "to_longitude": 105.8278949984313,
          "from_longitude": 105.828236010103
        }
      },
      {
        "id": 4053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283691397875,
            21.012147877035307,
            105.8285199722198,
            21.012431827736407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285199722198,
              21.012431827736407
            ],
            [
              105.82849429383776,
              21.012383579602165
            ],
            [
              105.82845200027701,
              21.012308678679968
            ],
            [
              105.8284320794407,
              21.01226782521244
            ],
            [
              105.82841900613276,
              21.01224101608377
            ],
            [
              105.82841280301176,
              21.012229394669678
            ],
            [
              105.8283691397875,
              21.012147877035307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_264_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4060",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012431827736407,
          "to_latitude": 21.012147877035307,
          "to_longitude": 105.8283691397875,
          "from_longitude": 105.8285199722198
        }
      },
      {
        "id": 4054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284320794407,
            21.012259920250287,
            105.82844634910086,
            21.01226782521244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284320794407,
              21.01226782521244
            ],
            [
              105.82844608538402,
              21.01226006695368
            ],
            [
              105.82844634910086,
              21.012259920250287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_264_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4061",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01226782521244,
          "to_latitude": 21.012259920250287,
          "to_longitude": 105.82844634910086,
          "from_longitude": 105.8284320794407
        }
      },
      {
        "id": 4055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82696840333254,
            21.013239919933948,
            105.8272490836942,
            21.013392430239136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82696840333254,
              21.013392430239136
            ],
            [
              105.82705479926035,
              21.01334877376707
            ],
            [
              105.82706228585631,
              21.0133451000837
            ],
            [
              105.82711077389865,
              21.013318649144367
            ],
            [
              105.82712911838647,
              21.013308641640744
            ],
            [
              105.82716017938938,
              21.013290939150362
            ],
            [
              105.82721735316578,
              21.013258136921184
            ],
            [
              105.8272490836942,
              21.013239919933948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_203_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4062",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013392430239136,
          "to_latitude": 21.013239919933948,
          "to_longitude": 105.8272490836942,
          "from_longitude": 105.82696840333254
        }
      },
      {
        "id": 4056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360985451904,
            21.012023921477528,
            105.83404895786812,
            21.01217212594888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83404895786812,
              21.0121243576412
            ],
            [
              105.83403050992992,
              21.012112702529738
            ],
            [
              105.8339900447824,
              21.012088250054145
            ],
            [
              105.83394484627324,
              21.012062103736717
            ],
            [
              105.83390491717405,
              21.0120363091299
            ],
            [
              105.83388273690028,
              21.012024803261166
            ],
            [
              105.83376980045196,
              21.012023921477528
            ],
            [
              105.83375648898591,
              21.012027638444025
            ],
            [
              105.83369071331892,
              21.012092721072452
            ],
            [
              105.83368104821989,
              21.012102106872423
            ],
            [
              105.83362102508896,
              21.012160847746266
            ],
            [
              105.83360985451904,
              21.01217212594888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_276_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4063",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0121243576412,
          "to_latitude": 21.01217212594888,
          "to_longitude": 105.83360985451904,
          "from_longitude": 105.83404895786812
        }
      },
      {
        "id": 4057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83367771382954,
            21.012080571355444,
            105.83369071331892,
            21.012092721072452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83367771382954,
              21.012080571355444
            ],
            [
              105.83367990631311,
              21.012083045385403
            ],
            [
              105.83369071331892,
              21.012092721072452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_276_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4064",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012080571355444,
          "to_latitude": 21.012092721072452,
          "to_longitude": 105.83369071331892,
          "from_longitude": 105.83367771382954
        }
      },
      {
        "id": 4058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746751794853,
            21.01421907744813,
            105.82769423065129,
            21.014620829682496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746751794853,
              21.01421907744813
            ],
            [
              105.82746975718203,
              21.014222889009787
            ],
            [
              105.82765694102949,
              21.01456393172993
            ],
            [
              105.82766248382721,
              21.014572850314977
            ],
            [
              105.82769423065129,
              21.014620829682496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_199_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4065",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01421907744813,
          "to_latitude": 21.014620829682496,
          "to_longitude": 105.82769423065129,
          "from_longitude": 105.82746751794853
        }
      },
      {
        "id": 4059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789511351673,
            21.014958744418358,
            105.82817093077958,
            21.01542621772473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82817093077958,
              21.01542621772473
            ],
            [
              105.82816968712676,
              21.015423976553112
            ],
            [
              105.82813195532599,
              21.01535466061637
            ],
            [
              105.82807539915757,
              21.01526351229597
            ],
            [
              105.8280565953403,
              21.015231642272244
            ],
            [
              105.82803114101125,
              21.015188499840807
            ],
            [
              105.82798215725288,
              21.015105530370764
            ],
            [
              105.82795725071605,
              21.01506176474441
            ],
            [
              105.82793305608618,
              21.01502515552012
            ],
            [
              105.8279113341324,
              21.014994387162357
            ],
            [
              105.82789511351673,
              21.014958744418358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4066",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01542621772473,
          "to_latitude": 21.014958744418358,
          "to_longitude": 105.82789511351673,
          "from_longitude": 105.82817093077958
        }
      },
      {
        "id": 4060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82780852867201,
            21.014570975030733,
            105.82800117877294,
            21.014878345988883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82780852867201,
              21.014878345988883
            ],
            [
              105.82783597344479,
              21.014871697482764
            ],
            [
              105.82786305796344,
              21.01486513472526
            ],
            [
              105.82787981531328,
              21.014856332538876
            ],
            [
              105.82800117877294,
              21.014792071743102
            ],
            [
              105.82794013131198,
              21.014681343414782
            ],
            [
              105.82791500228753,
              21.014620605440737
            ],
            [
              105.82788937859237,
              21.014570975030733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_198_Ngõ 161, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4067",
          "diaChiLapD": "Ngõ 161, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014878345988883,
          "to_latitude": 21.014570975030733,
          "to_longitude": 105.82788937859237,
          "from_longitude": 105.82780852867201
        }
      },
      {
        "id": 4061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848777208903,
            21.01526906195454,
            105.82851250499274,
            21.0153081776335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82848777208903,
              21.01526906195454
            ],
            [
              105.82851250499274,
              21.0153081776335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4068",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01526906195454,
          "to_latitude": 21.0153081776335,
          "to_longitude": 105.82851250499274,
          "from_longitude": 105.82848777208903
        }
      },
      {
        "id": 4062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82838612279679,
            21.01530326440441,
            105.8283876670695,
            21.015306291723434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283876670695,
              21.015306291723434
            ],
            [
              105.82838721878564,
              21.015305416874043
            ],
            [
              105.82838612279679,
              21.01530326440441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4069",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015306291723434,
          "to_latitude": 21.01530326440441,
          "to_longitude": 105.82838612279679,
          "from_longitude": 105.8283876670695
        }
      },
      {
        "id": 4063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282781560011,
            21.01527244634865,
            105.82837666126446,
            21.015333906326095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82837666126446,
              21.01527244634865
            ],
            [
              105.8282781560011,
              21.015333906326095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4070",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01527244634865,
          "to_latitude": 21.015333906326095,
          "to_longitude": 105.8282781560011,
          "from_longitude": 105.82837666126446
        }
      },
      {
        "id": 4064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82842796779565,
            21.01526906195454,
            105.82850629447725,
            21.01542899353396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82848777208903,
              21.01526906195454
            ],
            [
              105.82842877537915,
              21.015300367816334
            ],
            [
              105.82842796779565,
              21.015300796264803
            ],
            [
              105.82850629447725,
              21.01542899353396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4071",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01526906195454,
          "to_latitude": 21.01542899353396,
          "to_longitude": 105.82850629447725,
          "from_longitude": 105.82848777208903
        }
      },
      {
        "id": 4065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83020364225233,
            21.01847869165945,
            105.8305001516682,
            21.018693639717647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83020364225233,
              21.018693639717647
            ],
            [
              105.83023723348862,
              21.01866925760249
            ],
            [
              105.8303675330812,
              21.018574679887635
            ],
            [
              105.83038224101372,
              21.018563839843733
            ],
            [
              105.83046322753641,
              21.018504151384953
            ],
            [
              105.83046655474307,
              21.01850185720835
            ],
            [
              105.83048058516613,
              21.01849218291436
            ],
            [
              105.8305001516682,
              21.01847869165945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.4072",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018693639717647,
          "to_latitude": 21.01847869165945,
          "to_longitude": 105.8305001516682,
          "from_longitude": 105.83020364225233
        }
      },
      {
        "id": 4066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029025911263,
            21.017957145465477,
            105.83052417669495,
            21.018427481636706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052417669495,
              21.018427481636706
            ],
            [
              105.83048129260762,
              21.018312981427616
            ],
            [
              105.8304626582238,
              21.018257151932488
            ],
            [
              105.83045429126986,
              21.018217044426557
            ],
            [
              105.83045036556702,
              21.018198461671716
            ],
            [
              105.83044409017131,
              21.018155795956492
            ],
            [
              105.83042417550223,
              21.01806418512264
            ],
            [
              105.8303965970881,
              21.01802488765142
            ],
            [
              105.83035008477559,
              21.018001784388698
            ],
            [
              105.83029025911263,
              21.017957145465477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_85_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4073",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018427481636706,
          "to_latitude": 21.017957145465477,
          "to_longitude": 105.83029025911263,
          "from_longitude": 105.83052417669495
        }
      },
      {
        "id": 4067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8305401942571,
            21.018141098039138,
            105.8308484261353,
            21.018443296580806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8305401942571,
              21.018443296580806
            ],
            [
              105.83069862187028,
              21.018327858597445
            ],
            [
              105.83076255137395,
              21.018283040893763
            ],
            [
              105.83076298004639,
              21.018282628736145
            ],
            [
              105.8308484261353,
              21.018200412665518
            ],
            [
              105.83082787621805,
              21.018181539889174
            ],
            [
              105.83078385059194,
              21.018141098039138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.4074",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018443296580806,
          "to_latitude": 21.018141098039138,
          "to_longitude": 105.83078385059194,
          "from_longitude": 105.8305401942571
        }
      },
      {
        "id": 4068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83043046338874,
            21.017998528810505,
            105.83074496364627,
            21.018238526439298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83043046338874,
              21.01805907468991
            ],
            [
              105.83054276509198,
              21.017998528810505
            ],
            [
              105.83060300008562,
              21.018075027809004
            ],
            [
              105.83068549632193,
              21.018166921033696
            ],
            [
              105.83074496364627,
              21.018238526439298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4075",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01805907468991,
          "to_latitude": 21.018238526439298,
          "to_longitude": 105.83074496364627,
          "from_longitude": 105.83043046338874
        }
      },
      {
        "id": 4069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8317664195162,
            21.016797859137068,
            105.83203674589429,
            21.01693063902622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203674589429,
              21.01693063902622
            ],
            [
              105.83194838656283,
              21.016844305566867
            ],
            [
              105.83189763509168,
              21.016889531642168
            ],
            [
              105.83182027268928,
              21.01682676457244
            ],
            [
              105.8318095936756,
              21.01683916929218
            ],
            [
              105.83180329115947,
              21.016833471855822
            ],
            [
              105.8317845441894,
              21.016816193598988
            ],
            [
              105.8317664195162,
              21.016797859137068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4076",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01693063902622,
          "to_latitude": 21.016797859137068,
          "to_longitude": 105.8317664195162,
          "from_longitude": 105.83203674589429
        }
      },
      {
        "id": 4070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326421166218,
            21.016006057252905,
            105.83281839997441,
            21.016138465210485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281839997441,
              21.016138465210485
            ],
            [
              105.8327070020472,
              21.016057642588045
            ],
            [
              105.8326421166218,
              21.016006057252905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_179_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4077",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016138465210485,
          "to_latitude": 21.016006057252905,
          "to_longitude": 105.8326421166218,
          "from_longitude": 105.83281839997441
        }
      },
      {
        "id": 4071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320646223824,
            21.016164021974806,
            105.83286027854643,
            21.016945079829867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8320646223824,
              21.016945079829867
            ],
            [
              105.83213856316486,
              21.016871024049472
            ],
            [
              105.83217809561926,
              21.01683398722788
            ],
            [
              105.83225099600726,
              21.016760202038185
            ],
            [
              105.83227727112296,
              21.016733608103983
            ],
            [
              105.8322873772916,
              21.016732391695257
            ],
            [
              105.83234900944689,
              21.01667734118604
            ],
            [
              105.83242878065928,
              21.01660525740167
            ],
            [
              105.8324917851293,
              21.016549984257253
            ],
            [
              105.83259970203451,
              21.016447292373464
            ],
            [
              105.83267281896747,
              21.016373077822564
            ],
            [
              105.83286027854643,
              21.016164021974806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.4078",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016945079829867,
          "to_latitude": 21.016164021974806,
          "to_longitude": 105.83286027854643,
          "from_longitude": 105.8320646223824
        }
      },
      {
        "id": 4072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288047468878,
            21.01561512831131,
            105.83333880322839,
            21.01613651474482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83288047468878,
              21.01613651474482
            ],
            [
              105.83311971582964,
              21.015875852263868
            ],
            [
              105.83319216970823,
              21.015792332112262
            ],
            [
              105.833264511936,
              21.015695827718414
            ],
            [
              105.83329631298444,
              21.01565399084369
            ],
            [
              105.83333880322839,
              21.01561512831131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_180_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.4079",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01613651474482,
          "to_latitude": 21.01561512831131,
          "to_longitude": 105.83333880322839,
          "from_longitude": 105.83288047468878
        }
      },
      {
        "id": 4073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83474939465947,
            21.01393391002981,
            105.83498278862564,
            21.01420201598925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83474939465947,
              21.01420201598925
            ],
            [
              105.83481241057066,
              21.01413219372675
            ],
            [
              105.8348736959719,
              21.01407050443977
            ],
            [
              105.83487408400005,
              21.014070052729817
            ],
            [
              105.83488014045834,
              21.014062996825732
            ],
            [
              105.83488379130017,
              21.014035230041788
            ],
            [
              105.83494143029213,
              21.013976190621516
            ],
            [
              105.83498278862564,
              21.01393391002981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_255_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.4080",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01420201598925,
          "to_latitude": 21.01393391002981,
          "to_longitude": 105.83498278862564,
          "from_longitude": 105.83474939465947
        }
      },
      {
        "id": 4074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82901197409107,
            21.015390575675063,
            105.82908070541724,
            21.0154666289046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82908070541724,
              21.015390575675063
            ],
            [
              105.82901197409107,
              21.0154666289046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4081",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015390575675063,
          "to_latitude": 21.0154666289046,
          "to_longitude": 105.82901197409107,
          "from_longitude": 105.82908070541724
        }
      },
      {
        "id": 4075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82834245500257,
            21.015180620601114,
            105.82851048974236,
            21.01530326440441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283854233541,
              21.015180620601114
            ],
            [
              105.82834245500257,
              21.015206806582906
            ],
            [
              105.82835989599391,
              21.015239580112112
            ],
            [
              105.82837666126446,
              21.01527244634865
            ],
            [
              105.82838612279679,
              21.01530326440441
            ],
            [
              105.82839133283484,
              21.015301209488005
            ],
            [
              105.82845013524985,
              21.015268337488546
            ],
            [
              105.82851048974236,
              21.01523747210426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_140_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4082",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015180620601114,
          "to_latitude": 21.01523747210426,
          "to_longitude": 105.82851048974236,
          "from_longitude": 105.8283854233541
        }
      },
      {
        "id": 4076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82826086248943,
            21.015499422096244,
            105.82843312321307,
            21.01557068171138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82826086248943,
              21.01557068171138
            ],
            [
              105.8283269741746,
              21.015536825780075
            ],
            [
              105.8283798123224,
              21.015525834579762
            ],
            [
              105.82843312321307,
              21.015499422096244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4083",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01557068171138,
          "to_latitude": 21.015499422096244,
          "to_longitude": 105.82843312321307,
          "from_longitude": 105.82826086248943
        }
      },
      {
        "id": 4077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82857210578999,
            21.01605760979063,
            105.82890575460155,
            21.016234967178736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82858564360758,
              21.01605760979063
            ],
            [
              105.82858752164572,
              21.016059585030348
            ],
            [
              105.82859011766563,
              21.016062324514877
            ],
            [
              105.82859154626591,
              21.016063564911455
            ],
            [
              105.82857210578999,
              21.0160775575261
            ],
            [
              105.82862474342637,
              21.01616692732269
            ],
            [
              105.82866463779821,
              21.016234967178736
            ],
            [
              105.82873875110245,
              21.016197803335718
            ],
            [
              105.82875956175793,
              21.016187186607013
            ],
            [
              105.82880904017654,
              21.016165079380887
            ],
            [
              105.82884101725041,
              21.01614527572895
            ],
            [
              105.82886678943986,
              21.016132892493125
            ],
            [
              105.82890575460155,
              21.016108839429236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_130_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4084",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01605760979063,
          "to_latitude": 21.016108839429236,
          "to_longitude": 105.82890575460155,
          "from_longitude": 105.82858564360758
        }
      },
      {
        "id": 4078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83370788321766,
            21.012677837635263,
            105.83375925702555,
            21.01276997445172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375925702555,
              21.012677837635263
            ],
            [
              105.83372951382385,
              21.012732578817133
            ],
            [
              105.83370788321766,
              21.01276997445172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_272_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4085",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012677837635263,
          "to_latitude": 21.01276997445172,
          "to_longitude": 105.83370788321766,
          "from_longitude": 105.83375925702555
        }
      },
      {
        "id": 4079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052013403055,
            21.017088691134575,
            105.83061770776624,
            21.01715494398262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83061770776624,
              21.017088691134575
            ],
            [
              105.83060592218091,
              21.01709966776637
            ],
            [
              105.83052013403055,
              21.01715494398262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4086",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017088691134575,
          "to_latitude": 21.01715494398262,
          "to_longitude": 105.83052013403055,
          "from_longitude": 105.83061770776624
        }
      },
      {
        "id": 4080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8301535908904,
            21.01671423662474,
            105.83030777721898,
            21.016809261711664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83030777721898,
              21.01671423662474
            ],
            [
              105.83028020450674,
              21.01676332329214
            ],
            [
              105.83025440068141,
              21.016809261711664
            ],
            [
              105.8301535908904,
              21.01675714441485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_155_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4087",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01671423662474,
          "to_latitude": 21.01675714441485,
          "to_longitude": 105.8301535908904,
          "from_longitude": 105.83030777721898
        }
      },
      {
        "id": 4081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83008851914305,
            21.016609050288277,
            105.83042045065321,
            21.016753899083934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83008851914305,
              21.016609050288277
            ],
            [
              105.83022939119617,
              21.016673039209937
            ],
            [
              105.83028331113074,
              21.01670137849407
            ],
            [
              105.83030777721898,
              21.01671423662474
            ],
            [
              105.83032621265502,
              21.016727922637227
            ],
            [
              105.83037013359854,
              21.016753899083934
            ],
            [
              105.83041036686471,
              21.01669099177444
            ],
            [
              105.83041969342317,
              21.01669386366612
            ],
            [
              105.83042045065321,
              21.0166933749379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_155_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4088",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016609050288277,
          "to_latitude": 21.0166933749379,
          "to_longitude": 105.83042045065321,
          "from_longitude": 105.83008851914305
        }
      },
      {
        "id": 4082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299350113564,
            21.017192665844718,
            105.82996905661709,
            21.017214397541206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299350113564,
              21.017192665844718
            ],
            [
              105.82996905661709,
              21.017214397541206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_96_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4089",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017192665844718,
          "to_latitude": 21.017214397541206,
          "to_longitude": 105.82996905661709,
          "from_longitude": 105.8299350113564
        }
      },
      {
        "id": 4083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034631005893,
            21.016639042024185,
            105.83041036686471,
            21.01669099177444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83041036686471,
              21.01669099177444
            ],
            [
              105.83038052741341,
              21.01665625215948
            ],
            [
              105.83036745129291,
              21.016649053150818
            ],
            [
              105.8303550456554,
              21.016641855384638
            ],
            [
              105.83034631005893,
              21.016639042024185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_155_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4090",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01669099177444,
          "to_latitude": 21.016639042024185,
          "to_longitude": 105.83034631005893,
          "from_longitude": 105.83041036686471
        }
      },
      {
        "id": 4084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866513039843,
            21.015091371792263,
            105.82879991147844,
            21.015211867611065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82866513039843,
              21.015211867611065
            ],
            [
              105.82869128195917,
              21.015160226564205
            ],
            [
              105.82871676361043,
              21.015133935997778
            ],
            [
              105.82877677824033,
              21.015091371792263
            ],
            [
              105.82879991147844,
              21.01513675276859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_143_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4091",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015211867611065,
          "to_latitude": 21.01513675276859,
          "to_longitude": 105.82879991147844,
          "from_longitude": 105.82866513039843
        }
      },
      {
        "id": 4085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915338221373,
            21.014990788052692,
            105.82915464808426,
            21.014994436334074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915338221373,
              21.014990788052692
            ],
            [
              105.82915364711944,
              21.014991551793724
            ],
            [
              105.8291544682524,
              21.014993923275146
            ],
            [
              105.82915464808426,
              21.014994436334074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_147_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4092",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014990788052692,
          "to_latitude": 21.014994436334074,
          "to_longitude": 105.82915464808426,
          "from_longitude": 105.82915338221373
        }
      },
      {
        "id": 4086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82938311852885,
            21.01508301911697,
            105.82940761947074,
            21.015091630685404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82940761947074,
              21.01508301911697
            ],
            [
              105.82938311852885,
              21.015091630685404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_148_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4093",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01508301911697,
          "to_latitude": 21.015091630685404,
          "to_longitude": 105.82938311852885,
          "from_longitude": 105.82940761947074
        }
      },
      {
        "id": 4087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82902806703221,
            21.01514497079819,
            105.82928729696727,
            21.01561453270545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926575032889,
              21.01561453270545
            ],
            [
              105.82925789615558,
              21.015600621574595
            ],
            [
              105.82925889817044,
              21.015599867013318
            ],
            [
              105.82927175268682,
              21.015591715971873
            ],
            [
              105.8292775127736,
              21.01558806325109
            ],
            [
              105.8292657779662,
              21.015572727681896
            ],
            [
              105.82923627433824,
              21.015537048662928
            ],
            [
              105.82918732335807,
              21.01547476597977
            ],
            [
              105.8291708948947,
              21.015460056634062
            ],
            [
              105.82915513748011,
              21.015444407371294
            ],
            [
              105.82913166826843,
              21.015420934918836
            ],
            [
              105.82909780460058,
              21.015390888862402
            ],
            [
              105.82907198825382,
              21.015372110160584
            ],
            [
              105.82904416110055,
              21.015350828479786
            ],
            [
              105.82903007938535,
              21.015338308844488
            ],
            [
              105.82902806703221,
              21.01533517903841
            ],
            [
              105.82906081569016,
              21.01530342372154
            ],
            [
              105.82910853404496,
              21.01526976731827
            ],
            [
              105.82912529753882,
              21.01526225659296
            ],
            [
              105.8291440728358,
              21.015250989481853
            ],
            [
              105.82920048369226,
              21.015195671083358
            ],
            [
              105.82928729696727,
              21.01514497079819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_136_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4094",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01561453270545,
          "to_latitude": 21.01514497079819,
          "to_longitude": 105.82928729696727,
          "from_longitude": 105.82926575032889
        }
      },
      {
        "id": 4088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82906889026378,
            21.015134647628077,
            105.82912529753882,
            21.01526225659296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912529753882,
              21.01526225659296
            ],
            [
              105.82910911874649,
              21.01524415712104
            ],
            [
              105.82907672332469,
              21.01521172661358
            ],
            [
              105.82906889026378,
              21.015166472538787
            ],
            [
              105.8290968278584,
              21.015134647628077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_136_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4095",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01526225659296,
          "to_latitude": 21.015134647628077,
          "to_longitude": 105.8290968278584,
          "from_longitude": 105.82912529753882
        }
      },
      {
        "id": 4089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040557487736,
            21.018445566665953,
            105.83048442262822,
            21.018497086755936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83040557487736,
              21.018464757769394
            ],
            [
              105.83044282230762,
              21.018445566665953
            ],
            [
              105.83045821081832,
              21.01846643281334
            ],
            [
              105.83046487619228,
              21.01847546323322
            ],
            [
              105.8304706598808,
              21.018483181055103
            ],
            [
              105.83048058516613,
              21.01849218291436
            ],
            [
              105.83048380562408,
              21.018495105382886
            ],
            [
              105.83048442262822,
              21.018497086755936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.4096",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018464757769394,
          "to_latitude": 21.018497086755936,
          "to_longitude": 105.83048442262822,
          "from_longitude": 105.83040557487736
        }
      },
      {
        "id": 4090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189763509168,
            21.016889531642168,
            105.83197091421455,
            21.01694555619892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189763509168,
              21.016889531642168
            ],
            [
              105.83197091421455,
              21.01694555619892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4097",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016889531642168,
          "to_latitude": 21.01694555619892,
          "to_longitude": 105.83197091421455,
          "from_longitude": 105.83189763509168
        }
      },
      {
        "id": 4091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82976187190829,
            21.01649220386862,
            105.82987630655597,
            21.016669930215567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82976187190829,
              21.01649220386862
            ],
            [
              105.82976572718795,
              21.016556157804093
            ],
            [
              105.82987630655597,
              21.016573217767476
            ],
            [
              105.82986157561052,
              21.016669930215567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_123_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4098",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01649220386862,
          "to_latitude": 21.016669930215567,
          "to_longitude": 105.82986157561052,
          "from_longitude": 105.82976187190829
        }
      },
      {
        "id": 4092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82964858051746,
            21.016488292660604,
            105.82978101455242,
            21.016494981854176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82978101455242,
              21.016494981854176
            ],
            [
              105.82976187190829,
              21.01649220386862
            ],
            [
              105.82973974395136,
              21.016489700570048
            ],
            [
              105.82964858051746,
              21.016488292660604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_123_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4099",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016494981854176,
          "to_latitude": 21.016488292660604,
          "to_longitude": 105.82964858051746,
          "from_longitude": 105.82978101455242
        }
      },
      {
        "id": 4093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83101039898298,
            21.01627434315283,
            105.83117997003075,
            21.016408354526238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101039898298,
              21.01627434315283
            ],
            [
              105.83102256322509,
              21.016283633920025
            ],
            [
              105.83107457545584,
              21.0163256571012
            ],
            [
              105.83108132727702,
              21.01633155108089
            ],
            [
              105.83109326487627,
              21.016340907982112
            ],
            [
              105.83112352603398,
              21.016363893548416
            ],
            [
              105.83116300020394,
              21.016395819381028
            ],
            [
              105.83117997003075,
              21.016408354526238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_173_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4100",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01627434315283,
          "to_latitude": 21.016408354526238,
          "to_longitude": 105.83117997003075,
          "from_longitude": 105.83101039898298
        }
      },
      {
        "id": 4094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83119458896992,
            21.01642420515303,
            105.83137395056654,
            21.016540608887865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83119458896992,
              21.01642420515303
            ],
            [
              105.83122005812373,
              21.01643701489634
            ],
            [
              105.83128610744774,
              21.01648083097022
            ],
            [
              105.83131091802886,
              21.016496479140812
            ],
            [
              105.83132269190195,
              21.016502845343652
            ],
            [
              105.83137395056654,
              21.016540608887865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_175_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4101",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01642420515303,
          "to_latitude": 21.016540608887865,
          "to_longitude": 105.83137395056654,
          "from_longitude": 105.83119458896992
        }
      },
      {
        "id": 4095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83132269190195,
            21.016441559356604,
            105.8313700828407,
            21.016502845343652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83132269190195,
              21.016502845343652
            ],
            [
              105.8313700828407,
              21.016441559356604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_175_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4102",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016502845343652,
          "to_latitude": 21.016441559356604,
          "to_longitude": 105.8313700828407,
          "from_longitude": 105.83132269190195
        }
      },
      {
        "id": 4096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503531779222,
            21.010159280507146,
            105.83518385368032,
            21.010297181232982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518385368032,
              21.010297181232982
            ],
            [
              105.8351463626404,
              21.010269370682376
            ],
            [
              105.83511412828828,
              21.010238082198754
            ],
            [
              105.83509128088807,
              21.010215906343863
            ],
            [
              105.83505994864568,
              21.010184240390025
            ],
            [
              105.83505727225733,
              21.010180543075453
            ],
            [
              105.83505598330684,
              21.01017777740305
            ],
            [
              105.83503531779222,
              21.010159280507146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_333_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4103",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010297181232982,
          "to_latitude": 21.010159280507146,
          "to_longitude": 105.83503531779222,
          "from_longitude": 105.83518385368032
        }
      },
      {
        "id": 4097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503633306006,
            21.010515584133145,
            105.83506471870884,
            21.010546159330165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503633306006,
              21.010546159330165
            ],
            [
              105.8350470507068,
              21.010530279522964
            ],
            [
              105.83505678899216,
              21.010515584133145
            ],
            [
              105.83506286033835,
              21.010519524915676
            ],
            [
              105.83506471870884,
              21.010520729730246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_336_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4104",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010546159330165,
          "to_latitude": 21.010520729730246,
          "to_longitude": 105.83506471870884,
          "from_longitude": 105.83503633306006
        }
      },
      {
        "id": 4098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553273822423,
            21.010352291013188,
            105.83574397091698,
            21.01058869145909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83553273822423,
              21.01058869145909
            ],
            [
              105.83555702950422,
              21.010561630788324
            ],
            [
              105.83557447732726,
              21.010541913518338
            ],
            [
              105.83561010336796,
              21.01050150080682
            ],
            [
              105.83568706792859,
              21.010416148344653
            ],
            [
              105.83574397091698,
              21.010356344550054
            ],
            [
              105.83574093946999,
              21.01035280253896
            ],
            [
              105.83574044893608,
              21.010352291013188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_331_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4105",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01058869145909,
          "to_latitude": 21.010352291013188,
          "to_longitude": 105.83574044893608,
          "from_longitude": 105.83553273822423
        }
      },
      {
        "id": 4099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83574397091698,
            21.010340077005406,
            105.83591189841648,
            21.010585214264527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83574397091698,
              21.010356344550054
            ],
            [
              105.83576061644352,
              21.010340077005406
            ],
            [
              105.8357649677838,
              21.010343130211325
            ],
            [
              105.83591189841648,
              21.010454666701474
            ],
            [
              105.83584723826043,
              21.010523720645224
            ],
            [
              105.83578981273395,
              21.010585214264527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_331_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4106",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010356344550054,
          "to_latitude": 21.010585214264527,
          "to_longitude": 105.83578981273395,
          "from_longitude": 105.83574397091698
        }
      },
      {
        "id": 4100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520433734951,
            21.010800377052018,
            105.83554774448817,
            21.011184777773604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83520433734951,
              21.011182566338242
            ],
            [
              105.83520633979865,
              21.011183386573126
            ],
            [
              105.83520850029348,
              21.011184777773604
            ],
            [
              105.8352149499676,
              21.0111732155462
            ],
            [
              105.83522165107345,
              21.011161202278394
            ],
            [
              105.83525864085183,
              21.01110065999424
            ],
            [
              105.83529545110684,
              21.0110565997291
            ],
            [
              105.83529967969704,
              21.011051597685753
            ],
            [
              105.83534554923635,
              21.010997356948394
            ],
            [
              105.83538752916793,
              21.010958040312865
            ],
            [
              105.83543351522208,
              21.01091799767674
            ],
            [
              105.83553483691932,
              21.010815620030183
            ],
            [
              105.83554774448817,
              21.010800377052018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_319_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4107",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011182566338242,
          "to_latitude": 21.010800377052018,
          "to_longitude": 105.83554774448817,
          "from_longitude": 105.83520433734951
        }
      },
      {
        "id": 4101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83510889950904,
            21.011175051565377,
            105.83521831051407,
            21.011417451277005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521831051407,
              21.011175051565377
            ],
            [
              105.83521331391972,
              21.01118274718038
            ],
            [
              105.83519812669856,
              21.01120613576756
            ],
            [
              105.83516516290638,
              21.011264512216574
            ],
            [
              105.83514215462044,
              21.011300902270374
            ],
            [
              105.83510889950904,
              21.011348424278385
            ],
            [
              105.83518745958733,
              21.01140078070988
            ],
            [
              105.83521768997686,
              21.011417451277005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_318_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4108",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011175051565377,
          "to_latitude": 21.011417451277005,
          "to_longitude": 105.83521768997686,
          "from_longitude": 105.83521831051407
        }
      },
      {
        "id": 4102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83563411455768,
            21.01125084096371,
            105.8359840450007,
            21.011652239510894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83563411455768,
              21.011652239510894
            ],
            [
              105.83567896887712,
              21.011605169856974
            ],
            [
              105.83578232375703,
              21.01148714996543
            ],
            [
              105.83579358639709,
              21.01147430769101
            ],
            [
              105.8358086255455,
              21.01145715835433
            ],
            [
              105.83588991564392,
              21.01136446120521
            ],
            [
              105.8359840450007,
              21.01125084096371
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_313_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4109",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011652239510894,
          "to_latitude": 21.01125084096371,
          "to_longitude": 105.8359840450007,
          "from_longitude": 105.83563411455768
        }
      },
      {
        "id": 4103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83579358639709,
            21.01147430769101,
            105.83582063336853,
            21.011495198296615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83582063336853,
              21.011495198296615
            ],
            [
              105.83581741251061,
              21.01149271218502
            ],
            [
              105.83580012377068,
              21.011479357122553
            ],
            [
              105.83579358639709,
              21.01147430769101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_313_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4110",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011495198296615,
          "to_latitude": 21.01147430769101,
          "to_longitude": 105.83579358639709,
          "from_longitude": 105.83582063336853
        }
      },
      {
        "id": 4104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83545643368258,
            21.011201339828062,
            105.83589816513508,
            21.01166509870931
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83545643368258,
              21.01166509870931
            ],
            [
              105.8354595073361,
              21.011662397427028
            ],
            [
              105.83548234483214,
              21.011642325830994
            ],
            [
              105.83548849979206,
              21.01163691600213
            ],
            [
              105.8355860174692,
              21.011527440490497
            ],
            [
              105.83561842971916,
              21.01152082868196
            ],
            [
              105.83568707738299,
              21.011439836279205
            ],
            [
              105.83575880690553,
              21.011363847030104
            ],
            [
              105.83583282763308,
              21.011288205077758
            ],
            [
              105.83587340731151,
              21.011239971342004
            ],
            [
              105.83589816513508,
              21.011201339828062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_311_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4111",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01166509870931,
          "to_latitude": 21.011201339828062,
          "to_longitude": 105.83589816513508,
          "from_longitude": 105.83545643368258
        }
      },
      {
        "id": 4105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83527822368329,
            21.01148358361622,
            105.83557440177984,
            21.01171350945865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527822368329,
              21.01148358361622
            ],
            [
              105.83540963077638,
              21.011584743685813
            ],
            [
              105.83543710432363,
              21.011605893290724
            ],
            [
              105.83546232356939,
              21.01162625279914
            ],
            [
              105.83547944420721,
              21.011639997108176
            ],
            [
              105.83548234483214,
              21.011642325830994
            ],
            [
              105.83550153899866,
              21.011657733632013
            ],
            [
              105.83557440177984,
              21.01171350945865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_310_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4112",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01148358361622,
          "to_latitude": 21.01171350945865,
          "to_longitude": 105.83557440177984,
          "from_longitude": 105.83527822368329
        }
      },
      {
        "id": 4106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83545519659542,
            21.011639997108176,
            105.83547944420721,
            21.011664049816762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83547944420721,
              21.011639997108176
            ],
            [
              105.83546858703149,
              21.011651899824432
            ],
            [
              105.83545836547175,
              21.011661174647795
            ],
            [
              105.83545519659542,
              21.011664049816762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_310_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4113",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011639997108176,
          "to_latitude": 21.011664049816762,
          "to_longitude": 105.83545519659542,
          "from_longitude": 105.83547944420721
        }
      },
      {
        "id": 4107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601490598883,
            21.01127346957324,
            105.83639735532779,
            21.011475122658144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83601490598883,
              21.01127346957324
            ],
            [
              105.8360173037655,
              21.011274631081495
            ],
            [
              105.83608434193061,
              21.011307059852417
            ],
            [
              105.83629079244933,
              21.011475122658144
            ],
            [
              105.83633333364142,
              21.011459379910832
            ],
            [
              105.83639735532779,
              21.011416049149705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_314_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4114",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01127346957324,
          "to_latitude": 21.011416049149705,
          "to_longitude": 105.83639735532779,
          "from_longitude": 105.83601490598883
        }
      },
      {
        "id": 4108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459403003003,
            21.011185178557678,
            105.83467749849667,
            21.01123152188068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467749849667,
              21.011185178557678
            ],
            [
              105.83464569399338,
              21.01123152188068
            ],
            [
              105.83463999420734,
              21.011228323518978
            ],
            [
              105.83459403003003,
              21.011200767575797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_307_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4115",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011185178557678,
          "to_latitude": 21.011200767575797,
          "to_longitude": 105.83459403003003,
          "from_longitude": 105.83467749849667
        }
      },
      {
        "id": 4109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487382504218,
            21.012110745127902,
            105.8352476542285,
            21.01227110097638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352476542285,
              21.01227110097638
            ],
            [
              105.8352451451343,
              21.012270507229314
            ],
            [
              105.8352315410747,
              21.012267279044938
            ],
            [
              105.83521940027394,
              21.012268921994018
            ],
            [
              105.83512413521125,
              21.012209671981367
            ],
            [
              105.83507192601652,
              21.0121840390888
            ],
            [
              105.83498523504451,
              21.012132299680818
            ],
            [
              105.83493719348448,
              21.012110745127902
            ],
            [
              105.83490667553801,
              21.012154081671856
            ],
            [
              105.83487382504218,
              21.012133310623902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_299_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4116",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01227110097638,
          "to_latitude": 21.012133310623902,
          "to_longitude": 105.83487382504218,
          "from_longitude": 105.8352476542285
        }
      },
      {
        "id": 4110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483772592997,
            21.01182191940743,
            105.83529648220669,
            21.012146059093993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83483772592997,
              21.01182191940743
            ],
            [
              105.83494315953664,
              21.011909436700645
            ],
            [
              105.83506477394008,
              21.011993384452854
            ],
            [
              105.83509829645415,
              21.01201599293845
            ],
            [
              105.83521904941101,
              21.012096185580553
            ],
            [
              105.83529648220669,
              21.012146059093993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_302_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4117",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01182191940743,
          "to_latitude": 21.012146059093993,
          "to_longitude": 105.83529648220669,
          "from_longitude": 105.83483772592997
        }
      },
      {
        "id": 4111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505736349365,
            21.011993384452854,
            105.83506477394008,
            21.012002221844412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506477394008,
              21.011993384452854
            ],
            [
              105.83505736349365,
              21.012002221844412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_302_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4118",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011993384452854,
          "to_latitude": 21.012002221844412,
          "to_longitude": 105.83505736349365,
          "from_longitude": 105.83506477394008
        }
      },
      {
        "id": 4112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83417382758718,
            21.011745462495636,
            105.8343555173032,
            21.011938693670142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83417382758718,
              21.011938693670142
            ],
            [
              105.83420552436999,
              21.011880710194557
            ],
            [
              105.83423532399337,
              21.011827007366044
            ],
            [
              105.83424268235142,
              21.01181761749064
            ],
            [
              105.8342904867932,
              21.0117566096087
            ],
            [
              105.83434270182266,
              21.011745462495636
            ],
            [
              105.83435169385561,
              21.011748244594525
            ],
            [
              105.8343555173032,
              21.01174942803872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_286_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4119",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011938693670142,
          "to_latitude": 21.01174942803872,
          "to_longitude": 105.8343555173032,
          "from_longitude": 105.83417382758718
        }
      },
      {
        "id": 4113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436074135308,
            21.011756502674295,
            105.83457989869251,
            21.01190039639495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436074135308,
              21.011756502674295
            ],
            [
              105.8345021902531,
              21.01184552002893
            ],
            [
              105.83457989869251,
              21.01190039639495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_288_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4120",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011756502674295,
          "to_latitude": 21.01190039639495,
          "to_longitude": 105.83457989869251,
          "from_longitude": 105.83436074135308
        }
      },
      {
        "id": 4114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83352975564321,
            21.011122547989398,
            105.83396020366983,
            21.01147803292637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339351905558,
              21.011474254492935
            ],
            [
              105.83395965177068,
              21.01147803292637
            ],
            [
              105.83396020366983,
              21.011467434487567
            ],
            [
              105.83390445543648,
              21.011437882311622
            ],
            [
              105.83378783625209,
              21.011335050777934
            ],
            [
              105.83378511087881,
              21.0113315379415
            ],
            [
              105.83375550094358,
              21.01130566743572
            ],
            [
              105.83373663470279,
              21.011287954599712
            ],
            [
              105.83352975564321,
              21.011122547989398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_281_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4121",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011474254492935,
          "to_latitude": 21.011122547989398,
          "to_longitude": 105.83352975564321,
          "from_longitude": 105.8339351905558
        }
      },
      {
        "id": 4115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83365066056025,
            21.01151650996109,
            105.83392429796999,
            21.011801452888786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83392429796999,
              21.01152185906696
            ],
            [
              105.83392271575671,
              21.01152153624091
            ],
            [
              105.83389816968486,
              21.01151650996109
            ],
            [
              105.83383569338305,
              21.01163190778865
            ],
            [
              105.83378606567634,
              21.011654266446616
            ],
            [
              105.83371255258129,
              21.011731296019192
            ],
            [
              105.83365066056025,
              21.011801452888786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_279_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4122",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01152185906696,
          "to_latitude": 21.011801452888786,
          "to_longitude": 105.83365066056025,
          "from_longitude": 105.83392429796999
        }
      },
      {
        "id": 4116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360910254163,
            21.011960278181572,
            105.83368564017029,
            21.012029884171525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368564017029,
              21.012029884171525
            ],
            [
              105.83366442801848,
              21.012008067731806
            ],
            [
              105.83360910254163,
              21.011960278181572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_277_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4123",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012029884171525,
          "to_latitude": 21.011960278181572,
          "to_longitude": 105.83360910254163,
          "from_longitude": 105.83368564017029
        }
      },
      {
        "id": 4117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434625027617,
            21.011748244594525,
            105.83435169385561,
            21.011762405855436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83434625027617,
              21.011762405855436
            ],
            [
              105.83434658876688,
              21.011761522648037
            ],
            [
              105.83435169385561,
              21.011748244594525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_286_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4124",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011762405855436,
          "to_latitude": 21.011748244594525,
          "to_longitude": 105.83435169385561,
          "from_longitude": 105.83434625027617
        }
      },
      {
        "id": 4118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436254067855,
            21.012753013162822,
            105.83515661744,
            21.01342317688725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515661744,
              21.012903817843316
            ],
            [
              105.83507373732708,
              21.01285021179408
            ],
            [
              105.8350075864377,
              21.012813794165123
            ],
            [
              105.83494185328482,
              21.01278260322792
            ],
            [
              105.83487990748233,
              21.012753013162822
            ],
            [
              105.8348537897016,
              21.01279470952861
            ],
            [
              105.83482403990389,
              21.012839899320184
            ],
            [
              105.83479072225565,
              21.01288524841813
            ],
            [
              105.83477613861803,
              21.012900463436683
            ],
            [
              105.83476742572758,
              21.01290671766095
            ],
            [
              105.8347185415541,
              21.012931366235723
            ],
            [
              105.83471145921803,
              21.012933836074843
            ],
            [
              105.83470437783855,
              21.01293630500575
            ],
            [
              105.83469758658254,
              21.012940089414908
            ],
            [
              105.83466046795051,
              21.01298329571819
            ],
            [
              105.83465625458645,
              21.01299035612143
            ],
            [
              105.83465932243757,
              21.013015476916067
            ],
            [
              105.83468480261783,
              21.013067148251135
            ],
            [
              105.83466913685382,
              21.013115568404647
            ],
            [
              105.83462373987825,
              21.013162750661422
            ],
            [
              105.83459805634912,
              21.01316962283292
            ],
            [
              105.8345676523309,
              21.013194243039074
            ],
            [
              105.834550033698,
              21.013211345311056
            ],
            [
              105.83454224445427,
              21.0132224823435
            ],
            [
              105.8345210999333,
              21.01324019713189
            ],
            [
              105.83451643514833,
              21.013244270071752
            ],
            [
              105.83448538923093,
              21.013268841928333
            ],
            [
              105.83447176952576,
              21.013278724071057
            ],
            [
              105.83446327310199,
              21.013286547023544
            ],
            [
              105.83447187981449,
              21.013320512130676
            ],
            [
              105.83445697192941,
              21.013347831023147
            ],
            [
              105.83436254067855,
              21.01342317688725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_292_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4125",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 131,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012903817843316,
          "to_latitude": 21.01342317688725,
          "to_longitude": 105.83436254067855,
          "from_longitude": 105.83515661744
        }
      },
      {
        "id": 4119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455361024714,
            21.01280935206129,
            105.83471035441272,
            21.012912996871968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83471035441272,
              21.012912996871968
            ],
            [
              105.8346840074217,
              21.01288965311646
            ],
            [
              105.83458249678286,
              21.01282735616131
            ],
            [
              105.83455361024714,
              21.01280935206129
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_293_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4126",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012912996871968,
          "to_latitude": 21.01280935206129,
          "to_longitude": 105.83455361024714,
          "from_longitude": 105.83471035441272
        }
      },
      {
        "id": 4120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83458080813823,
            21.01262669858489,
            105.83487308808655,
            21.012738429736633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83487308808655,
              21.012738429736633
            ],
            [
              105.8348587371673,
              21.012732195643096
            ],
            [
              105.83482489634738,
              21.01271567641288
            ],
            [
              105.83478799728998,
              21.012697718891385
            ],
            [
              105.83477665266508,
              21.012715220338528
            ],
            [
              105.83471704124764,
              21.012689734754957
            ],
            [
              105.83462758682457,
              21.01264669775289
            ],
            [
              105.83458080813823,
              21.01262669858489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_293_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4127",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012738429736633,
          "to_latitude": 21.01262669858489,
          "to_longitude": 105.83458080813823,
          "from_longitude": 105.83487308808655
        }
      },
      {
        "id": 4121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83024525330896,
            21.016053273407312,
            105.83057852960123,
            21.016268264427225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83057852960123,
              21.016268264427225
            ],
            [
              105.8305779899333,
              21.016268087320157
            ],
            [
              105.83056823821553,
              21.01626486706065
            ],
            [
              105.83056107983391,
              21.016262504848836
            ],
            [
              105.83052102736576,
              21.016265611478012
            ],
            [
              105.83045992257058,
              21.016211863482884
            ],
            [
              105.83040166748029,
              21.01617104386902
            ],
            [
              105.83031325059461,
              21.016104474169413
            ],
            [
              105.83024525330896,
              21.016053273407312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_159_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4128",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016268264427225,
          "to_latitude": 21.016053273407312,
          "to_longitude": 105.83024525330896,
          "from_longitude": 105.83057852960123
        }
      },
      {
        "id": 4122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83045152020996,
            21.01626486706065,
            105.83056823821553,
            21.016367157032736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83056823821553,
              21.01626486706065
            ],
            [
              105.83055474721895,
              21.01628783961914
            ],
            [
              105.83050816670315,
              21.016367157032736
            ],
            [
              105.83045152020996,
              21.016341333963236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_159_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4129",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01626486706065,
          "to_latitude": 21.016341333963236,
          "to_longitude": 105.83045152020996,
          "from_longitude": 105.83056823821553
        }
      },
      {
        "id": 4123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83001961362321,
            21.017182041057147,
            105.83005352412727,
            21.017241326214176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005352412727,
              21.017182041057147
            ],
            [
              105.83003788519918,
              21.017209231280056
            ],
            [
              105.83001961362321,
              21.017241326214176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4130",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017182041057147,
          "to_latitude": 21.017241326214176,
          "to_longitude": 105.83001961362321,
          "from_longitude": 105.83005352412727
        }
      },
      {
        "id": 4124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174356266198,
            21.015100075313118,
            105.8318109143482,
            21.015163920289307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83174356266198,
              21.015163920289307
            ],
            [
              105.8318109143482,
              21.015100075313118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_194_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4131",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015163920289307,
          "to_latitude": 21.015100075313118,
          "to_longitude": 105.8318109143482,
          "from_longitude": 105.83174356266198
        }
      },
      {
        "id": 4125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83181931621208,
            21.01510273758729,
            105.83229623768725,
            21.01554299351271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83181931621208,
              21.01510273758729
            ],
            [
              105.8318458025041,
              21.015128656195923
            ],
            [
              105.83190259963122,
              21.015182030052383
            ],
            [
              105.83194747303676,
              21.015223852141013
            ],
            [
              105.83202182827242,
              21.01529315156492
            ],
            [
              105.83211349776847,
              21.015377940579384
            ],
            [
              105.83217653304484,
              21.015436134144633
            ],
            [
              105.83221746465009,
              21.01547397957002
            ],
            [
              105.83229623768725,
              21.01554299351271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_197_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4132",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01510273758729,
          "to_latitude": 21.01554299351271,
          "to_longitude": 105.83229623768725,
          "from_longitude": 105.83181931621208
        }
      },
      {
        "id": 4126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194747303676,
            21.015216636413324,
            105.83195446379456,
            21.015223852141013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195446379456,
              21.015216636413324
            ],
            [
              105.83195414815819,
              21.01521696131138
            ],
            [
              105.83195242557989,
              21.01521874005604
            ],
            [
              105.83194747303676,
              21.015223852141013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_197_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4133",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015216636413324,
          "to_latitude": 21.015223852141013,
          "to_longitude": 105.83194747303676,
          "from_longitude": 105.83195446379456
        }
      },
      {
        "id": 4127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164239735119,
            21.015307507286977,
            105.83204299394774,
            21.015844779982196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83164239735119,
              21.015844779982196
            ],
            [
              105.83165663183617,
              21.01582529828542
            ],
            [
              105.8316671182844,
              21.015822760444248
            ],
            [
              105.8317047921919,
              21.015789204815345
            ],
            [
              105.83181058073075,
              21.015695947175416
            ],
            [
              105.83185310420825,
              21.015659187548508
            ],
            [
              105.8319094499026,
              21.01561031672032
            ],
            [
              105.83199736737592,
              21.015534061954003
            ],
            [
              105.83204299394774,
              21.015492616529617
            ],
            [
              105.83199538428173,
              21.01544066282807
            ],
            [
              105.83194375216571,
              21.015384953301222
            ],
            [
              105.83188608502971,
              21.015324235980426
            ],
            [
              105.83186577814905,
              21.015307507286977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_188_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4134",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015844779982196,
          "to_latitude": 21.015307507286977,
          "to_longitude": 105.83186577814905,
          "from_longitude": 105.83164239735119
        }
      },
      {
        "id": 4128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187669728622,
            21.015294190036524,
            105.83239012397145,
            21.015808606188994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83187669728622,
              21.015294190036524
            ],
            [
              105.83190687211246,
              21.01532799110796
            ],
            [
              105.83206445110685,
              21.015493868233055
            ],
            [
              105.83215430526403,
              21.015585882865587
            ],
            [
              105.83219789110007,
              21.015628447307076
            ],
            [
              105.8322528771944,
              21.01567852318673
            ],
            [
              105.83231859106078,
              21.01574111764909
            ],
            [
              105.83238554463001,
              21.015805219823548
            ],
            [
              105.83239012397145,
              21.015808606188994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_192_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4135",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015294190036524,
          "to_latitude": 21.015808606188994,
          "to_longitude": 105.83239012397145,
          "from_longitude": 105.83187669728622
        }
      },
      {
        "id": 4129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83064473725133,
            21.0146813981525,
            105.83112680081942,
            21.014713803001694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83112680081942,
              21.01469458463466
            ],
            [
              105.83112583636408,
              21.014693781862718
            ],
            [
              105.83111465840582,
              21.0146813981525
            ],
            [
              105.83103019026076,
              21.01468730056406
            ],
            [
              105.83093005981391,
              21.014695437233627
            ],
            [
              105.8308474357404,
              21.01470291480568
            ],
            [
              105.83073670592631,
              21.014708999511473
            ],
            [
              105.83064473725133,
              21.014713803001694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_228_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4136",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01469458463466,
          "to_latitude": 21.014713803001694,
          "to_longitude": 105.83064473725133,
          "from_longitude": 105.83112680081942
        }
      },
      {
        "id": 4130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8305087757914,
            21.013866327218558,
            105.83109033448945,
            21.0140223598976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8305087757914,
              21.01401776834372
            ],
            [
              105.83051959884358,
              21.0140223598976
            ],
            [
              105.83052603123285,
              21.014011932373528
            ],
            [
              105.83053209263123,
              21.01400364640129
            ],
            [
              105.83063914656235,
              21.01401478623318
            ],
            [
              105.83078061349025,
              21.01400387552236
            ],
            [
              105.83087726699314,
              21.013996417093683
            ],
            [
              105.83092503973316,
              21.01398280069823
            ],
            [
              105.83097580729286,
              21.013958655216133
            ],
            [
              105.83109033448945,
              21.013866327218558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_225_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4137",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01401776834372,
          "to_latitude": 21.013866327218558,
          "to_longitude": 105.83109033448945,
          "from_longitude": 105.8305087757914
        }
      },
      {
        "id": 4131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83061874092341,
            21.014259333877717,
            105.83085632741391,
            21.014435391137255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83085632741391,
              21.014435391137255
            ],
            [
              105.83085525890345,
              21.014414759462593
            ],
            [
              105.83084306076462,
              21.01440487632655
            ],
            [
              105.83084505643002,
              21.014318847914392
            ],
            [
              105.83084687561343,
              21.01428779246535
            ],
            [
              105.8308081722811,
              21.014277174372467
            ],
            [
              105.83072636482134,
              21.014269662479727
            ],
            [
              105.83069233418216,
              21.01426575238043
            ],
            [
              105.83068277903816,
              21.01426465464626
            ],
            [
              105.83061874092341,
              21.014259333877717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_232_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4138",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014435391137255,
          "to_latitude": 21.014259333877717,
          "to_longitude": 105.83061874092341,
          "from_longitude": 105.83085632741391
        }
      },
      {
        "id": 4132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82956653617964,
            21.014457964633202,
            105.8296317047546,
            21.014533574715404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8296317047546,
              21.014533574715404
            ],
            [
              105.82961452437918,
              21.014513171529046
            ],
            [
              105.82958936541918,
              21.014484184150373
            ],
            [
              105.82956653617964,
              21.014457964633202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_217_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4139",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014533574715404,
          "to_latitude": 21.014457964633202,
          "to_longitude": 105.82956653617964,
          "from_longitude": 105.8296317047546
        }
      },
      {
        "id": 4133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82945064698613,
            21.014709043783753,
            105.82954451724466,
            21.01482769988726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82954451724466,
              21.01482769988726
            ],
            [
              105.82953179583401,
              21.01480928824238
            ],
            [
              105.82951541532358,
              21.014789500759935
            ],
            [
              105.82947229733473,
              21.01473499086928
            ],
            [
              105.82945064698613,
              21.014709043783753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_212_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4140",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01482769988726,
          "to_latitude": 21.014709043783753,
          "to_longitude": 105.82945064698613,
          "from_longitude": 105.82954451724466
        }
      },
      {
        "id": 4134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82904825045105,
            21.01502453005539,
            105.82907208662316,
            21.015081863564685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82907208662316,
              21.015081863564685
            ],
            [
              105.82905574618434,
              21.015048958297236
            ],
            [
              105.82904825045105,
              21.01502453005539
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_147_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4141",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015081863564685,
          "to_latitude": 21.01502453005539,
          "to_longitude": 105.82904825045105,
          "from_longitude": 105.82907208662316
        }
      },
      {
        "id": 4135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82893776329558,
            21.014904135172657,
            105.82942943313874,
            21.015119148396437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82942943313874,
              21.01503481149722
            ],
            [
              105.82941769856899,
              21.015014468453245
            ],
            [
              105.82940965222775,
              21.015000071426204
            ],
            [
              105.82940160550872,
              21.014984735949252
            ],
            [
              105.8293797593248,
              21.01494355600881
            ],
            [
              105.82935500181776,
              21.014906804320226
            ],
            [
              105.82932852909475,
              21.014904135172657
            ],
            [
              105.82924242882015,
              21.01494562361608
            ],
            [
              105.82915830440821,
              21.014993220837596
            ],
            [
              105.82915464808426,
              21.014994436334074
            ],
            [
              105.82908945839334,
              21.015016115312907
            ],
            [
              105.82904825045105,
              21.01502453005539
            ],
            [
              105.82903104829553,
              21.015030466781525
            ],
            [
              105.82901407188128,
              21.01503391040657
            ],
            [
              105.82901000169201,
              21.01503473591674
            ],
            [
              105.82899851117712,
              21.015036959647897
            ],
            [
              105.82894227280087,
              21.015055607084097
            ],
            [
              105.82893776329558,
              21.015119148396437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_147_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4142",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01503481149722,
          "to_latitude": 21.015119148396437,
          "to_longitude": 105.82893776329558,
          "from_longitude": 105.82942943313874
        }
      },
      {
        "id": 4136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82850813060286,
            21.015246768041237,
            105.82853183160177,
            21.015260026298876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82853183160177,
              21.015246768041237
            ],
            [
              105.82850813060286,
              21.015260026298876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_142_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4143",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015246768041237,
          "to_latitude": 21.015260026298876,
          "to_longitude": 105.82850813060286,
          "from_longitude": 105.82853183160177
        }
      },
      {
        "id": 4137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82916787770722,
            21.01551294934116,
            105.8292074397976,
            21.01554174279719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292074397976,
              21.01551294934116
            ],
            [
              105.82916787770722,
              21.01554174279719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4144",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01551294934116,
          "to_latitude": 21.01554174279719,
          "to_longitude": 105.82916787770722,
          "from_longitude": 105.8292074397976
        }
      },
      {
        "id": 4138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298238288746,
            21.014983869337858,
            105.82982430277646,
            21.014987808702326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982430277646,
              21.014987808702326
            ],
            [
              105.8298238288746,
              21.014983869337858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_220_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4145",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014987808702326,
          "to_latitude": 21.014983869337858,
          "to_longitude": 105.8298238288746,
          "from_longitude": 105.82982430277646
        }
      },
      {
        "id": 4139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299427826864,
            21.015485293657452,
            105.82998751579217,
            21.015934875337447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299427826864,
              21.015485293657452
            ],
            [
              105.82994398941428,
              21.015500849440926
            ],
            [
              105.82994521790657,
              21.015516340085966
            ],
            [
              105.82995249091282,
              21.015550378783477
            ],
            [
              105.82995726885589,
              21.015585844103292
            ],
            [
              105.82996252887095,
              21.015639588352578
            ],
            [
              105.8299651652457,
              21.015676390054015
            ],
            [
              105.82996752661168,
              21.015694261493216
            ],
            [
              105.82996945059512,
              21.01574052343919
            ],
            [
              105.82997299868245,
              21.015774679661263
            ],
            [
              105.82997428907049,
              21.015787099940514
            ],
            [
              105.82997550817761,
              21.015808020821265
            ],
            [
              105.82998210884395,
              21.015866402912444
            ],
            [
              105.82998599746836,
              21.01591568378701
            ],
            [
              105.82998751579217,
              21.015934875337447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4146",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015485293657452,
          "to_latitude": 21.015934875337447,
          "to_longitude": 105.82998751579217,
          "from_longitude": 105.8299427826864
        }
      },
      {
        "id": 4140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82997299868245,
            21.015773596301838,
            105.82998344703427,
            21.015774679661263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82998344703427,
              21.015773596301838
            ],
            [
              105.82998269234781,
              21.01577367495134
            ],
            [
              105.82997819978995,
              21.01577414125546
            ],
            [
              105.82997370722705,
              21.015774606656247
            ],
            [
              105.82997299868245,
              21.015774679661263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4147",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015773596301838,
          "to_latitude": 21.015774679661263,
          "to_longitude": 105.82997299868245,
          "from_longitude": 105.82998344703427
        }
      },
      {
        "id": 4141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82995563174723,
            21.015948300669926,
            105.83000127709974,
            21.016445177059907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299890397244,
              21.015948300669926
            ],
            [
              105.82999580110702,
              21.01603659318515
            ],
            [
              105.8299992984781,
              21.016092350286108
            ],
            [
              105.83000127709974,
              21.016125362550493
            ],
            [
              105.83000087434209,
              21.016161399592555
            ],
            [
              105.8299965757543,
              21.016193965578655
            ],
            [
              105.82999374930108,
              21.016229552896903
            ],
            [
              105.82999160671748,
              21.01625956488168
            ],
            [
              105.8299789340975,
              21.016357956329692
            ],
            [
              105.82995563174723,
              21.016445177059907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4148",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015948300669926,
          "to_latitude": 21.016445177059907,
          "to_longitude": 105.82995563174723,
          "from_longitude": 105.8299890397244
        }
      },
      {
        "id": 4142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999160671748,
            21.016259467313716,
            105.83000179313812,
            21.01625956488168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82999160671748,
              21.01625956488168
            ],
            [
              105.83000179313812,
              21.016259467313716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4149",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01625956488168,
          "to_latitude": 21.016259467313716,
          "to_longitude": 105.83000179313812,
          "from_longitude": 105.82999160671748
        }
      },
      {
        "id": 4143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83001057274652,
            21.01459715435919,
            105.83015281498481,
            21.015039481336913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83001057274652,
              21.015039481336913
            ],
            [
              105.83002817301781,
              21.014977380013477
            ],
            [
              105.83004558177839,
              21.01491091975336
            ],
            [
              105.83005012703268,
              21.01488938636104
            ],
            [
              105.83005735034556,
              21.014855170359574
            ],
            [
              105.83008270671424,
              21.014759881912546
            ],
            [
              105.8300981624661,
              21.014724318776008
            ],
            [
              105.83012084530839,
              21.014665135353106
            ],
            [
              105.83013738159364,
              21.01462945313461
            ],
            [
              105.83015281498481,
              21.01459715435919
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_221_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4150",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015039481336913,
          "to_latitude": 21.01459715435919,
          "to_longitude": 105.83015281498481,
          "from_longitude": 105.83001057274652
        }
      },
      {
        "id": 4144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83005012703268,
            21.01488938636104,
            105.83007837060435,
            21.014893780045693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005012703268,
              21.01488938636104
            ],
            [
              105.83006808734677,
              21.014892180024294
            ],
            [
              105.83007439536773,
              21.0148931626668
            ],
            [
              105.83007837060435,
              21.014893780045693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_221_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4151",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488938636104,
          "to_latitude": 21.014893780045693,
          "to_longitude": 105.83007837060435,
          "from_longitude": 105.83005012703268
        }
      },
      {
        "id": 4145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83016691714457,
            21.01415128263996,
            105.8303408467026,
            21.014566783238614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83016691714457,
              21.014566783238614
            ],
            [
              105.83018050807816,
              21.014539114352313
            ],
            [
              105.83019449182805,
              21.014513595031236
            ],
            [
              105.83022110425915,
              21.014459482999413
            ],
            [
              105.83024706621435,
              21.014374574149926
            ],
            [
              105.83026224402298,
              21.01434007725825
            ],
            [
              105.83026510345266,
              21.014333577242347
            ],
            [
              105.83028299251494,
              21.01429413911998
            ],
            [
              105.83030672551203,
              21.014236668483303
            ],
            [
              105.83033479627797,
              21.014171459515367
            ],
            [
              105.8303408467026,
              21.01415128263996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_223_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4152",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014566783238614,
          "to_latitude": 21.01415128263996,
          "to_longitude": 105.8303408467026,
          "from_longitude": 105.83016691714457
        }
      },
      {
        "id": 4146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026224402298,
            21.01434007725825,
            105.83028462830009,
            21.014348974083536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026224402298,
              21.01434007725825
            ],
            [
              105.83027393698725,
              21.014344725098127
            ],
            [
              105.83028114326613,
              21.0143475901881
            ],
            [
              105.83028462830009,
              21.014348974083536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_223_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4153",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01434007725825,
          "to_latitude": 21.014348974083536,
          "to_longitude": 105.83028462830009,
          "from_longitude": 105.83026224402298
        }
      },
      {
        "id": 4147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299395355019,
            21.016285957115905,
            105.83003715063818,
            21.016779119260054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8299395355019,
              21.016779119260054
            ],
            [
              105.82994670570692,
              21.016752497844465
            ],
            [
              105.82995511877533,
              21.01672049346517
            ],
            [
              105.82996378842064,
              21.016691223703024
            ],
            [
              105.82997160007834,
              21.016656577613382
            ],
            [
              105.82998087187745,
              21.01661409343664
            ],
            [
              105.82999199525601,
              21.016563758424088
            ],
            [
              105.82999605363243,
              21.016538889090423
            ],
            [
              105.8299971161457,
              21.016532378878217
            ],
            [
              105.8300009436576,
              21.016501252222795
            ],
            [
              105.83000476377272,
              21.016470179796826
            ],
            [
              105.83001064232414,
              21.01643786894149
            ],
            [
              105.8300175121805,
              21.016391569515587
            ],
            [
              105.83002563011219,
              21.01635004296501
            ],
            [
              105.83003715063818,
              21.016285957115905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4154",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016779119260054,
          "to_latitude": 21.016285957115905,
          "to_longitude": 105.83003715063818,
          "from_longitude": 105.8299395355019
        }
      },
      {
        "id": 4148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82997615317605,
            21.01653597482658,
            105.82999605363243,
            21.016538889090423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82997615317605,
              21.01653597482658
            ],
            [
              105.82997636208705,
              21.01653600542025
            ],
            [
              105.82997926181598,
              21.016536431144065
            ],
            [
              105.82998216345885,
              21.016536855052056
            ],
            [
              105.82999605363243,
              21.016538889090423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4155",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01653597482658,
          "to_latitude": 21.016538889090423,
          "to_longitude": 105.82999605363243,
          "from_longitude": 105.82997615317605
        }
      },
      {
        "id": 4149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82922428980304,
            21.01656489545168,
            105.82929963029736,
            21.016619370244136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82929963029736,
              21.016619370244136
            ],
            [
              105.82928870611127,
              21.016617393045493
            ],
            [
              105.82927167990394,
              21.01660733823982
            ],
            [
              105.82922428980304,
              21.01656489545168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4156",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016619370244136,
          "to_latitude": 21.01656489545168,
          "to_longitude": 105.82922428980304,
          "from_longitude": 105.82929963029736
        }
      },
      {
        "id": 4150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924061610025,
            21.016092117143852,
            105.82929625047136,
            21.01613116831482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924061610025,
              21.01613116831482
            ],
            [
              105.82926779257609,
              21.01611250166072
            ],
            [
              105.82929625047136,
              21.016092117143852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_122_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4157",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01613116831482,
          "to_latitude": 21.016092117143852,
          "to_longitude": 105.82929625047136,
          "from_longitude": 105.82924061610025
        }
      },
      {
        "id": 4151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82760830662914,
            21.01200025123634,
            105.82832455970718,
            21.01224176656182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832455970718,
              21.012052734170332
            ],
            [
              105.82832134088662,
              21.012053907775705
            ],
            [
              105.82827710194516,
              21.012070024424375
            ],
            [
              105.82825131481141,
              21.01208307059887
            ],
            [
              105.82813995515362,
              21.012138187352882
            ],
            [
              105.82803701485393,
              21.01218925815931
            ],
            [
              105.82795134743004,
              21.0122343702743
            ],
            [
              105.82793747317085,
              21.01224176656182
            ],
            [
              105.82790984377172,
              21.012193955954647
            ],
            [
              105.82784064791262,
              21.012090678143437
            ],
            [
              105.82779096813107,
              21.01201750472119
            ],
            [
              105.82778177736701,
              21.01200396671952
            ],
            [
              105.82777909813424,
              21.01200025123634
            ],
            [
              105.82772537679061,
              21.01202895697492
            ],
            [
              105.82767719758077,
              21.012054103220954
            ],
            [
              105.82762570379559,
              21.012081378221882
            ],
            [
              105.82760830662914,
              21.012089927876723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_265_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4158",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012052734170332,
          "to_latitude": 21.012089927876723,
          "to_longitude": 105.82760830662914,
          "from_longitude": 105.82832455970718
        }
      },
      {
        "id": 4152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82779096813107,
            21.011939460573796,
            105.82792203117302,
            21.01201750472119
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779096813107,
              21.01201750472119
            ],
            [
              105.82781115816027,
              21.012004677330093
            ],
            [
              105.8278620826396,
              21.011974825430613
            ],
            [
              105.82792203117302,
              21.011939460573796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_265_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4159",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01201750472119,
          "to_latitude": 21.011939460573796,
          "to_longitude": 105.82792203117302,
          "from_longitude": 105.82779096813107
        }
      },
      {
        "id": 4153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82873850867809,
            21.012411703591997,
            105.82893783527305,
            21.012766153812244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82873850867809,
              21.012411703591997
            ],
            [
              105.82873976817828,
              21.012415075522345
            ],
            [
              105.82874468600083,
              21.012428226022497
            ],
            [
              105.8287469276785,
              21.01243422065309
            ],
            [
              105.82877116800674,
              21.0124777536859
            ],
            [
              105.82880474915268,
              21.01253793282983
            ],
            [
              105.82886338382386,
              21.012640479473532
            ],
            [
              105.82890376576186,
              21.01270796146546
            ],
            [
              105.82891532897622,
              21.01272797932763
            ],
            [
              105.82893783527305,
              21.012766153812244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_263_Ngõ 12, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4160",
          "diaChiLapD": "Ngõ 12, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012411703591997,
          "to_latitude": 21.012766153812244,
          "to_longitude": 105.82893783527305,
          "from_longitude": 105.82873850867809
        }
      },
      {
        "id": 4154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82810424812698,
            21.013498318691077,
            105.82815135561899,
            21.01356682218853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82810424812698,
              21.013498318691077
            ],
            [
              105.82811873665977,
              21.013518711659355
            ],
            [
              105.82814218066424,
              21.013552338457874
            ],
            [
              105.82815135561899,
              21.01356682218853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_208_Ngõ 14, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4161",
          "diaChiLapD": "Ngõ 14, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013498318691077,
          "to_latitude": 21.01356682218853,
          "to_longitude": 105.82815135561899,
          "from_longitude": 105.82810424812698
        }
      },
      {
        "id": 4155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786661577434,
            21.014015888250608,
            105.82806563604123,
            21.01417209544684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82786661577434,
              21.014026877756812
            ],
            [
              105.82786722643202,
              21.0140263247209
            ],
            [
              105.82787885817783,
              21.014015888250608
            ],
            [
              105.82788031434049,
              21.014019513036395
            ],
            [
              105.82789698227631,
              21.014049603288893
            ],
            [
              105.82794190234152,
              21.014097176189914
            ],
            [
              105.82795074809019,
              21.014105864628434
            ],
            [
              105.82796653747499,
              21.014129445986715
            ],
            [
              105.8279842942463,
              21.01414453105877
            ],
            [
              105.82806563604123,
              21.01417209544684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4162",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014026877756812,
          "to_latitude": 21.01417209544684,
          "to_longitude": 105.82806563604123,
          "from_longitude": 105.82786661577434
        }
      },
      {
        "id": 4156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786548664974,
            21.013914564535987,
            105.82802285638579,
            21.01399083008595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82786548664974,
              21.01399083008595
            ],
            [
              105.82792495479309,
              21.01395804084156
            ],
            [
              105.82796496161157,
              21.013938744736947
            ],
            [
              105.82802285638579,
              21.013914564535987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4163",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01399083008595,
          "to_latitude": 21.013914564535987,
          "to_longitude": 105.82802285638579,
          "from_longitude": 105.82786548664974
        }
      },
      {
        "id": 4157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786548664974,
            21.01399083008595,
            105.82787885817783,
            21.014015888250608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82786548664974,
              21.01399083008595
            ],
            [
              105.82787885817783,
              21.014015888250608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4164",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01399083008595,
          "to_latitude": 21.014015888250608,
          "to_longitude": 105.82787885817783,
          "from_longitude": 105.82786548664974
        }
      },
      {
        "id": 4158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83069614750836,
            21.014829888042694,
            105.83107072244175,
            21.01530326646763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83069753057782,
              21.01530326646763
            ],
            [
              105.83069614750836,
              21.015294095548633
            ],
            [
              105.83076668216091,
              21.0152772112745
            ],
            [
              105.8307438832332,
              21.015131990944468
            ],
            [
              105.83071303716585,
              21.01494670940564
            ],
            [
              105.83069962630097,
              21.014882861759606
            ],
            [
              105.83079991342139,
              21.014866688461318
            ],
            [
              105.83090938386908,
              21.01485290715655
            ],
            [
              105.83098654716959,
              21.01484248461327
            ],
            [
              105.83107072244175,
              21.014829888042694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_167_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4165",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01530326646763,
          "to_latitude": 21.014829888042694,
          "to_longitude": 105.83107072244175,
          "from_longitude": 105.83069753057782
        }
      },
      {
        "id": 4159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110591176954,
            21.014852503550923,
            105.83119395681855,
            21.01493959801469
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83110591176954,
              21.01493959801469
            ],
            [
              105.83113417205595,
              21.01493849531559
            ],
            [
              105.83117416752624,
              21.014917704583496
            ],
            [
              105.83119395681855,
              21.014906495523206
            ],
            [
              105.83118663253393,
              21.01489362602389
            ],
            [
              105.83117538287755,
              21.014877854670885
            ],
            [
              105.83115861925205,
              21.014852503550923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_226_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4166",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01493959801469,
          "to_latitude": 21.014852503550923,
          "to_longitude": 105.83115861925205,
          "from_longitude": 105.83110591176954
        }
      },
      {
        "id": 4160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82902348911509,
            21.016057166896612,
            105.82915299457852,
            21.01614011257657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915299457852,
              21.016057166896612
            ],
            [
              105.82909854960317,
              21.016101174545863
            ],
            [
              105.82902348911509,
              21.01614011257657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_121_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4167",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016057166896612,
          "to_latitude": 21.01614011257657,
          "to_longitude": 105.82902348911509,
          "from_longitude": 105.82915299457852
        }
      },
      {
        "id": 4161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915508430956,
            21.01618476957001,
            105.82926465507246,
            21.01631824381973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926465507246,
              21.01618476957001
            ],
            [
              105.82915508430956,
              21.01625856966592
            ],
            [
              105.82919681040632,
              21.01631824381973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_121_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4168",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01618476957001,
          "to_latitude": 21.01631824381973,
          "to_longitude": 105.82919681040632,
          "from_longitude": 105.82926465507246
        }
      },
      {
        "id": 4162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83011818612151,
            21.01639340104682,
            105.83028354265959,
            21.016507151851993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83028354265959,
              21.01639340104682
            ],
            [
              105.8302467249585,
              21.016453818628186
            ],
            [
              105.83022625314103,
              21.01647715039118
            ],
            [
              105.83020523226755,
              21.016492875158573
            ],
            [
              105.83018369739244,
              21.016507151851993
            ],
            [
              105.83011818612151,
              21.016481764833753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_157_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4169",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01639340104682,
          "to_latitude": 21.016481764833753,
          "to_longitude": 105.83011818612151,
          "from_longitude": 105.83028354265959
        }
      },
      {
        "id": 4163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83050238484164,
            21.016584894184128,
            105.83080398052331,
            21.016818041849497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83050238484164,
              21.016818041849497
            ],
            [
              105.83050316563663,
              21.016816249651963
            ],
            [
              105.83052637231488,
              21.01680491378666
            ],
            [
              105.8305558767981,
              21.01674857830087
            ],
            [
              105.83056936098362,
              21.016695011733084
            ],
            [
              105.8306329897818,
              21.016584894184128
            ],
            [
              105.83066226947473,
              21.016605747593818
            ],
            [
              105.8307305498765,
              21.016664400206096
            ],
            [
              105.83080398052331,
              21.01671383822514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_153_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4170",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016818041849497,
          "to_latitude": 21.01671383822514,
          "to_longitude": 105.83080398052331,
          "from_longitude": 105.83050238484164
        }
      },
      {
        "id": 4164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091911560288,
            21.015886698172665,
            105.83167703964008,
            21.01622489682864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83092773043397,
              21.01589089702573
            ],
            [
              105.83092491832316,
              21.01588857411695
            ],
            [
              105.83092250308354,
              21.015886698172665
            ],
            [
              105.83091911560288,
              21.01589077652116
            ],
            [
              105.83092571993853,
              21.01589332026298
            ],
            [
              105.8309569505277,
              21.01590535132976
            ],
            [
              105.83098477879687,
              21.01592381642412
            ],
            [
              105.83103641082236,
              21.015963564449024
            ],
            [
              105.83104814563522,
              21.015953862488118
            ],
            [
              105.83105536067605,
              21.01595950762635
            ],
            [
              105.83110078431415,
              21.01591035892646
            ],
            [
              105.83140286732652,
              21.016173882846143
            ],
            [
              105.83145483519023,
              21.01622489682864
            ],
            [
              105.83156438714134,
              21.016126232299754
            ],
            [
              105.83167703964008,
              21.016222628378014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_162_Ngách 103, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4171",
          "diaChiLapD": "Ngách 103, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 107,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01589089702573,
          "to_latitude": 21.016222628378014,
          "to_longitude": 105.83167703964008,
          "from_longitude": 105.83092773043397
        }
      },
      {
        "id": 4165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83058563281489,
            21.01401478623318,
            105.83063914656235,
            21.014415431955396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83063914656235,
              21.01401478623318
            ],
            [
              105.83063579434666,
              21.014046420908205
            ],
            [
              105.83062344644028,
              21.014177643070372
            ],
            [
              105.83061491607214,
              21.01424956520025
            ],
            [
              105.830603613826,
              21.01427309249614
            ],
            [
              105.83058563281489,
              21.014415431955396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_225_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4172",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01401478623318,
          "to_latitude": 21.014415431955396,
          "to_longitude": 105.83058563281489,
          "from_longitude": 105.83063914656235
        }
      },
      {
        "id": 4166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83087698782853,
            21.013996417093683,
            105.83088469941052,
            21.0142619161358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83087726699314,
              21.013996417093683
            ],
            [
              105.8308836934576,
              21.0140656793547
            ],
            [
              105.83088469941052,
              21.01414674072577
            ],
            [
              105.83088067613104,
              21.01421559608211
            ],
            [
              105.83087698782853,
              21.0142619161358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_225_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4173",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013996417093683,
          "to_latitude": 21.0142619161358,
          "to_longitude": 105.83087698782853,
          "from_longitude": 105.83087726699314
        }
      },
      {
        "id": 4167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82852022587366,
            21.01515427939709,
            105.82895480957225,
            21.01524034779056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82852022587366,
              21.015233536304265
            ],
            [
              105.82852133295026,
              21.015234212848302
            ],
            [
              105.82852719549324,
              21.01523778816866
            ],
            [
              105.82857896463273,
              21.01524034779056
            ],
            [
              105.82860746295349,
              21.015237218414764
            ],
            [
              105.82866513039843,
              21.015211867611065
            ],
            [
              105.8286931676925,
              21.015214786659026
            ],
            [
              105.82874157385861,
              21.0152344010604
            ],
            [
              105.82878482443556,
              21.015228142374767
            ],
            [
              105.82884282672748,
              21.015211867297918
            ],
            [
              105.82887970794987,
              21.01520310426409
            ],
            [
              105.82891786186899,
              21.01519818185936
            ],
            [
              105.82895413847724,
              21.015195592592644
            ],
            [
              105.82895480957225,
              21.01515427939709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_143_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4174",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015233536304265,
          "to_latitude": 21.01515427939709,
          "to_longitude": 105.82895480957225,
          "from_longitude": 105.82852022587366
        }
      },
      {
        "id": 4168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83382479754583,
            21.013574634067634,
            105.83390943841844,
            21.013817466004397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384964496486,
              21.013817466004397
            ],
            [
              105.83390943841844,
              21.013763338939906
            ],
            [
              105.83382479754583,
              21.013689417454458
            ],
            [
              105.83388240803087,
              21.013628483556456
            ],
            [
              105.83390030381493,
              21.0135757333182
            ],
            [
              105.83390067766408,
              21.013574634067634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_252_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4175",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013817466004397,
          "to_latitude": 21.013574634067634,
          "to_longitude": 105.83390067766408,
          "from_longitude": 105.83384964496486
        }
      },
      {
        "id": 4169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359731605515,
            21.012184780141574,
            105.83369769306964,
            21.012376267034455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83359731605515,
              21.012184780141574
            ],
            [
              105.83368738125495,
              21.012261837393936
            ],
            [
              105.83363292082653,
              21.01231954030811
            ],
            [
              105.83369769306964,
              21.012376267034455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_275_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4176",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012184780141574,
          "to_latitude": 21.012376267034455,
          "to_longitude": 105.83369769306964,
          "from_longitude": 105.83359731605515
        }
      },
      {
        "id": 4170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440502828041,
            21.011801388046063,
            105.83476375864343,
            21.01202468597788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440502828041,
              21.011801388046063
            ],
            [
              105.83447373061817,
              21.011844739217807
            ],
            [
              105.83456424133973,
              21.011902936286006
            ],
            [
              105.83464610480523,
              21.011955303855625
            ],
            [
              105.83466125067666,
              21.011963701810384
            ],
            [
              105.83469230082441,
              21.011980917607776
            ],
            [
              105.83475922661056,
              21.0120214918279
            ],
            [
              105.83476375864343,
              21.01202468597788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_289_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4177",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011801388046063,
          "to_latitude": 21.01202468597788,
          "to_longitude": 105.83476375864343,
          "from_longitude": 105.83440502828041
        }
      },
      {
        "id": 4171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466125067666,
            21.011959792697617,
            105.8346638193601,
            21.011963701810384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346638193601,
              21.011959792697617
            ],
            [
              105.83466308013884,
              21.011960918129155
            ],
            [
              105.83466220259474,
              21.01196225107773
            ],
            [
              105.83466132794128,
              21.01196358491535
            ],
            [
              105.83466125067666,
              21.011963701810384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_289_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4178",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011959792697617,
          "to_latitude": 21.011963701810384,
          "to_longitude": 105.83466125067666,
          "from_longitude": 105.8346638193601
        }
      },
      {
        "id": 4172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427773337594,
            21.011787675339303,
            105.83428144132951,
            21.01179040386623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83427773337594,
              21.011787675339303
            ],
            [
              105.83428144132951,
              21.01179040386623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_287_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4179",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011787675339303,
          "to_latitude": 21.01179040386623,
          "to_longitude": 105.83428144132951,
          "from_longitude": 105.83427773337594
        }
      },
      {
        "id": 4173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373663470279,
            21.01118840369534,
            105.83418166241846,
            21.011398257512283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83373663470279,
              21.011287954599712
            ],
            [
              105.83375536917663,
              21.011268802042675
            ],
            [
              105.8337783154237,
              21.01123936188536
            ],
            [
              105.8338109225541,
              21.011200482649553
            ],
            [
              105.83382219151272,
              21.01118840369534
            ],
            [
              105.83391221060809,
              21.011241855287864
            ],
            [
              105.83400589531648,
              21.011296120734404
            ],
            [
              105.83410323075341,
              21.01135077106976
            ],
            [
              105.83418166241846,
              21.011398257512283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_281_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4180",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011287954599712,
          "to_latitude": 21.011398257512283,
          "to_longitude": 105.83418166241846,
          "from_longitude": 105.83373663470279
        }
      },
      {
        "id": 4174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83527222778312,
            21.010419217545106,
            105.83548388456045,
            21.010552849304077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527222778312,
              21.010419217545106
            ],
            [
              105.83536194788695,
              21.010482714606272
            ],
            [
              105.83537982485036,
              21.010483576133225
            ],
            [
              105.83541299691227,
              21.01050721777239
            ],
            [
              105.83545131424302,
              21.010531556722864
            ],
            [
              105.83548388456045,
              21.010552849304077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_332_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4181",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010419217545106,
          "to_latitude": 21.010552849304077,
          "to_longitude": 105.83548388456045,
          "from_longitude": 105.83527222778312
        }
      },
      {
        "id": 4175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8349252985255,
            21.010184240390025,
            105.83505994864568,
            21.010363619550663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83505994864568,
              21.010184240390025
            ],
            [
              105.83497555800515,
              21.010283257779676
            ],
            [
              105.8349252985255,
              21.010363619550663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_333_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4182",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010184240390025,
          "to_latitude": 21.010363619550663,
          "to_longitude": 105.8349252985255,
          "from_longitude": 105.83505994864568
        }
      },
      {
        "id": 4176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026410170577,
            21.01806418512264,
            105.83042417550223,
            21.018113359457274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83042417550223,
              21.01806418512264
            ],
            [
              105.83033998862251,
              21.018096046365457
            ],
            [
              105.83026410170577,
              21.018113359457274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4183",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01806418512264,
          "to_latitude": 21.018113359457274,
          "to_longitude": 105.83026410170577,
          "from_longitude": 105.83042417550223
        }
      },
      {
        "id": 4177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83002266259219,
            21.016870989781996,
            105.83013644690627,
            21.017190533508455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83007974283197,
              21.017190533508455
            ],
            [
              105.83013644690627,
              21.017043876529097
            ],
            [
              105.83002266259219,
              21.016976696158036
            ],
            [
              105.83006147033173,
              21.016922630327947
            ],
            [
              105.830094327405,
              21.016870989781996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_96_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4184",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017190533508455,
          "to_latitude": 21.016870989781996,
          "to_longitude": 105.830094327405,
          "from_longitude": 105.83007974283197
        }
      },
      {
        "id": 4178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83014055385408,
            21.017289001738572,
            105.83026091818081,
            21.01734627584915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026091818081,
              21.01734627584915
            ],
            [
              105.83025112982017,
              21.017343051236363
            ],
            [
              105.83021532043158,
              21.01733125284602
            ],
            [
              105.83018078705103,
              21.017325619958523
            ],
            [
              105.83015664728207,
              21.01730152138983
            ],
            [
              105.83014055385408,
              21.017289001738572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4185",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01734627584915,
          "to_latitude": 21.017289001738572,
          "to_longitude": 105.83014055385408,
          "from_longitude": 105.83026091818081
        }
      },
      {
        "id": 4179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82933118464146,
            21.016595481465984,
            105.82955504847216,
            21.01667866725064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82933118464146,
              21.016595481465984
            ],
            [
              105.82936191117489,
              21.016609943151188
            ],
            [
              105.82945634956381,
              21.016644650712056
            ],
            [
              105.82953928028425,
              21.016673276013126
            ],
            [
              105.82955504847216,
              21.01667866725064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4186",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016595481465984,
          "to_latitude": 21.01667866725064,
          "to_longitude": 105.82955504847216,
          "from_longitude": 105.82933118464146
        }
      },
      {
        "id": 4180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82986213981235,
            21.016356725058795,
            105.82988149682782,
            21.01636521759758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82986213981235,
              21.016356725058795
            ],
            [
              105.82986474819714,
              21.016357869366754
            ],
            [
              105.82986812994271,
              21.016359354031046
            ],
            [
              105.82987133520798,
              21.01636075826588
            ],
            [
              105.82988149682782,
              21.01636521759758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_124_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4187",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016356725058795,
          "to_latitude": 21.01636521759758,
          "to_longitude": 105.82988149682782,
          "from_longitude": 105.82986213981235
        }
      },
      {
        "id": 4181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82991017078905,
            21.01431131293982,
            105.8299801229255,
            21.014388448584672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82991017078905,
              21.01431131293982
            ],
            [
              105.8299243898408,
              21.014327101510812
            ],
            [
              105.82995941176165,
              21.0143690020124
            ],
            [
              105.8299801229255,
              21.014388448584672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_218_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4188",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01431131293982,
          "to_latitude": 21.014388448584672,
          "to_longitude": 105.8299801229255,
          "from_longitude": 105.82991017078905
        }
      },
      {
        "id": 4182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82958323423688,
            21.014786563313535,
            105.829588433777,
            21.014789118601495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829588433777,
              21.014786563313535
            ],
            [
              105.82958677030734,
              21.014787380714797
            ],
            [
              105.82958449095226,
              21.014788500990008
            ],
            [
              105.82958323423688,
              21.014789118601495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_213_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4189",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014786563313535,
          "to_latitude": 21.014789118601495,
          "to_longitude": 105.82958323423688,
          "from_longitude": 105.829588433777
        }
      },
      {
        "id": 4183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950926162248,
            21.014634298807948,
            105.82958629088387,
            21.01480261775739
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950926162248,
              21.014634298807948
            ],
            [
              105.82951311578968,
              21.014643291497936
            ],
            [
              105.82952353317177,
              21.01466514844226
            ],
            [
              105.82954540942708,
              21.014714375958516
            ],
            [
              105.82956754443497,
              21.014764817047936
            ],
            [
              105.82958629088387,
              21.01480261775739
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_212_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4190",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014634298807948,
          "to_latitude": 21.01480261775739,
          "to_longitude": 105.82958629088387,
          "from_longitude": 105.82950926162248
        }
      },
      {
        "id": 4184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82965199052641,
            21.015372472420633,
            105.82972690438949,
            21.015474770900394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82972690438949,
              21.015474770900394
            ],
            [
              105.82971193528587,
              21.015424961473276
            ],
            [
              105.82969487839878,
              21.015372472420633
            ],
            [
              105.82965199052641,
              21.015386740275474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4191",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015474770900394,
          "to_latitude": 21.015386740275474,
          "to_longitude": 105.82965199052641,
          "from_longitude": 105.82972690438949
        }
      },
      {
        "id": 4185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885016053409,
            21.01501110383773,
            105.82901407188128,
            21.01503391040657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82901407188128,
              21.01503391040657
            ],
            [
              105.8290074044586,
              21.015017676705284
            ],
            [
              105.82900371663195,
              21.01501110383773
            ],
            [
              105.82896448894176,
              21.01501736375006
            ],
            [
              105.82891654496935,
              21.01502393618534
            ],
            [
              105.82885016053409,
              21.015029256252827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_147_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4192",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01503391040657,
          "to_latitude": 21.015029256252827,
          "to_longitude": 105.82885016053409,
          "from_longitude": 105.82901407188128
        }
      },
      {
        "id": 4186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100548225707,
            21.016696312522892,
            105.83112249280897,
            21.016879087989864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310644902875,
              21.016879087989864
            ],
            [
              105.83101956330908,
              21.016844660648594
            ],
            [
              105.83100548225707,
              21.01683214208764
            ],
            [
              105.83108226030625,
              21.016745761935173
            ],
            [
              105.83112249280897,
              21.016696312522892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_176_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4193",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016879087989864,
          "to_latitude": 21.016696312522892,
          "to_longitude": 105.83112249280897,
          "from_longitude": 105.8310644902875
        }
      },
      {
        "id": 4187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83057312793501,
            21.01698284306624,
            105.8306994908398,
            21.017016087377097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83057312793501,
              21.01698284306624
            ],
            [
              105.8305735310245,
              21.016982849227272
            ],
            [
              105.83057942044915,
              21.01698291982811
            ],
            [
              105.83059084542765,
              21.017016087377097
            ],
            [
              105.8306994908398,
              21.016998255616393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_150_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4194",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01698284306624,
          "to_latitude": 21.016998255616393,
          "to_longitude": 105.8306994908398,
          "from_longitude": 105.83057312793501
        }
      },
      {
        "id": 4188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83041673652208,
            21.016702571642075,
            105.83051530842117,
            21.016846852156764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83051530842117,
              21.016846852156764
            ],
            [
              105.83050927313074,
              21.016837462788512
            ],
            [
              105.83047038105597,
              21.016783318237373
            ],
            [
              105.8304247836225,
              21.016714464869413
            ],
            [
              105.83041673652208,
              21.016702571642075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4195",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016846852156764,
          "to_latitude": 21.016702571642075,
          "to_longitude": 105.83041673652208,
          "from_longitude": 105.83051530842117
        }
      },
      {
        "id": 4189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306329897818,
            21.016509155091438,
            105.8306775815747,
            21.016584894184128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8306329897818,
              21.016584894184128
            ],
            [
              105.83064472499284,
              21.01656517638388
            ],
            [
              105.8306775815747,
              21.016509155091438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_153_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4196",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016584894184128,
          "to_latitude": 21.016509155091438,
          "to_longitude": 105.8306775815747,
          "from_longitude": 105.8306329897818
        }
      },
      {
        "id": 4190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83105536067605,
            21.01595950762635,
            105.83123824677685,
            21.01609563881318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83105536067605,
              21.01595950762635
            ],
            [
              105.83112928248562,
              21.01601551769216
            ],
            [
              105.83123824677685,
              21.01609563881318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_162_Ngách 103, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4197",
          "diaChiLapD": "Ngách 103, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01595950762635,
          "to_latitude": 21.01609563881318,
          "to_longitude": 105.83123824677685,
          "from_longitude": 105.83105536067605
        }
      },
      {
        "id": 4191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83148984800675,
            21.015433144442405,
            105.83181712183692,
            21.01569739942204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83148984800675,
              21.01569739942204
            ],
            [
              105.83150182819999,
              21.015686681006656
            ],
            [
              105.83150751868972,
              21.015681588811162
            ],
            [
              105.8315151806499,
              21.015683173555257
            ],
            [
              105.83155142061335,
              21.015658348316318
            ],
            [
              105.83162144342955,
              21.015600144543363
            ],
            [
              105.83167677467786,
              21.01557076583045
            ],
            [
              105.83175102146176,
              21.01549854588461
            ],
            [
              105.83181712183692,
              21.015433144442405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_187_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4198",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01569739942204,
          "to_latitude": 21.015433144442405,
          "to_longitude": 105.83181712183692,
          "from_longitude": 105.83148984800675
        }
      },
      {
        "id": 4192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83193006204527,
            21.01591266376694,
            105.83197951489463,
            21.01595731356181
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193006204527,
              21.01595731356181
            ],
            [
              105.83193187966594,
              21.015955672545942
            ],
            [
              105.83197951489463,
              21.01591266376694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_191_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4199",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01595731356181,
          "to_latitude": 21.01591266376694,
          "to_longitude": 105.83197951489463,
          "from_longitude": 105.83193006204527
        }
      },
      {
        "id": 4193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154373335398,
            21.013309966716022,
            105.83190370391073,
            21.0138284787423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190370391073,
              21.013309966716022
            ],
            [
              105.83187649391775,
              21.0133411725108
            ],
            [
              105.83181651635243,
              21.013407307256404
            ],
            [
              105.83173254483529,
              21.013504901194
            ],
            [
              105.83168254697955,
              21.01356841111066
            ],
            [
              105.83163095585735,
              21.01367056426295
            ],
            [
              105.83165211972185,
              21.013687148805776
            ],
            [
              105.83154373335398,
              21.0138284787423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_235_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4200",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013309966716022,
          "to_latitude": 21.0138284787423,
          "to_longitude": 105.83154373335398,
          "from_longitude": 105.83190370391073
        }
      },
      {
        "id": 4194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288858776699,
            21.01344313768022,
            105.83315359528486,
            21.013708180476186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83288858776699,
              21.01344313768022
            ],
            [
              105.83290447854588,
              21.013450207110775
            ],
            [
              105.83290519117293,
              21.013450525170374
            ],
            [
              105.8329222550069,
              21.013454040988577
            ],
            [
              105.832980593061,
              21.013497232128454
            ],
            [
              105.83315359528486,
              21.013636195265082
            ],
            [
              105.8330892229365,
              21.013708180476186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_238_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.4201",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01344313768022,
          "to_latitude": 21.013708180476186,
          "to_longitude": 105.8330892229365,
          "from_longitude": 105.83288858776699
        }
      },
      {
        "id": 4195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489503073524,
            21.011583014340374,
            105.83492501194523,
            21.011597601538522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489503073524,
              21.011583014340374
            ],
            [
              105.83492382076581,
              21.01159702299692
            ],
            [
              105.83492501194523,
              21.011597601538522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_304_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4202",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011583014340374,
          "to_latitude": 21.011597601538522,
          "to_longitude": 105.83492501194523,
          "from_longitude": 105.83489503073524
        }
      },
      {
        "id": 4196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463192361087,
            21.01116085606688,
            105.83468702948424,
            21.01119026483278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83468702948424,
              21.01119026483278
            ],
            [
              105.83467749849667,
              21.011185178557678
            ],
            [
              105.83463192361087,
              21.01116085606688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_307_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4203",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01119026483278,
          "to_latitude": 21.01116085606688,
          "to_longitude": 105.83463192361087,
          "from_longitude": 105.83468702948424
        }
      },
      {
        "id": 4197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83478174796322,
            21.01067962292537,
            105.83482898700234,
            21.010767465702354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83482898700234,
              21.01067962292537
            ],
            [
              105.83482433269734,
              21.0106876383983
            ],
            [
              105.83478174796322,
              21.010767465702354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_327_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4204",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01067962292537,
          "to_latitude": 21.010767465702354,
          "to_longitude": 105.83478174796322,
          "from_longitude": 105.83482898700234
        }
      },
      {
        "id": 4198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83536679340367,
            21.010718232753554,
            105.83559217719898,
            21.010956668049488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559217719898,
              21.010718232753554
            ],
            [
              105.83558354382434,
              21.01073320906915
            ],
            [
              105.83554369487014,
              21.010788459916515
            ],
            [
              105.83550738005668,
              21.010822833422495
            ],
            [
              105.83546369985162,
              21.010865141786276
            ],
            [
              105.83538546247561,
              21.010939635509317
            ],
            [
              105.83537387841646,
              21.010950205918167
            ],
            [
              105.83536679340367,
              21.010956668049488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_328_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4205",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010718232753554,
          "to_latitude": 21.010956668049488,
          "to_longitude": 105.83536679340367,
          "from_longitude": 105.83559217719898
        }
      },
      {
        "id": 4199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509386855877,
            21.010469287107522,
            105.83510918655873,
            21.010478563020587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509386855877,
              21.010469287107522
            ],
            [
              105.835095097211,
              21.01047003256098
            ],
            [
              105.83510918655873,
              21.010478563020587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_335_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4206",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010469287107522,
          "to_latitude": 21.010478563020587,
          "to_longitude": 105.83510918655873,
          "from_longitude": 105.83509386855877
        }
      },
      {
        "id": 4200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388422349007,
            21.011461795481093,
            105.8341814753055,
            21.011654017558385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388422349007,
              21.011654017558385
            ],
            [
              105.8338934267831,
              21.011624721509943
            ],
            [
              105.83392592622445,
              21.01154482823078
            ],
            [
              105.83394262026026,
              21.011514558823325
            ],
            [
              105.833967262297,
              21.011492203357005
            ],
            [
              105.83400078127895,
              21.011461795481093
            ],
            [
              105.83407756433957,
              21.011504278957894
            ],
            [
              105.8341814753055,
              21.011561132492236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_280_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4207",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011654017558385,
          "to_latitude": 21.011561132492236,
          "to_longitude": 105.8341814753055,
          "from_longitude": 105.83388422349007
        }
      },
      {
        "id": 4201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83393388173427,
            21.011479506838988,
            105.833967262297,
            21.011492203357005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833967262297,
              21.011492203357005
            ],
            [
              105.83393571227785,
              21.01148020418954
            ],
            [
              105.83393388173427,
              21.011479506838988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_280_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4208",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011492203357005,
          "to_latitude": 21.011479506838988,
          "to_longitude": 105.83393388173427,
          "from_longitude": 105.833967262297
        }
      },
      {
        "id": 4202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83383945135029,
            21.01270937003992,
            105.83391273974867,
            21.012743435476118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83391273974867,
              21.012743435476118
            ],
            [
              105.83388103959463,
              21.012728701174893
            ],
            [
              105.83383945135029,
              21.01270937003992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_273_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4209",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012743435476118,
          "to_latitude": 21.01270937003992,
          "to_longitude": 105.83383945135029,
          "from_longitude": 105.83391273974867
        }
      },
      {
        "id": 4203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82771142960556,
            21.01463819362422,
            105.82786305796344,
            21.01486513472526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82771142960556,
              21.01463819362422
            ],
            [
              105.82778262672107,
              21.01474116723845
            ],
            [
              105.82783573096222,
              21.014815036597433
            ],
            [
              105.82786305796344,
              21.01486513472526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_198_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4210",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01463819362422,
          "to_latitude": 21.01486513472526,
          "to_longitude": 105.82786305796344,
          "from_longitude": 105.82771142960556
        }
      },
      {
        "id": 4204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270090227694,
            21.013188840896508,
            105.82718363903264,
            21.0133451000837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82706228585631,
              21.0133451000837
            ],
            [
              105.82703796246068,
              21.013301269140808
            ],
            [
              105.82701797552821,
              21.01326373789211
            ],
            [
              105.8270090227694,
              21.01324626333772
            ],
            [
              105.82711453209272,
              21.013188840896508
            ],
            [
              105.82713563865079,
              21.013208042039672
            ],
            [
              105.82718363903264,
              21.013190679002765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_203_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4211",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0133451000837,
          "to_latitude": 21.013190679002765,
          "to_longitude": 105.82718363903264,
          "from_longitude": 105.82706228585631
        }
      },
      {
        "id": 4205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367047951149,
            21.011946404389963,
            105.83691302304905,
            21.012196053549577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670757340377,
              21.012196053549577
            ],
            [
              105.83670704891725,
              21.01219549251657
            ],
            [
              105.83670667153437,
              21.01219509153417
            ],
            [
              105.8367047951149,
              21.012193071215687
            ],
            [
              105.83674909365334,
              21.012148141953066
            ],
            [
              105.83678748345834,
              21.0121032715444
            ],
            [
              105.83679289457545,
              21.01209712648985
            ],
            [
              105.83682517834634,
              21.012063504151065
            ],
            [
              105.83686520368235,
              21.012018691617396
            ],
            [
              105.8369007532383,
              21.011965089230237
            ],
            [
              105.83690923765995,
              21.011952219903957
            ],
            [
              105.83691302304905,
              21.011946404389963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_297_Kim Hoa",
          "maTaiSan": "04.O16B.DODV.4212",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012196053549577,
          "to_latitude": 21.011946404389963,
          "to_longitude": 105.83691302304905,
          "from_longitude": 105.83670757340377
        }
      },
      {
        "id": 4206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83096181199537,
            21.015517344662765,
            105.83106164047575,
            21.015807389975297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83096181199537,
              21.015807389975297
            ],
            [
              105.83097589394193,
              21.015799252683127
            ],
            [
              105.83106164047575,
              21.015702778551745
            ],
            [
              105.83103027968266,
              21.015599006653296
            ],
            [
              105.83096769480072,
              21.015517344662765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4213",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015807389975297,
          "to_latitude": 21.015517344662765,
          "to_longitude": 105.83096769480072,
          "from_longitude": 105.83096181199537
        }
      },
      {
        "id": 4207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297281662995,
            21.016704259021623,
            105.82981567013536,
            21.016955769808067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8297281662995,
              21.016955769808067
            ],
            [
              105.82976629845014,
              21.016841990598138
            ],
            [
              105.82980180969486,
              21.01674689654015
            ],
            [
              105.82981567013536,
              21.016704259021623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101_Ngõ 71, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4214",
          "diaChiLapD": "Ngõ 71, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016955769808067,
          "to_latitude": 21.016704259021623,
          "to_longitude": 105.82981567013536,
          "from_longitude": 105.8297281662995
        }
      },
      {
        "id": 4208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884101725041,
            21.01614527572895,
            105.82895187158066,
            21.01628821930828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884101725041,
              21.01614527572895
            ],
            [
              105.82889947679297,
              21.016217074624933
            ],
            [
              105.82892639015715,
              21.01625679018853
            ],
            [
              105.82895187158066,
              21.01628821930828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_130_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4215",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01614527572895,
          "to_latitude": 21.01628821930828,
          "to_longitude": 105.82895187158066,
          "from_longitude": 105.82884101725041
        }
      },
      {
        "id": 4209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807297024443,
            21.014036104346005,
            105.8284409037912,
            21.014195006203657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807297024443,
              21.014195006203657
            ],
            [
              105.82808769572314,
              21.014187563307203
            ],
            [
              105.82814514773807,
              21.014160184185744
            ],
            [
              105.82822608211855,
              21.014129109445793
            ],
            [
              105.82832983949113,
              21.014087815554817
            ],
            [
              105.8284409037912,
              21.014036104346005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4216",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014195006203657,
          "to_latitude": 21.014036104346005,
          "to_longitude": 105.8284409037912,
          "from_longitude": 105.82807297024443
        }
      },
      {
        "id": 4210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807297024443,
            21.01418435137963,
            105.82816070572399,
            21.014383509621887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82816070572399,
              21.014383509621887
            ],
            [
              105.82811750670068,
              21.014319439117084
            ],
            [
              105.82807297024443,
              21.014195006203657
            ],
            [
              105.82807524087974,
              21.01418882070047
            ],
            [
              105.82807694383158,
              21.01418435137963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_201_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4217",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014383509621887,
          "to_latitude": 21.01418435137963,
          "to_longitude": 105.82807694383158,
          "from_longitude": 105.82816070572399
        }
      },
      {
        "id": 4211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82834499273704,
            21.013729613691254,
            105.82847020055512,
            21.013817806420157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82834499273704,
              21.013817806420157
            ],
            [
              105.8284203268766,
              21.013764842083354
            ],
            [
              105.82847020055512,
              21.013729613691254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_340_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4218",
          "diaChiLapD": "Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013817806420157,
          "to_latitude": 21.013729613691254,
          "to_longitude": 105.82847020055512,
          "from_longitude": 105.82834499273704
        }
      },
      {
        "id": 4212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089073443927,
            21.014216534186733,
            105.8311301206826,
            21.01446970318834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83106585792656,
              21.014465969820854
            ],
            [
              105.83106988159562,
              21.01446126694319
            ],
            [
              105.83107801177732,
              21.014469591998846
            ],
            [
              105.83108908709146,
              21.01446970318834
            ],
            [
              105.83109766067926,
              21.01444138674272
            ],
            [
              105.83110679903734,
              21.014415349084924
            ],
            [
              105.83111838682353,
              21.014256908308077
            ],
            [
              105.8311301206826,
              21.014218412428274
            ],
            [
              105.83089073443927,
              21.014216534186733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_231_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4219",
          "diaChiLapD": "Ngách 150, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014465969820854,
          "to_latitude": 21.014216534186733,
          "to_longitude": 105.83089073443927,
          "from_longitude": 105.83106585792656
        }
      },
      {
        "id": 4213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83517725237931,
            21.010826683227172,
            105.83533910573337,
            21.0109597502945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83517725237931,
              21.010826683227172
            ],
            [
              105.83518079190081,
              21.01082870443332
            ],
            [
              105.83521017631132,
              21.010845485738958
            ],
            [
              105.83524152311516,
              21.0108702328832
            ],
            [
              105.83528997327714,
              21.010906846633613
            ],
            [
              105.8352936899395,
              21.010909412515588
            ],
            [
              105.83533910573337,
              21.0109597502945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_324_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4220",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010826683227172,
          "to_latitude": 21.0109597502945,
          "to_longitude": 105.83533910573337,
          "from_longitude": 105.83517725237931
        }
      },
      {
        "id": 4214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498375270476,
            21.01070572637396,
            105.83516578520921,
            21.010819718741946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83498375270476,
              21.01070572637396
            ],
            [
              105.83498616237891,
              21.010707473128733
            ],
            [
              105.83500969703323,
              21.01072453427196
            ],
            [
              105.83505941703511,
              21.010757559669887
            ],
            [
              105.83510934061135,
              21.010787753346314
            ],
            [
              105.83516578520921,
              21.010819718741946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_326_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4221",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01070572637396,
          "to_latitude": 21.010819718741946,
          "to_longitude": 105.83516578520921,
          "from_longitude": 105.83498375270476
        }
      },
      {
        "id": 4215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82683478902429,
            21.01311785789982,
            105.82704174840616,
            21.01324544187441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82683478902429,
              21.01324544187441
            ],
            [
              105.82685705522573,
              21.013228269016484
            ],
            [
              105.82685878291349,
              21.0132272354587
            ],
            [
              105.82696977986289,
              21.013160879724495
            ],
            [
              105.82704174840616,
              21.01311785789982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_206_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4222",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01324544187441,
          "to_latitude": 21.01311785789982,
          "to_longitude": 105.82704174840616,
          "from_longitude": 105.82683478902429
        }
      },
      {
        "id": 4216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268595960316,
            21.012471962799317,
            105.82690302232281,
            21.012574196629437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82686082227444,
              21.012574196629437
            ],
            [
              105.82690302232281,
              21.01254465385714
            ],
            [
              105.8268595960316,
              21.012471962799317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4223",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012574196629437,
          "to_latitude": 21.012471962799317,
          "to_longitude": 105.8268595960316,
          "from_longitude": 105.82686082227444
        }
      },
      {
        "id": 4217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82667730189269,
            21.012192811400794,
            105.826788956537,
            21.012286583779414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82667730189269,
              21.012192811400794
            ],
            [
              105.82671353390532,
              21.012244044294786
            ],
            [
              105.8267535895591,
              21.01224483238191
            ],
            [
              105.826788956537,
              21.012286583779414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77_Ngõ 61, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4224",
          "diaChiLapD": "Ngõ 61, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012192811400794,
          "to_latitude": 21.012286583779414,
          "to_longitude": 105.826788956537,
          "from_longitude": 105.82667730189269
        }
      },
      {
        "id": 4218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82615328026932,
            21.011997333062784,
            105.82641240036922,
            21.01213245224208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82615328026932,
              21.01213245224208
            ],
            [
              105.82617021732806,
              21.012121558385516
            ],
            [
              105.82617651643159,
              21.012118326765922
            ],
            [
              105.82641240036922,
              21.011997333062784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4225",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01213245224208,
          "to_latitude": 21.011997333062784,
          "to_longitude": 105.82641240036922,
          "from_longitude": 105.82615328026932
        }
      },
      {
        "id": 4219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82598937107598,
            21.01171838179931,
            105.82631135770767,
            21.011871136212594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82598937107598,
              21.011871136212594
            ],
            [
              105.82600820277355,
              21.011863155112575
            ],
            [
              105.82602239390303,
              21.01185547021613
            ],
            [
              105.82631135770767,
              21.01171838179931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4226",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011871136212594,
          "to_latitude": 21.01171838179931,
          "to_longitude": 105.82631135770767,
          "from_longitude": 105.82598937107598
        }
      },
      {
        "id": 4220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82584724082923,
            21.011444426449682,
            105.8261395741749,
            21.01165554943016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82584724082923,
              21.01165554943016
            ],
            [
              105.82586498892138,
              21.01164748237973
            ],
            [
              105.82587971012813,
              21.01163788911469
            ],
            [
              105.82588542263333,
              21.01163416628838
            ],
            [
              105.82586674392377,
              21.01160196118226
            ],
            [
              105.82598361448248,
              21.011541358242038
            ],
            [
              105.82598681228556,
              21.011521689414113
            ],
            [
              105.8261395741749,
              21.011444426449682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4227",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01165554943016,
          "to_latitude": 21.011444426449682,
          "to_longitude": 105.8261395741749,
          "from_longitude": 105.82584724082923
        }
      },
      {
        "id": 4221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82581980625794,
            21.010865840090514,
            105.82586812037538,
            21.010951560051097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82586812037538,
              21.010951560051097
            ],
            [
              105.82585089762769,
              21.01093255214081
            ],
            [
              105.82582630747181,
              21.010920330699904
            ],
            [
              105.82582104719751,
              21.01091044241436
            ],
            [
              105.82581980625794,
              21.010898195265266
            ],
            [
              105.82583037171266,
              21.010865840090514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_68_Ngõ 119, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4228",
          "diaChiLapD": "Ngõ 119, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010951560051097,
          "to_latitude": 21.010865840090514,
          "to_longitude": 105.82583037171266,
          "from_longitude": 105.82586812037538
        }
      },
      {
        "id": 4222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613440636064,
            21.01092214927534,
            105.82617324787714,
            21.0109833990029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82617324787714,
              21.01092214927534
            ],
            [
              105.82613440636064,
              21.0109519058802
            ],
            [
              105.8261466180577,
              21.0109833990029
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_68_Ngõ 119, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4229",
          "diaChiLapD": "Ngõ 119, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01092214927534,
          "to_latitude": 21.0109833990029,
          "to_longitude": 105.8261466180577,
          "from_longitude": 105.82617324787714
        }
      },
      {
        "id": 4223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82611478413753,
            21.010563678035833,
            105.82650572466437,
            21.0108325431162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82611478413753,
              21.0108325431162
            ],
            [
              105.82623168042764,
              21.01077726260886
            ],
            [
              105.82625799766102,
              21.010746073764714
            ],
            [
              105.82624917972679,
              21.01069854002866
            ],
            [
              105.82650572466437,
              21.010563678035833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_68_Ngõ 119, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4230",
          "diaChiLapD": "Ngõ 119, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0108325431162,
          "to_latitude": 21.010563678035833,
          "to_longitude": 105.82650572466437,
          "from_longitude": 105.82611478413753
        }
      },
      {
        "id": 4224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82585135780575,
            21.01126674011531,
            105.8259816267431,
            21.01132093800326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82585135780575,
              21.01126674011531
            ],
            [
              105.82589047844135,
              21.011306883820982
            ],
            [
              105.82592342044444,
              21.01132093800326
            ],
            [
              105.82595534445215,
              21.01130890380996
            ],
            [
              105.82597504929846,
              21.01129739228046
            ],
            [
              105.8259816267431,
              21.011311526556643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 119, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4231",
          "diaChiLapD": "Ngõ 119, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01126674011531,
          "to_latitude": 21.011311526556643,
          "to_longitude": 105.8259816267431,
          "from_longitude": 105.82585135780575
        }
      },
      {
        "id": 4225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82712636419875,
            21.010826881461718,
            105.8271318650563,
            21.01082937671832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8271318650563,
              21.010826881461718
            ],
            [
              105.82712636419875,
              21.01082937671832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.4_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4232",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010826881461718,
          "to_latitude": 21.01082937671832,
          "to_longitude": 105.82712636419875,
          "from_longitude": 105.8271318650563
        }
      },
      {
        "id": 4226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82704724733364,
            21.010685590991578,
            105.82717550212713,
            21.01091868010521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82704724733364,
              21.010685590991578
            ],
            [
              105.82712636419875,
              21.01082937671832
            ],
            [
              105.82717550212713,
              21.01091868010521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.4_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4233",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010685590991578,
          "to_latitude": 21.01091868010521,
          "to_longitude": 105.82717550212713,
          "from_longitude": 105.82704724733364
        }
      },
      {
        "id": 4227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82724141939599,
            21.010661642861415,
            105.82724336630582,
            21.010665308624507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724141939599,
              21.010661642861415
            ],
            [
              105.8272418257049,
              21.010662410435
            ],
            [
              105.82724336630582,
              21.010665308624507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.3_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4234",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010661642861415,
          "to_latitude": 21.010665308624507,
          "to_longitude": 105.82724336630582,
          "from_longitude": 105.82724141939599
        }
      },
      {
        "id": 4228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82688710262592,
            21.00992011350727,
            105.82726192474306,
            21.010628393863094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82688710262592,
              21.00992011350727
            ],
            [
              105.82692097850773,
              21.009971883510836
            ],
            [
              105.82701242568132,
              21.010148164387363
            ],
            [
              105.82711214137397,
              21.010346738190837
            ],
            [
              105.82712037961178,
              21.010362356480087
            ],
            [
              105.82716604109962,
              21.010448179484445
            ],
            [
              105.82726192474306,
              21.010628393863094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.1_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4235",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00992011350727,
          "to_latitude": 21.010628393863094,
          "to_longitude": 105.82726192474306,
          "from_longitude": 105.82688710262592
        }
      },
      {
        "id": 4229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82714791634105,
            21.010448179484445,
            105.82716604109962,
            21.010458025142977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82714791634105,
              21.010458025142977
            ],
            [
              105.82715340200647,
              21.010455044927248
            ],
            [
              105.82716154227528,
              21.010450622903203
            ],
            [
              105.82716604109962,
              21.010448179484445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.1_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4236",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010458025142977,
          "to_latitude": 21.010448179484445,
          "to_longitude": 105.82716604109962,
          "from_longitude": 105.82714791634105
        }
      },
      {
        "id": 4230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8272275605438,
            21.010634479767546,
            105.82724692898555,
            21.010659511559037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8272275605438,
              21.010634479767546
            ],
            [
              105.82724658797345,
              21.010659072442426
            ],
            [
              105.82724692898555,
              21.010659511559037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.2_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4237",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010634479767546,
          "to_latitude": 21.010659511559037,
          "to_longitude": 105.82724692898555,
          "from_longitude": 105.8272275605438
        }
      },
      {
        "id": 4231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82709576200521,
            21.010562685429523,
            105.8272275605438,
            21.010698671451983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82709576200521,
              21.010698671451983
            ],
            [
              105.8272275605438,
              21.010634479767546
            ],
            [
              105.8271925447598,
              21.010562685429523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.2_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4238",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010698671451983,
          "to_latitude": 21.010562685429523,
          "to_longitude": 105.8271925447598,
          "from_longitude": 105.82709576200521
        }
      },
      {
        "id": 4232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82709463303486,
            21.0105967974952,
            105.82746361385853,
            21.01074081236833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746361385853,
              21.01074081236833
            ],
            [
              105.82738911838004,
              21.0105967974952
            ],
            [
              105.82734415905276,
              21.01061793030357
            ],
            [
              105.82724336630582,
              21.010665308624507
            ],
            [
              105.82722626752731,
              21.010673345560903
            ],
            [
              105.82709463303486,
              21.010735195284916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.3_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4239",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01074081236833,
          "to_latitude": 21.010735195284916,
          "to_longitude": 105.82709463303486,
          "from_longitude": 105.82746361385853
        }
      },
      {
        "id": 4233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82685637895887,
            21.009932059840686,
            105.82707200687959,
            21.01032440138108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82685637895887,
              21.009932059840686
            ],
            [
              105.8269140497697,
              21.01003695490642
            ],
            [
              105.82694089722453,
              21.01008578654032
            ],
            [
              105.82707200687959,
              21.01032440138108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4240",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009932059840686,
          "to_latitude": 21.01032440138108,
          "to_longitude": 105.82707200687959,
          "from_longitude": 105.82685637895887
        }
      },
      {
        "id": 4234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8269140497697,
            21.010024086942394,
            105.82694019452909,
            21.01003695490642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269140497697,
              21.01003695490642
            ],
            [
              105.82693076556968,
              21.01002872747628
            ],
            [
              105.8269384390096,
              21.010024950828985
            ],
            [
              105.82694019452909,
              21.010024086942394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4241",
          "diaChiLapD": "Ngách 167/37, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01003695490642,
          "to_latitude": 21.010024086942394,
          "to_longitude": 105.82694019452909,
          "from_longitude": 105.8269140497697
        }
      },
      {
        "id": 4235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82652021982437,
            21.01001892320397,
            105.82680807233457,
            21.010558889858718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82652021982437,
              21.01001892320397
            ],
            [
              105.82652247853053,
              21.010024190689
            ],
            [
              105.8265437123727,
              21.010074975334458
            ],
            [
              105.82680807233457,
              21.010558889858718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngách 167/31, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4242",
          "diaChiLapD": "Ngách 167/31, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01001892320397,
          "to_latitude": 21.010558889858718,
          "to_longitude": 105.82680807233457,
          "from_longitude": 105.82652021982437
        }
      },
      {
        "id": 4236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82648951842457,
            21.010031778010138,
            105.82678552226093,
            21.01056969001475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82648951842457,
              21.010031778010138
            ],
            [
              105.82649277242172,
              21.010037693694272
            ],
            [
              105.82678552226093,
              21.01056969001475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Ngách 167/31, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4243",
          "diaChiLapD": "Ngách 167/31, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010031778010138,
          "to_latitude": 21.01056969001475,
          "to_longitude": 105.82678552226093,
          "from_longitude": 105.82648951842457
        }
      },
      {
        "id": 4237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82614513145636,
            21.010127055830996,
            105.82643069291231,
            21.010541879287306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82627896619327,
              21.010127055830996
            ],
            [
              105.8262826348654,
              21.010133688474053
            ],
            [
              105.82643069291231,
              21.010401230463163
            ],
            [
              105.82614513145636,
              21.010541879287306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Ngách 167/15, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4244",
          "diaChiLapD": "Ngách 167/15, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010127055830996,
          "to_latitude": 21.010541879287306,
          "to_longitude": 105.82614513145636,
          "from_longitude": 105.82627896619327
        }
      },
      {
        "id": 4238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82630184277264,
            21.010116511576516,
            105.82649889746251,
            21.01049180985165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82630184277264,
              21.010116511576516
            ],
            [
              105.82630529028643,
              21.01012193587261
            ],
            [
              105.8263251130449,
              21.010169592299174
            ],
            [
              105.82641441803413,
              21.010354527789953
            ],
            [
              105.82644760952743,
              21.010411809989325
            ],
            [
              105.82646433310255,
              21.010433270717826
            ],
            [
              105.82649889746251,
              21.01049180985165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Ngách 167/15, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4245",
          "diaChiLapD": "Ngách 167/15, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010116511576516,
          "to_latitude": 21.01049180985165,
          "to_longitude": 105.82649889746251,
          "from_longitude": 105.82630184277264
        }
      },
      {
        "id": 4239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82572695323229,
            21.01036284010508,
            105.82612433410794,
            21.010659690654137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82572695323229,
              21.01036284010508
            ],
            [
              105.82573087579034,
              21.01036929088306
            ],
            [
              105.82590754387229,
              21.010659690654137
            ],
            [
              105.82612433410794,
              21.01056249524264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngách 167/15, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4246",
          "diaChiLapD": "Ngách 167/15, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01036284010508,
          "to_latitude": 21.01056249524264,
          "to_longitude": 105.82612433410794,
          "from_longitude": 105.82572695323229
        }
      },
      {
        "id": 4240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82546782139711,
            21.010466244165357,
            105.82547505751043,
            21.010482099529327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547505751043,
              21.010482099529327
            ],
            [
              105.82547404006712,
              21.01047987259193
            ],
            [
              105.82546782139711,
              21.010466244165357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4247",
          "diaChiLapD": "Ngõ 167, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010482099529327,
          "to_latitude": 21.010466244165357,
          "to_longitude": 105.82546782139711,
          "from_longitude": 105.82547505751043
        }
      },
      {
        "id": 4241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547505751043,
            21.01038202185517,
            105.82572361806672,
            21.010623105648083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82557396120646,
              21.010623105648083
            ],
            [
              105.8255046101526,
              21.01052004161979
            ],
            [
              105.82548222759199,
              21.010491897272
            ],
            [
              105.82547505751043,
              21.010482099529327
            ],
            [
              105.82550089890557,
              21.010472799192
            ],
            [
              105.82572361806672,
              21.01038202185517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4248",
          "diaChiLapD": "Ngõ 167, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010623105648083,
          "to_latitude": 21.01038202185517,
          "to_longitude": 105.82572361806672,
          "from_longitude": 105.82557396120646
        }
      },
      {
        "id": 4242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82714412281486,
            21.009127771072663,
            105.82720422068425,
            21.009158851680063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82714412281486,
              21.009158851680063
            ],
            [
              105.8271959526952,
              21.009134252371314
            ],
            [
              105.82720422068425,
              21.009130328306814
            ],
            [
              105.82720166044675,
              21.009127771072663
            ],
            [
              105.82719467550756,
              21.009131847868087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4249",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009158851680063,
          "to_latitude": 21.009131847868087,
          "to_longitude": 105.82719467550756,
          "from_longitude": 105.82714412281486
        }
      },
      {
        "id": 4243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82754350440791,
            21.009496023614176,
            105.82757063738867,
            21.009533821752743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757063738867,
              21.009533821752743
            ],
            [
              105.82754350440791,
              21.009496023614176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4250",
          "diaChiLapD": "Ngõ 167, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009533821752743,
          "to_latitude": 21.009496023614176,
          "to_longitude": 105.82754350440791,
          "from_longitude": 105.82757063738867
        }
      },
      {
        "id": 4244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82731107160323,
            21.009656843523544,
            105.82739992159321,
            21.00969919897857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82731107160323,
              21.00966461107104
            ],
            [
              105.8273161883852,
              21.009672889555254
            ],
            [
              105.8273211974087,
              21.009680988821128
            ],
            [
              105.82733528403949,
              21.009674288845826
            ],
            [
              105.82737196511611,
              21.009656843523544
            ],
            [
              105.82739992159321,
              21.00969919897857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4251",
          "diaChiLapD": "Ngõ 167, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00966461107104,
          "to_latitude": 21.00969919897857,
          "to_longitude": 105.82739992159321,
          "from_longitude": 105.82731107160323
        }
      },
      {
        "id": 4245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82719380753963,
            21.00911857904318,
            105.82756002185535,
            21.00932022057856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82719380753963,
              21.009130216344
            ],
            [
              105.82720132708704,
              21.009126625595442
            ],
            [
              105.82721817554778,
              21.00911857904318
            ],
            [
              105.82722694738136,
              21.00913346402697
            ],
            [
              105.82733693209649,
              21.00932022057856
            ],
            [
              105.82756002185535,
              21.009208038056048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4252",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009130216344,
          "to_latitude": 21.009208038056048,
          "to_longitude": 105.82756002185535,
          "from_longitude": 105.82719380753963
        }
      },
      {
        "id": 4246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82648223419223,
            21.008909863367386,
            105.82649887970537,
            21.0089339423566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82648223419223,
              21.0089339423566
            ],
            [
              105.82649887970537,
              21.00892519595669
            ],
            [
              105.82649039021118,
              21.008909863367386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4253",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0089339423566,
          "to_latitude": 21.008909863367386,
          "to_longitude": 105.82649039021118,
          "from_longitude": 105.82648223419223
        }
      },
      {
        "id": 4247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82667195708856,
            21.008904549310607,
            105.8266744976576,
            21.0089082528433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266744976576,
              21.0089082528433
            ],
            [
              105.82667195708856,
              21.008904549310607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4254",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0089082528433,
          "to_latitude": 21.008904549310607,
          "to_longitude": 105.82667195708856,
          "from_longitude": 105.8266744976576
        }
      },
      {
        "id": 4248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650832180934,
            21.008782879385123,
            105.827059829284,
            21.008957531150205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82650832180934,
              21.008908457566044
            ],
            [
              105.82650887743813,
              21.0089082200285
            ],
            [
              105.82654175313374,
              21.008893560854915
            ],
            [
              105.82656000580702,
              21.008895088072098
            ],
            [
              105.82662086156037,
              21.008934641027487
            ],
            [
              105.8266744976576,
              21.0089082528433
            ],
            [
              105.82682911574251,
              21.00883218249923
            ],
            [
              105.82694260631739,
              21.008782879385123
            ],
            [
              105.827059829284,
              21.008957531150205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4255",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008908457566044,
          "to_latitude": 21.008957531150205,
          "to_longitude": 105.827059829284,
          "from_longitude": 105.82650832180934
        }
      },
      {
        "id": 4249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82648223419223,
            21.0089339423566,
            105.82659955026973,
            21.009122600330613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82648223419223,
              21.0089339423566
            ],
            [
              105.82659955026973,
              21.009122600330613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4256",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0089339423566,
          "to_latitude": 21.009122600330613,
          "to_longitude": 105.82659955026973,
          "from_longitude": 105.82648223419223
        }
      },
      {
        "id": 4250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83012531295884,
            21.006777775078156,
            105.83014742326272,
            21.00681716440977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83012531295884,
              21.006777775078156
            ],
            [
              105.83014742326272,
              21.00681716440977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4257",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006777775078156,
          "to_latitude": 21.00681716440977,
          "to_longitude": 105.83014742326272,
          "from_longitude": 105.83012531295884
        }
      },
      {
        "id": 4251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82979245177624,
            21.006657971171787,
            105.83047780538732,
            21.006990089441377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82979245177624,
              21.006990089441377
            ],
            [
              105.83014742326272,
              21.00681716440977
            ],
            [
              105.83047780538732,
              21.006657971171787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4258",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006990089441377,
          "to_latitude": 21.006657971171787,
          "to_longitude": 105.83047780538732,
          "from_longitude": 105.82979245177624
        }
      },
      {
        "id": 4252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82479312500315,
            21.00969445648605,
            105.82496351024388,
            21.009805572739616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82479312500315,
              21.009805572739616
            ],
            [
              105.82480496325638,
              21.009796673672064
            ],
            [
              105.82491182278115,
              21.009716371854754
            ],
            [
              105.82496351024388,
              21.00969445648605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4259",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009805572739616,
          "to_latitude": 21.00969445648605,
          "to_longitude": 105.82496351024388,
          "from_longitude": 105.82479312500315
        }
      },
      {
        "id": 4253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82463248215464,
            21.009387659369295,
            105.82485951109847,
            21.009491313878513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82463248215464,
              21.009491313878513
            ],
            [
              105.82463358941149,
              21.009490809028303
            ],
            [
              105.82465597146505,
              21.0094805898423
            ],
            [
              105.82485951109847,
              21.009387659369295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4260",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009491313878513,
          "to_latitude": 21.009387659369295,
          "to_longitude": 105.82485951109847,
          "from_longitude": 105.82463248215464
        }
      },
      {
        "id": 4254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251901890796,
            21.009014317798407,
            105.82527853716734,
            21.009199497964158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82527853716734,
              21.009199497964158
            ],
            [
              105.8252259982195,
              21.009081244874686
            ],
            [
              105.82520967052842,
              21.009050728146043
            ],
            [
              105.8251974189511,
              21.00902538430104
            ],
            [
              105.8251901890796,
              21.009014317798407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4261",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009199497964158,
          "to_latitude": 21.009014317798407,
          "to_longitude": 105.8251901890796,
          "from_longitude": 105.82527853716734
        }
      },
      {
        "id": 4255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82516892446307,
            21.00924872462934,
            105.82522864305061,
            21.009324181099004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82518096123408,
              21.009324181099004
            ],
            [
              105.82516892446307,
              21.00927615799197
            ],
            [
              105.82522864305061,
              21.00924872462934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4262",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009324181099004,
          "to_latitude": 21.00924872462934,
          "to_longitude": 105.82522864305061,
          "from_longitude": 105.82518096123408
        }
      },
      {
        "id": 4256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82656334087167,
            21.00855662907482,
            105.82667233727821,
            21.00862656065045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656334087167,
              21.00862656065045
            ],
            [
              105.82667233727821,
              21.00855662907482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4263",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00862656065045,
          "to_latitude": 21.00855662907482,
          "to_longitude": 105.82667233727821,
          "from_longitude": 105.82656334087167
        }
      },
      {
        "id": 4257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82652896843781,
            21.008432177084867,
            105.82654781080034,
            21.00846672702197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82654781080034,
              21.00846672702197
            ],
            [
              105.82652896843781,
              21.008432177084867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4264",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00846672702197,
          "to_latitude": 21.008432177084867,
          "to_longitude": 105.82652896843781,
          "from_longitude": 105.82654781080034
        }
      },
      {
        "id": 4258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650039195616,
            21.008427036148642,
            105.8266323127177,
            21.00848899877066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266323127177,
              21.008427036148642
            ],
            [
              105.82654781080034,
              21.00846672702197
            ],
            [
              105.82650039195616,
              21.00848899877066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4265",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008427036148642,
          "to_latitude": 21.00848899877066,
          "to_longitude": 105.82650039195616,
          "from_longitude": 105.8266323127177
        }
      },
      {
        "id": 4259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82788320092318,
            21.00890863209832,
            105.82825361457687,
            21.009041151224363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82788320092318,
              21.00902952946326
            ],
            [
              105.82797070727985,
              21.00898870963408
            ],
            [
              105.82799756998408,
              21.009041151224363
            ],
            [
              105.82825361457687,
              21.00890863209832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4266",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00902952946326,
          "to_latitude": 21.00890863209832,
          "to_longitude": 105.82825361457687,
          "from_longitude": 105.82788320092318
        }
      },
      {
        "id": 4260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82783686932139,
            21.00892043421106,
            105.82818799768128,
            21.0096061798598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82784754369675,
              21.00892043421106
            ],
            [
              105.82784829664455,
              21.008922312872055
            ],
            [
              105.82785065391312,
              21.0089274823113
            ],
            [
              105.82783686932139,
              21.008936258278684
            ],
            [
              105.82784251805847,
              21.008952561133103
            ],
            [
              105.82788320092318,
              21.00902952946326
            ],
            [
              105.82818799768128,
              21.0096061798598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4267",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00892043421106,
          "to_latitude": 21.0096061798598,
          "to_longitude": 105.82818799768128,
          "from_longitude": 105.82784754369675
        }
      },
      {
        "id": 4261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961591084722,
            21.008592734944653,
            105.82962744258093,
            21.00859854742338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82961591084722,
              21.00859854742338
            ],
            [
              105.82962744258093,
              21.008592734944653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4268",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00859854742338,
          "to_latitude": 21.008592734944653,
          "to_longitude": 105.82962744258093,
          "from_longitude": 105.82961591084722
        }
      },
      {
        "id": 4262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82949710640234,
            21.008478983027523,
            105.82969235171666,
            21.008710445760954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82949710640234,
              21.008478983027523
            ],
            [
              105.8295222340871,
              21.00849833048823
            ],
            [
              105.82959381045774,
              21.00855140830535
            ],
            [
              105.82961553227197,
              21.00857113364527
            ],
            [
              105.82962744258093,
              21.008592734944653
            ],
            [
              105.82969235171666,
              21.008710445760954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4269",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008478983027523,
          "to_latitude": 21.008710445760954,
          "to_longitude": 105.82969235171666,
          "from_longitude": 105.82949710640234
        }
      },
      {
        "id": 4263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83071062600877,
            21.00797981727567,
            105.830745692481,
            21.00808612470553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830745692481,
              21.00808612470553
            ],
            [
              105.83071062600877,
              21.00797981727567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4270",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00808612470553,
          "to_latitude": 21.00797981727567,
          "to_longitude": 105.83071062600877,
          "from_longitude": 105.830745692481
        }
      },
      {
        "id": 4264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135628943405,
            21.00765994739219,
            105.83138861198975,
            21.00773954870178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83135866125633,
              21.00765994739219
            ],
            [
              105.83135628943405,
              21.007675086207215
            ],
            [
              105.83138861198975,
              21.00773954870178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4271",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00765994739219,
          "to_latitude": 21.00773954870178,
          "to_longitude": 105.83138861198975,
          "from_longitude": 105.83135866125633
        }
      },
      {
        "id": 4265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82936597683826,
            21.008276729232204,
            105.8293812367273,
            21.00828395534793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8293812367273,
              21.008276729232204
            ],
            [
              105.8293778702662,
              21.00827832357528
            ],
            [
              105.82937027485892,
              21.008281920206777
            ],
            [
              105.82936597683826,
              21.00828395534793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4272",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008276729232204,
          "to_latitude": 21.00828395534793,
          "to_longitude": 105.82936597683826,
          "from_longitude": 105.8293812367273
        }
      },
      {
        "id": 4266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82661971878217,
            21.012880270807933,
            105.82663651473631,
            21.012888849960326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82663651473631,
              21.012880270807933
            ],
            [
              105.82663180304361,
              21.012882677313
            ],
            [
              105.82662358828073,
              21.012886873869995
            ],
            [
              105.82661971878217,
              21.012888849960326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4273",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012880270807933,
          "to_latitude": 21.012888849960326,
          "to_longitude": 105.82661971878217,
          "from_longitude": 105.82663651473631
        }
      },
      {
        "id": 4267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82749897599565,
            21.0090009983782,
            105.82750224938383,
            21.0090072878847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749897599565,
              21.0090009983782
            ],
            [
              105.82750154164115,
              21.00900593106717
            ],
            [
              105.82750224938383,
              21.0090072878847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4274",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0090009983782,
          "to_latitude": 21.0090072878847,
          "to_longitude": 105.82750224938383,
          "from_longitude": 105.82749897599565
        }
      },
      {
        "id": 4268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82723815462104,
            21.00874739130182,
            105.82749897599565,
            21.009102479328572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82723815462104,
              21.00874739130182
            ],
            [
              105.82732014892353,
              21.008849722070302
            ],
            [
              105.82738240704556,
              21.00891923427644
            ],
            [
              105.82743209974832,
              21.0089675970873
            ],
            [
              105.82749897599565,
              21.0090009983782
            ],
            [
              105.82729854486453,
              21.009079849990577
            ],
            [
              105.8272513732032,
              21.009102479328572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4275",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00874739130182,
          "to_latitude": 21.009102479328572,
          "to_longitude": 105.8272513732032,
          "from_longitude": 105.82723815462104
        }
      },
      {
        "id": 4269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82757301132774,
            21.0091829182345,
            105.8276226499455,
            21.0092064719176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757301132774,
              21.0092064719176
            ],
            [
              105.82760555752942,
              21.009191028335426
            ],
            [
              105.82762149964167,
              21.009183463958383
            ],
            [
              105.8276226499455,
              21.0091829182345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4276",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0092064719176,
          "to_latitude": 21.0091829182345,
          "to_longitude": 105.8276226499455,
          "from_longitude": 105.82757301132774
        }
      },
      {
        "id": 4270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82725023962445,
            21.00903008522564,
            105.82768354517698,
            21.00942433448315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82768354517698,
              21.00942433448315
            ],
            [
              105.82761190443857,
              21.00928717482532
            ],
            [
              105.82757340508992,
              21.009207232325785
            ],
            [
              105.82757301132774,
              21.0092064719176
            ],
            [
              105.82748165106975,
              21.00903008522564
            ],
            [
              105.82725023962445,
              21.009132228991398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4277",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00942433448315,
          "to_latitude": 21.009132228991398,
          "to_longitude": 105.82725023962445,
          "from_longitude": 105.82768354517698
        }
      },
      {
        "id": 4271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291678085913,
            21.008308269710998,
            105.82918109444888,
            21.008314147609536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918109444888,
              21.008308269710998
            ],
            [
              105.82918101480378,
              21.008308304421657
            ],
            [
              105.8291678085913,
              21.008314147609536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4278",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008308269710998,
          "to_latitude": 21.008314147609536,
          "to_longitude": 105.8291678085913,
          "from_longitude": 105.82918109444888
        }
      },
      {
        "id": 4272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82907380065623,
            21.008390287724545,
            105.82920619132925,
            21.008644196532984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920619132925,
              21.008390287724545
            ],
            [
              105.82907380065623,
              21.008456126731996
            ],
            [
              105.82917112142314,
              21.008644196532984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4279",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008390287724545,
          "to_latitude": 21.008644196532984,
          "to_longitude": 105.82917112142314,
          "from_longitude": 105.82920619132925
        }
      },
      {
        "id": 4273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82913691284377,
            21.008293134462367,
            105.82927074464898,
            21.008538739029593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82917291457953,
              21.008293134462367
            ],
            [
              105.82918109444888,
              21.008308269710998
            ],
            [
              105.82927074464898,
              21.00847415024062
            ],
            [
              105.82913691284377,
              21.008538739029593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4280",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008293134462367,
          "to_latitude": 21.008538739029593,
          "to_longitude": 105.82913691284377,
          "from_longitude": 105.82917291457953
        }
      },
      {
        "id": 4274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83002101115966,
            21.008172397379553,
            105.83018728133673,
            21.008438020688253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83018728133673,
              21.008438020688253
            ],
            [
              105.83017274922985,
              21.008417358026307
            ],
            [
              105.8301693542487,
              21.00841257936076
            ],
            [
              105.83012879397062,
              21.008355511783012
            ],
            [
              105.83002101115966,
              21.008194128815433
            ],
            [
              105.83005745430063,
              21.008172397379553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4281",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008438020688253,
          "to_latitude": 21.008172397379553,
          "to_longitude": 105.83005745430063,
          "from_longitude": 105.83018728133673
        }
      },
      {
        "id": 4275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157392143838,
            21.00772827968952,
            105.83160798489659,
            21.007753651047302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157392143838,
              21.00772827968952
            ],
            [
              105.83157956888564,
              21.00773161570697
            ],
            [
              105.83158809127336,
              21.007738224514945
            ],
            [
              105.83160349200098,
              21.007750367195488
            ],
            [
              105.83160798489659,
              21.007753651047302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4282",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00772827968952,
          "to_latitude": 21.007753651047302,
          "to_longitude": 105.83160798489659,
          "from_longitude": 105.83157392143838
        }
      },
      {
        "id": 4276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143032658049,
            21.007415243473442,
            105.83180397969008,
            21.00786618582822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83143032658049,
              21.00786618582822
            ],
            [
              105.8314552925278,
              21.007850243850825
            ],
            [
              105.83148645415544,
              21.007821834177467
            ],
            [
              105.83151275433472,
              21.00779653818795
            ],
            [
              105.8315191942495,
              21.00779065110835
            ],
            [
              105.83154969547844,
              21.00776058542468
            ],
            [
              105.83155898209355,
              21.007751313594728
            ],
            [
              105.83157288377353,
              21.00772987985695
            ],
            [
              105.83157392143838,
              21.00772827968952
            ],
            [
              105.8315875932579,
              21.007707199331893
            ],
            [
              105.83162145452309,
              21.007666408570717
            ],
            [
              105.8317070442118,
              21.00758937325917
            ],
            [
              105.8317634520768,
              21.007497402081388
            ],
            [
              105.83180397969008,
              21.007415243473442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4283",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00786618582822,
          "to_latitude": 21.007415243473442,
          "to_longitude": 105.83180397969008,
          "from_longitude": 105.83143032658049
        }
      },
      {
        "id": 4277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83001192961244,
            21.00852324638398,
            105.83003270170765,
            21.008550032303646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83001192961244,
              21.00852324638398
            ],
            [
              105.83001794588657,
              21.008531006462068
            ],
            [
              105.8300270797443,
              21.00854278243209
            ],
            [
              105.83003270170765,
              21.008550032303646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4284",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00852324638398,
          "to_latitude": 21.008550032303646,
          "to_longitude": 105.83003270170765,
          "from_longitude": 105.83001192961244
        }
      },
      {
        "id": 4278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987764120536,
            21.00836649605594,
            105.83026762480735,
            21.008679491037796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026762480735,
              21.00836649605594
            ],
            [
              105.83021465646381,
              21.008391722351234
            ],
            [
              105.8301693542487,
              21.00841257936076
            ],
            [
              105.83016725401764,
              21.008413546122704
            ],
            [
              105.83010738685834,
              21.008451099849992
            ],
            [
              105.83010387675309,
              21.008463067556423
            ],
            [
              105.83006756658146,
              21.008487981323512
            ],
            [
              105.83003448283918,
              21.008510680893867
            ],
            [
              105.83001192961244,
              21.00852324638398
            ],
            [
              105.83000097088696,
              21.008529352351662
            ],
            [
              105.82997062104972,
              21.008548093284233
            ],
            [
              105.82995849782898,
              21.00856313331872
            ],
            [
              105.82987764120536,
              21.008679491037796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4285",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00836649605594,
          "to_latitude": 21.008679491037796,
          "to_longitude": 105.82987764120536,
          "from_longitude": 105.83026762480735
        }
      },
      {
        "id": 4279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83044206098751,
            21.008282926572637,
            105.8304526921259,
            21.008306448893666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044206098751,
              21.008282926572637
            ],
            [
              105.8304439940317,
              21.008287207463393
            ],
            [
              105.83044743060452,
              21.00829480759857
            ],
            [
              105.8304526921259,
              21.008306448893666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4286",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008282926572637,
          "to_latitude": 21.008306448893666,
          "to_longitude": 105.8304526921259,
          "from_longitude": 105.83044206098751
        }
      },
      {
        "id": 4280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029142327716,
            21.00814645330501,
            105.83084423826543,
            21.00833543903267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83084423826543,
              21.00814645330501
            ],
            [
              105.83073858016641,
              21.00818600744818
            ],
            [
              105.83073252525594,
              21.00819611791119
            ],
            [
              105.83069678199485,
              21.00820872890924
            ],
            [
              105.8306112780279,
              21.008231553493864
            ],
            [
              105.8305923931132,
              21.008237785797824
            ],
            [
              105.83044206098751,
              21.008282926572637
            ],
            [
              105.83043511517492,
              21.00828501169351
            ],
            [
              105.83029142327716,
              21.00833543903267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4287",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00814645330501,
          "to_latitude": 21.00833543903267,
          "to_longitude": 105.83029142327716,
          "from_longitude": 105.83084423826543
        }
      },
      {
        "id": 4281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029589846922,
            21.007951775853936,
            105.83086427009296,
            21.008150362214867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83086427009296,
              21.008150362214867
            ],
            [
              105.83086297559952,
              21.008146752018114
            ],
            [
              105.83079312004216,
              21.007951775853936
            ],
            [
              105.83071062600877,
              21.00797981727567
            ],
            [
              105.83070028881293,
              21.00798333160707
            ],
            [
              105.83056393193941,
              21.00801260142114
            ],
            [
              105.83054909234521,
              21.00803631217483
            ],
            [
              105.83047038998191,
              21.008046799746353
            ],
            [
              105.83029589846922,
              21.008067123824983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4288",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008150362214867,
          "to_latitude": 21.008067123824983,
          "to_longitude": 105.83029589846922,
          "from_longitude": 105.83086427009296
        }
      },
      {
        "id": 4282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83120026981194,
            21.007994123464965,
            105.83120778681892,
            21.00800816171971
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83120026981194,
              21.007994123464965
            ],
            [
              105.8312019065586,
              21.007997179213085
            ],
            [
              105.831204218824,
              21.00800149979099
            ],
            [
              105.83120653300828,
              21.008005819456248
            ],
            [
              105.83120778681892,
              21.00800816171971
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4289",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007994123464965,
          "to_latitude": 21.00800816171971,
          "to_longitude": 105.83120778681892,
          "from_longitude": 105.83120026981194
        }
      },
      {
        "id": 4283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8308932265659,
            21.007903679065503,
            105.83140659486365,
            21.00812617244556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8308932265659,
              21.00812617244556
            ],
            [
              105.8309378353822,
              21.00810624082735
            ],
            [
              105.83099247263382,
              21.008082798163333
            ],
            [
              105.83106430991424,
              21.008052848647214
            ],
            [
              105.83114594810294,
              21.008017561943337
            ],
            [
              105.8311850381642,
              21.00800069566726
            ],
            [
              105.83120026981194,
              21.007994123464965
            ],
            [
              105.83121863069626,
              21.00798620100906
            ],
            [
              105.83132660807306,
              21.007938218985522
            ],
            [
              105.83140659486365,
              21.007903679065503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4290",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00812617244556,
          "to_latitude": 21.007903679065503,
          "to_longitude": 105.83140659486365,
          "from_longitude": 105.8308932265659
        }
      },
      {
        "id": 4284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83081571689486,
            21.007739406580928,
            105.83144294562412,
            21.007964142701873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144294562412,
              21.00790507940699
            ],
            [
              105.83144031516632,
              21.007897496128546
            ],
            [
              105.8314129920702,
              21.007887478261534
            ],
            [
              105.83133783128424,
              21.007739406580928
            ],
            [
              105.83081571689486,
              21.007964142701873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4291",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00790507940699,
          "to_latitude": 21.007964142701873,
          "to_longitude": 105.83081571689486,
          "from_longitude": 105.83144294562412
        }
      },
      {
        "id": 4285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8307988240837,
            21.00769550889935,
            105.83144562091346,
            21.00793527948734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144562091346,
              21.00790391472332
            ],
            [
              105.83144155903041,
              21.00789630682549
            ],
            [
              105.8313651644097,
              21.007753150219138
            ],
            [
              105.83132618203628,
              21.00769550889935
            ],
            [
              105.8307988240837,
              21.00793527948734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4292",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00790391472332,
          "to_latitude": 21.00793527948734,
          "to_longitude": 105.8307988240837,
          "from_longitude": 105.83144562091346
        }
      },
      {
        "id": 4286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83120496597394,
            21.007434146484233,
            105.83160695279788,
            21.007755214155456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83160695279788,
              21.007755214155456
            ],
            [
              105.83160279008489,
              21.0077521499805
            ],
            [
              105.83157499160683,
              21.00773167691225
            ],
            [
              105.83157288377353,
              21.00772987985695
            ],
            [
              105.83155671887565,
              21.00771609613305
            ],
            [
              105.8315015935803,
              21.007689091673203
            ],
            [
              105.83145946331454,
              21.007617633762905
            ],
            [
              105.83135866125633,
              21.00765994739219
            ],
            [
              105.83124403981606,
              21.007437127765407
            ],
            [
              105.83120496597394,
              21.007434146484233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4293",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007755214155456,
          "to_latitude": 21.007434146484233,
          "to_longitude": 105.83120496597394,
          "from_longitude": 105.83160695279788
        }
      },
      {
        "id": 4287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143470745458,
            21.007302765385074,
            105.83189088445816,
            21.00751107740325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189088445816,
              21.007310348869037
            ],
            [
              105.8318895490101,
              21.0073099381066
            ],
            [
              105.83186630861131,
              21.007302765385074
            ],
            [
              105.83174876997724,
              21.007361889693666
            ],
            [
              105.83143470745458,
              21.00751107740325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4294",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007310348869037,
          "to_latitude": 21.00751107740325,
          "to_longitude": 105.83143470745458,
          "from_longitude": 105.83189088445816
        }
      },
      {
        "id": 4288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925706702542,
            21.00808035605062,
            105.82948106196504,
            21.008461045483237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925706702542,
              21.00808035605062
            ],
            [
              105.82927743128491,
              21.00812388716209
            ],
            [
              105.82929796089307,
              21.008141465967867
            ],
            [
              105.82931283094696,
              21.008144809505435
            ],
            [
              105.8293812367273,
              21.008276729232204
            ],
            [
              105.82938352541841,
              21.00828114388649
            ],
            [
              105.82948106196504,
              21.008461045483237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4295",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00808035605062,
          "to_latitude": 21.008461045483237,
          "to_longitude": 105.82948106196504,
          "from_longitude": 105.82925706702542
        }
      },
      {
        "id": 4289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890021114427,
            21.00814489067538,
            105.82927430290805,
            21.008300839744557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890021114427,
              21.008300839744557
            ],
            [
              105.82894175878249,
              21.00828198664886
            ],
            [
              105.82895702828807,
              21.00828555136844
            ],
            [
              105.82907737137887,
              21.00823123579895
            ],
            [
              105.82911693138554,
              21.008213890797315
            ],
            [
              105.82917698481195,
              21.00818755999537
            ],
            [
              105.82927430290805,
              21.00814489067538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4296",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008300839744557,
          "to_latitude": 21.00814489067538,
          "to_longitude": 105.82927430290805,
          "from_longitude": 105.82890021114427
        }
      },
      {
        "id": 4290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82824429653283,
            21.008719005331326,
            105.82826170641802,
            21.00873183041057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82824429653283,
              21.008719005331326
            ],
            [
              105.82825190334701,
              21.00872461076374
            ],
            [
              105.82826170641802,
              21.00873183041057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4297",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008719005331326,
          "to_latitude": 21.00873183041057,
          "to_longitude": 105.82826170641802,
          "from_longitude": 105.82824429653283
        }
      },
      {
        "id": 4291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786165995205,
            21.008350317763835,
            105.82827553767974,
            21.008919818183376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82813452389007,
              21.008350317763835
            ],
            [
              105.82812890671266,
              21.008370060725408
            ],
            [
              105.82814552024574,
              21.00840713771059
            ],
            [
              105.8281725042545,
              21.00845716797632
            ],
            [
              105.82818665517767,
              21.008481314609664
            ],
            [
              105.82823340782831,
              21.00855954041676
            ],
            [
              105.8282623164068,
              21.008666161980553
            ],
            [
              105.82827553767974,
              21.008712174820936
            ],
            [
              105.82827127840171,
              21.008724544462744
            ],
            [
              105.82826170641802,
              21.00873183041057
            ],
            [
              105.8282595435497,
              21.008733476680504
            ],
            [
              105.8282262479296,
              21.008749568031675
            ],
            [
              105.82805812088904,
              21.008824943938485
            ],
            [
              105.82786165995205,
              21.008919818183376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4298",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008350317763835,
          "to_latitude": 21.008919818183376,
          "to_longitude": 105.82786165995205,
          "from_longitude": 105.82813452389007
        }
      },
      {
        "id": 4292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8266856972642,
            21.00886849440081,
            105.82670072362006,
            21.008891299230875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266856972642,
              21.00886849440081
            ],
            [
              105.82669484538881,
              21.008882378617734
            ],
            [
              105.82670022505421,
              21.008890545652207
            ],
            [
              105.82670072362006,
              21.008891299230875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4299",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00886849440081,
          "to_latitude": 21.008891299230875,
          "to_longitude": 105.82670072362006,
          "from_longitude": 105.8266856972642
        }
      },
      {
        "id": 4293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82662497204122,
            21.0086486639927,
            105.82692800077116,
            21.008899077286017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82686332420563,
              21.0086486639927
            ],
            [
              105.82692800077116,
              21.00874340709798
            ],
            [
              105.82679510940655,
              21.0088183043562
            ],
            [
              105.8267633225097,
              21.008831023576523
            ],
            [
              105.82674763396784,
              21.008837300447006
            ],
            [
              105.8266856972642,
              21.00886849440081
            ],
            [
              105.82662497204122,
              21.008899077286017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4300",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0086486639927,
          "to_latitude": 21.008899077286017,
          "to_longitude": 105.82662497204122,
          "from_longitude": 105.82686332420563
        }
      },
      {
        "id": 4294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694221033371,
            21.00873391530271,
            105.82700639282363,
            21.008776653561974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694221033371,
              21.008776653561974
            ],
            [
              105.82695003644277,
              21.00877144275295
            ],
            [
              105.82700639282363,
              21.00873391530271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4301",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008776653561974,
          "to_latitude": 21.00873391530271,
          "to_longitude": 105.82700639282363,
          "from_longitude": 105.82694221033371
        }
      },
      {
        "id": 4295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8269258592641,
            21.00861348925946,
            105.82705115137227,
            21.008806808553313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82705115137227,
              21.008806808553313
            ],
            [
              105.82703246745255,
              21.00877572900751
            ],
            [
              105.82700639282363,
              21.00873391530271
            ],
            [
              105.82694978952131,
              21.00864652771357
            ],
            [
              105.8269258592641,
              21.00861348925946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4302",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008806808553313,
          "to_latitude": 21.00861348925946,
          "to_longitude": 105.8269258592641,
          "from_longitude": 105.82705115137227
        }
      },
      {
        "id": 4296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82709890670115,
            21.008948053393116,
            105.82713271851293,
            21.00896772923351
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82713271851293,
              21.008948053393116
            ],
            [
              105.82712449663698,
              21.00895283800477
            ],
            [
              105.82711031473424,
              21.00896109016779
            ],
            [
              105.82709890670115,
              21.00896772923351
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4303",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008948053393116,
          "to_latitude": 21.00896772923351,
          "to_longitude": 105.82709890670115,
          "from_longitude": 105.82713271851293
        }
      },
      {
        "id": 4297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82705962329295,
            21.008823132038316,
            105.82721130048058,
            21.009100361868253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82721130048058,
              21.009100361868253
            ],
            [
              105.8271639365317,
              21.009007152411495
            ],
            [
              105.82714732049426,
              21.008975697925425
            ],
            [
              105.82713271851293,
              21.008948053393116
            ],
            [
              105.82713159580368,
              21.00894592723544
            ],
            [
              105.8271144575302,
              21.00892163787049
            ],
            [
              105.82709084929208,
              21.00888176948494
            ],
            [
              105.82705962329295,
              21.008823132038316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4304",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009100361868253,
          "to_latitude": 21.008823132038316,
          "to_longitude": 105.82705962329295,
          "from_longitude": 105.82721130048058
        }
      },
      {
        "id": 4298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82624247093311,
            21.009022276155225,
            105.82625826182229,
            21.00904855202643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624247093311,
              21.009022276155225
            ],
            [
              105.8262538816124,
              21.009041263480572
            ],
            [
              105.82625715125181,
              21.00904670670725
            ],
            [
              105.82625826182229,
              21.00904855202643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4305",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009022276155225,
          "to_latitude": 21.00904855202643,
          "to_longitude": 105.82625826182229,
          "from_longitude": 105.82624247093311
        }
      },
      {
        "id": 4299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8260810199906,
            21.008844616882758,
            105.82658395020859,
            21.009106557865774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8260810199906,
              21.009106557865774
            ],
            [
              105.82611845866273,
              21.009086594551626
            ],
            [
              105.82617716328872,
              21.009057438815397
            ],
            [
              105.82624247093311,
              21.009022276155225
            ],
            [
              105.82626424237867,
              21.00901055404797
            ],
            [
              105.82629535488367,
              21.008994657029103
            ],
            [
              105.82637360676863,
              21.0089514731368
            ],
            [
              105.82641608362182,
              21.008934102843998
            ],
            [
              105.82658395020859,
              21.008844616882758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4306",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009106557865774,
          "to_latitude": 21.008844616882758,
          "to_longitude": 105.82658395020859,
          "from_longitude": 105.8260810199906
        }
      },
      {
        "id": 4300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82574655026771,
            21.009261577990994,
            105.82575920718976,
            21.009281378529575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574655026771,
              21.009261577990994
            ],
            [
              105.82575522624745,
              21.009275150227772
            ],
            [
              105.8257579866221,
              21.009279470514933
            ],
            [
              105.82575920718976,
              21.009281378529575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4307",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009261577990994,
          "to_latitude": 21.009281378529575,
          "to_longitude": 105.82575920718976,
          "from_longitude": 105.82574655026771
        }
      },
      {
        "id": 4301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82557118346985,
            21.009116753490332,
            105.82606297130866,
            21.009338952039094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82557118346985,
              21.009338952039094
            ],
            [
              105.82561248551363,
              21.00932183331918
            ],
            [
              105.82568636242078,
              21.009288496260822
            ],
            [
              105.82574655026771,
              21.009261577990994
            ],
            [
              105.82579413363342,
              21.009240296485718
            ],
            [
              105.82587217867305,
              21.00920055059908
            ],
            [
              105.82597613841881,
              21.009154217211197
            ],
            [
              105.8260283913697,
              21.009130949361914
            ],
            [
              105.82606297130866,
              21.009116753490332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4308",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009338952039094,
          "to_latitude": 21.009116753490332,
          "to_longitude": 105.82606297130866,
          "from_longitude": 105.82557118346985
        }
      },
      {
        "id": 4302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.825293637816,
            21.009468431870673,
            105.82530575129223,
            21.00949121753422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82530575129223,
              21.00949121753422
            ],
            [
              105.82530446833002,
              21.00948880762477
            ],
            [
              105.8253019363694,
              21.009484041834718
            ],
            [
              105.825293637816,
              21.009468431870673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4309",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00949121753422,
          "to_latitude": 21.009468431870673,
          "to_longitude": 105.825293637816,
          "from_longitude": 105.82530575129223
        }
      },
      {
        "id": 4303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82511523103989,
            21.009349891639847,
            105.8255528098484,
            21.009557720217206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82511523103989,
              21.009557720217206
            ],
            [
              105.82514318718724,
              21.00954363703253
            ],
            [
              105.8251507079529,
              21.00953658158881
            ],
            [
              105.82517692385002,
              21.009522467998483
            ],
            [
              105.82523756650015,
              21.009494958794928
            ],
            [
              105.825293637816,
              21.009468431870673
            ],
            [
              105.82529405419585,
              21.0094682347543
            ],
            [
              105.82534618688425,
              21.009446146403565
            ],
            [
              105.82541349738257,
              21.009415320666136
            ],
            [
              105.8255528098484,
              21.009349891639847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4310",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009557720217206,
          "to_latitude": 21.009349891639847,
          "to_longitude": 105.8255528098484,
          "from_longitude": 105.82511523103989
        }
      },
      {
        "id": 4304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82469874678206,
            21.009629288026602,
            105.82471991053012,
            21.00963873357413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82471991053012,
              21.009629288026602
            ],
            [
              105.82471945282812,
              21.00962949256767
            ],
            [
              105.8247080946796,
              21.009634561426875
            ],
            [
              105.82469874678206,
              21.00963873357413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4311",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009629288026602,
          "to_latitude": 21.00963873357413,
          "to_longitude": 105.82469874678206,
          "from_longitude": 105.82471991053012
        }
      },
      {
        "id": 4305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82461960377267,
            21.00932467532104,
            105.82479886483713,
            21.00976885765032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82479886483713,
              21.00976885765032
            ],
            [
              105.82474871186692,
              21.00968460927885
            ],
            [
              105.82471991053012,
              21.009629288026602
            ],
            [
              105.82471543663799,
              21.009620695634382
            ],
            [
              105.8247138354302,
              21.00961726029367
            ],
            [
              105.82471259047826,
              21.00961458916276
            ],
            [
              105.8247097433569,
              21.00960848269572
            ],
            [
              105.82467919435192,
              21.00955054964396
            ],
            [
              105.82468510112629,
              21.009532653383665
            ],
            [
              105.82466381083874,
              21.00949632219372
            ],
            [
              105.82465999770561,
              21.009489816664654
            ],
            [
              105.82465597146505,
              21.0094805898423
            ],
            [
              105.82461960377267,
              21.00939724016961
            ],
            [
              105.8246344318129,
              21.00932467532104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4312",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00976885765032,
          "to_latitude": 21.00932467532104,
          "to_longitude": 105.8246344318129,
          "from_longitude": 105.82479886483713
        }
      },
      {
        "id": 4306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521305893444,
            21.010635697839156,
            105.82523556261378,
            21.01064551304809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82523556261378,
              21.010635697839156
            ],
            [
              105.82523223662444,
              21.010637148293192
            ],
            [
              105.82522033561271,
              21.010642339046335
            ],
            [
              105.82521305893444,
              21.01064551304809
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4313",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010635697839156,
          "to_latitude": 21.01064551304809,
          "to_longitude": 105.82521305893444,
          "from_longitude": 105.82523556261378
        }
      },
      {
        "id": 4307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82522566334218,
            21.010495155999912,
            105.82546419055774,
            21.01085296306213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82535336971728,
              21.01085296306213
            ],
            [
              105.82530456085563,
              21.01076207958395
            ],
            [
              105.82529306720225,
              21.01073885375865
            ],
            [
              105.82529353620585,
              21.010731085540993
            ],
            [
              105.82525428155618,
              21.010667045668118
            ],
            [
              105.82523686940458,
              21.010638590859287
            ],
            [
              105.82523556261378,
              21.010635697839156
            ],
            [
              105.82522566334218,
              21.01061378389029
            ],
            [
              105.82522875495448,
              21.010606796890148
            ],
            [
              105.82528229874883,
              21.01058288531816
            ],
            [
              105.82546419055774,
              21.010495155999912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4314",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01085296306213,
          "to_latitude": 21.010495155999912,
          "to_longitude": 105.82546419055774,
          "from_longitude": 105.82535336971728
        }
      },
      {
        "id": 4308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82533691452669,
            21.010738314151496,
            105.82563592344898,
            21.010879921095068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82533691452669,
              21.010879921095068
            ],
            [
              105.82536401730158,
              21.01086279632877
            ],
            [
              105.82537541909383,
              21.010860577796084
            ],
            [
              105.82545181173299,
              21.010827332490916
            ],
            [
              105.82550984047583,
              21.010798091730415
            ],
            [
              105.82556286723246,
              21.01077373728758
            ],
            [
              105.82563592344898,
              21.010738314151496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4315",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010879921095068,
          "to_latitude": 21.010738314151496,
          "to_longitude": 105.82563592344898,
          "from_longitude": 105.82533691452669
        }
      },
      {
        "id": 4309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82561003827828,
            21.010877059741386,
            105.82564048418803,
            21.010949003047383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82561003827828,
              21.010877059741386
            ],
            [
              105.82564048418803,
              21.010949003047383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4316",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010877059741386,
          "to_latitude": 21.010949003047383,
          "to_longitude": 105.82564048418803,
          "from_longitude": 105.82561003827828
        }
      },
      {
        "id": 4310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8253438752694,
            21.01079329005709,
            105.82576936323599,
            21.01089170515038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8253438752694,
              21.01089170515038
            ],
            [
              105.82536461644561,
              21.01087553795063
            ],
            [
              105.8254062999581,
              21.010857390452188
            ],
            [
              105.82546488963696,
              21.01083289253832
            ],
            [
              105.82550512087226,
              21.01081478638397
            ],
            [
              105.82556180802892,
              21.01079329005709
            ],
            [
              105.82559278329862,
              21.010872289521107
            ],
            [
              105.82559731748974,
              21.01088220933712
            ],
            [
              105.82561003827828,
              21.010877059741386
            ],
            [
              105.82564607379008,
              21.010862472108
            ],
            [
              105.82569219025294,
              21.010843894772663
            ],
            [
              105.82570579082419,
              21.01087346183431
            ],
            [
              105.82576936323599,
              21.010839194726376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4317",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01089170515038,
          "to_latitude": 21.010839194726376,
          "to_longitude": 105.82576936323599,
          "from_longitude": 105.8253438752694
        }
      },
      {
        "id": 4311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552317104557,
            21.011075132866686,
            105.82573437092944,
            21.011182250626174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552317104557,
              21.011182250626174
            ],
            [
              105.82553921596754,
              21.011174257808857
            ],
            [
              105.82554788306703,
              21.011169940787934
            ],
            [
              105.82555905341164,
              21.01116918478595
            ],
            [
              105.82556729294761,
              21.011168627261085
            ],
            [
              105.82563468509025,
              21.011136077669278
            ],
            [
              105.82573437092944,
              21.011075132866686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4318",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011182250626174,
          "to_latitude": 21.011075132866686,
          "to_longitude": 105.82573437092944,
          "from_longitude": 105.82552317104557
        }
      },
      {
        "id": 4312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552957605046,
            21.01117360907802,
            105.82556178649456,
            21.011191626657066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556178649456,
              21.01117360907802
            ],
            [
              105.82555581985723,
              21.011176946545405
            ],
            [
              105.82553860847916,
              21.01118657444421
            ],
            [
              105.82552957605046,
              21.011191626657066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4319",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01117360907802,
          "to_latitude": 21.011191626657066,
          "to_longitude": 105.82552957605046,
          "from_longitude": 105.82556178649456
        }
      },
      {
        "id": 4313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82538509851655,
            21.010890955131377,
            105.82568306294,
            21.01135937253072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568306294,
              21.01135937253072
            ],
            [
              105.82565776055867,
              21.011332404834103
            ],
            [
              105.82562238567579,
              21.011271813720303
            ],
            [
              105.8256027455838,
              21.011241608473206
            ],
            [
              105.82556269105167,
              21.01117507333578
            ],
            [
              105.82556178649456,
              21.01117360907802
            ],
            [
              105.82555905341164,
              21.01116918478595
            ],
            [
              105.82553282516146,
              21.011126730321383
            ],
            [
              105.82550246917145,
              21.011077595742393
            ],
            [
              105.82548305829818,
              21.01106176873501
            ],
            [
              105.82547977017036,
              21.011059087651827
            ],
            [
              105.82545219516854,
              21.010987763551412
            ],
            [
              105.82542160439776,
              21.01096046017906
            ],
            [
              105.82538509851655,
              21.010890955131377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4320",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01135937253072,
          "to_latitude": 21.010890955131377,
          "to_longitude": 105.82538509851655,
          "from_longitude": 105.82568306294
        }
      },
      {
        "id": 4314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256821475738,
            21.01078227525396,
            105.82635358808201,
            21.011401737394117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256821475738,
              21.011401737394117
            ],
            [
              105.82569688204165,
              21.011393507049473
            ],
            [
              105.8257899226642,
              21.011323038321468
            ],
            [
              105.82582974601323,
              21.011298269159745
            ],
            [
              105.82584999489374,
              21.011219229788928
            ],
            [
              105.82595222841444,
              21.01116817547074
            ],
            [
              105.82590980790471,
              21.01109071382946
            ],
            [
              105.82585524189969,
              21.011002854469528
            ],
            [
              105.8258427729001,
              21.010982448775856
            ],
            [
              105.82585174274902,
              21.01096620677681
            ],
            [
              105.82586812037538,
              21.010951560051097
            ],
            [
              105.8258983831079,
              21.01092450063032
            ],
            [
              105.82591751132406,
              21.010896490861157
            ],
            [
              105.82591638020689,
              21.010844435275903
            ],
            [
              105.82601849643078,
              21.010798332980787
            ],
            [
              105.82607230175415,
              21.01078227525396
            ],
            [
              105.82609171537021,
              21.01079021069034
            ],
            [
              105.82611478413753,
              21.0108325431162
            ],
            [
              105.82617324787714,
              21.01092214927534
            ],
            [
              105.82629915108424,
              21.010847927685
            ],
            [
              105.82631520996266,
              21.0108470241533
            ],
            [
              105.82635358808201,
              21.01090534337325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_68_Ngõ 119, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4321",
          "diaChiLapD": "Ngõ 119, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 150,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011401737394117,
          "to_latitude": 21.01090534337325,
          "to_longitude": 105.82635358808201,
          "from_longitude": 105.8256821475738
        }
      },
      {
        "id": 4315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568483538437,
            21.01113748728083,
            105.8260365516645,
            21.01140457583774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568483538437,
              21.01140457583774
            ],
            [
              105.82569825097582,
              21.011395803634308
            ],
            [
              105.8258417115245,
              21.01130199470271
            ],
            [
              105.82585135780575,
              21.01126674011531
            ],
            [
              105.82586208847316,
              21.011227524132085
            ],
            [
              105.8260365516645,
              21.01113748728083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 119, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4322",
          "diaChiLapD": "Ngõ 119, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01140457583774,
          "to_latitude": 21.01113748728083,
          "to_longitude": 105.8260365516645,
          "from_longitude": 105.82568483538437
        }
      },
      {
        "id": 4316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82582450980779,
            21.011601728290742,
            105.82585982937745,
            21.011620588372104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82585982937745,
              21.011601728290742
            ],
            [
              105.82585835525336,
              21.011602515833193
            ],
            [
              105.82584160948512,
              21.011611457760676
            ],
            [
              105.82582486467179,
              21.011620398778728
            ],
            [
              105.82582450980779,
              21.011620588372104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4323",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011601728290742,
          "to_latitude": 21.011620588372104,
          "to_longitude": 105.82582450980779,
          "from_longitude": 105.82585982937745
        }
      },
      {
        "id": 4317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82571661405419,
            21.011409821117176,
            105.82603659408294,
            21.011878429940964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82603659408294,
              21.011878429940964
            ],
            [
              105.82602529827292,
              21.01185623289138
            ],
            [
              105.82602239390303,
              21.01185547021613
            ],
            [
              105.82601432138416,
              21.01185334888684
            ],
            [
              105.82594478709714,
              21.01172677498186
            ],
            [
              105.82590879753803,
              21.01166132756152
            ],
            [
              105.82588924678585,
              21.011655234452377
            ],
            [
              105.82587971012813,
              21.01163788911469
            ],
            [
              105.82586146270637,
              21.011604699204238
            ],
            [
              105.82585982937745,
              21.011601728290742
            ],
            [
              105.82585562508746,
              21.0115940812068
            ],
            [
              105.82584253269812,
              21.011578914545495
            ],
            [
              105.82582850902052,
              21.011562670337643
            ],
            [
              105.82576829838204,
              21.011474636030638
            ],
            [
              105.82574075216206,
              21.011447205043105
            ],
            [
              105.82571661405419,
              21.011409821117176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4324",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011878429940964,
          "to_latitude": 21.011409821117176,
          "to_longitude": 105.82571661405419,
          "from_longitude": 105.82603659408294
        }
      },
      {
        "id": 4318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82614939355582,
            21.012112372454652,
            105.8261726600266,
            21.012126234335327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8261726600266,
              21.012112372454652
            ],
            [
              105.82616670510684,
              21.012115920338623
            ],
            [
              105.82615520466044,
              21.01212277205351
            ],
            [
              105.82614939355582,
              21.012126234335327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4325",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012112372454652,
          "to_latitude": 21.012126234335327,
          "to_longitude": 105.82614939355582,
          "from_longitude": 105.8261726600266
        }
      },
      {
        "id": 4319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8260512113572,
            21.01190481448062,
            105.82628256523348,
            21.012289395915214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82628256523348,
              21.012289395915214
            ],
            [
              105.82625666630949,
              21.012246904753912
            ],
            [
              105.82626531685418,
              21.012222633699572
            ],
            [
              105.82623599082537,
              21.012168998008285
            ],
            [
              105.8262289296334,
              21.012159259387122
            ],
            [
              105.82620195731558,
              21.012162193069504
            ],
            [
              105.82617835295218,
              21.01212116210853
            ],
            [
              105.82617748950372,
              21.012119828622755
            ],
            [
              105.82617651643159,
              21.012118326765922
            ],
            [
              105.8261726600266,
              21.012112372454652
            ],
            [
              105.82616226859487,
              21.0120963283189
            ],
            [
              105.8261481022425,
              21.012074453216897
            ],
            [
              105.82610462970081,
              21.012003927206518
            ],
            [
              105.8260807327656,
              21.011968100234384
            ],
            [
              105.82607042506596,
              21.01193825980538
            ],
            [
              105.8260512113572,
              21.01190481448062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4326",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012289395915214,
          "to_latitude": 21.01190481448062,
          "to_longitude": 105.8260512113572,
          "from_longitude": 105.82628256523348
        }
      },
      {
        "id": 4320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82627053810765,
            21.01230760406544,
            105.82628851493615,
            21.012316939498326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82628851493615,
              21.01230760406544
            ],
            [
              105.82628301932854,
              21.012308095914758
            ],
            [
              105.82627053810765,
              21.012316939498326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4327",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01230760406544,
          "to_latitude": 21.012316939498326,
          "to_longitude": 105.82627053810765,
          "from_longitude": 105.82628851493615
        }
      },
      {
        "id": 4321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82628851493615,
            21.012149625659667,
            105.82651847671949,
            21.01236057643577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631214569501,
              21.01236057643577
            ],
            [
              105.8262946338347,
              21.012334525721446
            ],
            [
              105.82628851493615,
              21.01230760406544
            ],
            [
              105.82636396994485,
              21.012268197471432
            ],
            [
              105.82641123009083,
              21.012240648052227
            ],
            [
              105.82641922110953,
              21.012226747425842
            ],
            [
              105.82651844816579,
              21.01217377536047
            ],
            [
              105.82651847671949,
              21.012149625659667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4328",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01236057643577,
          "to_latitude": 21.012149625659667,
          "to_longitude": 105.82651847671949,
          "from_longitude": 105.82631214569501
        }
      },
      {
        "id": 4322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82655501620575,
            21.012256677384876,
            105.82670240606394,
            21.012378382817626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82655501620575,
              21.012256677384876
            ],
            [
              105.82658340631953,
              21.012296502717394
            ],
            [
              105.82661422220659,
              21.0123123753439
            ],
            [
              105.8266434991077,
              21.012326515827656
            ],
            [
              105.82667282433403,
              21.012360771823758
            ],
            [
              105.82670240606394,
              21.012378382817626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77_Ngõ 61, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4329",
          "diaChiLapD": "Ngõ 61, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012256677384876,
          "to_latitude": 21.012378382817626,
          "to_longitude": 105.82670240606394,
          "from_longitude": 105.82655501620575
        }
      },
      {
        "id": 4323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8263021876312,
            21.011858711755703,
            105.82662188972796,
            21.012371524930828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8263021876312,
              21.012371524930828
            ],
            [
              105.82631749503692,
              21.012364091104565
            ],
            [
              105.82638585256373,
              21.012327391615166
            ],
            [
              105.82645338266023,
              21.012292935216113
            ],
            [
              105.82653081739862,
              21.012254954160163
            ],
            [
              105.82662188972796,
              21.012204677132864
            ],
            [
              105.82640933106168,
              21.011858711755703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76_Ngõ 61, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4330",
          "diaChiLapD": "Ngõ 61, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012371524930828,
          "to_latitude": 21.011858711755703,
          "to_longitude": 105.82640933106168,
          "from_longitude": 105.8263021876312
        }
      },
      {
        "id": 4324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82630691126357,
            21.01178259746103,
            105.82673576210131,
            21.01237967077033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82630691126357,
              21.01237967077033
            ],
            [
              105.82632275654605,
              21.01237279071889
            ],
            [
              105.8263652754722,
              21.012354327309886
            ],
            [
              105.82642701752243,
              21.012322626774907
            ],
            [
              105.82655205156072,
              21.012258245335666
            ],
            [
              105.82655501620575,
              21.012256677384876
            ],
            [
              105.82665075383785,
              21.012206054324356
            ],
            [
              105.82667730189269,
              21.012192811400794
            ],
            [
              105.82672091838215,
              21.012171052119363
            ],
            [
              105.82673576210131,
              21.012150352115274
            ],
            [
              105.82673108985426,
              21.012128318030882
            ],
            [
              105.82671955367509,
              21.012104068262797
            ],
            [
              105.82653547362321,
              21.01178259746103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77_Ngõ 61, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4331",
          "diaChiLapD": "Ngõ 61, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 212,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01237967077033,
          "to_latitude": 21.01178259746103,
          "to_longitude": 105.82653547362321,
          "from_longitude": 105.82630691126357
        }
      },
      {
        "id": 4325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82645043318321,
            21.012401384456453,
            105.82669038902092,
            21.012612603136493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82645043318321,
              21.012612603136493
            ],
            [
              105.8264653761053,
              21.01260430848592
            ],
            [
              105.82647105000802,
              21.012600908279005
            ],
            [
              105.826571104488,
              21.012540943897996
            ],
            [
              105.82662623125033,
              21.01251112613741
            ],
            [
              105.82664172089456,
              21.01247234944541
            ],
            [
              105.82669038902092,
              21.012443353365846
            ],
            [
              105.82666279216298,
              21.012401384456453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4332",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012612603136493,
          "to_latitude": 21.012401384456453,
          "to_longitude": 105.82666279216298,
          "from_longitude": 105.82645043318321
        }
      },
      {
        "id": 4326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82672586151797,
            21.012841803937395,
            105.82721994204611,
            21.0130661216896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82672586151797,
              21.0130661216896
            ],
            [
              105.8267363439765,
              21.013053801331242
            ],
            [
              105.82673795418,
              21.013053093506976
            ],
            [
              105.82684683293432,
              21.01300524170205
            ],
            [
              105.82697973522306,
              21.012938652156425
            ],
            [
              105.82699782036168,
              21.012929590702694
            ],
            [
              105.82703563151988,
              21.012917373235407
            ],
            [
              105.82706828179201,
              21.012939146665786
            ],
            [
              105.8271883885154,
              21.01287588251458
            ],
            [
              105.8272199229491,
              21.01285822739094
            ],
            [
              105.82721994204611,
              21.012841803937395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_205_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4333",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0130661216896,
          "to_latitude": 21.012841803937395,
          "to_longitude": 105.82721994204611,
          "from_longitude": 105.82672586151797
        }
      },
      {
        "id": 4327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82644886764805,
            21.012599670820855,
            105.8264703140564,
            21.01261006906189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8264703140564,
              21.012599670820855
            ],
            [
              105.82646420391674,
              21.012602633275478
            ],
            [
              105.82645307154743,
              21.012608030835086
            ],
            [
              105.82644886764805,
              21.01261006906189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4334",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012599670820855,
          "to_latitude": 21.01261006906189,
          "to_longitude": 105.82644886764805,
          "from_longitude": 105.8264703140564
        }
      },
      {
        "id": 4328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82634775955977,
            21.01238848930087,
            105.82665434768512,
            21.012687603436135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82665434768512,
              21.012621199399934
            ],
            [
              105.82651793156872,
              21.012687603436135
            ],
            [
              105.82648116798879,
              21.01261793914118
            ],
            [
              105.82647105000802,
              21.012600908279005
            ],
            [
              105.8264703140564,
              21.012599670820855
            ],
            [
              105.82646690611621,
              21.0125939338229
            ],
            [
              105.82644374478399,
              21.012554949270037
            ],
            [
              105.82641065093533,
              21.012499244559432
            ],
            [
              105.82638552008437,
              21.012458946336704
            ],
            [
              105.82634775955977,
              21.01238848930087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4335",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012621199399934,
          "to_latitude": 21.01238848930087,
          "to_longitude": 105.82634775955977,
          "from_longitude": 105.82665434768512
        }
      },
      {
        "id": 4329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82665561029187,
            21.01256544162531,
            105.82674519539796,
            21.012733130180642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82674519539796,
              21.012733130180642
            ],
            [
              105.82672055138654,
              21.012696715325824
            ],
            [
              105.82667213326697,
              21.012613814017865
            ],
            [
              105.82667154649255,
              21.012590771051016
            ],
            [
              105.82665561029187,
              21.01256544162531
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4336",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012733130180642,
          "to_latitude": 21.01256544162531,
          "to_longitude": 105.82665561029187,
          "from_longitude": 105.82674519539796
        }
      },
      {
        "id": 4330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82657905162209,
            21.012529842331038,
            105.82691468353232,
            21.01282092981752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82657905162209,
              21.01282092981752
            ],
            [
              105.82659080566478,
              21.01281121542084
            ],
            [
              105.82659550789134,
              21.01280883696293
            ],
            [
              105.82674519539796,
              21.012733130180642
            ],
            [
              105.82691468353232,
              21.012649512328075
            ],
            [
              105.82686082227444,
              21.012574196629437
            ],
            [
              105.82682876428761,
              21.012529842331038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4337",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01282092981752,
          "to_latitude": 21.012529842331038,
          "to_longitude": 105.82682876428761,
          "from_longitude": 105.82657905162209
        }
      },
      {
        "id": 4331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265868786545,
            21.012596233972175,
            105.82717291295165,
            21.01283400302558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265868786545,
              21.01283400302558
            ],
            [
              105.8265979543325,
              21.012823726504294
            ],
            [
              105.82660464491623,
              21.012820487547316
            ],
            [
              105.82668498374112,
              21.012781601727276
            ],
            [
              105.82699395857486,
              21.012621145992206
            ],
            [
              105.82704193063908,
              21.012596233972175
            ],
            [
              105.82708475314375,
              21.012670352301537
            ],
            [
              105.82709730209919,
              21.01267100665623
            ],
            [
              105.8271255939548,
              21.012657902411384
            ],
            [
              105.82717291295165,
              21.01275006609055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4338",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01283400302558,
          "to_latitude": 21.01275006609055,
          "to_longitude": 105.82717291295165,
          "from_longitude": 105.8265868786545
        }
      },
      {
        "id": 4332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82653070791841,
            21.012719578808337,
            105.82673435416588,
            21.013049162094486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82673435416588,
              21.013049162094486
            ],
            [
              105.82670214411007,
              21.012991015348028
            ],
            [
              105.82666166568417,
              21.01292314308481
            ],
            [
              105.82664038025584,
              21.012887522998888
            ],
            [
              105.82663651473631,
              21.012880270807933
            ],
            [
              105.82660464491623,
              21.012820487547316
            ],
            [
              105.82660296342063,
              21.012817334424472
            ],
            [
              105.82659550789134,
              21.01280883696293
            ],
            [
              105.82657006305725,
              21.012779833334896
            ],
            [
              105.82655920030167,
              21.012762586612983
            ],
            [
              105.82653070791841,
              21.012719578808337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4339",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013049162094486,
          "to_latitude": 21.012719578808337,
          "to_longitude": 105.82653070791841,
          "from_longitude": 105.82673435416588
        }
      },
      {
        "id": 4333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150987885418,
            21.006635219691418,
            105.83152277993871,
            21.006648695631107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83150987885418,
              21.006648695631107
            ],
            [
              105.83152277993871,
              21.006635219691418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4340",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006648695631107,
          "to_latitude": 21.006635219691418,
          "to_longitude": 105.83152277993871,
          "from_longitude": 105.83150987885418
        }
      },
      {
        "id": 4334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189074035224,
            21.006965537414818,
            105.83191462632074,
            21.006991469274805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189074035224,
              21.006991469274805
            ],
            [
              105.83191462632074,
              21.006965537414818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4341",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006991469274805,
          "to_latitude": 21.006965537414818,
          "to_longitude": 105.83191462632074,
          "from_longitude": 105.83189074035224
        }
      },
      {
        "id": 4335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82920899024103,
            21.00720068438693,
            105.8292352695879,
            21.007248850951655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920899024103,
              21.00720068438693
            ],
            [
              105.8292352695879,
              21.007248850951655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4342",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00720068438693,
          "to_latitude": 21.007248850951655,
          "to_longitude": 105.8292352695879,
          "from_longitude": 105.82920899024103
        }
      },
      {
        "id": 4336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961779273559,
            21.007027756460214,
            105.82965975863303,
            21.00710185850249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82961779273559,
              21.007027756460214
            ],
            [
              105.82965975863303,
              21.00710185850249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4343",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007027756460214,
          "to_latitude": 21.00710185850249,
          "to_longitude": 105.82965975863303,
          "from_longitude": 105.82961779273559
        }
      },
      {
        "id": 4337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83080812532023,
            21.006455357724086,
            105.83084310997799,
            21.006518107478254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83080812532023,
              21.006455357724086
            ],
            [
              105.83084310997799,
              21.006518107478254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4344",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006455357724086,
          "to_latitude": 21.006518107478254,
          "to_longitude": 105.83084310997799,
          "from_longitude": 105.83080812532023
        }
      },
      {
        "id": 4338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8312844888106,
            21.006492851232295,
            105.83170502337815,
            21.006813454239534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83170502337815,
              21.006813454239534
            ],
            [
              105.83166244833684,
              21.006776261516823
            ],
            [
              105.83162395275275,
              21.00674481504324
            ],
            [
              105.8315652765679,
              21.006692991936713
            ],
            [
              105.83150987885418,
              21.006648695631107
            ],
            [
              105.83149200586327,
              21.006634405402483
            ],
            [
              105.83141903690043,
              21.006575235683
            ],
            [
              105.83140094222023,
              21.006571971304574
            ],
            [
              105.8312844888106,
              21.006492851232295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4345",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006813454239534,
          "to_latitude": 21.006492851232295,
          "to_longitude": 105.8312844888106,
          "from_longitude": 105.83170502337815
        }
      },
      {
        "id": 4339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83173211554885,
            21.006824706097927,
            105.83195456928358,
            21.007296268760918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83180212938333,
              21.007296268760918
            ],
            [
              105.83195456928358,
              21.007111425402584
            ],
            [
              105.83190887152982,
              21.007008073164666
            ],
            [
              105.83189074035224,
              21.006991469274805
            ],
            [
              105.83187535100036,
              21.006977377400478
            ],
            [
              105.83187771347501,
              21.00694529410579
            ],
            [
              105.83179879750126,
              21.006877903563076
            ],
            [
              105.83173211554885,
              21.006824706097927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.4346",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007296268760918,
          "to_latitude": 21.006824706097927,
          "to_longitude": 105.83173211554885,
          "from_longitude": 105.83180212938333
        }
      },
      {
        "id": 4340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82727911349433,
            21.008091653824664,
            105.82728888725879,
            21.008113986460653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82728888725879,
              21.008113986460653
            ],
            [
              105.8272832264104,
              21.008101052677826
            ],
            [
              105.82727972724659,
              21.008093061681034
            ],
            [
              105.82727911349433,
              21.008091653824664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4347",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008113986460653,
          "to_latitude": 21.008091653824664,
          "to_longitude": 105.82727911349433,
          "from_longitude": 105.82728888725879
        }
      },
      {
        "id": 4341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82718540055963,
            21.007789233151282,
            105.82804299980224,
            21.008161123100002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718540055963,
              21.008161123100002
            ],
            [
              105.82728888725879,
              21.008113986460653
            ],
            [
              105.8280018687692,
              21.007789233151282
            ],
            [
              105.82804299980224,
              21.007862923277035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4348",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008161123100002,
          "to_latitude": 21.007862923277035,
          "to_longitude": 105.82804299980224,
          "from_longitude": 105.82718540055963
        }
      },
      {
        "id": 4342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82675406995044,
            21.008334334516174,
            105.82677635555294,
            21.008376149512625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82675406995044,
              21.008334334516174
            ],
            [
              105.82677635555294,
              21.008376149512625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4349",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008334334516174,
          "to_latitude": 21.008376149512625,
          "to_longitude": 105.82677635555294,
          "from_longitude": 105.82675406995044
        }
      },
      {
        "id": 4343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8248354948455,
            21.009182138111683,
            105.8248523918958,
            21.009216499864344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8248523918958,
              21.009216499864344
            ],
            [
              105.82484759613031,
              21.009206747485436
            ],
            [
              105.82483881881856,
              21.009188899814824
            ],
            [
              105.8248354948455,
              21.009182138111683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4350",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009216499864344,
          "to_latitude": 21.009182138111683,
          "to_longitude": 105.8248354948455,
          "from_longitude": 105.8248523918958
        }
      },
      {
        "id": 4344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82534625668292,
            21.008945592209823,
            105.82536384416163,
            21.008984096372686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82536384416163,
              21.008984096372686
            ],
            [
              105.82534625668292,
              21.008945592209823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4351",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008984096372686,
          "to_latitude": 21.008945592209823,
          "to_longitude": 105.82534625668292,
          "from_longitude": 105.82536384416163
        }
      },
      {
        "id": 4345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82625419081494,
            21.008533083890402,
            105.82630563172731,
            21.008596255727404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82625419081494,
              21.008596255727404
            ],
            [
              105.82630563172731,
              21.008549064361628
            ],
            [
              105.82629831125467,
              21.008533083890402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4352",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008596255727404,
          "to_latitude": 21.008533083890402,
          "to_longitude": 105.82629831125467,
          "from_longitude": 105.82625419081494
        }
      },
      {
        "id": 4346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82645861226075,
            21.00846177248987,
            105.82647306437588,
            21.00849856388792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82647306437588,
              21.00849856388792
            ],
            [
              105.82646586659479,
              21.008474474562206
            ],
            [
              105.82645861226075,
              21.00846177248987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4353",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00849856388792,
          "to_latitude": 21.00846177248987,
          "to_longitude": 105.82645861226075,
          "from_longitude": 105.82647306437588
        }
      },
      {
        "id": 4347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270984789114,
            21.00817151178093,
            105.82725661174054,
            21.008677250907624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270984789114,
              21.00817151178093
            ],
            [
              105.8271037306742,
              21.008180383038592
            ],
            [
              105.82712325018993,
              21.008213341746593
            ],
            [
              105.82721863471875,
              21.008295106061414
            ],
            [
              105.82725066605856,
              21.008333557873954
            ],
            [
              105.82725661174054,
              21.008351745245022
            ],
            [
              105.82723863500769,
              21.008413345072167
            ],
            [
              105.82718910263829,
              21.008485451971858
            ],
            [
              105.8271428350444,
              21.008539789167557
            ],
            [
              105.82714730870333,
              21.00856978344907
            ],
            [
              105.82721648411443,
              21.008677250907624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 14, Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4354",
          "diaChiLapD": "Ngõ 14, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00817151178093,
          "to_latitude": 21.008677250907624,
          "to_longitude": 105.82721648411443,
          "from_longitude": 105.8270984789114
        }
      },
      {
        "id": 4348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82626972559068,
            21.00849856388792,
            105.82658608299442,
            21.008718406163332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82626972559068,
              21.008595244557586
            ],
            [
              105.82632436040159,
              21.00857205899338
            ],
            [
              105.82637868456966,
              21.008547662790292
            ],
            [
              105.8264227503964,
              21.00852610826184
            ],
            [
              105.82647306437588,
              21.00849856388792
            ],
            [
              105.82655777305258,
              21.008619332074094
            ],
            [
              105.82656334087167,
              21.00862656065045
            ],
            [
              105.82658608299442,
              21.00865608505446
            ],
            [
              105.82657579569064,
              21.00868212898279
            ],
            [
              105.8265244736148,
              21.008718406163332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4355",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008595244557586,
          "to_latitude": 21.008718406163332,
          "to_longitude": 105.8265244736148,
          "from_longitude": 105.82626972559068
        }
      },
      {
        "id": 4349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8261653359349,
            21.008633639935166,
            105.82623483843565,
            21.008800925351515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8261653359349,
              21.008633639935166
            ],
            [
              105.82619995199083,
              21.008698873622357
            ],
            [
              105.82623483843565,
              21.008780364972562
            ],
            [
              105.82619163081422,
              21.008800925351515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Ngõ 56, Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4356",
          "diaChiLapD": "Ngõ 56, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008633639935166,
          "to_latitude": 21.008800925351515,
          "to_longitude": 105.82619163081422,
          "from_longitude": 105.8261653359349
        }
      },
      {
        "id": 4350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605975418866,
            21.008596255727404,
            105.82633273275572,
            21.00873974483047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605975418866,
              21.008684395372256
            ],
            [
              105.8261552018169,
              21.008637903589978
            ],
            [
              105.8261653359349,
              21.008633639935166
            ],
            [
              105.82625419081494,
              21.008596255727404
            ],
            [
              105.82628746939703,
              21.008650574072572
            ],
            [
              105.82633273275572,
              21.00873974483047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4357",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008684395372256,
          "to_latitude": 21.00873974483047,
          "to_longitude": 105.82633273275572,
          "from_longitude": 105.82605975418866
        }
      },
      {
        "id": 4351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82551607832305,
            21.00865114638152,
            105.82604291319906,
            21.008916388192766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551607832305,
              21.008916388192766
            ],
            [
              105.82556498593136,
              21.00889821465504
            ],
            [
              105.82561591440168,
              21.008876707424424
            ],
            [
              105.82565949758069,
              21.008853931557947
            ],
            [
              105.82566474553829,
              21.008851633576832
            ],
            [
              105.82567040707727,
              21.008849154749974
            ],
            [
              105.82567183685765,
              21.008848529102426
            ],
            [
              105.82567938013858,
              21.008845226434588
            ],
            [
              105.82575510053626,
              21.00880887898437
            ],
            [
              105.8257832327636,
              21.00880515395261
            ],
            [
              105.82584697679481,
              21.008767798770844
            ],
            [
              105.82591706164247,
              21.008736742605056
            ],
            [
              105.82598923396684,
              21.008707294861324
            ],
            [
              105.82604291319906,
              21.008666719682612
            ],
            [
              105.82603337861507,
              21.00865408556348
            ],
            [
              105.8260311625844,
              21.00865114638152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4358",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008916388192766,
          "to_latitude": 21.00865114638152,
          "to_longitude": 105.8260311625844,
          "from_longitude": 105.82551607832305
        }
      },
      {
        "id": 4352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82565179538105,
            21.008811047589745,
            105.8259094236413,
            21.009011740467944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82565179538105,
              21.008811047589745
            ],
            [
              105.82566265048919,
              21.00882278293555
            ],
            [
              105.82567183685765,
              21.008848529102426
            ],
            [
              105.82567654191179,
              21.008861716868978
            ],
            [
              105.82572594326699,
              21.008953722816926
            ],
            [
              105.82576079652739,
              21.009011740467944
            ],
            [
              105.82580989354278,
              21.008989706452887
            ],
            [
              105.82585677578781,
              21.00896933427092
            ],
            [
              105.8259094236413,
              21.008935684660617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Ngõ 80, Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4359",
          "diaChiLapD": "Ngõ 80, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008811047589745,
          "to_latitude": 21.008935684660617,
          "to_longitude": 105.8259094236413,
          "from_longitude": 105.82565179538105
        }
      },
      {
        "id": 4353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259107696428,
            21.00860274872606,
            105.82615408429153,
            21.008918265722567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8261448602867,
              21.00860274872606
            ],
            [
              105.82615408429153,
              21.008620468609994
            ],
            [
              105.82604449721816,
              21.008681007363595
            ],
            [
              105.82608034944137,
              21.00874207932919
            ],
            [
              105.8260989431465,
              21.008771135292175
            ],
            [
              105.82608906601979,
              21.00878469099569
            ],
            [
              105.82605098953108,
              21.00881196540572
            ],
            [
              105.82603793095385,
              21.008836096173535
            ],
            [
              105.8260195963832,
              21.008854286737396
            ],
            [
              105.82598964675938,
              21.00886720463923
            ],
            [
              105.82594698553537,
              21.008895122700217
            ],
            [
              105.8259107696428,
              21.008918265722567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 60, Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4360",
          "diaChiLapD": "Ngõ 60, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00860274872606,
          "to_latitude": 21.008918265722567,
          "to_longitude": 105.8259107696428,
          "from_longitude": 105.8261448602867
        }
      },
      {
        "id": 4354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496784280701,
            21.00941161967159,
            105.82505375489814,
            21.009451757813196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82496784280701,
              21.009450959845612
            ],
            [
              105.8249772584118,
              21.009451757813196
            ],
            [
              105.82505375489814,
              21.00941161967159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4361",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009450959845612,
          "to_latitude": 21.00941161967159,
          "to_longitude": 105.82505375489814,
          "from_longitude": 105.82496784280701
        }
      },
      {
        "id": 4355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82469473665083,
            21.00925275436198,
            105.82534551266464,
            21.009629813772115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82469473665083,
              21.009629813772115
            ],
            [
              105.82470166620607,
              21.00962544379667
            ],
            [
              105.8247138354302,
              21.00961726029367
            ],
            [
              105.82473087694873,
              21.00960580155926
            ],
            [
              105.82479161628608,
              21.00957661113786
            ],
            [
              105.82488972380081,
              21.009530509990185
            ],
            [
              105.82493054626646,
              21.009503818649293
            ],
            [
              105.82494167995132,
              21.009476471869956
            ],
            [
              105.8249676717819,
              21.009453794097393
            ],
            [
              105.82496784280701,
              21.009450959845612
            ],
            [
              105.82497003207615,
              21.009414743383317
            ],
            [
              105.82496267772223,
              21.00937613808775
            ],
            [
              105.8250510668693,
              21.009334386936928
            ],
            [
              105.82512276564078,
              21.00932736445243
            ],
            [
              105.82517514690751,
              21.00932546839162
            ],
            [
              105.82518096123408,
              21.009324181099004
            ],
            [
              105.82522834917117,
              21.009313694257635
            ],
            [
              105.82534551266464,
              21.00925275436198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4362",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009629813772115,
          "to_latitude": 21.00925275436198,
          "to_longitude": 105.82534551266464,
          "from_longitude": 105.82469473665083
        }
      },
      {
        "id": 4356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535987277961,
            21.008814493435843,
            105.82574849501592,
            21.00924032947379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82564396913014,
              21.008814493435843
            ],
            [
              105.82566474553829,
              21.008851633576832
            ],
            [
              105.82571676674687,
              21.008944626688596
            ],
            [
              105.82574849501592,
              21.00900023614806
            ],
            [
              105.82569002613836,
              21.00903850866878
            ],
            [
              105.8255703050096,
              21.00911461012248
            ],
            [
              105.82541217760388,
              21.009205834449578
            ],
            [
              105.82535987277961,
              21.00924032947379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Ngõ 80, Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4363",
          "diaChiLapD": "Ngõ 80, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008814493435843,
          "to_latitude": 21.00924032947379,
          "to_longitude": 105.82535987277961,
          "from_longitude": 105.82564396913014
        }
      },
      {
        "id": 4357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82504927315661,
            21.008918772801753,
            105.82555228844363,
            21.009127657841116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504927315661,
              21.009127657841116
            ],
            [
              105.82508683931248,
              21.009109684843956
            ],
            [
              105.82509256346401,
              21.009103195404954
            ],
            [
              105.82520967052842,
              21.009050728146043
            ],
            [
              105.82523335273174,
              21.009040117807114
            ],
            [
              105.82526495617846,
              21.009030562858165
            ],
            [
              105.82536384416163,
              21.008984096372686
            ],
            [
              105.82539271681745,
              21.008970528963197
            ],
            [
              105.82550395713398,
              21.008918772801753
            ],
            [
              105.82555228844363,
              21.00900442437279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4364",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009127657841116,
          "to_latitude": 21.00900442437279,
          "to_longitude": 105.82555228844363,
          "from_longitude": 105.82504927315661
        }
      },
      {
        "id": 4358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82463426246974,
            21.009137920976926,
            105.82505099751889,
            21.00930716437785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82463426246974,
              21.00930716437785
            ],
            [
              105.82468337761179,
              21.009288029048623
            ],
            [
              105.82476676002891,
              21.009253612123388
            ],
            [
              105.82480503844593,
              21.009239209803674
            ],
            [
              105.8248169039144,
              21.009233519769285
            ],
            [
              105.8248523918958,
              21.009216499864344
            ],
            [
              105.82491413679534,
              21.00918688633482
            ],
            [
              105.82493088085309,
              21.00919131768793
            ],
            [
              105.82505099751889,
              21.009137920976926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4365",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00930716437785,
          "to_latitude": 21.009137920976926,
          "to_longitude": 105.82505099751889,
          "from_longitude": 105.82463426246974
        }
      },
      {
        "id": 4359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781926530876,
            21.008921905581808,
            105.82826393194794,
            21.009736000413547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82784456994065,
              21.008921905581808
            ],
            [
              105.82784530114452,
              21.008923330025684
            ],
            [
              105.82784673674438,
              21.008926550269905
            ],
            [
              105.82781926530876,
              21.008940723660608
            ],
            [
              105.8278246730723,
              21.008952228845885
            ],
            [
              105.82783305858983,
              21.00897013516251
            ],
            [
              105.82786484085867,
              21.009030160719274
            ],
            [
              105.82801753879113,
              21.009315830879636
            ],
            [
              105.82817581111425,
              21.009623384211427
            ],
            [
              105.82818965859673,
              21.00963366237194
            ],
            [
              105.82821075960993,
              21.009649314650684
            ],
            [
              105.82826393194794,
              21.009736000413547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Chùa Bộc",
          "maTaiSan": "04.O16B.DODV.4366",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008921905581808,
          "to_latitude": 21.009736000413547,
          "to_longitude": 105.82826393194794,
          "from_longitude": 105.82784456994065
        }
      },
      {
        "id": 4360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82685878291349,
            21.0132272354587,
            105.82694791917989,
            21.01338510347417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694791917989,
              21.01338510347417
            ],
            [
              105.82690473702122,
              21.013313125707693
            ],
            [
              105.82690118660807,
              21.013307200638017
            ],
            [
              105.82685878291349,
              21.0132272354587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_206_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4367",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01338510347417,
          "to_latitude": 21.0132272354587,
          "to_longitude": 105.82685878291349,
          "from_longitude": 105.82694791917989
        }
      },
      {
        "id": 4361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82673795418,
            21.013053093506976,
            105.82684492872099,
            21.013223639300584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82684492872099,
              21.013223639300584
            ],
            [
              105.82683764020321,
              21.01321281342559
            ],
            [
              105.8268015247746,
              21.01315643161585
            ],
            [
              105.82675425828616,
              21.013081459586907
            ],
            [
              105.82674104950286,
              21.013058478856188
            ],
            [
              105.82673795418,
              21.013053093506976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_205_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4368",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013223639300584,
          "to_latitude": 21.013053093506976,
          "to_longitude": 105.82673795418,
          "from_longitude": 105.82684492872099
        }
      },
      {
        "id": 4362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280565953403,
            21.01515545681993,
            105.82819357367738,
            21.015231642272244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280565953403,
              21.015231642272244
            ],
            [
              105.82819357367738,
              21.01515545681993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4369",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015231642272244,
          "to_latitude": 21.01515545681993,
          "to_longitude": 105.82819357367738,
          "from_longitude": 105.8280565953403
        }
      },
      {
        "id": 4363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82812771482301,
            21.015050220121854,
            105.82819357367738,
            21.01515545681993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819357367738,
              21.01515545681993
            ],
            [
              105.82812771482301,
              21.015050220121854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.4370",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01515545681993,
          "to_latitude": 21.015050220121854,
          "to_longitude": 105.82812771482301,
          "from_longitude": 105.82819357367738
        }
      },
      {
        "id": 4364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83103027968266,
            21.015577417644234,
            105.83106451180146,
            21.015599006653296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83106451180146,
              21.015577417644234
            ],
            [
              105.83104431244303,
              21.01559015604828
            ],
            [
              105.83103027968266,
              21.015599006653296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_163_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.4371",
          "diaChiLapD": "Ngách 106, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015577417644234,
          "to_latitude": 21.015599006653296,
          "to_longitude": 105.83103027968266,
          "from_longitude": 105.83106451180146
        }
      },
      {
        "id": 4365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83384548003433,
            21.011652758166004,
            105.83416751914841,
            21.011870484461884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386622201631,
              21.011652758166004
            ],
            [
              105.83386845076498,
              21.011654142726517
            ],
            [
              105.8338799882935,
              21.011661303856712
            ],
            [
              105.83384548003433,
              21.01175343122036
            ],
            [
              105.8338508880325,
              21.011766780578874
            ],
            [
              105.83400420076443,
              21.011870484461884
            ],
            [
              105.83404856059828,
              21.011811532107643
            ],
            [
              105.83407937181832,
              21.01182904454588
            ],
            [
              105.83411433784983,
              21.011773255252326
            ],
            [
              105.83416751914841,
              21.01179593104041
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_278_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4372",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011652758166004,
          "to_latitude": 21.01179593104041,
          "to_longitude": 105.83416751914841,
          "from_longitude": 105.83386622201631
        }
      },
      {
        "id": 4366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83011285021777,
            21.017891752249614,
            105.83046247222254,
            21.01866925760249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83023723348862,
              21.01866925760249
            ],
            [
              105.8302132505858,
              21.01864121661238
            ],
            [
              105.83017068740673,
              21.018591436249647
            ],
            [
              105.83011285021777,
              21.018418419417188
            ],
            [
              105.8301207691892,
              21.018385600929832
            ],
            [
              105.83022347517384,
              21.01811850831392
            ],
            [
              105.83031625010945,
              21.018000932506297
            ],
            [
              105.8303236343172,
              21.01799541930731
            ],
            [
              105.83032920504311,
              21.017991259861827
            ],
            [
              105.8303325915421,
              21.017988730560024
            ],
            [
              105.83034156122629,
              21.017982033622463
            ],
            [
              105.83046247222254,
              21.017891752249614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.4373",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 103.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01866925760249,
          "to_latitude": 21.017891752249614,
          "to_longitude": 105.83046247222254,
          "from_longitude": 105.83023723348862
        }
      },
      {
        "id": 4367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135047530129,
            21.012574684804505,
            105.83245884086264,
            21.013491147205503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83135047530129,
              21.013491147205503
            ],
            [
              105.83163801604327,
              21.01320583478126
            ],
            [
              105.8316906668287,
              21.013153958097583
            ],
            [
              105.83179293451232,
              21.01305404286814
            ],
            [
              105.83184963039011,
              21.012997367396938
            ],
            [
              105.83190937927101,
              21.012940676077204
            ],
            [
              105.8320015305659,
              21.012852281151947
            ],
            [
              105.83217261936592,
              21.01267937913831
            ],
            [
              105.83227485893207,
              21.012574684804505
            ],
            [
              105.83245884086264,
              21.012712465972456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_267_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.4374",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013491147205503,
          "to_latitude": 21.012712465972456,
          "to_longitude": 105.83245884086264,
          "from_longitude": 105.83135047530129
        }
      },
      {
        "id": 4368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885553887104,
            21.01228597122568,
            105.82906606321191,
            21.012453630488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82889277210867,
              21.012453630488
            ],
            [
              105.8288944020093,
              21.012452660612297
            ],
            [
              105.82885553887104,
              21.012383933796063
            ],
            [
              105.82900433992295,
              21.012314691062443
            ],
            [
              105.82906606321191,
              21.01228597122568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4375",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012453630488,
          "to_latitude": 21.01228597122568,
          "to_longitude": 105.82906606321191,
          "from_longitude": 105.82889277210867
        }
      },
      {
        "id": 4369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82891591745025,
            21.01249239618754,
            105.8292293144975,
            21.012751989562233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82891591745025,
              21.01249239618754
            ],
            [
              105.82893146844772,
              21.012518450751685
            ],
            [
              105.82894965655011,
              21.012549118789366
            ],
            [
              105.82896605227313,
              21.012577463428098
            ],
            [
              105.82903641262334,
              21.012691629783603
            ],
            [
              105.82907350592839,
              21.012751989562233
            ],
            [
              105.8292293144975,
              21.0126739891271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4376",
          "diaChiLapD": "Ngõ 10, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01249239618754,
          "to_latitude": 21.0126739891271,
          "to_longitude": 105.8292293144975,
          "from_longitude": 105.82891591745025
        }
      },
      {
        "id": 4370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82908474256115,
            21.01276999932591,
            105.82927808740793,
            21.013115591405825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82908474256115,
              21.01276999932591
            ],
            [
              105.82917676458487,
              21.01293098264201
            ],
            [
              105.82918264782573,
              21.012941275132135
            ],
            [
              105.82927808740793,
              21.013115591405825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4377",
          "diaChiLapD": "Ngõ 10, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01276999932591,
          "to_latitude": 21.013115591405825,
          "to_longitude": 105.82927808740793,
          "from_longitude": 105.82908474256115
        }
      },
      {
        "id": 4371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903269726103,
            21.012691629783603,
            105.82903641262334,
            21.012693640402613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903641262334,
              21.012691629783603
            ],
            [
              105.82903269726103,
              21.012693640402613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4378",
          "diaChiLapD": "Ngõ 10, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012691629783603,
          "to_latitude": 21.012693640402613,
          "to_longitude": 105.82903269726103,
          "from_longitude": 105.82903641262334
        }
      },
      {
        "id": 4372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292434362926,
            21.013162610293247,
            105.82943510498318,
            21.013343351999872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292918870618,
              21.013343351999872
            ],
            [
              105.8292434362926,
              21.01325901369984
            ],
            [
              105.8293309019162,
              21.01321689629774
            ],
            [
              105.82943510498318,
              21.013162610293247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4379",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013343351999872,
          "to_latitude": 21.013162610293247,
          "to_longitude": 105.82943510498318,
          "from_longitude": 105.8292918870618
        }
      },
      {
        "id": 4373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946659507837,
            21.013259242068866,
            105.8297738800153,
            21.013431330812917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946659507837,
              21.013431330812917
            ],
            [
              105.82959591283681,
              21.01335890924054
            ],
            [
              105.82965869259851,
              21.013323750355614
            ],
            [
              105.8297738800153,
              21.013259242068866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4380",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013431330812917,
          "to_latitude": 21.013259242068866,
          "to_longitude": 105.8297738800153,
          "from_longitude": 105.82946659507837
        }
      },
      {
        "id": 4374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962055773274,
            21.01335533423576,
            105.82988515385955,
            21.013812458851834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988515385955,
              21.013812458851834
            ],
            [
              105.82962055773274,
              21.01335533423576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4381",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013812458851834,
          "to_latitude": 21.01335533423576,
          "to_longitude": 105.82962055773274,
          "from_longitude": 105.82988515385955
        }
      },
      {
        "id": 4375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82965869259851,
            21.013323750355614,
            105.82966364227977,
            21.013332031379818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965869259851,
              21.013323750355614
            ],
            [
              105.82965999266183,
              21.01332592891911
            ],
            [
              105.8296621966031,
              21.013329613268372
            ],
            [
              105.82966364227977,
              21.013332031379818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4382",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013323750355614,
          "to_latitude": 21.013332031379818,
          "to_longitude": 105.82966364227977,
          "from_longitude": 105.82965869259851
        }
      },
      {
        "id": 4376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82935029930458,
            21.013408329858795,
            105.829584115854,
            21.013719498312604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82935029930458,
              21.013408329858795
            ],
            [
              105.82939481866411,
              21.01347524509699
            ],
            [
              105.82942692857121,
              21.013460444570025
            ],
            [
              105.82946807170232,
              21.013531722567894
            ],
            [
              105.829584115854,
              21.013719498312604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4383",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013408329858795,
          "to_latitude": 21.013719498312604,
          "to_longitude": 105.829584115854,
          "from_longitude": 105.82935029930458
        }
      },
      {
        "id": 4377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959211032805,
            21.01373165829797,
            105.82995285913044,
            21.013858554390495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959211032805,
              21.01373165829797
            ],
            [
              105.82964240061244,
              21.013817380288277
            ],
            [
              105.82966924841459,
              21.01385276781449
            ],
            [
              105.82967484488424,
              21.013854677942835
            ],
            [
              105.82973474415859,
              21.01384948493691
            ],
            [
              105.82983770934692,
              21.013838468320383
            ],
            [
              105.82989787369002,
              21.013823986095957
            ],
            [
              105.82995285913044,
              21.013858554390495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4384",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01373165829797,
          "to_latitude": 21.013858554390495,
          "to_longitude": 105.82995285913044,
          "from_longitude": 105.82959211032805
        }
      },
      {
        "id": 4378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940724676821,
            21.013024294236754,
            105.82947123049325,
            21.01313870012022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82940724676821,
              21.013024294236754
            ],
            [
              105.82947123049325,
              21.01313870012022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4385",
          "diaChiLapD": "Ngõ 10, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013024294236754,
          "to_latitude": 21.01313870012022,
          "to_longitude": 105.82947123049325,
          "from_longitude": 105.82940724676821
        }
      },
      {
        "id": 4379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280533155764,
            21.011438841264486,
            105.82806623598363,
            21.011466548280097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806623598363,
              21.011438841264486
            ],
            [
              105.82805503531763,
              21.01146286016785
            ],
            [
              105.8280540656504,
              21.011464940500318
            ],
            [
              105.8280533155764,
              21.011466548280097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4386",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011438841264486,
          "to_latitude": 21.011466548280097,
          "to_longitude": 105.8280533155764,
          "from_longitude": 105.82806623598363
        }
      },
      {
        "id": 4380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82828682496266,
            21.01155082982457,
            105.82833061988549,
            21.01164943504521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833061988549,
              21.01155082982457
            ],
            [
              105.82828774846716,
              21.011647356742678
            ],
            [
              105.82828682496266,
              21.01164943504521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4387",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01155082982457,
          "to_latitude": 21.01164943504521,
          "to_longitude": 105.82828682496266,
          "from_longitude": 105.82833061988549
        }
      },
      {
        "id": 4381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280472287029,
            21.011424556689224,
            105.82806810308676,
            21.01146377790031
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806810308676,
              21.011424556689224
            ],
            [
              105.82806160067138,
              21.01143677466012
            ],
            [
              105.82804776184516,
              21.011462776337726
            ],
            [
              105.8280472287029,
              21.01146377790031
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4388",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011424556689224,
          "to_latitude": 21.01146377790031,
          "to_longitude": 105.8280472287029,
          "from_longitude": 105.82806810308676
        }
      },
      {
        "id": 4382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280493858191,
            21.011425111678548,
            105.82807006075282,
            21.01146474198392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807006075282,
              21.011425111678548
            ],
            [
              105.82806352827537,
              21.011437634183007
            ],
            [
              105.82804998833653,
              21.01146358743851
            ],
            [
              105.8280493858191,
              21.01146474198392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4389",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011425111678548,
          "to_latitude": 21.01146474198392,
          "to_longitude": 105.8280493858191,
          "from_longitude": 105.82807006075282
        }
      },
      {
        "id": 4383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797109678759,
            21.011401653910845,
            105.82810526087903,
            21.01154482738212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82810526087903,
              21.01154482738212
            ],
            [
              105.82797109678759,
              21.011424470549546
            ],
            [
              105.8279828408459,
              21.011401653910845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4390",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01154482738212,
          "to_latitude": 21.011401653910845,
          "to_longitude": 105.8279828408459,
          "from_longitude": 105.82810526087903
        }
      },
      {
        "id": 4384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282847436278,
            21.011536980980875,
            105.82833185258465,
            21.01164703988576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833185258465,
              21.011536980980875
            ],
            [
              105.82832666078305,
              21.01154911038753
            ],
            [
              105.82828528683075,
              21.011645770015356
            ],
            [
              105.8282847436278,
              21.01164703988576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4391",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011536980980875,
          "to_latitude": 21.01164703988576,
          "to_longitude": 105.8282847436278,
          "from_longitude": 105.82833185258465
        }
      },
      {
        "id": 4385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82828341835322,
            21.01153311579339,
            105.82832801437932,
            21.011645515368752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832801437932,
              21.01153311579339
            ],
            [
              105.82832240276814,
              21.01154726053451
            ],
            [
              105.82828385570927,
              21.01164441311044
            ],
            [
              105.82828341835322,
              21.011645515368752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4392",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01153311579339,
          "to_latitude": 21.011645515368752,
          "to_longitude": 105.82828341835322,
          "from_longitude": 105.82832801437932
        }
      },
      {
        "id": 4386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82858296612346,
            21.012020916444172,
            105.8288106368971,
            21.012150501525213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288106368971,
              21.012150501525213
            ],
            [
              105.82875830030693,
              21.01205861024151
            ],
            [
              105.82871353481018,
              21.012080916664846
            ],
            [
              105.82869594555766,
              21.012089682332483
            ],
            [
              105.82866108833299,
              21.012034595439964
            ],
            [
              105.82865174088187,
              21.012020916444172
            ],
            [
              105.82858702495567,
              21.01204891367141
            ],
            [
              105.82858296612346,
              21.012040983241473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4393",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012150501525213,
          "to_latitude": 21.012040983241473,
          "to_longitude": 105.82858296612346,
          "from_longitude": 105.8288106368971
        }
      },
      {
        "id": 4387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828605065529,
            21.01130155803386,
            105.8288749073532,
            21.011425021749968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288749073532,
              21.011425021749968
            ],
            [
              105.82878086605194,
              21.011381994006143
            ],
            [
              105.82877740790265,
              21.011380412139626
            ],
            [
              105.82877394878658,
              21.011378829374507
            ],
            [
              105.82877356594048,
              21.011378654206922
            ],
            [
              105.82877010682445,
              21.011377071441732
            ],
            [
              105.82876664963727,
              21.011375489570298
            ],
            [
              105.82873994299005,
              21.01136326983626
            ],
            [
              105.82872437166877,
              21.0113561451569
            ],
            [
              105.828605065529,
              21.01130155803386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4394",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011425021749968,
          "to_latitude": 21.01130155803386,
          "to_longitude": 105.828605065529,
          "from_longitude": 105.8288749073532
        }
      },
      {
        "id": 4388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832501634026,
            21.011171296207657,
            105.82859352780928,
            21.011296577829512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859352780928,
              21.011296577829512
            ],
            [
              105.8284583360782,
              21.0112335008562
            ],
            [
              105.82842984111993,
              21.011220205004157
            ],
            [
              105.82842746195887,
              21.0112190947805
            ],
            [
              105.82842401521981,
              21.011217487560668
            ],
            [
              105.8284205694427,
              21.011215880336106
            ],
            [
              105.82841862136087,
              21.011214970336233
            ],
            [
              105.82841517461699,
              21.011213362213017
            ],
            [
              105.82841172883509,
              21.011211754085046
            ],
            [
              105.82832501634026,
              21.011171296207657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4395",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011296577829512,
          "to_latitude": 21.011171296207657,
          "to_longitude": 105.82832501634026,
          "from_longitude": 105.82859352780928
        }
      },
      {
        "id": 4389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871348022263,
            21.0113561451569,
            105.82872437166877,
            21.011376393432467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872437166877,
              21.0113561451569
            ],
            [
              105.82872172474073,
              21.011361066178573
            ],
            [
              105.82871642610968,
              21.011370914567653
            ],
            [
              105.82871348022263,
              21.011376393432467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4396",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0113561451569,
          "to_latitude": 21.011376393432467,
          "to_longitude": 105.82871348022263,
          "from_longitude": 105.82872437166877
        }
      },
      {
        "id": 4390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82841225039225,
            21.011220205004157,
            105.82842984111993,
            21.011252903429877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82842984111993,
              21.011220205004157
            ],
            [
              105.82841912196302,
              21.011240129971526
            ],
            [
              105.82841547145486,
              21.01124691290753
            ],
            [
              105.82841225039225,
              21.011252903429877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4397",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011220205004157,
          "to_latitude": 21.011252903429877,
          "to_longitude": 105.82841225039225,
          "from_longitude": 105.82842984111993
        }
      },
      {
        "id": 4391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840916408664,
            21.01123868909808,
            105.8284162003828,
            21.011251767745485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284162003828,
              21.01123868909808
            ],
            [
              105.82841542343526,
              21.011240132623048
            ],
            [
              105.82841232040953,
              21.01124589857066
            ],
            [
              105.82840916408664,
              21.011251767745485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4398",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01123868909808,
          "to_latitude": 21.011251767745485,
          "to_longitude": 105.82840916408664,
          "from_longitude": 105.8284162003828
        }
      },
      {
        "id": 4392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840599488945,
            21.011237485074798,
            105.82841305117594,
            21.011250601754888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82841305117594,
              21.011237485074798
            ],
            [
              105.82841233897817,
              21.011238809058664
            ],
            [
              105.82840924451953,
              21.011244558706455
            ],
            [
              105.82840599488945,
              21.011250601754888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4399",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011237485074798,
          "to_latitude": 21.011250601754888,
          "to_longitude": 105.82840599488945,
          "from_longitude": 105.82841305117594
        }
      },
      {
        "id": 4393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8287197961512,
            21.01136290666808,
            105.82872845962227,
            21.01137901002742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872845962227,
              21.01136290666808
            ],
            [
              105.8287271028366,
              21.01136542966385
            ],
            [
              105.82872223646103,
              21.01137447207668
            ],
            [
              105.8287197961512,
              21.01137901002742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4400",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01136290666808,
          "to_latitude": 21.01137901002742,
          "to_longitude": 105.8287197961512,
          "from_longitude": 105.82872845962227
        }
      },
      {
        "id": 4394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871696068312,
            21.011362972502774,
            105.82872495670853,
            21.011377835191603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872495670853,
              21.011362972502774
            ],
            [
              105.82872354659605,
              21.01136559330662
            ],
            [
              105.82871875448892,
              21.0113744980673
            ],
            [
              105.82871696068312,
              21.011377835191603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4401",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011362972502774,
          "to_latitude": 21.011377835191603,
          "to_longitude": 105.82871696068312,
          "from_longitude": 105.82872495670853
        }
      },
      {
        "id": 4395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799900583944,
            21.011086679245,
            105.82836027541383,
            21.011222321891648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82799900583944,
              21.011222321891648
            ],
            [
              105.82816441583864,
              21.011215009171654
            ],
            [
              105.82829446115355,
              21.011176323270867
            ],
            [
              105.82830034037072,
              21.011168313745998
            ],
            [
              105.82836027541383,
              21.011086679245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4402",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011222321891648,
          "to_latitude": 21.011086679245,
          "to_longitude": 105.82836027541383,
          "from_longitude": 105.82799900583944
        }
      },
      {
        "id": 4396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82830034037072,
            21.011168313745998,
            105.82841021994572,
            21.011249770058342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840373269512,
              21.011249770058342
            ],
            [
              105.82840699177788,
              21.011245961545274
            ],
            [
              105.82841021994572,
              21.011238122084606
            ],
            [
              105.82830034037072,
              21.011168313745998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4403",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011249770058342,
          "to_latitude": 21.011168313745998,
          "to_longitude": 105.82830034037072,
          "from_longitude": 105.82840373269512
        }
      },
      {
        "id": 4397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82881866826705,
            21.01186499156522,
            105.82905059895477,
            21.011989855284167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881866826705,
              21.011989855284167
            ],
            [
              105.82904107356724,
              21.011870119525124
            ],
            [
              105.82905059895477,
              21.01186499156522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4404",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011989855284167,
          "to_latitude": 21.01186499156522,
          "to_longitude": 105.82905059895477,
          "from_longitude": 105.82881866826705
        }
      },
      {
        "id": 4398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82907857143908,
            21.011884041551806,
            105.8291687661461,
            21.01197706881366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291687661461,
              21.01197706881366
            ],
            [
              105.82911454162351,
              21.011884041551806
            ],
            [
              105.82907857143908,
              21.011902247920336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4405",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01197706881366,
          "to_latitude": 21.011902247920336,
          "to_longitude": 105.82907857143908,
          "from_longitude": 105.8291687661461
        }
      },
      {
        "id": 4399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900661909201,
            21.011801725621744,
            105.82905752046315,
            21.011842229593178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82905752046315,
              21.011834279772625
            ],
            [
              105.8290492895331,
              21.01183866078058
            ],
            [
              105.82904653677585,
              21.011839922451173
            ],
            [
              105.8290448912666,
              21.011840677436474
            ],
            [
              105.82904432008793,
              21.01184106138079
            ],
            [
              105.82904258163272,
              21.011842229593178
            ],
            [
              105.82902009048024,
              21.011801725621744
            ],
            [
              105.82901577940804,
              21.011803854755296
            ],
            [
              105.82900661909201,
              21.011808377579637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4406",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011834279772625,
          "to_latitude": 21.011808377579637,
          "to_longitude": 105.82900661909201,
          "from_longitude": 105.82905752046315
        }
      },
      {
        "id": 4400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900757410493,
            21.011804211652258,
            105.82907137026692,
            21.011850573646996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82906580432929,
              21.011825378763852
            ],
            [
              105.82907137026692,
              21.01183553009691
            ],
            [
              105.82904840234245,
              21.0118483531045
            ],
            [
              105.82904442459656,
              21.011850573646996
            ],
            [
              105.82904000761413,
              21.01184189258615
            ],
            [
              105.82901964730905,
              21.011804211652258
            ],
            [
              105.82901767194393,
              21.011805155212176
            ],
            [
              105.82900757410493,
              21.011810088152767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4407",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011825378763852,
          "to_latitude": 21.011810088152767,
          "to_longitude": 105.82900757410493,
          "from_longitude": 105.82906580432929
        }
      },
      {
        "id": 4401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82901763875853,
            21.011111718089758,
            105.82926124328246,
            21.011317327700883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82907971093069,
              21.011317327700883
            ],
            [
              105.82924119099373,
              21.01122951229397
            ],
            [
              105.82924588351143,
              21.011226960392897
            ],
            [
              105.82926124328246,
              21.011218607472205
            ],
            [
              105.829172105341,
              21.011179495918327
            ],
            [
              105.82915997800605,
              21.011174175399898
            ],
            [
              105.82915649224783,
              21.011172645159434
            ],
            [
              105.82915300648969,
              21.011171114918906
            ],
            [
              105.82915076654443,
              21.011170132285205
            ],
            [
              105.82914728079139,
              21.01116860294774
            ],
            [
              105.82914379503843,
              21.011167073610235
            ],
            [
              105.82911497947575,
              21.01115442975916
            ],
            [
              105.82901763875853,
              21.011111718089758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4408",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011317327700883,
          "to_latitude": 21.011111718089758,
          "to_longitude": 105.82901763875853,
          "from_longitude": 105.82907971093069
        }
      },
      {
        "id": 4402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82870437482799,
            21.010969598571922,
            105.82900010964373,
            21.011102271747156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900010964373,
              21.011102271747156
            ],
            [
              105.82884692213749,
              21.011033548171888
            ],
            [
              105.82881068960191,
              21.01101729316883
            ],
            [
              105.82881001363106,
              21.011016990267716
            ],
            [
              105.82880721717237,
              21.01101573666251
            ],
            [
              105.82880374473785,
              21.01101417925292
            ],
            [
              105.82880226838904,
              21.011013515346878
            ],
            [
              105.82879879498782,
              21.01101195703866
            ],
            [
              105.82879532448223,
              21.011010400522714
            ],
            [
              105.82870437482799,
              21.010969598571922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4409",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011102271747156,
          "to_latitude": 21.010969598571922,
          "to_longitude": 105.82870437482799,
          "from_longitude": 105.82900010964373
        }
      },
      {
        "id": 4403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82879740253803,
            21.011023240442945,
            105.82880318302026,
            21.011033968213912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82880318302026,
              21.011023240442945
            ],
            [
              105.82880235464485,
              21.011024776349124
            ],
            [
              105.82879968005982,
              21.01102973905507
            ],
            [
              105.82879740253803,
              21.011033968213912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4410",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011023240442945,
          "to_latitude": 21.011033968213912,
          "to_longitude": 105.82879740253803,
          "from_longitude": 105.82880318302026
        }
      },
      {
        "id": 4404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8287946089398,
            21.01102199952931,
            105.82880037701256,
            21.011032710078428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82880037701256,
              21.01102199952931
            ],
            [
              105.82879941154806,
              21.01102379352263
            ],
            [
              105.82879678838066,
              21.01102866113935
            ],
            [
              105.8287946089398,
              21.011032710078428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4411",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01102199952931,
          "to_latitude": 21.011032710078428,
          "to_longitude": 105.8287946089398,
          "from_longitude": 105.82880037701256
        }
      },
      {
        "id": 4405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291003516761,
            21.011155289105854,
            105.82910804309,
            21.011170086198394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82910804309,
              21.011155289105854
            ],
            [
              105.82910690527457,
              21.011157400069703
            ],
            [
              105.82910109211493,
              21.01116865188064
            ],
            [
              105.8291003516761,
              21.011170086198394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4412",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011155289105854,
          "to_latitude": 21.011170086198394,
          "to_longitude": 105.8291003516761,
          "from_longitude": 105.82910804309
        }
      },
      {
        "id": 4406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291033561299,
            21.011157042007714,
            105.82911087694166,
            21.0111712051077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82911087694166,
              21.011157042007714
            ],
            [
              105.82910984196646,
              21.011158963696253
            ],
            [
              105.82910421385708,
              21.011169585057118
            ],
            [
              105.8291033561299,
              21.0111712051077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4413",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011157042007714,
          "to_latitude": 21.0111712051077,
          "to_longitude": 105.8291033561299,
          "from_longitude": 105.82911087694166
        }
      },
      {
        "id": 4407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284515278112,
            21.010979417954026,
            105.82874552809352,
            21.011065944918126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874074029756,
              21.011010271667494
            ],
            [
              105.82874332263424,
              21.011003758572546
            ],
            [
              105.82874552809352,
              21.01099745415189
            ],
            [
              105.82870503218199,
              21.010979417954026
            ],
            [
              105.82868686533402,
              21.011005668371766
            ],
            [
              105.82855135031348,
              21.01102404958054
            ],
            [
              105.8284515278112,
              21.011065944918126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT30_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4414",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011010271667494,
          "to_latitude": 21.011065944918126,
          "to_longitude": 105.8284515278112,
          "from_longitude": 105.82874074029756
        }
      },
      {
        "id": 4408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82934254519567,
            21.01131907988855,
            105.82945137236825,
            21.011507081580596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82945137236825,
              21.011507081580596
            ],
            [
              105.82939755117393,
              21.011414104825104
            ],
            [
              105.82934254519567,
              21.01131907988855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4415",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011507081580596,
          "to_latitude": 21.01131907988855,
          "to_longitude": 105.82934254519567,
          "from_longitude": 105.82945137236825
        }
      },
      {
        "id": 4409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940926136652,
            21.011610191961978,
            105.82952684103189,
            21.011661045174524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82952684103189,
              21.011661045174524
            ],
            [
              105.82950579393251,
              21.011625296939457
            ],
            [
              105.82946232271449,
              21.01164782485745
            ],
            [
              105.82945708759605,
              21.01165053747421
            ],
            [
              105.82944164268137,
              21.011622617277013
            ],
            [
              105.82943452702054,
              21.011610191961978
            ],
            [
              105.82941081155168,
              21.011620019951014
            ],
            [
              105.82940926136652,
              21.01162061731379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4416",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011661045174524,
          "to_latitude": 21.01162061731379,
          "to_longitude": 105.82940926136652,
          "from_longitude": 105.82952684103189
        }
      },
      {
        "id": 4410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946232271449,
            21.01164782485745,
            105.82952657943773,
            21.011756388261254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946232271449,
              21.01164782485745
            ],
            [
              105.82952657943773,
              21.011756388261254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4417",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01164782485745,
          "to_latitude": 21.011756388261254,
          "to_longitude": 105.82952657943773,
          "from_longitude": 105.82946232271449
        }
      },
      {
        "id": 4411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82932350450574,
            21.011444745752264,
            105.82937996946521,
            21.011474705485217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937996946521,
              21.011444745752264
            ],
            [
              105.82935062491569,
              21.01146031588008
            ],
            [
              105.82932350450574,
              21.011474705485217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4418",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011444745752264,
          "to_latitude": 21.011474705485217,
          "to_longitude": 105.82932350450574,
          "from_longitude": 105.82937996946521
        }
      },
      {
        "id": 4412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919077787919,
            21.01162352544704,
            105.82941413125126,
            21.011764091978275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82919077787919,
              21.011764091978275
            ],
            [
              105.82927126540888,
              21.011699260884896
            ],
            [
              105.82941330546927,
              21.011623963923917
            ],
            [
              105.82941413125126,
              21.01162352544704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4419",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011764091978275,
          "to_latitude": 21.01162352544704,
          "to_longitude": 105.82941413125126,
          "from_longitude": 105.82919077787919
        }
      },
      {
        "id": 4413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950211660925,
            21.010951977392025,
            105.82950928644489,
            21.01096576215244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950928644489,
              21.010951977392025
            ],
            [
              105.82950845369233,
              21.01095359009699
            ],
            [
              105.82950302938605,
              21.01096400905738
            ],
            [
              105.82950211660925,
              21.01096576215244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4420",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010951977392025,
          "to_latitude": 21.01096576215244,
          "to_longitude": 105.82950211660925,
          "from_longitude": 105.82950928644489
        }
      },
      {
        "id": 4414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82949915527989,
            21.010950910224857,
            105.82950678631838,
            21.010964439814064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950678631838,
              21.010950910224857
            ],
            [
              105.8295059297784,
              21.010952570013515
            ],
            [
              105.82950021275154,
              21.010962586658785
            ],
            [
              105.82949915527989,
              21.010964439814064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4421",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010950910224857,
          "to_latitude": 21.010964439814064,
          "to_longitude": 105.82949915527989,
          "from_longitude": 105.82950678631838
        }
      },
      {
        "id": 4415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919339355968,
            21.01081889165707,
            105.82920293939695,
            21.010834578435706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82920293939695,
              21.01081889165707
            ],
            [
              105.82920208284422,
              21.010820549637927
            ],
            [
              105.82919436983512,
              21.010833001161835
            ],
            [
              105.82919339355968,
              21.010834578435706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4422",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01081889165707,
          "to_latitude": 21.010834578435706,
          "to_longitude": 105.82919339355968,
          "from_longitude": 105.82920293939695
        }
      },
      {
        "id": 4416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919212664306,
            21.010818179768712,
            105.8292007452008,
            21.010834040867344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292007452008,
              21.010818179768712
            ],
            [
              105.8291999095898,
              21.010819797905548
            ],
            [
              105.82919461969381,
              21.010829477362876
            ],
            [
              105.82919212664306,
              21.010834040867344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4423",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010818179768712,
          "to_latitude": 21.010834040867344,
          "to_longitude": 105.82919212664306,
          "from_longitude": 105.8292007452008
        }
      },
      {
        "id": 4417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971744508906,
            21.01118686269757,
            105.82981687618393,
            21.011250086540382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981687618393,
              21.01118686269757
            ],
            [
              105.82978056850395,
              21.01120469962889
            ],
            [
              105.82974715791518,
              21.01122111429636
            ],
            [
              105.82972991001608,
              21.011243916339094
            ],
            [
              105.82971964952321,
              21.011248995533048
            ],
            [
              105.82971744508906,
              21.011250086540382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4424",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01118686269757,
          "to_latitude": 21.011250086540382,
          "to_longitude": 105.82971744508906,
          "from_longitude": 105.82981687618393
        }
      },
      {
        "id": 4418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8299591903807,
            21.01140660538683,
            105.82999493974418,
            21.01146797591425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82999493974418,
              21.01146797591425
            ],
            [
              105.82997273962494,
              21.011429864201514
            ],
            [
              105.8299591903807,
              21.01140660538683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4425",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01146797591425,
          "to_latitude": 21.01140660538683,
          "to_longitude": 105.8299591903807,
          "from_longitude": 105.82999493974418
        }
      },
      {
        "id": 4419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983282704167,
            21.01142660269347,
            105.82997273962494,
            21.011465974610125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82997273962494,
              21.011429864201514
            ],
            [
              105.82990359620699,
              21.011465974610125
            ],
            [
              105.82988143531168,
              21.01142660269347
            ],
            [
              105.82983708448029,
              21.011444684800882
            ],
            [
              105.82983282704167,
              21.011446419886585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4426",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011429864201514,
          "to_latitude": 21.011446419886585,
          "to_longitude": 105.82983282704167,
          "from_longitude": 105.82997273962494
        }
      },
      {
        "id": 4420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971880762369,
            21.011210734206664,
            105.82980628774013,
            21.01125243279981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82980628774013,
              21.011210734206664
            ],
            [
              105.82979264786637,
              21.011217690530305
            ],
            [
              105.829789260918,
              21.01121941775685
            ],
            [
              105.82978587301264,
              21.011221145891227
            ],
            [
              105.82975115155367,
              21.011237599468213
            ],
            [
              105.82972231451154,
              21.01125082511882
            ],
            [
              105.82971880762369,
              21.01125243279981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4427",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011210734206664,
          "to_latitude": 21.01125243279981,
          "to_longitude": 105.82971880762369,
          "from_longitude": 105.82980628774013
        }
      },
      {
        "id": 4421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82970667962223,
            21.01143160132527,
            105.82990495556349,
            21.011588774664556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82970667962223,
              21.011588774664556
            ],
            [
              105.82990495556349,
              21.011484091206064
            ],
            [
              105.82987584251829,
              21.01143160132527
            ],
            [
              105.82983796216644,
              21.011446848262473
            ],
            [
              105.82983401269351,
              21.011448437330156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4428",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011588774664556,
          "to_latitude": 21.011448437330156,
          "to_longitude": 105.82983401269351,
          "from_longitude": 105.82970667962223
        }
      },
      {
        "id": 4422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83012675869365,
            21.011250823974887,
            105.83027043645528,
            21.01137002089293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83027043645528,
              21.01135668038461
            ],
            [
              105.83025720338503,
              21.01133476585823
            ],
            [
              105.83018771675661,
              21.01137002089293
            ],
            [
              105.83018450059797,
              21.011363499939442
            ],
            [
              105.83018304428445,
              21.01136054807703
            ],
            [
              105.83016562491576,
              21.01132523383908
            ],
            [
              105.83014818699137,
              21.011296821237742
            ],
            [
              105.83012675869365,
              21.011261907714704
            ],
            [
              105.8301475467514,
              21.011250823974887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4429",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01135668038461,
          "to_latitude": 21.011250823974887,
          "to_longitude": 105.8301475467514,
          "from_longitude": 105.83027043645528
        }
      },
      {
        "id": 4423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83013184380022,
            21.011296821237742,
            105.83014818699137,
            21.01130545090597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83014818699137,
              21.011296821237742
            ],
            [
              105.83014724610717,
              21.011297318086093
            ],
            [
              105.83014401103908,
              21.011299026514138
            ],
            [
              105.83013184380022,
              21.01130545090597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4430",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011296821237742,
          "to_latitude": 21.01130545090597,
          "to_longitude": 105.83013184380022,
          "from_longitude": 105.83014818699137
        }
      },
      {
        "id": 4424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83001052539142,
            21.011031412680868,
            105.83018138235369,
            21.011218087783995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83009116740489,
              21.011053340440725
            ],
            [
              105.83007731099923,
              21.011031412680868
            ],
            [
              105.83001052539142,
              21.011067169304077
            ],
            [
              105.83005883427894,
              21.01114748152607
            ],
            [
              105.8300677844334,
              21.011162360059345
            ],
            [
              105.83009396335777,
              21.011210274028695
            ],
            [
              105.83009589714459,
              21.011213814272363
            ],
            [
              105.83009823225714,
              21.011218087783995
            ],
            [
              105.83016846128568,
              21.01118332774527
            ],
            [
              105.83018138235369,
              21.01120483735029
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.3_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4431",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011053340440725,
          "to_latitude": 21.01120483735029,
          "to_longitude": 105.83018138235369,
          "from_longitude": 105.83009116740489
        }
      },
      {
        "id": 4425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8300456082078,
            21.01114748152607,
            105.83005883427894,
            21.01115446746385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005883427894,
              21.01114748152607
            ],
            [
              105.83005833650094,
              21.011147744083335
            ],
            [
              105.83005572581664,
              21.011149123399296
            ],
            [
              105.8300456082078,
              21.01115446746385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.3_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4432",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01114748152607,
          "to_latitude": 21.01115446746385,
          "to_longitude": 105.8300456082078,
          "from_longitude": 105.83005883427894
        }
      },
      {
        "id": 4426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999248494858,
            21.011028445542603,
            105.83005043028967,
            21.01106145768866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83004899044172,
              21.011028445542603
            ],
            [
              105.83005043028967,
              21.011030856454468
            ],
            [
              105.83000300773057,
              21.011055804554204
            ],
            [
              105.82999248494858,
              21.01106145768866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.2_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4433",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011028445542603,
          "to_latitude": 21.01106145768866,
          "to_longitude": 105.82999248494858,
          "from_longitude": 105.83004899044172
        }
      },
      {
        "id": 4427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82999008191771,
            21.011031571186628,
            105.83003870762394,
            21.01105725134535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83003870762394,
              21.011031571186628
            ],
            [
              105.83000060969627,
              21.011051805928602
            ],
            [
              105.82999008191771,
              21.01105725134535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.1_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4434",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011031571186628,
          "to_latitude": 21.01105725134535,
          "to_longitude": 105.82999008191771,
          "from_longitude": 105.83003870762394
        }
      },
      {
        "id": 4428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023804827377,
            21.010898880360315,
            105.8303930307945,
            21.01102454830729
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303930307945,
              21.010984917780423
            ],
            [
              105.83033117222564,
              21.011015816418062
            ],
            [
              105.83031368978048,
              21.01102454830729
            ],
            [
              105.83030806574098,
              21.01101503136348
            ],
            [
              105.8303065665096,
              21.011012493388836
            ],
            [
              105.8303024125709,
              21.011005464884185
            ],
            [
              105.83028950311109,
              21.010983619231226
            ],
            [
              105.83025703816926,
              21.010936412478877
            ],
            [
              105.83023804827377,
              21.010898880360315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4435",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010984917780423,
          "to_latitude": 21.010898880360315,
          "to_longitude": 105.83023804827377,
          "from_longitude": 105.8303930307945
        }
      },
      {
        "id": 4429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83017508506497,
            21.01066079804809,
            105.8303125729499,
            21.010868995977102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303125729499,
              21.010829697242183
            ],
            [
              105.83023810029249,
              21.010868995977102
            ],
            [
              105.83023650451064,
              21.010864480408262
            ],
            [
              105.8302355204026,
              21.010861685209825
            ],
            [
              105.83023495361516,
              21.0108600802333
            ],
            [
              105.83023466932453,
              21.010859289491336
            ],
            [
              105.8302341729121,
              21.010857886494026
            ],
            [
              105.83021196103122,
              21.010795044543162
            ],
            [
              105.83019538744998,
              21.010762723081033
            ],
            [
              105.83017508506497,
              21.010705284840117
            ],
            [
              105.83026084095997,
              21.01066079804809
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.3_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4436",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010829697242183,
          "to_latitude": 21.01066079804809,
          "to_longitude": 105.83026084095997,
          "from_longitude": 105.8303125729499
        }
      },
      {
        "id": 4430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83021995650353,
            21.010857886494026,
            105.8302341729121,
            21.01086407430887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302341729121,
              21.010857886494026
            ],
            [
              105.8302311471472,
              21.010859695966815
            ],
            [
              105.83021995650353,
              21.01086407430887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.3_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4437",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010857886494026,
          "to_latitude": 21.01086407430887,
          "to_longitude": 105.83021995650353,
          "from_longitude": 105.8302341729121
        }
      },
      {
        "id": 4431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028427067563,
            21.011005464884185,
            105.8303024125709,
            21.011013751085013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303024125709,
              21.011005464884185
            ],
            [
              105.83030070469502,
              21.011006245475922
            ],
            [
              105.83029579118465,
              21.01100848958057
            ],
            [
              105.83028427067563,
              21.011013751085013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4438",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011005464884185,
          "to_latitude": 21.011013751085013,
          "to_longitude": 105.83028427067563,
          "from_longitude": 105.8303024125709
        }
      },
      {
        "id": 4432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83008162374573,
            21.010116950097743,
            105.83026528187959,
            21.010308640113234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026528187959,
              21.01028808461758
            ],
            [
              105.83025149858294,
              21.010265279479643
            ],
            [
              105.83017107620083,
              21.010308640113234
            ],
            [
              105.83016775284743,
              21.010302907293912
            ],
            [
              105.83016501123599,
              21.01029817963651
            ],
            [
              105.83014603457964,
              21.010265454405626
            ],
            [
              105.83012239632265,
              21.010224690622067
            ],
            [
              105.83008162374573,
              21.010154376479445
            ],
            [
              105.83015315630466,
              21.010116950097743
            ],
            [
              105.83016731410585,
              21.010141731482516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4439",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01028808461758,
          "to_latitude": 21.010141731482516,
          "to_longitude": 105.83016731410585,
          "from_longitude": 105.83026528187959
        }
      },
      {
        "id": 4433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83006594597815,
            21.010119827430415,
            105.83013705736681,
            21.0101579041827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83013582335849,
              21.010119827430415
            ],
            [
              105.83013705736681,
              21.010121893410663
            ],
            [
              105.83006768785629,
              21.01015718575022
            ],
            [
              105.83006594597815,
              21.0101579041827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.7_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4440",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010119827430415,
          "to_latitude": 21.0101579041827,
          "to_longitude": 105.83006594597815,
          "from_longitude": 105.83013582335849
        }
      },
      {
        "id": 4434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023029995194,
            21.01044243823317,
            105.83025019632343,
            21.01045332705193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83025019632343,
              21.01044243823317
            ],
            [
              105.83024061111439,
              21.010447683065355
            ],
            [
              105.83023202806372,
              21.01045238016874
            ],
            [
              105.83023029995194,
              21.01045332705193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.3_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4441",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01044243823317,
          "to_latitude": 21.01045332705193,
          "to_longitude": 105.83023029995194,
          "from_longitude": 105.83025019632343
        }
      },
      {
        "id": 4435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83012755150452,
            21.010265454405626,
            105.83014603457964,
            21.01027464909139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83014603457964,
              21.010265454405626
            ],
            [
              105.83013751995638,
              21.010269652589585
            ],
            [
              105.83013749405566,
              21.01026966536111
            ],
            [
              105.83012866614243,
              21.01027408997627
            ],
            [
              105.83012755150452,
              21.01027464909139
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4442",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010265454405626,
          "to_latitude": 21.01027464909139,
          "to_longitude": 105.83012755150452,
          "from_longitude": 105.83014603457964
        }
      },
      {
        "id": 4436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83006386179652,
            21.010122462196726,
            105.83012396229267,
            21.010154126219838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83012396229267,
              21.010122462196726
            ],
            [
              105.83006575183605,
              21.010153239064614
            ],
            [
              105.83006386179652,
              21.010154126219838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.8_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4443",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010122462196726,
          "to_latitude": 21.010154126219838,
          "to_longitude": 105.83006386179652,
          "from_longitude": 105.83012396229267
        }
      },
      {
        "id": 4437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82984638072682,
            21.01022049176461,
            105.82989884131628,
            21.01031074928799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82989884131628,
              21.01022049176461
            ],
            [
              105.82984638072682,
              21.010247561677712
            ],
            [
              105.8298812606566,
              21.010308494808843
            ],
            [
              105.8298825514961,
              21.01031074928799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4444",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01022049176461,
          "to_latitude": 21.01031074928799,
          "to_longitude": 105.8298825514961,
          "from_longitude": 105.82989884131628
        }
      },
      {
        "id": 4438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82975562732871,
            21.010269236459187,
            105.82996785622784,
            21.010381355611234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82996785622784,
              21.010269236459187
            ],
            [
              105.82994129862783,
              21.010279768443652
            ],
            [
              105.8298825514961,
              21.01031074928799
            ],
            [
              105.82987721119454,
              21.010313565397272
            ],
            [
              105.82987142298987,
              21.010316617626295
            ],
            [
              105.82983744570058,
              21.01033453532931
            ],
            [
              105.82982900844634,
              21.010338985120892
            ],
            [
              105.82975562732871,
              21.010381355611234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4445",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010269236459187,
          "to_latitude": 21.010381355611234,
          "to_longitude": 105.82975562732871,
          "from_longitude": 105.82996785622784
        }
      },
      {
        "id": 4439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951573110476,
            21.010317897434113,
            105.82971288529706,
            21.010485161494657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82971288529706,
              21.010317897434113
            ],
            [
              105.82966362055883,
              21.01034279209628
            ],
            [
              105.82970139777959,
              21.010409828688193
            ],
            [
              105.82969533360331,
              21.0104128994183
            ],
            [
              105.82968937877625,
              21.010415914518234
            ],
            [
              105.82966418489978,
              21.010428669876138
            ],
            [
              105.82962994558274,
              21.01044600442927
            ],
            [
              105.82962890770709,
              21.0104465297476
            ],
            [
              105.82955260470277,
              21.010485161494657
            ],
            [
              105.82951573110476,
              21.01042044988374
            ],
            [
              105.82956069629678,
              21.01039714151455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4446",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010317897434113,
          "to_latitude": 21.01039714151455,
          "to_longitude": 105.82956069629678,
          "from_longitude": 105.82971288529706
        }
      },
      {
        "id": 4440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82984605262381,
            21.01027224031393,
            105.82987910214803,
            21.010329537854957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82984605262381,
              21.01027224031393
            ],
            [
              105.82987043614555,
              21.010314890954902
            ],
            [
              105.82987142298987,
              21.010316617626295
            ],
            [
              105.82987202810945,
              21.010317675965663
            ],
            [
              105.82987425934193,
              21.010320736955556
            ],
            [
              105.82987810805315,
              21.010327891606075
            ],
            [
              105.82987910214803,
              21.010329537854957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.7_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4447",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01027224031393,
          "to_latitude": 21.010329537854957,
          "to_longitude": 105.82987910214803,
          "from_longitude": 105.82984605262381
        }
      },
      {
        "id": 4441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82984382332087,
            21.010260488257053,
            105.82988476273306,
            21.010326542248073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82984382332087,
              21.010262223571168
            ],
            [
              105.82984683121285,
              21.010260488257053
            ],
            [
              105.82987677834046,
              21.010312870217845
            ],
            [
              105.82987721119454,
              21.010313565397272
            ],
            [
              105.82987925901043,
              21.010316855797786
            ],
            [
              105.82987996419236,
              21.010318440229852
            ],
            [
              105.82988424520141,
              21.01032566773969
            ],
            [
              105.82988476273306,
              21.010326542248073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.8_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4448",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010262223571168,
          "to_latitude": 21.010326542248073,
          "to_longitude": 105.82988476273306,
          "from_longitude": 105.82984382332087
        }
      },
      {
        "id": 4442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983744570058,
            21.01033453532931,
            105.82984513573227,
            21.010347508796848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82983744570058,
              21.01033453532931
            ],
            [
              105.82983885458316,
              21.01033691478155
            ],
            [
              105.82984188980225,
              21.010342033012666
            ],
            [
              105.82984513573227,
              21.010347508796848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4449",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01033453532931,
          "to_latitude": 21.010347508796848,
          "to_longitude": 105.82984513573227,
          "from_longitude": 105.82983744570058
        }
      },
      {
        "id": 4443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82952155724314,
            21.010440784712102,
            105.82955331230131,
            21.01049501224045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82952155724314,
              21.010440784712102
            ],
            [
              105.82954928929901,
              21.010487647980426
            ],
            [
              105.82955331230131,
              21.01049501224045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4450",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010440784712102,
          "to_latitude": 21.01049501224045,
          "to_longitude": 105.82955331230131,
          "from_longitude": 105.82952155724314
        }
      },
      {
        "id": 4444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951451946506,
            21.01043103646888,
            105.82955420650504,
            21.01049459781655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82951451946506,
              21.010432693599192
            ],
            [
              105.82951779520603,
              21.01043103646888
            ],
            [
              105.82955038678949,
              21.010487814243056
            ],
            [
              105.82955420650504,
              21.01049459781655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4451",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010432693599192,
          "to_latitude": 21.01049459781655,
          "to_longitude": 105.82955420650504,
          "from_longitude": 105.82951451946506
        }
      },
      {
        "id": 4445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962890770709,
            21.0104465297476,
            105.82963515420893,
            21.010457122133264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82962890770709,
              21.0104465297476
            ],
            [
              105.82963061863839,
              21.010449433406464
            ],
            [
              105.82963389578295,
              21.01045498852645
            ],
            [
              105.82963515420893,
              21.010457122133264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4452",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0104465297476,
          "to_latitude": 21.010457122133264,
          "to_longitude": 105.82963515420893,
          "from_longitude": 105.82962890770709
        }
      },
      {
        "id": 4446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82966418677321,
            21.01047875826263,
            105.82988629952166,
            21.01089635829976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82988629952166,
              21.01089635829976
            ],
            [
              105.82986131345244,
              21.010842351636583
            ],
            [
              105.82981717901465,
              21.010754309056264
            ],
            [
              105.82978323390967,
              21.01069040787446
            ],
            [
              105.82978300459817,
              21.010689991702314
            ],
            [
              105.82977843879819,
              21.010681709708052
            ],
            [
              105.82968760860648,
              21.010516928198157
            ],
            [
              105.82966418677321,
              21.01047875826263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT27_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4453",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01089635829976,
          "to_latitude": 21.01047875826263,
          "to_longitude": 105.82966418677321,
          "from_longitude": 105.82988629952166
        }
      },
      {
        "id": 4447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8295264885033,
            21.010497747848497,
            105.82964349680132,
            21.010699090013265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964349680132,
              21.010699090013265
            ],
            [
              105.82954852396055,
              21.010536073655935
            ],
            [
              105.82953714824566,
              21.01051654717351
            ],
            [
              105.82953058967595,
              21.01050553178929
            ],
            [
              105.8295264885033,
              21.01049864300665
            ],
            [
              105.82952853914425,
              21.010497747848497
            ],
            [
              105.82953226967598,
              21.010504754954088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT28_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4454",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010699090013265,
          "to_latitude": 21.010504754954088,
          "to_longitude": 105.82953226967598,
          "from_longitude": 105.82964349680132
        }
      },
      {
        "id": 4448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023392161735,
            21.010502364481376,
            105.83042674296645,
            21.010742868489615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83042674296645,
              21.010742868489615
            ],
            [
              105.83035236059122,
              21.010594073668518
            ],
            [
              105.83028299296927,
              21.01051153937515
            ],
            [
              105.83024023942251,
              21.010511058818054
            ],
            [
              105.83023392161735,
              21.010502364481376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT22_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4455",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010742868489615,
          "to_latitude": 21.010502364481376,
          "to_longitude": 105.83023392161735,
          "from_longitude": 105.83042674296645
        }
      },
      {
        "id": 4449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83010997549556,
            21.01025028812086,
            105.83023447485425,
            21.010467450296176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83010997549556,
              21.01025028812086
            ],
            [
              105.83016837353142,
              21.010352151754077
            ],
            [
              105.83017098335412,
              21.01035670392527
            ],
            [
              105.83023447485425,
              21.010467450296176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT23_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4456",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01025028812086,
          "to_latitude": 21.010467450296176,
          "to_longitude": 105.83023447485425,
          "from_longitude": 105.83010997549556
        }
      },
      {
        "id": 4450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83017098335412,
            21.01035302915266,
            105.83017699992806,
            21.01035670392527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83017098335412,
              21.01035670392527
            ],
            [
              105.83017273483246,
              21.010355634089972
            ],
            [
              105.83017492538147,
              21.01035429656416
            ],
            [
              105.8301769644585,
              21.010353051003115
            ],
            [
              105.83017699992806,
              21.01035302915266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT23_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4457",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01035670392527,
          "to_latitude": 21.01035302915266,
          "to_longitude": 105.83017699992806,
          "from_longitude": 105.83017098335412
        }
      },
      {
        "id": 4451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82990647830694,
            21.010297964660637,
            105.83007988991389,
            21.010574690270325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83007988991389,
              21.010574690270325
            ],
            [
              105.82993391153958,
              21.01032521415615
            ],
            [
              105.82992204377506,
              21.010306817325766
            ],
            [
              105.82991633304417,
              21.010297964660637
            ],
            [
              105.82990647830694,
              21.01030247917569
            ],
            [
              105.82991161168023,
              21.010312336334565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT26_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4458",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010574690270325,
          "to_latitude": 21.010312336334565,
          "to_longitude": 105.82991161168023,
          "from_longitude": 105.83007988991389
        }
      },
      {
        "id": 4452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83002268626313,
            21.009829010697402,
            105.830313027842,
            21.01016121846386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83025831388922,
              21.009829010697402
            ],
            [
              105.830313027842,
              21.00992271806821
            ],
            [
              105.83002268626313,
              21.010072689896145
            ],
            [
              105.83004620882792,
              21.010116892841157
            ],
            [
              105.8300434295066,
              21.010143251692234
            ],
            [
              105.83005346920207,
              21.01016121846386
            ],
            [
              105.83006308259186,
              21.010155853375846
            ],
            [
              105.83006438891985,
              21.010155169584138
            ],
            [
              105.83006442632312,
              21.010155149530725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4459",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009829010697402,
          "to_latitude": 21.010155149530725,
          "to_longitude": 105.83006442632312,
          "from_longitude": 105.83025831388922
        }
      },
      {
        "id": 4453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961897839785,
            21.01009455832703,
            105.83009454199608,
            21.010234376052434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83009454199608,
              21.010215384179386
            ],
            [
              105.83009450458782,
              21.010215403329628
            ],
            [
              105.8300896474856,
              21.010217928959193
            ],
            [
              105.83008288646946,
              21.010221094327843
            ],
            [
              105.83006308387382,
              21.01018807685755
            ],
            [
              105.83002826262613,
              21.01012576154224
            ],
            [
              105.82998133848638,
              21.010148948618422
            ],
            [
              105.82992594504428,
              21.010126796207352
            ],
            [
              105.82991256910464,
              21.01013362750932
            ],
            [
              105.82989000416897,
              21.01009455832703
            ],
            [
              105.82961897839785,
              21.010234376052434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT24_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4460",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010215384179386,
          "to_latitude": 21.010234376052434,
          "to_longitude": 105.82961897839785,
          "from_longitude": 105.83009454199608
        }
      },
      {
        "id": 4454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897776996532,
            21.012161175282653,
            105.82927753168087,
            21.01229991186743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82927753168087,
              21.012161175282653
            ],
            [
              105.82915131297723,
              21.01221773620493
            ],
            [
              105.82897776996532,
              21.01229991186743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT11_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4461",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012161175282653,
          "to_latitude": 21.01229991186743,
          "to_longitude": 105.82897776996532,
          "from_longitude": 105.82927753168087
        }
      },
      {
        "id": 4455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865053462778,
            21.012296733440024,
            105.82896534368224,
            21.012454020967372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82896534368224,
              21.012296733440024
            ],
            [
              105.82883125330918,
              21.012363728610893
            ],
            [
              105.82880414916735,
              21.012377270832644
            ],
            [
              105.82868757011839,
              21.012435516851692
            ],
            [
              105.82865053462778,
              21.012454020967372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4462",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012296733440024,
          "to_latitude": 21.012454020967372,
          "to_longitude": 105.82865053462778,
          "from_longitude": 105.82896534368224
        }
      },
      {
        "id": 4456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883125330918,
            21.012363728610893,
            105.8288399389012,
            21.012382961142436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883125330918,
              21.012363728610893
            ],
            [
              105.82883264682434,
              21.012367330210328
            ],
            [
              105.8288335005224,
              21.01236915779395
            ],
            [
              105.8288399389012,
              21.012382961142436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4463",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012363728610893,
          "to_latitude": 21.012382961142436,
          "to_longitude": 105.8288399389012,
          "from_longitude": 105.82883125330918
        }
      },
      {
        "id": 4457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915131297723,
            21.01221773620493,
            105.82915946289756,
            21.01223193674895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915131297723,
              21.01221773620493
            ],
            [
              105.829153576669,
              21.01222179420643
            ],
            [
              105.82915484640526,
              21.012224232148128
            ],
            [
              105.82915703868278,
              21.012228248045947
            ],
            [
              105.82915946289756,
              21.01223193674895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT11_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4464",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01221773620493,
          "to_latitude": 21.01223193674895,
          "to_longitude": 105.82915946289756,
          "from_longitude": 105.82915131297723
        }
      },
      {
        "id": 4458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82930463538992,
            21.012033307102126,
            105.82961963387889,
            21.01216881688549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82930463538992,
              21.01216531905486
            ],
            [
              105.82936085944159,
              21.01216881688549
            ],
            [
              105.82948574500307,
              21.012103418825852
            ],
            [
              105.82948577762244,
              21.012103403311997
            ],
            [
              105.82961963387889,
              21.012033307102126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4465",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01216531905486,
          "to_latitude": 21.012033307102126,
          "to_longitude": 105.82961963387889,
          "from_longitude": 105.82930463538992
        }
      },
      {
        "id": 4459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82963376285943,
            21.011860884740848,
            105.82994675536766,
            21.012025722470398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82963376285943,
              21.012025722470398
            ],
            [
              105.82979026639809,
              21.01194330047875
            ],
            [
              105.82994675536766,
              21.011860884740848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4466",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012025722470398,
          "to_latitude": 21.011860884740848,
          "to_longitude": 105.82994675536766,
          "from_longitude": 105.82963376285943
        }
      },
      {
        "id": 4460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83002368052117,
            21.011820371964895,
            105.83003179512588,
            21.011830612896592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83002368052117,
              21.011820371964895
            ],
            [
              105.83002456007596,
              21.011821481351713
            ],
            [
              105.83002874283213,
              21.011826761979798
            ],
            [
              105.83003179512588,
              21.011830612896592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4467",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011820371964895,
          "to_latitude": 21.011830612896592,
          "to_longitude": 105.83003179512588,
          "from_longitude": 105.83002368052117
        }
      },
      {
        "id": 4461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82948577762244,
            21.012103403311997,
            105.82949242049281,
            21.0121119073153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82948577762244,
              21.012103403311997
            ],
            [
              105.82948970769228,
              21.012108391636925
            ],
            [
              105.82949242049281,
              21.0121119073153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4468",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012103403311997,
          "to_latitude": 21.0121119073153,
          "to_longitude": 105.82949242049281,
          "from_longitude": 105.82948577762244
        }
      },
      {
        "id": 4462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83018299191612,
            21.01111718217655,
            105.83043753808735,
            21.011703475926947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83027557615416,
              21.011703475926947
            ],
            [
              105.83027506436875,
              21.011702424359807
            ],
            [
              105.8302756505869,
              21.011683727436463
            ],
            [
              105.83018299191612,
              21.01152682948694
            ],
            [
              105.83020719369831,
              21.011485128698315
            ],
            [
              105.83029346371617,
              21.01143786737316
            ],
            [
              105.83042409641122,
              21.011371735184966
            ],
            [
              105.83043753808735,
              21.01135017642486
            ],
            [
              105.83043371165873,
              21.011320642456493
            ],
            [
              105.83033850602287,
              21.01111718217655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4469",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011703475926947,
          "to_latitude": 21.01111718217655,
          "to_longitude": 105.83033850602287,
          "from_longitude": 105.83027557615416
        }
      },
      {
        "id": 4463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83044432727543,
            21.01118756653613,
            105.83066377501275,
            21.011612078635764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83045082794979,
              21.011612078635764
            ],
            [
              105.83044828202486,
              21.01160760654561
            ],
            [
              105.83044432727543,
              21.011600769460493
            ],
            [
              105.83059557514888,
              21.01151976607652
            ],
            [
              105.83066377501275,
              21.011485434835585
            ],
            [
              105.8306432002489,
              21.01118756653613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4470",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011612078635764,
          "to_latitude": 21.01118756653613,
          "to_longitude": 105.8306432002489,
          "from_longitude": 105.83045082794979
        }
      },
      {
        "id": 4464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789026511584,
            21.011516652962847,
            105.82792282218392,
            21.01153387150721
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82789026511584,
              21.01153387150721
            ],
            [
              105.82789792075323,
              21.01152982303134
            ],
            [
              105.82791781968201,
              21.011519165691332
            ],
            [
              105.82792282218392,
              21.011516652962847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K15.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4471",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01153387150721,
          "to_latitude": 21.011516652962847,
          "to_longitude": 105.82792282218392,
          "from_longitude": 105.82789026511584
        }
      },
      {
        "id": 4465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82802707053722,
            21.01173857395744,
            105.82806757291708,
            21.01175999687343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82802707053722,
              21.01175999687343
            ],
            [
              105.82803980947793,
              21.01175325913697
            ],
            [
              105.8280453460263,
              21.011750256063802
            ],
            [
              105.82805701756027,
              21.011743924552942
            ],
            [
              105.82806757291708,
              21.01173857395744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K15.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4472",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01175999687343,
          "to_latitude": 21.01173857395744,
          "to_longitude": 105.82806757291708,
          "from_longitude": 105.82802707053722
        }
      },
      {
        "id": 4466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82792563278377,
            21.01154574304022,
            105.82817238829834,
            21.01184796658846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82817238829834,
              21.01180750295225
            ],
            [
              105.828129439867,
              21.01183208012147
            ],
            [
              105.82810167920037,
              21.01184796658846
            ],
            [
              105.82805582149119,
              21.011768248833107
            ],
            [
              105.8280542548969,
              21.01176552600443
            ],
            [
              105.82805262128451,
              21.011762686082488
            ],
            [
              105.8280453460263,
              21.011750256063802
            ],
            [
              105.82804440117684,
              21.01174864208067
            ],
            [
              105.8280342602978,
              21.011731316972867
            ],
            [
              105.82792563278377,
              21.01154574304022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K15.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4473",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01180750295225,
          "to_latitude": 21.01154574304022,
          "to_longitude": 105.82792563278377,
          "from_longitude": 105.82817238829834
        }
      },
      {
        "id": 4467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82804440117684,
            21.011735981016958,
            105.82806585698152,
            21.01174864208067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82804440117684,
              21.01174864208067
            ],
            [
              105.82804462265992,
              21.01174851184151
            ],
            [
              105.82805487500211,
              21.011742461817917
            ],
            [
              105.8280651273435,
              21.011736411793724
            ],
            [
              105.82806585698152,
              21.011735981016958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_K15.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4474",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01174864208067,
          "to_latitude": 21.011735981016958,
          "to_longitude": 105.82806585698152,
          "from_longitude": 105.82804440117684
        }
      },
      {
        "id": 4468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82770148388097,
            21.011140295031048,
            105.82792186329276,
            21.01151923935877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82770148388097,
              21.011140295031048
            ],
            [
              105.8277671948755,
              21.01125258962399
            ],
            [
              105.82789835565409,
              21.011495853289446
            ],
            [
              105.82791084719223,
              21.01151923935877
            ],
            [
              105.82791587507725,
              21.011517142894228
            ],
            [
              105.82792186329276,
              21.01151493427301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4475",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011140295031048,
          "to_latitude": 21.01151493427301,
          "to_longitude": 105.82792186329276,
          "from_longitude": 105.82770148388097
        }
      },
      {
        "id": 4469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82736325307175,
            21.010964324786524,
            105.82766351772744,
            21.011138623575583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766351772744,
              21.011138623575583
            ],
            [
              105.82760227946501,
              21.011041276352255
            ],
            [
              105.82757780748115,
              21.011002376000487
            ],
            [
              105.82755387020526,
              21.010964324786524
            ],
            [
              105.82736325307175,
              21.011068692814668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT34_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4476",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011138623575583,
          "to_latitude": 21.011068692814668,
          "to_longitude": 105.82736325307175,
          "from_longitude": 105.82766351772744
        }
      },
      {
        "id": 4470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82760227946501,
            21.011037059790922,
            105.82760976967243,
            21.011041276352255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82760227946501,
              21.011041276352255
            ],
            [
              105.82760762454247,
              21.01103826699903
            ],
            [
              105.82760944747133,
              21.01103724110005
            ],
            [
              105.82760976967243,
              21.011037059790922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT34_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4477",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011041276352255,
          "to_latitude": 21.011037059790922,
          "to_longitude": 105.82760976967243,
          "from_longitude": 105.82760227946501
        }
      },
      {
        "id": 4471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82724093328147,
            21.010956002617633,
            105.82755142337912,
            21.011123363684728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724093328147,
              21.011123363684728
            ],
            [
              105.8273107113058,
              21.01108746170957
            ],
            [
              105.8273894679416,
              21.011044268561108
            ],
            [
              105.82755142337912,
              21.010956002617633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT35_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4478",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011123363684728,
          "to_latitude": 21.010956002617633,
          "to_longitude": 105.82755142337912,
          "from_longitude": 105.82724093328147
        }
      },
      {
        "id": 4472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270100638353,
            21.011124966102802,
            105.82724017066585,
            21.01125955210575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724017066585,
              21.011124966102802
            ],
            [
              105.82711562913337,
              21.01118999685335
            ],
            [
              105.827066017167,
              21.01121590137271
            ],
            [
              105.82705663761006,
              21.01122201303051
            ],
            [
              105.8270100638353,
              21.01125237102745
            ],
            [
              105.82701625485102,
              21.011259380683423
            ],
            [
              105.82701647992099,
              21.01125955210575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT36_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4479",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011124966102802,
          "to_latitude": 21.01125955210575,
          "to_longitude": 105.82701647992099,
          "from_longitude": 105.82724017066585
        }
      },
      {
        "id": 4473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8273894679416,
            21.011044268561108,
            105.82739147064292,
            21.011046886308325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8273894679416,
              21.011044268561108
            ],
            [
              105.82739147064292,
              21.011046886308325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT35_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4480",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011044268561108,
          "to_latitude": 21.011046886308325,
          "to_longitude": 105.82739147064292,
          "from_longitude": 105.8273894679416
        }
      },
      {
        "id": 4474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82709832059783,
            21.011203967188354,
            105.827177124891,
            21.011365495563965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.827177124891,
              21.011365495563965
            ],
            [
              105.82710778065461,
              21.011226939927674
            ],
            [
              105.82710028919608,
              21.011207727686113
            ],
            [
              105.82709832059783,
              21.011203967188354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT37_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4481",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011365495563965,
          "to_latitude": 21.011203967188354,
          "to_longitude": 105.82709832059783,
          "from_longitude": 105.827177124891
        }
      },
      {
        "id": 4475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82655022980498,
            21.0115274167199,
            105.82663577560524,
            21.011668668592097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82655022980498,
              21.0115274167199
            ],
            [
              105.82663577560524,
              21.011668668592097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4482",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0115274167199,
          "to_latitude": 21.011668668592097,
          "to_longitude": 105.82663577560524,
          "from_longitude": 105.82655022980498
        }
      },
      {
        "id": 4476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8266195172781,
            21.011317743873036,
            105.82678129231846,
            21.011591956901135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266195172781,
              21.011317743873036
            ],
            [
              105.82678129231846,
              21.011591956901135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4483",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011317743873036,
          "to_latitude": 21.011591956901135,
          "to_longitude": 105.82678129231846,
          "from_longitude": 105.8266195172781
        }
      },
      {
        "id": 4477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276650081917,
            21.010832173338017,
            105.82779386800593,
            21.011020781439306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276650081917,
              21.010832173338017
            ],
            [
              105.82776019018836,
              21.011005881265426
            ],
            [
              105.82777389422796,
              21.01101141189288
            ],
            [
              105.82777617133205,
              21.01101233204167
            ],
            [
              105.82779386800593,
              21.011020781439306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT38_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4484",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010832173338017,
          "to_latitude": 21.011020781439306,
          "to_longitude": 105.82779386800593,
          "from_longitude": 105.8276650081917
        }
      },
      {
        "id": 4478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82777908982641,
            21.011002931724068,
            105.82787029290598,
            21.01113118580639
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82787029290598,
              21.01113118580639
            ],
            [
              105.82780896510499,
              21.011021643736353
            ],
            [
              105.82779682245295,
              21.011015454289804
            ],
            [
              105.82777908982641,
              21.01100641616386
            ],
            [
              105.82778127529502,
              21.011002931724068
            ],
            [
              105.82779893400595,
              21.011011648660865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT39_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4485",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01113118580639,
          "to_latitude": 21.011011648660865,
          "to_longitude": 105.82779893400595,
          "from_longitude": 105.82787029290598
        }
      },
      {
        "id": 4479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290269364318,
            21.010519575971415,
            105.82943944316112,
            21.010689375786693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290269364318,
              21.010689375786693
            ],
            [
              105.82907343851268,
              21.010653984825932
            ],
            [
              105.82913855190665,
              21.01061814187719
            ],
            [
              105.8292470086374,
              21.010558441228536
            ],
            [
              105.82926119397158,
              21.010550631451977
            ],
            [
              105.82932973048705,
              21.010527280527107
            ],
            [
              105.82943944316112,
              21.010519575971415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4486",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010689375786693,
          "to_latitude": 21.010519575971415,
          "to_longitude": 105.82943944316112,
          "from_longitude": 105.8290269364318
        }
      },
      {
        "id": 4480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82870626707202,
            21.010755428129933,
            105.82877011785024,
            21.010843088666526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82870626707202,
              21.010755428129933
            ],
            [
              105.82876142213695,
              21.010834020177555
            ],
            [
              105.8287685388221,
              21.01084090151784
            ],
            [
              105.82877011785024,
              21.010843088666526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A15.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4487",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010755428129933,
          "to_latitude": 21.010843088666526,
          "to_longitude": 105.82877011785024,
          "from_longitude": 105.82870626707202
        }
      },
      {
        "id": 4481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82891067488393,
            21.010623073638136,
            105.82898071564541,
            21.01073616227111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82891067488393,
              21.010623073638136
            ],
            [
              105.82896673563914,
              21.01071463604907
            ],
            [
              105.82896854107489,
              21.010717584415698
            ],
            [
              105.82897057073617,
              21.010720899302942
            ],
            [
              105.82897959922389,
              21.010734484999897
            ],
            [
              105.82898071564541,
              21.01073616227111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A15.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4488",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010623073638136,
          "to_latitude": 21.01073616227111,
          "to_longitude": 105.82898071564541,
          "from_longitude": 105.82891067488393
        }
      },
      {
        "id": 4482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82880940951644,
            21.010686318535015,
            105.82887835524478,
            21.010786635508428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82880940951644,
              21.010686318535015
            ],
            [
              105.82886071682655,
              21.010769583615527
            ],
            [
              105.82886663873926,
              21.010775230642132
            ],
            [
              105.82887657758155,
              21.010784709457454
            ],
            [
              105.82887835524478,
              21.010786635508428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A15.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4489",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010686318535015,
          "to_latitude": 21.010786635508428,
          "to_longitude": 105.82887835524478,
          "from_longitude": 105.82880940951644
        }
      },
      {
        "id": 4483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82827497244757,
            21.010670024882504,
            105.82839940939509,
            21.01079971954522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839826056102,
              21.010670024882504
            ],
            [
              105.82838420486928,
              21.010691743640244
            ],
            [
              105.82839940939509,
              21.010700395688335
            ],
            [
              105.82836457392779,
              21.010754223230975
            ],
            [
              105.82829517881154,
              21.0107893650544
            ],
            [
              105.82827497244757,
              21.01079971954522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4490",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010670024882504,
          "to_latitude": 21.01079971954522,
          "to_longitude": 105.82827497244757,
          "from_longitude": 105.82839826056102
        }
      },
      {
        "id": 4484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82827701571098,
            21.01066203146185,
            105.82841162158434,
            21.010803061471677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840732471297,
              21.01066203146185
            ],
            [
              105.82841162158434,
              21.01066447725371
            ],
            [
              105.82839420768002,
              21.010691386040662
            ],
            [
              105.82840697381003,
              21.010698650861556
            ],
            [
              105.82836879920157,
              21.010757638534052
            ],
            [
              105.82829628991591,
              21.01079199706622
            ],
            [
              105.82827701571098,
              21.010803061471677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4491",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01066203146185,
          "to_latitude": 21.010803061471677,
          "to_longitude": 105.82827701571098,
          "from_longitude": 105.82840732471297
        }
      },
      {
        "id": 4485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82827900946477,
            21.010622487778992,
            105.82846270163394,
            21.01080632144553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82846270163394,
              21.010622487778992
            ],
            [
              105.82837302351368,
              21.010761053841684
            ],
            [
              105.82834571227468,
              21.010781120996523
            ],
            [
              105.82829795711616,
              21.010794301209703
            ],
            [
              105.82827900946477,
              21.01080632144553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4492",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010622487778992,
          "to_latitude": 21.01080632144553,
          "to_longitude": 105.82827900946477,
          "from_longitude": 105.82846270163394
        }
      },
      {
        "id": 4486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818762195335,
            21.010390530108264,
            105.82839427509384,
            21.01065687587643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839427509384,
              21.010390530108264
            ],
            [
              105.82830519695418,
              21.010520501728422
            ],
            [
              105.82828967372559,
              21.01054315162268
            ],
            [
              105.82822351004809,
              21.010639688034548
            ],
            [
              105.82821155341021,
              21.010643494619018
            ],
            [
              105.82818762195335,
              21.01065687587643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4493",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010390530108264,
          "to_latitude": 21.01065687587643,
          "to_longitude": 105.82818762195335,
          "from_longitude": 105.82839427509384
        }
      },
      {
        "id": 4487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818593686845,
            21.01048215950926,
            105.82830651118724,
            21.010654119302462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828295974153,
              21.01048215950926
            ],
            [
              105.82828043305898,
              21.010506451554868
            ],
            [
              105.82830519695418,
              21.010520501728422
            ],
            [
              105.82830651118724,
              21.010521247718515
            ],
            [
              105.82828967372559,
              21.01054315162268
            ],
            [
              105.82821875289426,
              21.010635410025728
            ],
            [
              105.82820920093494,
              21.01064076929363
            ],
            [
              105.82818593686845,
              21.010654119302462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4494",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01048215950926,
          "to_latitude": 21.010654119302462,
          "to_longitude": 105.82818593686845,
          "from_longitude": 105.828295974153
        }
      },
      {
        "id": 4488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817245330881,
            21.010477535409414,
            105.82829894582292,
            21.01065584365715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82828642480608,
              21.010477535409414
            ],
            [
              105.82826864656548,
              21.010506053700187
            ],
            [
              105.82829894582292,
              21.01052299344848
            ],
            [
              105.82821252584631,
              21.010632333236096
            ],
            [
              105.82818259971184,
              21.01064866199382
            ],
            [
              105.82817466192263,
              21.010652992757993
            ],
            [
              105.82817245330881,
              21.010654198475525
            ],
            [
              105.82817422556356,
              21.01065584365715
            ],
            [
              105.82817593047697,
              21.01065504774613
            ],
            [
              105.82818415569496,
              21.010651206938014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D2.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4495",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010477535409414,
          "to_latitude": 21.010651206938014,
          "to_longitude": 105.82818415569496,
          "from_longitude": 105.82828642480608
        }
      },
      {
        "id": 4489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82808126889827,
            21.01035147258997,
            105.828207748261,
            21.010482956100503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828207748261,
              21.01036244811264
            ],
            [
              105.82818927683414,
              21.01035147258997
            ],
            [
              105.82811685781721,
              21.010466640447603
            ],
            [
              105.8281071430276,
              21.01047177559876
            ],
            [
              105.82808126889827,
              21.010482956100503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4496",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01036244811264,
          "to_latitude": 21.010482956100503,
          "to_longitude": 105.82808126889827,
          "from_longitude": 105.828207748261
        }
      },
      {
        "id": 4490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807852968554,
            21.010260164805214,
            105.8282200298728,
            21.01047847768656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8282200298728,
              21.010260164805214
            ],
            [
              105.82819038702958,
              21.010307936198735
            ],
            [
              105.82820156136648,
              21.010314191751114
            ],
            [
              105.82811027980516,
              21.010462191554815
            ],
            [
              105.82810486580982,
              21.01046699958196
            ],
            [
              105.82808528122888,
              21.010474980054997
            ],
            [
              105.82808439346493,
              21.010475342052814
            ],
            [
              105.82807852968554,
              21.01047847768656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4497",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010260164805214,
          "to_latitude": 21.01047847768656,
          "to_longitude": 105.82807852968554,
          "from_longitude": 105.8282200298728
        }
      },
      {
        "id": 4491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797422595935,
            21.01008323526788,
            105.82813989057944,
            21.010307908765157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82813989057944,
              21.01008323526788
            ],
            [
              105.82800612694056,
              21.010291249789955
            ],
            [
              105.8279981459743,
              21.010295074949074
            ],
            [
              105.82797422595935,
              21.010307908765157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4498",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01008323526788,
          "to_latitude": 21.010307908765157,
          "to_longitude": 105.82797422595935,
          "from_longitude": 105.82813989057944
        }
      },
      {
        "id": 4492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797557906851,
            21.010152279175795,
            105.82813832574023,
            21.010310122311424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82813832574023,
              21.010152279175795
            ],
            [
              105.82812242350089,
              21.01017718896554
            ],
            [
              105.82809611229257,
              21.010162150034073
            ],
            [
              105.82800835502945,
              21.010292876497427
            ],
            [
              105.82799951109426,
              21.01029667604929
            ],
            [
              105.82797557906851,
              21.010310122311424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4499",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010152279175795,
          "to_latitude": 21.010310122311424,
          "to_longitude": 105.82797557906851,
          "from_longitude": 105.82813832574023
        }
      },
      {
        "id": 4493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797733014132,
            21.010156408123404,
            105.82814531655323,
            21.010312985147422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82814531655323,
              21.010156408123404
            ],
            [
              105.82812682487354,
              21.010185675033863
            ],
            [
              105.82809795407692,
              21.010169248558558
            ],
            [
              105.82801181394338,
              21.010294777215865
            ],
            [
              105.82800253577811,
              21.010299199397277
            ],
            [
              105.82797733014132,
              21.010312985147422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D4.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4500",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010156408123404,
          "to_latitude": 21.010312985147422,
          "to_longitude": 105.82797733014132,
          "from_longitude": 105.82814531655323
        }
      },
      {
        "id": 4494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82734665138975,
            21.010077354366658,
            105.82751092682305,
            21.010212615500457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751092682305,
              21.010156678079092
            ],
            [
              105.82750708815341,
              21.01015969455476
            ],
            [
              105.82749459500924,
              21.010180458159482
            ],
            [
              105.82742368675791,
              21.010212615500457
            ],
            [
              105.82734665138975,
              21.010077354366658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT43_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4501",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010156678079092,
          "to_latitude": 21.010077354366658,
          "to_longitude": 105.82734665138975,
          "from_longitude": 105.82751092682305
        }
      },
      {
        "id": 4495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82778259746306,
            21.009850064268115,
            105.8280863396819,
            21.010043053970566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779821763503,
              21.010043053970566
            ],
            [
              105.82779020174239,
              21.01003008650593
            ],
            [
              105.82778259746306,
              21.010018673297484
            ],
            [
              105.82795663031159,
              21.009922066562172
            ],
            [
              105.8280863396819,
              21.009850064268115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT45_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4502",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010043053970566,
          "to_latitude": 21.009850064268115,
          "to_longitude": 105.8280863396819,
          "from_longitude": 105.82779821763503
        }
      },
      {
        "id": 4496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82762576291523,
            21.0102275286673,
            105.82769382746534,
            21.01034963445233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82769382746534,
              21.01034963445233
            ],
            [
              105.8276530227162,
              21.010276430432825
            ],
            [
              105.82762576291523,
              21.0102275286673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4503",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01034963445233,
          "to_latitude": 21.0102275286673,
          "to_longitude": 105.82762576291523,
          "from_longitude": 105.82769382746534
        }
      },
      {
        "id": 4497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82775245991928,
            21.01007316479933,
            105.82786329530815,
            21.010158495763704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82775245991928,
              21.010095528203117
            ],
            [
              105.82780546432582,
              21.01007316479933
            ],
            [
              105.82781310863065,
              21.010074920516086
            ],
            [
              105.82783307668274,
              21.01010454403699
            ],
            [
              105.82786329530815,
              21.010158495763704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4504",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010095528203117,
          "to_latitude": 21.010158495763704,
          "to_longitude": 105.82786329530815,
          "from_longitude": 105.82775245991928
        }
      },
      {
        "id": 4498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786479733554,
            21.01016172381284,
            105.82793491406386,
            21.010283705707103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82793491406386,
              21.010283705707103
            ],
            [
              105.82787710665116,
              21.010183138131822
            ],
            [
              105.82786479733554,
              21.01016172381284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4505",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010283705707103,
          "to_latitude": 21.01016172381284,
          "to_longitude": 105.82786479733554,
          "from_longitude": 105.82793491406386
        }
      },
      {
        "id": 4499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82775161914797,
            21.01043611451121,
            105.82810297352549,
            21.010845614521475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82810297352549,
              21.010783271641348
            ],
            [
              105.82795532821282,
              21.010845614521475
            ],
            [
              105.82785293732884,
              21.010672558679204
            ],
            [
              105.82783806123021,
              21.010647414781804
            ],
            [
              105.82776481201213,
              21.01052361273769
            ],
            [
              105.82775161914797,
              21.01049251741436
            ],
            [
              105.82776705467212,
              21.010472786317678
            ],
            [
              105.82780157229361,
              21.01043611451121
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT42_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4506",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010783271641348,
          "to_latitude": 21.01043611451121,
          "to_longitude": 105.82780157229361,
          "from_longitude": 105.82810297352549
        }
      },
      {
        "id": 4500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797547692535,
            21.010347390827178,
            105.8280338553854,
            21.010435326675424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82797547692535,
              21.010347390827178
            ],
            [
              105.82799646327032,
              21.010373798401957
            ],
            [
              105.8280338553854,
              21.010435326675424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D10.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4507",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010347390827178,
          "to_latitude": 21.010435326675424,
          "to_longitude": 105.8280338553854,
          "from_longitude": 105.82797547692535
        }
      },
      {
        "id": 4501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276472117745,
            21.010500069473164,
            105.82773301332321,
            21.010542684650755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276472117745,
              21.010542684650755
            ],
            [
              105.82773085878753,
              21.01050113943802
            ],
            [
              105.82773301332321,
              21.010500069473164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D12.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4508",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010542684650755,
          "to_latitude": 21.010500069473164,
          "to_longitude": 105.82773301332321,
          "from_longitude": 105.8276472117745
        }
      },
      {
        "id": 4502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82763481281934,
            21.010508289827932,
            105.82773763638032,
            21.0105963129428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82773763638032,
              21.010508289827932
            ],
            [
              105.82773527670507,
              21.010509488145633
            ],
            [
              105.82763481281934,
              21.010560645999124
            ],
            [
              105.82765479789094,
              21.0105963129428
            ],
            [
              105.82766852571986,
              21.01058924978035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D12.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4509",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010508289827932,
          "to_latitude": 21.01058924978035,
          "to_longitude": 105.82766852571986,
          "from_longitude": 105.82773763638032
        }
      },
      {
        "id": 4503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82823389509983,
            21.010781120996523,
            105.82836869671972,
            21.01087569127526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82834571227468,
              21.010781120996523
            ],
            [
              105.82836869671972,
              21.010812597634295
            ],
            [
              105.82828192609614,
              21.01087569127526
            ],
            [
              105.82823389509983,
              21.010788553290418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4510",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010781120996523,
          "to_latitude": 21.010788553290418,
          "to_longitude": 105.82823389509983,
          "from_longitude": 105.82834571227468
        }
      },
      {
        "id": 4504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82935127004676,
            21.012403824403275,
            105.82936680950122,
            21.012412058985653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82935127004676,
              21.012412058985653
            ],
            [
              105.82936680950122,
              21.012403824403275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.3_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4511",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012412058985653,
          "to_latitude": 21.012403824403275,
          "to_longitude": 105.82936680950122,
          "from_longitude": 105.82935127004676
        }
      },
      {
        "id": 4505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82955868919845,
            21.012763607383757,
            105.82957243682442,
            21.01277058770094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82955868919845,
              21.01277058770094
            ],
            [
              105.82957243682442,
              21.012763607383757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.6_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4512",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01277058770094,
          "to_latitude": 21.012763607383757,
          "to_longitude": 105.82957243682442,
          "from_longitude": 105.82955868919845
        }
      },
      {
        "id": 4506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925924647462,
            21.01230116252106,
            105.82930813451448,
            21.01232646621255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925924647462,
              21.01232646621255
            ],
            [
              105.82928533177505,
              21.012312206304635
            ],
            [
              105.82928621982941,
              21.012311721460268
            ],
            [
              105.82930813451448,
              21.01230116252106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.1_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4513",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01232646621255,
          "to_latitude": 21.01230116252106,
          "to_longitude": 105.82930813451448,
          "from_longitude": 105.82925924647462
        }
      },
      {
        "id": 4507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292552421549,
            21.012316260334146,
            105.82931676363914,
            21.012346428054958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292552421549,
              21.01234110895453
            ],
            [
              105.82925839201397,
              21.012346428054958
            ],
            [
              105.8292950599063,
              21.012327394504428
            ],
            [
              105.82929837584521,
              21.012325692923486
            ],
            [
              105.82931676363914,
              21.012316260334146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.2_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4514",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01234110895453,
          "to_latitude": 21.012316260334146,
          "to_longitude": 105.82931676363914,
          "from_longitude": 105.8292552421549
        }
      },
      {
        "id": 4508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82955237566033,
            21.012813507934045,
            105.82960095760858,
            21.01283852028375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82955237566033,
              21.01283852028375
            ],
            [
              105.8295829295879,
              21.01282260280068
            ],
            [
              105.82958714833839,
              21.012820474102526
            ],
            [
              105.82960095760858,
              21.012813507934045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.7_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4515",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01283852028375,
          "to_latitude": 21.012813507934045,
          "to_longitude": 105.82960095760858,
          "from_longitude": 105.82955237566033
        }
      },
      {
        "id": 4509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82953875375314,
            21.01281558858868,
            105.82960214650413,
            21.012845449850904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82953875375314,
              21.012838838696734
            ],
            [
              105.82954236768973,
              21.012845449850904
            ],
            [
              105.82958416153166,
              21.012824466471375
            ],
            [
              105.82958830091452,
              21.012822423063383
            ],
            [
              105.82960214650413,
              21.01281558858868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.8_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4516",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012838838696734,
          "to_latitude": 21.01281558858868,
          "to_longitude": 105.82960214650413,
          "from_longitude": 105.82953875375314
        }
      },
      {
        "id": 4510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924978692573,
            21.012233004956578,
            105.82943332730646,
            21.012552109706597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924978692573,
              21.012233004956578
            ],
            [
              105.82926917345068,
              21.01228226544286
            ],
            [
              105.82928533177505,
              21.012312206304635
            ],
            [
              105.82929837584521,
              21.012325692923486
            ],
            [
              105.82930183543203,
              21.012329269997313
            ],
            [
              105.82933012897259,
              21.0123741145466
            ],
            [
              105.82935127004676,
              21.012412058985653
            ],
            [
              105.82943332730646,
              21.012552109706597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.3_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4517",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012233004956578,
          "to_latitude": 21.012552109706597,
          "to_longitude": 105.82943332730646,
          "from_longitude": 105.82924978692573
        }
      },
      {
        "id": 4511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8296351131404,
            21.01375749481801,
            105.82968422194779,
            21.01378400008839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82968422194779,
              21.01375749481801
            ],
            [
              105.82965178114927,
              21.0137752604909
            ],
            [
              105.8296351131404,
              21.01378400008839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3.3_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4518",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01375749481801,
          "to_latitude": 21.01378400008839,
          "to_longitude": 105.8296351131404,
          "from_longitude": 105.82968422194779
        }
      },
      {
        "id": 4512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82951772344904,
            21.013566227277984,
            105.82956502823033,
            21.013591757266493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82956502823033,
              21.013566227277984
            ],
            [
              105.82953501654929,
              21.01358264340733
            ],
            [
              105.82953210975042,
              21.01358423370828
            ],
            [
              105.82951772344904,
              21.013591757266493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3.1_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4519",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013566227277984,
          "to_latitude": 21.013591757266493,
          "to_longitude": 105.82951772344904,
          "from_longitude": 105.82956502823033
        }
      },
      {
        "id": 4513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82954940225811,
            21.013572774820446,
            105.82961392057592,
            21.01360684565172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82961392057592,
              21.013572774820446
            ],
            [
              105.82954940225811,
              21.01360684565172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3.2_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4520",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013572774820446,
          "to_latitude": 21.01360684565172,
          "to_longitude": 105.82954940225811,
          "from_longitude": 105.82961392057592
        }
      },
      {
        "id": 4514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82984837451349,
            21.013261218106393,
            105.83048779893511,
            21.01401253538487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82984837451349,
              21.013261218106393
            ],
            [
              105.82993212275127,
              21.013405080943592
            ],
            [
              105.83011059809245,
              21.01370356993784
            ],
            [
              105.8302403417536,
              21.013798322911004
            ],
            [
              105.8304156060762,
              21.013926305468424
            ],
            [
              105.83048036895022,
              21.014003663139437
            ],
            [
              105.83048779893511,
              21.01401253538487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT225A_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4521",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 108.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013261218106393,
          "to_latitude": 21.01401253538487,
          "to_longitude": 105.83048779893511,
          "from_longitude": 105.82984837451349
        }
      },
      {
        "id": 4515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82945857878673,
            21.012592677834693,
            105.82968536726025,
            21.012986610524134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82945857878673,
              21.012592677834693
            ],
            [
              105.82955868919845,
              21.01277058770094
            ],
            [
              105.8295793581098,
              21.012807318666894
            ],
            [
              105.82958088935544,
              21.012809904362154
            ],
            [
              105.82958263033294,
              21.012812844002532
            ],
            [
              105.82958469659668,
              21.012816333927788
            ],
            [
              105.82958652496893,
              21.012819422174232
            ],
            [
              105.82958714833839,
              21.012820474102526
            ],
            [
              105.82958796785836,
              21.012821859267973
            ],
            [
              105.82958830091452,
              21.012822423063383
            ],
            [
              105.82968536726025,
              21.012986610524134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.6_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4522",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012592677834693,
          "to_latitude": 21.012986610524134,
          "to_longitude": 105.82968536726025,
          "from_longitude": 105.82945857878673
        }
      },
      {
        "id": 4516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82977843879819,
            21.010669715130796,
            105.82979838875633,
            21.010681709708052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82977843879819,
              21.010681709708052
            ],
            [
              105.82979658061562,
              21.01067080239998
            ],
            [
              105.82979758631056,
              21.010670197753676
            ],
            [
              105.82979838875633,
              21.010669715130796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT27_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4523",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010681709708052,
          "to_latitude": 21.010669715130796,
          "to_longitude": 105.82979838875633,
          "from_longitude": 105.82977843879819
        }
      },
      {
        "id": 4517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82913855190665,
            21.01061814187719,
            105.82914562605052,
            21.010627941732263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82913855190665,
              21.01061814187719
            ],
            [
              105.82914203954329,
              21.010622973400473
            ],
            [
              105.8291443717548,
              21.010626206424266
            ],
            [
              105.82914562605052,
              21.010627941732263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4524",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01061814187719,
          "to_latitude": 21.010627941732263,
          "to_longitude": 105.82914562605052,
          "from_longitude": 105.82913855190665
        }
      },
      {
        "id": 4518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82710028919608,
            21.01115916262329,
            105.82718937225536,
            21.011207727686113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718937225536,
              21.01115916262329
            ],
            [
              105.82710028919608,
              21.011207727686113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT37_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4525",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01115916262329,
          "to_latitude": 21.011207727686113,
          "to_longitude": 105.82710028919608,
          "from_longitude": 105.82718937225536
        }
      },
      {
        "id": 4519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82916937013,
            21.01293098264201,
            105.82917676458487,
            21.012934954032048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82917676458487,
              21.01293098264201
            ],
            [
              105.8291722041911,
              21.01293343183384
            ],
            [
              105.82916972306879,
              21.012934764441127
            ],
            [
              105.82916937013,
              21.012934954032048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4526",
          "diaChiLapD": "Ngõ 10, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01293098264201,
          "to_latitude": 21.012934954032048,
          "to_longitude": 105.82916937013,
          "from_longitude": 105.82917676458487
        }
      },
      {
        "id": 4520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859424099523,
            21.012005460368595,
            105.82871568527126,
            21.012072251672326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82871568527126,
              21.0120625634785
            ],
            [
              105.82871048024617,
              21.012065156700544
            ],
            [
              105.82869506050397,
              21.012072251672326
            ],
            [
              105.82866787486772,
              21.012032024323705
            ],
            [
              105.82865008662795,
              21.012005460368595
            ],
            [
              105.82859424099523,
              21.01202814407595
            ],
            [
              105.82859692910267,
              21.012035378470863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4527",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0120625634785,
          "to_latitude": 21.012035378470863,
          "to_longitude": 105.82859692910267,
          "from_longitude": 105.82871568527126
        }
      },
      {
        "id": 4521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82860111541318,
            21.012010151010337,
            105.82872995564469,
            21.012081903516652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872770310705,
              21.012060066235943
            ],
            [
              105.82872995564469,
              21.012064719523757
            ],
            [
              105.82872310162792,
              21.012068134584204
            ],
            [
              105.82869562267175,
              21.012081903516652
            ],
            [
              105.82866301649108,
              21.012033637662352
            ],
            [
              105.82864874950373,
              21.012010151010337
            ],
            [
              105.82860111541318,
              21.01202877085756
            ],
            [
              105.8286028682654,
              21.012032994838524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.6_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4528",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012060066235943,
          "to_latitude": 21.012032994838524,
          "to_longitude": 105.8286028682654,
          "from_longitude": 105.82872770310705
        }
      },
      {
        "id": 4522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871353481018,
            21.012080916664846,
            105.82877340341662,
            21.012190959536547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82877340341662,
              21.012190959536547
            ],
            [
              105.82871353481018,
              21.012080916664846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4529",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012190959536547,
          "to_latitude": 21.012080916664846,
          "to_longitude": 105.82871353481018,
          "from_longitude": 105.82877340341662
        }
      },
      {
        "id": 4523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848629542976,
            21.01167968756001,
            105.82859998896305,
            21.011846755821857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859998896305,
              21.011836836304397
            ],
            [
              105.82858008308332,
              21.011846755821857
            ],
            [
              105.82857244153466,
              21.011832628642754
            ],
            [
              105.82849434583945,
              21.01168825249347
            ],
            [
              105.82848918646422,
              21.01168482729127
            ],
            [
              105.82848629542976,
              21.01167968756001
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4530",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011836836304397,
          "to_latitude": 21.01167968756001,
          "to_longitude": 105.82848629542976,
          "from_longitude": 105.82859998896305
        }
      },
      {
        "id": 4524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848498524352,
            21.011680373165312,
            105.82867472371903,
            21.012013220126637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82867472371903,
              21.012013220126637
            ],
            [
              105.82860209811717,
              21.01188153752666
            ],
            [
              105.82857360152686,
              21.01185003177208
            ],
            [
              105.82856642479145,
              21.01183629071645
            ],
            [
              105.828487846357,
              21.011686017042386
            ],
            [
              105.82848498524352,
              21.011680373165312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4531",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012013220126637,
          "to_latitude": 21.011680373165312,
          "to_longitude": 105.82848498524352,
          "from_longitude": 105.82867472371903
        }
      },
      {
        "id": 4525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848895895742,
            21.011678292655425,
            105.82860406041982,
            21.011837590165317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82860406041982,
              21.011831204734413
            ],
            [
              105.82860123136682,
              21.011826838768243
            ],
            [
              105.82857965442976,
              21.011837590165317
            ],
            [
              105.828575983609,
              21.01183076881338
            ],
            [
              105.82849842610067,
              21.01168665378728
            ],
            [
              105.82849187809317,
              21.011682772894787
            ],
            [
              105.82848895895742,
              21.011678292655425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4532",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011831204734413,
          "to_latitude": 21.011678292655425,
          "to_longitude": 105.82848895895742,
          "from_longitude": 105.82860406041982
        }
      },
      {
        "id": 4526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8289732280095,
            21.01171364102075,
            105.82914219974418,
            21.012028018276403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914219974418,
              21.012028018276403
            ],
            [
              105.82913642502754,
              21.012000456478123
            ],
            [
              105.82908465888934,
              21.011913122905295
            ],
            [
              105.82907857143908,
              21.011902247920336
            ],
            [
              105.82904840234245,
              21.0118483531045
            ],
            [
              105.82904432008793,
              21.01184106138079
            ],
            [
              105.82903903315474,
              21.011831615010088
            ],
            [
              105.8290207511586,
              21.011798738144332
            ],
            [
              105.82901139982384,
              21.011781922283696
            ],
            [
              105.8289732280095,
              21.01171364102075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4533",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012028018276403,
          "to_latitude": 21.01171364102075,
          "to_longitude": 105.8289732280095,
          "from_longitude": 105.82914219974418
        }
      },
      {
        "id": 4527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892820305767,
            21.011654926003033,
            105.82895429923467,
            21.011668421782733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895429923467,
              21.011654926003033
            ],
            [
              105.82893492092155,
              21.011664916151997
            ],
            [
              105.82893169999475,
              21.01166657661766
            ],
            [
              105.82892970878281,
              21.011667611479545
            ],
            [
              105.82892820305767,
              21.011668421782733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4534",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011654926003033,
          "to_latitude": 21.011668421782733,
          "to_longitude": 105.82892820305767,
          "from_longitude": 105.82895429923467
        }
      },
      {
        "id": 4528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892693383039,
            21.011648827173666,
            105.8289634619063,
            21.011666248481667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289634619063,
              21.011651179041273
            ],
            [
              105.82896213686304,
              21.011648827173666
            ],
            [
              105.82893389471339,
              21.01166285457032
            ],
            [
              105.82893079336365,
              21.01166439522759
            ],
            [
              105.82892757176813,
              21.01166593466416
            ],
            [
              105.82892693383039,
              21.011666248481667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4535",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011651179041273,
          "to_latitude": 21.011666248481667,
          "to_longitude": 105.82892693383039,
          "from_longitude": 105.8289634619063
        }
      },
      {
        "id": 4529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82864597132595,
            21.01150999598504,
            105.82893511615605,
            21.011687716236967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82864597132595,
              21.011616012974805
            ],
            [
              105.8286630293184,
              21.011607553384547
            ],
            [
              105.82866858018308,
              21.011599308801294
            ],
            [
              105.82870990071532,
              21.011569473620444
            ],
            [
              105.82882677144497,
              21.01150999598504
            ],
            [
              105.82884687982404,
              21.01154300939782
            ],
            [
              105.8288569810002,
              21.011559575938815
            ],
            [
              105.82887593017246,
              21.011590653058455
            ],
            [
              105.82893511615605,
              21.011687716236967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT17_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4536",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011616012974805,
          "to_latitude": 21.011687716236967,
          "to_longitude": 105.82893511615605,
          "from_longitude": 105.82864597132595
        }
      },
      {
        "id": 4530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82912163934766,
            21.011364744574895,
            105.82926724342411,
            21.011436332067422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912163934766,
              21.011436332067422
            ],
            [
              105.8292647933664,
              21.011365949676794
            ],
            [
              105.82926724342411,
              21.011364744574895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4537",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011436332067422,
          "to_latitude": 21.011364744574895,
          "to_longitude": 105.82926724342411,
          "from_longitude": 105.82912163934766
        }
      },
      {
        "id": 4531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983121803157,
            21.01141476270331,
            105.82993648890147,
            21.01144839515065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82993648890147,
              21.01143435572758
            ],
            [
              105.82990896165381,
              21.01144839515065
            ],
            [
              105.82988857645174,
              21.01141476270331
            ],
            [
              105.82983503866716,
              21.011441759294154
            ],
            [
              105.82983121803157,
              21.01144368553992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4538",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01143435572758,
          "to_latitude": 21.01144368553992,
          "to_longitude": 105.82983121803157,
          "from_longitude": 105.82993648890147
        }
      },
      {
        "id": 4532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298318657085,
            21.011420558455892,
            105.82995035470053,
            21.011456758822543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82994874931613,
              21.011431382120637
            ],
            [
              105.82995035470053,
              21.011434156225757
            ],
            [
              105.82990603946273,
              21.011456758822543
            ],
            [
              105.82988566410329,
              21.011420558455892
            ],
            [
              105.82983578631247,
              21.011443020164133
            ],
            [
              105.8298318657085,
              21.011444785220004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4539",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011431382120637,
          "to_latitude": 21.011444785220004,
          "to_longitude": 105.8298318657085,
          "from_longitude": 105.82994874931613
        }
      },
      {
        "id": 4533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82841751118785,
            21.01136197578669,
            105.8286248046676,
            21.011587141466364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82841751118785,
              21.01136197578669
            ],
            [
              105.82854756233678,
              21.011427797433612
            ],
            [
              105.82858041608665,
              21.011480514113785
            ],
            [
              105.82858076158132,
              21.011512940052565
            ],
            [
              105.82861629740259,
              21.011568310890397
            ],
            [
              105.82861815957071,
              21.011571603113186
            ],
            [
              105.8286248046676,
              21.011587141466364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4540",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01136197578669,
          "to_latitude": 21.011587141466364,
          "to_longitude": 105.8286248046676,
          "from_longitude": 105.82841751118785
        }
      },
      {
        "id": 4534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82861629740259,
            21.011486780337954,
            105.828781345834,
            21.011568310890397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828781345834,
              21.011486780337954
            ],
            [
              105.82861629740259,
              21.011568310890397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4541",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011486780337954,
          "to_latitude": 21.011568310890397,
          "to_longitude": 105.82861629740259,
          "from_longitude": 105.828781345834
        }
      },
      {
        "id": 4535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82947794730195,
            21.010536073655935,
            105.82954852396055,
            21.010572478099423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82947794730195,
              21.010572478099423
            ],
            [
              105.82954852396055,
              21.010536073655935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT28_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4542",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010572478099423,
          "to_latitude": 21.010536073655935,
          "to_longitude": 105.82954852396055,
          "from_longitude": 105.82947794730195
        }
      },
      {
        "id": 4536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82887593017246,
            21.01158377466276,
            105.82888823542142,
            21.011590653058455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82887593017246,
              21.011590653058455
            ],
            [
              105.82888500951296,
              21.01158557786148
            ],
            [
              105.82888788156845,
              21.01158397238653
            ],
            [
              105.82888823542142,
              21.01158377466276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT17_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4543",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011590653058455,
          "to_latitude": 21.01158377466276,
          "to_longitude": 105.82888823542142,
          "from_longitude": 105.82887593017246
        }
      },
      {
        "id": 4537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940751786272,
            21.011605918176944,
            105.82948685710615,
            21.011643256128366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82948550870816,
              21.01162661157061
            ],
            [
              105.82948685710615,
              21.011629184610428
            ],
            [
              105.82945921645087,
              21.011643256128366
            ],
            [
              105.8294389166437,
              21.011605918176944
            ],
            [
              105.82940936276263,
              21.011617208948284
            ],
            [
              105.82940808803455,
              21.01161769567802
            ],
            [
              105.82940751786272,
              21.011617913425034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4544",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01162661157061,
          "to_latitude": 21.011617913425034,
          "to_longitude": 105.82940751786272,
          "from_longitude": 105.82948550870816
        }
      },
      {
        "id": 4538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931974481481,
            21.011437949238243,
            105.82938463742596,
            21.011466950647304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82938463742596,
              21.01144018699903
            ],
            [
              105.82938325144667,
              21.011437949238243
            ],
            [
              105.8293224822376,
              21.011465700789152
            ],
            [
              105.82931974481481,
              21.011466950647304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4545",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01144018699903,
          "to_latitude": 21.011466950647304,
          "to_longitude": 105.82931974481481,
          "from_longitude": 105.82938463742596
        }
      },
      {
        "id": 4539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82775190570132,
            21.011021643736353,
            105.82784683596493,
            21.01135940880014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82780896510499,
              21.011021643736353
            ],
            [
              105.82779927721438,
              21.01103166248423
            ],
            [
              105.82775771700557,
              21.011121102510796
            ],
            [
              105.82775190570132,
              21.011174900740286
            ],
            [
              105.82784683596493,
              21.01135940880014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT39_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4546",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011021643736353,
          "to_latitude": 21.01135940880014,
          "to_longitude": 105.82784683596493,
          "from_longitude": 105.82780896510499
        }
      },
      {
        "id": 4540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781399969595,
            21.010789549845168,
            105.8281197020697,
            21.01101257162644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281197020697,
              21.010789549845168
            ],
            [
              105.82807528515782,
              21.010822331823146
            ],
            [
              105.82792522717172,
              21.010899113651675
            ],
            [
              105.82786731513492,
              21.010952210666574
            ],
            [
              105.82781399969595,
              21.01101257162644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT40_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4547",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010789549845168,
          "to_latitude": 21.01101257162644,
          "to_longitude": 105.82781399969595,
          "from_longitude": 105.8281197020697
        }
      },
      {
        "id": 4541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82779409592605,
            21.010643551151116,
            105.82789398725097,
            21.011002989483636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779409592605,
              21.011002989483636
            ],
            [
              105.82786542853258,
              21.010899161627623
            ],
            [
              105.82788719954452,
              21.010858051041645
            ],
            [
              105.82789398725097,
              21.010845240075685
            ],
            [
              105.82789114336776,
              21.010809590927998
            ],
            [
              105.82787476539356,
              21.01077915962973
            ],
            [
              105.82780178086985,
              21.010643551151116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4548",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011002989483636,
          "to_latitude": 21.010643551151116,
          "to_longitude": 105.82780178086985,
          "from_longitude": 105.82779409592605
        }
      },
      {
        "id": 4542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82768318380239,
            21.01101141189288,
            105.82777389422796,
            21.01113214391422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82768318380239,
              21.01113214391422
            ],
            [
              105.82771518556228,
              21.011117150951453
            ],
            [
              105.82775860868445,
              21.011053628980967
            ],
            [
              105.82777389422796,
              21.01101141189288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT38_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4549",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01113214391422,
          "to_latitude": 21.01101141189288,
          "to_longitude": 105.82777389422796,
          "from_longitude": 105.82768318380239
        }
      },
      {
        "id": 4543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82787476539356,
            21.010773249824492,
            105.82788664599803,
            21.01077915962973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82787476539356,
              21.01077915962973
            ],
            [
              105.82787680192969,
              21.010778146237136
            ],
            [
              105.82788302953529,
              21.010775048581817
            ],
            [
              105.82788664599803,
              21.010773249824492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4550",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01077915962973,
          "to_latitude": 21.010773249824492,
          "to_longitude": 105.82788664599803,
          "from_longitude": 105.82787476539356
        }
      },
      {
        "id": 4544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82763618113357,
            21.010504580487623,
            105.82773555070605,
            21.010553454887248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82763618113357,
              21.01054974824125
            ],
            [
              105.82763826390558,
              21.010553454887248
            ],
            [
              105.82773400344033,
              21.010505357562895
            ],
            [
              105.82773555070605,
              21.010504580487623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D12.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4551",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01054974824125,
          "to_latitude": 21.010504580487623,
          "to_longitude": 105.82773555070605,
          "from_longitude": 105.82763618113357
        }
      },
      {
        "id": 4545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82751171796043,
            21.01024981785988,
            105.82759227792282,
            21.010289830570148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751171796043,
              21.010289830570148
            ],
            [
              105.82756009806798,
              21.010265800695098
            ],
            [
              105.82758903196029,
              21.01025199416394
            ],
            [
              105.82759227792282,
              21.01024981785988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4552",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010289830570148,
          "to_latitude": 21.01024981785988,
          "to_longitude": 105.82759227792282,
          "from_longitude": 105.82751171796043
        }
      },
      {
        "id": 4546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750457222214,
            21.010258855210786,
            105.82759736045098,
            21.01034059598341
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82759736045098,
              21.010258855210786
            ],
            [
              105.82759586411706,
              21.010259448682117
            ],
            [
              105.82758533335395,
              21.010265231772593
            ],
            [
              105.82759173898944,
              21.010278194433
            ],
            [
              105.82750457222214,
              21.01032144211287
            ],
            [
              105.82751514818752,
              21.01034059598341
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4553",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010258855210786,
          "to_latitude": 21.01034059598341,
          "to_longitude": 105.82751514818752,
          "from_longitude": 105.82759736045098
        }
      },
      {
        "id": 4547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750127081215,
            21.01025638892375,
            105.82759597324893,
            21.010302449571483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750127081215,
              21.010298741121773
            ],
            [
              105.82750335454898,
              21.010302449571483
            ],
            [
              105.82757462560818,
              21.010267005398248
            ],
            [
              105.82759374709298,
              21.01025755317071
            ],
            [
              105.8275948624137,
              21.01025694168147
            ],
            [
              105.82759597324893,
              21.01025638892375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4554",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010298741121773,
          "to_latitude": 21.01025638892375,
          "to_longitude": 105.82759597324893,
          "from_longitude": 105.82750127081215
        }
      },
      {
        "id": 4548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82751416176447,
            21.0100247335923,
            105.8277711582942,
            21.01015878221983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277711582942,
              21.0100247335923
            ],
            [
              105.8277198373339,
              21.0100455469892
            ],
            [
              105.82768727093104,
              21.010058754306495
            ],
            [
              105.82760295732925,
              21.01010688635207
            ],
            [
              105.82758443029749,
              21.01012400769379
            ],
            [
              105.82751416176447,
              21.01015878221983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4555",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0100247335923,
          "to_latitude": 21.01015878221983,
          "to_longitude": 105.82751416176447,
          "from_longitude": 105.8277711582942
        }
      },
      {
        "id": 4549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277198373339,
            21.0100455469892,
            105.82773498819896,
            21.010073434469195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277198373339,
              21.0100455469892
            ],
            [
              105.82772120754099,
              21.010048071164427
            ],
            [
              105.82772781167438,
              21.01006022446685
            ],
            [
              105.82773498819896,
              21.010073434469195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4556",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0100455469892,
          "to_latitude": 21.010073434469195,
          "to_longitude": 105.82773498819896,
          "from_longitude": 105.8277198373339
        }
      },
      {
        "id": 4550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82780811594952,
            21.01014774215939,
            105.82788627354252,
            21.010185902543473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82780811594952,
              21.010185902543473
            ],
            [
              105.82786329530815,
              21.010158495763704
            ],
            [
              105.8278692936531,
              21.010155517547425
            ],
            [
              105.82787631169708,
              21.010152031272895
            ],
            [
              105.82788555191055,
              21.010148053670427
            ],
            [
              105.82788627354252,
              21.01014774215939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4557",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010185902543473,
          "to_latitude": 21.01014774215939,
          "to_longitude": 105.82788627354252,
          "from_longitude": 105.82780811594952
        }
      },
      {
        "id": 4551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82779672903538,
            21.010150163307234,
            105.8278876556896,
            21.0101945076812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278876556896,
              21.010150163307234
            ],
            [
              105.82788606828366,
              21.01015098664364
            ],
            [
              105.8278782242767,
              21.010155052292138
            ],
            [
              105.82787094819042,
              21.010158667177052
            ],
            [
              105.82786479733554,
              21.01016172381284
            ],
            [
              105.82779881180599,
              21.0101945076812
            ],
            [
              105.82779672903538,
              21.010190801036977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D9.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4558",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010150163307234,
          "to_latitude": 21.010190801036977,
          "to_longitude": 105.82779672903538,
          "from_longitude": 105.8278876556896
        }
      },
      {
        "id": 4552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82797423835063,
            21.01042749742576,
            105.82804964993328,
            21.01046493729903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82797423835063,
              21.01046493729903
            ],
            [
              105.8280338553854,
              21.010435326675424
            ],
            [
              105.82803880524553,
              21.010432868394037
            ],
            [
              105.82803991129083,
              21.010432319269654
            ],
            [
              105.82804872518467,
              21.010427955345342
            ],
            [
              105.82804964993328,
              21.01042749742576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D10.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4559",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01046493729903,
          "to_latitude": 21.01042749742576,
          "to_longitude": 105.82804964993328,
          "from_longitude": 105.82797423835063
        }
      },
      {
        "id": 4553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82796205959217,
            21.010430475018822,
            105.82805157670896,
            21.01047629933223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82805157670896,
              21.010430475018822
            ],
            [
              105.8280500037392,
              21.01043130009298
            ],
            [
              105.82804081047671,
              21.010436118379772
            ],
            [
              105.82796414237592,
              21.01047629933223
            ],
            [
              105.82796205959217,
              21.010472591787217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D10.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4560",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010430475018822,
          "to_latitude": 21.010472591787217,
          "to_longitude": 105.82796205959217,
          "from_longitude": 105.82805157670896
        }
      },
      {
        "id": 4554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781733729863,
            21.010647414781804,
            105.82783806123021,
            21.010661247351372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82783806123021,
              21.010647414781804
            ],
            [
              105.82782692585576,
              21.010654847410386
            ],
            [
              105.82781859372876,
              21.010660408464375
            ],
            [
              105.82781733729863,
              21.010661247351372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT42_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4561",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010647414781804,
          "to_latitude": 21.010661247351372,
          "to_longitude": 105.82781733729863,
          "from_longitude": 105.82783806123021
        }
      },
      {
        "id": 4555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807987599989,
            21.01034129389215,
            105.82821228576442,
            21.010480679523003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82821228576442,
              21.01035551454095
            ],
            [
              105.82818932645796,
              21.01034129389215
            ],
            [
              105.82811392089909,
              21.01046468299758
            ],
            [
              105.82810544184538,
              21.01047028452212
            ],
            [
              105.82807987599989,
              21.010480679523003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4562",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01035551454095,
          "to_latitude": 21.010480679523003,
          "to_longitude": 105.82807987599989,
          "from_longitude": 105.82821228576442
        }
      },
      {
        "id": 4556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82803472197138,
            21.01039696605292,
            105.82808528122888,
            21.010474980054997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82803472197138,
              21.01039696605292
            ],
            [
              105.82808240565873,
              21.010470543853106
            ],
            [
              105.82808528122888,
              21.010474980054997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D3.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4563",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01039696605292,
          "to_latitude": 21.010474980054997,
          "to_longitude": 105.82808528122888,
          "from_longitude": 105.82803472197138
        }
      },
      {
        "id": 4557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818168588265,
            21.01148613411433,
            105.82846255833623,
            21.011610536535876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818168588265,
              21.01148613411433
            ],
            [
              105.82832240276814,
              21.01154726053451
            ],
            [
              105.82832666078305,
              21.01154911038753
            ],
            [
              105.82833061988549,
              21.01155082982457
            ],
            [
              105.82846255833623,
              21.011610536535876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4564",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01148613411433,
          "to_latitude": 21.011610536535876,
          "to_longitude": 105.82846255833623,
          "from_longitude": 105.82818168588265
        }
      },
      {
        "id": 4558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279828408459,
            21.011401653910845,
            105.82816627568566,
            21.01148345156862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279828408459,
              21.011401653910845
            ],
            [
              105.82806160067138,
              21.01143677466012
            ],
            [
              105.82806352827537,
              21.011437634183007
            ],
            [
              105.82806623598363,
              21.011438841264486
            ],
            [
              105.82816627568566,
              21.01148345156862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4565",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011401653910845,
          "to_latitude": 21.01148345156862,
          "to_longitude": 105.82816627568566,
          "from_longitude": 105.8279828408459
        }
      },
      {
        "id": 4559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900560091765,
            21.011798738144332,
            105.8290207511586,
            21.01180655170131
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290207511586,
              21.011798738144332
            ],
            [
              105.82901680988907,
              21.011800770637443
            ],
            [
              105.82901366569673,
              21.011802391892108
            ],
            [
              105.82901035077259,
              21.011804101591448
            ],
            [
              105.82900560091765,
              21.01180655170131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4566",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011798738144332,
          "to_latitude": 21.01180655170131,
          "to_longitude": 105.82900560091765,
          "from_longitude": 105.8290207511586
        }
      },
      {
        "id": 4560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940653405677,
            21.011599660035365,
            105.82947433286188,
            21.011635961239808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82947433286188,
              21.01162828734155
            ],
            [
              105.82945930597728,
              21.011635961239808
            ],
            [
              105.82943922352348,
              21.011599660035365
            ],
            [
              105.82940865005011,
              21.011615306615727
            ],
            [
              105.82940653405677,
              21.011616389059082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4567",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01162828734155,
          "to_latitude": 21.011616389059082,
          "to_longitude": 105.82940653405677,
          "from_longitude": 105.82947433286188
        }
      },
      {
        "id": 4561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931763046416,
            21.011414104825104,
            105.82939755117393,
            21.011462587532485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82939755117393,
              21.011414104825104
            ],
            [
              105.82931990832886,
              21.01146120623676
            ],
            [
              105.82931763046416,
              21.011462587532485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4568",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011414104825104,
          "to_latitude": 21.011462587532485,
          "to_longitude": 105.82931763046416,
          "from_longitude": 105.82939755117393
        }
      },
      {
        "id": 4562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278967695263,
            21.010852989768235,
            105.82792522717172,
            21.010899113651675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82792522717172,
              21.010899113651675
            ],
            [
              105.82789918823994,
              21.010856911550412
            ],
            [
              105.8278967695263,
              21.010852989768235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT40_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4569",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010899113651675,
          "to_latitude": 21.010852989768235,
          "to_longitude": 105.8278967695263,
          "from_longitude": 105.82792522717172
        }
      },
      {
        "id": 4563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291060791299,
            21.01115442975916,
            105.82911497947575,
            21.011172219710918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82911497947575,
              21.01115442975916
            ],
            [
              105.8291089647265,
              21.011166452101158
            ],
            [
              105.82910676983023,
              21.011170836216134
            ],
            [
              105.8291060791299,
              21.011172219710918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4570",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01115442975916,
          "to_latitude": 21.011172219710918,
          "to_longitude": 105.8291060791299,
          "from_longitude": 105.82911497947575
        }
      },
      {
        "id": 4564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288007621832,
            21.011016990267716,
            105.82881001363106,
            21.011035481011685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881001363106,
              21.011016990267716
            ],
            [
              105.82880609479739,
              21.0110248222608
            ],
            [
              105.82880326245807,
              21.011030481219247
            ],
            [
              105.8288007621832,
              21.011035481011685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4571",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011016990267716,
          "to_latitude": 21.011035481011685,
          "to_longitude": 105.8288007621832,
          "from_longitude": 105.82881001363106
        }
      },
      {
        "id": 4565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82908965135908,
            21.010753190243715,
            105.82936027046392,
            21.010872776394134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82936027046392,
              21.010872776394134
            ],
            [
              105.82923706379084,
              21.01081833155682
            ],
            [
              105.829211011369,
              21.010806819544893
            ],
            [
              105.82920661530228,
              21.010804877354012
            ],
            [
              105.82920313334996,
              21.010803338063397
            ],
            [
              105.82919965332646,
              21.010801799666545
            ],
            [
              105.82919801118523,
              21.01080107334602
            ],
            [
              105.82919452923805,
              21.010799534958434
            ],
            [
              105.82919104729595,
              21.01079799747399
            ],
            [
              105.82908965135908,
              21.010753190243715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4572",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010872776394134,
          "to_latitude": 21.010753190243715,
          "to_longitude": 105.82908965135908,
          "from_longitude": 105.82936027046392
        }
      },
      {
        "id": 4566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919608937331,
            21.010806819544893,
            105.829211011369,
            21.010835722331468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829211011369,
              21.010806819544893
            ],
            [
              105.82919907300736,
              21.010829942500568
            ],
            [
              105.82919700110628,
              21.01083395388819
            ],
            [
              105.82919608937331,
              21.010835722331468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4573",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010806819544893,
          "to_latitude": 21.010835722331468,
          "to_longitude": 105.82919608937331,
          "from_longitude": 105.829211011369
        }
      },
      {
        "id": 4567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82937395928126,
            21.01088093986933,
            105.82962856503585,
            21.010994619756527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937395928126,
              21.01088093986933
            ],
            [
              105.82949600432936,
              21.010935431815327
            ],
            [
              105.82950811674502,
              21.010940839092576
            ],
            [
              105.82952695314603,
              21.01094924933207
            ],
            [
              105.82953042844157,
              21.01095080129345
            ],
            [
              105.82953390373727,
              21.010952353254748
            ],
            [
              105.82953575420244,
              21.010953178814415
            ],
            [
              105.82953922949821,
              21.0109547307756
            ],
            [
              105.82954270382719,
              21.0109562818382
            ],
            [
              105.82962856503585,
              21.010994619756527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4574",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01088093986933,
          "to_latitude": 21.010994619756527,
          "to_longitude": 105.82962856503585,
          "from_longitude": 105.82937395928126
        }
      },
      {
        "id": 4568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82949653916313,
            21.010940839092576,
            105.82950811674502,
            21.01096327114763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950811674502,
              21.010940839092576
            ],
            [
              105.8294997274001,
              21.010957093798204
            ],
            [
              105.82949760792584,
              21.01096119845345
            ],
            [
              105.82949653916313,
              21.01096327114763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.3_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4575",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010940839092576,
          "to_latitude": 21.01096327114763,
          "to_longitude": 105.82949653916313,
          "from_longitude": 105.82950811674502
        }
      },
      {
        "id": 4569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82882238628804,
            21.010689506104097,
            105.82886984794962,
            21.010773367912485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883702228418,
              21.010689506104097
            ],
            [
              105.82882238628804,
              21.01069775610591
            ],
            [
              105.82886984794962,
              21.010773367912485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A15.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4576",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010689506104097,
          "to_latitude": 21.010773367912485,
          "to_longitude": 105.82886984794962,
          "from_longitude": 105.82883702228418
        }
      },
      {
        "id": 4570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871344758303,
            21.010630611014562,
            105.82897935567445,
            21.010834399767212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82872729170805,
              21.010748332959537
            ],
            [
              105.82871344758303,
              21.01075679948278
            ],
            [
              105.82876471203166,
              21.010834399767212
            ],
            [
              105.82886483997446,
              21.010776275404332
            ],
            [
              105.82886663873926,
              21.010775230642132
            ],
            [
              105.82886984794962,
              21.010773367912485
            ],
            [
              105.8288974713318,
              21.01075775476914
            ],
            [
              105.82896854107489,
              21.010717584415698
            ],
            [
              105.82897935567445,
              21.010711471083443
            ],
            [
              105.82892590816631,
              21.010639364081353
            ],
            [
              105.82894072124779,
              21.010630611014562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A15.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4577",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010748332959537,
          "to_latitude": 21.010630611014562,
          "to_longitude": 105.82894072124779,
          "from_longitude": 105.82872729170805
        }
      },
      {
        "id": 4571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82795663031159,
            21.009922066562172,
            105.82802501629187,
            21.010028318126825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82795663031159,
              21.009922066562172
            ],
            [
              105.82802501629187,
              21.010028318126825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT45_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4578",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009922066562172,
          "to_latitude": 21.010028318126825,
          "to_longitude": 105.82802501629187,
          "from_longitude": 105.82795663031159
        }
      },
      {
        "id": 4572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82960504398774,
            21.010402546988466,
            105.82963578360301,
            21.01045683003232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960504398774,
              21.010402546988466
            ],
            [
              105.82962942746481,
              21.0104451976649
            ],
            [
              105.82962994558274,
              21.01044600442927
            ],
            [
              105.82963121663087,
              21.01044798532941
            ],
            [
              105.82963261235378,
              21.010450768589553
            ],
            [
              105.82963578360301,
              21.01045683003232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.4_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4579",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010402546988466,
          "to_latitude": 21.01045683003232,
          "to_longitude": 105.82963578360301,
          "from_longitude": 105.82960504398774
        }
      },
      {
        "id": 4573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82968094880512,
            21.010345151518916,
            105.82972201352936,
            21.010412651908727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82968094880512,
              21.01034688593173
            ],
            [
              105.82968395766764,
              21.010345151518916
            ],
            [
              105.82971390475748,
              21.0103975335092
            ],
            [
              105.82971510269718,
              21.01039899179733
            ],
            [
              105.82971708964347,
              21.01040310352903
            ],
            [
              105.82972116759974,
              21.010411011259045
            ],
            [
              105.82972201352936,
              21.010412651908727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C15.5_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4580",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01034688593173,
          "to_latitude": 21.010412651908727,
          "to_longitude": 105.82972201352936,
          "from_longitude": 105.82968094880512
        }
      },
      {
        "id": 4574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83015108478168,
            21.01026703901296,
            105.83023383997913,
            21.010315387192325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83023260596323,
              21.01026703901296
            ],
            [
              105.83023383997913,
              21.01026910589557
            ],
            [
              105.83017113686529,
              21.010301176461752
            ],
            [
              105.83016775284743,
              21.010302907293912
            ],
            [
              105.83016436691076,
              21.01030463903858
            ],
            [
              105.83015207110769,
              21.010314585735262
            ],
            [
              105.83015108478168,
              21.010315387192325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.4_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4581",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01026703901296,
          "to_latitude": 21.010315387192325,
          "to_longitude": 105.83015108478168,
          "from_longitude": 105.83023260596323
        }
      },
      {
        "id": 4575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83014623975228,
            21.010269673790987,
            105.8302207439259,
            21.01030723031203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302207439259,
              21.010269673790987
            ],
            [
              105.83016839621062,
              21.01029644789651
            ],
            [
              105.83016501123599,
              21.01029817963651
            ],
            [
              105.83016162530441,
              21.01029991228433
            ],
            [
              105.8301474773507,
              21.010306641693674
            ],
            [
              105.83014623975228,
              21.01030723031203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.5_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4582",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010269673790987,
          "to_latitude": 21.01030723031203,
          "to_longitude": 105.83014623975228,
          "from_longitude": 105.8302207439259
        }
      },
      {
        "id": 4576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83019395515325,
            21.010348985786877,
            105.83034736216652,
            21.010460384117316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83034736216652,
              21.010443839215895
            ],
            [
              105.83033357980432,
              21.010421034081226
            ],
            [
              105.83026099681534,
              21.010460384117316
            ],
            [
              105.83025757657524,
              21.010454700546976
            ],
            [
              105.83025475630896,
              21.010450013920202
            ],
            [
              105.83025019632343,
              21.01044243823317
            ],
            [
              105.83024311605213,
              21.010430673036005
            ],
            [
              105.83019395515325,
              21.010348985786877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.3_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4583",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010443839215895,
          "to_latitude": 21.010348985786877,
          "to_longitude": 105.83019395515325,
          "from_longitude": 105.83034736216652
        }
      },
      {
        "id": 4577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023310704154,
            21.010422793633616,
            105.83031592021783,
            21.010458413872477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031468524258,
              21.010422793633616
            ],
            [
              105.83031592021783,
              21.010424859607642
            ],
            [
              105.83026105843201,
              21.01045291955819
            ],
            [
              105.83025757657524,
              21.010454700546976
            ],
            [
              105.83024483291547,
              21.01045324620904
            ],
            [
              105.83023465386088,
              21.010457732518972
            ],
            [
              105.83023310704154,
              21.010458413872477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.1_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4584",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010422793633616,
          "to_latitude": 21.010458413872477,
          "to_longitude": 105.83023310704154,
          "from_longitude": 105.83031468524258
        }
      },
      {
        "id": 4578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83023199936991,
            21.010425427505105,
            105.83030282511349,
            21.01045640779285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83030282511349,
              21.010425427505105
            ],
            [
              105.83025831681977,
              21.010448192805764
            ],
            [
              105.83025475630896,
              21.010450013920202
            ],
            [
              105.83024328938923,
              21.01045035077215
            ],
            [
              105.83023352618699,
              21.010455588343397
            ],
            [
              105.83023199936991,
              21.01045640779285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C13.2_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4585",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010425427505105,
          "to_latitude": 21.01045640779285,
          "to_longitude": 105.83023199936991,
          "from_longitude": 105.83030282511349
        }
      },
      {
        "id": 4579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035236059122,
            21.010561057435698,
            105.83042245078917,
            21.010594073668518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83042245078917,
              21.010561057435698
            ],
            [
              105.83035236059122,
              21.010594073668518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT22_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4586",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010561057435698,
          "to_latitude": 21.010594073668518,
          "to_longitude": 105.83035236059122,
          "from_longitude": 105.83042245078917
        }
      },
      {
        "id": 4580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83014897674177,
            21.01066456332953,
            105.8302352544118,
            21.01070227982697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83023405348804,
              21.01066456332953
            ],
            [
              105.8302352544118,
              21.01066516895401
            ],
            [
              105.8301809296821,
              21.01069295619598
            ],
            [
              105.8301670669566,
              21.01069700163535
            ],
            [
              105.83015041439705,
              21.01070186094221
            ],
            [
              105.83014897674177,
              21.01070227982697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.2_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4587",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01066456332953,
          "to_latitude": 21.01070227982697,
          "to_longitude": 105.83014897674177,
          "from_longitude": 105.83023405348804
        }
      },
      {
        "id": 4581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83014825806802,
            21.010667197196003,
            105.83022219333837,
            21.010699648623266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83022219333837,
              21.010667197196003
            ],
            [
              105.83015048332507,
              21.010698672218695
            ],
            [
              105.83014828685447,
              21.010699635837668
            ],
            [
              105.83014825806802,
              21.010699648623266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.1_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4588",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010667197196003,
          "to_latitude": 21.010699648623266,
          "to_longitude": 105.83014825806802,
          "from_longitude": 105.83022219333837
        }
      },
      {
        "id": 4582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302226938631,
            21.010832766598433,
            105.83029303523166,
            21.010870812566164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029180121001,
              21.010832766598433
            ],
            [
              105.83029303523166,
              21.0108348334803
            ],
            [
              105.8302355204026,
              21.010861685209825
            ],
            [
              105.83023274573536,
              21.010862980425227
            ],
            [
              105.83022458442755,
              21.010869845019364
            ],
            [
              105.8302226938631,
              21.010870812566164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.5_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4589",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010832766598433,
          "to_latitude": 21.010870812566164,
          "to_longitude": 105.8302226938631,
          "from_longitude": 105.83029180121001
        }
      },
      {
        "id": 4583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302210614012,
            21.01083323863707,
            105.83028743003281,
            21.010866969176135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83028743003281,
              21.01083323863707
            ],
            [
              105.83023495361516,
              21.0108600802333
            ],
            [
              105.83023433973074,
              21.010860393938202
            ],
            [
              105.8302321854612,
              21.010861335674978
            ],
            [
              105.83023198587975,
              21.010861425164876
            ],
            [
              105.83023118077597,
              21.010861775028577
            ],
            [
              105.83022756879119,
              21.0108638565228
            ],
            [
              105.8302210614012,
              21.010866969176135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.4_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4590",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01083323863707,
          "to_latitude": 21.010866969176135,
          "to_longitude": 105.8302210614012,
          "from_longitude": 105.83028743003281
        }
      },
      {
        "id": 4584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83033117222564,
            21.011015816418062,
            105.83037489611105,
            21.01109322175878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83037489611105,
              21.01109322175878
            ],
            [
              105.83033117222564,
              21.011015816418062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.6_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4591",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01109322175878,
          "to_latitude": 21.011015816418062,
          "to_longitude": 105.83033117222564,
          "from_longitude": 105.83037489611105
        }
      },
      {
        "id": 4585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028996891096,
            21.01098400117797,
            105.83036461567187,
            21.0110246821192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83036338165294,
              21.01098400117797
            ],
            [
              105.83036461567187,
              21.010986067155997
            ],
            [
              105.83030806574098,
              21.01101503136348
            ],
            [
              105.8303057991823,
              21.01101619313302
            ],
            [
              105.83029778777338,
              21.011020248870253
            ],
            [
              105.83029427041951,
              21.011022049000978
            ],
            [
              105.83028996891096,
              21.0110246821192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.8_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4592",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01098400117797,
          "to_latitude": 21.0110246821192,
          "to_longitude": 105.83028996891096,
          "from_longitude": 105.83036338165294
        }
      },
      {
        "id": 4586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028843666524,
            21.010989114205625,
            105.83035285878667,
            21.011021740563553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83035285878667,
              21.010989114205625
            ],
            [
              105.8303065665096,
              21.011012493388836
            ],
            [
              105.8303048120863,
              21.011013379885146
            ],
            [
              105.83029666162014,
              21.011017859010547
            ],
            [
              105.83029299654133,
              21.011019733023364
            ],
            [
              105.83028843666524,
              21.011021740563553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4.7_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4593",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010989114205625,
          "to_latitude": 21.011021740563553,
          "to_longitude": 105.83028843666524,
          "from_longitude": 105.83035285878667
        }
      },
      {
        "id": 4587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83017048095532,
            21.011333609659648,
            105.83024364163784,
            21.01137309646851
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83024240858434,
              21.011333609659648
            ],
            [
              105.83024364163784,
              21.011335674739932
            ],
            [
              105.83019958471085,
              21.011356793054286
            ],
            [
              105.83018625383383,
              21.011362224159736
            ],
            [
              105.83018450059797,
              21.011363499939442
            ],
            [
              105.83017329715568,
              21.01137165653515
            ],
            [
              105.83017048095532,
              21.01137309646851
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.8_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4594",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011333609659648,
          "to_latitude": 21.01137309646851,
          "to_longitude": 105.83017048095532,
          "from_longitude": 105.83024240858434
        }
      },
      {
        "id": 4588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83016777097515,
            21.011336243532938,
            105.83023054742054,
            21.011368352399913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83023054742054,
              21.011336243532938
            ],
            [
              105.83018482552029,
              21.011359638870413
            ],
            [
              105.83018304428445,
              21.01136054807703
            ],
            [
              105.83017463305765,
              21.011364842404774
            ],
            [
              105.83017068596968,
              21.011366861403953
            ],
            [
              105.83016777097515,
              21.011368352399913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.7_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4595",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011336243532938,
          "to_latitude": 21.011368352399913,
          "to_longitude": 105.83016777097515,
          "from_longitude": 105.83023054742054
        }
      },
      {
        "id": 4589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83008360130817,
            21.011184872684638,
            105.83015016844382,
            21.011220987159003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8301489353879,
              21.011184872684638
            ],
            [
              105.83015016844382,
              21.01118693866892
            ],
            [
              105.83009741252974,
              21.01121283952489
            ],
            [
              105.83009589714459,
              21.011213814272363
            ],
            [
              105.83008921163166,
              21.01121811643515
            ],
            [
              105.83008569618431,
              21.011219914745972
            ],
            [
              105.83008360130817,
              21.011220987159003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.5_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4596",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011184872684638,
          "to_latitude": 21.011220987159003,
          "to_longitude": 105.83008360130817,
          "from_longitude": 105.8301489353879
        }
      },
      {
        "id": 4590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83008089133367,
            21.011187506555807,
            105.83013707327197,
            21.01121624308874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83013707327197,
              21.011187506555807
            ],
            [
              105.83009537508458,
              21.01120968817401
            ],
            [
              105.83009396335777,
              21.011210274028695
            ],
            [
              105.83008648344125,
              21.01121338329255
            ],
            [
              105.8300830859708,
              21.011215120511668
            ],
            [
              105.83008089133367,
              21.01121624308874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2.4_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4597",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011187506555807,
          "to_latitude": 21.01121624308874,
          "to_longitude": 105.83008089133367,
          "from_longitude": 105.83013707327197
        }
      },
      {
        "id": 4591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900433992295,
            21.012314691062443,
            105.8290964099458,
            21.01247833691673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82900433992295,
              21.012314691062443
            ],
            [
              105.8290964099458,
              21.01247833691673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4598",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012314691062443,
          "to_latitude": 21.01247833691673,
          "to_longitude": 105.8290964099458,
          "from_longitude": 105.82900433992295
        }
      },
      {
        "id": 4592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82932520927802,
            21.013013444649758,
            105.82977796677866,
            21.013209939400493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82932520927802,
              21.013209939400493
            ],
            [
              105.82932700750274,
              21.01320966237748
            ],
            [
              105.82933548733185,
              21.013208358334953
            ],
            [
              105.82935151379681,
              21.01320155118486
            ],
            [
              105.82947123049325,
              21.01313870012022
            ],
            [
              105.82951204561488,
              21.013117271721434
            ],
            [
              105.82955232662187,
              21.01308171043494
            ],
            [
              105.82969014347142,
              21.013013444649758
            ],
            [
              105.82977796677866,
              21.013163514541294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4599",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013209939400493,
          "to_latitude": 21.013163514541294,
          "to_longitude": 105.82977796677866,
          "from_longitude": 105.82932520927802
        }
      },
      {
        "id": 4593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292792106412,
            21.01312385535497,
            105.8293309019162,
            21.01321689629774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292792106412,
              21.013125457285476
            ],
            [
              105.82928265123631,
              21.01312385535497
            ],
            [
              105.8293262714731,
              21.013208241578077
            ],
            [
              105.82932700750274,
              21.01320966237748
            ],
            [
              105.82932905029683,
              21.01321360493619
            ],
            [
              105.8293309019162,
              21.01321689629774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4600",
          "diaChiLapD": "Ngõ 10, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013125457285476,
          "to_latitude": 21.01321689629774,
          "to_longitude": 105.8293309019162,
          "from_longitude": 105.8292792106412
        }
      },
      {
        "id": 4594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946859408617,
            21.01348215280249,
            105.82965336228263,
            21.013781104837484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946859408617,
              21.01348215280249
            ],
            [
              105.8295071762964,
              21.013535805987217
            ],
            [
              105.82953501654929,
              21.01358264340733
            ],
            [
              105.82954940225811,
              21.01360684565172
            ],
            [
              105.8296113010281,
              21.013703533117518
            ],
            [
              105.82965336228263,
              21.013770863104373
            ],
            [
              105.82964979381944,
              21.013772479214463
            ],
            [
              105.82963334546142,
              21.013781104837484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B3.2_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4601",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01348215280249,
          "to_latitude": 21.013781104837484,
          "to_longitude": 105.82963334546142,
          "from_longitude": 105.82946859408617
        }
      },
      {
        "id": 4595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82964240061244,
            21.013811324387845,
            105.82965180072661,
            21.013817380288277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965180072661,
              21.013811324387845
            ],
            [
              105.82964240061244,
              21.013817380288277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4602",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013811324387845,
          "to_latitude": 21.013817380288277,
          "to_longitude": 105.82964240061244,
          "from_longitude": 105.82965180072661
        }
      },
      {
        "id": 4596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82940363322967,
            21.012552782179135,
            105.82945194413449,
            21.012577570970553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82940363322967,
              21.012577570970553
            ],
            [
              105.829433182915,
              21.012564269635952
            ],
            [
              105.82945194413449,
              21.012552782179135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.4_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4603",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012577570970553,
          "to_latitude": 21.012552782179135,
          "to_longitude": 105.82945194413449,
          "from_longitude": 105.82940363322967
        }
      },
      {
        "id": 4597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82939364190909,
            21.012560966089367,
            105.82945662101777,
            21.012591611549748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82939364190909,
              21.012586292447697
            ],
            [
              105.82939679081665,
              21.012591611549748
            ],
            [
              105.82945662101777,
              21.012560966089367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.5_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.4604",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012586292447697,
          "to_latitude": 21.012560966089367,
          "to_longitude": 105.82945662101777,
          "from_longitude": 105.82939364190909
        }
      },
      {
        "id": 4598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288974713318,
            21.01075775476914,
            105.82890569505223,
            21.010770185551266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288974713318,
              21.01075775476914
            ],
            [
              105.82890184994199,
              21.010764373054503
            ],
            [
              105.82890569505223,
              21.010770185551266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A15.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4605",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01075775476914,
          "to_latitude": 21.010770185551266,
          "to_longitude": 105.82890569505223,
          "from_longitude": 105.8288974713318
        }
      },
      {
        "id": 4599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82675969104947,
            21.011254442170937,
            105.82691504233573,
            21.011521447824645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82691504233573,
              21.011521447824645
            ],
            [
              105.82675969104947,
              21.011254442170937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4606",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011521447824645,
          "to_latitude": 21.011254442170937,
          "to_longitude": 105.82675969104947,
          "from_longitude": 105.82691504233573
        }
      },
      {
        "id": 4600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82856642479145,
            21.011724745886237,
            105.82861644567602,
            21.01183629071645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82856865696968,
              21.011724745886237
            ],
            [
              105.82861644567602,
              21.011810592416733
            ],
            [
              105.82857716709132,
              21.011830131695344
            ],
            [
              105.828575983609,
              21.01183076881338
            ],
            [
              105.82857488260458,
              21.01183136127169
            ],
            [
              105.828573360843,
              21.011832229456378
            ],
            [
              105.82857244153466,
              21.011832628642754
            ],
            [
              105.82857075069943,
              21.01183336307005
            ],
            [
              105.82856642479145,
              21.01183629071645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4607",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011724745886237,
          "to_latitude": 21.01183629071645,
          "to_longitude": 105.82856642479145,
          "from_longitude": 105.82856865696968
        }
      },
      {
        "id": 4601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82978056850395,
            21.01120469962889,
            105.8298747490834,
            21.01136417543081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298747490834,
              21.01136417543081
            ],
            [
              105.829789260918,
              21.01121941775685
            ],
            [
              105.8297858536738,
              21.011213649210905
            ],
            [
              105.82978056850395,
              21.01120469962889
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4608",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01136417543081,
          "to_latitude": 21.01120469962889,
          "to_longitude": 105.82978056850395,
          "from_longitude": 105.8298747490834
        }
      },
      {
        "id": 4602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82971861532761,
            21.011200201948665,
            105.82981442265356,
            21.011252100447145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981442265356,
              21.01120326459918
            ],
            [
              105.8298125319151,
              21.011200201948665
            ],
            [
              105.82978924936394,
              21.011211937296622
            ],
            [
              105.8297858536738,
              21.011213649210905
            ],
            [
              105.82978245702667,
              21.011215362032996
            ],
            [
              105.82974810970225,
              21.011232676365577
            ],
            [
              105.82973175112552,
              21.011245484393758
            ],
            [
              105.829721879616,
              21.01125045691686
            ],
            [
              105.82971861532761,
              21.011252100447145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.2_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4609",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01120326459918,
          "to_latitude": 21.011252100447145,
          "to_longitude": 105.82971861532761,
          "from_longitude": 105.82981442265356
        }
      },
      {
        "id": 4603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82995525891741,
            21.011691771623163,
            105.83026786417831,
            21.01185640661851
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82995525891741,
              21.01185640661851
            ],
            [
              105.83002368052117,
              21.011820371964895
            ],
            [
              105.8301115616305,
              21.011774088286973
            ],
            [
              105.83026786417831,
              21.011691771623163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4610",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01185640661851,
          "to_latitude": 21.011691771623163,
          "to_longitude": 105.83026786417831,
          "from_longitude": 105.82995525891741
        }
      },
      {
        "id": 4604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82979026639809,
            21.01194330047875,
            105.82979755048115,
            21.011952776826824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82979026639809,
              21.01194330047875
            ],
            [
              105.82979392955856,
              21.01194803087243
            ],
            [
              105.82979755048115,
              21.011952776826824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4611",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01194330047875,
          "to_latitude": 21.011952776826824,
          "to_longitude": 105.82979755048115,
          "from_longitude": 105.82979026639809
        }
      },
      {
        "id": 4605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82759173898944,
            21.010276430432825,
            105.8276530227162,
            21.010300599889195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82759173898944,
              21.010278194433
            ],
            [
              105.82760399122031,
              21.010300599889195
            ],
            [
              105.8276171805719,
              21.01029409844737
            ],
            [
              105.8276530227162,
              21.010276430432825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D11.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4612",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010278194433,
          "to_latitude": 21.010276430432825,
          "to_longitude": 105.8276530227162,
          "from_longitude": 105.82759173898944
        }
      },
      {
        "id": 4606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8269210432055,
            21.011212806998007,
            105.82705594056816,
            21.01144716906102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82705594056816,
              21.01144716906102
            ],
            [
              105.82704722884851,
              21.011432044428545
            ],
            [
              105.8269210432055,
              21.011212806998007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4613",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01144716906102,
          "to_latitude": 21.011212806998007,
          "to_longitude": 105.8269210432055,
          "from_longitude": 105.82705594056816
        }
      },
      {
        "id": 4607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265179989149,
            21.011439088346894,
            105.82796882111961,
            21.012859633790505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265179989149,
              21.011732379862142
            ],
            [
              105.82663577560524,
              21.011668668592097
            ],
            [
              105.82678129231846,
              21.011591956901135
            ],
            [
              105.82691504233573,
              21.011521447824645
            ],
            [
              105.82704632151278,
              21.01145223955946
            ],
            [
              105.82705594056816,
              21.01144716906102
            ],
            [
              105.82707127120906,
              21.011439088346894
            ],
            [
              105.82749479880768,
              21.012130055690136
            ],
            [
              105.8278272216335,
              21.01267955654293
            ],
            [
              105.82792384325337,
              21.01284108452229
            ],
            [
              105.82796194628814,
              21.012851444275107
            ],
            [
              105.82796882111961,
              21.012859633790505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D75_KT46_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4614",
          "diaChiLapD": "Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 75,
          "chieudaiQL": 251,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011732379862142,
          "to_latitude": 21.012859633790505,
          "to_longitude": 105.82796882111961,
          "from_longitude": 105.8265179989149
        }
      },
      {
        "id": 4608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82794576056129,
            21.012257366498655,
            105.8282088101947,
            21.01272488408721
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8282088101947,
              21.01272488408721
            ],
            [
              105.82820669052194,
              21.012716069887112
            ],
            [
              105.82819893141728,
              21.01268378751971
            ],
            [
              105.8281615254382,
              21.0126158600275
            ],
            [
              105.82812702471618,
              21.012594500460178
            ],
            [
              105.82808974380217,
              21.012528308341615
            ],
            [
              105.82804864194942,
              21.012452662677084
            ],
            [
              105.8280106980968,
              21.01238799389383
            ],
            [
              105.8280044295833,
              21.01237358271728
            ],
            [
              105.82797741748725,
              21.012321378537262
            ],
            [
              105.82795849889884,
              21.01228275561814
            ],
            [
              105.82794576056129,
              21.012257366498655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_259_Ngõ 135, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4615",
          "diaChiLapD": "Ngõ 135, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01272488408721,
          "to_latitude": 21.012257366498655,
          "to_longitude": 105.82794576056129,
          "from_longitude": 105.8282088101947
        }
      },
      {
        "id": 4609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283518433368,
            21.012420471749,
            105.82843605509505,
            21.01258259997564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82843605509505,
              21.01258259997564
            ],
            [
              105.8284346543644,
              21.012579954924252
            ],
            [
              105.82840840946707,
              21.012530323084242
            ],
            [
              105.8283518433368,
              21.012420471749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_260_Ngõ 129, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4616",
          "diaChiLapD": "Ngõ 129, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01258259997564,
          "to_latitude": 21.012420471749,
          "to_longitude": 105.8283518433368,
          "from_longitude": 105.82843605509505
        }
      },
      {
        "id": 4610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83062835674984,
            21.00982915940771,
            105.83092906333583,
            21.01048678510029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83092906333583,
              21.01048678510029
            ],
            [
              105.83092808580034,
              21.01048639426086
            ],
            [
              105.83088242849098,
              21.010468062239152
            ],
            [
              105.83082628307176,
              21.010341945289312
            ],
            [
              105.83062835674984,
              21.00982915940771
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13A_Ngõ 81, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4617",
          "diaChiLapD": "Ngõ 81, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01048678510029,
          "to_latitude": 21.00982915940771,
          "to_longitude": 105.83062835674984,
          "from_longitude": 105.83092906333583
        }
      },
      {
        "id": 4611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83040066395749,
            21.00986853223378,
            105.83093355875586,
            21.01083010955803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83092546819493,
              21.010489820333042
            ],
            [
              105.83092616471541,
              21.01049035977052
            ],
            [
              105.83093355875586,
              21.01049606998647
            ],
            [
              105.83077649181683,
              21.01082639326497
            ],
            [
              105.83075696594416,
              21.010827411691945
            ],
            [
              105.83070517740563,
              21.01083010955803
            ],
            [
              105.83068551724156,
              21.010651034448976
            ],
            [
              105.83068057046563,
              21.010605918083574
            ],
            [
              105.83040066395749,
              21.00986853223378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.4618",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 160.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010489820333042,
          "to_latitude": 21.00986853223378,
          "to_longitude": 105.83040066395749,
          "from_longitude": 105.83092546819493
        }
      },
      {
        "id": 4612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82886893148972,
            21.01154002159273,
            105.82898114252565,
            21.011658648504515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82886893148972,
              21.01154002159273
            ],
            [
              105.82893180240062,
              21.011658648504515
            ],
            [
              105.82898114252565,
              21.011633196384665
            ],
            [
              105.8289576471832,
              21.01159391032743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4619",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01154002159273,
          "to_latitude": 21.01159391032743,
          "to_longitude": 105.8289576471832,
          "from_longitude": 105.82886893148972
        }
      },
      {
        "id": 4613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892992382897,
            21.011658648504515,
            105.82893583333149,
            21.01167136514223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82892992382897,
              21.01167136514223
            ],
            [
              105.82893314578128,
              21.011668845703735
            ],
            [
              105.82893583333149,
              21.011666750675104
            ],
            [
              105.82893492092155,
              21.011664916151997
            ],
            [
              105.82893389471339,
              21.01166285457032
            ],
            [
              105.82893180240062,
              21.011658648504515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4.1_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.4620",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01167136514223,
          "to_latitude": 21.011658648504515,
          "to_longitude": 105.82893180240062,
          "from_longitude": 105.82892992382897
        }
      },
      {
        "id": 4614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83901149082298,
            21.010529399213215,
            105.83925228819658,
            21.010851759421257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390884418361,
              21.010529399213215
            ],
            [
              105.8390884236716,
              21.010529419173707
            ],
            [
              105.83908745824279,
              21.01053050870676
            ],
            [
              105.83908221050469,
              21.01053642902584
            ],
            [
              105.839077762831,
              21.010541446691377
            ],
            [
              105.83905056141663,
              21.01057055896714
            ],
            [
              105.83901149082298,
              21.01061975334255
            ],
            [
              105.83916407868503,
              21.010721758032744
            ],
            [
              105.83912851335717,
              21.0107931535622
            ],
            [
              105.83925228819658,
              21.010851759421257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT79.3_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4621",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010529399213215,
          "to_latitude": 21.010851759421257,
          "to_longitude": 105.83925228819658,
          "from_longitude": 105.8390884418361
        }
      },
      {
        "id": 4615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839039994206,
            21.010511263814056,
            105.83926187928977,
            21.01065451296565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839039994206,
              21.010511263814056
            ],
            [
              105.83906234067764,
              21.010525113861522
            ],
            [
              105.8390707057696,
              21.010529826283115
            ],
            [
              105.83908221050469,
              21.01053642902584
            ],
            [
              105.83909647030916,
              21.010544611469793
            ],
            [
              105.83910078987053,
              21.010546957520265
            ],
            [
              105.83913459965817,
              21.010570149078795
            ],
            [
              105.83917828210177,
              21.01059104562319
            ],
            [
              105.83920196278794,
              21.010606964681052
            ],
            [
              105.83921353664945,
              21.010614745781293
            ],
            [
              105.83923970170862,
              21.01063784585935
            ],
            [
              105.83926187928977,
              21.01065451296565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.5_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4622",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010511263814056,
          "to_latitude": 21.01065451296565,
          "to_longitude": 105.83926187928977,
          "from_longitude": 105.839039994206
        }
      },
      {
        "id": 4616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83845696532313,
            21.010491258951244,
            105.83857692564712,
            21.010622899561916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83845696532313,
              21.010491258951244
            ],
            [
              105.838531025026,
              21.01057110124666
            ],
            [
              105.83856561200574,
              21.01060652145897
            ],
            [
              105.83856650210207,
              21.010607441974326
            ],
            [
              105.83857463777336,
              21.01061585515838
            ],
            [
              105.83857692564712,
              21.01061852013742
            ],
            [
              105.83857664226926,
              21.010618760888406
            ],
            [
              105.83857185684427,
              21.010622899561916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.2_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4623",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010491258951244,
          "to_latitude": 21.010622899561916,
          "to_longitude": 105.83857185684427,
          "from_longitude": 105.83845696532313
        }
      },
      {
        "id": 4617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83896169227975,
            21.010846890977856,
            105.83917437677454,
            21.011137214386057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896169227975,
              21.010850117855504
            ],
            [
              105.83896625819945,
              21.01084869805549
            ],
            [
              105.83897206473483,
              21.010846890977856
            ],
            [
              105.83901447866762,
              21.01087452825683
            ],
            [
              105.83904189654797,
              21.010848335946108
            ],
            [
              105.83907597072195,
              21.010866160154574
            ],
            [
              105.83914243756945,
              21.01092105010046
            ],
            [
              105.83917437677454,
              21.01094359513145
            ],
            [
              105.83911122793653,
              21.011066098344045
            ],
            [
              105.83905284354891,
              21.011137214386057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.5_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4624",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010850117855504,
          "to_latitude": 21.011137214386057,
          "to_longitude": 105.83905284354891,
          "from_longitude": 105.83896169227975
        }
      },
      {
        "id": 4618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856650210207,
            21.010480090542714,
            105.83871576251357,
            21.010607441974326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856650210207,
              21.010607441974326
            ],
            [
              105.83865759837941,
              21.010531752623006
            ],
            [
              105.83871576251357,
              21.010480090542714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.2_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4625",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010607441974326,
          "to_latitude": 21.010480090542714,
          "to_longitude": 105.83871576251357,
          "from_longitude": 105.83856650210207
        }
      },
      {
        "id": 4619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83968147555015,
            21.01059579451876,
            105.84004567612003,
            21.010733731138174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84004567612003,
              21.010733731138174
            ],
            [
              105.84004186719523,
              21.010731364526972
            ],
            [
              105.84001551168181,
              21.010715333245777
            ],
            [
              105.83992428498986,
              21.010657138128085
            ],
            [
              105.83987729095007,
              21.010622214794576
            ],
            [
              105.83982333465373,
              21.01069383039351
            ],
            [
              105.83968147555015,
              21.01059579451876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.2_Ngách 378/47, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.4626",
          "diaChiLapD": "Ngách 378/47, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010733731138174,
          "to_latitude": 21.01059579451876,
          "to_longitude": 105.83968147555015,
          "from_longitude": 105.84004567612003
        }
      },
      {
        "id": 4620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83993059301139,
            21.010901444270488,
            105.84002664224214,
            21.011029961535755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002664224214,
              21.01090575381848
            ],
            [
              105.8400213901577,
              21.010901444270488
            ],
            [
              105.84001685900014,
              21.010908040304482
            ],
            [
              105.83994931898705,
              21.011006349143948
            ],
            [
              105.8399484027946,
              21.011007470051545
            ],
            [
              105.83993059301139,
              21.011029961535755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.1_Ngõ 31, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.4627",
          "diaChiLapD": "Ngõ 31, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01090575381848,
          "to_latitude": 21.011029961535755,
          "to_longitude": 105.83993059301139,
          "from_longitude": 105.84002664224214
        }
      },
      {
        "id": 4621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967551735621,
            21.01104586904781,
            105.83979830164279,
            21.011205393670593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979830164279,
              21.01104586904781
            ],
            [
              105.83977244505601,
              21.011075329765774
            ],
            [
              105.83976647863044,
              21.011083285003373
            ],
            [
              105.83970772134785,
              21.011161621669114
            ],
            [
              105.83967551735621,
              21.011205393670593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.2_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.4628",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01104586904781,
          "to_latitude": 21.011205393670593,
          "to_longitude": 105.83967551735621,
          "from_longitude": 105.83979830164279
        }
      },
      {
        "id": 4622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954210222106,
            21.011025316792008,
            105.83963455097393,
            21.011132761660086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83963455097393,
              21.011025316792008
            ],
            [
              105.83956393996017,
              21.01110569718769
            ],
            [
              105.83954210222106,
              21.011132761660086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.3_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.4629",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011025316792008,
          "to_latitude": 21.011132761660086,
          "to_longitude": 105.83954210222106,
          "from_longitude": 105.83963455097393
        }
      },
      {
        "id": 4623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83762154197174,
            21.010884447949035,
            105.83789860835202,
            21.011266237576347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83789860835202,
              21.010884447949035
            ],
            [
              105.83789837572095,
              21.010885852705435
            ],
            [
              105.83789518452672,
              21.010905216391482
            ],
            [
              105.83780175277042,
              21.01102460220436
            ],
            [
              105.83770364169577,
              21.011160272659353
            ],
            [
              105.83762154197174,
              21.011266237576347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4630",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010884447949035,
          "to_latitude": 21.011266237576347,
          "to_longitude": 105.83762154197174,
          "from_longitude": 105.83789860835202
        }
      },
      {
        "id": 4624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803443000654,
            21.010997693754614,
            105.83808155719863,
            21.011080613393048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803443000654,
              21.010997693754614
            ],
            [
              105.83803560331738,
              21.011007643316116
            ],
            [
              105.8380617350219,
              21.011056648219277
            ],
            [
              105.83808155719863,
              21.011080613393048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.2_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4631",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010997693754614,
          "to_latitude": 21.011080613393048,
          "to_longitude": 105.83808155719863,
          "from_longitude": 105.83803443000654
        }
      },
      {
        "id": 4625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794006812681,
            21.010933827200198,
            105.8380786525005,
            21.011108900431257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794006812681,
              21.010933827200198
            ],
            [
              105.83799015949563,
              21.010971219691555
            ],
            [
              105.8380028413261,
              21.010986213085168
            ],
            [
              105.83801603399134,
              21.0110015616388
            ],
            [
              105.83802386777849,
              21.0110143091036
            ],
            [
              105.83803965764314,
              21.01103896523737
            ],
            [
              105.8380487906956,
              21.01105648526252
            ],
            [
              105.83806088116134,
              21.011079606971
            ],
            [
              105.83806243002518,
              21.011082052500182
            ],
            [
              105.8380681994418,
              21.01109116110321
            ],
            [
              105.8380786525005,
              21.011108900431257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.3_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4632",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010933827200198,
          "to_latitude": 21.011108900431257,
          "to_longitude": 105.8380786525005,
          "from_longitude": 105.83794006812681
        }
      },
      {
        "id": 4626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380786525005,
            21.011108900431257,
            105.83818877359465,
            21.011243431264713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380786525005,
              21.011108900431257
            ],
            [
              105.83808471899033,
              21.011114708994782
            ],
            [
              105.83809661671901,
              21.011121062384543
            ],
            [
              105.83810927133494,
              21.01112808043153
            ],
            [
              105.83817972408077,
              21.01115937787198
            ],
            [
              105.83818877359465,
              21.011162801676626
            ],
            [
              105.83816208149122,
              21.01121563630354
            ],
            [
              105.8381472213908,
              21.011243431264713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.3_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4633",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011108900431257,
          "to_latitude": 21.011243431264713,
          "to_longitude": 105.8381472213908,
          "from_longitude": 105.8380786525005
        }
      },
      {
        "id": 4627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792517369493,
            21.010896747309868,
            105.83803443000654,
            21.010997693754614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792517369493,
              21.010896747309868
            ],
            [
              105.83799831784974,
              21.010958035791017
            ],
            [
              105.83800427782559,
              21.010964581010278
            ],
            [
              105.83803283984054,
              21.01099594752427
            ],
            [
              105.83803443000654,
              21.010997693754614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.2_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4634",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010896747309868,
          "to_latitude": 21.010997693754614,
          "to_longitude": 105.83803443000654,
          "from_longitude": 105.83792517369493
        }
      },
      {
        "id": 4628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803283984054,
            21.01092554913711,
            105.83809999452733,
            21.01099594752427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803283984054,
              21.01099594752427
            ],
            [
              105.83809999452733,
              21.01092554913711
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.2_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4635",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01099594752427,
          "to_latitude": 21.01092554913711,
          "to_longitude": 105.83809999452733,
          "from_longitude": 105.83803283984054
        }
      },
      {
        "id": 4629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83808155719863,
            21.010987741059424,
            105.83815602573009,
            21.011080613393048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83808155719863,
              21.011080613393048
            ],
            [
              105.83809196609687,
              21.011074088716192
            ],
            [
              105.83815602573009,
              21.010987741059424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.2_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4636",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011080613393048,
          "to_latitude": 21.010987741059424,
          "to_longitude": 105.83815602573009,
          "from_longitude": 105.83808155719863
        }
      },
      {
        "id": 4630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8383881648328,
            21.01110749583921,
            105.83846808807236,
            21.01120142395302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83846808807236,
              21.01110749583921
            ],
            [
              105.8384549034682,
              21.01112458116708
            ],
            [
              105.83839197688604,
              21.011197983756563
            ],
            [
              105.8383881648328,
              21.01120142395302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.5_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4637",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01110749583921,
          "to_latitude": 21.01120142395302,
          "to_longitude": 105.8383881648328,
          "from_longitude": 105.83846808807236
        }
      },
      {
        "id": 4631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807575132518,
            21.011088998405807,
            105.8383023200526,
            21.011154425278455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807575132518,
              21.011092013584438
            ],
            [
              105.83808014150594,
              21.011089781771357
            ],
            [
              105.83808203016271,
              21.011088998405807
            ],
            [
              105.8380894135994,
              21.011100851183407
            ],
            [
              105.83810418321022,
              21.011110065391055
            ],
            [
              105.83811993595329,
              21.011118601852697
            ],
            [
              105.83814168007008,
              21.011127937058284
            ],
            [
              105.83815780473321,
              21.01113606794302
            ],
            [
              105.83819679856795,
              21.011150193778033
            ],
            [
              105.83820573505592,
              21.01115387555752
            ],
            [
              105.83822212325542,
              21.011154425278455
            ],
            [
              105.83824309081862,
              21.011149244060412
            ],
            [
              105.83826283555022,
              21.01113816538919
            ],
            [
              105.83826628649592,
              21.01113465471649
            ],
            [
              105.83830118033552,
              21.011094144810034
            ],
            [
              105.8383023200526,
              21.011093258551313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.4_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4638",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011092013584438,
          "to_latitude": 21.011093258551313,
          "to_longitude": 105.8383023200526,
          "from_longitude": 105.83807575132518
        }
      },
      {
        "id": 4632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8383023200526,
            21.011018617483202,
            105.83844911424009,
            21.011093258551313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383023200526,
              21.011093258551313
            ],
            [
              105.83833583735051,
              21.011051365357407
            ],
            [
              105.83836455195419,
              21.011018617483202
            ],
            [
              105.83836909262327,
              21.011021628139073
            ],
            [
              105.83839695966819,
              21.01103809119782
            ],
            [
              105.83844911424009,
              21.01106890152296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.4_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4639",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011093258551313,
          "to_latitude": 21.01106890152296,
          "to_longitude": 105.83844911424009,
          "from_longitude": 105.8383023200526
        }
      },
      {
        "id": 4633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83846035205612,
            21.011009596845934,
            105.83868688951415,
            21.011094684227846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83846035205612,
              21.011086776959274
            ],
            [
              105.83847662817041,
              21.0110662396285
            ],
            [
              105.83851224361746,
              21.011018029749764
            ],
            [
              105.83851947330756,
              21.011009596845934
            ],
            [
              105.83859440974796,
              21.01105642283303
            ],
            [
              105.838655639282,
              21.011094684227846
            ],
            [
              105.83868688951415,
              21.01105386794591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.6_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4640",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011086776959274,
          "to_latitude": 21.01105386794591,
          "to_longitude": 105.83868688951415,
          "from_longitude": 105.83846035205612
        }
      },
      {
        "id": 4634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83880042956629,
            21.011032321250795,
            105.83900712434911,
            21.0113667334455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880042956629,
              21.0113667334455
            ],
            [
              105.83880483644006,
              21.011362326567447
            ],
            [
              105.83883032463294,
              21.011354846866737
            ],
            [
              105.83889720739504,
              21.01128419841804
            ],
            [
              105.83894071878358,
              21.01123714449079
            ],
            [
              105.83900712434911,
              21.011172002602454
            ],
            [
              105.83893453803154,
              21.011109244081577
            ],
            [
              105.83886606995067,
              21.011068207640854
            ],
            [
              105.83882565680379,
              21.011032321250795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.8_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4641",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0113667334455,
          "to_latitude": 21.011032321250795,
          "to_longitude": 105.83882565680379,
          "from_longitude": 105.83880042956629
        }
      },
      {
        "id": 4635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853941155637,
            21.011408886321185,
            105.83886596057114,
            21.011854755795856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886596057114,
              21.011408886321185
            ],
            [
              105.83886533780272,
              21.011410016615315
            ],
            [
              105.83878132627852,
              21.011562565351465
            ],
            [
              105.83870807289105,
              21.01164083495261
            ],
            [
              105.8386716442071,
              21.011680207217154
            ],
            [
              105.83853941155637,
              21.011854755795856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT77.9_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4642",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011408886321185,
          "to_latitude": 21.011854755795856,
          "to_longitude": 105.83853941155637,
          "from_longitude": 105.83886596057114
        }
      },
      {
        "id": 4636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859869427937,
            21.01151759192215,
            105.83870807289105,
            21.01164083495261
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386429946532,
              21.01151759192215
            ],
            [
              105.83859869427937,
              21.011583051054064
            ],
            [
              105.83870807289105,
              21.01164083495261
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.9_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4643",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01151759192215,
          "to_latitude": 21.01164083495261,
          "to_longitude": 105.83870807289105,
          "from_longitude": 105.8386429946532
        }
      },
      {
        "id": 4637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878132627852,
            21.011562565351465,
            105.83904740603988,
            21.01186606314123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83878132627852,
              21.011562565351465
            ],
            [
              105.83880648436813,
              21.011578447370162
            ],
            [
              105.8388677695412,
              21.011623342586553
            ],
            [
              105.83889786264358,
              21.011657013649923
            ],
            [
              105.8389238817089,
              21.011685803899272
            ],
            [
              105.83904740603988,
              21.011790137746665
            ],
            [
              105.83898651745854,
              21.01186606314123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.9_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4644",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011562565351465,
          "to_latitude": 21.01186606314123,
          "to_longitude": 105.83898651745854,
          "from_longitude": 105.83878132627852
        }
      },
      {
        "id": 4638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386716442071,
            21.011680207217154,
            105.83883238018466,
            21.011874463460625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386716442071,
              21.011680207217154
            ],
            [
              105.83873458928426,
              21.011728135455602
            ],
            [
              105.83883238018466,
              21.011805580869545
            ],
            [
              105.83878895698075,
              21.011874463460625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.9_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4645",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011680207217154,
          "to_latitude": 21.011874463460625,
          "to_longitude": 105.83878895698075,
          "from_longitude": 105.8386716442071
        }
      },
      {
        "id": 4639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83889282758913,
            21.011415757466516,
            105.83909251394152,
            21.01151204470089
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83889282758913,
              21.011415757466516
            ],
            [
              105.83890095653683,
              21.011419516350873
            ],
            [
              105.83894925034056,
              21.011434175124805
            ],
            [
              105.83897884096257,
              21.01144645578609
            ],
            [
              105.8390125717366,
              21.011465288790582
            ],
            [
              105.83903756128957,
              21.011479241415838
            ],
            [
              105.83908729860441,
              21.011507769265503
            ],
            [
              105.83909251394152,
              21.01151204470089
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.11_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4646",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011415757466516,
          "to_latitude": 21.01151204470089,
          "to_longitude": 105.83909251394152,
          "from_longitude": 105.83889282758913
        }
      },
      {
        "id": 4640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905673329683,
            21.011509719211823,
            105.83930727961132,
            21.011653066264522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905673329683,
              21.011509719211823
            ],
            [
              105.8390873846635,
              21.011526768162845
            ],
            [
              105.839163257431,
              21.011570318691874
            ],
            [
              105.839228009264,
              21.011607618627814
            ],
            [
              105.83925011818182,
              21.01162058284918
            ],
            [
              105.83930727961132,
              21.011653066264522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.10_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4647",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011509719211823,
          "to_latitude": 21.011653066264522,
          "to_longitude": 105.83930727961132,
          "from_longitude": 105.83905673329683
        }
      },
      {
        "id": 4641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83888294006489,
            21.0114245196176,
            105.83905673329683,
            21.011509719211823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905673329683,
              21.011509719211823
            ],
            [
              105.83905308983148,
              21.011507699504232
            ],
            [
              105.83902034976606,
              21.01149092278095
            ],
            [
              105.83901051569396,
              21.011485849989935
            ],
            [
              105.83898203218934,
              21.01147115587983
            ],
            [
              105.8389165055537,
              21.011440116300747
            ],
            [
              105.83889687053235,
              21.011430731057196
            ],
            [
              105.83888294006489,
              21.0114245196176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.10_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4648",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011509719211823,
          "to_latitude": 21.0114245196176,
          "to_longitude": 105.83888294006489,
          "from_longitude": 105.83905673329683
        }
      },
      {
        "id": 4642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909251394152,
            21.01151204470089,
            105.83920994794066,
            21.01158121885154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909251394152,
              21.01151204470089
            ],
            [
              105.83915206435152,
              21.011551348378077
            ],
            [
              105.8391815623773,
              21.011568048032267
            ],
            [
              105.83920994794066,
              21.01158121885154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.11_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4649",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01151204470089,
          "to_latitude": 21.01158121885154,
          "to_longitude": 105.83920994794066,
          "from_longitude": 105.83909251394152
        }
      },
      {
        "id": 4643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756416427738,
            21.011302443061275,
            105.83832206761846,
            21.01161685708044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756416427738,
              21.011302443061275
            ],
            [
              105.8375661401297,
              21.01130401036554
            ],
            [
              105.83759638553018,
              21.01132798028972
            ],
            [
              105.83762416612895,
              21.011346365059342
            ],
            [
              105.83765579516759,
              21.01137040583845
            ],
            [
              105.83768295568738,
              21.011391779710642
            ],
            [
              105.83771010954779,
              21.011411959548273
            ],
            [
              105.83773151897513,
              21.011428880829136
            ],
            [
              105.83774269280144,
              21.011435995612466
            ],
            [
              105.83775353419311,
              21.011440423132516
            ],
            [
              105.83779047018794,
              21.011446216106304
            ],
            [
              105.83781895939491,
              21.011450411310275
            ],
            [
              105.8378488477183,
              21.011440227512704
            ],
            [
              105.83786003592127,
              21.01142735205379
            ],
            [
              105.83787777587631,
              21.01141382201075
            ],
            [
              105.83789043889348,
              21.011402706897943
            ],
            [
              105.83790785879515,
              21.011388880363842
            ],
            [
              105.83793792334681,
              21.01136065196852
            ],
            [
              105.83796147185447,
              21.011344295123415
            ],
            [
              105.83796229583272,
              21.01134353867965
            ],
            [
              105.83797250743488,
              21.011344052002876
            ],
            [
              105.83798430801757,
              21.011349370783165
            ],
            [
              105.83801498793447,
              21.01137431306988
            ],
            [
              105.83803989671722,
              21.011391217076937
            ],
            [
              105.83806316833304,
              21.011411736621966
            ],
            [
              105.838083932029,
              21.01142636052512
            ],
            [
              105.83810743431481,
              21.011440989904386
            ],
            [
              105.8381267796561,
              21.011454317435366
            ],
            [
              105.83814367498715,
              21.011465573293435
            ],
            [
              105.83816318121706,
              21.01147946092991
            ],
            [
              105.83817510057028,
              21.011489679103658
            ],
            [
              105.83819831748669,
              21.011519512948308
            ],
            [
              105.83822300057484,
              21.01153698887657
            ],
            [
              105.83823710471181,
              21.011556037043118
            ],
            [
              105.83832206761846,
              21.01161685708044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT73.4_Ngõ 178, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4650",
          "diaChiLapD": "Ngõ 178, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011302443061275,
          "to_latitude": 21.01161685708044,
          "to_longitude": 105.83832206761846,
          "from_longitude": 105.83756416427738
        }
      },
      {
        "id": 4644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756614295756,
            21.01130003706697,
            105.83837557953353,
            21.011628542183715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756614295756,
              21.01130003706697
            ],
            [
              105.83756802720947,
              21.011300875919908
            ],
            [
              105.83759407205278,
              21.011312458022648
            ],
            [
              105.83761707241096,
              21.01132937239079
            ],
            [
              105.83764709881753,
              21.01135133009612
            ],
            [
              105.83768223315326,
              21.01137624961295
            ],
            [
              105.837730449699,
              21.011408274510963
            ],
            [
              105.8377499247755,
              21.011420725319468
            ],
            [
              105.83776588313448,
              21.011429906606388
            ],
            [
              105.83778372152035,
              21.011434001610528
            ],
            [
              105.8377935777332,
              21.011433056180174
            ],
            [
              105.83782820022381,
              21.01142332688247
            ],
            [
              105.8378526471486,
              21.01141454368142
            ],
            [
              105.83786470772525,
              21.01140940546343
            ],
            [
              105.8378821276302,
              21.011395578931857
            ],
            [
              105.83791505905091,
              21.01136793435801
            ],
            [
              105.83794985211523,
              21.011331915848654
            ],
            [
              105.8379587174331,
              21.011324404330818
            ],
            [
              105.83797242901922,
              21.011330012668314
            ],
            [
              105.83798455966246,
              21.01133742078775
            ],
            [
              105.83799796858936,
              21.01134601757686
            ],
            [
              105.83803055784396,
              21.011370950457497
            ],
            [
              105.83806409365482,
              21.011394682800347
            ],
            [
              105.83809539700478,
              21.011417231166195
            ],
            [
              105.8381387949504,
              21.01144951180777
            ],
            [
              105.83816632480784,
              21.011469487396052
            ],
            [
              105.83819730573408,
              21.011493202489746
            ],
            [
              105.83821622015753,
              21.011519098693615
            ],
            [
              105.83822998754987,
              21.011534862589425
            ],
            [
              105.83823957308397,
              21.011542284130744
            ],
            [
              105.8382533473472,
              21.011550841163384
            ],
            [
              105.83827156199163,
              21.011563566377212
            ],
            [
              105.83830247952498,
              21.011587291698234
            ],
            [
              105.83837557953353,
              21.011628542183715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT73.3_Ngõ 178, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4651",
          "diaChiLapD": "Ngõ 178, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01130003706697,
          "to_latitude": 21.011628542183715,
          "to_longitude": 105.83837557953353,
          "from_longitude": 105.83756614295756
        }
      },
      {
        "id": 4645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720049026603,
            21.010580679728204,
            105.83785384099914,
            21.011203407630436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83785384099914,
              21.010670297981147
            ],
            [
              105.83771869999197,
              21.010580679728204
            ],
            [
              105.83761378191274,
              21.01063178762995
            ],
            [
              105.83748884819424,
              21.010806575879393
            ],
            [
              105.83727979839324,
              21.011118051971682
            ],
            [
              105.8372283703577,
              21.011165686375865
            ],
            [
              105.83720049026603,
              21.011203407630436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.23_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.4652",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010670297981147,
          "to_latitude": 21.011203407630436,
          "to_longitude": 105.83720049026603,
          "from_longitude": 105.83785384099914
        }
      },
      {
        "id": 4646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920987033345,
            21.01121704644021,
            105.83938524792065,
            21.01159176347365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920987033345,
              21.01159176347365
            ],
            [
              105.8392180914481,
              21.01158241881421
            ],
            [
              105.83925335748538,
              21.01156221768578
            ],
            [
              105.83929555359856,
              21.01151184177628
            ],
            [
              105.83932370188148,
              21.011482427629595
            ],
            [
              105.8393354022478,
              21.01146982322784
            ],
            [
              105.83935184276251,
              21.011451520485412
            ],
            [
              105.839372598171,
              21.01143317748864
            ],
            [
              105.83937892799175,
              21.011425523051656
            ],
            [
              105.83938524792065,
              21.011412834091608
            ],
            [
              105.8393817768141,
              21.011403281554287
            ],
            [
              105.83936204390137,
              21.011382248868728
            ],
            [
              105.83927992611399,
              21.011277421078926
            ],
            [
              105.83929163482594,
              21.011266310571934
            ],
            [
              105.83934703155087,
              21.01121704644021
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT77.12_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4653",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01159176347365,
          "to_latitude": 21.01121704644021,
          "to_longitude": 105.83934703155087,
          "from_longitude": 105.83920987033345
        }
      },
      {
        "id": 4647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83780050753656,
            21.01088868404046,
            105.8378243238388,
            21.010968639381705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83781453879652,
              21.010968639381705
            ],
            [
              105.8378099575217,
              21.01096701376073
            ],
            [
              105.83780050753656,
              21.010962370625386
            ],
            [
              105.8378243238388,
              21.01088868404046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4654",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.71,
          "chatLieu": "HDPE",
          "chieuDai": 10.144,
          "from_latitude": 21.010968639381705,
          "to_latitude": 21.01088868404046,
          "to_longitude": 105.8378243238388,
          "from_longitude": 105.83781453879652
        }
      },
      {
        "id": 4648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376500711295,
            21.010962370625386,
            105.83780050753656,
            21.011172501398818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83780050753656,
              21.010962370625386
            ],
            [
              105.83772351906762,
              21.011062687972377
            ],
            [
              105.8376500711295,
              21.011172501398818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4655",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 27.851,
          "from_latitude": 21.010962370625386,
          "to_latitude": 21.011172501398818,
          "to_longitude": 105.8376500711295,
          "from_longitude": 105.83780050753656
        }
      },
      {
        "id": 4649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373769152761,
            21.011464813635904,
            105.8376242658279,
            21.01171604252094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373769152761,
              21.01171604252094
            ],
            [
              105.83738003356302,
              21.01170962511966
            ],
            [
              105.83742481751162,
              21.01161747633532
            ],
            [
              105.83739266109716,
              21.011564441893082
            ],
            [
              105.83747467242755,
              21.011464813635904
            ],
            [
              105.8376242658279,
              21.011572690948444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4656",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01171604252094,
          "to_latitude": 21.011572690948444,
          "to_longitude": 105.8376242658279,
          "from_longitude": 105.8373769152761
        }
      },
      {
        "id": 4650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375523907782,
            21.011659803992607,
            105.83798978327818,
            21.011881002481218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375523907782,
              21.011824048125487
            ],
            [
              105.83766389946197,
              21.011709662216123
            ],
            [
              105.8377149505534,
              21.011659803992607
            ],
            [
              105.83778915810196,
              21.011718541125074
            ],
            [
              105.83785970123348,
              21.011776662203665
            ],
            [
              105.83798978327818,
              21.011881002481218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.4_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4657",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011824048125487,
          "to_latitude": 21.011881002481218,
          "to_longitude": 105.83798978327818,
          "from_longitude": 105.8375523907782
        }
      },
      {
        "id": 4651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83745409090857,
            21.01195850307573,
            105.83771769193945,
            21.012306161048095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83771769193945,
              21.01195850307573
            ],
            [
              105.83771286392015,
              21.011962777419726
            ],
            [
              105.83766827747662,
              21.01200225105985
            ],
            [
              105.83755034444675,
              21.012171169159753
            ],
            [
              105.83754353164588,
              21.012180723583537
            ],
            [
              105.83745409090857,
              21.012306161048095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT75.5_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4658",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01195850307573,
          "to_latitude": 21.012306161048095,
          "to_longitude": 105.83745409090857,
          "from_longitude": 105.83771769193945
        }
      },
      {
        "id": 4652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83754353164588,
            21.012180723583537,
            105.83769333763833,
            21.01227037449635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754353164588,
              21.012180723583537
            ],
            [
              105.83760289515969,
              21.01221724047026
            ],
            [
              105.83769333763833,
              21.01227037449635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.5_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4659",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012180723583537,
          "to_latitude": 21.01227037449635,
          "to_longitude": 105.83769333763833,
          "from_longitude": 105.83754353164588
        }
      },
      {
        "id": 4653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779464904731,
            21.011944597484735,
            105.83793906690278,
            21.01210315442347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779464904731,
              21.011944597484735
            ],
            [
              105.83789651175228,
              21.012023138877996
            ],
            [
              105.83793906690278,
              21.012059671578974
            ],
            [
              105.83790512022443,
              21.01210207442606
            ],
            [
              105.83790425572478,
              21.01210315442347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.6_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4660",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011944597484735,
          "to_latitude": 21.01210315442347,
          "to_longitude": 105.83790425572478,
          "from_longitude": 105.83779464904731
        }
      },
      {
        "id": 4654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769967379767,
            21.01186572224801,
            105.83779464904731,
            21.011944597484735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779464904731,
              21.011944597484735
            ],
            [
              105.83769967379767,
              21.01186572224801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.6_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4661",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011944597484735,
          "to_latitude": 21.01186572224801,
          "to_longitude": 105.83769967379767,
          "from_longitude": 105.83779464904731
        }
      },
      {
        "id": 4655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792922379712,
            21.011908038252486,
            105.83839409878131,
            21.012134865324928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792922379712,
              21.012123592467205
            ],
            [
              105.83793003294106,
              21.01212259041956
            ],
            [
              105.8379981148687,
              21.012038312166908
            ],
            [
              105.83811182491036,
              21.011908038252486
            ],
            [
              105.83824754916357,
              21.01201811400584
            ],
            [
              105.83839409878131,
              21.012134865324928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.9_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4662",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012123592467205,
          "to_latitude": 21.012134865324928,
          "to_longitude": 105.83839409878131,
          "from_longitude": 105.83792922379712
        }
      },
      {
        "id": 4656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792528909618,
            21.01180739556934,
            105.83857652920582,
            21.012132399237366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792528909618,
              21.01212006307543
            ],
            [
              105.83792562057062,
              21.012119649572746
            ],
            [
              105.83793383678588,
              21.012109408095128
            ],
            [
              105.83792790696315,
              21.012090875133115
            ],
            [
              105.83798568887995,
              21.012024019322627
            ],
            [
              105.83808547747192,
              21.01190619719267
            ],
            [
              105.83817692082191,
              21.01180739556934
            ],
            [
              105.8382582509166,
              21.01186567017793
            ],
            [
              105.8383819702526,
              21.01196587852933
            ],
            [
              105.83857652920582,
              21.012132399237366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.8_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4663",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01212006307543,
          "to_latitude": 21.012132399237366,
          "to_longitude": 105.83857652920582,
          "from_longitude": 105.83792528909618
        }
      },
      {
        "id": 4657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83791975502275,
            21.01211780315149,
            105.83808448512562,
            21.01224306050399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792364987801,
              21.01211780315149
            ],
            [
              105.83792340000763,
              21.012118188252348
            ],
            [
              105.83791975502275,
              21.012123818838507
            ],
            [
              105.83808448512562,
              21.01224306050399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.7_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4664",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01211780315149,
          "to_latitude": 21.01224306050399,
          "to_longitude": 105.83808448512562,
          "from_longitude": 105.83792364987801
        }
      },
      {
        "id": 4658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377383697285,
            21.011991153091596,
            105.83791975502275,
            21.012123818838507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791975502275,
              21.012123818838507
            ],
            [
              105.83791520635373,
              21.01212110266227
            ],
            [
              105.83788483943488,
              21.01210297003734
            ],
            [
              105.83783574454104,
              21.012066319589778
            ],
            [
              105.83776261194919,
              21.012010462994752
            ],
            [
              105.8377383697285,
              21.011991153091596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.7_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4665",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012123818838507,
          "to_latitude": 21.011991153091596,
          "to_longitude": 105.8377383697285,
          "from_longitude": 105.83791975502275
        }
      },
      {
        "id": 4659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371710885195,
            21.011707081507208,
            105.83747019029501,
            21.011792284383457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83747019029501,
              21.011792284383457
            ],
            [
              105.83739405849943,
              21.01173259464568
            ],
            [
              105.83730203581955,
              21.011707171020074
            ],
            [
              105.83726387534972,
              21.011707081507208
            ],
            [
              105.83717785648386,
              21.011779484520577
            ],
            [
              105.8371710885195,
              21.011785181037467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT75.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4666",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011792284383457,
          "to_latitude": 21.011785181037467,
          "to_longitude": 105.8371710885195,
          "from_longitude": 105.83747019029501
        }
      },
      {
        "id": 4660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83747019029501,
            21.011792284383457,
            105.83764722983037,
            21.01193040472183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83747019029501,
              21.011792284383457
            ],
            [
              105.83753178656335,
              21.011840577391546
            ],
            [
              105.83758810483842,
              21.01188473158925
            ],
            [
              105.83764722983037,
              21.01193040472183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT75.3_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4667",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011792284383457,
          "to_latitude": 21.01193040472183,
          "to_longitude": 105.83764722983037,
          "from_longitude": 105.83747019029501
        }
      },
      {
        "id": 4661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699798538422,
            21.011882272756978,
            105.8373583772547,
            21.01216669644692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701190904515,
              21.011892170701305
            ],
            [
              105.83700079183299,
              21.011882272756978
            ],
            [
              105.83699798538422,
              21.011885794686314
            ],
            [
              105.83700901071612,
              21.011894913600003
            ],
            [
              105.83701179676838,
              21.0118972184724
            ],
            [
              105.83718878667779,
              21.012026323551893
            ],
            [
              105.8373583772547,
              21.01216669644692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT73.6_Ngõ 188, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4668",
          "diaChiLapD": "Ngõ 188, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011892170701305,
          "to_latitude": 21.01216669644692,
          "to_longitude": 105.8373583772547,
          "from_longitude": 105.83701190904515
        }
      },
      {
        "id": 4662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368919034837,
            21.011920425206657,
            105.83699101042008,
            21.01204851935233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368919034837,
              21.01204851935233
            ],
            [
              105.83697538234915,
              21.01194061960162
            ],
            [
              105.83699101042008,
              21.011920425206657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.7_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4669",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01204851935233,
          "to_latitude": 21.011920425206657,
          "to_longitude": 105.83699101042008,
          "from_longitude": 105.8368919034837
        }
      },
      {
        "id": 4663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83678980200459,
            21.012035132588608,
            105.8368919034837,
            21.012154378136287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687943070795,
              21.012035132588608
            ],
            [
              105.83688094251578,
              21.012036627215352
            ],
            [
              105.8368919034837,
              21.01204851935233
            ],
            [
              105.83686336481267,
              21.0120766867751
            ],
            [
              105.83678980200459,
              21.012154378136287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.7_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4670",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012035132588608,
          "to_latitude": 21.012154378136287,
          "to_longitude": 105.83678980200459,
          "from_longitude": 105.83687943070795
        }
      },
      {
        "id": 4664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368919034837,
            21.01204851935233,
            105.83702218451515,
            21.012198769399497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702218451515,
              21.012198769399497
            ],
            [
              105.83693722850914,
              21.012098291700703
            ],
            [
              105.836894817677,
              21.012051723207946
            ],
            [
              105.8368919034837,
              21.01204851935233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.7_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4671",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012198769399497,
          "to_latitude": 21.01204851935233,
          "to_longitude": 105.8368919034837,
          "from_longitude": 105.83702218451515
        }
      },
      {
        "id": 4665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83674449538383,
            21.012172746725646,
            105.83697458836994,
            21.012488141052415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674449538383,
              21.012172746725646
            ],
            [
              105.83674786611468,
              21.012175366662053
            ],
            [
              105.83676603169687,
              21.012189478728637
            ],
            [
              105.83678245343253,
              21.012202229177532
            ],
            [
              105.8368569369358,
              21.012277858367234
            ],
            [
              105.83688075712902,
              21.012304984268432
            ],
            [
              105.83688904880826,
              21.012315842696236
            ],
            [
              105.83689209830578,
              21.012330195289742
            ],
            [
              105.83686314695868,
              21.01236457712118
            ],
            [
              105.83686136931085,
              21.012375952945
            ],
            [
              105.83686617988974,
              21.012380271085018
            ],
            [
              105.83696389668788,
              21.012481419461356
            ],
            [
              105.83696983383231,
              21.012487317215623
            ],
            [
              105.83697458836994,
              21.012488141052415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT73.8_Ngõ 10, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4672",
          "diaChiLapD": "Ngõ 10, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012172746725646,
          "to_latitude": 21.012488141052415,
          "to_longitude": 105.83697458836994,
          "from_longitude": 105.83674449538383
        }
      },
      {
        "id": 4666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697091073813,
            21.012488141052415,
            105.83703715082086,
            21.012623028835723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83703715082086,
              21.012623028835723
            ],
            [
              105.83697091073813,
              21.012570859280586
            ],
            [
              105.83700485955366,
              21.012524807574195
            ],
            [
              105.83697458836994,
              21.012488141052415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT73.8_Ngõ 10, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4673",
          "diaChiLapD": "Ngõ 10, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012623028835723,
          "to_latitude": 21.012488141052415,
          "to_longitude": 105.83697458836994,
          "from_longitude": 105.83703715082086
        }
      },
      {
        "id": 4667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669746614507,
            21.012237543194395,
            105.83683771851778,
            21.01237247172342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669746614507,
              21.012237543194395
            ],
            [
              105.83670841883195,
              21.012247432934764
            ],
            [
              105.83674882738913,
              21.0122839033336
            ],
            [
              105.83683771851778,
              21.01237247172342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.9_Ngõ 12, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4674",
          "diaChiLapD": "Ngõ 12, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012237543194395,
          "to_latitude": 21.01237247172342,
          "to_longitude": 105.83683771851778,
          "from_longitude": 105.83669746614507
        }
      },
      {
        "id": 4668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83660189455505,
            21.012203347994454,
            105.83672887912525,
            21.012358701997815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660189455505,
              21.012358701997815
            ],
            [
              105.83661617790422,
              21.01234022224501
            ],
            [
              105.83661619893984,
              21.01234019956097
            ],
            [
              105.83663966201507,
              21.012309849653963
            ],
            [
              105.83666044136271,
              21.012275709461555
            ],
            [
              105.83666429476341,
              21.012271737099738
            ],
            [
              105.83669746614507,
              21.012237543194395
            ],
            [
              105.83672887912525,
              21.012203347994454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.9_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4675",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012358701997815,
          "to_latitude": 21.012203347994454,
          "to_longitude": 105.83672887912525,
          "from_longitude": 105.83660189455505
        }
      },
      {
        "id": 4669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83648978795392,
            21.012358701997815,
            105.83660189455505,
            21.012489089899145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660189455505,
              21.012358701997815
            ],
            [
              105.8365621555273,
              21.012402448163183
            ],
            [
              105.83655136656044,
              21.012414328933893
            ],
            [
              105.83648978795392,
              21.012489089899145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.9_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4676",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012358701997815,
          "to_latitude": 21.012489089899145,
          "to_longitude": 105.83648978795392,
          "from_longitude": 105.83660189455505
        }
      },
      {
        "id": 4670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615695478876,
            21.012673994013536,
            105.8363059442214,
            21.01282794024608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363059442214,
              21.012673994013536
            ],
            [
              105.83615695478876,
              21.01282794024608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4677",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012673994013536,
          "to_latitude": 21.01282794024608,
          "to_longitude": 105.83615695478876,
          "from_longitude": 105.8363059442214
        }
      },
      {
        "id": 4671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83599443570725,
            21.012831332570567,
            105.83615203039815,
            21.01295226584402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83615203039815,
              21.012831332570567
            ],
            [
              105.8361301075258,
              21.012852943372625
            ],
            [
              105.8360962871156,
              21.012883262818573
            ],
            [
              105.83603469310198,
              21.012939626823904
            ],
            [
              105.83602013082421,
              21.01295226584402
            ],
            [
              105.83601004492998,
              21.01294377721884
            ],
            [
              105.83599808601507,
              21.01293370541094
            ],
            [
              105.83599443570725,
              21.012930629802014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4678",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012831332570567,
          "to_latitude": 21.012930629802014,
          "to_longitude": 105.83599443570725,
          "from_longitude": 105.83615203039815
        }
      },
      {
        "id": 4672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590676669492,
            21.01295226584402,
            105.83602013082421,
            21.013050660116477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602013082421,
              21.01295226584402
            ],
            [
              105.83590676669492,
              21.013050660116477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4679",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01295226584402,
          "to_latitude": 21.013050660116477,
          "to_longitude": 105.83590676669492,
          "from_longitude": 105.83602013082421
        }
      },
      {
        "id": 4673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629077578566,
            21.012648290538234,
            105.83644321193582,
            21.012756320183072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629077578566,
              21.012648290538234
            ],
            [
              105.83629375830752,
              21.012652017938414
            ],
            [
              105.8362993145688,
              21.012658960813
            ],
            [
              105.83630883455116,
              21.01267084652096
            ],
            [
              105.8363119730159,
              21.012674766444366
            ],
            [
              105.83638062174602,
              21.012723689973548
            ],
            [
              105.83644321193582,
              21.012756320183072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4680",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012648290538234,
          "to_latitude": 21.012756320183072,
          "to_longitude": 105.83644321193582,
          "from_longitude": 105.83629077578566
        }
      },
      {
        "id": 4674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362854589702,
            21.01255413341529,
            105.83641233350345,
            21.012673994013536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362854589702,
              21.012653586082962
            ],
            [
              105.83628880290166,
              21.01265691970741
            ],
            [
              105.83629510095282,
              21.012663199582242
            ],
            [
              105.8363059442214,
              21.012673994013536
            ],
            [
              105.83630883455116,
              21.01267084652096
            ],
            [
              105.83633299936187,
              21.012644534231804
            ],
            [
              105.8363559339416,
              21.012617889277287
            ],
            [
              105.83641233350345,
              21.01255413341529
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4681",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012653586082962,
          "to_latitude": 21.01255413341529,
          "to_longitude": 105.83641233350345,
          "from_longitude": 105.8362854589702
        }
      },
      {
        "id": 4675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359098431793,
            21.013112336146634,
            105.8360698684509,
            21.01328825041422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359098431793,
              21.013112336146634
            ],
            [
              105.8359903966019,
              21.01319783251819
            ],
            [
              105.8360698684509,
              21.01328825041422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4682",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013112336146634,
          "to_latitude": 21.01328825041422,
          "to_longitude": 105.8360698684509,
          "from_longitude": 105.8359098431793
        }
      },
      {
        "id": 4676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360698684509,
            21.01328825041422,
            105.83626484863754,
            21.013515621027913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360698684509,
              21.01328825041422
            ],
            [
              105.83608028702847,
              21.013300160621075
            ],
            [
              105.83610859592832,
              21.01333304430315
            ],
            [
              105.83615308814115,
              21.013384725842602
            ],
            [
              105.83625577924192,
              21.01350485310383
            ],
            [
              105.83626484863754,
              21.013515621027913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4683",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01328825041422,
          "to_latitude": 21.013515621027913,
          "to_longitude": 105.83626484863754,
          "from_longitude": 105.8360698684509
        }
      },
      {
        "id": 4677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83583469113502,
            21.013109728317474,
            105.83608809016164,
            21.013374206561547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583469113502,
              21.013109728317474
            ],
            [
              105.83603704396658,
              21.01333936170188
            ],
            [
              105.83608809016164,
              21.013374206561547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.2_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4684",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013109728317474,
          "to_latitude": 21.013374206561547,
          "to_longitude": 105.83608809016164,
          "from_longitude": 105.83583469113502
        }
      },
      {
        "id": 4678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608809016164,
            21.013355294807383,
            105.8362318093808,
            21.013521988567764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361119924012,
              21.013355294807383
            ],
            [
              105.83611024651768,
              21.013356676288236
            ],
            [
              105.83608809016164,
              21.013374206561547
            ],
            [
              105.83618148641152,
              21.013464872102283
            ],
            [
              105.8362318093808,
              21.013521988567764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.2_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4685",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013355294807383,
          "to_latitude": 21.013521988567764,
          "to_longitude": 105.8362318093808,
          "from_longitude": 105.8361119924012
        }
      },
      {
        "id": 4679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83627689605309,
            21.01311909222905,
            105.83671361037005,
            21.013556740721977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627689605309,
              21.013556740721977
            ],
            [
              105.83627748299187,
              21.013556252805515
            ],
            [
              105.83630001829899,
              21.013537505808152
            ],
            [
              105.8362922526236,
              21.01352489702902
            ],
            [
              105.83642310823375,
              21.013414736015708
            ],
            [
              105.83642851780378,
              21.01341951638297
            ],
            [
              105.83651841033527,
              21.01333722424273
            ],
            [
              105.83650973732301,
              21.01332233026033
            ],
            [
              105.83653235591623,
              21.013299727861934
            ],
            [
              105.83663236357985,
              21.013199789312367
            ],
            [
              105.83671361037005,
              21.01311909222905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.6_Ngõ 281, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4686",
          "diaChiLapD": "Ngõ 281, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013556740721977,
          "to_latitude": 21.01311909222905,
          "to_longitude": 105.83671361037005,
          "from_longitude": 105.83627689605309
        }
      },
      {
        "id": 4680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571434419451,
            21.013529949907568,
            105.83627024906129,
            21.01398304670065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625894247048,
              21.013529949907568
            ],
            [
              105.83625834127953,
              21.013530469506872
            ],
            [
              105.83623933208771,
              21.013546921236017
            ],
            [
              105.83626255424169,
              21.013580463050594
            ],
            [
              105.83627024906129,
              21.013591577342705
            ],
            [
              105.8362323966419,
              21.01361663819091
            ],
            [
              105.8362094158564,
              21.013631400536166
            ],
            [
              105.8361945197797,
              21.013641630506033
            ],
            [
              105.83616186597047,
              21.01366210360244
            ],
            [
              105.83599322467929,
              21.013771070864824
            ],
            [
              105.83596551955748,
              21.013803217270123
            ],
            [
              105.83591498466036,
              21.01384081570076
            ],
            [
              105.8358297373154,
              21.01390423983863
            ],
            [
              105.83571434419451,
              21.01398304670065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.5_Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4687",
          "diaChiLapD": "Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013529949907568,
          "to_latitude": 21.01398304670065,
          "to_longitude": 105.83571434419451,
          "from_longitude": 105.83625894247048
        }
      },
      {
        "id": 4681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83577156190938,
            21.01356014242037,
            105.83627925345169,
            21.013769671147855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627925345169,
              21.01356014242037
            ],
            [
              105.83627830800198,
              21.013561026808087
            ],
            [
              105.83626255424169,
              21.013580463050594
            ],
            [
              105.8362588713549,
              21.013585006307
            ],
            [
              105.83625364096784,
              21.01358907394426
            ],
            [
              105.83620638756591,
              21.01362257194832
            ],
            [
              105.83619279709308,
              21.01363181550271
            ],
            [
              105.8361435754355,
              21.013662701102525
            ],
            [
              105.83604654375074,
              21.013724916033343
            ],
            [
              105.83595676956156,
              21.013769671147855
            ],
            [
              105.83593549582321,
              21.01376728728756
            ],
            [
              105.83590511410593,
              21.01373288189451
            ],
            [
              105.8358359529267,
              21.013656267839988
            ],
            [
              105.83577156190938,
              21.013600356617403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.3_Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4688",
          "diaChiLapD": "Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01356014242037,
          "to_latitude": 21.013600356617403,
          "to_longitude": 105.83577156190938,
          "from_longitude": 105.83627925345169
        }
      },
      {
        "id": 4682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358359529267,
            21.013599712734557,
            105.83590263000156,
            21.013656267839988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358359529267,
              21.013656267839988
            ],
            [
              105.8358490980476,
              21.01364149876695
            ],
            [
              105.83586873353389,
              21.013625569679878
            ],
            [
              105.83590263000156,
              21.013599712734557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.3_Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4689",
          "diaChiLapD": "Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013656267839988,
          "to_latitude": 21.013599712734557,
          "to_longitude": 105.83590263000156,
          "from_longitude": 105.8358359529267
        }
      },
      {
        "id": 4683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591498466036,
            21.01384081570076,
            105.83599027960254,
            21.01394598121611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591498466036,
              21.01384081570076
            ],
            [
              105.83599027960254,
              21.01394598121611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.5_Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4690",
          "diaChiLapD": "Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01384081570076,
          "to_latitude": 21.01394598121611,
          "to_longitude": 105.83599027960254,
          "from_longitude": 105.83591498466036
        }
      },
      {
        "id": 4684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835630179635,
            21.013247783645408,
            105.8358502548056,
            21.013519883830483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835630179635,
              21.013247783645408
            ],
            [
              105.83563238896062,
              21.01325068208133
            ],
            [
              105.83563794909627,
              21.01325797631265
            ],
            [
              105.83564336771411,
              21.01326507704553
            ],
            [
              105.83564675630006,
              21.01326951962292
            ],
            [
              105.83565518328318,
              21.013279461576374
            ],
            [
              105.83568913703252,
              21.013319494629517
            ],
            [
              105.83574932094601,
              21.013387581248487
            ],
            [
              105.83579387778958,
              21.013438105536487
            ],
            [
              105.83580335343198,
              21.013462921149316
            ],
            [
              105.8358041577898,
              21.01346502713066
            ],
            [
              105.8358502548056,
              21.013519883830483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.1_Ngõ 66, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4691",
          "diaChiLapD": "Ngõ 66, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013247783645408,
          "to_latitude": 21.013519883830483,
          "to_longitude": 105.8358502548056,
          "from_longitude": 105.835630179635
        }
      },
      {
        "id": 4685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83560934606534,
            21.013127148832776,
            105.83579276478238,
            21.013297295062035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83579276478238,
              21.013127148832776
            ],
            [
              105.83569224862079,
              21.013221203086726
            ],
            [
              105.83566781088996,
              21.013242764764605
            ],
            [
              105.83564336771411,
              21.01326507704553
            ],
            [
              105.83564058938526,
              21.013267613397367
            ],
            [
              105.83562630342621,
              21.01328065386624
            ],
            [
              105.83560934606534,
              21.013297295062035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.2_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4692",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013127148832776,
          "to_latitude": 21.013297295062035,
          "to_longitude": 105.83560934606534,
          "from_longitude": 105.83579276478238
        }
      },
      {
        "id": 4686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521046516225,
            21.013607809928892,
            105.83532648873378,
            21.013745297538875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83532648873378,
              21.013607809928892
            ],
            [
              105.83525545535466,
              21.013689155330727
            ],
            [
              105.83521046516225,
              21.013745297538875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.4_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4693",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013607809928892,
          "to_latitude": 21.013745297538875,
          "to_longitude": 105.83521046516225,
          "from_longitude": 105.83532648873378
        }
      },
      {
        "id": 4687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549201832375,
            21.013297295062035,
            105.83560934606534,
            21.01342091211619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560934606534,
              21.013297295062035
            ],
            [
              105.83556863147638,
              21.01333506193383
            ],
            [
              105.83550632616773,
              21.013403333749956
            ],
            [
              105.83549201832375,
              21.01342091211619
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.2_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4694",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013297295062035,
          "to_latitude": 21.01342091211619,
          "to_longitude": 105.83549201832375,
          "from_longitude": 105.83560934606534
        }
      },
      {
        "id": 4688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83533953559429,
            21.01355154813837,
            105.83560783313179,
            21.01391706598375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559098193369,
              21.01391706598375
            ],
            [
              105.83554652618966,
              21.013863810703047
            ],
            [
              105.83557436180122,
              21.013832277011996
            ],
            [
              105.83560783313179,
              21.013801055250603
            ],
            [
              105.83558856895326,
              21.013779175309878
            ],
            [
              105.83554089580909,
              21.013732862656322
            ],
            [
              105.83551334534592,
              21.013704713471768
            ],
            [
              105.8354770262916,
              21.013667636513567
            ],
            [
              105.8354666093224,
              21.01365929309581
            ],
            [
              105.83544663050478,
              21.01364329121978
            ],
            [
              105.83539490185811,
              21.01360490255466
            ],
            [
              105.83536158469126,
              21.013579685525247
            ],
            [
              105.83535816097866,
              21.013575326211424
            ],
            [
              105.83534233354469,
              21.01355512471945
            ],
            [
              105.83534176192651,
              21.01355439139779
            ],
            [
              105.83533953559429,
              21.01355154813837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT42.3_Ngõ 84, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4695",
          "diaChiLapD": "Ngõ 84, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01391706598375,
          "to_latitude": 21.01355154813837,
          "to_longitude": 105.83533953559429,
          "from_longitude": 105.83559098193369
        }
      },
      {
        "id": 4689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83539642676244,
            21.01365929309581,
            105.83546802392121,
            21.013802576413948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8354666093224,
              21.01365929309581
            ],
            [
              105.8354239346121,
              21.013699206589745
            ],
            [
              105.83541002829111,
              21.01371306349896
            ],
            [
              105.83539642676244,
              21.01372413246253
            ],
            [
              105.83544249163594,
              21.0137721926657
            ],
            [
              105.83546802392121,
              21.013802576413948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.3_Ngõ 84, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4696",
          "diaChiLapD": "Ngõ 84, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01365929309581,
          "to_latitude": 21.013802576413948,
          "to_longitude": 105.83546802392121,
          "from_longitude": 105.8354666093224
        }
      },
      {
        "id": 4690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506254796546,
            21.013892840052446,
            105.83524741877767,
            21.014346123453805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506254796546,
              21.013892840052446
            ],
            [
              105.835067447703,
              21.013899523350513
            ],
            [
              105.83507232337692,
              21.013911387663263
            ],
            [
              105.83507321361283,
              21.013913542904042
            ],
            [
              105.83509373128075,
              21.013963442910224
            ],
            [
              105.83509841997478,
              21.01397109369348
            ],
            [
              105.83511897165137,
              21.014011375992748
            ],
            [
              105.83514374553536,
              21.01406069690719
            ],
            [
              105.83516654453675,
              21.014102404295535
            ],
            [
              105.83520440241794,
              21.014168453725517
            ],
            [
              105.83524238438083,
              21.014227067191463
            ],
            [
              105.83524741877767,
              21.014238409558242
            ],
            [
              105.83524690566345,
              21.014247838129283
            ],
            [
              105.83515471726989,
              21.014346123453805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT42.5_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4697",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013892840052446,
          "to_latitude": 21.014346123453805,
          "to_longitude": 105.83515471726989,
          "from_longitude": 105.83506254796546
        }
      },
      {
        "id": 4691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83493757175073,
            21.014038706382735,
            105.8350557191408,
            21.014219874858682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83493757175073,
              21.014038706382735
            ],
            [
              105.83494028281345,
              21.014043263395404
            ],
            [
              105.83494682937199,
              21.014054267771133
            ],
            [
              105.83495423618866,
              21.01406670766502
            ],
            [
              105.83500669263323,
              21.01412316280157
            ],
            [
              105.83503867897184,
              21.014164005892862
            ],
            [
              105.8350557191408,
              21.014186748552298
            ],
            [
              105.83501052077116,
              21.014219874858682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.7_Ngõ 114, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4698",
          "diaChiLapD": "Ngõ 114, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014038706382735,
          "to_latitude": 21.014219874858682,
          "to_longitude": 105.83501052077116,
          "from_longitude": 105.83493757175073
        }
      },
      {
        "id": 4692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505614594148,
            21.01382457817865,
            105.83514862097334,
            21.013916954394514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514862097334,
              21.01382457817865
            ],
            [
              105.83509507293135,
              21.013888236548645
            ],
            [
              105.83507321361283,
              21.013913542904042
            ],
            [
              105.83507026698761,
              21.013916954394514
            ],
            [
              105.83506126092067,
              21.01390597474008
            ],
            [
              105.83505614594148,
              21.01390071507713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.6_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4699",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01382457817865,
          "to_latitude": 21.01390071507713,
          "to_longitude": 105.83505614594148,
          "from_longitude": 105.83514862097334
        }
      },
      {
        "id": 4693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489465499666,
            21.013916954394514,
            105.83507026698761,
            21.014115715471707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83507026698761,
              21.013916954394514
            ],
            [
              105.83499716678149,
              21.014001524414667
            ],
            [
              105.83495539991456,
              21.014050944296283
            ],
            [
              105.83495653602077,
              21.01405233962232
            ],
            [
              105.8349574209437,
              21.01405388611634
            ],
            [
              105.83495803234851,
              21.01405554595251
            ],
            [
              105.83495835460347,
              21.01405727585275
            ],
            [
              105.83495837977205,
              21.01405903250124
            ],
            [
              105.83495810663584,
              21.014060769839485
            ],
            [
              105.83495754168196,
              21.014062443577664
            ],
            [
              105.83495670103642,
              21.0140640129915
            ],
            [
              105.83495560466277,
              21.01406543553147
            ],
            [
              105.83495428122706,
              21.01406667673435
            ],
            [
              105.83495423618866,
              21.01406670766502
            ],
            [
              105.83495276421473,
              21.014067703919714
            ],
            [
              105.834951092923,
              21.01406849160443
            ],
            [
              105.83494930859797,
              21.01406901881189
            ],
            [
              105.8349474583023,
              21.014069272666013
            ],
            [
              105.83494558817208,
              21.01406924661778
            ],
            [
              105.83494374631242,
              21.014068942237614
            ],
            [
              105.83494197892969,
              21.014068365621313
            ],
            [
              105.83494033324246,
              21.01406753189196
            ],
            [
              105.83493884881435,
              21.014066463436038
            ],
            [
              105.83489465499666,
              21.014115715471707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.6_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4700",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013916954394514,
          "to_latitude": 21.014115715471707,
          "to_longitude": 105.83489465499666,
          "from_longitude": 105.83507026698761
        }
      },
      {
        "id": 4694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84085930985253,
            21.01674278556439,
            105.84121693915016,
            21.017875225757887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84085930985253,
              21.017875225757887
            ],
            [
              105.84087345118817,
              21.017821815104483
            ],
            [
              105.84087963678071,
              21.017790120230813
            ],
            [
              105.84091144313487,
              21.017675253652577
            ],
            [
              105.84095087512115,
              21.017558556473677
            ],
            [
              105.84098588015901,
              21.017446661028533
            ],
            [
              105.84102218456968,
              21.017339539010134
            ],
            [
              105.84103880221186,
              21.017296141879132
            ],
            [
              105.84105470109677,
              21.01729456934512
            ],
            [
              105.84108695125855,
              21.017202104994166
            ],
            [
              105.84112922550577,
              21.01708214211873
            ],
            [
              105.84113738024294,
              21.017057673221156
            ],
            [
              105.84118129963446,
              21.016938964001863
            ],
            [
              105.84121487052349,
              21.01682696992492
            ],
            [
              105.84121693915016,
              21.01676925095176
            ],
            [
              105.84120642333535,
              21.01674852339441
            ],
            [
              105.84120525702407,
              21.01674278556439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT71.1A_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.4701",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 121,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017875225757887,
          "to_latitude": 21.01674278556439,
          "to_longitude": 105.84120525702407,
          "from_longitude": 105.84085930985253
        }
      },
      {
        "id": 4695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84104064866662,
            21.016742723570196,
            105.84120245068998,
            21.01728490568491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84104064866662,
              21.01728490568491
            ],
            [
              105.84107133629684,
              21.01719752698297
            ],
            [
              105.84110231889841,
              21.017077673229704
            ],
            [
              105.84111047364483,
              21.017053205236458
            ],
            [
              105.84115469206154,
              21.016931208618544
            ],
            [
              105.84119137265348,
              21.016838717825717
            ],
            [
              105.84120170759918,
              21.016767680595354
            ],
            [
              105.84120242296433,
              21.01674361157581
            ],
            [
              105.84120245068998,
              21.016742723570196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT71.1_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.4702",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01728490568491,
          "to_latitude": 21.016742723570196,
          "to_longitude": 105.84120245068998,
          "from_longitude": 105.84104064866662
        }
      },
      {
        "id": 4696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445800515479,
            21.01453610354279,
            105.83570923628545,
            21.016211483653198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344846078737,
              21.01453610354279
            ],
            [
              105.8344867093304,
              21.014538459684278
            ],
            [
              105.83449001877963,
              21.01454216473779
            ],
            [
              105.83445800515479,
              21.014580270742933
            ],
            [
              105.8344595008335,
              21.01458422585394
            ],
            [
              105.8345330347368,
              21.01477824455074
            ],
            [
              105.83457111220176,
              21.014860609796134
            ],
            [
              105.8346199735964,
              21.014927182536116
            ],
            [
              105.83467862465648,
              21.015007072246995
            ],
            [
              105.83473550013437,
              21.01507418550246
            ],
            [
              105.8348261448666,
              21.015172677252377
            ],
            [
              105.83498061033875,
              21.015343596926755
            ],
            [
              105.83505710588274,
              21.0154136784111
            ],
            [
              105.83514987599918,
              21.015518744047192
            ],
            [
              105.83518364581586,
              21.015561606079096
            ],
            [
              105.83526090607714,
              21.015639419827593
            ],
            [
              105.83534077091696,
              21.015721474936512
            ],
            [
              105.83543224009917,
              21.015830716978595
            ],
            [
              105.8354623374496,
              21.015887923928933
            ],
            [
              105.83547455652567,
              21.015904944799534
            ],
            [
              105.83552851464256,
              21.015980083690142
            ],
            [
              105.8355954726145,
              21.016075107324774
            ],
            [
              105.8356329281759,
              21.01611543296495
            ],
            [
              105.83570923628545,
              21.016211483653198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT42.9_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.4703",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 230.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01453610354279,
          "to_latitude": 21.016211483653198,
          "to_longitude": 105.83570923628545,
          "from_longitude": 105.8344846078737
        }
      },
      {
        "id": 4697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756861991797,
            21.016560318585153,
            105.8382301505329,
            21.016960434437692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382301505329,
              21.016560318585153
            ],
            [
              105.83822578963917,
              21.016563343291494
            ],
            [
              105.83821359235638,
              21.01657713871458
            ],
            [
              105.83818538139518,
              21.016592098711385
            ],
            [
              105.83812124258368,
              21.016628357599124
            ],
            [
              105.83805893298123,
              21.01666403300195
            ],
            [
              105.83798731822378,
              21.01670982155178
            ],
            [
              105.83786413802574,
              21.016784290307697
            ],
            [
              105.83783289989402,
              21.01680547482121
            ],
            [
              105.83780490587776,
              21.016824156777314
            ],
            [
              105.83775358869885,
              21.016859395872718
            ],
            [
              105.83773066057752,
              21.016873656879355
            ],
            [
              105.83766838494769,
              21.01691545041519
            ],
            [
              105.83756861991797,
              21.016960434437692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT52.18_Ngõ 126, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4704",
          "diaChiLapD": "Ngõ 126, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016560318585153,
          "to_latitude": 21.016960434437692,
          "to_longitude": 105.83756861991797,
          "from_longitude": 105.8382301505329
        }
      },
      {
        "id": 4698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764437340133,
            21.01655645656619,
            105.838227733714,
            21.016807545773766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838227733714,
              21.01655645656619
            ],
            [
              105.83822325365017,
              21.01655881257075
            ],
            [
              105.8382083019188,
              21.016572534855612
            ],
            [
              105.8380734712399,
              21.0166479255459
            ],
            [
              105.83804215583262,
              21.01666663203088
            ],
            [
              105.83793272523154,
              21.01673188886337
            ],
            [
              105.83785228731237,
              21.016783161837076
            ],
            [
              105.83779657146808,
              21.016718912623745
            ],
            [
              105.83772371798153,
              21.016768727415368
            ],
            [
              105.83771063991948,
              21.016760553509414
            ],
            [
              105.83764437340133,
              21.016807545773766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT52.19_Ngõ 126, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4705",
          "diaChiLapD": "Ngõ 126, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01655645656619,
          "to_latitude": 21.016807545773766,
          "to_longitude": 105.83764437340133,
          "from_longitude": 105.838227733714
        }
      },
      {
        "id": 4699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794519919742,
            21.016478689891574,
            105.83804215583262,
            21.01666663203088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794519919742,
              21.016478689891574
            ],
            [
              105.8379617290539,
              21.016509311762995
            ],
            [
              105.83799660242867,
              21.01657389481731
            ],
            [
              105.83804215583262,
              21.01666663203088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.19_Ngõ 126, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4706",
          "diaChiLapD": "Ngõ 126, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016478689891574,
          "to_latitude": 21.01666663203088,
          "to_longitude": 105.83804215583262,
          "from_longitude": 105.83794519919742
        }
      },
      {
        "id": 4700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381122316019,
            21.016822110994386,
            105.83839200491144,
            21.016947641861304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83839200491144,
              21.016849420636003
            ],
            [
              105.83838983791806,
              21.016850500732378
            ],
            [
              105.83838269238395,
              21.01685493645327
            ],
            [
              105.838376554356,
              21.01685874669398
            ],
            [
              105.83836316607886,
              21.016875794183356
            ],
            [
              105.838253848485,
              21.01691858658484
            ],
            [
              105.83817714766624,
              21.016947641861304
            ],
            [
              105.8381122316019,
              21.016822110994386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.14_Ngõ Nam Lai",
          "maTaiSan": "04.O16A.DODV.4707",
          "diaChiLapD": "Ngõ Nam Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016849420636003,
          "to_latitude": 21.016822110994386,
          "to_longitude": 105.8381122316019,
          "from_longitude": 105.83839200491144
        }
      },
      {
        "id": 4701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824082191526,
            21.016599146178656,
            105.83835010704261,
            21.016797202196376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824082191526,
              21.016599146178656
            ],
            [
              105.83831732477043,
              21.016738306453504
            ],
            [
              105.83833806932303,
              21.01677604117707
            ],
            [
              105.83835010704261,
              21.016797202196376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4708",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016599146178656,
          "to_latitude": 21.016797202196376,
          "to_longitude": 105.83835010704261,
          "from_longitude": 105.83824082191526
        }
      },
      {
        "id": 4702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816769131022,
            21.016738306453504,
            105.83831732477043,
            21.016785756676363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816769131022,
              21.016785756676363
            ],
            [
              105.83822710794868,
              21.016760849270817
            ],
            [
              105.8382386095713,
              21.016769395806293
            ],
            [
              105.83831732477043,
              21.016738306453504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4709",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016785756676363,
          "to_latitude": 21.016738306453504,
          "to_longitude": 105.83831732477043,
          "from_longitude": 105.83816769131022
        }
      },
      {
        "id": 4703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819070016683,
            21.01703646474754,
            105.83838247195358,
            21.017119882191988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819070016683,
              21.017119882191988
            ],
            [
              105.83821167810237,
              21.017111245172078
            ],
            [
              105.83825045303418,
              21.017098485756296
            ],
            [
              105.83827939858494,
              21.01708620379871
            ],
            [
              105.83830989813195,
              21.017071938828153
            ],
            [
              105.83833876666912,
              21.017058959951466
            ],
            [
              105.83836636169505,
              21.017045140122097
            ],
            [
              105.83838247195358,
              21.01703646474754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.5_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4710",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017119882191988,
          "to_latitude": 21.01703646474754,
          "to_longitude": 105.83838247195358,
          "from_longitude": 105.83819070016683
        }
      },
      {
        "id": 4704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810852105181,
            21.017050488648376,
            105.83815313119595,
            21.01713895550981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815313119595,
              21.01713895550981
            ],
            [
              105.83813551155873,
              21.017101314659765
            ],
            [
              105.83810852105181,
              21.017050488648376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.5_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4711",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01713895550981,
          "to_latitude": 21.017050488648376,
          "to_longitude": 105.83810852105181,
          "from_longitude": 105.83815313119595
        }
      },
      {
        "id": 4705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805728644236,
            21.017119882191988,
            105.83819070016683,
            21.017193468482184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819070016683,
              21.017119882191988
            ],
            [
              105.83815313119595,
              21.01713895550981
            ],
            [
              105.83813479274754,
              21.01714826596867
            ],
            [
              105.83812317834762,
              21.017154486789003
            ],
            [
              105.83808147102383,
              21.017176823053326
            ],
            [
              105.83805728644236,
              21.017193468482184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.5_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4712",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017119882191988,
          "to_latitude": 21.017193468482184,
          "to_longitude": 105.83805728644236,
          "from_longitude": 105.83819070016683
        }
      },
      {
        "id": 4706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798559294806,
            21.017157256409146,
            105.83814639307091,
            21.017252239459708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814639307091,
              21.017157256409146
            ],
            [
              105.83812769481284,
              21.01716707625181
            ],
            [
              105.83810585060185,
              21.017178548232902
            ],
            [
              105.83808459138986,
              21.017189528685197
            ],
            [
              105.8380703723583,
              21.017201316256944
            ],
            [
              105.83805340725372,
              21.017215565092105
            ],
            [
              105.83803241287015,
              21.01722763261819
            ],
            [
              105.83800691353828,
              21.017241739232137
            ],
            [
              105.83798559294806,
              21.017252239459708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.4_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4713",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017157256409146,
          "to_latitude": 21.017252239459708,
          "to_longitude": 105.83798559294806,
          "from_longitude": 105.83814639307091
        }
      },
      {
        "id": 4707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814639307091,
            21.017020396558987,
            105.83843870304429,
            21.017157256409146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814639307091,
              21.017157256409146
            ],
            [
              105.83816466239493,
              21.017146937388603
            ],
            [
              105.83820108546686,
              21.0171285128096
            ],
            [
              105.83822924916957,
              21.017117472129204
            ],
            [
              105.8382554290238,
              21.01710878508535
            ],
            [
              105.83826903990449,
              21.017103678028892
            ],
            [
              105.83831696968576,
              21.017082489763762
            ],
            [
              105.83836167239588,
              21.017060833260825
            ],
            [
              105.83843870304429,
              21.017020396558987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.4_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4714",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017157256409146,
          "to_latitude": 21.017020396558987,
          "to_longitude": 105.83843870304429,
          "from_longitude": 105.83814639307091
        }
      },
      {
        "id": 4708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758025201546,
            21.017022948662646,
            105.83794427111842,
            21.017274023729268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794427111842,
              21.017274023729268
            ],
            [
              105.83793987889185,
              21.01726541867301
            ],
            [
              105.83793920263925,
              21.017247683591094
            ],
            [
              105.83790434995919,
              21.01716926755858
            ],
            [
              105.83785786723175,
              21.017050355851747
            ],
            [
              105.83783709579784,
              21.017022948662646
            ],
            [
              105.8377389910847,
              21.017058979981886
            ],
            [
              105.83758025201546,
              21.017118610256738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4715",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017274023729268,
          "to_latitude": 21.017118610256738,
          "to_longitude": 105.83758025201546,
          "from_longitude": 105.83794427111842
        }
      },
      {
        "id": 4709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746386833128,
            21.017017693815966,
            105.83795013139536,
            21.017270597838554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83795013139536,
              21.017270597838554
            ],
            [
              105.83794633752571,
              21.017262686220718
            ],
            [
              105.8379454518544,
              21.017232433487983
            ],
            [
              105.83789718759395,
              21.017130576210075
            ],
            [
              105.8378623468716,
              21.017044476382136
            ],
            [
              105.83784092480447,
              21.017017693815966
            ],
            [
              105.83781318928213,
              21.017027551810127
            ],
            [
              105.83771278793172,
              21.017063155458263
            ],
            [
              105.83762439690197,
              21.017094711276133
            ],
            [
              105.8375724266979,
              21.01711397911686
            ],
            [
              105.83747599024584,
              21.01714973291318
            ],
            [
              105.83746386833128,
              21.01715206690547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT60A.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4716",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017270597838554,
          "to_latitude": 21.01715206690547,
          "to_longitude": 105.83746386833128,
          "from_longitude": 105.83795013139536
        }
      },
      {
        "id": 4710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735681733059,
            21.017408438163095,
            105.83758641596093,
            21.01746136006228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735681733059,
              21.01746136006228
            ],
            [
              105.83741377586365,
              21.017451998675437
            ],
            [
              105.83751769117794,
              21.017426665616192
            ],
            [
              105.83758253143027,
              21.017408438163095
            ],
            [
              105.83758407869394,
              21.017412653116676
            ],
            [
              105.83758641596093,
              21.01741966778751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.3_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4717",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01746136006228,
          "to_latitude": 21.01741966778751,
          "to_longitude": 105.83758641596093,
          "from_longitude": 105.83735681733059
        }
      },
      {
        "id": 4711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758253143027,
            21.01727065732172,
            105.83793548801548,
            21.017408438163095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758253143027,
              21.017408438163095
            ],
            [
              105.8376637068366,
              21.017388032965698
            ],
            [
              105.83769476654281,
              21.017378278753917
            ],
            [
              105.8377308164481,
              21.017364840099667
            ],
            [
              105.83776867418727,
              21.017349740538577
            ],
            [
              105.83781187779798,
              21.017332979806273
            ],
            [
              105.83782249594913,
              21.017329395907357
            ],
            [
              105.83783872530178,
              21.017323603086776
            ],
            [
              105.8378462745391,
              21.01732065391252
            ],
            [
              105.83789362030298,
              21.017293566975322
            ],
            [
              105.83793548801548,
              21.01727065732172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.3_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4718",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017408438163095,
          "to_latitude": 21.01727065732172,
          "to_longitude": 105.83793548801548,
          "from_longitude": 105.83758253143027
        }
      },
      {
        "id": 4712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753997268796,
            21.017367115788186,
            105.83777217268766,
            21.017439397955844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753997268796,
              21.017439397955844
            ],
            [
              105.83754661418783,
              21.017437885776747
            ],
            [
              105.83758054713444,
              21.017427938601028
            ],
            [
              105.83759087117181,
              21.017424912550236
            ],
            [
              105.83764037563047,
              21.01740988302716
            ],
            [
              105.83767892019698,
              21.01739856822456
            ],
            [
              105.83772138875459,
              21.01738350287243
            ],
            [
              105.83777217268766,
              21.017367115788186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.4_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4719",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017439397955844,
          "to_latitude": 21.017367115788186,
          "to_longitude": 105.83777217268766,
          "from_longitude": 105.83753997268796
        }
      },
      {
        "id": 4713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737426079048,
            21.017439397955844,
            105.83753997268796,
            21.017485218666184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753997268796,
              21.017439397955844
            ],
            [
              105.83752164498394,
              21.01744478920906
            ],
            [
              105.83747294111389,
              21.017456938892074
            ],
            [
              105.83737426079048,
              21.017485218666184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.4_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4720",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017439397955844,
          "to_latitude": 21.017485218666184,
          "to_longitude": 105.83737426079048,
          "from_longitude": 105.83753997268796
        }
      },
      {
        "id": 4714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735599082291,
            21.01746907856602,
            105.83763118007555,
            21.017673905151014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735599082291,
              21.01746907856602
            ],
            [
              105.83735670291163,
              21.017471190411324
            ],
            [
              105.83741108660251,
              21.017632097597385
            ],
            [
              105.83750450674287,
              21.017599967962905
            ],
            [
              105.83753418980922,
              21.017673905151014
            ],
            [
              105.83763118007555,
              21.01763184589165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.5_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4721",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01746907856602,
          "to_latitude": 21.01763184589165,
          "to_longitude": 105.83763118007555,
          "from_longitude": 105.83735599082291
        }
      },
      {
        "id": 4715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83714477735869,
            21.017474034572555,
            105.83730387225592,
            21.01750535645886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83714477735869,
              21.01750535645886
            ],
            [
              105.83720274019514,
              21.0174934096889
            ],
            [
              105.8372322325429,
              21.017487719652433
            ],
            [
              105.83724689760295,
              21.017487748638466
            ],
            [
              105.83725992348793,
              21.017487774852043
            ],
            [
              105.83730387225592,
              21.017474034572555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.6_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4722",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01750535645886,
          "to_latitude": 21.017474034572555,
          "to_longitude": 105.83730387225592,
          "from_longitude": 105.83714477735869
        }
      },
      {
        "id": 4716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720249566034,
            21.017133206081546,
            105.83730581463554,
            21.01748044693436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730581463554,
              21.01748044693436
            ],
            [
              105.83730446813804,
              21.017476180409375
            ],
            [
              105.83730387225592,
              21.017474034572555
            ],
            [
              105.83729071926004,
              21.01742677127975
            ],
            [
              105.8372639368079,
              21.017348039905226
            ],
            [
              105.83726070838111,
              21.017339572715212
            ],
            [
              105.83727695218677,
              21.017331367365326
            ],
            [
              105.83725837717263,
              21.017286769974746
            ],
            [
              105.83723476521044,
              21.01722869417276
            ],
            [
              105.83723153945527,
              21.01722070477428
            ],
            [
              105.83723671822266,
              21.017213748849613
            ],
            [
              105.83723466775754,
              21.017208996241003
            ],
            [
              105.83722488903246,
              21.017186324670945
            ],
            [
              105.83720249566034,
              21.017133206081546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.6_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4723",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01748044693436,
          "to_latitude": 21.017133206081546,
          "to_longitude": 105.83720249566034,
          "from_longitude": 105.83730581463554
        }
      },
      {
        "id": 4717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697323581792,
            21.017085669266564,
            105.83723515528182,
            21.017188148265756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697323581792,
              21.017188148265756
            ],
            [
              105.83719151135243,
              21.017085669266564
            ],
            [
              105.83720919946066,
              21.01711614551176
            ],
            [
              105.83723110702597,
              21.017102225595277
            ],
            [
              105.83723230121039,
              21.017105400872996
            ],
            [
              105.83723515528182,
              21.017112979327514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4724",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017188148265756,
          "to_latitude": 21.017112979327514,
          "to_longitude": 105.83723515528182,
          "from_longitude": 105.83697323581792
        }
      },
      {
        "id": 4718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723110702597,
            21.01707071093151,
            105.83733815338584,
            21.017102225595277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723110702597,
              21.017102225595277
            ],
            [
              105.83733815338584,
              21.01707071093151
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4725",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017102225595277,
          "to_latitude": 21.01707071093151,
          "to_longitude": 105.83733815338584,
          "from_longitude": 105.83723110702597
        }
      },
      {
        "id": 4719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722450760482,
            21.0168769091085,
            105.83736217820936,
            21.017063689337363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722450760482,
              21.0168769091085
            ],
            [
              105.83724965374984,
              21.016905680392117
            ],
            [
              105.83727050654919,
              21.01691356587643
            ],
            [
              105.83727360380159,
              21.01691473475948
            ],
            [
              105.83729957588376,
              21.016944470226274
            ],
            [
              105.83732368084651,
              21.016987474206474
            ],
            [
              105.83735412579723,
              21.017040262311152
            ],
            [
              105.83736084687179,
              21.017059897833743
            ],
            [
              105.83736136029025,
              21.017061401884167
            ],
            [
              105.83736217820936,
              21.017063689337363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4726",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0168769091085,
          "to_latitude": 21.017063689337363,
          "to_longitude": 105.83736217820936,
          "from_longitude": 105.83722450760482
        }
      },
      {
        "id": 4720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83785641443521,
            21.017429606279993,
            105.83795555055396,
            21.017487856639367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83785641443521,
              21.017487856639367
            ],
            [
              105.83786049272561,
              21.017486413072213
            ],
            [
              105.83786204075602,
              21.01748559796571
            ],
            [
              105.83790432103636,
              21.01746334744647
            ],
            [
              105.83792096226547,
              21.01745172769873
            ],
            [
              105.83794399652206,
              21.017436995562992
            ],
            [
              105.83795555055396,
              21.017429606279993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4727",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017487856639367,
          "to_latitude": 21.017429606279993,
          "to_longitude": 105.83795555055396,
          "from_longitude": 105.83785641443521
        }
      },
      {
        "id": 4721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794399652206,
            21.017436995562992,
            105.83799858810805,
            21.017513970352205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794399652206,
              21.017436995562992
            ],
            [
              105.83799858810805,
              21.017513970352205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4728",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017436995562992,
          "to_latitude": 21.017513970352205,
          "to_longitude": 105.83799858810805,
          "from_longitude": 105.83794399652206
        }
      },
      {
        "id": 4722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786007553306,
            21.01748009436663,
            105.83798228462696,
            21.01766299768589
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798228462696,
              21.01766299768589
            ],
            [
              105.837907792137,
              21.017558979041954
            ],
            [
              105.83788870851127,
              21.017532546257225
            ],
            [
              105.83788516114281,
              21.017527605933832
            ],
            [
              105.83786900846633,
              21.01750511302446
            ],
            [
              105.83786204075602,
              21.01748559796571
            ],
            [
              105.83786007553306,
              21.01748009436663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4729",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01766299768589,
          "to_latitude": 21.01748009436663,
          "to_longitude": 105.83786007553306,
          "from_longitude": 105.83798228462696
        }
      },
      {
        "id": 4723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83780153844428,
            21.01737338586463,
            105.83786007553306,
            21.01748243821604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83780153844428,
              21.01737338586463
            ],
            [
              105.83783570468093,
              21.01743000237719
            ],
            [
              105.83785387119015,
              21.017467563790454
            ],
            [
              105.83786007553306,
              21.01748009436663
            ],
            [
              105.83785747082531,
              21.0174809995805
            ],
            [
              105.837853843661,
              21.01748243821604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4730",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01737338586463,
          "to_latitude": 21.01748243821604,
          "to_longitude": 105.837853843661,
          "from_longitude": 105.83780153844428
        }
      },
      {
        "id": 4724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83844917624596,
            21.01769500821703,
            105.83870363967944,
            21.01783884043373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83869534107663,
              21.01783884043373
            ],
            [
              105.83870363967944,
              21.01783399888615
            ],
            [
              105.83861384653255,
              21.01769500821703
            ],
            [
              105.8384547408935,
              21.017769992310072
            ],
            [
              105.83844917624596,
              21.017772614698494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.1_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4731",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01783884043373,
          "to_latitude": 21.017772614698494,
          "to_longitude": 105.83844917624596,
          "from_longitude": 105.83869534107663
        }
      },
      {
        "id": 4725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83864882358863,
            21.017445325665047,
            105.83882252689318,
            21.017767748344486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882252689318,
              21.017767748344486
            ],
            [
              105.83879462836072,
              21.01771596409696
            ],
            [
              105.83879142937451,
              21.0177100258201
            ],
            [
              105.83864882358863,
              21.017445325665047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.8_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4732",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017767748344486,
          "to_latitude": 21.017445325665047,
          "to_longitude": 105.83864882358863,
          "from_longitude": 105.83882252689318
        }
      },
      {
        "id": 4726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854632192171,
            21.01783884043373,
            105.83870242805068,
            21.017916157589685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83854632192171,
              21.017916157589685
            ],
            [
              105.83857628237296,
              21.017903343996732
            ],
            [
              105.83859553892698,
              21.01789736366504
            ],
            [
              105.8385986146511,
              21.017893128641948
            ],
            [
              105.83864409781329,
              21.01786874047827
            ],
            [
              105.83869534107663,
              21.01783884043373
            ],
            [
              105.83870210795915,
              21.017848911434463
            ],
            [
              105.83870242805068,
              21.017849384951976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.1_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4733",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017916157589685,
          "to_latitude": 21.017849384951976,
          "to_longitude": 105.83870242805068,
          "from_longitude": 105.83854632192171
        }
      },
      {
        "id": 4727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842514828038,
            21.01784635627361,
            105.83871217202132,
            21.017984729396066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871217202132,
              21.01784635627361
            ],
            [
              105.83866181390404,
              21.017871122552325
            ],
            [
              105.83860867340115,
              21.017898740461575
            ],
            [
              105.83858603303027,
              21.01790994004375
            ],
            [
              105.83855210950775,
              21.017924159622236
            ],
            [
              105.83853964329415,
              21.017934257693646
            ],
            [
              105.83849112403341,
              21.01795483473981
            ],
            [
              105.83842758403834,
              21.017984729396066
            ],
            [
              105.8384262013876,
              21.01798140150884
            ],
            [
              105.83842514828038,
              21.01797886141491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.2_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4734",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01784635627361,
          "to_latitude": 21.01797886141491,
          "to_longitude": 105.83842514828038,
          "from_longitude": 105.83871217202132
        }
      },
      {
        "id": 4728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858603303027,
            21.01790994004375,
            105.83864348784051,
            21.018019591769246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858603303027,
              21.01790994004375
            ],
            [
              105.83864348784051,
              21.018019591769246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.2_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4735",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01790994004375,
          "to_latitude": 21.018019591769246,
          "to_longitude": 105.83864348784051,
          "from_longitude": 105.83858603303027
        }
      },
      {
        "id": 4729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816755747196,
            21.01800919146422,
            105.83836049579382,
            21.018073608121693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836049579382,
              21.01800919146422
            ],
            [
              105.83829690614627,
              21.01803090040125
            ],
            [
              105.83819838472854,
              21.018063896509194
            ],
            [
              105.83816956057504,
              21.018072976834226
            ],
            [
              105.83816755747196,
              21.018073608121693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.4_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4736",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01800919146422,
          "to_latitude": 21.018073608121693,
          "to_longitude": 105.83816755747196,
          "from_longitude": 105.83836049579382
        }
      },
      {
        "id": 4730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381660120537,
            21.018070244002057,
            105.83823179895485,
            21.01826300649372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8381660120537,
              21.018070244002057
            ],
            [
              105.83816684766981,
              21.018072252267306
            ],
            [
              105.83816755747196,
              21.018073608121693
            ],
            [
              105.83817637082534,
              21.0180904676254
            ],
            [
              105.83820528655603,
              21.01816472549229
            ],
            [
              105.83819785250755,
              21.018172628297634
            ],
            [
              105.83823179895485,
              21.01826300649372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.4_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4737",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018070244002057,
          "to_latitude": 21.01826300649372,
          "to_longitude": 105.83823179895485,
          "from_longitude": 105.8381660120537
        }
      },
      {
        "id": 4731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829690614627,
            21.01803090040125,
            105.83837972394319,
            21.018191372658364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83829690614627,
              21.01803090040125
            ],
            [
              105.83833908792928,
              21.01812317902115
            ],
            [
              105.83834966880943,
              21.018125482484
            ],
            [
              105.83837972394319,
              21.018191372658364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.4_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4738",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01803090040125,
          "to_latitude": 21.018191372658364,
          "to_longitude": 105.83837972394319,
          "from_longitude": 105.83829690614627
        }
      },
      {
        "id": 4732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381903302573,
            21.017713792485864,
            105.8383556366708,
            21.018010277900988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83834952792752,
              21.018010277900988
            ],
            [
              105.83834906564881,
              21.018009492568133
            ],
            [
              105.83834656070077,
              21.018003292540445
            ],
            [
              105.8383556366708,
              21.017998122921057
            ],
            [
              105.83820796286079,
              21.01773790206641
            ],
            [
              105.8381903302573,
              21.017713792485864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.3_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4739",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018010277900988,
          "to_latitude": 21.017713792485864,
          "to_longitude": 105.8381903302573,
          "from_longitude": 105.83834952792752
        }
      },
      {
        "id": 4733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8383556366708,
            21.01792770478655,
            105.83852975243353,
            21.017998122921057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383556366708,
              21.017998122921057
            ],
            [
              105.83842044082672,
              21.017969629283108
            ],
            [
              105.83844709292661,
              21.017956947630502
            ],
            [
              105.8384833948768,
              21.017943671960015
            ],
            [
              105.83850906785011,
              21.01793549046822
            ],
            [
              105.83852975243353,
              21.01792770478655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.3_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4740",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017998122921057,
          "to_latitude": 21.01792770478655,
          "to_longitude": 105.83852975243353,
          "from_longitude": 105.8383556366708
        }
      },
      {
        "id": 4734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803913214071,
            21.01791454456177,
            105.83812852277099,
            21.018082791135278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812852277099,
              21.018082791135278
            ],
            [
              105.83812768050615,
              21.018081486513868
            ],
            [
              105.8381228752243,
              21.018074025174
            ],
            [
              105.83808430183798,
              21.017981397235445
            ],
            [
              105.83805908954929,
              21.017949974549467
            ],
            [
              105.83803913214071,
              21.01791454456177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.5_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4741",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018082791135278,
          "to_latitude": 21.01791454456177,
          "to_longitude": 105.83803913214071,
          "from_longitude": 105.83812852277099
        }
      },
      {
        "id": 4735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381228752243,
            21.01802071040481,
            105.83828679006425,
            21.018074025174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8381228752243,
              21.018074025174
            ],
            [
              105.83815947803238,
              21.018062590665295
            ],
            [
              105.83821819452812,
              21.018043646171645
            ],
            [
              105.83827497713787,
              21.018024811445695
            ],
            [
              105.83828679006425,
              21.01802071040481
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.5_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4742",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018074025174,
          "to_latitude": 21.01802071040481,
          "to_longitude": 105.83828679006425,
          "from_longitude": 105.8381228752243
        }
      },
      {
        "id": 4736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83782603272333,
            21.01808120685252,
            105.83787001383968,
            21.018172881289704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83787001383968,
              21.018172881289704
            ],
            [
              105.83786952928085,
              21.018172412193557
            ],
            [
              105.83785819741432,
              21.018161380032335
            ],
            [
              105.83782603272333,
              21.018098892657157
            ],
            [
              105.83786688324744,
              21.01808120685252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.6_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4743",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018172881289704,
          "to_latitude": 21.01808120685252,
          "to_longitude": 105.83786688324744,
          "from_longitude": 105.83787001383968
        }
      },
      {
        "id": 4737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83785819741432,
            21.018078791088872,
            105.83811470602974,
            21.018161380032335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83785819741432,
              21.018161380032335
            ],
            [
              105.83789430928881,
              21.01815022167441
            ],
            [
              105.83791645896989,
              21.018144326522474
            ],
            [
              105.83793714444163,
              21.01813703316395
            ],
            [
              105.83795964250933,
              21.0181291013273
            ],
            [
              105.83811470602974,
              21.018078791088872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.6_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4744",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018161380032335,
          "to_latitude": 21.018078791088872,
          "to_longitude": 105.83811470602974,
          "from_longitude": 105.83785819741432
        }
      },
      {
        "id": 4738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790202672735,
            21.01805219743264,
            105.83793714444163,
            21.01813703316395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793714444163,
              21.01813703316395
            ],
            [
              105.83790202672735,
              21.01805219743264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.6_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4745",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01813703316395,
          "to_latitude": 21.01805219743264,
          "to_longitude": 105.83790202672735,
          "from_longitude": 105.83793714444163
        }
      },
      {
        "id": 4739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779338943005,
            21.018147821770675,
            105.8379553788818,
            21.018202138355182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779338943005,
              21.018202138355182
            ],
            [
              105.83781540779512,
              21.018193570320502
            ],
            [
              105.83786444052247,
              21.01817533389623
            ],
            [
              105.8378946964571,
              21.018165112120595
            ],
            [
              105.83791832935164,
              21.018158506954634
            ],
            [
              105.8379553788818,
              21.018147821770675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.7_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4746",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018202138355182,
          "to_latitude": 21.018147821770675,
          "to_longitude": 105.8379553788818,
          "from_longitude": 105.83779338943005
        }
      },
      {
        "id": 4740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767768351808,
            21.018202138355182,
            105.83779338943005,
            21.018243389244457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779338943005,
              21.018202138355182
            ],
            [
              105.83772823373364,
              21.01822560163129
            ],
            [
              105.83767990838662,
              21.018243389244457
            ],
            [
              105.83767912248655,
              21.01824166976349
            ],
            [
              105.83767768351808,
              21.018238245502246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.7_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4747",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018202138355182,
          "to_latitude": 21.018238245502246,
          "to_longitude": 105.83767768351808,
          "from_longitude": 105.83779338943005
        }
      },
      {
        "id": 4741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83731593136827,
            21.0182911962158,
            105.83751880007553,
            21.01836812801495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83731593136827,
              21.01836812801495
            ],
            [
              105.83735367355109,
              21.01835760574345
            ],
            [
              105.83738027945053,
              21.018348852601846
            ],
            [
              105.83739739497766,
              21.01834255868508
            ],
            [
              105.83742564167174,
              21.018330479918397
            ],
            [
              105.83745525849395,
              21.018317940080422
            ],
            [
              105.83749758212413,
              21.01830064362514
            ],
            [
              105.83751880007553,
              21.0182911962158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.8_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4748",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01836812801495,
          "to_latitude": 21.0182911962158,
          "to_longitude": 105.83751880007553,
          "from_longitude": 105.83731593136827
        }
      },
      {
        "id": 4742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751880007553,
            21.018169413752616,
            105.83783151541255,
            21.0182911962158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751880007553,
              21.0182911962158
            ],
            [
              105.83756752536647,
              21.01827146655363
            ],
            [
              105.83759157398576,
              21.01826075062036
            ],
            [
              105.83762555621307,
              21.018248743838466
            ],
            [
              105.83765064302783,
              21.018239968329027
            ],
            [
              105.83766873183832,
              21.01823364068983
            ],
            [
              105.83768945906999,
              21.018225724833993
            ],
            [
              105.83771617421611,
              21.018215871879637
            ],
            [
              105.83774709142321,
              21.018203825974233
            ],
            [
              105.83779311376982,
              21.01818482130035
            ],
            [
              105.83783151541255,
              21.018169413752616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.8_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4749",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0182911962158,
          "to_latitude": 21.018169413752616,
          "to_longitude": 105.83783151541255,
          "from_longitude": 105.83751880007553
        }
      },
      {
        "id": 4743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732563347569,
            21.01832263188979,
            105.83746892423007,
            21.018378437526753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746892423007,
              21.01832263188979
            ],
            [
              105.83743868490708,
              21.01833411711956
            ],
            [
              105.83741036781338,
              21.018345130430863
            ],
            [
              105.8373863691591,
              21.01835480738581
            ],
            [
              105.83736363475879,
              21.018362806246934
            ],
            [
              105.83732563347569,
              21.018378437526753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.9_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4750",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832263188979,
          "to_latitude": 21.018378437526753,
          "to_longitude": 105.83732563347569,
          "from_longitude": 105.83746892423007
        }
      },
      {
        "id": 4744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746892423007,
            21.01824996611566,
            105.83764904433708,
            21.01832263188979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746892423007,
              21.01832263188979
            ],
            [
              105.83749197038352,
              21.018313443740787
            ],
            [
              105.83751832788887,
              21.018302416580152
            ],
            [
              105.83755259287432,
              21.018290569194182
            ],
            [
              105.83759006947456,
              21.018275215931475
            ],
            [
              105.83760206038261,
              21.01827059470714
            ],
            [
              105.83764904433708,
              21.01825582687535
            ],
            [
              105.8376462402168,
              21.01824996611566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.9_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4751",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832263188979,
          "to_latitude": 21.01824996611566,
          "to_longitude": 105.8376462402168,
          "from_longitude": 105.83746892423007
        }
      },
      {
        "id": 4745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83770629853733,
            21.01822374788359,
            105.83799805591279,
            21.01834378086909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83770629853733,
              21.01834378086909
            ],
            [
              105.83771336793407,
              21.018341542189894
            ],
            [
              105.8377439049775,
              21.018331868215768
            ],
            [
              105.83777985201739,
              21.018320984369513
            ],
            [
              105.83782946614667,
              21.018301775028625
            ],
            [
              105.83784972679268,
              21.01829281913111
            ],
            [
              105.83794462127146,
              21.0182612636627
            ],
            [
              105.83793891063314,
              21.018245681376644
            ],
            [
              105.83795946069849,
              21.018236705073075
            ],
            [
              105.83799805591279,
              21.01822374788359
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.1_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4752",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01834378086909,
          "to_latitude": 21.01822374788359,
          "to_longitude": 105.83799805591279,
          "from_longitude": 105.83770629853733
        }
      },
      {
        "id": 4746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377025149689,
            21.018359760368238,
            105.83800752626036,
            21.018387112611176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377025149689,
              21.018359760368238
            ],
            [
              105.83771313848342,
              21.018362461982147
            ],
            [
              105.83771864615018,
              21.01836444272587
            ],
            [
              105.83772668591466,
              21.01838110374074
            ],
            [
              105.83773581058662,
              21.018381200611266
            ],
            [
              105.83784168379472,
              21.018378033647135
            ],
            [
              105.83785090193433,
              21.018387112611176
            ],
            [
              105.83795413379339,
              21.018373130740567
            ],
            [
              105.83800752626036,
              21.018367828639263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.2_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4753",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018359760368238,
          "to_latitude": 21.018367828639263,
          "to_longitude": 105.83800752626036,
          "from_longitude": 105.8377025149689
        }
      },
      {
        "id": 4747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732101909509,
            21.018427508150893,
            105.8376697482993,
            21.018490277542295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376697482993,
              21.018427508150893
            ],
            [
              105.83766272091567,
              21.01842762494886
            ],
            [
              105.83765953579858,
              21.018427781535998
            ],
            [
              105.83765845005478,
              21.018427835656514
            ],
            [
              105.8376575506789,
              21.01843766174042
            ],
            [
              105.83764755671017,
              21.018441065525515
            ],
            [
              105.83748251707786,
              21.018459144150704
            ],
            [
              105.83741378239533,
              21.018466694772023
            ],
            [
              105.83732101909509,
              21.018490277542295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.3_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4754",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018427508150893,
          "to_latitude": 21.018490277542295,
          "to_longitude": 105.83732101909509,
          "from_longitude": 105.8376697482993
        }
      },
      {
        "id": 4748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765939227534,
            21.018256621782903,
            105.8376983600237,
            21.018497039812697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376734256258,
              21.018256621782903
            ],
            [
              105.83769831534575,
              21.018340863930522
            ],
            [
              105.8376983600237,
              21.01836143463174
            ],
            [
              105.83769753601882,
              21.01837338743664
            ],
            [
              105.83767445798867,
              21.018411140221847
            ],
            [
              105.83765964691828,
              21.01841374760121
            ],
            [
              105.83765939227534,
              21.018421374773357
            ],
            [
              105.83765953579858,
              21.018427781535998
            ],
            [
              105.83765957296669,
              21.018429441478247
            ],
            [
              105.83765994290917,
              21.01844590181286
            ],
            [
              105.83766306264133,
              21.018483295268982
            ],
            [
              105.83766504215667,
              21.018497039812697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.4_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4755",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018256621782903,
          "to_latitude": 21.018497039812697,
          "to_longitude": 105.83766504215667,
          "from_longitude": 105.8376734256258
        }
      },
      {
        "id": 4749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753005301801,
            21.018547359751118,
            105.83767228969387,
            21.018571281992536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767228969387,
              21.018547359751118
            ],
            [
              105.83753005301801,
              21.018571281992536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.4_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4756",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018547359751118,
          "to_latitude": 21.018571281992536,
          "to_longitude": 105.83753005301801,
          "from_longitude": 105.83767228969387
        }
      },
      {
        "id": 4750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83754804061408,
            21.01867755886662,
            105.83769414241613,
            21.018689218310673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754804061408,
              21.01867755886662
            ],
            [
              105.83760511992693,
              21.01868354258092
            ],
            [
              105.83762455708975,
              21.01868817253895
            ],
            [
              105.8376910811419,
              21.01868920701905
            ],
            [
              105.83769172282943,
              21.018689218310673
            ],
            [
              105.83769414241613,
              21.018688921880045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.6_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4757",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01867755886662,
          "to_latitude": 21.018688921880045,
          "to_longitude": 105.83769414241613,
          "from_longitude": 105.83754804061408
        }
      },
      {
        "id": 4751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376910811419,
            21.01868920701905,
            105.8377399150895,
            21.01887627164584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377399150895,
              21.01887627164584
            ],
            [
              105.83772308738554,
              21.018826468568985
            ],
            [
              105.8377174812871,
              21.01881014150529
            ],
            [
              105.83770111615918,
              21.018756524543353
            ],
            [
              105.83770021955667,
              21.018753586255805
            ],
            [
              105.83769167979115,
              21.018750400188207
            ],
            [
              105.8376910811419,
              21.01868920701905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.6_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4758",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01887627164584,
          "to_latitude": 21.01868920701905,
          "to_longitude": 105.8376910811419,
          "from_longitude": 105.8377399150895
        }
      },
      {
        "id": 4752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768637010881,
            21.01861229906867,
            105.83797171369604,
            21.018647453955595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83768637010881,
              21.018639203365296
            ],
            [
              105.83768727768037,
              21.01863911128334
            ],
            [
              105.83769678500401,
              21.01863813504782
            ],
            [
              105.83769799813702,
              21.018647453955595
            ],
            [
              105.83775199919391,
              21.018641901451332
            ],
            [
              105.83785145066717,
              21.01862979799339
            ],
            [
              105.83797171369604,
              21.01861229906867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.5_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4759",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018639203365296,
          "to_latitude": 21.01861229906867,
          "to_longitude": 105.83797171369604,
          "from_longitude": 105.83768637010881
        }
      },
      {
        "id": 4753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798628400044,
            21.018083100680602,
            105.83813554193863,
            21.018135168012602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798628400044,
              21.018135168012602
            ],
            [
              105.83798772481272,
              21.018134617174365
            ],
            [
              105.8380328640883,
              21.018117343750788
            ],
            [
              105.83813554193863,
              21.018083100680602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.1_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.4760",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018135168012602,
          "to_latitude": 21.018083100680602,
          "to_longitude": 105.83813554193863,
          "from_longitude": 105.83798628400044
        }
      },
      {
        "id": 4754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380350002369,
            21.01822728626424,
            105.83806229535057,
            21.018461877103825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380350002369,
              21.01822728626424
            ],
            [
              105.83804108501984,
              21.018241307739522
            ],
            [
              105.83805076168494,
              21.01827873211007
            ],
            [
              105.83806229535057,
              21.018338611407906
            ],
            [
              105.83804338225373,
              21.018342719412825
            ],
            [
              105.83805866730685,
              21.018461085640805
            ],
            [
              105.83805589793342,
              21.018461618637925
            ],
            [
              105.8380545853483,
              21.018461877103825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.2A_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4761",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01822728626424,
          "to_latitude": 21.018461877103825,
          "to_longitude": 105.8380545853483,
          "from_longitude": 105.8380350002369
        }
      },
      {
        "id": 4755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769591676432,
            21.01846573743331,
            105.83805496188116,
            21.018543660313814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769591676432,
              21.018543660313814
            ],
            [
              105.8377700810239,
              21.01853254491062
            ],
            [
              105.83777129261699,
              21.018521784258663
            ],
            [
              105.83779353981615,
              21.018517492766364
            ],
            [
              105.83780704349506,
              21.018502985518975
            ],
            [
              105.83797547915516,
              21.0184820392756
            ],
            [
              105.838052326266,
              21.018466278803647
            ],
            [
              105.83805496188116,
              21.01846573743331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.2_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4762",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018543660313814,
          "to_latitude": 21.01846573743331,
          "to_longitude": 105.83805496188116,
          "from_longitude": 105.83769591676432
        }
      },
      {
        "id": 4756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806783503925,
            21.01859194937478,
            105.8382641276167,
            21.018616621615386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83806783503925,
              21.018602124917404
            ],
            [
              105.83807077690413,
              21.018616621615386
            ],
            [
              105.83818397336745,
              21.018601876059215
            ],
            [
              105.8382641276167,
              21.01859194937478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.3_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4763",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018602124917404,
          "to_latitude": 21.01859194937478,
          "to_longitude": 105.8382641276167,
          "from_longitude": 105.83806783503925
        }
      },
      {
        "id": 4757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805951895799,
            21.018498498389594,
            105.83806908531993,
            21.01860297960874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83806026922908,
              21.01860297960874
            ],
            [
              105.8380622082731,
              21.01860276141117
            ],
            [
              105.83806783503925,
              21.018602124917404
            ],
            [
              105.83806428977314,
              21.01858465597328
            ],
            [
              105.83806284682436,
              21.018572947367822
            ],
            [
              105.83806908531993,
              21.018571437152673
            ],
            [
              105.83806317510395,
              21.018533399908122
            ],
            [
              105.83806081672282,
              21.01851211351247
            ],
            [
              105.83805951895799,
              21.018498498389594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.3_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4764",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01860297960874,
          "to_latitude": 21.018498498389594,
          "to_longitude": 105.83805951895799,
          "from_longitude": 105.83806026922908
        }
      },
      {
        "id": 4758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814363942439,
            21.018416460498635,
            105.83828508898375,
            21.01859918076205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814363942439,
              21.018429554555972
            ],
            [
              105.83825051242722,
              21.018416460498635
            ],
            [
              105.83827024572857,
              21.01844757144177
            ],
            [
              105.83827581499382,
              21.01851598667277
            ],
            [
              105.83827940465775,
              21.01854690168358
            ],
            [
              105.83828092685197,
              21.018561082019357
            ],
            [
              105.83828508898375,
              21.01859029434186
            ],
            [
              105.8382718588384,
              21.018597971004098
            ],
            [
              105.83826977334823,
              21.01859918076205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.4_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4765",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018429554555972,
          "to_latitude": 21.01859918076205,
          "to_longitude": 105.83826977334823,
          "from_longitude": 105.83814363942439
        }
      },
      {
        "id": 4759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382962176357,
            21.018726211688712,
            105.83830408043532,
            21.018880536565636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382962176357,
              21.018726211688712
            ],
            [
              105.83830408043532,
              21.018880536565636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.5_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4766",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018726211688712,
          "to_latitude": 21.018880536565636,
          "to_longitude": 105.83830408043532,
          "from_longitude": 105.8382962176357
        }
      },
      {
        "id": 4760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83827948081043,
            21.018459331969176,
            105.8382962176357,
            21.018726211688712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382962176357,
              21.018726211688712
            ],
            [
              105.83829589733641,
              21.018723119726253
            ],
            [
              105.83829246605599,
              21.018700061981683
            ],
            [
              105.83829134523248,
              21.018663551980254
            ],
            [
              105.83829101760378,
              21.018592720087828
            ],
            [
              105.83829141288503,
              21.018538667430857
            ],
            [
              105.83828351114683,
              21.018488371522682
            ],
            [
              105.83827948081043,
              21.018459331969176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.5_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4767",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018726211688712,
          "to_latitude": 21.018459331969176,
          "to_longitude": 105.83827948081043,
          "from_longitude": 105.8382962176357
        }
      },
      {
        "id": 4761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83827024572857,
            21.018293175752728,
            105.83845094087715,
            21.01844757144177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827024572857,
              21.01844757144177
            ],
            [
              105.83831822875418,
              21.018438586359608
            ],
            [
              105.83834474691939,
              21.018432731945442
            ],
            [
              105.83835858479877,
              21.018419340296486
            ],
            [
              105.83840039352789,
              21.018405282406768
            ],
            [
              105.83845094087715,
              21.01838696609109
            ],
            [
              105.83841604017466,
              21.018293175752728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.4_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4768",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01844757144177,
          "to_latitude": 21.018293175752728,
          "to_longitude": 105.83841604017466,
          "from_longitude": 105.83827024572857
        }
      },
      {
        "id": 4762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83827812532667,
            21.0187192003264,
            105.83850964219674,
            21.018727788456204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827812532667,
              21.018727788456204
            ],
            [
              105.83828067327318,
              21.018727567255493
            ],
            [
              105.8382962176357,
              21.018726211688712
            ],
            [
              105.83836381885155,
              21.018720320097447
            ],
            [
              105.83850964219674,
              21.0187192003264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63.5_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DODV.4769",
          "diaChiLapD": "Ngõ Sân Quần",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018727788456204,
          "to_latitude": 21.0187192003264,
          "to_longitude": 105.83850964219674,
          "from_longitude": 105.83827812532667
        }
      },
      {
        "id": 4763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862406649367,
            21.018434296550083,
            105.83890214147623,
            21.01857050940026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890214147623,
              21.018485871916308
            ],
            [
              105.83889882093251,
              21.01848761344861
            ],
            [
              105.83889668977073,
              21.01848856782704
            ],
            [
              105.8388590052429,
              21.01844007443074
            ],
            [
              105.83884819038425,
              21.018434296550083
            ],
            [
              105.83879245635396,
              21.01846777297184
            ],
            [
              105.83872744096789,
              21.01850668286653
            ],
            [
              105.8387194747525,
              21.018511450519227
            ],
            [
              105.83865666261819,
              21.01854699404909
            ],
            [
              105.83862406649367,
              21.01857050940026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62.2_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4770",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018485871916308,
          "to_latitude": 21.01857050940026,
          "to_longitude": 105.83862406649367,
          "from_longitude": 105.83890214147623
        }
      },
      {
        "id": 4764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872744096789,
            21.01850668286653,
            105.83876779797266,
            21.018613173551127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872744096789,
              21.01850668286653
            ],
            [
              105.83875978816594,
              21.01856663200017
            ],
            [
              105.83876779797266,
              21.018613173551127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62.2_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4771",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01850668286653,
          "to_latitude": 21.018613173551127,
          "to_longitude": 105.83876779797266,
          "from_longitude": 105.83872744096789
        }
      },
      {
        "id": 4765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83894648224252,
            21.018589989108676,
            105.83896258269439,
            21.018736217600242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896258269439,
              21.01873601875199
            ],
            [
              105.8389602078983,
              21.018736067496476
            ],
            [
              105.83895797603869,
              21.018736217600242
            ],
            [
              105.83895568668567,
              21.01871302146626
            ],
            [
              105.83895447800943,
              21.018696603210998
            ],
            [
              105.83894648224252,
              21.018589989108676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62.1_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4772",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01873601875199,
          "to_latitude": 21.018589989108676,
          "to_longitude": 105.83894648224252,
          "from_longitude": 105.83896258269439
        }
      },
      {
        "id": 4766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83889965556986,
            21.018492540029893,
            105.83894648224252,
            21.018589989108676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83894648224252,
              21.018589989108676
            ],
            [
              105.83894221901967,
              21.0185506386205
            ],
            [
              105.83891291401895,
              21.01850970724701
            ],
            [
              105.83889965556986,
              21.018492540029893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62.1_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4773",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018589989108676,
          "to_latitude": 21.018492540029893,
          "to_longitude": 105.83889965556986,
          "from_longitude": 105.83894648224252
        }
      },
      {
        "id": 4767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907882633451,
            21.018262158093254,
            105.83920247395724,
            21.018517642725122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83907882633451,
              21.018262158093254
            ],
            [
              105.83908609941753,
              21.018279480368278
            ],
            [
              105.83911869853057,
              21.018345410239288
            ],
            [
              105.83915112291386,
              21.018404519820724
            ],
            [
              105.83916734652436,
              21.018435855709125
            ],
            [
              105.8391899385067,
              21.018483736136773
            ],
            [
              105.83920247395724,
              21.018517642725122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4774",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018262158093254,
          "to_latitude": 21.018517642725122,
          "to_longitude": 105.83920247395724,
          "from_longitude": 105.83907882633451
        }
      },
      {
        "id": 4768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910369395764,
            21.018234856642437,
            105.83920061889536,
            21.018452243141137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83911685540703,
              21.018234856642437
            ],
            [
              105.83912013940622,
              21.018240339268132
            ],
            [
              105.83912321702793,
              21.018245728976524
            ],
            [
              105.83910369395764,
              21.018263050654475
            ],
            [
              105.83913918389189,
              21.018337431272823
            ],
            [
              105.83915413872954,
              21.018366885684593
            ],
            [
              105.83916541634994,
              21.018387630385998
            ],
            [
              105.83920061889536,
              21.018452243141137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.3_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4775",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018234856642437,
          "to_latitude": 21.018452243141137,
          "to_longitude": 105.83920061889536,
          "from_longitude": 105.83911685540703
        }
      },
      {
        "id": 4769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856950068073,
            21.01834995585854,
            105.83865468378129,
            21.01851327712982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857981111521,
              21.01851327712982
            ],
            [
              105.83856950068073,
              21.01849913289418
            ],
            [
              105.83859294417996,
              21.018458653166714
            ],
            [
              105.83859192421397,
              21.018456364006912
            ],
            [
              105.83863207243583,
              21.018399098610246
            ],
            [
              105.83865468378129,
              21.018373751069227
            ],
            [
              105.83864016662966,
              21.01834995585854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT52.5A_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4776",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01851327712982,
          "to_latitude": 21.01834995585854,
          "to_longitude": 105.83864016662966,
          "from_longitude": 105.83857981111521
        }
      },
      {
        "id": 4770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863062196666,
            21.018127212062133,
            105.83901709705928,
            21.018342873769207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83901709705928,
              21.018127212062133
            ],
            [
              105.83900897692548,
              21.018131457520465
            ],
            [
              105.83894563104487,
              21.018163889469694
            ],
            [
              105.83888652306105,
              21.018194695469063
            ],
            [
              105.83880642832214,
              21.018237574452694
            ],
            [
              105.83876599406256,
              21.018258295969225
            ],
            [
              105.83876479889705,
              21.018258908828447
            ],
            [
              105.83873171116845,
              21.018275865606537
            ],
            [
              105.83869225989292,
              21.01829949154262
            ],
            [
              105.83867844728977,
              21.018307762718532
            ],
            [
              105.83864682238836,
              21.01833258205583
            ],
            [
              105.83863541075151,
              21.0183410139073
            ],
            [
              105.83863062196666,
              21.018342873769207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.5_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4777",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018127212062133,
          "to_latitude": 21.018342873769207,
          "to_longitude": 105.83863062196666,
          "from_longitude": 105.83901709705928
        }
      },
      {
        "id": 4771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83864916344423,
            21.01808166376483,
            105.83880642832214,
            21.018237574452694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880642832214,
              21.018237574452694
            ],
            [
              105.83880468626475,
              21.018234657501445
            ],
            [
              105.83871333786605,
              21.01808166376483
            ],
            [
              105.83864916344423,
              21.018121447414238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.5_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.4778",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018237574452694,
          "to_latitude": 21.018121447414238,
          "to_longitude": 105.83864916344423,
          "from_longitude": 105.83880642832214
        }
      },
      {
        "id": 4772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884822516922,
            21.01777459089975,
            105.83893041557134,
            21.017908637814394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893041557134,
              21.01789763277558
            ],
            [
              105.83892165768974,
              21.017904632593748
            ],
            [
              105.83891664620695,
              21.017908637814394
            ],
            [
              105.83886619252613,
              21.017810030601478
            ],
            [
              105.83884822516922,
              21.01777459089975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.7_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4779",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01789763277558,
          "to_latitude": 21.01777459089975,
          "to_longitude": 105.83884822516922,
          "from_longitude": 105.83893041557134
        }
      },
      {
        "id": 4773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392549916135,
            21.018646351298145,
            105.83937326503137,
            21.01880603389915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392549916135,
              21.018646351298145
            ],
            [
              105.8392650689729,
              21.01866077573581
            ],
            [
              105.83926962416271,
              21.01866771079213
            ],
            [
              105.8393073736338,
              21.01872519626296
            ],
            [
              105.83934311337201,
              21.01877077992991
            ],
            [
              105.83937326503137,
              21.01880603389915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.2_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4780",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018646351298145,
          "to_latitude": 21.01880603389915,
          "to_longitude": 105.83937326503137,
          "from_longitude": 105.8392549916135
        }
      },
      {
        "id": 4774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921375038739,
            21.018562582075734,
            105.8392549916135,
            21.018646351298145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392549916135,
              21.018646351298145
            ],
            [
              105.83921375038739,
              21.018562582075734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.2_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4781",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018646351298145,
          "to_latitude": 21.018562582075734,
          "to_longitude": 105.83921375038739,
          "from_longitude": 105.8392549916135
        }
      },
      {
        "id": 4775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929389782244,
            21.018647198954838,
            105.83941267820116,
            21.018807735281708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929389782244,
              21.018647198954838
            ],
            [
              105.83930938747048,
              21.01866632235127
            ],
            [
              105.83934029771686,
              21.01870924546793
            ],
            [
              105.83941267820116,
              21.018807735281708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4782",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018647198954838,
          "to_latitude": 21.018807735281708,
          "to_longitude": 105.83941267820116,
          "from_longitude": 105.83929389782244
        }
      },
      {
        "id": 4776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83922485188523,
            21.018521765735027,
            105.83929389782244,
            21.018651301185884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83928716689293,
              21.018651301185884
            ],
            [
              105.8392880747712,
              21.018650748449918
            ],
            [
              105.83929389782244,
              21.018647198954838
            ],
            [
              105.83927799982975,
              21.01862197358734
            ],
            [
              105.83925716111855,
              21.018582286695917
            ],
            [
              105.83922485188523,
              21.018521765735027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4783",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018651301185884,
          "to_latitude": 21.018521765735027,
          "to_longitude": 105.83922485188523,
          "from_longitude": 105.83928716689293
        }
      },
      {
        "id": 4777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83948661766614,
            21.018845451951655,
            105.83958323433632,
            21.01886037794397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958323433632,
              21.01886037794397
            ],
            [
              105.83958255207719,
              21.018850714111434
            ],
            [
              105.83958218208518,
              21.018845451951655
            ],
            [
              105.83948661766614,
              21.018857340336126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4784",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01886037794397,
          "to_latitude": 21.018857340336126,
          "to_longitude": 105.83948661766614,
          "from_longitude": 105.83958323433632
        }
      },
      {
        "id": 4778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958218208518,
            21.018832932602116,
            105.83990031557613,
            21.018845451951655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958218208518,
              21.018845451951655
            ],
            [
              105.8398143789307,
              21.018838893648752
            ],
            [
              105.83990031557613,
              21.018832932602116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4785",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018845451951655,
          "to_latitude": 21.018832932602116,
          "to_longitude": 105.83990031557613,
          "from_longitude": 105.83958218208518
        }
      },
      {
        "id": 4779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83982768950332,
            21.0186104244246,
            105.83986741813999,
            21.01864801893241
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83986741813999,
              21.018642356838026
            ],
            [
              105.83985688882932,
              21.01864566494361
            ],
            [
              105.8398511793415,
              21.01864801893241
            ],
            [
              105.83983256057705,
              21.018616732875095
            ],
            [
              105.83982768950332,
              21.0186104244246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4786",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018642356838026,
          "to_latitude": 21.0186104244246,
          "to_longitude": 105.83982768950332,
          "from_longitude": 105.83986741813999
        }
      },
      {
        "id": 4780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8398511793415,
            21.01864801893241,
            105.8399069722379,
            21.01880131491816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8398511793415,
              21.01864801893241
            ],
            [
              105.83987487596437,
              21.018684917836744
            ],
            [
              105.8398822791127,
              21.018712046630625
            ],
            [
              105.83989777939883,
              21.018752121087136
            ],
            [
              105.8399069722379,
              21.01880131491816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4787",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01864801893241,
          "to_latitude": 21.01880131491816,
          "to_longitude": 105.8399069722379,
          "from_longitude": 105.8398511793415
        }
      },
      {
        "id": 4781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83989097986151,
            21.018631291633103,
            105.83991911467555,
            21.018709363731634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83991911467555,
              21.018709363731634
            ],
            [
              105.83990616102827,
              21.0186724425008
            ],
            [
              105.83989097986151,
              21.018631291633103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4788",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018709363731634,
          "to_latitude": 21.018631291633103,
          "to_longitude": 105.83989097986151,
          "from_longitude": 105.83991911467555
        }
      },
      {
        "id": 4782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83990378076554,
            21.018709363731634,
            105.83994470444057,
            21.018802080608673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83990378076554,
              21.01871462755783
            ],
            [
              105.83991595015743,
              21.018710450579466
            ],
            [
              105.83991911467555,
              21.018709363731634
            ],
            [
              105.8399385024994,
              21.01878538263269
            ],
            [
              105.83994470444057,
              21.018802080608673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4789",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01871462755783,
          "to_latitude": 21.018802080608673,
          "to_longitude": 105.83994470444057,
          "from_longitude": 105.83990378076554
        }
      },
      {
        "id": 4783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8398375793165,
            21.017608927534827,
            105.83995149113636,
            21.018623023197467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83985921501765,
              21.018623023197467
            ],
            [
              105.83986148616371,
              21.01862147198855
            ],
            [
              105.83988519703283,
              21.01860526219108
            ],
            [
              105.83990373878524,
              21.0185939390608
            ],
            [
              105.83991550019525,
              21.018585277786663
            ],
            [
              105.83992644256644,
              21.018575855525626
            ],
            [
              105.83993993473001,
              21.0185490236889
            ],
            [
              105.83994754279281,
              21.018517929690926
            ],
            [
              105.83994936884605,
              21.018492900484162
            ],
            [
              105.83995129955953,
              21.01846182548658
            ],
            [
              105.83995149113636,
              21.018438836602776
            ],
            [
              105.83994754248015,
              21.018379094325702
            ],
            [
              105.83994476616128,
              21.01831758316745
            ],
            [
              105.83993738069597,
              21.0181945039637
            ],
            [
              105.83993389811849,
              21.01812230844842
            ],
            [
              105.83993182207996,
              21.018073158055433
            ],
            [
              105.83992879278966,
              21.018027450037064
            ],
            [
              105.83992688655084,
              21.017987319297283
            ],
            [
              105.83992434792252,
              21.01794351194743
            ],
            [
              105.83992219219117,
              21.01792719411816
            ],
            [
              105.83991168343562,
              21.017867860869977
            ],
            [
              105.83990865608163,
              21.01785873971204
            ],
            [
              105.83989806041836,
              21.017834730153627
            ],
            [
              105.83988560951911,
              21.01780666253886
            ],
            [
              105.83987690256731,
              21.017782895653212
            ],
            [
              105.83987061613749,
              21.01775676933891
            ],
            [
              105.83986512059306,
              21.01773427911012
            ],
            [
              105.83985264021864,
              21.01768514124087
            ],
            [
              105.8398375793165,
              21.017608927534827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.3_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4790",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 130,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018623023197467,
          "to_latitude": 21.017608927534827,
          "to_longitude": 105.8398375793165,
          "from_longitude": 105.83985921501765
        }
      },
      {
        "id": 4784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955862150499,
            21.018526121599827,
            105.83979889843062,
            21.01862550232499
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979889843062,
              21.018559617539324
            ],
            [
              105.83973393782205,
              21.01858868330992
            ],
            [
              105.83966312188953,
              21.01862550232499
            ],
            [
              105.83955862150499,
              21.018526121599827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.4_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4791",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018559617539324,
          "to_latitude": 21.018526121599827,
          "to_longitude": 105.83955862150499,
          "from_longitude": 105.83979889843062
        }
      },
      {
        "id": 4785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979532806802,
            21.01835662639052,
            105.8398162966349,
            21.018536873763406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839807461119,
              21.01835662639052
            ],
            [
              105.8398162966349,
              21.01844199880073
            ],
            [
              105.83981368650991,
              21.018466674201246
            ],
            [
              105.83980928381862,
              21.01848302440916
            ],
            [
              105.83980064187935,
              21.018512273704435
            ],
            [
              105.83979532806802,
              21.01852859770042
            ],
            [
              105.83979609491512,
              21.018536873763406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4792",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01835662639052,
          "to_latitude": 21.018536873763406,
          "to_longitude": 105.83979609491512,
          "from_longitude": 105.839807461119
        }
      },
      {
        "id": 4786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969743970201,
            21.018283788931324,
            105.83980269863888,
            21.01832536192137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969743970201,
              21.018283788931324
            ],
            [
              105.8397813575669,
              21.01832536192137
            ],
            [
              105.83980269863888,
              21.01832504430872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.3_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4793",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018283788931324,
          "to_latitude": 21.01832504430872,
          "to_longitude": 105.83980269863888,
          "from_longitude": 105.83969743970201
        }
      },
      {
        "id": 4787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83974155517798,
            21.017962749812973,
            105.83980442198362,
            21.01832504430872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980269863888,
              21.01832504430872
            ],
            [
              105.83980442198362,
              21.018263287835936
            ],
            [
              105.83977323414828,
              21.017981821251734
            ],
            [
              105.83975412090297,
              21.0179726720155
            ],
            [
              105.83974210593753,
              21.01796318696405
            ],
            [
              105.83974155517798,
              21.017962749812973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT53.3_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4794",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832504430872,
          "to_latitude": 21.017962749812973,
          "to_longitude": 105.83974155517798,
          "from_longitude": 105.83980269863888
        }
      },
      {
        "id": 4788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83970746664052,
            21.01847681789275,
            105.83980064187935,
            21.018512273704435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980064187935,
              21.018512273704435
            ],
            [
              105.83974939163964,
              21.018477877253932
            ],
            [
              105.83970746664052,
              21.01847681789275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.4795",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018512273704435,
          "to_latitude": 21.01847681789275,
          "to_longitude": 105.83970746664052,
          "from_longitude": 105.83980064187935
        }
      },
      {
        "id": 4789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83938413404078,
            21.018372551334174,
            105.83942529363621,
            21.018432277110254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83938413404078,
              21.018372551334174
            ],
            [
              105.83938853415866,
              21.018378936186497
            ],
            [
              105.83939055528377,
              21.01838186891557
            ],
            [
              105.83942529363621,
              21.018432277110254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.1_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4796",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018372551334174,
          "to_latitude": 21.018432277110254,
          "to_longitude": 105.83942529363621,
          "from_longitude": 105.83938413404078
        }
      },
      {
        "id": 4790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924487350843,
            21.018332716880437,
            105.83945353264876,
            21.01844965114371
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924487350843,
              21.01844965114371
            ],
            [
              105.83927546239131,
              21.0184341291723
            ],
            [
              105.83930921097009,
              21.01841580064535
            ],
            [
              105.8393296793296,
              21.018405471552413
            ],
            [
              105.8393429293534,
              21.018398953547397
            ],
            [
              105.83935191147987,
              21.01839403453131
            ],
            [
              105.83938413404078,
              21.018372551334174
            ],
            [
              105.83939996135115,
              21.018362806938494
            ],
            [
              105.83944170908107,
              21.018332716880437
            ],
            [
              105.83945222590437,
              21.01834024122143
            ],
            [
              105.83945353264876,
              21.018341174125386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.1_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4797",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01844965114371,
          "to_latitude": 21.018341174125386,
          "to_longitude": 105.83945353264876,
          "from_longitude": 105.83924487350843
        }
      },
      {
        "id": 4791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944170908107,
            21.01800688431538,
            105.83969318914168,
            21.018332716880437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83944170908107,
              21.018332716880437
            ],
            [
              105.83946944609443,
              21.018309698645123
            ],
            [
              105.83949286277335,
              21.018288192044913
            ],
            [
              105.83950171888979,
              21.01827949545931
            ],
            [
              105.83950926359664,
              21.018270751151746
            ],
            [
              105.83951511778744,
              21.018244928908516
            ],
            [
              105.83952808755537,
              21.01821687581247
            ],
            [
              105.8395425747941,
              21.018193891342253
            ],
            [
              105.83955095341939,
              21.01818427220994
            ],
            [
              105.8395628104393,
              21.018166744446773
            ],
            [
              105.83958226853825,
              21.01813954719315
            ],
            [
              105.83962272224075,
              21.018092427781696
            ],
            [
              105.83969318914168,
              21.01800688431538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.1_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4798",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018332716880437,
          "to_latitude": 21.01800688431538,
          "to_longitude": 105.83969318914168,
          "from_longitude": 105.83944170908107
        }
      },
      {
        "id": 4792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926022779949,
            21.01762584339328,
            105.8398128932729,
            21.01795657087334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397481397633,
              21.01795657087334
            ],
            [
              105.83974683318455,
              21.01795532364899
            ],
            [
              105.83974267260453,
              21.01795134652021
            ],
            [
              105.83978820930363,
              21.017903567013597
            ],
            [
              105.83979652847728,
              21.017889348949463
            ],
            [
              105.83980892238378,
              21.01785828010332
            ],
            [
              105.8398128932729,
              21.017847965559124
            ],
            [
              105.83980389570789,
              21.017826755323423
            ],
            [
              105.83979967614867,
              21.017805544983517
            ],
            [
              105.83979128059788,
              21.017763345377816
            ],
            [
              105.83975427214509,
              21.01762584339328
            ],
            [
              105.83968558303513,
              21.017643330152772
            ],
            [
              105.83968085314015,
              21.01764453401247
            ],
            [
              105.83963143282269,
              21.017659617903583
            ],
            [
              105.83957554007203,
              21.01770040062679
            ],
            [
              105.83954473487593,
              21.017721240089422
            ],
            [
              105.83946109324862,
              21.01778377114841
            ],
            [
              105.83939962251374,
              21.01782119786608
            ],
            [
              105.83937129781903,
              21.017846793175796
            ],
            [
              105.83933175671588,
              21.01787529981525
            ],
            [
              105.83926022779949,
              21.01791514074963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT53.2_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4799",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 106,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01795657087334,
          "to_latitude": 21.01791514074963,
          "to_longitude": 105.83926022779949,
          "from_longitude": 105.8397481397633
        }
      },
      {
        "id": 4793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920279162268,
            21.017557312597905,
            105.83933155743784,
            21.017790336504618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920703677613,
              21.017790336504618
            ],
            [
              105.83920337873938,
              21.01778677236572
            ],
            [
              105.83920279162268,
              21.01778619810222
            ],
            [
              105.83923872884597,
              21.017762257905595
            ],
            [
              105.83933155743784,
              21.017702696586337
            ],
            [
              105.8392873661985,
              21.01765159616304
            ],
            [
              105.83925057302686,
              21.017610239334406
            ],
            [
              105.83920765774846,
              21.017557312597905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6_Ngõ Gia Tự A",
          "maTaiSan": "04.O16A.DODV.4800",
          "diaChiLapD": "Ngõ Gia Tự A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017790336504618,
          "to_latitude": 21.017557312597905,
          "to_longitude": 105.83920765774846,
          "from_longitude": 105.83920703677613
        }
      },
      {
        "id": 4794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391736529858,
            21.01780575753922,
            105.8392687629757,
            21.017968564054858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391736529858,
              21.01780575753922
            ],
            [
              105.83917367242641,
              21.017805793572254
            ],
            [
              105.83917635981814,
              21.017810726367582
            ],
            [
              105.83919319949116,
              21.017841631993548
            ],
            [
              105.83922599809235,
              21.01789646295684
            ],
            [
              105.83924570311119,
              21.017927138570585
            ],
            [
              105.83926266441834,
              21.017956568624363
            ],
            [
              105.8392687629757,
              21.017968564054858
            ],
            [
              105.83926849284819,
              21.01796790874403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6_Ngõ Gia Tự A",
          "maTaiSan": "04.O16A.DODV.4801",
          "diaChiLapD": "Ngõ Gia Tự A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01780575753922,
          "to_latitude": 21.01796790874403,
          "to_longitude": 105.83926849284819,
          "from_longitude": 105.8391736529858
        }
      },
      {
        "id": 4795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393429672309,
            21.017973021635427,
            105.83944794067298,
            21.01806283887772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393429672309,
              21.018048893740822
            ],
            [
              105.83935024516734,
              21.0180593451592
            ],
            [
              105.83935444536084,
              21.01806283887772
            ],
            [
              105.83944794067298,
              21.017973021635427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6A1_Ngõ Miếu Chợ",
          "maTaiSan": "04.O16A.DODV.4802",
          "diaChiLapD": "Ngõ Miếu Chợ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018048893740822,
          "to_latitude": 21.017973021635427,
          "to_longitude": 105.83944794067298,
          "from_longitude": 105.8393429672309
        }
      },
      {
        "id": 4796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914973356087,
            21.018069072359683,
            105.83934284448299,
            21.018223606487712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83934284448299,
              21.018069072359683
            ],
            [
              105.83933290268753,
              21.01808144318778
            ],
            [
              105.8392213413545,
              21.018163104872237
            ],
            [
              105.83920002523503,
              21.01818111553382
            ],
            [
              105.83914973356087,
              21.018223606487712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6A2_Ngõ Miếu Chợ",
          "maTaiSan": "04.O16A.DODV.4803",
          "diaChiLapD": "Ngõ Miếu Chợ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018069072359683,
          "to_latitude": 21.018223606487712,
          "to_longitude": 105.83914973356087,
          "from_longitude": 105.83934284448299
        }
      },
      {
        "id": 4797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83891468246381,
            21.01753528759169,
            105.83918469355787,
            21.01772747800378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917907431433,
              21.01753528759169
            ],
            [
              105.83917891449718,
              21.01753543560552
            ],
            [
              105.83917747232556,
              21.017536773168068
            ],
            [
              105.83918469355787,
              21.01755927478083
            ],
            [
              105.83914247729302,
              21.017586574317594
            ],
            [
              105.83907802689485,
              21.017629069153884
            ],
            [
              105.83891468246381,
              21.01772747800378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55.2_Ngõ Gia Tự B",
          "maTaiSan": "04.O16A.DODV.4804",
          "diaChiLapD": "Ngõ Gia Tự B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01753528759169,
          "to_latitude": 21.01772747800378,
          "to_longitude": 105.83891468246381,
          "from_longitude": 105.83917907431433
        }
      },
      {
        "id": 4798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890634904147,
            21.017532436876298,
            105.83917933649094,
            21.017719557183298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890634904147,
              21.017719557183298
            ],
            [
              105.83912773731073,
              21.017586049995128
            ],
            [
              105.83917933649094,
              21.01755566844196
            ],
            [
              105.83917358027898,
              21.01753488114623
            ],
            [
              105.83917626397468,
              21.017532793203088
            ],
            [
              105.83917672275406,
              21.017532436876298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55.3_Ngõ Gia Tự B",
          "maTaiSan": "04.O16A.DODV.4805",
          "diaChiLapD": "Ngõ Gia Tự B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017719557183298,
          "to_latitude": 21.017532436876298,
          "to_longitude": 105.83917672275406,
          "from_longitude": 105.83890634904147
        }
      },
      {
        "id": 4799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83918036457897,
            21.0172224956325,
            105.83961697965101,
            21.017537402899052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918036457897,
              21.017537402899052
            ],
            [
              105.83918071352576,
              21.017537188016952
            ],
            [
              105.83918645195408,
              21.017533650685596
            ],
            [
              105.8392051863545,
              21.01753579645544
            ],
            [
              105.83925435579617,
              21.017506305703012
            ],
            [
              105.83927676084546,
              21.017492267460533
            ],
            [
              105.83928689997644,
              21.017485083778542
            ],
            [
              105.83930366949424,
              21.017473202234022
            ],
            [
              105.8393501916002,
              21.01743614556692
            ],
            [
              105.83935106978804,
              21.017429945120256
            ],
            [
              105.83934782737553,
              21.01741985946572
            ],
            [
              105.83934735328054,
              21.01741644761976
            ],
            [
              105.83935819706159,
              21.017402308345137
            ],
            [
              105.83938404862013,
              21.0173715759639
            ],
            [
              105.83937878157047,
              21.017355349347216
            ],
            [
              105.83942993274479,
              21.01731472556969
            ],
            [
              105.83946841301828,
              21.01728783007667
            ],
            [
              105.83948510572439,
              21.017307087526454
            ],
            [
              105.83952911072656,
              21.01729244408117
            ],
            [
              105.83955036071198,
              21.01727948904988
            ],
            [
              105.83961697965101,
              21.0172224956325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55.4_Ngõ Gia Tự B",
          "maTaiSan": "04.O16A.DODV.4806",
          "diaChiLapD": "Ngõ Gia Tự B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017537402899052,
          "to_latitude": 21.0172224956325,
          "to_longitude": 105.83961697965101,
          "from_longitude": 105.83918036457897
        }
      },
      {
        "id": 4800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83918252234342,
            21.017249242778988,
            105.8397446063385,
            21.017542084566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918252234342,
              21.0175401741835
            ],
            [
              105.83918327616149,
              21.01753959601471
            ],
            [
              105.83918591404372,
              21.01753757242628
            ],
            [
              105.83921491701376,
              21.017542084566
            ],
            [
              105.83924117565013,
              21.017525470291528
            ],
            [
              105.83927526389685,
              21.017504272364597
            ],
            [
              105.83930017660693,
              21.017487975430424
            ],
            [
              105.83935131204393,
              21.017449340651197
            ],
            [
              105.83936046530059,
              21.017440461059277
            ],
            [
              105.83936099917126,
              21.017439943587714
            ],
            [
              105.83936075041312,
              21.01743485605348
            ],
            [
              105.83935644524608,
              21.017426868411814
            ],
            [
              105.83935642719577,
              21.017423643089504
            ],
            [
              105.83937625705816,
              21.017384504312123
            ],
            [
              105.83938853812502,
              21.017368671625523
            ],
            [
              105.8393912943806,
              21.0173611585603
            ],
            [
              105.83942272784839,
              21.01733433397628
            ],
            [
              105.83945034699045,
              21.017314005224836
            ],
            [
              105.83946969762681,
              21.01730079312479
            ],
            [
              105.8394898131254,
              21.017321802195486
            ],
            [
              105.83951228039628,
              21.017312874054955
            ],
            [
              105.83953237869684,
              21.017301594769613
            ],
            [
              105.83955936857801,
              21.01728622690049
            ],
            [
              105.8395701799972,
              21.017272326223708
            ],
            [
              105.83957606891306,
              21.017267149689456
            ],
            [
              105.83959630228964,
              21.017249242778988
            ],
            [
              105.83964322933095,
              21.017299320721087
            ],
            [
              105.83968631896923,
              21.017251248934464
            ],
            [
              105.8397446063385,
              21.017297323686925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT55.1_Ngõ Gia Tự B",
          "maTaiSan": "04.O16A.DODV.4807",
          "diaChiLapD": "Ngõ Gia Tự B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0175401741835,
          "to_latitude": 21.017297323686925,
          "to_longitude": 105.8397446063385,
          "from_longitude": 105.83918252234342
        }
      },
      {
        "id": 4801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936046530059,
            21.017440461059277,
            105.83948659507814,
            21.017496405771503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936046530059,
              21.017440461059277
            ],
            [
              105.83942619180071,
              21.017496405771503
            ],
            [
              105.83948659507814,
              21.017451839816225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55.1_Ngõ Gia Tự B",
          "maTaiSan": "04.O16A.DODV.4808",
          "diaChiLapD": "Ngõ Gia Tự B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017440461059277,
          "to_latitude": 21.017451839816225,
          "to_longitude": 105.83948659507814,
          "from_longitude": 105.83936046530059
        }
      },
      {
        "id": 4802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946109324862,
            21.01778377114841,
            105.83952255054596,
            21.017829702069832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83946109324862,
              21.01778377114841
            ],
            [
              105.83952255054596,
              21.017829702069832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.2_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4809",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01778377114841,
          "to_latitude": 21.017829702069832,
          "to_longitude": 105.83952255054596,
          "from_longitude": 105.83946109324862
        }
      },
      {
        "id": 4803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914069076774,
            21.017238382298522,
            105.83925883186903,
            21.017332703917297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914069076774,
              21.017238382298522
            ],
            [
              105.83925883186903,
              21.017332703917297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.4_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4810",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017238382298522,
          "to_latitude": 21.017332703917297,
          "to_longitude": 105.83925883186903,
          "from_longitude": 105.83914069076774
        }
      },
      {
        "id": 4804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83901846712436,
            21.01725361904968,
            105.83912818414053,
            21.01735329713119
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83901846712436,
              21.01735329713119
            ],
            [
              105.8390522452013,
              21.01733082091061
            ],
            [
              105.83906988156882,
              21.017313804803166
            ],
            [
              105.83912818414053,
              21.01725361904968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.3_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4811",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735329713119,
          "to_latitude": 21.01725361904968,
          "to_longitude": 105.83912818414053,
          "from_longitude": 105.83901846712436
        }
      },
      {
        "id": 4805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83901846712436,
            21.01735329713119,
            105.83918176644752,
            21.017522329310903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83901846712436,
              21.01735329713119
            ],
            [
              105.83902976364,
              21.017364025003424
            ],
            [
              105.83904908404922,
              21.017382370829672
            ],
            [
              105.83907289719461,
              21.0174062971901
            ],
            [
              105.83910234331296,
              21.017437581421856
            ],
            [
              105.83912369862522,
              21.017460118997985
            ],
            [
              105.83914693562363,
              21.017485599023995
            ],
            [
              105.83918176644752,
              21.017522329310903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.3_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4812",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735329713119,
          "to_latitude": 21.017522329310903,
          "to_longitude": 105.83918176644752,
          "from_longitude": 105.83901846712436
        }
      },
      {
        "id": 4806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839013351529,
            21.01736266786258,
            105.83916951390296,
            21.017530887279726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902015870328,
              21.01736266786258
            ],
            [
              105.8390158973392,
              21.017365945904057
            ],
            [
              105.839013351529,
              21.01736792026168
            ],
            [
              105.83904711328216,
              21.01739892489709
            ],
            [
              105.83906388006527,
              21.017415095708685
            ],
            [
              105.83909835692575,
              21.017452354339248
            ],
            [
              105.83912834716942,
              21.017485810844466
            ],
            [
              105.83916951390296,
              21.017530887279726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.2_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4813",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01736266786258,
          "to_latitude": 21.017530887279726,
          "to_longitude": 105.83916951390296,
          "from_longitude": 105.83902015870328
        }
      },
      {
        "id": 4807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387495595345,
            21.017370000391963,
            105.83897182696145,
            21.017498447992985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8387495595345,
              21.017498447992985
            ],
            [
              105.83884015509864,
              21.017450684114618
            ],
            [
              105.83891304054247,
              21.01740625009868
            ],
            [
              105.83893072060329,
              21.017395493129527
            ],
            [
              105.83895420010494,
              21.017379552860675
            ],
            [
              105.83896848064308,
              21.017370000391963
            ],
            [
              105.8389713550201,
              21.017373797820134
            ],
            [
              105.83897182696145,
              21.01737441871696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.1_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4814",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017498447992985,
          "to_latitude": 21.01737441871696,
          "to_longitude": 105.83897182696145,
          "from_longitude": 105.8387495595345
        }
      },
      {
        "id": 4808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83888641475521,
            21.017141187657,
            105.83899190047703,
            21.017370000391963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896848064308,
              21.017370000391963
            ],
            [
              105.83898202420605,
              21.01736094200715
            ],
            [
              105.83893107763095,
              21.017307468610795
            ],
            [
              105.83889303307537,
              21.017271429727497
            ],
            [
              105.83888641475521,
              21.017253692341402
            ],
            [
              105.83894504917482,
              21.017207938526653
            ],
            [
              105.838931979471,
              21.01719199063067
            ],
            [
              105.83896280077387,
              21.017141187657
            ],
            [
              105.83899190047703,
              21.017160255712856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.1_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4815",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017370000391963,
          "to_latitude": 21.017160255712856,
          "to_longitude": 105.83899190047703,
          "from_longitude": 105.83896848064308
        }
      },
      {
        "id": 4809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83865335335305,
            21.017083356416265,
            105.83887481264065,
            21.01736996815507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83865335335305,
              21.01736996815507
            ],
            [
              105.83865418833273,
              21.017369797845568
            ],
            [
              105.83866222774203,
              21.017368152280483
            ],
            [
              105.83867127592181,
              21.017367655158484
            ],
            [
              105.8387000110227,
              21.017366074657794
            ],
            [
              105.83872594713958,
              21.017355427844336
            ],
            [
              105.83876464662707,
              21.01733467966959
            ],
            [
              105.83878702674237,
              21.017320479033465
            ],
            [
              105.8388106771155,
              21.017309806445386
            ],
            [
              105.83882663479882,
              21.01730154184481
            ],
            [
              105.83883491261443,
              21.01730537314774
            ],
            [
              105.83887481264065,
              21.017275864081824
            ],
            [
              105.83886500327515,
              21.017261861905403
            ],
            [
              105.83884731921192,
              21.017240082963923
            ],
            [
              105.8388169402354,
              21.01719265545127
            ],
            [
              105.83878821381421,
              21.017159144784074
            ],
            [
              105.83878473890105,
              21.017155639345038
            ],
            [
              105.83883871251575,
              21.01711639008028
            ],
            [
              105.8388529053059,
              21.017108006825644
            ],
            [
              105.8388363441468,
              21.017083356416265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.10_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4816",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01736996815507,
          "to_latitude": 21.017083356416265,
          "to_longitude": 105.8388363441468,
          "from_longitude": 105.83865335335305
        }
      },
      {
        "id": 4810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914069076774,
            21.0170238442482,
            105.83934224242256,
            21.017238382298522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914069076774,
              21.017238382298522
            ],
            [
              105.83916242580078,
              21.017214115659236
            ],
            [
              105.83918316096789,
              21.017190809560176
            ],
            [
              105.83919634617202,
              21.017175647134984
            ],
            [
              105.83920111620044,
              21.0171702711011
            ],
            [
              105.83922063317857,
              21.01714827706844
            ],
            [
              105.83924605639615,
              21.017121279844027
            ],
            [
              105.83929020177861,
              21.017076203455183
            ],
            [
              105.83930119244907,
              21.01706562577348
            ],
            [
              105.83934224242256,
              21.0170238442482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.4_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4817",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017238382298522,
          "to_latitude": 21.0170238442482,
          "to_longitude": 105.83934224242256,
          "from_longitude": 105.83914069076774
        }
      },
      {
        "id": 4811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924605639615,
            21.017121279844027,
            105.83930483639129,
            21.01716723490388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924605639615,
              21.017121279844027
            ],
            [
              105.83930483639129,
              21.01716723490388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.4_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4818",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017121279844027,
          "to_latitude": 21.01716723490388,
          "to_longitude": 105.83930483639129,
          "from_longitude": 105.83924605639615
        }
      },
      {
        "id": 4812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899831374485,
            21.01715545448567,
            105.83920070790366,
            21.017349752019044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899831374485,
              21.017349752019044
            ],
            [
              105.83901524576859,
              21.01733579409315
            ],
            [
              105.8390250560708,
              21.01732966972325
            ],
            [
              105.83904001592363,
              21.01732049505872
            ],
            [
              105.83904684278798,
              21.01731532385162
            ],
            [
              105.8390591799644,
              21.01730418496435
            ],
            [
              105.8390705373146,
              21.017293289360058
            ],
            [
              105.83907961249578,
              21.017284036233416
            ],
            [
              105.83910214019316,
              21.01726492851808
            ],
            [
              105.83912141071926,
              21.017242895066822
            ],
            [
              105.83913112525988,
              21.017232379690096
            ],
            [
              105.8391437233019,
              21.01721869603248
            ],
            [
              105.83915176554956,
              21.017209479610113
            ],
            [
              105.83917695937166,
              21.017181537851986
            ],
            [
              105.83918023776532,
              21.017177942204725
            ],
            [
              105.83918742654717,
              21.017168343411132
            ],
            [
              105.8391938196669,
              21.01715545448567
            ],
            [
              105.839196470156,
              21.01715723250327
            ],
            [
              105.83920070790366,
              21.017161941780508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.5_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4819",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017349752019044,
          "to_latitude": 21.017161941780508,
          "to_longitude": 105.83920070790366,
          "from_longitude": 105.83899831374485
        }
      },
      {
        "id": 4813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391938196669,
            21.016987804696882,
            105.83935945578023,
            21.01715545448567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391938196669,
              21.01715545448567
            ],
            [
              105.83921067371857,
              21.017138571384454
            ],
            [
              105.83922448134432,
              21.017122730275453
            ],
            [
              105.839244971869,
              21.01710040447054
            ],
            [
              105.83925954325098,
              21.017084559591016
            ],
            [
              105.83928059600458,
              21.017064035649135
            ],
            [
              105.83929789900449,
              21.017047106066837
            ],
            [
              105.83931244616015,
              21.017032950330677
            ],
            [
              105.83934540327388,
              21.017000621225375
            ],
            [
              105.83935945578023,
              21.016987804696882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.5_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4820",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01715545448567,
          "to_latitude": 21.016987804696882,
          "to_longitude": 105.83935945578023,
          "from_longitude": 105.8391938196669
        }
      },
      {
        "id": 4814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83935919824387,
            21.01688022509992,
            105.83961870842973,
            21.01704858894138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83935919824387,
              21.017006415986963
            ],
            [
              105.83940133785002,
              21.016965283005792
            ],
            [
              105.83940532041949,
              21.01696152310607
            ],
            [
              105.83943156786111,
              21.01693674281519
            ],
            [
              105.839472601756,
              21.01690155396836
            ],
            [
              105.83948764797988,
              21.016888574461973
            ],
            [
              105.83949770742831,
              21.01688022509992
            ],
            [
              105.83953509559916,
              21.016911041035755
            ],
            [
              105.83955788001057,
              21.016935752791433
            ],
            [
              105.83961870842973,
              21.01699931139686
            ],
            [
              105.83959027959168,
              21.017024827770246
            ],
            [
              105.83961790017675,
              21.01704858894138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.6_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4821",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017006415986963,
          "to_latitude": 21.01704858894138,
          "to_longitude": 105.83961790017675,
          "from_longitude": 105.83935919824387
        }
      },
      {
        "id": 4815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936856374437,
            21.016873906690282,
            105.83956055911415,
            21.016948416332436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936856374437,
              21.01692906828772
            ],
            [
              105.83937198065054,
              21.016927020078825
            ],
            [
              105.83937290774145,
              21.01692646453815
            ],
            [
              105.83937488463417,
              21.01692527878699
            ],
            [
              105.83939582097462,
              21.016948416332436
            ],
            [
              105.83942492840778,
              21.016928472241766
            ],
            [
              105.83944073908343,
              21.016915610902526
            ],
            [
              105.83948936086918,
              21.016873906690282
            ],
            [
              105.83950026914367,
              21.01687542356698
            ],
            [
              105.83956055911415,
              21.016923051988037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.7_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4822",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01692906828772,
          "to_latitude": 21.016923051988037,
          "to_longitude": 105.83956055911415,
          "from_longitude": 105.83936856374437
        }
      },
      {
        "id": 4816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946637790545,
            21.01684894932437,
            105.83948936086918,
            21.016873906690282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83948936086918,
              21.016873906690282
            ],
            [
              105.83946637790545,
              21.01684894932437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.7_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.4823",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016873906690282,
          "to_latitude": 21.01684894932437,
          "to_longitude": 105.83946637790545,
          "from_longitude": 105.83948936086918
        }
      },
      {
        "id": 4817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392543668407,
            21.016608270909256,
            105.83935600144443,
            21.01682064421264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392543668407,
              21.01682064421264
            ],
            [
              105.8392612223376,
              21.016817844721565
            ],
            [
              105.8393207106309,
              21.01680560249057
            ],
            [
              105.83933919681743,
              21.016789022033993
            ],
            [
              105.83933164729854,
              21.01674549861147
            ],
            [
              105.83932656712634,
              21.01669957488194
            ],
            [
              105.8393221711131,
              21.01668669402051
            ],
            [
              105.83930561008823,
              21.01665416483586
            ],
            [
              105.83935600144443,
              21.016608270909256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.9_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4824",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01682064421264,
          "to_latitude": 21.016608270909256,
          "to_longitude": 105.83935600144443,
          "from_longitude": 105.8392543668407
        }
      },
      {
        "id": 4818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83891190766568,
            21.016450433654256,
            105.83914312352604,
            21.01673994800136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891190766568,
              21.016450433654256
            ],
            [
              105.83893948343254,
              21.016479978505892
            ],
            [
              105.8389537234306,
              21.016496212374374
            ],
            [
              105.83896406432861,
              21.01650739567522
            ],
            [
              105.83896883108208,
              21.01651347434738
            ],
            [
              105.83898380994059,
              21.016531270759312
            ],
            [
              105.83899620399619,
              21.01654939875528
            ],
            [
              105.83900131911656,
              21.01655687841484
            ],
            [
              105.83901917399554,
              21.01657584386256
            ],
            [
              105.83903112389198,
              21.016590405409303
            ],
            [
              105.83904160680734,
              21.016603759354364
            ],
            [
              105.83910239828667,
              21.01667057897672
            ],
            [
              105.83913456959885,
              21.016709799942713
            ],
            [
              105.83914275065047,
              21.016738636553377
            ],
            [
              105.83914312352604,
              21.01673994800136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT57.11_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4825",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016450433654256,
          "to_latitude": 21.01673994800136,
          "to_longitude": 105.83914312352604,
          "from_longitude": 105.83891190766568
        }
      },
      {
        "id": 4819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899620399619,
            21.016343551027273,
            105.83910623391314,
            21.01654939875528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899620399619,
              21.01654939875528
            ],
            [
              105.83910623391314,
              21.01646087215851
            ],
            [
              105.83909007810189,
              21.016439204926254
            ],
            [
              105.83906655051231,
              21.01641900837008
            ],
            [
              105.83906201266092,
              21.016399510279918
            ],
            [
              105.83904522235349,
              21.016376533788158
            ],
            [
              105.83903066325259,
              21.016349856621524
            ],
            [
              105.83902191087559,
              21.016343551027273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.11_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4826",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654939875528,
          "to_latitude": 21.016343551027273,
          "to_longitude": 105.83902191087559,
          "from_longitude": 105.83899620399619
        }
      },
      {
        "id": 4820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897249143955,
            21.016735412197068,
            105.83912605636893,
            21.016856585566764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897249143955,
              21.016856585566764
            ],
            [
              105.83898653545366,
              21.01684568665413
            ],
            [
              105.83899540370564,
              21.01683865287002
            ],
            [
              105.8390056828708,
              21.016828993681617
            ],
            [
              105.83901789098661,
              21.0168180044662
            ],
            [
              105.83902351525826,
              21.01681401157577
            ],
            [
              105.83903164792991,
              21.016808236898623
            ],
            [
              105.83904584289976,
              21.016797843042035
            ],
            [
              105.83906388541251,
              21.016783797441967
            ],
            [
              105.83907787110554,
              21.016772449004325
            ],
            [
              105.83912605636893,
              21.016735412197068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.12_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4827",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016856585566764,
          "to_latitude": 21.016735412197068,
          "to_longitude": 105.83912605636893,
          "from_longitude": 105.83897249143955
        }
      },
      {
        "id": 4821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892096418572,
            21.016667962948016,
            105.83904584289976,
            21.016797843042035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904584289976,
              21.016797843042035
            ],
            [
              105.83895715225857,
              21.016700085576772
            ],
            [
              105.83892096418572,
              21.016667962948016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.12_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4828",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016797843042035,
          "to_latitude": 21.016667962948016,
          "to_longitude": 105.83892096418572,
          "from_longitude": 105.83904584289976
        }
      },
      {
        "id": 4822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926832025567,
            21.016855102003145,
            105.8393651601471,
            21.016935729193925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393651601471,
              21.016935729193925
            ],
            [
              105.83932462854813,
              21.016898412873488
            ],
            [
              105.83927216283372,
              21.01685847103814
            ],
            [
              105.83926832025567,
              21.016855102003145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.8_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4829",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016935729193925,
          "to_latitude": 21.016855102003145,
          "to_longitude": 105.83926832025567,
          "from_longitude": 105.8393651601471
        }
      },
      {
        "id": 4823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915918840412,
            21.016747762030487,
            105.83926832025567,
            21.016855102003145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83926832025567,
              21.016855102003145
            ],
            [
              105.8392593417865,
              21.016847421928393
            ],
            [
              105.83923651958327,
              21.016824712763956
            ],
            [
              105.83915918840412,
              21.016747762030487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.8_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4830",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016855102003145,
          "to_latitude": 21.016747762030487,
          "to_longitude": 105.83915918840412,
          "from_longitude": 105.83926832025567
        }
      },
      {
        "id": 4824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83885818243066,
            21.016754793408218,
            105.83912682180792,
            21.016951819748865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912682180792,
              21.016754793408218
            ],
            [
              105.83909263015111,
              21.016775191556807
            ],
            [
              105.83908505008176,
              21.01678121731706
            ],
            [
              105.83902873496048,
              21.0168250521292
            ],
            [
              105.83898772693814,
              21.016855939592816
            ],
            [
              105.83889522802309,
              21.016924329911525
            ],
            [
              105.83888461227204,
              21.016934180430283
            ],
            [
              105.83885818243066,
              21.016951819748865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.13_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4831",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016754793408218,
          "to_latitude": 21.016951819748865,
          "to_longitude": 105.83885818243066,
          "from_longitude": 105.83912682180792
        }
      },
      {
        "id": 4825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859505677172,
            21.016946216616393,
            105.83885818243066,
            21.017110579621246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885240924705,
              21.016946216616393
            ],
            [
              105.83885522977609,
              21.016949159862914
            ],
            [
              105.83885818243066,
              21.016951819748865
            ],
            [
              105.838837468791,
              21.016965734886373
            ],
            [
              105.83878391140824,
              21.017000794752473
            ],
            [
              105.83874657480483,
              21.017023729237565
            ],
            [
              105.83872210339972,
              21.017041531395716
            ],
            [
              105.83869678569381,
              21.017056619922577
            ],
            [
              105.83859505677172,
              21.017110579621246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.13_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4832",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016946216616393,
          "to_latitude": 21.017110579621246,
          "to_longitude": 105.83859505677172,
          "from_longitude": 105.83885240924705
        }
      },
      {
        "id": 4826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872065752279,
            21.01659996274542,
            105.83889753955654,
            21.016712271019287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872065752279,
              21.016712271019287
            ],
            [
              105.83872489325555,
              21.016709572078096
            ],
            [
              105.83872646182415,
              21.016708647572706
            ],
            [
              105.83881848098541,
              21.016654415936998
            ],
            [
              105.83889753955654,
              21.01659996274542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.1_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.4833",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016712271019287,
          "to_latitude": 21.01659996274542,
          "to_longitude": 105.83889753955654,
          "from_longitude": 105.83872065752279
        }
      },
      {
        "id": 4827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83865802278967,
            21.01638564550336,
            105.83883367427157,
            21.016608750619213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83865802278967,
              21.016608750619213
            ],
            [
              105.83866291325205,
              21.016605988839167
            ],
            [
              105.83866463386599,
              21.01660008591855
            ],
            [
              105.83869387320925,
              21.01657637305148
            ],
            [
              105.83873055027192,
              21.016545606365153
            ],
            [
              105.83871664454665,
              21.016531347083156
            ],
            [
              105.83868156050323,
              21.016497093671695
            ],
            [
              105.83872640850404,
              21.016456045953536
            ],
            [
              105.83876796964901,
              21.01642585672768
            ],
            [
              105.83877629414003,
              21.01641981014992
            ],
            [
              105.83879296907458,
              21.016413734142084
            ],
            [
              105.83883367427157,
              21.01638564550336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.3_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.4834",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016608750619213,
          "to_latitude": 21.01638564550336,
          "to_longitude": 105.83883367427157,
          "from_longitude": 105.83865802278967
        }
      },
      {
        "id": 4828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870178456604,
            21.016343337537496,
            105.83876796964901,
            21.01642585672768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870178456604,
              21.016343337537496
            ],
            [
              105.83876796964901,
              21.01642585672768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.3_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.4835",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016343337537496,
          "to_latitude": 21.01642585672768,
          "to_longitude": 105.83876796964901,
          "from_longitude": 105.83870178456604
        }
      },
      {
        "id": 4829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871079522373,
            21.01670367656864,
            105.8388324986139,
            21.01692276955766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871893705712,
              21.016709427120766
            ],
            [
              105.83872368406465,
              21.01670664256238
            ],
            [
              105.83872181285318,
              21.01670367656864
            ],
            [
              105.83871701949421,
              21.016706257226932
            ],
            [
              105.83871079522373,
              21.01670960816366
            ],
            [
              105.8387214952342,
              21.016727555757793
            ],
            [
              105.83872377600156,
              21.016731512375234
            ],
            [
              105.8387198667392,
              21.016739782597444
            ],
            [
              105.83872242185137,
              21.01674480547283
            ],
            [
              105.83872551579498,
              21.016750886076323
            ],
            [
              105.838744946854,
              21.01678267497845
            ],
            [
              105.83875625133045,
              21.01680154629736
            ],
            [
              105.83877022235855,
              21.01682403723402
            ],
            [
              105.83878644866236,
              21.016849193301557
            ],
            [
              105.83880350851683,
              21.016879255181564
            ],
            [
              105.83882154832335,
              21.01690617171877
            ],
            [
              105.8388324986139,
              21.01692276955766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.2_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.4836",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016709427120766,
          "to_latitude": 21.01692276955766,
          "to_longitude": 105.8388324986139,
          "from_longitude": 105.83871893705712
        }
      },
      {
        "id": 4830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856535579582,
            21.01647209985126,
            105.83871079522373,
            21.01670960816366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856535579582,
              21.01647209985126
            ],
            [
              105.838576597887,
              21.016489284269536
            ],
            [
              105.83863109111996,
              21.016574139073786
            ],
            [
              105.83866496751638,
              21.016630510269895
            ],
            [
              105.83871079522373,
              21.01670960816366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.2_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.4837",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01647209985126,
          "to_latitude": 21.01670960816366,
          "to_longitude": 105.83871079522373,
          "from_longitude": 105.83856535579582
        }
      },
      {
        "id": 4831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862393723874,
            21.01669819599673,
            105.83872242185137,
            21.01676946206494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872242185137,
              21.01674480547283
            ],
            [
              105.83867177682647,
              21.01676946206494
            ],
            [
              105.83862393723874,
              21.01669819599673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.2_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.4838",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01674480547283,
          "to_latitude": 21.01669819599673,
          "to_longitude": 105.83862393723874,
          "from_longitude": 105.83872242185137
        }
      },
      {
        "id": 4832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83882845655054,
            21.017686260075845,
            105.83896544368993,
            21.017917347204865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882845655054,
              21.017686260075845
            ],
            [
              105.83887884910114,
              21.017755720578442
            ],
            [
              105.83890260696991,
              21.017798134414317
            ],
            [
              105.83892802053676,
              21.01784780109565
            ],
            [
              105.83895815090762,
              21.017904235848608
            ],
            [
              105.83896544368993,
              21.017917347204865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.9_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4839",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017686260075845,
          "to_latitude": 21.017917347204865,
          "to_longitude": 105.83896544368993,
          "from_longitude": 105.83882845655054
        }
      },
      {
        "id": 4833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874242124773,
            21.017517599916772,
            105.83882845655054,
            21.01769603781315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880750515463,
              21.01769603781315
            ],
            [
              105.83880850777737,
              21.017695570418876
            ],
            [
              105.83882845655054,
              21.017686260075845
            ],
            [
              105.83875712398425,
              21.017546211099635
            ],
            [
              105.83874242124773,
              21.017517599916772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.9_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4840",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01769603781315,
          "to_latitude": 21.017517599916772,
          "to_longitude": 105.83874242124773,
          "from_longitude": 105.83880750515463
        }
      },
      {
        "id": 4834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83864610953361,
            21.01730934878119,
            105.83872900124513,
            21.01749044563046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83864610953361,
              21.01730934878119
            ],
            [
              105.83866785203985,
              21.01735882586514
            ],
            [
              105.83867127592181,
              21.017367655158484
            ],
            [
              105.83868165880003,
              21.017394423392144
            ],
            [
              105.83869203629477,
              21.017414035410255
            ],
            [
              105.83870472025046,
              21.017441680166534
            ],
            [
              105.83871027490018,
              21.01745378668802
            ],
            [
              105.83872900124513,
              21.01749044563046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.11_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4841",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01730934878119,
          "to_latitude": 21.01749044563046,
          "to_longitude": 105.83872900124513,
          "from_longitude": 105.83864610953361
        }
      },
      {
        "id": 4835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870472025046,
            21.017388802371237,
            105.83880884872636,
            21.017441680166534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870472025046,
              21.017441680166534
            ],
            [
              105.83880884872636,
              21.017388802371237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.11_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4842",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017441680166534,
          "to_latitude": 21.017388802371237,
          "to_longitude": 105.83880884872636,
          "from_longitude": 105.83870472025046
        }
      },
      {
        "id": 4836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857944343546,
            21.017135698464074,
            105.83864610953361,
            21.01730934878119
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83864610953361,
              21.01730934878119
            ],
            [
              105.83864237947442,
              21.01730132938181
            ],
            [
              105.83863669798605,
              21.01728356839918
            ],
            [
              105.8386354477161,
              21.017279658184655
            ],
            [
              105.83862275816401,
              21.017235868325567
            ],
            [
              105.83860749409487,
              21.017196315533706
            ],
            [
              105.83857944343546,
              21.017135698464074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.11_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4843",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01730934878119,
          "to_latitude": 21.017135698464074,
          "to_longitude": 105.83857944343546,
          "from_longitude": 105.83864610953361
        }
      },
      {
        "id": 4837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858046797113,
            21.0172186364492,
            105.83864989483361,
            21.01740414512536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858046797113,
              21.0172186364492
            ],
            [
              105.83859566331903,
              21.0172600068684
            ],
            [
              105.83860584539653,
              21.017289403575276
            ],
            [
              105.83862313851803,
              21.017339331630293
            ],
            [
              105.83862953874929,
              21.01735781074579
            ],
            [
              105.83864989483361,
              21.01740414512536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4844",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0172186364492,
          "to_latitude": 21.01740414512536,
          "to_longitude": 105.83864989483361,
          "from_longitude": 105.83858046797113
        }
      },
      {
        "id": 4838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83847861615756,
            21.017030566140622,
            105.83858046797113,
            21.0172186364492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858046797113,
              21.0172186364492
            ],
            [
              105.83855773905447,
              21.017165806931494
            ],
            [
              105.83855297730099,
              21.017157010423496
            ],
            [
              105.8385294815521,
              21.01711361162727
            ],
            [
              105.83849729842213,
              21.017061253215253
            ],
            [
              105.83847861615756,
              21.017030566140622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4845",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0172186364492,
          "to_latitude": 21.017030566140622,
          "to_longitude": 105.83847861615756,
          "from_longitude": 105.83858046797113
        }
      },
      {
        "id": 4839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83833715473862,
            21.017157010423496,
            105.83855297730099,
            21.017311133521265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855297730099,
              21.017157010423496
            ],
            [
              105.83845414975816,
              21.017208437551286
            ],
            [
              105.83846906756018,
              21.017236302542926
            ],
            [
              105.83833715473862,
              21.017311133521265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4846",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017157010423496,
          "to_latitude": 21.017311133521265,
          "to_longitude": 105.83833715473862,
          "from_longitude": 105.83855297730099
        }
      },
      {
        "id": 4840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857380635024,
            21.017057401541713,
            105.83865920952144,
            21.01710092955668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857380635024,
              21.01710092955668
            ],
            [
              105.83863630154137,
              21.017066953820716
            ],
            [
              105.83865384691417,
              21.017057401541713
            ],
            [
              105.83865588850584,
              21.01706141985491
            ],
            [
              105.83865920952144,
              21.01706682101884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.14_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4847",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01710092955668,
          "to_latitude": 21.01706682101884,
          "to_longitude": 105.83865920952144,
          "from_longitude": 105.83857380635024
        }
      },
      {
        "id": 4841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83865384691417,
            21.01696147361571,
            105.83882003857458,
            21.017057401541713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83865384691417,
              21.017057401541713
            ],
            [
              105.83870596448978,
              21.017029081443415
            ],
            [
              105.83872856784797,
              21.01701679274286
            ],
            [
              105.83876059639866,
              21.016997978745778
            ],
            [
              105.83879032803188,
              21.016978859036502
            ],
            [
              105.83882003857458,
              21.01696147361571
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.14_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.4848",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017057401541713,
          "to_latitude": 21.01696147361571,
          "to_longitude": 105.83882003857458,
          "from_longitude": 105.83865384691417
        }
      },
      {
        "id": 4842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83839723024695,
            21.016830501248872,
            105.83847824738791,
            21.01696356952863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83839723024695,
              21.016830501248872
            ],
            [
              105.83842676431382,
              21.016882038160574
            ],
            [
              105.83844353578002,
              21.016909049506665
            ],
            [
              105.83844408518861,
              21.01690993466787
            ],
            [
              105.838459051145,
              21.01693368614382
            ],
            [
              105.83847443069311,
              21.016957256741478
            ],
            [
              105.83847824738791,
              21.01696356952863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.13_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4849",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016830501248872,
          "to_latitude": 21.01696356952863,
          "to_longitude": 105.83847824738791,
          "from_longitude": 105.83839723024695
        }
      },
      {
        "id": 4843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83847824738791,
            21.01696356952863,
            105.83855292491104,
            21.017096024111364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83847824738791,
              21.01696356952863
            ],
            [
              105.83848650409384,
              21.01697670686804
            ],
            [
              105.838494280953,
              21.016989357024766
            ],
            [
              105.83850919115962,
              21.017013801543058
            ],
            [
              105.83852040616527,
              21.017038350983036
            ],
            [
              105.83855292491104,
              21.017096024111364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.13_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4850",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01696356952863,
          "to_latitude": 21.017096024111364,
          "to_longitude": 105.83855292491104,
          "from_longitude": 105.83847824738791
        }
      },
      {
        "id": 4844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829958911554,
            21.016658392605198,
            105.83838939784502,
            21.01681858116555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83838939784502,
              21.01681858116555
            ],
            [
              105.83838669271871,
              21.01681356534678
            ],
            [
              105.83838248291445,
              21.01680576056236
            ],
            [
              105.83836472999374,
              21.016772845155756
            ],
            [
              105.83832743432077,
              21.016708448443126
            ],
            [
              105.83829958911554,
              21.016658392605198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.15_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4851",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01681858116555,
          "to_latitude": 21.016658392605198,
          "to_longitude": 105.83829958911554,
          "from_longitude": 105.83838939784502
        }
      },
      {
        "id": 4845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820003534336,
            21.016368772479698,
            105.83840526333577,
            21.016512180618612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820003534336,
              21.016512180618612
            ],
            [
              105.83820124065696,
              21.016511664358504
            ],
            [
              105.83820507250361,
              21.016510020579087
            ],
            [
              105.83820916838222,
              21.016510696784014
            ],
            [
              105.83821561933624,
              21.016511760610065
            ],
            [
              105.8382372091379,
              21.016500913998232
            ],
            [
              105.83835297896884,
              21.016440364953233
            ],
            [
              105.83833689569603,
              21.016413757557466
            ],
            [
              105.83840526333577,
              21.016368772479698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.20_Ngõ 113, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4852",
          "diaChiLapD": "Ngõ 113, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016512180618612,
          "to_latitude": 21.016368772479698,
          "to_longitude": 105.83840526333577,
          "from_longitude": 105.83820003534336
        }
      },
      {
        "id": 4846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809880559764,
            21.01633024889758,
            105.838194514908,
            21.016488797158352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809880559764,
              21.01633024889758
            ],
            [
              105.83813222397924,
              21.016386642335966
            ],
            [
              105.83814209524157,
              21.01640382540925
            ],
            [
              105.83815567616553,
              21.016427451869006
            ],
            [
              105.83817493800649,
              21.016458424258186
            ],
            [
              105.838194514908,
              21.016488797158352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.21_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4853",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01633024889758,
          "to_latitude": 21.016488797158352,
          "to_longitude": 105.838194514908,
          "from_longitude": 105.83809880559764
        }
      },
      {
        "id": 4847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838194514908,
            21.016488797158352,
            105.83828206487479,
            21.016630070761227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838194514908,
              21.016488797158352
            ],
            [
              105.83820916838222,
              21.016510696784014
            ],
            [
              105.83821056717923,
              21.016512786274614
            ],
            [
              105.83823516651314,
              21.016549549119272
            ],
            [
              105.83825269061727,
              21.01657888258025
            ],
            [
              105.83828206487479,
              21.016630070761227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.21_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4854",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016488797158352,
          "to_latitude": 21.016630070761227,
          "to_longitude": 105.83828206487479,
          "from_longitude": 105.838194514908
        }
      },
      {
        "id": 4848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84083941223766,
            21.017489697800077,
            105.84131477278449,
            21.01876935652286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84131477278449,
              21.01857518376766
            ],
            [
              105.84131344085345,
              21.018575079258593
            ],
            [
              105.8413009202777,
              21.01857408982973
            ],
            [
              105.84128460634975,
              21.018741773495975
            ],
            [
              105.84084846997534,
              21.01876935652286
            ],
            [
              105.84083941223766,
              21.018515606417157
            ],
            [
              105.84084207454318,
              21.018332296751478
            ],
            [
              105.84084294391411,
              21.018217471104496
            ],
            [
              105.8408508631898,
              21.01812919698531
            ],
            [
              105.84086571639286,
              21.01800955938925
            ],
            [
              105.84088282206139,
              21.017882815838146
            ],
            [
              105.84096836347283,
              21.01759203470118
            ],
            [
              105.84100342210324,
              21.017489697800077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT95.4_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4855",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 209,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01857518376766,
          "to_latitude": 21.017489697800077,
          "to_longitude": 105.84100342210324,
          "from_longitude": 105.84131477278449
        }
      },
      {
        "id": 4849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84021334179391,
            21.018496038915323,
            105.84024031146363,
            21.018805835938185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84024031146363,
              21.018805835938185
            ],
            [
              105.8402394093464,
              21.018800033571694
            ],
            [
              105.84022800892056,
              21.018785044892986
            ],
            [
              105.8402263623509,
              21.01874852667638
            ],
            [
              105.8402194160918,
              21.018635380785117
            ],
            [
              105.84021680827084,
              21.018569626364307
            ],
            [
              105.84021334179391,
              21.018496038915323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67.2_Ngõ 1A, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4856",
          "diaChiLapD": "Ngõ 1A, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018805835938185,
          "to_latitude": 21.018496038915323,
          "to_longitude": 105.84021334179391,
          "from_longitude": 105.84024031146363
        }
      },
      {
        "id": 4850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012243367587,
            21.01853852211544,
            105.84013593785338,
            21.01878275023061
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012243367587,
              21.01853852211544
            ],
            [
              105.84012427202234,
              21.018553986155133
            ],
            [
              105.84012499332175,
              21.018592936808282
            ],
            [
              105.84012823426335,
              21.01864155395835
            ],
            [
              105.84013253332593,
              21.01874305412288
            ],
            [
              105.84013593785338,
              21.01878275023061
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.10A2_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4857",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01853852211544,
          "to_latitude": 21.01878275023061,
          "to_longitude": 105.84013593785338,
          "from_longitude": 105.84012243367587
        }
      },
      {
        "id": 4851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83865657578092,
            21.018889297071624,
            105.8389410765956,
            21.018918786496616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83866154007134,
              21.018918786496616
            ],
            [
              105.83865745230271,
              21.01890649932611
            ],
            [
              105.83865657578092,
              21.01890233888377
            ],
            [
              105.8389410765956,
              21.018889297071624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.2_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4858",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018918786496616,
          "to_latitude": 21.018889297071624,
          "to_longitude": 105.8389410765956,
          "from_longitude": 105.83866154007134
        }
      },
      {
        "id": 4852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83833487252954,
            21.01890233888377,
            105.83865657578092,
            21.018934373200693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83865657578092,
              21.01890233888377
            ],
            [
              105.83833487252954,
              21.018934373200693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.2_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4859",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01890233888377,
          "to_latitude": 21.018934373200693,
          "to_longitude": 105.83833487252954,
          "from_longitude": 105.83865657578092
        }
      },
      {
        "id": 4853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390233722084,
            21.018881247155743,
            105.83914872434501,
            21.01888942808297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914872434501,
              21.01888942808297
            ],
            [
              105.83914799289776,
              21.018885925377766
            ],
            [
              105.83914734722089,
              21.018881247155743
            ],
            [
              105.8390233722084,
              21.018887100091064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.1_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4860",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01888942808297,
          "to_latitude": 21.018887100091064,
          "to_longitude": 105.8390233722084,
          "from_longitude": 105.83914872434501
        }
      },
      {
        "id": 4854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914734722089,
            21.018866529995165,
            105.8393463571689,
            21.018881247155743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914734722089,
              21.018881247155743
            ],
            [
              105.83925967405872,
              21.018874221409064
            ],
            [
              105.8393463571689,
              21.018866529995165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.1_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4861",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018881247155743,
          "to_latitude": 21.018866529995165,
          "to_longitude": 105.8393463571689,
          "from_longitude": 105.83914734722089
        }
      },
      {
        "id": 4855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378002743212,
            21.01894434156538,
            105.8380061197261,
            21.018965002428896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380061197261,
              21.01894434156538
            ],
            [
              105.83792663520772,
              21.018949177878994
            ],
            [
              105.83792268970355,
              21.018944608937836
            ],
            [
              105.83791987014305,
              21.018944597536308
            ],
            [
              105.83791660133559,
              21.018944583831527
            ],
            [
              105.83791210119618,
              21.018944565353287
            ],
            [
              105.83791005322169,
              21.018951458005525
            ],
            [
              105.83786335017835,
              21.01895627642459
            ],
            [
              105.8378002743212,
              21.018965002428896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4862",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01894434156538,
          "to_latitude": 21.018965002428896,
          "to_longitude": 105.8378002743212,
          "from_longitude": 105.8380061197261
        }
      },
      {
        "id": 4856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380061197261,
            21.01892710869525,
            105.83827913075653,
            21.018946031497126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380061197261,
              21.01894434156538
            ],
            [
              105.83808000460178,
              21.018946031497126
            ],
            [
              105.83818473436743,
              21.018935969313457
            ],
            [
              105.83827913075653,
              21.01892710869525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4863",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01894434156538,
          "to_latitude": 21.01892710869525,
          "to_longitude": 105.83827913075653,
          "from_longitude": 105.8380061197261
        }
      },
      {
        "id": 4857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379157405236,
            21.018712938416602,
            105.83801049337569,
            21.01897167226614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791736449115,
              21.01897167226614
            ],
            [
              105.8379165798334,
              21.01894572832183
            ],
            [
              105.83791660133559,
              21.018944583831527
            ],
            [
              105.83791801886572,
              21.01886479871525
            ],
            [
              105.8379157405236,
              21.018803773694774
            ],
            [
              105.83794304584883,
              21.0188003650064
            ],
            [
              105.83797745039567,
              21.01878817362074
            ],
            [
              105.83798929451353,
              21.018776214399338
            ],
            [
              105.83801049337569,
              21.018771091657086
            ],
            [
              105.83800152717596,
              21.018712938416602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.4_Ngõ 73, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4864",
          "diaChiLapD": "Ngõ 73, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01897167226614,
          "to_latitude": 21.018712938416602,
          "to_longitude": 105.83800152717596,
          "from_longitude": 105.83791736449115
        }
      },
      {
        "id": 4858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831361491055,
            21.018668554957014,
            105.83179103990172,
            21.018854143020658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831361491055,
              21.01871530662616
            ],
            [
              105.83136177378643,
              21.018715638534328
            ],
            [
              105.8313704025477,
              21.01872570435215
            ],
            [
              105.83139982579102,
              21.018760036640337
            ],
            [
              105.83145688551545,
              21.018837469407295
            ],
            [
              105.83146286703739,
              21.018846330612106
            ],
            [
              105.831468140516,
              21.018854143020658
            ],
            [
              105.83179103990172,
              21.018668554957014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3.4_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4865",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01871530662616,
          "to_latitude": 21.018668554957014,
          "to_longitude": 105.83179103990172,
          "from_longitude": 105.831361491055
        }
      },
      {
        "id": 4859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338891234616,
            21.014966387836612,
            105.83406984615507,
            21.015144504487672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83406984615507,
              21.014966387836612
            ],
            [
              105.83406274041128,
              21.014973662921552
            ],
            [
              105.83397776444039,
              21.015056916035835
            ],
            [
              105.8339236710047,
              21.01510846087148
            ],
            [
              105.8339246991655,
              21.015109818797967
            ],
            [
              105.83392533882844,
              21.01511137191882
            ],
            [
              105.83392555195194,
              21.015113025581904
            ],
            [
              105.83392532546375,
              21.015114677786908
            ],
            [
              105.83392467323007,
              21.015116227304677
            ],
            [
              105.83392363415183,
              21.015117577299502
            ],
            [
              105.83392277984137,
              21.015118248970097
            ],
            [
              105.83392227318676,
              21.015118646162744
            ],
            [
              105.83392067461611,
              21.015119367545875
            ],
            [
              105.83392017877914,
              21.015119461201973
            ],
            [
              105.83391893534653,
              21.015119696715853
            ],
            [
              105.83391716300622,
              21.015119614177433
            ],
            [
              105.83391546728265,
              21.015119124812323
            ],
            [
              105.83391395223265,
              21.015118258820124
            ],
            [
              105.8338891234616,
              21.015144504487672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.21_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4866",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014966387836612,
          "to_latitude": 21.015144504487672,
          "to_longitude": 105.8338891234616,
          "from_longitude": 105.83406984615507
        }
      },
      {
        "id": 4860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83389762139419,
            21.015106475912106,
            105.83426614219991,
            21.015381867066942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83389762139419,
              21.015106475912106
            ],
            [
              105.83390235273632,
              21.015110555168338
            ],
            [
              105.83390360770751,
              21.015111183984104
            ],
            [
              105.83392017877914,
              21.015119461201973
            ],
            [
              105.83392937636987,
              21.01512405779074
            ],
            [
              105.83393070362156,
              21.015125229993302
            ],
            [
              105.83402507728066,
              21.01520832289193
            ],
            [
              105.83408006462916,
              21.015245572373313
            ],
            [
              105.8340968353328,
              21.01526085671489
            ],
            [
              105.83410356345946,
              21.015254347607865
            ],
            [
              105.83411634753433,
              21.015264374853956
            ],
            [
              105.83426614219991,
              21.015381867066942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.20_Ngõ 160, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4867",
          "diaChiLapD": "Ngõ 160, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015106475912106,
          "to_latitude": 21.015381867066942,
          "to_longitude": 105.83426614219991,
          "from_longitude": 105.83389762139419
        }
      },
      {
        "id": 4861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83406984615507,
            21.01470893077173,
            105.83433070617836,
            21.014966387836612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83406984615507,
              21.014966387836612
            ],
            [
              105.83411532421769,
              21.014919822221835
            ],
            [
              105.8341713688929,
              21.014864575378272
            ],
            [
              105.83421248251582,
              21.014824046614333
            ],
            [
              105.83429323498267,
              21.014748510476846
            ],
            [
              105.83433070617836,
              21.01470893077173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.21_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4868",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014966387836612,
          "to_latitude": 21.01470893077173,
          "to_longitude": 105.83433070617836,
          "from_longitude": 105.83406984615507
        }
      },
      {
        "id": 4862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411634753433,
            21.01512359682863,
            105.83425166576949,
            21.015264374853956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425166576949,
              21.01512359682863
            ],
            [
              105.83421403238297,
              21.015165341439662
            ],
            [
              105.8342078913621,
              21.015165162002916
            ],
            [
              105.83411634753433,
              21.015264374853956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.20_Ngách 160/2, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4869",
          "diaChiLapD": "Ngách 160/2, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01512359682863,
          "to_latitude": 21.015264374853956,
          "to_longitude": 105.83411634753433,
          "from_longitude": 105.83425166576949
        }
      },
      {
        "id": 4863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336286294265,
            21.015679836708006,
            105.8335323330451,
            21.01588008870142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336286294265,
              21.015679836708006
            ],
            [
              105.83345415028138,
              21.015745358345956
            ],
            [
              105.83344722949786,
              21.015753039854694
            ],
            [
              105.83347742140023,
              21.015777024302697
            ],
            [
              105.83344792901228,
              21.01581054836123
            ],
            [
              105.8335323330451,
              21.01588008870142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.17_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4870",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015679836708006,
          "to_latitude": 21.01588008870142,
          "to_longitude": 105.8335323330451,
          "from_longitude": 105.83336286294265
        }
      },
      {
        "id": 4864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8330256148505,
            21.015679836708006,
            105.83336286294265,
            21.016043284999824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336286294265,
              21.015679836708006
            ],
            [
              105.83325818131797,
              21.015793815260484
            ],
            [
              105.83315149543212,
              21.015909975822524
            ],
            [
              105.8330256148505,
              21.016043284999824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.17_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4871",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015679836708006,
          "to_latitude": 21.016043284999824,
          "to_longitude": 105.8330256148505,
          "from_longitude": 105.83336286294265
        }
      },
      {
        "id": 4865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83276736229863,
            21.01608411583765,
            105.83299118965903,
            21.016320034723698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299118965903,
              21.01608411583765
            ],
            [
              105.83276736229863,
              21.016320034723698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.16_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4872",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01608411583765,
          "to_latitude": 21.016320034723698,
          "to_longitude": 105.83276736229863,
          "from_longitude": 105.83299118965903
        }
      },
      {
        "id": 4866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8325835245924,
            21.016320034723698,
            105.83276736229863,
            21.016508381674637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83276736229863,
              21.016320034723698
            ],
            [
              105.83276420871638,
              21.016323265640985
            ],
            [
              105.83271992464007,
              21.016368635519655
            ],
            [
              105.8325835245924,
              21.016508381674637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.16_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4873",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016320034723698,
          "to_latitude": 21.016508381674637,
          "to_longitude": 105.8325835245924,
          "from_longitude": 105.83276736229863
        }
      },
      {
        "id": 4867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83215161306413,
            21.016876716664207,
            105.83266156364361,
            21.01719516787197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83215161306413,
              21.01688336149337
            ],
            [
              105.8321525752396,
              21.016884096528024
            ],
            [
              105.83215886413231,
              21.01688889621209
            ],
            [
              105.83216317453616,
              21.016892177317445
            ],
            [
              105.83216651121151,
              21.0168947216382
            ],
            [
              105.83220475059656,
              21.016876716664207
            ],
            [
              105.83234463889967,
              21.016985979143023
            ],
            [
              105.83235484545457,
              21.016994008551215
            ],
            [
              105.83238495403957,
              21.0170176973375
            ],
            [
              105.832437459965,
              21.017061898952537
            ],
            [
              105.83244231756815,
              21.017074683813874
            ],
            [
              105.83244483227477,
              21.01708130208193
            ],
            [
              105.83249627049679,
              21.017131919088236
            ],
            [
              105.83250583119367,
              21.017140914484905
            ],
            [
              105.83255357460305,
              21.01719516787197
            ],
            [
              105.83266156364361,
              21.017135372444923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.13_Ngõ 256, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4874",
          "diaChiLapD": "Ngõ 256, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01688336149337,
          "to_latitude": 21.017135372444923,
          "to_longitude": 105.83266156364361,
          "from_longitude": 105.83215161306413
        }
      },
      {
        "id": 4868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244231756815,
            21.017005120266806,
            105.83249416032507,
            21.017074683813874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244231756815,
              21.017074683813874
            ],
            [
              105.83249416032507,
              21.017005120266806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.13_Ngõ 256, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4875",
          "diaChiLapD": "Ngõ 256, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017074683813874,
          "to_latitude": 21.017005120266806,
          "to_longitude": 105.83249416032507,
          "from_longitude": 105.83244231756815
        }
      },
      {
        "id": 4869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83236323677403,
            21.01668638692119,
            105.83255651137738,
            21.016843026800743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83236323677403,
              21.01668638692119
            ],
            [
              105.83236424371557,
              21.016687908444474
            ],
            [
              105.8323655202857,
              21.01668982787392
            ],
            [
              105.8323741168545,
              21.016702769669976
            ],
            [
              105.83241982808488,
              21.01674378544704
            ],
            [
              105.83250449014012,
              21.016807458580793
            ],
            [
              105.83251955648565,
              21.016818789871174
            ],
            [
              105.83255651137738,
              21.016843026800743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.15_Ngõ 246, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4876",
          "diaChiLapD": "Ngõ 246, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01668638692119,
          "to_latitude": 21.016843026800743,
          "to_longitude": 105.83255651137738,
          "from_longitude": 105.83236323677403
        }
      },
      {
        "id": 4870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83250449014012,
            21.016783057201877,
            105.83262102094697,
            21.016867392605004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83262102094697,
              21.016867392605004
            ],
            [
              105.8325952094633,
              21.01684840037283
            ],
            [
              105.83260027463906,
              21.01683844643288
            ],
            [
              105.8325310390367,
              21.016783057201877
            ],
            [
              105.83250449014012,
              21.016807458580793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.15_Ngõ 246, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4877",
          "diaChiLapD": "Ngõ 246, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016867392605004,
          "to_latitude": 21.016807458580793,
          "to_longitude": 105.83250449014012,
          "from_longitude": 105.83262102094697
        }
      },
      {
        "id": 4871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8322919995093,
            21.016511776057488,
            105.8325646864728,
            21.016776263100322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325646864728,
              21.016511776057488
            ],
            [
              105.83238058000282,
              21.016689222190223
            ],
            [
              105.83237500720574,
              21.016694029322906
            ],
            [
              105.83237595242932,
              21.01669552314811
            ],
            [
              105.83237643405914,
              21.016697201693038
            ],
            [
              105.83237641388368,
              21.01669893959636
            ],
            [
              105.83237589600074,
              21.016700608580116
            ],
            [
              105.83237491723337,
              21.0167020838191
            ],
            [
              105.8323741168545,
              21.016702769669976
            ],
            [
              105.83237355104276,
              21.016703255663675
            ],
            [
              105.83237189795905,
              21.016704038718814
            ],
            [
              105.83237008077695,
              21.016704372770846
            ],
            [
              105.83236823596349,
              21.01670423457148
            ],
            [
              105.83236649920423,
              21.01670363339229
            ],
            [
              105.83236499965179,
              21.016702614665803
            ],
            [
              105.8323638483478,
              21.016701253719653
            ],
            [
              105.8322919995093,
              21.016776263100322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.14_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4878",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016511776057488,
          "to_latitude": 21.016776263100322,
          "to_longitude": 105.8322919995093,
          "from_longitude": 105.8325646864728
        }
      },
      {
        "id": 4872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197910493017,
            21.016776263100322,
            105.8322919995093,
            21.017069501822164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8322919995093,
              21.016776263100322
            ],
            [
              105.8322696582473,
              21.01678941948309
            ],
            [
              105.83220316178789,
              21.0168526769777
            ],
            [
              105.83217544392748,
              21.0168806951183
            ],
            [
              105.83216619762338,
              21.016889348080976
            ],
            [
              105.83216317453616,
              21.016892177317445
            ],
            [
              105.83211023557108,
              21.016941723440766
            ],
            [
              105.83205802430932,
              21.01699274818115
            ],
            [
              105.83205625814067,
              21.016994475656418
            ],
            [
              105.83201758499501,
              21.017032271473397
            ],
            [
              105.83201880760073,
              21.01703313349301
            ],
            [
              105.83201902776523,
              21.01703328506124
            ],
            [
              105.83202005801222,
              21.01703467279527
            ],
            [
              105.83202057106706,
              21.01703629428435
            ],
            [
              105.83202051503598,
              21.01703798539536
            ],
            [
              105.83201989473855,
              21.01703957449199
            ],
            [
              105.83201885305486,
              21.017040807069257
            ],
            [
              105.83201877468846,
              21.017040899581463
            ],
            [
              105.83201811895475,
              21.017041302917132
            ],
            [
              105.83201726765071,
              21.017041826434976
            ],
            [
              105.83201552605458,
              21.01704226127488
            ],
            [
              105.83201372761471,
              21.01704215987718
            ],
            [
              105.83201205419414,
              21.01704153219116
            ],
            [
              105.83201125904677,
              21.01704090743551
            ],
            [
              105.83201067641858,
              21.017040443317736
            ],
            [
              105.83200973244884,
              21.017039001871417
            ],
            [
              105.83197910493017,
              21.017069501822164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.14_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4879",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016776263100322,
          "to_latitude": 21.017069501822164,
          "to_longitude": 105.83197910493017,
          "from_longitude": 105.8322919995093
        }
      },
      {
        "id": 4873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83200286845997,
            21.017030240740564,
            105.83234916099333,
            21.0173756343918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200286845997,
              21.017030240740564
            ],
            [
              105.83200507622746,
              21.017032513295547
            ],
            [
              105.83201373633051,
              21.01703835545138
            ],
            [
              105.83201811895475,
              21.017041302917132
            ],
            [
              105.83202377325796,
              21.017045114871998
            ],
            [
              105.83210053720242,
              21.017112331345153
            ],
            [
              105.83217673146109,
              21.01718175082363
            ],
            [
              105.83223711241035,
              21.017236274868832
            ],
            [
              105.8322622040538,
              21.017261101894913
            ],
            [
              105.83229762877792,
              21.017313633557894
            ],
            [
              105.83230815793527,
              21.01732674925497
            ],
            [
              105.83232777781183,
              21.017351187557754
            ],
            [
              105.83234916099333,
              21.0173756343918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.12_Ngõ 266, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4880",
          "diaChiLapD": "Ngõ 266, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017030240740564,
          "to_latitude": 21.0173756343918,
          "to_longitude": 105.83234916099333,
          "from_longitude": 105.83200286845997
        }
      },
      {
        "id": 4874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83230815793527,
            21.01727000269362,
            105.8323771301853,
            21.01732674925497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83230815793527,
              21.01732674925497
            ],
            [
              105.8323771301853,
              21.01727000269362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.12_Ngõ 266, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4881",
          "diaChiLapD": "Ngõ 266, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01732674925497,
          "to_latitude": 21.01727000269362,
          "to_longitude": 105.8323771301853,
          "from_longitude": 105.83230815793527
        }
      },
      {
        "id": 4875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225857135972,
            21.016994008551215,
            105.83235484545457,
            21.01714194161814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227320643107,
              21.01714194161814
            ],
            [
              105.83228197421965,
              21.017124889193415
            ],
            [
              105.83225857135972,
              21.01710813505996
            ],
            [
              105.83235484545457,
              21.016994008551215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.13_Ngõ 256, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4882",
          "diaChiLapD": "Ngõ 256, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01714194161814,
          "to_latitude": 21.016994008551215,
          "to_longitude": 105.83235484545457,
          "from_longitude": 105.83227320643107
        }
      },
      {
        "id": 4876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83176590835802,
            21.017264915629276,
            105.83200796073791,
            21.01745139590889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176590835802,
              21.017264915629276
            ],
            [
              105.83176737343385,
              21.01726561478787
            ],
            [
              105.83178121777742,
              21.01727220476811
            ],
            [
              105.83178429798302,
              21.017274641963194
            ],
            [
              105.83200796073791,
              21.01745139590889
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.11_Ngõ 278, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4883",
          "diaChiLapD": "Ngõ 278, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017264915629276,
          "to_latitude": 21.01745139590889,
          "to_longitude": 105.83200796073791,
          "from_longitude": 105.83176590835802
        }
      },
      {
        "id": 4877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83178121777742,
            21.017125995313325,
            105.83192601872189,
            21.01727220476811
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178121777742,
              21.01727220476811
            ],
            [
              105.83181895336665,
              21.017234101974203
            ],
            [
              105.83192601872189,
              21.017125995313325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.11_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4884",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01727220476811,
          "to_latitude": 21.017125995313325,
          "to_longitude": 105.83192601872189,
          "from_longitude": 105.83178121777742
        }
      },
      {
        "id": 4878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83181895336665,
            21.017234101974203,
            105.83189280328648,
            21.017299877692825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83181895336665,
              21.017234101974203
            ],
            [
              105.83182147749649,
              21.017236353113645
            ],
            [
              105.83189280328648,
              21.017299877692825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.11_Ngõ 276, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4885",
          "diaChiLapD": "Ngõ 276, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017234101974203,
          "to_latitude": 21.017299877692825,
          "to_longitude": 105.83189280328648,
          "from_longitude": 105.83181895336665
        }
      },
      {
        "id": 4879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162365105488,
            21.017412436646698,
            105.83183393510129,
            21.01758850744682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83162365105488,
              21.017412436646698
            ],
            [
              105.83162452246077,
              21.017412762063902
            ],
            [
              105.83163290482769,
              21.017415875147513
            ],
            [
              105.83163494046804,
              21.017417464807394
            ],
            [
              105.83164100971902,
              21.017422205971044
            ],
            [
              105.83179170493669,
              21.01753983863521
            ],
            [
              105.83178706882107,
              21.017551199485354
            ],
            [
              105.83183393510129,
              21.01758850744682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.10_Ngõ 286, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4886",
          "diaChiLapD": "Ngõ 286, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017412436646698,
          "to_latitude": 21.01758850744682,
          "to_longitude": 105.83183393510129,
          "from_longitude": 105.83162365105488
        }
      },
      {
        "id": 4880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163290482769,
            21.017279811040304,
            105.83176990512986,
            21.017415875147513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83163290482769,
              21.017415875147513
            ],
            [
              105.83176990512986,
              21.017279811040304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.10_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4887",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017415875147513,
          "to_latitude": 21.017279811040304,
          "to_longitude": 105.83176990512986,
          "from_longitude": 105.83163290482769
        }
      },
      {
        "id": 4881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8315654704083,
            21.017417464807394,
            105.83163494046804,
            21.01749344678127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83163494046804,
              21.017417464807394
            ],
            [
              105.8315654704083,
              21.01749344678127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.10_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4888",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017417464807394,
          "to_latitude": 21.01749344678127,
          "to_longitude": 105.8315654704083,
          "from_longitude": 105.83163494046804
        }
      },
      {
        "id": 4882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154786247829,
            21.01748767847425,
            105.83179414041628,
            21.01766210002889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83155143028247,
              21.017492319541965
            ],
            [
              105.83154828573358,
              21.017489381367444
            ],
            [
              105.83154786247829,
              21.01748903479154
            ],
            [
              105.83154908913903,
              21.01748767847425
            ],
            [
              105.83155254505928,
              21.017491082997257
            ],
            [
              105.83155845968898,
              21.01749691059596
            ],
            [
              105.83156357346505,
              21.017501951787374
            ],
            [
              105.83156703033278,
              21.017505353595745
            ],
            [
              105.83165275699967,
              21.017576074321816
            ],
            [
              105.83174037008554,
              21.01763447121086
            ],
            [
              105.83174048380297,
              21.01763849813451
            ],
            [
              105.83177693215946,
              21.01766210002889
            ],
            [
              105.83179414041628,
              21.01763867204225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.9_Ngõ 290, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4889",
          "diaChiLapD": "Ngõ 290, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017492319541965,
          "to_latitude": 21.01763867204225,
          "to_longitude": 105.83179414041628,
          "from_longitude": 105.83155143028247
        }
      },
      {
        "id": 4883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135487844842,
            21.01749691059596,
            105.83155845968898,
            21.01772440461947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83155845968898,
              21.01749691059596
            ],
            [
              105.83155163366295,
              21.017504538277606
            ],
            [
              105.83146418973502,
              21.017602253854637
            ],
            [
              105.83144077851917,
              21.01762841186568
            ],
            [
              105.83135487844842,
              21.01772440461947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.9_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4890",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01749691059596,
          "to_latitude": 21.01772440461947,
          "to_longitude": 105.83135487844842,
          "from_longitude": 105.83155845968898
        }
      },
      {
        "id": 4884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831322684489,
            21.01774481857409,
            105.83147084204153,
            21.01786416288329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831322684489,
              21.01774481857409
            ],
            [
              105.8313231888008,
              21.017745384238093
            ],
            [
              105.83132438228228,
              21.017748280467416
            ],
            [
              105.83133121040957,
              21.017764803212078
            ],
            [
              105.83147084204153,
              21.01786416288329
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.8_Ngõ 304, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4891",
          "diaChiLapD": "Ngõ 304, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01774481857409,
          "to_latitude": 21.01786416288329,
          "to_longitude": 105.83147084204153,
          "from_longitude": 105.831322684489
        }
      },
      {
        "id": 4885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83113754819212,
            21.017764803212078,
            105.83133121040957,
            21.017984325425676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133121040957,
              21.017764803212078
            ],
            [
              105.83122646175295,
              21.017883539991747
            ],
            [
              105.83122762050652,
              21.017884856650998
            ],
            [
              105.83122845619245,
              21.01788637359837
            ],
            [
              105.83122893283908,
              21.017888022364502
            ],
            [
              105.83122902790655,
              21.01788972809184
            ],
            [
              105.83122873808357,
              21.01789141402249
            ],
            [
              105.83122807545978,
              21.01789300512968
            ],
            [
              105.83122706944926,
              21.017894428108463
            ],
            [
              105.83122670766852,
              21.017894759553336
            ],
            [
              105.83122576780298,
              21.017895620403024
            ],
            [
              105.83122422601221,
              21.01789652754866
            ],
            [
              105.83122251600116,
              21.01789710945208
            ],
            [
              105.83122071361184,
              21.017897338645906
            ],
            [
              105.83121889959588,
              21.01789720570333
            ],
            [
              105.83121715671403,
              21.017896716542555
            ],
            [
              105.83121556300738,
              21.017895893362827
            ],
            [
              105.83121551741564,
              21.0178959983597
            ],
            [
              105.83113754819212,
              21.017984325425676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.8_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4892",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017764803212078,
          "to_latitude": 21.017984325425676,
          "to_longitude": 105.83113754819212,
          "from_longitude": 105.83133121040957
        }
      },
      {
        "id": 4886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8311977510312,
            21.01787046609749,
            105.83152162728699,
            21.018204567712996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311977510312,
              21.01787046609749
            ],
            [
              105.83119936638207,
              21.017872070461188
            ],
            [
              105.83120181456114,
              21.01787450262572
            ],
            [
              105.83120522706119,
              21.017877882980674
            ],
            [
              105.83122670766852,
              21.017894759553336
            ],
            [
              105.83124388306165,
              21.017908251098998
            ],
            [
              105.83144611730083,
              21.018126574813355
            ],
            [
              105.83150474236542,
              21.018189865359634
            ],
            [
              105.83152162728699,
              21.018204567712996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.7_Ngõ 314, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4893",
          "diaChiLapD": "Ngõ 314, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01787046609749,
          "to_latitude": 21.018204567712996,
          "to_longitude": 105.83152162728699,
          "from_longitude": 105.8311977510312
        }
      },
      {
        "id": 4887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83144611730083,
            21.01789434834079,
            105.83174916439332,
            21.018126574813355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144611730083,
              21.018126574813355
            ],
            [
              105.83152823119217,
              21.01805658991224
            ],
            [
              105.83156483669333,
              21.0180209070147
            ],
            [
              105.83161591857204,
              21.017979321218228
            ],
            [
              105.83162856492645,
              21.017973345078534
            ],
            [
              105.8316299985844,
              21.017972193683967
            ],
            [
              105.83165579669719,
              21.01795146319824
            ],
            [
              105.83170107151413,
              21.017911490926405
            ],
            [
              105.83172994851029,
              21.01789434834079
            ],
            [
              105.83174916439332,
              21.017910641596547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.7_Ngõ 314, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4894",
          "diaChiLapD": "Ngõ 314, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018126574813355,
          "to_latitude": 21.017910641596547,
          "to_longitude": 105.83174916439332,
          "from_longitude": 105.83144611730083
        }
      },
      {
        "id": 4888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142794062499,
            21.018146548333767,
            105.83160233766672,
            21.018308493655102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83142794062499,
              21.018308493655102
            ],
            [
              105.83153187225054,
              21.01821517930041
            ],
            [
              105.8315346076073,
              21.01820503716624
            ],
            [
              105.83160233766672,
              21.018146548333767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.1_Ngách 316/22, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4895",
          "diaChiLapD": "Ngách 316/22, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018308493655102,
          "to_latitude": 21.018146548333767,
          "to_longitude": 105.83160233766672,
          "from_longitude": 105.83142794062499
        }
      },
      {
        "id": 4889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83136451375513,
            21.01825626630594,
            105.83160734177923,
            21.018501148900075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83136451375513,
              21.018267293237518
            ],
            [
              105.83136479929821,
              21.0182670913261
            ],
            [
              105.83138011227888,
              21.01825626630594
            ],
            [
              105.83142794062499,
              21.018308493655102
            ],
            [
              105.83151302109866,
              21.01840139176162
            ],
            [
              105.83152651178513,
              21.0184156599955
            ],
            [
              105.83160734177923,
              21.018501148900075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.1_Ngõ 316, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4896",
          "diaChiLapD": "Ngõ 316, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018267293237518,
          "to_latitude": 21.018501148900075,
          "to_longitude": 105.83160734177923,
          "from_longitude": 105.83136451375513
        }
      },
      {
        "id": 4890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83112473400148,
            21.01799091744559,
            105.83138011227888,
            21.01825626630594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83138011227888,
              21.01825626630594
            ],
            [
              105.83136598802517,
              21.018240843221445
            ],
            [
              105.83136577223848,
              21.018240613953704
            ],
            [
              105.83112473400148,
              21.01799091744559
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.1_Ngõ 316, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4897",
          "diaChiLapD": "Ngõ 316, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01825626630594,
          "to_latitude": 21.01799091744559,
          "to_longitude": 105.83112473400148,
          "from_longitude": 105.83138011227888
        }
      },
      {
        "id": 4891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118768536231,
            21.018091109901498,
            105.83139000929171,
            21.018301113630955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83118768536231,
              21.018091109901498
            ],
            [
              105.83124933515768,
              21.01814979918435
            ],
            [
              105.83129980841643,
              21.018202825647286
            ],
            [
              105.83135930908145,
              21.01826772331832
            ],
            [
              105.83136231605172,
              21.01827100268386
            ],
            [
              105.83139000929171,
              21.018301113630955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.2_Ngõ 316, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4898",
          "diaChiLapD": "Ngõ 316, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018091109901498,
          "to_latitude": 21.018301113630955,
          "to_longitude": 105.83139000929171,
          "from_longitude": 105.83118768536231
        }
      },
      {
        "id": 4892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83139000929171,
            21.018301113630955,
            105.8316009706609,
            21.018523338859975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83139000929171,
              21.018301113630955
            ],
            [
              105.83142116239712,
              21.01833498856221
            ],
            [
              105.83146360473268,
              21.018378875652363
            ],
            [
              105.83151045262812,
              21.018426540159986
            ],
            [
              105.8316009706609,
              21.018523338859975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.2_Ngõ 316, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4899",
          "diaChiLapD": "Ngõ 316, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018301113630955,
          "to_latitude": 21.018523338859975,
          "to_longitude": 105.8316009706609,
          "from_longitude": 105.83139000929171
        }
      },
      {
        "id": 4893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83146286703739,
            21.01866221698573,
            105.83178532247155,
            21.018846330612106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83146286703739,
              21.018846330612106
            ],
            [
              105.83178532247155,
              21.01866221698573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.4_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4900",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018846330612106,
          "to_latitude": 21.01866221698573,
          "to_longitude": 105.83178532247155,
          "from_longitude": 105.83146286703739
        }
      },
      {
        "id": 4894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83127555278408,
            21.01868998379454,
            105.83137613068116,
            21.01873339359193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83137613068116,
              21.01872287003748
            ],
            [
              105.8313704025477,
              21.01872570435215
            ],
            [
              105.83135486050597,
              21.01873339359193
            ],
            [
              105.83127555278408,
              21.01868998379454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.3_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4901",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01872287003748,
          "to_latitude": 21.01868998379454,
          "to_longitude": 105.83127555278408,
          "from_longitude": 105.83137613068116
        }
      },
      {
        "id": 4895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83146044188118,
            21.01855395775978,
            105.83152678095068,
            21.01861063691564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83152678095068,
              21.01861063691564
            ],
            [
              105.83152541137027,
              21.018609466708504
            ],
            [
              105.83152079809798,
              21.01860552500533
            ],
            [
              105.83146044188118,
              21.01855395775978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.3_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4902",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01861063691564,
          "to_latitude": 21.01855395775978,
          "to_longitude": 105.83146044188118,
          "from_longitude": 105.83152678095068
        }
      },
      {
        "id": 4896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83114265986646,
            21.018507314401397,
            105.83130088392132,
            21.018855136592407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8312979783199,
              21.018507314401397
            ],
            [
              105.83130088392132,
              21.018596431332803
            ],
            [
              105.83125346951809,
              21.01866054812687
            ],
            [
              105.83125097315553,
              21.018662268323727
            ],
            [
              105.83124927026276,
              21.01866344180509
            ],
            [
              105.83124266184483,
              21.018667994737783
            ],
            [
              105.83120062784151,
              21.01875384662234
            ],
            [
              105.83116798270137,
              21.01881352594053
            ],
            [
              105.83115502548328,
              21.018826780849338
            ],
            [
              105.83115248643117,
              21.018832601894175
            ],
            [
              105.83114265986646,
              21.018855136592407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3.5_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4903",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018507314401397,
          "to_latitude": 21.018855136592407,
          "to_longitude": 105.83114265986646,
          "from_longitude": 105.8312979783199
        }
      },
      {
        "id": 4897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310194003975,
            21.018855136592407,
            105.83114889632188,
            21.019130993852613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114889632188,
              21.01885810211787
            ],
            [
              105.83114471682805,
              21.018856057766925
            ],
            [
              105.83114265986646,
              21.018855136592407
            ],
            [
              105.83110281358773,
              21.018939834282182
            ],
            [
              105.8310194003975,
              21.019130993852613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3.5_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4904",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01885810211787,
          "to_latitude": 21.019130993852613,
          "to_longitude": 105.8310194003975,
          "from_longitude": 105.83114889632188
        }
      },
      {
        "id": 4898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091296447732,
            21.018506496625918,
            105.83115374782149,
            21.018854798661447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311508192449,
              21.018854798661447
            ],
            [
              105.8311462018743,
              21.018853241481757
            ],
            [
              105.83115374782149,
              21.018833170181985
            ],
            [
              105.83115248643117,
              21.018832601894175
            ],
            [
              105.8310994647134,
              21.018808725880646
            ],
            [
              105.83109045080963,
              21.01880466837575
            ],
            [
              105.83100331191352,
              21.018765440706577
            ],
            [
              105.83100248352753,
              21.018765068108596
            ],
            [
              105.8310183195671,
              21.01872710317299
            ],
            [
              105.83099976717838,
              21.018627438939124
            ],
            [
              105.83093877204364,
              21.018527717382707
            ],
            [
              105.83091296447732,
              21.018506496625918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3.6_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4905",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018854798661447,
          "to_latitude": 21.018506496625918,
          "to_longitude": 105.83091296447732,
          "from_longitude": 105.8311508192449
        }
      },
      {
        "id": 4899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089467356318,
            21.018765440706577,
            105.83100331191352,
            21.018886687391436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100331191352,
              21.018765440706577
            ],
            [
              105.83099241529877,
              21.018783401274067
            ],
            [
              105.83098087856925,
              21.01880666960752
            ],
            [
              105.83094139755664,
              21.018789592808805
            ],
            [
              105.83090931722884,
              21.018858520150427
            ],
            [
              105.83089467356318,
              21.018886687391436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.6_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4906",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018765440706577,
          "to_latitude": 21.018886687391436,
          "to_longitude": 105.83089467356318,
          "from_longitude": 105.83100331191352
        }
      },
      {
        "id": 4900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310994647134,
            21.018687860991626,
            105.83115896146529,
            21.018808725880646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310994647134,
              21.018808725880646
            ],
            [
              105.83113747606393,
              21.018736965917157
            ],
            [
              105.83115896146529,
              21.018687860991626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.6_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4907",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018808725880646,
          "to_latitude": 21.018687860991626,
          "to_longitude": 105.83115896146529,
          "from_longitude": 105.8310994647134
        }
      },
      {
        "id": 4901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83101992656853,
            21.01880466837575,
            105.83109045080963,
            21.01898464718107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109045080963,
              21.01880466837575
            ],
            [
              105.83107167056899,
              21.018842521250054
            ],
            [
              105.83105346883345,
              21.018875408075594
            ],
            [
              105.83106693055993,
              21.01888392926697
            ],
            [
              105.83101992656853,
              21.01898464718107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.6_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4908",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01880466837575,
          "to_latitude": 21.01898464718107,
          "to_longitude": 105.83101992656853,
          "from_longitude": 105.83109045080963
        }
      },
      {
        "id": 4902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110745164919,
            21.018934189047062,
            105.83134061738427,
            21.01899827155404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83110745164919,
              21.018934189047062
            ],
            [
              105.83110814532581,
              21.018934555076868
            ],
            [
              105.83122916559256,
              21.01899827155404
            ],
            [
              105.83128759858509,
              21.018965082434068
            ],
            [
              105.83134061738427,
              21.01893496904162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3.7_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4909",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018934189047062,
          "to_latitude": 21.01893496904162,
          "to_longitude": 105.83134061738427,
          "from_longitude": 105.83110745164919
        }
      },
      {
        "id": 4903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83119484853341,
            21.01887588518175,
            105.83128759858509,
            21.018965082434068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128759858509,
              21.018965082434068
            ],
            [
              105.8312688651403,
              21.01893154059851
            ],
            [
              105.83124649341477,
              21.01889953124741
            ],
            [
              105.83119484853341,
              21.01887588518175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.7_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.4910",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018965082434068,
          "to_latitude": 21.01887588518175,
          "to_longitude": 105.83119484853341,
          "from_longitude": 105.83128759858509
        }
      },
      {
        "id": 4904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83060090218329,
            21.018434243405903,
            105.83089121852052,
            21.01856864925174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83089121852052,
              21.018542093260617
            ],
            [
              105.83084001008896,
              21.01849961180642
            ],
            [
              105.83081261939064,
              21.018521322724368
            ],
            [
              105.83079224631155,
              21.018499128826996
            ],
            [
              105.83072773433395,
              21.01855811285787
            ],
            [
              105.83071621074487,
              21.01856864925174
            ],
            [
              105.83068870173439,
              21.018543476127768
            ],
            [
              105.83068733733103,
              21.018536118797485
            ],
            [
              105.83061325524143,
              21.018459708226633
            ],
            [
              105.83060215940245,
              21.01843683855519
            ],
            [
              105.83060090218329,
              21.018434243405903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.4_Ngõ 350, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4911",
          "diaChiLapD": "Ngõ 350, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018542093260617,
          "to_latitude": 21.018434243405903,
          "to_longitude": 105.83060090218329,
          "from_longitude": 105.83089121852052
        }
      },
      {
        "id": 4905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83062942786098,
            21.01831309762189,
            105.83078786624701,
            21.01844724333435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83062942786098,
              21.01844724333435
            ],
            [
              105.83078786624701,
              21.01831309762189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.5_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4912",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01844724333435,
          "to_latitude": 21.01831309762189,
          "to_longitude": 105.83078786624701,
          "from_longitude": 105.83062942786098
        }
      },
      {
        "id": 4906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83078786624701,
            21.018039095388,
            105.83109738813687,
            21.01831309762189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109738813687,
              21.018039095388
            ],
            [
              105.83096558311948,
              21.0181520878164
            ],
            [
              105.83094477695161,
              21.01817134594704
            ],
            [
              105.83094269633507,
              21.018173271850298
            ],
            [
              105.8309103654102,
              21.01820319645463
            ],
            [
              105.83078786624701,
              21.01831309762189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.5_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4913",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018039095388,
          "to_latitude": 21.01831309762189,
          "to_longitude": 105.83078786624701,
          "from_longitude": 105.83109738813687
        }
      },
      {
        "id": 4907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83072773433395,
            21.01855811285787,
            105.83079332968396,
            21.018598146794588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83072773433395,
              21.01855811285787
            ],
            [
              105.83076157341779,
              21.018582314804085
            ],
            [
              105.83076683419347,
              21.018585063819007
            ],
            [
              105.83079332968396,
              21.018598146794588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.4_Ngõ 350, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4914",
          "diaChiLapD": "Ngõ 350, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01855811285787,
          "to_latitude": 21.018598146794588,
          "to_longitude": 105.83079332968396,
          "from_longitude": 105.83072773433395
        }
      },
      {
        "id": 4908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091957661362,
            21.018149461965116,
            105.83117382237671,
            21.018488968194596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83091957661362,
              21.018149461965116
            ],
            [
              105.83092112705634,
              21.018150810132546
            ],
            [
              105.83092497899253,
              21.01815415854016
            ],
            [
              105.83094477695161,
              21.01817134594704
            ],
            [
              105.83095337392598,
              21.01817881065577
            ],
            [
              105.8309743085207,
              21.018200613405547
            ],
            [
              105.83101860579134,
              21.01825499876661
            ],
            [
              105.83105337843638,
              21.018297690528897
            ],
            [
              105.83111897599547,
              21.018378226741817
            ],
            [
              105.83113957720309,
              21.018376487638264
            ],
            [
              105.83117382237671,
              21.01842258168915
            ],
            [
              105.83112503503598,
              21.018460447506502
            ],
            [
              105.83113751189667,
              21.018488968194596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.6_Ngõ 324, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4915",
          "diaChiLapD": "Ngõ 324, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018149461965116,
          "to_latitude": 21.018488968194596,
          "to_longitude": 105.83113751189667,
          "from_longitude": 105.83091957661362
        }
      },
      {
        "id": 4909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098024673824,
            21.018297690528897,
            105.83105337843638,
            21.018346461546074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83105337843638,
              21.018297690528897
            ],
            [
              105.83098024673824,
              21.018346461546074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.6_Ngõ 324, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4916",
          "diaChiLapD": "Ngõ 324, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018297690528897,
          "to_latitude": 21.018346461546074,
          "to_longitude": 105.83098024673824,
          "from_longitude": 105.83105337843638
        }
      },
      {
        "id": 4910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026783802359,
            21.018587844515647,
            105.83042856707053,
            21.01871580225361
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83042856707053,
              21.018587844515647
            ],
            [
              105.83033376688881,
              21.01865380554233
            ],
            [
              105.83026783802359,
              21.01871580225361
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.3_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4917",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018587844515647,
          "to_latitude": 21.01871580225361,
          "to_longitude": 105.83026783802359,
          "from_longitude": 105.83042856707053
        }
      },
      {
        "id": 4911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83042856707053,
            21.018462639921637,
            105.8305971972015,
            21.018587844515647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8305971972015,
              21.018462639921637
            ],
            [
              105.83052256900018,
              21.018516023598714
            ],
            [
              105.83050177771602,
              21.018531908962668
            ],
            [
              105.83042856707053,
              21.018587844515647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.3_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4918",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018462639921637,
          "to_latitude": 21.018587844515647,
          "to_longitude": 105.83042856707053,
          "from_longitude": 105.8305971972015
        }
      },
      {
        "id": 4912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83047689567154,
            21.018615617506935,
            105.83083053095885,
            21.018927300891104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83050644723431,
              21.018927300891104
            ],
            [
              105.8305075035619,
              21.018925060252133
            ],
            [
              105.8305113542972,
              21.01891689887898
            ],
            [
              105.83047689567154,
              21.01889252287746
            ],
            [
              105.8305351258012,
              21.018817120075077
            ],
            [
              105.83061112518642,
              21.018694307825967
            ],
            [
              105.83067355514409,
              21.018615617506935
            ],
            [
              105.8307278987516,
              21.018648056097128
            ],
            [
              105.83074078290008,
              21.0186311570642
            ],
            [
              105.83079935780468,
              21.01867394760192
            ],
            [
              105.83083053095885,
              21.01863661138849
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.1_Ngõ 295, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4919",
          "diaChiLapD": "Ngõ 295, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018927300891104,
          "to_latitude": 21.01863661138849,
          "to_longitude": 105.83083053095885,
          "from_longitude": 105.83050644723431
        }
      },
      {
        "id": 4913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83061112518642,
            21.018694307825967,
            105.83071508389962,
            21.01875005754646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83061112518642,
              21.018694307825967
            ],
            [
              105.83071508389962,
              21.01875005754646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.1_Ngõ 295, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4920",
          "diaChiLapD": "Ngõ 295, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018694307825967,
          "to_latitude": 21.01875005754646,
          "to_longitude": 105.83071508389962,
          "from_longitude": 105.83061112518642
        }
      },
      {
        "id": 4914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83051989317194,
            21.018914562998244,
            105.8306767133572,
            21.019011040755444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83051989317194,
              21.018914562998244
            ],
            [
              105.83056545176748,
              21.01894031369611
            ],
            [
              105.8305875627323,
              21.018950971273476
            ],
            [
              105.83064641877839,
              21.01897972346895
            ],
            [
              105.8306767133572,
              21.018994241197973
            ],
            [
              105.83066833900179,
              21.019007696788233
            ],
            [
              105.83066625756736,
              21.019011040755444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.1_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4921",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018914562998244,
          "to_latitude": 21.019011040755444,
          "to_longitude": 105.83066625756736,
          "from_longitude": 105.83051989317194
        }
      },
      {
        "id": 4915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306767133572,
            21.018994241197973,
            105.8310111314934,
            21.0191356994048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8306767133572,
              21.018994241197973
            ],
            [
              105.83068619651127,
              21.01899878598111
            ],
            [
              105.83072098194525,
              21.019015463962965
            ],
            [
              105.83076479066057,
              21.01903476418972
            ],
            [
              105.83082235644066,
              21.019059634240453
            ],
            [
              105.83089104279237,
              21.019085774080178
            ],
            [
              105.83095033849688,
              21.019110174088492
            ],
            [
              105.8310111314934,
              21.0191356994048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.1_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4922",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018994241197973,
          "to_latitude": 21.0191356994048,
          "to_longitude": 105.8310111314934,
          "from_longitude": 105.8306767133572
        }
      },
      {
        "id": 4916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83121721131928,
            21.018922179909556,
            105.83165759255833,
            21.01925235561984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83121721131928,
              21.01925235561984
            ],
            [
              105.83122057782091,
              21.019248472464124
            ],
            [
              105.83122715121692,
              21.01924089389995
            ],
            [
              105.83124393453538,
              21.019225447129852
            ],
            [
              105.8312923023581,
              21.019180930171952
            ],
            [
              105.8314161273636,
              21.019086811434573
            ],
            [
              105.83150133467295,
              21.019016861999926
            ],
            [
              105.83156140982159,
              21.01897259664426
            ],
            [
              105.83165759255833,
              21.018922179909556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.2_Ngõ 261, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4923",
          "diaChiLapD": "Ngõ 261, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01925235561984,
          "to_latitude": 21.018922179909556,
          "to_longitude": 105.83165759255833,
          "from_longitude": 105.83121721131928
        }
      },
      {
        "id": 4917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83103836008473,
            21.01914547837311,
            105.83124894862007,
            21.01923633061254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83103836008473,
              21.01914547837311
            ],
            [
              105.8311744604705,
              21.01920872559766
            ],
            [
              105.83122679724421,
              21.01922822970852
            ],
            [
              105.83122800057984,
              21.019226827442836
            ],
            [
              105.83122942432784,
              21.019225621003102
            ],
            [
              105.83123103306183,
              21.019224640368833
            ],
            [
              105.8312327855538,
              21.01922391012855
            ],
            [
              105.83123463957877,
              21.019223448553078
            ],
            [
              105.83123654709509,
              21.019223265812652
            ],
            [
              105.83123846196501,
              21.0192233684553
            ],
            [
              105.83124033510987,
              21.019223753107976
            ],
            [
              105.83124212126363,
              21.019224410056367
            ],
            [
              105.83124377416347,
              21.01922532326852
            ],
            [
              105.83124393453538,
              21.019225447129852
            ],
            [
              105.83124525327783,
              21.019226469458655
            ],
            [
              105.83124652189316,
              21.019227819902977
            ],
            [
              105.8312475480803,
              21.019229341338235
            ],
            [
              105.83124830565183,
              21.019230995053704
            ],
            [
              105.8312487761056,
              21.01923274049469
            ],
            [
              105.83124894862007,
              21.01923453435932
            ],
            [
              105.83124881716834,
              21.01923633061254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4924",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01914547837311,
          "to_latitude": 21.01923633061254,
          "to_longitude": 105.83124881716834,
          "from_longitude": 105.83103836008473
        }
      },
      {
        "id": 4918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124881716834,
            21.01923633061254,
            105.83147682746932,
            21.019329124458825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124881716834,
              21.01923633061254
            ],
            [
              105.8313395299717,
              21.019270505199724
            ],
            [
              105.83140361994596,
              21.019292771681595
            ],
            [
              105.83143311924708,
              21.019300546069662
            ],
            [
              105.83147682746932,
              21.019310192029412
            ],
            [
              105.83147528522815,
              21.01931952175041
            ],
            [
              105.83147369928824,
              21.019329124458825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4925",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01923633061254,
          "to_latitude": 21.019329124458825,
          "to_longitude": 105.83147369928824,
          "from_longitude": 105.83124881716834
        }
      },
      {
        "id": 4919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169327817467,
            21.01903270593709,
            105.83176062814438,
            21.019360689031842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317200651549,
              21.019360689031842
            ],
            [
              105.83172235399027,
              21.019343577087646
            ],
            [
              105.83172305588198,
              21.019333108894507
            ],
            [
              105.8317255550285,
              21.019295812462993
            ],
            [
              105.83172980079303,
              21.019245973442093
            ],
            [
              105.83173022855922,
              21.01922857343641
            ],
            [
              105.83173127283571,
              21.019210287062513
            ],
            [
              105.83172392743835,
              21.019193832804355
            ],
            [
              105.83171699239917,
              21.019166032037063
            ],
            [
              105.83171610420474,
              21.019150539753504
            ],
            [
              105.8317150487624,
              21.019132122742803
            ],
            [
              105.83169327817467,
              21.019051378832245
            ],
            [
              105.83176062814438,
              21.01903270593709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.4_Ngõ 251, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4926",
          "diaChiLapD": "Ngõ 251, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019360689031842,
          "to_latitude": 21.01903270593709,
          "to_longitude": 105.83176062814438,
          "from_longitude": 105.8317200651549
        }
      },
      {
        "id": 4920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162799386719,
            21.019150539753504,
            105.83171610420474,
            21.019243411975076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171610420474,
              21.019150539753504
            ],
            [
              105.83164325749455,
              21.01916053151646
            ],
            [
              105.83162956339618,
              21.019182106956386
            ],
            [
              105.83162799386719,
              21.019243411975076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.4_Ngõ 251, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4927",
          "diaChiLapD": "Ngõ 251, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019150539753504,
          "to_latitude": 21.019243411975076,
          "to_longitude": 105.83162799386719,
          "from_longitude": 105.83171610420474
        }
      },
      {
        "id": 4921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83147682746932,
            21.019310192029412,
            105.83198608506224,
            21.019343399918284
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.83147682746932,
                21.019310192029412
              ],
              [
                105.83150096094228,
                21.019314840379437
              ],
              [
                105.83154093325862,
                21.019321945748562
              ],
              [
                105.8316110291041,
                21.019328653648955
              ],
              [
                105.8317196231141,
                21.019336489287685
              ],
              [
                105.8317207061839,
                21.01933508399379
              ],
              [
                105.83172200182618,
                21.019333848369964
              ],
              [
                105.83172305588198,
                21.019333108894507
              ],
              [
                105.83172348229996,
                21.019332810551855
              ],
              [
                105.83172511310585,
                21.01933199419195
              ],
              [
                105.83172685587675,
                21.019331419348795
              ],
              [
                105.83172867123344,
                21.019331097053616
              ],
              [
                105.83172955126851,
                21.019331068363606
              ],
              [
                105.83173051690082,
                21.019331036545392
              ],
              [
                105.83173235152071,
                21.019331238929535
              ],
              [
                105.83173413273803,
                21.019331698993817
              ],
              [
                105.8317358200913,
                21.019332406097355
              ],
              [
                105.8317373740511,
                21.01933334417542
              ],
              [
                105.8317387589056,
                21.019334491725232
              ],
              [
                105.83173994372765,
                21.01933582270448
              ],
              [
                105.83174090045078,
                21.019337306540773
              ],
              [
                105.83174160772208,
                21.019338909016
              ],
              [
                105.83174204898326,
                21.019340593178907
              ]
            ],
            [
              [
                105.83174173331834,
                21.019340567625637
              ],
              [
                105.83174204898326,
                21.019340593178907
              ]
            ],
            [
              [
                105.83174204898326,
                21.019340593178907
              ],
              [
                105.83177632094214,
                21.019343399918284
              ],
              [
                105.83187773964849,
                21.01933926036281
              ],
              [
                105.83198608506224,
                21.019326732040444
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4928",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.83150096094228,
            21.019314840379437
          ],
          "to_latitude": [
            105.83177632094214,
            21.019343399918284
          ],
          "to_longitude": [
            105.83174204898326,
            21.019340593178907
          ],
          "from_longitude": [
            105.83147682746932,
            21.019310192029412
          ]
        }
      },
      {
        "id": 4922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197641862462,
            21.019172984547527,
            105.83200817668613,
            21.019322952380104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200817668613,
              21.019322952380104
            ],
            [
              105.83199509953774,
              21.01925042869324
            ],
            [
              105.8319816690091,
              21.019199862317816
            ],
            [
              105.83197641862462,
              21.019172984547527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4.1_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4929",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019322952380104,
          "to_latitude": 21.019172984547527,
          "to_longitude": 105.83197641862462,
          "from_longitude": 105.83200817668613
        }
      },
      {
        "id": 4923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319366197306,
            21.01902286364308,
            105.83197641862462,
            21.019172984547527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197641862462,
              21.019172984547527
            ],
            [
              105.83196542620614,
              21.019131523395135
            ],
            [
              105.83196121513264,
              21.019115640010916
            ],
            [
              105.8319366197306,
              21.01902286364308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4.1_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4930",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019172984547527,
          "to_latitude": 21.01902286364308,
          "to_longitude": 105.8319366197306,
          "from_longitude": 105.83197641862462
        }
      },
      {
        "id": 4924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190653806672,
            21.019131523395135,
            105.83196542620614,
            21.019155652917714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83196542620614,
              21.019131523395135
            ],
            [
              105.83190653806672,
              21.019155652917714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.1_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4931",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019131523395135,
          "to_latitude": 21.019155652917714,
          "to_longitude": 105.83190653806672,
          "from_longitude": 105.83196542620614
        }
      },
      {
        "id": 4925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197540329091,
            21.0190994615918,
            105.83220878148511,
            21.019193323674333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197540329091,
              21.019110824172646
            ],
            [
              105.83197568392993,
              21.01911077854206
            ],
            [
              105.83204391737553,
              21.0190994615918
            ],
            [
              105.83207135189468,
              21.01911271047812
            ],
            [
              105.83211971571191,
              21.019118147054165
            ],
            [
              105.83211980542289,
              21.019112470751647
            ],
            [
              105.83220878148511,
              21.019110843221114
            ],
            [
              105.8322075326744,
              21.019193323674333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4.2_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4932",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019110824172646,
          "to_latitude": 21.019193323674333,
          "to_longitude": 105.8322075326744,
          "from_longitude": 105.83197540329091
        }
      },
      {
        "id": 4926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83211971571191,
            21.019118147054165,
            105.83215700607293,
            21.019215945534125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83211971571191,
              21.019118147054165
            ],
            [
              105.83212203159904,
              21.019191735888256
            ],
            [
              105.83215700607293,
              21.019190611894476
            ],
            [
              105.83215663777086,
              21.019215945534125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.2_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4933",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019118147054165,
          "to_latitude": 21.019215945534125,
          "to_longitude": 105.83215663777086,
          "from_longitude": 105.83211971571191
        }
      },
      {
        "id": 4927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83203030311613,
            21.01901645997652,
            105.83205045305489,
            21.0190994615918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83204391737553,
              21.0190994615918
            ],
            [
              105.83203030311613,
              21.019043347608996
            ],
            [
              105.83205045305489,
              21.019038800668273
            ],
            [
              105.83204867453567,
              21.01901645997652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.2_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4934",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0190994615918,
          "to_latitude": 21.01901645997652,
          "to_longitude": 105.83204867453567,
          "from_longitude": 105.83204391737553
        }
      },
      {
        "id": 4928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83203967466181,
            21.019295635145742,
            105.8323501281366,
            21.01932321470219
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203967466181,
              21.01932321470219
            ],
            [
              105.83215324487988,
              21.019310936201155
            ],
            [
              105.83217947239618,
              21.019308100929763
            ],
            [
              105.83231878198849,
              21.019298635488568
            ],
            [
              105.8323501281366,
              21.019295635145742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.5_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4935",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01932321470219,
          "to_latitude": 21.019295635145742,
          "to_longitude": 105.8323501281366,
          "from_longitude": 105.83203967466181
        }
      },
      {
        "id": 4929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323501281366,
            21.019291611335486,
            105.83258997710197,
            21.019295635145742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323501281366,
              21.019295635145742
            ],
            [
              105.83238906424286,
              21.019291611335486
            ],
            [
              105.83252070118954,
              21.019292020293385
            ],
            [
              105.83258997710197,
              21.019292772326814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.5_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4936",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019295635145742,
          "to_latitude": 21.019292772326814,
          "to_longitude": 105.83258997710197,
          "from_longitude": 105.8323501281366
        }
      },
      {
        "id": 4930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217006635354,
            21.019020467539576,
            105.8323501281366,
            21.019295635145742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217006635354,
              21.019025877506834
            ],
            [
              105.8322366442862,
              21.019020467539576
            ],
            [
              105.83227387696762,
              21.019136787804413
            ],
            [
              105.83230457419303,
              21.019164717959654
            ],
            [
              105.8323501281366,
              21.019295635145742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.5_Ngõ 235, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4937",
          "diaChiLapD": "Ngõ 235, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019025877506834,
          "to_latitude": 21.019295635145742,
          "to_longitude": 105.8323501281366,
          "from_longitude": 105.83217006635354
        }
      },
      {
        "id": 4931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252592216627,
            21.01907690599584,
            105.83259642530066,
            21.019274611242455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83259642530066,
              21.019274611242455
            ],
            [
              105.83258573018199,
              21.019234231646816
            ],
            [
              105.83255532697534,
              21.019161893069835
            ],
            [
              105.83253420093212,
              21.019102030484195
            ],
            [
              105.83252592216627,
              21.01908051735393
            ],
            [
              105.83253672345174,
              21.019077125269167
            ],
            [
              105.83253741869285,
              21.01907690599584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.6_Ngõ 231, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4938",
          "diaChiLapD": "Ngõ 231, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019274611242455,
          "to_latitude": 21.01907690599584,
          "to_longitude": 105.83253741869285,
          "from_longitude": 105.83259642530066
        }
      },
      {
        "id": 4932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323297926851,
            21.01879376203836,
            105.83241491053842,
            21.01881698405581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241491053842,
              21.01879376203836
            ],
            [
              105.8323297926851,
              21.01881698405581
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5.6_Ngõ 231, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4939",
          "diaChiLapD": "Ngõ 231, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01879376203836,
          "to_latitude": 21.01881698405581,
          "to_longitude": 105.8323297926851,
          "from_longitude": 105.83241491053842
        }
      },
      {
        "id": 4933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83239415124164,
            21.01865815846008,
            105.8324449462467,
            21.01871243159835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83239415124164,
              21.01871243159835
            ],
            [
              105.83242971467857,
              21.018702014101112
            ],
            [
              105.8324193478035,
              21.018668416100674
            ],
            [
              105.8324449462467,
              21.01865815846008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5.6_Ngõ 231, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4940",
          "diaChiLapD": "Ngõ 231, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01871243159835,
          "to_latitude": 21.01865815846008,
          "to_longitude": 105.8324449462467,
          "from_longitude": 105.83239415124164
        }
      },
      {
        "id": 4934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83284907292175,
            21.0190072991928,
            105.83288411672025,
            21.019280761576344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328622404453,
              21.019280761576344
            ],
            [
              105.83284907292175,
              21.01910473700865
            ],
            [
              105.83288411672025,
              21.019090466093854
            ],
            [
              105.83286267547754,
              21.0190072991928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.6_Ngõ 223, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4941",
          "diaChiLapD": "Ngõ 223, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019280761576344,
          "to_latitude": 21.0190072991928,
          "to_longitude": 105.83286267547754,
          "from_longitude": 105.8328622404453
        }
      },
      {
        "id": 4935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326223850493,
            21.01928169482298,
            105.83285422521041,
            21.01930221136463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326223850493,
              21.01930221136463
            ],
            [
              105.83276179761425,
              21.01928981680356
            ],
            [
              105.8328531570266,
              21.01928169482298
            ],
            [
              105.83285364638648,
              21.019289439383545
            ],
            [
              105.83285422521041,
              21.01929856995569
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.6_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4942",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01930221136463,
          "to_latitude": 21.01929856995569,
          "to_longitude": 105.83285422521041,
          "from_longitude": 105.8326223850493
        }
      },
      {
        "id": 4936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328531570266,
            21.018945853487683,
            105.83317032558267,
            21.01928169482298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328531570266,
              21.01928169482298
            ],
            [
              105.8328622404453,
              21.019280761576344
            ],
            [
              105.83292571584661,
              21.01927599246203
            ],
            [
              105.83300636276482,
              21.019269933431133
            ],
            [
              105.83299124626046,
              21.01900266023247
            ],
            [
              105.83311647730442,
              21.019000653270044
            ],
            [
              105.83312132320097,
              21.018945853487683
            ],
            [
              105.83317032558267,
              21.018947107418356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.6_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4943",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01928169482298,
          "to_latitude": 21.018947107418356,
          "to_longitude": 105.83317032558267,
          "from_longitude": 105.8328531570266
        }
      },
      {
        "id": 4937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83291134714558,
            21.018987208495417,
            105.83296045929963,
            21.01927599246203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292571584661,
              21.01927599246203
            ],
            [
              105.83291134714558,
              21.019118050014836
            ],
            [
              105.83296045929963,
              21.019115849536423
            ],
            [
              105.83294482000957,
              21.018987208495417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.6_Ngõ 221C, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4944",
          "diaChiLapD": "Ngõ 221C, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01927599246203,
          "to_latitude": 21.018987208495417,
          "to_longitude": 105.83294482000957,
          "from_longitude": 105.83292571584661
        }
      },
      {
        "id": 4938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83271171915838,
            21.019067415304974,
            105.83276179761425,
            21.01928981680356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83276179761425,
              21.01928981680356
            ],
            [
              105.83271171915838,
              21.019067415304974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.6_Ngõ 227, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4945",
          "diaChiLapD": "Ngõ 227, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01928981680356,
          "to_latitude": 21.019067415304974,
          "to_longitude": 105.83271171915838,
          "from_longitude": 105.83276179761425
        }
      },
      {
        "id": 4939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335579320513,
            21.018968544217678,
            105.83360459606385,
            21.019226470627395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83360459606385,
              21.019226470627395
            ],
            [
              105.83359383081404,
              21.019107931827108
            ],
            [
              105.8335739784423,
              21.019108029111287
            ],
            [
              105.8335579320513,
              21.018968544217678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.7_Ngõ 219B, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4946",
          "diaChiLapD": "Ngõ 219B, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019226470627395,
          "to_latitude": 21.018968544217678,
          "to_longitude": 105.8335579320513,
          "from_longitude": 105.83360459606385
        }
      },
      {
        "id": 4940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385204183315,
            21.019163111254198,
            105.83443137697121,
            21.01921329914305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385204183315,
              21.01921329914305
            ],
            [
              105.83401482330203,
              21.019195238691097
            ],
            [
              105.8340784269077,
              21.01918914890928
            ],
            [
              105.83418844053078,
              21.019178614363582
            ],
            [
              105.83419928586035,
              21.019178131243542
            ],
            [
              105.83421553990645,
              21.0191774066306
            ],
            [
              105.83440581468228,
              21.019163258322244
            ],
            [
              105.83443137697121,
              21.019163111254198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.8_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4947",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01921329914305,
          "to_latitude": 21.019163111254198,
          "to_longitude": 105.83443137697121,
          "from_longitude": 105.83385204183315
        }
      },
      {
        "id": 4941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411096365528,
            21.01898807344462,
            105.8342109020359,
            21.019203126105815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342109020359,
              21.019203126105815
            ],
            [
              105.83420732305254,
              21.019195428315662
            ],
            [
              105.83419928586035,
              21.019178131243542
            ],
            [
              105.83411096365528,
              21.01898807344462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.8*_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4948",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019203126105815,
          "to_latitude": 21.01898807344462,
          "to_longitude": 105.83411096365528,
          "from_longitude": 105.8342109020359
        }
      },
      {
        "id": 4942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83482826106325,
            21.01892548465824,
            105.83491620116496,
            21.019152326915293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488753690361,
              21.01892548465824
            ],
            [
              105.83482826106325,
              21.018948121341026
            ],
            [
              105.83487086021232,
              21.01901236725436
            ],
            [
              105.83489151148342,
              21.019064541803736
            ],
            [
              105.83491620116496,
              21.019152326915293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.9_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4949",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01892548465824,
          "to_latitude": 21.019152326915293,
          "to_longitude": 105.83491620116496,
          "from_longitude": 105.83488753690361
        }
      },
      {
        "id": 4943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83448179909418,
            21.01915196004474,
            105.83492341093033,
            21.0191681360272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448179909418,
              21.0191681360272
            ],
            [
              105.83460827607742,
              21.019164421982744
            ],
            [
              105.83468558412056,
              21.01916462794917
            ],
            [
              105.83491620116496,
              21.019152326915293
            ],
            [
              105.83492341093033,
              21.01915196004474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.9_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4950",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0191681360272,
          "to_latitude": 21.01915196004474,
          "to_longitude": 105.83492341093033,
          "from_longitude": 105.83448179909418
        }
      },
      {
        "id": 4944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540710789578,
            21.019003599845387,
            105.83541166059982,
            21.01912322667779
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83541166059982,
              21.01912322667779
            ],
            [
              105.83540710789578,
              21.019003599845387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.10_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4951",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01912322667779,
          "to_latitude": 21.019003599845387,
          "to_longitude": 105.83540710789578,
          "from_longitude": 105.83541166059982
        }
      },
      {
        "id": 4945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553676410142,
            21.018831834885983,
            105.83555694426441,
            21.019112518031417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83555694426441,
              21.019112518031417
            ],
            [
              105.83553676410142,
              21.018836918845665
            ],
            [
              105.83555251956717,
              21.018831834885983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.10_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4952",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019112518031417,
          "to_latitude": 21.018831834885983,
          "to_longitude": 105.83555251956717,
          "from_longitude": 105.83555694426441
        }
      },
      {
        "id": 4946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354479831183,
            21.01887816511388,
            105.83549423278858,
            21.01912892952543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548277317666,
              21.01887816511388
            ],
            [
              105.83549423278858,
              21.018986740493308
            ],
            [
              105.8354479831183,
              21.018995602468678
            ],
            [
              105.83544989762508,
              21.019042781104833
            ],
            [
              105.83547815628977,
              21.019045230051557
            ],
            [
              105.83548310561704,
              21.01911896934294
            ],
            [
              105.83548347708184,
              21.019124521443246
            ],
            [
              105.83548377387405,
              21.01912892952543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.10_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4953",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01887816511388,
          "to_latitude": 21.01912892952543,
          "to_longitude": 105.83548377387405,
          "from_longitude": 105.83548277317666
        }
      },
      {
        "id": 4947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562823272712,
            21.018833807743132,
            105.83566674896011,
            21.019109235545884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566674896011,
              21.019109235545884
            ],
            [
              105.83562823272712,
              21.018833807743132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.11_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4954",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019109235545884,
          "to_latitude": 21.018833807743132,
          "to_longitude": 105.83562823272712,
          "from_longitude": 105.83566674896011
        }
      },
      {
        "id": 4948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575142132379,
            21.01870959272832,
            105.83581913502421,
            21.01910600209248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83581913502421,
              21.01910600209248
            ],
            [
              105.83581898846454,
              21.019105422942793
            ],
            [
              105.83581795736495,
              21.01910130208183
            ],
            [
              105.8358167413347,
              21.019096448711633
            ],
            [
              105.83578721479769,
              21.01891828562344
            ],
            [
              105.8357844863424,
              21.01891011130325
            ],
            [
              105.835757014066,
              21.018723044063456
            ],
            [
              105.83575142132379,
              21.01870959272832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.11_Ngõ 151, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4955",
          "diaChiLapD": "Ngõ 151, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01910600209248,
          "to_latitude": 21.01870959272832,
          "to_longitude": 105.83575142132379,
          "from_longitude": 105.83581913502421
        }
      },
      {
        "id": 4949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835757014066,
            21.0186721754562,
            105.8358557168122,
            21.018723044063456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835757014066,
              21.018723044063456
            ],
            [
              105.8358192454389,
              21.018707771881584
            ],
            [
              105.83581751985038,
              21.018683808793817
            ],
            [
              105.8358557168122,
              21.0186721754562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.11_Ngõ 151, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4956",
          "diaChiLapD": "Ngõ 151, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018723044063456,
          "to_latitude": 21.0186721754562,
          "to_longitude": 105.8358557168122,
          "from_longitude": 105.835757014066
        }
      },
      {
        "id": 4950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83578721479769,
            21.018763766334438,
            105.83584267008672,
            21.01891828562344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578721479769,
              21.01891828562344
            ],
            [
              105.83584267008672,
              21.01890920309884
            ],
            [
              105.83582200664998,
              21.018763766334438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.11_Ngõ 151, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4957",
          "diaChiLapD": "Ngõ 151, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01891828562344,
          "to_latitude": 21.018763766334438,
          "to_longitude": 105.83582200664998,
          "from_longitude": 105.83578721479769
        }
      },
      {
        "id": 4951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592219812672,
            21.018911297981894,
            105.83596415648023,
            21.0190898542698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83596415648023,
              21.0190898542698
            ],
            [
              105.83596414840714,
              21.019089786567672
            ],
            [
              105.83595664968392,
              21.019028132317242
            ],
            [
              105.83594077990979,
              21.019030313011054
            ],
            [
              105.83592219812672,
              21.018911297981894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.12_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4958",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0190898542698,
          "to_latitude": 21.018911297981894,
          "to_longitude": 105.83592219812672,
          "from_longitude": 105.83596415648023
        }
      },
      {
        "id": 4952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584299155956,
            21.019091157749415,
            105.83593468234457,
            21.019099395353283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584299155956,
              21.019094776119132
            ],
            [
              105.83589106117277,
              21.019092802111604
            ],
            [
              105.83593398806727,
              21.019091157749415
            ],
            [
              105.83593460614722,
              21.019098496116637
            ],
            [
              105.83593468234457,
              21.019099395353283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.12_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4959",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019094776119132,
          "to_latitude": 21.019099395353283,
          "to_longitude": 105.83593468234457,
          "from_longitude": 105.83584299155956
        }
      },
      {
        "id": 4953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593398806727,
            21.019070214088526,
            105.836229791863,
            21.019091157749415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593398806727,
              21.019091157749415
            ],
            [
              105.83596415648023,
              21.0190898542698
            ],
            [
              105.83600174461844,
              21.01908823070776
            ],
            [
              105.83606672985556,
              21.01908428487513
            ],
            [
              105.8361220911896,
              21.019079737807612
            ],
            [
              105.83618061563453,
              21.019074363175125
            ],
            [
              105.836229791863,
              21.019070214088526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.12_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4960",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019091157749415,
          "to_latitude": 21.019070214088526,
          "to_longitude": 105.836229791863,
          "from_longitude": 105.83593398806727
        }
      },
      {
        "id": 4954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83627676136403,
            21.019058764014698,
            105.8363374680554,
            21.019064138741822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627676136403,
              21.019064138741822
            ],
            [
              105.83629352516691,
              21.019062573905973
            ],
            [
              105.8363374680554,
              21.019058764014698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.13_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4961",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": 6.339,
          "from_latitude": 21.019064138741822,
          "to_latitude": 21.019058764014698,
          "to_longitude": 105.8363374680554,
          "from_longitude": 105.83627676136403
        }
      },
      {
        "id": 4955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630947912582,
            21.01885641309648,
            105.8363374680554,
            21.019058764014698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363374680554,
              21.019058764014698
            ],
            [
              105.83633677578696,
              21.01905412379056
            ],
            [
              105.83633308775357,
              21.019028460473063
            ],
            [
              105.83632839388554,
              21.019000606380224
            ],
            [
              105.83630947912582,
              21.01885641309648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.13_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4962",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": 22.672,
          "from_latitude": 21.019058764014698,
          "to_latitude": 21.01885641309648,
          "to_longitude": 105.83630947912582,
          "from_longitude": 105.8363374680554
        }
      },
      {
        "id": 4956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83658152736469,
            21.019042441147725,
            105.83664431793383,
            21.019045986641398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83658152736469,
              21.019045986641398
            ],
            [
              105.83664431793383,
              21.019042441147725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.14_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4963",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": 6.539,
          "from_latitude": 21.019045986641398,
          "to_latitude": 21.019042441147725,
          "to_longitude": 105.83664431793383,
          "from_longitude": 105.83658152736469
        }
      },
      {
        "id": 4957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83664431793383,
            21.01902526765063,
            105.83690937527066,
            21.019042441147725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83664431793383,
              21.019042441147725
            ],
            [
              105.83666639211995,
              21.0190398785182
            ],
            [
              105.83672706011035,
              21.019036289667884
            ],
            [
              105.83682051931791,
              21.0190333894198
            ],
            [
              105.83690937527066,
              21.01902526765063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.14_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4964",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019042441147725,
          "to_latitude": 21.01902526765063,
          "to_longitude": 105.83690937527066,
          "from_longitude": 105.83664431793383
        }
      },
      {
        "id": 4958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663773855723,
            21.01887128201655,
            105.83679471162141,
            21.01905234955133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83664541212588,
              21.01905234955133
            ],
            [
              105.83664431793383,
              21.019042441147725
            ],
            [
              105.83664424090688,
              21.019041738818395
            ],
            [
              105.83663773855723,
              21.018982854408833
            ],
            [
              105.83676478378996,
              21.018965864000176
            ],
            [
              105.83673734038854,
              21.018878157762508
            ],
            [
              105.83679471162141,
              21.01887128201655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.14_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4965",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01905234955133,
          "to_latitude": 21.01887128201655,
          "to_longitude": 105.83679471162141,
          "from_longitude": 105.83664541212588
        }
      },
      {
        "id": 4959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696343923742,
            21.01901698957791,
            105.83708358486538,
            21.0190225105605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696343923742,
              21.0190225105605
            ],
            [
              105.83707767994709,
              21.019017258893097
            ],
            [
              105.83708358486538,
              21.01901698957791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.15_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4966",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0190225105605,
          "to_latitude": 21.01901698957791,
          "to_longitude": 105.83708358486538,
          "from_longitude": 105.83696343923742
        }
      },
      {
        "id": 4960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701910170147,
            21.018779787017596,
            105.83709166677507,
            21.019041206785463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709166677507,
              21.019041206785463
            ],
            [
              105.83708358486538,
              21.01901698957791
            ],
            [
              105.8370387513964,
              21.018853174814147
            ],
            [
              105.83702283887736,
              21.018793743307903
            ],
            [
              105.83702283673168,
              21.018793703576605
            ],
            [
              105.83701910170147,
              21.018779787017596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.15_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.4967",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019041206785463,
          "to_latitude": 21.018779787017596,
          "to_longitude": 105.83701910170147,
          "from_longitude": 105.83709166677507
        }
      },
      {
        "id": 4961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728345085406,
            21.01855596906543,
            105.83745458390833,
            21.018586926761444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728345085406,
              21.018586926761444
            ],
            [
              105.83728587537227,
              21.018586135345725
            ],
            [
              105.83735563600523,
              21.018563330596837
            ],
            [
              105.83745458390833,
              21.01855596906543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65.4_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.4968",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018586926761444,
          "to_latitude": 21.01855596906543,
          "to_longitude": 105.83745458390833,
          "from_longitude": 105.83728345085406
        }
      },
      {
        "id": 4962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713877055389,
            21.018749422323737,
            105.83737424420612,
            21.01881876867186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713877055389,
              21.018773503316886
            ],
            [
              105.83717064562697,
              21.01881876867186
            ],
            [
              105.83723122719681,
              21.018792998699773
            ],
            [
              105.83724604518706,
              21.018815056557884
            ],
            [
              105.83737393150463,
              21.018749582830427
            ],
            [
              105.83737424420612,
              21.018749422323737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT65.1_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.4969",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018773503316886,
          "to_latitude": 21.018749422323737,
          "to_longitude": 105.83737424420612,
          "from_longitude": 105.83713877055389
        }
      },
      {
        "id": 4963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83923717215795,
            21.010559483960744,
            105.83939716735568,
            21.010654537520253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923717215795,
              21.010618459786865
            ],
            [
              105.83926584422039,
              21.010588646552474
            ],
            [
              105.83928785776939,
              21.010559483960744
            ],
            [
              105.83934011987655,
              21.010595932312732
            ],
            [
              105.83939716735568,
              21.010654537520253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.4_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4970",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010618459786865,
          "to_latitude": 21.010654537520253,
          "to_longitude": 105.83939716735568,
          "from_longitude": 105.83923717215795
        }
      },
      {
        "id": 4964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83717785648386,
            21.011779484520577,
            105.83730355602572,
            21.01186838911471
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717785648386,
              21.011779484520577
            ],
            [
              105.83730355602572,
              21.01186838911471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4971",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011779484520577,
          "to_latitude": 21.01186838911471,
          "to_longitude": 105.83730355602572,
          "from_longitude": 105.83717785648386
        }
      },
      {
        "id": 4965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697458836994,
            21.012300854752255,
            105.8371686486682,
            21.012488141052415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697458836994,
              21.012488141052415
            ],
            [
              105.8371686486682,
              21.012300854752255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.8_Ngõ 10, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4972",
          "diaChiLapD": "Ngõ 10, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012488141052415,
          "to_latitude": 21.012300854752255,
          "to_longitude": 105.8371686486682,
          "from_longitude": 105.83697458836994
        }
      },
      {
        "id": 4966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83464721948239,
            21.014151269860168,
            105.8348770149065,
            21.014360782387723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83464721948239,
              21.01435428701232
            ],
            [
              105.83465037180146,
              21.014356894512407
            ],
            [
              105.83465507802593,
              21.014360782387723
            ],
            [
              105.83471909907527,
              21.01430320024414
            ],
            [
              105.83472822978824,
              21.014293882039375
            ],
            [
              105.8347634126596,
              21.014257991401266
            ],
            [
              105.83485370405806,
              21.014170024997036
            ],
            [
              105.8348770149065,
              21.014151269860168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.8_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4973",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01435428701232,
          "to_latitude": 21.014151269860168,
          "to_longitude": 105.8348770149065,
          "from_longitude": 105.83464721948239
        }
      },
      {
        "id": 4967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83523321351747,
            21.01911896934294,
            105.83548310561704,
            21.01913067933573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523321351747,
              21.01913067933573
            ],
            [
              105.83541034795931,
              21.019123304479034
            ],
            [
              105.83541166059982,
              21.01912322667779
            ],
            [
              105.83548310561704,
              21.01911896934294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.10_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4974",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01913067933573,
          "to_latitude": 21.01911896934294,
          "to_longitude": 105.83548310561704,
          "from_longitude": 105.83523321351747
        }
      },
      {
        "id": 4968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83367798402166,
            21.015329035676917,
            105.83380011609253,
            21.015451901658768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83380011609253,
              21.015451901658768
            ],
            [
              105.83369265392494,
              21.015344164160123
            ],
            [
              105.83368830867873,
              21.01533980390977
            ],
            [
              105.8336813139609,
              21.015332793032528
            ],
            [
              105.83368024627394,
              21.015331592459383
            ],
            [
              105.83367798402166,
              21.015329035676917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.19_Ngõ 172, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4975",
          "diaChiLapD": "Ngõ 172, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015451901658768,
          "to_latitude": 21.015329035676917,
          "to_longitude": 105.83367798402166,
          "from_longitude": 105.83380011609253
        }
      },
      {
        "id": 4969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368830867873,
            21.015222888529475,
            105.83380980045347,
            21.01533980390977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368830867873,
              21.01533980390977
            ],
            [
              105.83380980045347,
              21.015222888529475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.19_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4976",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01533980390977,
          "to_latitude": 21.015222888529475,
          "to_longitude": 105.83380980045347,
          "from_longitude": 105.83368830867873
        }
      },
      {
        "id": 4970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83337744941606,
            21.01550512654277,
            105.83351948964214,
            21.015662808114413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83337744941606,
              21.015662808114413
            ],
            [
              105.83341488010545,
              21.01562018222306
            ],
            [
              105.83345057681892,
              21.015586806614433
            ],
            [
              105.83350265846377,
              21.015534267392827
            ],
            [
              105.83351180205925,
              21.015525163251528
            ],
            [
              105.83351948964214,
              21.01551750869321
            ],
            [
              105.8335102758061,
              21.015511895131276
            ],
            [
              105.83350239586733,
              21.01550709154898
            ],
            [
              105.8335015126934,
              21.015506551231304
            ],
            [
              105.83349917785338,
              21.01550512654277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.18_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4977",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015662808114413,
          "to_latitude": 21.01550512654277,
          "to_longitude": 105.83349917785338,
          "from_longitude": 105.83337744941606
        }
      },
      {
        "id": 4971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83351948964214,
            21.015376548776462,
            105.83365589272432,
            21.01551750869321
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83365589272432,
              21.015376548776462
            ],
            [
              105.83363863362341,
              21.015393846101297
            ],
            [
              105.8336385809945,
              21.015393896036556
            ],
            [
              105.83362575195004,
              21.015406755786902
            ],
            [
              105.83360538482086,
              21.015427167301784
            ],
            [
              105.83353133479741,
              21.01550571506007
            ],
            [
              105.83351948964214,
              21.01551750869321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.18_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.4978",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015376548776462,
          "to_latitude": 21.01551750869321,
          "to_longitude": 105.83351948964214,
          "from_longitude": 105.83365589272432
        }
      },
      {
        "id": 4972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718878667779,
            21.011919973031443,
            105.83731037530706,
            21.012026323551893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83718878667779,
              21.012026323551893
            ],
            [
              105.83725174720395,
              21.011986068656622
            ],
            [
              105.83731037530706,
              21.011919973031443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.6_Ngõ 188, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4979",
          "diaChiLapD": "Ngõ 188, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012026323551893,
          "to_latitude": 21.011919973031443,
          "to_longitude": 105.83731037530706,
          "from_longitude": 105.83718878667779
        }
      },
      {
        "id": 4973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83964539976463,
            21.017562609601057,
            105.83968558303513,
            21.017643330152772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83968558303513,
              21.017643330152772
            ],
            [
              105.83964539976463,
              21.017562609601057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.2_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4980",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017643330152772,
          "to_latitude": 21.017562609601057,
          "to_longitude": 105.83964539976463,
          "from_longitude": 105.83968558303513
        }
      },
      {
        "id": 4974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83964539976463,
            21.01744212495283,
            105.83969301403239,
            21.017562609601057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83964539976463,
              21.017562609601057
            ],
            [
              105.83969301403239,
              21.01754280805276
            ],
            [
              105.83965327888166,
              21.01744212495283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.2_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.4981",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017562609601057,
          "to_latitude": 21.01744212495283,
          "to_longitude": 105.83965327888166,
          "from_longitude": 105.83964539976463
        }
      },
      {
        "id": 4975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377389910847,
            21.017058979981886,
            105.83777881668729,
            21.017159743514696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377389910847,
              21.017058979981886
            ],
            [
              105.83777881668729,
              21.017159743514696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4982",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017058979981886,
          "to_latitude": 21.017159743514696,
          "to_longitude": 105.83777881668729,
          "from_longitude": 105.8377389910847
        }
      },
      {
        "id": 4976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859795990769,
            21.010667794851845,
            105.83872205761237,
            21.01078730664448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859795990769,
              21.010667794851845
            ],
            [
              105.83861806657367,
              21.01068706631041
            ],
            [
              105.83865123850808,
              21.01072074212528
            ],
            [
              105.83872205761237,
              21.01078730664448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.3_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4983",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010667794851845,
          "to_latitude": 21.01078730664448,
          "to_longitude": 105.83872205761237,
          "from_longitude": 105.83859795990769
        }
      },
      {
        "id": 4977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83754104503511,
            21.011187345123666,
            105.83763756744487,
            21.011299898669733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763756744487,
              21.011187345123666
            ],
            [
              105.83760585745473,
              21.01122650155124
            ],
            [
              105.83758514211735,
              21.01125050645552
            ],
            [
              105.83756567686719,
              21.01127306365775
            ],
            [
              105.8375411416003,
              21.011299793420378
            ],
            [
              105.83754104503511,
              21.011299898669733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.5_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4984",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011187345123666,
          "to_latitude": 21.011299898669733,
          "to_longitude": 105.83754104503511,
          "from_longitude": 105.83763756744487
        }
      },
      {
        "id": 4978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8374638308837,
            21.011299898669733,
            105.83754104503511,
            21.01138401936857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754104503511,
              21.011299898669733
            ],
            [
              105.8374638308837,
              21.01138401936857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.5_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.4985",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011299898669733,
          "to_latitude": 21.01138401936857,
          "to_longitude": 105.8374638308837,
          "from_longitude": 105.83754104503511
        }
      },
      {
        "id": 4979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766504215667,
            21.018497039812697,
            105.83768477714061,
            21.018642480879635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83768477714061,
              21.018642480879635
            ],
            [
              105.83767611729948,
              21.018567679695067
            ],
            [
              105.83767264599348,
              21.018549838249005
            ],
            [
              105.83767228969387,
              21.018547359751118
            ],
            [
              105.83766504215667,
              21.018497039812697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.4_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.4986",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018642480879635,
          "to_latitude": 21.018497039812697,
          "to_longitude": 105.83766504215667,
          "from_longitude": 105.83768477714061
        }
      },
      {
        "id": 4980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825496096283,
            21.01746282089561,
            105.83855029100025,
            21.017555661869785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825496096283,
              21.017555661869785
            ],
            [
              105.83828523073433,
              21.017543468222684
            ],
            [
              105.83832768473926,
              21.017525287573
            ],
            [
              105.83836710291169,
              21.017510564059855
            ],
            [
              105.8383937408697,
              21.017504314343054
            ],
            [
              105.83840879558288,
              21.01750194595913
            ],
            [
              105.83843159462084,
              21.017501069466583
            ],
            [
              105.83846640377502,
              21.017499367842454
            ],
            [
              105.83855029100025,
              21.01746282089561
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.1_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4987",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017555661869785,
          "to_latitude": 21.01746282089561,
          "to_longitude": 105.83855029100025,
          "from_longitude": 105.83825496096283
        }
      },
      {
        "id": 4981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83802780018769,
            21.017555661869785,
            105.83825496096283,
            21.017677799502476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825496096283,
              21.017555661869785
            ],
            [
              105.83825201379882,
              21.017557082711434
            ],
            [
              105.83824054270558,
              21.01756261548085
            ],
            [
              105.83822950866346,
              21.01756442029797
            ],
            [
              105.838220755941,
              21.01756494484417
            ],
            [
              105.83821445576433,
              21.01756487743584
            ],
            [
              105.83819652787749,
              21.017573301623266
            ],
            [
              105.83815196950201,
              21.0175986118188
            ],
            [
              105.83811383072825,
              21.0176238452143
            ],
            [
              105.83809232179105,
              21.017637142764865
            ],
            [
              105.83808584941944,
              21.01764482134403
            ],
            [
              105.83802780018769,
              21.017677799502476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.1_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4988",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017555661869785,
          "to_latitude": 21.017677799502476,
          "to_longitude": 105.83802780018769,
          "from_longitude": 105.83825496096283
        }
      },
      {
        "id": 4982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814188246117,
            21.017459863119708,
            105.83822950866346,
            21.01756442029797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822950866346,
              21.01756442029797
            ],
            [
              105.83817692147045,
              21.01750101297118
            ],
            [
              105.83815495744288,
              21.0174767657592
            ],
            [
              105.83814188246117,
              21.017459863119708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.1_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4989",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01756442029797,
          "to_latitude": 21.017459863119708,
          "to_longitude": 105.83814188246117,
          "from_longitude": 105.83822950866346
        }
      },
      {
        "id": 4983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819664576598,
            21.017466545640005,
            105.83857194314317,
            21.017589920359555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819664576598,
              21.017589920359555
            ],
            [
              105.83821071335713,
              21.017583561897954
            ],
            [
              105.83822806022522,
              21.017579956560986
            ],
            [
              105.83825072871133,
              21.017572069912866
            ],
            [
              105.83825854281612,
              21.017569351543607
            ],
            [
              105.83829745837053,
              21.017553506009275
            ],
            [
              105.83835694576526,
              21.017531742325627
            ],
            [
              105.83837692470516,
              21.01752576207243
            ],
            [
              105.8383933912024,
              21.01752109254391
            ],
            [
              105.83840925543143,
              21.017517956043054
            ],
            [
              105.83843061596576,
              21.01751460006525
            ],
            [
              105.83844676943247,
              21.01751622664053
            ],
            [
              105.83848376140942,
              21.017501992863977
            ],
            [
              105.83857194314317,
              21.017466545640005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.2_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4990",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017589920359555,
          "to_latitude": 21.017466545640005,
          "to_longitude": 105.83857194314317,
          "from_longitude": 105.83819664576598
        }
      },
      {
        "id": 4984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803953386978,
            21.017589920359555,
            105.83819664576598,
            21.017684482458197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819664576598,
              21.017589920359555
            ],
            [
              105.83816789515606,
              21.017603021472002
            ],
            [
              105.83811005348754,
              21.017638880822055
            ],
            [
              105.8380981844609,
              21.017647914631524
            ],
            [
              105.83808925381517,
              21.017656193320494
            ],
            [
              105.83803953386978,
              21.017684482458197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.2_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4991",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017589920359555,
          "to_latitude": 21.017684482458197,
          "to_longitude": 105.83803953386978,
          "from_longitude": 105.83819664576598
        }
      },
      {
        "id": 4985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83782256699632,
            21.017728022075417,
            105.83798531927451,
            21.017819278868796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798531927451,
              21.017728022075417
            ],
            [
              105.83796640910779,
              21.017739355845737
            ],
            [
              105.83794378261022,
              21.017750632952232
            ],
            [
              105.83787091397377,
              21.017788860430567
            ],
            [
              105.8378424400652,
              21.017806045407987
            ],
            [
              105.8378302653062,
              21.01781339257992
            ],
            [
              105.83782256699632,
              21.017819278868796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.3_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4992",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017728022075417,
          "to_latitude": 21.017819278868796,
          "to_longitude": 105.83782256699632,
          "from_longitude": 105.83798531927451
        }
      },
      {
        "id": 4986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767406550304,
            21.017710200796042,
            105.83797683823566,
            21.01789411789017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83797683823566,
              21.017710200796042
            ],
            [
              105.83790955847898,
              21.017749103467786
            ],
            [
              105.83784728744534,
              21.017778170628
            ],
            [
              105.8378372461624,
              21.01777998407904
            ],
            [
              105.83783452755034,
              21.017780475273952
            ],
            [
              105.83782234605322,
              21.017788682347533
            ],
            [
              105.83781098489871,
              21.017796337123247
            ],
            [
              105.83774560375538,
              21.017843988039466
            ],
            [
              105.83770294139204,
              21.0178738058062
            ],
            [
              105.83767406550304,
              21.01789411789017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.5_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4993",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017710200796042,
          "to_latitude": 21.01789411789017,
          "to_longitude": 105.83767406550304,
          "from_longitude": 105.83797683823566
        }
      },
      {
        "id": 4987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375950934007,
            21.01781681504486,
            105.83767406550304,
            21.01789411789017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767406550304,
              21.01789411789017
            ],
            [
              105.8376505971403,
              21.017874200850304
            ],
            [
              105.83764693992,
              21.017874738212306
            ],
            [
              105.8375950934007,
              21.01781681504486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.5_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4994",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01789411789017,
          "to_latitude": 21.01781681504486,
          "to_longitude": 105.8375950934007,
          "from_longitude": 105.83767406550304
        }
      },
      {
        "id": 4988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774140752224,
            21.017696879867046,
            105.83783452755034,
            21.017780475273952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783452755034,
              21.017780475273952
            ],
            [
              105.83783255002815,
              21.01777862527565
            ],
            [
              105.83779146133229,
              21.017738506822877
            ],
            [
              105.83774140752224,
              21.017696879867046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.5_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4995",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017780475273952,
          "to_latitude": 21.017696879867046,
          "to_longitude": 105.83774140752224,
          "from_longitude": 105.83783452755034
        }
      },
      {
        "id": 4989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768118834233,
            21.017899728867818,
            105.83778114391934,
            21.01802169481137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83768118834233,
              21.017899728867818
            ],
            [
              105.83771170597326,
              21.017931888710926
            ],
            [
              105.83774172015234,
              21.017962949997575
            ],
            [
              105.83772606700036,
              21.017979087304866
            ],
            [
              105.83774982139792,
              21.01800198717612
            ],
            [
              105.83776344177441,
              21.01802060509031
            ],
            [
              105.837776090238,
              21.018015224617685
            ],
            [
              105.83778114391934,
              21.01802169481137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.4_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4996",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017899728867818,
          "to_latitude": 21.01802169481137,
          "to_longitude": 105.83778114391934,
          "from_longitude": 105.83768118834233
        }
      },
      {
        "id": 4990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768118834233,
            21.017861933334895,
            105.83773401796454,
            21.017899728867818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83768118834233,
              21.017899728867818
            ],
            [
              105.83770606576083,
              21.017885336330618
            ],
            [
              105.83773401796454,
              21.017866020529834
            ],
            [
              105.83773295626791,
              21.017865040341032
            ],
            [
              105.83772982123185,
              21.017861933334895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.4_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4997",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017899728867818,
          "to_latitude": 21.017861933334895,
          "to_longitude": 105.83772982123185,
          "from_longitude": 105.83768118834233
        }
      },
      {
        "id": 4991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755934939377,
            21.017893724060137,
            105.83768118834233,
            21.01796497522777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755934939377,
              21.01796497522777
            ],
            [
              105.83764268175433,
              21.017902365312498
            ],
            [
              105.83765756200299,
              21.017893724060137
            ],
            [
              105.83766794564396,
              21.017898264016388
            ],
            [
              105.83767611171761,
              21.01790223863498
            ],
            [
              105.83768118834233,
              21.017899728867818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.4_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4998",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01796497522777,
          "to_latitude": 21.017899728867818,
          "to_longitude": 105.83768118834233,
          "from_longitude": 105.83755934939377
        }
      },
      {
        "id": 4992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372098864062,
            21.01789709153885,
            105.83763256239955,
            21.018124440803682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763256239955,
              21.01789709153885
            ],
            [
              105.83761209920667,
              21.017913695099946
            ],
            [
              105.83756586891872,
              21.017948335552013
            ],
            [
              105.83752804243241,
              21.017973368573678
            ],
            [
              105.83751227999497,
              21.017967700770196
            ],
            [
              105.83750203084138,
              21.01796743419759
            ],
            [
              105.83745963845297,
              21.017992900650082
            ],
            [
              105.83744357004741,
              21.018000658059265
            ],
            [
              105.837439434224,
              21.018002654673076
            ],
            [
              105.83738984706561,
              21.018037885151344
            ],
            [
              105.83738131636855,
              21.018041655652002
            ],
            [
              105.83733889347569,
              21.018065284164972
            ],
            [
              105.8372914186534,
              21.018089819983004
            ],
            [
              105.8372098864062,
              21.018124440803682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.10_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.4999",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01789709153885,
          "to_latitude": 21.018124440803682,
          "to_longitude": 105.8372098864062,
          "from_longitude": 105.83763256239955
        }
      },
      {
        "id": 4993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713414118091,
            21.018026354034884,
            105.83721813265169,
            21.018138468834458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721813265169,
              21.018138468834458
            ],
            [
              105.83721206552757,
              21.01812794091027
            ],
            [
              105.8372098864062,
              21.018124440803682
            ],
            [
              105.83719717487527,
              21.01811887356875
            ],
            [
              105.83715544705771,
              21.018054547190754
            ],
            [
              105.83713414118091,
              21.018026354034884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.10_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5000",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018138468834458,
          "to_latitude": 21.018026354034884,
          "to_longitude": 105.83713414118091,
          "from_longitude": 105.83721813265169
        }
      },
      {
        "id": 4994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697432148475,
            21.017907916688205,
            105.83720967865587,
            21.01815914442416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697432148475,
              21.017907916688205
            ],
            [
              105.83700395134369,
              21.017953049519626
            ],
            [
              105.83703049168126,
              21.017965823292094
            ],
            [
              105.83705925264326,
              21.017965921127754
            ],
            [
              105.83710546340758,
              21.018006521263853
            ],
            [
              105.83712769982948,
              21.018038050869595
            ],
            [
              105.83715477051979,
              21.018070350615602
            ],
            [
              105.83717710757045,
              21.018105083453953
            ],
            [
              105.83720439018481,
              21.01815914442416
            ],
            [
              105.83720696111286,
              21.018157871774047
            ],
            [
              105.83720967865587,
              21.01815649814448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.11_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5001",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017907916688205,
          "to_latitude": 21.01815649814448,
          "to_longitude": 105.83720967865587,
          "from_longitude": 105.83697432148475
        }
      },
      {
        "id": 4995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720439018481,
            21.01815914442416,
            105.83726938811627,
            21.018387527839778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720439018481,
              21.01815914442416
            ],
            [
              105.83721459946912,
              21.018183973480383
            ],
            [
              105.83722603456529,
              21.018208335858674
            ],
            [
              105.83724041722073,
              21.018249091693544
            ],
            [
              105.83725680461666,
              21.018301396215374
            ],
            [
              105.83726938811627,
              21.018368629876562
            ],
            [
              105.83726006743407,
              21.018387527839778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.11_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5002",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01815914442416,
          "to_latitude": 21.018387527839778,
          "to_longitude": 105.83726006743407,
          "from_longitude": 105.83720439018481
        }
      },
      {
        "id": 4996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722268170368,
            21.018087955861276,
            105.83732680807621,
            21.018317619861985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83732680807621,
              21.018087955861276
            ],
            [
              105.83724661724767,
              21.018130410982934
            ],
            [
              105.83722268170368,
              21.018147770425948
            ],
            [
              105.83724684094979,
              21.01821132067534
            ],
            [
              105.8372516058769,
              21.01822620673969
            ],
            [
              105.83725739585724,
              21.018244296913064
            ],
            [
              105.83727718663285,
              21.018317619861985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.9_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5003",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018087955861276,
          "to_latitude": 21.018317619861985,
          "to_longitude": 105.83727718663285,
          "from_longitude": 105.83732680807621
        }
      },
      {
        "id": 4997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83740500928373,
            21.017984332002452,
            105.83752529060895,
            21.018061304269274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752529060895,
              21.01799340749744
            ],
            [
              105.83751983814162,
              21.017997002075834
            ],
            [
              105.83750959230558,
              21.01798888918196
            ],
            [
              105.83750325549519,
              21.017984332002452
            ],
            [
              105.83748178871166,
              21.018000374168004
            ],
            [
              105.83744356373043,
              21.018030708347624
            ],
            [
              105.83740500928373,
              21.018061304269274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.6_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5004",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01799340749744,
          "to_latitude": 21.018061304269274,
          "to_longitude": 105.83740500928373,
          "from_longitude": 105.83752529060895
        }
      },
      {
        "id": 4998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83742160839341,
            21.017872919762336,
            105.83755508850469,
            21.01800467262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83744674381667,
              21.01800467262
            ],
            [
              105.83744591454357,
              21.018003626253087
            ],
            [
              105.83744357004741,
              21.018000658059265
            ],
            [
              105.83742160839341,
              21.01797286649058
            ],
            [
              105.83748008424503,
              21.017929592390637
            ],
            [
              105.83752974894804,
              21.017892927186043
            ],
            [
              105.83755508850469,
              21.017872919762336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.7_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5005",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01800467262,
          "to_latitude": 21.017872919762336,
          "to_longitude": 105.83755508850469,
          "from_longitude": 105.83744674381667
        }
      },
      {
        "id": 4999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83742765589678,
            21.01785468417029,
            105.83748008424503,
            21.017929592390637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748008424503,
              21.017929592390637
            ],
            [
              105.83742765589678,
              21.017875823252453
            ],
            [
              105.83745133074326,
              21.01785468417029
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.7_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5006",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017929592390637,
          "to_latitude": 21.01785468417029,
          "to_longitude": 105.83745133074326,
          "from_longitude": 105.83748008424503
        }
      },
      {
        "id": 5000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373784497751,
            21.018044243950765,
            105.83748523913872,
            21.01812846452299
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373784497751,
              21.018044243950765
            ],
            [
              105.83737871043922,
              21.018044584086272
            ],
            [
              105.83744332752087,
              21.01812846452299
            ],
            [
              105.83748523913872,
              21.018094414394977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.8_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5007",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018044243950765,
          "to_latitude": 21.018094414394977,
          "to_longitude": 105.83748523913872,
          "from_longitude": 105.8373784497751
        }
      },
      {
        "id": 5001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143764386983,
            21.018539342792366,
            105.8316060221335,
            21.018690401943147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83143764386983,
              21.018690401943147
            ],
            [
              105.83144532807627,
              21.01868434619814
            ],
            [
              105.83152678095068,
              21.01861063691564
            ],
            [
              105.83152890104456,
              21.01860871803821
            ],
            [
              105.8316060221335,
              21.018539342792366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.3_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.5008",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018690401943147,
          "to_latitude": 21.018539342792366,
          "to_longitude": 105.8316060221335,
          "from_longitude": 105.83143764386983
        }
      },
      {
        "id": 5002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84070040046318,
            21.008064690108867,
            105.84113654738483,
            21.008324840388855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84070040046318,
              21.00817432990529
            ],
            [
              105.8407011093918,
              21.00817364627519
            ],
            [
              105.84079360605993,
              21.008084485452905
            ],
            [
              105.84081560026554,
              21.008064690108867
            ],
            [
              105.84113654738483,
              21.008324840388855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5009",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00817432990529,
          "to_latitude": 21.008324840388855,
          "to_longitude": 105.84113654738483,
          "from_longitude": 105.84070040046318
        }
      },
      {
        "id": 5003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84072090323802,
            21.0081987330927,
            105.84079415617178,
            21.00830866300142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84072090323802,
              21.0081987330927
            ],
            [
              105.84074435793586,
              21.008228608781277
            ],
            [
              105.84076333483085,
              21.00825432466054
            ],
            [
              105.84077012798734,
              21.008263133664375
            ],
            [
              105.84077821741313,
              21.008275998966326
            ],
            [
              105.84078412791915,
              21.008286245747286
            ],
            [
              105.84078809495583,
              21.008298299546354
            ],
            [
              105.84079415617178,
              21.00830866300142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.4_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5010",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0081987330927,
          "to_latitude": 21.00830866300142,
          "to_longitude": 105.84079415617178,
          "from_longitude": 105.84072090323802
        }
      },
      {
        "id": 5004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84079415617178,
            21.00830866300142,
            105.84086968729962,
            21.008455556004776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84079415617178,
              21.00830866300142
            ],
            [
              105.84080078732424,
              21.00832409343813
            ],
            [
              105.84080696143216,
              21.008335892461474
            ],
            [
              105.84081288495696,
              21.00834691685301
            ],
            [
              105.84081645131926,
              21.00835355418853
            ],
            [
              105.84082631543072,
              21.008369851100554
            ],
            [
              105.8408351788702,
              21.008384719539297
            ],
            [
              105.84083846455187,
              21.008388300847034
            ],
            [
              105.84084169914813,
              21.008396154657806
            ],
            [
              105.84086968729962,
              21.008455556004776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.4_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5011",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00830866300142,
          "to_latitude": 21.008455556004776,
          "to_longitude": 105.84086968729962,
          "from_longitude": 105.84079415617178
        }
      },
      {
        "id": 5005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8407159679458,
            21.008206829589763,
            105.84077288864631,
            21.008285800889947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8407159679458,
              21.008206829589763
            ],
            [
              105.84072369891499,
              21.008216664541543
            ],
            [
              105.84074048103894,
              21.008240195528085
            ],
            [
              105.84075903941202,
              21.008266751667573
            ],
            [
              105.84077090472549,
              21.00828296372874
            ],
            [
              105.84077288864631,
              21.008285800889947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.3_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5012",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008206829589763,
          "to_latitude": 21.008285800889947,
          "to_longitude": 105.84077288864631,
          "from_longitude": 105.8407159679458
        }
      },
      {
        "id": 5006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84077288864631,
            21.008285800889947,
            105.84087981075581,
            21.00859227942162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077288864631,
              21.008285800889947
            ],
            [
              105.84077938608154,
              21.008301229278594
            ],
            [
              105.84078296748692,
              21.00831364175661
            ],
            [
              105.84079091963167,
              21.008324666061757
            ],
            [
              105.84080018288616,
              21.008342394844902
            ],
            [
              105.84080144706826,
              21.008344501240455
            ],
            [
              105.84081727079509,
              21.008370882109947
            ],
            [
              105.84082610532298,
              21.008385225918744
            ],
            [
              105.8408382635348,
              21.008413365410007
            ],
            [
              105.84086176242391,
              21.008465878370846
            ],
            [
              105.84087188130697,
              21.008494336838368
            ],
            [
              105.84087387803186,
              21.008510099976952
            ],
            [
              105.84087129114495,
              21.008558438868626
            ],
            [
              105.84087981075581,
              21.00859227942162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.3_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5013",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008285800889947,
          "to_latitude": 21.00859227942162,
          "to_longitude": 105.84087981075581,
          "from_longitude": 105.84077288864631
        }
      },
      {
        "id": 5007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013751461885,
            21.008090896144818,
            105.84070607309181,
            21.008298246548136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84013751461885,
              21.008298246548136
            ],
            [
              105.84030967602321,
              21.008213170854688
            ],
            [
              105.84044066975402,
              21.00815632174601
            ],
            [
              105.84060870960046,
              21.008099489163282
            ],
            [
              105.84065345558543,
              21.008090896144818
            ],
            [
              105.84070282245938,
              21.00813260433239
            ],
            [
              105.84070607309181,
              21.008135425301322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.1_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.5014",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008298246548136,
          "to_latitude": 21.008135425301322,
          "to_longitude": 105.84070607309181,
          "from_longitude": 105.84013751461885
        }
      },
      {
        "id": 5008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8404994371985,
            21.00817617532158,
            105.84065009349465,
            21.008305199004507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8404994371985,
              21.008305199004507
            ],
            [
              105.84053651037839,
              21.008272585627804
            ],
            [
              105.84056543411832,
              21.008246785775658
            ],
            [
              105.84059291739634,
              21.00822226928994
            ],
            [
              105.8406495968238,
              21.00817663029044
            ],
            [
              105.84065009349465,
              21.00817617532158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5015",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008305199004507,
          "to_latitude": 21.00817617532158,
          "to_longitude": 105.84065009349465,
          "from_longitude": 105.8404994371985
        }
      },
      {
        "id": 5009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84065009349465,
            21.008139385295745,
            105.84068444607732,
            21.00817617532158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84065009349465,
              21.00817617532158
            ],
            [
              105.84068444607732,
              21.008139385295745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5016",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00817617532158,
          "to_latitude": 21.008139385295745,
          "to_longitude": 105.84068444607732,
          "from_longitude": 105.84065009349465
        }
      },
      {
        "id": 5010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84027509661594,
            21.008338575868507,
            105.84071255937752,
            21.008670662075026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027509661594,
              21.008565207999457
            ],
            [
              105.84027807619846,
              21.00856274012983
            ],
            [
              105.84029166665819,
              21.00853816222466
            ],
            [
              105.84033368991872,
              21.008489935698808
            ],
            [
              105.84038790609127,
              21.00843329864179
            ],
            [
              105.84044348438125,
              21.00838200734863
            ],
            [
              105.84049449632624,
              21.008338575868507
            ],
            [
              105.84052128613136,
              21.008367810988023
            ],
            [
              105.84060856952475,
              21.008461156221337
            ],
            [
              105.84064856515376,
              21.008501550454476
            ],
            [
              105.84066415258367,
              21.00852335586794
            ],
            [
              105.84068958295391,
              21.008556305409616
            ],
            [
              105.84070107164544,
              21.008571734433364
            ],
            [
              105.84070926833809,
              21.008581252762053
            ],
            [
              105.84071255937752,
              21.008587163460973
            ],
            [
              105.84071156207908,
              21.008590801155858
            ],
            [
              105.84070406089636,
              21.008596573687655
            ],
            [
              105.84064911567062,
              21.008637757567357
            ],
            [
              105.84060613708786,
              21.008670662075026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT84.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5017",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008565207999457,
          "to_latitude": 21.008670662075026,
          "to_longitude": 105.84060613708786,
          "from_longitude": 105.84027509661594
        }
      },
      {
        "id": 5011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84050601291722,
            21.008171844217053,
            105.84072901732301,
            21.00867693874126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84069894081948,
              21.008171844217053
            ],
            [
              105.84069853292378,
              21.008172177715416
            ],
            [
              105.84065011534533,
              21.008204456069898
            ],
            [
              105.84058433943756,
              21.008264537397245
            ],
            [
              105.84050601291722,
              21.00833495125214
            ],
            [
              105.84056042302004,
              21.008392800478564
            ],
            [
              105.8406146594664,
              21.008452953765733
            ],
            [
              105.84064440858751,
              21.00848639862602
            ],
            [
              105.84065857762833,
              21.008499347706977
            ],
            [
              105.8406739564287,
              21.00851378835623
            ],
            [
              105.84068944370757,
              21.00853145207548
            ],
            [
              105.8407152690611,
              21.0085621054722
            ],
            [
              105.84072264237703,
              21.008569907225766
            ],
            [
              105.84072592056282,
              21.008573523798344
            ],
            [
              105.84072901732301,
              21.008581155195714
            ],
            [
              105.84072759237739,
              21.008588811641673
            ],
            [
              105.84070268575574,
              21.00860556575606
            ],
            [
              105.84063457682151,
              21.008659242125056
            ],
            [
              105.84061268713832,
              21.00867693874126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT85.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5018",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008171844217053,
          "to_latitude": 21.00867693874126,
          "to_longitude": 105.84061268713832,
          "from_longitude": 105.84069894081948
        }
      },
      {
        "id": 5012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84042632244325,
            21.00934992850276,
            105.84078313693215,
            21.009753648973234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84076039258552,
              21.00934992850276
            ],
            [
              105.84075963645955,
              21.009350601527665
            ],
            [
              105.84075537203888,
              21.00935466091212
            ],
            [
              105.84078313693215,
              21.00940423177076
            ],
            [
              105.84077297549457,
              21.00941505111828
            ],
            [
              105.84070712054937,
              21.009470722445514
            ],
            [
              105.8406699376128,
              21.009495293193368
            ],
            [
              105.84062976985071,
              21.00952191544889
            ],
            [
              105.84058922596887,
              21.009542527693053
            ],
            [
              105.84055389022338,
              21.009571288380975
            ],
            [
              105.84053722505286,
              21.00958376745033
            ],
            [
              105.84052474818311,
              21.0095973133125
            ],
            [
              105.84049448242499,
              21.00962948210106
            ],
            [
              105.8404767934685,
              21.009650834076485
            ],
            [
              105.8404727802088,
              21.009654228343898
            ],
            [
              105.84049205163309,
              21.009686992455386
            ],
            [
              105.84050711146789,
              21.009711956348568
            ],
            [
              105.84042632244325,
              21.009753648973234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.10_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5019",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00934992850276,
          "to_latitude": 21.009753648973234,
          "to_longitude": 105.84042632244325,
          "from_longitude": 105.84076039258552
        }
      },
      {
        "id": 5013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046987388072,
            21.009347658742417,
            105.84077234261348,
            21.009635433054765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84075910439402,
              21.009347658742417
            ],
            [
              105.84075780769739,
              21.009348677663304
            ],
            [
              105.84075004369723,
              21.009354417173647
            ],
            [
              105.84077078550625,
              21.009388488180544
            ],
            [
              105.84077234261348,
              21.009399438402728
            ],
            [
              105.84074441436653,
              21.009422511056016
            ],
            [
              105.84071685566997,
              21.009443557753798
            ],
            [
              105.8407079906032,
              21.009451069408605
            ],
            [
              105.84069151197775,
              21.00946250254052
            ],
            [
              105.84066932813442,
              21.009477846816605
            ],
            [
              105.84064017011997,
              21.00949740837383
            ],
            [
              105.84062775079079,
              21.009509203510508
            ],
            [
              105.84060078557742,
              21.00952579075034
            ],
            [
              105.84057517792861,
              21.009541407539928
            ],
            [
              105.84052835536302,
              21.00957620521059
            ],
            [
              105.8405104933127,
              21.009593957792696
            ],
            [
              105.84048059249614,
              21.009626023615205
            ],
            [
              105.84046987388072,
              21.009635433054765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.9_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5020",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009347658742417,
          "to_latitude": 21.009635433054765,
          "to_longitude": 105.84046987388072,
          "from_longitude": 105.84075910439402
        }
      },
      {
        "id": 5014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84073649792214,
            21.00892924719715,
            105.84104921656674,
            21.009096850238247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84073649792214,
              21.009096850238247
            ],
            [
              105.84074150143726,
              21.00909422965185
            ],
            [
              105.84074489430992,
              21.009095136887503
            ],
            [
              105.84075022832036,
              21.009096565628546
            ],
            [
              105.8407574525261,
              21.009091999363907
            ],
            [
              105.84079779343898,
              21.009069694540226
            ],
            [
              105.84083952514992,
              21.00904582928958
            ],
            [
              105.84087796075178,
              21.009024251021827
            ],
            [
              105.84091562286639,
              21.0090007644423
            ],
            [
              105.8409438970921,
              21.00898246178638
            ],
            [
              105.84097229627255,
              21.00896380083166
            ],
            [
              105.84099691224405,
              21.008951132493717
            ],
            [
              105.84104921656674,
              21.00892924719715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.5_Ngõ 422, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5021",
          "diaChiLapD": "Ngõ 422, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009096850238247,
          "to_latitude": 21.00892924719715,
          "to_longitude": 105.84104921656674,
          "from_longitude": 105.84073649792214
        }
      },
      {
        "id": 5015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84074286507489,
            21.008899352991083,
            105.84119309540591,
            21.00927820433973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84074286507489,
              21.00910618792565
            ],
            [
              105.8407471834718,
              21.00910403769086
            ],
            [
              105.84074905624924,
              21.00910283076295
            ],
            [
              105.84076149574422,
              21.00909481370527
            ],
            [
              105.84083693472054,
              21.009055432523574
            ],
            [
              105.84086969740217,
              21.00903511338256
            ],
            [
              105.84091566113467,
              21.00900656295241
            ],
            [
              105.84094073628448,
              21.008990351714512
            ],
            [
              105.840969492699,
              21.00897107028632
            ],
            [
              105.8409860164667,
              21.008963757414563
            ],
            [
              105.84099458454746,
              21.00895904178936
            ],
            [
              105.84102312676183,
              21.008945822948327
            ],
            [
              105.84105690403167,
              21.00893079526462
            ],
            [
              105.8410963198894,
              21.00891816666846
            ],
            [
              105.84116853040896,
              21.008901747710315
            ],
            [
              105.84118484799077,
              21.008899352991083
            ],
            [
              105.84119193968722,
              21.00901476443162
            ],
            [
              105.84119309540591,
              21.009178534025494
            ],
            [
              105.84118899127941,
              21.009278096835256
            ],
            [
              105.84118898707266,
              21.00927820433973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.6_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5022",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 117,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00910618792565,
          "to_latitude": 21.00927820433973,
          "to_longitude": 105.84118898707266,
          "from_longitude": 105.84074286507489
        }
      },
      {
        "id": 5016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84100201648272,
            21.00927820433973,
            105.84118898707266,
            21.009321382201126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84118898707266,
              21.00927820433973
            ],
            [
              105.84108581759529,
              21.009292357497788
            ],
            [
              105.84100201648272,
              21.009321382201126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.6_Ngõ 400, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5023",
          "diaChiLapD": "Ngõ 400, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00927820433973,
          "to_latitude": 21.009321382201126,
          "to_longitude": 105.84100201648272,
          "from_longitude": 105.84118898707266
        }
      },
      {
        "id": 5017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84103675837274,
            21.009178534025494,
            105.84119309540591,
            21.009202660525506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84119309540591,
              21.009178534025494
            ],
            [
              105.84114815386624,
              21.0091819788792
            ],
            [
              105.84105146413138,
              21.009196368243117
            ],
            [
              105.84103675837274,
              21.009202660525506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.6_Ngõ 404, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5024",
          "diaChiLapD": "Ngõ 404, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009178534025494,
          "to_latitude": 21.009202660525506,
          "to_longitude": 105.84103675837274,
          "from_longitude": 105.84119309540591
        }
      },
      {
        "id": 5018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84097765257239,
            21.009125724259874,
            105.84103675837274,
            21.009202660525506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84103675837274,
              21.009202660525506
            ],
            [
              105.84097765257239,
              21.009125724259874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.6_Ngõ 404, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5025",
          "diaChiLapD": "Ngõ 404, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 111,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009202660525506,
          "to_latitude": 21.009125724259874,
          "to_longitude": 105.84097765257239,
          "from_longitude": 105.84103675837274
        }
      },
      {
        "id": 5019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8403307883093,
            21.0086062880297,
            105.8404328583121,
            21.008697298658582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8403307883093,
              21.0086062880297
            ],
            [
              105.84038864899411,
              21.00865731086116
            ],
            [
              105.84041738017241,
              21.008683325445265
            ],
            [
              105.8404328583121,
              21.008697298658582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.3_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5026",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0086062880297,
          "to_latitude": 21.008697298658582,
          "to_longitude": 105.8404328583121,
          "from_longitude": 105.8403307883093
        }
      },
      {
        "id": 5020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060277909414,
            21.008836554747035,
            105.84069715437083,
            21.00890330768154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060277909414,
              21.00890330768154
            ],
            [
              105.84069715437083,
              21.008836554747035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.3_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5027",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00890330768154,
          "to_latitude": 21.008836554747035,
          "to_longitude": 105.84069715437083,
          "from_longitude": 105.84060277909414
        }
      },
      {
        "id": 5021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84032427583573,
            21.00861960029561,
            105.84044290140868,
            21.00872168308229
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84032427583573,
              21.00861960029561
            ],
            [
              105.84039902500861,
              21.008682574287445
            ],
            [
              105.84040300315928,
              21.00868596792609
            ],
            [
              105.84041419581835,
              21.00869714785795
            ],
            [
              105.84043160469561,
              21.008712190889298
            ],
            [
              105.84044290140868,
              21.00872168308229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.4_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5028",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00861960029561,
          "to_latitude": 21.00872168308229,
          "to_longitude": 105.84044290140868,
          "from_longitude": 105.84032427583573
        }
      },
      {
        "id": 5022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044290140868,
            21.00872168308229,
            105.84061412149671,
            21.008936918433307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84044290140868,
              21.00872168308229
            ],
            [
              105.84045983454116,
              21.0087359363324
            ],
            [
              105.84048100282706,
              21.00876031096894
            ],
            [
              105.84052261072047,
              21.00880913428393
            ],
            [
              105.84054288895908,
              21.00883774491376
            ],
            [
              105.84057215614361,
              21.008877869700804
            ],
            [
              105.84058723809211,
              21.008899926003078
            ],
            [
              105.84061412149671,
              21.008936918433307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.4_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5029",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00872168308229,
          "to_latitude": 21.008936918433307,
          "to_longitude": 105.84061412149671,
          "from_longitude": 105.84044290140868
        }
      },
      {
        "id": 5023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8404328583121,
            21.008697298658582,
            105.84062171810552,
            21.008929632533132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8404328583121,
              21.008697298658582
            ],
            [
              105.84044217033734,
              21.008705705945502
            ],
            [
              105.84046676965833,
              21.008725954871878
            ],
            [
              105.84046859702345,
              21.008727459650668
            ],
            [
              105.84048211926579,
              21.008742910258547
            ],
            [
              105.84049886864358,
              21.00876265149958
            ],
            [
              105.84052364028082,
              21.008792366259573
            ],
            [
              105.8405536762613,
              21.008832022092662
            ],
            [
              105.84060087317471,
              21.008900658907258
            ],
            [
              105.84060277909414,
              21.00890330768154
            ],
            [
              105.84062171810552,
              21.008929632533132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.3_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5030",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008697298658582,
          "to_latitude": 21.008929632533132,
          "to_longitude": 105.84062171810552,
          "from_longitude": 105.8404328583121
        }
      },
      {
        "id": 5024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84064424659411,
            21.008954356298474,
            105.84077899786281,
            21.00915662494604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84064424659411,
              21.008954356298474
            ],
            [
              105.84066302432473,
              21.008976271497765
            ],
            [
              105.8406781872086,
              21.008994399269106
            ],
            [
              105.84069603564814,
              21.009021788077824
            ],
            [
              105.8407114628355,
              21.009045369111117
            ],
            [
              105.84074489430992,
              21.009095136887503
            ],
            [
              105.84074512419554,
              21.009095478976942
            ],
            [
              105.84074905624924,
              21.00910283076295
            ],
            [
              105.8407664409045,
              21.00913533771637
            ],
            [
              105.84077899786281,
              21.009155320930013
            ],
            [
              105.8407769676531,
              21.009156241410803
            ],
            [
              105.8407761214117,
              21.00915662494604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.8_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5031",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008954356298474,
          "to_latitude": 21.00915662494604,
          "to_longitude": 105.8407761214117,
          "from_longitude": 105.84064424659411
        }
      },
      {
        "id": 5025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84064004034038,
            21.00896776647307,
            105.84075734007384,
            21.00914591788762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84064004034038,
              21.00896776647307
            ],
            [
              105.84067856627486,
              21.009017075550098
            ],
            [
              105.84071211931843,
              21.00907120440269
            ],
            [
              105.84071680415666,
              21.00907883247001
            ],
            [
              105.84073514065538,
              21.009108689180827
            ],
            [
              105.84074806494526,
              21.0091310569005
            ],
            [
              105.84075734007384,
              21.00914591788762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.7_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5032",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00896776647307,
          "to_latitude": 21.00914591788762,
          "to_longitude": 105.84075734007384,
          "from_longitude": 105.84064004034038
        }
      },
      {
        "id": 5026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84072855563325,
            21.00914591788762,
            105.8408092525392,
            21.009350218917675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84075734007384,
              21.00914591788762
            ],
            [
              105.84076241149046,
              21.009154041720027
            ],
            [
              105.84076479724428,
              21.009157864119427
            ],
            [
              105.8407971470803,
              21.009212175025986
            ],
            [
              105.8408092525392,
              21.009235523171327
            ],
            [
              105.84079220596436,
              21.00924876195404
            ],
            [
              105.84074394104124,
              21.009286710931
            ],
            [
              105.84073865687236,
              21.009303448491618
            ],
            [
              105.84072855563325,
              21.009326727516594
            ],
            [
              105.84073695957929,
              21.009338034105035
            ],
            [
              105.84074311087947,
              21.009350218917675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.7_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5033",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00914591788762,
          "to_latitude": 21.009350218917675,
          "to_longitude": 105.84074311087947,
          "from_longitude": 105.84075734007384
        }
      },
      {
        "id": 5027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84074588595941,
            21.009161546492255,
            105.84082454335484,
            21.00937854251213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84078291014248,
              21.009161546492255
            ],
            [
              105.84079455405205,
              21.00918011796025
            ],
            [
              105.8408058501324,
              21.009206652163794
            ],
            [
              105.84082454335484,
              21.009237507873415
            ],
            [
              105.84080000612727,
              21.009251536957503
            ],
            [
              105.84077720668442,
              21.009270480927096
            ],
            [
              105.84075755230523,
              21.009287306649163
            ],
            [
              105.84074588595941,
              21.009312969790603
            ],
            [
              105.8407500015221,
              21.00932714904432
            ],
            [
              105.84076707049303,
              21.00935755846283
            ],
            [
              105.84078107688441,
              21.00937854251213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.8_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5034",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009161546492255,
          "to_latitude": 21.00937854251213,
          "to_longitude": 105.84078107688441,
          "from_longitude": 105.84078291014248
        }
      },
      {
        "id": 5028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84087355588322,
            21.008441392670075,
            105.84119292565056,
            21.008880595548195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84087355588322,
              21.008491577342912
            ],
            [
              105.84088107705053,
              21.00849091785756
            ],
            [
              105.84088290282368,
              21.008490765223293
            ],
            [
              105.84089030064779,
              21.00850259613171
            ],
            [
              105.84091901772699,
              21.00849244469832
            ],
            [
              105.84098032583832,
              21.00846810061546
            ],
            [
              105.84099400424884,
              21.008462669673047
            ],
            [
              105.84101634467177,
              21.008469115771703
            ],
            [
              105.8410662682831,
              21.008454633322827
            ],
            [
              105.84108851279625,
              21.00845117603259
            ],
            [
              105.84116701738198,
              21.008441392670075
            ],
            [
              105.84118450395513,
              21.00852364957021
            ],
            [
              105.84118431014161,
              21.008639554060597
            ],
            [
              105.84118635561589,
              21.00869677855527
            ],
            [
              105.84119292565056,
              21.008880595548195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT85.6_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5035",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008491577342912,
          "to_latitude": 21.008880595548195,
          "to_longitude": 105.84119292565056,
          "from_longitude": 105.84087355588322
        }
      },
      {
        "id": 5029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84087209634022,
            21.00834454294663,
            105.84115964793212,
            21.008493989562346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84087209634022,
              21.008483961342254
            ],
            [
              105.84087902606228,
              21.00848366606916
            ],
            [
              105.84088108161413,
              21.00848366494441
            ],
            [
              105.84088677614677,
              21.008493989562346
            ],
            [
              105.8409012411024,
              21.00849039551169
            ],
            [
              105.84093937079089,
              21.008474501831515
            ],
            [
              105.84099952473649,
              21.008452408860805
            ],
            [
              105.84100536905498,
              21.008453760105
            ],
            [
              105.84101608588169,
              21.008456237073347
            ],
            [
              105.84107136049865,
              21.008444160560916
            ],
            [
              105.84115034274978,
              21.008436538067304
            ],
            [
              105.84115964793212,
              21.00834454294663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT85.5_Ngõ 452, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5036",
          "diaChiLapD": "Ngõ 452, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008483961342254,
          "to_latitude": 21.00834454294663,
          "to_longitude": 105.84115964793212,
          "from_longitude": 105.84087209634022
        }
      },
      {
        "id": 5030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84092064859051,
            21.008343546446735,
            105.84100536905498,
            21.008453760105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84100536905498,
              21.008453760105
            ],
            [
              105.84097914045265,
              21.008404882584227
            ],
            [
              105.84095897935458,
              21.008394945587813
            ],
            [
              105.84093482446745,
              21.008374018924258
            ],
            [
              105.84092064859051,
              21.008343546446735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.5_Ngõ 452, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5037",
          "diaChiLapD": "Ngõ 452, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008453760105,
          "to_latitude": 21.008343546446735,
          "to_longitude": 105.84092064859051,
          "from_longitude": 105.84100536905498
        }
      },
      {
        "id": 5031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84104227962116,
            21.00869677855527,
            105.84118635561589,
            21.008705681111763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84118635561589,
              21.00869677855527
            ],
            [
              105.84104227962116,
              21.008705681111763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.6_Ngõ 430, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5038",
          "diaChiLapD": "Ngõ 430, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00869677855527,
          "to_latitude": 21.008705681111763,
          "to_longitude": 105.84104227962116,
          "from_longitude": 105.84118635561589
        }
      },
      {
        "id": 5032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84087444665964,
            21.008494605147494,
            105.84088751884467,
            21.00858515283001
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84087444665964,
              21.00849622364299
            ],
            [
              105.84088430080676,
              21.008494605147494
            ],
            [
              105.84088413488429,
              21.00853125158092
            ],
            [
              105.84088113249214,
              21.00855796843446
            ],
            [
              105.84088751884467,
              21.00858515283001
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.7_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5039",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00849622364299,
          "to_latitude": 21.00858515283001,
          "to_longitude": 105.84088751884467,
          "from_longitude": 105.84087444665964
        }
      },
      {
        "id": 5033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84078532948655,
            21.00858515283001,
            105.84089086351071,
            21.00866342083958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84088751884467,
              21.00858515283001
            ],
            [
              105.84088987829078,
              21.008607110293998
            ],
            [
              105.84089086351071,
              21.008614706057518
            ],
            [
              105.84088672973716,
              21.008616499512975
            ],
            [
              105.84084516455547,
              21.008634528218174
            ],
            [
              105.84078532948655,
              21.00866342083958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.7_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5040",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00858515283001,
          "to_latitude": 21.00866342083958,
          "to_longitude": 105.84078532948655,
          "from_longitude": 105.84088751884467
        }
      },
      {
        "id": 5034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84083048254867,
            21.008616499512975,
            105.84088960177255,
            21.008648827447217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84088672973716,
              21.008616499512975
            ],
            [
              105.84088960177255,
              21.00862076028157
            ],
            [
              105.84083048254867,
              21.008648827447217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.7_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5041",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008616499512975,
          "to_latitude": 21.008648827447217,
          "to_longitude": 105.84083048254867,
          "from_longitude": 105.84088672973716
        }
      },
      {
        "id": 5035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83973681118071,
            21.00831993300566,
            105.840092681209,
            21.008819236992142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8399983179105,
              21.008819236992142
            ],
            [
              105.83998803698806,
              21.008811809042136
            ],
            [
              105.83998614748208,
              21.008810715531922
            ],
            [
              105.83997732339076,
              21.008805615225192
            ],
            [
              105.8398752377654,
              21.008719512516738
            ],
            [
              105.83981650598493,
              21.00866939070742
            ],
            [
              105.8398090188072,
              21.00865876780233
            ],
            [
              105.83980187798964,
              21.008653486663185
            ],
            [
              105.83979512807142,
              21.008647928111213
            ],
            [
              105.83976872136755,
              21.008622678720958
            ],
            [
              105.83975722126594,
              21.00861413225807
            ],
            [
              105.83979370698884,
              21.008585131221217
            ],
            [
              105.83973681118071,
              21.008514148481837
            ],
            [
              105.83989529188067,
              21.008408065768123
            ],
            [
              105.840092681209,
              21.00831993300566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.4_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.5042",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008819236992142,
          "to_latitude": 21.00831993300566,
          "to_longitude": 105.840092681209,
          "from_longitude": 105.8399983179105
        }
      },
      {
        "id": 5036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83938753133641,
            21.00851830832665,
            105.83999619714004,
            21.008821334811998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83999619714004,
              21.008821334811998
            ],
            [
              105.83998550949511,
              21.008813935062996
            ],
            [
              105.83998314970808,
              21.008813543872943
            ],
            [
              105.8399699528351,
              21.00881136088729
            ],
            [
              105.8399402454648,
              21.008785188464383
            ],
            [
              105.83986611891646,
              21.00872103790632
            ],
            [
              105.83981233081475,
              21.00867753942836
            ],
            [
              105.83980228460791,
              21.008666157798153
            ],
            [
              105.83979669386817,
              21.00865494566359
            ],
            [
              105.83979013475746,
              21.00865013765252
            ],
            [
              105.83974774416627,
              21.008612314769003
            ],
            [
              105.83978466286248,
              21.008578866830135
            ],
            [
              105.83976945558784,
              21.00856017106489
            ],
            [
              105.83973158542653,
              21.00851830832665
            ],
            [
              105.83957396190534,
              21.00862720743416
            ],
            [
              105.83938753133641,
              21.008783376037716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.5_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.5043",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008821334811998,
          "to_latitude": 21.008783376037716,
          "to_longitude": 105.83938753133641,
          "from_longitude": 105.83999619714004
        }
      },
      {
        "id": 5037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84055965127105,
            21.00907883247001,
            105.84071680415666,
            21.00917607314324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84071680415666,
              21.00907883247001
            ],
            [
              105.84055965127105,
              21.00917607314324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.7_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5044",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00907883247001,
          "to_latitude": 21.00917607314324,
          "to_longitude": 105.84055965127105,
          "from_longitude": 105.84071680415666
        }
      },
      {
        "id": 5038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83997567715788,
            21.0085629797424,
            105.84028368511616,
            21.008863786483406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027787989255,
              21.00856770702652
            ],
            [
              105.84028118969452,
              21.008563917012328
            ],
            [
              105.84028204822329,
              21.0085629797424
            ],
            [
              105.84028368511616,
              21.008564310238285
            ],
            [
              105.84027935709588,
              21.008569034698038
            ],
            [
              105.84026049114888,
              21.008585454535087
            ],
            [
              105.84020581332543,
              21.00864126105995
            ],
            [
              105.8402013131976,
              21.008645999012707
            ],
            [
              105.84017543187677,
              21.008678172290683
            ],
            [
              105.84004772776433,
              21.00879885389821
            ],
            [
              105.83997567715788,
              21.008863786483406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5045",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00856770702652,
          "to_latitude": 21.008863786483406,
          "to_longitude": 105.83997567715788,
          "from_longitude": 105.84027787989255
        }
      },
      {
        "id": 5039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83984555495175,
            21.008842993113,
            105.83995193764704,
            21.008939346663194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83984555495175,
              21.008939346663194
            ],
            [
              105.83987673235512,
              21.00891263438269
            ],
            [
              105.83994750461953,
              21.008847520276873
            ],
            [
              105.83995193764704,
              21.008842993113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.6_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5046",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008939346663194,
          "to_latitude": 21.008842993113,
          "to_longitude": 105.83995193764704,
          "from_longitude": 105.83984555495175
        }
      },
      {
        "id": 5040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83995193764704,
            21.008681310355076,
            105.8401181149703,
            21.008842993113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83995193764704,
              21.008842993113
            ],
            [
              105.83997253359131,
              21.008823560645315
            ],
            [
              105.8399797739842,
              21.008816729046668
            ],
            [
              105.83998314970808,
              21.008813543872943
            ],
            [
              105.83998614748208,
              21.008810715531922
            ],
            [
              105.83999039009998,
              21.00880671227386
            ],
            [
              105.84002238579755,
              21.008776523934216
            ],
            [
              105.84006373618926,
              21.008735395620693
            ],
            [
              105.8401181149703,
              21.008681310355076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.6_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5047",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008842993113,
          "to_latitude": 21.008681310355076,
          "to_longitude": 105.8401181149703,
          "from_longitude": 105.83995193764704
        }
      },
      {
        "id": 5041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013519589574,
            21.008487121308733,
            105.8402324395115,
            21.008551953354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8402324395115,
              21.008551953354
            ],
            [
              105.84023241634081,
              21.008551938113605
            ],
            [
              105.84013519589574,
              21.008487121308733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5048",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008551953354,
          "to_latitude": 21.008487121308733,
          "to_longitude": 105.84013519589574,
          "from_longitude": 105.8402324395115
        }
      },
      {
        "id": 5042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84027335320665,
            21.00833774051892,
            105.84045185785074,
            21.00850741115365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027335320665,
              21.00850741115365
            ],
            [
              105.84030091454588,
              21.00847689240193
            ],
            [
              105.84030332069989,
              21.008474227750824
            ],
            [
              105.84039437213319,
              21.008388341235293
            ],
            [
              105.84045185785074,
              21.00833774051892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5049",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00850741115365,
          "to_latitude": 21.00833774051892,
          "to_longitude": 105.84045185785074,
          "from_longitude": 105.84027335320665
        }
      },
      {
        "id": 5043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998735211874,
            21.00891966652187,
            105.84016249387396,
            21.00898872492671
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83998735211874,
              21.00893552164258
            ],
            [
              105.84004284338208,
              21.00898872492671
            ],
            [
              105.84009955900181,
              21.008940363604577
            ],
            [
              105.84012383094533,
              21.00891966652187
            ],
            [
              105.84015717353543,
              21.008947415154207
            ],
            [
              105.84016249387396,
              21.008952073008423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.2_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5050",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00893552164258,
          "to_latitude": 21.008952073008423,
          "to_longitude": 105.84016249387396,
          "from_longitude": 105.83998735211874
        }
      },
      {
        "id": 5044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83997803643938,
            21.00894218297522,
            105.8401710094047,
            21.00900567989093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83997803643938,
              21.00894518877106
            ],
            [
              105.84002348488508,
              21.008984701784428
            ],
            [
              105.84004730818569,
              21.00900567989093
            ],
            [
              105.84012895885854,
              21.00894218297522
            ],
            [
              105.8401423237101,
              21.00895512342361
            ],
            [
              105.84015802032748,
              21.008970321258186
            ],
            [
              105.8401710094047,
              21.00898297543081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT83.3_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5051",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00894518877106,
          "to_latitude": 21.00898297543081,
          "to_longitude": 105.8401710094047,
          "from_longitude": 105.83997803643938
        }
      },
      {
        "id": 5045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84018383771681,
            21.009166087725255,
            105.84022928491758,
            21.009243486883356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019189976597,
              21.009166087725255
            ],
            [
              105.84022928491758,
              21.009210264095195
            ],
            [
              105.84018383771681,
              21.009243486883356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D15_KT83.3_Ngách 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5052",
          "diaChiLapD": "Ngách 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 15,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009166087725255,
          "to_latitude": 21.009243486883356,
          "to_longitude": 105.84018383771681,
          "from_longitude": 105.84019189976597
        }
      },
      {
        "id": 5046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84016249387396,
            21.008952073008423,
            105.8402573244099,
            21.00905022604595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84016249387396,
              21.008952073008423
            ],
            [
              105.84022329881223,
              21.009011995276296
            ],
            [
              105.8402573244099,
              21.00905022604595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.2_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5053",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008952073008423,
          "to_latitude": 21.00905022604595,
          "to_longitude": 105.8402573244099,
          "from_longitude": 105.84016249387396
        }
      },
      {
        "id": 5047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401710094047,
            21.00898297543081,
            105.84025926331134,
            21.00906821231287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401710094047,
              21.00898297543081
            ],
            [
              105.84021563072015,
              21.00902644588732
            ],
            [
              105.84025299376248,
              21.00906307231094
            ],
            [
              105.84025926331134,
              21.00906821231287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT83.3_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5054",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00898297543081,
          "to_latitude": 21.00906821231287,
          "to_longitude": 105.84025926331134,
          "from_longitude": 105.8401710094047
        }
      },
      {
        "id": 5048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84027246366398,
            21.009080458982986,
            105.84036771143401,
            21.009172876225346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027246366398,
              21.009080458982986
            ],
            [
              105.84031138637879,
              21.00911647253115
            ],
            [
              105.84033723786753,
              21.009138562384255
            ],
            [
              105.84034889253127,
              21.009150991906356
            ],
            [
              105.840350817229,
              21.009153043559465
            ],
            [
              105.84036771143401,
              21.009172876225346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.4_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5055",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009080458982986,
          "to_latitude": 21.009172876225346,
          "to_longitude": 105.84036771143401,
          "from_longitude": 105.84027246366398
        }
      },
      {
        "id": 5049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84028025254476,
            21.00907297795486,
            105.84040721027104,
            21.009196850538494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84028025254476,
              21.00907297795486
            ],
            [
              105.84028945242592,
              21.009081625157545
            ],
            [
              105.84031554245293,
              21.009104916931154
            ],
            [
              105.84032418263972,
              21.00910980767621
            ],
            [
              105.84034604574192,
              21.009127193360104
            ],
            [
              105.84036285767041,
              21.009142984504393
            ],
            [
              105.84036480509245,
              21.009145315141403
            ],
            [
              105.84039212321592,
              21.009178005208536
            ],
            [
              105.84040721027104,
              21.009196850538494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.5_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5056",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00907297795486,
          "to_latitude": 21.009196850538494,
          "to_longitude": 105.84040721027104,
          "from_longitude": 105.84028025254476
        }
      },
      {
        "id": 5050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008973471454,
            21.009293445052254,
            105.84048034262493,
            21.00965152781257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84048034262493,
              21.009293445052254
            ],
            [
              105.84047995467715,
              21.00929373419365
            ],
            [
              105.84047617669671,
              21.009296542912875
            ],
            [
              105.84048023648606,
              21.009309184258527
            ],
            [
              105.8404613693793,
              21.00933519726868
            ],
            [
              105.84039248816376,
              21.00939652496206
            ],
            [
              105.8403462718057,
              21.009433078166882
            ],
            [
              105.84027958795909,
              21.0094914016801
            ],
            [
              105.84021675231475,
              21.009539022831284
            ],
            [
              105.8401992076614,
              21.009556089170573
            ],
            [
              105.8401235395957,
              21.009621286262597
            ],
            [
              105.84011690127203,
              21.009632575919824
            ],
            [
              105.84008973471454,
              21.00965152781257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.6_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5057",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009293445052254,
          "to_latitude": 21.00965152781257,
          "to_longitude": 105.84008973471454,
          "from_longitude": 105.84048034262493
        }
      },
      {
        "id": 5051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009382595895,
            21.009295559973484,
            105.84048908258097,
            21.00965518825358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84048170978173,
              21.009295559973484
            ],
            [
              105.84048121684351,
              21.009295823439928
            ],
            [
              105.84047889021913,
              21.009297747960158
            ],
            [
              105.84048908258097,
              21.009321295255226
            ],
            [
              105.84046756539227,
              21.00934798432164
            ],
            [
              105.84040053187508,
              21.009406374639568
            ],
            [
              105.84033321521075,
              21.009455952668404
            ],
            [
              105.84028942663284,
              21.00949714095819
            ],
            [
              105.84024358549803,
              21.009532001447685
            ],
            [
              105.840228109653,
              21.009545246871582
            ],
            [
              105.84022405764065,
              21.00955185589967
            ],
            [
              105.84013972093884,
              21.00961343954891
            ],
            [
              105.84013341503034,
              21.009623626535223
            ],
            [
              105.84009382595895,
              21.00965518825358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.7_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5058",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009295559973484,
          "to_latitude": 21.00965518825358,
          "to_longitude": 105.84009382595895,
          "from_longitude": 105.84048170978173
        }
      },
      {
        "id": 5052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036771143401,
            21.009172876225346,
            105.84047617573444,
            21.009305300579676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84036771143401,
              21.009172876225346
            ],
            [
              105.84038144330646,
              21.009188996899983
            ],
            [
              105.84039011214212,
              21.009199173168245
            ],
            [
              105.840427303874,
              21.009241434709207
            ],
            [
              105.84045861474095,
              21.009280458324472
            ],
            [
              105.84047617573444,
              21.009305300579676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.4_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5059",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009172876225346,
          "to_latitude": 21.009305300579676,
          "to_longitude": 105.84047617573444,
          "from_longitude": 105.84036771143401
        }
      },
      {
        "id": 5053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84040721027104,
            21.009196850538494,
            105.84049234474267,
            21.009310887548388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84040721027104,
              21.009196850538494
            ],
            [
              105.84043976169248,
              21.0092365120301
            ],
            [
              105.84047064653251,
              21.009271945624402
            ],
            [
              105.84049234474267,
              21.009310887548388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.5_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5060",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009196850538494,
          "to_latitude": 21.009310887548388,
          "to_longitude": 105.84049234474267,
          "from_longitude": 105.84040721027104
        }
      },
      {
        "id": 5054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83970294050559,
            21.008894246193822,
            105.83995482155123,
            21.00933255988648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83994304906466,
              21.008894246193822
            ],
            [
              105.83994254193522,
              21.00889472379292
            ],
            [
              105.839922155347,
              21.00891392670814
            ],
            [
              105.83981272038241,
              21.009009744337263
            ],
            [
              105.83970294050559,
              21.009118722676803
            ],
            [
              105.83976340595848,
              21.009179363043312
            ],
            [
              105.83983304324121,
              21.00923040020163
            ],
            [
              105.83995482155123,
              21.00933255988648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT83.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5061",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008894246193822,
          "to_latitude": 21.00933255988648,
          "to_longitude": 105.83995482155123,
          "from_longitude": 105.83994304906466
        }
      },
      {
        "id": 5055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967592687192,
            21.00911016334071,
            105.84000104497329,
            21.009408593659646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83967592687192,
              21.00911016334071
            ],
            [
              105.83967905343741,
              21.00911350248865
            ],
            [
              105.8396973424645,
              21.00913302124508
            ],
            [
              105.8398018359417,
              21.00922256790456
            ],
            [
              105.83983387751148,
              21.00923990703659
            ],
            [
              105.83990805738706,
              21.009300479663203
            ],
            [
              105.83995141662773,
              21.00934479280184
            ],
            [
              105.84000104497329,
              21.009408593659646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.9_Ngõ 70, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5062",
          "diaChiLapD": "Ngõ 70, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00911016334071,
          "to_latitude": 21.009408593659646,
          "to_longitude": 105.84000104497329,
          "from_longitude": 105.83967592687192
        }
      },
      {
        "id": 5056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83968912599178,
            21.008955553427718,
            105.83982666174701,
            21.009085758121355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83968912599178,
              21.009085758121355
            ],
            [
              105.8397020818357,
              21.009073572017623
            ],
            [
              105.83970701634513,
              21.0090689303855
            ],
            [
              105.83971028114078,
              21.009065859570597
            ],
            [
              105.8397141736355,
              21.009062198562397
            ],
            [
              105.8397728949563,
              21.00900696565303
            ],
            [
              105.83982666174701,
              21.008955553427718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.8_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5063",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009085758121355,
          "to_latitude": 21.008955553427718,
          "to_longitude": 105.83982666174701,
          "from_longitude": 105.83968912599178
        }
      },
      {
        "id": 5057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83950958727029,
            21.00902295487305,
            105.83962603874777,
            21.009148757693712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83962603874777,
              21.009148757693712
            ],
            [
              105.83950958727029,
              21.00902295487305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.8_Ngõ 37, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5064",
          "diaChiLapD": "Ngõ 37, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009148757693712,
          "to_latitude": 21.00902295487305,
          "to_longitude": 105.83950958727029,
          "from_longitude": 105.83962603874777
        }
      },
      {
        "id": 5058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955318741897,
            21.009085758121355,
            105.83968912599178,
            21.009222616108747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83968912599178,
              21.009085758121355
            ],
            [
              105.83962603874777,
              21.009148757693712
            ],
            [
              105.83955318741897,
              21.009222616108747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.8_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5065",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009085758121355,
          "to_latitude": 21.009222616108747,
          "to_longitude": 105.83955318741897,
          "from_longitude": 105.83968912599178
        }
      },
      {
        "id": 5059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954376819096,
            21.008894198700705,
            105.8397116345197,
            21.009076171327642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397116345197,
              21.009076171327642
            ],
            [
              105.83970928072128,
              21.009073069528018
            ],
            [
              105.83970701634513,
              21.0090689303855
            ],
            [
              105.83970335254581,
              21.00906223660399
            ],
            [
              105.83963898485419,
              21.008994220769015
            ],
            [
              105.83961939952478,
              21.008973037436018
            ],
            [
              105.8395957651296,
              21.00894747356852
            ],
            [
              105.83954376819096,
              21.008894198700705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.7_Ngõ 63, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5066",
          "diaChiLapD": "Ngõ 63, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009076171327642,
          "to_latitude": 21.008894198700705,
          "to_longitude": 105.83954376819096,
          "from_longitude": 105.8397116345197
        }
      },
      {
        "id": 5060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83961939952478,
            21.008764236155013,
            105.83971090537555,
            21.008973037436018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83961939952478,
              21.008973037436018
            ],
            [
              105.8396901834268,
              21.00890877421364
            ],
            [
              105.83966022010198,
              21.008872954761124
            ],
            [
              105.83971090537555,
              21.00881954990865
            ],
            [
              105.83965421839272,
              21.008764236155013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.7_Ngõ 63, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5067",
          "diaChiLapD": "Ngõ 63, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008973037436018,
          "to_latitude": 21.008764236155013,
          "to_longitude": 105.83965421839272,
          "from_longitude": 105.83961939952478
        }
      },
      {
        "id": 5061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395287145783,
            21.00925367589428,
            105.84006915020332,
            21.009902371708982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395287145783,
              21.00925367589428
            ],
            [
              105.83953047512904,
              21.0092554456575
            ],
            [
              105.83955593814156,
              21.009281012252327
            ],
            [
              105.83959353599418,
              21.009314236121078
            ],
            [
              105.83963837147276,
              21.009365047983486
            ],
            [
              105.83972432082182,
              21.009451009156546
            ],
            [
              105.83973702340488,
              21.00946867315414
            ],
            [
              105.83975064798356,
              21.009486941373986
            ],
            [
              105.83975302423381,
              21.009499553110828
            ],
            [
              105.83977434066783,
              21.00952530542261
            ],
            [
              105.83979797365437,
              21.009554548099707
            ],
            [
              105.83981902462101,
              21.009587002736502
            ],
            [
              105.83983249206244,
              21.009620843311392
            ],
            [
              105.83984781129224,
              21.00964422444053
            ],
            [
              105.83990918524907,
              21.009726207062517
            ],
            [
              105.83999429446358,
              21.009819935444387
            ],
            [
              105.84006915020332,
              21.009902371708982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.10_Ngõ 78, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5068",
          "diaChiLapD": "Ngõ 78, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00925367589428,
          "to_latitude": 21.009902371708982,
          "to_longitude": 105.84006915020332,
          "from_longitude": 105.8395287145783
        }
      },
      {
        "id": 5062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952238906419,
            21.00925984812343,
            105.84015097988772,
            21.009937803210292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83952238906419,
              21.00925984812343
            ],
            [
              105.83952351625587,
              21.009261540624372
            ],
            [
              105.83953568571323,
              21.0092769347561
            ],
            [
              105.8395412925104,
              21.009284139212532
            ],
            [
              105.83956211426617,
              21.00930229245761
            ],
            [
              105.83964135174091,
              21.009381434026363
            ],
            [
              105.83969716319127,
              21.009439418412367
            ],
            [
              105.83969994565716,
              21.009442286871195
            ],
            [
              105.83973066694143,
              21.009473955867655
            ],
            [
              105.83973947396332,
              21.009488203222915
            ],
            [
              105.83974160011802,
              21.009504252059433
            ],
            [
              105.83974619689575,
              21.009508870141996
            ],
            [
              105.83976954824034,
              21.009534196026063
            ],
            [
              105.8397882779781,
              21.00956026277784
            ],
            [
              105.83980249929127,
              21.009580998366502
            ],
            [
              105.83981814480597,
              21.00960218305334
            ],
            [
              105.83982067179318,
              21.009626600993023
            ],
            [
              105.83982807294385,
              21.00963937399339
            ],
            [
              105.83988179006494,
              21.009706213923813
            ],
            [
              105.8399501070921,
              21.009777379569385
            ],
            [
              105.83999036171343,
              21.009823108916663
            ],
            [
              105.84009132413111,
              21.009937803210292
            ],
            [
              105.84015097988772,
              21.00989206205632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.11_Ngõ 78, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5069",
          "diaChiLapD": "Ngõ 78, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00925984812343,
          "to_latitude": 21.00989206205632,
          "to_longitude": 105.84015097988772,
          "from_longitude": 105.83952238906419
        }
      },
      {
        "id": 5063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943322265876,
            21.009244025549805,
            105.83953243601972,
            21.00934311547927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943322265876,
              21.00934311547927
            ],
            [
              105.83945659517912,
              21.009319630155165
            ],
            [
              105.83946458724027,
              21.009311663129285
            ],
            [
              105.83947985393553,
              21.009296443453252
            ],
            [
              105.83953243601972,
              21.009244025549805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.12_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5070",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00934311547927,
          "to_latitude": 21.009244025549805,
          "to_longitude": 105.83953243601972,
          "from_longitude": 105.83943322265876
        }
      },
      {
        "id": 5064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936763420141,
            21.008987581229512,
            105.83953243601972,
            21.009244025549805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953243601972,
              21.009244025549805
            ],
            [
              105.83943158046074,
              21.009153428390057
            ],
            [
              105.83939344008101,
              21.009117264453618
            ],
            [
              105.83944132714444,
              21.009075680430044
            ],
            [
              105.83936763420141,
              21.008987581229512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.12_Ngõ 73, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5071",
          "diaChiLapD": "Ngõ 73, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009244025549805,
          "to_latitude": 21.008987581229512,
          "to_longitude": 105.83936763420141,
          "from_longitude": 105.83953243601972
        }
      },
      {
        "id": 5065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932442581742,
            21.00934311547927,
            105.83943322265876,
            21.009450462932143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943322265876,
              21.00934311547927
            ],
            [
              105.83940794700413,
              21.009369778699238
            ],
            [
              105.83932442581742,
              21.009450462932143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.12_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5072",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00934311547927,
          "to_latitude": 21.009450462932143,
          "to_longitude": 105.83932442581742,
          "from_longitude": 105.83943322265876
        }
      },
      {
        "id": 5066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389750121725,
            21.00881086094523,
            105.83934893623217,
            21.009473497954296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932294253466,
              21.009473497954296
            ],
            [
              105.83931842758925,
              21.009470781646847
            ],
            [
              105.83929445816844,
              21.009456349826557
            ],
            [
              105.83926437036523,
              21.009430610002212
            ],
            [
              105.8389750121725,
              21.009154909516297
            ],
            [
              105.83934893623217,
              21.00881086094523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.13_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.5073",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 104,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009473497954296,
          "to_latitude": 21.00881086094523,
          "to_longitude": 105.83934893623217,
          "from_longitude": 105.83932294253466
        }
      },
      {
        "id": 5067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862678945697,
            21.009155696987072,
            105.83929116121143,
            21.009583980064868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83922044712693,
              21.009583980064868
            ],
            [
              105.83921610735355,
              21.00958025127955
            ],
            [
              105.83920251095896,
              21.009568560156644
            ],
            [
              105.83929116121143,
              21.00946811884351
            ],
            [
              105.83923210321991,
              21.00941042762134
            ],
            [
              105.83910467933039,
              21.009286655794444
            ],
            [
              105.83896862863558,
              21.009155696987072
            ],
            [
              105.83862678945697,
              21.00952136970634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.14_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.5074",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 119,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009583980064868,
          "to_latitude": 21.00952136970634,
          "to_longitude": 105.83862678945697,
          "from_longitude": 105.83922044712693
        }
      },
      {
        "id": 5068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83930996636434,
            21.009284139212532,
            105.8395412925104,
            21.009524535425136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395412925104,
              21.009284139212532
            ],
            [
              105.8394859496761,
              21.009340493441712
            ],
            [
              105.83930996636434,
              21.009524535425136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.11_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5075",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009284139212532,
          "to_latitude": 21.009524535425136,
          "to_longitude": 105.83930996636434,
          "from_longitude": 105.8395412925104
        }
      },
      {
        "id": 5069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933452427956,
            21.009578824535748,
            105.83955595595775,
            21.00973665543418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933452427956,
              21.009578824535748
            ],
            [
              105.83934611271997,
              21.009589569037278
            ],
            [
              105.8393654092618,
              21.009604171124202
            ],
            [
              105.83943133682051,
              21.009651640940394
            ],
            [
              105.83944436873672,
              21.00965905715819
            ],
            [
              105.83945670684048,
              21.009666078475636
            ],
            [
              105.8394679103785,
              21.00966957378195
            ],
            [
              105.83951315356477,
              21.009703157367465
            ],
            [
              105.83955595595775,
              21.00973665543418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.3_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5076",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009578824535748,
          "to_latitude": 21.00973665543418,
          "to_longitude": 105.83955595595775,
          "from_longitude": 105.83933452427956
        }
      },
      {
        "id": 5070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955595595775,
            21.00973665543418,
            105.83973293780667,
            21.00989998924602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83955595595775,
              21.00973665543418
            ],
            [
              105.83956138624018,
              21.00974119082878
            ],
            [
              105.83963978280929,
              21.009806673462293
            ],
            [
              105.8396704827214,
              21.009835199351382
            ],
            [
              105.83969801027058,
              21.009861828762933
            ],
            [
              105.83973293780667,
              21.00989998924602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.3_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5077",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00973665543418,
          "to_latitude": 21.00989998924602,
          "to_longitude": 105.83973293780667,
          "from_longitude": 105.83955595595775
        }
      },
      {
        "id": 5071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83973168047068,
            21.00991623375939,
            105.83981542174948,
            21.00999631789725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83973168047068,
              21.009917839549693
            ],
            [
              105.83973318719549,
              21.009917565663176
            ],
            [
              105.83973988802506,
              21.00991623375939
            ],
            [
              105.83975123712628,
              21.009927515024433
            ],
            [
              105.83976547147972,
              21.009939747596754
            ],
            [
              105.83979698623128,
              21.00996622183048
            ],
            [
              105.83980488473274,
              21.00997367328873
            ],
            [
              105.83981259073992,
              21.009982307113937
            ],
            [
              105.83981542174948,
              21.009991440094268
            ],
            [
              105.83981461027571,
              21.00999631789725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.5_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5078",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009917839549693,
          "to_latitude": 21.00999631789725,
          "to_longitude": 105.83981461027571,
          "from_longitude": 105.83973168047068
        }
      },
      {
        "id": 5072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83975372126564,
            21.00999631789725,
            105.83981461027571,
            21.010079361862548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83981461027571,
              21.00999631789725
            ],
            [
              105.8398130547701,
              21.010000250083507
            ],
            [
              105.83979003892378,
              21.010033333163463
            ],
            [
              105.83975783813901,
              21.01007196130053
            ],
            [
              105.83975372126564,
              21.010079361862548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.5_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5079",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00999631789725,
          "to_latitude": 21.010079361862548,
          "to_longitude": 105.83975372126564,
          "from_longitude": 105.83981461027571
        }
      },
      {
        "id": 5073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933713732029,
            21.009600069033215,
            105.8395314296738,
            21.009743221846765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933713732029,
              21.009600069033215
            ],
            [
              105.83938359913039,
              21.009633815547108
            ],
            [
              105.83944322894594,
              21.009674197208216
            ],
            [
              105.83947032014045,
              21.009694883788967
            ],
            [
              105.8394939859035,
              21.009713313848287
            ],
            [
              105.83950376691037,
              21.009721298872336
            ],
            [
              105.8395314296738,
              21.009743221846765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.2_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5080",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009600069033215,
          "to_latitude": 21.009743221846765,
          "to_longitude": 105.8395314296738,
          "from_longitude": 105.83933713732029
        }
      },
      {
        "id": 5074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395314296738,
            21.009743221846765,
            105.83972598088552,
            21.00991056149451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395314296738,
              21.009743221846765
            ],
            [
              105.8395530993449,
              21.00975698033346
            ],
            [
              105.83958382669272,
              21.009776490118547
            ],
            [
              105.83958600061086,
              21.009777870358135
            ],
            [
              105.8395900308543,
              21.00978042917876
            ],
            [
              105.83962144644042,
              21.00980500806876
            ],
            [
              105.83971805430123,
              21.009901700237783
            ],
            [
              105.83972598088552,
              21.00991056149451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.2_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5081",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009743221846765,
          "to_latitude": 21.00991056149451,
          "to_longitude": 105.83972598088552,
          "from_longitude": 105.8395314296738
        }
      },
      {
        "id": 5075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83972942278525,
            21.009923148101283,
            105.83980479661639,
            21.010066280776286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83972942278525,
              21.009923148101283
            ],
            [
              105.83977237163016,
              21.009964231567494
            ],
            [
              105.83980479661639,
              21.009994878719706
            ],
            [
              105.83976501239457,
              21.010046082843054
            ],
            [
              105.83974926563529,
              21.010066280776286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.6_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5082",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009923148101283,
          "to_latitude": 21.010066280776286,
          "to_longitude": 105.83974926563529,
          "from_longitude": 105.83972942278525
        }
      },
      {
        "id": 5076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83940124097428,
            21.00977372807547,
            105.83960351424334,
            21.010214542573376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958931521838,
              21.00977372807547
            ],
            [
              105.83958859307225,
              21.00977463034668
            ],
            [
              105.83958600061086,
              21.009777870358135
            ],
            [
              105.83958487249447,
              21.00977927953474
            ],
            [
              105.83956712972467,
              21.009798604849546
            ],
            [
              105.83952118406292,
              21.009864685615298
            ],
            [
              105.83949249294824,
              21.009910831086064
            ],
            [
              105.83948574597673,
              21.009924374800725
            ],
            [
              105.83945163005058,
              21.009965936067086
            ],
            [
              105.83943221274869,
              21.00998959065441
            ],
            [
              105.83940131454332,
              21.010027232302495
            ],
            [
              105.83940124097428,
              21.010027321181376
            ],
            [
              105.83945683147566,
              21.010071746620955
            ],
            [
              105.83947554157243,
              21.010087766938042
            ],
            [
              105.8394767387477,
              21.0101002472049
            ],
            [
              105.83947685987923,
              21.010103673440355
            ],
            [
              105.83960351424334,
              21.010214542573376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT80.4_Ngách 88/11, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5083",
          "diaChiLapD": "Ngách 88/11, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00977372807547,
          "to_latitude": 21.010214542573376,
          "to_longitude": 105.83960351424334,
          "from_longitude": 105.83958931521838
        }
      },
      {
        "id": 5077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945163005058,
            21.009965936067086,
            105.83960087679253,
            21.010016135654592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945163005058,
              21.009965936067086
            ],
            [
              105.83947221167696,
              21.009981728572317
            ],
            [
              105.83952171405124,
              21.010016135654592
            ],
            [
              105.83955457633346,
              21.009976243395755
            ],
            [
              105.83960087679253,
              21.010007082257932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.4_Ngách 88/11, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5084",
          "diaChiLapD": "Ngách 88/11, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009965936067086,
          "to_latitude": 21.010007082257932,
          "to_longitude": 105.83960087679253,
          "from_longitude": 105.83945163005058
        }
      },
      {
        "id": 5078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936110285946,
            21.009926397787364,
            105.83943221274869,
            21.00998959065441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943221274869,
              21.00998959065441
            ],
            [
              105.83936110285946,
              21.009926397787364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.4_Ngách 88/11, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5085",
          "diaChiLapD": "Ngách 88/11, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00998959065441,
          "to_latitude": 21.009926397787364,
          "to_longitude": 105.83936110285946,
          "from_longitude": 105.83943221274869
        }
      },
      {
        "id": 5079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944436873672,
            21.00960051253075,
            105.83956649389735,
            21.00965905715819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83944436873672,
              21.00965905715819
            ],
            [
              105.83946345777449,
              21.009638458894013
            ],
            [
              105.83949166277846,
              21.009607730253002
            ],
            [
              105.8395249954133,
              21.00960051253075
            ],
            [
              105.83956649389735,
              21.00963185651617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.3_Ngách 88/4, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5086",
          "diaChiLapD": "Ngách 88/4, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00965905715819,
          "to_latitude": 21.00963185651617,
          "to_longitude": 105.83956649389735,
          "from_longitude": 105.83944436873672
        }
      },
      {
        "id": 5080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83975990178051,
            21.010141114611162,
            105.83991986493703,
            21.010350169912012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83975990178051,
              21.010141114611162
            ],
            [
              105.83977165548585,
              21.010154219293508
            ],
            [
              105.83979344463312,
              21.010176935335075
            ],
            [
              105.83980579599564,
              21.01018631487818
            ],
            [
              105.83980917344596,
              21.01018875949561
            ],
            [
              105.8398198608512,
              21.01019455332046
            ],
            [
              105.8398395387993,
              21.010204409732985
            ],
            [
              105.83985614921224,
              21.010220274175953
            ],
            [
              105.83987503286184,
              21.0102382472645
            ],
            [
              105.83988761940115,
              21.010249943313923
            ],
            [
              105.83989408294235,
              21.010258800944154
            ],
            [
              105.83991533131648,
              21.01029024117105
            ],
            [
              105.83991640562868,
              21.010294685150797
            ],
            [
              105.83991986493703,
              21.010309002244135
            ],
            [
              105.83991654460893,
              21.010325181830375
            ],
            [
              105.8399043894659,
              21.010350169912012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.7_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5087",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010141114611162,
          "to_latitude": 21.010350169912012,
          "to_longitude": 105.8399043894659,
          "from_longitude": 105.83975990178051
        }
      },
      {
        "id": 5081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83991640562868,
            21.010216649081237,
            105.84003739530212,
            21.010294685150797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83991640562868,
              21.010294685150797
            ],
            [
              105.84003739530212,
              21.010216649081237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.7_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5088",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010294685150797,
          "to_latitude": 21.010216649081237,
          "to_longitude": 105.84003739530212,
          "from_longitude": 105.83991640562868
        }
      },
      {
        "id": 5082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83975468572449,
            21.01015251646175,
            105.83990838113478,
            21.010352877032915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83975468572449,
              21.01015251646175
            ],
            [
              105.839766693849,
              21.01016587821101
            ],
            [
              105.8397815143162,
              21.01018142814083
            ],
            [
              105.83979592346137,
              21.01019274759039
            ],
            [
              105.83982347167873,
              21.01020483767543
            ],
            [
              105.83984361568672,
              21.01022079758307
            ],
            [
              105.83985773823832,
              21.01023401521248
            ],
            [
              105.83986910469311,
              21.010245813026888
            ],
            [
              105.83988046376166,
              21.010256462878953
            ],
            [
              105.83988824870356,
              21.010264931923505
            ],
            [
              105.83989419871041,
              21.010273123702454
            ],
            [
              105.83990061166406,
              21.010291158340134
            ],
            [
              105.83990648432712,
              21.010303747395824
            ],
            [
              105.83990838113478,
              21.010313015049316
            ],
            [
              105.83988770504313,
              21.010352877032915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.8_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5089",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01015251646175,
          "to_latitude": 21.010352877032915,
          "to_longitude": 105.83988770504313,
          "from_longitude": 105.83975468572449
        }
      },
      {
        "id": 5083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83986577406237,
            21.010352877032915,
            105.83996331468589,
            21.01046238014421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83989406339539,
              21.010355315064416
            ],
            [
              105.83988894485998,
              21.010353551041295
            ],
            [
              105.83988770504313,
              21.010352877032915
            ],
            [
              105.83986577406237,
              21.01039418585047
            ],
            [
              105.83987101196658,
              21.010398200119813
            ],
            [
              105.83996331468589,
              21.01046238014421
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.8_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5090",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010355315064416,
          "to_latitude": 21.01046238014421,
          "to_longitude": 105.83996331468589,
          "from_longitude": 105.83989406339539
        }
      },
      {
        "id": 5084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399717932163,
            21.01037038877703,
            105.84025435758222,
            21.010551189780777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8399717932163,
              21.01045092605432
            ],
            [
              105.84002347933603,
              21.01038825631144
            ],
            [
              105.84004505960579,
              21.01037038877703
            ],
            [
              105.84005579814016,
              21.010379297554096
            ],
            [
              105.84009542625155,
              21.010411364179014
            ],
            [
              105.8401264400351,
              21.010437671012895
            ],
            [
              105.84015443970719,
              21.010462689370065
            ],
            [
              105.84021079879774,
              21.01051031539444
            ],
            [
              105.84024664873536,
              21.010537249549962
            ],
            [
              105.84024764592655,
              21.010542863583233
            ],
            [
              105.84024841747718,
              21.010544084545298
            ],
            [
              105.84024926086941,
              21.010545423474962
            ],
            [
              105.84025435758222,
              21.010551189780777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.5_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5091",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01045092605432,
          "to_latitude": 21.010551189780777,
          "to_longitude": 105.84025435758222,
          "from_longitude": 105.8399717932163
        }
      },
      {
        "id": 5085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390823760301,
            21.009572744867754,
            105.83919150717803,
            21.009685734457914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390823760301,
              21.009685734457914
            ],
            [
              105.83912066919305,
              21.009644974598952
            ],
            [
              105.83919150717803,
              21.009572744867754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.15_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5092",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009685734457914,
          "to_latitude": 21.009572744867754,
          "to_longitude": 105.83919150717803,
          "from_longitude": 105.8390823760301
        }
      },
      {
        "id": 5086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899795969248,
            21.0094173465739,
            105.83919150717803,
            21.009572744867754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919150717803,
              21.009572744867754
            ],
            [
              105.83919140084694,
              21.00957265145679
            ],
            [
              105.83915725895045,
              21.00954283909696
            ],
            [
              105.83906018665752,
              21.009466367557806
            ],
            [
              105.83899795969248,
              21.0094173465739
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.15_Ngõ 97, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5093",
          "diaChiLapD": "Ngõ 97, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009572744867754,
          "to_latitude": 21.0094173465739,
          "to_longitude": 105.83899795969248,
          "from_longitude": 105.83919150717803
        }
      },
      {
        "id": 5087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897656518776,
            21.009466367557806,
            105.83906018665752,
            21.009540514581797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906018665752,
              21.009466367557806
            ],
            [
              105.83897656518776,
              21.009540514581797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.15_Ngõ 97, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5094",
          "diaChiLapD": "Ngõ 97, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009466367557806,
          "to_latitude": 21.009540514581797,
          "to_longitude": 105.83897656518776,
          "from_longitude": 105.83906018665752
        }
      },
      {
        "id": 5088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897641975368,
            21.009685734457914,
            105.83910372007462,
            21.00979851774787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83910372007462,
              21.00970439911757
            ],
            [
              105.83909878159865,
              21.009700082572774
            ],
            [
              105.8390823760301,
              21.009685734457914
            ],
            [
              105.83897662055492,
              21.009798303596888
            ],
            [
              105.83897641975368,
              21.00979851774787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.15_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5095",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00970439911757,
          "to_latitude": 21.00979851774787,
          "to_longitude": 105.83897641975368,
          "from_longitude": 105.83910372007462
        }
      },
      {
        "id": 5089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387831426136,
            21.009631443706336,
            105.83897641975368,
            21.00979851774787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897641975368,
              21.00979851774787
            ],
            [
              105.83894211432155,
              21.009768361120443
            ],
            [
              105.8387831426136,
              21.009631443706336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.15_Ngõ 113, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5096",
          "diaChiLapD": "Ngõ 113, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00979851774787,
          "to_latitude": 21.009631443706336,
          "to_longitude": 105.8387831426136,
          "from_longitude": 105.83897641975368
        }
      },
      {
        "id": 5090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389021475106,
            21.009823211164605,
            105.83896960811398,
            21.009894304159378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389021475106,
              21.009894304159378
            ],
            [
              105.83896960811398,
              21.009823211164605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.18_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5097",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009894304159378,
          "to_latitude": 21.009823211164605,
          "to_longitude": 105.83896960811398,
          "from_longitude": 105.8389021475106
        }
      },
      {
        "id": 5091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859244629683,
            21.00970551932644,
            105.83891008861879,
            21.009899406163836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891008861879,
              21.009899406163836
            ],
            [
              105.83890727471311,
              21.009897451006935
            ],
            [
              105.8389021475106,
              21.009894304159378
            ],
            [
              105.8388880915838,
              21.009887648947107
            ],
            [
              105.83886117258194,
              21.009865752909633
            ],
            [
              105.83866395145455,
              21.00970551932644
            ],
            [
              105.83859244629683,
              21.009798355587787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.18_Ngõ 119, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5098",
          "diaChiLapD": "Ngõ 119, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009899406163836,
          "to_latitude": 21.009798355587787,
          "to_longitude": 105.83859244629683,
          "from_longitude": 105.83891008861879
        }
      },
      {
        "id": 5092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838727201824,
            21.009894304159378,
            105.8389021475106,
            21.01007716535702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389021475106,
              21.009894304159378
            ],
            [
              105.83879954279539,
              21.010002718183085
            ],
            [
              105.83874977084339,
              21.01005564859906
            ],
            [
              105.838727201824,
              21.01007716535702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.18_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5099",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009894304159378,
          "to_latitude": 21.01007716535702,
          "to_longitude": 105.838727201824,
          "from_longitude": 105.8389021475106
        }
      },
      {
        "id": 5093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389539284666,
            21.00953812444945,
            105.83927095666849,
            21.009883467602783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927095666849,
              21.00953812444945
            ],
            [
              105.83927057829705,
              21.009538750443646
            ],
            [
              105.83926021177123,
              21.00955591767344
            ],
            [
              105.8392592031808,
              21.009575944423098
            ],
            [
              105.83918091817435,
              21.00965418573606
            ],
            [
              105.8390381081573,
              21.00979626985467
            ],
            [
              105.8389539284666,
              21.009883467602783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5100",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00953812444945,
          "to_latitude": 21.009883467602783,
          "to_longitude": 105.8389539284666,
          "from_longitude": 105.83927095666849
        }
      },
      {
        "id": 5094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858447064401,
            21.010083152519954,
            105.83871828577243,
            21.01021095802596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858447064401,
              21.01021095802596
            ],
            [
              105.8386099242226,
              21.010188541007167
            ],
            [
              105.83863656653693,
              21.01016265245397
            ],
            [
              105.83871828577243,
              21.010083152519954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.21_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5101",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01021095802596,
          "to_latitude": 21.010083152519954,
          "to_longitude": 105.83871828577243,
          "from_longitude": 105.83858447064401
        }
      },
      {
        "id": 5095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384890203936,
            21.01021095802596,
            105.83859011086132,
            21.010296358582835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859011086132,
              21.01021622673859
            ],
            [
              105.83858625177638,
              21.01021262382652
            ],
            [
              105.83858447064401,
              21.01021095802596
            ],
            [
              105.83855671772127,
              21.01023433746747
            ],
            [
              105.8384890203936,
              21.010296358582835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.21_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5102",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01021622673859,
          "to_latitude": 21.010296358582835,
          "to_longitude": 105.8384890203936,
          "from_longitude": 105.83859011086132
        }
      },
      {
        "id": 5096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890300297405,
            21.00990283548374,
            105.83939647995349,
            21.010339278603812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890300297405,
              21.00990640676232
            ],
            [
              105.83890497443277,
              21.00990753329953
            ],
            [
              105.83892497394365,
              21.00990283548374
            ],
            [
              105.83895171445079,
              21.009926007743445
            ],
            [
              105.8390307411518,
              21.00999480238797
            ],
            [
              105.83905796108085,
              21.01001545949566
            ],
            [
              105.8390825566039,
              21.01004396591442
            ],
            [
              105.83915587292331,
              21.01011195407483
            ],
            [
              105.83922352668476,
              21.01017428252441
            ],
            [
              105.83939647995349,
              21.010339278603812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.16_Ngõ 110, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5103",
          "diaChiLapD": "Ngõ 110, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00990640676232,
          "to_latitude": 21.010339278603812,
          "to_longitude": 105.83939647995349,
          "from_longitude": 105.83890300297405
        }
      },
      {
        "id": 5097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890219846303,
            21.009907201953062,
            105.83953848746249,
            21.010412857395725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890219846303,
              21.009907201953062
            ],
            [
              105.83890393205156,
              21.009908622307687
            ],
            [
              105.83899325589,
              21.009981723360823
            ],
            [
              105.8390383268516,
              21.010022696296456
            ],
            [
              105.83906873527869,
              21.01004710051337
            ],
            [
              105.83909236692173,
              21.010069059693542
            ],
            [
              105.83912584361376,
              21.010101165015595
            ],
            [
              105.83916254744207,
              21.010135840347385
            ],
            [
              105.83921992927625,
              21.010185478570726
            ],
            [
              105.83925029164982,
              21.01021487961708
            ],
            [
              105.83929404656205,
              21.010249091110722
            ],
            [
              105.83932553229299,
              21.01028155666142
            ],
            [
              105.839349738465,
              21.010309397475663
            ],
            [
              105.83936776254154,
              21.010324005835024
            ],
            [
              105.83940493740536,
              21.010361015428245
            ],
            [
              105.83941399635718,
              21.01035271978682
            ],
            [
              105.83944169988663,
              21.010327066133854
            ],
            [
              105.83953848746249,
              21.010412857395725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.17_Ngõ 110, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5104",
          "diaChiLapD": "Ngõ 110, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009907201953062,
          "to_latitude": 21.010412857395725,
          "to_longitude": 105.83953848746249,
          "from_longitude": 105.83890219846303
        }
      },
      {
        "id": 5098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390307411518,
            21.009845896584277,
            105.83927940342883,
            21.00999480238797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390307411518,
              21.00999480238797
            ],
            [
              105.83913293886904,
              21.009904802465897
            ],
            [
              105.8392057118009,
              21.009845896584277
            ],
            [
              105.8392464749725,
              21.009889717801776
            ],
            [
              105.83925485929853,
              21.009887212454693
            ],
            [
              105.83927940342883,
              21.009912375361807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.16_Ngõ 110, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5105",
          "diaChiLapD": "Ngõ 110, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00999480238797,
          "to_latitude": 21.009912375361807,
          "to_longitude": 105.83927940342883,
          "from_longitude": 105.8390307411518
        }
      },
      {
        "id": 5099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83881480728999,
            21.009995166950297,
            105.83926526823069,
            21.01040313705618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881480728999,
              21.009995166950297
            ],
            [
              105.83881711711437,
              21.009997457883323
            ],
            [
              105.83883390774449,
              21.010014094702
            ],
            [
              105.8388680262114,
              21.01004418091637
            ],
            [
              105.83894442464397,
              21.01011565939059
            ],
            [
              105.83894649495114,
              21.010122765688628
            ],
            [
              105.83897106376509,
              21.010144603749243
            ],
            [
              105.83900559491073,
              21.01017640402562
            ],
            [
              105.83902814532675,
              21.010196115903522
            ],
            [
              105.83908837426674,
              21.010246079745606
            ],
            [
              105.83912828451611,
              21.010279274242464
            ],
            [
              105.83912428614583,
              21.010286543238223
            ],
            [
              105.83914479953886,
              21.010305096372292
            ],
            [
              105.83926526823069,
              21.01040313705618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.19_Ngõ 116, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5106",
          "diaChiLapD": "Ngõ 116, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009995166950297,
          "to_latitude": 21.01040313705618,
          "to_longitude": 105.83926526823069,
          "from_longitude": 105.83881480728999
        }
      },
      {
        "id": 5100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83880776928318,
            21.010002259439048,
            105.83926077864753,
            21.01040788306249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880776928318,
              21.010002259439048
            ],
            [
              105.83880997327635,
              21.01000489050623
            ],
            [
              105.83882293277208,
              21.010020343116086
            ],
            [
              105.83890359821444,
              21.01009372354043
            ],
            [
              105.83892136777045,
              21.010113229583727
            ],
            [
              105.83894451262229,
              21.010131221513262
            ],
            [
              105.83896581425192,
              21.01015175336188
            ],
            [
              105.83898152932794,
              21.010164573021935
            ],
            [
              105.83900135671215,
              21.010180521044827
            ],
            [
              105.83900300047496,
              21.01018703512348
            ],
            [
              105.83906707331613,
              21.01024149885242
            ],
            [
              105.83907410940445,
              21.010247479632092
            ],
            [
              105.83908826683289,
              21.010256797032422
            ],
            [
              105.83910918268066,
              21.010274559671718
            ],
            [
              105.83911371139409,
              21.0102848937102
            ],
            [
              105.83912152499902,
              21.010293851294044
            ],
            [
              105.83913730200294,
              21.010308279275865
            ],
            [
              105.83926077864753,
              21.01040788306249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT74.20_Ngõ 116, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5107",
          "diaChiLapD": "Ngõ 116, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010002259439048,
          "to_latitude": 21.01040788306249,
          "to_longitude": 105.83926077864753,
          "from_longitude": 105.83880776928318
        }
      },
      {
        "id": 5101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883390774449,
            21.009953969747155,
            105.83889419050233,
            21.010014094702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883390774449,
              21.010014094702
            ],
            [
              105.83889419050233,
              21.009953969747155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.19_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5108",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010014094702,
          "to_latitude": 21.009953969747155,
          "to_longitude": 105.83889419050233,
          "from_longitude": 105.83883390774449
        }
      },
      {
        "id": 5102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386658684181,
            21.010020343116086,
            105.83882293277208,
            21.010188547098032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386658684181,
              21.010188547098032
            ],
            [
              105.83870289686284,
              21.010147948916366
            ],
            [
              105.83874180880873,
              21.01010633976737
            ],
            [
              105.83878053504102,
              21.01006541075009
            ],
            [
              105.83882293277208,
              21.010020343116086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.20_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5109",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010188547098032,
          "to_latitude": 21.010020343116086,
          "to_longitude": 105.83882293277208,
          "from_longitude": 105.8386658684181
        }
      },
      {
        "id": 5103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867574198266,
            21.010234602623502,
            105.83886929924583,
            21.010380602247558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867574198266,
              21.010234602623502
            ],
            [
              105.83870325010061,
              21.01025674055846
            ],
            [
              105.83871814436257,
              21.01026520442432
            ],
            [
              105.83875196805546,
              21.010290216895054
            ],
            [
              105.83878186416005,
              21.01031382523716
            ],
            [
              105.8388006467761,
              21.010330435980535
            ],
            [
              105.8388194030568,
              21.010346292659122
            ],
            [
              105.83886929924583,
              21.010380602247558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.1_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5110",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010234602623502,
          "to_latitude": 21.010380602247558,
          "to_longitude": 105.83886929924583,
          "from_longitude": 105.83867574198266
        }
      },
      {
        "id": 5104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886585709767,
            21.010380602247558,
            105.83900713136376,
            21.010473179719735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886585709767,
              21.010385336757583
            ],
            [
              105.83886782728979,
              21.010382622791152
            ],
            [
              105.83886929924583,
              21.010380602247558
            ],
            [
              105.83890278969565,
              21.010402949107725
            ],
            [
              105.83895414421112,
              21.010438470833297
            ],
            [
              105.83900713136376,
              21.010473179719735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.1_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5111",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010385336757583,
          "to_latitude": 21.010473179719735,
          "to_longitude": 105.83900713136376,
          "from_longitude": 105.83886585709767
        }
      },
      {
        "id": 5105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83868823559845,
            21.010258428623224,
            105.83886087362917,
            21.010395169438507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868823559845,
              21.010258428623224
            ],
            [
              105.8387078079228,
              21.010272975230283
            ],
            [
              105.83872110951324,
              21.010282169525357
            ],
            [
              105.83873289443014,
              21.010291751552476
            ],
            [
              105.83874907722591,
              21.010305302346787
            ],
            [
              105.83876425541023,
              21.010318267382594
            ],
            [
              105.83878221512899,
              21.010333245541602
            ],
            [
              105.83879128104874,
              21.010341503291706
            ],
            [
              105.83880345022253,
              21.010351165612885
            ],
            [
              105.83882454911841,
              21.0103688108693
            ],
            [
              105.83884706988984,
              21.010384251568617
            ],
            [
              105.83886087362917,
              21.010395169438507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.2_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5112",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010258428623224,
          "to_latitude": 21.010395169438507,
          "to_longitude": 105.83886087362917,
          "from_longitude": 105.83868823559845
        }
      },
      {
        "id": 5106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886087362917,
            21.010395169438507,
            105.83902057697514,
            21.010500209251266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886087362917,
              21.010395169438507
            ],
            [
              105.83888202928361,
              21.010409888863734
            ],
            [
              105.83890735775933,
              21.010427031839317
            ],
            [
              105.83892149543797,
              21.01043660044689
            ],
            [
              105.83896560526942,
              21.010463411963904
            ],
            [
              105.83902057697514,
              21.010500209251266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.2_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5113",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010395169438507,
          "to_latitude": 21.010500209251266,
          "to_longitude": 105.83902057697514,
          "from_longitude": 105.83886087362917
        }
      },
      {
        "id": 5107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878534458108,
            21.0103688108693,
            105.83882454911841,
            21.01044171915303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882454911841,
              21.0103688108693
            ],
            [
              105.83878534458108,
              21.010418999401647
            ],
            [
              105.83882418048131,
              21.01044171915303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.2_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5114",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0103688108693,
          "to_latitude": 21.01044171915303,
          "to_longitude": 105.83882418048131,
          "from_longitude": 105.83882454911841
        }
      },
      {
        "id": 5108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926187928977,
            21.01065451296565,
            105.83938249966319,
            21.010764642460934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83926187928977,
              21.01065451296565
            ],
            [
              105.83929680999117,
              21.010683251081364
            ],
            [
              105.83931053520743,
              21.01069439329959
            ],
            [
              105.83933403171837,
              21.010713665111716
            ],
            [
              105.83934719470705,
              21.01072626880721
            ],
            [
              105.83936619668631,
              21.010744587302337
            ],
            [
              105.83938249966319,
              21.010764642460934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.5_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5115",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01065451296565,
          "to_latitude": 21.010764642460934,
          "to_longitude": 105.83938249966319,
          "from_longitude": 105.83926187928977
        }
      },
      {
        "id": 5109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903663308809,
            21.010494882349096,
            105.83924805610468,
            21.01062877873471
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903663308809,
              21.010494882349096
            ],
            [
              105.83906084696959,
              21.010510688605198
            ],
            [
              105.83908438750201,
              21.010525286954177
            ],
            [
              105.83910171052783,
              21.010535110806128
            ],
            [
              105.83912174744061,
              21.010546546173828
            ],
            [
              105.83916906361524,
              21.01057065111903
            ],
            [
              105.83921997372433,
              21.01059841173896
            ],
            [
              105.8392240521277,
              21.01060063523382
            ],
            [
              105.83923717215795,
              21.010618459786865
            ],
            [
              105.83924056653903,
              21.010623071167053
            ],
            [
              105.83924805610468,
              21.01062877873471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.4_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5116",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010494882349096,
          "to_latitude": 21.01062877873471,
          "to_longitude": 105.83924805610468,
          "from_longitude": 105.83903663308809
        }
      },
      {
        "id": 5110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924805610468,
            21.01062877873471,
            105.83939465112286,
            21.01075633969421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924805610468,
              21.01062877873471
            ],
            [
              105.83927904713504,
              21.010654818483232
            ],
            [
              105.83929581195252,
              21.010667162353784
            ],
            [
              105.8393175005456,
              21.010686173924885
            ],
            [
              105.83934123624019,
              21.010707278101563
            ],
            [
              105.83935953630063,
              21.010722550774734
            ],
            [
              105.83937584328964,
              21.01073816566384
            ],
            [
              105.83939465112286,
              21.01075633969421
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.4_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5117",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01062877873471,
          "to_latitude": 21.01075633969421,
          "to_longitude": 105.83939465112286,
          "from_longitude": 105.83924805610468
        }
      },
      {
        "id": 5111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83844547210406,
            21.010502203049935,
            105.83859795990769,
            21.010667794851845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844547210406,
              21.010502203049935
            ],
            [
              105.83849430039908,
              21.010550426763658
            ],
            [
              105.83854484038216,
              21.010604234792737
            ],
            [
              105.83856046968653,
              21.010622874388282
            ],
            [
              105.83858111993538,
              21.010647502003245
            ],
            [
              105.83859795990769,
              21.010667794851845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.3_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5118",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010502203049935,
          "to_latitude": 21.010667794851845,
          "to_longitude": 105.83859795990769,
          "from_longitude": 105.83844547210406
        }
      },
      {
        "id": 5112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857692564712,
            21.01061852013742,
            105.8387261776393,
            21.01076927333317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857692564712,
              21.01061852013742
            ],
            [
              105.8386211480298,
              21.01067304030693
            ],
            [
              105.83866208927773,
              21.010712569562063
            ],
            [
              105.8387261776393,
              21.01076927333317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.2_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5119",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01061852013742,
          "to_latitude": 21.01076927333317,
          "to_longitude": 105.8387261776393,
          "from_longitude": 105.83857692564712
        }
      },
      {
        "id": 5113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874415226484,
            21.010721535140537,
            105.83892670218235,
            21.01083294484795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83874415226484,
              21.010780026136924
            ],
            [
              105.83879844154252,
              21.01073604066366
            ],
            [
              105.83881634517182,
              21.010721535140537
            ],
            [
              105.83884574331051,
              21.010751545266793
            ],
            [
              105.83890361749559,
              21.010809733793657
            ],
            [
              105.83892670218235,
              21.01083294484795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT78.4_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5120",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010780026136924,
          "to_latitude": 21.01083294484795,
          "to_longitude": 105.83892670218235,
          "from_longitude": 105.83874415226484
        }
      },
      {
        "id": 5114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892670218235,
            21.01083294484795,
            105.83899753261409,
            21.010894279521985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892670218235,
              21.01083294484795
            ],
            [
              105.83899753261409,
              21.010894279521985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT78.4_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5121",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01083294484795,
          "to_latitude": 21.010894279521985,
          "to_longitude": 105.83899753261409,
          "from_longitude": 105.83892670218235
        }
      },
      {
        "id": 5115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890361749559,
            21.01080173867299,
            105.83897116067125,
            21.010844505503773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890361749559,
              21.010809733793657
            ],
            [
              105.83891010988536,
              21.010803801020966
            ],
            [
              105.83891236652535,
              21.01080173867299
            ],
            [
              105.83893499677079,
              21.01082074565094
            ],
            [
              105.8389477474084,
              21.01082927967562
            ],
            [
              105.83897116067125,
              21.010844505503773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.4_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5122",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010809733793657,
          "to_latitude": 21.010844505503773,
          "to_longitude": 105.83897116067125,
          "from_longitude": 105.83890361749559
        }
      },
      {
        "id": 5116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388271520195,
            21.010718755936217,
            105.83891236652535,
            21.01080173867299
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891236652535,
              21.01080173867299
            ],
            [
              105.83890482299105,
              21.010795324131955
            ],
            [
              105.8388271520195,
              21.010718755936217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.4_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5123",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01080173867299,
          "to_latitude": 21.010718755936217,
          "to_longitude": 105.8388271520195,
          "from_longitude": 105.83891236652535
        }
      },
      {
        "id": 5117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83838257805404,
            21.01024670780948,
            105.83859103631937,
            21.010419638625113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83838346529915,
              21.010419638625113
            ],
            [
              105.83838729749783,
              21.010417052780088
            ],
            [
              105.83838589290646,
              21.010414948864884
            ],
            [
              105.83838257805404,
              21.01040907165734
            ],
            [
              105.83845032693117,
              21.01036279895014
            ],
            [
              105.83859103631937,
              21.01024670780948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5124",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010419638625113,
          "to_latitude": 21.01024670780948,
          "to_longitude": 105.83859103631937,
          "from_longitude": 105.83838346529915
        }
      },
      {
        "id": 5118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83828493954009,
            21.01040907165734,
            105.83838257805404,
            21.010480926389963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83838257805404,
              21.01040907165734
            ],
            [
              105.8383784319924,
              21.01041212330599
            ],
            [
              105.83828493954009,
              21.010480926389963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5125",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01040907165734,
          "to_latitude": 21.010480926389963,
          "to_longitude": 105.83828493954009,
          "from_longitude": 105.83838257805404
        }
      },
      {
        "id": 5119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798956243557,
            21.009651724536937,
            105.83846226019799,
            21.010388046354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83806941137367,
              21.010388046354
            ],
            [
              105.83807001078458,
              21.01038514495433
            ],
            [
              105.83807352485947,
              21.010368140703505
            ],
            [
              105.83800849290847,
              21.01030138030065
            ],
            [
              105.83798956243557,
              21.010202825147694
            ],
            [
              105.83823136586263,
              21.009888733731767
            ],
            [
              105.83846226019799,
              21.009651724536937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.22_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.5126",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 108,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010388046354,
          "to_latitude": 21.009651724536937,
          "to_longitude": 105.83846226019799,
          "from_longitude": 105.83806941137367
        }
      },
      {
        "id": 5120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84093666018745,
            21.009564342800935,
            105.84110170490335,
            21.009801755163963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84110170490335,
              21.009801755163963
            ],
            [
              105.84110010726388,
              21.009799880741834
            ],
            [
              105.84108201386358,
              21.00979677456608
            ],
            [
              105.84105819249257,
              21.009757999477475
            ],
            [
              105.84099172913821,
              21.00965021565152
            ],
            [
              105.84093666018745,
              21.009564342800935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.15_Ngách 378/1, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5127",
          "diaChiLapD": "Ngách 378/1, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009801755163963,
          "to_latitude": 21.009564342800935,
          "to_longitude": 105.84093666018745,
          "from_longitude": 105.84110170490335
        }
      },
      {
        "id": 5121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84080591942902,
            21.00974606621912,
            105.84099235978591,
            21.009881483964673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84099235978591,
              21.009881483964673
            ],
            [
              105.84099165429332,
              21.009880722420547
            ],
            [
              105.84094001424982,
              21.009824853088546
            ],
            [
              105.84087940086255,
              21.0097683262762
            ],
            [
              105.84086538524241,
              21.00975188369379
            ],
            [
              105.84085547587384,
              21.00974606621912
            ],
            [
              105.84083466847645,
              21.00975025251059
            ],
            [
              105.84080591942902,
              21.009779803540884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.13a_Ngách 378/3, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5128",
          "diaChiLapD": "Ngách 378/3, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009881483964673,
          "to_latitude": 21.009779803540884,
          "to_longitude": 105.84080591942902,
          "from_longitude": 105.84099235978591
        }
      },
      {
        "id": 5122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8411426404086,
            21.009339748540228,
            105.84119809207243,
            21.009775507065434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84114637354506,
              21.009775507065434
            ],
            [
              105.84114626557225,
              21.00977529263183
            ],
            [
              105.8411426404086,
              21.009767955597688
            ],
            [
              105.84116311820247,
              21.009761083806435
            ],
            [
              105.84119809207243,
              21.009755977511787
            ],
            [
              105.841194787778,
              21.00960908174344
            ],
            [
              105.8411952629576,
              21.009530502513382
            ],
            [
              105.84119166374005,
              21.009339748540228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.16_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5129",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009775507065434,
          "to_latitude": 21.009339748540228,
          "to_longitude": 105.84119166374005,
          "from_longitude": 105.84114637354506
        }
      },
      {
        "id": 5123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84105410842412,
            21.009530502513382,
            105.8411952629576,
            21.009546272324744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8411952629576,
              21.009530502513382
            ],
            [
              105.84114127955822,
              21.00953653373712
            ],
            [
              105.84105410842412,
              21.009546272324744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.16_Ngõ 386, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5130",
          "diaChiLapD": "Ngõ 386, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009530502513382,
          "to_latitude": 21.009546272324744,
          "to_longitude": 105.84105410842412,
          "from_longitude": 105.8411952629576
        }
      },
      {
        "id": 5124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84089245199844,
            21.009882442634744,
            105.84098077524327,
            21.00995698412719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84089702303636,
              21.00995698412719
            ],
            [
              105.84089645494987,
              21.009956196613402
            ],
            [
              105.84089245199844,
              21.009952333434125
            ],
            [
              105.84093133657112,
              21.009922092819004
            ],
            [
              105.84096950149073,
              21.0098914845268
            ],
            [
              105.84098077524327,
              21.009882442634744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.13_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5131",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00995698412719,
          "to_latitude": 21.009882442634744,
          "to_longitude": 105.84098077524327,
          "from_longitude": 105.84089702303636
        }
      },
      {
        "id": 5125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84070407712666,
            21.009952333434125,
            105.84089245199844,
            21.0101016124405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84089245199844,
              21.009952333434125
            ],
            [
              105.84087409106397,
              21.009966885686374
            ],
            [
              105.84084895664977,
              21.009986797719453
            ],
            [
              105.84081662750009,
              21.010012767094498
            ],
            [
              105.84078540992193,
              21.01003729665154
            ],
            [
              105.84070407712666,
              21.0101016124405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.13_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5132",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009952333434125,
          "to_latitude": 21.0101016124405,
          "to_longitude": 105.84070407712666,
          "from_longitude": 105.84089245199844
        }
      },
      {
        "id": 5126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84096473432955,
            21.009795287017717,
            105.84113280143495,
            21.00991070701719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84096473432955,
              21.00991070701719
            ],
            [
              105.84098685952414,
              21.0098914149998
            ],
            [
              105.84100169225702,
              21.009879619651645
            ],
            [
              105.84100515217568,
              21.00987686849264
            ],
            [
              105.84105836981779,
              21.009833733939253
            ],
            [
              105.84107760053536,
              21.009822660200808
            ],
            [
              105.84113280143495,
              21.009795287017717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.14_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5133",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00991070701719,
          "to_latitude": 21.009795287017717,
          "to_longitude": 105.84113280143495,
          "from_longitude": 105.84096473432955
        }
      },
      {
        "id": 5127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84052122248247,
            21.009815039911217,
            105.84065573124589,
            21.010150400486467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84065573124589,
              21.010150400486467
            ],
            [
              105.8406547050556,
              21.01014922684864
            ],
            [
              105.84062564801546,
              21.010115928522332
            ],
            [
              105.84059703819725,
              21.01008519223461
            ],
            [
              105.84055840281037,
              21.0100406021358
            ],
            [
              105.84052576137458,
              21.009993495844505
            ],
            [
              105.84052569884162,
              21.009963617498745
            ],
            [
              105.84055811401599,
              21.00992998841204
            ],
            [
              105.84052122248247,
              21.00990117353058
            ],
            [
              105.84061272052043,
              21.009815039911217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.10_Ngách 378/15, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5134",
          "diaChiLapD": "Ngách 378/15, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010150400486467,
          "to_latitude": 21.009815039911217,
          "to_longitude": 105.84061272052043,
          "from_longitude": 105.84065573124589
        }
      },
      {
        "id": 5128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84053433061774,
            21.010172316185493,
            105.84061973948403,
            21.010253739226208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84053433061774,
              21.010253739226208
            ],
            [
              105.84055857708823,
              21.010229328148505
            ],
            [
              105.84055884105763,
              21.010229057683787
            ],
            [
              105.84058596487533,
              21.010204701937358
            ],
            [
              105.84059046509442,
              21.010200661262843
            ],
            [
              105.84061973948403,
              21.010172316185493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.9_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5135",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010253739226208,
          "to_latitude": 21.010172316185493,
          "to_longitude": 105.84061973948403,
          "from_longitude": 105.84053433061774
        }
      },
      {
        "id": 5129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036456114539,
            21.0100130016326,
            105.84058596487533,
            21.010204701937358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84058596487533,
              21.010204701937358
            ],
            [
              105.84056293518873,
              21.010178801038055
            ],
            [
              105.84050417257689,
              21.010119027796765
            ],
            [
              105.84040548026445,
              21.0100130016326
            ],
            [
              105.84036456114539,
              21.01004839513697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.9_Ngách 378/17, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5136",
          "diaChiLapD": "Ngách 378/17, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010204701937358,
          "to_latitude": 21.01004839513697,
          "to_longitude": 105.84036456114539,
          "from_longitude": 105.84058596487533
        }
      },
      {
        "id": 5130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84039945612076,
            21.010253739226208,
            105.84053433061774,
            21.010376593552994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84053433061774,
              21.010253739226208
            ],
            [
              105.8404764269189,
              21.0103040259157
            ],
            [
              105.84044984885455,
              21.01033102720301
            ],
            [
              105.84042055313314,
              21.010358322811335
            ],
            [
              105.84039945612076,
              21.010376593552994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.9_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5137",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010253739226208,
          "to_latitude": 21.010376593552994,
          "to_longitude": 105.84039945612076,
          "from_longitude": 105.84053433061774
        }
      },
      {
        "id": 5131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8406797240469,
            21.01012707451435,
            105.84094244672849,
            21.0103806538129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406797240469,
              21.01012707451435
            ],
            [
              105.84068064404677,
              21.010128008419038
            ],
            [
              105.8406851849723,
              21.01013260687952
            ],
            [
              105.84070080806764,
              21.010135509430455
            ],
            [
              105.84071330846696,
              21.01013783128003
            ],
            [
              105.8407195050717,
              21.0101546620524
            ],
            [
              105.84072885986762,
              21.010175660954875
            ],
            [
              105.84074208645778,
              21.01021968739278
            ],
            [
              105.84074350024994,
              21.010267715670437
            ],
            [
              105.84074484670491,
              21.010303555271676
            ],
            [
              105.8407462002361,
              21.01034082915757
            ],
            [
              105.84074634485975,
              21.010366638070682
            ],
            [
              105.84075099858568,
              21.0103795203428
            ],
            [
              105.84081170382808,
              21.0103806538129
            ],
            [
              105.84086738173313,
              21.010374843730652
            ],
            [
              105.84087000091041,
              21.01033192132322
            ],
            [
              105.84086619107462,
              21.01019809318352
            ],
            [
              105.84094244672849,
              21.010196723706276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.12_Ngõ 354, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5138",
          "diaChiLapD": "Ngõ 354, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01012707451435,
          "to_latitude": 21.010196723706276,
          "to_longitude": 105.84094244672849,
          "from_longitude": 105.8406797240469
        }
      },
      {
        "id": 5132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84076344020065,
            21.01019809318352,
            105.84086619107462,
            21.010205015592824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84086619107462,
              21.01019809318352
            ],
            [
              105.84076344020065,
              21.010205015592824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.12_Ngõ 354, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5139",
          "diaChiLapD": "Ngõ 354, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01019809318352,
          "to_latitude": 21.010205015592824,
          "to_longitude": 105.84076344020065,
          "from_longitude": 105.84086619107462
        }
      },
      {
        "id": 5133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84081516729049,
            21.01038581653463,
            105.84119998795931,
            21.01040342052581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84081516729049,
              21.01040342052581
            ],
            [
              105.84090387063048,
              21.010394429570347
            ],
            [
              105.84095376552966,
              21.010387923652225
            ],
            [
              105.8410250191012,
              21.01038581653463
            ],
            [
              105.84119998795931,
              21.01038733979157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.17_Ngõ 354, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5140",
          "diaChiLapD": "Ngõ 354, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01040342052581,
          "to_latitude": 21.01038733979157,
          "to_longitude": 105.84119998795931,
          "from_longitude": 105.84081516729049
        }
      },
      {
        "id": 5134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060636176498,
            21.010129693838334,
            105.84073272699011,
            21.010450689098707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406764384436,
              21.010129693838334
            ],
            [
              105.84067726351151,
              21.010130680599016
            ],
            [
              105.84068937203524,
              21.01014512294411
            ],
            [
              105.84071317464452,
              21.01017351651862
            ],
            [
              105.84071419167002,
              21.010175972779546
            ],
            [
              105.84073219703151,
              21.01022807662502
            ],
            [
              105.84073272699011,
              21.010249868803708
            ],
            [
              105.84072979837637,
              21.010272251617437
            ],
            [
              105.84072927114431,
              21.01028716645182
            ],
            [
              105.84073002120041,
              21.01031201676287
            ],
            [
              105.84072680098552,
              21.010353599947987
            ],
            [
              105.84070980711712,
              21.01036682672229
            ],
            [
              105.84067136543212,
              21.010399057614094
            ],
            [
              105.84060636176498,
              21.010450689098707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.11_Ngõ 354, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5141",
          "diaChiLapD": "Ngõ 354, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010129693838334,
          "to_latitude": 21.010450689098707,
          "to_longitude": 105.84060636176498,
          "from_longitude": 105.8406764384436
        }
      },
      {
        "id": 5135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008909803329,
            21.010078283630197,
            105.84038508618538,
            21.01039770151551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84038508618538,
              21.01039770151551
            ],
            [
              105.84037516087388,
              21.01038680977956
            ],
            [
              105.840354941301,
              21.010363554969302
            ],
            [
              105.84027338764658,
              21.010302966590338
            ],
            [
              105.8401998587996,
              21.010247116602816
            ],
            [
              105.84019218998749,
              21.01024118054339
            ],
            [
              105.84023216732332,
              21.01019940051467
            ],
            [
              105.84016181748933,
              21.01014472887331
            ],
            [
              105.84008909803329,
              21.010078283630197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT81.7_Ngách 378/31, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5142",
          "diaChiLapD": "Ngách 378/31, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01039770151551,
          "to_latitude": 21.010078283630197,
          "to_longitude": 105.84008909803329,
          "from_longitude": 105.84038508618538
        }
      },
      {
        "id": 5136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84045532628511,
            21.01029484440543,
            105.84054343929185,
            21.010507654840715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84051542483199,
              21.01029484440543
            ],
            [
              105.84045532628511,
              21.010349465663346
            ],
            [
              105.84048989347944,
              21.010385749053714
            ],
            [
              105.84054343929185,
              21.01043925078612
            ],
            [
              105.84048379016605,
              21.010507654840715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.8_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5143",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01029484440543,
          "to_latitude": 21.010507654840715,
          "to_longitude": 105.84048379016605,
          "from_longitude": 105.84051542483199
        }
      },
      {
        "id": 5137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002033805403,
            21.01065950822175,
            105.84030845952428,
            21.01091144815553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002033805403,
              21.01091144815553
            ],
            [
              105.84002274073367,
              21.010909357975574
            ],
            [
              105.84002664224214,
              21.01090575381848
            ],
            [
              105.84010712029408,
              21.01083141496421
            ],
            [
              105.8401332764144,
              21.010803587554427
            ],
            [
              105.84030845952428,
              21.01065950822175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT81.1_Ngõ 31, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5144",
          "diaChiLapD": "Ngõ 31, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01091144815553,
          "to_latitude": 21.01065950822175,
          "to_longitude": 105.84030845952428,
          "from_longitude": 105.84002033805403
        }
      },
      {
        "id": 5138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83975034556329,
            21.010956854087702,
            105.83986335148336,
            21.011070744774525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83986335148336,
              21.010956854087702
            ],
            [
              105.83981461548777,
              21.011003752479716
            ],
            [
              105.83976567445029,
              21.011058361791775
            ],
            [
              105.83975034556329,
              21.011070744774525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.1_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5145",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010956854087702,
          "to_latitude": 21.011070744774525,
          "to_longitude": 105.83975034556329,
          "from_longitude": 105.83986335148336
        }
      },
      {
        "id": 5139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83986335148336,
            21.01076186229395,
            105.84001671966782,
            21.010956854087702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83986335148336,
              21.010956854087702
            ],
            [
              105.83987868564077,
              21.010937168501897
            ],
            [
              105.83988269452863,
              21.0109329848513
            ],
            [
              105.83991462406424,
              21.010899659051486
            ],
            [
              105.8399227376179,
              21.010889156957134
            ],
            [
              105.83994449968878,
              21.010854608696302
            ],
            [
              105.83996221118572,
              21.010821437973625
            ],
            [
              105.83999781597105,
              21.01078241634979
            ],
            [
              105.84001671966782,
              21.01076186229395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.1_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5146",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010956854087702,
          "to_latitude": 21.01076186229395,
          "to_longitude": 105.84001671966782,
          "from_longitude": 105.83986335148336
        }
      },
      {
        "id": 5140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987430208245,
            21.010819608416927,
            105.83994449968878,
            21.010854608696302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83994449968878,
              21.010854608696302
            ],
            [
              105.83987430208245,
              21.010819608416927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.1_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5147",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010854608696302,
          "to_latitude": 21.010819608416927,
          "to_longitude": 105.83987430208245,
          "from_longitude": 105.83994449968878
        }
      },
      {
        "id": 5141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947205597113,
            21.010987758268357,
            105.83975086173163,
            21.01110642509994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83975086173163,
              21.011081848271488
            ],
            [
              105.83974794524711,
              21.011079788861945
            ],
            [
              105.83974468291323,
              21.011077463804806
            ],
            [
              105.8397291247789,
              21.011092025590536
            ],
            [
              105.83967498638562,
              21.01105242985814
            ],
            [
              105.83963455097393,
              21.011025316792008
            ],
            [
              105.83958086650641,
              21.010987758268357
            ],
            [
              105.83947205597113,
              21.01110642509994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT82.3_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5148",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011081848271488,
          "to_latitude": 21.01110642509994,
          "to_longitude": 105.83947205597113,
          "from_longitude": 105.83975086173163
        }
      },
      {
        "id": 5142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83981902462101,
            21.009526468887284,
            105.83990992937971,
            21.009587002736502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83981902462101,
              21.009587002736502
            ],
            [
              105.83989374414753,
              21.009536027208487
            ],
            [
              105.83990992937971,
              21.009526468887284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.10_Ngõ 78, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5149",
          "diaChiLapD": "Ngõ 78, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009587002736502,
          "to_latitude": 21.009526468887284,
          "to_longitude": 105.83990992937971,
          "from_longitude": 105.83981902462101
        }
      },
      {
        "id": 5143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907492378233,
            21.010293851294044,
            105.83922214420156,
            21.010459881650828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912152499902,
              21.010293851294044
            ],
            [
              105.83907492378233,
              21.010347176230994
            ],
            [
              105.83913065263462,
              21.01038881554881
            ],
            [
              105.83915892504815,
              21.010411206153645
            ],
            [
              105.83922214420156,
              21.010459881650828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.20_Ngõ 116, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5150",
          "diaChiLapD": "Ngõ 116, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010293851294044,
          "to_latitude": 21.010459881650828,
          "to_longitude": 105.83922214420156,
          "from_longitude": 105.83912152499902
        }
      },
      {
        "id": 5144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84092152498187,
            21.00886258984753,
            105.84097229627255,
            21.00896380083166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84097229627255,
              21.00896380083166
            ],
            [
              105.84092152498187,
              21.00886258984753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.5_Ngõ 422, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5151",
          "diaChiLapD": "Ngõ 422, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00896380083166,
          "to_latitude": 21.00886258984753,
          "to_longitude": 105.84092152498187,
          "from_longitude": 105.84097229627255
        }
      },
      {
        "id": 5145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84094534589529,
            21.00875920831799,
            105.84104921656674,
            21.00892924719715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84104921656674,
              21.00892924719715
            ],
            [
              105.84100372422517,
              21.008844533731157
            ],
            [
              105.84098360924521,
              21.00883425509324
            ],
            [
              105.84094534589529,
              21.00875920831799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.5_Ngõ 422, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5152",
          "diaChiLapD": "Ngõ 422, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00892924719715,
          "to_latitude": 21.00875920831799,
          "to_longitude": 105.84094534589529,
          "from_longitude": 105.84104921656674
        }
      },
      {
        "id": 5146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84070712054937,
            21.009470722445514,
            105.84075302702819,
            21.009568750114735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84070712054937,
              21.009470722445514
            ],
            [
              105.84075302702819,
              21.009553462199662
            ],
            [
              105.84072177698675,
              21.009568750114735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.10_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5153",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009470722445514,
          "to_latitude": 21.009568750114735,
          "to_longitude": 105.84072177698675,
          "from_longitude": 105.84070712054937
        }
      },
      {
        "id": 5147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84007111706877,
            21.00906821231287,
            105.84025926331134,
            21.00926237431902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84025926331134,
              21.00906821231287
            ],
            [
              105.84019396369442,
              21.009133740299035
            ],
            [
              105.84020132465527,
              21.009158574236
            ],
            [
              105.84019189976597,
              21.009166087725255
            ],
            [
              105.8401376651873,
              21.009209189096445
            ],
            [
              105.84007111706877,
              21.00926237431902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D15_KT83.3_Ngách 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5154",
          "diaChiLapD": "Ngách 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 15,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00906821231287,
          "to_latitude": 21.00926237431902,
          "to_longitude": 105.84007111706877,
          "from_longitude": 105.84025926331134
        }
      },
      {
        "id": 5148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932673673907,
            21.00919536108749,
            105.83946458724027,
            21.009311663129285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83946458724027,
              21.009311663129285
            ],
            [
              105.83932673673907,
              21.00919536108749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.12_Ngõ 77, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5155",
          "diaChiLapD": "Ngõ 77, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009311663129285,
          "to_latitude": 21.00919536108749,
          "to_longitude": 105.83932673673907,
          "from_longitude": 105.83946458724027
        }
      },
      {
        "id": 5149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386748331516,
            21.009904060372282,
            105.83879954279539,
            21.010002718183085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879954279539,
              21.010002718183085
            ],
            [
              105.83876800170411,
              21.009975804922608
            ],
            [
              105.8386748331516,
              21.009904060372282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.18_Ngõ 127, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5156",
          "diaChiLapD": "Ngõ 127, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010002718183085,
          "to_latitude": 21.009904060372282,
          "to_longitude": 105.8386748331516,
          "from_longitude": 105.83879954279539
        }
      },
      {
        "id": 5150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83851931078738,
            21.00995226476278,
            105.838727201824,
            21.01007716535702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838727201824,
              21.01007716535702
            ],
            [
              105.83867031983472,
              21.010036894548023
            ],
            [
              105.83857014205276,
              21.00995226476278
            ],
            [
              105.83851931078738,
              21.00998330165602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.18_Ngõ 133, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5157",
          "diaChiLapD": "Ngõ 133, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01007716535702,
          "to_latitude": 21.00998330165602,
          "to_longitude": 105.83851931078738,
          "from_longitude": 105.838727201824
        }
      },
      {
        "id": 5151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83976501239457,
            21.010046082843054,
            105.83976976002467,
            21.01004912134372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83976976002467,
              21.01004912134372
            ],
            [
              105.83976830370393,
              21.010047745694344
            ],
            [
              105.83976501239457,
              21.010046082843054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.6_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5158",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01004912134372,
          "to_latitude": 21.010046082843054,
          "to_longitude": 105.83976501239457,
          "from_longitude": 105.83976976002467
        }
      },
      {
        "id": 5152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83988161934865,
            21.01034592748686,
            105.83995943703086,
            21.010448594406668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83989628778959,
              21.01035076086742
            ],
            [
              105.83989157536661,
              21.010349202581445
            ],
            [
              105.83989322304673,
              21.01034592748686
            ],
            [
              105.83989779988948,
              21.0103476661836
            ],
            [
              105.8399043894659,
              21.010350169912012
            ],
            [
              105.8399007001886,
              21.010357318173714
            ],
            [
              105.83988911893556,
              21.010378805234307
            ],
            [
              105.83988161934865,
              21.01039519422163
            ],
            [
              105.83988964554626,
              21.01040088014444
            ],
            [
              105.83995943703086,
              21.010448594406668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.7_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5159",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01035076086742,
          "to_latitude": 21.010448594406668,
          "to_longitude": 105.83995943703086,
          "from_longitude": 105.83989628778959
        }
      },
      {
        "id": 5153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84022988789968,
            21.0103971776842,
            105.84036861837465,
            21.010567818769182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84036861837465,
              21.0103971776842
            ],
            [
              105.8403312750522,
              21.010442796084384
            ],
            [
              105.84028873518264,
              21.01049969160119
            ],
            [
              105.84026628125059,
              21.010525755722995
            ],
            [
              105.84025384078875,
              21.010538778420234
            ],
            [
              105.84024841747718,
              21.010544084545298
            ],
            [
              105.84022988789968,
              21.010562212524786
            ],
            [
              105.84023101636888,
              21.01056309933804
            ],
            [
              105.84023703545535,
              21.010567818769182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT81.4_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5160",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0103971776842,
          "to_latitude": 21.010567818769182,
          "to_longitude": 105.84023703545535,
          "from_longitude": 105.84036861837465
        }
      },
      {
        "id": 5154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008538746072,
            21.010562212524786,
            105.84022988789968,
            21.010688737879786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84022988789968,
              21.010562212524786
            ],
            [
              105.84021180378456,
              21.010581046430488
            ],
            [
              105.8401848995166,
              21.01060718490354
            ],
            [
              105.84017679481344,
              21.010614108392016
            ],
            [
              105.84016676576763,
              21.01060478966959
            ],
            [
              105.84012504451022,
              21.010644188363695
            ],
            [
              105.84008538746072,
              21.010688737879786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT81.4_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5161",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010562212524786,
          "to_latitude": 21.010688737879786,
          "to_longitude": 105.84008538746072,
          "from_longitude": 105.84022988789968
        }
      },
      {
        "id": 5155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998622270171,
            21.010731175055014,
            105.84005101578714,
            21.01081052230239
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84004439563012,
              21.0107350706185
            ],
            [
              105.84004039553035,
              21.010732575789994
            ],
            [
              105.84003834941258,
              21.010731175055014
            ],
            [
              105.8400363510653,
              21.010733839496087
            ],
            [
              105.84004209028718,
              21.010737708575256
            ],
            [
              105.84005101578714,
              21.010743719726687
            ],
            [
              105.84004494318131,
              21.0107502294359
            ],
            [
              105.84003830070935,
              21.010757351634485
            ],
            [
              105.83998622270171,
              21.01081052230239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.3_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5162",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0107350706185,
          "to_latitude": 21.01081052230239,
          "to_longitude": 105.83998622270171,
          "from_longitude": 105.84004439563012
        }
      },
      {
        "id": 5156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84005101578714,
            21.010599412192178,
            105.84021096974148,
            21.010743719726687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84005101578714,
              21.010743719726687
            ],
            [
              105.84012577804452,
              21.01066593347616
            ],
            [
              105.84014919465638,
              21.01064017171512
            ],
            [
              105.84015879126906,
              21.01063032706066
            ],
            [
              105.84016732387313,
              21.010634885955152
            ],
            [
              105.84021096974148,
              21.010599412192178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.3_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5163",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010743719726687,
          "to_latitude": 21.010599412192178,
          "to_longitude": 105.84021096974148,
          "from_longitude": 105.84005101578714
        }
      },
      {
        "id": 5157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023253846797,
            21.010452239901557,
            105.84034175977713,
            21.010579825855654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84023253846797,
              21.010579825855654
            ],
            [
              105.84027303063863,
              21.01054270842713
            ],
            [
              105.8402740728005,
              21.010541753089075
            ],
            [
              105.84029434025584,
              21.010519454466213
            ],
            [
              105.84034175977713,
              21.010452239901557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.6_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5164",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010579825855654,
          "to_latitude": 21.010452239901557,
          "to_longitude": 105.84034175977713,
          "from_longitude": 105.84023253846797
        }
      },
      {
        "id": 5158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84034175977713,
            21.010356672237865,
            105.840432334879,
            21.010452239901557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84034175977713,
              21.010452239901557
            ],
            [
              105.84036641345764,
              21.010420147627478
            ],
            [
              105.8404043855782,
              21.010383754355292
            ],
            [
              105.840432334879,
              21.010356672237865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.6_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5165",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010452239901557,
          "to_latitude": 21.010356672237865,
          "to_longitude": 105.840432334879,
          "from_longitude": 105.84034175977713
        }
      },
      {
        "id": 5159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84051542483199,
            21.010094755187662,
            105.84074721647086,
            21.01029484440543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84074721647086,
              21.010094755187662
            ],
            [
              105.84071226899084,
              21.01012587501897
            ],
            [
              105.84070584106875,
              21.01013127825018
            ],
            [
              105.84070080806764,
              21.010135509430455
            ],
            [
              105.84069481959004,
              21.010140543781226
            ],
            [
              105.84068937203524,
              21.01014512294411
            ],
            [
              105.84066012854325,
              21.010169708661643
            ],
            [
              105.84056791011035,
              21.010245756853713
            ],
            [
              105.84056596356092,
              21.010247362466178
            ],
            [
              105.84051542483199,
              21.01029484440543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.8_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5166",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010094755187662,
          "to_latitude": 21.01029484440543,
          "to_longitude": 105.84051542483199,
          "from_longitude": 105.84074721647086
        }
      },
      {
        "id": 5160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84075629612336,
            21.00991070701719,
            105.84096473432955,
            21.010081930609232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84075629612336,
              21.010081930609232
            ],
            [
              105.84077093979468,
              21.010066086157686
            ],
            [
              105.84086733692132,
              21.009988052698922
            ],
            [
              105.84089141755562,
              21.00996925322329
            ],
            [
              105.84090972800001,
              21.00995564689432
            ],
            [
              105.84095321003667,
              21.009919498146882
            ],
            [
              105.84096473432955,
              21.00991070701719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.14_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5167",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010081930609232,
          "to_latitude": 21.00991070701719,
          "to_longitude": 105.84096473432955,
          "from_longitude": 105.84075629612336
        }
      },
      {
        "id": 5161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979830164279,
            21.010868679574475,
            105.83994696975977,
            21.01104586904781
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83994696975977,
              21.010868679574475
            ],
            [
              105.83993388785319,
              21.010891188366084
            ],
            [
              105.839926334041,
              21.01090493930494
            ],
            [
              105.83990359075042,
              21.010931572034284
            ],
            [
              105.83986732331468,
              21.010968216914502
            ],
            [
              105.83979830164279,
              21.01104586904781
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.2_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5168",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010868679574475,
          "to_latitude": 21.01104586904781,
          "to_longitude": 105.83979830164279,
          "from_longitude": 105.83994696975977
        }
      },
      {
        "id": 5162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013755222315,
            21.00850741115365,
            105.84027335320665,
            21.008655254825054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027335320665,
              21.00850741115365
            ],
            [
              105.8402324395115,
              21.008551953354
            ],
            [
              105.84013755222315,
              21.008655254825054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5169",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00850741115365,
          "to_latitude": 21.008655254825054,
          "to_longitude": 105.84013755222315,
          "from_longitude": 105.84027335320665
        }
      },
      {
        "id": 5163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84039845964864,
            21.010231836936455,
            105.8404764269189,
            21.0103040259157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8404764269189,
              21.0103040259157
            ],
            [
              105.84039845964864,
              21.010231836936455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.9_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5170",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0103040259157,
          "to_latitude": 21.010231836936455,
          "to_longitude": 105.84039845964864,
          "from_longitude": 105.8404764269189
        }
      },
      {
        "id": 5164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8410773291512,
            21.00953653373712,
            105.84114127955822,
            21.009628907333326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84114127955822,
              21.00953653373712
            ],
            [
              105.8411395804066,
              21.00959513608846
            ],
            [
              105.84107779415245,
              21.009598435673706
            ],
            [
              105.8410773291512,
              21.009628907333326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.16_Ngõ 386, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5171",
          "diaChiLapD": "Ngõ 386, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00953653373712,
          "to_latitude": 21.009628907333326,
          "to_longitude": 105.8410773291512,
          "from_longitude": 105.84114127955822
        }
      },
      {
        "id": 5165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83038831599828,
            21.018665699306634,
            105.83053264325106,
            21.018852277667307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83038831599828,
              21.018852277667307
            ],
            [
              105.83048591096872,
              21.018738061719354
            ],
            [
              105.83053264325106,
              21.018665699306634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.2_Ngõ 297, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5172",
          "diaChiLapD": "Ngõ 297, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018852277667307,
          "to_latitude": 21.018665699306634,
          "to_longitude": 105.83053264325106,
          "from_longitude": 105.83038831599828
        }
      },
      {
        "id": 5166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002965790614,
            21.01854073885908,
            105.8400365204671,
            21.01882839424488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8400365204671,
              21.01882839424488
            ],
            [
              105.8400338181456,
              21.01876292931865
            ],
            [
              105.84003359954636,
              21.018723903021694
            ],
            [
              105.84002983721845,
              21.018684725321478
            ],
            [
              105.84003032192871,
              21.018591279054856
            ],
            [
              105.84002965790614,
              21.01854073885908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67.4_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5173",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01882839424488,
          "to_latitude": 21.01854073885908,
          "to_longitude": 105.84002965790614,
          "from_longitude": 105.8400365204671
        }
      },
      {
        "id": 5167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84114997745652,
            21.0097713140015,
            105.84120904633879,
            21.01045978636141
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84114997745652,
              21.00977339106156
            ],
            [
              105.84115007458037,
              21.009773557677924
            ],
            [
              105.84115524105373,
              21.009782329541835
            ],
            [
              105.84118044913622,
              21.009773169080134
            ],
            [
              105.84118567390709,
              21.00977157886859
            ],
            [
              105.84119101768846,
              21.0097713140015
            ],
            [
              105.84119979809182,
              21.009808580072548
            ],
            [
              105.84120904633879,
              21.00989909520956
            ],
            [
              105.84120848200101,
              21.01004583667767
            ],
            [
              105.84119998795931,
              21.01038733979157
            ],
            [
              105.84119818561616,
              21.01045978636141
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.17_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5174",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00977339106156,
          "to_latitude": 21.01045978636141,
          "to_longitude": 105.84119818561616,
          "from_longitude": 105.84114997745652
        }
      },
      {
        "id": 5168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530436973635,
            21.013431680182883,
            105.83548175398373,
            21.013607809928892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83530436973635,
              21.01359251312256
            ],
            [
              105.83530675875394,
              21.013594168746526
            ],
            [
              105.83532083586437,
              21.013603900525712
            ],
            [
              105.83532648873378,
              21.013607809928892
            ],
            [
              105.83534849193093,
              21.01358140903107
            ],
            [
              105.83535007045306,
              21.013582273796004
            ],
            [
              105.83535146685225,
              21.013582735713673
            ],
            [
              105.83535179641957,
              21.013582841579325
            ],
            [
              105.83535360525758,
              21.013583090117443
            ],
            [
              105.83535543055089,
              21.013583011607405
            ],
            [
              105.83535720786254,
              21.013582608172086
            ],
            [
              105.83535887090203,
              21.013581894588498
            ],
            [
              105.83536035825881,
              21.01358089825491
            ],
            [
              105.83536158469126,
              21.013579685525247
            ],
            [
              105.83536161721968,
              21.01357965375267
            ],
            [
              105.83536260186516,
              21.01357820646849
            ],
            [
              105.83536327498288,
              21.01357661077855
            ],
            [
              105.83536361188051,
              21.01357492370724
            ],
            [
              105.83536360232421,
              21.013573207627314
            ],
            [
              105.83536324473219,
              21.013571523965787
            ],
            [
              105.83536270594271,
              21.013570294612695
            ],
            [
              105.83536255196124,
              21.013569935885297
            ],
            [
              105.83536155125631,
              21.013568499251978
            ],
            [
              105.83536027754738,
              21.01356726808782
            ],
            [
              105.83536076009143,
              21.01356668855808
            ],
            [
              105.83536199426038,
              21.013565310500315
            ],
            [
              105.83548175398373,
              21.013431680182883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.4_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5175",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01359251312256,
          "to_latitude": 21.013431680182883,
          "to_longitude": 105.83548175398373,
          "from_longitude": 105.83530436973635
        }
      },
      {
        "id": 5169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83492341093033,
            21.019140367010284,
            105.83512510479748,
            21.01915196004474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83492341093033,
              21.01915196004474
            ],
            [
              105.83493696052814,
              21.01915099573437
            ],
            [
              105.83506123934835,
              21.019144335890495
            ],
            [
              105.83510841288994,
              21.01914140456864
            ],
            [
              105.83512510479748,
              21.019140367010284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.9_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5176",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01915196004474,
          "to_latitude": 21.019140367010284,
          "to_longitude": 105.83512510479748,
          "from_longitude": 105.83492341093033
        }
      },
      {
        "id": 5170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704463749093,
            21.011605798906483,
            105.8372625788458,
            21.011783790676592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372625788458,
              21.011664018528336
            ],
            [
              105.83726133544288,
              21.011665287354504
            ],
            [
              105.83725658499675,
              21.01166191731485
            ],
            [
              105.83724738445075,
              21.011655390724712
            ],
            [
              105.83711699151596,
              21.011783790676592
            ],
            [
              105.83704463749093,
              21.011734826951873
            ],
            [
              105.83708864258445,
              21.011605798906483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.1_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5178",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011664018528336,
          "to_latitude": 21.011605798906483,
          "to_longitude": 105.83708864258445,
          "from_longitude": 105.8372625788458
        }
      },
      {
        "id": 5171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858079122945,
            21.018270339470252,
            105.83863062196666,
            21.018342873769207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863062196666,
              21.018342873769207
            ],
            [
              105.83859574611066,
              21.018301886703576
            ],
            [
              105.83858079122945,
              21.018270339470252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.5_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.5179",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018342873769207,
          "to_latitude": 21.018270339470252,
          "to_longitude": 105.83858079122945,
          "from_longitude": 105.83863062196666
        }
      },
      {
        "id": 5172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853223909856,
            21.018301886703576,
            105.83859574611066,
            21.018332884978044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859574611066,
              21.018301886703576
            ],
            [
              105.83853223909856,
              21.018332884978044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.5_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.5180",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018301886703576,
          "to_latitude": 21.018332884978044,
          "to_longitude": 105.83853223909856,
          "from_longitude": 105.83859574611066
        }
      },
      {
        "id": 5173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026002063347,
            21.01876296978591,
            105.83046506122047,
            21.018901563478998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83046241920178,
              21.018901563478998
            ],
            [
              105.8304631290108,
              21.018899110467657
            ],
            [
              105.83046506122047,
              21.018895049167504
            ],
            [
              105.83038831599828,
              21.018852277667307
            ],
            [
              105.83038410887677,
              21.01884993265833
            ],
            [
              105.83026002063347,
              21.01876296978591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.2_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5181",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018901563478998,
          "to_latitude": 21.01876296978591,
          "to_longitude": 105.83026002063347,
          "from_longitude": 105.83046241920178
        }
      },
      {
        "id": 5174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83316365540892,
            21.019226470627395,
            105.83360459606385,
            21.019252726215303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83316365540892,
              21.019252726215303
            ],
            [
              105.83345512329143,
              21.01923537091505
            ],
            [
              105.83360459606385,
              21.019226470627395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.7_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5182",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019252726215303,
          "to_latitude": 21.019226470627395,
          "to_longitude": 105.83360459606385,
          "from_longitude": 105.83316365540892
        }
      },
      {
        "id": 5175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360459606385,
            21.01921810169165,
            105.83377757725609,
            21.019226470627395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377757725609,
              21.01921810169165
            ],
            [
              105.83360459606385,
              21.019226470627395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.7_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5183",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01921810169165,
          "to_latitude": 21.019226470627395,
          "to_longitude": 105.83360459606385,
          "from_longitude": 105.83377757725609
        }
      },
      {
        "id": 5176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83566674896011,
            21.01910130208183,
            105.83581795736495,
            21.019109235545884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566674896011,
              21.019109235545884
            ],
            [
              105.83581153239643,
              21.0191016389406
            ],
            [
              105.83581795736495,
              21.01910130208183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.11_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5184",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019109235545884,
          "to_latitude": 21.01910130208183,
          "to_longitude": 105.83581795736495,
          "from_longitude": 105.83566674896011
        }
      },
      {
        "id": 5177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790069403756,
            21.016905517536433,
            105.83801294789782,
            21.017227117925692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83801294789782,
              21.017227117925692
            ],
            [
              105.8380117282126,
              21.017224374520595
            ],
            [
              105.83796887608676,
              21.017138922051863
            ],
            [
              105.83795586969971,
              21.017131188586607
            ],
            [
              105.83790069403756,
              21.016992853429425
            ],
            [
              105.83794157310743,
              21.016963974722746
            ],
            [
              105.83792279450213,
              21.016905517536433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.3_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5185",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017227117925692,
          "to_latitude": 21.016905517536433,
          "to_longitude": 105.83792279450213,
          "from_longitude": 105.83801294789782
        }
      },
      {
        "id": 5178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83625970288816,
            21.01323143840111,
            105.83645249350799,
            21.013561707845383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627802673821,
              21.013558370000816
            ],
            [
              105.83627632987019,
              21.01355991653254
            ],
            [
              105.8362744477537,
              21.013561707845383
            ],
            [
              105.83625970288816,
              21.0135419455077
            ],
            [
              105.836264214998,
              21.013538445916
            ],
            [
              105.83629274443311,
              21.013516317591748
            ],
            [
              105.83639592383612,
              21.01343259272293
            ],
            [
              105.8364402699949,
              21.01339802147839
            ],
            [
              105.83637892177072,
              21.013293172372986
            ],
            [
              105.83645249350799,
              21.01323143840111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.4_Ngõ 281, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5186",
          "diaChiLapD": "Ngõ 281, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013558370000816,
          "to_latitude": 21.01323143840111,
          "to_longitude": 105.83645249350799,
          "from_longitude": 105.83627802673821
        }
      },
      {
        "id": 5179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645767284644,
            21.013185334511245,
            105.83653235591623,
            21.013299727861934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650038345218,
              21.013185334511245
            ],
            [
              105.83645767284644,
              21.013223049079787
            ],
            [
              105.83653235591623,
              21.013299727861934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.6_Ngõ 281, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5187",
          "diaChiLapD": "Ngõ 281, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013185334511245,
          "to_latitude": 21.013299727861934,
          "to_longitude": 105.83653235591623,
          "from_longitude": 105.83650038345218
        }
      },
      {
        "id": 5180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83056545176748,
            21.018838482543945,
            105.8306824938947,
            21.01894031369611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83056545176748,
              21.01894031369611
            ],
            [
              105.83060982153359,
              21.01884919920368
            ],
            [
              105.83066774027276,
              21.018871467202047
            ],
            [
              105.8306824938947,
              21.018838482543945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.1_Ngõ 291, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5188",
          "diaChiLapD": "Ngõ 291, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01894031369611,
          "to_latitude": 21.018838482543945,
          "to_longitude": 105.8306824938947,
          "from_longitude": 105.83056545176748
        }
      },
      {
        "id": 5181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310979990805,
            21.018590253786115,
            105.83125201398514,
            21.018667994199227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83125201398514,
              21.018667994199227
            ],
            [
              105.83124927026276,
              21.01866344180509
            ],
            [
              105.8312484858039,
              21.018662143188184
            ],
            [
              105.8310979990805,
              21.018590253786115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.5_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.5189",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018667994199227,
          "to_latitude": 21.018590253786115,
          "to_longitude": 105.8310979990805,
          "from_longitude": 105.83125201398514
        }
      },
      {
        "id": 5182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83092303405205,
            21.01825499876661,
            105.83101860579134,
            21.018321208654076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101860579134,
              21.01825499876661
            ],
            [
              105.83101816785674,
              21.018255301679883
            ],
            [
              105.83092303405205,
              21.018321208654076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.6_Ngõ 324, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5190",
          "diaChiLapD": "Ngõ 324, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01825499876661,
          "to_latitude": 21.018321208654076,
          "to_longitude": 105.83092303405205,
          "from_longitude": 105.83101860579134
        }
      },
      {
        "id": 5183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83137613068116,
            21.018690401943147,
            105.83143764386983,
            21.01872287003748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83137613068116,
              21.01872287003748
            ],
            [
              105.83139497367553,
              21.01871405279291
            ],
            [
              105.8314150880966,
              21.018706226492316
            ],
            [
              105.83141997967627,
              21.018704322973562
            ],
            [
              105.83143764386983,
              21.018690401943147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.3_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.5191",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01872287003748,
          "to_latitude": 21.018690401943147,
          "to_longitude": 105.83143764386983,
          "from_longitude": 105.83137613068116
        }
      },
      {
        "id": 5184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736854543854,
            21.01874290109775,
            105.83737093137175,
            21.01874422883972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737093137175,
              21.01874290109775
            ],
            [
              105.83736904314692,
              21.018743951809686
            ],
            [
              105.83736854543854,
              21.01874422883972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65.2_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5192",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01874290109775,
          "to_latitude": 21.01874422883972,
          "to_longitude": 105.83736854543854,
          "from_longitude": 105.83737093137175
        }
      },
      {
        "id": 5185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83745176436086,
            21.01897138660234,
            105.83773093222354,
            21.018978089464586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773093222354,
              21.01897406546253
            ],
            [
              105.8376724508796,
              21.018977492160126
            ],
            [
              105.8375392230942,
              21.018978089464586
            ],
            [
              105.83748070922391,
              21.01897138660234
            ],
            [
              105.8374778870456,
              21.018971595592976
            ],
            [
              105.83745176436086,
              21.018973532453398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.5_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5193",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01897406546253,
          "to_latitude": 21.018973532453398,
          "to_longitude": 105.83745176436086,
          "from_longitude": 105.83773093222354
        }
      },
      {
        "id": 5186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83709511728108,
            21.018973532453398,
            105.83745254181164,
            21.019020519528915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83745254181164,
              21.018999405986094
            ],
            [
              105.83745176436086,
              21.018973532453398
            ],
            [
              105.83715181958078,
              21.01901512191776
            ],
            [
              105.83709511728108,
              21.019020519528915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.5_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5194",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018999405986094,
          "to_latitude": 21.019020519528915,
          "to_longitude": 105.83709511728108,
          "from_longitude": 105.83745254181164
        }
      },
      {
        "id": 5187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872646182415,
            21.016708647572706,
            105.8388321123786,
            21.016896537329238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872646182415,
              21.016708647572706
            ],
            [
              105.8387302988418,
              21.016715153543966
            ],
            [
              105.83873952186796,
              21.016730792552977
            ],
            [
              105.83873943752569,
              21.016749769699807
            ],
            [
              105.83876256865734,
              21.016793849490718
            ],
            [
              105.8388321123786,
              21.016896537329238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.1_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.5195",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016708647572706,
          "to_latitude": 21.016896537329238,
          "to_longitude": 105.8388321123786,
          "from_longitude": 105.83872646182415
        }
      },
      {
        "id": 5188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84026063182766,
            21.009188996899983,
            105.84038144330646,
            21.009402293610613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84038144330646,
              21.009188996899983
            ],
            [
              105.84029405489804,
              21.009252834784217
            ],
            [
              105.84032943360012,
              21.009298902454113
            ],
            [
              105.84026063182766,
              21.009351578621246
            ],
            [
              105.84029870871684,
              21.009402293610613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.4_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5196",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009188996899983,
          "to_latitude": 21.009402293610613,
          "to_longitude": 105.84029870871684,
          "from_longitude": 105.84038144330646
        }
      },
      {
        "id": 5189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958902765598,
            21.00913302124508,
            105.8396973424645,
            21.009241189318807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8396973424645,
              21.00913302124508
            ],
            [
              105.83958902765598,
              21.009241189318807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.9_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5197",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00913302124508,
          "to_latitude": 21.009241189318807,
          "to_longitude": 105.83958902765598,
          "from_longitude": 105.8396973424645
        }
      },
      {
        "id": 5190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914177944287,
            21.0165478030899,
            105.83933104408307,
            21.016735456681182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933104408307,
              21.0165478030899
            ],
            [
              105.83914177944287,
              21.016708439350438
            ],
            [
              105.83914236796163,
              21.016709093091123
            ],
            [
              105.83916089354338,
              21.01673015611258
            ],
            [
              105.83915860729776,
              21.016732443513643
            ],
            [
              105.83915567483925,
              21.016735456681182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.10_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.5198",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0165478030899,
          "to_latitude": 21.016735456681182,
          "to_longitude": 105.83915567483925,
          "from_longitude": 105.83933104408307
        }
      },
      {
        "id": 5191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83582979327825,
            21.01373288189451,
            105.83590511410593,
            21.013773927744563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590511410593,
              21.01373288189451
            ],
            [
              105.83584882625958,
              21.01376397644977
            ],
            [
              105.83582979327825,
              21.013773927744563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.3_Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5199",
          "diaChiLapD": "Ngõ 308, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01373288189451,
          "to_latitude": 21.013773927744563,
          "to_longitude": 105.83582979327825,
          "from_longitude": 105.83590511410593
        }
      },
      {
        "id": 5192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573338471332,
            21.013462921149316,
            105.83580335343198,
            21.01350739640061
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83580335343198,
              21.013462921149316
            ],
            [
              105.83576893938918,
              21.01349359932051
            ],
            [
              105.8357580357758,
              21.01348130128405
            ],
            [
              105.83573338471332,
              21.01350739640061
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.1_Ngõ 66, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5200",
          "diaChiLapD": "Ngõ 66, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013462921149316,
          "to_latitude": 21.01350739640061,
          "to_longitude": 105.83573338471332,
          "from_longitude": 105.83580335343198
        }
      },
      {
        "id": 5193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732368084651,
            21.01693534995474,
            105.83739545007462,
            21.016987474206474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83732368084651,
              21.016987474206474
            ],
            [
              105.83739545007462,
              21.01693534995474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5201",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016987474206474,
          "to_latitude": 21.01693534995474,
          "to_longitude": 105.83739545007462,
          "from_longitude": 105.83732368084651
        }
      },
      {
        "id": 5194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736084687179,
            21.01703147785604,
            105.83742856304947,
            21.017060030370203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83736084687179,
              21.017059897833743
            ],
            [
              105.83738456629688,
              21.017060030370203
            ],
            [
              105.83742856304947,
              21.01703147785604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5202",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017059897833743,
          "to_latitude": 21.01703147785604,
          "to_longitude": 105.83742856304947,
          "from_longitude": 105.83736084687179
        }
      },
      {
        "id": 5195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723989707548,
            21.017487748638466,
            105.83732112276022,
            21.017673259221503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724689760295,
              21.017487748638466
            ],
            [
              105.83724941794526,
              21.01749275274001
            ],
            [
              105.83732112276022,
              21.0176351182308
            ],
            [
              105.83723989707548,
              21.017673259221503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.6_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5203",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017487748638466,
          "to_latitude": 21.017673259221503,
          "to_longitude": 105.83723989707548,
          "from_longitude": 105.83724689760295
        }
      },
      {
        "id": 5196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370790985679,
            21.017208996241003,
            105.83723466775754,
            21.017255469451026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723466775754,
              21.017208996241003
            ],
            [
              105.8370790985679,
              21.017255469451026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.6_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5204",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017208996241003,
          "to_latitude": 21.017255469451026,
          "to_longitude": 105.8370790985679,
          "from_longitude": 105.83723466775754
        }
      },
      {
        "id": 5197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83457111220176,
            21.014764077003175,
            105.83473362270422,
            21.014860609796134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83457111220176,
              21.014860609796134
            ],
            [
              105.83473362270422,
              21.0147909190834
            ],
            [
              105.83472111444236,
              21.014764077003175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT42.9_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5205",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014860609796134,
          "to_latitude": 21.014764077003175,
          "to_longitude": 105.83472111444236,
          "from_longitude": 105.83457111220176
        }
      },
      {
        "id": 5198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356329281759,
            21.015975896303953,
            105.83581059329353,
            21.01611543296495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356329281759,
              21.01611543296495
            ],
            [
              105.83581059329353,
              21.015975896303953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.9_Ngách 218/125, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5206",
          "diaChiLapD": "Ngách 218/125, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01611543296495,
          "to_latitude": 21.015975896303953,
          "to_longitude": 105.83581059329353,
          "from_longitude": 105.8356329281759
        }
      },
      {
        "id": 5199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842000875785,
            21.017706155642088,
            105.8384547408935,
            21.017769992310072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384547408935,
              21.017769992310072
            ],
            [
              105.83842000875785,
              21.017706155642088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.1_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.5207",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017769992310072,
          "to_latitude": 21.017706155642088,
          "to_longitude": 105.83842000875785,
          "from_longitude": 105.8384547408935
        }
      },
      {
        "id": 5200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820796286079,
            21.01770630012787,
            105.83827577393134,
            21.01773790206641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820796286079,
              21.01773790206641
            ],
            [
              105.83827577393134,
              21.01770630012787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.3_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.5208",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01773790206641,
          "to_latitude": 21.01770630012787,
          "to_longitude": 105.83827577393134,
          "from_longitude": 105.83820796286079
        }
      },
      {
        "id": 5201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375724266979,
            21.01711397911686,
            105.83759883808,
            21.017177747654863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375724266979,
              21.01711397911686
            ],
            [
              105.83759883808,
              21.017177747654863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT60A.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5209",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01711397911686,
          "to_latitude": 21.017177747654863,
          "to_longitude": 105.83759883808,
          "from_longitude": 105.8375724266979
        }
      },
      {
        "id": 5202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83748167621142,
            21.017163859780453,
            105.83763147149214,
            21.017207254737155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748167621142,
              21.017207254737155
            ],
            [
              105.83759883808,
              21.017177747654863
            ],
            [
              105.83763147149214,
              21.017163859780453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT60A.2_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5210",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017207254737155,
          "to_latitude": 21.017163859780453,
          "to_longitude": 105.83763147149214,
          "from_longitude": 105.83748167621142
        }
      },
      {
        "id": 5203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83775997707231,
            21.017527605933832,
            105.83788516114281,
            21.017589530962823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83788516114281,
              21.017527605933832
            ],
            [
              105.83788163989733,
              21.017529752175786
            ],
            [
              105.83778356074549,
              21.017589530962823
            ],
            [
              105.83775997707231,
              21.01755929697842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.1_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5211",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017527605933832,
          "to_latitude": 21.01755929697842,
          "to_longitude": 105.83775997707231,
          "from_longitude": 105.83788516114281
        }
      },
      {
        "id": 5204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929020177861,
            21.017076203455183,
            105.83939835297276,
            21.01717137361817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929020177861,
              21.017076203455183
            ],
            [
              105.83939835297276,
              21.01717137361817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.4_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.5212",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017076203455183,
          "to_latitude": 21.01717137361817,
          "to_longitude": 105.83939835297276,
          "from_longitude": 105.83929020177861
        }
      },
      {
        "id": 5205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388321123786,
            21.016805140335002,
            105.83891155043115,
            21.016896537329238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388321123786,
              21.016896537329238
            ],
            [
              105.83891155043115,
              21.016841459936852
            ],
            [
              105.83890771883475,
              21.016805140335002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58.1_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DODV.5213",
          "diaChiLapD": "Ngõ Xã Đằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016896537329238,
          "to_latitude": 21.016805140335002,
          "to_longitude": 105.83890771883475,
          "from_longitude": 105.8388321123786
        }
      },
      {
        "id": 5206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924093624174,
            21.017436614142483,
            105.83928689997644,
            21.017485083778542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83928689997644,
              21.017485083778542
            ],
            [
              105.83924093624174,
              21.017436614142483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55.4_Ngõ Gia Tự B",
          "maTaiSan": "04.O16A.DODV.5214",
          "diaChiLapD": "Ngõ Gia Tự B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017485083778542,
          "to_latitude": 21.017436614142483,
          "to_longitude": 105.83924093624174,
          "from_longitude": 105.83928689997644
        }
      },
      {
        "id": 5207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84007992232438,
            21.017844949983868,
            105.84021306146853,
            21.017847525926637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84021306146853,
              21.017845550586138
            ],
            [
              105.84011996637226,
              21.017844949983868
            ],
            [
              105.84008452617635,
              21.017847175321133
            ],
            [
              105.84007992232438,
              21.017847525926637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.8A3_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5215",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017845550586138,
          "to_latitude": 21.017847525926637,
          "to_longitude": 105.84007992232438,
          "from_longitude": 105.84021306146853
        }
      },
      {
        "id": 5208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84011242202142,
            21.017686484179027,
            105.84011996637226,
            21.017844949983868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84011996637226,
              21.017844949983868
            ],
            [
              105.84011996312728,
              21.017844885871032
            ],
            [
              105.84011242202142,
              21.017686484179027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.8A3_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5216",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017844949983868,
          "to_latitude": 21.017686484179027,
          "to_longitude": 105.84011242202142,
          "from_longitude": 105.84011996637226
        }
      },
      {
        "id": 5209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008576808323,
            21.01813397332383,
            105.8402279767161,
            21.0181361107123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8402279767161,
              21.0181361107123
            ],
            [
              105.84014481850626,
              21.018135185564564
            ],
            [
              105.84009764859597,
              21.018134218135465
            ],
            [
              105.84008576808323,
              21.01813397332383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.8A1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5217",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0181361107123,
          "to_latitude": 21.01813397332383,
          "to_longitude": 105.84008576808323,
          "from_longitude": 105.8402279767161
        }
      },
      {
        "id": 5210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84014461361011,
            21.018135185564564,
            105.84014481850626,
            21.01818944862314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84014481850626,
              21.018135185564564
            ],
            [
              105.84014481674315,
              21.01813555770129
            ],
            [
              105.84014461361011,
              21.01818944862314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.8A1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5218",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018135185564564,
          "to_latitude": 21.01818944862314,
          "to_longitude": 105.84014461361011,
          "from_longitude": 105.84014481850626
        }
      },
      {
        "id": 5211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008890692924,
            21.017990405821195,
            105.84020100916221,
            21.01799184017172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84020100916221,
              21.01799184017172
            ],
            [
              105.8400994976084,
              21.01799153674233
            ],
            [
              105.84009138240836,
              21.01799098882045
            ],
            [
              105.84008890692924,
              21.017990405821195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.8A2_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5219",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01799184017172,
          "to_latitude": 21.017990405821195,
          "to_longitude": 105.84008890692924,
          "from_longitude": 105.84020100916221
        }
      },
      {
        "id": 5212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009383610196,
            21.01826348009812,
            105.84020809144926,
            21.018263738769342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84020809144926,
              21.018263723291998
            ],
            [
              105.84015082650362,
              21.018263738769342
            ],
            [
              105.84010315381715,
              21.018263523501478
            ],
            [
              105.84009383610196,
              21.01826348009812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.10A1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5220",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018263723291998,
          "to_latitude": 21.01826348009812,
          "to_longitude": 105.84009383610196,
          "from_longitude": 105.84020809144926
        }
      },
      {
        "id": 5213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015082650362,
            21.018237519716468,
            105.84015125006302,
            21.018263738769342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015082650362,
              21.018263738769342
            ],
            [
              105.84015125006302,
              21.018237519716468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.10A1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5221",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018263738769342,
          "to_latitude": 21.018237519716468,
          "to_longitude": 105.84015125006302,
          "from_longitude": 105.84015082650362
        }
      },
      {
        "id": 5214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83891664620695,
            21.017908637814394,
            105.8390058817686,
            21.018099030802095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891664620695,
              21.017908637814394
            ],
            [
              105.83893427232793,
              21.01794308564429
            ],
            [
              105.8390058817686,
              21.018099030802095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.7_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5222",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017908637814394,
          "to_latitude": 21.018099030802095,
          "to_longitude": 105.8390058817686,
          "from_longitude": 105.83891664620695
        }
      },
      {
        "id": 5215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879142937451,
            21.017700434139034,
            105.83881110049785,
            21.0177100258201
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881110049785,
              21.017700434139034
            ],
            [
              105.83881020256297,
              21.01770087211396
            ],
            [
              105.83880496652323,
              21.017703425151698
            ],
            [
              105.83879142937451,
              21.0177100258201
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.8_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5223",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017700434139034,
          "to_latitude": 21.0177100258201,
          "to_longitude": 105.83879142937451,
          "from_longitude": 105.83881110049785
        }
      },
      {
        "id": 5216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84011032926895,
            21.018474390256657,
            105.84012243367587,
            21.018538669507883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84011032926895,
              21.018538669507883
            ],
            [
              105.84011497808322,
              21.018538614032945
            ],
            [
              105.84012243367587,
              21.01853852211544
            ],
            [
              105.84011481166691,
              21.018474390256657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.10A2_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5224",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018538669507883,
          "to_latitude": 21.018474390256657,
          "to_longitude": 105.84011481166691,
          "from_longitude": 105.84011032926895
        }
      },
      {
        "id": 5217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926962416271,
            21.018654659793395,
            105.8392922070948,
            21.01866771079213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83926962416271,
              21.01866771079213
            ],
            [
              105.83928392163654,
              21.018659448014958
            ],
            [
              105.83929163082574,
              21.018654993216416
            ],
            [
              105.8392922070948,
              21.018654659793395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.2_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5225",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01866771079213,
          "to_latitude": 21.018654659793395,
          "to_longitude": 105.8392922070948,
          "from_longitude": 105.83926962416271
        }
      },
      {
        "id": 5218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83864016662966,
            21.01813989352345,
            105.83899874277701,
            21.01834995585854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83864016662966,
              21.01834995585854
            ],
            [
              105.83869367261312,
              21.018304926499706
            ],
            [
              105.83872221651357,
              21.018284261818454
            ],
            [
              105.83899874277701,
              21.01813989352345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT52.5A_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.5226",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01834995585854,
          "to_latitude": 21.01813989352345,
          "to_longitude": 105.83899874277701,
          "from_longitude": 105.83864016662966
        }
      },
      {
        "id": 5219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783912026323,
            21.01780292576553,
            105.8378424400652,
            21.017806045407987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783912026323,
              21.01780292576553
            ],
            [
              105.83783938717195,
              21.017803178256838
            ],
            [
              105.83784169448653,
              21.017805344565534
            ],
            [
              105.8378424400652,
              21.017806045407987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.3_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5227",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01780292576553,
          "to_latitude": 21.017806045407987,
          "to_longitude": 105.8378424400652,
          "from_longitude": 105.83783912026323
        }
      },
      {
        "id": 5220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83742894257837,
            21.0180142612641,
            105.83744356373043,
            21.018030708347624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742894257837,
              21.0180142612641
            ],
            [
              105.83742926687582,
              21.01801462727976
            ],
            [
              105.83743274694434,
              21.018018540978275
            ],
            [
              105.83744356373043,
              21.018030708347624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.6_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5228",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0180142612641,
          "to_latitude": 21.018030708347624,
          "to_longitude": 105.83744356373043,
          "from_longitude": 105.83742894257837
        }
      },
      {
        "id": 5221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83917239641706,
            21.01673891754453,
            105.83924323953141,
            21.016813739097262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923898135811,
              21.016813739097262
            ],
            [
              105.83924240902319,
              21.016811035097515
            ],
            [
              105.83924323953141,
              21.01681058209828
            ],
            [
              105.83924147536081,
              21.016809556610216
            ],
            [
              105.83922493046148,
              21.016794006136912
            ],
            [
              105.83917239641706,
              21.01673891754453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.9_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.5229",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016813739097262,
          "to_latitude": 21.01673891754453,
          "to_longitude": 105.83917239641706,
          "from_longitude": 105.83923898135811
        }
      },
      {
        "id": 5222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83761001046949,
            21.018756524543353,
            105.83770111615918,
            21.018760179615104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83770111615918,
              21.018756524543353
            ],
            [
              105.83761001046949,
              21.018760179615104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.6_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.5230",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018756524543353,
          "to_latitude": 21.018760179615104,
          "to_longitude": 105.83761001046949,
          "from_longitude": 105.83770111615918
        }
      },
      {
        "id": 5223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010103176453,
            21.018348281579073,
            105.84021875994391,
            21.01838176162951
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84021863201454,
              21.018348281579073
            ],
            [
              105.84021875994391,
              21.01838176162951
            ],
            [
              105.84013109733482,
              21.018381108920142
            ],
            [
              105.840108710481,
              21.018381402835775
            ],
            [
              105.84010103176453,
              21.018381502176936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.10_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5231",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018348281579073,
          "to_latitude": 21.018381502176936,
          "to_longitude": 105.84010103176453,
          "from_longitude": 105.84021863201454
        }
      },
      {
        "id": 5224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013109733482,
            21.018381108920142,
            105.8401329020305,
            21.01841889990394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84013109733482,
              21.018381108920142
            ],
            [
              105.8401329020305,
              21.01841889990394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.10_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5232",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018381108920142,
          "to_latitude": 21.01841889990394,
          "to_longitude": 105.8401329020305,
          "from_longitude": 105.84013109733482
        }
      },
      {
        "id": 5225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372354708618,
            21.01822620673969,
            105.8372516058769,
            21.018230999173955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372354708618,
              21.018230999173955
            ],
            [
              105.83723648788296,
              21.018230697007848
            ],
            [
              105.83724324401805,
              21.01822869021199
            ],
            [
              105.8372516058769,
              21.01822620673969
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.9_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5233",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018230999173955,
          "to_latitude": 21.01822620673969,
          "to_longitude": 105.8372516058769,
          "from_longitude": 105.8372354708618
        }
      },
      {
        "id": 5226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919024431673,
            21.01817118226183,
            105.83920002523503,
            21.01818111553382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919024431673,
              21.01817118226183
            ],
            [
              105.83919246751701,
              21.018173440191884
            ],
            [
              105.83919732606327,
              21.018178376727334
            ],
            [
              105.83920002523503,
              21.01818111553382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6A2_Ngõ Miếu Chợ",
          "maTaiSan": "04.O16A.DODV.5234",
          "diaChiLapD": "Ngõ Miếu Chợ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01817118226183,
          "to_latitude": 21.01818111553382,
          "to_longitude": 105.83920002523503,
          "from_longitude": 105.83919024431673
        }
      },
      {
        "id": 5227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924323953141,
            21.01681058209828,
            105.83937365202418,
            21.016924011323386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924323953141,
              21.01681058209828
            ],
            [
              105.83924854577454,
              21.016815081071865
            ],
            [
              105.8392543668407,
              21.01682064421264
            ],
            [
              105.83928343543302,
              21.01684842577205
            ],
            [
              105.83932907643815,
              21.016885839733217
            ],
            [
              105.83936030483403,
              21.016913143655984
            ],
            [
              105.83937051075215,
              21.016922198697788
            ],
            [
              105.83937365202418,
              21.016924011323386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.9_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.5235",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01681058209828,
          "to_latitude": 21.016924011323386,
          "to_longitude": 105.83937365202418,
          "from_longitude": 105.83924323953141
        }
      },
      {
        "id": 5228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83906516000829,
            21.01778619810222,
            105.83920279162268,
            21.017874800402367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920279162268,
              21.01778619810222
            ],
            [
              105.83919683147296,
              21.017790168268927
            ],
            [
              105.8391736529858,
              21.01780575753922
            ],
            [
              105.83912760745964,
              21.017836726792943
            ],
            [
              105.83906516000829,
              21.017874800402367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6_Ngõ Gia Tự A",
          "maTaiSan": "04.O16A.DODV.5236",
          "diaChiLapD": "Ngõ Gia Tự A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01778619810222,
          "to_latitude": 21.017874800402367,
          "to_longitude": 105.83906516000829,
          "from_longitude": 105.83920279162268
        }
      },
      {
        "id": 5229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83860584539653,
            21.017280708937616,
            105.83862256062281,
            21.017289403575276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83862256062281,
              21.017280708937616
            ],
            [
              105.83862181440047,
              21.01728109738954
            ],
            [
              105.8386116598043,
              21.017286378919653
            ],
            [
              105.83860584539653,
              21.017289403575276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5237",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017280708937616,
          "to_latitude": 21.017289403575276,
          "to_longitude": 105.83860584539653,
          "from_longitude": 105.83862256062281
        }
      },
      {
        "id": 5230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837473140437,
            21.01681356534678,
            105.83838669271871,
            21.016818981314298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837473140437,
              21.016818981314298
            ],
            [
              105.83837626854849,
              21.01681828548252
            ],
            [
              105.83838016801532,
              21.01681651943059
            ],
            [
              105.83838669271871,
              21.01681356534678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.15_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5238",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016818981314298,
          "to_latitude": 21.01681356534678,
          "to_longitude": 105.83838669271871,
          "from_longitude": 105.83837473140437
        }
      },
      {
        "id": 5231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820316265266,
            21.016512786274614,
            105.83821056717923,
            21.016517179005923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820316265266,
              21.016517179005923
            ],
            [
              105.83820438507162,
              21.016516453113578
            ],
            [
              105.83820867170161,
              21.016513910192042
            ],
            [
              105.83821056717923,
              21.016512786274614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.21_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5239",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016517179005923,
          "to_latitude": 21.016512786274614,
          "to_longitude": 105.83821056717923,
          "from_longitude": 105.83820316265266
        }
      },
      {
        "id": 5232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723287711827,
            21.01847218264138,
            105.83727718407779,
            21.01860129267172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723287711827,
              21.01847218264138
            ],
            [
              105.83723359391433,
              21.018474275496015
            ],
            [
              105.83727718407779,
              21.01860129267172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65.3_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5240",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01847218264138,
          "to_latitude": 21.01860129267172,
          "to_longitude": 105.83727718407779,
          "from_longitude": 105.83723287711827
        }
      },
      {
        "id": 5233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83727718407779,
            21.01860129267172,
            105.83734298135026,
            21.018724913689876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83727718407779,
              21.01860129267172
            ],
            [
              105.83734298135026,
              21.018724913689876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65.3_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5241",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01860129267172,
          "to_latitude": 21.018724913689876,
          "to_longitude": 105.83734298135026,
          "from_longitude": 105.83727718407779
        }
      },
      {
        "id": 5234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712127142228,
            21.01860129267172,
            105.83727718407779,
            21.018666105797834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83727718407779,
              21.01860129267172
            ],
            [
              105.83720257100639,
              21.01863989607484
            ],
            [
              105.83718238405363,
              21.018640700842923
            ],
            [
              105.83712127142228,
              21.018666105797834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65.3_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5242",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01860129267172,
          "to_latitude": 21.018666105797834,
          "to_longitude": 105.83712127142228,
          "from_longitude": 105.83727718407779
        }
      },
      {
        "id": 5235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979609491512,
            21.018536873763406,
            105.83982951032996,
            21.01857911065935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979609491512,
              21.018536873763406
            ],
            [
              105.83979747449305,
              21.018544628351236
            ],
            [
              105.83980178243866,
              21.018545027985787
            ],
            [
              105.83981377365171,
              21.018546139364258
            ],
            [
              105.83982951032996,
              21.01857466675737
            ],
            [
              105.83982766936937,
              21.01857608035853
            ],
            [
              105.83982254950128,
              21.01857911065935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5243",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018536873763406,
          "to_latitude": 21.01857911065935,
          "to_longitude": 105.83982254950128,
          "from_longitude": 105.83979609491512
        }
      },
      {
        "id": 5236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83827178189533,
            21.01651929043821,
            105.8384941021599,
            21.01663647199745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827178189533,
              21.01663647199745
            ],
            [
              105.83827259165464,
              21.016636264782026
            ],
            [
              105.83830005905243,
              21.016629225191817
            ],
            [
              105.8384941021599,
              21.01651929043821
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.17_Ngõ 113, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5244",
          "diaChiLapD": "Ngõ 113, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01663647199745,
          "to_latitude": 21.01651929043821,
          "to_longitude": 105.8384941021599,
          "from_longitude": 105.83827178189533
        }
      },
      {
        "id": 5237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83729918604989,
            21.018605796237363,
            105.8373917950041,
            21.018770079364526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83729918604989,
              21.018605796237363
            ],
            [
              105.8373292902901,
              21.0186731360367
            ],
            [
              105.8373702801994,
              21.01874205256316
            ],
            [
              105.83737093137175,
              21.01874290109775
            ],
            [
              105.8373917950041,
              21.018770079364526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65.2_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5245",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018605796237363,
          "to_latitude": 21.018770079364526,
          "to_longitude": 105.8373917950041,
          "from_longitude": 105.83729918604989
        }
      },
      {
        "id": 5238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838763258416,
            21.01825625823704,
            105.83876479889705,
            21.018258908828447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838763258416,
              21.01825625823704
            ],
            [
              105.83876394414808,
              21.018257437175603
            ],
            [
              105.83876471440628,
              21.018258765632503
            ],
            [
              105.83876479889705,
              21.018258908828447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.5_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.5246",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01825625823704,
          "to_latitude": 21.018258908828447,
          "to_longitude": 105.83876479889705,
          "from_longitude": 105.838763258416
        }
      },
      {
        "id": 5239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83232117835593,
            21.01869652068253,
            105.83252592216627,
            21.01908051735393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83252592216627,
              21.01908051735393
            ],
            [
              105.83252045174667,
              21.019066178340008
            ],
            [
              105.8325128826194,
              21.019046692184716
            ],
            [
              105.8324929561753,
              21.019000173380757
            ],
            [
              105.83247537618183,
              21.018948495613746
            ],
            [
              105.83245385862239,
              21.01888740835188
            ],
            [
              105.83244800438375,
              21.01887895933443
            ],
            [
              105.83244226693766,
              21.01887248780713
            ],
            [
              105.8324195617512,
              21.01881198622168
            ],
            [
              105.83241491053842,
              21.01879376203836
            ],
            [
              105.83239415124164,
              21.01871243159835
            ],
            [
              105.83239038798915,
              21.018697685903103
            ],
            [
              105.83233138280177,
              21.01871870814396
            ],
            [
              105.83232117835593,
              21.01869652068253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.6_Ngõ 231, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5247",
          "diaChiLapD": "Ngõ 231, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01908051735393,
          "to_latitude": 21.01869652068253,
          "to_longitude": 105.83232117835593,
          "from_longitude": 105.83252592216627
        }
      },
      {
        "id": 5240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84065061252716,
            21.017875225757887,
            105.84085930985253,
            21.018769327008616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84065195670196,
              21.018769327008616
            ],
            [
              105.84065169470277,
              21.01876754895366
            ],
            [
              105.84065061252716,
              21.018760174064766
            ],
            [
              105.84079453361716,
              21.018751256982952
            ],
            [
              105.8408047115835,
              21.01875082732219
            ],
            [
              105.84081239404834,
              21.018609669757836
            ],
            [
              105.84081369961153,
              21.01850212374349
            ],
            [
              105.84081376798524,
              21.018446202146734
            ],
            [
              105.84081466644895,
              21.018402105961204
            ],
            [
              105.84080999780495,
              21.018348521835552
            ],
            [
              105.840810078033,
              21.018296601458093
            ],
            [
              105.84081074889968,
              21.018240048238653
            ],
            [
              105.84081320817197,
              21.018197760719445
            ],
            [
              105.84081479916732,
              21.018178873683393
            ],
            [
              105.84081869855493,
              21.018135398532994
            ],
            [
              105.8408252147614,
              21.01808305705377
            ],
            [
              105.84083620050494,
              21.018003662719654
            ],
            [
              105.84085930985253,
              21.017875225757887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT67.1_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5248",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 114,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018769327008616,
          "to_latitude": 21.017875225757887,
          "to_longitude": 105.84085930985253,
          "from_longitude": 105.84065195670196
        }
      },
      {
        "id": 5241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84067136543212,
            21.010399057614094,
            105.8406781141133,
            21.010406092001237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84067136543212,
              21.010399057614094
            ],
            [
              105.8406781141133,
              21.010406092001237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.11_Ngõ 354, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5249",
          "diaChiLapD": "Ngõ 354, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010399057614094,
          "to_latitude": 21.010406092001237,
          "to_longitude": 105.8406781141133,
          "from_longitude": 105.84067136543212
        }
      },
      {
        "id": 5242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8406153557631,
            21.010384858404116,
            105.84080711352169,
            21.01046617831566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84080711352169,
              21.01041513449203
            ],
            [
              105.84078225382108,
              21.01040701269753
            ],
            [
              105.84074235149912,
              21.010393841232535
            ],
            [
              105.84070474562847,
              21.010384858404116
            ],
            [
              105.8406781141133,
              21.010406092001237
            ],
            [
              105.84064724871257,
              21.01043495346401
            ],
            [
              105.8406153557631,
              21.01046617831566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.11_Ngõ 354, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5250",
          "diaChiLapD": "Ngõ 354, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01041513449203,
          "to_latitude": 21.01046617831566,
          "to_longitude": 105.8406153557631,
          "from_longitude": 105.84080711352169
        }
      },
      {
        "id": 5243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83942529363621,
            21.01839724885495,
            105.83953260513964,
            21.01844048893391
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83942529363621,
              21.018432277110254
            ],
            [
              105.83948613063997,
              21.01839724885495
            ],
            [
              105.83953260513964,
              21.01844048893391
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.1_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.5251",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018432277110254,
          "to_latitude": 21.01844048893391,
          "to_longitude": 105.83953260513964,
          "from_longitude": 105.83942529363621
        }
      },
      {
        "id": 5244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933155743784,
            21.017569422837138,
            105.83949436875922,
            21.017702696586337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933155743784,
              21.017702696586337
            ],
            [
              105.83933625639631,
              21.01769957353884
            ],
            [
              105.83938617190132,
              21.017666395721413
            ],
            [
              105.83949436875922,
              21.017569422837138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.6_Ngõ Gia Tự A",
          "maTaiSan": "04.O16A.DODV.5252",
          "diaChiLapD": "Ngõ Gia Tự A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017702696586337,
          "to_latitude": 21.017569422837138,
          "to_longitude": 105.83949436875922,
          "from_longitude": 105.83933155743784
        }
      },
      {
        "id": 5245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84061272052043,
            21.00980424097414,
            105.8406875068192,
            21.00985704160269
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84061272052043,
              21.009815039911217
            ],
            [
              105.84062460294602,
              21.00980424097414
            ],
            [
              105.8406875068192,
              21.00985704160269
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.10_Ngách 378/15, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5253",
          "diaChiLapD": "Ngách 378/15, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009815039911217,
          "to_latitude": 21.00985704160269,
          "to_longitude": 105.8406875068192,
          "from_longitude": 105.84061272052043
        }
      },
      {
        "id": 5246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836455195419,
            21.01096132750017,
            105.83840328616641,
            21.011018617483202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836455195419,
              21.011018617483202
            ],
            [
              105.83840328616641,
              21.01096132750017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.4_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5254",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011018617483202,
          "to_latitude": 21.01096132750017,
          "to_longitude": 105.83840328616641,
          "from_longitude": 105.83836455195419
        }
      },
      {
        "id": 5247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837972394319,
            21.018137546620277,
            105.83851446283361,
            21.018191372658364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83851446283361,
              21.018137546620277
            ],
            [
              105.83845342023481,
              21.018163010407385
            ],
            [
              105.83837972394319,
              21.018191372658364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.4_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.5255",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018137546620277,
          "to_latitude": 21.018191372658364,
          "to_longitude": 105.83837972394319,
          "from_longitude": 105.83851446283361
        }
      },
      {
        "id": 5248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83744082590496,
            21.01867755886662,
            105.83754804061408,
            21.01871357347462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754804061408,
              21.01867755886662
            ],
            [
              105.83744082590496,
              21.01871357347462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.6_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.5256",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01867755886662,
          "to_latitude": 21.01871357347462,
          "to_longitude": 105.83744082590496,
          "from_longitude": 105.83754804061408
        }
      },
      {
        "id": 5249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920038210617,
            21.01164263911784,
            105.83932327652047,
            21.011907839380832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920038210617,
              21.011907839380832
            ],
            [
              105.8392314352902,
              21.01181735206371
            ],
            [
              105.83931953073063,
              21.011666690583578
            ],
            [
              105.83931881941794,
              21.011652853084968
            ],
            [
              105.83932218431562,
              21.011645140113757
            ],
            [
              105.83932327652047,
              21.01164263911784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.12A_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5257",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011907839380832,
          "to_latitude": 21.01164263911784,
          "to_longitude": 105.83932327652047,
          "from_longitude": 105.83920038210617
        }
      },
      {
        "id": 5250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83968376272526,
            21.010989537259096,
            105.84011474962722,
            21.011449769952787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83968376272526,
              21.011449769952787
            ],
            [
              105.8398553297327,
              21.01129597757123
            ],
            [
              105.83996198215125,
              21.011166762465162
            ],
            [
              105.8399809136482,
              21.011180371824338
            ],
            [
              105.84004781098751,
              21.01111347034448
            ],
            [
              105.84011203060481,
              21.010994569899893
            ],
            [
              105.84011474962722,
              21.010989537259096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.1A_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5258",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011449769952787,
          "to_latitude": 21.010989537259096,
          "to_longitude": 105.84011474962722,
          "from_longitude": 105.83968376272526
        }
      },
      {
        "id": 5251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84059072808017,
            21.010682143432334,
            105.84111099746411,
            21.010946184018135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84110814368697,
              21.010946184018135
            ],
            [
              105.84111099746411,
              21.01072601358941
            ],
            [
              105.84100070151757,
              21.01071671360893
            ],
            [
              105.84059072808017,
              21.010682143432334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.1B_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5259",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010946184018135,
          "to_latitude": 21.010682143432334,
          "to_longitude": 105.84059072808017,
          "from_longitude": 105.84110814368697
        }
      },
      {
        "id": 5252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743867594669,
            21.012306161048095,
            105.8375948198683,
            21.012447929412815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375948198683,
              21.012447929412815
            ],
            [
              105.83747828458763,
              21.012402066466993
            ],
            [
              105.83743867594669,
              21.012333854810894
            ],
            [
              105.83745409090857,
              21.012306161048095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.5_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5260",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012447929412815,
          "to_latitude": 21.012306161048095,
          "to_longitude": 105.83745409090857,
          "from_longitude": 105.8375948198683
        }
      },
      {
        "id": 5253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778262625701,
            21.012114678347135,
            105.83791954333078,
            21.01234650536046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791954333078,
              21.012114678347135
            ],
            [
              105.83791714804455,
              21.012118273233895
            ],
            [
              105.83791520635373,
              21.01212110266227
            ],
            [
              105.83778262625701,
              21.012314274168183
            ],
            [
              105.83784323471131,
              21.01234650536046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75.8A_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5261",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012114678347135,
          "to_latitude": 21.01234650536046,
          "to_longitude": 105.83784323471131,
          "from_longitude": 105.83791954333078
        }
      },
      {
        "id": 5254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837557953353,
            21.011592462268982,
            105.83853292692281,
            21.011682759652405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837557953353,
              21.011628542183715
            ],
            [
              105.83839421980588,
              21.011592462268982
            ],
            [
              105.83840173948576,
              21.011597358628972
            ],
            [
              105.83853292692281,
              21.011682759652405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.3_Ngõ 178, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5262",
          "diaChiLapD": "Ngõ 178, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011628542183715,
          "to_latitude": 21.011682759652405,
          "to_longitude": 105.83853292692281,
          "from_longitude": 105.83837557953353
        }
      },
      {
        "id": 5255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83840173948576,
            21.011543994712145,
            105.83844052269922,
            21.011597358628972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83840173948576,
              21.011597358628972
            ],
            [
              105.83844052269922,
              21.011543994712145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.3_Ngõ 178, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5263",
          "diaChiLapD": "Ngõ 178, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011597358628972,
          "to_latitude": 21.011543994712145,
          "to_longitude": 105.83844052269922,
          "from_longitude": 105.83840173948576
        }
      },
      {
        "id": 5256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83830247952498,
            21.011494333660398,
            105.83836077605619,
            21.011587291698234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836077605619,
              21.011494333660398
            ],
            [
              105.83834600850557,
              21.011512695809827
            ],
            [
              105.83834560608479,
              21.011532430535105
            ],
            [
              105.83830247952498,
              21.011587291698234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.3_Ngõ 178, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5264",
          "diaChiLapD": "Ngõ 178, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011494333660398,
          "to_latitude": 21.011587291698234,
          "to_longitude": 105.83830247952498,
          "from_longitude": 105.83836077605619
        }
      },
      {
        "id": 5257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381387949504,
            21.011341039284797,
            105.83821998100166,
            21.01144951180777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8381387949504,
              21.01144951180777
            ],
            [
              105.83821998100166,
              21.011341039284797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.3_Ngõ 178, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5265",
          "diaChiLapD": "Ngõ 178, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01144951180777,
          "to_latitude": 21.011341039284797,
          "to_longitude": 105.83821998100166,
          "from_longitude": 105.8381387949504
        }
      },
      {
        "id": 5258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850731210491,
            21.01093314377503,
            105.83853962449247,
            21.011009596845934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83851947330756,
              21.011009596845934
            ],
            [
              105.83850731210491,
              21.01100119536813
            ],
            [
              105.83853962449247,
              21.01095025866827
            ],
            [
              105.83851078612908,
              21.01093314377503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.6_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5266",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011009596845934,
          "to_latitude": 21.01093314377503,
          "to_longitude": 105.83851078612908,
          "from_longitude": 105.83851947330756
        }
      },
      {
        "id": 5259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859440974796,
            21.010979968328385,
            105.83863894501111,
            21.01105642283303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83861240579408,
              21.010979968328385
            ],
            [
              105.83863894501111,
              21.010999454660467
            ],
            [
              105.83859440974796,
              21.01105642283303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.6_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5267",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010979968328385,
          "to_latitude": 21.01105642283303,
          "to_longitude": 105.83859440974796,
          "from_longitude": 105.83861240579408
        }
      },
      {
        "id": 5260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83898467384293,
            21.01024149885242,
            105.83906707331613,
            21.010334538024058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83898467384293,
              21.010334538024058
            ],
            [
              105.83906707331613,
              21.01024149885242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.20_Ngõ 116, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5268",
          "diaChiLapD": "Ngõ 116, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010334538024058,
          "to_latitude": 21.01024149885242,
          "to_longitude": 105.83906707331613,
          "from_longitude": 105.83898467384293
        }
      },
      {
        "id": 5261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945117693472,
            21.010412857395725,
            105.83953848746249,
            21.01051905410331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953848746249,
              21.010412857395725
            ],
            [
              105.83952399550186,
              21.010416306050118
            ],
            [
              105.83945117693472,
              21.01050198024816
            ],
            [
              105.83946889452582,
              21.01051905410331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.17_Ngõ 110, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5269",
          "diaChiLapD": "Ngõ 110, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010412857395725,
          "to_latitude": 21.01051905410331,
          "to_longitude": 105.83946889452582,
          "from_longitude": 105.83953848746249
        }
      },
      {
        "id": 5262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965049997398,
            21.010130492896042,
            105.83973789753493,
            21.010322400454335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83973789753493,
              21.010130492896042
            ],
            [
              105.83970124818808,
              21.010187386299613
            ],
            [
              105.83965049997398,
              21.010260047302896
            ],
            [
              105.83972719986502,
              21.010322400454335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.6A_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5270",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010130492896042,
          "to_latitude": 21.010322400454335,
          "to_longitude": 105.83972719986502,
          "from_longitude": 105.83973789753493
        }
      },
      {
        "id": 5263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83978884879393,
            21.010385896204728,
            105.83986577406237,
            21.010453296761405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83978884879393,
              21.010453296761405
            ],
            [
              105.83982033284558,
              21.010407761586887
            ],
            [
              105.83984239500596,
              21.010392119050042
            ],
            [
              105.83985293436189,
              21.010385896204728
            ],
            [
              105.83986577406237,
              21.01039418585047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.8_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5271",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010453296761405,
          "to_latitude": 21.01039418585047,
          "to_longitude": 105.83986577406237,
          "from_longitude": 105.83978884879393
        }
      },
      {
        "id": 5264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399260865592,
            21.009823108916663,
            105.83999818803565,
            21.009954946442434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83999818803565,
              21.009954946442434
            ],
            [
              105.8399260865592,
              21.00987497590258
            ],
            [
              105.83999036171343,
              21.009823108916663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.11_Ngõ 78, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5272",
          "diaChiLapD": "Ngõ 78, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009954946442434,
          "to_latitude": 21.009823108916663,
          "to_longitude": 105.83999036171343,
          "from_longitude": 105.83999818803565
        }
      },
      {
        "id": 5265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83960259814704,
            21.009442286871195,
            105.83969994565716,
            21.009513088694607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83960259814704,
              21.009499738983656
            ],
            [
              105.83962144233475,
              21.009513088694607
            ],
            [
              105.83969994565716,
              21.009442286871195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.11_Ngõ 78, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5273",
          "diaChiLapD": "Ngõ 78, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009499738983656,
          "to_latitude": 21.009442286871195,
          "to_longitude": 105.83969994565716,
          "from_longitude": 105.83960259814704
        }
      },
      {
        "id": 5266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83999624959702,
            21.008673659176413,
            105.84006373618926,
            21.008735395620693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83999624959702,
              21.008673659176413
            ],
            [
              105.84006373618926,
              21.008735395620693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.6_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5274",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008673659176413,
          "to_latitude": 21.008735395620693,
          "to_longitude": 105.84006373618926,
          "from_longitude": 105.83999624959702
        }
      },
      {
        "id": 5267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8409699668692,
            21.00846810061546,
            105.84101718155404,
            21.008574612136197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84098032583832,
              21.00846810061546
            ],
            [
              105.84101718155404,
              21.008552871229625
            ],
            [
              105.8409699668692,
              21.008574612136197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.6_Ngõ 452, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5275",
          "diaChiLapD": "Ngõ 452, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00846810061546,
          "to_latitude": 21.008574612136197,
          "to_longitude": 105.8409699668692,
          "from_longitude": 105.84098032583832
        }
      },
      {
        "id": 5268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83840526333577,
            21.016335649088404,
            105.83846948202064,
            21.016368772479698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83840526333577,
              21.016368772479698
            ],
            [
              105.83846948202064,
              21.016335649088404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.20_Ngõ 113, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5276",
          "diaChiLapD": "Ngõ 113, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016368772479698,
          "to_latitude": 21.016335649088404,
          "to_longitude": 105.83846948202064,
          "from_longitude": 105.83840526333577
        }
      },
      {
        "id": 5269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958142931907,
            21.01775439245578,
            105.83979967614867,
            21.01784189743386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979967614867,
              21.017805544983517
            ],
            [
              105.83976720707709,
              21.01784189743386
            ],
            [
              105.83972310863227,
              21.017814791706705
            ],
            [
              105.83966905556697,
              21.017767343026343
            ],
            [
              105.83964873468017,
              21.017774612211852
            ],
            [
              105.83958142931907,
              21.01775439245578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.2_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.5277",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017805544983517,
          "to_latitude": 21.01775439245578,
          "to_longitude": 105.83958142931907,
          "from_longitude": 105.83979967614867
        }
      },
      {
        "id": 5270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83956164008193,
            21.01791162391377,
            105.83969984020426,
            21.018008837742748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969305578596,
              21.018008837742748
            ],
            [
              105.83969984020426,
              21.01800003576082
            ],
            [
              105.83956164008193,
              21.01791162391377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.2A_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.5278",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018008837742748,
          "to_latitude": 21.01791162391377,
          "to_longitude": 105.83956164008193,
          "from_longitude": 105.83969305578596
        }
      },
      {
        "id": 5271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947259388238,
            21.018174326042168,
            105.83952808755537,
            21.01821687581247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947259388238,
              21.018174326042168
            ],
            [
              105.83952808755537,
              21.01821687581247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.1_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.5279",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018174326042168,
          "to_latitude": 21.01821687581247,
          "to_longitude": 105.83952808755537,
          "from_longitude": 105.83947259388238
        }
      },
      {
        "id": 5272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84099925768894,
            21.01071671360893,
            105.84100070151757,
            21.010731297876745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84100070151757,
              21.01071671360893
            ],
            [
              105.84099925768894,
              21.010731297876745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.1B_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5280",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01071671360893,
          "to_latitude": 21.010731297876745,
          "to_longitude": 105.84099925768894,
          "from_longitude": 105.84100070151757
        }
      },
      {
        "id": 5273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967543195006,
            21.018008837742748,
            105.83973205911822,
            21.01814766755998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83973205911822,
              21.01814766755998
            ],
            [
              105.8396803247331,
              21.018041720111373
            ],
            [
              105.83967611020395,
              21.018033089840085
            ],
            [
              105.83967543195006,
              21.01803170132059
            ],
            [
              105.83969305578596,
              21.018008837742748
            ],
            [
              105.83970149047883,
              21.018016108605224
            ],
            [
              105.83970288529184,
              21.01801730842676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53.2A_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DODV.5281",
          "diaChiLapD": "Ngõ Đình Tương Thuận",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01814766755998,
          "to_latitude": 21.01801730842676,
          "to_longitude": 105.83970288529184,
          "from_longitude": 105.83973205911822
        }
      },
      {
        "id": 5274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842858294608,
            21.01106885096673,
            105.83843737773431,
            21.01108002563922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83843737773431,
              21.01106885096673
            ],
            [
              105.83843695269583,
              21.01106938957635
            ],
            [
              105.83843505964528,
              21.01107179606165
            ],
            [
              105.83842858294608,
              21.01108002563922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.5_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5282",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01106885096673,
          "to_latitude": 21.01108002563922,
          "to_longitude": 105.83842858294608,
          "from_longitude": 105.83843737773431
        }
      },
      {
        "id": 5275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825615141942,
            21.01104024903148,
            105.83861540922122,
            21.011314689982182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825615141942,
              21.011167806013844
            ],
            [
              105.83827436377435,
              21.01114363271698
            ],
            [
              105.83830245168393,
              21.01111457851502
            ],
            [
              105.83835104227042,
              21.01106566435855
            ],
            [
              105.83837128882314,
              21.01104024903148
            ],
            [
              105.83839537190259,
              21.011056054218823
            ],
            [
              105.8384242420856,
              21.011076795420234
            ],
            [
              105.83842858294608,
              21.01108002563922
            ],
            [
              105.83843457553208,
              21.011084484233617
            ],
            [
              105.83846808807236,
              21.01110749583921
            ],
            [
              105.83851256107127,
              21.011141952376295
            ],
            [
              105.83858551470553,
              21.011200756761603
            ],
            [
              105.83861540922122,
              21.011225944873264
            ],
            [
              105.83859253033361,
              21.011254183132362
            ],
            [
              105.83854325908479,
              21.011314689982182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.5_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5283",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011167806013844,
          "to_latitude": 21.011314689982182,
          "to_longitude": 105.83854325908479,
          "from_longitude": 105.83825615141942
        }
      },
      {
        "id": 5276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83846035205612,
            21.011086776959274,
            105.83880121734649,
            21.01136001950878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83846035205612,
              21.011086776959274
            ],
            [
              105.83848921487788,
              21.01110843586139
            ],
            [
              105.83850015944421,
              21.011116351078204
            ],
            [
              105.83851493444543,
              21.01112721360731
            ],
            [
              105.83852600000353,
              21.01113577945089
            ],
            [
              105.83852980956428,
              21.011138778350254
            ],
            [
              105.838538395625,
              21.01114567641445
            ],
            [
              105.83855858246484,
              21.011161727487423
            ],
            [
              105.83858166269806,
              21.011179322360498
            ],
            [
              105.8385844121323,
              21.011181957967594
            ],
            [
              105.83863969288517,
              21.01123494775624
            ],
            [
              105.8386696849076,
              21.011259680152165
            ],
            [
              105.83872862784719,
              21.01130426451855
            ],
            [
              105.83880121734649,
              21.01136001950878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.6_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5284",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011086776959274,
          "to_latitude": 21.01136001950878,
          "to_longitude": 105.83880121734649,
          "from_longitude": 105.83846035205612
        }
      },
      {
        "id": 5277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857873574415,
            21.011181957967594,
            105.8385844121323,
            21.011188122448537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857873574415,
              21.011188122448537
            ],
            [
              105.83858147880663,
              21.011185143644504
            ],
            [
              105.83858387193087,
              21.01118254411279
            ],
            [
              105.8385844121323,
              21.011181957967594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.6_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5285",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011188122448537,
          "to_latitude": 21.011181957967594,
          "to_longitude": 105.8385844121323,
          "from_longitude": 105.83857873574415
        }
      },
      {
        "id": 5278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862825952095,
            21.01123888255172,
            105.83884508755852,
            21.011407927031463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83862825952095,
              21.01123888255172
            ],
            [
              105.83864999391776,
              21.01125696727283
            ],
            [
              105.83868570209145,
              21.01128591664688
            ],
            [
              105.83871081732629,
              21.01130687591696
            ],
            [
              105.83871778536594,
              21.011312894077236
            ],
            [
              105.83872914261909,
              21.01132270220878
            ],
            [
              105.83877283962362,
              21.011357528304252
            ],
            [
              105.83878363100925,
              21.01136728773752
            ],
            [
              105.83881655862643,
              21.01139043583093
            ],
            [
              105.83884508755852,
              21.011407927031463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.7_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5286",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01123888255172,
          "to_latitude": 21.011407927031463,
          "to_longitude": 105.83884508755852,
          "from_longitude": 105.83862825952095
        }
      },
      {
        "id": 5279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871778536594,
            21.011304775034983,
            105.83872492350726,
            21.011312894077236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871778536594,
              21.011312894077236
            ],
            [
              105.83872196903864,
              21.011308136043983
            ],
            [
              105.83872431463618,
              21.011305466293116
            ],
            [
              105.83872492350726,
              21.011304775034983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.7_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5287",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011312894077236,
          "to_latitude": 21.011304775034983,
          "to_longitude": 105.83872492350726,
          "from_longitude": 105.83871778536594
        }
      },
      {
        "id": 5280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84006748309996,
            21.017991172655638,
            105.84008890737685,
            21.017992083228272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84008890737685,
              21.017991172655638
            ],
            [
              105.84008724841182,
              21.01799124407391
            ],
            [
              105.84008224937729,
              21.017991455728335
            ],
            [
              105.84006748309996,
              21.017992083228272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.7_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5288",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017991172655638,
          "to_latitude": 21.017992083228272,
          "to_longitude": 105.84006748309996,
          "from_longitude": 105.84008890737685
        }
      },
      {
        "id": 5281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400511425036,
            21.017749425340917,
            105.84008301543655,
            21.01822455764098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8400511425036,
              21.017749425340917
            ],
            [
              105.84005987426745,
              21.0177769305362
            ],
            [
              105.84006199723802,
              21.017846647093474
            ],
            [
              105.84006559403605,
              21.017937508057543
            ],
            [
              105.84006748309996,
              21.017992083228272
            ],
            [
              105.84006783664692,
              21.01800230687437
            ],
            [
              105.8400719626856,
              21.018055242485953
            ],
            [
              105.8400769244426,
              21.018136646280475
            ],
            [
              105.84008301543655,
              21.01822455764098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.7_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5289",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017749425340917,
          "to_latitude": 21.01822455764098,
          "to_longitude": 105.84008301543655,
          "from_longitude": 105.8400511425036
        }
      },
      {
        "id": 5282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009383311403,
            21.018419734139783,
            105.8401026848806,
            21.01856499015625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009383311403,
              21.018419734139783
            ],
            [
              105.84009731194429,
              21.01846840521362
            ],
            [
              105.84010015395866,
              21.01851776226887
            ],
            [
              105.84010088552529,
              21.018531414489505
            ],
            [
              105.8401026848806,
              21.01856499015625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.9_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5290",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018419734139783,
          "to_latitude": 21.01856499015625,
          "to_longitude": 105.8401026848806,
          "from_longitude": 105.84009383311403
        }
      },
      {
        "id": 5283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401026848806,
            21.01856499015625,
            105.84011437878868,
            21.01878949901019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401026848806,
              21.01856499015625
            ],
            [
              105.84010314885776,
              21.0185736326153
            ],
            [
              105.84010859303741,
              21.018663185611004
            ],
            [
              105.84011069870388,
              21.018720015958106
            ],
            [
              105.84011437878868,
              21.01878949901019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.9_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5291",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01856499015625,
          "to_latitude": 21.01878949901019,
          "to_longitude": 105.84011437878868,
          "from_longitude": 105.8401026848806
        }
      },
      {
        "id": 5284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769799813702,
            21.018647453955595,
            105.83774792557026,
            21.01887149749722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769799813702,
              21.018647453955595
            ],
            [
              105.8377086712506,
              21.01875805443113
            ],
            [
              105.83772397693458,
              21.01880971751744
            ],
            [
              105.83774792557026,
              21.01887149749722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.5_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.5292",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018647453955595,
          "to_latitude": 21.01887149749722,
          "to_longitude": 105.83774792557026,
          "from_longitude": 105.83769799813702
        }
      },
      {
        "id": 5285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83782234605322,
            21.017788682347533,
            105.83783576282858,
            21.017804951071096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783576282858,
              21.017804951071096
            ],
            [
              105.83783559625256,
              21.017804750472607
            ],
            [
              105.83783397398581,
              21.017802781305573
            ],
            [
              105.83782234605322,
              21.017788682347533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.5_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5293",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017804951071096,
          "to_latitude": 21.017788682347533,
          "to_longitude": 105.83782234605322,
          "from_longitude": 105.83783576282858
        }
      },
      {
        "id": 5286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83935919824387,
            21.017006415986963,
            105.83948482340007,
            21.01713428237926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83935919824387,
              21.017006415986963
            ],
            [
              105.83948482340007,
              21.01713428237926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.6_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.5294",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017006415986963,
          "to_latitude": 21.01713428237926,
          "to_longitude": 105.83948482340007,
          "from_longitude": 105.83935919824387
        }
      },
      {
        "id": 5287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886777980058,
            21.016856585566764,
            105.83897249143955,
            21.016928954283618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886777980058,
              21.016928954283618
            ],
            [
              105.83895384200835,
              21.01686995853922
            ],
            [
              105.83897249143955,
              21.016856585566764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.12_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.5295",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016928954283618,
          "to_latitude": 21.016856585566764,
          "to_longitude": 105.83897249143955,
          "from_longitude": 105.83886777980058
        }
      },
      {
        "id": 5288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856163512476,
            21.014137881361606,
            105.83856533920807,
            21.01414127006637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856163512476,
              21.014137881361606
            ],
            [
              105.83856206152399,
              21.014138273065704
            ],
            [
              105.83856426888143,
              21.014140290827488
            ],
            [
              105.83856533920807,
              21.01414127006637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.7_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5296",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014137881361606,
          "to_latitude": 21.01414127006637,
          "to_longitude": 105.83856533920807,
          "from_longitude": 105.83856163512476
        }
      },
      {
        "id": 5289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909815670191,
            21.01489093208866,
            105.83943870212453,
            21.01510296702916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943870212453,
              21.01489093208866
            ],
            [
              105.83936571600991,
              21.01498090915166
            ],
            [
              105.83933074257558,
              21.014959161353385
            ],
            [
              105.83930090735649,
              21.01494060277348
            ],
            [
              105.83927355984179,
              21.014972271032846
            ],
            [
              105.83918886693385,
              21.015037499633248
            ],
            [
              105.83912137734623,
              21.015092445946852
            ],
            [
              105.83910720922259,
              21.01510296702916
            ],
            [
              105.83910135301856,
              21.015097987536095
            ],
            [
              105.83909815670191,
              21.015095269241854
            ],
            [
              105.8391021096421,
              21.01509223839742
            ],
            [
              105.8391044538523,
              21.015094470443678
            ],
            [
              105.83910534966192,
              21.01509520305614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.29_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5297",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01489093208866,
          "to_latitude": 21.01509520305614,
          "to_longitude": 105.83910534966192,
          "from_longitude": 105.83943870212453
        }
      },
      {
        "id": 5290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756275706145,
            21.01634761127921,
            105.83786039584905,
            21.016440186222304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756275706145,
              21.016380125649533
            ],
            [
              105.83760140485167,
              21.016355129228653
            ],
            [
              105.83761710604269,
              21.016377420265407
            ],
            [
              105.83768974452379,
              21.016440186222304
            ],
            [
              105.83773026663496,
              21.016414067771358
            ],
            [
              105.83774512244436,
              21.016367493937967
            ],
            [
              105.83777905254807,
              21.01634761127921
            ],
            [
              105.83782503071916,
              21.01639622668747
            ],
            [
              105.83785760115119,
              21.01640571090669
            ],
            [
              105.83786039584905,
              21.01640902913591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.2_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5298",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016380125649533,
          "to_latitude": 21.01640902913591,
          "to_longitude": 105.83786039584905,
          "from_longitude": 105.83756275706145
        }
      },
      {
        "id": 5291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83861181980484,
            21.014719454788917,
            105.83861388871061,
            21.014720622395117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83861388871061,
              21.014720622395117
            ],
            [
              105.83861212860369,
              21.014719630298714
            ],
            [
              105.83861181980484,
              21.014719454788917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.5_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5299",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014720622395117,
          "to_latitude": 21.014719454788917,
          "to_longitude": 105.83861181980484,
          "from_longitude": 105.83861388871061
        }
      },
      {
        "id": 5292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867899748807,
            21.016082271104114,
            105.83902183746353,
            21.016187022699306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867899748807,
              21.016082936642565
            ],
            [
              105.83871269028246,
              21.01612765083028
            ],
            [
              105.83871885134133,
              21.01613708353229
            ],
            [
              105.83873043677625,
              21.016133442423545
            ],
            [
              105.83875450349248,
              21.016122426375876
            ],
            [
              105.83876822548669,
              21.016118630215086
            ],
            [
              105.8387870941829,
              21.01610663630897
            ],
            [
              105.83879712704788,
              21.01610368388131
            ],
            [
              105.83880970729187,
              21.016117789823056
            ],
            [
              105.83882260897981,
              21.016121946975147
            ],
            [
              105.83883343385473,
              21.016118882253387
            ],
            [
              105.83885246751672,
              21.016109038102634
            ],
            [
              105.83885764051549,
              21.016105714925523
            ],
            [
              105.8388733198266,
              21.016096890793783
            ],
            [
              105.83888093303271,
              21.016092838423845
            ],
            [
              105.8388955484707,
              21.016084880308288
            ],
            [
              105.83890118430568,
              21.016082271104114
            ],
            [
              105.83890514703543,
              21.016088380840234
            ],
            [
              105.83892143963237,
              21.016114909471888
            ],
            [
              105.83892723607839,
              21.016123473256254
            ],
            [
              105.83895443347448,
              21.016164148594168
            ],
            [
              105.83896138082744,
              21.016175711727104
            ],
            [
              105.83896506083055,
              21.016181835500998
            ],
            [
              105.83896533075823,
              21.016182283975287
            ],
            [
              105.83896817767041,
              21.016187022699306
            ],
            [
              105.83897027284803,
              21.016186175981755
            ],
            [
              105.83901126917443,
              21.016163546757674
            ],
            [
              105.83901919623096,
              21.01615868083561
            ],
            [
              105.83902183746353,
              21.016156228202238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT90.5A_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5300",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016082936642565,
          "to_latitude": 21.016156228202238,
          "to_longitude": 105.83902183746353,
          "from_longitude": 105.83867899748807
        }
      },
      {
        "id": 5293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809574412618,
            21.01417772103675,
            105.83833479680423,
            21.014447432774254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383317438288,
              21.01417772103675
            ],
            [
              105.83833191145906,
              21.014177938791008
            ],
            [
              105.83833479680423,
              21.014181647663534
            ],
            [
              105.8383254392295,
              21.01418854111338
            ],
            [
              105.83828724754748,
              21.014213100987465
            ],
            [
              105.83826762220349,
              21.014232532097356
            ],
            [
              105.83825099119822,
              21.014247635557254
            ],
            [
              105.8382388617079,
              21.014261824452063
            ],
            [
              105.83822032256201,
              21.014280257561943
            ],
            [
              105.83818849457231,
              21.01430543819028
            ],
            [
              105.83817375596661,
              21.014318702387953
            ],
            [
              105.83815674709672,
              21.01433556176177
            ],
            [
              105.83814262249719,
              21.014349396479332
            ],
            [
              105.83811676868054,
              21.014372967925304
            ],
            [
              105.83809574412618,
              21.014390755718495
            ],
            [
              105.83814365123479,
              21.014447432774254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.4_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5301",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01417772103675,
          "to_latitude": 21.014447432774254,
          "to_longitude": 105.83814365123479,
          "from_longitude": 105.8383317438288
        }
      },
      {
        "id": 5294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83866024936879,
            21.01625070088092,
            105.83881740457319,
            21.016308146764054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83866024936879,
              21.016308146764054
            ],
            [
              105.83866769571611,
              21.016293345959305
            ],
            [
              105.83869814026293,
              21.016275989459547
            ],
            [
              105.83871579195936,
              21.016266029297952
            ],
            [
              105.83873082921282,
              21.016259529629316
            ],
            [
              105.83874577627934,
              21.01625444846539
            ],
            [
              105.83877070267447,
              21.0162550734411
            ],
            [
              105.83879653553913,
              21.016257820132616
            ],
            [
              105.83881193197433,
              21.01625070088092
            ],
            [
              105.83881704647524,
              21.016258761322767
            ],
            [
              105.83881740457319,
              21.016259323168768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5302",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016308146764054,
          "to_latitude": 21.016259323168768,
          "to_longitude": 105.83881740457319,
          "from_longitude": 105.83866024936879
        }
      },
      {
        "id": 5295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765717043642,
            21.01560464582289,
            105.8377528902414,
            21.015767627120677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774211350848,
              21.015767627120677
            ],
            [
              105.83774494459841,
              21.01576609666668
            ],
            [
              105.8377528902414,
              21.01576179883922
            ],
            [
              105.83774503511819,
              21.01575026902636
            ],
            [
              105.83772617661326,
              21.015725515098662
            ],
            [
              105.83768199130057,
              21.01565132958495
            ],
            [
              105.83765717043642,
              21.01560464582289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.27_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5303",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015767627120677,
          "to_latitude": 21.01560464582289,
          "to_longitude": 105.83765717043642,
          "from_longitude": 105.83774211350848
        }
      },
      {
        "id": 5296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84129587014515,
            21.01567418553127,
            105.84131446236431,
            21.015674515395823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84129587014515,
              21.01567418553127
            ],
            [
              105.8412993729067,
              21.01567424859672
            ],
            [
              105.84130403394344,
              21.015674330321346
            ],
            [
              105.84131446236431,
              21.015674515395823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.7_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5304",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01567418553127,
          "to_latitude": 21.015674515395823,
          "to_longitude": 105.84131446236431,
          "from_longitude": 105.84129587014515
        }
      },
      {
        "id": 5297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83996773196017,
            21.015044595898367,
            105.8401494326784,
            21.015354697677854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401307597858,
              21.015354697677854
            ],
            [
              105.84012550546338,
              21.01535362440125
            ],
            [
              105.8401236152232,
              21.01535275851176
            ],
            [
              105.84012989558893,
              21.015337970630394
            ],
            [
              105.84013928142423,
              21.015296968508004
            ],
            [
              105.84014329773963,
              21.015263350560904
            ],
            [
              105.84014760223135,
              21.015213860647535
            ],
            [
              105.84014852890895,
              21.015197605271158
            ],
            [
              105.8401487142142,
              21.01518517780486
            ],
            [
              105.84014808452864,
              21.015118266491832
            ],
            [
              105.8401494326784,
              21.015086236942125
            ],
            [
              105.84014854444764,
              21.01506401660652
            ],
            [
              105.84012536992601,
              21.015061501761345
            ],
            [
              105.84004921812408,
              21.015054893158272
            ],
            [
              105.84001734816857,
              21.015049362917352
            ],
            [
              105.84000260847041,
              21.015046096474926
            ],
            [
              105.83998377131066,
              21.015045472319805
            ],
            [
              105.83996773196017,
              21.015044595898367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.11A_Ngách 51, Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5305",
          "diaChiLapD": "Ngách 51, Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015354697677854,
          "to_latitude": 21.015044595898367,
          "to_longitude": 105.83996773196017,
          "from_longitude": 105.8401307597858
        }
      },
      {
        "id": 5298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452534113854,
            21.014452008838656,
            105.83483268519515,
            21.014641171187982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452534113854,
              21.014523372409435
            ],
            [
              105.83459646635258,
              21.014463232756754
            ],
            [
              105.83461944133126,
              21.014456883285987
            ],
            [
              105.83464074691084,
              21.014452008838656
            ],
            [
              105.83465715781902,
              21.01445968703077
            ],
            [
              105.8347150438335,
              21.01449512557918
            ],
            [
              105.83474955037694,
              21.014512449936575
            ],
            [
              105.83478243331471,
              21.01453083360655
            ],
            [
              105.83481477287883,
              21.01455891605273
            ],
            [
              105.83482288208334,
              21.01456740992916
            ],
            [
              105.83483268519515,
              21.01458367225762
            ],
            [
              105.83483194823967,
              21.014594885794857
            ],
            [
              105.83482672521008,
              21.0146077209554
            ],
            [
              105.83481508540399,
              21.014641171187982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.2_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5306",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014523372409435,
          "to_latitude": 21.014641171187982,
          "to_longitude": 105.83481508540399,
          "from_longitude": 105.83452534113854
        }
      },
      {
        "id": 5299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372912291305,
            21.01616848305459,
            105.83745102151543,
            21.01622540047383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372912291305,
              21.01622540047383
            ],
            [
              105.83733862604964,
              21.016190459909875
            ],
            [
              105.8373448275019,
              21.016190773515177
            ],
            [
              105.83734895250491,
              21.016190982631024
            ],
            [
              105.83737011324833,
              21.01617926482256
            ],
            [
              105.83739928639928,
              21.01616848305459
            ],
            [
              105.83740759387813,
              21.016169030161723
            ],
            [
              105.83744480204825,
              21.01618735497141
            ],
            [
              105.83745102151543,
              21.01619520227581
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.4_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5307",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01622540047383,
          "to_latitude": 21.01619520227581,
          "to_longitude": 105.83745102151543,
          "from_longitude": 105.8372912291305
        }
      },
      {
        "id": 5300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84128597051853,
            21.014993384800363,
            105.84130840550611,
            21.01509965741251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84130032012669,
              21.01509965741251
            ],
            [
              105.84128597051853,
              21.01499358064313
            ],
            [
              105.84128795216876,
              21.014993593424688
            ],
            [
              105.84130840550611,
              21.014993384800363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.6_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5308",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01509965741251,
          "to_latitude": 21.014993384800363,
          "to_longitude": 105.84130840550611,
          "from_longitude": 105.84130032012669
        }
      },
      {
        "id": 5301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8412865008337,
            21.014603248343928,
            105.84128752738687,
            21.014750600962632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8412865008337,
              21.014750600962632
            ],
            [
              105.84128752738687,
              21.01461565158238
            ],
            [
              105.84128701437432,
              21.014603248343928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.4_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5309",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014750600962632,
          "to_latitude": 21.014603248343928,
          "to_longitude": 105.84128701437432,
          "from_longitude": 105.8412865008337
        }
      },
      {
        "id": 5302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835939995093,
            21.014589562868444,
            105.83599783910239,
            21.014631282509455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835939995093,
              21.014631282509455
            ],
            [
              105.83594255863686,
              21.014629556496974
            ],
            [
              105.83594340556715,
              21.014628943562396
            ],
            [
              105.83598355914516,
              21.014599899084573
            ],
            [
              105.83599783910239,
              21.014589562868444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.8_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5310",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014631282509455,
          "to_latitude": 21.014589562868444,
          "to_longitude": 105.83599783910239,
          "from_longitude": 105.835939995093
        }
      },
      {
        "id": 5303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83925236196625,
            21.016000003623475,
            105.83925683658475,
            21.016019143330993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925683658475,
              21.016000003623475
            ],
            [
              105.83925236196625,
              21.016019143330993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.8_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5311",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016000003623475,
          "to_latitude": 21.016019143330993,
          "to_longitude": 105.83925236196625,
          "from_longitude": 105.83925683658475
        }
      },
      {
        "id": 5304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389800836245,
            21.01617391346918,
            105.83900056306247,
            21.016183588637688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389800836245,
              21.016183588637688
            ],
            [
              105.83900056306247,
              21.01617391346918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5312",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016183588637688,
          "to_latitude": 21.01617391346918,
          "to_longitude": 105.83900056306247,
          "from_longitude": 105.8389800836245
        }
      },
      {
        "id": 5305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835799137169,
            21.01440812480244,
            105.83590656270586,
            21.014574467964284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590413132839,
              21.014574467964284
            ],
            [
              105.83590656270586,
              21.014568875897776
            ],
            [
              105.83587375231751,
              21.01450881460606
            ],
            [
              105.835799137169,
              21.01440812480244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.7_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5313",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014574467964284,
          "to_latitude": 21.01440812480244,
          "to_longitude": 105.835799137169,
          "from_longitude": 105.83590413132839
        }
      },
      {
        "id": 5306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737011324833,
            21.01617926482256,
            105.83742307270755,
            21.016257639217827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737011324833,
              21.01617926482256
            ],
            [
              105.83742307270755,
              21.016242128664604
            ],
            [
              105.83740097408061,
              21.016257639217827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.4_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5314",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01617926482256,
          "to_latitude": 21.016257639217827,
          "to_longitude": 105.83740097408061,
          "from_longitude": 105.83737011324833
        }
      },
      {
        "id": 5307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391360950822,
            21.016055825224562,
            105.83923694725614,
            21.016101795501804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923694725614,
              21.016055825224562
            ],
            [
              105.8391360950822,
              21.016101795501804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5315",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016055825224562,
          "to_latitude": 21.016101795501804,
          "to_longitude": 105.8391360950822,
          "from_longitude": 105.83923694725614
        }
      },
      {
        "id": 5308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608210449212,
            21.01626945278636,
            105.83661774841195,
            21.016642731359923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661774841195,
              21.01626945278636
            ],
            [
              105.83661371875539,
              21.016273575950265
            ],
            [
              105.83657060526735,
              21.01630043036549
            ],
            [
              105.83652382839293,
              21.016332922644406
            ],
            [
              105.83652805072535,
              21.016380593022067
            ],
            [
              105.83635407703052,
              21.016487910524322
            ],
            [
              105.83635095606263,
              21.01648472845388
            ],
            [
              105.83633713593719,
              21.01649337070238
            ],
            [
              105.83632970012914,
              21.016497166476274
            ],
            [
              105.83628973969829,
              21.016508480704857
            ],
            [
              105.83625084143486,
              21.016525177435412
            ],
            [
              105.83617939758797,
              21.016582044249837
            ],
            [
              105.83612386767544,
              21.01661416486512
            ],
            [
              105.83608210449212,
              21.016642731359923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.9_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5316",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01626945278636,
          "to_latitude": 21.016642731359923,
          "to_longitude": 105.83608210449212,
          "from_longitude": 105.83661774841195
        }
      },
      {
        "id": 5309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83638510030835,
            21.0145184567835,
            105.83659601765223,
            21.014654233495442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83659601765223,
              21.014522936189003
            ],
            [
              105.83658212165169,
              21.0145184567835
            ],
            [
              105.83657369354125,
              21.01451937525631
            ],
            [
              105.83656565607862,
              21.014524452059256
            ],
            [
              105.83656055222653,
              21.014528445019625
            ],
            [
              105.83655658235101,
              21.014531550857136
            ],
            [
              105.83655229354679,
              21.0145349066494
            ],
            [
              105.83655217478464,
              21.014534999362244
            ],
            [
              105.83650575958806,
              21.014566359915083
            ],
            [
              105.83646482772811,
              21.014595024469177
            ],
            [
              105.8364492600523,
              21.01460592705362
            ],
            [
              105.83638510030835,
              21.014654233495442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.2_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5317",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014522936189003,
          "to_latitude": 21.014654233495442,
          "to_longitude": 105.83638510030835,
          "from_longitude": 105.83659601765223
        }
      },
      {
        "id": 5310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83700605764237,
            21.01407004852328,
            105.83714689765792,
            21.01428223196256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83700605764237,
              21.014097366853655
            ],
            [
              105.83702117579007,
              21.01407004852328
            ],
            [
              105.8370784368722,
              21.014132712512406
            ],
            [
              105.8370934078005,
              21.01415382578755
            ],
            [
              105.83714689765792,
              21.0142263913861
            ],
            [
              105.83710984097681,
              21.014256147085526
            ],
            [
              105.8371286142707,
              21.01428223196256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.7_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5318",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014097366853655,
          "to_latitude": 21.01428223196256,
          "to_longitude": 105.8371286142707,
          "from_longitude": 105.83700605764237
        }
      },
      {
        "id": 5311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83714328480058,
            21.01622540047383,
            105.8372912291305,
            21.016321247064024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372912291305,
              21.01622540047383
            ],
            [
              105.83728198299212,
              21.016232815384708
            ],
            [
              105.83724419209264,
              21.016263122990512
            ],
            [
              105.83719213642381,
              21.01629478426801
            ],
            [
              105.83718243644054,
              21.016300683990657
            ],
            [
              105.83716538802872,
              21.016310518188927
            ],
            [
              105.83714328480058,
              21.016321247064024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.4_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5319",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01622540047383,
          "to_latitude": 21.016321247064024,
          "to_longitude": 105.83714328480058,
          "from_longitude": 105.8372912291305
        }
      },
      {
        "id": 5312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590652350443,
            21.017525146421935,
            105.83591076352454,
            21.01756600641603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591076352454,
              21.01756600641603
            ],
            [
              105.83590652350443,
              21.017525146421935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5320",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01756600641603,
          "to_latitude": 21.017525146421935,
          "to_longitude": 105.83590652350443,
          "from_longitude": 105.83591076352454
        }
      },
      {
        "id": 5313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904679411083,
            21.01485883435512,
            105.83912137005801,
            21.01509223839742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912137005801,
              21.01485883435512
            ],
            [
              105.8390892085631,
              21.01488767033503
            ],
            [
              105.83905450987889,
              21.01491795314143
            ],
            [
              105.83904679411083,
              21.01494905848222
            ],
            [
              105.83904740722035,
              21.014967695289815
            ],
            [
              105.83905346922789,
              21.014987284319503
            ],
            [
              105.8390707077182,
              21.01501147976683
            ],
            [
              105.83907795772065,
              21.015021611601806
            ],
            [
              105.83911357414631,
              21.015066308102764
            ],
            [
              105.83910712119865,
              21.015072099787933
            ],
            [
              105.83910130474483,
              21.015077321109047
            ],
            [
              105.83910627723908,
              21.015086482371036
            ],
            [
              105.8391021096421,
              21.01509223839742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.29_Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5321",
          "diaChiLapD": "Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01485883435512,
          "to_latitude": 21.01509223839742,
          "to_longitude": 105.8391021096421,
          "from_longitude": 105.83912137005801
        }
      },
      {
        "id": 5314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730823612595,
            21.014122898783263,
            105.83743709700597,
            21.014306216546892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737619550884,
              21.014122898783263
            ],
            [
              105.83737642320511,
              21.014123365532775
            ],
            [
              105.83737773091273,
              21.014126030833932
            ],
            [
              105.83738088592706,
              21.014131870908603
            ],
            [
              105.83743709700597,
              21.01423593289977
            ],
            [
              105.83739127942793,
              21.01426246481873
            ],
            [
              105.83730823612595,
              21.014306216546892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.2_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5322",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014122898783263,
          "to_latitude": 21.014306216546892,
          "to_longitude": 105.83730823612595,
          "from_longitude": 105.83737619550884
        }
      },
      {
        "id": 5315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83522355451287,
            21.017669295264337,
            105.83526997667227,
            21.01769565256636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83522355451287,
              21.01769565256636
            ],
            [
              105.83526997667227,
              21.017669295264337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5323",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01769565256636,
          "to_latitude": 21.017669295264337,
          "to_longitude": 105.83526997667227,
          "from_longitude": 105.83522355451287
        }
      },
      {
        "id": 5316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368970276965,
            21.01629620873249,
            105.83718008383819,
            21.01650672054958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717855034585,
              21.016302088659813
            ],
            [
              105.83717837177069,
              21.016301805926233
            ],
            [
              105.83717486696264,
              21.01629620873249
            ],
            [
              105.83714640625017,
              21.01631244875318
            ],
            [
              105.83712720818852,
              21.016325265132878
            ],
            [
              105.83718008383819,
              21.016417068762088
            ],
            [
              105.83713358827974,
              21.016443242672107
            ],
            [
              105.83708721406546,
              21.016473064094725
            ],
            [
              105.83704157689894,
              21.01650447606916
            ],
            [
              105.83701148273668,
              21.01646486330922
            ],
            [
              105.83693657231292,
              21.01650672054958
            ],
            [
              105.8368970276965,
              21.01644411301397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.6_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5324",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016302088659813,
          "to_latitude": 21.01644411301397,
          "to_longitude": 105.8368970276965,
          "from_longitude": 105.83717855034585
        }
      },
      {
        "id": 5317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83775446155119,
            21.016509165593487,
            105.83776327441545,
            21.016516835727614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775446155119,
              21.016509165593487
            ],
            [
              105.83775568049433,
              21.016510228104845
            ],
            [
              105.83776327441545,
              21.016516835727614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.3_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5325",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016509165593487,
          "to_latitude": 21.016516835727614,
          "to_longitude": 105.83776327441545,
          "from_longitude": 105.83775446155119
        }
      },
      {
        "id": 5318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83964276627535,
            21.015971843530767,
            105.83968825358342,
            21.016000222313426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83964276627535,
              21.015971843530767
            ],
            [
              105.83968825358342,
              21.016000222313426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.14_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5326",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015971843530767,
          "to_latitude": 21.016000222313426,
          "to_longitude": 105.83968825358342,
          "from_longitude": 105.83964276627535
        }
      },
      {
        "id": 5319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620873315198,
            21.0159498797375,
            105.836232621467,
            21.015995174629136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620873315198,
              21.0159498797375
            ],
            [
              105.83620878662107,
              21.015949980635817
            ],
            [
              105.836232621467,
              21.015995174629136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.11_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5327",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0159498797375,
          "to_latitude": 21.015995174629136,
          "to_longitude": 105.836232621467,
          "from_longitude": 105.83620873315198
        }
      },
      {
        "id": 5320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401192303869,
            21.01499914052691,
            105.84012298424054,
            21.01505145300558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401192303869,
              21.01505145300558
            ],
            [
              105.84012298424054,
              21.01499914052691
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.11_Ngách 41, Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5328",
          "diaChiLapD": "Ngách 41, Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01505145300558,
          "to_latitude": 21.01499914052691,
          "to_longitude": 105.84012298424054,
          "from_longitude": 105.8401192303869
        }
      },
      {
        "id": 5321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83653088513712,
            21.01534029600524,
            105.8365886150636,
            21.01539010515814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365886150636,
              21.01538072497163
            ],
            [
              105.83657645080946,
              21.01539010515814
            ],
            [
              105.83653088513712,
              21.01534029600524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.7_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5329",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01538072497163,
          "to_latitude": 21.01534029600524,
          "to_longitude": 105.83653088513712,
          "from_longitude": 105.8365886150636
        }
      },
      {
        "id": 5322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771038317722,
            21.01631742136209,
            105.83774512244436,
            21.016367493937967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774512244436,
              21.016367493937967
            ],
            [
              105.83771038317722,
              21.01631742136209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.2_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5330",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016367493937967,
          "to_latitude": 21.01631742136209,
          "to_longitude": 105.83771038317722,
          "from_longitude": 105.83774512244436
        }
      },
      {
        "id": 5323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589306524193,
            21.017006758691306,
            105.83598930423983,
            21.01707175899028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590363818581,
              21.017006758691306
            ],
            [
              105.83589306524193,
              21.0170122453425
            ],
            [
              105.83591577190174,
              21.017056592272386
            ],
            [
              105.83592353756754,
              21.01707175899028
            ],
            [
              105.83598930423983,
              21.017050425918658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.9_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5331",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017006758691306,
          "to_latitude": 21.017050425918658,
          "to_longitude": 105.83598930423983,
          "from_longitude": 105.83590363818581
        }
      },
      {
        "id": 5324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401576276514,
            21.0150768561808,
            105.84021909231446,
            21.01508164874218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401576276514,
              21.01508164874218
            ],
            [
              105.84021909231446,
              21.0150768561808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.11_Ngách 41, Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5332",
          "diaChiLapD": "Ngách 41, Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01508164874218,
          "to_latitude": 21.0150768561808,
          "to_longitude": 105.84021909231446,
          "from_longitude": 105.8401576276514
        }
      },
      {
        "id": 5325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357684574465,
            21.01475916173276,
            105.83580485236723,
            21.014801245003444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83579240783449,
              21.01475916173276
            ],
            [
              105.83580485236723,
              21.014772806126015
            ],
            [
              105.8357684574465,
              21.014801245003444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.9_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5333",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01475916173276,
          "to_latitude": 21.014801245003444,
          "to_longitude": 105.8357684574465,
          "from_longitude": 105.83579240783449
        }
      },
      {
        "id": 5326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379290188236,
            21.016364265181075,
            105.83798377833905,
            21.016409561299668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379290188236,
              21.016364265181075
            ],
            [
              105.8379342015451,
              21.01637300815189
            ],
            [
              105.83795079782514,
              21.01639296623635
            ],
            [
              105.83796146397015,
              21.016399362718303
            ],
            [
              105.83798377833905,
              21.016409561299668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.1_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5334",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016364265181075,
          "to_latitude": 21.016409561299668,
          "to_longitude": 105.83798377833905,
          "from_longitude": 105.8379290188236
        }
      },
      {
        "id": 5327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663547290409,
            21.016288728038433,
            105.8366840036498,
            21.01636894622528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663547290409,
              21.016288728038433
            ],
            [
              105.8366840036498,
              21.01636372071344
            ],
            [
              105.83667306926475,
              21.016368203900537
            ],
            [
              105.83667125822294,
              21.01636894622528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.10_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5335",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016288728038433,
          "to_latitude": 21.01636894622528,
          "to_longitude": 105.83667125822294,
          "from_longitude": 105.83663547290409
        }
      },
      {
        "id": 5328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706263732307,
            21.015417288926123,
            105.83710679928845,
            21.015472362505065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710679928845,
              21.015472362505065
            ],
            [
              105.83706263732307,
              21.015417288926123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.4_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5336",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015472362505065,
          "to_latitude": 21.015417288926123,
          "to_longitude": 105.83706263732307,
          "from_longitude": 105.83710679928845
        }
      },
      {
        "id": 5329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871662086013,
            21.013955110760204,
            105.83878400128606,
            21.01399580349682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871662086013,
              21.01399580349682
            ],
            [
              105.83872831591123,
              21.013984441995845
            ],
            [
              105.83873201382391,
              21.013981221837163
            ],
            [
              105.83874461484908,
              21.013971724639486
            ],
            [
              105.83876222090956,
              21.01397068223416
            ],
            [
              105.83878400128606,
              21.013955110760204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.7_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5337",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01399580349682,
          "to_latitude": 21.013955110760204,
          "to_longitude": 105.83878400128606,
          "from_longitude": 105.83871662086013
        }
      },
      {
        "id": 5330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83631853225553,
            21.015876487754145,
            105.83638007270886,
            21.0159424719437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631853225553,
              21.015876487754145
            ],
            [
              105.83638007270886,
              21.0159424719437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.11_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5338",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015876487754145,
          "to_latitude": 21.0159424719437,
          "to_longitude": 105.83638007270886,
          "from_longitude": 105.83631853225553
        }
      },
      {
        "id": 5331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83595333622716,
            21.01723878223613,
            105.83608165885241,
            21.01731214247569
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608165885241,
              21.01730256501083
            ],
            [
              105.83595931889568,
              21.01731214247569
            ],
            [
              105.83595333622716,
              21.017265831958188
            ],
            [
              105.83595483905829,
              21.017259667294123
            ],
            [
              105.83595706884103,
              21.017250518418017
            ],
            [
              105.83595755654632,
              21.01723878223613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.7_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5339",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01730256501083,
          "to_latitude": 21.01723878223613,
          "to_longitude": 105.83595755654632,
          "from_longitude": 105.83608165885241
        }
      },
      {
        "id": 5332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863827862906,
            21.014026086519827,
            105.83869853667765,
            21.014082496344137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863827862906,
              21.014082496344137
            ],
            [
              105.8386555973037,
              21.01406635165361
            ],
            [
              105.83867595407543,
              21.014047514829517
            ],
            [
              105.83868821069599,
              21.014036461272052
            ],
            [
              105.83869853667765,
              21.014026086519827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5340",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014082496344137,
          "to_latitude": 21.014026086519827,
          "to_longitude": 105.83869853667765,
          "from_longitude": 105.83863827862906
        }
      },
      {
        "id": 5333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594644700379,
            21.017149571138308,
            105.83607952370888,
            21.01723878223613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83595755654632,
              21.01723878223613
            ],
            [
              105.83595356803367,
              21.017216122779608
            ],
            [
              105.83594644700379,
              21.017171468948863
            ],
            [
              105.83607952370888,
              21.017149571138308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.7_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5341",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01723878223613,
          "to_latitude": 21.017149571138308,
          "to_longitude": 105.83607952370888,
          "from_longitude": 105.83595755654632
        }
      },
      {
        "id": 5334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598449253718,
            21.01386498591294,
            105.83650289881629,
            21.014296683054923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650289881629,
              21.01386498591294
            ],
            [
              105.8364911452343,
              21.013871918140747
            ],
            [
              105.83635672629262,
              21.013951191999197
            ],
            [
              105.83631129905763,
              21.01397839913062
            ],
            [
              105.83628579313091,
              21.013991428869453
            ],
            [
              105.83626447486431,
              21.014002287444136
            ],
            [
              105.83624624457703,
              21.014015079996987
            ],
            [
              105.83620298515368,
              21.01403774135541
            ],
            [
              105.83617160913599,
              21.014053288311352
            ],
            [
              105.83616058962006,
              21.014062662841223
            ],
            [
              105.83615222523953,
              21.014068798003912
            ],
            [
              105.83612450891927,
              21.01409617727085
            ],
            [
              105.83608883005745,
              21.014133274616395
            ],
            [
              105.83607099753773,
              21.014161348657886
            ],
            [
              105.83603914353971,
              21.014191590691762
            ],
            [
              105.83598449253718,
              21.01424957256664
            ],
            [
              105.83602674900614,
              21.014296683054923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.11_Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5342",
          "diaChiLapD": "Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01386498591294,
          "to_latitude": 21.014296683054923,
          "to_longitude": 105.83602674900614,
          "from_longitude": 105.83650289881629
        }
      },
      {
        "id": 5335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372763214957,
            21.014132591094814,
            105.83734852208198,
            21.01416424675355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734852208198,
              21.014132591094814
            ],
            [
              105.8372763214957,
              21.01416424675355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.1_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5343",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014132591094814,
          "to_latitude": 21.01416424675355,
          "to_longitude": 105.8372763214957,
          "from_longitude": 105.83734852208198
        }
      },
      {
        "id": 5336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8374973841036,
            21.01639314410454,
            105.83754272041814,
            21.016464828093685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374973841036,
              21.01639314410454
            ],
            [
              105.83754272041814,
              21.016459300651043
            ],
            [
              105.83753397260608,
              21.016464828093685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.6_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5344",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01639314410454,
          "to_latitude": 21.016464828093685,
          "to_longitude": 105.83753397260608,
          "from_longitude": 105.8374973841036
        }
      },
      {
        "id": 5337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84089869197288,
            21.015074025116483,
            105.84098338203124,
            21.015086830600637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84089869197288,
              21.015074025116483
            ],
            [
              105.84098338203124,
              21.015086830600637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.3_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5345",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015074025116483,
          "to_latitude": 21.015086830600637,
          "to_longitude": 105.84098338203124,
          "from_longitude": 105.84089869197288
        }
      },
      {
        "id": 5338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853580838755,
            21.014181937856964,
            105.83860579166058,
            21.01424426583543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83853580838755,
              21.014181937856964
            ],
            [
              105.83860579166058,
              21.01424426583543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5346",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014181937856964,
          "to_latitude": 21.01424426583543,
          "to_longitude": 105.83860579166058,
          "from_longitude": 105.83853580838755
        }
      },
      {
        "id": 5339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632306009132,
            21.01359735762376,
            105.83636518079852,
            21.01366613949846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635531305167,
              21.01366613949846
            ],
            [
              105.8363568205722,
              21.01366462009023
            ],
            [
              105.83636518079852,
              21.013656192554784
            ],
            [
              105.83632306009132,
              21.01359735762376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.7_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5347",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01366613949846,
          "to_latitude": 21.01359735762376,
          "to_longitude": 105.83632306009132,
          "from_longitude": 105.83635531305167
        }
      },
      {
        "id": 5340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783820637906,
            21.016470266807495,
            105.83789104898186,
            21.016546425621527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783820637906,
              21.016470266807495
            ],
            [
              105.83789104898186,
              21.016546425621527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.3_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5348",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016470266807495,
          "to_latitude": 21.016546425621527,
          "to_longitude": 105.83789104898186,
          "from_longitude": 105.83783820637906
        }
      },
      {
        "id": 5341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361899982312,
            21.016454368370706,
            105.83625084143486,
            21.016525177435412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625084143486,
              21.016525177435412
            ],
            [
              105.8361899982312,
              21.016454368370706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.9_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5349",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016525177435412,
          "to_latitude": 21.016454368370706,
          "to_longitude": 105.8361899982312,
          "from_longitude": 105.83625084143486
        }
      },
      {
        "id": 5342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902431069029,
            21.016164700930016,
            105.83905066872046,
            21.016249948119288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902943031,
              21.016164700930016
            ],
            [
              105.8390287842494,
              21.016165109663756
            ],
            [
              105.83902431069029,
              21.016167936236315
            ],
            [
              105.83902579280755,
              21.0161699584706
            ],
            [
              105.83903683743426,
              21.016185026675547
            ],
            [
              105.83904837022739,
              21.016202928594662
            ],
            [
              105.8390289118506,
              21.01621870181614
            ],
            [
              105.83905066872046,
              21.016249948119288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5350",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016164700930016,
          "to_latitude": 21.016249948119288,
          "to_longitude": 105.83905066872046,
          "from_longitude": 105.83902943031
        }
      },
      {
        "id": 5343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83942616866808,
            21.01537059402379,
            105.83949308819706,
            21.0154525240291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83942616866808,
              21.01537059402379
            ],
            [
              105.83943596012257,
              21.015382441169383
            ],
            [
              105.8394526208843,
              21.01540703142351
            ],
            [
              105.83949308819706,
              21.0154525240291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.33_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5351",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01537059402379,
          "to_latitude": 21.0154525240291,
          "to_longitude": 105.83949308819706,
          "from_longitude": 105.83942616866808
        }
      },
      {
        "id": 5344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871051881565,
            21.013958286393795,
            105.83879153360209,
            21.0140196190791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871051881565,
              21.0140196190791
            ],
            [
              105.8387248530591,
              21.014010950627483
            ],
            [
              105.83874465084135,
              21.013996397833026
            ],
            [
              105.83874939575925,
              21.013992910577553
            ],
            [
              105.83876678132357,
              21.01398013095916
            ],
            [
              105.83879153360209,
              21.013958286393795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6A_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5352",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0140196190791,
          "to_latitude": 21.013958286393795,
          "to_longitude": 105.83879153360209,
          "from_longitude": 105.83871051881565
        }
      },
      {
        "id": 5345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719213642381,
            21.01629478426801,
            105.83724574667491,
            21.016378081310105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719213642381,
              21.01629478426801
            ],
            [
              105.83724574667491,
              21.016378081310105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.4_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5353",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629478426801,
          "to_latitude": 21.016378081310105,
          "to_longitude": 105.83724574667491,
          "from_longitude": 105.83719213642381
        }
      },
      {
        "id": 5346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83628973969829,
            21.016508480704857,
            105.83635604670691,
            21.016593073168096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83628973969829,
              21.016508480704857
            ],
            [
              105.83635604670691,
              21.016593073168096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.9_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5354",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016508480704857,
          "to_latitude": 21.016593073168096,
          "to_longitude": 105.83635604670691,
          "from_longitude": 105.83628973969829
        }
      },
      {
        "id": 5347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590413132839,
            21.014574467964284,
            105.8359560720221,
            21.01467084423112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590413132839,
              21.014574467964284
            ],
            [
              105.83590592523609,
              21.01459482053958
            ],
            [
              105.8359158914896,
              21.01461116509725
            ],
            [
              105.83592780170603,
              21.014631116613682
            ],
            [
              105.83592840857843,
              21.01463213337235
            ],
            [
              105.83593453180151,
              21.014639824952745
            ],
            [
              105.83595208123913,
              21.01466571365394
            ],
            [
              105.8359560720221,
              21.01467084423112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.7_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5355",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014574467964284,
          "to_latitude": 21.01467084423112,
          "to_longitude": 105.8359560720221,
          "from_longitude": 105.83590413132839
        }
      },
      {
        "id": 5348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83776052566816,
            21.016518816485426,
            105.83785121203371,
            21.016638544727243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83785121203371,
              21.016638544727243
            ],
            [
              105.83781730168351,
              21.016593779051846
            ],
            [
              105.83776052566816,
              21.016518816485426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.3_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5356",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016638544727243,
          "to_latitude": 21.016518816485426,
          "to_longitude": 105.83776052566816,
          "from_longitude": 105.83785121203371
        }
      },
      {
        "id": 5349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83881193197433,
            21.016193402210135,
            105.83892036456216,
            21.01625070088092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881193197433,
              21.01625070088092
            ],
            [
              105.83888793563241,
              21.016210768533657
            ],
            [
              105.83892036456216,
              21.016193402210135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5357",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01625070088092,
          "to_latitude": 21.016193402210135,
          "to_longitude": 105.83892036456216,
          "from_longitude": 105.83881193197433
        }
      },
      {
        "id": 5350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755549177611,
            21.014570590038563,
            105.83765324949441,
            21.014614741300786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755549177611,
              21.014614741300786
            ],
            [
              105.83760024560208,
              21.014588565927614
            ],
            [
              105.83760910916197,
              21.01459000538495
            ],
            [
              105.83765324949441,
              21.014570590038563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.2_Ngõ 207, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5358",
          "diaChiLapD": "Ngõ 207, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014614741300786,
          "to_latitude": 21.014570590038563,
          "to_longitude": 105.83765324949441,
          "from_longitude": 105.83755549177611
        }
      },
      {
        "id": 5351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83681321472514,
            21.015752717922748,
            105.83713781248174,
            21.015846101343772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713781248174,
              21.015846101343772
            ],
            [
              105.83713086073539,
              21.015834585120718
            ],
            [
              105.83709981763451,
              21.015783146609845
            ],
            [
              105.83708091617063,
              21.015759640171684
            ],
            [
              105.83700046664067,
              21.015766906814992
            ],
            [
              105.83688135198517,
              21.015841230212196
            ],
            [
              105.83681321472514,
              21.015752717922748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.3_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5359",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015846101343772,
          "to_latitude": 21.015752717922748,
          "to_longitude": 105.83681321472514,
          "from_longitude": 105.83713781248174
        }
      },
      {
        "id": 5352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728198299212,
            21.016232815384708,
            105.83734917792805,
            21.016319755076278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728198299212,
              21.016232815384708
            ],
            [
              105.83734917792805,
              21.016319755076278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.4_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5360",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016232815384708,
          "to_latitude": 21.016319755076278,
          "to_longitude": 105.83734917792805,
          "from_longitude": 105.83728198299212
        }
      },
      {
        "id": 5353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83693285753883,
            21.015667032124902,
            105.83700046664067,
            21.015766906814992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83700046664067,
              21.015766906814992
            ],
            [
              105.83693285753883,
              21.015667032124902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.3_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5361",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015766906814992,
          "to_latitude": 21.015667032124902,
          "to_longitude": 105.83693285753883,
          "from_longitude": 105.83700046664067
        }
      },
      {
        "id": 5354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83206409956371,
            21.01817931009274,
            105.83216309194701,
            21.018236758898336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83206409956371,
              21.018236758898336
            ],
            [
              105.83213133089873,
              21.018195218602763
            ],
            [
              105.83216309194701,
              21.01817931009274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25.2_Ngách 298/34, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5362",
          "diaChiLapD": "Ngách 298/34, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018236758898336,
          "to_latitude": 21.01817931009274,
          "to_longitude": 105.83216309194701,
          "from_longitude": 105.83206409956371
        }
      },
      {
        "id": 5355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83734852208198,
            21.01409158594238,
            105.83745342499347,
            21.014132591094814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734852208198,
              21.014132591094814
            ],
            [
              105.8373721553259,
              21.014122710023106
            ],
            [
              105.83740549483598,
              21.01410877085949
            ],
            [
              105.83745342499347,
              21.01409158594238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.1_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5363",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014132591094814,
          "to_latitude": 21.01409158594238,
          "to_longitude": 105.83745342499347,
          "from_longitude": 105.83734852208198
        }
      },
      {
        "id": 5356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83186538593864,
            21.01810319621642,
            105.83195406049762,
            21.01818647635301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195406049762,
              21.01810319621642
            ],
            [
              105.8319027656287,
              21.018147648182985
            ],
            [
              105.83190282636487,
              21.01815859767999
            ],
            [
              105.83186538593864,
              21.01818647635301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25.2_Ngách 298/15, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5364",
          "diaChiLapD": "Ngách 298/15, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01810319621642,
          "to_latitude": 21.01818647635301,
          "to_longitude": 105.83186538593864,
          "from_longitude": 105.83195406049762
        }
      },
      {
        "id": 5357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869853667765,
            21.014026086519827,
            105.83878069158575,
            21.01409897905125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83869853667765,
              21.014026086519827
            ],
            [
              105.83878069158575,
              21.01409897905125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5365",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014026086519827,
          "to_latitude": 21.01409897905125,
          "to_longitude": 105.83878069158575,
          "from_longitude": 105.83869853667765
        }
      },
      {
        "id": 5358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386373370331,
            21.014907811446307,
            105.83872209629733,
            21.014987361624474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386373370331,
              21.014987361624474
            ],
            [
              105.83866585101335,
              21.01496179802349
            ],
            [
              105.83872209629733,
              21.014907811446307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.4_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5366",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014987361624474,
          "to_latitude": 21.014907811446307,
          "to_longitude": 105.83872209629733,
          "from_longitude": 105.8386373370331
        }
      },
      {
        "id": 5359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809686609334,
            21.015553095667077,
            105.83815208376632,
            21.0156385974039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815208376632,
              21.0156385974039
            ],
            [
              105.83809686609334,
              21.015553095667077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.29_Ngõ 153, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5367",
          "diaChiLapD": "Ngõ 153, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0156385974039,
          "to_latitude": 21.015553095667077,
          "to_longitude": 105.83809686609334,
          "from_longitude": 105.83815208376632
        }
      },
      {
        "id": 5360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735260892637,
            21.016456866563896,
            105.83742297592343,
            21.016549562208315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742297592343,
              21.016549562208315
            ],
            [
              105.83735260892637,
              21.016456866563896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.6_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5368",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016549562208315,
          "to_latitude": 21.016456866563896,
          "to_longitude": 105.83735260892637,
          "from_longitude": 105.83742297592343
        }
      },
      {
        "id": 5361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645655877528,
            21.016250447617757,
            105.8365212933887,
            21.016327178861676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365212933887,
              21.016327178861676
            ],
            [
              105.83645655877528,
              21.016250447617757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.8_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5369",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016327178861676,
          "to_latitude": 21.016250447617757,
          "to_longitude": 105.83645655877528,
          "from_longitude": 105.8365212933887
        }
      },
      {
        "id": 5362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83784820529812,
            21.016287820520677,
            105.8379290188236,
            21.016372259832806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792384014814,
              21.016372259832806
            ],
            [
              105.83792751767267,
              21.016366582118433
            ],
            [
              105.8379290188236,
              21.016364265181075
            ],
            [
              105.83791756422062,
              21.016364126502893
            ],
            [
              105.83788373451154,
              21.016333099380308
            ],
            [
              105.83784820529812,
              21.016287820520677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.1_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5370",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016372259832806,
          "to_latitude": 21.016287820520677,
          "to_longitude": 105.83784820529812,
          "from_longitude": 105.83792384014814
        }
      },
      {
        "id": 5363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83596228127466,
            21.01410054669251,
            105.83603801686235,
            21.014167380779764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83596228127466,
              21.01410054669251
            ],
            [
              105.83603801686235,
              21.014167380779764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.10_Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5371",
          "diaChiLapD": "Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01410054669251,
          "to_latitude": 21.014167380779764,
          "to_longitude": 105.83603801686235,
          "from_longitude": 105.83596228127466
        }
      },
      {
        "id": 5364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704082838692,
            21.015377416191015,
            105.83712484821167,
            21.01543539748548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712484821167,
              21.015377416191015
            ],
            [
              105.8370849517339,
              21.015406503025066
            ],
            [
              105.83708136215134,
              21.01540174082438
            ],
            [
              105.83706263732307,
              21.015417288926123
            ],
            [
              105.83704082838692,
              21.01543539748548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.4_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5372",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015377416191015,
          "to_latitude": 21.01543539748548,
          "to_longitude": 105.83704082838692,
          "from_longitude": 105.83712484821167
        }
      },
      {
        "id": 5365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8348440910135,
            21.014864301429576,
            105.83494422756556,
            21.014937679440962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494422756556,
              21.014864301429576
            ],
            [
              105.83493459712143,
              21.01487094313423
            ],
            [
              105.83493358355803,
              21.014871690558923
            ],
            [
              105.8348440910135,
              21.014937679440962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.2_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5373",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014864301429576,
          "to_latitude": 21.014937679440962,
          "to_longitude": 105.8348440910135,
          "from_longitude": 105.83494422756556
        }
      },
      {
        "id": 5366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902579280755,
            21.01607020870184,
            105.8392240204013,
            21.0161699584706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392240204013,
              21.01607020870184
            ],
            [
              105.839093970403,
              21.016130436758335
            ],
            [
              105.83902700859647,
              21.01616976821688
            ],
            [
              105.83902579280755,
              21.0161699584706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5374",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01607020870184,
          "to_latitude": 21.0161699584706,
          "to_longitude": 105.83902579280755,
          "from_longitude": 105.8392240204013
        }
      },
      {
        "id": 5367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659601765223,
            21.01440530939323,
            105.8368209809699,
            21.01453197224716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368209809699,
              21.01440530939323
            ],
            [
              105.83664601928112,
              21.014522000249286
            ],
            [
              105.83662624925685,
              21.014531231775283
            ],
            [
              105.83661930441008,
              21.01453197224716
            ],
            [
              105.83661177264204,
              21.01452958231957
            ],
            [
              105.83659601765223,
              21.014522936189003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.2_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5375",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01440530939323,
          "to_latitude": 21.014522936189003,
          "to_longitude": 105.83659601765223,
          "from_longitude": 105.8368209809699
        }
      },
      {
        "id": 5368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841789399074,
            21.014122133918445,
            105.83850209991415,
            21.0141981714405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850209991415,
              21.0141981714405
            ],
            [
              105.83841789399074,
              21.014122133918445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5376",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0141981714405,
          "to_latitude": 21.014122133918445,
          "to_longitude": 105.83841789399074,
          "from_longitude": 105.83850209991415
        }
      },
      {
        "id": 5369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590363818581,
            21.01693872326832,
            105.8359790722207,
            21.017006758691306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590363818581,
              21.017006758691306
            ],
            [
              105.8359790722207,
              21.01696537712548
            ],
            [
              105.8359595298896,
              21.01693872326832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.9_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5377",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017006758691306,
          "to_latitude": 21.01693872326832,
          "to_longitude": 105.8359595298896,
          "from_longitude": 105.83590363818581
        }
      },
      {
        "id": 5370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83578971685178,
            21.01431809173081,
            105.83588529368957,
            21.014388761688302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578971685178,
              21.014388761688302
            ],
            [
              105.83579253176693,
              21.014387091346393
            ],
            [
              105.83579306247663,
              21.014386696739873
            ],
            [
              105.83588529368957,
              21.01431809173081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.6_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5378",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014388761688302,
          "to_latitude": 21.01431809173081,
          "to_longitude": 105.83588529368957,
          "from_longitude": 105.83578971685178
        }
      },
      {
        "id": 5371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390093622003,
            21.016101795501804,
            105.8391360950822,
            21.016156801416738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390093622003,
              21.016152811412443
            ],
            [
              105.83901819195167,
              21.016147844393654
            ],
            [
              105.83902483518675,
              21.016156801416738
            ],
            [
              105.83903449276566,
              21.01615176257263
            ],
            [
              105.83905986487191,
              21.016138521726788
            ],
            [
              105.83909977668658,
              21.016114449059934
            ],
            [
              105.83911964432632,
              21.01610752721136
            ],
            [
              105.8391360950822,
              21.016101795501804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5379",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016152811412443,
          "to_latitude": 21.016101795501804,
          "to_longitude": 105.8391360950822,
          "from_longitude": 105.8390093622003
        }
      },
      {
        "id": 5372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83734282788687,
            21.01615795869308,
            105.83746286527952,
            21.016191476884167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746286527952,
              21.016191476884167
            ],
            [
              105.83744615128623,
              21.016171783973213
            ],
            [
              105.83740460680733,
              21.01615795869308
            ],
            [
              105.83739774090608,
              21.01615906822869
            ],
            [
              105.83736497469594,
              21.016175324961317
            ],
            [
              105.83735501794779,
              21.01617985666963
            ],
            [
              105.83734282788687,
              21.016178930347948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.3_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5380",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016191476884167,
          "to_latitude": 21.016178930347948,
          "to_longitude": 105.83734282788687,
          "from_longitude": 105.83746286527952
        }
      },
      {
        "id": 5373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944181510648,
            21.01598095870346,
            105.83953353537692,
            21.016079365907363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953353537692,
              21.01598095870346
            ],
            [
              105.83944181510648,
              21.016079365907363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.14_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5381",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01598095870346,
          "to_latitude": 21.016079365907363,
          "to_longitude": 105.83944181510648,
          "from_longitude": 105.83953353537692
        }
      },
      {
        "id": 5374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603939468937,
            21.013922942730503,
            105.8360984263396,
            21.014013143911352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603939468937,
              21.014013143911352
            ],
            [
              105.8360984263396,
              21.01396157325792
            ],
            [
              105.83605772743232,
              21.013922942730503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.10_Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5382",
          "diaChiLapD": "Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014013143911352,
          "to_latitude": 21.013922942730503,
          "to_longitude": 105.83605772743232,
          "from_longitude": 105.83603939468937
        }
      },
      {
        "id": 5375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83337008329522,
            21.017800407949625,
            105.8334977703693,
            21.017861927114254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83337008329522,
              21.017861927114254
            ],
            [
              105.8334977703693,
              21.017800407949625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5383",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017861927114254,
          "to_latitude": 21.017800407949625,
          "to_longitude": 105.8334977703693,
          "from_longitude": 105.83337008329522
        }
      },
      {
        "id": 5376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635000438537,
            21.01626418034422,
            105.83661432431572,
            21.01648156642845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661432431572,
              21.01626418034422
            ],
            [
              105.8366070579624,
              21.01626420614204
            ],
            [
              105.83658737608303,
              21.016281176059522
            ],
            [
              105.8365212933887,
              21.016327178861676
            ],
            [
              105.83651197806094,
              21.016333234719703
            ],
            [
              105.8365252732189,
              21.016364656643887
            ],
            [
              105.83642243458631,
              21.016435135063805
            ],
            [
              105.83635000438537,
              21.01648156642845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.8_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5384",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01626418034422,
          "to_latitude": 21.01648156642845,
          "to_longitude": 105.83635000438537,
          "from_longitude": 105.83661432431572
        }
      },
      {
        "id": 5377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8412865008337,
            21.014750600962632,
            105.84130834982467,
            21.014986887285918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84130834982467,
              21.014986887285918
            ],
            [
              105.84128704945826,
              21.014986486809928
            ],
            [
              105.84129045768319,
              21.01487588107804
            ],
            [
              105.8412865008337,
              21.014750600962632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.4_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5385",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014986887285918,
          "to_latitude": 21.014750600962632,
          "to_longitude": 105.8412865008337,
          "from_longitude": 105.84130834982467
        }
      },
      {
        "id": 5378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83469666097172,
            21.014466704577107,
            105.8348180505041,
            21.014541518314974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83469666097172,
              21.014466704577107
            ],
            [
              105.83472011187608,
              21.014479675443464
            ],
            [
              105.8347533651961,
              21.014498068140966
            ],
            [
              105.83477317007538,
              21.014507696892696
            ],
            [
              105.83480110777961,
              21.014527989852606
            ],
            [
              105.8348180505041,
              21.014541518314974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.1_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5386",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014466704577107,
          "to_latitude": 21.014541518314974,
          "to_longitude": 105.8348180505041,
          "from_longitude": 105.83469666097172
        }
      },
      {
        "id": 5379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879584310986,
            21.015209932479237,
            105.83891942550164,
            21.015283736375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879584310986,
              21.015283736375
            ],
            [
              105.83883314638425,
              21.015260079472355
            ],
            [
              105.83885974009027,
              21.015245991734275
            ],
            [
              105.83891942550164,
              21.015209932479237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.2_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5387",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015283736375,
          "to_latitude": 21.015209932479237,
          "to_longitude": 105.83891942550164,
          "from_longitude": 105.83879584310986
        }
      },
      {
        "id": 5380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83795327278776,
            21.015029051493205,
            105.83809958774863,
            21.01520384437634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809958774863,
              21.01520384437634
            ],
            [
              105.83809817839827,
              21.015201585129983
            ],
            [
              105.83809635567933,
              21.015190936968782
            ],
            [
              105.83795327278776,
              21.015029051493205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.3_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5388",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01520384437634,
          "to_latitude": 21.015029051493205,
          "to_longitude": 105.83795327278776,
          "from_longitude": 105.83809958774863
        }
      },
      {
        "id": 5381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921391165279,
            21.015779183874265,
            105.83932229363991,
            21.015847189438702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932229363991,
              21.015847189438702
            ],
            [
              105.83932029541437,
              21.015845935686915
            ],
            [
              105.83921391165279,
              21.015779183874265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.8_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5389",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015847189438702,
          "to_latitude": 21.015779183874265,
          "to_longitude": 105.83921391165279,
          "from_longitude": 105.83932229363991
        }
      },
      {
        "id": 5382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377002103932,
            21.01432118041522,
            105.83780854513756,
            21.01441565097519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377002103932,
              21.01441565097519
            ],
            [
              105.83770764976254,
              21.0144104506133
            ],
            [
              105.8377104247944,
              21.014408187921575
            ],
            [
              105.83774750779084,
              21.014381092862976
            ],
            [
              105.83778175194891,
              21.01435379409846
            ],
            [
              105.83778412320173,
              21.01434330592495
            ],
            [
              105.83780854513756,
              21.01432118041522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.5_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5390",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01441565097519,
          "to_latitude": 21.01432118041522,
          "to_longitude": 105.83780854513756,
          "from_longitude": 105.8377002103932
        }
      },
      {
        "id": 5383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83741888096559,
            21.01448751606015,
            105.83755549177611,
            21.014706325988968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751893705225,
              21.01448751606015
            ],
            [
              105.83751826974807,
              21.014488771213788
            ],
            [
              105.8375112750021,
              21.01450193671956
            ],
            [
              105.83753152365036,
              21.014552423919596
            ],
            [
              105.83755549177611,
              21.014614741300786
            ],
            [
              105.83741888096559,
              21.014706325988968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.2_Ngõ 207, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5391",
          "diaChiLapD": "Ngõ 207, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01448751606015,
          "to_latitude": 21.014706325988968,
          "to_longitude": 105.83741888096559,
          "from_longitude": 105.83751893705225
        }
      },
      {
        "id": 5384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84130032012669,
            21.01509965741251,
            105.84130374135846,
            21.01524382928291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84130032012669,
              21.01509965741251
            ],
            [
              105.84130374135846,
              21.01524382928291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.6_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5392",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01509965741251,
          "to_latitude": 21.01524382928291,
          "to_longitude": 105.84130374135846,
          "from_longitude": 105.84130032012669
        }
      },
      {
        "id": 5385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915518647856,
            21.015845935686915,
            105.83932029541437,
            21.016000003623475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915518647856,
              21.015883913311416
            ],
            [
              105.83921681524421,
              21.015968558412194
            ],
            [
              105.83924280274408,
              21.01599362022341
            ],
            [
              105.83925683658475,
              21.016000003623475
            ],
            [
              105.8392615048539,
              21.015972837817586
            ],
            [
              105.83927938063265,
              21.01591371492882
            ],
            [
              105.83928024669959,
              21.015910850146668
            ],
            [
              105.83930480704971,
              21.015865323021508
            ],
            [
              105.83932029541437,
              21.015845935686915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.8_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5393",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015883913311416,
          "to_latitude": 21.015845935686915,
          "to_longitude": 105.83932029541437,
          "from_longitude": 105.83915518647856
        }
      },
      {
        "id": 5386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662001591578,
            21.018764805776794,
            105.83664918670111,
            21.018910757869207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662001591578,
              21.018764805776794
            ],
            [
              105.83664918670111,
              21.018910757869207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5394",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018764805776794,
          "to_latitude": 21.018910757869207,
          "to_longitude": 105.83664918670111,
          "from_longitude": 105.83662001591578
        }
      },
      {
        "id": 5387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768559857266,
            21.016448144181044,
            105.83787432778702,
            21.016572810752574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83768559857266,
              21.016572810752574
            ],
            [
              105.83776052566816,
              21.016518816485426
            ],
            [
              105.83776327441545,
              21.016516835727614
            ],
            [
              105.83783820637906,
              21.016470266807495
            ],
            [
              105.83787432778702,
              21.016448144181044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.3_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5395",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016572810752574,
          "to_latitude": 21.016448144181044,
          "to_longitude": 105.83787432778702,
          "from_longitude": 105.83768559857266
        }
      },
      {
        "id": 5388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718145025797,
            21.016210346507517,
            105.83729103158576,
            21.016290136476904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83718145025797,
              21.016290136476904
            ],
            [
              105.83723779183713,
              21.016251665478304
            ],
            [
              105.83723861309151,
              21.016251105050635
            ],
            [
              105.83729103158576,
              21.016210346507517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.5_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5396",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016290136476904,
          "to_latitude": 21.016210346507517,
          "to_longitude": 105.83729103158576,
          "from_longitude": 105.83718145025797
        }
      },
      {
        "id": 5389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83631332552086,
            21.018472512256963,
            105.83632932758798,
            21.018631548230942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83632932758798,
              21.018631548230942
            ],
            [
              105.8363286575226,
              21.018624895667827
            ],
            [
              105.8363285607147,
              21.018623923371564
            ],
            [
              105.83631332552086,
              21.018472512256963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5397",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018631548230942,
          "to_latitude": 21.018472512256963,
          "to_longitude": 105.83631332552086,
          "from_longitude": 105.83632932758798
        }
      },
      {
        "id": 5390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779767384019,
            21.0156385974039,
            105.83819735483144,
            21.015856803408376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779767384019,
              21.015856803408376
            ],
            [
              105.83780506957088,
              21.01585204493258
            ],
            [
              105.83786833198924,
              21.015811340299706
            ],
            [
              105.83793618381691,
              21.01577425935503
            ],
            [
              105.83815208376632,
              21.0156385974039
            ],
            [
              105.83819735483144,
              21.015699267015837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.29_Ngõ 153, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5398",
          "diaChiLapD": "Ngõ 153, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015856803408376,
          "to_latitude": 21.015699267015837,
          "to_longitude": 105.83819735483144,
          "from_longitude": 105.83779767384019
        }
      },
      {
        "id": 5391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83481687114471,
            21.014541518314974,
            105.83484376201683,
            21.014674255673434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8348180505041,
              21.014541518314974
            ],
            [
              105.83483319892892,
              21.01455844899494
            ],
            [
              105.83484376201683,
              21.014572646435013
            ],
            [
              105.83484194445833,
              21.014597277257458
            ],
            [
              105.83483625237584,
              21.014617092130262
            ],
            [
              105.8348238994843,
              21.0146475507695
            ],
            [
              105.83481984858442,
              21.014660475920206
            ],
            [
              105.83481687114471,
              21.014674255673434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.1_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5399",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014541518314974,
          "to_latitude": 21.014674255673434,
          "to_longitude": 105.83481687114471,
          "from_longitude": 105.8348180505041
        }
      },
      {
        "id": 5392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667952885422,
            21.014102369941483,
            105.8367751552905,
            21.01424021672735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667952885422,
              21.014102369941483
            ],
            [
              105.83673399435523,
              21.014180882223993
            ],
            [
              105.8367751552905,
              21.01424021672735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5400",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014102369941483,
          "to_latitude": 21.01424021672735,
          "to_longitude": 105.8367751552905,
          "from_longitude": 105.83667952885422
        }
      },
      {
        "id": 5393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84070589394912,
            21.01505962042347,
            105.84088841492807,
            21.015083504662318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84070589394912,
              21.01505962042347
            ],
            [
              105.84087265437138,
              21.015081398564522
            ],
            [
              105.84088841492807,
              21.015083504662318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.4_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5401",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01505962042347,
          "to_latitude": 21.015083504662318,
          "to_longitude": 105.84088841492807,
          "from_longitude": 105.84070589394912
        }
      },
      {
        "id": 5394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944361306978,
            21.015190219965326,
            105.83957779241321,
            21.015272743010346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83957779241321,
              21.015234971954733
            ],
            [
              105.83950694951686,
              21.015190219965326
            ],
            [
              105.83948929653364,
              21.015218824556744
            ],
            [
              105.83944361306978,
              21.015272743010346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.32_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5402",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015234971954733,
          "to_latitude": 21.015272743010346,
          "to_longitude": 105.83944361306978,
          "from_longitude": 105.83957779241321
        }
      },
      {
        "id": 5395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746219885087,
            21.016556009277064,
            105.83759179907861,
            21.01667924370701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759179907861,
              21.016556009277064
            ],
            [
              105.83759059880553,
              21.016557945376164
            ],
            [
              105.83758978871957,
              21.016572046884292
            ],
            [
              105.83756560394487,
              21.016594852231556
            ],
            [
              105.83746219885087,
              21.01667924370701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.5_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5403",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016556009277064,
          "to_latitude": 21.01667924370701,
          "to_longitude": 105.83746219885087,
          "from_longitude": 105.83759179907861
        }
      },
      {
        "id": 5396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858113325714,
            21.014616105300217,
            105.83869828411704,
            21.014749907401697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858113325714,
              21.014749907401697
            ],
            [
              105.83859008306199,
              21.014743370011264
            ],
            [
              105.83860745557625,
              21.014727894348116
            ],
            [
              105.83861388871061,
              21.014720622395117
            ],
            [
              105.8386173442436,
              21.014716715176654
            ],
            [
              105.83862347051952,
              21.014708410544404
            ],
            [
              105.83863082432683,
              21.014697888768858
            ],
            [
              105.83865300063137,
              21.014667946870848
            ],
            [
              105.83868035542878,
              21.014632921388568
            ],
            [
              105.83869421773964,
              21.01462000830565
            ],
            [
              105.83869828411704,
              21.014616105300217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.5_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5404",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014749907401697,
          "to_latitude": 21.014616105300217,
          "to_longitude": 105.83869828411704,
          "from_longitude": 105.83858113325714
        }
      },
      {
        "id": 5397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83552040237865,
            21.01744990070617,
            105.83554954023795,
            21.017625930575004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554954023795,
              21.017625930575004
            ],
            [
              105.83552040237865,
              21.01744990070617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5405",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017625930575004,
          "to_latitude": 21.01744990070617,
          "to_longitude": 105.83552040237865,
          "from_longitude": 105.83554954023795
        }
      },
      {
        "id": 5398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662239233392,
            21.014409935740883,
            105.83683860333478,
            21.014545103493028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662239233392,
              21.014545103493028
            ],
            [
              105.8366323516678,
              21.014543464844085
            ],
            [
              105.83664784570857,
              21.0145378175647
            ],
            [
              105.83667259358543,
              21.01452080195656
            ],
            [
              105.83671650018556,
              21.01448947085778
            ],
            [
              105.83683860333478,
              21.014409935740883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.1_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5406",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014545103493028,
          "to_latitude": 21.014409935740883,
          "to_longitude": 105.83683860333478,
          "from_longitude": 105.83662239233392
        }
      },
      {
        "id": 5399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933981521487,
            21.01540703142351,
            105.8394526208843,
            21.01554341749109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394526208843,
              21.01540703142351
            ],
            [
              105.83940230535268,
              21.01544783533259
            ],
            [
              105.83936025660914,
              21.015486051351065
            ],
            [
              105.83933981521487,
              21.01554341749109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.33_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5407",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01540703142351,
          "to_latitude": 21.01554341749109,
          "to_longitude": 105.83933981521487,
          "from_longitude": 105.8394526208843
        }
      },
      {
        "id": 5400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568104474342,
            21.017421917368708,
            105.83570359060612,
            21.01760710757265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568104474342,
              21.017421917368708
            ],
            [
              105.83568854860142,
              21.017468649455925
            ],
            [
              105.8356975720762,
              21.017529144633375
            ],
            [
              105.83570054265041,
              21.01756075101611
            ],
            [
              105.83570359060612,
              21.01760710757265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5408",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017421917368708,
          "to_latitude": 21.01760710757265,
          "to_longitude": 105.83570359060612,
          "from_longitude": 105.83568104474342
        }
      },
      {
        "id": 5401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484376201683,
            21.014487970930706,
            105.83500131264405,
            21.014572646435013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83484376201683,
              21.014572646435013
            ],
            [
              105.8348703688541,
              21.0145674361397
            ],
            [
              105.83492621008186,
              21.01454760549321
            ],
            [
              105.8349490950709,
              21.014531483529094
            ],
            [
              105.83495522092083,
              21.014520819802527
            ],
            [
              105.83497048880734,
              21.01450857572812
            ],
            [
              105.83500131264405,
              21.014487970930706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.1_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5409",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014572646435013,
          "to_latitude": 21.014487970930706,
          "to_longitude": 105.83500131264405,
          "from_longitude": 105.83484376201683
        }
      },
      {
        "id": 5402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932543925326,
            21.0152562265677,
            105.83950204107313,
            21.01532227201758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932543925326,
              21.01528059880896
            ],
            [
              105.83932546918523,
              21.0152804469197
            ],
            [
              105.83932637145155,
              21.015275972413843
            ],
            [
              105.8393463191349,
              21.015279422768824
            ],
            [
              105.83936803986101,
              21.015289956487074
            ],
            [
              105.83939369057333,
              21.015266885339308
            ],
            [
              105.83941031469837,
              21.0152562265677
            ],
            [
              105.83944361306978,
              21.015272743010346
            ],
            [
              105.83950204107313,
              21.01532227201758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.32_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5410",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01528059880896,
          "to_latitude": 21.01532227201758,
          "to_longitude": 105.83950204107313,
          "from_longitude": 105.83932543925326
        }
      },
      {
        "id": 5403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.836249213482,
            21.015953122452064,
            105.83640273380175,
            21.01606634843395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640273380175,
              21.015953122452064
            ],
            [
              105.83628218457052,
              21.016047482394555
            ],
            [
              105.836249213482,
              21.01606634843395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.10_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5411",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015953122452064,
          "to_latitude": 21.01606634843395,
          "to_longitude": 105.836249213482,
          "from_longitude": 105.83640273380175
        }
      },
      {
        "id": 5404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743365792613,
            21.014040847970822,
            105.83756289523646,
            21.014143641821878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743365792613,
              21.014040847970822
            ],
            [
              105.83743775776166,
              21.014051722482126
            ],
            [
              105.83746496323162,
              21.014094158434503
            ],
            [
              105.83747977419992,
              21.014107976216263
            ],
            [
              105.83749446628067,
              21.01411583330186
            ],
            [
              105.83756289523646,
              21.014143641821878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.3_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5412",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014040847970822,
          "to_latitude": 21.014143641821878,
          "to_longitude": 105.83756289523646,
          "from_longitude": 105.83743365792613
        }
      },
      {
        "id": 5405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83649400188351,
            21.018611982287727,
            105.83657063973403,
            21.018768096087392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83657063973403,
              21.018768096087392
            ],
            [
              105.83655969082712,
              21.018721548997522
            ],
            [
              105.8365448377578,
              21.01865972681526
            ],
            [
              105.83653439106318,
              21.018611982287727
            ],
            [
              105.8365087234589,
              21.018615649147524
            ],
            [
              105.83649400188351,
              21.018617752890652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5413",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018768096087392,
          "to_latitude": 21.018617752890652,
          "to_longitude": 105.83649400188351,
          "from_longitude": 105.83657063973403
        }
      },
      {
        "id": 5406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83581040069686,
            21.01464020538838,
            105.83597731261612,
            21.0147160644856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83597731261612,
              21.014671227605636
            ],
            [
              105.83597715144417,
              21.01467130517166
            ],
            [
              105.8358999622164,
              21.014708400505864
            ],
            [
              105.83585765400915,
              21.0147160644856
            ],
            [
              105.83581040069686,
              21.01464020538838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.9_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5414",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014671227605636,
          "to_latitude": 21.01464020538838,
          "to_longitude": 105.83581040069686,
          "from_longitude": 105.83597731261612
        }
      },
      {
        "id": 5407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820927863228,
            21.014006880743096,
            105.83835071605117,
            21.014154682951364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83835071605117,
              21.014154682951364
            ],
            [
              105.83820927863228,
              21.014006880743096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.5_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5415",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014154682951364,
          "to_latitude": 21.014006880743096,
          "to_longitude": 105.83820927863228,
          "from_longitude": 105.83835071605117
        }
      },
      {
        "id": 5408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943624056737,
            21.01589715357858,
            105.83958708300037,
            21.01602708311512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958708300037,
              21.01602708311512
            ],
            [
              105.83953353537692,
              21.01598095870346
            ],
            [
              105.83943624056737,
              21.01589715357858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.14_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5416",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01602708311512,
          "to_latitude": 21.01589715357858,
          "to_longitude": 105.83943624056737,
          "from_longitude": 105.83958708300037
        }
      },
      {
        "id": 5409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83923109402718,
            21.01527342295281,
            105.83942616866808,
            21.01537059402379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923109402718,
              21.01527342295281
            ],
            [
              105.83927042192671,
              21.015277242876163
            ],
            [
              105.83930934265145,
              21.01527912196469
            ],
            [
              105.83932678318915,
              21.015282166497848
            ],
            [
              105.83932997896767,
              21.01528272427883
            ],
            [
              105.83934102434688,
              21.01528332191438
            ],
            [
              105.83935452120643,
              21.015290364601565
            ],
            [
              105.83937132698237,
              21.015305462170367
            ],
            [
              105.839406565728,
              21.015345554026144
            ],
            [
              105.83942616866808,
              21.01537059402379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.33_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5417",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01527342295281,
          "to_latitude": 21.01537059402379,
          "to_longitude": 105.83942616866808,
          "from_longitude": 105.83923109402718
        }
      },
      {
        "id": 5410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83579306247663,
            21.014386696739873,
            105.83591599289984,
            21.014558935644548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83579306247663,
              21.014386696739873
            ],
            [
              105.83582824048248,
              21.01442904321829
            ],
            [
              105.83585652775571,
              21.014465783808337
            ],
            [
              105.83587899419184,
              21.014496616096544
            ],
            [
              105.83588268868313,
              21.014504161547222
            ],
            [
              105.83589379411369,
              21.01452176141117
            ],
            [
              105.83590699978045,
              21.014543565400835
            ],
            [
              105.83591599289984,
              21.014558935644548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.6_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5418",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014386696739873,
          "to_latitude": 21.014558935644548,
          "to_longitude": 105.83591599289984,
          "from_longitude": 105.83579306247663
        }
      },
      {
        "id": 5411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363588040208,
            21.014496890110557,
            105.83646482772811,
            21.014595024469177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646482772811,
              21.014595024469177
            ],
            [
              105.83646474260213,
              21.01459493908138
            ],
            [
              105.83640111744387,
              21.014531064239577
            ],
            [
              105.8363588040208,
              21.014496890110557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.2_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5419",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014595024469177,
          "to_latitude": 21.014496890110557,
          "to_longitude": 105.8363588040208,
          "from_longitude": 105.83646482772811
        }
      },
      {
        "id": 5412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872343778053,
            21.016076641911006,
            105.8389045426936,
            21.01613259538251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389045426936,
              21.016076641911006
            ],
            [
              105.83887529738662,
              21.01608997770315
            ],
            [
              105.83885520466677,
              21.016101547719714
            ],
            [
              105.83883552096938,
              21.016113497780893
            ],
            [
              105.83882067460664,
              21.016116630202024
            ],
            [
              105.83880758543506,
              21.016109909721767
            ],
            [
              105.83879635294211,
              21.016100117254297
            ],
            [
              105.83879065596572,
              21.016100910373446
            ],
            [
              105.83876792994923,
              21.01611497813186
            ],
            [
              105.83875511995342,
              21.016118100507427
            ],
            [
              105.83873806097883,
              21.016126022787265
            ],
            [
              105.83872343778053,
              21.01613259538251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.3_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5420",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016076641911006,
          "to_latitude": 21.01613259538251,
          "to_longitude": 105.83872343778053,
          "from_longitude": 105.8389045426936
        }
      },
      {
        "id": 5413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361504199647,
            21.015876487754145,
            105.83631853225553,
            21.01598827338154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631853225553,
              21.015876487754145
            ],
            [
              105.83629857180597,
              21.01588990211072
            ],
            [
              105.8362961270642,
              21.015891545351415
            ],
            [
              105.8362378758369,
              21.01593069200885
            ],
            [
              105.83620873315198,
              21.0159498797375
            ],
            [
              105.8361504199647,
              21.01598827338154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.11_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5421",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015876487754145,
          "to_latitude": 21.01598827338154,
          "to_longitude": 105.8361504199647,
          "from_longitude": 105.83631853225553
        }
      },
      {
        "id": 5414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338922772248,
            21.015620003033337,
            105.83403589487756,
            21.015762465977858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83403589487756,
              21.015620003033337
            ],
            [
              105.8338922772248,
              21.01572839942437
            ],
            [
              105.83392257334116,
              21.015762465977858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.2_Ngách 164/9, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5422",
          "diaChiLapD": "Ngách 164/9, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015620003033337,
          "to_latitude": 21.015762465977858,
          "to_longitude": 105.83392257334116,
          "from_longitude": 105.83403589487756
        }
      },
      {
        "id": 5415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571248540419,
            21.014673450849468,
            105.83585765400915,
            21.01475916173276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585765400915,
              21.0147160644856
            ],
            [
              105.83579240783449,
              21.01475916173276
            ],
            [
              105.83577253585392,
              21.014732672941257
            ],
            [
              105.83575278371409,
              21.014727692042886
            ],
            [
              105.83571248540419,
              21.014673450849468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.9_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5423",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0147160644856,
          "to_latitude": 21.014673450849468,
          "to_longitude": 105.83571248540419,
          "from_longitude": 105.83585765400915
        }
      },
      {
        "id": 5416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697425967128,
            21.016288886987667,
            105.8371805996621,
            21.016364921343797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697425967128,
              21.016364921343797
            ],
            [
              105.83709063825967,
              21.016342358852118
            ],
            [
              105.83714544027109,
              21.016311380476367
            ],
            [
              105.8371805996621,
              21.016288886987667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.5_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5424",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016364921343797,
          "to_latitude": 21.016288886987667,
          "to_longitude": 105.8371805996621,
          "from_longitude": 105.83697425967128
        }
      },
      {
        "id": 5417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83680604365088,
            21.018725291487367,
            105.8368686375694,
            21.018929295506815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83680604365088,
              21.018725291487367
            ],
            [
              105.8368380734121,
              21.01883415575238
            ],
            [
              105.8368686375694,
              21.018929295506815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5425",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018725291487367,
          "to_latitude": 21.018929295506815,
          "to_longitude": 105.8368686375694,
          "from_longitude": 105.83680604365088
        }
      },
      {
        "id": 5418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587526717567,
            21.013954585712877,
            105.83612557815367,
            21.01408626364374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612557815367,
              21.01408626364374
            ],
            [
              105.83608637814159,
              21.014049922658643
            ],
            [
              105.83603939468937,
              21.014013143911352
            ],
            [
              105.83601896280469,
              21.013995607947688
            ],
            [
              105.83597788600909,
              21.013954585712877
            ],
            [
              105.83587526717567,
              21.01403682087844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.10_Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5426",
          "diaChiLapD": "Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01408626364374,
          "to_latitude": 21.01403682087844,
          "to_longitude": 105.83587526717567,
          "from_longitude": 105.83612557815367
        }
      },
      {
        "id": 5419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83741866503689,
            21.016527908510337,
            105.83758978871957,
            21.016617368582853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758978871957,
              21.016572046884292
            ],
            [
              105.83756696016137,
              21.016550559579972
            ],
            [
              105.83754238014869,
              21.016527908510337
            ],
            [
              105.83750287754725,
              21.016557112731473
            ],
            [
              105.83741866503689,
              21.016617368582853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.5_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5427",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016572046884292,
          "to_latitude": 21.016617368582853,
          "to_longitude": 105.83741866503689,
          "from_longitude": 105.83758978871957
        }
      },
      {
        "id": 5420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84067492728879,
            21.014900337373717,
            105.84087360900692,
            21.014917082125393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84067492728879,
              21.01490374879091
            ],
            [
              105.840735864577,
              21.014907683791098
            ],
            [
              105.84073651406746,
              21.014900337373717
            ],
            [
              105.84087360900692,
              21.014917082125393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.4_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5428",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01490374879091,
          "to_latitude": 21.014917082125393,
          "to_longitude": 105.84087360900692,
          "from_longitude": 105.84067492728879
        }
      },
      {
        "id": 5421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806083663896,
            21.013791562580092,
            105.83816493170224,
            21.013962593751028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83806083663896,
              21.013791562580092
            ],
            [
              105.83808709475083,
              21.013815234098793
            ],
            [
              105.83813231865264,
              21.013856379896232
            ],
            [
              105.8381492364465,
              21.013877804129734
            ],
            [
              105.83811221205524,
              21.013909327491177
            ],
            [
              105.83811225140649,
              21.013911033487766
            ],
            [
              105.83812341269856,
              21.013923098871558
            ],
            [
              105.83816493170224,
              21.013962593751028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.16_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5429",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013791562580092,
          "to_latitude": 21.013962593751028,
          "to_longitude": 105.83816493170224,
          "from_longitude": 105.83806083663896
        }
      },
      {
        "id": 5422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854487423801,
            21.01399580349682,
            105.83871662086013,
            21.014153317875863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871662086013,
              21.01399580349682
            ],
            [
              105.83870043744875,
              21.014013356142616
            ],
            [
              105.83868677261967,
              21.014027343088458
            ],
            [
              105.8386729123552,
              21.014039580548413
            ],
            [
              105.83867144758771,
              21.014040828798574
            ],
            [
              105.83863105374591,
              21.014074951197035
            ],
            [
              105.83860886946155,
              21.01409588076162
            ],
            [
              105.83858360891958,
              21.014118528966357
            ],
            [
              105.8385627195901,
              21.014136915889445
            ],
            [
              105.83856163512476,
              21.014137881361606
            ],
            [
              105.83855960976703,
              21.014139684243716
            ],
            [
              105.83854487423801,
              21.014153317875863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.7_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5430",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01399580349682,
          "to_latitude": 21.014153317875863,
          "to_longitude": 105.83854487423801,
          "from_longitude": 105.83871662086013
        }
      },
      {
        "id": 5423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83759894802361,
            21.014985866342773,
            105.83772918710015,
            21.015074198003433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759894802361,
              21.015074198003433
            ],
            [
              105.83762431539846,
              21.015059256623964
            ],
            [
              105.83768061297724,
              21.01502265447703
            ],
            [
              105.83772918710015,
              21.014985866342773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.22_Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5431",
          "diaChiLapD": "Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015074198003433,
          "to_latitude": 21.014985866342773,
          "to_longitude": 105.83772918710015,
          "from_longitude": 105.83759894802361
        }
      },
      {
        "id": 5424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630338275702,
            21.016323335772498,
            105.83651197806094,
            21.01636343266689
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630338275702,
              21.016323335772498
            ],
            [
              105.83633846322232,
              21.01636343266689
            ],
            [
              105.83636698872142,
              21.016345164721898
            ],
            [
              105.83639059562547,
              21.016333936296252
            ],
            [
              105.83644556581085,
              21.016331535328515
            ],
            [
              105.83651197806094,
              21.016333234719703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.8_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5432",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016323335772498,
          "to_latitude": 21.016333234719703,
          "to_longitude": 105.83651197806094,
          "from_longitude": 105.83630338275702
        }
      },
      {
        "id": 5425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83656598352768,
            21.015311371988098,
            105.83667382777806,
            21.015491564189453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667382777806,
              21.015491564189453
            ],
            [
              105.83667255604524,
              21.015489829930548
            ],
            [
              105.83666655112322,
              21.015481631088957
            ],
            [
              105.83666181019876,
              21.015475159318374
            ],
            [
              105.8365886150636,
              21.01538072497163
            ],
            [
              105.83658666466486,
              21.015337852183936
            ],
            [
              105.83656598352768,
              21.015311371988098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.7_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5433",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015491564189453,
          "to_latitude": 21.015311371988098,
          "to_longitude": 105.83656598352768,
          "from_longitude": 105.83667382777806
        }
      },
      {
        "id": 5426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83845592195554,
            21.014082496344137,
            105.83863827862906,
            21.01424176460955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863827862906,
              21.014082496344137
            ],
            [
              105.83862015793864,
              21.014100747691728
            ],
            [
              105.83858764062003,
              21.014132858615213
            ],
            [
              105.83856843354359,
              21.014151045763917
            ],
            [
              105.83853580838755,
              21.014181937856964
            ],
            [
              105.83851939696297,
              21.014198286709245
            ],
            [
              105.83850209991415,
              21.0141981714405
            ],
            [
              105.83849924961696,
              21.014198152068488
            ],
            [
              105.83845592195554,
              21.01424176460955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5434",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014082496344137,
          "to_latitude": 21.01424176460955,
          "to_longitude": 105.83845592195554,
          "from_longitude": 105.83863827862906
        }
      },
      {
        "id": 5427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704110674056,
            21.015260497590624,
            105.83717452727858,
            21.015449795476854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83704110674056,
              21.015260497590624
            ],
            [
              105.83704133882189,
              21.015261577608154
            ],
            [
              105.83704243093169,
              21.01526663300018
            ],
            [
              105.83705980637545,
              21.01528990940609
            ],
            [
              105.83712224439299,
              21.015373649009913
            ],
            [
              105.83712484821167,
              21.015377416191015
            ],
            [
              105.83714276076218,
              21.015403335488198
            ],
            [
              105.83715817751785,
              21.015423297655815
            ],
            [
              105.83717452727858,
              21.015449795476854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.4_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5435",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015260497590624,
          "to_latitude": 21.015449795476854,
          "to_longitude": 105.83717452727858,
          "from_longitude": 105.83704110674056
        }
      },
      {
        "id": 5428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83789882201896,
            21.015190936968782,
            105.83809635567933,
            21.015336498043478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83789882201896,
              21.015336498043478
            ],
            [
              105.83791799651254,
              21.015320013703978
            ],
            [
              105.837924087396,
              21.015315521778653
            ],
            [
              105.83794686744999,
              21.015295119556573
            ],
            [
              105.83797163903152,
              21.015275465327385
            ],
            [
              105.83799593998387,
              21.015257834828233
            ],
            [
              105.83801379372414,
              21.015243761366243
            ],
            [
              105.83803758943944,
              21.015227125993214
            ],
            [
              105.83807655435292,
              21.015200934750794
            ],
            [
              105.83809635567933,
              21.015190936968782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.3_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5436",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015336498043478,
          "to_latitude": 21.015190936968782,
          "to_longitude": 105.83809635567933,
          "from_longitude": 105.83789882201896
        }
      },
      {
        "id": 5429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723261162066,
            21.015989000894578,
            105.83734282788687,
            21.01618983017374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734261980986,
              21.016186975485475
            ],
            [
              105.83734233465945,
              21.01618983017374
            ],
            [
              105.83734107626003,
              21.01618979752885
            ],
            [
              105.837341541137,
              21.016186914859272
            ],
            [
              105.83734282788687,
              21.016178930347948
            ],
            [
              105.83733040345851,
              21.016165314880986
            ],
            [
              105.83728374909927,
              21.016114944984952
            ],
            [
              105.8372749049795,
              21.01610183937564
            ],
            [
              105.83730220554193,
              21.01606836791636
            ],
            [
              105.83723261162066,
              21.015989000894578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.3_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5437",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016186975485475,
          "to_latitude": 21.015989000894578,
          "to_longitude": 105.83723261162066,
          "from_longitude": 105.83734261980986
        }
      },
      {
        "id": 5430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569172803345,
            21.017930650390472,
            105.83595345852443,
            21.01797399408542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83595345852443,
              21.017930650390472
            ],
            [
              105.83586204486785,
              21.017948874128262
            ],
            [
              105.8357990272945,
              21.017957823998966
            ],
            [
              105.83569172803345,
              21.01797399408542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5438",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017930650390472,
          "to_latitude": 21.01797399408542,
          "to_longitude": 105.83569172803345,
          "from_longitude": 105.83595345852443
        }
      },
      {
        "id": 5431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8398947759855,
            21.01501484440384,
            105.8401583209714,
            21.015337836905324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8398947759855,
              21.01501484440384
            ],
            [
              105.83994627867628,
              21.015031318749017
            ],
            [
              105.84004231073781,
              21.015045661995472
            ],
            [
              105.8401192303869,
              21.01505145300558
            ],
            [
              105.84015698482294,
              21.01505429598105
            ],
            [
              105.8401576276514,
              21.01508164874218
            ],
            [
              105.8401583209714,
              21.015111146410085
            ],
            [
              105.8401581228878,
              21.01518483598827
            ],
            [
              105.84015803262027,
              21.01521182656361
            ],
            [
              105.84015459706055,
              21.015264290114544
            ],
            [
              105.84014382272267,
              21.01532238808608
            ],
            [
              105.84015299370647,
              21.015335767410605
            ],
            [
              105.8401544145309,
              21.015337836905324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.11_Ngách 41, Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5439",
          "diaChiLapD": "Ngách 41, Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01501484440384,
          "to_latitude": 21.015337836905324,
          "to_longitude": 105.8401544145309,
          "from_longitude": 105.8398947759855
        }
      },
      {
        "id": 5432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83678935237764,
            21.014097366853655,
            105.83700605764237,
            21.014241596577452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678935237764,
              21.014241596577452
            ],
            [
              105.83681187012198,
              21.014226765076543
            ],
            [
              105.83681876838945,
              21.014222333348414
            ],
            [
              105.83682566132565,
              21.014216946034903
            ],
            [
              105.83683458964838,
              21.01421154961317
            ],
            [
              105.83684189586664,
              21.01420730736116
            ],
            [
              105.83685001468301,
              21.014202679048076
            ],
            [
              105.8368668504287,
              21.014191316772553
            ],
            [
              105.83687942971727,
              21.01418341581372
            ],
            [
              105.83689230507557,
              21.01417616823531
            ],
            [
              105.83692004851633,
              21.014164017979137
            ],
            [
              105.83696455857799,
              21.014140187838755
            ],
            [
              105.83696479152857,
              21.014140044886574
            ],
            [
              105.83698605906343,
              21.01412699615579
            ],
            [
              105.83699163160837,
              21.014121842943883
            ],
            [
              105.8369999597461,
              21.014109218252393
            ],
            [
              105.83700605764237,
              21.014097366853655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.7_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5440",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014241596577452,
          "to_latitude": 21.014097366853655,
          "to_longitude": 105.83700605764237,
          "from_longitude": 105.83678935237764
        }
      },
      {
        "id": 5433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667943641382,
            21.01835502793109,
            105.83677041208583,
            21.018592410143096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83677041208583,
              21.018592410143096
            ],
            [
              105.83672087281124,
              21.01846110919281
            ],
            [
              105.83667943641382,
              21.01835502793109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5441",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018592410143096,
          "to_latitude": 21.01835502793109,
          "to_longitude": 105.83667943641382,
          "from_longitude": 105.83677041208583
        }
      },
      {
        "id": 5434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499838813403,
            21.014907151049634,
            105.83518825162244,
            21.015113994176534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499838813403,
              21.014907151049634
            ],
            [
              105.83503156442126,
              21.01493587424572
            ],
            [
              105.83507378550856,
              21.014975435992802
            ],
            [
              105.83509798267723,
              21.015000100801455
            ],
            [
              105.8351268064047,
              21.01503403346386
            ],
            [
              105.8351502979107,
              21.015065331396386
            ],
            [
              105.83517737066785,
              21.01509765948547
            ],
            [
              105.83518825162244,
              21.015113994176534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.3_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5442",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014907151049634,
          "to_latitude": 21.015113994176534,
          "to_longitude": 105.83518825162244,
          "from_longitude": 105.83499838813403
        }
      },
      {
        "id": 5435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83891942550164,
            21.015198726960445,
            105.83904789001384,
            21.015420637996257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904789001384,
              21.015420637996257
            ],
            [
              105.8390064247669,
              21.015374187365207
            ],
            [
              105.83897801975822,
              21.015342361448198
            ],
            [
              105.8389922932472,
              21.015311805448118
            ],
            [
              105.83891942550164,
              21.015209932479237
            ],
            [
              105.83892138025686,
              21.01520100802063
            ],
            [
              105.83891993325668,
              21.015198898902266
            ],
            [
              105.83891981686308,
              21.015198726960445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.2_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5443",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015420637996257,
          "to_latitude": 21.015198726960445,
          "to_longitude": 105.83891981686308,
          "from_longitude": 105.83904789001384
        }
      },
      {
        "id": 5436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83595433078067,
            21.017889401447242,
            105.83623675400119,
            21.01793682144735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83595433078067,
              21.01793682144735
            ],
            [
              105.83595606180774,
              21.0179365618446
            ],
            [
              105.83596577420947,
              21.017935105990183
            ],
            [
              105.83607966060265,
              21.017918029036988
            ],
            [
              105.83623675400119,
              21.017889401447242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5444",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01793682144735,
          "to_latitude": 21.017889401447242,
          "to_longitude": 105.83623675400119,
          "from_longitude": 105.83595433078067
        }
      },
      {
        "id": 5437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883846922416,
            21.01594887522112,
            105.83896980636077,
            21.01618274603191
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896980636077,
              21.01618274603191
            ],
            [
              105.83896973850959,
              21.016181965981175
            ],
            [
              105.83896917648092,
              21.016175385154764
            ],
            [
              105.83895710222266,
              21.016154576616287
            ],
            [
              105.83894029284723,
              21.016134202394017
            ],
            [
              105.83892511638199,
              21.01611477663167
            ],
            [
              105.8389109158185,
              21.01608769936293
            ],
            [
              105.8389045426936,
              21.016076641911006
            ],
            [
              105.83888806655477,
              21.016043265939608
            ],
            [
              105.83886434544341,
              21.015997222750592
            ],
            [
              105.83883846922416,
              21.01594887522112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.3_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5445",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01618274603191,
          "to_latitude": 21.01594887522112,
          "to_longitude": 105.83883846922416,
          "from_longitude": 105.83896980636077
        }
      },
      {
        "id": 5438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755541265718,
            21.013971463856706,
            105.83776541862635,
            21.014148510003555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755541265718,
              21.014148510003555
            ],
            [
              105.83755708009741,
              21.014147034958953
            ],
            [
              105.83756289523646,
              21.014143641821878
            ],
            [
              105.83758633223486,
              21.0141299708548
            ],
            [
              105.8376101548921,
              21.014117148823175
            ],
            [
              105.83763607895334,
              21.014104255023316
            ],
            [
              105.83765524068188,
              21.014091191287733
            ],
            [
              105.83765705479908,
              21.01408928287564
            ],
            [
              105.83766260153685,
              21.01408571402477
            ],
            [
              105.83767678631992,
              21.014073695433655
            ],
            [
              105.83776541862635,
              21.013971463856706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.3_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5446",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014148510003555,
          "to_latitude": 21.013971463856706,
          "to_longitude": 105.83776541862635,
          "from_longitude": 105.83755541265718
        }
      },
      {
        "id": 5439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83638459676794,
            21.01453240096238,
            105.83662239233392,
            21.014671231943876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662239233392,
              21.014545103493028
            ],
            [
              105.83660639050329,
              21.01454042218703
            ],
            [
              105.83658936759137,
              21.01453600060961
            ],
            [
              105.8365756515673,
              21.01453240096238
            ],
            [
              105.83656013001091,
              21.014542083976973
            ],
            [
              105.83653884405419,
              21.014556503841778
            ],
            [
              105.83652985511604,
              21.014562593319802
            ],
            [
              105.83648462312443,
              21.01459689436739
            ],
            [
              105.83643986577803,
              21.01462885823491
            ],
            [
              105.83638459676794,
              21.014671231943876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.1_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5447",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014545103493028,
          "to_latitude": 21.014671231943876,
          "to_longitude": 105.83638459676794,
          "from_longitude": 105.83662239233392
        }
      },
      {
        "id": 5440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856295295121,
            21.0147567857554,
            105.83877591553048,
            21.014956519825084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856295295121,
              21.014760951310738
            ],
            [
              105.83856351540484,
              21.014761418214963
            ],
            [
              105.83856455910316,
              21.014762279262552
            ],
            [
              105.83857374176084,
              21.0147567857554
            ],
            [
              105.83858871071575,
              21.01476941040038
            ],
            [
              105.83859997199035,
              21.014780586483596
            ],
            [
              105.8386113628391,
              21.014796356627663
            ],
            [
              105.83872209629733,
              21.014907811446307
            ],
            [
              105.83874844756471,
              21.014933810000127
            ],
            [
              105.83877591553048,
              21.014956519825084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.4_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5448",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014760951310738,
          "to_latitude": 21.014956519825084,
          "to_longitude": 105.83877591553048,
          "from_longitude": 105.83856295295121
        }
      },
      {
        "id": 5441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83480468195904,
            21.014641171187982,
            105.83493358355803,
            21.014871690558923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83481508540399,
              21.014641171187982
            ],
            [
              105.83481186847747,
              21.01465426566554
            ],
            [
              105.83480788691801,
              21.014673946596066
            ],
            [
              105.83480468195904,
              21.014684870565915
            ],
            [
              105.83482731776145,
              21.014720931837093
            ],
            [
              105.83483732655792,
              21.014739408765443
            ],
            [
              105.8348424318328,
              21.014753118147894
            ],
            [
              105.83485797807141,
              21.014774634350367
            ],
            [
              105.8348918071383,
              21.014812979133996
            ],
            [
              105.83492923382032,
              21.01485736779536
            ],
            [
              105.83493358355803,
              21.014871690558923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.2_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5449",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014641171187982,
          "to_latitude": 21.014871690558923,
          "to_longitude": 105.83493358355803,
          "from_longitude": 105.83481508540399
        }
      },
      {
        "id": 5442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483292608666,
            21.014704560467983,
            105.83499838813403,
            21.014907151049634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499838813403,
              21.014907151049634
            ],
            [
              105.83497487046371,
              21.014887386497172
            ],
            [
              105.8349689721581,
              21.014881212098018
            ],
            [
              105.8349610342259,
              21.014872904356515
            ],
            [
              105.83494465168165,
              21.014859963870112
            ],
            [
              105.83492518199462,
              21.014837117513025
            ],
            [
              105.83490757904997,
              21.01481213038265
            ],
            [
              105.8348736905257,
              21.0147734733783
            ],
            [
              105.83485105618715,
              21.014748912866054
            ],
            [
              105.8348487301602,
              21.014741639773245
            ],
            [
              105.83484553692743,
              21.014729609135497
            ],
            [
              105.83483292608666,
              21.014704560467983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.3_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5450",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014907151049634,
          "to_latitude": 21.014704560467983,
          "to_longitude": 105.83483292608666,
          "from_longitude": 105.83499838813403
        }
      },
      {
        "id": 5443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83757502954322,
            21.014155630194185,
            105.8377104247944,
            21.014408187921575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377104247944,
              21.014408187921575
            ],
            [
              105.83768635984113,
              21.014368843642156
            ],
            [
              105.83767133269964,
              21.01434216141916
            ],
            [
              105.83765963908208,
              21.01432296496238
            ],
            [
              105.83763291316687,
              21.014278227075216
            ],
            [
              105.83757502954322,
              21.014155630194185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.5_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5451",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014408187921575,
          "to_latitude": 21.014155630194185,
          "to_longitude": 105.83757502954322,
          "from_longitude": 105.8377104247944
        }
      },
      {
        "id": 5444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735882144786,
            21.01501038950234,
            105.83759894802361,
            21.0151136008634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735882144786,
              21.0151136008634
            ],
            [
              105.83736135219887,
              21.01511289473134
            ],
            [
              105.83738838558082,
              21.015105346205182
            ],
            [
              105.83742846902874,
              21.01508220611364
            ],
            [
              105.83750153774722,
              21.01504074251361
            ],
            [
              105.83755074328903,
              21.01501038950234
            ],
            [
              105.83759894802361,
              21.015074198003433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.22_Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5452",
          "diaChiLapD": "Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0151136008634,
          "to_latitude": 21.015074198003433,
          "to_longitude": 105.83759894802361,
          "from_longitude": 105.83735882144786
        }
      },
      {
        "id": 5445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594340556715,
            21.014628943562396,
            105.83614987129933,
            21.014822040944903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83594340556715,
              21.014628943562396
            ],
            [
              105.83596419667683,
              21.014661558001915
            ],
            [
              105.83597295490901,
              21.014664294189448
            ],
            [
              105.83600226717812,
              21.01470353345371
            ],
            [
              105.83604245570855,
              21.01474979422354
            ],
            [
              105.83607212509853,
              21.01477612735231
            ],
            [
              105.83610757411914,
              21.014798034272562
            ],
            [
              105.83614987129933,
              21.014822040944903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.8_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5453",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014628943562396,
          "to_latitude": 21.014822040944903,
          "to_longitude": 105.83614987129933,
          "from_longitude": 105.83594340556715
        }
      },
      {
        "id": 5446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8412952488762,
            21.01529073232839,
            105.84131084162696,
            21.015994873221867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84129798657331,
              21.01529073232839
            ],
            [
              105.8412952488762,
              21.015638023537687
            ],
            [
              105.84129587014515,
              21.01567418553127
            ],
            [
              105.8412964582167,
              21.015708373242482
            ],
            [
              105.84130142641628,
              21.015868176765814
            ],
            [
              105.84131084162696,
              21.015994873221867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.7_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5454",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01529073232839,
          "to_latitude": 21.015994873221867,
          "to_longitude": 105.84131084162696,
          "from_longitude": 105.84129798657331
        }
      },
      {
        "id": 5447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379593987602,
            21.013690109874563,
            105.83815151994403,
            21.01387988630204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379593987602,
              21.01387988630204
            ],
            [
              105.83802206026088,
              21.01382078952381
            ],
            [
              105.83806083663896,
              21.013791562580092
            ],
            [
              105.83807754814343,
              21.01377606583987
            ],
            [
              105.83811400618433,
              21.013741831990664
            ],
            [
              105.83815105341468,
              21.013706828394188
            ],
            [
              105.83815151994403,
              21.013706311258197
            ],
            [
              105.83814364958505,
              21.013693933406376
            ],
            [
              105.83814566834931,
              21.013691639207686
            ],
            [
              105.838146987259,
              21.013690109874563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.16_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5455",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01387988630204,
          "to_latitude": 21.013690109874563,
          "to_longitude": 105.838146987259,
          "from_longitude": 105.8379593987602
        }
      },
      {
        "id": 5448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84113557051624,
            21.01732112516391,
            105.84132921863598,
            21.017449892428644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84132921863598,
              21.01732325131894
            ],
            [
              105.8413210471437,
              21.017323122828763
            ],
            [
              105.84119387049068,
              21.01732112516391
            ],
            [
              105.84115743713122,
              21.017322389182947
            ],
            [
              105.84114195548274,
              21.017365768965703
            ],
            [
              105.84113557051624,
              21.017449892428644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5456",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01732325131894,
          "to_latitude": 21.017449892428644,
          "to_longitude": 105.84113557051624,
          "from_longitude": 105.84132921863598
        }
      },
      {
        "id": 5449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84126672333245,
            21.014297924537352,
            105.84127130960857,
            21.01459034169624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84126939448375,
              21.01459034169624
            ],
            [
              105.84127130960857,
              21.01458809855469
            ],
            [
              105.84126672333245,
              21.014297924537352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.3_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5457",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01459034169624,
          "to_latitude": 21.014297924537352,
          "to_longitude": 105.84126672333245,
          "from_longitude": 105.84126939448375
        }
      },
      {
        "id": 5450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327148784937,
            21.017512334540818,
            105.8334745128296,
            21.01768822232934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327148784937,
              21.01768822232934
            ],
            [
              105.83338417558353,
              21.017649980561345
            ],
            [
              105.8334745128296,
              21.017601264241343
            ],
            [
              105.83341035877018,
              21.017512334540818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.5_Ngách 41, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5458",
          "diaChiLapD": "Ngách 41, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01768822232934,
          "to_latitude": 21.017512334540818,
          "to_longitude": 105.83341035877018,
          "from_longitude": 105.83327148784937
        }
      },
      {
        "id": 5451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869828411704,
            21.014616105300217,
            105.83885074865982,
            21.01482859806664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83869828411704,
              21.014616105300217
            ],
            [
              105.83875380986608,
              21.014660887092816
            ],
            [
              105.83876549400541,
              21.014672165854375
            ],
            [
              105.83881126330103,
              21.014711507737175
            ],
            [
              105.83885074865982,
              21.01474626421243
            ],
            [
              105.83881782895584,
              21.01479897879896
            ],
            [
              105.83878396821382,
              21.01482859806664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.5_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5459",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014616105300217,
          "to_latitude": 21.01482859806664,
          "to_longitude": 105.83878396821382,
          "from_longitude": 105.83869828411704
        }
      },
      {
        "id": 5452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364530859487,
            21.018297100932447,
            105.83646306629613,
            21.018611705281224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646306629613,
              21.018611705281224
            ],
            [
              105.8364530859487,
              21.018297100932447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5460",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018611705281224,
          "to_latitude": 21.018297100932447,
          "to_longitude": 105.8364530859487,
          "from_longitude": 105.83646306629613
        }
      },
      {
        "id": 5453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366840036498,
            21.01636372071344,
            105.8368464793239,
            21.016565267250083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366840036498,
              21.01636372071344
            ],
            [
              105.83671607619529,
              21.01642346841245
            ],
            [
              105.83671181096634,
              21.016428472477546
            ],
            [
              105.83677868485579,
              21.01654377431238
            ],
            [
              105.83681903482567,
              21.016523319232157
            ],
            [
              105.8368464793239,
              21.016565267250083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.10_Ngách 178/80/4, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5461",
          "diaChiLapD": "Ngách 178/80/4, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01636372071344,
          "to_latitude": 21.016565267250083,
          "to_longitude": 105.8368464793239,
          "from_longitude": 105.8366840036498
        }
      },
      {
        "id": 5454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716090215866,
            21.014025381265828,
            105.83738088592706,
            21.01418352882239
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83738088592706,
              21.014131870908603
            ],
            [
              105.83733567983747,
              21.014150418913104
            ],
            [
              105.83726052943167,
              21.01418352882239
            ],
            [
              105.83719704711358,
              21.014083915701587
            ],
            [
              105.83716090215866,
              21.014025381265828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.2_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5462",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014131870908603,
          "to_latitude": 21.014025381265828,
          "to_longitude": 105.83716090215866,
          "from_longitude": 105.83738088592706
        }
      },
      {
        "id": 5455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953586552651,
            21.015651387969843,
            105.83978826081847,
            21.01578740518556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953586552651,
              21.015651387969843
            ],
            [
              105.83959855109214,
              21.015672878830408
            ],
            [
              105.8395892372245,
              21.01569789712415
            ],
            [
              105.83971679348906,
              21.015745436500517
            ],
            [
              105.83978826081847,
              21.01578740518556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.15_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5463",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015651387969843,
          "to_latitude": 21.01578740518556,
          "to_longitude": 105.83978826081847,
          "from_longitude": 105.83953586552651
        }
      },
      {
        "id": 5456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932361021492,
            21.0150492384167,
            105.839550524781,
            21.01528035492968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932361021492,
              21.01528035492968
            ],
            [
              105.83932366819545,
              21.015280058386224
            ],
            [
              105.83932535429247,
              21.015271604022264
            ],
            [
              105.83934733901711,
              21.015261070552185
            ],
            [
              105.83936141487428,
              21.015229764016045
            ],
            [
              105.83940747790997,
              21.015138127794817
            ],
            [
              105.83947479308164,
              21.015164441662623
            ],
            [
              105.83951757539405,
              21.015094381553148
            ],
            [
              105.839550524781,
              21.0150492384167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.31_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5464",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01528035492968,
          "to_latitude": 21.0150492384167,
          "to_longitude": 105.839550524781,
          "from_longitude": 105.83932361021492
        }
      },
      {
        "id": 5457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367751552905,
            21.014044124439497,
            105.83702681423607,
            21.01424021672735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367751552905,
              21.01424021672735
            ],
            [
              105.83683119560317,
              21.01420389702906
            ],
            [
              105.83686527920392,
              21.014181934580787
            ],
            [
              105.83687950318142,
              21.014171065666005
            ],
            [
              105.83692206838916,
              21.014151400838035
            ],
            [
              105.83693927431737,
              21.014142425763
            ],
            [
              105.8369645599097,
              21.0141311167503
            ],
            [
              105.83697337788949,
              21.01412404809287
            ],
            [
              105.8369856565394,
              21.014107953651077
            ],
            [
              105.83700763526784,
              21.01407393306636
            ],
            [
              105.83702681423607,
              21.014044124439497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.16_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5465",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01424021672735,
          "to_latitude": 21.014044124439497,
          "to_longitude": 105.83702681423607,
          "from_longitude": 105.8367751552905
        }
      },
      {
        "id": 5458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83682015419197,
            21.01659044262347,
            105.83705372780452,
            21.0167504998757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705372780452,
              21.016654717283128
            ],
            [
              105.83698458637232,
              21.01659044262347
            ],
            [
              105.83696918349669,
              21.016601601865908
            ],
            [
              105.83691652361921,
              21.016658789341353
            ],
            [
              105.83687695147263,
              21.016693315559536
            ],
            [
              105.83683733828951,
              21.016729800160565
            ],
            [
              105.83682015419197,
              21.0167504998757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.7_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5466",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016654717283128,
          "to_latitude": 21.0167504998757,
          "to_longitude": 105.83682015419197,
          "from_longitude": 105.83705372780452
        }
      },
      {
        "id": 5459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630952569341,
            21.015924137070904,
            105.83648756689567,
            21.016185142978603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83639630610519,
              21.015924137070904
            ],
            [
              105.83639664454722,
              21.01592462585778
            ],
            [
              105.8363994440645,
              21.015928643184242
            ],
            [
              105.83640273380175,
              21.015953122452064
            ],
            [
              105.83646786160944,
              21.01602794237431
            ],
            [
              105.83648756689567,
              21.016049842609636
            ],
            [
              105.83633407385784,
              21.016165206465462
            ],
            [
              105.83630952569341,
              21.016185142978603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.10_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5467",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015924137070904,
          "to_latitude": 21.016185142978603,
          "to_longitude": 105.83630952569341,
          "from_longitude": 105.83639630610519
        }
      },
      {
        "id": 5460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83738922357537,
            21.01477443467453,
            105.83757848091426,
            21.015042457653887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748228847628,
              21.015042457653887
            ],
            [
              105.83744550041148,
              21.01498302865574
            ],
            [
              105.83741321528134,
              21.014939814745414
            ],
            [
              105.83738922357537,
              21.014899808905934
            ],
            [
              105.83741345419169,
              21.014883878703117
            ],
            [
              105.83747113823931,
              21.014848198315338
            ],
            [
              105.83750079555298,
              21.014828388233607
            ],
            [
              105.83750593112087,
              21.014828355727417
            ],
            [
              105.83757848091426,
              21.01477443467453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.21_Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5468",
          "diaChiLapD": "Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015042457653887,
          "to_latitude": 21.01477443467453,
          "to_longitude": 105.83757848091426,
          "from_longitude": 105.83748228847628
        }
      },
      {
        "id": 5461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84043367898143,
            21.01496932609094,
            105.84054721655151,
            21.015408345152952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84043367898143,
              21.015408345152952
            ],
            [
              105.84043383790556,
              21.01540752333763
            ],
            [
              105.84044491315898,
              21.015350616119974
            ],
            [
              105.84045074143683,
              21.015323991909234
            ],
            [
              105.8404670770843,
              21.015288471425393
            ],
            [
              105.84048210695371,
              21.01527244534332
            ],
            [
              105.84048708096806,
              21.015261776280454
            ],
            [
              105.84050137551127,
              21.015223532722384
            ],
            [
              105.84051382084947,
              21.015182134306638
            ],
            [
              105.8405206882042,
              21.015135977265693
            ],
            [
              105.8405305944978,
              21.015086937475647
            ],
            [
              105.84053874784153,
              21.015043164913287
            ],
            [
              105.84054721655151,
              21.01496932609094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.7_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5469",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015408345152952,
          "to_latitude": 21.01496932609094,
          "to_longitude": 105.84054721655151,
          "from_longitude": 105.84043367898143
        }
      },
      {
        "id": 5462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8402143300833,
            21.016416487292574,
            105.84036672917428,
            21.016753259957987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8402143300833,
              21.016753259957987
            ],
            [
              105.84021451985637,
              21.016752621344924
            ],
            [
              105.84022324192019,
              21.01672345292233
            ],
            [
              105.84022159393577,
              21.016667099012043
            ],
            [
              105.84022937602279,
              21.01648284543184
            ],
            [
              105.84025561503266,
              21.01646072053754
            ],
            [
              105.84025263837977,
              21.016417548510073
            ],
            [
              105.84036672917428,
              21.016416487292574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.8_Ngách 222/67, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5470",
          "diaChiLapD": "Ngách 222/67, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016753259957987,
          "to_latitude": 21.016416487292574,
          "to_longitude": 105.84036672917428,
          "from_longitude": 105.8402143300833
        }
      },
      {
        "id": 5463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84127346606401,
            21.01379581720199,
            105.84128446727807,
            21.01422867516412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84127346606401,
              21.01379581720199
            ],
            [
              105.84127776772497,
              21.013920158984575
            ],
            [
              105.84128046880986,
              21.014053429818816
            ],
            [
              105.84128402625319,
              21.014209554295388
            ],
            [
              105.84128446727807,
              21.01422867516412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.1_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5471",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01379581720199,
          "to_latitude": 21.01422867516412,
          "to_longitude": 105.84128446727807,
          "from_longitude": 105.84127346606401
        }
      },
      {
        "id": 5464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318889255668,
            21.01752781857958,
            105.8334182930753,
            21.017939766566137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334182930753,
              21.017939766566137
            ],
            [
              105.83337008329522,
              21.017861927114254
            ],
            [
              105.83335875279525,
              21.017843634516986
            ],
            [
              105.8332757763265,
              21.01769655163451
            ],
            [
              105.83327148784937,
              21.01768822232934
            ],
            [
              105.83318889255668,
              21.01752781857958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5472",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017939766566137,
          "to_latitude": 21.01752781857958,
          "to_longitude": 105.83318889255668,
          "from_longitude": 105.8334182930753
        }
      },
      {
        "id": 5465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856430984075,
            21.016088561835186,
            105.8389668432914,
            21.016207590491057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389668432914,
              21.01618217716167
            ],
            [
              105.83896672479226,
              21.01618145697348
            ],
            [
              105.83896591194387,
              21.016176467961014
            ],
            [
              105.83896138082744,
              21.016175711727104
            ],
            [
              105.83895572896341,
              21.016174767730067
            ],
            [
              105.83894703610677,
              21.01616001127628
            ],
            [
              105.83892157171617,
              21.016121326225548
            ],
            [
              105.83889756604628,
              21.016088561835186
            ],
            [
              105.8388648898192,
              21.01610745853126
            ],
            [
              105.8388210267257,
              21.01612781940876
            ],
            [
              105.8388122423498,
              21.016123012409004
            ],
            [
              105.83879924478603,
              21.016112891742036
            ],
            [
              105.83879322887606,
              21.016110837665888
            ],
            [
              105.83876940713847,
              21.016124548634163
            ],
            [
              105.83875810421311,
              21.01612710358089
            ],
            [
              105.83872945686853,
              21.016138142211954
            ],
            [
              105.8386895825876,
              21.016159477840503
            ],
            [
              105.83865919740725,
              21.016172136378536
            ],
            [
              105.83862038294862,
              21.016185519300297
            ],
            [
              105.83856430984075,
              21.016207590491057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.4_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5473",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01618217716167,
          "to_latitude": 21.016207590491057,
          "to_longitude": 105.83856430984075,
          "from_longitude": 105.8389668432914
        }
      },
      {
        "id": 5466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603801686235,
            21.013847417364936,
            105.8364903920172,
            21.014167380779764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364903920172,
              21.013847417364936
            ],
            [
              105.83647793412909,
              21.01385224763836
            ],
            [
              105.8364555237357,
              21.0138725268171
            ],
            [
              105.83641597859443,
              21.01390091989432
            ],
            [
              105.83637049517777,
              21.013927362286047
            ],
            [
              105.83633382800488,
              21.013952635008476
            ],
            [
              105.83631446185606,
              21.013967526826086
            ],
            [
              105.83630534832422,
              21.01397317613078
            ],
            [
              105.83629139122402,
              21.01398050214356
            ],
            [
              105.83624725258034,
              21.014003847059286
            ],
            [
              105.83621584592896,
              21.01401993610837
            ],
            [
              105.83619526937755,
              21.01403132664125
            ],
            [
              105.83617405882863,
              21.01404373731834
            ],
            [
              105.83614681857405,
              21.01406436896868
            ],
            [
              105.83612557815367,
              21.01408626364374
            ],
            [
              105.8361245270365,
              21.01408734725934
            ],
            [
              105.83608032875489,
              21.014134001940374
            ],
            [
              105.83603801686235,
              21.014167380779764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.10_Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5474",
          "diaChiLapD": "Ngõ 292, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013847417364936,
          "to_latitude": 21.014167380779764,
          "to_longitude": 105.83603801686235,
          "from_longitude": 105.8364903920172
        }
      },
      {
        "id": 5467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832106143144,
            21.015762290098333,
            105.83877550975112,
            21.0160156755606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832106143144,
              21.015793178063657
            ],
            [
              105.83832267813371,
              21.015792605582227
            ],
            [
              105.83832704724702,
              21.015791055798015
            ],
            [
              105.83834074138863,
              21.015794499149088
            ],
            [
              105.83834663384079,
              21.01579598120626
            ],
            [
              105.83839470217109,
              21.015765408652392
            ],
            [
              105.83841155210922,
              21.015774818497842
            ],
            [
              105.8384304453997,
              21.0158064628697
            ],
            [
              105.8385469400914,
              21.015762290098333
            ],
            [
              105.83858733362219,
              21.015826274085494
            ],
            [
              105.83860936523034,
              21.015854727231584
            ],
            [
              105.83860344678152,
              21.015879049514407
            ],
            [
              105.8386282240644,
              21.01590394216326
            ],
            [
              105.83863325869554,
              21.01590546366456
            ],
            [
              105.83867347494596,
              21.015917599840364
            ],
            [
              105.83877550975112,
              21.0160156755606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.20_Ngách 143/31, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5475",
          "diaChiLapD": "Ngách 143/31, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015793178063657,
          "to_latitude": 21.0160156755606,
          "to_longitude": 105.83877550975112,
          "from_longitude": 105.83832106143144
        }
      },
      {
        "id": 5468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036558734837,
            21.01741619343157,
            105.84087983646121,
            21.017537942461885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406918148901,
              21.017529558158714
            ],
            [
              105.84086092420156,
              21.017537942461885
            ],
            [
              105.84087495518371,
              21.017485786026647
            ],
            [
              105.84087983646121,
              21.017458054620594
            ],
            [
              105.84087297394821,
              21.01741619343157
            ],
            [
              105.84081207249635,
              21.01742260836994
            ],
            [
              105.84067951376531,
              21.017431441281502
            ],
            [
              105.8405890548499,
              21.01742984338774
            ],
            [
              105.84057581126454,
              21.017425298775
            ],
            [
              105.84052178647234,
              21.01742565603434
            ],
            [
              105.84042046491277,
              21.017425542426956
            ],
            [
              105.8403723251299,
              21.01742706914273
            ],
            [
              105.84036603607323,
              21.017427268209932
            ],
            [
              105.84036558734837,
              21.017431478544413
            ],
            [
              105.84037207955588,
              21.017432169955168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.5_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5476",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017529558158714,
          "to_latitude": 21.017432169955168,
          "to_longitude": 105.84037207955588,
          "from_longitude": 105.8406918148901
        }
      },
      {
        "id": 5469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83972627673656,
            21.0155742134483,
            105.83982890551927,
            21.015603010477744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83972627673656,
              21.0155742134483
            ],
            [
              105.83974766227121,
              21.015580876650183
            ],
            [
              105.83978944837857,
              21.01558923024191
            ],
            [
              105.83982890551927,
              21.015598764091486
            ],
            [
              105.83982862095492,
              21.015602230262257
            ],
            [
              105.83982847237566,
              21.015603010477744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.17_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5477",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0155742134483,
          "to_latitude": 21.015603010477744,
          "to_longitude": 105.83982847237566,
          "from_longitude": 105.83972627673656
        }
      },
      {
        "id": 5470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83789840238445,
            21.0146250810841,
            105.83798816390643,
            21.014741657095097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83789840238445,
              21.0146250810841
            ],
            [
              105.83790589972583,
              21.014634219110867
            ],
            [
              105.83792932959624,
              21.01466214250451
            ],
            [
              105.83793597459679,
              21.014670061762217
            ],
            [
              105.83795309640652,
              21.01469131700691
            ],
            [
              105.83798816390643,
              21.014741657095097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.8_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5478",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0146250810841,
          "to_latitude": 21.014741657095097,
          "to_longitude": 105.83798816390643,
          "from_longitude": 105.83789840238445
        }
      },
      {
        "id": 5471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979054845805,
            21.017282590687756,
            105.83979759057732,
            21.017284532156335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979759057732,
              21.017282590687756
            ],
            [
              105.83979595971071,
              21.01728304041493
            ],
            [
              105.83979283724338,
              21.01728390124634
            ],
            [
              105.83979054845805,
              21.017284532156335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.7_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5479",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017282590687756,
          "to_latitude": 21.017284532156335,
          "to_longitude": 105.83979054845805,
          "from_longitude": 105.83979759057732
        }
      },
      {
        "id": 5472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855830210365,
            21.014746856842947,
            105.83856855412512,
            21.01475799996737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856855412512,
              21.01475799996737
            ],
            [
              105.83856834315264,
              21.01475777249156
            ],
            [
              105.83855830210365,
              21.014746856842947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.3_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5480",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01475799996737,
          "to_latitude": 21.014746856842947,
          "to_longitude": 105.83855830210365,
          "from_longitude": 105.83856855412512
        }
      },
      {
        "id": 5473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84093656198093,
            21.016728296731184,
            105.84106435387208,
            21.01673236987839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84093656198093,
              21.016728296731184
            ],
            [
              105.84097272907319,
              21.01673000839428
            ],
            [
              105.84106383530826,
              21.01673235618409
            ],
            [
              105.84106435387208,
              21.01673236987839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.2_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5481",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016728296731184,
          "to_latitude": 21.01673236987839,
          "to_longitude": 105.84106435387208,
          "from_longitude": 105.84093656198093
        }
      },
      {
        "id": 5474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746580085825,
            21.016147163263987,
            105.83771412845623,
            21.01622693983344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83771412845623,
              21.016147163263987
            ],
            [
              105.83770343355039,
              21.01614939088982
            ],
            [
              105.83763939942969,
              21.01616786821247
            ],
            [
              105.8375770097228,
              21.016189014571427
            ],
            [
              105.83754734894191,
              21.016201013606576
            ],
            [
              105.837529647796,
              21.016207816221264
            ],
            [
              105.83748546130187,
              21.01622559874443
            ],
            [
              105.83747999727568,
              21.01622693983344
            ],
            [
              105.83747412372509,
              21.01622350397995
            ],
            [
              105.83746580085825,
              21.016214343807626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.1_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5482",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016147163263987,
          "to_latitude": 21.016214343807626,
          "to_longitude": 105.83746580085825,
          "from_longitude": 105.83771412845623
        }
      },
      {
        "id": 5475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83727372246919,
            21.01305299374952,
            105.83733117726739,
            21.013071085541757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83727372246919,
              21.01305299374952
            ],
            [
              105.83730286380082,
              21.013071085541757
            ],
            [
              105.83732089909188,
              21.0130549320386
            ],
            [
              105.83732538612485,
              21.013057114727655
            ],
            [
              105.83732943525857,
              21.013059084604194
            ],
            [
              105.83733117726739,
              21.01305649461607
            ],
            [
              105.83733013128318,
              21.01305590995811
            ],
            [
              105.83732739904983,
              21.013054462890285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.7_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5483",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01305299374952,
          "to_latitude": 21.013054462890285,
          "to_longitude": 105.83732739904983,
          "from_longitude": 105.83727372246919
        }
      },
      {
        "id": 5476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765135016363,
            21.013462166238746,
            105.83765426842524,
            21.013468502441366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83765135016363,
              21.013462166238746
            ],
            [
              105.8376522089904,
              21.013464030781567
            ],
            [
              105.83765311554018,
              21.013466000766698
            ],
            [
              105.83765402304178,
              21.013467968940684
            ],
            [
              105.83765426842524,
              21.013468502441366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.13_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5484",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013462166238746,
          "to_latitude": 21.013468502441366,
          "to_longitude": 105.83765426842524,
          "from_longitude": 105.83765135016363
        }
      },
      {
        "id": 5477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379331180743,
            21.014385659587955,
            105.83794539781108,
            21.01439646579869
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794539781108,
              21.01439646579869
            ],
            [
              105.83793326695385,
              21.014385792531886
            ],
            [
              105.8379331180743,
              21.014385659587955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.2_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5485",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01439646579869,
          "to_latitude": 21.014385659587955,
          "to_longitude": 105.8379331180743,
          "from_longitude": 105.83794539781108
        }
      },
      {
        "id": 5478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357785872976,
            21.0156896063301,
            105.83578492937453,
            21.015692190901834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578492937453,
              21.0156896063301
            ],
            [
              105.83578417020944,
              21.015689916252708
            ],
            [
              105.8357819166965,
              21.015690834160715
            ],
            [
              105.8357785872976,
              21.015692190901834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.16_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5486",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0156896063301,
          "to_latitude": 21.015692190901834,
          "to_longitude": 105.8357785872976,
          "from_longitude": 105.83578492937453
        }
      },
      {
        "id": 5479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83761255910713,
            21.013607633610494,
            105.83761685876833,
            21.013611227146644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83761685876833,
              21.013611227146644
            ],
            [
              105.8376149848553,
              21.013609661148873
            ],
            [
              105.83761306552643,
              21.01360805834246
            ],
            [
              105.83761255910713,
              21.013607633610494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5487",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013611227146644,
          "to_latitude": 21.013607633610494,
          "to_longitude": 105.83761255910713,
          "from_longitude": 105.83761685876833
        }
      },
      {
        "id": 5480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83925088104127,
            21.016077161987358,
            105.83931512011752,
            21.01629161243637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925088104127,
              21.016077161987358
            ],
            [
              105.83925701481583,
              21.016095982007833
            ],
            [
              105.8392638328281,
              21.016121216057076
            ],
            [
              105.83928116142982,
              21.016162860409704
            ],
            [
              105.8392868992532,
              21.016185354889668
            ],
            [
              105.83929554508171,
              21.01622952052102
            ],
            [
              105.83930005305622,
              21.01626017808087
            ],
            [
              105.83930144388434,
              21.01629161243637
            ],
            [
              105.83930527400105,
              21.016290522317306
            ],
            [
              105.83931512011752,
              21.01628771800587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5488",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016077161987358,
          "to_latitude": 21.01628771800587,
          "to_longitude": 105.83931512011752,
          "from_longitude": 105.83925088104127
        }
      },
      {
        "id": 5481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786125145295,
            21.013387811684215,
            105.83799049932085,
            21.013550923390017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83786125145295,
              21.013387811684215
            ],
            [
              105.83788604098369,
              21.013422400515537
            ],
            [
              105.83793596420931,
              21.013488263444472
            ],
            [
              105.83793938638162,
              21.0134921620676
            ],
            [
              105.83795782835911,
              21.013513171462375
            ],
            [
              105.83799049932085,
              21.013550923390017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.15_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5489",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013387811684215,
          "to_latitude": 21.013550923390017,
          "to_longitude": 105.83799049932085,
          "from_longitude": 105.83786125145295
        }
      },
      {
        "id": 5482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83834871789027,
            21.015529285375,
            105.83844400187212,
            21.015622414652842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844400187212,
              21.015531793409316
            ],
            [
              105.8384426208647,
              21.015530820217336
            ],
            [
              105.83844065859472,
              21.015529285375
            ],
            [
              105.83842338524997,
              21.015546517304642
            ],
            [
              105.83839668245369,
              21.01557311516578
            ],
            [
              105.83835480684934,
              21.015617234465115
            ],
            [
              105.83834871789027,
              21.015622414652842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.18_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5490",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015531793409316,
          "to_latitude": 21.015622414652842,
          "to_longitude": 105.83834871789027,
          "from_longitude": 105.83844400187212
        }
      },
      {
        "id": 5483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829059619595,
            21.015563462301106,
            105.83842168847742,
            21.015691505037935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83829059619595,
              21.015691505037935
            ],
            [
              105.83831277017387,
              21.015669887304817
            ],
            [
              105.83833557275022,
              21.015651277820005
            ],
            [
              105.83835625783507,
              21.0156364072752
            ],
            [
              105.83838343918136,
              21.015609029386013
            ],
            [
              105.83841579127025,
              21.01557775569619
            ],
            [
              105.83842168847742,
              21.015572173613272
            ],
            [
              105.8384114975786,
              21.015564401916023
            ],
            [
              105.83841026778263,
              21.015563462301106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.19_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5491",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015691505037935,
          "to_latitude": 21.015563462301106,
          "to_longitude": 105.83841026778263,
          "from_longitude": 105.83829059619595
        }
      },
      {
        "id": 5484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353120268496,
            21.013940570620747,
            105.8353270229124,
            21.01395169068274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353120268496,
              21.01395169068274
            ],
            [
              105.83532688694635,
              21.01394068328814
            ],
            [
              105.8353270229124,
              21.013940570620747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.2_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5492",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01395169068274,
          "to_latitude": 21.013940570620747,
          "to_longitude": 105.8353270229124,
          "from_longitude": 105.8353120268496
        }
      },
      {
        "id": 5485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909874857895,
            21.014599333058282,
            105.83912276473784,
            21.01459956350614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909874857895,
              21.01459956350614
            ],
            [
              105.83910180062026,
              21.01459953490524
            ],
            [
              105.83912276473784,
              21.014599333058282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.22_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5493",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01459956350614,
          "to_latitude": 21.014599333058282,
          "to_longitude": 105.83912276473784,
          "from_longitude": 105.83909874857895
        }
      },
      {
        "id": 5486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884767292805,
            21.01386938637708,
            105.83885770845865,
            21.013881516509453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885770845865,
              21.01386938637708
            ],
            [
              105.83885150441495,
              21.01387547308244
            ],
            [
              105.83884990485403,
              21.013877042643163
            ],
            [
              105.83884767292805,
              21.013879232223687
            ],
            [
              105.83885096394096,
              21.013881516509453
            ],
            [
              105.83885488298628,
              21.013878105580904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.20_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5494",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01386938637708,
          "to_latitude": 21.013878105580904,
          "to_longitude": 105.83885488298628,
          "from_longitude": 105.83885770845865
        }
      },
      {
        "id": 5487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364495394452,
            21.017499939168165,
            105.83658167138938,
            21.01779926458579
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83658167138938,
              21.01779926458579
            ],
            [
              105.83650908751557,
              21.017630948947836
            ],
            [
              105.83649583371525,
              21.01760178940293
            ],
            [
              105.83647741046948,
              21.017561257491174
            ],
            [
              105.8364495394452,
              21.017499939168165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5495",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01779926458579,
          "to_latitude": 21.017499939168165,
          "to_longitude": 105.8364495394452,
          "from_longitude": 105.83658167138938
        }
      },
      {
        "id": 5488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83658149998013,
            21.01258997932549,
            105.83664287307103,
            21.012653294936946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83658149998013,
              21.012602084003436
            ],
            [
              105.83659275859753,
              21.01259127846845
            ],
            [
              105.83659447414564,
              21.01258997932549
            ],
            [
              105.83660448411919,
              21.012599686739303
            ],
            [
              105.83661028893172,
              21.01260531509283
            ],
            [
              105.83661386937607,
              21.012622597850907
            ],
            [
              105.8366230251252,
              21.01263384946153
            ],
            [
              105.83664287307103,
              21.012653294936946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.1_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5496",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012602084003436,
          "to_latitude": 21.012653294936946,
          "to_longitude": 105.83664287307103,
          "from_longitude": 105.83658149998013
        }
      },
      {
        "id": 5489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368028443808,
            21.014273671457378,
            105.83697783961581,
            21.01453894303614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368028443808,
              21.014273671457378
            ],
            [
              105.8368908360468,
              21.014406142696625
            ],
            [
              105.83692087793193,
              21.01444853768801
            ],
            [
              105.83697783961581,
              21.01453251656155
            ],
            [
              105.83697148666572,
              21.01453641542093
            ],
            [
              105.83696736735928,
              21.01453894303614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.17_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5497",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014273671457378,
          "to_latitude": 21.01453894303614,
          "to_longitude": 105.83696736735928,
          "from_longitude": 105.8368028443808
        }
      },
      {
        "id": 5490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998995920133,
            21.016848462164518,
            105.84003020393247,
            21.017015621870698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003020393247,
              21.016848462164518
            ],
            [
              105.84002985383519,
              21.016848471119012
            ],
            [
              105.83998995920133,
              21.01684933829985
            ],
            [
              105.8399917740268,
              21.016897194766212
            ],
            [
              105.83999201566867,
              21.01691337482239
            ],
            [
              105.83999219434337,
              21.016979629900998
            ],
            [
              105.83999414184738,
              21.017015621870698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.2_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5498",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016848462164518,
          "to_latitude": 21.017015621870698,
          "to_longitude": 105.83999414184738,
          "from_longitude": 105.84003020393247
        }
      },
      {
        "id": 5491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84029543222688,
            21.01535537564207,
            105.84043209120247,
            21.015408050836346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029543222688,
              21.01535537564207
            ],
            [
              105.84031428290955,
              21.01536029542625
            ],
            [
              105.84033736675897,
              21.015365504104878
            ],
            [
              105.84035703281722,
              21.015370533662974
            ],
            [
              105.84040515445658,
              21.015382711671503
            ],
            [
              105.84043209120247,
              21.015392102197534
            ],
            [
              105.84043022189248,
              21.015407676684056
            ],
            [
              105.84043017781602,
              21.015408050836346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.10_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5499",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01535537564207,
          "to_latitude": 21.015408050836346,
          "to_longitude": 105.84043017781602,
          "from_longitude": 105.84029543222688
        }
      },
      {
        "id": 5492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84113535963984,
            21.014246250021223,
            105.84127516493618,
            21.014251765372386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84113535963984,
              21.014251765372386
            ],
            [
              105.84127516493618,
              21.014246250021223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.2_Ngõ 324, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5500",
          "diaChiLapD": "Ngõ 324, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014251765372386,
          "to_latitude": 21.014246250021223,
          "to_longitude": 105.84127516493618,
          "from_longitude": 105.84113535963984
        }
      },
      {
        "id": 5493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83935436351594,
            21.016401699139827,
            105.83937277869026,
            21.01641132381916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936497178652,
              21.016401699139827
            ],
            [
              105.83935436351594,
              21.016407029918334
            ],
            [
              105.83935489017615,
              21.016409006282995
            ],
            [
              105.83935601611087,
              21.01641132381916
            ],
            [
              105.83936770520111,
              21.016406264997578
            ],
            [
              105.83937277869026,
              21.01640406861584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.4_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5501",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016401699139827,
          "to_latitude": 21.01640406861584,
          "to_longitude": 105.83937277869026,
          "from_longitude": 105.83936497178652
        }
      },
      {
        "id": 5494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661731771467,
            21.016166032441646,
            105.83675021935417,
            21.016255183962564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675021935417,
              21.016166032441646
            ],
            [
              105.83674571973242,
              21.01616867843887
            ],
            [
              105.83670948668598,
              21.01619201802118
            ],
            [
              105.83666663576749,
              21.01622196200156
            ],
            [
              105.8366640000271,
              21.01622368205767
            ],
            [
              105.83663785347457,
              21.016240744289547
            ],
            [
              105.83661731771467,
              21.016255183962564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.7_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5502",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016166032441646,
          "to_latitude": 21.016255183962564,
          "to_longitude": 105.83661731771467,
          "from_longitude": 105.83675021935417
        }
      },
      {
        "id": 5495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611800109301,
            21.015112365172907,
            105.8362787953172,
            21.0152426846507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611800109301,
              21.0152426846507
            ],
            [
              105.83616011739689,
              21.015204367917864
            ],
            [
              105.83617138427635,
              21.015193797205264
            ],
            [
              105.83618938339389,
              21.015180923600646
            ],
            [
              105.83622552294655,
              21.015157923299768
            ],
            [
              105.83624298455966,
              21.015146125362122
            ],
            [
              105.8362787953172,
              21.015119538156114
            ],
            [
              105.83627315636305,
              21.015112963304173
            ],
            [
              105.8362726451277,
              21.015112365172907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.1_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5503",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0152426846507,
          "to_latitude": 21.015112365172907,
          "to_longitude": 105.8362726451277,
          "from_longitude": 105.83611800109301
        }
      },
      {
        "id": 5496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954175162961,
            21.0150002319978,
            105.8397804282319,
            21.015504567129344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954465444958,
              21.015504567129344
            ],
            [
              105.8395463463589,
              21.0155016214966
            ],
            [
              105.83954901710989,
              21.015497571810187
            ],
            [
              105.83954175162961,
              21.01548949671427
            ],
            [
              105.83957565318406,
              21.015444829389068
            ],
            [
              105.83962518557009,
              21.01538631075501
            ],
            [
              105.839661532484,
              21.01534493895054
            ],
            [
              105.83967290789059,
              21.015333522059883
            ],
            [
              105.83971476711,
              21.015284670539696
            ],
            [
              105.83973625869999,
              21.01524980299382
            ],
            [
              105.83974874291067,
              21.01517097936063
            ],
            [
              105.83975093286868,
              21.015153044979364
            ],
            [
              105.8397804282319,
              21.0150002319978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.18_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5504",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015504567129344,
          "to_latitude": 21.0150002319978,
          "to_longitude": 105.8397804282319,
          "from_longitude": 105.83954465444958
        }
      },
      {
        "id": 5497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83382463543981,
            21.017689934191008,
            105.83390570331845,
            21.017784487300894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83382463543981,
              21.017784487300894
            ],
            [
              105.83383056684346,
              21.017777569334566
            ],
            [
              105.83390570331845,
              21.017689934191008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11.2_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5505",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017784487300894,
          "to_latitude": 21.017689934191008,
          "to_longitude": 105.83390570331845,
          "from_longitude": 105.83382463543981
        }
      },
      {
        "id": 5498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8408831686657,
            21.017002676559706,
            105.8409222949087,
            21.01714665010268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8408831686657,
              21.01714665010268
            ],
            [
              105.8409222949087,
              21.01705092999367
            ],
            [
              105.8409219170891,
              21.01704136220996
            ],
            [
              105.84091619017148,
              21.017002676559706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A2_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5506",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01714665010268,
          "to_latitude": 21.017002676559706,
          "to_longitude": 105.84091619017148,
          "from_longitude": 105.8408831686657
        }
      },
      {
        "id": 5499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720170582257,
            21.01451981409999,
            105.83733309627145,
            21.014578871439877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83733309627145,
              21.014531116259132
            ],
            [
              105.83724428433628,
              21.014578871439877
            ],
            [
              105.83720170582257,
              21.01451981409999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5507",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014531116259132,
          "to_latitude": 21.01451981409999,
          "to_longitude": 105.83720170582257,
          "from_longitude": 105.83733309627145
        }
      },
      {
        "id": 5500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666332136426,
            21.015352837170035,
            105.83687085558437,
            21.01549338789671
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667068568845,
              21.01549338789671
            ],
            [
              105.8366693240346,
              21.01549157098338
            ],
            [
              105.83666332136426,
              21.015483603355996
            ],
            [
              105.83666655112322,
              21.015481631088957
            ],
            [
              105.83668566509984,
              21.015469962270277
            ],
            [
              105.83679400561044,
              21.01540041496559
            ],
            [
              105.83687085558437,
              21.015352837170035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.8_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5508",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01549338789671,
          "to_latitude": 21.015352837170035,
          "to_longitude": 105.83687085558437,
          "from_longitude": 105.83667068568845
        }
      },
      {
        "id": 5501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84051126391836,
            21.015331927499197,
            105.8406123811309,
            21.015420738454708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406123811309,
              21.015331927499197
            ],
            [
              105.84060469002331,
              21.015348325589247
            ],
            [
              105.84060098847434,
              21.015356216375004
            ],
            [
              105.84058119541507,
              21.015394096011917
            ],
            [
              105.84054315881791,
              21.015420738454708
            ],
            [
              105.84051126391836,
              21.015420215000873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.6_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5509",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015331927499197,
          "to_latitude": 21.015420215000873,
          "to_longitude": 105.84051126391836,
          "from_longitude": 105.8406123811309
        }
      },
      {
        "id": 5502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807523045209,
            21.01629841620034,
            105.83808982560554,
            21.016321591477244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807523045209,
              21.01629841620034
            ],
            [
              105.83807929512184,
              21.016304870485687
            ],
            [
              105.83808982560554,
              21.016321591477244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.32_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5510",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629841620034,
          "to_latitude": 21.016321591477244,
          "to_longitude": 105.83808982560554,
          "from_longitude": 105.83807523045209
        }
      },
      {
        "id": 5503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752338046304,
            21.01540978629823,
            105.83761967586034,
            21.015599435472094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752338046304,
              21.01540978629823
            ],
            [
              105.83754258907624,
              21.015439370776722
            ],
            [
              105.83755660912017,
              21.0154624270024
            ],
            [
              105.8375591906145,
              21.015466672995156
            ],
            [
              105.83757929983877,
              21.01549974309297
            ],
            [
              105.83758175360586,
              21.015503854230516
            ],
            [
              105.8375907910421,
              21.015522985184326
            ],
            [
              105.83758220319406,
              21.01553475492569
            ],
            [
              105.83761744167253,
              21.01559588776915
            ],
            [
              105.83761967586034,
              21.015599435472094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.25_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5511",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01540978629823,
          "to_latitude": 21.015599435472094,
          "to_longitude": 105.83761967586034,
          "from_longitude": 105.83752338046304
        }
      },
      {
        "id": 5504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813053576057,
            21.0158253591361,
            105.83833625328518,
            21.015966256823962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83813053576057,
              21.015966256823962
            ],
            [
              105.83815908279932,
              21.015947249642675
            ],
            [
              105.83818495072009,
              21.01593063203848
            ],
            [
              105.83819115203134,
              21.015926446655495
            ],
            [
              105.83819706967647,
              21.015922452346917
            ],
            [
              105.83820519364401,
              21.015916843945245
            ],
            [
              105.83822461719681,
              21.015903436531165
            ],
            [
              105.83825132887506,
              21.015885641472103
            ],
            [
              105.83827756634399,
              21.0158669915861
            ],
            [
              105.83833625328518,
              21.0158253591361
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.23_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5512",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015966256823962,
          "to_latitude": 21.0158253591361,
          "to_longitude": 105.83833625328518,
          "from_longitude": 105.83813053576057
        }
      },
      {
        "id": 5505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83552479285326,
            21.01486662478019,
            105.83571579696648,
            21.015003688355243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83552479285326,
              21.015003688355243
            ],
            [
              105.8355300084066,
              21.01500143538995
            ],
            [
              105.8355456704158,
              21.014994669191907
            ],
            [
              105.83555267822999,
              21.014992621488066
            ],
            [
              105.83557039400152,
              21.014986898361137
            ],
            [
              105.8355983343667,
              21.014976724505587
            ],
            [
              105.8356190028309,
              21.014967469715614
            ],
            [
              105.83564639709616,
              21.01494957686854
            ],
            [
              105.83571579696648,
              21.01490923307371
            ],
            [
              105.83570100170627,
              21.01486662478019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.7_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5513",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015003688355243,
          "to_latitude": 21.01486662478019,
          "to_longitude": 105.83570100170627,
          "from_longitude": 105.83552479285326
        }
      },
      {
        "id": 5506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83270713837003,
            21.018317085710304,
            105.83271350914697,
            21.018347187001375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83270713837003,
              21.018317085710304
            ],
            [
              105.83271350914697,
              21.018347187001375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.2_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5514",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018317085710304,
          "to_latitude": 21.018347187001375,
          "to_longitude": 105.83271350914697,
          "from_longitude": 105.83270713837003
        }
      },
      {
        "id": 5507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84007873915718,
            21.01672317048255,
            105.84037703375621,
            21.016730909888143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84007873915718,
              21.016730712215402
            ],
            [
              105.84021818021542,
              21.016729984743904
            ],
            [
              105.84021836120814,
              21.016728293918824
            ],
            [
              105.8402189929391,
              21.016726701125382
            ],
            [
              105.84022003559487,
              21.01672531133415
            ],
            [
              105.84022141946657,
              21.016724216114977
            ],
            [
              105.84022305452869,
              21.016723486363897
            ],
            [
              105.84022324192019,
              21.01672345292233
            ],
            [
              105.84022483331492,
              21.01672317048255
            ],
            [
              105.84022663858781,
              21.016723289824085
            ],
            [
              105.84022835294302,
              21.016723835936133
            ],
            [
              105.84022986362902,
              21.016724772343416
            ],
            [
              105.84023107122941,
              21.01672603902098
            ],
            [
              105.84023189639149,
              21.016727551457738
            ],
            [
              105.84023228565829,
              21.01672921146637
            ],
            [
              105.84023221244587,
              21.016730909888143
            ],
            [
              105.84037703375621,
              21.01672882887791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.6_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5515",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016730712215402,
          "to_latitude": 21.01672882887791,
          "to_longitude": 105.84037703375621,
          "from_longitude": 105.84007873915718
        }
      },
      {
        "id": 5508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83930144388434,
            21.01629161243637,
            105.83935704562506,
            21.01640328727478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83930144388434,
              21.01629161243637
            ],
            [
              105.83931238546994,
              21.0163185151674
            ],
            [
              105.83933516591787,
              21.016364029122553
            ],
            [
              105.83934227147702,
              21.016377384357046
            ],
            [
              105.83935704562506,
              21.01640328727478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5516",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629161243637,
          "to_latitude": 21.01640328727478,
          "to_longitude": 105.83935704562506,
          "from_longitude": 105.83930144388434
        }
      },
      {
        "id": 5509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385312992179,
            21.01839247348222,
            105.83421122275217,
            21.018594639310656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421122275217,
              21.01839247348222
            ],
            [
              105.83414225638637,
              21.01843052219537
            ],
            [
              105.83413102725814,
              21.01843687995707
            ],
            [
              105.8341232470694,
              21.018442259775735
            ],
            [
              105.8340602273963,
              21.018485831417625
            ],
            [
              105.83403663416513,
              21.01849978449258
            ],
            [
              105.83397845152939,
              21.01853079563507
            ],
            [
              105.83392594639295,
              21.018560779959387
            ],
            [
              105.8339063387562,
              21.018570969613155
            ],
            [
              105.83388457966092,
              21.018582276261164
            ],
            [
              105.83385312992179,
              21.018594639310656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.3_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5517",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01839247348222,
          "to_latitude": 21.018594639310656,
          "to_longitude": 105.83385312992179,
          "from_longitude": 105.83421122275217
        }
      },
      {
        "id": 5510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8385067224375,
            21.014385138616866,
            105.83866709851237,
            21.014717224446287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8385067224375,
              21.014385138616866
            ],
            [
              105.8385454257736,
              21.014430768420844
            ],
            [
              105.83857923118983,
              21.014471271269816
            ],
            [
              105.83862895959959,
              21.01453296745342
            ],
            [
              105.83866709851237,
              21.014628002747987
            ],
            [
              105.83864386796341,
              21.014659497067072
            ],
            [
              105.83862451204419,
              21.014687375646552
            ],
            [
              105.83861066406314,
              21.014714210044673
            ],
            [
              105.83861406697775,
              21.014717064620378
            ],
            [
              105.83861425929538,
              21.014717224446287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.6_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5518",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014385138616866,
          "to_latitude": 21.014717224446287,
          "to_longitude": 105.83861425929538,
          "from_longitude": 105.8385067224375
        }
      },
      {
        "id": 5511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83496195826984,
            21.014889847181728,
            105.83513562380764,
            21.015067955940907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83513562380764,
              21.015067955940907
            ],
            [
              105.83511718846303,
              21.015043093952777
            ],
            [
              105.83510012893548,
              21.015022595912807
            ],
            [
              105.83508716229252,
              21.01500742848361
            ],
            [
              105.83507458504619,
              21.01499533733133
            ],
            [
              105.83505063156606,
              21.014970729129516
            ],
            [
              105.83503952508423,
              21.01495984107653
            ],
            [
              105.83502442917703,
              21.014947344992432
            ],
            [
              105.8350110957323,
              21.014935748900733
            ],
            [
              105.83498970638121,
              21.014920745640275
            ],
            [
              105.83496195826984,
              21.01489668533806
            ],
            [
              105.83496359834031,
              21.014894953033362
            ],
            [
              105.8349691039128,
              21.014889847181728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.4_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5519",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015067955940907,
          "to_latitude": 21.014889847181728,
          "to_longitude": 105.8349691039128,
          "from_longitude": 105.83513562380764
        }
      },
      {
        "id": 5512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854589652624,
            21.013592466682667,
            105.83857561253127,
            21.01361815695012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83854589652624,
              21.013592466682667
            ],
            [
              105.83857561253127,
              21.01361815695012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.18_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5520",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013592466682667,
          "to_latitude": 21.01361815695012,
          "to_longitude": 105.83857561253127,
          "from_longitude": 105.83854589652624
        }
      },
      {
        "id": 5513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044301058438,
            21.01561897324634,
            105.84079103231443,
            21.01566528519033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84044301058438,
              21.01561897324634
            ],
            [
              105.84044454262005,
              21.015665267627917
            ],
            [
              105.84046903789033,
              21.015664848554266
            ],
            [
              105.8405430045403,
              21.01566528519033
            ],
            [
              105.84055510166502,
              21.015658901954307
            ],
            [
              105.84079103231443,
              21.015661055472382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.6A_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5521",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01561897324634,
          "to_latitude": 21.015661055472382,
          "to_longitude": 105.84079103231443,
          "from_longitude": 105.84044301058438
        }
      },
      {
        "id": 5514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84045125141951,
            21.01576179540723,
            105.8404592836554,
            21.01592644137938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8404592836554,
              21.01592644137938
            ],
            [
              105.84045342381174,
              21.015926147877252
            ],
            [
              105.84045131565783,
              21.01592604990504
            ],
            [
              105.84045252075097,
              21.015907642578505
            ],
            [
              105.84045168230229,
              21.015785303284552
            ],
            [
              105.84045143901825,
              21.015771241295646
            ],
            [
              105.84045125141951,
              21.01576179540723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.6_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5522",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01592644137938,
          "to_latitude": 21.01576179540723,
          "to_longitude": 105.84045125141951,
          "from_longitude": 105.8404592836554
        }
      },
      {
        "id": 5515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573575393521,
            21.01512683685322,
            105.83584195676193,
            21.01520148711641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573575393521,
              21.01520148711641
            ],
            [
              105.83578752651725,
              21.015161670653665
            ],
            [
              105.83584195676193,
              21.01512683685322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.10_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5523",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01520148711641,
          "to_latitude": 21.01512683685322,
          "to_longitude": 105.83584195676193,
          "from_longitude": 105.83573575393521
        }
      },
      {
        "id": 5516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598079100985,
            21.01603233260803,
            105.83602356372316,
            21.016079119891753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598079100985,
              21.01603233260803
            ],
            [
              105.83598611305352,
              21.016037444899055
            ],
            [
              105.83599251939361,
              21.016045180244415
            ],
            [
              105.83602356372316,
              21.016079119891753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.19_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5524",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01603233260803,
          "to_latitude": 21.016079119891753,
          "to_longitude": 105.83602356372316,
          "from_longitude": 105.83598079100985
        }
      },
      {
        "id": 5517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355944999496,
            21.017687628953077,
            105.83564699085949,
            21.01769743387272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83564699085949,
              21.017687628953077
            ],
            [
              105.8355944999496,
              21.01769743387272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22.1_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5525",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017687628953077,
          "to_latitude": 21.01769743387272,
          "to_longitude": 105.8355944999496,
          "from_longitude": 105.83564699085949
        }
      },
      {
        "id": 5518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435763428582,
            21.016769704408798,
            105.83464206797836,
            21.016922910447423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435763428582,
              21.016792348209272
            ],
            [
              105.83436732549976,
              21.016790206100914
            ],
            [
              105.83437151586358,
              21.016789192905296
            ],
            [
              105.83438055208048,
              21.016807816430166
            ],
            [
              105.83441660777312,
              21.016800053404065
            ],
            [
              105.83442120709266,
              21.016799063491653
            ],
            [
              105.83448634206682,
              21.01678779150082
            ],
            [
              105.83450078934392,
              21.016789664371824
            ],
            [
              105.8345865096622,
              21.016769704408798
            ],
            [
              105.83459433923467,
              21.01679811124598
            ],
            [
              105.83463337609193,
              21.01689771700036
            ],
            [
              105.83464206797836,
              21.016922910447423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14.2_Ngách 107, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5526",
          "diaChiLapD": "Ngách 107, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016792348209272,
          "to_latitude": 21.016922910447423,
          "to_longitude": 105.83464206797836,
          "from_longitude": 105.83435763428582
        }
      },
      {
        "id": 5519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665648593674,
            21.016529538965944,
            105.83668671011195,
            21.016575573074793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665648593674,
              21.016529538965944
            ],
            [
              105.83666825836856,
              21.016546989165743
            ],
            [
              105.83667995514395,
              21.0165643268337
            ],
            [
              105.83668671011195,
              21.016575573074793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.11_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5527",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016529538965944,
          "to_latitude": 21.016575573074793,
          "to_longitude": 105.83668671011195,
          "from_longitude": 105.83665648593674
        }
      },
      {
        "id": 5520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010087035666,
            21.01602617203885,
            105.8401371901589,
            21.01610330243049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401371901589,
              21.01602617203885
            ],
            [
              105.84011843991661,
              21.016065990212272
            ],
            [
              105.84010087035666,
              21.01610330243049
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.7_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5528",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01602617203885,
          "to_latitude": 21.01610330243049,
          "to_longitude": 105.84010087035666,
          "from_longitude": 105.8401371901589
        }
      },
      {
        "id": 5521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208562527581,
            21.01796404050692,
            105.8321126684117,
            21.018021561966872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83208562527581,
              21.01796404050692
            ],
            [
              105.8321126684117,
              21.018021561966872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25.4_Ngách 298/24, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5529",
          "diaChiLapD": "Ngách 298/24, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01796404050692,
          "to_latitude": 21.018021561966872,
          "to_longitude": 105.8321126684117,
          "from_longitude": 105.83208562527581
        }
      },
      {
        "id": 5522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382488641639,
            21.014836917297107,
            105.8383413033912,
            21.014906015086392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382488641639,
              21.014906015086392
            ],
            [
              105.83828399802877,
              21.01487387868627
            ],
            [
              105.83829539871176,
              21.01486456175645
            ],
            [
              105.83831345724528,
              21.014849107129116
            ],
            [
              105.8383239744889,
              21.014841787062682
            ],
            [
              105.8383413033912,
              21.014836917297107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.2_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5530",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014906015086392,
          "to_latitude": 21.014836917297107,
          "to_longitude": 105.8383413033912,
          "from_longitude": 105.8382488641639
        }
      },
      {
        "id": 5523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355769684299,
            21.015200810275793,
            105.83571872009044,
            21.01536284423911
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83571872009044,
              21.015200810275793
            ],
            [
              105.83571612591508,
              21.015202392822925
            ],
            [
              105.83568095568835,
              21.01523036231363
            ],
            [
              105.8356568868668,
              21.015250687474477
            ],
            [
              105.83563897008425,
              21.015267817516555
            ],
            [
              105.83562036739568,
              21.015287184599778
            ],
            [
              105.83561072965459,
              21.01529769579166
            ],
            [
              105.83560197390221,
              21.015308015683658
            ],
            [
              105.83559698068733,
              21.015314956197408
            ],
            [
              105.8355825887502,
              21.015343542581583
            ],
            [
              105.8355769684299,
              21.015360857901015
            ],
            [
              105.83558391559686,
              21.0153622526817
            ],
            [
              105.83558686914606,
              21.01536284423911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.11_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5531",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015200810275793,
          "to_latitude": 21.01536284423911,
          "to_longitude": 105.83558686914606,
          "from_longitude": 105.83571872009044
        }
      },
      {
        "id": 5524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572224267404,
            21.01675133111734,
            105.83579033372567,
            21.016825709509902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572224267404,
              21.01675133111734
            ],
            [
              105.83574810708116,
              21.016778374848638
            ],
            [
              105.83576687870611,
              21.0167996538181
            ],
            [
              105.83579033372567,
              21.016825709509902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.10_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5532",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01675133111734,
          "to_latitude": 21.016825709509902,
          "to_longitude": 105.83579033372567,
          "from_longitude": 105.83572224267404
        }
      },
      {
        "id": 5525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83419296593806,
            21.01630359629405,
            105.83423133204431,
            21.016367424205527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83419296593806,
              21.01630359629405
            ],
            [
              105.83421335655453,
              21.01633859651902
            ],
            [
              105.83423133204431,
              21.016367424205527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15.1_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5533",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01630359629405,
          "to_latitude": 21.016367424205527,
          "to_longitude": 105.83423133204431,
          "from_longitude": 105.83419296593806
        }
      },
      {
        "id": 5526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932229363991,
            21.015782584112532,
            105.83936137826566,
            21.015847189438702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932229363991,
              21.015847189438702
            ],
            [
              105.83936137826566,
              21.015782584112532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.8_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5534",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015847189438702,
          "to_latitude": 21.015782584112532,
          "to_longitude": 105.83936137826566,
          "from_longitude": 105.83932229363991
        }
      },
      {
        "id": 5527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548010849083,
            21.016713896133645,
            105.83554680936399,
            21.016755737715602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548010849083,
              21.016755737715602
            ],
            [
              105.83551484630159,
              21.016737106110188
            ],
            [
              105.83554680936399,
              21.016713896133645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.13_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5535",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016755737715602,
          "to_latitude": 21.016713896133645,
          "to_longitude": 105.83554680936399,
          "from_longitude": 105.83548010849083
        }
      },
      {
        "id": 5528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691014025078,
            21.01494987297321,
            105.83697876492057,
            21.014989273204243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697876492057,
              21.01494987297321
            ],
            [
              105.83691014025078,
              21.014989273204243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5536",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01494987297321,
          "to_latitude": 21.014989273204243,
          "to_longitude": 105.83691014025078,
          "from_longitude": 105.83697876492057
        }
      },
      {
        "id": 5529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358677571963,
            21.01735075400882,
            105.83593090036483,
            21.017372776922826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358677571963,
              21.017372776922826
            ],
            [
              105.83593090036483,
              21.017366212734306
            ],
            [
              105.83592919943108,
              21.01735184989264
            ],
            [
              105.83592907115582,
              21.01735075400882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.6_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5537",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017372776922826,
          "to_latitude": 21.01735075400882,
          "to_longitude": 105.83592907115582,
          "from_longitude": 105.8358677571963
        }
      },
      {
        "id": 5530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774024205681,
            21.013381854838926,
            105.83781724682149,
            21.01341319472839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774024205681,
              21.01341319472839
            ],
            [
              105.83779125773256,
              21.013394792338378
            ],
            [
              105.83781724682149,
              21.013381854838926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.11_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5538",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01341319472839,
          "to_latitude": 21.013381854838926,
          "to_longitude": 105.83781724682149,
          "from_longitude": 105.83774024205681
        }
      },
      {
        "id": 5531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702769093047,
            21.01291824933701,
            105.8370827269444,
            21.0129755162647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702769093047,
              21.0129755162647
            ],
            [
              105.83703551320544,
              21.012968093923373
            ],
            [
              105.8370827269444,
              21.01291824933701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.6_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5539",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0129755162647,
          "to_latitude": 21.01291824933701,
          "to_longitude": 105.8370827269444,
          "from_longitude": 105.83702769093047
        }
      },
      {
        "id": 5532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786845679346,
            21.014323864623332,
            105.83802792388038,
            21.014534031941558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83802792388038,
              21.014323864623332
            ],
            [
              105.8379969910821,
              21.014344855266938
            ],
            [
              105.83795666343829,
              21.014378988926648
            ],
            [
              105.83794539781108,
              21.01439646579869
            ],
            [
              105.8379304722171,
              21.014413112568086
            ],
            [
              105.83791592675905,
              21.014428413466586
            ],
            [
              105.83788985176973,
              21.01445545073632
            ],
            [
              105.83786845679346,
              21.0144768478038
            ],
            [
              105.83793849200822,
              21.014534031941558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.2_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5540",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014323864623332,
          "to_latitude": 21.014534031941558,
          "to_longitude": 105.83793849200822,
          "from_longitude": 105.83802792388038
        }
      },
      {
        "id": 5533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397335915613,
            21.01876817170819,
            105.83400841411432,
            21.018844852494308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400841411432,
              21.018844852494308
            ],
            [
              105.83397335915613,
              21.01876817170819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5541",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018844852494308,
          "to_latitude": 21.01876817170819,
          "to_longitude": 105.83397335915613,
          "from_longitude": 105.83400841411432
        }
      },
      {
        "id": 5534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83847517706216,
            21.013828951410915,
            105.83853794318853,
            21.013884462231147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83847517706216,
              21.013828951410915
            ],
            [
              105.83853794318853,
              21.013884462231147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.19_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5542",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013828951410915,
          "to_latitude": 21.013884462231147,
          "to_longitude": 105.83853794318853,
          "from_longitude": 105.83847517706216
        }
      },
      {
        "id": 5535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515944221256,
            21.018535525607444,
            105.83517992675439,
            21.018617884679397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515944221256,
              21.018617884679397
            ],
            [
              105.83516069638989,
              21.018617500974244
            ],
            [
              105.83517992675439,
              21.018611611474288
            ],
            [
              105.83517092624662,
              21.018535525607444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.5_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5543",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018617884679397,
          "to_latitude": 21.018535525607444,
          "to_longitude": 105.83517092624662,
          "from_longitude": 105.83515944221256
        }
      },
      {
        "id": 5536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84006395944351,
            21.01744594852299,
            105.8401849358715,
            21.017446466559605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84006395944351,
              21.01744594852299
            ],
            [
              105.8401849358715,
              21.017446466559605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.1_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5544",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01744594852299,
          "to_latitude": 21.017446466559605,
          "to_longitude": 105.8401849358715,
          "from_longitude": 105.84006395944351
        }
      },
      {
        "id": 5537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562750070674,
            21.01550433168327,
            105.83574703290262,
            21.015644027022855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562750070674,
              21.01550433168327
            ],
            [
              105.83564308789018,
              21.01552055832344
            ],
            [
              105.83566180026713,
              21.015535378645787
            ],
            [
              105.8356650541023,
              21.01553807052993
            ],
            [
              105.83567299976174,
              21.015544644007015
            ],
            [
              105.8356772339111,
              21.0155490806213
            ],
            [
              105.83568283342608,
              21.015555139042338
            ],
            [
              105.83568907169716,
              21.015562929419616
            ],
            [
              105.83569921697898,
              21.01557531427508
            ],
            [
              105.83574703290262,
              21.015644027022855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.14_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5545",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01550433168327,
          "to_latitude": 21.015644027022855,
          "to_longitude": 105.83574703290262,
          "from_longitude": 105.83562750070674
        }
      },
      {
        "id": 5538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969246975816,
            21.016344072497017,
            105.83972628209658,
            21.016420681915204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969246975816,
              21.016420681915204
            ],
            [
              105.83972628209658,
              21.016344072497017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72A.1_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5546",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016420681915204,
          "to_latitude": 21.016344072497017,
          "to_longitude": 105.83972628209658,
          "from_longitude": 105.83969246975816
        }
      },
      {
        "id": 5539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359756718577,
            21.01477885387521,
            105.83604774284204,
            21.014827312849814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604774284204,
              21.01477885387521
            ],
            [
              105.8359756718577,
              21.014827312849814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.10_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5547",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01477885387521,
          "to_latitude": 21.014827312849814,
          "to_longitude": 105.8359756718577,
          "from_longitude": 105.83604774284204
        }
      },
      {
        "id": 5540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83980755373035,
            21.015604039633736,
            105.839833517718,
            21.01567028829144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980755373035,
              21.01567028829144
            ],
            [
              105.83983035474466,
              21.015621025014084
            ],
            [
              105.83983337762905,
              21.01560479000094
            ],
            [
              105.839833517718,
              21.015604039633736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.16_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5548",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01567028829144,
          "to_latitude": 21.015604039633736,
          "to_longitude": 105.839833517718,
          "from_longitude": 105.83980755373035
        }
      },
      {
        "id": 5541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83830385369144,
            21.012263920979525,
            105.83839300092593,
            21.012325006670732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83830385369144,
              21.012263920979525
            ],
            [
              105.83835927023117,
              21.012300701815512
            ],
            [
              105.83839300092593,
              21.012325006670732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.12_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5549",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012263920979525,
          "to_latitude": 21.012325006670732,
          "to_longitude": 105.83839300092593,
          "from_longitude": 105.83830385369144
        }
      },
      {
        "id": 5542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558454918852,
            21.01842695544074,
            105.83565920996301,
            21.018451708339903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83565920996301,
              21.01842695544074
            ],
            [
              105.83558454918852,
              21.018451708339903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5550",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01842695544074,
          "to_latitude": 21.018451708339903,
          "to_longitude": 105.83558454918852,
          "from_longitude": 105.83565920996301
        }
      },
      {
        "id": 5543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716678354115,
            21.012972769885632,
            105.83727372246919,
            21.01305299374952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716678354115,
              21.012972769885632
            ],
            [
              105.83719991380698,
              21.012998597154503
            ],
            [
              105.83727372246919,
              21.01305299374952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.7_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5551",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012972769885632,
          "to_latitude": 21.01305299374952,
          "to_longitude": 105.83727372246919,
          "from_longitude": 105.83716678354115
        }
      },
      {
        "id": 5544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83834427387798,
            21.013699355033513,
            105.83860913929297,
            21.013875993285428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83860913929297,
              21.013699355033513
            ],
            [
              105.83847517706216,
              21.013828951410915
            ],
            [
              105.83843630043597,
              21.013866560860592
            ],
            [
              105.83834427387798,
              21.013875993285428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.19_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5552",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013699355033513,
          "to_latitude": 21.013875993285428,
          "to_longitude": 105.83834427387798,
          "from_longitude": 105.83860913929297
        }
      },
      {
        "id": 5545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607579187311,
            21.016246451973984,
            105.83611696042442,
            21.01630571070554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83607579187311,
              21.016246451973984
            ],
            [
              105.83611696042442,
              21.01630571070554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.20_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5553",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016246451973984,
          "to_latitude": 21.01630571070554,
          "to_longitude": 105.83611696042442,
          "from_longitude": 105.83607579187311
        }
      },
      {
        "id": 5546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617737894072,
            21.014889903193737,
            105.83630636092873,
            21.015039048205594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617737894072,
              21.014889903193737
            ],
            [
              105.83624309311678,
              21.014953712750334
            ],
            [
              105.83630636092873,
              21.015039048205594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.8_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5554",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014889903193737,
          "to_latitude": 21.015039048205594,
          "to_longitude": 105.83630636092873,
          "from_longitude": 105.83617737894072
        }
      },
      {
        "id": 5547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838277598247,
            21.014951099925046,
            105.83837141320927,
            21.01498988237669
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838277598247,
              21.01498988237669
            ],
            [
              105.83837141320927,
              21.014951099925046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.13_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5555",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01498988237669,
          "to_latitude": 21.014951099925046,
          "to_longitude": 105.83837141320927,
          "from_longitude": 105.838277598247
        }
      },
      {
        "id": 5548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83517992675439,
            21.018611611474288,
            105.83518583411096,
            21.018702467596125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518583411096,
              21.018702467596125
            ],
            [
              105.83518240561524,
              21.018664969013468
            ],
            [
              105.83517992675439,
              21.018611611474288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.5_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5556",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018702467596125,
          "to_latitude": 21.018611611474288,
          "to_longitude": 105.83517992675439,
          "from_longitude": 105.83518583411096
        }
      },
      {
        "id": 5549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83703116062651,
            21.015851510034803,
            105.83711758330111,
            21.015902828326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83703116062651,
              21.015902828326
            ],
            [
              105.83710589924358,
              21.015858684029126
            ],
            [
              105.83710851502434,
              21.01585700922585
            ],
            [
              105.83711758330111,
              21.015851510034803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.4_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5557",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015902828326,
          "to_latitude": 21.015851510034803,
          "to_longitude": 105.83711758330111,
          "from_longitude": 105.83703116062651
        }
      },
      {
        "id": 5550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859390197253,
            21.013630101364164,
            105.83907078501424,
            21.014018602357655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859390197253,
              21.013630101364164
            ],
            [
              105.83864161982895,
              21.01371040756098
            ],
            [
              105.83876700304226,
              21.01380800968638
            ],
            [
              105.83879643775754,
              21.013815033464503
            ],
            [
              105.83882780146517,
              21.01384138304409
            ],
            [
              105.83885770845865,
              21.01386938637708
            ],
            [
              105.83891846143261,
              21.013926273555644
            ],
            [
              105.8390151620337,
              21.014015055122123
            ],
            [
              105.83907078501424,
              21.014018602357655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.20_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5558",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013630101364164,
          "to_latitude": 21.014018602357655,
          "to_longitude": 105.83907078501424,
          "from_longitude": 105.83859390197253
        }
      },
      {
        "id": 5551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726946328113,
            21.013397550547253,
            105.83733321562099,
            21.013474240299868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83733321562099,
              21.013474240299868
            ],
            [
              105.83726946328113,
              21.013397550547253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.10_Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5559",
          "diaChiLapD": "Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013474240299868,
          "to_latitude": 21.013397550547253,
          "to_longitude": 105.83726946328113,
          "from_longitude": 105.83733321562099
        }
      },
      {
        "id": 5552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378319742513,
            21.014556852607967,
            105.83789840238445,
            21.0146250810841
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378319742513,
              21.014556852607967
            ],
            [
              105.83786429028389,
              21.014586649796286
            ],
            [
              105.83789840238445,
              21.0146250810841
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.8_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5560",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014556852607967,
          "to_latitude": 21.0146250810841,
          "to_longitude": 105.83789840238445,
          "from_longitude": 105.8378319742513
        }
      },
      {
        "id": 5553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695332048848,
            21.013880734123518,
            105.83701068623022,
            21.013950105872006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701068623022,
              21.013950105872006
            ],
            [
              105.83695332048848,
              21.013880734123518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.14_Ngõ 247, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5561",
          "diaChiLapD": "Ngõ 247, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013950105872006,
          "to_latitude": 21.013880734123518,
          "to_longitude": 105.83695332048848,
          "from_longitude": 105.83701068623022
        }
      },
      {
        "id": 5554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572629202402,
            21.015075737915684,
            105.83579152551557,
            21.015137717367406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572629202402,
              21.015075737915684
            ],
            [
              105.83574893810169,
              21.01509529257754
            ],
            [
              105.83579152551557,
              21.015137717367406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.8_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5562",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015075737915684,
          "to_latitude": 21.015137717367406,
          "to_longitude": 105.83579152551557,
          "from_longitude": 105.83572629202402
        }
      },
      {
        "id": 5555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870212028448,
            21.01557040027592,
            105.83877301708895,
            21.01563701396489
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83877301708895,
              21.01563701396489
            ],
            [
              105.83870212028448,
              21.01557040027592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.16_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5563",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01563701396489,
          "to_latitude": 21.01557040027592,
          "to_longitude": 105.83870212028448,
          "from_longitude": 105.83877301708895
        }
      },
      {
        "id": 5556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706543105406,
            21.015171926541985,
            105.83714739092002,
            21.015229106485776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706543105406,
              21.015229106485776
            ],
            [
              105.83708123016001,
              21.01521657870937
            ],
            [
              105.83714739092002,
              21.015171926541985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.3_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5564",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015229106485776,
          "to_latitude": 21.015171926541985,
          "to_longitude": 105.83714739092002,
          "from_longitude": 105.83706543105406
        }
      },
      {
        "id": 5557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695510474516,
            21.015902828326,
            105.83703696819308,
            21.015959498661307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83703696819308,
              21.015911164511135
            ],
            [
              105.83703354708423,
              21.015906256062017
            ],
            [
              105.83703116062651,
              21.015902828326
            ],
            [
              105.83699321651382,
              21.015935496754732
            ],
            [
              105.83697358965931,
              21.015949555352964
            ],
            [
              105.83695510474516,
              21.015959498661307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.4_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5565",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015911164511135,
          "to_latitude": 21.015959498661307,
          "to_longitude": 105.83695510474516,
          "from_longitude": 105.83703696819308
        }
      },
      {
        "id": 5558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83731803920479,
            21.01348627367789,
            105.83737029124627,
            21.013541320045622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83731803920479,
              21.01348627367789
            ],
            [
              105.83737029124627,
              21.013541320045622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.10_Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5566",
          "diaChiLapD": "Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01348627367789,
          "to_latitude": 21.013541320045622,
          "to_longitude": 105.83737029124627,
          "from_longitude": 105.83731803920479
        }
      },
      {
        "id": 5559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84007062894105,
            21.01534841440321,
            105.84014164254191,
            21.01554150306174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84007062894105,
              21.01554150306174
            ],
            [
              105.84010807559697,
              21.015442141486268
            ],
            [
              105.84014164254191,
              21.01534841440321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.13_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5567",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01554150306174,
          "to_latitude": 21.01534841440321,
          "to_longitude": 105.84014164254191,
          "from_longitude": 105.84007062894105
        }
      },
      {
        "id": 5560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608041237586,
            21.01578082231924,
            105.83616353192423,
            21.01583975795307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83616353192423,
              21.01578082231924
            ],
            [
              105.83608041237586,
              21.01583975795307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.12_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5568",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01578082231924,
          "to_latitude": 21.01583975795307,
          "to_longitude": 105.83608041237586,
          "from_longitude": 105.83616353192423
        }
      },
      {
        "id": 5561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363954149598,
            21.01374485889546,
            105.83648629967581,
            21.01384920567454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363954149598,
              21.01374485889546
            ],
            [
              105.83642947387814,
              21.013796280933388
            ],
            [
              105.83646523803489,
              21.01384920567454
            ],
            [
              105.83647658011638,
              21.013845760111497
            ],
            [
              105.83648629967581,
              21.013841668590274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.9_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5569",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01374485889546,
          "to_latitude": 21.013841668590274,
          "to_longitude": 105.83648629967581,
          "from_longitude": 105.8363954149598
        }
      },
      {
        "id": 5562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84000008663735,
            21.016514644116832,
            105.84003023992341,
            21.01660229300834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003023992341,
              21.016514644116832
            ],
            [
              105.84002767780173,
              21.016514903348053
            ],
            [
              105.84000008663735,
              21.01651768693293
            ],
            [
              105.84000040752254,
              21.01660229300834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5570",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016514644116832,
          "to_latitude": 21.01660229300834,
          "to_longitude": 105.84000040752254,
          "from_longitude": 105.84003023992341
        }
      },
      {
        "id": 5563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83576063421867,
            21.017435495946014,
            105.83586535941612,
            21.017459173663024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586535941612,
              21.017435495946014
            ],
            [
              105.83576063421867,
              21.017459173663024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.5_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5571",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017435495946014,
          "to_latitude": 21.017459173663024,
          "to_longitude": 105.83576063421867,
          "from_longitude": 105.83586535941612
        }
      },
      {
        "id": 5564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593583868827,
            21.01589305230386,
            105.83598079100985,
            21.01603233260803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593583868827,
              21.01589305230386
            ],
            [
              105.83595018722782,
              21.01591018822355
            ],
            [
              105.83596702683481,
              21.015953322051864
            ],
            [
              105.8359575624783,
              21.01596594956893
            ],
            [
              105.83595238669496,
              21.01597965794639
            ],
            [
              105.83595714925691,
              21.01599191297571
            ],
            [
              105.83596142386135,
              21.016002914017573
            ],
            [
              105.83597200874725,
              21.016018395657113
            ],
            [
              105.83598079100985,
              21.01603233260803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.19_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5572",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01589305230386,
          "to_latitude": 21.01603233260803,
          "to_longitude": 105.83598079100985,
          "from_longitude": 105.83593583868827
        }
      },
      {
        "id": 5565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84035898097082,
            21.017414580163734,
            105.84051129400974,
            21.01743765292506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84037181496035,
              21.01743765292506
            ],
            [
              105.84036656014209,
              21.017436847918102
            ],
            [
              105.84035898097082,
              21.017435476330075
            ],
            [
              105.84036384068425,
              21.017417098824062
            ],
            [
              105.84036657878582,
              21.017416998588587
            ],
            [
              105.84042277482689,
              21.017414948850142
            ],
            [
              105.84051129400974,
              21.017414580163734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.4_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5573",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01743765292506,
          "to_latitude": 21.017414580163734,
          "to_longitude": 105.84051129400974,
          "from_longitude": 105.84037181496035
        }
      },
      {
        "id": 5566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83652239334397,
            21.016140468638522,
            105.83667122239525,
            21.016231808975057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667122239525,
              21.016231808975057
            ],
            [
              105.8366666291602,
              21.016226640735212
            ],
            [
              105.8366640000271,
              21.01622368205767
            ],
            [
              105.83659003886328,
              21.016140468638522
            ],
            [
              105.83652239334397,
              21.016196005430764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.6_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5574",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016231808975057,
          "to_latitude": 21.016196005430764,
          "to_longitude": 105.83652239334397,
          "from_longitude": 105.83667122239525
        }
      },
      {
        "id": 5567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767747886706,
            21.016081644426286,
            105.83787092026363,
            21.016143772378644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767747886706,
              21.016143772378644
            ],
            [
              105.83768216040413,
              21.016142354749388
            ],
            [
              105.83771295237173,
              21.016132589213502
            ],
            [
              105.83771775781162,
              21.01613096594131
            ],
            [
              105.83774942091118,
              21.016120271208028
            ],
            [
              105.83787092026363,
              21.016081644426286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.2_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5575",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016143772378644,
          "to_latitude": 21.016081644426286,
          "to_longitude": 105.83787092026363,
          "from_longitude": 105.83767747886706
        }
      },
      {
        "id": 5568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414978243609,
            21.01641411433778,
            105.83421605498013,
            21.016476991612887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421605498013,
              21.016476991612887
            ],
            [
              105.83414978243609,
              21.01641411433778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5576",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016476991612887,
          "to_latitude": 21.01641411433778,
          "to_longitude": 105.83414978243609,
          "from_longitude": 105.83421605498013
        }
      },
      {
        "id": 5569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83576970945548,
            21.015662129143642,
            105.83581979177784,
            21.0157517322495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83576970945548,
              21.015662129143642
            ],
            [
              105.83577860532291,
              21.015677644384795
            ],
            [
              105.83578492937453,
              21.0156896063301
            ],
            [
              105.83578798105626,
              21.015695378296684
            ],
            [
              105.83578963292233,
              21.01570232320276
            ],
            [
              105.83579658214468,
              21.015716340524595
            ],
            [
              105.83580722020218,
              21.015729805014765
            ],
            [
              105.83581386281502,
              21.01574093171768
            ],
            [
              105.83581979177784,
              21.0157517322495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.16_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5577",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015662129143642,
          "to_latitude": 21.0157517322495,
          "to_longitude": 105.83581979177784,
          "from_longitude": 105.83576970945548
        }
      },
      {
        "id": 5570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691953573519,
            21.015844176609562,
            105.83714441313616,
            21.016078040263285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83714441313616,
              21.015844176609562
            ],
            [
              105.83709103887836,
              21.01588016626992
            ],
            [
              105.83704571484847,
              21.015914050632553
            ],
            [
              105.83698945658188,
              21.01595313083321
            ],
            [
              105.83697062746438,
              21.015966552346324
            ],
            [
              105.83698384740727,
              21.01600189550015
            ],
            [
              105.8369928987344,
              21.01602766059879
            ],
            [
              105.8369376192766,
              21.016064257520796
            ],
            [
              105.83691953573519,
              21.016078040263285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.5_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5578",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015844176609562,
          "to_latitude": 21.016078040263285,
          "to_longitude": 105.83691953573519,
          "from_longitude": 105.83714441313616
        }
      },
      {
        "id": 5571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569813129827,
            21.01555189538658,
            105.83576233570271,
            21.015638213663568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569813129827,
              21.01555189538658
            ],
            [
              105.8357323859593,
              21.015594215698098
            ],
            [
              105.83575287859573,
              21.01562192982484
            ],
            [
              105.83576053274966,
              21.01563470988101
            ],
            [
              105.83576233570271,
              21.015638213663568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.15_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5579",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01555189538658,
          "to_latitude": 21.015638213663568,
          "to_longitude": 105.83576233570271,
          "from_longitude": 105.83569813129827
        }
      },
      {
        "id": 5572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598704061131,
            21.015175783723866,
            105.83617736231682,
            21.015332966908325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598704061131,
              21.015332966908325
            ],
            [
              105.83610296170545,
              21.015236552507226
            ],
            [
              105.8361063063027,
              21.015233955558305
            ],
            [
              105.83610729830772,
              21.01523313778118
            ],
            [
              105.83615106129682,
              21.015194449861756
            ],
            [
              105.8361534811661,
              21.015192487907346
            ],
            [
              105.83615628024886,
              21.015190218799326
            ],
            [
              105.83617736231682,
              21.015175783723866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.3_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5580",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015332966908325,
          "to_latitude": 21.015175783723866,
          "to_longitude": 105.83617736231682,
          "from_longitude": 105.83598704061131
        }
      },
      {
        "id": 5573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83579970147393,
            21.0177027898754,
            105.83590908927094,
            21.017732535811106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590908927094,
              21.0177027898754
            ],
            [
              105.83579970147393,
              21.017732535811106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5581",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0177027898754,
          "to_latitude": 21.017732535811106,
          "to_longitude": 105.83579970147393,
          "from_longitude": 105.83590908927094
        }
      },
      {
        "id": 5574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594042300767,
            21.01499191460903,
            105.83602473975476,
            21.0150462081619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602473975476,
              21.015001418439038
            ],
            [
              105.83602453744788,
              21.01500119182062
            ],
            [
              105.8360163054692,
              21.01499191460903
            ],
            [
              105.83600080599942,
              21.015002814109213
            ],
            [
              105.83594042300767,
              21.0150462081619
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.7_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5582",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015001418439038,
          "to_latitude": 21.0150462081619,
          "to_longitude": 105.83594042300767,
          "from_longitude": 105.83602473975476
        }
      },
      {
        "id": 5575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84124204886798,
            21.018233217321974,
            105.84133136046573,
            21.01823439694597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84133136046573,
              21.018233217321974
            ],
            [
              105.8412994539225,
              21.018233639765622
            ],
            [
              105.84124204886798,
              21.01823439694597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.1A2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5583",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018233217321974,
          "to_latitude": 21.01823439694597,
          "to_longitude": 105.84124204886798,
          "from_longitude": 105.84133136046573
        }
      },
      {
        "id": 5576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608939320777,
            21.014796185015815,
            105.83616496205444,
            21.01484222700474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83616496205444,
              21.01484222700474
            ],
            [
              105.83614034668626,
              21.01483281715742
            ],
            [
              105.83611106827942,
              21.014820200292174
            ],
            [
              105.83610646675794,
              21.01481784832899
            ],
            [
              105.83609466873446,
              21.014809920901772
            ],
            [
              105.83608939320777,
              21.014806416123154
            ],
            [
              105.83609844802851,
              21.014796781188092
            ],
            [
              105.83609900839704,
              21.014796185015815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.10_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5584",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484222700474,
          "to_latitude": 21.014796185015815,
          "to_longitude": 105.83609900839704,
          "from_longitude": 105.83616496205444
        }
      },
      {
        "id": 5577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379277087336,
            21.014314074514367,
            105.83801950137847,
            21.01438627332485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379277087336,
              21.01438627332485
            ],
            [
              105.83793095094651,
              21.01438429916692
            ],
            [
              105.83796651890606,
              21.014354801721424
            ],
            [
              105.83800617394483,
              21.01432390220513
            ],
            [
              105.83801083077564,
              21.014320139016878
            ],
            [
              105.83801950137847,
              21.014314074514367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.1_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5585",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01438627332485,
          "to_latitude": 21.014314074514367,
          "to_longitude": 105.83801950137847,
          "from_longitude": 105.8379277087336
        }
      },
      {
        "id": 5578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509213862642,
            21.018045213174915,
            105.8355409191393,
            21.018333844155283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355409191393,
              21.018333844155283
            ],
            [
              105.83552416875429,
              21.01829984958757
            ],
            [
              105.83549792024849,
              21.018242234485424
            ],
            [
              105.83540639759302,
              21.01805235476601
            ],
            [
              105.83532513115087,
              21.018084174297936
            ],
            [
              105.83532366122655,
              21.018079500104857
            ],
            [
              105.835298018932,
              21.018045213174915
            ],
            [
              105.8351147834728,
              21.018136262717217
            ],
            [
              105.83509213862642,
              21.01810006968876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.2_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5586",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018333844155283,
          "to_latitude": 21.01810006968876,
          "to_longitude": 105.83509213862642,
          "from_longitude": 105.8355409191393
        }
      },
      {
        "id": 5579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767398157934,
            21.014370190778536,
            105.83777200011473,
            21.01452830166995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767398157934,
              21.014370190778536
            ],
            [
              105.83768158293954,
              21.01438039049572
            ],
            [
              105.83768279076672,
              21.014385839119694
            ],
            [
              105.83768628298179,
              21.01440158860666
            ],
            [
              105.83769376981046,
              21.014412121273576
            ],
            [
              105.83769628771716,
              21.01441566306339
            ],
            [
              105.83771871649017,
              21.014453810496114
            ],
            [
              105.83777200011473,
              21.01452830166995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5587",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014370190778536,
          "to_latitude": 21.01452830166995,
          "to_longitude": 105.83777200011473,
          "from_longitude": 105.83767398157934
        }
      },
      {
        "id": 5580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83578203676852,
            21.016825709509902,
            105.8359075628369,
            21.016983442544873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578203676852,
              21.01683102690807
            ],
            [
              105.83578819750787,
              21.0168271371638
            ],
            [
              105.83579033372567,
              21.016825709509902
            ],
            [
              105.83580965256434,
              21.01685403996714
            ],
            [
              105.8358242957331,
              21.016871555606443
            ],
            [
              105.83583967089429,
              21.016895621443
            ],
            [
              105.83586673334057,
              21.016928945708862
            ],
            [
              105.8359075628369,
              21.016983442544873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.10_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5588",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01683102690807,
          "to_latitude": 21.016983442544873,
          "to_longitude": 105.8359075628369,
          "from_longitude": 105.83578203676852
        }
      },
      {
        "id": 5581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569901934175,
            21.01737550048674,
            105.83584268670567,
            21.017400100204785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584268670567,
              21.01737550048674
            ],
            [
              105.83580443783815,
              21.017381040918394
            ],
            [
              105.83573438127827,
              21.017394017593144
            ],
            [
              105.83569901934175,
              21.017400100204785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5589",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01737550048674,
          "to_latitude": 21.017400100204785,
          "to_longitude": 105.83569901934175,
          "from_longitude": 105.83584268670567
        }
      },
      {
        "id": 5582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405626096467,
            21.018701298779543,
            105.8341015177375,
            21.01880361993533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8341015177375,
              21.01880361993533
            ],
            [
              105.83405626096467,
              21.018701298779543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5590",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01880361993533,
          "to_latitude": 21.018701298779543,
          "to_longitude": 105.83405626096467,
          "from_longitude": 105.8341015177375
        }
      },
      {
        "id": 5583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83777923714096,
            21.015811127838962,
            105.83783740050431,
            21.01590288535298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783740050431,
              21.01590288535298
            ],
            [
              105.83782878433684,
              21.01588909220031
            ],
            [
              105.83780620979519,
              21.015853825894524
            ],
            [
              105.83780506957088,
              21.01585204493258
            ],
            [
              105.83780180803375,
              21.01584694874637
            ],
            [
              105.83779183518361,
              21.015831369372968
            ],
            [
              105.83777923714096,
              21.015811127838962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.30_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5591",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01590288535298,
          "to_latitude": 21.015811127838962,
          "to_longitude": 105.83777923714096,
          "from_longitude": 105.83783740050431
        }
      },
      {
        "id": 5584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399573079529,
            21.01474913430305,
            105.8400119942115,
            21.01488938501103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8399573079529,
              21.01488938501103
            ],
            [
              105.84000216945485,
              21.01478596479121
            ],
            [
              105.8400119942115,
              21.01474913430305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.8_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5592",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488938501103,
          "to_latitude": 21.01474913430305,
          "to_longitude": 105.8400119942115,
          "from_longitude": 105.8399573079529
        }
      },
      {
        "id": 5585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83631223722213,
            21.01501350032346,
            105.83640194037761,
            21.015085258986936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640194037761,
              21.01501350032346
            ],
            [
              105.83634022208904,
              21.01506116249183
            ],
            [
              105.83631223722213,
              21.015085258986936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.1_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5593",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01501350032346,
          "to_latitude": 21.015085258986936,
          "to_longitude": 105.83631223722213,
          "from_longitude": 105.83640194037761
        }
      },
      {
        "id": 5586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84111520355242,
            21.014589857922036,
            105.8412649390025,
            21.01459488675517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84111520355242,
              21.01459488675517
            ],
            [
              105.8411448310467,
              21.014593701573144
            ],
            [
              105.84119287761946,
              21.014592699898294
            ],
            [
              105.8412649390025,
              21.014589857922036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.1_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5594",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01459488675517,
          "to_latitude": 21.014589857922036,
          "to_longitude": 105.8412649390025,
          "from_longitude": 105.84111520355242
        }
      },
      {
        "id": 5587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598990061046,
            21.014698529840253,
            105.83608939320777,
            21.014806416123154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608939320777,
              21.014806416123154
            ],
            [
              105.83606050815258,
              21.014787397970878
            ],
            [
              105.83605446103091,
              21.01478300730678
            ],
            [
              105.83604774284204,
              21.01477885387521
            ],
            [
              105.83603847593243,
              21.01476683233471
            ],
            [
              105.8360154285968,
              21.014736377763224
            ],
            [
              105.83600181730903,
              21.014718950995665
            ],
            [
              105.83598990061046,
              21.014698529840253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.10_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5595",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014806416123154,
          "to_latitude": 21.014698529840253,
          "to_longitude": 105.83598990061046,
          "from_longitude": 105.83608939320777
        }
      },
      {
        "id": 5588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794908215187,
            21.015952882473925,
            105.83813004931751,
            21.016058661700054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794908215187,
              21.016058661700054
            ],
            [
              105.83795904736778,
              21.0160549617852
            ],
            [
              105.83811276176769,
              21.015964144133356
            ],
            [
              105.83813004931751,
              21.015952882473925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.22_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5596",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016058661700054,
          "to_latitude": 21.015952882473925,
          "to_longitude": 105.83813004931751,
          "from_longitude": 105.83794908215187
        }
      },
      {
        "id": 5589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83557176765095,
            21.015383033216896,
            105.83561010960781,
            21.01548943441579
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557176765095,
              21.015383033216896
            ],
            [
              105.83557334007767,
              21.015396614505757
            ],
            [
              105.83557728187027,
              21.015410562608757
            ],
            [
              105.83557784066487,
              21.015416072243017
            ],
            [
              105.8355819401156,
              21.015432937889656
            ],
            [
              105.8355862617735,
              21.015446867868604
            ],
            [
              105.83559111187053,
              21.01545930402845
            ],
            [
              105.83559749083179,
              21.01547232067818
            ],
            [
              105.83561010960781,
              21.01548943441579
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.13_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5597",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015383033216896,
          "to_latitude": 21.01548943441579,
          "to_longitude": 105.83561010960781,
          "from_longitude": 105.83557176765095
        }
      },
      {
        "id": 5590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819936434709,
            21.014776600250894,
            105.83826820168092,
            21.01487069497546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826820168092,
              21.01487069497546
            ],
            [
              105.83819936434709,
              21.014776600250894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.1_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5598",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01487069497546,
          "to_latitude": 21.014776600250894,
          "to_longitude": 105.83819936434709,
          "from_longitude": 105.83826820168092
        }
      },
      {
        "id": 5591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83577536684255,
            21.015593333524315,
            105.83586415283278,
            21.01564404499272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577536684255,
              21.01564404499272
            ],
            [
              105.835860507182,
              21.01559559323866
            ],
            [
              105.83586415283278,
              21.015593333524315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.16_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5599",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01564404499272,
          "to_latitude": 21.015593333524315,
          "to_longitude": 105.83586415283278,
          "from_longitude": 105.83577536684255
        }
      },
      {
        "id": 5592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572679740502,
            21.01578862308262,
            105.83583183591088,
            21.015866185945708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572679740502,
              21.015866185945708
            ],
            [
              105.83579477100648,
              21.01581373868559
            ],
            [
              105.83583183591088,
              21.01578862308262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.18_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5600",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015866185945708,
          "to_latitude": 21.01578862308262,
          "to_longitude": 105.83583183591088,
          "from_longitude": 105.83572679740502
        }
      },
      {
        "id": 5593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633478643623,
            21.014909994003485,
            105.83651298962401,
            21.015048091556263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633478643623,
              21.015048091556263
            ],
            [
              105.83636925141245,
              21.015019606950688
            ],
            [
              105.83640777914842,
              21.014991217977848
            ],
            [
              105.83647245290356,
              21.01494225867672
            ],
            [
              105.83647498063388,
              21.01494014805882
            ],
            [
              105.83651298962401,
              21.014909994003485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.2_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5601",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015048091556263,
          "to_latitude": 21.014909994003485,
          "to_longitude": 105.83651298962401,
          "from_longitude": 105.83633478643623
        }
      },
      {
        "id": 5594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84116738324153,
            21.01784891732567,
            105.84132318571093,
            21.017856068166523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84132318571093,
              21.01784891732567
            ],
            [
              105.84130327367652,
              21.017849832316085
            ],
            [
              105.84129220929124,
              21.017850339549746
            ],
            [
              105.84116738324153,
              21.017856068166523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.1A_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5602",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01784891732567,
          "to_latitude": 21.017856068166523,
          "to_longitude": 105.84116738324153,
          "from_longitude": 105.84132318571093
        }
      },
      {
        "id": 5595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586514867552,
            21.01552616059288,
            105.83598752768755,
            21.01559510256426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586514867552,
              21.01559510256426
            ],
            [
              105.83590839654356,
              21.015568552957433
            ],
            [
              105.83593631572393,
              21.015552664448183
            ],
            [
              105.83594736344727,
              21.0155486748159
            ],
            [
              105.83598752768755,
              21.01552616059288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.16_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5603",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01559510256426,
          "to_latitude": 21.01552616059288,
          "to_longitude": 105.83598752768755,
          "from_longitude": 105.83586514867552
        }
      },
      {
        "id": 5596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356121928601,
            21.01656240163659,
            105.83571089429509,
            21.016629846099825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356121928601,
              21.016629846099825
            ],
            [
              105.83571089429509,
              21.01656240163659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.12_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5604",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016629846099825,
          "to_latitude": 21.01656240163659,
          "to_longitude": 105.83571089429509,
          "from_longitude": 105.8356121928601
        }
      },
      {
        "id": 5597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593090036483,
            21.01735738434648,
            105.83602593283793,
            21.017382390347215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602593283793,
              21.017382390347215
            ],
            [
              105.83602395997752,
              21.01735738434648
            ],
            [
              105.83594750550019,
              21.017364486367153
            ],
            [
              105.83593090036483,
              21.017366212734306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.6_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5605",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017382390347215,
          "to_latitude": 21.017366212734306,
          "to_longitude": 105.83593090036483,
          "from_longitude": 105.83602593283793
        }
      },
      {
        "id": 5598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83796744635822,
            21.01530945713728,
            105.838032983007,
            21.015394010289306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83796744635822,
              21.01530945713728
            ],
            [
              105.838032983007,
              21.015394010289306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5606",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01530945713728,
          "to_latitude": 21.015394010289306,
          "to_longitude": 105.838032983007,
          "from_longitude": 105.83796744635822
        }
      },
      {
        "id": 5599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335571467177,
            21.01687405801563,
            105.83364477975198,
            21.016956933014416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364477975198,
              21.016956933014416
            ],
            [
              105.8335571467177,
              21.01687405801563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.10_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5607",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016956933014416,
          "to_latitude": 21.01687405801563,
          "to_longitude": 105.8335571467177,
          "from_longitude": 105.83364477975198
        }
      },
      {
        "id": 5600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83781260590881,
            21.015452423317434,
            105.83788970326127,
            21.015545102801827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83781260590881,
              21.015452423317434
            ],
            [
              105.83788970326127,
              21.015545102801827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5608",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015452423317434,
          "to_latitude": 21.015545102801827,
          "to_longitude": 105.83788970326127,
          "from_longitude": 105.83781260590881
        }
      },
      {
        "id": 5601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84011456211466,
            21.016019071582182,
            105.84015223165329,
            21.01611455220981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84011456211466,
              21.01611455220981
            ],
            [
              105.84013464412502,
              21.01606364942293
            ],
            [
              105.84015223165329,
              21.016019071582182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.8_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5609",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01611455220981,
          "to_latitude": 21.016019071582182,
          "to_longitude": 105.84015223165329,
          "from_longitude": 105.84011456211466
        }
      },
      {
        "id": 5602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84005194878232,
            21.016130200636372,
            105.84010503518456,
            21.016254055375615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84005194878232,
              21.016254055375615
            ],
            [
              105.84007167021166,
              21.016205061081322
            ],
            [
              105.8400841204464,
              21.016171200975737
            ],
            [
              105.84010503518456,
              21.016130200636372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.6_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5610",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016254055375615,
          "to_latitude": 21.016130200636372,
          "to_longitude": 105.84010503518456,
          "from_longitude": 105.84005194878232
        }
      },
      {
        "id": 5603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83564566209232,
            21.015431888456543,
            105.83573490985668,
            21.015493979139798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83564566209232,
              21.015493979139798
            ],
            [
              105.83567190895955,
              21.015474205087056
            ],
            [
              105.83573490985668,
              21.015431888456543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.15_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5611",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015493979139798,
          "to_latitude": 21.015431888456543,
          "to_longitude": 105.83573490985668,
          "from_longitude": 105.83564566209232
        }
      },
      {
        "id": 5604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84117925986966,
            21.01805837651313,
            105.84132173115195,
            21.018059650099996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84132173115195,
              21.018059650099996
            ],
            [
              105.84129810724956,
              21.018058908872487
            ],
            [
              105.84128983108457,
              21.01805864812581
            ],
            [
              105.84128119508894,
              21.01805837651313
            ],
            [
              105.84117925986966,
              21.018059205757055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.1A1_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5612",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018059650099996,
          "to_latitude": 21.018059205757055,
          "to_longitude": 105.84117925986966,
          "from_longitude": 105.84132173115195
        }
      },
      {
        "id": 5605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83846052021512,
            21.01221600121767,
            105.83857680509487,
            21.01229808755919
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857680509487,
              21.01221600121767
            ],
            [
              105.83846052021512,
              21.01229808755919
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.14_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5613",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01221600121767,
          "to_latitude": 21.01229808755919,
          "to_longitude": 105.83846052021512,
          "from_longitude": 105.83857680509487
        }
      },
      {
        "id": 5606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708801517257,
            21.01516549103819,
            105.83718073831552,
            21.01523390439136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717713356869,
              21.01516549103819
            ],
            [
              105.83717745661995,
              21.015165807383447
            ],
            [
              105.83718073831552,
              21.015169006713066
            ],
            [
              105.83713829606576,
              21.015199716534337
            ],
            [
              105.837107239819,
              21.015221221564747
            ],
            [
              105.83708801517257,
              21.01523390439136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.1_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5614",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01516549103819,
          "to_latitude": 21.01523390439136,
          "to_longitude": 105.83708801517257,
          "from_longitude": 105.83717713356869
        }
      },
      {
        "id": 5607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849903428754,
            21.014710106811584,
            105.83860697617007,
            21.014786879065614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83849903428754,
              21.014786879065614
            ],
            [
              105.83853200805609,
              21.014766702012246
            ],
            [
              105.83854911322236,
              21.01475347399059
            ],
            [
              105.83855830210365,
              21.014746856842947
            ],
            [
              105.83857720203336,
              21.014735831064858
            ],
            [
              105.83860070315116,
              21.014718488940613
            ],
            [
              105.83860697617007,
              21.014710106811584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.3_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5615",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014786879065614,
          "to_latitude": 21.014710106811584,
          "to_longitude": 105.83860697617007,
          "from_longitude": 105.83849903428754
        }
      },
      {
        "id": 5608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83626677176392,
            21.014237781993984,
            105.83655629976367,
            21.01454193932669
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83655629976367,
              21.01454193932669
            ],
            [
              105.83655583445368,
              21.014541124196576
            ],
            [
              105.83655229354679,
              21.0145349066494
            ],
            [
              105.83654166696539,
              21.014516251316667
            ],
            [
              105.83651397335241,
              21.01447594651328
            ],
            [
              105.8364837409055,
              21.01444300191739
            ],
            [
              105.83647205879743,
              21.014429294213024
            ],
            [
              105.83645342606071,
              21.014410458752344
            ],
            [
              105.83636056242631,
              21.014326779924538
            ],
            [
              105.83626677176392,
              21.014237781993984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.3_Ngách 260/1, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5616",
          "diaChiLapD": "Ngách 260/1, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01454193932669,
          "to_latitude": 21.014237781993984,
          "to_longitude": 105.83626677176392,
          "from_longitude": 105.83655629976367
        }
      },
      {
        "id": 5609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749346435667,
            21.01531176755872,
            105.83763106426426,
            21.015551212268733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763106426426,
              21.015551212268733
            ],
            [
              105.8375935566694,
              21.015483376031312
            ],
            [
              105.8375586519635,
              21.015423266697237
            ],
            [
              105.83752805518739,
              21.015368217686305
            ],
            [
              105.83752390605314,
              21.015360753996944
            ],
            [
              105.83749346435667,
              21.01531176755872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.24_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5617",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015551212268733,
          "to_latitude": 21.01531176755872,
          "to_longitude": 105.83749346435667,
          "from_longitude": 105.83763106426426
        }
      },
      {
        "id": 5610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499559924964,
            21.017778464723296,
            105.83503546858329,
            21.01786933652935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503546858329,
              21.01786933652935
            ],
            [
              105.8350330770646,
              21.01786482492137
            ],
            [
              105.83503059027676,
              21.01786013403938
            ],
            [
              105.83499559924964,
              21.017794120228668
            ],
            [
              105.83503348892557,
              21.017778464723296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.3_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5618",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01786933652935,
          "to_latitude": 21.017778464723296,
          "to_longitude": 105.83503348892557,
          "from_longitude": 105.83503546858329
        }
      },
      {
        "id": 5611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83759412962783,
            21.01341319472839,
            105.83774024205681,
            21.013474244450272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759412962783,
              21.0134725685666
            ],
            [
              105.83759528908013,
              21.013471939633988
            ],
            [
              105.83760819937504,
              21.01346493660532
            ],
            [
              105.83762046775902,
              21.013474244450272
            ],
            [
              105.83765371951917,
              21.013453405943505
            ],
            [
              105.83766189543977,
              21.013447646475203
            ],
            [
              105.83773663311375,
              21.013415295333
            ],
            [
              105.83774024205681,
              21.01341319472839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.11_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5619",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0134725685666,
          "to_latitude": 21.01341319472839,
          "to_longitude": 105.83774024205681,
          "from_longitude": 105.83759412962783
        }
      },
      {
        "id": 5612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562430921761,
            21.015008114044292,
            105.83572629202402,
            21.015075737915684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562430921761,
              21.015008114044292
            ],
            [
              105.83564460432724,
              21.015022664662425
            ],
            [
              105.83565211914025,
              21.015028052517696
            ],
            [
              105.83566604862027,
              21.015036465376443
            ],
            [
              105.83567880541536,
              21.01504417135003
            ],
            [
              105.83572629202402,
              21.015075737915684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.8_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5620",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015008114044292,
          "to_latitude": 21.015075737915684,
          "to_longitude": 105.83572629202402,
          "from_longitude": 105.83562430921761
        }
      },
      {
        "id": 5613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732554457988,
            21.01288305427029,
            105.83746561460758,
            21.013044831902782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746561460758,
              21.01288305427029
            ],
            [
              105.83744588076783,
              21.01289165525696
            ],
            [
              105.8374323070479,
              21.01289922153695
            ],
            [
              105.8374055807391,
              21.012930002189904
            ],
            [
              105.83732554457988,
              21.013044831902782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5621",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01288305427029,
          "to_latitude": 21.013044831902782,
          "to_longitude": 105.83732554457988,
          "from_longitude": 105.83746561460758
        }
      },
      {
        "id": 5614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752118753125,
            21.01439775631408,
            105.8376536108531,
            21.01447468913373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376536108531,
              21.01439775631408
            ],
            [
              105.83762074333805,
              21.01441870944877
            ],
            [
              105.8376035526329,
              21.014430408641385
            ],
            [
              105.83759381493655,
              21.014436765601946
            ],
            [
              105.83758358731116,
              21.01444341219918
            ],
            [
              105.83754906354758,
              21.014462508316253
            ],
            [
              105.83754098806514,
              21.014467660319063
            ],
            [
              105.83752118753125,
              21.01447468913373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5622",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01439775631408,
          "to_latitude": 21.01447468913373,
          "to_longitude": 105.83752118753125,
          "from_longitude": 105.8376536108531
        }
      },
      {
        "id": 5615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620741644815,
            21.017555275430997,
            105.8364983523959,
            21.0176383734952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620741644815,
              21.0176383734952
            ],
            [
              105.83646855732748,
              21.017563785792102
            ],
            [
              105.83647741046948,
              21.017561257491174
            ],
            [
              105.83649832550242,
              21.017555282789026
            ],
            [
              105.8364983523959,
              21.017555275430997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.4_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5623",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0176383734952,
          "to_latitude": 21.017555275430997,
          "to_longitude": 105.8364983523959,
          "from_longitude": 105.83620741644815
        }
      },
      {
        "id": 5616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487410195657,
            21.018824998836926,
            105.8349907344122,
            21.01888431479929
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349907344122,
              21.01884801891666
            ],
            [
              105.83498384560846,
              21.018824998836926
            ],
            [
              105.83492428174294,
              21.018856193208425
            ],
            [
              105.83487410195657,
              21.01888431479929
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.7_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5624",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01884801891666,
          "to_latitude": 21.01888431479929,
          "to_longitude": 105.83487410195657,
          "from_longitude": 105.8349907344122
        }
      },
      {
        "id": 5617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728010521877,
            21.014980014396663,
            105.83735391407846,
            21.01508334713621
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728010521877,
              21.014985332116822
            ],
            [
              105.83728450884337,
              21.01498290154619
            ],
            [
              105.83728973814259,
              21.014980014396663
            ],
            [
              105.83729338789269,
              21.014985561198998
            ],
            [
              105.83732000033784,
              21.0150302996936
            ],
            [
              105.83735391407846,
              21.01508334713621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.20_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5625",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014985332116822,
          "to_latitude": 21.01508334713621,
          "to_longitude": 105.83735391407846,
          "from_longitude": 105.83728010521877
        }
      },
      {
        "id": 5618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498205765905,
            21.0178968878133,
            105.83504319675441,
            21.018012018866365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504319675441,
              21.018012018866365
            ],
            [
              105.83498205765905,
              21.0178968878133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.3_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5626",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018012018866365,
          "to_latitude": 21.0178968878133,
          "to_longitude": 105.83498205765905,
          "from_longitude": 105.83504319675441
        }
      },
      {
        "id": 5619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926630682681,
            21.015985618244255,
            105.83927995013781,
            21.01611520326967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927995013781,
              21.01611520326967
            ],
            [
              105.83927155677046,
              21.016091914407635
            ],
            [
              105.83926630682681,
              21.016063089534477
            ],
            [
              105.83926671017193,
              21.016046122293343
            ],
            [
              105.83926766313874,
              21.016006009942252
            ],
            [
              105.83927381029335,
              21.015985618244255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.7_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5627",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01611520326967,
          "to_latitude": 21.015985618244255,
          "to_longitude": 105.83927381029335,
          "from_longitude": 105.83927995013781
        }
      },
      {
        "id": 5620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943966613883,
            21.01554485634325,
            105.83949706119394,
            21.015666508432275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943966613883,
              21.015666508432275
            ],
            [
              105.83944843808644,
              21.015648298610817
            ],
            [
              105.8394581656467,
              21.01562792807862
            ],
            [
              105.83946523205869,
              21.015613324212048
            ],
            [
              105.83947365389744,
              21.015589621806853
            ],
            [
              105.8394875506442,
              21.015562405942223
            ],
            [
              105.83949706119394,
              21.01554485634325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.9_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5628",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015666508432275,
          "to_latitude": 21.01554485634325,
          "to_longitude": 105.83949706119394,
          "from_longitude": 105.83943966613883
        }
      },
      {
        "id": 5621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376493120126,
            21.018456928870105,
            105.833818137254,
            21.018579819027334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83381576019339,
              21.018579819027334
            ],
            [
              105.833818137254,
              21.018578447119857
            ],
            [
              105.83376493120126,
              21.018456928870105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.2_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5629",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018579819027334,
          "to_latitude": 21.018456928870105,
          "to_longitude": 105.83376493120126,
          "from_longitude": 105.83381576019339
        }
      },
      {
        "id": 5622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463014929144,
            21.015446438373587,
            105.83473685122996,
            21.015533356204575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463014929144,
              21.015533356204575
            ],
            [
              105.83473685122996,
              21.015446438373587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.6_Ngách 164/3, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5630",
          "diaChiLapD": "Ngách 164/3, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015533356204575,
          "to_latitude": 21.015446438373587,
          "to_longitude": 105.83473685122996,
          "from_longitude": 105.83463014929144
        }
      },
      {
        "id": 5623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83580540733543,
            21.01505585298372,
            105.83592678076012,
            21.01513705575247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83580540733543,
              21.01513705575247
            ],
            [
              105.8358248703692,
              21.015125877571915
            ],
            [
              105.83584993548078,
              21.01510861845653
            ],
            [
              105.83592678076012,
              21.01505585298372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.9_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5631",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01513705575247,
          "to_latitude": 21.01505585298372,
          "to_longitude": 105.83592678076012,
          "from_longitude": 105.83580540733543
        }
      },
      {
        "id": 5624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998324785215,
            21.015331791396846,
            105.84011877877099,
            21.015362746758015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84011877877099,
              21.015362746758015
            ],
            [
              105.83998324785215,
              21.015331791396846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.12_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5632",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015362746758015,
          "to_latitude": 21.015331791396846,
          "to_longitude": 105.83998324785215,
          "from_longitude": 105.84011877877099
        }
      },
      {
        "id": 5625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367737924695,
            21.016088297855564,
            105.83689683445218,
            21.01616600049696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367737924695,
              21.01616600049696
            ],
            [
              105.83679587563338,
              21.016151674226506
            ],
            [
              105.83683281508101,
              21.01612770973342
            ],
            [
              105.83689683445218,
              21.016088297855564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.6_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5633",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01616600049696,
          "to_latitude": 21.016088297855564,
          "to_longitude": 105.83689683445218,
          "from_longitude": 105.8367737924695
        }
      },
      {
        "id": 5626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459828574195,
            21.015498192825138,
            105.83465785581048,
            21.01558625683469
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83464936397618,
              21.01558625683469
            ],
            [
              105.83464535586849,
              21.015580083990642
            ],
            [
              105.83464246321036,
              21.015576432896523
            ],
            [
              105.83465785581048,
              21.01556393172025
            ],
            [
              105.83463014929144,
              21.015533356204575
            ],
            [
              105.83459828574195,
              21.015498192825138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.6_Ngách 164/3, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5634",
          "diaChiLapD": "Ngách 164/3, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01558625683469,
          "to_latitude": 21.015498192825138,
          "to_longitude": 105.83459828574195,
          "from_longitude": 105.83464936397618
        }
      },
      {
        "id": 5627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551559722773,
            21.01550433168327,
            105.83562750070674,
            21.015601804491226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562750070674,
              21.01550433168327
            ],
            [
              105.83551559722773,
              21.015601804491226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.14_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5635",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01550433168327,
          "to_latitude": 21.015601804491226,
          "to_longitude": 105.83551559722773,
          "from_longitude": 105.83562750070674
        }
      },
      {
        "id": 5628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8408967074751,
            21.014418710085298,
            105.84092659129601,
            21.01458182095234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84092659129601,
              21.01458182095234
            ],
            [
              105.84092637799075,
              21.014580663170776
            ],
            [
              105.8409248101289,
              21.01457209842504
            ],
            [
              105.8408967074751,
              21.014418710085298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.3_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5636",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01458182095234,
          "to_latitude": 21.014418710085298,
          "to_longitude": 105.8408967074751,
          "from_longitude": 105.84092659129601
        }
      },
      {
        "id": 5629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83744436970083,
            21.01374951895552,
            105.8375190188538,
            21.013856774308387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746107108514,
              21.01374951895552
            ],
            [
              105.83744436970083,
              21.013763565912186
            ],
            [
              105.8375190188538,
              21.013856774308387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.2_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5637",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01374951895552,
          "to_latitude": 21.013856774308387,
          "to_longitude": 105.8375190188538,
          "from_longitude": 105.83746107108514
        }
      },
      {
        "id": 5630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341567073224,
            21.01735305104621,
            105.83356223099122,
            21.01738424662454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356223099122,
              21.01735305104621
            ],
            [
              105.83341567073224,
              21.01738424662454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.8_Ngách 77, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5638",
          "diaChiLapD": "Ngách 77, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735305104621,
          "to_latitude": 21.01738424662454,
          "to_longitude": 105.83341567073224,
          "from_longitude": 105.83356223099122
        }
      },
      {
        "id": 5631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352750422648,
            21.01644636879847,
            105.83547674430936,
            21.016674871403097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83547674430936,
              21.016674871403097
            ],
            [
              105.83539472359617,
              21.016564743918845
            ],
            [
              105.83537784990287,
              21.016546735193316
            ],
            [
              105.8352750422648,
              21.01644636879847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24.1_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5639",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016674871403097,
          "to_latitude": 21.01644636879847,
          "to_longitude": 105.8352750422648,
          "from_longitude": 105.83547674430936
        }
      },
      {
        "id": 5632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665473006448,
            21.01616600049696,
            105.8367737924695,
            21.01624252464461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665473006448,
              21.01624252464461
            ],
            [
              105.83667122239525,
              21.016231808975057
            ],
            [
              105.83668705434317,
              21.0162215228736
            ],
            [
              105.83669948469078,
              21.016213446528564
            ],
            [
              105.83674639826003,
              21.01618391700918
            ],
            [
              105.8367737924695,
              21.01616600049696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.6_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5640",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01624252464461,
          "to_latitude": 21.01616600049696,
          "to_longitude": 105.8367737924695,
          "from_longitude": 105.83665473006448
        }
      },
      {
        "id": 5633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389233939229,
            21.014781920265815,
            105.839041293459,
            21.014966972089976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389233939229,
              21.014781920265815
            ],
            [
              105.8389898767435,
              21.014856553691093
            ],
            [
              105.8390401526379,
              21.01491048383406
            ],
            [
              105.839041293459,
              21.014920280896785
            ],
            [
              105.83903621915684,
              21.014941240844745
            ],
            [
              105.83903269812724,
              21.014966972089976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.27_Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5641",
          "diaChiLapD": "Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014781920265815,
          "to_latitude": 21.014966972089976,
          "to_longitude": 105.83903269812724,
          "from_longitude": 105.8389233939229
        }
      },
      {
        "id": 5634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617736231682,
            21.015081276648477,
            105.83630342199109,
            21.015175783723866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617736231682,
              21.015175783723866
            ],
            [
              105.83619392521516,
              21.015166007108174
            ],
            [
              105.83620421055276,
              21.015158649470653
            ],
            [
              105.836240858972,
              21.015135306150015
            ],
            [
              105.83627891050996,
              21.015103621873724
            ],
            [
              105.83630342199109,
              21.015081276648477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.3_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5642",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015175783723866,
          "to_latitude": 21.015081276648477,
          "to_longitude": 105.83630342199109,
          "from_longitude": 105.83617736231682
        }
      },
      {
        "id": 5635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526676482337,
            21.015373501877896,
            105.83558492961603,
            21.015462018094713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83558492961603,
              21.015373501877896
            ],
            [
              105.83558405634811,
              21.015373704876268
            ],
            [
              105.83557176765095,
              21.015383033216896
            ],
            [
              105.83547054501604,
              21.015412883316014
            ],
            [
              105.83543311419444,
              21.01542600852615
            ],
            [
              105.83541138712636,
              21.01543709120035
            ],
            [
              105.83536785216664,
              21.015462018094713
            ],
            [
              105.83533738412825,
              21.015402135784115
            ],
            [
              105.83526676482337,
              21.01542842763272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.13_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5643",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015373501877896,
          "to_latitude": 21.01542842763272,
          "to_longitude": 105.83526676482337,
          "from_longitude": 105.83558492961603
        }
      },
      {
        "id": 5636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401849358715,
            21.01744584354112,
            105.84033904020997,
            21.01744687957829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401849358715,
              21.017446466559605
            ],
            [
              105.840232807534,
              21.01744584354112
            ],
            [
              105.84023995134349,
              21.017445913030514
            ],
            [
              105.84033904020997,
              21.01744687957829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.1_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5644",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017446466559605,
          "to_latitude": 21.01744687957829,
          "to_longitude": 105.84033904020997,
          "from_longitude": 105.8401849358715
        }
      },
      {
        "id": 5637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538921559298,
            21.015255037144097,
            105.83558584447185,
            21.01541246633972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83558584447185,
              21.015365741396398
            ],
            [
              105.83558247009978,
              21.01536524042179
            ],
            [
              105.83556298517992,
              21.015375434168575
            ],
            [
              105.83551347482144,
              21.015385526984602
            ],
            [
              105.83542928048838,
              21.01541246633972
            ],
            [
              105.83538921559298,
              21.015334862028688
            ],
            [
              105.83547263718418,
              21.015300792822163
            ],
            [
              105.8354496729899,
              21.015255037144097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.12_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5645",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015365741396398,
          "to_latitude": 21.015255037144097,
          "to_longitude": 105.8354496729899,
          "from_longitude": 105.83558584447185
        }
      },
      {
        "id": 5638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336642732894,
            21.01670922658936,
            105.83376981322235,
            21.016814486905435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336642732894,
              21.01670922658936
            ],
            [
              105.83371673574618,
              21.016760099233355
            ],
            [
              105.83375138525524,
              21.01679614421294
            ],
            [
              105.83376981322235,
              21.016814486905435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27.1_Ngõ 216, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5646",
          "diaChiLapD": "Ngõ 216, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01670922658936,
          "to_latitude": 21.016814486905435,
          "to_longitude": 105.83376981322235,
          "from_longitude": 105.8336642732894
        }
      },
      {
        "id": 5639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84096637183629,
            21.015253433165043,
            105.84106253949058,
            21.01526297462624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84106253949058,
              21.015254796547378
            ],
            [
              105.84099812482545,
              21.015253806205617
            ],
            [
              105.84096724660718,
              21.015253433165043
            ],
            [
              105.84096638992496,
              21.015262769505078
            ],
            [
              105.84096637183629,
              21.01526297462624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.1_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5647",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015254796547378,
          "to_latitude": 21.01526297462624,
          "to_longitude": 105.84096637183629,
          "from_longitude": 105.84106253949058
        }
      },
      {
        "id": 5640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388869489845,
            21.016174790614063,
            105.83415137034471,
            21.016283779262114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415137034471,
              21.016220863458507
            ],
            [
              105.83414973929727,
              21.01622041713382
            ],
            [
              105.83412639875009,
              21.016214013002752
            ],
            [
              105.83410199468824,
              21.016224976769244
            ],
            [
              105.83405566199757,
              21.016252852535096
            ],
            [
              105.83400014144141,
              21.016283779262114
            ],
            [
              105.83392888604132,
              21.016207028404384
            ],
            [
              105.83391941208198,
              21.016202737559432
            ],
            [
              105.83388869489845,
              21.016174790614063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15.2_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5648",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016220863458507,
          "to_latitude": 21.016174790614063,
          "to_longitude": 105.83388869489845,
          "from_longitude": 105.83415137034471
        }
      },
      {
        "id": 5641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673364074043,
            21.012768191269164,
            105.83685578012407,
            21.012868696626423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83685578012407,
              21.012868696626423
            ],
            [
              105.83684872039359,
              21.012862278713403
            ],
            [
              105.83683714449789,
              21.0128550059811
            ],
            [
              105.83679083976568,
              21.012817946802826
            ],
            [
              105.83678593317167,
              21.012813662552738
            ],
            [
              105.8367550794287,
              21.01278672026362
            ],
            [
              105.83673364074043,
              21.012768191269164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.4_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5649",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012868696626423,
          "to_latitude": 21.012768191269164,
          "to_longitude": 105.83673364074043,
          "from_longitude": 105.83685578012407
        }
      },
      {
        "id": 5642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798707799342,
            21.01237001433638,
            105.83809507321573,
            21.01246213499907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798707799342,
              21.01246213499907
            ],
            [
              105.83809083115105,
              21.01237379896686
            ],
            [
              105.83809507321573,
              21.01237001433638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.10_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5650",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01246213499907,
          "to_latitude": 21.01237001433638,
          "to_longitude": 105.83809507321573,
          "from_longitude": 105.83798707799342
        }
      },
      {
        "id": 5643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555590749377,
            21.015036465376443,
            105.83566604862027,
            21.0151363068358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566604862027,
              21.015036465376443
            ],
            [
              105.8356572021669,
              21.015044314494425
            ],
            [
              105.83555590749377,
              21.0151363068358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.8_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5651",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015036465376443,
          "to_latitude": 21.0151363068358,
          "to_longitude": 105.83555590749377,
          "from_longitude": 105.83566604862027
        }
      },
      {
        "id": 5644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202613438155,
            21.017570786507328,
            105.83213254929476,
            21.017676930531767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83213254929476,
              21.017676930531767
            ],
            [
              105.83202613438155,
              21.017570786507328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26.2_Ngách 268/7, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5652",
          "diaChiLapD": "Ngách 268/7, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017676930531767,
          "to_latitude": 21.017570786507328,
          "to_longitude": 105.83202613438155,
          "from_longitude": 105.83213254929476
        }
      },
      {
        "id": 5645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445813529903,
            21.016895201676284,
            105.83496889321856,
            21.017128671251857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83445813529903,
              21.017122580846866
            ],
            [
              105.83445935756946,
              21.017124417429336
            ],
            [
              105.83446219396728,
              21.017128671251857
            ],
            [
              105.83449621754161,
              21.017123055203992
            ],
            [
              105.83453438715766,
              21.01711243572144
            ],
            [
              105.83469884483286,
              21.017041826794074
            ],
            [
              105.83475283729396,
              21.017001623092195
            ],
            [
              105.83484678138493,
              21.016959546032247
            ],
            [
              105.83496889321856,
              21.016895201676284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.4_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5653",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017122580846866,
          "to_latitude": 21.016895201676284,
          "to_longitude": 105.83496889321856,
          "from_longitude": 105.83445813529903
        }
      },
      {
        "id": 5646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872175147886,
            21.014444344144344,
            105.83886344973892,
            21.014513078080242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872175147886,
              21.014513078080242
            ],
            [
              105.83878144365617,
              21.01448721895303
            ],
            [
              105.83886344973892,
              21.014444344144344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.8_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5654",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014513078080242,
          "to_latitude": 21.014444344144344,
          "to_longitude": 105.83886344973892,
          "from_longitude": 105.83872175147886
        }
      },
      {
        "id": 5647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354456698767,
            21.016760195900538,
            105.83569786254701,
            21.01696740943391
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569786254701,
              21.016760195900538
            ],
            [
              105.835580976971,
              21.0168615879399
            ],
            [
              105.8355396430764,
              21.01688418911033
            ],
            [
              105.83552967106831,
              21.016880129319837
            ],
            [
              105.8354456698767,
              21.01691978605511
            ],
            [
              105.83546866494869,
              21.01696740943391
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.11_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5655",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016760195900538,
          "to_latitude": 21.01696740943391,
          "to_longitude": 105.83546866494869,
          "from_longitude": 105.83569786254701
        }
      },
      {
        "id": 5648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691112627463,
            21.01526663300018,
            105.83704243093169,
            21.015354781521857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83704243093169,
              21.01526663300018
            ],
            [
              105.83702417230967,
              21.015279016615906
            ],
            [
              105.83699989043234,
              21.015295432952364
            ],
            [
              105.83696788333826,
              21.015318414891603
            ],
            [
              105.83694482129533,
              21.015333989737034
            ],
            [
              105.83691112627463,
              21.015354781521857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.4_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5656",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01526663300018,
          "to_latitude": 21.015354781521857,
          "to_longitude": 105.83691112627463,
          "from_longitude": 105.83704243093169
        }
      },
      {
        "id": 5649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83581396479141,
            21.015877485228135,
            105.83593749399995,
            21.01597294426109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83581396479141,
              21.01597294426109
            ],
            [
              105.8358689386211,
              21.0159270288268
            ],
            [
              105.83593297953317,
              21.015880748179246
            ],
            [
              105.83593586047436,
              21.0158786656398
            ],
            [
              105.83593749399995,
              21.015877485228135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.17_Ngách 218/125, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5657",
          "diaChiLapD": "Ngách 218/125, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01597294426109,
          "to_latitude": 21.015877485228135,
          "to_longitude": 105.83593749399995,
          "from_longitude": 105.83581396479141
        }
      },
      {
        "id": 5650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83193784511698,
            21.018508599382493,
            105.83204825492318,
            21.01859469210722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203370257696,
              21.018508599382493
            ],
            [
              105.83204825492318,
              21.018534933075486
            ],
            [
              105.83198496007796,
              21.01856786442161
            ],
            [
              105.83193784511698,
              21.01859469210722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.5_Ngách 241/18, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5658",
          "diaChiLapD": "Ngách 241/18, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018508599382493,
          "to_latitude": 21.01859469210722,
          "to_longitude": 105.83193784511698,
          "from_longitude": 105.83203370257696
        }
      },
      {
        "id": 5651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83233970698517,
            21.018488904173008,
            105.83248922733114,
            21.01855290220593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83248922733114,
              21.018488904173008
            ],
            [
              105.83233970698517,
              21.01855290220593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.4_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5659",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018488904173008,
          "to_latitude": 21.01855290220593,
          "to_longitude": 105.83233970698517,
          "from_longitude": 105.83248922733114
        }
      },
      {
        "id": 5652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83621490952535,
            21.01759603749732,
            105.83651882972904,
            21.017670545633727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83621490952535,
              21.017670545633727
            ],
            [
              105.83643282444034,
              21.0176090883692
            ],
            [
              105.83645083073978,
              21.017604010424357
            ],
            [
              105.83649286543981,
              21.01760253290035
            ],
            [
              105.83649583371525,
              21.01760178940293
            ],
            [
              105.83651880283048,
              21.017596043952157
            ],
            [
              105.83651882972904,
              21.01759603749732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.3_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5660",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017670545633727,
          "to_latitude": 21.01759603749732,
          "to_longitude": 105.83651882972904,
          "from_longitude": 105.83621490952535
        }
      },
      {
        "id": 5653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661350218252,
            21.012651794583277,
            105.8367208502571,
            21.012758903084997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661350218252,
              21.012651794583277
            ],
            [
              105.83661525687391,
              21.012658376784977
            ],
            [
              105.83662330434322,
              21.012672015647965
            ],
            [
              105.83666286935537,
              21.012705029054125
            ],
            [
              105.83667553411392,
              21.012717302114968
            ],
            [
              105.8367208502571,
              21.012758903084997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.2_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5661",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012651794583277,
          "to_latitude": 21.012758903084997,
          "to_longitude": 105.8367208502571,
          "from_longitude": 105.83661350218252
        }
      },
      {
        "id": 5654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015057135751,
            21.014583155586433,
            105.84018602080737,
            21.014730586251957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018602080737,
              21.014730586251957
            ],
            [
              105.84015493729797,
              21.014601315017256
            ],
            [
              105.84015057135751,
              21.014583155586433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.9A_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5662",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014730586251957,
          "to_latitude": 21.014583155586433,
          "to_longitude": 105.84015057135751,
          "from_longitude": 105.84018602080737
        }
      },
      {
        "id": 5655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84003889071337,
            21.013817018968574,
            105.84097320598343,
            21.014708421467695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003889071337,
              21.014708421467695
            ],
            [
              105.8400655470159,
              21.014614515355422
            ],
            [
              105.84014932989011,
              21.014603146445197
            ],
            [
              105.84015493729797,
              21.014601315017256
            ],
            [
              105.84022299911015,
              21.01457908385773
            ],
            [
              105.84032915861737,
              21.014499252896336
            ],
            [
              105.84042221628451,
              21.014391731429626
            ],
            [
              105.84048005550494,
              21.01435798850006
            ],
            [
              105.84053788398475,
              21.014322333479974
            ],
            [
              105.8405986585932,
              21.01429539263862
            ],
            [
              105.84066191319275,
              21.014211667398015
            ],
            [
              105.84072694759708,
              21.014125594894264
            ],
            [
              105.84083640766194,
              21.01397966205648
            ],
            [
              105.84087868218961,
              21.013924723193654
            ],
            [
              105.84097320598343,
              21.013817018968574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.9_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5663",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 157,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014708421467695,
          "to_latitude": 21.013817018968574,
          "to_longitude": 105.84097320598343,
          "from_longitude": 105.84003889071337
        }
      },
      {
        "id": 5656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84033564496288,
            21.015381999150158,
            105.84043779088655,
            21.01541309739532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84033564496288,
              21.015381999150158
            ],
            [
              105.84043670972878,
              21.01541309739532
            ],
            [
              105.84043770497239,
              21.015409256488244
            ],
            [
              105.84043779088655,
              21.01540896703229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.9_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5664",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015381999150158,
          "to_latitude": 21.01540896703229,
          "to_longitude": 105.84043779088655,
          "from_longitude": 105.84033564496288
        }
      },
      {
        "id": 5657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84041530894108,
            21.016572684718668,
            105.84042822769563,
            21.016728026819106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84042822769563,
              21.016728026819106
            ],
            [
              105.84041684453776,
              21.016711115097447
            ],
            [
              105.84041530894108,
              21.016572684718668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.5_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5665",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016728026819106,
          "to_latitude": 21.016572684718668,
          "to_longitude": 105.84041530894108,
          "from_longitude": 105.84042822769563
        }
      },
      {
        "id": 5658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449728316717,
            21.018116762999455,
            105.83459861390769,
            21.018216348057436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459861390769,
              21.018200531294376
            ],
            [
              105.83455968606603,
              21.018216348057436
            ],
            [
              105.83449728316717,
              21.018116762999455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.7_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5666",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018200531294376,
          "to_latitude": 21.018116762999455,
          "to_longitude": 105.83449728316717,
          "from_longitude": 105.83459861390769
        }
      },
      {
        "id": 5659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83675021935417,
            21.016076328731252,
            105.83689014279439,
            21.016166032441646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675021935417,
              21.016166032441646
            ],
            [
              105.8367904393602,
              21.016138851699097
            ],
            [
              105.83681290732126,
              21.01612366818058
            ],
            [
              105.83689014279439,
              21.016076328731252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.7_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5667",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016166032441646,
          "to_latitude": 21.016076328731252,
          "to_longitude": 105.83689014279439,
          "from_longitude": 105.83675021935417
        }
      },
      {
        "id": 5660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84005584069162,
            21.015535244485307,
            105.84021323000037,
            21.015602286711946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84005584069162,
              21.015535244485307
            ],
            [
              105.84005640862652,
              21.015535486454592
            ],
            [
              105.84007062894105,
              21.01554150306174
            ],
            [
              105.84017691307407,
              21.015586484427313
            ],
            [
              105.84021323000037,
              21.015602286711946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.13_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5668",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015535244485307,
          "to_latitude": 21.015602286711946,
          "to_longitude": 105.84021323000037,
          "from_longitude": 105.84005584069162
        }
      },
      {
        "id": 5661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83395825207653,
            21.018354461519355,
            105.83403681677889,
            21.018497507471807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83403681677889,
              21.018497507471807
            ],
            [
              105.83403642128818,
              21.01849661973486
            ],
            [
              105.83400837291411,
              21.018433365351267
            ],
            [
              105.83400329621104,
              21.018435875914513
            ],
            [
              105.83395825207653,
              21.018354461519355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.4_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5669",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018497507471807,
          "to_latitude": 21.018354461519355,
          "to_longitude": 105.83395825207653,
          "from_longitude": 105.83403681677889
        }
      },
      {
        "id": 5662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83797616190779,
            21.012323387596723,
            105.83813129719923,
            21.012454989990495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83797616190779,
              21.01245433793464
            ],
            [
              105.8379785607199,
              21.012454989990495
            ],
            [
              105.83799111464516,
              21.012444651276194
            ],
            [
              105.8380588100004,
              21.012386548537382
            ],
            [
              105.83813129719923,
              21.012323387596723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.9_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5670",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01245433793464,
          "to_latitude": 21.012323387596723,
          "to_longitude": 105.83813129719923,
          "from_longitude": 105.83797616190779
        }
      },
      {
        "id": 5663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779246697645,
            21.014413031780332,
            105.83790396102427,
            21.01452966140062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779246697645,
              21.01452966140062
            ],
            [
              105.83779481664983,
              21.014527193060555
            ],
            [
              105.8378088948605,
              21.01451658310937
            ],
            [
              105.837833638385,
              21.014491580146494
            ],
            [
              105.83790396102427,
              21.014413031780332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5671",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01452966140062,
          "to_latitude": 21.014413031780332,
          "to_longitude": 105.83790396102427,
          "from_longitude": 105.83779246697645
        }
      },
      {
        "id": 5664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402743491872,
            21.01804150368793,
            105.83415310493639,
            21.01812025947712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413723474867,
              21.01804150368793
            ],
            [
              105.83413730052136,
              21.018041568397575
            ],
            [
              105.83415310493639,
              21.018056705716052
            ],
            [
              105.83413255049875,
              21.01806683771436
            ],
            [
              105.83411192317298,
              21.018077355744435
            ],
            [
              105.8340851189667,
              21.018089397991467
            ],
            [
              105.83405141897849,
              21.018106885261584
            ],
            [
              105.83402743491872,
              21.01812025947712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5672",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01804150368793,
          "to_latitude": 21.01812025947712,
          "to_longitude": 105.83402743491872,
          "from_longitude": 105.83413723474867
        }
      },
      {
        "id": 5665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84108645351338,
            21.014431067614577,
            105.84108710038319,
            21.014587778223362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84108710038319,
              21.014587778223362
            ],
            [
              105.84108709354564,
              21.014586215680378
            ],
            [
              105.84108708562907,
              21.014584289143652
            ],
            [
              105.84108645351338,
              21.014431067614577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.1_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5673",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014587778223362,
          "to_latitude": 21.014431067614577,
          "to_longitude": 105.84108645351338,
          "from_longitude": 105.84108710038319
        }
      },
      {
        "id": 5666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83304798007457,
            21.016174550615553,
            105.83318913574251,
            21.016445176157536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314108893853,
              21.016174550615553
            ],
            [
              105.83304798007457,
              21.01631245750569
            ],
            [
              105.83318913574251,
              21.016445176157536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27.4_Ngách 216/1, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5674",
          "diaChiLapD": "Ngách 216/1, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016174550615553,
          "to_latitude": 21.016445176157536,
          "to_longitude": 105.83318913574251,
          "from_longitude": 105.83314108893853
        }
      },
      {
        "id": 5667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84068659713814,
            21.01582487507165,
            105.84069418829675,
            21.015988564673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84068659713814,
              21.015988564673
            ],
            [
              105.84068798791661,
              21.015971712682255
            ],
            [
              105.84069418829675,
              21.01589666481512
            ],
            [
              105.84069285476818,
              21.01582487507165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.2_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5675",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015988564673,
          "to_latitude": 21.01582487507165,
          "to_longitude": 105.84069285476818,
          "from_longitude": 105.84068659713814
        }
      },
      {
        "id": 5668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629869099929,
            21.015162333169467,
            105.83639552938145,
            21.015294228669102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631476011324,
              21.015162333169467
            ],
            [
              105.83629869099929,
              21.015181481031593
            ],
            [
              105.8363290560472,
              21.015217484223438
            ],
            [
              105.83634442426015,
              21.015234973679334
            ],
            [
              105.83636323440838,
              21.015253521953365
            ],
            [
              105.83639552938145,
              21.015294228669102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.2_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5676",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015162333169467,
          "to_latitude": 21.015294228669102,
          "to_longitude": 105.83639552938145,
          "from_longitude": 105.83631476011324
        }
      },
      {
        "id": 5669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83339187645167,
            21.01841795370065,
            105.83355650904893,
            21.01847286167617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355650904893,
              21.01841795370065
            ],
            [
              105.83339187645167,
              21.01847286167617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.3_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5677",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01841795370065,
          "to_latitude": 21.01847286167617,
          "to_longitude": 105.83339187645167,
          "from_longitude": 105.83355650904893
        }
      },
      {
        "id": 5670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83776293094887,
            21.01287665449294,
            105.83791788619831,
            21.012954463843194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83776293094887,
              21.01287665449294
            ],
            [
              105.83791788619831,
              21.012954463843194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.4_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5678",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01287665449294,
          "to_latitude": 21.012954463843194,
          "to_longitude": 105.83791788619831,
          "from_longitude": 105.83776293094887
        }
      },
      {
        "id": 5671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998995920133,
            21.016647734492228,
            105.83999174769912,
            21.01684933829985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83998995920133,
              21.01684933829985
            ],
            [
              105.8399908559219,
              21.016735151063237
            ],
            [
              105.83999174769912,
              21.016647734492228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.2_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5679",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01684933829985,
          "to_latitude": 21.016647734492228,
          "to_longitude": 105.83999174769912,
          "from_longitude": 105.83998995920133
        }
      },
      {
        "id": 5672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405282732834,
            21.015351076098142,
            105.83416799490247,
            21.015454595770183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416240467905,
              21.015454595770183
            ],
            [
              105.83416591213248,
              21.01545117883511
            ],
            [
              105.83416799490247,
              21.01544913546433
            ],
            [
              105.8341473625096,
              21.015431002326434
            ],
            [
              105.83410514942481,
              21.01539424482155
            ],
            [
              105.83405282732834,
              21.015351076098142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.3_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5680",
          "diaChiLapD": "Ngõ 164, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015454595770183,
          "to_latitude": 21.015351076098142,
          "to_longitude": 105.83405282732834,
          "from_longitude": 105.83416240467905
        }
      },
      {
        "id": 5673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809618488097,
            21.01488959889323,
            105.83824833378458,
            21.014936602638976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824833378458,
              21.01488959889323
            ],
            [
              105.83823262696151,
              21.01490346851098
            ],
            [
              105.83817170891375,
              21.014932923806676
            ],
            [
              105.83814604108886,
              21.014936602638976
            ],
            [
              105.83809618488097,
              21.014899368961775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.1_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5681",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488959889323,
          "to_latitude": 21.014899368961775,
          "to_longitude": 105.83809618488097,
          "from_longitude": 105.83824833378458
        }
      },
      {
        "id": 5674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783116251693,
            21.014570137613006,
            105.83794515002096,
            21.014700636772115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783116251693,
              21.014570137613006
            ],
            [
              105.8378663355811,
              21.01461131851655
            ],
            [
              105.83788956560936,
              21.014634464844654
            ],
            [
              105.83791930739751,
              21.014666474049875
            ],
            [
              105.83792433598406,
              21.01467312140036
            ],
            [
              105.83794515002096,
              21.014700636772115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.9_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5682",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014570137613006,
          "to_latitude": 21.014700636772115,
          "to_longitude": 105.83794515002096,
          "from_longitude": 105.83783116251693
        }
      },
      {
        "id": 5675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83651894143465,
            21.016724672557828,
            105.83667922873555,
            21.016807694836597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667922873555,
              21.016724672557828
            ],
            [
              105.83667462000642,
              21.016727248638624
            ],
            [
              105.83667458740202,
              21.01672726686346
            ],
            [
              105.83662543049559,
              21.016753881855884
            ],
            [
              105.8365884317573,
              21.016772078609915
            ],
            [
              105.83651894143465,
              21.016807694836597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.13_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5683",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016724672557828,
          "to_latitude": 21.016807694836597,
          "to_longitude": 105.83651894143465,
          "from_longitude": 105.83667922873555
        }
      },
      {
        "id": 5676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84106356900048,
            21.016634528813675,
            105.84114299796752,
            21.016748336424968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84106356900048,
              21.016748336424968
            ],
            [
              105.84106365961381,
              21.016746483465894
            ],
            [
              105.84106435387208,
              21.01673236987839
            ],
            [
              105.84106916419663,
              21.016634528813675
            ],
            [
              105.84114299796752,
              21.016635887119715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.2_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5684",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016748336424968,
          "to_latitude": 21.016635887119715,
          "to_longitude": 105.84114299796752,
          "from_longitude": 105.84106356900048
        }
      },
      {
        "id": 5677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389263797871,
            21.01512268605433,
            105.83908581456859,
            21.015205028907534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389263797871,
              21.015205028907534
            ],
            [
              105.83895183309802,
              21.015190166393406
            ],
            [
              105.83897390572062,
              21.015177630993527
            ],
            [
              105.83899903009126,
              21.01516484118701
            ],
            [
              105.83904322120696,
              21.015141783432398
            ],
            [
              105.83908581456859,
              21.01512268605433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.4_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5685",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015205028907534,
          "to_latitude": 21.01512268605433,
          "to_longitude": 105.83908581456859,
          "from_longitude": 105.8389263797871
        }
      },
      {
        "id": 5678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841805302401,
            21.01348591200661,
            105.83854589652624,
            21.013592466682667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83841805302401,
              21.01348591200661
            ],
            [
              105.83851174189353,
              21.01356310409795
            ],
            [
              105.83852459755714,
              21.013573696075827
            ],
            [
              105.83854589652624,
              21.013592466682667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.18_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5686",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01348591200661,
          "to_latitude": 21.013592466682667,
          "to_longitude": 105.83854589652624,
          "from_longitude": 105.83841805302401
        }
      },
      {
        "id": 5679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343658935425,
            21.015293261098094,
            105.83449524900954,
            21.015414691142205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343658935425,
              21.015293261098094
            ],
            [
              105.83449524900954,
              21.015414691142205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.4_Ngách 164/14, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5687",
          "diaChiLapD": "Ngách 164/14, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015293261098094,
          "to_latitude": 21.015414691142205,
          "to_longitude": 105.83449524900954,
          "from_longitude": 105.8343658935425
        }
      },
      {
        "id": 5680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771268598338,
            21.01608556552982,
            105.83789141799984,
            21.016147163263987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83771268598338,
              21.01613931864072
            ],
            [
              105.83771295587138,
              21.016140794082993
            ],
            [
              105.83771412845623,
              21.016147163263987
            ],
            [
              105.83771755186768,
              21.016146450924275
            ],
            [
              105.83777355647238,
              21.016127250792774
            ],
            [
              105.8378213175418,
              21.01611172039446
            ],
            [
              105.83789141799984,
              21.01608556552982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.1_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5688",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01613931864072,
          "to_latitude": 21.01608556552982,
          "to_longitude": 105.83789141799984,
          "from_longitude": 105.83771268598338
        }
      },
      {
        "id": 5681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83831398581535,
            21.0150979029969,
            105.83843708515823,
            21.015236931201553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83831398581535,
              21.0150979029969
            ],
            [
              105.83834923458855,
              21.015138642644384
            ],
            [
              105.83836911213638,
              21.01516139084111
            ],
            [
              105.83838520095975,
              21.015180238616836
            ],
            [
              105.83840323230162,
              21.015200701718115
            ],
            [
              105.83842037229198,
              21.015220712178635
            ],
            [
              105.83843708515823,
              21.015236931201553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.15_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5689",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0150979029969,
          "to_latitude": 21.015236931201553,
          "to_longitude": 105.83843708515823,
          "from_longitude": 105.83831398581535
        }
      },
      {
        "id": 5682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441614662628,
            21.016363905710413,
            105.83453908024964,
            21.016501076960097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453908024964,
              21.016501076960097
            ],
            [
              105.83453226578689,
              21.016493804205354
            ],
            [
              105.83450019080207,
              21.01645957398144
            ],
            [
              105.83445672680135,
              21.016407772284705
            ],
            [
              105.83441614662628,
              21.016363905710413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.19_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5690",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016501076960097,
          "to_latitude": 21.016363905710413,
          "to_longitude": 105.83441614662628,
          "from_longitude": 105.83453908024964
        }
      },
      {
        "id": 5683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630007916243,
            21.017861285526212,
            105.8366617130261,
            21.0179606372316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630007916243,
              21.0179606372316
            ],
            [
              105.83662194192723,
              21.017880560829127
            ],
            [
              105.83666168808156,
              21.017861297390816
            ],
            [
              105.8366617130261,
              21.017861285526212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.1_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5691",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0179606372316,
          "to_latitude": 21.017861285526212,
          "to_longitude": 105.8366617130261,
          "from_longitude": 105.83630007916243
        }
      },
      {
        "id": 5684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494094527734,
            21.01791770298779,
            105.83498034985378,
            21.01805313449072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494094527734,
              21.01791770298779
            ],
            [
              105.8349662022703,
              21.017963664233484
            ],
            [
              105.8349509852284,
              21.01797905489652
            ],
            [
              105.83498034985378,
              21.018033843059893
            ],
            [
              105.83494843964353,
              21.01805313449072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.4_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5692",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01791770298779,
          "to_latitude": 21.01805313449072,
          "to_longitude": 105.83494843964353,
          "from_longitude": 105.83494094527734
        }
      },
      {
        "id": 5685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370180270143,
            21.01835558328487,
            105.837085416835,
            21.018516581014612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837085416835,
              21.018516581014612
            ],
            [
              105.83708354745937,
              21.018509978614922
            ],
            [
              105.8370661843366,
              21.018448651155357
            ],
            [
              105.8370180270143,
              21.01835558328487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5693",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018516581014612,
          "to_latitude": 21.01835558328487,
          "to_longitude": 105.8370180270143,
          "from_longitude": 105.837085416835
        }
      },
      {
        "id": 5686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84016747729565,
            21.015338986329652,
            105.84033564496288,
            21.015381999150158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84016747729565,
              21.015338986329652
            ],
            [
              105.84019989988144,
              21.015346136000804
            ],
            [
              105.84022926242443,
              21.01535223136168
            ],
            [
              105.84024995520711,
              21.015357322700122
            ],
            [
              105.84029230319386,
              21.015369480482068
            ],
            [
              105.84029338325779,
              21.01536979037255
            ],
            [
              105.84033564496288,
              21.015381999150158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.9_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5694",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015338986329652,
          "to_latitude": 21.015381999150158,
          "to_longitude": 105.84033564496288,
          "from_longitude": 105.84016747729565
        }
      },
      {
        "id": 5687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543124049304,
            21.016755737715602,
            105.83550024075595,
            21.01689107642385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83550024075595,
              21.01689107642385
            ],
            [
              105.83543124049304,
              21.016780898565543
            ],
            [
              105.83548010849083,
              21.016755737715602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.13_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5695",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01689107642385,
          "to_latitude": 21.016755737715602,
          "to_longitude": 105.83548010849083,
          "from_longitude": 105.83550024075595
        }
      },
      {
        "id": 5688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534244259565,
            21.015015140207016,
            105.83552996931341,
            21.01513624320591
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534244259565,
              21.01513624320591
            ],
            [
              105.8354072607134,
              21.01510121131181
            ],
            [
              105.83545470937439,
              21.015073986355493
            ],
            [
              105.83549932431944,
              21.015039535958135
            ],
            [
              105.83552996931341,
              21.015015140207016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.5_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5696",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01513624320591,
          "to_latitude": 21.015015140207016,
          "to_longitude": 105.83552996931341,
          "from_longitude": 105.83534244259565
        }
      },
      {
        "id": 5689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783740050431,
            21.01590288535298,
            105.83793270900922,
            21.01605728725082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783740050431,
              21.01590288535298
            ],
            [
              105.83786435626656,
              21.015946169673487
            ],
            [
              105.83789435813227,
              21.01599283149587
            ],
            [
              105.83793270900922,
              21.01605728725082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.30_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5697",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01590288535298,
          "to_latitude": 21.01605728725082,
          "to_longitude": 105.83793270900922,
          "from_longitude": 105.83783740050431
        }
      },
      {
        "id": 5690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83628627443957,
            21.017807286458623,
            105.83662494617903,
            21.01793238811972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83628627443957,
              21.01793238811972
            ],
            [
              105.83655812738063,
              21.0178573330271
            ],
            [
              105.83659268645347,
              21.017818020959258
            ],
            [
              105.8366249202524,
              21.01780729471514
            ],
            [
              105.83662494617903,
              21.017807286458623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.2_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5698",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01793238811972,
          "to_latitude": 21.017807286458623,
          "to_longitude": 105.83662494617903,
          "from_longitude": 105.83628627443957
        }
      },
      {
        "id": 5691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425942089949,
            21.015567298060972,
            105.83450251182228,
            21.015701237653857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450251182228,
              21.015690503924503
            ],
            [
              105.8344863465469,
              21.015701237653857
            ],
            [
              105.83435310139689,
              21.015567298060972
            ],
            [
              105.83429592318208,
              21.015610403959478
            ],
            [
              105.83428173280494,
              21.015596242339768
            ],
            [
              105.83425942089949,
              21.01561357534248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.5_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5699",
          "diaChiLapD": "Ngõ 164, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015690503924503,
          "to_latitude": 21.01561357534248,
          "to_longitude": 105.83425942089949,
          "from_longitude": 105.83450251182228
        }
      },
      {
        "id": 5692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368211377615,
            21.01467791978318,
            105.83701347189437,
            21.01494987297321
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368211377615,
              21.01467791978318
            ],
            [
              105.83682310530537,
              21.014680577326995
            ],
            [
              105.8368365726961,
              21.014698748087643
            ],
            [
              105.83693950982831,
              21.014833915060063
            ],
            [
              105.8369638075884,
              21.014883109768128
            ],
            [
              105.8369434331544,
              21.01490177125629
            ],
            [
              105.83697876492057,
              21.01494987297321
            ],
            [
              105.83701347189437,
              21.014929443810598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5700",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01467791978318,
          "to_latitude": 21.014929443810598,
          "to_longitude": 105.83701347189437,
          "from_longitude": 105.8368211377615
        }
      },
      {
        "id": 5693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629696352031,
            21.013608646139744,
            105.8363954149598,
            21.01374485889546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629696352031,
              21.013608646139744
            ],
            [
              105.83633523214854,
              21.01366117473133
            ],
            [
              105.83635599641951,
              21.013689675975627
            ],
            [
              105.83637012966165,
              21.013709462084588
            ],
            [
              105.8363954149598,
              21.01374485889546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.9_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5701",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013608646139744,
          "to_latitude": 21.01374485889546,
          "to_longitude": 105.8363954149598,
          "from_longitude": 105.83629696352031
        }
      },
      {
        "id": 5694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83628628365913,
            21.015458684661617,
            105.83645185177268,
            21.015635118545735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83628628365913,
              21.015458684661617
            ],
            [
              105.83630526937351,
              21.01548197581202
            ],
            [
              105.83633900954976,
              21.01551359983458
            ],
            [
              105.83634423129078,
              21.015518494027685
            ],
            [
              105.8364099017426,
              21.015592684476825
            ],
            [
              105.83645185177268,
              21.015635118545735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5702",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015458684661617,
          "to_latitude": 21.015635118545735,
          "to_longitude": 105.83645185177268,
          "from_longitude": 105.83628628365913
        }
      },
      {
        "id": 5695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697790308253,
            21.014539408753837,
            105.83703566781634,
            21.014618519792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697790308253,
              21.014539408753837
            ],
            [
              105.83698487526536,
              21.014548222439338
            ],
            [
              105.8369922194555,
              21.014557506680692
            ],
            [
              105.83701207939095,
              21.01458697512087
            ],
            [
              105.83703566781634,
              21.014618519792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.18_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5703",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014539408753837,
          "to_latitude": 21.014618519792,
          "to_longitude": 105.83703566781634,
          "from_longitude": 105.83697790308253
        }
      },
      {
        "id": 5696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936630221044,
            21.01561497977529,
            105.83945096331345,
            21.015774639450115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936630221044,
              21.015774639450115
            ],
            [
              105.83938031876643,
              21.01575232479793
            ],
            [
              105.83939390887429,
              21.01572823651082
            ],
            [
              105.8394053126607,
              21.015705036943388
            ],
            [
              105.83942419167096,
              21.015675481538846
            ],
            [
              105.8394278838064,
              21.015668302563743
            ],
            [
              105.83943808950082,
              21.015648456252904
            ],
            [
              105.83945096331345,
              21.01561497977529
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.10_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5704",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015774639450115,
          "to_latitude": 21.01561497977529,
          "to_longitude": 105.83945096331345,
          "from_longitude": 105.83936630221044
        }
      },
      {
        "id": 5697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8405688215037,
            21.01655537562131,
            105.84058239304058,
            21.01672555534469
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84058239304058,
              21.01672555534469
            ],
            [
              105.8405688215037,
              21.01655537562131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.5_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5705",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01672555534469,
          "to_latitude": 21.01655537562131,
          "to_longitude": 105.8405688215037,
          "from_longitude": 105.84058239304058
        }
      },
      {
        "id": 5698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954703004582,
            21.015503123796755,
            105.83972627673656,
            21.0155742134483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954703004582,
              21.015503123796755
            ],
            [
              105.83962400376087,
              21.01553554630906
            ],
            [
              105.83969634335232,
              21.015564955923022
            ],
            [
              105.83972627673656,
              21.0155742134483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.17_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5706",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015503123796755,
          "to_latitude": 21.0155742134483,
          "to_longitude": 105.83972627673656,
          "from_longitude": 105.83954703004582
        }
      },
      {
        "id": 5699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83317200067339,
            21.016428351735517,
            105.83343937229304,
            21.016600179406385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83343937229304,
              21.016428351735517
            ],
            [
              105.83343836083115,
              21.01642913436715
            ],
            [
              105.83342915716139,
              21.016442152472152
            ],
            [
              105.8334353626915,
              21.016476609899804
            ],
            [
              105.83330403322329,
              21.016600179406385
            ],
            [
              105.83317200067339,
              21.016466650244887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27.2_Ngách 216/19, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5707",
          "diaChiLapD": "Ngách 216/19, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016428351735517,
          "to_latitude": 21.016466650244887,
          "to_longitude": 105.83317200067339,
          "from_longitude": 105.83343937229304
        }
      },
      {
        "id": 5700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83688271055075,
            21.015242533509088,
            105.83704309677647,
            21.015350956148662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688271055075,
              21.015350956148662
            ],
            [
              105.83690263901026,
              21.01533855439795
            ],
            [
              105.83692007277958,
              21.015327116915376
            ],
            [
              105.8369312078852,
              21.015316211705915
            ],
            [
              105.83695831316825,
              21.01529826497584
            ],
            [
              105.83699121189966,
              21.01527578716708
            ],
            [
              105.83704309677647,
              21.015242533509088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.5_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5708",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015350956148662,
          "to_latitude": 21.015242533509088,
          "to_longitude": 105.83704309677647,
          "from_longitude": 105.83688271055075
        }
      },
      {
        "id": 5701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673399435523,
            21.01407422150405,
            105.83690236367207,
            21.014180882223993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673399435523,
              21.014180882223993
            ],
            [
              105.83678362012023,
              21.014152421430122
            ],
            [
              105.8368424422053,
              21.01411125850671
            ],
            [
              105.83690236367207,
              21.01407422150405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5709",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014180882223993,
          "to_latitude": 21.01407422150405,
          "to_longitude": 105.83690236367207,
          "from_longitude": 105.83673399435523
        }
      },
      {
        "id": 5702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83982602484856,
            21.015958644700046,
            105.84002860672189,
            21.01630032355227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002860672189,
              21.01630032355227
            ],
            [
              105.84002663700909,
              21.016299869021275
            ],
            [
              105.83998473732892,
              21.0162901675425
            ],
            [
              105.83991440796325,
              21.0162752195892
            ],
            [
              105.83985013859265,
              21.016251639388567
            ],
            [
              105.83983838030278,
              21.016242616424858
            ],
            [
              105.83982967340769,
              21.01623309968842
            ],
            [
              105.83982602484856,
              21.01621782341418
            ],
            [
              105.8398335364475,
              21.016195800063493
            ],
            [
              105.83987260275043,
              21.016120388549194
            ],
            [
              105.83988973753429,
              21.016089425451746
            ],
            [
              105.83992617905061,
              21.01602044067495
            ],
            [
              105.83996041466726,
              21.015958644700046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.3_Ngách 1/34, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5710",
          "diaChiLapD": "Ngách 1/34, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01630032355227,
          "to_latitude": 21.015958644700046,
          "to_longitude": 105.83996041466726,
          "from_longitude": 105.84002860672189
        }
      },
      {
        "id": 5703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83324446774213,
            21.018112909248742,
            105.83343231277797,
            21.018178236590742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83343231277797,
              21.018112909248742
            ],
            [
              105.8333162436914,
              21.01815171407102
            ],
            [
              105.83324446774213,
              21.018178236590742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5711",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018112909248742,
          "to_latitude": 21.018178236590742,
          "to_longitude": 105.83324446774213,
          "from_longitude": 105.83343231277797
        }
      },
      {
        "id": 5704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749112771822,
            21.016143772378644,
            105.83767747886706,
            21.016214506355603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749112771822,
              21.016214506355603
            ],
            [
              105.83753692776601,
              21.01619179692946
            ],
            [
              105.83761003699469,
              21.016166455622553
            ],
            [
              105.83764001122583,
              21.016156155804236
            ],
            [
              105.8376459148613,
              21.016154126998664
            ],
            [
              105.83767747886706,
              21.016143772378644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.2_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5712",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016214506355603,
          "to_latitude": 21.016143772378644,
          "to_longitude": 105.83767747886706,
          "from_longitude": 105.83749112771822
        }
      },
      {
        "id": 5705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814892288525,
            21.01217895776165,
            105.83831692249863,
            21.012251397515413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814892288525,
              21.012190390413224
            ],
            [
              105.83816095123164,
              21.01217895776165
            ],
            [
              105.83818056912756,
              21.012189744073133
            ],
            [
              105.83823849137012,
              21.012207238703624
            ],
            [
              105.83831692249863,
              21.012251397515413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.11_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5713",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012190390413224,
          "to_latitude": 21.012251397515413,
          "to_longitude": 105.83831692249863,
          "from_longitude": 105.83814892288525
        }
      },
      {
        "id": 5706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733669386581,
            21.015101322953683,
            105.8374322465167,
            21.015256568488432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83733669386581,
              21.015101322953683
            ],
            [
              105.83737671616235,
              21.01516613372385
            ],
            [
              105.83740586713667,
              21.01521499466808
            ],
            [
              105.83741983759424,
              21.015236930249745
            ],
            [
              105.8374322465167,
              21.015256568488432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.23_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5714",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015101322953683,
          "to_latitude": 21.015256568488432,
          "to_longitude": 105.8374322465167,
          "from_longitude": 105.83733669386581
        }
      },
      {
        "id": 5707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83734053147971,
            21.01572947317925,
            105.83751113852837,
            21.015835123989696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751113852837,
              21.01572947317925
            ],
            [
              105.83749521872409,
              21.015739165465963
            ],
            [
              105.83747731764744,
              21.015751326983477
            ],
            [
              105.8374654202947,
              21.015758714309484
            ],
            [
              105.8374325207496,
              21.01577846718669
            ],
            [
              105.83739160691997,
              21.015803666204675
            ],
            [
              105.83734053147971,
              21.015835123989696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.2_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5715",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01572947317925,
          "to_latitude": 21.015835123989696,
          "to_longitude": 105.83734053147971,
          "from_longitude": 105.83751113852837
        }
      },
      {
        "id": 5708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441660777312,
            21.016800053404065,
            105.83449308152088,
            21.016969860236667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441660777312,
              21.016800053404065
            ],
            [
              105.83444705844296,
              21.016862184083323
            ],
            [
              105.83447781216339,
              21.01693296164368
            ],
            [
              105.83449308152088,
              21.016969860236667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14.2_Ngách 107, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5716",
          "diaChiLapD": "Ngách 107, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016800053404065,
          "to_latitude": 21.016969860236667,
          "to_longitude": 105.83449308152088,
          "from_longitude": 105.83441660777312
        }
      },
      {
        "id": 5709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8406521886183,
            21.016643006433384,
            105.84086178819148,
            21.01664418861427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84086178819148,
              21.016643006433384
            ],
            [
              105.8406521886183,
              21.01664418861427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.4_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5717",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016643006433384,
          "to_latitude": 21.01664418861427,
          "to_longitude": 105.8406521886183,
          "from_longitude": 105.84086178819148
        }
      },
      {
        "id": 5710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83631358076043,
            21.016818739002566,
            105.83649791048764,
            21.016933097446167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83649791048764,
              21.016818739002566
            ],
            [
              105.83648895831003,
              21.0168240271402
            ],
            [
              105.83644573435967,
              21.016849562459928
            ],
            [
              105.83637593048425,
              21.01689244390008
            ],
            [
              105.83631358076043,
              21.016933097446167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.14_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5718",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016818739002566,
          "to_latitude": 21.016933097446167,
          "to_longitude": 105.83631358076043,
          "from_longitude": 105.83649791048764
        }
      },
      {
        "id": 5711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411586208484,
            21.017855913673877,
            105.83418339647761,
            21.018041944813593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418339647761,
              21.018041944813593
            ],
            [
              105.83417909627815,
              21.01803601545808
            ],
            [
              105.8341738916395,
              21.018028838668474
            ],
            [
              105.83416324874608,
              21.018014163783178
            ],
            [
              105.83414265487936,
              21.017944217905843
            ],
            [
              105.83412098865868,
              21.017879842951903
            ],
            [
              105.83411586208484,
              21.017855913673877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5719",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018041944813593,
          "to_latitude": 21.017855913673877,
          "to_longitude": 105.83411586208484,
          "from_longitude": 105.83418339647761
        }
      },
      {
        "id": 5712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778323996822,
            21.014843253715398,
            105.83807476738068,
            21.014946023333422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807476738068,
              21.014888332293406
            ],
            [
              105.83807011983785,
              21.01489358846725
            ],
            [
              105.83802698476018,
              21.014916115996957
            ],
            [
              105.83800655046223,
              21.014918356386882
            ],
            [
              105.83796119700622,
              21.014946023333422
            ],
            [
              105.83788579813277,
              21.014843253715398
            ],
            [
              105.83778323996822,
              21.014908878517907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.12_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5720",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014888332293406,
          "to_latitude": 21.014908878517907,
          "to_longitude": 105.83778323996822,
          "from_longitude": 105.83807476738068
        }
      },
      {
        "id": 5713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83513562380764,
            21.015067955940907,
            105.83518752310785,
            21.01521803083398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83513562380764,
              21.015067955940907
            ],
            [
              105.83515539279401,
              21.015094098475103
            ],
            [
              105.83517578658602,
              21.015121195357576
            ],
            [
              105.83518752310785,
              21.015137257590954
            ],
            [
              105.83515946040082,
              21.015152247974267
            ],
            [
              105.83518206850144,
              21.01521803083398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.4_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5721",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015067955940907,
          "to_latitude": 21.01521803083398,
          "to_longitude": 105.83518206850144,
          "from_longitude": 105.83513562380764
        }
      },
      {
        "id": 5714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832070256815,
            21.012490674269603,
            105.83842275621745,
            21.012839714785805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832070256815,
              21.012839714785805
            ],
            [
              105.83842275621745,
              21.012711819383167
            ],
            [
              105.83840684380225,
              21.01268042584766
            ],
            [
              105.83839720309517,
              21.012663146801664
            ],
            [
              105.83837711526566,
              21.012640060919377
            ],
            [
              105.83839743911484,
              21.012597459559363
            ],
            [
              105.83841345225797,
              21.012576826884032
            ],
            [
              105.83841009756858,
              21.01256314240586
            ],
            [
              105.838394275173,
              21.01253769796264
            ],
            [
              105.8383240724412,
              21.012490674269603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.13_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5722",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012839714785805,
          "to_latitude": 21.012490674269603,
          "to_longitude": 105.8383240724412,
          "from_longitude": 105.83832070256815
        }
      },
      {
        "id": 5715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83512481678551,
            21.018811549891804,
            105.83527185628924,
            21.018921507919753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527185628924,
              21.018921507919753
            ],
            [
              105.83518235616017,
              21.018811549891804
            ],
            [
              105.83512481678551,
              21.018835230406268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5723",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018921507919753,
          "to_latitude": 21.018835230406268,
          "to_longitude": 105.83512481678551,
          "from_longitude": 105.83527185628924
        }
      },
      {
        "id": 5716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83866895345943,
            21.015626096692408,
            105.8387681194826,
            21.01577215478945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83866895345943,
              21.015626096692408
            ],
            [
              105.83873535061592,
              21.0156979206957
            ],
            [
              105.83875959134896,
              21.0157256051266
            ],
            [
              105.8387681194826,
              21.015735339576228
            ],
            [
              105.83873367245712,
              21.015762505861908
            ],
            [
              105.83872144055533,
              21.01577215478945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.17_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5724",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015626096692408,
          "to_latitude": 21.01577215478945,
          "to_longitude": 105.83872144055533,
          "from_longitude": 105.83866895345943
        }
      },
      {
        "id": 5717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559363433393,
            21.01520148711641,
            105.83573575393521,
            21.01535108444927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559363433393,
              21.01535108444927
            ],
            [
              105.83560461482057,
              21.015330191324253
            ],
            [
              105.83560874540959,
              21.01532242859675
            ],
            [
              105.83562950566035,
              21.0152935535125
            ],
            [
              105.83564702257513,
              21.015276476015995
            ],
            [
              105.8356767257704,
              21.015249302029837
            ],
            [
              105.83573575393521,
              21.01520148711641
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.10_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5725",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01535108444927,
          "to_latitude": 21.01520148711641,
          "to_longitude": 105.83573575393521,
          "from_longitude": 105.83559363433393
        }
      },
      {
        "id": 5718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612115585775,
            21.0183341476804,
            105.83631418156742,
            21.018413675225005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612115585775,
              21.018413675225005
            ],
            [
              105.8362260723078,
              21.018370018822264
            ],
            [
              105.83623935084596,
              21.018372385026478
            ],
            [
              105.83631418156742,
              21.0183341476804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5726",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018413675225005,
          "to_latitude": 21.0183341476804,
          "to_longitude": 105.83631418156742,
          "from_longitude": 105.83612115585775
        }
      },
      {
        "id": 5719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84106253949058,
            21.015254796547378,
            105.8412762603466,
            21.015256071021113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84106253949058,
              21.015254796547378
            ],
            [
              105.84110766129633,
              21.015256071021113
            ],
            [
              105.84114272291998,
              21.01525592206406
            ],
            [
              105.8412762603466,
              21.01525505766254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.1_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5727",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015254796547378,
          "to_latitude": 21.01525505766254,
          "to_longitude": 105.8412762603466,
          "from_longitude": 105.84106253949058
        }
      },
      {
        "id": 5720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83564630893657,
            21.017656373356417,
            105.83568248611132,
            21.017845642559877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568248611132,
              21.017845642559877
            ],
            [
              105.83567267437368,
              21.01777799591457
            ],
            [
              105.83564699085949,
              21.017687628953077
            ],
            [
              105.83564630893657,
              21.01768522972651
            ],
            [
              105.83565826295573,
              21.017679854627566
            ],
            [
              105.83565167455528,
              21.017656373356417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5728",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017845642559877,
          "to_latitude": 21.017656373356417,
          "to_longitude": 105.83565167455528,
          "from_longitude": 105.83568248611132
        }
      },
      {
        "id": 5721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83739199331656,
            21.01514902733554,
            105.83749346435667,
            21.01531176755872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749346435667,
              21.01531176755872
            ],
            [
              105.8374588905856,
              21.015256128434267
            ],
            [
              105.83742971866008,
              21.01521006669481
            ],
            [
              105.83739199331656,
              21.01514902733554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.24_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5729",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01531176755872,
          "to_latitude": 21.01514902733554,
          "to_longitude": 105.83739199331656,
          "from_longitude": 105.83749346435667
        }
      },
      {
        "id": 5722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798134321951,
            21.015187604856614,
            105.83815138368576,
            21.015297885227437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815138368576,
              21.015187604856614
            ],
            [
              105.83812447611132,
              21.01520475234839
            ],
            [
              105.83811457171684,
              21.015211063192567
            ],
            [
              105.8380873565727,
              21.01522223104437
            ],
            [
              105.83805210618205,
              21.015245585029735
            ],
            [
              105.83800446026252,
              21.015279779139988
            ],
            [
              105.83798134321951,
              21.015297885227437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.1_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5730",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015187604856614,
          "to_latitude": 21.015297885227437,
          "to_longitude": 105.83798134321951,
          "from_longitude": 105.83815138368576
        }
      },
      {
        "id": 5723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371741951061,
            21.014806867682772,
            105.83728973814259,
            21.014980014396663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371741951061,
              21.014806867682772
            ],
            [
              105.83719319131778,
              21.01483289274228
            ],
            [
              105.83720949259867,
              21.014855713776218
            ],
            [
              105.83722200155115,
              21.014875705604428
            ],
            [
              105.8372451559673,
              21.01491099353328
            ],
            [
              105.83726443345527,
              21.014941564933398
            ],
            [
              105.83728271223984,
              21.01496933854129
            ],
            [
              105.83728973814259,
              21.014980014396663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.20_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5731",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014806867682772,
          "to_latitude": 21.014980014396663,
          "to_longitude": 105.83728973814259,
          "from_longitude": 105.8371741951061
        }
      },
      {
        "id": 5724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83747054669392,
            21.013298545710526,
            105.83760449600517,
            21.013457706461942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83747054669392,
              21.013298545710526
            ],
            [
              105.83748131490869,
              21.01331017410784
            ],
            [
              105.83749520636998,
              21.013325745059944
            ],
            [
              105.83750078386325,
              21.013331996821346
            ],
            [
              105.83753386387194,
              21.01337343559652
            ],
            [
              105.83757243262826,
              21.013420560238586
            ],
            [
              105.83760449600517,
              21.013457706461942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.9_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5732",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013298545710526,
          "to_latitude": 21.013457706461942,
          "to_longitude": 105.83760449600517,
          "from_longitude": 105.83747054669392
        }
      },
      {
        "id": 5725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83639327843382,
            21.01500354060056,
            105.83654297182547,
            21.015166431835414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83639327843382,
              21.01500354060056
            ],
            [
              105.83639360761218,
              21.015003921046162
            ],
            [
              105.83640194037761,
              21.01501350032346
            ],
            [
              105.83640309577795,
              21.01501472935096
            ],
            [
              105.83645688479776,
              21.01507194339212
            ],
            [
              105.83654297182547,
              21.015166431835414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.1_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5733",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01500354060056,
          "to_latitude": 21.015166431835414,
          "to_longitude": 105.83654297182547,
          "from_longitude": 105.83639327843382
        }
      },
      {
        "id": 5726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84071735184787,
            21.015818900949114,
            105.8408924693229,
            21.016013727339082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8408924693229,
              21.015818900949114
            ],
            [
              105.84078266241099,
              21.015820632300926
            ],
            [
              105.84077719953237,
              21.016001670704544
            ],
            [
              105.84072491961041,
              21.016005868025697
            ],
            [
              105.84072211413495,
              21.0160088742691
            ],
            [
              105.84071735184787,
              21.016013727339082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.1_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5734",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015818900949114,
          "to_latitude": 21.016013727339082,
          "to_longitude": 105.84071735184787,
          "from_longitude": 105.8408924693229
        }
      },
      {
        "id": 5727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337342583966,
            21.01891531565794,
            105.83379774076965,
            21.019129320678626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337342583966,
              21.01891531565794
            ],
            [
              105.83373850843412,
              21.018930907964208
            ],
            [
              105.83375687653933,
              21.01899830234584
            ],
            [
              105.83376694783692,
              21.01902989293204
            ],
            [
              105.83378395000473,
              21.019086736214813
            ],
            [
              105.83379774076965,
              21.019129320678626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5735",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01891531565794,
          "to_latitude": 21.019129320678626,
          "to_longitude": 105.83379774076965,
          "from_longitude": 105.8337342583966
        }
      },
      {
        "id": 5728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374212241124,
            21.018255480153467,
            105.83379034494773,
            21.01841688779339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375816057003,
              21.01841688779339
            ],
            [
              105.83374212241124,
              21.01837977703699
            ],
            [
              105.83379034494773,
              21.018356721621032
            ],
            [
              105.8337878739591,
              21.01831282166994
            ],
            [
              105.83376918781138,
              21.01826381939621
            ],
            [
              105.83376600836709,
              21.018255480153467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.1_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5736",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01841688779339,
          "to_latitude": 21.018255480153467,
          "to_longitude": 105.83376600836709,
          "from_longitude": 105.83375816057003
        }
      },
      {
        "id": 5729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608934264159,
            21.014339980105255,
            105.83637376567007,
            21.014676449826215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83637376567007,
              21.014676449826215
            ],
            [
              105.83637311683839,
              21.0146758442426
            ],
            [
              105.8363441856668,
              21.01464872895867
            ],
            [
              105.83631498592995,
              21.014618300156087
            ],
            [
              105.83629273093466,
              21.01459556339315
            ],
            [
              105.83626939701988,
              21.014570820449507
            ],
            [
              105.83625161314168,
              21.01455154453702
            ],
            [
              105.83624015942671,
              21.014540324980416
            ],
            [
              105.83619585535233,
              21.01449036681198
            ],
            [
              105.83617093792945,
              21.01446386131645
            ],
            [
              105.83608934264159,
              21.014382289276007
            ],
            [
              105.83613793140414,
              21.014339980105255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.4_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5737",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014676449826215,
          "to_latitude": 21.014339980105255,
          "to_longitude": 105.83613793140414,
          "from_longitude": 105.83637376567007
        }
      },
      {
        "id": 5730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361643015072,
            21.01728235893606,
            105.83637952177943,
            21.017340498883026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361643015072,
              21.017340498883026
            ],
            [
              105.83633518888443,
              21.017294325127647
            ],
            [
              105.83634369314836,
              21.01729202706484
            ],
            [
              105.83637949488597,
              21.01728236629407
            ],
            [
              105.83637952177943,
              21.01728235893606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.7_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5738",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017340498883026,
          "to_latitude": 21.01728235893606,
          "to_longitude": 105.83637952177943,
          "from_longitude": 105.8361643015072
        }
      },
      {
        "id": 5731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869329273517,
            21.012180014365004,
            105.83882670707818,
            21.01224839928394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882670707818,
              21.012232448218672
            ],
            [
              105.83882107962012,
              21.01224427206945
            ],
            [
              105.83881935297495,
              21.01224839928394
            ],
            [
              105.83872413559001,
              21.012194476502607
            ],
            [
              105.83869329273517,
              21.012180014365004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.17_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5739",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012232448218672,
          "to_latitude": 21.012180014365004,
          "to_longitude": 105.83869329273517,
          "from_longitude": 105.83882670707818
        }
      },
      {
        "id": 5732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366746718842,
            21.018224144040193,
            105.83686350988732,
            21.018275289609544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83686350988732,
              21.018224144040193
            ],
            [
              105.83668786462316,
              21.018275289609544
            ],
            [
              105.8366746718842,
              21.018239504635343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.6_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5740",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018224144040193,
          "to_latitude": 21.018239504635343,
          "to_longitude": 105.8366746718842,
          "from_longitude": 105.83686350988732
        }
      },
      {
        "id": 5733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352988491536,
            21.018455075073323,
            105.83539731811358,
            21.018632501394084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352988491536,
              21.018455075073323
            ],
            [
              105.83539731811358,
              21.018632501394084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.3_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5741",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018455075073323,
          "to_latitude": 21.018632501394084,
          "to_longitude": 105.83539731811358,
          "from_longitude": 105.8352988491536
        }
      },
      {
        "id": 5734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617612941843,
            21.01731645969687,
            105.83639431333917,
            21.017371866623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617612941843,
              21.017371866623
            ],
            [
              105.83635690135922,
              21.01732595948454
            ],
            [
              105.836361410355,
              21.017324814609147
            ],
            [
              105.83639428644064,
              21.017316466151687
            ],
            [
              105.83639431333917,
              21.01731645969687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.6_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5742",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017371866623,
          "to_latitude": 21.01731645969687,
          "to_longitude": 105.83639431333917,
          "from_longitude": 105.83617612941843
        }
      },
      {
        "id": 5735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84005572204187,
            21.01746960920683,
            105.84026790746374,
            21.017470807071156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84005572204187,
              21.017470807071156
            ],
            [
              105.84024985901513,
              21.017469710998903
            ],
            [
              105.84026790746374,
              21.01746960920683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.2_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5743",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017470807071156,
          "to_latitude": 21.01746960920683,
          "to_longitude": 105.84026790746374,
          "from_longitude": 105.84005572204187
        }
      },
      {
        "id": 5736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326549972715,
            21.016083588248126,
            105.83339528705562,
            21.01624972637522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83326549972715,
              21.01624972637522
            ],
            [
              105.83331473956865,
              21.016188090297785
            ],
            [
              105.83339087600095,
              21.01608954675272
            ],
            [
              105.83339528705562,
              21.016083588248126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28.1_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5744",
          "diaChiLapD": "Ngách 216/8, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01624972637522,
          "to_latitude": 21.016083588248126,
          "to_longitude": 105.83339528705562,
          "from_longitude": 105.83326549972715
        }
      },
      {
        "id": 5737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611625000249,
            21.015254870527297,
            105.83628628365913,
            21.015458684661617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611625000249,
              21.015254870527297
            ],
            [
              105.83614981668198,
              21.015293350916274
            ],
            [
              105.83616290012894,
              21.01530787820327
            ],
            [
              105.83617057197822,
              21.01531606435496
            ],
            [
              105.83618133385113,
              21.015330859566326
            ],
            [
              105.83620031325364,
              21.015353196955843
            ],
            [
              105.83621296797912,
              21.01537019214822
            ],
            [
              105.8362479654326,
              21.015412009219837
            ],
            [
              105.83625601175522,
              21.015421624233927
            ],
            [
              105.83628628365913,
              21.015458684661617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5745",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015254870527297,
          "to_latitude": 21.015458684661617,
          "to_longitude": 105.83628628365913,
          "from_longitude": 105.83611625000249
        }
      },
      {
        "id": 5738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83762633350995,
            21.01338645205179,
            105.83783838090547,
            21.013488189355677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83762633350995,
              21.013488189355677
            ],
            [
              105.83765426842524,
              21.013468502441366
            ],
            [
              105.83768616241714,
              21.013446025841613
            ],
            [
              105.83774162811689,
              21.01342472298092
            ],
            [
              105.83775849875494,
              21.013418775278232
            ],
            [
              105.83779222658556,
              21.013406884450248
            ],
            [
              105.83783838090547,
              21.01338645205179
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.13_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5746",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013488189355677,
          "to_latitude": 21.01338645205179,
          "to_longitude": 105.83783838090547,
          "from_longitude": 105.83762633350995
        }
      },
      {
        "id": 5739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002812550547,
            21.01735226700716,
            105.84004117615049,
            21.017636326528244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002812550547,
              21.01735226700716
            ],
            [
              105.8400281696279,
              21.01738625057209
            ],
            [
              105.84002884797926,
              21.017420460423867
            ],
            [
              105.84003782598076,
              21.017458099476443
            ],
            [
              105.8400399764065,
              21.017467114769556
            ],
            [
              105.84004117615049,
              21.017499609534564
            ],
            [
              105.84004039401266,
              21.01754167380299
            ],
            [
              105.8400368522385,
              21.017636326528244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.4_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5747",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735226700716,
          "to_latitude": 21.017636326528244,
          "to_longitude": 105.8400368522385,
          "from_longitude": 105.84002812550547
        }
      },
      {
        "id": 5740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575409524316,
            21.015657457598394,
            105.83587630436386,
            21.015844206707715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575409524316,
              21.015657457598394
            ],
            [
              105.8357607699831,
              21.01567400620179
            ],
            [
              105.83576660034554,
              21.015687483473922
            ],
            [
              105.83577219777406,
              21.0157000713109
            ],
            [
              105.83578031170507,
              21.015715952586135
            ],
            [
              105.83578718213762,
              21.015728259588467
            ],
            [
              105.83579408260607,
              21.01573680722311
            ],
            [
              105.83580250346735,
              21.01574976596167
            ],
            [
              105.83581112516438,
              21.01576234164916
            ],
            [
              105.83583183591088,
              21.01578862308262
            ],
            [
              105.83585951108911,
              21.015822326413183
            ],
            [
              105.83587630436386,
              21.015844206707715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.18_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5748",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015657457598394,
          "to_latitude": 21.015844206707715,
          "to_longitude": 105.83587630436386,
          "from_longitude": 105.83575409524316
        }
      },
      {
        "id": 5741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793936673896,
            21.01611189770187,
            105.83805555131048,
            21.016303622681306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83805555131048,
              21.016303622681306
            ],
            [
              105.83802870098881,
              21.016258916202453
            ],
            [
              105.8379806464324,
              21.01618386829275
            ],
            [
              105.83793936673896,
              21.01611189770187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.31_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5749",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016303622681306,
          "to_latitude": 21.01611189770187,
          "to_longitude": 105.83793936673896,
          "from_longitude": 105.83805555131048
        }
      },
      {
        "id": 5742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783838090547,
            21.01338645205179,
            105.83812890590976,
            21.013682521540083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783838090547,
              21.01338645205179
            ],
            [
              105.83786104399078,
              21.013411290213746
            ],
            [
              105.8378846794538,
              21.013438171193176
            ],
            [
              105.83790857120003,
              21.01347097967075
            ],
            [
              105.8379153595762,
              21.013479778869424
            ],
            [
              105.83794348942281,
              21.013512906082088
            ],
            [
              105.83796116513031,
              21.013533466734852
            ],
            [
              105.83798375626975,
              21.013559021489964
            ],
            [
              105.83801449431864,
              21.013594286420606
            ],
            [
              105.83803370154375,
              21.013615556681795
            ],
            [
              105.83805356430192,
              21.01363518706946
            ],
            [
              105.83808576780723,
              21.013661917428216
            ],
            [
              105.83809270713058,
              21.01366760065533
            ],
            [
              105.83812890590976,
              21.013682521540083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.13_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5750",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01338645205179,
          "to_latitude": 21.013682521540083,
          "to_longitude": 105.83812890590976,
          "from_longitude": 105.83783838090547
        }
      },
      {
        "id": 5743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863632083222,
            21.01372438758897,
            105.83881659013247,
            21.013881274110812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863632083222,
              21.01372438758897
            ],
            [
              105.83872527523255,
              21.0137974254783
            ],
            [
              105.83881659013247,
              21.013881274110812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.19_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5751",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01372438758897,
          "to_latitude": 21.013881274110812,
          "to_longitude": 105.83881659013247,
          "from_longitude": 105.83863632083222
        }
      },
      {
        "id": 5744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83195687337086,
            21.017941179838218,
            105.8321493091831,
            21.01801529691532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195687337086,
              21.018012178046956
            ],
            [
              105.83196102414698,
              21.018009047944155
            ],
            [
              105.83198493831844,
              21.01801529691532
            ],
            [
              105.83202091001445,
              21.018002199448475
            ],
            [
              105.83208562527581,
              21.01796404050692
            ],
            [
              105.83211794989029,
              21.017941179838218
            ],
            [
              105.8321493091831,
              21.017973885756756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25.4_Ngách 298/24, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5752",
          "diaChiLapD": "Ngách 298/24, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018012178046956,
          "to_latitude": 21.017973885756756,
          "to_longitude": 105.8321493091831,
          "from_longitude": 105.83195687337086
        }
      },
      {
        "id": 5745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661637158865,
            21.018085036584097,
            105.83668744361508,
            21.018249705087683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666412136353,
              21.018085036584097
            ],
            [
              105.83668744361508,
              21.01814861361769
            ],
            [
              105.83661637158865,
              21.018171876152408
            ],
            [
              105.83664448402423,
              21.018249705087683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.6A_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5753",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018085036584097,
          "to_latitude": 21.018249705087683,
          "to_longitude": 105.83664448402423,
          "from_longitude": 105.83666412136353
        }
      },
      {
        "id": 5746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530581272278,
            21.013944247007426,
            105.83545073840799,
            21.014125088089727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83545073840799,
              21.014125088089727
            ],
            [
              105.83543493299118,
              21.01410539862989
            ],
            [
              105.83541665807051,
              21.01408238387932
            ],
            [
              105.83539818752965,
              21.01405826905626
            ],
            [
              105.83538284474933,
              21.014038740803116
            ],
            [
              105.83537497222109,
              21.014028872889472
            ],
            [
              105.83535977253487,
              21.01400981993079
            ],
            [
              105.835335277607,
              21.01398054745992
            ],
            [
              105.83531858863768,
              21.013959552650345
            ],
            [
              105.8353120268496,
              21.01395169068274
            ],
            [
              105.83530581272278,
              21.013944247007426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.2_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5754",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014125088089727,
          "to_latitude": 21.013944247007426,
          "to_longitude": 105.83530581272278,
          "from_longitude": 105.83545073840799
        }
      },
      {
        "id": 5747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953881567922,
            21.0167110471645,
            105.83969006293692,
            21.016876521840768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953881567922,
              21.0167110471645
            ],
            [
              105.83958660207458,
              21.016765860104083
            ],
            [
              105.83965876370453,
              21.01684413408404
            ],
            [
              105.83966703373231,
              21.016853104727403
            ],
            [
              105.83969006293692,
              21.016876521840768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5755",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0167110471645,
          "to_latitude": 21.016876521840768,
          "to_longitude": 105.83969006293692,
          "from_longitude": 105.83953881567922
        }
      },
      {
        "id": 5748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83974057783466,
            21.01662127080573,
            105.83997906706762,
            21.016621742717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83997906706762,
              21.016621742717
            ],
            [
              105.83977605849984,
              21.01662134134255
            ],
            [
              105.83974057783466,
              21.01662127080573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72A.3_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5756",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016621742717,
          "to_latitude": 21.01662127080573,
          "to_longitude": 105.83974057783466,
          "from_longitude": 105.83997906706762
        }
      },
      {
        "id": 5749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83683694056836,
            21.01283120882543,
            105.83701342697732,
            21.012972819858632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83683694056836,
              21.01283120882543
            ],
            [
              105.83688339136037,
              21.012873066103875
            ],
            [
              105.83693356336326,
              21.012913630614765
            ],
            [
              105.83696456912098,
              21.01293849922157
            ],
            [
              105.83701342697732,
              21.012972819858632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.3_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5757",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01283120882543,
          "to_latitude": 21.012972819858632,
          "to_longitude": 105.83701342697732,
          "from_longitude": 105.83683694056836
        }
      },
      {
        "id": 5750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83651298962401,
            21.014760296140892,
            105.83670166572759,
            21.014909994003485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83651298962401,
              21.014909994003485
            ],
            [
              105.83658487119362,
              21.014847950339927
            ],
            [
              105.8365889644574,
              21.01484488995935
            ],
            [
              105.83660224528741,
              21.01483495963785
            ],
            [
              105.83666942662221,
              21.014786682513634
            ],
            [
              105.83670166572759,
              21.014760296140892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.2_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5758",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014909994003485,
          "to_latitude": 21.014760296140892,
          "to_longitude": 105.83670166572759,
          "from_longitude": 105.83651298962401
        }
      },
      {
        "id": 5751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610703845537,
            21.015705264354363,
            105.8362480085786,
            21.01589785335496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83610703845537,
              21.015705264354363
            ],
            [
              105.83612294082059,
              21.015727285389485
            ],
            [
              105.8361329451959,
              21.015739923812838
            ],
            [
              105.83614478245786,
              21.015754876320152
            ],
            [
              105.83616273639494,
              21.015779785714802
            ],
            [
              105.83616353192423,
              21.01578082231924
            ],
            [
              105.83618289008626,
              21.0158060391319
            ],
            [
              105.83620000677249,
              21.01582881018922
            ],
            [
              105.83621761869978,
              21.01585431287028
            ],
            [
              105.83623697735257,
              21.015881512250903
            ],
            [
              105.8362480085786,
              21.01589785335496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.12_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5759",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015705264354363,
          "to_latitude": 21.01589785335496,
          "to_longitude": 105.8362480085786,
          "from_longitude": 105.83610703845537
        }
      },
      {
        "id": 5752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743312535682,
            21.013566632177756,
            105.8376539342928,
            21.013750568763033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743312535682,
              21.013750568763033
            ],
            [
              105.83747844469896,
              21.013717008590543
            ],
            [
              105.83750586252951,
              21.013697559986106
            ],
            [
              105.83753059262318,
              21.01367774615697
            ],
            [
              105.83754742505012,
              21.01366580938225
            ],
            [
              105.83760982123292,
              21.01361034682643
            ],
            [
              105.83761255910713,
              21.013607633610494
            ],
            [
              105.8376539342928,
              21.013566632177756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5760",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013750568763033,
          "to_latitude": 21.013566632177756,
          "to_longitude": 105.8376539342928,
          "from_longitude": 105.83743312535682
        }
      },
      {
        "id": 5753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83649273659204,
            21.01680759968946,
            105.8366046148366,
            21.017028229253555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83649273659204,
              21.01680759968946
            ],
            [
              105.83649289725786,
              21.016807948447195
            ],
            [
              105.83649791048764,
              21.016818739002566
            ],
            [
              105.8365386552248,
              21.016906468423716
            ],
            [
              105.83654214618565,
              21.01691889768572
            ],
            [
              105.83654233344869,
              21.016932810932296
            ],
            [
              105.8366046148366,
              21.017028229253555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.14_Ngách 178/80/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5761",
          "diaChiLapD": "Ngách 178/80/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01680759968946,
          "to_latitude": 21.017028229253555,
          "to_longitude": 105.8366046148366,
          "from_longitude": 105.83649273659204
        }
      },
      {
        "id": 5754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392917081804,
            21.0161527265958,
            105.83935941715939,
            21.01637997240312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392917081804,
              21.0161527265958
            ],
            [
              105.83929481761415,
              21.01616260516834
            ],
            [
              105.8393001072117,
              21.016176854523373
            ],
            [
              105.83930646583872,
              21.01620921004598
            ],
            [
              105.83930981920844,
              21.01623208209414
            ],
            [
              105.83931437494225,
              21.01626010019824
            ],
            [
              105.83931746620378,
              21.016272231499705
            ],
            [
              105.83931958964982,
              21.016280562294423
            ],
            [
              105.83932338077405,
              21.01629657039191
            ],
            [
              105.83932382706395,
              21.01629845231473
            ],
            [
              105.83932940588431,
              21.016317799842472
            ],
            [
              105.83933817187379,
              21.01633788132116
            ],
            [
              105.83934264494529,
              21.016348719614
            ],
            [
              105.83935941715939,
              21.01637997240312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.6_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5762",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0161527265958,
          "to_latitude": 21.01637997240312,
          "to_longitude": 105.83935941715939,
          "from_longitude": 105.8392917081804
        }
      },
      {
        "id": 5755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809457721264,
            21.01406258311073,
            105.83823061878506,
            21.01426661421562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809457721264,
              21.01406258311073
            ],
            [
              105.83811920597338,
              21.014088682385747
            ],
            [
              105.8381680661501,
              21.014138281596125
            ],
            [
              105.83815866885097,
              21.014174735951602
            ],
            [
              105.83822581839988,
              21.01425398188994
            ],
            [
              105.83823042062217,
              21.014266096741284
            ],
            [
              105.83823061878506,
              21.01426661421562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.3_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5763",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01406258311073,
          "to_latitude": 21.01426661421562,
          "to_longitude": 105.83823061878506,
          "from_longitude": 105.83809457721264
        }
      },
      {
        "id": 5756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584259082164,
            21.01578183703054,
            105.83598514533173,
            21.01596737119042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584259082164,
              21.015785769345047
            ],
            [
              105.83584611978903,
              21.01578345691402
            ],
            [
              105.83584859159866,
              21.01578183703054
            ],
            [
              105.83586586561746,
              21.015798672262946
            ],
            [
              105.83589585926147,
              21.01582964729743
            ],
            [
              105.83589909645464,
              21.01583332106335
            ],
            [
              105.83592758386368,
              21.015864667491826
            ],
            [
              105.83593749399995,
              21.015877485228135
            ],
            [
              105.83595050177502,
              21.015894307994444
            ],
            [
              105.83596202799848,
              21.015920420475936
            ],
            [
              105.83596649614861,
              21.01593663488072
            ],
            [
              105.83597297330833,
              21.015947930389288
            ],
            [
              105.83598514533173,
              21.01596737119042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.17_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5764",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015785769345047,
          "to_latitude": 21.01596737119042,
          "to_longitude": 105.83598514533173,
          "from_longitude": 105.83584259082164
        }
      },
      {
        "id": 5757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344493103141,
            21.016290828235014,
            105.83361850957371,
            21.01644595093292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344493103141,
              21.01644595093292
            ],
            [
              105.83344643838373,
              21.016445948967647
            ],
            [
              105.83345063380915,
              21.016445847123702
            ],
            [
              105.83346909223494,
              21.01641221363783
            ],
            [
              105.83347134875756,
              21.016409062973636
            ],
            [
              105.83349941999322,
              21.016375107808926
            ],
            [
              105.83354814055414,
              21.01632234026404
            ],
            [
              105.83358065122519,
              21.016290828235014
            ],
            [
              105.83361850957371,
              21.016302113684816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27.3_Ngõ 216, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5765",
          "diaChiLapD": "Ngõ 216, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01644595093292,
          "to_latitude": 21.016302113684816,
          "to_longitude": 105.83361850957371,
          "from_longitude": 105.83344493103141
        }
      },
      {
        "id": 5758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936504834904,
            21.016392346784116,
            105.83947883942325,
            21.016615816395174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936504834904,
              21.016392346784116
            ],
            [
              105.83937277869026,
              21.01640406861584
            ],
            [
              105.8393800908792,
              21.016415154834405
            ],
            [
              105.83939444710053,
              21.0164433829018
            ],
            [
              105.83941297218384,
              21.016479138638147
            ],
            [
              105.83942840360638,
              21.016506116754698
            ],
            [
              105.83945216651982,
              21.016556832927964
            ],
            [
              105.83946795172768,
              21.016591333147456
            ],
            [
              105.83947883942325,
              21.016615816395174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.4_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5766",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016392346784116,
          "to_latitude": 21.016615816395174,
          "to_longitude": 105.83947883942325,
          "from_longitude": 105.83936504834904
        }
      },
      {
        "id": 5759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360894390951,
            21.01822423675591,
            105.83630615969952,
            21.01834307164085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360894390951,
              21.01834307164085
            ],
            [
              105.83614974664792,
              21.018317325076968
            ],
            [
              105.83614913220948,
              21.018308570436886
            ],
            [
              105.83630615969952,
              21.01822423675591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5767",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01834307164085,
          "to_latitude": 21.01822423675591,
          "to_longitude": 105.83630615969952,
          "from_longitude": 105.8360894390951
        }
      },
      {
        "id": 5760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730758950851,
            21.01313877882383,
            105.83747651230675,
            21.013326763784434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730758950851,
              21.01313877882383
            ],
            [
              105.83732487634218,
              21.013158110366252
            ],
            [
              105.83734573492943,
              21.013186772701022
            ],
            [
              105.83735538862662,
              21.01319639872416
            ],
            [
              105.83735815801705,
              21.01319915980001
            ],
            [
              105.83737392024467,
              21.013214876910553
            ],
            [
              105.83740309991711,
              21.013246894408294
            ],
            [
              105.83741869498031,
              21.01326402588315
            ],
            [
              105.83744855903245,
              21.013296810454314
            ],
            [
              105.83747651230675,
              21.013326763784434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.8_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5768",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01313877882383,
          "to_latitude": 21.013326763784434,
          "to_longitude": 105.83747651230675,
          "from_longitude": 105.83730758950851
        }
      },
      {
        "id": 5761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379515013354,
            21.016089121851035,
            105.83807523045209,
            21.01629841620034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379515013354,
              21.016089121851035
            ],
            [
              105.83800256427678,
              21.016177851440172
            ],
            [
              105.83803039412624,
              21.01622708728321
            ],
            [
              105.83807523045209,
              21.01629841620034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.32_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5769",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016089121851035,
          "to_latitude": 21.01629841620034,
          "to_longitude": 105.83807523045209,
          "from_longitude": 105.8379515013354
        }
      },
      {
        "id": 5762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824222044669,
            21.014918125731594,
            105.83836429890151,
            21.015133559131968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824222044669,
              21.014918125731594
            ],
            [
              105.8382666647669,
              21.01496902570854
            ],
            [
              105.838277598247,
              21.01498988237669
            ],
            [
              105.83830504910895,
              21.015028819686044
            ],
            [
              105.83831209868949,
              21.015037866867395
            ],
            [
              105.83832106403132,
              21.015048217896293
            ],
            [
              105.838328486856,
              21.01506329135469
            ],
            [
              105.83833266329181,
              21.015077355650195
            ],
            [
              105.83833489731589,
              21.015084314821497
            ],
            [
              105.83834165123871,
              21.015105347434226
            ],
            [
              105.83836429890151,
              21.015133559131968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.13_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5770",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014918125731594,
          "to_latitude": 21.015133559131968,
          "to_longitude": 105.83836429890151,
          "from_longitude": 105.83824222044669
        }
      },
      {
        "id": 5763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036086995258,
            21.017179013388013,
            105.84038199472009,
            21.01743512781552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84038199472009,
              21.017179013388013
            ],
            [
              105.84038144404816,
              21.017225803081473
            ],
            [
              105.84037995027394,
              21.017268401960838
            ],
            [
              105.84037833166028,
              21.017342971049214
            ],
            [
              105.84037760977296,
              21.01735336710384
            ],
            [
              105.84037095059738,
              21.01739360789302
            ],
            [
              105.84036657878582,
              21.017416998588587
            ],
            [
              105.8403665022009,
              21.017417408127105
            ],
            [
              105.84036086995258,
              21.01743422416329
            ],
            [
              105.8403660291158,
              21.01743478215976
            ],
            [
              105.84037193644615,
              21.01743512781552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.3_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5771",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017179013388013,
          "to_latitude": 21.01743512781552,
          "to_longitude": 105.84037193644615,
          "from_longitude": 105.84038199472009
        }
      },
      {
        "id": 5764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859125187892,
            21.012197896241883,
            105.83903199386911,
            21.012584498243328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859125187892,
              21.012197896241883
            ],
            [
              105.83859536432578,
              21.012202589900966
            ],
            [
              105.83863588253747,
              21.012218161399336
            ],
            [
              105.83864807998607,
              21.01223595260424
            ],
            [
              105.8386587401601,
              21.012248901071114
            ],
            [
              105.83866874344085,
              21.012277352102796
            ],
            [
              105.8386817864509,
              21.01232870114269
            ],
            [
              105.83869307346542,
              21.01234451425417
            ],
            [
              105.83873365768578,
              21.01239287424729
            ],
            [
              105.83878398266171,
              21.01243545164077
            ],
            [
              105.83903199386911,
              21.012584498243328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.16_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5772",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012197896241883,
          "to_latitude": 21.012584498243328,
          "to_longitude": 105.83903199386911,
          "from_longitude": 105.83859125187892
        }
      },
      {
        "id": 5765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447651244647,
            21.01550909809958,
            105.83488422605272,
            21.015690503924503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447651244647,
              21.015664542713736
            ],
            [
              105.83447682489727,
              21.015664856406545
            ],
            [
              105.83450251182228,
              21.015690503924503
            ],
            [
              105.83466481755997,
              21.01558575198738
            ],
            [
              105.83467761248492,
              21.015598235906623
            ],
            [
              105.83473921026051,
              21.01565323451441
            ],
            [
              105.83488422605272,
              21.01550909809958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.5_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5773",
          "diaChiLapD": "Ngõ 164, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015664542713736,
          "to_latitude": 21.01550909809958,
          "to_longitude": 105.83488422605272,
          "from_longitude": 105.83447651244647
        }
      },
      {
        "id": 5766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587320432721,
            21.01842312176101,
            105.83612525642712,
            21.018511685064826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612525642712,
              21.01842312176101
            ],
            [
              105.83611658884925,
              21.018426506292517
            ],
            [
              105.8361108622988,
              21.01842874282197
            ],
            [
              105.83608178991723,
              21.018440095626907
            ],
            [
              105.83587320432721,
              21.018511685064826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5774",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01842312176101,
          "to_latitude": 21.018511685064826,
          "to_longitude": 105.83587320432721,
          "from_longitude": 105.83612525642712
        }
      },
      {
        "id": 5767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83412155745711,
            21.0184388981305,
            105.83432828650483,
            21.018523538425683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83412155745711,
              21.0184388981305
            ],
            [
              105.8341232470694,
              21.018442259775735
            ],
            [
              105.83412528005027,
              21.018446304381346
            ],
            [
              105.83416409836413,
              21.018523538425683
            ],
            [
              105.83432828650483,
              21.018474202976947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.5_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5775",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0184388981305,
          "to_latitude": 21.018474202976947,
          "to_longitude": 105.83432828650483,
          "from_longitude": 105.83412155745711
        }
      },
      {
        "id": 5768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83727688907977,
            21.015752683235156,
            105.83749354347586,
            21.015893431428477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749354347586,
              21.015752683235156
            ],
            [
              105.83746054974823,
              21.015773656837
            ],
            [
              105.8374363934033,
              21.015789130555127
            ],
            [
              105.83741737061948,
              21.015800933588746
            ],
            [
              105.83740286659561,
              21.01581024947959
            ],
            [
              105.8373956241693,
              21.01581490150642
            ],
            [
              105.8373667971858,
              21.015832884780476
            ],
            [
              105.83732736490181,
              21.015856049656577
            ],
            [
              105.83730394334485,
              21.015873227736797
            ],
            [
              105.83727688907977,
              21.015893431428477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.1_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5776",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015752683235156,
          "to_latitude": 21.015893431428477,
          "to_longitude": 105.83727688907977,
          "from_longitude": 105.83749354347586
        }
      },
      {
        "id": 5769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377697882832,
            21.015839073152154,
            105.83789794961751,
            21.016041320547455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377697882832,
              21.015839073152154
            ],
            [
              105.83777745350795,
              21.015851047381695
            ],
            [
              105.83780543029368,
              21.01589475390956
            ],
            [
              105.83781543900781,
              21.015910380976152
            ],
            [
              105.83788003167598,
              21.016011373888478
            ],
            [
              105.83789794961751,
              21.016041320547455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.28_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5777",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015839073152154,
          "to_latitude": 21.016041320547455,
          "to_longitude": 105.83789794961751,
          "from_longitude": 105.8377697882832
        }
      },
      {
        "id": 5770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84003002390118,
            21.0168453345502,
            105.84003969067776,
            21.017078617184797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003002390118,
              21.016845344222673
            ],
            [
              105.84003033647862,
              21.0168453345502
            ],
            [
              105.84003858731984,
              21.016845379614807
            ],
            [
              105.84003968580673,
              21.016968686780203
            ],
            [
              105.84003969067776,
              21.017078617184797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5778",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016845344222673,
          "to_latitude": 21.017078617184797,
          "to_longitude": 105.84003969067776,
          "from_longitude": 105.84003002390118
        }
      },
      {
        "id": 5771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84083640766194,
            21.01397966205648,
            105.84097242933996,
            21.014187913534283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84083640766194,
              21.01397966205648
            ],
            [
              105.84093214821729,
              21.014061369583057
            ],
            [
              105.84097242933996,
              21.014187913534283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.9_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5779",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01397966205648,
          "to_latitude": 21.014187913534283,
          "to_longitude": 105.84097242933996,
          "from_longitude": 105.84083640766194
        }
      },
      {
        "id": 5772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8405839739618,
            21.01569474011528,
            105.84059124389124,
            21.015959006366593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84059124389124,
              21.015959006366593
            ],
            [
              105.84058978239298,
              21.015945733492508
            ],
            [
              105.84058996410535,
              21.015872939101726
            ],
            [
              105.8405840860215,
              21.01571010158758
            ],
            [
              105.8405839739618,
              21.01569474011528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.3_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5780",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015959006366593,
          "to_latitude": 21.01569474011528,
          "to_longitude": 105.8405839739618,
          "from_longitude": 105.84059124389124
        }
      },
      {
        "id": 5773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84061230680526,
            21.015250159916995,
            105.84087020672808,
            21.01529961233336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84061230680526,
              21.01529961233336
            ],
            [
              105.8406191739338,
              21.015289636621
            ],
            [
              105.84063451143467,
              21.01528439349541
            ],
            [
              105.8407063365693,
              21.015273148418263
            ],
            [
              105.8407171701283,
              21.015259809368235
            ],
            [
              105.84087020672808,
              21.015250159916995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.5_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5781",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01529961233336,
          "to_latitude": 21.015250159916995,
          "to_longitude": 105.84087020672808,
          "from_longitude": 105.84061230680526
        }
      },
      {
        "id": 5774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319426988796,
            21.018826502654093,
            105.83216052300291,
            21.018998335748694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319426988796,
              21.018998335748694
            ],
            [
              105.83198432841492,
              21.018987377449335
            ],
            [
              105.83201818270608,
              21.01897889587662
            ],
            [
              105.83203096632485,
              21.01897094729492
            ],
            [
              105.83207582063653,
              21.018935597876276
            ],
            [
              105.8320983209907,
              21.01891742061171
            ],
            [
              105.83212723533654,
              21.018899355581322
            ],
            [
              105.83214960438194,
              21.01888490746397
            ],
            [
              105.83215888812362,
              21.018875178576934
            ],
            [
              105.83216052300291,
              21.018854120928413
            ],
            [
              105.83215395253406,
              21.018826502654093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.4_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5782",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018998335748694,
          "to_latitude": 21.018826502654093,
          "to_longitude": 105.83215395253406,
          "from_longitude": 105.8319426988796
        }
      },
      {
        "id": 5775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8327815110777,
            21.016934994550205,
            105.83288382059796,
            21.01709195710237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83280921894443,
              21.016934994550205
            ],
            [
              105.83286865092445,
              21.0169946054602
            ],
            [
              105.83288382059796,
              21.017019858516885
            ],
            [
              105.83282316673844,
              21.017062076829205
            ],
            [
              105.8327815110777,
              21.01709195710237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12.2_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5783",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016934994550205,
          "to_latitude": 21.01709195710237,
          "to_longitude": 105.8327815110777,
          "from_longitude": 105.83280921894443
        }
      },
      {
        "id": 5776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83740916725547,
            21.01431797942254,
            105.83764307570543,
            21.01444904522457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83764307570543,
              21.01431797942254
            ],
            [
              105.83753758471329,
              21.014361353133072
            ],
            [
              105.83746832974433,
              21.014401842358374
            ],
            [
              105.83745926368884,
              21.01441909340547
            ],
            [
              105.83740916725547,
              21.01444904522457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.4_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5784",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01431797942254,
          "to_latitude": 21.01444904522457,
          "to_longitude": 105.83740916725547,
          "from_longitude": 105.83764307570543
        }
      },
      {
        "id": 5777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060214943007,
            21.01717652136197,
            105.84088050883229,
            21.017187083467228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060214943007,
              21.017187083467228
            ],
            [
              105.84077749506893,
              21.017182158762864
            ],
            [
              105.84080187860316,
              21.01717652136197
            ],
            [
              105.84086871955448,
              21.01717792517962
            ],
            [
              105.84087035217368,
              21.01717796046455
            ],
            [
              105.84088050883229,
              21.01717923980612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A3_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5785",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017187083467228,
          "to_latitude": 21.01717923980612,
          "to_longitude": 105.84088050883229,
          "from_longitude": 105.84060214943007
        }
      },
      {
        "id": 5778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83648679575533,
            21.01669516616111,
            105.83669444560778,
            21.01681068014754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83648841732932,
              21.01681068014754
            ],
            [
              105.83648820672946,
              21.016810346990155
            ],
            [
              105.83648679575533,
              21.016808312641384
            ],
            [
              105.83651045063525,
              21.016796533898475
            ],
            [
              105.83657155824054,
              21.01676430624613
            ],
            [
              105.83664864168001,
              21.01672378427837
            ],
            [
              105.83669444560778,
              21.01669516616111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.15_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5786",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01681068014754,
          "to_latitude": 21.01669516616111,
          "to_longitude": 105.83669444560778,
          "from_longitude": 105.83648841732932
        }
      },
      {
        "id": 5779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84005786899893,
            21.017091984022272,
            105.84032918785466,
            21.01710102400758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84005786899893,
              21.01710102400758
            ],
            [
              105.84014855964209,
              21.017100577953908
            ],
            [
              105.84020277069659,
              21.017097258226787
            ],
            [
              105.84023399636091,
              21.017095191879047
            ],
            [
              105.84024362186473,
              21.017094555435786
            ],
            [
              105.84032918785466,
              21.017091984022272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.2_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5787",
          "diaChiLapD": "Ngách 1/49, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01710102400758,
          "to_latitude": 21.017091984022272,
          "to_longitude": 105.84032918785466,
          "from_longitude": 105.84005786899893
        }
      },
      {
        "id": 5780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84026259508357,
            21.016752418855674,
            105.84056451101611,
            21.01676296154889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84026259508357,
              21.01676296154889
            ],
            [
              105.84028511729011,
              21.0167624890163
            ],
            [
              105.84032569253631,
              21.016762406015886
            ],
            [
              105.84036808090546,
              21.01676126811653
            ],
            [
              105.84044568555026,
              21.016757818302196
            ],
            [
              105.84044697134877,
              21.01675776136948
            ],
            [
              105.84045227898984,
              21.01675752560031
            ],
            [
              105.84056451101611,
              21.016752418855674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.7_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5788",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01676296154889,
          "to_latitude": 21.016752418855674,
          "to_longitude": 105.84056451101611,
          "from_longitude": 105.84026259508357
        }
      },
      {
        "id": 5781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368256871537,
            21.0186711781454,
            105.83375017996859,
            21.018913622656733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375017996859,
              21.018913622656733
            ],
            [
              105.83368256871537,
              21.0186711781454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5789",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018913622656733,
          "to_latitude": 21.0186711781454,
          "to_longitude": 105.83368256871537,
          "from_longitude": 105.83375017996859
        }
      },
      {
        "id": 5782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912465756671,
            21.014103913271416,
            105.83912745599855,
            21.014369255331758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912745599855,
              21.014103913271416
            ],
            [
              105.83912465756671,
              21.01420911834123
            ],
            [
              105.83912652728162,
              21.014291093853764
            ],
            [
              105.83912595930212,
              21.014369255331758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.22_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5790",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014103913271416,
          "to_latitude": 21.014369255331758,
          "to_longitude": 105.83912595930212,
          "from_longitude": 105.83912745599855
        }
      },
      {
        "id": 5783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765148073782,
            21.012589931858436,
            105.83780868684141,
            21.012809565256255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83780868684141,
              21.012589931858436
            ],
            [
              105.83776140138554,
              21.012663389082206
            ],
            [
              105.83772156687705,
              21.012716688480328
            ],
            [
              105.83771522233987,
              21.01272517751025
            ],
            [
              105.83768913063614,
              21.012763991971575
            ],
            [
              105.837672543356,
              21.012784841472474
            ],
            [
              105.83765148073782,
              21.012809565256255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.3_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5791",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012589931858436,
          "to_latitude": 21.012809565256255,
          "to_longitude": 105.83765148073782,
          "from_longitude": 105.83780868684141
        }
      },
      {
        "id": 5784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83618524344212,
            21.014679927782144,
            105.83636740842691,
            21.01488950159451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619587176061,
              21.01488950159451
            ],
            [
              105.83618524344212,
              21.0148791595255
            ],
            [
              105.836187600057,
              21.014862227840627
            ],
            [
              105.83619874237819,
              21.014830008353464
            ],
            [
              105.83622568876933,
              21.01479632744808
            ],
            [
              105.8362687063104,
              21.014755950522407
            ],
            [
              105.8362863046676,
              21.014735216310264
            ],
            [
              105.83630575996055,
              21.01471886986482
            ],
            [
              105.83633658792283,
              21.01469730648858
            ],
            [
              105.83636740842691,
              21.014679927782144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.6_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5792",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488950159451,
          "to_latitude": 21.014679927782144,
          "to_longitude": 105.83636740842691,
          "from_longitude": 105.83619587176061
        }
      },
      {
        "id": 5785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83542602890199,
            21.014064202019657,
            105.83559541417819,
            21.01428137790939
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83542602890199,
              21.014064202019657
            ],
            [
              105.83547685448207,
              21.01413109003231
            ],
            [
              105.83550458173545,
              21.0141654064718
            ],
            [
              105.83553006921747,
              21.01419723016707
            ],
            [
              105.83556196630563,
              21.01423618314439
            ],
            [
              105.83559541417819,
              21.01428137790939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.1_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5793",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014064202019657,
          "to_latitude": 21.01428137790939,
          "to_longitude": 105.83559541417819,
          "from_longitude": 105.83542602890199
        }
      },
      {
        "id": 5786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84003247188171,
            21.017621398854605,
            105.84030772062873,
            21.017684650741288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003247188171,
              21.017684650741288
            ],
            [
              105.84003610209976,
              21.017682386498493
            ],
            [
              105.84008114859749,
              21.017654286059962
            ],
            [
              105.84023264430414,
              21.01763958125273
            ],
            [
              105.84025164394274,
              21.017626910023004
            ],
            [
              105.84030772062873,
              21.017621398854605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.6_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5794",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017684650741288,
          "to_latitude": 21.017621398854605,
          "to_longitude": 105.84030772062873,
          "from_longitude": 105.84003247188171
        }
      },
      {
        "id": 5787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84018268965028,
            21.01485932925,
            105.84046205408761,
            21.014906102680374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046205408761,
              21.014895368157045
            ],
            [
              105.84040433255662,
              21.01485932925
            ],
            [
              105.84030142082193,
              21.01489048304496
            ],
            [
              105.84023949377416,
              21.014898406684328
            ],
            [
              105.84018268965028,
              21.014906102680374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.7_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5795",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014895368157045,
          "to_latitude": 21.014906102680374,
          "to_longitude": 105.84018268965028,
          "from_longitude": 105.84046205408761
        }
      },
      {
        "id": 5788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879584573317,
            21.014960122074132,
            105.83900505040205,
            21.015148771145242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879584573317,
              21.014960122074132
            ],
            [
              105.83881372799311,
              21.01497198265873
            ],
            [
              105.83883009077117,
              21.014985285052653
            ],
            [
              105.83885197841683,
              21.015002115293555
            ],
            [
              105.8388648687218,
              21.01501474654853
            ],
            [
              105.83890543063433,
              21.015053469174596
            ],
            [
              105.83900505040205,
              21.015148771145242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.3_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5796",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014960122074132,
          "to_latitude": 21.015148771145242,
          "to_longitude": 105.83900505040205,
          "from_longitude": 105.83879584573317
        }
      },
      {
        "id": 5789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015223165329,
            21.015771121534687,
            105.84027582530031,
            21.016019071582182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015223165329,
              21.016019071582182
            ],
            [
              105.84015999890758,
              21.01600320152119
            ],
            [
              105.84022104506795,
              21.015878470150128
            ],
            [
              105.84027582530031,
              21.015771121534687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.8_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5797",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016019071582182,
          "to_latitude": 21.015771121534687,
          "to_longitude": 105.84027582530031,
          "from_longitude": 105.84015223165329
        }
      },
      {
        "id": 5790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84061847918822,
            21.017002213157557,
            105.84093100055314,
            21.017011979761058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84061847918822,
              21.017011979761058
            ],
            [
              105.84091619017148,
              21.017002676559706
            ],
            [
              105.8409188225552,
              21.0170025949026
            ],
            [
              105.84093100055314,
              21.017002213157557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A2_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5798",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017011979761058,
          "to_latitude": 21.017002213157557,
          "to_longitude": 105.84093100055314,
          "from_longitude": 105.84061847918822
        }
      },
      {
        "id": 5791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84057821830271,
            21.016749773328115,
            105.84087376041605,
            21.016753723746167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84087376041605,
              21.016751083871394
            ],
            [
              105.84067131513517,
              21.016753723746167
            ],
            [
              105.84057821830271,
              21.016749773328115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.3_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5799",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016751083871394,
          "to_latitude": 21.016749773328115,
          "to_longitude": 105.84057821830271,
          "from_longitude": 105.84087376041605
        }
      },
      {
        "id": 5792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363875729579,
            21.013683023859848,
            105.8365236503123,
            21.013878064140382
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.8363875729579,
                21.013683023859848
              ],
              [
                105.83644587353328,
                21.013761743200558
              ],
              [
                105.8364604370546,
                21.013781407057138
              ],
              [
                105.83647201779081,
                21.013797315764
              ],
              [
                105.8364850935603,
                21.013813590633845
              ],
              [
                105.83649716349514,
                21.013828926567292
              ],
              [
                105.83650856288253,
                21.013843323565442
              ]
            ],
            [
              [
                105.83650856288253,
                21.013843323565442
              ],
              [
                105.83651627423652,
                21.013856468648495
              ]
            ],
            [
              [
                105.83651627423652,
                21.013856468648495
              ],
              [
                105.8365236503123,
                21.013868048840006
              ]
            ],
            [
              [
                105.8365236503123,
                21.013868048840006
              ],
              [
                105.83649951043364,
                21.013878064140382
              ]
            ],
            [
              [
                105.83649951043364,
                21.013878064140382
              ],
              [
                105.83649640925763,
                21.013872501936703
              ]
            ],
            [
              [
                105.83649640925763,
                21.013872501936703
              ],
              [
                105.83650517127354,
                21.013868177924216
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5800",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": 29.38,
          "from_latitude": [
            105.83644587353328,
            21.013761743200558
          ],
          "to_latitude": [
            105.83650517127354,
            21.013868177924216
          ],
          "to_longitude": [
            105.83649640925763,
            21.013872501936703
          ],
          "from_longitude": [
            105.8363875729579,
            21.013683023859848
          ]
        }
      },
      {
        "id": 5793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365884317573,
            21.016772078609915,
            105.83673772498345,
            21.01700857528134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365884317573,
              21.016772078609915
            ],
            [
              105.83661225592208,
              21.01681220821373
            ],
            [
              105.83664775156454,
              21.01686737599848
            ],
            [
              105.83665538061143,
              21.016870111376885
            ],
            [
              105.83671246468788,
              21.01696432864017
            ],
            [
              105.83673772498345,
              21.01700857528134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.13_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5801",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016772078609915,
          "to_latitude": 21.01700857528134,
          "to_longitude": 105.83673772498345,
          "from_longitude": 105.8365884317573
        }
      },
      {
        "id": 5794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818439125828,
            21.01328874052607,
            105.83837363596355,
            21.0134930866054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837363596355,
              21.0134930866054
            ],
            [
              105.83837273173488,
              21.0134894682289
            ],
            [
              105.83837096461626,
              21.013482399271844
            ],
            [
              105.83836809542585,
              21.013470907237142
            ],
            [
              105.83836447408883,
              21.013460977873383
            ],
            [
              105.83826584799843,
              21.013382785764676
            ],
            [
              105.8382686869615,
              21.013375959660838
            ],
            [
              105.83818439125828,
              21.01328874052607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.17_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5802",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0134930866054,
          "to_latitude": 21.01328874052607,
          "to_longitude": 105.83818439125828,
          "from_longitude": 105.83837363596355
        }
      },
      {
        "id": 5795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886821369113,
            21.013914291654423,
            105.83907401133399,
            21.01409771247925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886821369113,
              21.013914291654423
            ],
            [
              105.83899561125409,
              21.014027329701626
            ],
            [
              105.83904242062887,
              21.014065708079524
            ],
            [
              105.83906948163225,
              21.014066587139755
            ],
            [
              105.83907401133399,
              21.01409771247925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.21_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5803",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013914291654423,
          "to_latitude": 21.01409771247925,
          "to_longitude": 105.83907401133399,
          "from_longitude": 105.83886821369113
        }
      },
      {
        "id": 5796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84000175377531,
            21.017120529003297,
            105.8400039780027,
            21.017407551986718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84000191992862,
              21.017120529003297
            ],
            [
              105.8400039780027,
              21.01723053688122
            ],
            [
              105.84000175377531,
              21.017288552875772
            ],
            [
              105.84000192546226,
              21.01731920743276
            ],
            [
              105.84000206234667,
              21.017343647984006
            ],
            [
              105.8400024202553,
              21.017407551986718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.5_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5804",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017120529003297,
          "to_latitude": 21.017407551986718,
          "to_longitude": 105.8400024202553,
          "from_longitude": 105.84000191992862
        }
      },
      {
        "id": 5797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84022876330782,
            21.01707273611394,
            105.8405568512285,
            21.017082300726827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84022876330782,
              21.017082300726827
            ],
            [
              105.84024276333191,
              21.01708159209706
            ],
            [
              105.84027392117461,
              21.017080298333127
            ],
            [
              105.8403322634543,
              21.017078810670768
            ],
            [
              105.84037481627884,
              21.01707782188992
            ],
            [
              105.84039365876764,
              21.017078149710606
            ],
            [
              105.84043135152247,
              21.017078304921505
            ],
            [
              105.8405568512285,
              21.01707273611394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.1_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5805",
          "diaChiLapD": "Ngách 1/49, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017082300726827,
          "to_latitude": 21.01707273611394,
          "to_longitude": 105.8405568512285,
          "from_longitude": 105.84022876330782
        }
      },
      {
        "id": 5798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83672788886639,
            21.01455240119781,
            105.83696679218706,
            21.0147337298011
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696679218706,
              21.01455240119781
            ],
            [
              105.83687490078108,
              21.01462546613475
            ],
            [
              105.83672788886639,
              21.0147337298011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.5_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5806",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01455240119781,
          "to_latitude": 21.0147337298011,
          "to_longitude": 105.83672788886639,
          "from_longitude": 105.83696679218706
        }
      },
      {
        "id": 5799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84056562401045,
            21.0170756264371,
            105.84058349196935,
            21.017378220237926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84057851790185,
              21.017378220237926
            ],
            [
              105.84058015132565,
              21.017309744222487
            ],
            [
              105.84058200159339,
              21.01727704110346
            ],
            [
              105.84058349196935,
              21.017201406845555
            ],
            [
              105.84058236958593,
              21.017171235698086
            ],
            [
              105.8405657105117,
              21.017076128201904
            ],
            [
              105.84056562401045,
              21.0170756264371
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.3_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5807",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017378220237926,
          "to_latitude": 21.0170756264371,
          "to_longitude": 105.84056562401045,
          "from_longitude": 105.84057851790185
        }
      },
      {
        "id": 5800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752013086226,
            21.012709645649952,
            105.83775044759305,
            21.012890033403785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752013086226,
              21.012890033403785
            ],
            [
              105.83752235139342,
              21.012889420022915
            ],
            [
              105.83753153414136,
              21.012886884632373
            ],
            [
              105.83756019834829,
              21.01287292871656
            ],
            [
              105.83757486712071,
              21.01286612297027
            ],
            [
              105.8375826701929,
              21.01286250327368
            ],
            [
              105.83762251428811,
              21.01285121824307
            ],
            [
              105.83763949001401,
              21.012840500108396
            ],
            [
              105.83767978068018,
              21.0127970463377
            ],
            [
              105.83775044759305,
              21.012709645649952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.2_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5808",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012890033403785,
          "to_latitude": 21.012709645649952,
          "to_longitude": 105.83775044759305,
          "from_longitude": 105.83752013086226
        }
      },
      {
        "id": 5801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858361447221,
            21.012202576455355,
            105.83899816786902,
            21.012572764160453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858361447221,
              21.012202576455355
            ],
            [
              105.83858560608985,
              21.012205239280274
            ],
            [
              105.83863117706777,
              21.012237472041814
            ],
            [
              105.83863676615003,
              21.01224493583635
            ],
            [
              105.83864153040456,
              21.012255069110815
            ],
            [
              105.83865291915372,
              21.012275144942294
            ],
            [
              105.83866612838277,
              21.01230495216983
            ],
            [
              105.83867262319845,
              21.012328519599414
            ],
            [
              105.83871293813777,
              21.012379816403847
            ],
            [
              105.83874727237905,
              21.012422249533966
            ],
            [
              105.83876994552665,
              21.012434608582396
            ],
            [
              105.8388539907161,
              21.01248548564363
            ],
            [
              105.83899816786902,
              21.012572764160453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.15_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5809",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012202576455355,
          "to_latitude": 21.012572764160453,
          "to_longitude": 105.83899816786902,
          "from_longitude": 105.83858361447221
        }
      },
      {
        "id": 5802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390591501425,
            21.014349875339185,
            105.83908938085642,
            21.014649667339523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908703615029,
              21.014349875339185
            ],
            [
              105.83908938085642,
              21.014618741795875
            ],
            [
              105.8390591501425,
              21.014649667339523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.23_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5810",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014349875339185,
          "to_latitude": 21.014649667339523,
          "to_longitude": 105.8390591501425,
          "from_longitude": 105.83908703615029
        }
      },
      {
        "id": 5803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8385259304008,
            21.015285782200227,
            105.83878393042069,
            21.015462710283124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8385259304008,
              21.015462710283124
            ],
            [
              105.83856078279638,
              21.015432522563884
            ],
            [
              105.83859402585459,
              21.015411108558528
            ],
            [
              105.83863400521392,
              21.015382963936062
            ],
            [
              105.83869444102235,
              21.0153393311389
            ],
            [
              105.8387818183496,
              21.01528704628796
            ],
            [
              105.83878393042069,
              21.015285782200227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.1_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5811",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015462710283124,
          "to_latitude": 21.015285782200227,
          "to_longitude": 105.83878393042069,
          "from_longitude": 105.8385259304008
        }
      },
      {
        "id": 5804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060111676109,
            21.0168893584669,
            105.84092901577804,
            21.01689407461743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060111676109,
              21.016893671029564
            ],
            [
              105.8408210370977,
              21.016892610407133
            ],
            [
              105.8408574707565,
              21.0168893584669
            ],
            [
              105.84091722568782,
              21.01689329741551
            ],
            [
              105.84092112589174,
              21.016893555426044
            ],
            [
              105.84092901577804,
              21.01689407461743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A1_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5812",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016893671029564,
          "to_latitude": 21.01689407461743,
          "to_longitude": 105.84092901577804,
          "from_longitude": 105.84060111676109
        }
      },
      {
        "id": 5805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83851014683,
            21.01545752228009,
            105.83891039968539,
            21.015846482559486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83851014683,
              21.01545752228009
            ],
            [
              105.83851128390624,
              21.015458567125254
            ],
            [
              105.83855783107977,
              21.01550125890423
            ],
            [
              105.83860901701843,
              21.015552088431857
            ],
            [
              105.83863492851887,
              21.01557735213569
            ],
            [
              105.83865735424469,
              21.015600943989877
            ],
            [
              105.83868083701084,
              21.015622619408266
            ],
            [
              105.83872873627764,
              21.01567279310047
            ],
            [
              105.83877301708895,
              21.01563701396489
            ],
            [
              105.8388022779465,
              21.015664831703713
            ],
            [
              105.83882866944319,
              21.015689912364852
            ],
            [
              105.83883530431577,
              21.01569823989095
            ],
            [
              105.8388523782798,
              21.015719667787238
            ],
            [
              105.83888954081792,
              21.015769898886663
            ],
            [
              105.83888628315553,
              21.015784249112077
            ],
            [
              105.83888940585376,
              21.015794957690723
            ],
            [
              105.83891039968539,
              21.015815345608804
            ],
            [
              105.83889095993396,
              21.015846482559486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.16_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5813",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01545752228009,
          "to_latitude": 21.015846482559486,
          "to_longitude": 105.83889095993396,
          "from_longitude": 105.83851014683
        }
      },
      {
        "id": 5806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371446720223,
            21.013795770599124,
            105.8373934915049,
            21.013981161585065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373934915049,
              21.013795770599124
            ],
            [
              105.83734157403275,
              21.013832733152356
            ],
            [
              105.8372899032051,
              21.013869942863213
            ],
            [
              105.83725725605152,
              21.013894001934233
            ],
            [
              105.837225020997,
              21.01391882309911
            ],
            [
              105.83717772929734,
              21.013957674064255
            ],
            [
              105.8371446720223,
              21.013981161585065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.5_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5814",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013795770599124,
          "to_latitude": 21.013981161585065,
          "to_longitude": 105.8371446720223,
          "from_longitude": 105.8373934915049
        }
      },
      {
        "id": 5807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83625018739576,
            21.01693985512969,
            105.83643539202649,
            21.017152887067326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83628548298891,
              21.01694338961891
            ],
            [
              105.83628276214047,
              21.01693985512969
            ],
            [
              105.83627687785344,
              21.016943292823743
            ],
            [
              105.83625018739576,
              21.01696221018594
            ],
            [
              105.83631099834828,
              21.017025984164032
            ],
            [
              105.8363732532208,
              21.017094240946676
            ],
            [
              105.83643539202649,
              21.017152887067326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.16_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5815",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01694338961891,
          "to_latitude": 21.017152887067326,
          "to_longitude": 105.83643539202649,
          "from_longitude": 105.83628548298891
        }
      },
      {
        "id": 5808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83651669456101,
            21.013929812050485,
            105.83667959864425,
            21.014165413756512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667959864425,
              21.014165413756512
            ],
            [
              105.83666263491442,
              21.014140551926385
            ],
            [
              105.83661901368906,
              21.014082296199007
            ],
            [
              105.83659103721492,
              21.014041107635453
            ],
            [
              105.83654319452299,
              21.013970670984985
            ],
            [
              105.83651669456101,
              21.013929812050485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.15_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5816",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014165413756512,
          "to_latitude": 21.013929812050485,
          "to_longitude": 105.83651669456101,
          "from_longitude": 105.83667959864425
        }
      },
      {
        "id": 5809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766142776963,
            21.015358137241616,
            105.83788252621407,
            21.01556189333104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766142776963,
              21.01556189333104
            ],
            [
              105.83767678187355,
              21.01555133039712
            ],
            [
              105.83768865393998,
              21.01554475969596
            ],
            [
              105.83770276579963,
              21.015534989584538
            ],
            [
              105.83771391617032,
              21.015527342179283
            ],
            [
              105.83773863916963,
              21.015509138799622
            ],
            [
              105.83774848107568,
              21.015496800166915
            ],
            [
              105.83776263089419,
              21.01548590534986
            ],
            [
              105.83777394539827,
              21.015477850681755
            ],
            [
              105.8377825159749,
              21.015462991989953
            ],
            [
              105.83779235952973,
              21.015448366383424
            ],
            [
              105.83783385705482,
              21.015404906627364
            ],
            [
              105.83784953608117,
              21.015386905834777
            ],
            [
              105.83786169224531,
              21.015376092182787
            ],
            [
              105.83787511166811,
              21.01536359953762
            ],
            [
              105.83788071520509,
              21.015358137241616
            ],
            [
              105.8378822901716,
              21.01535973722379
            ],
            [
              105.83788252621407,
              21.01535997541568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.5_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5817",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01556189333104,
          "to_latitude": 21.01535997541568,
          "to_longitude": 105.83788252621407,
          "from_longitude": 105.83766142776963
        }
      },
      {
        "id": 5810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391206745325,
            21.014369255331758,
            105.83912595930212,
            21.01467087870049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912595930212,
              21.014369255331758
            ],
            [
              105.83912587697601,
              21.014375345273535
            ],
            [
              105.83912354350272,
              21.014513111978903
            ],
            [
              105.83912303040054,
              21.014590249831624
            ],
            [
              105.83912276473784,
              21.014599333058282
            ],
            [
              105.8391206745325,
              21.01467087870049
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.22_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5818",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014369255331758,
          "to_latitude": 21.01467087870049,
          "to_longitude": 105.8391206745325,
          "from_longitude": 105.83912595930212
        }
      },
      {
        "id": 5811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805555131048,
            21.016303622681306,
            105.8382132510765,
            21.01655703734266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382132510765,
              21.01655703734266
            ],
            [
              105.83817162705134,
              21.016485974447466
            ],
            [
              105.8381449970633,
              21.016440256196752
            ],
            [
              105.83812728645972,
              21.01641248621928
            ],
            [
              105.83811861836439,
              21.016398878500432
            ],
            [
              105.83809727846496,
              21.016366133377467
            ],
            [
              105.8380939925859,
              21.0163609885436
            ],
            [
              105.83809306485037,
              21.016359535310595
            ],
            [
              105.83807840648579,
              21.01633658253866
            ],
            [
              105.83806091696023,
              21.016311360362042
            ],
            [
              105.83805555131048,
              21.016303622681306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.31_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5819",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01655703734266,
          "to_latitude": 21.016303622681306,
          "to_longitude": 105.83805555131048,
          "from_longitude": 105.8382132510765
        }
      },
      {
        "id": 5812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83757743806801,
            21.0145477239181,
            105.83781469318969,
            21.01474047677376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83781469318969,
              21.0145477239181
            ],
            [
              105.83781269895475,
              21.014549418251494
            ],
            [
              105.83773897163569,
              21.014612066730557
            ],
            [
              105.83767633149003,
              21.014665591316884
            ],
            [
              105.83763651818681,
              21.014695739153083
            ],
            [
              105.83757743806801,
              21.01474047677376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.7_Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5820",
          "diaChiLapD": "Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0145477239181,
          "to_latitude": 21.01474047677376,
          "to_longitude": 105.83757743806801,
          "from_longitude": 105.83781469318969
        }
      },
      {
        "id": 5813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359273731124,
            21.014889903193737,
            105.83617737894072,
            21.01506772747424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359273731124,
              21.01506772747424
            ],
            [
              105.83594590548908,
              21.01505543295144
            ],
            [
              105.83596644578729,
              21.015041996820372
            ],
            [
              105.83600044794413,
              21.01502037997034
            ],
            [
              105.83601786184161,
              21.015008995063607
            ],
            [
              105.83602285726964,
              21.015005563554013
            ],
            [
              105.83603998527197,
              21.014993797986012
            ],
            [
              105.8360510649772,
              21.014985026520893
            ],
            [
              105.83607382733109,
              21.0149681408826
            ],
            [
              105.83609734345771,
              21.014950497344692
            ],
            [
              105.83612267321084,
              21.014931715860094
            ],
            [
              105.83614964669046,
              21.0149111713285
            ],
            [
              105.83617737894072,
              21.014889903193737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.8_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5821",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01506772747424,
          "to_latitude": 21.014889903193737,
          "to_longitude": 105.83617737894072,
          "from_longitude": 105.8359273731124
        }
      },
      {
        "id": 5814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83973141649015,
            21.01704327949835,
            105.83979759538343,
            21.017343062381208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83973141649015,
              21.01704327949835
            ],
            [
              105.83974142758653,
              21.01709845497588
            ],
            [
              105.83974654833531,
              21.017118097391332
            ],
            [
              105.83975430795505,
              21.01714534456764
            ],
            [
              105.83976765946069,
              21.017181542181778
            ],
            [
              105.83978095136293,
              21.017219635051717
            ],
            [
              105.83978927520629,
              21.01726020198738
            ],
            [
              105.83978998848998,
              21.017280216609
            ],
            [
              105.83979054845805,
              21.017284532156335
            ],
            [
              105.83979254202477,
              21.017299896082434
            ],
            [
              105.83979571417967,
              21.017322339978733
            ],
            [
              105.83979759538343,
              21.017343062381208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.7_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5822",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01704327949835,
          "to_latitude": 21.017343062381208,
          "to_longitude": 105.83979759538343,
          "from_longitude": 105.83973141649015
        }
      },
      {
        "id": 5815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401371901589,
            21.01570022358106,
            105.8402746756873,
            21.01602617203885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401371901589,
              21.01602617203885
            ],
            [
              105.84021299789976,
              21.01587751092791
            ],
            [
              105.84023963136218,
              21.015814888963156
            ],
            [
              105.8402746756873,
              21.015747236045605
            ],
            [
              105.8402577307803,
              21.015741670073396
            ],
            [
              105.84016012373705,
              21.01570022358106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.7_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5823",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01602617203885,
          "to_latitude": 21.01570022358106,
          "to_longitude": 105.84016012373705,
          "from_longitude": 105.8401371901589
        }
      },
      {
        "id": 5816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699387144308,
            21.0145937729438,
            105.83718311156039,
            21.014842894571363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83699387144308,
              21.0145937729438
            ],
            [
              105.83700983609498,
              21.014618681200023
            ],
            [
              105.83702683146494,
              21.014638849682033
            ],
            [
              105.83705178676631,
              21.01467486942445
            ],
            [
              105.83707418472711,
              21.014705793108668
            ],
            [
              105.83710297761225,
              21.014744301311907
            ],
            [
              105.83713163919433,
              21.01478272073656
            ],
            [
              105.83714868697267,
              21.014808309195722
            ],
            [
              105.83717144497197,
              21.014842894571363
            ],
            [
              105.83718199224475,
              21.01483577403875
            ],
            [
              105.83718311156039,
              21.014835017951583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.19_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5824",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0145937729438,
          "to_latitude": 21.014835017951583,
          "to_longitude": 105.83718311156039,
          "from_longitude": 105.83699387144308
        }
      },
      {
        "id": 5817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84078041030865,
            21.015262946892637,
            105.84096266342871,
            21.015489390724447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84096266342871,
              21.015262946892637
            ],
            [
              105.84096262355594,
              21.015263555862386
            ],
            [
              105.84096256175188,
              21.0152643708755
            ],
            [
              105.8409459503843,
              21.015480260178784
            ],
            [
              105.84078041030865,
              21.015489390724447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.2_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5825",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015262946892637,
          "to_latitude": 21.015489390724447,
          "to_longitude": 105.84078041030865,
          "from_longitude": 105.84096266342871
        }
      },
      {
        "id": 5818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530515558691,
            21.014769937508284,
            105.83552269047043,
            21.015013085892154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83552269047043,
              21.015013085892154
            ],
            [
              105.83552008058625,
              21.01500977574638
            ],
            [
              105.83551958778936,
              21.015008687974547
            ],
            [
              105.83551108197396,
              21.01498996345109
            ],
            [
              105.83545560392403,
              21.01492976681696
            ],
            [
              105.83539997651289,
              21.014873142246213
            ],
            [
              105.8353576279556,
              21.014826592055698
            ],
            [
              105.83530515558691,
              21.014769937508284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.6_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5826",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015013085892154,
          "to_latitude": 21.014769937508284,
          "to_longitude": 105.83530515558691,
          "from_longitude": 105.83552269047043
        }
      },
      {
        "id": 5819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84004247011661,
            21.014844733250758,
            105.8403820051747,
            21.014893454963083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84004247011661,
              21.01488535164514
            ],
            [
              105.84012255466367,
              21.01489307257321
            ],
            [
              105.84017534853426,
              21.014893454963083
            ],
            [
              105.84021274477601,
              21.01489040254876
            ],
            [
              105.8402778913851,
              21.014879899669214
            ],
            [
              105.84032085880479,
              21.014871123987046
            ],
            [
              105.84036673615276,
              21.01485274981443
            ],
            [
              105.8403820051747,
              21.014844733250758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.6_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5827",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488535164514,
          "to_latitude": 21.014844733250758,
          "to_longitude": 105.8403820051747,
          "from_longitude": 105.84004247011661
        }
      },
      {
        "id": 5820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798724293814,
            21.014438823310392,
            105.8383416468856,
            21.01475604908775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798724293814,
              21.01475604908775
            ],
            [
              105.83798797939968,
              21.01475563720307
            ],
            [
              105.83799167512858,
              21.01475356958446
            ],
            [
              105.83800662928566,
              21.0147554613432
            ],
            [
              105.83802690369215,
              21.01474147892516
            ],
            [
              105.83806651200584,
              21.01471959018714
            ],
            [
              105.83808909081651,
              21.014706574602336
            ],
            [
              105.83810914445087,
              21.01469715544713
            ],
            [
              105.83814485378852,
              21.014679181503713
            ],
            [
              105.8381819849608,
              21.01466071100078
            ],
            [
              105.83819467659352,
              21.014654673646962
            ],
            [
              105.83825506163843,
              21.014620918915405
            ],
            [
              105.8382900206734,
              21.01459159149988
            ],
            [
              105.8383416468856,
              21.014539000705817
            ],
            [
              105.83832215556234,
              21.01446692553299
            ],
            [
              105.838302401143,
              21.014438823310392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.10_Ngách 165/87, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5828",
          "diaChiLapD": "Ngách 165/87, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01475604908775,
          "to_latitude": 21.014438823310392,
          "to_longitude": 105.838302401143,
          "from_longitude": 105.83798724293814
        }
      },
      {
        "id": 5821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83994159243498,
            21.015353572769186,
            105.84012114568999,
            21.015612854458137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83994159243498,
              21.015612854458137
            ],
            [
              105.83996254053821,
              21.015602382026504
            ],
            [
              105.84000162909926,
              21.015577875155902
            ],
            [
              105.84003017640958,
              21.015558801731945
            ],
            [
              105.84004507472977,
              21.01554675503659
            ],
            [
              105.84005349640985,
              21.015537297346224
            ],
            [
              105.84007991030911,
              21.01548184356767
            ],
            [
              105.84009721093604,
              21.015446332803613
            ],
            [
              105.84011877877099,
              21.015362746758015
            ],
            [
              105.84012114568999,
              21.015353572769186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.12_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5829",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015612854458137,
          "to_latitude": 21.015353572769186,
          "to_longitude": 105.84012114568999,
          "from_longitude": 105.83994159243498
        }
      },
      {
        "id": 5822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836727742028,
            21.015146362106112,
            105.83861929245036,
            21.015354751099885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836727742028,
              21.015146362106112
            ],
            [
              105.83838325113128,
              21.01516303006667
            ],
            [
              105.8384039870285,
              21.01518600344808
            ],
            [
              105.83841595939506,
              21.015198076552483
            ],
            [
              105.8384308643049,
              21.015211972361566
            ],
            [
              105.83844747715156,
              21.01522699058853
            ],
            [
              105.83845054293829,
              21.01522976282872
            ],
            [
              105.8384711858174,
              21.0152495148598
            ],
            [
              105.8384876296646,
              21.015264241270867
            ],
            [
              105.8385024309214,
              21.015278004810135
            ],
            [
              105.8385268554595,
              21.015300216642217
            ],
            [
              105.83858863431043,
              21.01533387155362
            ],
            [
              105.83861929245036,
              21.015354751099885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.15_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5830",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015146362106112,
          "to_latitude": 21.015354751099885,
          "to_longitude": 105.83861929245036,
          "from_longitude": 105.83836727742028
        }
      },
      {
        "id": 5823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002180426846,
            21.01631945760979,
            105.84036958455144,
            21.016330011549943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002180426846,
              21.01631945760979
            ],
            [
              105.84002451513788,
              21.01631947945003
            ],
            [
              105.84003058777623,
              21.01632100933206
            ],
            [
              105.84005997229988,
              21.016328419701157
            ],
            [
              105.84025083975064,
              21.016330011549943
            ],
            [
              105.84036958455144,
              21.01632418000322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.2_Ngách 222/61, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5831",
          "diaChiLapD": "Ngách 222/61, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01631945760979,
          "to_latitude": 21.01632418000322,
          "to_longitude": 105.84036958455144,
          "from_longitude": 105.84002180426846
        }
      },
      {
        "id": 5824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366846185942,
            21.017534596396544,
            105.83383056684346,
            21.017777569334566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83383056684346,
              21.017777569334566
            ],
            [
              105.83382437225474,
              21.017770369385683
            ],
            [
              105.83382146666438,
              21.017766992019563
            ],
            [
              105.83367034313724,
              21.01759131397032
            ],
            [
              105.83366846185942,
              21.017582541137532
            ],
            [
              105.83371596670665,
              21.017534596396544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11.2_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5832",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017777569334566,
          "to_latitude": 21.017534596396544,
          "to_longitude": 105.83371596670665,
          "from_longitude": 105.83383056684346
        }
      },
      {
        "id": 5825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83369806762512,
            21.016342318432557,
            105.83409918610872,
            21.01658378386045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83409918610872,
              21.01658378386045
            ],
            [
              105.83409887084129,
              21.016583482825954
            ],
            [
              105.83409584083746,
              21.0165805631052
            ],
            [
              105.8340914916824,
              21.01657637359574
            ],
            [
              105.83399503134133,
              21.016469705098306
            ],
            [
              105.83399731910497,
              21.01646092989472
            ],
            [
              105.83393752783331,
              21.016395387860985
            ],
            [
              105.83389168553306,
              21.016357148750725
            ],
            [
              105.83387676711696,
              21.016351844979013
            ],
            [
              105.83385747446036,
              21.01635116005957
            ],
            [
              105.83384235638822,
              21.01635631479819
            ],
            [
              105.83382323227197,
              21.016349955894857
            ],
            [
              105.8337981273939,
              21.016342318432557
            ],
            [
              105.83373029132355,
              21.01640687377712
            ],
            [
              105.83369806762512,
              21.01643688326845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5833",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01658378386045,
          "to_latitude": 21.01643688326845,
          "to_longitude": 105.83369806762512,
          "from_longitude": 105.83409918610872
        }
      },
      {
        "id": 5826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370827269444,
            21.012835498405128,
            105.83733816559403,
            21.012960328247388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83708714785281,
              21.012922029258707
            ],
            [
              105.83708604215103,
              21.012921085409662
            ],
            [
              105.8370827269444,
              21.01291824933701
            ],
            [
              105.83710400419345,
              21.01290007836135
            ],
            [
              105.83717309336689,
              21.012835498405128
            ],
            [
              105.83721294938393,
              21.012868573471916
            ],
            [
              105.83726107373909,
              21.012901761390072
            ],
            [
              105.83733816559403,
              21.012960328247388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.6_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5834",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012922029258707,
          "to_latitude": 21.012960328247388,
          "to_longitude": 105.83733816559403,
          "from_longitude": 105.83708714785281
        }
      },
      {
        "id": 5827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046666117821,
            21.01462543081786,
            105.84069000788364,
            21.014806682254875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84069000788364,
              21.01462543081786
            ],
            [
              105.84067707566165,
              21.014688584890116
            ],
            [
              105.84057148369592,
              21.01467347813392
            ],
            [
              105.84054571623633,
              21.0146875087608
            ],
            [
              105.84051245613628,
              21.014722281898845
            ],
            [
              105.84046666117821,
              21.014806682254875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.5_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5835",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01462543081786,
          "to_latitude": 21.014806682254875,
          "to_longitude": 105.84046666117821,
          "from_longitude": 105.84069000788364
        }
      },
      {
        "id": 5828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444766243151,
            21.017834766961748,
            105.8346916971585,
            21.0179375035243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346916971585,
              21.017863291142554
            ],
            [
              105.83461518977289,
              21.017902432909732
            ],
            [
              105.83457597445336,
              21.017834766961748
            ],
            [
              105.83444766243151,
              21.017907848551676
            ],
            [
              105.83446155178999,
              21.017932180123587
            ],
            [
              105.83445036120726,
              21.0179375035243
            ],
            [
              105.83444952109646,
              21.017936061583054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.5_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5836",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017863291142554,
          "to_latitude": 21.017936061583054,
          "to_longitude": 105.83444952109646,
          "from_longitude": 105.8346916971585
        }
      },
      {
        "id": 5829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768674936147,
            21.01530945713728,
            105.83796744635822,
            21.015563199360642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83768674936147,
              21.015563199360642
            ],
            [
              105.83770427766942,
              21.01555026649764
            ],
            [
              105.83772838789073,
              21.015529129755276
            ],
            [
              105.83775599086079,
              21.015510054129116
            ],
            [
              105.83776687701938,
              21.01549919254391
            ],
            [
              105.83778110436982,
              21.015487367926024
            ],
            [
              105.83778903571887,
              21.015480858171884
            ],
            [
              105.83779906548203,
              21.015468398482504
            ],
            [
              105.83781260590881,
              21.015452423317434
            ],
            [
              105.8378344231787,
              21.015428418374164
            ],
            [
              105.83786747042029,
              21.015400171679097
            ],
            [
              105.83787655727976,
              21.015387676887176
            ],
            [
              105.83788477244882,
              21.0153792834121
            ],
            [
              105.8378899845976,
              21.015375047815155
            ],
            [
              105.83792344077969,
              21.015347859480674
            ],
            [
              105.83796744635822,
              21.01530945713728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5837",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015563199360642,
          "to_latitude": 21.01530945713728,
          "to_longitude": 105.83796744635822,
          "from_longitude": 105.83768674936147
        }
      },
      {
        "id": 5830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810968778882,
            21.014941146515525,
            105.8383068478164,
            21.015191058259443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810968778882,
              21.015191058259443
            ],
            [
              105.83812480004069,
              21.015185599693762
            ],
            [
              105.83816528242163,
              21.01516518339622
            ],
            [
              105.83819123868665,
              21.015147012716163
            ],
            [
              105.83822126484546,
              21.015127113983823
            ],
            [
              105.8383036641693,
              21.015070344104586
            ],
            [
              105.8383068478164,
              21.015057028444165
            ],
            [
              105.83826946042427,
              21.015006944632862
            ],
            [
              105.83823702384007,
              21.014941146515525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.2_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5838",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015191058259443,
          "to_latitude": 21.014941146515525,
          "to_longitude": 105.83823702384007,
          "from_longitude": 105.83810968778882
        }
      },
      {
        "id": 5831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669146244351,
            21.01477666396953,
            105.83683537498169,
            21.01505614450024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669146244351,
              21.01477666396953
            ],
            [
              105.83669211661747,
              21.0147783994592
            ],
            [
              105.83669759486378,
              21.014792894558397
            ],
            [
              105.83683537498169,
              21.014972882546772
            ],
            [
              105.83671783730375,
              21.01505614450024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.3_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5839",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01477666396953,
          "to_latitude": 21.01505614450024,
          "to_longitude": 105.83671783730375,
          "from_longitude": 105.83669146244351
        }
      },
      {
        "id": 5832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687748561589,
            21.013219899923033,
            105.83742612396149,
            21.013761332625002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742612396149,
              21.013761332625002
            ],
            [
              105.83742441435767,
              21.01375990671942
            ],
            [
              105.83742109080141,
              21.013757130308246
            ],
            [
              105.8374033974428,
              21.013755456105315
            ],
            [
              105.8373623581085,
              21.013708446554375
            ],
            [
              105.83730376684979,
              21.01363580676746
            ],
            [
              105.83723701973102,
              21.01356941224293
            ],
            [
              105.83721612434937,
              21.01354657316545
            ],
            [
              105.83719583977818,
              21.013523731079623
            ],
            [
              105.8371746615326,
              21.013486745282073
            ],
            [
              105.83717217670743,
              21.013479301391367
            ],
            [
              105.83716108195233,
              21.01346138542982
            ],
            [
              105.8371391383586,
              21.013428939385637
            ],
            [
              105.8371177994663,
              21.013403669190406
            ],
            [
              105.83706276767614,
              21.01335690904807
            ],
            [
              105.837021099705,
              21.01333321652745
            ],
            [
              105.83697263932353,
              21.013302402065772
            ],
            [
              105.83687748561589,
              21.013219899923033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.4_Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5840",
          "diaChiLapD": "Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013761332625002,
          "to_latitude": 21.013219899923033,
          "to_longitude": 105.83687748561589,
          "from_longitude": 105.83742612396149
        }
      },
      {
        "id": 5833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410889138753,
            21.017377733147093,
            105.83419515990154,
            21.0176630935925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416886134911,
              21.01737918393231
            ],
            [
              105.83415674620359,
              21.017377733147093
            ],
            [
              105.83413101960545,
              21.01739653887891
            ],
            [
              105.83410889138753,
              21.017438302284653
            ],
            [
              105.83414378230195,
              21.017464434908607
            ],
            [
              105.83419515990154,
              21.017526338368814
            ],
            [
              105.83411838546907,
              21.0176630935925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.2_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5841",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01737918393231,
          "to_latitude": 21.0176630935925,
          "to_longitude": 105.83411838546907,
          "from_longitude": 105.83416886134911
        }
      },
      {
        "id": 5834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837840906346,
            21.01297851635551,
            105.83837465132491,
            21.013386695228192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837840906346,
              21.013386695228192
            ],
            [
              105.83784287809196,
              21.01338579945568
            ],
            [
              105.8378577777711,
              21.01338020106132
            ],
            [
              105.83786955592215,
              21.013371314043322
            ],
            [
              105.83788224709116,
              21.013356082797326
            ],
            [
              105.83790066913144,
              21.013341839455205
            ],
            [
              105.83791530102371,
              21.013331009093424
            ],
            [
              105.837929441034,
              21.01331925715448
            ],
            [
              105.83794388399383,
              21.01330904552618
            ],
            [
              105.83795593966177,
              21.013297491720955
            ],
            [
              105.8379620337059,
              21.01329134146034
            ],
            [
              105.83796545520558,
              21.013287887841134
            ],
            [
              105.83798722803785,
              21.01325064815604
            ],
            [
              105.83800619772347,
              21.013229404827523
            ],
            [
              105.8380270637879,
              21.013206001579714
            ],
            [
              105.83803364213296,
              21.01319855641741
            ],
            [
              105.83803692749548,
              21.0131948406291
            ],
            [
              105.83804867356822,
              21.013179010672516
            ],
            [
              105.83806042163668,
              21.013163538382127
            ],
            [
              105.83806685946587,
              21.01315418630112
            ],
            [
              105.83807255157448,
              21.013148064210018
            ],
            [
              105.83808013101607,
              21.013137990248545
            ],
            [
              105.83809519758908,
              21.013121464708515
            ],
            [
              105.83810388129521,
              21.01310622247695
            ],
            [
              105.83812363331313,
              21.013067303700172
            ],
            [
              105.83813762451969,
              21.01304235456519
            ],
            [
              105.83815507067916,
              21.013021835885855
            ],
            [
              105.83817838146331,
              21.013004381857424
            ],
            [
              105.83819166585194,
              21.012991348824617
            ],
            [
              105.83820866185528,
              21.012979272084873
            ],
            [
              105.83821667426523,
              21.01297851635551
            ],
            [
              105.83822545642333,
              21.01297883167041
            ],
            [
              105.83823869368649,
              21.01298353277073
            ],
            [
              105.83829806519842,
              21.013037230489083
            ],
            [
              105.83837465132491,
              21.013102126439318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.14_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5842",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013386695228192,
          "to_latitude": 21.013102126439318,
          "to_longitude": 105.83837465132491,
          "from_longitude": 105.837840906346
        }
      },
      {
        "id": 5835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83657507257385,
            21.01637869961657,
            105.83671598274155,
            21.016665548620622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665850919405,
              21.01637869961657
            ],
            [
              105.83657507257385,
              21.016431686610623
            ],
            [
              105.83659594417537,
              21.01645968960002
            ],
            [
              105.83663145140447,
              21.01651332998397
            ],
            [
              105.83666318192131,
              21.016580566198442
            ],
            [
              105.83670211267865,
              21.01665178092121
            ],
            [
              105.83671040639801,
              21.016665548620622
            ],
            [
              105.83671378298743,
              21.016663854726357
            ],
            [
              105.83671598274155,
              21.016662782618926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.12_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5843",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01637869961657,
          "to_latitude": 21.016662782618926,
          "to_longitude": 105.83671598274155,
          "from_longitude": 105.83665850919405
        }
      },
      {
        "id": 5836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608253091917,
            21.01438965512624,
            105.83636845921401,
            21.014678439522154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636845921401,
              21.014678439522154
            ],
            [
              105.83636614711477,
              21.014676610116933
            ],
            [
              105.83634797778362,
              21.014662219844524
            ],
            [
              105.83630133259396,
              21.014614729985045
            ],
            [
              105.83626323620459,
              21.014572780019034
            ],
            [
              105.83619233334909,
              21.014500479461475
            ],
            [
              105.83608253091917,
              21.01438965512624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.5_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5844",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014678439522154,
          "to_latitude": 21.01438965512624,
          "to_longitude": 105.83608253091917,
          "from_longitude": 105.83636845921401
        }
      },
      {
        "id": 5837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83649518659797,
            21.015811660117127,
            105.8367116655727,
            21.015992354144704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83649518659797,
              21.0158699732024
            ],
            [
              105.8365106762182,
              21.015860920798687
            ],
            [
              105.83651740876215,
              21.015856598282976
            ],
            [
              105.8365874036217,
              21.015811660117127
            ],
            [
              105.83663214334655,
              21.015864607497292
            ],
            [
              105.8366778441078,
              21.015914973239507
            ],
            [
              105.8367116655727,
              21.015962862042837
            ],
            [
              105.83666574649955,
              21.015992354144704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.9_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5845",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0158699732024,
          "to_latitude": 21.015992354144704,
          "to_longitude": 105.83666574649955,
          "from_longitude": 105.83649518659797
        }
      },
      {
        "id": 5838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352011270741,
            21.01683400743162,
            105.83549882188669,
            21.01724569709944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83549882188669,
              21.01722020928643
            ],
            [
              105.83536958009641,
              21.01724569709944
            ],
            [
              105.83529926834565,
              21.017103170333034
            ],
            [
              105.83522586476826,
              21.016954375882705
            ],
            [
              105.8352011270741,
              21.016902841030433
            ],
            [
              105.83521893491448,
              21.016893570520562
            ],
            [
              105.83533334970613,
              21.01683400743162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.14_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5846",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01722020928643,
          "to_latitude": 21.01683400743162,
          "to_longitude": 105.83533334970613,
          "from_longitude": 105.83549882188669
        }
      },
      {
        "id": 5839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353597075579,
            21.0142980274354,
            105.83565135017324,
            21.014495698350917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353597075579,
              21.014406667330693
            ],
            [
              105.83542681407593,
              21.014495698350917
            ],
            [
              105.83551881801002,
              21.01443926469827
            ],
            [
              105.83554470290773,
              21.014423267923718
            ],
            [
              105.83558657672579,
              21.014396174184206
            ],
            [
              105.83565135017324,
              21.014349909924025
            ],
            [
              105.83563462551157,
              21.01432614965055
            ],
            [
              105.83561482916912,
              21.0142980274354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.3_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5847",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014406667330693,
          "to_latitude": 21.0142980274354,
          "to_longitude": 105.83561482916912,
          "from_longitude": 105.8353597075579
        }
      },
      {
        "id": 5840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390955517604,
            21.016846496324543,
            105.83425987777255,
            21.017142744609203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390955517604,
              21.017142744609203
            ],
            [
              105.83411939242406,
              21.016963581031817
            ],
            [
              105.83425987777255,
              21.016846496324543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.1_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5848",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017142744609203,
          "to_latitude": 21.016846496324543,
          "to_longitude": 105.83425987777255,
          "from_longitude": 105.83390955517604
        }
      },
      {
        "id": 5841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953382681693,
            21.0149906272615,
            105.83976575983283,
            21.015504003092133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954345945521,
              21.015504003092133
            ],
            [
              105.83954535231098,
              21.015501211822276
            ],
            [
              105.83954732626665,
              21.015498301829865
            ],
            [
              105.83953382681693,
              21.015481520613307
            ],
            [
              105.83953664183338,
              21.01547815395557
            ],
            [
              105.83956760369415,
              21.015441216475573
            ],
            [
              105.83961634012931,
              21.015380042681205
            ],
            [
              105.83966017852141,
              21.015327489930918
            ],
            [
              105.83968066451226,
              21.015304408101922
            ],
            [
              105.83971256528135,
              21.015263026618207
            ],
            [
              105.83972193707679,
              21.015232212045184
            ],
            [
              105.8397274643041,
              21.015196636587508
            ],
            [
              105.83973282090638,
              21.01515756017331
            ],
            [
              105.83973661357906,
              21.015119202315176
            ],
            [
              105.83974416373209,
              21.015076408243225
            ],
            [
              105.83975009232671,
              21.015034348366537
            ],
            [
              105.83976575983283,
              21.0149906272615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.34_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5849",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015504003092133,
          "to_latitude": 21.0149906272615,
          "to_longitude": 105.83976575983283,
          "from_longitude": 105.83954345945521
        }
      },
      {
        "id": 5842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645814865119,
            21.015784334555285,
            105.83651740876215,
            21.015856598282976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83645814865119,
              21.015784334555285
            ],
            [
              105.83650730057121,
              21.015843635233303
            ],
            [
              105.8365090871272,
              21.015843332901007
            ],
            [
              105.83651058846938,
              21.015843461905824
            ],
            [
              105.83651089547523,
              21.015843488396236
            ],
            [
              105.83651259281113,
              21.015844091533275
            ],
            [
              105.83651405768728,
              21.015845098651326
            ],
            [
              105.83651518388034,
              21.015846436208257
            ],
            [
              105.83651532512047,
              21.015846751642258
            ],
            [
              105.836515888122,
              21.01584800706533
            ],
            [
              105.83651612072002,
              21.015849697660624
            ],
            [
              105.83651586463692,
              21.015851385239404
            ],
            [
              105.83651513746894,
              21.015852947779923
            ],
            [
              105.83651740876215,
              21.015856598282976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.9_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5850",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015784334555285,
          "to_latitude": 21.015856598282976,
          "to_longitude": 105.83651740876215,
          "from_longitude": 105.83645814865119
        }
      },
      {
        "id": 5843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548594285293,
            21.014832408020798,
            105.83554448569079,
            21.015009590960133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554448569079,
              21.014832408020798
            ],
            [
              105.8355156382808,
              21.014866074674632
            ],
            [
              105.8354958098772,
              21.014895932393575
            ],
            [
              105.83549244996536,
              21.014934983546677
            ],
            [
              105.83548594285293,
              21.014949554706476
            ],
            [
              105.83551682136832,
              21.01498590959368
            ],
            [
              105.83552479285326,
              21.015003688355243
            ],
            [
              105.83552550002146,
              21.015005269138086
            ],
            [
              105.83552772884403,
              21.015009590960133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.7_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5851",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014832408020798,
          "to_latitude": 21.015009590960133,
          "to_longitude": 105.83552772884403,
          "from_longitude": 105.83554448569079
        }
      },
      {
        "id": 5844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84059152073647,
            21.015339325905607,
            105.84060429936052,
            21.01534555823602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060429936052,
              21.01534555823602
            ],
            [
              105.84060409873719,
              21.015345461679136
            ],
            [
              105.84059152073647,
              21.015339325905607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.5_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5852",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01534555823602,
          "to_latitude": 21.015339325905607,
          "to_longitude": 105.84059152073647,
          "from_longitude": 105.84060429936052
        }
      },
      {
        "id": 5845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83861929245036,
            21.015276705857175,
            105.83877344378749,
            21.015354751099885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83877344378749,
              21.015282235457367
            ],
            [
              105.83877115527449,
              21.01527878197323
            ],
            [
              105.83876960938099,
              21.015276705857175
            ],
            [
              105.83871612564499,
              21.01531244816606
            ],
            [
              105.83868588538121,
              21.015331037462104
            ],
            [
              105.83865391548544,
              21.015352367531456
            ],
            [
              105.83861929245036,
              21.015354751099885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.15_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5853",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015282235457367,
          "to_latitude": 21.015354751099885,
          "to_longitude": 105.83861929245036,
          "from_longitude": 105.83877344378749
        }
      },
      {
        "id": 5846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910636967069,
            21.01490101753523,
            105.83932280643138,
            21.015092547750744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83910916041293,
              21.015092547750744
            ],
            [
              105.83910734665037,
              21.015090563281333
            ],
            [
              105.83910636967069,
              21.01508942100558
            ],
            [
              105.83911506921032,
              21.015083199144083
            ],
            [
              105.83912778127412,
              21.015074108723564
            ],
            [
              105.83912912774628,
              21.01507303537511
            ],
            [
              105.83915326392044,
              21.015055556366146
            ],
            [
              105.83919956637787,
              21.01501993966941
            ],
            [
              105.83924968919727,
              21.014979779866852
            ],
            [
              105.83927130927525,
              21.014967520338875
            ],
            [
              105.83932280643138,
              21.01490101753523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.28_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5854",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015092547750744,
          "to_latitude": 21.01490101753523,
          "to_longitude": 105.83932280643138,
          "from_longitude": 105.83910916041293
        }
      },
      {
        "id": 5847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908581456859,
            21.01512268605433,
            105.83919256280879,
            21.015265474068787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908581456859,
              21.01512268605433
            ],
            [
              105.83912014397468,
              21.0151656664523
            ],
            [
              105.83913124237495,
              21.01518021413255
            ],
            [
              105.83913721994546,
              21.015187973148794
            ],
            [
              105.8391500460371,
              21.01520459423716
            ],
            [
              105.83916248825619,
              21.015225330499213
            ],
            [
              105.83917261436068,
              21.015244042317963
            ],
            [
              105.83917604629194,
              21.015254486528313
            ],
            [
              105.83917937833654,
              21.015265474068787
            ],
            [
              105.83918609578376,
              21.015263458358234
            ],
            [
              105.83919256280879,
              21.01526069781978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.4_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5855",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01512268605433,
          "to_latitude": 21.01526069781978,
          "to_longitude": 105.83919256280879,
          "from_longitude": 105.83908581456859
        }
      },
      {
        "id": 5848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83532443678506,
            21.013936349419648,
            105.83532784686292,
            21.013938931319853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83532443678506,
              21.013938931319853
            ],
            [
              105.83532472223405,
              21.01393871495094
            ],
            [
              105.83532784686292,
              21.013936349419648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.1_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5856",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013938931319853,
          "to_latitude": 21.013936349419648,
          "to_longitude": 105.83532784686292,
          "from_longitude": 105.83532443678506
        }
      },
      {
        "id": 5849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706556103121,
            21.013790623279807,
            105.83738079711368,
            21.014023685331207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83738079711368,
              21.013790623279807
            ],
            [
              105.83731879622157,
              21.01383507440872
            ],
            [
              105.83728376816427,
              21.01386427641906
            ],
            [
              105.8372695089187,
              21.01387450334742
            ],
            [
              105.83725670760984,
              21.013881567234456
            ],
            [
              105.83724179309783,
              21.01389486202521
            ],
            [
              105.83720250426407,
              21.013923306406877
            ],
            [
              105.83716274100837,
              21.013951891307496
            ],
            [
              105.83714410026793,
              21.01396472029644
            ],
            [
              105.8371289732701,
              21.013975105934446
            ],
            [
              105.83711804151228,
              21.0139826122249
            ],
            [
              105.83706751099636,
              21.014022159223952
            ],
            [
              105.83706556103121,
              21.014023685331207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5857",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013790623279807,
          "to_latitude": 21.014023685331207,
          "to_longitude": 105.83706556103121,
          "from_longitude": 105.83738079711368
        }
      },
      {
        "id": 5850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701059767819,
            21.01297700547362,
            105.83716750566381,
            21.013124750096686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701793903205,
              21.01298017790895
            ],
            [
              105.83701965470965,
              21.01297700547362
            ],
            [
              105.83702169207176,
              21.012979077383307
            ],
            [
              105.83702010716435,
              21.012981723498722
            ],
            [
              105.83701059767819,
              21.012997592965295
            ],
            [
              105.83701618286764,
              21.013001954257145
            ],
            [
              105.83710022370286,
              21.013066229738705
            ],
            [
              105.83716750566381,
              21.013124750096686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.5_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5858",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01298017790895,
          "to_latitude": 21.013124750096686,
          "to_longitude": 105.83716750566381,
          "from_longitude": 105.83701793903205
        }
      },
      {
        "id": 5851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366146979877,
            21.014805120567914,
            105.83671783575757,
            21.014946971485262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366146979877,
              21.01484626006218
            ],
            [
              105.83666839919613,
              21.014805120567914
            ],
            [
              105.83671333833409,
              21.014858344249802
            ],
            [
              105.83671783575757,
              21.014867209858586
            ],
            [
              105.83670217932713,
              21.014892898684327
            ],
            [
              105.83668924668677,
              21.014946971485262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.1_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5859",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484626006218,
          "to_latitude": 21.014946971485262,
          "to_longitude": 105.83668924668677,
          "from_longitude": 105.8366146979877
        }
      },
      {
        "id": 5852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83833965324068,
            21.014764562300446,
            105.83856114066063,
            21.014836917297107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856114066063,
              21.014764562300446
            ],
            [
              105.83854607886508,
              21.014772153166643
            ],
            [
              105.83852433005218,
              21.014787889747215
            ],
            [
              105.83849827735544,
              21.01479877193356
            ],
            [
              105.83846873855407,
              21.01481411696506
            ],
            [
              105.83842243807244,
              21.014835996323256
            ],
            [
              105.83841338562566,
              21.014834154098658
            ],
            [
              105.83838501496277,
              21.014829773269657
            ],
            [
              105.83837050580841,
              21.014829366058457
            ],
            [
              105.83834722702261,
              21.014835551336542
            ],
            [
              105.8383413033912,
              21.014836917297107
            ],
            [
              105.83834090641977,
              21.014835595126794
            ],
            [
              105.83833965324068,
              21.014832191631207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.2_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5860",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014764562300446,
          "to_latitude": 21.014832191631207,
          "to_longitude": 105.83833965324068,
          "from_longitude": 105.83856114066063
        }
      },
      {
        "id": 5853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83416799490247,
            21.01544913546433,
            105.83425260899025,
            21.01554362419077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416799490247,
              21.01544913546433
            ],
            [
              105.83420697938585,
              21.015491533163484
            ],
            [
              105.83420853960055,
              21.015490660223968
            ],
            [
              105.83421031036043,
              21.015490284832055
            ],
            [
              105.83421165773208,
              21.015490403773597
            ],
            [
              105.83421211872789,
              21.015490444867932
            ],
            [
              105.83421330748499,
              21.015490928586768
            ],
            [
              105.83421378472528,
              21.015491123149644
            ],
            [
              105.8342151434944,
              21.0154922536469
            ],
            [
              105.83421606262564,
              21.015493724105884
            ],
            [
              105.83421644992903,
              21.015495389559497
            ],
            [
              105.83421626793766,
              21.015497085804554
            ],
            [
              105.83421553399845,
              21.015498645659456
            ],
            [
              105.8342143213195,
              21.015499914313796
            ],
            [
              105.83425260899025,
              21.01554362419077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.3_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5861",
          "diaChiLapD": "Ngõ 164, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01544913546433,
          "to_latitude": 21.01554362419077,
          "to_longitude": 105.83425260899025,
          "from_longitude": 105.83416799490247
        }
      },
      {
        "id": 5854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83939912942525,
            21.016483586641368,
            105.83950493572033,
            21.016677301922357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83941011894906,
              21.016483586641368
            ],
            [
              105.83939912942525,
              21.016488000725435
            ],
            [
              105.8394101949094,
              21.016504378475236
            ],
            [
              105.83942932959184,
              21.016538192883424
            ],
            [
              105.83944387042226,
              21.016566917710954
            ],
            [
              105.83945920749667,
              21.016601613432368
            ],
            [
              105.83946920861753,
              21.016626328667783
            ],
            [
              105.83947911436053,
              21.016645517547698
            ],
            [
              105.83948368965841,
              21.01665571133301
            ],
            [
              105.83949186199945,
              21.016663559760755
            ],
            [
              105.83950493572033,
              21.016677301922357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.3_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5862",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016483586641368,
          "to_latitude": 21.016677301922357,
          "to_longitude": 105.83950493572033,
          "from_longitude": 105.83941011894906
        }
      },
      {
        "id": 5855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716492441818,
            21.015835123989696,
            105.83734053147971,
            21.01591160430153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734053147971,
              21.015835123989696
            ],
            [
              105.83733267192295,
              21.01584002653474
            ],
            [
              105.8373015162689,
              21.015860112223045
            ],
            [
              105.83726742878635,
              21.015883501876775
            ],
            [
              105.83722751805416,
              21.01591160430153
            ],
            [
              105.83718517460957,
              21.015861470577757
            ],
            [
              105.83716492441818,
              21.01584743115618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.2_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5863",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015835123989696,
          "to_latitude": 21.01584743115618,
          "to_longitude": 105.83716492441818,
          "from_longitude": 105.83734053147971
        }
      },
      {
        "id": 5856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83578197869872,
            21.01544076354071,
            105.83586903589155,
            21.015602013003143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578197869872,
              21.01544076354071
            ],
            [
              105.83580382413228,
              21.015474233587938
            ],
            [
              105.83583357052103,
              21.015540777021034
            ],
            [
              105.83586415283278,
              21.015593333524315
            ],
            [
              105.83586514867552,
              21.01559510256426
            ],
            [
              105.83586674107009,
              21.015597934478013
            ],
            [
              105.83586903589155,
              21.015602013003143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.16_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5864",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01544076354071,
          "to_latitude": 21.015602013003143,
          "to_longitude": 105.83586903589155,
          "from_longitude": 105.83578197869872
        }
      },
      {
        "id": 5857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722200155115,
            21.014739106779157,
            105.8374164802266,
            21.014875705604428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722200155115,
              21.014875705604428
            ],
            [
              105.83726556161459,
              21.01485122792959
            ],
            [
              105.83739505256037,
              21.014770692319214
            ],
            [
              105.83738610716536,
              21.0147580415319
            ],
            [
              105.8374164802266,
              21.014739106779157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.20_Ngõ 207, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5865",
          "diaChiLapD": "Ngõ 207, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014875705604428,
          "to_latitude": 21.014739106779157,
          "to_longitude": 105.8374164802266,
          "from_longitude": 105.83722200155115
        }
      },
      {
        "id": 5858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691325998544,
            21.016067513349274,
            105.8369948534103,
            21.016165396273095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83691325998544,
              21.016067513349274
            ],
            [
              105.83691369890244,
              21.016068251833868
            ],
            [
              105.83691953573519,
              21.016078040263285
            ],
            [
              105.83692961986446,
              21.016094956817028
            ],
            [
              105.8369948534103,
              21.016165396273095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.5_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5866",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016067513349274,
          "to_latitude": 21.016165396273095,
          "to_longitude": 105.8369948534103,
          "from_longitude": 105.83691325998544
        }
      },
      {
        "id": 5859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829059619595,
            21.015691505037935,
            105.83851317410607,
            21.015963082419876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83829059619595,
              21.015691505037935
            ],
            [
              105.83833898858553,
              21.015791029471075
            ],
            [
              105.83834074138863,
              21.015794499149088
            ],
            [
              105.83834274029125,
              21.015798457161715
            ],
            [
              105.838353014941,
              21.01581880132669
            ],
            [
              105.83835862628345,
              21.015836764992148
            ],
            [
              105.83842719625584,
              21.015952271863924
            ],
            [
              105.83846766023943,
              21.01595159463681
            ],
            [
              105.83851317410607,
              21.015963082419876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.19_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5867",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015691505037935,
          "to_latitude": 21.015963082419876,
          "to_longitude": 105.83851317410607,
          "from_longitude": 105.83829059619595
        }
      },
      {
        "id": 5860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837992692266,
            21.01350668164626,
            105.83863632083222,
            21.01372438758897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837992692266,
              21.01350668164626
            ],
            [
              105.83846375024552,
              21.013570094918343
            ],
            [
              105.83848824877694,
              21.013591485373087
            ],
            [
              105.83855966806998,
              21.01365384424164
            ],
            [
              105.83860913929297,
              21.013699355033513
            ],
            [
              105.83863632083222,
              21.01372438758897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.19_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5868",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01350668164626,
          "to_latitude": 21.01372438758897,
          "to_longitude": 105.83863632083222,
          "from_longitude": 105.83837992692266
        }
      },
      {
        "id": 5861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023357376202,
            21.015526481685054,
            105.840240333063,
            21.015555541062156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.840240333063,
              21.015526481685054
            ],
            [
              105.84023357376202,
              21.015555541062156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.9_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5869",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015526481685054,
          "to_latitude": 21.015555541062156,
          "to_longitude": 105.84023357376202,
          "from_longitude": 105.840240333063
        }
      },
      {
        "id": 5862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84003858731984,
            21.01652677562575,
            105.84004074931826,
            21.016845379614807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003858731984,
              21.016845379614807
            ],
            [
              105.84004074931826,
              21.016754614846192
            ],
            [
              105.8400407094874,
              21.016751796985478
            ],
            [
              105.84003896751747,
              21.016627296223234
            ],
            [
              105.84004023505547,
              21.01655373234225
            ],
            [
              105.84004023702686,
              21.01652677562575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5870",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016845379614807,
          "to_latitude": 21.01652677562575,
          "to_longitude": 105.84004023702686,
          "from_longitude": 105.84003858731984
        }
      },
      {
        "id": 5863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8394947029994,
            21.016441135626835,
            105.83956816394281,
            21.016456188174345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956816394281,
              21.016441135626835
            ],
            [
              105.83953050792444,
              21.016441672811233
            ],
            [
              105.8394947029994,
              21.016456188174345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72A.2_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5871",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016441135626835,
          "to_latitude": 21.016456188174345,
          "to_longitude": 105.8394947029994,
          "from_longitude": 105.83956816394281
        }
      },
      {
        "id": 5864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669759486378,
            21.01463158390153,
            105.83689340011709,
            21.014792894558397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669759486378,
              21.014792894558397
            ],
            [
              105.83670908146806,
              21.01478292608491
            ],
            [
              105.8367456790928,
              21.014757922782895
            ],
            [
              105.83675911254998,
              21.01474793840867
            ],
            [
              105.83678219580729,
              21.01473204013077
            ],
            [
              105.83682427682135,
              21.0146974546333
            ],
            [
              105.83682570889307,
              21.014698618168197
            ],
            [
              105.83682735483899,
              21.014699495232236
            ],
            [
              105.83682915291092,
              21.014700052709838
            ],
            [
              105.83683103180186,
              21.014700268371122
            ],
            [
              105.8368329202857,
              21.014700134437398
            ],
            [
              105.8368347433641,
              21.014699656696898
            ],
            [
              105.83683643187064,
              21.01469885174788
            ],
            [
              105.8368365726961,
              21.014698748087643
            ],
            [
              105.8368379196154,
              21.01469775243198
            ],
            [
              105.83683914911592,
              21.014696400580284
            ],
            [
              105.83684007256925,
              21.014694848814898
            ],
            [
              105.83684065472255,
              21.014693157825267
            ],
            [
              105.83684087284789,
              21.01469139185211
            ],
            [
              105.83684071867111,
              21.01468961958118
            ],
            [
              105.83684019738972,
              21.014687910535166
            ],
            [
              105.8368393295716,
              21.014686330548273
            ],
            [
              105.83683814922588,
              21.014684940872485
            ],
            [
              105.83688580372682,
              21.014638647449654
            ],
            [
              105.83689340011709,
              21.01463158390153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.3_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5872",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014792894558397,
          "to_latitude": 21.01463158390153,
          "to_longitude": 105.83689340011709,
          "from_longitude": 105.83669759486378
        }
      },
      {
        "id": 5865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718073831552,
            21.01508826661144,
            105.83729967026252,
            21.015169006713066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83729967026252,
              21.01508826661144
            ],
            [
              105.83719097893596,
              21.015160540078
            ],
            [
              105.83718073831552,
              21.015169006713066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.1_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5873",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01508826661144,
          "to_latitude": 21.015169006713066,
          "to_longitude": 105.83718073831552,
          "from_longitude": 105.83729967026252
        }
      },
      {
        "id": 5866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370337913373,
            21.014023685331207,
            105.83706556103121,
            21.014045376305827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706556103121,
              21.014023685331207
            ],
            [
              105.8370337913373,
              21.014045376305827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5874",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014023685331207,
          "to_latitude": 21.014045376305827,
          "to_longitude": 105.8370337913373,
          "from_longitude": 105.83706556103121
        }
      },
      {
        "id": 5867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530935743943,
            21.01496135712536,
            105.83540248156854,
            21.015091837631317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540248156854,
              21.015091837631317
            ],
            [
              105.83537223058725,
              21.015043627530385
            ],
            [
              105.8353324200477,
              21.01498956359404
            ],
            [
              105.83530935743943,
              21.01496135712536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.6_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5875",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015091837631317,
          "to_latitude": 21.01496135712536,
          "to_longitude": 105.83530935743943,
          "from_longitude": 105.83540248156854
        }
      },
      {
        "id": 5868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83652407519023,
            21.01808548415424,
            105.8365382797108,
            21.01812779718577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365382797108,
              21.01812779718577
            ],
            [
              105.83652407519023,
              21.01808548415424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.6A_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5876",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01812779718577,
          "to_latitude": 21.01808548415424,
          "to_longitude": 105.83652407519023,
          "from_longitude": 105.8365382797108
        }
      },
      {
        "id": 5869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594439299598,
            21.01629894854219,
            105.83600129938618,
            21.016385436414957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83600129938618,
              21.01629894854219
            ],
            [
              105.83597416306904,
              21.0163364058261
            ],
            [
              105.83594439299598,
              21.016385436414957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.20_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5877",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629894854219,
          "to_latitude": 21.016385436414957,
          "to_longitude": 105.83594439299598,
          "from_longitude": 105.83600129938618
        }
      },
      {
        "id": 5870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83799049932085,
            21.013550923390017,
            105.8380267083358,
            21.013590584461504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83799049932085,
              21.013550923390017
            ],
            [
              105.8380267083358,
              21.013590584461504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.15_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5878",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013550923390017,
          "to_latitude": 21.013590584461504,
          "to_longitude": 105.8380267083358,
          "from_longitude": 105.83799049932085
        }
      },
      {
        "id": 5871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352565337896,
            21.01518141976827,
            105.83528452366876,
            21.015237649882174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352565337896,
              21.01518141976827
            ],
            [
              105.83528452366876,
              21.015237649882174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.5_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5879",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01518141976827,
          "to_latitude": 21.015237649882174,
          "to_longitude": 105.83528452366876,
          "from_longitude": 105.8352565337896
        }
      },
      {
        "id": 5872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357424492205,
            21.017196543983957,
            105.83578586232005,
            21.017200078179734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578586232005,
              21.017200078179734
            ],
            [
              105.8357424492205,
              21.017196543983957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.8_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5880",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017200078179734,
          "to_latitude": 21.017196543983957,
          "to_longitude": 105.8357424492205,
          "from_longitude": 105.83578586232005
        }
      },
      {
        "id": 5873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8409190010827,
            21.016769874908643,
            105.84092289801585,
            21.016827712521884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84092289801585,
              21.016827712521884
            ],
            [
              105.84092087938863,
              21.016790569310157
            ],
            [
              105.8409190010827,
              21.016769874908643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A1_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5881",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016827712521884,
          "to_latitude": 21.016769874908643,
          "to_longitude": 105.8409190010827,
          "from_longitude": 105.84092289801585
        }
      },
      {
        "id": 5874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691918994188,
            21.015902974544925,
            105.83696486877786,
            21.0159759398857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83691918994188,
              21.015902974544925
            ],
            [
              105.83695510474516,
              21.015959498661307
            ],
            [
              105.83696486877786,
              21.0159759398857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.4_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5882",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015902974544925,
          "to_latitude": 21.0159759398857,
          "to_longitude": 105.83696486877786,
          "from_longitude": 105.83691918994188
        }
      },
      {
        "id": 5875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83817621142632,
            21.015177664496232,
            105.83821391527913,
            21.015220685362348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83817621142632,
              21.015177664496232
            ],
            [
              105.83821391527913,
              21.015220685362348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.1_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5883",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015177664496232,
          "to_latitude": 21.015220685362348,
          "to_longitude": 105.83821391527913,
          "from_longitude": 105.83817621142632
        }
      },
      {
        "id": 5876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84025683218243,
            21.0165172653502,
            105.84037973728327,
            21.016520832961785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84037973728327,
              21.016520832961785
            ],
            [
              105.84025683218243,
              21.0165172653502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.6_Ngách 222/61, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5884",
          "diaChiLapD": "Ngách 222/61, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016520832961785,
          "to_latitude": 21.0165172653502,
          "to_longitude": 105.84025683218243,
          "from_longitude": 105.84037973728327
        }
      },
      {
        "id": 5877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364237726582,
            21.015859951570707,
            105.83649518659797,
            21.015918331592818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364237726582,
              21.015918331592818
            ],
            [
              105.83649518659797,
              21.0158699732024
            ],
            [
              105.83648952593653,
              21.015860202858647
            ],
            [
              105.8364893812064,
              21.015859951570707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.9_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5885",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015918331592818,
          "to_latitude": 21.015859951570707,
          "to_longitude": 105.8364893812064,
          "from_longitude": 105.8364237726582
        }
      },
      {
        "id": 5878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634004446215,
            21.016818486396666,
            105.83638415035955,
            21.016878999641616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83638415035955,
              21.016878999641616
            ],
            [
              105.83634004446215,
              21.016818486396666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.15_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5886",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016878999641616,
          "to_latitude": 21.016818486396666,
          "to_longitude": 105.83634004446215,
          "from_longitude": 105.83638415035955
        }
      },
      {
        "id": 5879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83649518659797,
            21.0158699732024,
            105.83654308943288,
            21.01593073058795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83649518659797,
              21.0158699732024
            ],
            [
              105.83654308943288,
              21.01593073058795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.9_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5887",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0158699732024,
          "to_latitude": 21.01593073058795,
          "to_longitude": 105.83654308943288,
          "from_longitude": 105.83649518659797
        }
      },
      {
        "id": 5880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746561460758,
            21.0128212378625,
            105.83749043249698,
            21.01288305427029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746561460758,
              21.01288305427029
            ],
            [
              105.83749043249698,
              21.012871407874666
            ],
            [
              105.83747124880107,
              21.0128212378625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5888",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01288305427029,
          "to_latitude": 21.0128212378625,
          "to_longitude": 105.83747124880107,
          "from_longitude": 105.83746561460758
        }
      },
      {
        "id": 5881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603755435928,
            21.01639875671554,
            105.83607369731541,
            21.016429486799943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83607369731541,
              21.01639875671554
            ],
            [
              105.83603755435928,
              21.016429486799943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.20_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5889",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01639875671554,
          "to_latitude": 21.016429486799943,
          "to_longitude": 105.83603755435928,
          "from_longitude": 105.83607369731541
        }
      },
      {
        "id": 5882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708801517257,
            21.01523390439136,
            105.83713527188392,
            21.015300743491316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83708801517257,
              21.01523390439136
            ],
            [
              105.83713527188392,
              21.015300743491316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.1_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5890",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01523390439136,
          "to_latitude": 21.015300743491316,
          "to_longitude": 105.83713527188392,
          "from_longitude": 105.83708801517257
        }
      },
      {
        "id": 5883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883079665496,
            21.015345185836846,
            105.83887522583862,
            21.015378701933397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883079665496,
              21.015352153958528
            ],
            [
              105.83883942945486,
              21.01534672185728
            ],
            [
              105.8388410591544,
              21.015345894597885
            ],
            [
              105.83884282717737,
              21.015345376462246
            ],
            [
              105.83884466821497,
              21.015345185836846
            ],
            [
              105.83884651401225,
              21.015345330284017
            ],
            [
              105.83884829623837,
              21.015345803818093
            ],
            [
              105.83884994842585,
              21.015346589605578
            ],
            [
              105.83885140980776,
              21.015347658139675
            ],
            [
              105.83885262629504,
              21.01534896994526
            ],
            [
              105.83887522583862,
              21.015378701933397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.1_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5891",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015352153958528,
          "to_latitude": 21.015378701933397,
          "to_longitude": 105.83887522583862,
          "from_longitude": 105.83883079665496
        }
      },
      {
        "id": 5884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411000765763,
            21.01871224245432,
            105.83414610805147,
            21.01878610815382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83414610805147,
              21.01878610815382
            ],
            [
              105.83411000765763,
              21.01871224245432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5892",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01878610815382,
          "to_latitude": 21.01871224245432,
          "to_longitude": 105.83411000765763,
          "from_longitude": 105.83414610805147
        }
      },
      {
        "id": 5885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252487491896,
            21.018415330068787,
            105.83256726694498,
            21.018488561984583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83256726694498,
              21.018488561984583
            ],
            [
              105.83252487491896,
              21.018415330068787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.4_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5893",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018488561984583,
          "to_latitude": 21.018415330068787,
          "to_longitude": 105.83252487491896,
          "from_longitude": 105.83256726694498
        }
      },
      {
        "id": 5886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84069813778689,
            21.015190554814673,
            105.8407063365693,
            21.015273148418263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8407063365693,
              21.015273148418263
            ],
            [
              105.84069813778689,
              21.015190554814673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.5_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5894",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015273148418263,
          "to_latitude": 21.015190554814673,
          "to_longitude": 105.84069813778689,
          "from_longitude": 105.8407063365693
        }
      },
      {
        "id": 5887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83788838977306,
            21.014946023333422,
            105.83796119700622,
            21.014994486728447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83796119700622,
              21.014946023333422
            ],
            [
              105.83788838977306,
              21.014994486728447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.12_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5895",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014946023333422,
          "to_latitude": 21.014994486728447,
          "to_longitude": 105.83788838977306,
          "from_longitude": 105.83796119700622
        }
      },
      {
        "id": 5888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83564566209232,
            21.015493979139798,
            105.83569813129827,
            21.01555189538658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569813129827,
              21.01555189538658
            ],
            [
              105.83568152278922,
              21.015530190293237
            ],
            [
              105.8356804869347,
              21.015528836028665
            ],
            [
              105.8356631184875,
              21.015517833359247
            ],
            [
              105.83565091420053,
              21.015501738233546
            ],
            [
              105.83564566209232,
              21.015493979139798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.15_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5896",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01555189538658,
          "to_latitude": 21.015493979139798,
          "to_longitude": 105.83564566209232,
          "from_longitude": 105.83569813129827
        }
      },
      {
        "id": 5889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8411817965978,
            21.013958648645154,
            105.84127563841119,
            21.014020275978293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84127563841119,
              21.013958648645154
            ],
            [
              105.8411817965978,
              21.013961513399757
            ],
            [
              105.84118696171218,
              21.014020275978293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5897",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013958648645154,
          "to_latitude": 21.014020275978293,
          "to_longitude": 105.84118696171218,
          "from_longitude": 105.84127563841119
        }
      },
      {
        "id": 5890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710636118805,
            21.01270834814282,
            105.83717309336689,
            21.012835498405128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717309336689,
              21.012835498405128
            ],
            [
              105.83710636118805,
              21.012783427071106
            ],
            [
              105.83716845244672,
              21.01270834814282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.6_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5898",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012835498405128,
          "to_latitude": 21.01270834814282,
          "to_longitude": 105.83716845244672,
          "from_longitude": 105.83717309336689
        }
      },
      {
        "id": 5891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83802792388038,
            21.014323864623332,
            105.8380852507859,
            21.0143897822491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83802792388038,
              21.014323864623332
            ],
            [
              105.83803212213292,
              21.014328240839404
            ],
            [
              105.83804175830583,
              21.014340046385097
            ],
            [
              105.8380852507859,
              21.0143897822491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.2_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5899",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014323864623332,
          "to_latitude": 21.0143897822491,
          "to_longitude": 105.8380852507859,
          "from_longitude": 105.83802792388038
        }
      },
      {
        "id": 5892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436504731246,
            21.01752722265253,
            105.83444212113875,
            21.01757242501029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444212113875,
              21.01752722265253
            ],
            [
              105.83439582722951,
              21.01755538464847
            ],
            [
              105.83436504731246,
              21.01757242501029
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5900",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01752722265253,
          "to_latitude": 21.01757242501029,
          "to_longitude": 105.83436504731246,
          "from_longitude": 105.83444212113875
        }
      },
      {
        "id": 5893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83416398178827,
            21.018523538425683,
            105.83419630560276,
            21.01860274771496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416409836413,
              21.018523538425683
            ],
            [
              105.83416398178827,
              21.018536650202044
            ],
            [
              105.83419630560276,
              21.01860274771496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.5_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5901",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018523538425683,
          "to_latitude": 21.01860274771496,
          "to_longitude": 105.83419630560276,
          "from_longitude": 105.83416409836413
        }
      },
      {
        "id": 5894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440117590126,
            21.016224702815983,
            105.83445814024898,
            21.01627919585611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83445814024898,
              21.01627919585611
            ],
            [
              105.83440117590126,
              21.016224702815983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5902",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01627919585611,
          "to_latitude": 21.016224702815983,
          "to_longitude": 105.83440117590126,
          "from_longitude": 105.83445814024898
        }
      },
      {
        "id": 5895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551559722773,
            21.015601804491226,
            105.83556880133014,
            21.015670942441353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83556880133014,
              21.015670942441353
            ],
            [
              105.83551559722773,
              21.015601804491226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.14_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5903",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015670942441353,
          "to_latitude": 21.015601804491226,
          "to_longitude": 105.83551559722773,
          "from_longitude": 105.83556880133014
        }
      },
      {
        "id": 5896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979759538343,
            21.017343062381208,
            105.83982412929542,
            21.01756596978813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979759538343,
              21.017343062381208
            ],
            [
              105.83980157809872,
              21.017372485096736
            ],
            [
              105.83980341403432,
              21.017391307340198
            ],
            [
              105.8398034572709,
              21.017420159698194
            ],
            [
              105.83982412929542,
              21.01756596978813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.7_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5904",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017343062381208,
          "to_latitude": 21.01756596978813,
          "to_longitude": 105.83982412929542,
          "from_longitude": 105.83979759538343
        }
      },
      {
        "id": 5897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591709758473,
            21.017743508506566,
            105.83600367003912,
            21.017758896742748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591709758473,
              21.017758896742748
            ],
            [
              105.83591961828417,
              21.017758449002375
            ],
            [
              105.83592770507926,
              21.01775701107388
            ],
            [
              105.83600367003912,
              21.017743508506566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5905",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017758896742748,
          "to_latitude": 21.017743508506566,
          "to_longitude": 105.83600367003912,
          "from_longitude": 105.83591709758473
        }
      },
      {
        "id": 5898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584595193813,
            21.01506772747424,
            105.8359273731124,
            21.015122741876688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359273731124,
              21.01506772747424
            ],
            [
              105.83591943638918,
              21.015073629299934
            ],
            [
              105.83588626738491,
              21.015095444363173
            ],
            [
              105.83584595193813,
              21.015122741876688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.8_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5906",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01506772747424,
          "to_latitude": 21.015122741876688,
          "to_longitude": 105.83584595193813,
          "from_longitude": 105.8359273731124
        }
      },
      {
        "id": 5899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421939989022,
            21.017157936048243,
            105.83428033286012,
            21.01722562384665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428033286012,
              21.01722562384665
            ],
            [
              105.83427379369495,
              21.017222537984495
            ],
            [
              105.83427143265328,
              21.017221424145525
            ],
            [
              105.83426974481569,
              21.017220627649795
            ],
            [
              105.83421939989022,
              21.017157936048243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.3_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5907",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01722562384665,
          "to_latitude": 21.017157936048243,
          "to_longitude": 105.83421939989022,
          "from_longitude": 105.83428033286012
        }
      },
      {
        "id": 5900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427085187509,
            21.018625485707275,
            105.83430956700515,
            21.018700230933195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430956700515,
              21.018700230933195
            ],
            [
              105.83427085187509,
              21.018625485707275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5908",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018700230933195,
          "to_latitude": 21.018625485707275,
          "to_longitude": 105.83427085187509,
          "from_longitude": 105.83430956700515
        }
      },
      {
        "id": 5901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902196406848,
            21.014649667339523,
            105.83910059251798,
            21.01468770935734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902196406848,
              21.01468770935734
            ],
            [
              105.8390591501425,
              21.014649667339523
            ],
            [
              105.83909538793651,
              21.01468268119618
            ],
            [
              105.83910059251798,
              21.014687420936724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.23_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5909",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01468770935734,
          "to_latitude": 21.014687420936724,
          "to_longitude": 105.83910059251798,
          "from_longitude": 105.83902196406848
        }
      },
      {
        "id": 5902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815138368576,
            21.015094745541507,
            105.83829799431763,
            21.015187604856614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815138368576,
              21.015187604856614
            ],
            [
              105.83817621142632,
              21.015177664496232
            ],
            [
              105.83818777516586,
              21.015170151411347
            ],
            [
              105.8382090840944,
              21.01515761896823
            ],
            [
              105.83829799431763,
              21.015094745541507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.1_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5910",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015187604856614,
          "to_latitude": 21.015094745541507,
          "to_longitude": 105.83829799431763,
          "from_longitude": 105.83815138368576
        }
      },
      {
        "id": 5903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362479654326,
            21.015350811997724,
            105.83632609117495,
            21.015412009219837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362479654326,
              21.015412009219837
            ],
            [
              105.83629515569747,
              21.015375716929565
            ],
            [
              105.83632609117495,
              21.015350811997724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5911",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015412009219837,
          "to_latitude": 21.015350811997724,
          "to_longitude": 105.83632609117495,
          "from_longitude": 105.8362479654326
        }
      },
      {
        "id": 5904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83851160853821,
            21.014471271269816,
            105.83857923118983,
            21.014530632055767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857923118983,
              21.014471271269816
            ],
            [
              105.83851160853821,
              21.014530632055767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.6_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5912",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014471271269816,
          "to_latitude": 21.014530632055767,
          "to_longitude": 105.83851160853821,
          "from_longitude": 105.83857923118983
        }
      },
      {
        "id": 5905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543672834378,
            21.015550213946877,
            105.83551559722773,
            21.015601804491226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83551559722773,
              21.015601804491226
            ],
            [
              105.83551395550575,
              21.015589079799106
            ],
            [
              105.83549616691496,
              21.015565854936476
            ],
            [
              105.83543672834378,
              21.015550213946877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.14_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5913",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015601804491226,
          "to_latitude": 21.015550213946877,
          "to_longitude": 105.83543672834378,
          "from_longitude": 105.83551559722773
        }
      },
      {
        "id": 5906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84076172514195,
            21.017293754045138,
            105.84087320518589,
            21.01730025638098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84076172514195,
              21.01730025638098
            ],
            [
              105.84087320518589,
              21.017293754045138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A3_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5914",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01730025638098,
          "to_latitude": 21.017293754045138,
          "to_longitude": 105.84087320518589,
          "from_longitude": 105.84076172514195
        }
      },
      {
        "id": 5907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561404994201,
            21.01557531427508,
            105.83569921697898,
            21.015640392635625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569921697898,
              21.01557531427508
            ],
            [
              105.83561404994201,
              21.015640392635625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.14_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5915",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01557531427508,
          "to_latitude": 21.015640392635625,
          "to_longitude": 105.83561404994201,
          "from_longitude": 105.83569921697898
        }
      },
      {
        "id": 5908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8349907344122,
            21.018815885108936,
            105.83509005263502,
            21.01884801891666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349907344122,
              21.01884801891666
            ],
            [
              105.834993903162,
              21.018846993557855
            ],
            [
              105.83499890402732,
              21.01884537572298
            ],
            [
              105.83509005263502,
              21.018815885108936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.7_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5916",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01884801891666,
          "to_latitude": 21.018815885108936,
          "to_longitude": 105.83509005263502,
          "from_longitude": 105.8349907344122
        }
      },
      {
        "id": 5909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8404518888889,
            21.014791587394875,
            105.84055904784294,
            21.014850111667183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8404518888889,
              21.014791587394875
            ],
            [
              105.84045230201032,
              21.014792011675652
            ],
            [
              105.84046666117821,
              21.014806682254875
            ],
            [
              105.84055904784294,
              21.014850111667183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.5_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5917",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014791587394875,
          "to_latitude": 21.014850111667183,
          "to_longitude": 105.84055904784294,
          "from_longitude": 105.8404518888889
        }
      },
      {
        "id": 5910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803681507239,
            21.015966256823962,
            105.83813053576057,
            21.01602014240466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803681507239,
              21.01602014240466
            ],
            [
              105.8380729974302,
              21.016000000208685
            ],
            [
              105.83809957536359,
              21.015985291244473
            ],
            [
              105.83811870032865,
              21.015973532793474
            ],
            [
              105.83813053576057,
              21.015966256823962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.23_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5918",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01602014240466,
          "to_latitude": 21.015966256823962,
          "to_longitude": 105.83813053576057,
          "from_longitude": 105.83803681507239
        }
      },
      {
        "id": 5911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612341853188,
            21.018793540089757,
            105.83622432915146,
            21.01881227401959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83622432915146,
              21.018793540089757
            ],
            [
              105.83621813854583,
              21.01879467335816
            ],
            [
              105.83620909191866,
              21.018796329180997
            ],
            [
              105.83616693199122,
              21.018804047634333
            ],
            [
              105.83612341853188,
              21.01881227401959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.5919",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018793540089757,
          "to_latitude": 21.01881227401959,
          "to_longitude": 105.83612341853188,
          "from_longitude": 105.83622432915146
        }
      },
      {
        "id": 5912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869400050669,
            21.012422249533966,
            105.83874727237905,
            21.012510337132678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83874727237905,
              21.012422249533966
            ],
            [
              105.83869400050669,
              21.012510337132678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.15_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5920",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012422249533966,
          "to_latitude": 21.012510337132678,
          "to_longitude": 105.83869400050669,
          "from_longitude": 105.83874727237905
        }
      },
      {
        "id": 5913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415551487178,
            21.01623703705086,
            105.83419296593806,
            21.016306300709072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415551487178,
              21.01623703705086
            ],
            [
              105.83419296593806,
              21.01630359629405
            ],
            [
              105.83418906491715,
              21.016306300709072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15.1_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5921",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01623703705086,
          "to_latitude": 21.016306300709072,
          "to_longitude": 105.83418906491715,
          "from_longitude": 105.83415551487178
        }
      },
      {
        "id": 5914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589464389276,
            21.01603233260803,
            105.83598079100985,
            21.016101773079665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598079100985,
              21.01603233260803
            ],
            [
              105.83593619580301,
              21.016068111679004
            ],
            [
              105.83589464389276,
              21.016101773079665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.19_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5922",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01603233260803,
          "to_latitude": 21.016101773079665,
          "to_longitude": 105.83589464389276,
          "from_longitude": 105.83598079100985
        }
      },
      {
        "id": 5915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400342900079,
            21.016127517248417,
            105.84010168011271,
            21.01624882258448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009511011638,
              21.016130754549554
            ],
            [
              105.8400998991115,
              21.01613341347501
            ],
            [
              105.84010115588848,
              21.01613384352588
            ],
            [
              105.84010168011271,
              21.016132120296263
            ],
            [
              105.84009554840881,
              21.016129661291153
            ],
            [
              105.84009020148746,
              21.016127517248417
            ],
            [
              105.84007684686424,
              21.016152931260518
            ],
            [
              105.84005799816158,
              21.016192865526424
            ],
            [
              105.8400342900079,
              21.01624882258448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.5_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5923",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016130754549554,
          "to_latitude": 21.01624882258448,
          "to_longitude": 105.8400342900079,
          "from_longitude": 105.84009511011638
        }
      },
      {
        "id": 5916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357970583121,
            21.018214005194285,
            105.83581678204071,
            21.01832565426804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83581678204071,
              21.01832565426804
            ],
            [
              105.8358154043471,
              21.018317855374544
            ],
            [
              105.8357970583121,
              21.018214005194285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5924",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832565426804,
          "to_latitude": 21.018214005194285,
          "to_longitude": 105.8357970583121,
          "from_longitude": 105.83581678204071
        }
      },
      {
        "id": 5917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945096331345,
            21.015523757090417,
            105.83949869767477,
            21.01561497977529
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945096331345,
              21.01561497977529
            ],
            [
              105.8394702541319,
              21.015573758102526
            ],
            [
              105.83947539750065,
              21.015562912102975
            ],
            [
              105.83948294258326,
              21.01554841426061
            ],
            [
              105.83949869767477,
              21.015523757090417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.10_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5925",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01561497977529,
          "to_latitude": 21.015523757090417,
          "to_longitude": 105.83949869767477,
          "from_longitude": 105.83945096331345
        }
      },
      {
        "id": 5918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338501979034,
            21.015313706715567,
            105.83394325140881,
            21.015406839094663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394325140881,
              21.015313706715567
            ],
            [
              105.8338501979034,
              21.015406839094663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.1_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5926",
          "diaChiLapD": "Ngõ 164, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015313706715567,
          "to_latitude": 21.015406839094663,
          "to_longitude": 105.8338501979034,
          "from_longitude": 105.83394325140881
        }
      },
      {
        "id": 5919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365236503123,
            21.013868048840006,
            105.83666202015009,
            21.014063443071063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666202015009,
              21.014063443071063
            ],
            [
              105.83659808160859,
              21.013974774347073
            ],
            [
              105.83658366473524,
              21.01395662162717
            ],
            [
              105.8365236503123,
              21.013868048840006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5927",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": 25.986,
          "from_latitude": 21.014063443071063,
          "to_latitude": 21.013868048840006,
          "to_longitude": 105.8365236503123,
          "from_longitude": 105.83666202015009
        }
      },
      {
        "id": 5920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839865825057,
            21.015836437659352,
            105.83997446517785,
            21.01588211473895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839865825057,
              21.015836437659352
            ],
            [
              105.83997446517785,
              21.01588211473895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT72.4_Ngách 1/34, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5928",
          "diaChiLapD": "Ngách 1/34, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015836437659352,
          "to_latitude": 21.01588211473895,
          "to_longitude": 105.83997446517785,
          "from_longitude": 105.839865825057
        }
      },
      {
        "id": 5921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841615284878,
            21.012339220437717,
            105.83851531124797,
            21.012423270884835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83841615284878,
              21.012339220437717
            ],
            [
              105.83851531124797,
              21.012423270884835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.13_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5929",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012339220437717,
          "to_latitude": 21.012423270884835,
          "to_longitude": 105.83851531124797,
          "from_longitude": 105.83841615284878
        }
      },
      {
        "id": 5922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763651818681,
            21.014695739153083,
            105.83772349501085,
            21.014789967897837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763651818681,
              21.014695739153083
            ],
            [
              105.83772349501085,
              21.014789967897837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.7_Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5930",
          "diaChiLapD": "Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014695739153083,
          "to_latitude": 21.014789967897837,
          "to_longitude": 105.83772349501085,
          "from_longitude": 105.83763651818681
        }
      },
      {
        "id": 5923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584060284159,
            21.015696842169618,
            105.83593510398356,
            21.01578183703054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584859159866,
              21.01578183703054
            ],
            [
              105.83584060284159,
              21.015771676167045
            ],
            [
              105.83593510398356,
              21.015696842169618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.17_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5931",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01578183703054,
          "to_latitude": 21.015696842169618,
          "to_longitude": 105.83593510398356,
          "from_longitude": 105.83584859159866
        }
      },
      {
        "id": 5924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529926834565,
            21.01704828488611,
            105.83542455525146,
            21.017103170333034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83529926834565,
              21.017103170333034
            ],
            [
              105.83542455525146,
              21.01704828488611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.14_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5932",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017103170333034,
          "to_latitude": 21.01704828488611,
          "to_longitude": 105.83542455525146,
          "from_longitude": 105.83529926834565
        }
      },
      {
        "id": 5925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83600824008549,
            21.015172237402044,
            105.83610296170545,
            21.015236552507226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83610296170545,
              21.015236552507226
            ],
            [
              105.83603957444379,
              21.015172237402044
            ],
            [
              105.83600824008549,
              21.015200309140884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.3_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5933",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015236552507226,
          "to_latitude": 21.015200309140884,
          "to_longitude": 105.83600824008549,
          "from_longitude": 105.83610296170545
        }
      },
      {
        "id": 5926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883530431577,
            21.015617058782293,
            105.83895478542766,
            21.01569823989095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883530431577,
              21.01569823989095
            ],
            [
              105.83890492564798,
              21.015656143273194
            ],
            [
              105.83891897643622,
              21.01564664613768
            ],
            [
              105.83895478542766,
              21.015617058782293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.16_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5934",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01569823989095,
          "to_latitude": 21.015617058782293,
          "to_longitude": 105.83895478542766,
          "from_longitude": 105.83883530431577
        }
      },
      {
        "id": 5927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673211936231,
            21.013408882868816,
            105.83680945850809,
            21.013474650352222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83680945850809,
              21.013408882868816
            ],
            [
              105.83673211936231,
              21.013474650352222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.8_Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5935",
          "diaChiLapD": "Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013408882868816,
          "to_latitude": 21.013474650352222,
          "to_longitude": 105.83673211936231,
          "from_longitude": 105.83680945850809
        }
      },
      {
        "id": 5928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044160986072,
            21.017169487936595,
            105.84058236958593,
            21.017171235698086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84058236958593,
              21.017171235698086
            ],
            [
              105.84044160986072,
              21.017169487936595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.3_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5936",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017171235698086,
          "to_latitude": 21.017169487936595,
          "to_longitude": 105.84044160986072,
          "from_longitude": 105.84058236958593
        }
      },
      {
        "id": 5929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83740697823568,
            21.01447468913373,
            105.83752295597482,
            21.014510446342342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752295597482,
              21.014488030081903
            ],
            [
              105.83752278931463,
              21.014486779936803
            ],
            [
              105.83752118753125,
              21.01447468913373
            ],
            [
              105.83746127159968,
              21.01448325398908
            ],
            [
              105.83742916210078,
              21.014498015367607
            ],
            [
              105.83740697823568,
              21.014510446342342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5937",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014488030081903,
          "to_latitude": 21.014510446342342,
          "to_longitude": 105.83740697823568,
          "from_longitude": 105.83752295597482
        }
      },
      {
        "id": 5930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836429890151,
            21.015055603114856,
            105.8384712667777,
            21.015133559131968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836429890151,
              21.015133559131968
            ],
            [
              105.8384712667777,
              21.015055603114856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.13_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5938",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015133559131968,
          "to_latitude": 21.015055603114856,
          "to_longitude": 105.8384712667777,
          "from_longitude": 105.83836429890151
        }
      },
      {
        "id": 5931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83432498758043,
            21.0179375035243,
            105.83445036120726,
            21.018006395908333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83445036120726,
              21.0179375035243
            ],
            [
              105.83441177078583,
              21.017959322329748
            ],
            [
              105.83436304856005,
              21.017985370943343
            ],
            [
              105.83432498758043,
              21.018006395908333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.5_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5939",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0179375035243,
          "to_latitude": 21.018006395908333,
          "to_longitude": 105.83432498758043,
          "from_longitude": 105.83445036120726
        }
      },
      {
        "id": 5932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644587353328,
            21.013680987935416,
            105.83656230083321,
            21.01376174320056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83644587353328,
              21.01376174320056
            ],
            [
              105.83644673592757,
              21.013761144639165
            ],
            [
              105.83656230083321,
              21.013680987935416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.12_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5940",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01376174320056,
          "to_latitude": 21.013680987935416,
          "to_longitude": 105.83656230083321,
          "from_longitude": 105.83644587353328
        }
      },
      {
        "id": 5933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84091722568782,
            21.016827712521884,
            105.8409274971687,
            21.01689329741551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84091722568782,
              21.01689329741551
            ],
            [
              105.84092617486007,
              21.016877893865665
            ],
            [
              105.8409274971687,
              21.016836480860782
            ],
            [
              105.84092289801585,
              21.016827712521884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A1_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5941",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01689329741551,
          "to_latitude": 21.016827712521884,
          "to_longitude": 105.84092289801585,
          "from_longitude": 105.84091722568782
        }
      },
      {
        "id": 5934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386193504066,
            21.015671713757744,
            105.83871133165333,
            21.01577440758854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386193504066,
              21.015671713757744
            ],
            [
              105.83871133165333,
              21.01577440758854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.17_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5942",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015671713757744,
          "to_latitude": 21.01577440758854,
          "to_longitude": 105.83871133165333,
          "from_longitude": 105.8386193504066
        }
      },
      {
        "id": 5935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879542283495,
            21.015197497799473,
            105.83891784618457,
            21.0152655186147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879542283495,
              21.0152655186147
            ],
            [
              105.83883547057862,
              21.015242907662216
            ],
            [
              105.83886211455086,
              21.01522903644972
            ],
            [
              105.83888190046638,
              21.01521732433473
            ],
            [
              105.83891612770877,
              21.015197497799473
            ],
            [
              105.83891773564321,
              21.015199831027058
            ],
            [
              105.83891784618457,
              21.015199988546165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.3_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5943",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0152655186147,
          "to_latitude": 21.015199988546165,
          "to_longitude": 105.83891784618457,
          "from_longitude": 105.83879542283495
        }
      },
      {
        "id": 5936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344863465469,
            21.015701237653857,
            105.83456027098487,
            21.015796940194576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344863465469,
              21.015701237653857
            ],
            [
              105.83450630093976,
              21.015732526829932
            ],
            [
              105.83451902348364,
              21.015746351501225
            ],
            [
              105.83456027098487,
              21.015796940194576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29.5_Ngách 164/39, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5944",
          "diaChiLapD": "Ngách 164/39, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015701237653857,
          "to_latitude": 21.015796940194576,
          "to_longitude": 105.83456027098487,
          "from_longitude": 105.8344863465469
        }
      },
      {
        "id": 5937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905025386565,
            21.01467087870049,
            105.8391206745325,
            21.01476104078575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391206745325,
              21.01467087870049
            ],
            [
              105.83909272976386,
              21.01471064637621
            ],
            [
              105.83905025386565,
              21.01476104078575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.22_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.5945",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01467087870049,
          "to_latitude": 21.01476104078575,
          "to_longitude": 105.83905025386565,
          "from_longitude": 105.8391206745325
        }
      },
      {
        "id": 5938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401519362329,
            21.0153231753724,
            105.84029543222688,
            21.01535537564207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401519362329,
              21.0153231753724
            ],
            [
              105.84020711868656,
              21.015333852637557
            ],
            [
              105.8402375318799,
              21.01534084603194
            ],
            [
              105.84025936556854,
              21.01534611870191
            ],
            [
              105.84027871020659,
              21.01535164030711
            ],
            [
              105.84029543222688,
              21.01535537564207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.10_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5946",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0153231753724,
          "to_latitude": 21.01535537564207,
          "to_longitude": 105.84029543222688,
          "from_longitude": 105.8401519362329
        }
      },
      {
        "id": 5939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849586037665,
            21.014617458917105,
            105.83860697617007,
            21.014710106811584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83849586037665,
              21.014617458917105
            ],
            [
              105.83860697617007,
              21.014710106811584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.3_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5947",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014617458917105,
          "to_latitude": 21.014710106811584,
          "to_longitude": 105.83860697617007,
          "from_longitude": 105.83849586037665
        }
      },
      {
        "id": 5940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760480786945,
            21.012809565256255,
            105.83765148073782,
            21.012842706713883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83765148073782,
              21.012809565256255
            ],
            [
              105.83764841245335,
              21.012813224871923
            ],
            [
              105.83762981815566,
              21.01283123818965
            ],
            [
              105.83760480786945,
              21.012842706713883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.3_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5948",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012809565256255,
          "to_latitude": 21.012842706713883,
          "to_longitude": 105.83760480786945,
          "from_longitude": 105.83765148073782
        }
      },
      {
        "id": 5941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83770867285773,
            21.014416752790225,
            105.83779481664983,
            21.014527193060555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83770867285773,
              21.014416752790225
            ],
            [
              105.83771606279693,
              21.0144293994777
            ],
            [
              105.83772856124668,
              21.01444647209994
            ],
            [
              105.8377371136369,
              21.014459886222483
            ],
            [
              105.83774366028688,
              21.01446828377829
            ],
            [
              105.83775434492448,
              21.01448163501373
            ],
            [
              105.83776717160622,
              21.01449736653516
            ],
            [
              105.83778577953974,
              21.014518964028277
            ],
            [
              105.83779481664983,
              21.014527193060555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.6_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5949",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014416752790225,
          "to_latitude": 21.014527193060555,
          "to_longitude": 105.83779481664983,
          "from_longitude": 105.83770867285773
        }
      },
      {
        "id": 5942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767633149003,
            21.014665591316884,
            105.83776545902127,
            21.01477132826828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767633149003,
              21.014665591316884
            ],
            [
              105.8377240248799,
              21.01471794309975
            ],
            [
              105.83776545902127,
              21.01477132826828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.7_Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5950",
          "diaChiLapD": "Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014665591316884,
          "to_latitude": 21.01477132826828,
          "to_longitude": 105.83776545902127,
          "from_longitude": 105.83767633149003
        }
      },
      {
        "id": 5943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526961687221,
            21.018756395093046,
            105.83536296028335,
            21.018866740652214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536296028335,
              21.018866740652214
            ],
            [
              105.83526961687221,
              21.018756395093046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5951",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018866740652214,
          "to_latitude": 21.018756395093046,
          "to_longitude": 105.83526961687221,
          "from_longitude": 105.83536296028335
        }
      },
      {
        "id": 5944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815167949899,
            21.015813758456748,
            105.83821483622997,
            21.015904797136752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83818953138818,
              21.015904797136752
            ],
            [
              105.83815167949899,
              21.015858726815384
            ],
            [
              105.83821483622997,
              21.015813758456748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.22_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5952",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015904797136752,
          "to_latitude": 21.015813758456748,
          "to_longitude": 105.83821483622997,
          "from_longitude": 105.83818953138818
        }
      },
      {
        "id": 5945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936812486691,
            21.015666508432275,
            105.83943966613883,
            21.01579007197068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936812486691,
              21.01579007197068
            ],
            [
              105.8393925453071,
              21.015751523830474
            ],
            [
              105.83940895982008,
              21.01572832392741
            ],
            [
              105.83941855460293,
              21.01570949314572
            ],
            [
              105.83943111159797,
              21.015683345183756
            ],
            [
              105.83943489933938,
              21.015675889350288
            ],
            [
              105.83943966613883,
              21.015666508432275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.9_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5953",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01579007197068,
          "to_latitude": 21.015666508432275,
          "to_longitude": 105.83943966613883,
          "from_longitude": 105.83936812486691
        }
      },
      {
        "id": 5946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83404218788615,
            21.015855588628362,
            105.83417162512058,
            21.015931915270258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83404218788615,
              21.015931915270258
            ],
            [
              105.83417162512058,
              21.015855588628362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15.3_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5954",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015931915270258,
          "to_latitude": 21.015855588628362,
          "to_longitude": 105.83417162512058,
          "from_longitude": 105.83404218788615
        }
      },
      {
        "id": 5947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602555896965,
            21.015739923812838,
            105.8361329451959,
            21.01582062445321
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361329451959,
              21.015739923812838
            ],
            [
              105.83604752565562,
              21.0157971320276
            ],
            [
              105.83604167865661,
              21.0158091510638
            ],
            [
              105.83602555896965,
              21.01582062445321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.12_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5955",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015739923812838,
          "to_latitude": 21.01582062445321,
          "to_longitude": 105.83602555896965,
          "from_longitude": 105.8361329451959
        }
      },
      {
        "id": 5948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936504834904,
            21.016283241226592,
            105.8394865454692,
            21.016392346784116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394865454692,
              21.016283241226592
            ],
            [
              105.83941217086229,
              21.016372223490315
            ],
            [
              105.83936504834904,
              21.016392346784116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.4_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5956",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016283241226592,
          "to_latitude": 21.016392346784116,
          "to_longitude": 105.83936504834904,
          "from_longitude": 105.8394865454692
        }
      },
      {
        "id": 5949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630526937351,
            21.015391219753358,
            105.83643463129056,
            21.01548197581202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83643463129056,
              21.015391219753358
            ],
            [
              105.83635430366968,
              21.01544533753805
            ],
            [
              105.83630526937351,
              21.01548197581202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5957",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015391219753358,
          "to_latitude": 21.01548197581202,
          "to_longitude": 105.83630526937351,
          "from_longitude": 105.83643463129056
        }
      },
      {
        "id": 5950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387788879295,
            21.01248548564363,
            105.8388539907161,
            21.012614471401697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388539907161,
              21.01248548564363
            ],
            [
              105.83885383064893,
              21.0124857601097
            ],
            [
              105.8387788879295,
              21.012614471401697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.15_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5958",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01248548564363,
          "to_latitude": 21.012614471401697,
          "to_longitude": 105.8387788879295,
          "from_longitude": 105.8388539907161
        }
      },
      {
        "id": 5951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83516243380696,
            21.018360139486592,
            105.83523442217805,
            21.018492890577562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523442217805,
              21.018492890577562
            ],
            [
              105.8352317503216,
              21.018487963060036
            ],
            [
              105.83516243380696,
              21.018360139486592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.3_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5959",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018492890577562,
          "to_latitude": 21.018360139486592,
          "to_longitude": 105.83516243380696,
          "from_longitude": 105.83523442217805
        }
      },
      {
        "id": 5952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696772052541,
            21.013906425954463,
            105.83706751099636,
            21.014022159223952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706751099636,
              21.014022159223952
            ],
            [
              105.83696772052541,
              21.013906425954463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5960",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014022159223952,
          "to_latitude": 21.013906425954463,
          "to_longitude": 105.83696772052541,
          "from_longitude": 105.83706751099636
        }
      },
      {
        "id": 5953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319705302088,
            21.017159188421363,
            105.8320195540142,
            21.01725533167289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8320195540142,
              21.017159188421363
            ],
            [
              105.83201938567312,
              21.01715936176064
            ],
            [
              105.8319705302088,
              21.017209806450957
            ],
            [
              105.83201707454391,
              21.01725533167289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26.1_Ngõ 268, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5961",
          "diaChiLapD": "Ngõ 268, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017159188421363,
          "to_latitude": 21.01725533167289,
          "to_longitude": 105.83201707454391,
          "from_longitude": 105.8320195540142
        }
      },
      {
        "id": 5954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83996397002232,
            21.01531329010343,
            105.84012973684601,
            21.015357545174126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012973684601,
              21.015357545174126
            ],
            [
              105.84012450598303,
              21.015355508042003
            ],
            [
              105.84012114568999,
              21.015353572769186
            ],
            [
              105.84009863311472,
              21.015340613387455
            ],
            [
              105.84003991157772,
              21.015330599366557
            ],
            [
              105.83996397002232,
              21.01531329010343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.12_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5962",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015357545174126,
          "to_latitude": 21.01531329010343,
          "to_longitude": 105.83996397002232,
          "from_longitude": 105.84012973684601
        }
      },
      {
        "id": 5955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969006293692,
            21.016876521840768,
            105.83972724249001,
            21.017025389178695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969006293692,
              21.016876521840768
            ],
            [
              105.83969661891675,
              21.016885687910126
            ],
            [
              105.83971141165772,
              21.016945646745093
            ],
            [
              105.83971497217867,
              21.01696340912613
            ],
            [
              105.83971996630565,
              21.016994456268538
            ],
            [
              105.83972724249001,
              21.017025389178695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.5963",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016876521840768,
          "to_latitude": 21.017025389178695,
          "to_longitude": 105.83972724249001,
          "from_longitude": 105.83969006293692
        }
      },
      {
        "id": 5956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749354347586,
            21.0156690534913,
            105.83762073221409,
            21.015752683235156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749354347586,
              21.015752683235156
            ],
            [
              105.83752911954302,
              21.015730078336926
            ],
            [
              105.8375465659424,
              21.015718664212365
            ],
            [
              105.83757047234693,
              21.015703568354063
            ],
            [
              105.83762073221409,
              21.0156690534913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.1_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5964",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015752683235156,
          "to_latitude": 21.0156690534913,
          "to_longitude": 105.83762073221409,
          "from_longitude": 105.83749354347586
        }
      },
      {
        "id": 5957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83881935297495,
            21.01224839928394,
            105.83904650687832,
            21.012371680359543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881935297495,
              21.01224839928394
            ],
            [
              105.83904650687832,
              21.012371680359543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.17_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.5965",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01224839928394,
          "to_latitude": 21.012371680359543,
          "to_longitude": 105.83904650687832,
          "from_longitude": 105.83881935297495
        }
      },
      {
        "id": 5958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368845455252,
            21.017784487300894,
            105.83382463543981,
            21.017869552041216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368845455252,
              21.017869552041216
            ],
            [
              105.8338242726101,
              21.017784975014088
            ],
            [
              105.83382463543981,
              21.017784487300894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11.2_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5966",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017869552041216,
          "to_latitude": 21.017784487300894,
          "to_longitude": 105.83382463543981,
          "from_longitude": 105.83368845455252
        }
      },
      {
        "id": 5959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83511521942415,
            21.014553442994515,
            105.8352192588991,
            21.014659699750585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352192588991,
              21.014659699750585
            ],
            [
              105.83511521942415,
              21.014553442994515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.5_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5967",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014659699750585,
          "to_latitude": 21.014553442994515,
          "to_longitude": 105.83511521942415,
          "from_longitude": 105.8352192588991
        }
      },
      {
        "id": 5960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213359146272,
            21.01750136743621,
            105.8322246840549,
            21.017629454327746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8322246840549,
              21.017629454327746
            ],
            [
              105.83213359146272,
              21.01750136743621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26.2_Ngách 268/7, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5968",
          "diaChiLapD": "Ngách 268/7, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017629454327746,
          "to_latitude": 21.01750136743621,
          "to_longitude": 105.83213359146272,
          "from_longitude": 105.8322246840549
        }
      },
      {
        "id": 5961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83576970945548,
            21.01554964726424,
            105.8359693038904,
            21.015662129143642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83576970945548,
              21.015662129143642
            ],
            [
              105.83580608225063,
              21.015639868426167
            ],
            [
              105.83586903589155,
              21.015602013003143
            ],
            [
              105.83590730572494,
              21.015581156490413
            ],
            [
              105.8359693038904,
              21.01554964726424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.16_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5969",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015662129143642,
          "to_latitude": 21.01554964726424,
          "to_longitude": 105.8359693038904,
          "from_longitude": 105.83576970945548
        }
      },
      {
        "id": 5962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83416657995066,
            21.01863227537147,
            105.83421920956135,
            21.01876682531979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421920956135,
              21.01876682531979
            ],
            [
              105.83418730267118,
              21.018697176097604
            ],
            [
              105.83419644591456,
              21.01869368997557
            ],
            [
              105.83416657995066,
              21.01863227537147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5970",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01876682531979,
          "to_latitude": 21.01863227537147,
          "to_longitude": 105.83416657995066,
          "from_longitude": 105.83421920956135
        }
      },
      {
        "id": 5963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84000008663735,
            21.016354106490354,
            105.84000308080368,
            21.01651768693293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84000008663735,
              21.01651768693293
            ],
            [
              105.84000308080368,
              21.016354106490354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.1_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5971",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01651768693293,
          "to_latitude": 21.016354106490354,
          "to_longitude": 105.84000308080368,
          "from_longitude": 105.84000008663735
        }
      },
      {
        "id": 5964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8374322465167,
            21.015256568488432,
            105.83751578122424,
            21.015391705032425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374322465167,
              21.015256568488432
            ],
            [
              105.83743940790647,
              21.015267903023283
            ],
            [
              105.8374753222248,
              21.015325540710386
            ],
            [
              105.83748282520955,
              21.01533758259256
            ],
            [
              105.83750455507827,
              21.01537268781583
            ],
            [
              105.83751578122424,
              21.015391705032425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.23_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5972",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015256568488432,
          "to_latitude": 21.015391705032425,
          "to_longitude": 105.83751578122424,
          "from_longitude": 105.8374322465167
        }
      },
      {
        "id": 5965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84003058777623,
            21.01632100933206,
            105.84003714266203,
            21.016491945443853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003058777623,
              21.01632100933206
            ],
            [
              105.84003076258801,
              21.01632817645114
            ],
            [
              105.84003327867397,
              21.01643151086763
            ],
            [
              105.84003714266203,
              21.016491945443853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.2_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5973",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01632100933206,
          "to_latitude": 21.016491945443853,
          "to_longitude": 105.84003714266203,
          "from_longitude": 105.84003058777623
        }
      },
      {
        "id": 5966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84094721373867,
            21.014582788437536,
            105.84111520355242,
            21.01459488675517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84094721373867,
              21.014582788437536
            ],
            [
              105.8409881461415,
              21.0145835480447
            ],
            [
              105.8410468752942,
              21.0145855275343
            ],
            [
              105.84108099406606,
              21.01458743628252
            ],
            [
              105.84108710038319,
              21.014587778223362
            ],
            [
              105.84111520355242,
              21.01459488675517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.1_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5974",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014582788437536,
          "to_latitude": 21.01459488675517,
          "to_longitude": 105.84111520355242,
          "from_longitude": 105.84094721373867
        }
      },
      {
        "id": 5967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824010440003,
            21.014142090722036,
            105.83836564114672,
            21.014241318755477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836564114672,
              21.014142090722036
            ],
            [
              105.83835071605117,
              21.014154682951364
            ],
            [
              105.83833422274151,
              21.014166710262543
            ],
            [
              105.83832794442357,
              21.014171389189173
            ],
            [
              105.83829345005874,
              21.014197094199236
            ],
            [
              105.83826455909288,
              21.014220594824824
            ],
            [
              105.83824809601222,
              21.014233558622397
            ],
            [
              105.83824010440003,
              21.014241318755477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.5_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5975",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014142090722036,
          "to_latitude": 21.014241318755477,
          "to_longitude": 105.83824010440003,
          "from_longitude": 105.83836564114672
        }
      },
      {
        "id": 5968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809507321573,
            21.01233016645778,
            105.83821898538012,
            21.012410677684095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809507321573,
              21.01237001433638
            ],
            [
              105.83814116584057,
              21.01233016645778
            ],
            [
              105.83821898538012,
              21.012410677684095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.10_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5976",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01237001433638,
          "to_latitude": 21.012410677684095,
          "to_longitude": 105.83821898538012,
          "from_longitude": 105.83809507321573
        }
      },
      {
        "id": 5969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83619555525809,
            21.01366117473133,
            105.83633523214854,
            21.013724714845743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633523214854,
              21.01366117473133
            ],
            [
              105.83625560767365,
              21.013711164875318
            ],
            [
              105.83624293709099,
              21.013694060466797
            ],
            [
              105.83619555525809,
              21.013724714845743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.9_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5977",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01366117473133,
          "to_latitude": 21.013724714845743,
          "to_longitude": 105.83619555525809,
          "from_longitude": 105.83633523214854
        }
      },
      {
        "id": 5970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836564114672,
            21.014113394567225,
            105.8384964670405,
            21.01417961626949
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836564114672,
              21.014142090722036
            ],
            [
              105.83837247250129,
              21.014136022619947
            ],
            [
              105.83839467136939,
              21.01411735107353
            ],
            [
              105.8384073967023,
              21.014113394567225
            ],
            [
              105.83842182912585,
              21.01411368202664
            ],
            [
              105.83844308860938,
              21.014133292836313
            ],
            [
              105.8384964670405,
              21.01417961626949
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.5_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5978",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014142090722036,
          "to_latitude": 21.01417961626949,
          "to_longitude": 105.8384964670405,
          "from_longitude": 105.83836564114672
        }
      },
      {
        "id": 5971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360163054692,
            21.01488279351794,
            105.83616238992965,
            21.01499191460903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83616238992965,
              21.01488279351794
            ],
            [
              105.83611099910866,
              21.014922897246134
            ],
            [
              105.83606684546177,
              21.014956202953627
            ],
            [
              105.8360163054692,
              21.01499191460903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.7_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5979",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488279351794,
          "to_latitude": 21.01499191460903,
          "to_longitude": 105.8360163054692,
          "from_longitude": 105.83616238992965
        }
      },
      {
        "id": 5972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84026790746374,
            21.01746960920683,
            105.84042018231062,
            21.017509964268996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84026790746374,
              21.01746960920683
            ],
            [
              105.84034532668706,
              21.017472315811325
            ],
            [
              105.84037900578801,
              21.01747358195152
            ],
            [
              105.84038529206545,
              21.017481641972203
            ],
            [
              105.84042018231062,
              21.01748978378001
            ],
            [
              105.84042004624851,
              21.017509964268996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.2_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5980",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01746960920683,
          "to_latitude": 21.017509964268996,
          "to_longitude": 105.84042004624851,
          "from_longitude": 105.84026790746374
        }
      },
      {
        "id": 5973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355878861606,
            21.017918029283777,
            105.83371014476178,
            21.018004082359447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355878861606,
              21.018004082359447
            ],
            [
              105.83368521576467,
              21.017933253370018
            ],
            [
              105.83371014476178,
              21.017918029283777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11.1_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5981",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018004082359447,
          "to_latitude": 21.017918029283777,
          "to_longitude": 105.83371014476178,
          "from_longitude": 105.83355878861606
        }
      },
      {
        "id": 5974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162276640358,
            21.01763895476087,
            105.83175793197228,
            21.017702909966808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83162276640358,
              21.017702909966808
            ],
            [
              105.83167054871923,
              21.01763895476087
            ],
            [
              105.83175793197228,
              21.01767706271971
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25.1_Ngõ 298, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.5982",
          "diaChiLapD": "Ngõ 298, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017702909966808,
          "to_latitude": 21.01767706271971,
          "to_longitude": 105.83175793197228,
          "from_longitude": 105.83162276640358
        }
      },
      {
        "id": 5975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83811870032865,
            21.015973532793474,
            105.83820488854515,
            21.016117005288113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83811870032865,
              21.015973532793474
            ],
            [
              105.83816702326504,
              21.0160423841308
            ],
            [
              105.83816619959953,
              21.016050947138663
            ],
            [
              105.83820488854515,
              21.016117005288113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.23_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5983",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015973532793474,
          "to_latitude": 21.016117005288113,
          "to_longitude": 105.83820488854515,
          "from_longitude": 105.83811870032865
        }
      },
      {
        "id": 5976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668671011195,
            21.016575573074793,
            105.83674188569822,
            21.016705739168135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668671011195,
              21.016575573074793
            ],
            [
              105.83669430628386,
              21.016588799561777
            ],
            [
              105.83669471564976,
              21.01659596643828
            ],
            [
              105.8366945448115,
              21.016604140553532
            ],
            [
              105.8367068875222,
              21.01662673451649
            ],
            [
              105.83674188569822,
              21.01668653465645
            ],
            [
              105.836707328253,
              21.016705739168135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.11_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5984",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016575573074793,
          "to_latitude": 21.016705739168135,
          "to_longitude": 105.836707328253,
          "from_longitude": 105.83668671011195
        }
      },
      {
        "id": 5977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354038106609,
            21.01800006913446,
            105.8355764660651,
            21.018021226106463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355764660651,
              21.01800006913446
            ],
            [
              105.83554563700987,
              21.01800499951954
            ],
            [
              105.83549468925013,
              21.018011404321854
            ],
            [
              105.83541725215696,
              21.0180198702923
            ],
            [
              105.8354038106609,
              21.018021226106463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.1_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.5985",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01800006913446,
          "to_latitude": 21.018021226106463,
          "to_longitude": 105.8354038106609,
          "from_longitude": 105.8355764660651
        }
      },
      {
        "id": 5978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83996535023358,
            21.015428265890797,
            105.84009721093604,
            21.015446332803613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009721093604,
              21.015446332803613
            ],
            [
              105.84002051187814,
              21.015428265890797
            ],
            [
              105.83999610153569,
              21.01543319427525
            ],
            [
              105.83996535023358,
              21.015430756561063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.12_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5986",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015446332803613,
          "to_latitude": 21.015430756561063,
          "to_longitude": 105.83996535023358,
          "from_longitude": 105.84009721093604
        }
      },
      {
        "id": 5979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83382580213308,
            21.016906856744825,
            105.83393331096548,
            21.017026258010443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83382580213308,
              21.017026258010443
            ],
            [
              105.83386067486427,
              21.01700070014857
            ],
            [
              105.8339026727431,
              21.016966150978817
            ],
            [
              105.83390130859917,
              21.01695246387808
            ],
            [
              105.83389836375018,
              21.016936313303216
            ],
            [
              105.83393331096548,
              21.016906856744825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.1_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5987",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017026258010443,
          "to_latitude": 21.016906856744825,
          "to_longitude": 105.83393331096548,
          "from_longitude": 105.83382580213308
        }
      },
      {
        "id": 5980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766929232839,
            21.01589475390956,
            105.83780543029368,
            21.016010423828476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769035422411,
              21.016010423828476
            ],
            [
              105.83766929232839,
              21.015978513662663
            ],
            [
              105.83775950870287,
              21.015923234701724
            ],
            [
              105.83780543029368,
              21.01589475390956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.28_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5988",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016010423828476,
          "to_latitude": 21.01589475390956,
          "to_longitude": 105.83780543029368,
          "from_longitude": 105.83769035422411
        }
      },
      {
        "id": 5981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83738465177117,
            21.012890033403785,
            105.83752013086226,
            21.012977129764945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83738465177117,
              21.012977129764945
            ],
            [
              105.83740008907274,
              21.012952391247108
            ],
            [
              105.83741343146623,
              21.012935262768398
            ],
            [
              105.8374238205864,
              21.012923788557075
            ],
            [
              105.83743103665651,
              21.012915818214903
            ],
            [
              105.83744259387142,
              21.01290939992271
            ],
            [
              105.83745204243966,
              21.01290347703988
            ],
            [
              105.8374629592194,
              21.012898646154085
            ],
            [
              105.83747611958928,
              21.01289457557808
            ],
            [
              105.83749084000912,
              21.012892367899905
            ],
            [
              105.83750822216014,
              21.01289086789179
            ],
            [
              105.83752013086226,
              21.012890033403785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.2_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5989",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012977129764945,
          "to_latitude": 21.012890033403785,
          "to_longitude": 105.83752013086226,
          "from_longitude": 105.83738465177117
        }
      },
      {
        "id": 5982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84103979575195,
            21.014143711784378,
            105.84113535963984,
            21.014251765372386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84113535963984,
              21.014251765372386
            ],
            [
              105.84112470927631,
              21.014210094514947
            ],
            [
              105.84111809895357,
              21.01418419654837
            ],
            [
              105.84110430975325,
              21.014143711784378
            ],
            [
              105.84103979575195,
              21.014153570554576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.2_Ngõ 324, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.5990",
          "diaChiLapD": "Ngõ 324, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014251765372386,
          "to_latitude": 21.014153570554576,
          "to_longitude": 105.84103979575195,
          "from_longitude": 105.84113535963984
        }
      },
      {
        "id": 5983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83844334665224,
            21.01229808755919,
            105.83855978784771,
            21.012393076391263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844334665224,
              21.012298686120047
            ],
            [
              105.83844477455865,
              21.012300311210062
            ],
            [
              105.83844930247288,
              21.012304130310735
            ],
            [
              105.83846052021512,
              21.01229808755919
            ],
            [
              105.83855978784771,
              21.012393076391263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.14_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5991",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012298686120047,
          "to_latitude": 21.012393076391263,
          "to_longitude": 105.83855978784771,
          "from_longitude": 105.83844334665224
        }
      },
      {
        "id": 5984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807185462786,
            21.012657042156665,
            105.83816395703955,
            21.012803603201895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816395703955,
              21.012657042156665
            ],
            [
              105.83815124179691,
              21.012678451594986
            ],
            [
              105.83807185462786,
              21.012803603201895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.7_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5992",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012657042156665,
          "to_latitude": 21.012803603201895,
          "to_longitude": 105.83807185462786,
          "from_longitude": 105.83816395703955
        }
      },
      {
        "id": 5985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326333303823,
            21.017343430987392,
            105.8333669993342,
            21.017482978092083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83326333303823,
              21.017343430987392
            ],
            [
              105.83328138932796,
              21.017358762394625
            ],
            [
              105.83331737785508,
              21.017394277933256
            ],
            [
              105.8333401085481,
              21.017430089634896
            ],
            [
              105.8333669993342,
              21.017482978092083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.8_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.5993",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017343430987392,
          "to_latitude": 21.017482978092083,
          "to_longitude": 105.8333669993342,
          "from_longitude": 105.83326333303823
        }
      },
      {
        "id": 5986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695142231804,
            21.01854605678396,
            105.83700052525155,
            21.01871203005363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695142231804,
              21.01854605678396
            ],
            [
              105.83697642304676,
              21.01862077761577
            ],
            [
              105.83698946524227,
              21.018656285109753
            ],
            [
              105.83700052525155,
              21.01871203005363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.5994",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01854605678396,
          "to_latitude": 21.01871203005363,
          "to_longitude": 105.83700052525155,
          "from_longitude": 105.83695142231804
        }
      },
      {
        "id": 5987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83995195270944,
            21.01554150306174,
            105.84007062894105,
            21.01561959464686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84007062894105,
              21.01554150306174
            ],
            [
              105.84005884451277,
              21.01554944639338
            ],
            [
              105.84002024127227,
              21.01557696042164
            ],
            [
              105.83999376457582,
              21.01559733148844
            ],
            [
              105.83996883279902,
              21.01561101377824
            ],
            [
              105.83995195270944,
              21.01561959464686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.13_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.5995",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01554150306174,
          "to_latitude": 21.01561959464686,
          "to_longitude": 105.83995195270944,
          "from_longitude": 105.84007062894105
        }
      },
      {
        "id": 5988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832106403132,
            21.014942891379114,
            105.83847277799913,
            21.015048217896293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832106403132,
              21.015048217896293
            ],
            [
              105.83847277799913,
              21.014942891379114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.13_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5996",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015048217896293,
          "to_latitude": 21.014942891379114,
          "to_longitude": 105.83847277799913,
          "from_longitude": 105.83832106403132
        }
      },
      {
        "id": 5989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83600109445808,
            21.015561129999924,
            105.83610703845537,
            21.015705264354363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83600109445808,
              21.015561129999924
            ],
            [
              105.83604404655667,
              21.01562959740006
            ],
            [
              105.83605402418799,
              21.015641582025584
            ],
            [
              105.83610703845537,
              21.015705264354363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.12_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5997",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015561129999924,
          "to_latitude": 21.015705264354363,
          "to_longitude": 105.83610703845537,
          "from_longitude": 105.83600109445808
        }
      },
      {
        "id": 5990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702717437498,
            21.013486745282073,
            105.8371746615326,
            21.01359062727042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702717437498,
              21.01359062727042
            ],
            [
              105.83708251541654,
              21.01354988784571
            ],
            [
              105.8371746615326,
              21.013486745282073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.4_Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5998",
          "diaChiLapD": "Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01359062727042,
          "to_latitude": 21.013486745282073,
          "to_longitude": 105.8371746615326,
          "from_longitude": 105.83702717437498
        }
      },
      {
        "id": 5991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83686472134606,
            21.014416439650354,
            105.83697834374149,
            21.014567354151747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697834374149,
              21.014567354151747
            ],
            [
              105.83696854824717,
              21.014554566619875
            ],
            [
              105.83696679218706,
              21.01455240119781
            ],
            [
              105.83692946375159,
              21.014506402969324
            ],
            [
              105.83686472134606,
              21.014416439650354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.5_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5999",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014567354151747,
          "to_latitude": 21.014416439650354,
          "to_longitude": 105.83686472134606,
          "from_longitude": 105.83697834374149
        }
      },
      {
        "id": 5992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83707134281903,
            21.01501479648841,
            105.83717404860079,
            21.015168929438197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717404860079,
              21.015168929438197
            ],
            [
              105.83717374886118,
              21.015168481107498
            ],
            [
              105.83717191926964,
              21.015165724437978
            ],
            [
              105.83717189585606,
              21.01515325644653
            ],
            [
              105.83709470025308,
              21.015046303445384
            ],
            [
              105.83707134281903,
              21.01501479648841
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.22_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6000",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015168929438197,
          "to_latitude": 21.01501479648841,
          "to_longitude": 105.83707134281903,
          "from_longitude": 105.83717404860079
        }
      },
      {
        "id": 5993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83780388133864,
            21.012817848954615,
            105.8379672605812,
            21.01290883817856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83780388133864,
              21.012817848954615
            ],
            [
              105.8379672605812,
              21.01290883817856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.4_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6001",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012817848954615,
          "to_latitude": 21.01290883817856,
          "to_longitude": 105.8379672605812,
          "from_longitude": 105.83780388133864
        }
      },
      {
        "id": 5994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83660448411919,
            21.01250730858949,
            105.83674499260873,
            21.012599686739303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660448411919,
              21.012599686739303
            ],
            [
              105.83663533614266,
              21.01256803327766
            ],
            [
              105.83664422654665,
              21.01255895822039
            ],
            [
              105.83665744043421,
              21.012554135961796
            ],
            [
              105.83669211789774,
              21.012564206201837
            ],
            [
              105.83672771159885,
              21.0125259015456
            ],
            [
              105.83674499260873,
              21.01250730858949
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.1_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.6002",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012599686739303,
          "to_latitude": 21.01250730858949,
          "to_longitude": 105.83674499260873,
          "from_longitude": 105.83660448411919
        }
      },
      {
        "id": 5995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814084424458,
            21.014542751716593,
            105.83821022410191,
            21.01466071100078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8381819849608,
              21.01466071100078
            ],
            [
              105.83814084424458,
              21.014589620395807
            ],
            [
              105.83821022410191,
              21.014542751716593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.10_Ngách 165/87, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6003",
          "diaChiLapD": "Ngách 165/87, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01466071100078,
          "to_latitude": 21.014542751716593,
          "to_longitude": 105.83821022410191,
          "from_longitude": 105.8381819849608
        }
      },
      {
        "id": 5996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015999890758,
            21.015904746329223,
            105.84027160066368,
            21.016003640662532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015999890758,
              21.01600320152119
            ],
            [
              105.84022141437508,
              21.016003640662532
            ],
            [
              105.84027160066368,
              21.015904746329223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.8_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6004",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01600320152119,
          "to_latitude": 21.015904746329223,
          "to_longitude": 105.84027160066368,
          "from_longitude": 105.84015999890758
        }
      },
      {
        "id": 5997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83237050374095,
            21.017813183585464,
            105.83249479301291,
            21.017934961182352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83249479301291,
              21.017813183585464
            ],
            [
              105.8324329434315,
              21.01784733007989
            ],
            [
              105.8324320163846,
              21.01788360179842
            ],
            [
              105.83237050374095,
              21.017934961182352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26.3_Ngách 268/41, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6005",
          "diaChiLapD": "Ngách 268/41, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017813183585464,
          "to_latitude": 21.017934961182352,
          "to_longitude": 105.83237050374095,
          "from_longitude": 105.83249479301291
        }
      },
      {
        "id": 5998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83627624812986,
            21.015109360499665,
            105.83640971173428,
            21.015250511534102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627624812986,
              21.015109360499665
            ],
            [
              105.83627684318702,
              21.015109982605928
            ],
            [
              105.83628100559082,
              21.015114317493566
            ],
            [
              105.83628851983728,
              21.015134264395815
            ],
            [
              105.83631476011324,
              21.015162333169467
            ],
            [
              105.83632992018455,
              21.015178551007075
            ],
            [
              105.83636324101985,
              21.015205367419625
            ],
            [
              105.83637422452453,
              21.015211259350256
            ],
            [
              105.83640971173428,
              21.015250511534102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.2_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6006",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015109360499665,
          "to_latitude": 21.015250511534102,
          "to_longitude": 105.83640971173428,
          "from_longitude": 105.83627624812986
        }
      },
      {
        "id": 5999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84096256175188,
            21.0152643708755,
            105.84126134575786,
            21.015279102060227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84126134575786,
              21.015279102060227
            ],
            [
              105.84114403440772,
              21.015272113086763
            ],
            [
              105.84108904908432,
              21.015267262750218
            ],
            [
              105.84102703765946,
              21.015265131509086
            ],
            [
              105.8409745023221,
              21.015264387723274
            ],
            [
              105.84096256175188,
              21.0152643708755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.2_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6007",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015279102060227,
          "to_latitude": 21.0152643708755,
          "to_longitude": 105.84096256175188,
          "from_longitude": 105.84126134575786
        }
      },
      {
        "id": 6000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8403820051747,
            21.01473107270569,
            105.84050026099011,
            21.01485280627213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84050026099011,
              21.01473107270569
            ],
            [
              105.84047563261454,
              21.014764190939907
            ],
            [
              105.84045529141538,
              21.014784752151197
            ],
            [
              105.84043567122187,
              21.01480798243731
            ],
            [
              105.84039278973349,
              21.01484050437671
            ],
            [
              105.8403820051747,
              21.014844733250758
            ],
            [
              105.84038251500215,
              21.01484542079532
            ],
            [
              105.84038502520185,
              21.014849119742102
            ],
            [
              105.84038748915945,
              21.01485280627213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.6_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6008",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01473107270569,
          "to_latitude": 21.01485280627213,
          "to_longitude": 105.84038748915945,
          "from_longitude": 105.84050026099011
        }
      },
      {
        "id": 6001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83381238581732,
            21.016605283615316,
            105.8338991778422,
            21.016738607905012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338991778422,
              21.016738607905012
            ],
            [
              105.83389887300021,
              21.016738278819066
            ],
            [
              105.83381238581732,
              21.016644881186032
            ],
            [
              105.83385831297814,
              21.016605283615316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.10_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6009",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016738607905012,
          "to_latitude": 21.016605283615316,
          "to_longitude": 105.83385831297814,
          "from_longitude": 105.8338991778422
        }
      },
      {
        "id": 6002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84086871955448,
            21.01717792517962,
            105.84087464535236,
            21.01735863358676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84087464535236,
              21.01735863358676
            ],
            [
              105.84087320518589,
              21.017293754045138
            ],
            [
              105.84086871955448,
              21.01717792517962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A3_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6010",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735863358676,
          "to_latitude": 21.01717792517962,
          "to_longitude": 105.84086871955448,
          "from_longitude": 105.84087464535236
        }
      },
      {
        "id": 6003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83891612770877,
            21.015108877132185,
            105.83908059194543,
            21.015197497799473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891612770877,
              21.015197497799473
            ],
            [
              105.83892033216128,
              21.015195061846672
            ],
            [
              105.83892959418309,
              21.01518999696019
            ],
            [
              105.83894031831086,
              21.01518544241069
            ],
            [
              105.83895271916403,
              21.015178665790554
            ],
            [
              105.83900156982922,
              21.015150609208973
            ],
            [
              105.83900505040205,
              21.015148771145242
            ],
            [
              105.83908059194543,
              21.015108877132185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.3_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6011",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015197497799473,
          "to_latitude": 21.015108877132185,
          "to_longitude": 105.83908059194543,
          "from_longitude": 105.83891612770877
        }
      },
      {
        "id": 6004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766925486486,
            21.015680195207988,
            105.8377697882832,
            21.015839073152154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766925486486,
              21.015680195207988
            ],
            [
              105.83776221451343,
              21.015827283632802
            ],
            [
              105.8377697882832,
              21.015839073152154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.28_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6012",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015680195207988,
          "to_latitude": 21.015839073152154,
          "to_longitude": 105.8377697882832,
          "from_longitude": 105.83766925486486
        }
      },
      {
        "id": 6005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647403463571,
            21.012523935168765,
            105.83662435315588,
            21.012651794583277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647403463571,
              21.012523935168765
            ],
            [
              105.83658356987652,
              21.012614304389857
            ],
            [
              105.83660223978309,
              21.01263070186026
            ],
            [
              105.83660939841518,
              21.01263640213771
            ],
            [
              105.83661350218252,
              21.012651794583277
            ],
            [
              105.83662375086149,
              21.01264634923038
            ],
            [
              105.83662435315588,
              21.012646028333847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.2_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.6013",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012523935168765,
          "to_latitude": 21.012646028333847,
          "to_longitude": 105.83662435315588,
          "from_longitude": 105.83647403463571
        }
      },
      {
        "id": 6006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83717223205066,
            21.01313877882383,
            105.83730758950851,
            21.01327151977212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717223205066,
              21.01327151977212
            ],
            [
              105.83727685698673,
              21.01316891530286
            ],
            [
              105.83730758950851,
              21.01313877882383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.8_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6014",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01327151977212,
          "to_latitude": 21.01313877882383,
          "to_longitude": 105.83730758950851,
          "from_longitude": 105.83717223205066
        }
      },
      {
        "id": 6007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84024184201249,
            21.016894813647703,
            105.84024276333191,
            21.01708159209706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84024276333191,
              21.01708159209706
            ],
            [
              105.84024184201249,
              21.016894813647703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.1_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6015",
          "diaChiLapD": "Ngách 1/49, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01708159209706,
          "to_latitude": 21.016894813647703,
          "to_longitude": 105.84024184201249,
          "from_longitude": 105.84024276333191
        }
      },
      {
        "id": 6008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696528824952,
            21.015073824604833,
            105.83707267197576,
            21.015243493543412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83707267197576,
              21.015243493543412
            ],
            [
              105.83707222648768,
              21.015242611478804
            ],
            [
              105.83706543105406,
              21.015229106485776
            ],
            [
              105.83696528824952,
              21.015073824604833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.3_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6016",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015243493543412,
          "to_latitude": 21.015073824604833,
          "to_longitude": 105.83696528824952,
          "from_longitude": 105.83707267197576
        }
      },
      {
        "id": 6009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629228353153,
            21.016808312641384,
            105.83648679575533,
            21.01693279298182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83648679575533,
              21.016808312641384
            ],
            [
              105.83640427081453,
              21.016866139065932
            ],
            [
              105.83638415035955,
              21.016878999641616
            ],
            [
              105.83637083640618,
              21.016887509340638
            ],
            [
              105.83629228353153,
              21.01693279298182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.15_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6017",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016808312641384,
          "to_latitude": 21.01693279298182,
          "to_longitude": 105.83629228353153,
          "from_longitude": 105.83648679575533
        }
      },
      {
        "id": 6010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713282138513,
            21.013973973325147,
            105.83724589709453,
            21.014136567230672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713282138513,
              21.013973973325147
            ],
            [
              105.83713333004995,
              21.01397428333799
            ],
            [
              105.8371446720223,
              21.013981161585065
            ],
            [
              105.83720694858246,
              21.01407898234248
            ],
            [
              105.83724589709453,
              21.014136567230672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.5_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6018",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013973973325147,
          "to_latitude": 21.014136567230672,
          "to_longitude": 105.83724589709453,
          "from_longitude": 105.83713282138513
        }
      },
      {
        "id": 6011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83940940085009,
            21.01532979413775,
            105.83953382681693,
            21.015481520613307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83940940085009,
              21.01532979413775
            ],
            [
              105.83942516406535,
              21.015351624958665
            ],
            [
              105.83947670315204,
              21.015412059164564
            ],
            [
              105.83953382681693,
              21.015481520613307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.34_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6019",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01532979413775,
          "to_latitude": 21.015481520613307,
          "to_longitude": 105.83953382681693,
          "from_longitude": 105.83940940085009
        }
      },
      {
        "id": 6012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953924294383,
            21.01662127080573,
            105.83974057783466,
            21.01662738306491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83974057783466,
              21.01662127080573
            ],
            [
              105.83953924294383,
              21.01662738306491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72A.3_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6020",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01662127080573,
          "to_latitude": 21.01662738306491,
          "to_longitude": 105.83953924294383,
          "from_longitude": 105.83974057783466
        }
      },
      {
        "id": 6013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84086178819148,
            21.016642466482487,
            105.84096736887629,
            21.016746051635263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84086835494425,
              21.016746051635263
            ],
            [
              105.84086828015162,
              21.016744893169346
            ],
            [
              105.84086178819148,
              21.016643006433384
            ],
            [
              105.84096736887629,
              21.016642466482487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.4_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6021",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016746051635263,
          "to_latitude": 21.016642466482487,
          "to_longitude": 105.84096736887629,
          "from_longitude": 105.84086835494425
        }
      },
      {
        "id": 6014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83703566781634,
            21.014618519792,
            105.8371515283922,
            21.014777262236446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83703566781634,
              21.014618519792
            ],
            [
              105.83704725512167,
              21.014634016305912
            ],
            [
              105.8370821190295,
              21.01468171475781
            ],
            [
              105.8371200255452,
              21.01473303280537
            ],
            [
              105.8371515283922,
              21.014777262236446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.18_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6022",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014618519792,
          "to_latitude": 21.014777262236446,
          "to_longitude": 105.8371515283922,
          "from_longitude": 105.83703566781634
        }
      },
      {
        "id": 6015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843708515823,
            21.01522699058853,
            105.83857391943478,
            21.01536119886579
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844747715156,
              21.01522699058853
            ],
            [
              105.83844247204327,
              21.01523177885798
            ],
            [
              105.83843708515823,
              21.015236931201553
            ],
            [
              105.83844756710273,
              21.015246696685544
            ],
            [
              105.8384740827188,
              21.015271462473088
            ],
            [
              105.83851342609289,
              21.015306445528168
            ],
            [
              105.83857391943478,
              21.01536119886579
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.15_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6023",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01522699058853,
          "to_latitude": 21.01536119886579,
          "to_longitude": 105.83857391943478,
          "from_longitude": 105.83844747715156
        }
      },
      {
        "id": 6016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520534969733,
            21.015127208955935,
            105.83534244259565,
            21.01518141976827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83520534969733,
              21.0151424476192
            ],
            [
              105.835245184151,
              21.015165911123756
            ],
            [
              105.83525007687781,
              21.015176163088643
            ],
            [
              105.8352565337896,
              21.01518141976827
            ],
            [
              105.83526832574059,
              21.015175303045634
            ],
            [
              105.83531848551452,
              21.015150038361366
            ],
            [
              105.83534244259565,
              21.01513624320591
            ],
            [
              105.83533646043897,
              21.015127798525683
            ],
            [
              105.83533604448631,
              21.015127208955935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.5_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6024",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0151424476192,
          "to_latitude": 21.015127208955935,
          "to_longitude": 105.83533604448631,
          "from_longitude": 105.83520534969733
        }
      },
      {
        "id": 6017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83619029930017,
            21.01488950159451,
            105.83632754706507,
            21.015046907790428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619029930017,
              21.014895197618937
            ],
            [
              105.83619224478385,
              21.01489320909243
            ],
            [
              105.83619587176061,
              21.01488950159451
            ],
            [
              105.83620583264866,
              21.014900387974652
            ],
            [
              105.83624336994271,
              21.014939353735123
            ],
            [
              105.83625782974383,
              21.014958168179568
            ],
            [
              105.83627700096085,
              21.014980064749416
            ],
            [
              105.83629141552422,
              21.01500059824899
            ],
            [
              105.83632754706507,
              21.015046907790428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.6_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6025",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014895197618937,
          "to_latitude": 21.015046907790428,
          "to_longitude": 105.83632754706507,
          "from_longitude": 105.83619029930017
        }
      },
      {
        "id": 6018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813004931751,
            21.015833769979768,
            105.83829500625228,
            21.015952882473925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83813004931751,
              21.015952882473925
            ],
            [
              105.83818285781295,
              21.015909679425626
            ],
            [
              105.83818553802361,
              21.01590748674136
            ],
            [
              105.83818953138818,
              21.015904797136752
            ],
            [
              105.83829500625228,
              21.015833769979768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.22_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6026",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015952882473925,
          "to_latitude": 21.015833769979768,
          "to_longitude": 105.83829500625228,
          "from_longitude": 105.83813004931751
        }
      },
      {
        "id": 6019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650185366031,
            21.01691889768572,
            105.83654214618565,
            21.01699200062087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83654214618565,
              21.01691889768572
            ],
            [
              105.83650185366031,
              21.016947263761022
            ],
            [
              105.83652826999788,
              21.01699200062087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.14_Ngách 178/80/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6027",
          "diaChiLapD": "Ngách 178/80/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01691889768572,
          "to_latitude": 21.01699200062087,
          "to_longitude": 105.83652826999788,
          "from_longitude": 105.83654214618565
        }
      },
      {
        "id": 6020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83552687678876,
            21.017382977554718,
            105.83569901934175,
            21.017423916565416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83552687678876,
              21.017423916565416
            ],
            [
              105.83558432778996,
              21.017416251431186
            ],
            [
              105.83563518967952,
              21.01740931954632
            ],
            [
              105.83568094146608,
              21.017403209393013
            ],
            [
              105.83569901934175,
              21.017400100204785
            ],
            [
              105.83569580898997,
              21.01738415781028
            ],
            [
              105.8356955725081,
              21.017382977554718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6028",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017423916565416,
          "to_latitude": 21.017382977554718,
          "to_longitude": 105.8356955725081,
          "from_longitude": 105.83552687678876
        }
      },
      {
        "id": 6021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83685578012407,
            21.012868696626423,
            105.83700761636962,
            21.012986674099597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83685578012407,
              21.012868696626423
            ],
            [
              105.83688982806747,
              21.012895055959984
            ],
            [
              105.83691344663713,
              21.01291343692096
            ],
            [
              105.83694672125876,
              21.012938079403636
            ],
            [
              105.83696866037248,
              21.01295646771443
            ],
            [
              105.83700761636962,
              21.012986674099597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.4_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.6029",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012868696626423,
          "to_latitude": 21.012986674099597,
          "to_longitude": 105.83700761636962,
          "from_longitude": 105.83685578012407
        }
      },
      {
        "id": 6022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.840472636754,
            21.014641367321513,
            105.84064903319914,
            21.014676174063197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.840472636754,
              21.014641367321513
            ],
            [
              105.8405318506015,
              21.01467473703549
            ],
            [
              105.8405455592563,
              21.014670995898314
            ],
            [
              105.8405756591241,
              21.01466278049793
            ],
            [
              105.84064903319914,
              21.014676174063197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.4_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6030",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014641367321513,
          "to_latitude": 21.014676174063197,
          "to_longitude": 105.84064903319914,
          "from_longitude": 105.840472636754
        }
      },
      {
        "id": 6023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927381029335,
            21.015795987237098,
            105.83936335405649,
            21.015985618244255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927381029335,
              21.015985618244255
            ],
            [
              105.83927819829591,
              21.01597420784904
            ],
            [
              105.83928846596366,
              21.015944284876973
            ],
            [
              105.83930137573009,
              21.015908971978877
            ],
            [
              105.83931093913785,
              21.015889687036196
            ],
            [
              105.8393187398047,
              21.015873756330194
            ],
            [
              105.83932981534258,
              21.015851715416595
            ],
            [
              105.83936335405649,
              21.015795987237098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.7_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.6031",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015985618244255,
          "to_latitude": 21.015795987237098,
          "to_longitude": 105.83936335405649,
          "from_longitude": 105.83927381029335
        }
      },
      {
        "id": 6024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571856154624,
            21.015830451976395,
            105.83589693830824,
            21.01596118689585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589409461148,
              21.015834916346304
            ],
            [
              105.83589693830824,
              21.01583167425045
            ],
            [
              105.83589551071469,
              21.015830451976395
            ],
            [
              105.83589179509542,
              21.015832930615588
            ],
            [
              105.83588366520023,
              21.015838353777287
            ],
            [
              105.83587630436386,
              21.015844206707715
            ],
            [
              105.835849012152,
              21.015863692380204
            ],
            [
              105.8357549871299,
              21.015932330584274
            ],
            [
              105.83571856154624,
              21.01596118689585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.18_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6032",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015834916346304,
          "to_latitude": 21.01596118689585,
          "to_longitude": 105.83571856154624,
          "from_longitude": 105.83589409461148
        }
      },
      {
        "id": 6025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008302418677,
            21.01675278604044,
            105.84026259508357,
            21.01676302190606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84008302418677,
              21.016756397699947
            ],
            [
              105.84014058023524,
              21.016760541071825
            ],
            [
              105.84025896985908,
              21.01676302190606
            ],
            [
              105.84026259508357,
              21.01676296154889
            ],
            [
              105.84026159567473,
              21.016754041733893
            ],
            [
              105.84026145589188,
              21.01675278604044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.7_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6033",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016756397699947,
          "to_latitude": 21.01675278604044,
          "to_longitude": 105.84026145589188,
          "from_longitude": 105.84008302418677
        }
      },
      {
        "id": 6026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794515002096,
            21.014700636772115,
            105.83804853865246,
            21.014870471076716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794515002096,
              21.014700636772115
            ],
            [
              105.83797448864159,
              21.014740885356126
            ],
            [
              105.83799415987153,
              21.014772351632672
            ],
            [
              105.8379993755259,
              21.014791992744126
            ],
            [
              105.83804853865246,
              21.014870471076716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.9_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6034",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014700636772115,
          "to_latitude": 21.014870471076716,
          "to_longitude": 105.83804853865246,
          "from_longitude": 105.83794515002096
        }
      },
      {
        "id": 6027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84004903902851,
            21.017082300726827,
            105.84022914517439,
            21.01709019482024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84022914517439,
              21.01709019482024
            ],
            [
              105.84022912571953,
              21.0170898128528
            ],
            [
              105.84022876330782,
              21.017082300726827
            ],
            [
              105.8402127899281,
              21.01708310852094
            ],
            [
              105.84015183418776,
              21.01708737020344
            ],
            [
              105.84011894103047,
              21.017087468517897
            ],
            [
              105.84004903902851,
              21.017087063130106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.1_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6035",
          "diaChiLapD": "Ngách 1/49, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01709019482024,
          "to_latitude": 21.017087063130106,
          "to_longitude": 105.84004903902851,
          "from_longitude": 105.84022914517439
        }
      },
      {
        "id": 6028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84032918785466,
            21.017090520968537,
            105.84055210256088,
            21.017094100939552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84032918785466,
              21.017091984022272
            ],
            [
              105.84034083984936,
              21.01709214775478
            ],
            [
              105.84038949264334,
              21.017090520968537
            ],
            [
              105.84044504998985,
              21.017091246374004
            ],
            [
              105.84055210256088,
              21.017094100939552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.2_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6036",
          "diaChiLapD": "Ngách 1/49, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017091984022272,
          "to_latitude": 21.017094100939552,
          "to_longitude": 105.84055210256088,
          "from_longitude": 105.84032918785466
        }
      },
      {
        "id": 6029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83403167473872,
            21.016386160469967,
            105.83413659373826,
            21.01654686901601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413659373826,
              21.01654686901601
            ],
            [
              105.83403167473872,
              21.016427000740485
            ],
            [
              105.83407535161685,
              21.016386160469967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6037",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654686901601,
          "to_latitude": 21.016386160469967,
          "to_longitude": 105.83407535161685,
          "from_longitude": 105.83413659373826
        }
      },
      {
        "id": 6030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044719239886,
            21.01529961233336,
            105.84061230680526,
            21.015409516616682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84044719239886,
              21.01539464062379
            ],
            [
              105.84049036468264,
              21.01540214448851
            ],
            [
              105.8405327763042,
              21.015409516616682
            ],
            [
              105.84055564115603,
              21.015397443164062
            ],
            [
              105.8405686690921,
              21.015383278573697
            ],
            [
              105.84058712558559,
              21.015347723213605
            ],
            [
              105.84059152073647,
              21.015339325905607
            ],
            [
              105.84061230680526,
              21.01529961233336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.5_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6038",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01539464062379,
          "to_latitude": 21.01529961233336,
          "to_longitude": 105.84061230680526,
          "from_longitude": 105.84044719239886
        }
      },
      {
        "id": 6031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549108536127,
            21.01837481077906,
            105.83556060768271,
            21.01855196228213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83549108536127,
              21.01837481077906
            ],
            [
              105.83554901338218,
              21.01849413932603
            ],
            [
              105.83556060768271,
              21.018543321639154
            ],
            [
              105.83552963276833,
              21.01855196228213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.3_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6039",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01837481077906,
          "to_latitude": 21.01855196228213,
          "to_longitude": 105.83552963276833,
          "from_longitude": 105.83549108536127
        }
      },
      {
        "id": 6032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732445545243,
            21.013130613771686,
            105.83747054669392,
            21.013298545710526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83732445545243,
              21.013130613771686
            ],
            [
              105.83736482263726,
              21.013176490391476
            ],
            [
              105.83739392743868,
              21.013210950578316
            ],
            [
              105.83742721978668,
              21.013250632463237
            ],
            [
              105.83743730478619,
              21.013262044742778
            ],
            [
              105.8374493683487,
              21.013275677344073
            ],
            [
              105.83747054669392,
              21.013298545710526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.9_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6040",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013130613771686,
          "to_latitude": 21.013298545710526,
          "to_longitude": 105.83747054669392,
          "from_longitude": 105.83732445545243
        }
      },
      {
        "id": 6033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83545073840799,
            21.014125088089727,
            105.83558765853324,
            21.01429508810943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83545073840799,
              21.014125088089727
            ],
            [
              105.83545675758064,
              21.01413258600673
            ],
            [
              105.83546493589704,
              21.014143469445447
            ],
            [
              105.83547486066225,
              21.01415617875684
            ],
            [
              105.83550149767323,
              21.014188872036414
            ],
            [
              105.83554057000482,
              21.014237538284913
            ],
            [
              105.83558765853324,
              21.01429508810943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.2_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6041",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014125088089727,
          "to_latitude": 21.01429508810943,
          "to_longitude": 105.83558765853324,
          "from_longitude": 105.83545073840799
        }
      },
      {
        "id": 6034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400119942115,
            21.014591250242876,
            105.84007302173387,
            21.0147516116703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002501818806,
              21.0147516116703
            ],
            [
              105.84002475723402,
              21.014751563281425
            ],
            [
              105.8400119942115,
              21.01474913430305
            ],
            [
              105.84003014097249,
              21.01468110511893
            ],
            [
              105.84005358659331,
              21.01460647881674
            ],
            [
              105.84007302173387,
              21.014591250242876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.8_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6042",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0147516116703,
          "to_latitude": 21.014591250242876,
          "to_longitude": 105.84007302173387,
          "from_longitude": 105.84002501818806
        }
      },
      {
        "id": 6035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807806862296,
            21.01503993667104,
            105.8381882528323,
            21.01519825750687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83811360941493,
              21.01519825750687
            ],
            [
              105.83811127434099,
              21.01519369869654
            ],
            [
              105.83810968778882,
              21.015191058259443
            ],
            [
              105.83807806862296,
              21.015138472059554
            ],
            [
              105.83811840098863,
              21.015105669697913
            ],
            [
              105.83812528019541,
              21.015088013908112
            ],
            [
              105.8381882528323,
              21.01503993667104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.2_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6043",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01519825750687,
          "to_latitude": 21.01503993667104,
          "to_longitude": 105.8381882528323,
          "from_longitude": 105.83811360941493
        }
      },
      {
        "id": 6036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617565648419,
            21.014662219844524,
            105.83634797778362,
            21.01481697538673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634797778362,
              21.014662219844524
            ],
            [
              105.83630163785284,
              21.014704507182156
            ],
            [
              105.83626071935859,
              21.01474248476981
            ],
            [
              105.8362461794578,
              21.014755368474937
            ],
            [
              105.8362075606188,
              21.0147884618515
            ],
            [
              105.83617565648419,
              21.01481697538673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.5_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6044",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014662219844524,
          "to_latitude": 21.01481697538673,
          "to_longitude": 105.83617565648419,
          "from_longitude": 105.83634797778362
        }
      },
      {
        "id": 6037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83781689954812,
            21.012438060942365,
            105.83797616190779,
            21.01254236917347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83797508835738,
              21.012456222831634
            ],
            [
              105.83797545846765,
              21.01245566552589
            ],
            [
              105.83797616190779,
              21.01245433793464
            ],
            [
              105.8379400289748,
              21.012438060942365
            ],
            [
              105.83793040334552,
              21.012440174019705
            ],
            [
              105.83792012104156,
              21.01244268774909
            ],
            [
              105.837905109895,
              21.01244587869961
            ],
            [
              105.83788890986531,
              21.01245148891883
            ],
            [
              105.8378775738347,
              21.012463398658177
            ],
            [
              105.83785793206025,
              21.012482613929944
            ],
            [
              105.83784194236983,
              21.012499707606644
            ],
            [
              105.83781689954812,
              21.01254236917347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.9_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6045",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012456222831634,
          "to_latitude": 21.01254236917347,
          "to_longitude": 105.83781689954812,
          "from_longitude": 105.83797508835738
        }
      },
      {
        "id": 6038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400964420602,
            21.016127430432082,
            105.8403230913667,
            21.016135020088335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8403230913667,
              21.016131410663775
            ],
            [
              105.84025229269031,
              21.016135020088335
            ],
            [
              105.84013872611096,
              21.01613466237965
            ],
            [
              105.84010503518456,
              21.016130200636372
            ],
            [
              105.84010087845553,
              21.016129651228805
            ],
            [
              105.8400964420602,
              21.016127430432082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.6_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6046",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016131410663775,
          "to_latitude": 21.016127430432082,
          "to_longitude": 105.8400964420602,
          "from_longitude": 105.8403230913667
        }
      },
      {
        "id": 6039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400135846079,
            21.01714578954315,
            105.84004305855325,
            21.01735226700716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84004305855325,
              21.01714578954315
            ],
            [
              105.84002812550547,
              21.01735226700716
            ],
            [
              105.84002169838197,
              21.017352031389596
            ],
            [
              105.8400135846079,
              21.017351731842357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.4_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6047",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01714578954315,
          "to_latitude": 21.017351731842357,
          "to_longitude": 105.8400135846079,
          "from_longitude": 105.84004305855325
        }
      },
      {
        "id": 6040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813648848113,
            21.01221054900922,
            105.83830385369144,
            21.012307111119146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814732603226,
              21.012307111119146
            ],
            [
              105.83813648848113,
              21.012264816831593
            ],
            [
              105.83816177615697,
              21.012233981736582
            ],
            [
              105.83817766619269,
              21.012216279748877
            ],
            [
              105.83818862308075,
              21.01221054900922
            ],
            [
              105.83824686986804,
              21.012238094924296
            ],
            [
              105.83825001883919,
              21.012239584182577
            ],
            [
              105.8382945050732,
              21.012258969498298
            ],
            [
              105.83830385369144,
              21.012263920979525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.12_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6048",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012307111119146,
          "to_latitude": 21.012263920979525,
          "to_longitude": 105.83830385369144,
          "from_longitude": 105.83814732603226
        }
      },
      {
        "id": 6041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502735772288,
            21.015067955940907,
            105.83513562380764,
            21.015243010176217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83513562380764,
              21.015067955940907
            ],
            [
              105.83505499022027,
              21.01511278766944
            ],
            [
              105.83502963719762,
              21.015130356986205
            ],
            [
              105.83502735772288,
              21.015152357175943
            ],
            [
              105.8350694452097,
              21.01522285592095
            ],
            [
              105.83506564137909,
              21.015243010176217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.4_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6049",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015067955940907,
          "to_latitude": 21.015243010176217,
          "to_longitude": 105.83506564137909,
          "from_longitude": 105.83513562380764
        }
      },
      {
        "id": 6042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83799027713658,
            21.012145069435473,
            105.83814892288525,
            21.012231767156912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83799027713658,
              21.012145069435473
            ],
            [
              105.83811004165861,
              21.012231767156912
            ],
            [
              105.83812448918925,
              21.012216195759688
            ],
            [
              105.83814514982737,
              21.012193928864104
            ],
            [
              105.83814892288525,
              21.012190390413224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.11_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.6050",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012145069435473,
          "to_latitude": 21.012190390413224,
          "to_longitude": 105.83814892288525,
          "from_longitude": 105.83799027713658
        }
      },
      {
        "id": 6043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364715941018,
            21.015483603355996,
            105.83666332136426,
            21.01563331257949
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364715941018,
              21.01563331257949
            ],
            [
              105.83651107962997,
              21.01559484250233
            ],
            [
              105.83655128113872,
              21.015558499601852
            ],
            [
              105.83658104599462,
              21.015533260768084
            ],
            [
              105.8366395487325,
              21.01549785933561
            ],
            [
              105.83666332136426,
              21.015483603355996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.8_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6051",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01563331257949,
          "to_latitude": 21.015483603355996,
          "to_longitude": 105.83666332136426,
          "from_longitude": 105.8364715941018
        }
      },
      {
        "id": 6044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83563600702055,
            21.015004614400528,
            105.83580540733543,
            21.015149535205765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83563600702055,
              21.015004614400528
            ],
            [
              105.8356856763825,
              21.01503448122757
            ],
            [
              105.83568860316564,
              21.015034586076922
            ],
            [
              105.83569741769499,
              21.015040517609624
            ],
            [
              105.83571978265095,
              21.015057614176406
            ],
            [
              105.83573358998193,
              21.01506913652795
            ],
            [
              105.83575988183588,
              21.015092498434818
            ],
            [
              105.83577110956077,
              21.01510407319703
            ],
            [
              105.83578304227679,
              21.015112521145056
            ],
            [
              105.83579599741108,
              21.01512595438439
            ],
            [
              105.83580540733543,
              21.01513705575247
            ],
            [
              105.8358028289318,
              21.015139569447783
            ],
            [
              105.8357993425903,
              21.015142969150464
            ],
            [
              105.8358037422836,
              21.015149149978356
            ],
            [
              105.83580401761921,
              21.015149535205765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.9_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6052",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015004614400528,
          "to_latitude": 21.015149535205765,
          "to_longitude": 105.83580401761921,
          "from_longitude": 105.83563600702055
        }
      },
      {
        "id": 6045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84069313647399,
            21.01457209842504,
            105.8409248101289,
            21.014593406016143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84069313647399,
              21.014593406016143
            ],
            [
              105.84076737814001,
              21.014579847567298
            ],
            [
              105.84081985242602,
              21.014576949928156
            ],
            [
              105.84087217047175,
              21.014575352783872
            ],
            [
              105.84090951831209,
              21.014572778263418
            ],
            [
              105.8409248101289,
              21.01457209842504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.3_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6053",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014593406016143,
          "to_latitude": 21.01457209842504,
          "to_longitude": 105.8409248101289,
          "from_longitude": 105.84069313647399
        }
      },
      {
        "id": 6046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400024202553,
            21.017407551986718,
            105.84001788095516,
            21.017674330302185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84001788095516,
              21.017674330302185
            ],
            [
              105.84001163111164,
              21.01763653416586
            ],
            [
              105.8400077222758,
              21.017601933815943
            ],
            [
              105.8400056572485,
              21.017575018018643
            ],
            [
              105.84000548244742,
              21.017543807995537
            ],
            [
              105.84000477629796,
              21.017505664743137
            ],
            [
              105.84000273772415,
              21.01746372007971
            ],
            [
              105.8400024202553,
              21.017407551986718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.5_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6054",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017674330302185,
          "to_latitude": 21.017407551986718,
          "to_longitude": 105.8400024202553,
          "from_longitude": 105.84001788095516
        }
      },
      {
        "id": 6047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668705434317,
            21.0162215228736,
            105.8368185132608,
            21.01637828195634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668705434317,
              21.0162215228736
            ],
            [
              105.83679301219404,
              21.01637828195634
            ],
            [
              105.8368185132608,
              21.016362593969966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.6_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6055",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0162215228736,
          "to_latitude": 21.016362593969966,
          "to_longitude": 105.8368185132608,
          "from_longitude": 105.83668705434317
        }
      },
      {
        "id": 6048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83717144497197,
            21.014842894571363,
            105.8373009321409,
            21.015040328100742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717144497197,
              21.014842894571363
            ],
            [
              105.83719362950957,
              21.014874521093237
            ],
            [
              105.83720698089918,
              21.014893612778646
            ],
            [
              105.8372189327424,
              21.014913059994523
            ],
            [
              105.837235076059,
              21.014936186189345
            ],
            [
              105.8373009321409,
              21.015040328100742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.19_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6056",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014842894571363,
          "to_latitude": 21.015040328100742,
          "to_longitude": 105.8373009321409,
          "from_longitude": 105.83717144497197
        }
      },
      {
        "id": 6049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824833378458,
            21.014801142558902,
            105.83845802611602,
            21.01488959889323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824833378458,
              21.01488959889323
            ],
            [
              105.83825309176034,
              21.014884482170313
            ],
            [
              105.83826820168092,
              21.01487069497546
            ],
            [
              105.83829286775315,
              21.014848188840155
            ],
            [
              105.83831918306066,
              21.014835065395093
            ],
            [
              105.83833299730146,
              21.01482954907885
            ],
            [
              105.83836499980461,
              21.01481767837487
            ],
            [
              105.83842146636924,
              21.014822717396388
            ],
            [
              105.83845802611602,
              21.014801142558902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.1_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6057",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488959889323,
          "to_latitude": 21.014801142558902,
          "to_longitude": 105.83845802611602,
          "from_longitude": 105.83824833378458
        }
      },
      {
        "id": 6050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756642391094,
            21.01416489086506,
            105.83767398157934,
            21.014370190778536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767398157934,
              21.014370190778536
            ],
            [
              105.8376630558577,
              21.01435207802328
            ],
            [
              105.83764307570543,
              21.01431797942254
            ],
            [
              105.83763620748864,
              21.014306255967607
            ],
            [
              105.83759203330095,
              21.014211579806023
            ],
            [
              105.83756642391094,
              21.01416489086506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6058",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014370190778536,
          "to_latitude": 21.01416489086506,
          "to_longitude": 105.83756642391094,
          "from_longitude": 105.83767398157934
        }
      },
      {
        "id": 6051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83560980694385,
            21.01415080380917,
            105.83572094073322,
            21.014305798155203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560980694385,
              21.014305798155203
            ],
            [
              105.83561027043825,
              21.01430507962175
            ],
            [
              105.83561482916912,
              21.0142980274354
            ],
            [
              105.83572094073322,
              21.01423040569491
            ],
            [
              105.8356561722351,
              21.01415080380917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.3_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6059",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014305798155203,
          "to_latitude": 21.01415080380917,
          "to_longitude": 105.8356561722351,
          "from_longitude": 105.83560980694385
        }
      },
      {
        "id": 6052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331748289325,
            21.016653812943698,
            105.83341613838182,
            21.016818354499424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83334528246657,
              21.016818354499424
            ],
            [
              105.83341613838182,
              21.01675933030687
            ],
            [
              105.8333590274493,
              21.01670283428086
            ],
            [
              105.83335709904512,
              21.016694749930977
            ],
            [
              105.83331748289325,
              21.016653812943698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12.1_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6060",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016818354499424,
          "to_latitude": 21.016653812943698,
          "to_longitude": 105.83331748289325,
          "from_longitude": 105.83334528246657
        }
      },
      {
        "id": 6053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750255669398,
            21.01336318455954,
            105.8376539342928,
            21.013566632177756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376539342928,
              21.013566632177756
            ],
            [
              105.83762992774719,
              21.01352755585232
            ],
            [
              105.8376042564005,
              21.013488011719502
            ],
            [
              105.83757504792626,
              21.013453263954485
            ],
            [
              105.83755017637762,
              21.01342016850958
            ],
            [
              105.83750255669398,
              21.01336318455954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6061",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013566632177756,
          "to_latitude": 21.01336318455954,
          "to_longitude": 105.83750255669398,
          "from_longitude": 105.8376539342928
        }
      },
      {
        "id": 6054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572505541152,
            21.015197957799,
            105.83581654915412,
            21.015368975800644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572505541152,
              21.015368975800644
            ],
            [
              105.83581654915412,
              21.01529203316371
            ],
            [
              105.83579379723909,
              21.01526816523257
            ],
            [
              105.83577086585983,
              21.01524247185994
            ],
            [
              105.83573575393521,
              21.01520148711641
            ],
            [
              105.83573266233157,
              21.015198733920364
            ],
            [
              105.83573179419098,
              21.015197957799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.10_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6062",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015368975800644,
          "to_latitude": 21.015197957799,
          "to_longitude": 105.83573179419098,
          "from_longitude": 105.83572505541152
        }
      },
      {
        "id": 6055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718520180587,
            21.014639474559132,
            105.83739745014923,
            21.01477244855013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83718520180587,
              21.01477244855013
            ],
            [
              105.83733235854343,
              21.014678638081598
            ],
            [
              105.83736475431444,
              21.014659146932598
            ],
            [
              105.83739745014923,
              21.014639474559132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6063",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01477244855013,
          "to_latitude": 21.014639474559132,
          "to_longitude": 105.83739745014923,
          "from_longitude": 105.83718520180587
        }
      },
      {
        "id": 6056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83256591466935,
            21.01865991318449,
            105.83272887383265,
            21.018754376662866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272887383265,
              21.018754376662866
            ],
            [
              105.8326992910624,
              21.018681491252618
            ],
            [
              105.83267398043326,
              21.018691087265086
            ],
            [
              105.83266128652276,
              21.01865991318449
            ],
            [
              105.83261482627047,
              21.018673312319535
            ],
            [
              105.83256591466935,
              21.018682156736155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5.5_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6064",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018754376662866,
          "to_latitude": 21.018682156736155,
          "to_longitude": 105.83256591466935,
          "from_longitude": 105.83272887383265
        }
      },
      {
        "id": 6057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83322508808777,
            21.018189015339107,
            105.83345933275565,
            21.018269457839693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345933275565,
              21.018189015339107
            ],
            [
              105.83345927707936,
              21.018189036386037
            ],
            [
              105.83329183426362,
              21.01825198046891
            ],
            [
              105.8332816271501,
              21.01824924221404
            ],
            [
              105.83322508808777,
              21.018269457839693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6065",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018189015339107,
          "to_latitude": 21.018269457839693,
          "to_longitude": 105.83322508808777,
          "from_longitude": 105.83345933275565
        }
      },
      {
        "id": 6058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83745227011937,
            21.013599469866858,
            105.8376493340138,
            21.01374951895552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83745227011937,
              21.013739122808765
            ],
            [
              105.83745296828133,
              21.01373994943545
            ],
            [
              105.83746107108514,
              21.01374951895552
            ],
            [
              105.83749097174363,
              21.013725952074857
            ],
            [
              105.83751933689202,
              21.013700958427545
            ],
            [
              105.83754138941218,
              21.013684838416097
            ],
            [
              105.83757963766553,
              21.013652149411413
            ],
            [
              105.83762471231825,
              21.01361130048288
            ],
            [
              105.8376493340138,
              21.013599469866858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.2_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6066",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013739122808765,
          "to_latitude": 21.013599469866858,
          "to_longitude": 105.8376493340138,
          "from_longitude": 105.83745227011937
        }
      },
      {
        "id": 6059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84087376041605,
            21.016751083871394,
            105.84112663925542,
            21.01675682831772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84087376041605,
              21.016751083871394
            ],
            [
              105.84092530599774,
              21.016751749469158
            ],
            [
              105.84092542047586,
              21.016751750709727
            ],
            [
              105.840936029549,
              21.016751887944654
            ],
            [
              105.84101923687486,
              21.0167529615166
            ],
            [
              105.84112663925542,
              21.01675682831772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.3_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6067",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016751083871394,
          "to_latitude": 21.01675682831772,
          "to_longitude": 105.84112663925542,
          "from_longitude": 105.84087376041605
        }
      },
      {
        "id": 6060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83664878692676,
            21.012679164917856,
            105.83683694056836,
            21.01283120882543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83664878692676,
              21.012679164917856
            ],
            [
              105.83667497218678,
              21.012701404513646
            ],
            [
              105.83670718736093,
              21.012727892124232
            ],
            [
              105.83676147010382,
              21.012770281762194
            ],
            [
              105.83679325789474,
              21.012796366812662
            ],
            [
              105.83680020492066,
              21.012802067219013
            ],
            [
              105.83683694056836,
              21.01283120882543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.3_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.6068",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012679164917856,
          "to_latitude": 21.01283120882543,
          "to_longitude": 105.83683694056836,
          "from_longitude": 105.83664878692676
        }
      },
      {
        "id": 6061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354694658398,
            21.016274997412776,
            105.83556904781723,
            21.016444944426173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8354694658398,
              21.016444944426173
            ],
            [
              105.83556904781723,
              21.016375848239992
            ],
            [
              105.83549520054783,
              21.016274997412776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.12_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6069",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016444944426173,
          "to_latitude": 21.016274997412776,
          "to_longitude": 105.83549520054783,
          "from_longitude": 105.8354694658398
        }
      },
      {
        "id": 6062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202092974965,
            21.01833516462786,
            105.83212770132184,
            21.018508599382493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203370257696,
              21.018508599382493
            ],
            [
              105.83202092974965,
              21.01848172679137
            ],
            [
              105.83210293698748,
              21.018441774361214
            ],
            [
              105.83210289932275,
              21.018434985908485
            ],
            [
              105.83212770132184,
              21.01842058008771
            ],
            [
              105.832083797075,
              21.01833516462786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.5_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6070",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018508599382493,
          "to_latitude": 21.01833516462786,
          "to_longitude": 105.832083797075,
          "from_longitude": 105.83203370257696
        }
      },
      {
        "id": 6063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907401133399,
            21.01409550751746,
            105.83909082380869,
            21.014312815956163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909053708001,
              21.014312815956163
            ],
            [
              105.83909082380869,
              21.014286690595945
            ],
            [
              105.83907789416202,
              21.014133054548463
            ],
            [
              105.83907401133399,
              21.01409771247925
            ],
            [
              105.83908550763041,
              21.014096049850316
            ],
            [
              105.83908925129843,
              21.01409550751746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.21_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6071",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014312815956163,
          "to_latitude": 21.01409550751746,
          "to_longitude": 105.83908925129843,
          "from_longitude": 105.83909053708001
        }
      },
      {
        "id": 6064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044348957859,
            21.01675776136948,
            105.84044697134877,
            21.016995088788057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84044348957859,
              21.016995088788057
            ],
            [
              105.84044609972604,
              21.01692117053885
            ],
            [
              105.84044697134877,
              21.01675776136948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.7_Ngách 222/42, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6072",
          "diaChiLapD": "Ngách 222/42, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016995088788057,
          "to_latitude": 21.01675776136948,
          "to_longitude": 105.84044697134877,
          "from_longitude": 105.84044348957859
        }
      },
      {
        "id": 6065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83997970718893,
            21.01488675713891,
            105.84018268965028,
            21.014913755547777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83997970718893,
              21.01488675713891
            ],
            [
              105.8401262017537,
              21.014913755547777
            ],
            [
              105.84018268965028,
              21.014906102680374
            ],
            [
              105.84018131932883,
              21.014895531794867
            ],
            [
              105.84018117753844,
              21.014894432368628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.7_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6073",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01488675713891,
          "to_latitude": 21.014894432368628,
          "to_longitude": 105.84018117753844,
          "from_longitude": 105.83997970718893
        }
      },
      {
        "id": 6066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601540852007,
            21.01804613411607,
            105.8362779490821,
            21.018105523553665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83601540852007,
              21.018105523553665
            ],
            [
              105.83601884131758,
              21.018104759716877
            ],
            [
              105.8361289320339,
              21.018080275073075
            ],
            [
              105.8362779490821,
              21.01804613411607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6074",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018105523553665,
          "to_latitude": 21.01804613411607,
          "to_longitude": 105.8362779490821,
          "from_longitude": 105.83601540852007
        }
      },
      {
        "id": 6067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687472969392,
            21.01536549486513,
            105.8370495856088,
            21.015560499721495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687472969392,
              21.01536549486513
            ],
            [
              105.83687540233207,
              21.015366228587368
            ],
            [
              105.8368784102601,
              21.015369497011296
            ],
            [
              105.8368908723921,
              21.015367304099975
            ],
            [
              105.83694756461229,
              21.015445336559537
            ],
            [
              105.8369740370172,
              21.01548256458315
            ],
            [
              105.83698890838237,
              21.015502533965567
            ],
            [
              105.8370495856088,
              21.015560499721495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.6_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6075",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01536549486513,
          "to_latitude": 21.015560499721495,
          "to_longitude": 105.8370495856088,
          "from_longitude": 105.83687472969392
        }
      },
      {
        "id": 6068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.840240333063,
            21.015369480482068,
            105.84032934503244,
            21.01554610625556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029230319386,
              21.015369480482068
            ],
            [
              105.84026242918428,
              21.015451526929475
            ],
            [
              105.840240333063,
              21.015526481685054
            ],
            [
              105.84032934503244,
              21.01554610625556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.9_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6076",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015369480482068,
          "to_latitude": 21.01554610625556,
          "to_longitude": 105.84032934503244,
          "from_longitude": 105.84029230319386
        }
      },
      {
        "id": 6069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380339962088,
            21.01425398188994,
            105.83822581839988,
            21.01437805655115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380339962088,
              21.014317092145287
            ],
            [
              105.83806301738991,
              21.014348052620825
            ],
            [
              105.83809296053731,
              21.01437805655115
            ],
            [
              105.83809803926417,
              21.01437560095724
            ],
            [
              105.838114085245,
              21.014360805683314
            ],
            [
              105.83814297098019,
              21.014333949630448
            ],
            [
              105.8381553548946,
              21.014320531745398
            ],
            [
              105.83818505621673,
              21.014292450508847
            ],
            [
              105.83822581839988,
              21.01425398188994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.3_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6077",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014317092145287,
          "to_latitude": 21.01425398188994,
          "to_longitude": 105.83822581839988,
          "from_longitude": 105.8380339962088
        }
      },
      {
        "id": 6070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640309577795,
            21.01484626006218,
            105.8366146979877,
            21.01501472935096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640309577795,
              21.01501472935096
            ],
            [
              105.83647649552738,
              21.01496220096167
            ],
            [
              105.83651176938443,
              21.01493456860798
            ],
            [
              105.8365428183066,
              21.014904845282782
            ],
            [
              105.83656169193338,
              21.014885967221144
            ],
            [
              105.83658211890526,
              21.014870335836626
            ],
            [
              105.8366146979877,
              21.01484626006218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.1_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6078",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01501472935096,
          "to_latitude": 21.01484626006218,
          "to_longitude": 105.8366146979877,
          "from_longitude": 105.83640309577795
        }
      },
      {
        "id": 6071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83339528705562,
            21.01599968018943,
            105.83357639288407,
            21.016088519542276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339528705562,
              21.016083588248126
            ],
            [
              105.83340465654108,
              21.01606405979199
            ],
            [
              105.83342393388392,
              21.016041047834737
            ],
            [
              105.83345858586586,
              21.01599968018943
            ],
            [
              105.8334734156308,
              21.01600842752647
            ],
            [
              105.83353390389107,
              21.016056822213194
            ],
            [
              105.83356393767237,
              21.016080554514666
            ],
            [
              105.83357639288407,
              21.016088519542276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28.1_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6079",
          "diaChiLapD": "Ngách 216/8, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016083588248126,
          "to_latitude": 21.016088519542276,
          "to_longitude": 105.83357639288407,
          "from_longitude": 105.83339528705562
        }
      },
      {
        "id": 6072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83600129938618,
            21.01621832930403,
            105.83612502111774,
            21.01639875671554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612502111774,
              21.01621832930403
            ],
            [
              105.83607579187311,
              21.016246451973984
            ],
            [
              105.83605546250492,
              21.01625806437769
            ],
            [
              105.83600129938618,
              21.01629894854219
            ],
            [
              105.83603057956292,
              21.01633927181171
            ],
            [
              105.83607369731541,
              21.01639875671554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.20_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6080",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01621832930403,
          "to_latitude": 21.01639875671554,
          "to_longitude": 105.83607369731541,
          "from_longitude": 105.83612502111774
        }
      },
      {
        "id": 6073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667959864425,
            21.014165413756512,
            105.83683188183186,
            21.01438553206725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83683188183186,
              21.01438553206725
            ],
            [
              105.83675202448005,
              21.01427861726515
            ],
            [
              105.8367391348389,
              21.01424898986617
            ],
            [
              105.83670192179288,
              21.014198128957737
            ],
            [
              105.83667959864425,
              21.014165413756512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.15_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6081",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01438553206725,
          "to_latitude": 21.014165413756512,
          "to_longitude": 105.83667959864425,
          "from_longitude": 105.83683188183186
        }
      },
      {
        "id": 6074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8406123811309,
            21.01526530553975,
            105.84086848704628,
            21.015331927499197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406123811309,
              21.015331927499197
            ],
            [
              105.84062522289187,
              21.015307201528135
            ],
            [
              105.84066112856523,
              21.015292709825356
            ],
            [
              105.84070775601818,
              21.01528715672403
            ],
            [
              105.84070968717758,
              21.015286741633744
            ],
            [
              105.84072506784689,
              21.01527306850128
            ],
            [
              105.84080121145323,
              21.01526841002753
            ],
            [
              105.84086848704628,
              21.01526530553975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.6_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6082",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015331927499197,
          "to_latitude": 21.01526530553975,
          "to_longitude": 105.84086848704628,
          "from_longitude": 105.8406123811309
        }
      },
      {
        "id": 6075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415310493639,
            21.017895560863167,
            105.83431696205349,
            21.018056705716052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83431696205349,
              21.017895560863167
            ],
            [
              105.83425444452064,
              21.01792196161947
            ],
            [
              105.83428902067234,
              21.01799766199671
            ],
            [
              105.83428584476431,
              21.018002413177804
            ],
            [
              105.83428029240946,
              21.018010719362866
            ],
            [
              105.83422720999415,
              21.0180261141129
            ],
            [
              105.83418339647761,
              21.018041944813593
            ],
            [
              105.83417926358004,
              21.01804343823904
            ],
            [
              105.83415310493639,
              21.018056705716052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6083",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017895560863167,
          "to_latitude": 21.018056705716052,
          "to_longitude": 105.83415310493639,
          "from_longitude": 105.83431696205349
        }
      },
      {
        "id": 6076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83267438465751,
            21.018414992351282,
            105.83284299927902,
            21.018521883426903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326772302729,
              21.01846626417283
            ],
            [
              105.83267692977724,
              21.018465678547738
            ],
            [
              105.83267438465751,
              21.018460692560875
            ],
            [
              105.83277378655772,
              21.018414992351282
            ],
            [
              105.83277495767643,
              21.018416800293092
            ],
            [
              105.83284299927902,
              21.018521883426903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.3_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6084",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01846626417283,
          "to_latitude": 21.018521883426903,
          "to_longitude": 105.83284299927902,
          "from_longitude": 105.8326772302729
        }
      },
      {
        "id": 6077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587693583819,
            21.01747709432332,
            105.83616622482734,
            21.017481986722746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83587693583819,
              21.017481986722746
            ],
            [
              105.83588775755746,
              21.017481260647294
            ],
            [
              105.83589477234656,
              21.017480789921887
            ],
            [
              105.83593932519176,
              21.017479964935173
            ],
            [
              105.83602790108384,
              21.01747732217743
            ],
            [
              105.83605707816801,
              21.01747736394682
            ],
            [
              105.83606617765703,
              21.01747709432332
            ],
            [
              105.83616622482734,
              21.01748119007082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.5_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6085",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017481986722746,
          "to_latitude": 21.01748119007082,
          "to_longitude": 105.83616622482734,
          "from_longitude": 105.83587693583819
        }
      },
      {
        "id": 6078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046899292304,
            21.015939191693775,
            105.8404828761642,
            21.01624832039726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046980462732,
              21.015939191693775
            ],
            [
              105.8404697954577,
              21.01593978696339
            ],
            [
              105.84046899292304,
              21.016000679936106
            ],
            [
              105.84047888127992,
              21.01600353314187
            ],
            [
              105.84048031058053,
              21.016163501417346
            ],
            [
              105.8404828761642,
              21.01624832039726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.4_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6086",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015939191693775,
          "to_latitude": 21.01624832039726,
          "to_longitude": 105.8404828761642,
          "from_longitude": 105.84046980462732
        }
      },
      {
        "id": 6079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8374493683487,
            21.01309839611381,
            105.83767853339458,
            21.013275677344073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374493683487,
              21.013275677344073
            ],
            [
              105.83747026081961,
              21.013258537892778
            ],
            [
              105.83750798677823,
              21.013227592892576
            ],
            [
              105.83767853339458,
              21.01309839611381
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.9_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6087",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013275677344073,
          "to_latitude": 21.01309839611381,
          "to_longitude": 105.83767853339458,
          "from_longitude": 105.8374493683487
        }
      },
      {
        "id": 6080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870496714803,
            21.01527367297728,
            105.83883079665496,
            21.015446796310826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83877675779982,
              21.0152803702194
            ],
            [
              105.83877453833152,
              21.015276534331193
            ],
            [
              105.838773689799,
              21.01527531103333
            ],
            [
              105.8387769079263,
              21.01527367297728
            ],
            [
              105.83877979948404,
              21.01527865896884
            ],
            [
              105.83878393042069,
              21.015285782200227
            ],
            [
              105.83879623582801,
              21.01530700329266
            ],
            [
              105.83880103163283,
              21.015313640924003
            ],
            [
              105.83883079665496,
              21.015352153958528
            ],
            [
              105.83876305455887,
              21.015403106421545
            ],
            [
              105.83870496714803,
              21.015446796310826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.1_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6088",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0152803702194,
          "to_latitude": 21.015446796310826,
          "to_longitude": 105.83870496714803,
          "from_longitude": 105.83877675779982
        }
      },
      {
        "id": 6081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667332958535,
            21.016711474756622,
            105.83680417269927,
            21.016971750062645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667332958535,
              21.016711474756622
            ],
            [
              105.83667373871944,
              21.016712392226616
            ],
            [
              105.83667922873555,
              21.016724672557828
            ],
            [
              105.83667958136307,
              21.016725462047866
            ],
            [
              105.83673022494044,
              21.01681791176071
            ],
            [
              105.83673172362916,
              21.01683463390606
            ],
            [
              105.83680417269927,
              21.016971750062645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.13_Ngách 178/80/16, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6089",
          "diaChiLapD": "Ngách 178/80/16, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016711474756622,
          "to_latitude": 21.016971750062645,
          "to_longitude": 105.83680417269927,
          "from_longitude": 105.83667332958535
        }
      },
      {
        "id": 6082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524214411726,
            21.01383753377985,
            105.83542602890199,
            21.014064202019657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83542602890199,
              21.014064202019657
            ],
            [
              105.8354035533866,
              21.014032919904327
            ],
            [
              105.83533311201073,
              21.013942835819073
            ],
            [
              105.83532784686292,
              21.013936349419648
            ],
            [
              105.83527283785354,
              21.013868570541362
            ],
            [
              105.83524214411726,
              21.01383753377985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.1_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6090",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014064202019657,
          "to_latitude": 21.01383753377985,
          "to_longitude": 105.83524214411726,
          "from_longitude": 105.83542602890199
        }
      },
      {
        "id": 6083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850400681533,
            21.015464063883226,
            105.83866895345943,
            21.015671713757744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850400681533,
              21.015464063883226
            ],
            [
              105.83850537743324,
              21.015465415576763
            ],
            [
              105.83857381597333,
              21.015532820951595
            ],
            [
              105.83862915579911,
              21.01558661695916
            ],
            [
              105.83866895345943,
              21.015626096692408
            ],
            [
              105.8386193504066,
              21.015671713757744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.17_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6091",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015464063883226,
          "to_latitude": 21.015671713757744,
          "to_longitude": 105.8386193504066,
          "from_longitude": 105.83850400681533
        }
      },
      {
        "id": 6084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372664574742,
            21.01540978629823,
            105.83752338046304,
            21.0155414737504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752338046304,
              21.01540978629823
            ],
            [
              105.83737939580335,
              21.015484160203396
            ],
            [
              105.8372664574742,
              21.0155414737504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.25_Ngõ 186, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6092",
          "diaChiLapD": "Ngõ 186, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01540978629823,
          "to_latitude": 21.0155414737504,
          "to_longitude": 105.8372664574742,
          "from_longitude": 105.83752338046304
        }
      },
      {
        "id": 6085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815642003857,
            21.013470907237142,
            105.83836809542585,
            21.013680324843772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815642003857,
              21.013680324843772
            ],
            [
              105.83818910032547,
              21.0136532784795
            ],
            [
              105.83821336826605,
              21.013623765299798
            ],
            [
              105.83823840995109,
              21.013596039398323
            ],
            [
              105.83825725662012,
              21.013567756919365
            ],
            [
              105.83831810550757,
              21.013503993879162
            ],
            [
              105.83834295998021,
              21.01348012655584
            ],
            [
              105.83836809542585,
              21.013470907237142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.17_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6093",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013680324843772,
          "to_latitude": 21.013470907237142,
          "to_longitude": 105.83836809542585,
          "from_longitude": 105.83815642003857
        }
      },
      {
        "id": 6086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334353626915,
            21.016476609899804,
            105.8336560309956,
            21.016689432166807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334353626915,
              21.016476609899804
            ],
            [
              105.83348708667397,
              21.016524102758694
            ],
            [
              105.83354870505258,
              21.01658281394584
            ],
            [
              105.83355450334003,
              21.016588817270165
            ],
            [
              105.833557645639,
              21.01659896315113
            ],
            [
              105.83358919035263,
              21.016626951260612
            ],
            [
              105.83360728217569,
              21.016643147751104
            ],
            [
              105.83361724351656,
              21.016652915185137
            ],
            [
              105.8336560309956,
              21.016689432166807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27.2_Ngõ 216, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6094",
          "diaChiLapD": "Ngõ 216, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016476609899804,
          "to_latitude": 21.016689432166807,
          "to_longitude": 105.8336560309956,
          "from_longitude": 105.8334353626915
        }
      },
      {
        "id": 6087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774145080108,
            21.01416049934281,
            105.8379300306626,
            21.014389171174226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774145080108,
              21.01416049934281
            ],
            [
              105.8377799236975,
              21.014203156147914
            ],
            [
              105.83782368946895,
              21.014257238858615
            ],
            [
              105.8379277087336,
              21.01438627332485
            ],
            [
              105.83792959856171,
              21.014388619625933
            ],
            [
              105.8379300306626,
              21.014389171174226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.1_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6095",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01416049934281,
          "to_latitude": 21.014389171174226,
          "to_longitude": 105.8379300306626,
          "from_longitude": 105.83774145080108
        }
      },
      {
        "id": 6088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023133784443,
            21.017110597263173,
            105.84023530407941,
            21.01744584354112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84023527547667,
              21.017110597263173
            ],
            [
              105.84023530407941,
              21.017250837013666
            ],
            [
              105.84023430495859,
              21.017318035449584
            ],
            [
              105.84023133784443,
              21.017346283062594
            ],
            [
              105.840232807534,
              21.01744584354112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.1_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6096",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017110597263173,
          "to_latitude": 21.01744584354112,
          "to_longitude": 105.840232807534,
          "from_longitude": 105.84023527547667
        }
      },
      {
        "id": 6089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83531263182964,
            21.015008687974547,
            105.83551958778936,
            21.01513754389407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83551958778936,
              21.015008687974547
            ],
            [
              105.83550600556642,
              21.015019442531443
            ],
            [
              105.83547163373595,
              21.01504689231732
            ],
            [
              105.83544784664242,
              21.01506460574672
            ],
            [
              105.83542600812757,
              21.015078639803402
            ],
            [
              105.83540248156854,
              21.015091837631317
            ],
            [
              105.83537188610767,
              21.015106652614804
            ],
            [
              105.83534652766318,
              21.015118750270297
            ],
            [
              105.83531263182964,
              21.01513754389407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.6_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6097",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015008687974547,
          "to_latitude": 21.01513754389407,
          "to_longitude": 105.83531263182964,
          "from_longitude": 105.83551958778936
        }
      },
      {
        "id": 6090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953303071236,
            21.015509086414344,
            105.83983035474466,
            21.015621025014084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953303071236,
              21.015509086414344
            ],
            [
              105.83958771058963,
              21.015532223621285
            ],
            [
              105.83962317663047,
              21.015546912042318
            ],
            [
              105.83966595694073,
              21.015563610158296
            ],
            [
              105.83971760120997,
              21.01558524037236
            ],
            [
              105.83983035474466,
              21.015621025014084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.16_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6098",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015509086414344,
          "to_latitude": 21.015621025014084,
          "to_longitude": 105.83983035474466,
          "from_longitude": 105.83953303071236
        }
      },
      {
        "id": 6091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356728179628,
            21.018409238631463,
            105.83586807635241,
            21.018522405117235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356728179628,
              21.0184238826617
            ],
            [
              105.83577643968846,
              21.01841076457676
            ],
            [
              105.83579569271672,
              21.018412058238276
            ],
            [
              105.8358386561564,
              21.018409238631463
            ],
            [
              105.83586392895997,
              21.01846850053098
            ],
            [
              105.83586807635241,
              21.01848186322302
            ],
            [
              105.8358227171905,
              21.018522405117235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6099",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0184238826617,
          "to_latitude": 21.018522405117235,
          "to_longitude": 105.8358227171905,
          "from_longitude": 105.8356728179628
        }
      },
      {
        "id": 6092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83400837291411,
            21.018266301606246,
            105.83414747397127,
            21.018433365351267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400837291411,
              21.018433365351267
            ],
            [
              105.83404936918699,
              21.018410303742748
            ],
            [
              105.8340554351617,
              21.018416164830672
            ],
            [
              105.83414747397127,
              21.01835960162664
            ],
            [
              105.83409453626393,
              21.018266301606246
            ],
            [
              105.83401832923792,
              21.018303993771962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.4_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6100",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018433365351267,
          "to_latitude": 21.018303993771962,
          "to_longitude": 105.83401832923792,
          "from_longitude": 105.83400837291411
        }
      },
      {
        "id": 6093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725046098597,
            21.013338115048054,
            105.83749458861757,
            21.01354051247005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749458861757,
              21.013338115048054
            ],
            [
              105.83748598313232,
              21.01334394888075
            ],
            [
              105.83733321562099,
              21.013474240299868
            ],
            [
              105.83731803920479,
              21.01348627367789
            ],
            [
              105.83725046098597,
              21.01354051247005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.10_Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6101",
          "diaChiLapD": "Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013338115048054,
          "to_latitude": 21.01354051247005,
          "to_longitude": 105.83725046098597,
          "from_longitude": 105.83749458861757
        }
      },
      {
        "id": 6094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83207690759691,
            21.018577386293437,
            105.83215395253406,
            21.018827696963896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83214688588691,
              21.018827696963896
            ],
            [
              105.83215179226217,
              21.01882690070534
            ],
            [
              105.83215395253406,
              21.018826502654093
            ],
            [
              105.83214405775418,
              21.018787653687816
            ],
            [
              105.83213287300761,
              21.01875938237254
            ],
            [
              105.83210587278347,
              21.018682229126146
            ],
            [
              105.83210275819212,
              21.01867134695558
            ],
            [
              105.83210367590509,
              21.01864395488734
            ],
            [
              105.83207690759691,
              21.018577386293437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.4_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6102",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018827696963896,
          "to_latitude": 21.018577386293437,
          "to_longitude": 105.83207690759691,
          "from_longitude": 105.83214688588691
        }
      },
      {
        "id": 6095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494608101562,
            21.01782856082485,
            105.83524003297725,
            21.017909971273024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494608101562,
              21.01790109367975
            ],
            [
              105.83494799980195,
              21.017904148902034
            ],
            [
              105.83495182615162,
              21.017909971273024
            ],
            [
              105.83497762137816,
              21.017899176681087
            ],
            [
              105.83498205765905,
              21.0178968878133
            ],
            [
              105.83503546858329,
              21.01786933652935
            ],
            [
              105.83511451494553,
              21.01782856082485
            ],
            [
              105.8351729973442,
              21.017874447443578
            ],
            [
              105.83520552373506,
              21.01789015649917
            ],
            [
              105.83524003297725,
              21.017891921772495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.3_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6103",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01790109367975,
          "to_latitude": 21.017891921772495,
          "to_longitude": 105.83524003297725,
          "from_longitude": 105.83494608101562
        }
      },
      {
        "id": 6096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83745227114642,
            21.015946339459457,
            105.83764001122583,
            21.016156155804236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83764001122583,
              21.016156155804236
            ],
            [
              105.83762416785474,
              21.016129243671262
            ],
            [
              105.83761222935138,
              21.016110662616985
            ],
            [
              105.83758528930532,
              21.016072080362978
            ],
            [
              105.83757450645564,
              21.016055285612463
            ],
            [
              105.83755833622438,
              21.01603098901194
            ],
            [
              105.83751179300984,
              21.01596848652618
            ],
            [
              105.83749601669005,
              21.015946339459457
            ],
            [
              105.83745227114642,
              21.015974515007343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.2_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6104",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016156155804236,
          "to_latitude": 21.015974515007343,
          "to_longitude": 105.83745227114642,
          "from_longitude": 105.83764001122583
        }
      },
      {
        "id": 6097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84127510047243,
            21.013958648645154,
            105.84130291981708,
            21.014563205802983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84127563841119,
              21.013958648645154
            ],
            [
              105.84127522094815,
              21.014209584286537
            ],
            [
              105.84127516493618,
              21.014246250021223
            ],
            [
              105.84127510047243,
              21.014285353597955
            ],
            [
              105.84127630157187,
              21.014462812120303
            ],
            [
              105.8412752892217,
              21.014562906036627
            ],
            [
              105.84128225141795,
              21.014563205802983
            ],
            [
              105.84130291981708,
              21.014562591473407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6105",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013958648645154,
          "to_latitude": 21.014562591473407,
          "to_longitude": 105.84130291981708,
          "from_longitude": 105.84127563841119
        }
      },
      {
        "id": 6098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668228965453,
            21.015189380514663,
            105.8368916579783,
            21.01536478895767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668228965453,
              21.015189380514663
            ],
            [
              105.83675123121024,
              21.01528642528841
            ],
            [
              105.83677462381709,
              21.015316481537965
            ],
            [
              105.83682680377156,
              21.015283174106
            ],
            [
              105.83688271055075,
              21.015350956148662
            ],
            [
              105.83688778169761,
              21.015357649385006
            ],
            [
              105.8368916579783,
              21.015362765146826
            ],
            [
              105.83688823323985,
              21.01536478895767
            ],
            [
              105.83688756597884,
              21.015363639725894
            ],
            [
              105.83688460545847,
              21.01535955816714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.5_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6106",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015189380514663,
          "to_latitude": 21.01535955816714,
          "to_longitude": 105.83688460545847,
          "from_longitude": 105.83668228965453
        }
      },
      {
        "id": 6099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835998122919,
            21.0167376312649,
            105.83625018739576,
            21.01696221018594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625018739576,
              21.01696221018594
            ],
            [
              105.83618070897019,
              21.016884905134084
            ],
            [
              105.83613012002776,
              21.016832397361043
            ],
            [
              105.83607258497102,
              21.016809924300894
            ],
            [
              105.83607035416473,
              21.016809051007865
            ],
            [
              105.83602981905017,
              21.01677423401913
            ],
            [
              105.835998122919,
              21.0167376312649
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.16_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.6107",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01696221018594,
          "to_latitude": 21.0167376312649,
          "to_longitude": 105.835998122919,
          "from_longitude": 105.83625018739576
        }
      },
      {
        "id": 6100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815766681506,
            21.01536419032433,
            105.83834871789027,
            21.015622414652842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83834871789027,
              21.015622414652842
            ],
            [
              105.83827380338637,
              21.01554755969136
            ],
            [
              105.83824641555562,
              21.01552146681921
            ],
            [
              105.83825100674417,
              21.015505263843785
            ],
            [
              105.8382368026369,
              21.015470634673576
            ],
            [
              105.83815766681506,
              21.01536419032433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.18_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6108",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015622414652842,
          "to_latitude": 21.01536419032433,
          "to_longitude": 105.83815766681506,
          "from_longitude": 105.83834871789027
        }
      },
      {
        "id": 6101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84106435387208,
            21.016524581187195,
            105.84127340264365,
            21.016735394949052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84106435387208,
              21.01673236987839
            ],
            [
              105.84113812862617,
              21.016734345378453
            ],
            [
              105.841232928175,
              21.016735394949052
            ],
            [
              105.84124711025582,
              21.01668311489864
            ],
            [
              105.84125865201385,
              21.016632760027314
            ],
            [
              105.84127340264365,
              21.016524581187195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.2_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6109",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01673236987839,
          "to_latitude": 21.016524581187195,
          "to_longitude": 105.84127340264365,
          "from_longitude": 105.84106435387208
        }
      },
      {
        "id": 6102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376412844872,
            21.01516343984068,
            105.83788755291265,
            21.015358137241616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83788071520509,
              21.015358137241616
            ],
            [
              105.83788755291265,
              21.015351471192105
            ],
            [
              105.83779852304671,
              21.015253138474023
            ],
            [
              105.83775535712248,
              21.015189564448356
            ],
            [
              105.83774228379559,
              21.01516343984068
            ],
            [
              105.83771559910143,
              21.01517038154577
            ],
            [
              105.83768969476407,
              21.01518494351903
            ],
            [
              105.8376412844872,
              21.015209399117992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.5_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6110",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015358137241616,
          "to_latitude": 21.015209399117992,
          "to_longitude": 105.8376412844872,
          "from_longitude": 105.83788071520509
        }
      },
      {
        "id": 6103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332521754407,
            21.018482535826042,
            105.83345545411301,
            21.018696521857382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345545411301,
              21.018696521857382
            ],
            [
              105.8334246626035,
              21.01859088706932
            ],
            [
              105.83335752778501,
              21.01858155327728
            ],
            [
              105.8333497474149,
              21.018540511839724
            ],
            [
              105.83326890271213,
              21.01855660681549
            ],
            [
              105.8332521754407,
              21.018482535826042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5.1_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6111",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018696521857382,
          "to_latitude": 21.018482535826042,
          "to_longitude": 105.8332521754407,
          "from_longitude": 105.83345545411301
        }
      },
      {
        "id": 6104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364914566042,
            21.01804759985733,
            105.83680843422319,
            21.01819280394999
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83680843422319,
              21.01804759985733
            ],
            [
              105.83680840732455,
              21.018047606312205
            ],
            [
              105.83679454738284,
              21.018051201580143
            ],
            [
              105.83666412136353,
              21.018085036584097
            ],
            [
              105.83665566553431,
              21.018087229651663
            ],
            [
              105.8365382797108,
              21.01812779718577
            ],
            [
              105.83655535532942,
              21.01817367105708
            ],
            [
              105.8364914566042,
              21.01819280394999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.6A_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.6112",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01804759985733,
          "to_latitude": 21.01819280394999,
          "to_longitude": 105.8364914566042,
          "from_longitude": 105.83680843422319
        }
      },
      {
        "id": 6105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84042822769563,
            21.016723714587823,
            105.84083278790648,
            21.01675040510749
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84042931984207,
              21.01675040510749
            ],
            [
              105.84042909065687,
              21.016745726638966
            ],
            [
              105.84042822769563,
              21.016728026819106
            ],
            [
              105.84058239304058,
              21.01672555534469
            ],
            [
              105.84083278790648,
              21.016723714587823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.5_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6113",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01675040510749,
          "to_latitude": 21.016723714587823,
          "to_longitude": 105.84083278790648,
          "from_longitude": 105.84042931984207
        }
      },
      {
        "id": 6106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036659694269,
            21.01634202417055,
            105.84039934288869,
            21.01675239676032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84036659694269,
              21.01675239676032
            ],
            [
              105.84036801707764,
              21.016749189624367
            ],
            [
              105.84037703375621,
              21.01672882887791
            ],
            [
              105.84037958733123,
              21.016612703272955
            ],
            [
              105.84037955043145,
              21.01653933280509
            ],
            [
              105.84037973728327,
              21.016520832961785
            ],
            [
              105.84039753081397,
              21.016501007823944
            ],
            [
              105.84039934288869,
              21.01648723374641
            ],
            [
              105.84039787073586,
              21.016440811718304
            ],
            [
              105.84039132625907,
              21.01634202417055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.6_Ngách 222/61, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6114",
          "diaChiLapD": "Ngách 222/61, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01675239676032,
          "to_latitude": 21.01634202417055,
          "to_longitude": 105.84039132625907,
          "from_longitude": 105.84036659694269
        }
      },
      {
        "id": 6107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903583848777,
            21.012449801340146,
            105.83913925654329,
            21.012574759102822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83913925654329,
              21.012574759102822
            ],
            [
              105.83904427419613,
              21.012562745855803
            ],
            [
              105.83903583848777,
              21.012449801340146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.18A_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6115",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012574759102822,
          "to_latitude": 21.012449801340146,
          "to_longitude": 105.83903583848777,
          "from_longitude": 105.83913925654329
        }
      },
      {
        "id": 6108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778837584586,
            21.01381884682558,
            105.83783788147102,
            21.01387864216744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783788147102,
              21.01387864216744
            ],
            [
              105.83778837584586,
              21.01381884682558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.3_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6116",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01387864216744,
          "to_latitude": 21.01381884682558,
          "to_longitude": 105.83778837584586,
          "from_longitude": 105.83783788147102
        }
      },
      {
        "id": 6109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736498639252,
            21.014382450276962,
            105.83740916725547,
            21.01444904522457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83740916725547,
              21.01444904522457
            ],
            [
              105.83736498639252,
              21.014382450276962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.4_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6117",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01444904522457,
          "to_latitude": 21.014382450276962,
          "to_longitude": 105.83736498639252,
          "from_longitude": 105.83740916725547
        }
      },
      {
        "id": 6110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83772838083884,
            21.01411342080199,
            105.83776562081002,
            21.01416049934281
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774145080108,
              21.01416049934281
            ],
            [
              105.83772838083884,
              21.014144433016412
            ],
            [
              105.83776562081002,
              21.01411342080199
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.1_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6118",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01416049934281,
          "to_latitude": 21.01411342080199,
          "to_longitude": 105.83776562081002,
          "from_longitude": 105.83774145080108
        }
      },
      {
        "id": 6111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83422973616801,
            21.01735892596804,
            105.83426439941722,
            21.01745083068196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83426439941722,
              21.01745083068196
            ],
            [
              105.83422973616801,
              21.01735892596804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.2_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6119",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01745083068196,
          "to_latitude": 21.01735892596804,
          "to_longitude": 105.83422973616801,
          "from_longitude": 105.83426439941722
        }
      },
      {
        "id": 6112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83677868485579,
            21.01654377431238,
            105.8368137388796,
            21.01662323825497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83677868485579,
              21.01654377431238
            ],
            [
              105.8368137388796,
              21.01662323825497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.10_Ngách 178/80/4, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6120",
          "diaChiLapD": "Ngách 178/80/4, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654377431238,
          "to_latitude": 21.01662323825497,
          "to_longitude": 105.8368137388796,
          "from_longitude": 105.83677868485579
        }
      },
      {
        "id": 6113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83906134569082,
            21.0160194261765,
            105.83911964432632,
            21.01610752721136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906134569082,
              21.0160194261765
            ],
            [
              105.83911964432632,
              21.01610752721136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.6121",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0160194261765,
          "to_latitude": 21.01610752721136,
          "to_longitude": 105.83911964432632,
          "from_longitude": 105.83906134569082
        }
      },
      {
        "id": 6114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83780992019649,
            21.01260357079552,
            105.83790194040458,
            21.012668049557266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83780992019649,
              21.01260357079552
            ],
            [
              105.83790194040458,
              21.012668049557266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6122",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01260357079552,
          "to_latitude": 21.012668049557266,
          "to_longitude": 105.83790194040458,
          "from_longitude": 105.83780992019649
        }
      },
      {
        "id": 6115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83801351652227,
            21.01405618472659,
            105.83809457721264,
            21.014097488886225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809457721264,
              21.01406258311073
            ],
            [
              105.83805116523396,
              21.014097488886225
            ],
            [
              105.83801351652227,
              21.01405618472659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.3_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6123",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01406258311073,
          "to_latitude": 21.01405618472659,
          "to_longitude": 105.83801351652227,
          "from_longitude": 105.83809457721264
        }
      },
      {
        "id": 6116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328012243757,
            21.017272513279632,
            105.83289041526909,
            21.017359637826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328012243757,
              21.017359637826
            ],
            [
              105.83289041526909,
              21.017272513279632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.7_Ngách 60, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6124",
          "diaChiLapD": "Ngách 60, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017359637826,
          "to_latitude": 21.017272513279632,
          "to_longitude": 105.83289041526909,
          "from_longitude": 105.8328012243757
        }
      },
      {
        "id": 6117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83772918710015,
            21.014976075369557,
            105.83780732894398,
            21.01504102532232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83772918710015,
              21.014985866342773
            ],
            [
              105.83776165918268,
              21.014976075369557
            ],
            [
              105.83780732894398,
              21.01504102532232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.22_Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6125",
          "diaChiLapD": "Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014985866342773,
          "to_latitude": 21.01504102532232,
          "to_longitude": 105.83780732894398,
          "from_longitude": 105.83772918710015
        }
      },
      {
        "id": 6118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265888582129,
            21.01695711283482,
            105.83273242229126,
            21.01703127896252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83270918784375,
              21.01695711283482
            ],
            [
              105.83273242229126,
              21.01698060669011
            ],
            [
              105.83265888582129,
              21.01703127896252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12.2_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6126",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01695711283482,
          "to_latitude": 21.01703127896252,
          "to_longitude": 105.83265888582129,
          "from_longitude": 105.83270918784375
        }
      },
      {
        "id": 6119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83788461084556,
            21.012954463843194,
            105.83792710267636,
            21.013027368878717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791788619831,
              21.012954463843194
            ],
            [
              105.83792710267636,
              21.012958828898817
            ],
            [
              105.83788461084556,
              21.013027368878717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.4_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6127",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012954463843194,
          "to_latitude": 21.013027368878717,
          "to_longitude": 105.83788461084556,
          "from_longitude": 105.83791788619831
        }
      },
      {
        "id": 6120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876370489217,
            21.013441037079247,
            105.83882231151753,
            21.01355086631281
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83876370489217,
              21.01355086631281
            ],
            [
              105.83882231151753,
              21.013441037079247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.19_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6128",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01355086631281,
          "to_latitude": 21.013441037079247,
          "to_longitude": 105.83882231151753,
          "from_longitude": 105.83876370489217
        }
      },
      {
        "id": 6121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375201466107,
            21.012753809027195,
            105.83760480786945,
            21.012842706713883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375201466107,
              21.012753809027195
            ],
            [
              105.83760480786945,
              21.012842706713883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.3_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6129",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012753809027195,
          "to_latitude": 21.012842706713883,
          "to_longitude": 105.83760480786945,
          "from_longitude": 105.8375201466107
        }
      },
      {
        "id": 6122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83443541300957,
            21.018474951976078,
            105.83446105378452,
            21.018586499369338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443541300957,
              21.018474951976078
            ],
            [
              105.83446105378452,
              21.018572089908353
            ],
            [
              105.83444662574583,
              21.018586499369338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6130",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018474951976078,
          "to_latitude": 21.018586499369338,
          "to_longitude": 105.83444662574583,
          "from_longitude": 105.83443541300957
        }
      },
      {
        "id": 6123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83351180205925,
            21.015525163251528,
            105.83360521263573,
            21.01561375541334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83351180205925,
              21.015525163251528
            ],
            [
              105.83359138216969,
              21.015598051069254
            ],
            [
              105.83360521263573,
              21.01561375541334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.18_Ngõ 180, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6131",
          "diaChiLapD": "Ngõ 180, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015525163251528,
          "to_latitude": 21.01561375541334,
          "to_longitude": 105.83360521263573,
          "from_longitude": 105.83351180205925
        }
      },
      {
        "id": 6124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736627482031,
            21.012739578257342,
            105.83747124880107,
            21.0128212378625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83747124880107,
              21.0128212378625
            ],
            [
              105.83736627482031,
              21.012739578257342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6132",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0128212378625,
          "to_latitude": 21.012739578257342,
          "to_longitude": 105.83736627482031,
          "from_longitude": 105.83747124880107
        }
      },
      {
        "id": 6125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773897163569,
            21.014612066730557,
            105.83782112385562,
            21.014712743815174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773897163569,
              21.014612066730557
            ],
            [
              105.83782112385562,
              21.014712743815174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.7_Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6133",
          "diaChiLapD": "Ngách 191/2, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014612066730557,
          "to_latitude": 21.014712743815174,
          "to_longitude": 105.83782112385562,
          "from_longitude": 105.83773897163569
        }
      },
      {
        "id": 6126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83889095993396,
            21.015846482559486,
            105.83898022588721,
            21.015939195452166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83889095993396,
              21.015846482559486
            ],
            [
              105.83898022588721,
              21.015939195452166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.16_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6134",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015846482559486,
          "to_latitude": 21.015939195452166,
          "to_longitude": 105.83898022588721,
          "from_longitude": 105.83889095993396
        }
      },
      {
        "id": 6127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83223929145029,
            21.018432778490254,
            105.83227791441705,
            21.01852804702644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227293186938,
              21.018432778490254
            ],
            [
              105.83223929145029,
              21.018447879700933
            ],
            [
              105.83227791441705,
              21.01852804702644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.4_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6135",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018432778490254,
          "to_latitude": 21.01852804702644,
          "to_longitude": 105.83227791441705,
          "from_longitude": 105.83227293186938
        }
      },
      {
        "id": 6128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895478542766,
            21.015596534049443,
            105.83905799158782,
            21.015669520274685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895478542766,
              21.015617058782293
            ],
            [
              105.83897912014574,
              21.015596534049443
            ],
            [
              105.83905799158782,
              21.015669520274685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.16_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6136",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015617058782293,
          "to_latitude": 21.015669520274685,
          "to_longitude": 105.83905799158782,
          "from_longitude": 105.83895478542766
        }
      },
      {
        "id": 6129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379620337059,
            21.01329134146034,
            105.83808881526882,
            21.013358041538748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379620337059,
              21.01329134146034
            ],
            [
              105.83808881526882,
              21.013358041538748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.14_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6137",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01329134146034,
          "to_latitude": 21.013358041538748,
          "to_longitude": 105.83808881526882,
          "from_longitude": 105.8379620337059
        }
      },
      {
        "id": 6130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83988914500979,
            21.017674330302185,
            105.84001880193436,
            21.01769764407777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84001788095516,
              21.017674330302185
            ],
            [
              105.84001880193436,
              21.017684705598132
            ],
            [
              105.83988914500979,
              21.01769764407777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.5_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6138",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017674330302185,
          "to_latitude": 21.01769764407777,
          "to_longitude": 105.83988914500979,
          "from_longitude": 105.84001788095516
        }
      },
      {
        "id": 6131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435566865185,
            21.01755538464847,
            105.83439582722951,
            21.017703901903374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439582722951,
              21.01755538464847
            ],
            [
              105.83438809259499,
              21.01758485054024
            ],
            [
              105.83435566865185,
              21.017703901903374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6139",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01755538464847,
          "to_latitude": 21.017703901903374,
          "to_longitude": 105.83435566865185,
          "from_longitude": 105.83439582722951
        }
      },
      {
        "id": 6132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855630163085,
            21.016251798513,
            105.83866024936879,
            21.016335341430693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855630163085,
              21.016251798513
            ],
            [
              105.83862205655817,
              21.016335341430693
            ],
            [
              105.83866024936879,
              21.016308146764054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.5_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.6140",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016251798513,
          "to_latitude": 21.016308146764054,
          "to_longitude": 105.83866024936879,
          "from_longitude": 105.83855630163085
        }
      },
      {
        "id": 6133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83429993110342,
            21.01757242501029,
            105.83436504731246,
            21.01764687536518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436504731246,
              21.01757242501029
            ],
            [
              105.83431302076472,
              21.017591531373032
            ],
            [
              105.83429993110342,
              21.017628808415527
            ],
            [
              105.83436167622305,
              21.01764687536518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6141",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01757242501029,
          "to_latitude": 21.01764687536518,
          "to_longitude": 105.83436167622305,
          "from_longitude": 105.83436504731246
        }
      },
      {
        "id": 6134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83913925654329,
            21.012574759102822,
            105.83918054387522,
            21.012717875098573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918054387522,
              21.012716963207822
            ],
            [
              105.83915876195847,
              21.012717603538533
            ],
            [
              105.839149484825,
              21.012717875098573
            ],
            [
              105.83913925654329,
              21.012574759102822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.18A_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6142",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012716963207822,
          "to_latitude": 21.012574759102822,
          "to_longitude": 105.83913925654329,
          "from_longitude": 105.83918054387522
        }
      },
      {
        "id": 6135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753798509166,
            21.013976335578313,
            105.83766260153685,
            21.01408571402477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753798509166,
              21.013976335578313
            ],
            [
              105.83766260153685,
              21.01408571402477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.3_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6143",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013976335578313,
          "to_latitude": 21.01408571402477,
          "to_longitude": 105.83766260153685,
          "from_longitude": 105.83753798509166
        }
      },
      {
        "id": 6136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945566909955,
            21.014148947818594,
            105.83963700458216,
            21.014219396130507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945566909955,
              21.01414948403702
            ],
            [
              105.83959884103825,
              21.014219396130507
            ],
            [
              105.83963700458216,
              21.014148947818594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.25_Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6144",
          "diaChiLapD": "Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01414948403702,
          "to_latitude": 21.014148947818594,
          "to_longitude": 105.83963700458216,
          "from_longitude": 105.83945566909955
        }
      },
      {
        "id": 6137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876305455887,
            21.015403106421545,
            105.83886601166279,
            21.01551530578212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886601166279,
              21.01551530578212
            ],
            [
              105.83876305455887,
              21.015403106421545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46A.1_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6145",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01551530578212,
          "to_latitude": 21.015403106421545,
          "to_longitude": 105.83876305455887,
          "from_longitude": 105.83886601166279
        }
      },
      {
        "id": 6138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494655097897,
            21.01751627375321,
            105.83511322433358,
            21.0175986313615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494655097897,
              21.0175986313615
            ],
            [
              105.83511322433358,
              21.01751627375321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6146",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0175986313615,
          "to_latitude": 21.01751627375321,
          "to_longitude": 105.83511322433358,
          "from_longitude": 105.83494655097897
        }
      },
      {
        "id": 6139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886480587822,
            21.013465916899094,
            105.83892003377376,
            21.013612972143306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886480587822,
              21.013612972143306
            ],
            [
              105.83889181528014,
              21.01357935284603
            ],
            [
              105.83890681208901,
              21.013553973274853
            ],
            [
              105.83891588118514,
              21.013499807373076
            ],
            [
              105.83891734370722,
              21.01349110663032
            ],
            [
              105.83892003377376,
              21.013465916899094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.19_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6147",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013612972143306,
          "to_latitude": 21.013465916899094,
          "to_longitude": 105.83892003377376,
          "from_longitude": 105.83886480587822
        }
      },
      {
        "id": 6140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83680464369837,
            21.0167504998757,
            105.83686033363229,
            21.01687394547575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83682015419197,
              21.0167504998757
            ],
            [
              105.83680464369837,
              21.016764616788866
            ],
            [
              105.83685035209925,
              21.016855943643893
            ],
            [
              105.83686033363229,
              21.01687394547575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.7_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6148",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0167504998757,
          "to_latitude": 21.01687394547575,
          "to_longitude": 105.83686033363229,
          "from_longitude": 105.83682015419197
        }
      },
      {
        "id": 6141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83622291722668,
            21.013709462084588,
            105.83637012966165,
            21.01380340838172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83637012966165,
              21.013709462084588
            ],
            [
              105.83622291722668,
              21.01380340838172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.9_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6149",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013709462084588,
          "to_latitude": 21.01380340838172,
          "to_longitude": 105.83622291722668,
          "from_longitude": 105.83637012966165
        }
      },
      {
        "id": 6142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83772254030974,
            21.01236554209367,
            105.83776118187332,
            21.01251066182541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774226683748,
              21.01236554209367
            ],
            [
              105.8377272362668,
              21.012404184680836
            ],
            [
              105.83776118187332,
              21.012420307198898
            ],
            [
              105.83772254030974,
              21.01251066182541
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.5_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6150",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01236554209367,
          "to_latitude": 21.01251066182541,
          "to_longitude": 105.83772254030974,
          "from_longitude": 105.83774226683748
        }
      },
      {
        "id": 6143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83152651178513,
            21.01831738863723,
            105.83168070498418,
            21.0184156599955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168070498418,
              21.01831738863723
            ],
            [
              105.83152651178513,
              21.0184156599955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.1_Ngách 316/30, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6151",
          "diaChiLapD": "Ngách 316/30, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01831738863723,
          "to_latitude": 21.0184156599955,
          "to_longitude": 105.83152651178513,
          "from_longitude": 105.83168070498418
        }
      },
      {
        "id": 6144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83782368946895,
            21.014163491465474,
            105.83793464065465,
            21.014257238858615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793464065465,
              21.014163491465474
            ],
            [
              105.83782368946895,
              21.014257238858615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.1_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6152",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014163491465474,
          "to_latitude": 21.014257238858615,
          "to_longitude": 105.83782368946895,
          "from_longitude": 105.83793464065465
        }
      },
      {
        "id": 6145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83776541862635,
            21.01381418846174,
            105.83787577240345,
            21.013971463856706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83776541862635,
              21.013971463856706
            ],
            [
              105.8378439146907,
              21.01389586945896
            ],
            [
              105.83783788147102,
              21.01387864216744
            ],
            [
              105.8378323898009,
              21.013862962141598
            ],
            [
              105.83787577240345,
              21.01381418846174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.3_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6153",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013971463856706,
          "to_latitude": 21.01381418846174,
          "to_longitude": 105.83787577240345,
          "from_longitude": 105.83776541862635
        }
      },
      {
        "id": 6146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83354436427462,
            21.017264694839294,
            105.83368854781594,
            21.01739520515883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83354436427462,
              21.017264694839294
            ],
            [
              105.8335864902546,
              21.01729764850929
            ],
            [
              105.83362672630821,
              21.01727492492485
            ],
            [
              105.83368854781594,
              21.01739520515883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.8_Ngách 77, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6154",
          "diaChiLapD": "Ngách 77, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017264694839294,
          "to_latitude": 21.01739520515883,
          "to_longitude": 105.83368854781594,
          "from_longitude": 105.83354436427462
        }
      },
      {
        "id": 6147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83275311932911,
            21.01630834264952,
            105.83293443108587,
            21.016457183513978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83293443108587,
              21.016457183513978
            ],
            [
              105.83276736229863,
              21.016320034723698
            ],
            [
              105.83275551104629,
              21.016310308101257
            ],
            [
              105.83275327203384,
              21.016308471966408
            ],
            [
              105.83275311932911,
              21.01630834264952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.16_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6155",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016457183513978,
          "to_latitude": 21.01630834264952,
          "to_longitude": 105.83275311932911,
          "from_longitude": 105.83293443108587
        }
      },
      {
        "id": 6148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569377890177,
            21.01420773697525,
            105.83586941970063,
            21.01433782321866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569377890177,
              21.01433782321866
            ],
            [
              105.8356971272893,
              21.01433521813027
            ],
            [
              105.83569765399125,
              21.014334622124633
            ],
            [
              105.83570436153425,
              21.01432702828523
            ],
            [
              105.83586941970063,
              21.01420773697525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.4A_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6156",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01433782321866,
          "to_latitude": 21.01420773697525,
          "to_longitude": 105.83586941970063,
          "from_longitude": 105.83569377890177
        }
      },
      {
        "id": 6149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83226539874518,
            21.017934961182352,
            105.83237050374095,
            21.018089427999307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237050374095,
              21.017934961182352
            ],
            [
              105.83231917203315,
              21.017976208809163
            ],
            [
              105.83226539874518,
              21.018012061663114
            ],
            [
              105.83231899328523,
              21.018089427999307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26.3_Ngách 268/41, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6157",
          "diaChiLapD": "Ngách 268/41, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017934961182352,
          "to_latitude": 21.018089427999307,
          "to_longitude": 105.83231899328523,
          "from_longitude": 105.83237050374095
        }
      },
      {
        "id": 6150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8348760726072,
            21.017608272343786,
            105.83499559924964,
            21.017794120228668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499559924964,
              21.017794120228668
            ],
            [
              105.8348760726072,
              21.017608272343786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18.3_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6158",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017794120228668,
          "to_latitude": 21.017608272343786,
          "to_longitude": 105.8348760726072,
          "from_longitude": 105.83499559924964
        }
      },
      {
        "id": 6151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377799236975,
            21.014071133803878,
            105.83793561476972,
            21.014203156147914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793561476972,
              21.014071133803878
            ],
            [
              105.8377799236975,
              21.014203156147914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.1_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6159",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014071133803878,
          "to_latitude": 21.014203156147914,
          "to_longitude": 105.8377799236975,
          "from_longitude": 105.83793561476972
        }
      },
      {
        "id": 6152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362575195004,
            21.015406755786902,
            105.83379803123826,
            21.01556864735018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362575195004,
              21.015406755786902
            ],
            [
              105.83369863790028,
              21.01548154061239
            ],
            [
              105.833719679557,
              21.015487473750177
            ],
            [
              105.83379803123826,
              21.01556864735018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.18_Ngõ 176, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6160",
          "diaChiLapD": "Ngõ 176, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015406755786902,
          "to_latitude": 21.01556864735018,
          "to_longitude": 105.83379803123826,
          "from_longitude": 105.83362575195004
        }
      },
      {
        "id": 6153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83205625814067,
            21.016994475656418,
            105.83224265806581,
            21.017086595252298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83224265806581,
              21.01701871597443
            ],
            [
              105.83218114885997,
              21.017086595252298
            ],
            [
              105.83205630741905,
              21.01699451515729
            ],
            [
              105.83205625814067,
              21.016994475656418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.14_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6161",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01701871597443,
          "to_latitude": 21.016994475656418,
          "to_longitude": 105.83205625814067,
          "from_longitude": 105.83224265806581
        }
      },
      {
        "id": 6154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751753662591,
            21.01287953354515,
            105.83772768940821,
            21.01306411518519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751753662591,
              21.01287953354515
            ],
            [
              105.83751791808963,
              21.012880320182326
            ],
            [
              105.83752235139342,
              21.012889420022915
            ],
            [
              105.83753631111551,
              21.01291808558409
            ],
            [
              105.83755750242604,
              21.012952931535963
            ],
            [
              105.83772768940821,
              21.01306411518519
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.1A_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6162",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01287953354515,
          "to_latitude": 21.01306411518519,
          "to_longitude": 105.83772768940821,
          "from_longitude": 105.83751753662591
        }
      },
      {
        "id": 6155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83271992464007,
            21.016368635519655,
            105.83295470747464,
            21.01654874348807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83295470747464,
              21.01654874348807
            ],
            [
              105.83271992464007,
              21.016368635519655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.16_Ngõ 236, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6163",
          "diaChiLapD": "Ngõ 236, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654874348807,
          "to_latitude": 21.016368635519655,
          "to_longitude": 105.83271992464007,
          "from_longitude": 105.83295470747464
        }
      },
      {
        "id": 6156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83655535532942,
            21.01817367105708,
            105.83661192355568,
            21.018466414972433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83655535532942,
              21.01817367105708
            ],
            [
              105.8365883213393,
              21.018309024145633
            ],
            [
              105.83661192355568,
              21.018466414972433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21.6A_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.6164",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01817367105708,
          "to_latitude": 21.018466414972433,
          "to_longitude": 105.83661192355568,
          "from_longitude": 105.83655535532942
        }
      },
      {
        "id": 6157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83972496673194,
            21.01457196309532,
            105.84003014097249,
            21.01468110511893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83972496673194,
              21.01457196309532
            ],
            [
              105.8398798295494,
              21.01461576554813
            ],
            [
              105.83993378747059,
              21.014634395518488
            ],
            [
              105.84003014097249,
              21.01468110511893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.8_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6165",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01457196309532,
          "to_latitude": 21.01468110511893,
          "to_longitude": 105.84003014097249,
          "from_longitude": 105.83972496673194
        }
      },
      {
        "id": 6158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351706726806,
            21.018084174297936,
            105.83535939453064,
            21.018336081402794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83532513115087,
              21.018084174297936
            ],
            [
              105.83535939453064,
              21.018175462915295
            ],
            [
              105.83522187166795,
              21.018250053538146
            ],
            [
              105.83524985966966,
              21.01830376544217
            ],
            [
              105.8351706726806,
              21.018336081402794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.2_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6166",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018084174297936,
          "to_latitude": 21.018336081402794,
          "to_longitude": 105.8351706726806,
          "from_longitude": 105.83532513115087
        }
      },
      {
        "id": 6159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8330819419433,
            21.01794064814593,
            105.83341063043372,
            21.018117679078372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330819419433,
              21.01794064814593
            ],
            [
              105.833146169878,
              21.018031966633526
            ],
            [
              105.83316776377212,
              21.018024691980344
            ],
            [
              105.8332315431769,
              21.018117679078372
            ],
            [
              105.83341063043372,
              21.01804852614754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6167",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01794064814593,
          "to_latitude": 21.01804852614754,
          "to_longitude": 105.83341063043372,
          "from_longitude": 105.8330819419433
        }
      },
      {
        "id": 6160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002633896723,
            21.014414199180464,
            105.84038380445152,
            21.014744983667402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002633896723,
              21.01474474968089
            ],
            [
              105.84002673954892,
              21.014744824477066
            ],
            [
              105.84002760906317,
              21.014744983667402
            ],
            [
              105.8400345354417,
              21.014714838699057
            ],
            [
              105.84004265707982,
              21.014682386414698
            ],
            [
              105.84006260172775,
              21.014610074295433
            ],
            [
              105.84009417182686,
              21.01459832101874
            ],
            [
              105.84015057135751,
              21.014583155586433
            ],
            [
              105.84019438087434,
              21.014571375270545
            ],
            [
              105.84029834567131,
              21.01449917114867
            ],
            [
              105.84038380445152,
              21.014414199180464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.9A_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6168",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01474474968089,
          "to_latitude": 21.014414199180464,
          "to_longitude": 105.84038380445152,
          "from_longitude": 105.84002633896723
        }
      },
      {
        "id": 6161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83898730985376,
            21.01374561442564,
            105.8392334274481,
            21.01409776651027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83898730985376,
              21.01374561442564
            ],
            [
              105.83901133864329,
              21.01376383318883
            ],
            [
              105.83906313301803,
              21.01378233231194
            ],
            [
              105.8391780462934,
              21.013867776878747
            ],
            [
              105.8392334274481,
              21.01392250282818
            ],
            [
              105.83920087453889,
              21.013962664475393
            ],
            [
              105.83911377615044,
              21.014094402050063
            ],
            [
              105.83909161415639,
              21.014097464892558
            ],
            [
              105.83908942842383,
              21.01409776651027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.21A_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6169",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01374561442564,
          "to_latitude": 21.01409776651027,
          "to_longitude": 105.83908942842383,
          "from_longitude": 105.83898730985376
        }
      },
      {
        "id": 6162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84128752825991,
            21.017997055313387,
            105.84132037477491,
            21.01799761427271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84128752825991,
              21.01799761427271
            ],
            [
              105.84129733056774,
              21.017997448376473
            ],
            [
              105.84130713382734,
              21.017997280668528
            ],
            [
              105.84132037477491,
              21.017997055313387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.3_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6170",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01799761427271,
          "to_latitude": 21.017997055313387,
          "to_longitude": 105.84132037477491,
          "from_longitude": 105.84128752825991
        }
      },
      {
        "id": 6163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8408745101214,
            21.014903830994793,
            105.84088694668253,
            21.014905160491644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8408745101214,
              21.014903830994793
            ],
            [
              105.84088694668253,
              21.014905160491644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.4_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6171",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014903830994793,
          "to_latitude": 21.014905160491644,
          "to_longitude": 105.84088694668253,
          "from_longitude": 105.8408745101214
        }
      },
      {
        "id": 6164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84129215775314,
            21.017497343721082,
            105.84132310110631,
            21.017853384182764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84132310110631,
              21.017853384182764
            ],
            [
              105.84130257948503,
              21.017852978965937
            ],
            [
              105.84129215775314,
              21.017852984442143
            ],
            [
              105.84129220929124,
              21.017850339549746
            ],
            [
              105.84129433256308,
              21.017741489750314
            ],
            [
              105.84130908902512,
              21.017643772924103
            ],
            [
              105.84131403839896,
              21.017564745318477
            ],
            [
              105.84131667024204,
              21.017497343721082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6172",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017853384182764,
          "to_latitude": 21.017497343721082,
          "to_longitude": 105.84131667024204,
          "from_longitude": 105.84132310110631
        }
      },
      {
        "id": 6165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83185418135292,
            21.017911487214377,
            105.83215853596226,
            21.018282952127144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83186605103342,
              21.017911487214377
            ],
            [
              105.83186031070028,
              21.017915583409383
            ],
            [
              105.83185418135292,
              21.01791995699005
            ],
            [
              105.8319404837299,
              21.018012543620507
            ],
            [
              105.83193909061032,
              21.018013719206444
            ],
            [
              105.83193827306835,
              21.018015301138348
            ],
            [
              105.83193815101423,
              21.01801705579778
            ],
            [
              105.83193874224848,
              21.018018723872203
            ],
            [
              105.83193996075387,
              21.018020059201902
            ],
            [
              105.8319401102583,
              21.018020131631964
            ],
            [
              105.83194045846064,
              21.01802030063856
            ],
            [
              105.83194162555291,
              21.01802086486572
            ],
            [
              105.83194349068809,
              21.018021021937592
            ],
            [
              105.83194528090807,
              21.018020506473352
            ],
            [
              105.83194673210403,
              21.018019395635527
            ],
            [
              105.83195315969807,
              21.018027861739697
            ],
            [
              105.83192623347259,
              21.01806805595808
            ],
            [
              105.83195406049762,
              21.01810319621642
            ],
            [
              105.83199860895753,
              21.018159453373208
            ],
            [
              105.83206409956371,
              21.018236758898336
            ],
            [
              105.83208460498071,
              21.018244288138746
            ],
            [
              105.83209159079216,
              21.018237371395653
            ],
            [
              105.83215853596226,
              21.018282952127144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25.2_Ngõ 298, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6173",
          "diaChiLapD": "Ngõ 298, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017911487214377,
          "to_latitude": 21.018282952127144,
          "to_longitude": 105.83215853596226,
          "from_longitude": 105.83186605103342
        }
      },
      {
        "id": 6166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735415820814,
            21.016598600076044,
            105.83739257101062,
            21.016658479912977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735415820814,
              21.016598600076044
            ],
            [
              105.83735473863378,
              21.01659970095889
            ],
            [
              105.83737177604284,
              21.016632001291924
            ],
            [
              105.83739257101062,
              21.016658479912977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41.6_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6174",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016598600076044,
          "to_latitude": 21.016658479912977,
          "to_longitude": 105.83739257101062,
          "from_longitude": 105.83735415820814
        }
      },
      {
        "id": 6167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84083489810726,
            21.014750141328165,
            105.84104135684503,
            21.015264111803045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84088176176499,
              21.015264111803045
            ],
            [
              105.84088181257874,
              21.015263738520588
            ],
            [
              105.8408859519366,
              21.015258104527263
            ],
            [
              105.84088778787148,
              21.01519591844796
            ],
            [
              105.84088583644085,
              21.01517933316693
            ],
            [
              105.84089064924983,
              21.01512258332779
            ],
            [
              105.84089474891648,
              21.015102148406207
            ],
            [
              105.84089869197288,
              21.015074025116483
            ],
            [
              105.8409053050541,
              21.014970178624697
            ],
            [
              105.8408787174348,
              21.014954763751536
            ],
            [
              105.84088694668253,
              21.014905160491644
            ],
            [
              105.84089160656272,
              21.01487707069081
            ],
            [
              105.84083489810726,
              21.014868533696443
            ],
            [
              105.84084213286215,
              21.014750141328165
            ],
            [
              105.8410010080727,
              21.01476283666899
            ],
            [
              105.84104135684503,
              21.014765692819097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.3_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6175",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015264111803045,
          "to_latitude": 21.014765692819097,
          "to_longitude": 105.84104135684503,
          "from_longitude": 105.84088176176499
        }
      },
      {
        "id": 6168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713595093153,
            21.0166997195782,
            105.83717768768625,
            21.016750381965053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713595093153,
              21.0166997195782
            ],
            [
              105.83717768768625,
              21.016750381965053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41.6_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6176",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0166997195782,
          "to_latitude": 21.016750381965053,
          "to_longitude": 105.83717768768625,
          "from_longitude": 105.83713595093153
        }
      },
      {
        "id": 6169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83861782826453,
            21.01460694734896,
            105.83883848639938,
            21.014722946525968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83861782826453,
              21.01471210002599
            ],
            [
              105.8386184371748,
              21.014712445669037
            ],
            [
              105.83861953995813,
              21.014713035457856
            ],
            [
              105.83862302621607,
              21.01470842989571
            ],
            [
              105.83863209604809,
              21.014694800700042
            ],
            [
              105.83864206973666,
              21.01468059169489
            ],
            [
              105.83866101812421,
              21.01465552505219
            ],
            [
              105.83867616087757,
              21.014632308905508
            ],
            [
              105.8386779377532,
              21.014629590474513
            ],
            [
              105.83870344753173,
              21.01460694734896
            ],
            [
              105.83871754202585,
              21.014619216810548
            ],
            [
              105.83877297241783,
              21.014666899318904
            ],
            [
              105.8388269189782,
              21.014712587579506
            ],
            [
              105.83883848639938,
              21.014722946525968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT49.7_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6177",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01471210002599,
          "to_latitude": 21.014722946525968,
          "to_longitude": 105.83883848639938,
          "from_longitude": 105.83861782826453
        }
      },
      {
        "id": 6170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958177874592,
            21.01603427817834,
            105.83965024211597,
            21.016102378454022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958177874592,
              21.01603427817834
            ],
            [
              105.83965024211597,
              21.016102378454022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.14_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6178",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01603427817834,
          "to_latitude": 21.016102378454022,
          "to_longitude": 105.83965024211597,
          "from_longitude": 105.83958177874592
        }
      },
      {
        "id": 6171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854701787153,
            21.016182283975287,
            105.83896533075823,
            21.0163480631755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896533075823,
              21.016182283975287
            ],
            [
              105.83896054078382,
              21.01618340230403
            ],
            [
              105.83892142922883,
              21.016202481584788
            ],
            [
              105.83889219913493,
              21.01621854142821
            ],
            [
              105.83887557367395,
              21.016227438872306
            ],
            [
              105.8388567572188,
              21.016238111114326
            ],
            [
              105.838821554876,
              21.016258035482238
            ],
            [
              105.83879981348845,
              21.01626825156029
            ],
            [
              105.83879599565404,
              21.016268270384106
            ],
            [
              105.83876965102903,
              21.016260534097785
            ],
            [
              105.8387521521527,
              21.016260321405
            ],
            [
              105.8387397671066,
              21.016264265424727
            ],
            [
              105.83872295416626,
              21.016273011133766
            ],
            [
              105.8387064646324,
              21.01628265214803
            ],
            [
              105.83867730338855,
              21.016301914447663
            ],
            [
              105.83866243658434,
              21.016317222413264
            ],
            [
              105.83862502120517,
              21.0163480631755
            ],
            [
              105.83854701787153,
              21.016252472916864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT90.5A_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.6179",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016182283975287,
          "to_latitude": 21.016252472916864,
          "to_longitude": 105.83854701787153,
          "from_longitude": 105.83896533075823
        }
      },
      {
        "id": 6172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946546962747,
            21.015614224381903,
            105.83989334731926,
            21.016171398779
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83988163785367,
              21.015614224381903
            ],
            [
              105.83988218937847,
              21.01561565868802
            ],
            [
              105.83989334731926,
              21.01564459979313
            ],
            [
              105.83988940028424,
              21.015655556414234
            ],
            [
              105.83986010483645,
              21.015696327134698
            ],
            [
              105.83982931012038,
              21.015741980852823
            ],
            [
              105.83979371678923,
              21.01579425359214
            ],
            [
              105.83973430812982,
              21.015866001766337
            ],
            [
              105.8396647787456,
              21.01594855365333
            ],
            [
              105.83964276627535,
              21.015971843530767
            ],
            [
              105.83958708300037,
              21.01602708311512
            ],
            [
              105.83958177874592,
              21.01603427817834
            ],
            [
              105.83952183466499,
              21.016101488418887
            ],
            [
              105.83946546962747,
              21.016171398779
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.14_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6180",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015614224381903,
          "to_latitude": 21.016171398779,
          "to_longitude": 105.83946546962747,
          "from_longitude": 105.83988163785367
        }
      },
      {
        "id": 6173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8403889155747,
            21.01519290808944,
            105.84049514573908,
            21.015224166852644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84049514573908,
              21.015224166852644
            ],
            [
              105.8403889155747,
              21.01519290808944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.8_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6181",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015224166852644,
          "to_latitude": 21.01519290808944,
          "to_longitude": 105.8403889155747,
          "from_longitude": 105.84049514573908
        }
      },
      {
        "id": 6174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83690532659422,
            21.016464828093685,
            105.83755763589843,
            21.01697237246366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755551978643,
              21.01653143865267
            ],
            [
              105.83755612438173,
              21.016530666129274
            ],
            [
              105.83755763589843,
              21.016528826046244
            ],
            [
              105.83755063937576,
              21.016522911858623
            ],
            [
              105.83753731978481,
              21.01651247835772
            ],
            [
              105.83755752756726,
              21.016490477495893
            ],
            [
              105.83753397260608,
              21.016464828093685
            ],
            [
              105.83747885598996,
              21.016508540890737
            ],
            [
              105.83742297592343,
              21.016549562208315
            ],
            [
              105.83735415820814,
              21.016598600076044
            ],
            [
              105.83734843816508,
              21.016602675577182
            ],
            [
              105.8373199835424,
              21.016566252218286
            ],
            [
              105.83727705008262,
              21.016591228121683
            ],
            [
              105.83726516613562,
              21.01659448221607
            ],
            [
              105.83719763178352,
              21.016639076166502
            ],
            [
              105.83712917005464,
              21.016688664063985
            ],
            [
              105.83713595093153,
              21.0166997195782
            ],
            [
              105.83708804421812,
              21.016762280479362
            ],
            [
              105.83704543576357,
              21.016810691584556
            ],
            [
              105.83706007706682,
              21.016825780159774
            ],
            [
              105.83700861376902,
              21.016881581649564
            ],
            [
              105.83695037455868,
              21.01694170406502
            ],
            [
              105.83690532659422,
              21.01697237246366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41.6_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6182",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01653143865267,
          "to_latitude": 21.01697237246366,
          "to_longitude": 105.83690532659422,
          "from_longitude": 105.83755551978643
        }
      },
      {
        "id": 6175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83672720116535,
            21.01870560256026,
            105.83686709206407,
            21.01874484572617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83686709206407,
              21.01870560256026
            ],
            [
              105.83683215440541,
              21.018716050414284
            ],
            [
              105.83681773814169,
              21.018721152290098
            ],
            [
              105.83680604365088,
              21.018725291487367
            ],
            [
              105.836759069136,
              21.01873627634397
            ],
            [
              105.83672720116535,
              21.01874484572617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6183",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01870560256026,
          "to_latitude": 21.01874484572617,
          "to_longitude": 105.83672720116535,
          "from_longitude": 105.83686709206407
        }
      },
      {
        "id": 6176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358985756341,
            21.017564646255302,
            105.83606043680052,
            21.01757981427893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358985756341,
              21.01757981427893
            ],
            [
              105.83589973252627,
              21.017579236853415
            ],
            [
              105.83591176954138,
              21.017573222749796
            ],
            [
              105.83599272167636,
              21.017564646255302
            ],
            [
              105.83606043680052,
              21.01756718120936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6184",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01757981427893,
          "to_latitude": 21.01756718120936,
          "to_longitude": 105.83606043680052,
          "from_longitude": 105.8358985756341
        }
      },
      {
        "id": 6177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83621072956518,
            21.018623923371564,
            105.8363285607147,
            21.018643027762938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363285607147,
              21.018623923371564
            ],
            [
              105.83628515149823,
              21.01863254670269
            ],
            [
              105.83625267604286,
              21.018637234216346
            ],
            [
              105.83621072956518,
              21.018643027762938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6185",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018623923371564,
          "to_latitude": 21.018643027762938,
          "to_longitude": 105.83621072956518,
          "from_longitude": 105.8363285607147
        }
      },
      {
        "id": 6178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83657870968307,
            21.01874484572617,
            105.83672720116535,
            21.01877294193834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83657870968307,
              21.01877294193834
            ],
            [
              105.83659568495668,
              21.01877011626657
            ],
            [
              105.83662001591578,
              21.018764805776794
            ],
            [
              105.83665778250814,
              21.018756562369802
            ],
            [
              105.83669932909494,
              21.01874909339275
            ],
            [
              105.83672720116535,
              21.01874484572617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6186",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01877294193834,
          "to_latitude": 21.01874484572617,
          "to_longitude": 105.83672720116535,
          "from_longitude": 105.83657870968307
        }
      },
      {
        "id": 6179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668168388309,
            21.01855813036064,
            105.83684705126723,
            21.01862011038936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684705126723,
              21.01856434751774
            ],
            [
              105.83684615281423,
              21.01856141916968
            ],
            [
              105.83684508593163,
              21.01855813036064
            ],
            [
              105.83677041208583,
              21.018592410143096
            ],
            [
              105.83668168388309,
              21.01862011038936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6187",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01856434751774,
          "to_latitude": 21.01862011038936,
          "to_longitude": 105.83668168388309,
          "from_longitude": 105.83684705126723
        }
      },
      {
        "id": 6180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83649323207658,
            21.018617752890652,
            105.8365161897098,
            21.018744387124645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83649400188351,
              21.018617752890652
            ],
            [
              105.8365161897098,
              21.01871559570891
            ],
            [
              105.83649323207658,
              21.018720153367568
            ],
            [
              105.83649868974963,
              21.018744387124645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6188",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018617752890652,
          "to_latitude": 21.018744387124645,
          "to_longitude": 105.83649868974963,
          "from_longitude": 105.83649400188351
        }
      },
      {
        "id": 6181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83636303381658,
            21.018597230459484,
            105.83653667134038,
            21.018622454144335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636303381658,
              21.018622454144335
            ],
            [
              105.83639266004228,
              21.0186195075831
            ],
            [
              105.83646306629613,
              21.018611705281224
            ],
            [
              105.83649455032722,
              21.018607428150496
            ],
            [
              105.83649897553808,
              21.018601430660294
            ],
            [
              105.83653667134038,
              21.018597230459484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6189",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018622454144335,
          "to_latitude": 21.018597230459484,
          "to_longitude": 105.83653667134038,
          "from_longitude": 105.83636303381658
        }
      },
      {
        "id": 6182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593739822463,
            21.017842710121425,
            105.8359652302624,
            21.018012169033902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359652302624,
              21.018012169033902
            ],
            [
              105.83596421250415,
              21.01800526346776
            ],
            [
              105.83596059331364,
              21.0179806982027
            ],
            [
              105.83595907364457,
              21.017970381819058
            ],
            [
              105.83595433078067,
              21.01793682144735
            ],
            [
              105.83595345852443,
              21.017930650390472
            ],
            [
              105.83594996244962,
              21.017905909294377
            ],
            [
              105.83593739822463,
              21.017842710121425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6190",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018012169033902,
          "to_latitude": 21.017842710121425,
          "to_longitude": 105.83593739822463,
          "from_longitude": 105.8359652302624
        }
      },
      {
        "id": 6183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84131667024204,
            21.017323122828763,
            105.8413210471437,
            21.017497343721082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8413210471437,
              21.017323122828763
            ],
            [
              105.84131667024204,
              21.017497343721082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6191",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017323122828763,
          "to_latitude": 21.017497343721082,
          "to_longitude": 105.84131667024204,
          "from_longitude": 105.8413210471437
        }
      },
      {
        "id": 6184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84128752825991,
            21.0179639433559,
            105.84130304263354,
            21.01821231678955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84130304263354,
              21.01821231678955
            ],
            [
              105.84129373974511,
              21.018162270041167
            ],
            [
              105.84128983108457,
              21.01805864812581
            ],
            [
              105.84128752825991,
              21.01799761427271
            ],
            [
              105.84128855528921,
              21.0179639433559
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.3_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6192",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01821231678955,
          "to_latitude": 21.0179639433559,
          "to_longitude": 105.84128855528921,
          "from_longitude": 105.84130304263354
        }
      },
      {
        "id": 6185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593739822463,
            21.017790048852447,
            105.83619958725016,
            21.017842710121425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593739822463,
              21.017842710121425
            ],
            [
              105.83594621034116,
              21.01784075559641
            ],
            [
              105.83603936395926,
              21.0178200926881
            ],
            [
              105.83619958725016,
              21.017790048852447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6193",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017842710121425,
          "to_latitude": 21.017790048852447,
          "to_longitude": 105.83619958725016,
          "from_longitude": 105.83593739822463
        }
      },
      {
        "id": 6186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84132910535776,
            21.01675929933397,
            105.84133016170124,
            21.01701530603796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84132910535776,
              21.01701530603796
            ],
            [
              105.84132911008926,
              21.01690914205337
            ],
            [
              105.84133016170124,
              21.01675929933397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6194",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01701530603796,
          "to_latitude": 21.01675929933397,
          "to_longitude": 105.84133016170124,
          "from_longitude": 105.84132910535776
        }
      },
      {
        "id": 6187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83596421250415,
            21.01792684110916,
            105.83625712428842,
            21.01800526346776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83596421250415,
              21.01800526346776
            ],
            [
              105.83596959964544,
              21.018004093512662
            ],
            [
              105.83598056511948,
              21.01800171111407
            ],
            [
              105.83608072727776,
              21.01797995335377
            ],
            [
              105.836092901758,
              21.017972365144946
            ],
            [
              105.83625712428842,
              21.01792684110916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6195",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01800526346776,
          "to_latitude": 21.01792684110916,
          "to_longitude": 105.83625712428842,
          "from_longitude": 105.83596421250415
        }
      },
      {
        "id": 6188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569397923443,
            21.018012169033902,
            105.8359652302624,
            21.018059490352712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359652302624,
              21.018012169033902
            ],
            [
              105.83588783246606,
              21.01802267450479
            ],
            [
              105.83588760551942,
              21.018027693029854
            ],
            [
              105.8358008161937,
              21.018044416339052
            ],
            [
              105.83580050672221,
              21.01804066857622
            ],
            [
              105.83569397923443,
              21.018059490352712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6196",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018012169033902,
          "to_latitude": 21.018059490352712,
          "to_longitude": 105.83569397923443,
          "from_longitude": 105.8359652302624
        }
      },
      {
        "id": 6189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83532562450849,
            21.01787293275847,
            105.83552544160156,
            21.01791817599624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83532562450849,
              21.017895551563612
            ],
            [
              105.8354034819626,
              21.01787293275847
            ],
            [
              105.83542301147811,
              21.01791817599624
            ],
            [
              105.83552544160156,
              21.017898254517608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6197",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017895551563612,
          "to_latitude": 21.017898254517608,
          "to_longitude": 105.83552544160156,
          "from_longitude": 105.83532562450849
        }
      },
      {
        "id": 6190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84132674429611,
            21.01701530603796,
            105.84132995422364,
            21.01732325131894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84132910535776,
              21.01701530603796
            ],
            [
              105.84132995422364,
              21.017090664983158
            ],
            [
              105.84132674429611,
              21.017169562958458
            ],
            [
              105.84132921863598,
              21.01732325131894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95.2_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6198",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01701530603796,
          "to_latitude": 21.01732325131894,
          "to_longitude": 105.84132921863598,
          "from_longitude": 105.84132910535776
        }
      },
      {
        "id": 6191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735662793708,
            21.014915405080192,
            105.83767008786805,
            21.015108721603852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735662793708,
              21.015108721603852
            ],
            [
              105.8373586601886,
              21.015107976377035
            ],
            [
              105.83738638593339,
              21.015097802383806
            ],
            [
              105.83741963297157,
              21.015078288968517
            ],
            [
              105.83748228847628,
              21.015042457653887
            ],
            [
              105.83751871129965,
              21.015016998920427
            ],
            [
              105.83757474731792,
              21.01497920403336
            ],
            [
              105.83767008786805,
              21.014915405080192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.21_Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6199",
          "diaChiLapD": "Ngõ 191, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015108721603852,
          "to_latitude": 21.014915405080192,
          "to_longitude": 105.83767008786805,
          "from_longitude": 105.83735662793708
        }
      },
      {
        "id": 6192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83382917551597,
            21.015409258089843,
            105.83411825655308,
            21.015672410713314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411825655308,
              21.015409258089843
            ],
            [
              105.8341179211882,
              21.015409667088406
            ],
            [
              105.8340429686016,
              21.01550109043864
            ],
            [
              105.83397970224134,
              21.015561500251682
            ],
            [
              105.8339558805484,
              21.01557321081702
            ],
            [
              105.83382917551597,
              21.015672410713314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29.2_Ngách 164/9, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6200",
          "diaChiLapD": "Ngách 164/9, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015409258089843,
          "to_latitude": 21.015672410713314,
          "to_longitude": 105.83382917551597,
          "from_longitude": 105.83411825655308
        }
      },
      {
        "id": 6193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340484465889,
            21.017955805977955,
            105.83354714498272,
            21.01832597817235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83354714498272,
              21.01832597817235
            ],
            [
              105.83353087796729,
              21.018288872128174
            ],
            [
              105.83348990414505,
              21.01818789639272
            ],
            [
              105.83344254299577,
              21.018034121432827
            ],
            [
              105.83343697344127,
              21.018022550413725
            ],
            [
              105.83343237026786,
              21.01801298794732
            ],
            [
              105.83340484465889,
              21.017955805977955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6201",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832597817235,
          "to_latitude": 21.017955805977955,
          "to_longitude": 105.83340484465889,
          "from_longitude": 105.83354714498272
        }
      },
      {
        "id": 6194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83171704674908,
            21.018010237799167,
            105.83195486655659,
            21.018275410529288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195486655659,
              21.018010237799167
            ],
            [
              105.83195355737513,
              21.018011111297643
            ],
            [
              105.83194045846064,
              21.01802030063856
            ],
            [
              105.8319381941764,
              21.01802188874254
            ],
            [
              105.83190779048837,
              21.018049770043042
            ],
            [
              105.83176796914947,
              21.018145933652086
            ],
            [
              105.83175030070079,
              21.01812750212644
            ],
            [
              105.83171704674908,
              21.018152445591543
            ],
            [
              105.8317592592186,
              21.018222178606084
            ],
            [
              105.83176558842041,
              21.018218801211557
            ],
            [
              105.8318011156709,
              21.018275410529288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25.3_Ngách 298/11, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6202",
          "diaChiLapD": "Ngách 298/11, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018010237799167,
          "to_latitude": 21.018275410529288,
          "to_longitude": 105.8318011156709,
          "from_longitude": 105.83195486655659
        }
      },
      {
        "id": 6195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83339125147432,
            21.0176613282224,
            105.83371816543445,
            21.017964662753183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339125147432,
              21.017964662753183
            ],
            [
              105.83340198831459,
              21.017959212482864
            ],
            [
              105.83340484465889,
              21.017955805977955
            ],
            [
              105.8334182930753,
              21.017939766566137
            ],
            [
              105.83344215627174,
              21.01792731431669
            ],
            [
              105.83350598915784,
              21.017890912327374
            ],
            [
              105.83357837404229,
              21.017851446220057
            ],
            [
              105.8336884889624,
              21.017791812242464
            ],
            [
              105.83371816543445,
              21.017773695344985
            ],
            [
              105.83367885750475,
              21.017721305866836
            ],
            [
              105.8336288932705,
              21.0176613282224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6203",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017964662753183,
          "to_latitude": 21.0176613282224,
          "to_longitude": 105.8336288932705,
          "from_longitude": 105.83339125147432
        }
      },
      {
        "id": 6196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83523276989682,
            21.017438262468424,
            105.8354034819626,
            21.01787293275847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8354034819626,
              21.01787293275847
            ],
            [
              105.83533244490174,
              21.017741349439135
            ],
            [
              105.83536933817454,
              21.017715027158687
            ],
            [
              105.83531196855675,
              21.01761158982245
            ],
            [
              105.83523276989682,
              21.017438262468424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6204",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01787293275847,
          "to_latitude": 21.017438262468424,
          "to_longitude": 105.83523276989682,
          "from_longitude": 105.8354034819626
        }
      },
      {
        "id": 6197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84038879501571,
            21.015023562382346,
            105.84052259499602,
            21.015408237688792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84043239515918,
              21.015408237688792
            ],
            [
              105.84043448231229,
              21.01538965258509
            ],
            [
              105.84043088565805,
              21.015379183023146
            ],
            [
              105.84044013323945,
              21.015332577067113
            ],
            [
              105.84044885041388,
              21.01530993896266
            ],
            [
              105.84046857139936,
              21.01527704639371
            ],
            [
              105.84048470147277,
              21.015249646000793
            ],
            [
              105.84049514573908,
              21.015224166852644
            ],
            [
              105.84050928187499,
              21.015178254804837
            ],
            [
              105.84051315385064,
              21.01516386990441
            ],
            [
              105.84052259499602,
              21.015088469122066
            ],
            [
              105.84048102830457,
              21.015082688810505
            ],
            [
              105.8404862796988,
              21.0150401508414
            ],
            [
              105.84040276828044,
              21.01502583812787
            ],
            [
              105.84038879501571,
              21.015023562382346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.8_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6205",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015408237688792,
          "to_latitude": 21.015023562382346,
          "to_longitude": 105.84038879501571,
          "from_longitude": 105.84043239515918
        }
      },
      {
        "id": 6198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543833374208,
            21.01756600641603,
            105.83591176954138,
            21.017684206520027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591176954138,
              21.017573222749796
            ],
            [
              105.83591176454549,
              21.01757318935505
            ],
            [
              105.83591076352454,
              21.01756600641603
            ],
            [
              105.83590182892247,
              21.01756772850885
            ],
            [
              105.83589703394809,
              21.0175686525844
            ],
            [
              105.83585002781716,
              21.01757771076114
            ],
            [
              105.83582052313385,
              21.01758120126962
            ],
            [
              105.83580313043271,
              21.017585224780635
            ],
            [
              105.83570359060612,
              21.01760710757265
            ],
            [
              105.83554954023795,
              21.017625930575004
            ],
            [
              105.83549116295728,
              21.01763673263049
            ],
            [
              105.83547408788739,
              21.01764044745205
            ],
            [
              105.83547599040533,
              21.01765439563223
            ],
            [
              105.83543833374208,
              21.017662823392847
            ],
            [
              105.83544068457734,
              21.017684206520027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.4_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6206",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017573222749796,
          "to_latitude": 21.017684206520027,
          "to_longitude": 105.83544068457734,
          "from_longitude": 105.83591176954138
        }
      },
      {
        "id": 6199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818987458405,
            21.015905619186096,
            105.83860273729182,
            21.01611427010613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83860273729182,
              21.01604136956433
            ],
            [
              105.83853859138245,
              21.01608541803963
            ],
            [
              105.83849674195376,
              21.016099822083493
            ],
            [
              105.83845476386273,
              21.01611427010613
            ],
            [
              105.83839879504517,
              21.016025068965963
            ],
            [
              105.83828612628437,
              21.016041973427743
            ],
            [
              105.83820732536978,
              21.01591840595521
            ],
            [
              105.83820519364401,
              21.015916843945245
            ],
            [
              105.83819053622884,
              21.015906105473654
            ],
            [
              105.83818987458405,
              21.015905619186096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46.21_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6207",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01604136956433,
          "to_latitude": 21.015905619186096,
          "to_longitude": 105.83818987458405,
          "from_longitude": 105.83860273729182
        }
      },
      {
        "id": 6200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83508802266415,
            21.01747039444796,
            105.83532562450849,
            21.017895551563612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83508802266415,
              21.01747039444796
            ],
            [
              105.83511322433358,
              21.01751627375321
            ],
            [
              105.83522355451287,
              21.01769565256636
            ],
            [
              105.83522737533482,
              21.01770186334472
            ],
            [
              105.83519418528763,
              21.017717256464717
            ],
            [
              105.83521358757746,
              21.017754178949314
            ],
            [
              105.83519045823348,
              21.01777383285958
            ],
            [
              105.83522290921994,
              21.017822643644987
            ],
            [
              105.83524861224727,
              21.01786473685757
            ],
            [
              105.83525985222565,
              21.017883143572984
            ],
            [
              105.83532562450849,
              21.017895551563612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6208",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01747039444796,
          "to_latitude": 21.017895551563612,
          "to_longitude": 105.83532562450849,
          "from_longitude": 105.83508802266415
        }
      },
      {
        "id": 6201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489546771939,
            21.01818111887057,
            105.83515878751803,
            21.01854770084454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515878751803,
              21.01854765047197
            ],
            [
              105.83515680617072,
              21.01854770084454
            ],
            [
              105.83512210229576,
              21.018540723104234
            ],
            [
              105.83507113718949,
              21.018452313716107
            ],
            [
              105.83503407732029,
              21.018470179865826
            ],
            [
              105.83499761761466,
              21.01840509817526
            ],
            [
              105.83498817362126,
              21.018403973952818
            ],
            [
              105.83489546771939,
              21.01821139911373
            ],
            [
              105.83492427545669,
              21.018201399935624
            ],
            [
              105.83491490375299,
              21.01818111887057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.4_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6209",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01854765047197,
          "to_latitude": 21.01818111887057,
          "to_longitude": 105.83491490375299,
          "from_longitude": 105.83515878751803
        }
      },
      {
        "id": 6202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84082451697726,
            21.01475653048115,
            105.84089788150717,
            21.01526413395603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84088038632481,
              21.01526413395603
            ],
            [
              105.84088044510193,
              21.0152634652801
            ],
            [
              105.84087803877276,
              21.01525805060648
            ],
            [
              105.84088023117317,
              21.015203512165503
            ],
            [
              105.84088152098747,
              21.015188044409975
            ],
            [
              105.84088119489799,
              21.01517569082684
            ],
            [
              105.84088841492807,
              21.015083504662318
            ],
            [
              105.84089788150717,
              21.014973381115002
            ],
            [
              105.84087084270405,
              21.01495778782592
            ],
            [
              105.84087360900692,
              21.014917082125393
            ],
            [
              105.8408745101214,
              21.014903830994793
            ],
            [
              105.84087608030028,
              21.014880720581694
            ],
            [
              105.84082451697726,
              21.014871798671294
            ],
            [
              105.84083268020053,
              21.01475653048115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.4_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6210",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01526413395603,
          "to_latitude": 21.01475653048115,
          "to_longitude": 105.84083268020053,
          "from_longitude": 105.84088038632481
        }
      },
      {
        "id": 6203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83950234723481,
            21.015613833573266,
            105.83988639328923,
            21.015939747286573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987901823868,
              21.015613833573266
            ],
            [
              105.83987964249438,
              21.0156160596475
            ],
            [
              105.83988639328923,
              21.015640098134504
            ],
            [
              105.83987316522143,
              21.015664061833014
            ],
            [
              105.83985486775369,
              21.015691634547732
            ],
            [
              105.83982742321105,
              21.015733292123528
            ],
            [
              105.83978826081847,
              21.01578740518556
            ],
            [
              105.8397693009517,
              21.015807662339927
            ],
            [
              105.83971751420931,
              21.015876923343075
            ],
            [
              105.8396649487689,
              21.015939747286573
            ],
            [
              105.83950234723481,
              21.015830024958117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.15_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6211",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015613833573266,
          "to_latitude": 21.015830024958117,
          "to_longitude": 105.83950234723481,
          "from_longitude": 105.83987901823868
        }
      },
      {
        "id": 6204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83705651665939,
            21.015455138400156,
            105.83756624837325,
            21.015692801936602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705651665939,
              21.015577963781706
            ],
            [
              105.83710377243193,
              21.015643410118063
            ],
            [
              105.83713431483558,
              21.015627374830963
            ],
            [
              105.83714892932278,
              21.01562371802177
            ],
            [
              105.83716704227466,
              21.015620043996183
            ],
            [
              105.83717799129018,
              21.01562009037598
            ],
            [
              105.83722183024358,
              21.015671734161888
            ],
            [
              105.83723904216875,
              21.015663289216658
            ],
            [
              105.83725430161117,
              21.015692801936602
            ],
            [
              105.83735867688652,
              21.01564031040406
            ],
            [
              105.83733124111015,
              21.015582858578757
            ],
            [
              105.83738703406351,
              21.015553515509634
            ],
            [
              105.83754252890175,
              21.01547441887734
            ],
            [
              105.83755660912017,
              21.0154624270024
            ],
            [
              105.8375637846981,
              21.015456315688137
            ],
            [
              105.83756624837325,
              21.015455138400156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.26_Ngõ 188, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6212",
          "diaChiLapD": "Ngõ 188, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015577963781706,
          "to_latitude": 21.015455138400156,
          "to_longitude": 105.83756624837325,
          "from_longitude": 105.83705651665939
        }
      },
      {
        "id": 6205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698033238463,
            21.01630197103448,
            105.83718726329023,
            21.01680603965162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698033238463,
              21.01680603965162
            ],
            [
              105.83701199781541,
              21.016729536135347
            ],
            [
              105.83705372780452,
              21.016654717283128
            ],
            [
              105.83708084059893,
              21.016630493085678
            ],
            [
              105.83708589175198,
              21.016623597406223
            ],
            [
              105.83707915289759,
              21.01661317574623
            ],
            [
              105.83709845189199,
              21.016593962241842
            ],
            [
              105.83710572083912,
              21.01658526365249
            ],
            [
              105.83712344478694,
              21.016568746793563
            ],
            [
              105.83710962200351,
              21.016543124409193
            ],
            [
              105.83706830109737,
              21.016492843826242
            ],
            [
              105.83713027083289,
              21.016454841941723
            ],
            [
              105.83717228404633,
              21.01643010002282
            ],
            [
              105.83718726329023,
              21.01642180877734
            ],
            [
              105.83713558748181,
              21.016322993840593
            ],
            [
              105.83717308586749,
              21.01630197103448
            ],
            [
              105.83717369869296,
              21.016303879240926
            ],
            [
              105.8371739561328,
              21.01630467642414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT40.7_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6213",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01680603965162,
          "to_latitude": 21.01630467642414,
          "to_longitude": 105.8371739561328,
          "from_longitude": 105.83698033238463
        }
      },
      {
        "id": 6206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397970224134,
            21.015561500251682,
            105.83435625216192,
            21.015981234836197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83397970224134,
              21.015561500251682
            ],
            [
              105.83403589487756,
              21.015620003033337
            ],
            [
              105.83406600852801,
              21.015651354459333
            ],
            [
              105.83406351353281,
              21.015698456309146
            ],
            [
              105.83415356663333,
              21.015806633880928
            ],
            [
              105.83425688500837,
              21.015771265508036
            ],
            [
              105.83430628594878,
              21.01584159309529
            ],
            [
              105.83431262354068,
              21.015859467540135
            ],
            [
              105.83435625216192,
              21.01592032330208
            ],
            [
              105.83430305406948,
              21.0159509325406
            ],
            [
              105.83430309129638,
              21.015957625252312
            ],
            [
              105.83426566179047,
              21.015981234836197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29.2_Ngách 164/9, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6214",
          "diaChiLapD": "Ngách 164/9, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015561500251682,
          "to_latitude": 21.015981234836197,
          "to_longitude": 105.83426566179047,
          "from_longitude": 105.83397970224134
        }
      },
      {
        "id": 6207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299758443988,
            21.018744139778136,
            105.83330613349854,
            21.018755665934663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83330613349854,
              21.018744139778136
            ],
            [
              105.83325568457154,
              21.018753477858198
            ],
            [
              105.83319225217033,
              21.018751746372043
            ],
            [
              105.83317017575855,
              21.018751143663433
            ],
            [
              105.83313489256027,
              21.018750180216248
            ],
            [
              105.83299758443988,
              21.018755665934663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.2_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6215",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018744139778136,
          "to_latitude": 21.018755665934663,
          "to_longitude": 105.83299758443988,
          "from_longitude": 105.83330613349854
        }
      },
      {
        "id": 6208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391776544676,
            21.015318580683875,
            105.83922042675282,
            21.015441180378833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920194902515,
              21.015318580683875
            ],
            [
              105.83919353593596,
              21.015324423589426
            ],
            [
              105.83919232266514,
              21.01532557847474
            ],
            [
              105.83919130651276,
              21.015326891355137
            ],
            [
              105.8391905103927,
              21.015328331407957
            ],
            [
              105.8391899514426,
              21.015329866935886
            ],
            [
              105.8391896438989,
              21.01533146354614
            ],
            [
              105.83918959333104,
              21.01533308508233
            ],
            [
              105.83918980049874,
              21.015334695411752
            ],
            [
              105.8391902623192,
              21.015336259323867
            ],
            [
              105.83919096704756,
              21.015337740747658
            ],
            [
              105.83921904980042,
              21.01538657863148
            ],
            [
              105.83921976233577,
              21.015388079887554
            ],
            [
              105.83922022427295,
              21.015389664573128
            ],
            [
              105.83922042675282,
              21.015391296602985
            ],
            [
              105.83922036379671,
              21.015392938974415
            ],
            [
              105.83922003711614,
              21.015394553743583
            ],
            [
              105.83921945419421,
              21.01539610293819
            ],
            [
              105.83921862830553,
              21.01539755217024
            ],
            [
              105.83921757849623,
              21.01539886702325
            ],
            [
              105.8391776544676,
              21.015441180378833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.30_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6216",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015318580683875,
          "to_latitude": 21.015441180378833,
          "to_longitude": 105.8391776544676,
          "from_longitude": 105.83920194902515
        }
      },
      {
        "id": 6209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83469085255636,
            21.017570490727547,
            105.83494507766343,
            21.01791770298779
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83469085255636,
              21.017570490727547
            ],
            [
              105.83471330228848,
              21.017625584743644
            ],
            [
              105.83472313739237,
              21.017652744312162
            ],
            [
              105.83473109339411,
              21.0176615080979
            ],
            [
              105.83476364987128,
              21.017747450011264
            ],
            [
              105.83477969547761,
              21.017824925727187
            ],
            [
              105.83476335708576,
              21.017838986007707
            ],
            [
              105.83479810513884,
              21.017908716925618
            ],
            [
              105.83490355805877,
              21.017855145816082
            ],
            [
              105.83494094527734,
              21.01791770298779
            ],
            [
              105.83494507766343,
              21.01791577419253
            ],
            [
              105.8349389419238,
              21.017906541742562
            ],
            [
              105.83493794088207,
              21.01790487659409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.4_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6217",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017570490727547,
          "to_latitude": 21.01790487659409,
          "to_longitude": 105.83493794088207,
          "from_longitude": 105.83469085255636
        }
      },
      {
        "id": 6210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368087691606,
            21.018257344163246,
            105.83377108838053,
            21.018300560029314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368087691606,
              21.018300560029314
            ],
            [
              105.83376032543453,
              21.01826193444905
            ],
            [
              105.83376129730974,
              21.01826337303829
            ],
            [
              105.83376270677735,
              21.018264448193005
            ],
            [
              105.83376440506783,
              21.018265046836042
            ],
            [
              105.83376621869276,
              21.018265108398452
            ],
            [
              105.83376796003111,
              21.018264625670795
            ],
            [
              105.83376918781138,
              21.01826381939621
            ],
            [
              105.83376944659283,
              21.018263649224863
            ],
            [
              105.83377052695066,
              21.01826228006078
            ],
            [
              105.83377108838053,
              21.01826065963405
            ],
            [
              105.83377107217187,
              21.01825895532896
            ],
            [
              105.83377048123283,
              21.018257344163246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10.2_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6218",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018300560029314,
          "to_latitude": 21.018257344163246,
          "to_longitude": 105.83377048123283,
          "from_longitude": 105.83368087691606
        }
      },
      {
        "id": 6211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569370628136,
            21.014298126418378,
            105.83576443546775,
            21.014399078755407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573259470413,
              21.014298126418378
            ],
            [
              105.83569370628136,
              21.014330313263446
            ],
            [
              105.83569765399125,
              21.014334622124633
            ],
            [
              105.8357160579297,
              21.014354709761076
            ],
            [
              105.835756899467,
              21.014399078755407
            ],
            [
              105.83576294506595,
              21.014394740676817
            ],
            [
              105.83576413834211,
              21.014396208877475
            ],
            [
              105.83576443546775,
              21.01439653438517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32.4_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6219",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014298126418378,
          "to_latitude": 21.01439653438517,
          "to_longitude": 105.83576443546775,
          "from_longitude": 105.83573259470413
        }
      },
      {
        "id": 6212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562296672432,
            21.01670635318101,
            105.83566587389171,
            21.016734013874647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562296672432,
              21.01670635318101
            ],
            [
              105.83565231581704,
              21.016734013874647
            ],
            [
              105.83566587389171,
              21.016726324062667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.12_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6220",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01670635318101,
          "to_latitude": 21.016726324062667,
          "to_longitude": 105.83566587389171,
          "from_longitude": 105.83562296672432
        }
      },
      {
        "id": 6213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421787507082,
            21.016548265169604,
            105.83427331598091,
            21.01661351952969
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83427331598091,
              21.01661351952969
            ],
            [
              105.83425635530199,
              21.016591782605
            ],
            [
              105.83423862592878,
              21.016570199383164
            ],
            [
              105.83421787507082,
              21.016548265169604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6221",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01661351952969,
          "to_latitude": 21.016548265169604,
          "to_longitude": 105.83421787507082,
          "from_longitude": 105.83427331598091
        }
      },
      {
        "id": 6214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325693940617,
            21.018358474643914,
            105.83355650904893,
            21.018454553175726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355650904893,
              21.01841795370065
            ],
            [
              105.83353087040608,
              21.018358474643914
            ],
            [
              105.83345828980738,
              21.01838786803224
            ],
            [
              105.83342700618647,
              21.018401042187946
            ],
            [
              105.83341620433383,
              21.01837852714213
            ],
            [
              105.8333260956194,
              21.018406307383508
            ],
            [
              105.8333328789802,
              21.018424769481744
            ],
            [
              105.83325693940617,
              21.018454553175726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.3_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6222",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01841795370065,
          "to_latitude": 21.018454553175726,
          "to_longitude": 105.83325693940617,
          "from_longitude": 105.83355650904893
        }
      },
      {
        "id": 6215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954925091139,
            21.016152535197957,
            105.83978276856644,
            21.016619042781354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954925091139,
              21.016152535197957
            ],
            [
              105.83961517904538,
              21.016184790920924
            ],
            [
              105.8395979713785,
              21.01621752467037
            ],
            [
              105.839587504422,
              21.01623898904971
            ],
            [
              105.83964428539139,
              21.01628703128366
            ],
            [
              105.83959937844389,
              21.016373171160872
            ],
            [
              105.8395886795312,
              21.016389802632023
            ],
            [
              105.83958606150698,
              21.016399493593703
            ],
            [
              105.8396342372307,
              21.016412519671302
            ],
            [
              105.83969246975816,
              21.016420681915204
            ],
            [
              105.83976971650792,
              21.016431509629392
            ],
            [
              105.83977121629435,
              21.01644493225785
            ],
            [
              105.83977278401194,
              21.016489386604192
            ],
            [
              105.83977393758477,
              21.016600238960734
            ],
            [
              105.83978276856644,
              21.01661524939563
            ],
            [
              105.83978264441443,
              21.016618330904144
            ],
            [
              105.83978261665615,
              21.016619042781354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT72A.1_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6223",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016152535197957,
          "to_latitude": 21.016619042781354,
          "to_longitude": 105.83978261665615,
          "from_longitude": 105.83954925091139
        }
      },
      {
        "id": 6216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342054541854,
            21.01523214587673,
            105.83443078714954,
            21.0155002585665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342054541854,
              21.0155002585665
            ],
            [
              105.8342102753987,
              21.015495932867285
            ],
            [
              105.83421330748499,
              21.015490928586768
            ],
            [
              105.83426382719993,
              21.015407531805774
            ],
            [
              105.83429282795724,
              21.015366949515386
            ],
            [
              105.8343658935425,
              21.015293261098094
            ],
            [
              105.83443078714954,
              21.01523214587673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29.4_Ngách 164/14, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6224",
          "diaChiLapD": "Ngách 164/14, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0155002585665,
          "to_latitude": 21.01523214587673,
          "to_longitude": 105.83443078714954,
          "from_longitude": 105.8342054541854
        }
      },
      {
        "id": 6217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342079808817,
            21.01646116795212,
            105.83427464158667,
            21.016518580437655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342079808817,
              21.016518580437655
            ],
            [
              105.83422735522517,
              21.016501779392
            ],
            [
              105.83425254340322,
              21.016479937841314
            ],
            [
              105.83427464158667,
              21.01646116795212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.3_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6225",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016518580437655,
          "to_latitude": 21.01646116795212,
          "to_longitude": 105.83427464158667,
          "from_longitude": 105.8342079808817
        }
      },
      {
        "id": 6218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499890402732,
            21.01884537572298,
            105.83503025718846,
            21.018922099743442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503025718846,
              21.018922099743442
            ],
            [
              105.835000463257,
              21.01886488042113
            ],
            [
              105.83499890402732,
              21.01884537572298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.7_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6226",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018922099743442,
          "to_latitude": 21.01884537572298,
          "to_longitude": 105.83499890402732,
          "from_longitude": 105.83503025718846
        }
      },
      {
        "id": 6219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83259000609632,
            21.018308890879727,
            105.83280263397907,
            21.018437687785926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83279332098505,
              21.018437687785926
            ],
            [
              105.83279667939111,
              21.018435475605475
            ],
            [
              105.83280263397907,
              21.01843098723411
            ],
            [
              105.83278612735259,
              21.018383787849093
            ],
            [
              105.83277630354694,
              21.018308890879727
            ],
            [
              105.83270713837003,
              21.018317085710304
            ],
            [
              105.83259000609632,
              21.0183309626372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6.2_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6227",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018437687785926,
          "to_latitude": 21.0183309626372,
          "to_longitude": 105.83259000609632,
          "from_longitude": 105.83279332098505
        }
      },
      {
        "id": 6220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378373723879,
            21.012462429477022,
            105.83789995508594,
            21.012533135862256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378373723879,
              21.012533135862256
            ],
            [
              105.83784833286622,
              21.012515640628973
            ],
            [
              105.83786390190359,
              21.01249625483327
            ],
            [
              105.83788273205946,
              21.01247742652467
            ],
            [
              105.83789995508594,
              21.012462429477022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6228",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012533135862256,
          "to_latitude": 21.012462429477022,
          "to_longitude": 105.83789995508594,
          "from_longitude": 105.8378373723879
        }
      },
      {
        "id": 6221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568314568691,
            21.014338848220216,
            105.83574567844151,
            21.014417011398347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568314568691,
              21.014338848220216
            ],
            [
              105.83574567844151,
              21.014417011398347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32.5_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6229",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014338848220216,
          "to_latitude": 21.014417011398347,
          "to_longitude": 105.83574567844151,
          "from_longitude": 105.83568314568691
        }
      },
      {
        "id": 6222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617172959347,
            21.018531679436784,
            105.83619620013054,
            21.018631630950157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619620013054,
              21.018631630950157
            ],
            [
              105.83617192385208,
              21.01853287706016
            ],
            [
              105.83617172959347,
              21.018531679436784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6230",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018631630950157,
          "to_latitude": 21.018531679436784,
          "to_longitude": 105.83617172959347,
          "from_longitude": 105.83619620013054
        }
      },
      {
        "id": 6223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590692808485,
            21.017723696569604,
            105.8359262509229,
            21.017788995966512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359262509229,
              21.017788995966512
            ],
            [
              105.83591709758473,
              21.017758896742748
            ],
            [
              105.83590692808485,
              21.017725456066046
            ],
            [
              105.83591048101871,
              21.01772484699587
            ],
            [
              105.83592108491324,
              21.017723696569604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6231",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017788995966512,
          "to_latitude": 21.017723696569604,
          "to_longitude": 105.83592108491324,
          "from_longitude": 105.8359262509229
        }
      },
      {
        "id": 6224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335189738354,
            21.017096132115224,
            105.83383691219089,
            21.017339187119422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83383691219089,
              21.017339187119422
            ],
            [
              105.83378728105095,
              21.01726678493394
            ],
            [
              105.83377229124909,
              21.017233947608677
            ],
            [
              105.83373273017995,
              21.017168183524298
            ],
            [
              105.8337158720186,
              21.01715727299717
            ],
            [
              105.83368154759034,
              21.017117770683637
            ],
            [
              105.83360544126006,
              21.01716259312031
            ],
            [
              105.8335189738354,
              21.017096132115224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.9_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6232",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017339187119422,
          "to_latitude": 21.017096132115224,
          "to_longitude": 105.8335189738354,
          "from_longitude": 105.83383691219089
        }
      },
      {
        "id": 6225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673440273361,
            21.017967308821856,
            105.83694741709577,
            21.018539316552044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83694038343098,
              21.01853580531132
            ],
            [
              105.83694112014162,
              21.01853784748877
            ],
            [
              105.83694158526852,
              21.018539316552044
            ],
            [
              105.83694741709577,
              21.018537674701435
            ],
            [
              105.83694666139053,
              21.018533952621052
            ],
            [
              105.83694396295351,
              21.018520652377518
            ],
            [
              105.8369238173821,
              21.01843819953971
            ],
            [
              105.83690739307454,
              21.01838977812482
            ],
            [
              105.83688896562487,
              21.018329913683488
            ],
            [
              105.83686350988732,
              21.018224144040193
            ],
            [
              105.83684472021453,
              21.01814607599224
            ],
            [
              105.83682031370873,
              21.018087141467504
            ],
            [
              105.83679454738284,
              21.018051201580143
            ],
            [
              105.83673440273361,
              21.017967308821856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.6_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.6233",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01853580531132,
          "to_latitude": 21.017967308821856,
          "to_longitude": 105.83673440273361,
          "from_longitude": 105.83694038343098
        }
      },
      {
        "id": 6226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387488485447,
            21.01524675977649,
            105.83405305460333,
            21.015369806057777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388684845443,
              21.01524675977649
            ],
            [
              105.83388441383501,
              21.015249188739002
            ],
            [
              105.83387488485447,
              21.015258694916955
            ],
            [
              105.8339340565454,
              21.015307872696724
            ],
            [
              105.83394325140881,
              21.015313706715567
            ],
            [
              105.83400762423035,
              21.015356120013706
            ],
            [
              105.834036507664,
              21.015359993253806
            ],
            [
              105.83405305460333,
              21.015369806057777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT29.1_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6234",
          "diaChiLapD": "Ngõ 164, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01524675977649,
          "to_latitude": 21.015369806057777,
          "to_longitude": 105.83405305460333,
          "from_longitude": 105.83388684845443
        }
      },
      {
        "id": 6227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483943151747,
            21.01604996297127,
            105.8349665432062,
            21.016419899427685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83484455533687,
              21.01604996297127
            ],
            [
              105.8349665432062,
              21.01618189985227
            ],
            [
              105.83483943151747,
              21.016286639251717
            ],
            [
              105.83484943280416,
              21.01639758923345
            ],
            [
              105.83484861709466,
              21.01641902223659
            ],
            [
              105.83484858446147,
              21.016419899427685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.17_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6235",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01604996297127,
          "to_latitude": 21.016419899427685,
          "to_longitude": 105.83484858446147,
          "from_longitude": 105.83484455533687
        }
      },
      {
        "id": 6228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396505961129,
            21.015740266343197,
            105.83420159300348,
            21.01616287676254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83407719487062,
              21.015740266343197
            ],
            [
              105.83396505961129,
              21.015825854668268
            ],
            [
              105.8340363059259,
              21.015910170973022
            ],
            [
              105.83404057639855,
              21.015929450141456
            ],
            [
              105.83404218788615,
              21.015931915270258
            ],
            [
              105.83404351317381,
              21.0159339437382
            ],
            [
              105.83412881091371,
              21.016050830037564
            ],
            [
              105.83413437283683,
              21.016062417332652
            ],
            [
              105.83420140095717,
              21.016162592285262
            ],
            [
              105.83420159300348,
              21.01616287676254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15.3_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6236",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015740266343197,
          "to_latitude": 21.01616287676254,
          "to_longitude": 105.83420159300348,
          "from_longitude": 105.83407719487062
        }
      },
      {
        "id": 6229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908934454071,
            21.015262002380695,
            105.83930461352344,
            21.01571364257571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919506440081,
              21.015262002380695
            ],
            [
              105.8391868467604,
              21.015265297230577
            ],
            [
              105.8391803059011,
              21.015267583940517
            ],
            [
              105.83919970493358,
              21.015307250874987
            ],
            [
              105.83920194902515,
              21.015318580683875
            ],
            [
              105.83920745675728,
              21.015346380035176
            ],
            [
              105.83922171851594,
              21.015364627064113
            ],
            [
              105.83926041625662,
              21.015408827814337
            ],
            [
              105.83930461352344,
              21.015456488771076
            ],
            [
              105.83920899670461,
              21.015543566066427
            ],
            [
              105.83925507930638,
              21.015591899668646
            ],
            [
              105.8391832725704,
              21.01565978340666
            ],
            [
              105.83914302036263,
              21.01569365684437
            ],
            [
              105.83908934454071,
              21.01571364257571
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.30_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6237",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015262002380695,
          "to_latitude": 21.01571364257571,
          "to_longitude": 105.83908934454071,
          "from_longitude": 105.83919506440081
        }
      },
      {
        "id": 6230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83565607791235,
            21.014394932461318,
            105.83576881624117,
            21.014481460322546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83565607791235,
              21.014481460322546
            ],
            [
              105.83567310973652,
              21.014469702489865
            ],
            [
              105.83574567844151,
              21.014417011398347
            ],
            [
              105.83576881624117,
              21.014397942669817
            ],
            [
              105.8357665662178,
              21.014395167275058
            ],
            [
              105.83576637733346,
              21.014394932461318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32.5_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6238",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014481460322546,
          "to_latitude": 21.014394932461318,
          "to_longitude": 105.83576637733346,
          "from_longitude": 105.83565607791235
        }
      },
      {
        "id": 6231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909777493471,
            21.01414948403702,
            105.83945566909955,
            21.01484956261861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909777493471,
              21.01484956261861
            ],
            [
              105.83909961357864,
              21.01484825742416
            ],
            [
              105.83913060506437,
              21.01482624745669
            ],
            [
              105.83915480847315,
              21.014798956395524
            ],
            [
              105.83917769688713,
              21.014774755423566
            ],
            [
              105.83919511237065,
              21.01475441924246
            ],
            [
              105.83921294483628,
              21.0147329637149
            ],
            [
              105.83924842052562,
              21.014689585717996
            ],
            [
              105.83926674118774,
              21.014665127267172
            ],
            [
              105.83928187361361,
              21.014643004924302
            ],
            [
              105.83928888465873,
              21.014632091910116
            ],
            [
              105.83929512260933,
              21.01460575925851
            ],
            [
              105.83930159389804,
              21.014568006899303
            ],
            [
              105.83930260569463,
              21.014541504029005
            ],
            [
              105.83930885453346,
              21.014486344932813
            ],
            [
              105.83933658373947,
              21.014426808522423
            ],
            [
              105.83939258024321,
              21.01426844821401
            ],
            [
              105.83940011150719,
              21.014249771226034
            ],
            [
              105.83945566909955,
              21.01414948403702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.25_Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6239",
          "diaChiLapD": "Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484956261861,
          "to_latitude": 21.01414948403702,
          "to_longitude": 105.83945566909955,
          "from_longitude": 105.83909777493471
        }
      },
      {
        "id": 6232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838390254163,
            21.01302665093994,
            105.83850172999132,
            21.01311753443258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850172999132,
              21.01302665093994
            ],
            [
              105.838390254163,
              21.01311753443258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.18_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6240",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01302665093994,
          "to_latitude": 21.01311753443258,
          "to_longitude": 105.838390254163,
          "from_longitude": 105.83850172999132
        }
      },
      {
        "id": 6233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83928392812494,
            21.01307395387549,
            105.83957459842358,
            21.013766670915214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83957459842358,
              21.013766670915214
            ],
            [
              105.83956673702903,
              21.013611887191665
            ],
            [
              105.83953097432482,
              21.01349735421834
            ],
            [
              105.83948143603067,
              21.01337715373102
            ],
            [
              105.83942743385437,
              21.013277980625674
            ],
            [
              105.83933474756464,
              21.013146555432
            ],
            [
              105.83932455460389,
              21.013129796715067
            ],
            [
              105.83930037677172,
              21.013097649207182
            ],
            [
              105.83929511440441,
              21.01309065259869
            ],
            [
              105.83929014692787,
              21.013083237245887
            ],
            [
              105.83928392812494,
              21.01307395387549
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.19_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6241",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013766670915214,
          "to_latitude": 21.01307395387549,
          "to_longitude": 105.83928392812494,
          "from_longitude": 105.83957459842358
        }
      },
      {
        "id": 6234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515155337454,
            21.01675135535649,
            105.835211527845,
            21.016877315428456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835211527845,
              21.016877315428456
            ],
            [
              105.83518502394139,
              21.016834573100965
            ],
            [
              105.8351738934872,
              21.016810251525108
            ],
            [
              105.83516922101951,
              21.016793785199656
            ],
            [
              105.83515155337454,
              21.01675135535649
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.15_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6242",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016877315428456,
          "to_latitude": 21.01675135535649,
          "to_longitude": 105.83515155337454,
          "from_longitude": 105.835211527845
        }
      },
      {
        "id": 6235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774980070079,
            21.012682687588466,
            105.83783988574727,
            21.01287665449294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774980070079,
              21.012682687588466
            ],
            [
              105.83775061168495,
              21.012683220110716
            ],
            [
              105.83775350319509,
              21.012685112580474
            ],
            [
              105.83775327479056,
              21.012705361285096
            ],
            [
              105.83779772141526,
              21.012734863938302
            ],
            [
              105.83783988574727,
              21.012766146330996
            ],
            [
              105.83780388133864,
              21.012817848954615
            ],
            [
              105.83776293094887,
              21.01287665449294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.4_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6243",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012682687588466,
          "to_latitude": 21.01287665449294,
          "to_longitude": 105.83776293094887,
          "from_longitude": 105.83774980070079
        }
      },
      {
        "id": 6236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83211322893104,
            21.017535666605053,
            105.8323543807841,
            21.01778592812186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323543807841,
              21.017535666605053
            ],
            [
              105.83235147746025,
              21.017537717582417
            ],
            [
              105.83234646276233,
              21.017556947386662
            ],
            [
              105.83223085695334,
              21.017625999100783
            ],
            [
              105.8322246840549,
              21.017629454327746
            ],
            [
              105.83213254929476,
              21.017676930531767
            ],
            [
              105.83211322893104,
              21.017686886446004
            ],
            [
              105.83219181189376,
              21.01778592812186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT26.2_Ngách 268/7, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6244",
          "diaChiLapD": "Ngách 268/7, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017535666605053,
          "to_latitude": 21.01778592812186,
          "to_longitude": 105.83219181189376,
          "from_longitude": 105.8323543807841
        }
      },
      {
        "id": 6237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601100115135,
            21.018091080983126,
            105.83605794312982,
            21.018263753561726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605794312982,
              21.018263753561726
            ],
            [
              105.83604734171851,
              21.01822480444996
            ],
            [
              105.83604170150184,
              21.01819921402571
            ],
            [
              105.83603170346142,
              21.018158923369004
            ],
            [
              105.83601540852007,
              21.018105523553665
            ],
            [
              105.83601100115135,
              21.018091080983126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6245",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018263753561726,
          "to_latitude": 21.018091080983126,
          "to_longitude": 105.83601100115135,
          "from_longitude": 105.83605794312982
        }
      },
      {
        "id": 6238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862036909528,
            21.01422044915656,
            105.838908954223,
            21.014709376443818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885030344027,
              21.01422044915656
            ],
            [
              105.838908954223,
              21.014267438329902
            ],
            [
              105.8388526733626,
              21.014337199043027
            ],
            [
              105.83883519137017,
              21.014396730922492
            ],
            [
              105.83871337670764,
              21.014468480229276
            ],
            [
              105.83872175147886,
              21.014513078080242
            ],
            [
              105.83873565801204,
              21.014567772897554
            ],
            [
              105.83870307727332,
              21.01460128505662
            ],
            [
              105.83868060563472,
              21.014624337720907
            ],
            [
              105.83867528775323,
              21.014629095022507
            ],
            [
              105.83867393527329,
              21.014631158330506
            ],
            [
              105.83865716893436,
              21.014656810346306
            ],
            [
              105.83863439305665,
              21.014689280614505
            ],
            [
              105.83862193298393,
              21.014709376443818
            ],
            [
              105.83862162228657,
              21.014709205459518
            ],
            [
              105.83862036909528,
              21.01470838157385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT49.8_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6246",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01422044915656,
          "to_latitude": 21.01470838157385,
          "to_longitude": 105.83862036909528,
          "from_longitude": 105.83885030344027
        }
      },
      {
        "id": 6239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867971835389,
            21.012719707043814,
            105.83918080689047,
            21.01328415812029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867971835389,
              21.01328415812029
            ],
            [
              105.83870497382931,
              21.01323499488131
            ],
            [
              105.8387522698719,
              21.01313630217721
            ],
            [
              105.83885353638046,
              21.013040212023416
            ],
            [
              105.83894377546979,
              21.0129747647731
            ],
            [
              105.83902592687288,
              21.012919673081246
            ],
            [
              105.83915716590882,
              21.012798213321613
            ],
            [
              105.83914997217786,
              21.012720221980878
            ],
            [
              105.83915896610837,
              21.012719707043814
            ],
            [
              105.83918080689047,
              21.01272064796812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.18_Ngõ 77, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6247",
          "diaChiLapD": "Ngõ 77, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01328415812029,
          "to_latitude": 21.01272064796812,
          "to_longitude": 105.83918080689047,
          "from_longitude": 105.83867971835389
        }
      },
      {
        "id": 6240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357093784068,
            21.01692575967034,
            105.83368097762892,
            21.017011262519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368097762892,
              21.01692575967034
            ],
            [
              105.83368085125805,
              21.01692586867659
            ],
            [
              105.83364477975198,
              21.016956933014416
            ],
            [
              105.83357093784068,
              21.017011262519
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.10_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6248",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01692575967034,
          "to_latitude": 21.017011262519,
          "to_longitude": 105.83357093784068,
          "from_longitude": 105.83368097762892
        }
      },
      {
        "id": 6241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390621622175,
            21.018182860102712,
            105.833982927259,
            21.018311058938725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390621622175,
              21.018182860102712
            ],
            [
              105.83390756005366,
              21.018185106156853
            ],
            [
              105.833982927259,
              21.018311058938725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10.2_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6249",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018182860102712,
          "to_latitude": 21.018311058938725,
          "to_longitude": 105.833982927259,
          "from_longitude": 105.83390621622175
        }
      },
      {
        "id": 6242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356607495481,
            21.01832837570195,
            105.83580409364669,
            21.018350386085828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83580409364669,
              21.01832837570195
            ],
            [
              105.83578797897195,
              21.01833130545243
            ],
            [
              105.8357324356155,
              21.018340428128322
            ],
            [
              105.8356607495481,
              21.018350386085828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6250",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832837570195,
          "to_latitude": 21.018350386085828,
          "to_longitude": 105.8356607495481,
          "from_longitude": 105.83580409364669
        }
      },
      {
        "id": 6243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83377048123283,
            21.018182860102712,
            105.83390621622175,
            21.018257344163246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377048123283,
              21.018257344163246
            ],
            [
              105.83379381978025,
              21.018243608244152
            ],
            [
              105.83381461734508,
              21.018230481808168
            ],
            [
              105.833861947453,
              21.01820575436294
            ],
            [
              105.83390621622175,
              21.018182860102712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10.2_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6251",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018257344163246,
          "to_latitude": 21.018182860102712,
          "to_longitude": 105.83390621622175,
          "from_longitude": 105.83377048123283
        }
      },
      {
        "id": 6244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83431064754491,
            21.018008777590047,
            105.83438089140576,
            21.01813226964475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83431064754491,
              21.018008777590047
            ],
            [
              105.83435913760624,
              21.018096614077756
            ],
            [
              105.83438089140576,
              21.01813226964475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10.4_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6252",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018008777590047,
          "to_latitude": 21.01813226964475,
          "to_longitude": 105.83438089140576,
          "from_longitude": 105.83431064754491
        }
      },
      {
        "id": 6245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415850598742,
            21.016102715777347,
            105.83430214183598,
            21.0161933559918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415850598742,
              21.0161933559918
            ],
            [
              105.83420555308041,
              21.01616439192301
            ],
            [
              105.83422080149121,
              21.016155004906572
            ],
            [
              105.83426999934358,
              21.016129652204697
            ],
            [
              105.83430214183598,
              21.016102715777347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15.4_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6253",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0161933559918,
          "to_latitude": 21.016102715777347,
          "to_longitude": 105.83430214183598,
          "from_longitude": 105.83415850598742
        }
      },
      {
        "id": 6246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819268385159,
            21.012310608028518,
            105.83841615284878,
            21.01266171302253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819268385159,
              21.01266171302253
            ],
            [
              105.8382601575999,
              21.012575826311362
            ],
            [
              105.83828793611963,
              21.01253697545625
            ],
            [
              105.8383240724412,
              21.012490674269603
            ],
            [
              105.83833237988729,
              21.012474382527767
            ],
            [
              105.83835505566023,
              21.01243316687221
            ],
            [
              105.83841615284878,
              21.012339220437717
            ],
            [
              105.83840128018394,
              21.01232329581394
            ],
            [
              105.8384037025601,
              21.012310608028518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.13_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6254",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01266171302253,
          "to_latitude": 21.012310608028518,
          "to_longitude": 105.8384037025601,
          "from_longitude": 105.83819268385159
        }
      },
      {
        "id": 6247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356308941468,
            21.018354023744134,
            105.83368262296771,
            21.018640444765303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368262296771,
              21.018640444765303
            ],
            [
              105.83368195280026,
              21.018638944181053
            ],
            [
              105.8336236254897,
              21.018508099901094
            ],
            [
              105.83360654814726,
              21.018462456043356
            ],
            [
              105.83356308941468,
              21.018354023744134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6255",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018640444765303,
          "to_latitude": 21.018354023744134,
          "to_longitude": 105.83356308941468,
          "from_longitude": 105.83368262296771
        }
      },
      {
        "id": 6248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572260146094,
            21.017477119204315,
            105.83587878049354,
            21.017526116905476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572260146094,
              21.017526116905476
            ],
            [
              105.83580458496161,
              21.017508249386402
            ],
            [
              105.83587878049354,
              21.017494349120323
            ],
            [
              105.83587693583819,
              21.017481986722746
            ],
            [
              105.83587620937398,
              21.017477119204315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.5_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6256",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017526116905476,
          "to_latitude": 21.017477119204315,
          "to_longitude": 105.83587620937398,
          "from_longitude": 105.83572260146094
        }
      },
      {
        "id": 6249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543272521487,
            21.01730804729213,
            105.83553992377384,
            21.01740908205898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83553992377384,
              21.01740908205898
            ],
            [
              105.83553945520858,
              21.017406819977218
            ],
            [
              105.83553871176711,
              21.01740321976398
            ],
            [
              105.83551474406255,
              21.017406188959797
            ],
            [
              105.83550666612193,
              21.017346876865496
            ],
            [
              105.83544904228609,
              21.01735943016815
            ],
            [
              105.83543272521487,
              21.01730804729213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT23.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6257",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01740908205898,
          "to_latitude": 21.01730804729213,
          "to_longitude": 105.83543272521487,
          "from_longitude": 105.83553992377384
        }
      },
      {
        "id": 6250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356122976926,
            21.018165967147993,
            105.8356465870521,
            21.01833594716875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356465870521,
              21.01833594716875
            ],
            [
              105.83563462022047,
              21.018274462971597
            ],
            [
              105.83561708226347,
              21.01819528488169
            ],
            [
              105.8356122976926,
              21.018165967147993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.1_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6258",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01833594716875,
          "to_latitude": 21.018165967147993,
          "to_longitude": 105.8356122976926,
          "from_longitude": 105.8356465870521
        }
      },
      {
        "id": 6251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351143136193,
            21.018906233248497,
            105.83529729098615,
            21.019015140091685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351143136193,
              21.019015140091685
            ],
            [
              105.83527185628924,
              21.018921507919753
            ],
            [
              105.83529729098615,
              21.018906233248497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT17.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6259",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019015140091685,
          "to_latitude": 21.018906233248497,
          "to_longitude": 105.83529729098615,
          "from_longitude": 105.8351143136193
        }
      },
      {
        "id": 6252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83525185095735,
            21.016689954919883,
            105.83534181823472,
            21.01683400743162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83533334970613,
              21.01683400743162
            ],
            [
              105.83534181823472,
              21.016830130758436
            ],
            [
              105.83533806311655,
              21.01682431436793
            ],
            [
              105.835333685065,
              21.016817534586064
            ],
            [
              105.83528946884294,
              21.016749051545716
            ],
            [
              105.83525185095735,
              21.016689954919883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.14_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6260",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01683400743162,
          "to_latitude": 21.016689954919883,
          "to_longitude": 105.83525185095735,
          "from_longitude": 105.83533334970613
        }
      },
      {
        "id": 6253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398260478178,
            21.017220656334324,
            105.83411787670993,
            21.01735229564469
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411787670993,
              21.01735229564469
            ],
            [
              105.83406038165158,
              21.01731105450754
            ],
            [
              105.83401041176668,
              21.017250061776156
            ],
            [
              105.83398260478178,
              21.017220656334324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.1_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6261",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735229564469,
          "to_latitude": 21.017220656334324,
          "to_longitude": 105.83398260478178,
          "from_longitude": 105.83411787670993
        }
      },
      {
        "id": 6254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473687990981,
            21.01867104996461,
            105.83502656855019,
            21.018838887432103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83476158962397,
              21.018838887432103
            ],
            [
              105.83473687990981,
              21.018789300645125
            ],
            [
              105.83478510250417,
              21.018757275028744
            ],
            [
              105.83484139748752,
              21.018727609649236
            ],
            [
              105.83490252851372,
              21.01869730903333
            ],
            [
              105.83498728649407,
              21.01867104996461
            ],
            [
              105.83500005877433,
              21.01869910915605
            ],
            [
              105.83502568701698,
              21.018755914478167
            ],
            [
              105.83502656855019,
              21.018758747178058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.6_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6262",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018838887432103,
          "to_latitude": 21.018758747178058,
          "to_longitude": 105.83502656855019,
          "from_longitude": 105.83476158962397
        }
      },
      {
        "id": 6255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83290405361055,
            21.016764775500736,
            105.83304021116297,
            21.016876094621676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83303091070904,
              21.016876094621676
            ],
            [
              105.83303255623863,
              21.01687429817906
            ],
            [
              105.83304021116297,
              21.016865942898452
            ],
            [
              105.83299530142713,
              21.016830368008755
            ],
            [
              105.83296878561421,
              21.016810483284186
            ],
            [
              105.8329499360733,
              21.016796236883945
            ],
            [
              105.83290405361055,
              21.016764775500736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12.2_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6263",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016876094621676,
          "to_latitude": 21.016764775500736,
          "to_longitude": 105.83290405361055,
          "from_longitude": 105.83303091070904
        }
      },
      {
        "id": 6256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83168616759467,
            21.017731878950016,
            105.83185956406047,
            21.01789057947506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168616759467,
              21.017731878950016
            ],
            [
              105.83174613824453,
              21.017761496939233
            ],
            [
              105.83179807748684,
              21.017819888469
            ],
            [
              105.83185371368245,
              21.017883853768208
            ],
            [
              105.83185956406047,
              21.01789057947506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25.1_Ngõ 298, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6264",
          "diaChiLapD": "Ngõ 298, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017731878950016,
          "to_latitude": 21.01789057947506,
          "to_longitude": 105.83185956406047,
          "from_longitude": 105.83168616759467
        }
      },
      {
        "id": 6257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83307718141135,
            21.01611616467196,
            105.83342504324463,
            21.01644238127095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83308477009872,
              21.01611616467196
            ],
            [
              105.8330842562098,
              21.016116645897554
            ],
            [
              105.83307718141135,
              21.016123275889242
            ],
            [
              105.83314108893853,
              21.016174550615553
            ],
            [
              105.83321211582106,
              21.016231537667462
            ],
            [
              105.83321942211973,
              21.016238355553043
            ],
            [
              105.83324019687018,
              21.016258563715066
            ],
            [
              105.8332367719975,
              21.016262134678822
            ],
            [
              105.83331451013316,
              21.016331362735404
            ],
            [
              105.83335474175942,
              21.016369342264376
            ],
            [
              105.83337022772913,
              21.01638417232285
            ],
            [
              105.83339441537152,
              21.016414400379006
            ],
            [
              105.83342504324463,
              21.01644238127095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT27.4_Ngõ 216, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6265",
          "diaChiLapD": "Ngõ 216, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01611616467196,
          "to_latitude": 21.01644238127095,
          "to_longitude": 105.83342504324463,
          "from_longitude": 105.83308477009872
        }
      },
      {
        "id": 6258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83428811866456,
            21.017135821257344,
            105.83446107267864,
            21.017219709285584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446107267864,
              21.017135821257344
            ],
            [
              105.8343380214159,
              21.017193222263533
            ],
            [
              105.83428811866456,
              21.017219709285584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.3_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6266",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017135821257344,
          "to_latitude": 21.017219709285584,
          "to_longitude": 105.83428811866456,
          "from_longitude": 105.83446107267864
        }
      },
      {
        "id": 6259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341622462089,
            21.01688235540547,
            105.83355387645294,
            21.01701864778516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355387645294,
              21.01701864778516
            ],
            [
              105.8335119126645,
              21.01697851357636
            ],
            [
              105.83347565869514,
              21.016939881444724
            ],
            [
              105.83343364783235,
              21.016898884866134
            ],
            [
              105.83341622462089,
              21.01688235540547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12.1_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6267",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01701864778516,
          "to_latitude": 21.01688235540547,
          "to_longitude": 105.83341622462089,
          "from_longitude": 105.83355387645294
        }
      },
      {
        "id": 6260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83430134200489,
            21.016274856200454,
            105.83446046516507,
            21.01641685939698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430134200489,
              21.01641685939698
            ],
            [
              105.83433639632867,
              21.01638767139536
            ],
            [
              105.83437726749555,
              21.01635381409036
            ],
            [
              105.83441238034908,
              21.016323567207493
            ],
            [
              105.83445431109998,
              21.016286345595706
            ],
            [
              105.83445814024898,
              21.01627919585611
            ],
            [
              105.83446046516507,
              21.016274856200454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6268",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01641685939698,
          "to_latitude": 21.016274856200454,
          "to_longitude": 105.83446046516507,
          "from_longitude": 105.83430134200489
        }
      },
      {
        "id": 6261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695142231804,
            21.018506533817625,
            105.8371548814545,
            21.01854605678396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695142231804,
              21.01854605678396
            ],
            [
              105.83696222328079,
              21.01854315035787
            ],
            [
              105.83696926480482,
              21.018541256877757
            ],
            [
              105.83698384826604,
              21.01853733287412
            ],
            [
              105.83703426168489,
              21.018526347294173
            ],
            [
              105.8370797436801,
              21.01851769641281
            ],
            [
              105.837085416835,
              21.018516581014612
            ],
            [
              105.83710793551076,
              21.018512150082305
            ],
            [
              105.83712519241064,
              21.018506533817625
            ],
            [
              105.8371548814545,
              21.018508299837524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.6269",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01854605678396,
          "to_latitude": 21.018508299837524,
          "to_longitude": 105.8371548814545,
          "from_longitude": 105.83695142231804
        }
      },
      {
        "id": 6262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83417785176137,
            21.01826590752292,
            105.83431373806738,
            21.01836303009769
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83431373806738,
              21.018332786947827
            ],
            [
              105.8343119085429,
              21.01832761954204
            ],
            [
              105.83423555033112,
              21.01836303009769
            ],
            [
              105.83417785176137,
              21.01826590752292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9.6_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6270",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018332786947827,
          "to_latitude": 21.01826590752292,
          "to_longitude": 105.83417785176137,
          "from_longitude": 105.83431373806738
        }
      },
      {
        "id": 6263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357396329289,
            21.016701807535078,
            105.83367127059418,
            21.016849917972905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83367127059418,
              21.016701807535078
            ],
            [
              105.8336642732894,
              21.01670922658936
            ],
            [
              105.83357396329289,
              21.016804971964895
            ],
            [
              105.83360526364648,
              21.01683156217181
            ],
            [
              105.83358470694337,
              21.016849917972905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT27.1_Ngõ 216, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6271",
          "diaChiLapD": "Ngõ 216, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016701807535078,
          "to_latitude": 21.016849917972905,
          "to_longitude": 105.83358470694337,
          "from_longitude": 105.83367127059418
        }
      },
      {
        "id": 6264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84103210906531,
            21.014691766812334,
            105.84104827321526,
            21.01489783497886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84103210906531,
              21.01489783497886
            ],
            [
              105.84103553493632,
              21.014838208902187
            ],
            [
              105.84104135684503,
              21.014765692819097
            ],
            [
              105.84104503875723,
              21.0147198224739
            ],
            [
              105.84104827321526,
              21.014691766812334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT93.3_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.6272",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01489783497886,
          "to_latitude": 21.014691766812334,
          "to_longitude": 105.84104827321526,
          "from_longitude": 105.84103210906531
        }
      },
      {
        "id": 6265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8331670676836,
            21.018740409611592,
            105.83347273474224,
            21.019108305724416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331670676836,
              21.018740409611592
            ],
            [
              105.83317017575855,
              21.018751143663433
            ],
            [
              105.83317203529592,
              21.01875756370763
            ],
            [
              105.83322255996538,
              21.018932054044036
            ],
            [
              105.83330108677652,
              21.018916171858773
            ],
            [
              105.83338602451504,
              21.01890591151138
            ],
            [
              105.83343259242066,
              21.018901498721643
            ],
            [
              105.83345646462557,
              21.018983590537367
            ],
            [
              105.83347273474224,
              21.019108305724416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.3_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6273",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018740409611592,
          "to_latitude": 21.019108305724416,
          "to_longitude": 105.83347273474224,
          "from_longitude": 105.8331670676836
        }
      },
      {
        "id": 6266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83580409364669,
            21.018289463383304,
            105.8360448028391,
            21.01832837570195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83580409364669,
              21.01832837570195
            ],
            [
              105.83581678204071,
              21.01832565426804
            ],
            [
              105.83589665939128,
              21.01831212077596
            ],
            [
              105.83600878220707,
              21.018294761690303
            ],
            [
              105.8360448028391,
              21.018289463383304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6274",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832837570195,
          "to_latitude": 21.018289463383304,
          "to_longitude": 105.8360448028391,
          "from_longitude": 105.83580409364669
        }
      },
      {
        "id": 6267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83149490920997,
            21.017620054398098,
            105.83168616759467,
            21.017731878950016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83149490920997,
              21.017620054398098
            ],
            [
              105.83154441128933,
              21.017653737560806
            ],
            [
              105.83158910379416,
              21.01767860074463
            ],
            [
              105.83162276640358,
              21.017702909966808
            ],
            [
              105.83168616759467,
              21.017731878950016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25.1_Ngõ 298, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6275",
          "diaChiLapD": "Ngõ 298, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017620054398098,
          "to_latitude": 21.017731878950016,
          "to_longitude": 105.83168616759467,
          "from_longitude": 105.83149490920997
        }
      },
      {
        "id": 6268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344936940739,
            21.01709613211524,
            105.8335189738354,
            21.017130244010186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344936940739,
              21.01710964847371
            ],
            [
              105.83345339653236,
              21.017114743704944
            ],
            [
              105.83347089678402,
              21.017130244010186
            ],
            [
              105.83348047462373,
              21.017123259813317
            ],
            [
              105.83349556204452,
              21.017110427988975
            ],
            [
              105.8335189738354,
              21.01709613211524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.9_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6276",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.12,
          "chatLieu": "HDPE",
          "chieuDai": 9.484,
          "from_latitude": 21.01710964847371,
          "to_latitude": 21.01709613211524,
          "to_longitude": 105.8335189738354,
          "from_longitude": 105.83344936940739
        }
      },
      {
        "id": 6269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278934469566,
            21.01828306007786,
            105.8329955414102,
            21.018483267367532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328250213664,
              21.018483267367532
            ],
            [
              105.83282670223127,
              21.018482287268682
            ],
            [
              105.8328386015566,
              21.01847534734331
            ],
            [
              105.83280244279798,
              21.018409724456568
            ],
            [
              105.83278934469566,
              21.018308879423554
            ],
            [
              105.8329955414102,
              21.01828306007786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6.1_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6277",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018483267367532,
          "to_latitude": 21.01828306007786,
          "to_longitude": 105.8329955414102,
          "from_longitude": 105.8328250213664
        }
      },
      {
        "id": 6270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332574203525,
            21.016726060729667,
            105.83341622462089,
            21.016888239517044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332574203525,
              21.016726060729667
            ],
            [
              105.8333241970664,
              21.016798000650212
            ],
            [
              105.83334528246657,
              21.016818354499424
            ],
            [
              105.83341622462089,
              21.01688235540547
            ],
            [
              105.83341264385281,
              21.016885397846888
            ],
            [
              105.83340929956935,
              21.016888239517044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12.1_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6278",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016726060729667,
          "to_latitude": 21.016888239517044,
          "to_longitude": 105.83340929956935,
          "from_longitude": 105.8332574203525
        }
      },
      {
        "id": 6271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83491382196931,
            21.016338670712827,
            105.83510377121638,
            21.016489494579343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83491382196931,
              21.0164580159908
            ],
            [
              105.83491503659013,
              21.016456577515847
            ],
            [
              105.83492209690203,
              21.01644822145903
            ],
            [
              105.83492568248428,
              21.016443977674715
            ],
            [
              105.83495147053307,
              21.016464078822267
            ],
            [
              105.83498111478046,
              21.016489494579343
            ],
            [
              105.8349923345602,
              21.016479934880568
            ],
            [
              105.8350190298462,
              21.016457377694532
            ],
            [
              105.83510377121638,
              21.0163887999059
            ],
            [
              105.83505284975844,
              21.016338670712827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.16_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6279",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0164580159908,
          "to_latitude": 21.016338670712827,
          "to_longitude": 105.83505284975844,
          "from_longitude": 105.83491382196931
        }
      },
      {
        "id": 6272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773410254474,
            21.013165575311085,
            105.83789851953411,
            21.013333109540174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773410254474,
              21.013165575311085
            ],
            [
              105.83779271465389,
              21.013224205841777
            ],
            [
              105.8378030843243,
              21.01323456986026
            ],
            [
              105.83789851953411,
              21.013333109540174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.12_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6280",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013165575311085,
          "to_latitude": 21.013333109540174,
          "to_longitude": 105.83789851953411,
          "from_longitude": 105.83773410254474
        }
      },
      {
        "id": 6273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568387857581,
            21.017799903752692,
            105.83590777782261,
            21.017852791081157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568534542077,
              21.017852791081157
            ],
            [
              105.83568387857581,
              21.017845390945666
            ],
            [
              105.8356938251718,
              21.0178436693117
            ],
            [
              105.83576146325338,
              21.01783235652705
            ],
            [
              105.83582782347851,
              21.01782339295776
            ],
            [
              105.83590777782261,
              21.017799903752692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT22.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6281",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017852791081157,
          "to_latitude": 21.017799903752692,
          "to_longitude": 105.83590777782261,
          "from_longitude": 105.83568534542077
        }
      },
      {
        "id": 6274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263289651013,
            21.018468216013993,
            105.83275286271808,
            21.01859037732564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83267262276304,
              21.018468216013993
            ],
            [
              105.83267380020065,
              21.01847161234914
            ],
            [
              105.8326379256474,
              21.018488199676767
            ],
            [
              105.83263289651013,
              21.01851667980264
            ],
            [
              105.8326402725859,
              21.01854515992307
            ],
            [
              105.83264798393091,
              21.01856550286267
            ],
            [
              105.83265911506024,
              21.01859037732564
            ],
            [
              105.83275286271808,
              21.018558717429222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6.3_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6282",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 26.728,
          "from_latitude": 21.018468216013993,
          "to_latitude": 21.018558717429222,
          "to_longitude": 105.83275286271808,
          "from_longitude": 105.83267262276304
        }
      },
      {
        "id": 6275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518310788774,
            21.016355314547326,
            105.83536338103796,
            21.016517081980105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518310788774,
              21.016517081980105
            ],
            [
              105.83520889149605,
              21.016499236846165
            ],
            [
              105.83523127543658,
              21.016482745171473
            ],
            [
              105.83525675949625,
              21.016463969373643
            ],
            [
              105.8352750422648,
              21.01644636879847
            ],
            [
              105.83528725405402,
              21.01643461208468
            ],
            [
              105.83531555245604,
              21.016391258370128
            ],
            [
              105.83536338103796,
              21.016355314547326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT24.1_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6283",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016517081980105,
          "to_latitude": 21.016355314547326,
          "to_longitude": 105.83536338103796,
          "from_longitude": 105.83518310788774
        }
      },
      {
        "id": 6276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355650904893,
            21.01841795370065,
            105.83364349383244,
            21.01862989422906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364349383244,
              21.018625971775837
            ],
            [
              105.83364226993743,
              21.018626439321388
            ],
            [
              105.83363322366834,
              21.01862989422906
            ],
            [
              105.83362233862871,
              21.01858636518612
            ],
            [
              105.83359751610683,
              21.018517273641045
            ],
            [
              105.83355996379908,
              21.018425981792372
            ],
            [
              105.83355650904893,
              21.01841795370065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.3_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6284",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018625971775837,
          "to_latitude": 21.01841795370065,
          "to_longitude": 105.83355650904893,
          "from_longitude": 105.83364349383244
        }
      },
      {
        "id": 6277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249038427073,
            21.0187551326126,
            105.83272611625155,
            21.018844277659674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272611625155,
              21.0187551326126
            ],
            [
              105.83270595105942,
              21.018759866674028
            ],
            [
              105.83267362185606,
              21.01876805459391
            ],
            [
              105.83263113598676,
              21.018780690022584
            ],
            [
              105.8325892820607,
              21.01879695421389
            ],
            [
              105.83251895177982,
              21.01882456858642
            ],
            [
              105.83250842013628,
              21.018828703607106
            ],
            [
              105.83249038427073,
              21.018844277659674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.5_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6285",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0187551326126,
          "to_latitude": 21.018844277659674,
          "to_longitude": 105.83249038427073,
          "from_longitude": 105.83272611625155
        }
      },
      {
        "id": 6278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364699903524,
            21.01867093607789,
            105.83378084574026,
            21.01915784421969
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83378084574026,
              21.01915784421969
            ],
            [
              105.83372513978478,
              21.018953172032592
            ],
            [
              105.8337160580749,
              21.01892083865882
            ],
            [
              105.83367426695149,
              21.018772050288987
            ],
            [
              105.83364699903524,
              21.01867093607789
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6286",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01915784421969,
          "to_latitude": 21.01867093607789,
          "to_longitude": 105.83364699903524,
          "from_longitude": 105.83378084574026
        }
      },
      {
        "id": 6279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584040938935,
            21.018051088050306,
            105.83601100115135,
            21.018093827957628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83599881602443,
              21.018093827957628
            ],
            [
              105.83599994874542,
              21.018093573101144
            ],
            [
              105.83601100115135,
              21.018091080983126
            ],
            [
              105.8359992338336,
              21.018051088050306
            ],
            [
              105.83599068041354,
              21.01805285975897
            ],
            [
              105.83599061699692,
              21.018052872715774
            ],
            [
              105.83584040938935,
              21.018083981730108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6287",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018093827957628,
          "to_latitude": 21.018083981730108,
          "to_longitude": 105.83584040938935,
          "from_longitude": 105.83599881602443
        }
      },
      {
        "id": 6280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332816217948,
            21.017784796946824,
            105.8333916905649,
            21.017994863527548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8333916905649,
              21.017994863527548
            ],
            [
              105.83335636802917,
              21.017924292410687
            ],
            [
              105.83332448904879,
              21.017863888273315
            ],
            [
              105.8332816217948,
              21.017784796946824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.6_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6288",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017994863527548,
          "to_latitude": 21.017784796946824,
          "to_longitude": 105.8332816217948,
          "from_longitude": 105.8333916905649
        }
      },
      {
        "id": 6281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83539118496041,
            21.016347020096376,
            105.83562296672432,
            21.01670635318101
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562296672432,
              21.01670635318101
            ],
            [
              105.83559373476731,
              21.016677096812444
            ],
            [
              105.83558415233004,
              21.016667506475287
            ],
            [
              105.8356180383518,
              21.016637228343015
            ],
            [
              105.8356121928601,
              21.016629846099825
            ],
            [
              105.83555017872285,
              21.01655152230539
            ],
            [
              105.83547420371252,
              21.016450871600565
            ],
            [
              105.8354694658398,
              21.016444944426173
            ],
            [
              105.83539118496041,
              21.016347020096376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.12_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6289",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01670635318101,
          "to_latitude": 21.016347020096376,
          "to_longitude": 105.83539118496041,
          "from_longitude": 105.83562296672432
        }
      },
      {
        "id": 6282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555919073345,
            21.01791725517022,
            105.8356122976926,
            21.018165967147993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356122976926,
              21.018165967147993
            ],
            [
              105.83559831119737,
              21.01809471456244
            ],
            [
              105.83558759811164,
              21.018048301721635
            ],
            [
              105.8355764660651,
              21.01800006913446
            ],
            [
              105.83555919073345,
              21.01791725517022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.1_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6290",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018165967147993,
          "to_latitude": 21.01791725517022,
          "to_longitude": 105.83555919073345,
          "from_longitude": 105.8356122976926
        }
      },
      {
        "id": 6283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575086695511,
            21.01714751465616,
            105.83594126869015,
            21.017206351016757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575086695511,
              21.017156274829507
            ],
            [
              105.83578586232005,
              21.017200078179734
            ],
            [
              105.83579322730827,
              21.017206351016757
            ],
            [
              105.83593341688555,
              21.017188455785504
            ],
            [
              105.83594126869015,
              21.017186801334823
            ],
            [
              105.83593216385299,
              21.017157112837506
            ],
            [
              105.8359304317614,
              21.01714751465616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.8_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6291",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017156274829507,
          "to_latitude": 21.01714751465616,
          "to_longitude": 105.8359304317614,
          "from_longitude": 105.83575086695511
        }
      },
      {
        "id": 6284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83343231277797,
            21.018112909248742,
            105.83352033207527,
            21.018342529271823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83352033207527,
              21.018342529271823
            ],
            [
              105.83347075249239,
              21.018218684506504
            ],
            [
              105.83346292805317,
              21.018199140935224
            ],
            [
              105.83345933275565,
              21.018189015339107
            ],
            [
              105.83343246815359,
              21.018113345648185
            ],
            [
              105.83343231277797,
              21.018112909248742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6292",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018342529271823,
          "to_latitude": 21.018112909248742,
          "to_longitude": 105.83343231277797,
          "from_longitude": 105.83352033207527
        }
      },
      {
        "id": 6285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83317559723851,
            21.017256490132084,
            105.83334503205919,
            21.01747150170528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83317559723851,
              21.01747150170528
            ],
            [
              105.83321542949977,
              21.017416246041964
            ],
            [
              105.83326333303823,
              21.017343430987392
            ],
            [
              105.83327024399205,
              21.01733292695637
            ],
            [
              105.83330851768369,
              21.017288552827882
            ],
            [
              105.83333308330207,
              21.01726697729569
            ],
            [
              105.83334503205919,
              21.017256490132084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.8_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6293",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01747150170528,
          "to_latitude": 21.017256490132084,
          "to_longitude": 105.83334503205919,
          "from_longitude": 105.83317559723851
        }
      },
      {
        "id": 6286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543268860586,
            21.014499157758088,
            105.83569816200045,
            21.014711060583377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543268860586,
              21.014499157758088
            ],
            [
              105.83551422451134,
              21.014601032190196
            ],
            [
              105.8356056727611,
              21.014706450019208
            ],
            [
              105.8356156842459,
              21.014711060583377
            ],
            [
              105.83562962660713,
              21.014701588634747
            ],
            [
              105.8356512151107,
              21.014682231273003
            ],
            [
              105.83569816200045,
              21.014633346684047
            ],
            [
              105.83565912159281,
              21.014574208336647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32.5_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6294",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014499157758088,
          "to_latitude": 21.014574208336647,
          "to_longitude": 105.83565912159281,
          "from_longitude": 105.83543268860586
        }
      },
      {
        "id": 6287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.836653508368,
            21.013655695169255,
            105.83698876158351,
            21.014104717120926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.836653508368,
              21.014104717120926
            ],
            [
              105.83665606245546,
              21.01410216108137
            ],
            [
              105.83671006273084,
              21.014048119353916
            ],
            [
              105.83676684406014,
              21.014007213073
            ],
            [
              105.83683020323821,
              21.013959106432285
            ],
            [
              105.8368733361377,
              21.013934995864734
            ],
            [
              105.83692358859845,
              21.013902847697608
            ],
            [
              105.83695332048848,
              21.013880734123518
            ],
            [
              105.83698679891341,
              21.013855834634395
            ],
            [
              105.83698876158351,
              21.01385434459453
            ],
            [
              105.83693264812979,
              21.01378942228229
            ],
            [
              105.83680194809362,
              21.013655695169255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.14_Ngõ 247, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6295",
          "diaChiLapD": "Ngõ 247, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014104717120926,
          "to_latitude": 21.013655695169255,
          "to_longitude": 105.83680194809362,
          "from_longitude": 105.836653508368
        }
      },
      {
        "id": 6288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83282776541301,
            21.01716252409602,
            105.83322475108136,
            21.01738288223537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83322475108136,
              21.01738288223537
            ],
            [
              105.833210603962,
              21.01737669398629
            ],
            [
              105.83318605211737,
              21.017365950249996
            ],
            [
              105.83309292488026,
              21.017174397931846
            ],
            [
              105.83308518740075,
              21.01716252409602
            ],
            [
              105.83300465681437,
              21.017235568416464
            ],
            [
              105.83293329375401,
              21.017309522522943
            ],
            [
              105.83289041526909,
              21.017272513279632
            ],
            [
              105.83282776541301,
              21.017218438645354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.7_Ngách 60, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6296",
          "diaChiLapD": "Ngách 60, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01738288223537,
          "to_latitude": 21.017218438645354,
          "to_longitude": 105.83282776541301,
          "from_longitude": 105.83322475108136
        }
      },
      {
        "id": 6289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177313468936,
            21.018816445301628,
            105.83190522629442,
            21.01898437284546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190522629442,
              21.018978833229976
            ],
            [
              105.83188949917924,
              21.01898437284546
            ],
            [
              105.83183039955412,
              21.018884692041446
            ],
            [
              105.83177313468936,
              21.018816445301628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4.3_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6297",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018978833229976,
          "to_latitude": 21.018816445301628,
          "to_longitude": 105.83177313468936,
          "from_longitude": 105.83190522629442
        }
      },
      {
        "id": 6290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449063875426,
            21.01619641692037,
            105.834608800532,
            21.01638402472955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.834608800532,
              21.01619641692037
            ],
            [
              105.83457462851953,
              21.01621819149691
            ],
            [
              105.8345287524091,
              21.01624529740844
            ],
            [
              105.83449063875426,
              21.016278211804345
            ],
            [
              105.83458726962316,
              21.01638402472955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6298",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01619641692037,
          "to_latitude": 21.01638402472955,
          "to_longitude": 105.83458726962316,
          "from_longitude": 105.834608800532
        }
      },
      {
        "id": 6291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663553767929,
            21.013640472037075,
            105.83688683907869,
            21.014074883472908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663553767929,
              21.014074883472908
            ],
            [
              105.83663695530186,
              21.01407397508399
            ],
            [
              105.83664224908524,
              21.01407058002541
            ],
            [
              105.83666762955174,
              21.014069380380484
            ],
            [
              105.83667345041027,
              21.014066147115877
            ],
            [
              105.83669143352772,
              21.014053368378793
            ],
            [
              105.83671593671653,
              21.014033544038107
            ],
            [
              105.83680415168861,
              21.013970019966752
            ],
            [
              105.83688683907869,
              21.01391041051824
            ],
            [
              105.83682716607909,
              21.01384023536534
            ],
            [
              105.83673250873306,
              21.01372536276284
            ],
            [
              105.83664146339655,
              21.013640472037075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.13_Ngõ 247, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6299",
          "diaChiLapD": "Ngõ 247, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014074883472908,
          "to_latitude": 21.013640472037075,
          "to_longitude": 105.83664146339655,
          "from_longitude": 105.83663553767929
        }
      },
      {
        "id": 6292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83621938665792,
            21.01876007826058,
            105.83625947303882,
            21.019031390319817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625947303882,
              21.019031390319817
            ],
            [
              105.83624243532681,
              21.018922277012148
            ],
            [
              105.83623698834907,
              21.018885981541008
            ],
            [
              105.83623010423553,
              21.01883709677285
            ],
            [
              105.8362267234146,
              21.01880974841597
            ],
            [
              105.83622557975063,
              21.01880200347317
            ],
            [
              105.83622432915146,
              21.018793540089757
            ],
            [
              105.83621938665792,
              21.01876007826058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6300",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019031390319817,
          "to_latitude": 21.01876007826058,
          "to_longitude": 105.83621938665792,
          "from_longitude": 105.83625947303882
        }
      },
      {
        "id": 6293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194974939863,
            21.01710734057187,
            105.83219828972982,
            21.017342474479722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219828972982,
              21.017342474479722
            ],
            [
              105.83217389654025,
              21.01729651127672
            ],
            [
              105.83214324387434,
              21.01725902564689
            ],
            [
              105.83212131306104,
              21.017236425849017
            ],
            [
              105.83210122694972,
              21.01722451843299
            ],
            [
              105.83202390780458,
              21.017162139640746
            ],
            [
              105.8320195540142,
              21.017159188421363
            ],
            [
              105.8319899696629,
              21.017139131582958
            ],
            [
              105.83194974939863,
              21.01710734057187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT26.1_Ngõ 268, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6301",
          "diaChiLapD": "Ngõ 268, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017342474479722,
          "to_latitude": 21.01710734057187,
          "to_longitude": 105.83194974939863,
          "from_longitude": 105.83219828972982
        }
      },
      {
        "id": 6294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602373615201,
            21.016067056611558,
            105.83618044628392,
            21.016297891980468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602373615201,
              21.016069843834206
            ],
            [
              105.83602900067515,
              21.016067742354064
            ],
            [
              105.83603087840592,
              21.016067056611558
            ],
            [
              105.83606173259957,
              21.01611848270466
            ],
            [
              105.8360974280937,
              21.01617384834632
            ],
            [
              105.83612502111774,
              21.01621832930403
            ],
            [
              105.83612586073559,
              21.016219681820335
            ],
            [
              105.8361402913826,
              21.01624532478753
            ],
            [
              105.83618044628392,
              21.016297891980468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT33.20_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6302",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016069843834206,
          "to_latitude": 21.016297891980468,
          "to_longitude": 105.83618044628392,
          "from_longitude": 105.83602373615201
        }
      },
      {
        "id": 6295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83227293186938,
            21.018361874158416,
            105.83264971682289,
            21.018509185627664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83264971682289,
              21.018476383995143
            ],
            [
              105.83264971682289,
              21.018476383995143
            ],
            [
              105.83264664282704,
              21.01846566594785
            ],
            [
              105.832641948968,
              21.01845752876655
            ],
            [
              105.83256726694498,
              21.018488561984576
            ],
            [
              105.83250076313382,
              21.018509185627664
            ],
            [
              105.83248922733114,
              21.018488904173
            ],
            [
              105.83241697682762,
              21.018361874158416
            ],
            [
              105.83227293186938,
              21.018432778490247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6.4_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6303",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": 53.423,
          "from_latitude": 21.018476383995143,
          "to_latitude": 21.018432778490247,
          "to_longitude": 105.83227293186938,
          "from_longitude": 105.83264971682289
        }
      },
      {
        "id": 6296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83796052393458,
            21.01245197600496,
            105.83820302473872,
            21.012938421826224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83796552435639,
              21.01245197600496
            ],
            [
              105.83796517765433,
              21.012452591001743
            ],
            [
              105.83796052393458,
              21.012460862165234
            ],
            [
              105.83801388263622,
              21.01250861116518
            ],
            [
              105.83804765886119,
              21.012545372230722
            ],
            [
              105.83808860645539,
              21.0125857966691
            ],
            [
              105.83813308071693,
              21.01263194913449
            ],
            [
              105.83816395703955,
              21.012657042156665
            ],
            [
              105.83820302473872,
              21.012688792227657
            ],
            [
              105.83804733145885,
              21.012938421826224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.7_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6304",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01245197600496,
          "to_latitude": 21.012938421826224,
          "to_longitude": 105.83804733145885,
          "from_longitude": 105.83796552435639
        }
      },
      {
        "id": 6297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83985955079707,
            21.016801471825865,
            105.84003009048818,
            21.01711949751738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003009048818,
              21.01711949751738
            ],
            [
              105.84002867729397,
              21.01711930849609
            ],
            [
              105.83986226354101,
              21.017096955238962
            ],
            [
              105.83986102946015,
              21.016976894021646
            ],
            [
              105.83986036062868,
              21.016915344446012
            ],
            [
              105.83985955079707,
              21.016801471825865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT68.3_Ngách 1/30, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6305",
          "diaChiLapD": "Ngách 1/30, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01711949751738,
          "to_latitude": 21.016801471825865,
          "to_longitude": 105.83985955079707,
          "from_longitude": 105.84003009048818
        }
      },
      {
        "id": 6298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850172999132,
            21.01302665093994,
            105.83867971835389,
            21.01328415812029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867971835389,
              21.01328415812029
            ],
            [
              105.83862717445109,
              21.01324923924307
            ],
            [
              105.83861974051169,
              21.01319431017936
            ],
            [
              105.83863111106515,
              21.013134031665544
            ],
            [
              105.83852124493875,
              21.013038097068193
            ],
            [
              105.83850172999132,
              21.01302665093994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.18_Ngõ 77, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6306",
          "diaChiLapD": "Ngõ 77, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01328415812029,
          "to_latitude": 21.01302665093994,
          "to_longitude": 105.83850172999132,
          "from_longitude": 105.83867971835389
        }
      },
      {
        "id": 6299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388369136571,
            21.017684305276852,
            105.83439317645566,
            21.01781618424177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388369136571,
              21.017684979519007
            ],
            [
              105.83388638997711,
              21.0176860790633
            ],
            [
              105.83389167147791,
              21.017688226332
            ],
            [
              105.83391160340406,
              21.017684305276852
            ],
            [
              105.83393493733105,
              21.01770158880137
            ],
            [
              105.83397806776308,
              21.017731595640694
            ],
            [
              105.83402322974516,
              21.017687027716228
            ],
            [
              105.83408610728517,
              21.01773332672016
            ],
            [
              105.83417300609572,
              21.01781618424177
            ],
            [
              105.83422416705959,
              21.017795880875877
            ],
            [
              105.8342859447581,
              21.01779056863063
            ],
            [
              105.8343057075997,
              21.017794986926198
            ],
            [
              105.83439317645566,
              21.017765703517153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT11.3_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6307",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017684979519007,
          "to_latitude": 21.017765703517153,
          "to_longitude": 105.83439317645566,
          "from_longitude": 105.83388369136571
        }
      },
      {
        "id": 6300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374211919944,
            21.01661479310623,
            105.83409279137018,
            21.01692599383848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83374211919944,
              21.01692599383848
            ],
            [
              105.83383202423266,
              21.016839082994068
            ],
            [
              105.83389664920466,
              21.01678278346578
            ],
            [
              105.83397488438987,
              21.016717091400412
            ],
            [
              105.83403273632007,
              21.016666519856336
            ],
            [
              105.83409279137018,
              21.01661479310623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6308",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01692599383848,
          "to_latitude": 21.01661479310623,
          "to_longitude": 105.83409279137018,
          "from_longitude": 105.83374211919944
        }
      },
      {
        "id": 6301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83474838746334,
            21.01641617315262,
            105.8348202104512,
            21.016665460912005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480035060713,
              21.016665460912005
            ],
            [
              105.83474838746334,
              21.01651389108592
            ],
            [
              105.8348202104512,
              21.01648806230549
            ],
            [
              105.83479773668763,
              21.01642015526828
            ],
            [
              105.83479730621606,
              21.016418858543823
            ],
            [
              105.83479640724553,
              21.01641617315262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.18_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6309",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016665460912005,
          "to_latitude": 21.01641617315262,
          "to_longitude": 105.83479640724553,
          "from_longitude": 105.83480035060713
        }
      },
      {
        "id": 6302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331729763631,
            21.018657470544163,
            105.8336359944375,
            21.018723651440876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83331729763631,
              21.018723651440876
            ],
            [
              105.83337977889332,
              21.018713482138736
            ],
            [
              105.83341990695926,
              21.018704489039315
            ],
            [
              105.83345545411301,
              21.018696521857382
            ],
            [
              105.8336359944375,
              21.018657470544163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.1_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6310",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018723651440876,
          "to_latitude": 21.018657470544163,
          "to_longitude": 105.8336359944375,
          "from_longitude": 105.83331729763631
        }
      },
      {
        "id": 6303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83544101760017,
            21.018634694360642,
            105.83561157037785,
            21.018825889248866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83544524940247,
              21.018825889248866
            ],
            [
              105.83544263945404,
              21.018821889042325
            ],
            [
              105.83544101760017,
              21.018818781787715
            ],
            [
              105.83546333995703,
              21.018803785216072
            ],
            [
              105.83547246441341,
              21.01878277205469
            ],
            [
              105.83556091232246,
              21.018760470571443
            ],
            [
              105.83559054803214,
              21.018734873951782
            ],
            [
              105.83556797198042,
              21.018657197380776
            ],
            [
              105.83561157037785,
              21.018634694360642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT17.2_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6311",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018825889248866,
          "to_latitude": 21.018634694360642,
          "to_longitude": 105.83561157037785,
          "from_longitude": 105.83544524940247
        }
      },
      {
        "id": 6304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83799036547789,
            21.014389043167363,
            105.83843690727254,
            21.014762656960656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83799036547789,
              21.014761062859563
            ],
            [
              105.83799115328361,
              21.01476071575408
            ],
            [
              105.83799487447992,
              21.01475907433174
            ],
            [
              105.83801220539475,
              21.014762656960656
            ],
            [
              105.83802246288394,
              21.014755845803926
            ],
            [
              105.83806719896374,
              21.01473201155364
            ],
            [
              105.83809406320192,
              21.01471486880184
            ],
            [
              105.83814094548553,
              21.014688825513005
            ],
            [
              105.83815618365617,
              21.014683014971215
            ],
            [
              105.83820136127278,
              21.014660567670088
            ],
            [
              105.83825566863553,
              21.014632799643618
            ],
            [
              105.8382908725789,
              21.014601337606
            ],
            [
              105.83830150477893,
              21.01459100923872
            ],
            [
              105.83835735552043,
              21.0145371674335
            ],
            [
              105.83833518622757,
              21.014497803072217
            ],
            [
              105.83832749952757,
              21.014460638052128
            ],
            [
              105.8383288738116,
              21.014433387333177
            ],
            [
              105.83840447015875,
              21.014389043167363
            ],
            [
              105.83843690727254,
              21.014431540785065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46.11_Ngách 165/87, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6312",
          "diaChiLapD": "Ngách 165/87, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014761062859563,
          "to_latitude": 21.014431540785065,
          "to_longitude": 105.83843690727254,
          "from_longitude": 105.83799036547789
        }
      },
      {
        "id": 6305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83784003153637,
            21.01296698736906,
            105.83837455571629,
            21.013385758376376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83784003153637,
              21.013385758376376
            ],
            [
              105.8378434532456,
              21.013383719207656
            ],
            [
              105.83785097790764,
              21.013370549272896
            ],
            [
              105.83787647578126,
              21.013350783098527
            ],
            [
              105.83789851953411,
              21.013333109540174
            ],
            [
              105.83793828673687,
              21.013298225368903
            ],
            [
              105.83795051933723,
              21.01327908360903
            ],
            [
              105.83797782325583,
              21.01324632828979
            ],
            [
              105.83800287694362,
              21.013220753843385
            ],
            [
              105.83801982371024,
              21.013200028088825
            ],
            [
              105.83802524750419,
              21.013193399708744
            ],
            [
              105.83804912952881,
              21.013163171292856
            ],
            [
              105.83806769328994,
              21.013137627900168
            ],
            [
              105.83809989844025,
              21.013089947027222
            ],
            [
              105.83810735487381,
              21.013072835747238
            ],
            [
              105.83811459645581,
              21.013059795453696
            ],
            [
              105.83813647759692,
              21.01303300100537
            ],
            [
              105.83814656060454,
              21.013022529031776
            ],
            [
              105.83817078136896,
              21.012996983955176
            ],
            [
              105.83818394587789,
              21.012986081316242
            ],
            [
              105.83820542637885,
              21.01296781256024
            ],
            [
              105.83821763813796,
              21.01296698736906
            ],
            [
              105.83823354568895,
              21.0129716888631
            ],
            [
              105.83824815967814,
              21.012980733108012
            ],
            [
              105.8382693632396,
              21.012998288484408
            ],
            [
              105.83831680709133,
              21.013040819689706
            ],
            [
              105.83837455571629,
              21.013091215058964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.12_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6313",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013385758376376,
          "to_latitude": 21.013091215058964,
          "to_longitude": 105.83837455571629,
          "from_longitude": 105.83784003153637
        }
      },
      {
        "id": 6306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83637243600307,
            21.013428313662285,
            105.83673211936231,
            21.013689730647275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83637243600307,
              21.013689730647275
            ],
            [
              105.83637410379286,
              21.013688317031544
            ],
            [
              105.8363821565271,
              21.01367270134001
            ],
            [
              105.83644795899558,
              21.013619766836758
            ],
            [
              105.83649389603687,
              21.013585335885498
            ],
            [
              105.83655724360396,
              21.013534989407148
            ],
            [
              105.83662913074532,
              21.0134757872512
            ],
            [
              105.83668517594056,
              21.013428313662285
            ],
            [
              105.83673211936231,
              21.013474650352222
            ],
            [
              105.83672308143318,
              21.013493845866613
            ],
            [
              105.83672636905933,
              21.013512948250362
            ],
            [
              105.83669158853024,
              21.01355135549033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31.8_Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6314",
          "diaChiLapD": "Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013689730647275,
          "to_latitude": 21.01355135549033,
          "to_longitude": 105.83669158853024,
          "from_longitude": 105.83637243600307
        }
      },
      {
        "id": 6307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83511463931823,
            21.014338848220216,
            105.83568314568691,
            21.014814005131235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83516896738521,
              21.014814005131235
            ],
            [
              105.83511463931823,
              21.01473917139877
            ],
            [
              105.83519719890518,
              21.014683705560834
            ],
            [
              105.8352192588991,
              21.014659699750585
            ],
            [
              105.835259302967,
              21.014611133681356
            ],
            [
              105.83531445258893,
              21.014569376011405
            ],
            [
              105.83535991345803,
              21.014542961100585
            ],
            [
              105.8353722930965,
              21.014535635681515
            ],
            [
              105.83543268860586,
              21.014499157758088
            ],
            [
              105.83552565792463,
              21.014440581428886
            ],
            [
              105.83556066110216,
              21.014419259622002
            ],
            [
              105.83561466536159,
              21.014382072379977
            ],
            [
              105.83568314568691,
              21.014338848220216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT32.5_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6315",
          "diaChiLapD": "Ngõ 96, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014814005131235,
          "to_latitude": 21.014338848220216,
          "to_longitude": 105.83568314568691,
          "from_longitude": 105.83516896738521
        }
      },
      {
        "id": 6308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83304630389016,
            21.017506590411667,
            105.8332816217948,
            21.017784796946824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332816217948,
              21.017784796946824
            ],
            [
              105.83326554244945,
              21.017755128909855
            ],
            [
              105.83321114720229,
              21.017659579470607
            ],
            [
              105.83317808945186,
              21.017601509587266
            ],
            [
              105.83313110290301,
              21.017506590411667
            ],
            [
              105.83304630389016,
              21.017544286283936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.6_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6316",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017784796946824,
          "to_latitude": 21.017544286283936,
          "to_longitude": 105.83304630389016,
          "from_longitude": 105.8332816217948
        }
      },
      {
        "id": 6309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83424089626195,
            21.018045332027633,
            105.83475841325613,
            21.018368389602493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83424089626195,
              21.01836274736505
            ],
            [
              105.83424137515213,
              21.01836340978966
            ],
            [
              105.83424498998026,
              21.018368389602493
            ],
            [
              105.83432131554547,
              21.018335064747724
            ],
            [
              105.83432297933047,
              21.018334474911093
            ],
            [
              105.8343287670584,
              21.018334066267172
            ],
            [
              105.83442383691093,
              21.018334573635535
            ],
            [
              105.83452345030058,
              21.0183340849855
            ],
            [
              105.83457556855998,
              21.018329517305105
            ],
            [
              105.83460122289944,
              21.018318452491858
            ],
            [
              105.83459861390769,
              21.018200531294376
            ],
            [
              105.83463302749165,
              21.018175242880606
            ],
            [
              105.83460819032067,
              21.018100822539477
            ],
            [
              105.83473576535596,
              21.018045332027633
            ],
            [
              105.83475841325613,
              21.018126679983677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9.7_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6317",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01836274736505,
          "to_latitude": 21.018126679983677,
          "to_longitude": 105.83475841325613,
          "from_longitude": 105.83424089626195
        }
      },
      {
        "id": 6310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720524772667,
            21.012508363495506,
            105.83782050850755,
            21.01265989973326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720524772667,
              21.012553739201635
            ],
            [
              105.8372781812651,
              21.012614075197412
            ],
            [
              105.83731659727216,
              21.012643361978842
            ],
            [
              105.83733849623675,
              21.01265989973326
            ],
            [
              105.83735820789018,
              21.012648879130506
            ],
            [
              105.83739766630093,
              21.012618825241855
            ],
            [
              105.83743617341685,
              21.012591193958208
            ],
            [
              105.83745961382542,
              21.01256507837016
            ],
            [
              105.83748747355654,
              21.012540565024292
            ],
            [
              105.8375011547078,
              21.012529504539813
            ],
            [
              105.83751242171887,
              21.01253796287458
            ],
            [
              105.83755037488575,
              21.012554811771412
            ],
            [
              105.83757768014614,
              21.01256709850205
            ],
            [
              105.83759143564781,
              21.01257262897312
            ],
            [
              105.83761389204484,
              21.012573399972545
            ],
            [
              105.83763461605592,
              21.012569351763165
            ],
            [
              105.83765653330411,
              21.012552638084124
            ],
            [
              105.83768185317516,
              21.01252909282266
            ],
            [
              105.83771787757351,
              21.012508363495506
            ],
            [
              105.83772254030974,
              21.01251066182541
            ],
            [
              105.8377431676554,
              21.01252082812081
            ],
            [
              105.83775431678603,
              21.012531256951917
            ],
            [
              105.83778976635978,
              21.01254539490001
            ],
            [
              105.83781851936862,
              21.012555106596153
            ],
            [
              105.83782050850755,
              21.012555776929492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.5_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6318",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012553739201635,
          "to_latitude": 21.012555776929492,
          "to_longitude": 105.83782050850755,
          "from_longitude": 105.83720524772667
        }
      },
      {
        "id": 6311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390992609401,
            21.014498560650804,
            105.83973488019983,
            21.014853141991686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390992609401,
              21.014853141991686
            ],
            [
              105.8391105309393,
              21.014848158418197
            ],
            [
              105.83912570920887,
              21.014841445765043
            ],
            [
              105.83915530667747,
              21.01481992047413
            ],
            [
              105.83919406678653,
              21.0147719632011
            ],
            [
              105.83925852825482,
              21.014693499204213
            ],
            [
              105.83929973012613,
              21.014642871675445
            ],
            [
              105.8394185128537,
              21.014695613805156
            ],
            [
              105.83942743612492,
              21.01469843751459
            ],
            [
              105.83943505432529,
              21.01469529283699
            ],
            [
              105.83948405739741,
              21.014628854679817
            ],
            [
              105.83950411156158,
              21.01462986127301
            ],
            [
              105.83955329067432,
              21.01462994646997
            ],
            [
              105.83958079176097,
              21.01463244004478
            ],
            [
              105.83959638446223,
              21.01463051871439
            ],
            [
              105.83963331367211,
              21.014637182002566
            ],
            [
              105.83968241779345,
              21.014646037835348
            ],
            [
              105.83971739429117,
              21.014547710071977
            ],
            [
              105.83973488019983,
              21.014498560650804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.26_Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6319",
          "diaChiLapD": "Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014853141991686,
          "to_latitude": 21.014498560650804,
          "to_longitude": 105.83973488019983,
          "from_longitude": 105.8390992609401
        }
      },
      {
        "id": 6312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83796824626336,
            21.01234002131078,
            105.83839183146054,
            21.01265665275447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379702516687,
              21.012453952462135
            ],
            [
              105.83797010244311,
              21.012454273841698
            ],
            [
              105.83796824626336,
              21.012458291492756
            ],
            [
              105.83799499138337,
              21.012480336640575
            ],
            [
              105.83801163345326,
              21.01249548394188
            ],
            [
              105.83808517457415,
              21.012571375532865
            ],
            [
              105.83812140706998,
              21.012608564354178
            ],
            [
              105.83813635319395,
              21.01262196954755
            ],
            [
              105.83816927454716,
              21.01264775068751
            ],
            [
              105.83818103177468,
              21.01265665275447
            ],
            [
              105.8382091113803,
              21.01262296145185
            ],
            [
              105.8382420069455,
              21.01258886435507
            ],
            [
              105.83825412948185,
              21.012572076919238
            ],
            [
              105.8382667474962,
              21.012552896206905
            ],
            [
              105.83832962749125,
              21.012459859597584
            ],
            [
              105.83836742337353,
              21.01239278239002
            ],
            [
              105.83839183146054,
              21.01234002131078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.8_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6320",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012453952462135,
          "to_latitude": 21.01234002131078,
          "to_longitude": 105.83839183146054,
          "from_longitude": 105.8379702516687
        }
      },
      {
        "id": 6313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353988163775,
            21.01797919271615,
            105.83378238884511,
            21.018185809132937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353988163775,
              21.01797919271615
            ],
            [
              105.83355878861606,
              21.018004082359447
            ],
            [
              105.8335889655619,
              21.018043807334756
            ],
            [
              105.83362557520074,
              21.018108471325487
            ],
            [
              105.83372159394783,
              21.01806823811354
            ],
            [
              105.83378238884511,
              21.018185809132937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT11.1_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6321",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01797919271615,
          "to_latitude": 21.018185809132937,
          "to_longitude": 105.83378238884511,
          "from_longitude": 105.83353988163775
        }
      },
      {
        "id": 6314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331704882468,
            21.01714551836491,
            105.83364077722729,
            21.017606446445615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83331704882468,
              21.01723446839929
            ],
            [
              105.83331994058551,
              21.017236745714992
            ],
            [
              105.83334503205919,
              21.017256490132084
            ],
            [
              105.83347146552425,
              21.01714551836491
            ],
            [
              105.83351537798359,
              21.017194950763685
            ],
            [
              105.83354012103358,
              21.0172316187715
            ],
            [
              105.83354436427462,
              21.017264694839294
            ],
            [
              105.83354732658633,
              21.017305455495332
            ],
            [
              105.83355167375882,
              21.017331960997716
            ],
            [
              105.83356039606838,
              21.01734915539732
            ],
            [
              105.83356223099122,
              21.01735305104621
            ],
            [
              105.83356711958001,
              21.01736343043311
            ],
            [
              105.83358871545353,
              21.017402398107944
            ],
            [
              105.83364077722729,
              21.01749173112675
            ],
            [
              105.83362060436369,
              21.017525681933847
            ],
            [
              105.8336349617932,
              21.017542011425682
            ],
            [
              105.83356512648942,
              21.017606446445615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.8_Ngách 77, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6322",
          "diaChiLapD": "Ngách 77, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01723446839929,
          "to_latitude": 21.017606446445615,
          "to_longitude": 105.83356512648942,
          "from_longitude": 105.83331704882468
        }
      },
      {
        "id": 6315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733937188651,
            21.01654690877625,
            105.83766166279655,
            21.01685129365921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83761923863499,
              21.01654690877625
            ],
            [
              105.83760806075142,
              21.016556705985206
            ],
            [
              105.83759586332779,
              21.016576170903154
            ],
            [
              105.83758448582627,
              21.016589669602062
            ],
            [
              105.83765040441122,
              21.016691387673134
            ],
            [
              105.83766166279655,
              21.01670874910564
            ],
            [
              105.83754549309269,
              21.01679252335505
            ],
            [
              105.83750639288236,
              21.01679750293697
            ],
            [
              105.837440316228,
              21.01685129365921
            ],
            [
              105.83741961738681,
              21.016826359988627
            ],
            [
              105.83733937188651,
              21.016729670141192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41.4_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6323",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654690877625,
          "to_latitude": 21.016729670141192,
          "to_longitude": 105.83733937188651,
          "from_longitude": 105.83761923863499
        }
      },
      {
        "id": 6316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337218197177,
            21.018586499369338,
            105.83444662574583,
            21.018916944619814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337218197177,
              21.018916944619814
            ],
            [
              105.83372524344993,
              21.018916274808305
            ],
            [
              105.8337342583966,
              21.01891531565794
            ],
            [
              105.83375017996859,
              21.018913622656733
            ],
            [
              105.83376335484805,
              21.018912221309808
            ],
            [
              105.8339731069657,
              21.01885636557723
            ],
            [
              105.83400841411432,
              21.018844852494308
            ],
            [
              105.83400835914426,
              21.018834967876554
            ],
            [
              105.8341015177375,
              21.01880361993533
            ],
            [
              105.83414610805147,
              21.01878610815382
            ],
            [
              105.83415061292189,
              21.01879527908081
            ],
            [
              105.83421920956135,
              21.01876682531979
            ],
            [
              105.83422353128245,
              21.018765032902913
            ],
            [
              105.83425287890206,
              21.01874328746469
            ],
            [
              105.83429667862208,
              21.018710762514473
            ],
            [
              105.83430956700515,
              21.018700230933195
            ],
            [
              105.83431856943548,
              21.01869287517911
            ],
            [
              105.8343339511048,
              21.01868059627354
            ],
            [
              105.8343567637411,
              21.01866863769264
            ],
            [
              105.8343663888741,
              21.018643530524272
            ],
            [
              105.83444662574583,
              21.018586499369338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.2_Ngách 1, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6324",
          "diaChiLapD": "Ngách 1, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018916944619814,
          "to_latitude": 21.018586499369338,
          "to_longitude": 105.83444662574583,
          "from_longitude": 105.8337218197177
        }
      },
      {
        "id": 6317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83983769214528,
            21.015717389178178,
            105.84008114199932,
            21.016297573108123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002973025468,
              21.016297573108123
            ],
            [
              105.8400263298201,
              21.01629654667532
            ],
            [
              105.84000909570415,
              21.01628435154397
            ],
            [
              105.84000607302286,
              21.01628241460262
            ],
            [
              105.83993575169096,
              21.016268900937483
            ],
            [
              105.83985158486308,
              21.016237293573983
            ],
            [
              105.83983982121867,
              21.01622731412295
            ],
            [
              105.83983769214528,
              21.01621059602876
            ],
            [
              105.83986886787835,
              21.016142094289727
            ],
            [
              105.83991631529749,
              21.016053072261716
            ],
            [
              105.83994149552677,
              21.016004554126134
            ],
            [
              105.83995535274823,
              21.015979393254785
            ],
            [
              105.83996133717883,
              21.01596860992767
            ],
            [
              105.83996952334023,
              21.01595333483734
            ],
            [
              105.83998212968889,
              21.015932063098216
            ],
            [
              105.83999908261528,
              21.01589165136274
            ],
            [
              105.83997446517785,
              21.01588211473895
            ],
            [
              105.84000509769211,
              21.01582948710375
            ],
            [
              105.84002403360628,
              21.015802210188244
            ],
            [
              105.84005079516493,
              21.01576038705184
            ],
            [
              105.84008114199932,
              21.015717389178178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT72.4_Ngách 1/34, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6325",
          "diaChiLapD": "Ngách 1/34, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016297573108123,
          "to_latitude": 21.015717389178178,
          "to_longitude": 105.84008114199932,
          "from_longitude": 105.84002973025468
        }
      },
      {
        "id": 6318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871502731847,
            21.013054984572104,
            105.83931574617394,
            21.01346746418262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931574617394,
              21.013054984572104
            ],
            [
              105.83930489506403,
              21.013061227893804
            ],
            [
              105.83928392812494,
              21.01307395387549
            ],
            [
              105.8392781051989,
              21.013077488010886
            ],
            [
              105.83918681946164,
              21.01314685968681
            ],
            [
              105.83909505223369,
              21.013255361356105
            ],
            [
              105.83905076589394,
              21.01330102276455
            ],
            [
              105.83897640942588,
              21.013392183320136
            ],
            [
              105.83897225568133,
              21.013398173209982
            ],
            [
              105.83892624970915,
              21.01346746418262
            ],
            [
              105.83892003377376,
              21.013465916899094
            ],
            [
              105.83885984717593,
              21.013450932904433
            ],
            [
              105.83882532486633,
              21.013441834221574
            ],
            [
              105.83882231151753,
              21.013441037079247
            ],
            [
              105.83881164154543,
              21.01343822465617
            ],
            [
              105.83879389118997,
              21.013421707293592
            ],
            [
              105.83871502731847,
              21.01334828549816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.19_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6326",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013054984572104,
          "to_latitude": 21.01334828549816,
          "to_longitude": 105.83871502731847,
          "from_longitude": 105.83931574617394
        }
      },
      {
        "id": 6319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83235460134763,
            21.017558996709482,
            105.83260419783493,
            21.018187075007933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237121836027,
              21.017558996709482
            ],
            [
              105.83235460134763,
              21.017577489348593
            ],
            [
              105.83245477804684,
              21.017740443103584
            ],
            [
              105.83249479301291,
              21.017813183585464
            ],
            [
              105.83260419783493,
              21.01797852717245
            ],
            [
              105.83248774650862,
              21.018057498802673
            ],
            [
              105.8324276402731,
              21.018094314826865
            ],
            [
              105.83237808695021,
              21.018117495598005
            ],
            [
              105.8324083225223,
              21.018162981270773
            ],
            [
              105.83236397648515,
              21.018187075007933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT26.3_Ngõ 268, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6327",
          "diaChiLapD": "Ngõ 268, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017558996709482,
          "to_latitude": 21.018187075007933,
          "to_longitude": 105.83236397648515,
          "from_longitude": 105.83237121836027
        }
      },
      {
        "id": 6320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83689202770012,
            21.013153024675834,
            105.83742827098013,
            21.013759437931594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742827098013,
              21.013759437931594
            ],
            [
              105.83742731071708,
              21.01375835698239
            ],
            [
              105.83740313647498,
              21.01373110826929
            ],
            [
              105.83734526396601,
              21.013668330879252
            ],
            [
              105.83731950617852,
              21.013636729179495
            ],
            [
              105.8372916644524,
              21.013607291920774
            ],
            [
              105.83726702040516,
              21.013580826787482
            ],
            [
              105.8372526161396,
              21.013565065953884
            ],
            [
              105.83723726767192,
              21.013551100859054
            ],
            [
              105.83722351255884,
              21.013537725856814
            ],
            [
              105.83721646879212,
              21.013529695626882
            ],
            [
              105.83719183143972,
              21.013504425414588
            ],
            [
              105.83718250420077,
              21.01348624875789
            ],
            [
              105.83717280539841,
              21.013467680123007
            ],
            [
              105.8371656113907,
              21.01345547953988
            ],
            [
              105.83715369551135,
              21.013436420523014
            ],
            [
              105.83714156599967,
              21.013415449527077
            ],
            [
              105.83712866764718,
              21.01340270436443
            ],
            [
              105.83710377800827,
              21.013377725314285
            ],
            [
              105.83708322815176,
              21.01335991186559
            ],
            [
              105.83700947607221,
              21.01331515502789
            ],
            [
              105.83700644641735,
              21.013312985032186
            ],
            [
              105.83698827137063,
              21.013299964139268
            ],
            [
              105.83696245401602,
              21.013280591428543
            ],
            [
              105.83689202770012,
              21.01322296168088
            ],
            [
              105.83696003680598,
              21.013153024675834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT45.3_Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6328",
          "diaChiLapD": "Ngách 241/36, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013759437931594,
          "to_latitude": 21.013153024675834,
          "to_longitude": 105.83696003680598,
          "from_longitude": 105.83742827098013
        }
      },
      {
        "id": 6321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83894273596174,
            21.0130571553555,
            105.8394428219409,
            21.01373763739452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394428219409,
              21.013649154144243
            ],
            [
              105.83939836270818,
              21.013626442440955
            ],
            [
              105.83937236033479,
              21.01365159996147
            ],
            [
              105.8393070265178,
              21.01369939481372
            ],
            [
              105.83926853252204,
              21.013725574066797
            ],
            [
              105.83925079769286,
              21.01373763739452
            ],
            [
              105.83918333184343,
              21.013671025915894
            ],
            [
              105.83906001414383,
              21.013549248155428
            ],
            [
              105.83900854738096,
              21.013509679712335
            ],
            [
              105.83894273596174,
              21.01347036533862
            ],
            [
              105.83896429388456,
              21.013430857696285
            ],
            [
              105.83904625111872,
              21.013340836564364
            ],
            [
              105.83911763999073,
              21.0132723750872
            ],
            [
              105.83924145005932,
              21.013157054983413
            ],
            [
              105.83929706572981,
              21.01311894820785
            ],
            [
              105.83930037677172,
              21.013097649207182
            ],
            [
              105.83930581684625,
              21.013062650440755
            ],
            [
              105.83931695975637,
              21.0130571553555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.20_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6329",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 130,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013649154144243,
          "to_latitude": 21.0130571553555,
          "to_longitude": 105.83931695975637,
          "from_longitude": 105.8394428219409
        }
      },
      {
        "id": 6322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444212113875,
            21.017367999229702,
            105.83511549715412,
            21.01762428221693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444212113875,
              21.01752722265253
            ],
            [
              105.83448705511658,
              21.01762428221693
            ],
            [
              105.83467557021766,
              21.017539576986344
            ],
            [
              105.83485686829287,
              21.017474869131394
            ],
            [
              105.83486853458234,
              21.01746290557475
            ],
            [
              105.83499073476968,
              21.017417816601245
            ],
            [
              105.83511549715412,
              21.017367999229702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6330",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01752722265253,
          "to_latitude": 21.017367999229702,
          "to_longitude": 105.83511549715412,
          "from_longitude": 105.83444212113875
        }
      },
      {
        "id": 6323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83236795803074,
            21.01741126947688,
            105.83304630389016,
            21.01780546068388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83236795803074,
              21.01741126947688
            ],
            [
              105.83248900464295,
              21.017576050389053
            ],
            [
              105.83257042935826,
              21.017686350223634
            ],
            [
              105.83258703748695,
              21.017730374305845
            ],
            [
              105.83263419744122,
              21.01780546068388
            ],
            [
              105.83304630389016,
              21.017544286283936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.6_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6331",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01741126947688,
          "to_latitude": 21.017544286283936,
          "to_longitude": 105.83304630389016,
          "from_longitude": 105.83236795803074
        }
      },
      {
        "id": 6324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83930461352344,
            21.015456488771076,
            105.83934999413634,
            21.01552392878522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83930461352344,
              21.015456488771076
            ],
            [
              105.83934863860851,
              21.01549537845544
            ],
            [
              105.839349372215,
              21.01549703496144
            ],
            [
              105.83934982733813,
              21.015498776841145
            ],
            [
              105.83934999413634,
              21.015500564401304
            ],
            [
              105.83934986755766,
              21.015502354312098
            ],
            [
              105.83934945217891,
              21.015504105002627
            ],
            [
              105.83934875643006,
              21.015505775786263
            ],
            [
              105.83934779740288,
              21.015507326836797
            ],
            [
              105.83934659798611,
              21.0155087228156
            ],
            [
              105.83934518492615,
              21.015509930171433
            ],
            [
              105.83932678544863,
              21.01552392878522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT44.30_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6332",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015456488771076,
          "to_latitude": 21.01552392878522,
          "to_longitude": 105.83932678544863,
          "from_longitude": 105.83930461352344
        }
      },
      {
        "id": 6325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484943280416,
            21.01639758923345,
            105.83504699233997,
            21.01653025620178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83484943280416,
              21.01639758923345
            ],
            [
              105.83485276936774,
              21.016399416340985
            ],
            [
              105.83486686772827,
              21.016409465978683
            ],
            [
              105.83490267124104,
              21.016433840815942
            ],
            [
              105.83492209690203,
              21.01644822145903
            ],
            [
              105.83500637366085,
              21.016510611733043
            ],
            [
              105.83501649646423,
              21.01651653778021
            ],
            [
              105.83504699233997,
              21.01653025620178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.17_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6333",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01639758923345,
          "to_latitude": 21.01653025620178,
          "to_longitude": 105.83504699233997,
          "from_longitude": 105.83484943280416
        }
      },
      {
        "id": 6326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376143310305,
            21.01653422322818,
            105.83763129884036,
            21.01654690877625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376143310305,
              21.01654315926961
            ],
            [
              105.83761832378181,
              21.016546211475603
            ],
            [
              105.83761923863499,
              21.01654690877625
            ],
            [
              105.83763129884036,
              21.01653422322818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT41.4_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6334",
          "diaChiLapD": "Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654315926961,
          "to_latitude": 21.01653422322818,
          "to_longitude": 105.83763129884036,
          "from_longitude": 105.8376143310305
        }
      },
      {
        "id": 6327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340725601697,
            21.01803850604831,
            105.83343231277797,
            21.018112909248742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83343231277797,
              21.018112909248742
            ],
            [
              105.83341063043372,
              21.01804852614754
            ],
            [
              105.83340725601697,
              21.01803850604831
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6335",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018112909248742,
          "to_latitude": 21.01803850604831,
          "to_longitude": 105.83340725601697,
          "from_longitude": 105.83343231277797
        }
      },
      {
        "id": 6328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414570083957,
            21.017219709285584,
            105.83428811866456,
            21.017354058995295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428811866456,
              21.017219709285584
            ],
            [
              105.83428035488465,
              21.017224049418385
            ],
            [
              105.83428033286012,
              21.01722562384665
            ],
            [
              105.83428007019313,
              21.01724411775255
            ],
            [
              105.83423720159445,
              21.01728123011897
            ],
            [
              105.83418449039577,
              21.01733080736607
            ],
            [
              105.83415232072673,
              21.01735325307664
            ],
            [
              105.83415116606194,
              21.017354058995295
            ],
            [
              105.83414675093418,
              21.01735046503673
            ],
            [
              105.83414570083957,
              21.017349317671275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.3_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6336",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017219709285584,
          "to_latitude": 21.017349317671275,
          "to_longitude": 105.83414570083957,
          "from_longitude": 105.83428811866456
        }
      },
      {
        "id": 6329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83984825188847,
            21.017096955238962,
            105.83986226354101,
            21.017179007324522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83986226354101,
              21.017096955238962
            ],
            [
              105.83986184547165,
              21.017108532112346
            ],
            [
              105.83984825188847,
              21.017114484626557
            ],
            [
              105.83984861319172,
              21.017179007324522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT68.3_Ngách 1/30, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6337",
          "diaChiLapD": "Ngách 1/30, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017096955238962,
          "to_latitude": 21.017179007324522,
          "to_longitude": 105.83984861319172,
          "from_longitude": 105.83986226354101
        }
      },
      {
        "id": 6330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585922188755,
            21.017402474420194,
            105.83588716260115,
            21.017477119204315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588716260115,
              21.01747565274216
            ],
            [
              105.83588116044878,
              21.017476243136514
            ],
            [
              105.83587620937398,
              21.017477119204315
            ],
            [
              105.83586678539652,
              21.01744453834248
            ],
            [
              105.83586535941612,
              21.017435495946014
            ],
            [
              105.83586235390383,
              21.01741644003824
            ],
            [
              105.83585922188755,
              21.017402474420194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.5_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6338",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01747565274216,
          "to_latitude": 21.017402474420194,
          "to_longitude": 105.83585922188755,
          "from_longitude": 105.83588716260115
        }
      },
      {
        "id": 6331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337612375201,
            21.016949813011415,
            105.83398260478178,
            21.017220656334324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398260478178,
              21.017220656334324
            ],
            [
              105.8339269077528,
              21.017165932030718
            ],
            [
              105.83390955517604,
              21.017142744609203
            ],
            [
              105.83390062449239,
              21.017130811629062
            ],
            [
              105.8338809756366,
              21.017101892758635
            ],
            [
              105.83385307309142,
              21.01706082622975
            ],
            [
              105.83382580213308,
              21.017026258010443
            ],
            [
              105.83378899172882,
              21.01698029720185
            ],
            [
              105.8337612375201,
              21.016949813011415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.1_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6339",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017220656334324,
          "to_latitude": 21.016949813011415,
          "to_longitude": 105.8337612375201,
          "from_longitude": 105.83398260478178
        }
      },
      {
        "id": 6332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620218764833,
            21.01865963488052,
            105.83621938665792,
            21.01876007826058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83621938665792,
              21.01876007826058
            ],
            [
              105.83620218764833,
              21.01865963488052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6340",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01876007826058,
          "to_latitude": 21.01865963488052,
          "to_longitude": 105.83620218764833,
          "from_longitude": 105.83621938665792
        }
      },
      {
        "id": 6333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8397515544121,
            21.016801471825865,
            105.83985955079707,
            21.016802505338124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397515544121,
              21.016802505338124
            ],
            [
              105.83985955079707,
              21.016801471825865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT68.3_Ngách 1/30, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6341",
          "diaChiLapD": "Ngách 1/30, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016802505338124,
          "to_latitude": 21.016801471825865,
          "to_longitude": 105.83985955079707,
          "from_longitude": 105.8397515544121
        }
      },
      {
        "id": 6334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368436962458,
            21.01854605678396,
            105.83695142231804,
            21.01857181020035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368436962458,
              21.01857181020035
            ],
            [
              105.83693770798668,
              21.018549206058783
            ],
            [
              105.83694468914254,
              21.018547526940733
            ],
            [
              105.83695142231804,
              21.01854605678396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.5_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6342",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01857181020035,
          "to_latitude": 21.01854605678396,
          "to_longitude": 105.83695142231804,
          "from_longitude": 105.8368436962458
        }
      },
      {
        "id": 6335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344558505271,
            21.016487912429188,
            105.83456586547993,
            21.016555787080947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344558505271,
              21.016555787080947
            ],
            [
              105.8344780855298,
              21.016537751719536
            ],
            [
              105.83452384547518,
              21.01650531013926
            ],
            [
              105.83453908024964,
              21.016501076960097
            ],
            [
              105.83456586547993,
              21.0164936339607
            ],
            [
              105.83456339127306,
              21.016487912429188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.19_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6343",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016555787080947,
          "to_latitude": 21.016487912429188,
          "to_longitude": 105.83456339127306,
          "from_longitude": 105.8344558505271
        }
      },
      {
        "id": 6336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590031347737,
            21.017604596532983,
            105.83590908927094,
            21.017725456066046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590692808485,
              21.017725456066046
            ],
            [
              105.83590908927094,
              21.0177027898754
            ],
            [
              105.8359070242239,
              21.01767625697475
            ],
            [
              105.8359058541409,
              21.017648923935873
            ],
            [
              105.83590031347737,
              21.017604596532983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6344",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017725456066046,
          "to_latitude": 21.017604596532983,
          "to_longitude": 105.83590031347737,
          "from_longitude": 105.83590692808485
        }
      },
      {
        "id": 6337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350757027373,
            21.016517081980105,
            105.83518310788774,
            21.016579199198176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350757027373,
              21.016579199198176
            ],
            [
              105.835120786881,
              21.01655998940253
            ],
            [
              105.83515732427324,
              21.016534927110303
            ],
            [
              105.83518310788774,
              21.016517081980105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT24.1_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6345",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016579199198176,
          "to_latitude": 21.016517081980105,
          "to_longitude": 105.83518310788774,
          "from_longitude": 105.8350757027373
        }
      },
      {
        "id": 6338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502662136615,
            21.018918593642486,
            105.83510397443732,
            21.01901651226706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502662136615,
              21.01892320416977
            ],
            [
              105.83503025718846,
              21.018922099743442
            ],
            [
              105.83504180520714,
              21.018918593642486
            ],
            [
              105.83507700785596,
              21.01897314989312
            ],
            [
              105.83510397443732,
              21.01901651226706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.7_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6346",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01892320416977,
          "to_latitude": 21.01901651226706,
          "to_longitude": 105.83510397443732,
          "from_longitude": 105.83502662136615
        }
      },
      {
        "id": 6339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83409279137018,
            21.01653286147083,
            105.8341887935643,
            21.01661479310623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8341887935643,
              21.01653286147083
            ],
            [
              105.83416552465978,
              21.016553531151644
            ],
            [
              105.83411875606217,
              21.016592812283694
            ],
            [
              105.83410084753075,
              21.01660785387506
            ],
            [
              105.83409279137018,
              21.01661479310623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6347",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01653286147083,
          "to_latitude": 21.01661479310623,
          "to_longitude": 105.83409279137018,
          "from_longitude": 105.8341887935643
        }
      },
      {
        "id": 6340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505619545615,
            21.016574450512433,
            105.83515155337454,
            21.01675135535649
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83505619545615,
              21.016578154179495
            ],
            [
              105.83506117709105,
              21.0165762058588
            ],
            [
              105.83506640701944,
              21.016574450512433
            ],
            [
              105.8351041048356,
              21.01663945568457
            ],
            [
              105.83510845412516,
              21.016642454716845
            ],
            [
              105.83513958167731,
              21.016707246455123
            ],
            [
              105.83513738303222,
              21.01671871284316
            ],
            [
              105.83514897880592,
              21.016740916786237
            ],
            [
              105.83515155337454,
              21.01675135535649
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.15_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6348",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016578154179495,
          "to_latitude": 21.01675135535649,
          "to_longitude": 105.83515155337454,
          "from_longitude": 105.83505619545615
        }
      },
      {
        "id": 6341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83446046516507,
            21.016195636780736,
            105.83457910782882,
            21.016284496680985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446904544306,
              21.016284496680985
            ],
            [
              105.8344621930413,
              21.016276799593424
            ],
            [
              105.83446046516507,
              21.016274856200454
            ],
            [
              105.83448632763748,
              21.016257172464663
            ],
            [
              105.83453846706693,
              21.016223171333607
            ],
            [
              105.83457910782882,
              21.016195636780736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6349",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016284496680985,
          "to_latitude": 21.016195636780736,
          "to_longitude": 105.83457910782882,
          "from_longitude": 105.83446904544306
        }
      },
      {
        "id": 6342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427368680222,
            21.018231223409455,
            105.8343287670584,
            21.018334066267172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343287670584,
              21.018334066267172
            ],
            [
              105.8343220706736,
              21.018327282476076
            ],
            [
              105.83429563959984,
              21.0182884713956
            ],
            [
              105.83429144116838,
              21.01826590776452
            ],
            [
              105.83427368680222,
              21.018231223409455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT9.7_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6350",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018334066267172,
          "to_latitude": 21.018231223409455,
          "to_longitude": 105.83427368680222,
          "from_longitude": 105.8343287670584
        }
      },
      {
        "id": 6343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83272611625155,
            21.018716811570734,
            105.83289362063117,
            21.0187551326126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272611625155,
              21.0187551326126
            ],
            [
              105.83272887383265,
              21.018754376662866
            ],
            [
              105.83276692670033,
              21.018743941489713
            ],
            [
              105.83279369526583,
              21.018734794454243
            ],
            [
              105.83283153621836,
              21.018729160039634
            ],
            [
              105.83289362063117,
              21.018716811570734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.5_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6351",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0187551326126,
          "to_latitude": 21.018716811570734,
          "to_longitude": 105.83289362063117,
          "from_longitude": 105.83272611625155
        }
      },
      {
        "id": 6344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83775892212314,
            21.012533135862256,
            105.8378373723879,
            21.012693116466963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775892212314,
              21.012693116466963
            ],
            [
              105.83779850458437,
              21.012634993277167
            ],
            [
              105.83780992019649,
              21.01260357079552
            ],
            [
              105.83782861999128,
              21.012552096090687
            ],
            [
              105.83782981933747,
              21.012548794319358
            ],
            [
              105.8378373723879,
              21.012533135862256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6352",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012693116466963,
          "to_latitude": 21.012533135862256,
          "to_longitude": 105.8378373723879,
          "from_longitude": 105.83775892212314
        }
      },
      {
        "id": 6345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334382274421,
            21.01797919271615,
            105.83353988163775,
            21.018021281434972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334382274421,
              21.018010710222164
            ],
            [
              105.83344145901287,
              21.018016662897352
            ],
            [
              105.83344396843934,
              21.018021281434972
            ],
            [
              105.83353988163775,
              21.01797919271615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT11.1_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6353",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018010710222164,
          "to_latitude": 21.01797919271615,
          "to_longitude": 105.83353988163775,
          "from_longitude": 105.8334382274421
        }
      },
      {
        "id": 6346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529729098615,
            21.018832128309253,
            105.83542234436969,
            21.018912772514444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353030087299,
              21.018912772514444
            ],
            [
              105.83529887450423,
              21.01890815746913
            ],
            [
              105.83529729098615,
              21.018906233248497
            ],
            [
              105.83536296028335,
              21.018866740652214
            ],
            [
              105.83540218100325,
              21.018844773115994
            ],
            [
              105.83542234436969,
              21.018832128309253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT17.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6354",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018912772514444,
          "to_latitude": 21.018832128309253,
          "to_longitude": 105.83542234436969,
          "from_longitude": 105.8353030087299
        }
      },
      {
        "id": 6347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427464158667,
            21.016350941249783,
            105.83440224852053,
            21.01646116795212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440224852053,
              21.016350941249783
            ],
            [
              105.83435703521673,
              21.016389997139363
            ],
            [
              105.83433387706965,
              21.016411720362093
            ],
            [
              105.83430194764323,
              21.016437023624572
            ],
            [
              105.83427464158667,
              21.01646116795212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.3_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6355",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016350941249783,
          "to_latitude": 21.01646116795212,
          "to_longitude": 105.83427464158667,
          "from_longitude": 105.83440224852053
        }
      },
      {
        "id": 6348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850172999132,
            21.012886902340146,
            105.83866500596741,
            21.01302665093994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850172999132,
              21.01302665093994
            ],
            [
              105.83866500596741,
              21.012886902340146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.18_Ngõ 77, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.6356",
          "diaChiLapD": "Ngõ 77, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01302665093994,
          "to_latitude": 21.012886902340146,
          "to_longitude": 105.83866500596741,
          "from_longitude": 105.83850172999132
        }
      },
      {
        "id": 6349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427331598091,
            21.016609992971272,
            105.83434231377335,
            21.016777197692445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83434231377335,
              21.016777197692445
            ],
            [
              105.83430722047444,
              21.01669546269169
            ],
            [
              105.8343080743889,
              21.016685034389756
            ],
            [
              105.83428324599313,
              21.016629061391622
            ],
            [
              105.83427331598091,
              21.01661351952969
            ],
            [
              105.83427987591634,
              21.016610486848
            ],
            [
              105.83428094284982,
              21.016609992971272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT14.1_Ngách 107, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6357",
          "diaChiLapD": "Ngách 107, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016777197692445,
          "to_latitude": 21.016609992971272,
          "to_longitude": 105.83428094284982,
          "from_longitude": 105.83434231377335
        }
      },
      {
        "id": 6350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83565920996301,
            21.018350386085828,
            105.83567960564613,
            21.018501074074187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356607495481,
              21.018350386085828
            ],
            [
              105.8356728179628,
              21.0184238826617
            ],
            [
              105.83565920996301,
              21.01842695544074
            ],
            [
              105.83567960564613,
              21.018501074074187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6358",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018350386085828,
          "to_latitude": 21.018501074074187,
          "to_longitude": 105.83567960564613,
          "from_longitude": 105.8356607495481
        }
      },
      {
        "id": 6351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83457189438997,
            21.01642015526828,
            105.83479773668763,
            21.01649370915885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83479773668763,
              21.01642015526828
            ],
            [
              105.83473845853389,
              21.01643909230232
            ],
            [
              105.83469407884519,
              21.01645162009913
            ],
            [
              105.83464440756799,
              21.016464331910058
            ],
            [
              105.83457189438997,
              21.01649370915885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.18_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6359",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01642015526828,
          "to_latitude": 21.01649370915885,
          "to_longitude": 105.83457189438997,
          "from_longitude": 105.83479773668763
        }
      },
      {
        "id": 6352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834608800532,
            21.01607350723513,
            105.83480610395432,
            21.01619641692037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480610395432,
              21.01607350723513
            ],
            [
              105.83467514834001,
              21.01615508592521
            ],
            [
              105.834608800532,
              21.01619641692037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6360",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01607350723513,
          "to_latitude": 21.01619641692037,
          "to_longitude": 105.834608800532,
          "from_longitude": 105.83480610395432
        }
      },
      {
        "id": 6353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84104411373815,
            21.014899853952294,
            105.84130838371964,
            21.014991386977737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84130838371964,
              21.014991386977737
            ],
            [
              105.84128715978194,
              21.014989006257938
            ],
            [
              105.84128096852592,
              21.014988272745796
            ],
            [
              105.8412783550052,
              21.014921562743055
            ],
            [
              105.84111996940752,
              21.014908212215833
            ],
            [
              105.84104411373815,
              21.014899853952294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT91.5_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6361",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014991386977737,
          "to_latitude": 21.014899853952294,
          "to_longitude": 105.84104411373815,
          "from_longitude": 105.84130838371964
        }
      },
      {
        "id": 6354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83330613349854,
            21.01872322666248,
            105.83345853940118,
            21.018867243258292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83330613349854,
              21.018744139778136
            ],
            [
              105.83341912224033,
              21.01872322666248
            ],
            [
              105.83345853940118,
              21.018867243258292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.2_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6362",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018744139778136,
          "to_latitude": 21.018867243258292,
          "to_longitude": 105.83345853940118,
          "from_longitude": 105.83330613349854
        }
      },
      {
        "id": 6355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354780667375,
            21.017791066741868,
            105.83568387857581,
            21.017877744504503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8354780667375,
              21.017791066741868
            ],
            [
              105.83550237230605,
              21.017877744504503
            ],
            [
              105.83560095049049,
              21.017860368217868
            ],
            [
              105.83568248611132,
              21.017845642559877
            ],
            [
              105.83568387857581,
              21.017845390945666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT22.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6363",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017791066741868,
          "to_latitude": 21.017845390945666,
          "to_longitude": 105.83568387857581,
          "from_longitude": 105.8354780667375
        }
      },
      {
        "id": 6356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357751575293,
            21.017014461290522,
            105.8359304317614,
            21.01714751465616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359304317614,
              21.01714751465616
            ],
            [
              105.8359292991996,
              21.017141238297082
            ],
            [
              105.83589799662013,
              21.017064795986613
            ],
            [
              105.8358229409288,
              21.01710330972877
            ],
            [
              105.8357751575293,
              21.017014461290522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.8_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6364",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01714751465616,
          "to_latitude": 21.017014461290522,
          "to_longitude": 105.8357751575293,
          "from_longitude": 105.8359304317614
        }
      },
      {
        "id": 6357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83430214183598,
            21.015942129445783,
            105.83448082427657,
            21.016102715777347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430214183598,
              21.016102715777347
            ],
            [
              105.83432345085713,
              21.016082506399957
            ],
            [
              105.83436049565286,
              21.01602086209651
            ],
            [
              105.83439099268651,
              21.015990030889675
            ],
            [
              105.83440250760066,
              21.01600101543118
            ],
            [
              105.83448082427657,
              21.015942129445783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15.4_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6365",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016102715777347,
          "to_latitude": 21.015942129445783,
          "to_longitude": 105.83448082427657,
          "from_longitude": 105.83430214183598
        }
      },
      {
        "id": 6358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83270918784375,
            21.016796236883945,
            105.8329499360733,
            21.01695711283482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329499360733,
              21.016796236883945
            ],
            [
              105.83294548274858,
              21.01680099971499
            ],
            [
              105.83294259960395,
              21.016804082085844
            ],
            [
              105.83289164974308,
              21.016858562957914
            ],
            [
              105.83280921894443,
              21.016934994550205
            ],
            [
              105.83280139723449,
              21.01694224689663
            ],
            [
              105.83277222518363,
              21.016914509863525
            ],
            [
              105.83270918784375,
              21.01695711283482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT12.2_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6366",
          "diaChiLapD": "Ngách 68, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016796236883945,
          "to_latitude": 21.01695711283482,
          "to_longitude": 105.83270918784375,
          "from_longitude": 105.8329499360733
        }
      },
      {
        "id": 6359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83219828972982,
            21.017335192933267,
            105.83234204173874,
            21.01753893794182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234204173874,
              21.01753893794182
            ],
            [
              105.83232090229113,
              21.017504679991152
            ],
            [
              105.83230816180561,
              21.017482067770604
            ],
            [
              105.83224390171645,
              21.017395567801177
            ],
            [
              105.83219828972982,
              21.017342474479722
            ],
            [
              105.83220260647074,
              21.017340133881277
            ],
            [
              105.832211716599,
              21.017335192933267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT26.1_Ngõ 268, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.6367",
          "diaChiLapD": "Ngõ 268, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01753893794182,
          "to_latitude": 21.017335192933267,
          "to_longitude": 105.832211716599,
          "from_longitude": 105.83234204173874
        }
      },
      {
        "id": 6360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299864484708,
            21.018710177064214,
            105.83331729763631,
            21.01872990797174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299864484708,
              21.018710177064214
            ],
            [
              105.83301988366954,
              21.018713774473387
            ],
            [
              105.83316834647792,
              21.018729564654198
            ],
            [
              105.83326901042732,
              21.01872990797174
            ],
            [
              105.83331729763631,
              21.018723651440876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.1_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6368",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018710177064214,
          "to_latitude": 21.018723651440876,
          "to_longitude": 105.83331729763631,
          "from_longitude": 105.83299864484708
        }
      },
      {
        "id": 6361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390532990312,
            21.017943529840256,
            105.83400885136803,
            21.018182860102712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390621622175,
              21.018182860102712
            ],
            [
              105.83391518028446,
              21.018174660946347
            ],
            [
              105.83395657414287,
              21.01815512269703
            ],
            [
              105.83397407039018,
              21.01814686364485
            ],
            [
              105.83400885136803,
              21.01813053986226
            ],
            [
              105.83400502035208,
              21.01812387297735
            ],
            [
              105.8340009717454,
              21.018116825998394
            ],
            [
              105.83395171154422,
              21.018031098594633
            ],
            [
              105.83390532990312,
              21.017943529840256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10.2_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6369",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018182860102712,
          "to_latitude": 21.017943529840256,
          "to_longitude": 105.83390532990312,
          "from_longitude": 105.83390621622175
        }
      },
      {
        "id": 6362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368097762892,
            21.016705180464953,
            105.83393787279152,
            21.01692575967034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368097762892,
              21.01692575967034
            ],
            [
              105.83370837158782,
              21.016902167776927
            ],
            [
              105.83376453580884,
              21.01685408033011
            ],
            [
              105.83379024837757,
              21.016832066468922
            ],
            [
              105.83379539816883,
              21.016827657879915
            ],
            [
              105.83387368946255,
              21.016760626102446
            ],
            [
              105.8338991778422,
              21.016738607905012
            ],
            [
              105.83393787279152,
              21.016705180464953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.10_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6370",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01692575967034,
          "to_latitude": 21.016705180464953,
          "to_longitude": 105.83393787279152,
          "from_longitude": 105.83368097762892
        }
      },
      {
        "id": 6363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83182841475687,
            21.01878134975226,
            105.83194940214943,
            21.018992501552656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83194940214943,
              21.018992501552656
            ],
            [
              105.83194791711406,
              21.018988554497916
            ],
            [
              105.8319470632779,
              21.018986889514593
            ],
            [
              105.83192103143884,
              21.01898631953794
            ],
            [
              105.83190720870293,
              21.01898330804562
            ],
            [
              105.83190522629442,
              21.018978833229976
            ],
            [
              105.83188401206384,
              21.018930941422447
            ],
            [
              105.83182841475687,
              21.018819085180883
            ],
            [
              105.83190516877374,
              21.01878134975226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4.3_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6371",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018992501552656,
          "to_latitude": 21.01878134975226,
          "to_longitude": 105.83190516877374,
          "from_longitude": 105.83194940214943
        }
      },
      {
        "id": 6364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83606156805537,
            21.018174139937482,
            105.83628915487333,
            21.018531679436784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617172959347,
              21.018531679436784
            ],
            [
              105.83612525642712,
              21.01842312176101
            ],
            [
              105.83612235490605,
              21.018416344681043
            ],
            [
              105.83612115585775,
              21.018413675225005
            ],
            [
              105.8360894390951,
              21.01834307164085
            ],
            [
              105.83608171104595,
              21.01832586798
            ],
            [
              105.83607221386562,
              21.01830295199263
            ],
            [
              105.83606156805537,
              21.018277264235902
            ],
            [
              105.83618130182272,
              21.018224540732824
            ],
            [
              105.83628915487333,
              21.018174139937482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.6372",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018531679436784,
          "to_latitude": 21.018174139937482,
          "to_longitude": 105.83628915487333,
          "from_longitude": 105.83617172959347
        }
      },
      {
        "id": 6365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414871472009,
            21.017314501175953,
            105.83450371575033,
            21.01752722265253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444212113875,
              21.01752722265253
            ],
            [
              105.83444573972807,
              21.017524927874117
            ],
            [
              105.83450371575033,
              21.017500264553465
            ],
            [
              105.8344430997744,
              21.0173305244768
            ],
            [
              105.83439524223922,
              21.017314501175953
            ],
            [
              105.83422973616801,
              21.01735892596804
            ],
            [
              105.83416886134911,
              21.01737918393231
            ],
            [
              105.83415232072673,
              21.01735325307664
            ],
            [
              105.83415135615262,
              21.017351743098878
            ],
            [
              105.83414871472009,
              21.017347605734344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.2_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6373",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01752722265253,
          "to_latitude": 21.017347605734344,
          "to_longitude": 105.83414871472009,
          "from_longitude": 105.83444212113875
        }
      },
      {
        "id": 6366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323962403668,
            21.017264971875367,
            105.83304630389016,
            21.017544286283936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83304630389016,
              21.017544286283936
            ],
            [
              105.8328838756856,
              21.017387831994142
            ],
            [
              105.83275858471923,
              21.017470973909845
            ],
            [
              105.83258345539586,
              21.017264971875367
            ],
            [
              105.8323962403668,
              21.01738182054443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.6_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6374",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017544286283936,
          "to_latitude": 21.01738182054443,
          "to_longitude": 105.8323962403668,
          "from_longitude": 105.83304630389016
        }
      },
      {
        "id": 6367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669529192426,
            21.01330446823166,
            105.83671948374646,
            21.01336820087366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83671948374646,
              21.01330446823166
            ],
            [
              105.83669529192426,
              21.013360608792077
            ],
            [
              105.83670933696378,
              21.01336820087366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.7_Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6375",
          "diaChiLapD": "Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01330446823166,
          "to_latitude": 21.01336820087366,
          "to_longitude": 105.83670933696378,
          "from_longitude": 105.83671948374646
        }
      },
      {
        "id": 6368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670933696378,
            21.013329581189723,
            105.83673864485316,
            21.01336820087366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670933696378,
              21.01336820087366
            ],
            [
              105.83673864485316,
              21.013329581189723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.7_Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6376",
          "diaChiLapD": "Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01336820087366,
          "to_latitude": 21.013329581189723,
          "to_longitude": 105.83673864485316,
          "from_longitude": 105.83670933696378
        }
      },
      {
        "id": 6369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83636518079852,
            21.01336820087366,
            105.83670933696378,
            21.01366788439825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636518079852,
              21.013656192554784
            ],
            [
              105.83637408125445,
              21.01366788439825
            ],
            [
              105.83649540514158,
              21.01357047313329
            ],
            [
              105.83657092300561,
              21.013512029923778
            ],
            [
              105.83667349348458,
              21.013421430734095
            ],
            [
              105.83670933696378,
              21.01336820087366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.7_Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6377",
          "diaChiLapD": "Ngõ 273, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013656192554784,
          "to_latitude": 21.01336820087366,
          "to_longitude": 105.83670933696378,
          "from_longitude": 105.83636518079852
        }
      },
      {
        "id": 6370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263345853217,
            21.018707372250965,
            105.83297270229326,
            21.018905507904687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83296882496461,
              21.018707372250965
            ],
            [
              105.83296921982512,
              21.018709879475217
            ],
            [
              105.83297270229326,
              21.018731908327133
            ],
            [
              105.83290216083223,
              21.018766513041097
            ],
            [
              105.83286504567639,
              21.018786092414743
            ],
            [
              105.83281781594087,
              21.018805919106136
            ],
            [
              105.83281006480729,
              21.01883317301642
            ],
            [
              105.83263345853217,
              21.018905507904687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.4_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6378",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018707372250965,
          "to_latitude": 21.018905507904687,
          "to_longitude": 105.83263345853217,
          "from_longitude": 105.83296882496461
        }
      },
      {
        "id": 6371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83290216083223,
            21.018766513041097,
            105.8330148455353,
            21.018886988483867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330148455353,
              21.01886974718154
            ],
            [
              105.83294482515973,
              21.018886988483867
            ],
            [
              105.83290216083223,
              21.018766513041097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.4_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.6379",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01886974718154,
          "to_latitude": 21.018766513041097,
          "to_longitude": 105.83290216083223,
          "from_longitude": 105.8330148455353
        }
      },
      {
        "id": 6372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537642296429,
            21.018407559389303,
            105.83538171653866,
            21.01841721956946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538171653866,
              21.01841721956946
            ],
            [
              105.83537852892951,
              21.018411403104835
            ],
            [
              105.83537711881351,
              21.018408832227884
            ],
            [
              105.83537642296429,
              21.018407559389303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.3_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6380",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01841721956946,
          "to_latitude": 21.018407559389303,
          "to_longitude": 105.83537642296429,
          "from_longitude": 105.83538171653866
        }
      },
      {
        "id": 6373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518750711225,
            21.018359059475536,
            105.83553264156117,
            21.018515251721457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518750711225,
              21.018515251721457
            ],
            [
              105.83522865167666,
              21.01849552922831
            ],
            [
              105.83523442217805,
              21.018492890577562
            ],
            [
              105.83525397509212,
              21.01848394839473
            ],
            [
              105.83525998491544,
              21.01847384612995
            ],
            [
              105.8352988491536,
              21.018455075073323
            ],
            [
              105.83535894815816,
              21.01842604839572
            ],
            [
              105.83538171653866,
              21.01841721956946
            ],
            [
              105.83549108536127,
              21.01837481077906
            ],
            [
              105.83549370871464,
              21.018373793509316
            ],
            [
              105.83553264156117,
              21.018359059475536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.3_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.6381",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018515251721457,
          "to_latitude": 21.018359059475536,
          "to_longitude": 105.83553264156117,
          "from_longitude": 105.83518750711225
        }
      },
      {
        "id": 6374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699698094486,
            21.016175517270717,
            105.83718145025797,
            21.016290136476904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83699698094486,
              21.016175517270717
            ],
            [
              105.83708239193109,
              21.016252800501956
            ],
            [
              105.83712720271078,
              21.01621863142844
            ],
            [
              105.8371762700667,
              21.0162825279187
            ],
            [
              105.8371805996621,
              21.016288886987667
            ],
            [
              105.83718145025797,
              21.016290136476904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.5_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6382",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016175517270717,
          "to_latitude": 21.016290136476904,
          "to_longitude": 105.83718145025797,
          "from_longitude": 105.83699698094486
        }
      },
      {
        "id": 6375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712720271078,
            21.016171037697422,
            105.83718596842968,
            21.01621863142844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712720271078,
              21.01621863142844
            ],
            [
              105.83718596842968,
              21.016171037697422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.5_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6383",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01621863142844,
          "to_latitude": 21.016171037697422,
          "to_longitude": 105.83718596842968,
          "from_longitude": 105.83712720271078
        }
      },
      {
        "id": 6376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629054046025,
            21.015885221340458,
            105.8362961270642,
            21.015891545351415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629054046025,
              21.015885221340458
            ],
            [
              105.83629138072742,
              21.015886172821244
            ],
            [
              105.83629416484087,
              21.01588932583966
            ],
            [
              105.8362961270642,
              21.015891545351415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.11_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6384",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015885221340458,
          "to_latitude": 21.015891545351415,
          "to_longitude": 105.8362961270642,
          "from_longitude": 105.83629054046025
        }
      },
      {
        "id": 6377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735538862662,
            21.013188316210357,
            105.83736484247578,
            21.01319639872416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735538862662,
              21.01319639872416
            ],
            [
              105.83735924579706,
              21.01319310103692
            ],
            [
              105.83736387555061,
              21.013189142903215
            ],
            [
              105.83736484247578,
              21.013188316210357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.8_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6385",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01319639872416,
          "to_latitude": 21.013188316210357,
          "to_longitude": 105.83736484247578,
          "from_longitude": 105.83735538862662
        }
      },
      {
        "id": 6378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733309627145,
            21.014531116259132,
            105.83739745014923,
            21.014639474559132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83739745014923,
              21.014639474559132
            ],
            [
              105.83737518114755,
              21.014600472750057
            ],
            [
              105.83733309627145,
              21.014531116259132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6386",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014639474559132,
          "to_latitude": 21.014531116259132,
          "to_longitude": 105.83733309627145,
          "from_longitude": 105.83739745014923
        }
      },
      {
        "id": 6379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897981106918,
            21.016163546757674,
            105.83904553410491,
            21.01632944533213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83901126917443,
              21.016163546757674
            ],
            [
              105.83901938133407,
              21.016170963765887
            ],
            [
              105.83903990647109,
              21.01620436672124
            ],
            [
              105.83901963588919,
              21.016220597365727
            ],
            [
              105.83904553410491,
              21.016251190988633
            ],
            [
              105.83904159895746,
              21.016256204320634
            ],
            [
              105.83899683632795,
              21.016296494796148
            ],
            [
              105.83897981106918,
              21.01631268143134
            ],
            [
              105.83899081691817,
              21.01632373951667
            ],
            [
              105.83899683017944,
              21.01632944533213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT90.5A_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.6387",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016163546757674,
          "to_latitude": 21.01632944533213,
          "to_longitude": 105.83899683017944,
          "from_longitude": 105.83901126917443
        }
      },
      {
        "id": 6380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903269812724,
            21.014966972089976,
            105.8391080860582,
            21.015066866925572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903269812724,
              21.014966972089976
            ],
            [
              105.83903934677936,
              21.014990836798628
            ],
            [
              105.83906244815748,
              21.01502155170597
            ],
            [
              105.8390749120581,
              21.015036882977082
            ],
            [
              105.83908638534983,
              21.015051386360433
            ],
            [
              105.83909442164185,
              21.01505436529973
            ],
            [
              105.8391045188133,
              21.015058107233884
            ],
            [
              105.8391080860582,
              21.015062821629776
            ],
            [
              105.83910337405769,
              21.015066866925572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.27_Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6388",
          "diaChiLapD": "Ngách 143/64, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014966972089976,
          "to_latitude": 21.015066866925572,
          "to_longitude": 105.83910337405769,
          "from_longitude": 105.83903269812724
        }
      },
      {
        "id": 6381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044454262005,
            21.015665267627917,
            105.84045729527062,
            21.015697004467235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84045729527062,
              21.015696216809648
            ],
            [
              105.84044969409011,
              21.015696566875746
            ],
            [
              105.84044521873004,
              21.015697004467235
            ],
            [
              105.84044454262005,
              21.015665267627917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.6A_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.6389",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015696216809648,
          "to_latitude": 21.015665267627917,
          "to_longitude": 105.84044454262005,
          "from_longitude": 105.84045729527062
        }
      },
      {
        "id": 6382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83956805938048,
            21.016407282439204,
            105.83977981105474,
            21.016619120663396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956805938048,
              21.016489133446907
            ],
            [
              105.83956816394281,
              21.016441135626835
            ],
            [
              105.8395682380197,
              21.016407282439204
            ],
            [
              105.83975481351618,
              21.016432947964343
            ],
            [
              105.83975928847339,
              21.016442227271913
            ],
            [
              105.83976239391052,
              21.01650138212108
            ],
            [
              105.8397604178612,
              21.01660167950062
            ],
            [
              105.83977981105474,
              21.016615503349183
            ],
            [
              105.83977966455001,
              21.016618715935447
            ],
            [
              105.83977964757722,
              21.016619120663396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72A.2_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.6390",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016489133446907,
          "to_latitude": 21.016619120663396,
          "to_longitude": 105.83977964757722,
          "from_longitude": 105.83956805938048
        }
      },
      {
        "id": 6383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81222209518317,
            21.014967719199696,
            105.81253926283587,
            21.01542319132211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81222209518317,
              21.014967719199696
            ],
            [
              105.81238231334406,
              21.015198253871144
            ],
            [
              105.812386922169,
              21.0152048588506
            ],
            [
              105.81253926283587,
              21.01542319132211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6391",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014967719199696,
          "to_latitude": 21.01542319132211,
          "to_longitude": 105.81253926283587,
          "from_longitude": 105.81222209518317
        }
      },
      {
        "id": 6384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81260701765525,
            21.01529210394124,
            105.8126342050053,
            21.01530842416757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8126342050053,
              21.01529210394124
            ],
            [
              105.81261912659582,
              21.015301154955587
            ],
            [
              105.81260999479198,
              21.015306636910474
            ],
            [
              105.81260701765525,
              21.01530842416757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6392",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01529210394124,
          "to_latitude": 21.01530842416757,
          "to_longitude": 105.81260701765525,
          "from_longitude": 105.8126342050053
        }
      },
      {
        "id": 6385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8113965085654,
            21.016027063932167,
            105.81145931014832,
            21.016122396418954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81145931014832,
              21.016122396418954
            ],
            [
              105.81145809888417,
              21.016120407867966
            ],
            [
              105.81145288204608,
              21.016111833112806
            ],
            [
              105.81144069803125,
              21.01609351233406
            ],
            [
              105.81140252252179,
              21.016036111792175
            ],
            [
              105.8113965085654,
              21.016027063932167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6393",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016122396418954,
          "to_latitude": 21.016027063932167,
          "to_longitude": 105.8113965085654,
          "from_longitude": 105.81145931014832
        }
      },
      {
        "id": 6386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81291318138055,
            21.014543200705756,
            105.81292091819009,
            21.014554379125663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81292091819009,
              21.014554379125663
            ],
            [
              105.81292085904865,
              21.014554293601552
            ],
            [
              105.81291318138055,
              21.014543200705756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_49_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6394",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014554379125663,
          "to_latitude": 21.014543200705756,
          "to_longitude": 105.81291318138055,
          "from_longitude": 105.81292091819009
        }
      },
      {
        "id": 6387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81292655524707,
            21.014532214967407,
            105.81293499072224,
            21.01454611147545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81293499072224,
              21.01454611147545
            ],
            [
              105.81292655524707,
              21.014532214967407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_49_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6395",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01454611147545,
          "to_latitude": 21.014532214967407,
          "to_longitude": 105.81292655524707,
          "from_longitude": 105.81293499072224
        }
      },
      {
        "id": 6388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81112506421792,
            21.014511325751396,
            105.81122293281386,
            21.01457029358458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81122293281386,
              21.014511325751396
            ],
            [
              105.8111977228351,
              21.014526515419256
            ],
            [
              105.81112506421792,
              21.01457029358458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6396",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014511325751396,
          "to_latitude": 21.01457029358458,
          "to_longitude": 105.81112506421792,
          "from_longitude": 105.81122293281386
        }
      },
      {
        "id": 6389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81102304929136,
            21.01435709857236,
            105.81111987032185,
            21.014418372714236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81111987032185,
              21.01435709857236
            ],
            [
              105.81110645014878,
              21.01436559148169
            ],
            [
              105.81102304929136,
              21.014418372714236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6397",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01435709857236,
          "to_latitude": 21.014418372714236,
          "to_longitude": 105.81102304929136,
          "from_longitude": 105.81111987032185
        }
      },
      {
        "id": 6390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81091820098682,
            21.014204066995273,
            105.8110176081035,
            21.014262230039375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110176081035,
              21.014204066995273
            ],
            [
              105.81100868651166,
              21.014209286829512
            ],
            [
              105.81091820098682,
              21.014262230039375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6398",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014204066995273,
          "to_latitude": 21.014262230039375,
          "to_longitude": 105.81091820098682,
          "from_longitude": 105.8110176081035
        }
      },
      {
        "id": 6391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81086022887536,
            21.014176398831918,
            105.81120028433925,
            21.014682311954317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81120028433925,
              21.014682311954317
            ],
            [
              105.81112506421792,
              21.01457029358458
            ],
            [
              105.81102304929136,
              21.014418372714236
            ],
            [
              105.81091820098682,
              21.014262230039375
            ],
            [
              105.8108670744242,
              21.014186090367485
            ],
            [
              105.81086382323515,
              21.0141814894602
            ],
            [
              105.81086022887536,
              21.014176398831918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6399",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014682311954317,
          "to_latitude": 21.014176398831918,
          "to_longitude": 105.81086022887536,
          "from_longitude": 105.81120028433925
        }
      },
      {
        "id": 6392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81085805675576,
            21.014177507444604,
            105.81117225069582,
            21.01470437329716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81117225069582,
              21.01470437329716
            ],
            [
              105.81085805675576,
              21.0142319062678
            ],
            [
              105.81086990549575,
              21.014198032913708
            ],
            [
              105.81086109019819,
              21.014183056038977
            ],
            [
              105.8108582600224,
              21.014177507444604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_45_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6400",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01470437329716,
          "to_latitude": 21.014177507444604,
          "to_longitude": 105.8108582600224,
          "from_longitude": 105.81117225069582
        }
      },
      {
        "id": 6393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81050517085738,
            21.014383731432087,
            105.81084773318969,
            21.014895298375183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81050517085738,
              21.014383731432087
            ],
            [
              105.81050736964556,
              21.014386188578477
            ],
            [
              105.8105138866216,
              21.01439346555665
            ],
            [
              105.81084773318969,
              21.014895298375183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_46_Ngõ 36, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6401",
          "diaChiLapD": "Ngõ 36, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014383731432087,
          "to_latitude": 21.014895298375183,
          "to_longitude": 105.81084773318969,
          "from_longitude": 105.81050517085738
        }
      },
      {
        "id": 6394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81038386588436,
            21.014837696693725,
            105.81055145166434,
            21.015086064848624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81055145166434,
              21.015086064848624
            ],
            [
              105.8105303508641,
              21.01505531559963
            ],
            [
              105.81046239378222,
              21.014954358496123
            ],
            [
              105.8104444452301,
              21.01492769397395
            ],
            [
              105.81038386588436,
              21.014837696693725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6402",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015086064848624,
          "to_latitude": 21.014837696693725,
          "to_longitude": 105.81038386588436,
          "from_longitude": 105.81055145166434
        }
      },
      {
        "id": 6395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81039335796682,
            21.01621762404427,
            105.8107964235187,
            21.016457756259832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8107964235187,
              21.016457756259832
            ],
            [
              105.8107453113055,
              21.016425159362452
            ],
            [
              105.81064253089657,
              21.016359601457864
            ],
            [
              105.8105030663951,
              21.016270649414256
            ],
            [
              105.81044795729272,
              21.016231974671964
            ],
            [
              105.81042750768569,
              21.01621762404427
            ],
            [
              105.81039761164364,
              21.0162358283201
            ],
            [
              105.81039335796682,
              21.016239753239844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6403",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016457756259832,
          "to_latitude": 21.016239753239844,
          "to_longitude": 105.81039335796682,
          "from_longitude": 105.8107964235187
        }
      },
      {
        "id": 6396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81042416906362,
            21.015969348071078,
            105.81078846285625,
            21.016261473632753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81042416906362,
              21.016261473632753
            ],
            [
              105.810428799623,
              21.016255731427382
            ],
            [
              105.81044795729272,
              21.016231974671964
            ],
            [
              105.81047688536985,
              21.01619610152479
            ],
            [
              105.81077101094009,
              21.01598049579224
            ],
            [
              105.81078846285625,
              21.015969348071078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6404",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016261473632753,
          "to_latitude": 21.015969348071078,
          "to_longitude": 105.81078846285625,
          "from_longitude": 105.81042416906362
        }
      },
      {
        "id": 6397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81022756328657,
            21.014606085513183,
            105.81037751220182,
            21.01482842008827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81037751220182,
              21.01482842008827
            ],
            [
              105.81030253769256,
              21.01471725372296
            ],
            [
              105.81027315863865,
              21.014673692725164
            ],
            [
              105.81022756328657,
              21.014606085513183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6405",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01482842008827,
          "to_latitude": 21.014606085513183,
          "to_longitude": 105.81022756328657,
          "from_longitude": 105.81037751220182
        }
      },
      {
        "id": 6398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81043026273812,
            21.01492769397395,
            105.8104444452301,
            21.014935939365603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8104444452301,
              21.01492769397395
            ],
            [
              105.81043899964737,
              21.014930887537425
            ],
            [
              105.81043896704489,
              21.01493090666054
            ],
            [
              105.81043423946943,
              21.014933640673128
            ],
            [
              105.81043186896778,
              21.01493501132426
            ],
            [
              105.81043026273812,
              21.014935939365603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6406",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01492769397395,
          "to_latitude": 21.014935939365603,
          "to_longitude": 105.81043026273812,
          "from_longitude": 105.8104444452301
        }
      },
      {
        "id": 6399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81026383678302,
            21.014673692725164,
            105.81027315863865,
            21.01467908242838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81027315863865,
              21.014673692725164
            ],
            [
              105.81027106335391,
              21.01467490399827
            ],
            [
              105.81026615358125,
              21.014677742745143
            ],
            [
              105.81026396527895,
              21.014679007751656
            ],
            [
              105.81026383678302,
              21.01467908242838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6407",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014673692725164,
          "to_latitude": 21.01467908242838,
          "to_longitude": 105.81026383678302,
          "from_longitude": 105.81027315863865
        }
      },
      {
        "id": 6400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81056148651544,
            21.01493741953686,
            105.81089662813208,
            21.015131443121145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81056148651544,
              21.015131443121145
            ],
            [
              105.81056603387799,
              21.01512953732106
            ],
            [
              105.8106279056122,
              21.01509427493773
            ],
            [
              105.81089487812166,
              21.014938441300366
            ],
            [
              105.81089662813208,
              21.01493741953686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6408",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015131443121145,
          "to_latitude": 21.01493741953686,
          "to_longitude": 105.81089662813208,
          "from_longitude": 105.81056148651544
        }
      },
      {
        "id": 6401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8106279056122,
            21.01509427493773,
            105.81080150545193,
            21.015355329817126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8106279056122,
              21.01509427493773
            ],
            [
              105.81063382829267,
              21.015103180552952
            ],
            [
              105.81080150545193,
              21.015355329817126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6409",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01509427493773,
          "to_latitude": 21.015355329817126,
          "to_longitude": 105.81080150545193,
          "from_longitude": 105.8106279056122
        }
      },
      {
        "id": 6402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8126233359945,
            21.01454611147545,
            105.81293499072224,
            21.01472993142391
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8126233359945,
              21.01472993142391
            ],
            [
              105.8126249491347,
              21.01472886152241
            ],
            [
              105.81263284039409,
              21.014723628461113
            ],
            [
              105.81292091819009,
              21.014554379125663
            ],
            [
              105.81293499072224,
              21.01454611147545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6410",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01472993142391,
          "to_latitude": 21.01454611147545,
          "to_longitude": 105.81293499072224,
          "from_longitude": 105.8126233359945
        }
      },
      {
        "id": 6403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81270424680727,
            21.014848356841167,
            105.81273613250892,
            21.014867089443428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81273613250892,
              21.014848356841167
            ],
            [
              105.81271596740274,
              21.014860952946517
            ],
            [
              105.81270614273373,
              21.014867089443428
            ],
            [
              105.81270424680727,
              21.01486461281549
            ],
            [
              105.81270618251904,
              21.014863424855882
            ],
            [
              105.81271412463852,
              21.014858345999865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6411",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014848356841167,
          "to_latitude": 21.014858345999865,
          "to_longitude": 105.81271412463852,
          "from_longitude": 105.81273613250892
        }
      },
      {
        "id": 6404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.812386922169,
            21.01513809005557,
            105.81249896775572,
            21.0152048588506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81249896775572,
              21.01513809005557
            ],
            [
              105.81249702322012,
              21.01513924824855
            ],
            [
              105.81249173999947,
              21.0151423968431
            ],
            [
              105.812386922169,
              21.0152048588506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6412",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01513809005557,
          "to_latitude": 21.0152048588506,
          "to_longitude": 105.812386922169,
          "from_longitude": 105.81249896775572
        }
      },
      {
        "id": 6405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81150016002798,
            21.017033667889915,
            105.81168591636003,
            21.017414855818156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81151339856852,
              21.017039667627344
            ],
            [
              105.81151218477329,
              21.017037035087437
            ],
            [
              105.8115095260024,
              21.017033667889915
            ],
            [
              105.81150335885174,
              21.017036851381814
            ],
            [
              105.81150298292913,
              21.017045600038315
            ],
            [
              105.81150016002798,
              21.017111282720553
            ],
            [
              105.8115955899574,
              21.01726552158324
            ],
            [
              105.81167669991333,
              21.01740099459321
            ],
            [
              105.81168591636003,
              21.017414855818156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_29_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6413",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017039667627344,
          "to_latitude": 21.017414855818156,
          "to_longitude": 105.81168591636003,
          "from_longitude": 105.81151339856852
        }
      },
      {
        "id": 6406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81252973588946,
            21.016809260142438,
            105.81253585630476,
            21.0168318962732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81253585630476,
              21.016809260142438
            ],
            [
              105.8125309684457,
              21.01682626959723
            ],
            [
              105.81252988982834,
              21.01683119101997
            ],
            [
              105.81252973588946,
              21.0168318962732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6414",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016809260142438,
          "to_latitude": 21.0168318962732,
          "to_longitude": 105.81252973588946,
          "from_longitude": 105.81253585630476
        }
      },
      {
        "id": 6407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8124092667924,
            21.01726122861552,
            105.81264699354381,
            21.017655319601655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81264699354381,
              21.017655319601655
            ],
            [
              105.81263603494762,
              21.017637917067805
            ],
            [
              105.81260387560665,
              21.017587798818642
            ],
            [
              105.81249371363097,
              21.017402937425224
            ],
            [
              105.8124092667924,
              21.01726122861552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_02_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6415",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017655319601655,
          "to_latitude": 21.01726122861552,
          "to_longitude": 105.8124092667924,
          "from_longitude": 105.81264699354381
        }
      },
      {
        "id": 6408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81159314022938,
            21.017615499240534,
            105.81188794779632,
            21.01777968964566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81188794779632,
              21.017708476917438
            ],
            [
              105.81187795448032,
              21.017693450635225
            ],
            [
              105.81182611513435,
              21.017615499240534
            ],
            [
              105.81160753113971,
              21.017770814512826
            ],
            [
              105.81159314022938,
              21.01777968964566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6416",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017708476917438,
          "to_latitude": 21.01777968964566,
          "to_longitude": 105.81159314022938,
          "from_longitude": 105.81188794779632
        }
      },
      {
        "id": 6409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81363761193963,
            21.01709616152227,
            105.81366756769619,
            21.017143622879527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81363761193963,
              21.01709616152227
            ],
            [
              105.8136567083306,
              21.017126439422466
            ],
            [
              105.81366030128672,
              21.01713212702572
            ],
            [
              105.81366756769619,
              21.017143622879527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6417",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01709616152227,
          "to_latitude": 21.017143622879527,
          "to_longitude": 105.81366756769619,
          "from_longitude": 105.81363761193963
        }
      },
      {
        "id": 6410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81148580450943,
            21.016102681829626,
            105.81193103595992,
            21.016344739724033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81148580450943,
              21.016344739724033
            ],
            [
              105.81185636753959,
              21.01613548213327
            ],
            [
              105.81192985845759,
              21.016103199579764
            ],
            [
              105.81193103595992,
              21.016102681829626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6418",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016344739724033,
          "to_latitude": 21.016102681829626,
          "to_longitude": 105.81193103595992,
          "from_longitude": 105.81148580450943
        }
      },
      {
        "id": 6411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81243806297974,
            21.015894501287125,
            105.81254039518689,
            21.01595580436328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81243806297974,
              21.01595580436328
            ],
            [
              105.81253050578917,
              21.01590184299092
            ],
            [
              105.81253680618501,
              21.015897165790502
            ],
            [
              105.81254039518689,
              21.015894501287125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6419",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01595580436328,
          "to_latitude": 21.015894501287125,
          "to_longitude": 105.81254039518689,
          "from_longitude": 105.81243806297974
        }
      },
      {
        "id": 6412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8120533122719,
            21.01629343610992,
            105.81206092204168,
            21.01629773296164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81206092204168,
              21.01629343610992
            ],
            [
              105.81205828763261,
              21.016294923656638
            ],
            [
              105.81205458584357,
              21.016297014235377
            ],
            [
              105.8120533122719,
              21.01629773296164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6420",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629343610992,
          "to_latitude": 21.01629773296164,
          "to_longitude": 105.8120533122719,
          "from_longitude": 105.81206092204168
        }
      },
      {
        "id": 6413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118990512162,
            21.016043127465746,
            105.81224103963692,
            21.016575544128635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81224103963692,
              21.016575544128635
            ],
            [
              105.81223175975488,
              21.016560981426924
            ],
            [
              105.81217041653836,
              21.01646471828412
            ],
            [
              105.81208473136314,
              21.016330254561623
            ],
            [
              105.81206092204168,
              21.01629343610992
            ],
            [
              105.81200781966636,
              21.01621132147407
            ],
            [
              105.8118990512162,
              21.016043127465746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6421",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016575544128635,
          "to_latitude": 21.016043127465746,
          "to_longitude": 105.8118990512162,
          "from_longitude": 105.81224103963692
        }
      },
      {
        "id": 6414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81381608168027,
            21.016159931260336,
            105.81383481974888,
            21.01618393252203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81381608168027,
              21.016159931260336
            ],
            [
              105.81383091717609,
              21.016178797371616
            ],
            [
              105.81383481974888,
              21.01618393252203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6422",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016159931260336,
          "to_latitude": 21.01618393252203,
          "to_longitude": 105.81383481974888,
          "from_longitude": 105.81381608168027
        }
      },
      {
        "id": 6415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81261889106415,
            21.01552207072475,
            105.81289736210549,
            21.01600219592135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81289736210549,
              21.01600219592135
            ],
            [
              105.81268170024894,
              21.01567711297927
            ],
            [
              105.81261889106415,
              21.01558243492897
            ],
            [
              105.81265742529133,
              21.015553113625224
            ],
            [
              105.81264633991819,
              21.01553447516963
            ],
            [
              105.81263848388626,
              21.01552207072475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6423",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01600219592135,
          "to_latitude": 21.01552207072475,
          "to_longitude": 105.81263848388626,
          "from_longitude": 105.81289736210549
        }
      },
      {
        "id": 6416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81229971271982,
            21.01646319052306,
            105.81268798041135,
            21.016696344444984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81229971271982,
              21.016696344444984
            ],
            [
              105.81231232112988,
              21.016689980611993
            ],
            [
              105.81268798041135,
              21.01646319052306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6424",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016696344444984,
          "to_latitude": 21.01646319052306,
          "to_longitude": 105.81268798041135,
          "from_longitude": 105.81229971271982
        }
      },
      {
        "id": 6417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81310584897672,
            21.017115521115375,
            105.81361650115626,
            21.017356647842043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81310584897672,
              21.017356647842043
            ],
            [
              105.81327741285314,
              21.01727376766675
            ],
            [
              105.81329266119543,
              21.01726640120278
            ],
            [
              105.81334490262319,
              21.017242061447675
            ],
            [
              105.81335174212678,
              21.01723887467535
            ],
            [
              105.81336584716519,
              21.01723230309249
            ],
            [
              105.81349439390013,
              21.01717241229727
            ],
            [
              105.81349559906559,
              21.01717185104904
            ],
            [
              105.81361650115626,
              21.017115521115375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6425",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017356647842043,
          "to_latitude": 21.017115521115375,
          "to_longitude": 105.81361650115626,
          "from_longitude": 105.81310584897672
        }
      },
      {
        "id": 6418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81335174212678,
            21.01723887467535,
            105.81337957917685,
            21.01728873976819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81335174212678,
              21.01723887467535
            ],
            [
              105.81336311414894,
              21.0172592440962
            ],
            [
              105.8133694369293,
              21.017270573764563
            ],
            [
              105.81337410053762,
              21.017278923487293
            ],
            [
              105.81337576258663,
              21.01728190161246
            ],
            [
              105.81337957917685,
              21.01728873976819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6426",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01723887467535,
          "to_latitude": 21.01728873976819,
          "to_longitude": 105.81337957917685,
          "from_longitude": 105.81335174212678
        }
      },
      {
        "id": 6419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81190628272826,
            21.01726122861552,
            105.8124092667924,
            21.017540924577908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8124092667924,
              21.01726122861552
            ],
            [
              105.81190628272826,
              21.017540924577908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_02_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6427",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01726122861552,
          "to_latitude": 21.017540924577908,
          "to_longitude": 105.81190628272826,
          "from_longitude": 105.8124092667924
        }
      },
      {
        "id": 6420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81199124924949,
            21.015853635420395,
            105.81225346958261,
            21.01624881962815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81225346958261,
              21.01624881962815
            ],
            [
              105.81201220710926,
              21.015889355621937
            ],
            [
              105.81199273797725,
              21.01585587916008
            ],
            [
              105.81199124924949,
              21.015853635420395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6428",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01624881962815,
          "to_latitude": 21.015853635420395,
          "to_longitude": 105.81199124924949,
          "from_longitude": 105.81225346958261
        }
      },
      {
        "id": 6421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81348671411408,
            21.01715652872441,
            105.81351834956587,
            21.017219094887217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81348671411408,
              21.01715652872441
            ],
            [
              105.81349439390013,
              21.01717241229727
            ],
            [
              105.81351001519911,
              21.017204724643086
            ],
            [
              105.81351834956587,
              21.017219094887217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6429",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01715652872441,
          "to_latitude": 21.017219094887217,
          "to_longitude": 105.81351834956587,
          "from_longitude": 105.81348671411408
        }
      },
      {
        "id": 6422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81326901424113,
            21.01725872210289,
            105.81330698301963,
            21.017325173522362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81326901424113,
              21.01725872210289
            ],
            [
              105.81327741285314,
              21.01727376766675
            ],
            [
              105.81328122749524,
              21.017280602221234
            ],
            [
              105.81329266072494,
              21.01730108425775
            ],
            [
              105.81329756815978,
              21.017309340687714
            ],
            [
              105.81330698301963,
              21.017325173522362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6430",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01725872210289,
          "to_latitude": 21.017325173522362,
          "to_longitude": 105.81330698301963,
          "from_longitude": 105.81326901424113
        }
      },
      {
        "id": 6423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81293943930274,
            21.016900326714943,
            105.81348265896459,
            21.01716537801323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81293943930274,
              21.01716537801323
            ],
            [
              105.81294110344102,
              21.01716483896277
            ],
            [
              105.81295924566379,
              21.017158952638827
            ],
            [
              105.81320816175325,
              21.017035984320845
            ],
            [
              105.81348265896459,
              21.016900326714943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_26_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6431",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01716537801323,
          "to_latitude": 21.016900326714943,
          "to_longitude": 105.81348265896459,
          "from_longitude": 105.81293943930274
        }
      },
      {
        "id": 6424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81156354282308,
            21.01727032633989,
            105.81233919752788,
            21.01772618270193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81156354282308,
              21.01772618270193
            ],
            [
              105.811577421931,
              21.017717478013772
            ],
            [
              105.81161830395642,
              21.017685291529734
            ],
            [
              105.81185756498957,
              21.01754756819718
            ],
            [
              105.81212650164066,
              21.017392761020467
            ],
            [
              105.81233919752788,
              21.01727032633989
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6432",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01772618270193,
          "to_latitude": 21.01727032633989,
          "to_longitude": 105.81233919752788,
          "from_longitude": 105.81156354282308
        }
      },
      {
        "id": 6425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81189299985735,
            21.017023589924516,
            105.81212650164066,
            21.017392761020467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81189299985735,
              21.017023589924516
            ],
            [
              105.81212650164066,
              21.017392761020467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6433",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017023589924516,
          "to_latitude": 21.017392761020467,
          "to_longitude": 105.81212650164066,
          "from_longitude": 105.81189299985735
        }
      },
      {
        "id": 6426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81163679954683,
            21.017201098218965,
            105.81185756498957,
            21.01754756819718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81163679954683,
              21.017201098218965
            ],
            [
              105.81185756498957,
              21.01754756819718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6434",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017201098218965,
          "to_latitude": 21.01754756819718,
          "to_longitude": 105.81185756498957,
          "from_longitude": 105.81163679954683
        }
      },
      {
        "id": 6427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81163469153364,
            21.017004478757464,
            105.81184847261316,
            21.017127257619997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81184847261316,
              21.017004478757464
            ],
            [
              105.81163469153364,
              21.017127257619997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6435",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017004478757464,
          "to_latitude": 21.017127257619997,
          "to_longitude": 105.81163469153364,
          "from_longitude": 105.81184847261316
        }
      },
      {
        "id": 6428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8117565235617,
            21.016900655178418,
            105.81205545138283,
            21.017330488412895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81205545138283,
              21.017330488412895
            ],
            [
              105.81184847261316,
              21.017004478757464
            ],
            [
              105.81179639026784,
              21.016922459456644
            ],
            [
              105.81176559054428,
              21.016912321365645
            ],
            [
              105.81175690039474,
              21.016901142026835
            ],
            [
              105.8117565235617,
              21.016900655178418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_32_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6436",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017330488412895,
          "to_latitude": 21.016900655178418,
          "to_longitude": 105.8117565235617,
          "from_longitude": 105.81205545138283
        }
      },
      {
        "id": 6429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81198139042785,
            21.017402937425224,
            105.81249371363097,
            21.017657722926703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81249371363097,
              21.017402937425224
            ],
            [
              105.81198139042785,
              21.017657722926703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6437",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017402937425224,
          "to_latitude": 21.017657722926703,
          "to_longitude": 105.81198139042785,
          "from_longitude": 105.81249371363097
        }
      },
      {
        "id": 6430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8125190639535,
            21.017502018797416,
            105.81262249920594,
            21.017667548898476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81262249920594,
              21.017667548898476
            ],
            [
              105.812610154205,
              21.017647795560272
            ],
            [
              105.8125190639535,
              21.017502018797416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6438",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017667548898476,
          "to_latitude": 21.017502018797416,
          "to_longitude": 105.8125190639535,
          "from_longitude": 105.81262249920594
        }
      },
      {
        "id": 6431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8119028044677,
            21.01686027767655,
            105.81211014435821,
            21.016974443642646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119028044677,
              21.016974443642646
            ],
            [
              105.81210373188674,
              21.01686380829724
            ],
            [
              105.81211014435821,
              21.01686027767655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6439",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016974443642646,
          "to_latitude": 21.01686027767655,
          "to_longitude": 105.81211014435821,
          "from_longitude": 105.8119028044677
        }
      },
      {
        "id": 6432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81202300879121,
            21.016738682828397,
            105.81231262617926,
            21.017183412731896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81202300879121,
              21.016738682828397
            ],
            [
              105.81202551555404,
              21.016741334483367
            ],
            [
              105.81204424702851,
              21.016761135063405
            ],
            [
              105.81211014435821,
              21.01686027767655
            ],
            [
              105.81227137923429,
              21.0171144169797
            ],
            [
              105.81231262617926,
              21.017183412731896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_34_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6440",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016738682828397,
          "to_latitude": 21.017183412731896,
          "to_longitude": 105.81231262617926,
          "from_longitude": 105.81202300879121
        }
      },
      {
        "id": 6433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81253785766009,
            21.016834034340476,
            105.81254332537286,
            21.016854987349554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81253785766009,
              21.016854987349554
            ],
            [
              105.81254004587323,
              21.016846601263122
            ],
            [
              105.81254316421125,
              21.01683464930538
            ],
            [
              105.81254332537286,
              21.016834034340476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6441",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016854987349554,
          "to_latitude": 21.016834034340476,
          "to_longitude": 105.81254332537286,
          "from_longitude": 105.81253785766009
        }
      },
      {
        "id": 6434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81224756473667,
            21.01678572038395,
            105.8127281366034,
            21.01690039054821
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81224756473667,
              21.01678572038395
            ],
            [
              105.81253785766009,
              21.016854987349554
            ],
            [
              105.81254443723554,
              21.016856557347456
            ],
            [
              105.8127281366034,
              21.01690039054821
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6442",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01678572038395,
          "to_latitude": 21.01690039054821,
          "to_longitude": 105.8127281366034,
          "from_longitude": 105.81224756473667
        }
      },
      {
        "id": 6435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81255312333762,
            21.0171915602209,
            105.8127930318017,
            21.017582409730956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8127930318017,
              21.017582409730956
            ],
            [
              105.81277801536265,
              21.017559091482504
            ],
            [
              105.81273210750278,
              21.017492237998816
            ],
            [
              105.81255312333762,
              21.0171915602209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Ngõ 15, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6443",
          "diaChiLapD": "Ngõ 15, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017582409730956,
          "to_latitude": 21.0171915602209,
          "to_longitude": 105.81255312333762,
          "from_longitude": 105.8127930318017
        }
      },
      {
        "id": 6436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81103815341602,
            21.016732643737292,
            105.81107405589604,
            21.016750489276493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81107405589604,
              21.016732643737292
            ],
            [
              105.81106216259377,
              21.016738555166178
            ],
            [
              105.81104798886993,
              21.016745600179032
            ],
            [
              105.81103815341602,
              21.016750489276493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6444",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016732643737292,
          "to_latitude": 21.016750489276493,
          "to_longitude": 105.81103815341602,
          "from_longitude": 105.81107405589604
        }
      },
      {
        "id": 6437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81121318873574,
            21.017060229305283,
            105.81126064779859,
            21.017084896076344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81126064779859,
              21.017060229305283
            ],
            [
              105.81124588358072,
              21.017067838055688
            ],
            [
              105.81123289306132,
              21.017074532408742
            ],
            [
              105.81121318873574,
              21.017084896076344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6445",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017060229305283,
          "to_latitude": 21.017084896076344,
          "to_longitude": 105.81121318873574,
          "from_longitude": 105.81126064779859
        }
      },
      {
        "id": 6438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81098896557242,
            21.016495371322037,
            105.81117270728144,
            21.016910289120062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81117270728144,
              21.016910289120062
            ],
            [
              105.81107516135866,
              21.016734634601757
            ],
            [
              105.81107405589604,
              21.016732643737292
            ],
            [
              105.81098896557242,
              21.0165794192621
            ],
            [
              105.81101204321958,
              21.016495371322037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6446",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016910289120062,
          "to_latitude": 21.016495371322037,
          "to_longitude": 105.81101204321958,
          "from_longitude": 105.81117270728144
        }
      },
      {
        "id": 6439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8111795147015,
            21.01692517528438,
            105.81145107480559,
            21.017127116805256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81145107480559,
              21.017046780676278
            ],
            [
              105.81130083722616,
              21.017127116805256
            ],
            [
              105.81126525566197,
              21.017067899224333
            ],
            [
              105.81126064779859,
              21.017060229305283
            ],
            [
              105.8111795147015,
              21.01692517528438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6447",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017046780676278,
          "to_latitude": 21.01692517528438,
          "to_longitude": 105.8111795147015,
          "from_longitude": 105.81145107480559
        }
      },
      {
        "id": 6440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81129490428023,
            21.016643111325045,
            105.81151800481855,
            21.017037375001824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81151742346732,
              21.017037375001824
            ],
            [
              105.81151556141523,
              21.01703529381392
            ],
            [
              105.81151164777303,
              21.017030058403638
            ],
            [
              105.81151800481855,
              21.016993523202196
            ],
            [
              105.81137674430059,
              21.01677371081552
            ],
            [
              105.81129490428023,
              21.016643111325045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_30_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6448",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017037375001824,
          "to_latitude": 21.016643111325045,
          "to_longitude": 105.81129490428023,
          "from_longitude": 105.81151742346732
        }
      },
      {
        "id": 6441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81135175805383,
            21.016180490127624,
            105.81159945285128,
            21.01664931536351
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81159945285128,
              21.01664931536351
            ],
            [
              105.81145649993177,
              21.01642513134793
            ],
            [
              105.81144255266986,
              21.01640325935781
            ],
            [
              105.81143135275647,
              21.016385482145406
            ],
            [
              105.81135175805383,
              21.016259143329968
            ],
            [
              105.81136537497954,
              21.016195769299703
            ],
            [
              105.81135640445083,
              21.01618129178116
            ],
            [
              105.81135537083397,
              21.016180490127624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_35_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6449",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01664931536351,
          "to_latitude": 21.016180490127624,
          "to_longitude": 105.81135537083397,
          "from_longitude": 105.81159945285128
        }
      },
      {
        "id": 6442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81160663954681,
            21.01665837929733,
            105.81175181260497,
            21.016900692061483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81172021019019,
              21.016900692061483
            ],
            [
              105.81174154029934,
              21.01688911205956
            ],
            [
              105.81175181260497,
              21.016883005092083
            ],
            [
              105.81160663954681,
              21.01665837929733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6450",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016900692061483,
          "to_latitude": 21.01665837929733,
          "to_longitude": 105.81160663954681,
          "from_longitude": 105.81172021019019
        }
      },
      {
        "id": 6443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81154666052275,
            21.016900692061483,
            105.81173099806745,
            21.016997824968783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81154666052275,
              21.016997824968783
            ],
            [
              105.81172021019019,
              21.016900692061483
            ],
            [
              105.81173013447213,
              21.01691452913393
            ],
            [
              105.81173099806745,
              21.016915729919862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6451",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016997824968783,
          "to_latitude": 21.016915729919862,
          "to_longitude": 105.81173099806745,
          "from_longitude": 105.81154666052275
        }
      },
      {
        "id": 6444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116771421846,
            21.016669936211795,
            105.81212501954373,
            21.016856536884628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8116771421846,
              21.016671457682158
            ],
            [
              105.81179629607398,
              21.016856536884628
            ],
            [
              105.8118781181099,
              21.016810089906595
            ],
            [
              105.8119215733439,
              21.01678542309555
            ],
            [
              105.81212501954373,
              21.016669936211795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_33_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6452",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016671457682158,
          "to_latitude": 21.016669936211795,
          "to_longitude": 105.81212501954373,
          "from_longitude": 105.8116771421846
        }
      },
      {
        "id": 6445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81151276834571,
            21.016137910449356,
            105.81195431331415,
            21.016584054992695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81195431331415,
              21.016137910449356
            ],
            [
              105.81195336732743,
              21.016138548127316
            ],
            [
              105.81194490424724,
              21.01614425356679
            ],
            [
              105.81151823424037,
              21.01639499330721
            ],
            [
              105.81151276834571,
              21.016413817369887
            ],
            [
              105.81162185938388,
              21.016584054992695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6453",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016137910449356,
          "to_latitude": 21.016584054992695,
          "to_longitude": 105.81162185938388,
          "from_longitude": 105.81195431331415
        }
      },
      {
        "id": 6446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81125609199013,
            21.01642513134793,
            105.81145649993177,
            21.016538959076257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81125609199013,
              21.016538959076257
            ],
            [
              105.81145649993177,
              21.01642513134793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6454",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016538959076257,
          "to_latitude": 21.01642513134793,
          "to_longitude": 105.81145649993177,
          "from_longitude": 105.81125609199013
        }
      },
      {
        "id": 6447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118781181099,
            21.016810089906595,
            105.81188846940113,
            21.016826040292568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8118781181099,
              21.016810089906595
            ],
            [
              105.81188228054847,
              21.016816503737193
            ],
            [
              105.8118874353515,
              21.016824449223048
            ],
            [
              105.81188846940113,
              21.016826040292568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_33_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6455",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016810089906595,
          "to_latitude": 21.016826040292568,
          "to_longitude": 105.81188846940113,
          "from_longitude": 105.8118781181099
        }
      },
      {
        "id": 6448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81208864496223,
            21.01641383327369,
            105.8122064471776,
            21.016605940334717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122064471776,
              21.016605940334717
            ],
            [
              105.81208864496223,
              21.01641383327369
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6456",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016605940334717,
          "to_latitude": 21.01641383327369,
          "to_longitude": 105.81208864496223,
          "from_longitude": 105.8122064471776
        }
      },
      {
        "id": 6449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81194880358727,
            21.01618540952477,
            105.81206360815695,
            21.016366704911842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81206360815695,
              21.016366704911842
            ],
            [
              105.81194880358727,
              21.01618540952477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6457",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016366704911842,
          "to_latitude": 21.01618540952477,
          "to_longitude": 105.81194880358727,
          "from_longitude": 105.81206360815695
        }
      },
      {
        "id": 6450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81175843492089,
            21.0163988417009,
            105.81211906420292,
            21.01659796340535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81175843492089,
              21.01659796340535
            ],
            [
              105.81204839872964,
              21.016433668793955
            ],
            [
              105.81208864496223,
              21.01641383327369
            ],
            [
              105.81211676455766,
              21.016399975391437
            ],
            [
              105.81211906420292,
              21.0163988417009
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_22_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6458",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01659796340535,
          "to_latitude": 21.0163988417009,
          "to_longitude": 105.81211906420292,
          "from_longitude": 105.81175843492089
        }
      },
      {
        "id": 6451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81172797264665,
            21.01634576302475,
            105.8120869950981,
            21.016557382189834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81172797264665,
              21.016557382189834
            ],
            [
              105.81206360815695,
              21.016366704911842
            ],
            [
              105.8120863077548,
              21.01634637869456
            ],
            [
              105.8120869950981,
              21.01634576302475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_21_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6459",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016557382189834,
          "to_latitude": 21.01634576302475,
          "to_longitude": 105.8120869950981,
          "from_longitude": 105.81172797264665
        }
      },
      {
        "id": 6452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81103233442724,
            21.016259143329968,
            105.81135175805383,
            21.016438427194547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81103233442724,
              21.016438427194547
            ],
            [
              105.81135175805383,
              21.016259143329968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6460",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016438427194547,
          "to_latitude": 21.016259143329968,
          "to_longitude": 105.81135175805383,
          "from_longitude": 105.81103233442724
        }
      },
      {
        "id": 6453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8125242512233,
            21.014671170842853,
            105.81262836014648,
            21.014749280187328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8125242512233,
              21.014687364016893
            ],
            [
              105.81255226161484,
              21.014671170842853
            ],
            [
              105.81255554029507,
              21.014676928620172
            ],
            [
              105.81258832749334,
              21.014726231008705
            ],
            [
              105.81260749193316,
              21.014749280187328
            ],
            [
              105.81262665157159,
              21.014737705029255
            ],
            [
              105.81262836014648,
              21.01473667260749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6461",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014687364016893,
          "to_latitude": 21.01473667260749,
          "to_longitude": 105.81262836014648,
          "from_longitude": 105.8125242512233
        }
      },
      {
        "id": 6454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81124878231381,
            21.013955207439512,
            105.81202891380596,
            21.0150446713245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81124878231381,
              21.013955207439512
            ],
            [
              105.81125160886901,
              21.013959551137443
            ],
            [
              105.81145449449369,
              21.014271125133135
            ],
            [
              105.81148210685262,
              21.014354717311704
            ],
            [
              105.81148454485464,
              21.014388232626775
            ],
            [
              105.81192107641931,
              21.0150446713245
            ],
            [
              105.81200770758294,
              21.014996353347048
            ],
            [
              105.81198106438163,
              21.014956288645934
            ],
            [
              105.81202891380596,
              21.014928358255577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_43_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6462",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 150,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013955207439512,
          "to_latitude": 21.014928358255577,
          "to_longitude": 105.81202891380596,
          "from_longitude": 105.81124878231381
        }
      },
      {
        "id": 6455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81107000875308,
            21.01520057880327,
            105.8118169421321,
            21.015905297351257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8118169421321,
              21.015905297351257
            ],
            [
              105.81181279189283,
              21.01589917700865
            ],
            [
              105.81179687352106,
              21.015875688640875
            ],
            [
              105.81173095404452,
              21.01586671708398
            ],
            [
              105.81171965455432,
              21.015849735205453
            ],
            [
              105.81156628512088,
              21.015619097699204
            ],
            [
              105.81155661686887,
              21.015604558485727
            ],
            [
              105.81155534833276,
              21.015602651498988
            ],
            [
              105.81148902744522,
              21.015502922649635
            ],
            [
              105.8114761151334,
              21.015483506115636
            ],
            [
              105.81139437698555,
              21.01536059172229
            ],
            [
              105.8113847698713,
              21.015346145238645
            ],
            [
              105.81137902835363,
              21.015345133004768
            ],
            [
              105.81114261413349,
              21.015303435555346
            ],
            [
              105.81107000875308,
              21.01520057880327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_37_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6463",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015905297351257,
          "to_latitude": 21.01520057880327,
          "to_longitude": 105.81107000875308,
          "from_longitude": 105.8118169421321
        }
      },
      {
        "id": 6456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81156628512088,
            21.015233955263007,
            105.81207128335573,
            21.015619097699204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81156628512088,
              21.015619097699204
            ],
            [
              105.8120262294102,
              21.01534990959381
            ],
            [
              105.81207128335573,
              21.015323541568915
            ],
            [
              105.81201480585032,
              21.015233955263007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6464",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015619097699204,
          "to_latitude": 21.015233955263007,
          "to_longitude": 105.81201480585032,
          "from_longitude": 105.81156628512088
        }
      },
      {
        "id": 6457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80934413666746,
            21.015352440387886,
            105.80956295938321,
            21.01540632039598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80934413666746,
              21.01540632039598
            ],
            [
              105.80935640848976,
              21.015402642778103
            ],
            [
              105.8094037641791,
              21.015388448150716
            ],
            [
              105.80956295938321,
              21.015352440387886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6465",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01540632039598,
          "to_latitude": 21.015352440387886,
          "to_longitude": 105.80956295938321,
          "from_longitude": 105.80934413666746
        }
      },
      {
        "id": 6458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80984674384246,
            21.018931510564894,
            105.80987838641295,
            21.01897635966134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80987838641295,
              21.01897635966134
            ],
            [
              105.80986958750525,
              21.018959111050975
            ],
            [
              105.80984674384246,
              21.018931510564894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6466",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01897635966134,
          "to_latitude": 21.018931510564894,
          "to_longitude": 105.80984674384246,
          "from_longitude": 105.80987838641295
        }
      },
      {
        "id": 6459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81086387386674,
            21.01762918402054,
            105.81088480555083,
            21.017667535583325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81086387386674,
              21.01762918402054
            ],
            [
              105.81086660692827,
              21.017634193846376
            ],
            [
              105.81087592727968,
              21.01765127101753
            ],
            [
              105.81087696686704,
              21.01765317548703
            ],
            [
              105.81088480555083,
              21.017667535583325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6467",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01762918402054,
          "to_latitude": 21.017667535583325,
          "to_longitude": 105.81088480555083,
          "from_longitude": 105.81086387386674
        }
      },
      {
        "id": 6460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81069156414628,
            21.016955448952945,
            105.81072337567355,
            21.017011302940737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81069156414628,
              21.016955448952945
            ],
            [
              105.81069374606686,
              21.016958337015453
            ],
            [
              105.81069809921,
              21.016964091513845
            ],
            [
              105.81072276638527,
              21.017010165970024
            ],
            [
              105.81072337567355,
              21.017011302940737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6468",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016955448952945,
          "to_latitude": 21.017011302940737,
          "to_longitude": 105.81072337567355,
          "from_longitude": 105.81069156414628
        }
      },
      {
        "id": 6461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8093026986983,
            21.0176859781146,
            105.80943610985669,
            21.01798711822623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80943610985669,
              21.0176859781146
            ],
            [
              105.80932415709432,
              21.01774346204054
            ],
            [
              105.80931849628313,
              21.017746368464625
            ],
            [
              105.8093026986983,
              21.017759314628425
            ],
            [
              105.80942830987229,
              21.01798711822623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6469",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0176859781146,
          "to_latitude": 21.01798711822623,
          "to_longitude": 105.80942830987229,
          "from_longitude": 105.80943610985669
        }
      },
      {
        "id": 6462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80980743253392,
            21.018131124769486,
            105.80981248078339,
            21.018140191732655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80981248078339,
              21.018140191732655
            ],
            [
              105.80981118010982,
              21.018137855852903
            ],
            [
              105.80981063323574,
              21.018136875743643
            ],
            [
              105.80980792210778,
              21.01813200398994
            ],
            [
              105.80980743253392,
              21.018131124769486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6470",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018140191732655,
          "to_latitude": 21.018131124769486,
          "to_longitude": 105.80980743253392,
          "from_longitude": 105.80981248078339
        }
      },
      {
        "id": 6463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80968438219058,
            21.0181002705645,
            105.80998764632535,
            21.018253595765557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80968438219058,
              21.018253595765557
            ],
            [
              105.8098548192628,
              21.018169737105083
            ],
            [
              105.80988597564648,
              21.018153442663543
            ],
            [
              105.80998764632535,
              21.0181002705645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6471",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018253595765557,
          "to_latitude": 21.0181002705645,
          "to_longitude": 105.80998764632535,
          "from_longitude": 105.80968438219058
        }
      },
      {
        "id": 6464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81076620984803,
            21.016990392292577,
            105.81092174241353,
            21.01727945904678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81092174241353,
              21.01727945904678
            ],
            [
              105.81076620984803,
              21.016990392292577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6472",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01727945904678,
          "to_latitude": 21.016990392292577,
          "to_longitude": 105.81076620984803,
          "from_longitude": 105.81092174241353
        }
      },
      {
        "id": 6465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8105733569651,
            21.017821387277014,
            105.81078313003746,
            21.01819871474497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8105733569651,
              21.017821387277014
            ],
            [
              105.81078313003746,
              21.01819871474497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_43_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6473",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017821387277014,
          "to_latitude": 21.01819871474497,
          "to_longitude": 105.81078313003746,
          "from_longitude": 105.8105733569651
        }
      },
      {
        "id": 6466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80976242139677,
            21.017507149394437,
            105.8097717268071,
            21.01752344360198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8097717268071,
              21.01752344360198
            ],
            [
              105.8097711538416,
              21.017522441035933
            ],
            [
              105.80976820158753,
              21.01751727055683
            ],
            [
              105.80976520273688,
              21.017512021718552
            ],
            [
              105.80976242139677,
              21.017507149394437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6474",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01752344360198,
          "to_latitude": 21.017507149394437,
          "to_longitude": 105.80976242139677,
          "from_longitude": 105.8097717268071
        }
      },
      {
        "id": 6467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80958679305357,
            21.017437510243074,
            105.80994802645378,
            21.017613586272986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80958679305357,
              21.017613586272986
            ],
            [
              105.8097717268071,
              21.01752344360198
            ],
            [
              105.8097804739161,
              21.017519180274356
            ],
            [
              105.80994802645378,
              21.017437510243074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6475",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017613586272986,
          "to_latitude": 21.017437510243074,
          "to_longitude": 105.80994802645378,
          "from_longitude": 105.80958679305357
        }
      },
      {
        "id": 6468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81048458123894,
            21.01860251029929,
            105.8105207668462,
            21.018664547742052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81048458123894,
              21.01860251029929
            ],
            [
              105.8105059235513,
              21.018641896853065
            ],
            [
              105.81051362504496,
              21.018653651804286
            ],
            [
              105.8105207668462,
              21.018664547742052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6476",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01860251029929,
          "to_latitude": 21.018664547742052,
          "to_longitude": 105.8105207668462,
          "from_longitude": 105.81048458123894
        }
      },
      {
        "id": 6469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.810405649734,
            21.01750841917889,
            105.81055275088907,
            21.017785603808388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.810405649734,
              21.01750841917889
            ],
            [
              105.81054672531712,
              21.017765333362817
            ],
            [
              105.81054746267064,
              21.017766671143765
            ],
            [
              105.81055121787959,
              21.01778317600892
            ],
            [
              105.81055275088907,
              21.017785603808388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6477",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01750841917889,
          "to_latitude": 21.017785603808388,
          "to_longitude": 105.81055275088907,
          "from_longitude": 105.810405649734
        }
      },
      {
        "id": 6470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81035898016415,
            21.017765333362817,
            105.81054672531712,
            21.01785960717708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81035898016415,
              21.01785960717708
            ],
            [
              105.8104604715349,
              21.01780892495546
            ],
            [
              105.81054672531712,
              21.017765333362817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6478",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01785960717708,
          "to_latitude": 21.017765333362817,
          "to_longitude": 105.81054672531712,
          "from_longitude": 105.81035898016415
        }
      },
      {
        "id": 6471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81019516997773,
            21.01771883000739,
            105.81022604794774,
            21.0177339300177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81022604794774,
              21.01771883000739
            ],
            [
              105.81021731914674,
              21.01772309868998
            ],
            [
              105.81019843584318,
              21.017732332910526
            ],
            [
              105.81019516997773,
              21.0177339300177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6479",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01771883000739,
          "to_latitude": 21.0177339300177,
          "to_longitude": 105.81019516997773,
          "from_longitude": 105.81022604794774
        }
      },
      {
        "id": 6472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81016335577313,
            21.01760456217923,
            105.81029521829258,
            21.017844898341092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81029521829258,
              21.017844898341092
            ],
            [
              105.81023076176028,
              21.017727420743235
            ],
            [
              105.81022604794774,
              21.01771883000739
            ],
            [
              105.81016335577313,
              21.01760456217923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6480",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017844898341092,
          "to_latitude": 21.01760456217923,
          "to_longitude": 105.81016335577313,
          "from_longitude": 105.81029521829258
        }
      },
      {
        "id": 6473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81011205250428,
            21.017334483961037,
            105.81059382098451,
            21.017571371688366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81011205250428,
              21.017571371688366
            ],
            [
              105.81014131263832,
              21.017553992423508
            ],
            [
              105.81059382098451,
              21.017334483961037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6481",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017571371688366,
          "to_latitude": 21.017334483961037,
          "to_longitude": 105.81059382098451,
          "from_longitude": 105.81011205250428
        }
      },
      {
        "id": 6474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81014301355387,
            21.01722136138292,
            105.81017732704764,
            21.01728203673622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81017732704764,
              21.01728203673622
            ],
            [
              105.81015706139203,
              21.017246201010742
            ],
            [
              105.81014427033722,
              21.017223586372367
            ],
            [
              105.81014301355387,
              21.01722136138292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6482",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01728203673622,
          "to_latitude": 21.01722136138292,
          "to_longitude": 105.81014301355387,
          "from_longitude": 105.81017732704764
        }
      },
      {
        "id": 6475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81002717877011,
            21.01712296922783,
            105.81048999827486,
            21.01753798291434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81048999827486,
              21.01712296922783
            ],
            [
              105.81022789734507,
              21.017256309491746
            ],
            [
              105.81017732704764,
              21.01728203673622
            ],
            [
              105.8100363603867,
              21.017353751463837
            ],
            [
              105.81002717877011,
              21.0173584224228
            ],
            [
              105.81003400795565,
              21.017370587103503
            ],
            [
              105.81012799064544,
              21.01753798291434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6483",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01712296922783,
          "to_latitude": 21.01753798291434,
          "to_longitude": 105.81012799064544,
          "from_longitude": 105.81048999827486
        }
      },
      {
        "id": 6476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81049222561897,
            21.017351753675374,
            105.81090615719745,
            21.017556146092918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81090615719745,
              21.017351753675374
            ],
            [
              105.81071734781393,
              21.017443518354096
            ],
            [
              105.81049222561897,
              21.017556146092918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_51_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6484",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017351753675374,
          "to_latitude": 21.017556146092918,
          "to_longitude": 105.81049222561897,
          "from_longitude": 105.81090615719745
        }
      },
      {
        "id": 6477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81052298100073,
            21.017039011449757,
            105.81071734781393,
            21.017443518354096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81052298100073,
              21.017039011449757
            ],
            [
              105.81052475227423,
              21.01704227540754
            ],
            [
              105.81056622869886,
              21.017164206928708
            ],
            [
              105.81071734781393,
              21.017443518354096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_51_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6485",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017039011449757,
          "to_latitude": 21.017443518354096,
          "to_longitude": 105.81071734781393,
          "from_longitude": 105.81052298100073
        }
      },
      {
        "id": 6478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8110944476397,
            21.017643726552247,
            105.81146404137934,
            21.01783306527398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110944476397,
              21.01783306527398
            ],
            [
              105.81142542483099,
              21.017668997013708
            ],
            [
              105.81145935402519,
              21.017646794596928
            ],
            [
              105.81146404137934,
              21.017643726552247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6486",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01783306527398,
          "to_latitude": 21.017643726552247,
          "to_longitude": 105.81146404137934,
          "from_longitude": 105.8110944476397
        }
      },
      {
        "id": 6479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81112437734528,
            21.017498213241897,
            105.81114938222507,
            21.017542656593175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81114938222507,
              21.017542656593175
            ],
            [
              105.81114144678422,
              21.017528551681124
            ],
            [
              105.81112895213063,
              21.01750634667795
            ],
            [
              105.81112437734528,
              21.017498213241897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6487",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017542656593175,
          "to_latitude": 21.017498213241897,
          "to_longitude": 105.81112437734528,
          "from_longitude": 105.81114938222507
        }
      },
      {
        "id": 6480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81098701606204,
            21.017469982877127,
            105.81129484236398,
            21.017623775582145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81098701606204,
              21.017623775582145
            ],
            [
              105.8111369086157,
              21.017548888731845
            ],
            [
              105.81114938222507,
              21.017542656593175
            ],
            [
              105.81129484236398,
              21.017469982877127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6488",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017623775582145,
          "to_latitude": 21.017469982877127,
          "to_longitude": 105.81129484236398,
          "from_longitude": 105.81098701606204
        }
      },
      {
        "id": 6481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81073329692387,
            21.017846408032383,
            105.81101955944793,
            21.017987511006428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81101955944793,
              21.017846408032383
            ],
            [
              105.81100347898156,
              21.017854334652533
            ],
            [
              105.81073329692387,
              21.017987511006428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6489",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017846408032383,
          "to_latitude": 21.017987511006428,
          "to_longitude": 105.81073329692387,
          "from_longitude": 105.81101955944793
        }
      },
      {
        "id": 6482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81077280361525,
            21.017887850223975,
            105.81104185850225,
            21.018019206681114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81077280361525,
              21.018019206681114
            ],
            [
              105.81102589563227,
              21.017895643511068
            ],
            [
              105.81104185850225,
              21.017887850223975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6490",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018019206681114,
          "to_latitude": 21.017887850223975,
          "to_longitude": 105.81104185850225,
          "from_longitude": 105.81077280361525
        }
      },
      {
        "id": 6483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81091543872775,
            21.017652900876264,
            105.81114152711238,
            21.018073081462116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81091543872775,
              21.017652900876264
            ],
            [
              105.81101955944793,
              21.017846408032383
            ],
            [
              105.81104185850225,
              21.017887850223975
            ],
            [
              105.81114152711238,
              21.018073081462116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_46_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6491",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017652900876264,
          "to_latitude": 21.018073081462116,
          "to_longitude": 105.81114152711238,
          "from_longitude": 105.81091543872775
        }
      },
      {
        "id": 6484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81079381595829,
            21.017646840956612,
            105.81080333846383,
            21.01766376813577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81079381595829,
              21.017646840956612
            ],
            [
              105.81079875106845,
              21.017655613073366
            ],
            [
              105.81080258767089,
              21.01766243583938
            ],
            [
              105.81080333846383,
              21.01766376813577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6492",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017646840956612,
          "to_latitude": 21.01766376813577,
          "to_longitude": 105.81080333846383,
          "from_longitude": 105.81079381595829
        }
      },
      {
        "id": 6485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81061213354579,
            21.017534301405767,
            105.81102271322872,
            21.017736167165285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81061213354579,
              21.017736167165285
            ],
            [
              105.81079381595829,
              21.017646840956612
            ],
            [
              105.81079954839667,
              21.01764402265928
            ],
            [
              105.81102271322872,
              21.017534301405767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6493",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017736167165285,
          "to_latitude": 21.017534301405767,
          "to_longitude": 105.81102271322872,
          "from_longitude": 105.81061213354579
        }
      },
      {
        "id": 6486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80953142422267,
            21.017619339588723,
            105.80967110310249,
            21.017893266264952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80967110310249,
              21.017893266264952
            ],
            [
              105.80953470907073,
              21.01762428810156
            ],
            [
              105.80953142422267,
              21.017619339588723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6494",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017893266264952,
          "to_latitude": 21.017619339588723,
          "to_longitude": 105.80953142422267,
          "from_longitude": 105.80967110310249
        }
      },
      {
        "id": 6487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80967924996514,
            21.01756513063015,
            105.81010839607674,
            21.017789395946274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80967924996514,
              21.017789395946274
            ],
            [
              105.81001807510256,
              21.01762278747495
            ],
            [
              105.81007110121949,
              21.017589188686017
            ],
            [
              105.81010443242155,
              21.017568439063613
            ],
            [
              105.81010839607674,
              21.01756513063015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6495",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017789395946274,
          "to_latitude": 21.01756513063015,
          "to_longitude": 105.81010839607674,
          "from_longitude": 105.80967924996514
        }
      },
      {
        "id": 6488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81024451938274,
            21.01786352813856,
            105.81027231378297,
            21.017877123143933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81024451938274,
              21.017877123143933
            ],
            [
              105.8102630793649,
              21.017868044910067
            ],
            [
              105.81027114041117,
              21.01786410185815
            ],
            [
              105.81027231378297,
              21.01786352813856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6496",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017877123143933,
          "to_latitude": 21.01786352813856,
          "to_longitude": 105.81027231378297,
          "from_longitude": 105.81024451938274
        }
      },
      {
        "id": 6489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81004611212505,
            21.017636390622386,
            105.81025326560841,
            21.01801424417573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81004611212505,
              21.01801424417573
            ],
            [
              105.81024294031062,
              21.01791655929716
            ],
            [
              105.81025326560841,
              21.01789281572589
            ],
            [
              105.81024451938274,
              21.017877123143933
            ],
            [
              105.81023361672555,
              21.01785756327244
            ],
            [
              105.81011033934911,
              21.017636390622386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6497",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01801424417573,
          "to_latitude": 21.017636390622386,
          "to_longitude": 105.81011033934911,
          "from_longitude": 105.81004611212505
        }
      },
      {
        "id": 6490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80950406540087,
            21.017828486112737,
            105.80990347631669,
            21.018021787666786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80950406540087,
              21.018021787666786
            ],
            [
              105.80990347631669,
              21.017828486112737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6498",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018021787666786,
          "to_latitude": 21.017828486112737,
          "to_longitude": 105.80990347631669,
          "from_longitude": 105.80950406540087
        }
      },
      {
        "id": 6491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8098582792576,
            21.01774643613396,
            105.80999653141119,
            21.01804620796882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80999653141119,
              21.01804620796882
            ],
            [
              105.80999637696158,
              21.01804593050985
            ],
            [
              105.80998555940819,
              21.01802627266838
            ],
            [
              105.8099843923573,
              21.018008068235496
            ],
            [
              105.80990347631669,
              21.017828486112737
            ],
            [
              105.8098582792576,
              21.01774643613396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_36_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6499",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01804620796882,
          "to_latitude": 21.01774643613396,
          "to_longitude": 105.8098582792576,
          "from_longitude": 105.80999653141119
        }
      },
      {
        "id": 6492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80986269961444,
            21.018111127930148,
            105.80988597564648,
            21.018153442663543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80986269961444,
              21.018111127930148
            ],
            [
              105.80986344767673,
              21.018112490050342
            ],
            [
              105.80987417101615,
              21.018131982154145
            ],
            [
              105.80988597564648,
              21.018153442663543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6500",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018111127930148,
          "to_latitude": 21.018153442663543,
          "to_longitude": 105.80988597564648,
          "from_longitude": 105.80986269961444
        }
      },
      {
        "id": 6493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80965458445999,
            21.01805615823359,
            105.80995795970627,
            21.018207244806174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80965458445999,
              21.018207244806174
            ],
            [
              105.8097985333505,
              21.0181355568236
            ],
            [
              105.80980743253392,
              21.018131124769486
            ],
            [
              105.80995795970627,
              21.01805615823359
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6501",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018207244806174,
          "to_latitude": 21.01805615823359,
          "to_longitude": 105.80995795970627,
          "from_longitude": 105.80965458445999
        }
      },
      {
        "id": 6494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80976886057006,
            21.01828324892335,
            105.81013036272522,
            21.018458516457276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81013036272522,
              21.01828324892335
            ],
            [
              105.81012657579564,
              21.018285085156794
            ],
            [
              105.80976886057006,
              21.018458516457276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6502",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01828324892335,
          "to_latitude": 21.018458516457276,
          "to_longitude": 105.80976886057006,
          "from_longitude": 105.81013036272522
        }
      },
      {
        "id": 6495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80999902851674,
            21.01804458020304,
            105.81022604291731,
            21.018461486139188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80999902851674,
              21.01804458020304
            ],
            [
              105.80999918014395,
              21.018044869417405
            ],
            [
              105.81000263910839,
              21.018051416708886
            ],
            [
              105.81013036272522,
              21.01828324892335
            ],
            [
              105.81022604291731,
              21.018461486139188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_37_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6503",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01804458020304,
          "to_latitude": 21.018461486139188,
          "to_longitude": 105.81022604291731,
          "from_longitude": 105.80999902851674
        }
      },
      {
        "id": 6496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8111247042178,
            21.018339128987883,
            105.81114331434158,
            21.01837347040855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8111247042178,
              21.018339128987883
            ],
            [
              105.81112797966848,
              21.01834517312835
            ],
            [
              105.81113491438866,
              21.018357972226013
            ],
            [
              105.81113875241753,
              21.018365052396213
            ],
            [
              105.811141851029,
              21.018370770410968
            ],
            [
              105.81114331434158,
              21.01837347040855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6504",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018339128987883,
          "to_latitude": 21.01837347040855,
          "to_longitude": 105.81114331434158,
          "from_longitude": 105.8111247042178
        }
      },
      {
        "id": 6497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81082466623046,
            21.01848639460599,
            105.81084188798457,
            21.01851838344575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81082466623046,
              21.01848639460599
            ],
            [
              105.8108251200245,
              21.018487237864704
            ],
            [
              105.81083166164157,
              21.01849939123407
            ],
            [
              105.81083574283933,
              21.018506968834252
            ],
            [
              105.81083820517384,
              21.018511542787557
            ],
            [
              105.81084188798457,
              21.01851838344575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6505",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01848639460599,
          "to_latitude": 21.01851838344575,
          "to_longitude": 105.81084188798457,
          "from_longitude": 105.81082466623046
        }
      },
      {
        "id": 6498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81096979285851,
            21.01828999319234,
            105.81122645795561,
            21.01841393318796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81096979285851,
              21.01841393318796
            ],
            [
              105.8110981244995,
              21.01835196324165
            ],
            [
              105.8111247042178,
              21.018339128987883
            ],
            [
              105.81118074199021,
              21.018312069319737
            ],
            [
              105.81120964356322,
              21.018298113010445
            ],
            [
              105.81122645795561,
              21.01828999319234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6506",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01841393318796,
          "to_latitude": 21.01828999319234,
          "to_longitude": 105.81122645795561,
          "from_longitude": 105.81096979285851
        }
      },
      {
        "id": 6499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81069773520635,
            21.01842120848051,
            105.8109573325065,
            21.018548764129115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81069773520635,
              21.018548764129115
            ],
            [
              105.81082466623046,
              21.01848639460599
            ],
            [
              105.81082753486996,
              21.018484985444985
            ],
            [
              105.8109573325065,
              21.01842120848051
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6507",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018548764129115,
          "to_latitude": 21.01842120848051,
          "to_longitude": 105.8109573325065,
          "from_longitude": 105.81069773520635
        }
      },
      {
        "id": 6500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81047211568833,
            21.018225245181664,
            105.81051457081091,
            21.018246412291614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81047211568833,
              21.018246412291614
            ],
            [
              105.81047345305495,
              21.018245745659993
            ],
            [
              105.81049375529578,
              21.01823562219353
            ],
            [
              105.81049561935762,
              21.01823469292255
            ],
            [
              105.81051457081091,
              21.018225245181664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6508",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018246412291614,
          "to_latitude": 21.018225245181664,
          "to_longitude": 105.81051457081091,
          "from_longitude": 105.81047211568833
        }
      },
      {
        "id": 6501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81036880687311,
            21.017970761335995,
            105.81056280541179,
            21.01830945546799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81056280541179,
              21.01830945546799
            ],
            [
              105.81051457081091,
              21.018225245181664
            ],
            [
              105.81045944909172,
              21.018129011398674
            ],
            [
              105.81036880687311,
              21.017970761335995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6509",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01830945546799,
          "to_latitude": 21.017970761335995,
          "to_longitude": 105.81036880687311,
          "from_longitude": 105.81056280541179
        }
      },
      {
        "id": 6502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81044159558056,
            21.018210436004285,
            105.8104559585475,
            21.01821756045253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8104559585475,
              21.018210436004285
            ],
            [
              105.81045492049391,
              21.018210951273883
            ],
            [
              105.81045101099451,
              21.018212890163166
            ],
            [
              105.81044159558056,
              21.01821756045253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6510",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018210436004285,
          "to_latitude": 21.01821756045253,
          "to_longitude": 105.81044159558056,
          "from_longitude": 105.8104559585475
        }
      },
      {
        "id": 6503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81031486557387,
            21.017986856397368,
            105.81049802605267,
            21.01832028853886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81049802605267,
              21.01832028853886
            ],
            [
              105.81044159558056,
              21.01821756045253
            ],
            [
              105.81040012746716,
              21.01814207017689
            ],
            [
              105.81031486557387,
              21.017986856397368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6511",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01832028853886,
          "to_latitude": 21.017986856397368,
          "to_longitude": 105.81031486557387,
          "from_longitude": 105.81049802605267
        }
      },
      {
        "id": 6504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80996899921735,
            21.01887960434126,
            105.80999207706535,
            21.018921173929257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80996899921735,
              21.01887960434126
            ],
            [
              105.80998339653618,
              21.018905540636872
            ],
            [
              105.80999207706535,
              21.018921173929257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6512",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01887960434126,
          "to_latitude": 21.018921173929257,
          "to_longitude": 105.80999207706535,
          "from_longitude": 105.80996899921735
        }
      },
      {
        "id": 6505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80894878681266,
            21.01791304295268,
            105.80895553715635,
            21.01792379901582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80895553715635,
              21.01792379901582
            ],
            [
              105.80895308133569,
              21.017919885262287
            ],
            [
              105.80894949998995,
              21.01791418124234
            ],
            [
              105.80894878681266,
              21.01791304295268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6513",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01792379901582,
          "to_latitude": 21.01791304295268,
          "to_longitude": 105.80894878681266,
          "from_longitude": 105.80895553715635
        }
      },
      {
        "id": 6506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80890139448292,
            21.01779128177657,
            105.80922008919248,
            21.017950919399627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80890139448292,
              21.017950919399627
            ],
            [
              105.80895553715635,
              21.01792379901582
            ],
            [
              105.80907279056666,
              21.01786506533801
            ],
            [
              105.80922008919248,
              21.01779128177657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6514",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017950919399627,
          "to_latitude": 21.01779128177657,
          "to_longitude": 105.80922008919248,
          "from_longitude": 105.80890139448292
        }
      },
      {
        "id": 6507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80886962565943,
            21.019417855919553,
            105.8088898374845,
            21.0194561939889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80886962565943,
              21.019417855919553
            ],
            [
              105.80888274375646,
              21.019445100848994
            ],
            [
              105.8088898374845,
              21.0194561939889
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6515",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019417855919553,
          "to_latitude": 21.0194561939889,
          "to_longitude": 105.8088898374845,
          "from_longitude": 105.80886962565943
        }
      },
      {
        "id": 6508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80932107143457,
            21.019197746056903,
            105.80934188348739,
            21.019236774894857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80932107143457,
              21.019197746056903
            ],
            [
              105.80933118204689,
              21.019216709105947
            ],
            [
              105.80933708085261,
              21.01922776817004
            ],
            [
              105.80934129457599,
              21.01923567033871
            ],
            [
              105.80934188348739,
              21.019236774894857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6516",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019197746056903,
          "to_latitude": 21.019236774894857,
          "to_longitude": 105.80934188348739,
          "from_longitude": 105.80932107143457
        }
      },
      {
        "id": 6509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80964733754061,
            21.019042841521863,
            105.80966692209975,
            21.019079002991756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80964733754061,
              21.019042841521863
            ],
            [
              105.80965835371317,
              21.019063111728506
            ],
            [
              105.8096607482644,
              21.019067964216283
            ],
            [
              105.8096656816988,
              21.019076785147337
            ],
            [
              105.80966692209975,
              21.019079002991756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6517",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019042841521863,
          "to_latitude": 21.019079002991756,
          "to_longitude": 105.80966692209975,
          "from_longitude": 105.80964733754061
        }
      },
      {
        "id": 6510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80866248586024,
            21.018057949789206,
            105.80866843083211,
            21.018068133432497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80866843083211,
              21.018068133432497
            ],
            [
              105.80866653267364,
              21.018064881800115
            ],
            [
              105.80866574915258,
              21.018063542423643
            ],
            [
              105.80866248586024,
              21.018057949789206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6518",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018068133432497,
          "to_latitude": 21.018057949789206,
          "to_longitude": 105.80866248586024,
          "from_longitude": 105.80866843083211
        }
      },
      {
        "id": 6511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80809131727578,
            21.018355218625533,
            105.80810935618794,
            21.018384187996354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80810935618794,
              21.018384187996354
            ],
            [
              105.80809753240719,
              21.01836520048905
            ],
            [
              105.80809320269525,
              21.018358249937155
            ],
            [
              105.80809131727578,
              21.018355218625533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6519",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018384187996354,
          "to_latitude": 21.018355218625533,
          "to_longitude": 105.80809131727578,
          "from_longitude": 105.80810935618794
        }
      },
      {
        "id": 6512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80840232863761,
            21.017959777326393,
            105.80888126715064,
            21.018203608051266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80840232863761,
              21.018203608051266
            ],
            [
              105.80864296175172,
              21.018081100221778
            ],
            [
              105.80866843083211,
              21.018068133432497
            ],
            [
              105.80888126715064,
              21.017959777326393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6520",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018203608051266,
          "to_latitude": 21.017959777326393,
          "to_longitude": 105.80888126715064,
          "from_longitude": 105.80840232863761
        }
      },
      {
        "id": 6513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80787719242262,
            21.01824079216759,
            105.80838427302835,
            21.018505131123213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80787719242262,
              21.018505131123213
            ],
            [
              105.80810935618794,
              21.018384187996354
            ],
            [
              105.80814181488125,
              21.01836727890549
            ],
            [
              105.80838427302835,
              21.01824079216759
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6521",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018505131123213,
          "to_latitude": 21.01824079216759,
          "to_longitude": 105.80838427302835,
          "from_longitude": 105.80787719242262
        }
      },
      {
        "id": 6514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8094717193254,
            21.018085361780553,
            105.80967522588762,
            21.018446316053133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80967522588762,
              21.018446316053133
            ],
            [
              105.80957675387651,
              21.01827165954794
            ],
            [
              105.80956510073136,
              21.018250990426907
            ],
            [
              105.8094717193254,
              21.018085361780553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6522",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018446316053133,
          "to_latitude": 21.018085361780553,
          "to_longitude": 105.8094717193254,
          "from_longitude": 105.80967522588762
        }
      },
      {
        "id": 6515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81000348630663,
            21.017043420345196,
            105.8104429253714,
            21.01726819431304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81000348630663,
              21.01726819431304
            ],
            [
              105.8102413075194,
              21.017146548516358
            ],
            [
              105.81024928697987,
              21.017142467660808
            ],
            [
              105.8104429253714,
              21.017043420345196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6523",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01726819431304,
          "to_latitude": 21.017043420345196,
          "to_longitude": 105.8104429253714,
          "from_longitude": 105.81000348630663
        }
      },
      {
        "id": 6516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81024928697987,
            21.017142467660808,
            105.81026462563078,
            21.0171695971387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81024928697987,
              21.017142467660808
            ],
            [
              105.81025127509136,
              21.017145984395988
            ],
            [
              105.81026113018086,
              21.01716341673621
            ],
            [
              105.81026462563078,
              21.0171695971387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6524",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017142467660808,
          "to_latitude": 21.0171695971387,
          "to_longitude": 105.81026462563078,
          "from_longitude": 105.81024928697987
        }
      },
      {
        "id": 6517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8105326139358,
            21.01681389551114,
            105.81093308115558,
            21.017007498879416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8105326139358,
              21.017007498879416
            ],
            [
              105.81074606685945,
              21.016904304937682
            ],
            [
              105.81074933663652,
              21.0169027240599
            ],
            [
              105.81093308115558,
              21.01681389551114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6525",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017007498879416,
          "to_latitude": 21.01681389551114,
          "to_longitude": 105.81093308115558,
          "from_longitude": 105.8105326139358
        }
      },
      {
        "id": 6518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81074933663652,
            21.0169027240599,
            105.81076269002253,
            21.016927031331733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81074933663652,
              21.0169027240599
            ],
            [
              105.81075183244161,
              21.016907267146816
            ],
            [
              105.81075989500978,
              21.016921946830642
            ],
            [
              105.81076269002253,
              21.016927031331733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6526",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0169027240599,
          "to_latitude": 21.016927031331733,
          "to_longitude": 105.81076269002253,
          "from_longitude": 105.81074933663652
        }
      },
      {
        "id": 6519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81019898607636,
            21.01652337513017,
            105.81047455940929,
            21.017064661703543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81047455940929,
              21.017064661703543
            ],
            [
              105.81047164448307,
              21.017060349126037
            ],
            [
              105.81046280608471,
              21.01704219117837
            ],
            [
              105.81046704709703,
              21.017002451499746
            ],
            [
              105.81038830337486,
              21.016861722453708
            ],
            [
              105.81019898607636,
              21.01652337513017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6527",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017064661703543,
          "to_latitude": 21.01652337513017,
          "to_longitude": 105.81019898607636,
          "from_longitude": 105.81047455940929
        }
      },
      {
        "id": 6520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80993554877357,
            21.01666063046887,
            105.81014353308014,
            21.016985563248287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80993554877357,
              21.01666063046887
            ],
            [
              105.80997287604767,
              21.016729539808733
            ],
            [
              105.80999324704774,
              21.016723985511252
            ],
            [
              105.81014353308014,
              21.016985051735606
            ],
            [
              105.81014252483389,
              21.016985563248287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6528",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01666063046887,
          "to_latitude": 21.016985563248287,
          "to_longitude": 105.81014252483389,
          "from_longitude": 105.80993554877357
        }
      },
      {
        "id": 6521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81016231505286,
            21.016861722453708,
            105.81038830337486,
            21.01697595168328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81016231505286,
              21.01697595168328
            ],
            [
              105.81038256083025,
              21.01686462478523
            ],
            [
              105.81038830337486,
              21.016861722453708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6529",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01697595168328,
          "to_latitude": 21.016861722453708,
          "to_longitude": 105.81038830337486,
          "from_longitude": 105.81016231505286
        }
      },
      {
        "id": 6522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80969842349101,
            21.016738255693262,
            105.80989150285714,
            21.017112894347903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80989150285714,
              21.017112894347903
            ],
            [
              105.80969842349101,
              21.01676302200533
            ],
            [
              105.8097110923597,
              21.016738255693262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6530",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017112894347903,
          "to_latitude": 21.016738255693262,
          "to_longitude": 105.8097110923597,
          "from_longitude": 105.80989150285714
        }
      },
      {
        "id": 6523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8097595199527,
            21.016152028180645,
            105.81021564478344,
            21.01637838120609
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81021564478344,
              21.016152028180645
            ],
            [
              105.81018438217004,
              21.01617261532228
            ],
            [
              105.81014783114477,
              21.016180447859853
            ],
            [
              105.8097595199527,
              21.01637838120609
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6531",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016152028180645,
          "to_latitude": 21.01637838120609,
          "to_longitude": 105.8097595199527,
          "from_longitude": 105.81021564478344
        }
      },
      {
        "id": 6524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80988477706785,
            21.01630267798277,
            105.81041659224564,
            21.016598258563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80988477706785,
              21.016598258563
            ],
            [
              105.81036457200322,
              21.016350898059127
            ],
            [
              105.81039908624938,
              21.016318249938486
            ],
            [
              105.81041659224564,
              21.01630267798277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Ngõ 34, Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6532",
          "diaChiLapD": "Ngõ 34, Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016598258563,
          "to_latitude": 21.01630267798277,
          "to_longitude": 105.81041659224564,
          "from_longitude": 105.80988477706785
        }
      },
      {
        "id": 6525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80968889662827,
            21.01581222492117,
            105.81009166003278,
            21.016120373342464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81009166003278,
              21.016120373342464
            ],
            [
              105.80990568959895,
              21.015978090704582
            ],
            [
              105.80968889662827,
              21.01581222492117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6533",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016120373342464,
          "to_latitude": 21.01581222492117,
          "to_longitude": 105.80968889662827,
          "from_longitude": 105.81009166003278
        }
      },
      {
        "id": 6526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81014783114477,
            21.016180447859853,
            105.81037187677897,
            21.01632485931215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81014783114477,
              21.016180447859853
            ],
            [
              105.81037187677897,
              21.01632485931215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6534",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016180447859853,
          "to_latitude": 21.01632485931215,
          "to_longitude": 105.81037187677897,
          "from_longitude": 105.81014783114477
        }
      },
      {
        "id": 6527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80920149928448,
            21.01592310625054,
            105.80977340208896,
            21.01651473969447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80920149928448,
              21.016031821907674
            ],
            [
              105.80920583222264,
              21.016029567613263
            ],
            [
              105.80941194480165,
              21.01592310625054
            ],
            [
              105.8096360142594,
              21.016252832228904
            ],
            [
              105.80970360369435,
              21.01638421213953
            ],
            [
              105.80977340208896,
              21.01651473969447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_57_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6535",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016031821907674,
          "to_latitude": 21.01651473969447,
          "to_longitude": 105.80977340208896,
          "from_longitude": 105.80920149928448
        }
      },
      {
        "id": 6528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80918705829541,
            21.01579466750464,
            105.80961003898709,
            21.016005913733743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80918705829541,
              21.016005913733743
            ],
            [
              105.80919077515556,
              21.01600411398443
            ],
            [
              105.80961003898709,
              21.01579466750464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6536",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016005913733743,
          "to_latitude": 21.01579466750464,
          "to_longitude": 105.80961003898709,
          "from_longitude": 105.80918705829541
        }
      },
      {
        "id": 6529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80908187624443,
            21.01561478943475,
            105.80959619082729,
            21.01576654620551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80908187624443,
              21.01576654620551
            ],
            [
              105.80929063917347,
              21.0156648062123
            ],
            [
              105.80932617507527,
              21.015647487561022
            ],
            [
              105.80939326718642,
              21.01561478943475
            ],
            [
              105.80947421945419,
              21.015642974315167
            ],
            [
              105.80952675586956,
              21.015683432861895
            ],
            [
              105.80959619082729,
              21.015741372454286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6537",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01576654620551,
          "to_latitude": 21.015741372454286,
          "to_longitude": 105.80959619082729,
          "from_longitude": 105.80908187624443
        }
      },
      {
        "id": 6530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80929608953572,
            21.015591709070616,
            105.80932617507527,
            21.015647487561022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80932617507527,
              21.015647487561022
            ],
            [
              105.8093120208861,
              21.015621245643562
            ],
            [
              105.80929869943644,
              21.01559655151059
            ],
            [
              105.80929608953572,
              21.015591709070616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6538",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015647487561022,
          "to_latitude": 21.015591709070616,
          "to_longitude": 105.80929608953572,
          "from_longitude": 105.80932617507527
        }
      },
      {
        "id": 6531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80943100920945,
            21.016422863257088,
            105.80955536054284,
            21.016645385314924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80955536054284,
              21.016645385314924
            ],
            [
              105.80950674553581,
              21.01655839004582
            ],
            [
              105.80948497322395,
              21.01651942990531
            ],
            [
              105.80943100920945,
              21.016422863257088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6539",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016645385314924,
          "to_latitude": 21.016422863257088,
          "to_longitude": 105.80943100920945,
          "from_longitude": 105.80955536054284
        }
      },
      {
        "id": 6532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80922637988506,
            21.016056413193265,
            105.80941800573154,
            21.016421398725697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80939976883538,
              21.016421398725697
            ],
            [
              105.80940236794002,
              21.01642013719455
            ],
            [
              105.80941800573154,
              21.016412545220753
            ],
            [
              105.80936729761972,
              21.01630664083376
            ],
            [
              105.80932411287279,
              21.016229958928594
            ],
            [
              105.80922637988506,
              21.016056413193265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6540",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016421398725697,
          "to_latitude": 21.016056413193265,
          "to_longitude": 105.80922637988506,
          "from_longitude": 105.80939976883538
        }
      },
      {
        "id": 6533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80949433730582,
            21.01655839004582,
            105.80950674553581,
            21.016564614527407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80950674553581,
              21.01655839004582
            ],
            [
              105.80950503405332,
              21.016559248127873
            ],
            [
              105.80949828406698,
              21.01656263393477
            ],
            [
              105.80949615431223,
              21.016563702652835
            ],
            [
              105.80949433730582,
              21.016564614527407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6541",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01655839004582,
          "to_latitude": 21.016564614527407,
          "to_longitude": 105.80949433730582,
          "from_longitude": 105.80950674553581
        }
      },
      {
        "id": 6534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80831211426981,
            21.01613953393173,
            105.80915452380637,
            21.016604378336805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80834382384319,
              21.01613953393173
            ],
            [
              105.80835266767025,
              21.016155008480926
            ],
            [
              105.8083633957881,
              21.01617313047264
            ],
            [
              105.80831211426981,
              21.01620682250716
            ],
            [
              105.8085120406772,
              21.016527785980408
            ],
            [
              105.80857191506102,
              21.016521702687438
            ],
            [
              105.80889331262956,
              21.016604378336805
            ],
            [
              105.80914126083373,
              21.01646983839544
            ],
            [
              105.80915452380637,
              21.016462641612716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6542",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 116.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01613953393173,
          "to_latitude": 21.016462641612716,
          "to_longitude": 105.80915452380637,
          "from_longitude": 105.80834382384319
        }
      },
      {
        "id": 6535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80925058178107,
            21.016184144420635,
            105.80941916160855,
            21.016487340610492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80941916160855,
              21.016487340610492
            ],
            [
              105.80935981466477,
              21.016381338227337
            ],
            [
              105.80925058178107,
              21.016188771029874
            ],
            [
              105.80926864646474,
              21.0161845519242
            ],
            [
              105.80927038634803,
              21.016184144420635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.2_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6543",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016487340610492,
          "to_latitude": 21.016184144420635,
          "to_longitude": 105.80927038634803,
          "from_longitude": 105.80941916160855
        }
      },
      {
        "id": 6536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80836841510616,
            21.018047997471356,
            105.80864454942095,
            21.018193489564123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80836841510616,
              21.018193489564123
            ],
            [
              105.80850343484393,
              21.01812234819005
            ],
            [
              105.80851562093822,
              21.01811592792908
            ],
            [
              105.80864454942095,
              21.018047997471356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6544",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018193489564123,
          "to_latitude": 21.018047997471356,
          "to_longitude": 105.80864454942095,
          "from_longitude": 105.80836841510616
        }
      },
      {
        "id": 6537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80866099639019,
            21.017865756109963,
            105.80900522076186,
            21.01804075578202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80866099639019,
              21.01804075578202
            ],
            [
              105.80880226729535,
              21.01796866587757
            ],
            [
              105.80887004585334,
              21.017934078729375
            ],
            [
              105.80890168408665,
              21.017917933917403
            ],
            [
              105.80900522076186,
              21.017865756109963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6545",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01804075578202,
          "to_latitude": 21.017865756109963,
          "to_longitude": 105.80900522076186,
          "from_longitude": 105.80866099639019
        }
      },
      {
        "id": 6538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80851562093822,
            21.01811592792908,
            105.80852603207816,
            21.018134462377642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80851562093822,
              21.01811592792908
            ],
            [
              105.808522145023,
              21.01812754315243
            ],
            [
              105.80852446735379,
              21.018131677934598
            ],
            [
              105.80852539105271,
              21.01813332464648
            ],
            [
              105.80852603207816,
              21.018134462377642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6546",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01811592792908,
          "to_latitude": 21.018134462377642,
          "to_longitude": 105.80852603207816,
          "from_longitude": 105.80851562093822
        }
      },
      {
        "id": 6539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80887004585334,
            21.017934078729375,
            105.80887788467678,
            21.017952599193308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80887004585334,
              21.017934078729375
            ],
            [
              105.80887479108212,
              21.017945296847447
            ],
            [
              105.8088754663276,
              21.017946893254724
            ],
            [
              105.80887622924793,
              21.017948693374805
            ],
            [
              105.8088775563312,
              21.017951826687572
            ],
            [
              105.80887788467678,
              21.017952599193308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6547",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017934078729375,
          "to_latitude": 21.017952599193308,
          "to_longitude": 105.80887788467678,
          "from_longitude": 105.80887004585334
        }
      },
      {
        "id": 6540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80989441152502,
            21.017111419076656,
            105.80997202282909,
            21.017251813231038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80989441152502,
              21.017111419076656
            ],
            [
              105.80989820208517,
              21.017118276406908
            ],
            [
              105.80997202282909,
              21.017251813231038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6548",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017111419076656,
          "to_latitude": 21.017251813231038,
          "to_longitude": 105.80997202282909,
          "from_longitude": 105.80989441152502
        }
      },
      {
        "id": 6541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80990568959895,
            21.015942905006067,
            105.80993670408084,
            21.015978090704582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80993670408084,
              21.015942905006067
            ],
            [
              105.80990568959895,
              21.015978090704582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6549",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015942905006067,
          "to_latitude": 21.015978090704582,
          "to_longitude": 105.80990568959895,
          "from_longitude": 105.80993670408084
        }
      },
      {
        "id": 6542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80766141471129,
            21.0165877445282,
            105.80814906228555,
            21.016851275074128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80814906228555,
              21.0165877445282
            ],
            [
              105.80814149996269,
              21.016590338396306
            ],
            [
              105.80808050145086,
              21.016633640814863
            ],
            [
              105.80766141471129,
              21.016851275074128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6550",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0165877445282,
          "to_latitude": 21.016851275074128,
          "to_longitude": 105.80766141471129,
          "from_longitude": 105.80814906228555
        }
      },
      {
        "id": 6543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80766811965995,
            21.016671455953873,
            105.80819541941733,
            21.017310287643053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80819541941733,
              21.016671455953873
            ],
            [
              105.80818698811974,
              21.016675594860857
            ],
            [
              105.80766811965995,
              21.016930262230808
            ],
            [
              105.8078804171276,
              21.017310287643053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6551",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016671455953873,
          "to_latitude": 21.017310287643053,
          "to_longitude": 105.8078804171276,
          "from_longitude": 105.80819541941733
        }
      },
      {
        "id": 6544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80816683797114,
            21.01742731703782,
            105.80859470112956,
            21.01763011900336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80859470112956,
              21.01742731703782
            ],
            [
              105.80857571475845,
              21.017437073636376
            ],
            [
              105.80817966090564,
              21.017624065161982
            ],
            [
              105.80816683797114,
              21.01763011900336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6552",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01742731703782,
          "to_latitude": 21.01763011900336,
          "to_longitude": 105.80816683797114,
          "from_longitude": 105.80859470112956
        }
      },
      {
        "id": 6545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80809192415938,
            21.017293358905626,
            105.80851969314334,
            21.017495378937507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80851969314334,
              21.017293358905626
            ],
            [
              105.80849036328549,
              21.017308181186365
            ],
            [
              105.80810288768576,
              21.017490228131173
            ],
            [
              105.80809192415938,
              21.017495378937507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6553",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017293358905626,
          "to_latitude": 21.017495378937507,
          "to_longitude": 105.80809192415938,
          "from_longitude": 105.80851969314334
        }
      },
      {
        "id": 6546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80793300661797,
            21.016948723504285,
            105.80832436508423,
            21.017307699476262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80798757812772,
              21.017307699476262
            ],
            [
              105.8080154731568,
              21.01729383519592
            ],
            [
              105.80800481591969,
              21.01727048676656
            ],
            [
              105.80793300661797,
              21.017143060670378
            ],
            [
              105.80802221115425,
              21.01709876445791
            ],
            [
              105.80823841783808,
              21.01699140297097
            ],
            [
              105.80832436508423,
              21.016948723504285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6554",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017307699476262,
          "to_latitude": 21.016948723504285,
          "to_longitude": 105.80832436508423,
          "from_longitude": 105.80798757812772
        }
      },
      {
        "id": 6547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80792113894577,
            21.016908928735035,
            105.80802221115425,
            21.01709876445791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80802221115425,
              21.01709876445791
            ],
            [
              105.80801818408277,
              21.017091200978573
            ],
            [
              105.80792113894577,
              21.016908928735035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6555",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01709876445791,
          "to_latitude": 21.016908928735035,
          "to_longitude": 105.80792113894577,
          "from_longitude": 105.80802221115425
        }
      },
      {
        "id": 6548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8081171646177,
            21.016768163387436,
            105.80823841783808,
            21.01699140297097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80823841783808,
              21.01699140297097
            ],
            [
              105.8081171646177,
              21.016768163387436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6556",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01699140297097,
          "to_latitude": 21.016768163387436,
          "to_longitude": 105.8081171646177,
          "from_longitude": 105.80823841783808
        }
      },
      {
        "id": 6549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80798973358736,
            21.01711202501663,
            105.80838890131608,
            21.017311576735946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80798973358736,
              21.017311576735946
            ],
            [
              105.80799790813799,
              21.017307804865098
            ],
            [
              105.80807145942687,
              21.017269318475048
            ],
            [
              105.8082016581753,
              21.017204606854307
            ],
            [
              105.80829513937108,
              21.017158145014047
            ],
            [
              105.80838890131608,
              21.01711202501663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6557",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017311576735946,
          "to_latitude": 21.01711202501663,
          "to_longitude": 105.80838890131608,
          "from_longitude": 105.80798973358736
        }
      },
      {
        "id": 6550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81049024834994,
            21.01641442476353,
            105.81058356843964,
            21.016470006061287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81058356843964,
              21.01641442476353
            ],
            [
              105.81057358095654,
              21.01642037314237
            ],
            [
              105.81053008324004,
              21.016446280851724
            ],
            [
              105.81049024834994,
              21.016470006061287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6558",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01641442476353,
          "to_latitude": 21.016470006061287,
          "to_longitude": 105.81049024834994,
          "from_longitude": 105.81058356843964
        }
      },
      {
        "id": 6551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80841370738158,
            21.01914938073817,
            105.80859957635545,
            21.01933955138087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80841370738158,
              21.01914938073817
            ],
            [
              105.80841432659088,
              21.019150396637347
            ],
            [
              105.80841874839982,
              21.019157650226518
            ],
            [
              105.80844324600699,
              21.019194453271847
            ],
            [
              105.80850414305188,
              21.019291858856096
            ],
            [
              105.80853848644546,
              21.01933955138087
            ],
            [
              105.80859957635545,
              21.01930928013244
            ],
            [
              105.80858116459466,
              21.019279709237995
            ],
            [
              105.80854337959549,
              21.01929594824604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6559",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01914938073817,
          "to_latitude": 21.01929594824604,
          "to_longitude": 105.80854337959549,
          "from_longitude": 105.80841370738158
        }
      },
      {
        "id": 6552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80837896830998,
            21.01915028642141,
            105.80853173319889,
            21.019420627847776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8084118604832,
              21.01915028642141
            ],
            [
              105.80841747238003,
              21.01915827951848
            ],
            [
              105.80853173319889,
              21.019344500656587
            ],
            [
              105.80845689311161,
              21.01938836759684
            ],
            [
              105.80840185500573,
              21.019420627847776
            ],
            [
              105.80837896830998,
              21.01938090423043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6560",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01915028642141,
          "to_latitude": 21.01938090423043,
          "to_longitude": 105.80837896830998,
          "from_longitude": 105.8084118604832
        }
      },
      {
        "id": 6553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80841534896541,
            21.019148576289044,
            105.80853263116778,
            21.019247343313147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80841534896541,
              21.019148576289044
            ],
            [
              105.80841610884387,
              21.019149811907468
            ],
            [
              105.80842077682591,
              21.019157405747098
            ],
            [
              105.80843692768087,
              21.019182470359297
            ],
            [
              105.80847880701585,
              21.019163597972064
            ],
            [
              105.80853263116778,
              21.019247343313147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6561",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019148576289044,
          "to_latitude": 21.019247343313147,
          "to_longitude": 105.80853263116778,
          "from_longitude": 105.80841534896541
        }
      },
      {
        "id": 6554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80748322972075,
            21.01665021942662,
            105.8075304116597,
            21.016731213827708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80748322972075,
              21.01665021942662
            ],
            [
              105.80748343840153,
              21.01665056527607
            ],
            [
              105.80748705564662,
              21.016656521160602
            ],
            [
              105.8075304116597,
              21.016731213827708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6562",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01665021942662,
          "to_latitude": 21.016731213827708,
          "to_longitude": 105.8075304116597,
          "from_longitude": 105.80748322972075
        }
      },
      {
        "id": 6555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80800466889957,
            21.016203121024333,
            105.80809858660925,
            21.01634699919683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80806146180396,
              21.01629996104784
            ],
            [
              105.80800466889957,
              21.016206817946497
            ],
            [
              105.80801094587669,
              21.016203121024333
            ],
            [
              105.80806896031639,
              21.016295702835627
            ],
            [
              105.8080779432492,
              21.016310039573334
            ],
            [
              105.80809858660925,
              21.01634699919683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6563",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01629996104784,
          "to_latitude": 21.01634699919683,
          "to_longitude": 105.80809858660925,
          "from_longitude": 105.80806146180396
        }
      },
      {
        "id": 6556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81022821054326,
            21.014309798770988,
            105.81052825408278,
            21.01448745416461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81022821054326,
              21.01448745416461
            ],
            [
              105.81035513283962,
              21.014412303503317
            ],
            [
              105.8103847828961,
              21.014394748027794
            ],
            [
              105.81052825408278,
              21.014309798770988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6564",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01448745416461,
          "to_latitude": 21.014309798770988,
          "to_longitude": 105.81052825408278,
          "from_longitude": 105.81022821054326
        }
      },
      {
        "id": 6557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80947362340606,
            21.013407494930302,
            105.80966849616637,
            21.013806764909912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80966849616637,
              21.013407494930302
            ],
            [
              105.8096381411456,
              21.01342968900587
            ],
            [
              105.80947362340606,
              21.01356564543895
            ],
            [
              105.8096494512207,
              21.013806764909912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.3_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6565",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013407494930302,
          "to_latitude": 21.013806764909912,
          "to_longitude": 105.8096494512207,
          "from_longitude": 105.80966849616637
        }
      },
      {
        "id": 6558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8097467834851,
            21.013620451570397,
            105.80989327079321,
            21.01381200650258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80989327079321,
              21.013620451570397
            ],
            [
              105.8098930976688,
              21.013620633943507
            ],
            [
              105.80981985775456,
              21.01369749508239
            ],
            [
              105.80983186495138,
              21.01373979937028
            ],
            [
              105.8097467834851,
              21.01381200650258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.1_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6566",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013620451570397,
          "to_latitude": 21.01381200650258,
          "to_longitude": 105.8097467834851,
          "from_longitude": 105.80989327079321
        }
      },
      {
        "id": 6559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80991221124027,
            21.014076263369848,
            105.81012339674459,
            21.014228543387233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80991221124027,
              21.014228543387233
            ],
            [
              105.80999561020113,
              21.01416840680323
            ],
            [
              105.8100060066797,
              21.014160910185275
            ],
            [
              105.81003600219223,
              21.01413928136979
            ],
            [
              105.81012339674459,
              21.014076263369848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6567",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014228543387233,
          "to_latitude": 21.014076263369848,
          "to_longitude": 105.81012339674459,
          "from_longitude": 105.80991221124027
        }
      },
      {
        "id": 6560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81002936764702,
            21.01397928226773,
            105.81032569314067,
            21.014188249898027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81002936764702,
              21.014188249898027
            ],
            [
              105.81017392352662,
              21.014086309651617
            ],
            [
              105.81019161818914,
              21.014073831285348
            ],
            [
              105.81032569314067,
              21.01397928226773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6568",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014188249898027,
          "to_latitude": 21.01397928226773,
          "to_longitude": 105.81032569314067,
          "from_longitude": 105.81002936764702
        }
      },
      {
        "id": 6561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80905711719807,
            21.012989588215156,
            105.80935312081603,
            21.013261712732284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80905711719807,
              21.013261712732284
            ],
            [
              105.80917237796643,
              21.013156121778163
            ],
            [
              105.80920211898213,
              21.01312887623458
            ],
            [
              105.80921458705407,
              21.013115070692653
            ],
            [
              105.80930974354854,
              21.013025736743035
            ],
            [
              105.80935312081603,
              21.012989588215156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6569",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013261712732284,
          "to_latitude": 21.012989588215156,
          "to_longitude": 105.80935312081603,
          "from_longitude": 105.80905711719807
        }
      },
      {
        "id": 6562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80717304825086,
            21.013698477672676,
            105.80718830139661,
            21.01371686009267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80718830139661,
              21.01371686009267
            ],
            [
              105.80718025370714,
              21.01370717139485
            ],
            [
              105.80718007362164,
              21.013706954570907
            ],
            [
              105.80717462063848,
              21.013700376027153
            ],
            [
              105.80717304825086,
              21.013698477672676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6570",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01371686009267,
          "to_latitude": 21.013698477672676,
          "to_longitude": 105.80717304825086,
          "from_longitude": 105.80718830139661
        }
      },
      {
        "id": 6563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80932541948073,
            21.014746872127343,
            105.80974275509737,
            21.014998272813298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80932541948073,
              21.014998272813298
            ],
            [
              105.80951223981022,
              21.014885733173106
            ],
            [
              105.80951792560906,
              21.014882308172425
            ],
            [
              105.80974275509737,
              21.014746872127343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6571",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014998272813298,
          "to_latitude": 21.014746872127343,
          "to_longitude": 105.80974275509737,
          "from_longitude": 105.80932541948073
        }
      },
      {
        "id": 6564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80982083448266,
            21.014512660602026,
            105.8101487303127,
            21.01471017624581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80982083448266,
              21.01471017624581
            ],
            [
              105.8099347591591,
              21.01464155126112
            ],
            [
              105.81003661416332,
              21.014580196853373
            ],
            [
              105.8101487303127,
              21.014512660602026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6572",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01471017624581,
          "to_latitude": 21.014512660602026,
          "to_longitude": 105.8101487303127,
          "from_longitude": 105.80982083448266
        }
      },
      {
        "id": 6565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80769061362834,
            21.014413162973476,
            105.8081029767688,
            21.014870537542325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80769061362834,
              21.014413162973476
            ],
            [
              105.8077055807866,
              21.014424676737836
            ],
            [
              105.80784757973012,
              21.01457314557138
            ],
            [
              105.80785679039647,
              21.014599249419014
            ],
            [
              105.80794678076376,
              21.014671832819324
            ],
            [
              105.80796080224727,
              21.014683142404536
            ],
            [
              105.80794276542929,
              21.014719834131206
            ],
            [
              105.80804377382266,
              21.01480114815319
            ],
            [
              105.8081029767688,
              21.014870537542325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_37_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6573",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014413162973476,
          "to_latitude": 21.014870537542325,
          "to_longitude": 105.8081029767688,
          "from_longitude": 105.80769061362834
        }
      },
      {
        "id": 6566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80815568971732,
            21.014516977336452,
            105.8085300052578,
            21.014856772151983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80815568971732,
              21.014516977336452
            ],
            [
              105.80837265238162,
              21.014702318913958
            ],
            [
              105.8084404897434,
              21.014789194318325
            ],
            [
              105.8085300052578,
              21.014856772151983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6574",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014516977336452,
          "to_latitude": 21.014856772151983,
          "to_longitude": 105.8085300052578,
          "from_longitude": 105.80815568971732
        }
      },
      {
        "id": 6567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80827737577519,
            21.013911682831093,
            105.8085089793428,
            21.014173964932898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8085089793428,
              21.014173964932898
            ],
            [
              105.80849121780325,
              21.014155145652918
            ],
            [
              105.80839696369941,
              21.01404194651334
            ],
            [
              105.80834845588227,
              21.013980443979815
            ],
            [
              105.80833851657835,
              21.013969615419175
            ],
            [
              105.80829269353514,
              21.013925247015745
            ],
            [
              105.8082906686998,
              21.013923288498752
            ],
            [
              105.80827737577519,
              21.013911682831093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6575",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014173964932898,
          "to_latitude": 21.013911682831093,
          "to_longitude": 105.80827737577519,
          "from_longitude": 105.8085089793428
        }
      },
      {
        "id": 6568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80904145904128,
            21.013850492054498,
            105.80942764336488,
            21.01417393060864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80904145904128,
              21.013850492054498
            ],
            [
              105.80906323228143,
              21.01387634551604
            ],
            [
              105.80912748075401,
              21.01387121222679
            ],
            [
              105.80915947037761,
              21.01386865660046
            ],
            [
              105.80921669517926,
              21.013921898051773
            ],
            [
              105.80924872468763,
              21.013951697689443
            ],
            [
              105.8093275757089,
              21.01404113791512
            ],
            [
              105.8094164177451,
              21.014162722554538
            ],
            [
              105.80942764336488,
              21.01417393060864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6576",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013850492054498,
          "to_latitude": 21.01417393060864,
          "to_longitude": 105.80942764336488,
          "from_longitude": 105.80904145904128
        }
      },
      {
        "id": 6569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80853742040024,
            21.01438670839383,
            105.8087061458866,
            21.014561339455035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8087061458866,
              21.01438670839383
            ],
            [
              105.80862619454388,
              21.014469459284076
            ],
            [
              105.80853742040024,
              21.014561339455035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6577",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01438670839383,
          "to_latitude": 21.014561339455035,
          "to_longitude": 105.80853742040024,
          "from_longitude": 105.8087061458866
        }
      },
      {
        "id": 6570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80862619454388,
            21.014469459284076,
            105.80879472481844,
            21.014749456767234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80862619454388,
              21.014469459284076
            ],
            [
              105.80863624453147,
              21.01447743399905
            ],
            [
              105.80874563501398,
              21.01465162835385
            ],
            [
              105.8087552425918,
              21.014688517525933
            ],
            [
              105.80879472481844,
              21.014749456767234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6578",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014469459284076,
          "to_latitude": 21.014749456767234,
          "to_longitude": 105.80879472481844,
          "from_longitude": 105.80862619454388
        }
      },
      {
        "id": 6571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80861913215642,
            21.01420598690443,
            105.8088249870918,
            21.01429645914655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80861913215642,
              21.014292286526263
            ],
            [
              105.8087551285724,
              21.01420598690443
            ],
            [
              105.8088249870918,
              21.01429645914655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6579",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014292286526263,
          "to_latitude": 21.01429645914655,
          "to_longitude": 105.8088249870918,
          "from_longitude": 105.80861913215642
        }
      },
      {
        "id": 6572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8085089793428,
            21.01401925756122,
            105.80869817977663,
            21.014173964932898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8085089793428,
              21.014173964932898
            ],
            [
              105.80858353881943,
              21.014103677187485
            ],
            [
              105.80869817977663,
              21.01401925756122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6580",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014173964932898,
          "to_latitude": 21.01401925756122,
          "to_longitude": 105.80869817977663,
          "from_longitude": 105.8085089793428
        }
      },
      {
        "id": 6573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80886235097888,
            21.013850492054498,
            105.80904145904128,
            21.01402271600858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80904145904128,
              21.013850492054498
            ],
            [
              105.80886235097888,
              21.01402271600858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6581",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013850492054498,
          "to_latitude": 21.01402271600858,
          "to_longitude": 105.80886235097888,
          "from_longitude": 105.80904145904128
        }
      },
      {
        "id": 6574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80943835349721,
            21.014057831201576,
            105.80961048793691,
            21.01418462356417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80943835349721,
              21.01418462356417
            ],
            [
              105.80961048793691,
              21.014057831201576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6582",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01418462356417,
          "to_latitude": 21.014057831201576,
          "to_longitude": 105.80961048793691,
          "from_longitude": 105.80943835349721
        }
      },
      {
        "id": 6575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80926937070035,
            21.014174033983064,
            105.80942774781131,
            21.014297984204273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80942774781131,
              21.014174033983064
            ],
            [
              105.80942579851312,
              21.014175559778227
            ],
            [
              105.80926937070035,
              21.014297984204273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6583",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014174033983064,
          "to_latitude": 21.014297984204273,
          "to_longitude": 105.80926937070035,
          "from_longitude": 105.80942774781131
        }
      },
      {
        "id": 6576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80901133249579,
            21.013284917619018,
            105.8092256103325,
            21.013574532460957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80901133249579,
              21.013284917619018
            ],
            [
              105.80901817648065,
              21.013291851683253
            ],
            [
              105.80913525903256,
              21.01343252112501
            ],
            [
              105.8092256103325,
              21.013574532460957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6584",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013284917619018,
          "to_latitude": 21.013574532460957,
          "to_longitude": 105.8092256103325,
          "from_longitude": 105.80901133249579
        }
      },
      {
        "id": 6577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80964079177555,
            21.01424655527975,
            105.80988533013927,
            21.014420179870605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80964079177555,
              21.014420179870605
            ],
            [
              105.80975090357036,
              21.014340215051412
            ],
            [
              105.80976358033807,
              21.014331382558165
            ],
            [
              105.8098384161732,
              21.014279241541203
            ],
            [
              105.80988533013927,
              21.01424655527975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6585",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014420179870605,
          "to_latitude": 21.01424655527975,
          "to_longitude": 105.80988533013927,
          "from_longitude": 105.80964079177555
        }
      },
      {
        "id": 6578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8096502137881,
            21.01422796257776,
            105.80997103602253,
            21.014457760840614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80997103602253,
              21.01422796257776
            ],
            [
              105.80978508443211,
              21.01436115631174
            ],
            [
              105.80975496062206,
              21.014382733308008
            ],
            [
              105.8096502137881,
              21.014457760840614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6586",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01422796257776,
          "to_latitude": 21.014457760840614,
          "to_longitude": 105.8096502137881,
          "from_longitude": 105.80997103602253
        }
      },
      {
        "id": 6579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80912430343882,
            21.014532410861996,
            105.80955208965152,
            21.014853647862928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80955208965152,
              21.014532410861996
            ],
            [
              105.8093395391971,
              21.01467761537403
            ],
            [
              105.80931979577373,
              21.01469211127849
            ],
            [
              105.8091984070464,
              21.01478123130299
            ],
            [
              105.80912430343882,
              21.014853647862928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6587",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014532410861996,
          "to_latitude": 21.014853647862928,
          "to_longitude": 105.80912430343882,
          "from_longitude": 105.80955208965152
        }
      },
      {
        "id": 6580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80774473458247,
            21.014119692994406,
            105.80804422773578,
            21.014378269002325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80774473458247,
              21.014378269002325
            ],
            [
              105.80781618281937,
              21.014316582450228
            ],
            [
              105.80783936785693,
              21.01429721100251
            ],
            [
              105.8078959739407,
              21.014249913783285
            ],
            [
              105.80804422773578,
              21.014119692994406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6588",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014378269002325,
          "to_latitude": 21.014119692994406,
          "to_longitude": 105.80804422773578,
          "from_longitude": 105.80774473458247
        }
      },
      {
        "id": 6581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80783255636086,
            21.014289878413063,
            105.80783936785693,
            21.01429721100251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80783936785693,
              21.01429721100251
            ],
            [
              105.80783870510224,
              21.01429649969503
            ],
            [
              105.80783565636557,
              21.01429321557762
            ],
            [
              105.80783255636086,
              21.014289878413063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6589",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01429721100251,
          "to_latitude": 21.014289878413063,
          "to_longitude": 105.80783255636086,
          "from_longitude": 105.80783936785693
        }
      },
      {
        "id": 6582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80817141435979,
            21.01497212794679,
            105.80847490779965,
            21.01516988491486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80817141435979,
              21.01516988491486
            ],
            [
              105.80827634664722,
              21.015073344273475
            ],
            [
              105.80831772206709,
              21.015047206057737
            ],
            [
              105.80845947798811,
              21.014985807511138
            ],
            [
              105.80847490779965,
              21.01497212794679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6590",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01516988491486,
          "to_latitude": 21.01497212794679,
          "to_longitude": 105.80847490779965,
          "from_longitude": 105.80817141435979
        }
      },
      {
        "id": 6583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80812995655513,
            21.013925247015745,
            105.80829269353514,
            21.01404747096877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80812995655513,
              21.01404747096877
            ],
            [
              105.80829269353514,
              21.013925247015745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6591",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01404747096877,
          "to_latitude": 21.013925247015745,
          "to_longitude": 105.80829269353514,
          "from_longitude": 105.80812995655513
        }
      },
      {
        "id": 6584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80835112739518,
            21.013488023019086,
            105.8088050372219,
            21.013871417846307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80835112739518,
              21.013871417846307
            ],
            [
              105.80851774504403,
              21.01373288912307
            ],
            [
              105.80852387200889,
              21.013727794698223
            ],
            [
              105.80852696706222,
              21.013722895326907
            ],
            [
              105.80876036959573,
              21.013525751874816
            ],
            [
              105.80876661485077,
              21.013520477136687
            ],
            [
              105.8088050372219,
              21.013488023019086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6592",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013871417846307,
          "to_latitude": 21.013488023019086,
          "to_longitude": 105.8088050372219,
          "from_longitude": 105.80835112739518
        }
      },
      {
        "id": 6585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80882397482002,
            21.013317746124027,
            105.8089931511718,
            21.0134643189719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80882397482002,
              21.0134643189719
            ],
            [
              105.80882720899447,
              21.01346131842496
            ],
            [
              105.80884579377384,
              21.013444076850856
            ],
            [
              105.80890612399166,
              21.01339235565252
            ],
            [
              105.80891842595682,
              21.013381808868647
            ],
            [
              105.8089931511718,
              21.013317746124027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6593",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0134643189719,
          "to_latitude": 21.013317746124027,
          "to_longitude": 105.8089931511718,
          "from_longitude": 105.80882397482002
        }
      },
      {
        "id": 6586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80870173679942,
            21.014834188673113,
            105.8090760670384,
            21.015116721777982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80870173679942,
              21.015116721777982
            ],
            [
              105.80881683464911,
              21.015027531744348
            ],
            [
              105.80886883133456,
              21.014987238982638
            ],
            [
              105.8090760670384,
              21.014834188673113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6594",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015116721777982,
          "to_latitude": 21.014834188673113,
          "to_longitude": 105.8090760670384,
          "from_longitude": 105.80870173679942
        }
      },
      {
        "id": 6587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80917166552511,
            21.014478818423516,
            105.80955612912241,
            21.014760243705677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80917166552511,
              21.014760243705677
            ],
            [
              105.80929694019198,
              21.01466055279168
            ],
            [
              105.809336685989,
              21.014628923228734
            ],
            [
              105.80935380091509,
              21.014617012222573
            ],
            [
              105.80944059483316,
              21.014562017188364
            ],
            [
              105.80955612912241,
              21.014478818423516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6595",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014760243705677,
          "to_latitude": 21.014478818423516,
          "to_longitude": 105.80955612912241,
          "from_longitude": 105.80917166552511
        }
      },
      {
        "id": 6588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80929694019198,
            21.01466055279168,
            105.80930094278308,
            21.01466554125356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80929694019198,
              21.01466055279168
            ],
            [
              105.80929770817166,
              21.014661510174182
            ],
            [
              105.8093007645977,
              21.014665320810053
            ],
            [
              105.80930094278308,
              21.01466554125356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6596",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01466055279168,
          "to_latitude": 21.01466554125356,
          "to_longitude": 105.80930094278308,
          "from_longitude": 105.80929694019198
        }
      },
      {
        "id": 6589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80929943477395,
            21.01466666752753,
            105.80931979577373,
            21.01469211127849
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80931979577373,
              21.01469211127849
            ],
            [
              105.80930670600458,
              21.01467575374568
            ],
            [
              105.80929975437769,
              21.01466706884734
            ],
            [
              105.80929943477395,
              21.01466666752753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6597",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01469211127849,
          "to_latitude": 21.01466666752753,
          "to_longitude": 105.80929943477395,
          "from_longitude": 105.80931979577373
        }
      },
      {
        "id": 6590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80976526679625,
            21.01433711997066,
            105.80978508443211,
            21.01436115631174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80978508443211,
              21.01436115631174
            ],
            [
              105.80978392706267,
              21.014359700395953
            ],
            [
              105.80976910306974,
              21.0143410560482
            ],
            [
              105.8097653683719,
              21.01433722606822
            ],
            [
              105.80976526679625,
              21.01433711997066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6598",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01436115631174,
          "to_latitude": 21.01433711997066,
          "to_longitude": 105.80976526679625,
          "from_longitude": 105.80978508443211
        }
      },
      {
        "id": 6591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80850447338108,
            21.013719695494956,
            105.80851774504403,
            21.01373288912307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80851774504403,
              21.01373288912307
            ],
            [
              105.80851676828247,
              21.013731921890056
            ],
            [
              105.8085050305925,
              21.013720278139637
            ],
            [
              105.80850447338108,
              21.013719695494956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6599",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01373288912307,
          "to_latitude": 21.013719695494956,
          "to_longitude": 105.80850447338108,
          "from_longitude": 105.80851774504403
        }
      },
      {
        "id": 6592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8091653875161,
            21.013148962609325,
            105.80917237796643,
            21.013156121778163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80917237796643,
              21.013156121778163
            ],
            [
              105.80917117276377,
              21.01315489008591
            ],
            [
              105.80917017453322,
              21.01315386605526
            ],
            [
              105.80916897899833,
              21.013152643349283
            ],
            [
              105.80916809201008,
              21.013151734402925
            ],
            [
              105.8091653875161,
              21.013148962609325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6600",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013156121778163,
          "to_latitude": 21.013148962609325,
          "to_longitude": 105.8091653875161,
          "from_longitude": 105.80917237796643
        }
      },
      {
        "id": 6593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80951792560906,
            21.014882308172425,
            105.8095459576621,
            21.014923886803977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80951792560906,
              21.014882308172425
            ],
            [
              105.80952015883906,
              21.01488562323525
            ],
            [
              105.80952391545253,
              21.01489119544443
            ],
            [
              105.80952583837049,
              21.014894048627266
            ],
            [
              105.80952641146179,
              21.014894898548487
            ],
            [
              105.8095459576621,
              21.014923886803977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6601",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014882308172425,
          "to_latitude": 21.014923886803977,
          "to_longitude": 105.8095459576621,
          "from_longitude": 105.80951792560906
        }
      },
      {
        "id": 6594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81003661416332,
            21.014580196853373,
            105.81006001160131,
            21.014614988926123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81003661416332,
              21.014580196853373
            ],
            [
              105.81003890659326,
              21.01458360736972
            ],
            [
              105.8100422269051,
              21.01458854215858
            ],
            [
              105.81004365821404,
              21.014590670576894
            ],
            [
              105.81006001160131,
              21.014614988926123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6602",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014580196853373,
          "to_latitude": 21.014614988926123,
          "to_longitude": 105.81006001160131,
          "from_longitude": 105.81003661416332
        }
      },
      {
        "id": 6595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80685445032485,
            21.014048960566768,
            105.8071117154614,
            21.01428717470853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8071117154614,
              21.01428717470853
            ],
            [
              105.80709263289054,
              21.014264516497644
            ],
            [
              105.80708621717848,
              21.01425689928894
            ],
            [
              105.8070369050212,
              21.01419170597734
            ],
            [
              105.80696550351267,
              21.014129986465964
            ],
            [
              105.80694022835183,
              21.01410827528861
            ],
            [
              105.80693562654143,
              21.01410453335715
            ],
            [
              105.8069016022506,
              21.014081501598803
            ],
            [
              105.80687527732704,
              21.014063308952224
            ],
            [
              105.80685445032485,
              21.014048960566768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6603",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01428717470853,
          "to_latitude": 21.014048960566768,
          "to_longitude": 105.80685445032485,
          "from_longitude": 105.8071117154614
        }
      },
      {
        "id": 6596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80708317195288,
            21.014264516497644,
            105.80709263289054,
            21.014272858442588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80709263289054,
              21.014264516497644
            ],
            [
              105.80709193782539,
              21.01426512947469
            ],
            [
              105.80709002782808,
              21.014266813122443
            ],
            [
              105.80708727628844,
              21.014269239603838
            ],
            [
              105.80708317195288,
              21.014272858442588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6604",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014264516497644,
          "to_latitude": 21.014272858442588,
          "to_longitude": 105.80708317195288,
          "from_longitude": 105.80709263289054
        }
      },
      {
        "id": 6597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80684744513847,
            21.013826135920763,
            105.80703855513036,
            21.01398843767324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80703855513036,
              21.013826135920763
            ],
            [
              105.80696068056452,
              21.013884505263338
            ],
            [
              105.80695315015683,
              21.013890150033856
            ],
            [
              105.80692220457507,
              21.013913345410007
            ],
            [
              105.80684744513847,
              21.01398843767324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6605",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013826135920763,
          "to_latitude": 21.01398843767324,
          "to_longitude": 105.80684744513847,
          "from_longitude": 105.80703855513036
        }
      },
      {
        "id": 6598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80694906670384,
            21.013870290224485,
            105.80696068056452,
            21.013884505263338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80696068056452,
              21.013884505263338
            ],
            [
              105.80695727525477,
              21.013880378300932
            ],
            [
              105.80695725298517,
              21.013880351309684
            ],
            [
              105.80695144977861,
              21.013873220740226
            ],
            [
              105.80694906670384,
              21.013870290224485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6606",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013884505263338,
          "to_latitude": 21.013870290224485,
          "to_longitude": 105.80694906670384,
          "from_longitude": 105.80696068056452
        }
      },
      {
        "id": 6599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80710838708688,
            21.01406034818311,
            105.80735660615422,
            21.014319517979857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80710838708688,
              21.014319517979857
            ],
            [
              105.80712724800438,
              21.014315818297842
            ],
            [
              105.80715472545478,
              21.014293261662292
            ],
            [
              105.80717005124903,
              21.014306005778828
            ],
            [
              105.80732021225042,
              21.014165945806646
            ],
            [
              105.80735660615422,
              21.01406034818311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Ngõ 594/2, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6607",
          "diaChiLapD": "Ngõ 594/2, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014319517979857,
          "to_latitude": 21.01406034818311,
          "to_longitude": 105.80735660615422,
          "from_longitude": 105.80710838708688
        }
      },
      {
        "id": 6600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80696848202746,
            21.014327125324034,
            105.80711840518966,
            21.014529163595917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80696848202746,
              21.014529163595917
            ],
            [
              105.80701695340402,
              21.01441785634649
            ],
            [
              105.80704030166387,
              21.014396974934158
            ],
            [
              105.80704425638334,
              21.014393438096707
            ],
            [
              105.80711840518966,
              21.014327125324034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6608",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014529163595917,
          "to_latitude": 21.014327125324034,
          "to_longitude": 105.80711840518966,
          "from_longitude": 105.80696848202746
        }
      },
      {
        "id": 6601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80744324842068,
            21.014669762217444,
            105.8077915555804,
            21.015003791257634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80744324842068,
              21.014669762217444
            ],
            [
              105.8074560235363,
              21.014679704851947
            ],
            [
              105.80745655339248,
              21.01468056672769
            ],
            [
              105.80746120674594,
              21.01468815976892
            ],
            [
              105.80764079883728,
              21.014853721897786
            ],
            [
              105.8077915555804,
              21.015003791257634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6609",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014669762217444,
          "to_latitude": 21.015003791257634,
          "to_longitude": 105.8077915555804,
          "from_longitude": 105.80744324842068
        }
      },
      {
        "id": 6602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80772473882419,
            21.015204115041705,
            105.80803835046301,
            21.015534712469517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80803835046301,
              21.015534712469517
            ],
            [
              105.80790550439372,
              21.015394671763595
            ],
            [
              105.80789242664156,
              21.01538088646016
            ],
            [
              105.8077894696218,
              21.015272351965333
            ],
            [
              105.80772473882419,
              21.015204115041705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6610",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015534712469517,
          "to_latitude": 21.015204115041705,
          "to_longitude": 105.80772473882419,
          "from_longitude": 105.80803835046301
        }
      },
      {
        "id": 6603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80736045989559,
            21.014844812545498,
            105.80770189871832,
            21.015182351782173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80770189871832,
              21.015182351782173
            ],
            [
              105.80753154564154,
              21.015013944970644
            ],
            [
              105.80748719999903,
              21.01497010586348
            ],
            [
              105.80736045989559,
              21.014844812545498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6611",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015182351782173,
          "to_latitude": 21.014844812545498,
          "to_longitude": 105.80736045989559,
          "from_longitude": 105.80770189871832
        }
      },
      {
        "id": 6604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.807079841911,
            21.01365865531751,
            105.8072678302273,
            21.013796237379097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.807079841911,
              21.013796237379097
            ],
            [
              105.80717239121473,
              21.013728504135326
            ],
            [
              105.80718830139661,
              21.01371686009267
            ],
            [
              105.8072678302273,
              21.01365865531751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6612",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013796237379097,
          "to_latitude": 21.01365865531751,
          "to_longitude": 105.8072678302273,
          "from_longitude": 105.807079841911
        }
      },
      {
        "id": 6605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80809371680702,
            21.015384909724432,
            105.80833714058612,
            21.01555790906481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80809371680702,
              21.01555790906481
            ],
            [
              105.80821869934172,
              21.015469084342534
            ],
            [
              105.8082259132046,
              21.015463957666938
            ],
            [
              105.80833714058612,
              21.015384909724432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6613",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01555790906481,
          "to_latitude": 21.015384909724432,
          "to_longitude": 105.80833714058612,
          "from_longitude": 105.80809371680702
        }
      },
      {
        "id": 6606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80745655339248,
            21.01445029398158,
            105.80767383734815,
            21.01468056672769
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80745655339248,
              21.01468056672769
            ],
            [
              105.80767383734815,
              21.01445029398158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6614",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01468056672769,
          "to_latitude": 21.01445029398158,
          "to_longitude": 105.80767383734815,
          "from_longitude": 105.80745655339248
        }
      },
      {
        "id": 6607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81035513283962,
            21.014412303503317,
            105.81036706416181,
            21.014430195637495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81035513283962,
              21.014412303503317
            ],
            [
              105.81035757478386,
              21.0144159680137
            ],
            [
              105.81036347303107,
              21.0144248105306
            ],
            [
              105.81036706416181,
              21.014430195637495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6615",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014412303503317,
          "to_latitude": 21.014430195637495,
          "to_longitude": 105.81036706416181,
          "from_longitude": 105.81035513283962
        }
      },
      {
        "id": 6608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80939582615432,
            21.012942830834096,
            105.80959953896476,
            21.01320064309504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80939582615432,
              21.012942830834096
            ],
            [
              105.8094045911209,
              21.012951194588776
            ],
            [
              105.80940859171774,
              21.012963309537497
            ],
            [
              105.80947079513133,
              21.013046671599962
            ],
            [
              105.80955249923201,
              21.013145272264282
            ],
            [
              105.80959953896476,
              21.01320064309504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_46_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6616",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012942830834096,
          "to_latitude": 21.01320064309504,
          "to_longitude": 105.80959953896476,
          "from_longitude": 105.80939582615432
        }
      },
      {
        "id": 6609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81042982238108,
            21.013677733034818,
            105.81066773721176,
            21.013848999540727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81042982238108,
              21.013848999540727
            ],
            [
              105.81053018834704,
              21.01377674906966
            ],
            [
              105.81053848158533,
              21.013770778961067
            ],
            [
              105.81066773721176,
              21.013677733034818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6617",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013848999540727,
          "to_latitude": 21.013677733034818,
          "to_longitude": 105.81066773721176,
          "from_longitude": 105.81042982238108
        }
      },
      {
        "id": 6610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81053848158533,
            21.013770778961067,
            105.8105465978373,
            21.01378083297491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81053848158533,
              21.013770778961067
            ],
            [
              105.81053977926783,
              21.01377238865543
            ],
            [
              105.81054387660465,
              21.01377746244346
            ],
            [
              105.8105465978373,
              21.01378083297491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6618",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013770778961067,
          "to_latitude": 21.01378083297491,
          "to_longitude": 105.8105465978373,
          "from_longitude": 105.81053848158533
        }
      },
      {
        "id": 6611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81016319704084,
            21.01388985926605,
            105.81037797771343,
            21.01404605865643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81016319704084,
              21.01404605865643
            ],
            [
              105.81024916953656,
              21.013983535109233
            ],
            [
              105.81025948707415,
              21.013976031626417
            ],
            [
              105.81037797771343,
              21.01388985926605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6619",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01404605865643,
          "to_latitude": 21.01388985926605,
          "to_longitude": 105.81037797771343,
          "from_longitude": 105.81016319704084
        }
      },
      {
        "id": 6612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80944321369425,
            21.012670792346977,
            105.8097744148197,
            21.012918102603855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80944321369425,
              21.012918102603855
            ],
            [
              105.80962726416482,
              21.012775303435124
            ],
            [
              105.80962989842185,
              21.012773259538104
            ],
            [
              105.8097744148197,
              21.012670792346977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6620",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012918102603855,
          "to_latitude": 21.012670792346977,
          "to_longitude": 105.8097744148197,
          "from_longitude": 105.80944321369425
        }
      },
      {
        "id": 6613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80961931671283,
            21.012765313602355,
            105.80962726416482,
            21.012775303435124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80962726416482,
              21.012775303435124
            ],
            [
              105.80962577558849,
              21.012773431801772
            ],
            [
              105.809621788313,
              21.012768421594988
            ],
            [
              105.80962066485348,
              21.01276700796786
            ],
            [
              105.80961931671283,
              21.012765313602355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6621",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012775303435124,
          "to_latitude": 21.012765313602355,
          "to_longitude": 105.80961931671283,
          "from_longitude": 105.80962726416482
        }
      },
      {
        "id": 6614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80982663011712,
            21.01268045948413,
            105.81006454775998,
            21.012985195516258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80982663011712,
              21.01268045948413
            ],
            [
              105.80993764757712,
              21.01282265660288
            ],
            [
              105.8099516697345,
              21.012839620237582
            ],
            [
              105.80997275236261,
              21.01286512642008
            ],
            [
              105.80998339646496,
              21.012879456962043
            ],
            [
              105.81001081824557,
              21.012916376873548
            ],
            [
              105.81006454775998,
              21.012985195516258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6622",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01268045948413,
          "to_latitude": 21.012985195516258,
          "to_longitude": 105.81006454775998,
          "from_longitude": 105.80982663011712
        }
      },
      {
        "id": 6615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8101044211053,
            21.013031447904112,
            105.8103784845423,
            21.013291896746388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8101044211053,
              21.013031447904112
            ],
            [
              105.81017891592761,
              21.01310243203281
            ],
            [
              105.81018965411943,
              21.013106392140624
            ],
            [
              105.81019873371584,
              21.01310977395003
            ],
            [
              105.81021321866827,
              21.01311517041365
            ],
            [
              105.81024666934543,
              21.013148070205638
            ],
            [
              105.81025178026852,
              21.01315309672329
            ],
            [
              105.81029243558002,
              21.013193082350007
            ],
            [
              105.8103784845423,
              21.013291896746388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6623",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013031447904112,
          "to_latitude": 21.013291896746388,
          "to_longitude": 105.8103784845423,
          "from_longitude": 105.8101044211053
        }
      },
      {
        "id": 6616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81040919549079,
            21.013326532913407,
            105.81068244710914,
            21.013644819739923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81040919549079,
              21.013326532913407
            ],
            [
              105.81052774624503,
              21.013460963893284
            ],
            [
              105.81052926868225,
              21.01346268993693
            ],
            [
              105.81068244710914,
              21.013644819739923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6624",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013326532913407,
          "to_latitude": 21.013644819739923,
          "to_longitude": 105.81068244710914,
          "from_longitude": 105.81040919549079
        }
      },
      {
        "id": 6617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81025178026852,
            21.01314713281902,
            105.81025706654042,
            21.01315309672329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81025178026852,
              21.01315309672329
            ],
            [
              105.81025325266283,
              21.013151435899353
            ],
            [
              105.81025586280595,
              21.013148489781894
            ],
            [
              105.81025706654042,
              21.01314713281902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6625",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01315309672329,
          "to_latitude": 21.01314713281902,
          "to_longitude": 105.81025706654042,
          "from_longitude": 105.81025178026852
        }
      },
      {
        "id": 6618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81017042962428,
            21.014047629254236,
            105.81019161818914,
            21.014073831285348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81019161818914,
              21.014073831285348
            ],
            [
              105.81019033977489,
              21.014072226915975
            ],
            [
              105.81017407681371,
              21.014051820030378
            ],
            [
              105.81017113913568,
              21.01404844691073
            ],
            [
              105.81017042962428,
              21.014047629254236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6626",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014073831285348,
          "to_latitude": 21.014047629254236,
          "to_longitude": 105.81017042962428,
          "from_longitude": 105.81019161818914
        }
      },
      {
        "id": 6619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81024916953656,
            21.013983535109233,
            105.8102540678348,
            21.013989617609425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81024916953656,
              21.013983535109233
            ],
            [
              105.81024998301596,
              21.01398454556126
            ],
            [
              105.81025333087278,
              21.01398870434173
            ],
            [
              105.8102540678348,
              21.013989617609425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6627",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013983535109233,
          "to_latitude": 21.013989617609425,
          "to_longitude": 105.8102540678348,
          "from_longitude": 105.81024916953656
        }
      },
      {
        "id": 6620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81052774624503,
            21.013454574361788,
            105.81053553892205,
            21.013460963893284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81052774624503,
              21.013460963893284
            ],
            [
              105.8105294595436,
              21.013459559339648
            ],
            [
              105.81053335923168,
              21.013456361396635
            ],
            [
              105.81053553892205,
              21.013454574361788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6628",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013460963893284,
          "to_latitude": 21.013454574361788,
          "to_longitude": 105.81053553892205,
          "from_longitude": 105.81052774624503
        }
      },
      {
        "id": 6621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80998339646496,
            21.012874270519717,
            105.8099910070955,
            21.012879456962043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80998339646496,
              21.012879456962043
            ],
            [
              105.8099870287867,
              21.01287698108099
            ],
            [
              105.80999038605286,
              21.012874693477688
            ],
            [
              105.8099910070955,
              21.012874270519717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6629",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012879456962043,
          "to_latitude": 21.012874270519717,
          "to_longitude": 105.8099910070955,
          "from_longitude": 105.80998339646496
        }
      },
      {
        "id": 6622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80747710860815,
            21.01497010586348,
            105.80748719999903,
            21.014978201531264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80748719999903,
              21.01497010586348
            ],
            [
              105.80748531887852,
              21.01497161505537
            ],
            [
              105.8074799820112,
              21.014975896401147
            ],
            [
              105.80747710860815,
              21.014978201531264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6630",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01497010586348,
          "to_latitude": 21.014978201531264,
          "to_longitude": 105.80747710860815,
          "from_longitude": 105.80748719999903
        }
      },
      {
        "id": 6623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80703146883643,
            21.01438957873826,
            105.80704030166387,
            21.014396974934158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80704030166387,
              21.014396974934158
            ],
            [
              105.8070346208653,
              21.01439221837798
            ],
            [
              105.80703418217115,
              21.014391851038088
            ],
            [
              105.80703228245689,
              21.01439026133324
            ],
            [
              105.80703146883643,
              21.01438957873826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6631",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014396974934158,
          "to_latitude": 21.01438957873826,
          "to_longitude": 105.80703146883643,
          "from_longitude": 105.80704030166387
        }
      },
      {
        "id": 6624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81079589176534,
            21.011137885473268,
            105.81117476420805,
            21.011322408881014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81079589176534,
              21.011197669516534
            ],
            [
              105.81085154286099,
              21.011155601085655
            ],
            [
              105.81087497837117,
              21.011137885473268
            ],
            [
              105.81090766547399,
              21.01113866544837
            ],
            [
              105.81096994384818,
              21.011140151617887
            ],
            [
              105.8110244829022,
              21.011163566214602
            ],
            [
              105.81117476420805,
              21.011322408881014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6632",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011197669516534,
          "to_latitude": 21.011322408881014,
          "to_longitude": 105.81117476420805,
          "from_longitude": 105.81079589176534
        }
      },
      {
        "id": 6625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81011916909839,
            21.01134671913271,
            105.81062553438473,
            21.011657471442625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81011916909839,
              21.011657471442625
            ],
            [
              105.81037928511552,
              21.01149784166018
            ],
            [
              105.81042556522827,
              21.011469439591334
            ],
            [
              105.81062553438473,
              21.01134671913271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6633",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011657471442625,
          "to_latitude": 21.01134671913271,
          "to_longitude": 105.81062553438473,
          "from_longitude": 105.81011916909839
        }
      },
      {
        "id": 6626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81039654851378,
            21.011431759587413,
            105.81042556522827,
            21.011469439591334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81042556522827,
              21.011469439591334
            ],
            [
              105.81041613581299,
              21.011457195166468
            ],
            [
              105.81040253998634,
              21.011439541274196
            ],
            [
              105.81039985859076,
              21.011436057644897
            ],
            [
              105.81039654851378,
              21.011431759587413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6634",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011469439591334,
          "to_latitude": 21.011431759587413,
          "to_longitude": 105.81039654851378,
          "from_longitude": 105.81042556522827
        }
      },
      {
        "id": 6627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8106860126734,
            21.012547206230128,
            105.81088357680267,
            21.012813428178433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81088357680267,
              21.012813428178433
            ],
            [
              105.81084215537369,
              21.012759846426196
            ],
            [
              105.8107665826478,
              21.012662086084706
            ],
            [
              105.8106860126734,
              21.012547206230128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6635",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012813428178433,
          "to_latitude": 21.012547206230128,
          "to_longitude": 105.8106860126734,
          "from_longitude": 105.81088357680267
        }
      },
      {
        "id": 6628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81007756821516,
            21.012965038486843,
            105.81029186025184,
            21.01316848835925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81029186025184,
              21.01316848835925
            ],
            [
              105.81021898341035,
              21.013095743410844
            ],
            [
              105.81019351920155,
              21.01309126542509
            ],
            [
              105.81019080856288,
              21.01308842774183
            ],
            [
              105.81018061513177,
              21.013077756758463
            ],
            [
              105.8101594872443,
              21.01305563792835
            ],
            [
              105.81007756821516,
              21.012965038486843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6636",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01316848835925,
          "to_latitude": 21.012965038486843,
          "to_longitude": 105.81007756821516,
          "from_longitude": 105.81029186025184
        }
      },
      {
        "id": 6629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809822118409,
            21.01265545789926,
            105.81006118343517,
            21.012947519787225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809822118409,
              21.01265545789926
            ],
            [
              105.80993689283001,
              21.01279546396911
            ],
            [
              105.80993991973634,
              21.012799156412427
            ],
            [
              105.80996543186066,
              21.012831582825918
            ],
            [
              105.8099802578207,
              21.01285097231783
            ],
            [
              105.810011692282,
              21.01289208307772
            ],
            [
              105.81006118343517,
              21.012947519787225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6637",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01265545789926,
          "to_latitude": 21.012947519787225,
          "to_longitude": 105.81006118343517,
          "from_longitude": 105.809822118409
        }
      },
      {
        "id": 6630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81018227986696,
            21.01308842774183,
            105.81019080856288,
            21.013096214444694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81019080856288,
              21.01308842774183
            ],
            [
              105.8101873032945,
              21.01309163102477
            ],
            [
              105.8101866275101,
              21.013092248438358
            ],
            [
              105.81018396742374,
              21.013094674532727
            ],
            [
              105.81018227986696,
              21.013096214444694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6638",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01308842774183,
          "to_latitude": 21.013096214444694,
          "to_longitude": 105.81018227986696,
          "from_longitude": 105.81019080856288
        }
      },
      {
        "id": 6631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8093876469459,
            21.011988565135955,
            105.80963779435258,
            21.012163345943204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8093876469459,
              21.012163345943204
            ],
            [
              105.80950725215308,
              21.012079776518068
            ],
            [
              105.80952265211918,
              21.01206901624376
            ],
            [
              105.80963779435258,
              21.011988565135955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6639",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012163345943204,
          "to_latitude": 21.011988565135955,
          "to_longitude": 105.80963779435258,
          "from_longitude": 105.8093876469459
        }
      },
      {
        "id": 6632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80950272836185,
            21.01204271686876,
            105.80952265211918,
            21.01206901624376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80952265211918,
              21.01206901624376
            ],
            [
              105.80951756679187,
              21.01206230415444
            ],
            [
              105.80950753565052,
              21.01204906447353
            ],
            [
              105.8095062422678,
              21.012047355394586
            ],
            [
              105.80950272836185,
              21.01204271686876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6640",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01206901624376,
          "to_latitude": 21.01204271686876,
          "to_longitude": 105.80950272836185,
          "from_longitude": 105.80952265211918
        }
      },
      {
        "id": 6633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81088437874816,
            21.011735797261153,
            105.81156315902265,
            21.0120488325328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81156315902265,
              21.011754382243392
            ],
            [
              105.81155505943693,
              21.011761803867515
            ],
            [
              105.81150972703648,
              21.011790222493666
            ],
            [
              105.81127990375163,
              21.01194438140183
            ],
            [
              105.81112418483002,
              21.0120488325328
            ],
            [
              105.8111136412437,
              21.01203655552566
            ],
            [
              105.81096455887975,
              21.0118629637156
            ],
            [
              105.81094353952362,
              21.01183848883979
            ],
            [
              105.81088437874816,
              21.011735797261153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6641",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011754382243392,
          "to_latitude": 21.011735797261153,
          "to_longitude": 105.81088437874816,
          "from_longitude": 105.81156315902265
        }
      },
      {
        "id": 6634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81119784641186,
            21.01136317356664,
            105.81150972703648,
            21.011790222493666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81150972703648,
              21.011790222493666
            ],
            [
              105.8114072037887,
              21.011649840227683
            ],
            [
              105.81139635481013,
              21.011634985975114
            ],
            [
              105.81119784641186,
              21.01136317356664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6642",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011790222493666,
          "to_latitude": 21.01136317356664,
          "to_longitude": 105.81119784641186,
          "from_longitude": 105.81150972703648
        }
      },
      {
        "id": 6635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81218819922132,
            21.012693730335055,
            105.8124458435494,
            21.013093070449166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8124458435494,
              21.013093070449166
            ],
            [
              105.8123057320778,
              21.01287590145115
            ],
            [
              105.8122486806891,
              21.012787474951743
            ],
            [
              105.81218819922132,
              21.012693730335055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6643",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013093070449166,
          "to_latitude": 21.012693730335055,
          "to_longitude": 105.81218819922132,
          "from_longitude": 105.8124458435494
        }
      },
      {
        "id": 6636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81224509031627,
            21.01317400401326,
            105.81245081060928,
            21.0133201892426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81224509031627,
              21.0133201892426
            ],
            [
              105.81245081060928,
              21.01317400401326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6644",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0133201892426,
          "to_latitude": 21.01317400401326,
          "to_longitude": 105.81245081060928,
          "from_longitude": 105.81224509031627
        }
      },
      {
        "id": 6637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81197067009842,
            21.012975753967275,
            105.8122627610286,
            21.0133406443027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81197067009842,
              21.012975753967275
            ],
            [
              105.81224509031627,
              21.0133201892426
            ],
            [
              105.81225355155897,
              21.013328210164953
            ],
            [
              105.8122627610286,
              21.0133406443027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6645",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012975753967275,
          "to_latitude": 21.0133406443027,
          "to_longitude": 105.8122627610286,
          "from_longitude": 105.81197067009842
        }
      },
      {
        "id": 6638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81083257932269,
            21.011129897035136,
            105.81085154286099,
            21.011155601085655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81085154286099,
              21.011155601085655
            ],
            [
              105.81084936549368,
              21.01115264977326
            ],
            [
              105.81083453092458,
              21.011132545035384
            ],
            [
              105.81083257932269,
              21.011129897035136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6646",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011155601085655,
          "to_latitude": 21.011129897035136,
          "to_longitude": 105.81083257932269,
          "from_longitude": 105.81085154286099
        }
      },
      {
        "id": 6639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8122486806891,
            21.012747641114736,
            105.81231255596494,
            21.012787474951743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122486806891,
              21.012787474951743
            ],
            [
              105.81226930600097,
              21.012776062647703
            ],
            [
              105.81228994953192,
              21.012764640318743
            ],
            [
              105.81229098051016,
              21.012764069975688
            ],
            [
              105.81230168872098,
              21.012755915086633
            ],
            [
              105.81231023001011,
              21.01274941261181
            ],
            [
              105.81231255596494,
              21.012747641114736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6647",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012787474951743,
          "to_latitude": 21.012747641114736,
          "to_longitude": 105.81231255596494,
          "from_longitude": 105.8122486806891
        }
      },
      {
        "id": 6640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81047287433145,
            21.012242256394206,
            105.81088717410118,
            21.012538108580618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81088717410118,
              21.01251475568881
            ],
            [
              105.81083780499021,
              21.012446509950312
            ],
            [
              105.81068121304176,
              21.012537303515227
            ],
            [
              105.81067982549239,
              21.012538108580618
            ],
            [
              105.81064277271929,
              21.012485139122997
            ],
            [
              105.81060261688984,
              21.01242773242375
            ],
            [
              105.81051529264576,
              21.01230289584605
            ],
            [
              105.81047287433145,
              21.012242256394206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6648",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01251475568881,
          "to_latitude": 21.012242256394206,
          "to_longitude": 105.81047287433145,
          "from_longitude": 105.81088717410118
        }
      },
      {
        "id": 6641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81058668204423,
            21.01242773242375,
            105.81060261688984,
            21.01243794075981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81060261688984,
              21.01242773242375
            ],
            [
              105.81059872785107,
              21.012430225798614
            ],
            [
              105.81059542739082,
              21.012432341529376
            ],
            [
              105.81059098332722,
              21.01243519161396
            ],
            [
              105.81059083665983,
              21.01243528534527
            ],
            [
              105.81058668204423,
              21.01243794075981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6649",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01242773242375,
          "to_latitude": 21.01243794075981,
          "to_longitude": 105.81058668204423,
          "from_longitude": 105.81060261688984
        }
      },
      {
        "id": 6642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81083014121376,
            21.012759846426196,
            105.81084215537369,
            21.012767545899038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81084215537369,
              21.012759846426196
            ],
            [
              105.81084112487778,
              21.012760507081207
            ],
            [
              105.81083394782186,
              21.012765106202174
            ],
            [
              105.81083014121376,
              21.012767545899038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6650",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012759846426196,
          "to_latitude": 21.012767545899038,
          "to_longitude": 105.81083014121376,
          "from_longitude": 105.81084215537369
        }
      },
      {
        "id": 6643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81169709128415,
            21.01307530801879,
            105.81203120420338,
            21.013476751559256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81203120420338,
              21.013476751559256
            ],
            [
              105.81202522695737,
              21.013468252704843
            ],
            [
              105.81200398482343,
              21.013465071750634
            ],
            [
              105.81169709128415,
              21.01307530801879
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_05_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6651",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013476751559256,
          "to_latitude": 21.01307530801879,
          "to_longitude": 105.81169709128415,
          "from_longitude": 105.81203120420338
        }
      },
      {
        "id": 6644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80598984115636,
            21.015850462687933,
            105.80620922655282,
            21.01603764949661
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80598984115636,
              21.015850462687933
            ],
            [
              105.80612471926287,
              21.01603718321734
            ],
            [
              105.80612505556354,
              21.01603764949661
            ],
            [
              105.80613259077775,
              21.016037527081735
            ],
            [
              105.80616068094746,
              21.016037070620246
            ],
            [
              105.80617108635556,
              21.016036902086725
            ],
            [
              105.80620922655282,
              21.015999152428673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6652",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015850462687933,
          "to_latitude": 21.015999152428673,
          "to_longitude": 105.80620922655282,
          "from_longitude": 105.80598984115636
        }
      },
      {
        "id": 6645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80593300300802,
            21.01584787744614,
            105.80595918745074,
            21.01586088154062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80593300300802,
              21.01586088154062
            ],
            [
              105.8059485028026,
              21.015853183954743
            ],
            [
              105.80595918745074,
              21.01584787744614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Ngõ 107, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6653",
          "diaChiLapD": "Ngõ 107, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01586088154062,
          "to_latitude": 21.01584787744614,
          "to_longitude": 105.80595918745074,
          "from_longitude": 105.80593300300802
        }
      },
      {
        "id": 6646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80649617148033,
            21.014602751939933,
            105.80682652982284,
            21.01493414577093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80649617148033,
              21.014602751939933
            ],
            [
              105.80657106384588,
              21.014671796543514
            ],
            [
              105.80658990798555,
              21.014700744226086
            ],
            [
              105.80670916647651,
              21.014809246453108
            ],
            [
              105.80682652982284,
              21.01493414577093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 594/35, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6654",
          "diaChiLapD": "Ngõ 594/35, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014602751939933,
          "to_latitude": 21.01493414577093,
          "to_longitude": 105.80682652982284,
          "from_longitude": 105.80649617148033
        }
      },
      {
        "id": 6647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80601953772786,
            21.01465615512509,
            105.80657876126786,
            21.01513899105061
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80601953772786,
              21.01465615512509
            ],
            [
              105.80602184581649,
              21.014658378780293
            ],
            [
              105.80611426279498,
              21.014747338066208
            ],
            [
              105.80625027419009,
              21.0148533550419
            ],
            [
              105.80628168053632,
              21.014899182392416
            ],
            [
              105.80632210171562,
              21.01495816590385
            ],
            [
              105.8063702397658,
              21.01502648654422
            ],
            [
              105.80649084832615,
              21.01513899105061
            ],
            [
              105.80657876126786,
              21.01505445292489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Ngõ 560, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6655",
          "diaChiLapD": "Ngõ 560, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01465615512509,
          "to_latitude": 21.01505445292489,
          "to_longitude": 105.80657876126786,
          "from_longitude": 105.80601953772786
        }
      },
      {
        "id": 6648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80670916647651,
            21.01455276302223,
            105.8069624198963,
            21.014809246453108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8069624198963,
              21.01455276302223
            ],
            [
              105.80695868265275,
              21.014559713033375
            ],
            [
              105.80695662307353,
              21.014561844487716
            ],
            [
              105.8068380943824,
              21.014684517819134
            ],
            [
              105.80681753559755,
              21.014701988931158
            ],
            [
              105.80678140591384,
              21.01473268760791
            ],
            [
              105.80670916647651,
              21.014809246453108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 594/35, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6656",
          "diaChiLapD": "Ngõ 594/35, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01455276302223,
          "to_latitude": 21.014809246453108,
          "to_longitude": 105.80670916647651,
          "from_longitude": 105.8069624198963
        }
      },
      {
        "id": 6649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80665165612314,
            21.01455255037035,
            105.80681753559755,
            21.014701988931158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80681753559755,
              21.014701988931158
            ],
            [
              105.80672319335889,
              21.014627507998117
            ],
            [
              105.80669400401617,
              21.014588622374724
            ],
            [
              105.80665165612314,
              21.01455255037035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 594/35, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6657",
          "diaChiLapD": "Ngõ 594/35, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014701988931158,
          "to_latitude": 21.01455255037035,
          "to_longitude": 105.80665165612314,
          "from_longitude": 105.80681753559755
        }
      },
      {
        "id": 6650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80562760851045,
            21.01501935835707,
            105.80565390521492,
            21.015044649808743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80565390521492,
              21.015044649808743
            ],
            [
              105.80564803020651,
              21.01503901347695
            ],
            [
              105.80564787936696,
              21.01503886877128
            ],
            [
              105.80563464104976,
              21.015026129084006
            ],
            [
              105.80562996879969,
              21.015021629641467
            ],
            [
              105.80562760851045,
              21.01501935835707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6658",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015044649808743,
          "to_latitude": 21.01501935835707,
          "to_longitude": 105.80562760851045,
          "from_longitude": 105.80565390521492
        }
      },
      {
        "id": 6651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80621652391142,
            21.01502648654422,
            105.8063702397658,
            21.0151655274568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8063702397658,
              21.01502648654422
            ],
            [
              105.80621652391142,
              21.0151655274568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Ngõ 560, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6659",
          "diaChiLapD": "Ngõ 560, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01502648654422,
          "to_latitude": 21.0151655274568,
          "to_longitude": 105.80621652391142,
          "from_longitude": 105.8063702397658
        }
      },
      {
        "id": 6652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80638971288958,
            21.01432105153853,
            105.80640793986393,
            21.01433845709062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80640793986393,
              21.01433845709062
            ],
            [
              105.80640002581,
              21.01433089934426
            ],
            [
              105.8063923534965,
              21.014323575291417
            ],
            [
              105.80639195898875,
              21.0143231959984
            ],
            [
              105.80638971288958,
              21.01432105153853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6660",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01433845709062,
          "to_latitude": 21.01432105153853,
          "to_longitude": 105.80638971288958,
          "from_longitude": 105.80640793986393
        }
      },
      {
        "id": 6653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80629531692796,
            21.014241788763634,
            105.80651850130263,
            21.014436928028747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80629531692796,
              21.014436928028747
            ],
            [
              105.80640793986393,
              21.01433845709062
            ],
            [
              105.80641834907505,
              21.014329355587698
            ],
            [
              105.80651850130263,
              21.014241788763634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6661",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014436928028747,
          "to_latitude": 21.014241788763634,
          "to_longitude": 105.80651850130263,
          "from_longitude": 105.80629531692796
        }
      },
      {
        "id": 6654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80613565714563,
            21.014552758743324,
            105.80615175540315,
            21.014568024722475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80615175540315,
              21.014568024722475
            ],
            [
              105.80614469344233,
              21.01456132822083
            ],
            [
              105.80613673520428,
              21.014553782413703
            ],
            [
              105.806136311713,
              21.014553379773467
            ],
            [
              105.80613565714563,
              21.014552758743324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6662",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014568024722475,
          "to_latitude": 21.014552758743324,
          "to_longitude": 105.80613565714563,
          "from_longitude": 105.80615175540315
        }
      },
      {
        "id": 6655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80607046743985,
            21.014454162132218,
            105.806276536415,
            21.014642199201074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80607046743985,
              21.014642199201074
            ],
            [
              105.80615175540315,
              21.014568024722475
            ],
            [
              105.8061669927576,
              21.014554120466773
            ],
            [
              105.806276536415,
              21.014454162132218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6663",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014642199201074,
          "to_latitude": 21.014454162132218,
          "to_longitude": 105.806276536415,
          "from_longitude": 105.80607046743985
        }
      },
      {
        "id": 6656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80589213028578,
            21.01477127462527,
            105.8059138515303,
            21.0147921781439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8059138515303,
              21.0147921781439
            ],
            [
              105.80590949161737,
              21.014787982520993
            ],
            [
              105.80589863680767,
              21.01477753841168
            ],
            [
              105.80589517710362,
              21.014774207431053
            ],
            [
              105.80589213028578,
              21.01477127462527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6664",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0147921781439,
          "to_latitude": 21.01477127462527,
          "to_longitude": 105.80589213028578,
          "from_longitude": 105.8059138515303
        }
      },
      {
        "id": 6657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80576630591229,
            21.014695012490073,
            105.8060154428218,
            21.014933295761505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80576630591229,
              21.014933295761505
            ],
            [
              105.8059138515303,
              21.0147921781439
            ],
            [
              105.80591518178447,
              21.014790905514833
            ],
            [
              105.8060154428218,
              21.014695012490073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6665",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014933295761505,
          "to_latitude": 21.014695012490073,
          "to_longitude": 105.8060154428218,
          "from_longitude": 105.80576630591229
        }
      },
      {
        "id": 6658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80568842934387,
            21.015427051565222,
            105.80570985761646,
            21.01543813902456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80568842934387,
              21.01543813902456
            ],
            [
              105.80570401275455,
              21.01543007525754
            ],
            [
              105.80570985761646,
              21.015427051565222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Ngõ 107, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6666",
          "diaChiLapD": "Ngõ 107, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01543813902456,
          "to_latitude": 21.015427051565222,
          "to_longitude": 105.80570985761646,
          "from_longitude": 105.80568842934387
        }
      },
      {
        "id": 6659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80555798392963,
            21.015214150642503,
            105.80581283754309,
            21.01565648992706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80581283754309,
              21.01565648992706
            ],
            [
              105.80577846029053,
              21.01559608308724
            ],
            [
              105.8056947857991,
              21.015449054256973
            ],
            [
              105.80568842934387,
              21.01543813902456
            ],
            [
              105.80555798392963,
              21.015214150642503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Ngõ 107, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6667",
          "diaChiLapD": "Ngõ 107, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01565648992706,
          "to_latitude": 21.015214150642503,
          "to_longitude": 105.80555798392963,
          "from_longitude": 105.80581283754309
        }
      },
      {
        "id": 6660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8055924571167,
            21.015585454337362,
            105.80599617076014,
            21.01619488825211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80599617076014,
              21.01619488825211
            ],
            [
              105.80560038244006,
              21.01580163253646
            ],
            [
              105.8055924571167,
              21.015763864492477
            ],
            [
              105.8057258861624,
              21.015628822150482
            ],
            [
              105.80577846029053,
              21.01559608308724
            ],
            [
              105.80578625959555,
              21.01559122651521
            ],
            [
              105.80579279911873,
              21.01558715359015
            ],
            [
              105.80579552767576,
              21.015585454337362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.1_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6668",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01619488825211,
          "to_latitude": 21.015585454337362,
          "to_longitude": 105.80579552767576,
          "from_longitude": 105.80599617076014
        }
      },
      {
        "id": 6661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80544877313226,
            21.015198749831637,
            105.80559324093855,
            21.015742673317654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80555250637532,
              21.015742673317654
            ],
            [
              105.80547241533303,
              21.015615723362266
            ],
            [
              105.80545960531424,
              21.015567923685598
            ],
            [
              105.80544877313226,
              21.01542143230325
            ],
            [
              105.8054638779049,
              21.015322082319308
            ],
            [
              105.80550904990311,
              21.01524080721597
            ],
            [
              105.80553944890433,
              21.015210497226164
            ],
            [
              105.80559247421357,
              21.01519891784992
            ],
            [
              105.80559324093855,
              21.015198749831637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6669",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015742673317654,
          "to_latitude": 21.015198749831637,
          "to_longitude": 105.80559324093855,
          "from_longitude": 105.80555250637532
        }
      },
      {
        "id": 6662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80557181744344,
            21.014952653228697,
            105.8057473500667,
            21.015312786027405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80568234704229,
              21.015312786027405
            ],
            [
              105.80562335371962,
              21.015227988394813
            ],
            [
              105.80559902122734,
              21.015185136986123
            ],
            [
              105.80557181744344,
              21.015125463873655
            ],
            [
              105.80558497261895,
              21.015112512498572
            ],
            [
              105.80563399544113,
              21.015064250233696
            ],
            [
              105.80565390521492,
              21.015044649808743
            ],
            [
              105.8057473500667,
              21.014952653228697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6670",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015312786027405,
          "to_latitude": 21.014952653228697,
          "to_longitude": 105.8057473500667,
          "from_longitude": 105.80568234704229
        }
      },
      {
        "id": 6663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80582778573036,
            21.015685961049716,
            105.80609566063946,
            21.016131293789627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80609566063946,
              21.016131293789627
            ],
            [
              105.80593300300802,
              21.01586088154062
            ],
            [
              105.80591158934024,
              21.015825281935214
            ],
            [
              105.80582778573036,
              21.015685961049716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Ngõ 107, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6671",
          "diaChiLapD": "Ngõ 107, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016131293789627,
          "to_latitude": 21.015685961049716,
          "to_longitude": 105.80582778573036,
          "from_longitude": 105.80609566063946
        }
      },
      {
        "id": 6664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8068379474123,
            21.015044310472874,
            105.8070873082897,
            21.015294205542205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8068379474123,
              21.015294205542205
            ],
            [
              105.80689439274224,
              21.01523763956271
            ],
            [
              105.8068948749575,
              21.015237155854454
            ],
            [
              105.80694392145988,
              21.015188004333265
            ],
            [
              105.80695596636816,
              21.01517593335057
            ],
            [
              105.8070873082897,
              21.015044310472874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6672",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015294205542205,
          "to_latitude": 21.015044310472874,
          "to_longitude": 105.8070873082897,
          "from_longitude": 105.8068379474123
        }
      },
      {
        "id": 6665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80750771254924,
            21.015028216209753,
            105.80775240142697,
            21.01528398723012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80775240142697,
              21.01528398723012
            ],
            [
              105.80763063779526,
              21.01515670869033
            ],
            [
              105.80759909240966,
              21.0151237343113
            ],
            [
              105.80750771254924,
              21.015028216209753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6673",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01528398723012,
          "to_latitude": 21.015028216209753,
          "to_longitude": 105.80750771254924,
          "from_longitude": 105.80775240142697
        }
      },
      {
        "id": 6666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8073618333088,
            21.01488132170002,
            105.80737060571231,
            21.014888734583096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8073618333088,
              21.014888734583096
            ],
            [
              105.80736369869385,
              21.014887161337946
            ],
            [
              105.80736375902207,
              21.014887110470827
            ],
            [
              105.80736565407076,
              21.014885508181553
            ],
            [
              105.8073683812535,
              21.014883201940698
            ],
            [
              105.80737060571231,
              21.01488132170002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6674",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014888734583096,
          "to_latitude": 21.01488132170002,
          "to_longitude": 105.80737060571231,
          "from_longitude": 105.8073618333088
        }
      },
      {
        "id": 6667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80718088031053,
            21.01487080504811,
            105.80748769095987,
            21.015007521610432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80748769095987,
              21.015007521610432
            ],
            [
              105.80737234391705,
              21.014898654549842
            ],
            [
              105.8073618333088,
              21.014888734583096
            ],
            [
              105.80735099107434,
              21.014878501865542
            ],
            [
              105.80732645076088,
              21.01487539920758
            ],
            [
              105.80728159882379,
              21.01487080504811
            ],
            [
              105.80718088031053,
              21.014983443226548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6675",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015007521610432,
          "to_latitude": 21.014983443226548,
          "to_longitude": 105.80718088031053,
          "from_longitude": 105.80748769095987
        }
      },
      {
        "id": 6668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80699639311142,
            21.01458558962529,
            105.8071841034868,
            21.014703990469275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80699639311142,
              21.01458558962529
            ],
            [
              105.80708106715757,
              21.014634525974515
            ],
            [
              105.80709917996829,
              21.014644994285767
            ],
            [
              105.80710071807968,
              21.01464588389591
            ],
            [
              105.8071841034868,
              21.014703990469275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6676",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01458558962529,
          "to_latitude": 21.014703990469275,
          "to_longitude": 105.8071841034868,
          "from_longitude": 105.80699639311142
        }
      },
      {
        "id": 6669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8069927830001,
            21.014432717115454,
            105.80700532315566,
            21.014437616363928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80700532315566,
              21.014437616363928
            ],
            [
              105.80700450475987,
              21.01443729779291
            ],
            [
              105.8069927830001,
              21.014432717115454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6677",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014437616363928,
          "to_latitude": 21.014432717115454,
          "to_longitude": 105.8069927830001,
          "from_longitude": 105.80700532315566
        }
      },
      {
        "id": 6670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80679345314716,
            21.014375586745548,
            105.80695662307353,
            21.014561844487716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80695662307353,
              21.014561844487716
            ],
            [
              105.8069549115186,
              21.01456072085922
            ],
            [
              105.80679785100529,
              21.014457589826396
            ],
            [
              105.80679345314716,
              21.014418798913926
            ],
            [
              105.80683475702513,
              21.014375586745548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6678",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014561844487716,
          "to_latitude": 21.014375586745548,
          "to_longitude": 105.80683475702513,
          "from_longitude": 105.80695662307353
        }
      },
      {
        "id": 6671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8069503244607,
            21.01433010412062,
            105.80709214120132,
            21.014523067112375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8069503244607,
              21.014523067112375
            ],
            [
              105.8069927830001,
              21.014432717115454
            ],
            [
              105.80700037900625,
              21.014416553022482
            ],
            [
              105.8070354137436,
              21.014379390566518
            ],
            [
              105.80709214120132,
              21.01433010412062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6679",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014523067112375,
          "to_latitude": 21.01433010412062,
          "to_longitude": 105.80709214120132,
          "from_longitude": 105.8069503244607
        }
      },
      {
        "id": 6672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80692653322694,
            21.014105917552694,
            105.80693591294774,
            21.014120416418724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80692653322694,
              21.014120416418724
            ],
            [
              105.80693065118305,
              21.01411396499167
            ],
            [
              105.80693118440963,
              21.01411312968518
            ],
            [
              105.80693574406999,
              21.0141061748708
            ],
            [
              105.80693591294774,
              21.014105917552694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6680",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014120416418724,
          "to_latitude": 21.014105917552694,
          "to_longitude": 105.80693591294774,
          "from_longitude": 105.80692653322694
        }
      },
      {
        "id": 6673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80680961873689,
            21.01405114220592,
            105.80707727756273,
            21.014285078193623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80707727756273,
              21.014285078193623
            ],
            [
              105.8070428927956,
              21.014232960576884
            ],
            [
              105.80694057312034,
              21.014128734509192
            ],
            [
              105.80693120965293,
              21.014123187024932
            ],
            [
              105.80692653322694,
              21.014120416418724
            ],
            [
              105.80680961873689,
              21.01405114220592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6681",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014285078193623,
          "to_latitude": 21.01405114220592,
          "to_longitude": 105.80680961873689,
          "from_longitude": 105.80707727756273
        }
      },
      {
        "id": 6674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80666748285682,
            21.014087649079283,
            105.80668355459684,
            21.014105138885427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80668355459684,
              21.014105138885427
            ],
            [
              105.80667861202684,
              21.01409974203966
            ],
            [
              105.80667857332227,
              21.01409969977139
            ],
            [
              105.80667047657536,
              21.014090903672816
            ],
            [
              105.80666885876126,
              21.014089143723194
            ],
            [
              105.80666748285682,
              21.014087649079283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6682",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014105138885427,
          "to_latitude": 21.014087649079283,
          "to_longitude": 105.80666748285682,
          "from_longitude": 105.80668355459684
        }
      },
      {
        "id": 6675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80653819576848,
            21.014041075171306,
            105.80676173255108,
            21.0142244075917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80653819576848,
              21.0142244075917
            ],
            [
              105.80653973974084,
              21.014222989428117
            ],
            [
              105.8065509813752,
              21.014213777390236
            ],
            [
              105.80667223540433,
              21.014114414525523
            ],
            [
              105.80668355459684,
              21.014105138885427
            ],
            [
              105.80676173255108,
              21.014041075171306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6683",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0142244075917,
          "to_latitude": 21.014041075171306,
          "to_longitude": 105.80676173255108,
          "from_longitude": 105.80653819576848
        }
      },
      {
        "id": 6676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80670136947354,
            21.01545342142809,
            105.806716288638,
            21.015464948150143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80670136947354,
              21.01545342142809
            ],
            [
              105.80670429825356,
              21.015455685485115
            ],
            [
              105.8067091289837,
              21.015459416400283
            ],
            [
              105.806716288638,
              21.015464948150143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6684",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01545342142809,
          "to_latitude": 21.015464948150143,
          "to_longitude": 105.806716288638,
          "from_longitude": 105.80670136947354
        }
      },
      {
        "id": 6677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80645743754494,
            21.015727309799104,
            105.80647149729285,
            21.015737328610015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80645743754494,
              21.015727309799104
            ],
            [
              105.80646051406411,
              21.015729499095066
            ],
            [
              105.80646501101317,
              21.015732695080978
            ],
            [
              105.80646519062432,
              21.015732823391364
            ],
            [
              105.80647149729285,
              21.015737328610015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6685",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015727309799104,
          "to_latitude": 21.015737328610015,
          "to_longitude": 105.80647149729285,
          "from_longitude": 105.80645743754494
        }
      },
      {
        "id": 6678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80629979405082,
            21.01561696262059,
            105.80656520505408,
            21.015908512285524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80629979405082,
              21.015908512285524
            ],
            [
              105.80643948744051,
              21.015745689796507
            ],
            [
              105.80645743754494,
              21.015727309799104
            ],
            [
              105.80656520505408,
              21.01561696262059
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6686",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015908512285524,
          "to_latitude": 21.01561696262059,
          "to_longitude": 105.80656520505408,
          "from_longitude": 105.80629979405082
        }
      },
      {
        "id": 6679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80658555063981,
            21.015325239035995,
            105.80682568608076,
            21.01559255213339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80658555063981,
              21.01559255213339
            ],
            [
              105.80669275688432,
              21.015462302171088
            ],
            [
              105.80670136947354,
              21.01545342142809
            ],
            [
              105.80682568608076,
              21.015325239035995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6687",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01559255213339,
          "to_latitude": 21.015325239035995,
          "to_longitude": 105.80682568608076,
          "from_longitude": 105.80658555063981
        }
      },
      {
        "id": 6680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81170845593334,
            21.0130684624256,
            105.81203540791758,
            21.013474281037197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81203540791758,
              21.013474281037197
            ],
            [
              105.81203442495246,
              21.013473060047296
            ],
            [
              105.8120288704999,
              21.01346607659926
            ],
            [
              105.81170845593334,
              21.0130684624256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6688",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013474281037197,
          "to_latitude": 21.0130684624256,
          "to_longitude": 105.81170845593334,
          "from_longitude": 105.81203540791758
        }
      },
      {
        "id": 6681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80616068094746,
            21.016037070620246,
            105.80616542029057,
            21.016055297007895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80616068094746,
              21.016037070620246
            ],
            [
              105.8061625020611,
              21.016044079179448
            ],
            [
              105.80616542029057,
              21.016055297007895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6689",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016037070620246,
          "to_latitude": 21.016055297007895,
          "to_longitude": 105.80616542029057,
          "from_longitude": 105.80616068094746
        }
      },
      {
        "id": 6682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80694392145988,
            21.015188004333265,
            105.80696012933184,
            21.015203770099767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80694392145988,
              21.015188004333265
            ],
            [
              105.80694564368622,
              21.015189679784097
            ],
            [
              105.80694625096552,
              21.01519027122861
            ],
            [
              105.80695423740679,
              21.01519803905589
            ],
            [
              105.80696012933184,
              21.015203770099767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6690",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015188004333265,
          "to_latitude": 21.015203770099767,
          "to_longitude": 105.80696012933184,
          "from_longitude": 105.80694392145988
        }
      },
      {
        "id": 6683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80759909240966,
            21.01511286486765,
            105.80761012503552,
            21.0151237343113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80759909240966,
              21.0151237343113
            ],
            [
              105.8075999535852,
              21.015122885704116
            ],
            [
              105.80760572920549,
              21.015117195040805
            ],
            [
              105.80761012503552,
              21.01511286486765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6691",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0151237343113,
          "to_latitude": 21.01511286486765,
          "to_longitude": 105.80761012503552,
          "from_longitude": 105.80759909240966
        }
      },
      {
        "id": 6684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80787957416781,
            21.01538088646016,
            105.80789242664156,
            21.015391960564187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80787957416781,
              21.015391960564187
            ],
            [
              105.80788146139025,
              21.015390333919207
            ],
            [
              105.80788788332055,
              21.015384800952226
            ],
            [
              105.80789242664156,
              21.01538088646016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6692",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015391960564187,
          "to_latitude": 21.01538088646016,
          "to_longitude": 105.80789242664156,
          "from_longitude": 105.80787957416781
        }
      },
      {
        "id": 6685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80821869934172,
            21.015469084342534,
            105.80822795042063,
            21.015479278495594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80821869934172,
              21.015469084342534
            ],
            [
              105.80822015668846,
              21.01547069239284
            ],
            [
              105.80822475996023,
              21.015475762929913
            ],
            [
              105.80822795042063,
              21.015479278495594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6693",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015469084342534,
          "to_latitude": 21.015479278495594,
          "to_longitude": 105.80822795042063,
          "from_longitude": 105.80821869934172
        }
      },
      {
        "id": 6686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80881683464911,
            21.015027531744348,
            105.80882769901233,
            21.0150409562713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80881683464911,
              21.015027531744348
            ],
            [
              105.8088182678742,
              21.015029304291893
            ],
            [
              105.80882284253995,
              21.015034955725096
            ],
            [
              105.80882769901233,
              21.0150409562713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6694",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015027531744348,
          "to_latitude": 21.0150409562713,
          "to_longitude": 105.80882769901233,
          "from_longitude": 105.80881683464911
        }
      },
      {
        "id": 6687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80976358033807,
            21.014331382558165,
            105.80976808191531,
            21.01433525314645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80976358033807,
              21.014331382558165
            ],
            [
              105.80976365282247,
              21.014331445439193
            ],
            [
              105.80976796402621,
              21.014335154352356
            ],
            [
              105.80976808191531,
              21.01433525314645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6695",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014331382558165,
          "to_latitude": 21.01433525314645,
          "to_longitude": 105.80976808191531,
          "from_longitude": 105.80976358033807
        }
      },
      {
        "id": 6688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81003600219223,
            21.01413928136979,
            105.81004120244481,
            21.014145881795102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81003600219223,
              21.01413928136979
            ],
            [
              105.8100383839038,
              21.01414230397623
            ],
            [
              105.81004094675048,
              21.01414555875331
            ],
            [
              105.81004120244481,
              21.014145881795102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6696",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01413928136979,
          "to_latitude": 21.014145881795102,
          "to_longitude": 105.81004120244481,
          "from_longitude": 105.81003600219223
        }
      },
      {
        "id": 6689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8099748387993,
            21.01285097231783,
            105.8099802578207,
            21.01285568858312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8099748387993,
              21.01285568858312
            ],
            [
              105.80997527442959,
              21.012855309862843
            ],
            [
              105.80997842913838,
              21.01285256348044
            ],
            [
              105.8099802578207,
              21.01285097231783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6697",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01285568858312,
          "to_latitude": 21.01285097231783,
          "to_longitude": 105.8099802578207,
          "from_longitude": 105.8099748387993
        }
      },
      {
        "id": 6690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80891066116888,
            21.013373760077265,
            105.80891842595682,
            21.013381808868647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80891842595682,
              21.013381808868647
            ],
            [
              105.8089163684481,
              21.013379677997566
            ],
            [
              105.80891256778294,
              21.013375736310405
            ],
            [
              105.80891066116888,
              21.013373760077265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6698",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013381808868647,
          "to_latitude": 21.013373760077265,
          "to_longitude": 105.80891066116888,
          "from_longitude": 105.80891842595682
        }
      },
      {
        "id": 6691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80708106715757,
            21.014625227985622,
            105.80708738129331,
            21.014634525974515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80708106715757,
              21.014634525974515
            ],
            [
              105.80708338723176,
              21.014631107987633
            ],
            [
              105.80708653667476,
              21.014626472126167
            ],
            [
              105.80708738129331,
              21.014625227985622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6699",
          "diaChiLapD": "Ngõ 594, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014634525974515,
          "to_latitude": 21.014625227985622,
          "to_longitude": 105.80708738129331,
          "from_longitude": 105.80708106715757
        }
      },
      {
        "id": 6692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81089662813208,
            21.01493741953686,
            105.81105159403594,
            21.01517045088388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81089662813208,
              21.01493741953686
            ],
            [
              105.8109038901493,
              21.014948340184738
            ],
            [
              105.81095859678939,
              21.015030606527656
            ],
            [
              105.81105159403594,
              21.01517045088388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6700",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01493741953686,
          "to_latitude": 21.01517045088388,
          "to_longitude": 105.81105159403594,
          "from_longitude": 105.81089662813208
        }
      },
      {
        "id": 6693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8105634338634,
            21.016990392292577,
            105.81076620984803,
            21.017089380538824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81076620984803,
              21.016990392292577
            ],
            [
              105.81072337567355,
              21.017011302940737
            ],
            [
              105.81071683436114,
              21.01701449631672
            ],
            [
              105.8105634338634,
              21.017089380538824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6701",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016990392292577,
          "to_latitude": 21.017089380538824,
          "to_longitude": 105.8105634338634,
          "from_longitude": 105.81076620984803
        }
      },
      {
        "id": 6694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81063444458493,
            21.017652900876264,
            105.81091543872775,
            21.017787147449326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81063444458493,
              21.017787147449326
            ],
            [
              105.8108650566643,
              21.017676970906344
            ],
            [
              105.81088480555083,
              21.017667535583325
            ],
            [
              105.81091543872775,
              21.017652900876264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6702",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017787147449326,
          "to_latitude": 21.017652900876264,
          "to_longitude": 105.81091543872775,
          "from_longitude": 105.81063444458493
        }
      },
      {
        "id": 6695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8108647686923,
            21.018073081462116,
            105.81114152711238,
            21.018200711951852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81114152711238,
              21.018073081462116
            ],
            [
              105.8108647686923,
              21.018200711951852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6703",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018073081462116,
          "to_latitude": 21.018200711951852,
          "to_longitude": 105.8108647686923,
          "from_longitude": 105.81114152711238
        }
      },
      {
        "id": 6696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80684586999904,
            21.016799201047977,
            105.80721785087603,
            21.016992150373184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80684586999904,
              21.016992150373184
            ],
            [
              105.80699837619228,
              21.01691304387518
            ],
            [
              105.80703106532046,
              21.016896087840994
            ],
            [
              105.80721785087603,
              21.016799201047977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6704",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016992150373184,
          "to_latitude": 21.016799201047977,
          "to_longitude": 105.80721785087603,
          "from_longitude": 105.80684586999904
        }
      },
      {
        "id": 6697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80698896522965,
            21.016894411545902,
            105.80699837619228,
            21.01691304387518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80699837619228,
              21.01691304387518
            ],
            [
              105.80699230706551,
              21.016901030814527
            ],
            [
              105.80698896522965,
              21.016894411545902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6705",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01691304387518,
          "to_latitude": 21.016894411545902,
          "to_longitude": 105.80698896522965,
          "from_longitude": 105.80699837619228
        }
      },
      {
        "id": 6698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8066843717919,
            21.016723473053013,
            105.8067546934555,
            21.016866784637347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8067546934555,
              21.016866784637347
            ],
            [
              105.80674904458006,
              21.016855281705375
            ],
            [
              105.8067480028834,
              21.016853153218033
            ],
            [
              105.8066843717919,
              21.016723473053013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6706",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016866784637347,
          "to_latitude": 21.016723473053013,
          "to_longitude": 105.8066843717919,
          "from_longitude": 105.8067546934555
        }
      },
      {
        "id": 6699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80750366068823,
            21.016344840408667,
            105.80801454995795,
            21.016646138307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80750366068823,
              21.016646138307
            ],
            [
              105.80801418265145,
              21.016345048089423
            ],
            [
              105.80801454995795,
              21.016344840408667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6707",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016646138307,
          "to_latitude": 21.016344840408667,
          "to_longitude": 105.80801454995795,
          "from_longitude": 105.80750366068823
        }
      },
      {
        "id": 6700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80793260089568,
            21.016183773809228,
            105.8080128390183,
            21.016327586925446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8080128390183,
              21.016327586925446
            ],
            [
              105.80798831113994,
              21.016283624940804
            ],
            [
              105.8079604545703,
              21.0162337002878
            ],
            [
              105.8079350224376,
              21.01618812038929
            ],
            [
              105.80793260089568,
              21.016183773809228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6708",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016327586925446,
          "to_latitude": 21.016183773809228,
          "to_longitude": 105.80793260089568,
          "from_longitude": 105.8080128390183
        }
      },
      {
        "id": 6701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80984777643283,
            21.016985563248287,
            105.81014252483389,
            21.017135163870257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81014252483389,
              21.016985563248287
            ],
            [
              105.80989441152502,
              21.017111419076656
            ],
            [
              105.80989150285714,
              21.017112894347903
            ],
            [
              105.80985569812165,
              21.017131605374043
            ],
            [
              105.8098533278559,
              21.01713267072548
            ],
            [
              105.80984777643283,
              21.017135163870257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6709",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016985563248287,
          "to_latitude": 21.017135163870257,
          "to_longitude": 105.80984777643283,
          "from_longitude": 105.81014252483389
        }
      },
      {
        "id": 6702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80987341027037,
            21.01740234150256,
            105.80990537698676,
            21.017446355387438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80990537698676,
              21.017446355387438
            ],
            [
              105.80990426537846,
              21.017444827900754
            ],
            [
              105.80987341027037,
              21.01740234150256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6710",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017446355387438,
          "to_latitude": 21.01740234150256,
          "to_longitude": 105.80987341027037,
          "from_longitude": 105.80990537698676
        }
      },
      {
        "id": 6703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80810571746643,
            21.01828178184841,
            105.80813357361075,
            21.018339933966946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80813357361075,
              21.018339933966946
            ],
            [
              105.80813208508992,
              21.01833683031843
            ],
            [
              105.80810571746643,
              21.01828178184841
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6711",
          "diaChiLapD": "Ngõ 97, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018339933966946,
          "to_latitude": 21.01828178184841,
          "to_longitude": 105.80810571746643,
          "from_longitude": 105.80813357361075
        }
      },
      {
        "id": 6704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80852967429789,
            21.016141351220273,
            105.8092458971437,
            21.01648302666811
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8092458971437,
              21.016141351220273
            ],
            [
              105.80924204171092,
              21.01614403318131
            ],
            [
              105.80906088313392,
              21.01627004529015
            ],
            [
              105.80876802589063,
              21.01643065624893
            ],
            [
              105.80873755365427,
              21.016368092639933
            ],
            [
              105.80852967429789,
              21.01648302666811
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.1_Ngõ 49/64, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6712",
          "diaChiLapD": "Ngõ 49/64, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016141351220273,
          "to_latitude": 21.01648302666811,
          "to_longitude": 105.80852967429789,
          "from_longitude": 105.8092458971437
        }
      },
      {
        "id": 6705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80895678734622,
            21.01608833014046,
            105.80906088313392,
            21.01627004529015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80906088313392,
              21.01627004529015
            ],
            [
              105.80895678734622,
              21.01608833014046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.1_Ngõ 49/64, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6713",
          "diaChiLapD": "Ngõ 49/64, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01627004529015,
          "to_latitude": 21.01608833014046,
          "to_longitude": 105.80895678734622,
          "from_longitude": 105.80906088313392
        }
      },
      {
        "id": 6706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8094537332496,
            21.016542997463773,
            105.80959460779921,
            21.01679451037874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80959460779921,
              21.01679451037874
            ],
            [
              105.80950145969506,
              21.01662862497284
            ],
            [
              105.8094537332496,
              21.016542997463773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6714",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01679451037874,
          "to_latitude": 21.016542997463773,
          "to_longitude": 105.8094537332496,
          "from_longitude": 105.80959460779921
        }
      },
      {
        "id": 6707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80959460779921,
            21.016769687330108,
            105.80965108447535,
            21.01679451037874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80959460779921,
              21.01679451037874
            ],
            [
              105.80962950626244,
              21.016779171860993
            ],
            [
              105.80964178238162,
              21.0167737762588
            ],
            [
              105.80965108447535,
              21.016769687330108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6715",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01679451037874,
          "to_latitude": 21.016769687330108,
          "to_longitude": 105.80965108447535,
          "from_longitude": 105.80959460779921
        }
      },
      {
        "id": 6708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81037551364608,
            21.017821387277014,
            105.8105733569651,
            21.017916999036803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81037551364608,
              21.017916999036803
            ],
            [
              105.81053946154613,
              21.017837768053234
            ],
            [
              105.81056080545488,
              21.017827453551675
            ],
            [
              105.8105733569651,
              21.017821387277014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6716",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017916999036803,
          "to_latitude": 21.017821387277014,
          "to_longitude": 105.8105733569651,
          "from_longitude": 105.81037551364608
        }
      },
      {
        "id": 6709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80669337741418,
            21.01509544457129,
            105.80689439274224,
            21.01523763956271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80689439274224,
              21.01523763956271
            ],
            [
              105.80676117663515,
              21.01509544457129
            ],
            [
              105.80669337741418,
              21.01515377505928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6717",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01523763956271,
          "to_latitude": 21.01515377505928,
          "to_longitude": 105.80669337741418,
          "from_longitude": 105.80689439274224
        }
      },
      {
        "id": 6710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80781618281937,
            21.014316582450228,
            105.80800865321339,
            21.01444857324904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80781618281937,
              21.014316582450228
            ],
            [
              105.80796535220065,
              21.014445797327326
            ],
            [
              105.80798398871752,
              21.01442811933783
            ],
            [
              105.80800865321339,
              21.01444857324904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6718",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014316582450228,
          "to_latitude": 21.01444857324904,
          "to_longitude": 105.80800865321339,
          "from_longitude": 105.80781618281937
        }
      },
      {
        "id": 6711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80823786329647,
            21.014400782163147,
            105.80831633256058,
            21.014529717231337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80826334279327,
              21.014400782163147
            ],
            [
              105.80828076403223,
              21.014416964750698
            ],
            [
              105.80831633256058,
              21.01445000298924
            ],
            [
              105.80823786329647,
              21.014529717231337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6719",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014400782163147,
          "to_latitude": 21.014529717231337,
          "to_longitude": 105.80823786329647,
          "from_longitude": 105.80826334279327
        }
      },
      {
        "id": 6712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80819960548531,
            21.014416964750698,
            105.80828076403223,
            21.01449941047139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80828076403223,
              21.014416964750698
            ],
            [
              105.80819960548531,
              21.01449941047139
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6720",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014416964750698,
          "to_latitude": 21.01449941047139,
          "to_longitude": 105.80819960548531,
          "from_longitude": 105.80828076403223
        }
      },
      {
        "id": 6713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8088612143026,
            21.014245897341052,
            105.80907058699667,
            21.014533160547735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8088612143026,
              21.014533160547735
            ],
            [
              105.80898661951117,
              21.014417002470072
            ],
            [
              105.80907058699667,
              21.01434108511657
            ],
            [
              105.80898569673424,
              21.014245897341052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6721",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014533160547735,
          "to_latitude": 21.014245897341052,
          "to_longitude": 105.80898569673424,
          "from_longitude": 105.8088612143026
        }
      },
      {
        "id": 6714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80932245170916,
            21.01442396970669,
            105.80944059483316,
            21.014562017188364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80944059483316,
              21.014562017188364
            ],
            [
              105.8093697565612,
              21.014474217111516
            ],
            [
              105.80932245170916,
              21.01442396970669
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6722",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014562017188364,
          "to_latitude": 21.01442396970669,
          "to_longitude": 105.80932245170916,
          "from_longitude": 105.80944059483316
        }
      },
      {
        "id": 6715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80912748075401,
            21.01373826690878,
            105.80921534933285,
            21.01387121222679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80912748075401,
              21.01387121222679
            ],
            [
              105.80921534933285,
              21.013781719690368
            ],
            [
              105.80918235451254,
              21.01373826690878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6723",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01387121222679,
          "to_latitude": 21.01373826690878,
          "to_longitude": 105.80918235451254,
          "from_longitude": 105.80912748075401
        }
      },
      {
        "id": 6716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80921669517926,
            21.013809927053167,
            105.80933413433834,
            21.013921898051773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80921669517926,
              21.013921898051773
            ],
            [
              105.80933413433834,
              21.013809927053167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6724",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013921898051773,
          "to_latitude": 21.013809927053167,
          "to_longitude": 105.80933413433834,
          "from_longitude": 105.80921669517926
        }
      },
      {
        "id": 6717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80852696706222,
            21.013722895326907,
            105.80879309739963,
            21.01392988256914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80852696706222,
              21.013722895326907
            ],
            [
              105.80863125108121,
              21.013839839391718
            ],
            [
              105.80867106380059,
              21.013814569301847
            ],
            [
              105.80879309739963,
              21.01392988256914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6725",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013722895326907,
          "to_latitude": 21.01392988256914,
          "to_longitude": 105.80879309739963,
          "from_longitude": 105.80852696706222
        }
      },
      {
        "id": 6718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80829358549605,
            21.013815145460438,
            105.80833327524695,
            21.013864156699007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80833327524695,
              21.013864156699007
            ],
            [
              105.80833043404995,
              21.0138606503132
            ],
            [
              105.80829358549605,
              21.013815145460438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6726",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013864156699007,
          "to_latitude": 21.013815145460438,
          "to_longitude": 105.80829358549605,
          "from_longitude": 105.80833327524695
        }
      },
      {
        "id": 6719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80882720899447,
            21.01346131842496,
            105.8089098646955,
            21.01353359748757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80882720899447,
              21.01346131842496
            ],
            [
              105.8089098646955,
              21.01353359748757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6727",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01346131842496,
          "to_latitude": 21.01353359748757,
          "to_longitude": 105.8089098646955,
          "from_longitude": 105.80882720899447
        }
      },
      {
        "id": 6720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81022954267273,
            21.01361574031585,
            105.81034001287306,
            21.0136881417611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81022954267273,
              21.0136881417611
            ],
            [
              105.81034001287306,
              21.01361574031585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6728",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0136881417611,
          "to_latitude": 21.01361574031585,
          "to_longitude": 105.81034001287306,
          "from_longitude": 105.81022954267273
        }
      },
      {
        "id": 6721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81009915859889,
            21.01372945914163,
            105.81026442509192,
            21.01384463463056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81009915859889,
              21.01384463463056
            ],
            [
              105.81025774654583,
              21.013734112768887
            ],
            [
              105.81026201874194,
              21.013731135252183
            ],
            [
              105.81026442509192,
              21.01372945914163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6729",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01384463463056,
          "to_latitude": 21.01372945914163,
          "to_longitude": 105.81026442509192,
          "from_longitude": 105.81009915859889
        }
      },
      {
        "id": 6722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81009805450671,
            21.013502670189627,
            105.8104249984354,
            21.01386914374765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81009805450671,
              21.013502670189627
            ],
            [
              105.81015650441535,
              21.01359160741843
            ],
            [
              105.8102160819045,
              21.013672197935815
            ],
            [
              105.81022954267273,
              21.0136881417611
            ],
            [
              105.81026442509192,
              21.01372945914163
            ],
            [
              105.81027371519878,
              21.01374046319502
            ],
            [
              105.81028533506075,
              21.013747720397944
            ],
            [
              105.81032067373273,
              21.013768964944024
            ],
            [
              105.81036116684218,
              21.01380203800511
            ],
            [
              105.81042022244009,
              21.01386522390293
            ],
            [
              105.8104249984354,
              21.01386914374765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6730",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013502670189627,
          "to_latitude": 21.01386914374765,
          "to_longitude": 105.8104249984354,
          "from_longitude": 105.81009805450671
        }
      },
      {
        "id": 6723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80935598824516,
            21.013294726111383,
            105.80958937672254,
            21.013433704065474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80958937672254,
              21.013318197033513
            ],
            [
              105.80955436676513,
              21.013294726111383
            ],
            [
              105.80935598824516,
              21.013433704065474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.4_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6731",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013318197033513,
          "to_latitude": 21.013433704065474,
          "to_longitude": 105.80935598824516,
          "from_longitude": 105.80958937672254
        }
      },
      {
        "id": 6724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80963842956032,
            21.01325436104839,
            105.80987422855102,
            21.01341182315576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80963842956032,
              21.013261409740984
            ],
            [
              105.80964078636923,
              21.013260357102954
            ],
            [
              105.8096499856106,
              21.01325436104839
            ],
            [
              105.8097030645288,
              21.01330405521859
            ],
            [
              105.80975152511115,
              21.013273883453245
            ],
            [
              105.80987422855102,
              21.01341182315576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.1_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6732",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013261409740984,
          "to_latitude": 21.01341182315576,
          "to_longitude": 105.80987422855102,
          "from_longitude": 105.80963842956032
        }
      },
      {
        "id": 6725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80959953896476,
            21.013078257542553,
            105.80973778453827,
            21.01320064309504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80959953896476,
              21.01320064309504
            ],
            [
              105.80973778453827,
              21.01310112048633
            ],
            [
              105.80972116893126,
              21.013078257542553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6733",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01320064309504,
          "to_latitude": 21.013078257542553,
          "to_longitude": 105.80972116893126,
          "from_longitude": 105.80959953896476
        }
      },
      {
        "id": 6726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809686258441,
            21.01340861892404,
            105.80989131603779,
            21.013606354245148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809686258441,
              21.01340861892404
            ],
            [
              105.80971876473068,
              21.013442637898905
            ],
            [
              105.80972463532103,
              21.013445836294547
            ],
            [
              105.80972659922371,
              21.013448087730417
            ],
            [
              105.80977278154974,
              21.013501050965978
            ],
            [
              105.80983085562282,
              21.013556576047804
            ],
            [
              105.80989131603779,
              21.013606354245148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_46_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6734",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01340861892404,
          "to_latitude": 21.013606354245148,
          "to_longitude": 105.80989131603779,
          "from_longitude": 105.809686258441
        }
      },
      {
        "id": 6727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80960763898243,
            21.0133586732494,
            105.80966132128826,
            21.013407990231013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80960763898243,
              21.0133586732494
            ],
            [
              105.80964773757206,
              21.013407990231013
            ],
            [
              105.80965529008375,
              21.013404326020428
            ],
            [
              105.80966132128826,
              21.013400390854407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54.5_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6735",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0133586732494,
          "to_latitude": 21.013400390854407,
          "to_longitude": 105.80966132128826,
          "from_longitude": 105.80960763898243
        }
      },
      {
        "id": 6728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80988981369123,
            21.013550910784808,
            105.81010275186425,
            21.013673007640246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80988981369123,
              21.01361725704748
            ],
            [
              105.80989327079321,
              21.013620451570397
            ],
            [
              105.80990391569392,
              21.013630288546906
            ],
            [
              105.80999446145287,
              21.013550910784808
            ],
            [
              105.8100503237516,
              21.013602264368092
            ],
            [
              105.81010275186425,
              21.013673007640246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54.1_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6736",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01361725704748,
          "to_latitude": 21.013673007640246,
          "to_longitude": 105.81010275186425,
          "from_longitude": 105.80988981369123
        }
      },
      {
        "id": 6729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81010275186425,
            21.0136428226209,
            105.8101472956456,
            21.013673007640246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81010275186425,
              21.013673007640246
            ],
            [
              105.8101472956456,
              21.013651054826845
            ],
            [
              105.81014539229194,
              21.013648439106543
            ],
            [
              105.81014164218296,
              21.0136428226209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54.1_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6737",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013673007640246,
          "to_latitude": 21.0136428226209,
          "to_longitude": 105.81014164218296,
          "from_longitude": 105.81010275186425
        }
      },
      {
        "id": 6730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80947079513133,
            21.012929423511622,
            105.80964105978266,
            21.013046671599962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80947079513133,
              21.013046671599962
            ],
            [
              105.8096041859889,
              21.01295359596102
            ],
            [
              105.80964105978266,
              21.012929423511622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6738",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013046671599962,
          "to_latitude": 21.012929423511622,
          "to_longitude": 105.80964105978266,
          "from_longitude": 105.80947079513133
        }
      },
      {
        "id": 6731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81003553168937,
            21.01310977395003,
            105.81019873371584,
            21.01320446748781
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81003553168937,
              21.013147375651062
            ],
            [
              105.81009081497265,
              21.01320446748781
            ],
            [
              105.81019873371584,
              21.01310977395003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6739",
          "diaChiLapD": "Ngõ 562, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013147375651062,
          "to_latitude": 21.01310977395003,
          "to_longitude": 105.81019873371584,
          "from_longitude": 105.81003553168937
        }
      },
      {
        "id": 6732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80978611163525,
            21.01394919652131,
            105.80999561020113,
            21.01416840680323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80978611163525,
              21.013982118628814
            ],
            [
              105.80983418914695,
              21.01394919652131
            ],
            [
              105.80999561020113,
              21.01416840680323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6740",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013982118628814,
          "to_latitude": 21.01416840680323,
          "to_longitude": 105.80999561020113,
          "from_longitude": 105.80978611163525
        }
      },
      {
        "id": 6733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80971000098926,
            21.014152697970886,
            105.8098384161732,
            21.014279241541203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8098384161732,
              21.014279241541203
            ],
            [
              105.8097737544325,
              21.014194837277007
            ],
            [
              105.80975640620633,
              21.01420610362541
            ],
            [
              105.80971000098926,
              21.014152697970886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6741",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014279241541203,
          "to_latitude": 21.014152697970886,
          "to_longitude": 105.80971000098926,
          "from_longitude": 105.8098384161732
        }
      },
      {
        "id": 6734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8077894696218,
            21.015140891289093,
            105.80798827921213,
            21.015272351965333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8077894696218,
              21.015272351965333
            ],
            [
              105.8078741455411,
              21.015207567728385
            ],
            [
              105.80797487526108,
              21.015147264001605
            ],
            [
              105.80798827921213,
              21.015140891289093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6742",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015272351965333,
          "to_latitude": 21.015140891289093,
          "to_longitude": 105.80798827921213,
          "from_longitude": 105.8077894696218
        }
      },
      {
        "id": 6735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80653973974084,
            21.014222989428117,
            105.80665731209504,
            21.014411618575153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8066036480698,
              21.014411618575153
            ],
            [
              105.80665731209504,
              21.014357354644506
            ],
            [
              105.80665258087393,
              21.01433741841536
            ],
            [
              105.80654462510182,
              21.014227943966645
            ],
            [
              105.80653973974084,
              21.014222989428117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6743",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014411618575153,
          "to_latitude": 21.014222989428117,
          "to_longitude": 105.80653973974084,
          "from_longitude": 105.8066036480698
        }
      },
      {
        "id": 6736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80665258087393,
            21.01429501371615,
            105.80670075346883,
            21.01433741841536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80665258087393,
              21.01433741841536
            ],
            [
              105.80670075346883,
              21.01429501371615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6744",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01433741841536,
          "to_latitude": 21.01429501371615,
          "to_longitude": 105.80670075346883,
          "from_longitude": 105.80665258087393
        }
      },
      {
        "id": 6737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80578725640171,
            21.015544386373904,
            105.80584928831122,
            21.015575691666932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80578725640171,
              21.015575691666932
            ],
            [
              105.80579112734353,
              21.015573738615444
            ],
            [
              105.80584928831122,
              21.015544386373904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 107, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6745",
          "diaChiLapD": "Ngõ 107, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015575691666932,
          "to_latitude": 21.015544386373904,
          "to_longitude": 105.80584928831122,
          "from_longitude": 105.80578725640171
        }
      },
      {
        "id": 6738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81039461183275,
            21.01227219880234,
            105.81045876505456,
            21.012308360748055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81045876505456,
              21.01227219880234
            ],
            [
              105.81045697652237,
              21.01227320719591
            ],
            [
              105.81039461183275,
              21.012308360748055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6746",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01227219880234,
          "to_latitude": 21.012308360748055,
          "to_longitude": 105.81039461183275,
          "from_longitude": 105.81045876505456
        }
      },
      {
        "id": 6739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81042113383319,
            21.01199429188999,
            105.81080638789221,
            21.012225906908576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81042113383319,
              21.012225906908576
            ],
            [
              105.81042286529122,
              21.012224839174106
            ],
            [
              105.81068410609818,
              21.012063726780948
            ],
            [
              105.81073798646062,
              21.012033905571034
            ],
            [
              105.8107564695051,
              21.012025501473502
            ],
            [
              105.81080638789221,
              21.01199429188999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6747",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012225906908576,
          "to_latitude": 21.01199429188999,
          "to_longitude": 105.81080638789221,
          "from_longitude": 105.81042113383319
        }
      },
      {
        "id": 6740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81068410609818,
            21.012063726780948,
            105.81077664604685,
            21.012105009637693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81068410609818,
              21.012063726780948
            ],
            [
              105.81071332716944,
              21.012105009637693
            ],
            [
              105.81077664604685,
              21.01206685360861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6748",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012063726780948,
          "to_latitude": 21.01206685360861,
          "to_longitude": 105.81077664604685,
          "from_longitude": 105.81068410609818
        }
      },
      {
        "id": 6741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81065611063993,
            21.011916010605006,
            105.81073798646062,
            21.012033905571034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81073798646062,
              21.012033905571034
            ],
            [
              105.81065611063993,
              21.011916010605006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6749",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012033905571034,
          "to_latitude": 21.011916010605006,
          "to_longitude": 105.81065611063993,
          "from_longitude": 105.81073798646062
        }
      },
      {
        "id": 6742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81028039291682,
            21.01197253950021,
            105.8104185127646,
            21.012157202631297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8104185127646,
              21.012157202631297
            ],
            [
              105.81036132733797,
              21.012080746586186
            ],
            [
              105.81030797984522,
              21.012009422454614
            ],
            [
              105.81028039291682,
              21.01197253950021
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6750",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012157202631297,
          "to_latitude": 21.01197253950021,
          "to_longitude": 105.81028039291682,
          "from_longitude": 105.8104185127646
        }
      },
      {
        "id": 6743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81027957792999,
            21.012009422454614,
            105.81030797984522,
            21.01203055507475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81030797984522,
              21.012009422454614
            ],
            [
              105.81028919214086,
              21.012023401781477
            ],
            [
              105.81028185813301,
              21.01202885879083
            ],
            [
              105.81027957792999,
              21.01203055507475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6751",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012009422454614,
          "to_latitude": 21.01203055507475,
          "to_longitude": 105.81027957792999,
          "from_longitude": 105.81030797984522
        }
      },
      {
        "id": 6744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81115560791291,
            21.01305084069713,
            105.81145200681412,
            21.013226209553068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81115560791291,
              21.013226209553068
            ],
            [
              105.8111588277583,
              21.013224304647995
            ],
            [
              105.81145200681412,
              21.01305084069713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6752",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013226209553068,
          "to_latitude": 21.01305084069713,
          "to_longitude": 105.81145200681412,
          "from_longitude": 105.81115560791291
        }
      },
      {
        "id": 6745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81082216265698,
            21.01281106387239,
            105.81087308002617,
            21.012840090619846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81082216265698,
              21.012840090619846
            ],
            [
              105.81087154184303,
              21.012811941012313
            ],
            [
              105.81087308002617,
              21.01281106387239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6753",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012840090619846,
          "to_latitude": 21.01281106387239,
          "to_longitude": 105.81087308002617,
          "from_longitude": 105.81082216265698
        }
      },
      {
        "id": 6746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8110508049841,
            21.013124681933174,
            105.81109767050211,
            21.01315376078933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110508049841,
              21.01315376078933
            ],
            [
              105.81109484135045,
              21.0131264368451
            ],
            [
              105.81109767050211,
              21.013124681933174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6754",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01315376078933,
          "to_latitude": 21.013124681933174,
          "to_longitude": 105.81109767050211,
          "from_longitude": 105.8110508049841
        }
      },
      {
        "id": 6747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81096900397146,
            21.013011587518715,
            105.81102041003152,
            21.01303794579225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81096900397146,
              21.01303794579225
            ],
            [
              105.8110181576786,
              21.013012742645714
            ],
            [
              105.81102041003152,
              21.013011587518715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6755",
          "diaChiLapD": "Ngõ 538, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01303794579225,
          "to_latitude": 21.013011587518715,
          "to_longitude": 105.81102041003152,
          "from_longitude": 105.81096900397146
        }
      },
      {
        "id": 6748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.810603649261,
            21.011288385372644,
            105.81092462539971,
            21.011550347351836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81092462539971,
              21.011481507268563
            ],
            [
              105.81083468427934,
              21.011550347351836
            ],
            [
              105.81078348418437,
              21.01149387576706
            ],
            [
              105.81060841031876,
              21.011293828130924
            ],
            [
              105.810603649261,
              21.011288385372644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Ngõ 530, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6756",
          "diaChiLapD": "Ngõ 530, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011481507268563,
          "to_latitude": 21.011288385372644,
          "to_longitude": 105.810603649261,
          "from_longitude": 105.81092462539971
        }
      },
      {
        "id": 6749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81078348418437,
            21.011406663633636,
            105.81089682170574,
            21.01149387576706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81078348418437,
              21.01149387576706
            ],
            [
              105.81089682170574,
              21.011406663633636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Ngõ 530, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6757",
          "diaChiLapD": "Ngõ 530, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01149387576706,
          "to_latitude": 21.011406663633636,
          "to_longitude": 105.81089682170574,
          "from_longitude": 105.81078348418437
        }
      },
      {
        "id": 6750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81122456199087,
            21.011634985975114,
            105.81139635481013,
            21.011736065335576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81122456199087,
              21.01171476175338
            ],
            [
              105.81124748036173,
              21.011736065335576
            ],
            [
              105.81139635481013,
              21.011634985975114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6758",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01171476175338,
          "to_latitude": 21.011634985975114,
          "to_longitude": 105.81139635481013,
          "from_longitude": 105.81122456199087
        }
      },
      {
        "id": 6751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81130199593117,
            21.011649840227683,
            105.8114072037887,
            21.011840931856113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81138731465325,
              21.011840931856113
            ],
            [
              105.81130199593117,
              21.011726628607178
            ],
            [
              105.8114072037887,
              21.011649840227683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6759",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011840931856113,
          "to_latitude": 21.011649840227683,
          "to_longitude": 105.8114072037887,
          "from_longitude": 105.81138731465325
        }
      },
      {
        "id": 6752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81119919517506,
            21.01184335797378,
            105.81127990375163,
            21.01194438140183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81127990375163,
              21.01194438140183
            ],
            [
              105.81119919517506,
              21.01184335797378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6760",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01194438140183,
          "to_latitude": 21.01184335797378,
          "to_longitude": 105.81119919517506,
          "from_longitude": 105.81127990375163
        }
      },
      {
        "id": 6753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81096455887975,
            21.011804326279915,
            105.8110450582772,
            21.0118629637156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81096455887975,
              21.0118629637156
            ],
            [
              105.8110450582772,
              21.011804326279915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6761",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0118629637156,
          "to_latitude": 21.011804326279915,
          "to_longitude": 105.8110450582772,
          "from_longitude": 105.81096455887975
        }
      },
      {
        "id": 6754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8110486297481,
            21.01203655552566,
            105.8111136412437,
            21.012081342220146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110486297481,
              21.012081342220146
            ],
            [
              105.8111136412437,
              21.01203655552566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6762",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012081342220146,
          "to_latitude": 21.01203655552566,
          "to_longitude": 105.8111136412437,
          "from_longitude": 105.8110486297481
        }
      },
      {
        "id": 6755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81206066637114,
            21.012616947881163,
            105.81220350228442,
            21.012878740363238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81208603653592,
              21.012878740363238
            ],
            [
              105.81206066637114,
              21.012855919440447
            ],
            [
              105.81212209700395,
              21.012769975774404
            ],
            [
              105.81213295280439,
              21.01274430478064
            ],
            [
              105.81220196571351,
              21.012619719998963
            ],
            [
              105.81220350228442,
              21.012616947881163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6763",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012878740363238,
          "to_latitude": 21.012616947881163,
          "to_longitude": 105.81220350228442,
          "from_longitude": 105.81208603653592
        }
      },
      {
        "id": 6756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81197395148614,
            21.01243054380665,
            105.81205689686155,
            21.01247747038198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81197395148614,
              21.01247747038198
            ],
            [
              105.81205264569141,
              21.01243294952273
            ],
            [
              105.81205689686155,
              21.01243054380665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6764",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01247747038198,
          "to_latitude": 21.01243054380665,
          "to_longitude": 105.81205689686155,
          "from_longitude": 105.81197395148614
        }
      },
      {
        "id": 6757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8120778321486,
            21.012591109009747,
            105.8121831101042,
            21.01265108098748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8120778321486,
              21.01265108098748
            ],
            [
              105.81217742440901,
              21.012594348030735
            ],
            [
              105.8121831101042,
              21.012591109009747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6765",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01265108098748,
          "to_latitude": 21.012591109009747,
          "to_longitude": 105.8121831101042,
          "from_longitude": 105.8120778321486
        }
      },
      {
        "id": 6758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81179047808925,
            21.012226106515453,
            105.81190141862692,
            21.012264943586263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81179047808925,
              21.012264943586263
            ],
            [
              105.81189793346725,
              21.012227327149272
            ],
            [
              105.81190141862692,
              21.012226106515453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6766",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012264943586263,
          "to_latitude": 21.012226106515453,
          "to_longitude": 105.81190141862692,
          "from_longitude": 105.81179047808925
        }
      },
      {
        "id": 6759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81156042853375,
            21.012122895481596,
            105.81162372029381,
            21.01222787755809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81162372029381,
              21.01222787755809
            ],
            [
              105.81160469499268,
              21.01219631916956
            ],
            [
              105.81158527272318,
              21.012164104218215
            ],
            [
              105.81156042853375,
              21.012122895481596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6767",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01222787755809,
          "to_latitude": 21.012122895481596,
          "to_longitude": 105.81156042853375,
          "from_longitude": 105.81162372029381
        }
      },
      {
        "id": 6760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81160469499268,
            21.012087968865607,
            105.81180082395551,
            21.01219631916956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81160469499268,
              21.01219631916956
            ],
            [
              105.81162305652533,
              21.01218608561682
            ],
            [
              105.8117951751292,
              21.012091086665144
            ],
            [
              105.81180082395551,
              21.012087968865607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6768",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01219631916956,
          "to_latitude": 21.012087968865607,
          "to_longitude": 105.81180082395551,
          "from_longitude": 105.81160469499268
        }
      },
      {
        "id": 6761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81169674756153,
            21.012033793323983,
            105.81175327852063,
            21.01206490285344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81169674756153,
              21.01206490285344
            ],
            [
              105.81175117146331,
              21.012034953186443
            ],
            [
              105.81175327852063,
              21.012033793323983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6769",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01206490285344,
          "to_latitude": 21.012033793323983,
          "to_longitude": 105.81175327852063,
          "from_longitude": 105.81169674756153
        }
      },
      {
        "id": 6762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81150567457412,
            21.011774549535986,
            105.81157622608873,
            21.011815135712173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81150567457412,
              21.011815135712173
            ],
            [
              105.81156059863528,
              21.01178376565204
            ],
            [
              105.81156651958145,
              21.011780274432187
            ],
            [
              105.81157622608873,
              21.011774549535986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6770",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011815135712173,
          "to_latitude": 21.011774549535986,
          "to_longitude": 105.81157622608873,
          "from_longitude": 105.81150567457412
        }
      },
      {
        "id": 6763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81141969053462,
            21.011927490462956,
            105.81167698699927,
            21.01208559675332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81141969053462,
              21.01208559675332
            ],
            [
              105.81167414628518,
              21.011929236406804
            ],
            [
              105.81167698699927,
              21.011927490462956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6771",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01208559675332,
          "to_latitude": 21.011927490462956,
          "to_longitude": 105.81167698699927,
          "from_longitude": 105.81141969053462
        }
      },
      {
        "id": 6764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81157743161972,
            21.01188505586553,
            105.81164782776939,
            21.01192520597446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81157743161972,
              21.01192520597446
            ],
            [
              105.81163982239111,
              21.01188962193789
            ],
            [
              105.81164782776939,
              21.01188505586553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6772",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01192520597446,
          "to_latitude": 21.01188505586553,
          "to_longitude": 105.81164782776939,
          "from_longitude": 105.81157743161972
        }
      },
      {
        "id": 6765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80628168053632,
            21.01480056567744,
            105.80638599478935,
            21.014899182392416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80628168053632,
              21.014899182392416
            ],
            [
              105.80628201551136,
              21.01489886557786
            ],
            [
              105.80638599478935,
              21.01480056567744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Ngõ 560, Đường Láng",
          "maTaiSan": "04.O9B.DODV.6773",
          "diaChiLapD": "Ngõ 560, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014899182392416,
          "to_latitude": 21.01480056567744,
          "to_longitude": 105.80638599478935,
          "from_longitude": 105.80628168053632
        }
      },
      {
        "id": 6766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80794678076376,
            21.014604084434833,
            105.80803925094166,
            21.014671832819324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80794678076376,
              21.014671832819324
            ],
            [
              105.80799354200745,
              21.014604084434833
            ],
            [
              105.80803925094166,
              21.014622603035193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_37_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6774",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014671832819324,
          "to_latitude": 21.014622603035193,
          "to_longitude": 105.80803925094166,
          "from_longitude": 105.80794678076376
        }
      },
      {
        "id": 6767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80804593334085,
            21.01410846136762,
            105.80830719241038,
            21.01435345328176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80804593334085,
              21.01410846136762
            ],
            [
              105.80805538318138,
              21.01411842878301
            ],
            [
              105.8080893584774,
              21.014131082434375
            ],
            [
              105.80818652401639,
              21.0142453219868
            ],
            [
              105.80830719241038,
              21.01435345328176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6775",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01410846136762,
          "to_latitude": 21.01435345328176,
          "to_longitude": 105.80830719241038,
          "from_longitude": 105.80804593334085
        }
      },
      {
        "id": 6768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80846624809514,
            21.01496331835585,
            105.80867174415829,
            21.0151585826827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80846624809514,
              21.01496331835585
            ],
            [
              105.80847490779965,
              21.01497212794679
            ],
            [
              105.80865654919602,
              21.01514391691868
            ],
            [
              105.80867174415829,
              21.0151585826827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6776",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01496331835585,
          "to_latitude": 21.0151585826827,
          "to_longitude": 105.80867174415829,
          "from_longitude": 105.80846624809514
        }
      },
      {
        "id": 6769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80846624809514,
            21.014856772151983,
            105.8085300052578,
            21.01496331835585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8085300052578,
              21.014856772151983
            ],
            [
              105.80846624809514,
              21.01496331835585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6777",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014856772151983,
          "to_latitude": 21.01496331835585,
          "to_longitude": 105.80846624809514,
          "from_longitude": 105.8085300052578
        }
      },
      {
        "id": 6770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80815568971732,
            21.01435345328176,
            105.80830719241038,
            21.014516977336452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80830719241038,
              21.01435345328176
            ],
            [
              105.80826334279327,
              21.014400782163147
            ],
            [
              105.80815568971732,
              21.014516977336452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6778",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01435345328176,
          "to_latitude": 21.014516977336452,
          "to_longitude": 105.80815568971732,
          "from_longitude": 105.80830719241038
        }
      },
      {
        "id": 6771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8087061458866,
            21.01438670839383,
            105.80903707605232,
            21.0147126900353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80901963099679,
              21.0147126900353
            ],
            [
              105.80903707605232,
              21.014692067653137
            ],
            [
              105.80887097956914,
              21.014542383058
            ],
            [
              105.8088612143026,
              21.014533160547735
            ],
            [
              105.8087061458866,
              21.01438670839383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6779",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0147126900353,
          "to_latitude": 21.01438670839383,
          "to_longitude": 105.8087061458866,
          "from_longitude": 105.80901963099679
        }
      },
      {
        "id": 6772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80901963099679,
            21.0147126900353,
            105.80912872983416,
            21.014807379682168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80912872983416,
              21.014807379682168
            ],
            [
              105.80911892830567,
              21.014795185737018
            ],
            [
              105.80901963099679,
              21.0147126900353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6780",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014807379682168,
          "to_latitude": 21.0147126900353,
          "to_longitude": 105.80901963099679,
          "from_longitude": 105.80912872983416
        }
      },
      {
        "id": 6773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8085089793428,
            21.014173964932898,
            105.80861913215642,
            21.014292286526263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80861913215642,
              21.014292286526263
            ],
            [
              105.80856516055596,
              21.01423349171601
            ],
            [
              105.8085089793428,
              21.014173964932898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6781",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014292286526263,
          "to_latitude": 21.014173964932898,
          "to_longitude": 105.8085089793428,
          "from_longitude": 105.80861913215642
        }
      },
      {
        "id": 6774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80861913215642,
            21.014292286526263,
            105.8087061458866,
            21.01438670839383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8087061458866,
              21.01438670839383
            ],
            [
              105.8087035972504,
              21.01438430075738
            ],
            [
              105.80861913215642,
              21.014292286526263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6782",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01438670839383,
          "to_latitude": 21.014292286526263,
          "to_longitude": 105.80861913215642,
          "from_longitude": 105.8087061458866
        }
      },
      {
        "id": 6775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80875031220432,
            21.01351264595503,
            105.80904145904128,
            21.013850492054498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80875031220432,
              21.01351264595503
            ],
            [
              105.80876036959573,
              21.013525751874816
            ],
            [
              105.80877567753245,
              21.0135457009901
            ],
            [
              105.8089247114375,
              21.013711868589432
            ],
            [
              105.80904145904128,
              21.013850492054498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6783",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01351264595503,
          "to_latitude": 21.013850492054498,
          "to_longitude": 105.80904145904128,
          "from_longitude": 105.80875031220432
        }
      },
      {
        "id": 6776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80942764336488,
            21.01417393060864,
            105.80959380681699,
            21.014461654822256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80942764336488,
              21.01417393060864
            ],
            [
              105.80942774781131,
              21.014174033983064
            ],
            [
              105.80943835349721,
              21.01418462356417
            ],
            [
              105.80945707737166,
              21.014203317130686
            ],
            [
              105.80950028329403,
              21.014263526746785
            ],
            [
              105.8095267781672,
              21.01435360977382
            ],
            [
              105.80955968694826,
              21.014416188761256
            ],
            [
              105.8095825951655,
              21.014448407223036
            ],
            [
              105.80959380681699,
              21.014461654822256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_60_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6784",
          "diaChiLapD": "Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01417393060864,
          "to_latitude": 21.014461654822256,
          "to_longitude": 105.80959380681699,
          "from_longitude": 105.80942764336488
        }
      },
      {
        "id": 6777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80885020556494,
            21.015023492823442,
            105.80889771499335,
            21.01507902507865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80885020556494,
              21.015023492823442
            ],
            [
              105.80885361887914,
              21.01502748422285
            ],
            [
              105.80889771499335,
              21.01507902507865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6785",
          "diaChiLapD": "Ngõ 25, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015023492823442,
          "to_latitude": 21.01507902507865,
          "to_longitude": 105.80889771499335,
          "from_longitude": 105.80885020556494
        }
      },
      {
        "id": 6778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80985384639345,
            21.011803514860905,
            105.80991779133645,
            21.011888079718876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80985384639345,
              21.011803514860905
            ],
            [
              105.80986068733148,
              21.011812563367172
            ],
            [
              105.80991779133645,
              21.011888079718876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94_Đường Láng",
          "maTaiSan": "04.O9B.DODV.6786",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011803514860905,
          "to_latitude": 21.011888079718876,
          "to_longitude": 105.80991779133645,
          "from_longitude": 105.80985384639345
        }
      },
      {
        "id": 6779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8119186154874,
            21.01310796589111,
            105.81216073389707,
            21.013400615272584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81216073389707,
              21.013400615272584
            ],
            [
              105.81215422872445,
              21.013389661257676
            ],
            [
              105.8121403095226,
              21.013374712425545
            ],
            [
              105.81209213710056,
              21.01332298232433
            ],
            [
              105.8119186154874,
              21.01310796589111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Ngõ 9, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6787",
          "diaChiLapD": "Ngõ 9, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013400615272584,
          "to_latitude": 21.01310796589111,
          "to_longitude": 105.8119186154874,
          "from_longitude": 105.81216073389707
        }
      },
      {
        "id": 6780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8121403095226,
            21.013316631762294,
            105.81222307181073,
            21.013374712425545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8121403095226,
              21.013374712425545
            ],
            [
              105.81222307181073,
              21.013316631762294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Ngõ 9, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6788",
          "diaChiLapD": "Ngõ 9, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013374712425545,
          "to_latitude": 21.013316631762294,
          "to_longitude": 105.81222307181073,
          "from_longitude": 105.8121403095226
        }
      },
      {
        "id": 6781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81160478544204,
            21.013589400063534,
            105.81167192915927,
            21.013687928207663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81167192915927,
              21.013687928207663
            ],
            [
              105.81166550733016,
              21.013678506555234
            ],
            [
              105.81160478544204,
              21.013589400063534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6789",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013687928207663,
          "to_latitude": 21.013589400063534,
          "to_longitude": 105.81160478544204,
          "from_longitude": 105.81167192915927
        }
      },
      {
        "id": 6782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81128724762002,
            21.013932197632464,
            105.81132479999819,
            21.013989925460706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81128724762002,
              21.013932197632464
            ],
            [
              105.8112904069034,
              21.013937056389988
            ],
            [
              105.81132479999819,
              21.013989925460706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6790",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013932197632464,
          "to_latitude": 21.013989925460706,
          "to_longitude": 105.81132479999819,
          "from_longitude": 105.81128724762002
        }
      },
      {
        "id": 6783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8115728680431,
            21.013778641428814,
            105.8116288016685,
            21.0138617326865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8115728680431,
              21.013778641428814
            ],
            [
              105.81158776089589,
              21.01380056039458
            ],
            [
              105.8116288016685,
              21.0138617326865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6791",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013778641428814,
          "to_latitude": 21.0138617326865,
          "to_longitude": 105.8116288016685,
          "from_longitude": 105.8115728680431
        }
      },
      {
        "id": 6784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81114536621348,
            21.014015521346067,
            105.81117791022925,
            21.014067816679944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81114536621348,
              21.014015521346067
            ],
            [
              105.81114747385584,
              21.014018911050727
            ],
            [
              105.81117791022925,
              21.014067816679944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6792",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014015521346067,
          "to_latitude": 21.014067816679944,
          "to_longitude": 105.81117791022925,
          "from_longitude": 105.81114536621348
        }
      },
      {
        "id": 6785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81117775166778,
            21.014443715021944,
            105.81130581722786,
            21.014635356599744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81117775166778,
              21.014443715021944
            ],
            [
              105.81122293281386,
              21.014511325751396
            ],
            [
              105.81130581722786,
              21.014635356599744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6793",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014443715021944,
          "to_latitude": 21.014635356599744,
          "to_longitude": 105.81130581722786,
          "from_longitude": 105.81117775166778
        }
      },
      {
        "id": 6786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81098641618235,
            21.01415739049443,
            105.81107483667184,
            21.014289707496424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81098641618235,
              21.01415739049443
            ],
            [
              105.8110176081035,
              21.014204066995273
            ],
            [
              105.81107483667184,
              21.014289707496424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6794",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01415739049443,
          "to_latitude": 21.014289707496424,
          "to_longitude": 105.81107483667184,
          "from_longitude": 105.81098641618235
        }
      },
      {
        "id": 6787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81108061946753,
            21.01429836176311,
            105.81117123279887,
            21.014433959619776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81108061946753,
              21.01429836176311
            ],
            [
              105.81111987032185,
              21.01435709857236
            ],
            [
              105.81117123279887,
              21.014433959619776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6795",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01429836176311,
          "to_latitude": 21.014433959619776,
          "to_longitude": 105.81117123279887,
          "from_longitude": 105.81108061946753
        }
      },
      {
        "id": 6788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81057358002987,
            21.014343564107858,
            105.81092959930349,
            21.014856963551118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81057358002987,
              21.014343564107858
            ],
            [
              105.81057676965669,
              21.014348166217264
            ],
            [
              105.81092959930349,
              21.014856963551118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_88_Ngõ 36, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6796",
          "diaChiLapD": "Ngõ 36, Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014343564107858,
          "to_latitude": 21.014856963551118,
          "to_longitude": 105.81092959930349,
          "from_longitude": 105.81057358002987
        }
      },
      {
        "id": 6789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81036769377026,
            21.0144669694058,
            105.81070802311653,
            21.014998635075127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81036769377026,
              21.0144669694058
            ],
            [
              105.81036864178733,
              21.014468310701282
            ],
            [
              105.81038307721413,
              21.014488729883023
            ],
            [
              105.81037942200906,
              21.01449801532384
            ],
            [
              105.81070802311653,
              21.014998635075127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DODV.6797",
          "diaChiLapD": "Vũ Ngọc Phan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0144669694058,
          "to_latitude": 21.014998635075127,
          "to_longitude": 105.81070802311653,
          "from_longitude": 105.81036769377026
        }
      },
      {
        "id": 6790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81054338788977,
            21.01534332473346,
            105.81060551896572,
            21.01537401307202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81054338788977,
              21.01534332473346
            ],
            [
              105.81054854129351,
              21.015345871673457
            ],
            [
              105.81060551896572,
              21.01537401307202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6798",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01534332473346,
          "to_latitude": 21.01537401307202,
          "to_longitude": 105.81060551896572,
          "from_longitude": 105.81054338788977
        }
      },
      {
        "id": 6791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81045556930451,
            21.0155232652188,
            105.8106483450193,
            21.01592099673652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81045556930451,
              21.0155232652188
            ],
            [
              105.8104603389836,
              21.015525293729553
            ],
            [
              105.81049874030292,
              21.015541616390898
            ],
            [
              105.81064225892754,
              21.015724947377564
            ],
            [
              105.8106483450193,
              21.01575856635383
            ],
            [
              105.8106351219487,
              21.015798395806605
            ],
            [
              105.81064456665901,
              21.01581288013926
            ],
            [
              105.81061047264775,
              21.015909016953547
            ],
            [
              105.81058406760971,
              21.01592099673652
            ],
            [
              105.81045691158714,
              21.015751214771605
            ],
            [
              105.81047436012851,
              21.01573979883964
            ],
            [
              105.81046665470721,
              21.01572693111949
            ],
            [
              105.81050471504265,
              21.015705241217958
            ],
            [
              105.81049084721396,
              21.01568236527682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6799",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0155232652188,
          "to_latitude": 21.01568236527682,
          "to_longitude": 105.81049084721396,
          "from_longitude": 105.81045556930451
        }
      },
      {
        "id": 6792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81064225892754,
            21.015595286194603,
            105.81074859620878,
            21.015724947377564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81064225892754,
              21.015724947377564
            ],
            [
              105.81068802932622,
              21.015705773222177
            ],
            [
              105.81071775166667,
              21.015667203727006
            ],
            [
              105.81074859620878,
              21.015595286194603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6800",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015724947377564,
          "to_latitude": 21.015595286194603,
          "to_longitude": 105.81074859620878,
          "from_longitude": 105.81064225892754
        }
      },
      {
        "id": 6793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81037554908335,
            21.01568826975821,
            105.81043808288523,
            21.01572742219762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81037554908335,
              21.01568826975821
            ],
            [
              105.81038293272988,
              21.015692859118317
            ],
            [
              105.81039208939566,
              21.015698546487624
            ],
            [
              105.81039459123315,
              21.015700117026974
            ],
            [
              105.81043808288523,
              21.01572742219762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6801",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01568826975821,
          "to_latitude": 21.01572742219762,
          "to_longitude": 105.81043808288523,
          "from_longitude": 105.81037554908335
        }
      },
      {
        "id": 6794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81028798878395,
            21.01555154907947,
            105.81045970744667,
            21.015952419648833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81028798878395,
              21.015952419648833
            ],
            [
              105.81033062067867,
              21.01583463880418
            ],
            [
              105.81039123087348,
              21.015707183636707
            ],
            [
              105.81039459123315,
              21.015700117026974
            ],
            [
              105.8103962119665,
              21.01569670865081
            ],
            [
              105.81039937259237,
              21.01569006299374
            ],
            [
              105.81040553553925,
              21.01566705640269
            ],
            [
              105.81041044911593,
              21.015648714616276
            ],
            [
              105.81045970744667,
              21.01555154907947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6802",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015952419648833,
          "to_latitude": 21.01555154907947,
          "to_longitude": 105.81045970744667,
          "from_longitude": 105.81028798878395
        }
      },
      {
        "id": 6795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81037658592449,
            21.01568610881257,
            105.8103962119665,
            21.01569670865081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8103962119665,
              21.01569670865081
            ],
            [
              105.81038957733652,
              21.015693125533105
            ],
            [
              105.81038430398247,
              21.015690278384856
            ],
            [
              105.81037903254752,
              21.015687430324064
            ],
            [
              105.81037658592449,
              21.01568610881257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6803",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01569670865081,
          "to_latitude": 21.01568610881257,
          "to_longitude": 105.81037658592449,
          "from_longitude": 105.8103962119665
        }
      },
      {
        "id": 6796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81039237560464,
            21.01592426516261,
            105.81075568721613,
            21.016239001013773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81039237560464,
              21.016239001013773
            ],
            [
              105.81039688815397,
              21.016235452410438
            ],
            [
              105.81062504403639,
              21.016056015721198
            ],
            [
              105.81069296341812,
              21.016002160434493
            ],
            [
              105.81075568721613,
              21.01592426516261
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6804",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016239001013773,
          "to_latitude": 21.01592426516261,
          "to_longitude": 105.81075568721613,
          "from_longitude": 105.81039237560464
        }
      },
      {
        "id": 6797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81077101094009,
            21.01598049579224,
            105.81091935410004,
            21.0162045011937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81077101094009,
              21.01598049579224
            ],
            [
              105.81091935410004,
              21.0162045011937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6805",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01598049579224,
          "to_latitude": 21.0162045011937,
          "to_longitude": 105.81091935410004,
          "from_longitude": 105.81077101094009
        }
      },
      {
        "id": 6798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8097475202867,
            21.016104519365673,
            105.81015227404042,
            21.01634588686235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8097475202867,
              21.01634588686235
            ],
            [
              105.81007777381572,
              21.016184390763854
            ],
            [
              105.81012449484535,
              21.01612514681148
            ],
            [
              105.81015227404042,
              21.016104519365673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6806",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01634588686235,
          "to_latitude": 21.016104519365673,
          "to_longitude": 105.81015227404042,
          "from_longitude": 105.8097475202867
        }
      },
      {
        "id": 6799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80881623451147,
            21.01570794962242,
            105.80888464195158,
            21.015832261851166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80888464195158,
              21.015832261851166
            ],
            [
              105.80887360710872,
              21.015812209344364
            ],
            [
              105.80884491984283,
              21.015760080394067
            ],
            [
              105.80884277873126,
              21.015756187721415
            ],
            [
              105.80883852275298,
              21.015748452832046
            ],
            [
              105.80881623451147,
              21.01570794962242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6807",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015832261851166,
          "to_latitude": 21.01570794962242,
          "to_longitude": 105.80881623451147,
          "from_longitude": 105.80888464195158
        }
      },
      {
        "id": 6800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80858231624903,
            21.01565567686191,
            105.80902998364508,
            21.015896029935785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80858231624903,
              21.015896029935785
            ],
            [
              105.808781953265,
              21.015788845005112
            ],
            [
              105.80884032710335,
              21.015757503639723
            ],
            [
              105.80884277873126,
              21.015756187721415
            ],
            [
              105.80892814535342,
              21.01571035394328
            ],
            [
              105.80902998364508,
              21.01565567686191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6808",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015896029935785,
          "to_latitude": 21.01565567686191,
          "to_longitude": 105.80902998364508,
          "from_longitude": 105.80858231624903
        }
      },
      {
        "id": 6801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80884032710335,
            21.015757503639723,
            105.80888112240427,
            21.015834261172486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80884032710335,
              21.015757503639723
            ],
            [
              105.80884239706222,
              21.01576140207008
            ],
            [
              105.80888112240427,
              21.015834261172486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6809",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015757503639723,
          "to_latitude": 21.015834261172486,
          "to_longitude": 105.80888112240427,
          "from_longitude": 105.80884032710335
        }
      },
      {
        "id": 6802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80837617747953,
            21.015812134645913,
            105.80901487853117,
            21.016157306587438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80837617747953,
              21.016157306587438
            ],
            [
              105.80842829486257,
              21.016129141033176
            ],
            [
              105.8086293346236,
              21.01602049360213
            ],
            [
              105.80866974357896,
              21.01599865564853
            ],
            [
              105.80901487853117,
              21.015812134645913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6810",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016157306587438,
          "to_latitude": 21.015812134645913,
          "to_longitude": 105.80901487853117,
          "from_longitude": 105.80837617747953
        }
      },
      {
        "id": 6803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8086105285416,
            21.01598800352055,
            105.8086293346236,
            21.01602049360213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8086293346236,
              21.01602049360213
            ],
            [
              105.80862101716437,
              21.016006127537395
            ],
            [
              105.80861137136074,
              21.015989460035847
            ],
            [
              105.8086105285416,
              21.01598800352055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6811",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01602049360213,
          "to_latitude": 21.01598800352055,
          "to_longitude": 105.8086105285416,
          "from_longitude": 105.8086293346236
        }
      },
      {
        "id": 6804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80756160026282,
            21.016792733019223,
            105.80766811965995,
            21.016930262230808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80766811965995,
              21.016930262230808
            ],
            [
              105.80765146760326,
              21.016928880766226
            ],
            [
              105.80757906734785,
              21.016792733019223
            ],
            [
              105.80756160026282,
              21.01680166306399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6812",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016930262230808,
          "to_latitude": 21.01680166306399,
          "to_longitude": 105.80756160026282,
          "from_longitude": 105.80766811965995
        }
      },
      {
        "id": 6805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80788269249625,
            21.01619506092243,
            105.80797336517323,
            21.01635001370756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80797336517323,
              21.01635001370756
            ],
            [
              105.80795299021158,
              21.016315194349364
            ],
            [
              105.80791784038247,
              21.016255128547964
            ],
            [
              105.80789259359233,
              21.01621198919085
            ],
            [
              105.80788269249625,
              21.01619506092243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6813",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01635001370756,
          "to_latitude": 21.01619506092243,
          "to_longitude": 105.80788269249625,
          "from_longitude": 105.80797336517323
        }
      },
      {
        "id": 6806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80738491297866,
            21.016508174572145,
            105.80748044877053,
            21.01665219263336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80748044877053,
              21.01665219263336
            ],
            [
              105.8074803295009,
              21.01665201616657
            ],
            [
              105.80738491297866,
              21.016508174572145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6814",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01665219263336,
          "to_latitude": 21.016508174572145,
          "to_longitude": 105.80738491297866,
          "from_longitude": 105.80748044877053
        }
      },
      {
        "id": 6807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80800967811592,
            21.01632938220256,
            105.80805366090452,
            21.016376471789492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80800967811592,
              21.01632938220256
            ],
            [
              105.80801672750998,
              21.01634425019326
            ],
            [
              105.80802104860773,
              21.016351935116482
            ],
            [
              105.80803990781253,
              21.016356624526722
            ],
            [
              105.80805366090452,
              21.016376471789492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Trúc Khê",
          "maTaiSan": "04.O9B.DODV.6815",
          "diaChiLapD": "Trúc Khê",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01632938220256,
          "to_latitude": 21.016376471789492,
          "to_longitude": 105.80805366090452,
          "from_longitude": 105.80800967811592
        }
      },
      {
        "id": 6808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80763681025314,
            21.018469184936322,
            105.80785730458713,
            21.018582731325093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80766005714027,
              21.018570371822655
            ],
            [
              105.80764180280308,
              21.01858141375325
            ],
            [
              105.80763937998653,
              21.018582731325093
            ],
            [
              105.80763681025314,
              21.01857820299419
            ],
            [
              105.80768782194245,
              21.018554069491223
            ],
            [
              105.80785730458713,
              21.018469184936322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6816",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018570371822655,
          "to_latitude": 21.018469184936322,
          "to_longitude": 105.80785730458713,
          "from_longitude": 105.80766005714027
        }
      },
      {
        "id": 6809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8093163530266,
            21.01772907280041,
            105.80932415709432,
            21.01774346204054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8093163530266,
              21.01772907280041
            ],
            [
              105.80931667367537,
              21.017729666502603
            ],
            [
              105.80932058735456,
              21.01773688003512
            ],
            [
              105.80932415709432,
              21.01774346204054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6817",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01772907280041,
          "to_latitude": 21.01774346204054,
          "to_longitude": 105.80932415709432,
          "from_longitude": 105.8093163530266
        }
      },
      {
        "id": 6810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81133119423832,
            21.017487706979274,
            105.81141470424575,
            21.017634041729774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81141470424575,
              21.017634041729774
            ],
            [
              105.81137619580927,
              21.017566563665973
            ],
            [
              105.81136759715892,
              21.017551495474034
            ],
            [
              105.81133119423832,
              21.017487706979274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6818",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017634041729774,
          "to_latitude": 21.017487706979274,
          "to_longitude": 105.81133119423832,
          "from_longitude": 105.81141470424575
        }
      },
      {
        "id": 6811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81136759715892,
            21.0175374597247,
            105.8113989453483,
            21.017551495474034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81136759715892,
              21.017551495474034
            ],
            [
              105.8113848292118,
              21.017543780102848
            ],
            [
              105.81138732808729,
              21.017542661729575
            ],
            [
              105.81138982695786,
              21.017541542453056
            ],
            [
              105.8113989453483,
              21.0175374597247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6819",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017551495474034,
          "to_latitude": 21.0175374597247,
          "to_longitude": 105.8113989453483,
          "from_longitude": 105.81136759715892
        }
      },
      {
        "id": 6812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81120155562539,
            21.018025052639462,
            105.81137537278188,
            21.01826365839975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81137537278188,
              21.01826365839975
            ],
            [
              105.81136560306976,
              21.01824977631489
            ],
            [
              105.81134842452057,
              21.018216363835638
            ],
            [
              105.8112968484345,
              21.018235788064768
            ],
            [
              105.81120155562539,
              21.018039560936113
            ],
            [
              105.811237109934,
              21.018025052639462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6820",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01826365839975,
          "to_latitude": 21.018025052639462,
          "to_longitude": 105.811237109934,
          "from_longitude": 105.81137537278188
        }
      },
      {
        "id": 6813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81116472464691,
            21.018279740577306,
            105.81119871410898,
            21.018347078552527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81119871410898,
              21.018347078552527
            ],
            [
              105.81119049513089,
              21.018331070992296
            ],
            [
              105.81118074199021,
              21.018312069319737
            ],
            [
              105.81117626597523,
              21.018303350123684
            ],
            [
              105.81116472464691,
              21.018279740577306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_86_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6821",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018347078552527,
          "to_latitude": 21.018279740577306,
          "to_longitude": 105.81116472464691,
          "from_longitude": 105.81119871410898
        }
      },
      {
        "id": 6814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80825585733676,
            21.019226766171073,
            105.80827791681666,
            21.019269905232488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80825585733676,
              21.019226766171073
            ],
            [
              105.80825721748008,
              21.019229429652285
            ],
            [
              105.80827791681666,
              21.019269905232488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6822",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019226766171073,
          "to_latitude": 21.019269905232488,
          "to_longitude": 105.80827791681666,
          "from_longitude": 105.80825585733676
        }
      },
      {
        "id": 6815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8082384760308,
            21.01917722537447,
            105.80826417613176,
            21.01922821246155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80826210226145,
              21.019223704359675
            ],
            [
              105.80826337424625,
              21.019225895871113
            ],
            [
              105.80826417613176,
              21.019227429356103
            ],
            [
              105.80826284418374,
              21.01922821246155
            ],
            [
              105.80826065640058,
              21.019224413038376
            ],
            [
              105.80825731986522,
              21.019218619325212
            ],
            [
              105.8082514412878,
              21.019202731464915
            ],
            [
              105.8082384760308,
              21.01917722537447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6823",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019223704359675,
          "to_latitude": 21.01917722537447,
          "to_longitude": 105.8082384760308,
          "from_longitude": 105.80826210226145
        }
      },
      {
        "id": 6816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8083873260284,
            21.01915125504296,
            105.80846684777478,
            21.01932841358103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80840988405892,
              21.01915125504296
            ],
            [
              105.80841577417803,
              21.019159109527116
            ],
            [
              105.80846684777478,
              21.01925315573588
            ],
            [
              105.8083873260284,
              21.019291705911485
            ],
            [
              105.80840737597241,
              21.01932841358103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6824",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01915125504296,
          "to_latitude": 21.01932841358103,
          "to_longitude": 105.80840737597241,
          "from_longitude": 105.80840988405892
        }
      },
      {
        "id": 6817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8077780456631,
            21.018668268622115,
            105.80786161206774,
            21.01871025824692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8077780456631,
              21.01871025824692
            ],
            [
              105.80778239254647,
              21.01870807437236
            ],
            [
              105.80786161206774,
              21.018668268622115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.6825",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01871025824692,
          "to_latitude": 21.018668268622115,
          "to_longitude": 105.80786161206774,
          "from_longitude": 105.8077780456631
        }
      },
      {
        "id": 6818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81187385716264,
            21.017886725799517,
            105.81193967827399,
            21.018000321373055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81193967827399,
              21.018000321373055
            ],
            [
              105.81192248914962,
              21.017970659207442
            ],
            [
              105.81187385716264,
              21.017886725799517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6826",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018000321373055,
          "to_latitude": 21.017886725799517,
          "to_longitude": 105.81187385716264,
          "from_longitude": 105.81193967827399
        }
      },
      {
        "id": 6819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81194456231336,
            21.01779032604769,
            105.81212118099333,
            21.017887525507827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81194456231336,
              21.017887525507827
            ],
            [
              105.81202036457469,
              21.017845808486978
            ],
            [
              105.81204754634453,
              21.01783084953613
            ],
            [
              105.81212118099333,
              21.01779032604769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6827",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017887525507827,
          "to_latitude": 21.01779032604769,
          "to_longitude": 105.81212118099333,
          "from_longitude": 105.81194456231336
        }
      },
      {
        "id": 6820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81204754634453,
            21.01783084953613,
            105.81209750637198,
            21.017924106899713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81204754634453,
              21.01783084953613
            ],
            [
              105.81206328426289,
              21.017860225970917
            ],
            [
              105.81207825450626,
              21.01788817354877
            ],
            [
              105.81209322763623,
              21.01791612020806
            ],
            [
              105.81209750637198,
              21.017924106899713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6828",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01783084953613,
          "to_latitude": 21.017924106899713,
          "to_longitude": 105.81209750637198,
          "from_longitude": 105.81204754634453
        }
      },
      {
        "id": 6821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81212290026846,
            21.017762306024885,
            105.81219048344207,
            21.01787920883771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81219048344207,
              21.01787920883771
            ],
            [
              105.81216919024901,
              21.017842379305524
            ],
            [
              105.81212290026846,
              21.017762306024885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6829",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01787920883771,
          "to_latitude": 21.017762306024885,
          "to_longitude": 105.81212290026846,
          "from_longitude": 105.81219048344207
        }
      },
      {
        "id": 6822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81150940469756,
            21.01749962950708,
            105.81161830395642,
            21.017685291529734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81161830395642,
              21.017685291529734
            ],
            [
              105.81150940469756,
              21.01749962950708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6830",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017685291529734,
          "to_latitude": 21.01749962950708,
          "to_longitude": 105.81150940469756,
          "from_longitude": 105.81161830395642
        }
      },
      {
        "id": 6823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81116584347922,
            21.016546496919077,
            105.81149051695802,
            21.01705270016771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81149051695802,
              21.01705270016771
            ],
            [
              105.81148948184145,
              21.017051089229817
            ],
            [
              105.81116584347922,
              21.016546496919077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6831",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01705270016771,
          "to_latitude": 21.016546496919077,
          "to_longitude": 105.81116584347922,
          "from_longitude": 105.81149051695802
        }
      },
      {
        "id": 6824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81149997070206,
            21.01758560519308,
            105.81155293269538,
            21.017611256623233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81149997070206,
              21.017611256623233
            ],
            [
              105.811521421839,
              21.017600866524134
            ],
            [
              105.81155293269538,
              21.01758560519308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.6832",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017611256623233,
          "to_latitude": 21.01758560519308,
          "to_longitude": 105.81155293269538,
          "from_longitude": 105.81149997070206
        }
      },
      {
        "id": 6825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81121084092632,
            21.016385482145406,
            105.81143135275647,
            21.016508699024946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81121084092632,
              21.016508699024946
            ],
            [
              105.81143135275647,
              21.016385482145406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6833",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016508699024946,
          "to_latitude": 21.016385482145406,
          "to_longitude": 105.81143135275647,
          "from_longitude": 105.81121084092632
        }
      },
      {
        "id": 6826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81094876722456,
            21.01589594096079,
            105.81118853818793,
            21.016275872685657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81118853818793,
              21.016275872685657
            ],
            [
              105.81118690719056,
              21.016273290003284
            ],
            [
              105.81113657503812,
              21.016193544589637
            ],
            [
              105.81094876722456,
              21.01589594096079
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92_Ngõ 21, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6834",
          "diaChiLapD": "Ngõ 21, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016275872685657,
          "to_latitude": 21.01589594096079,
          "to_longitude": 105.81094876722456,
          "from_longitude": 105.81118853818793
        }
      },
      {
        "id": 6827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81115547040973,
            21.015845958103704,
            105.81136577555732,
            21.016174861263426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81136577555732,
              21.016174861263426
            ],
            [
              105.81136493401536,
              21.016173645918492
            ],
            [
              105.81136024728214,
              21.016166862444003
            ],
            [
              105.81131127423842,
              21.016090128287154
            ],
            [
              105.81115547040973,
              21.015845958103704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6835",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016174861263426,
          "to_latitude": 21.015845958103704,
          "to_longitude": 105.81115547040973,
          "from_longitude": 105.81136577555732
        }
      },
      {
        "id": 6828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8113634891164,
            21.01612953371217,
            105.81138578542253,
            21.016163743734154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81138578542253,
              21.016163743734154
            ],
            [
              105.81138465934532,
              21.01616185413147
            ],
            [
              105.81138353133942,
              21.016159963634774
            ],
            [
              105.81138035499991,
              21.01615462896155
            ],
            [
              105.8113634891164,
              21.01612953371217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6836",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016163743734154,
          "to_latitude": 21.01612953371217,
          "to_longitude": 105.8113634891164,
          "from_longitude": 105.81138578542253
        }
      },
      {
        "id": 6829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81122184363511,
            21.015502922649635,
            105.81148902744522,
            21.015660123909313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81122184363511,
              21.015660123909313
            ],
            [
              105.81123183814628,
              21.015654244105857
            ],
            [
              105.81148003978937,
              21.015508210566765
            ],
            [
              105.81148902744522,
              21.015502922649635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6837",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015660123909313,
          "to_latitude": 21.015502922649635,
          "to_longitude": 105.81148902744522,
          "from_longitude": 105.81122184363511
        }
      },
      {
        "id": 6830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.810960923492,
            21.015620838082214,
            105.81123183814628,
            21.015762538479382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81123183814628,
              21.015654244105857
            ],
            [
              105.81121516130808,
              21.01562976032559
            ],
            [
              105.81120856319936,
              21.015620838082214
            ],
            [
              105.810960923492,
              21.015762538479382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6838",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015654244105857,
          "to_latitude": 21.015762538479382,
          "to_longitude": 105.810960923492,
          "from_longitude": 105.81123183814628
        }
      },
      {
        "id": 6831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8120262294102,
            21.01534990959381,
            105.81206053498255,
            21.015406757629943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8120262294102,
              21.01534990959381
            ],
            [
              105.81206053498255,
              21.015406757629943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6839",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01534990959381,
          "to_latitude": 21.015406757629943,
          "to_longitude": 105.81206053498255,
          "from_longitude": 105.8120262294102
        }
      },
      {
        "id": 6832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81173095404452,
            21.015828207217982,
            105.81180341650979,
            21.01586671708398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81173095404452,
              21.01586671708398
            ],
            [
              105.81180341650979,
              21.015828207217982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6840",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01586671708398,
          "to_latitude": 21.015828207217982,
          "to_longitude": 105.81180341650979,
          "from_longitude": 105.81173095404452
        }
      },
      {
        "id": 6833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81095859678939,
            21.015002414188466,
            105.81109650625191,
            21.01513047935972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81095859678939,
              21.015030606527656
            ],
            [
              105.81101029757724,
              21.015002414188466
            ],
            [
              105.81109650625191,
              21.01513047935972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6841",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015030606527656,
          "to_latitude": 21.01513047935972,
          "to_longitude": 105.81109650625191,
          "from_longitude": 105.81095859678939
        }
      },
      {
        "id": 6834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81115399937302,
            21.01484587159682,
            105.81157082976648,
            21.01560968993413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81115399937302,
              21.01484587159682
            ],
            [
              105.81145556743772,
              21.015308770692407
            ],
            [
              105.81146392199153,
              21.015321594625217
            ],
            [
              105.81146936159107,
              21.01532938792961
            ],
            [
              105.81147511528961,
              21.015337630446695
            ],
            [
              105.81147807599945,
              21.015341872337782
            ],
            [
              105.81142407875626,
              21.0153721459714
            ],
            [
              105.81157082976648,
              21.01559606627431
            ],
            [
              105.811567453763,
              21.015597919545595
            ],
            [
              105.81155661686887,
              21.015604558485727
            ],
            [
              105.81154824208119,
              21.01560968993413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6842",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 102.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484587159682,
          "to_latitude": 21.01560968993413,
          "to_longitude": 105.81154824208119,
          "from_longitude": 105.81115399937302
        }
      },
      {
        "id": 6835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81115399937302,
            21.01471381211769,
            105.81170254799585,
            21.015607685772853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81115399937302,
              21.01484587159682
            ],
            [
              105.81138438242417,
              21.01471381211769
            ],
            [
              105.81169329001986,
              21.015192978154367
            ],
            [
              105.81169830426693,
              21.015201093223585
            ],
            [
              105.81170254799585,
              21.015207962803768
            ],
            [
              105.81147511528961,
              21.015337630446695
            ],
            [
              105.81141405695254,
              21.015372442157005
            ],
            [
              105.81156727902035,
              21.015595491597782
            ],
            [
              105.81156549393921,
              21.015596610180932
            ],
            [
              105.81155534833276,
              21.015602651498988
            ],
            [
              105.81154689510214,
              21.015607685772853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6843",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 158,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484587159682,
          "to_latitude": 21.015607685772853,
          "to_longitude": 105.81154689510214,
          "from_longitude": 105.81115399937302
        }
      },
      {
        "id": 6836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81145556743772,
            21.015217421859052,
            105.81161820450359,
            21.015308770692407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81145556743772,
              21.015308770692407
            ],
            [
              105.81161820450359,
              21.015217421859052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6844",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015308770692407,
          "to_latitude": 21.015217421859052,
          "to_longitude": 105.81161820450359,
          "from_longitude": 105.81145556743772
        }
      },
      {
        "id": 6837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81140377260482,
            21.014734611631496,
            105.81190894570706,
            21.01536339306643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81167789666372,
              21.014734611631496
            ],
            [
              105.81190474746342,
              21.01508020096778
            ],
            [
              105.81190894570706,
              21.015086663403768
            ],
            [
              105.81169830426693,
              21.015201093223585
            ],
            [
              105.81161026195757,
              21.015248921907755
            ],
            [
              105.81146936159107,
              21.01532938792961
            ],
            [
              105.81140981587069,
              21.01536339306643
            ],
            [
              105.81140649958623,
              21.015359214289514
            ],
            [
              105.81140377260482,
              21.015355323541545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6845",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 107,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014734611631496,
          "to_latitude": 21.015355323541545,
          "to_longitude": 105.81140377260482,
          "from_longitude": 105.81167789666372
        }
      },
      {
        "id": 6838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81238902944295,
            21.014970026476398,
            105.81241439491828,
            21.014986512290882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81238902944295,
              21.014986512290882
            ],
            [
              105.81239264034838,
              21.01498416562267
            ],
            [
              105.81240296118868,
              21.01497745774024
            ],
            [
              105.81241439491828,
              21.014970026476398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6846",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014986512290882,
          "to_latitude": 21.014970026476398,
          "to_longitude": 105.81241439491828,
          "from_longitude": 105.81238902944295
        }
      },
      {
        "id": 6839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81234329233432,
            21.014809812242028,
            105.81269955466232,
            21.015442905496208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81269955466232,
              21.015442905496208
            ],
            [
              105.81269074916442,
              21.015426944159874
            ],
            [
              105.8126839274472,
              21.01541458174398
            ],
            [
              105.81268549910459,
              21.01541348855602
            ],
            [
              105.8126916036455,
              21.015409241319322
            ],
            [
              105.81266566004125,
              21.01537122643057
            ],
            [
              105.81261912659582,
              21.015301154955587
            ],
            [
              105.81252680177234,
              21.015162129324526
            ],
            [
              105.81240296118868,
              21.01497745774024
            ],
            [
              105.81234329233432,
              21.0148884802017
            ],
            [
              105.81247332012998,
              21.014809812242028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6847",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015442905496208,
          "to_latitude": 21.014809812242028,
          "to_longitude": 105.81247332012998,
          "from_longitude": 105.81269955466232
        }
      },
      {
        "id": 6840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81265818327354,
            21.014856036989368,
            105.81271249800024,
            21.014904865735296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81265818327354,
              21.014904865735296
            ],
            [
              105.8126816695347,
              21.014878343152265
            ],
            [
              105.81270102674581,
              21.014863462129345
            ],
            [
              105.81270454240496,
              21.014860759134468
            ],
            [
              105.81271249800024,
              21.014856036989368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6848",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014904865735296,
          "to_latitude": 21.014856036989368,
          "to_longitude": 105.81271249800024,
          "from_longitude": 105.81265818327354
        }
      },
      {
        "id": 6841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81262549073466,
            21.014764202262615,
            105.8129452381178,
            21.015206367580618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81262549073466,
              21.014764202262615
            ],
            [
              105.81270102674581,
              21.014863462129345
            ],
            [
              105.81270956895065,
              21.014874687292
            ],
            [
              105.81280200273014,
              21.015001515112637
            ],
            [
              105.81284373594225,
              21.015058777309523
            ],
            [
              105.8129452381178,
              21.015206367580618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6849",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014764202262615,
          "to_latitude": 21.015206367580618,
          "to_longitude": 105.8129452381178,
          "from_longitude": 105.81262549073466
        }
      },
      {
        "id": 6842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81280200273014,
            21.01499688074816,
            105.81281200195833,
            21.015001515112637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81280200273014,
              21.015001515112637
            ],
            [
              105.81280541576818,
              21.01499993351233
            ],
            [
              105.81280919917899,
              21.014998179432325
            ],
            [
              105.81281200195833,
              21.01499688074816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6850",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015001515112637,
          "to_latitude": 21.01499688074816,
          "to_longitude": 105.81281200195833,
          "from_longitude": 105.81280200273014
        }
      },
      {
        "id": 6843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81301532910571,
            21.014896027536054,
            105.81318458752281,
            21.01512723809383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81301532910571,
              21.014896027536054
            ],
            [
              105.81306944095117,
              21.01498264324906
            ],
            [
              105.81316585412146,
              21.015105118153187
            ],
            [
              105.8131719379524,
              21.01511284780564
            ],
            [
              105.81318458752281,
              21.01512723809383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6851",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014896027536054,
          "to_latitude": 21.01512723809383,
          "to_longitude": 105.81318458752281,
          "from_longitude": 105.81301532910571
        }
      },
      {
        "id": 6844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81348568473436,
            21.014666940089395,
            105.81354927402224,
            21.014697156356966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81354927402224,
              21.014666940089395
            ],
            [
              105.81354779357298,
              21.01466764355753
            ],
            [
              105.81354678805884,
              21.014668121657255
            ],
            [
              105.81348568473436,
              21.014697156356966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6852",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014666940089395,
          "to_latitude": 21.014697156356966,
          "to_longitude": 105.81348568473436,
          "from_longitude": 105.81354927402224
        }
      },
      {
        "id": 6845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81321079870972,
            21.01431459328757,
            105.81332033845608,
            21.014375681061253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81321079870972,
              21.014375681061253
            ],
            [
              105.8133148604675,
              21.01431764890203
            ],
            [
              105.81331837819451,
              21.014315686532303
            ],
            [
              105.81332033845608,
              21.01431459328757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6853",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014375681061253,
          "to_latitude": 21.01431459328757,
          "to_longitude": 105.81332033845608,
          "from_longitude": 105.81321079870972
        }
      },
      {
        "id": 6846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81275193915619,
            21.01361164323861,
            105.81287011872878,
            21.01367573096641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81275193915619,
              21.01367573096641
            ],
            [
              105.81286224731271,
              21.013615910663702
            ],
            [
              105.81286554857462,
              21.01361412185427
            ],
            [
              105.81287011872878,
              21.01361164323861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6854",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01367573096641,
          "to_latitude": 21.01361164323861,
          "to_longitude": 105.81287011872878,
          "from_longitude": 105.81275193915619
        }
      },
      {
        "id": 6847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81410202744192,
            21.015665487467718,
            105.81417656233586,
            21.01571087909917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81410202744192,
              21.01571087909917
            ],
            [
              105.8141533907539,
              21.0156795992949
            ],
            [
              105.81417656233586,
              21.015665487467718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6855",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01571087909917,
          "to_latitude": 21.015665487467718,
          "to_longitude": 105.81417656233586,
          "from_longitude": 105.81410202744192
        }
      },
      {
        "id": 6848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81391716917281,
            21.01539484262802,
            105.81400776594617,
            21.01545184976564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81391716917281,
              21.01545184976564
            ],
            [
              105.81397244871482,
              21.015417065723664
            ],
            [
              105.81400776594617,
              21.01539484262802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6856",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01545184976564,
          "to_latitude": 21.01539484262802,
          "to_longitude": 105.81400776594617,
          "from_longitude": 105.81391716917281
        }
      },
      {
        "id": 6849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8137116808318,
            21.015368595815154,
            105.81399113395675,
            21.01553610583139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8137116808318,
              21.01553610583139
            ],
            [
              105.81397089518732,
              21.01538072725753
            ],
            [
              105.81399113395675,
              21.015368595815154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6857",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01553610583139,
          "to_latitude": 21.015368595815154,
          "to_longitude": 105.81399113395675,
          "from_longitude": 105.8137116808318
        }
      },
      {
        "id": 6850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8134579585667,
            21.014991015137035,
            105.81351514099978,
            21.015068902575226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8134579585667,
              21.014991015137035
            ],
            [
              105.81347303599001,
              21.015010355227854
            ],
            [
              105.81348095375104,
              21.015021363850977
            ],
            [
              105.81351514099978,
              21.015068902575226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6858",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014991015137035,
          "to_latitude": 21.015068902575226,
          "to_longitude": 105.81351514099978,
          "from_longitude": 105.8134579585667
        }
      },
      {
        "id": 6851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81298936139164,
            21.015105118153187,
            105.81316585412146,
            21.015213316630508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81298936139164,
              21.015213316630508
            ],
            [
              105.81315146518824,
              21.015113939207794
            ],
            [
              105.81316585412146,
              21.015105118153187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6859",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015213316630508,
          "to_latitude": 21.015105118153187,
          "to_longitude": 105.81316585412146,
          "from_longitude": 105.81298936139164
        }
      },
      {
        "id": 6852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8131904576656,
            21.015160417871865,
            105.81321621189385,
            21.015239734557046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81319145575041,
              21.015160417871865
            ],
            [
              105.81320154284211,
              21.01517429743739
            ],
            [
              105.81320804239868,
              21.015182589618934
            ],
            [
              105.81321621189385,
              21.01519301356575
            ],
            [
              105.8131904576656,
              21.01520946522699
            ],
            [
              105.81319991021478,
              21.015223938536714
            ],
            [
              105.8132102270236,
              21.015239734557046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6860",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015160417871865,
          "to_latitude": 21.015239734557046,
          "to_longitude": 105.8132102270236,
          "from_longitude": 105.81319145575041
        }
      },
      {
        "id": 6853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81319991021478,
            21.015216502430324,
            105.81344634872548,
            21.01552566803978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81319991021478,
              21.015223938536714
            ],
            [
              105.81321159015138,
              21.015216502430324
            ],
            [
              105.81330843057532,
              21.015355454599554
            ],
            [
              105.81342705837938,
              21.01552566803978
            ],
            [
              105.81344634872548,
              21.01551601694371
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6861",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015223938536714,
          "to_latitude": 21.01551601694371,
          "to_longitude": 105.81344634872548,
          "from_longitude": 105.81319991021478
        }
      },
      {
        "id": 6854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81341553319317,
            21.015465500404495,
            105.8134702873461,
            21.015556241557167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8134702873461,
              21.015556241557167
            ],
            [
              105.81344634872548,
              21.01551601694371
            ],
            [
              105.81341553319317,
              21.015465500404495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6862",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015556241557167,
          "to_latitude": 21.015465500404495,
          "to_longitude": 105.81341553319317,
          "from_longitude": 105.8134702873461
        }
      },
      {
        "id": 6855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81330843057532,
            21.015347256768276,
            105.81332489587865,
            21.015355454599554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81330843057532,
              21.015355454599554
            ],
            [
              105.81332489587865,
              21.015347256768276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6863",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015355454599554,
          "to_latitude": 21.015347256768276,
          "to_longitude": 105.81332489587865,
          "from_longitude": 105.81330843057532
        }
      },
      {
        "id": 6856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81329615825379,
            21.01530437700092,
            105.8133566792294,
            21.015394078108766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8133566792294,
              21.015394078108766
            ],
            [
              105.81332489587865,
              21.015347256768276
            ],
            [
              105.81329615825379,
              21.01530437700092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6864",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015394078108766,
          "to_latitude": 21.01530437700092,
          "to_longitude": 105.81329615825379,
          "from_longitude": 105.8133566792294
        }
      },
      {
        "id": 6857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81237905320458,
            21.016062017498804,
            105.81265028795484,
            21.016270016224365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81265028795484,
              21.016062017498804
            ],
            [
              105.81264704350315,
              21.01606382680799
            ],
            [
              105.81237905320458,
              21.016213266861858
            ],
            [
              105.81240103471745,
              21.016246323017867
            ],
            [
              105.81241679176439,
              21.016270016224365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6865",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016062017498804,
          "to_latitude": 21.016270016224365,
          "to_longitude": 105.81241679176439,
          "from_longitude": 105.81265028795484
        }
      },
      {
        "id": 6858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8128536784118,
            21.016080308078575,
            105.81287228648354,
            21.016114431647566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8128536784118,
              21.016080308078575
            ],
            [
              105.81287228648354,
              21.016114431647566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6866",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016080308078575,
          "to_latitude": 21.016114431647566,
          "to_longitude": 105.81287228648354,
          "from_longitude": 105.8128536784118
        }
      },
      {
        "id": 6859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81268170024894,
            21.015629886209833,
            105.81293521866549,
            21.015898236819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81268170024894,
              21.01567711297927
            ],
            [
              105.81276028694533,
              21.015629886209833
            ],
            [
              105.81293521866549,
              21.015898236819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6867",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01567711297927,
          "to_latitude": 21.015898236819,
          "to_longitude": 105.81293521866549,
          "from_longitude": 105.81268170024894
        }
      },
      {
        "id": 6860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81258853228117,
            21.015554034306337,
            105.81261964948986,
            21.01560293097605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81258853228117,
              21.015554034306337
            ],
            [
              105.81260293723858,
              21.015573548396628
            ],
            [
              105.81261964948986,
              21.01560293097605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6868",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015554034306337,
          "to_latitude": 21.01560293097605,
          "to_longitude": 105.81261964948986,
          "from_longitude": 105.81258853228117
        }
      },
      {
        "id": 6861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81264181862986,
            21.01551988433844,
            105.81266976932639,
            21.01555816241719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81264181862986,
              21.01551988433844
            ],
            [
              105.81264955911136,
              21.0155303139748
            ],
            [
              105.81266976932639,
              21.01555816241719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6869",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01551988433844,
          "to_latitude": 21.01555816241719,
          "to_longitude": 105.81266976932639,
          "from_longitude": 105.81264181862986
        }
      },
      {
        "id": 6862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81283240158787,
            21.01539111109202,
            105.81311615443674,
            21.015816826767256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81311615443674,
              21.015816826767256
            ],
            [
              105.81285101667677,
              21.015414654218848
            ],
            [
              105.81283454118851,
              21.015393819586823
            ],
            [
              105.81283240158787,
              21.01539111109202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6870",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015816826767256,
          "to_latitude": 21.01539111109202,
          "to_longitude": 105.81283240158787,
          "from_longitude": 105.81311615443674
        }
      },
      {
        "id": 6863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81272755163266,
            21.015414654218848,
            105.81285101667677,
            21.01549558261034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81272755163266,
              21.01549558261034
            ],
            [
              105.81285101667677,
              21.015414654218848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6871",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01549558261034,
          "to_latitude": 21.015414654218848,
          "to_longitude": 105.81285101667677,
          "from_longitude": 105.81272755163266
        }
      },
      {
        "id": 6864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81222029118835,
            21.016492893771755,
            105.81235029932611,
            21.016567687554765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81222029118835,
              21.016567687554765
            ],
            [
              105.81222725539801,
              21.016563727068828
            ],
            [
              105.81223175975488,
              21.016560981426924
            ],
            [
              105.81234347508601,
              21.016492893771755
            ],
            [
              105.81235029932611,
              21.0165035861181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Ngõ 10, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.6872",
          "diaChiLapD": "Ngõ 10, Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016567687554765,
          "to_latitude": 21.0165035861181,
          "to_longitude": 105.81235029932611,
          "from_longitude": 105.81222029118835
        }
      },
      {
        "id": 6865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81206365961177,
            21.016715028338467,
            105.81235286068136,
            21.017168308311458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81206365961177,
              21.016715028338467
            ],
            [
              105.81206574343953,
              21.01671987143707
            ],
            [
              105.81207864955995,
              21.016749835847857
            ],
            [
              105.81235286068136,
              21.017168308311458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6873",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016715028338467,
          "to_latitude": 21.017168308311458,
          "to_longitude": 105.81235286068136,
          "from_longitude": 105.81206365961177
        }
      },
      {
        "id": 6866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81274638637147,
            21.01682369461149,
            105.81276918867515,
            21.016882046410995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81274638637147,
              21.016882046410995
            ],
            [
              105.8127480619345,
              21.01687775800784
            ],
            [
              105.81276918867515,
              21.01682369461149
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6874",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016882046410995,
          "to_latitude": 21.01682369461149,
          "to_longitude": 105.81276918867515,
          "from_longitude": 105.81274638637147
        }
      },
      {
        "id": 6867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81239295428514,
            21.016977124734474,
            105.8128304019544,
            21.017168699857013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81239295428514,
              21.016977124734474
            ],
            [
              105.8125110699911,
              21.017168699857013
            ],
            [
              105.81282775647328,
              21.016980173645123
            ],
            [
              105.8128304019544,
              21.01697859842045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6875",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016977124734474,
          "to_latitude": 21.01697859842045,
          "to_longitude": 105.8128304019544,
          "from_longitude": 105.81239295428514
        }
      },
      {
        "id": 6868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81260858677706,
            21.017028996650385,
            105.81286047820429,
            21.017152368874054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81260858677706,
              21.017152368874054
            ],
            [
              105.81285781010648,
              21.01703030372483
            ],
            [
              105.81286047820429,
              21.017028996650385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6876",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017152368874054,
          "to_latitude": 21.017028996650385,
          "to_longitude": 105.81286047820429,
          "from_longitude": 105.81260858677706
        }
      },
      {
        "id": 6869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81277665097659,
            21.0171931871701,
            105.81295746499947,
            21.01729418662137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81277665097659,
              21.01729418662137
            ],
            [
              105.81292943006376,
              21.017217884427783
            ],
            [
              105.81295598201665,
              21.01719449309888
            ],
            [
              105.81295746499947,
              21.0171931871701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6877",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01729418662137,
          "to_latitude": 21.0171931871701,
          "to_longitude": 105.81295746499947,
          "from_longitude": 105.81277665097659
        }
      },
      {
        "id": 6870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8131889733474,
            21.017002555444257,
            105.81320816175325,
            21.017035984320845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81320816175325,
              21.017035984320845
            ],
            [
              105.8131889733474,
              21.017002555444257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_26_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6878",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017035984320845,
          "to_latitude": 21.017002555444257,
          "to_longitude": 105.8131889733474,
          "from_longitude": 105.81320816175325
        }
      },
      {
        "id": 6871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337257789536,
            21.016648707918815,
            105.8137012478673,
            21.017125960841472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81337257789536,
              21.016648707918815
            ],
            [
              105.81363891683961,
              21.017083485702383
            ],
            [
              105.81367903507821,
              21.01709662998351
            ],
            [
              105.81369259497785,
              21.01711458542749
            ],
            [
              105.8137012478673,
              21.017125960841472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.6879",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016648707918815,
          "to_latitude": 21.017125960841472,
          "to_longitude": 105.8137012478673,
          "from_longitude": 105.81337257789536
        }
      },
      {
        "id": 6872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81370089078143,
            21.01620025760673,
            105.81380684553366,
            21.016247870468394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81380684553366,
              21.01620025760673
            ],
            [
              105.81371894568831,
              21.016239923317904
            ],
            [
              105.81370089078143,
              21.016247870468394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6880",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01620025760673,
          "to_latitude": 21.016247870468394,
          "to_longitude": 105.81370089078143,
          "from_longitude": 105.81380684553366
        }
      },
      {
        "id": 6873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81422970687544,
            21.01595348389835,
            105.81424512625024,
            21.015977125254718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424512625024,
              21.015977125254718
            ],
            [
              105.81422970687544,
              21.01595348389835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.6881",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015977125254718,
          "to_latitude": 21.01595348389835,
          "to_longitude": 105.81422970687544,
          "from_longitude": 105.81424512625024
        }
      },
      {
        "id": 6874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81165823627336,
            21.020957012693366,
            105.81167429857385,
            21.02110578650052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81165823627336,
              21.02110578650052
            ],
            [
              105.81167429857385,
              21.020984793830785
            ],
            [
              105.81166270316973,
              21.020980040377246
            ],
            [
              105.81166300452675,
              21.0209767114476
            ],
            [
              105.81166376513431,
              21.02097185477454
            ],
            [
              105.81166608684906,
              21.020957012693366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6882",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02110578650052,
          "to_latitude": 21.020957012693366,
          "to_longitude": 105.81166608684906,
          "from_longitude": 105.81165823627336
        }
      },
      {
        "id": 6875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81081719388357,
            21.0204702418152,
            105.81084150852205,
            21.02051273118033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81084150852205,
              21.02051273118033
            ],
            [
              105.81081719388357,
              21.0204702418152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6883",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02051273118033,
          "to_latitude": 21.0204702418152,
          "to_longitude": 105.81081719388357,
          "from_longitude": 105.81084150852205
        }
      },
      {
        "id": 6876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81061504029289,
            21.020570499829756,
            105.81070711500693,
            21.020594363333093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81061504029289,
              21.02058644999988
            ],
            [
              105.8106480315011,
              21.020570499829756
            ],
            [
              105.81066000374052,
              21.020593189668936
            ],
            [
              105.81069314546295,
              21.020578568324638
            ],
            [
              105.81069794772183,
              21.02057644994746
            ],
            [
              105.81070195606068,
              21.020584775767798
            ],
            [
              105.81070441166459,
              21.02058987814161
            ],
            [
              105.81070711500693,
              21.020594363333093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6884",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02058644999988,
          "to_latitude": 21.020594363333093,
          "to_longitude": 105.81070711500693,
          "from_longitude": 105.81061504029289
        }
      },
      {
        "id": 6877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8109814801524,
            21.020380187169266,
            105.81100871801384,
            21.020433838213837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81100871801384,
              21.020433838213837
            ],
            [
              105.81100659152679,
              21.020429649245386
            ],
            [
              105.8110060966106,
              21.0204286743123
            ],
            [
              105.8109814801524,
              21.020380187169266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6885",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020433838213837,
          "to_latitude": 21.020380187169266,
          "to_longitude": 105.8109814801524,
          "from_longitude": 105.81100871801384
        }
      },
      {
        "id": 6878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81120399023966,
            21.020918559617918,
            105.81122379263996,
            21.021068793131064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81120399023966,
              21.021068793131064
            ],
            [
              105.81122197822361,
              21.020928848812282
            ],
            [
              105.81122272307248,
              21.020925166412315
            ],
            [
              105.81122329364052,
              21.02092234291013
            ],
            [
              105.81122379263996,
              21.020918559617918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6886",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021068793131064,
          "to_latitude": 21.020918559617918,
          "to_longitude": 105.81122379263996,
          "from_longitude": 105.81120399023966
        }
      },
      {
        "id": 6879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81157811726904,
            21.01873313403988,
            105.81167963246,
            21.01884784943759
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81164986670365,
              21.01873313403988
            ],
            [
              105.81167476454084,
              21.018789723517052
            ],
            [
              105.81167963246,
              21.01879690624371
            ],
            [
              105.8115807029918,
              21.01884748845516
            ],
            [
              105.81157811726904,
              21.01884784943759
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6887",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01873313403988,
          "to_latitude": 21.01884784943759,
          "to_longitude": 105.81157811726904,
          "from_longitude": 105.81164986670365
        }
      },
      {
        "id": 6880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81116647943732,
            21.02123266402778,
            105.81185174527779,
            21.02132341884666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81116647943732,
              21.02123266402778
            ],
            [
              105.81137256993158,
              21.021255678926146
            ],
            [
              105.81158216891298,
              21.021268581454326
            ],
            [
              105.81164704883675,
              21.02127806557236
            ],
            [
              105.81172419630894,
              21.021293667394062
            ],
            [
              105.81183121095015,
              21.02131862858702
            ],
            [
              105.81185174527779,
              21.02132341884666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6888",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02123266402778,
          "to_latitude": 21.02132341884666,
          "to_longitude": 105.81185174527779,
          "from_longitude": 105.81116647943732
        }
      },
      {
        "id": 6881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81073744089878,
            21.020759183906204,
            105.81078775467078,
            21.020784157725142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81073744089878,
              21.020784157725142
            ],
            [
              105.81075421708502,
              21.020776032710355
            ],
            [
              105.81076709977394,
              21.020769793224936
            ],
            [
              105.81078588870024,
              21.020760299255024
            ],
            [
              105.81078775467078,
              21.020759183906204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6889",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020784157725142,
          "to_latitude": 21.020759183906204,
          "to_longitude": 105.81078775467078,
          "from_longitude": 105.81073744089878
        }
      },
      {
        "id": 6882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81072055026999,
            21.020758352763323,
            105.8107440178847,
            21.020769691742885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81072055026999,
              21.020769691742885
            ],
            [
              105.81074376842257,
              21.02075847317846
            ],
            [
              105.8107440178847,
              21.020758352763323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6890",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020769691742885,
          "to_latitude": 21.020758352763323,
          "to_longitude": 105.8107440178847,
          "from_longitude": 105.81072055026999
        }
      },
      {
        "id": 6883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81086093768086,
            21.020371894117993,
            105.81132485818044,
            21.020540166244317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81086093768086,
              21.020540166244317
            ],
            [
              105.81100781640002,
              21.020465631624692
            ],
            [
              105.81110633367231,
              21.02042166692882
            ],
            [
              105.81111773500884,
              21.02041847474725
            ],
            [
              105.81112717304721,
              21.02041583205749
            ],
            [
              105.81124673063434,
              21.020404435803115
            ],
            [
              105.81126892715524,
              21.020371894117993
            ],
            [
              105.81131376302217,
              21.02037604105617
            ],
            [
              105.81132485818044,
              21.02037706749294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6891",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020540166244317,
          "to_latitude": 21.02037706749294,
          "to_longitude": 105.81132485818044,
          "from_longitude": 105.81086093768086
        }
      },
      {
        "id": 6884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81180675858882,
            21.019325627747577,
            105.81187008484736,
            21.01935545650174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81187008484736,
              21.019325627747577
            ],
            [
              105.81181862185967,
              21.01934982967778
            ],
            [
              105.8118174032667,
              21.019350402720683
            ],
            [
              105.81180865896123,
              21.01935465891432
            ],
            [
              105.81180675858882,
              21.01935545650174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6892",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019325627747577,
          "to_latitude": 21.01935545650174,
          "to_longitude": 105.81180675858882,
          "from_longitude": 105.81187008484736
        }
      },
      {
        "id": 6885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81187349364303,
            21.019481478748567,
            105.8119365269254,
            21.019488672138944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119365269254,
              21.019481478748567
            ],
            [
              105.81188294307336,
              21.01948725864791
            ],
            [
              105.81187619882989,
              21.019487987225936
            ],
            [
              105.81187349364303,
              21.019488672138944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6893",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019481478748567,
          "to_latitude": 21.019488672138944,
          "to_longitude": 105.81187349364303,
          "from_longitude": 105.8119365269254
        }
      },
      {
        "id": 6886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81193567282735,
            21.021009956227672,
            105.81198479011617,
            21.021474578963307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81193567282735,
              21.021474578963307
            ],
            [
              105.81195079204733,
              21.021403125599573
            ],
            [
              105.8119622275053,
              21.021335873570695
            ],
            [
              105.81196316786122,
              21.02132228724496
            ],
            [
              105.81196778673602,
              21.021255567240324
            ],
            [
              105.81198479011617,
              21.021009956227672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6894",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021474578963307,
          "to_latitude": 21.021009956227672,
          "to_longitude": 105.81198479011617,
          "from_longitude": 105.81193567282735
        }
      },
      {
        "id": 6887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81158309384237,
            21.018756797086102,
            105.81177928134093,
            21.018856268167994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81177928134093,
              21.018756797086102
            ],
            [
              105.8116425148494,
              21.018827948459712
            ],
            [
              105.81158821078532,
              21.018853829426234
            ],
            [
              105.81158309384237,
              21.018856268167994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6895",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018756797086102,
          "to_latitude": 21.018856268167994,
          "to_longitude": 105.81158309384237,
          "from_longitude": 105.81177928134093
        }
      },
      {
        "id": 6888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80996200033195,
            21.019754892163252,
            105.81065390754789,
            21.019922640464742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80996200033195,
              21.01988866025899
            ],
            [
              105.81005993184205,
              21.019837595067077
            ],
            [
              105.81006184461944,
              21.019836597826274
            ],
            [
              105.81007355629603,
              21.01983049042272
            ],
            [
              105.81008135704836,
              21.019826423068487
            ],
            [
              105.81008788389164,
              21.019823019330914
            ],
            [
              105.8101252518701,
              21.01980580828765
            ],
            [
              105.81022860994935,
              21.019810513179905
            ],
            [
              105.81022845820647,
              21.01979756160928
            ],
            [
              105.81027389983242,
              21.019797241284788
            ],
            [
              105.81027382745113,
              21.01980236112736
            ],
            [
              105.81037256275181,
              21.019805723174564
            ],
            [
              105.810381496267,
              21.01980602745434
            ],
            [
              105.81038801768344,
              21.01980624928949
            ],
            [
              105.81044077070872,
              21.019804942988372
            ],
            [
              105.81044969952745,
              21.01980473425331
            ],
            [
              105.81046034413022,
              21.019804457729766
            ],
            [
              105.81055683382827,
              21.019754892163252
            ],
            [
              105.81064535593325,
              21.01988692422149
            ],
            [
              105.81065390754789,
              21.019922640464742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6896",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01988866025899,
          "to_latitude": 21.019922640464742,
          "to_longitude": 105.81065390754789,
          "from_longitude": 105.80996200033195
        }
      },
      {
        "id": 6889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81176898251142,
            21.018310705723067,
            105.8122004974574,
            21.018998266040715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122004974574,
              21.018998266040715
            ],
            [
              105.81219476890772,
              21.018987774398784
            ],
            [
              105.81189696048635,
              21.018442386276718
            ],
            [
              105.81185424943507,
              21.018463339980663
            ],
            [
              105.81176898251142,
              21.018310705723067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6897",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018998266040715,
          "to_latitude": 21.018310705723067,
          "to_longitude": 105.81176898251142,
          "from_longitude": 105.8122004974574
        }
      },
      {
        "id": 6890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81182254323441,
            21.01980851488832,
            105.81190474198608,
            21.019817799943507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81190474198608,
              21.019817355794654
            ],
            [
              105.81184648321451,
              21.019809876031502
            ],
            [
              105.8118358807352,
              21.01980851488832
            ],
            [
              105.81182720210668,
              21.019817799943507
            ],
            [
              105.81182254323441,
              21.01981743288708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6898",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019817355794654,
          "to_latitude": 21.01981743288708,
          "to_longitude": 105.81182254323441,
          "from_longitude": 105.81190474198608
        }
      },
      {
        "id": 6891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81181911943635,
            21.019980708590694,
            105.81192535218946,
            21.019994683910667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81181911943635,
              21.019980708590694
            ],
            [
              105.81192535218946,
              21.019994683910667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6899",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019980708590694,
          "to_latitude": 21.019994683910667,
          "to_longitude": 105.81192535218946,
          "from_longitude": 105.81181911943635
        }
      },
      {
        "id": 6892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81092790350479,
            21.02122697582111,
            105.81112619599294,
            21.021248156624935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81112619599294,
              21.021240899378693
            ],
            [
              105.81096771003568,
              21.02122697582111
            ],
            [
              105.81092790350479,
              21.021248156624935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6900",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021240899378693,
          "to_latitude": 21.021248156624935,
          "to_longitude": 105.81092790350479,
          "from_longitude": 105.81112619599294
        }
      },
      {
        "id": 6893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81096342586629,
            21.02039245959957,
            105.81100393450879,
            21.020445470211477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81096342586629,
              21.02039245959957
            ],
            [
              105.81099767491482,
              21.020432655249824
            ],
            [
              105.8110002711513,
              21.020437968304126
            ],
            [
              105.81100393450879,
              21.020445470211477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6901",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02039245959957,
          "to_latitude": 21.020445470211477,
          "to_longitude": 105.81100393450879,
          "from_longitude": 105.81096342586629
        }
      },
      {
        "id": 6894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81096970589303,
            21.02038930900561,
            105.81100852221007,
            21.020443168590205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81096970589303,
              21.02038930900561
            ],
            [
              105.81100089040294,
              21.020431339270466
            ],
            [
              105.81100399375546,
              21.020436147712047
            ],
            [
              105.81100852221007,
              21.020443168590205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6902",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02038930900561,
          "to_latitude": 21.020443168590205,
          "to_longitude": 105.81100852221007,
          "from_longitude": 105.81096970589303
        }
      },
      {
        "id": 6895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81077371559269,
            21.020489627303416,
            105.8108053641581,
            21.02054507826541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81077371559269,
              21.020489627303416
            ],
            [
              105.81079904382302,
              21.02053348113725
            ],
            [
              105.81080055272493,
              21.02053624775828
            ],
            [
              105.81080463447356,
              21.02054373864343
            ],
            [
              105.8108053641581,
              21.02054507826541
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6903",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020489627303416,
          "to_latitude": 21.02054507826541,
          "to_longitude": 105.8108053641581,
          "from_longitude": 105.81077371559269
        }
      },
      {
        "id": 6896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81073890812608,
            21.02076159258756,
            105.81078931839605,
            21.020787400546446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81073890812608,
              21.020787400546446
            ],
            [
              105.81075599997837,
              21.020779123187797
            ],
            [
              105.8107703688736,
              21.020772163971202
            ],
            [
              105.81078732527726,
              21.020762676929486
            ],
            [
              105.81078931839605,
              21.02076159258756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6904",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020787400546446,
          "to_latitude": 21.02076159258756,
          "to_longitude": 105.81078931839605,
          "from_longitude": 105.81073890812608
        }
      },
      {
        "id": 6897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81184506201781,
            21.019802344473554,
            105.81195939370443,
            21.019817965642194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81184506201781,
              21.019802344473554
            ],
            [
              105.81195939370443,
              21.019817965642194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6905",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019802344473554,
          "to_latitude": 21.019817965642194,
          "to_longitude": 105.81195939370443,
          "from_longitude": 105.81184506201781
        }
      },
      {
        "id": 6898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81143868670948,
            21.020938179756655,
            105.81145582415681,
            21.021090904686243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81143868670948,
              21.021090904686243
            ],
            [
              105.81145582415681,
              21.020961816690356
            ],
            [
              105.81144787954321,
              21.02095139069515
            ],
            [
              105.8114473428106,
              21.02095068873788
            ],
            [
              105.81144946265137,
              21.020938179756655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6906",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021090904686243,
          "to_latitude": 21.020938179756655,
          "to_longitude": 105.81144946265137,
          "from_longitude": 105.81143868670948
        }
      },
      {
        "id": 6899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118571825679,
            21.020797166015615,
            105.81193579057998,
            21.020808595090358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8118571825679,
              21.020797166015615
            ],
            [
              105.81192546671828,
              21.020804516618707
            ],
            [
              105.81192763266742,
              21.020807637770922
            ],
            [
              105.81193579057998,
              21.020808595090358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6907",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020797166015615,
          "to_latitude": 21.020808595090358,
          "to_longitude": 105.81193579057998,
          "from_longitude": 105.8118571825679
        }
      },
      {
        "id": 6900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81088474709638,
            21.021173049412365,
            105.81176939385419,
            21.021479363081728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81088474709638,
              21.021173049412365
            ],
            [
              105.81092790350479,
              21.021248156624935
            ],
            [
              105.81095260581975,
              21.021291149022197
            ],
            [
              105.8109837177458,
              21.021277567879565
            ],
            [
              105.81100161501543,
              21.021312015661767
            ],
            [
              105.81098936835605,
              21.021360624364213
            ],
            [
              105.8110051494566,
              21.021389945577702
            ],
            [
              105.81111076154058,
              21.021402821496782
            ],
            [
              105.8111128215321,
              21.021403072706217
            ],
            [
              105.81117905025233,
              21.02141114695366
            ],
            [
              105.81122169661175,
              21.02141469467012
            ],
            [
              105.81122687149644,
              21.021415125216187
            ],
            [
              105.81130422964851,
              21.021423076785357
            ],
            [
              105.81130627210064,
              21.02142328652761
            ],
            [
              105.8113227869008,
              21.021424983500122
            ],
            [
              105.81149414677463,
              21.021446464026834
            ],
            [
              105.81149529519138,
              21.021446607580412
            ],
            [
              105.81152756355202,
              21.021450652733236
            ],
            [
              105.81162560077894,
              21.02145902665375
            ],
            [
              105.81168463447166,
              21.02146737529384
            ],
            [
              105.81168712212947,
              21.0214677274234
            ],
            [
              105.81176939385419,
              21.021479363081728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6908",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021173049412365,
          "to_latitude": 21.021479363081728,
          "to_longitude": 105.81176939385419,
          "from_longitude": 105.81088474709638
        }
      },
      {
        "id": 6901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81081903265449,
            21.01910711541344,
            105.81110058720127,
            21.019586348334602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81081903265449,
              21.01910711541344
            ],
            [
              105.81105950041392,
              21.019524582791355
            ],
            [
              105.81109768519872,
              21.019581770160226
            ],
            [
              105.81109874451452,
              21.019583228338718
            ],
            [
              105.81110058720127,
              21.019586348334602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6909",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01910711541344,
          "to_latitude": 21.019586348334602,
          "to_longitude": 105.81110058720127,
          "from_longitude": 105.81081903265449
        }
      },
      {
        "id": 6902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81134516094413,
            21.02090518741782,
            105.8118915544193,
            21.020956636646275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81134516094413,
              21.02090518741782
            ],
            [
              105.8116194269412,
              21.02093101282846
            ],
            [
              105.81166519601611,
              21.02093532231308
            ],
            [
              105.8118915544193,
              21.020956636646275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6910",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02090518741782,
          "to_latitude": 21.020956636646275,
          "to_longitude": 105.8118915544193,
          "from_longitude": 105.81134516094413
        }
      },
      {
        "id": 6903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81150071196274,
            21.020942731544068,
            105.81150181775436,
            21.020956685368745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81150071196274,
              21.020956685368745
            ],
            [
              105.811500985724,
              21.02095323373193
            ],
            [
              105.8115009955576,
              21.020953095491148
            ],
            [
              105.81150128108611,
              21.020949507410776
            ],
            [
              105.81150181775436,
              21.020942731544068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6911",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020956685368745,
          "to_latitude": 21.020942731544068,
          "to_longitude": 105.81150181775436,
          "from_longitude": 105.81150071196274
        }
      },
      {
        "id": 6904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81169953741268,
            21.020351471392026,
            105.81172270829697,
            21.020516060678442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81172270829697,
              21.020351471392026
            ],
            [
              105.81169953741268,
              21.020516060678442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6912",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020351471392026,
          "to_latitude": 21.020516060678442,
          "to_longitude": 105.81169953741268,
          "from_longitude": 105.81172270829697
        }
      },
      {
        "id": 6905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81158708896804,
            21.021458741292967,
            105.81160374402553,
            21.02150826381905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81160201526387,
              21.021473116646685
            ],
            [
              105.81160374402553,
              21.02145962501214
            ],
            [
              105.81159359806851,
              21.021458741292967
            ],
            [
              105.8115918589531,
              21.02147209749265
            ],
            [
              105.81158913712333,
              21.02149299762437
            ],
            [
              105.81158788153807,
              21.021502380023897
            ],
            [
              105.81158785194349,
              21.021502599649473
            ],
            [
              105.81158708896804,
              21.02150826381905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6913",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021473116646685,
          "to_latitude": 21.02150826381905,
          "to_longitude": 105.81158708896804,
          "from_longitude": 105.81160201526387
        }
      },
      {
        "id": 6906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81097740109004,
            21.020899974407715,
            105.81101002982646,
            21.021043963621583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81097740109004,
              21.021043963621583
            ],
            [
              105.81098119633617,
              21.021015372811355
            ],
            [
              105.81099758175034,
              21.020946019808044
            ],
            [
              105.81100614120031,
              21.02090951929459
            ],
            [
              105.81101002982646,
              21.020899974407715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6914",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021043963621583,
          "to_latitude": 21.020899974407715,
          "to_longitude": 105.81101002982646,
          "from_longitude": 105.81097740109004
        }
      },
      {
        "id": 6907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8100495897369,
            21.019822460147115,
            105.81006284039925,
            21.019838227024124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81006284039925,
              21.019838227024124
            ],
            [
              105.81006184461944,
              21.019836597826274
            ],
            [
              105.81005269794292,
              21.019826394259464
            ],
            [
              105.8100495897369,
              21.019822460147115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6915",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019838227024124,
          "to_latitude": 21.019822460147115,
          "to_longitude": 105.8100495897369,
          "from_longitude": 105.81006284039925
        }
      },
      {
        "id": 6908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81061060949274,
            21.02055927345715,
            105.81070357684987,
            21.02059613878158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81061060949274,
              21.020578094577644
            ],
            [
              105.81064976873941,
              21.02055927345715
            ],
            [
              105.81066360377208,
              21.020585776879788
            ],
            [
              105.81068988878167,
              21.02057333338115
            ],
            [
              105.81069314546295,
              21.020578568324638
            ],
            [
              105.81069819738815,
              21.02058668775139
            ],
            [
              105.810701391978,
              21.020591825441997
            ],
            [
              105.81070357684987,
              21.02059613878158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6916",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020578094577644,
          "to_latitude": 21.02059613878158,
          "to_longitude": 105.81070357684987,
          "from_longitude": 105.81061060949274
        }
      },
      {
        "id": 6909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81182235822644,
            21.019818441772493,
            105.81190368559767,
            21.019827456216085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81190368559767,
              21.019827456216085
            ],
            [
              105.81184493435805,
              21.019820876611718
            ],
            [
              105.81182697701004,
              21.019818866826828
            ],
            [
              105.81182235822644,
              21.019818441772493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6917",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019827456216085,
          "to_latitude": 21.019818441772493,
          "to_longitude": 105.81182235822644,
          "from_longitude": 105.81190368559767
        }
      },
      {
        "id": 6910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116621756037,
            21.021781662143383,
            105.81166231405808,
            21.02182874403944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8116621756037,
              21.021781662143383
            ],
            [
              105.81166225801061,
              21.021809681683703
            ],
            [
              105.81166226319642,
              21.021811883728393
            ],
            [
              105.81166231405808,
              21.02182874403944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6918",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021781662143383,
          "to_latitude": 21.02182874403944,
          "to_longitude": 105.81166231405808,
          "from_longitude": 105.8116621756037
        }
      },
      {
        "id": 6911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81149181274078,
            21.021415837175997,
            105.81149873223819,
            21.02146205584344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81149873223819,
              21.021415837175997
            ],
            [
              105.8114945413271,
              21.02144382381579
            ],
            [
              105.81149414677463,
              21.021446464026834
            ],
            [
              105.81149190464058,
              21.021461439403552
            ],
            [
              105.81149181274078,
              21.02146205584344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6919",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021415837175997,
          "to_latitude": 21.02146205584344,
          "to_longitude": 105.81149181274078,
          "from_longitude": 105.81149873223819
        }
      },
      {
        "id": 6912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81158805004455,
            21.018764748528753,
            105.81178413418571,
            21.018864655382416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81178413418571,
              21.018764748528753
            ],
            [
              105.81164448108211,
              21.01883221134723
            ],
            [
              105.81159294562785,
              21.01886184105048
            ],
            [
              105.81158805004455,
              21.018864655382416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6920",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018764748528753,
          "to_latitude": 21.018864655382416,
          "to_longitude": 105.81158805004455,
          "from_longitude": 105.81178413418571
        }
      },
      {
        "id": 6913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81168255351258,
            21.021436633525646,
            105.81168926256359,
            21.02148120009433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81168926256359,
              21.021436633525646
            ],
            [
              105.81168515074405,
              21.021463941467545
            ],
            [
              105.81168463447166,
              21.02146737529384
            ],
            [
              105.81168260458176,
              21.021480860236952
            ],
            [
              105.81168255351258,
              21.02148120009433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6921",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021436633525646,
          "to_latitude": 21.02148120009433,
          "to_longitude": 105.81168255351258,
          "from_longitude": 105.81168926256359
        }
      },
      {
        "id": 6914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809425366432,
            21.019457925160374,
            105.80957934743219,
            21.019784739120382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809425366432,
              21.019457925160374
            ],
            [
              105.80942917684175,
              21.01946453130622
            ],
            [
              105.80957934743219,
              21.01972487767126
            ],
            [
              105.8094616785111,
              21.019784739120382
            ],
            [
              105.80943507220894,
              21.01973889953236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6922",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019457925160374,
          "to_latitude": 21.01973889953236,
          "to_longitude": 105.80943507220894,
          "from_longitude": 105.809425366432
        }
      },
      {
        "id": 6915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81171892599039,
            21.01903614392604,
            105.81183547908309,
            21.019086125680527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81183547908309,
              21.01903614392604
            ],
            [
              105.81177438885936,
              21.019061233595394
            ],
            [
              105.81171892599039,
              21.019086125680527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6923",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01903614392604,
          "to_latitude": 21.019086125680527,
          "to_longitude": 105.81171892599039,
          "from_longitude": 105.81183547908309
        }
      },
      {
        "id": 6916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81093899080668,
            21.020872210972225,
            105.81131558488867,
            21.020905516363317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81093899080668,
              21.020872210972225
            ],
            [
              105.81098799703145,
              21.020876545852076
            ],
            [
              105.81131558488867,
              21.020905516363317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6924",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020872210972225,
          "to_latitude": 21.020905516363317,
          "to_longitude": 105.81131558488867,
          "from_longitude": 105.81093899080668
        }
      },
      {
        "id": 6917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81174637292679,
            21.020129774346564,
            105.8117523692091,
            21.020181072891862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81174637292679,
              21.020181072891862
            ],
            [
              105.81174877897217,
              21.020160487682855
            ],
            [
              105.81175101522932,
              21.020143190130668
            ],
            [
              105.81175129921826,
              21.02014038515576
            ],
            [
              105.8117523692091,
              21.020129774346564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6925",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020181072891862,
          "to_latitude": 21.020129774346564,
          "to_longitude": 105.8117523692091,
          "from_longitude": 105.81174637292679
        }
      },
      {
        "id": 6918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81077711270677,
            21.020488632011695,
            105.81080846580345,
            21.02054352257356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81077711270677,
              21.020488632011695
            ],
            [
              105.81080224046686,
              21.020532237509617
            ],
            [
              105.81080349951115,
              21.020534517578447
            ],
            [
              105.81080811023888,
              21.02054287846036
            ],
            [
              105.81080846580345,
              21.02054352257356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6926",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020488632011695,
          "to_latitude": 21.02054352257356,
          "to_longitude": 105.81080846580345,
          "from_longitude": 105.81077711270677
        }
      },
      {
        "id": 6919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81196316786122,
            21.02132228724496,
            105.81197481377595,
            21.02132357113765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81196316786122,
              21.02132228724496
            ],
            [
              105.8119634662572,
              21.021322321046405
            ],
            [
              105.81196370593494,
              21.021322347902323
            ],
            [
              105.81196930606094,
              21.021322965170494
            ],
            [
              105.81197169607059,
              21.021323227439275
            ],
            [
              105.81197481377595,
              21.02132357113765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6927",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02132228724496,
          "to_latitude": 21.02132357113765,
          "to_longitude": 105.81197481377595,
          "from_longitude": 105.81196316786122
        }
      },
      {
        "id": 6920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81186060542161,
            21.01961433563726,
            105.8119801006723,
            21.01963170970443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81186060542161,
              21.01961433563726
            ],
            [
              105.81186346568202,
              21.019614752825692
            ],
            [
              105.81187312565042,
              21.019616156401558
            ],
            [
              105.8119801006723,
              21.01963170970443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6928",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01961433563726,
          "to_latitude": 21.01963170970443,
          "to_longitude": 105.8119801006723,
          "from_longitude": 105.81186060542161
        }
      },
      {
        "id": 6921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81174896714607,
            21.02013001864107,
            105.81175493033268,
            21.020181033414907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81174896714607,
              21.020181033414907
            ],
            [
              105.8117511488806,
              21.020162371345467
            ],
            [
              105.8117534771726,
              21.020143060962106
            ],
            [
              105.81175376433205,
              21.020140486295197
            ],
            [
              105.81175487149635,
              21.02013052292306
            ],
            [
              105.81175493033268,
              21.02013001864107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6929",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020181033414907,
          "to_latitude": 21.02013001864107,
          "to_longitude": 105.81175493033268,
          "from_longitude": 105.81174896714607
        }
      },
      {
        "id": 6922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81180199250115,
            21.019316840353753,
            105.81186381625156,
            21.0193459429655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81186381625156,
              21.019316840353753
            ],
            [
              105.81181199343801,
              21.01934121019323
            ],
            [
              105.81181080271689,
              21.019341778586913
            ],
            [
              105.81180418133187,
              21.01934494348488
            ],
            [
              105.81180199250115,
              21.0193459429655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6930",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019316840353753,
          "to_latitude": 21.0193459429655,
          "to_longitude": 105.81180199250115,
          "from_longitude": 105.81186381625156
        }
      },
      {
        "id": 6923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81099243306171,
            21.0190375260413,
            105.81126638581236,
            21.019499549587792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81099243306171,
              21.0190375260413
            ],
            [
              105.8112640285717,
              21.019496092434007
            ],
            [
              105.81126638581236,
              21.019499549587792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6931",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0190375260413,
          "to_latitude": 21.019499549587792,
          "to_longitude": 105.81126638581236,
          "from_longitude": 105.81099243306171
        }
      },
      {
        "id": 6924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81109768519872,
            21.01951252530241,
            105.81123420225757,
            21.019581770160226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81109768519872,
              21.019581770160226
            ],
            [
              105.81123420225757,
              21.01951252530241
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6932",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019581770160226,
          "to_latitude": 21.01951252530241,
          "to_longitude": 105.81123420225757,
          "from_longitude": 105.81109768519872
        }
      },
      {
        "id": 6925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81121869445728,
            21.02141469467012,
            105.81122169661175,
            21.02143464322893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81122169661175,
              21.02141469467012
            ],
            [
              105.81122166407211,
              21.02141490347097
            ],
            [
              105.81122138832055,
              21.021416743758195
            ],
            [
              105.81122088594893,
              21.02142008177495
            ],
            [
              105.81121869445728,
              21.02143464322893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6933",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02141469467012,
          "to_latitude": 21.02143464322893,
          "to_longitude": 105.81121869445728,
          "from_longitude": 105.81122169661175
        }
      },
      {
        "id": 6926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81168911240992,
            21.020674389381266,
            105.81192403496082,
            21.02070851303812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81168911240992,
              21.020674389381266
            ],
            [
              105.81188622429657,
              21.020705436476828
            ],
            [
              105.81191318522524,
              21.02070769140574
            ],
            [
              105.81191787889729,
              21.020708084486365
            ],
            [
              105.81192403496082,
              21.02070851303812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6934",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020674389381266,
          "to_latitude": 21.02070851303812,
          "to_longitude": 105.81192403496082,
          "from_longitude": 105.81168911240992
        }
      },
      {
        "id": 6927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81130124044454,
            21.021398098143,
            105.81130799051196,
            21.02144292773491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81130799051196,
              21.021398098143
            ],
            [
              105.81130422964851,
              21.021423076785357
            ],
            [
              105.8113039761003,
              21.02142475348252
            ],
            [
              105.81130152181541,
              21.02144105942071
            ],
            [
              105.81130124044454,
              21.02144292773491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6935",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021398098143,
          "to_latitude": 21.02144292773491,
          "to_longitude": 105.81130124044454,
          "from_longitude": 105.81130799051196
        }
      },
      {
        "id": 6928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80923614606932,
            21.019489519855853,
            105.80926436655233,
            21.019539479553856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80923614606932,
              21.019489519855853
            ],
            [
              105.80924317206559,
              21.01950196091928
            ],
            [
              105.80925261351881,
              21.01951867269722
            ],
            [
              105.80926436655233,
              21.019539479553856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6936",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019489519855853,
          "to_latitude": 21.019539479553856,
          "to_longitude": 105.80926436655233,
          "from_longitude": 105.80923614606932
        }
      },
      {
        "id": 6929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81071003567159,
            21.018662679650475,
            105.81112170493759,
            21.018890586452855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81071003567159,
              21.018890586452855
            ],
            [
              105.81076566550871,
              21.01885972626852
            ],
            [
              105.81084936950587,
              21.018817001119988
            ],
            [
              105.81085614322198,
              21.018813543790248
            ],
            [
              105.8108797231542,
              21.018801507873793
            ],
            [
              105.81090533203,
              21.018788436214926
            ],
            [
              105.81099815088189,
              21.018739728818687
            ],
            [
              105.81102776449625,
              21.018724188494144
            ],
            [
              105.81112170493759,
              21.018662679650475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6937",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018890586452855,
          "to_latitude": 21.018662679650475,
          "to_longitude": 105.81112170493759,
          "from_longitude": 105.81071003567159
        }
      },
      {
        "id": 6930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81086833104126,
            21.018782899675276,
            105.81090940810807,
            21.01884985978981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81090940810807,
              21.01884985978981
            ],
            [
              105.8108797705137,
              21.018801549196528
            ],
            [
              105.8108797231542,
              21.018801507873793
            ],
            [
              105.8108760269868,
              21.018798247677402
            ],
            [
              105.8108720101929,
              21.018788884089425
            ],
            [
              105.81086833104126,
              21.018782899675276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6938",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01884985978981,
          "to_latitude": 21.018782899675276,
          "to_longitude": 105.81086833104126,
          "from_longitude": 105.81090940810807
        }
      },
      {
        "id": 6931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81098577858207,
            21.020876545852076,
            105.81098799703145,
            21.020897865385034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81098577858207,
              21.020897865385034
            ],
            [
              105.8109862957843,
              21.020892999939377
            ],
            [
              105.81098799703145,
              21.020876545852076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6939",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020897865385034,
          "to_latitude": 21.020876545852076,
          "to_longitude": 105.81098799703145,
          "from_longitude": 105.81098577858207
        }
      },
      {
        "id": 6932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809276852188,
            21.019818326257393,
            105.80933026953903,
            21.01991016437642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809276852188,
              21.019818326257393
            ],
            [
              105.80931538893348,
              21.019887186594044
            ],
            [
              105.80932831010068,
              21.01990679472181
            ],
            [
              105.80933026953903,
              21.01991016437642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6940",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019818326257393,
          "to_latitude": 21.01991016437642,
          "to_longitude": 105.80933026953903,
          "from_longitude": 105.809276852188
        }
      },
      {
        "id": 6933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81151954352725,
            21.02052810327812,
            105.81165545833868,
            21.020542247175715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81151954352725,
              21.02052810327812
            ],
            [
              105.81164215675899,
              21.02054130241818
            ],
            [
              105.8116441934815,
              21.02054152211957
            ],
            [
              105.81165446453605,
              21.020542247175715
            ],
            [
              105.81165471793601,
              21.020538766732482
            ],
            [
              105.81165545833868,
              21.02053195466716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6941",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02052810327812,
          "to_latitude": 21.02053195466716,
          "to_longitude": 105.81165545833868,
          "from_longitude": 105.81151954352725
        }
      },
      {
        "id": 6934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81086510297213,
            21.02109267157104,
            105.81170967409615,
            21.021172963388402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81086510297213,
              21.02109306918652
            ],
            [
              105.81094899597876,
              21.02110214951456
            ],
            [
              105.81095021068202,
              21.02109267157104
            ],
            [
              105.81170967409615,
              21.021172963388402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6942",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02109306918652,
          "to_latitude": 21.021172963388402,
          "to_longitude": 105.81170967409615,
          "from_longitude": 105.81086510297213
        }
      },
      {
        "id": 6935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81159645859788,
            21.020115200955654,
            105.81159976427931,
            21.020165615044405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81159645859788,
              21.020165615044405
            ],
            [
              105.8115984500227,
              21.020148578791577
            ],
            [
              105.81159864726301,
              21.02012974464331
            ],
            [
              105.8115986635285,
              21.020128128690786
            ],
            [
              105.81159976427931,
              21.020115200955654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6943",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020165615044405,
          "to_latitude": 21.020115200955654,
          "to_longitude": 105.81159976427931,
          "from_longitude": 105.81159645859788
        }
      },
      {
        "id": 6936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8111020325847,
            21.02143110435066,
            105.8118562394154,
            21.021524538880563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8111020325847,
              21.02143110435066
            ],
            [
              105.81122420071709,
              21.021441381241754
            ],
            [
              105.8112394806205,
              21.021447219992687
            ],
            [
              105.81137228986069,
              21.02146472784766
            ],
            [
              105.8113709352427,
              21.021487455910698
            ],
            [
              105.81145418782081,
              21.021495470518527
            ],
            [
              105.81158708896804,
              21.02150826381905
            ],
            [
              105.8117561553274,
              21.021524538880563
            ],
            [
              105.81176090948284,
              21.0214968286452
            ],
            [
              105.8118562394154,
              21.02150585303092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6944",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02143110435066,
          "to_latitude": 21.02150585303092,
          "to_longitude": 105.8118562394154,
          "from_longitude": 105.8111020325847
        }
      },
      {
        "id": 6937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8108377535379,
            21.018798978375116,
            105.81087729508914,
            21.01886358568128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81087729508914,
              21.01886358568128
            ],
            [
              105.8108701500049,
              21.018851911192613
            ],
            [
              105.81084936950587,
              21.018817001119988
            ],
            [
              105.81084767745803,
              21.018814162209903
            ],
            [
              105.8108377535379,
              21.018798978375116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6945",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01886358568128,
          "to_latitude": 21.018798978375116,
          "to_longitude": 105.8108377535379,
          "from_longitude": 105.81087729508914
        }
      },
      {
        "id": 6938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81062398851138,
            21.02058000891158,
            105.81065935295159,
            21.020606444030207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81062398851138,
              21.020590355366036
            ],
            [
              105.8106453999642,
              21.02058000891158
            ],
            [
              105.81065237549774,
              21.020593226927254
            ],
            [
              105.81065935295159,
              21.020606444030207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6946",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020590355366036,
          "to_latitude": 21.020606444030207,
          "to_longitude": 105.81065935295159,
          "from_longitude": 105.81062398851138
        }
      },
      {
        "id": 6939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81142127890814,
            21.020381056864526,
            105.81166393556765,
            21.020412938319215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81166393556765,
              21.020412938319215
            ],
            [
              105.81142127890814,
              21.020381056864526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6947",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020412938319215,
          "to_latitude": 21.020381056864526,
          "to_longitude": 105.81142127890814,
          "from_longitude": 105.81166393556765
        }
      },
      {
        "id": 6940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81144513818325,
            21.020101291518575,
            105.8114541189691,
            21.02017812138874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81144513818325,
              21.02017812138874
            ],
            [
              105.81144946674759,
              21.020141088261415
            ],
            [
              105.8114535785035,
              21.020115543422314
            ],
            [
              105.81145381180635,
              21.020109414810378
            ],
            [
              105.8114541189691,
              21.020101291518575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6948",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02017812138874,
          "to_latitude": 21.020101291518575,
          "to_longitude": 105.8114541189691,
          "from_longitude": 105.81144513818325
        }
      },
      {
        "id": 6941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81131161517386,
            21.020087682198817,
            105.81132315642654,
            21.020192321339703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81131328370896,
              21.020192321339703
            ],
            [
              105.81132315642654,
              21.020107867588624
            ],
            [
              105.81131168894882,
              21.020099718264053
            ],
            [
              105.81131161517386,
              21.020087682198817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6949",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020192321339703,
          "to_latitude": 21.020087682198817,
          "to_longitude": 105.81131161517386,
          "from_longitude": 105.81131328370896
        }
      },
      {
        "id": 6942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81192065696355,
            21.020221565639673,
            105.8120496608077,
            21.02099002284073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81192065696355,
              21.02099002284073
            ],
            [
              105.81192215673363,
              21.02098665657735
            ],
            [
              105.81192344592766,
              21.020980110150564
            ],
            [
              105.8119885813541,
              21.020977285539125
            ],
            [
              105.81200704343797,
              21.020792149499705
            ],
            [
              105.81200831584725,
              21.020708602072784
            ],
            [
              105.81203770919963,
              21.02049298951356
            ],
            [
              105.8120496608077,
              21.020408266583065
            ],
            [
              105.81200899092096,
              21.020403354789366
            ],
            [
              105.81202446280597,
              21.020267900613298
            ],
            [
              105.81203152287678,
              21.020221565639673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6950",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02099002284073,
          "to_latitude": 21.020221565639673,
          "to_longitude": 105.81203152287678,
          "from_longitude": 105.81192065696355
        }
      },
      {
        "id": 6943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118561943008,
            21.020804924944052,
            105.81193550367365,
            21.020811393756624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8118561943008,
              21.020804924944052
            ],
            [
              105.81192475737602,
              21.02081048943772
            ],
            [
              105.81192683134458,
              21.020810658377048
            ],
            [
              105.81193550367365,
              21.020811393756624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6951",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020804924944052,
          "to_latitude": 21.020811393756624,
          "to_longitude": 105.81193550367365,
          "from_longitude": 105.8118561943008
        }
      },
      {
        "id": 6944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8109869488949,
            21.01872052539765,
            105.81099815088189,
            21.018739728818687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81099815088189,
              21.018739728818687
            ],
            [
              105.81099664395892,
              21.01873714735081
            ],
            [
              105.8109942544111,
              21.018733049054443
            ],
            [
              105.81099228141692,
              21.018729666838578
            ],
            [
              105.8109869488949,
              21.01872052539765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6952",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018739728818687,
          "to_latitude": 21.01872052539765,
          "to_longitude": 105.8109869488949,
          "from_longitude": 105.81099815088189
        }
      },
      {
        "id": 6945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81147056367759,
            21.018781793717597,
            105.8117628679553,
            21.01926463773163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81147056367759,
              21.018781793717597
            ],
            [
              105.81176010649021,
              21.01926007876914
            ],
            [
              105.8117628679553,
              21.01926463773163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6953",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018781793717597,
          "to_latitude": 21.01926463773163,
          "to_longitude": 105.8117628679553,
          "from_longitude": 105.81147056367759
        }
      },
      {
        "id": 6946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81142111808107,
            21.01933240005008,
            105.81156479397526,
            21.019408361396238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81142111808107,
              21.019408361396238
            ],
            [
              105.81156479397526,
              21.01933240005008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Cạnh 81g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6954",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019408361396238,
          "to_latitude": 21.01933240005008,
          "to_longitude": 105.81156479397526,
          "from_longitude": 105.81142111808107
        }
      },
      {
        "id": 6947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81085757043756,
            21.018775561338096,
            105.8114442664963,
            21.01906843612972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81085757043756,
              21.01906843612972
            ],
            [
              105.8114442664963,
              21.018775561338096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6955",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01906843612972,
          "to_latitude": 21.018775561338096,
          "to_longitude": 105.8114442664963,
          "from_longitude": 105.81085757043756
        }
      },
      {
        "id": 6948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81160677594197,
            21.0216101789359,
            105.81160746889014,
            21.02161811709908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81160677594197,
              21.02161811709908
            ],
            [
              105.81160716699564,
              21.02161376438455
            ],
            [
              105.81160746889014,
              21.0216101789359
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6956",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02161811709908,
          "to_latitude": 21.0216101789359,
          "to_longitude": 105.81160746889014,
          "from_longitude": 105.81160677594197
        }
      },
      {
        "id": 6949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81168505601434,
            21.021437664466703,
            105.81169164807778,
            21.021481450991555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81169164807778,
              21.021437664466703
            ],
            [
              105.81168756964715,
              21.021464742829426
            ],
            [
              105.81168712212947,
              21.0214677274234
            ],
            [
              105.81168505972036,
              21.02148142478026
            ],
            [
              105.81168505601434,
              21.021481450991555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6957",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021437664466703,
          "to_latitude": 21.021481450991555,
          "to_longitude": 105.81168505601434,
          "from_longitude": 105.81169164807778
        }
      },
      {
        "id": 6950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81164782115161,
            21.02180889341248,
            105.81164791291278,
            21.021828866954706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81164782115161,
              21.02180889341248
            ],
            [
              105.81164783464492,
              21.021812097097186
            ],
            [
              105.81164784992899,
              21.021815098450308
            ],
            [
              105.81164786619314,
              21.021818636316087
            ],
            [
              105.81164791291278,
              21.021828866954706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6958",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02180889341248,
          "to_latitude": 21.021828866954706,
          "to_longitude": 105.81164791291278,
          "from_longitude": 105.81164782115161
        }
      },
      {
        "id": 6951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81065706205474,
            21.020607608370618,
            105.81082310681418,
            21.020846997933813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81082310681418,
              21.020846997933813
            ],
            [
              105.81078950294659,
              21.020791277201806
            ],
            [
              105.81078422149855,
              21.020782520290087
            ],
            [
              105.81078202388704,
              21.020778876304494
            ],
            [
              105.81076163902115,
              21.02078889994693
            ],
            [
              105.81075599997837,
              21.020779123187797
            ],
            [
              105.81075421708502,
              21.020776032710355
            ],
            [
              105.8107440178847,
              21.020758352763323
            ],
            [
              105.81065706205474,
              21.020607608370618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6959",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020846997933813,
          "to_latitude": 21.020607608370618,
          "to_longitude": 105.81065706205474,
          "from_longitude": 105.81082310681418
        }
      },
      {
        "id": 6952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81098673980874,
            21.020912500305368,
            105.81101383291946,
            21.021045182711937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81098673980874,
              21.021045182711937
            ],
            [
              105.81101383291946,
              21.020912500305368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6960",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021045182711937,
          "to_latitude": 21.020912500305368,
          "to_longitude": 105.81101383291946,
          "from_longitude": 105.81098673980874
        }
      },
      {
        "id": 6953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81085614322198,
            21.018813543790248,
            105.81090875606124,
            21.01889756870479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81085614322198,
              21.018813543790248
            ],
            [
              105.81090875606124,
              21.01889756870479
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6961",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018813543790248,
          "to_latitude": 21.01889756870479,
          "to_longitude": 105.81090875606124,
          "from_longitude": 105.81085614322198
        }
      },
      {
        "id": 6954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81169026073056,
            21.020665985688193,
            105.8119264993139,
            21.020691415816064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81169026073056,
              21.020665985688193
            ],
            [
              105.81178249313287,
              21.02067671214188
            ],
            [
              105.81190173427366,
              21.020688750277852
            ],
            [
              105.81192268864245,
              21.02069100587564
            ],
            [
              105.8119264993139,
              21.020691415816064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6962",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020665985688193,
          "to_latitude": 21.020691415816064,
          "to_longitude": 105.8119264993139,
          "from_longitude": 105.81169026073056
        }
      },
      {
        "id": 6955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81164451825488,
            21.0201196329793,
            105.81164616674315,
            21.020134267719442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81164616674315,
              21.0201196329793
            ],
            [
              105.81164544530436,
              21.02012603850178
            ],
            [
              105.81164506807164,
              21.02012938947121
            ],
            [
              105.81164497439204,
              21.02013021004923
            ],
            [
              105.81164451825488,
              21.020134267719442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6963",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0201196329793,
          "to_latitude": 21.020134267719442,
          "to_longitude": 105.81164451825488,
          "from_longitude": 105.81164616674315
        }
      },
      {
        "id": 6956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81184715550052,
            21.020835647124198,
            105.81192074312486,
            21.020844258612144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81192074312486,
              21.020844258612144
            ],
            [
              105.81184715550052,
              21.020835647124198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6964",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020844258612144,
          "to_latitude": 21.020835647124198,
          "to_longitude": 105.81184715550052,
          "from_longitude": 105.81192074312486
        }
      },
      {
        "id": 6957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81129642534307,
            21.020087385408132,
            105.81130883187537,
            21.02019075064638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81129642534307,
              21.02019075064638
            ],
            [
              105.81130430472275,
              21.020123339478126
            ],
            [
              105.81130883187537,
              21.020099713826603
            ],
            [
              105.81130850736895,
              21.020087385408132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6965",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02019075064638,
          "to_latitude": 21.020087385408132,
          "to_longitude": 105.81130850736895,
          "from_longitude": 105.81129642534307
        }
      },
      {
        "id": 6958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81127263184014,
            21.02161529046618,
            105.81177112301854,
            21.021629737916317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81127263184014,
              21.021629737916317
            ],
            [
              105.81137160714917,
              21.021624430829196
            ],
            [
              105.81154936315184,
              21.021629324615308
            ],
            [
              105.81154950941358,
              21.02161529046618
            ],
            [
              105.81160677594197,
              21.02161811709908
            ],
            [
              105.81177112301854,
              21.021626229610103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6966",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021629737916317,
          "to_latitude": 21.021626229610103,
          "to_longitude": 105.81177112301854,
          "from_longitude": 105.81127263184014
        }
      },
      {
        "id": 6959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81151433413484,
            21.020547599017167,
            105.81189770270777,
            21.020593350718908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81151433413484,
              21.020547599017167
            ],
            [
              105.81163916636682,
              21.020562522673227
            ],
            [
              105.81164631170915,
              21.020563376451246
            ],
            [
              105.8116559742262,
              21.020564528928134
            ],
            [
              105.81189770270777,
              21.020593350718908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6967",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020547599017167,
          "to_latitude": 21.020593350718908,
          "to_longitude": 105.81189770270777,
          "from_longitude": 105.81151433413484
        }
      },
      {
        "id": 6960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81112971340114,
            21.018645453933882,
            105.81117738507051,
            21.018721181786827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81117738507051,
              21.018721181786827
            ],
            [
              105.81114886969397,
              21.018674588346297
            ],
            [
              105.81114257743077,
              21.01866319070654
            ],
            [
              105.81113820057061,
              21.018657154528366
            ],
            [
              105.81112971340114,
              21.018645453933882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6968",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018721181786827,
          "to_latitude": 21.018645453933882,
          "to_longitude": 105.81112971340114,
          "from_longitude": 105.81117738507051
        }
      },
      {
        "id": 6961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81149295234368,
            21.02141624544651,
            105.81149986573367,
            21.021462170535777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81149986573367,
              21.02141624544651
            ],
            [
              105.81149568702907,
              21.02144399899529
            ],
            [
              105.81149529519138,
              21.021446607580412
            ],
            [
              105.81149307121143,
              21.02146138235396
            ],
            [
              105.81149295234368,
              21.021462170535777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6969",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02141624544651,
          "to_latitude": 21.021462170535777,
          "to_longitude": 105.81149295234368,
          "from_longitude": 105.81149986573367
        }
      },
      {
        "id": 6962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81143322565987,
            21.021779246398467,
            105.81143337710739,
            21.021830698051097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81143322565987,
              21.021779246398467
            ],
            [
              105.81143332072735,
              21.02181156885722
            ],
            [
              105.81143333027325,
              21.021815288304015
            ],
            [
              105.81143337710739,
              21.021830698051097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6970",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021779246398467,
          "to_latitude": 21.021830698051097,
          "to_longitude": 105.81143337710739,
          "from_longitude": 105.81143322565987
        }
      },
      {
        "id": 6963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81114436502192,
            21.018944784354904,
            105.81142081208628,
            21.01942363463679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81114436502192,
              21.018944784354904
            ],
            [
              105.81141514111646,
              21.019413821920924
            ],
            [
              105.81141862141875,
              21.019419850625255
            ],
            [
              105.81142081208628,
              21.01942363463679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6971",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018944784354904,
          "to_latitude": 21.01942363463679,
          "to_longitude": 105.81142081208628,
          "from_longitude": 105.81114436502192
        }
      },
      {
        "id": 6964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81188963592056,
            21.020593350718908,
            105.81192690943531,
            21.020940468131737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81190930670101,
              21.020940468131737
            ],
            [
              105.81192074312486,
              21.020844258612144
            ],
            [
              105.81192367997419,
              21.02081955214975
            ],
            [
              105.81192449882474,
              21.020812662031712
            ],
            [
              105.81192475737602,
              21.02081048943772
            ],
            [
              105.81192546671828,
              21.020804516618707
            ],
            [
              105.8119261135333,
              21.0207990770026
            ],
            [
              105.81192690943531,
              21.020792390220358
            ],
            [
              105.81191512073536,
              21.020710893142198
            ],
            [
              105.81191318522524,
              21.02070769140574
            ],
            [
              105.81190909385747,
              21.020700922273996
            ],
            [
              105.81190701469305,
              21.02069748367422
            ],
            [
              105.81190173427366,
              21.020688750277852
            ],
            [
              105.8118977953167,
              21.020682236031806
            ],
            [
              105.81188963592056,
              21.020668743720584
            ],
            [
              105.81189770270777,
              21.020593350718908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6972",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020940468131737,
          "to_latitude": 21.020593350718908,
          "to_longitude": 105.81189770270777,
          "from_longitude": 105.81190930670101
        }
      },
      {
        "id": 6965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81145692269067,
            21.020101659706974,
            105.81146758285473,
            21.020179692071906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81146199558677,
              21.020179692071906
            ],
            [
              105.81146758285473,
              21.020131898203452
            ],
            [
              105.81145692269067,
              21.02011721379574
            ],
            [
              105.81145712451186,
              21.020114867149747
            ],
            [
              105.81145745284702,
              21.020109767764406
            ],
            [
              105.8114579746112,
              21.020101659706974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6973",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020179692071906,
          "to_latitude": 21.020101659706974,
          "to_longitude": 105.8114579746112,
          "from_longitude": 105.81146199558677
        }
      },
      {
        "id": 6966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81189056646122,
            21.020143555330993,
            105.81189667673345,
            21.020195822684396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81189056646122,
              21.020195822684396
            ],
            [
              105.8118919409648,
              21.020184067837846
            ],
            [
              105.81189548653809,
              21.020159680017965
            ],
            [
              105.8118960359654,
              21.02015224382945
            ],
            [
              105.81189667673345,
              21.020143555330993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6974",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020195822684396,
          "to_latitude": 21.020143555330993,
          "to_longitude": 105.81189667673345,
          "from_longitude": 105.81189056646122
        }
      },
      {
        "id": 6967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81151855331505,
            21.02053208888945,
            105.8116566692262,
            21.020550247555086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81151855331505,
              21.020535861311306
            ],
            [
              105.81164112011406,
              21.02054954480366
            ],
            [
              105.81164303271122,
              21.020549758774745
            ],
            [
              105.81165549770833,
              21.020550247555086
            ],
            [
              105.8116560162228,
              21.020538697311462
            ],
            [
              105.8116566692262,
              21.02053208888945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6975",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020535861311306,
          "to_latitude": 21.02053208888945,
          "to_longitude": 105.8116566692262,
          "from_longitude": 105.81151855331505
        }
      },
      {
        "id": 6968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81181878930109,
            21.019616156401558,
            105.81187312565042,
            21.01998298539759
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81181878930109,
              21.01998298539759
            ],
            [
              105.81181911943635,
              21.019980708590694
            ],
            [
              105.8118207914404,
              21.01996917722825
            ],
            [
              105.81184171795667,
              21.019823974679753
            ],
            [
              105.81184493435805,
              21.019820876611718
            ],
            [
              105.8118456482659,
              21.01982018946085
            ],
            [
              105.81184661224815,
              21.019810077738725
            ],
            [
              105.81184648321451,
              21.019809876031502
            ],
            [
              105.81184437016816,
              21.01980657307613
            ],
            [
              105.81184506201781,
              21.019802344473554
            ],
            [
              105.81185524254104,
              21.01974015650781
            ],
            [
              105.81186300196056,
              21.019686352117102
            ],
            [
              105.81187267134482,
              21.019619307222744
            ],
            [
              105.81187285862066,
              21.019618006583794
            ],
            [
              105.81187312565042,
              21.019616156401558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6976",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01998298539759,
          "to_latitude": 21.019616156401558,
          "to_longitude": 105.81187312565042,
          "from_longitude": 105.81181878930109
        }
      },
      {
        "id": 6969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81118540386332,
            21.024471521075252,
            105.81131028802,
            21.024529743604113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81118540386332,
              21.024529743604113
            ],
            [
              105.81122557333596,
              21.024509528350436
            ],
            [
              105.81129675396765,
              21.02447370822263
            ],
            [
              105.81131028802,
              21.024471521075252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.1_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9C.DODV.6977",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024529743604113,
          "to_latitude": 21.024471521075252,
          "to_longitude": 105.81131028802,
          "from_longitude": 105.81118540386332
        }
      },
      {
        "id": 6970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81110988248471,
            21.02137954569173,
            105.81111636421463,
            21.021422597348682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81111636421463,
              21.02137954569173
            ],
            [
              105.8111128215321,
              21.021403072706217
            ],
            [
              105.81111243890183,
              21.021405606278822
            ],
            [
              105.81111007190772,
              21.02142133644239
            ],
            [
              105.81110988248471,
              21.021422597348682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6978",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02137954569173,
          "to_latitude": 21.021422597348682,
          "to_longitude": 105.81110988248471,
          "from_longitude": 105.81111636421463
        }
      },
      {
        "id": 6971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81044388993664,
            21.019768372673788,
            105.81044977623836,
            21.019817682567766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81044977623836,
              21.019817682567766
            ],
            [
              105.81044969952745,
              21.01980473425331
            ],
            [
              105.81044460564927,
              21.019776918319078
            ],
            [
              105.81044388993664,
              21.019768372673788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6979",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019817682567766,
          "to_latitude": 21.019768372673788,
          "to_longitude": 105.81044388993664,
          "from_longitude": 105.81044977623836
        }
      },
      {
        "id": 6972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81204598901871,
            21.019572102932756,
            105.81212825545815,
            21.02015104660768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81204598901871,
              21.02015104660768
            ],
            [
              105.81204614192919,
              21.020149972842905
            ],
            [
              105.8120517498843,
              21.02011047501011
            ],
            [
              105.81205178416062,
              21.020110231878167
            ],
            [
              105.81209421214969,
              21.01981168410117
            ],
            [
              105.81212825545815,
              21.019572102932756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54g.1_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6980",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02015104660768,
          "to_latitude": 21.019572102932756,
          "to_longitude": 105.81212825545815,
          "from_longitude": 105.81204598901871
        }
      },
      {
        "id": 6973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8110267071025,
            21.021523537474707,
            105.81127559479431,
            21.021629737916317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110267071025,
              21.021527734988968
            ],
            [
              105.81105895397461,
              21.021523537474707
            ],
            [
              105.8112627587135,
              21.021541484543565
            ],
            [
              105.81127559479431,
              21.02156858428679
            ],
            [
              105.81127263184014,
              21.021629737916317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6981",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021527734988968,
          "to_latitude": 21.021629737916317,
          "to_longitude": 105.81127263184014,
          "from_longitude": 105.8110267071025
        }
      },
      {
        "id": 6974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809417103017,
            21.019746452027373,
            105.80947023980538,
            21.01983780640291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809417103017,
              21.019746452027373
            ],
            [
              105.80945633749161,
              21.019816073142508
            ],
            [
              105.80947006405552,
              21.019837503754516
            ],
            [
              105.80947023980538,
              21.01983780640291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6982",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019746452027373,
          "to_latitude": 21.01983780640291,
          "to_longitude": 105.80947023980538,
          "from_longitude": 105.809417103017
        }
      },
      {
        "id": 6975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8111078280936,
            21.021379007955435,
            105.81111434692444,
            21.02142231449954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81111434692444,
              21.021379007955435
            ],
            [
              105.81111076154058,
              21.021402821496782
            ],
            [
              105.81111038823174,
              21.021405299928023
            ],
            [
              105.81110798593882,
              21.02142126238949
            ],
            [
              105.8111078280936,
              21.02142231449954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6983",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021379007955435,
          "to_latitude": 21.02142231449954,
          "to_longitude": 105.8111078280936,
          "from_longitude": 105.81111434692444
        }
      },
      {
        "id": 6976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81143723163541,
            21.018586305135376,
            105.81146681820637,
            21.018601851728224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81143723163541,
              21.018601851728224
            ],
            [
              105.81144112611575,
              21.01859980450067
            ],
            [
              105.81145736688575,
              21.018591271007466
            ],
            [
              105.81146681820637,
              21.018586305135376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6984",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018601851728224,
          "to_latitude": 21.018586305135376,
          "to_longitude": 105.81146681820637,
          "from_longitude": 105.81143723163541
        }
      },
      {
        "id": 6977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81185978433771,
            21.01961814756543,
            105.81193842431482,
            21.01962742230626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81193842431482,
              21.01962742230626
            ],
            [
              105.81188261825933,
              21.01962058478306
            ],
            [
              105.81187267134482,
              21.019619307222744
            ],
            [
              105.81186817872702,
              21.019618730728222
            ],
            [
              105.81186282145227,
              21.01961835978023
            ],
            [
              105.81185978433771,
              21.01961814756543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6985",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01962742230626,
          "to_latitude": 21.01961814756543,
          "to_longitude": 105.81185978433771,
          "from_longitude": 105.81193842431482
        }
      },
      {
        "id": 6978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81121320240332,
            21.020918756532414,
            105.8112306441926,
            21.02106991255415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81121320240332,
              21.02106991255415
            ],
            [
              105.8112306441926,
              21.02093854337642
            ],
            [
              105.81122452856384,
              21.020929053423785
            ],
            [
              105.81122507719441,
              21.02092537557254
            ],
            [
              105.81122606300372,
              21.020918756532414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6986",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02106991255415,
          "to_latitude": 21.020918756532414,
          "to_longitude": 105.81122606300372,
          "from_longitude": 105.81121320240332
        }
      },
      {
        "id": 6979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80901006920489,
            21.019668292782814,
            105.80928464726945,
            21.019929794826417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80901006920489,
              21.019668292782814
            ],
            [
              105.80901397649546,
              21.019674849703048
            ],
            [
              105.809165921148,
              21.019929794826417
            ],
            [
              105.80928464726945,
              21.01986721524858
            ],
            [
              105.80926099962377,
              21.019826472022846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6987",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019668292782814,
          "to_latitude": 21.019826472022846,
          "to_longitude": 105.80926099962377,
          "from_longitude": 105.80901006920489
        }
      },
      {
        "id": 6980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81160253142644,
            21.020115522685497,
            105.81161636844897,
            21.020167185707994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81161331696298,
              21.020167185707994
            ],
            [
              105.81161636844897,
              21.020141079600787
            ],
            [
              105.81160485080524,
              21.02013041865881
            ],
            [
              105.81160253142644,
              21.020128272816866
            ],
            [
              105.81160313387598,
              21.020115522685497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6988",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020167185707994,
          "to_latitude": 21.020115522685497,
          "to_longitude": 105.81160313387598,
          "from_longitude": 105.81161331696298
        }
      },
      {
        "id": 6981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81174773907725,
            21.020691304358174,
            105.81191512073536,
            21.020710893142198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81191512073536,
              21.020710893142198
            ],
            [
              105.81174773907725,
              21.020691304358174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6989",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020710893142198,
          "to_latitude": 21.020691304358174,
          "to_longitude": 105.81174773907725,
          "from_longitude": 105.81191512073536
        }
      },
      {
        "id": 6982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81130328479637,
            21.021398554643625,
            105.81130999581973,
            21.021443132951994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81130999581973,
              21.021398554643625
            ],
            [
              105.81130627210064,
              21.02142328652761
            ],
            [
              105.81130601671158,
              21.02142497858843
            ],
            [
              105.81130359773016,
              21.02144105313191
            ],
            [
              105.81130328479637,
              21.021443132951994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6990",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021398554643625,
          "to_latitude": 21.021443132951994,
          "to_longitude": 105.81130328479637,
          "from_longitude": 105.81130999581973
        }
      },
      {
        "id": 6983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81073560185172,
            21.02056766361948,
            105.81074111223764,
            21.020577310024933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81073560185172,
              21.02056766361948
            ],
            [
              105.81073704113122,
              21.02057018579811
            ],
            [
              105.81073822301883,
              21.020572251266884
            ],
            [
              105.81073982058527,
              21.02057504817562
            ],
            [
              105.81074111223764,
              21.020577310024933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6991",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02056766361948,
          "to_latitude": 21.020577310024933,
          "to_longitude": 105.81074111223764,
          "from_longitude": 105.81073560185172
        }
      },
      {
        "id": 6984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81183121095015,
            21.021197300314725,
            105.81185059397667,
            21.02131862858702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81183121095015,
              21.02131862858702
            ],
            [
              105.81184526384595,
              21.021242642503182
            ],
            [
              105.81185059397667,
              21.021197300314725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6992",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02131862858702,
          "to_latitude": 21.021197300314725,
          "to_longitude": 105.81185059397667,
          "from_longitude": 105.81183121095015
        }
      },
      {
        "id": 6985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81004765009342,
            21.019823470160922,
            105.81006085968082,
            21.01983911258819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81006085968082,
              21.01983911258819
            ],
            [
              105.81005993184205,
              21.019837595067077
            ],
            [
              105.81005085618348,
              21.019827360452226
            ],
            [
              105.81004765009342,
              21.019823470160922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6993",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01983911258819,
          "to_latitude": 21.019823470160922,
          "to_longitude": 105.81004765009342,
          "from_longitude": 105.81006085968082
        }
      },
      {
        "id": 6986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81199447992708,
            21.019799381340547,
            105.81209421214969,
            21.01981168410117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81199447992708,
              21.019799381340547
            ],
            [
              105.81209421214969,
              21.01981168410117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54g.1_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6994",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019799381340547,
          "to_latitude": 21.01981168410117,
          "to_longitude": 105.81209421214969,
          "from_longitude": 105.81199447992708
        }
      },
      {
        "id": 6987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81129528305705,
            21.020341454274405,
            105.81131376302217,
            21.02037604105617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81129528305705,
              21.020341454274405
            ],
            [
              105.81129618781064,
              21.020343147124606
            ],
            [
              105.81130090403319,
              21.020351978071385
            ],
            [
              105.81130724508357,
              21.02036384243697
            ],
            [
              105.81131376302217,
              21.02037604105617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6995",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020341454274405,
          "to_latitude": 21.02037604105617,
          "to_longitude": 105.81131376302217,
          "from_longitude": 105.81129528305705
        }
      },
      {
        "id": 6988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81142933500664,
            21.020937930571954,
            105.81144660038971,
            21.02108977600563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81142933500664,
              21.02108977600563
            ],
            [
              105.8114403481013,
              21.021006829723284
            ],
            [
              105.81144522016486,
              21.02095112428446
            ],
            [
              105.81144530073936,
              21.02095019267204
            ],
            [
              105.81144660038971,
              21.020937930571954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6996",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02108977600563,
          "to_latitude": 21.020937930571954,
          "to_longitude": 105.81144660038971,
          "from_longitude": 105.81142933500664
        }
      },
      {
        "id": 6989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81186009305165,
            21.019616711765913,
            105.81193620291793,
            21.01962613858374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81193620291793,
              21.01962613858374
            ],
            [
              105.8118803835398,
              21.01961897234836
            ],
            [
              105.81187285862066,
              21.019618006583794
            ],
            [
              105.81186603449858,
              21.019617130506635
            ],
            [
              105.8118629483006,
              21.019616914009994
            ],
            [
              105.81186009305165,
              21.019616711765913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6997",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01962613858374,
          "to_latitude": 21.019616711765913,
          "to_longitude": 105.81186009305165,
          "from_longitude": 105.81193620291793
        }
      },
      {
        "id": 6990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81166549111636,
            21.020957268741945,
            105.81168401254735,
            21.021107005553173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81166757500006,
              21.021107005553173
            ],
            [
              105.81168401254735,
              21.020983184892003
            ],
            [
              105.81166549111636,
              21.020977756358622
            ],
            [
              105.81166602997669,
              21.0209758299118
            ],
            [
              105.81166662941365,
              21.020972121107274
            ],
            [
              105.8116690241825,
              21.020957268741945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.6998",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021107005553173,
          "to_latitude": 21.020957268741945,
          "to_longitude": 105.8116690241825,
          "from_longitude": 105.81166757500006
        }
      },
      {
        "id": 6991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81171507828911,
            21.019029002064357,
            105.81183132322182,
            21.019079577572775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81183132322182,
              21.019029002064357
            ],
            [
              105.81177266142123,
              21.0190564809237
            ],
            [
              105.81171507828911,
              21.019079577572775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.6999",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019029002064357,
          "to_latitude": 21.019079577572775,
          "to_longitude": 105.81171507828911,
          "from_longitude": 105.81183132322182
        }
      },
      {
        "id": 6992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81065706205474,
            21.020367163654353,
            105.81113754150486,
            21.020607608370618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81065706205474,
              21.020607608370618
            ],
            [
              105.81065935295159,
              21.020606444030207
            ],
            [
              105.81066365364633,
              21.020604255786107
            ],
            [
              105.81066945858373,
              21.02060130346763
            ],
            [
              105.81069819738815,
              21.02058668775139
            ],
            [
              105.81070195606068,
              21.020584775767798
            ],
            [
              105.81073560185172,
              21.02056766361948
            ],
            [
              105.81074270188523,
              21.020564052091803
            ],
            [
              105.81077782227032,
              21.02054959020398
            ],
            [
              105.81080055272493,
              21.02053624775828
            ],
            [
              105.81080349951115,
              21.020534517578447
            ],
            [
              105.81083411234563,
              21.020516546446782
            ],
            [
              105.81084150852205,
              21.02051273118033
            ],
            [
              105.81096530165972,
              21.020455064233666
            ],
            [
              105.8110002711513,
              21.020437968304126
            ],
            [
              105.81100399375546,
              21.020436147712047
            ],
            [
              105.81100871801384,
              21.020433838213837
            ],
            [
              105.81105263997262,
              21.020412364094387
            ],
            [
              105.81113754150486,
              21.020367163654353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7000",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020607608370618,
          "to_latitude": 21.020367163654353,
          "to_longitude": 105.81113754150486,
          "from_longitude": 105.81065706205474
        }
      },
      {
        "id": 6993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81044054684212,
            21.019769215055625,
            105.81044092189646,
            21.019817977015677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81044086712146,
              21.019817977015677
            ],
            [
              105.81044077070872,
              21.019804942988372
            ],
            [
              105.8104407670426,
              21.0198044426174
            ],
            [
              105.81044092189646,
              21.019777026192962
            ],
            [
              105.81044054684212,
              21.019769215055625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7001",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019817977015677,
          "to_latitude": 21.019769215055625,
          "to_longitude": 105.81044054684212,
          "from_longitude": 105.81044086712146
        }
      },
      {
        "id": 6994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118745896273,
            21.019489597718334,
            105.81193945110346,
            21.01949263591505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81193945110346,
              21.01949263591505
            ],
            [
              105.81188426771998,
              21.019489927686962
            ],
            [
              105.81187751582178,
              21.019489597718334
            ],
            [
              105.8118745896273,
              21.0194908608489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7002",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01949263591505,
          "to_latitude": 21.0194908608489,
          "to_longitude": 105.8118745896273,
          "from_longitude": 105.81193945110346
        }
      },
      {
        "id": 6995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81133177685534,
            21.0200975956075,
            105.81202055321103,
            21.020164302775516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81133177685534,
              21.0200975956075
            ],
            [
              105.81145381180635,
              21.020109414810378
            ],
            [
              105.81145613136869,
              21.02010963948751
            ],
            [
              105.81145745284702,
              21.020109767764406
            ],
            [
              105.81147298332527,
              21.020111271615313
            ],
            [
              105.8115728445809,
              21.020126943101623
            ],
            [
              105.81159864726301,
              21.02012974464331
            ],
            [
              105.81160485080524,
              21.02013041865881
            ],
            [
              105.81162736161815,
              21.020132862869552
            ],
            [
              105.81164451825488,
              21.020134267719442
            ],
            [
              105.8116869454846,
              21.020137740503944
            ],
            [
              105.81175129921826,
              21.02014038515576
            ],
            [
              105.81175376433205,
              21.020140486295197
            ],
            [
              105.81179001749082,
              21.020141976125878
            ],
            [
              105.81189520823801,
              21.020152163780043
            ],
            [
              105.8118960359654,
              21.02015224382945
            ],
            [
              105.81189986179412,
              21.020152613053288
            ],
            [
              105.81191207749801,
              21.02015379216856
            ],
            [
              105.81202055321103,
              21.020164302775516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7003",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0200975956075,
          "to_latitude": 21.020164302775516,
          "to_longitude": 105.81202055321103,
          "from_longitude": 105.81133177685534
        }
      },
      {
        "id": 6996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80941410173833,
            21.019749493027078,
            105.8094665733933,
            21.019839701662207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80941410173833,
              21.019749493027078
            ],
            [
              105.80945279850009,
              21.01981804998893
            ],
            [
              105.8094665733933,
              21.019839701662207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7004",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019749493027078,
          "to_latitude": 21.019839701662207,
          "to_longitude": 105.8094665733933,
          "from_longitude": 105.80941410173833
        }
      },
      {
        "id": 6997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81181031612434,
            21.01929380903981,
            105.81191016021266,
            21.01934074219687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81181031612434,
              21.01934074219687
            ],
            [
              105.81181155295009,
              21.01934016093796
            ],
            [
              105.81191016021266,
              21.01929380903981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7005",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01934074219687,
          "to_latitude": 21.01929380903981,
          "to_longitude": 105.81191016021266,
          "from_longitude": 105.81181031612434
        }
      },
      {
        "id": 6998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81113317718955,
            21.018643632767535,
            105.81118001600824,
            21.018719043041745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81118001600824,
              21.018719043041745
            ],
            [
              105.81114541894657,
              21.01866210231555
            ],
            [
              105.81114090708147,
              21.01865529361783
            ],
            [
              105.81113317718955,
              21.018643632767535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7006",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018719043041745,
          "to_latitude": 21.018643632767535,
          "to_longitude": 105.81113317718955,
          "from_longitude": 105.81118001600824
        }
      },
      {
        "id": 6999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81189478776302,
            21.020143944143932,
            105.81190075085621,
            21.020194967050042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81189478776302,
              21.020194967050042
            ],
            [
              105.81189571724745,
              21.02018701240113
            ],
            [
              105.81189906864076,
              21.020160340341313
            ],
            [
              105.81189986179412,
              21.020152613053288
            ],
            [
              105.81190075085621,
              21.020143944143932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7007",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020194967050042,
          "to_latitude": 21.020143944143932,
          "to_longitude": 105.81190075085621,
          "from_longitude": 105.81189478776302
        }
      },
      {
        "id": 7000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81117366587864,
            21.018979726016866,
            105.811428724786,
            21.019420000237634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81117366587864,
              21.018979726016866
            ],
            [
              105.81142111808107,
              21.019408361396238
            ],
            [
              105.81142243024723,
              21.01940979217295
            ],
            [
              105.811428724786,
              21.019420000237634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Cạnh 81g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7008",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018979726016866,
          "to_latitude": 21.019420000237634,
          "to_longitude": 105.811428724786,
          "from_longitude": 105.81117366587864
        }
      },
      {
        "id": 7001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80927830882783,
            21.019816941000695,
            105.80933201065616,
            21.01990926460585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80927830882783,
              21.019816941000695
            ],
            [
              105.8093175942388,
              21.01988584835577
            ],
            [
              105.80932991529285,
              21.019905663468283
            ],
            [
              105.80933201065616,
              21.01990926460585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7009",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019816941000695,
          "to_latitude": 21.01990926460585,
          "to_longitude": 105.80933201065616,
          "from_longitude": 105.80927830882783
        }
      },
      {
        "id": 7002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81210102736945,
            21.023332286886916,
            105.812111855542,
            21.023370648085216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.812111855542,
              21.023332286886916
            ],
            [
              105.81211076449313,
              21.02334060630656
            ],
            [
              105.81211017030996,
              21.023342442695725
            ],
            [
              105.81210102736945,
              21.023370648085216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7010",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023332286886916,
          "to_latitude": 21.023370648085216,
          "to_longitude": 105.81210102736945,
          "from_longitude": 105.812111855542
        }
      },
      {
        "id": 7003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409752714771,
            21.022370449068916,
            105.81489349018094,
            21.022456223988264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81489349018094,
              21.022456223988264
            ],
            [
              105.81409752714771,
              21.022370449068916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7011",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022456223988264,
          "to_latitude": 21.022370449068916,
          "to_longitude": 105.81409752714771,
          "from_longitude": 105.81489349018094
        }
      },
      {
        "id": 7004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81449730745291,
            21.02342439312492,
            105.81450205326988,
            21.02348344938767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81450205326988,
              21.02342439312492
            ],
            [
              105.81449730745291,
              21.023464532687694
            ],
            [
              105.81449801164676,
              21.02347020248523
            ],
            [
              105.81449939990239,
              21.02348137778933
            ],
            [
              105.81449891861693,
              21.02348344938767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7012",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02342439312492,
          "to_latitude": 21.02348344938767,
          "to_longitude": 105.81449891861693,
          "from_longitude": 105.81450205326988
        }
      },
      {
        "id": 7005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8143221978978,
            21.023706129385644,
            105.8143283655829,
            21.02375831486676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8143283655829,
              21.023706129385644
            ],
            [
              105.81432638079855,
              21.0237229199466
            ],
            [
              105.81432522475686,
              21.0237458186778
            ],
            [
              105.81432412392023,
              21.023750365374863
            ],
            [
              105.8143221978978,
              21.02375831486676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7013",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023706129385644,
          "to_latitude": 21.02375831486676,
          "to_longitude": 105.8143221978978,
          "from_longitude": 105.8143283655829
        }
      },
      {
        "id": 7006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81227733042826,
            21.02271541597404,
            105.81237957875052,
            21.022733308310002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81237957875052,
              21.022733308310002
            ],
            [
              105.81228812322776,
              21.022725056062097
            ],
            [
              105.8122820332101,
              21.022716058290065
            ],
            [
              105.81227983537433,
              21.022715758979405
            ],
            [
              105.81227733042826,
              21.02271541597404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7014",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022733308310002,
          "to_latitude": 21.02271541597404,
          "to_longitude": 105.81227733042826,
          "from_longitude": 105.81237957875052
        }
      },
      {
        "id": 7007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81374113427427,
            21.02349670895371,
            105.81375090427231,
            21.023537827250543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81375090427231,
              21.02349670895371
            ],
            [
              105.81375057652463,
              21.023509701640084
            ],
            [
              105.81375006816472,
              21.023511217881325
            ],
            [
              105.81374113427427,
              21.023537827250543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7015",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349670895371,
          "to_latitude": 21.023537827250543,
          "to_longitude": 105.81374113427427,
          "from_longitude": 105.81375090427231
        }
      },
      {
        "id": 7008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81481441845195,
            21.023371971160035,
            105.81482576064293,
            21.02343918944556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81482174820515,
              21.02343918944556
            ],
            [
              105.81482576064293,
              21.023400419069002
            ],
            [
              105.81481441845195,
              21.023390268747672
            ],
            [
              105.8148146289864,
              21.023383516116226
            ],
            [
              105.8148149866353,
              21.023371971160035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7016",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02343918944556,
          "to_latitude": 21.023371971160035,
          "to_longitude": 105.8148149866353,
          "from_longitude": 105.81482174820515
        }
      },
      {
        "id": 7009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81467346245702,
            21.021464039243163,
            105.81467994920756,
            21.021511998265485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81467346245702,
              21.021511998265485
            ],
            [
              105.81467365386241,
              21.021510576572098
            ],
            [
              105.81467634788825,
              21.02149065834428
            ],
            [
              105.81467690001838,
              21.021486577628263
            ],
            [
              105.81467994920756,
              21.021464039243163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7017",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021511998265485,
          "to_latitude": 21.021464039243163,
          "to_longitude": 105.81467994920756,
          "from_longitude": 105.81467346245702
        }
      },
      {
        "id": 7010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81278275047275,
            21.02196482831522,
            105.81279842239181,
            21.022052978737154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81279842239181,
              21.02196482831522
            ],
            [
              105.81279694676834,
              21.02197495422577
            ],
            [
              105.81279524296319,
              21.02197634882213
            ],
            [
              105.81279232284425,
              21.021978740071514
            ],
            [
              105.81278275047275,
              21.022052978737154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7018",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02196482831522,
          "to_latitude": 21.022052978737154,
          "to_longitude": 105.81278275047275,
          "from_longitude": 105.81279842239181
        }
      },
      {
        "id": 7011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81431737130646,
            21.023453474077233,
            105.81431857303959,
            21.023463177781558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81431737130646,
              21.023463177781558
            ],
            [
              105.81431760901553,
              21.02346124735011
            ],
            [
              105.81431797674496,
              21.02345828210099
            ],
            [
              105.81431810024252,
              21.02345728615331
            ],
            [
              105.81431857303959,
              21.023453474077233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7019",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023463177781558,
          "to_latitude": 21.023453474077233,
          "to_longitude": 105.81431857303959,
          "from_longitude": 105.81431737130646
        }
      },
      {
        "id": 7012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81208540631522,
            21.023330178119075,
            105.81209061299863,
            21.0233682920874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81209061299863,
              21.023330178119075
            ],
            [
              105.81208791176535,
              21.02333604573813
            ],
            [
              105.81208758176048,
              21.02334030061257
            ],
            [
              105.81208540631522,
              21.0233682920874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7020",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023330178119075,
          "to_latitude": 21.0233682920874,
          "to_longitude": 105.81208540631522,
          "from_longitude": 105.81209061299863
        }
      },
      {
        "id": 7013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81397648808834,
            21.02306342799791,
            105.81400643626414,
            21.023066537189834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81397648808834,
              21.02306342799791
            ],
            [
              105.81398414405844,
              21.0230642232299
            ],
            [
              105.8139980006379,
              21.02306565988367
            ],
            [
              105.81399914701261,
              21.023065779042536
            ],
            [
              105.81400643626414,
              21.023066537189834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7021",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02306342799791,
          "to_latitude": 21.023066537189834,
          "to_longitude": 105.81400643626414,
          "from_longitude": 105.81397648808834
        }
      },
      {
        "id": 7014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81380139147532,
            21.023934831579005,
            105.81387453317765,
            21.0239402133152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81380139147532,
              21.023934831579005
            ],
            [
              105.81383302758336,
              21.023937841493773
            ],
            [
              105.81383394194873,
              21.023937928344342
            ],
            [
              105.81385795417059,
              21.0239402133152
            ],
            [
              105.81386653320462,
              21.023940031358308
            ],
            [
              105.81387453317765,
              21.02393986030106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7022",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023934831579005,
          "to_latitude": 21.02393986030106,
          "to_longitude": 105.81387453317765,
          "from_longitude": 105.81380139147532
        }
      },
      {
        "id": 7015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81312463176695,
            21.02365829382451,
            105.81317579899378,
            21.023658795255532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81317579899378,
              21.023658795255532
            ],
            [
              105.813173643059,
              21.023658774853835
            ],
            [
              105.81316575622598,
              21.023658696949248
            ],
            [
              105.81312463176695,
              21.02365829382451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7023",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023658795255532,
          "to_latitude": 21.02365829382451,
          "to_longitude": 105.81312463176695,
          "from_longitude": 105.81317579899378
        }
      },
      {
        "id": 7016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81303568624016,
            21.024307106576615,
            105.81309267114128,
            21.024316313728963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81303568624016,
              21.024316313728963
            ],
            [
              105.81303584194724,
              21.024316288597454
            ],
            [
              105.81309267114128,
              21.024307106576615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_La Thành",
          "maTaiSan": "04.O9C.DODV.7024",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024316313728963,
          "to_latitude": 21.024307106576615,
          "to_longitude": 105.81309267114128,
          "from_longitude": 105.81303568624016
        }
      },
      {
        "id": 7017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81446712009756,
            21.023721147338023,
            105.81447323178529,
            21.023772829457222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81447323178529,
              21.023721147338023
            ],
            [
              105.81447282711522,
              21.023724572506847
            ],
            [
              105.81447247691034,
              21.023733065422416
            ],
            [
              105.81447092302577,
              21.02376081909865
            ],
            [
              105.81446967652032,
              21.02376475862226
            ],
            [
              105.81446712009756,
              21.023772829457222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7025",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023721147338023,
          "to_latitude": 21.023772829457222,
          "to_longitude": 105.81446712009756,
          "from_longitude": 105.81447323178529
        }
      },
      {
        "id": 7018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81321877459767,
            21.023681064313518,
            105.81360134846037,
            21.023863036190015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81321877459767,
              21.023863036190015
            ],
            [
              105.81322623758341,
              21.023780889913194
            ],
            [
              105.81323530643401,
              21.023681064313518
            ],
            [
              105.81323900066374,
              21.02368145219827
            ],
            [
              105.81324517827775,
              21.023682106402337
            ],
            [
              105.81325056753616,
              21.023682671343426
            ],
            [
              105.8133549190491,
              21.02369373545257
            ],
            [
              105.81336705289912,
              21.023695021426786
            ],
            [
              105.81337921850889,
              21.023696309957923
            ],
            [
              105.81345215732452,
              21.023704038678982
            ],
            [
              105.8134444070772,
              21.023777010450477
            ],
            [
              105.81345593176484,
              21.023790311351767
            ],
            [
              105.81349312422132,
              21.023793482626566
            ],
            [
              105.8135072018007,
              21.02379468251909
            ],
            [
              105.81360134846037,
              21.023802709861354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7026",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023863036190015,
          "to_latitude": 21.023802709861354,
          "to_longitude": 105.81360134846037,
          "from_longitude": 105.81321877459767
        }
      },
      {
        "id": 7019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81315155396355,
            21.021019464492117,
            105.81401593791587,
            21.021104589670504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81315155396355,
              21.021019464492117
            ],
            [
              105.81329741042052,
              21.021033275693075
            ],
            [
              105.81401593791587,
              21.021104589670504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7027",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021019464492117,
          "to_latitude": 21.021104589670504,
          "to_longitude": 105.81401593791587,
          "from_longitude": 105.81315155396355
        }
      },
      {
        "id": 7020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81446482540387,
            21.02346725779098,
            105.81446636155283,
            21.02347968181133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81446482540387,
              21.02347968181133
            ],
            [
              105.81446532576092,
              21.023475631151005
            ],
            [
              105.814465624328,
              21.023473216298196
            ],
            [
              105.81446636155283,
              21.02346725779098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7028",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02347968181133,
          "to_latitude": 21.02346725779098,
          "to_longitude": 105.81446636155283,
          "from_longitude": 105.81446482540387
        }
      },
      {
        "id": 7021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81411578059155,
            21.021345519044004,
            105.81493336871895,
            21.021422083383396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81411578059155,
              21.021366153468517
            ],
            [
              105.81414507042746,
              21.021345519044004
            ],
            [
              105.81421255310823,
              21.021352073198127
            ],
            [
              105.81421737900362,
              21.0213525414527
            ],
            [
              105.81427748323064,
              21.02135836948016
            ],
            [
              105.81428233511375,
              21.021358840318126
            ],
            [
              105.81441038029732,
              21.021371287945602
            ],
            [
              105.81441353436068,
              21.021371594392644
            ],
            [
              105.81447358860343,
              21.021377427105815
            ],
            [
              105.81448067634355,
              21.021378115748345
            ],
            [
              105.81448873330424,
              21.021378898201217
            ],
            [
              105.81459586750488,
              21.02138930336438
            ],
            [
              105.8145981755419,
              21.021389527149907
            ],
            [
              105.81463898394777,
              21.02139349106831
            ],
            [
              105.81466517509074,
              21.02139603466281
            ],
            [
              105.81466616356525,
              21.021396131089897
            ],
            [
              105.81478831544574,
              21.021407995172396
            ],
            [
              105.81480186435644,
              21.02140931220634
            ],
            [
              105.81485354887378,
              21.021414335844728
            ],
            [
              105.81485774338823,
              21.021414743879834
            ],
            [
              105.81493336871895,
              21.021422083383396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7029",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021366153468517,
          "to_latitude": 21.021422083383396,
          "to_longitude": 105.81493336871895,
          "from_longitude": 105.81411578059155
        }
      },
      {
        "id": 7022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81448189279904,
            21.023722484635496,
            105.81448802294582,
            21.023774309553367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81448802294582,
              21.023722484635496
            ],
            [
              105.81448635847549,
              21.02376352711924
            ],
            [
              105.81448521238501,
              21.023766294676435
            ],
            [
              105.81448189279904,
              21.023774309553367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7030",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023722484635496,
          "to_latitude": 21.023774309553367,
          "to_longitude": 105.81448189279904,
          "from_longitude": 105.81448802294582
        }
      },
      {
        "id": 7023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409163588178,
            21.02082997228332,
            105.81412805914876,
            21.021124811036998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81409163588178,
              21.021124811036998
            ],
            [
              105.81409170499772,
              21.021124252511612
            ],
            [
              105.81412805914876,
              21.02082997228332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7031",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021124811036998,
          "to_latitude": 21.02082997228332,
          "to_longitude": 105.81412805914876,
          "from_longitude": 105.81409163588178
        }
      },
      {
        "id": 7024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409020768908,
            21.022215819061316,
            105.81490985712563,
            21.02228731614583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81409020768908,
              21.022215819061316
            ],
            [
              105.81409916185922,
              21.022216002913407
            ],
            [
              105.81411921563956,
              21.02221641092775
            ],
            [
              105.81422605083881,
              21.022218592046663
            ],
            [
              105.81423070263811,
              21.022219059326982
            ],
            [
              105.81424977377364,
              21.022220976806754
            ],
            [
              105.81425039651823,
              21.0222210397611
            ],
            [
              105.81442295591171,
              21.022238381999724
            ],
            [
              105.81442316092645,
              21.022238402695503
            ],
            [
              105.81444717165368,
              21.022240815856332
            ],
            [
              105.81444804368773,
              21.022240903809834
            ],
            [
              105.81457927590438,
              21.022254092930986
            ],
            [
              105.81461881381952,
              21.022258066544886
            ],
            [
              105.81465140435056,
              21.02226134181113
            ],
            [
              105.81465199244045,
              21.022261400413843
            ],
            [
              105.8146785413091,
              21.02226406874023
            ],
            [
              105.81481469486734,
              21.02227775255858
            ],
            [
              105.81481791445677,
              21.022278075844948
            ],
            [
              105.81484158928002,
              21.02228045533307
            ],
            [
              105.81484453359258,
              21.022280751034447
            ],
            [
              105.81490985712563,
              21.02228731614583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7032",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022215819061316,
          "to_latitude": 21.02228731614583,
          "to_longitude": 105.81490985712563,
          "from_longitude": 105.81409020768908
        }
      },
      {
        "id": 7025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81543820584646,
            21.022847863352283,
            105.81545450585249,
            21.022942476860575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81545450585249,
              21.022847863352283
            ],
            [
              105.81545237165152,
              21.022861388571464
            ],
            [
              105.81544678596734,
              21.022883604028223
            ],
            [
              105.81544494114146,
              21.022895503857605
            ],
            [
              105.81544070319671,
              21.02292284860163
            ],
            [
              105.81543820584646,
              21.022942476860575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7033",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022847863352283,
          "to_latitude": 21.022942476860575,
          "to_longitude": 105.81543820584646,
          "from_longitude": 105.81545450585249
        }
      },
      {
        "id": 7026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81408374510322,
            21.02338698175628,
            105.81408498136999,
            21.023439046423853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81408374510322,
              21.02338698175628
            ],
            [
              105.81408458826802,
              21.02342799417321
            ],
            [
              105.81408475637508,
              21.023431670406698
            ],
            [
              105.81408498136999,
              21.023436610886034
            ],
            [
              105.81408451263684,
              21.023439046423853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7034",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02338698175628,
          "to_latitude": 21.023439046423853,
          "to_longitude": 105.81408451263684,
          "from_longitude": 105.81408374510322
        }
      },
      {
        "id": 7027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81396222513612,
            21.021443795181515,
            105.81400060127456,
            21.021732132780265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81396222513612,
              21.021732132780265
            ],
            [
              105.81399633202801,
              21.02147587205879
            ],
            [
              105.81400060127456,
              21.021443795181515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7035",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021732132780265,
          "to_latitude": 21.021443795181515,
          "to_longitude": 105.81400060127456,
          "from_longitude": 105.81396222513612
        }
      },
      {
        "id": 7028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81333199858138,
            21.02144812213195,
            105.81334382113832,
            21.021499107772073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81333436667815,
              21.02144812213195
            ],
            [
              105.81333199858138,
              21.02146200974092
            ],
            [
              105.81333330476552,
              21.021463572401387
            ],
            [
              105.81334382113832,
              21.021476170010118
            ],
            [
              105.81334111445383,
              21.021499107772073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7036",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02144812213195,
          "to_latitude": 21.021499107772073,
          "to_longitude": 105.81334111445383,
          "from_longitude": 105.81333436667815
        }
      },
      {
        "id": 7029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.812936778085,
            21.024288150363276,
            105.81302318148448,
            21.024305574772626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81302318148448,
              21.024288150363276
            ],
            [
              105.81302244539825,
              21.024288298398474
            ],
            [
              105.812936778085,
              21.024305574772626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_La Thành",
          "maTaiSan": "04.O9C.DODV.7037",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024288150363276,
          "to_latitude": 21.024305574772626,
          "to_longitude": 105.812936778085,
          "from_longitude": 105.81302318148448
        }
      },
      {
        "id": 7030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8148562956259,
            21.023091798252413,
            105.81486305646678,
            21.023157116805617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81486305646678,
              21.023091798252413
            ],
            [
              105.81486122792266,
              21.023112190140232
            ],
            [
              105.81486086509861,
              21.023116232916827
            ],
            [
              105.8148562956259,
              21.023157116805617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7038",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023091798252413,
          "to_latitude": 21.023157116805617,
          "to_longitude": 105.8148562956259,
          "from_longitude": 105.81486305646678
        }
      },
      {
        "id": 7031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81420843644342,
            21.023397336653584,
            105.8142144424759,
            21.023450952071215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8142144424759,
              21.023397336653584
            ],
            [
              105.81420986219733,
              21.023436087157855
            ],
            [
              105.81420930869339,
              21.02344328401249
            ],
            [
              105.81420881267441,
              21.02344972910689
            ],
            [
              105.81420843644342,
              21.023450952071215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7039",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023397336653584,
          "to_latitude": 21.023450952071215,
          "to_longitude": 105.81420843644342,
          "from_longitude": 105.8142144424759
        }
      },
      {
        "id": 7032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81282970991488,
            21.023797126702913,
            105.81314897609994,
            21.02386265058618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81283560530302,
              21.023801528165848
            ],
            [
              105.81282970991488,
              21.02385098094569
            ],
            [
              105.81295614640138,
              21.02386265058618
            ],
            [
              105.81295721267108,
              21.02384586623411
            ],
            [
              105.8130915823743,
              21.023850376808298
            ],
            [
              105.81309543112526,
              21.023797126702913
            ],
            [
              105.81311269346013,
              21.023799671662925
            ],
            [
              105.81314146178295,
              21.02380391477509
            ],
            [
              105.81314897609994,
              21.02380554620625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7040",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023801528165848,
          "to_latitude": 21.02380554620625,
          "to_longitude": 105.81314897609994,
          "from_longitude": 105.81283560530302
        }
      },
      {
        "id": 7033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81280629138934,
            21.021978269710615,
            105.81281250073815,
            21.022026407092728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81281250073815,
              21.021978269710615
            ],
            [
              105.81280629138934,
              21.022026407092728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7041",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021978269710615,
          "to_latitude": 21.022026407092728,
          "to_longitude": 105.81280629138934,
          "from_longitude": 105.81281250073815
        }
      },
      {
        "id": 7034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81535311361479,
            21.021559649159318,
            105.81535823329529,
            21.021609709780115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81535311361479,
              21.021609709780115
            ],
            [
              105.81535483043157,
              21.021600275470945
            ],
            [
              105.81535516386928,
              21.02159843851483
            ],
            [
              105.81535823329529,
              21.021559649159318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7042",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021609709780115,
          "to_latitude": 21.021559649159318,
          "to_longitude": 105.81535823329529,
          "from_longitude": 105.81535311361479
        }
      },
      {
        "id": 7035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8125219416612,
            21.021937239894093,
            105.81253438027878,
            21.02203058195791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81253438027878,
              21.021937239894093
            ],
            [
              105.81253353668774,
              21.021944708197477
            ],
            [
              105.81253263295476,
              21.021949244007914
            ],
            [
              105.81253206446446,
              21.0219520918908
            ],
            [
              105.8125219416612,
              21.02203058195791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7043",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021937239894093,
          "to_latitude": 21.02203058195791,
          "to_longitude": 105.8125219416612,
          "from_longitude": 105.81253438027878
        }
      },
      {
        "id": 7036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81523699795304,
            21.02123240271022,
            105.81524302904313,
            21.021287315645147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152421547752,
              21.02123240271022
            ],
            [
              105.81524272830472,
              21.021243054430364
            ],
            [
              105.81524302904313,
              21.021248650288417
            ],
            [
              105.81523699795304,
              21.021287315645147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7044",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02123240271022,
          "to_latitude": 21.021287315645147,
          "to_longitude": 105.81523699795304,
          "from_longitude": 105.8152421547752
        }
      },
      {
        "id": 7037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81341536151318,
            21.02082523085681,
            105.81342073357023,
            21.020869847991566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81342073357023,
              21.02082523085681
            ],
            [
              105.8134193599186,
              21.020841200167727
            ],
            [
              105.8134190114734,
              21.020845240164775
            ],
            [
              105.81341620837945,
              21.020862815024348
            ],
            [
              105.81341536151318,
              21.020869847991566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7045",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02082523085681,
          "to_latitude": 21.020869847991566,
          "to_longitude": 105.81341536151318,
          "from_longitude": 105.81342073357023
        }
      },
      {
        "id": 7038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8124303214538,
            21.024327322407547,
            105.81252498358434,
            21.024335533356037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8124303214538,
              21.024335533356037
            ],
            [
              105.812432619543,
              21.02433533360358
            ],
            [
              105.81252498358434,
              21.024327322407547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_La Thành",
          "maTaiSan": "04.O9C.DODV.7046",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024335533356037,
          "to_latitude": 21.024327322407547,
          "to_longitude": 105.81252498358434,
          "from_longitude": 105.8124303214538
        }
      },
      {
        "id": 7039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81434033170035,
            21.023708528087983,
            105.81434671995284,
            21.023760131140914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81434671995284,
              21.023708528087983
            ],
            [
              105.81434565444351,
              21.02371745346137
            ],
            [
              105.81434360240036,
              21.023748250688296
            ],
            [
              105.81434251940152,
              21.02375218491202
            ],
            [
              105.81434033170035,
              21.023760131140914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7047",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023708528087983,
          "to_latitude": 21.023760131140914,
          "to_longitude": 105.81434033170035,
          "from_longitude": 105.81434671995284
        }
      },
      {
        "id": 7040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81270153176668,
            21.02231166312796,
            105.81270695159667,
            21.022354434271012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81270695159667,
              21.02231166312796
            ],
            [
              105.81270616558348,
              21.02231768418597
            ],
            [
              105.81270551321643,
              21.022320320211573
            ],
            [
              105.81270524775015,
              21.02232139270795
            ],
            [
              105.81270153176668,
              21.022354434271012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7048",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02231166312796,
          "to_latitude": 21.022354434271012,
          "to_longitude": 105.81270153176668,
          "from_longitude": 105.81270695159667
        }
      },
      {
        "id": 7041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81328767538288,
            21.022378242532337,
            105.81369129874709,
            21.02242220505101
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81369129874709,
              21.02242220505101
            ],
            [
              105.81350010688412,
              21.022401380734106
            ],
            [
              105.81349874776019,
              21.022401232782773
            ],
            [
              105.81349565988977,
              21.022400896196245
            ],
            [
              105.81348847251277,
              21.022400114054836
            ],
            [
              105.81348557041314,
              21.02239979735384
            ],
            [
              105.8134812706868,
              21.02239932836934
            ],
            [
              105.81328767538288,
              21.022378242532337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7049",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02242220505101,
          "to_latitude": 21.022378242532337,
          "to_longitude": 105.81328767538288,
          "from_longitude": 105.81369129874709
        }
      },
      {
        "id": 7042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81223875384275,
            21.02296923738163,
            105.81235191427868,
            21.022978220542633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81235191427868,
              21.022978220542633
            ],
            [
              105.81226438932813,
              21.022970330674873
            ],
            [
              105.8122637723919,
              21.022970274911394
            ],
            [
              105.81225236625444,
              21.02296923738163
            ],
            [
              105.81224365362165,
              21.022969431635588
            ],
            [
              105.81223875384275,
              21.022969539938426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7050",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022978220542633,
          "to_latitude": 21.022969539938426,
          "to_longitude": 105.81223875384275,
          "from_longitude": 105.81235191427868
        }
      },
      {
        "id": 7043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8131846739285,
            21.0234558262027,
            105.81385491485686,
            21.023554782318197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81385491485686,
              21.023521722025656
            ],
            [
              105.81375006816472,
              21.023511217881325
            ],
            [
              105.81374692266576,
              21.02351090235001
            ],
            [
              105.81374554819402,
              21.023510764409895
            ],
            [
              105.81372975140833,
              21.02350918166444
            ],
            [
              105.81363634519153,
              21.023499826714602
            ],
            [
              105.81356086878559,
              21.023493702598156
            ],
            [
              105.81353481760688,
              21.02349113469045
            ],
            [
              105.81350351885051,
              21.02348804994225
            ],
            [
              105.81345695854841,
              21.023481854306272
            ],
            [
              105.8133538174571,
              21.023471516984475
            ],
            [
              105.81335194055562,
              21.02347132905671
            ],
            [
              105.81335035818228,
              21.02347117043015
            ],
            [
              105.81333518222431,
              21.023469649711238
            ],
            [
              105.81319721068104,
              21.0234558262027
            ],
            [
              105.8131846739285,
              21.023554782318197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7051",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023521722025656,
          "to_latitude": 21.023554782318197,
          "to_longitude": 105.8131846739285,
          "from_longitude": 105.81385491485686
        }
      },
      {
        "id": 7044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409618636533,
            21.02213294592564,
            105.8149785915767,
            21.022247839822313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81409618636533,
              21.02216301035344
            ],
            [
              105.81410382518378,
              21.02216419856739
            ],
            [
              105.81411734859495,
              21.02216629977745
            ],
            [
              105.81420705604489,
              21.022180243426543
            ],
            [
              105.81475338218208,
              21.022235133042706
            ],
            [
              105.8148798557495,
              21.022247839822313
            ],
            [
              105.81491981284088,
              21.022213534394037
            ],
            [
              105.8149633069666,
              21.022162739854153
            ],
            [
              105.8149785915767,
              21.02213294592564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7052",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02216301035344,
          "to_latitude": 21.02213294592564,
          "to_longitude": 105.8149785915767,
          "from_longitude": 105.81409618636533
        }
      },
      {
        "id": 7045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81257709680425,
            21.022306424316678,
            105.81327154873367,
            21.022377186996376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81257709680425,
              21.022306424316678
            ],
            [
              105.8126894617749,
              21.0223178750967
            ],
            [
              105.81270551321643,
              21.022320320211573
            ],
            [
              105.81272447321254,
              21.022323208460048
            ],
            [
              105.8127351716073,
              21.022324838245765
            ],
            [
              105.81291677490373,
              21.02234507519139
            ],
            [
              105.81293168215024,
              21.02234673633247
            ],
            [
              105.8131172741817,
              21.02236596496385
            ],
            [
              105.81312029651025,
              21.02236627838672
            ],
            [
              105.81312747116824,
              21.022367021765795
            ],
            [
              105.81327154873367,
              21.022377186996376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7053",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022306424316678,
          "to_latitude": 21.022377186996376,
          "to_longitude": 105.81327154873367,
          "from_longitude": 105.81257709680425
        }
      },
      {
        "id": 7046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81538147804517,
            21.0208068025783,
            105.81552431419553,
            21.021254391302463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81552431419553,
              21.021254391302463
            ],
            [
              105.8155211615177,
              21.021242665367424
            ],
            [
              105.81549727060518,
              21.021216619742003
            ],
            [
              105.81538147804517,
              21.0208068025783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7054",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021254391302463,
          "to_latitude": 21.0208068025783,
          "to_longitude": 105.81538147804517,
          "from_longitude": 105.81552431419553
        }
      },
      {
        "id": 7047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81535853561438,
            21.02156014418599,
            105.81536330849785,
            21.021611885856807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81536330849785,
              21.02156014418599
            ],
            [
              105.81536314770601,
              21.021561887282118
            ],
            [
              105.8153628733788,
              21.021564864730873
            ],
            [
              105.81536207120092,
              21.02157355400932
            ],
            [
              105.81535974939075,
              21.021597865564704
            ],
            [
              105.81535951458635,
              21.02160068114632
            ],
            [
              105.81535897166125,
              21.021607158984907
            ],
            [
              105.81535853561438,
              21.021611885856807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7055",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02156014418599,
          "to_latitude": 21.021611885856807,
          "to_longitude": 105.81535853561438,
          "from_longitude": 105.81536330849785
        }
      },
      {
        "id": 7048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8119945052056,
            21.022470496346205,
            105.81238148407104,
            21.022490046661
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81238148407104,
              21.022488038587984
            ],
            [
              105.81229922318458,
              21.022479933908937
            ],
            [
              105.81229785423248,
              21.022490046661
            ],
            [
              105.81205421615583,
              21.022470496346205
            ],
            [
              105.81205240275821,
              21.022479722442686
            ],
            [
              105.8119945052056,
              21.022475543237274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7056",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022488038587984,
          "to_latitude": 21.022475543237274,
          "to_longitude": 105.8119945052056,
          "from_longitude": 105.81238148407104
        }
      },
      {
        "id": 7049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81350948168071,
            21.02115900433954,
            105.81351078521318,
            21.02116803743046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81351078521318,
              21.02115900433954
            ],
            [
              105.81351023543954,
              21.02116281587969
            ],
            [
              105.8135099925371,
              21.02116449975441
            ],
            [
              105.8135095780388,
              21.02116735774246
            ],
            [
              105.81350948168071,
              21.02116803743046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7057",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02115900433954,
          "to_latitude": 21.02116803743046,
          "to_longitude": 105.81350948168071,
          "from_longitude": 105.81351078521318
        }
      },
      {
        "id": 7050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81404782600087,
            21.023678227464533,
            105.81404976065718,
            21.023730833452444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81404976065718,
              21.023678227464533
            ],
            [
              105.81404972781357,
              21.02372249749784
            ],
            [
              105.81404956432988,
              21.02372321634599
            ],
            [
              105.81404782600087,
              21.023730833452444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7058",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023678227464533,
          "to_latitude": 21.023730833452444,
          "to_longitude": 105.81404782600087,
          "from_longitude": 105.81404976065718
        }
      },
      {
        "id": 7051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8130883968444,
            21.021996694286187,
            105.81310340758507,
            21.022084511892253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81310340758507,
              21.021996694286187
            ],
            [
              105.81310197619788,
              21.02200592669526
            ],
            [
              105.81310179957289,
              21.022007061090196
            ],
            [
              105.81309714198012,
              21.022016700019588
            ],
            [
              105.8130883968444,
              21.022084511892253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7059",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021996694286187,
          "to_latitude": 21.022084511892253,
          "to_longitude": 105.8130883968444,
          "from_longitude": 105.81310340758507
        }
      },
      {
        "id": 7052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81437757696618,
            21.02341288252046,
            105.81438244325459,
            21.023470180046136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81438244325459,
              21.02341288252046
            ],
            [
              105.81437757696618,
              21.023454049627
            ],
            [
              105.81437829191049,
              21.023459042856516
            ],
            [
              105.81437948886027,
              21.023467410041313
            ],
            [
              105.81437896326706,
              21.023470180046136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7060",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02341288252046,
          "to_latitude": 21.023470180046136,
          "to_longitude": 105.81437896326706,
          "from_longitude": 105.81438244325459
        }
      },
      {
        "id": 7053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81266331216811,
            21.021962508813274,
            105.81269465148813,
            21.022171440522232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81266331216811,
              21.022171440522232
            ],
            [
              105.81269465148813,
              21.021962508813274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7061",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022171440522232,
          "to_latitude": 21.021962508813274,
          "to_longitude": 105.81269465148813,
          "from_longitude": 105.81266331216811
        }
      },
      {
        "id": 7054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81480415856196,
            21.021477989853114,
            105.81481639761824,
            21.021552512425767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81481339648094,
              21.021477989853114
            ],
            [
              105.81481639761824,
              21.021525745363164
            ],
            [
              105.81481573880322,
              21.021527187360334
            ],
            [
              105.81480415856196,
              21.021552512425767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7062",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021477989853114,
          "to_latitude": 21.021552512425767,
          "to_longitude": 105.81480415856196,
          "from_longitude": 105.81481339648094
        }
      },
      {
        "id": 7055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81356751368948,
            21.02084057132797,
            105.81356935324517,
            21.02085860854959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81356935324517,
              21.02084057132797
            ],
            [
              105.813568875752,
              21.02084387490873
            ],
            [
              105.8135677344231,
              21.020856207618063
            ],
            [
              105.81356751368948,
              21.02085860854959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7063",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02084057132797,
          "to_latitude": 21.02085860854959,
          "to_longitude": 105.81356751368948,
          "from_longitude": 105.81356935324517
        }
      },
      {
        "id": 7056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81396904956799,
            21.02312342072894,
            105.81402231795049,
            21.023131076253428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81402231795049,
              21.023131076253428
            ],
            [
              105.81399862654128,
              21.023127436732143
            ],
            [
              105.81397664794683,
              21.023124061784927
            ],
            [
              105.81396904956799,
              21.02312342072894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7064",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023131076253428,
          "to_latitude": 21.02312342072894,
          "to_longitude": 105.81396904956799,
          "from_longitude": 105.81402231795049
        }
      },
      {
        "id": 7057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81205730246761,
            21.021826467599833,
            105.81210005866981,
            21.021829490695463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81210005866981,
              21.021829490695463
            ],
            [
              105.81209824082153,
              21.02182936750365
            ],
            [
              105.81206063462633,
              21.021826796724028
            ],
            [
              105.81205730246761,
              21.021826467599833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7065",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021829490695463,
          "to_latitude": 21.021826467599833,
          "to_longitude": 105.81205730246761,
          "from_longitude": 105.81210005866981
        }
      },
      {
        "id": 7058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81251983253607,
            21.020402780802332,
            105.81252828780646,
            21.020455417050847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81252828780646,
              21.020402780802332
            ],
            [
              105.81252535400944,
              21.020420396920393
            ],
            [
              105.81252406301851,
              21.020427671361492
            ],
            [
              105.81252131388864,
              21.020443151366756
            ],
            [
              105.81251983253607,
              21.020455417050847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7066",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020402780802332,
          "to_latitude": 21.020455417050847,
          "to_longitude": 105.81251983253607,
          "from_longitude": 105.81252828780646
        }
      },
      {
        "id": 7059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81435242594797,
            21.023410391645402,
            105.81436089172651,
            21.023467197300768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81436089172651,
              21.023410391645402
            ],
            [
              105.81435651286961,
              21.023447431378976
            ],
            [
              105.81435456105207,
              21.023456829771295
            ],
            [
              105.81435277326625,
              21.0234654334415
            ],
            [
              105.81435242594797,
              21.023467197300768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7067",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023410391645402,
          "to_latitude": 21.023467197300768,
          "to_longitude": 105.81435242594797,
          "from_longitude": 105.81436089172651
        }
      },
      {
        "id": 7060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81422806268252,
            21.023399091122926,
            105.81423133877874,
            21.023452890458735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81423133877874,
              21.023399091122926
            ],
            [
              105.81422806268252,
              21.02342680697947
            ],
            [
              105.8142297634346,
              21.02344203266768
            ],
            [
              105.81422947549204,
              21.02344516463176
            ],
            [
              105.81422890778383,
              21.023451340004446
            ],
            [
              105.81422861125272,
              21.023452890458735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7068",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023399091122926,
          "to_latitude": 21.023452890458735,
          "to_longitude": 105.81422861125272,
          "from_longitude": 105.81423133877874
        }
      },
      {
        "id": 7061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8123086683014,
            21.0222708375756,
            105.81231606219734,
            21.02231130677777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81231606219734,
              21.0222708375756
            ],
            [
              105.81231550543933,
              21.022275319340633
            ],
            [
              105.81231362915847,
              21.022282523413175
            ],
            [
              105.81231059655978,
              21.02229416424306
            ],
            [
              105.8123086683014,
              21.02231130677777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7069",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0222708375756,
          "to_latitude": 21.02231130677777,
          "to_longitude": 105.8123086683014,
          "from_longitude": 105.81231606219734
        }
      },
      {
        "id": 7062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81229197927149,
            21.021621019004183,
            105.81230386001992,
            21.0218454230585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81229197927149,
              21.0218454230585
            ],
            [
              105.81230386001992,
              21.021621019004183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7070",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0218454230585,
          "to_latitude": 21.021621019004183,
          "to_longitude": 105.81230386001992,
          "from_longitude": 105.81229197927149
        }
      },
      {
        "id": 7063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81342918328635,
            21.020496992067862,
            105.81343760141775,
            21.02055172473114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81343518057426,
              21.020496992067862
            ],
            [
              105.81343760141775,
              21.02051092090669
            ],
            [
              105.81343546808074,
              21.02052126493637
            ],
            [
              105.81342918328635,
              21.02055172473114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7071",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020496992067862,
          "to_latitude": 21.02055172473114,
          "to_longitude": 105.81342918328635,
          "from_longitude": 105.81343518057426
        }
      },
      {
        "id": 7064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81452252982527,
            21.0234268426093,
            105.81452696288504,
            21.02348610889821
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81452696288504,
              21.0234268426093
            ],
            [
              105.81452252982527,
              21.02346434505786
            ],
            [
              105.81452282436149,
              21.02347252146611
            ],
            [
              105.81452322121048,
              21.02348359403389
            ],
            [
              105.81452297417314,
              21.02348610889821
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7072",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0234268426093,
          "to_latitude": 21.02348610889821,
          "to_longitude": 105.81452297417314,
          "from_longitude": 105.81452696288504
        }
      },
      {
        "id": 7065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81545238548375,
            21.022849194953448,
            105.81546839552034,
            21.022943351846116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81546839552034,
              21.022849194953448
            ],
            [
              105.81546710336619,
              21.022863837943554
            ],
            [
              105.81545949715023,
              21.022897130329586
            ],
            [
              105.81545672055849,
              21.022909280304194
            ],
            [
              105.81545238548375,
              21.022943351846116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7073",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022849194953448,
          "to_latitude": 21.022943351846116,
          "to_longitude": 105.81545238548375,
          "from_longitude": 105.81546839552034
        }
      },
      {
        "id": 7066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81221004460515,
            21.02251867427828,
            105.81229770340947,
            21.02314363506304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81221004460515,
              21.02314363506304
            ],
            [
              105.8122134732083,
              21.023120029998
            ],
            [
              105.81222446790767,
              21.02299467995362
            ],
            [
              105.81223776862723,
              21.022865806242475
            ],
            [
              105.81227507268136,
              21.022695889039706
            ],
            [
              105.8122785772899,
              21.022671756124783
            ],
            [
              105.81228550802716,
              21.02261628471937
            ],
            [
              105.81229770340947,
              21.02251867427828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7074",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02314363506304,
          "to_latitude": 21.02251867427828,
          "to_longitude": 105.81229770340947,
          "from_longitude": 105.81221004460515
        }
      },
      {
        "id": 7067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81316237008151,
            21.02080830228521,
            105.8140215546849,
            21.020905015445663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81316237008151,
              21.02080830228521
            ],
            [
              105.81328871645063,
              21.020826885488287
            ],
            [
              105.8134193599186,
              21.020841200167727
            ],
            [
              105.81342216092932,
              21.02084150741867
            ],
            [
              105.81347595281713,
              21.020847402051963
            ],
            [
              105.81356751368948,
              21.02085860854959
            ],
            [
              105.81358720201645,
              21.020861018125693
            ],
            [
              105.81378883166028,
              21.02088032110748
            ],
            [
              105.81379250350085,
              21.020880672960242
            ],
            [
              105.81384776491691,
              21.020885963410926
            ],
            [
              105.8140215546849,
              21.020905015445663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7075",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02080830228521,
          "to_latitude": 21.020905015445663,
          "to_longitude": 105.8140215546849,
          "from_longitude": 105.81316237008151
        }
      },
      {
        "id": 7068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81186733849597,
            21.023114000801232,
            105.8119545328304,
            21.02312436518981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119545328304,
              21.023114000801232
            ],
            [
              105.8118863323244,
              21.023123891554306
            ],
            [
              105.81188268110543,
              21.02312398214499
            ],
            [
              105.81186733849597,
              21.02312436518981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7076",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023114000801232,
          "to_latitude": 21.02312436518981,
          "to_longitude": 105.81186733849597,
          "from_longitude": 105.8119545328304
        }
      },
      {
        "id": 7069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81322381510827,
            21.02366061874823,
            105.81324517827775,
            21.023881419861997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81324515215427,
              21.02366061874823
            ],
            [
              105.81324510386452,
              21.023661115754184
            ],
            [
              105.81324346977135,
              21.023678025661525
            ],
            [
              105.81324517827775,
              21.023682106402337
            ],
            [
              105.81324270465895,
              21.023685946107857
            ],
            [
              105.81322381510827,
              21.023881419861997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7077",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02366061874823,
          "to_latitude": 21.023881419861997,
          "to_longitude": 105.81322381510827,
          "from_longitude": 105.81324515215427
        }
      },
      {
        "id": 7070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81413268763197,
            21.02202584224094,
            105.81497127459006,
            21.022097519380623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81413268763197,
              21.02202584224094
            ],
            [
              105.81421223323072,
              21.02203440523012
            ],
            [
              105.81446939299639,
              21.022058140111277
            ],
            [
              105.81497127459006,
              21.022097519380623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7078",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02202584224094,
          "to_latitude": 21.022097519380623,
          "to_longitude": 105.81497127459006,
          "from_longitude": 105.81413268763197
        }
      },
      {
        "id": 7071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81344612705237,
            21.022255083926353,
            105.81400518171037,
            21.022310765007525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81344612705237,
              21.022255083926353
            ],
            [
              105.81345297334033,
              21.022255766539562
            ],
            [
              105.81346048311812,
              21.022256575140787
            ],
            [
              105.81346617368304,
              21.02225718740977
            ],
            [
              105.81350669393612,
              21.022261547682337
            ],
            [
              105.81360346248093,
              21.022272855678587
            ],
            [
              105.81360783373516,
              21.02227108341065
            ],
            [
              105.81361576364493,
              21.02227184483389
            ],
            [
              105.81361973648663,
              21.02227447680207
            ],
            [
              105.81372613297862,
              21.022284618669584
            ],
            [
              105.81375191507752,
              21.022287098421927
            ],
            [
              105.81375831271797,
              21.02228771362075
            ],
            [
              105.813775349672,
              21.022289351853207
            ],
            [
              105.81379811904534,
              21.02229133749584
            ],
            [
              105.81382028689634,
              21.022293270916705
            ],
            [
              105.81388552870227,
              21.02229896248959
            ],
            [
              105.81388821198009,
              21.02229919623075
            ],
            [
              105.81389346140715,
              21.0222997067251
            ],
            [
              105.8139222293041,
              21.022302503648262
            ],
            [
              105.81400518171037,
              21.022310765007525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7079",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022255083926353,
          "to_latitude": 21.022310765007525,
          "to_longitude": 105.81400518171037,
          "from_longitude": 105.81344612705237
        }
      },
      {
        "id": 7072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81417207813045,
            21.02369114461831,
            105.8141782394134,
            21.023743278580902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8141782394134,
              21.02369114461831
            ],
            [
              105.81417642460619,
              21.023731799433495
            ],
            [
              105.81417498408045,
              21.02373561885151
            ],
            [
              105.81417207813045,
              21.023743278580902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7080",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02369114461831,
          "to_latitude": 21.023743278580902,
          "to_longitude": 105.81417207813045,
          "from_longitude": 105.8141782394134
        }
      },
      {
        "id": 7073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8136005758576,
            21.023687002226783,
            105.81365113088648,
            21.024078174393235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8136005758576,
              21.024078174393235
            ],
            [
              105.81363982486228,
              21.023712046807642
            ],
            [
              105.81365051474185,
              21.023693120023214
            ],
            [
              105.81365113088648,
              21.023687002226783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7081",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024078174393235,
          "to_latitude": 21.023687002226783,
          "to_longitude": 105.81365113088648,
          "from_longitude": 105.8136005758576
        }
      },
      {
        "id": 7074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337921850889,
            21.023667663132386,
            105.81338263209096,
            21.023696309957923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81337921850889,
              21.023696309957923
            ],
            [
              105.81338190612408,
              21.023673754356572
            ],
            [
              105.81338240850506,
              21.02366953930163
            ],
            [
              105.81338263209096,
              21.023667663132386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7082",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023696309957923,
          "to_latitude": 21.023667663132386,
          "to_longitude": 105.81338263209096,
          "from_longitude": 105.81337921850889
        }
      },
      {
        "id": 7075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81334988896602,
            21.02345580160093,
            105.81335554459555,
            21.023498523301953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81335554459555,
              21.02345580160093
            ],
            [
              105.81335421736863,
              21.0234689833257
            ],
            [
              105.8133538174571,
              21.023471516984475
            ],
            [
              105.81335146053767,
              21.023486405313804
            ],
            [
              105.81334988896602,
              21.023498523301953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7083",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02345580160093,
          "to_latitude": 21.023498523301953,
          "to_longitude": 105.81334988896602,
          "from_longitude": 105.81335554459555
        }
      },
      {
        "id": 7076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81484253475894,
            21.02226763578761,
            105.81484612270194,
            21.022297244507584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81484253475894,
              21.022297244507584
            ],
            [
              105.81484453359258,
              21.022280751034447
            ],
            [
              105.81484464037933,
              21.022279867166613
            ],
            [
              105.81484546121607,
              21.022273189288054
            ],
            [
              105.81484612270194,
              21.02226763578761
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7084",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022297244507584,
          "to_latitude": 21.02226763578761,
          "to_longitude": 105.81484612270194,
          "from_longitude": 105.81484253475894
        }
      },
      {
        "id": 7077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81293975285242,
            21.02198142904243,
            105.81295730085951,
            21.02206969863693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81295730085951,
              21.02198142904243
            ],
            [
              105.81295589468425,
              21.021990345200365
            ],
            [
              105.8129556128534,
              21.021992125002534
            ],
            [
              105.81294923381076,
              21.021996179370795
            ],
            [
              105.81293975285242,
              21.02206969863693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7085",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02198142904243,
          "to_latitude": 21.02206969863693,
          "to_longitude": 105.81293975285242,
          "from_longitude": 105.81295730085951
        }
      },
      {
        "id": 7078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81389482857517,
            21.0237171589874,
            105.81393223889809,
            21.024034885650238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81389482857517,
              21.024034885650238
            ],
            [
              105.81391466346275,
              21.02381594690051
            ],
            [
              105.81392347387494,
              21.023817098803505
            ],
            [
              105.81393223889809,
              21.02372926136268
            ],
            [
              105.81391095144225,
              21.023727930718213
            ],
            [
              105.81391129604229,
              21.0237171589874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7086",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024034885650238,
          "to_latitude": 21.0237171589874,
          "to_longitude": 105.81391129604229,
          "from_longitude": 105.81389482857517
        }
      },
      {
        "id": 7079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81512288266235,
            21.021558449575156,
            105.8156249887093,
            21.02160878564206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8156249887093,
              21.02160878564206
            ],
            [
              105.81539427954334,
              21.021603692043556
            ],
            [
              105.81537862655435,
              21.02160233636977
            ],
            [
              105.81535951458635,
              21.02160068114632
            ],
            [
              105.81535483043157,
              21.021600275470945
            ],
            [
              105.81532682463332,
              21.021597849973112
            ],
            [
              105.81530009131592,
              21.021591669074954
            ],
            [
              105.81512288266235,
              21.021558449575156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7087",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02160878564206,
          "to_latitude": 21.021558449575156,
          "to_longitude": 105.81512288266235,
          "from_longitude": 105.8156249887093
        }
      },
      {
        "id": 7080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8154823860683,
            21.02285118285368,
            105.8154891390632,
            21.022899674267755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8154823860683,
              21.022899674267755
            ],
            [
              105.8154843842298,
              21.022866266059797
            ],
            [
              105.8154891390632,
              21.02285118285368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7088",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022899674267755,
          "to_latitude": 21.02285118285368,
          "to_longitude": 105.8154891390632,
          "from_longitude": 105.8154823860683
        }
      },
      {
        "id": 7081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81280106261353,
            21.021965254140763,
            105.81280249580453,
            21.02197699662601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81280106261353,
              21.02197699662601
            ],
            [
              105.81280126795481,
              21.021975302996193
            ],
            [
              105.81280139786236,
              21.021974245599075
            ],
            [
              105.81280181143076,
              21.021970858325652
            ],
            [
              105.81280249580453,
              21.021965254140763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7089",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02197699662601,
          "to_latitude": 21.021965254140763,
          "to_longitude": 105.81280249580453,
          "from_longitude": 105.81280106261353
        }
      },
      {
        "id": 7082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81378202551252,
            21.02233307315176,
            105.81401119478137,
            21.02234756984211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81378202551252,
              21.02233307315176
            ],
            [
              105.81381943751596,
              21.022337058929477
            ],
            [
              105.81388973180594,
              21.022344546328334
            ],
            [
              105.81389079005153,
              21.022336582927842
            ],
            [
              105.81401119478137,
              21.02234756984211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7090",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02233307315176,
          "to_latitude": 21.02234756984211,
          "to_longitude": 105.81401119478137,
          "from_longitude": 105.81378202551252
        }
      },
      {
        "id": 7083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81477855717276,
            21.02062345408559,
            105.81478577833646,
            21.020688496663016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81478577833646,
              21.02062345408559
            ],
            [
              105.81478147455395,
              21.02066312728832
            ],
            [
              105.81478111814623,
              21.02066640319496
            ],
            [
              105.81477855717276,
              21.020688496663016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7091",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02062345408559,
          "to_latitude": 21.020688496663016,
          "to_longitude": 105.81477855717276,
          "from_longitude": 105.81478577833646
        }
      },
      {
        "id": 7084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81331832562972,
            21.02144793959933,
            105.81333249464129,
            21.02149688280225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81333249464129,
              21.02144793959933
            ],
            [
              105.8133304130247,
              21.02146232442003
            ],
            [
              105.81332896153324,
              21.021463590459927
            ],
            [
              105.81332150396307,
              21.021470094132543
            ],
            [
              105.81331832562972,
              21.02149688280225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7092",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02144793959933,
          "to_latitude": 21.02149688280225,
          "to_longitude": 105.81331832562972,
          "from_longitude": 105.81333249464129
        }
      },
      {
        "id": 7085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81398621868733,
            21.022981515102625,
            105.81404275338815,
            21.022986386563712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81404275338815,
              21.022981515102625
            ],
            [
              105.8140170987324,
              21.02298339556952
            ],
            [
              105.81400643357281,
              21.02298417732414
            ],
            [
              105.81399568765991,
              21.022986386563712
            ],
            [
              105.81398938239799,
              21.022985427706228
            ],
            [
              105.81398621868733,
              21.02298494516914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7093",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022981515102625,
          "to_latitude": 21.02298494516914,
          "to_longitude": 105.81398621868733,
          "from_longitude": 105.81404275338815
        }
      },
      {
        "id": 7086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81523722849069,
            21.023125394615903,
            105.81524511953054,
            21.023176023092162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81524511953054,
              21.023125394615903
            ],
            [
              105.81524465311624,
              21.023134445380514
            ],
            [
              105.81524359822039,
              21.02314035844533
            ],
            [
              105.81523722849069,
              21.023176023092162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7094",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023125394615903,
          "to_latitude": 21.023176023092162,
          "to_longitude": 105.81523722849069,
          "from_longitude": 105.81524511953054
        }
      },
      {
        "id": 7087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81345811600907,
            21.022185653301676,
            105.81346906884662,
            21.022273304299176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81346906884662,
              21.022185653301676
            ],
            [
              105.81346048311812,
              21.022256575140787
            ],
            [
              105.81345948683999,
              21.0222647938563
            ],
            [
              105.81345826812208,
              21.022272085117812
            ],
            [
              105.81345811600907,
              21.022273304299176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7095",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022185653301676,
          "to_latitude": 21.022273304299176,
          "to_longitude": 105.81345811600907,
          "from_longitude": 105.81346906884662
        }
      },
      {
        "id": 7088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81465057730202,
            21.02224756578031,
            105.81465366904652,
            21.022273075081802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81465057730202,
              21.022273075081802
            ],
            [
              105.81465199244045,
              21.022261400413843
            ],
            [
              105.81465250613446,
              21.022257150075536
            ],
            [
              105.8146527722551,
              21.02225496118445
            ],
            [
              105.81465366904652,
              21.02224756578031
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7096",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022273075081802,
          "to_latitude": 21.02224756578031,
          "to_longitude": 105.81465366904652,
          "from_longitude": 105.81465057730202
        }
      },
      {
        "id": 7089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81480597765251,
            21.023371767318025,
            105.814812852796,
            21.023437477513045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81480597765251,
              21.023437477513045
            ],
            [
              105.81481005137519,
              21.023398123358405
            ],
            [
              105.81481268642415,
              21.02339018672278
            ],
            [
              105.81481274924307,
              21.02338333634726
            ],
            [
              105.814812852796,
              21.023371767318025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7097",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023437477513045,
          "to_latitude": 21.023371767318025,
          "to_longitude": 105.814812852796,
          "from_longitude": 105.81480597765251
        }
      },
      {
        "id": 7090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81528694995788,
            21.020977018117872,
            105.81531447185257,
            21.021218212368233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81528694995788,
              21.021218212368233
            ],
            [
              105.81528736959265,
              21.021214536028275
            ],
            [
              105.81531447185257,
              21.020977018117872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7098",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021218212368233,
          "to_latitude": 21.020977018117872,
          "to_longitude": 105.81531447185257,
          "from_longitude": 105.81528694995788
        }
      },
      {
        "id": 7091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81250428051075,
            21.021934263487477,
            105.81250588662472,
            21.021945875828916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81250588662472,
              21.021934263487477
            ],
            [
              105.81250550710092,
              21.021937011112556
            ],
            [
              105.81250521452124,
              21.021939116127434
            ],
            [
              105.81250444343311,
              21.02194469724176
            ],
            [
              105.81250428051075,
              21.021945875828916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7099",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021934263487477,
          "to_latitude": 21.021945875828916,
          "to_longitude": 105.81250428051075,
          "from_longitude": 105.81250588662472
        }
      },
      {
        "id": 7092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8144725963191,
            21.0213092150639,
            105.81449750603561,
            21.02144456714242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8144802549616,
              21.0213092150639
            ],
            [
              105.8144725963191,
              21.021367242549026
            ],
            [
              105.81448067634355,
              21.021378115748345
            ],
            [
              105.81449750603561,
              21.021400766682568
            ],
            [
              105.81449368055762,
              21.02144456714242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7100",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0213092150639,
          "to_latitude": 21.02144456714242,
          "to_longitude": 105.81449368055762,
          "from_longitude": 105.8144802549616
        }
      },
      {
        "id": 7093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81238030939649,
            21.02192216073141,
            105.81239005817486,
            21.022013769291224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81239005817486,
              21.02192216073141
            ],
            [
              105.8123877884774,
              21.021932033012046
            ],
            [
              105.81238683639839,
              21.021936168922903
            ],
            [
              105.81238910770541,
              21.021945592287686
            ],
            [
              105.81238030939649,
              21.022013769291224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7101",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02192216073141,
          "to_latitude": 21.022013769291224,
          "to_longitude": 105.81238030939649,
          "from_longitude": 105.81239005817486
        }
      },
      {
        "id": 7094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81220828492164,
            21.020702243439985,
            105.8122128007716,
            21.02088333689424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81220828492164,
              21.02088333689424
            ],
            [
              105.81220915997706,
              21.020726981267735
            ],
            [
              105.81221076690622,
              21.020714984148217
            ],
            [
              105.8122128007716,
              21.020702243439985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7102",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02088333689424,
          "to_latitude": 21.020702243439985,
          "to_longitude": 105.8122128007716,
          "from_longitude": 105.81220828492164
        }
      },
      {
        "id": 7095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81524182341342,
            21.021233030024277,
            105.81524865152426,
            21.02128841161566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81524865152426,
              21.021233030024277
            ],
            [
              105.81524755134727,
              21.021243528991967
            ],
            [
              105.81524675562913,
              21.021251107269798
            ],
            [
              105.81524344264687,
              21.02127606757659
            ],
            [
              105.81524182341342,
              21.02128841161566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7103",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021233030024277,
          "to_latitude": 21.02128841161566,
          "to_longitude": 105.81524182341342,
          "from_longitude": 105.81524865152426
        }
      },
      {
        "id": 7096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81396940717815,
            21.023120539528854,
            105.81402896062721,
            21.023125964710395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81402896062721,
              21.023125964710395
            ],
            [
              105.81399917844519,
              21.023123132922365
            ],
            [
              105.81397763225688,
              21.023121085456356
            ],
            [
              105.81396940717815,
              21.023120539528854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7104",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023125964710395,
          "to_latitude": 21.023120539528854,
          "to_longitude": 105.81396940717815,
          "from_longitude": 105.81402896062721
        }
      },
      {
        "id": 7097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81325417770948,
            21.021694831469066,
            105.81327411893041,
            21.02173946406391
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81327411893041,
              21.021694831469066
            ],
            [
              105.81327094651711,
              21.021716506034778
            ],
            [
              105.81327071431785,
              21.021718922375527
            ],
            [
              105.8132702471559,
              21.02172377765093
            ],
            [
              105.81325546432902,
              21.02172801853504
            ],
            [
              105.81325417770948,
              21.02173946406391
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7105",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021694831469066,
          "to_latitude": 21.02173946406391,
          "to_longitude": 105.81325417770948,
          "from_longitude": 105.81327411893041
        }
      },
      {
        "id": 7098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81310299989208,
            21.02361672677715,
            105.81312814175173,
            21.024079289225895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81312814175173,
              21.02361672677715
            ],
            [
              105.81312526361893,
              21.023650811177667
            ],
            [
              105.81312463176695,
              21.02365829382451
            ],
            [
              105.81311269346013,
              21.023799671662925
            ],
            [
              105.8131083645132,
              21.023850939673743
            ],
            [
              105.81310801922068,
              21.02385503113808
            ],
            [
              105.81310775797373,
              21.02385812323113
            ],
            [
              105.81310773900672,
              21.02385835093509
            ],
            [
              105.81310299989208,
              21.02396862242742
            ],
            [
              105.8131181617781,
              21.024079289225895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7106",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02361672677715,
          "to_latitude": 21.024079289225895,
          "to_longitude": 105.8131181617781,
          "from_longitude": 105.81312814175173
        }
      },
      {
        "id": 7099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81158159325258,
            21.024133766566244,
            105.81167691127037,
            21.02465880366333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81159099588537,
              21.02465880366333
            ],
            [
              105.81158159325258,
              21.02462413930535
            ],
            [
              105.81158778620808,
              21.024551916580815
            ],
            [
              105.81161966154708,
              21.024395095131396
            ],
            [
              105.81162218592385,
              21.024382673641025
            ],
            [
              105.81166377003002,
              21.024184872479363
            ],
            [
              105.81167691127037,
              21.024133766566244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.2_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7107",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02465880366333,
          "to_latitude": 21.024133766566244,
          "to_longitude": 105.81167691127037,
          "from_longitude": 105.81159099588537
        }
      },
      {
        "id": 7100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8150277188476,
            21.023108102771612,
            105.81504846632437,
            21.02316946086513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81504846632437,
              21.023108102771612
            ],
            [
              105.81504630137528,
              21.023126950851196
            ],
            [
              105.81504552701799,
              21.023133683604062
            ],
            [
              105.81503103753678,
              21.023137397560376
            ],
            [
              105.8150277188476,
              21.02316946086513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7108",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023108102771612,
          "to_latitude": 21.02316946086513,
          "to_longitude": 105.8150277188476,
          "from_longitude": 105.81504846632437
        }
      },
      {
        "id": 7101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81291677490373,
            21.022332480086845,
            105.81291914717148,
            21.02234507519139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81291677490373,
              21.02234507519139
            ],
            [
              105.81291731610715,
              21.02234163130772
            ],
            [
              105.81291781273286,
              21.022338482089314
            ],
            [
              105.81291914717148,
              21.022332480086845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7109",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02234507519139,
          "to_latitude": 21.022332480086845,
          "to_longitude": 105.81291914717148,
          "from_longitude": 105.81291677490373
        }
      },
      {
        "id": 7102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8146546585424,
            21.02362905337441,
            105.81479513170909,
            21.023641849383168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81479513170909,
              21.023641849383168
            ],
            [
              105.81466264799298,
              21.023629781945186
            ],
            [
              105.8146546585424,
              21.02362905337441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7110",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023641849383168,
          "to_latitude": 21.02362905337441,
          "to_longitude": 105.8146546585424,
          "from_longitude": 105.81479513170909
        }
      },
      {
        "id": 7103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81406115005534,
            21.02338403456692,
            105.81406411123731,
            21.023436801728202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81406411123731,
              21.02338403456692
            ],
            [
              105.81406193416818,
              21.02340244553293
            ],
            [
              105.8140617050245,
              21.023429519060233
            ],
            [
              105.8140616672181,
              21.02343389537723
            ],
            [
              105.81406115005534,
              21.023436801728202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7111",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02338403456692,
          "to_latitude": 21.023436801728202,
          "to_longitude": 105.81406115005534,
          "from_longitude": 105.81406411123731
        }
      },
      {
        "id": 7104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81244525200773,
            21.02161219166461,
            105.81245157602567,
            21.021669545218387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81244525200773,
              21.021669545218387
            ],
            [
              105.81244636058264,
              21.021659494224647
            ],
            [
              105.81244757453581,
              21.02163444824433
            ],
            [
              105.81244778381927,
              21.02163010813875
            ],
            [
              105.81245157602567,
              21.02161219166461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7112",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021669545218387,
          "to_latitude": 21.02161219166461,
          "to_longitude": 105.81245157602567,
          "from_longitude": 105.81244525200773
        }
      },
      {
        "id": 7105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118302474184,
            21.02325696625033,
            105.81253870396061,
            21.02331577035152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8118302474184,
              21.02325696625033
            ],
            [
              105.81183397969316,
              21.023257276306865
            ],
            [
              105.81188261194134,
              21.023259375395604
            ],
            [
              105.81253870396061,
              21.02331577035152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22'd_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7113",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02325696625033,
          "to_latitude": 21.02331577035152,
          "to_longitude": 105.81253870396061,
          "from_longitude": 105.8118302474184
        }
      },
      {
        "id": 7106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81484676238964,
            21.02134633312402,
            105.8148642199448,
            21.02148147724372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8148642199448,
              21.02134633312402
            ],
            [
              105.81486219274416,
              21.02136203008814
            ],
            [
              105.81485354887378,
              21.021414335844728
            ],
            [
              105.81484889300702,
              21.021442501809815
            ],
            [
              105.81484676238964,
              21.02148147724372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7114",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02134633312402,
          "to_latitude": 21.02148147724372,
          "to_longitude": 105.81484676238964,
          "from_longitude": 105.8148642199448
        }
      },
      {
        "id": 7107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81498914373174,
            21.021478464757543,
            105.81550819541265,
            21.02152450642766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81498914373174,
              21.021478464757543
            ],
            [
              105.8153050204511,
              21.021512576032013
            ],
            [
              105.81550819541265,
              21.02152450642766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7115",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021478464757543,
          "to_latitude": 21.02152450642766,
          "to_longitude": 105.81550819541265,
          "from_longitude": 105.81498914373174
        }
      },
      {
        "id": 7108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81222932095038,
            21.02273568356334,
            105.81240945608808,
            21.023230297114893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81240945608808,
              21.022746796209574
            ],
            [
              105.81229604022913,
              21.02273568356334
            ],
            [
              105.8122811243526,
              21.022846260182195
            ],
            [
              105.81227772183863,
              21.022871481901692
            ],
            [
              105.81226730904827,
              21.022948682623433
            ],
            [
              105.8122653224881,
              21.0229634111078
            ],
            [
              105.81226438932813,
              21.022970330674873
            ],
            [
              105.81226379466369,
              21.02297474126552
            ],
            [
              105.81223967003142,
              21.023153582637583
            ],
            [
              105.81224215666707,
              21.02315604921879
            ],
            [
              105.81224153186812,
              21.023161822923658
            ],
            [
              105.81224101701997,
              21.023166583585322
            ],
            [
              105.81223763161731,
              21.02316868440204
            ],
            [
              105.81222932095038,
              21.023230297114893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7116",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022746796209574,
          "to_latitude": 21.023230297114893,
          "to_longitude": 105.81222932095038,
          "from_longitude": 105.81240945608808
        }
      },
      {
        "id": 7109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81189745117663,
            21.022954975551034,
            105.81196533865145,
            21.022976944193857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81196533865145,
              21.022968121304704
            ],
            [
              105.81193314059753,
              21.022976944193857
            ],
            [
              105.81191175037056,
              21.02295625761734
            ],
            [
              105.81191112566573,
              21.022955655436938
            ],
            [
              105.81189745117663,
              21.022954975551034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7117",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022968121304704,
          "to_latitude": 21.022954975551034,
          "to_longitude": 105.81189745117663,
          "from_longitude": 105.81196533865145
        }
      },
      {
        "id": 7110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8122187818533,
            21.02315934833731,
            105.81235840557333,
            21.023268314191217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122187818533,
              21.02315934833731
            ],
            [
              105.81222328084766,
              21.023159838982274
            ],
            [
              105.81224153186812,
              21.023161822923658
            ],
            [
              105.81224312489731,
              21.023161995961424
            ],
            [
              105.81224056070803,
              21.02317611210585
            ],
            [
              105.81223332937437,
              21.023230795521677
            ],
            [
              105.81223013095688,
              21.023254982972535
            ],
            [
              105.81234652792686,
              21.023268314191217
            ],
            [
              105.81235005943705,
              21.023241867078866
            ],
            [
              105.81235840557333,
              21.023179352771027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7118",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02315934833731,
          "to_latitude": 21.023179352771027,
          "to_longitude": 105.81235840557333,
          "from_longitude": 105.8122187818533
        }
      },
      {
        "id": 7111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8122192018893,
            21.023155156009118,
            105.8123505289982,
            21.02325676301316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122192018893,
              21.023155198710327
            ],
            [
              105.81222353461949,
              21.023155186148294
            ],
            [
              105.81223306103277,
              21.023155156009118
            ],
            [
              105.81224215666707,
              21.02315604921879
            ],
            [
              105.8122511233297,
              21.023156930398716
            ],
            [
              105.8122412249365,
              21.023231778462506
            ],
            [
              105.81223944507224,
              21.023245240537317
            ],
            [
              105.81234005656833,
              21.02325676301316
            ],
            [
              105.81234223649457,
              21.023240438505095
            ],
            [
              105.8123505289982,
              21.023178322777056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7119",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023155198710327,
          "to_latitude": 21.023178322777056,
          "to_longitude": 105.8123505289982,
          "from_longitude": 105.8122192018893
        }
      },
      {
        "id": 7112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81324036690937,
            21.020501432628954,
            105.81408331724488,
            21.02058711886484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81324036690937,
              21.020501432628954
            ],
            [
              105.81343454889233,
              21.020521171783507
            ],
            [
              105.81343546808074,
              21.02052126493637
            ],
            [
              105.81344039898117,
              21.020521766131104
            ],
            [
              105.8134567769253,
              21.020523431036924
            ],
            [
              105.81364052061356,
              21.02054210890349
            ],
            [
              105.8136537531006,
              21.020543453738686
            ],
            [
              105.81382832609992,
              21.020561198879392
            ],
            [
              105.8138440505118,
              21.02056279732596
            ],
            [
              105.81384737018378,
              21.020563134604643
            ],
            [
              105.81384996990275,
              21.020563398553612
            ],
            [
              105.81408331724488,
              21.02058711886484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7120",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020501432628954,
          "to_latitude": 21.02058711886484,
          "to_longitude": 105.81408331724488,
          "from_longitude": 105.81324036690937
        }
      },
      {
        "id": 7113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81520750407759,
            21.022869055706035,
            105.81575121285114,
            21.022922940252993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81520750407759,
              21.022869055706035
            ],
            [
              105.81530140273986,
              21.022879514797495
            ],
            [
              105.815302178572,
              21.022879601401193
            ],
            [
              105.81531832278222,
              21.022881398207883
            ],
            [
              105.81532020075778,
              21.022881607786395
            ],
            [
              105.81544494114146,
              21.022895503857605
            ],
            [
              105.81544639654601,
              21.02289566578322
            ],
            [
              105.81545949715023,
              21.022897130329586
            ],
            [
              105.81546049245225,
              21.02289724207388
            ],
            [
              105.8154823860683,
              21.022899674267755
            ],
            [
              105.81559195213991,
              21.02290915665648
            ],
            [
              105.81560149569792,
              21.022909983409292
            ],
            [
              105.81560598836558,
              21.022910371930347
            ],
            [
              105.81560863603889,
              21.022910601298562
            ],
            [
              105.81575121285114,
              21.022922940252993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7121",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022869055706035,
          "to_latitude": 21.022922940252993,
          "to_longitude": 105.81575121285114,
          "from_longitude": 105.81520750407759
        }
      },
      {
        "id": 7114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81448631604938,
            21.02260730668648,
            105.81449601678372,
            21.022723111650247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81449601678372,
              21.02260730668648
            ],
            [
              105.81448778587443,
              21.022705563035046
            ],
            [
              105.81448957575743,
              21.022709213437302
            ],
            [
              105.81448718869962,
              21.022712686938476
            ],
            [
              105.81448662046762,
              21.022719471993074
            ],
            [
              105.81448631604938,
              21.022723111650247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7122",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02260730668648,
          "to_latitude": 21.022723111650247,
          "to_longitude": 105.81448631604938,
          "from_longitude": 105.81449601678372
        }
      },
      {
        "id": 7115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81443226441532,
            21.02143908976354,
            105.81444128555847,
            21.02151352999294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81444128555847,
              21.02143908976354
            ],
            [
              105.81443403327577,
              21.021481963661383
            ],
            [
              105.81443378518877,
              21.02148641053452
            ],
            [
              105.81443226441532,
              21.02151352999294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7123",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02143908976354,
          "to_latitude": 21.02151352999294,
          "to_longitude": 105.81443226441532,
          "from_longitude": 105.81444128555847
        }
      },
      {
        "id": 7116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81458976646164,
            21.021389527149907,
            105.8145981755419,
            21.02145461243838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8145981755419,
              21.021389527149907
            ],
            [
              105.8145962257853,
              21.021404610449295
            ],
            [
              105.81459249078144,
              21.02143353974181
            ],
            [
              105.81459207534581,
              21.02143675270472
            ],
            [
              105.81458976646164,
              21.02145461243838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7124",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021389527149907,
          "to_latitude": 21.02145461243838,
          "to_longitude": 105.81458976646164,
          "from_longitude": 105.8145981755419
        }
      },
      {
        "id": 7117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81205824197912,
            21.02181841083885,
            105.8121354613723,
            21.02182609713603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8121354613723,
              21.02182609713603
            ],
            [
              105.81210404085611,
              21.021822132053497
            ],
            [
              105.8121005348427,
              21.02182185163132
            ],
            [
              105.81206191776614,
              21.021818777161222
            ],
            [
              105.81205824197912,
              21.02181841083885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7125",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02182609713603,
          "to_latitude": 21.02181841083885,
          "to_longitude": 105.81205824197912,
          "from_longitude": 105.8121354613723
        }
      },
      {
        "id": 7118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81246760353437,
            21.021614216275232,
            105.81247397801904,
            21.021672018974815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81246760353437,
              21.021672018974815
            ],
            [
              105.81247139315859,
              21.021637647510374
            ],
            [
              105.81247151053357,
              21.021636584753928
            ],
            [
              105.81247158045622,
              21.021633155767557
            ],
            [
              105.81247227985692,
              21.021629612677323
            ],
            [
              105.81247359106267,
              21.021617722647125
            ],
            [
              105.81247397801904,
              21.021614216275232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7126",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021672018974815,
          "to_latitude": 21.021614216275232,
          "to_longitude": 105.81247397801904,
          "from_longitude": 105.81246760353437
        }
      },
      {
        "id": 7119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81228530230862,
            21.021919855618595,
            105.81264947828907,
            21.021963129294924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81228530230862,
              21.021919855618595
            ],
            [
              105.81237932721606,
              21.021931027487547
            ],
            [
              105.8123857727906,
              21.021931793348678
            ],
            [
              105.8123877884774,
              21.021932033012046
            ],
            [
              105.81239437555746,
              21.02193281626156
            ],
            [
              105.812472443447,
              21.021942092547633
            ],
            [
              105.81250428051075,
              21.021945875828916
            ],
            [
              105.81252194135125,
              21.02194797456399
            ],
            [
              105.81253263295476,
              21.021949244007914
            ],
            [
              105.81253699160972,
              21.021949761509653
            ],
            [
              105.81254544613431,
              21.021950766155207
            ],
            [
              105.81264947828907,
              21.021963129294924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7127",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021919855618595,
          "to_latitude": 21.021963129294924,
          "to_longitude": 105.81264947828907,
          "from_longitude": 105.81228530230862
        }
      },
      {
        "id": 7120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81205638778913,
            21.021834313790162,
            105.81213333095263,
            21.02184197250359
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81213333095263,
              21.02184197250359
            ],
            [
              105.8121025905434,
              21.02183676223539
            ],
            [
              105.81209961434391,
              21.02183661567094
            ],
            [
              105.81206018003677,
              21.021834691298498
            ],
            [
              105.81205638778913,
              21.021834313790162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7128",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02184197250359,
          "to_latitude": 21.021834313790162,
          "to_longitude": 105.81205638778913,
          "from_longitude": 105.81213333095263
        }
      },
      {
        "id": 7121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8154979286517,
            21.02194701298148,
            105.8155019037463,
            21.021983591966368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8155019037463,
              21.02194701298148
            ],
            [
              105.815501490457,
              21.02195079406551
            ],
            [
              105.8155002434525,
              21.021962063287138
            ],
            [
              105.81549949339461,
              21.02196883747418
            ],
            [
              105.81549812374847,
              21.021981789092763
            ],
            [
              105.8154979286517,
              21.021983591966368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7129",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02194701298148,
          "to_latitude": 21.021983591966368,
          "to_longitude": 105.8154979286517,
          "from_longitude": 105.8155019037463
        }
      },
      {
        "id": 7122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81308067165202,
            21.020800502180304,
            105.81309885002676,
            21.020953618426738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81308067165202,
              21.020953618426738
            ],
            [
              105.81309885002676,
              21.020800502180304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7130",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020953618426738,
          "to_latitude": 21.020800502180304,
          "to_longitude": 105.81309885002676,
          "from_longitude": 105.81308067165202
        }
      },
      {
        "id": 7123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81201786668478,
            21.024053524797996,
            105.8120518979317,
            21.02425932618589
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81201786668478,
              21.024053524797996
            ],
            [
              105.81201894379711,
              21.024060037345187
            ],
            [
              105.81204006949812,
              21.02418779736685
            ],
            [
              105.8120518979317,
              21.02425932618589
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7131",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024053524797996,
          "to_latitude": 21.02425932618589,
          "to_longitude": 105.8120518979317,
          "from_longitude": 105.81201786668478
        }
      },
      {
        "id": 7124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81354571978521,
            21.02172353309376,
            105.81355312938399,
            21.021789411110483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81355312938399,
              21.02172353309376
            ],
            [
              105.8135512783249,
              21.02174512547224
            ],
            [
              105.81355082097342,
              21.02174877308603
            ],
            [
              105.81354571978521,
              21.021789411110483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7132",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02172353309376,
          "to_latitude": 21.021789411110483,
          "to_longitude": 105.81354571978521,
          "from_longitude": 105.81355312938399
        }
      },
      {
        "id": 7125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81237202955623,
            21.0219218636015,
            105.81238721487313,
            21.022013757353644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81238721487313,
              21.0219218636015
            ],
            [
              105.8123857727906,
              21.021931793348678
            ],
            [
              105.81238513378484,
              21.021936186087316
            ],
            [
              105.81238088485802,
              21.02194509052883
            ],
            [
              105.81237202955623,
              21.022013757353644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7133",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0219218636015,
          "to_latitude": 21.022013757353644,
          "to_longitude": 105.81237202955623,
          "from_longitude": 105.81238721487313
        }
      },
      {
        "id": 7126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81321514531028,
            21.024412550852734,
            105.81322432264527,
            21.02444782315716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81321514531028,
              21.024412550852734
            ],
            [
              105.81322072284193,
              21.02443398757323
            ],
            [
              105.81322360153213,
              21.02444505640717
            ],
            [
              105.81322415660341,
              21.02444718446618
            ],
            [
              105.81322432264527,
              21.02444782315716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_La Thành",
          "maTaiSan": "04.O9C.DODV.7134",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024412550852734,
          "to_latitude": 21.02444782315716,
          "to_longitude": 105.81322432264527,
          "from_longitude": 105.81321514531028
        }
      },
      {
        "id": 7127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81195228780932,
            21.02270867931161,
            105.81202186161293,
            21.022723307104965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81195228780932,
              21.022722343102284
            ],
            [
              105.81197342401899,
              21.022723307104965
            ],
            [
              105.81202186161293,
              21.02270867931161
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7135",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022722343102284,
          "to_latitude": 21.02270867931161,
          "to_longitude": 105.81202186161293,
          "from_longitude": 105.81195228780932
        }
      },
      {
        "id": 7128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81278896017136,
            21.02196501834578,
            105.81280023868047,
            21.02205353074179
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81280023868047,
              21.02196501834578
            ],
            [
              105.81280016760124,
              21.021965570557526
            ],
            [
              105.81279975031462,
              21.021968804300037
            ],
            [
              105.81279904584049,
              21.021975314518258
            ],
            [
              105.81279886123379,
              21.02197675153235
            ],
            [
              105.81278896017136,
              21.02205353074179
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7136",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02196501834578,
          "to_latitude": 21.02205353074179,
          "to_longitude": 105.81278896017136,
          "from_longitude": 105.81280023868047
        }
      },
      {
        "id": 7129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8130798613865,
            21.021995427205052,
            105.81309128455896,
            21.02208401798446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81309128455896,
              21.021995427205052
            ],
            [
              105.81309077037747,
              21.021999418316
            ],
            [
              105.81309005130966,
              21.022004654150727
            ],
            [
              105.8130899022972,
              21.022005736026472
            ],
            [
              105.8130798613865,
              21.02208401798446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7137",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021995427205052,
          "to_latitude": 21.02208401798446,
          "to_longitude": 105.8130798613865,
          "from_longitude": 105.81309128455896
        }
      },
      {
        "id": 7130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81448127246338,
            21.0226463648423,
            105.8144866071759,
            21.022708255133676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81448127246338,
              21.022708255133676
            ],
            [
              105.8144866071759,
              21.0226463648423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7138",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022708255133676,
          "to_latitude": 21.0226463648423,
          "to_longitude": 105.8144866071759,
          "from_longitude": 105.81448127246338
        }
      },
      {
        "id": 7131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81478377724363,
            21.02062402099977,
            105.81479093470213,
            21.02068846862425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81479093470213,
              21.02062402099977
            ],
            [
              105.81478439578214,
              21.020663429424857
            ],
            [
              105.81478377724363,
              21.020667149168567
            ],
            [
              105.81478441062403,
              21.02068846862425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7139",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02062402099977,
          "to_latitude": 21.02068846862425,
          "to_longitude": 105.81478441062403,
          "from_longitude": 105.81479093470213
        }
      },
      {
        "id": 7132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81341856701466,
            21.020825579595417,
            105.81342411391394,
            21.02087165898869
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81342411391394,
              21.020825579595417
            ],
            [
              105.8134240771165,
              21.02082588957848
            ],
            [
              105.81342216092932,
              21.02084150741867
            ],
            [
              105.81342172328756,
              21.0208450646155
            ],
            [
              105.81341950780089,
              21.02086385150623
            ],
            [
              105.81341856701466,
              21.02087165898869
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7140",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020825579595417,
          "to_latitude": 21.02087165898869,
          "to_longitude": 105.81341856701466,
          "from_longitude": 105.81342411391394
        }
      },
      {
        "id": 7133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81382264504603,
            21.020539875277787,
            105.81384800198431,
            21.020590865473654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81384800198431,
              21.020539875277787
            ],
            [
              105.81384505920944,
              21.020557935845016
            ],
            [
              105.8138440505118,
              21.02056279732596
            ],
            [
              105.81384351426311,
              21.020565376800004
            ],
            [
              105.81382490664787,
              21.020572077454727
            ],
            [
              105.81382264504603,
              21.020590865473654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7141",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020539875277787,
          "to_latitude": 21.020590865473654,
          "to_longitude": 105.81382264504603,
          "from_longitude": 105.81384800198431
        }
      },
      {
        "id": 7134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81465413719448,
            21.02132811131908,
            105.81467191026883,
            21.021461341190044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81467191026883,
              21.02132811131908
            ],
            [
              105.81466616356525,
              21.021396131089897
            ],
            [
              105.81466485292923,
              21.02141163494238
            ],
            [
              105.81465413719448,
              21.021461341190044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7142",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02132811131908,
          "to_latitude": 21.021461341190044,
          "to_longitude": 105.81465413719448,
          "from_longitude": 105.81467191026883
        }
      },
      {
        "id": 7135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81360202668074,
            21.022183312206593,
            105.81361880122418,
            21.022288361026543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81361880122418,
              21.022183312206593
            ],
            [
              105.81360783373516,
              21.02227108341065
            ],
            [
              105.81360737732624,
              21.022274727406966
            ],
            [
              105.81360495866507,
              21.02228088905236
            ],
            [
              105.81360202668074,
              21.022288361026543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7143",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022183312206593,
          "to_latitude": 21.022288361026543,
          "to_longitude": 105.81360202668074,
          "from_longitude": 105.81361880122418
        }
      },
      {
        "id": 7136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8152178139314,
            21.023125089785594,
            105.81524163162781,
            21.023174130857086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81524163162781,
              21.023125089785594
            ],
            [
              105.81524090303127,
              21.02313475238844
            ],
            [
              105.81524003105872,
              21.023140161478935
            ],
            [
              105.81523974999448,
              21.02314190063506
            ],
            [
              105.81522320327173,
              21.023144281369877
            ],
            [
              105.8152178139314,
              21.023174130857086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7144",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023125089785594,
          "to_latitude": 21.023174130857086,
          "to_longitude": 105.8152178139314,
          "from_longitude": 105.81524163162781
        }
      },
      {
        "id": 7137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81265715930563,
            21.020416456371642,
            105.81265992302593,
            21.02044024704741
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81265715930563,
              21.02044024704741
            ],
            [
              105.81265768864421,
              21.02043567870191
            ],
            [
              105.81265869969843,
              21.02042698482008
            ],
            [
              105.81265908755391,
              21.02042364554033
            ],
            [
              105.81265992302593,
              21.020416456371642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7145",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02044024704741,
          "to_latitude": 21.020416456371642,
          "to_longitude": 105.81265992302593,
          "from_longitude": 105.81265715930563
        }
      },
      {
        "id": 7138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81447051511087,
            21.02086416838723,
            105.8146359832384,
            21.020881693442817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81447051511087,
              21.02086416838723
            ],
            [
              105.8146359832384,
              21.020881693442817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7146",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02086416838723,
          "to_latitude": 21.020881693442817,
          "to_longitude": 105.8146359832384,
          "from_longitude": 105.81447051511087
        }
      },
      {
        "id": 7139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81347965652292,
            21.023666924508927,
            105.81385459377296,
            21.023723620147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81347965652292,
              21.023666924508927
            ],
            [
              105.8136572427058,
              21.023682800077403
            ],
            [
              105.81365676245035,
              21.023687368188956
            ],
            [
              105.81365616051737,
              21.023693091207104
            ],
            [
              105.81385249534799,
              21.023709560617437
            ],
            [
              105.81385459377296,
              21.023723620147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7147",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023666924508927,
          "to_latitude": 21.023723620147,
          "to_longitude": 105.81385459377296,
          "from_longitude": 105.81347965652292
        }
      },
      {
        "id": 7140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81310645200813,
            21.021680243148754,
            105.81313230177602,
            21.02172522552291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81313230177602,
              21.021680243148754
            ],
            [
              105.81313029730417,
              21.021700875223683
            ],
            [
              105.81311912423827,
              21.02170341883579
            ],
            [
              105.81310863622272,
              21.02170580743008
            ],
            [
              105.81310645200813,
              21.02172522552291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7148",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021680243148754,
          "to_latitude": 21.02172522552291,
          "to_longitude": 105.81310645200813,
          "from_longitude": 105.81313230177602
        }
      },
      {
        "id": 7141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81227788356675,
            21.022712396552098,
            105.81238042460555,
            21.022725190576267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81238042460555,
              21.022725190576267
            ],
            [
              105.81228915293858,
              21.022714650478264
            ],
            [
              105.81228093637462,
              21.022713007909275
            ],
            [
              105.81227788356675,
              21.022712396552098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7149",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022725190576267,
          "to_latitude": 21.022712396552098,
          "to_longitude": 105.81227788356675,
          "from_longitude": 105.81238042460555
        }
      },
      {
        "id": 7142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81189023945974,
            21.022975365153616,
            105.81196780593672,
            21.02299613567237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81196780593672,
              21.022975365153616
            ],
            [
              105.81190917462072,
              21.02299613567237
            ],
            [
              105.81190485479985,
              21.022996044293443
            ],
            [
              105.81189673084371,
              21.022995872622047
            ],
            [
              105.81189023945974,
              21.022995537801922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7150",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022975365153616,
          "to_latitude": 21.022995537801922,
          "to_longitude": 105.81189023945974,
          "from_longitude": 105.81196780593672
        }
      },
      {
        "id": 7143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81312888358829,
            21.021680404570386,
            105.81313386682973,
            21.021724719061364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81313386682973,
              21.021680404570386
            ],
            [
              105.81313375305263,
              21.021681420342127
            ],
            [
              105.8131319763138,
              21.02169721544784
            ],
            [
              105.81313153026635,
              21.021701175523518
            ],
            [
              105.8131311411907,
              21.0217046430678
            ],
            [
              105.81312888358829,
              21.021724719061364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7151",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021680404570386,
          "to_latitude": 21.021724719061364,
          "to_longitude": 105.81312888358829,
          "from_longitude": 105.81313386682973
        }
      },
      {
        "id": 7144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.813271483115,
            21.02169509299252,
            105.8132766609364,
            21.021741125452422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8132766609364,
              21.02169509299252
            ],
            [
              105.81327626172352,
              21.021698641875982
            ],
            [
              105.81327375359392,
              21.021717928744728
            ],
            [
              105.81327362785427,
              21.021719221864586
            ],
            [
              105.813271483115,
              21.021741125452422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7152",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02169509299252,
          "to_latitude": 21.021741125452422,
          "to_longitude": 105.813271483115,
          "from_longitude": 105.8132766609364
        }
      },
      {
        "id": 7145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81420535517857,
            21.020603637360352,
            105.81532617670895,
            21.02073897064887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81420535517857,
              21.020603637360352
            ],
            [
              105.81437705095125,
              21.020621366803056
            ],
            [
              105.81438394157374,
              21.02062207807189
            ],
            [
              105.81440115513904,
              21.020623855401706
            ],
            [
              105.81440224277718,
              21.020623967613794
            ],
            [
              105.81458308341868,
              21.02064264119312
            ],
            [
              105.81473991518708,
              21.020658835510886
            ],
            [
              105.8147688694895,
              21.020661825600687
            ],
            [
              105.81478147455395,
              21.02066312728832
            ],
            [
              105.81478439578214,
              21.020663429424857
            ],
            [
              105.81479540692449,
              21.020664566230113
            ],
            [
              105.81498597094675,
              21.02068424235109
            ],
            [
              105.81498382762916,
              21.020704412655622
            ],
            [
              105.81506923610058,
              21.020713033882153
            ],
            [
              105.81508742544054,
              21.02071486969214
            ],
            [
              105.81509328996083,
              21.020715462106867
            ],
            [
              105.81509369902423,
              21.020715503501556
            ],
            [
              105.81532617670895,
              21.02073897064887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7153",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020603637360352,
          "to_latitude": 21.02073897064887,
          "to_longitude": 105.81532617670895,
          "from_longitude": 105.81420535517857
        }
      },
      {
        "id": 7146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81515367732108,
            21.023117517940918,
            105.8151555309955,
            21.023135403495953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81515367732108,
              21.023135403495953
            ],
            [
              105.81515416268763,
              21.023130722453335
            ],
            [
              105.81515454197095,
              21.0231270544367
            ],
            [
              105.81515455938893,
              21.02312689628848
            ],
            [
              105.81515492317826,
              21.023123386410855
            ],
            [
              105.8151555309955,
              21.023117517940918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7154",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023135403495953,
          "to_latitude": 21.023117517940918,
          "to_longitude": 105.8151555309955,
          "from_longitude": 105.81515367732108
        }
      },
      {
        "id": 7147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8129506085932,
            21.0219904869625,
            105.81295722683501,
            21.02204180024464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81295722683501,
              21.0219904869625
            ],
            [
              105.8129506085932,
              21.02204180024464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7155",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0219904869625,
          "to_latitude": 21.02204180024464,
          "to_longitude": 105.8129506085932,
          "from_longitude": 105.81295722683501
        }
      },
      {
        "id": 7148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81265035545245,
            21.021631010225416,
            105.81265373208078,
            21.02165850796343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81265035545245,
              21.02165850796343
            ],
            [
              105.81265071655652,
              21.02165555719981
            ],
            [
              105.81265157528442,
              21.02164857566322
            ],
            [
              105.81265198711274,
              21.021645221817682
            ],
            [
              105.81265373208078,
              21.021631010225416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7156",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02165850796343,
          "to_latitude": 21.021631010225416,
          "to_longitude": 105.81265373208078,
          "from_longitude": 105.81265035545245
        }
      },
      {
        "id": 7149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81458308341868,
            21.02060169007603,
            105.81458792190197,
            21.02064264119312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81458308341868,
              21.02064264119312
            ],
            [
              105.8145845815312,
              21.020629949995552
            ],
            [
              105.81458582313849,
              21.020619446740035
            ],
            [
              105.81458622320636,
              21.020616061334067
            ],
            [
              105.81458792190197,
              21.02060169007603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7157",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02064264119312,
          "to_latitude": 21.02060169007603,
          "to_longitude": 105.81458792190197,
          "from_longitude": 105.81458308341868
        }
      },
      {
        "id": 7150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81364052061356,
            21.020518607371216,
            105.81364325881225,
            21.02054210890349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81364052061356,
              21.02054210890349
            ],
            [
              105.8136411409895,
              21.02053677237687
            ],
            [
              105.81364210066137,
              21.020528547511773
            ],
            [
              105.81364250968876,
              21.020525036515917
            ],
            [
              105.81364325881225,
              21.020518607371216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7158",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02054210890349,
          "to_latitude": 21.020518607371216,
          "to_longitude": 105.81364325881225,
          "from_longitude": 105.81364052061356
        }
      },
      {
        "id": 7151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81381797985615,
            21.022293270916705,
            105.81382028689634,
            21.022313084313115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81381797985615,
              21.022313084313115
            ],
            [
              105.81381859749195,
              21.022307774896834
            ],
            [
              105.81381884560747,
              21.022305633963924
            ],
            [
              105.81381892743723,
              21.02230493628082
            ],
            [
              105.81381909564686,
              21.0223034930218
            ],
            [
              105.81382028689634,
              21.022293270916705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7159",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022313084313115,
          "to_latitude": 21.022293270916705,
          "to_longitude": 105.81382028689634,
          "from_longitude": 105.81381797985615
        }
      },
      {
        "id": 7152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81301814918442,
            21.02168906329791,
            105.81335934209156,
            21.02172275451349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81301814918442,
              21.02168906329791
            ],
            [
              105.81309500467097,
              21.021700961746433
            ],
            [
              105.81311912423827,
              21.02170341883579
            ],
            [
              105.8131311411907,
              21.0217046430678
            ],
            [
              105.81320705733408,
              21.021712375754106
            ],
            [
              105.81326863431909,
              21.021718708320456
            ],
            [
              105.81327071431785,
              21.021718922375527
            ],
            [
              105.81327362785427,
              21.021719221864586
            ],
            [
              105.8133079810271,
              21.02172275451349
            ],
            [
              105.81335934209156,
              21.02172187033304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7160",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02168906329791,
          "to_latitude": 21.02172187033304,
          "to_longitude": 105.81335934209156,
          "from_longitude": 105.81301814918442
        }
      },
      {
        "id": 7153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81504545465488,
            21.02310840772115,
            105.81505193017738,
            21.023170984521112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81505193017738,
              21.02310840772115
            ],
            [
              105.81504952350595,
              21.023127207281636
            ],
            [
              105.81504867357067,
              21.023133840138485
            ],
            [
              105.81504545465488,
              21.023170984521112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7161",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02310840772115,
          "to_latitude": 21.023170984521112,
          "to_longitude": 105.81504545465488,
          "from_longitude": 105.81505193017738
        }
      },
      {
        "id": 7154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8141908982051,
            21.023692861792973,
            105.81419707780856,
            21.023745163844016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81419707780856,
              21.023692861792973
            ],
            [
              105.81419601437402,
              21.023734234689744
            ],
            [
              105.81419446526607,
              21.02373754520757
            ],
            [
              105.8141908982051,
              21.023745163844016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7162",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023692861792973,
          "to_latitude": 21.023745163844016,
          "to_longitude": 105.8141908982051,
          "from_longitude": 105.81419707780856
        }
      },
      {
        "id": 7155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81346846798391,
            21.024374617768043,
            105.81348655394736,
            21.024415120486516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81346846798391,
              21.024374617768043
            ],
            [
              105.8134809096268,
              21.024402479722152
            ],
            [
              105.81348392559374,
              21.024409236790955
            ],
            [
              105.81348655394736,
              21.024415120486516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_La Thành",
          "maTaiSan": "04.O9C.DODV.7163",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024374617768043,
          "to_latitude": 21.024415120486516,
          "to_longitude": 105.81348655394736,
          "from_longitude": 105.81346846798391
        }
      },
      {
        "id": 7156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81407030223627,
            21.021466023429795,
            105.81410698247474,
            21.02174270265941
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81407030223627,
              21.02174270265941
            ],
            [
              105.81410698247474,
              21.02149853465907
            ],
            [
              105.81408888624836,
              21.021495792363886
            ],
            [
              105.81409330268247,
              21.021466023429795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7164",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02174270265941,
          "to_latitude": 21.021466023429795,
          "to_longitude": 105.81409330268247,
          "from_longitude": 105.81407030223627
        }
      },
      {
        "id": 7157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81191547433765,
            21.02284069678788,
            105.8119904741087,
            21.02285392661766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119904741087,
              21.02284069678788
            ],
            [
              105.81193157979557,
              21.02285392661766
            ],
            [
              105.8119295078448,
              21.022853780250287
            ],
            [
              105.81192403012084,
              21.022853393624462
            ],
            [
              105.81191547433765,
              21.022853595241738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7165",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02284069678788,
          "to_latitude": 21.022853595241738,
          "to_longitude": 105.81191547433765,
          "from_longitude": 105.8119904741087
        }
      },
      {
        "id": 7158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81421769815016,
            21.023764993292485,
            105.81424259369746,
            21.023942817911045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424259369746,
              21.023764993292485
            ],
            [
              105.81421769815016,
              21.023942817911045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7166",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023764993292485,
          "to_latitude": 21.023942817911045,
          "to_longitude": 105.81421769815016,
          "from_longitude": 105.81424259369746
        }
      },
      {
        "id": 7159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81373928908658,
            21.021743026028908,
            105.81374262007506,
            21.021770034015084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81373928908658,
              21.021770034015084
            ],
            [
              105.8137399700032,
              21.02176450842464
            ],
            [
              105.8137406602255,
              21.02175892498308
            ],
            [
              105.81374109145135,
              21.021755427429664
            ],
            [
              105.81374262007506,
              21.021743026028908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7167",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021770034015084,
          "to_latitude": 21.021743026028908,
          "to_longitude": 105.81374262007506,
          "from_longitude": 105.81373928908658
        }
      },
      {
        "id": 7160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81550032071253,
            21.021947313648067,
            105.81550494043032,
            21.021985820500785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81550494043032,
              21.021947313648067
            ],
            [
              105.8155043891305,
              21.021952250621347
            ],
            [
              105.81550325879205,
              21.021962330636676
            ],
            [
              105.81550245965659,
              21.021969455511172
            ],
            [
              105.81550067030659,
              21.021983063078572
            ],
            [
              105.81550032071253,
              21.021985820500785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7168",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021947313648067,
          "to_latitude": 21.021985820500785,
          "to_longitude": 105.81550032071253,
          "from_longitude": 105.81550494043032
        }
      },
      {
        "id": 7161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81376670717304,
            21.021184555681376,
            105.8137729023565,
            21.02122916672658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81377269290795,
              21.021184555681376
            ],
            [
              105.81377229766713,
              21.021187593320075
            ],
            [
              105.81377188644075,
              21.021190698777307
            ],
            [
              105.8137729023565,
              21.021193193147447
            ],
            [
              105.81377265887983,
              21.021194593411767
            ],
            [
              105.81377239579044,
              21.021196102157223
            ],
            [
              105.81377136227972,
              21.02119851962258
            ],
            [
              105.81376670717304,
              21.02122916672658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7169",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021184555681376,
          "to_latitude": 21.02122916672658,
          "to_longitude": 105.81376670717304,
          "from_longitude": 105.81377269290795
        }
      },
      {
        "id": 7162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8132234374431,
            21.02201166228341,
            105.81324667199317,
            21.022098964638054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81324667199317,
              21.02201166228341
            ],
            [
              105.81324565467733,
              21.02201955142071
            ],
            [
              105.81324518937278,
              21.02202120203574
            ],
            [
              105.81324451259695,
              21.022023592502016
            ],
            [
              105.81323255915935,
              21.02202823082507
            ],
            [
              105.8132234374431,
              21.022098964638054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7170",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02201166228341,
          "to_latitude": 21.022098964638054,
          "to_longitude": 105.8132234374431,
          "from_longitude": 105.81324667199317
        }
      },
      {
        "id": 7163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8151993600428,
            21.021780361861644,
            105.81548827044912,
            21.021824413774137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8151993600428,
              21.021780361861644
            ],
            [
              105.8153534652034,
              21.021799269293844
            ],
            [
              105.8153520619512,
              21.021808342615294
            ],
            [
              105.81535277812186,
              21.02180842679524
            ],
            [
              105.81548827044912,
              21.021824413774137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7171",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021780361861644,
          "to_latitude": 21.021824413774137,
          "to_longitude": 105.81548827044912,
          "from_longitude": 105.8151993600428
        }
      },
      {
        "id": 7164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8155974178307,
            21.022862375672805,
            105.8156059206442,
            21.022953133862067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8156059206442,
              21.022862375672805
            ],
            [
              105.81560588245269,
              21.022862782308135
            ],
            [
              105.81560468934568,
              21.022876708564866
            ],
            [
              105.81560149569792,
              21.022909983409292
            ],
            [
              105.8156000363451,
              21.022925189008955
            ],
            [
              105.8155974178307,
              21.022953133862067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7172",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022862375672805,
          "to_latitude": 21.022953133862067,
          "to_longitude": 105.8155974178307,
          "from_longitude": 105.8156059206442
        }
      },
      {
        "id": 7165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81247899570734,
            21.020730217413668,
            105.8124837949279,
            21.020769289585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8124837949279,
              21.020730217413668
            ],
            [
              105.812483785739,
              21.02073029694161
            ],
            [
              105.81248271660195,
              21.02073999097305
            ],
            [
              105.81248213246896,
              21.020745277645844
            ],
            [
              105.812480646727,
              21.020756379089686
            ],
            [
              105.81247899570734,
              21.020769289585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7173",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020730217413668,
          "to_latitude": 21.020769289585,
          "to_longitude": 105.81247899570734,
          "from_longitude": 105.8124837949279
        }
      },
      {
        "id": 7166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81398580301888,
            21.022988295422117,
            105.81404418868391,
            21.022992148250616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81404418868391,
              21.022992148250616
            ],
            [
              105.81402669508417,
              21.022990402942607
            ],
            [
              105.81402379646424,
              21.02299161539834
            ],
            [
              105.81401970437815,
              21.022989704786657
            ],
            [
              105.81401630779705,
              21.02298956388135
            ],
            [
              105.81398994724096,
              21.022988468879003
            ],
            [
              105.81398580301888,
              21.022988295422117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7174",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022992148250616,
          "to_latitude": 21.022988295422117,
          "to_longitude": 105.81398580301888,
          "from_longitude": 105.81404418868391
        }
      },
      {
        "id": 7167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81531386334946,
            21.022835540970437,
            105.81532594247366,
            21.022930464816397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81532594247366,
              21.022835540970437
            ],
            [
              105.815324689771,
              21.02284771163817
            ],
            [
              105.81532020075778,
              21.022881607786395
            ],
            [
              105.81531715045357,
              21.02290463482865
            ],
            [
              105.81531386334946,
              21.022930464816397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7175",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022835540970437,
          "to_latitude": 21.022930464816397,
          "to_longitude": 105.81531386334946,
          "from_longitude": 105.81532594247366
        }
      },
      {
        "id": 7168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81378893108429,
            21.020863791967226,
            105.81379432236902,
            21.020908568170594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81379432236902,
              21.020863791967226
            ],
            [
              105.81379250350085,
              21.020880672960242
            ],
            [
              105.81379208584576,
              21.0208845434821
            ],
            [
              105.81379016451677,
              21.020898320572112
            ],
            [
              105.81378893108429,
              21.020908568170594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7176",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020863791967226,
          "to_latitude": 21.020908568170594,
          "to_longitude": 105.81378893108429,
          "from_longitude": 105.81379432236902
        }
      },
      {
        "id": 7169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8127287810128,
            21.024345322734725,
            105.81283306995896,
            21.024519721666035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8127287810128,
              21.024345322734725
            ],
            [
              105.81274216507366,
              21.02440278352053
            ],
            [
              105.8127859396782,
              21.02440542671591
            ],
            [
              105.81280430746833,
              21.024462280193035
            ],
            [
              105.81281328626108,
              21.024490048554835
            ],
            [
              105.8128319061067,
              21.024517977677167
            ],
            [
              105.81283306995896,
              21.024519721666035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_La Thành",
          "maTaiSan": "04.O9C.DODV.7177",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024345322734725,
          "to_latitude": 21.024519721666035,
          "to_longitude": 105.81283306995896,
          "from_longitude": 105.8127287810128
        }
      },
      {
        "id": 7170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81381943751596,
            21.02230329482686,
            105.81383276820033,
            21.022337058929477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81381943751596,
              21.022337058929477
            ],
            [
              105.81382187620649,
              21.022313497412664
            ],
            [
              105.81382291456707,
              21.022303464813618
            ],
            [
              105.81383276820033,
              21.02230329482686
            ],
            [
              105.81383241082992,
              21.022307638350977
            ],
            [
              105.81383217823257,
              21.02231458799236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7178",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022337058929477,
          "to_latitude": 21.02231458799236,
          "to_longitude": 105.81383217823257,
          "from_longitude": 105.81381943751596
        }
      },
      {
        "id": 7171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116106898694,
            21.024393300781107,
            105.81161966154708,
            21.024395095131396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8116106898694,
              21.024393300781107
            ],
            [
              105.81161966154708,
              21.024395095131396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.2_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7179",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024393300781107,
          "to_latitude": 21.024395095131396,
          "to_longitude": 105.81161966154708,
          "from_longitude": 105.8116106898694
        }
      },
      {
        "id": 7172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81202925893723,
            21.022253168850085,
            105.81256281003621,
            21.022306181860287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81202925893723,
              21.022253168850085
            ],
            [
              105.81228959242397,
              21.022279035233588
            ],
            [
              105.81229304183172,
              21.02228129489369
            ],
            [
              105.81229745991504,
              21.02227981779081
            ],
            [
              105.81230871925584,
              21.02228093640741
            ],
            [
              105.81231183373114,
              21.02228316605377
            ],
            [
              105.81231362915847,
              21.022282523413175
            ],
            [
              105.81231603522686,
              21.022281662887973
            ],
            [
              105.81256281003621,
              21.022306181860287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7180",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022253168850085,
          "to_latitude": 21.022306181860287,
          "to_longitude": 105.81256281003621,
          "from_longitude": 105.81202925893723
        }
      },
      {
        "id": 7173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81311803797693,
            21.022351939167056,
            105.81312190895576,
            21.022386361609207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81312190895576,
              21.022351939167056
            ],
            [
              105.81312170613919,
              21.022353742978794
            ],
            [
              105.81312053230673,
              21.02236417002965
            ],
            [
              105.81312029651025,
              21.02236627838672
            ],
            [
              105.81311803797693,
              21.022386361609207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7181",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022351939167056,
          "to_latitude": 21.022386361609207,
          "to_longitude": 105.81311803797693,
          "from_longitude": 105.81312190895576
        }
      },
      {
        "id": 7174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81233026380514,
            21.021618951868383,
            105.81297880568499,
            21.02168507392533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81233026380514,
              21.021618951868383
            ],
            [
              105.8124191129097,
              21.021630625344056
            ],
            [
              105.81244757453581,
              21.02163444824433
            ],
            [
              105.81247139315859,
              21.021637647510374
            ],
            [
              105.81252048790202,
              21.021644241392902
            ],
            [
              105.81265035545245,
              21.02165850796343
            ],
            [
              105.81269977962569,
              21.021663936862602
            ],
            [
              105.8128446107967,
              21.021676074233376
            ],
            [
              105.81286585132972,
              21.021677854148393
            ],
            [
              105.81288592156838,
              21.02167953668583
            ],
            [
              105.81297880568499,
              21.02168507392533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7182",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021618951868383,
          "to_latitude": 21.02168507392533,
          "to_longitude": 105.81297880568499,
          "from_longitude": 105.81233026380514
        }
      },
      {
        "id": 7175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81350619648313,
            21.023678101275408,
            105.81351416513844,
            21.023831199388386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81351416513844,
              21.023678101275408
            ],
            [
              105.81351388845528,
              21.023681715508136
            ],
            [
              105.81351322293398,
              21.023690435738324
            ],
            [
              105.81351002562826,
              21.02378978797174
            ],
            [
              105.8135072018007,
              21.02379468251909
            ],
            [
              105.81350925965303,
              21.023798078746108
            ],
            [
              105.81350619648313,
              21.023831199388386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7183",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023678101275408,
          "to_latitude": 21.023831199388386,
          "to_longitude": 105.81350619648313,
          "from_longitude": 105.81351416513844
        }
      },
      {
        "id": 7176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81483547590393,
            21.023091421379643,
            105.81485876779978,
            21.02315545616239
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81485876779978,
              21.023091421379643
            ],
            [
              105.81485789319369,
              21.023111925213396
            ],
            [
              105.81485771810458,
              21.02311599509058
            ],
            [
              105.81483907979576,
              21.023120636651008
            ],
            [
              105.81483547590393,
              21.02315545616239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7184",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023091421379643,
          "to_latitude": 21.02315545616239,
          "to_longitude": 105.81483547590393,
          "from_longitude": 105.81485876779978
        }
      },
      {
        "id": 7177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81295490473286,
            21.02168507392533,
            105.81297880568499,
            21.0218787263562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81295490473286,
              21.0218787263562
            ],
            [
              105.81297880568499,
              21.02168507392533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7185",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0218787263562,
          "to_latitude": 21.02168507392533,
          "to_longitude": 105.81297880568499,
          "from_longitude": 105.81295490473286
        }
      },
      {
        "id": 7178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81236920624092,
            21.024443887792945,
            105.81244797636428,
            21.0244565172507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81236920624092,
              21.0244565172507
            ],
            [
              105.81244797636428,
              21.024443887792945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_La Thành",
          "maTaiSan": "04.O9C.DODV.7186",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0244565172507,
          "to_latitude": 21.024443887792945,
          "to_longitude": 105.81244797636428,
          "from_longitude": 105.81236920624092
        }
      },
      {
        "id": 7179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8130025066588,
            21.02420927822372,
            105.81308176619542,
            21.02447611138553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81308149539427,
              21.02447611138553
            ],
            [
              105.81308152002553,
              21.024472670874953
            ],
            [
              105.81308176619542,
              21.02443965854628
            ],
            [
              105.8130754635482,
              21.024412761665275
            ],
            [
              105.81305599278245,
              21.02432957448066
            ],
            [
              105.81303568624016,
              21.024316313728963
            ],
            [
              105.81302723403465,
              21.02431079477053
            ],
            [
              105.81302318148448,
              21.024288150363276
            ],
            [
              105.81301625101784,
              21.02424943142803
            ],
            [
              105.81300841055838,
              21.024245492901642
            ],
            [
              105.8130025066588,
              21.02420927822372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_La Thành",
          "maTaiSan": "04.O9C.DODV.7187",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02447611138553,
          "to_latitude": 21.02420927822372,
          "to_longitude": 105.8130025066588,
          "from_longitude": 105.81308149539427
        }
      },
      {
        "id": 7180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81471083311082,
            21.023373593723488,
            105.8153173174392,
            21.023431573088246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81471083311082,
              21.023373593723488
            ],
            [
              105.81481274924307,
              21.02338333634726
            ],
            [
              105.81481387150771,
              21.02338344387408
            ],
            [
              105.8148146289864,
              21.023383516116226
            ],
            [
              105.81482966308033,
              21.023384953382468
            ],
            [
              105.81499733386919,
              21.023403674161408
            ],
            [
              105.8150086738375,
              21.023404210531577
            ],
            [
              105.81501258822067,
              21.023404395903256
            ],
            [
              105.81501383143288,
              21.02340445497816
            ],
            [
              105.81504149358328,
              21.02340819455355
            ],
            [
              105.8150939976433,
              21.023412191717874
            ],
            [
              105.81519503976374,
              21.02341988345812
            ],
            [
              105.81520038254378,
              21.023420393463404
            ],
            [
              105.81520327675396,
              21.023420670430273
            ],
            [
              105.81521072354374,
              21.023421382608817
            ],
            [
              105.8153173174392,
              21.023431573088246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7188",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023373593723488,
          "to_latitude": 21.023431573088246,
          "to_longitude": 105.8153173174392,
          "from_longitude": 105.81471083311082
        }
      },
      {
        "id": 7181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81375341291627,
            21.022212408075966,
            105.81376772298394,
            21.02230624780614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81376772298394,
              21.022212408075966
            ],
            [
              105.81375831271797,
              21.02228771362075
            ],
            [
              105.81375815230271,
              21.022288997874405
            ],
            [
              105.81375425419651,
              21.022297832927368
            ],
            [
              105.81375341291627,
              21.02230624780614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7189",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022212408075966,
          "to_latitude": 21.02230624780614,
          "to_longitude": 105.81375341291627,
          "from_longitude": 105.81376772298394
        }
      },
      {
        "id": 7182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81339834369628,
            21.021732700490958,
            105.81403059698603,
            21.0218081734522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81403059698603,
              21.0218081734522
            ],
            [
              105.81399430238869,
              21.021802138365924
            ],
            [
              105.81396940072725,
              21.02180039417788
            ],
            [
              105.81394304401226,
              21.021796778236094
            ],
            [
              105.81392740692738,
              21.021794632932043
            ],
            [
              105.81379481567456,
              21.021776443206807
            ],
            [
              105.81373928908658,
              21.021770034015084
            ],
            [
              105.81359998220499,
              21.02175395549509
            ],
            [
              105.81355082097342,
              21.02174877308603
            ],
            [
              105.81353509996806,
              21.02174711588986
            ],
            [
              105.81339834369628,
              21.021732700490958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7190",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0218081734522,
          "to_latitude": 21.021732700490958,
          "to_longitude": 105.81339834369628,
          "from_longitude": 105.81403059698603
        }
      },
      {
        "id": 7183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81190917191856,
            21.02288904449723,
            105.8119230937184,
            21.022890806171752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119230937184,
              21.022890806171752
            ],
            [
              105.81191960316782,
              21.022890365801448
            ],
            [
              105.81191631668531,
              21.022889948844067
            ],
            [
              105.81191267980398,
              21.022889488397905
            ],
            [
              105.81190917191856,
              21.02288904449723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7191",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022890806171752,
          "to_latitude": 21.02288904449723,
          "to_longitude": 105.81190917191856,
          "from_longitude": 105.8119230937184
        }
      },
      {
        "id": 7184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81388676752279,
            21.022227135973452,
            105.81390253051657,
            21.022320367567136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81390253051657,
              21.022227135973452
            ],
            [
              105.81389346140715,
              21.0222997067251
            ],
            [
              105.81389260954793,
              21.02230652565215
            ],
            [
              105.81388813476094,
              21.02231031352309
            ],
            [
              105.81388735675303,
              21.022316028352844
            ],
            [
              105.81388676752279,
              21.022320367567136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7192",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022227135973452,
          "to_latitude": 21.022320367567136,
          "to_longitude": 105.81388676752279,
          "from_longitude": 105.81390253051657
        }
      },
      {
        "id": 7185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8128920918625,
            21.020441135433632,
            105.81289749440548,
            21.020486011929826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81289749440548,
              21.020441135433632
            ],
            [
              105.8128959386962,
              21.020454052702714
            ],
            [
              105.81289528051616,
              21.020459518569627
            ],
            [
              105.81289515902776,
              21.02046053166854
            ],
            [
              105.81289448336877,
              21.020466144845102
            ],
            [
              105.8128920918625,
              21.020486011929826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7193",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020441135433632,
          "to_latitude": 21.020486011929826,
          "to_longitude": 105.8128920918625,
          "from_longitude": 105.81289749440548
        }
      },
      {
        "id": 7186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81560474842087,
            21.022863061242276,
            105.81561307385685,
            21.022951941675366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81561307385685,
              21.022863061242276
            ],
            [
              105.8156125883511,
              21.022868244480854
            ],
            [
              105.81561188457178,
              21.022876628899585
            ],
            [
              105.81560863603889,
              21.022910601298562
            ],
            [
              105.81560790514345,
              21.022918245201097
            ],
            [
              105.81560474842087,
              21.022951941675366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7194",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022863061242276,
          "to_latitude": 21.022951941675366,
          "to_longitude": 105.81560474842087,
          "from_longitude": 105.81561307385685
        }
      },
      {
        "id": 7187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81306669278402,
            21.021638359800072,
            105.81357021172717,
            21.021690819582766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81306669278402,
              21.021638359800072
            ],
            [
              105.81306680347241,
              21.021638371012916
            ],
            [
              105.81357021172717,
              21.021690819582766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7195",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021638359800072,
          "to_latitude": 21.021690819582766,
          "to_longitude": 105.81357021172717,
          "from_longitude": 105.81306669278402
        }
      },
      {
        "id": 7188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81186305592873,
            21.022542430254024,
            105.81198346982727,
            21.023237218636506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81186305592873,
              21.023237218636506
            ],
            [
              105.81188159714695,
              21.023130237654634
            ],
            [
              105.81188268110543,
              21.02312398214499
            ],
            [
              105.81188289565684,
              21.023122743698945
            ],
            [
              105.81188326411986,
              21.023120615742176
            ],
            [
              105.81190485479985,
              21.022996044293443
            ],
            [
              105.81190542382411,
              21.022992759247813
            ],
            [
              105.81190677830857,
              21.022984943475645
            ],
            [
              105.81191175037056,
              21.02295625761734
            ],
            [
              105.8119230937184,
              21.022890806171752
            ],
            [
              105.81192593421706,
              21.02287441619113
            ],
            [
              105.8119278567659,
              21.02286331628082
            ],
            [
              105.81192854055497,
              21.022859374040713
            ],
            [
              105.8119295078448,
              21.022853780250287
            ],
            [
              105.81192994345994,
              21.022851262681698
            ],
            [
              105.81193061042758,
              21.022847411747883
            ],
            [
              105.81195228780932,
              21.022722343102284
            ],
            [
              105.81195321417012,
              21.022717007830188
            ],
            [
              105.8119532374953,
              21.022716874041137
            ],
            [
              105.8119540938463,
              21.022711933813756
            ],
            [
              105.81195496790714,
              21.02270688872741
            ],
            [
              105.81198346982727,
              21.022542430254024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7196",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023237218636506,
          "to_latitude": 21.022542430254024,
          "to_longitude": 105.81198346982727,
          "from_longitude": 105.81186305592873
        }
      },
      {
        "id": 7189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81264324793692,
            21.02074683548468,
            105.81264478464405,
            21.02075953588624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81264324793692,
              21.02075953588624
            ],
            [
              105.81264350198606,
              21.02075742202275
            ],
            [
              105.81264351769308,
              21.02075730272164
            ],
            [
              105.81264394216487,
              21.020753797073187
            ],
            [
              105.81264478464405,
              21.02074683548468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7197",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02075953588624,
          "to_latitude": 21.02074683548468,
          "to_longitude": 105.81264478464405,
          "from_longitude": 105.81264324793692
        }
      },
      {
        "id": 7190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81529378370243,
            21.022834873429208,
            105.8153189827205,
            21.022928324680148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8153189827205,
              21.022834873429208
            ],
            [
              105.81531836123746,
              21.022846188424648
            ],
            [
              105.81530140273986,
              21.022879514797495
            ],
            [
              105.81529952671211,
              21.02288320173116
            ],
            [
              105.81529378370243,
              21.022928324680148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7198",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022834873429208,
          "to_latitude": 21.022928324680148,
          "to_longitude": 105.81529378370243,
          "from_longitude": 105.8153189827205
        }
      },
      {
        "id": 7191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81481285811026,
            21.02226452524095,
            105.81481629876204,
            21.02229291570451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81481285811026,
              21.02229291570451
            ],
            [
              105.81481469486734,
              21.02227775255858
            ],
            [
              105.81481493150363,
              21.022275803163772
            ],
            [
              105.81481592435043,
              21.02226891400934
            ],
            [
              105.81481629876204,
              21.02226452524095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7199",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02229291570451,
          "to_latitude": 21.02226452524095,
          "to_longitude": 105.81481629876204,
          "from_longitude": 105.81481285811026
        }
      },
      {
        "id": 7192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81220828492164,
            21.02088333689424,
            105.81307381463547,
            21.020971526080036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81220828492164,
              21.02088333689424
            ],
            [
              105.81307381463547,
              21.020971526080036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7200",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02088333689424,
          "to_latitude": 21.020971526080036,
          "to_longitude": 105.81307381463547,
          "from_longitude": 105.81220828492164
        }
      },
      {
        "id": 7193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8122456395531,
            21.02043509406293,
            105.81226497890809,
            21.020589805473982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122456395531,
              21.020589805473982
            ],
            [
              105.8122456607679,
              21.020589637372296
            ],
            [
              105.81226497890809,
              21.02043509406293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7201",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020589805473982,
          "to_latitude": 21.02043509406293,
          "to_longitude": 105.81226497890809,
          "from_longitude": 105.8122456395531
        }
      },
      {
        "id": 7194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81322931044089,
            21.023660971298423,
            105.8132505691196,
            21.02388096635911
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8132505691196,
              21.023660971298423
            ],
            [
              105.81325049713656,
              21.023661711385817
            ],
            [
              105.81324887126073,
              21.023678539963456
            ],
            [
              105.81325056753616,
              21.023682671343426
            ],
            [
              105.8132481097957,
              21.02368642336004
            ],
            [
              105.81322931044089,
              21.02388096635911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7202",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023660971298423,
          "to_latitude": 21.02388096635911,
          "to_longitude": 105.81322931044089,
          "from_longitude": 105.8132505691196
        }
      },
      {
        "id": 7195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81336584715933,
            21.023666975500948,
            105.81337645718172,
            21.023733612161838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81337645718172,
              21.023666975500948
            ],
            [
              105.81337641307353,
              21.023668065907305
            ],
            [
              105.81337619975307,
              21.02367343029146
            ],
            [
              105.8133699128025,
              21.02369021522537
            ],
            [
              105.81336705289912,
              21.023695021426786
            ],
            [
              105.81336910807332,
              21.023698811475594
            ],
            [
              105.81336584715933,
              21.023733612161838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7203",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023666975500948,
          "to_latitude": 21.023733612161838,
          "to_longitude": 105.81336584715933,
          "from_longitude": 105.81337645718172
        }
      },
      {
        "id": 7196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81401593791587,
            21.020889675322366,
            105.81404508498943,
            21.021104589670504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81401593791587,
              21.021104589670504
            ],
            [
              105.81404383728534,
              21.02091341630641
            ],
            [
              105.81404508498943,
              21.020889675322366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7204",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021104589670504,
          "to_latitude": 21.020889675322366,
          "to_longitude": 105.81404508498943,
          "from_longitude": 105.81401593791587
        }
      },
      {
        "id": 7197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81221273275007,
            21.020582055750637,
            105.81311753651002,
            21.020678472598757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81221273275007,
              21.020582055750637
            ],
            [
              105.81223021245111,
              21.020583953966025
            ],
            [
              105.8122456395531,
              21.020589805473982
            ],
            [
              105.81311753651002,
              21.020678472598757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7205",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020582055750637,
          "to_latitude": 21.020678472598757,
          "to_longitude": 105.81311753651002,
          "from_longitude": 105.81221273275007
        }
      },
      {
        "id": 7198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8151978935234,
            21.023408986138126,
            105.8152014229622,
            21.023442976134582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8151978935234,
              21.023442976134582
            ],
            [
              105.81519812293061,
              21.02344075941903
            ],
            [
              105.81519986136274,
              21.02342609532425
            ],
            [
              105.81520038254378,
              21.023420393463404
            ],
            [
              105.8152014229622,
              21.023408986138126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7206",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023442976134582,
          "to_latitude": 21.023408986138126,
          "to_longitude": 105.8152014229622,
          "from_longitude": 105.8151978935234
        }
      },
      {
        "id": 7199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81437191715105,
            21.020581635349988,
            105.81440559621957,
            21.020644971039157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81440559621957,
              21.020581635349988
            ],
            [
              105.814401182701,
              21.020612457442056
            ],
            [
              105.81438394157374,
              21.02062207807189
            ],
            [
              105.81437398167691,
              21.020627636348287
            ],
            [
              105.81437191715105,
              21.020644971039157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7207",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020581635349988,
          "to_latitude": 21.020644971039157,
          "to_longitude": 105.81437191715105,
          "from_longitude": 105.81440559621957
        }
      },
      {
        "id": 7200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81289577830984,
            21.02044152144855,
            105.81290120393582,
            21.020486575991008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81290120393582,
              21.02044152144855
            ],
            [
              105.81289965471083,
              21.020454392622035
            ],
            [
              105.8128989494986,
              21.02046023265003
            ],
            [
              105.81289887407569,
              21.020460870689277
            ],
            [
              105.81289819013138,
              21.020466552550765
            ],
            [
              105.81289577830984,
              21.020486575991008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7208",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02044152144855,
          "to_latitude": 21.020486575991008,
          "to_longitude": 105.81289577830984,
          "from_longitude": 105.81290120393582
        }
      },
      {
        "id": 7201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81269465148813,
            21.021962508813274,
            105.81332526774494,
            21.02202974211972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81269465148813,
              21.021962508813274
            ],
            [
              105.81272986767806,
              21.02196907229896
            ],
            [
              105.81279524296319,
              21.02197634882213
            ],
            [
              105.81279886123379,
              21.02197675153235
            ],
            [
              105.81280106261353,
              21.02197699662601
            ],
            [
              105.81281250073815,
              21.021978269710615
            ],
            [
              105.81281633173337,
              21.021978695791045
            ],
            [
              105.81291952037488,
              21.021986465366883
            ],
            [
              105.81292801573382,
              21.021987371346313
            ],
            [
              105.81294581113721,
              21.0219892697806
            ],
            [
              105.81295589468425,
              21.021990345200365
            ],
            [
              105.81295722683501,
              21.0219904869625
            ],
            [
              105.81308652649456,
              21.022004278096805
            ],
            [
              105.81309005130966,
              21.022004654150727
            ],
            [
              105.81310134765832,
              21.02200585924823
            ],
            [
              105.81310197619788,
              21.02200592669526
            ],
            [
              105.81310495044093,
              21.022006243961386
            ],
            [
              105.81310551930005,
              21.02200630446788
            ],
            [
              105.81323037013031,
              21.022019620887768
            ],
            [
              105.81324518937278,
              21.02202120203574
            ],
            [
              105.81325209750365,
              21.022021937652124
            ],
            [
              105.81325318806172,
              21.022022054373465
            ],
            [
              105.81325683030478,
              21.022022442507616
            ],
            [
              105.81332526774494,
              21.02202974211972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7209",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021962508813274,
          "to_latitude": 21.02202974211972,
          "to_longitude": 105.81332526774494,
          "from_longitude": 105.81269465148813
        }
      },
      {
        "id": 7202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81378604702185,
            21.020863499330208,
            105.81379148872954,
            21.020908733711106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81379148872954,
              21.020863499330208
            ],
            [
              105.81378883166028,
              21.02088032110748
            ],
            [
              105.81378824477679,
              21.020884028954796
            ],
            [
              105.81378604702185,
              21.020908733711106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7210",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020863499330208,
          "to_latitude": 21.020908733711106,
          "to_longitude": 105.81378604702185,
          "from_longitude": 105.81379148872954
        }
      },
      {
        "id": 7203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81482452786646,
            21.021480117025632,
            105.81483374424919,
            21.02155541446496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81483374424919,
              21.021480117025632
            ],
            [
              105.81483294301361,
              21.02152740029831
            ],
            [
              105.81483247151947,
              21.021528970559682
            ],
            [
              105.81482452786646,
              21.02155541446496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7211",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021480117025632,
          "to_latitude": 21.02155541446496,
          "to_longitude": 105.81482452786646,
          "from_longitude": 105.81483374424919
        }
      },
      {
        "id": 7204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81349208902354,
            21.02367796834697,
            105.81351222361506,
            21.023831407770178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81351212018406,
              21.02367796834697
            ],
            [
              105.81351222361506,
              21.023681606051873
            ],
            [
              105.81350526921604,
              21.023688846166923
            ],
            [
              105.81349593634357,
              21.02378979936326
            ],
            [
              105.81349312422132,
              21.023793482626566
            ],
            [
              105.81349516576357,
              21.02379812809517
            ],
            [
              105.81349208902354,
              21.023831407770178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7212",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02367796834697,
          "to_latitude": 21.023831407770178,
          "to_longitude": 105.81349208902354,
          "from_longitude": 105.81351212018406
        }
      },
      {
        "id": 7205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81360727194392,
            21.022184644808735,
            105.81362666015605,
            21.022288990775714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81362666015605,
              21.022184644808735
            ],
            [
              105.81361576364493,
              21.02227184483389
            ],
            [
              105.81361017231734,
              21.022276934170126
            ],
            [
              105.81360902268615,
              21.022281709613587
            ],
            [
              105.81360727194392,
              21.022288990775714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7213",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022184644808735,
          "to_latitude": 21.022288990775714,
          "to_longitude": 105.81360727194392,
          "from_longitude": 105.81362666015605
        }
      },
      {
        "id": 7206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81380049528285,
            21.023938978970627,
            105.81387444178624,
            21.02394485674546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81380049528285,
              21.023938978970627
            ],
            [
              105.81383350837872,
              21.023942412232604
            ],
            [
              105.81383357767963,
              21.023942419126804
            ],
            [
              105.81385702315447,
              21.02394485674546
            ],
            [
              105.81386614363116,
              21.02394446806827
            ],
            [
              105.81387444178624,
              21.023944113132217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7214",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023938978970627,
          "to_latitude": 21.023944113132217,
          "to_longitude": 105.81387444178624,
          "from_longitude": 105.81380049528285
        }
      },
      {
        "id": 7207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81503136712833,
            21.021220292591202,
            105.81549523774648,
            21.021267525440173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81503136712833,
              21.021220292591202
            ],
            [
              105.81521239284778,
              21.021244731697195
            ],
            [
              105.81524215080017,
              21.021242995778856
            ],
            [
              105.81524272830472,
              21.021243054430364
            ],
            [
              105.81524502387407,
              21.021243285492734
            ],
            [
              105.81524755134727,
              21.021243528991967
            ],
            [
              105.81526421765523,
              21.02124513453214
            ],
            [
              105.81549150138409,
              21.021267163095196
            ],
            [
              105.81549523774648,
              21.021267525440173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7215",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021220292591202,
          "to_latitude": 21.021267525440173,
          "to_longitude": 105.81549523774648,
          "from_longitude": 105.81503136712833
        }
      },
      {
        "id": 7208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81204006949812,
            21.02415519440304,
            105.81227046108121,
            21.02418779736685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81204006949812,
              21.02418779736685
            ],
            [
              105.81227046108121,
              21.02415519440304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7216",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02418779736685,
          "to_latitude": 21.02415519440304,
          "to_longitude": 105.81227046108121,
          "from_longitude": 105.81204006949812
        }
      },
      {
        "id": 7209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81424086123283,
            21.021465812989394,
            105.81506850477392,
            21.021554175057158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424086123283,
              21.021465812989394
            ],
            [
              105.81441854343339,
              21.021484783640894
            ],
            [
              105.81442353033282,
              21.021485316150923
            ],
            [
              105.81443378518877,
              21.02148641053452
            ],
            [
              105.81443782976739,
              21.021486842781783
            ],
            [
              105.81467346245702,
              21.021511998265485
            ],
            [
              105.8146913367532,
              21.02151390607579
            ],
            [
              105.8148095872414,
              21.021526532182854
            ],
            [
              105.81481573880322,
              21.021527187360334
            ],
            [
              105.81483009694337,
              21.021528717289648
            ],
            [
              105.81483247151947,
              21.021528970559682
            ],
            [
              105.81505501674583,
              21.021552734914255
            ],
            [
              105.81506850477392,
              21.021554175057158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7217",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021465812989394,
          "to_latitude": 21.021554175057158,
          "to_longitude": 105.81506850477392,
          "from_longitude": 105.81424086123283
        }
      },
      {
        "id": 7210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81511691816624,
            21.021924899390307,
            105.81572115050118,
            21.02197651329832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81511691816624,
              21.021924899390307
            ],
            [
              105.81517661946022,
              21.021932829026508
            ],
            [
              105.81518855567359,
              21.02193441388672
            ],
            [
              105.81533062215388,
              21.021947016700555
            ],
            [
              105.8155002434525,
              21.021962063287138
            ],
            [
              105.81550325879205,
              21.021962330636676
            ],
            [
              105.81553678556715,
              21.02196530498715
            ],
            [
              105.81570462764726,
              21.021975122085554
            ],
            [
              105.81572115050118,
              21.02197651329832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7218",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021924899390307,
          "to_latitude": 21.02197651329832,
          "to_longitude": 105.81572115050118,
          "from_longitude": 105.81511691816624
        }
      },
      {
        "id": 7211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81228550802716,
            21.02261628471937,
            105.81229529647736,
            21.02261739590916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81228550802716,
              21.02261628471937
            ],
            [
              105.81229032380843,
              21.022616832559933
            ],
            [
              105.8122924626549,
              21.022617075248867
            ],
            [
              105.81229529647736,
              21.02261739590916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7219",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02261628471937,
          "to_latitude": 21.02261739590916,
          "to_longitude": 105.81229529647736,
          "from_longitude": 105.81228550802716
        }
      },
      {
        "id": 7212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8141789465685,
            21.022604340799127,
            105.81419166323852,
            21.0226918232389
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81419166323852,
              21.022604340799127
            ],
            [
              105.8141820206746,
              21.022670667584837
            ],
            [
              105.81418383937569,
              21.022673957464892
            ],
            [
              105.81418105906127,
              21.022677291037777
            ],
            [
              105.81417943899268,
              21.02268843648896
            ],
            [
              105.8141789465685,
              21.0226918232389
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7220",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022604340799127,
          "to_latitude": 21.0226918232389,
          "to_longitude": 105.8141789465685,
          "from_longitude": 105.81419166323852
        }
      },
      {
        "id": 7213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81323197386891,
            21.02201249576876,
            105.81325464652991,
            21.022099459437186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81325464652991,
              21.02201249576876
            ],
            [
              105.81325330948182,
              21.022020851597052
            ],
            [
              105.81325318806172,
              21.022022054373465
            ],
            [
              105.81325286926871,
              21.022025177452086
            ],
            [
              105.81324063123792,
              21.022032327205782
            ],
            [
              105.81323197386891,
              21.022099459437186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7221",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02201249576876,
          "to_latitude": 21.022099459437186,
          "to_longitude": 105.81323197386891,
          "from_longitude": 105.81325464652991
        }
      },
      {
        "id": 7214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81191620344039,
            21.022833452939143,
            105.81198800682485,
            21.022849493817272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81198800682485,
              21.022833452939143
            ],
            [
              105.81195888067568,
              21.022841433810328
            ],
            [
              105.81193061042758,
              21.022847411747883
            ],
            [
              105.81192489892176,
              21.02284862043495
            ],
            [
              105.81191620344039,
              21.022849493817272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7222",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022833452939143,
          "to_latitude": 21.022849493817272,
          "to_longitude": 105.81191620344039,
          "from_longitude": 105.81198800682485
        }
      },
      {
        "id": 7215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81228991225615,
            21.022269332028074,
            105.81229560901103,
            21.022309129252033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81229560901103,
              21.022269332028074
            ],
            [
              105.81229530497836,
              21.022273762908572
            ],
            [
              105.81229304183172,
              21.02228129489369
            ],
            [
              105.81228991225615,
              21.022309129252033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7223",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022269332028074,
          "to_latitude": 21.022309129252033,
          "to_longitude": 105.81228991225615,
          "from_longitude": 105.81229560901103
        }
      },
      {
        "id": 7216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81286109732164,
            21.02165300814057,
            105.81286755690618,
            21.02171158803296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81286109732164,
              21.02171158803296
            ],
            [
              105.81286243235223,
              21.021699484725122
            ],
            [
              105.81286585132972,
              21.021677854148393
            ],
            [
              105.81286626797353,
              21.021675212022842
            ],
            [
              105.81286755690618,
              21.02165300814057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7224",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02171158803296,
          "to_latitude": 21.02165300814057,
          "to_longitude": 105.81286755690618,
          "from_longitude": 105.81286109732164
        }
      },
      {
        "id": 7217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81444535138009,
            21.02222621484671,
            105.81444894031408,
            21.02225583259763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81444535138009,
              21.02225583259763
            ],
            [
              105.81444804368773,
              21.022240903809834
            ],
            [
              105.81444825005967,
              21.022239757529388
            ],
            [
              105.81444869634049,
              21.022228231115104
            ],
            [
              105.81444894031408,
              21.02222621484671
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7225",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225583259763,
          "to_latitude": 21.02222621484671,
          "to_longitude": 105.81444894031408,
          "from_longitude": 105.81444535138009
        }
      },
      {
        "id": 7218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81283796280725,
            21.021650626738854,
            105.81284532081426,
            21.02170912800593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81283796280725,
              21.02170912800593
            ],
            [
              105.8128446107967,
              21.021676074233376
            ],
            [
              105.81284532081426,
              21.02167253922018
            ],
            [
              105.81284441320715,
              21.021650626738854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7226",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02170912800593,
          "to_latitude": 21.021650626738854,
          "to_longitude": 105.81284441320715,
          "from_longitude": 105.81283796280725
        }
      },
      {
        "id": 7219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81346393782877,
            21.02218776163983,
            105.81347469152867,
            21.022273817488678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81347469152867,
              21.02218776163983
            ],
            [
              105.81346617368304,
              21.02225718740977
            ],
            [
              105.81346514736738,
              21.02226554355955
            ],
            [
              105.81346400144027,
              21.02227330776415
            ],
            [
              105.81346393782877,
              21.022273817488678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7227",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02218776163983,
          "to_latitude": 21.022273817488678,
          "to_longitude": 105.81346393782877,
          "from_longitude": 105.81347469152867
        }
      },
      {
        "id": 7220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81312005525197,
            21.02133935946479,
            105.81398044742421,
            21.021426173919956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81312005525197,
              21.02133935946479
            ],
            [
              105.81398044742421,
              21.021426173919956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7228",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02133935946479,
          "to_latitude": 21.021426173919956,
          "to_longitude": 105.81398044742421,
          "from_longitude": 105.81312005525197
        }
      },
      {
        "id": 7221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81397538328936,
            21.023421468969257,
            105.81463188855105,
            21.02348269331909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81463188855105,
              21.02348269331909
            ],
            [
              105.81452334121175,
              21.023472569571567
            ],
            [
              105.81452282436149,
              21.02347252146611
            ],
            [
              105.81449837449917,
              21.023470235973395
            ],
            [
              105.81449801164676,
              21.02347020248523
            ],
            [
              105.81446636155283,
              21.02346725779098
            ],
            [
              105.81437852964353,
              21.023459065202097
            ],
            [
              105.81437829191049,
              21.023459042856516
            ],
            [
              105.81435657840608,
              21.023457017918698
            ],
            [
              105.81435456105207,
              21.023456829771295
            ],
            [
              105.81431857303959,
              21.023453474077233
            ],
            [
              105.81430359014685,
              21.023452077165953
            ],
            [
              105.81422947549204,
              21.02344516463176
            ],
            [
              105.81422761790792,
              21.023444991072825
            ],
            [
              105.81421060996219,
              21.023443405138003
            ],
            [
              105.81420930869339,
              21.02344328401249
            ],
            [
              105.81408475637508,
              21.023431670406698
            ],
            [
              105.81408053109605,
              21.023431276049795
            ],
            [
              105.8140617050245,
              21.023429519060233
            ],
            [
              105.81406057314999,
              21.02342941338088
            ],
            [
              105.81397538328936,
              21.023421468969257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7229",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02348269331909,
          "to_latitude": 21.023421468969257,
          "to_longitude": 105.81397538328936,
          "from_longitude": 105.81463188855105
        }
      },
      {
        "id": 7222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81501165187403,
            21.023391150137456,
            105.81501663790964,
            21.02345224405174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.815013217718,
              21.02345224405174
            ],
            [
              105.81501663790964,
              21.023419208389303
            ],
            [
              105.81501165187403,
              21.02340911342922
            ],
            [
              105.81501258822067,
              21.023404395903256
            ],
            [
              105.81501521460127,
              21.023391150137456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7230",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02345224405174,
          "to_latitude": 21.023391150137456,
          "to_longitude": 105.81501521460127,
          "from_longitude": 105.815013217718
        }
      },
      {
        "id": 7223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81422872336489,
            21.022203632168278,
            105.81423240815234,
            21.022234029859938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81422872336489,
              21.022234029859938
            ],
            [
              105.81423070263811,
              21.022219059326982
            ],
            [
              105.81423146769546,
              21.02221326507397
            ],
            [
              105.81423206932689,
              21.022206428375725
            ],
            [
              105.81423240815234,
              21.022203632168278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7231",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022234029859938,
          "to_latitude": 21.022203632168278,
          "to_longitude": 105.81423240815234,
          "from_longitude": 105.81422872336489
        }
      },
      {
        "id": 7224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81382173845518,
            21.023723620147,
            105.8138828471174,
            21.0240632941635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81382173845518,
              21.0240632941635
            ],
            [
              105.81383005251017,
              21.02397814056797
            ],
            [
              105.81383350837872,
              21.023942412232604
            ],
            [
              105.81383394194873,
              21.023937928344342
            ],
            [
              105.81383664270298,
              21.023910007505524
            ],
            [
              105.81385278792823,
              21.023742371657207
            ],
            [
              105.81385459377296,
              21.023723620147
            ],
            [
              105.81388027951093,
              21.023727264593585
            ],
            [
              105.8138828471174,
              21.02372762804911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7232",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0240632941635,
          "to_latitude": 21.02372762804911,
          "to_longitude": 105.8138828471174,
          "from_longitude": 105.81382173845518
        }
      },
      {
        "id": 7225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81309531589622,
            21.022351547698907,
            105.81311782994621,
            21.022384938360325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81311782994621,
              21.022351547698907
            ],
            [
              105.81311775770709,
              21.0223634164539
            ],
            [
              105.8131172741817,
              21.02236596496385
            ],
            [
              105.8131167205127,
              21.022368883229195
            ],
            [
              105.81309672004454,
              21.022372456269192
            ],
            [
              105.81309531589622,
              21.022384938360325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7233",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022351547698907,
          "to_latitude": 21.022384938360325,
          "to_longitude": 105.81309531589622,
          "from_longitude": 105.81311782994621
        }
      },
      {
        "id": 7226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81313517513728,
            21.023775707393913,
            105.81335158371387,
            21.024074799527853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81322623758341,
              21.023780889913194
            ],
            [
              105.81316782420903,
              21.023775707393913
            ],
            [
              105.8131526203914,
              21.02389516867884
            ],
            [
              105.8131393725677,
              21.023976190995608
            ],
            [
              105.81313517513728,
              21.024001864527037
            ],
            [
              105.81314062227145,
              21.024051057148426
            ],
            [
              105.81315472891002,
              21.02405264441509
            ],
            [
              105.81329735663896,
              21.02406869651855
            ],
            [
              105.81335158371387,
              21.024074799527853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7234",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023780889913194,
          "to_latitude": 21.024074799527853,
          "to_longitude": 105.81335158371387,
          "from_longitude": 105.81322623758341
        }
      },
      {
        "id": 7227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81201894379711,
            21.024030388274664,
            105.81227385704254,
            21.024060037345187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81201894379711,
              21.024060037345187
            ],
            [
              105.81202110596134,
              21.024059785860164
            ],
            [
              105.81227385704254,
              21.024030388274664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7235",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024060037345187,
          "to_latitude": 21.024030388274664,
          "to_longitude": 105.81227385704254,
          "from_longitude": 105.81201894379711
        }
      },
      {
        "id": 7228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8132454812812,
            21.022022442507616,
            105.81325683030478,
            21.0220732500299
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81325683030478,
              21.022022442507616
            ],
            [
              105.8132454812812,
              21.0220732500299
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7236",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022022442507616,
          "to_latitude": 21.0220732500299,
          "to_longitude": 105.8132454812812,
          "from_longitude": 105.81325683030478
        }
      },
      {
        "id": 7229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81228341614742,
            21.024576166587586,
            105.81228861877365,
            21.02460850799069
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81228341614742,
              21.024576166587586
            ],
            [
              105.81228669839074,
              21.024596568367134
            ],
            [
              105.81228825942893,
              21.024606281444928
            ],
            [
              105.81228861877365,
              21.02460850799069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_La Thành",
          "maTaiSan": "04.O9C.DODV.7237",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024576166587586,
          "to_latitude": 21.02460850799069,
          "to_longitude": 105.81228861877365,
          "from_longitude": 105.81228341614742
        }
      },
      {
        "id": 7230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81421138992884,
            21.021281172398535,
            105.81422850616192,
            21.021416328497207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8142193781409,
              21.021281172398535
            ],
            [
              105.81421138992884,
              21.02134301036261
            ],
            [
              105.81421737900362,
              21.0213525414527
            ],
            [
              105.81422850616192,
              21.021370251961446
            ],
            [
              105.8142235597552,
              21.021416328497207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7238",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021281172398535,
          "to_latitude": 21.021416328497207,
          "to_longitude": 105.8142235597552,
          "from_longitude": 105.8142193781409
        }
      },
      {
        "id": 7231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8156249887093,
            21.02160878564206,
            105.81572426577142,
            21.021913454007418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81572426577142,
              21.021913454007418
            ],
            [
              105.81563642061738,
              21.021644288167
            ],
            [
              105.8156249887093,
              21.02160878564206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7239",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021913454007418,
          "to_latitude": 21.02160878564206,
          "to_longitude": 105.8156249887093,
          "from_longitude": 105.81572426577142
        }
      },
      {
        "id": 7232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81462525190116,
            21.023788668009637,
            105.81526996524225,
            21.023895104739484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81462525190116,
              21.023788668009637
            ],
            [
              105.81463246697416,
              21.023873499767518
            ],
            [
              105.81482771705348,
              21.023885910522228
            ],
            [
              105.81493812976994,
              21.023895104739484
            ],
            [
              105.81494017530338,
              21.023838531246515
            ],
            [
              105.81504373871687,
              21.02384668518157
            ],
            [
              105.81504525231198,
              21.02382268821766
            ],
            [
              105.81511711983015,
              21.023825683025187
            ],
            [
              105.81511786279829,
              21.023805214146186
            ],
            [
              105.81519312663173,
              21.023809360516836
            ],
            [
              105.81526996524225,
              21.023799706125544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7240",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023788668009637,
          "to_latitude": 21.023799706125544,
          "to_longitude": 105.81526996524225,
          "from_longitude": 105.81462525190116
        }
      },
      {
        "id": 7233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8119278567659,
            21.022849651599547,
            105.81199742967021,
            21.022864285039223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119278567659,
              21.02286331628082
            ],
            [
              105.8119491325093,
              21.022864285039223
            ],
            [
              105.81199742967021,
              21.022849651599547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7241",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02286331628082,
          "to_latitude": 21.022849651599547,
          "to_longitude": 105.81199742967021,
          "from_longitude": 105.8119278567659
        }
      },
      {
        "id": 7234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81433808595527,
            21.020856164408727,
            105.81437459651842,
            21.021151136393385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81433808595527,
              21.021151136393385
            ],
            [
              105.81433838822672,
              21.0211486953289
            ],
            [
              105.81437459651842,
              21.020856164408727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7242",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021151136393385,
          "to_latitude": 21.020856164408727,
          "to_longitude": 105.81437459651842,
          "from_longitude": 105.81433808595527
        }
      },
      {
        "id": 7235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81465512415558,
            21.02362103790709,
            105.81479544744651,
            21.02363356708441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81479544744651,
              21.02363356708441
            ],
            [
              105.81467695724325,
              21.02362103790709
            ],
            [
              105.81466349594832,
              21.02362649916901
            ],
            [
              105.81465512415558,
              21.023625514106698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7243",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02363356708441,
          "to_latitude": 21.023625514106698,
          "to_longitude": 105.81465512415558,
          "from_longitude": 105.81479544744651
        }
      },
      {
        "id": 7236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81258360262012,
            21.02337649830942,
            105.8125891065818,
            21.023415294743213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8125891065818,
              21.02337649830942
            ],
            [
              105.81258809368809,
              21.02338663103878
            ],
            [
              105.8125873157152,
              21.023390942247698
            ],
            [
              105.81258573541896,
              21.023399686717553
            ],
            [
              105.81258360262012,
              21.023415294743213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7244",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02337649830942,
          "to_latitude": 21.023415294743213,
          "to_longitude": 105.81258360262012,
          "from_longitude": 105.8125891065818
        }
      },
      {
        "id": 7237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81393223889809,
            21.02372926136268,
            105.81410456120594,
            21.023803777135093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81393223889809,
              21.02372926136268
            ],
            [
              105.81403993377354,
              21.023737735754334
            ],
            [
              105.81403281438,
              21.02379732682662
            ],
            [
              105.8140327748004,
              21.02379765579078
            ],
            [
              105.81403352359384,
              21.02379772265855
            ],
            [
              105.81410086036695,
              21.023803777135093
            ],
            [
              105.81410456120594,
              21.023774908536726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7245",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02372926136268,
          "to_latitude": 21.023774908536726,
          "to_longitude": 105.81410456120594,
          "from_longitude": 105.81393223889809
        }
      },
      {
        "id": 7238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81402471599884,
            21.023676694927573,
            105.81403083957414,
            21.0237285188989
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81403083957414,
              21.023676694927573
            ],
            [
              105.81402890075616,
              21.02369309868476
            ],
            [
              105.81402757450851,
              21.023719207396272
            ],
            [
              105.81402702814205,
              21.02372098756251
            ],
            [
              105.81402471599884,
              21.0237285188989
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7246",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023676694927573,
          "to_latitude": 21.0237285188989,
          "to_longitude": 105.81402471599884,
          "from_longitude": 105.81403083957414
        }
      },
      {
        "id": 7239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81422019896657,
            21.023046929181092,
            105.81466665293541,
            21.023086627883142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81422019896657,
              21.023046929181092
            ],
            [
              105.81444380080026,
              21.023066811658758
            ],
            [
              105.81447227208177,
              21.02306934345255
            ],
            [
              105.81466665293541,
              21.023086627883142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7247",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023046929181092,
          "to_latitude": 21.023086627883142,
          "to_longitude": 105.81466665293541,
          "from_longitude": 105.81422019896657
        }
      },
      {
        "id": 7240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81417215643958,
            21.022620868394668,
            105.81418027072993,
            21.02267260977523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81417215643958,
              21.02267260977523
            ],
            [
              105.81417217689648,
              21.022672479612567
            ],
            [
              105.81418027072993,
              21.022620868394668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7248",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02267260977523,
          "to_latitude": 21.022620868394668,
          "to_longitude": 105.81418027072993,
          "from_longitude": 105.81417215643958
        }
      },
      {
        "id": 7241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81345395326093,
            21.02416165200901,
            105.8137570394091,
            21.024255541633632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81345395326093,
              21.02416165200901
            ],
            [
              105.81347471460639,
              21.024255541633632
            ],
            [
              105.8137570394091,
              21.024196736727422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_La Thành",
          "maTaiSan": "04.O9C.DODV.7249",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02416165200901,
          "to_latitude": 21.024196736727422,
          "to_longitude": 105.8137570394091,
          "from_longitude": 105.81345395326093
        }
      },
      {
        "id": 7242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81186708275342,
            21.02465631263289,
            105.81187416871933,
            21.024685602990033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81186708275342,
              21.02465631263289
            ],
            [
              105.81187121172385,
              21.02467338017998
            ],
            [
              105.81187289444341,
              21.024680342353633
            ],
            [
              105.81187416871933,
              21.024685602990033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_La Thành",
          "maTaiSan": "04.O9C.DODV.7250",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02465631263289,
          "to_latitude": 21.024685602990033,
          "to_longitude": 105.81187416871933,
          "from_longitude": 105.81186708275342
        }
      },
      {
        "id": 7243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81517661946022,
            21.021915447383602,
            105.81517830270533,
            21.021932829026508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81517830270533,
              21.021915447383602
            ],
            [
              105.81517728438459,
              21.02192596402252
            ],
            [
              105.81517701295837,
              21.02192876803745
            ],
            [
              105.81517683891276,
              21.0219305509389
            ],
            [
              105.81517661946022,
              21.021932829026508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7251",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021915447383602,
          "to_latitude": 21.021932829026508,
          "to_longitude": 105.81517661946022,
          "from_longitude": 105.81517830270533
        }
      },
      {
        "id": 7244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81447227208177,
            21.0230330563104,
            105.81447679640269,
            21.02306934345255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81447227208177,
              21.02306934345255
            ],
            [
              105.81447264632527,
              21.02306633842987
            ],
            [
              105.81447493887413,
              21.023047944970966
            ],
            [
              105.81447673187665,
              21.023033574168586
            ],
            [
              105.81447679640269,
              21.0230330563104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7252",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02306934345255,
          "to_latitude": 21.0230330563104,
          "to_longitude": 105.81447679640269,
          "from_longitude": 105.81447227208177
        }
      },
      {
        "id": 7245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81293121642867,
            21.021980356284303,
            105.81294703839889,
            21.022069203823047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81294703839889,
              21.021980356284303
            ],
            [
              105.81294581113721,
              21.0219892697806
            ],
            [
              105.81294557323304,
              21.02199098975979
            ],
            [
              105.8129407952747,
              21.02199493079761
            ],
            [
              105.81293121642867,
              21.022069203823047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7253",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021980356284303,
          "to_latitude": 21.022069203823047,
          "to_longitude": 105.81293121642867,
          "from_longitude": 105.81294703839889
        }
      },
      {
        "id": 7246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81253475804499,
            21.021938624455935,
            105.81254763329905,
            21.022031628992757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81254763329905,
              21.021938624455935
            ],
            [
              105.81254634157104,
              21.021946652191673
            ],
            [
              105.81254544613431,
              21.021950766155207
            ],
            [
              105.81254480599178,
              21.021953705606432
            ],
            [
              105.81253475804499,
              21.022031628992757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7254",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021938624455935,
          "to_latitude": 21.022031628992757,
          "to_longitude": 105.81253475804499,
          "from_longitude": 105.81254763329905
        }
      },
      {
        "id": 7247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81223828917183,
            21.022973263455246,
            105.81235067962272,
            21.022982580899857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81235067962272,
              21.022982580899857
            ],
            [
              105.81226379466369,
              21.02297474126552
            ],
            [
              105.81226285241759,
              21.022974656345927
            ],
            [
              105.81225101129151,
              21.022973587474045
            ],
            [
              105.81224231853787,
              21.022973367051527
            ],
            [
              105.81223828917183,
              21.022973263455246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7255",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022982580899857,
          "to_latitude": 21.022973263455246,
          "to_longitude": 105.81223828917183,
          "from_longitude": 105.81235067962272
        }
      },
      {
        "id": 7248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81351529308283,
            21.023678190416746,
            105.81353131693933,
            21.02379532887856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81351554511876,
              21.023678190416746
            ],
            [
              105.81351535688351,
              21.02368108525745
            ],
            [
              105.81351529308283,
              21.023684928794868
            ],
            [
              105.81353131693933,
              21.023693253065495
            ],
            [
              105.81352105992849,
              21.02379532887856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7256",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023678190416746,
          "to_latitude": 21.02379532887856,
          "to_longitude": 105.81352105992849,
          "from_longitude": 105.81351554511876
        }
      },
      {
        "id": 7249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8150939976433,
            21.023398840255552,
            105.81509549695193,
            21.023412191717874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8150939976433,
              21.023412191717874
            ],
            [
              105.81509417011604,
              21.023410649985898
            ],
            [
              105.81509441605002,
              21.023408464804184
            ],
            [
              105.81509481151726,
              21.023404942129737
            ],
            [
              105.81509549695193,
              21.023398840255552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7257",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023412191717874,
          "to_latitude": 21.023398840255552,
          "to_longitude": 105.81509549695193,
          "from_longitude": 105.8150939976433
        }
      },
      {
        "id": 7250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81324662524379,
            21.020706792166596,
            105.81404183871442,
            21.020785018530148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81324662524379,
              21.020706792166596
            ],
            [
              105.81404183871442,
              21.020785018530148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7258",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020706792166596,
          "to_latitude": 21.020785018530148,
          "to_longitude": 105.81404183871442,
          "from_longitude": 105.81324662524379
        }
      },
      {
        "id": 7251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8124831782813,
            21.02073061190427,
            105.81248762283327,
            21.02076980612376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81248762283327,
              21.02073061190427
            ],
            [
              105.81248638386224,
              21.020740386231008
            ],
            [
              105.8124857039407,
              21.020745748329265
            ],
            [
              105.81248450681358,
              21.02075649884535
            ],
            [
              105.8124831782813,
              21.02076980612376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7259",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02073061190427,
          "to_latitude": 21.02076980612376,
          "to_longitude": 105.8124831782813,
          "from_longitude": 105.81248762283327
        }
      },
      {
        "id": 7252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8129976603702,
            21.02341931453671,
            105.81300290702211,
            21.023456401747712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81300290702211,
              21.02341931453671
            ],
            [
              105.81300215580119,
              21.02343494396223
            ],
            [
              105.81300168800658,
              21.02343702362145
            ],
            [
              105.81299817759829,
              21.0234526165588
            ],
            [
              105.8129976603702,
              21.023456401747712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7260",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02341931453671,
          "to_latitude": 21.023456401747712,
          "to_longitude": 105.8129976603702,
          "from_longitude": 105.81300290702211
        }
      },
      {
        "id": 7253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81228781749941,
            21.020405337449297,
            105.81313097781678,
            21.020492133888204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81228781749941,
              21.020405337449297
            ],
            [
              105.81250305625011,
              21.020425681636247
            ],
            [
              105.81252108226506,
              21.020427389341222
            ],
            [
              105.81252406301851,
              21.020427671361492
            ],
            [
              105.81252549516817,
              21.02042780723039
            ],
            [
              105.81265715930563,
              21.02044024704741
            ],
            [
              105.81266890522036,
              21.020441356996923
            ],
            [
              105.81273385580946,
              21.020448493785825
            ],
            [
              105.81275957212424,
              21.020451320852167
            ],
            [
              105.81279791219744,
              21.020455531854637
            ],
            [
              105.81289448336877,
              21.020466144845102
            ],
            [
              105.81289819013138,
              21.020466552550765
            ],
            [
              105.81313097781678,
              21.020492133888204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7261",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020405337449297,
          "to_latitude": 21.020492133888204,
          "to_longitude": 105.81313097781678,
          "from_longitude": 105.81228781749941
        }
      },
      {
        "id": 7254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8145943834139,
            21.02131856627042,
            105.81463933190747,
            21.021458733809116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8146026441853,
              21.02131856627042
            ],
            [
              105.8145943834139,
              21.021382618460294
            ],
            [
              105.81463933190747,
              21.021390961268388
            ],
            [
              105.81463898394777,
              21.02139349106831
            ],
            [
              105.81463707670908,
              21.0214073692591
            ],
            [
              105.81462919434063,
              21.021458733809116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7262",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02131856627042,
          "to_latitude": 21.021458733809116,
          "to_longitude": 105.81462919434063,
          "from_longitude": 105.8146026441853
        }
      },
      {
        "id": 7255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8118655685231,
            21.023324844065893,
            105.81232602767051,
            21.02336290596552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81232602767051,
              21.02336290596552
            ],
            [
              105.81211017030996,
              21.023342442695725
            ],
            [
              105.81210714329515,
              21.023342155471415
            ],
            [
              105.81209140859889,
              21.02334066350229
            ],
            [
              105.81208758176048,
              21.02334030061257
            ],
            [
              105.81193183158857,
              21.023325534891978
            ],
            [
              105.81192319950617,
              21.023325330422182
            ],
            [
              105.81190247464386,
              21.023324844065893
            ],
            [
              105.81189878691734,
              21.023325299735298
            ],
            [
              105.8118655685231,
              21.023329401797312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7263",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02336290596552,
          "to_latitude": 21.023329401797312,
          "to_longitude": 105.8118655685231,
          "from_longitude": 105.81232602767051
        }
      },
      {
        "id": 7256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81478740862124,
            21.02133837998661,
            105.81484018065022,
            21.02148078980551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.814794881687,
              21.02133837998661
            ],
            [
              105.81478740862124,
              21.0213967236262
            ],
            [
              105.81480186435644,
              21.02140931220634
            ],
            [
              105.8148378780622,
              21.02144067586548
            ],
            [
              105.81484018065022,
              21.02148078980551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7264",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02133837998661,
          "to_latitude": 21.02148078980551,
          "to_longitude": 105.81484018065022,
          "from_longitude": 105.814794881687
        }
      },
      {
        "id": 7257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81334600209256,
            21.02345544678897,
            105.813352112212,
            21.023498621373825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.813352112212,
              21.02345544678897
            ],
            [
              105.81335201284864,
              21.02345610481368
            ],
            [
              105.81335094183424,
              21.023468104794983
            ],
            [
              105.81335035818228,
              21.02347117043015
            ],
            [
              105.81334821061681,
              21.023482445752013
            ],
            [
              105.81334600209256,
              21.023498621373825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7265",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02345544678897,
          "to_latitude": 21.023498621373825,
          "to_longitude": 105.81334600209256,
          "from_longitude": 105.813352112212
        }
      },
      {
        "id": 7258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81372405114458,
            21.023496419727977,
            105.81374811292905,
            21.023535683432463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81374811292905,
              21.023496419727977
            ],
            [
              105.81374661636208,
              21.023510298653246
            ],
            [
              105.81374554819402,
              21.023510764409895
            ],
            [
              105.81372624063148,
              21.023519198091044
            ],
            [
              105.81372405114458,
              21.023535683432463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7266",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023496419727977,
          "to_latitude": 21.023535683432463,
          "to_longitude": 105.81372405114458,
          "from_longitude": 105.81374811292905
        }
      },
      {
        "id": 7259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81349565988977,
            21.022367870380663,
            105.81349985773768,
            21.022400896196245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81349985773768,
              21.022367870380663
            ],
            [
              105.81349964274027,
              21.02236955592819
            ],
            [
              105.81349938254246,
              21.02237159846673
            ],
            [
              105.81349868139434,
              21.022377120540025
            ],
            [
              105.81349565988977,
              21.022400896196245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7267",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022367870380663,
          "to_latitude": 21.022400896196245,
          "to_longitude": 105.81349565988977,
          "from_longitude": 105.81349985773768
        }
      },
      {
        "id": 7260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81376876305784,
            21.021184599591024,
            105.81377504390595,
            21.021229370052374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81377465365337,
              21.021184599591024
            ],
            [
              105.81377416773427,
              21.021188299729324
            ],
            [
              105.81377387027437,
              21.0211905655439
            ],
            [
              105.81377504390595,
              21.021193760064037
            ],
            [
              105.81377495156103,
              21.021194824507884
            ],
            [
              105.8137748247991,
              21.0211962829236
            ],
            [
              105.81377358464293,
              21.021199087958998
            ],
            [
              105.81376876305784,
              21.021229370052374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7268",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021184599591024,
          "to_latitude": 21.021229370052374,
          "to_longitude": 105.81376876305784,
          "from_longitude": 105.81377465365337
        }
      },
      {
        "id": 7261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81235862009153,
            21.024319790614424,
            105.81246422232299,
            21.024544755869616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81235862009153,
              21.024332708237896
            ],
            [
              105.81242775611987,
              21.024319790614424
            ],
            [
              105.8124303214538,
              21.024335533356037
            ],
            [
              105.81244797636428,
              21.024443887792945
            ],
            [
              105.81246117650466,
              21.024524943587952
            ],
            [
              105.81246422232299,
              21.02454360778023
            ],
            [
              105.81243924642305,
              21.024544755869616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_La Thành",
          "maTaiSan": "04.O9C.DODV.7269",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024332708237896,
          "to_latitude": 21.024544755869616,
          "to_longitude": 105.81243924642305,
          "from_longitude": 105.81235862009153
        }
      },
      {
        "id": 7262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81279272996198,
            21.02339775847033,
            105.81279457886149,
            21.023413028237425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81279272996198,
              21.023413028237425
            ],
            [
              105.81279310464373,
              21.023409920247584
            ],
            [
              105.81279359638827,
              21.023405869632057
            ],
            [
              105.81279402086668,
              21.023402364887378
            ],
            [
              105.81279457886149,
              21.02339775847033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7270",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023413028237425,
          "to_latitude": 21.02339775847033,
          "to_longitude": 105.81279457886149,
          "from_longitude": 105.81279272996198
        }
      },
      {
        "id": 7263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81258216129932,
            21.02392520605609,
            105.81259394696636,
            21.023992883675515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81258216129932,
              21.02392520605609
            ],
            [
              105.81258248896525,
              21.023927089526268
            ],
            [
              105.81259394696636,
              21.023992883675515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7271",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02392520605609,
          "to_latitude": 21.023992883675515,
          "to_longitude": 105.81259394696636,
          "from_longitude": 105.81258216129932
        }
      },
      {
        "id": 7264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337955041052,
            21.02114645383331,
            105.81338144488743,
            21.021188944067305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81338144488743,
              21.02114645383331
            ],
            [
              105.81337965545933,
              21.021154938278144
            ],
            [
              105.81337955041052,
              21.021155434652435
            ],
            [
              105.81338037477465,
              21.021188944067305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7272",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02114645383331,
          "to_latitude": 21.021188944067305,
          "to_longitude": 105.81338037477465,
          "from_longitude": 105.81338144488743
        }
      },
      {
        "id": 7265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81225159520315,
            21.022868458024014,
            105.81227772183863,
            21.022871481901692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81225159520315,
              21.022868458024014
            ],
            [
              105.81225314978985,
              21.022868638471184
            ],
            [
              105.81225585563689,
              21.022868952517328
            ],
            [
              105.81226215193692,
              21.022869680256075
            ],
            [
              105.81227772183863,
              21.022871481901692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7273",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022868458024014,
          "to_latitude": 21.022871481901692,
          "to_longitude": 105.81227772183863,
          "from_longitude": 105.81225159520315
        }
      },
      {
        "id": 7266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81242437985993,
            21.02337342304883,
            105.81309383250108,
            21.023445402242718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81242437985993,
              21.02337342304883
            ],
            [
              105.8125873157152,
              21.023390942247698
            ],
            [
              105.81258905600606,
              21.023391129030205
            ],
            [
              105.81260686615009,
              21.02339305452154
            ],
            [
              105.81260794613624,
              21.023393171297258
            ],
            [
              105.81275277445191,
              21.023408732846686
            ],
            [
              105.81279272996198,
              21.023413028237425
            ],
            [
              105.812931104457,
              21.02342790602324
            ],
            [
              105.81297646426056,
              21.023433765219526
            ],
            [
              105.81300168800658,
              21.02343702362145
            ],
            [
              105.81301237165951,
              21.02343840326371
            ],
            [
              105.81308543423667,
              21.023444499422762
            ],
            [
              105.81309383250108,
              21.023445402242718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7274",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02337342304883,
          "to_latitude": 21.023445402242718,
          "to_longitude": 105.81309383250108,
          "from_longitude": 105.81242437985993
        }
      },
      {
        "id": 7267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81335371312004,
            21.02366514723427,
            105.81336005395747,
            21.023732823866794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81336005395747,
              21.02366514723427
            ],
            [
              105.81335978024623,
              21.023668068677257
            ],
            [
              105.81335944378998,
              21.023671659711784
            ],
            [
              105.8133577787618,
              21.023689426027296
            ],
            [
              105.8133549190491,
              21.02369373545257
            ],
            [
              105.81335697403198,
              21.02369802227749
            ],
            [
              105.81335371312004,
              21.023732823866794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7275",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02366514723427,
          "to_latitude": 21.023732823866794,
          "to_longitude": 105.81335371312004,
          "from_longitude": 105.81336005395747
        }
      },
      {
        "id": 7268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81326863431909,
            21.02169460102126,
            105.81327187819247,
            21.021718708320456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81326863431909,
              21.021718708320456
            ],
            [
              105.81326895423747,
              21.02171632497971
            ],
            [
              105.81326972917033,
              21.021710571327738
            ],
            [
              105.81327023776382,
              21.021706792501494
            ],
            [
              105.81327187819247,
              21.02169460102126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7276",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021718708320456,
          "to_latitude": 21.02169460102126,
          "to_longitude": 105.81327187819247,
          "from_longitude": 105.81326863431909
        }
      },
      {
        "id": 7269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81392385681156,
            21.023710786221244,
            105.81458152589923,
            21.02377581768092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81392385681156,
              21.023710786221244
            ],
            [
              105.81402702814205,
              21.02372098756251
            ],
            [
              105.81402761816148,
              21.02372104615797
            ],
            [
              105.81404647660288,
              21.023722911382574
            ],
            [
              105.81404956432988,
              21.02372321634599
            ],
            [
              105.81417498408045,
              21.02373561885151
            ],
            [
              105.81419383000956,
              21.023737482313095
            ],
            [
              105.81419446526607,
              21.02373754520757
            ],
            [
              105.81423454607244,
              21.02374150817155
            ],
            [
              105.81427305029511,
              21.023745316093343
            ],
            [
              105.81432412392023,
              21.023750365374863
            ],
            [
              105.8143251480324,
              21.02375046705221
            ],
            [
              105.81434251940152,
              21.02375218491202
            ],
            [
              105.81434351174913,
              21.02375228312845
            ],
            [
              105.81446967652032,
              21.02376475862226
            ],
            [
              105.81447002687271,
              21.023764793073557
            ],
            [
              105.81448482642017,
              21.0237662567828
            ],
            [
              105.81448521238501,
              21.023766294676435
            ],
            [
              105.81458152589923,
              21.02377581768092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7277",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023710786221244,
          "to_latitude": 21.02377581768092,
          "to_longitude": 105.81458152589923,
          "from_longitude": 105.81392385681156
        }
      },
      {
        "id": 7270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81499939238579,
            21.023390766503564,
            105.81501120678968,
            21.023451608494717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81499939238579,
              21.023451608494717
            ],
            [
              105.81500294501039,
              21.023417274260527
            ],
            [
              105.81500776499533,
              21.023409032700055
            ],
            [
              105.8150086738375,
              21.023404210531577
            ],
            [
              105.81501120678968,
              21.023390766503564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7278",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023451608494717,
          "to_latitude": 21.023390766503564,
          "to_longitude": 105.81501120678968,
          "from_longitude": 105.81499939238579
        }
      },
      {
        "id": 7271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81424786233707,
            21.02220563652647,
            105.81425163271294,
            21.022236747274537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424786233707,
              21.022236747274537
            ],
            [
              105.81425039651823,
              21.0222210397611
            ],
            [
              105.81425105446264,
              21.02221695583017
            ],
            [
              105.81425120733365,
              21.022209145794672
            ],
            [
              105.81425163271294,
              21.02220563652647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7279",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022236747274537,
          "to_latitude": 21.02220563652647,
          "to_longitude": 105.81425163271294,
          "from_longitude": 105.81424786233707
        }
      },
      {
        "id": 7272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81422706115882,
            21.021688586029125,
            105.81478682851365,
            21.021718570688673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81422706115882,
              21.021688586029125
            ],
            [
              105.81424816226084,
              21.021690019599113
            ],
            [
              105.81435524517804,
              21.021699268119015
            ],
            [
              105.81447206191861,
              21.021708964026374
            ],
            [
              105.81450668356229,
              21.02171002843291
            ],
            [
              105.8145074943778,
              21.021699555241856
            ],
            [
              105.81465009420562,
              21.021714938018533
            ],
            [
              105.81478682851365,
              21.021718570688673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7280",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021688586029125,
          "to_latitude": 21.021718570688673,
          "to_longitude": 105.81478682851365,
          "from_longitude": 105.81422706115882
        }
      },
      {
        "id": 7273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81532688562372,
            21.02314271647698,
            105.81534766931837,
            21.023391104197042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81534766931837,
              21.02314271647698
            ],
            [
              105.81532688562372,
              21.023391104197042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7281",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02314271647698,
          "to_latitude": 21.023391104197042,
          "to_longitude": 105.81532688562372,
          "from_longitude": 105.81534766931837
        }
      },
      {
        "id": 7274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81344003532233,
            21.02049773981929,
            105.81345313183985,
            21.020554150446088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81344238306451,
              21.02049773981929
            ],
            [
              105.81344161704746,
              21.020511696543462
            ],
            [
              105.81344039898117,
              21.020521766131104
            ],
            [
              105.81344003532233,
              21.020524774715078
            ],
            [
              105.81345313183985,
              21.020536521785896
            ],
            [
              105.8134513554704,
              21.020554150446088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7282",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02049773981929,
          "to_latitude": 21.020554150446088,
          "to_longitude": 105.8134513554704,
          "from_longitude": 105.81344238306451
        }
      },
      {
        "id": 7275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81277558368149,
            21.023797662873495,
            105.81310775797373,
            21.02388172277249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81310775797373,
              21.02385812323113
            ],
            [
              105.81309195990018,
              21.023857373209122
            ],
            [
              105.81297180933551,
              21.023869525148495
            ],
            [
              105.81285001975947,
              21.02388172277249
            ],
            [
              105.81284731695904,
              21.023869375926235
            ],
            [
              105.81278084833174,
              21.023876885989445
            ],
            [
              105.81277558368149,
              21.023877481085385
            ],
            [
              105.81278295563645,
              21.023797662873495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7283",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02385812323113,
          "to_latitude": 21.023797662873495,
          "to_longitude": 105.81278295563645,
          "from_longitude": 105.81310775797373
        }
      },
      {
        "id": 7276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81461749261753,
            21.022244107191128,
            105.8146205061047,
            21.022268971213105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81461749261753,
              21.022268971213105
            ],
            [
              105.81461881381952,
              21.022258066544886
            ],
            [
              105.81461942053562,
              21.022253054340528
            ],
            [
              105.81461967378306,
              21.022250974801636
            ],
            [
              105.8146205061047,
              21.022244107191128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7284",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022268971213105,
          "to_latitude": 21.022244107191128,
          "to_longitude": 105.8146205061047,
          "from_longitude": 105.81461749261753
        }
      },
      {
        "id": 7277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81309003328161,
            21.0214330999445,
            105.81357066385637,
            21.021478234692513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81309003328161,
              21.0214330999445
            ],
            [
              105.81309012856389,
              21.021433108521293
            ],
            [
              105.81316067344265,
              21.021444669496958
            ],
            [
              105.81331565382224,
              21.02146364415776
            ],
            [
              105.81332796205925,
              21.0214635943359
            ],
            [
              105.81332896153324,
              21.021463590459927
            ],
            [
              105.81333330476552,
              21.021463572401387
            ],
            [
              105.81335675823331,
              21.021463477412375
            ],
            [
              105.81357066385637,
              21.021478234692513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7285",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0214330999445,
          "to_latitude": 21.021478234692513,
          "to_longitude": 105.81357066385637,
          "from_longitude": 105.81309003328161
        }
      },
      {
        "id": 7278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81311753651002,
            21.020510109302133,
            105.81313734349537,
            21.020678472598757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81311753651002,
              21.020678472598757
            ],
            [
              105.81313734349537,
              21.020510109302133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7286",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020678472598757,
          "to_latitude": 21.020510109302133,
          "to_longitude": 105.81313734349537,
          "from_longitude": 105.81311753651002
        }
      },
      {
        "id": 7279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81459931582133,
            21.020881693442817,
            105.8146359832384,
            21.021179040804622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81459931582133,
              21.021179040804622
            ],
            [
              105.81459992208829,
              21.02117412344084
            ],
            [
              105.8146359832384,
              21.020881693442817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7287",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021179040804622,
          "to_latitude": 21.020881693442817,
          "to_longitude": 105.8146359832384,
          "from_longitude": 105.81459931582133
        }
      },
      {
        "id": 7280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81335288694781,
            21.02114477181798,
            105.81336411057876,
            21.021186401957618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81336411057876,
              21.02114477181798
            ],
            [
              105.8133637943741,
              21.021152095796246
            ],
            [
              105.81336372603901,
              21.02115333083515
            ],
            [
              105.81336361546431,
              21.021155291367812
            ],
            [
              105.81335586434517,
              21.021162651801962
            ],
            [
              105.81335288694781,
              21.021186401957618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7288",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02114477181798,
          "to_latitude": 21.021186401957618,
          "to_longitude": 105.81335288694781,
          "from_longitude": 105.81336411057876
        }
      },
      {
        "id": 7281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8128761807424,
            21.02077122103931,
            105.81288102550882,
            21.020811454987356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81288102550882,
              21.02077122103931
            ],
            [
              105.81287981830378,
              21.020782414187128
            ],
            [
              105.8128793722149,
              21.020786544972896
            ],
            [
              105.8128761807424,
              21.020811454987356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7289",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02077122103931,
          "to_latitude": 21.020811454987356,
          "to_longitude": 105.8128761807424,
          "from_longitude": 105.81288102550882
        }
      },
      {
        "id": 7282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81426129068957,
            21.0212887818878,
            105.81428904251686,
            21.02142027291544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81428904251686,
              21.0212887818878
            ],
            [
              105.8142809038574,
              21.021351784834707
            ],
            [
              105.81427748323064,
              21.02135836948016
            ],
            [
              105.81426702048574,
              21.02137851276547
            ],
            [
              105.81426129068957,
              21.02142027291544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7290",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0212887818878,
          "to_latitude": 21.02142027291544,
          "to_longitude": 105.81426129068957,
          "from_longitude": 105.81428904251686
        }
      },
      {
        "id": 7283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81312448544037,
            21.021129190634277,
            105.81398592745757,
            21.02121611117547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81312448544037,
              21.021129190634277
            ],
            [
              105.81312534880496,
              21.02112927773298
            ],
            [
              105.81335927638735,
              21.021152881531204
            ],
            [
              105.81336372603901,
              21.02115333083515
            ],
            [
              105.81337965545933,
              21.021154938278144
            ],
            [
              105.81338693871639,
              21.021155672999598
            ],
            [
              105.81350948168071,
              21.02116803743046
            ],
            [
              105.81352239943413,
              21.021169341327855
            ],
            [
              105.813750601215,
              21.021192371323206
            ],
            [
              105.81377265887983,
              21.021194593411767
            ],
            [
              105.81377495156103,
              21.021194824507884
            ],
            [
              105.81377543569695,
              21.02119487277237
            ],
            [
              105.81398592745757,
              21.02121611117547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7291",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021129190634277,
          "to_latitude": 21.02121611117547,
          "to_longitude": 105.81398592745757,
          "from_longitude": 105.81312448544037
        }
      },
      {
        "id": 7284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81439884466913,
            21.02058203608701,
            105.81440924234607,
            21.020650459287616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81440924234607,
              21.02058203608701
            ],
            [
              105.81440383953932,
              21.02061278794788
            ],
            [
              105.81440224277718,
              21.020623967613794
            ],
            [
              105.81440078224603,
              21.02063419372243
            ],
            [
              105.81439884466913,
              21.020650459287616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7292",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02058203608701,
          "to_latitude": 21.020650459287616,
          "to_longitude": 105.81439884466913,
          "from_longitude": 105.81440924234607
        }
      },
      {
        "id": 7285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81384441418828,
            21.020540169734286,
            105.8138508327417,
            21.020593471001195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8138508327417,
              21.020540169734286
            ],
            [
              105.81384773445157,
              21.02055811272284
            ],
            [
              105.81384737018378,
              21.020563134604643
            ],
            [
              105.81384630948604,
              21.020577734934232
            ],
            [
              105.81384441418828,
              21.020593471001195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7293",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020540169734286,
          "to_latitude": 21.020593471001195,
          "to_longitude": 105.81384441418828,
          "from_longitude": 105.8138508327417
        }
      },
      {
        "id": 7286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81297217598205,
            21.023416670996752,
            105.81297735472624,
            21.023453154526642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81297735472624,
              21.023416670996752
            ],
            [
              105.81297700883569,
              21.023431297708775
            ],
            [
              105.81297646426056,
              21.023433765219526
            ],
            [
              105.81297217598205,
              21.023453154526642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7294",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023416670996752,
          "to_latitude": 21.023453154526642,
          "to_longitude": 105.81297217598205,
          "from_longitude": 105.81297735472624
        }
      },
      {
        "id": 7287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81388113283609,
            21.022225803388814,
            105.81389467061858,
            21.022319771303057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81389467061858,
              21.022225803388814
            ],
            [
              105.81388552870227,
              21.02229896248959
            ],
            [
              105.81388461046438,
              21.022306312831958
            ],
            [
              105.81388410891662,
              21.022310323755125
            ],
            [
              105.81388251447495,
              21.02231538313562
            ],
            [
              105.81388113283609,
              21.022319771303057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7295",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022225803388814,
          "to_latitude": 21.022319771303057,
          "to_longitude": 105.81388113283609,
          "from_longitude": 105.81389467061858
        }
      },
      {
        "id": 7288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81309833512549,
            21.02200630446788,
            105.81310551930005,
            21.022057555427352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81310551930005,
              21.02200630446788
            ],
            [
              105.81309833512549,
              21.022057555427352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7296",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02200630446788,
          "to_latitude": 21.022057555427352,
          "to_longitude": 105.81309833512549,
          "from_longitude": 105.81310551930005
        }
      },
      {
        "id": 7289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81189878691734,
            21.02329196373093,
            105.81190099021391,
            21.023325299735298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81189878691734,
              21.023325299735298
            ],
            [
              105.81190023095148,
              21.02330346453989
            ],
            [
              105.8119006158532,
              21.023297621529412
            ],
            [
              105.81190077068027,
              21.023295302205
            ],
            [
              105.81190099021391,
              21.02329196373093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7297",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023325299735298,
          "to_latitude": 21.02329196373093,
          "to_longitude": 105.81190099021391,
          "from_longitude": 105.81189878691734
        }
      },
      {
        "id": 7290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81473264772494,
            21.02310194210852,
            105.81534766931837,
            21.02314271647698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81473264772494,
              21.02310194210852
            ],
            [
              105.81484224738729,
              21.023110680557224
            ],
            [
              105.81485789319369,
              21.023111925213396
            ],
            [
              105.81486122792266,
              21.023112190140232
            ],
            [
              105.81486308728952,
              21.02311233839345
            ],
            [
              105.81503437524795,
              21.02312600000091
            ],
            [
              105.81504630137528,
              21.023126950851196
            ],
            [
              105.81504952350595,
              21.023127207281636
            ],
            [
              105.81505210852725,
              21.023127413474754
            ],
            [
              105.81514927298153,
              21.023135160862573
            ],
            [
              105.81515367732108,
              21.023135403495953
            ],
            [
              105.81524003105872,
              21.023140161478935
            ],
            [
              105.81524359822039,
              21.02314035844533
            ],
            [
              105.81525574985051,
              21.023141028199543
            ],
            [
              105.81534766931837,
              21.02314271647698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7298",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02310194210852,
          "to_latitude": 21.02314271647698,
          "to_longitude": 105.81534766931837,
          "from_longitude": 105.81473264772494
        }
      },
      {
        "id": 7291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81287319059896,
            21.020770915441098,
            105.81287806578356,
            21.020811394309163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81287806578356,
              21.020770915441098
            ],
            [
              105.81287677592418,
              21.0207821225323
            ],
            [
              105.81287636613672,
              21.020785674175634
            ],
            [
              105.81287319059896,
              21.020811394309163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7299",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020770915441098,
          "to_latitude": 21.020811394309163,
          "to_longitude": 105.81287319059896,
          "from_longitude": 105.81287806578356
        }
      },
      {
        "id": 7292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8142962890314,
            21.022687208644516,
            105.81429875413171,
            21.022703768473754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8142962890314,
              21.022703768473754
            ],
            [
              105.8142968514761,
              21.022699990290885
            ],
            [
              105.81429873557433,
              21.022687334281958
            ],
            [
              105.81429875413171,
              21.022687208644516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7300",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022703768473754,
          "to_latitude": 21.022687208644516,
          "to_longitude": 105.81429875413171,
          "from_longitude": 105.8142962890314
        }
      },
      {
        "id": 7293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8152008364189,
            21.023409276007513,
            105.81520445192088,
            21.023444068482167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152008364189,
              21.023444068482167
            ],
            [
              105.81520142501114,
              21.023438379846624
            ],
            [
              105.815202755208,
              21.023425774356546
            ],
            [
              105.81520327675396,
              21.023420670430273
            ],
            [
              105.81520359912024,
              21.02341750397751
            ],
            [
              105.81520401133939,
              21.0234135241897
            ],
            [
              105.8152043970611,
              21.023409801948603
            ],
            [
              105.81520445192088,
              21.023409276007513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7301",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023444068482167,
          "to_latitude": 21.023409276007513,
          "to_longitude": 105.81520445192088,
          "from_longitude": 105.8152008364189
        }
      },
      {
        "id": 7294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81260240754952,
            21.023378446914496,
            105.81260886641329,
            21.023417413288975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81260793944509,
              21.023378446914496
            ],
            [
              105.81260886641329,
              21.023389024698993
            ],
            [
              105.81260686615009,
              21.02339305452154
            ],
            [
              105.81260530456548,
              21.023396198891643
            ],
            [
              105.81260240754952,
              21.023417413288975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7302",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023378446914496,
          "to_latitude": 21.023417413288975,
          "to_longitude": 105.81260240754952,
          "from_longitude": 105.81260793944509
        }
      },
      {
        "id": 7295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81418916111036,
            21.021831028480477,
            105.81496969317345,
            21.02191239879123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81418916111036,
              21.021831028480477
            ],
            [
              105.81437474298373,
              21.02184972289826
            ],
            [
              105.81437483293513,
              21.021850166855444
            ],
            [
              105.81437508040774,
              21.021850211735153
            ],
            [
              105.81437561868518,
              21.02185030941614
            ],
            [
              105.81437624545418,
              21.02184987376929
            ],
            [
              105.81439687390468,
              21.021851952681043
            ],
            [
              105.81439714430303,
              21.02185356184074
            ],
            [
              105.81439808908974,
              21.0218537614464
            ],
            [
              105.81439957120665,
              21.02185406144719
            ],
            [
              105.81440100401882,
              21.02185236826126
            ],
            [
              105.81447543756093,
              21.02185986631442
            ],
            [
              105.81457698607035,
              21.021870659517536
            ],
            [
              105.81464281027084,
              21.021877339758888
            ],
            [
              105.8147595011352,
              21.021890043813993
            ],
            [
              105.8147608394209,
              21.021891314566723
            ],
            [
              105.81476214332193,
              21.021891391417633
            ],
            [
              105.81476400246258,
              21.021891500834677
            ],
            [
              105.81476539674833,
              21.021890684864044
            ],
            [
              105.8147886074103,
              21.02189316323013
            ],
            [
              105.81479023403261,
              21.021893324341466
            ],
            [
              105.81479066231869,
              21.021894070162002
            ],
            [
              105.81479178463103,
              21.02189418852781
            ],
            [
              105.8147926971095,
              21.021894284414888
            ],
            [
              105.81479363562455,
              21.021893683789
            ],
            [
              105.81496969317345,
              21.02191239879123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7303",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021831028480477,
          "to_latitude": 21.02191239879123,
          "to_longitude": 105.81496969317345,
          "from_longitude": 105.81418916111036
        }
      },
      {
        "id": 7296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81353481760688,
            21.02347457279005,
            105.81353696244727,
            21.02349113469045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81353481760688,
              21.02349113469045
            ],
            [
              105.8135350290169,
              21.023489497031267
            ],
            [
              105.81353555347776,
              21.023485451677058
            ],
            [
              105.81353600589469,
              21.023481957636125
            ],
            [
              105.81353696244727,
              21.02347457279005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7304",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349113469045,
          "to_latitude": 21.02347457279005,
          "to_longitude": 105.81353696244727,
          "from_longitude": 105.81353481760688
        }
      },
      {
        "id": 7297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81206893390747,
            21.021621665691654,
            105.81211301167738,
            21.021977120132743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81211301167738,
              21.021621665691654
            ],
            [
              105.8121005348427,
              21.02182185163132
            ],
            [
              105.81210005866981,
              21.021829490695463
            ],
            [
              105.81209961434391,
              21.02183661567094
            ],
            [
              105.81209841159117,
              21.021855925196967
            ],
            [
              105.81206893390747,
              21.021977120132743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7305",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021621665691654,
          "to_latitude": 21.021977120132743,
          "to_longitude": 105.81206893390747,
          "from_longitude": 105.81211301167738
        }
      },
      {
        "id": 7298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81407761584188,
            21.02266170783557,
            105.8145296319633,
            21.02271383310365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81407761584188,
              21.02266170783557
            ],
            [
              105.81417215643958,
              21.02267260977523
            ],
            [
              105.81418383937569,
              21.022673957464892
            ],
            [
              105.81429875413171,
              21.022687208644516
            ],
            [
              105.81448127246338,
              21.022708255133676
            ],
            [
              105.81448957575743,
              21.022709213437302
            ],
            [
              105.8145296319633,
              21.02271383310365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7306",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02266170783557,
          "to_latitude": 21.02271383310365,
          "to_longitude": 105.8145296319633,
          "from_longitude": 105.81407761584188
        }
      },
      {
        "id": 7299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81464281027084,
            21.021868861114026,
            105.81464414080102,
            21.021877339758888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81464281027084,
              21.021877339758888
            ],
            [
              105.8146429046813,
              21.021875948336636
            ],
            [
              105.81464414080102,
              21.021868861114026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7307",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021877339758888,
          "to_latitude": 21.021868861114026,
          "to_longitude": 105.81464414080102,
          "from_longitude": 105.81464281027084
        }
      },
      {
        "id": 7300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81313914273908,
            21.02080830228521,
            105.81316237008151,
            21.021008321092157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81313914273908,
              21.021008321092157
            ],
            [
              105.81316237008151,
              21.02080830228521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7308",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021008321092157,
          "to_latitude": 21.02080830228521,
          "to_longitude": 105.81316237008151,
          "from_longitude": 105.81313914273908
        }
      },
      {
        "id": 7301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81271807597989,
            21.022313756919683,
            105.81272658740785,
            21.02235585728607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81272658740785,
              21.022313756919683
            ],
            [
              105.81272608108797,
              21.022319910318963
            ],
            [
              105.81272447321254,
              21.022323208460048
            ],
            [
              105.81272093110785,
              21.02233047017389
            ],
            [
              105.81271807597989,
              21.02235585728607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7309",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022313756919683,
          "to_latitude": 21.02235585728607,
          "to_longitude": 105.81271807597989,
          "from_longitude": 105.81272658740785
        }
      },
      {
        "id": 7302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81374983613243,
            21.02221107457262,
            105.8137598650067,
            21.02230586930518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8137598650067,
              21.02221107457262
            ],
            [
              105.81375191507752,
              21.022287098421927
            ],
            [
              105.81375081853523,
              21.02229757839662
            ],
            [
              105.81374983613243,
              21.02230586930518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7310",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02221107457262,
          "to_latitude": 21.02230586930518,
          "to_longitude": 105.81374983613243,
          "from_longitude": 105.8137598650067
        }
      },
      {
        "id": 7303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8141578614207,
            21.02156923648298,
            105.81418267481217,
            21.021802905480435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8141578614207,
              21.021802905480435
            ],
            [
              105.81415866771854,
              21.021795316317203
            ],
            [
              105.81418267481217,
              21.02156923648298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7311",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021802905480435,
          "to_latitude": 21.02156923648298,
          "to_longitude": 105.81418267481217,
          "from_longitude": 105.8141578614207
        }
      },
      {
        "id": 7304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81441571504543,
            21.021438749469727,
            105.81443803222611,
            21.02148900261034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81443803222611,
              21.021438749469727
            ],
            [
              105.8144312611838,
              21.021481669837467
            ],
            [
              105.81442353033282,
              21.021485316150923
            ],
            [
              105.81441571504543,
              21.02148900261034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7312",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021438749469727,
          "to_latitude": 21.02148900261034,
          "to_longitude": 105.81441571504543,
          "from_longitude": 105.81443803222611
        }
      },
      {
        "id": 7305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81488590618255,
            21.021171688675047,
            105.81549727060518,
            21.021233077311393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81488590618255,
              21.021171688675047
            ],
            [
              105.81528694995788,
              21.021218212368233
            ],
            [
              105.81530333312188,
              21.021220112810678
            ],
            [
              105.81544059101634,
              21.021233077311393
            ],
            [
              105.81549727060518,
              21.021216619742003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7313",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021171688675047,
          "to_latitude": 21.021216619742003,
          "to_longitude": 105.81549727060518,
          "from_longitude": 105.81488590618255
        }
      },
      {
        "id": 7306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81442136001267,
            21.02222371130579,
            105.81442494160402,
            21.022253264892036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81442136001267,
              21.022253264892036
            ],
            [
              105.81442295591171,
              21.022238381999724
            ],
            [
              105.81442323602924,
              21.022235758589517
            ],
            [
              105.8144247049774,
              21.022225663409824
            ],
            [
              105.81442494160402,
              21.02222371130579
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7314",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022253264892036,
          "to_latitude": 21.02222371130579,
          "to_longitude": 105.81442494160402,
          "from_longitude": 105.81442136001267
        }
      },
      {
        "id": 7307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81797422421863,
            21.02254852972918,
            105.8179910285715,
            21.02262213775771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8179910285715,
              21.02262213775771
            ],
            [
              105.81797422421863,
              21.02254852972918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Thành Công",
          "maTaiSan": "04.O9C.DODV.7315",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02262213775771,
          "to_latitude": 21.02254852972918,
          "to_longitude": 105.81797422421863,
          "from_longitude": 105.8179910285715
        }
      },
      {
        "id": 7308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730502135473,
            21.02242717259169,
            105.81739735630823,
            21.022459303315717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81730502135473,
              21.022459303315717
            ],
            [
              105.81730652399368,
              21.022458777642903
            ],
            [
              105.8173066104075,
              21.022458747421183
            ],
            [
              105.81739735630823,
              21.02242717259169
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Thành Công",
          "maTaiSan": "04.O9C.DODV.7316",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022459303315717,
          "to_latitude": 21.02242717259169,
          "to_longitude": 105.81739735630823,
          "from_longitude": 105.81730502135473
        }
      },
      {
        "id": 7309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81863841843607,
            21.023206450827995,
            105.81870672742886,
            21.023533104461897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81870672742886,
              21.023533104461897
            ],
            [
              105.81870579860073,
              21.02352895951066
            ],
            [
              105.81870319600944,
              21.02352025229052
            ],
            [
              105.81867803367751,
              21.02343611954417
            ],
            [
              105.81866038621443,
              21.023344723002023
            ],
            [
              105.81866315063007,
              21.02332845161886
            ],
            [
              105.81866308050967,
              21.023320190143643
            ],
            [
              105.81866414007662,
              21.023310928778383
            ],
            [
              105.81865716786429,
              21.023282614575017
            ],
            [
              105.81865436228993,
              21.023269324456237
            ],
            [
              105.81863841843607,
              21.023206450827995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_La Thành",
          "maTaiSan": "04.O9C.DODV.7317",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023533104461897,
          "to_latitude": 21.023206450827995,
          "to_longitude": 105.81863841843607,
          "from_longitude": 105.81870672742886
        }
      },
      {
        "id": 7310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81634241182539,
            21.020258559088475,
            105.81646015082853,
            21.020380717925626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81646015082853,
              21.020371404099095
            ],
            [
              105.8164449610416,
              21.020380717925626
            ],
            [
              105.81635158018356,
              21.02026948297509
            ],
            [
              105.81634241182539,
              21.020258559088475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7318",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020371404099095,
          "to_latitude": 21.020258559088475,
          "to_longitude": 105.81634241182539,
          "from_longitude": 105.81646015082853
        }
      },
      {
        "id": 7311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81708869797754,
            21.020622515143767,
            105.81713440004975,
            21.020652749825643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81713440004975,
              21.020622515143767
            ],
            [
              105.81712721959988,
              21.020627757645176
            ],
            [
              105.81711920555331,
              21.020633609312902
            ],
            [
              105.8171148015917,
              21.020633775888406
            ],
            [
              105.81708869797754,
              21.020652749825643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7319",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020622515143767,
          "to_latitude": 21.020652749825643,
          "to_longitude": 105.81708869797754,
          "from_longitude": 105.81713440004975
        }
      },
      {
        "id": 7312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81739712140113,
            21.020729567370267,
            105.81743217421202,
            21.020754896111182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743217421202,
              21.020729567370267
            ],
            [
              105.81740511763823,
              21.02074913122593
            ],
            [
              105.81740410672738,
              21.020749859568728
            ],
            [
              105.81739712140113,
              21.020754896111182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7320",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020729567370267,
          "to_latitude": 21.020754896111182,
          "to_longitude": 105.81739712140113,
          "from_longitude": 105.81743217421202
        }
      },
      {
        "id": 7313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8166462723196,
            21.021277079096,
            105.8168204745064,
            21.02145039627928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8166462723196,
              21.02145039627928
            ],
            [
              105.8168204745064,
              21.021310468265746
            ],
            [
              105.8167957468456,
              21.021277079096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7321",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02145039627928,
          "to_latitude": 21.021277079096,
          "to_longitude": 105.8167957468456,
          "from_longitude": 105.8166462723196
        }
      },
      {
        "id": 7314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81811226819688,
            21.023020052750955,
            105.8183494049136,
            21.023091069898424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8183494049136,
              21.023020052750955
            ],
            [
              105.81825712729248,
              21.023056897658023
            ],
            [
              105.81811226819688,
              21.023091069898424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Thành Công",
          "maTaiSan": "04.O9C.DODV.7322",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023020052750955,
          "to_latitude": 21.023091069898424,
          "to_longitude": 105.81811226819688,
          "from_longitude": 105.8183494049136
        }
      },
      {
        "id": 7315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81668197624698,
            21.02382822436732,
            105.81668857039826,
            21.023871083329237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81668857039826,
              21.023871083329237
            ],
            [
              105.81668602666453,
              21.023857090109765
            ],
            [
              105.81668197624698,
              21.02382822436732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9c.44_La Thành",
          "maTaiSan": "04.O9C.DODV.7323",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023871083329237,
          "to_latitude": 21.02382822436732,
          "to_longitude": 105.81668197624698,
          "from_longitude": 105.81668857039826
        }
      },
      {
        "id": 7316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8168988104051,
            21.020070452210646,
            105.81697920843771,
            21.020127033516776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81697920843771,
              21.020070452210646
            ],
            [
              105.81691211061221,
              21.02011750291895
            ],
            [
              105.81691069135671,
              21.02011849786547
            ],
            [
              105.81690874888001,
              21.020120039067468
            ],
            [
              105.81690499140757,
              21.020122683697263
            ],
            [
              105.8168988104051,
              21.020127033516776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7324",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020070452210646,
          "to_latitude": 21.020127033516776,
          "to_longitude": 105.8168988104051,
          "from_longitude": 105.81697920843771
        }
      },
      {
        "id": 7317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81956133276995,
            21.023241522306346,
            105.81962542133819,
            21.02326300186476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81962542133819,
              21.023241522306346
            ],
            [
              105.81962009206734,
              21.023243308376607
            ],
            [
              105.81961122243301,
              21.023246281185845
            ],
            [
              105.81956133276995,
              21.02326300186476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7325",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023241522306346,
          "to_latitude": 21.02326300186476,
          "to_longitude": 105.81956133276995,
          "from_longitude": 105.81962542133819
        }
      },
      {
        "id": 7318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81604360096362,
            21.022531943318626,
            105.81617936685413,
            21.02296741140402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81617936685413,
              21.02296741140402
            ],
            [
              105.81610861137752,
              21.022736168049295
            ],
            [
              105.8160908733345,
              21.022678197343073
            ],
            [
              105.81607063874951,
              21.02261559331633
            ],
            [
              105.81604360096362,
              21.022531943318626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.73_Thành Công",
          "maTaiSan": "04.O9C.DODV.7326",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02296741140402,
          "to_latitude": 21.022531943318626,
          "to_longitude": 105.81604360096362,
          "from_longitude": 105.81617936685413
        }
      },
      {
        "id": 7319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690689574597,
            21.02053371333303,
            105.81694397656123,
            21.020574272592107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81690689574597,
              21.02053371333303
            ],
            [
              105.81692227307876,
              21.020552471765715
            ],
            [
              105.81692287484847,
              21.020557691334538
            ],
            [
              105.81692878625931,
              21.020560534302607
            ],
            [
              105.81694019818309,
              21.020569373570776
            ],
            [
              105.81694397656123,
              21.020574272592107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7327",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02053371333303,
          "to_latitude": 21.020574272592107,
          "to_longitude": 105.81694397656123,
          "from_longitude": 105.81690689574597
        }
      },
      {
        "id": 7320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81717570137884,
            21.02161458020222,
            105.81720705140981,
            21.021651670530833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81717570137884,
              21.02161458020222
            ],
            [
              105.81720705140981,
              21.021651670530833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Thành Công",
          "maTaiSan": "04.O9C.DODV.7328",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02161458020222,
          "to_latitude": 21.021651670530833,
          "to_longitude": 105.81720705140981,
          "from_longitude": 105.81717570137884
        }
      },
      {
        "id": 7321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81736050317916,
            21.02096581399757,
            105.81740108103624,
            21.02099531057851
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81740108103624,
              21.02096581399757
            ],
            [
              105.81739862293105,
              21.020967707231904
            ],
            [
              105.81739853573343,
              21.020967769973605
            ],
            [
              105.81736050317916,
              21.02099531057851
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7329",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02096581399757,
          "to_latitude": 21.02099531057851,
          "to_longitude": 105.81736050317916,
          "from_longitude": 105.81740108103624
        }
      },
      {
        "id": 7322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81909263535404,
            21.023314360930616,
            105.81911284006344,
            21.023469328631016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81911284006344,
              21.023469328631016
            ],
            [
              105.81911263070786,
              21.023456187695135
            ],
            [
              105.81911122590492,
              21.023452787490818
            ],
            [
              105.81911043137912,
              21.02345086473542
            ],
            [
              105.81909263535404,
              21.023314360930616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_La Thành",
          "maTaiSan": "04.O9C.DODV.7330",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023469328631016,
          "to_latitude": 21.023314360930616,
          "to_longitude": 105.81909263535404,
          "from_longitude": 105.81911284006344
        }
      },
      {
        "id": 7323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81603814740403,
            21.021533226154347,
            105.81606401728874,
            21.021621675197277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81606401728874,
              21.021621675197277
            ],
            [
              105.81604029899934,
              21.021554904121242
            ],
            [
              105.81604234349963,
              21.021545590169826
            ],
            [
              105.81603879561395,
              21.021535140595624
            ],
            [
              105.81603814740403,
              21.021533226154347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7331",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021621675197277,
          "to_latitude": 21.021533226154347,
          "to_longitude": 105.81603814740403,
          "from_longitude": 105.81606401728874
        }
      },
      {
        "id": 7324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81691234239209,
            21.021267593706526,
            105.81732198789031,
            21.021549003051984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81732198789031,
              21.021549003051984
            ],
            [
              105.8173215211326,
              21.02154843716514
            ],
            [
              105.81731829927666,
              21.02154451909367
            ],
            [
              105.81731614057205,
              21.02153163048327
            ],
            [
              105.81719676640581,
              21.02140445886511
            ],
            [
              105.81705802891813,
              21.021267593706526
            ],
            [
              105.81691234239209,
              21.021387830139982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Thành Công",
          "maTaiSan": "04.O9C.DODV.7332",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021549003051984,
          "to_latitude": 21.021387830139982,
          "to_longitude": 105.81691234239209,
          "from_longitude": 105.81732198789031
        }
      },
      {
        "id": 7325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81604054533504,
            21.02153246768102,
            105.81607293781894,
            21.021617982457045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81607293781894,
              21.021617982457045
            ],
            [
              105.81604953016081,
              21.0215520796997
            ],
            [
              105.81604726168081,
              21.021545691219142
            ],
            [
              105.81604089152587,
              21.021533152472713
            ],
            [
              105.81604054533504,
              21.02153246768102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7333",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021617982457045,
          "to_latitude": 21.02153246768102,
          "to_longitude": 105.81604054533504,
          "from_longitude": 105.81607293781894
        }
      },
      {
        "id": 7326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81682112613933,
            21.021552802752858,
            105.81696130474936,
            21.02188638851384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81696130474936,
              21.02188638851384
            ],
            [
              105.81692007382554,
              21.021835274225484
            ],
            [
              105.81695775380587,
              21.021806001211278
            ],
            [
              105.81682112613933,
              21.021655960299917
            ],
            [
              105.81683129425254,
              21.021647660490913
            ],
            [
              105.81694750679972,
              21.021552802752858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Thành Công",
          "maTaiSan": "04.O9C.DODV.7334",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02188638851384,
          "to_latitude": 21.021552802752858,
          "to_longitude": 105.81694750679972,
          "from_longitude": 105.81696130474936
        }
      },
      {
        "id": 7327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81696872558533,
            21.021581892655643,
            105.81719779096298,
            21.02180815663246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81696872558533,
              21.02170031431496
            ],
            [
              105.81700766034,
              21.021651869691045
            ],
            [
              105.81705277644119,
              21.02159613161468
            ],
            [
              105.817069424766,
              21.021581892655643
            ],
            [
              105.81709419230988,
              21.021587096393223
            ],
            [
              105.81710978747691,
              21.02159037335016
            ],
            [
              105.81717989898006,
              21.021594194074726
            ],
            [
              105.81717570137884,
              21.02161458020222
            ],
            [
              105.81717144003736,
              21.02163527373299
            ],
            [
              105.81712477852936,
              21.02175129249937
            ],
            [
              105.81719779096298,
              21.02180815663246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Thành Công",
          "maTaiSan": "04.O9C.DODV.7335",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02170031431496,
          "to_latitude": 21.02180815663246,
          "to_longitude": 105.81719779096298,
          "from_longitude": 105.81696872558533
        }
      },
      {
        "id": 7328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81941558486469,
            21.023321177758596,
            105.81952590807515,
            21.023339583263088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81952590807515,
              21.023321177758596
            ],
            [
              105.81941558486469,
              21.023339583263088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_La Thành",
          "maTaiSan": "04.O9C.DODV.7336",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023321177758596,
          "to_latitude": 21.023339583263088,
          "to_longitude": 105.81941558486469,
          "from_longitude": 105.81952590807515
        }
      },
      {
        "id": 7329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81680370562273,
            21.02364040146173,
            105.81689235074893,
            21.023672267629365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81689235074893,
              21.02364040146173
            ],
            [
              105.81682607693776,
              21.02366541850352
            ],
            [
              105.81680370562273,
              21.023672267629365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9c.44_La Thành",
          "maTaiSan": "04.O9C.DODV.7337",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02364040146173,
          "to_latitude": 21.023672267629365,
          "to_longitude": 105.81680370562273,
          "from_longitude": 105.81689235074893
        }
      },
      {
        "id": 7330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81657426615655,
            21.023449510427522,
            105.81658716312539,
            21.023453299062577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81657426615655,
              21.023453299062577
            ],
            [
              105.81657535723195,
              21.02345297859796
            ],
            [
              105.81657795237837,
              21.02345221645988
            ],
            [
              105.81658716312539,
              21.023449510427522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Thành Công",
          "maTaiSan": "04.O9C.DODV.7338",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023453299062577,
          "to_latitude": 21.023449510427522,
          "to_longitude": 105.81658716312539,
          "from_longitude": 105.81657426615655
        }
      },
      {
        "id": 7331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81742703653693,
            21.022329083361182,
            105.81772725879162,
            21.022415731487023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81742703653693,
              21.022415731487023
            ],
            [
              105.8174915228988,
              21.022397876372917
            ],
            [
              105.8175044795296,
              21.022394288803824
            ],
            [
              105.81757836288625,
              21.022375971200034
            ],
            [
              105.81758561989216,
              21.022374171411176
            ],
            [
              105.81758881705217,
              21.02237337926762
            ],
            [
              105.81766276353197,
              21.02234864751102
            ],
            [
              105.81772725879162,
              21.022329083361182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15_Thành Công",
          "maTaiSan": "04.O9C.DODV.7339",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022415731487023,
          "to_latitude": 21.022329083361182,
          "to_longitude": 105.81772725879162,
          "from_longitude": 105.81742703653693
        }
      },
      {
        "id": 7332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704346851133,
            21.02025498856633,
            105.81712215334892,
            21.020310327902816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81712215334892,
              21.02025498856633
            ],
            [
              105.81706143696789,
              21.02029775989804
            ],
            [
              105.81705949152338,
              21.02029910963187
            ],
            [
              105.81705392641612,
              21.02030297327209
            ],
            [
              105.81704859732203,
              21.020306721068668
            ],
            [
              105.81704346851133,
              21.020310327902816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7340",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02025498856633,
          "to_latitude": 21.020310327902816,
          "to_longitude": 105.81704346851133,
          "from_longitude": 105.81712215334892
        }
      },
      {
        "id": 7333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8174021310566,
            21.022879345613198,
            105.81744997558908,
            21.022975621029868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8174021310566,
              21.022879345613198
            ],
            [
              105.81742533571408,
              21.022975621029868
            ],
            [
              105.81744997558908,
              21.02296936156794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7341",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022879345613198,
          "to_latitude": 21.02296936156794,
          "to_longitude": 105.81744997558908,
          "from_longitude": 105.8174021310566
        }
      },
      {
        "id": 7334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81608898411476,
            21.021216131075956,
            105.81610512717981,
            21.021261407366488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610512717981,
              21.021261407366488
            ],
            [
              105.81609473406697,
              21.0212322568023
            ],
            [
              105.8160935198205,
              21.021228855655416
            ],
            [
              105.81608898411476,
              21.021216131075956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7342",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021261407366488,
          "to_latitude": 21.021216131075956,
          "to_longitude": 105.81608898411476,
          "from_longitude": 105.81610512717981
        }
      },
      {
        "id": 7335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743901062953,
            21.023441936939555,
            105.81763302827422,
            21.023700344149752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743901062953,
              21.023700344149752
            ],
            [
              105.81751501605265,
              21.0236896549465
            ],
            [
              105.81752944928847,
              21.023687625567025
            ],
            [
              105.81763302827422,
              21.02367305898985
            ],
            [
              105.81758490840686,
              21.023441936939555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_La Thành",
          "maTaiSan": "04.O9C.DODV.7343",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023700344149752,
          "to_latitude": 21.023441936939555,
          "to_longitude": 105.81758490840686,
          "from_longitude": 105.81743901062953
        }
      },
      {
        "id": 7336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81912696770488,
            21.023127698294868,
            105.81917972703414,
            21.023441829303888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81917972703414,
              21.023441829303888
            ],
            [
              105.81914618928649,
              21.023236712762664
            ],
            [
              105.81912696770488,
              21.023127698294868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_La Thành",
          "maTaiSan": "04.O9C.DODV.7344",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023441829303888,
          "to_latitude": 21.023127698294868,
          "to_longitude": 105.81912696770488,
          "from_longitude": 105.81917972703414
        }
      },
      {
        "id": 7337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8165536217181,
            21.020032004720345,
            105.81700306153063,
            21.020433048568602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8165536217181,
              21.02014263855945
            ],
            [
              105.81656151790999,
              21.02015241963665
            ],
            [
              105.81656602226151,
              21.020158502022717
            ],
            [
              105.81669833795719,
              21.020079324863314
            ],
            [
              105.81671452505793,
              21.02010035465906
            ],
            [
              105.81681535666443,
              21.020032004720345
            ],
            [
              105.81700306153063,
              21.02026911444461
            ],
            [
              105.81676773316494,
              21.020433048568602
            ],
            [
              105.81669740095097,
              21.02033419838527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7345",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02014263855945,
          "to_latitude": 21.02033419838527,
          "to_longitude": 105.81669740095097,
          "from_longitude": 105.8165536217181
        }
      },
      {
        "id": 7338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81821006926866,
            21.02356243794346,
            105.81843267223174,
            21.02360028476373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81843267223174,
              21.02356243794346
            ],
            [
              105.81842632321367,
              21.023563517141618
            ],
            [
              105.81821006926866,
              21.02360028476373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_La Thành",
          "maTaiSan": "04.O9C.DODV.7346",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02356243794346,
          "to_latitude": 21.02360028476373,
          "to_longitude": 105.81821006926866,
          "from_longitude": 105.81843267223174
        }
      },
      {
        "id": 7339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81650874546882,
            21.02010846631405,
            105.81653636937742,
            21.020152106811103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81650874546882,
              21.02010846631405
            ],
            [
              105.81652583563297,
              21.02013546573807
            ],
            [
              105.81652905418632,
              21.020140553521653
            ],
            [
              105.81653636937742,
              21.020152106811103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7347",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02010846631405,
          "to_latitude": 21.020152106811103,
          "to_longitude": 105.81653636937742,
          "from_longitude": 105.81650874546882
        }
      },
      {
        "id": 7340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81623452185194,
            21.021171496156352,
            105.81625107033035,
            21.021219493716448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81625107033035,
              21.021219493716448
            ],
            [
              105.81624037749806,
              21.021188480339266
            ],
            [
              105.8162397793497,
              21.021186749916808
            ],
            [
              105.81623452185194,
              21.021171496156352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7348",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021219493716448,
          "to_latitude": 21.021171496156352,
          "to_longitude": 105.81623452185194,
          "from_longitude": 105.81625107033035
        }
      },
      {
        "id": 7341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81741818098409,
            21.02314333447132,
            105.81789511622297,
            21.02327692578599
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81741818098409,
              21.02327692578599
            ],
            [
              105.81747490380873,
              21.02325312771781
            ],
            [
              105.81754771306257,
              21.023245398386948
            ],
            [
              105.81760582797207,
              21.023231678110243
            ],
            [
              105.81764422049255,
              21.02322171882612
            ],
            [
              105.8176720373399,
              21.023213955540662
            ],
            [
              105.81767568157535,
              21.02321293814871
            ],
            [
              105.81776280887438,
              21.023186307614548
            ],
            [
              105.81789511622297,
              21.02314333447132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Thành Công",
          "maTaiSan": "04.O9C.DODV.7349",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02327692578599,
          "to_latitude": 21.02314333447132,
          "to_longitude": 105.81789511622297,
          "from_longitude": 105.81741818098409
        }
      },
      {
        "id": 7342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81784973771722,
            21.02364355997557,
            105.81785316827134,
            21.023662976399148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81785316827134,
              21.023662976399148
            ],
            [
              105.8178528942897,
              21.023661423263697
            ],
            [
              105.817851172099,
              21.023651682117386
            ],
            [
              105.81785061818664,
              21.023648543358906
            ],
            [
              105.81784973771722,
              21.02364355997557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_La Thành",
          "maTaiSan": "04.O9C.DODV.7350",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023662976399148,
          "to_latitude": 21.02364355997557,
          "to_longitude": 105.81784973771722,
          "from_longitude": 105.81785316827134
        }
      },
      {
        "id": 7343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81741235523262,
            21.021180555312547,
            105.81743912844159,
            21.02121452346297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743912844159,
              21.02121452346297
            ],
            [
              105.81742095362875,
              21.021191544198317
            ],
            [
              105.81742087807471,
              21.021191448819355
            ],
            [
              105.81741479785644,
              21.021183762674813
            ],
            [
              105.81741235523262,
              21.021180555312547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7351",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02121452346297,
          "to_latitude": 21.021180555312547,
          "to_longitude": 105.81741235523262,
          "from_longitude": 105.81743912844159
        }
      },
      {
        "id": 7344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81683091083426,
            21.020574375717285,
            105.81687097328273,
            21.02062367836378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81683091083426,
              21.020574375717285
            ],
            [
              105.81684644925006,
              21.02059321467378
            ],
            [
              105.81684704532928,
              21.020598449625094
            ],
            [
              105.81685242690008,
              21.02060062404251
            ],
            [
              105.81685644191508,
              21.02060552192942
            ],
            [
              105.81686292636668,
              21.02061343196139
            ],
            [
              105.8168688412039,
              21.020620965373233
            ],
            [
              105.81687097328273,
              21.02062367836378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7352",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020574375717285,
          "to_latitude": 21.02062367836378,
          "to_longitude": 105.81687097328273,
          "from_longitude": 105.81683091083426
        }
      },
      {
        "id": 7345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81814088925528,
            21.023249039646462,
            105.81819546192914,
            21.02355099616761
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8181838727265,
              21.02355099616761
            ],
            [
              105.8181766004745,
              21.023523391516076
            ],
            [
              105.81816950112422,
              21.0234964444845
            ],
            [
              105.81816308153718,
              21.023472074700724
            ],
            [
              105.81815857261662,
              21.02339202445267
            ],
            [
              105.81814088925528,
              21.023260846308137
            ],
            [
              105.81819546192914,
              21.023249039646462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Thành Công",
          "maTaiSan": "04.O9C.DODV.7353",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02355099616761,
          "to_latitude": 21.023249039646462,
          "to_longitude": 105.81819546192914,
          "from_longitude": 105.8181838727265
        }
      },
      {
        "id": 7346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81669231266658,
            21.021595249738613,
            105.81676342032516,
            21.02167881447235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81669231266658,
              21.021595249738613
            ],
            [
              105.81676249571653,
              21.02167772781409
            ],
            [
              105.81676342032516,
              21.02167881447235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Thành Công",
          "maTaiSan": "04.O9C.DODV.7354",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021595249738613,
          "to_latitude": 21.02167881447235,
          "to_longitude": 105.81676342032516,
          "from_longitude": 105.81669231266658
        }
      },
      {
        "id": 7347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81610586329883,
            21.022736168049295,
            105.81610861137752,
            21.022736932720246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610586329883,
              21.022736932720246
            ],
            [
              105.8161073982158,
              21.02273650354826
            ],
            [
              105.81610861137752,
              21.022736168049295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.73_Thành Công",
          "maTaiSan": "04.O9C.DODV.7355",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022736932720246,
          "to_latitude": 21.022736168049295,
          "to_longitude": 105.81610861137752,
          "from_longitude": 105.81610586329883
        }
      },
      {
        "id": 7348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81768788886673,
            21.023664620137474,
            105.81769286265376,
            21.02368548785628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81768788886673,
              21.023664620137474
            ],
            [
              105.81768857704357,
              21.023667507154066
            ],
            [
              105.8176900922167,
              21.023673864004216
            ],
            [
              105.81769106692145,
              21.023677959063658
            ],
            [
              105.81769286265376,
              21.02368548785628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_La Thành",
          "maTaiSan": "04.O9C.DODV.7356",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023664620137474,
          "to_latitude": 21.02368548785628,
          "to_longitude": 105.81769286265376,
          "from_longitude": 105.81768788886673
        }
      },
      {
        "id": 7349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8158062799441,
            21.02130283226434,
            105.81582235159335,
            21.02134808002266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81582235159335,
              21.02134808002266
            ],
            [
              105.81581023557159,
              21.021313968147737
            ],
            [
              105.81580927134948,
              21.021311256768293
            ],
            [
              105.8158062799441,
              21.02130283226434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7357",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02134808002266,
          "to_latitude": 21.02130283226434,
          "to_longitude": 105.8158062799441,
          "from_longitude": 105.81582235159335
        }
      },
      {
        "id": 7350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81549329524674,
            21.020919904993317,
            105.81558187099907,
            21.02094695591671
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81558187099907,
              21.020919904993317
            ],
            [
              105.81552782933416,
              21.020936552263315
            ],
            [
              105.81552135455749,
              21.020938547826205
            ],
            [
              105.8155032265285,
              21.020943923136166
            ],
            [
              105.81549329524674,
              21.02094695591671
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7358",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020919904993317,
          "to_latitude": 21.02094695591671,
          "to_longitude": 105.81549329524674,
          "from_longitude": 105.81558187099907
        }
      },
      {
        "id": 7351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81757506256378,
            21.02236454769718,
            105.81757836288625,
            21.022375971200034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81757506256378,
              21.02236454769718
            ],
            [
              105.81757683822684,
              21.022370693749046
            ],
            [
              105.81757793835575,
              21.022374505497194
            ],
            [
              105.81757836288625,
              21.022375971200034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15_Thành Công",
          "maTaiSan": "04.O9C.DODV.7359",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02236454769718,
          "to_latitude": 21.022375971200034,
          "to_longitude": 105.81757836288625,
          "from_longitude": 105.81757506256378
        }
      },
      {
        "id": 7352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81655613029996,
            21.02183921937668,
            105.81673890463684,
            21.022138491814612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8166709044786,
              21.022138491814612
            ],
            [
              105.81673890463684,
              21.02210804177626
            ],
            [
              105.81673305606765,
              21.022082130989016
            ],
            [
              105.81671234902575,
              21.02199039481954
            ],
            [
              105.81655613029996,
              21.02183921937668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.69.1_Thành Công",
          "maTaiSan": "04.O9C.DODV.7360",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022138491814612,
          "to_latitude": 21.02183921937668,
          "to_longitude": 105.81655613029996,
          "from_longitude": 105.8166709044786
        }
      },
      {
        "id": 7353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8194010942715,
            21.0232538762293,
            105.81943812897478,
            21.02341824501757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81943812897478,
              21.02341824501757
            ],
            [
              105.81943636512622,
              21.023407940477313
            ],
            [
              105.81942896257341,
              21.02340265162329
            ],
            [
              105.81942235857522,
              21.02336675399966
            ],
            [
              105.81941558486469,
              21.023339583263088
            ],
            [
              105.8194010942715,
              21.023281466330285
            ],
            [
              105.81940393719844,
              21.0232538762293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_La Thành",
          "maTaiSan": "04.O9C.DODV.7361",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02341824501757,
          "to_latitude": 21.0232538762293,
          "to_longitude": 105.81940393719844,
          "from_longitude": 105.81943812897478
        }
      },
      {
        "id": 7354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81545223585017,
            21.020788881181666,
            105.81553851410762,
            21.020819513201502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81553851410762,
              21.020788881181666
            ],
            [
              105.81548493173459,
              21.02080524623453
            ],
            [
              105.81547986480102,
              21.020811220084926
            ],
            [
              105.81545223585017,
              21.020819513201502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7362",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020788881181666,
          "to_latitude": 21.020819513201502,
          "to_longitude": 105.81545223585017,
          "from_longitude": 105.81553851410762
        }
      },
      {
        "id": 7355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81549003099464,
            21.020909139764072,
            105.81558067058518,
            21.02093682290505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81558067058518,
              21.020909139764072
            ],
            [
              105.81552448465861,
              21.020926313057128
            ],
            [
              105.81551809526982,
              21.020928266662047
            ],
            [
              105.81550202516786,
              21.020933159717238
            ],
            [
              105.81549003099464,
              21.02093682290505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7363",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020909139764072,
          "to_latitude": 21.02093682290505,
          "to_longitude": 105.81549003099464,
          "from_longitude": 105.81558067058518
        }
      },
      {
        "id": 7356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81758983631332,
            21.023444868608077,
            105.81771846542571,
            21.023670755163394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81758983631332,
              21.023444868608077
            ],
            [
              105.8176402922128,
              21.023670755163394
            ],
            [
              105.81764267435408,
              21.02367044835616
            ],
            [
              105.81768464058044,
              21.02366503859353
            ],
            [
              105.81768788886673,
              21.023664620137474
            ],
            [
              105.81769821243492,
              21.023663289728496
            ],
            [
              105.81771846542571,
              21.023660678674723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_La Thành",
          "maTaiSan": "04.O9C.DODV.7364",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023444868608077,
          "to_latitude": 21.023660678674723,
          "to_longitude": 105.81771846542571,
          "from_longitude": 105.81758983631332
        }
      },
      {
        "id": 7357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8155368801866,
            21.021054213762152,
            105.8156286319893,
            21.0210822337475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8156286319893,
              21.021054213762152
            ],
            [
              105.81557196254816,
              21.021071638681242
            ],
            [
              105.81557014169259,
              21.021072199283815
            ],
            [
              105.81554639851566,
              21.021079327589568
            ],
            [
              105.8155368801866,
              21.0210822337475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7365",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021054213762152,
          "to_latitude": 21.0210822337475,
          "to_longitude": 105.8155368801866,
          "from_longitude": 105.8156286319893
        }
      },
      {
        "id": 7358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81791612151413,
            21.02341244947935,
            105.81817968125362,
            21.02363311336597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81817968125362,
              21.023601223082167
            ],
            [
              105.81805103915197,
              21.023624693517498
            ],
            [
              105.81803347432617,
              21.02362789774615
            ],
            [
              105.8180332302234,
              21.023627942275095
            ],
            [
              105.81793686405398,
              21.02363311336597
            ],
            [
              105.81791612151413,
              21.02345022844432
            ],
            [
              105.81791793848755,
              21.02341244947935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_La Thành",
          "maTaiSan": "04.O9C.DODV.7366",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023601223082167,
          "to_latitude": 21.02341244947935,
          "to_longitude": 105.81791793848755,
          "from_longitude": 105.81817968125362
        }
      },
      {
        "id": 7359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81719118010594,
            21.02116151481191,
            105.81722730304091,
            21.02118644039583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81719118010594,
              21.02118644039583
            ],
            [
              105.8172199047305,
              21.02116667797707
            ],
            [
              105.81722045292318,
              21.021166300504078
            ],
            [
              105.81722102603794,
              21.021165906653238
            ],
            [
              105.81722730304091,
              21.02116151481191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7367",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02118644039583,
          "to_latitude": 21.02116151481191,
          "to_longitude": 105.81722730304091,
          "from_longitude": 105.81719118010594
        }
      },
      {
        "id": 7360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81807450997731,
            21.023010671131388,
            105.81808482268934,
            21.023013100737174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81808482268934,
              21.023010671131388
            ],
            [
              105.81807896690485,
              21.02301205052453
            ],
            [
              105.81807618841036,
              21.02301270517983
            ],
            [
              105.81807615574311,
              21.023012712562764
            ],
            [
              105.81807450997731,
              21.023013100737174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Thành Công",
          "maTaiSan": "04.O9C.DODV.7368",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023010671131388,
          "to_latitude": 21.023013100737174,
          "to_longitude": 105.81807450997731,
          "from_longitude": 105.81808482268934
        }
      },
      {
        "id": 7361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81754647079599,
            21.021739546165705,
            105.81755480857413,
            21.021747076702315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81754647079599,
              21.021747076702315
            ],
            [
              105.8175485729493,
              21.021745177950812
            ],
            [
              105.81755020029796,
              21.02174370780454
            ],
            [
              105.81755182765144,
              21.021742238561455
            ],
            [
              105.81755480857413,
              21.021739546165705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Thành Công",
          "maTaiSan": "04.O9C.DODV.7369",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021747076702315,
          "to_latitude": 21.021739546165705,
          "to_longitude": 105.81755480857413,
          "from_longitude": 105.81754647079599
        }
      },
      {
        "id": 7362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172017334507,
            21.023012353363747,
            105.81729529445873,
            21.023025402943734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8172017334507,
              21.023025402943734
            ],
            [
              105.81729529445873,
              21.023012353363747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7370",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023025402943734,
          "to_latitude": 21.023012353363747,
          "to_longitude": 105.81729529445873,
          "from_longitude": 105.8172017334507
        }
      },
      {
        "id": 7363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81682449320195,
            21.02163956230261,
            105.81683129425254,
            21.021647660490913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81682449320195,
              21.02163956230261
            ],
            [
              105.81682720723481,
              21.021642794563462
            ],
            [
              105.81682842337439,
              21.021644243821804
            ],
            [
              105.81682964143792,
              21.02164569307091
            ],
            [
              105.81683129425254,
              21.021647660490913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Thành Công",
          "maTaiSan": "04.O9C.DODV.7371",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02163956230261,
          "to_latitude": 21.021647660490913,
          "to_longitude": 105.81683129425254,
          "from_longitude": 105.81682449320195
        }
      },
      {
        "id": 7364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81894248294249,
            21.022859697991986,
            105.8193773774433,
            21.02311403391268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8193773774433,
              21.022859697991986
            ],
            [
              105.81936278392415,
              21.0228695204321
            ],
            [
              105.81929837727333,
              21.022912870339557
            ],
            [
              105.8192633559799,
              21.022973752111167
            ],
            [
              105.81894248294249,
              21.02311403391268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.32_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7372",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022859697991986,
          "to_latitude": 21.02311403391268,
          "to_longitude": 105.81894248294249,
          "from_longitude": 105.8193773774433
        }
      },
      {
        "id": 7365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81696652390154,
            21.021480369881584,
            105.81725065467332,
            21.021578114046473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81725065467332,
              21.02157712545601
            ],
            [
              105.81722490090418,
              21.021577710744964
            ],
            [
              105.81720713344569,
              21.021578114046473
            ],
            [
              105.81717143454148,
              21.021577778849714
            ],
            [
              105.81710712276671,
              21.02156653554093
            ],
            [
              105.81709536916529,
              21.021564212001405
            ],
            [
              105.81709109915701,
              21.021563368125847
            ],
            [
              105.81705559662942,
              21.021556348081905
            ],
            [
              105.8170475862684,
              21.021554764365156
            ],
            [
              105.81703725913319,
              21.021551883904163
            ],
            [
              105.81696652390154,
              21.021535985455348
            ],
            [
              105.81696803310082,
              21.021534193429968
            ],
            [
              105.81698756091195,
              21.021511002314217
            ],
            [
              105.81702393472301,
              21.021480369881584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Thành Công",
          "maTaiSan": "04.O9C.DODV.7373",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02157712545601,
          "to_latitude": 21.021480369881584,
          "to_longitude": 105.81702393472301,
          "from_longitude": 105.81725065467332
        }
      },
      {
        "id": 7366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81689265183167,
            21.022428429494315,
            105.81692127310866,
            21.022542378614084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81689265183167,
              21.022428429494315
            ],
            [
              105.81691986107344,
              21.02253675738955
            ],
            [
              105.81692127310866,
              21.022542378614084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Thành Công",
          "maTaiSan": "04.O9C.DODV.7374",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022428429494315,
          "to_latitude": 21.022542378614084,
          "to_longitude": 105.81692127310866,
          "from_longitude": 105.81689265183167
        }
      },
      {
        "id": 7367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81721046238076,
            21.023121922266334,
            105.81730264530573,
            21.02312715764583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81721046238076,
              21.023121922266334
            ],
            [
              105.81728333840925,
              21.02312715764583
            ],
            [
              105.81730264530573,
              21.02312570912616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7375",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023121922266334,
          "to_latitude": 21.02312570912616,
          "to_longitude": 105.81730264530573,
          "from_longitude": 105.81721046238076
        }
      },
      {
        "id": 7368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81727202348635,
            21.020853393500644,
            105.81731375115078,
            21.02088372474771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81731375115078,
              21.020853393500644
            ],
            [
              105.81731092273363,
              21.020855471868156
            ],
            [
              105.81730871039254,
              21.020857096565116
            ],
            [
              105.81727202348635,
              21.02088372474771
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7376",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020853393500644,
          "to_latitude": 21.02088372474771,
          "to_longitude": 105.81727202348635,
          "from_longitude": 105.81731375115078
        }
      },
      {
        "id": 7369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81590126490252,
            21.02158100056355,
            105.81592762754687,
            21.021663670224367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81592762754687,
              21.021663670224367
            ],
            [
              105.81590382722831,
              21.021596664682555
            ],
            [
              105.81590406557277,
              21.021590081723094
            ],
            [
              105.81590181918222,
              21.021582801649338
            ],
            [
              105.81590126490252,
              21.02158100056355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7377",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021663670224367,
          "to_latitude": 21.02158100056355,
          "to_longitude": 105.81590126490252,
          "from_longitude": 105.81592762754687
        }
      },
      {
        "id": 7370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81718119661765,
            21.020736485316036,
            105.8172229338399,
            21.020766825619038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8172229338399,
              21.020736485316036
            ],
            [
              105.81721797328406,
              21.020740253928064
            ],
            [
              105.81721612624946,
              21.020741657384548
            ],
            [
              105.81718119661765,
              21.020766825619038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7378",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020736485316036,
          "to_latitude": 21.020766825619038,
          "to_longitude": 105.81718119661765,
          "from_longitude": 105.8172229338399
        }
      },
      {
        "id": 7371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81942896257341,
            21.023344398337898,
            105.8196163728603,
            21.02340265162329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81942896257341,
              21.02340265162329
            ],
            [
              105.8196163728603,
              21.023376847538724
            ],
            [
              105.81961072753246,
              21.023344398337898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_La Thành",
          "maTaiSan": "04.O9C.DODV.7379",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02340265162329,
          "to_latitude": 21.023344398337898,
          "to_longitude": 105.81961072753246,
          "from_longitude": 105.81942896257341
        }
      },
      {
        "id": 7372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81766687941543,
            21.023518996366374,
            105.81769821243492,
            21.023663289728496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81769821243492,
              21.023663289728496
            ],
            [
              105.81767824221286,
              21.023564287328025
            ],
            [
              105.81766687941543,
              21.023518996366374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_La Thành",
          "maTaiSan": "04.O9C.DODV.7380",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023663289728496,
          "to_latitude": 21.023518996366374,
          "to_longitude": 105.81766687941543,
          "from_longitude": 105.81769821243492
        }
      },
      {
        "id": 7373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81665503375521,
            21.02176563734238,
            105.81665980867312,
            21.02177104913311
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81665503375521,
              21.02176563734238
            ],
            [
              105.81665760173765,
              21.02176854785515
            ],
            [
              105.8166583828898,
              21.02176943558959
            ],
            [
              105.81665916691796,
              21.021770321503777
            ],
            [
              105.81665980867312,
              21.02177104913311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Thành Công",
          "maTaiSan": "04.O9C.DODV.7381",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02176563734238,
          "to_latitude": 21.02177104913311,
          "to_longitude": 105.81665980867312,
          "from_longitude": 105.81665503375521
        }
      },
      {
        "id": 7374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763018083997,
            21.02266013285779,
            105.8176775565109,
            21.02277211928301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176775565109,
              21.02277211928301
            ],
            [
              105.81763018083997,
              21.02266013285779
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_Thành Công",
          "maTaiSan": "04.O9C.DODV.7382",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02277211928301,
          "to_latitude": 21.02266013285779,
          "to_longitude": 105.81763018083997,
          "from_longitude": 105.8176775565109
        }
      },
      {
        "id": 7375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81872315954354,
            21.02345086473542,
            105.81911043137912,
            21.023523304838086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81911043137912,
              21.02345086473542
            ],
            [
              105.81890437045695,
              21.023484884397092
            ],
            [
              105.81888854268111,
              21.02348749776838
            ],
            [
              105.81872315954354,
              21.023523304838086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_La Thành",
          "maTaiSan": "04.O9C.DODV.7383",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02345086473542,
          "to_latitude": 21.023523304838086,
          "to_longitude": 105.81872315954354,
          "from_longitude": 105.81911043137912
        }
      },
      {
        "id": 7376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81736173844119,
            21.020967751410527,
            105.81740258650471,
            21.02099744438729
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81740258650471,
              21.020967751410527
            ],
            [
              105.81740053403931,
              21.020968881275245
            ],
            [
              105.81739981161007,
              21.02096941313471
            ],
            [
              105.81736173844119,
              21.02099744438729
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7384",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020967751410527,
          "to_latitude": 21.02099744438729,
          "to_longitude": 105.81736173844119,
          "from_longitude": 105.81740258650471
        }
      },
      {
        "id": 7377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81617592387188,
            21.021484461023473,
            105.81620455829717,
            21.02157798826893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81620455829717,
              21.02157798826893
            ],
            [
              105.81618105781689,
              21.02151183126621
            ],
            [
              105.81617980558451,
              21.021494559443884
            ],
            [
              105.8161761239284,
              21.02148498483845
            ],
            [
              105.81617592387188,
              21.021484461023473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7385",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02157798826893,
          "to_latitude": 21.021484461023473,
          "to_longitude": 105.81617592387188,
          "from_longitude": 105.81620455829717
        }
      },
      {
        "id": 7378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172883120733,
            21.02245617098703,
            105.81736550243006,
            21.022725513964936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81730388068422,
              21.02245617098703
            ],
            [
              105.8173039405157,
              21.0224563820546
            ],
            [
              105.81730502135473,
              21.022459303315717
            ],
            [
              105.81736550243006,
              21.02262292937062
            ],
            [
              105.81731184133417,
              21.022626343114556
            ],
            [
              105.81730889132781,
              21.02263877033617
            ],
            [
              105.8172883120733,
              21.022725513964936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Thành Công",
          "maTaiSan": "04.O9C.DODV.7386",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02245617098703,
          "to_latitude": 21.022725513964936,
          "to_longitude": 105.8172883120733,
          "from_longitude": 105.81730388068422
        }
      },
      {
        "id": 7379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704417213429,
            21.02246495499798,
            105.81729953037511,
            21.022760102889965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81706124692033,
              21.022760102889965
            ],
            [
              105.81705769871176,
              21.02269188566478
            ],
            [
              105.81705541359328,
              21.02265509195533
            ],
            [
              105.81704417213429,
              21.022515503474835
            ],
            [
              105.81709975082599,
              21.022504218014323
            ],
            [
              105.81715256254424,
              21.022499232288286
            ],
            [
              105.81715752919732,
              21.022498689974206
            ],
            [
              105.81717454041645,
              21.02249683250016
            ],
            [
              105.81723566286372,
              21.022475461123346
            ],
            [
              105.81726541324133,
              21.022469120231104
            ],
            [
              105.81729953037511,
              21.02246495499798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Thành Công",
          "maTaiSan": "04.O9C.DODV.7387",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022760102889965,
          "to_latitude": 21.02246495499798,
          "to_longitude": 105.81729953037511,
          "from_longitude": 105.81706124692033
        }
      },
      {
        "id": 7380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81715680531354,
            21.022495602609848,
            105.81715752919732,
            21.022498689974206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81715680531354,
              21.022495602609848
            ],
            [
              105.81715691365415,
              21.02249606544469
            ],
            [
              105.81715733914366,
              21.022497884305743
            ],
            [
              105.81715752919732,
              21.022498689974206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Thành Công",
          "maTaiSan": "04.O9C.DODV.7388",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022495602609848,
          "to_latitude": 21.022498689974206,
          "to_longitude": 105.81715752919732,
          "from_longitude": 105.81715680531354
        }
      },
      {
        "id": 7381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81646768820002,
            21.0206924344996,
            105.81649929251384,
            21.020713519396608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81649929251384,
              21.0206924344996
            ],
            [
              105.8164805501874,
              21.020704938419698
            ],
            [
              105.8164758411212,
              21.02070807986701
            ],
            [
              105.81646768820002,
              21.020713519396608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7389",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0206924344996,
          "to_latitude": 21.020713519396608,
          "to_longitude": 105.81646768820002,
          "from_longitude": 105.81649929251384
        }
      },
      {
        "id": 7382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8174102078969,
            21.021182105633866,
            105.81743597076543,
            21.02121478973535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743597076543,
              21.02121478973535
            ],
            [
              105.81741894006004,
              21.021192927683142
            ],
            [
              105.81741884415247,
              21.021192804401963
            ],
            [
              105.8174125036698,
              21.02118466789462
            ],
            [
              105.8174102078969,
              21.021182105633866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7390",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02121478973535,
          "to_latitude": 21.021182105633866,
          "to_longitude": 105.8174102078969,
          "from_longitude": 105.81743597076543
        }
      },
      {
        "id": 7383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81791731388968,
            21.02289011378623,
            105.81810528107724,
            21.023118432346095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81791731388968,
              21.023118432346095
            ],
            [
              105.8180977419913,
              21.02306776155672
            ],
            [
              105.81809832982499,
              21.02306759614841
            ],
            [
              105.81810528107724,
              21.02306564425893
            ],
            [
              105.81808482268934,
              21.023010671131388
            ],
            [
              105.818076995692,
              21.022989640114684
            ],
            [
              105.81803995683359,
              21.02289011378623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_Thành Công",
          "maTaiSan": "04.O9C.DODV.7391",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023118432346095,
          "to_latitude": 21.02289011378623,
          "to_longitude": 105.81803995683359,
          "from_longitude": 105.81791731388968
        }
      },
      {
        "id": 7384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81657291521734,
            21.021625899591516,
            105.81683719865792,
            21.021824435162948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81657291521734,
              21.021824435162948
            ],
            [
              105.81666643144345,
              21.02175294918189
            ],
            [
              105.81675118389178,
              21.02168816292035
            ],
            [
              105.81676330246243,
              21.02167889903843
            ],
            [
              105.81676342032516,
              21.02167881447235
            ],
            [
              105.81683719865792,
              21.021625899591516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Thành Công",
          "maTaiSan": "04.O9C.DODV.7392",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021824435162948,
          "to_latitude": 21.021625899591516,
          "to_longitude": 105.81683719865792,
          "from_longitude": 105.81657291521734
        }
      },
      {
        "id": 7385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81630836866117,
            21.02279801622373,
            105.81636187282584,
            21.02292901906903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81636187282584,
              21.02292901906903
            ],
            [
              105.8163616774996,
              21.022928657812567
            ],
            [
              105.81632862777835,
              21.022867576753487
            ],
            [
              105.81630836866117,
              21.02279801622373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Thành Công",
          "maTaiSan": "04.O9C.DODV.7393",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02292901906903,
          "to_latitude": 21.02279801622373,
          "to_longitude": 105.81630836866117,
          "from_longitude": 105.81636187282584
        }
      },
      {
        "id": 7386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81544813012415,
            21.02077994327677,
            105.81553597494991,
            21.02080677208951
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81553597494991,
              21.02077994327677
            ],
            [
              105.81548165163932,
              21.020795206330792
            ],
            [
              105.8154756696148,
              21.020796887916042
            ],
            [
              105.81546174060453,
              21.020802615219754
            ],
            [
              105.81544813012415,
              21.02080677208951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7394",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02077994327677,
          "to_latitude": 21.02080677208951,
          "to_longitude": 105.81544813012415,
          "from_longitude": 105.81553597494991
        }
      },
      {
        "id": 7387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81594952736907,
            21.021258900441346,
            105.81596615297015,
            21.02130570540114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81596615297015,
              21.02130570540114
            ],
            [
              105.81595409848612,
              21.021271770274453
            ],
            [
              105.81595311667631,
              21.021269009302745
            ],
            [
              105.81594952736907,
              21.021258900441346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7395",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02130570540114,
          "to_latitude": 21.021258900441346,
          "to_longitude": 105.81594952736907,
          "from_longitude": 105.81596615297015
        }
      },
      {
        "id": 7388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81587263618734,
            21.02195927192769,
            105.81604570400262,
            21.022038866296242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81587263618734,
              21.022038866296242
            ],
            [
              105.8158757682409,
              21.02203795346467
            ],
            [
              105.81588711907054,
              21.022035731271497
            ],
            [
              105.81589921877465,
              21.022031117352824
            ],
            [
              105.81600399054783,
              21.02199620720371
            ],
            [
              105.81604570400262,
              21.02195927192769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Thành Công",
          "maTaiSan": "04.O9C.DODV.7396",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022038866296242,
          "to_latitude": 21.02195927192769,
          "to_longitude": 105.81604570400262,
          "from_longitude": 105.81587263618734
        }
      },
      {
        "id": 7389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81650698526892,
            21.023758807209145,
            105.81704072032302,
            21.023855829883317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81704072032302,
              21.023758807209145
            ],
            [
              105.81693656488152,
              21.023782713755537
            ],
            [
              105.81686433167602,
              21.023799292591
            ],
            [
              105.81686196716848,
              21.023799835044922
            ],
            [
              105.81668197624698,
              21.02382822436732
            ],
            [
              105.81650698526892,
              21.023855829883317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9c.44_La Thành",
          "maTaiSan": "04.O9C.DODV.7397",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023758807209145,
          "to_latitude": 21.023855829883317,
          "to_longitude": 105.81650698526892,
          "from_longitude": 105.81704072032302
        }
      },
      {
        "id": 7390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.818345369808,
            21.02279617302337,
            105.81840921617193,
            21.022816203086407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.818345369808,
              21.022816203086407
            ],
            [
              105.81835596045248,
              21.022812880642725
            ],
            [
              105.81840921617193,
              21.02279617302337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_04_Thành Công",
          "maTaiSan": "04.O9C.DODV.7398",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022816203086407,
          "to_latitude": 21.02279617302337,
          "to_longitude": 105.81840921617193,
          "from_longitude": 105.818345369808
        }
      },
      {
        "id": 7391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637567149393,
            21.023466290802528,
            105.81650577607226,
            21.02350091674792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637567149393,
              21.02350091674792
            ],
            [
              105.8165047138355,
              21.023466290802528
            ],
            [
              105.8165055816261,
              21.023468777736074
            ],
            [
              105.81650577607226,
              21.02346933048064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Thành Công",
          "maTaiSan": "04.O9C.DODV.7399",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02350091674792,
          "to_latitude": 21.02346933048064,
          "to_longitude": 105.81650577607226,
          "from_longitude": 105.81637567149393
        }
      },
      {
        "id": 7392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81938487709908,
            21.022984683752124,
            105.8195064478377,
            21.023170487821876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8195064478377,
              21.023170487821876
            ],
            [
              105.8194371464954,
              21.023064570691687
            ],
            [
              105.81938487709908,
              21.022984683752124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.32.1_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7400",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023170487821876,
          "to_latitude": 21.022984683752124,
          "to_longitude": 105.81938487709908,
          "from_longitude": 105.8195064478377
        }
      },
      {
        "id": 7393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8194371464954,
            21.023038089379607,
            105.81950549138908,
            21.023064570691687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81950549138908,
              21.023038089379607
            ],
            [
              105.81944422895656,
              21.023061811534635
            ],
            [
              105.8194440494449,
              21.023061881044715
            ],
            [
              105.8194371464954,
              21.023064570691687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.32.1_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7401",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023038089379607,
          "to_latitude": 21.023064570691687,
          "to_longitude": 105.8194371464954,
          "from_longitude": 105.81950549138908
        }
      },
      {
        "id": 7394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81609297446255,
            21.02121490702557,
            105.81610869630407,
            21.021259852045933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610869630407,
              21.021259852045933
            ],
            [
              105.81609862842649,
              21.02123107114786
            ],
            [
              105.81609743823859,
              21.02122767169202
            ],
            [
              105.81609297446255,
              21.02121490702557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7402",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021259852045933,
          "to_latitude": 21.02121490702557,
          "to_longitude": 105.81609297446255,
          "from_longitude": 105.81610869630407
        }
      },
      {
        "id": 7395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81785481493903,
            21.02266772923462,
            105.81803172971108,
            21.022866975657045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81803172971108,
              21.022866975657045
            ],
            [
              105.81801654094846,
              21.022825016153465
            ],
            [
              105.8180080639543,
              21.022771945389852
            ],
            [
              105.81798571691384,
              21.022754113881355
            ],
            [
              105.81797486176323,
              21.022745453562813
            ],
            [
              105.81786066004756,
              21.022734586870914
            ],
            [
              105.81785481493903,
              21.02266772923462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Thành Công",
          "maTaiSan": "04.O9C.DODV.7403",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022866975657045,
          "to_latitude": 21.02266772923462,
          "to_longitude": 105.81785481493903,
          "from_longitude": 105.81803172971108
        }
      },
      {
        "id": 7396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8183367350186,
            21.022530484505648,
            105.81838369061565,
            21.02267779452388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8183367350186,
              21.022530484505648
            ],
            [
              105.81838369061565,
              21.02266905470244
            ],
            [
              105.81835887004948,
              21.02267779452388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Thành Công",
          "maTaiSan": "04.O9C.DODV.7404",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022530484505648,
          "to_latitude": 21.02267779452388,
          "to_longitude": 105.81835887004948,
          "from_longitude": 105.8183367350186
        }
      },
      {
        "id": 7397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81797920227413,
            21.022754113881355,
            105.81798571691384,
            21.022767854386757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81797920227413,
              21.022767854386757
            ],
            [
              105.81797945814417,
              21.02276732205891
            ],
            [
              105.81798379860041,
              21.02275832491764
            ],
            [
              105.81798571691384,
              21.022754113881355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Thành Công",
          "maTaiSan": "04.O9C.DODV.7405",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022767854386757,
          "to_latitude": 21.022754113881355,
          "to_longitude": 105.81798571691384,
          "from_longitude": 105.81797920227413
        }
      },
      {
        "id": 7398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81719791011639,
            21.023357153016295,
            105.81719891076422,
            21.02335948033977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81719891076422,
              21.02335948033977
            ],
            [
              105.81719887665739,
              21.02335940011646
            ],
            [
              105.81719793351368,
              21.02335720980715
            ],
            [
              105.81719791011639,
              21.023357153016295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Thành Công",
          "maTaiSan": "04.O9C.DODV.7406",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02335948033977,
          "to_latitude": 21.023357153016295,
          "to_longitude": 105.81719791011639,
          "from_longitude": 105.81719891076422
        }
      },
      {
        "id": 7399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81761403112908,
            21.02100128383384,
            105.81765381569339,
            21.021030005304826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81765381569339,
              21.02100128383384
            ],
            [
              105.81762502760215,
              21.021022092565605
            ],
            [
              105.81762372828646,
              21.02102303184469
            ],
            [
              105.81761757360184,
              21.021027447499396
            ],
            [
              105.81761403112908,
              21.021030005304826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7407",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02100128383384,
          "to_latitude": 21.021030005304826,
          "to_longitude": 105.81761403112908,
          "from_longitude": 105.81765381569339
        }
      },
      {
        "id": 7400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81656354588965,
            21.02335019361514,
            105.81673662285024,
            21.02349403657717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81660207117172,
              21.02349403657717
            ],
            [
              105.81658947585295,
              21.023456418041008
            ],
            [
              105.81658716312539,
              21.023449510427522
            ],
            [
              105.81656354588965,
              21.02337896257643
            ],
            [
              105.81659635712127,
              21.02337272361688
            ],
            [
              105.81671484674739,
              21.02335019361514
            ],
            [
              105.81673662285024,
              21.023421280479088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Thành Công",
          "maTaiSan": "04.O9C.DODV.7408",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349403657717,
          "to_latitude": 21.023421280479088,
          "to_longitude": 105.81673662285024,
          "from_longitude": 105.81660207117172
        }
      },
      {
        "id": 7401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81619507526887,
            21.023008610783826,
            105.81646641006556,
            21.02381408875681
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81646641006556,
              21.02381408875681
            ],
            [
              105.8164131265909,
              21.02365073132446
            ],
            [
              105.81640933265213,
              21.023639099716583
            ],
            [
              105.81636872151205,
              21.023514594252774
            ],
            [
              105.81636705053216,
              21.023509470138418
            ],
            [
              105.81634123068619,
              21.02343031152626
            ],
            [
              105.81619507526887,
              21.023008610783826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Thành Công",
          "maTaiSan": "04.O9C.DODV.7409",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02381408875681,
          "to_latitude": 21.023008610783826,
          "to_longitude": 105.81619507526887,
          "from_longitude": 105.81646641006556
        }
      },
      {
        "id": 7402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81802657727935,
            21.0223386145646,
            105.818075419601,
            21.02239810603606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.818075419601,
              21.0223386145646
            ],
            [
              105.81802657727935,
              21.022352975041777
            ],
            [
              105.81803908300836,
              21.02239810603606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Thành Công",
          "maTaiSan": "04.O9C.DODV.7410",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0223386145646,
          "to_latitude": 21.02239810603606,
          "to_longitude": 105.81803908300836,
          "from_longitude": 105.818075419601
        }
      },
      {
        "id": 7403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8160882521112,
            21.020335341755697,
            105.8161177108157,
            21.020381882813837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8160882521112,
              21.020335341755697
            ],
            [
              105.81610599913299,
              21.02036337949215
            ],
            [
              105.81610973581965,
              21.020369284928204
            ],
            [
              105.8161177108157,
              21.020381882813837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7411",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020335341755697,
          "to_latitude": 21.020381882813837,
          "to_longitude": 105.8161177108157,
          "from_longitude": 105.8160882521112
        }
      },
      {
        "id": 7404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81674392456564,
            21.01989172524596,
            105.81682536084558,
            21.01994983791853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81682536084558,
              21.01989172524596
            ],
            [
              105.81676420348182,
              21.01993476594443
            ],
            [
              105.81675653425322,
              21.01994016342238
            ],
            [
              105.8167535185021,
              21.019942286034986
            ],
            [
              105.8167464676858,
              21.019947835898222
            ],
            [
              105.81674392456564,
              21.01994983791853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7412",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01989172524596,
          "to_latitude": 21.01994983791853,
          "to_longitude": 105.81674392456564,
          "from_longitude": 105.81682536084558
        }
      },
      {
        "id": 7405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81748902981712,
            21.020844602553883,
            105.81752647727745,
            21.020871466566952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81752647727745,
              21.020844602553883
            ],
            [
              105.81749778476643,
              21.020865296094936
            ],
            [
              105.81749600059136,
              21.020866583639258
            ],
            [
              105.81749058019287,
              21.020870348146577
            ],
            [
              105.81748902981712,
              21.020871466566952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7413",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020844602553883,
          "to_latitude": 21.020871466566952,
          "to_longitude": 105.81748902981712,
          "from_longitude": 105.81752647727745
        }
      },
      {
        "id": 7406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81676368397144,
            21.019909826238937,
            105.81685237913263,
            21.019972200882165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81685237913263,
              21.019909826238937
            ],
            [
              105.81678316232947,
              21.01995850475184
            ],
            [
              105.81678036506001,
              21.019960471863985
            ],
            [
              105.81677695831215,
              21.019962867322622
            ],
            [
              105.81676368397144,
              21.019972200882165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7414",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019909826238937,
          "to_latitude": 21.019972200882165,
          "to_longitude": 105.81676368397144,
          "from_longitude": 105.81685237913263
        }
      },
      {
        "id": 7407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81644734367391,
            21.02322109173339,
            105.81650956275938,
            21.023466290802528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8165047138355,
              21.023466290802528
            ],
            [
              105.81650956275938,
              21.023462671777818
            ],
            [
              105.81644734367391,
              21.02322109173339
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Thành Công",
          "maTaiSan": "04.O9C.DODV.7415",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023466290802528,
          "to_latitude": 21.02322109173339,
          "to_longitude": 105.81644734367391,
          "from_longitude": 105.8165047138355
        }
      },
      {
        "id": 7408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81659438917906,
            21.022520729112166,
            105.81703636524948,
            21.022655114799424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81659438917906,
              21.022655114799424
            ],
            [
              105.8168548972506,
              21.022601372832018
            ],
            [
              105.81689142268216,
              21.022554728192535
            ],
            [
              105.81692127310866,
              21.022542378614084
            ],
            [
              105.81694083905249,
              21.02253428385316
            ],
            [
              105.81698875692818,
              21.022527840391387
            ],
            [
              105.81702903608631,
              21.02252182380438
            ],
            [
              105.81703636524948,
              21.022520729112166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Thành Công",
          "maTaiSan": "04.O9C.DODV.7416",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022655114799424,
          "to_latitude": 21.022520729112166,
          "to_longitude": 105.81703636524948,
          "from_longitude": 105.81659438917906
        }
      },
      {
        "id": 7409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8176153465913,
            21.02100334437781,
            105.81765432042174,
            21.021031674467512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81765432042174,
              21.02100334437781
            ],
            [
              105.8176263757313,
              21.021023754345375
            ],
            [
              105.81762507455572,
              21.021024705375346
            ],
            [
              105.81761812817714,
              21.02102965232018
            ],
            [
              105.8176153465913,
              21.021031674467512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7417",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02100334437781,
          "to_latitude": 21.021031674467512,
          "to_longitude": 105.8176153465913,
          "from_longitude": 105.81765432042174
        }
      },
      {
        "id": 7410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81805254169909,
            21.022195435430316,
            105.81818744714242,
            21.0222572848226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81805254169909,
              21.022224350810266
            ],
            [
              105.81809768249988,
              21.0222572848226
            ],
            [
              105.81818744714242,
              21.022195435430316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Thành Công",
          "maTaiSan": "04.O9C.DODV.7418",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022224350810266,
          "to_latitude": 21.022195435430316,
          "to_longitude": 105.81818744714242,
          "from_longitude": 105.81805254169909
        }
      },
      {
        "id": 7411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81682773497974,
            21.023404885614756,
            105.81682947868222,
            21.023409759181156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81682773497974,
              21.023404885614756
            ],
            [
              105.8168281042364,
              21.02340591803603
            ],
            [
              105.8168288544901,
              21.02340801895123
            ],
            [
              105.81682947868222,
              21.023409759181156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Thành Công",
          "maTaiSan": "04.O9C.DODV.7419",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023404885614756,
          "to_latitude": 21.023409759181156,
          "to_longitude": 105.81682947868222,
          "from_longitude": 105.81682773497974
        }
      },
      {
        "id": 7412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81572613538788,
            21.020510880417888,
            105.81576316668183,
            21.020575639446008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81572613538788,
              21.020510880417888
            ],
            [
              105.81575742009531,
              21.02056348066356
            ],
            [
              105.81575937346084,
              21.020566767171818
            ],
            [
              105.81576044437793,
              21.020570878945723
            ],
            [
              105.81576316668183,
              21.020575639446008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7420",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020510880417888,
          "to_latitude": 21.020575639446008,
          "to_longitude": 105.81576316668183,
          "from_longitude": 105.81572613538788
        }
      },
      {
        "id": 7413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81801783883014,
            21.02278320171134,
            105.81850106461842,
            21.022900388419764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81802436258127,
              21.022869400124623
            ],
            [
              105.81801783883014,
              21.022870891115385
            ],
            [
              105.81802086055205,
              21.022878848460582
            ],
            [
              105.81802708133118,
              21.02287719092672
            ],
            [
              105.81804131054075,
              21.02287339848565
            ],
            [
              105.81828327312695,
              21.022794958090707
            ],
            [
              105.8183347078977,
              21.02278320171134
            ],
            [
              105.818345369808,
              21.022816203086407
            ],
            [
              105.81837256864962,
              21.022900388419764
            ],
            [
              105.81850106461842,
              21.022876176912238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_04_Thành Công",
          "maTaiSan": "04.O9C.DODV.7421",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022869400124623,
          "to_latitude": 21.022876176912238,
          "to_longitude": 105.81850106461842,
          "from_longitude": 105.81802436258127
        }
      },
      {
        "id": 7414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8155957174983,
            21.02059373200617,
            105.81565740073253,
            21.020633110488497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8155957174983,
              21.02059373200617
            ],
            [
              105.81563946296939,
              21.020605014006275
            ],
            [
              105.81564790114444,
              21.020618056790855
            ],
            [
              105.81564852107041,
              21.020619017561692
            ],
            [
              105.81565590293505,
              21.02063098695767
            ],
            [
              105.81565740073253,
              21.020633110488497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7422",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02059373200617,
          "to_latitude": 21.020633110488497,
          "to_longitude": 105.81565740073253,
          "from_longitude": 105.8155957174983
        }
      },
      {
        "id": 7415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172039365744,
            21.022043290480244,
            105.81741223512505,
            21.02242493935127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81739594925803,
              21.02242493935127
            ],
            [
              105.81739577861474,
              21.022424165202768
            ],
            [
              105.81739507023785,
              21.022420923313206
            ],
            [
              105.81741223512505,
              21.02240667009585
            ],
            [
              105.81738161152842,
              21.022230233716012
            ],
            [
              105.81729587378422,
              21.022240446638733
            ],
            [
              105.81728914933848,
              21.02219593631937
            ],
            [
              105.81729380214325,
              21.022185468143476
            ],
            [
              105.81728906405729,
              21.022123283871522
            ],
            [
              105.81727177221562,
              21.022043290480244
            ],
            [
              105.8172039365744,
              21.022047241067757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Thành Công",
          "maTaiSan": "04.O9C.DODV.7423",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02242493935127,
          "to_latitude": 21.022047241067757,
          "to_longitude": 105.8172039365744,
          "from_longitude": 105.81739594925803
        }
      },
      {
        "id": 7416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8163198552158,
            21.020499982940766,
            105.81637453995134,
            21.020536466605318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637453995134,
              21.020499982940766
            ],
            [
              105.81634007211598,
              21.020521996526327
            ],
            [
              105.81633601729865,
              21.0205251574105
            ],
            [
              105.81632919221988,
              21.02053047856064
            ],
            [
              105.81632016864141,
              21.020536257358895
            ],
            [
              105.8163198552158,
              21.020536466605318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7424",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020499982940766,
          "to_latitude": 21.020536466605318,
          "to_longitude": 105.8163198552158,
          "from_longitude": 105.81637453995134
        }
      },
      {
        "id": 7417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81693330107515,
            21.020825615091074,
            105.816972623564,
            21.020862359358592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81693330107515,
              21.020862359358592
            ],
            [
              105.8169571261665,
              21.02084591910085
            ],
            [
              105.81695892570099,
              21.02083756193029
            ],
            [
              105.81695969611216,
              21.020834006741314
            ],
            [
              105.816972623564,
              21.020825615091074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7425",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020862359358592,
          "to_latitude": 21.020825615091074,
          "to_longitude": 105.816972623564,
          "from_longitude": 105.81693330107515
        }
      },
      {
        "id": 7418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81636289456043,
            21.02365073132446,
            105.8164131265909,
            21.023665975945413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81636289456043,
              21.023665975945413
            ],
            [
              105.81637246883291,
              21.02366307037268
            ],
            [
              105.81639545802908,
              21.02365609278859
            ],
            [
              105.81639757668403,
              21.02365545035988
            ],
            [
              105.8164131265909,
              21.02365073132446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Thành Công",
          "maTaiSan": "04.O9C.DODV.7426",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023665975945413,
          "to_latitude": 21.02365073132446,
          "to_longitude": 105.8164131265909,
          "from_longitude": 105.81636289456043
        }
      },
      {
        "id": 7419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81579855844112,
            21.021846597594276,
            105.81587345801697,
            21.022042280165422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81587345801697,
              21.022042280165422
            ],
            [
              105.81587279046417,
              21.02203963962183
            ],
            [
              105.81587263618734,
              21.022038866296242
            ],
            [
              105.81587142389257,
              21.022032818683382
            ],
            [
              105.81584260140085,
              21.0219596925729
            ],
            [
              105.81579855844112,
              21.021846597594276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Thành Công",
          "maTaiSan": "04.O9C.DODV.7427",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022042280165422,
          "to_latitude": 21.021846597594276,
          "to_longitude": 105.81579855844112,
          "from_longitude": 105.81587345801697
        }
      },
      {
        "id": 7420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81673305606765,
            21.022009268405107,
            105.81690292786702,
            21.022082130989016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81673305606765,
              21.022082130989016
            ],
            [
              105.81690292786702,
              21.022009268405107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_DV9c.69.1_Thành Công",
          "maTaiSan": "04.O9C.DODV.7428",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022082130989016,
          "to_latitude": 21.022009268405107,
          "to_longitude": 105.81690292786702,
          "from_longitude": 105.81673305606765
        }
      },
      {
        "id": 7421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81726821681083,
            21.020850253862946,
            105.81731131178982,
            21.020881579809412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81731131178982,
              21.020850253862946
            ],
            [
              105.8173112772926,
              21.020850278415836
            ],
            [
              105.81730909998612,
              21.020853212623567
            ],
            [
              105.81730729797339,
              21.020855640124115
            ],
            [
              105.81726821681083,
              21.020881579809412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7429",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020850253862946,
          "to_latitude": 21.020881579809412,
          "to_longitude": 105.81726821681083,
          "from_longitude": 105.81731131178982
        }
      },
      {
        "id": 7422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81614253087409,
            21.022935502119232,
            105.81651662525925,
            21.02310724327399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81614253087409,
              21.02298728393427
            ],
            [
              105.81617350462427,
              21.022977860064685
            ],
            [
              105.8161965074885,
              21.022987194722603
            ],
            [
              105.81636438427789,
              21.022935502119232
            ],
            [
              105.81640817006148,
              21.02303973839818
            ],
            [
              105.81644248299774,
              21.023027866061238
            ],
            [
              105.81647243574784,
              21.02310724327399
            ],
            [
              105.81651662525925,
              21.023094634346872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Thành Công",
          "maTaiSan": "04.O9C.DODV.7430",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02298728393427,
          "to_latitude": 21.023094634346872,
          "to_longitude": 105.81651662525925,
          "from_longitude": 105.81614253087409
        }
      },
      {
        "id": 7423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81691501697205,
            21.020089789827356,
            105.81699717273226,
            21.020147569133687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81699717273226,
              21.020089789827356
            ],
            [
              105.81692870049835,
              21.02013794546771
            ],
            [
              105.81692648874632,
              21.020139500608337
            ],
            [
              105.81692264980863,
              21.020142200726614
            ],
            [
              105.81691501697205,
              21.020147569133687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7431",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020089789827356,
          "to_latitude": 21.020147569133687,
          "to_longitude": 105.81691501697205,
          "from_longitude": 105.81699717273226
        }
      },
      {
        "id": 7424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81682607693776,
            21.02366541850352,
            105.81686196716848,
            21.023799835044922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81686196716848,
              21.023799835044922
            ],
            [
              105.81682607693776,
              21.02366541850352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9c.44_La Thành",
          "maTaiSan": "04.O9C.DODV.7432",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023799835044922,
          "to_latitude": 21.02366541850352,
          "to_longitude": 105.81682607693776,
          "from_longitude": 105.81686196716848
        }
      },
      {
        "id": 7425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81810360973786,
            21.022269865671966,
            105.81820925930937,
            21.022366331774677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81810360973786,
              21.022366331774677
            ],
            [
              105.8181268480576,
              21.022347588237423
            ],
            [
              105.81818991910629,
              21.022288106338806
            ],
            [
              105.81820925930937,
              21.022269865671966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Thành Công",
          "maTaiSan": "04.O9C.DODV.7433",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022366331774677,
          "to_latitude": 21.022269865671966,
          "to_longitude": 105.81820925930937,
          "from_longitude": 105.81810360973786
        }
      },
      {
        "id": 7426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81649790898845,
            21.020113430983393,
            105.81652599380669,
            21.02015780178698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81649790898845,
              21.020113430983393
            ],
            [
              105.81651542415628,
              21.020141103079112
            ],
            [
              105.816518269135,
              21.02014560013912
            ],
            [
              105.81652599380669,
              21.02015780178698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7434",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020113430983393,
          "to_latitude": 21.02015780178698,
          "to_longitude": 105.81652599380669,
          "from_longitude": 105.81649790898845
        }
      },
      {
        "id": 7427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81742601011246,
            21.022779982636692,
            105.8174861161622,
            21.022859156865522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81744306413391,
              21.022859156865522
            ],
            [
              105.81742601011246,
              21.022798468830274
            ],
            [
              105.8174861161622,
              21.022779982636692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_07_Thành Công",
          "maTaiSan": "04.O9C.DODV.7435",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022859156865522,
          "to_latitude": 21.022779982636692,
          "to_longitude": 105.8174861161622,
          "from_longitude": 105.81744306413391
        }
      },
      {
        "id": 7428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81768050778933,
            21.021843330950432,
            105.81772739658686,
            21.021863090455682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81772739658686,
              21.021843330950432
            ],
            [
              105.81768142533444,
              21.021862703979732
            ],
            [
              105.81768050778933,
              21.021863090455682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Thành Công",
          "maTaiSan": "04.O9C.DODV.7436",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021843330950432,
          "to_latitude": 21.021863090455682,
          "to_longitude": 105.81768050778933,
          "from_longitude": 105.81772739658686
        }
      },
      {
        "id": 7429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175055390381,
            21.022850275934513,
            105.8175546097696,
            21.023019965915868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175055390381,
              21.022850275934513
            ],
            [
              105.8175546097696,
              21.023019965915868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7437",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022850275934513,
          "to_latitude": 21.023019965915868,
          "to_longitude": 105.8175546097696,
          "from_longitude": 105.8175055390381
        }
      },
      {
        "id": 7430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8165814425425,
            21.021715162873157,
            105.81684514732737,
            21.021834389334103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81684514732737,
              21.02183159755321
            ],
            [
              105.8167406943573,
              21.021715162873157
            ],
            [
              105.81666874173135,
              21.02176382920028
            ],
            [
              105.81665980867312,
              21.02177104913311
            ],
            [
              105.8165814425425,
              21.021834389334103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69_Thành Công",
          "maTaiSan": "04.O9C.DODV.7438",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02183159755321,
          "to_latitude": 21.021834389334103,
          "to_longitude": 105.8165814425425,
          "from_longitude": 105.81684514732737
        }
      },
      {
        "id": 7431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81926956245202,
            21.02340761692095,
            105.81941672242273,
            21.023429510128835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81926956245202,
              21.023429510128835
            ],
            [
              105.81941672242273,
              21.02340761692095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_La Thành",
          "maTaiSan": "04.O9C.DODV.7439",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023429510128835,
          "to_latitude": 21.02340761692095,
          "to_longitude": 105.81941672242273,
          "from_longitude": 105.81926956245202
        }
      },
      {
        "id": 7432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81614211995735,
            21.0228787550838,
            105.81702493417492,
            21.023180675213272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81614211995735,
              21.022986019581893
            ],
            [
              105.81617199198406,
              21.022977433774514
            ],
            [
              105.81636187282584,
              21.02292901906903
            ],
            [
              105.81647431152726,
              21.022893820722036
            ],
            [
              105.81652243847594,
              21.0228787550838
            ],
            [
              105.81656243825562,
              21.022974565228726
            ],
            [
              105.8165808113065,
              21.02298588116087
            ],
            [
              105.81661034999814,
              21.02303779501334
            ],
            [
              105.81661337414707,
              21.02305239289765
            ],
            [
              105.8167293705861,
              21.023025193437928
            ],
            [
              105.81675989249199,
              21.023161344701418
            ],
            [
              105.81696902914223,
              21.023122051566055
            ],
            [
              105.81701494071343,
              21.023115118244863
            ],
            [
              105.81702493417492,
              21.023180675213272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74_Thành Công",
          "maTaiSan": "04.O9C.DODV.7440",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 114,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022986019581893,
          "to_latitude": 21.023180675213272,
          "to_longitude": 105.81702493417492,
          "from_longitude": 105.81614211995735
        }
      },
      {
        "id": 7433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81664521401616,
            21.02042375697272,
            105.81710890217929,
            21.020757599107288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81664521401616,
              21.020757599107288
            ],
            [
              105.81672261961066,
              21.02070186957291
            ],
            [
              105.81685644191508,
              21.02060552192942
            ],
            [
              105.81686919382221,
              21.02059634080652
            ],
            [
              105.81690462088989,
              21.020570834245834
            ],
            [
              105.81692287484847,
              21.020557691334538
            ],
            [
              105.81710890217929,
              21.02042375697272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7441",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020757599107288,
          "to_latitude": 21.02042375697272,
          "to_longitude": 105.81710890217929,
          "from_longitude": 105.81664521401616
        }
      },
      {
        "id": 7434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81886927067015,
            21.023354603108885,
            105.81890437045695,
            21.023484884397092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81890437045695,
              21.023484884397092
            ],
            [
              105.81886927067015,
              21.023354603108885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_La Thành",
          "maTaiSan": "04.O9C.DODV.7442",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023484884397092,
          "to_latitude": 21.023354603108885,
          "to_longitude": 105.81886927067015,
          "from_longitude": 105.81890437045695
        }
      },
      {
        "id": 7435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81776248324522,
            21.02205413083246,
            105.81776753815414,
            21.022055949847665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81776753815414,
              21.02205413083246
            ],
            [
              105.81776590791092,
              21.02205471763892
            ],
            [
              105.81776248324522,
              21.022055949847665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Thành Công",
          "maTaiSan": "04.O9C.DODV.7443",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02205413083246,
          "to_latitude": 21.022055949847665,
          "to_longitude": 105.81776248324522,
          "from_longitude": 105.81776753815414
        }
      },
      {
        "id": 7436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81923226739191,
            21.02332512439207,
            105.81927331616464,
            21.023444127927277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81923226739191,
              21.02332762700029
            ],
            [
              105.81925209569857,
              21.02332512439207
            ],
            [
              105.81925285734354,
              21.0233303603396
            ],
            [
              105.81926454506497,
              21.023410736350186
            ],
            [
              105.81926956245202,
              21.023429510128835
            ],
            [
              105.81927047188128,
              21.023432919040562
            ],
            [
              105.81927331616464,
              21.023444127927277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_La Thành",
          "maTaiSan": "04.O9C.DODV.7444",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02332762700029,
          "to_latitude": 21.023444127927277,
          "to_longitude": 105.81927331616464,
          "from_longitude": 105.81923226739191
        }
      },
      {
        "id": 7437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157734617907,
            21.02159238864335,
            105.81648096683949,
            21.021843673185355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157734617907,
              21.021843673185355
            ],
            [
              105.81579374517848,
              21.021836431374915
            ],
            [
              105.81595468627015,
              21.0217789665228
            ],
            [
              105.81609565314855,
              21.02172900743481
            ],
            [
              105.81622894739377,
              21.02169216378005
            ],
            [
              105.81623840580355,
              21.021689025741072
            ],
            [
              105.8163191345698,
              21.021662252342384
            ],
            [
              105.81638453439356,
              21.021639758809883
            ],
            [
              105.81641935784258,
              21.021627006097585
            ],
            [
              105.81645062926295,
              21.02161153003763
            ],
            [
              105.81648096683949,
              21.02159238864335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_72_Thành Công",
          "maTaiSan": "04.O9C.DODV.7445",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021843673185355,
          "to_latitude": 21.02159238864335,
          "to_longitude": 105.81648096683949,
          "from_longitude": 105.8157734617907
        }
      },
      {
        "id": 7438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81778934459703,
            21.02211467394811,
            105.8181261293527,
            21.022388472637306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81778934459703,
              21.022156127643854
            ],
            [
              105.81779263254813,
              21.02215557983452
            ],
            [
              105.81790335104319,
              21.022137097627446
            ],
            [
              105.81790661305418,
              21.022131089031785
            ],
            [
              105.81796104861166,
              21.02211467394811
            ],
            [
              105.81802807036237,
              21.02217172367964
            ],
            [
              105.81805178818149,
              21.022209663451967
            ],
            [
              105.81805254169909,
              21.022224350810266
            ],
            [
              105.8180534588437,
              21.022242228481474
            ],
            [
              105.81803410634724,
              21.022271443394718
            ],
            [
              105.81807239622101,
              21.022335641232516
            ],
            [
              105.818075419601,
              21.0223386145646
            ],
            [
              105.81810360973786,
              21.022366331774677
            ],
            [
              105.8181261293527,
              21.022388472637306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Thành Công",
          "maTaiSan": "04.O9C.DODV.7446",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022156127643854,
          "to_latitude": 21.022388472637306,
          "to_longitude": 105.8181261293527,
          "from_longitude": 105.81778934459703
        }
      },
      {
        "id": 7439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81656659521805,
            21.02337402779731,
            105.81699415363231,
            21.02351811893297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81656659521805,
              21.02351811893297
            ],
            [
              105.816723393468,
              21.02346788547863
            ],
            [
              105.81674925090903,
              21.023458898846325
            ],
            [
              105.81674091734085,
              21.023435262581486
            ],
            [
              105.81680098217521,
              21.023416387529142
            ],
            [
              105.8168278534,
              21.0234102005357
            ],
            [
              105.81682947868222,
              21.023409759181156
            ],
            [
              105.81693203751207,
              21.02338188888692
            ],
            [
              105.81699415363231,
              21.02337402779731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78_Thành Công",
          "maTaiSan": "04.O9C.DODV.7447",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02351811893297,
          "to_latitude": 21.02337402779731,
          "to_longitude": 105.81699415363231,
          "from_longitude": 105.81656659521805
        }
      },
      {
        "id": 7440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81609337574378,
            21.02033343033205,
            105.81612241235095,
            21.020379302316663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81609337574378,
              21.02033343033205
            ],
            [
              105.81611073075364,
              21.02036084762522
            ],
            [
              105.81611491087362,
              21.020367453644393
            ],
            [
              105.81612241235095,
              21.020379302316663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7448",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02033343033205,
          "to_latitude": 21.020379302316663,
          "to_longitude": 105.81612241235095,
          "from_longitude": 105.81609337574378
        }
      },
      {
        "id": 7441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8176720373399,
            21.023213955540662,
            105.8176750923102,
            21.02322254318439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176750923102,
              21.02322254318439
            ],
            [
              105.81767498484415,
              21.023222241120106
            ],
            [
              105.81767441237105,
              21.023220637032082
            ],
            [
              105.817673842774,
              21.023219031123787
            ],
            [
              105.8176720373399,
              21.023213955540662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Thành Công",
          "maTaiSan": "04.O9C.DODV.7449",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02322254318439,
          "to_latitude": 21.023213955540662,
          "to_longitude": 105.8176720373399,
          "from_longitude": 105.8176750923102
        }
      },
      {
        "id": 7442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81658249501119,
            21.023271894661786,
            105.81671035251311,
            21.02331049037756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81658249501119,
              21.02329468092163
            ],
            [
              105.81670093315917,
              21.023271894661786
            ],
            [
              105.81671035251311,
              21.02331049037756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Thành Công",
          "maTaiSan": "04.O9C.DODV.7450",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02329468092163,
          "to_latitude": 21.02331049037756,
          "to_longitude": 105.81671035251311,
          "from_longitude": 105.81658249501119
        }
      },
      {
        "id": 7443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81606403526719,
            21.021675155537565,
            105.81659655481687,
            21.021950567190785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81606403526719,
              21.021950567190785
            ],
            [
              105.81625353405059,
              21.021849339170558
            ],
            [
              105.81626776939486,
              21.02184173432811
            ],
            [
              105.81643663621924,
              21.02175108616944
            ],
            [
              105.81646607864427,
              21.021762939696504
            ],
            [
              105.81653870567786,
              21.02172186816138
            ],
            [
              105.81659655481687,
              21.021675155537565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Thành Công",
          "maTaiSan": "04.O9C.DODV.7451",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021950567190785,
          "to_latitude": 21.021675155537565,
          "to_longitude": 105.81659655481687,
          "from_longitude": 105.81606403526719
        }
      },
      {
        "id": 7444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81695430718128,
            21.022731999220436,
            105.81785673276231,
            21.022955968290557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81785673276231,
              21.022741027697407
            ],
            [
              105.81785306451174,
              21.0227411672721
            ],
            [
              105.81781405602622,
              21.022731999220436
            ],
            [
              105.81780124209286,
              21.02273459167488
            ],
            [
              105.81776528715794,
              21.022741864816933
            ],
            [
              105.81773763941925,
              21.022747457751887
            ],
            [
              105.8176775565109,
              21.02277211928301
            ],
            [
              105.81765697568359,
              21.022780566749514
            ],
            [
              105.81753630792402,
              21.022830472019567
            ],
            [
              105.81744306413391,
              21.022859156865522
            ],
            [
              105.81738006638288,
              21.02287853758783
            ],
            [
              105.81728174740701,
              21.02290304492624
            ],
            [
              105.81718042623041,
              21.022926175663937
            ],
            [
              105.81710626166976,
              21.02293902451209
            ],
            [
              105.81703028490894,
              21.022947495964242
            ],
            [
              105.81695430718128,
              21.022955968290557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_07_Thành Công",
          "maTaiSan": "04.O9C.DODV.7452",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022741027697407,
          "to_latitude": 21.022955968290557,
          "to_longitude": 105.81695430718128,
          "from_longitude": 105.81785673276231
        }
      },
      {
        "id": 7445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690991126393,
            21.01967879980035,
            105.81735162332988,
            21.020257434860724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81690991126393,
              21.01967879980035
            ],
            [
              105.81696932008994,
              21.019756623759406
            ],
            [
              105.81696947710533,
              21.019756839779802
            ],
            [
              105.81735162332988,
              21.020257434860724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2h_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7453",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01967879980035,
          "to_latitude": 21.020257434860724,
          "to_longitude": 105.81735162332988,
          "from_longitude": 105.81690991126393
        }
      },
      {
        "id": 7446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81672261961066,
            21.02070186957291,
            105.81673450477892,
            21.020715469298935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81673450477892,
              21.020715469298935
            ],
            [
              105.81673266751628,
              21.020713369085506
            ],
            [
              105.81672371150522,
              21.020703116202892
            ],
            [
              105.81672342013896,
              21.02070278340787
            ],
            [
              105.81672261961066,
              21.02070186957291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7454",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020715469298935,
          "to_latitude": 21.02070186957291,
          "to_longitude": 105.81672261961066,
          "from_longitude": 105.81673450477892
        }
      },
      {
        "id": 7447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81664529835774,
            21.022226612549403,
            105.81715904504016,
            21.022495114945464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81715904504016,
              21.022495114945464
            ],
            [
              105.81715903248404,
              21.022494399650135
            ],
            [
              105.81715879660351,
              21.02248024270282
            ],
            [
              105.81714245142874,
              21.022394081132717
            ],
            [
              105.81712643188638,
              21.022391853060135
            ],
            [
              105.81707834147782,
              21.0223851617601
            ],
            [
              105.81699880654158,
              21.022379848074404
            ],
            [
              105.8170135610594,
              21.02231833970904
            ],
            [
              105.81695656508995,
              21.02230007746051
            ],
            [
              105.81690906391431,
              21.022301240452038
            ],
            [
              105.81684184464943,
              21.022313467819114
            ],
            [
              105.81679870226212,
              21.02232029114389
            ],
            [
              105.81679020652966,
              21.022321972202718
            ],
            [
              105.81677840445901,
              21.022286743416036
            ],
            [
              105.81667947799964,
              21.02231234983451
            ],
            [
              105.81664529835774,
              21.022226612549403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11_Thành Công",
          "maTaiSan": "04.O9C.DODV.7455",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 126,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022495114945464,
          "to_latitude": 21.022226612549403,
          "to_longitude": 105.81664529835774,
          "from_longitude": 105.81715904504016
        }
      },
      {
        "id": 7448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81784227171379,
            21.022516041327,
            105.81838453797114,
            21.022654695471605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81784227171379,
              21.022654695471605
            ],
            [
              105.81784241496692,
              21.022654680331147
            ],
            [
              105.81784608460856,
              21.02265426707239
            ],
            [
              105.81785661424233,
              21.02264328649393
            ],
            [
              105.8179910285715,
              21.02262213775771
            ],
            [
              105.81799897605761,
              21.022639163293594
            ],
            [
              105.81827438397472,
              21.022549511086243
            ],
            [
              105.81828239941596,
              21.02254690194068
            ],
            [
              105.8183367350186,
              21.022530484505648
            ],
            [
              105.81838453797114,
              21.022516041327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Thành Công",
          "maTaiSan": "04.O9C.DODV.7456",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022654695471605,
          "to_latitude": 21.022516041327,
          "to_longitude": 105.81838453797114,
          "from_longitude": 105.81784227171379
        }
      },
      {
        "id": 7449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81631260570725,
            21.021442092271464,
            105.8163538493317,
            21.02153129032335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8163538493317,
              21.02153129032335
            ],
            [
              105.8163306760392,
              21.021466048547712
            ],
            [
              105.81632339554976,
              21.021461409289255
            ],
            [
              105.81631318955885,
              21.021443139922496
            ],
            [
              105.81631260570725,
              21.021442092271464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7457",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02153129032335,
          "to_latitude": 21.021442092271464,
          "to_longitude": 105.81631260570725,
          "from_longitude": 105.8163538493317
        }
      },
      {
        "id": 7450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81749035108272,
            21.020845260643465,
            105.81752880787833,
            21.020873142025412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81752880787833,
              21.020845260643465
            ],
            [
              105.81749898083778,
              21.020866769832196
            ],
            [
              105.81749699064153,
              21.020868204689453
            ],
            [
              105.81749235465281,
              21.02087168994264
            ],
            [
              105.81749035108272,
              21.020873142025412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7458",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020845260643465,
          "to_latitude": 21.020873142025412,
          "to_longitude": 105.81749035108272,
          "from_longitude": 105.81752880787833
        }
      },
      {
        "id": 7451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81622985348565,
            21.02117292849621,
            105.816246576715,
            21.021221042637706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816246576715,
              21.021221042637706
            ],
            [
              105.81623572205781,
              21.021189813262275
            ],
            [
              105.81623505361166,
              21.021187892596213
            ],
            [
              105.81622985348565,
              21.02117292849621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7459",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021221042637706,
          "to_latitude": 21.02117292849621,
          "to_longitude": 105.81622985348565,
          "from_longitude": 105.816246576715
        }
      },
      {
        "id": 7452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81773457790946,
            21.023633330377336,
            105.81792911447639,
            21.023658402752407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81773457790946,
              21.023658402752407
            ],
            [
              105.81777155490948,
              21.023653637473505
            ],
            [
              105.8178439246462,
              21.023644309606926
            ],
            [
              105.81784973771722,
              21.02364355997557
            ],
            [
              105.81792911447639,
              21.023633330377336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_La Thành",
          "maTaiSan": "04.O9C.DODV.7460",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023658402752407,
          "to_latitude": 21.023633330377336,
          "to_longitude": 105.81792911447639,
          "from_longitude": 105.81773457790946
        }
      },
      {
        "id": 7453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81816157602475,
            21.023523391516076,
            105.8181766004745,
            21.023527783021663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81816157602475,
              21.023527783021663
            ],
            [
              105.81816227523618,
              21.02352757823855
            ],
            [
              105.81816267094482,
              21.023527462528445
            ],
            [
              105.81816614012124,
              21.0235264486777
            ],
            [
              105.8181766004745,
              21.023523391516076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_Thành Công",
          "maTaiSan": "04.O9C.DODV.7461",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023527783021663,
          "to_latitude": 21.023523391516076,
          "to_longitude": 105.8181766004745,
          "from_longitude": 105.81816157602475
        }
      },
      {
        "id": 7454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81645028084509,
            21.020670352547267,
            105.81648439734737,
            21.020693115953918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648439734737,
              21.020670352547267
            ],
            [
              105.81646303452028,
              21.020684606853163
            ],
            [
              105.81646073414309,
              21.020686141639157
            ],
            [
              105.81645093932761,
              21.020692676534512
            ],
            [
              105.81645028084509,
              21.020693115953918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7462",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020670352547267,
          "to_latitude": 21.020693115953918,
          "to_longitude": 105.81645028084509,
          "from_longitude": 105.81648439734737
        }
      },
      {
        "id": 7455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157918143584,
            21.020833480709097,
            105.8166202628955,
            21.021297287184346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157918143584,
              21.021297287184346
            ],
            [
              105.81616736050405,
              21.021180144381972
            ],
            [
              105.81621407360703,
              21.02116557336931
            ],
            [
              105.8162550447971,
              21.021152792998212
            ],
            [
              105.8163275721345,
              21.02113038180436
            ],
            [
              105.81632409756992,
              21.021120502723548
            ],
            [
              105.81641694188345,
              21.02108768364694
            ],
            [
              105.8164672550513,
              21.02106223398055
            ],
            [
              105.81660765548125,
              21.020987022074205
            ],
            [
              105.8166202628955,
              21.02095748202114
            ],
            [
              105.81658927160511,
              21.020904425312207
            ],
            [
              105.816544247786,
              21.020833480709097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7463",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021297287184346,
          "to_latitude": 21.020833480709097,
          "to_longitude": 105.816544247786,
          "from_longitude": 105.8157918143584
        }
      },
      {
        "id": 7456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8169532675152,
            21.02274510904244,
            105.81786074726334,
            21.022966252908823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81786074726334,
              21.02274510904244
            ],
            [
              105.81786024956352,
              21.022748042406295
            ],
            [
              105.81784077431018,
              21.022745598890317
            ],
            [
              105.81780500575262,
              21.022746477202812
            ],
            [
              105.81772934895781,
              21.022760673882544
            ],
            [
              105.81766798323677,
              21.022786482353343
            ],
            [
              105.81753603748469,
              21.022839447780306
            ],
            [
              105.8175055390381,
              21.022850275934513
            ],
            [
              105.81747713942505,
              21.022860358769
            ],
            [
              105.8174021310566,
              21.022879345613198
            ],
            [
              105.8171817371754,
              21.02293513298789
            ],
            [
              105.8169532675152,
              21.022966252908823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7464",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02274510904244,
          "to_latitude": 21.022966252908823,
          "to_longitude": 105.8169532675152,
          "from_longitude": 105.81786074726334
        }
      },
      {
        "id": 7457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172849119374,
            21.023460303456414,
            105.817331996131,
            21.023716109443473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.817331996131,
              21.023716109443473
            ],
            [
              105.8172849119374,
              21.023460303456414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_43_La Thành",
          "maTaiSan": "04.O9C.DODV.7465",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023716109443473,
          "to_latitude": 21.023460303456414,
          "to_longitude": 105.8172849119374,
          "from_longitude": 105.817331996131
        }
      },
      {
        "id": 7458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81751501605265,
            21.0236896549465,
            105.81751876584097,
            21.023709934231317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81751876584097,
              21.023709934231317
            ],
            [
              105.81751824051521,
              21.02370709701187
            ],
            [
              105.81751747328939,
              21.02370294405072
            ],
            [
              105.81751636609707,
              21.023696956463947
            ],
            [
              105.81751501605265,
              21.0236896549465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42_La Thành",
          "maTaiSan": "04.O9C.DODV.7466",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023709934231317,
          "to_latitude": 21.0236896549465,
          "to_longitude": 105.81751501605265,
          "from_longitude": 105.81751876584097
        }
      },
      {
        "id": 7459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81719255701738,
            21.021162639744254,
            105.8172281566719,
            21.02118720603966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81719255701738,
              21.02118720603966
            ],
            [
              105.81722084048869,
              21.021167692321193
            ],
            [
              105.81722132350504,
              21.02116735851621
            ],
            [
              105.81722191674199,
              21.021166950117063
            ],
            [
              105.8172281566719,
              21.021162639744254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7467",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02118720603966,
          "to_latitude": 21.021162639744254,
          "to_longitude": 105.8172281566719,
          "from_longitude": 105.81719255701738
        }
      },
      {
        "id": 7460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81617751099509,
            21.021483905148845,
            105.81621348075487,
            21.021574297318672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81621348075487,
              21.021574297318672
            ],
            [
              105.81619028897279,
              21.021509006837178
            ],
            [
              105.81618780263895,
              21.02150200701604
            ],
            [
              105.81617796945132,
              21.021484714046895
            ],
            [
              105.81617751099509,
              21.021483905148845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7468",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021574297318672,
          "to_latitude": 21.021483905148845,
          "to_longitude": 105.81617751099509,
          "from_longitude": 105.81621348075487
        }
      },
      {
        "id": 7461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81658249501119,
            21.02329468092163,
            105.81659635712127,
            21.02337272361688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81659635712127,
              21.02337272361688
            ],
            [
              105.81658249501119,
              21.02329468092163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Thành Công",
          "maTaiSan": "04.O9C.DODV.7469",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02337272361688,
          "to_latitude": 21.02329468092163,
          "to_longitude": 105.81658249501119,
          "from_longitude": 105.81659635712127
        }
      },
      {
        "id": 7462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81782817450235,
            21.022331607647843,
            105.81792601715169,
            21.02264328649393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81785661424233,
              21.02264328649393
            ],
            [
              105.81784591949761,
              21.022549393310435
            ],
            [
              105.81784091207376,
              21.02251459438583
            ],
            [
              105.81782817450235,
              21.022389204667356
            ],
            [
              105.81792601715169,
              21.02237674203586
            ],
            [
              105.81791984994368,
              21.022331607647843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_Thành Công",
          "maTaiSan": "04.O9C.DODV.7470",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02264328649393,
          "to_latitude": 21.022331607647843,
          "to_longitude": 105.81791984994368,
          "from_longitude": 105.81785661424233
        }
      },
      {
        "id": 7463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81775796847214,
            21.022062616593626,
            105.81780139436589,
            21.022278708932262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81780139436589,
              21.022278708932262
            ],
            [
              105.81779460956795,
              21.02223444912133
            ],
            [
              105.8177944030981,
              21.02223310340299
            ],
            [
              105.81778828461556,
              21.022193190327776
            ],
            [
              105.81778296811534,
              21.022158511208122
            ],
            [
              105.81775796847214,
              21.022062616593626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Thành Công",
          "maTaiSan": "04.O9C.DODV.7471",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022278708932262,
          "to_latitude": 21.022062616593626,
          "to_longitude": 105.81775796847214,
          "from_longitude": 105.81780139436589
        }
      },
      {
        "id": 7464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81732262287224,
            21.021251280150803,
            105.81735088977105,
            21.021287143333485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81735088977105,
              21.021287143333485
            ],
            [
              105.81733025699184,
              21.02126096489882
            ],
            [
              105.81732888239348,
              21.021254799754427
            ],
            [
              105.81732880145175,
              21.02125477485302
            ],
            [
              105.81732428608593,
              21.021253390225898
            ],
            [
              105.81732304715055,
              21.021251818241762
            ],
            [
              105.81732262287224,
              21.021251280150803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7472",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021287143333485,
          "to_latitude": 21.021251280150803,
          "to_longitude": 105.81732262287224,
          "from_longitude": 105.81735088977105
        }
      },
      {
        "id": 7465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81951027602425,
            21.023176903960827,
            105.81957965128962,
            21.023309348458362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81957965128962,
              21.023309348458362
            ],
            [
              105.81956787013475,
              21.023278432694767
            ],
            [
              105.8195637485403,
              21.02326761745242
            ],
            [
              105.81956133276995,
              21.02326300186476
            ],
            [
              105.81955056143596,
              21.02324241956239
            ],
            [
              105.81951027602425,
              21.023176903960827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7473",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023309348458362,
          "to_latitude": 21.023176903960827,
          "to_longitude": 105.81951027602425,
          "from_longitude": 105.81957965128962
        }
      },
      {
        "id": 7466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81551432790012,
            21.021002487538876,
            105.81554937060531,
            21.021012235182855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81551432790012,
              21.021012235182855
            ],
            [
              105.81552780402036,
              21.021008487210363
            ],
            [
              105.81552962805098,
              21.021007979883453
            ],
            [
              105.81554250191718,
              21.02100439828151
            ],
            [
              105.8155450098389,
              21.021003700707368
            ],
            [
              105.81554937060531,
              21.021002487538876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7474",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021012235182855,
          "to_latitude": 21.021002487538876,
          "to_longitude": 105.81554937060531,
          "from_longitude": 105.81551432790012
        }
      },
      {
        "id": 7467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81844523543401,
            21.02352025229052,
            105.81870319600944,
            21.02356201348698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81870319600944,
              21.02352025229052
            ],
            [
              105.81869455752852,
              21.02352169658415
            ],
            [
              105.8186100939857,
              21.023535820442596
            ],
            [
              105.81850190987888,
              21.023554388484964
            ],
            [
              105.81844523543401,
              21.02356201348698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_La Thành",
          "maTaiSan": "04.O9C.DODV.7475",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02352025229052,
          "to_latitude": 21.02356201348698,
          "to_longitude": 105.81844523543401,
          "from_longitude": 105.81870319600944
        }
      },
      {
        "id": 7468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81731238845266,
            21.021258347401897,
            105.8173409740477,
            21.021294615507003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8173409740477,
              21.021294615507003
            ],
            [
              105.81731636873563,
              21.0212633971857
            ],
            [
              105.81731634742992,
              21.021263371094484
            ],
            [
              105.81731261705822,
              21.02125863714251
            ],
            [
              105.81731238845266,
              21.021258347401897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7476",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021294615507003,
          "to_latitude": 21.021258347401897,
          "to_longitude": 105.81731238845266,
          "from_longitude": 105.8173409740477
        }
      },
      {
        "id": 7469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81701443722451,
            21.022814750782445,
            105.81703028490894,
            21.022947495964242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81703028490894,
              21.022947495964242
            ],
            [
              105.81701443722451,
              21.022814750782445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_07_Thành Công",
          "maTaiSan": "04.O9C.DODV.7477",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022947495964242,
          "to_latitude": 21.022814750782445,
          "to_longitude": 105.81701443722451,
          "from_longitude": 105.81703028490894
        }
      },
      {
        "id": 7470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743533325812,
            21.021929925441896,
            105.81776248324522,
            21.02214485165967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81770515840125,
              21.021929925441896
            ],
            [
              105.8177299757155,
              21.02198128463739
            ],
            [
              105.81773056462733,
              21.0219827287754
            ],
            [
              105.81773920396579,
              21.022003857972404
            ],
            [
              105.81776248324522,
              21.022055949847665
            ],
            [
              105.8175980597175,
              21.02210979132746
            ],
            [
              105.81753386869389,
              21.02198841354912
            ],
            [
              105.81750085400984,
              21.02198585622961
            ],
            [
              105.81743533325812,
              21.021999925471345
            ],
            [
              105.81746224305715,
              21.02214485165967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Thành Công",
          "maTaiSan": "04.O9C.DODV.7478",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021929925441896,
          "to_latitude": 21.02214485165967,
          "to_longitude": 105.81746224305715,
          "from_longitude": 105.81770515840125
        }
      },
      {
        "id": 7471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81911122590492,
            21.023432439083116,
            105.81924279185957,
            21.023452787490818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81911122590492,
              21.023452787490818
            ],
            [
              105.81917972703414,
              21.023441829303888
            ],
            [
              105.81924279185957,
              21.023432439083116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_La Thành",
          "maTaiSan": "04.O9C.DODV.7479",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023452787490818,
          "to_latitude": 21.023432439083116,
          "to_longitude": 105.81924279185957,
          "from_longitude": 105.81911122590492
        }
      },
      {
        "id": 7472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157224310156,
            21.021072732663665,
            105.81652755588821,
            21.02134081949542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81652755588821,
              21.021072732663665
            ],
            [
              105.81638848536225,
              21.021135858364165
            ],
            [
              105.81637664542862,
              21.021139579583743
            ],
            [
              105.81626911982794,
              21.02118025220294
            ],
            [
              105.81624037749806,
              21.021188480339266
            ],
            [
              105.81623572205781,
              21.021189813262275
            ],
            [
              105.81620934826591,
              21.021197363445722
            ],
            [
              105.81609862842649,
              21.02123107114786
            ],
            [
              105.81609473406697,
              21.0212322568023
            ],
            [
              105.81605749707288,
              21.021243593927586
            ],
            [
              105.81604863502658,
              21.021246009222782
            ],
            [
              105.81596025946598,
              21.021270091426537
            ],
            [
              105.81595409848612,
              21.021271770274453
            ],
            [
              105.81590016540831,
              21.021286466172903
            ],
            [
              105.81587998498146,
              21.021292638079412
            ],
            [
              105.81587075382234,
              21.021295460685018
            ],
            [
              105.81581798006475,
              21.021311599773377
            ],
            [
              105.81581023557159,
              21.021313968147737
            ],
            [
              105.8157224310156,
              21.02134081949542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7480",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021072732663665,
          "to_latitude": 21.02134081949542,
          "to_longitude": 105.8157224310156,
          "from_longitude": 105.81652755588821
        }
      },
      {
        "id": 7473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81701756720376,
            21.023488981543174,
            105.8174220844053,
            21.023764227341015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81738382905867,
              21.023488981543174
            ],
            [
              105.8174220844053,
              21.023700700082518
            ],
            [
              105.81737189651822,
              21.02370937832903
            ],
            [
              105.817331996131,
              21.023716109443473
            ],
            [
              105.81722236639942,
              21.02373460414266
            ],
            [
              105.81720434178094,
              21.0237376452053
            ],
            [
              105.81704676423357,
              21.023764227341015
            ],
            [
              105.81701756720376,
              21.023623422683105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_43_La Thành",
          "maTaiSan": "04.O9C.DODV.7481",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023488981543174,
          "to_latitude": 21.023623422683105,
          "to_longitude": 105.81701756720376,
          "from_longitude": 105.81738382905867
        }
      },
      {
        "id": 7474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8170419942105,
            21.02025051438985,
            105.81712432418351,
            21.020308458981773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81712432418351,
              21.02025051438985
            ],
            [
              105.81705948601514,
              21.020296333138297
            ],
            [
              105.81705801073004,
              21.020297285000265
            ],
            [
              105.81705632167431,
              21.02029837427634
            ],
            [
              105.81705107596939,
              21.02030206657557
            ],
            [
              105.8170419942105,
              21.020308458981773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7482",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02025051438985,
          "to_latitude": 21.020308458981773,
          "to_longitude": 105.8170419942105,
          "from_longitude": 105.81712432418351
        }
      },
      {
        "id": 7475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81803347432617,
            21.02362789774615,
            105.81803480791986,
            21.023637469146333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81803347432617,
              21.02362789774615
            ],
            [
              105.81803382477285,
              21.02363041786914
            ],
            [
              105.8180341351245,
              21.02363264192666
            ],
            [
              105.81803454260601,
              21.023635565517615
            ],
            [
              105.81803480791986,
              21.023637469146333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39_La Thành",
          "maTaiSan": "04.O9C.DODV.7483",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02362789774615,
          "to_latitude": 21.023637469146333,
          "to_longitude": 105.81803480791986,
          "from_longitude": 105.81803347432617
        }
      },
      {
        "id": 7476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730511539811,
            21.021263370871946,
            105.81733358859383,
            21.021299490485926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81733358859383,
              21.021299490485926
            ],
            [
              105.81730905076336,
              21.02127253416721
            ],
            [
              105.81730768473547,
              21.021269353243245
            ],
            [
              105.81730511539811,
              21.021263370871946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7484",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021299490485926,
          "to_latitude": 21.021263370871946,
          "to_longitude": 105.81730511539811,
          "from_longitude": 105.81733358859383
        }
      },
      {
        "id": 7477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81796104861166,
            21.02198271343668,
            105.81803893157183,
            21.02211467394811
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81796104861166,
              21.02211467394811
            ],
            [
              105.81797520712291,
              21.022096876420456
            ],
            [
              105.81803893157183,
              21.0220771153466
            ],
            [
              105.81800687225493,
              21.02198271343668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Thành Công",
          "maTaiSan": "04.O9C.DODV.7485",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02211467394811,
          "to_latitude": 21.02198271343668,
          "to_longitude": 105.81800687225493,
          "from_longitude": 105.81796104861166
        }
      },
      {
        "id": 7478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81841088968423,
            21.023307423057737,
            105.81858648300451,
            21.023447847446597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81841088968423,
              21.023447847446597
            ],
            [
              105.81857997750689,
              21.02341645678895
            ],
            [
              105.81858648300451,
              21.023402985467815
            ],
            [
              105.81856324198095,
              21.023307423057737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_38_La Thành",
          "maTaiSan": "04.O9C.DODV.7486",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023447847446597,
          "to_latitude": 21.023307423057737,
          "to_longitude": 105.81856324198095,
          "from_longitude": 105.81841088968423
        }
      },
      {
        "id": 7479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8170062930415,
            21.023295258157482,
            105.81741714097176,
            21.023370665264626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8170062930415,
              21.023361858115887
            ],
            [
              105.8170212407716,
              21.023361037535775
            ],
            [
              105.81705146314873,
              21.023370665264626
            ],
            [
              105.81712639009972,
              21.023369693816726
            ],
            [
              105.81715197740532,
              21.02336551449156
            ],
            [
              105.81719430879214,
              21.023357749288333
            ],
            [
              105.81719791011639,
              21.023357153016295
            ],
            [
              105.81721393106191,
              21.0233545009382
            ],
            [
              105.81721949646092,
              21.023352965808147
            ],
            [
              105.81722993476173,
              21.02334780248573
            ],
            [
              105.81723794297606,
              21.023345444519705
            ],
            [
              105.81728346627418,
              21.023332039564387
            ],
            [
              105.81741714097176,
              21.023295258157482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Thành Công",
          "maTaiSan": "04.O9C.DODV.7487",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023361858115887,
          "to_latitude": 21.023295258157482,
          "to_longitude": 105.81741714097176,
          "from_longitude": 105.8170062930415
        }
      },
      {
        "id": 7480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81720434178094,
            21.0237376452053,
            105.81720804661249,
            21.023753566645226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81720804661249,
              21.023753566645226
            ],
            [
              105.81720778362359,
              21.023752440681964
            ],
            [
              105.81720681429942,
              21.02374827152899
            ],
            [
              105.81720587454822,
              21.023744233201768
            ],
            [
              105.81720434178094,
              21.0237376452053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_43_La Thành",
          "maTaiSan": "04.O9C.DODV.7488",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023753566645226,
          "to_latitude": 21.0237376452053,
          "to_longitude": 105.81720434178094,
          "from_longitude": 105.81720804661249
        }
      },
      {
        "id": 7481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157814763847,
            21.02160842753893,
            105.81620765032318,
            21.021737820783624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157814763847,
              21.021737820783624
            ],
            [
              105.81607584513235,
              21.02164241434958
            ],
            [
              105.81610297326188,
              21.021644243288563
            ],
            [
              105.81620765032318,
              21.02160842753893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7489",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021737820783624,
          "to_latitude": 21.02160842753893,
          "to_longitude": 105.81620765032318,
          "from_longitude": 105.8157814763847
        }
      },
      {
        "id": 7482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81631382107322,
            21.020491655251387,
            105.81636888055156,
            21.020528393616168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81636888055156,
              21.020491655251387
            ],
            [
              105.81633054480122,
              21.02051723437185
            ],
            [
              105.8163302150816,
              21.020517454535227
            ],
            [
              105.81632186947756,
              21.020523016918215
            ],
            [
              105.81631452364277,
              21.020527924179223
            ],
            [
              105.81631382107322,
              21.020528393616168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7490",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020491655251387,
          "to_latitude": 21.020528393616168,
          "to_longitude": 105.81631382107322,
          "from_longitude": 105.81636888055156
        }
      },
      {
        "id": 7483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637664542862,
            21.021139579583743,
            105.81644580211005,
            21.02134793645658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81644580211005,
              21.02134793645658
            ],
            [
              105.81637664542862,
              21.021139579583743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7491",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02134793645658,
          "to_latitude": 21.021139579583743,
          "to_longitude": 105.81637664542862,
          "from_longitude": 105.81644580211005
        }
      },
      {
        "id": 7484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81559131458543,
            21.020594976087384,
            105.8156542020078,
            21.02063484828161
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81559131458543,
              21.020594976087384
            ],
            [
              105.81563630948503,
              21.02060657996894
            ],
            [
              105.81564496637255,
              21.02062003989914
            ],
            [
              105.81564567558372,
              21.02062114475821
            ],
            [
              105.8156542020078,
              21.02063484828161
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7492",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020594976087384,
          "to_latitude": 21.02063484828161,
          "to_longitude": 105.8156542020078,
          "from_longitude": 105.81559131458543
        }
      },
      {
        "id": 7485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81827438397472,
            21.022549511086243,
            105.81829996131499,
            21.02264824166985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81827438397472,
              21.022549511086243
            ],
            [
              105.81829996131499,
              21.02264824166985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_08_Thành Công",
          "maTaiSan": "04.O9C.DODV.7493",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022549511086243,
          "to_latitude": 21.02264824166985,
          "to_longitude": 105.81829996131499,
          "from_longitude": 105.81827438397472
        }
      },
      {
        "id": 7486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81712643188638,
            21.022297002388747,
            105.81724319894035,
            21.022391853060135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81712643188638,
              21.022391853060135
            ],
            [
              105.81715549937492,
              21.02231581164593
            ],
            [
              105.81724319894035,
              21.022297002388747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Thành Công",
          "maTaiSan": "04.O9C.DODV.7494",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022391853060135,
          "to_latitude": 21.022297002388747,
          "to_longitude": 105.81724319894035,
          "from_longitude": 105.81712643188638
        }
      },
      {
        "id": 7487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81709277148784,
            21.020623915343304,
            105.81713548701204,
            21.020654963938178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81713548701204,
              21.020623915343304
            ],
            [
              105.81713516698181,
              21.020624148106826
            ],
            [
              105.81712833629393,
              21.020629141443848
            ],
            [
              105.81712029066057,
              21.02063502216663
            ],
            [
              105.81709277148784,
              21.020654963938178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7495",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020623915343304,
          "to_latitude": 21.020654963938178,
          "to_longitude": 105.81709277148784,
          "from_longitude": 105.81713548701204
        }
      },
      {
        "id": 7488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8155325748281,
            21.021041203672997,
            105.81562317031772,
            21.021068872175146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81562317031772,
              21.021041203672997
            ],
            [
              105.81556760145767,
              21.02105828911901
            ],
            [
              105.8155642805076,
              21.021059311237487
            ],
            [
              105.8155409358931,
              21.021066318405413
            ],
            [
              105.8155325748281,
              21.021068872175146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7496",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021041203672997,
          "to_latitude": 21.021068872175146,
          "to_longitude": 105.8155325748281,
          "from_longitude": 105.81562317031772
        }
      },
      {
        "id": 7489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81670279074156,
            21.022810763143998,
            105.81693144211191,
            21.02288466591491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81693144211191,
              21.02287235079703
            ],
            [
              105.81679199327466,
              21.02287512776496
            ],
            [
              105.81672689951459,
              21.02288466591491
            ],
            [
              105.81670279074156,
              21.022810763143998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74_Thành Công",
          "maTaiSan": "04.O9C.DODV.7497",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02287235079703,
          "to_latitude": 21.022810763143998,
          "to_longitude": 105.81670279074156,
          "from_longitude": 105.81693144211191
        }
      },
      {
        "id": 7490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8169313016333,
            21.022760099730792,
            105.81696902914223,
            21.023122051566055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81693554617377,
              21.022760099730792
            ],
            [
              105.81693144211191,
              21.02287235079703
            ],
            [
              105.81693133898689,
              21.022875140456808
            ],
            [
              105.8169313016333,
              21.022974044876765
            ],
            [
              105.81696902914223,
              21.023122051566055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74_Thành Công",
          "maTaiSan": "04.O9C.DODV.7498",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022760099730792,
          "to_latitude": 21.023122051566055,
          "to_longitude": 105.81696902914223,
          "from_longitude": 105.81693554617377
        }
      },
      {
        "id": 7491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81739913817756,
            21.020731906992893,
            105.81743437449109,
            21.020757455197025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743437449109,
              21.020731906992893
            ],
            [
              105.81740679815279,
              21.020752121863655
            ],
            [
              105.81740625093883,
              21.0207525020422
            ],
            [
              105.81739913817756,
              21.020757455197025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7499",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020731906992893,
          "to_latitude": 21.020757455197025,
          "to_longitude": 105.81739913817756,
          "from_longitude": 105.81743437449109
        }
      },
      {
        "id": 7492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81622506135804,
            21.02037665648689,
            105.81655664220348,
            21.02079326734476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81622506135804,
              21.02037665648689
            ],
            [
              105.8162850309557,
              21.020456954639013
            ],
            [
              105.8163302150816,
              21.020517454535227
            ],
            [
              105.81633253495367,
              21.020520561341893
            ],
            [
              105.81633601729865,
              21.0205251574105
            ],
            [
              105.81641148467163,
              21.020624768002516
            ],
            [
              105.81644940283651,
              21.02066878361627
            ],
            [
              105.81645988822622,
              21.02068095485322
            ],
            [
              105.81646303452028,
              21.020684606853163
            ],
            [
              105.81646541604707,
              21.020687375554907
            ],
            [
              105.8164805501874,
              21.020704938419698
            ],
            [
              105.81655664220348,
              21.02079326734476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7500",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02037665648689,
          "to_latitude": 21.02079326734476,
          "to_longitude": 105.81655664220348,
          "from_longitude": 105.81622506135804
        }
      },
      {
        "id": 7493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81643867853279,
            21.022789046741924,
            105.81647431152726,
            21.022893820722036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81647431152726,
              21.022893820722036
            ],
            [
              105.81647427339598,
              21.02289370800172
            ],
            [
              105.81643867853279,
              21.022789046741924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Thành Công",
          "maTaiSan": "04.O9C.DODV.7501",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022893820722036,
          "to_latitude": 21.022789046741924,
          "to_longitude": 105.81643867853279,
          "from_longitude": 105.81647431152726
        }
      },
      {
        "id": 7494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8171817371754,
            21.02293513298789,
            105.81721046238076,
            21.02322977411167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8171817371754,
              21.02293513298789
            ],
            [
              105.8172017334507,
              21.023025402943734
            ],
            [
              105.81720216149657,
              21.023027337405257
            ],
            [
              105.81721046238076,
              21.023121922266334
            ],
            [
              105.81719311663353,
              21.023147312216672
            ],
            [
              105.81720345531608,
              21.02322977411167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7502",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02293513298789,
          "to_latitude": 21.02322977411167,
          "to_longitude": 105.81720345531608,
          "from_longitude": 105.8171817371754
        }
      },
      {
        "id": 7495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81702598193236,
            21.022044999717625,
            105.81714478724811,
            21.022164025615936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81714478724811,
              21.022155801375646
            ],
            [
              105.81707853228754,
              21.022164025615936
            ],
            [
              105.81702598193236,
              21.022044999717625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Thành Công",
          "maTaiSan": "04.O9C.DODV.7503",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022155801375646,
          "to_latitude": 21.022044999717625,
          "to_longitude": 105.81702598193236,
          "from_longitude": 105.81714478724811
        }
      },
      {
        "id": 7496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81746026146827,
            21.02300138688007,
            105.81762838233288,
            21.023052878668004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81746026146827,
              21.023052878668004
            ],
            [
              105.8175546097696,
              21.023019965915868
            ],
            [
              105.81762838233288,
              21.02300138688007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_06_Thành Công",
          "maTaiSan": "04.O9C.DODV.7504",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023052878668004,
          "to_latitude": 21.02300138688007,
          "to_longitude": 105.81762838233288,
          "from_longitude": 105.81746026146827
        }
      },
      {
        "id": 7497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81737615143174,
            21.020329758514457,
            105.81787725531112,
            21.020972880661223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81737615143174,
              21.020329758514457
            ],
            [
              105.81787725531112,
              21.020972880661223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7505",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020329758514457,
          "to_latitude": 21.020972880661223,
          "to_longitude": 105.81787725531112,
          "from_longitude": 105.81737615143174
        }
      },
      {
        "id": 7498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81717800642585,
            21.0207341807577,
            105.81722114259337,
            21.020765536685676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81722114259337,
              21.0207341807577
            ],
            [
              105.81721613504,
              21.020737974885826
            ],
            [
              105.81721445850843,
              21.020739244748757
            ],
            [
              105.81717800642585,
              21.020765536685676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7506",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0207341807577,
          "to_latitude": 21.020765536685676,
          "to_longitude": 105.81717800642585,
          "from_longitude": 105.81722114259337
        }
      },
      {
        "id": 7499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8174915228988,
            21.022397876372917,
            105.81751134203331,
            21.022513862915524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8174915228988,
              21.022397876372917
            ],
            [
              105.81750850956723,
              21.022460154407778
            ],
            [
              105.81751134203331,
              21.022513862915524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Thành Công",
          "maTaiSan": "04.O9C.DODV.7507",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022397876372917,
          "to_latitude": 21.022513862915524,
          "to_longitude": 105.81751134203331,
          "from_longitude": 105.8174915228988
        }
      },
      {
        "id": 7500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81758313618195,
            21.022374171411176,
            105.81759986662505,
            21.022481053053127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81758561989216,
              21.022374171411176
            ],
            [
              105.81759986662505,
              21.02246145669771
            ],
            [
              105.81758313618195,
              21.02246205466951
            ],
            [
              105.81758409401546,
              21.022481053053127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Thành Công",
          "maTaiSan": "04.O9C.DODV.7508",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022374171411176,
          "to_latitude": 21.022481053053127,
          "to_longitude": 105.81758409401546,
          "from_longitude": 105.81758561989216
        }
      },
      {
        "id": 7501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81692610423329,
            21.0208244532553,
            105.81697174280058,
            21.020855943979214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81692610423329,
              21.020855943979214
            ],
            [
              105.81695638757681,
              21.02083412650316
            ],
            [
              105.81695879269245,
              21.020832394304023
            ],
            [
              105.81697174280058,
              21.0208244532553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7509",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020855943979214,
          "to_latitude": 21.0208244532553,
          "to_longitude": 105.81697174280058,
          "from_longitude": 105.81692610423329
        }
      },
      {
        "id": 7502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81707063285725,
            21.0219534886047,
            105.81740328991646,
            21.022408661345715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81740328991646,
              21.022408661345715
            ],
            [
              105.81738432582884,
              21.022341686086662
            ],
            [
              105.81738887390948,
              21.02233512486762
            ],
            [
              105.8173785347145,
              21.022286102242248
            ],
            [
              105.81737528745519,
              21.022236409662796
            ],
            [
              105.81729881488778,
              21.022243859348716
            ],
            [
              105.81725565194608,
              21.022222370338547
            ],
            [
              105.81723103129264,
              21.022212544110374
            ],
            [
              105.8171597418867,
              21.02221363901561
            ],
            [
              105.81714478724811,
              21.022155801375646
            ],
            [
              105.81714038179864,
              21.022138756972176
            ],
            [
              105.81712745992614,
              21.022083406082917
            ],
            [
              105.81708387893504,
              21.02200690077552
            ],
            [
              105.81707063285725,
              21.021975373143945
            ],
            [
              105.81707313539623,
              21.021966801240847
            ],
            [
              105.81711468197261,
              21.0219534886047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Thành Công",
          "maTaiSan": "04.O9C.DODV.7510",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022408661345715,
          "to_latitude": 21.0219534886047,
          "to_longitude": 105.81711468197261,
          "from_longitude": 105.81740328991646
        }
      },
      {
        "id": 7503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81773211897146,
            21.022231476494625,
            105.81780815730176,
            21.02228642847068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81780815730176,
              21.022231476494625
            ],
            [
              105.81780622795364,
              21.022231900352402
            ],
            [
              105.81779460956795,
              21.02223444912133
            ],
            [
              105.81773211897146,
              21.022248163393378
            ],
            [
              105.81773345990763,
              21.02228642847068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Thành Công",
          "maTaiSan": "04.O9C.DODV.7511",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022231476494625,
          "to_latitude": 21.02228642847068,
          "to_longitude": 105.81773345990763,
          "from_longitude": 105.81780815730176
        }
      },
      {
        "id": 7504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81727476114534,
            21.020567665915983,
            105.81731898438548,
            21.020599707220548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81731898438548,
              21.020567665915983
            ],
            [
              105.81728288200907,
              21.020593864064725
            ],
            [
              105.81728227643652,
              21.02059430323304
            ],
            [
              105.81727566375467,
              21.020599052560264
            ],
            [
              105.81727476114534,
              21.020599707220548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7512",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020567665915983,
          "to_latitude": 21.020599707220548,
          "to_longitude": 105.81727476114534,
          "from_longitude": 105.81731898438548
        }
      },
      {
        "id": 7505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81573040245134,
            21.02050959814599,
            105.81576698020864,
            21.02057356721796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81573040245134,
              21.02050959814599
            ],
            [
              105.81576062230263,
              21.020561853045585
            ],
            [
              105.81576245187571,
              21.020565018211805
            ],
            [
              105.81576471048086,
              21.020569596677547
            ],
            [
              105.81576698020864,
              21.02057356721796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7513",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02050959814599,
          "to_latitude": 21.02057356721796,
          "to_longitude": 105.81576698020864,
          "from_longitude": 105.81573040245134
        }
      },
      {
        "id": 7506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172756348856,
            21.020568347145076,
            105.8173204474091,
            21.020600815798097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8173204474091,
              21.020568347145076
            ],
            [
              105.817283800501,
              21.02059506455636
            ],
            [
              105.81728321602237,
              21.020595490978142
            ],
            [
              105.81727718714971,
              21.020599691048016
            ],
            [
              105.8172756348856,
              21.020600815798097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7514",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020568347145076,
          "to_latitude": 21.020600815798097,
          "to_longitude": 105.8172756348856,
          "from_longitude": 105.8173204474091
        }
      },
      {
        "id": 7507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8159556199749,
            21.021257031340667,
            105.81597189289707,
            21.021302842635397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81597189289707,
              21.021302842635397
            ],
            [
              105.81596025946598,
              21.021270091426537
            ],
            [
              105.81595900998254,
              21.021266578447488
            ],
            [
              105.8159556199749,
              21.021257031340667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7515",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021302842635397,
          "to_latitude": 21.021257031340667,
          "to_longitude": 105.8159556199749,
          "from_longitude": 105.81597189289707
        }
      },
      {
        "id": 7508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81818991910629,
            21.022288106338806,
            105.81830828369235,
            21.02242372271396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81818991910629,
              21.022288106338806
            ],
            [
              105.81830828369235,
              21.02242372271396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17_Thành Công",
          "maTaiSan": "04.O9C.DODV.7516",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022288106338806,
          "to_latitude": 21.02242372271396,
          "to_longitude": 105.81830828369235,
          "from_longitude": 105.81818991910629
        }
      },
      {
        "id": 7509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81590549601391,
            21.021579647104133,
            105.8159365500071,
            21.021659977481885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8159365500071,
              21.021659977481885
            ],
            [
              105.8159130583952,
              21.02159384026825
            ],
            [
              105.81590825603405,
              21.021588329232202
            ],
            [
              105.8159060343422,
              21.021581345298525
            ],
            [
              105.81590549601391,
              21.021579647104133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7517",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021659977481885,
          "to_latitude": 21.021579647104133,
          "to_longitude": 105.81590549601391,
          "from_longitude": 105.8159365500071
        }
      },
      {
        "id": 7510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8163592354655,
            21.020249325042805,
            105.81645923202905,
            21.020370593812128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81645923202905,
              21.020361841527087
            ],
            [
              105.81644624498367,
              21.020370593812128
            ],
            [
              105.81636809316002,
              21.02026167209909
            ],
            [
              105.8163592354655,
              21.020249325042805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7518",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020361841527087,
          "to_latitude": 21.020249325042805,
          "to_longitude": 105.8163592354655,
          "from_longitude": 105.81645923202905
        }
      },
      {
        "id": 7511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81737509088197,
            21.021562925824487,
            105.8177082337578,
            21.02190574121104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81737509088197,
              21.021562925824487
            ],
            [
              105.8175369356519,
              21.021722197085428
            ],
            [
              105.81755480857413,
              21.021739546165705
            ],
            [
              105.81762908651851,
              21.021811647785533
            ],
            [
              105.81767656426779,
              21.02185702527788
            ],
            [
              105.81768050778933,
              21.021863090455682
            ],
            [
              105.8177082337578,
              21.02190574121104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Thành Công",
          "maTaiSan": "04.O9C.DODV.7519",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021562925824487,
          "to_latitude": 21.02190574121104,
          "to_longitude": 105.8177082337578,
          "from_longitude": 105.81737509088197
        }
      },
      {
        "id": 7512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81775317936375,
            21.023542946888764,
            105.81777155490948,
            21.023653637473505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81777155490948,
              21.023653637473505
            ],
            [
              105.81775429828862,
              21.0235542589384
            ],
            [
              105.81775317936375,
              21.023542946888764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_La Thành",
          "maTaiSan": "04.O9C.DODV.7520",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023653637473505,
          "to_latitude": 21.023542946888764,
          "to_longitude": 105.81775317936375,
          "from_longitude": 105.81777155490948
        }
      },
      {
        "id": 7513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81581402247842,
            21.021300457576984,
            105.81583015374238,
            21.021345876661723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81583015374238,
              21.021345876661723
            ],
            [
              105.81581798006475,
              21.021311599773377
            ],
            [
              105.81581722687213,
              21.02130948441498
            ],
            [
              105.81581402247842,
              21.021300457576984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7521",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021345876661723,
          "to_latitude": 21.021300457576984,
          "to_longitude": 105.81581402247842,
          "from_longitude": 105.81583015374238
        }
      },
      {
        "id": 7514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81925285734354,
            21.023320791060673,
            105.8193350868401,
            21.0233303603396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81925285734354,
              21.0233303603396
            ],
            [
              105.8193350868401,
              21.023320791060673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_La Thành",
          "maTaiSan": "04.O9C.DODV.7522",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0233303603396,
          "to_latitude": 21.023320791060673,
          "to_longitude": 105.8193350868401,
          "from_longitude": 105.81925285734354
        }
      },
      {
        "id": 7515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8163080871865,
            21.02144348505571,
            105.8163449268901,
            21.021534983087196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8163449268901,
              21.021534983087196
            ],
            [
              105.81632144488883,
              21.021468872984194
            ],
            [
              105.81631767804316,
              21.021463265764233
            ],
            [
              105.81630873514824,
              21.021444823238195
            ],
            [
              105.8163080871865,
              21.02144348505571
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7523",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021534983087196,
          "to_latitude": 21.02144348505571,
          "to_longitude": 105.8163080871865,
          "from_longitude": 105.8163449268901
        }
      },
      {
        "id": 7516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730085715645,
            21.021265290947802,
            105.81732371885536,
            21.021306928114885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81732371885536,
              21.021306928114885
            ],
            [
              105.81730085715645,
              21.02127792154475
            ],
            [
              105.81730772708175,
              21.02127147471983
            ],
            [
              105.81730655672847,
              21.02127013092244
            ],
            [
              105.81730233420933,
              21.021265290947802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7524",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021306928114885,
          "to_latitude": 21.021265290947802,
          "to_longitude": 105.81730233420933,
          "from_longitude": 105.81732371885536
        }
      },
      {
        "id": 7517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81376524114754,
            21.024145725218386,
            105.81393184235597,
            21.024326576449056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81376524114754,
              21.02417046234595
            ],
            [
              105.81387896572697,
              21.024145725218386
            ],
            [
              105.81390394024798,
              21.02422993009034
            ],
            [
              105.81391280965873,
              21.02425984317436
            ],
            [
              105.81391831094898,
              21.024278382681764
            ],
            [
              105.81391979553327,
              21.024283385778187
            ],
            [
              105.81393025496611,
              21.02432088829537
            ],
            [
              105.81393184235597,
              21.024326576449056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_La Thành",
          "maTaiSan": "04.O9C.DODV.7525",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02417046234595,
          "to_latitude": 21.024326576449056,
          "to_longitude": 105.81393184235597,
          "from_longitude": 105.81376524114754
        }
      },
      {
        "id": 7518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81247324503974,
            21.024498295778926,
            105.8127709819203,
            21.02454523113112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81247324503974,
              21.02454523113112
            ],
            [
              105.81258100543329,
              21.02452824413906
            ],
            [
              105.8127709819203,
              21.024498295778926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_La Thành",
          "maTaiSan": "04.O9C.DODV.7526",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02454523113112,
          "to_latitude": 21.024498295778926,
          "to_longitude": 105.8127709819203,
          "from_longitude": 105.81247324503974
        }
      },
      {
        "id": 7519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81390394024798,
            21.02409692765043,
            105.8141455058955,
            21.02422993009034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81390394024798,
              21.02422993009034
            ],
            [
              105.8141455058955,
              21.024168263979956
            ],
            [
              105.8141267107562,
              21.02409692765043
            ],
            [
              105.81390553136846,
              21.02414557550685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_La Thành",
          "maTaiSan": "04.O9C.DODV.7527",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02422993009034,
          "to_latitude": 21.02414557550685,
          "to_longitude": 105.81390553136846,
          "from_longitude": 105.81390394024798
        }
      },
      {
        "id": 7520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81268870146938,
            21.02408683033458,
            105.81287632814089,
            21.024113056278626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81268870146938,
              21.024111257716847
            ],
            [
              105.81282606252186,
              21.02409292783769
            ],
            [
              105.81287175788852,
              21.02408683033458
            ],
            [
              105.81287632814089,
              21.024113056278626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7528",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024111257716847,
          "to_latitude": 21.024113056278626,
          "to_longitude": 105.81287632814089,
          "from_longitude": 105.81268870146938
        }
      },
      {
        "id": 7521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81254789062821,
            21.024352170043553,
            105.81258100543329,
            21.02452824413906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81254789062821,
              21.024352170043553
            ],
            [
              105.81257642135462,
              21.0245038978695
            ],
            [
              105.81258100543329,
              21.02452824413906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_La Thành",
          "maTaiSan": "04.O9C.DODV.7529",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024352170043553,
          "to_latitude": 21.02452824413906,
          "to_longitude": 105.81258100543329,
          "from_longitude": 105.81254789062821
        }
      },
      {
        "id": 7522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116846519259,
            21.024018100772327,
            105.81231943230037,
            21.02410150788305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8116846519259,
              21.02410150788305
            ],
            [
              105.81201786668478,
              21.024053524797996
            ],
            [
              105.81231943230037,
              21.024018100772327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7530",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02410150788305,
          "to_latitude": 21.024018100772327,
          "to_longitude": 105.81231943230037,
          "from_longitude": 105.8116846519259
        }
      },
      {
        "id": 7523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81375257602605,
            21.02417870889385,
            105.81391831094898,
            21.02430641214614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81391831094898,
              21.024278382681764
            ],
            [
              105.8137841905914,
              21.02430641214614
            ],
            [
              105.81377871915458,
              21.02428433093727
            ],
            [
              105.8137570394091,
              21.024196736727422
            ],
            [
              105.81375257602605,
              21.02417870889385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_La Thành",
          "maTaiSan": "04.O9C.DODV.7531",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024278382681764,
          "to_latitude": 21.02417870889385,
          "to_longitude": 105.81375257602605,
          "from_longitude": 105.81391831094898
        }
      },
      {
        "id": 7524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81235067647054,
            21.024544755869616,
            105.81244749857869,
            21.024584724003045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81235067647054,
              21.024563972404074
            ],
            [
              105.81243924642305,
              21.024544755869616
            ],
            [
              105.81244029731134,
              21.024546602465644
            ],
            [
              105.81244749857869,
              21.024584724003045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_08_La Thành",
          "maTaiSan": "04.O9C.DODV.7532",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024563972404074,
          "to_latitude": 21.024584724003045,
          "to_longitude": 105.81244749857869,
          "from_longitude": 105.81235067647054
        }
      },
      {
        "id": 7525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81281328626108,
            21.024465098731696,
            105.81294272817766,
            21.024490048554835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81281328626108,
              21.024490048554835
            ],
            [
              105.81294272817766,
              21.024465098731696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_La Thành",
          "maTaiSan": "04.O9C.DODV.7533",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024490048554835,
          "to_latitude": 21.024465098731696,
          "to_longitude": 105.81294272817766,
          "from_longitude": 105.81281328626108
        }
      },
      {
        "id": 7526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81415274300919,
            21.024083373887905,
            105.8143359612002,
            21.02419970123765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8143359612002,
              21.02419970123765
            ],
            [
              105.81433160598996,
              21.024183359228346
            ],
            [
              105.8143049871001,
              21.024083373887905
            ],
            [
              105.81415274300919,
              21.024116539451338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_La Thành",
          "maTaiSan": "04.O9C.DODV.7534",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02419970123765,
          "to_latitude": 21.024116539451338,
          "to_longitude": 105.81415274300919,
          "from_longitude": 105.8143359612002
        }
      },
      {
        "id": 7527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116130430134,
            21.024619392590104,
            105.81206699195913,
            21.02469417451868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8116130430134,
              21.02469417451868
            ],
            [
              105.81184801497714,
              21.024659154887054
            ],
            [
              105.81186708275342,
              21.02465631263289
            ],
            [
              105.81192730720814,
              21.024647336982255
            ],
            [
              105.81206699195913,
              21.024619392590104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_La Thành",
          "maTaiSan": "04.O9C.DODV.7535",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02469417451868,
          "to_latitude": 21.024619392590104,
          "to_longitude": 105.81206699195913,
          "from_longitude": 105.8116130430134
        }
      },
      {
        "id": 7528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81612424048141,
            21.02363917804078,
            105.81635236539016,
            21.023893717450186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81630833649614,
              21.02363917804078
            ],
            [
              105.81635224066709,
              21.02378667831822
            ],
            [
              105.81635236539016,
              21.023794920567635
            ],
            [
              105.81635230548754,
              21.023814307715863
            ],
            [
              105.81635224199164,
              21.023838158628266
            ],
            [
              105.81632929069055,
              21.02385757887815
            ],
            [
              105.81631790873536,
              21.02385958538221
            ],
            [
              105.81623870786568,
              21.02387354371713
            ],
            [
              105.81612424048141,
              21.023893717450186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_La Thành",
          "maTaiSan": "04.O9C.DODV.7536",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02363917804078,
          "to_latitude": 21.023893717450186,
          "to_longitude": 105.81612424048141,
          "from_longitude": 105.81630833649614
        }
      },
      {
        "id": 7529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81234282456798,
            21.024105840276864,
            105.81260569234165,
            21.0241408632054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81234282456798,
              21.0241408632054
            ],
            [
              105.81260569234165,
              21.024105840276864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7537",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0241408632054,
          "to_latitude": 21.024105840276864,
          "to_longitude": 105.81260569234165,
          "from_longitude": 105.81234282456798
        }
      },
      {
        "id": 7530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81296436149549,
            21.02443965854628,
            105.81308176619542,
            21.02445745608427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81296436149549,
              21.02445745608427
            ],
            [
              105.81308154319422,
              21.02443969212859
            ],
            [
              105.81308176619542,
              21.02443965854628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_La Thành",
          "maTaiSan": "04.O9C.DODV.7538",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02445745608427,
          "to_latitude": 21.02443965854628,
          "to_longitude": 105.81308176619542,
          "from_longitude": 105.81296436149549
        }
      },
      {
        "id": 7531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81631790873536,
            21.02385958538221,
            105.81633133800891,
            21.02391892434642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81631790873536,
              21.02385958538221
            ],
            [
              105.81632840454478,
              21.023905962669996
            ],
            [
              105.81633102852032,
              21.02391756105619
            ],
            [
              105.81633133800891,
              21.02391892434642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_01_La Thành",
          "maTaiSan": "04.O9C.DODV.7539",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02385958538221,
          "to_latitude": 21.02391892434642,
          "to_longitude": 105.81633133800891,
          "from_longitude": 105.81631790873536
        }
      },
      {
        "id": 7532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8130992056419,
            21.02428869524079,
            105.81336636787267,
            21.024435291163314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81336636787267,
              21.02428869524079
            ],
            [
              105.81334422247664,
              21.024308510471617
            ],
            [
              105.81335309591249,
              21.024362060122904
            ],
            [
              105.81335686114475,
              21.024384753765798
            ],
            [
              105.81322213928128,
              21.024411178827332
            ],
            [
              105.81321514531028,
              21.024412550852734
            ],
            [
              105.8130992056419,
              21.024435291163314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_04_La Thành",
          "maTaiSan": "04.O9C.DODV.7540",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02428869524079,
          "to_latitude": 21.024435291163314,
          "to_longitude": 105.8130992056419,
          "from_longitude": 105.81336636787267
        }
      },
      {
        "id": 7533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337549488156,
            21.02430587257315,
            105.81377388083695,
            21.024395544925877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81337549488156,
              21.024395544925877
            ],
            [
              105.81346846798391,
              21.024374617768043
            ],
            [
              105.81357468792778,
              21.024350709767553
            ],
            [
              105.81377388083695,
              21.02430587257315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_03_La Thành",
          "maTaiSan": "04.O9C.DODV.7541",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024395544925877,
          "to_latitude": 21.02430587257315,
          "to_longitude": 105.81377388083695,
          "from_longitude": 105.81337549488156
        }
      },
      {
        "id": 7534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81230889414088,
            21.02396279319546,
            105.8123441860308,
            21.024148009360793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8123441860308,
              21.024148009360793
            ],
            [
              105.81234282456798,
              21.0241408632054
            ],
            [
              105.81231943230037,
              21.024018100772327
            ],
            [
              105.81230913871067,
              21.02396407732007
            ],
            [
              105.81230889414088,
              21.02396279319546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7542",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024148009360793,
          "to_latitude": 21.02396279319546,
          "to_longitude": 105.81230889414088,
          "from_longitude": 105.8123441860308
        }
      },
      {
        "id": 7535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81208086223594,
            21.02435214279589,
            105.81233301867671,
            21.02461605216093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81229530961618,
              21.02435214279589
            ],
            [
              105.81232993499496,
              21.024548910803123
            ],
            [
              105.81233301867671,
              21.024566398815182
            ],
            [
              105.81233134081876,
              21.024566729281556
            ],
            [
              105.81228341614742,
              21.024576166587586
            ],
            [
              105.81223389240971,
              21.02458591861454
            ],
            [
              105.81208086223594,
              21.02461605216093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_La Thành",
          "maTaiSan": "04.O9C.DODV.7543",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02435214279589,
          "to_latitude": 21.02461605216093,
          "to_longitude": 105.81208086223594,
          "from_longitude": 105.81229530961618
        }
      },
      {
        "id": 7536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81391979553327,
            21.024152893143416,
            105.81456873965817,
            21.024283385778187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81391979553327,
              21.024283385778187
            ],
            [
              105.8143359612002,
              21.02419970123765
            ],
            [
              105.81456873965817,
              21.024152893143416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_La Thành",
          "maTaiSan": "04.O9C.DODV.7544",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024283385778187,
          "to_latitude": 21.024152893143416,
          "to_longitude": 105.81456873965817,
          "from_longitude": 105.81391979553327
        }
      },
      {
        "id": 7537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81840564986948,
            21.023408572371096,
            105.81844348900471,
            21.023574273255438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81840564986948,
              21.023408572371096
            ],
            [
              105.81841088968423,
              21.023447847446597
            ],
            [
              105.81842541345848,
              21.023556696739938
            ],
            [
              105.81843267223174,
              21.02356243794346
            ],
            [
              105.81844260245028,
              21.0235702933896
            ],
            [
              105.81844348900471,
              21.023574273255438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_38_La Thành",
          "maTaiSan": "04.O9C.DODV.7545",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023408572371096,
          "to_latitude": 21.023574273255438,
          "to_longitude": 105.81844348900471,
          "from_longitude": 105.81840564986948
        }
      },
      {
        "id": 7538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81709419230988,
            21.02157556349465,
            105.81709768012583,
            21.021587096393223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81709419230988,
              21.021587096393223
            ],
            [
              105.81709475264364,
              21.021585239376535
            ],
            [
              105.81709584219179,
              21.021581640202474
            ],
            [
              105.81709768012583,
              21.02157556349465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Thành Công",
          "maTaiSan": "04.O9C.DODV.7546",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021587096393223,
          "to_latitude": 21.02157556349465,
          "to_longitude": 105.81709768012583,
          "from_longitude": 105.81709419230988
        }
      },
      {
        "id": 7539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81646287367647,
            21.021762939696504,
            105.81646607864427,
            21.021774310451207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81646287367647,
              21.021774310451207
            ],
            [
              105.8164635380662,
              21.021771951645917
            ],
            [
              105.81646607864427,
              21.021762939696504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Thành Công",
          "maTaiSan": "04.O9C.DODV.7547",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021774310451207,
          "to_latitude": 21.021762939696504,
          "to_longitude": 105.81646607864427,
          "from_longitude": 105.81646287367647
        }
      },
      {
        "id": 7540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157837797931,
            21.021443054833085,
            105.81640581788257,
            21.0216333993876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157837797931,
              21.0216333993876
            ],
            [
              105.81590382722831,
              21.021596664682555
            ],
            [
              105.8159130583952,
              21.02159384026825
            ],
            [
              105.81604029899934,
              21.021554904121242
            ],
            [
              105.81604953016081,
              21.0215520796997
            ],
            [
              105.8161179542468,
              21.021531141568722
            ],
            [
              105.81618105781689,
              21.02151183126621
            ],
            [
              105.81619028897279,
              21.021509006837178
            ],
            [
              105.81632144488883,
              21.021468872984194
            ],
            [
              105.8163306760392,
              21.021466048547712
            ],
            [
              105.81640581788257,
              21.021443054833085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7548",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0216333993876,
          "to_latitude": 21.021443054833085,
          "to_longitude": 105.81640581788257,
          "from_longitude": 105.8157837797931
        }
      },
      {
        "id": 7541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8161055414847,
            21.02150915786885,
            105.8161179542468,
            21.021531141568722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8161179542468,
              21.021531141568722
            ],
            [
              105.81610596792093,
              21.02150991634002
            ],
            [
              105.8161055414847,
              21.02150915786885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7549",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021531141568722,
          "to_latitude": 21.02150915786885,
          "to_longitude": 105.8161055414847,
          "from_longitude": 105.8161179542468
        }
      },
      {
        "id": 7542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81640395751784,
            21.02122301039679,
            105.81703606429244,
            21.021383529356616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81703606429244,
              21.02126533005044
            ],
            [
              105.81699052849898,
              21.02122301039679
            ],
            [
              105.8167957468456,
              21.021277079096
            ],
            [
              105.81644179072099,
              21.021375331620572
            ],
            [
              105.81640687884226,
              21.021383492754158
            ],
            [
              105.81640395751784,
              21.021383529356616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7550",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02126533005044,
          "to_latitude": 21.021383529356616,
          "to_longitude": 105.81640395751784,
          "from_longitude": 105.81703606429244
        }
      },
      {
        "id": 7543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81545942467115,
            21.02072717161759,
            105.81563561121705,
            21.021266459467594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81545942467115,
              21.02072717161759
            ],
            [
              105.81548165163932,
              21.020795206330792
            ],
            [
              105.81548204588131,
              21.020796413862044
            ],
            [
              105.81548493173459,
              21.02080524623453
            ],
            [
              105.81549956450547,
              21.020850034863226
            ],
            [
              105.81552448465861,
              21.020926313057128
            ],
            [
              105.81552609292275,
              21.020931236967126
            ],
            [
              105.81552782933416,
              21.020936552263315
            ],
            [
              105.8155289787815,
              21.020940069338554
            ],
            [
              105.81554937060531,
              21.021002487538876
            ],
            [
              105.81556760145767,
              21.02105828911901
            ],
            [
              105.81556850048213,
              21.021061041457898
            ],
            [
              105.81557138537924,
              21.02106987292974
            ],
            [
              105.81557196254816,
              21.021071638681242
            ],
            [
              105.81560765642787,
              21.0211809025425
            ],
            [
              105.81563561121705,
              21.021266459467594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7551",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02072717161759,
          "to_latitude": 21.021266459467594,
          "to_longitude": 105.81563561121705,
          "from_longitude": 105.81545942467115
        }
      },
      {
        "id": 7544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81562747736675,
            21.020348736043303,
            105.81613336445119,
            21.02062952545415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81562747736675,
              21.02062952545415
            ],
            [
              105.81564140669559,
              21.020622446004605
            ],
            [
              105.81564496637255,
              21.02062003989914
            ],
            [
              105.81564790114444,
              21.020618056790855
            ],
            [
              105.81564918546171,
              21.020617188050224
            ],
            [
              105.81574796024795,
              21.020568288748617
            ],
            [
              105.81575648571918,
              21.020563955726075
            ],
            [
              105.81575742009531,
              21.02056348066356
            ],
            [
              105.81576062230263,
              21.020561853045585
            ],
            [
              105.81585114843762,
              21.02051584292684
            ],
            [
              105.81589016311236,
              21.020481719738733
            ],
            [
              105.81589933182748,
              21.020475385685188
            ],
            [
              105.81590841141492,
              21.02046911347813
            ],
            [
              105.81594463382021,
              21.02044972988875
            ],
            [
              105.81605511670688,
              21.0203906084279
            ],
            [
              105.81606321695926,
              21.020386273817525
            ],
            [
              105.81610599913299,
              21.02036337949215
            ],
            [
              105.81611073075364,
              21.02036084762522
            ],
            [
              105.81613336445119,
              21.020348736043303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7552",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02062952545415,
          "to_latitude": 21.020348736043303,
          "to_longitude": 105.81613336445119,
          "from_longitude": 105.81562747736675
        }
      },
      {
        "id": 7545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81589933182748,
            21.020475385685188,
            105.81591171192187,
            21.020494923623247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81589933182748,
              21.020475385685188
            ],
            [
              105.81590084745402,
              21.020477780096492
            ],
            [
              105.81590722912429,
              21.020487849372724
            ],
            [
              105.81591171192187,
              21.020494923623247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7553",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020475385685188,
          "to_latitude": 21.020494923623247,
          "to_longitude": 105.81591171192187,
          "from_longitude": 105.81589933182748
        }
      },
      {
        "id": 7546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81627002581699,
            21.020456954639013,
            105.8162850309557,
            21.020469797376126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81627002581699,
              21.020469797376126
            ],
            [
              105.81627765047186,
              21.020463272002342
            ],
            [
              105.8162850309557,
              21.020456954639013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7554",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020469797376126,
          "to_latitude": 21.020456954639013,
          "to_longitude": 105.8162850309557,
          "from_longitude": 105.81627002581699
        }
      },
      {
        "id": 7547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81709243502958,
            21.02101074125615,
            105.81730381290308,
            21.02127202281875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81730381290308,
              21.02127202281875
            ],
            [
              105.81729986544968,
              21.0212669954576
            ],
            [
              105.8172336393883,
              21.02118266233064
            ],
            [
              105.81722784463359,
              21.02117528280797
            ],
            [
              105.81722132350504,
              21.02116735851621
            ],
            [
              105.81722045292318,
              21.021166300504078
            ],
            [
              105.81709243502958,
              21.02101074125615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7555",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02127202281875,
          "to_latitude": 21.02101074125615,
          "to_longitude": 105.81709243502958,
          "from_longitude": 105.81730381290308
        }
      },
      {
        "id": 7548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81709243502958,
            21.02100500290545,
            105.81710863611536,
            21.02101074125615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81709243502958,
              21.02101074125615
            ],
            [
              105.81709457225823,
              21.02100998402064
            ],
            [
              105.817099262452,
              21.021008323036114
            ],
            [
              105.81710863611536,
              21.02100500290545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7556",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02101074125615,
          "to_latitude": 21.02100500290545,
          "to_longitude": 105.81710863611536,
          "from_longitude": 105.81709243502958
        }
      },
      {
        "id": 7549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81708458078518,
            21.02057490149706,
            105.81745936310236,
            21.021046088202628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81708458078518,
              21.02057490149706
            ],
            [
              105.81712569031123,
              21.02062586098915
            ],
            [
              105.81712721959988,
              21.020627757645176
            ],
            [
              105.81712833629393,
              21.020629141443848
            ],
            [
              105.81713128929073,
              21.02063280158544
            ],
            [
              105.81721613504,
              21.020737974885826
            ],
            [
              105.81721797328406,
              21.020740253928064
            ],
            [
              105.81721982605096,
              21.02074255006182
            ],
            [
              105.81722542503925,
              21.020749490654627
            ],
            [
              105.81725981218557,
              21.020792115816644
            ],
            [
              105.81726613853272,
              21.020799957946647
            ],
            [
              105.8173084123411,
              21.020852360571762
            ],
            [
              105.81730909998612,
              21.020853212623567
            ],
            [
              105.81731092273363,
              21.020855471868156
            ],
            [
              105.8173140132714,
              21.02085930295847
            ],
            [
              105.81732156383171,
              21.020868662947684
            ],
            [
              105.81739452210479,
              21.02096260202894
            ],
            [
              105.81739853573343,
              21.020967769973605
            ],
            [
              105.81739981161007,
              21.02096941313471
            ],
            [
              105.81739998792547,
              21.02096963990059
            ],
            [
              105.81745936310236,
              21.021046088202628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7557",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02057490149706,
          "to_latitude": 21.021046088202628,
          "to_longitude": 105.81745936310236,
          "from_longitude": 105.81708458078518
        }
      },
      {
        "id": 7550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81725981218557,
            21.020789137515145,
            105.8172638352778,
            21.020792115816644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81725981218557,
              21.020792115816644
            ],
            [
              105.81726018967686,
              21.02079183580899
            ],
            [
              105.81726212697225,
              21.020790402111842
            ],
            [
              105.8172638352778,
              21.020789137515145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7558",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020792115816644,
          "to_latitude": 21.020789137515145,
          "to_longitude": 105.8172638352778,
          "from_longitude": 105.81725981218557
        }
      },
      {
        "id": 7551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81720175755451,
            21.02048787265753,
            105.81771892679666,
            21.021137800668324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81771892679666,
              21.021137800668324
            ],
            [
              105.81763370519722,
              21.021032785704744
            ],
            [
              105.81762768802216,
              21.02102537113604
            ],
            [
              105.8176263757313,
              21.021023754345375
            ],
            [
              105.81762502760215,
              21.021022092565605
            ],
            [
              105.81750040740882,
              21.020868527881333
            ],
            [
              105.81749898083778,
              21.020866769832196
            ],
            [
              105.81749778476643,
              21.020865296094936
            ],
            [
              105.81749440864712,
              21.02086113579957
            ],
            [
              105.81745159412739,
              21.02080837724012
            ],
            [
              105.81742490770337,
              21.020775492543425
            ],
            [
              105.81740625093883,
              21.0207525020422
            ],
            [
              105.81740410672738,
              21.020749859568728
            ],
            [
              105.81739968853233,
              21.02074441537416
            ],
            [
              105.81739372658575,
              21.02073706917614
            ],
            [
              105.81737197167294,
              21.020710261166798
            ],
            [
              105.817283800501,
              21.02059506455636
            ],
            [
              105.81728288200907,
              21.020593864064725
            ],
            [
              105.81728213597916,
              21.020592889454417
            ],
            [
              105.81727634697535,
              21.02058532564638
            ],
            [
              105.81720175755451,
              21.02048787265753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7559",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021137800668324,
          "to_latitude": 21.02048787265753,
          "to_longitude": 105.81720175755451,
          "from_longitude": 105.81771892679666
        }
      },
      {
        "id": 7552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8174436069097,
            21.02080837724012,
            105.81745159412739,
            21.020813855761247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81745159412739,
              21.02080837724012
            ],
            [
              105.81744959396725,
              21.02080974891882
            ],
            [
              105.8174458466657,
              21.02081231944708
            ],
            [
              105.8174436069097,
              21.020813855761247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7560",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02080837724012,
          "to_latitude": 21.020813855761247,
          "to_longitude": 105.8174436069097,
          "from_longitude": 105.81745159412739
        }
      },
      {
        "id": 7553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81667709983564,
            21.019825709415354,
            105.81713803949383,
            21.02039589876978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81713803949383,
              21.02039589876978
            ],
            [
              105.81708518608087,
              21.02033077528416
            ],
            [
              105.8170737164412,
              21.020316637972435
            ],
            [
              105.81706578562199,
              21.020306865215748
            ],
            [
              105.81705949152338,
              21.02029910963187
            ],
            [
              105.81705801073004,
              21.020297285000265
            ],
            [
              105.81701833438733,
              21.020248395121953
            ],
            [
              105.81692870049835,
              21.02013794546771
            ],
            [
              105.81692077067,
              21.020128174505246
            ],
            [
              105.81691211061221,
              21.02011750291895
            ],
            [
              105.81690961486663,
              21.02011442767458
            ],
            [
              105.81678847688214,
              21.01996515769415
            ],
            [
              105.81678316232947,
              21.01995850475184
            ],
            [
              105.81677531301348,
              21.01994867649194
            ],
            [
              105.81676420348182,
              21.01993476594443
            ],
            [
              105.81667709983564,
              21.019825709415354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7561",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02039589876978,
          "to_latitude": 21.019825709415354,
          "to_longitude": 105.81667709983564,
          "from_longitude": 105.81713803949383
        }
      },
      {
        "id": 7554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81700357244462,
            21.020248395121953,
            105.81701833438733,
            21.020259776235438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81701833438733,
              21.020248395121953
            ],
            [
              105.81700945997981,
              21.02025523724078
            ],
            [
              105.81700653057743,
              21.02025749492718
            ],
            [
              105.817005161677,
              21.020258551052052
            ],
            [
              105.81700360214184,
              21.020259753512136
            ],
            [
              105.81700357244462,
              21.020259776235438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7562",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020248395121953,
          "to_latitude": 21.020259776235438,
          "to_longitude": 105.81700357244462,
          "from_longitude": 105.81701833438733
        }
      },
      {
        "id": 7555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81615852053662,
            21.02003227567226,
            105.81671641587347,
            21.0203343461586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81671641587347,
              21.02003227567226
            ],
            [
              105.81652583563297,
              21.02013546573807
            ],
            [
              105.81651542415628,
              21.020141103079112
            ],
            [
              105.81649197602712,
              21.020153798491613
            ],
            [
              105.8164839019743,
              21.020158170028367
            ],
            [
              105.81642316241309,
              21.02019105723274
            ],
            [
              105.81615852053662,
              21.0203343461586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7563",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02003227567226,
          "to_latitude": 21.0203343461586,
          "to_longitude": 105.81615852053662,
          "from_longitude": 105.81671641587347
        }
      },
      {
        "id": 7556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81642316241309,
            21.02019105723274,
            105.81643382975516,
            21.020208385026898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81642316241309,
              21.02019105723274
            ],
            [
              105.81643382975516,
              21.020208385026898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7564",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02019105723274,
          "to_latitude": 21.020208385026898,
          "to_longitude": 105.81643382975516,
          "from_longitude": 105.81642316241309
        }
      },
      {
        "id": 7557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81391880327116,
            21.021777690722708,
            105.8139306934365,
            21.021827295968645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8139306934365,
              21.021777690722708
            ],
            [
              105.81392842959634,
              21.0217907495796
            ],
            [
              105.81392740692738,
              21.021794632932043
            ],
            [
              105.81391880327116,
              21.021827295968645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7565",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021777690722708,
          "to_latitude": 21.021827295968645,
          "to_longitude": 105.81391880327116,
          "from_longitude": 105.8139306934365
        }
      },
      {
        "id": 7558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81394067404058,
            21.02178032882225,
            105.81394565211292,
            21.02182927324907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81394565211292,
              21.02178032882225
            ],
            [
              105.81394323376787,
              21.021794165194287
            ],
            [
              105.81394304401226,
              21.021796778236094
            ],
            [
              105.81394067404058,
              21.02182927324907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7566",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02178032882225,
          "to_latitude": 21.02182927324907,
          "to_longitude": 105.81394067404058,
          "from_longitude": 105.81394565211292
        }
      },
      {
        "id": 7559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81249762622394,
            21.02040256494031,
            105.81252620589304,
            21.020453256925578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81252620589304,
              21.02040256494031
            ],
            [
              105.81252268511115,
              21.0204196148287
            ],
            [
              105.81252108226506,
              21.020427389341222
            ],
            [
              105.81252037830772,
              21.020430800582442
            ],
            [
              105.81249935319676,
              21.02043891896697
            ],
            [
              105.81249762622394,
              21.020453256925578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7567",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02040256494031,
          "to_latitude": 21.020453256925578,
          "to_longitude": 105.81249762622394,
          "from_longitude": 105.81252620589304
        }
      },
      {
        "id": 7560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81194006777598,
            21.022690974004608,
            105.81202947893105,
            21.02271525148832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81202947893105,
              21.022690974004608
            ],
            [
              105.81197969979426,
              21.022704613750868
            ],
            [
              105.8119540938463,
              21.022711933813756
            ],
            [
              105.81194256977865,
              21.022715228705522
            ],
            [
              105.81194006777598,
              21.02271525148832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7568",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022690974004608,
          "to_latitude": 21.02271525148832,
          "to_longitude": 105.81194006777598,
          "from_longitude": 105.81202947893105
        }
      },
      {
        "id": 7561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81193783658927,
            21.022697784004055,
            105.81203181976849,
            21.02272780164944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81203181976849,
              21.022697784004055
            ],
            [
              105.81198187060714,
              21.02271146972413
            ],
            [
              105.8119532374953,
              21.022716874041137
            ],
            [
              105.81194850262678,
              21.022717767356212
            ],
            [
              105.81194376955159,
              21.02272716364797
            ],
            [
              105.81193783658927,
              21.02272780164944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7569",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022697784004055,
          "to_latitude": 21.02272780164944,
          "to_longitude": 105.81193783658927,
          "from_longitude": 105.81203181976849
        }
      },
      {
        "id": 7562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81116357108391,
            21.02452490400329,
            105.81118239644671,
            21.02453147806417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81118239644671,
              21.02452490400329
            ],
            [
              105.81116357108391,
              21.02453147806417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.1_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9C.DODV.7570",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02452490400329,
          "to_latitude": 21.02453147806417,
          "to_longitude": 105.81116357108391,
          "from_longitude": 105.81118239644671
        }
      },
      {
        "id": 7563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81104962923271,
            21.02431133857557,
            105.81145399768907,
            21.02472724099247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81104962923271,
              21.02431133857557
            ],
            [
              105.81108626659565,
              21.024384728133157
            ],
            [
              105.81115730756895,
              21.02448454732745
            ],
            [
              105.81118239644671,
              21.02452490400329
            ],
            [
              105.81118540386332,
              21.024529743604113
            ],
            [
              105.81126467858859,
              21.024657272433554
            ],
            [
              105.8112743829929,
              21.0246728727531
            ],
            [
              105.81145399768907,
              21.02472724099247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.1_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9C.DODV.7571",
          "diaChiLapD": "Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02431133857557,
          "to_latitude": 21.02472724099247,
          "to_longitude": 105.81145399768907,
          "from_longitude": 105.81104962923271
        }
      },
      {
        "id": 7564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80901006920489,
            21.019457925160374,
            105.809425366432,
            21.019668292782814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80901006920489,
              21.019668292782814
            ],
            [
              105.80901068126751,
              21.019667982774145
            ],
            [
              105.80920842527918,
              21.019567816218192
            ],
            [
              105.80926436655233,
              21.019539479553856
            ],
            [
              105.809425366432,
              21.019457925160374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1m_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7572",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019668292782814,
          "to_latitude": 21.019457925160374,
          "to_longitude": 105.809425366432,
          "from_longitude": 105.80901006920489
        }
      },
      {
        "id": 7565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81131911760838,
            21.01852879292922,
            105.8114442664963,
            21.018775561338096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8114442664963,
              21.018775561338096
            ],
            [
              105.81131911760838,
              21.018567395136053
            ],
            [
              105.81134775074247,
              21.01855448963416
            ],
            [
              105.81140358674558,
              21.018529957183784
            ],
            [
              105.81140621146484,
              21.018528803886984
            ],
            [
              105.81140623641647,
              21.01852879292922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7573",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018775561338096,
          "to_latitude": 21.01852879292922,
          "to_longitude": 105.81140623641647,
          "from_longitude": 105.8114442664963
        }
      },
      {
        "id": 7566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81112170493759,
            21.018662679650475,
            105.8111759427592,
            21.01875295520117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81112170493759,
              21.018662679650475
            ],
            [
              105.8111759427592,
              21.01875295520117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7574",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018662679650475,
          "to_latitude": 21.01875295520117,
          "to_longitude": 105.8111759427592,
          "from_longitude": 105.81112170493759
        }
      },
      {
        "id": 7567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81071003567159,
            21.018890586452855,
            105.81078084522605,
            21.01901060684619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81078084522605,
              21.01901060684619
            ],
            [
              105.81071003567159,
              21.018890586452855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7575",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01901060684619,
          "to_latitude": 21.018890586452855,
          "to_longitude": 105.81071003567159,
          "from_longitude": 105.81078084522605
        }
      },
      {
        "id": 7568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81139678477598,
            21.018297669834855,
            105.81174164140837,
            21.018465779134623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81174164140837,
              21.018297669834855
            ],
            [
              105.81139867485086,
              21.01846485785882
            ],
            [
              105.81139678477598,
              21.018465779134623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7576",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018297669834855,
          "to_latitude": 21.018465779134623,
          "to_longitude": 105.81139678477598,
          "from_longitude": 105.81174164140837
        }
      },
      {
        "id": 7569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81139678477598,
            21.018465779134623,
            105.81156400565212,
            21.018753878970323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81139678477598,
              21.018465779134623
            ],
            [
              105.81146681820637,
              21.018586305135376
            ],
            [
              105.81150983845102,
              21.01866034165603
            ],
            [
              105.81154910677986,
              21.018727920820847
            ],
            [
              105.8115562775004,
              21.018740413482274
            ],
            [
              105.81155974730326,
              21.01874645939589
            ],
            [
              105.81156400565212,
              21.018753878970323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7577",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018465779134623,
          "to_latitude": 21.018753878970323,
          "to_longitude": 105.81156400565212,
          "from_longitude": 105.81139678477598
        }
      },
      {
        "id": 7570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81156400565212,
            21.018675048311923,
            105.8117246439244,
            21.018753878970323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81156400565212,
              21.018753878970323
            ],
            [
              105.8117246439244,
              21.018675048311923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7578",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018753878970323,
          "to_latitude": 21.018675048311923,
          "to_longitude": 105.8117246439244,
          "from_longitude": 105.81156400565212
        }
      },
      {
        "id": 7571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81178644497739,
            21.018998266040715,
            105.8122004974574,
            21.019200351766603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81178644497739,
              21.019200351766603
            ],
            [
              105.81185408695478,
              21.01919725332943
            ],
            [
              105.81219971456608,
              21.018998648231282
            ],
            [
              105.8122004974574,
              21.018998266040715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7579",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019200351766603,
          "to_latitude": 21.018998266040715,
          "to_longitude": 105.8122004974574,
          "from_longitude": 105.81178644497739
        }
      },
      {
        "id": 7572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81169953741268,
            21.020516060678442,
            105.81191394176453,
            21.020546800324226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81169953741268,
              21.020516060678442
            ],
            [
              105.81191025358544,
              21.02054627147585
            ],
            [
              105.81191394176453,
              21.020546800324226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7580",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020516060678442,
          "to_latitude": 21.020546800324226,
          "to_longitude": 105.81191394176453,
          "from_longitude": 105.81169953741268
        }
      },
      {
        "id": 7573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81158191686535,
            21.020334897869688,
            105.81172270829697,
            21.020351471392026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81158191686535,
              21.020334897869688
            ],
            [
              105.81172270829697,
              21.020351471392026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7581",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020334897869688,
          "to_latitude": 21.020351471392026,
          "to_longitude": 105.81172270829697,
          "from_longitude": 105.81158191686535
        }
      },
      {
        "id": 7574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81163916636682,
            21.020412938319215,
            105.81166393556765,
            21.020562522673227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81163916636682,
              21.020562522673227
            ],
            [
              105.81164035090649,
              21.020555656101486
            ],
            [
              105.81164112011406,
              21.02054954480366
            ],
            [
              105.81164215675899,
              21.02054130241818
            ],
            [
              105.81165328410925,
              21.020452833240263
            ],
            [
              105.81166393556765,
              21.020412938319215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7582",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020562522673227,
          "to_latitude": 21.020412938319215,
          "to_longitude": 105.81166393556765,
          "from_longitude": 105.81163916636682
        }
      },
      {
        "id": 7575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81083326085064,
            21.020899629317412,
            105.8110060636624,
            21.02109306918652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110060636624,
              21.020899629317412
            ],
            [
              105.81100422398902,
              21.02090898649897
            ],
            [
              105.81100330531417,
              21.02091236804877
            ],
            [
              105.81092274256524,
              21.0209064521335
            ],
            [
              105.81088392108768,
              21.020914630777835
            ],
            [
              105.81084808989156,
              21.02093072369445
            ],
            [
              105.81083326085064,
              21.020963955499166
            ],
            [
              105.81083378534913,
              21.0210173680991
            ],
            [
              105.81086510297213,
              21.02109306918652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7583",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020899629317412,
          "to_latitude": 21.02109306918652,
          "to_longitude": 105.81086510297213,
          "from_longitude": 105.8110060636624
        }
      },
      {
        "id": 7576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81082081217355,
            21.02066191034307,
            105.81093899080668,
            21.020872210972225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81082081217355,
              21.02066191034307
            ],
            [
              105.81082253980672,
              21.02074604864192
            ],
            [
              105.81093899080668,
              21.020872210972225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7584",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02066191034307,
          "to_latitude": 21.020872210972225,
          "to_longitude": 105.81093899080668,
          "from_longitude": 105.81082081217355
        }
      },
      {
        "id": 7577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81183600963475,
            21.02132341884666,
            105.81185174527779,
            21.021496551502498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81185174527779,
              21.02132341884666
            ],
            [
              105.81183853033448,
              21.02148026330585
            ],
            [
              105.81183600963475,
              21.021496551502498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7585",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02132341884666,
          "to_latitude": 21.021496551502498,
          "to_longitude": 105.81183600963475,
          "from_longitude": 105.81185174527779
        }
      },
      {
        "id": 7578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8113436106018,
            21.021808502924305,
            105.81181267287674,
            21.021817960890633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8113436106018,
              21.021811451259275
            ],
            [
              105.81137802374661,
              21.02181166916637
            ],
            [
              105.81143332072735,
              21.02181156885722
            ],
            [
              105.81144399570051,
              21.021811545930422
            ],
            [
              105.81164087931126,
              21.021808502924305
            ],
            [
              105.81164782115161,
              21.02180889341248
            ],
            [
              105.8116478460989,
              21.021808881551454
            ],
            [
              105.81166225801061,
              21.021809681683703
            ],
            [
              105.81181267287674,
              21.021817960890633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7586",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021811451259275,
          "to_latitude": 21.021817960890633,
          "to_longitude": 105.81181267287674,
          "from_longitude": 105.8113436106018
        }
      },
      {
        "id": 7579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81181267287674,
            21.02166048364184,
            105.81181407806443,
            21.021817960890633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81181267287674,
              21.021817960890633
            ],
            [
              105.81181407806443,
              21.02166048364184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1x_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7587",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021817960890633,
          "to_latitude": 21.02166048364184,
          "to_longitude": 105.81181407806443,
          "from_longitude": 105.81181267287674
        }
      },
      {
        "id": 7580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81211301167738,
            21.02160569152184,
            105.81230386001992,
            21.021621665691654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81230386001992,
              21.021621019004183
            ],
            [
              105.81213015997832,
              21.02160569152184
            ],
            [
              105.81211301167738,
              21.021621665691654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7588",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021621019004183,
          "to_latitude": 21.021621665691654,
          "to_longitude": 105.81211301167738,
          "from_longitude": 105.81230386001992
        }
      },
      {
        "id": 7581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81261868939666,
            21.021963129294924,
            105.81264947828907,
            21.022169619652946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81264947828907,
              21.021963129294924
            ],
            [
              105.81261868939666,
              21.022169619652946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7589",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021963129294924,
          "to_latitude": 21.022169619652946,
          "to_longitude": 105.81261868939666,
          "from_longitude": 105.81264947828907
        }
      },
      {
        "id": 7582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81326244972301,
            21.02258406327534,
            105.81353567399088,
            21.02261461525961
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81326244972301,
              21.02258406327534
            ],
            [
              105.81353567399088,
              21.02261461525961
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7590",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02258406327534,
          "to_latitude": 21.02261461525961,
          "to_longitude": 105.81353567399088,
          "from_longitude": 105.81326244972301
        }
      },
      {
        "id": 7583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81326244972301,
            21.022378242532337,
            105.81328767538288,
            21.02258406327534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81328767538288,
              21.022378242532337
            ],
            [
              105.81326244972301,
              21.02258406327534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7591",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022378242532337,
          "to_latitude": 21.02258406327534,
          "to_longitude": 105.81326244972301,
          "from_longitude": 105.81328767538288
        }
      },
      {
        "id": 7584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81367398412004,
            21.02242220505101,
            105.81369129874709,
            21.02257999951967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81367398412004,
              21.02257999951967
            ],
            [
              105.81369129874709,
              21.02242220505101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7592",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02257999951967,
          "to_latitude": 21.02242220505101,
          "to_longitude": 105.81369129874709,
          "from_longitude": 105.81367398412004
        }
      },
      {
        "id": 7585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81255415842645,
            21.022306424316678,
            105.81257709680425,
            21.022474377076982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81255415842645,
              21.022474377076982
            ],
            [
              105.81257709680425,
              21.022306424316678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7593",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022474377076982,
          "to_latitude": 21.022306424316678,
          "to_longitude": 105.81257709680425,
          "from_longitude": 105.81255415842645
        }
      },
      {
        "id": 7586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8119945052056,
            21.022247143797244,
            105.81202993625178,
            21.022475543237274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8119945052056,
              21.022475543237274
            ],
            [
              105.81202925893723,
              21.022253168850085
            ],
            [
              105.8120295443616,
              21.022250629417535
            ],
            [
              105.81202993625178,
              21.022247143797244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7594",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022475543237274,
          "to_latitude": 21.022247143797244,
          "to_longitude": 105.81202993625178,
          "from_longitude": 105.8119945052056
        }
      },
      {
        "id": 7587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81180182519078,
            21.023329401797312,
            105.8118655685231,
            21.023649578706404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8118655685231,
              21.023329401797312
            ],
            [
              105.81186415624657,
              21.023336494359537
            ],
            [
              105.81180182519078,
              21.023649578706404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7595",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023329401797312,
          "to_latitude": 21.023649578706404,
          "to_longitude": 105.81180182519078,
          "from_longitude": 105.8118655685231
        }
      },
      {
        "id": 7588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.811995078166,
            21.02314363506304,
            105.81221004460515,
            21.0232112393023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.811995078166,
              21.0232112393023
            ],
            [
              105.81221004460515,
              21.02314363506304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7596",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0232112393023,
          "to_latitude": 21.02314363506304,
          "to_longitude": 105.81221004460515,
          "from_longitude": 105.811995078166
        }
      },
      {
        "id": 7589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81222932095038,
            21.023230297114893,
            105.81241064067405,
            21.023252865348528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81222932095038,
              21.023230297114893
            ],
            [
              105.81223332937437,
              21.023230795521677
            ],
            [
              105.8122412249365,
              21.023231778462506
            ],
            [
              105.81233712973554,
              21.02324371632187
            ],
            [
              105.81234223649457,
              21.023240438505095
            ],
            [
              105.81235005943705,
              21.023241867078866
            ],
            [
              105.81235315189531,
              21.023245710896457
            ],
            [
              105.81241064067405,
              21.023252865348528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7597",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023230297114893,
          "to_latitude": 21.023252865348528,
          "to_longitude": 105.81241064067405,
          "from_longitude": 105.81222932095038
        }
      },
      {
        "id": 7590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81230889414088,
            21.023896581030513,
            105.81279026334963,
            21.02396279319546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81230889414088,
              21.02396279319546
            ],
            [
              105.81258216129932,
              21.02392520605609
            ],
            [
              105.81279026334963,
              21.023896581030513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7598",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02396279319546,
          "to_latitude": 21.023896581030513,
          "to_longitude": 105.81279026334963,
          "from_longitude": 105.81230889414088
        }
      },
      {
        "id": 7591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81279026334963,
            21.023896581030513,
            105.81282606252186,
            21.02409292783769
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81279026334963,
              21.023896581030513
            ],
            [
              105.81282606252186,
              21.02409292783769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10.3_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7599",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023896581030513,
          "to_latitude": 21.02409292783769,
          "to_longitude": 105.81282606252186,
          "from_longitude": 105.81279026334963
        }
      },
      {
        "id": 7592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81346618986295,
            21.024078174393235,
            105.8136005758576,
            21.024079919154403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81346618986295,
              21.024079919154403
            ],
            [
              105.8136005758576,
              21.024078174393235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16d_Thành Công",
          "maTaiSan": "04.O9C.DODV.7600",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024079919154403,
          "to_latitude": 21.024078174393235,
          "to_longitude": 105.8136005758576,
          "from_longitude": 105.81346618986295
        }
      },
      {
        "id": 7593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81426428456048,
            21.02322009891944,
            105.81466022223289,
            21.02326088127298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81426428456048,
              21.02322009891944
            ],
            [
              105.81466022223289,
              21.02326088127298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7601",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02322009891944,
          "to_latitude": 21.02326088127298,
          "to_longitude": 105.81466022223289,
          "from_longitude": 105.81426428456048
        }
      },
      {
        "id": 7594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81463188855105,
            21.02326088127298,
            105.81466022223289,
            21.02348269331909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81466022223289,
              21.02326088127298
            ],
            [
              105.81463188855105,
              21.02348269331909
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7602",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02326088127298,
          "to_latitude": 21.02348269331909,
          "to_longitude": 105.81463188855105,
          "from_longitude": 105.81466022223289
        }
      },
      {
        "id": 7595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81399579303464,
            21.02234756984211,
            105.81401119478137,
            21.022536749556906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81401119478137,
              21.02234756984211
            ],
            [
              105.81399579303464,
              21.022536749556906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7603",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02234756984211,
          "to_latitude": 21.022536749556906,
          "to_longitude": 105.81399579303464,
          "from_longitude": 105.81401119478137
        }
      },
      {
        "id": 7596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337534618137,
            21.021930003103165,
            105.81393382746613,
            21.021999551738308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81337534618137,
              21.021930003103165
            ],
            [
              105.81376057756337,
              21.021970310469108
            ],
            [
              105.81379124652051,
              21.021977407619143
            ],
            [
              105.81386247694282,
              21.02198406319901
            ],
            [
              105.81391700395,
              21.021988846805773
            ],
            [
              105.81393382746613,
              21.021999551738308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7604",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021930003103165,
          "to_latitude": 21.021999551738308,
          "to_longitude": 105.81393382746613,
          "from_longitude": 105.81337534618137
        }
      },
      {
        "id": 7597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337534618137,
            21.021732700490958,
            105.81339834369628,
            21.021930003103165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81339834369628,
              21.021732700490958
            ],
            [
              105.81337534618137,
              21.021930003103165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7605",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021732700490958,
          "to_latitude": 21.021930003103165,
          "to_longitude": 105.81337534618137,
          "from_longitude": 105.81339834369628
        }
      },
      {
        "id": 7598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81396222513612,
            21.021732132780265,
            105.81407030223627,
            21.02174270265941
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81396222513612,
              21.021732132780265
            ],
            [
              105.81407030223627,
              21.02174270265941
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7606",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021732132780265,
          "to_latitude": 21.02174270265941,
          "to_longitude": 105.81407030223627,
          "from_longitude": 105.81396222513612
        }
      },
      {
        "id": 7599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81400060127456,
            21.021443795181515,
            105.81408682633175,
            21.021454624986728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81400060127456,
              21.021443795181515
            ],
            [
              105.81408682633175,
              21.021454624986728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7607",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021443795181515,
          "to_latitude": 21.021454624986728,
          "to_longitude": 105.81408682633175,
          "from_longitude": 105.81400060127456
        }
      },
      {
        "id": 7600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81408467967015,
            21.021688586029125,
            105.81422706115882,
            21.021807682659436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81413807451818,
              21.021792995142555
            ],
            [
              105.81411182995049,
              21.021793025922513
            ],
            [
              105.81408879452786,
              21.02179305308061
            ],
            [
              105.81408526482137,
              21.021791952682346
            ],
            [
              105.81408467967015,
              21.021796687491474
            ],
            [
              105.81411112611596,
              21.021798934592184
            ],
            [
              105.81413715198222,
              21.021801145766325
            ],
            [
              105.8141578614207,
              21.021802905480435
            ],
            [
              105.8142140891138,
              21.021807682659436
            ],
            [
              105.81421499051845,
              21.0218016646564
            ],
            [
              105.81422706115882,
              21.021688586029125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7608",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021792995142555,
          "to_latitude": 21.021688586029125,
          "to_longitude": 105.81422706115882,
          "from_longitude": 105.81413807451818
        }
      },
      {
        "id": 7601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81411461306918,
            21.02202584224094,
            105.81413268763197,
            21.02219134672092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81411461306918,
              21.02219134672092
            ],
            [
              105.81411734859495,
              21.02216629977745
            ],
            [
              105.81413268763197,
              21.02202584224094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7609",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02219134672092,
          "to_latitude": 21.02202584224094,
          "to_longitude": 105.81413268763197,
          "from_longitude": 105.81411461306918
        }
      },
      {
        "id": 7602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81504638764252,
            21.02218522592026,
            105.81537045319259,
            21.022218079334365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81504638764252,
              21.02218522592026
            ],
            [
              105.81537045319259,
              21.022218079334365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7610",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02218522592026,
          "to_latitude": 21.022218079334365,
          "to_longitude": 105.81537045319259,
          "from_longitude": 105.81504638764252
        }
      },
      {
        "id": 7603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8149785915767,
            21.02213294592564,
            105.81505209025723,
            21.02218522592026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8149785915767,
              21.02213294592564
            ],
            [
              105.81505209025723,
              21.022138324713676
            ],
            [
              105.81504638764252,
              21.02218522592026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7611",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02213294592564,
          "to_latitude": 21.02218522592026,
          "to_longitude": 105.81504638764252,
          "from_longitude": 105.8149785915767
        }
      },
      {
        "id": 7604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409752714771,
            21.02219218268593,
            105.81412262707374,
            21.022370449068916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81409752714771,
              21.022370449068916
            ],
            [
              105.81411921563956,
              21.02221641092775
            ],
            [
              105.81412262707374,
              21.02219218268593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7612",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022370449068916,
          "to_latitude": 21.02219218268593,
          "to_longitude": 105.81412262707374,
          "from_longitude": 105.81409752714771
        }
      },
      {
        "id": 7605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81533062215388,
            21.021808342615294,
            105.8153520619512,
            21.021947016700555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8153520619512,
              21.021808342615294
            ],
            [
              105.81533317865828,
              21.021930482613847
            ],
            [
              105.81533189621486,
              21.02193877496767
            ],
            [
              105.81533062215388,
              21.021947016700555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7613",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021808342615294,
          "to_latitude": 21.021947016700555,
          "to_longitude": 105.81533062215388,
          "from_longitude": 105.8153520619512
        }
      },
      {
        "id": 7606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8154830725642,
            21.021253717747477,
            105.81552877530885,
            21.02152450642766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81550819541265,
              21.02152450642766
            ],
            [
              105.81551834862948,
              21.02137991158634
            ],
            [
              105.81551867364188,
              21.021373695824764
            ],
            [
              105.8154830725642,
              21.02137046857268
            ],
            [
              105.81549523774648,
              21.021267525440173
            ],
            [
              105.81552877530885,
              21.021262960507375
            ],
            [
              105.81552765243752,
              21.021253717747477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7614",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02152450642766,
          "to_latitude": 21.021253717747477,
          "to_longitude": 105.81552765243752,
          "from_longitude": 105.81550819541265
        }
      },
      {
        "id": 7607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8140609467308,
            21.021124811036998,
            105.81484969896304,
            21.021205785546798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81484969896304,
              21.021205785546798
            ],
            [
              105.81484723872444,
              21.021205522751735
            ],
            [
              105.81459931582133,
              21.021179040804622
            ],
            [
              105.81433808595527,
              21.021151136393385
            ],
            [
              105.81409163588178,
              21.021124811036998
            ],
            [
              105.81408015587482,
              21.02115461829511
            ],
            [
              105.81406847026288,
              21.021153818866658
            ],
            [
              105.8140609467308,
              21.021153302099044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7615",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021205785546798,
          "to_latitude": 21.021153302099044,
          "to_longitude": 105.8140609467308,
          "from_longitude": 105.81484969896304
        }
      },
      {
        "id": 7608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81492257593958,
            21.02094178795332,
            105.81531447185257,
            21.020977018117872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81492257593958,
              21.02094178795332
            ],
            [
              105.81531447185257,
              21.020977018117872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7616",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02094178795332,
          "to_latitude": 21.020977018117872,
          "to_longitude": 105.81531447185257,
          "from_longitude": 105.81492257593958
        }
      },
      {
        "id": 7609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81503928119756,
            21.021554175057158,
            105.81506850477392,
            21.02181918197431
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81506850477392,
              21.021554175057158
            ],
            [
              105.81506843049364,
              21.02155484470452
            ],
            [
              105.81503928119756,
              21.02181918197431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7617",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021554175057158,
          "to_latitude": 21.02181918197431,
          "to_longitude": 105.81503928119756,
          "from_longitude": 105.81506850477392
        }
      },
      {
        "id": 7610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81407523050261,
            21.02118663336402,
            105.81411578059155,
            21.021366153468517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81408729150294,
              21.02118663336402
            ],
            [
              105.81407691060672,
              21.021241524311073
            ],
            [
              105.81407523050261,
              21.02128945308593
            ],
            [
              105.81408933975297,
              21.021334625511507
            ],
            [
              105.81411578059155,
              21.021366153468517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7618",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02118663336402,
          "to_latitude": 21.021366153468517,
          "to_longitude": 105.81411578059155,
          "from_longitude": 105.81408729150294
        }
      },
      {
        "id": 7611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81484969896304,
            21.020906103550665,
            105.81488499489207,
            21.021205785546798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81488499489207,
              21.020906103550665
            ],
            [
              105.81485052657324,
              21.02119876169904
            ],
            [
              105.81484969896304,
              21.021205785546798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7619",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020906103550665,
          "to_latitude": 21.021205785546798,
          "to_longitude": 105.81484969896304,
          "from_longitude": 105.81488499489207
        }
      },
      {
        "id": 7612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81470827242126,
            21.02088766348167,
            105.81488499489207,
            21.020906103550665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81470827242126,
              21.02088766348167
            ],
            [
              105.81488499489207,
              21.020906103550665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7620",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02088766348167,
          "to_latitude": 21.020906103550665,
          "to_longitude": 105.81488499489207,
          "from_longitude": 105.81470827242126
        }
      },
      {
        "id": 7613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81406055448957,
            21.02058711886484,
            105.81408331724488,
            21.020765279244348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81408331724488,
              21.02058711886484
            ],
            [
              105.81406055448957,
              21.020765279244348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7621",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02058711886484,
          "to_latitude": 21.020765279244348,
          "to_longitude": 105.81406055448957,
          "from_longitude": 105.81408331724488
        }
      },
      {
        "id": 7614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81316293642972,
            21.02047234566837,
            105.8131979257068,
            21.020773485570082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8131979257068,
              21.02047234566837
            ],
            [
              105.81319696297837,
              21.02048763920631
            ],
            [
              105.81316293642972,
              21.020773485570082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7622",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02047234566837,
          "to_latitude": 21.020773485570082,
          "to_longitude": 105.81316293642972,
          "from_longitude": 105.8131979257068
        }
      },
      {
        "id": 7615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81316293642972,
            21.020706792166596,
            105.81324662524379,
            21.020782715396614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81316293642972,
              21.020773485570082
            ],
            [
              105.81323629367486,
              21.020782715396614
            ],
            [
              105.81324662524379,
              21.020706792166596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7623",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020773485570082,
          "to_latitude": 21.020706792166596,
          "to_longitude": 105.81324662524379,
          "from_longitude": 105.81316293642972
        }
      },
      {
        "id": 7616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81398044742421,
            21.021207234242343,
            105.81400867965532,
            21.021426173919956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81398044742421,
              21.021426173919956
            ],
            [
              105.81398101903582,
              21.021421602659903
            ],
            [
              105.81400867965532,
              21.021220456107013
            ],
            [
              105.81400782387017,
              21.021207234242343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7624",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021426173919956,
          "to_latitude": 21.021207234242343,
          "to_longitude": 105.81400782387017,
          "from_longitude": 105.81398044742421
        }
      },
      {
        "id": 7617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81310253462962,
            21.021129190634277,
            105.81312448544037,
            21.02131822369407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81310253462962,
              21.02131822369407
            ],
            [
              105.81312448544037,
              21.021129190634277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7625",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02131822369407,
          "to_latitude": 21.021129190634277,
          "to_longitude": 105.81312448544037,
          "from_longitude": 105.81310253462962
        }
      },
      {
        "id": 7618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81357021172717,
            21.02153547688342,
            105.81358819480566,
            21.021690819582766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81357021172717,
              21.021690819582766
            ],
            [
              105.81358819480566,
              21.02153547688342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7626",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021690819582766,
          "to_latitude": 21.02153547688342,
          "to_longitude": 105.81358819480566,
          "from_longitude": 105.81357021172717
        }
      },
      {
        "id": 7619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81306669278402,
            21.0214330999445,
            105.81309003328161,
            21.021638359800072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81309003328161,
              21.0214330999445
            ],
            [
              105.81306698662222,
              21.021635778777416
            ],
            [
              105.81306669278402,
              21.021638359800072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7627",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0214330999445,
          "to_latitude": 21.021638359800072,
          "to_longitude": 105.81306669278402,
          "from_longitude": 105.81309003328161
        }
      },
      {
        "id": 7620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81713753666413,
            21.020257434860724,
            105.81735162332988,
            21.020417311279353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81735162332988,
              21.020257434860724
            ],
            [
              105.81723157231555,
              21.020361559437408
            ],
            [
              105.81713753666413,
              21.020417311279353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2h_Láng Hạ",
          "maTaiSan": "04.O9C.DODV.7628",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020257434860724,
          "to_latitude": 21.020417311279353,
          "to_longitude": 105.81713753666413,
          "from_longitude": 105.81735162332988
        }
      },
      {
        "id": 7621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81635632242327,
            21.02028321636588,
            105.81651058551533,
            21.02047078706024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81651058551533,
              21.02047078706024
            ],
            [
              105.81635632242327,
              21.02028321636588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7629",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02047078706024,
          "to_latitude": 21.02028321636588,
          "to_longitude": 105.81635632242327,
          "from_longitude": 105.81651058551533
        }
      },
      {
        "id": 7622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81622506135804,
            21.02028321636588,
            105.81635632242327,
            21.02037665648689
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81635632242327,
              21.02028321636588
            ],
            [
              105.81622506135804,
              21.02037665648689
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26h_Thành Công",
          "maTaiSan": "04.O9C.DODV.7630",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02028321636588,
          "to_latitude": 21.02037665648689,
          "to_longitude": 105.81622506135804,
          "from_longitude": 105.81635632242327
        }
      },
      {
        "id": 7623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81716921426187,
            21.020329758514457,
            105.81737615143174,
            21.020465840327358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81716921426187,
              21.020465840327358
            ],
            [
              105.81717605245794,
              21.020461343727657
            ],
            [
              105.81737615143174,
              21.020329758514457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7631",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020465840327358,
          "to_latitude": 21.020329758514457,
          "to_longitude": 105.81737615143174,
          "from_longitude": 105.81716921426187
        }
      },
      {
        "id": 7624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730381290308,
            21.02113195696994,
            105.81750768562199,
            21.02127202281875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81750768562199,
              21.02113195696994
            ],
            [
              105.81742809648084,
              21.021186636962238
            ],
            [
              105.81742087807471,
              21.021191448819355
            ],
            [
              105.81741884415247,
              21.021192804401963
            ],
            [
              105.81741126342834,
              21.02119785645178
            ],
            [
              105.81732880145175,
              21.02125477485302
            ],
            [
              105.81731634742992,
              21.021263371094484
            ],
            [
              105.81730768473547,
              21.021269353243245
            ],
            [
              105.81730655672847,
              21.02127013092244
            ],
            [
              105.81730381290308,
              21.02127202281875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7632",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02113195696994,
          "to_latitude": 21.02127202281875,
          "to_longitude": 105.81730381290308,
          "from_longitude": 105.81750768562199
        }
      },
      {
        "id": 7625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81604328860936,
            21.021230145301093,
            105.81604863502658,
            21.021246009222782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81604863502658,
              21.021246009222782
            ],
            [
              105.81604780980476,
              21.021243562726685
            ],
            [
              105.81604588164016,
              21.02123783919467
            ],
            [
              105.81604328860936,
              21.021230145301093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7633",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021246009222782,
          "to_latitude": 21.021230145301093,
          "to_longitude": 105.81604328860936,
          "from_longitude": 105.81604863502658
        }
      },
      {
        "id": 7626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81574113430156,
            21.021639231023528,
            105.8157814763847,
            21.021737820783624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81574113430156,
              21.021639231023528
            ],
            [
              105.81574550558315,
              21.02164985007495
            ],
            [
              105.8157814763847,
              21.021737820783624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7634",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021639231023528,
          "to_latitude": 21.021737820783624,
          "to_longitude": 105.8157814763847,
          "from_longitude": 105.81574113430156
        }
      },
      {
        "id": 7627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690180881277,
            21.02126533005044,
            105.81703606429244,
            21.02138307465936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81690180881277,
              21.02138307465936
            ],
            [
              105.81703606429244,
              21.02126533005044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7635",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02138307465936,
          "to_latitude": 21.02126533005044,
          "to_longitude": 105.81703606429244,
          "from_longitude": 105.81690180881277
        }
      },
      {
        "id": 7628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81702781474684,
            21.02251659727815,
            105.81702903608631,
            21.02252182380438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81702903608631,
              21.02252182380438
            ],
            [
              105.81702871008888,
              21.022520432594554
            ],
            [
              105.81702813880062,
              21.0225179848844
            ],
            [
              105.81702781474684,
              21.02251659727815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_09_Thành Công",
          "maTaiSan": "04.O9C.DODV.7636",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02252182380438,
          "to_latitude": 21.02251659727815,
          "to_longitude": 105.81702781474684,
          "from_longitude": 105.81702903608631
        }
      },
      {
        "id": 7629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81811226819688,
            21.023091069898424,
            105.81813712398325,
            21.02325043576622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81813712398325,
              21.02325043576622
            ],
            [
              105.81812675759946,
              21.023183971122076
            ],
            [
              105.81811226819688,
              21.023091069898424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Thành Công",
          "maTaiSan": "04.O9C.DODV.7637",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02325043576622,
          "to_latitude": 21.023091069898424,
          "to_longitude": 105.81811226819688,
          "from_longitude": 105.81813712398325
        }
      },
      {
        "id": 7630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81396362947802,
            21.02278339764347,
            105.8140427465408,
            21.023400342735325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81396362947802,
              21.023400342735325
            ],
            [
              105.81399862654128,
              21.023127436732143
            ],
            [
              105.81399917383638,
              21.023123169976746
            ],
            [
              105.81399917844519,
              21.023123132922365
            ],
            [
              105.81400150420501,
              21.02310499853561
            ],
            [
              105.81400643626414,
              21.023066537189834
            ],
            [
              105.81400810397996,
              21.02305353357371
            ],
            [
              105.81401630779705,
              21.02298956388135
            ],
            [
              105.8140166492823,
              21.022986902242568
            ],
            [
              105.8140170987324,
              21.02298339556952
            ],
            [
              105.81401914114049,
              21.022967468219576
            ],
            [
              105.81403217732732,
              21.02286581533511
            ],
            [
              105.8140427465408,
              21.02278339764347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7638",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023400342735325,
          "to_latitude": 21.02278339764347,
          "to_longitude": 105.8140427465408,
          "from_longitude": 105.81396362947802
        }
      },
      {
        "id": 7631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81780139436589,
            21.022278708932262,
            105.81781779980919,
            21.02238573106478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81781779980919,
              21.02238573106478
            ],
            [
              105.81780139436589,
              21.022278708932262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Thành Công",
          "maTaiSan": "04.O9C.DODV.7639",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02238573106478,
          "to_latitude": 21.022278708932262,
          "to_longitude": 105.81780139436589,
          "from_longitude": 105.81781779980919
        }
      },
      {
        "id": 7632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80476766421728,
            21.018023619424284,
            105.80490901778892,
            21.018212623254936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80490901778892,
              21.018023619424284
            ],
            [
              105.80490712157314,
              21.018025912663404
            ],
            [
              105.80490682911214,
              21.018026266306652
            ],
            [
              105.80482904913343,
              21.018130422588744
            ],
            [
              105.80476766421728,
              21.018212623254936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M75_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7640",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018023619424284,
          "to_latitude": 21.018212623254936,
          "to_longitude": 105.80476766421728,
          "from_longitude": 105.80490901778892
        }
      },
      {
        "id": 7633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80465984171335,
            21.0180197308225,
            105.80490237865303,
            21.018230391568956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80490237865303,
              21.0180197308225
            ],
            [
              105.8048488563127,
              21.018095017125994
            ],
            [
              105.80475958950456,
              21.01820437071241
            ],
            [
              105.80473902778056,
              21.018230391568956
            ],
            [
              105.80465984171335,
              21.018183141576014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M77_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7641",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0180197308225,
          "to_latitude": 21.018183141576014,
          "to_longitude": 105.80465984171335,
          "from_longitude": 105.80490237865303
        }
      },
      {
        "id": 7634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80476766421728,
            21.018212623254936,
            105.80491035674248,
            21.018345388213096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80491035674248,
              21.018345388213096
            ],
            [
              105.80485902612145,
              21.018305991381087
            ],
            [
              105.80476766421728,
              21.018212623254936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M75_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7642",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": 20.93,
          "from_latitude": 21.018345388213096,
          "to_latitude": 21.018212623254936,
          "to_longitude": 105.80476766421728,
          "from_longitude": 105.80491035674248
        }
      },
      {
        "id": 7635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81352816948073,
            21.021721736113882,
            105.81353569540873,
            21.02178835789036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81353566355409,
              21.021721736113882
            ],
            [
              105.81353569540873,
              21.021743565164176
            ],
            [
              105.81353509996806,
              21.02174711588986
            ],
            [
              105.81352816948073,
              21.02178835789036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3e_Thành Công",
          "maTaiSan": "04.O9C.DODV.7643",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021721736113882,
          "to_latitude": 21.02178835789036,
          "to_longitude": 105.81352816948073,
          "from_longitude": 105.81353566355409
        }
      },
      {
        "id": 7636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82632440708348,
            21.0089339423566,
            105.82648223419223,
            21.009013707720378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82632440708348,
              21.009013707720378
            ],
            [
              105.82648223419223,
              21.0089339423566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.7644",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009013707720378,
          "to_latitude": 21.0089339423566,
          "to_longitude": 105.82648223419223,
          "from_longitude": 105.82632440708348
        }
      },
      {
        "id": 7637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362541626217,
            21.014213762200328,
            105.83656565607862,
            21.014524452059256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83656565607862,
              21.014524452059256
            ],
            [
              105.83655943764212,
              21.014517975807177
            ],
            [
              105.83649063667406,
              21.014437256022937
            ],
            [
              105.83648115332974,
              21.014426616600694
            ],
            [
              105.83646141173942,
              21.014404203501467
            ],
            [
              105.83636591200121,
              21.014319759057184
            ],
            [
              105.8362541626217,
              21.014213762200328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.2_Ngách 260/1, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7645",
          "diaChiLapD": "Ngách 260/1, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014524452059256,
          "to_latitude": 21.014213762200328,
          "to_longitude": 105.8362541626217,
          "from_longitude": 105.83656565607862
        }
      },
      {
        "id": 7638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80924594221464,
            21.01548127226694,
            105.80935006677903,
            21.01556422717156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80924594221464,
              21.01548127226694
            ],
            [
              105.80928992429813,
              21.01549576039535
            ],
            [
              105.8093282785847,
              21.01553761963493
            ],
            [
              105.80935006677903,
              21.01556422717156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.7646",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01548127226694,
          "to_latitude": 21.01556422717156,
          "to_longitude": 105.80935006677903,
          "from_longitude": 105.80924594221464
        }
      },
      {
        "id": 7639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8104539752693,
            21.015526451274088,
            105.8104785262121,
            21.01554520005547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8104539752693,
              21.015526451274088
            ],
            [
              105.81045870205756,
              21.01552855315071
            ],
            [
              105.8104785262121,
              21.01554520005547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.7647",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015526451274088,
          "to_latitude": 21.01554520005547,
          "to_longitude": 105.8104785262121,
          "from_longitude": 105.8104539752693
        }
      },
      {
        "id": 7640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81075126646279,
            21.01654259313174,
            105.81077401183876,
            21.016567480701354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81077401183876,
              21.01654259313174
            ],
            [
              105.81076409682854,
              21.016553228932334
            ],
            [
              105.81075126646279,
              21.016567480701354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.7648",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01654259313174,
          "to_latitude": 21.016567480701354,
          "to_longitude": 105.81075126646279,
          "from_longitude": 105.81077401183876
        }
      },
      {
        "id": 7641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81043996293698,
            21.016396898039087,
            105.81093254093307,
            21.01676664207618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81043996293698,
              21.016396898039087
            ],
            [
              105.81053008324004,
              21.016446280851724
            ],
            [
              105.81075126646279,
              21.016567480701354
            ],
            [
              105.81084783743744,
              21.016634770714948
            ],
            [
              105.81088277396925,
              21.016670810109026
            ],
            [
              105.81093254093307,
              21.01676664207618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.7649",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016396898039087,
          "to_latitude": 21.01676664207618,
          "to_longitude": 105.81093254093307,
          "from_longitude": 105.81043996293698
        }
      },
      {
        "id": 7642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8125637661223,
            21.015412014599832,
            105.81268453014141,
            21.01548122142367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81268453014141,
              21.015412014599832
            ],
            [
              105.81268361809641,
              21.015412523861134
            ],
            [
              105.8125637661223,
              21.01548122142367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.7650",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015412014599832,
          "to_latitude": 21.01548122142367,
          "to_longitude": 105.8125637661223,
          "from_longitude": 105.81268453014141
        }
      },
      {
        "id": 7643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8126916036455,
            21.015243775523913,
            105.81294640568115,
            21.015409241319322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8126916036455,
              21.015409241319322
            ],
            [
              105.81294640568115,
              21.015243775523913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.7651",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015409241319322,
          "to_latitude": 21.015243775523913,
          "to_longitude": 105.81294640568115,
          "from_longitude": 105.8126916036455
        }
      },
      {
        "id": 7644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81429640667196,
            21.01595861241385,
            105.81436161605713,
            21.01599240539955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81436161605713,
              21.01595861241385
            ],
            [
              105.81434158568302,
              21.015968562510995
            ],
            [
              105.81429640667196,
              21.01599240539955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Láng Hạ",
          "maTaiSan": "04.O9B.DODV.7652",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01595861241385,
          "to_latitude": 21.01599240539955,
          "to_longitude": 105.81429640667196,
          "from_longitude": 105.81436161605713
        }
      },
      {
        "id": 7645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81025674268543,
            21.01608591853033,
            105.81030223682704,
            21.01614504468588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81025674268543,
              21.01614504468588
            ],
            [
              105.810259575024,
              21.016141908734888
            ],
            [
              105.81030223682704,
              21.01608591853033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.7653",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01614504468588,
          "to_latitude": 21.01608591853033,
          "to_longitude": 105.81030223682704,
          "from_longitude": 105.81025674268543
        }
      },
      {
        "id": 7646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80770471268795,
            21.013310519509904,
            105.80780359472493,
            21.013404450183494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80770471268795,
              21.013310519509904
            ],
            [
              105.8077128087806,
              21.01331776200811
            ],
            [
              105.80780359472493,
              21.013404450183494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Đường Láng",
          "maTaiSan": "04.O9B.DODV.7654",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013310519509904,
          "to_latitude": 21.013404450183494,
          "to_longitude": 105.80780359472493,
          "from_longitude": 105.80770471268795
        }
      },
      {
        "id": 7647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80803618738625,
            21.01306185820923,
            105.8081552332593,
            21.01319773553331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80803618738625,
              21.01306185820923
            ],
            [
              105.80804764523994,
              21.01307478410347
            ],
            [
              105.8081552332593,
              21.01319773553331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97_Đường Láng",
          "maTaiSan": "04.O9B.DODV.7655",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01306185820923,
          "to_latitude": 21.01319773553331,
          "to_longitude": 105.8081552332593,
          "from_longitude": 105.80803618738625
        }
      },
      {
        "id": 7648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80878069660021,
            21.012541138117943,
            105.80888139366851,
            21.01266303255063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80878069660021,
              21.012541138117943
            ],
            [
              105.80879012421799,
              21.012553416965
            ],
            [
              105.80888139366851,
              21.01266303255063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96_Đường Láng",
          "maTaiSan": "04.O9B.DODV.7656",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012541138117943,
          "to_latitude": 21.01266303255063,
          "to_longitude": 105.80888139366851,
          "from_longitude": 105.80878069660021
        }
      },
      {
        "id": 7649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80918434765036,
            21.012259535826693,
            105.80928817754166,
            21.012394396329928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80918434765036,
              21.012259535826693
            ],
            [
              105.80919249581473,
              21.012269831825325
            ],
            [
              105.80928817754166,
              21.012394396329928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Đường Láng",
          "maTaiSan": "04.O9B.DODV.7657",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012259535826693,
          "to_latitude": 21.012394396329928,
          "to_longitude": 105.80928817754166,
          "from_longitude": 105.80918434765036
        }
      },
      {
        "id": 7650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81076585226945,
            21.016925488901695,
            105.81107830340079,
            21.017493719872263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81076585226945,
              21.016925488901695
            ],
            [
              105.81077128223613,
              21.016933986790498
            ],
            [
              105.8108968386534,
              21.017165270586002
            ],
            [
              105.81107830340079,
              21.017493719872263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Nguyên Hồng",
          "maTaiSan": "04.O9B.DODV.7658",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016925488901695,
          "to_latitude": 21.017493719872263,
          "to_longitude": 105.81107830340079,
          "from_longitude": 105.81076585226945
        }
      },
      {
        "id": 7651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81053990610825,
            21.017781081908222,
            105.81056080545488,
            21.017827453551675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81056080545488,
              21.017827453551675
            ],
            [
              105.81054295690437,
              21.01779052790728
            ],
            [
              105.81053990610825,
              21.01778421713461
            ],
            [
              105.81054599757708,
              21.017781081908222
            ],
            [
              105.81054762392232,
              21.017783870554396
            ],
            [
              105.81054976539748,
              21.01778710474741
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.7659",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017827453551675,
          "to_latitude": 21.01778710474741,
          "to_longitude": 105.81054976539748,
          "from_longitude": 105.81056080545488
        }
      },
      {
        "id": 7652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81025328311206,
            21.01833654222638,
            105.81053061269198,
            21.01849467739285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81053061269198,
              21.01833654222638
            ],
            [
              105.8105286742134,
              21.018337585659133
            ],
            [
              105.81031574718186,
              21.01845554555411
            ],
            [
              105.81025328311206,
              21.01849467739285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.7660",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01833654222638,
          "to_latitude": 21.01849467739285,
          "to_longitude": 105.81025328311206,
          "from_longitude": 105.81053061269198
        }
      },
      {
        "id": 7653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81014014109283,
            21.017978920945616,
            105.81037063621086,
            21.01840634355663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81014014109283,
              21.017978920945616
            ],
            [
              105.81014096135954,
              21.01798047149542
            ],
            [
              105.81027807593,
              21.018233524483062
            ],
            [
              105.81037063621086,
              21.01840634355663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.7661",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017978920945616,
          "to_latitude": 21.01840634355663,
          "to_longitude": 105.81037063621086,
          "from_longitude": 105.81014014109283
        }
      },
      {
        "id": 7654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80956510073136,
            21.018239137496668,
            105.80959741195439,
            21.018250990426907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80956510073136,
              21.018250990426907
            ],
            [
              105.80957085342305,
              21.018248707688052
            ],
            [
              105.8095924015648,
              21.018239137496668
            ],
            [
              105.80959741195439,
              21.018247429676464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.7662",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018250990426907,
          "to_latitude": 21.018247429676464,
          "to_longitude": 105.80959741195439,
          "from_longitude": 105.80956510073136
        }
      },
      {
        "id": 7655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82075485086399,
            21.014201722998795,
            105.82077022945495,
            21.014215572302675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82077022945495,
              21.014201722998795
            ],
            [
              105.8207572448556,
              21.014213416492858
            ],
            [
              105.82075485086399,
              21.014215572302675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DODV.7663",
          "diaChiLapD": "Ngõ 178, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014201722998795,
          "to_latitude": 21.014215572302675,
          "to_longitude": 105.82075485086399,
          "from_longitude": 105.82077022945495
        }
      },
      {
        "id": 7656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82214334713237,
            21.013164098142475,
            105.82217448918777,
            21.01319423783023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82217448918777,
              21.013164098142475
            ],
            [
              105.82216803617375,
              21.013169635396054
            ],
            [
              105.8221488360613,
              21.013186110903387
            ],
            [
              105.8221488025236,
              21.013186134549336
            ],
            [
              105.82214334713237,
              21.01319081433521
            ],
            [
              105.82214703082224,
              21.01319423783023
            ],
            [
              105.82214965711609,
              21.013192000491873
            ],
            [
              105.8221524551539,
              21.013189682839634
            ],
            [
              105.82215249062534,
              21.01318966099079
            ],
            [
              105.82217168714789,
              21.013173759049526
            ],
            [
              105.82217170725364,
              21.01317374179106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Trung Liệt",
          "maTaiSan": "04.O12.DODV.7664",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013164098142475,
          "to_latitude": 21.01317374179106,
          "to_longitude": 105.82217170725364,
          "from_longitude": 105.82217448918777
        }
      },
      {
        "id": 7657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8213725033488,
            21.01232539230395,
            105.82139781514313,
            21.0123472994397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82139781514313,
              21.01232539230395
            ],
            [
              105.82138827631584,
              21.012333216041245
            ],
            [
              105.82137633672419,
              21.012343005950953
            ],
            [
              105.82137630318161,
              21.012343028693564
            ],
            [
              105.8213725033488,
              21.012346144202606
            ],
            [
              105.82137418533489,
              21.0123472994397
            ],
            [
              105.82137784592557,
              21.01234457840984
            ],
            [
              105.82137788140186,
              21.01234455746436
            ],
            [
              105.82139030687017,
              21.012335314371118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Trung Liệt",
          "maTaiSan": "04.O12.DODV.7665",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01232539230395,
          "to_latitude": 21.012335314371118,
          "to_longitude": 105.82139030687017,
          "from_longitude": 105.82139781514313
        }
      },
      {
        "id": 7658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82351157842646,
            21.01172143616445,
            105.82352239683154,
            21.01173624912221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82352239683154,
              21.01173624912221
            ],
            [
              105.82351625304659,
              21.01172940710702
            ],
            [
              105.82351157842646,
              21.011724200950844
            ],
            [
              105.82351549940051,
              21.01172143616445
            ],
            [
              105.82351983478662,
              21.011726525639617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DODV.7666",
          "diaChiLapD": "Ngõ 178, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01173624912221,
          "to_latitude": 21.011726525639617,
          "to_longitude": 105.82351983478662,
          "from_longitude": 105.82352239683154
        }
      },
      {
        "id": 7659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82565592439265,
            21.013632990117618,
            105.82566363679366,
            21.0136481793402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82566363679366,
              21.0136481793402
            ],
            [
              105.82565842912574,
              21.013638777638825
            ],
            [
              105.82565592439265,
              21.013634255597577
            ],
            [
              105.82565817025683,
              21.013632990117618
            ],
            [
              105.8256583533907,
              21.013633406519478
            ],
            [
              105.82566065917715,
              21.01363778500991
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.7667",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0136481793402,
          "to_latitude": 21.01363778500991,
          "to_longitude": 105.82566065917715,
          "from_longitude": 105.82566363679366
        }
      },
      {
        "id": 7660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589782092897,
            21.014208175174026,
            105.8259070359081,
            21.014209802358614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82589782092897,
              21.014209802358614
            ],
            [
              105.8259033697753,
              21.014209008583858
            ],
            [
              105.8259070359081,
              21.014208175174026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.7668",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014209802358614,
          "to_latitude": 21.014208175174026,
          "to_longitude": 105.8259070359081,
          "from_longitude": 105.82589782092897
        }
      },
      {
        "id": 7661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590217905359,
            21.014209982981637,
            105.82592220009263,
            21.014215614929284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259085061098,
              21.014212261458372
            ],
            [
              105.82590409926503,
              21.01421361420605
            ],
            [
              105.82590217905359,
              21.014214276559883
            ],
            [
              105.82590278089457,
              21.014215614929284
            ],
            [
              105.82590905460357,
              21.01421351969958
            ],
            [
              105.82592220009263,
              21.014209982981637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62B_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DODV.7669",
          "diaChiLapD": "Ngõ 90, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014212261458372,
          "to_latitude": 21.014209982981637,
          "to_longitude": 105.82592220009263,
          "from_longitude": 105.8259085061098
        }
      },
      {
        "id": 7662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82565937167276,
            21.015705289590297,
            105.82567391680176,
            21.015729516685138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82567391680176,
              21.015729516685138
            ],
            [
              105.8256719159227,
              21.015726188072236
            ],
            [
              105.8256658695029,
              21.015716112123215
            ],
            [
              105.82565937167276,
              21.015705289590297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D64_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.7670",
          "diaChiLapD": "Ngõ 19, Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015729516685138,
          "to_latitude": 21.015705289590297,
          "to_longitude": 105.82565937167276,
          "from_longitude": 105.82567391680176
        }
      },
      {
        "id": 7663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82306269172693,
            21.013972024302298,
            105.82308040842425,
            21.01398922254223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82306269172693,
              21.01398922254223
            ],
            [
              105.82306271759424,
              21.013989203449334
            ],
            [
              105.8230667907971,
              21.013985243869953
            ],
            [
              105.8230756051262,
              21.013976686511388
            ],
            [
              105.82308040842425,
              21.013972024302298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140A_Trần Quang Diệu",
          "maTaiSan": "04.O12.DODV.7671",
          "diaChiLapD": "Trần Quang Diệu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01398922254223,
          "to_latitude": 21.013972024302298,
          "to_longitude": 105.82308040842425,
          "from_longitude": 105.82306269172693
        }
      },
      {
        "id": 7664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288687394393,
            21.015489783316,
            105.8228985201968,
            21.015490146985226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8228985201968,
              21.015490146985226
            ],
            [
              105.82289849229636,
              21.015490146216944
            ],
            [
              105.82289585709825,
              21.015490064124272
            ],
            [
              105.82288687394393,
              21.015489783316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D29_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.7672",
          "diaChiLapD": "Ngõ 95, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015490146985226,
          "to_latitude": 21.015489783316,
          "to_longitude": 105.82288687394393,
          "from_longitude": 105.8228985201968
        }
      },
      {
        "id": 7665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149262131723,
            21.015776043251723,
            105.82150359350818,
            21.015790878261132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82150359350818,
              21.015776043251723
            ],
            [
              105.82149950965096,
              21.01578105962217
            ],
            [
              105.82149262131723,
              21.015789511860767
            ],
            [
              105.82149415539963,
              21.015790878261132
            ],
            [
              105.82149531660683,
              21.0157894997493
            ],
            [
              105.82150081590532,
              21.015782278090168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B82_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DODV.7673",
          "diaChiLapD": "Ngõ 24, Đặng Tiến Đông",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015776043251723,
          "to_latitude": 21.015782278090168,
          "to_longitude": 105.82150081590532,
          "from_longitude": 105.82150359350818
        }
      },
      {
        "id": 7666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82229482819376,
            21.015921976632416,
            105.82232254007184,
            21.01592220488111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82229482819376,
              21.015921976632416
            ],
            [
              105.82230123597174,
              21.015922029660945
            ],
            [
              105.82231100588116,
              21.01592210979325
            ],
            [
              105.82232254007184,
              21.01592220488111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D23_Ngõ 86, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.7674",
          "diaChiLapD": "Ngõ 86, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015921976632416,
          "to_latitude": 21.01592220488111,
          "to_longitude": 105.82232254007184,
          "from_longitude": 105.82229482819376
        }
      },
      {
        "id": 7667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591531394561,
            21.02017067174315,
            105.82593351602127,
            21.020201882579812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593351602127,
              21.020201882579812
            ],
            [
              105.82592171818202,
              21.02018436761124
            ],
            [
              105.82591531394561,
              21.02017485974163
            ],
            [
              105.82592139937246,
              21.02017067174315
            ],
            [
              105.82592260775519,
              21.020172096585142
            ],
            [
              105.82592832608705,
              21.020180727139973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B46_Đông Các",
          "maTaiSan": "04.O12.DODV.7675",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020201882579812,
          "to_latitude": 21.020180727139973,
          "to_longitude": 105.82592832608705,
          "from_longitude": 105.82593351602127
        }
      },
      {
        "id": 7668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82716951415935,
            21.01947088910238,
            105.82718651170259,
            21.01951030092279
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82717935158728,
              21.01948165833321
            ],
            [
              105.82717398022854,
              21.01947088910238
            ],
            [
              105.82716951415935,
              21.019473214947883
            ],
            [
              105.82717461721633,
              21.01948434858018
            ],
            [
              105.82718651170259,
              21.01951030092279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A41_Đông Các",
          "maTaiSan": "04.O12.DODV.7676",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01948165833321,
          "to_latitude": 21.01951030092279,
          "to_longitude": 105.82718651170259,
          "from_longitude": 105.82717935158728
        }
      },
      {
        "id": 7669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807440188571,
            21.01893506029205,
            105.82809726814405,
            21.018969536231598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82809726814405,
              21.018969536231598
            ],
            [
              105.82807967550197,
              21.01894560872421
            ],
            [
              105.82807440188571,
              21.01893843749643
            ],
            [
              105.82807904970123,
              21.01893506029205
            ],
            [
              105.82808531914169,
              21.01894191867215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A33_Đông Các",
          "maTaiSan": "04.O12.DODV.7677",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018969536231598,
          "to_latitude": 21.01894191867215,
          "to_longitude": 105.82808531914169,
          "from_longitude": 105.82809726814405
        }
      },
      {
        "id": 7670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613572456701,
            21.01786891463829,
            105.82620192766724,
            21.017973124018262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613572456701,
              21.01786891463829
            ],
            [
              105.82620192766724,
              21.01796645492273
            ],
            [
              105.82619444181084,
              21.017969958763334
            ],
            [
              105.82618730231592,
              21.017973124018262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A74_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.7678",
          "diaChiLapD": "Ngõ 31, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01786891463829,
          "to_latitude": 21.017973124018262,
          "to_longitude": 105.82618730231592,
          "from_longitude": 105.82613572456701
        }
      },
      {
        "id": 7671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82664736925703,
            21.019999849803273,
            105.82665924597909,
            21.020018923303724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82665924597909,
              21.020018923303724
            ],
            [
              105.82665535112872,
              21.020012668427817
            ],
            [
              105.82664971219756,
              21.020003614802874
            ],
            [
              105.82664736925703,
              21.019999849803273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B41_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DODV.7679",
          "diaChiLapD": "Ô Chợ Dừa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020018923303724,
          "to_latitude": 21.019999849803273,
          "to_longitude": 105.82664736925703,
          "from_longitude": 105.82665924597909
        }
      },
      {
        "id": 7672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81699042485405,
            21.016679838833543,
            105.81701662022293,
            21.016702707925784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81699893581163,
              21.01669405774201
            ],
            [
              105.81699042485405,
              21.01668387859629
            ],
            [
              105.81699542517205,
              21.016679838833543
            ],
            [
              105.81700447532504,
              21.01668958093714
            ],
            [
              105.81701662022293,
              21.016702707925784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DODV.7680",
          "diaChiLapD": "Ngõ 87, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01669405774201,
          "to_latitude": 21.016702707925784,
          "to_longitude": 105.81701662022293,
          "from_longitude": 105.81699893581163
        }
      },
      {
        "id": 7673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8163836579347,
            21.01641373061533,
            105.8163917029259,
            21.016418147695333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81638736753519,
              21.01641445503426
            ],
            [
              105.81638733878574,
              21.01641447504323
            ],
            [
              105.8163836579347,
              21.01641704342148
            ],
            [
              105.81638467687331,
              21.016418147695333
            ],
            [
              105.81638834761186,
              21.016415842204804
            ],
            [
              105.81638840132207,
              21.016415813043768
            ],
            [
              105.81638845791802,
              21.016415783868897
            ],
            [
              105.81638851259012,
              21.01641575470325
            ],
            [
              105.81638854999687,
              21.016415734652742
            ],
            [
              105.8163886708497,
              21.016415669943633
            ],
            [
              105.8163917029259,
              21.01641373061533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B25_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DODV.7681",
          "diaChiLapD": "Ngõ 364, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01641445503426,
          "to_latitude": 21.01641373061533,
          "to_longitude": 105.8163917029259,
          "from_longitude": 105.81638736753519
        }
      },
      {
        "id": 7674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8210752178627,
            21.01932068711894,
            105.82110811413699,
            21.019329335133513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8210752178627,
              21.019328658284596
            ],
            [
              105.8210902219539,
              21.019324858245252
            ],
            [
              105.82110669389168,
              21.01932068711894
            ],
            [
              105.82110811413699,
              21.019325150331245
            ],
            [
              105.82109180292149,
              21.019329335133513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.7682",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019328658284596,
          "to_latitude": 21.019329335133513,
          "to_longitude": 105.82109180292149,
          "from_longitude": 105.8210752178627
        }
      },
      {
        "id": 7675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82222726072234,
            21.02194793878219,
            105.82225697436165,
            21.021960672963164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82223438329214,
              21.02195015752627
            ],
            [
              105.82222774404939,
              21.02194793878219
            ],
            [
              105.82222726072234,
              21.021949442280324
            ],
            [
              105.82223371551156,
              21.021951881400035
            ],
            [
              105.82225697436165,
              21.021960672963164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT155_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DODV.7683",
          "diaChiLapD": "Nguyễn Phúc Lai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02195015752627,
          "to_latitude": 21.021960672963164,
          "to_longitude": 105.82225697436165,
          "from_longitude": 105.82223438329214
        }
      },
      {
        "id": 7676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299207170303,
            21.021255973475338,
            105.82299683519042,
            21.021264071046893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8229927654581,
              21.02125905796326
            ],
            [
              105.82299207170303,
              21.02125657293245
            ],
            [
              105.82299481292715,
              21.021255973475338
            ],
            [
              105.82299547488807,
              21.02125862485354
            ],
            [
              105.82299683519042,
              21.021264071046893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT160_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.7684",
          "diaChiLapD": "Ngõ 16, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02125905796326,
          "to_latitude": 21.021264071046893,
          "to_longitude": 105.82299683519042,
          "from_longitude": 105.8229927654581
        }
      },
      {
        "id": 7677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84056543411832,
            21.008246785775658,
            105.8405719949257,
            21.008253097616954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84056543411832,
              21.008246785775658
            ],
            [
              105.84056697858988,
              21.008248273886412
            ],
            [
              105.84056953558982,
              21.008250731574954
            ],
            [
              105.8405719949257,
              21.008253097616954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.2_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7685",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008246785775658,
          "to_latitude": 21.008253097616954,
          "to_longitude": 105.8405719949257,
          "from_longitude": 105.84056543411832
        }
      },
      {
        "id": 7678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84030091454588,
            21.00847689240193,
            105.84030764961364,
            21.00848185849178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84030764961364,
              21.00848185849178
            ],
            [
              105.84030254399175,
              21.008478095579818
            ],
            [
              105.84030091454588,
              21.00847689240193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.3_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7686",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00848185849178,
          "to_latitude": 21.00847689240193,
          "to_longitude": 105.84030091454588,
          "from_longitude": 105.84030764961364
        }
      },
      {
        "id": 7679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8408112693056,
            21.00834691685301,
            105.84081288495696,
            21.00834783257603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8408112693056,
              21.00834783257603
            ],
            [
              105.84081169215575,
              21.00834759293882
            ],
            [
              105.84081288495696,
              21.00834691685301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.4_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7687",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00834783257603,
          "to_latitude": 21.00834691685301,
          "to_longitude": 105.84081288495696,
          "from_longitude": 105.8408112693056
        }
      },
      {
        "id": 7680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84080144706826,
            21.008340769501604,
            105.84080831616687,
            21.008344501240455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84080831616687,
              21.008340769501604
            ],
            [
              105.8408081243714,
              21.0083408734168
            ],
            [
              105.84080534431924,
              21.00834238379506
            ],
            [
              105.84080144706826,
              21.008344501240455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85.3_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7688",
          "diaChiLapD": "Ngõ 2, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008340769501604,
          "to_latitude": 21.008344501240455,
          "to_longitude": 105.84080144706826,
          "from_longitude": 105.84080831616687
        }
      },
      {
        "id": 7681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046170261504,
            21.008725954871878,
            105.84046676965833,
            21.008730283760972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046170261504,
              21.008730283760972
            ],
            [
              105.84046281501006,
              21.00872933349444
            ],
            [
              105.84046511542306,
              21.00872736755569
            ],
            [
              105.84046676965833,
              21.008725954871878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.3_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7689",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008730283760972,
          "to_latitude": 21.008725954871878,
          "to_longitude": 105.84046676965833,
          "from_longitude": 105.84046170261504
        }
      },
      {
        "id": 7682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84045983454116,
            21.008729452961006,
            105.84046877034872,
            21.0087359363324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84045983454116,
              21.0087359363324
            ],
            [
              105.84046408859334,
              21.00873243158625
            ],
            [
              105.84046770401076,
              21.008729452961006
            ],
            [
              105.84046877034872,
              21.008730927175876
            ],
            [
              105.84046536677421,
              21.008733946303195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.4_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7690",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0087359363324,
          "to_latitude": 21.008733946303195,
          "to_longitude": 105.84046536677421,
          "from_longitude": 105.84045983454116
        }
      },
      {
        "id": 7683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84076241149046,
            21.009148376583664,
            105.84077230649183,
            21.009154041720027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077230649183,
              21.009148376583664
            ],
            [
              105.84077190474486,
              21.009148606181096
            ],
            [
              105.84076952016736,
              21.009149971895702
            ],
            [
              105.84076241149046,
              21.009154041720027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.7_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7691",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009148376583664,
          "to_latitude": 21.009154041720027,
          "to_longitude": 105.84076241149046,
          "from_longitude": 105.84077230649183
        }
      },
      {
        "id": 7684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84077899786281,
            21.009155320930013,
            105.84078291014248,
            21.009161546492255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077899786281,
              21.009155320930013
            ],
            [
              105.84078291014248,
              21.009161546492255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84.8_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7692",
          "diaChiLapD": "Ngõ 26, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009155320930013,
          "to_latitude": 21.009161546492255,
          "to_longitude": 105.84078291014248,
          "from_longitude": 105.84077899786281
        }
      },
      {
        "id": 7685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399797739842,
            21.008816729046668,
            105.83999055419702,
            21.00882691839916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8399797739842,
              21.008816729046668
            ],
            [
              105.83998124288465,
              21.00881811998745
            ],
            [
              105.83998676255844,
              21.008823335056242
            ],
            [
              105.83999055419702,
              21.00882691839916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.6_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7693",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008816729046668,
          "to_latitude": 21.00882691839916,
          "to_longitude": 105.83999055419702,
          "from_longitude": 105.8399797739842
        }
      },
      {
        "id": 7686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009955900181,
            21.008940363604577,
            105.84010834049145,
            21.008947645401594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009955900181,
              21.008940363604577
            ],
            [
              105.84010104463262,
              21.008941478075
            ],
            [
              105.84010834049145,
              21.008947645401594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.2_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7694",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008940363604577,
          "to_latitude": 21.008947645401594,
          "to_longitude": 105.84010834049145,
          "from_longitude": 105.84009955900181
        }
      },
      {
        "id": 7687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401423237101,
            21.008945848132523,
            105.84015167042992,
            21.00895512342361
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015167042992,
              21.008945848132523
            ],
            [
              105.84015127345032,
              21.008946242091785
            ],
            [
              105.84014904749708,
              21.008948451529417
            ],
            [
              105.8401423237101,
              21.00895512342361
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT83.3_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7695",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008945848132523,
          "to_latitude": 21.00895512342361,
          "to_longitude": 105.8401423237101,
          "from_longitude": 105.84015167042992
        }
      },
      {
        "id": 7688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84034889253127,
            21.00914316870566,
            105.84035811367912,
            21.009150991906356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84035811367912,
              21.00914316870566
            ],
            [
              105.84035798156361,
              21.009143280454673
            ],
            [
              105.84035482615623,
              21.0091459578389
            ],
            [
              105.84034889253127,
              21.009150991906356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.4_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7696",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00914316870566,
          "to_latitude": 21.009150991906356,
          "to_longitude": 105.84034889253127,
          "from_longitude": 105.84035811367912
        }
      },
      {
        "id": 7689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036130086264,
            21.009145315141403,
            105.84036480509245,
            21.009147710635936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84036130086264,
              21.009147710635936
            ],
            [
              105.84036165061381,
              21.009147471360784
            ],
            [
              105.84036462973843,
              21.00914543523297
            ],
            [
              105.84036480509245,
              21.009145315141403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83.5_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7697",
          "diaChiLapD": "Ngõ 50, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009147710635936,
          "to_latitude": 21.009145315141403,
          "to_longitude": 105.84036480509245,
          "from_longitude": 105.84036130086264
        }
      },
      {
        "id": 7690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971028114078,
            21.009065859570597,
            105.83971626445424,
            21.00907176332684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971626445424,
              21.00907176332684
            ],
            [
              105.83971351945091,
              21.009069056360286
            ],
            [
              105.83971028114078,
              21.009065859570597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.8_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7698",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00907176332684,
          "to_latitude": 21.009065859570597,
          "to_longitude": 105.83971028114078,
          "from_longitude": 105.83971626445424
        }
      },
      {
        "id": 7691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947985393553,
            21.009296443453252,
            105.83948384253219,
            21.0092998135779
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83948384253219,
              21.0092998135779
            ],
            [
              105.83948146310749,
              21.009297804803104
            ],
            [
              105.83947985393553,
              21.009296443453252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74.12_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7699",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0092998135779,
          "to_latitude": 21.009296443453252,
          "to_longitude": 105.83947985393553,
          "from_longitude": 105.83948384253219
        }
      },
      {
        "id": 7692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395530993449,
            21.009753335412103,
            105.83955666105801,
            21.00975698033346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395530993449,
              21.00975698033346
            ],
            [
              105.83955321890161,
              21.009756858711537
            ],
            [
              105.83955636839309,
              21.009753634920052
            ],
            [
              105.83955666105801,
              21.009753335412103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.2_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7700",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00975698033346,
          "to_latitude": 21.009753335412103,
          "to_longitude": 105.83955666105801,
          "from_longitude": 105.8395530993449
        }
      },
      {
        "id": 7693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955312524317,
            21.00974119082878,
            105.83956138624018,
            21.0097512944085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956138624018,
              21.00974119082878
            ],
            [
              105.83955698394162,
              21.009746574991382
            ],
            [
              105.83955346630147,
              21.009750876339407
            ],
            [
              105.83955312524317,
              21.0097512944085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80.3_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7701",
          "diaChiLapD": "Ngõ 88, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00974119082878,
          "to_latitude": 21.0097512944085,
          "to_longitude": 105.83955312524317,
          "from_longitude": 105.83956138624018
        }
      },
      {
        "id": 7694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890735775933,
            21.010416444516583,
            105.83891444742008,
            21.010427031839317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891444742008,
              21.010416444516583
            ],
            [
              105.83891443215636,
              21.01041646717244
            ],
            [
              105.83891357932973,
              21.010417739503968
            ],
            [
              105.8389118336363,
              21.0104203484933
            ],
            [
              105.83890735775933,
              21.010427031839317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.2_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7702",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010416444516583,
          "to_latitude": 21.010427031839317,
          "to_longitude": 105.83890735775933,
          "from_longitude": 105.83891444742008
        }
      },
      {
        "id": 7695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921488717746,
            21.01059841173896,
            105.83921997372433,
            21.01060408649323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921488717746,
              21.01060408649323
            ],
            [
              105.83921556969878,
              21.01060332170904
            ],
            [
              105.83921850252473,
              21.01060005112108
            ],
            [
              105.83921997372433,
              21.01059841173896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.4_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7703",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01060408649323,
          "to_latitude": 21.01059841173896,
          "to_longitude": 105.83921997372433,
          "from_longitude": 105.83921488717746
        }
      },
      {
        "id": 7696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920196278794,
            21.01059579017009,
            105.83921518119443,
            21.010606964681052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920196278794,
              21.010606964681052
            ],
            [
              105.83920758730532,
              21.010599789728072
            ],
            [
              105.83921071709176,
              21.01059579017009
            ],
            [
              105.83921518119443,
              21.010597837440663
            ],
            [
              105.83921206428033,
              21.010602418611704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79.5_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7704",
          "diaChiLapD": "Ngõ 126, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010606964681052,
          "to_latitude": 21.010602418611704,
          "to_longitude": 105.83921206428033,
          "from_longitude": 105.83920196278794
        }
      },
      {
        "id": 7697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856046968653,
            21.010614957130077,
            105.83857481995649,
            21.010622874388282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856046968653,
              21.010622874388282
            ],
            [
              105.83856790152706,
              21.01061825841604
            ],
            [
              105.83857321683179,
              21.010614957130077
            ],
            [
              105.83857481995649,
              21.01061626793734
            ],
            [
              105.83856941006658,
              21.0106200285275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78.3_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7705",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010622874388282,
          "to_latitude": 21.0106200285275,
          "to_longitude": 105.83856941006658,
          "from_longitude": 105.83856046968653
        }
      },
      {
        "id": 7698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878921142465,
            21.010727838821094,
            105.83879844154252,
            21.01073604066366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83878921142465,
              21.010727838821094
            ],
            [
              105.8387902710206,
              21.01072879553188
            ],
            [
              105.83879844154252,
              21.01073604066366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT78.4_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7706",
          "diaChiLapD": "Ngõ 138, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010727838821094,
          "to_latitude": 21.01073604066366,
          "to_longitude": 105.83879844154252,
          "from_longitude": 105.83878921142465
        }
      },
      {
        "id": 7699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8409970312378,
            21.00987487547076,
            105.84100169225702,
            21.009879619651645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84100169225702,
              21.009879619651645
            ],
            [
              105.84100050330218,
              21.009878409788136
            ],
            [
              105.84099819795819,
              21.009876065573252
            ],
            [
              105.8409970312378,
              21.00987487547076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.14_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7707",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009879619651645,
          "to_latitude": 21.00987487547076,
          "to_longitude": 105.8409970312378,
          "from_longitude": 105.84100169225702
        }
      },
      {
        "id": 7700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84055857708823,
            21.010229328148505,
            105.8405657484088,
            21.010236430001143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8405657484088,
              21.010236430001143
            ],
            [
              105.84056278186979,
              21.010233504660558
            ],
            [
              105.84055975491539,
              21.010230440521845
            ],
            [
              105.84055857708823,
              21.010229328148505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.9_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7708",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010236430001143,
          "to_latitude": 21.010229328148505,
          "to_longitude": 105.84055857708823,
          "from_longitude": 105.8405657484088
        }
      },
      {
        "id": 7701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84055575856028,
            21.01023173468818,
            105.84056791011035,
            21.010245756853713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84056430053126,
              21.010237764892697
            ],
            [
              105.84055814040083,
              21.01023173468818
            ],
            [
              105.84055575856028,
              21.010234279094128
            ],
            [
              105.84056184787215,
              21.010240030552314
            ],
            [
              105.84056791011035,
              21.010245756853713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.8_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7709",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010237764892697,
          "to_latitude": 21.010245756853713,
          "to_longitude": 105.84056791011035,
          "from_longitude": 105.84056430053126
        }
      },
      {
        "id": 7702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84026179585356,
            21.010530925383787,
            105.84027303063863,
            21.01054270842713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027023286744,
              21.01053539528859
            ],
            [
              105.84026416612166,
              21.010530925383787
            ],
            [
              105.84026179585356,
              21.010533648567062
            ],
            [
              105.84026746493902,
              21.010538219790245
            ],
            [
              105.84027303063863,
              21.01054270842713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81.6_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7710",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01053539528859,
          "to_latitude": 21.01054270842713,
          "to_longitude": 105.84027303063863,
          "from_longitude": 105.84027023286744
        }
      },
      {
        "id": 7703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83988269452863,
            21.0109329848513,
            105.83988963942157,
            21.010937956240436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83988963942157,
              21.010937956240436
            ],
            [
              105.8398872191894,
              21.010936224060597
            ],
            [
              105.83988456814288,
              21.010934327729966
            ],
            [
              105.83988269452863,
              21.0109329848513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.1_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7711",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010937956240436,
          "to_latitude": 21.0109329848513,
          "to_longitude": 105.83988269452863,
          "from_longitude": 105.83988963942157
        }
      },
      {
        "id": 7704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83975019277368,
            21.011074868354676,
            105.83976647863044,
            21.011083285003373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83975298144077,
              21.011079382847214
            ],
            [
              105.83975019277368,
              21.011077474548582
            ],
            [
              105.8397524684856,
              21.011074868354676
            ],
            [
              105.83975536587174,
              21.011076609020257
            ],
            [
              105.83976647863044,
              21.011083285003373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82.2_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7712",
          "diaChiLapD": "Ngõ 378, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011079382847214,
          "to_latitude": 21.011083285003373,
          "to_longitude": 105.83976647863044,
          "from_longitude": 105.83975298144077
        }
      },
      {
        "id": 7705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726045376307,
            21.0107549024846,
            105.83784515961078,
            21.011249617818027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83726045376307,
              21.011249617818027
            ],
            [
              105.83747372549082,
              21.010969315635727
            ],
            [
              105.83756984319542,
              21.0108427063332
            ],
            [
              105.83761416690824,
              21.010876145011885
            ],
            [
              105.83762100055232,
              21.010869301059945
            ],
            [
              105.83770051012623,
              21.010782542417573
            ],
            [
              105.83777690322094,
              21.0107549024846
            ],
            [
              105.83783773255084,
              21.010767892126392
            ],
            [
              105.83784515961078,
              21.01076947684487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT73.1_Xã Đàn",
          "maTaiSan": "04.O16A.DODV.7713",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011249617818027,
          "to_latitude": 21.01076947684487,
          "to_longitude": 105.83784515961078,
          "from_longitude": 105.83726045376307
        }
      },
      {
        "id": 7706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758514211735,
            21.01125050645552,
            105.83759810747466,
            21.01126098055462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758514211735,
              21.01125050645552
            ],
            [
              105.83759324426877,
              21.0112570537896
            ],
            [
              105.83759810747466,
              21.01126098055462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.5_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7714",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01125050645552,
          "to_latitude": 21.01126098055462,
          "to_longitude": 105.83759810747466,
          "from_longitude": 105.83758514211735
        }
      },
      {
        "id": 7707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753178656335,
            21.011821319761847,
            105.83754866449118,
            21.011840577391546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754866449118,
              21.011821319761847
            ],
            [
              105.83754564408454,
              21.0118247650757
            ],
            [
              105.8375386445961,
              21.011832752420496
            ],
            [
              105.83753178656335,
              21.011840577391546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT75.3_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7715",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011821319761847,
          "to_latitude": 21.011840577391546,
          "to_longitude": 105.83753178656335,
          "from_longitude": 105.83754866449118
        }
      },
      {
        "id": 7708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83812448918925,
            21.012216195759688,
            105.83813815010473,
            21.012230452703214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812448918925,
              21.012216195759688
            ],
            [
              105.83812856356523,
              21.01222044794129
            ],
            [
              105.83813763634504,
              21.012229918718667
            ],
            [
              105.83813815010473,
              21.012230452703214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.11_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7716",
          "diaChiLapD": "Ngõ 198, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012216195759688,
          "to_latitude": 21.012230452703214,
          "to_longitude": 105.83813815010473,
          "from_longitude": 105.83812448918925
        }
      },
      {
        "id": 7709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824686986804,
            21.012224223228717,
            105.8382558487811,
            21.012238094924296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824686986804,
              21.012238094924296
            ],
            [
              105.83824863321308,
              21.01223536843527
            ],
            [
              105.83825180612104,
              21.012230468166887
            ],
            [
              105.8382558487811,
              21.012224223228717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.12_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7717",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012238094924296,
          "to_latitude": 21.012224223228717,
          "to_longitude": 105.8382558487811,
          "from_longitude": 105.83824686986804
        }
      },
      {
        "id": 7710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380009821486,
            21.010964581010278,
            105.83800427782559,
            21.01096919736313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83800427782559,
              21.010964581010278
            ],
            [
              105.8380035533991,
              21.010965594382913
            ],
            [
              105.83800110145529,
              21.01096903058226
            ],
            [
              105.8380009821486,
              21.01096919736313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.2_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7718",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010964581010278,
          "to_latitude": 21.01096919736313,
          "to_longitude": 105.8380009821486,
          "from_longitude": 105.83800427782559
        }
      },
      {
        "id": 7711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806243002518,
            21.011076928290514,
            105.83807498165164,
            21.011082469631173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807089809726,
              21.011082469631173
            ],
            [
              105.83807498165164,
              21.011080449779726
            ],
            [
              105.83807329403409,
              21.011076928290514
            ],
            [
              105.83806908907596,
              21.011078911707656
            ],
            [
              105.83806243002518,
              21.011082052500182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.3_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7719",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011082469631173,
          "to_latitude": 21.011082052500182,
          "to_longitude": 105.83806243002518,
          "from_longitude": 105.83807089809726
        }
      },
      {
        "id": 7712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900721725264,
            21.011465288790582,
            105.8390125717366,
            21.01147345595277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83900721725264,
              21.01147345595277
            ],
            [
              105.83901141665109,
              21.01146738274057
            ],
            [
              105.8390125717366,
              21.011465288790582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.11_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7720",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01147345595277,
          "to_latitude": 21.011465288790582,
          "to_longitude": 105.8390125717366,
          "from_longitude": 105.83900721725264
        }
      },
      {
        "id": 7713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83901051569396,
            21.01147213894149,
            105.83902252332801,
            21.011485849989935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83901051569396,
              21.011485849989935
            ],
            [
              105.83901566807168,
              21.011477663953265
            ],
            [
              105.83901914621806,
              21.01147213894149
            ],
            [
              105.83902252332801,
              21.011473659577003
            ],
            [
              105.83901937211648,
              21.01147950813749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77.10_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7721",
          "diaChiLapD": "Ngõ 105, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011485849989935,
          "to_latitude": 21.01147950813749,
          "to_longitude": 105.83901937211648,
          "from_longitude": 105.83901051569396
        }
      },
      {
        "id": 7714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665449309818,
            21.012264536918927,
            105.83666429476341,
            21.012271737099738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665449309818,
              21.012264536918927
            ],
            [
              105.83665765827224,
              21.01226686431986
            ],
            [
              105.8366641778964,
              21.01227165186807
            ],
            [
              105.83666429476341,
              21.012271737099738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73.9_Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7722",
          "diaChiLapD": "Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012264536918927,
          "to_latitude": 21.012271737099738,
          "to_longitude": 105.83666429476341,
          "from_longitude": 105.83665449309818
        }
      },
      {
        "id": 7715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83678953752795,
            21.012796366812662,
            105.83679325789474,
            21.012800392716656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678953752795,
              21.012800392716656
            ],
            [
              105.83679193989454,
              21.012797715486233
            ],
            [
              105.83679325789474,
              21.012796366812662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.3_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7723",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012800392716656,
          "to_latitude": 21.012796366812662,
          "to_longitude": 105.83679325789474,
          "from_longitude": 105.83678953752795
        }
      },
      {
        "id": 7716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83678593317167,
            21.012799314855826,
            105.83679645116975,
            21.012813662552738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678593317167,
              21.012813662552738
            ],
            [
              105.83679412679203,
              21.012804178140918
            ],
            [
              105.83679645116975,
              21.012801487745644
            ],
            [
              105.83679364179162,
              21.012799314855826
            ],
            [
              105.83679131756966,
              21.012801860734402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.4_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DODV.7724",
          "diaChiLapD": "Ngõ 28, Kim Hoa",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012813662552738,
          "to_latitude": 21.012801860734402,
          "to_longitude": 105.83679131756966,
          "from_longitude": 105.83678593317167
        }
      },
      {
        "id": 7717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83757322196644,
            21.01284746684074,
            105.83757486712071,
            21.01286612297027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83757486712071,
              21.01286612297027
            ],
            [
              105.83757354748072,
              21.01285115400661
            ],
            [
              105.83757326924918,
              21.01284801034904
            ],
            [
              105.83757322196644,
              21.01284746684074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.2_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7725",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01286612297027,
          "to_latitude": 21.01284746684074,
          "to_longitude": 105.83757322196644,
          "from_longitude": 105.83757486712071
        }
      },
      {
        "id": 7718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749043249698,
            21.012845556268623,
            105.8375690823095,
            21.01287572281027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749043249698,
              21.012871407874666
            ],
            [
              105.83750579918475,
              21.01287572281027
            ],
            [
              105.83751616663332,
              21.012875014231405
            ],
            [
              105.83756556693521,
              21.012845556268623
            ],
            [
              105.83756877521006,
              21.012849396340318
            ],
            [
              105.8375690823095,
              21.01284995753736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.1_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7726",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012871407874666,
          "to_latitude": 21.01284995753736,
          "to_longitude": 105.8375690823095,
          "from_longitude": 105.83749043249698
        }
      },
      {
        "id": 7719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83772156687705,
            21.012716688480328,
            105.83772648077723,
            21.012720554473955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83772648077723,
              21.012720554473955
            ],
            [
              105.83772508275266,
              21.012719456713775
            ],
            [
              105.83772258907672,
              21.012717492736545
            ],
            [
              105.83772156687705,
              21.012716688480328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.3_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7727",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012720554473955,
          "to_latitude": 21.012716688480328,
          "to_longitude": 105.83772156687705,
          "from_longitude": 105.83772648077723
        }
      },
      {
        "id": 7720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83781715308375,
            21.012547821368546,
            105.83782861999128,
            21.012552294234144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83782176455853,
              21.012552294234144
            ],
            [
              105.83781897827063,
              21.012551157246126
            ],
            [
              105.83781715308375,
              21.012550218751056
            ],
            [
              105.8378186662561,
              21.012547821368546
            ],
            [
              105.83782297250252,
              21.01254967074202
            ],
            [
              105.83782861999128,
              21.012552096090687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT76.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7728",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012552294234144,
          "to_latitude": 21.012552096090687,
          "to_longitude": 105.83782861999128,
          "from_longitude": 105.83782176455853
        }
      },
      {
        "id": 7721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813317583703,
            21.012317364675372,
            105.83814116584057,
            21.01233016645778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83813317583703,
              21.012317364675372
            ],
            [
              105.83813407234024,
              21.012318569676697
            ],
            [
              105.83814116584057,
              21.01233016645778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76.10_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7729",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012317364675372,
          "to_latitude": 21.01233016645778,
          "to_longitude": 105.83814116584057,
          "from_longitude": 105.83813317583703
        }
      },
      {
        "id": 7722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83747983119092,
            21.013325745059944,
            105.83749520636998,
            21.01334001993797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749520636998,
              21.013325745059944
            ],
            [
              105.83748876310064,
              21.013331029024805
            ],
            [
              105.83747983119092,
              21.01333835388082
            ],
            [
              105.83748229627739,
              21.01334001993797
            ],
            [
              105.83749102745574,
              21.013333784455156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.9_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7730",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013325745059944,
          "to_latitude": 21.013333784455156,
          "to_longitude": 105.83749102745574,
          "from_longitude": 105.83749520636998
        }
      },
      {
        "id": 7723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379335111749,
            21.0134921620676,
            105.83793938638162,
            21.01349702234881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793938638162,
              21.0134921620676
            ],
            [
              105.83793661538387,
              21.01349445454925
            ],
            [
              105.83793449356472,
              21.013496209124966
            ],
            [
              105.8379335111749,
              21.01349702234881
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.15_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7731",
          "diaChiLapD": "Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0134921620676,
          "to_latitude": 21.01349702234881,
          "to_longitude": 105.8379335111749,
          "from_longitude": 105.83793938638162
        }
      },
      {
        "id": 7724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83848915331278,
            21.01356310409795,
            105.83851174189353,
            21.013586381312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83851174189353,
              21.01356310409795
            ],
            [
              105.83849352508012,
              21.01358187616259
            ],
            [
              105.83848971568183,
              21.01358580138033
            ],
            [
              105.83848915331278,
              21.013586381312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.18_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.7732",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01356310409795,
          "to_latitude": 21.013586381312,
          "to_longitude": 105.83848915331278,
          "from_longitude": 105.83851174189353
        }
      },
      {
        "id": 7725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83848824877694,
            21.013588211363945,
            105.8384915115955,
            21.013591485373087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848824877694,
              21.013591485373087
            ],
            [
              105.83849119306109,
              21.013588530868624
            ],
            [
              105.8384915115955,
              21.013588211363945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.19_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.7733",
          "diaChiLapD": "Ngõ 119, Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013591485373087,
          "to_latitude": 21.013588211363945,
          "to_longitude": 105.8384915115955,
          "from_longitude": 105.83848824877694
        }
      },
      {
        "id": 7726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387305408326,
            21.01398135628032,
            105.83874465084135,
            21.013996397833026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83873459953972,
              21.013988458366534
            ],
            [
              105.8387305408326,
              21.013983063546544
            ],
            [
              105.8387331409902,
              21.01398135628032
            ],
            [
              105.83873752089607,
              21.013987080096836
            ],
            [
              105.83874465084135,
              21.013996397833026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6A_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7734",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013988458366534,
          "to_latitude": 21.013996397833026,
          "to_longitude": 105.83874465084135,
          "from_longitude": 105.83873459953972
        }
      },
      {
        "id": 7727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855796426626,
            21.014140110565524,
            105.83856843354359,
            21.014151045763917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856308478171,
              21.014143393818852
            ],
            [
              105.83855942649846,
              21.014140110565524
            ],
            [
              105.83855796426626,
              21.014141640608162
            ],
            [
              105.83856153749338,
              21.01414485111959
            ],
            [
              105.83856843354359,
              21.014151045763917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.6_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7735",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014143393818852,
          "to_latitude": 21.014151045763917,
          "to_longitude": 105.83856843354359,
          "from_longitude": 105.83856308478171
        }
      },
      {
        "id": 7728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832794442357,
            21.014171389189173,
            105.83833370714461,
            21.014176692526018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83833370714461,
              21.014176692526018
            ],
            [
              105.83833350023801,
              21.01417650386846
            ],
            [
              105.8383314639368,
              21.014174627970156
            ],
            [
              105.83832794442357,
              21.014171389189173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48.5_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7736",
          "diaChiLapD": "Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014176692526018,
          "to_latitude": 21.014171389189173,
          "to_longitude": 105.83832794442357,
          "from_longitude": 105.83833370714461
        }
      },
      {
        "id": 7729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792215661957,
            21.01466214250451,
            105.83792932959624,
            21.01466733253185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792215661957,
              21.01466733253185
            ],
            [
              105.83792260307044,
              21.014667008785278
            ],
            [
              105.83792498193071,
              21.014665288169258
            ],
            [
              105.83792932959624,
              21.01466214250451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.8_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7737",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01466733253185,
          "to_latitude": 21.01466214250451,
          "to_longitude": 105.83792932959624,
          "from_longitude": 105.83792215661957
        }
      },
      {
        "id": 7730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792433598406,
            21.0146714935817,
            105.83792676249736,
            21.01467312140036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792676249736,
              21.0146714935817
            ],
            [
              105.83792628524542,
              21.01467181386709
            ],
            [
              105.83792433598406,
              21.01467312140036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.9_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7738",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0146714935817,
          "to_latitude": 21.01467312140036,
          "to_longitude": 105.83792433598406,
          "from_longitude": 105.83792676249736
        }
      },
      {
        "id": 7731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83833299730146,
            21.01482954907885,
            105.83833846927548,
            21.014837155504623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83833299730146,
              21.01482954907885
            ],
            [
              105.83833496983165,
              21.014833954318238
            ],
            [
              105.83833640368486,
              21.014837155504623
            ],
            [
              105.83833846927548,
              21.01483669732729
            ],
            [
              105.8383383802458,
              21.014836257895883
            ],
            [
              105.83833725250018,
              21.014833094943015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49.1_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7739",
          "diaChiLapD": "Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01482954907885,
          "to_latitude": 21.014833094943015,
          "to_longitude": 105.83833725250018,
          "from_longitude": 105.83833299730146
        }
      },
      {
        "id": 7732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832576825041,
            21.015084314821497,
            105.83833489731589,
            21.015091829467597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832576825041,
              21.015091829467597
            ],
            [
              105.83832692398583,
              21.01509087809718
            ],
            [
              105.83833158235633,
              21.015087043537747
            ],
            [
              105.83833489731589,
              21.015084314821497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.13_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7740",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015091829467597,
          "to_latitude": 21.015084314821497,
          "to_longitude": 105.83833489731589,
          "from_longitude": 105.83832576825041
        }
      },
      {
        "id": 7733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83811299507427,
            21.015190585149682,
            105.83812447611132,
            21.01520475234839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83811600602732,
              21.015197302734627
            ],
            [
              105.83811299507427,
              21.015191775384814
            ],
            [
              105.83811606853386,
              21.015190585149682
            ],
            [
              105.83811928085154,
              21.01519599770122
            ],
            [
              105.83812447611132,
              21.01520475234839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.1_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7741",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015197302734627,
          "to_latitude": 21.01520475234839,
          "to_longitude": 105.83812447611132,
          "from_longitude": 105.83811600602732
        }
      },
      {
        "id": 7734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83787964042722,
            21.015361700334328,
            105.8378899845976,
            21.015375047815155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83787964042722,
              21.015361700334328
            ],
            [
              105.83787986910242,
              21.015361997272052
            ],
            [
              105.8378851109392,
              21.015368759184984
            ],
            [
              105.8378899845976,
              21.015375047815155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7742",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015361700334328,
          "to_latitude": 21.015375047815155,
          "to_longitude": 105.8378899845976,
          "from_longitude": 105.83787964042722
        }
      },
      {
        "id": 7735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771606410092,
            21.01613096594131,
            105.8377222380792,
            21.016143867615416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83771606410092,
              21.016138247040907
            ],
            [
              105.8377179366444,
              21.016143867615416
            ],
            [
              105.8377222380792,
              21.01614242566324
            ],
            [
              105.83772010382621,
              21.01613696624812
            ],
            [
              105.83771775781162,
              21.01613096594131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.2_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7743",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016138247040907,
          "to_latitude": 21.01613096594131,
          "to_longitude": 105.83771775781162,
          "from_longitude": 105.83771606410092
        }
      },
      {
        "id": 7736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373448275019,
            21.016187125708022,
            105.83734531407964,
            21.016190773515177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734531407964,
              21.016187125708022
            ],
            [
              105.83734521097031,
              21.016188126987757
            ],
            [
              105.8373448275019,
              21.016190773515177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.4_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7744",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016187125708022,
          "to_latitude": 21.016190773515177,
          "to_longitude": 105.8373448275019,
          "from_longitude": 105.83734531407964
        }
      },
      {
        "id": 7737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723779183713,
            21.016251665478304,
            105.83724488880802,
            21.016260241889864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724488880802,
              21.016260241889864
            ],
            [
              105.83724436582018,
              21.016259607690714
            ],
            [
              105.83723779183713,
              21.016251665478304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40.5_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7745",
          "diaChiLapD": "Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016260241889864,
          "to_latitude": 21.016251665478304,
          "to_longitude": 105.83723779183713,
          "from_longitude": 105.83724488880802
        }
      },
      {
        "id": 7738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371289732701,
            21.013975105934446,
            105.83713029171268,
            21.013976420864548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371289732701,
              21.013975105934446
            ],
            [
              105.83712998315316,
              21.013976115286475
            ],
            [
              105.83713029171268,
              21.013976420864548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.6_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7746",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013975105934446,
          "to_latitude": 21.013976420864548,
          "to_longitude": 105.83713029171268,
          "from_longitude": 105.8371289732701
        }
      },
      {
        "id": 7739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695970877227,
            21.014135918671165,
            105.83696479152857,
            21.014140044886574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695970877227,
              21.014135918671165
            ],
            [
              105.83696056009676,
              21.014136611772447
            ],
            [
              105.83696222695896,
              21.01413796292528
            ],
            [
              105.83696479152857,
              21.014140044886574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45.7_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7747",
          "diaChiLapD": "Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014135918671165,
          "to_latitude": 21.014140044886574,
          "to_longitude": 105.83696479152857,
          "from_longitude": 105.83695970877227
        }
      },
      {
        "id": 7740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373721553259,
            21.014122710023106,
            105.83737302022115,
            21.014124455311723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737302022115,
              21.014124455311723
            ],
            [
              105.83737269335226,
              21.014123799373216
            ],
            [
              105.8373721553259,
              21.014122710023106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.1_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7748",
          "diaChiLapD": "Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014124455311723,
          "to_latitude": 21.014122710023106,
          "to_longitude": 105.8373721553259,
          "from_longitude": 105.83737302022115
        }
      },
      {
        "id": 7741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769376981046,
            21.01440542203809,
            105.83770582615448,
            21.014412283594854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769376981046,
              21.014412121273576
            ],
            [
              105.8376967045515,
              21.01441020824671
            ],
            [
              105.83770404715499,
              21.01440542203809
            ],
            [
              105.83770582615448,
              21.014407731855258
            ],
            [
              105.83769804167838,
              21.014412283594854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.4_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7749",
          "diaChiLapD": "Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014412121273576,
          "to_latitude": 21.014412283594854,
          "to_longitude": 105.83769804167838,
          "from_longitude": 105.83769376981046
        }
      },
      {
        "id": 7742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736475431444,
            21.014659146932598,
            105.83737030662455,
            21.014666393269856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737030662455,
              21.014666393269856
            ],
            [
              105.83736988999594,
              21.014665856095558
            ],
            [
              105.83736475431444,
              21.014659146932598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7750",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014666393269856,
          "to_latitude": 21.014659146932598,
          "to_longitude": 105.83736475431444,
          "from_longitude": 105.83737030662455
        }
      },
      {
        "id": 7743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562762959922,
            21.013250031650387,
            105.83564058938526,
            21.013267613397367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562762959922,
              21.013250031650387
            ],
            [
              105.83562841224577,
              21.013250658257395
            ],
            [
              105.83563007803886,
              21.01325199407446
            ],
            [
              105.83563561844794,
              21.013260236788636
            ],
            [
              105.83564058938526,
              21.013267613397367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42.2_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7751",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013250031650387,
          "to_latitude": 21.013267613397367,
          "to_longitude": 105.83564058938526,
          "from_longitude": 105.83562762959922
        }
      },
      {
        "id": 7744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608601940041,
            21.01333304430315,
            105.83610859592832,
            21.013359156972164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83610692326012,
              21.013349805531924
            ],
            [
              105.83610226687112,
              21.01335399679525
            ],
            [
              105.83609655776813,
              21.013359156972164
            ],
            [
              105.83608601940041,
              21.0133497447711
            ],
            [
              105.8360984059801,
              21.013340582109148
            ],
            [
              105.83610859592832,
              21.01333304430315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.1_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7752",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013349805531924,
          "to_latitude": 21.01333304430315,
          "to_longitude": 105.83610859592832,
          "from_longitude": 105.83610692326012
        }
      },
      {
        "id": 7745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659103721492,
            21.01403045347306,
            105.83660953431111,
            21.014041107635453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660953431111,
              21.01403045347306
            ],
            [
              105.83660672968573,
              21.01403206868082
            ],
            [
              105.83659786611392,
              21.01403717393399
            ],
            [
              105.83659103721492,
              21.014041107635453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.15_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7753",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01403045347306,
          "to_latitude": 21.014041107635453,
          "to_longitude": 105.83659103721492,
          "from_longitude": 105.83660953431111
        }
      },
      {
        "id": 7746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697741388984,
            21.014548222439338,
            105.83698487526536,
            21.01455327745958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698487526536,
              21.014548222439338
            ],
            [
              105.83698405112321,
              21.014548781073408
            ],
            [
              105.83698040668428,
              21.0145512503529
            ],
            [
              105.83697741388984,
              21.01455327745958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.18_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7754",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014548222439338,
          "to_latitude": 21.01455327745958,
          "to_longitude": 105.83697741388984,
          "from_longitude": 105.83698487526536
        }
      },
      {
        "id": 7747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367751552905,
            21.01424021672735,
            105.83692139730582,
            21.014462106017987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367751552905,
              21.01424021672735
            ],
            [
              105.8367772401261,
              21.014243726338933
            ],
            [
              105.83681386313913,
              21.014305377339863
            ],
            [
              105.83692139730582,
              21.014456456410276
            ],
            [
              105.83691553171542,
              21.014460639192265
            ],
            [
              105.83691351407732,
              21.014462106017987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7755",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01424021672735,
          "to_latitude": 21.014462106017987,
          "to_longitude": 105.83691351407732,
          "from_longitude": 105.8367751552905
        }
      },
      {
        "id": 7748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8374753222248,
            21.015318482867468,
            105.83748799285422,
            21.015325540710386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374753222248,
              21.015325540710386
            ],
            [
              105.83748191406082,
              21.015321869171714
            ],
            [
              105.83748728514492,
              21.015318876544068
            ],
            [
              105.83748799285422,
              21.015318482867468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.23_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7756",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015325540710386,
          "to_latitude": 21.015318482867468,
          "to_longitude": 105.83748799285422,
          "from_longitude": 105.8374753222248
        }
      },
      {
        "id": 7749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751560682703,
            21.015368217686305,
            105.83752805518739,
            21.015374597923213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751560682703,
              21.015374597923213
            ],
            [
              105.83751657656757,
              21.01537410089224
            ],
            [
              105.83752162478213,
              21.015371513594076
            ],
            [
              105.83752805518739,
              21.015368217686305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.24_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7757",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015374597923213,
          "to_latitude": 21.015368217686305,
          "to_longitude": 105.83752805518739,
          "from_longitude": 105.83751560682703
        }
      },
      {
        "id": 7750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375591906145,
            21.015461341024967,
            105.8375695131859,
            21.015466672995156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375695131859,
              21.015461341024967
            ],
            [
              105.8375669282259,
              21.015462676071067
            ],
            [
              105.83756065718165,
              21.01546591519593
            ],
            [
              105.8375591906145,
              21.015466672995156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.25_Ngõ 186, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7758",
          "diaChiLapD": "Ngõ 186, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015461341024967,
          "to_latitude": 21.015466672995156,
          "to_longitude": 105.8375591906145,
          "from_longitude": 105.8375695131859
        }
      },
      {
        "id": 7751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83777745350795,
            21.015844301992548,
            105.83778976518457,
            21.015851047381695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778976518457,
              21.015844301992548
            ],
            [
              105.83778662061323,
              21.015846024571296
            ],
            [
              105.83778043121147,
              21.015849415947237
            ],
            [
              105.83777745350795,
              21.015851047381695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.28_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7759",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015844301992548,
          "to_latitude": 21.015851047381695,
          "to_longitude": 105.83777745350795,
          "from_longitude": 105.83778976518457
        }
      },
      {
        "id": 7752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779423727754,
            21.01584694874637,
            105.83780180803375,
            21.015851371181398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779423727754,
              21.015851371181398
            ],
            [
              105.83779561125145,
              21.015850568674878
            ],
            [
              105.83779947333275,
              21.015848312370068
            ],
            [
              105.83780180803375,
              21.01584694874637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.30_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7760",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015851371181398,
          "to_latitude": 21.01584694874637,
          "to_longitude": 105.83780180803375,
          "from_longitude": 105.83779423727754
        }
      },
      {
        "id": 7753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806091696023,
            21.016306505408604,
            105.83806864307245,
            21.016311360362042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83806091696023,
              21.016311360362042
            ],
            [
              105.83806481740234,
              21.016308909668993
            ],
            [
              105.83806846382033,
              21.016306618291402
            ],
            [
              105.83806864307245,
              21.016306505408604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.31_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7761",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016311360362042,
          "to_latitude": 21.016306505408604,
          "to_longitude": 105.83806864307245,
          "from_longitude": 105.83806091696023
        }
      },
      {
        "id": 7754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806982404373,
            21.016304870485687,
            105.83807929512184,
            21.016310240744318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807929512184,
              21.016304870485687
            ],
            [
              105.8380749867731,
              21.01630731325306
            ],
            [
              105.83807017596092,
              21.01631004120466
            ],
            [
              105.83806982404373,
              21.016310240744318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31.32_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7762",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016304870485687,
          "to_latitude": 21.016310240744318,
          "to_longitude": 105.83806982404373,
          "from_longitude": 105.83807929512184
        }
      },
      {
        "id": 7755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836290083113,
            21.01681472970868,
            105.8383725586573,
            21.016819736001818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383725586573,
              21.01681472970868
            ],
            [
              105.83837032955864,
              21.016815885078444
            ],
            [
              105.83836368255201,
              21.01681933111809
            ],
            [
              105.83836290083113,
              21.016819736001818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7763",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01681472970868,
          "to_latitude": 21.016819736001818,
          "to_longitude": 105.83836290083113,
          "from_longitude": 105.8383725586573
        }
      },
      {
        "id": 7756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83835010704261,
            21.016797202196376,
            105.83845188129438,
            21.01698608317008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83835010704261,
              21.016797202196376
            ],
            [
              105.83836290083113,
              21.016819736001818
            ],
            [
              105.83836786134587,
              21.016828471923827
            ],
            [
              105.83838269238395,
              21.01685493645327
            ],
            [
              105.83840330756611,
              21.016891720710333
            ],
            [
              105.83845188129438,
              21.01698608317008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.16_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7764",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016797202196376,
          "to_latitude": 21.01698608317008,
          "to_longitude": 105.83845188129438,
          "from_longitude": 105.83835010704261
        }
      },
      {
        "id": 7757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842925861094,
            21.016909049506665,
            105.83844353578002,
            21.016916622048345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844353578002,
              21.016909049506665
            ],
            [
              105.83843507459393,
              21.016913536875165
            ],
            [
              105.83843056778129,
              21.016915927339646
            ],
            [
              105.83842925861094,
              21.016916622048345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.13_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7765",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016909049506665,
          "to_latitude": 21.016916622048345,
          "to_longitude": 105.83842925861094,
          "from_longitude": 105.83844353578002
        }
      },
      {
        "id": 7758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862341232215,
            21.01728356839918,
            105.83863669798605,
            21.01728800188342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83862341232215,
              21.01728800188342
            ],
            [
              105.83862438111676,
              21.017287678269625
            ],
            [
              105.83862905995232,
              21.017286117014454
            ],
            [
              105.83863669798605,
              21.01728356839918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.11_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7766",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01728800188342,
          "to_latitude": 21.01728356839918,
          "to_longitude": 105.83863669798605,
          "from_longitude": 105.83862341232215
        }
      },
      {
        "id": 7759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905448254718,
            21.018187710705288,
            105.83906859020715,
            21.018194131758044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906859020715,
              21.018187710705288
            ],
            [
              105.83906458233979,
              21.018189535114487
            ],
            [
              105.83905999013002,
              21.01819162524388
            ],
            [
              105.83905448254718,
              21.018194131758044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7767",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018187710705288,
          "to_latitude": 21.018194131758044,
          "to_longitude": 105.83905448254718,
          "from_longitude": 105.83906859020715
        }
      },
      {
        "id": 7760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903923262353,
            21.01814786167198,
            105.83907882633451,
            21.018262158093254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83907882633451,
              21.018262158093254
            ],
            [
              105.83906847797228,
              21.018236597329878
            ],
            [
              105.83905448254718,
              21.018194131758044
            ],
            [
              105.83903923262353,
              21.01814786167198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52.4_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7768",
          "diaChiLapD": "Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018262158093254,
          "to_latitude": 21.01814786167198,
          "to_longitude": 105.83903923262353,
          "from_longitude": 105.83907882633451
        }
      },
      {
        "id": 7761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979889843062,
            21.018559617539324,
            105.8398559315555,
            21.01861077182875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979889843062,
              21.018559617539324
            ],
            [
              105.83982814953075,
              21.01860616797138
            ],
            [
              105.8398549034929,
              21.018610647743532
            ],
            [
              105.8398559315555,
              21.01861077182875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88.4_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7769",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018559617539324,
          "to_latitude": 21.01861077182875,
          "to_longitude": 105.8398559315555,
          "from_longitude": 105.83979889843062
        }
      },
      {
        "id": 7762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965876370453,
            21.016835249303494,
            105.83967079501261,
            21.01684413408404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83965876370453,
              21.01684413408404
            ],
            [
              105.83966098285774,
              21.016842578618704
            ],
            [
              105.83967079501261,
              21.016835249303494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7770",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01684413408404,
          "to_latitude": 21.016835249303494,
          "to_longitude": 105.83967079501261,
          "from_longitude": 105.83965876370453
        }
      },
      {
        "id": 7763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953711131353,
            21.016693381295692,
            105.83974111192559,
            21.017035547014185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953711131353,
              21.016693381295692
            ],
            [
              105.83962745802,
              21.016783269519465
            ],
            [
              105.83966741648086,
              21.01682387542641
            ],
            [
              105.83967660432592,
              21.01683321186092
            ],
            [
              105.83969864430365,
              21.01686034700793
            ],
            [
              105.83971829922963,
              21.016915931918444
            ],
            [
              105.83974111192559,
              21.017035547014185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7771",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016693381295692,
          "to_latitude": 21.017035547014185,
          "to_longitude": 105.83974111192559,
          "from_longitude": 105.83953711131353
        }
      },
      {
        "id": 7764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965343466598,
            21.01682387542641,
            105.83966741648086,
            21.016839074232813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83966741648086,
              21.01682387542641
            ],
            [
              105.8396634475337,
              21.016827145717617
            ],
            [
              105.83965343466598,
              21.016835393698212
            ],
            [
              105.83965729823457,
              21.016839074232813
            ],
            [
              105.83966729117277,
              21.01683121834939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7772",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01682387542641,
          "to_latitude": 21.01683121834939,
          "to_longitude": 105.83966729117277,
          "from_longitude": 105.83966741648086
        }
      },
      {
        "id": 7765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83930671104412,
            21.016294010567428,
            105.83932338077405,
            21.01629979739775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931680794251,
              21.016294010567428
            ],
            [
              105.83930671104412,
              21.01629635005277
            ],
            [
              105.83930749603891,
              21.01629979739775
            ],
            [
              105.83931779921004,
              21.016297704378005
            ],
            [
              105.83932338077405,
              21.01629657039191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.6_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7773",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016294010567428,
          "to_latitude": 21.01629657039191,
          "to_longitude": 105.83932338077405,
          "from_longitude": 105.83931680794251
        }
      },
      {
        "id": 7766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924615503922,
            21.01604472017405,
            105.83926671017193,
            21.016049850316225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925838621562,
              21.016049850316225
            ],
            [
              105.83924615503922,
              21.016048451947817
            ],
            [
              105.83924681713516,
              21.01604472017405
            ],
            [
              105.83925844867126,
              21.016045539824475
            ],
            [
              105.83926671017193,
              21.016046122293343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.7_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7774",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016049850316225,
          "to_latitude": 21.016046122293343,
          "to_longitude": 105.83926671017193,
          "from_longitude": 105.83925838621562
        }
      },
      {
        "id": 7767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902483518675,
            21.016156801416738,
            105.83903195390332,
            21.01616512112823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902483518675,
              21.016156801416738
            ],
            [
              105.83903125191895,
              21.0161643026567
            ],
            [
              105.83903195390332,
              21.01616512112823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.1_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7775",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016156801416738,
          "to_latitude": 21.01616512112823,
          "to_longitude": 105.83903195390332,
          "from_longitude": 105.83902483518675
        }
      },
      {
        "id": 7768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927938063265,
            21.01591371492882,
            105.83929307475947,
            21.015922307480814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929307475947,
              21.015922307480814
            ],
            [
              105.83928335198941,
              21.015916207202263
            ],
            [
              105.83928029384506,
              21.015914290293342
            ],
            [
              105.83927938063265,
              21.01591371492882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.8_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7776",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015922307480814,
          "to_latitude": 21.01591371492882,
          "to_longitude": 105.83927938063265,
          "from_longitude": 105.83929307475947
        }
      },
      {
        "id": 7769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8394278838064,
            21.015668302563743,
            105.83943417131393,
            21.0156709333409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394278838064,
              21.015668302563743
            ],
            [
              105.83943122666702,
              21.01566970142135
            ],
            [
              105.83943404404366,
              21.01567088067866
            ],
            [
              105.83943417131393,
              21.0156709333409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.10_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7777",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015668302563743,
          "to_latitude": 21.0156709333409,
          "to_longitude": 105.83943417131393,
          "from_longitude": 105.8394278838064
        }
      },
      {
        "id": 7770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943137826901,
            21.015674273695144,
            105.83943489933938,
            21.015675889350288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943489933938,
              21.015675889350288
            ],
            [
              105.8394345833731,
              21.015675808715955
            ],
            [
              105.83943168789116,
              21.01567442390903
            ],
            [
              105.83943137826901,
              21.015674273695144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89.9_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.7778",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015675889350288,
          "to_latitude": 21.015674273695144,
          "to_longitude": 105.83943137826901,
          "from_longitude": 105.83943489933938
        }
      },
      {
        "id": 7771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932678318915,
            21.015280332996213,
            105.83932695762081,
            21.015282166497848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932695762081,
              21.015280332996213
            ],
            [
              105.83932692222065,
              21.01528053910588
            ],
            [
              105.83932678318915,
              21.015282166497848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44.33_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7779",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015280332996213,
          "to_latitude": 21.015282166497848,
          "to_longitude": 105.83932678318915,
          "from_longitude": 105.83932695762081
        }
      },
      {
        "id": 7772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818285781295,
            21.015909679425626,
            105.83818480477031,
            21.01591243279533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83818285781295,
              21.015909679425626
            ],
            [
              105.83818463510333,
              21.015912195180128
            ],
            [
              105.83818480477031,
              21.01591243279533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.22_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7780",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015909679425626,
          "to_latitude": 21.01591243279533,
          "to_longitude": 105.83818480477031,
          "from_longitude": 105.83818285781295
        }
      },
      {
        "id": 7773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818196048442,
            21.01591452783653,
            105.83819115203134,
            21.015926446655495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819115203134,
              21.015926446655495
            ],
            [
              105.83818376081322,
              21.015916862832754
            ],
            [
              105.83818216785268,
              21.015914798685273
            ],
            [
              105.83818196048442,
              21.01591452783653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.23_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7781",
          "diaChiLapD": "Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015926446655495,
          "to_latitude": 21.01591452783653,
          "to_longitude": 105.83818196048442,
          "from_longitude": 105.83819115203134
        }
      },
      {
        "id": 7774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902351525826,
            21.01681401157577,
            105.83903075543746,
            21.016822715460048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902351525826,
              21.01681401157577
            ],
            [
              105.83902948869613,
              21.016821192549862
            ],
            [
              105.83903054722576,
              21.01682246629398
            ],
            [
              105.83903075543746,
              21.016822715460048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.12_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.7782",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01681401157577,
          "to_latitude": 21.016822715460048,
          "to_longitude": 105.83903075543746,
          "from_longitude": 105.83902351525826
        }
      },
      {
        "id": 7775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83923651958327,
            21.016813251762326,
            105.83924722046206,
            21.016824712763956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923651958327,
              21.016824712763956
            ],
            [
              105.83924362862251,
              21.016818229579947
            ],
            [
              105.83924722046206,
              21.016814953029634
            ],
            [
              105.83924530725663,
              21.016813251762326
            ],
            [
              105.83924157434214,
              21.016816243589872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.8_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DODV.7783",
          "diaChiLapD": "Ngõ Vạn Ứng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016824712763956,
          "to_latitude": 21.016816243589872,
          "to_longitude": 105.83924157434214,
          "from_longitude": 105.83923651958327
        }
      },
      {
        "id": 7776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83937210896502,
            21.016930458081436,
            105.83940532041949,
            21.01696152310607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83937210896502,
              21.01693327517253
            ],
            [
              105.83937630651464,
              21.016930458081436
            ],
            [
              105.83940532041949,
              21.01696152310607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.6_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.7784",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01693327517253,
          "to_latitude": 21.01696152310607,
          "to_longitude": 105.83940532041949,
          "from_longitude": 105.83937210896502
        }
      },
      {
        "id": 7777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919165402976,
            21.017160400705315,
            105.83920111620044,
            21.0171702711011
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919852096581,
              21.01716444275627
            ],
            [
              105.83919323650683,
              21.017160400705315
            ],
            [
              105.83919165402976,
              21.017162793925248
            ],
            [
              105.83919656821229,
              21.01716667702903
            ],
            [
              105.83920111620044,
              21.0171702711011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.4_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.7785",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01716444275627,
          "to_latitude": 21.0171702711011,
          "to_longitude": 105.83920111620044,
          "from_longitude": 105.83919852096581
        }
      },
      {
        "id": 7778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83901748367744,
            21.017364025003424,
            105.83902976364,
            21.01737213867985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390221845453,
              21.017364728059675
            ],
            [
              105.83901822130825,
              21.017368680242125
            ],
            [
              105.83901748367744,
              21.017369572652182
            ],
            [
              105.8390206031971,
              21.01737213867985
            ],
            [
              105.83902534327372,
              21.017367940602014
            ],
            [
              105.83902976364,
              21.017364025003424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57.3_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DODV.7786",
          "diaChiLapD": "Ngõ Chùa Mỹ Giang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017364728059675,
          "to_latitude": 21.017364025003424,
          "to_longitude": 105.83902976364,
          "from_longitude": 105.8390221845453
        }
      },
      {
        "id": 7779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010088552529,
            21.01853108275517,
            105.84011766084609,
            21.018536906930272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84011022512384,
              21.018536906930272
            ],
            [
              105.84011482246068,
              21.01853659158126
            ],
            [
              105.84011766084609,
              21.01853653059577
            ],
            [
              105.84011757934464,
              21.01853108275517
            ],
            [
              105.84010988937587,
              21.018531235442694
            ],
            [
              105.84010088552529,
              21.018531414489505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.9_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7787",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018536906930272,
          "to_latitude": 21.018531414489505,
          "to_longitude": 105.84010088552529,
          "from_longitude": 105.84011022512384
        }
      },
      {
        "id": 7780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023992070944,
            21.017445913030514,
            105.8402402092398,
            21.017459160759692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84023995134349,
              21.017445913030514
            ],
            [
              105.8402402092398,
              21.017455888762584
            ],
            [
              105.84023992070944,
              21.017459160759692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.1_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7788",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017445913030514,
          "to_latitude": 21.017459160759692,
          "to_longitude": 105.84023992070944,
          "from_longitude": 105.84023995134349
        }
      },
      {
        "id": 7781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84024328089076,
            21.017449046691645,
            105.84025045757673,
            21.017469710998903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84024328089076,
              21.017459178485538
            ],
            [
              105.84024361773318,
              21.017454742898057
            ],
            [
              105.84024353195196,
              21.017449046691645
            ],
            [
              105.84025045757673,
              21.01744949119273
            ],
            [
              105.84025016959963,
              21.017459214911984
            ],
            [
              105.84024985901513,
              21.017469710998903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69.2_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7789",
          "diaChiLapD": "Ngách 1/33, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017459178485538,
          "to_latitude": 21.017469710998903,
          "to_longitude": 105.84024985901513,
          "from_longitude": 105.84024328089076
        }
      },
      {
        "id": 7782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84000206234667,
            21.017343647984006,
            105.84002327725707,
            21.01734906397037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84000206234667,
              21.017343647984006
            ],
            [
              105.84001346207958,
              21.01734393853207
            ],
            [
              105.84002327725707,
              21.017344188129687
            ],
            [
              105.84002322857214,
              21.01734906397037
            ],
            [
              105.84002169019466,
              21.01734885208342
            ],
            [
              105.8400135349823,
              21.01734853919243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68.5_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7790",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017343647984006,
          "to_latitude": 21.01734853919243,
          "to_longitude": 105.8400135349823,
          "from_longitude": 105.84000206234667
        }
      },
      {
        "id": 7783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84093567396236,
            21.01674371191798,
            105.840936029549,
            21.016751887944654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84093567396236,
              21.01674371191798
            ],
            [
              105.84093574371884,
              21.016745344601006
            ],
            [
              105.84093598868645,
              21.016750947891122
            ],
            [
              105.840936029549,
              21.016751887944654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.3_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7791",
          "diaChiLapD": "Ngõ 222, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01674371191798,
          "to_latitude": 21.016751887944654,
          "to_longitude": 105.840936029549,
          "from_longitude": 105.84093567396236
        }
      },
      {
        "id": 7784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83977605849984,
            21.01661759565442,
            105.83977607215525,
            21.01662134134255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83977606253825,
              21.01661759565442
            ],
            [
              105.83977607215525,
              21.016617938831914
            ],
            [
              105.83977605849984,
              21.01662134134255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72A.3_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7792",
          "diaChiLapD": "Ngách 1/32, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01661759565442,
          "to_latitude": 21.01662134134255,
          "to_longitude": 105.83977605849984,
          "from_longitude": 105.83977606253825
        }
      },
      {
        "id": 7785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012777314629,
            21.016061786584327,
            105.84013464412502,
            21.01606364942293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012777314629,
              21.016061786584327
            ],
            [
              105.84013122837472,
              21.01606280732532
            ],
            [
              105.84013464412502,
              21.01606364942293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.8_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7793",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016061786584327,
          "to_latitude": 21.01606364942293,
          "to_longitude": 105.84013464412502,
          "from_longitude": 105.84012777314629
        }
      },
      {
        "id": 7786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84011843991661,
            21.016064256809504,
            105.84013176515508,
            21.016069261366102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84011843991661,
              21.016065990212272
            ],
            [
              105.8401251480187,
              21.01606779785571
            ],
            [
              105.84013057673907,
              21.016069261366102
            ],
            [
              105.84013176515508,
              21.01606573021572
            ],
            [
              105.840130058326,
              21.016065152452445
            ],
            [
              105.84012669422042,
              21.016064256809504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72.7_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7794",
          "diaChiLapD": "Ngõ 1, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016065990212272,
          "to_latitude": 21.016064256809504,
          "to_longitude": 105.84012669422042,
          "from_longitude": 105.84011843991661
        }
      },
      {
        "id": 7787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023349132205,
            21.01708917890525,
            105.84023399636091,
            21.017095191879047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84023349132205,
              21.01708917890525
            ],
            [
              105.84023359727365,
              21.017089546897115
            ],
            [
              105.84023399636091,
              21.017095191879047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70.2_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7795",
          "diaChiLapD": "Ngách 1/49, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01708917890525,
          "to_latitude": 21.017095191879047,
          "to_longitude": 105.84023399636091,
          "from_longitude": 105.84023349132205
        }
      },
      {
        "id": 7788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862573303917,
            21.018345920057275,
            105.83864016662966,
            21.01834995585854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83862573303917,
              21.018345920057275
            ],
            [
              105.83862604811996,
              21.018346357470637
            ],
            [
              105.83864016662966,
              21.01834995585854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT52.5A_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DODV.7796",
          "diaChiLapD": "Ngõ Hồ Dài",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018345920057275,
          "to_latitude": 21.01834995585854,
          "to_longitude": 105.83864016662966,
          "from_longitude": 105.83862573303917
        }
      },
      {
        "id": 7789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376500483547,
            21.018239968329027,
            105.83765678593933,
            21.018254526049375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376500483547,
              21.018248546463873
            ],
            [
              105.83765275020978,
              21.018254526049375
            ],
            [
              105.83765678593933,
              21.01825286411599
            ],
            [
              105.83765402352472,
              21.018247064570296
            ],
            [
              105.83765064302783,
              21.018239968329027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56.8_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DODV.7797",
          "diaChiLapD": "Ngõ Khâm Đức",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018248546463873,
          "to_latitude": 21.018239968329027,
          "to_longitude": 105.83765064302783,
          "from_longitude": 105.8376500483547
        }
      },
      {
        "id": 7790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765957296669,
            21.018429282998092,
            105.83766912813127,
            21.018429454942286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83765957296669,
              21.018429441478247
            ],
            [
              105.83766350935932,
              21.018429282998092
            ],
            [
              105.83766912813127,
              21.018429454942286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64.4_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DODV.7798",
          "diaChiLapD": "Ngõ Tô Tiền",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018429441478247,
          "to_latitude": 21.018429454942286,
          "to_longitude": 105.83766912813127,
          "from_longitude": 105.83765957296669
        }
      },
      {
        "id": 7791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83791987014305,
            21.018944597536308,
            105.83792146395145,
            21.01897142807337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791987014305,
              21.018944597536308
            ],
            [
              105.83792004349087,
              21.01894583771174
            ],
            [
              105.83792146395145,
              21.01897142807337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51.3_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7799",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018944597536308,
          "to_latitude": 21.01897142807337,
          "to_longitude": 105.83792146395145,
          "from_longitude": 105.83791987014305
        }
      },
      {
        "id": 7792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824956896376,
            21.017557082711434,
            105.83825779620753,
            21.017570239527252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824956896376,
              21.017564217580926
            ],
            [
              105.83825215213002,
              21.017569440840386
            ],
            [
              105.83825265392127,
              21.017570239527252
            ],
            [
              105.83825779620753,
              21.017568122322178
            ],
            [
              105.83825483742757,
              21.017562473688816
            ],
            [
              105.83825201379882,
              21.017557082711434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.1_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7800",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017564217580926,
          "to_latitude": 21.017557082711434,
          "to_longitude": 105.83825201379882,
          "from_longitude": 105.83824956896376
        }
      },
      {
        "id": 7793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824721942162,
            21.017564995092066,
            105.83825072871133,
            21.017572069912866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824721942162,
              21.017564995092066
            ],
            [
              105.8382498775746,
              21.01757038236878
            ],
            [
              105.83825072871133,
              21.017572069912866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59.2_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7801",
          "diaChiLapD": "Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017564995092066,
          "to_latitude": 21.017572069912866,
          "to_longitude": 105.83825072871133,
          "from_longitude": 105.83824721942162
        }
      },
      {
        "id": 7794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83812317834762,
            21.017154486789003,
            105.83812759682965,
            21.017161419843795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812317834762,
              21.017154486789003
            ],
            [
              105.83812747207878,
              21.017161129620327
            ],
            [
              105.83812759682965,
              21.017161419843795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.5_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7802",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017154486789003,
          "to_latitude": 21.017161419843795,
          "to_longitude": 105.83812759682965,
          "from_longitude": 105.83812317834762
        }
      },
      {
        "id": 7795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83812471824078,
            21.017162889119344,
            105.83812769481284,
            21.01716707625181
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812471824078,
              21.017162889119344
            ],
            [
              105.83812505043522,
              21.017163291223767
            ],
            [
              105.83812769481284,
              21.01716707625181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60A.4_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7803",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017162889119344,
          "to_latitude": 21.01716707625181,
          "to_longitude": 105.83812769481284,
          "from_longitude": 105.83812471824078
        }
      },
      {
        "id": 7796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83757502382265,
            21.01741170391435,
            105.8375826794712,
            21.017427938601028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375826794712,
              21.017420822440048
            ],
            [
              105.83758021148346,
              21.017414343121324
            ],
            [
              105.83757929155199,
              21.01741170391435
            ],
            [
              105.83757502382265,
              21.017413159246196
            ],
            [
              105.83757838409666,
              21.01742215055354
            ],
            [
              105.83758054713444,
              21.017427938601028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60.4_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7804",
          "diaChiLapD": "Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017420822440048,
          "to_latitude": 21.017427938601028,
          "to_longitude": 105.83758054713444,
          "from_longitude": 105.8375826794712
        }
      },
      {
        "id": 7797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83740080128943,
            21.01580766016655,
            105.83740286659561,
            21.01581024947959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83740080128943,
              21.01580766016655
            ],
            [
              105.83740096784237,
              21.015807857152815
            ],
            [
              105.83740286659561,
              21.01581024947959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.1_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7805",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01580766016655,
          "to_latitude": 21.01581024947959,
          "to_longitude": 105.83740286659561,
          "from_longitude": 105.83740080128943
        }
      },
      {
        "id": 7798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83739160691997,
            21.015803666204675,
            105.83739723682275,
            21.015810157061605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83739723682275,
              21.015810157061605
            ],
            [
              105.83739711281066,
              21.01580999870458
            ],
            [
              105.83739160691997,
              21.015803666204675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.2_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7806",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015810157061605,
          "to_latitude": 21.015803666204675,
          "to_longitude": 105.83739160691997,
          "from_longitude": 105.83739723682275
        }
      },
      {
        "id": 7799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83678634022692,
            21.016141429104707,
            105.83679587563338,
            21.016151674226506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678845541155,
              21.016145499251742
            ],
            [
              105.83678634022692,
              21.016142762047743
            ],
            [
              105.83678831630978,
              21.016141429104707
            ],
            [
              105.83679039054033,
              21.01614423967127
            ],
            [
              105.83679587563338,
              21.016151674226506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.6_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7807",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016145499251742,
          "to_latitude": 21.016151674226506,
          "to_longitude": 105.83679587563338,
          "from_longitude": 105.83678845541155
        }
      },
      {
        "id": 7800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367904393602,
            21.016138851699097,
            105.83679312885494,
            21.016142456786284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367904393602,
              21.016138851699097
            ],
            [
              105.83679131597803,
              21.016139765966262
            ],
            [
              105.83679312885494,
              21.016142456786284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.7_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7808",
          "diaChiLapD": "Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016138851699097,
          "to_latitude": 21.016142456786284,
          "to_longitude": 105.83679312885494,
          "from_longitude": 105.8367904393602
        }
      },
      {
        "id": 7801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665238517429,
            21.016546989165743,
            105.83666825836856,
            21.016554888247576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665238517429,
              21.016554888247576
            ],
            [
              105.83665307992327,
              21.0165544115414
            ],
            [
              105.83666825836856,
              21.016546989165743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39.11_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7809",
          "diaChiLapD": "Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016554888247576,
          "to_latitude": 21.016546989165743,
          "to_longitude": 105.83666825836856,
          "from_longitude": 105.83665238517429
        }
      },
      {
        "id": 7802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605402418799,
            21.015634255688052,
            105.83606547752967,
            21.015641582025584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605402418799,
              21.015641582025584
            ],
            [
              105.83605690741393,
              21.015639737924182
            ],
            [
              105.83605984719112,
              21.015637857415868
            ],
            [
              105.83606547752967,
              21.015634255688052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37.12_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7810",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015641582025584,
          "to_latitude": 21.015634255688052,
          "to_longitude": 105.83606547752967,
          "from_longitude": 105.83605402418799
        }
      },
      {
        "id": 7803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359575624783,
            21.01596594956893,
            105.83597333661963,
            21.015971866757898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359575624783,
              21.01596594956893
            ],
            [
              105.83597156459737,
              21.015971325401715
            ],
            [
              105.83597333661963,
              21.015971866757898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.19_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7811",
          "diaChiLapD": "Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01596594956893,
          "to_latitude": 21.015971866757898,
          "to_longitude": 105.83597333661963,
          "from_longitude": 105.8359575624783
        }
      },
      {
        "id": 7804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83567731311352,
            21.015530190293237,
            105.83568152278922,
            21.015532908902546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567731311352,
              21.015532908902546
            ],
            [
              105.83567751056236,
              21.01553278148126
            ],
            [
              105.83568037164714,
              21.01553093388202
            ],
            [
              105.83568152278922,
              21.015530190293237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.15_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7812",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015532908902546,
          "to_latitude": 21.015530190293237,
          "to_longitude": 105.83568152278922,
          "from_longitude": 105.83567731311352
        }
      },
      {
        "id": 7805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356650541023,
            21.015531218259493,
            105.835676170534,
            21.01553807052993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835676170534,
              21.015531218259493
            ],
            [
              105.83567598163664,
              21.015531326671052
            ],
            [
              105.8356650541023,
              21.01553807052993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.14_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7813",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015531218259493,
          "to_latitude": 21.01553807052993,
          "to_longitude": 105.8356650541023,
          "from_longitude": 105.835676170534
        }
      },
      {
        "id": 7806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360212260037,
            21.015003178025268,
            105.83602285726964,
            21.015005563554013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360212260037,
              21.015003178025268
            ],
            [
              105.8360214532996,
              21.01500357342386
            ],
            [
              105.83602285726964,
              21.015005563554013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.8_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7814",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015003178025268,
          "to_latitude": 21.015005563554013,
          "to_longitude": 105.83602285726964,
          "from_longitude": 105.8360212260037
        }
      },
      {
        "id": 7807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83655658235101,
            21.014531550857136,
            105.83656083770643,
            21.01453910076008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83656083770643,
              21.01453910076008
            ],
            [
              105.83656026222567,
              21.0145381940428
            ],
            [
              105.83655658235101,
              21.014531550857136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.2_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7815",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01453910076008,
          "to_latitude": 21.014531550857136,
          "to_longitude": 105.83655658235101,
          "from_longitude": 105.83656083770643
        }
      },
      {
        "id": 7808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83653661363572,
            21.01455414203811,
            105.83653884405419,
            21.014556503841778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83653661363572,
              21.01455414203811
            ],
            [
              105.83653713351202,
              21.014554737416464
            ],
            [
              105.83653884405419,
              21.014556503841778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33.1_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7816",
          "diaChiLapD": "Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01455414203811,
          "to_latitude": 21.014556503841778,
          "to_longitude": 105.83653884405419,
          "from_longitude": 105.83653661363572
        }
      },
      {
        "id": 7809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365889644574,
            21.01484488995935,
            105.8365958436378,
            21.014851862443685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365889644574,
              21.01484488995935
            ],
            [
              105.83658928363398,
              21.014845201808733
            ],
            [
              105.8365958436378,
              21.014851862443685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34.2_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7817",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01484488995935,
          "to_latitude": 21.014851862443685,
          "to_longitude": 105.8365958436378,
          "from_longitude": 105.8365889644574
        }
      },
      {
        "id": 7810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361534811661,
            21.015192487907346,
            105.83615868094786,
            21.01519689718325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361534811661,
              21.015192487907346
            ],
            [
              105.83615397565639,
              21.0151928431539
            ],
            [
              105.83615868094786,
              21.01519689718325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.3_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7818",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015192487907346,
          "to_latitude": 21.01519689718325,
          "to_longitude": 105.83615868094786,
          "from_longitude": 105.8361534811661
        }
      },
      {
        "id": 7811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632602713213,
            21.01551359983458,
            105.83633900954976,
            21.01552375447612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83632602713213,
              21.01552375447612
            ],
            [
              105.83633758427212,
              21.015514518194415
            ],
            [
              105.83633900954976,
              21.01551359983458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36.4_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7819",
          "diaChiLapD": "Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01552375447612,
          "to_latitude": 21.01551359983458,
          "to_longitude": 105.83633900954976,
          "from_longitude": 105.83632602713213
        }
      },
      {
        "id": 7812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592780170603,
            21.01462532577969,
            105.83594111251496,
            21.014631116613682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593838589098,
              21.014628885129362
            ],
            [
              105.83594111251496,
              21.014627251347754
            ],
            [
              105.83593998786921,
              21.01462532577969
            ],
            [
              105.83593696195281,
              21.01462676322565
            ],
            [
              105.83592780170603,
              21.014631116613682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32.7_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7820",
          "diaChiLapD": "Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014628885129362,
          "to_latitude": 21.014631116613682,
          "to_longitude": 105.83592780170603,
          "from_longitude": 105.83593838589098
        }
      },
      {
        "id": 7813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83471241970385,
            21.014479675443464,
            105.83472011187608,
            21.014490460652947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83471241970385,
              21.014490460652947
            ],
            [
              105.83471296338797,
              21.014489804050893
            ],
            [
              105.83472011187608,
              21.014479675443464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.1_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7821",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014490460652947,
          "to_latitude": 21.014479675443464,
          "to_longitude": 105.83472011187608,
          "from_longitude": 105.83471241970385
        }
      },
      {
        "id": 7814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83495716197832,
            21.014881212098018,
            105.8349689721581,
            21.014895199565046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496751092649,
              21.014888287001767
            ],
            [
              105.83496205124729,
              21.01489351546666
            ],
            [
              105.8349602954767,
              21.014895199565046
            ],
            [
              105.83495716197832,
              21.014892347205116
            ],
            [
              105.83496454740886,
              21.01488538413041
            ],
            [
              105.8349689721581,
              21.014881212098018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.4_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7822",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014888287001767,
          "to_latitude": 21.014881212098018,
          "to_longitude": 105.8349689721581,
          "from_longitude": 105.83496751092649
        }
      },
      {
        "id": 7815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83564460432724,
            21.015016477046693,
            105.83564949302672,
            21.015022664662425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83564949302672,
              21.015016477046693
            ],
            [
              105.8356455812777,
              21.015021389027105
            ],
            [
              105.83564460432724,
              21.015022664662425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35.8_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7823",
          "diaChiLapD": "Ngõ 126, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015016477046693,
          "to_latitude": 21.015022664662425,
          "to_longitude": 105.83564460432724,
          "from_longitude": 105.83564949302672
        }
      },
      {
        "id": 7816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8341587220188,
            21.01485153791748,
            105.8341713688929,
            21.014864575378272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8341587220188,
              21.01485153791748
            ],
            [
              105.8341627242226,
              21.014855445515963
            ],
            [
              105.8341713688929,
              21.014864575378272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.21_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7824",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01485153791748,
          "to_latitude": 21.014864575378272,
          "to_longitude": 105.8341713688929,
          "from_longitude": 105.8341587220188
        }
      },
      {
        "id": 7817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83324414526395,
            21.01578144569756,
            105.83325818131797,
            21.015793815260484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83324414526395,
              21.01578144569756
            ],
            [
              105.83324793395182,
              21.015784839524738
            ],
            [
              105.83325818131797,
              21.015793815260484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.17_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7825",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01578144569756,
          "to_latitude": 21.015793815260484,
          "to_longitude": 105.83325818131797,
          "from_longitude": 105.83324414526395
        }
      },
      {
        "id": 7818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341872447143,
            21.016037320456057,
            105.83342393388392,
            21.016041047834737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83341872447143,
              21.016037320456057
            ],
            [
              105.83342118744268,
              21.016039124775308
            ],
            [
              105.83342393388392,
              21.016041047834737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28.1_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7826",
          "diaChiLapD": "Ngách 216/8, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016037320456057,
          "to_latitude": 21.016041047834737,
          "to_longitude": 105.83342393388392,
          "from_longitude": 105.83341872447143
        }
      },
      {
        "id": 7819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83215473190236,
            21.016880452304942,
            105.83216619762338,
            21.016889348080976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83215473190236,
              21.016880452304942
            ],
            [
              105.83215558874758,
              21.01688110114522
            ],
            [
              105.83216176608774,
              21.016885906794208
            ],
            [
              105.83216619762338,
              21.016889348080976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.14_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7827",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016880452304942,
          "to_latitude": 21.016889348080976,
          "to_longitude": 105.83216619762338,
          "from_longitude": 105.83215473190236
        }
      },
      {
        "id": 7820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318537637624,
            21.017728536710553,
            105.83193639317746,
            21.01789583118759
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318537637624,
              21.01789583118759
            ],
            [
              105.83185883423418,
              21.017891499817118
            ],
            [
              105.83185956406047,
              21.01789057947506
            ],
            [
              105.831881648906,
              21.017862722605788
            ],
            [
              105.83192283488184,
              21.017813822926886
            ],
            [
              105.83193639317746,
              21.017784615879208
            ],
            [
              105.83187649888464,
              21.017728536710553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT25.1_Ngõ 298, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7828",
          "diaChiLapD": "Ngõ 298, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01789583118759,
          "to_latitude": 21.017728536710553,
          "to_longitude": 105.83187649888464,
          "from_longitude": 105.8318537637624
        }
      },
      {
        "id": 7821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83135930908145,
            21.018264619021576,
            105.83136317239624,
            21.01826772331832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83136317239624,
              21.018264619021576
            ],
            [
              105.83136300671363,
              21.018264751702116
            ],
            [
              105.83135930908145,
              21.01826772331832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.2_Ngõ 316, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7829",
          "diaChiLapD": "Ngõ 316, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018264619021576,
          "to_latitude": 21.01826772331832,
          "to_longitude": 105.83135930908145,
          "from_longitude": 105.83136317239624
        }
      },
      {
        "id": 7822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83140836066963,
            21.018694690869502,
            105.8314150880966,
            21.018706226492316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140836066963,
              21.018694690869502
            ],
            [
              105.83140881613191,
              21.01869529380405
            ],
            [
              105.8314150880966,
              21.018706226492316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3.3_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DODV.7830",
          "diaChiLapD": "Ngõ Chiến Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018694690869502,
          "to_latitude": 21.018706226492316,
          "to_longitude": 105.8314150880966,
          "from_longitude": 105.83140836066963
        }
      },
      {
        "id": 7823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8309187815916,
            21.018150075525245,
            105.83094269633507,
            21.018173271850298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8309187815916,
              21.018150075525245
            ],
            [
              105.83092095009488,
              21.018151858737877
            ],
            [
              105.83092414005095,
              21.018155005346895
            ],
            [
              105.83094269633507,
              21.018173271850298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.5_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7831",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018150075525245,
          "to_latitude": 21.018173271850298,
          "to_longitude": 105.83094269633507,
          "from_longitude": 105.8309187815916
        }
      },
      {
        "id": 7824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83049040684313,
            21.018517390941494,
            105.83050177771602,
            21.018531908962668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83050177771602,
              21.018531908962668
            ],
            [
              105.83049494085544,
              21.01852318015556
            ],
            [
              105.83049183680991,
              21.01851921841022
            ],
            [
              105.83049040684313,
              21.018517390941494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2.3_Đê La Thành",
          "maTaiSan": "04.O16A.DODV.7832",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018531908962668,
          "to_latitude": 21.018517390941494,
          "to_longitude": 105.83049040684313,
          "from_longitude": 105.83050177771602
        }
      },
      {
        "id": 7825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83196121513264,
            21.019112330329108,
            105.83197604365395,
            21.019115640010916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197604365395,
              21.019112330329108
            ],
            [
              105.83197587546593,
              21.01911235824844
            ],
            [
              105.83196121513264,
              21.019115640010916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4.1_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7833",
          "diaChiLapD": "Ngõ 241, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019112330329108,
          "to_latitude": 21.019115640010916,
          "to_longitude": 105.83196121513264,
          "from_longitude": 105.83197604365395
        }
      },
      {
        "id": 7826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83204825492318,
            21.018534933075486,
            105.83207552464553,
            21.018576733612996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83207314272777,
              21.018576733612996
            ],
            [
              105.83207552464553,
              21.018575887382443
            ],
            [
              105.8320740514471,
              21.018569392273793
            ],
            [
              105.83204825492318,
              21.018534933075486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.5_Ngách 241/18, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7834",
          "diaChiLapD": "Ngách 241/18, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018576733612996,
          "to_latitude": 21.018534933075486,
          "to_longitude": 105.83204825492318,
          "from_longitude": 105.83207314272777
        }
      },
      {
        "id": 7827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83215324487988,
            21.019310936201155,
            105.83215711945279,
            21.01934044803751
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83215324487988,
              21.019310936201155
            ],
            [
              105.83215711945279,
              21.01932927528698
            ],
            [
              105.83215677838852,
              21.01934044803751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.5_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7835",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019310936201155,
          "to_latitude": 21.01934044803751,
          "to_longitude": 105.83215677838852,
          "from_longitude": 105.83215324487988
        }
      },
      {
        "id": 7828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83251895177982,
            21.01882456858642,
            105.83252243201615,
            21.018831304061028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83251895177982,
              21.01882456858642
            ],
            [
              105.83251935893689,
              21.01882565317297
            ],
            [
              105.83252243201615,
              21.018831304061028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.5_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7836",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01882456858642,
          "to_latitude": 21.018831304061028,
          "to_longitude": 105.83252243201615,
          "from_longitude": 105.83251895177982
        }
      },
      {
        "id": 7829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83319225217033,
            21.018732786571732,
            105.83320004753774,
            21.018751746372043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83319225217033,
              21.018751746372043
            ],
            [
              105.83319289588786,
              21.018742599877527
            ],
            [
              105.83319358688455,
              21.018732786571732
            ],
            [
              105.83320004753774,
              21.018733506410012
            ],
            [
              105.83319962649632,
              21.01874308265197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.2_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7837",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018751746372043,
          "to_latitude": 21.01874308265197,
          "to_longitude": 105.83319962649632,
          "from_longitude": 105.83319225217033
        }
      },
      {
        "id": 7830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341990695926,
            21.018704489039315,
            105.83342187882155,
            21.018713809687178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83342187882155,
              21.018713809687178
            ],
            [
              105.83342133493932,
              21.01871079919504
            ],
            [
              105.83341990695926,
              21.018704489039315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT5.1_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7838",
          "diaChiLapD": "Ngách 24, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018713809687178,
          "to_latitude": 21.018704489039315,
          "to_longitude": 105.83341990695926,
          "from_longitude": 105.83342187882155
        }
      },
      {
        "id": 7831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390115959486,
            21.018562147919283,
            105.8339063387562,
            21.018570969613155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390115959486,
              21.018562147919283
            ],
            [
              105.83390352226927,
              21.01856666600744
            ],
            [
              105.8339063387562,
              21.018570969613155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9.3_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7839",
          "diaChiLapD": "Ngách 15, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018562147919283,
          "to_latitude": 21.018570969613155,
          "to_longitude": 105.8339063387562,
          "from_longitude": 105.83390115959486
        }
      },
      {
        "id": 7832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337160580749,
            21.018919529038843,
            105.8337224968742,
            21.01892083865882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337160580749,
              21.01892083865882
            ],
            [
              105.83371937786838,
              21.018920160324605
            ],
            [
              105.8337224968742,
              21.018919529038843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7840",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01892083865882,
          "to_latitude": 21.018919529038843,
          "to_longitude": 105.8337224968742,
          "from_longitude": 105.8337160580749
        }
      },
      {
        "id": 7833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83345512329143,
            21.01923537091505,
            105.83345686111234,
            21.01925450443505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345686111234,
              21.01925450443505
            ],
            [
              105.83345676787405,
              21.019253478828976
            ],
            [
              105.83345629764912,
              21.01924831739903
            ],
            [
              105.83345583029997,
              21.01924315414852
            ],
            [
              105.83345512329143,
              21.01923537091505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1.7_Ngõ 219B, Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7841",
          "diaChiLapD": "Ngõ 219B, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01925450443505,
          "to_latitude": 21.01923537091505,
          "to_longitude": 105.83345512329143,
          "from_longitude": 105.83345686111234
        }
      },
      {
        "id": 7834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340784269077,
            21.01918914890928,
            105.83408066673876,
            21.019211184728587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8340784269077,
              21.01918914890928
            ],
            [
              105.83407968805992,
              21.019198492903403
            ],
            [
              105.83408066673876,
              21.019211184728587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.8_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7842",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01918914890928,
          "to_latitude": 21.019211184728587,
          "to_longitude": 105.83408066673876,
          "from_longitude": 105.8340784269077
        }
      },
      {
        "id": 7835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83510841288994,
            21.01914140456864,
            105.83511006310647,
            21.019148396454124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83511006310647,
              21.019148396454124
            ],
            [
              105.83510954984838,
              21.0191462276231
            ],
            [
              105.83510862174363,
              21.01914228779972
            ],
            [
              105.83510841288994,
              21.01914140456864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.9_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7843",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019148396454124,
          "to_latitude": 21.01914140456864,
          "to_longitude": 105.83510841288994,
          "from_longitude": 105.83511006310647
        }
      },
      {
        "id": 7836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537710403259,
            21.018333844155283,
            105.8355409191393,
            21.01840603372298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83537975821922,
              21.01840603372298
            ],
            [
              105.8353787686207,
              21.01840452477902
            ],
            [
              105.83537710403259,
              21.018401861216404
            ],
            [
              105.83547556289315,
              21.0183605835587
            ],
            [
              105.8355409191393,
              21.018333844155283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16.2_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.7844",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01840603372298,
          "to_latitude": 21.018333844155283,
          "to_longitude": 105.8355409191393,
          "from_longitude": 105.83537975821922
        }
      },
      {
        "id": 7837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553878202277,
            21.018338222644147,
            105.8356607495481,
            21.018355688982545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83553878202277,
              21.018338222644147
            ],
            [
              105.83553996679058,
              21.018341266109566
            ],
            [
              105.8355452609344,
              21.018355688982545
            ],
            [
              105.8356607495481,
              21.018350386085828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16.1_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.7845",
          "diaChiLapD": "Ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018338222644147,
          "to_latitude": 21.018350386085828,
          "to_longitude": 105.8356607495481,
          "from_longitude": 105.83553878202277
        }
      },
      {
        "id": 7838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83556875375908,
            21.018048301721635,
            105.83558759811164,
            21.018051115697695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83556875375908,
              21.018051115697695
            ],
            [
              105.83557049825525,
              21.01805085603267
            ],
            [
              105.83557488206566,
              21.01805020043659
            ],
            [
              105.83558759811164,
              21.018048301721635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.1_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.7846",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018051115697695,
          "to_latitude": 21.018048301721635,
          "to_longitude": 105.83558759811164,
          "from_longitude": 105.83556875375908
        }
      },
      {
        "id": 7839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521199757342,
            21.01786473685757,
            105.83524861224727,
            21.017881675955017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521199757342,
              21.017881675955017
            ],
            [
              105.83521537145643,
              21.017879488039075
            ],
            [
              105.83524861224727,
              21.01786473685757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18.2_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DODV.7847",
          "diaChiLapD": "Ngách 22/17, ngõ Toàn Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017881675955017,
          "to_latitude": 21.01786473685757,
          "to_longitude": 105.83524861224727,
          "from_longitude": 105.83521199757342
        }
      },
      {
        "id": 7840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630162647324,
            21.019061871514822,
            105.8363029112327,
            21.019071240168614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630162647324,
              21.019061871514822
            ],
            [
              105.83630290107932,
              21.01907079944526
            ],
            [
              105.8363029112327,
              21.019071240168614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.13_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.7848",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.45,
          "chatLieu": "HDPE",
          "chieuDai": 1.046,
          "from_latitude": 21.019061871514822,
          "to_latitude": 21.019071240168614,
          "to_longitude": 105.8363029112327,
          "from_longitude": 105.83630162647324
        }
      },
      {
        "id": 7841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362111384565,
            21.01880200347317,
            105.83622557975063,
            21.018804519491145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83622557975063,
              21.01880200347317
            ],
            [
              105.83621761094848,
              21.018803392063074
            ],
            [
              105.83621349200297,
              21.018804109599863
            ],
            [
              105.8362111384565,
              21.018804519491145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.7849",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01880200347317,
          "to_latitude": 21.018804519491145,
          "to_longitude": 105.8362111384565,
          "from_longitude": 105.83622557975063
        }
      },
      {
        "id": 7842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650626029903,
            21.0186027876092,
            105.8365087234589,
            21.018615649147524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650626029903,
              21.0186027876092
            ],
            [
              105.83650655262734,
              21.01860432074916
            ],
            [
              105.83650719265331,
              21.01860765591756
            ],
            [
              105.8365087234589,
              21.018615649147524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.7850",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0186027876092,
          "to_latitude": 21.018615649147524,
          "to_longitude": 105.8365087234589,
          "from_longitude": 105.83650626029903
        }
      },
      {
        "id": 7843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83681432854266,
            21.01870883554092,
            105.83681773814169,
            21.018721152290098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681773814169,
              21.018721152290098
            ],
            [
              105.83681511295919,
              21.018711669611577
            ],
            [
              105.83681456222425,
              21.01870968432503
            ],
            [
              105.83681432854266,
              21.01870883554092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.3_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.7851",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018721152290098,
          "to_latitude": 21.01870883554092,
          "to_longitude": 105.83681432854266,
          "from_longitude": 105.83681773814169
        }
      },
      {
        "id": 7844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8369332854244,
            21.01853757532648,
            105.83693770798668,
            21.018549206058783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369332854244,
              21.01853757532648
            ],
            [
              105.83693351224103,
              21.01853822814463
            ],
            [
              105.836934749821,
              21.01854176630626
            ],
            [
              105.83693476738266,
              21.018541810477828
            ],
            [
              105.83693770798668,
              21.018549206058783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.7852",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01853757532648,
          "to_latitude": 21.018549206058783,
          "to_longitude": 105.83693770798668,
          "from_longitude": 105.8369332854244
        }
      },
      {
        "id": 7845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605546722157,
            21.01830295199263,
            105.83607221386562,
            21.018307439322875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605546722157,
              21.018307439322875
            ],
            [
              105.83605805678081,
              21.01830674556486
            ],
            [
              105.83606343952573,
              21.018305302854476
            ],
            [
              105.83607221386562,
              21.01830295199263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT19.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.7853",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018307439322875,
          "to_latitude": 21.01830295199263,
          "to_longitude": 105.83607221386562,
          "from_longitude": 105.83605546722157
        }
      },
      {
        "id": 7846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83596059331364,
            21.017978680514947,
            105.83597545768431,
            21.0179806982027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83597545768431,
              21.017978680514947
            ],
            [
              105.83597056120732,
              21.017979344963162
            ],
            [
              105.83596209282437,
              21.01798049483426
            ],
            [
              105.83596059331364,
              21.0179806982027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.2_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.7854",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017978680514947,
          "to_latitude": 21.0179806982027,
          "to_longitude": 105.83596059331364,
          "from_longitude": 105.83597545768431
        }
      },
      {
        "id": 7847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594927319885,
            21.017259667294123,
            105.83595483905829,
            21.01726017877264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83594927319885,
              21.01726017877264
            ],
            [
              105.83595147011995,
              21.017259976493577
            ],
            [
              105.83595349590982,
              21.017259791313453
            ],
            [
              105.83595483905829,
              21.017259667294123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.7_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7855",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01726017877264,
          "to_latitude": 21.017259667294123,
          "to_longitude": 105.83595483905829,
          "from_longitude": 105.83594927319885
        }
      },
      {
        "id": 7848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591351381156,
            21.017056592272386,
            105.83591577190174,
            21.017057555366268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591351381156,
              21.017057555366268
            ],
            [
              105.83591444557995,
              21.017057146143394
            ],
            [
              105.83591577190174,
              21.017056592272386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.9_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7856",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017057555366268,
          "to_latitude": 21.017056592272386,
          "to_longitude": 105.83591577190174,
          "from_longitude": 105.83591351381156
        }
      },
      {
        "id": 7849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589799662013,
            21.017048955284,
            105.83591085516012,
            21.017064795986613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591085516012,
              21.017048955284
            ],
            [
              105.83591042845518,
              21.017049199444777
            ],
            [
              105.83589799662013,
              21.017064795986613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.8_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7857",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017048955284,
          "to_latitude": 21.017064795986613,
          "to_longitude": 105.83589799662013,
          "from_longitude": 105.83591085516012
        }
      },
      {
        "id": 7850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558699123189,
            21.016677096812444,
            105.83559373476731,
            21.0166832565052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83558699123189,
              21.0166832565052
            ],
            [
              105.83558743244146,
              21.01668285330653
            ],
            [
              105.83558889006423,
              21.016681522020285
            ],
            [
              105.835590347687,
              21.016680190734046
            ],
            [
              105.83559373476731,
              21.016677096812444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT20.12_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7858",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0166832565052,
          "to_latitude": 21.016677096812444,
          "to_longitude": 105.83559373476731,
          "from_longitude": 105.83558699123189
        }
      },
      {
        "id": 7851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353375416004,
            21.016780898565543,
            105.83543124049304,
            21.01682432074919
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353375416004,
              21.01681513475018
            ],
            [
              105.83533840920215,
              21.01681633087517
            ],
            [
              105.835345041095,
              21.01682432074919
            ],
            [
              105.83540936584313,
              21.01678935268213
            ],
            [
              105.83543124049304,
              21.016780898565543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.13_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7859",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01681513475018,
          "to_latitude": 21.016780898565543,
          "to_longitude": 105.83543124049304,
          "from_longitude": 105.8353375416004
        }
      },
      {
        "id": 7852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521021297547,
            21.01688182626364,
            105.83521893491448,
            21.016893570520562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521021297547,
              21.01688182626364
            ],
            [
              105.83521198163616,
              21.01688383538353
            ],
            [
              105.83521893491448,
              21.016893570520562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20.14_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7860",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01688182626364,
          "to_latitude": 21.016893570520562,
          "to_longitude": 105.83521893491448,
          "from_longitude": 105.83521021297547
        }
      },
      {
        "id": 7853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521741231887,
            21.01647383247278,
            105.83523127543658,
            21.016482745171473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521741231887,
              21.01647383247278
            ],
            [
              105.83521960011723,
              21.016475275923064
            ],
            [
              105.83523127543658,
              21.016482745171473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT24.1_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7861",
          "diaChiLapD": "Ngách 119, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01647383247278,
          "to_latitude": 21.016482745171473,
          "to_longitude": 105.83523127543658,
          "from_longitude": 105.83521741231887
        }
      },
      {
        "id": 7854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83621943389123,
            21.017140414837183,
            105.8364495394452,
            21.017499939168165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364495394452,
              21.017499939168165
            ],
            [
              105.83643381139875,
              21.01746454508529
            ],
            [
              105.83637010361612,
              21.017345195572915
            ],
            [
              105.83635690135922,
              21.01732595948454
            ],
            [
              105.83633518888443,
              21.017294325127647
            ],
            [
              105.83631718018088,
              21.017268086484865
            ],
            [
              105.83630518678473,
              21.017252070765586
            ],
            [
              105.83628352406436,
              21.017223144925765
            ],
            [
              105.83621943389123,
              21.017140414837183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.7862",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017499939168165,
          "to_latitude": 21.017140414837183,
          "to_longitude": 105.83621943389123,
          "from_longitude": 105.8364495394452
        }
      },
      {
        "id": 7855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630518678473,
            21.017235691244167,
            105.8363442293641,
            21.017252070765586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630518678473,
              21.017252070765586
            ],
            [
              105.83631049585465,
              21.017249843508804
            ],
            [
              105.83632078769655,
              21.01724552608247
            ],
            [
              105.83634420345271,
              21.017235702210247
            ],
            [
              105.8363442293641,
              21.017235691244167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30.5_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DODV.7863",
          "diaChiLapD": "Ngõ Cống Trắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017252070765586,
          "to_latitude": 21.017235691244167,
          "to_longitude": 105.8363442293641,
          "from_longitude": 105.83630518678473
        }
      },
      {
        "id": 7856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83394680203585,
            21.018142734106263,
            105.83395657414287,
            21.01815512269703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394680203585,
              21.018142734106263
            ],
            [
              105.83394745758203,
              21.01814333514969
            ],
            [
              105.83395657414287,
              21.01815512269703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT10.2_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7864",
          "diaChiLapD": "Ngách 25, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018142734106263,
          "to_latitude": 21.01815512269703,
          "to_longitude": 105.83395657414287,
          "from_longitude": 105.83394680203585
        }
      },
      {
        "id": 7857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83347075249239,
            21.01821451237956,
            105.83348571173059,
            21.018218684506504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83347075249239,
              21.018218684506504
            ],
            [
              105.8334715765711,
              21.018218454662755
            ],
            [
              105.83348139060064,
              21.018215717678792
            ],
            [
              105.83348571173059,
              21.01821451237956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.4_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7865",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018218684506504,
          "to_latitude": 21.01821451237956,
          "to_longitude": 105.83348571173059,
          "from_longitude": 105.83347075249239
        }
      },
      {
        "id": 7858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353528364371,
            21.017869552041216,
            105.83368845455252,
            21.017981522198397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353528364371,
              21.017966755263604
            ],
            [
              105.8335428598124,
              21.017977110642423
            ],
            [
              105.8335465041561,
              21.017981522198397
            ],
            [
              105.83362675126918,
              21.01792616414876
            ],
            [
              105.83365335991242,
              21.01790524242858
            ],
            [
              105.83368845455252,
              21.017869552041216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11.2_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7866",
          "diaChiLapD": "Ngách 35, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017966755263604,
          "to_latitude": 21.017869552041216,
          "to_longitude": 105.83368845455252,
          "from_longitude": 105.83353528364371
        }
      },
      {
        "id": 7859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83321114720229,
            21.01764794811261,
            105.83323209445416,
            21.017659579470607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83321114720229,
              21.017659579470607
            ],
            [
              105.83322245754796,
              21.01765329904914
            ],
            [
              105.83322972286774,
              21.01764926488826
            ],
            [
              105.83323209445416,
              21.01764794811261
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.6_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7867",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017659579470607,
          "to_latitude": 21.01764794811261,
          "to_longitude": 105.83323209445416,
          "from_longitude": 105.83321114720229
        }
      },
      {
        "id": 7860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376453580884,
            21.01685408033011,
            105.83377021920838,
            21.016859832209757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377021920838,
              21.016859832209757
            ],
            [
              105.83376817899936,
              21.01685776930848
            ],
            [
              105.83376453580884,
              21.01685408033011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.10_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7868",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016859832209757,
          "to_latitude": 21.01685408033011,
          "to_longitude": 105.83376453580884,
          "from_longitude": 105.83377021920838
        }
      },
      {
        "id": 7861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83386828622952,
            21.017101892758635,
            105.8338809756366,
            21.017112206557098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386828622952,
              21.017112206557098
            ],
            [
              105.83387216841345,
              21.01710905062898
            ],
            [
              105.83387959762035,
              21.01710301228596
            ],
            [
              105.8338809756366,
              21.017101892758635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.1_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7869",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017112206557098,
          "to_latitude": 21.017101892758635,
          "to_longitude": 105.8338809756366,
          "from_longitude": 105.83386828622952
        }
      },
      {
        "id": 7862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410304140368,
            21.016579774512024,
            105.83411875606217,
            21.016592812283694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83410304140368,
              21.016579774512024
            ],
            [
              105.8341034504693,
              21.016580163602626
            ],
            [
              105.83411875606217,
              21.016592812283694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.1_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7870",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016579774512024,
          "to_latitude": 21.016592812283694,
          "to_longitude": 105.83411875606217,
          "from_longitude": 105.83410304140368
        }
      },
      {
        "id": 7863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342102493596,
            21.01648627722971,
            105.83422735522517,
            21.016501779392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83422735522517,
              21.016501779392
            ],
            [
              105.83421388071696,
              21.016489567934627
            ],
            [
              105.83421135443574,
              21.016487279809507
            ],
            [
              105.8342102493596,
              21.01648627722971
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.3_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7871",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016501779392,
          "to_latitude": 21.01648627722971,
          "to_longitude": 105.8342102493596,
          "from_longitude": 105.83422735522517
        }
      },
      {
        "id": 7864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420319052863,
            21.01616161976953,
            105.83420555308041,
            21.01616439192301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420319052863,
              21.01616161976953
            ],
            [
              105.83420359070497,
              21.0161619673552
            ],
            [
              105.83420555308041,
              21.01616439192301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15.4_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7872",
          "diaChiLapD": "Ngách 114, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01616161976953,
          "to_latitude": 21.01616439192301,
          "to_longitude": 105.83420555308041,
          "from_longitude": 105.83420319052863
        }
      },
      {
        "id": 7865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465130974056,
            21.016131093109337,
            105.83467514834001,
            21.01615508592521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465130974056,
              21.016131093109337
            ],
            [
              105.83465676281875,
              21.01613642338055
            ],
            [
              105.83467514834001,
              21.01615508592521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.5_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.7873",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016131093109337,
          "to_latitude": 21.01615508592521,
          "to_longitude": 105.83467514834001,
          "from_longitude": 105.83465130974056
        }
      },
      {
        "id": 7866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80394761025286,
            21.016864690772653,
            105.8040289512684,
            21.01695882590534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80394761025286,
              21.01695882590534
            ],
            [
              105.80402438952736,
              21.016869682804856
            ],
            [
              105.8040289512684,
              21.016864690772653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Ngõ 726, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7874",
          "diaChiLapD": "Ngõ 726, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01695882590534,
          "to_latitude": 21.016864690772653,
          "to_longitude": 105.8040289512684,
          "from_longitude": 105.80394761025286
        }
      },
      {
        "id": 7867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80393156145192,
            21.016790019485722,
            105.8040289512684,
            21.016864690772653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80393156145192,
              21.016790019485722
            ],
            [
              105.80393524644168,
              21.016792846341925
            ],
            [
              105.8040289512684,
              21.016864690772653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Ngõ 726, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7875",
          "diaChiLapD": "Ngõ 726, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016790019485722,
          "to_latitude": 21.016864690772653,
          "to_longitude": 105.8040289512684,
          "from_longitude": 105.80393156145192
        }
      },
      {
        "id": 7868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8040289512684,
            21.01683721482372,
            105.80414029877575,
            21.016898920281616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8040289512684,
              21.016864690772653
            ],
            [
              105.8040821170587,
              21.016898920281616
            ],
            [
              105.80414029877575,
              21.01683721482372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Ngõ 726, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7876",
          "diaChiLapD": "Ngõ 726, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016864690772653,
          "to_latitude": 21.01683721482372,
          "to_longitude": 105.80414029877575,
          "from_longitude": 105.8040289512684
        }
      },
      {
        "id": 7869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8008645931455,
            21.02281531367514,
            105.80090982487154,
            21.022878590986277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8008645931455,
              21.022878590986277
            ],
            [
              105.80088111106359,
              21.022841955896716
            ],
            [
              105.80089312348571,
              21.02281531367514
            ],
            [
              105.80090982487154,
              21.02282275351231
            ],
            [
              105.8008972086774,
              21.022848381541674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7877",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022878590986277,
          "to_latitude": 21.022848381541674,
          "to_longitude": 105.8008972086774,
          "from_longitude": 105.8008645931455
        }
      },
      {
        "id": 7870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80164995286026,
            21.02311397428352,
            105.80166084907158,
            21.02314227052655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80166084907158,
              21.02311397428352
            ],
            [
              105.80165743902714,
              21.02312283026208
            ],
            [
              105.80165283061152,
              21.023134795385005
            ],
            [
              105.80164995286026,
              21.02314227052655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7878",
          "diaChiLapD": "Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02311397428352,
          "to_latitude": 21.02314227052655,
          "to_longitude": 105.80164995286026,
          "from_longitude": 105.80166084907158
        }
      },
      {
        "id": 7871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80493331701513,
            21.017689325648153,
            105.80493603135945,
            21.017694578125123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80493603135945,
              21.017689325648153
            ],
            [
              105.80493449847316,
              21.01769228104194
            ],
            [
              105.80493331701513,
              21.017694578125123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7879",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017689325648153,
          "to_latitude": 21.017694578125123,
          "to_longitude": 105.80493331701513,
          "from_longitude": 105.80493603135945
        }
      },
      {
        "id": 7872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80498597685104,
            21.019119817149484,
            105.80524906568081,
            21.01926820269461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80524906568081,
              21.01926820269461
            ],
            [
              105.80524781792424,
              21.019267469759814
            ],
            [
              105.80524587150623,
              21.019266327356608
            ],
            [
              105.80521711235183,
              21.019249441237502
            ],
            [
              105.8051307635056,
              21.019198738929376
            ],
            [
              105.80510941077802,
              21.019186913754698
            ],
            [
              105.80500464728382,
              21.019128894765412
            ],
            [
              105.80498597685104,
              21.019119817149484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7880",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01926820269461,
          "to_latitude": 21.019119817149484,
          "to_longitude": 105.80498597685104,
          "from_longitude": 105.80524906568081
        }
      },
      {
        "id": 7873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80524906568081,
            21.01926820269461,
            105.80570038889329,
            21.019523874285834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80524906568081,
              21.01926820269461
            ],
            [
              105.80525547851364,
              21.01927167596319
            ],
            [
              105.80526099989942,
              21.019274666611008
            ],
            [
              105.8053944439368,
              21.01934904170549
            ],
            [
              105.80541097203955,
              21.01935846180667
            ],
            [
              105.8054401381144,
              21.019375162609343
            ],
            [
              105.8054703251024,
              21.01939244928449
            ],
            [
              105.80549974497166,
              21.019409296101088
            ],
            [
              105.80552571231743,
              21.019424166738393
            ],
            [
              105.80554441273922,
              21.019434874479288
            ],
            [
              105.80557031736856,
              21.0194497083741
            ],
            [
              105.8055928456928,
              21.01946260818302
            ],
            [
              105.80561931120208,
              21.019476733991887
            ],
            [
              105.80564408567308,
              21.019491951680426
            ],
            [
              105.8056683402717,
              21.019505838661402
            ],
            [
              105.80570038889329,
              21.019523874285834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_135_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7881",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01926820269461,
          "to_latitude": 21.019523874285834,
          "to_longitude": 105.80570038889329,
          "from_longitude": 105.80524906568081
        }
      },
      {
        "id": 7874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80542345517746,
            21.018615323263926,
            105.80556903440338,
            21.018900946045683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80542345517746,
              21.018900946045683
            ],
            [
              105.80542912772867,
              21.018892679930914
            ],
            [
              105.80556903440338,
              21.018688836947987
            ],
            [
              105.80548774918807,
              21.018615323263926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_130_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7882",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018900946045683,
          "to_latitude": 21.018615323263926,
          "to_longitude": 105.80548774918807,
          "from_longitude": 105.80542345517746
        }
      },
      {
        "id": 7875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80302947732508,
            21.022280111164292,
            105.80303543156663,
            21.022281464993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80302947732508,
              21.022280111164292
            ],
            [
              105.80303185928243,
              21.022280845082385
            ],
            [
              105.80303543156663,
              21.022281464993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_50_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7883",
          "diaChiLapD": "Ngõ 157, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022280111164292,
          "to_latitude": 21.022281464993,
          "to_longitude": 105.80303543156663,
          "from_longitude": 105.80302947732508
        }
      },
      {
        "id": 7876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7994755345637,
            21.02654531881616,
            105.79948510596081,
            21.02657097195116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79948491993325,
              21.026555571862122
            ],
            [
              105.79948174158757,
              21.026547842515853
            ],
            [
              105.79948041305678,
              21.02654531881616
            ],
            [
              105.7994755345637,
              21.02654691878942
            ],
            [
              105.79947961235905,
              21.026557167530115
            ],
            [
              105.79948510596081,
              21.02657097195116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7884",
          "diaChiLapD": "Ngõ Chùa Nền, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026555571862122,
          "to_latitude": 21.02657097195116,
          "to_longitude": 105.79948510596081,
          "from_longitude": 105.79948491993325
        }
      },
      {
        "id": 7877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79914607693001,
            21.025570934266774,
            105.79916119330449,
            21.025577352422943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79915502874496,
              21.025577352422943
            ],
            [
              105.7991571673115,
              21.025576815790718
            ],
            [
              105.79916119330449,
              21.02557573196435
            ],
            [
              105.7991597930219,
              21.025570934266774
            ],
            [
              105.79915377542056,
              21.025572692228852
            ],
            [
              105.79914607693001,
              21.025574942220377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7885",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025577352422943,
          "to_latitude": 21.025574942220377,
          "to_longitude": 105.79914607693001,
          "from_longitude": 105.79915502874496
        }
      },
      {
        "id": 7878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79968644818533,
            21.02422172465801,
            105.79971728801301,
            21.02423719540296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79970190545845,
              21.024226155300877
            ],
            [
              105.79969157235051,
              21.02422249432414
            ],
            [
              105.79968833112196,
              21.02422172465801
            ],
            [
              105.79968644818533,
              21.024226536886406
            ],
            [
              105.79970023787257,
              21.024231302518196
            ],
            [
              105.79971728801301,
              21.02423719540296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7886",
          "diaChiLapD": "Ngõ 1150, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024226155300877,
          "to_latitude": 21.02423719540296,
          "to_longitude": 105.79971728801301,
          "from_longitude": 105.79970190545845
        }
      },
      {
        "id": 7879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80593034636618,
            21.020906719341,
            105.80594659255638,
            21.020933145310803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80593034636618,
              21.020933145310803
            ],
            [
              105.80593630278328,
              21.0209331197981
            ],
            [
              105.80594533427303,
              21.020932847585055
            ],
            [
              105.80594659255638,
              21.020906719341
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_152_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7887",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020933145310803,
          "to_latitude": 21.020906719341,
          "to_longitude": 105.80594659255638,
          "from_longitude": 105.80593034636618
        }
      },
      {
        "id": 7880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80751504451419,
            21.021670667055258,
            105.80751550687549,
            21.0217030644447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80751504451419,
              21.0217030644447
            ],
            [
              105.80751540855213,
              21.02170010373976
            ],
            [
              105.80751550687549,
              21.021670667055258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D25_183_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7888",
          "diaChiLapD": "Ngõ 62, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 25,
          "chieudaiQL": 3.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0217030644447,
          "to_latitude": 21.021670667055258,
          "to_longitude": 105.80751550687549,
          "from_longitude": 105.80751504451419
        }
      },
      {
        "id": 7881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80529483053472,
            21.024783424140605,
            105.80539225157621,
            21.02478542961126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80539225157621,
              21.024783424140605
            ],
            [
              105.80536548506221,
              21.02478542961126
            ],
            [
              105.80529483053472,
              21.02478410314461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7889",
          "diaChiLapD": "Ngõ 84, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024783424140605,
          "to_latitude": 21.02478410314461,
          "to_longitude": 105.80529483053472,
          "from_longitude": 105.80539225157621
        }
      },
      {
        "id": 7882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80968824697993,
            21.025537527467215,
            105.80977333628584,
            21.025578496563607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80977333628584,
              21.025537527467215
            ],
            [
              105.80971513251407,
              21.025565921175613
            ],
            [
              105.80968824697993,
              21.025578496563607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.7890",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025537527467215,
          "to_latitude": 21.025578496563607,
          "to_longitude": 105.80968824697993,
          "from_longitude": 105.80977333628584
        }
      },
      {
        "id": 7883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80972289341598,
            21.02558040052382,
            105.80979278006353,
            21.025611059160116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80979278006353,
              21.02558040052382
            ],
            [
              105.80978984210472,
              21.025582004194295
            ],
            [
              105.80972289341598,
              21.025611059160116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.7891",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02558040052382,
          "to_latitude": 21.025611059160116,
          "to_longitude": 105.80972289341598,
          "from_longitude": 105.80979278006353
        }
      },
      {
        "id": 7884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80972353588932,
            21.025002203958575,
            105.80976068434485,
            21.025019159192997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80976068434485,
              21.025002203958575
            ],
            [
              105.80975918376325,
              21.025003298589713
            ],
            [
              105.80974674323409,
              21.02500883319046
            ],
            [
              105.80972353588932,
              21.025019159192997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Q98_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7892",
          "diaChiLapD": "Ngõ 54, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025002203958575,
          "to_latitude": 21.025019159192997,
          "to_longitude": 105.80972353588932,
          "from_longitude": 105.80976068434485
        }
      },
      {
        "id": 7885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116414791736,
            21.018737186198955,
            105.81167143659424,
            21.018793181598774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8116414791736,
              21.018737186198955
            ],
            [
              105.81167075537952,
              21.018791910394285
            ],
            [
              105.81167143659424,
              21.018793181598774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7893",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018737186198955,
          "to_latitude": 21.018793181598774,
          "to_longitude": 105.81167143659424,
          "from_longitude": 105.8116414791736
        }
      },
      {
        "id": 7886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.811574460075,
            21.018793181598774,
            105.81167143659424,
            21.018841661708503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81167143659424,
              21.018793181598774
            ],
            [
              105.81157631118609,
              21.018841361134275
            ],
            [
              105.811574460075,
              21.018841661708503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71g_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DODV.7894",
          "diaChiLapD": "Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018793181598774,
          "to_latitude": 21.018841661708503,
          "to_longitude": 105.811574460075,
          "from_longitude": 105.81167143659424
        }
      },
      {
        "id": 7887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81101383291946,
            21.020909487539193,
            105.81111944626196,
            21.020916801269504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81111944626196,
              21.020909487539193
            ],
            [
              105.81111803192721,
              21.020914779973932
            ],
            [
              105.81111749068711,
              21.020916801269504
            ],
            [
              105.81105969303178,
              21.020915325515904
            ],
            [
              105.81101383291946,
              21.020912500305368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7895",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020909487539193,
          "to_latitude": 21.020912500305368,
          "to_longitude": 105.81101383291946,
          "from_longitude": 105.81111944626196
        }
      },
      {
        "id": 7888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81165071286097,
            21.02093532231308,
            105.81166519601611,
            21.020975503434972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81166519601611,
              21.02093532231308
            ],
            [
              105.81166078194994,
              21.020956552070896
            ],
            [
              105.81165771799905,
              21.02097129201616
            ],
            [
              105.81165684134767,
              21.020975503434972
            ],
            [
              105.81165071286097,
              21.020974839903023
            ],
            [
              105.8116514119624,
              21.02097070520259
            ],
            [
              105.81165390540411,
              21.02095595443137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7896",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02093532231308,
          "to_latitude": 21.02095595443137,
          "to_longitude": 105.81165390540411,
          "from_longitude": 105.81166519601611
        }
      },
      {
        "id": 7889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8110193426249,
            21.020909208865625,
            105.81194439607377,
            21.020998633801625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8110193426249,
              21.020909208865625
            ],
            [
              105.81111803192721,
              21.020914779973932
            ],
            [
              105.81113177504498,
              21.020915555342846
            ],
            [
              105.8112191812984,
              21.02092485091598
            ],
            [
              105.81122272307248,
              21.020925166412315
            ],
            [
              105.81122291940801,
              21.02092518354051
            ],
            [
              105.81122507719441,
              21.02092537557254
            ],
            [
              105.81123230702745,
              21.02092601851243
            ],
            [
              105.81123990932092,
              21.02092669399829
            ],
            [
              105.81129870753554,
              21.020931922346467
            ],
            [
              105.81137202035012,
              21.02094378790598
            ],
            [
              105.81144522016486,
              21.02095112428446
            ],
            [
              105.81144787954321,
              21.02095139069515
            ],
            [
              105.81150071196274,
              21.020956685368745
            ],
            [
              105.8116514119624,
              21.02097070520259
            ],
            [
              105.81165771799905,
              21.02097129201616
            ],
            [
              105.81166376513431,
              21.02097185477454
            ],
            [
              105.81166662941365,
              21.020972121107274
            ],
            [
              105.81171809598034,
              21.020976908293868
            ],
            [
              105.81194439607377,
              21.020998633801625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7897",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020909208865625,
          "to_latitude": 21.020998633801625,
          "to_longitude": 105.81194439607377,
          "from_longitude": 105.8110193426249
        }
      },
      {
        "id": 7890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8116559742262,
            21.020532527022507,
            105.81166063011337,
            21.020564528928134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81166063011337,
              21.020532527022507
            ],
            [
              105.81165947430722,
              21.02053954790924
            ],
            [
              105.8116559742262,
              21.020564528928134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7898",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020532527022507,
          "to_latitude": 21.020564528928134,
          "to_longitude": 105.8116559742262,
          "from_longitude": 105.81166063011337
        }
      },
      {
        "id": 7891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81164748434121,
            21.020510497385963,
            105.81169953741268,
            21.020538284337032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81165212791815,
              21.020531585783818
            ],
            [
              105.81165170435182,
              21.020538284337032
            ],
            [
              105.81164748434121,
              21.02053813647319
            ],
            [
              105.81164821804364,
              21.020531152826553
            ],
            [
              105.8116503890051,
              21.020510497385963
            ],
            [
              105.81169953741268,
              21.020516060678442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7899",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020531585783818,
          "to_latitude": 21.020516060678442,
          "to_longitude": 105.81169953741268,
          "from_longitude": 105.81165212791815
        }
      },
      {
        "id": 7892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81186869586583,
            21.023106756947694,
            105.81195206650521,
            21.023121594522426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81195206650521,
              21.023106756947694
            ],
            [
              105.81189595687071,
              21.023119777473426
            ],
            [
              105.81188692593925,
              21.023121594522426
            ],
            [
              105.81188326411986,
              21.023120615742176
            ],
            [
              105.81186869586583,
              21.023116726436818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7900",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023106756947694,
          "to_latitude": 21.023116726436818,
          "to_longitude": 105.81186869586583,
          "from_longitude": 105.81195206650521
        }
      },
      {
        "id": 7893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81347537812184,
            21.022367452020063,
            105.8134934717443,
            21.022432410160974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8134934717443,
              21.022367452020063
            ],
            [
              105.81349304149042,
              21.02236953421075
            ],
            [
              105.81348783170264,
              21.022394770916417
            ],
            [
              105.81348557041314,
              21.02239979735384
            ],
            [
              105.81347687031881,
              21.022419140935398
            ],
            [
              105.81347537812184,
              21.022432410160974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7901",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022367452020063,
          "to_latitude": 21.022432410160974,
          "to_longitude": 105.81347537812184,
          "from_longitude": 105.8134934717443
        }
      },
      {
        "id": 7894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81349284442491,
            21.022368361097815,
            105.81350735617677,
            21.022434456433132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81350735617677,
              21.022368361097815
            ],
            [
              105.81350639202633,
              21.022372749072137
            ],
            [
              105.81350010688412,
              21.022401380734106
            ],
            [
              105.81349284442491,
              21.022434456433132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36e_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7902",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022368361097815,
          "to_latitude": 21.022434456433132,
          "to_longitude": 105.81349284442491,
          "from_longitude": 105.81350735617677
        }
      },
      {
        "id": 7895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81407030223627,
            21.02174270265941,
            105.81408000502135,
            21.021803286470643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81407939574788,
              21.021803286470643
            ],
            [
              105.81408000502135,
              21.021800691291837
            ],
            [
              105.81407030223627,
              21.02174270265941
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7903",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021803286470643,
          "to_latitude": 21.02174270265941,
          "to_longitude": 105.81407030223627,
          "from_longitude": 105.81407939574788
        }
      },
      {
        "id": 7896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81332796205925,
            21.0214476159053,
            105.81332916540437,
            21.0214635943359
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81332916540437,
              21.0214476159053
            ],
            [
              105.81332837615149,
              21.02146101544754
            ],
            [
              105.81332796205925,
              21.0214635943359
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31a_Thành Công",
          "maTaiSan": "04.O9C.DODV.7904",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0214476159053,
          "to_latitude": 21.0214635943359,
          "to_longitude": 105.81332796205925,
          "from_longitude": 105.81332916540437
        }
      },
      {
        "id": 7897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81224271143333,
            21.020714147866126,
            105.81309885002676,
            21.020800502180304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81224271143333,
              21.020714147866126
            ],
            [
              105.81248271660195,
              21.02073999097305
            ],
            [
              105.81248638386224,
              21.020740386231008
            ],
            [
              105.8125160309016,
              21.020743578428924
            ],
            [
              105.81264324793692,
              21.02075953588624
            ],
            [
              105.81265000077376,
              21.020760383367815
            ],
            [
              105.81287677592418,
              21.0207821225323
            ],
            [
              105.81287981830378,
              21.020782414187128
            ],
            [
              105.8129349593893,
              21.020787700092097
            ],
            [
              105.81309885002676,
              21.020800502180304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7a_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7905",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020714147866126,
          "to_latitude": 21.020800502180304,
          "to_longitude": 105.81309885002676,
          "from_longitude": 105.81224271143333
        }
      },
      {
        "id": 7898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81507926490814,
            21.020659099400127,
            105.81509243431165,
            21.02076617236881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81509243431165,
              21.020659099400127
            ],
            [
              105.81508912578315,
              21.020704172751582
            ],
            [
              105.81508742544054,
              21.02071486969214
            ],
            [
              105.81507926490814,
              21.02076617236881
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7906",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020659099400127,
          "to_latitude": 21.02076617236881,
          "to_longitude": 105.81507926490814,
          "from_longitude": 105.81509243431165
        }
      },
      {
        "id": 7899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81508701328542,
            21.020660301914436,
            105.81510179218193,
            21.02076823795254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81510179218193,
              21.020660301914436
            ],
            [
              105.81509508264475,
              21.020704586787986
            ],
            [
              105.81509369902423,
              21.020715503501556
            ],
            [
              105.81508701328542,
              21.02076823795254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7907",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020660301914436,
          "to_latitude": 21.02076823795254,
          "to_longitude": 105.81508701328542,
          "from_longitude": 105.81510179218193
        }
      },
      {
        "id": 7900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81616736050405,
            21.021180144381972,
            105.81618571117045,
            21.021204395099385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81616736050405,
              21.021180144381972
            ],
            [
              105.81617208996437,
              21.021190643381097
            ],
            [
              105.8161782839412,
              21.021204395099385
            ],
            [
              105.81618571117045,
              21.021201533262477
            ],
            [
              105.81618061807404,
              21.021188028262312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40k_Thành Công",
          "maTaiSan": "04.O9C.DODV.7908",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021180144381972,
          "to_latitude": 21.021188028262312,
          "to_longitude": 105.81618061807404,
          "from_longitude": 105.81616736050405
        }
      },
      {
        "id": 7901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81537862655435,
            21.021562054585704,
            105.8153828884077,
            21.02160233636977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81537862655435,
              21.02160233636977
            ],
            [
              105.8153788296468,
              21.021600411522197
            ],
            [
              105.81538217932668,
              21.021568758124477
            ],
            [
              105.8153828884077,
              21.021562054585704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7909",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02160233636977,
          "to_latitude": 21.021562054585704,
          "to_longitude": 105.8153828884077,
          "from_longitude": 105.81537862655435
        }
      },
      {
        "id": 7902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81675118389178,
            21.021687933420708,
            105.8167651111218,
            21.02169705227923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81675118389178,
              21.02168816292035
            ],
            [
              105.816755002515,
              21.02169126252323
            ],
            [
              105.81676213537878,
              21.02169705227923
            ],
            [
              105.8167651111218,
              21.02169428947011
            ],
            [
              105.81676395699512,
              21.021692922107185
            ],
            [
              105.81675947682227,
              21.021687933420708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Thành Công",
          "maTaiSan": "04.O9C.DODV.7910",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02168816292035,
          "to_latitude": 21.021687933420708,
          "to_longitude": 105.81675947682227,
          "from_longitude": 105.81675118389178
        }
      },
      {
        "id": 7903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8170859693459,
            21.021563368125847,
            105.81709293994116,
            21.021584674963005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81709293994116,
              21.021574585490566
            ],
            [
              105.81709021865167,
              21.021584674963005
            ],
            [
              105.8170859693459,
              21.02158392311703
            ],
            [
              105.81708852719443,
              21.02157367455862
            ],
            [
              105.81709109915701,
              21.021563368125847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66_Thành Công",
          "maTaiSan": "04.O9C.DODV.7911",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021574585490566,
          "to_latitude": 21.021563368125847,
          "to_longitude": 105.81709109915701,
          "from_longitude": 105.81709293994116
        }
      },
      {
        "id": 7904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730400699315,
            21.022408661345715,
            105.81740328991646,
            21.022456034430025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81730654752026,
              21.022456034430025
            ],
            [
              105.81730650618752,
              21.022455863918786
            ],
            [
              105.81730400699315,
              21.02244528198087
            ],
            [
              105.81740328991646,
              21.022408661345715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Thành Công",
          "maTaiSan": "04.O9C.DODV.7912",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022456034430025,
          "to_latitude": 21.022408661345715,
          "to_longitude": 105.81740328991646,
          "from_longitude": 105.81730654752026
        }
      },
      {
        "id": 7905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81811166884584,
            21.02318328975041,
            105.81812675759946,
            21.02318663678016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81811499838723,
              21.02318328975041
            ],
            [
              105.81811166884584,
              21.023184329121833
            ],
            [
              105.81811224997232,
              21.02318663678016
            ],
            [
              105.81811545595095,
              21.02318604780992
            ],
            [
              105.81812675759946,
              21.023183971122076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_02_Thành Công",
          "maTaiSan": "04.O9C.DODV.7913",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02318328975041,
          "to_latitude": 21.023183971122076,
          "to_longitude": 105.81812675759946,
          "from_longitude": 105.81811499838723
        }
      },
      {
        "id": 7906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81441304131272,
            21.02148900261034,
            105.81441571504543,
            21.021511069515782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81441571504543,
              21.02148900261034
            ],
            [
              105.81441304131272,
              21.021511069515782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30b_Thành Công",
          "maTaiSan": "04.O9C.DODV.7914",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02148900261034,
          "to_latitude": 21.021511069515782,
          "to_longitude": 105.81441304131272,
          "from_longitude": 105.81441571504543
        }
      },
      {
        "id": 7907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81313691220707,
            21.023787284956843,
            105.81335378588588,
            21.024058522469065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81315043119002,
              21.023796602717727
            ],
            [
              105.81314237755839,
              21.023795392962622
            ],
            [
              105.81314439994584,
              21.023787284956843
            ],
            [
              105.81315183415367,
              21.023787981027354
            ],
            [
              105.81316044354651,
              21.023788786188675
            ],
            [
              105.81313691220707,
              21.02396243305779
            ],
            [
              105.8131393725677,
              21.023976190995608
            ],
            [
              105.81315036477275,
              21.024037647319744
            ],
            [
              105.81328283305126,
              21.024058522469065
            ],
            [
              105.81328915744439,
              21.023992772502964
            ],
            [
              105.81335378588588,
              21.02400052925982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14d_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.7915",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023796602717727,
          "to_latitude": 21.02400052925982,
          "to_longitude": 105.81335378588588,
          "from_longitude": 105.81315043119002
        }
      },
      {
        "id": 7908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81424259369746,
            21.023763301558873,
            105.81451065213267,
            21.023784330420018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424259369746,
              21.023764993292485
            ],
            [
              105.81424697134136,
              21.023763505493395
            ],
            [
              105.81425542037586,
              21.023763301558873
            ],
            [
              105.81428876374986,
              21.023764495858345
            ],
            [
              105.81438574449001,
              21.023774833200143
            ],
            [
              105.81451065213267,
              21.023784330420018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34c_Thành Công",
          "maTaiSan": "04.O9C.DODV.7916",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023764993292485,
          "to_latitude": 21.023784330420018,
          "to_longitude": 105.81451065213267,
          "from_longitude": 105.81424259369746
        }
      },
      {
        "id": 7909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81423261609109,
            21.02374150817155,
            105.81423786513182,
            21.023762800240817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81423454607244,
              21.02374150817155
            ],
            [
              105.81423381005293,
              21.023749461900966
            ],
            [
              105.81423261609109,
              21.02376236931422
            ],
            [
              105.81423637116401,
              21.023762800240817
            ],
            [
              105.81423786513182,
              21.02374986790735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25C_Thành Công",
          "maTaiSan": "04.O9C.DODV.7917",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02374150817155,
          "to_latitude": 21.02374986790735,
          "to_longitude": 105.81423786513182,
          "from_longitude": 105.81423454607244
        }
      },
      {
        "id": 7910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84066843910247,
            21.015752319276533,
            105.84080230947899,
            21.015951637671847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84068048675603,
              21.015951637671847
            ],
            [
              105.84068189962274,
              21.015925511273036
            ],
            [
              105.8406840113205,
              21.01590307199208
            ],
            [
              105.84068388767942,
              21.01588101227222
            ],
            [
              105.84068222715867,
              21.015838238393062
            ],
            [
              105.84066843910247,
              21.015837956978555
            ],
            [
              105.84066897447903,
              21.01576305091016
            ],
            [
              105.84066869693388,
              21.015752319276533
            ],
            [
              105.8407109116193,
              21.01575388369962
            ],
            [
              105.84080230947899,
              21.01575259021516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.2A_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7918",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015951637671847,
          "to_latitude": 21.01575259021516,
          "to_longitude": 105.84080230947899,
          "from_longitude": 105.84068048675603
        }
      },
      {
        "id": 7911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84066518336493,
            21.015951637671847,
            105.84068048675603,
            21.015971044985456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84066518336493,
              21.015971044985456
            ],
            [
              105.84067362345276,
              21.015957494668637
            ],
            [
              105.84068048675603,
              21.015951637671847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92.2A_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7919",
          "diaChiLapD": "Ngõ 226, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015971044985456,
          "to_latitude": 21.015951637671847,
          "to_longitude": 105.84068048675603,
          "from_longitude": 105.84066518336493
        }
      },
      {
        "id": 7912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060300849431,
            21.01534745506105,
            105.84060469002331,
            21.015348325589247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060300849431,
              21.01534745506105
            ],
            [
              105.84060343780328,
              21.015347677842527
            ],
            [
              105.84060469002331,
              21.015348325589247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93.6_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DODV.7920",
          "diaChiLapD": "Ngõ Hoàng An A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01534745506105,
          "to_latitude": 21.015348325589247,
          "to_longitude": 105.84060469002331,
          "from_longitude": 105.84060300849431
        }
      },
      {
        "id": 7913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84127881143245,
            21.01422867516412,
            105.84130287739364,
            21.014561409341695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84128446727807,
              21.01422867516412
            ],
            [
              105.84128254830246,
              21.01443793716492
            ],
            [
              105.84127881143245,
              21.014561124624617
            ],
            [
              105.84128205857614,
              21.014561409341695
            ],
            [
              105.84130287739364,
              21.014560001238088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91.1_Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7921",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01422867516412,
          "to_latitude": 21.014560001238088,
          "to_longitude": 105.84130287739364,
          "from_longitude": 105.84128446727807
        }
      },
      {
        "id": 7914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84108099406606,
            21.014582802612505,
            105.8410810209907,
            21.01458743628252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84108099406606,
              21.01458743628252
            ],
            [
              105.84108101028127,
              21.01458449530641
            ],
            [
              105.8410810209907,
              21.014582802612505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.1_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7922",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01458743628252,
          "to_latitude": 21.014582802612505,
          "to_longitude": 105.8410810209907,
          "from_longitude": 105.84108099406606
        }
      },
      {
        "id": 7915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8405455592563,
            21.014670995898314,
            105.84054724319105,
            21.014675558793794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8405455592563,
              21.014670995898314
            ],
            [
              105.84054684562831,
              21.014674477792784
            ],
            [
              105.84054724319105,
              21.014675558793794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.4_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.7923",
          "diaChiLapD": "Ngõ 304, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014670995898314,
          "to_latitude": 21.014675558793794,
          "to_longitude": 105.84054724319105,
          "from_longitude": 105.8405455592563
        }
      },
      {
        "id": 7916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83999299868525,
            21.014708421467695,
            105.84003889071337,
            21.014854085484224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003889071337,
              21.014708421467695
            ],
            [
              105.84002388964906,
              21.014764116052405
            ],
            [
              105.84000097817247,
              21.014854085484224
            ],
            [
              105.83999676535771,
              21.01485296189963
            ],
            [
              105.83999299868525,
              21.014851631983653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94.9_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DODV.7924",
          "diaChiLapD": "Hồ Ba Mẫu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014708421467695,
          "to_latitude": 21.014851631983653,
          "to_longitude": 105.83999299868525,
          "from_longitude": 105.84003889071337
        }
      },
      {
        "id": 7917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82712890779797,
            21.02060825550226,
            105.82714215159885,
            21.020633434442672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82712890779797,
              21.02060825550226
            ],
            [
              105.82713277216331,
              21.020615604320014
            ],
            [
              105.82714215159885,
              21.020633434442672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B1.1_Đường La Thành",
          "maTaiSan": "04.O13.DODV.7925",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02060825550226,
          "to_latitude": 21.020633434442672,
          "to_longitude": 105.82714215159885,
          "from_longitude": 105.82712890779797
        }
      },
      {
        "id": 7918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865496127548,
            21.02274238423967,
            105.8286564608589,
            21.022751060537356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82865496127548,
              21.02274238423967
            ],
            [
              105.82865511139788,
              21.0227432641525
            ],
            [
              105.82865599082317,
              21.022748343217376
            ],
            [
              105.8286564608589,
              21.022751060537356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3#_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DODV.7926",
          "diaChiLapD": "Ngõ 44, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02274238423967,
          "to_latitude": 21.022751060537356,
          "to_longitude": 105.8286564608589,
          "from_longitude": 105.82865496127548
        }
      },
      {
        "id": 7919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987546582541,
            21.020104316296763,
            105.82990794650273,
            21.020137293670047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82987546582541,
              21.020104316296763
            ],
            [
              105.82987557635133,
              21.020104472015447
            ],
            [
              105.82988061978116,
              21.0201115702414
            ],
            [
              105.82988911200442,
              21.02012356157175
            ],
            [
              105.8298988480656,
              21.020137293670047
            ],
            [
              105.82990064627559,
              21.020136303994477
            ],
            [
              105.82990343900367,
              21.020134764822075
            ],
            [
              105.82990794650273,
              21.020132281542324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_O3_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.7927",
          "diaChiLapD": "Ngõ 278, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020104316296763,
          "to_latitude": 21.020132281542324,
          "to_longitude": 105.82990794650273,
          "from_longitude": 105.82987546582541
        }
      },
      {
        "id": 7920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82893412070293,
            21.02475730101218,
            105.82895676874642,
            21.024766080938353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82894558391182,
              21.024766080938353
            ],
            [
              105.82894017955867,
              21.024765758635326
            ],
            [
              105.82893412070293,
              21.024765547909215
            ],
            [
              105.82893458496346,
              21.02475730101218
            ],
            [
              105.8289454247863,
              21.024757483918975
            ],
            [
              105.82895676874642,
              21.02475767610971
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N4_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DODV.7928",
          "diaChiLapD": "Ngõ Thổ Quan 1, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024766080938353,
          "to_latitude": 21.02475767610971,
          "to_longitude": 105.82895676874642,
          "from_longitude": 105.82894558391182
        }
      },
      {
        "id": 7921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918642408828,
            21.025622582039805,
            105.82927252183234,
            21.02572115626285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918642408828,
              21.025635848333987
            ],
            [
              105.82918814500603,
              21.025635655685406
            ],
            [
              105.829238237238,
              21.025622582039805
            ],
            [
              105.82927252183234,
              21.02572115626285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N7_Ngách 168/48, Hào Nam",
          "maTaiSan": "04.O13.DODV.7929",
          "diaChiLapD": "Ngách 168/48, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025635848333987,
          "to_latitude": 21.02572115626285,
          "to_longitude": 105.82927252183234,
          "from_longitude": 105.82918642408828
        }
      },
      {
        "id": 7922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918785233929,
            21.0256295484172,
            105.82927102846527,
            21.025744398993748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918785233929,
              21.02564031233129
            ],
            [
              105.82919674898875,
              21.025638061472492
            ],
            [
              105.82923290491547,
              21.0256295484172
            ],
            [
              105.82927102846527,
              21.025744398993748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N8_Ngách 168/48, Hào Nam",
          "maTaiSan": "04.O13.DODV.7930",
          "diaChiLapD": "Ngách 168/48, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02564031233129,
          "to_latitude": 21.025744398993748,
          "to_longitude": 105.82927102846527,
          "from_longitude": 105.82918785233929
        }
      },
      {
        "id": 7923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924091490818,
            21.025720743690535,
            105.82930590404598,
            21.025862150296135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924091490818,
              21.025724573734
            ],
            [
              105.82924819256263,
              21.025722505992306
            ],
            [
              105.82925469845658,
              21.025720743690535
            ],
            [
              105.82930590404598,
              21.025862150296135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_N9_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DODV.7931",
          "diaChiLapD": "Ngõ 168, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025724573734,
          "to_latitude": 21.025862150296135,
          "to_longitude": 105.82930590404598,
          "from_longitude": 105.82924091490818
        }
      },
      {
        "id": 7924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83015594529273,
            21.023849224603765,
            105.83017816733246,
            21.023858138998335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83016770862544,
              21.023858138998335
            ],
            [
              105.83017816733246,
              21.023854481371703
            ],
            [
              105.8301757621096,
              21.023849224603765
            ],
            [
              105.83016552345087,
              21.02385301122049
            ],
            [
              105.83015594529273,
              21.023856554354893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_S2_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.7932",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023858138998335,
          "to_latitude": 21.023856554354893,
          "to_longitude": 105.83015594529273,
          "from_longitude": 105.83016770862544
        }
      },
      {
        "id": 7925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142823072767,
            21.023739221152752,
            105.83143793924029,
            21.02375505993729
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83142869371622,
              21.023745515265876
            ],
            [
              105.83143435985299,
              21.02375505993729
            ],
            [
              105.83143793924029,
              21.023753814053975
            ],
            [
              105.83143177537706,
              21.02374454820249
            ],
            [
              105.83142823072767,
              21.023739221152752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Q6_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.7933",
          "diaChiLapD": "Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023745515265876,
          "to_latitude": 21.023739221152752,
          "to_longitude": 105.83142823072767,
          "from_longitude": 105.83142869371622
        }
      },
      {
        "id": 7926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82233411619818,
            21.02398725336871,
            105.82242670604286,
            21.024198478099027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233411619818,
              21.02399106256969
            ],
            [
              105.82233663653646,
              21.02399017064154
            ],
            [
              105.82234669355903,
              21.02398725336871
            ],
            [
              105.82242670604286,
              21.024193966206887
            ],
            [
              105.8224195221417,
              21.024196470365418
            ],
            [
              105.82241376023006,
              21.024198478099027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E18_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DODV.7934",
          "diaChiLapD": "Ngõ 418, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02399106256969,
          "to_latitude": 21.024198478099027,
          "to_longitude": 105.82241376023006,
          "from_longitude": 105.82233411619818
        }
      },
      {
        "id": 7927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82438016813246,
            21.02400385083297,
            105.82441685870768,
            21.024125252492283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82438016813246,
              21.02400385083297
            ],
            [
              105.82439797486741,
              21.024093473774208
            ],
            [
              105.82439916835648,
              21.024125252492283
            ],
            [
              105.82440518958003,
              21.02412317298362
            ],
            [
              105.82441685870768,
              21.0241205765489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_F7_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DODV.7935",
          "diaChiLapD": "Ngõ 318, La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02400385083297,
          "to_latitude": 21.0241205765489,
          "to_longitude": 105.82441685870768,
          "from_longitude": 105.82438016813246
        }
      },
      {
        "id": 7928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82624340914782,
            21.02470627505095,
            105.82626417574153,
            21.02482497896592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624340914782,
              21.02470627505095
            ],
            [
              105.82626417574153,
              21.024816064133237
            ],
            [
              105.82624892025073,
              21.024819005989617
            ],
            [
              105.82625025707141,
              21.02482382903925
            ],
            [
              105.82625060395584,
              21.02482497896592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Hẻm 127/23/16, Hào Nam",
          "maTaiSan": "04.O13.DODV.7936",
          "diaChiLapD": "Hẻm 127/23/16, Hào Nam",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02470627505095,
          "to_latitude": 21.02482497896592,
          "to_longitude": 105.82625060395584,
          "from_longitude": 105.82624340914782
        }
      },
      {
        "id": 7929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612760429191,
            21.025164568143968,
            105.82613999330779,
            21.025179155115733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613325540481,
              21.02517670036492
            ],
            [
              105.82612760429191,
              21.025166340728386
            ],
            [
              105.82613104521587,
              21.025164568143968
            ],
            [
              105.82613735392069,
              21.025174852327982
            ],
            [
              105.82613999330779,
              21.025179155115733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT16_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DODV.7937",
          "diaChiLapD": "Ngõ 127, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02517670036492,
          "to_latitude": 21.025179155115733,
          "to_longitude": 105.82613999330779,
          "from_longitude": 105.82613325540481
        }
      },
      {
        "id": 7930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82467979824774,
            21.022344672148684,
            105.82491119041082,
            21.022427715796763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82467979824774,
              21.022427715796763
            ],
            [
              105.82474152886941,
              21.022403798030133
            ],
            [
              105.82485344415176,
              21.022365369519584
            ],
            [
              105.82491119041082,
              21.022347436820123
            ],
            [
              105.82491099600814,
              21.022346722408344
            ],
            [
              105.82491023823101,
              21.022344672148684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT45_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.7938",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022427715796763,
          "to_latitude": 21.022344672148684,
          "to_longitude": 105.82491023823101,
          "from_longitude": 105.82467979824774
        }
      },
      {
        "id": 7931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82548242756015,
            21.022741029029614,
            105.82551271944216,
            21.022751450065538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82548242756015,
              21.022747241963984
            ],
            [
              105.82549932081457,
              21.02274354616808
            ],
            [
              105.82551082767561,
              21.022741029029614
            ],
            [
              105.82551271944216,
              21.022749333134254
            ],
            [
              105.82550160094266,
              21.022751450065538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T3_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.7939",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022747241963984,
          "to_latitude": 21.022751450065538,
          "to_longitude": 105.82550160094266,
          "from_longitude": 105.82548242756015
        }
      },
      {
        "id": 7932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556201037083,
            21.02326471001939,
            105.82556636081763,
            21.02328067057126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556201037083,
              21.02326471001939
            ],
            [
              105.82556332566959,
              21.02326930014863
            ],
            [
              105.82556636081763,
              21.02328067057126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4'_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DODV.7940",
          "diaChiLapD": "Ngõ 45, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02326471001939,
          "to_latitude": 21.02328067057126,
          "to_longitude": 105.82556636081763,
          "from_longitude": 105.82556201037083
        }
      },
      {
        "id": 7933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82689292275789,
            21.024981446158534,
            105.82689539054124,
            21.024991260343764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82689292275789,
              21.024981446158534
            ],
            [
              105.82689459301686,
              21.024987766934554
            ],
            [
              105.82689539054124,
              21.024991260343764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT15_Ngách 101/2, Hào Nam",
          "maTaiSan": "04.O13.DODV.7941",
          "diaChiLapD": "Ngách 101/2, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024981446158534,
          "to_latitude": 21.024991260343764,
          "to_longitude": 105.82689539054124,
          "from_longitude": 105.82689292275789
        }
      },
      {
        "id": 7934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559100996311,
            21.029942926111456,
            105.83575477878695,
            21.03016590596659
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575477878695,
              21.03015234997358
            ],
            [
              105.8357331923539,
              21.03016590596659
            ],
            [
              105.83559100996311,
              21.029942926111456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Z11'_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.7942",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03015234997358,
          "to_latitude": 21.029942926111456,
          "to_longitude": 105.83559100996311,
          "from_longitude": 105.83575477878695
        }
      },
      {
        "id": 7935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82842003390587,
            21.015286542254497,
            105.82842877537915,
            21.015300367816334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82842003390587,
              21.015286542254497
            ],
            [
              105.82842048807028,
              21.015287262623566
            ],
            [
              105.8284239388452,
              21.015292718464995
            ],
            [
              105.82842877537915,
              21.015300367816334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_141_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.7943",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015286542254497,
          "to_latitude": 21.015300367816334,
          "to_longitude": 105.82842877537915,
          "from_longitude": 105.82842003390587
        }
      },
      {
        "id": 7936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82943385609357,
            21.015046506024007,
            105.82944434429083,
            21.01507182091927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294439340471,
              21.01507182091927
            ],
            [
              105.82943385609357,
              21.015051160897038
            ],
            [
              105.82943537588315,
              21.015050279165422
            ],
            [
              105.82944434429083,
              21.015046506024007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_149_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.7944",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01507182091927,
          "to_latitude": 21.015046506024007,
          "to_longitude": 105.82944434429083,
          "from_longitude": 105.8294439340471
        }
      },
      {
        "id": 7937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82968301308878,
            21.014473860289193,
            105.82969747650196,
            21.01448587799537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82969188077442,
              21.014484628122833
            ],
            [
              105.82968301308878,
              21.014475821563156
            ],
            [
              105.82968531183505,
              21.014473860289193
            ],
            [
              105.82969412219879,
              21.014482564160573
            ],
            [
              105.82969747650196,
              21.01448587799537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_218_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.7945",
          "diaChiLapD": "Ngõ 115, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014484628122833,
          "to_latitude": 21.01448587799537,
          "to_longitude": 105.82969747650196,
          "from_longitude": 105.82969188077442
        }
      },
      {
        "id": 7938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82928419858179,
            21.01666226077035,
            105.82930930760985,
            21.016667589627474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82930204155709,
              21.016664369823527
            ],
            [
              105.82928444363158,
              21.01666226077035
            ],
            [
              105.82928419858179,
              21.01666456699606
            ],
            [
              105.8293017339779,
              21.016666675450836
            ],
            [
              105.82930927969424,
              21.01666758615064
            ],
            [
              105.82930930760985,
              21.016667589627474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.7946",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016664369823527,
          "to_latitude": 21.016667589627474,
          "to_longitude": 105.82930930760985,
          "from_longitude": 105.82930204155709
        }
      },
      {
        "id": 7939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962990035013,
            21.016477952897002,
            105.82964998836302,
            21.016548255856048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964998836302,
              21.016477952897002
            ],
            [
              105.82964901128068,
              21.01648669365747
            ],
            [
              105.82964858051746,
              21.016488292660604
            ],
            [
              105.82963632716222,
              21.016499574088314
            ],
            [
              105.82963572097572,
              21.01650493588043
            ],
            [
              105.82962990035013,
              21.016548255856048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_123_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.7947",
          "diaChiLapD": "Ngõ 73, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016477952897002,
          "to_latitude": 21.016548255856048,
          "to_longitude": 105.82962990035013,
          "from_longitude": 105.82964998836302
        }
      },
      {
        "id": 7940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336080094583,
            21.029060381437123,
            105.83398101264332,
            21.029177548660417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398101264332,
              21.029060381437123
            ],
            [
              105.83381071158105,
              21.029101556370467
            ],
            [
              105.8338222062745,
              21.02915455353566
            ],
            [
              105.83371390161513,
              21.029177548660417
            ],
            [
              105.83369445047144,
              21.029158525501003
            ],
            [
              105.8336080094583,
              21.029176633503486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Y3_Ngõ 23, Phan Phù Tiên",
          "maTaiSan": "04.O13.DODV.7948",
          "diaChiLapD": "Ngõ 23, Phan Phù Tiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029060381437123,
          "to_latitude": 21.029176633503486,
          "to_longitude": 105.8336080094583,
          "from_longitude": 105.83398101264332
        }
      },
      {
        "id": 7941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80599180198124,
            21.01710182071773,
            105.80599685611355,
            21.017104871364616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80599180198124,
              21.01710182071773
            ],
            [
              105.80599327122233,
              21.01710277930884
            ],
            [
              105.80599685611355,
              21.017104871364616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M90_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7949",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01710182071773,
          "to_latitude": 21.017104871364616,
          "to_longitude": 105.80599685611355,
          "from_longitude": 105.80599180198124
        }
      },
      {
        "id": 7942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80550485698714,
            21.017092668373337,
            105.80586673535716,
            21.017280669557888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80550485698714,
              21.017280669557888
            ],
            [
              105.80565694568843,
              21.017092668373337
            ],
            [
              105.80586673535716,
              21.01723672611297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M90_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7950",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017280669557888,
          "to_latitude": 21.01723672611297,
          "to_longitude": 105.80586673535716,
          "from_longitude": 105.80550485698714
        }
      },
      {
        "id": 7943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80572160951284,
            21.017145228135718,
            105.80587510997043,
            21.017247083513585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80572160951284,
              21.017145228135718
            ],
            [
              105.80583244786824,
              21.017227477786303
            ],
            [
              105.80586885169133,
              21.01724407379329
            ],
            [
              105.80587510997043,
              21.017247083513585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M90_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7951",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017145228135718,
          "to_latitude": 21.017247083513585,
          "to_longitude": 105.80587510997043,
          "from_longitude": 105.80572160951284
        }
      },
      {
        "id": 7944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80582918487295,
            21.01729031103164,
            105.80583842617415,
            21.01729905116112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80582918487295,
              21.01729031103164
            ],
            [
              105.80583136362308,
              21.01729226704128
            ],
            [
              105.80583842617415,
              21.01729905116112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M88_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7952",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01729031103164,
          "to_latitude": 21.01729905116112,
          "to_longitude": 105.80583842617415,
          "from_longitude": 105.80582918487295
        }
      },
      {
        "id": 7945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80547913519742,
            21.017263393969454,
            105.80584270767042,
            21.01770514924672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80547913519742,
              21.01770514924672
            ],
            [
              105.80557724732802,
              21.017585768213067
            ],
            [
              105.80565249058816,
              21.017492496691876
            ],
            [
              105.80566365958394,
              21.017478661421222
            ],
            [
              105.80573906148129,
              21.017385214788998
            ],
            [
              105.80584270767042,
              21.017263393969454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M86_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7953",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 141.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01770514924672,
          "to_latitude": 21.017263393969454,
          "to_longitude": 105.80584270767042,
          "from_longitude": 105.80547913519742
        }
      },
      {
        "id": 7946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80566365958394,
            21.017478661421222,
            105.80566930634016,
            21.017482975598366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80566365958394,
              21.017478661421222
            ],
            [
              105.80566631379472,
              21.017480664858777
            ],
            [
              105.80566930634016,
              21.017482975598366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M86_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.7954",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 141.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017478661421222,
          "to_latitude": 21.017482975598366,
          "to_longitude": 105.80566930634016,
          "from_longitude": 105.80566365958394
        }
      },
      {
        "id": 7947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8047460224427,
            21.01792541892226,
            105.80474971210964,
            21.017931050850503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80474971210964,
              21.01792541892226
            ],
            [
              105.80474759796556,
              21.017928510742127
            ],
            [
              105.8047460224427,
              21.017931050850503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M79_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7955",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 143,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01792541892226,
          "to_latitude": 21.017931050850503,
          "to_longitude": 105.8047460224427,
          "from_longitude": 105.80474971210964
        }
      },
      {
        "id": 7948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80454092766531,
            21.017801433849122,
            105.80488426315392,
            21.01801185908546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80454092766531,
              21.017801433849122
            ],
            [
              105.80466640211053,
              21.017878134241364
            ],
            [
              105.8047460224427,
              21.017931050850503
            ],
            [
              105.80475507402546,
              21.017937065990097
            ],
            [
              105.80480589078343,
              21.017963676787666
            ],
            [
              105.80488426315392,
              21.01801185908546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M79_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7956",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 143,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017801433849122,
          "to_latitude": 21.01801185908546,
          "to_longitude": 105.80488426315392,
          "from_longitude": 105.80454092766531
        }
      },
      {
        "id": 7949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80482904913343,
            21.018130422588744,
            105.8049692495594,
            21.018202978343552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80482904913343,
              21.018130422588744
            ],
            [
              105.80493284614668,
              21.018202978343552
            ],
            [
              105.8049692495594,
              21.018156921134963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M75_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7957",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018130422588744,
          "to_latitude": 21.018156921134963,
          "to_longitude": 105.8049692495594,
          "from_longitude": 105.80482904913343
        }
      },
      {
        "id": 7950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80508339114519,
            21.0179876843898,
            105.80508501410102,
            21.01799088498625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80508339114519,
              21.01799088498625
            ],
            [
              105.80508501410102,
              21.0179876843898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M76_Ngõ 157/23, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7958",
          "diaChiLapD": "Ngõ 157/23, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01799088498625,
          "to_latitude": 21.0179876843898,
          "to_longitude": 105.80508501410102,
          "from_longitude": 105.80508339114519
        }
      },
      {
        "id": 7951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80494036497608,
            21.017966018440173,
            105.80518995236402,
            21.018041850258108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80518995236402,
              21.018041850258108
            ],
            [
              105.80513728600434,
              21.01800974676251
            ],
            [
              105.80508501410102,
              21.0179876843898
            ],
            [
              105.80503804402403,
              21.017967859500022
            ],
            [
              105.80501806199202,
              21.017966018440173
            ],
            [
              105.80500267501463,
              21.017971843921682
            ],
            [
              105.80494036497608,
              21.01801069311391
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M76_Ngõ 157/23, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7959",
          "diaChiLapD": "Ngõ 157/23, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018041850258108,
          "to_latitude": 21.01801069311391,
          "to_longitude": 105.80494036497608,
          "from_longitude": 105.80518995236402
        }
      },
      {
        "id": 7952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80502653481192,
            21.017973168953365,
            105.80522536713949,
            21.018070217447054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80502653481192,
              21.017973168953365
            ],
            [
              105.8050367417566,
              21.01797399407375
            ],
            [
              105.80513337772783,
              21.018015509798424
            ],
            [
              105.80522536713949,
              21.018070217447054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M78_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7960",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 143,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017973168953365,
          "to_latitude": 21.018070217447054,
          "to_longitude": 105.80522536713949,
          "from_longitude": 105.80502653481192
        }
      },
      {
        "id": 7953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80358473804702,
            21.01898156796302,
            105.80435905430352,
            21.019197878133422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80435905430352,
              21.019197878133422
            ],
            [
              105.80420215803734,
              21.019119885455137
            ],
            [
              105.80415296321209,
              21.019195419350638
            ],
            [
              105.80412155352819,
              21.01918562598722
            ],
            [
              105.80396193665933,
              21.019098785263893
            ],
            [
              105.80381534134838,
              21.019029217632966
            ],
            [
              105.80370844169822,
              21.01898156796302
            ],
            [
              105.80367760829931,
              21.018986493529265
            ],
            [
              105.803642107403,
              21.01901115683314
            ],
            [
              105.80363394188105,
              21.01901916550617
            ],
            [
              105.80359568326618,
              21.01901030401611
            ],
            [
              105.80359278994096,
              21.019009634842853
            ],
            [
              105.80358473804702,
              21.019006535060942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M59_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7961",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019197878133422,
          "to_latitude": 21.019006535060942,
          "to_longitude": 105.80358473804702,
          "from_longitude": 105.80435905430352
        }
      },
      {
        "id": 7954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80355339209535,
            21.01901030401611,
            105.80359568326618,
            21.01911005865032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80359568326618,
              21.01901030401611
            ],
            [
              105.80355339209535,
              21.01911005865032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M59_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7962",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01901030401611,
          "to_latitude": 21.01911005865032,
          "to_longitude": 105.80355339209535,
          "from_longitude": 105.80359568326618
        }
      },
      {
        "id": 7955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80355901390988,
            21.01914598243227,
            105.80391856959945,
            21.019349518050067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80391856959945,
              21.019349518050067
            ],
            [
              105.80382864445544,
              21.019304007476805
            ],
            [
              105.80382619558942,
              21.01930280691502
            ],
            [
              105.803800471412,
              21.019290196550138
            ],
            [
              105.80371620939951,
              21.019237321319363
            ],
            [
              105.80361850790318,
              21.01918176642865
            ],
            [
              105.80355901390988,
              21.01914598243227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M54_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7963",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019349518050067,
          "to_latitude": 21.01914598243227,
          "to_longitude": 105.80355901390988,
          "from_longitude": 105.80391856959945
        }
      },
      {
        "id": 7956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80382619558942,
            21.019299910870785,
            105.80382759511807,
            21.01930280691502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80382759511807,
              21.019299910870785
            ],
            [
              105.80382745101373,
              21.01930030535976
            ],
            [
              105.80382619558942,
              21.01930280691502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M54_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7964",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019299910870785,
          "to_latitude": 21.01930280691502,
          "to_longitude": 105.80382619558942,
          "from_longitude": 105.80382759511807
        }
      },
      {
        "id": 7957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80356850742335,
            21.01913877949263,
            105.80392529986148,
            21.01933917497621
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80356850742335,
              21.01913877949263
            ],
            [
              105.8036430923367,
              21.01918813456875
            ],
            [
              105.80374500284236,
              21.019245199628156
            ],
            [
              105.80383020136078,
              21.019292245492466
            ],
            [
              105.80385691533358,
              21.019306996345126
            ],
            [
              105.80392529986148,
              21.01933917497621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M53_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7965",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01913877949263,
          "to_latitude": 21.01933917497621,
          "to_longitude": 105.80392529986148,
          "from_longitude": 105.80356850742335
        }
      },
      {
        "id": 7958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80382579263413,
            21.019292245492466,
            105.80383020136078,
            21.019299417192816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80382579263413,
              21.019299417192816
            ],
            [
              105.80382585824314,
              21.019299274172543
            ],
            [
              105.80383020136078,
              21.019292245492466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M53_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7966",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019299417192816,
          "to_latitude": 21.019292245492466,
          "to_longitude": 105.80383020136078,
          "from_longitude": 105.80382579263413
        }
      },
      {
        "id": 7959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80393589614415,
            21.019359674268557,
            105.80441354957213,
            21.01960173967055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80441354957213,
              21.01960173967055
            ],
            [
              105.80439671160902,
              21.019591396933468
            ],
            [
              105.80424920125608,
              21.019493575594613
            ],
            [
              105.80422380544424,
              21.01948175768198
            ],
            [
              105.80418183977366,
              21.01946222865201
            ],
            [
              105.80407634983615,
              21.019416532570528
            ],
            [
              105.80393589614415,
              21.019359674268557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M56_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7967",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01960173967055,
          "to_latitude": 21.019359674268557,
          "to_longitude": 105.80393589614415,
          "from_longitude": 105.80441354957213
        }
      },
      {
        "id": 7960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80422380544424,
            21.019472563464372,
            105.8042297799739,
            21.01948175768198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8042297799739,
              21.019472563464372
            ],
            [
              105.80422686893415,
              21.01947756214986
            ],
            [
              105.80422380544424,
              21.01948175768198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M56_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7968",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019472563464372,
          "to_latitude": 21.01948175768198,
          "to_longitude": 105.80422380544424,
          "from_longitude": 105.8042297799739
        }
      },
      {
        "id": 7961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80395362069602,
            21.019343765017766,
            105.80427676428907,
            21.019491923333526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80395362069602,
              21.019343765017766
            ],
            [
              105.80405379102929,
              21.019391643234723
            ],
            [
              105.80406749280037,
              21.019398192816872
            ],
            [
              105.80415896692183,
              21.01943851682021
            ],
            [
              105.80417878988686,
              21.019447254883367
            ],
            [
              105.80424322193481,
              21.019475687408296
            ],
            [
              105.80427676428907,
              21.019491923333526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M55_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7969",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019343765017766,
          "to_latitude": 21.019491923333526,
          "to_longitude": 105.80427676428907,
          "from_longitude": 105.80395362069602
        }
      },
      {
        "id": 7962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80415473049052,
            21.01943851682021,
            105.80415896692183,
            21.019446064360753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80415896692183,
              21.01943851682021
            ],
            [
              105.80415606852976,
              21.019443901123672
            ],
            [
              105.80415473049052,
              21.019446064360753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M55_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7970",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01943851682021,
          "to_latitude": 21.019446064360753,
          "to_longitude": 105.80415473049052,
          "from_longitude": 105.80415896692183
        }
      },
      {
        "id": 7963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8042892537837,
            21.019501840448445,
            105.80489667208529,
            21.019858273727667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8042892537837,
              21.019501840448445
            ],
            [
              105.8043958839308,
              21.01957034205718
            ],
            [
              105.80444986292552,
              21.019609817134757
            ],
            [
              105.80448199406572,
              21.019628911186448
            ],
            [
              105.80450571775,
              21.01964300949221
            ],
            [
              105.80462987790854,
              21.01971740289926
            ],
            [
              105.8046763869606,
              21.019744080155334
            ],
            [
              105.80469568380038,
              21.019755149169736
            ],
            [
              105.8047443238332,
              21.01978383504274
            ],
            [
              105.80478145775815,
              21.019802658785846
            ],
            [
              105.80489667208529,
              21.019858273727667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M57_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7971",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019501840448445,
          "to_latitude": 21.019858273727667,
          "to_longitude": 105.80489667208529,
          "from_longitude": 105.8042892537837
        }
      },
      {
        "id": 7964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80447605127925,
            21.019628911186448,
            105.80448199406572,
            21.019636847967256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80448199406572,
              21.019628911186448
            ],
            [
              105.80447659761529,
              21.019636119187144
            ],
            [
              105.80447605127925,
              21.019636847967256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M57_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7972",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019628911186448,
          "to_latitude": 21.019636847967256,
          "to_longitude": 105.80447605127925,
          "from_longitude": 105.80448199406572
        }
      },
      {
        "id": 7965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80447625804872,
            21.01963838205752,
            105.80447882997593,
            21.019641859008434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80447882997593,
              21.01963838205752
            ],
            [
              105.80447812356485,
              21.019639038433137
            ],
            [
              105.80447625804872,
              21.019641859008434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M58_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7973",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01963838205752,
          "to_latitude": 21.019641859008434,
          "to_longitude": 105.80447625804872,
          "from_longitude": 105.80447882997593
        }
      },
      {
        "id": 7966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80331194086952,
            21.01954051485608,
            105.80372890316958,
            21.01979565490699
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80331194086952,
              21.01954051485608
            ],
            [
              105.80347823902245,
              21.019641594745192
            ],
            [
              105.80353535682733,
              21.01967929580164
            ],
            [
              105.8035510346399,
              21.01968964410575
            ],
            [
              105.80359370913858,
              21.019715432032058
            ],
            [
              105.80363035857334,
              21.0197375794943
            ],
            [
              105.80369575807356,
              21.01977726098907
            ],
            [
              105.80372890316958,
              21.01979565490699
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M42_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7974",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 154,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01954051485608,
          "to_latitude": 21.01979565490699,
          "to_longitude": 105.80372890316958,
          "from_longitude": 105.80331194086952
        }
      },
      {
        "id": 7967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80332839705677,
            21.019534733247347,
            105.80368854687168,
            21.019761771257254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80332839705677,
              21.019534733247347
            ],
            [
              105.80349024141499,
              21.019635834158066
            ],
            [
              105.80354536631744,
              21.019670179191998
            ],
            [
              105.80361824785459,
              21.019715586170854
            ],
            [
              105.80368854687168,
              21.019761771257254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M43_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7975",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 154,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019534733247347,
          "to_latitude": 21.019761771257254,
          "to_longitude": 105.80368854687168,
          "from_longitude": 105.80332839705677
        }
      },
      {
        "id": 7968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80354000587973,
            21.019670179191998,
            105.80354536631744,
            21.0196772948647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80354000587973,
              21.0196772948647
            ],
            [
              105.80354023512014,
              21.01967699029673
            ],
            [
              105.80354536631744,
              21.019670179191998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M43_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7976",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0196772948647,
          "to_latitude": 21.019670179191998,
          "to_longitude": 105.80354536631744,
          "from_longitude": 105.80354000587973
        }
      },
      {
        "id": 7969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80353535682733,
            21.01967545697516,
            105.80353843764348,
            21.01967929580164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80353843764348,
              21.01967545697516
            ],
            [
              105.80353832297004,
              21.019675599323858
            ],
            [
              105.80353535682733,
              21.01967929580164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M42_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7977",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01967545697516,
          "to_latitude": 21.01967929580164,
          "to_longitude": 105.80353535682733,
          "from_longitude": 105.80353843764348
        }
      },
      {
        "id": 7970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80368731087768,
            21.01976091740156,
            105.80396582604382,
            21.01989856833502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80368731087768,
              21.01976879699557
            ],
            [
              105.80368793201129,
              21.019767843863598
            ],
            [
              105.80369606681464,
              21.01976091740156
            ],
            [
              105.80375318267133,
              21.019789621323266
            ],
            [
              105.80385627943262,
              21.01983998866837
            ],
            [
              105.80391814286475,
              21.01987124159074
            ],
            [
              105.80396582604382,
              21.01989856833502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M45_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7978",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01976879699557,
          "to_latitude": 21.01989856833502,
          "to_longitude": 105.80396582604382,
          "from_longitude": 105.80368731087768
        }
      },
      {
        "id": 7971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80373864141515,
            21.019802878083524,
            105.80394093420261,
            21.01990001914819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80373864141515,
              21.019802878083524
            ],
            [
              105.80377065841192,
              21.019814504006455
            ],
            [
              105.80383634515447,
              21.019846742381144
            ],
            [
              105.80391137277162,
              21.01988366950583
            ],
            [
              105.80394093420261,
              21.01990001914819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M44_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7979",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019802878083524,
          "to_latitude": 21.01990001914819,
          "to_longitude": 105.80394093420261,
          "from_longitude": 105.80373864141515
        }
      },
      {
        "id": 7972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80395057334103,
            21.019907772976023,
            105.80407380175359,
            21.019978301757877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80395057334103,
              21.019907772976023
            ],
            [
              105.80407380175359,
              21.019978301757877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M46_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7980",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019907772976023,
          "to_latitude": 21.019978301757877,
          "to_longitude": 105.80407380175359,
          "from_longitude": 105.80395057334103
        }
      },
      {
        "id": 7973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80395008842463,
            21.019972916078576,
            105.8040913220945,
            21.020317947942456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8040913220945,
              21.019972916078576
            ],
            [
              105.80408572552096,
              21.019988555755475
            ],
            [
              105.80405837498782,
              21.020056410979578
            ],
            [
              105.80398519350881,
              21.02025744991766
            ],
            [
              105.80395008842463,
              21.020317947942456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M47_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7981",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019972916078576,
          "to_latitude": 21.020317947942456,
          "to_longitude": 105.80395008842463,
          "from_longitude": 105.8040913220945
        }
      },
      {
        "id": 7974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80412162386008,
            21.01998493837109,
            105.80449920133314,
            21.020187042619884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8041269861832,
              21.01998493837109
            ],
            [
              105.80412557646058,
              21.019987906724097
            ],
            [
              105.80412162386008,
              21.019998336933085
            ],
            [
              105.8042571177269,
              21.020038585543503
            ],
            [
              105.80437301116949,
              21.020102084625492
            ],
            [
              105.80449920133314,
              21.020187042619884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M49_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7982",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01998493837109,
          "to_latitude": 21.020187042619884,
          "to_longitude": 105.80449920133314,
          "from_longitude": 105.8041269861832
        }
      },
      {
        "id": 7975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80414063389098,
            21.019986279252645,
            105.80414770843632,
            21.019997188711322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8041421457107,
              21.01998939369104
            ],
            [
              105.80414063389098,
              21.01999556718425
            ],
            [
              105.80414517057949,
              21.019997188711322
            ],
            [
              105.80414667411067,
              21.019990725320678
            ],
            [
              105.80414770843632,
              21.019986279252645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M50_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7983",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01998939369104,
          "to_latitude": 21.019986279252645,
          "to_longitude": 105.80414770843632,
          "from_longitude": 105.8041421457107
        }
      },
      {
        "id": 7976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80409448502003,
            21.01997147492795,
            105.80450058868438,
            21.020178112153946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80409448502003,
              21.01997147492795
            ],
            [
              105.80414770843632,
              21.019986279252645
            ],
            [
              105.8041965122866,
              21.020002824203587
            ],
            [
              105.80425964179803,
              21.020027976923938
            ],
            [
              105.80437375434822,
              21.020091603652272
            ],
            [
              105.80450058868438,
              21.020178112153946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M50_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7984",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01997147492795,
          "to_latitude": 21.020178112153946,
          "to_longitude": 105.80450058868438,
          "from_longitude": 105.80409448502003
        }
      },
      {
        "id": 7977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80317832653468,
            21.020229186650244,
            105.80324958575825,
            21.020356490093683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80324958575825,
              21.020229186650244
            ],
            [
              105.80322491507118,
              21.020270860733397
            ],
            [
              105.80322095466947,
              21.020276435198717
            ],
            [
              105.80317832653468,
              21.020356490093683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M38_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7985",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020229186650244,
          "to_latitude": 21.020356490093683,
          "to_longitude": 105.80317832653468,
          "from_longitude": 105.80324958575825
        }
      },
      {
        "id": 7978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8030059638767,
            21.019924032845232,
            105.80347793079866,
            21.02034766143931
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8030059638767,
              21.019924032845232
            ],
            [
              105.80301533007449,
              21.019930765560083
            ],
            [
              105.80307691210004,
              21.019970569187045
            ],
            [
              105.80316209777452,
              21.020022360101393
            ],
            [
              105.80323992685233,
              21.020069215238866
            ],
            [
              105.80331695548625,
              21.0201185598066
            ],
            [
              105.80324958575825,
              21.020229186650244
            ],
            [
              105.80328304126495,
              21.02024948951718
            ],
            [
              105.80337849502845,
              21.02029836407114
            ],
            [
              105.80347793079866,
              21.02034766143931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M38_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.7986",
          "diaChiLapD": "Ngõ 102, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019924032845232,
          "to_latitude": 21.02034766143931,
          "to_longitude": 105.80347793079866,
          "from_longitude": 105.8030059638767
        }
      },
      {
        "id": 7979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80254786327595,
            21.02040486941328,
            105.80284689090084,
            21.020608818324902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80254786327595,
              21.02040486941328
            ],
            [
              105.80259987772608,
              21.020439305295678
            ],
            [
              105.80261353206126,
              21.020445073020415
            ],
            [
              105.80267981663538,
              21.02047307287171
            ],
            [
              105.80276844879599,
              21.02051256469533
            ],
            [
              105.80279064921709,
              21.020523883965673
            ],
            [
              105.80281785981242,
              21.02053775830632
            ],
            [
              105.80284689090084,
              21.020559799179313
            ],
            [
              105.80282775801713,
              21.020608818324902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M31_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7987",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02040486941328,
          "to_latitude": 21.020608818324902,
          "to_longitude": 105.80282775801713,
          "from_longitude": 105.80254786327595
        }
      },
      {
        "id": 7980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8025361367522,
            21.020411866058893,
            105.80280866172163,
            21.02055129145028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8025361367522,
              21.020411866058893
            ],
            [
              105.80260446029446,
              21.02045183585289
            ],
            [
              105.80265167189496,
              21.020475176529192
            ],
            [
              105.8026784043987,
              21.020487618820468
            ],
            [
              105.80270290823358,
              21.020499023878482
            ],
            [
              105.8027606762481,
              21.02052589962435
            ],
            [
              105.80280866172163,
              21.02055129145028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M32_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7988",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020411866058893,
          "to_latitude": 21.02055129145028,
          "to_longitude": 105.80280866172163,
          "from_longitude": 105.8025361367522
        }
      },
      {
        "id": 7981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80261056200426,
            21.020445073020415,
            105.80261353206126,
            21.02045038176585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80261056200426,
              21.02045038176585
            ],
            [
              105.80261131666016,
              21.020449211232517
            ],
            [
              105.80261353206126,
              21.020445073020415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M31_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7989",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02045038176585,
          "to_latitude": 21.020445073020415,
          "to_longitude": 105.80261353206126,
          "from_longitude": 105.80261056200426
        }
      },
      {
        "id": 7982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8026784043987,
            21.02048092278056,
            105.8026809774288,
            21.020487618820468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8026809774288,
              21.02048092278056
            ],
            [
              105.80268085713725,
              21.020481453543898
            ],
            [
              105.8026784043987,
              21.020487618820468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M32_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7990",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02048092278056,
          "to_latitude": 21.020487618820468,
          "to_longitude": 105.8026784043987,
          "from_longitude": 105.8026809774288
        }
      },
      {
        "id": 7983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80281248960995,
            21.020623921029188,
            105.80298946467782,
            21.020707038122165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80281248960995,
              21.020623921029188
            ],
            [
              105.80290684987412,
              21.02066402342121
            ],
            [
              105.80292566564131,
              21.020673819546527
            ],
            [
              105.80298946467782,
              21.020707038122165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M34_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7991",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020623921029188,
          "to_latitude": 21.020707038122165,
          "to_longitude": 105.80298946467782,
          "from_longitude": 105.80281248960995
        }
      },
      {
        "id": 7984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80292566564131,
            21.020666033394388,
            105.80293128132384,
            21.020673819546527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80293128132384,
              21.020666033394388
            ],
            [
              105.80293074041934,
              21.020666700724533
            ],
            [
              105.80292566564131,
              21.020673819546527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M34_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7992",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020666033394388,
          "to_latitude": 21.020673819546527,
          "to_longitude": 105.80292566564131,
          "from_longitude": 105.80293128132384
        }
      },
      {
        "id": 7985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80281778904444,
            21.020613101066782,
            105.80303488388141,
            21.020715219218307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80281778904444,
              21.02062013226018
            ],
            [
              105.80281843442403,
              21.02061903450008
            ],
            [
              105.80282228711056,
              21.020613101066782
            ],
            [
              105.80289871769126,
              21.020647020578323
            ],
            [
              105.80291090022325,
              21.020652426707894
            ],
            [
              105.80303488388141,
              21.020715219218307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M33_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7993",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02062013226018,
          "to_latitude": 21.020715219218307,
          "to_longitude": 105.80303488388141,
          "from_longitude": 105.80281778904444
        }
      },
      {
        "id": 7986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80304070837369,
            21.020715297148122,
            105.80306314519778,
            21.020724869823084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80306314519778,
              21.020715297148122
            ],
            [
              105.80304731979308,
              21.020721951888703
            ],
            [
              105.80304070837369,
              21.020724869823084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M35_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.7994",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020715297148122,
          "to_latitude": 21.020724869823084,
          "to_longitude": 105.80304070837369,
          "from_longitude": 105.80306314519778
        }
      },
      {
        "id": 7987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80203666709703,
            21.021333684220703,
            105.80245541369308,
            21.021474797883467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80203666709703,
              21.021333684220703
            ],
            [
              105.80217943457261,
              21.02138802125423
            ],
            [
              105.80220040417076,
              21.021396002647545
            ],
            [
              105.80245541369308,
              21.021474797883467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M17_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7995",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021333684220703,
          "to_latitude": 21.021474797883467,
          "to_longitude": 105.80245541369308,
          "from_longitude": 105.80203666709703
        }
      },
      {
        "id": 7988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80217531218422,
            21.02138802125423,
            105.80217943457261,
            21.02139407788159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80217943457261,
              21.02138802125423
            ],
            [
              105.80217672889758,
              21.021392047063003
            ],
            [
              105.80217531218422,
              21.02139407788159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M17_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7996",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02138802125423,
          "to_latitude": 21.02139407788159,
          "to_longitude": 105.80217531218422,
          "from_longitude": 105.80217943457261
        }
      },
      {
        "id": 7989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80238038753373,
            21.021471785916006,
            105.80240729377074,
            21.021536209228934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80240729377074,
              21.021471785916006
            ],
            [
              105.80240559452916,
              21.02147494981571
            ],
            [
              105.8024027836311,
              21.02148178245804
            ],
            [
              105.80238038753373,
              21.021536209228934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M20_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7997",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021471785916006,
          "to_latitude": 21.021536209228934,
          "to_longitude": 105.80238038753373,
          "from_longitude": 105.80240729377074
        }
      },
      {
        "id": 7990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80251673573038,
            21.021004337019086,
            105.80265580687755,
            21.021073363156386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80265580687755,
              21.021073363156386
            ],
            [
              105.8025880462903,
              21.02104193438469
            ],
            [
              105.80251673573038,
              21.021004337019086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M18_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7998",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021073363156386,
          "to_latitude": 21.021004337019086,
          "to_longitude": 105.80251673573038,
          "from_longitude": 105.80265580687755
        }
      },
      {
        "id": 7991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80266212061719,
            21.02105915506214,
            105.80275813305343,
            21.02107629143862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80266212061719,
              21.02107629143862
            ],
            [
              105.80267842877302,
              21.0210733702113
            ],
            [
              105.80269281023634,
              21.02105915506214
            ],
            [
              105.80275813305343,
              21.021076000916008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M19_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.7999",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02107629143862,
          "to_latitude": 21.021076000916008,
          "to_longitude": 105.80275813305343,
          "from_longitude": 105.80266212061719
        }
      },
      {
        "id": 7992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80248350055707,
            21.02107629143862,
            105.80266212061719,
            21.021495817198684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80248350055707,
              21.021495817198684
            ],
            [
              105.80248567491265,
              21.02148849981446
            ],
            [
              105.80250609441549,
              21.02144527698134
            ],
            [
              105.80259701494593,
              21.021217917024085
            ],
            [
              105.80266212061719,
              21.02107629143862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M19_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8000",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021495817198684,
          "to_latitude": 21.02107629143862,
          "to_longitude": 105.80266212061719,
          "from_longitude": 105.80248350055707
        }
      },
      {
        "id": 7993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80246999243303,
            21.021073363156386,
            105.80265580687755,
            21.021491682718842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80246999243303,
              21.021491682718842
            ],
            [
              105.80247330986816,
              21.021484231683253
            ],
            [
              105.80254362084717,
              21.021323518926838
            ],
            [
              105.80265580687755,
              21.021073363156386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M18_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8001",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021491682718842,
          "to_latitude": 21.021073363156386,
          "to_longitude": 105.80265580687755,
          "from_longitude": 105.80246999243303
        }
      },
      {
        "id": 7994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80253230865884,
            21.02150258504012,
            105.80253596661382,
            21.021510758460526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80253230865884,
              21.021510758460526
            ],
            [
              105.80253368679821,
              21.021508171431798
            ],
            [
              105.80253596661382,
              21.02150258504012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M21_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8002",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021510758460526,
          "to_latitude": 21.02150258504012,
          "to_longitude": 105.80253596661382,
          "from_longitude": 105.80253230865884
        }
      },
      {
        "id": 7995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80249759758914,
            21.021490817249955,
            105.8031692791814,
            21.021711953045475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80249759758914,
              21.021490817249955
            ],
            [
              105.80253596661382,
              21.02150258504012
            ],
            [
              105.80277058356668,
              21.021587510484533
            ],
            [
              105.80280693895034,
              21.021603721668583
            ],
            [
              105.80280878458842,
              21.02160454483157
            ],
            [
              105.80286941483611,
              21.021631579532183
            ],
            [
              105.80295766527031,
              21.021665576883425
            ],
            [
              105.80306968474007,
              21.02169784608787
            ],
            [
              105.8031692791814,
              21.021711953045475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M21_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8003",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021490817249955,
          "to_latitude": 21.021711953045475,
          "to_longitude": 105.8031692791814,
          "from_longitude": 105.80249759758914
        }
      },
      {
        "id": 7996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80280346136793,
            21.021043269975106,
            105.80300090032587,
            21.021609467265886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80300090032587,
              21.021043269975106
            ],
            [
              105.80292909698075,
              21.021256802675865
            ],
            [
              105.80290098013317,
              21.021339240366647
            ],
            [
              105.80290513517464,
              21.021402168611154
            ],
            [
              105.80286465081998,
              21.021513484916333
            ],
            [
              105.80282119707552,
              21.021595570468897
            ],
            [
              105.8028107143855,
              21.021602759974282
            ],
            [
              105.80280878458842,
              21.02160454483157
            ],
            [
              105.80280346136793,
              21.021609467265886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M22_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8004",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021043269975106,
          "to_latitude": 21.021609467265886,
          "to_longitude": 105.80280346136793,
          "from_longitude": 105.80300090032587
        }
      },
      {
        "id": 7997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80286487424621,
            21.02163627296692,
            105.80314077639844,
            21.021720708957442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80286859534702,
              21.02163627296692
            ],
            [
              105.80286487424621,
              21.021641992618008
            ],
            [
              105.80286632529386,
              21.021642602674333
            ],
            [
              105.80292853713247,
              21.021668751948663
            ],
            [
              105.80297918919106,
              21.021685800640753
            ],
            [
              105.80305331325535,
              21.021706556449498
            ],
            [
              105.80314077639844,
              21.021720708957442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M25_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8005",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02163627296692,
          "to_latitude": 21.021720708957442,
          "to_longitude": 105.80314077639844,
          "from_longitude": 105.80286859534702
        }
      },
      {
        "id": 7998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8026139566461,
            21.02161897983903,
            105.80284217298355,
            21.02225909972861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8026139566461,
              21.02225909972861
            ],
            [
              105.80265847375804,
              21.022135516661958
            ],
            [
              105.80267368109766,
              21.022064049137267
            ],
            [
              105.80272942314055,
              21.021897993818914
            ],
            [
              105.8027787395161,
              21.021779466026324
            ],
            [
              105.80283812904679,
              21.021640876015983
            ],
            [
              105.80284147070938,
              21.021624657212513
            ],
            [
              105.80284217298355,
              21.02162124872323
            ],
            [
              105.80283604360007,
              21.02161897983903
            ],
            [
              105.80283497013033,
              21.021621873433208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_M26_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DODV.8006",
          "diaChiLapD": "Ngõ 898, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225909972861,
          "to_latitude": 21.021621873433208,
          "to_longitude": 105.80283497013033,
          "from_longitude": 105.8026139566461
        }
      },
      {
        "id": 7999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80176493804471,
            21.021698493659144,
            105.80232402463433,
            21.021797171492693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80176493804471,
              21.021717120786004
            ],
            [
              105.80189053869128,
              21.021763082035015
            ],
            [
              105.80194040793549,
              21.021721551267635
            ],
            [
              105.80196042324326,
              21.02170272482259
            ],
            [
              105.8019649219266,
              21.021698493659144
            ],
            [
              105.8020560489616,
              21.021718902381227
            ],
            [
              105.80208612541529,
              21.021745717435344
            ],
            [
              105.80222679419701,
              21.021777746417026
            ],
            [
              105.80227875779974,
              21.021785340450233
            ],
            [
              105.80232402463433,
              21.021797171492693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M11_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8007",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021717120786004,
          "to_latitude": 21.021797171492693,
          "to_longitude": 105.80232402463433,
          "from_longitude": 105.80176493804471
        }
      },
      {
        "id": 8000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80189643032666,
            21.021676345123392,
            105.80222566332974,
            21.021766138027225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80189643032666,
              21.021741402042267
            ],
            [
              105.80195869488888,
              21.021676345123392
            ],
            [
              105.80207546680487,
              21.021713553116946
            ],
            [
              105.80209594519748,
              21.021735442958242
            ],
            [
              105.80218923474092,
              21.021760334976186
            ],
            [
              105.80222566332974,
              21.021766138027225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M14_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8008",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021741402042267,
          "to_latitude": 21.021766138027225,
          "to_longitude": 105.80222566332974,
          "from_longitude": 105.80189643032666
        }
      },
      {
        "id": 8001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80223289858624,
            21.02176623143827,
            105.80239118523863,
            21.022085717595868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80223289858624,
              21.021772826638355
            ],
            [
              105.80223421673033,
              21.02176963292475
            ],
            [
              105.80223611576179,
              21.02176623143827
            ],
            [
              105.80231750886213,
              21.021784536242453
            ],
            [
              105.80236953922031,
              21.02180460443361
            ],
            [
              105.80238846898185,
              21.021822199461923
            ],
            [
              105.80239118523863,
              21.021901766573784
            ],
            [
              105.8023622393225,
              21.02205747895965
            ],
            [
              105.80235399038575,
              21.022085717595868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M13_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8009",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021772826638355,
          "to_latitude": 21.022085717595868,
          "to_longitude": 105.80235399038575,
          "from_longitude": 105.80223289858624
        }
      },
      {
        "id": 8002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80238846898185,
            21.021822199461923,
            105.80254615069074,
            21.0218371798503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80238846898185,
              21.021822199461923
            ],
            [
              105.80246077728694,
              21.021826685192256
            ],
            [
              105.80254615069074,
              21.0218371798503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M13_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8010",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021822199461923,
          "to_latitude": 21.0218371798503,
          "to_longitude": 105.80254615069074,
          "from_longitude": 105.80238846898185
        }
      },
      {
        "id": 8003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8019503291481,
            21.021693367086993,
            105.80196042324326,
            21.02170272482259
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8019503291481,
              21.021693367086993
            ],
            [
              105.80195691773719,
              21.02169983837133
            ],
            [
              105.80196042324326,
              21.02170272482259
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M11_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8011",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021693367086993,
          "to_latitude": 21.02170272482259,
          "to_longitude": 105.80196042324326,
          "from_longitude": 105.8019503291481
        }
      },
      {
        "id": 8004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80209205278189,
            21.021735442958242,
            105.80209594519748,
            21.021743131538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80209594519748,
              21.021735442958242
            ],
            [
              105.80209249363256,
              21.021741921841347
            ],
            [
              105.80209205278189,
              21.021743131538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M14_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8012",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021735442958242,
          "to_latitude": 21.021743131538,
          "to_longitude": 105.80209205278189,
          "from_longitude": 105.80209594519748
        }
      },
      {
        "id": 8005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80193512981045,
            21.022136451546757,
            105.80206088843248,
            21.022210107743383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80193512981045,
              21.022180634378103
            ],
            [
              105.80202207962394,
              21.022210107743383
            ],
            [
              105.80206088843248,
              21.022136451546757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M06_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8013",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022180634378103,
          "to_latitude": 21.022136451546757,
          "to_longitude": 105.80206088843248,
          "from_longitude": 105.80193512981045
        }
      },
      {
        "id": 8006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80150941986277,
            21.022153992378037,
            105.80189386750072,
            21.022288399024767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80150941986277,
              21.022153992378037
            ],
            [
              105.80173132591764,
              21.022241668050036
            ],
            [
              105.8017760898592,
              21.02225748033121
            ],
            [
              105.8017822312287,
              21.022259649841423
            ],
            [
              105.80187093061458,
              21.02228277165576
            ],
            [
              105.80189386750072,
              21.022288399024767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M05_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8014",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022153992378037,
          "to_latitude": 21.022288399024767,
          "to_longitude": 105.80189386750072,
          "from_longitude": 105.80150941986277
        }
      },
      {
        "id": 8007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80193123790119,
            21.0221267254164,
            105.80193928442282,
            21.022129874071556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80193123790119,
              21.0221267254164
            ],
            [
              105.80193697181652,
              21.02212897542319
            ],
            [
              105.80193928442282,
              21.022129874071556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M06_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8015",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0221267254164,
          "to_latitude": 21.022129874071556,
          "to_longitude": 105.80193928442282,
          "from_longitude": 105.80193123790119
        }
      },
      {
        "id": 8008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80207367936694,
            21.02214156041113,
            105.80232376404945,
            21.022359555828768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80207367936694,
              21.02214156041113
            ],
            [
              105.80217351962807,
              21.022181435920185
            ],
            [
              105.80214243249117,
              21.02230917632467
            ],
            [
              105.80232376404945,
              21.022359555828768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M07_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8016",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02214156041113,
          "to_latitude": 21.022359555828768,
          "to_longitude": 105.80232376404945,
          "from_longitude": 105.80207367936694
        }
      },
      {
        "id": 8009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80202094389142,
            21.02214156041113,
            105.80207367936694,
            21.022216292090995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80207367936694,
              21.02214156041113
            ],
            [
              105.80202094389142,
              21.022216292090995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M07_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8017",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02214156041113,
          "to_latitude": 21.022216292090995,
          "to_longitude": 105.80202094389142,
          "from_longitude": 105.80207367936694
        }
      },
      {
        "id": 8010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8017442667342,
            21.022234993691526,
            105.80174712257813,
            21.02224080498297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80174712257813,
              21.022234993691526
            ],
            [
              105.80174686600326,
              21.02223540496712
            ],
            [
              105.8017442667342,
              21.02224080498297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M04_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8018",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022234993691526,
          "to_latitude": 21.02224080498297,
          "to_longitude": 105.8017442667342,
          "from_longitude": 105.80174712257813
        }
      },
      {
        "id": 8011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8017760898592,
            21.022251764927205,
            105.80177853159937,
            21.02225748033121
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80177853159937,
              21.022251764927205
            ],
            [
              105.80177664568936,
              21.02225636402919
            ],
            [
              105.8017760898592,
              21.02225748033121
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M05_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.8019",
          "diaChiLapD": "Ngõ 185, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022251764927205,
          "to_latitude": 21.02225748033121,
          "to_longitude": 105.8017760898592,
          "from_longitude": 105.80177853159937
        }
      },
      {
        "id": 8012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82980730036722,
            21.01748076917367,
            105.82981623726799,
            21.017491343502748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981255699461,
              21.017483821723957
            ],
            [
              105.82981623726799,
              21.017490228412782
            ],
            [
              105.82981391071014,
              21.017491343502748
            ],
            [
              105.82981005960683,
              21.017485182421183
            ],
            [
              105.82980730036722,
              21.01748076917367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.8020",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017483821723957,
          "to_latitude": 21.01748076917367,
          "to_longitude": 105.82980730036722,
          "from_longitude": 105.82981255699461
        }
      },
      {
        "id": 8013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83031717760278,
            21.017183605278372,
            105.83069732375424,
            21.017477612908156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83031717760278,
              21.017477612908156
            ],
            [
              105.83053684926224,
              21.01730771696971
            ],
            [
              105.83069732375424,
              21.017183605278372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.1_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.8021",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017477612908156,
          "to_latitude": 21.017183605278372,
          "to_longitude": 105.83069732375424,
          "from_longitude": 105.83031717760278
        }
      },
      {
        "id": 8014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83053684926224,
            21.01730771696971,
            105.83056916125689,
            21.01733675416011
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83053684926224,
              21.01730771696971
            ],
            [
              105.83055997767956,
              21.01732861577769
            ],
            [
              105.83056916125689,
              21.01733675416011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.1_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.8022",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01730771696971,
          "to_latitude": 21.01733675416011,
          "to_longitude": 105.83056916125689,
          "from_longitude": 105.83053684926224
        }
      },
      {
        "id": 8015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83069304203786,
            21.016879087989864,
            105.8310644902875,
            21.017155973950043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83069304203786,
              21.017155973950043
            ],
            [
              105.83070169614662,
              21.017150494276642
            ],
            [
              105.83072996361504,
              21.017154085658756
            ],
            [
              105.8310644902875,
              21.016879087989864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_176_Xã Đàn",
          "maTaiSan": "04.O16B.DODV.8023",
          "diaChiLapD": "Xã Đàn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017155973950043,
          "to_latitude": 21.016879087989864,
          "to_longitude": 105.8310644902875,
          "from_longitude": 105.83069304203786
        }
      },
      {
        "id": 8016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035834395406,
            21.01662236820095,
            105.83038466513845,
            21.01663360813349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83035834395406,
              21.016623399943356
            ],
            [
              105.8303638513143,
              21.01662570067347
            ],
            [
              105.83036954572181,
              21.016628079071
            ],
            [
              105.83038278285915,
              21.01663360813349
            ],
            [
              105.83038466513845,
              21.016630610173717
            ],
            [
              105.83037108909488,
              21.016624679023924
            ],
            [
              105.83036579907802,
              21.01662236820095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_156_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.8024",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016623399943356,
          "to_latitude": 21.01662236820095,
          "to_longitude": 105.83036579907802,
          "from_longitude": 105.83035834395406
        }
      },
      {
        "id": 8017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83121790454396,
            21.015307753371314,
            105.8312328925869,
            21.01531536883564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83122899054139,
              21.015313577769778
            ],
            [
              105.8312328925869,
              21.01531053199744
            ],
            [
              105.8312309215745,
              21.015307753371314
            ],
            [
              105.83122648891835,
              21.01531034651058
            ],
            [
              105.83121790454396,
              21.01531536883564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_170_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.8025",
          "diaChiLapD": "Ngách 108, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015313577769778,
          "to_latitude": 21.01531536883564,
          "to_longitude": 105.83121790454396,
          "from_longitude": 105.83122899054139
        }
      },
      {
        "id": 8018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153367232673,
            21.0151421289558,
            105.83171883074696,
            21.015322186880518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83153367232673,
              21.015316765776674
            ],
            [
              105.83153612257742,
              21.01531905792812
            ],
            [
              105.8315401511931,
              21.015322186880518
            ],
            [
              105.83160365678093,
              21.015252710204965
            ],
            [
              105.83165223193926,
              21.015204539507977
            ],
            [
              105.83171883074696,
              21.0151421289558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_194_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.8026",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015316765776674,
          "to_latitude": 21.0151421289558,
          "to_longitude": 105.83171883074696,
          "from_longitude": 105.83153367232673
        }
      },
      {
        "id": 8019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83158858813181,
            21.01501294203964,
            105.8316023274908,
            21.015024381704436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83158858813181,
              21.015024381704436
            ],
            [
              105.8315921842428,
              21.015022372515578
            ],
            [
              105.8316023274908,
              21.01501294203964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_195_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.8027",
          "diaChiLapD": "Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015024381704436,
          "to_latitude": 21.01501294203964,
          "to_longitude": 105.8316023274908,
          "from_longitude": 105.83158858813181
        }
      },
      {
        "id": 8020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280771754548,
            21.013522249606833,
            105.83282729169679,
            21.013532833033956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281852862176,
              21.013522599527253
            ],
            [
              105.83282729169679,
              21.013529694821287
            ],
            [
              105.83282407128488,
              21.013532833033956
            ],
            [
              105.8328147977282,
              21.013526831334392
            ],
            [
              105.83280771754548,
              21.013522249606833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_240_Nam Đồng",
          "maTaiSan": "04.O16B.DODV.8028",
          "diaChiLapD": "Nam Đồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013522599527253,
          "to_latitude": 21.013522249606833,
          "to_longitude": 105.83280771754548,
          "from_longitude": 105.83281852862176
        }
      },
      {
        "id": 8021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355961577662,
            21.012191366896282,
            105.8335756904146,
            21.012206615877126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355961577662,
              21.012191366896282
            ],
            [
              105.83356086128832,
              21.01219254853563
            ],
            [
              105.83356848038706,
              21.012199778559225
            ],
            [
              105.8335756904146,
              21.012206615877126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_275_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8029",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012191366896282,
          "to_latitude": 21.012206615877126,
          "to_longitude": 105.8335756904146,
          "from_longitude": 105.83355961577662
        }
      },
      {
        "id": 8022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387321126278,
            21.012728701174893,
            105.83388103959463,
            21.012742287419503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388103959463,
              21.012728701174893
            ],
            [
              105.83387681450539,
              21.012735215166224
            ],
            [
              105.83387321126278,
              21.012740771401013
            ],
            [
              105.83387718279278,
              21.012742287419503
            ],
            [
              105.83387987309835,
              21.012737063529514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_273_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8030",
          "diaChiLapD": "Ngõ 6, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012728701174893,
          "to_latitude": 21.012737063529514,
          "to_longitude": 105.83387987309835,
          "from_longitude": 105.83388103959463
        }
      },
      {
        "id": 8023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555673202886,
            21.01066485714802,
            105.83557515914283,
            21.010681454971884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557515914283,
              21.01066485714802
            ],
            [
              105.83555742711326,
              21.010680869881885
            ],
            [
              105.83555673202886,
              21.010681454971884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_328_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8031",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01066485714802,
          "to_latitude": 21.010681454971884,
          "to_longitude": 105.83555673202886,
          "from_longitude": 105.83557515914283
        }
      },
      {
        "id": 8024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83482708914208,
            21.010700739719173,
            105.83498779188646,
            21.010915760552262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83482708914208,
              21.010915760552262
            ],
            [
              105.83485148919257,
              21.010877790292597
            ],
            [
              105.83488093175244,
              21.010836055034105
            ],
            [
              105.83489501904862,
              21.010813444128107
            ],
            [
              105.83493974586686,
              21.01076005723167
            ],
            [
              105.83498375270476,
              21.01070572637396
            ],
            [
              105.83498754349202,
              21.01070104532489
            ],
            [
              105.83498779188646,
              21.010700739719173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_326_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8032",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010915760552262,
          "to_latitude": 21.010700739719173,
          "to_longitude": 105.83498779188646,
          "from_longitude": 105.83482708914208
        }
      },
      {
        "id": 8025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291256183426,
            21.00818755999537,
            105.82918463692178,
            21.00823045157921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291256183426,
              21.00823045157921
            ],
            [
              105.82918463692178,
              21.008201535363398
            ],
            [
              105.82917698481195,
              21.00818755999537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8033",
          "diaChiLapD": "Ngõ 82, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00823045157921,
          "to_latitude": 21.00818755999537,
          "to_longitude": 105.82917698481195,
          "from_longitude": 105.8291256183426
        }
      },
      {
        "id": 8026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82954390541678,
            21.01550201000445,
            105.829551056273,
            21.015508310375694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82954390541678,
              21.015508310375694
            ],
            [
              105.82954680257083,
              21.01550653495966
            ],
            [
              105.829551056273,
              21.015503929187446
            ],
            [
              105.82954953926605,
              21.01550201000445
            ],
            [
              105.82954498579385,
              21.01550520162516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_138_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.8034",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015508310375694,
          "to_latitude": 21.01550520162516,
          "to_longitude": 105.82954498579385,
          "from_longitude": 105.82954390541678
        }
      },
      {
        "id": 8027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82942156830966,
            21.013436614815504,
            105.82945278834455,
            21.013460444570025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82942692857121,
              21.013460444570025
            ],
            [
              105.82942156830966,
              21.0134523651532
            ],
            [
              105.82944637292002,
              21.013436614815504
            ],
            [
              105.82945048813762,
              21.013442897462674
            ],
            [
              105.82945278834455,
              21.01344607373258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DODV.8035",
          "diaChiLapD": "Trần Hữu Tước",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013460444570025,
          "to_latitude": 21.01344607373258,
          "to_longitude": 105.82945278834455,
          "from_longitude": 105.82942692857121
        }
      },
      {
        "id": 8028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290085981006,
            21.01180622228942,
            105.82905059895477,
            21.01186499156522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290085981006,
              21.011811923034983
            ],
            [
              105.82901926499707,
              21.01180622228942
            ],
            [
              105.82905059895477,
              21.01186499156522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT18_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DODV.8036",
          "diaChiLapD": "Ngõ 119, Hồ Đắc Di",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011811923034983,
          "to_latitude": 21.01186499156522,
          "to_longitude": 105.82905059895477,
          "from_longitude": 105.8290085981006
        }
      },
      {
        "id": 8029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164988311165,
            21.007215066400747,
            105.8323269828592,
            21.008046501552858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323269828592,
              21.00723361825032
            ],
            [
              105.83231030928046,
              21.00724986663504
            ],
            [
              105.83226650640607,
              21.007215066400747
            ],
            [
              105.83220076843018,
              21.00726987497211
            ],
            [
              105.83208814643983,
              21.00742619328099
            ],
            [
              105.83180284454619,
              21.00786412970632
            ],
            [
              105.83164988311165,
              21.008046501552858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1A_Ngõ 80, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8037",
          "diaChiLapD": "Ngõ 80, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 121,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00723361825032,
          "to_latitude": 21.008046501552858,
          "to_longitude": 105.83164988311165,
          "from_longitude": 105.8323269828592
        }
      },
      {
        "id": 8030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83232760742128,
            21.007264714164524,
            105.8326727525996,
            21.007548007973142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83232760742128,
              21.007264714164524
            ],
            [
              105.83259770504782,
              21.007486409348637
            ],
            [
              105.8326727525996,
              21.007548007973142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2A_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8038",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007264714164524,
          "to_latitude": 21.007548007973142,
          "to_longitude": 105.8326727525996,
          "from_longitude": 105.83232760742128
        }
      },
      {
        "id": 8031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83259770504782,
            21.007470657551465,
            105.83261422651181,
            21.007486409348637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83259770504782,
              21.007486409348637
            ],
            [
              105.83260532274278,
              21.007481130660647
            ],
            [
              105.83261422651181,
              21.007470657551465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2A_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8039",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007486409348637,
          "to_latitude": 21.007470657551465,
          "to_longitude": 105.83261422651181,
          "from_longitude": 105.83259770504782
        }
      },
      {
        "id": 8032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83261567351302,
            21.00764060504308,
            105.83263371534927,
            21.007651826964835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263371534927,
              21.007651826964835
            ],
            [
              105.83262875137,
              21.007648763122845
            ],
            [
              105.83261567351302,
              21.00764060504308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8040",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007651826964835,
          "to_latitude": 21.00764060504308,
          "to_longitude": 105.83261567351302,
          "from_longitude": 105.83263371534927
        }
      },
      {
        "id": 8033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208372773876,
            21.00752919986267,
            105.83268154769891,
            21.007724937865927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83268154769891,
              21.007564407261373
            ],
            [
              105.83261567351302,
              21.00764060504308
            ],
            [
              105.83255284463601,
              21.00770519499988
            ],
            [
              105.83233103379334,
              21.00752919986267
            ],
            [
              105.83229141523996,
              21.007544210141734
            ],
            [
              105.83208372773876,
              21.007724937865927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8041",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007564407261373,
          "to_latitude": 21.007724937865927,
          "to_longitude": 105.83208372773876,
          "from_longitude": 105.83268154769891
        }
      },
      {
        "id": 8034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8324850970925,
            21.00777507209211,
            105.83250731299988,
            21.00779330793037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83250731299988,
              21.00779330793037
            ],
            [
              105.83250023421886,
              21.007787540236762
            ],
            [
              105.8324850970925,
              21.00777507209211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8042",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00779330793037,
          "to_latitude": 21.00777507209211,
          "to_longitude": 105.8324850970925,
          "from_longitude": 105.83250731299988
        }
      },
      {
        "id": 8035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225638037055,
            21.007718407978402,
            105.83253924569354,
            21.008018515408185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83253924569354,
              21.007718407978402
            ],
            [
              105.8324850970925,
              21.00777507209211
            ],
            [
              105.83225638037055,
              21.008018515408185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8043",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007718407978402,
          "to_latitude": 21.008018515408185,
          "to_longitude": 105.83225638037055,
          "from_longitude": 105.83253924569354
        }
      },
      {
        "id": 8036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83185758523216,
            21.007881847758537,
            105.8322580451609,
            21.00811392653329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8322580451609,
              21.008052117200574
            ],
            [
              105.83225529603425,
              21.00804707890342
            ],
            [
              105.83215404504945,
              21.007959884363427
            ],
            [
              105.83205922035246,
              21.007881847758537
            ],
            [
              105.83185758523216,
              21.00811392653329
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5A_Ngõ 29, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8044",
          "diaChiLapD": "Ngõ 29, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008052117200574,
          "to_latitude": 21.00811392653329,
          "to_longitude": 105.83185758523216,
          "from_longitude": 105.8322580451609
        }
      },
      {
        "id": 8037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83203046845482,
            21.008043738849757,
            105.83223385625436,
            21.008265549491977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83223385625436,
              21.008043738849757
            ],
            [
              105.83217543150076,
              21.00810745539397
            ],
            [
              105.83203046845482,
              21.008265549491977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8045",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008043738849757,
          "to_latitude": 21.008265549491977,
          "to_longitude": 105.83203046845482,
          "from_longitude": 105.83223385625436
        }
      },
      {
        "id": 8038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217543150076,
            21.00810745539397,
            105.83219112001316,
            21.008122390288392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217543150076,
              21.00810745539397
            ],
            [
              105.83218740087769,
              21.00811900963679
            ],
            [
              105.83219112001316,
              21.008122390288392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8046",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00810745539397,
          "to_latitude": 21.008122390288392,
          "to_longitude": 105.83219112001316,
          "from_longitude": 105.83217543150076
        }
      },
      {
        "id": 8039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83159925178565,
            21.008079274554337,
            105.83203035668082,
            21.00829673932789
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203035668082,
              21.00829673932789
            ],
            [
              105.8320270152599,
              21.00829417063257
            ],
            [
              105.83191930768898,
              21.008206220813822
            ],
            [
              105.83176383983515,
              21.008079274554337
            ],
            [
              105.83159925178565,
              21.00825349015194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7A_Ngõ 47, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8047",
          "diaChiLapD": "Ngõ 47, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00829673932789,
          "to_latitude": 21.00825349015194,
          "to_longitude": 105.83159925178565,
          "from_longitude": 105.83203035668082
        }
      },
      {
        "id": 8040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83183969763414,
            21.008206220813822,
            105.83191930768898,
            21.00828911784355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83191930768898,
              21.008206220813822
            ],
            [
              105.83183969763414,
              21.00828911784355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7A_Ngõ 47, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8048",
          "diaChiLapD": "Ngõ 47, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008206220813822,
          "to_latitude": 21.00828911784355,
          "to_longitude": 105.83183969763414,
          "from_longitude": 105.83191930768898
        }
      },
      {
        "id": 8041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177940655959,
            21.008296682148703,
            105.83200325607305,
            21.00854631434782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200325607305,
              21.008296682148703
            ],
            [
              105.8318903746541,
              21.00841852095994
            ],
            [
              105.83186980553603,
              21.008440721637545
            ],
            [
              105.83177940655959,
              21.00854631434782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8049",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008296682148703,
          "to_latitude": 21.00854631434782,
          "to_longitude": 105.83177940655959,
          "from_longitude": 105.83200325607305
        }
      },
      {
        "id": 8042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318903746541,
            21.00841852095994,
            105.8319103488731,
            21.008435103187296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318903746541,
              21.00841852095994
            ],
            [
              105.83189891758886,
              21.008425529778908
            ],
            [
              105.8319103488731,
              21.008435103187296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8050",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00841852095994,
          "to_latitude": 21.008435103187296,
          "to_longitude": 105.8319103488731,
          "from_longitude": 105.8318903746541
        }
      },
      {
        "id": 8043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83139506864411,
            21.00828614767731,
            105.83178191014846,
            21.008583756174804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178191014846,
              21.008583756174804
            ],
            [
              105.8317661220703,
              21.00857532856892
            ],
            [
              105.83148076003913,
              21.008349067727355
            ],
            [
              105.83139506864411,
              21.00828614767731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8051",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008583756174804,
          "to_latitude": 21.00828614767731,
          "to_longitude": 105.83139506864411,
          "from_longitude": 105.83178191014846
        }
      },
      {
        "id": 8044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83222992521631,
            21.007796125939894,
            105.83259779119822,
            21.0081884169057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83259779119822,
              21.007796125939894
            ],
            [
              105.83237940953146,
              21.00802749782055
            ],
            [
              105.83236627040571,
              21.0080414184724
            ],
            [
              105.83236362345728,
              21.008044223289737
            ],
            [
              105.83235894730582,
              21.008049177773838
            ],
            [
              105.83222992521631,
              21.0081884169057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6.5_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8052",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007796125939894,
          "to_latitude": 21.0081884169057,
          "to_longitude": 105.83222992521631,
          "from_longitude": 105.83259779119822
        }
      },
      {
        "id": 8045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83235676522474,
            21.008011281868292,
            105.83237940953146,
            21.00802749782055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83235676522474,
              21.008011281868292
            ],
            [
              105.8323646616695,
              21.008017405955364
            ],
            [
              105.83237940953146,
              21.00802749782055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6.5_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8053",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008011281868292,
          "to_latitude": 21.00802749782055,
          "to_longitude": 105.83237940953146,
          "from_longitude": 105.83235676522474
        }
      },
      {
        "id": 8046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234447353887,
            21.008025205599402,
            105.83239963397794,
            21.008064739152843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234447353887,
              21.008025205599402
            ],
            [
              105.83235273804173,
              21.00803196104809
            ],
            [
              105.83236627040571,
              21.0080414184724
            ],
            [
              105.83239963397794,
              21.008064739152843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6.4_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8054",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008025205599402,
          "to_latitude": 21.008064739152843,
          "to_longitude": 105.83239963397794,
          "from_longitude": 105.83234447353887
        }
      },
      {
        "id": 8047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234191559262,
            21.008028102110128,
            105.83239391806269,
            21.00806796633675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234191559262,
              21.008028102110128
            ],
            [
              105.83234942582955,
              21.008033097248173
            ],
            [
              105.83236362345728,
              21.008044223289737
            ],
            [
              105.83239391806269,
              21.00806796633675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8055",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008028102110128,
          "to_latitude": 21.00806796633675,
          "to_longitude": 105.83239391806269,
          "from_longitude": 105.83234191559262
        }
      },
      {
        "id": 8048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320801341396,
            21.008314542399354,
            105.83213173152214,
            21.008352295136365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8320801341396,
              21.008314542399354
            ],
            [
              105.8320828560911,
              21.008317282122484
            ],
            [
              105.83213173152214,
              21.008352295136365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4.1_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8056",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008314542399354,
          "to_latitude": 21.008352295136365,
          "to_longitude": 105.83213173152214,
          "from_longitude": 105.8320801341396
        }
      },
      {
        "id": 8049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83228982573307,
            21.008637533744324,
            105.83233361587277,
            21.008686957460867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83233361587277,
              21.008637533744324
            ],
            [
              105.8323335967457,
              21.008637553708834
            ],
            [
              105.83232404697209,
              21.008647938738257
            ],
            [
              105.83232129333066,
              21.00865093285108
            ],
            [
              105.83228982573307,
              21.008686957460867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.1_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8057",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008637533744324,
          "to_latitude": 21.008686957460867,
          "to_longitude": 105.83228982573307,
          "from_longitude": 105.83233361587277
        }
      },
      {
        "id": 8050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323055408499,
            21.00865264696446,
            105.83235143700053,
            21.00869835156687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83235143700053,
              21.00865264696446
            ],
            [
              105.83235141690155,
              21.00865266512728
            ],
            [
              105.8323407639575,
              21.00866237813414
            ],
            [
              105.83233752337638,
              21.00866533217726
            ],
            [
              105.8323055408499,
              21.00869835156687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.2_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8058",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00865264696446,
          "to_latitude": 21.00869835156687,
          "to_longitude": 105.8323055408499,
          "from_longitude": 105.83235143700053
        }
      },
      {
        "id": 8051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83260033082342,
            21.00888400857876,
            105.83263532768154,
            21.008918801351435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263532768154,
              21.00888400857876
            ],
            [
              105.83263530758256,
              21.008884026741622
            ],
            [
              105.83262611197816,
              21.00889236515186
            ],
            [
              105.83262064515003,
              21.008897322608608
            ],
            [
              105.83260033082342,
              21.008918801351435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.4_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8059",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00888400857876,
          "to_latitude": 21.008918801351435,
          "to_longitude": 105.83260033082342,
          "from_longitude": 105.83263532768154
        }
      },
      {
        "id": 8052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326179742208,
            21.00889866645655,
            105.83265353494716,
            21.00893376999169
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265353494716,
              21.00889866645655
            ],
            [
              105.83265351484815,
              21.008898684619396
            ],
            [
              105.83264484945683,
              21.008906367404208
            ],
            [
              105.83263719101768,
              21.00891315648768
            ],
            [
              105.8326179742208,
              21.00893376999169
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.5_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8060",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00889866645655,
          "to_latitude": 21.00893376999169,
          "to_longitude": 105.8326179742208,
          "from_longitude": 105.83265353494716
        }
      },
      {
        "id": 8053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83290559022107,
            21.0091281763715,
            105.83293862538093,
            21.00916823534403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83293862538093,
              21.0091281763715
            ],
            [
              105.83293860914947,
              21.009128198128362
            ],
            [
              105.83292212020034,
              21.009150005926337
            ],
            [
              105.83290559022107,
              21.00916823534403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.5.1_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8061",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0091281763715,
          "to_latitude": 21.00916823534403,
          "to_longitude": 105.83290559022107,
          "from_longitude": 105.83293862538093
        }
      },
      {
        "id": 8054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83291364918671,
            21.00913634964075,
            105.83294877834825,
            21.00917572338075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83294877834825,
              21.00913634964075
            ],
            [
              105.83294875632052,
              21.009136366909857
            ],
            [
              105.83293323363215,
              21.00915757475362
            ],
            [
              105.83291364918671,
              21.00917572338075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.5.2_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8062",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00913634964075,
          "to_latitude": 21.00917572338075,
          "to_longitude": 105.83291364918671,
          "from_longitude": 105.83294877834825
        }
      },
      {
        "id": 8055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83214656203731,
            21.008501475252405,
            105.83291605860471,
            21.009128394311414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83291605860471,
              21.009128394311414
            ],
            [
              105.83265489384581,
              21.008913873739633
            ],
            [
              105.83264484945683,
              21.008906367404208
            ],
            [
              105.83262611197816,
              21.00889236515186
            ],
            [
              105.83251574065764,
              21.00880988244858
            ],
            [
              105.83250726155964,
              21.008804679801315
            ],
            [
              105.8325012108534,
              21.008800967338043
            ],
            [
              105.8323407639575,
              21.00866237813414
            ],
            [
              105.83232404697209,
              21.008647938738257
            ],
            [
              105.83230523456922,
              21.008631689589322
            ],
            [
              105.83214656203731,
              21.008501475252405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.3_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8063",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009128394311414,
          "to_latitude": 21.008501475252405,
          "to_longitude": 105.83214656203731,
          "from_longitude": 105.83291605860471
        }
      },
      {
        "id": 8056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83250726155964,
            21.008790629779604,
            105.83251933327881,
            21.008804679801315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83250726155964,
              21.008804679801315
            ],
            [
              105.83250964380484,
              21.008802186986266
            ],
            [
              105.83251931608045,
              21.008790650637927
            ],
            [
              105.83251933327881,
              21.008790629779604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5.3_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8064",
          "diaChiLapD": "Ngõ 4, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008804679801315,
          "to_latitude": 21.008790629779604,
          "to_longitude": 105.83251933327881,
          "from_longitude": 105.83250726155964
        }
      },
      {
        "id": 8057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83054123601624,
            21.00870493127086,
            105.83148742243345,
            21.009203496738117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83054123601624,
              21.00890216039515
            ],
            [
              105.83068226354425,
              21.008816395383697
            ],
            [
              105.83075675717623,
              21.00875676475884
            ],
            [
              105.8308984821407,
              21.00870493127086
            ],
            [
              105.83101424302468,
              21.00882806370488
            ],
            [
              105.83117446028217,
              21.008998483181287
            ],
            [
              105.83136464191004,
              21.009148588880432
            ],
            [
              105.83148054786396,
              21.00920056413495
            ],
            [
              105.83148742243345,
              21.009203496738117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8065",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 126,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00890216039515,
          "to_latitude": 21.009203496738117,
          "to_longitude": 105.83148742243345,
          "from_longitude": 105.83054123601624
        }
      },
      {
        "id": 8058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089199287824,
            21.00882806370488,
            105.83101424302468,
            21.00893173046769
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101424302468,
              21.00882806370488
            ],
            [
              105.83089199287824,
              21.00893173046769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8066",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00882806370488,
          "to_latitude": 21.00893173046769,
          "to_longitude": 105.83089199287824,
          "from_longitude": 105.83101424302468
        }
      },
      {
        "id": 8059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83127163634995,
            21.009221497573293,
            105.83145350184536,
            21.009653503867632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83145350184536,
              21.009221497573293
            ],
            [
              105.83140474160231,
              21.00934044496662
            ],
            [
              105.83136712052685,
              21.00943222128205
            ],
            [
              105.83127163634995,
              21.009653503867632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8067",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009221497573293,
          "to_latitude": 21.009653503867632,
          "to_longitude": 105.83127163634995,
          "from_longitude": 105.83145350184536
        }
      },
      {
        "id": 8060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83140474160231,
            21.00934044496662,
            105.83142619823411,
            21.009349746327292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140474160231,
              21.00934044496662
            ],
            [
              105.83141689241933,
              21.009345156439203
            ],
            [
              105.83142619823411,
              21.009349746327292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11A_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8068",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00934044496662,
          "to_latitude": 21.009349746327292,
          "to_longitude": 105.83142619823411,
          "from_longitude": 105.83140474160231
        }
      },
      {
        "id": 8061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83166211023304,
            21.00895442671714,
            105.83172849528607,
            21.008981134913373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83166211023304,
              21.00895442671714
            ],
            [
              105.83167017614228,
              21.00895762716837
            ],
            [
              105.83167577918667,
              21.008959884946204
            ],
            [
              105.83172849528607,
              21.008981134913373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.5_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8069",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00895442671714,
          "to_latitude": 21.008981134913373,
          "to_longitude": 105.83172849528607,
          "from_longitude": 105.83166211023304
        }
      },
      {
        "id": 8062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83165434531612,
            21.00897307834213,
            105.83172028875816,
            21.008992825732143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165434531612,
              21.00897307834213
            ],
            [
              105.83166209683023,
              21.00897508987864
            ],
            [
              105.83166811884068,
              21.008976924705944
            ],
            [
              105.83172028875816,
              21.008992825732143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.4_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8070",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00897307834213,
          "to_latitude": 21.008992825732143,
          "to_longitude": 105.83172028875816,
          "from_longitude": 105.83165434531612
        }
      },
      {
        "id": 8063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314889612182,
            21.008869471207124,
            105.83171642401526,
            21.009385881211607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171642401526,
              21.008869471207124
            ],
            [
              105.83167577918667,
              21.008959884946204
            ],
            [
              105.83166811884068,
              21.008976924705944
            ],
            [
              105.83165168833769,
              21.009013473648107
            ],
            [
              105.83158654855615,
              21.009160602298287
            ],
            [
              105.83157282480644,
              21.00919160032875
            ],
            [
              105.83151756135706,
              21.00932271075619
            ],
            [
              105.83151327945858,
              21.009332167678654
            ],
            [
              105.83150500169953,
              21.00935045148048
            ],
            [
              105.8314889612182,
              21.009385881211607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8071",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008869471207124,
          "to_latitude": 21.009385881211607,
          "to_longitude": 105.8314889612182,
          "from_longitude": 105.83171642401526
        }
      },
      {
        "id": 8064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314934283531,
            21.00934666841839,
            105.83155629851278,
            21.009366732561258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8314934283531,
              21.00934666841839
            ],
            [
              105.83150315257086,
              21.009349865290126
            ],
            [
              105.83150500169953,
              21.00935045148048
            ],
            [
              105.83155629851278,
              21.009366732561258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.1_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8072",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00934666841839,
          "to_latitude": 21.009366732561258,
          "to_longitude": 105.83155629851278,
          "from_longitude": 105.8314934283531
        }
      },
      {
        "id": 8065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150142839658,
            21.009326697845136,
            105.83156004167972,
            21.009353271560954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83150142839658,
              21.009326697845136
            ],
            [
              105.83151050225835,
              21.009330915829743
            ],
            [
              105.83151327945858,
              21.009332167678654
            ],
            [
              105.83156004167972,
              21.009353271560954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.2_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8073",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009326697845136,
          "to_latitude": 21.009353271560954,
          "to_longitude": 105.83156004167972,
          "from_longitude": 105.83150142839658
        }
      },
      {
        "id": 8066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157117597143,
            21.009154952774786,
            105.83158654855615,
            21.009160602298287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157117597143,
              21.009154952774786
            ],
            [
              105.83158085910883,
              21.00915802881095
            ],
            [
              105.83158654855615,
              21.009160602298287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8074",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009154952774786,
          "to_latitude": 21.009160602298287,
          "to_longitude": 105.83158654855615,
          "from_longitude": 105.83157117597143
        }
      },
      {
        "id": 8067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154929447419,
            21.009634763121042,
            105.83155959907536,
            21.009666564569887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83155959907536,
              21.009634763121042
            ],
            [
              105.83155624249251,
              21.009645430361296
            ],
            [
              105.83155508182186,
              21.009648963129543
            ],
            [
              105.83154929447419,
              21.009666564569887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8075",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009634763121042,
          "to_latitude": 21.009666564569887,
          "to_longitude": 105.83154929447419,
          "from_longitude": 105.83155959907536
        }
      },
      {
        "id": 8068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8315626935987,
            21.009639267971735,
            105.83157355137419,
            21.009673310317854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157355137419,
              21.009639267971735
            ],
            [
              105.83156981229152,
              21.009650169210406
            ],
            [
              105.83156865009708,
              21.009653947663573
            ],
            [
              105.8315626935987,
              21.009673310317854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5.2_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8076",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009639267971735,
          "to_latitude": 21.009673310317854,
          "to_longitude": 105.8315626935987,
          "from_longitude": 105.83157355137419
        }
      },
      {
        "id": 8069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314462840969,
            21.009608996820354,
            105.83204229094407,
            21.009831559502736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8314462840969,
              21.009608996820354
            ],
            [
              105.83155508182186,
              21.009648963129543
            ],
            [
              105.83156865009708,
              21.009653947663573
            ],
            [
              105.83161192072502,
              21.009669843429574
            ],
            [
              105.83175326389619,
              21.009724295457307
            ],
            [
              105.83182233534653,
              21.00975090466725
            ],
            [
              105.83191938424132,
              21.009786117588234
            ],
            [
              105.83194043158818,
              21.009793754430486
            ],
            [
              105.83197343246411,
              21.00980572792376
            ],
            [
              105.83204229094407,
              21.009831559502736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8077",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009608996820354,
          "to_latitude": 21.009831559502736,
          "to_longitude": 105.83204229094407,
          "from_longitude": 105.8314462840969
        }
      },
      {
        "id": 8070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83175326389619,
            21.009700895276374,
            105.83176441533027,
            21.009724295457307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176441533027,
              21.009700895276374
            ],
            [
              105.83175507021043,
              21.00972062766109
            ],
            [
              105.83175326389619,
              21.009724295457307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8078",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009700895276374,
          "to_latitude": 21.009724295457307,
          "to_longitude": 105.83175326389619,
          "from_longitude": 105.83176441533027
        }
      },
      {
        "id": 8071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190417071144,
            21.009755421220845,
            105.83193329047798,
            21.009822006667214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193329047798,
              21.009755421220845
            ],
            [
              105.83192097461668,
              21.009782365039623
            ],
            [
              105.83191938424132,
              21.009786117588234
            ],
            [
              105.83190417071144,
              21.009822006667214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D5.4_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8079",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009755421220845,
          "to_latitude": 21.009822006667214,
          "to_longitude": 105.83190417071144,
          "from_longitude": 105.83193329047798
        }
      },
      {
        "id": 8072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83192515218555,
            21.009761967143817,
            105.83195356225252,
            21.00982400770524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195356225252,
              21.009761967143817
            ],
            [
              105.83194216131888,
              21.009790327264835
            ],
            [
              105.83194043158818,
              21.009793754430486
            ],
            [
              105.83192515218555,
              21.00982400770524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11A_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8080",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009761967143817,
          "to_latitude": 21.00982400770524,
          "to_longitude": 105.83192515218555,
          "from_longitude": 105.83195356225252
        }
      },
      {
        "id": 8073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142439654316,
            21.009930117766135,
            105.8314392977795,
            21.00995573018373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8314392977795,
              21.009930117766135
            ],
            [
              105.83143670890455,
              21.00993386435055
            ],
            [
              105.83143576420127,
              21.009935231933994
            ],
            [
              105.83142439654316,
              21.00995573018373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8081",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009930117766135,
          "to_latitude": 21.00995573018373,
          "to_longitude": 105.83142439654316,
          "from_longitude": 105.8314392977795
        }
      },
      {
        "id": 8074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314435137814,
            21.00993668680997,
            105.83145799216928,
            21.00996101367932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83145799216928,
              21.00993668680997
            ],
            [
              105.83145568341544,
              21.00994047086465
            ],
            [
              105.83145453189155,
              21.009942357007063
            ],
            [
              105.8314435137814,
              21.00996101367932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4.2_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8082",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00993668680997,
          "to_latitude": 21.00996101367932,
          "to_longitude": 105.8314435137814,
          "from_longitude": 105.83145799216928
        }
      },
      {
        "id": 8075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83130472197769,
            21.009887907258815,
            105.83193991138614,
            21.010112813295954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83130472197769,
              21.009887907258815
            ],
            [
              105.83143670890455,
              21.00993386435055
            ],
            [
              105.83145568341544,
              21.00994047086465
            ],
            [
              105.83148916059888,
              21.00995212780201
            ],
            [
              105.83158877948253,
              21.009990068738137
            ],
            [
              105.83161921620305,
              21.01000087341109
            ],
            [
              105.83166727018755,
              21.01001793262102
            ],
            [
              105.83179317399797,
              21.010059329870163
            ],
            [
              105.83180728428496,
              21.010064215992315
            ],
            [
              105.83182551943146,
              21.01007053072353
            ],
            [
              105.83186095080399,
              21.01008280035562
            ],
            [
              105.83193991138614,
              21.010112813295954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8083",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009887907258815,
          "to_latitude": 21.010112813295954,
          "to_longitude": 105.83193991138614,
          "from_longitude": 105.83130472197769
        }
      },
      {
        "id": 8076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83179052876989,
            21.010060053194326,
            105.83180909747637,
            21.010105656598054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83180909747637,
              21.010060053194326
            ],
            [
              105.83180728428496,
              21.010064215992315
            ],
            [
              105.83180641805068,
              21.010066203710156
            ],
            [
              105.83179052876989,
              21.010105656598054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4.4_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8084",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010060053194326,
          "to_latitude": 21.010105656598054,
          "to_longitude": 105.83179052876989,
          "from_longitude": 105.83180909747637
        }
      },
      {
        "id": 8077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83180673826689,
            21.010066534001954,
            105.83182754131386,
            21.010109735813863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83182754131386,
              21.010066534001954
            ],
            [
              105.83182551943146,
              21.01007053072353
            ],
            [
              105.83182428917648,
              21.010072962801473
            ],
            [
              105.83180673826689,
              21.010109735813863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4.5_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8085",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010066534001954,
          "to_latitude": 21.010109735813863,
          "to_longitude": 105.83180673826689,
          "from_longitude": 105.83182754131386
        }
      },
      {
        "id": 8078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83161921620305,
            21.009994447743175,
            105.8316223827561,
            21.01000087341109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316223827561,
              21.009994447743175
            ],
            [
              105.83162024483049,
              21.00999902851338
            ],
            [
              105.83161921620305,
              21.01000087341109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8086",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009994447743175,
          "to_latitude": 21.01000087341109,
          "to_longitude": 105.83161921620305,
          "from_longitude": 105.8316223827561
        }
      },
      {
        "id": 8079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83130470158649,
            21.010217847231814,
            105.8313212286794,
            21.010265472870167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8313212286794,
              21.010217847231814
            ],
            [
              105.83132041855067,
              21.01022093480216
            ],
            [
              105.83131979541461,
              21.010222702749434
            ],
            [
              105.83130470158649,
              21.010265472870167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8087",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010217847231814,
          "to_latitude": 21.010265472870167,
          "to_longitude": 105.83130470158649,
          "from_longitude": 105.8313212286794
        }
      },
      {
        "id": 8080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83132203547827,
            21.010224616703145,
            105.83134068315239,
            21.010270406509804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83134068315239,
              21.010224616703145
            ],
            [
              105.8313395114586,
              21.01022772410451
            ],
            [
              105.83133924288084,
              21.010228381158505
            ],
            [
              105.83132203547827,
              21.010270406509804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3.2_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8088",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010224616703145,
          "to_latitude": 21.010270406509804,
          "to_longitude": 105.83132203547827,
          "from_longitude": 105.83134068315239
        }
      },
      {
        "id": 8081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118272233352,
            21.01017581390246,
            105.8318287799332,
            21.010400809940013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83118272233352,
              21.01017581390246
            ],
            [
              105.83131439906651,
              21.010221126790242
            ],
            [
              105.83131979541461,
              21.010222702749434
            ],
            [
              105.83133924288084,
              21.010228381158505
            ],
            [
              105.83135110694147,
              21.01023184487313
            ],
            [
              105.83143392981688,
              21.010260691179038
            ],
            [
              105.83155939906366,
              21.010306439750924
            ],
            [
              105.83166649746579,
              21.0103437705333
            ],
            [
              105.8316906652933,
              21.01035487770055
            ],
            [
              105.8316914001363,
              21.01035521552821
            ],
            [
              105.83171009333529,
              21.010360864161118
            ],
            [
              105.83171262642162,
              21.010361629457368
            ],
            [
              105.83173280151797,
              21.010367726973527
            ],
            [
              105.8318287799332,
              21.010400809940013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8089",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01017581390246,
          "to_latitude": 21.010400809940013,
          "to_longitude": 105.8318287799332,
          "from_longitude": 105.83118272233352
        }
      },
      {
        "id": 8082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316906652933,
            21.01034760674437,
            105.83169417441154,
            21.01035487770055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83169417441154,
              21.01034760674437
            ],
            [
              105.83169136183174,
              21.010353684747994
            ],
            [
              105.8316906652933,
              21.01035487770055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8090",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01034760674437,
          "to_latitude": 21.01035487770055,
          "to_longitude": 105.8316906652933,
          "from_longitude": 105.83169417441154
        }
      },
      {
        "id": 8083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169106727165,
            21.010354203357934,
            105.83171313546437,
            21.010400671081303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171313546437,
              21.010354203357934
            ],
            [
              105.83171009333529,
              21.010360864161118
            ],
            [
              105.83170946418288,
              21.01036224013917
            ],
            [
              105.83169106727165,
              21.010400671081303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3.4_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8091",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010354203357934,
          "to_latitude": 21.010400671081303,
          "to_longitude": 105.83169106727165,
          "from_longitude": 105.83171313546437
        }
      },
      {
        "id": 8084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169397544897,
            21.010355315856913,
            105.83171633322667,
            21.010401947576657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171633322667,
              21.010355315856913
            ],
            [
              105.83171262642162,
              21.010361629457368
            ],
            [
              105.83171192984324,
              21.010362815184372
            ],
            [
              105.83169397544897,
              21.010401947576657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3.5_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8092",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010355315856913,
          "to_latitude": 21.010401947576657,
          "to_longitude": 105.83169397544897,
          "from_longitude": 105.83171633322667
        }
      },
      {
        "id": 8085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83099771828041,
            21.010113388697185,
            105.83108935382393,
            21.010137866590192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83108935382393,
              21.010137866590192
            ],
            [
              105.83108186739408,
              21.010137702637785
            ],
            [
              105.83099771828041,
              21.010113388697185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12A_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8093",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010137866590192,
          "to_latitude": 21.010113388697185,
          "to_longitude": 105.83099771828041,
          "from_longitude": 105.83108935382393
        }
      },
      {
        "id": 8086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098608638242,
            21.0104882462517,
            105.83108924447512,
            21.010524472123485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83098608638242,
              21.0104882462517
            ],
            [
              105.8309906455507,
              21.010489415341773
            ],
            [
              105.83101783864592,
              21.010496372863862
            ],
            [
              105.83102426366449,
              21.010498018776246
            ],
            [
              105.83108924447512,
              21.010524472123485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2.2_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8094",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0104882462517,
          "to_latitude": 21.010524472123485,
          "to_longitude": 105.83108924447512,
          "from_longitude": 105.83098608638242
        }
      },
      {
        "id": 8087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83097543412475,
            21.01051184265659,
            105.83106818816653,
            21.01053676218768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83097543412475,
              21.01051184265659
            ],
            [
              105.8309807217344,
              21.010513235802502
            ],
            [
              105.83101316981356,
              21.010521773597986
            ],
            [
              105.83106818816653,
              21.01053676218768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2.1_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8095",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01051184265659,
          "to_latitude": 21.01053676218768,
          "to_longitude": 105.83106818816653,
          "from_longitude": 105.83097543412475
        }
      },
      {
        "id": 8088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83095731099296,
            21.010555455800045,
            105.83099607481414,
            21.010566748304715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83095731099296,
              21.010555455800045
            ],
            [
              105.83096250497023,
              21.010556963210156
            ],
            [
              105.83099041998764,
              21.01056504985592
            ],
            [
              105.83099607481414,
              21.010566748304715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8096",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010555455800045,
          "to_latitude": 21.010566748304715,
          "to_longitude": 105.83099607481414,
          "from_longitude": 105.83095731099296
        }
      },
      {
        "id": 8089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8309031044794,
            21.0105286819279,
            105.83101011651614,
            21.010782760737225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83101011651614,
              21.0105286819279
            ],
            [
              105.83099607481414,
              21.010566748304715
            ],
            [
              105.8309031044794,
              21.010782760737225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8097",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0105286819279,
          "to_latitude": 21.010782760737225,
          "to_longitude": 105.8309031044794,
          "from_longitude": 105.83101011651614
        }
      },
      {
        "id": 8090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83097021116585,
            21.010962678046234,
            105.83109356778242,
            21.011093334158936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83097021116585,
              21.011093334158936
            ],
            [
              105.83104322487937,
              21.011017633205654
            ],
            [
              105.83109356778242,
              21.010962678046234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8098",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011093334158936,
          "to_latitude": 21.010962678046234,
          "to_longitude": 105.83109356778242,
          "from_longitude": 105.83097021116585
        }
      },
      {
        "id": 8091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098539145477,
            21.010974569315177,
            105.83110534102872,
            21.011105913313674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83098539145477,
              21.011105913313674
            ],
            [
              105.83105908436634,
              21.011029360907937
            ],
            [
              105.83110534102872,
              21.010974569315177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1.6_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8099",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011105913313674,
          "to_latitude": 21.010974569315177,
          "to_longitude": 105.83110534102872,
          "from_longitude": 105.83098539145477
        }
      },
      {
        "id": 8092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83129260720422,
            21.011253193536554,
            105.83140414712936,
            21.011355823759462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83129260720422,
              21.011355823759462
            ],
            [
              105.83136758898733,
              21.01128828995655
            ],
            [
              105.83136949210324,
              21.01128646336829
            ],
            [
              105.83140414712936,
              21.011253193536554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1.4_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8100",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011355823759462,
          "to_latitude": 21.011253193536554,
          "to_longitude": 105.83140414712936,
          "from_longitude": 105.83129260720422
        }
      },
      {
        "id": 8093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8313115269848,
            21.011264126708987,
            105.83141642396374,
            21.01137109609726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8313115269848,
              21.01137109609726
            ],
            [
              105.83138482303404,
              21.011306776070203
            ],
            [
              105.83138779523453,
              21.011302765678117
            ],
            [
              105.83141642396374,
              21.011264126708987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8.3_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8101",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01137109609726,
          "to_latitude": 21.011264126708987,
          "to_longitude": 105.83141642396374,
          "from_longitude": 105.8313115269848
        }
      },
      {
        "id": 8094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8312532091353,
            21.011250216140176,
            105.83132879679275,
            21.01132402307816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8312532091353,
              21.01132402307816
            ],
            [
              105.83132381020921,
              21.011254894824233
            ],
            [
              105.83132879679275,
              21.011250216140176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1.5_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8102",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01132402307816,
          "to_latitude": 21.011250216140176,
          "to_longitude": 105.83132879679275,
          "from_longitude": 105.8312532091353
        }
      },
      {
        "id": 8095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83105988618938,
            21.011038838151585,
            105.83180817781498,
            21.011674609490804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83105988618938,
              21.011038838151585
            ],
            [
              105.83128669835372,
              21.0112159285302
            ],
            [
              105.83132879679275,
              21.011250216140176
            ],
            [
              105.83136949210324,
              21.01128646336829
            ],
            [
              105.83138779523453,
              21.011302765678117
            ],
            [
              105.83140689105343,
              21.0113197749512
            ],
            [
              105.83154500096948,
              21.011441936038956
            ],
            [
              105.83168149524789,
              21.01157054399796
            ],
            [
              105.83169586236706,
              21.011584080866267
            ],
            [
              105.83172844029151,
              21.011614776712747
            ],
            [
              105.83180817781498,
              21.011674609490804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1.5_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8103",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011038838151585,
          "to_latitude": 21.011674609490804,
          "to_longitude": 105.83180817781498,
          "from_longitude": 105.83105988618938
        }
      },
      {
        "id": 8096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162302580743,
            21.011522991587054,
            105.83173200321535,
            21.01162543878734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83173200321535,
              21.011522991587054
            ],
            [
              105.83168573444817,
              21.01156656353707
            ],
            [
              105.83168149524789,
              21.01157054399796
            ],
            [
              105.83162302580743,
              21.01162543878734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1.1_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8104",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011522991587054,
          "to_latitude": 21.01162543878734,
          "to_longitude": 105.83162302580743,
          "from_longitude": 105.83173200321535
        }
      },
      {
        "id": 8097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316384592931,
            21.01153155227957,
            105.83174070402495,
            21.011638731093825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83174070402495,
              21.01153155227957
            ],
            [
              105.83170051462321,
              21.011579651289434
            ],
            [
              105.83169586236706,
              21.011584080866267
            ],
            [
              105.8316384592931,
              21.011638731093825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1.2_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8105",
          "diaChiLapD": "Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01153155227957,
          "to_latitude": 21.011638731093825,
          "to_longitude": 105.8316384592931,
          "from_longitude": 105.83174070402495
        }
      },
      {
        "id": 8098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83282914122199,
            21.010696597845943,
            105.8328943052543,
            21.010737966361937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83282914122199,
              21.010696597845943
            ],
            [
              105.83285523337321,
              21.01071262432583
            ],
            [
              105.83285978331521,
              21.010715420119244
            ],
            [
              105.8328943052543,
              21.010737966361937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5B.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8106",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010696597845943,
          "to_latitude": 21.010737966361937,
          "to_longitude": 105.8328943052543,
          "from_longitude": 105.83282914122199
        }
      },
      {
        "id": 8099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83283481515225,
            21.010690816529884,
            105.83289770527223,
            21.010731497980817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83283481515225,
              21.010690816529884
            ],
            [
              105.83285955362695,
              21.010707659827702
            ],
            [
              105.83286481011778,
              21.01071124067852
            ],
            [
              105.83289770527223,
              21.010731497980817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5B.2_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8107",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010690816529884,
          "to_latitude": 21.010731497980817,
          "to_longitude": 105.83289770527223,
          "from_longitude": 105.83283481515225
        }
      },
      {
        "id": 8100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83291647970869,
            21.01060760459327,
            105.83293955861289,
            21.010623704519592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83291647970869,
              21.01060760459327
            ],
            [
              105.83293574661124,
              21.010620417374337
            ],
            [
              105.83293955861289,
              21.010623704519592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5B.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8108",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01060760459327,
          "to_latitude": 21.010623704519592,
          "to_longitude": 105.83293955861289,
          "from_longitude": 105.83291647970869
        }
      },
      {
        "id": 8101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8327836471497,
            21.010584979525103,
            105.8331650238918,
            21.010794892717378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8327836471497,
              21.010794892717378
            ],
            [
              105.83285523337321,
              21.01071262432583
            ],
            [
              105.83285955362695,
              21.010707659827702
            ],
            [
              105.83288075726652,
              21.01068329270097
            ],
            [
              105.83293955861289,
              21.010623704519592
            ],
            [
              105.83297297854187,
              21.010584979525103
            ],
            [
              105.8331650238918,
              21.010726350602248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5B.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8109",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010794892717378,
          "to_latitude": 21.010726350602248,
          "to_longitude": 105.8331650238918,
          "from_longitude": 105.8327836471497
        }
      },
      {
        "id": 8102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83329123646936,
            21.010994514424937,
            105.83333917636519,
            21.011040862462842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83333917636519,
              21.010994514424937
            ],
            [
              105.8333190725859,
              21.011013578804516
            ],
            [
              105.83331586038689,
              21.011016625759595
            ],
            [
              105.83329123646936,
              21.011040862462842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8110",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010994514424937,
          "to_latitude": 21.011040862462842,
          "to_longitude": 105.83329123646936,
          "from_longitude": 105.83333917636519
        }
      },
      {
        "id": 8103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331007202331,
            21.011008834072832,
            105.83335597673599,
            21.011052718988747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335597673599,
              21.011008834072832
            ],
            [
              105.83333676426234,
              21.011028175895564
            ],
            [
              105.83333356457426,
              21.01103139711217
            ],
            [
              105.83331007202331,
              21.011052718988747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5.2_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8111",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011008834072832,
          "to_latitude": 21.011052718988747,
          "to_longitude": 105.83331007202331,
          "from_longitude": 105.83335597673599
        }
      },
      {
        "id": 8104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360693924205,
            21.01125447685266,
            105.83364550596241,
            21.01129084086902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364550596241,
              21.01125447685266
            ],
            [
              105.83362574724468,
              21.01127401196401
            ],
            [
              105.83362360242708,
              21.01127613240584
            ],
            [
              105.83360693924205,
              21.01129084086902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5.4_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8112",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01125447685266,
          "to_latitude": 21.01129084086902,
          "to_longitude": 105.83360693924205,
          "from_longitude": 105.83364550596241
        }
      },
      {
        "id": 8105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362386717542,
            21.011267779916153,
            105.83366331459459,
            21.011303005671284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366331459459,
              21.011267779916153
            ],
            [
              105.83364445343307,
              21.011287678244837
            ],
            [
              105.83364160365436,
              21.011290685494888
            ],
            [
              105.83362386717542,
              21.011303005671284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5.5_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8113",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011267779916153,
          "to_latitude": 21.011303005671284,
          "to_longitude": 105.83362386717542,
          "from_longitude": 105.83366331459459
        }
      },
      {
        "id": 8106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356642697211,
            21.01120664895667,
            105.83358804801442,
            21.011230673181675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358804801442,
              21.01120664895667
            ],
            [
              105.83356794394487,
              21.011228949622442
            ],
            [
              105.83356642697211,
              21.011230673181675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8114",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01120664895667,
          "to_latitude": 21.011230673181675,
          "to_longitude": 105.83356642697211,
          "from_longitude": 105.83358804801442
        }
      },
      {
        "id": 8107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313103831952,
            21.010858431256757,
            105.83379921664508,
            21.011418656242554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83313103831952,
              21.010858431256757
            ],
            [
              105.83320487789051,
              21.010919363483303
            ],
            [
              105.8333190725859,
              21.011013578804516
            ],
            [
              105.83333676426234,
              21.011028175895564
            ],
            [
              105.83335976692926,
              21.01104715561648
            ],
            [
              105.83356642697211,
              21.011230673181675
            ],
            [
              105.83362574724468,
              21.01127401196401
            ],
            [
              105.83364445343307,
              21.011287678244837
            ],
            [
              105.83366214550232,
              21.011300603427994
            ],
            [
              105.83379921664508,
              21.011418656242554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E5.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8115",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010858431256757,
          "to_latitude": 21.011418656242554,
          "to_longitude": 105.83379921664508,
          "from_longitude": 105.83313103831952
        }
      },
      {
        "id": 8108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83232365466358,
            21.010057600066933,
            105.83236193956101,
            21.01009916770772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83236193956101,
              21.010057600066933
            ],
            [
              105.83235685687224,
              21.010062974733625
            ],
            [
              105.8323542088974,
              21.01006577413686
            ],
            [
              105.83232365466358,
              21.01009916770772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6.1_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8116",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010057600066933,
          "to_latitude": 21.01009916770772,
          "to_longitude": 105.83232365466358,
          "from_longitude": 105.83236193956101
        }
      },
      {
        "id": 8109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83233768170297,
            21.010070899885264,
            105.83237785585956,
            21.01010447599877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237785585956,
              21.010070899885264
            ],
            [
              105.83237206157754,
              21.010075272743197
            ],
            [
              105.83236977701023,
              21.0100769973371
            ],
            [
              105.83233768170297,
              21.01010447599877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6.2_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8117",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010070899885264,
          "to_latitude": 21.01010447599877,
          "to_longitude": 105.83233768170297,
          "from_longitude": 105.83237785585956
        }
      },
      {
        "id": 8110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83220730187656,
            21.00994201011223,
            105.83279472502656,
            21.01042709117897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220730187656,
              21.00994201011223
            ],
            [
              105.83235685687224,
              21.010062974733625
            ],
            [
              105.83237206157754,
              21.010075272743197
            ],
            [
              105.83240223620257,
              21.010099679325926
            ],
            [
              105.83254727158423,
              21.01022498690832
            ],
            [
              105.83255754006446,
              21.010233858729748
            ],
            [
              105.83265464740167,
              21.01031470188009
            ],
            [
              105.83266711160827,
              21.010325077659296
            ],
            [
              105.83267704780711,
              21.010333350454054
            ],
            [
              105.83279472502656,
              21.01042709117897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8118",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00994201011223,
          "to_latitude": 21.01042709117897,
          "to_longitude": 105.83279472502656,
          "from_longitude": 105.83220730187656
        }
      },
      {
        "id": 8111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263488673954,
            21.010308323521354,
            105.83266198595109,
            21.01033371572704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83266198595109,
              21.010308323521354
            ],
            [
              105.83265591802723,
              21.010313479921116
            ],
            [
              105.83265464740167,
              21.01031470188009
            ],
            [
              105.83263488673954,
              21.01033371572704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6.4_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8119",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010308323521354,
          "to_latitude": 21.01033371572704,
          "to_longitude": 105.83263488673954,
          "from_longitude": 105.83266198595109
        }
      },
      {
        "id": 8112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265019234422,
            21.01031840768952,
            105.83267405415906,
            21.010340093476557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83267405415906,
              21.01031840768952
            ],
            [
              105.8326682793787,
              21.01032404136476
            ],
            [
              105.83266711160827,
              21.010325077659296
            ],
            [
              105.83265019234422,
              21.010340093476557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6.5_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8120",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01031840768952,
          "to_latitude": 21.010340093476557,
          "to_longitude": 105.83265019234422,
          "from_longitude": 105.83267405415906
        }
      },
      {
        "id": 8113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83254727158423,
            21.01021790538562,
            105.83255378092012,
            21.01022498690832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83255378092012,
              21.01021790538562
            ],
            [
              105.83254937358402,
              21.01022260385067
            ],
            [
              105.83254727158423,
              21.01022498690832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6.3_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8121",
          "diaChiLapD": "Ngõ 4B, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01021790538562,
          "to_latitude": 21.01022498690832,
          "to_longitude": 105.83254727158423,
          "from_longitude": 105.83255378092012
        }
      },
      {
        "id": 8114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83349416525344,
            21.01073221295882,
            105.83351756882031,
            21.010770578284486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83351756882031,
              21.01073221295882
            ],
            [
              105.83351252239592,
              21.010739609802847
            ],
            [
              105.83351088137401,
              21.010742378097614
            ],
            [
              105.83349416525344,
              21.010770578284486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1.1_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8122",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01073221295882,
          "to_latitude": 21.010770578284486,
          "to_longitude": 105.83349416525344,
          "from_longitude": 105.83351756882031
        }
      },
      {
        "id": 8115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83349939443485,
            21.0107378531976,
            105.83352484234108,
            21.010772703249796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83352484234108,
              21.0107378531976
            ],
            [
              105.8335190396682,
              21.01074400368249
            ],
            [
              105.83351677544857,
              21.010747312449016
            ],
            [
              105.83349939443485,
              21.010772703249796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1.3_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8123",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0107378531976,
          "to_latitude": 21.010772703249796,
          "to_longitude": 105.83349939443485,
          "from_longitude": 105.83352484234108
        }
      },
      {
        "id": 8116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8333858989323,
            21.0106377581918,
            105.83413322090672,
            21.011170101872764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8333858989323,
              21.0106377581918
            ],
            [
              105.83343066834739,
              21.010675240449636
            ],
            [
              105.83351088137401,
              21.010742378097614
            ],
            [
              105.83351220824123,
              21.01074348888778
            ],
            [
              105.83351677544857,
              21.010747312449016
            ],
            [
              105.83351873143975,
              21.010748949447446
            ],
            [
              105.8335423381946,
              21.010760663351427
            ],
            [
              105.83359594719417,
              21.01080136200153
            ],
            [
              105.83372661319187,
              21.010888904616344
            ],
            [
              105.83372141144301,
              21.01091473976372
            ],
            [
              105.83387184930152,
              21.01101399505376
            ],
            [
              105.83387889127496,
              21.01101864105702
            ],
            [
              105.833988772179,
              21.011075457338606
            ],
            [
              105.83413322090672,
              21.011170101872764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1.2_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8124",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 99.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0106377581918,
          "to_latitude": 21.011170101872764,
          "to_longitude": 105.83413322090672,
          "from_longitude": 105.8333858989323
        }
      },
      {
        "id": 8117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387184930152,
            21.010796878656212,
            105.83403532468846,
            21.01101399505376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387184930152,
              21.01101399505376
            ],
            [
              105.83403532468846,
              21.010796878656212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1.2_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8125",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01101399505376,
          "to_latitude": 21.010796878656212,
          "to_longitude": 105.83403532468846,
          "from_longitude": 105.83387184930152
        }
      },
      {
        "id": 8118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366233980043,
            21.01054916744425,
            105.83369284394105,
            21.010582882550782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369284394105,
              21.01054916744425
            ],
            [
              105.83368896587076,
              21.010552861668014
            ],
            [
              105.83368498257276,
              21.010557353051926
            ],
            [
              105.83366233980043,
              21.010582882550782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2.2_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8126",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01054916744425,
          "to_latitude": 21.010582882550782,
          "to_longitude": 105.83366233980043,
          "from_longitude": 105.83369284394105
        }
      },
      {
        "id": 8119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366774984572,
            21.010552783709073,
            105.83369768798514,
            21.010585532302102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369768798514,
              21.010552783709073
            ],
            [
              105.83369350551806,
              21.010556226521235
            ],
            [
              105.83368932840422,
              21.01056097898369
            ],
            [
              105.83366774984572,
              21.010585532302102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2.3_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8127",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010552783709073,
          "to_latitude": 21.010585532302102,
          "to_longitude": 105.83366774984572,
          "from_longitude": 105.83369768798514
        }
      },
      {
        "id": 8120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368326337171,
            21.010547783752322,
            105.83369099035816,
            21.01055591812091
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369099035816,
              21.010547783752322
            ],
            [
              105.8336873667442,
              21.010552435954608
            ],
            [
              105.83368326337171,
              21.01055591812091
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2.1_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8128",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010547783752322,
          "to_latitude": 21.01055591812091,
          "to_longitude": 105.83368326337171,
          "from_longitude": 105.83369099035816
        }
      },
      {
        "id": 8121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356263673396,
            21.01046588847671,
            105.83379556219668,
            21.01064962125311
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356263673396,
              21.01046588847671
            ],
            [
              105.83359911335879,
              21.010493119062158
            ],
            [
              105.83368326337171,
              21.01055591812091
            ],
            [
              105.83368498257276,
              21.010557353051926
            ],
            [
              105.83368932840422,
              21.01056097898369
            ],
            [
              105.83379556219668,
              21.01064962125311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G2.1_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8129",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01046588847671,
          "to_latitude": 21.01064962125311,
          "to_longitude": 105.83379556219668,
          "from_longitude": 105.83356263673396
        }
      },
      {
        "id": 8122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338480895765,
            21.010352814074132,
            105.83390820989237,
            21.010400161307167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390820989237,
              21.010352814074132
            ],
            [
              105.83389964658227,
              21.01036329009756
            ],
            [
              105.8338974017652,
              21.010364895291005
            ],
            [
              105.8338480895765,
              21.010400161307167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G3.1_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8130",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010352814074132,
          "to_latitude": 21.010400161307167,
          "to_longitude": 105.8338480895765,
          "from_longitude": 105.83390820989237
        }
      },
      {
        "id": 8123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385635009465,
            21.010355369212434,
            105.8339117292682,
            21.010402702245834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339117292682,
              21.010355369212434
            ],
            [
              105.83390138448645,
              21.010364455773622
            ],
            [
              105.83389929930274,
              21.010366226378252
            ],
            [
              105.83385635009465,
              21.010402702245834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G3.2_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8131",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010355369212434,
          "to_latitude": 21.010402702245834,
          "to_longitude": 105.83385635009465,
          "from_longitude": 105.8339117292682
        }
      },
      {
        "id": 8124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374560184093,
            21.010258423579184,
            105.83420514434333,
            21.010773177594476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83374560184093,
              21.010258423579184
            ],
            [
              105.83378579966484,
              21.010286624021536
            ],
            [
              105.8338974017652,
              21.010364895291005
            ],
            [
              105.83389929930274,
              21.010366226378252
            ],
            [
              105.8339026791394,
              21.01036859703938
            ],
            [
              105.83391024610971,
              21.010373904340273
            ],
            [
              105.83400265707665,
              21.010442599655153
            ],
            [
              105.8340545692555,
              21.01048727344344
            ],
            [
              105.83420514434333,
              21.010562291535926
            ],
            [
              105.83403707153535,
              21.010773177594476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G3.3_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8132",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010258423579184,
          "to_latitude": 21.010773177594476,
          "to_longitude": 105.83403707153535,
          "from_longitude": 105.83374560184093
        }
      },
      {
        "id": 8125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420514434333,
            21.010562291535926,
            105.8343029833716,
            21.010604827958254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420514434333,
              21.010562291535926
            ],
            [
              105.8343029833716,
              21.010604827958254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G3.3_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8133",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010562291535926,
          "to_latitude": 21.010604827958254,
          "to_longitude": 105.8343029833716,
          "from_longitude": 105.83420514434333
        }
      },
      {
        "id": 8126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8339026791394,
            21.010357252373947,
            105.83391432244431,
            21.01036859703938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339026791394,
              21.01036859703938
            ],
            [
              105.8339054783864,
              21.01036550151683
            ],
            [
              105.83391432244431,
              21.010357252373947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G3.3_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8134",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01036859703938,
          "to_latitude": 21.010357252373947,
          "to_longitude": 105.83391432244431,
          "from_longitude": 105.8339026791394
        }
      },
      {
        "id": 8127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83351220824123,
            21.010733961391995,
            105.833519824583,
            21.01074348888778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83351220824123,
              21.01074348888778
            ],
            [
              105.83351413555849,
              21.01074083028733
            ],
            [
              105.833519824583,
              21.010733961391995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1.2_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DODV.8135",
          "diaChiLapD": "Ngõ 4A, Đặng Văn Ngữ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01074348888778,
          "to_latitude": 21.010733961391995,
          "to_longitude": 105.833519824583,
          "from_longitude": 105.83351220824123
        }
      },
      {
        "id": 8128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83286912146443,
            21.00769876089909,
            105.83289515959277,
            21.007732577747404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83289515959277,
              21.00769876089909
            ],
            [
              105.83288982154505,
              21.007705075281617
            ],
            [
              105.83288188678164,
              21.007715616823656
            ],
            [
              105.83286912146443,
              21.007732577747404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1.1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8136",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00769876089909,
          "to_latitude": 21.007732577747404,
          "to_longitude": 105.83286912146443,
          "from_longitude": 105.83289515959277
        }
      },
      {
        "id": 8129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288093319612,
            21.007711887420005,
            105.8329106736103,
            21.00773998781009
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329106736103,
              21.007711887420005
            ],
            [
              105.83290511501697,
              21.007717112689
            ],
            [
              105.83289502490189,
              21.007726656780545
            ],
            [
              105.83288093319612,
              21.00773998781009
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1.2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8137",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007711887420005,
          "to_latitude": 21.00773998781009,
          "to_longitude": 105.83288093319612,
          "from_longitude": 105.8329106736103
        }
      },
      {
        "id": 8130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278171241889,
            21.007631439582063,
            105.8332951298333,
            21.0080643660495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278171241889,
              21.007631439582063
            ],
            [
              105.83288188678164,
              21.007715616823656
            ],
            [
              105.83289502490189,
              21.007726656780545
            ],
            [
              105.83295206727274,
              21.007774589975725
            ],
            [
              105.83306850867633,
              21.007871136639256
            ],
            [
              105.83311825659966,
              21.00791238458072
            ],
            [
              105.83318703941168,
              21.007974259309723
            ],
            [
              105.8331903426778,
              21.007977230104203
            ],
            [
              105.83321833009722,
              21.008002407780694
            ],
            [
              105.8332951298333,
              21.0080643660495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8138",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007631439582063,
          "to_latitude": 21.0080643660495,
          "to_longitude": 105.8332951298333,
          "from_longitude": 105.83278171241889
        }
      },
      {
        "id": 8131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83306850867633,
            21.007857851670206,
            105.83308296711151,
            21.007871136639256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83308296711151,
              21.007857851670206
            ],
            [
              105.83307690017023,
              21.007862991820325
            ],
            [
              105.83306850867633,
              21.007871136639256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8139",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007857851670206,
          "to_latitude": 21.007871136639256,
          "to_longitude": 105.83306850867633,
          "from_longitude": 105.83308296711151
        }
      },
      {
        "id": 8132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314844064176,
            21.007960729581466,
            105.83320425998991,
            21.008001087115808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83320425998991,
              21.007960729581466
            ],
            [
              105.83319910206829,
              21.007965876124008
            ],
            [
              105.83318703941168,
              21.007974259309723
            ],
            [
              105.83314844064176,
              21.008001087115808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1.4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8140",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007960729581466,
          "to_latitude": 21.008001087115808,
          "to_longitude": 105.83314844064176,
          "from_longitude": 105.83320425998991
        }
      },
      {
        "id": 8133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315571328387,
            21.007964042459793,
            105.83320816537349,
            21.00800451718754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83320816537349,
              21.007964042459793
            ],
            [
              105.83320190400477,
              21.0079681204697
            ],
            [
              105.8331903426778,
              21.007977230104203
            ],
            [
              105.83315571328387,
              21.00800451718754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1.5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8141",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007964042459793,
          "to_latitude": 21.00800451718754,
          "to_longitude": 105.83315571328387,
          "from_longitude": 105.83320816537349
        }
      },
      {
        "id": 8134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833342139668,
            21.008095272750296,
            105.83398855610537,
            21.00864223542497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833342139668,
              21.008095272750296
            ],
            [
              105.83352685085819,
              21.008252456095008
            ],
            [
              105.83352979469196,
              21.008254961675334
            ],
            [
              105.83356077161163,
              21.008281322326003
            ],
            [
              105.8336629914333,
              21.00836975770116
            ],
            [
              105.83370909425015,
              21.008409644089486
            ],
            [
              105.83382198181764,
              21.00850088490541
            ],
            [
              105.83382603612215,
              21.00850416181308
            ],
            [
              105.83389571877466,
              21.008560481506
            ],
            [
              105.83398855610537,
              21.00864223542497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8142",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008095272750296,
          "to_latitude": 21.00864223542497,
          "to_longitude": 105.83398855610537,
          "from_longitude": 105.833342139668
        }
      },
      {
        "id": 8135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337934250132,
            21.00848373616601,
            105.83384486032638,
            21.008521451268475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384486032638,
              21.00848373616601
            ],
            [
              105.83384267680152,
              21.00848598054158
            ],
            [
              105.83382198181764,
              21.00850088490541
            ],
            [
              105.8337934250132,
              21.008521451268475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2A_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8143",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00848373616601,
          "to_latitude": 21.008521451268475,
          "to_longitude": 105.8337934250132,
          "from_longitude": 105.83384486032638
        }
      },
      {
        "id": 8136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83380019816306,
            21.008485449401054,
            105.83384747849519,
            21.008526556532164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384747849519,
              21.008485449401054
            ],
            [
              105.83384434410812,
              21.008488293661358
            ],
            [
              105.83382603612215,
              21.00850416181308
            ],
            [
              105.83380019816306,
              21.008526556532164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8144",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008485449401054,
          "to_latitude": 21.008526556532164,
          "to_longitude": 105.83380019816306,
          "from_longitude": 105.83384747849519
        }
      },
      {
        "id": 8137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336629914333,
            21.008353029859865,
            105.83367794726723,
            21.00836975770116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83367794726723,
              21.008353029859865
            ],
            [
              105.83367295046865,
              21.00835947536862
            ],
            [
              105.8336629914333,
              21.00836975770116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8145",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008353029859865,
          "to_latitude": 21.00836975770116,
          "to_longitude": 105.8336629914333,
          "from_longitude": 105.83367794726723
        }
      },
      {
        "id": 8138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83349902817386,
            21.00823879875841,
            105.83353987496514,
            21.008280330424004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353987496514,
              21.00823879875841
            ],
            [
              105.83353596693233,
              21.00824332318908
            ],
            [
              105.83352685085819,
              21.008252456095008
            ],
            [
              105.83349902817386,
              21.008280330424004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8146",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00823879875841,
          "to_latitude": 21.008280330424004,
          "to_longitude": 105.83349902817386,
          "from_longitude": 105.83353987496514
        }
      },
      {
        "id": 8139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83350413229294,
            21.008241699113327,
            105.83354335868799,
            21.00828281458532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83354335868799,
              21.008241699113327
            ],
            [
              105.8335385566168,
              21.008245451149165
            ],
            [
              105.83352979469196,
              21.008254961675334
            ],
            [
              105.83350413229294,
              21.00828281458532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8147",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008241699113327,
          "to_latitude": 21.00828281458532,
          "to_longitude": 105.83350413229294,
          "from_longitude": 105.83354335868799
        }
      },
      {
        "id": 8140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363495031298,
            21.008783619777383,
            105.83365495046309,
            21.008824221092702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83365495046309,
              21.008783619777383
            ],
            [
              105.83364694121003,
              21.008790795397562
            ],
            [
              105.8336412568244,
              21.008795888532447
            ],
            [
              105.83365317191115,
              21.008805778287908
            ],
            [
              105.83363495031298,
              21.008824221092702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3.4_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8148",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008783619777383,
          "to_latitude": 21.008824221092702,
          "to_longitude": 105.83363495031298,
          "from_longitude": 105.83365495046309
        }
      },
      {
        "id": 8141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362289028757,
            21.00878119645704,
            105.8336519936071,
            21.008815771787383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336519936071,
              21.00878119645704
            ],
            [
              105.83364495716401,
              21.008789125117644
            ],
            [
              105.8336390678147,
              21.008795761965604
            ],
            [
              105.8336371191387,
              21.008800804283894
            ],
            [
              105.83362289028757,
              21.008815771787383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3.5_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8149",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00878119645704,
          "to_latitude": 21.008815771787383,
          "to_longitude": 105.83362289028757,
          "from_longitude": 105.8336519936071
        }
      },
      {
        "id": 8142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327103912976,
            21.00848246609367,
            105.8337570225728,
            21.008883482508057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337570225728,
              21.008883482508057
            ],
            [
              105.83364694121003,
              21.008790795397562
            ],
            [
              105.83364495716401,
              21.008789125117644
            ],
            [
              105.83359358068971,
              21.008745866615264
            ],
            [
              105.83357042998551,
              21.008726324024188
            ],
            [
              105.83353323520306,
              21.00869492493382
            ],
            [
              105.83336368057198,
              21.008556382230296
            ],
            [
              105.83335398695739,
              21.008548647509375
            ],
            [
              105.83335181790358,
              21.008546917615703
            ],
            [
              105.83327103912976,
              21.00848246609367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8150",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008883482508057,
          "to_latitude": 21.00848246609367,
          "to_longitude": 105.83327103912976,
          "from_longitude": 105.8337570225728
        }
      },
      {
        "id": 8143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83334102999622,
            21.00854280921014,
            105.83336114948013,
            21.008583928626013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336114948013,
              21.00854280921014
            ],
            [
              105.83335398695739,
              21.008548647509375
            ],
            [
              105.83334653046566,
              21.008554725699437
            ],
            [
              105.83335947038226,
              21.00856816103778
            ],
            [
              105.83334102999622,
              21.008583928626013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3.2_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8151",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00854280921014,
          "to_latitude": 21.008583928626013,
          "to_longitude": 105.83334102999622,
          "from_longitude": 105.83336114948013
        }
      },
      {
        "id": 8144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83332947130285,
            21.008540560064326,
            105.83335840617904,
            21.008573947682816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335840617904,
              21.008540560064326
            ],
            [
              105.83335181790358,
              21.008546917615703
            ],
            [
              105.8333420981412,
              21.008556297339425
            ],
            [
              105.83334405368102,
              21.00855733911772
            ],
            [
              105.83332947130285,
              21.008573947682816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3.1_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8152",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008540560064326,
          "to_latitude": 21.008573947682816,
          "to_longitude": 105.83332947130285,
          "from_longitude": 105.83335840617904
        }
      },
      {
        "id": 8145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357042998551,
            21.00872001631677,
            105.83357735135073,
            21.008726324024188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83357735135073,
              21.00872001631677
            ],
            [
              105.8335722778261,
              21.008724600650897
            ],
            [
              105.83357042998551,
              21.008726324024188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8153",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00872001631677,
          "to_latitude": 21.008726324024188,
          "to_longitude": 105.83357042998551,
          "from_longitude": 105.83357735135073
        }
      },
      {
        "id": 8146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83393305702339,
            21.009034912672703,
            105.83396938653176,
            21.009070442951426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396938653176,
              21.009034912672703
            ],
            [
              105.83396073976225,
              21.00904333878594
            ],
            [
              105.83394354762613,
              21.00906009115943
            ],
            [
              105.83393305702339,
              21.009070442951426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.1_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8154",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009034912672703,
          "to_latitude": 21.009070442951426,
          "to_longitude": 105.83393305702339,
          "from_longitude": 105.83396938653176
        }
      },
      {
        "id": 8147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83395002312905,
            21.009048694024322,
            105.83398580187006,
            21.009082594898484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398580187006,
              21.009048694024322
            ],
            [
              105.83397724768105,
              21.009057163039486
            ],
            [
              105.83396104045565,
              21.00907321058819
            ],
            [
              105.83395002312905,
              21.009082594898484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8155",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009048694024322,
          "to_latitude": 21.009082594898484,
          "to_longitude": 105.83395002312905,
          "from_longitude": 105.83398580187006
        }
      },
      {
        "id": 8148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387836449411,
            21.008974353977635,
            105.83432573470328,
            21.00934628252634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387836449411,
              21.008974353977635
            ],
            [
              105.83396073976225,
              21.00904333878594
            ],
            [
              105.83397724768105,
              21.009057163039486
            ],
            [
              105.83404200761304,
              21.009111395044304
            ],
            [
              105.83406715733453,
              21.00913285525194
            ],
            [
              105.83407798673332,
              21.00914209545865
            ],
            [
              105.83412437487743,
              21.009177355808816
            ],
            [
              105.83426108617883,
              21.009293440154348
            ],
            [
              105.83427257930839,
              21.0093031991581
            ],
            [
              105.83428012735416,
              21.00930960847197
            ],
            [
              105.83432573470328,
              21.00934628252634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8156",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008974353977635,
          "to_latitude": 21.00934628252634,
          "to_longitude": 105.83432573470328,
          "from_longitude": 105.83387836449411
        }
      },
      {
        "id": 8149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83424510536666,
            21.00928604961661,
            105.83426852671387,
            21.00930920661971
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83426852671387,
              21.00928604961661
            ],
            [
              105.83426108617883,
              21.009293440154348
            ],
            [
              105.83425829382763,
              21.009296214100324
            ],
            [
              105.83424510536666,
              21.00930920661971
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.4_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8157",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00928604961661,
          "to_latitude": 21.00930920661971,
          "to_longitude": 105.83424510536666,
          "from_longitude": 105.83426852671387
        }
      },
      {
        "id": 8150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425735406323,
            21.009296609877648,
            105.8342811054696,
            21.009315072632262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342811054696,
              21.009296609877648
            ],
            [
              105.83427257930839,
              21.0093031991581
            ],
            [
              105.83426714774043,
              21.009307397784273
            ],
            [
              105.83425735406323,
              21.009315072632262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8158",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009296609877648,
          "to_latitude": 21.009315072632262,
          "to_longitude": 105.83425735406323,
          "from_longitude": 105.8342811054696
        }
      },
      {
        "id": 8151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83406715733453,
            21.00912466698042,
            105.83407629599141,
            21.00913285525194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83407629599141,
              21.00912466698042
            ],
            [
              105.83406837657498,
              21.009131737405642
            ],
            [
              105.83406715733453,
              21.00913285525194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8159",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00912466698042,
          "to_latitude": 21.00913285525194,
          "to_longitude": 105.83406715733453,
          "from_longitude": 105.83407629599141
        }
      },
      {
        "id": 8152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83305553179721,
            21.008706231331193,
            105.83354340138389,
            21.00910545794734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83305553179721,
              21.008706231331193
            ],
            [
              105.83314136555263,
              21.008776782074047
            ],
            [
              105.83314201297226,
              21.0087773136139
            ],
            [
              105.8332115916483,
              21.008834503904204
            ],
            [
              105.83330339324054,
              21.00891061540251
            ],
            [
              105.83331268656426,
              21.00891832047267
            ],
            [
              105.83343002684765,
              21.009012668507857
            ],
            [
              105.83343769626862,
              21.00901894539543
            ],
            [
              105.83344105024997,
              21.009021690129142
            ],
            [
              105.83354340138389,
              21.00910545794734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8160",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008706231331193,
          "to_latitude": 21.00910545794734,
          "to_longitude": 105.83354340138389,
          "from_longitude": 105.83305553179721
        }
      },
      {
        "id": 8153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313065667829,
            21.008771407867595,
            105.83314782560393,
            21.008791485723957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314782560393,
              21.008771407867595
            ],
            [
              105.83314201297226,
              21.0087773136139
            ],
            [
              105.83313597269466,
              21.008783450797054
            ],
            [
              105.83313565378926,
              21.008785782680253
            ],
            [
              105.83313065667829,
              21.008791485723957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4.2_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8161",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008771407867595,
          "to_latitude": 21.008791485723957,
          "to_longitude": 105.83313065667829,
          "from_longitude": 105.83314782560393
        }
      },
      {
        "id": 8154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312955270084,
            21.00877068978994,
            105.83314690589471,
            21.008790669193534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314690589471,
              21.00877068978994
            ],
            [
              105.83314136555263,
              21.008776782074047
            ],
            [
              105.83313545713304,
              21.008783279901845
            ],
            [
              105.83313582961826,
              21.008783668271676
            ],
            [
              105.83312955270084,
              21.008790669193534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4.1_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8162",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00877068978994,
          "to_latitude": 21.008790669193534,
          "to_longitude": 105.83312955270084,
          "from_longitude": 105.83314690589471
        }
      },
      {
        "id": 8155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83330339324054,
            21.008900991440342,
            105.83331152839409,
            21.00891061540251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83331152839409,
              21.008900991440342
            ],
            [
              105.83330838416725,
              21.008905011738975
            ],
            [
              105.83330339324054,
              21.00891061540251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8163",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008900991440342,
          "to_latitude": 21.00891061540251,
          "to_longitude": 105.83330339324054,
          "from_longitude": 105.83331152839409
        }
      },
      {
        "id": 8156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83342504466358,
            21.009015671772005,
            105.83344846092163,
            21.009058637278013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344846092163,
              21.009015671772005
            ],
            [
              105.83344105024997,
              21.009021690129142
            ],
            [
              105.83343116999643,
              21.00902971490275
            ],
            [
              105.8334439598626,
              21.009041410451985
            ],
            [
              105.83342504466358,
              21.009058637278013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4.4_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8164",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009015671772005,
          "to_latitude": 21.009058637278013,
          "to_longitude": 105.83342504466358,
          "from_longitude": 105.83344846092163
        }
      },
      {
        "id": 8157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341291895573,
            21.009012556425862,
            105.83344498175919,
            21.00904736931323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344498175919,
              21.009012556425862
            ],
            [
              105.83343769626862,
              21.00901894539543
            ],
            [
              105.83342587444695,
              21.009029310902694
            ],
            [
              105.83342846996932,
              21.009031968125463
            ],
            [
              105.83341291895573,
              21.00904736931323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4.5_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8165",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009012556425862,
          "to_latitude": 21.00904736931323,
          "to_longitude": 105.83341291895573,
          "from_longitude": 105.83344498175919
        }
      },
      {
        "id": 8158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372837421972,
            21.009273825078164,
            105.83376344123691,
            21.009308595839588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376344123691,
              21.009273825078164
            ],
            [
              105.83375426722857,
              21.009281887958366
            ],
            [
              105.83374564266761,
              21.009289468533403
            ],
            [
              105.83372837421972,
              21.009308595839588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4.1_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8166",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009273825078164,
          "to_latitude": 21.009308595839588,
          "to_longitude": 105.83372837421972,
          "from_longitude": 105.83376344123691
        }
      },
      {
        "id": 8159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366919901673,
            21.00921176463339,
            105.8340649727656,
            21.009536366517253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366919901673,
              21.00921176463339
            ],
            [
              105.83375426722857,
              21.009281887958366
            ],
            [
              105.8338123760697,
              21.009329787606685
            ],
            [
              105.83388006587501,
              21.009391958212934
            ],
            [
              105.83388277396877,
              21.009394445070246
            ],
            [
              105.83402672675194,
              21.009505390892166
            ],
            [
              105.83404252103313,
              21.00951818263524
            ],
            [
              105.83404554732043,
              21.009520633607682
            ],
            [
              105.8340649727656,
              21.009536366517253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8167",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00921176463339,
          "to_latitude": 21.009536366517253,
          "to_longitude": 105.8340649727656,
          "from_longitude": 105.83366919901673
        }
      },
      {
        "id": 8160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388006587501,
            21.00938048083338,
            105.83389151310612,
            21.009391958212934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83389151310612,
              21.00938048083338
            ],
            [
              105.83388231706728,
              21.009389598795533
            ],
            [
              105.83388006587501,
              21.009391958212934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8168",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00938048083338,
          "to_latitude": 21.009391958212934,
          "to_longitude": 105.83388006587501,
          "from_longitude": 105.83389151310612
        }
      },
      {
        "id": 8161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340256080781,
            21.00951403518825,
            105.83405188609832,
            21.00954675144899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405188609832,
              21.00951403518825
            ],
            [
              105.83404554732043,
              21.009520633607682
            ],
            [
              105.83403490395553,
              21.00953171415444
            ],
            [
              105.83403754925955,
              21.009533979123443
            ],
            [
              105.8340256080781,
              21.00954675144899
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.3_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8169",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00951403518825,
          "to_latitude": 21.00954675144899,
          "to_longitude": 105.8340256080781,
          "from_longitude": 105.83405188609832
        }
      },
      {
        "id": 8162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402202671004,
            21.009511715807818,
            105.83404910101689,
            21.009543423455295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83402202671004,
              21.009543423455295
            ],
            [
              105.83403362252854,
              21.009533066240706
            ],
            [
              105.83403040193183,
              21.009530095059418
            ],
            [
              105.83404252103313,
              21.00951818263524
            ],
            [
              105.83404910101689,
              21.009511715807818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4.4_Ngõ 4C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8170",
          "diaChiLapD": "Ngõ 4C, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009543423455295,
          "to_latitude": 21.009511715807818,
          "to_longitude": 105.83404910101689,
          "from_longitude": 105.83402202671004
        }
      },
      {
        "id": 8163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423046047936,
            21.008842621767453,
            105.83426083333616,
            21.00887564535794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83426083333616,
              21.008842621767453
            ],
            [
              105.83425586612903,
              21.008847467535578
            ],
            [
              105.8342497534787,
              21.008854247305017
            ],
            [
              105.83423046047936,
              21.00887564535794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8171",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008842621767453,
          "to_latitude": 21.00887564535794,
          "to_longitude": 105.83423046047936,
          "from_longitude": 105.83426083333616
        }
      },
      {
        "id": 8164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425345414308,
            21.008863843723322,
            105.83428372511503,
            21.00889166333715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428372511503,
              21.008863843723322
            ],
            [
              105.83427900762246,
              21.00886792955857
            ],
            [
              105.83427280370667,
              21.008873691841302
            ],
            [
              105.83425345414308,
              21.00889166333715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8172",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008863843723322,
          "to_latitude": 21.00889166333715,
          "to_longitude": 105.83425345414308,
          "from_longitude": 105.83428372511503
        }
      },
      {
        "id": 8165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411678206289,
            21.008742075438516,
            105.83471954962847,
            21.009241872208836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411678206289,
              21.008742075438516
            ],
            [
              105.8342497534787,
              21.008854247305017
            ],
            [
              105.83427280370667,
              21.008873691841302
            ],
            [
              105.83432837516813,
              21.00892057136834
            ],
            [
              105.83445098691229,
              21.009021431283692
            ],
            [
              105.83452620006351,
              21.009083301901427
            ],
            [
              105.834569301183,
              21.009118851924914
            ],
            [
              105.83457480538668,
              21.00912339163756
            ],
            [
              105.83461651698113,
              21.00915780136687
            ],
            [
              105.83467129618684,
              21.009202975757844
            ],
            [
              105.83471954962847,
              21.009241872208836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8173",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008742075438516,
          "to_latitude": 21.009241872208836,
          "to_longitude": 105.83471954962847,
          "from_longitude": 105.83411678206289
        }
      },
      {
        "id": 8166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445098691229,
            21.009006155373193,
            105.83446417994587,
            21.009021431283692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446417994587,
              21.009006155373193
            ],
            [
              105.8344602132612,
              21.009010509402508
            ],
            [
              105.83445098691229,
              21.009021431283692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8174",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009006155373193,
          "to_latitude": 21.009021431283692,
          "to_longitude": 105.83445098691229,
          "from_longitude": 105.83446417994587
        }
      },
      {
        "id": 8167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456275919858,
            21.009103141728392,
            105.83458540537607,
            21.00913577522408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83458540537607,
              21.009103141728392
            ],
            [
              105.83457904330706,
              21.009108658217205
            ],
            [
              105.83457946031119,
              21.009118606101524
            ],
            [
              105.83457480538668,
              21.00912339163756
            ],
            [
              105.83456275919858,
              21.00913577522408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8175",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009103141728392,
          "to_latitude": 21.00913577522408,
          "to_longitude": 105.83456275919858,
          "from_longitude": 105.83458540537607
        }
      },
      {
        "id": 8168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455891229897,
            21.009098872192823,
            105.83458043075585,
            21.009130536415952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83458043075585,
              21.009098872192823
            ],
            [
              105.83457290079919,
              21.009106091569407
            ],
            [
              105.83457645811788,
              21.00911080250745
            ],
            [
              105.834569301183,
              21.009118851924914
            ],
            [
              105.83455891229897,
              21.009130536415952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8176",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009098872192823,
          "to_latitude": 21.009130536415952,
          "to_longitude": 105.83455891229897,
          "from_longitude": 105.83458043075585
        }
      },
      {
        "id": 8169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83480164336487,
            21.009306667911908,
            105.83529221904166,
            21.009710822985486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480164336487,
              21.009306667911908
            ],
            [
              105.8349107774529,
              21.009398158500023
            ],
            [
              105.83491292383869,
              21.009399958032343
            ],
            [
              105.83494547139105,
              21.009427243739243
            ],
            [
              105.83505575766391,
              21.009518154123622
            ],
            [
              105.83510974367056,
              21.009562655740794
            ],
            [
              105.83520799157164,
              21.009642432332598
            ],
            [
              105.83521229725038,
              21.009645928358218
            ],
            [
              105.83529221904166,
              21.009710822985486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8177",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009306667911908,
          "to_latitude": 21.009710822985486,
          "to_longitude": 105.83529221904166,
          "from_longitude": 105.83480164336487
        }
      },
      {
        "id": 8170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520523831251,
            21.0096227020463,
            105.83522773050794,
            21.009652745321702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83522773050794,
              21.0096227020463
            ],
            [
              105.83521759740948,
              21.009632295258083
            ],
            [
              105.8352196039952,
              21.009638873533206
            ],
            [
              105.83521229725038,
              21.009645928358218
            ],
            [
              105.83520523831251,
              21.009652745321702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8178",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0096227020463,
          "to_latitude": 21.009652745321702,
          "to_longitude": 105.83520523831251,
          "from_longitude": 105.83522773050794
        }
      },
      {
        "id": 8171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520209963729,
            21.00962128004477,
            105.83522595654748,
            21.00964911621341
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83522595654748,
              21.00962128004477
            ],
            [
              105.83521364731858,
              21.0096318530978
            ],
            [
              105.83521550942811,
              21.009633904214695
            ],
            [
              105.83520799157164,
              21.009642432332598
            ],
            [
              105.83520209963729,
              21.00964911621341
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8179",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00962128004477,
          "to_latitude": 21.00964911621341,
          "to_longitude": 105.83520209963729,
          "from_longitude": 105.83522595654748
        }
      },
      {
        "id": 8172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505575766391,
            21.009498844915104,
            105.8350732558043,
            21.009518154123622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350732558043,
              21.009498844915104
            ],
            [
              105.83506550480827,
              21.009506617268315
            ],
            [
              105.83505575766391,
              21.009518154123622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8180",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009498844915104,
          "to_latitude": 21.009518154123622,
          "to_longitude": 105.83505575766391,
          "from_longitude": 105.8350732558043
        }
      },
      {
        "id": 8173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487459071239,
            21.009382534014147,
            105.83492781515459,
            21.009432525823204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83492781515459,
              21.009382534014147
            ],
            [
              105.83492328056919,
              21.00938768296881
            ],
            [
              105.83492056557293,
              21.009393466092064
            ],
            [
              105.83491292383869,
              21.009399958032343
            ],
            [
              105.83487459071239,
              21.009432525823204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8181",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009382534014147,
          "to_latitude": 21.009432525823204,
          "to_longitude": 105.83487459071239,
          "from_longitude": 105.83492781515459
        }
      },
      {
        "id": 8174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487189270892,
            21.009380489067418,
            105.83492524705451,
            21.00942787932128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83492524705451,
              21.009380489067418
            ],
            [
              105.83491798788644,
              21.00938762403365
            ],
            [
              105.83492070912037,
              21.009390567841418
            ],
            [
              105.8349107774529,
              21.009398158500023
            ],
            [
              105.83487189270892,
              21.00942787932128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8182",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009380489067418,
          "to_latitude": 21.00942787932128,
          "to_longitude": 105.83487189270892,
          "from_longitude": 105.83492524705451
        }
      },
      {
        "id": 8175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83542993571082,
            21.009812407296852,
            105.83547005393228,
            21.00985001493861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83547005393228,
              21.009812407296852
            ],
            [
              105.8354564866845,
              21.00982266047945
            ],
            [
              105.83544922560067,
              21.00983014141247
            ],
            [
              105.83542993571082,
              21.00985001493861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2.2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8183",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009812407296852,
          "to_latitude": 21.00985001493861,
          "to_longitude": 105.83542993571082,
          "from_longitude": 105.83547005393228
        }
      },
      {
        "id": 8176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83542571052105,
            21.009809278348296,
            105.8354660427041,
            21.009845255815144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8354660427041,
              21.009809278348296
            ],
            [
              105.83545481706192,
              21.00982166609688
            ],
            [
              105.83544686533597,
              21.009828110808577
            ],
            [
              105.83542571052105,
              21.009845255815144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2.1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8184",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009809278348296,
          "to_latitude": 21.009845255815144,
          "to_longitude": 105.83542571052105,
          "from_longitude": 105.8354660427041
        }
      },
      {
        "id": 8177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537417461382,
            21.00976557069388,
            105.83584635476367,
            21.01016760417647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83537417461382,
              21.00976557069388
            ],
            [
              105.83544686533597,
              21.009828110808577
            ],
            [
              105.83544922560067,
              21.00983014141247
            ],
            [
              105.83550881603787,
              21.009881411244333
            ],
            [
              105.83570301211095,
              21.0100475614874
            ],
            [
              105.8357136562503,
              21.0100566688168
            ],
            [
              105.83573374119838,
              21.010073847964993
            ],
            [
              105.83576543299253,
              21.010100967901064
            ],
            [
              105.83584635476367,
              21.01016760417647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8185",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00976557069388,
          "to_latitude": 21.01016760417647,
          "to_longitude": 105.83584635476367,
          "from_longitude": 105.83537417461382
        }
      },
      {
        "id": 8178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570301211095,
            21.010024546065765,
            105.83572312903618,
            21.0100475614874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572312903618,
              21.010024546065765
            ],
            [
              105.83571205295573,
              21.010036671161103
            ],
            [
              105.83570301211095,
              21.0100475614874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8186",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010024546065765,
          "to_latitude": 21.0100475614874,
          "to_longitude": 105.83570301211095,
          "from_longitude": 105.83572312903618
        }
      },
      {
        "id": 8179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83567894537265,
            21.010049927563593,
            105.83578004477066,
            21.01009056660834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83578004477066,
              21.01007204803306
            ],
            [
              105.83576917559023,
              21.010084189277066
            ],
            [
              105.83572055977649,
              21.010049927563593
            ],
            [
              105.8357136562503,
              21.0100566688168
            ],
            [
              105.83567894537265,
              21.01009056660834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2.4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8187",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01007204803306,
          "to_latitude": 21.01009056660834,
          "to_longitude": 105.83567894537265,
          "from_longitude": 105.83578004477066
        }
      },
      {
        "id": 8180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356990544218,
            21.010071210066023,
            105.83578685499079,
            21.010108100609596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356990544218,
              21.010108100609596
            ],
            [
              105.83573374119838,
              21.010073847964993
            ],
            [
              105.83573641405565,
              21.010071210066023
            ],
            [
              105.83576686224836,
              21.010087648248714
            ],
            [
              105.8357737920701,
              21.010092534982277
            ],
            [
              105.83578685499079,
              21.01007722167583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2.5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8188",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010108100609596,
          "to_latitude": 21.01007722167583,
          "to_longitude": 105.83578685499079,
          "from_longitude": 105.8356990544218
        }
      },
      {
        "id": 8181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589103302353,
            21.010199693970275,
            105.83615919296479,
            21.010423912640373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589103302353,
              21.010199693970275
            ],
            [
              105.8359922068188,
              21.010284289766407
            ],
            [
              105.83599534473456,
              21.010286913573353
            ],
            [
              105.83601980636445,
              21.010307366147703
            ],
            [
              105.83606657553601,
              21.0103464718062
            ],
            [
              105.83606861658117,
              21.01034817790517
            ],
            [
              105.83615919296479,
              21.010423912640373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8189",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010199693970275,
          "to_latitude": 21.010423912640373,
          "to_longitude": 105.83615919296479,
          "from_longitude": 105.83589103302353
        }
      },
      {
        "id": 8182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601980636445,
            21.010284584798317,
            105.836043013245,
            21.010307366147703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.836043013245,
              21.010284584798317
            ],
            [
              105.83603046385326,
              21.010296075862783
            ],
            [
              105.83601980636445,
              21.010307366147703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1.3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8190",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010284584798317,
          "to_latitude": 21.010307366147703,
          "to_longitude": 105.83601980636445,
          "from_longitude": 105.836043013245
        }
      },
      {
        "id": 8183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835986569298,
            21.010261866461907,
            105.83601699417642,
            21.01029537203216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83601699417642,
              21.010261866461907
            ],
            [
              105.83600400207874,
              21.010273661376566
            ],
            [
              105.83600570647499,
              21.01027692448353
            ],
            [
              105.83599534473456,
              21.010286913573353
            ],
            [
              105.835986569298,
              21.01029537203216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1.4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8191",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010261866461907,
          "to_latitude": 21.01029537203216,
          "to_longitude": 105.835986569298,
          "from_longitude": 105.83601699417642
        }
      },
      {
        "id": 8184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598304151113,
            21.010259663250682,
            105.836014470374,
            21.01029252162452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.836014470374,
              21.010259663250682
            ],
            [
              105.83600003820477,
              21.0102735110445
            ],
            [
              105.83600169563397,
              21.010275768189715
            ],
            [
              105.8359922068188,
              21.010284289766407
            ],
            [
              105.83598304151113,
              21.01029252162452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1.5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8192",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010259663250682,
          "to_latitude": 21.01029252162452,
          "to_longitude": 105.83598304151113,
          "from_longitude": 105.836014470374
        }
      },
      {
        "id": 8185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360597475477,
            21.01032584586575,
            105.83609026864416,
            21.010356238505373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83609026864416,
              21.01032584586575
            ],
            [
              105.83607923395537,
              21.010334349752462
            ],
            [
              105.83608132149605,
              21.01033663188028
            ],
            [
              105.83606861658117,
              21.01034817790517
            ],
            [
              105.8360597475477,
              21.010356238505373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1.2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8193",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01032584586575,
          "to_latitude": 21.010356238505373,
          "to_longitude": 105.8360597475477,
          "from_longitude": 105.83609026864416
        }
      },
      {
        "id": 8186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605775185214,
            21.01032349535322,
            105.83608757664774,
            21.01035438495785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608757664774,
              21.01032349535322
            ],
            [
              105.83607474294598,
              21.01033437001201
            ],
            [
              105.83607717084868,
              21.01033697020918
            ],
            [
              105.83606657553601,
              21.0103464718062
            ],
            [
              105.83605775185214,
              21.01035438495785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1.1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8194",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01032349535322,
          "to_latitude": 21.01035438495785,
          "to_longitude": 105.83605775185214,
          "from_longitude": 105.83608757664774
        }
      },
      {
        "id": 8187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490484791879,
            21.010104749905814,
            105.8349626450935,
            21.010143709641206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83490484791879,
              21.010104749905814
            ],
            [
              105.83493463860583,
              21.010128886040373
            ],
            [
              105.8349626450935,
              21.010143709641206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.2_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8195",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010104749905814,
          "to_latitude": 21.010143709641206,
          "to_longitude": 105.8349626450935,
          "from_longitude": 105.83490484791879
        }
      },
      {
        "id": 8188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489359527249,
            21.01011782871858,
            105.83495975132087,
            21.010146926676452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489359527249,
              21.01011782871858
            ],
            [
              105.83492122048361,
              21.010142786576214
            ],
            [
              105.83493757909118,
              21.010131816126304
            ],
            [
              105.83495975132087,
              21.010146926676452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.1_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.8196",
          "diaChiLapD": "Ngõ 46A, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01011782871858,
          "to_latitude": 21.010146926676452,
          "to_longitude": 105.83495975132087,
          "from_longitude": 105.83489359527249
        }
      },
      {
        "id": 8189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80948400533433,
            21.0133586732494,
            105.80960763898243,
            21.013460084441153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80960763898243,
              21.0133586732494
            ],
            [
              105.80948400533433,
              21.013460084441153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.5_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.8197",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0133586732494,
          "to_latitude": 21.013460084441153,
          "to_longitude": 105.80948400533433,
          "from_longitude": 105.80960763898243
        }
      },
      {
        "id": 8190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80958937672254,
            21.013275408138732,
            105.80963327223334,
            21.013318197033513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80958937672254,
              21.013318197033513
            ],
            [
              105.80963327223334,
              21.013275408138732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.4_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.8198",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013318197033513,
          "to_latitude": 21.013275408138732,
          "to_longitude": 105.80963327223334,
          "from_longitude": 105.80958937672254
        }
      },
      {
        "id": 8191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80971794656641,
            21.013448087730417,
            105.80972659922371,
            21.0134555498384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80972659922371,
              21.013448087730417
            ],
            [
              105.80972406321496,
              21.013449801482917
            ],
            [
              105.80971794656641,
              21.0134555498384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_46_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.8199",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013448087730417,
          "to_latitude": 21.0134555498384,
          "to_longitude": 105.80971794656641,
          "from_longitude": 105.80972659922371
        }
      },
      {
        "id": 8192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80958937672254,
            21.013318197033513,
            105.80966727260477,
            21.013408923639318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80958937672254,
              21.013318197033513
            ],
            [
              105.80959380193582,
              21.013323787739296
            ],
            [
              105.8095995608179,
              21.01333106384695
            ],
            [
              105.8096646006508,
              21.0133988153724
            ],
            [
              105.80966727260477,
              21.01340161531255
            ],
            [
              105.80966465969927,
              21.013403696141076
            ],
            [
              105.80965809871455,
              21.013408923639318
            ],
            [
              105.80965554172263,
              21.013406393217448
            ],
            [
              105.80966243634771,
              21.013401494713438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_54.4_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DODV.8200",
          "diaChiLapD": "Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013318197033513,
          "to_latitude": 21.013401494713438,
          "to_longitude": 105.80966243634771,
          "from_longitude": 105.80958937672254
        }
      },
      {
        "id": 8193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80903869367039,
            21.015783253542534,
            105.8092193602204,
            21.016133728647493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8092193602204,
              21.016133728647493
            ],
            [
              105.80913764218128,
              21.01598966664799
            ],
            [
              105.80903869367039,
              21.015815227858052
            ],
            [
              105.80905360015011,
              21.015804211642596
            ],
            [
              105.80906930857128,
              21.01579260180367
            ],
            [
              105.80907590141265,
              21.015787729143568
            ],
            [
              105.80907231423711,
              21.015783253542534
            ],
            [
              105.80906915600586,
              21.01578464779247
            ],
            [
              105.80906589676351,
              21.015786040716268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.8201",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016133728647493,
          "to_latitude": 21.015786040716268,
          "to_longitude": 105.80906589676351,
          "from_longitude": 105.8092193602204
        }
      },
      {
        "id": 8194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80917050079469,
            21.016487429703353,
            105.8094408350471,
            21.016621162923407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8094408350471,
              21.016488911173536
            ],
            [
              105.80943839670272,
              21.01649037245719
            ],
            [
              105.80942762180058,
              21.016517441101424
            ],
            [
              105.80924070470604,
              21.016621162923407
            ],
            [
              105.80917050079469,
              21.016487429703353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.2_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.8202",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016488911173536,
          "to_latitude": 21.016487429703353,
          "to_longitude": 105.80917050079469,
          "from_longitude": 105.8094408350471
        }
      },
      {
        "id": 8195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809249482007,
            21.016495269984304,
            105.80944480468756,
            21.016787041042196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80944480468756,
              21.016495269984304
            ],
            [
              105.80944190701707,
              21.016497355778068
            ],
            [
              105.80943372434791,
              21.01652349350525
            ],
            [
              105.80942732067889,
              21.016544172685553
            ],
            [
              105.809249482007,
              21.016641757238848
            ],
            [
              105.80928060937111,
              21.016674683598147
            ],
            [
              105.80928484786547,
              21.01668148642537
            ],
            [
              105.8093506048168,
              21.016787041042196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.1_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.8203",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016495269984304,
          "to_latitude": 21.016787041042196,
          "to_longitude": 105.8093506048168,
          "from_longitude": 105.80944480468756
        }
      },
      {
        "id": 8196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80920873018752,
            21.01668148642537,
            105.80928484786547,
            21.016720410954324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80928484786547,
              21.01668148642537
            ],
            [
              105.80920873018752,
              21.016720410954324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.1_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.8204",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01668148642537,
          "to_latitude": 21.016720410954324,
          "to_longitude": 105.80920873018752,
          "from_longitude": 105.80928484786547
        }
      },
      {
        "id": 8197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80956048599126,
            21.016612860315128,
            105.80982953047028,
            21.01670712008387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80956048599126,
              21.016679282581954
            ],
            [
              105.8095626446843,
              21.016678050240948
            ],
            [
              105.80957610373476,
              21.0166708244924
            ],
            [
              105.80959075399218,
              21.01669716156867
            ],
            [
              105.80963242065594,
              21.01670712008387
            ],
            [
              105.80981735017822,
              21.016619609414047
            ],
            [
              105.80982953047028,
              21.016612860315128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.1_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.8205",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016679282581954,
          "to_latitude": 21.016612860315128,
          "to_longitude": 105.80982953047028,
          "from_longitude": 105.80956048599126
        }
      },
      {
        "id": 8198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8089661970712,
            21.015979843027836,
            105.80916083262731,
            21.01608262034519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80916083262731,
              21.015979843027836
            ],
            [
              105.80915796670492,
              21.01598157189033
            ],
            [
              105.80913764218128,
              21.01598966664799
            ],
            [
              105.80910706804444,
              21.016001843969786
            ],
            [
              105.8089661970712,
              21.01608262034519
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.1_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DODV.8206",
          "diaChiLapD": "Ngõ 49, Huỳnh Thúc Kháng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015979843027836,
          "to_latitude": 21.01608262034519,
          "to_longitude": 105.8089661970712,
          "from_longitude": 105.80916083262731
        }
      },
      {
        "id": 8199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81190967703465,
            21.015692255511034,
            105.81219454301826,
            21.015740877308726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81219454301826,
              21.01571307919871
            ],
            [
              105.81219350903588,
              21.015710789031655
            ],
            [
              105.81218276191656,
              21.015692255511034
            ],
            [
              105.81206459494696,
              21.01572579916839
            ],
            [
              105.81191155953357,
              21.015740695808187
            ],
            [
              105.81190967703465,
              21.015740877308726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.1_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.8207",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01571307919871,
          "to_latitude": 21.015740877308726,
          "to_longitude": 105.81190967703465,
          "from_longitude": 105.81219454301826
        }
      },
      {
        "id": 8200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80817516648848,
            21.017446623086656,
            105.80864756239437,
            21.017645099229426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80817516648848,
              21.017645099229426
            ],
            [
              105.80818916987391,
              21.017639248425173
            ],
            [
              105.80859555938548,
              21.017446623086656
            ],
            [
              105.80864756239437,
              21.017532409344046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.1_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DODV.8208",
          "diaChiLapD": "Ngõ 99, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017645099229426,
          "to_latitude": 21.017532409344046,
          "to_longitude": 105.80864756239437,
          "from_longitude": 105.80817516648848
        }
      },
      {
        "id": 8201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82197085185798,
            21.013300712036838,
            105.82229132194003,
            21.0134403988268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82229132194003,
              21.013300712036838
            ],
            [
              105.82229130280602,
              21.013300731097072
            ],
            [
              105.82227819543027,
              21.013313680781703
            ],
            [
              105.82227816669707,
              21.013313703501254
            ],
            [
              105.82227263384148,
              21.013319167666545
            ],
            [
              105.82226747142214,
              21.01332324539458
            ],
            [
              105.82211917009808,
              21.0134403988268
            ],
            [
              105.82197085185798,
              21.013312066870935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34.1_Trung Liệt",
          "maTaiSan": "04.O12.DODV.8209",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013300712036838,
          "to_latitude": 21.013312066870935,
          "to_longitude": 105.82197085185798,
          "from_longitude": 105.82229132194003
        }
      },
      {
        "id": 8202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291575565093,
            21.01779812185687,
            105.82935759701513,
            21.017981919416936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82935759701513,
              21.01779812185687
            ],
            [
              105.82929708181722,
              21.017833301241
            ],
            [
              105.8291575565093,
              21.01791838956951
            ],
            [
              105.82918800104393,
              21.01795783224918
            ],
            [
              105.82921339605653,
              21.017981919416936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A26.1_Đông Các",
          "maTaiSan": "04.O12.DODV.8210",
          "diaChiLapD": "Đông Các",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01779812185687,
          "to_latitude": 21.017981919416936,
          "to_longitude": 105.82921339605653,
          "from_longitude": 105.82935759701513
        }
      },
      {
        "id": 8203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81679878741296,
            21.017082661472255,
            105.81688028298929,
            21.017152713308704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81679878741296,
              21.017082661472255
            ],
            [
              105.81686652230694,
              21.017135509241275
            ],
            [
              105.81688028298929,
              21.01714557557102
            ],
            [
              105.8168776363804,
              21.017148172413197
            ],
            [
              105.81687135168475,
              21.017152713308704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34.2_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.8211",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017082661472255,
          "to_latitude": 21.017152713308704,
          "to_longitude": 105.81687135168475,
          "from_longitude": 105.81679878741296
        }
      },
      {
        "id": 8204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704446502361,
            21.017194903596618,
            105.81720071401766,
            21.017246537572557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81704446502361,
              21.017194903596618
            ],
            [
              105.81712764307734,
              21.0172140047693
            ],
            [
              105.81714618077845,
              21.017219471478217
            ],
            [
              105.81715762232048,
              21.01722284607404
            ],
            [
              105.81720071401766,
              21.017246537572557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.8212",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017194903596618,
          "to_latitude": 21.017246537572557,
          "to_longitude": 105.81720071401766,
          "from_longitude": 105.81704446502361
        }
      },
      {
        "id": 8205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81714117699048,
            21.017219471478217,
            105.81715058531375,
            21.017233330467253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81715058531375,
              21.0172264909789
            ],
            [
              105.8171465843109,
              21.017233330467253
            ],
            [
              105.81714117699048,
              21.01723152830843
            ],
            [
              105.81714403782715,
              21.017224641909646
            ],
            [
              105.81714618077845,
              21.017219471478217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DODV.8213",
          "diaChiLapD": "Mai Anh Tuấn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0172264909789,
          "to_latitude": 21.017219471478217,
          "to_longitude": 105.81714618077845,
          "from_longitude": 105.81715058531375
        }
      },
      {
        "id": 8206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81995166683468,
            21.019815003045228,
            105.81998550187015,
            21.0201904829996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81998550187015,
              21.019815003045228
            ],
            [
              105.8199696958128,
              21.019958916272635
            ],
            [
              105.8199651360544,
              21.0200004297741
            ],
            [
              105.81995166683468,
              21.0201904829996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48*_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8214",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019815003045228,
          "to_latitude": 21.0201904829996,
          "to_longitude": 105.81995166683468,
          "from_longitude": 105.81998550187015
        }
      },
      {
        "id": 8207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81994672889451,
            21.019956109561033,
            105.8199696958128,
            21.019958916272635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994672889451,
              21.019956109561033
            ],
            [
              105.81996540257876,
              21.01995821709649
            ],
            [
              105.8199696958128,
              21.019958916272635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48*_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8215",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019956109561033,
          "to_latitude": 21.019958916272635,
          "to_longitude": 105.8199696958128,
          "from_longitude": 105.81994672889451
        }
      },
      {
        "id": 8208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993665798424,
            21.02007625173363,
            105.82031757024096,
            21.020236362485093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81993665798424,
              21.020236362485093
            ],
            [
              105.81994303879057,
              21.020234439467504
            ],
            [
              105.82031757024096,
              21.02007625173363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44'_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8216",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020236362485093,
          "to_latitude": 21.02007625173363,
          "to_longitude": 105.82031757024096,
          "from_longitude": 105.81993665798424
        }
      },
      {
        "id": 8209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81973178074021,
            21.020336915477678,
            105.81995175468553,
            21.02037732116598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81995175468553,
              21.020336915477678
            ],
            [
              105.81993440715586,
              21.02033914155334
            ],
            [
              105.81973178074021,
              21.02037732116598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT116_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8217",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020336915477678,
          "to_latitude": 21.02037732116598,
          "to_longitude": 105.81973178074021,
          "from_longitude": 105.81995175468553
        }
      },
      {
        "id": 8210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81965337568829,
            21.020002502258027,
            105.81993620300553,
            21.020223586981185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81993620300553,
              21.020208570589336
            ],
            [
              105.81991504620147,
              21.02021028844353
            ],
            [
              105.81991161253897,
              21.02021056783346
            ],
            [
              105.81969683472833,
              21.020223586981185
            ],
            [
              105.81965337568829,
              21.020002502258027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46*_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8218",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020208570589336,
          "to_latitude": 21.020002502258027,
          "to_longitude": 105.81965337568829,
          "from_longitude": 105.81993620300553
        }
      },
      {
        "id": 8211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81991382796028,
            21.02006649537638,
            105.81992979812033,
            21.02031675381558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81992466368544,
              21.02006649537638
            ],
            [
              105.81991382796028,
              21.020188928490374
            ],
            [
              105.81991414074226,
              21.020194408664963
            ],
            [
              105.81991504620147,
              21.02021028844353
            ],
            [
              105.81991585488942,
              21.020224479654768
            ],
            [
              105.81992979812033,
              21.02031675381558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46ps_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8219",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02006649537638,
          "to_latitude": 21.02031675381558,
          "to_longitude": 105.81992979812033,
          "from_longitude": 105.81992466368544
        }
      },
      {
        "id": 8212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81991414074226,
            21.02019305889741,
            105.81993594888543,
            21.020194638481033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81991414074226,
              21.020194408664963
            ],
            [
              105.81991780613716,
              21.020194638481033
            ],
            [
              105.81993594888543,
              21.02019305889741
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46ps_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DODV.8220",
          "diaChiLapD": "Ngõ 29, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020194408664963,
          "to_latitude": 21.02019305889741,
          "to_longitude": 105.81993594888543,
          "from_longitude": 105.81991414074226
        }
      },
      {
        "id": 8213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357463141306,
            21.01585994470798,
            105.83383706703559,
            21.016077932444023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83357463141306,
              21.016077932444023
            ],
            [
              105.83357589190474,
              21.016076437753604
            ],
            [
              105.83358935177706,
              21.016075646513485
            ],
            [
              105.83370372439121,
              21.01596469755867
            ],
            [
              105.83376881374987,
              21.01591620057152
            ],
            [
              105.83383706703559,
              21.01585994470798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28.3_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.8221",
          "diaChiLapD": "Ngách 216/8, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016077932444023,
          "to_latitude": 21.01585994470798,
          "to_longitude": 105.83383706703559,
          "from_longitude": 105.83357463141306
        }
      },
      {
        "id": 8214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353808207296,
            21.016079863912015,
            105.83359711069217,
            21.01617894120682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335869059164,
              21.016079863912015
            ],
            [
              105.8335865970032,
              21.016081398197972
            ],
            [
              105.83359711069217,
              21.0161069585221
            ],
            [
              105.83353808207296,
              21.01617894120682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28.4_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.8222",
          "diaChiLapD": "Ngách 216/8, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016079863912015,
          "to_latitude": 21.01617894120682,
          "to_longitude": 105.83353808207296,
          "from_longitude": 105.8335869059164
        }
      },
      {
        "id": 8215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359711069217,
            21.0161069585221,
            105.83364458970661,
            21.01615499871712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83359711069217,
              21.0161069585221
            ],
            [
              105.83364458970661,
              21.01615499871712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28.4_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DODV.8223",
          "diaChiLapD": "Ngách 216/8, Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0161069585221,
          "to_latitude": 21.01615499871712,
          "to_longitude": 105.83364458970661,
          "from_longitude": 105.83359711069217
        }
      },
      {
        "id": 8216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314853031689,
            21.01738074656102,
            105.83322268894463,
            21.017466723435636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83322268894463,
              21.017386222524806
            ],
            [
              105.8332130409656,
              21.01738074656102
            ],
            [
              105.83319686377928,
              21.01740722072764
            ],
            [
              105.83314853031689,
              21.017466723435636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT7.7A_Ngách 60, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.8224",
          "diaChiLapD": "Ngách 60, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017386222524806,
          "to_latitude": 21.017466723435636,
          "to_longitude": 105.83314853031689,
          "from_longitude": 105.83322268894463
        }
      },
      {
        "id": 8217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402039619874,
            21.01735018137588,
            105.83414761463031,
            21.017509091031926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.834144181918,
              21.01735018137588
            ],
            [
              105.83414761463031,
              21.01735528402042
            ],
            [
              105.83410081610808,
              21.017416211975824
            ],
            [
              105.83402039619874,
              21.017509091031926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT13.2A_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.8225",
          "diaChiLapD": "Ngách 79, Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01735018137588,
          "to_latitude": 21.017509091031926,
          "to_longitude": 105.83402039619874,
          "from_longitude": 105.834144181918
        }
      },
      {
        "id": 8218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82491361645674,
            21.02234427640956,
            105.82496255803716,
            21.022468557705803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82491361645674,
              21.02234427640956
            ],
            [
              105.82491430919045,
              21.022345694726784
            ],
            [
              105.82496255803716,
              21.022468557705803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT46_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.8226",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02234427640956,
          "to_latitude": 21.022468557705803,
          "to_longitude": 105.82496255803716,
          "from_longitude": 105.82491361645674
        }
      },
      {
        "id": 8219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82504940628048,
            21.02229923778748,
            105.825118969244,
            21.022449435511735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504940628048,
              21.02229923778748
            ],
            [
              105.8250501230073,
              21.02230047082653
            ],
            [
              105.82506198484117,
              21.022312294315082
            ],
            [
              105.825118969244,
              21.022449435511735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT48_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.8227",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02229923778748,
          "to_latitude": 21.022449435511735,
          "to_longitude": 105.825118969244,
          "from_longitude": 105.82504940628048
        }
      },
      {
        "id": 8220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82479439825363,
            21.022280140319676,
            105.8250653508897,
            21.022370713021797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82479439825363,
              21.022370713021797
            ],
            [
              105.82492553661962,
              21.022328759891174
            ],
            [
              105.82494913193153,
              21.022321211001085
            ],
            [
              105.8250653508897,
              21.022280140319676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT47_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.8228",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022370713021797,
          "to_latitude": 21.022280140319676,
          "to_longitude": 105.8250653508897,
          "from_longitude": 105.82479439825363
        }
      },
      {
        "id": 8221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82492553661962,
            21.022328759891174,
            105.82493110225727,
            21.022341453925737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82492553661962,
              21.022328759891174
            ],
            [
              105.82493037080954,
              21.022339818119
            ],
            [
              105.82493110225727,
              21.022341453925737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT47_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DODV.8229",
          "diaChiLapD": "Ngõ 39, Hào Nam",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022328759891174,
          "to_latitude": 21.022341453925737,
          "to_longitude": 105.82493110225727,
          "from_longitude": 105.82492553661962
        }
      },
      {
        "id": 8222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786704593528,
            21.003645990397974,
            105.83787068616657,
            21.00365657334331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83786704593528,
              21.00365657334331
            ],
            [
              105.83786902128313,
              21.003650831751948
            ],
            [
              105.83787063883126,
              21.003646126114784
            ],
            [
              105.83787068616657,
              21.003645990397974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,42_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8230",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00365657334331,
          "to_latitude": 21.003645990397974,
          "to_longitude": 105.83787068616657,
          "from_longitude": 105.83786704593528
        }
      },
      {
        "id": 8223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83426196325152,
            21.002211547305286,
            105.83428050191681,
            21.00228977625053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83426196325152,
              21.00228977625053
            ],
            [
              105.83426218126948,
              21.002288852988233
            ],
            [
              105.8342636512214,
              21.002282666814523
            ],
            [
              105.83428047744155,
              21.002211643167207
            ],
            [
              105.83428050191681,
              21.002211547305286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D16,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8231",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00228977625053,
          "to_latitude": 21.002211547305286,
          "to_longitude": 105.83428050191681,
          "from_longitude": 105.83426196325152
        }
      },
      {
        "id": 8224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695131888854,
            21.001775609195832,
            105.8371850853065,
            21.001829774488883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695131888854,
              21.001829774488883
            ],
            [
              105.83695795895511,
              21.001818294358948
            ],
            [
              105.83696918560578,
              21.001792758260446
            ],
            [
              105.8371850853065,
              21.001775609195832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,106_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8232",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001829774488883,
          "to_latitude": 21.001775609195832,
          "to_longitude": 105.8371850853065,
          "from_longitude": 105.83695131888854
        }
      },
      {
        "id": 8225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423430397994,
            21.002164948683752,
            105.83428452093742,
            21.002289267810543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425955195031,
              21.002289267810543
            ],
            [
              105.83425974519176,
              21.00228838621808
            ],
            [
              105.83426110172374,
              21.002282214148675
            ],
            [
              105.83428452093742,
              21.002175626178918
            ],
            [
              105.83423430397994,
              21.002164948683752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D16,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8233",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002289267810543,
          "to_latitude": 21.002164948683752,
          "to_longitude": 105.83423430397994,
          "from_longitude": 105.83425955195031
        }
      },
      {
        "id": 8226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752700510733,
            21.001049414765774,
            105.83789269266579,
            21.00109004958149
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83789269266579,
              21.00109004958149
            ],
            [
              105.83774394090014,
              21.00108386481378
            ],
            [
              105.83753182436867,
              21.00105018137671
            ],
            [
              105.83752700510733,
              21.001049414765774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,118_Ngách 102/72, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8234",
          "diaChiLapD": "Ngách 102/72, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00109004958149,
          "to_latitude": 21.001049414765774,
          "to_longitude": 105.83752700510733,
          "from_longitude": 105.83789269266579
        }
      },
      {
        "id": 8227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83738133379593,
            21.001496613763596,
            105.83738172810006,
            21.001511106151018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83738172810006,
              21.001496613763596
            ],
            [
              105.83738164995528,
              21.001499502663357
            ],
            [
              105.83738160016793,
              21.001501267811037
            ],
            [
              105.83738157794814,
              21.00150211243625
            ],
            [
              105.83738152845305,
              21.001503929969562
            ],
            [
              105.83738140484631,
              21.001508497286068
            ],
            [
              105.83738133379593,
              21.001511106151018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,114_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8235",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001496613763596,
          "to_latitude": 21.001511106151018,
          "to_longitude": 105.83738133379593,
          "from_longitude": 105.83738172810006
        }
      },
      {
        "id": 8228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83777829392604,
            21.00315672615212,
            105.83778219099109,
            21.003169028294703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83777829392604,
              21.003169028294703
            ],
            [
              105.83778041684852,
              21.00316232675246
            ],
            [
              105.8377821673386,
              21.003156796720116
            ],
            [
              105.83778219099109,
              21.00315672615212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,52_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8236",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003169028294703,
          "to_latitude": 21.00315672615212,
          "to_longitude": 105.83778219099109,
          "from_longitude": 105.83777829392604
        }
      },
      {
        "id": 8229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84033301845209,
            20.9991302254242,
            105.84033364598781,
            20.999158158054495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84033364598781,
              20.9991302254242
            ],
            [
              105.84033358140505,
              20.999133121483293
            ],
            [
              105.84033347598351,
              20.999137828710708
            ],
            [
              105.8403333666074,
              20.99914268860271
            ],
            [
              105.84033311525036,
              20.999153800417858
            ],
            [
              105.84033301845209,
              20.999158158054495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,162_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8237",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.9991302254242,
          "to_latitude": 20.999158158054495,
          "to_longitude": 105.84033301845209,
          "from_longitude": 105.84033364598781
        }
      },
      {
        "id": 8230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368059645573,
            21.001532305765636,
            105.83681213403673,
            21.001722675620496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681177581775,
              21.001532305765636
            ],
            [
              105.836811161647,
              21.001543255879877
            ],
            [
              105.83680836862521,
              21.001593150247167
            ],
            [
              105.83680771864483,
              21.001604750859848
            ],
            [
              105.8368059645573,
              21.0016481143141
            ],
            [
              105.83681213403673,
              21.001663159724036
            ],
            [
              105.83681000061695,
              21.001722675620496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,7_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8238",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001532305765636,
          "to_latitude": 21.001722675620496,
          "to_longitude": 105.83681000061695,
          "from_longitude": 105.83681177581775
        }
      },
      {
        "id": 8231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971457426928,
            20.99852012030464,
            105.83974659495503,
            20.99852054018501
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971457426928,
              20.99852054018501
            ],
            [
              105.83971577262088,
              20.99852052524188
            ],
            [
              105.83974659495503,
              20.99852012030464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,165_Trường Chinh",
          "maTaiSan": "04.O17.DODV.8239",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99852054018501,
          "to_latitude": 20.99852012030464,
          "to_longitude": 105.83974659495503,
          "from_longitude": 105.83971457426928
        }
      },
      {
        "id": 8232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945968526122,
            20.999093271574985,
            105.8394607913148,
            20.999148323200927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394607913148,
              20.999093271574985
            ],
            [
              105.83946020968897,
              20.99912221920589
            ],
            [
              105.83945977708609,
              20.999143759653155
            ],
            [
              105.83945968526122,
              20.999148323200927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,154_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8240",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999093271574985,
          "to_latitude": 20.999148323200927,
          "to_longitude": 105.83945968526122,
          "from_longitude": 105.8394607913148
        }
      },
      {
        "id": 8233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931378458536,
            20.99982072390279,
            105.83940913815968,
            20.999876945732968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931378458536,
              20.999859911720034
            ],
            [
              105.83938484399059,
              20.999876945732968
            ],
            [
              105.83940913815968,
              20.99982072390279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,152_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8241",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999859911720034,
          "to_latitude": 20.99982072390279,
          "to_longitude": 105.83940913815968,
          "from_longitude": 105.83931378458536
        }
      },
      {
        "id": 8234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84031025436751,
            20.999444916323622,
            105.84042696139933,
            20.999447270900436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84031025436751,
              20.999447270900436
            ],
            [
              105.84042696139933,
              20.999444916323622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,160_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8242",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999447270900436,
          "to_latitude": 20.999444916323622,
          "to_longitude": 105.84042696139933,
          "from_longitude": 105.84031025436751
        }
      },
      {
        "id": 8235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659304130714,
            21.00154207055573,
            105.83660425256853,
            21.001721769907974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660100613616,
              21.00154207055573
            ],
            [
              105.83660100186069,
              21.001542166318668
            ],
            [
              105.83659960209562,
              21.001585778224882
            ],
            [
              105.83659910537847,
              21.001601237567346
            ],
            [
              105.83659761529212,
              21.00164762733618
            ],
            [
              105.83660425256853,
              21.001665153422508
            ],
            [
              105.83660268193833,
              21.001721769907974
            ],
            [
              105.83659304130714,
              21.00172176309618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,4_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8243",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00154207055573,
          "to_latitude": 21.00172176309618,
          "to_longitude": 105.83659304130714,
          "from_longitude": 105.83660100613616
        }
      },
      {
        "id": 8236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8383579819502,
            20.999593205852097,
            105.83877155474825,
            20.999604389126095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383579819502,
              20.999593205852097
            ],
            [
              105.83848314958185,
              20.99959793467785
            ],
            [
              105.83852166374136,
              20.99959939065016
            ],
            [
              105.83853556901444,
              20.999599916478864
            ],
            [
              105.83854104492461,
              20.999600123441084
            ],
            [
              105.83854581277356,
              20.99960030318125
            ],
            [
              105.838554430691,
              20.999600628343376
            ],
            [
              105.83869886553055,
              20.99960290377903
            ],
            [
              105.83875872339286,
              20.999604127186636
            ],
            [
              105.83876259516919,
              20.99960419843255
            ],
            [
              105.83876891613923,
              20.99960433528825
            ],
            [
              105.83877155474825,
              20.999604389126095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9A,1_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8244",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999593205852097,
          "to_latitude": 20.999604389126095,
          "to_longitude": 105.83877155474825,
          "from_longitude": 105.8383579819502
        }
      },
      {
        "id": 8237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608967274229,
            21.002782291143838,
            105.8365836774103,
            21.002891761775103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608967274229,
              21.002782291143838
            ],
            [
              105.8360927486234,
              21.00278295887363
            ],
            [
              105.83628209379324,
              21.002823594238713
            ],
            [
              105.8365836774103,
              21.002891761775103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,69_Ngõ 81, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8245",
          "diaChiLapD": "Ngõ 81, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002782291143838,
          "to_latitude": 21.002891761775103,
          "to_longitude": 105.8365836774103,
          "from_longitude": 105.83608967274229
        }
      },
      {
        "id": 8238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587768001615,
            21.001004087099552,
            105.8358794632972,
            21.001062810957162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83587768001615,
              21.001062810957162
            ],
            [
              105.83587782784569,
              21.001056712553414
            ],
            [
              105.8358794632972,
              21.001004087099552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,95_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8246",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001062810957162,
          "to_latitude": 21.001004087099552,
          "to_longitude": 105.8358794632972,
          "from_longitude": 105.83587768001615
        }
      },
      {
        "id": 8239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83880584589032,
            20.99952291808527,
            105.8391527854787,
            20.999600902346216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880584589032,
              20.999598168553597
            ],
            [
              105.83881249136232,
              20.99959837064744
            ],
            [
              105.83889590028296,
              20.999600902346216
            ],
            [
              105.8389418202965,
              20.999580281219423
            ],
            [
              105.83900340295479,
              20.999558830500828
            ],
            [
              105.83900379165345,
              20.99955867955292
            ],
            [
              105.8390883828205,
              20.999524589473825
            ],
            [
              105.8391527854787,
              20.99952291808527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,146_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8247",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999598168553597,
          "to_latitude": 20.99952291808527,
          "to_longitude": 105.8391527854787,
          "from_longitude": 105.83880584589032
        }
      },
      {
        "id": 8240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640477127582,
            21.00308490428133,
            105.8364284860288,
            21.003167546728648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640477127582,
              21.003167546728648
            ],
            [
              105.8364085008037,
              21.00315363950254
            ],
            [
              105.83641118251953,
              21.003143639359664
            ],
            [
              105.8364284860288,
              21.00308490428133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,67_Ngõ 65, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8248",
          "diaChiLapD": "Ngõ 65, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003167546728648,
          "to_latitude": 21.00308490428133,
          "to_longitude": 105.8364284860288,
          "from_longitude": 105.83640477127582
        }
      },
      {
        "id": 8241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647225271638,
            21.001825699268757,
            105.83647235600631,
            21.001832152308705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647235600631,
              21.001832152308705
            ],
            [
              105.83647231070262,
              21.001829373305902
            ],
            [
              105.83647225271638,
              21.001825699268757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,105_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8249",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001832152308705,
          "to_latitude": 21.001825699268757,
          "to_longitude": 105.83647225271638,
          "from_longitude": 105.83647235600631
        }
      },
      {
        "id": 8242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835568219626,
            21.001584199648867,
            105.8355719692251,
            21.00160090244284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835568219626,
              21.00160090244284
            ],
            [
              105.83556984428971,
              21.001593664144753
            ],
            [
              105.8355719692251,
              21.001584199648867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,3_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8250",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00160090244284,
          "to_latitude": 21.001584199648867,
          "to_longitude": 105.8355719692251,
          "from_longitude": 105.835568219626
        }
      },
      {
        "id": 8243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816200508079,
            21.00327569909729,
            105.83817294571126,
            21.003313507787546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816200508079,
              21.003313507787546
            ],
            [
              105.83817294571126,
              21.00327569909729
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,50_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8251",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003313507787546,
          "to_latitude": 21.00327569909729,
          "to_longitude": 105.83817294571126,
          "from_longitude": 105.83816200508079
        }
      },
      {
        "id": 8244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84062296699688,
            20.99875280283654,
            105.84074414477068,
            20.99875863633466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84062296699688,
              20.99875863633466
            ],
            [
              105.84062926142741,
              20.998758334285814
            ],
            [
              105.84074414477068,
              20.99875280283654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1,5_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8252",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99875863633466,
          "to_latitude": 20.99875280283654,
          "to_longitude": 105.84074414477068,
          "from_longitude": 105.84062296699688
        }
      },
      {
        "id": 8245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538519816065,
            21.002687170221336,
            105.83538685137727,
            21.00282978323358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538519816065,
              21.00282978323358
            ],
            [
              105.83538558624758,
              21.00279599345926
            ],
            [
              105.8353868284106,
              21.002689092398512
            ],
            [
              105.83538683636331,
              21.00268844745628
            ],
            [
              105.83538685137727,
              21.002687170221336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6,3_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8253",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00282978323358,
          "to_latitude": 21.002687170221336,
          "to_longitude": 105.83538685137727,
          "from_longitude": 105.83538519816065
        }
      },
      {
        "id": 8246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629381193909,
            21.001059237747278,
            105.83655088979437,
            21.00109879293474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83651806202671,
              21.00109879293474
            ],
            [
              105.83655088979437,
              21.001098631609043
            ],
            [
              105.83654953624115,
              21.001059237747278
            ],
            [
              105.83650117044881,
              21.001060703815668
            ],
            [
              105.83642396674382,
              21.001063044088276
            ],
            [
              105.83641998634873,
              21.00106316480779
            ],
            [
              105.83641702821876,
              21.001063254310644
            ],
            [
              105.83640822115083,
              21.001063521584904
            ],
            [
              105.83629381193909,
              21.001066988476865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,104_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8254",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00109879293474,
          "to_latitude": 21.001066988476865,
          "to_longitude": 105.83629381193909,
          "from_longitude": 105.83651806202671
        }
      },
      {
        "id": 8247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728876860748,
            21.003137510496316,
            105.83735623107921,
            21.003709999372823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373003866002,
              21.003709999372823
            ],
            [
              105.83730076722927,
              21.00370839969475
            ],
            [
              105.83730701725172,
              21.003682167284733
            ],
            [
              105.83735623107921,
              21.003389991001043
            ],
            [
              105.83728876860748,
              21.003373664688258
            ],
            [
              105.83735460031167,
              21.003137510496316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,38_Phương Mai",
          "maTaiSan": "04.O17.DODV.8255",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003709999372823,
          "to_latitude": 21.003137510496316,
          "to_longitude": 105.83735460031167,
          "from_longitude": 105.8373003866002
        }
      },
      {
        "id": 8248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83641921759931,
            21.001039128327896,
            105.83642040686611,
            21.001076318224907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83642040686611,
              21.001076318224907
            ],
            [
              105.83642039540092,
              21.001075986797385
            ],
            [
              105.83641998634873,
              21.00106316480779
            ],
            [
              105.83641921759931,
              21.001039128327896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I4,1_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8256",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001076318224907,
          "to_latitude": 21.001039128327896,
          "to_longitude": 105.83641921759931,
          "from_longitude": 105.83642040686611
        }
      },
      {
        "id": 8249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83678360380274,
            21.00135743073513,
            105.83707484738423,
            21.00136172433256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678360380274,
              21.00135743073513
            ],
            [
              105.83694009566383,
              21.00135824473213
            ],
            [
              105.83707484738423,
              21.00136172433256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,110_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8257",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00135743073513,
          "to_latitude": 21.00136172433256,
          "to_longitude": 105.83707484738423,
          "from_longitude": 105.83678360380274
        }
      },
      {
        "id": 8250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83625430100756,
            21.000903035199695,
            105.83660109669668,
            21.000917408777983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625430100756,
              21.000917408777983
            ],
            [
              105.83625896967665,
              21.000917216036036
            ],
            [
              105.83660109669668,
              21.000903035199695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,103_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8258",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000917408777983,
          "to_latitude": 21.000903035199695,
          "to_longitude": 105.83660109669668,
          "from_longitude": 105.83625430100756
        }
      },
      {
        "id": 8251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462664475071,
            21.00277822209758,
            105.83471536995695,
            21.002817093722815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83462664475071,
              21.002817093722815
            ],
            [
              105.83462667406513,
              21.00281700325647
            ],
            [
              105.83462705613474,
              21.00281583080226
            ],
            [
              105.83463851429771,
              21.00277822209758
            ],
            [
              105.83471536995695,
              21.00279701347946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D7,3_Phương Mai",
          "maTaiSan": "04.O17.DODV.8259",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002817093722815,
          "to_latitude": 21.00279701347946,
          "to_longitude": 105.83471536995695,
          "from_longitude": 105.83462664475071
        }
      },
      {
        "id": 8252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526625444652,
            21.00318852446854,
            105.83527314747936,
            21.003210927583467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527314747936,
              21.00318852446854
            ],
            [
              105.83527284609401,
              21.00318950323779
            ],
            [
              105.83526939861596,
              21.003200704800012
            ],
            [
              105.83526909915417,
              21.003201683559805
            ],
            [
              105.83526630453387,
              21.00321076746673
            ],
            [
              105.83526625444652,
              21.003210927583467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,72_Phương Mai",
          "maTaiSan": "04.O17.DODV.8260",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00318852446854,
          "to_latitude": 21.003210927583467,
          "to_longitude": 105.83526625444652,
          "from_longitude": 105.83527314747936
        }
      },
      {
        "id": 8253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706772807237,
            21.002586631167592,
            105.83708362419574,
            21.00263844700103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83708362419574,
              21.002586631167592
            ],
            [
              105.83706772807237,
              21.00263844700103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,59_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8261",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002586631167592,
          "to_latitude": 21.00263844700103,
          "to_longitude": 105.83706772807237,
          "from_longitude": 105.83708362419574
        }
      },
      {
        "id": 8254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837421817129,
            21.002673687594676,
            105.83744519126793,
            21.002724346184753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837421817129,
              21.002724346184753
            ],
            [
              105.83743493340637,
              21.00271115146637
            ],
            [
              105.83744519126793,
              21.002673687594676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,59_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8262",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002724346184753,
          "to_latitude": 21.002673687594676,
          "to_longitude": 105.83744519126793,
          "from_longitude": 105.837421817129
        }
      },
      {
        "id": 8255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83742547276789,
            21.002431524578956,
            105.83765598182168,
            21.002744451158904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742547276789,
              21.002744451158904
            ],
            [
              105.83742557644366,
              21.00274441451981
            ],
            [
              105.83746104969372,
              21.002731846836884
            ],
            [
              105.83755018235787,
              21.002742640786575
            ],
            [
              105.83760329652836,
              21.00265829546319
            ],
            [
              105.83762634401796,
              21.002609613811263
            ],
            [
              105.83763561564871,
              21.00259002509711
            ],
            [
              105.83765598182168,
              21.002450576159713
            ],
            [
              105.83759170942875,
              21.002431524578956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,57_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8263",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002744451158904,
          "to_latitude": 21.002431524578956,
          "to_longitude": 105.83759170942875,
          "from_longitude": 105.83742547276789
        }
      },
      {
        "id": 8256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83990225400483,
            20.998447228961616,
            105.84025220675507,
            20.998724640267017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84025220675507,
              20.998724640267017
            ],
            [
              105.84025219353452,
              20.99872245001079
            ],
            [
              105.840251590097,
              20.998619961297884
            ],
            [
              105.84025058504517,
              20.998449839243115
            ],
            [
              105.83990225400483,
              20.998447228961616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1A,3_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8264",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 66.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998724640267017,
          "to_latitude": 20.998447228961616,
          "to_longitude": 105.83990225400483,
          "from_longitude": 105.84025220675507
        }
      },
      {
        "id": 8257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553234137548,
            21.000619963787138,
            105.83603902092939,
            21.00084459500221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603902092939,
              21.00084459500221
            ],
            [
              105.83603779153033,
              21.000844219878882
            ],
            [
              105.83600260310308,
              21.000833437471005
            ],
            [
              105.83600026897659,
              21.00075697550912
            ],
            [
              105.83585908925507,
              21.00074493150697
            ],
            [
              105.83585968805308,
              21.000708752562765
            ],
            [
              105.83570944552149,
              21.000702683516828
            ],
            [
              105.83571560855466,
              21.000646800477853
            ],
            [
              105.8356783131994,
              21.000640063054735
            ],
            [
              105.83558279873836,
              21.000622807020154
            ],
            [
              105.83555351603808,
              21.000619963787138
            ],
            [
              105.83553234137548,
              21.000701918057857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,101_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8265",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00084459500221,
          "to_latitude": 21.000701918057857,
          "to_longitude": 105.83553234137548,
          "from_longitude": 105.83603902092939
        }
      },
      {
        "id": 8258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763025956486,
            21.000930795807793,
            105.83806456397527,
            21.000958004281333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763025956486,
              21.000958004281333
            ],
            [
              105.83773100373719,
              21.000954536002613
            ],
            [
              105.8378331725473,
              21.000948510846754
            ],
            [
              105.83792442843402,
              21.00094492386375
            ],
            [
              105.83792679131086,
              21.000944685523002
            ],
            [
              105.83806456397527,
              21.000930795807793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,120_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8266",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000958004281333,
          "to_latitude": 21.000930795807793,
          "to_longitude": 105.83806456397527,
          "from_longitude": 105.83763025956486
        }
      },
      {
        "id": 8259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84029232800492,
            20.999129763698082,
            105.84063580060845,
            20.999133598415998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029232800492,
              20.999129763698082
            ],
            [
              105.84033364598781,
              20.9991302254242
            ],
            [
              105.84048912587667,
              20.999131962509015
            ],
            [
              105.84048918646519,
              20.999131961306666
            ],
            [
              105.84049136227135,
              20.999131987596567
            ],
            [
              105.84050560015626,
              20.999132144913755
            ],
            [
              105.84063580060845,
              20.999133598415998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,162_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8267",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999129763698082,
          "to_latitude": 20.999133598415998,
          "to_longitude": 105.84063580060845,
          "from_longitude": 105.84029232800492
        }
      },
      {
        "id": 8260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825187740608,
            21.002992355213333,
            105.83827079703086,
            21.00327927581482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825310759079,
              21.00327927581482
            ],
            [
              105.83825640459486,
              21.0032745826774
            ],
            [
              105.83826207009635,
              21.003266517877538
            ],
            [
              105.83827079703086,
              21.003233108855152
            ],
            [
              105.83825187740608,
              21.002992355213333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,49_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8268",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00327927581482,
          "to_latitude": 21.002992355213333,
          "to_longitude": 105.83825187740608,
          "from_longitude": 105.83825310759079
        }
      },
      {
        "id": 8261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83648173396422,
            21.001075478677997,
            105.83648299508069,
            21.001178671192005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83648299508069,
              21.001075478677997
            ],
            [
              105.83648297748216,
              21.001076808312796
            ],
            [
              105.83648173396422,
              21.001178671192005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I3,2_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8269",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001075478677997,
          "to_latitude": 21.001178671192005,
          "to_longitude": 105.83648173396422,
          "from_longitude": 105.83648299508069
        }
      },
      {
        "id": 8262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83681048029305,
            21.00154185872391,
            105.83681727148796,
            21.00160492141251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681546726754,
              21.00160492141251
            ],
            [
              105.83681579650293,
              21.001593412699187
            ],
            [
              105.83681727148796,
              21.00154191074712
            ],
            [
              105.83681370659707,
              21.00154185872391
            ],
            [
              105.83681363231477,
              21.00154302695985
            ],
            [
              105.83681048029305,
              21.00159322476938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,8_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8270",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00160492141251,
          "to_latitude": 21.00159322476938,
          "to_longitude": 105.83681048029305,
          "from_longitude": 105.83681546726754
        }
      },
      {
        "id": 8263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834718541813,
            21.003045316395085,
            105.83504106287444,
            21.003128512121286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504106287444,
              21.003128512121286
            ],
            [
              105.83489775737537,
              21.00309592491164
            ],
            [
              105.83489575610535,
              21.003095469566695
            ],
            [
              105.834718541813,
              21.003045316395085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,74_Phương Mai",
          "maTaiSan": "04.O17.DODV.8271",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003128512121286,
          "to_latitude": 21.003045316395085,
          "to_longitude": 105.834718541813,
          "from_longitude": 105.83504106287444
        }
      },
      {
        "id": 8264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83517826979312,
            21.001523740427015,
            105.8359533858668,
            21.00165921313793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359533858668,
              21.00165921313793
            ],
            [
              105.83574915789316,
              21.001614642816207
            ],
            [
              105.83562811299747,
              21.001593845980278
            ],
            [
              105.83561687942834,
              21.001591915835917
            ],
            [
              105.83560652294244,
              21.001590136740077
            ],
            [
              105.83559538950365,
              21.001588224167254
            ],
            [
              105.8355719692251,
              21.001584199648867
            ],
            [
              105.8353672628215,
              21.001549028373077
            ],
            [
              105.83535729048405,
              21.00154731511862
            ],
            [
              105.83535361656524,
              21.00154668372882
            ],
            [
              105.83534355565521,
              21.00154495555337
            ],
            [
              105.83522007795052,
              21.001523740427015
            ],
            [
              105.83519041939466,
              21.001548800533236
            ],
            [
              105.83517826979312,
              21.001594863248073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,3_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8272",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00165921313793,
          "to_latitude": 21.001594863248073,
          "to_longitude": 105.83517826979312,
          "from_longitude": 105.8359533858668
        }
      },
      {
        "id": 8265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750429493126,
            21.003130085196855,
            105.83758790761688,
            21.00348369426987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758790761688,
              21.00348369426987
            ],
            [
              105.83758322720205,
              21.003482657818587
            ],
            [
              105.83757437291614,
              21.003480693519705
            ],
            [
              105.83756959971439,
              21.003453722312592
            ],
            [
              105.83750429493126,
              21.0034077994199
            ],
            [
              105.83758276113338,
              21.003130085196855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,45_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8273",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00348369426987,
          "to_latitude": 21.003130085196855,
          "to_longitude": 105.83758276113338,
          "from_longitude": 105.83758790761688
        }
      },
      {
        "id": 8266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83834912760899,
            20.99956260120625,
            105.83857617386273,
            20.999570864291947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857617386273,
              20.99956911337532
            ],
            [
              105.83852748796562,
              20.999570864291947
            ],
            [
              105.83834912760899,
              20.99956260120625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,145_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8274",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99956911337532,
          "to_latitude": 20.99956260120625,
          "to_longitude": 105.83834912760899,
          "from_longitude": 105.83857617386273
        }
      },
      {
        "id": 8267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377100897801,
            21.00282505397846,
            105.83772468970724,
            21.002874180002895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377100897801,
              21.002874180002895
            ],
            [
              105.83772468970724,
              21.00282505397846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,60_Ngách 15/34, Phương Mai",
          "maTaiSan": "04.O17.DODV.8275",
          "diaChiLapD": "Ngách 15/34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002874180002895,
          "to_latitude": 21.00282505397846,
          "to_longitude": 105.83772468970724,
          "from_longitude": 105.8377100897801
        }
      },
      {
        "id": 8268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83740777321881,
            21.003105280830873,
            105.83756894392648,
            21.00353494479117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750180951593,
              21.003105280830873
            ],
            [
              105.83750130478919,
              21.003106876603866
            ],
            [
              105.83744455930668,
              21.00328619133079
            ],
            [
              105.83740777321881,
              21.003399134584736
            ],
            [
              105.83752782780815,
              21.00343177083943
            ],
            [
              105.83756436313041,
              21.003455749628213
            ],
            [
              105.83756645289428,
              21.003479299977545
            ],
            [
              105.83756894392648,
              21.003507362672362
            ],
            [
              105.83756006695768,
              21.00353494479117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,44_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8276",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003105280830873,
          "to_latitude": 21.00353494479117,
          "to_longitude": 105.83756006695768,
          "from_longitude": 105.83750180951593
        }
      },
      {
        "id": 8269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661383399817,
            21.002849404921392,
            105.83738291701786,
            21.00303578510495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661383399817,
              21.002849404921392
            ],
            [
              105.83707413741139,
              21.002952606078278
            ],
            [
              105.83717151618413,
              21.002974439536477
            ],
            [
              105.83719319240205,
              21.00298941588432
            ],
            [
              105.83737729142024,
              21.00303578510495
            ],
            [
              105.83738249679371,
              21.00302057989023
            ],
            [
              105.83738291701786,
              21.00301935395243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,54_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8277",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002849404921392,
          "to_latitude": 21.00301935395243,
          "to_longitude": 105.83738291701786,
          "from_longitude": 105.83661383399817
        }
      },
      {
        "id": 8270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373395449645,
            21.002816064312018,
            105.83738378561854,
            21.002825923956518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373395449645,
              21.002816064312018
            ],
            [
              105.83738378561854,
              21.002825923956518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,56_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8278",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002816064312018,
          "to_latitude": 21.002825923956518,
          "to_longitude": 105.83738378561854,
          "from_longitude": 105.8373395449645
        }
      },
      {
        "id": 8271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823101412463,
            21.001894762809304,
            105.83823987331911,
            21.002341126570432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823987331911,
              21.002341126570432
            ],
            [
              105.83823920377755,
              21.002144590743725
            ],
            [
              105.83823101412463,
              21.001894762809304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,61_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8279",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002341126570432,
          "to_latitude": 21.001894762809304,
          "to_longitude": 105.83823101412463,
          "from_longitude": 105.83823987331911
        }
      },
      {
        "id": 8272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640879989835,
            21.00153683387393,
            105.83641348721231,
            21.00172097224247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641348721231,
              21.00153683387393
            ],
            [
              105.83641336667516,
              21.001540749046512
            ],
            [
              105.83641210121503,
              21.00158223590592
            ],
            [
              105.83641198823204,
              21.00158595323493
            ],
            [
              105.8364117415445,
              21.001596401155744
            ],
            [
              105.83641161780038,
              21.00160163956975
            ],
            [
              105.83641154392654,
              21.00160478044857
            ],
            [
              105.83640879989835,
              21.00172097224247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,1_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8280",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00153683387393,
          "to_latitude": 21.00172097224247,
          "to_longitude": 105.83640879989835,
          "from_longitude": 105.83641348721231
        }
      },
      {
        "id": 8273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83599738517407,
            21.001514317762357,
            105.83600744299687,
            21.00192287451809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83599939648947,
              21.00192287451809
            ],
            [
              105.83599738517407,
              21.001773646209543
            ],
            [
              105.83599794893577,
              21.001726347829457
            ],
            [
              105.83599865695207,
              21.001666956353883
            ],
            [
              105.83599867717062,
              21.001665232899796
            ],
            [
              105.83599867771494,
              21.001665157929384
            ],
            [
              105.83600744299687,
              21.00161386133334
            ],
            [
              105.83600238702213,
              21.00155850577852
            ],
            [
              105.8359976875733,
              21.001514317762357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,93_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8281",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00192287451809,
          "to_latitude": 21.001514317762357,
          "to_longitude": 105.8359976875733,
          "from_longitude": 105.83599939648947
        }
      },
      {
        "id": 8274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361284731783,
            21.000759791881183,
            105.8361306314315,
            21.000833202227827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361284731783,
              21.000833202227827
            ],
            [
              105.83612858643095,
              21.00082935934879
            ],
            [
              105.8361306314315,
              21.000759791881183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I5,2_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8282",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000833202227827,
          "to_latitude": 21.000759791881183,
          "to_longitude": 105.8361306314315,
          "from_longitude": 105.8361284731783
        }
      },
      {
        "id": 8275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921033260576,
            20.999325717515752,
            105.83926020562187,
            20.999325864026755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83926020562187,
              20.999325717515752
            ],
            [
              105.83923675559828,
              20.99932578615463
            ],
            [
              105.83921531577164,
              20.999325849396705
            ],
            [
              105.83921033260576,
              20.999325864026755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8283",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999325717515752,
          "to_latitude": 20.999325864026755,
          "to_longitude": 105.83921033260576,
          "from_longitude": 105.83926020562187
        }
      },
      {
        "id": 8276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84042115500064,
            20.998916289260688,
            105.84049431567752,
            20.998926311760368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84049431567752,
              20.998916831075565
            ],
            [
              105.84049428297122,
              20.99891683033382
            ],
            [
              105.8404805726414,
              20.998916410281627
            ],
            [
              105.84047764319644,
              20.998916289260688
            ],
            [
              105.84047758004058,
              20.998926311760368
            ],
            [
              105.84042115500064,
              20.998926080002416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1B,3_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8284",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998916831075565,
          "to_latitude": 20.998926080002416,
          "to_longitude": 105.84042115500064,
          "from_longitude": 105.84049431567752
        }
      },
      {
        "id": 8277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439182189275,
            21.001853883185234,
            105.83441413074978,
            21.00207121802087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440848284651,
              21.00207121802087
            ],
            [
              105.83440724962527,
              21.00206738806522
            ],
            [
              105.83439182189275,
              21.00203024716617
            ],
            [
              105.83439432842695,
              21.002011205713554
            ],
            [
              105.83439494692615,
              21.002006539325752
            ],
            [
              105.83439498723807,
              21.002006177837742
            ],
            [
              105.8344090455081,
              21.001894380768384
            ],
            [
              105.83441413074978,
              21.001853883185234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8285",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00207121802087,
          "to_latitude": 21.001853883185234,
          "to_longitude": 105.83441413074978,
          "from_longitude": 105.83440848284651
        }
      },
      {
        "id": 8278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462078610972,
            21.00278388935389,
            105.83462977177732,
            21.00281536657894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83462078610972,
              21.00281536657894
            ],
            [
              105.83462977177732,
              21.00278388935389
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D7,4_Phương Mai",
          "maTaiSan": "04.O17.DODV.8286",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00281536657894,
          "to_latitude": 21.00278388935389,
          "to_longitude": 105.83462977177732,
          "from_longitude": 105.83462078610972
        }
      },
      {
        "id": 8279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83533616578214,
            21.001495522134984,
            105.83541155248385,
            21.001512651268662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83533616578214,
              21.001495522134984
            ],
            [
              105.83536008156244,
              21.00150078709362
            ],
            [
              105.83538757113571,
              21.001506839284254
            ],
            [
              105.83541155248385,
              21.001512651268662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,4_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8287",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001495522134984,
          "to_latitude": 21.001512651268662,
          "to_longitude": 105.83541155248385,
          "from_longitude": 105.83533616578214
        }
      },
      {
        "id": 8280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659329205304,
            21.001542432545428,
            105.83659916282066,
            21.001601250783636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83659329205304,
              21.001601250783636
            ],
            [
              105.83659438978773,
              21.001585594293815
            ],
            [
              105.83659741383997,
              21.001542451307774
            ],
            [
              105.83659829093897,
              21.001542432545428
            ],
            [
              105.83659916282066,
              21.00154251316355
            ],
            [
              105.83659910113094,
              21.001543697595622
            ],
            [
              105.83659691416686,
              21.001585683048372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,3_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8288",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001601250783636,
          "to_latitude": 21.001585683048372,
          "to_longitude": 105.83659691416686,
          "from_longitude": 105.83659329205304
        }
      },
      {
        "id": 8281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794216486453,
            21.003853634271017,
            105.83794837385376,
            21.00387393140613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794837385376,
              21.003853634271017
            ],
            [
              105.83794668958808,
              21.00385913868835
            ],
            [
              105.837943279492,
              21.003870283211388
            ],
            [
              105.83794216486453,
              21.00387393140613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,36_Phương Mai",
          "maTaiSan": "04.O17.DODV.8289",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003853634271017,
          "to_latitude": 21.00387393140613,
          "to_longitude": 105.83794216486453,
          "from_longitude": 105.83794837385376
        }
      },
      {
        "id": 8282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440081541397,
            21.002958046477566,
            105.83442626026145,
            21.00296426148465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83442626026145,
              21.00296426148465
            ],
            [
              105.83442185668247,
              21.00296318655586
            ],
            [
              105.83441628477787,
              21.002961824709164
            ],
            [
              105.83440081541397,
              21.002958046477566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,77_Phương Mai",
          "maTaiSan": "04.O17.DODV.8290",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00296426148465,
          "to_latitude": 21.002958046477566,
          "to_longitude": 105.83440081541397,
          "from_longitude": 105.83442626026145
        }
      },
      {
        "id": 8283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83638268608951,
            21.00146234176948,
            105.83638837867144,
            21.00153650108896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83638268608951,
              21.00153650108896
            ],
            [
              105.836382894995,
              21.001533772320794
            ],
            [
              105.83638405453767,
              21.00151867462463
            ],
            [
              105.83638837867144,
              21.00146234176948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2,2_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8291",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00153650108896,
          "to_latitude": 21.00146234176948,
          "to_longitude": 105.83638837867144,
          "from_longitude": 105.83638268608951
        }
      },
      {
        "id": 8284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375378338612,
            21.000532304567095,
            105.83760751117251,
            21.000535707410627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375378338612,
              21.000532304567095
            ],
            [
              105.83756812466389,
              21.000533784046635
            ],
            [
              105.83759687802642,
              21.0005351879889
            ],
            [
              105.83760751117251,
              21.000535707410627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8292",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000532304567095,
          "to_latitude": 21.000535707410627,
          "to_longitude": 105.83760751117251,
          "from_longitude": 105.8375378338612
        }
      },
      {
        "id": 8285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360193879503,
            21.002963058403466,
            105.83604673688103,
            21.002963321492626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360193879503,
              21.002963058403466
            ],
            [
              105.83604673688103,
              21.002963321492626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8293",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002963058403466,
          "to_latitude": 21.002963321492626,
          "to_longitude": 105.83604673688103,
          "from_longitude": 105.8360193879503
        }
      },
      {
        "id": 8286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83567340771694,
            21.002808354311814,
            105.83567406222859,
            21.002887316484166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567343420255,
              21.002887316484166
            ],
            [
              105.83567340771694,
              21.00288636009817
            ],
            [
              105.83567406222859,
              21.002808354311814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,4_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8294",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002887316484166,
          "to_latitude": 21.002808354311814,
          "to_longitude": 105.83567406222859,
          "from_longitude": 105.83567343420255
        }
      },
      {
        "id": 8287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506644783373,
            21.002174812319197,
            105.83514365977548,
            21.002244904824245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506644783373,
              21.002174812319197
            ],
            [
              105.83514365977548,
              21.002185424864482
            ],
            [
              105.83513657663228,
              21.002222406071137
            ],
            [
              105.83513172904439,
              21.002244904824245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8B,4_Phương Mai",
          "maTaiSan": "04.O17.DODV.8295",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002174812319197,
          "to_latitude": 21.002244904824245,
          "to_longitude": 105.83513172904439,
          "from_longitude": 105.83506644783373
        }
      },
      {
        "id": 8288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916299335772,
            20.999620814437918,
            105.83922629427856,
            20.9996359186381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83922629427856,
              20.9996359186381
            ],
            [
              105.83916506695479,
              20.999621310022427
            ],
            [
              105.83916299335772,
              20.999620814437918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,148_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8296",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.9996359186381,
          "to_latitude": 20.999620814437918,
          "to_longitude": 105.83916299335772,
          "from_longitude": 105.83922629427856
        }
      },
      {
        "id": 8289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743007450052,
            20.99930008370484,
            105.83765040633071,
            20.9994206286318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743018704598,
              20.99930008370484
            ],
            [
              105.83743013796908,
              20.999305252204195
            ],
            [
              105.83743007450052,
              20.99931215135493
            ],
            [
              105.83765025358446,
              20.999305685779234
            ],
            [
              105.83765040633071,
              20.9994206286318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,133_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8297",
          "diaChiLapD": "Ngách 102/25, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99930008370484,
          "to_latitude": 20.9994206286318,
          "to_longitude": 105.83765040633071,
          "from_longitude": 105.83743018704598
        }
      },
      {
        "id": 8290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83486478898814,
            21.002839858609924,
            105.83494129509967,
            21.002933847369164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83486478898814,
              21.002839858609924
            ],
            [
              105.83494129509967,
              21.002859606312114
            ],
            [
              105.83492897855523,
              21.002896264535668
            ],
            [
              105.83492240232137,
              21.00291476053601
            ],
            [
              105.83491873727428,
              21.00292506715924
            ],
            [
              105.83491839444797,
              21.0029260316796
            ],
            [
              105.8349156168026,
              21.002933847369164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D7,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8298",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002839858609924,
          "to_latitude": 21.002933847369164,
          "to_longitude": 105.8349156168026,
          "from_longitude": 105.83486478898814
        }
      },
      {
        "id": 8291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418462164295,
            21.002275752024524,
            105.83418728990662,
            21.002287331813537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418728990662,
              21.002275752024524
            ],
            [
              105.83418725795752,
              21.002275887665
            ],
            [
              105.83418662589891,
              21.002278634763243
            ],
            [
              105.83418462164295,
              21.002287331813537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.85_Phương Mai",
          "maTaiSan": "04.O17.DODV.8299",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002275752024524,
          "to_latitude": 21.002287331813537,
          "to_longitude": 105.83418462164295,
          "from_longitude": 105.83418728990662
        }
      },
      {
        "id": 8292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824276248816,
            20.999565553760352,
            105.83826147148591,
            21.000053258548565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826147148591,
              21.000053258548565
            ],
            [
              105.83824323682813,
              20.999577811809164
            ],
            [
              105.83824312175969,
              20.999574946440895
            ],
            [
              105.83824276248816,
              20.999565553760352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,143_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8300",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000053258548565,
          "to_latitude": 20.999565553760352,
          "to_longitude": 105.83824276248816,
          "from_longitude": 105.83826147148591
        }
      },
      {
        "id": 8293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360873670724,
            21.002146695741846,
            105.83614446945475,
            21.00214976087626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360873670724,
              21.00214976087626
            ],
            [
              105.83609769331379,
              21.00214921067088
            ],
            [
              105.83610951091089,
              21.002148575461923
            ],
            [
              105.83614446945475,
              21.002146695741846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8301",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00214976087626,
          "to_latitude": 21.002146695741846,
          "to_longitude": 105.83614446945475,
          "from_longitude": 105.8360873670724
        }
      },
      {
        "id": 8294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555937494297,
            21.00105979432219,
            105.83598376863645,
            21.001068032692217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598376863645,
              21.001068032692217
            ],
            [
              105.83582360959224,
              21.001066535813283
            ],
            [
              105.83555937494297,
              21.00105979432219
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,97_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8302",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001068032692217,
          "to_latitude": 21.00105979432219,
          "to_longitude": 105.83555937494297,
          "from_longitude": 105.83598376863645
        }
      },
      {
        "id": 8295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423708742255,
            21.001945651628972,
            105.83439432842695,
            21.002011211269483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83423708742255,
              21.001945651628972
            ],
            [
              105.83437029881317,
              21.001976300913412
            ],
            [
              105.83438047921918,
              21.00199173591829
            ],
            [
              105.83438079661767,
              21.002010075270157
            ],
            [
              105.83439383576766,
              21.00201117109624
            ],
            [
              105.8343943005642,
              21.002011211269483
            ],
            [
              105.83439432842695,
              21.002011205713554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8303",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001945651628972,
          "to_latitude": 21.002011205713554,
          "to_longitude": 105.83439432842695,
          "from_longitude": 105.83423708742255
        }
      },
      {
        "id": 8296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553319089384,
            21.002891358948315,
            105.83598510052308,
            21.002907518251895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598510052308,
              21.002891358948315
            ],
            [
              105.83577363811051,
              21.00289678255819
            ],
            [
              105.83571666310442,
              21.002898247338166
            ],
            [
              105.83553319089384,
              21.002907518251895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,3_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8304",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002891358948315,
          "to_latitude": 21.002907518251895,
          "to_longitude": 105.83553319089384,
          "from_longitude": 105.83598510052308
        }
      },
      {
        "id": 8297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83692837614609,
            21.001520850957743,
            105.83693837742456,
            21.00159554631073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83693837742456,
              21.001520850957743
            ],
            [
              105.8369362730032,
              21.001536566592044
            ],
            [
              105.83692849523027,
              21.00159465062861
            ],
            [
              105.83692837614609,
              21.00159554631073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,111_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8305",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001520850957743,
          "to_latitude": 21.00159554631073,
          "to_longitude": 105.83692837614609,
          "from_longitude": 105.83693837742456
        }
      },
      {
        "id": 8298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586893620219,
            21.00100408604794,
            105.83587066435513,
            21.00106269221548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586893620219,
              21.00106269221548
            ],
            [
              105.83587066435513,
              21.00100408604794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,96_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8306",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00106269221548,
          "to_latitude": 21.00100408604794,
          "to_longitude": 105.83587066435513,
          "from_longitude": 105.83586893620219
        }
      },
      {
        "id": 8299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84049558075445,
            20.998783848206028,
            105.84050750900474,
            20.998783908311818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84050750900474,
              20.998783848206028
            ],
            [
              105.84050747630347,
              20.998783848367495
            ],
            [
              105.84050566235196,
              20.998783858226556
            ],
            [
              105.84049679842984,
              20.998783908311818
            ],
            [
              105.84049558075445,
              20.998783908001084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,164_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8307",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998783848206028,
          "to_latitude": 20.998783908001084,
          "to_longitude": 105.84049558075445,
          "from_longitude": 105.84050750900474
        }
      },
      {
        "id": 8300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83450133820509,
            21.0026465151808,
            105.8349827989462,
            21.002763094457418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450133820509,
              21.0026465151808
            ],
            [
              105.83451682784462,
              21.002650831627474
            ],
            [
              105.83451850021801,
              21.00265129852509
            ],
            [
              105.83453001315848,
              21.00265450543487
            ],
            [
              105.83453550591983,
              21.002660491279773
            ],
            [
              105.8345359207412,
              21.00266036550401
            ],
            [
              105.83454405868828,
              21.002657890507493
            ],
            [
              105.83455392623246,
              21.002657351675875
            ],
            [
              105.83463294460786,
              21.002677105267868
            ],
            [
              105.83476157880139,
              21.002705596279245
            ],
            [
              105.83497586578419,
              21.00276151711302
            ],
            [
              105.8349827989462,
              21.002763094457418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,78_Phương Mai",
          "maTaiSan": "04.O17.DODV.8308",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0026465151808,
          "to_latitude": 21.002763094457418,
          "to_longitude": 105.8349827989462,
          "from_longitude": 105.83450133820509
        }
      },
      {
        "id": 8301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84044472782284,
            20.998697686379195,
            105.8404448493104,
            20.998727184511534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84044472782284,
              20.998727184511534
            ],
            [
              105.84044473789328,
              20.998724516334214
            ],
            [
              105.8404448493104,
              20.998697686379195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1A,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8309",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998727184511534,
          "to_latitude": 20.998697686379195,
          "to_longitude": 105.8404448493104,
          "from_longitude": 105.84044472782284
        }
      },
      {
        "id": 8302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699115065762,
            21.002485199475604,
            105.83742629602983,
            21.002742712012527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742629602983,
              21.002742712012527
            ],
            [
              105.83742620162464,
              21.00274268628345
            ],
            [
              105.83719681966498,
              21.002678518679932
            ],
            [
              105.83707163396579,
              21.002646518886852
            ],
            [
              105.83699115065762,
              21.00262594538163
            ],
            [
              105.83702327172807,
              21.002485199475604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,58_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8310",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002742712012527,
          "to_latitude": 21.002485199475604,
          "to_longitude": 105.83702327172807,
          "from_longitude": 105.83742629602983
        }
      },
      {
        "id": 8303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584975749561,
            21.003133841466024,
            105.83593418863343,
            21.003172984610053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593418863343,
              21.003133841466024
            ],
            [
              105.83588548687385,
              21.00315057805798
            ],
            [
              105.83585586713708,
              21.00316209060932
            ],
            [
              105.83584975749561,
              21.003172984610053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4A,1_Ngõ 28A, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8311",
          "diaChiLapD": "Ngõ 28A, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003133841466024,
          "to_latitude": 21.003172984610053,
          "to_longitude": 105.83584975749561,
          "from_longitude": 105.83593418863343
        }
      },
      {
        "id": 8304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573355804286,
            21.00099453212217,
            105.83573537512886,
            21.001060842340173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573355804286,
              21.001060842340173
            ],
            [
              105.83573369759704,
              21.001051874428363
            ],
            [
              105.83573537512886,
              21.00099453212217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,98_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8312",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001060842340173,
          "to_latitude": 21.00099453212217,
          "to_longitude": 105.83573537512886,
          "from_longitude": 105.83573355804286
        }
      },
      {
        "id": 8305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538704590957,
            21.002090749390728,
            105.83538907028151,
            21.002160454941485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538704590957,
              21.002160454941485
            ],
            [
              105.83538907028151,
              21.002090749390728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2A,5_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8313",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002160454941485,
          "to_latitude": 21.002090749390728,
          "to_longitude": 105.83538907028151,
          "from_longitude": 105.83538704590957
        }
      },
      {
        "id": 8306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418972757632,
            21.0022695933367,
            105.83419126082698,
            21.002276159175008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418972757632,
              21.002276159175008
            ],
            [
              105.83418975950536,
              21.002276019921723
            ],
            [
              105.83419126082698,
              21.0022695933367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.84_Phương Mai",
          "maTaiSan": "04.O17.DODV.8314",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002276159175008,
          "to_latitude": 21.0022695933367,
          "to_longitude": 105.83419126082698,
          "from_longitude": 105.83418972757632
        }
      },
      {
        "id": 8307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363798292337,
            21.001462069556304,
            105.83638532329542,
            21.001536469964808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363798292337,
              21.001536469964808
            ],
            [
              105.83637995706096,
              21.001534726111068
            ],
            [
              105.83638113946411,
              21.001518725076853
            ],
            [
              105.83638532329542,
              21.001462069556304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2,1_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8315",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001536469964808,
          "to_latitude": 21.001462069556304,
          "to_longitude": 105.83638532329542,
          "from_longitude": 105.8363798292337
        }
      },
      {
        "id": 8308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899264175058,
            20.999537795028623,
            105.83900379165345,
            20.99955867955292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899264175058,
              20.999537795028623
            ],
            [
              105.83899292559637,
              20.999538330145644
            ],
            [
              105.83899742494366,
              20.9995467440968
            ],
            [
              105.83900379165345,
              20.99955867955292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,146_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8316",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999537795028623,
          "to_latitude": 20.99955867955292,
          "to_longitude": 105.83900379165345,
          "from_longitude": 105.83899264175058
        }
      },
      {
        "id": 8309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667195082526,
            21.001601758370914,
            105.83711767481279,
            21.001606848179996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667195082526,
              21.001601758370914
            ],
            [
              105.83680158996863,
              21.00160461569806
            ],
            [
              105.83680348311724,
              21.001604657875255
            ],
            [
              105.83680771864483,
              21.001604750859848
            ],
            [
              105.83681546726754,
              21.00160492141251
            ],
            [
              105.83690288001985,
              21.001606848179996
            ],
            [
              105.83700718342047,
              21.001605265011012
            ],
            [
              105.83700886939505,
              21.00160523955948
            ],
            [
              105.8370118508654,
              21.001605194189327
            ],
            [
              105.83701501891883,
              21.0016051460952
            ],
            [
              105.83711767481279,
              21.001603588121004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,8_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8317",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001601758370914,
          "to_latitude": 21.001603588121004,
          "to_longitude": 105.83711767481279,
          "from_longitude": 105.83667195082526
        }
      },
      {
        "id": 8310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83759158503628,
            21.000817437360958,
            105.83798476100043,
            21.0008344409357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759158503628,
              21.0008344409357
            ],
            [
              105.83760019721014,
              21.00083406888222
            ],
            [
              105.8376067005726,
              21.00083378759193
            ],
            [
              105.83798476100043,
              21.000817437360958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8318",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0008344409357,
          "to_latitude": 21.000817437360958,
          "to_longitude": 105.83798476100043,
          "from_longitude": 105.83759158503628
        }
      },
      {
        "id": 8311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954866581564,
            20.998603061515723,
            105.83960092070114,
            20.998603887681274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954866581564,
              20.998603061515723
            ],
            [
              105.83954918429154,
              20.998603070700767
            ],
            [
              105.83960092070114,
              20.998603887681274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,8_Trường Chinh",
          "maTaiSan": "04.O17.DODV.8319",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998603061515723,
          "to_latitude": 20.998603887681274,
          "to_longitude": 105.83960092070114,
          "from_longitude": 105.83954866581564
        }
      },
      {
        "id": 8312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506870211357,
            21.002235580784546,
            105.83507692861505,
            21.002251311101645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83507245602267,
              21.002235580784546
            ],
            [
              105.83507234003476,
              21.00223599774071
            ],
            [
              105.83506870211357,
              21.00224979249246
            ],
            [
              105.83507692861505,
              21.002251311101645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8B,3_Phương Mai",
          "maTaiSan": "04.O17.DODV.8320",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002235580784546,
          "to_latitude": 21.002251311101645,
          "to_longitude": 105.83507692861505,
          "from_longitude": 105.83507245602267
        }
      },
      {
        "id": 8313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722790097296,
            21.001329721434885,
            105.83753074861892,
            21.001348800652977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722790097296,
              21.001348800652977
            ],
            [
              105.83751607265727,
              21.00133624718958
            ],
            [
              105.83752323505638,
              21.001333062402065
            ],
            [
              105.83753059510596,
              21.001329789932115
            ],
            [
              105.83753074861892,
              21.001329721434885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,116_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8321",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001348800652977,
          "to_latitude": 21.001329721434885,
          "to_longitude": 105.83753074861892,
          "from_longitude": 105.83722790097296
        }
      },
      {
        "id": 8314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584408582328,
            21.003083945909836,
            105.83594770952813,
            21.003171673881294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584517256709,
              21.00308597681674
            ],
            [
              105.83590371179676,
              21.003084716567916
            ],
            [
              105.83594770952813,
              21.003083945909836
            ],
            [
              105.83594671457492,
              21.00312359607949
            ],
            [
              105.83593345723,
              21.00312375422117
            ],
            [
              105.83592694343906,
              21.003134098338517
            ],
            [
              105.83588335383533,
              21.003149260791364
            ],
            [
              105.83585093770631,
              21.003160723848744
            ],
            [
              105.83584408582328,
              21.003171673881294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4A,2_Ngõ 28A, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8322",
          "diaChiLapD": "Ngõ 28A, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00308597681674,
          "to_latitude": 21.003171673881294,
          "to_longitude": 105.83584408582328,
          "from_longitude": 105.83584517256709
        }
      },
      {
        "id": 8315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568171152824,
            21.00268632889664,
            105.83568181715681,
            21.002703918699172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568181715681,
              21.002703918699172
            ],
            [
              105.8356817544975,
              21.002693528297996
            ],
            [
              105.83568171749094,
              21.002687572609197
            ],
            [
              105.83568171152824,
              21.00268632889664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,91_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8323",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002703918699172,
          "to_latitude": 21.00268632889664,
          "to_longitude": 105.83568171152824,
          "from_longitude": 105.83568181715681
        }
      },
      {
        "id": 8316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701058496386,
            21.00153910958899,
            105.83701904311012,
            21.00173311122566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701058496386,
              21.00153910958899
            ],
            [
              105.8370107101414,
              21.00154568897214
            ],
            [
              105.83701168796372,
              21.001596679379244
            ],
            [
              105.8370118508654,
              21.001605194189327
            ],
            [
              105.83701287265299,
              21.00165854902452
            ],
            [
              105.83701904311012,
              21.00167359351829
            ],
            [
              105.83701690882076,
              21.00173311122566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,10_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8324",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00153910958899,
          "to_latitude": 21.00173311122566,
          "to_longitude": 105.83701690882076,
          "from_longitude": 105.83701058496386
        }
      },
      {
        "id": 8317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716908600739,
            21.0015143028072,
            105.8375432302416,
            21.00159540225146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716908600739,
              21.00159540225146
            ],
            [
              105.83717362483145,
              21.001577271159018
            ],
            [
              105.83717736233396,
              21.00157309071386
            ],
            [
              105.83720968357287,
              21.001536934596764
            ],
            [
              105.83724565258098,
              21.00153000517012
            ],
            [
              105.8373204273893,
              21.001526894272757
            ],
            [
              105.83749762913057,
              21.001517058913983
            ],
            [
              105.8375432302416,
              21.0015143028072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,113_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8325",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00159540225146,
          "to_latitude": 21.0015143028072,
          "to_longitude": 105.8375432302416,
          "from_longitude": 105.83716908600739
        }
      },
      {
        "id": 8318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764957632764,
            21.003424086822392,
            105.8383069077525,
            21.00354580785771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383069077525,
              21.00347071498159
            ],
            [
              105.83830483387894,
              21.00347086790534
            ],
            [
              105.83813876125947,
              21.003483071815662
            ],
            [
              105.83810156063797,
              21.00354580785771
            ],
            [
              105.83766374366229,
              21.003427901872183
            ],
            [
              105.83764957632764,
              21.003424086822392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,47_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8326",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00347071498159,
          "to_latitude": 21.003424086822392,
          "to_longitude": 105.83764957632764,
          "from_longitude": 105.8383069077525
        }
      },
      {
        "id": 8319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410271316188,
            21.00186583201337,
            105.83417860682924,
            21.002262385069002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83410271316188,
              21.002262385069002
            ],
            [
              105.83410327788637,
              21.002258425268387
            ],
            [
              105.8341037547107,
              21.00225509093095
            ],
            [
              105.8341301163427,
              21.00207061692581
            ],
            [
              105.83413120033796,
              21.00202492102008
            ],
            [
              105.83417860682924,
              21.00186583201337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.86_Phương Mai",
          "maTaiSan": "04.O17.DODV.8327",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002262385069002,
          "to_latitude": 21.00186583201337,
          "to_longitude": 105.83417860682924,
          "from_longitude": 105.83410271316188
        }
      },
      {
        "id": 8320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623580164321,
            21.001328587452075,
            105.83640752338081,
            21.001332944686318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83623580164321,
              21.001332944686318
            ],
            [
              105.83624802207996,
              21.001332635361514
            ],
            [
              105.8362497695026,
              21.001332590647966
            ],
            [
              105.83640752338081,
              21.001328587452075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,102_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8328",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001332944686318,
          "to_latitude": 21.001328587452075,
          "to_longitude": 105.83640752338081,
          "from_longitude": 105.83623580164321
        }
      },
      {
        "id": 8321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883832634287,
            20.999470584177942,
            105.83919403639614,
            20.999567068517607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883832634287,
              20.999567068517607
            ],
            [
              105.83894121316423,
              20.999555286542712
            ],
            [
              105.83896017746956,
              20.999502594649226
            ],
            [
              105.83896652038145,
              20.999484973073066
            ],
            [
              105.83900971158177,
              20.999482241113046
            ],
            [
              105.83919403639614,
              20.999470584177942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,147_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8329",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999567068517607,
          "to_latitude": 20.999470584177942,
          "to_longitude": 105.83919403639614,
          "from_longitude": 105.83883832634287
        }
      },
      {
        "id": 8322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83464179485198,
            21.00185325229876,
            105.83474047467308,
            21.00234832195523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83464179485198,
              21.00234832195523
            ],
            [
              105.83465843267747,
              21.002302606687635
            ],
            [
              105.83469283443898,
              21.002184505589046
            ],
            [
              105.83469644366453,
              21.002172117307786
            ],
            [
              105.8347241353051,
              21.002061244205027
            ],
            [
              105.83472933834861,
              21.0020244754578
            ],
            [
              105.83474047467308,
              21.00185325229876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,82_Phương Mai",
          "maTaiSan": "04.O17.DODV.8330",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00234832195523,
          "to_latitude": 21.00185325229876,
          "to_longitude": 105.83474047467308,
          "from_longitude": 105.83464179485198
        }
      },
      {
        "id": 8323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743089160876,
            21.002542013485108,
            105.83763561564871,
            21.00259002509711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763561564871,
              21.00259002509711
            ],
            [
              105.83748173092631,
              21.00256039779982
            ],
            [
              105.83743089160876,
              21.002542013485108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,57_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8331",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00259002509711,
          "to_latitude": 21.002542013485108,
          "to_longitude": 105.83743089160876,
          "from_longitude": 105.83763561564871
        }
      },
      {
        "id": 8324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372181768984,
            21.002398000055866,
            105.83730742527452,
            21.002682143411473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724785176298,
              21.002682143411473
            ],
            [
              105.83724793907669,
              21.002681760014404
            ],
            [
              105.83730742527452,
              21.002419401953436
            ],
            [
              105.8372181768984,
              21.002398000055866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,59_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8332",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002682143411473,
          "to_latitude": 21.002398000055866,
          "to_longitude": 105.8372181768984,
          "from_longitude": 105.83724785176298
        }
      },
      {
        "id": 8325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83407445705308,
            21.002616739875922,
            105.83415080605512,
            21.002696578128553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413227240064,
              21.002696578128553
            ],
            [
              105.83413342774985,
              21.002692799691776
            ],
            [
              105.83413769958922,
              21.00267882481912
            ],
            [
              105.83415080605512,
              21.00263594677448
            ],
            [
              105.83407445705308,
              21.002616739875922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14,4_Phương Mai",
          "maTaiSan": "04.O17.DODV.8333",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002696578128553,
          "to_latitude": 21.002616739875922,
          "to_longitude": 105.83407445705308,
          "from_longitude": 105.83413227240064
        }
      },
      {
        "id": 8326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768922227699,
            21.003346034589235,
            105.83830653010793,
            21.00347612001131
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83830653010793,
              21.00346182549268
            ],
            [
              105.83830423680249,
              21.003462127626097
            ],
            [
              105.83819775743768,
              21.00347612001131
            ],
            [
              105.83815427919079,
              21.003472958763076
            ],
            [
              105.83768922227699,
              21.003346034589235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,48_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8334",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00346182549268,
          "to_latitude": 21.003346034589235,
          "to_longitude": 105.83768922227699,
          "from_longitude": 105.83830653010793
        }
      },
      {
        "id": 8327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83609636871184,
            21.00160112725515,
            105.8366507693758,
            21.00179985939833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366507693758,
              21.00160112725515
            ],
            [
              105.83660464266151,
              21.00160122570756
            ],
            [
              105.83659910537847,
              21.001601237567346
            ],
            [
              105.83659329205304,
              21.001601250783636
            ],
            [
              105.83642716970962,
              21.001601606506767
            ],
            [
              105.83642093605854,
              21.001601619976103
            ],
            [
              105.83641704735986,
              21.00160162824538
            ],
            [
              105.83641161780038,
              21.00160163956975
            ],
            [
              105.83639684786083,
              21.001601670595484
            ],
            [
              105.83639655546749,
              21.001601672032184
            ],
            [
              105.8362508388742,
              21.001604132993737
            ],
            [
              105.83624540206766,
              21.001604224735487
            ],
            [
              105.83622040410589,
              21.001604646508962
            ],
            [
              105.83616397656695,
              21.001626082669777
            ],
            [
              105.83612764212512,
              21.00164616192229
            ],
            [
              105.83610172959263,
              21.001667160041
            ],
            [
              105.83609636871184,
              21.00179985939833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,3_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8335",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00160112725515,
          "to_latitude": 21.00179985939833,
          "to_longitude": 105.83609636871184,
          "from_longitude": 105.8366507693758
        }
      },
      {
        "id": 8328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760645853629,
            21.00083378759193,
            105.8376067005726,
            21.00092503624473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376067005726,
              21.00083378759193
            ],
            [
              105.83760645853629,
              21.00092503624473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8336",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00083378759193,
          "to_latitude": 21.00092503624473,
          "to_longitude": 105.83760645853629,
          "from_longitude": 105.8376067005726
        }
      },
      {
        "id": 8329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608892666592,
            21.002580055990016,
            105.83613807682434,
            21.002580493421174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608892666592,
              21.002580493421174
            ],
            [
              105.83610282955901,
              21.00258037003326
            ],
            [
              105.83611169740175,
              21.002580291247924
            ],
            [
              105.83613807682434,
              21.002580055990016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8337",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002580493421174,
          "to_latitude": 21.002580055990016,
          "to_longitude": 105.83613807682434,
          "from_longitude": 105.83608892666592
        }
      },
      {
        "id": 8330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83556762790906,
            21.0015355551494,
            105.83561761132509,
            21.00160725022927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560190900955,
              21.00160725022927
            ],
            [
              105.83560521690657,
              21.001594829087075
            ],
            [
              105.83560652294244,
              21.001590136740077
            ],
            [
              105.83561761132509,
              21.00155026359542
            ],
            [
              105.8356121265141,
              21.00154916148796
            ],
            [
              105.83561408582054,
              21.001543982708164
            ],
            [
              105.83561269262421,
              21.001543730321504
            ],
            [
              105.83556762790906,
              21.0015355551494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,1_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8338",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00160725022927,
          "to_latitude": 21.0015355551494,
          "to_longitude": 105.83556762790906,
          "from_longitude": 105.83560190900955
        }
      },
      {
        "id": 8331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002623469122,
            20.99915572279499,
            105.84010923988811,
            20.99953676239268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84010923988811,
              20.99915572279499
            ],
            [
              105.84010870221312,
              20.999162423767466
            ],
            [
              105.84010788149449,
              20.999172686650986
            ],
            [
              105.84008901255476,
              20.999464062707204
            ],
            [
              105.84002793991742,
              20.99946472266272
            ],
            [
              105.84002623469122,
              20.99953676239268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,159_Ngách 78/16, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8339",
          "diaChiLapD": "Ngách 78/16, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99915572279499,
          "to_latitude": 20.99953676239268,
          "to_longitude": 105.84002623469122,
          "from_longitude": 105.84010923988811
        }
      },
      {
        "id": 8332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778632553194,
            21.00095511415023,
            105.83778659565745,
            21.000963585928893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778659565745,
              21.00095511415023
            ],
            [
              105.83778652186773,
              21.000957403286943
            ],
            [
              105.83778640167782,
              21.00096120194191
            ],
            [
              105.83778632553194,
              21.000963585928893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,119_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8340",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00095511415023,
          "to_latitude": 21.000963585928893,
          "to_longitude": 105.83778632553194,
          "from_longitude": 105.83778659565745
        }
      },
      {
        "id": 8333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83557907764374,
            21.00091954223556,
            105.83603855968462,
            21.000930504885297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557907764374,
              21.00091954223556
            ],
            [
              105.83603841635396,
              21.000930501976345
            ],
            [
              105.83603855968462,
              21.000930504885297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,100_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8341",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00091954223556,
          "to_latitude": 21.000930504885297,
          "to_longitude": 105.83603855968462,
          "from_longitude": 105.83557907764374
        }
      },
      {
        "id": 8334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967766802301,
            20.998892672270085,
            105.83970827592915,
            20.99889295213688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83970827592915,
              20.99889295213688
            ],
            [
              105.83969859847801,
              20.998892863483913
            ],
            [
              105.83967766802301,
              20.998892672270085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8342",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99889295213688,
          "to_latitude": 20.998892672270085,
          "to_longitude": 105.83967766802301,
          "from_longitude": 105.83970827592915
        }
      },
      {
        "id": 8335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8406284561207,
            20.998893131571236,
            105.84073151602811,
            20.998895705278862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406284561207,
              20.998895705278862
            ],
            [
              105.84063099211346,
              20.998895643078882
            ],
            [
              105.84073151602811,
              20.998893131571236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1,2_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8343",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998895705278862,
          "to_latitude": 20.998893131571236,
          "to_longitude": 105.84073151602811,
          "from_longitude": 105.8406284561207
        }
      },
      {
        "id": 8336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83714075997943,
            21.00198650390097,
            105.83772207933605,
            21.00232791116412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769663293606,
              21.002023227249147
            ],
            [
              105.83769730298665,
              21.002022818403393
            ],
            [
              105.83772207933605,
              21.002007667701104
            ],
            [
              105.83770415932683,
              21.00198650390097
            ],
            [
              105.83766106452495,
              21.00200750642372
            ],
            [
              105.83753110135963,
              21.00208523442391
            ],
            [
              105.83714075997943,
              21.00232791116412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,64_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8344",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 77.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002023227249147,
          "to_latitude": 21.00232791116412,
          "to_longitude": 105.83714075997943,
          "from_longitude": 105.83769663293606
        }
      },
      {
        "id": 8337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83731450990501,
            21.00264649978949,
            105.83733040595284,
            21.00269831473976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83733040595284,
              21.00264649978949
            ],
            [
              105.83731450990501,
              21.00269831473976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,59_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8345",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00264649978949,
          "to_latitude": 21.00269831473976,
          "to_longitude": 105.83731450990501,
          "from_longitude": 105.83733040595284
        }
      },
      {
        "id": 8338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83554356642802,
            21.000803279575276,
            105.83558101631041,
            21.00091954223556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557907764374,
              21.00091954223556
            ],
            [
              105.83557992757143,
              21.00090997741704
            ],
            [
              105.83558101631041,
              21.000897697232332
            ],
            [
              105.83554356642802,
              21.00088981976565
            ],
            [
              105.83554970123642,
              21.000803279575276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,100_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8346",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00091954223556,
          "to_latitude": 21.000803279575276,
          "to_longitude": 105.83554970123642,
          "from_longitude": 105.83557907764374
        }
      },
      {
        "id": 8339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813241243305,
            20.999548399777588,
            105.8381335977435,
            20.999561777868877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8381335977435,
              20.999548399777588
            ],
            [
              105.83813274177518,
              20.999558058570674
            ],
            [
              105.83813241243305,
              20.999561777868877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H10,3_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8347",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999548399777588,
          "to_latitude": 20.999561777868877,
          "to_longitude": 105.83813241243305,
          "from_longitude": 105.8381335977435
        }
      },
      {
        "id": 8340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737653147854,
            21.00295646487847,
            105.83742356267732,
            21.002967559943542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742356267732,
              21.002967559943542
            ],
            [
              105.83737653147854,
              21.00295646487847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,55_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8348",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002967559943542,
          "to_latitude": 21.00295646487847,
          "to_longitude": 105.83737653147854,
          "from_longitude": 105.83742356267732
        }
      },
      {
        "id": 8341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366059884977,
            21.002935404361747,
            105.83738670288584,
            21.003123169909237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366059884977,
              21.002935404361747
            ],
            [
              105.8373570028569,
              21.003123169909237
            ],
            [
              105.83738630277004,
              21.003021771488143
            ],
            [
              105.83738670288584,
              21.00302038939127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,53_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8349",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 91.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002935404361747,
          "to_latitude": 21.00302038939127,
          "to_longitude": 105.83738670288584,
          "from_longitude": 105.8366059884977
        }
      },
      {
        "id": 8342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83580386213293,
            21.00332020111339,
            105.83580915782706,
            21.003339081049756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83580915782706,
              21.00332020111339
            ],
            [
              105.83580801097929,
              21.003324290228093
            ],
            [
              105.8358052984992,
              21.00333395541683
            ],
            [
              105.83580503821427,
              21.003334887017374
            ],
            [
              105.83580386213293,
              21.003339081049756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,70_Phương Mai",
          "maTaiSan": "04.O17.DODV.8350",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00332020111339,
          "to_latitude": 21.003339081049756,
          "to_longitude": 105.83580386213293,
          "from_longitude": 105.83580915782706
        }
      },
      {
        "id": 8343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83513912249458,
            21.00222656355371,
            105.83514358568473,
            21.00224606171111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83513912249458,
              21.00224606171111
            ],
            [
              105.83513939456411,
              21.00224482747294
            ],
            [
              105.83514358568473,
              21.00222656355371
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8B,5_Phương Mai",
          "maTaiSan": "04.O17.DODV.8351",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00224606171111,
          "to_latitude": 21.00222656355371,
          "to_longitude": 105.83514358568473,
          "from_longitude": 105.83513912249458
        }
      },
      {
        "id": 8344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857617386273,
            20.99956496882076,
            105.8388206678631,
            20.99956911337532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857617386273,
              20.99956911337532
            ],
            [
              105.8388206678631,
              20.99956496882076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,145_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8352",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99956911337532,
          "to_latitude": 20.99956496882076,
          "to_longitude": 105.8388206678631,
          "from_longitude": 105.83857617386273
        }
      },
      {
        "id": 8345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83407527831622,
            21.00291450602212,
            105.83411489853738,
            21.00296289089289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411489853738,
              21.00291450602212
            ],
            [
              105.83407527831622,
              21.00296289089289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,77_Phương Mai",
          "maTaiSan": "04.O17.DODV.8353",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00291450602212,
          "to_latitude": 21.00296289089289,
          "to_longitude": 105.83407527831622,
          "from_longitude": 105.83411489853738
        }
      },
      {
        "id": 8346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84041272273456,
            20.99891950036639,
            105.84049428355834,
            20.99893200612999
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84049428355834,
              20.998920199361248
            ],
            [
              105.84049425084694,
              20.998920197716306
            ],
            [
              105.84048035910243,
              20.99891950036639
            ],
            [
              105.84047880982178,
              20.998919540531393
            ],
            [
              105.84047858913829,
              20.99893200612999
            ],
            [
              105.84041272273456,
              20.99893107762726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1B,2_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8354",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998920199361248,
          "to_latitude": 20.99893107762726,
          "to_longitude": 105.84041272273456,
          "from_longitude": 105.84049428355834
        }
      },
      {
        "id": 8347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83762932759771,
            21.000963460383407,
            105.83790285295004,
            21.000969046283807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790285295004,
              21.000963460383407
            ],
            [
              105.83778632553194,
              21.000963585928893
            ],
            [
              105.83776139666132,
              21.00096361287596
            ],
            [
              105.83774839551269,
              21.000963625376627
            ],
            [
              105.83762932759771,
              21.000969046283807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,119_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8355",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000963460383407,
          "to_latitude": 21.000969046283807,
          "to_longitude": 105.83762932759771,
          "from_longitude": 105.83790285295004
        }
      },
      {
        "id": 8348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84067098237132,
            20.999057895403933,
            105.84101509437725,
            20.999138627470014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84067098237132,
              20.99913427732849
            ],
            [
              105.84085545820814,
              20.999136642241492
            ],
            [
              105.84101015944046,
              20.999138627470014
            ],
            [
              105.84101509437725,
              20.999057895403933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,163_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8356",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99913427732849,
          "to_latitude": 20.999057895403933,
          "to_longitude": 105.84101509437725,
          "from_longitude": 105.84067098237132
        }
      },
      {
        "id": 8349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83501399447985,
            21.00247259241849,
            105.83516187796862,
            21.002972707896706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83505754697094,
              21.002972707896706
            ],
            [
              105.83506237380651,
              21.002933680216028
            ],
            [
              105.8350762202639,
              21.002821752283328
            ],
            [
              105.83504623657676,
              21.002790322607602
            ],
            [
              105.83501399447985,
              21.0027824086512
            ],
            [
              105.83503621458902,
              21.002681842866902
            ],
            [
              105.83508244957866,
              21.00247259241849
            ],
            [
              105.83516187796862,
              21.00248946156088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,76_Phương Mai",
          "maTaiSan": "04.O17.DODV.8357",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002972707896706,
          "to_latitude": 21.00248946156088,
          "to_longitude": 105.83516187796862,
          "from_longitude": 105.83505754697094
        }
      },
      {
        "id": 8350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83826147148591,
            21.000053258548565,
            105.83871927421842,
            21.00007710861911
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826147148591,
              21.000053258548565
            ],
            [
              105.83871927421842,
              21.00007710861911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,143_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8358",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000053258548565,
          "to_latitude": 21.00007710861911,
          "to_longitude": 105.83871927421842,
          "from_longitude": 105.83826147148591
        }
      },
      {
        "id": 8351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612236628798,
            21.001816871308076,
            105.83688640975562,
            21.00182854172696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612236628798,
              21.001827795826955
            ],
            [
              105.836333651861,
              21.00182854172696
            ],
            [
              105.83647225271638,
              21.001825699268757
            ],
            [
              105.8367044985812,
              21.00182553241636
            ],
            [
              105.83688640975562,
              21.001816871308076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,105_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8359",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001827795826955,
          "to_latitude": 21.001816871308076,
          "to_longitude": 105.83688640975562,
          "from_longitude": 105.83612236628798
        }
      },
      {
        "id": 8352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593424682772,
            21.002093971154768,
            105.83603995934996,
            21.0021077081441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603995934996,
              21.002093971154768
            ],
            [
              105.83602339514171,
              21.002094108510544
            ],
            [
              105.83601647206874,
              21.002094165996912
            ],
            [
              105.83599711574274,
              21.002094326998932
            ],
            [
              105.83593455157435,
              21.002094898906364
            ],
            [
              105.83593424682772,
              21.0021077081441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2,2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8360",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002093971154768,
          "to_latitude": 21.0021077081441,
          "to_longitude": 105.83593424682772,
          "from_longitude": 105.83603995934996
        }
      },
      {
        "id": 8353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526355903464,
            21.002687393235984,
            105.83537969065738,
            21.002758720955832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83526355903464,
              21.002758720955832
            ],
            [
              105.83527352529727,
              21.002758636823742
            ],
            [
              105.83527788614363,
              21.002758600972488
            ],
            [
              105.83531404055807,
              21.0027582998084
            ],
            [
              105.83537563507946,
              21.00275765881551
            ],
            [
              105.83537966505831,
              21.002688151168055
            ],
            [
              105.83537969065738,
              21.002687393235984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6,6_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8361",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002758720955832,
          "to_latitude": 21.002687393235984,
          "to_longitude": 105.83537969065738,
          "from_longitude": 105.83526355903464
        }
      },
      {
        "id": 8354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83714534526479,
            21.00135421416007,
            105.8372141047722,
            21.00153010274865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83714534526479,
              21.00135421416007
            ],
            [
              105.8372141047722,
              21.001355443087228
            ],
            [
              105.83720064221946,
              21.001429840251397
            ],
            [
              105.83720584286426,
              21.00149898459251
            ],
            [
              105.8372059249997,
              21.001507847541298
            ],
            [
              105.83720609385658,
              21.00152484360999
            ],
            [
              105.83720775202768,
              21.001527076357227
            ],
            [
              105.8372118567517,
              21.00153010274865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,112_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8362",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00135421416007,
          "to_latitude": 21.00153010274865,
          "to_longitude": 105.8372118567517,
          "from_longitude": 105.83714534526479
        }
      },
      {
        "id": 8355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760907256125,
            21.00042291309617,
            105.83822139080647,
            21.00050848111645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83760907256125,
              21.000506288894982
            ],
            [
              105.83760944962208,
              21.000506292459026
            ],
            [
              105.83790050183893,
              21.00050848111645
            ],
            [
              105.83790531319829,
              21.000428052292744
            ],
            [
              105.83822139080647,
              21.00042291309617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,125_Ngách 102/56, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8363",
          "diaChiLapD": "Ngách 102/56, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000506288894982,
          "to_latitude": 21.00042291309617,
          "to_longitude": 105.83822139080647,
          "from_longitude": 105.83760907256125
        }
      },
      {
        "id": 8356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83896713001835,
            20.999796777072728,
            105.83911682868447,
            21.000217071336206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896713001835,
              21.000217071336206
            ],
            [
              105.83896742290699,
              21.00013282967009
            ],
            [
              105.83901778033734,
              21.000133432318616
            ],
            [
              105.83902759709672,
              21.000039689651317
            ],
            [
              105.83908197058174,
              20.999864847141367
            ],
            [
              105.83911682868447,
              20.99980174128218
            ],
            [
              105.83910461557244,
              20.999796797614035
            ],
            [
              105.83910456832773,
              20.999796777072728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,153_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8364",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000217071336206,
          "to_latitude": 20.999796777072728,
          "to_longitude": 105.83910456832773,
          "from_longitude": 105.83896713001835
        }
      },
      {
        "id": 8357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84073945881963,
            20.998742882776327,
            105.8407496187609,
            20.998930746902705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8407496187609,
              20.998742882776327
            ],
            [
              105.84074264703108,
              20.998871793000056
            ],
            [
              105.84073945881963,
              20.998930746902705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1,3_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8365",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998742882776327,
          "to_latitude": 20.998930746902705,
          "to_longitude": 105.84073945881963,
          "from_longitude": 105.8407496187609
        }
      },
      {
        "id": 8358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716908600739,
            21.00159540225146,
            105.83745940169985,
            21.001603804900615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83745940169985,
              21.001603804900615
            ],
            [
              105.83718657410175,
              21.001603128239942
            ],
            [
              105.83716908600739,
              21.00159540225146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,113_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8366",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001603804900615,
          "to_latitude": 21.00159540225146,
          "to_longitude": 105.83716908600739,
          "from_longitude": 105.83745940169985
        }
      },
      {
        "id": 8359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84085438430719,
            20.999136642241492,
            105.84085545820814,
            20.999159907401346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84085545820814,
              20.999136642241492
            ],
            [
              105.84085438430719,
              20.999159907401346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,163_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8367",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999136642241492,
          "to_latitude": 20.999159907401346,
          "to_longitude": 105.84085438430719,
          "from_longitude": 105.84085545820814
        }
      },
      {
        "id": 8360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009832627662,
            20.999157042032255,
            105.8403117771155,
            20.999459476231255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009832627662,
              20.999459476231255
            ],
            [
              105.8403101572193,
              20.999454487246673
            ],
            [
              105.84031025436751,
              20.999447270900436
            ],
            [
              105.8403117771155,
              20.999334751321022
            ],
            [
              105.84024600772645,
              20.999246533684055
            ],
            [
              105.84023139925998,
              20.9991849787246
            ],
            [
              105.84023206549968,
              20.99917272228169
            ],
            [
              105.8402342293816,
              20.999158760383317
            ],
            [
              105.84023449677112,
              20.999157042032255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,160_Ngách 78/10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8368",
          "diaChiLapD": "Ngách 78/10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999459476231255,
          "to_latitude": 20.999157042032255,
          "to_longitude": 105.84023449677112,
          "from_longitude": 105.84009832627662
        }
      },
      {
        "id": 8361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526472297913,
            21.001544182219757,
            105.83526720481133,
            21.00155433220571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83526472297913,
              21.00155433220571
            ],
            [
              105.83526720481133,
              21.001544182219757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,6_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8369",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00155433220571,
          "to_latitude": 21.001544182219757,
          "to_longitude": 105.83526720481133,
          "from_longitude": 105.83526472297913
        }
      },
      {
        "id": 8362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83507861377575,
            21.003146323766185,
            105.83538525534554,
            21.00321282292866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83507861377575,
              21.003146323766185
            ],
            [
              105.83512200275872,
              21.00315576637179
            ],
            [
              105.83522698134826,
              21.003178518368323
            ],
            [
              105.83527314747936,
              21.00318852446854
            ],
            [
              105.83538525534554,
              21.00321282292866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,72_Phương Mai",
          "maTaiSan": "04.O17.DODV.8370",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003146323766185,
          "to_latitude": 21.00321282292866,
          "to_longitude": 105.83538525534554,
          "from_longitude": 105.83507861377575
        }
      },
      {
        "id": 8363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84017304471898,
            20.999091510248025,
            105.84017814670477,
            20.999156395135426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84017814670477,
              20.999091510248025
            ],
            [
              105.84017479924444,
              20.999134079520456
            ],
            [
              105.84017304471898,
              20.999156395135426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,161_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8371",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999091510248025,
          "to_latitude": 20.999156395135426,
          "to_longitude": 105.84017304471898,
          "from_longitude": 105.84017814670477
        }
      },
      {
        "id": 8364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603324227325,
            21.000970681196527,
            105.83606507970066,
            21.00097110650666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603324227325,
              21.000970681196527
            ],
            [
              105.83603388868406,
              21.000970690666946
            ],
            [
              105.83603931291312,
              21.000970762478232
            ],
            [
              105.83606507970066,
              21.00097110650666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1,5_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8372",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000970681196527,
          "to_latitude": 21.00097110650666,
          "to_longitude": 105.83606507970066,
          "from_longitude": 105.83603324227325
        }
      },
      {
        "id": 8365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687814289382,
            21.001964423742923,
            105.83717121127114,
            21.00264344028831
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369663829441,
              21.001964423742923
            ],
            [
              105.83698661603374,
              21.001966602190347
            ],
            [
              105.83709949226642,
              21.00197875009042
            ],
            [
              105.83710736996468,
              21.002029139335477
            ],
            [
              105.83710901895554,
              21.00209664281984
            ],
            [
              105.83717121127114,
              21.00218237008993
            ],
            [
              105.83689344445814,
              21.002343851690494
            ],
            [
              105.83687814289382,
              21.002381380976583
            ],
            [
              105.83690732310289,
              21.002459448711136
            ],
            [
              105.83691885645521,
              21.002519614566957
            ],
            [
              105.83691191170087,
              21.00264344028831
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,109_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8373",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 106.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001964423742923,
          "to_latitude": 21.00264344028831,
          "to_longitude": 105.83691191170087,
          "from_longitude": 105.8369663829441
        }
      },
      {
        "id": 8366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528144043022,
            21.002687304766567,
            105.83538262574187,
            21.002765729853188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528144043022,
              21.002765729853188
            ],
            [
              105.83531396881607,
              21.002765457321598
            ],
            [
              105.83537912157523,
              21.002763667123567
            ],
            [
              105.83538253303932,
              21.00268931309246
            ],
            [
              105.83538262574187,
              21.002687304766567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6,5_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8374",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002765729853188,
          "to_latitude": 21.002687304766567,
          "to_longitude": 105.83538262574187,
          "from_longitude": 105.83528144043022
        }
      },
      {
        "id": 8367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753583757954,
            21.001470207543072,
            105.83767661363069,
            21.001489698764058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753583757954,
              21.001471911064858
            ],
            [
              105.83753917784487,
              21.001471698629917
            ],
            [
              105.83754648334089,
              21.001471231846093
            ],
            [
              105.83754765541514,
              21.00147115743396
            ],
            [
              105.83755591665172,
              21.001470630850417
            ],
            [
              105.83758687705141,
              21.001470207543072
            ],
            [
              105.83758750617041,
              21.001489698764058
            ],
            [
              105.83767661363069,
              21.001482836541626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,115_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8375",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001471911064858,
          "to_latitude": 21.001482836541626,
          "to_longitude": 105.83767661363069,
          "from_longitude": 105.83753583757954
        }
      },
      {
        "id": 8368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783237807121,
            21.002853671421853,
            105.83784697700861,
            21.002902795644253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783237807121,
              21.002902795644253
            ],
            [
              105.83784697700861,
              21.002853671421853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,60_Ngách 15/34, Phương Mai",
          "maTaiSan": "04.O17.DODV.8376",
          "diaChiLapD": "Ngách 15/34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002902795644253,
          "to_latitude": 21.002853671421853,
          "to_longitude": 105.83784697700861,
          "from_longitude": 105.83783237807121
        }
      },
      {
        "id": 8369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83482022227115,
            21.002136656592285,
            105.83491005970485,
            21.002203422163745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83491005970485,
              21.00214767611119
            ],
            [
              105.83483440565303,
              21.002136656592285
            ],
            [
              105.83482732339174,
              21.00217363688084
            ],
            [
              105.83482155227824,
              21.002197846349713
            ],
            [
              105.83482061140919,
              21.0022017917389
            ],
            [
              105.83482022227115,
              21.002203422163745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8B,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8377",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00214767611119,
          "to_latitude": 21.002203422163745,
          "to_longitude": 105.83482022227115,
          "from_longitude": 105.83491005970485
        }
      },
      {
        "id": 8370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353995161315,
            21.0032152955172,
            105.83570280448339,
            21.00328499623349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353995161315,
              21.0032152955172
            ],
            [
              105.83554883440884,
              21.003249610458504
            ],
            [
              105.83555670573924,
              21.00325141981834
            ],
            [
              105.83570280448339,
              21.00328499623349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,71_Phương Mai",
          "maTaiSan": "04.O17.DODV.8378",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0032152955172,
          "to_latitude": 21.00328499623349,
          "to_longitude": 105.83570280448339,
          "from_longitude": 105.8353995161315
        }
      },
      {
        "id": 8371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83642390078346,
            21.001039295897094,
            105.83642400394956,
            21.001076280679154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83642400394956,
              21.001076280679154
            ],
            [
              105.83642400135773,
              21.00107581553062
            ],
            [
              105.83642396674382,
              21.001063044088276
            ],
            [
              105.83642390078346,
              21.001039295897094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I4,2_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8379",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001076280679154,
          "to_latitude": 21.001039295897094,
          "to_longitude": 105.83642390078346,
          "from_longitude": 105.83642400394956
        }
      },
      {
        "id": 8372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893801297539,
            20.99979646311508,
            105.83894560902854,
            20.999899907576022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389397736186,
              20.999899907576022
            ],
            [
              105.83893801297539,
              20.99985713768849
            ],
            [
              105.83894560902854,
              20.99979646311508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,151_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8380",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999899907576022,
          "to_latitude": 20.99979646311508,
          "to_longitude": 105.83894560902854,
          "from_longitude": 105.8389397736186
        }
      },
      {
        "id": 8373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608899182228,
            21.002941074680827,
            105.83611889251794,
            21.002941335363268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608899182228,
              21.002941335363268
            ],
            [
              105.83609171761957,
              21.002941317457893
            ],
            [
              105.83609276406405,
              21.002941307801567
            ],
            [
              105.83609742307154,
              21.002941268658113
            ],
            [
              105.83609807709995,
              21.00294126273578
            ],
            [
              105.83611889251794,
              21.002941074680827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,68_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8381",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002941335363268,
          "to_latitude": 21.002941074680827,
          "to_longitude": 105.83611889251794,
          "from_longitude": 105.83608899182228
        }
      },
      {
        "id": 8374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83556581771616,
            21.002671525459153,
            105.8359937649334,
            21.00267585813947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83556581771616,
              21.00267283552736
            ],
            [
              105.83568035472882,
              21.00267585813947
            ],
            [
              105.83577467392784,
              21.00267393272255
            ],
            [
              105.83584219316478,
              21.002672554341586
            ],
            [
              105.8359937649334,
              21.002671525459153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,90_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8382",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00267283552736,
          "to_latitude": 21.002671525459153,
          "to_longitude": 105.8359937649334,
          "from_longitude": 105.83556581771616
        }
      },
      {
        "id": 8375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364144461089,
            21.00153687993966,
            105.83641771478182,
            21.001720829788997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641771478182,
              21.00153687993966
            ],
            [
              105.83641768267849,
              21.00154026719388
            ],
            [
              105.83641729315902,
              21.001582217620104
            ],
            [
              105.83641716075032,
              21.001596430527425
            ],
            [
              105.83641704735986,
              21.00160162824538
            ],
            [
              105.83641698073278,
              21.001604688701505
            ],
            [
              105.8364144461089,
              21.001720829788997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,2_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8383",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00153687993966,
          "to_latitude": 21.001720829788997,
          "to_longitude": 105.8364144461089,
          "from_longitude": 105.83641771478182
        }
      },
      {
        "id": 8376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83828341649213,
            21.00348211647736,
            105.83829090040528,
            21.003731081641767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83829090040528,
              21.003731081641767
            ],
            [
              105.83828454745992,
              21.003616239848782
            ],
            [
              105.83828341649213,
              21.00348211647736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,46_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8384",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003731081641767,
          "to_latitude": 21.00348211647736,
          "to_longitude": 105.83828341649213,
          "from_longitude": 105.83829090040528
        }
      },
      {
        "id": 8377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484020846782,
            21.00264075554397,
            105.83503621458902,
            21.002681842866902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503621458902,
              21.002681842866902
            ],
            [
              105.83484020846782,
              21.00264075554397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,76_Phương Mai",
          "maTaiSan": "04.O17.DODV.8385",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002681842866902,
          "to_latitude": 21.00264075554397,
          "to_longitude": 105.83484020846782,
          "from_longitude": 105.83503621458902
        }
      },
      {
        "id": 8378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341847329399,
            21.002365597317016,
            105.83398050361996,
            21.002460413604304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398050361996,
              21.002434524574465
            ],
            [
              105.83397753022618,
              21.002434122755528
            ],
            [
              105.83396841076619,
              21.002432885758655
            ],
            [
              105.8339591471812,
              21.002460413604304
            ],
            [
              105.83388372145698,
              21.002450344547242
            ],
            [
              105.83376673074018,
              21.002424950796364
            ],
            [
              105.83359635341114,
              21.00237548009086
            ],
            [
              105.83347168144064,
              21.002380571504325
            ],
            [
              105.83342936531746,
              21.002379284766192
            ],
            [
              105.83341847329399,
              21.002365597317016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,89_Phương Mai",
          "maTaiSan": "04.O17.DODV.8386",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002434524574465,
          "to_latitude": 21.002365597317016,
          "to_longitude": 105.83341847329399,
          "from_longitude": 105.83398050361996
        }
      },
      {
        "id": 8379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753451434683,
            21.00035355575499,
            105.8375441694201,
            21.000746033114677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753451434683,
              21.000746033114677
            ],
            [
              105.83753652054537,
              21.000545333735936
            ],
            [
              105.8375378338612,
              21.000532304567095
            ],
            [
              105.8375441694201,
              21.00046936824585
            ],
            [
              105.83754251621318,
              21.00035355575499
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8387",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000746033114677,
          "to_latitude": 21.00035355575499,
          "to_longitude": 105.83754251621318,
          "from_longitude": 105.83753451434683
        }
      },
      {
        "id": 8380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83762653715334,
            21.003489272168363,
            105.83807834670168,
            21.003696612395988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83805106444926,
              21.003696612395988
            ],
            [
              105.8380521574154,
              21.003693736564774
            ],
            [
              105.83805442542473,
              21.003686663982954
            ],
            [
              105.83807834670168,
              21.003612047279983
            ],
            [
              105.83762653715334,
              21.003489272168363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,41_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8388",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003696612395988,
          "to_latitude": 21.003489272168363,
          "to_longitude": 105.83762653715334,
          "from_longitude": 105.83805106444926
        }
      },
      {
        "id": 8381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764159465153,
            21.003385119807557,
            105.83766170333402,
            21.00344973434665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83764159465153,
              21.00344973434665
            ],
            [
              105.83764957632764,
              21.003424086822392
            ],
            [
              105.83766170333402,
              21.003385119807557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,47_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8389",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00344973434665,
          "to_latitude": 21.003385119807557,
          "to_longitude": 105.83766170333402,
          "from_longitude": 105.83764159465153
        }
      },
      {
        "id": 8382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824563705187,
            20.999565662444517,
            105.83872404399001,
            20.999968313286452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824563705187,
              20.999565662444517
            ],
            [
              105.83824624798409,
              20.999574994272965
            ],
            [
              105.83824644588898,
              20.999577880910746
            ],
            [
              105.83827061882353,
              20.99994686050069
            ],
            [
              105.83872404399001,
              20.999968313286452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,144_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8390",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 89.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999565662444517,
          "to_latitude": 20.999968313286452,
          "to_longitude": 105.83872404399001,
          "from_longitude": 105.83824563705187
        }
      },
      {
        "id": 8383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391339693037,
            21.000022636310796,
            105.83924375050424,
            21.000054492914455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391339693037,
              21.000022636310796
            ],
            [
              105.83924375050424,
              21.000054492914455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,152_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8391",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000022636310796,
          "to_latitude": 21.000054492914455,
          "to_longitude": 105.83924375050424,
          "from_longitude": 105.8391339693037
        }
      },
      {
        "id": 8384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398028055714,
            21.002484620044076,
            105.83426033032634,
            21.002658546499454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398028055714,
              21.002658546499454
            ],
            [
              105.83398935501545,
              21.002620002931796
            ],
            [
              105.83399558225888,
              21.002589745961288
            ],
            [
              105.8340279117702,
              21.002484620044076
            ],
            [
              105.83418679022894,
              21.00252350214467
            ],
            [
              105.83426033032634,
              21.002540585728347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14,5_Phương Mai",
          "maTaiSan": "04.O17.DODV.8392",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002658546499454,
          "to_latitude": 21.002540585728347,
          "to_longitude": 105.83426033032634,
          "from_longitude": 105.83398028055714
        }
      },
      {
        "id": 8385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559079283356,
            21.001549396022792,
            105.83560593256867,
            21.001605156023047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559079283356,
              21.001605156023047
            ],
            [
              105.83559521796188,
              21.001588855460977
            ],
            [
              105.83559538950365,
              21.001588224167254
            ],
            [
              105.83559638387095,
              21.001584563027727
            ],
            [
              105.83560593256867,
              21.001549396022792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,2_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8393",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001605156023047,
          "to_latitude": 21.001549396022792,
          "to_longitude": 105.83560593256867,
          "from_longitude": 105.83559079283356
        }
      },
      {
        "id": 8386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572479595436,
            21.000994530159875,
            105.83572657618204,
            21.0010607236808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572479595436,
              21.0010607236808
            ],
            [
              105.83572482619257,
              21.00105958998385
            ],
            [
              105.83572657618204,
              21.000994530159875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,99_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8394",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0010607236808,
          "to_latitude": 21.000994530159875,
          "to_longitude": 105.83572657618204,
          "from_longitude": 105.83572479595436
        }
      },
      {
        "id": 8387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455945567826,
            21.002343370579357,
            105.83515864531053,
            21.00241013806346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455945567826,
              21.00236374000424
            ],
            [
              105.83456138353039,
              21.00235739351875
            ],
            [
              105.834569967012,
              21.00235132420731
            ],
            [
              105.83457560821788,
              21.00234733590256
            ],
            [
              105.83458264135027,
              21.002343370579357
            ],
            [
              105.83461475231546,
              21.00234605829562
            ],
            [
              105.83462386914246,
              21.0023468219804
            ],
            [
              105.83462622869807,
              21.002347019055936
            ],
            [
              105.83464179485198,
              21.00234832195523
            ],
            [
              105.8346452976235,
              21.002348615489353
            ],
            [
              105.83466513244092,
              21.00234973939076
            ],
            [
              105.83483173696831,
              21.002361555774737
            ],
            [
              105.83497936266798,
              21.002388315031624
            ],
            [
              105.83515864531053,
              21.00241013806346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,82_Phương Mai",
          "maTaiSan": "04.O17.DODV.8395",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00236374000424,
          "to_latitude": 21.00241013806346,
          "to_longitude": 105.83515864531053,
          "from_longitude": 105.83455945567826
        }
      },
      {
        "id": 8388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953261771498,
            20.999147473945996,
            105.83953317779348,
            20.999175284309494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953261771498,
              20.999175284309494
            ],
            [
              105.83953308598093,
              20.999152039300135
            ],
            [
              105.83953317779348,
              20.999147473945996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,155_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8396",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999175284309494,
          "to_latitude": 20.999147473945996,
          "to_longitude": 105.83953317779348,
          "from_longitude": 105.83953261771498
        }
      },
      {
        "id": 8389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736036678833,
            21.00275475399116,
            105.83743690801585,
            21.00301047237815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743690801585,
              21.00275475399116
            ],
            [
              105.83740731322428,
              21.00285362574439
            ],
            [
              105.83738178436865,
              21.002938915560005
            ],
            [
              105.83737653147854,
              21.00295646487847
            ],
            [
              105.83736036678833,
              21.00301047237815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,55_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8397",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00275475399116,
          "to_latitude": 21.00301047237815,
          "to_longitude": 105.83736036678833,
          "from_longitude": 105.83743690801585
        }
      },
      {
        "id": 8390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774295882786,
            21.003197295699874,
            105.83794360435893,
            21.003326877832638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794360435893,
              21.003197295699874
            ],
            [
              105.83794300486052,
              21.003199143940464
            ],
            [
              105.83793813988935,
              21.003214150591674
            ],
            [
              105.83790158575559,
              21.003326877832638
            ],
            [
              105.83774295882786,
              21.003289939952314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,50_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8398",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003197295699874,
          "to_latitude": 21.003289939952314,
          "to_longitude": 105.83774295882786,
          "from_longitude": 105.83794360435893
        }
      },
      {
        "id": 8391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623830372115,
            21.001227396816937,
            105.83625190259927,
            21.001227510590933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83623830372115,
              21.001227510590933
            ],
            [
              105.83624161038485,
              21.001227482604676
            ],
            [
              105.83625116682889,
              21.001227404044432
            ],
            [
              105.83625190259927,
              21.001227396816937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,102_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8399",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001227510590933,
          "to_latitude": 21.001227396816937,
          "to_longitude": 105.83625190259927,
          "from_longitude": 105.83623830372115
        }
      },
      {
        "id": 8392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83761229791035,
            21.00044493560096,
            105.83767531350408,
            21.0004454940688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83761229791035,
              21.0004454940688
            ],
            [
              105.8376315087259,
              21.00044532366967
            ],
            [
              105.83767531350408,
              21.00044493560096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8400",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0004454940688,
          "to_latitude": 21.00044493560096,
          "to_longitude": 105.83767531350408,
          "from_longitude": 105.83761229791035
        }
      },
      {
        "id": 8393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8348762547891,
            21.002484642065724,
            105.83488825516368,
            21.002557388932505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8348762547891,
              21.002557388932505
            ],
            [
              105.83487723178082,
              21.002551464398064
            ],
            [
              105.83488825516368,
              21.002484642065724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,79_Phương Mai",
          "maTaiSan": "04.O17.DODV.8401",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002557388932505,
          "to_latitude": 21.002484642065724,
          "to_longitude": 105.83488825516368,
          "from_longitude": 105.8348762547891
        }
      },
      {
        "id": 8394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954909386567,
            20.9985991123094,
            105.83960166031652,
            20.998599582873865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954909386567,
              20.9985991123094
            ],
            [
              105.83954923911263,
              20.998599114302785
            ],
            [
              105.83960166031652,
              20.998599582873865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,7_Trường Chinh",
          "maTaiSan": "04.O17.DODV.8402",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.9985991123094,
          "to_latitude": 20.998599582873865,
          "to_longitude": 105.83960166031652,
          "from_longitude": 105.83954909386567
        }
      },
      {
        "id": 8395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83539193474105,
            21.00209215619664,
            105.83539467703802,
            21.002161273657872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83539193474105,
              21.002161273657872
            ],
            [
              105.83539204614235,
              21.002157094788423
            ],
            [
              105.83539467703802,
              21.00209215619664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2A,4_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8403",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002161273657872,
          "to_latitude": 21.00209215619664,
          "to_longitude": 105.83539467703802,
          "from_longitude": 105.83539193474105
        }
      },
      {
        "id": 8396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83510446474048,
            21.001590598125038,
            105.83519967303194,
            21.001606486621885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83519967303194,
              21.001606486621885
            ],
            [
              105.83519907421181,
              21.001606387495475
            ],
            [
              105.83510446474048,
              21.001590598125038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,7_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8404",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001606486621885,
          "to_latitude": 21.001590598125038,
          "to_longitude": 105.83510446474048,
          "from_longitude": 105.83519967303194
        }
      },
      {
        "id": 8397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83588295094508,
            21.002235238022656,
            105.83588315179621,
            21.002276031393208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588315179621,
              21.002235238022656
            ],
            [
              105.83588313663535,
              21.00223804261305
            ],
            [
              105.83588309866464,
              21.002245905379613
            ],
            [
              105.83588295094508,
              21.002276031393208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,5_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8405",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002235238022656,
          "to_latitude": 21.002276031393208,
          "to_longitude": 105.83588295094508,
          "from_longitude": 105.83588315179621
        }
      },
      {
        "id": 8398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8374346033454,
            21.001489698764058,
            105.83759101302229,
            21.001746548924427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758750617041,
              21.001489698764058
            ],
            [
              105.83759101302229,
              21.001598287151577
            ],
            [
              105.83758756476651,
              21.001680674672944
            ],
            [
              105.8375106611923,
              21.00169479474069
            ],
            [
              105.8374346033454,
              21.001746548924427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,115_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8406",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001489698764058,
          "to_latitude": 21.001746548924427,
          "to_longitude": 105.8374346033454,
          "from_longitude": 105.83758750617041
        }
      },
      {
        "id": 8399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839914018804,
            20.99917061291896,
            105.84000539870095,
            20.999174098300927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839914018804,
              20.99917061291896
            ],
            [
              105.84000539870095,
              20.999174098300927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,158_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8407",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99917061291896,
          "to_latitude": 20.999174098300927,
          "to_longitude": 105.84000539870095,
          "from_longitude": 105.839914018804
        }
      },
      {
        "id": 8400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730407612539,
            21.002940640031397,
            105.83734831681447,
            21.002950499683813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730407612539,
              21.002940640031397
            ],
            [
              105.83734831681447,
              21.002950499683813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,56_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8408",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002940640031397,
          "to_latitude": 21.002950499683813,
          "to_longitude": 105.83734831681447,
          "from_longitude": 105.83730407612539
        }
      },
      {
        "id": 8401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83583083969918,
            21.002780295159496,
            105.8359348012109,
            21.002784353563897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583083969918,
              21.002784353563897
            ],
            [
              105.8358969085559,
              21.00278177466916
            ],
            [
              105.8358991203371,
              21.002781687936405
            ],
            [
              105.8359348012109,
              21.002780295159496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,2_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8409",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002784353563897,
          "to_latitude": 21.002780295159496,
          "to_longitude": 105.8359348012109,
          "from_longitude": 105.83583083969918
        }
      },
      {
        "id": 8402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965648577515,
            20.998852277021488,
            105.83970873997463,
            20.998852471187995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83970873997463,
              20.99885244921955
            ],
            [
              105.83970080693918,
              20.998852471187995
            ],
            [
              105.8396774983839,
              20.998852368478058
            ],
            [
              105.83965648577515,
              20.998852277021488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4,3_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8410",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99885244921955,
          "to_latitude": 20.998852277021488,
          "to_longitude": 105.83965648577515,
          "from_longitude": 105.83970873997463
        }
      },
      {
        "id": 8403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83560622967082,
            21.002794290263342,
            105.83571120767968,
            21.002795966296116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560622967082,
              21.002795966296116
            ],
            [
              105.83566888408913,
              21.00279496592898
            ],
            [
              105.83571120767968,
              21.002794290263342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,5_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8411",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002795966296116,
          "to_latitude": 21.002794290263342,
          "to_longitude": 105.83571120767968,
          "from_longitude": 105.83560622967082
        }
      },
      {
        "id": 8404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650068149403,
            21.001060703815668,
            105.83650117044881,
            21.00107514157253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650068149403,
              21.00107514157253
            ],
            [
              105.83650117044881,
              21.001060703815668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,104_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8412",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00107514157253,
          "to_latitude": 21.001060703815668,
          "to_longitude": 105.83650117044881,
          "from_longitude": 105.83650068149403
        }
      },
      {
        "id": 8405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83789543203125,
            21.00095051803437,
            105.83799645898617,
            21.001326232634344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83799645898617,
              21.001326232634344
            ],
            [
              105.83797218651124,
              21.001322209932486
            ],
            [
              105.83796557716238,
              21.001256127266608
            ],
            [
              105.83789543203125,
              21.00124910133539
            ],
            [
              105.83789689564661,
              21.001102235082797
            ],
            [
              105.83791685533332,
              21.00109784199653
            ],
            [
              105.83791395027917,
              21.00095460021278
            ],
            [
              105.83791386882007,
              21.00095051803437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,121_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8413",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001326232634344,
          "to_latitude": 21.00095051803437,
          "to_longitude": 105.83791386882007,
          "from_longitude": 105.83799645898617
        }
      },
      {
        "id": 8406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83450194685821,
            21.00252773080272,
            105.83501220936067,
            21.00267222352018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450194685821,
              21.00264440316421
            ],
            [
              105.83451765301979,
              21.002648046549204
            ],
            [
              105.83451865657449,
              21.002648280983887
            ],
            [
              105.83453141373509,
              21.002651239728912
            ],
            [
              105.83453681871634,
              21.002657353359005
            ],
            [
              105.83453704230953,
              21.002657606069253
            ],
            [
              105.83454620788703,
              21.002655370808515
            ],
            [
              105.83455109321326,
              21.002645869306384
            ],
            [
              105.83459212137898,
              21.00265613111166
            ],
            [
              105.83464268969314,
              21.00266907386938
            ],
            [
              105.83465795739511,
              21.00267222352018
            ],
            [
              105.83466814545157,
              21.00266255601246
            ],
            [
              105.83470479392325,
              21.002558075382648
            ],
            [
              105.83472087250603,
              21.00254065188802
            ],
            [
              105.83474416885794,
              21.00252773080272
            ],
            [
              105.8348762547891,
              21.002557388932505
            ],
            [
              105.83501220936067,
              21.00258791494784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,79_Phương Mai",
          "maTaiSan": "04.O17.DODV.8414",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00264440316421,
          "to_latitude": 21.00258791494784,
          "to_longitude": 105.83501220936067,
          "from_longitude": 105.83450194685821
        }
      },
      {
        "id": 8407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83690042883096,
            21.003109154655423,
            105.83719519267433,
            21.003283915070952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719519267433,
              21.003279738826812
            ],
            [
              105.83719505739879,
              21.00327980091151
            ],
            [
              105.83718606582349,
              21.003283915070952
            ],
            [
              105.83706398255075,
              21.00325122261034
            ],
            [
              105.83703260425189,
              21.003208857582692
            ],
            [
              105.83704742427531,
              21.00316172575362
            ],
            [
              105.83704838356712,
              21.00315816142461
            ],
            [
              105.83690042883096,
              21.003109154655423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,39_Phương Mai",
          "maTaiSan": "04.O17.DODV.8415",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003279738826812,
          "to_latitude": 21.003109154655423,
          "to_longitude": 105.83690042883096,
          "from_longitude": 105.83719519267433
        }
      },
      {
        "id": 8408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83600267187951,
            21.001237151016785,
            105.83619912166981,
            21.001489025258074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619912166981,
              21.001489025258074
            ],
            [
              105.83607934228239,
              21.00148491955696
            ],
            [
              105.83602118601141,
              21.001471413838754
            ],
            [
              105.83601168210669,
              21.00146504038659
            ],
            [
              105.83600982862319,
              21.001459512715424
            ],
            [
              105.83600267187951,
              21.001438160380275
            ],
            [
              105.83600600751697,
              21.001365791094596
            ],
            [
              105.83602438752195,
              21.001237151016785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,94_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8416",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001489025258074,
          "to_latitude": 21.001237151016785,
          "to_longitude": 105.83602438752195,
          "from_longitude": 105.83619912166981
        }
      },
      {
        "id": 8409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792216777412,
            21.00178559116627,
            105.83810619874912,
            21.002050449464928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810619874912,
              21.002050449464928
            ],
            [
              105.83810619470012,
              21.002050413355857
            ],
            [
              105.83810446121443,
              21.002029527578266
            ],
            [
              105.83808420465633,
              21.00178559116627
            ],
            [
              105.83792216777412,
              21.00180897945601
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,62_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8417",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002050449464928,
          "to_latitude": 21.00180897945601,
          "to_longitude": 105.83792216777412,
          "from_longitude": 105.83810619874912
        }
      },
      {
        "id": 8410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010788149449,
            20.999172686650986,
            105.84021548464527,
            20.99917601147148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84021548464527,
              20.99917601147148
            ],
            [
              105.84010788149449,
              20.999172686650986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,159_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8418",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99917601147148,
          "to_latitude": 20.999172686650986,
          "to_longitude": 105.84010788149449,
          "from_longitude": 105.84021548464527
        }
      },
      {
        "id": 8411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83951195574369,
            20.998584650555326,
            105.83955005064905,
            20.998585654950528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83955005064905,
              20.998585654950528
            ],
            [
              105.83954934649535,
              20.99858563764892
            ],
            [
              105.83953635318598,
              20.99858529437075
            ],
            [
              105.83951195574369,
              20.998584650555326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,5_Trường Chinh",
          "maTaiSan": "04.O17.DODV.8419",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998585654950528,
          "to_latitude": 20.998584650555326,
          "to_longitude": 105.83951195574369,
          "from_longitude": 105.83955005064905
        }
      },
      {
        "id": 8412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823810157394,
            21.002637363391703,
            105.83824755574595,
            21.00283475917111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824755574595,
              21.00283475917111
            ],
            [
              105.83823879076195,
              21.00265174837669
            ],
            [
              105.83823810157394,
              21.002637363391703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8420",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00283475917111,
          "to_latitude": 21.002637363391703,
          "to_longitude": 105.83823810157394,
          "from_longitude": 105.83824755574595
        }
      },
      {
        "id": 8413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718125046278,
            21.002614172197134,
            105.83719714559732,
            21.002665986228664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719714559732,
              21.002614172197134
            ],
            [
              105.83718125046278,
              21.002665986228664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,59_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8421",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002614172197134,
          "to_latitude": 21.002665986228664,
          "to_longitude": 105.83718125046278,
          "from_longitude": 105.83719714559732
        }
      },
      {
        "id": 8414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439494692615,
            21.002006539325752,
            105.83451343827286,
            21.002017123227503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439494692615,
              21.002006539325752
            ],
            [
              105.83439873273149,
              21.00200687934878
            ],
            [
              105.83451343827286,
              21.002017123227503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8422",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002006539325752,
          "to_latitude": 21.002017123227503,
          "to_longitude": 105.83451343827286,
          "from_longitude": 105.83439494692615
        }
      },
      {
        "id": 8415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382252827238,
            21.002144590743725,
            105.83823920377755,
            21.002145242781253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823920377755,
              21.002144590743725
            ],
            [
              105.83823515661287,
              21.002144644093352
            ],
            [
              105.8382252827238,
              21.002145242781253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,61_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8423",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002144590743725,
          "to_latitude": 21.002145242781253,
          "to_longitude": 105.8382252827238,
          "from_longitude": 105.83823920377755
        }
      },
      {
        "id": 8416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83682820740849,
            21.003580728790087,
            105.83727336628509,
            21.003690215269515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83682820740849,
              21.003580728790087
            ],
            [
              105.83702781395571,
              21.003629822123234
            ],
            [
              105.8371359723829,
              21.003656423090206
            ],
            [
              105.83727336628509,
              21.003690215269515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,40_Phương Mai",
          "maTaiSan": "04.O17.DODV.8424",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003580728790087,
          "to_latitude": 21.003690215269515,
          "to_longitude": 105.83727336628509,
          "from_longitude": 105.83682820740849
        }
      },
      {
        "id": 8417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365940979936,
            21.00154213888104,
            105.83661112100877,
            21.001725318418423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660584941116,
              21.00154213888104
            ],
            [
              105.83660583407111,
              21.00154283805312
            ],
            [
              105.83660495389759,
              21.00158596688959
            ],
            [
              105.83660464266151,
              21.00160122570756
            ],
            [
              105.83660365725703,
              21.00164946189893
            ],
            [
              105.83661112100877,
              21.00166220043981
            ],
            [
              105.83660916238294,
              21.001725135089355
            ],
            [
              105.8365940979936,
              21.001725318418423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,5_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8425",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00154213888104,
          "to_latitude": 21.001725318418423,
          "to_longitude": 105.8365940979936,
          "from_longitude": 105.83660584941116
        }
      },
      {
        "id": 8418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529363813331,
            21.001848695588446,
            105.83531087147189,
            21.00191664041983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83529363813331,
              21.00191664041983
            ],
            [
              105.83529587817472,
              21.001907806717167
            ],
            [
              105.83531087147189,
              21.001848695588446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2C,2_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8426",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00191664041983,
          "to_latitude": 21.001848695588446,
          "to_longitude": 105.83531087147189,
          "from_longitude": 105.83529363813331
        }
      },
      {
        "id": 8419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467464301812,
            21.003037523739817,
            105.83468494317198,
            21.003069067929044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467464301812,
              21.003069067929044
            ],
            [
              105.83468024565127,
              21.003051912582904
            ],
            [
              105.83468130394716,
              21.003048672038204
            ],
            [
              105.8346821900982,
              21.003045955305424
            ],
            [
              105.83468494317198,
              21.003037523739817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,75_Phương Mai",
          "maTaiSan": "04.O17.DODV.8427",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003069067929044,
          "to_latitude": 21.003037523739817,
          "to_longitude": 105.83468494317198,
          "from_longitude": 105.83467464301812
        }
      },
      {
        "id": 8420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601753870794,
            21.002722059783665,
            105.83602623840164,
            21.00313990794882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602623840164,
              21.00313990794882
            ],
            [
              105.83601973968008,
              21.003014117827988
            ],
            [
              105.8360193879503,
              21.002963058403466
            ],
            [
              105.83601893550211,
              21.002899960378624
            ],
            [
              105.8360205529229,
              21.002878299405868
            ],
            [
              105.83602076144356,
              21.002875500188612
            ],
            [
              105.83602387711957,
              21.002833751344088
            ],
            [
              105.83601753870794,
              21.002722059783665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8428",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00313990794882,
          "to_latitude": 21.002722059783665,
          "to_longitude": 105.83601753870794,
          "from_longitude": 105.83602623840164
        }
      },
      {
        "id": 8421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449763220136,
            21.002506731134577,
            105.83453844484293,
            21.00251785297865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453844484293,
              21.00251785297865
            ],
            [
              105.83452197606019,
              21.00251336520492
            ],
            [
              105.83449763220136,
              21.002506731134577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,81_Phương Mai",
          "maTaiSan": "04.O17.DODV.8429",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00251785297865,
          "to_latitude": 21.002506731134577,
          "to_longitude": 105.83449763220136,
          "from_longitude": 105.83453844484293
        }
      },
      {
        "id": 8422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83804731852801,
            21.003245638160582,
            105.83805825823399,
            21.00328344503336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83804731852801,
              21.00328344503336
            ],
            [
              105.83805825823399,
              21.003245638160582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,50_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8430",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00328344503336,
          "to_latitude": 21.003245638160582,
          "to_longitude": 105.83805825823399,
          "from_longitude": 105.83804731852801
        }
      },
      {
        "id": 8423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84000482323633,
            20.999154622182527,
            105.84001170911621,
            20.999387735052427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84000482323633,
              20.999154622182527
            ],
            [
              105.8400048780164,
              20.99915650513707
            ],
            [
              105.84000539870095,
              20.999174098300927
            ],
            [
              105.84001170911621,
              20.999387735052427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,158_Ngách 78/20, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8431",
          "diaChiLapD": "Ngách 78/20, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999154622182527,
          "to_latitude": 20.999387735052427,
          "to_longitude": 105.84001170911621,
          "from_longitude": 105.84000482323633
        }
      },
      {
        "id": 8424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608732170097,
            21.00308873553802,
            105.83721079349647,
            21.00338466385137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721079349647,
              21.00338466385137
            ],
            [
              105.83710916156548,
              21.00337084398375
            ],
            [
              105.83708446949781,
              21.003380806964554
            ],
            [
              105.83677175713242,
              21.003288860750832
            ],
            [
              105.83609242117299,
              21.00309023784357
            ],
            [
              105.83608732170097,
              21.00308873553802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,66_Ngõ 65, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8432",
          "diaChiLapD": "Ngõ 65, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 119.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00338466385137,
          "to_latitude": 21.00308873553802,
          "to_longitude": 105.83608732170097,
          "from_longitude": 105.83721079349647
        }
      },
      {
        "id": 8425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83828454745992,
            21.003616138752164,
            105.83830195654157,
            21.003616239848782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83828454745992,
              21.003616239848782
            ],
            [
              105.83829452257386,
              21.003616181688145
            ],
            [
              105.83830195654157,
              21.003616138752164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,46_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8433",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003616239848782,
          "to_latitude": 21.003616138752164,
          "to_longitude": 105.83830195654157,
          "from_longitude": 105.83828454745992
        }
      },
      {
        "id": 8426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411537205679,
            21.00225511077693,
            105.83447283578802,
            21.00231921382733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411537205679,
              21.00225511077693
            ],
            [
              105.83419126082698,
              21.0022695933367
            ],
            [
              105.8342089593799,
              21.002272970471225
            ],
            [
              105.83425848001897,
              21.002281749191525
            ],
            [
              105.83426110172374,
              21.002282214148675
            ],
            [
              105.8342636512214,
              21.002282666814523
            ],
            [
              105.83426668885126,
              21.00228320470083
            ],
            [
              105.83428560044204,
              21.002286434075447
            ],
            [
              105.83433538517409,
              21.002296347737
            ],
            [
              105.83434868208197,
              21.002293282946642
            ],
            [
              105.83434871376191,
              21.00229327195265
            ],
            [
              105.83435750153632,
              21.002291249909288
            ],
            [
              105.83446137623648,
              21.0023164356834
            ],
            [
              105.83446497263725,
              21.002317307730113
            ],
            [
              105.83447283578802,
              21.00231921382733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.84_Phương Mai",
          "maTaiSan": "04.O17.DODV.8434",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00225511077693,
          "to_latitude": 21.00231921382733,
          "to_longitude": 105.83447283578802,
          "from_longitude": 105.83411537205679
        }
      },
      {
        "id": 8427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83507100546711,
            21.002884992835142,
            105.83510542804628,
            21.003139922900015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83507100546711,
              21.003139922900015
            ],
            [
              105.83508993366021,
              21.003036510036974
            ],
            [
              105.83510542804628,
              21.00298047195389
            ],
            [
              105.8351028240218,
              21.00296226937513
            ],
            [
              105.83510078143894,
              21.00294799217076
            ],
            [
              105.83509438662546,
              21.002884992835142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,73_Phương Mai",
          "maTaiSan": "04.O17.DODV.8435",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003139922900015,
          "to_latitude": 21.002884992835142,
          "to_longitude": 105.83509438662546,
          "from_longitude": 105.83507100546711
        }
      },
      {
        "id": 8428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344090455081,
            21.001894380768384,
            105.83450847886046,
            21.00190118777343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344090455081,
              21.001894380768384
            ],
            [
              105.83450847886046,
              21.00190118777343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8436",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001894380768384,
          "to_latitude": 21.00190118777343,
          "to_longitude": 105.83450847886046,
          "from_longitude": 105.8344090455081
        }
      },
      {
        "id": 8429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751777674874,
            21.00002633855336,
            105.83763535033445,
            21.000031975160784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751777674874,
              21.00002633855336
            ],
            [
              105.83756640286825,
              21.000028668988868
            ],
            [
              105.83758005401181,
              21.000029323499753
            ],
            [
              105.83759629415117,
              21.000030102561126
            ],
            [
              105.83761958066084,
              21.00003121907855
            ],
            [
              105.83763535033445,
              21.000031975160784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8437",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00002633855336,
          "to_latitude": 21.000031975160784,
          "to_longitude": 105.83763535033445,
          "from_longitude": 105.83751777674874
        }
      },
      {
        "id": 8430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623972833018,
            21.00091455153619,
            105.8362457285439,
            21.00116744810956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83623972833018,
              21.00116744810956
            ],
            [
              105.8362441183963,
              21.000982400737193
            ],
            [
              105.8362457285439,
              21.00091455153619
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,102_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8438",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00116744810956,
          "to_latitude": 21.00091455153619,
          "to_longitude": 105.8362457285439,
          "from_longitude": 105.83623972833018
        }
      },
      {
        "id": 8431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83599794893577,
            21.001726347829457,
            105.8360325442856,
            21.00172679933808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83599794893577,
              21.001726347829457
            ],
            [
              105.8360325442856,
              21.00172679933808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,93_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8439",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001726347829457,
          "to_latitude": 21.00172679933808,
          "to_longitude": 105.8360325442856,
          "from_longitude": 105.83599794893577
        }
      },
      {
        "id": 8432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623295930892,
            21.00116744810956,
            105.83623972833018,
            21.00145275167722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83623295930892,
              21.00145275167722
            ],
            [
              105.83623580164321,
              21.001332944686318
            ],
            [
              105.83623830372115,
              21.001227510590933
            ],
            [
              105.83623972833018,
              21.00116744810956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,102_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8440",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00145275167722,
          "to_latitude": 21.00116744810956,
          "to_longitude": 105.83623972833018,
          "from_longitude": 105.83623295930892
        }
      },
      {
        "id": 8433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505644047844,
            21.002251311101645,
            105.8352445190925,
            21.002346788790234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352445190925,
              21.002264881730742
            ],
            [
              105.83518873346485,
              21.00226380784581
            ],
            [
              105.83513302211458,
              21.00225966080373
            ],
            [
              105.83507692861505,
              21.002251311101645
            ],
            [
              105.83505644047844,
              21.00233952942813
            ],
            [
              105.83515085675836,
              21.002346788790234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8B,3_Phương Mai",
          "maTaiSan": "04.O17.DODV.8441",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002264881730742,
          "to_latitude": 21.002346788790234,
          "to_longitude": 105.83515085675836,
          "from_longitude": 105.8352445190925
        }
      },
      {
        "id": 8434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83881734629846,
            20.999791447668297,
            105.83910677500438,
            21.00022228404785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881734629846,
              21.00022228404785
            ],
            [
              105.83882970193527,
              21.000057534454452
            ],
            [
              105.83901635507169,
              21.000063594727596
            ],
            [
              105.8390617543303,
              20.999867871430833
            ],
            [
              105.83906506671575,
              20.999857687495517
            ],
            [
              105.83908868789234,
              20.999827996755993
            ],
            [
              105.83910444381273,
              20.999796870718704
            ],
            [
              105.83910574371681,
              20.999791447668297
            ],
            [
              105.83910664501389,
              20.99979163019332
            ],
            [
              105.83910677500438,
              20.999791655746115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,151_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8442",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00022228404785,
          "to_latitude": 20.999791655746115,
          "to_longitude": 105.83910677500438,
          "from_longitude": 105.83881734629846
        }
      },
      {
        "id": 8435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83403819182746,
            21.002263530388262,
            105.8343496208153,
            21.002322866222606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83403819182746,
              21.002263530388262
            ],
            [
              105.83417221905087,
              21.002285167949044
            ],
            [
              105.83418462164295,
              21.002287331813537
            ],
            [
              105.834195497255,
              21.0022892285798
            ],
            [
              105.83425345687712,
              21.0022993370406
            ],
            [
              105.8343496208153,
              21.002322866222606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.85_Phương Mai",
          "maTaiSan": "04.O17.DODV.8443",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002263530388262,
          "to_latitude": 21.002322866222606,
          "to_longitude": 105.8343496208153,
          "from_longitude": 105.83403819182746
        }
      },
      {
        "id": 8436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83738255451978,
            21.002830247430587,
            105.8374009834801,
            21.002834355861122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374009834801,
              21.002834355861122
            ],
            [
              105.83740053180848,
              21.002834255115534
            ],
            [
              105.83739055940488,
              21.00283203166155
            ],
            [
              105.83738255451978,
              21.002830247430587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,56_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8444",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002834355861122,
          "to_latitude": 21.002830247430587,
          "to_longitude": 105.83738255451978,
          "from_longitude": 105.8374009834801
        }
      },
      {
        "id": 8437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555112656612,
            21.00325141981834,
            105.83555670573924,
            21.003276919074086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83555670573924,
              21.00325141981834
            ],
            [
              105.8355531160271,
              21.00326782285744
            ],
            [
              105.83555112656612,
              21.003276919074086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,71_Phương Mai",
          "maTaiSan": "04.O17.DODV.8445",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00325141981834,
          "to_latitude": 21.003276919074086,
          "to_longitude": 105.83555112656612,
          "from_longitude": 105.83555670573924
        }
      },
      {
        "id": 8438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758756476651,
            21.001680674672944,
            105.83769526563853,
            21.00181248801156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758756476651,
              21.001680674672944
            ],
            [
              105.83761317398024,
              21.00169205936403
            ],
            [
              105.83762630045685,
              21.001772236421242
            ],
            [
              105.83764423749543,
              21.00181248801156
            ],
            [
              105.83769526563853,
              21.00179942735263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,115_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8446",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001680674672944,
          "to_latitude": 21.00179942735263,
          "to_longitude": 105.83769526563853,
          "from_longitude": 105.83758756476651
        }
      },
      {
        "id": 8439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798688206414,
            21.003146623470595,
            105.8380036760011,
            21.003196424187657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380036760011,
              21.003146623470595
            ],
            [
              105.83798688206414,
              21.003196424187657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,51_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8447",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003146623470595,
          "to_latitude": 21.003196424187657,
          "to_longitude": 105.83798688206414,
          "from_longitude": 105.8380036760011
        }
      },
      {
        "id": 8440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971716147543,
            20.99916708148002,
            105.83987429292566,
            20.999170718591085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971716147543,
              20.99916708148002
            ],
            [
              105.83987429292566,
              20.999170718591085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,157_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8448",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99916708148002,
          "to_latitude": 20.999170718591085,
          "to_longitude": 105.83987429292566,
          "from_longitude": 105.83971716147543
        }
      },
      {
        "id": 8441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83703389047758,
            21.002630237791774,
            105.83743268153886,
            21.002726981635476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743268153886,
              21.002726981635476
            ],
            [
              105.837421817129,
              21.002724346184753
            ],
            [
              105.83731450990501,
              21.00269831473976
            ],
            [
              105.83724785176298,
              21.002682143411473
            ],
            [
              105.83718125046278,
              21.002665986228664
            ],
            [
              105.83706772807237,
              21.00263844700103
            ],
            [
              105.83703389047758,
              21.002630237791774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,59_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8449",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002726981635476,
          "to_latitude": 21.002630237791774,
          "to_longitude": 105.83703389047758,
          "from_longitude": 105.83743268153886
        }
      },
      {
        "id": 8442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786884054203,
            21.00364147411986,
            105.83787016655882,
            21.00364550632232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83787016655882,
              21.00364147411986
            ],
            [
              105.83786993767269,
              21.003642169826808
            ],
            [
              105.83786889443877,
              21.00364533986357
            ],
            [
              105.83786884054203,
              21.00364550632232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,43_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8450",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00364147411986,
          "to_latitude": 21.00364550632232,
          "to_longitude": 105.83786884054203,
          "from_longitude": 105.83787016655882
        }
      },
      {
        "id": 8443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438474997153,
            21.00203024716617,
            105.83439182189275,
            21.002083814231657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439182189275,
              21.00203024716617
            ],
            [
              105.83438474997153,
              21.002083814231657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8451",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00203024716617,
          "to_latitude": 21.002083814231657,
          "to_longitude": 105.83438474997153,
          "from_longitude": 105.83439182189275
        }
      },
      {
        "id": 8444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702244036225,
            21.003629822123234,
            105.83702781395571,
            21.00364488363886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702781395571,
              21.003629822123234
            ],
            [
              105.83702379936649,
              21.003641070763884
            ],
            [
              105.83702244036225,
              21.00364488363886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,40_Phương Mai",
          "maTaiSan": "04.O17.DODV.8452",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003629822123234,
          "to_latitude": 21.00364488363886,
          "to_longitude": 105.83702244036225,
          "from_longitude": 105.83702781395571
        }
      },
      {
        "id": 8445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83918832920752,
            20.998586230798935,
            105.83922422962554,
            20.99890819734119
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918832920752,
              20.998586230798935
            ],
            [
              105.83920385576077,
              20.998723328027776
            ],
            [
              105.83920558068674,
              20.998735610617764
            ],
            [
              105.83921498776145,
              20.998802614288795
            ],
            [
              105.839215962825,
              20.99881375709153
            ],
            [
              105.83922422962554,
              20.99890819734119
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,168_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8453",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998586230798935,
          "to_latitude": 20.99890819734119,
          "to_longitude": 105.83922422962554,
          "from_longitude": 105.83918832920752
        }
      },
      {
        "id": 8446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83539066256064,
            21.002687054871355,
            105.8353933364113,
            21.002830128969215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353933364113,
              21.002830128969215
            ],
            [
              105.83539269535562,
              21.002795828503967
            ],
            [
              105.83539073841416,
              21.002691178627995
            ],
            [
              105.83539066256064,
              21.002687054871355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6,1_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8454",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002830128969215,
          "to_latitude": 21.002687054871355,
          "to_longitude": 105.83539066256064,
          "from_longitude": 105.8353933364113
        }
      },
      {
        "id": 8447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589648460098,
            21.00278177466916,
            105.8358969085559,
            21.002880433382607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589648460098,
              21.002880433382607
            ],
            [
              105.83589651830275,
              21.002879231023734
            ],
            [
              105.8358969085559,
              21.00278177466916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,2_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8455",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002880433382607,
          "to_latitude": 21.00278177466916,
          "to_longitude": 105.8358969085559,
          "from_longitude": 105.83589648460098
        }
      },
      {
        "id": 8448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893801297539,
            20.99985713768849,
            105.83906506671575,
            20.999857687495517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893801297539,
              20.99985713768849
            ],
            [
              105.8390625755013,
              20.999857676290677
            ],
            [
              105.83906506671575,
              20.999857687495517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,151_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8456",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99985713768849,
          "to_latitude": 20.999857687495517,
          "to_longitude": 105.83906506671575,
          "from_longitude": 105.83893801297539
        }
      },
      {
        "id": 8449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971461607348,
            20.99852577868652,
            105.83974562151056,
            20.998525781104167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971461607348,
              20.99852577868652
            ],
            [
              105.8397184191347,
              20.998525778895612
            ],
            [
              105.83971986860698,
              20.99852577897186
            ],
            [
              105.83974562151056,
              20.998525781104167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,166_Trường Chinh",
          "maTaiSan": "04.O17.DODV.8457",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99852577868652,
          "to_latitude": 20.998525781104167,
          "to_longitude": 105.83974562151056,
          "from_longitude": 105.83971461607348
        }
      },
      {
        "id": 8450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370068906095,
            21.001539006209985,
            105.8370160474394,
            21.001735688695184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83700754591645,
              21.001539006209985
            ],
            [
              105.83700750793717,
              21.001545822137274
            ],
            [
              105.83700723043235,
              21.001596619104248
            ],
            [
              105.83700718342047,
              21.001605265011012
            ],
            [
              105.8370068906095,
              21.001658491729795
            ],
            [
              105.8370136305444,
              21.001673720391256
            ],
            [
              105.83701361491902,
              21.001674195564764
            ],
            [
              105.83701349541462,
              21.00167794815157
            ],
            [
              105.83701334314877,
              21.00168272515731
            ],
            [
              105.83701319088792,
              21.001687503066254
            ],
            [
              105.83701307138857,
              21.00169125655624
            ],
            [
              105.8370129181609,
              21.00169603356672
            ],
            [
              105.8370127659051,
              21.00170081237884
            ],
            [
              105.8370126136392,
              21.001705589384564
            ],
            [
              105.8370124931831,
              21.00170934378249
            ],
            [
              105.83701176247993,
              21.00173216646719
            ],
            [
              105.8370116503476,
              21.001735688695184
            ],
            [
              105.8370160474394,
              21.001735603847838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,9_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8458",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001539006209985,
          "to_latitude": 21.001735603847838,
          "to_longitude": 105.8370160474394,
          "from_longitude": 105.83700754591645
        }
      },
      {
        "id": 8451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453295650837,
            21.00253688195448,
            105.83456647004948,
            21.00254513299644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453295650837,
              21.00253688195448
            ],
            [
              105.83453661561433,
              21.00253778620821
            ],
            [
              105.83453718196172,
              21.002537926141198
            ],
            [
              105.83455012504439,
              21.00254111068328
            ],
            [
              105.83455306849399,
              21.002541835090575
            ],
            [
              105.83455365506991,
              21.002541980343683
            ],
            [
              105.83456647004948,
              21.00254513299644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,80_Phương Mai",
          "maTaiSan": "04.O17.DODV.8459",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00253688195448,
          "to_latitude": 21.00254513299644,
          "to_longitude": 105.83456647004948,
          "from_longitude": 105.83453295650837
        }
      },
      {
        "id": 8452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696629873405,
            21.00195967138393,
            105.83716998258109,
            21.001990871544514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696629873405,
              21.00195967138393
            ],
            [
              105.8369873000318,
              21.001961622054093
            ],
            [
              105.83711366097249,
              21.001973352283485
            ],
            [
              105.83716998258109,
              21.001990871544514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,108_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8460",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00195967138393,
          "to_latitude": 21.001990871544514,
          "to_longitude": 105.83716998258109,
          "from_longitude": 105.83696629873405
        }
      },
      {
        "id": 8453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718927925054,
            21.003203224029136,
            105.83734140281842,
            21.00370918017513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83729689240317,
              21.00370918017513
            ],
            [
              105.83729747906386,
              21.003706925548393
            ],
            [
              105.83730485536454,
              21.003659475350567
            ],
            [
              105.83734140281842,
              21.003397184977903
            ],
            [
              105.83720093103409,
              21.003360081405063
            ],
            [
              105.83721451178988,
              21.003312368562344
            ],
            [
              105.83719519267433,
              21.003279738826812
            ],
            [
              105.83718927925054,
              21.003269750239443
            ],
            [
              105.83721205910093,
              21.003203224029136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,39_Phương Mai",
          "maTaiSan": "04.O17.DODV.8461",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00370918017513,
          "to_latitude": 21.003203224029136,
          "to_longitude": 105.83721205910093,
          "from_longitude": 105.83729689240317
        }
      },
      {
        "id": 8454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83566865885629,
            21.00279496592898,
            105.83566938267816,
            21.002887454698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566938267816,
              21.002887454698
            ],
            [
              105.83566865885629,
              21.002808930904465
            ],
            [
              105.83566888408913,
              21.00279496592898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,5_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8462",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002887454698,
          "to_latitude": 21.00279496592898,
          "to_longitude": 105.83566888408913,
          "from_longitude": 105.83566938267816
        }
      },
      {
        "id": 8455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520291149176,
            21.00154714388986,
            105.83538976962295,
            21.001644011347484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83520291149176,
              21.001621166217962
            ],
            [
              105.83521732167229,
              21.00154714388986
            ],
            [
              105.83526472297913,
              21.00155433220571
            ],
            [
              105.83533613202395,
              21.001565159212003
            ],
            [
              105.8353235458803,
              21.00163388059675
            ],
            [
              105.83538976962295,
              21.001644011347484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,6_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8463",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001621166217962,
          "to_latitude": 21.001644011347484,
          "to_longitude": 105.83538976962295,
          "from_longitude": 105.83520291149176
        }
      },
      {
        "id": 8456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915108405652,
            20.99966935828615,
            105.83927541863142,
            20.999814163451646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915108405652,
              20.99966935828615
            ],
            [
              105.83916057473574,
              20.99967215937231
            ],
            [
              105.83917008564862,
              20.999674966680736
            ],
            [
              105.83927541863142,
              20.99970605487762
            ],
            [
              105.83924048325784,
              20.999814163451646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,150_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8464",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99966935828615,
          "to_latitude": 20.999814163451646,
          "to_longitude": 105.83924048325784,
          "from_longitude": 105.83915108405652
        }
      },
      {
        "id": 8457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602466363902,
            21.0011423515938,
            105.83605855327782,
            21.001143741405706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602466363902,
              21.0011423515938
            ],
            [
              105.83603053992235,
              21.001142592797923
            ],
            [
              105.8360349250067,
              21.00114277268053
            ],
            [
              105.83603792279364,
              21.001142895247575
            ],
            [
              105.83605855327782,
              21.001143741405706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1,2_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8465",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0011423515938,
          "to_latitude": 21.001143741405706,
          "to_longitude": 105.83605855327782,
          "from_longitude": 105.83602466363902
        }
      },
      {
        "id": 8458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378427650713,
            21.00194978089198,
            105.83810446121443,
            21.00203501137448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378437484527,
              21.00194978089198
            ],
            [
              105.8378427650713,
              21.00203501137448
            ],
            [
              105.83809334257984,
              21.0020297602582
            ],
            [
              105.83810446121443,
              21.002029527578266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,62_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8466",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00194978089198,
          "to_latitude": 21.002029527578266,
          "to_longitude": 105.83810446121443,
          "from_longitude": 105.8378437484527
        }
      },
      {
        "id": 8459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753558380869,
            21.00126369714678,
            105.83755000297778,
            21.001502570500197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754164121558,
              21.001502570500197
            ],
            [
              105.83754221441497,
              21.0014768745263
            ],
            [
              105.83754765541514,
              21.00147115743396
            ],
            [
              105.83755000297778,
              21.001468690012935
            ],
            [
              105.83754248553825,
              21.001455995133945
            ],
            [
              105.83753583951618,
              21.001375032889914
            ],
            [
              105.83753572244375,
              21.001324227931857
            ],
            [
              105.83753558380869,
              21.00126369714678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,117_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8467",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001502570500197,
          "to_latitude": 21.00126369714678,
          "to_longitude": 105.83753558380869,
          "from_longitude": 105.83754164121558
        }
      },
      {
        "id": 8460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356783131994,
            21.00057876942914,
            105.83568121818678,
            21.000640063054735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356783131994,
              21.000640063054735
            ],
            [
              105.83568121818678,
              21.00057876942914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,101_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8468",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000640063054735,
          "to_latitude": 21.00057876942914,
          "to_longitude": 105.83568121818678,
          "from_longitude": 105.8356783131994
        }
      },
      {
        "id": 8461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538143720321,
            21.00185538361319,
            105.83539818486882,
            21.001939037682614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538143720321,
              21.001939037682614
            ],
            [
              105.8353855529806,
              21.001918476321045
            ],
            [
              105.83539818486882,
              21.00185538361319
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2C,1_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8469",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001939037682614,
          "to_latitude": 21.00185538361319,
          "to_longitude": 105.83539818486882,
          "from_longitude": 105.83538143720321
        }
      },
      {
        "id": 8462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509000744729,
            21.001593241814945,
            105.83519923394,
            21.001626425960623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83519923394,
              21.00160812722814
            ],
            [
              105.83519856397528,
              21.001608033870173
            ],
            [
              105.83509733412468,
              21.001593802527502
            ],
            [
              105.83509379718105,
              21.001593241814945
            ],
            [
              105.83509181251144,
              21.001602163682026
            ],
            [
              105.83509544613185,
              21.001602639920307
            ],
            [
              105.83509000744729,
              21.001626425960623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,8_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8470",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00160812722814,
          "to_latitude": 21.001626425960623,
          "to_longitude": 105.83509000744729,
          "from_longitude": 105.83519923394
        }
      },
      {
        "id": 8463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83492246313675,
            21.002887427074867,
            105.83493935109264,
            21.00293572230297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83492246313675,
              21.00293572230297
            ],
            [
              105.834924444715,
              21.002929871421465
            ],
            [
              105.83493578818948,
              21.002896383778
            ],
            [
              105.83493935109264,
              21.002887427074867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D7,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8471",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00293572230297,
          "to_latitude": 21.002887427074867,
          "to_longitude": 105.83493935109264,
          "from_longitude": 105.83492246313675
        }
      },
      {
        "id": 8464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661587815288,
            21.00146193398802,
            105.83661637704928,
            21.001542062494618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661587815288,
              21.001542062494618
            ],
            [
              105.83661587934695,
              21.001541759004922
            ],
            [
              105.83661604938291,
              21.001514627075895
            ],
            [
              105.83661637704928,
              21.00146193398802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2,5_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8472",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001542062494618,
          "to_latitude": 21.00146193398802,
          "to_longitude": 105.83661637704928,
          "from_longitude": 105.83661587815288
        }
      },
      {
        "id": 8465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551239514782,
            21.000907981284225,
            105.83557992757143,
            21.00090997741704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557992757143,
              21.00090997741704
            ],
            [
              105.83551239514782,
              21.000907981284225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,100_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8473",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00090997741704,
          "to_latitude": 21.000907981284225,
          "to_longitude": 105.83551239514782,
          "from_longitude": 105.83557992757143
        }
      },
      {
        "id": 8466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778411358703,
            21.00314998828961,
            105.837786554835,
            21.003157387785443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837786554835,
              21.00314998828961
            ],
            [
              105.83778584072299,
              21.003152154125907
            ],
            [
              105.83778413249593,
              21.003157328982738
            ],
            [
              105.83778411358703,
              21.003157387785443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,51_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8474",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00314998828961,
          "to_latitude": 21.003157387785443,
          "to_longitude": 105.83778411358703,
          "from_longitude": 105.837786554835
        }
      },
      {
        "id": 8467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83978585856916,
            20.99908334118094,
            105.83979123005912,
            20.99915166055079
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979123005912,
              20.99908334118094
            ],
            [
              105.8397879597675,
              20.999124938197742
            ],
            [
              105.839786216834,
              20.999147101108132
            ],
            [
              105.83978585856916,
              20.99915166055079
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,156_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8475",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99908334118094,
          "to_latitude": 20.99915166055079,
          "to_longitude": 105.83978585856916,
          "from_longitude": 105.83979123005912
        }
      },
      {
        "id": 8468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380623890102,
            21.00064597073531,
            105.83811228914188,
            21.000680950254303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380623890102,
              21.00064597073531
            ],
            [
              105.83806361154303,
              21.000680950254303
            ],
            [
              105.83811228914188,
              21.00068023098451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,124_Ngách 102/62, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8476",
          "diaChiLapD": "Ngách 102/62, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00064597073531,
          "to_latitude": 21.00068023098451,
          "to_longitude": 105.83811228914188,
          "from_longitude": 105.8380623890102
        }
      },
      {
        "id": 8469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661243262932,
            21.001461953374225,
            105.83661251649247,
            21.001542233468307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661251649247,
              21.001542233468307
            ],
            [
              105.83661251494169,
              21.001542127798526
            ],
            [
              105.83661248705053,
              21.001514688842292
            ],
            [
              105.83661243262932,
              21.001461953374225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2,4_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8477",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001542233468307,
          "to_latitude": 21.001461953374225,
          "to_longitude": 105.83661243262932,
          "from_longitude": 105.83661251649247
        }
      },
      {
        "id": 8470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535015003126,
            21.00150078709362,
            105.83536371036071,
            21.001559811967898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535015003126,
              21.001559811967898
            ],
            [
              105.83535242317025,
              21.001551396295547
            ],
            [
              105.83535361656524,
              21.00154668372882
            ],
            [
              105.83536371036071,
              21.001506791995688
            ],
            [
              105.83535822556797,
              21.001505692589525
            ],
            [
              105.83536008156244,
              21.00150078709362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,4_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8478",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001559811967898,
          "to_latitude": 21.00150078709362,
          "to_longitude": 105.83536008156244,
          "from_longitude": 105.83535015003126
        }
      },
      {
        "id": 8471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83951095954383,
            20.99858847972466,
            105.8395498675231,
            20.99858885507832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395498675231,
              20.99858885507832
            ],
            [
              105.83954950874644,
              20.998588852331505
            ],
            [
              105.83953619634443,
              20.998588722884943
            ],
            [
              105.83951095954383,
              20.99858847972466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,6_Trường Chinh",
          "maTaiSan": "04.O17.DODV.8479",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99858885507832,
          "to_latitude": 20.99858847972466,
          "to_longitude": 105.83951095954383,
          "from_longitude": 105.8395498675231
        }
      },
      {
        "id": 8472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489106542596,
            21.00309592491164,
            105.83489775737537,
            21.003121978768785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489106542596,
              21.003121978768785
            ],
            [
              105.83489556655401,
              21.003104449464388
            ],
            [
              105.8348974005538,
              21.003097313113518
            ],
            [
              105.83489775737537,
              21.00309592491164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,74_Phương Mai",
          "maTaiSan": "04.O17.DODV.8480",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003121978768785,
          "to_latitude": 21.00309592491164,
          "to_longitude": 105.83489775737537,
          "from_longitude": 105.83489106542596
        }
      },
      {
        "id": 8473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876259516919,
            20.99957558527333,
            105.83876367029342,
            20.99960419843255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83876367029342,
              20.99957558527333
            ],
            [
              105.8387632242778,
              20.99958871766033
            ],
            [
              105.83876282715441,
              20.999600271871415
            ],
            [
              105.83876259516919,
              20.99960419843255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9A,1_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8481",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99957558527333,
          "to_latitude": 20.99960419843255,
          "to_longitude": 105.83876259516919,
          "from_longitude": 105.83876367029342
        }
      },
      {
        "id": 8474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857617386273,
            20.99956911337532,
            105.83857655183665,
            20.99957702918864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83857617386273,
              20.99956911337532
            ],
            [
              105.8385764555967,
              20.999574780631093
            ],
            [
              105.83857655183665,
              20.99957702918864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,145_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8482",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99956911337532,
          "to_latitude": 20.99957702918864,
          "to_longitude": 105.83857655183665,
          "from_longitude": 105.83857617386273
        }
      },
      {
        "id": 8475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601642365797,
            21.002096859785322,
            105.83603993600339,
            21.0020972173312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603993600339,
              21.002096859785322
            ],
            [
              105.83603770662282,
              21.002096893315763
            ],
            [
              105.83603412211873,
              21.002096948856774
            ],
            [
              105.83603053664282,
              21.002097002596024
            ],
            [
              105.83601642365797,
              21.0020972173312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2,1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8483",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002096859785322,
          "to_latitude": 21.0020972173312,
          "to_longitude": 105.83601642365797,
          "from_longitude": 105.83603993600339
        }
      },
      {
        "id": 8476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83734089387968,
            21.003701982685094,
            105.83778988550803,
            21.003808196421065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734089387968,
              21.003701982685094
            ],
            [
              105.83755223469004,
              21.00375197779378
            ],
            [
              105.837558243669,
              21.003753399707787
            ],
            [
              105.83778988550803,
              21.003808196421065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,37_Phương Mai",
          "maTaiSan": "04.O17.DODV.8484",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003701982685094,
          "to_latitude": 21.003808196421065,
          "to_longitude": 105.83778988550803,
          "from_longitude": 105.83734089387968
        }
      },
      {
        "id": 8477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380662767432,
            21.003227635335843,
            105.83826207009635,
            21.003266517877538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380662767432,
              21.003227635335843
            ],
            [
              105.83826207009635,
              21.003266517877538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,49_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8485",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003227635335843,
          "to_latitude": 21.003266517877538,
          "to_longitude": 105.83826207009635,
          "from_longitude": 105.8380662767432
        }
      },
      {
        "id": 8478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83739594814173,
            21.00285107314257,
            105.83740731322428,
            21.00285362574439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83739594814173,
              21.00285107314257
            ],
            [
              105.83739663480682,
              21.00285122782882
            ],
            [
              105.83740134514828,
              21.002852287622538
            ],
            [
              105.83740163695501,
              21.002852353025613
            ],
            [
              105.83740731322428,
              21.00285362574439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,55_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8486",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00285107314257,
          "to_latitude": 21.00285362574439,
          "to_longitude": 105.83740731322428,
          "from_longitude": 105.83739594814173
        }
      },
      {
        "id": 8479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83567922382105,
            21.003078423263275,
            105.83567975477806,
            21.00313370710094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567922382105,
              21.003078423263275
            ],
            [
              105.83567937387612,
              21.003094144976178
            ],
            [
              105.83567975477806,
              21.00313370710094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4A,3_Ngõ 28A, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8487",
          "diaChiLapD": "Ngõ 28A, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003078423263275,
          "to_latitude": 21.00313370710094,
          "to_longitude": 105.83567975477806,
          "from_longitude": 105.83567922382105
        }
      },
      {
        "id": 8480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670647034965,
            21.001513830109285,
            105.83716048513114,
            21.001520850957743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716048513114,
              21.00151744108582
            ],
            [
              105.83693837742456,
              21.001520850957743
            ],
            [
              105.83693613724147,
              21.001520670488347
            ],
            [
              105.83693259968366,
              21.001515342592235
            ],
            [
              105.83693159574038,
              21.001513830109285
            ],
            [
              105.83692289354279,
              21.001520662442445
            ],
            [
              105.83670647034965,
              21.001519072708906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,111_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8488",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00151744108582,
          "to_latitude": 21.001519072708906,
          "to_longitude": 105.83670647034965,
          "from_longitude": 105.83716048513114
        }
      },
      {
        "id": 8481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805188495279,
            21.00290503656779,
            105.83806648382198,
            21.002954160806475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83805188495279,
              21.002954160806475
            ],
            [
              105.83806648382198,
              21.00290503656779
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,60_Ngách 15/34, Phương Mai",
          "maTaiSan": "04.O17.DODV.8489",
          "diaChiLapD": "Ngách 15/34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002954160806475,
          "to_latitude": 21.00290503656779,
          "to_longitude": 105.83806648382198,
          "from_longitude": 105.83805188495279
        }
      },
      {
        "id": 8482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590308995001,
            21.002795385211027,
            105.83590429925476,
            21.002880313331417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590308995001,
              21.002880313331417
            ],
            [
              105.83590309231845,
              21.002880047771427
            ],
            [
              105.83590429925476,
              21.002795385211027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,1_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8490",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002880313331417,
          "to_latitude": 21.002795385211027,
          "to_longitude": 105.83590429925476,
          "from_longitude": 105.83590308995001
        }
      },
      {
        "id": 8483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601490099191,
            21.001995196349824,
            105.83601804363994,
            21.002193051640468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83601490099191,
              21.002193051640468
            ],
            [
              105.8360159656552,
              21.00212602796547
            ],
            [
              105.83601642365797,
              21.0020972173312
            ],
            [
              105.83601647206874,
              21.002094165996912
            ],
            [
              105.83601670274774,
              21.002079630155368
            ],
            [
              105.83601804363994,
              21.001995196349824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2,1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8491",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002193051640468,
          "to_latitude": 21.001995196349824,
          "to_longitude": 105.83601804363994,
          "from_longitude": 105.83601490099191
        }
      },
      {
        "id": 8484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83692459965363,
            21.00135824473213,
            105.83694009566383,
            21.00159549533025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83692459965363,
              21.00159549533025
            ],
            [
              105.83692469523616,
              21.001594524795962
            ],
            [
              105.83693050082744,
              21.001536369165883
            ],
            [
              105.83693259968366,
              21.001515342592235
            ],
            [
              105.83693349518065,
              21.001506375481313
            ],
            [
              105.83694009566383,
              21.00135824473213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,110_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8492",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00159549533025,
          "to_latitude": 21.00135824473213,
          "to_longitude": 105.83694009566383,
          "from_longitude": 105.83692459965363
        }
      },
      {
        "id": 8485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764918417504,
            21.003140454320523,
            105.83793154186682,
            21.003209382430896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793154186682,
              21.003209382430896
            ],
            [
              105.83777829392604,
              21.003169028294703
            ],
            [
              105.83764918417504,
              21.003140454320523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,52_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8493",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003209382430896,
          "to_latitude": 21.003140454320523,
          "to_longitude": 105.83764918417504,
          "from_longitude": 105.83793154186682
        }
      },
      {
        "id": 8486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375078679107,
            21.00306867671602,
            105.83806013490789,
            21.003212063263074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375078679107,
              21.00306867671602
            ],
            [
              105.8375435745963,
              21.003086208754507
            ],
            [
              105.837786554835,
              21.00314998828961
            ],
            [
              105.83786141131284,
              21.00316963710488
            ],
            [
              105.83798688206414,
              21.003196424187657
            ],
            [
              105.83806013490789,
              21.003212063263074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,51_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8494",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00306867671602,
          "to_latitude": 21.003212063263074,
          "to_longitude": 105.83806013490789,
          "from_longitude": 105.8375078679107
        }
      },
      {
        "id": 8487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773084665484,
            21.00108386481378,
            105.83774394090014,
            21.00133002660029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773084665484,
              21.00133002660029
            ],
            [
              105.83774394090014,
              21.00108386481378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,118_Ngách 102/72, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8495",
          "diaChiLapD": "Ngách 102/72, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00133002660029,
          "to_latitude": 21.00108386481378,
          "to_longitude": 105.83774394090014,
          "from_longitude": 105.83773084665484
        }
      },
      {
        "id": 8488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375314032626,
            21.001081588602105,
            105.83753558380869,
            21.00126369714678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375314032626,
              21.001081588602105
            ],
            [
              105.83753558380869,
              21.00126369714678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,117_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8496",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001081588602105,
          "to_latitude": 21.00126369714678,
          "to_longitude": 105.83753558380869,
          "from_longitude": 105.8375314032626
        }
      },
      {
        "id": 8489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910546032583,
            20.999794263515664,
            105.83943777261446,
            21.00027485403042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83910546032583,
              20.999794263515664
            ],
            [
              105.8391055152549,
              20.999794282212644
            ],
            [
              105.83931378458536,
              20.999859911720034
            ],
            [
              105.83924375050424,
              21.000054492914455
            ],
            [
              105.83923294001069,
              21.000084526067305
            ],
            [
              105.83927839901938,
              21.000119060772136
            ],
            [
              105.83943777261446,
              21.000174311047953
            ],
            [
              105.8393929871032,
              21.00027485403042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,152_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8497",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999794263515664,
          "to_latitude": 21.00027485403042,
          "to_longitude": 105.8393929871032,
          "from_longitude": 105.83910546032583
        }
      },
      {
        "id": 8490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758341539622,
            21.003581187935982,
            105.83825332625594,
            21.003762241899405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825332625594,
              21.003762241899405
            ],
            [
              105.83806704204264,
              21.003711108168275
            ],
            [
              105.83791759943081,
              21.003670890793597
            ],
            [
              105.83786704593528,
              21.00365657334331
            ],
            [
              105.83772497115359,
              21.003616335785033
            ],
            [
              105.83758341539622,
              21.003581187935982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,42_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8498",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003762241899405,
          "to_latitude": 21.003581187935982,
          "to_longitude": 105.83758341539622,
          "from_longitude": 105.83825332625594
        }
      },
      {
        "id": 8491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373321512071,
            21.0027423723768,
            105.83740757454267,
            21.003007275571527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373321512071,
              21.003007275571527
            ],
            [
              105.83734831681447,
              21.002950499683813
            ],
            [
              105.83737300949497,
              21.002863768825687
            ],
            [
              105.83738255451978,
              21.002830247430587
            ],
            [
              105.83738378561854,
              21.002825923956518
            ],
            [
              105.83740757454267,
              21.0027423723768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,56_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8499",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003007275571527,
          "to_latitude": 21.0027423723768,
          "to_longitude": 105.83740757454267,
          "from_longitude": 105.8373321512071
        }
      },
      {
        "id": 8492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84043810549966,
            20.99869777928493,
            105.84043834655505,
            20.99872709407921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84043834655505,
              20.99872709407921
            ],
            [
              105.84043831909347,
              20.998723906732618
            ],
            [
              105.84043810549966,
              20.99869777928493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1A,2_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8500",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99872709407921,
          "to_latitude": 20.99869777928493,
          "to_longitude": 105.84043810549966,
          "from_longitude": 105.84043834655505
        }
      },
      {
        "id": 8493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361199378998,
            21.000759789938858,
            105.83612183249922,
            21.000833220667925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361199378998,
              21.000833220667925
            ],
            [
              105.83612004131412,
              21.00082916557914
            ],
            [
              105.83612183249922,
              21.000759789938858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I5,1_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8501",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000833220667925,
          "to_latitude": 21.000759789938858,
          "to_longitude": 105.83612183249922,
          "from_longitude": 105.8361199378998
        }
      },
      {
        "id": 8494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83531366529418,
            21.002670418213565,
            105.83552400510372,
            21.00279768048783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353148653715,
              21.002670418213565
            ],
            [
              105.83531404055807,
              21.0027582998084
            ],
            [
              105.83531396881607,
              21.002765457321598
            ],
            [
              105.83531388126983,
              21.00277478717039
            ],
            [
              105.83531366529418,
              21.00279768048783
            ],
            [
              105.83537935425619,
              21.00279614146435
            ],
            [
              105.83538558624758,
              21.00279599345926
            ],
            [
              105.83538574975329,
              21.002795991753572
            ],
            [
              105.83538838200484,
              21.00279593006059
            ],
            [
              105.83539134412814,
              21.002795860426012
            ],
            [
              105.83539269535562,
              21.002795828503967
            ],
            [
              105.83551727673819,
              21.002792910184372
            ],
            [
              105.83552250645309,
              21.00271033690538
            ],
            [
              105.83552341005746,
              21.002696071438386
            ],
            [
              105.83552400245456,
              21.002686721950337
            ],
            [
              105.83552400510372,
              21.002686679485727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,92_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8502",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002670418213565,
          "to_latitude": 21.002686679485727,
          "to_longitude": 105.83552400510372,
          "from_longitude": 105.8353148653715
        }
      },
      {
        "id": 8495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704811971494,
            21.002022932092757,
            105.83769640910174,
            21.002341798483325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769640910174,
              21.002022932092757
            ],
            [
              105.83769284109786,
              21.002024737135148
            ],
            [
              105.83749879125783,
              21.0021228754488
            ],
            [
              105.83715081471253,
              21.002341798483325
            ],
            [
              105.83708686350222,
              21.00232995828791
            ],
            [
              105.83704811971494,
              21.002300731664675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,65_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8503",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002022932092757,
          "to_latitude": 21.002300731664675,
          "to_longitude": 105.83704811971494,
          "from_longitude": 105.83769640910174
        }
      },
      {
        "id": 8496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83577467392784,
            21.00267393272255,
            105.83577503515993,
            21.002686120883133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577503515993,
              21.002686120883133
            ],
            [
              105.83577500360815,
              21.002685118457507
            ],
            [
              105.83577495939534,
              21.002683569642507
            ],
            [
              105.83577486701184,
              21.002680452160945
            ],
            [
              105.83577467392784,
              21.00267393272255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,90_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8504",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002686120883133,
          "to_latitude": 21.00267393272255,
          "to_longitude": 105.83577467392784,
          "from_longitude": 105.83577503515993
        }
      },
      {
        "id": 8497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362725874372,
            21.00151343149798,
            105.83668453479214,
            21.001520618668966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362725874372,
              21.001520618668966
            ],
            [
              105.83638113946411,
              21.001518725076853
            ],
            [
              105.83638405453767,
              21.00151867462463
            ],
            [
              105.83639130614829,
              21.001518547767862
            ],
            [
              105.83640011002983,
              21.00151839431584
            ],
            [
              105.83647856208613,
              21.00151702520321
            ],
            [
              105.83649221704735,
              21.001516787391754
            ],
            [
              105.83659986212817,
              21.00151490895584
            ],
            [
              105.83660866793247,
              21.001514755483573
            ],
            [
              105.83661248705053,
              21.001514688842292
            ],
            [
              105.83661604938291,
              21.001514627075895
            ],
            [
              105.83668453479214,
              21.00151343149798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2,3_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8505",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001520618668966,
          "to_latitude": 21.00151343149798,
          "to_longitude": 105.83668453479214,
          "from_longitude": 105.8362725874372
        }
      },
      {
        "id": 8498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84048072356394,
            20.998989635680964,
            105.84049282559425,
            20.998989925752642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84049282559425,
              20.998989635680964
            ],
            [
              105.84049203509393,
              20.9989896576483
            ],
            [
              105.84048618699127,
              20.99898979671466
            ],
            [
              105.84048072356394,
              20.998989925752642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1B,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8506",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998989635680964,
          "to_latitude": 20.998989925752642,
          "to_longitude": 105.84048072356394,
          "from_longitude": 105.84049282559425
        }
      },
      {
        "id": 8499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608795224161,
            21.00230813980835,
            105.83614581797822,
            21.00230868959798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608795224161,
              21.00230868959798
            ],
            [
              105.83609960447517,
              21.002308583588643
            ],
            [
              105.83611032084326,
              21.002308480369074
            ],
            [
              105.83614581797822,
              21.00230813980835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8507",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00230868959798,
          "to_latitude": 21.00230813980835,
          "to_longitude": 105.83614581797822,
          "from_longitude": 105.83608795224161
        }
      },
      {
        "id": 8500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763126548814,
            21.003578692592054,
            105.83815920103994,
            21.00371702662392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815920103994,
              21.00371702662392
            ],
            [
              105.8380560371336,
              21.003691421463603
            ],
            [
              105.83805442542473,
              21.003686663982954
            ],
            [
              105.83805434815896,
              21.003686434944182
            ],
            [
              105.8380497931871,
              21.00368977853071
            ],
            [
              105.83787016655882,
              21.00364147411986
            ],
            [
              105.83784363424267,
              21.00363433918276
            ],
            [
              105.83763126548814,
              21.003578692592054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,43_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8508",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00371702662392,
          "to_latitude": 21.003578692592054,
          "to_longitude": 105.83763126548814,
          "from_longitude": 105.83815920103994
        }
      },
      {
        "id": 8501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793813988935,
            21.003214150591674,
            105.83825513164388,
            21.003297241835174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793813988935,
              21.003214150591674
            ],
            [
              105.83794120114838,
              21.00321495294009
            ],
            [
              105.83805825823399,
              21.003245638160582
            ],
            [
              105.83817294571126,
              21.00327569909729
            ],
            [
              105.83825513164388,
              21.003297241835174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,50_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8509",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003214150591674,
          "to_latitude": 21.003297241835174,
          "to_longitude": 105.83825513164388,
          "from_longitude": 105.83793813988935
        }
      },
      {
        "id": 8502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771466275226,
            21.002822707973422,
            105.83827838358675,
            21.00298656145242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827838358675,
              21.00298656145242
            ],
            [
              105.8382638068063,
              21.002980384728826
            ],
            [
              105.83826245316583,
              21.002979812427863
            ],
            [
              105.83810747319043,
              21.002914156030528
            ],
            [
              105.83806648382198,
              21.00290503656779
            ],
            [
              105.83797372548008,
              21.002883330812637
            ],
            [
              105.83784697700861,
              21.002853671421853
            ],
            [
              105.83772468970724,
              21.00282505397846
            ],
            [
              105.83771466275226,
              21.002822707973422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,60_Ngách 15/34, Phương Mai",
          "maTaiSan": "04.O17.DODV.8510",
          "diaChiLapD": "Ngách 15/34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00298656145242,
          "to_latitude": 21.002822707973422,
          "to_longitude": 105.83771466275226,
          "from_longitude": 105.83827838358675
        }
      },
      {
        "id": 8503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570420840623,
            21.00215816764953,
            105.83570507275199,
            21.00223960040685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83570420840623,
              21.00223960040685
            ],
            [
              105.835704210936,
              21.002239363749275
            ],
            [
              105.83570498308443,
              21.002182314927836
            ],
            [
              105.8357049949032,
              21.00217908222474
            ],
            [
              105.83570507275199,
              21.00215816764953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2A,1_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8511",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00223960040685,
          "to_latitude": 21.00215816764953,
          "to_longitude": 105.83570507275199,
          "from_longitude": 105.83570420840623
        }
      },
      {
        "id": 8504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439535098131,
            21.001900880454528,
            105.83450823765885,
            21.00206795797956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450823765885,
              21.001907283021843
            ],
            [
              105.83441253179042,
              21.001900880454528
            ],
            [
              105.83439889670821,
              21.002005751318894
            ],
            [
              105.83439873273149,
              21.00200687934878
            ],
            [
              105.83439535098131,
              21.002028528189108
            ],
            [
              105.83440791896643,
              21.00206477355772
            ],
            [
              105.83440946504768,
              21.00206795797956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D18,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8512",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001907283021843,
          "to_latitude": 21.00206795797956,
          "to_longitude": 105.83440946504768,
          "from_longitude": 105.83450823765885
        }
      },
      {
        "id": 8505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363122163657,
            21.00225047169507,
            105.83401245011933,
            21.00235680041802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83401245011933,
              21.002326250986304
            ],
            [
              105.83401195119127,
              21.00232612697924
            ],
            [
              105.8338799720121,
              21.002293113062457
            ],
            [
              105.83371495561687,
              21.00225047169507
            ],
            [
              105.83370868848667,
              21.00225052406853
            ],
            [
              105.83363122163657,
              21.00235680041802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,87_Phương Mai",
          "maTaiSan": "04.O17.DODV.8513",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002326250986304,
          "to_latitude": 21.00235680041802,
          "to_longitude": 105.83363122163657,
          "from_longitude": 105.83401245011933
        }
      },
      {
        "id": 8506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573319568825,
            21.002238309201726,
            105.8357341118838,
            21.002276913053304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8357341118838,
              21.002238309201726
            ],
            [
              105.83573386259616,
              21.002248816747844
            ],
            [
              105.83573371212613,
              21.002255132841196
            ],
            [
              105.83573363427901,
              21.00225842638456
            ],
            [
              105.83573319568825,
              21.002276913053304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,7_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8514",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002238309201726,
          "to_latitude": 21.002276913053304,
          "to_longitude": 105.83573319568825,
          "from_longitude": 105.8357341118838
        }
      },
      {
        "id": 8507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760032023994,
            21.00064597073531,
            105.8380623890102,
            21.00067473354914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83760032023994,
              21.000669950378406
            ],
            [
              105.83760038758196,
              21.00066995275672
            ],
            [
              105.83760373638135,
              21.000670066343446
            ],
            [
              105.83774263386927,
              21.00067473354914
            ],
            [
              105.838003345777,
              21.00067358668887
            ],
            [
              105.8380623890102,
              21.00064597073531
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,124_Ngách 102/62, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8515",
          "diaChiLapD": "Ngách 102/62, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000669950378406,
          "to_latitude": 21.00064597073531,
          "to_longitude": 105.8380623890102,
          "from_longitude": 105.83760032023994
        }
      },
      {
        "id": 8508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364914192717,
            21.001516787391754,
            105.83649221704735,
            21.00158195724073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83649221704735,
              21.001516787391754
            ],
            [
              105.83649212635963,
              21.001524159965683
            ],
            [
              105.83649194456595,
              21.001539002663954
            ],
            [
              105.83649190145096,
              21.001542484810944
            ],
            [
              105.83649150388003,
              21.001575047148304
            ],
            [
              105.8364914192717,
              21.00158195724073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I2,3_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8516",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001516787391754,
          "to_latitude": 21.00158195724073,
          "to_longitude": 105.8364914192717,
          "from_longitude": 105.83649221704735
        }
      },
      {
        "id": 8509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569824784179,
            21.00215796752437,
            105.83569855414194,
            21.002239431864552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569824784179,
              21.002239431864552
            ],
            [
              105.83569826075963,
              21.00223605102706
            ],
            [
              105.83569827727636,
              21.00223158901063
            ],
            [
              105.83569846500028,
              21.00218169118723
            ],
            [
              105.83569847681923,
              21.00217845848412
            ],
            [
              105.83569855414194,
              21.00215796752437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2A,2_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8517",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002239431864552,
          "to_latitude": 21.00215796752437,
          "to_longitude": 105.83569855414194,
          "from_longitude": 105.83569824784179
        }
      },
      {
        "id": 8510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602435562167,
            21.00114818252611,
            105.83605614665615,
            21.001149769613097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602435562167,
              21.00114818252611
            ],
            [
              105.83602916521977,
              21.001148423549747
            ],
            [
              105.83603426729074,
              21.0011486775884
            ],
            [
              105.83603726138139,
              21.001148827270374
            ],
            [
              105.83605614665615,
              21.001149769613097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1,1_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8518",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00114818252611,
          "to_latitude": 21.001149769613097,
          "to_longitude": 105.83605614665615,
          "from_longitude": 105.83602435562167
        }
      },
      {
        "id": 8511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83797536865764,
            21.00263461149937,
            105.838257107937,
            21.002637563339956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83797536865764,
              21.00263461149937
            ],
            [
              105.83823810157394,
              21.002637363391703
            ],
            [
              105.83824115948673,
              21.002637396203582
            ],
            [
              105.83824642112496,
              21.00263745067895
            ],
            [
              105.838257107937,
              21.002637563339956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngách 15/38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8519",
          "diaChiLapD": "Ngách 15/38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00263461149937,
          "to_latitude": 21.002637563339956,
          "to_longitude": 105.838257107937,
          "from_longitude": 105.83797536865764
        }
      },
      {
        "id": 8512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912374029116,
            20.999531960384775,
            105.83917713287633,
            20.999758355101683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917713287633,
              20.999531960384775
            ],
            [
              105.83915066915644,
              20.99964417388974
            ],
            [
              105.83914443138151,
              20.999670621272823
            ],
            [
              105.83912374029116,
              20.999758355101683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,149_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8520",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999531960384775,
          "to_latitude": 20.999758355101683,
          "to_longitude": 105.83912374029116,
          "from_longitude": 105.83917713287633
        }
      },
      {
        "id": 8513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83679998154717,
            21.0015322220599,
            105.83680932745348,
            21.00172525309321
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83680932745348,
              21.0015322220599
            ],
            [
              105.83680843043199,
              21.001543332532346
            ],
            [
              105.83680442331574,
              21.001593010674583
            ],
            [
              105.83680348311724,
              21.001604657875255
            ],
            [
              105.83679998154717,
              21.00164805611362
            ],
            [
              105.83680672147095,
              21.00166328659041
            ],
            [
              105.83680474021675,
              21.00172525309321
            ],
            [
              105.83680913731357,
              21.001725169154387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I7,6_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8521",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0015322220599,
          "to_latitude": 21.001725169154387,
          "to_longitude": 105.83680913731357,
          "from_longitude": 105.83680932745348
        }
      },
      {
        "id": 8514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83481629949173,
            21.002174884618604,
            105.8348222729225,
            21.00220283985429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83481629949173,
              21.00220283985429
            ],
            [
              105.83481678028325,
              21.002199875818455
            ],
            [
              105.8348222729225,
              21.002174884618604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D8B,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8522",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00220283985429,
          "to_latitude": 21.002174884618604,
          "to_longitude": 105.8348222729225,
          "from_longitude": 105.83481629949173
        }
      },
      {
        "id": 8515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721808175187,
            21.00149259106132,
            105.83749954134561,
            21.00150163678368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721808175187,
              21.00150163678368
            ],
            [
              105.83735889054356,
              21.001497393591915
            ],
            [
              105.83738172810006,
              21.001496613763596
            ],
            [
              105.83749954134561,
              21.00149259106132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,114_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8523",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00150163678368,
          "to_latitude": 21.00149259106132,
          "to_longitude": 105.83749954134561,
          "from_longitude": 105.83721808175187
        }
      },
      {
        "id": 8516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83754632120456,
            21.00375197779378,
            105.83755223469004,
            21.00377008133419
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755223469004,
              21.00375197779378
            ],
            [
              105.83755182992535,
              21.003753216300957
            ],
            [
              105.83754662096983,
              21.00376915947252
            ],
            [
              105.83754632120456,
              21.00377008133419
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,37_Phương Mai",
          "maTaiSan": "04.O17.DODV.8524",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00375197779378,
          "to_latitude": 21.00377008133419,
          "to_longitude": 105.83754632120456,
          "from_longitude": 105.83755223469004
        }
      },
      {
        "id": 8517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444545889306,
            21.00226562867276,
            105.8345004093046,
            21.002277196059485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444545889306,
              21.00226562867276
            ],
            [
              105.83446955452915,
              21.00227070138786
            ],
            [
              105.8344742789005,
              21.002271696162754
            ],
            [
              105.8345004093046,
              21.002277196059485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D17,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8525",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00226562867276,
          "to_latitude": 21.002277196059485,
          "to_longitude": 105.8345004093046,
          "from_longitude": 105.83444545889306
        }
      },
      {
        "id": 8518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023206549968,
            20.99917272228169,
            105.84043965770861,
            20.99917657857935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84043965770861,
              20.99917657857935
            ],
            [
              105.84023206549968,
              20.99917272228169
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,160_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8526",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99917657857935,
          "to_latitude": 20.99917272228169,
          "to_longitude": 105.84023206549968,
          "from_longitude": 105.84043965770861
        }
      },
      {
        "id": 8519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534003811552,
            21.001508068325933,
            105.83535357287464,
            21.001557906107085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534003811552,
              21.001557906107085
            ],
            [
              105.8353424783306,
              21.001548920582618
            ],
            [
              105.83534355565521,
              21.00154495555337
            ],
            [
              105.83534402220097,
              21.001543236231402
            ],
            [
              105.83535357287464,
              21.001508068325933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,5_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8527",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001557906107085,
          "to_latitude": 21.001508068325933,
          "to_longitude": 105.83535357287464,
          "from_longitude": 105.83534003811552
        }
      },
      {
        "id": 8520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749957329624,
            20.999786308892705,
            105.83753598016258,
            21.00026636911058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753598016258,
              20.999786308892705
            ],
            [
              105.83751777674874,
              21.00002633855336
            ],
            [
              105.83749957329624,
              21.00026636911058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8528",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999786308892705,
          "to_latitude": 21.00026636911058,
          "to_longitude": 105.83749957329624,
          "from_longitude": 105.83753598016258
        }
      },
      {
        "id": 8521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807148719158,
            21.000917145988957,
            105.83821834588032,
            21.00093198017403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807148719158,
              21.00093198017403
            ],
            [
              105.83807148833358,
              21.00093184016846
            ],
            [
              105.83807162520212,
              21.000923794465066
            ],
            [
              105.83821834588032,
              21.000917145988957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,122_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8529",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00093198017403,
          "to_latitude": 21.000917145988957,
          "to_longitude": 105.83821834588032,
          "from_longitude": 105.83807148719158
        }
      },
      {
        "id": 8522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839215962825,
            20.99879473740522,
            105.83955238947327,
            20.99881375709153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839215962825,
              20.99881375709153
            ],
            [
              105.83955238947327,
              20.99879473740522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,168_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8530",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99881375709153,
          "to_latitude": 20.99879473740522,
          "to_longitude": 105.83955238947327,
          "from_longitude": 105.839215962825
        }
      },
      {
        "id": 8523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84062321467465,
            20.998761055162387,
            105.8407438687734,
            20.998763704915188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84062321467465,
              20.998763704915188
            ],
            [
              105.8406292811425,
              20.998763572895115
            ],
            [
              105.8407438687734,
              20.998761055162387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1,4_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8531",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998763704915188,
          "to_latitude": 20.998761055162387,
          "to_longitude": 105.8407438687734,
          "from_longitude": 105.84062321467465
        }
      },
      {
        "id": 8524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763760597004,
            20.99999305618008,
            105.83767607755968,
            20.999993386390052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763760597004,
              20.999993386390052
            ],
            [
              105.83767607755968,
              20.99999305618008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8532",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999993386390052,
          "to_latitude": 20.99999305618008,
          "to_longitude": 105.83767607755968,
          "from_longitude": 105.83763760597004
        }
      },
      {
        "id": 8525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83446120689351,
            21.00229871262785,
            105.83447076348315,
            21.002329401419296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447076348315,
              21.00229871262785
            ],
            [
              105.83446497263725,
              21.002317307730113
            ],
            [
              105.83446129664505,
              21.00232910923732
            ],
            [
              105.83446120689351,
              21.002329401419296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D17,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8533",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00229871262785,
          "to_latitude": 21.002329401419296,
          "to_longitude": 105.83446120689351,
          "from_longitude": 105.83447076348315
        }
      },
      {
        "id": 8526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8345019241508,
            21.002368173709108,
            105.83461697789433,
            21.002760901356513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345019241508,
              21.002760901356513
            ],
            [
              105.83453505956423,
              21.002663249210514
            ],
            [
              105.8345359207412,
              21.00266036550401
            ],
            [
              105.83453669125,
              21.00265778211305
            ],
            [
              105.83453681871634,
              21.002657353359005
            ],
            [
              105.83453974119034,
              21.00264755799685
            ],
            [
              105.8345427108665,
              21.002637602532246
            ],
            [
              105.83456017123564,
              21.002569070463682
            ],
            [
              105.83456504440316,
              21.002549944245168
            ],
            [
              105.83456647004948,
              21.00254513299644
            ],
            [
              105.83460243030012,
              21.002423775370747
            ],
            [
              105.83461697789433,
              21.002368173709108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,80_Phương Mai",
          "maTaiSan": "04.O17.DODV.8534",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002760901356513,
          "to_latitude": 21.002368173709108,
          "to_longitude": 105.83461697789433,
          "from_longitude": 105.8345019241508
        }
      },
      {
        "id": 8527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83707413741139,
            21.00291069884944,
            105.8370861317529,
            21.002952606078278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8370861317529,
              21.00291069884944
            ],
            [
              105.83707413741139,
              21.002952606078278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,54_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8535",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00291069884944,
          "to_latitude": 21.002952606078278,
          "to_longitude": 105.83707413741139,
          "from_longitude": 105.8370861317529
        }
      },
      {
        "id": 8528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602552524012,
            21.000842420231873,
            105.83613778247638,
            21.001227108783702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602552524012,
              21.001227108783702
            ],
            [
              105.83603426729074,
              21.0011486775884
            ],
            [
              105.8360349250067,
              21.00114277268053
            ],
            [
              105.83603494333761,
              21.001142610009843
            ],
            [
              105.83603560474991,
              21.001136677987045
            ],
            [
              105.83603589057054,
              21.0011341150348
            ],
            [
              105.83603698608125,
              21.001097670814495
            ],
            [
              105.83603794117454,
              21.00106589154192
            ],
            [
              105.83603885058992,
              21.000996897782333
            ],
            [
              105.83603895943926,
              21.000988637247364
            ],
            [
              105.836039046026,
              21.00098207849923
            ],
            [
              105.83603905459383,
              21.00098171716681
            ],
            [
              105.83603931291312,
              21.000970762478232
            ],
            [
              105.8360406945751,
              21.00091212730234
            ],
            [
              105.83604192319689,
              21.000842420231873
            ],
            [
              105.83613778247638,
              21.000844706050405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1,3_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8536",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001227108783702,
          "to_latitude": 21.000844706050405,
          "to_longitude": 105.83613778247638,
          "from_longitude": 105.83602552524012
        }
      },
      {
        "id": 8529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835721205704,
            21.003271561829575,
            105.8360332843937,
            21.003361003441004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360332843937,
              21.003271561829575
            ],
            [
              105.83600763236427,
              21.003358548322037
            ],
            [
              105.83599914225545,
              21.003361003441004
            ],
            [
              105.8358723709955,
              21.003335208499028
            ],
            [
              105.83586566085818,
              21.00333361474195
            ],
            [
              105.83580915782706,
              21.00332020111339
            ],
            [
              105.835721205704,
              21.00329932136713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,70_Phương Mai",
          "maTaiSan": "04.O17.DODV.8537",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003271561829575,
          "to_latitude": 21.00329932136713,
          "to_longitude": 105.835721205704,
          "from_longitude": 105.8360332843937
        }
      },
      {
        "id": 8530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83370225097823,
            21.002173734364636,
            105.8337646716723,
            21.00225052406853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83370868848667,
              21.00225052406853
            ],
            [
              105.83370225097823,
              21.00222405765415
            ],
            [
              105.83373047494499,
              21.002173734364636
            ],
            [
              105.8337646716723,
              21.002184999900585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,87_Phương Mai",
          "maTaiSan": "04.O17.DODV.8538",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00225052406853,
          "to_latitude": 21.002184999900585,
          "to_longitude": 105.8337646716723,
          "from_longitude": 105.83370868848667
        }
      },
      {
        "id": 8531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414229986136,
            21.00264586593313,
            105.8341548749946,
            21.002699025505393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8341548749946,
              21.00264586593313
            ],
            [
              105.83415116756363,
              21.002661535201906
            ],
            [
              105.83414229986136,
              21.002699025505393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14,3_Phương Mai",
          "maTaiSan": "04.O17.DODV.8539",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00264586593313,
          "to_latitude": 21.002699025505393,
          "to_longitude": 105.83414229986136,
          "from_longitude": 105.8341548749946
        }
      },
      {
        "id": 8532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807612657112,
            21.00093136572018,
            105.8381208037121,
            21.001237239836357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807612657112,
              21.00093136572018
            ],
            [
              105.83807613208617,
              21.00093149214461
            ],
            [
              105.83809069041656,
              21.00121555262539
            ],
            [
              105.8381208037121,
              21.001237239836357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,123_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8540",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00093136572018,
          "to_latitude": 21.001237239836357,
          "to_longitude": 105.8381208037121,
          "from_longitude": 105.83807612657112
        }
      },
      {
        "id": 8533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336652748368,
            21.002355725897274,
            105.83398161704731,
            21.00245174885971
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398161704731,
              21.00243081769881
            ],
            [
              105.83397863518788,
              21.002430450243963
            ],
            [
              105.83396558831853,
              21.00242883687636
            ],
            [
              105.83395732345977,
              21.00245174885971
            ],
            [
              105.83391747581622,
              21.002449146794316
            ],
            [
              105.83390057461344,
              21.00244542159453
            ],
            [
              105.83368264061889,
              21.002388548081065
            ],
            [
              105.8336652748368,
              21.002355725897274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,88_Phương Mai",
          "maTaiSan": "04.O17.DODV.8541",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00243081769881,
          "to_latitude": 21.002355725897274,
          "to_longitude": 105.8336652748368,
          "from_longitude": 105.83398161704731
        }
      },
      {
        "id": 8534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83577306827115,
            21.002883924325513,
            105.83577363811051,
            21.00289678255819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577306827115,
              21.002883924325513
            ],
            [
              105.83577344611123,
              21.002892012662816
            ],
            [
              105.83577363811051,
              21.00289678255819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4B,3_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8542",
          "diaChiLapD": "Ngõ 28B, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002883924325513,
          "to_latitude": 21.00289678255819,
          "to_longitude": 105.83577363811051,
          "from_longitude": 105.83577306827115
        }
      },
      {
        "id": 8535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83795912658205,
            21.002883330812637,
            105.83797372548008,
            21.002932455044434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83795912658205,
              21.002932455044434
            ],
            [
              105.83797372548008,
              21.002883330812637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,60_Ngách 15/34, Phương Mai",
          "maTaiSan": "04.O17.DODV.8543",
          "diaChiLapD": "Ngách 15/34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002932455044434,
          "to_latitude": 21.002883330812637,
          "to_longitude": 105.83797372548008,
          "from_longitude": 105.83795912658205
        }
      },
      {
        "id": 8536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696624234187,
            21.00179187770852,
            105.83730489334202,
            21.00196569295701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696624234187,
              21.001955767920233
            ],
            [
              105.83698707991823,
              21.00195837152431
            ],
            [
              105.83702755479926,
              21.00196342656248
            ],
            [
              105.83705837159954,
              21.00196569295701
            ],
            [
              105.83716498950243,
              21.001963256496374
            ],
            [
              105.83724904567903,
              21.00194967585074
            ],
            [
              105.83727878130519,
              21.00193313694828
            ],
            [
              105.83726039076403,
              21.001797772478678
            ],
            [
              105.83730489334202,
              21.00179187770852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,107_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8544",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001955767920233,
          "to_latitude": 21.00179187770852,
          "to_longitude": 105.83730489334202,
          "from_longitude": 105.83696624234187
        }
      },
      {
        "id": 8537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83604505982852,
            21.003084643157486,
            105.83671493347077,
            21.0032445126246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604505982852,
              21.00308997189949
            ],
            [
              105.83604566351542,
              21.003089907514944
            ],
            [
              105.83608734559202,
              21.003085426905212
            ],
            [
              105.83609464940731,
              21.003084643157486
            ],
            [
              105.83627311001547,
              21.00313570462868
            ],
            [
              105.83632790770837,
              21.003148957580244
            ],
            [
              105.83640477127582,
              21.003167546728648
            ],
            [
              105.83653148678437,
              21.00319819187102
            ],
            [
              105.83654810216676,
              21.003202209951482
            ],
            [
              105.83663988239277,
              21.003225482064675
            ],
            [
              105.8366627646072,
              21.003231284813385
            ],
            [
              105.83671493347077,
              21.0032445126246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,67_Ngõ 65, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8545",
          "diaChiLapD": "Ngõ 65, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00308997189949,
          "to_latitude": 21.0032445126246,
          "to_longitude": 105.83671493347077,
          "from_longitude": 105.83604505982852
        }
      },
      {
        "id": 8538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382194021497,
            21.000051919720935,
            105.83826147148591,
            21.00024040087793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826147148591,
              21.000053258548565
            ],
            [
              105.83822850477313,
              21.000051919720935
            ],
            [
              105.8382194021497,
              21.00024040087793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,143_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8546",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000053258548565,
          "to_latitude": 21.00024040087793,
          "to_longitude": 105.8382194021497,
          "from_longitude": 105.83826147148591
        }
      },
      {
        "id": 8539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559412295673,
            21.002245333305694,
            105.83594238971841,
            21.00258607496899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559955072305,
              21.00258607496899
            ],
            [
              105.83559412295673,
              21.002251052722503
            ],
            [
              105.83569378969783,
              21.002249457880946
            ],
            [
              105.83570859703006,
              21.002249220795363
            ],
            [
              105.83571854841287,
              21.0022490617435
            ],
            [
              105.83573386259616,
              21.002248816747844
            ],
            [
              105.83574247327624,
              21.0022486787288
            ],
            [
              105.8357558030599,
              21.002248415474313
            ],
            [
              105.83580299300547,
              21.002247485574017
            ],
            [
              105.83588309866464,
              21.002245905379613
            ],
            [
              105.83588760636277,
              21.00224581640491
            ],
            [
              105.83588974239838,
              21.00224577430251
            ],
            [
              105.83590339041302,
              21.002245658505988
            ],
            [
              105.83590758965728,
              21.00224562343261
            ],
            [
              105.83594238971841,
              21.002245333305694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,6_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8547",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00258607496899,
          "to_latitude": 21.002245333305694,
          "to_longitude": 105.83594238971841,
          "from_longitude": 105.83559955072305
        }
      },
      {
        "id": 8540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967724689202,
            20.998792615032833,
            105.83967766802301,
            20.998892672270085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83967766802301,
              20.998892672270085
            ],
            [
              105.83967753606126,
              20.9988611684177
            ],
            [
              105.83967753503578,
              20.99886098506799
            ],
            [
              105.8396774983839,
              20.998852368478058
            ],
            [
              105.83967747864538,
              20.99884763567411
            ],
            [
              105.83967724689202,
              20.998792615032833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8548",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998892672270085,
          "to_latitude": 20.998792615032833,
          "to_longitude": 105.83967724689202,
          "from_longitude": 105.83967766802301
        }
      },
      {
        "id": 8541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344578488466,
            21.002271696162754,
            105.8344742789005,
            21.00232866910729
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344578488466,
              21.00232866910729
            ],
            [
              105.8344580394497,
              21.002328005205012
            ],
            [
              105.83446137623648,
              21.0023164356834
            ],
            [
              105.8344742789005,
              21.002271696162754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D17,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8549",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00232866910729,
          "to_latitude": 21.002271696162754,
          "to_longitude": 105.8344742789005,
          "from_longitude": 105.8344578488466
        }
      },
      {
        "id": 8542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706049031483,
            21.002646518886852,
            105.83707163396579,
            21.00268366825622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706049031483,
              21.00268366825622
            ],
            [
              105.83706969909728,
              21.002652968399854
            ],
            [
              105.83707163396579,
              21.002646518886852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,58_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8550",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00268366825622,
          "to_latitude": 21.002646518886852,
          "to_longitude": 105.83707163396579,
          "from_longitude": 105.83706049031483
        }
      },
      {
        "id": 8543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371253371529,
            21.001961432729104,
            105.83732428674065,
            21.002056534321678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371253371529,
              21.002056534321678
            ],
            [
              105.8371436788477,
              21.002046221410232
            ],
            [
              105.83712709045662,
              21.00201511642036
            ],
            [
              105.83714646873867,
              21.001998889509256
            ],
            [
              105.83716998258109,
              21.001990871544514
            ],
            [
              105.83726859349859,
              21.001961432729104
            ],
            [
              105.83729351716416,
              21.001981306643167
            ],
            [
              105.83732428674065,
              21.002022695531814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,108_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8551",
          "diaChiLapD": "Ngõ 89, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002056534321678,
          "to_latitude": 21.002022695531814,
          "to_longitude": 105.83732428674065,
          "from_longitude": 105.8371253371529
        }
      },
      {
        "id": 8544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83588585597107,
            21.002079434958073,
            105.8360400784502,
            21.00208072797829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360400784502,
              21.002079434958073
            ],
            [
              105.83602351328676,
              21.00207957322174
            ],
            [
              105.83601670274774,
              21.002079630155368
            ],
            [
              105.83588585597107,
              21.00208072797829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2,3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8552",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002079434958073,
          "to_latitude": 21.00208072797829,
          "to_longitude": 105.83588585597107,
          "from_longitude": 105.8360400784502
        }
      },
      {
        "id": 8545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84062741486272,
            20.998869496113425,
            105.84074264703108,
            20.998871793000056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84074264703108,
              20.998871793000056
            ],
            [
              105.84065206733955,
              20.99886998676623
            ],
            [
              105.8406408021148,
              20.998869762394513
            ],
            [
              105.84062741486272,
              20.998869496113425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1,3_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8553",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998871793000056,
          "to_latitude": 20.998869496113425,
          "to_longitude": 105.84062741486272,
          "from_longitude": 105.84074264703108
        }
      },
      {
        "id": 8546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83762634401796,
            21.002609613811263,
            105.8377027057224,
            21.00262964639524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377027057224,
              21.00262964639524
            ],
            [
              105.83762634401796,
              21.002609613811263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,57_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8554",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00262964639524,
          "to_latitude": 21.002609613811263,
          "to_longitude": 105.83762634401796,
          "from_longitude": 105.8377027057224
        }
      },
      {
        "id": 8547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444166164486,
            21.00285271680501,
            105.83470070916073,
            21.003042058296977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83470070916073,
              21.003042058296977
            ],
            [
              105.83468494317198,
              21.003037523739817
            ],
            [
              105.83444580990107,
              21.0029687468077
            ],
            [
              105.83444166164486,
              21.00295979088485
            ],
            [
              105.83447137730087,
              21.00285271680501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,75_Phương Mai",
          "maTaiSan": "04.O17.DODV.8555",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003042058296977,
          "to_latitude": 21.00285271680501,
          "to_longitude": 105.83447137730087,
          "from_longitude": 105.83470070916073
        }
      },
      {
        "id": 8548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810218622871,
            21.002049632186228,
            105.8381023179347,
            21.00205188367235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810218622871,
              21.00205188367235
            ],
            [
              105.83810231436476,
              21.002049681881203
            ],
            [
              105.8381023179347,
              21.002049632186228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,63_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8556",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00205188367235,
          "to_latitude": 21.002049632186228,
          "to_longitude": 105.8381023179347,
          "from_longitude": 105.83810218622871
        }
      },
      {
        "id": 8549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647603735147,
            21.001075511038316,
            105.83647971826203,
            21.001178913249642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647971826203,
              21.001075511038316
            ],
            [
              105.83647969406364,
              21.001076174124933
            ],
            [
              105.83647603735147,
              21.001178913249642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I3,1_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8557",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001075511038316,
          "to_latitude": 21.001178913249642,
          "to_longitude": 105.83647603735147,
          "from_longitude": 105.83647971826203
        }
      },
      {
        "id": 8550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598506233726,
            21.001438160380275,
            105.83600267187951,
            21.00148702196099
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83600267187951,
              21.001438160380275
            ],
            [
              105.83599608499104,
              21.00145643608483
            ],
            [
              105.83599275649718,
              21.001465670754307
            ],
            [
              105.83598506233726,
              21.00148702196099
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,94_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8558",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001438160380275,
          "to_latitude": 21.00148702196099,
          "to_longitude": 105.83598506233726,
          "from_longitude": 105.83600267187951
        }
      },
      {
        "id": 8551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820498939644,
            21.002053676513995,
            105.83820822276928,
            21.002201008491706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820498939644,
              21.002201008491706
            ],
            [
              105.83820822276928,
              21.002053676513995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,63_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8559",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002201008491706,
          "to_latitude": 21.002053676513995,
          "to_longitude": 105.83820822276928,
          "from_longitude": 105.83820498939644
        }
      },
      {
        "id": 8552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436636508813,
            21.002707261063236,
            105.83438372380817,
            21.002756202549442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438372380817,
              21.002707261063236
            ],
            [
              105.83437866582783,
              21.00272048469812
            ],
            [
              105.83436689421966,
              21.002754665374816
            ],
            [
              105.83436636508813,
              21.002756202549442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8560",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002707261063236,
          "to_latitude": 21.002756202549442,
          "to_longitude": 105.83436636508813,
          "from_longitude": 105.83438372380817
        }
      },
      {
        "id": 8553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388086890955,
            21.00217511663168,
            105.8341037547107,
            21.00225509093095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388086890955,
              21.00217511663168
            ],
            [
              105.83401040700014,
              21.002221321483326
            ],
            [
              105.83402125700033,
              21.00224596071553
            ],
            [
              105.8340967808515,
              21.00225431942678
            ],
            [
              105.8341037547107,
              21.00225509093095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6.86_Phương Mai",
          "maTaiSan": "04.O17.DODV.8561",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00217511663168,
          "to_latitude": 21.00225509093095,
          "to_longitude": 105.8341037547107,
          "from_longitude": 105.83388086890955
        }
      },
      {
        "id": 8554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602287661658,
            21.002546625389794,
            105.83604493236355,
            21.00254774984859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602287661658,
              21.00254774984859
            ],
            [
              105.83602634407129,
              21.002547572946934
            ],
            [
              105.83604248514484,
              21.002546750312998
            ],
            [
              105.83604493236355,
              21.002546625389794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8562",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00254774984859,
          "to_latitude": 21.002546625389794,
          "to_longitude": 105.83604493236355,
          "from_longitude": 105.83602287661658
        }
      },
      {
        "id": 8555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378088441126,
            21.003820835868353,
            105.83825206832852,
            21.003925021915418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378088441126,
              21.003820835868353
            ],
            [
              105.83794837385376,
              21.003853634271017
            ],
            [
              105.83802726049888,
              21.003872177483252
            ],
            [
              105.83825206832852,
              21.003925021915418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,36_Phương Mai",
          "maTaiSan": "04.O17.DODV.8563",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003820835868353,
          "to_latitude": 21.003925021915418,
          "to_longitude": 105.83825206832852,
          "from_longitude": 105.8378088441126
        }
      },
      {
        "id": 8556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509544613185,
            21.001556664489254,
            105.83510527050082,
            21.001602639920307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509544613185,
              21.001602639920307
            ],
            [
              105.83509733412468,
              21.001593802527502
            ],
            [
              105.83510527050082,
              21.001556664489254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D1,8_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8564",
          "diaChiLapD": "Ngõ 32, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001602639920307,
          "to_latitude": 21.001556664489254,
          "to_longitude": 105.83510527050082,
          "from_longitude": 105.83509544613185
        }
      },
      {
        "id": 8557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773229889697,
            21.002051101458335,
            105.83820822276928,
            21.00205437055379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820822276928,
              21.002053676513995
            ],
            [
              105.83810218622871,
              21.00205188367235
            ],
            [
              105.83805596117331,
              21.002051101458335
            ],
            [
              105.83782672945809,
              21.00205437055379
            ],
            [
              105.83773229889697,
              21.0020514274528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,63_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8565",
          "diaChiLapD": "Ngõ 15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002053676513995,
          "to_latitude": 21.0020514274528,
          "to_longitude": 105.83773229889697,
          "from_longitude": 105.83820822276928
        }
      },
      {
        "id": 8558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83896017746956,
            20.999502594649226,
            105.83898896689296,
            20.99954060320347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896017746956,
              20.999502594649226
            ],
            [
              105.83897847960077,
              20.99952675786164
            ],
            [
              105.83898867523894,
              20.9995402198668
            ],
            [
              105.83898896689296,
              20.99954060320347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,147_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8566",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999502594649226,
          "to_latitude": 20.99954060320347,
          "to_longitude": 105.83898896689296,
          "from_longitude": 105.83896017746956
        }
      },
      {
        "id": 8559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84036282562914,
            20.99893445542806,
            105.84048106029859,
            20.999123584668638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84047988195654,
              20.999123584668638
            ],
            [
              105.84048055251104,
              20.999017097420207
            ],
            [
              105.84048072356394,
              20.998989925752642
            ],
            [
              105.84048089058354,
              20.998963408040023
            ],
            [
              105.84048094201904,
              20.998955243532677
            ],
            [
              105.84048106029859,
              20.998936512765837
            ],
            [
              105.84042093246451,
              20.998935466515164
            ],
            [
              105.84041262241603,
              20.99893532147499
            ],
            [
              105.84036282562914,
              20.99893445542806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1B,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8567",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999123584668638,
          "to_latitude": 20.99893445542806,
          "to_longitude": 105.84036282562914,
          "from_longitude": 105.84047988195654
        }
      },
      {
        "id": 8560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357558030599,
            21.002237365467995,
            105.83575597526308,
            21.002248415474313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8357558030599,
              21.002248415474313
            ],
            [
              105.83575582230536,
              21.002247207766967
            ],
            [
              105.83575596856798,
              21.002237717758927
            ],
            [
              105.83575597526308,
              21.002237365467995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,6_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8568",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002248415474313,
          "to_latitude": 21.002237365467995,
          "to_longitude": 105.83575597526308,
          "from_longitude": 105.8357558030599
        }
      },
      {
        "id": 8561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380623890102,
            21.000586371307264,
            105.83815358395985,
            21.00064597073531
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380623890102,
              21.00064597073531
            ],
            [
              105.83806477062087,
              21.00059120186523
            ],
            [
              105.83815358395985,
              21.000586371307264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,124_Ngách 102/62, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8569",
          "diaChiLapD": "Ngách 102/62, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00064597073531,
          "to_latitude": 21.000586371307264,
          "to_longitude": 105.83815358395985,
          "from_longitude": 105.8380623890102
        }
      },
      {
        "id": 8562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792672130221,
            21.000944685523002,
            105.83792679131086,
            21.000949547027407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792679131086,
              21.000944685523002
            ],
            [
              105.83792676282206,
              21.000946646565854
            ],
            [
              105.83792672999427,
              21.00094886414612
            ],
            [
              105.83792672405039,
              21.000949350110705
            ],
            [
              105.83792672130221,
              21.000949547027407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,120_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8570",
          "diaChiLapD": "Ngách 102/70, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000944685523002,
          "to_latitude": 21.000949547027407,
          "to_longitude": 105.83792672130221,
          "from_longitude": 105.83792679131086
        }
      },
      {
        "id": 8563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965577565965,
            20.998861147771105,
            105.83970864050717,
            20.998861169815477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83970864050717,
              20.998861147771105
            ],
            [
              105.83970069208243,
              20.998861169815477
            ],
            [
              105.83967753606126,
              20.9988611684177
            ],
            [
              105.83965577565965,
              20.99886116826229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4,2_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8571",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998861147771105,
          "to_latitude": 20.99886116826229,
          "to_longitude": 105.83965577565965,
          "from_longitude": 105.83970864050717
        }
      },
      {
        "id": 8564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987310796205,
            20.999152840574755,
            105.83988816465066,
            20.999520954364815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987310796205,
              20.999152840574755
            ],
            [
              105.83987333707407,
              20.999156314152433
            ],
            [
              105.83987429292566,
              20.999170718591085
            ],
            [
              105.83988184708237,
              20.999284691847134
            ],
            [
              105.83988816465066,
              20.999520954364815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,157_Ngách 78/22, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8572",
          "diaChiLapD": "Ngách 78/22, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999152840574755,
          "to_latitude": 20.999520954364815,
          "to_longitude": 105.83988816465066,
          "from_longitude": 105.83987310796205
        }
      },
      {
        "id": 8565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454922428216,
            21.001932895130803,
            105.8346423030698,
            21.00236128552229
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83454922428216,
              21.00236128552229
            ],
            [
              105.83455369444565,
              21.002348043990377
            ],
            [
              105.83455408241126,
              21.00234689408802
            ],
            [
              105.83458192341438,
              21.002264416795565
            ],
            [
              105.83457817497636,
              21.002140536957967
            ],
            [
              105.8346423030698,
              21.001932895130803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,83_Phương Mai",
          "maTaiSan": "04.O17.DODV.8573",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00236128552229,
          "to_latitude": 21.001932895130803,
          "to_longitude": 105.8346423030698,
          "from_longitude": 105.83454922428216
        }
      },
      {
        "id": 8566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83430653808534,
            21.002674065595688,
            105.83438081820782,
            21.00275479670371
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436110843537,
              21.00275479670371
            ],
            [
              105.83436159903347,
              21.002753248879273
            ],
            [
              105.83436580169533,
              21.002739983373015
            ],
            [
              105.83438081820782,
              21.002692582529775
            ],
            [
              105.83430653808534,
              21.002674065595688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D14,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8574",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00275479670371,
          "to_latitude": 21.002674065595688,
          "to_longitude": 105.83430653808534,
          "from_longitude": 105.83436110843537
        }
      },
      {
        "id": 8567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718634553023,
            21.002678518679932,
            105.83719681966498,
            21.00271343289341
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83718634553023,
              21.00271343289341
            ],
            [
              105.83719555525524,
              21.00268273392963
            ],
            [
              105.83719681966498,
              21.002678518679932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,58_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DODV.8575",
          "diaChiLapD": "Ngách 15/24, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00271343289341,
          "to_latitude": 21.002678518679932,
          "to_longitude": 105.83719681966498,
          "from_longitude": 105.83718634553023
        }
      },
      {
        "id": 8568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914443138151,
            20.999670621272823,
            105.83915046808833,
            20.999671868676355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914443138151,
              20.999670621272823
            ],
            [
              105.83914898804416,
              20.999671563456083
            ],
            [
              105.83915046808833,
              20.999671868676355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,149_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8576",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999670621272823,
          "to_latitude": 20.999671868676355,
          "to_longitude": 105.83915046808833,
          "from_longitude": 105.83914443138151
        }
      },
      {
        "id": 8569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360221401377,
            21.002273385246205,
            105.8360294683969,
            21.002622405068706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360221401377,
              21.002622405068706
            ],
            [
              105.83602287661658,
              21.00254774984859
            ],
            [
              105.8360235375676,
              21.0024807751269
            ],
            [
              105.8360237222261,
              21.002473001059588
            ],
            [
              105.83602390831527,
              21.00246513846914
            ],
            [
              105.83602417372948,
              21.002455975747285
            ],
            [
              105.83602460323921,
              21.00244121854271
            ],
            [
              105.8360294683969,
              21.002273385246205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8577",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002622405068706,
          "to_latitude": 21.002273385246205,
          "to_longitude": 105.8360294683969,
          "from_longitude": 105.8360221401377
        }
      },
      {
        "id": 8570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603315764884,
            21.00098170653816,
            105.83606468290884,
            21.00098173709655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603315764884,
              21.00098173709655
            ],
            [
              105.83603484469445,
              21.000981732423774
            ],
            [
              105.83603905459383,
              21.00098171716681
            ],
            [
              105.8360421382015,
              21.00098170653816
            ],
            [
              105.83606468290884,
              21.00098172135917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1,4_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8578",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00098173709655,
          "to_latitude": 21.00098172135917,
          "to_longitude": 105.83606468290884,
          "from_longitude": 105.83603315764884
        }
      },
      {
        "id": 8571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83795264646069,
            20.999548101933108,
            105.8383255130491,
            20.999548714792137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83795264646069,
              20.999548101933108
            ],
            [
              105.83805991008782,
              20.999548278426946
            ],
            [
              105.83808123027097,
              20.999548313466914
            ],
            [
              105.83812811332155,
              20.99954839065051
            ],
            [
              105.83813181640235,
              20.999548394999465
            ],
            [
              105.8381335977435,
              20.999548399777588
            ],
            [
              105.8383255130491,
              20.999548714792137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H10,3_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.8579",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999548101933108,
          "to_latitude": 20.999548714792137,
          "to_longitude": 105.8383255130491,
          "from_longitude": 105.83795264646069
        }
      },
      {
        "id": 8572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366627646072,
            21.00315271826677,
            105.83668581037192,
            21.003231284813385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366627646072,
              21.003231284813385
            ],
            [
              105.83668581037192,
              21.00315271826677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,67_Ngõ 65, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8580",
          "diaChiLapD": "Ngõ 65, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003231284813385,
          "to_latitude": 21.00315271826677,
          "to_longitude": 105.83668581037192,
          "from_longitude": 105.8366627646072
        }
      },
      {
        "id": 8573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84062859158054,
            20.99889862948215,
            105.84073097164412,
            20.998899112478924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84062859158054,
              20.998899112478924
            ],
            [
              105.84063101148355,
              20.998899102336026
            ],
            [
              105.84073097164412,
              20.99889862948215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H1,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8581",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998899112478924,
          "to_latitude": 20.99889862948215,
          "to_longitude": 105.84073097164412,
          "from_longitude": 105.84062859158054
        }
      },
      {
        "id": 8574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538816217683,
            21.002687133078414,
            105.83538845139016,
            21.002830344428347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538816217683,
              21.002687133078414
            ],
            [
              105.8353881682241,
              21.002690811887362
            ],
            [
              105.83538838200484,
              21.00279593006059
            ],
            [
              105.83538845139016,
              21.002830344428347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6,2_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8582",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002687133078414,
          "to_latitude": 21.002830344428347,
          "to_longitude": 105.83538845139016,
          "from_longitude": 105.83538816217683
        }
      },
      {
        "id": 8575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84111892659064,
            21.005252329685145,
            105.84113079468632,
            21.00572980948714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84112040958973,
              21.005252329685145
            ],
            [
              105.84113079468632,
              21.005685968832893
            ],
            [
              105.84111895202896,
              21.00572971452284
            ],
            [
              105.84111892659064,
              21.00572980948714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.10_Giải Phóng",
          "maTaiSan": "04.O17.DODV.8583",
          "diaChiLapD": "Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005252329685145,
          "to_latitude": 21.00572980948714,
          "to_longitude": 105.84111892659064,
          "from_longitude": 105.84112040958973
        }
      },
      {
        "id": 8576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84098318326272,
            21.005433185749887,
            105.84099606628376,
            21.005744155581834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84099606628376,
              21.005744155581834
            ],
            [
              105.84099564719855,
              21.005737856758586
            ],
            [
              105.84099123182774,
              21.005671363317713
            ],
            [
              105.84098442287205,
              21.00552909303734
            ],
            [
              105.84098318326272,
              21.005433185749887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.12_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8584",
          "diaChiLapD": "Ngõ 24, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005744155581834,
          "to_latitude": 21.005433185749887,
          "to_longitude": 105.84098318326272,
          "from_longitude": 105.84099606628376
        }
      },
      {
        "id": 8577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8408847018874,
            21.005431929121272,
            105.84099268980073,
            21.005744786459033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84099268980073,
              21.005744786459033
            ],
            [
              105.8409921206678,
              21.005738488377222
            ],
            [
              105.84098503310332,
              21.005659959125467
            ],
            [
              105.8408888596766,
              21.005662408771386
            ],
            [
              105.84088594603831,
              21.005555266414873
            ],
            [
              105.84088485991802,
              21.005497899861453
            ],
            [
              105.8408847018874,
              21.005431929121272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.13_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8585",
          "diaChiLapD": "Ngõ 24, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005744786459033,
          "to_latitude": 21.005431929121272,
          "to_longitude": 105.8408847018874,
          "from_longitude": 105.84099268980073
        }
      },
      {
        "id": 8578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.840803124223,
            21.00567621753259,
            105.84089787826751,
            21.005747392229374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84089787826751,
              21.005744306547406
            ],
            [
              105.84082403918472,
              21.00574670812907
            ],
            [
              105.840803124223,
              21.005747392229374
            ],
            [
              105.84080434332427,
              21.00567621753259
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.14_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8586",
          "diaChiLapD": "Ngõ 24, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005744306547406,
          "to_latitude": 21.00567621753259,
          "to_longitude": 105.84080434332427,
          "from_longitude": 105.84089787826751
        }
      },
      {
        "id": 8579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84079107310279,
            21.00553569010898,
            105.84080410064901,
            21.005652482985973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84080181116835,
              21.00553569010898
            ],
            [
              105.84080410064901,
              21.005652303152072
            ],
            [
              105.84079110100134,
              21.005652482985973
            ],
            [
              105.84079107310279,
              21.005652482220576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.15_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8587",
          "diaChiLapD": "Ngõ 24, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00553569010898,
          "to_latitude": 21.005652482220576,
          "to_longitude": 105.84079107310279,
          "from_longitude": 105.84080181116835
        }
      },
      {
        "id": 8580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023094398825,
            21.005649255968795,
            105.84079142534807,
            21.005659775402258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84023094398825,
              21.005659775402258
            ],
            [
              105.84079139649776,
              21.00564925701454
            ],
            [
              105.84079142534807,
              21.005649255968795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.16_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8588",
          "diaChiLapD": "Ngõ 24, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005659775402258,
          "to_latitude": 21.005649255968795,
          "to_longitude": 105.84079142534807,
          "from_longitude": 105.84023094398825
        }
      },
      {
        "id": 8581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84111500668632,
            21.005729931827343,
            105.84112864850353,
            21.005899756414063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84112864850353,
              21.005899756414063
            ],
            [
              105.84111503053498,
              21.00573023970902
            ],
            [
              105.84111500668632,
              21.005729931827343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.11_Giải Phóng",
          "maTaiSan": "04.O17.DODV.8589",
          "diaChiLapD": "Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005899756414063,
          "to_latitude": 21.005729931827343,
          "to_longitude": 105.84111500668632,
          "from_longitude": 105.84112864850353
        }
      },
      {
        "id": 8582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84035506452527,
            21.006571784988253,
            105.84060887335858,
            21.00675749178372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84035506452527,
              21.00675749178372
            ],
            [
              105.8403551528466,
              21.006750762325048
            ],
            [
              105.8403573300891,
              21.006586130777425
            ],
            [
              105.84035730458069,
              21.006571784988253
            ],
            [
              105.84060887335858,
              21.006572183762007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.8_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8590",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00675749178372,
          "to_latitude": 21.006572183762007,
          "to_longitude": 105.84060887335858,
          "from_longitude": 105.84035506452527
        }
      },
      {
        "id": 8583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84006574020299,
            21.006586130777425,
            105.8403573300891,
            21.00658792440842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8403573300891,
              21.006586130777425
            ],
            [
              105.84032799568591,
              21.006586985557213
            ],
            [
              105.84006574020299,
              21.00658792440842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.8_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8591",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006586130777425,
          "to_latitude": 21.00658792440842,
          "to_longitude": 105.84006574020299,
          "from_longitude": 105.8403573300891
        }
      },
      {
        "id": 8584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399974325543,
            21.00665994147094,
            105.84035314738716,
            21.006757471443823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84035314738716,
              21.006757471443823
            ],
            [
              105.84035304051498,
              21.0067514411415
            ],
            [
              105.8403528127627,
              21.006738424994506
            ],
            [
              105.84011044446449,
              21.006751843123606
            ],
            [
              105.84006190804921,
              21.00674204608033
            ],
            [
              105.84005350335826,
              21.006701875105893
            ],
            [
              105.8399974325543,
              21.00665994147094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.9_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8592",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006757471443823,
          "to_latitude": 21.00665994147094,
          "to_longitude": 105.8399974325543,
          "from_longitude": 105.84035314738716
        }
      },
      {
        "id": 8585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84096249783252,
            21.006971362456166,
            105.84117196419655,
            21.007219070172447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84117196419655,
              21.007214836649926
            ],
            [
              105.84112915667137,
              21.00721570306787
            ],
            [
              105.84096249783252,
              21.007219070172447
            ],
            [
              105.84096382599957,
              21.006971362456166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.5_Giải Phóng",
          "maTaiSan": "04.O17.DODV.8593",
          "diaChiLapD": "Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007214836649926,
          "to_latitude": 21.006971362456166,
          "to_longitude": 105.84096382599957,
          "from_longitude": 105.84117196419655
        }
      },
      {
        "id": 8586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84042364999402,
            21.006736994941985,
            105.84094341490179,
            21.00676212751413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84042364999402,
              21.006736994941985
            ],
            [
              105.84064056907582,
              21.006747484879835
            ],
            [
              105.84080100674801,
              21.00675524224321
            ],
            [
              105.84094341490179,
              21.00676212751413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.7_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8594",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006736994941985,
          "to_latitude": 21.00676212751413,
          "to_longitude": 105.84094341490179,
          "from_longitude": 105.84042364999402
        }
      },
      {
        "id": 8587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84094272485397,
            21.00676212751413,
            105.84094341490179,
            21.006781455409925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84094341490179,
              21.00676212751413
            ],
            [
              105.8409432610769,
              21.006766432138498
            ],
            [
              105.84094285776561,
              21.00677770998482
            ],
            [
              105.84094272485397,
              21.006781455409925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.7_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8595",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00676212751413,
          "to_latitude": 21.006781455409925,
          "to_longitude": 105.84094272485397,
          "from_longitude": 105.84094341490179
        }
      },
      {
        "id": 8588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84095117020172,
            21.00667058666297,
            105.84111027926883,
            21.00676935328871
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84095117020172,
              21.006763098997613
            ],
            [
              105.84096202327801,
              21.006763649625682
            ],
            [
              105.84098427015853,
              21.006764778014087
            ],
            [
              105.84107227691297,
              21.00676935328871
            ],
            [
              105.84111027926883,
              21.00667058666297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.6_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8596",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006763098997613,
          "to_latitude": 21.00667058666297,
          "to_longitude": 105.84111027926883,
          "from_longitude": 105.84095117020172
        }
      },
      {
        "id": 8589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84096202327801,
            21.006763649625682,
            105.84096209733367,
            21.006782185230854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84096202327801,
              21.006763649625682
            ],
            [
              105.84096204960643,
              21.00677023580791
            ],
            [
              105.84096208533843,
              21.00677935819776
            ],
            [
              105.84096209733367,
              21.006782185230854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.6_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8597",
          "diaChiLapD": "Ngõ 10, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006763649625682,
          "to_latitude": 21.006782185230854,
          "to_longitude": 105.84096209733367,
          "from_longitude": 105.84096202327801
        }
      },
      {
        "id": 8590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84082308235158,
            21.00574670812907,
            105.84082403918472,
            21.00575601968175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84082403918472,
              21.00574670812907
            ],
            [
              105.84082311202422,
              21.00575496366568
            ],
            [
              105.84082308235158,
              21.00575601968175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.14_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DODV.8598",
          "diaChiLapD": "Ngõ 24, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00574670812907,
          "to_latitude": 21.00575601968175,
          "to_longitude": 105.84082308235158,
          "from_longitude": 105.84082403918472
        }
      },
      {
        "id": 8591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710704625508,
            21.006565367004573,
            105.83721985573345,
            21.006728024858113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710704625508,
              21.006565367004573
            ],
            [
              105.83710747330899,
              21.006566053161542
            ],
            [
              105.8371387075218,
              21.006616076389317
            ],
            [
              105.83717715028739,
              21.006662172153938
            ],
            [
              105.83718669415748,
              21.0066930543418
            ],
            [
              105.83721985573345,
              21.006728024858113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.31_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8599",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006565367004573,
          "to_latitude": 21.006728024858113,
          "to_longitude": 105.83721985573345,
          "from_longitude": 105.83710704625508
        }
      },
      {
        "id": 8592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83693690827387,
            21.006737829194286,
            105.83708231672504,
            21.006862712012342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83693690827387,
              21.006737829194286
            ],
            [
              105.83693735384398,
              21.006738213583425
            ],
            [
              105.83708231672504,
              21.006862712012342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.30_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8600",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006737829194286,
          "to_latitude": 21.006862712012342,
          "to_longitude": 105.83708231672504,
          "from_longitude": 105.83693690827387
        }
      },
      {
        "id": 8593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83677686011559,
            21.006879084861794,
            105.83693015842115,
            21.007016646423335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83693015842115,
              21.007016646423335
            ],
            [
              105.83678090196814,
              21.006882713114862
            ],
            [
              105.83677686011559,
              21.006879084861794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.29_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8601",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007016646423335,
          "to_latitude": 21.006879084861794,
          "to_longitude": 105.83677686011559,
          "from_longitude": 105.83693015842115
        }
      },
      {
        "id": 8594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667421539917,
            21.00692107826031,
            105.8368467954722,
            21.00711244415575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368467954722,
              21.00711244415575
            ],
            [
              105.83671074108669,
              21.00700133269037
            ],
            [
              105.83669911382579,
              21.00699183644298
            ],
            [
              105.83672262251604,
              21.006969123074366
            ],
            [
              105.83667515072406,
              21.006922008500045
            ],
            [
              105.83667421539917,
              21.00692107826031
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8602",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00711244415575,
          "to_latitude": 21.00692107826031,
          "to_longitude": 105.83667421539917,
          "from_longitude": 105.8368467954722
        }
      },
      {
        "id": 8595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662989800513,
            21.00700133269037,
            105.83671074108669,
            21.007088136261004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83671074108669,
              21.00700133269037
            ],
            [
              105.83662989800513,
              21.007088136261004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8603",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00700133269037,
          "to_latitude": 21.007088136261004,
          "to_longitude": 105.83662989800513,
          "from_longitude": 105.83671074108669
        }
      },
      {
        "id": 8596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83637056890333,
            21.006750742289793,
            105.83648999209812,
            21.007010304790498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646812874039,
              21.007010304790498
            ],
            [
              105.83646664561343,
              21.007008595952325
            ],
            [
              105.83637056890333,
              21.00689776461038
            ],
            [
              105.83637274403293,
              21.00686468594375
            ],
            [
              105.83648999209812,
              21.006750742289793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.27_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8604",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007010304790498,
          "to_latitude": 21.006750742289793,
          "to_longitude": 105.83648999209812,
          "from_longitude": 105.83646812874039
        }
      },
      {
        "id": 8597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687303022661,
            21.006303663168236,
            105.83712101906568,
            21.006483909566352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687303022661,
              21.006303927771857
            ],
            [
              105.83687354676559,
              21.00630427478002
            ],
            [
              105.83687530296905,
              21.006305449369208
            ],
            [
              105.8368957982891,
              21.006303663168236
            ],
            [
              105.83712101906568,
              21.006483909566352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.4_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8605",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006303927771857,
          "to_latitude": 21.006483909566352,
          "to_longitude": 105.83712101906568,
          "from_longitude": 105.83687303022661
        }
      },
      {
        "id": 8598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666132316007,
            21.006353394417747,
            105.8368435148384,
            21.006540980499835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666132316007,
              21.006540980499835
            ],
            [
              105.8367554537426,
              21.00644373082193
            ],
            [
              105.83675553790705,
              21.006443644601735
            ],
            [
              105.8368435148384,
              21.006353394417747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.39_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8606",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006540980499835,
          "to_latitude": 21.006353394417747,
          "to_longitude": 105.8368435148384,
          "from_longitude": 105.83666132316007
        }
      },
      {
        "id": 8599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645902276386,
            21.006319500296385,
            105.83685447397846,
            21.006561405707775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83685447397846,
              21.006319500296385
            ],
            [
              105.83674553662321,
              21.00643128075057
            ],
            [
              105.83674118846396,
              21.00643568096409
            ],
            [
              105.83664076561323,
              21.00653730517684
            ],
            [
              105.83660920897972,
              21.006561405707775
            ],
            [
              105.83645902276386,
              21.006508684734477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.38_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8607",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006319500296385,
          "to_latitude": 21.006508684734477,
          "to_longitude": 105.83645902276386,
          "from_longitude": 105.83685447397846
        }
      },
      {
        "id": 8600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83674596608309,
            21.006435331410405,
            105.83675553790705,
            21.006443644601735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675553790705,
              21.006443644601735
            ],
            [
              105.83674837281079,
              21.006437421382504
            ],
            [
              105.83674600185289,
              21.006435363750654
            ],
            [
              105.83674596608309,
              21.006435331410405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.39_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8608",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006443644601735,
          "to_latitude": 21.006435331410405,
          "to_longitude": 105.83674596608309,
          "from_longitude": 105.83675553790705
        }
      },
      {
        "id": 8601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83674118846396,
            21.00643568096409,
            105.83674386608111,
            21.00643787889467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674118846396,
              21.00643568096409
            ],
            [
              105.83674378395617,
              21.00643781336301
            ],
            [
              105.83674386608111,
              21.00643787889467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.38_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8609",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00643568096409,
          "to_latitude": 21.00643787889467,
          "to_longitude": 105.83674386608111,
          "from_longitude": 105.83674118846396
        }
      },
      {
        "id": 8602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644840006993,
            21.006527200673155,
            105.83680609955624,
            21.006829961906025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83644840006993,
              21.006527200673155
            ],
            [
              105.83651214422255,
              21.00655093122401
            ],
            [
              105.83655013872631,
              21.00656807103744
            ],
            [
              105.83657632639341,
              21.00658197030179
            ],
            [
              105.83658219389882,
              21.006585084683028
            ],
            [
              105.83660251502715,
              21.006595869557202
            ],
            [
              105.8366440516868,
              21.00667503627908
            ],
            [
              105.8367585137607,
              21.006754463161464
            ],
            [
              105.83680609955624,
              21.006829961906025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.37_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8610",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006527200673155,
          "to_latitude": 21.006829961906025,
          "to_longitude": 105.83680609955624,
          "from_longitude": 105.83644840006993
        }
      },
      {
        "id": 8603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83604047378428,
            21.006488076710554,
            105.83620511869952,
            21.006628125801225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620511869952,
              21.006628125801225
            ],
            [
              105.83604047378428,
              21.006488076710554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.33_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8611",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006628125801225,
          "to_latitude": 21.006488076710554,
          "to_longitude": 105.83604047378428,
          "from_longitude": 105.83620511869952
        }
      },
      {
        "id": 8604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608440960599,
            21.00651009391365,
            105.83639172899036,
            21.006784874756455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608440960599,
              21.006784874756455
            ],
            [
              105.83619499728962,
              21.006669806085966
            ],
            [
              105.83624041358283,
              21.006622550188833
            ],
            [
              105.83630719017923,
              21.006556981199434
            ],
            [
              105.83633589422114,
              21.0065332777088
            ],
            [
              105.83636199144243,
              21.00652387695045
            ],
            [
              105.83639172899036,
              21.00651009391365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.34_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8612",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006784874756455,
          "to_latitude": 21.00651009391365,
          "to_longitude": 105.83639172899036,
          "from_longitude": 105.83608440960599
        }
      },
      {
        "id": 8605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611885075392,
            21.00687314890285,
            105.8361375133888,
            21.006885363681615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611885075392,
              21.006885363681615
            ],
            [
              105.83613050088304,
              21.00687773832043
            ],
            [
              105.83613730541637,
              21.006873284505186
            ],
            [
              105.8361374903849,
              21.006873163467464
            ],
            [
              105.8361375133888,
              21.00687314890285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.21_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8613",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006885363681615,
          "to_latitude": 21.00687314890285,
          "to_longitude": 105.8361375133888,
          "from_longitude": 105.83611885075392
        }
      },
      {
        "id": 8606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589214513056,
            21.006661434976973,
            105.83591167059116,
            21.006682969834475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589214513056,
              21.006682969834475
            ],
            [
              105.83590538007407,
              21.00666837283775
            ],
            [
              105.83591111515085,
              21.006662047382182
            ],
            [
              105.83591165242669,
              21.006661454937145
            ],
            [
              105.83591167059116,
              21.006661434976973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.19_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8614",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006682969834475,
          "to_latitude": 21.006661434976973,
          "to_longitude": 105.83591167059116,
          "from_longitude": 105.83589214513056
        }
      },
      {
        "id": 8607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586744468958,
            21.00661935776823,
            105.83603652560865,
            21.006764209359176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586744468958,
              21.00661935776823
            ],
            [
              105.83591899738354,
              21.006663536020795
            ],
            [
              105.83595229209418,
              21.006692068852498
            ],
            [
              105.83603652560865,
              21.006764209359176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.20_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8615",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00661935776823,
          "to_latitude": 21.006764209359176,
          "to_longitude": 105.83603652560865,
          "from_longitude": 105.83586744468958
        }
      },
      {
        "id": 8608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83622868104413,
            21.006376828506912,
            105.83636471076933,
            21.006516645914054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83622868104413,
              21.006376828506912
            ],
            [
              105.83635093437125,
              21.006482922153346
            ],
            [
              105.83636396379262,
              21.00651481965066
            ],
            [
              105.83636471076933,
              21.006516645914054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.35_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8616",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006376828506912,
          "to_latitude": 21.006516645914054,
          "to_longitude": 105.83636471076933,
          "from_longitude": 105.83622868104413
        }
      },
      {
        "id": 8609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83641287669833,
            21.006302446565353,
            105.8365728203358,
            21.006505582242973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641287669833,
              21.006505582242973
            ],
            [
              105.83641300380405,
              21.006505434392583
            ],
            [
              105.83642112887817,
              21.00649595937019
            ],
            [
              105.83654588515607,
              21.0063366558443
            ],
            [
              105.8365728203358,
              21.006302446565353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.36_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8617",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006505582242973,
          "to_latitude": 21.006302446565353,
          "to_longitude": 105.8365728203358,
          "from_longitude": 105.83641287669833
        }
      },
      {
        "id": 8610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363469242343,
            21.006092764377456,
            105.83654588515607,
            21.0063366558443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635252667055,
              21.006092764377456
            ],
            [
              105.8363469242343,
              21.006163097156744
            ],
            [
              105.83647421746558,
              21.0062593135719
            ],
            [
              105.83654588515607,
              21.0063366558443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.36_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8618",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006092764377456,
          "to_latitude": 21.0063366558443,
          "to_longitude": 105.83654588515607,
          "from_longitude": 105.83635252667055
        }
      },
      {
        "id": 8611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585867959434,
            21.005829054685595,
            105.83642976356502,
            21.005889684796582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585867959434,
              21.005886365717288
            ],
            [
              105.8358644050733,
              21.005840501619982
            ],
            [
              105.83605707068318,
              21.00585835772007
            ],
            [
              105.8361990438412,
              21.005842929529468
            ],
            [
              105.83633165986119,
              21.005829054685595
            ],
            [
              105.83642976356502,
              21.005889684796582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.43_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8619",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005886365717288,
          "to_latitude": 21.005889684796582,
          "to_longitude": 105.83642976356502,
          "from_longitude": 105.83585867959434
        }
      },
      {
        "id": 8612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585328615466,
            21.005885982141383,
            105.83585867959434,
            21.005886365717288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585328615466,
              21.005885982141383
            ],
            [
              105.83585403290557,
              21.005886042602942
            ],
            [
              105.83585867959434,
              21.005886365717288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.43_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8620",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005885982141383,
          "to_latitude": 21.005886365717288,
          "to_longitude": 105.83585867959434,
          "from_longitude": 105.83585328615466
        }
      },
      {
        "id": 8613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644331980645,
            21.005859630528075,
            105.83682847573179,
            21.006236932449333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83645096984999,
              21.005859630528075
            ],
            [
              105.8364509651968,
              21.005859658550918
            ],
            [
              105.83645064389569,
              21.005861723096544
            ],
            [
              105.83644331980645,
              21.005908939967473
            ],
            [
              105.83651461048284,
              21.00597320902561
            ],
            [
              105.83682847573179,
              21.006236932449333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.45_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8621",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005859630528075,
          "to_latitude": 21.006236932449333,
          "to_longitude": 105.83682847573179,
          "from_longitude": 105.83645096984999
        }
      },
      {
        "id": 8614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562005473429,
            21.005918293318334,
            105.83574809505956,
            21.00641249472462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83574383266777,
              21.005918293318334
            ],
            [
              105.83562005473429,
              21.006288288665832
            ],
            [
              105.83563824121185,
              21.006317013163134
            ],
            [
              105.83573379629394,
              21.006408612426814
            ],
            [
              105.83574589384979,
              21.00641189856664
            ],
            [
              105.83574809505956,
              21.00641249472462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8622",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005918293318334,
          "to_latitude": 21.00641249472462,
          "to_longitude": 105.83574809505956,
          "from_longitude": 105.83574383266777
        }
      },
      {
        "id": 8615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573379629394,
            21.006164343053435,
            105.83588100348344,
            21.006408612426814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583904255894,
              21.006164343053435
            ],
            [
              105.83588100348344,
              21.006304404251583
            ],
            [
              105.83573379629394,
              21.006408612426814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8623",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006164343053435,
          "to_latitude": 21.006408612426814,
          "to_longitude": 105.83573379629394,
          "from_longitude": 105.83583904255894
        }
      },
      {
        "id": 8616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584065628871,
            21.005888159487995,
            105.83591661986003,
            21.00632737266191
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591661986003,
              21.00632737266191
            ],
            [
              105.83588961027355,
              21.00628280379748
            ],
            [
              105.83584065628871,
              21.00610885360784
            ],
            [
              105.83584189475523,
              21.00609979630426
            ],
            [
              105.83585476207563,
              21.00600556102803
            ],
            [
              105.83585776714813,
              21.005888159487995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.42_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8624",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00632737266191,
          "to_latitude": 21.005888159487995,
          "to_longitude": 105.83585776714813,
          "from_longitude": 105.83591661986003
        }
      },
      {
        "id": 8617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83606575024204,
            21.005727908682747,
            105.8364952570937,
            21.005851886410095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83606575024204,
              21.00584033186583
            ],
            [
              105.83620994573333,
              21.005821699848013
            ],
            [
              105.83626265846195,
              21.005810850529823
            ],
            [
              105.83627298867499,
              21.005808724159163
            ],
            [
              105.83632424224974,
              21.005813412047733
            ],
            [
              105.83643642355682,
              21.005851886410095
            ],
            [
              105.8364952570937,
              21.005727908682747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.44_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8625",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00584033186583,
          "to_latitude": 21.005727908682747,
          "to_longitude": 105.8364952570937,
          "from_longitude": 105.83606575024204
        }
      },
      {
        "id": 8618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83637261388384,
            21.005544684299974,
            105.83653464773681,
            21.005667863755342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83637261388384,
              21.005544684299974
            ],
            [
              105.83653097185686,
              21.005665346469538
            ],
            [
              105.83653464773681,
              21.005667863755342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.47_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8626",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005544684299974,
          "to_latitude": 21.005667863755342,
          "to_longitude": 105.83653464773681,
          "from_longitude": 105.83637261388384
        }
      },
      {
        "id": 8619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83638800408967,
            21.00508498305592,
            105.83659671279284,
            21.00545360448599
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83659671279284,
              21.00545360448599
            ],
            [
              105.83659428394574,
              21.00545168442607
            ],
            [
              105.8365608154525,
              21.005428527659493
            ],
            [
              105.83638800408967,
              21.00528843409452
            ],
            [
              105.83640443590356,
              21.005225991053273
            ],
            [
              105.83645958655241,
              21.00508498305592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.48_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8627",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00545360448599,
          "to_latitude": 21.00508498305592,
          "to_longitude": 105.83645958655241,
          "from_longitude": 105.83659671279284
        }
      },
      {
        "id": 8620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645958655241,
            21.005010348734007,
            105.83662891903144,
            21.005117078216777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365577308171,
              21.005117078216777
            ],
            [
              105.83645958655241,
              21.00508498305592
            ],
            [
              105.83648456197365,
              21.005010348734007
            ],
            [
              105.83662891903144,
              21.0050474247214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.48_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8628",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005117078216777,
          "to_latitude": 21.0050474247214,
          "to_longitude": 105.83662891903144,
          "from_longitude": 105.8365577308171
        }
      },
      {
        "id": 8621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.836603913924,
            21.005358115336506,
            105.83662982883185,
            21.005370918522484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.836603913924,
              21.005358115336506
            ],
            [
              105.83661982572298,
              21.005365976471275
            ],
            [
              105.8366264510684,
              21.005369251514516
            ],
            [
              105.83662982883185,
              21.005370918522484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.49_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8629",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005358115336506,
          "to_latitude": 21.005370918522484,
          "to_longitude": 105.83662982883185,
          "from_longitude": 105.836603913924
        }
      },
      {
        "id": 8622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364709347432,
            21.004770041243756,
            105.83680778683848,
            21.00479923401831
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83680778683848,
              21.00478602630061
            ],
            [
              105.83671659814797,
              21.00478156197307
            ],
            [
              105.83670207576971,
              21.00478085117242
            ],
            [
              105.8366578967936,
              21.00479923401831
            ],
            [
              105.8364709347432,
              21.004770041243756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.50_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8630",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00478602630061,
          "to_latitude": 21.004770041243756,
          "to_longitude": 105.8364709347432,
          "from_longitude": 105.83680778683848
        }
      },
      {
        "id": 8623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669325213113,
            21.00478156197307,
            105.83671659814797,
            21.004893263909988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669325213113,
              21.004893263909988
            ],
            [
              105.83671659814797,
              21.00478156197307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.50_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8631",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004893263909988,
          "to_latitude": 21.00478156197307,
          "to_longitude": 105.83671659814797,
          "from_longitude": 105.83669325213113
        }
      },
      {
        "id": 8624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612176711149,
            21.00654115481563,
            105.83629176483477,
            21.006717896815115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612176711149,
              21.006717896815115
            ],
            [
              105.83617667353404,
              21.006658764768563
            ],
            [
              105.83620436433272,
              21.00662894331369
            ],
            [
              105.83620511869952,
              21.006628125801225
            ],
            [
              105.83621162430838,
              21.006621124550406
            ],
            [
              105.83629176483477,
              21.00654115481563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.33_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8632",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006717896815115,
          "to_latitude": 21.00654115481563,
          "to_longitude": 105.83629176483477,
          "from_longitude": 105.83612176711149
        }
      },
      {
        "id": 8625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710866334475,
            21.00648839911562,
            105.83757949031504,
            21.006581681079464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83757949031504,
              21.00648839911562
            ],
            [
              105.83721031359956,
              21.006547630012935
            ],
            [
              105.83716352498887,
              21.006548322625594
            ],
            [
              105.83713495285137,
              21.006581681079464
            ],
            [
              105.83710929966364,
              21.006564111276532
            ],
            [
              105.83710866334475,
              21.006563673632566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.32_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8633",
          "diaChiLapD": "Ngõ 5, Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00648839911562,
          "to_latitude": 21.006563673632566,
          "to_longitude": 105.83710866334475,
          "from_longitude": 105.83757949031504
        }
      },
      {
        "id": 8626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83658219389882,
            21.006561104160582,
            105.83659191080659,
            21.006585084683028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83658219389882,
              21.006585084683028
            ],
            [
              105.8365885832766,
              21.006569315479656
            ],
            [
              105.83659122574026,
              21.006562792043102
            ],
            [
              105.83659191080659,
              21.006561104160582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8634",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006585084683028,
          "to_latitude": 21.006561104160582,
          "to_longitude": 105.83659191080659,
          "from_longitude": 105.83658219389882
        }
      },
      {
        "id": 8627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610188098423,
            21.006820919739024,
            105.83629680733463,
            21.00706159910771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83610188098423,
              21.006820919739024
            ],
            [
              105.83614473476881,
              21.00686133536667
            ],
            [
              105.83614715693557,
              21.006868958429052
            ],
            [
              105.83615250548709,
              21.006885792656014
            ],
            [
              105.8361957331409,
              21.00694513622655
            ],
            [
              105.83625401410143,
              21.007018431097435
            ],
            [
              105.83629680733463,
              21.00706159910771
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.22_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8635",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006820919739024,
          "to_latitude": 21.00706159910771,
          "to_longitude": 105.83629680733463,
          "from_longitude": 105.83610188098423
        }
      },
      {
        "id": 8628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607416495869,
            21.00683431073183,
            105.83625044783861,
            21.00707994922398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83607416495869,
              21.00683431073183
            ],
            [
              105.83609947214573,
              21.006860229096624
            ],
            [
              105.83611885075392,
              21.006885363681615
            ],
            [
              105.83616354433589,
              21.00694333257567
            ],
            [
              105.83619336114634,
              21.00697448192606
            ],
            [
              105.8362353085555,
              21.0070348569357
            ],
            [
              105.8362492563255,
              21.00707171043936
            ],
            [
              105.83625044783861,
              21.00707994922398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.21_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8636",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00683431073183,
          "to_latitude": 21.00707994922398,
          "to_longitude": 105.83625044783861,
          "from_longitude": 105.83607416495869
        }
      },
      {
        "id": 8629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83613850766194,
            21.006868958429052,
            105.83614715693557,
            21.006874992016716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83613850766194,
              21.006874992016716
            ],
            [
              105.83613863125292,
              21.00687490379666
            ],
            [
              105.83614200492616,
              21.006872551481067
            ],
            [
              105.83614715693557,
              21.006868958429052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.22_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8637",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006874992016716,
          "to_latitude": 21.006868958429052,
          "to_longitude": 105.83614715693557,
          "from_longitude": 105.83613850766194
        }
      },
      {
        "id": 8630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573389354945,
            21.006545423490213,
            105.83604945308198,
            21.006809582599015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604945308198,
              21.006809582599015
            ],
            [
              105.83601036967498,
              21.006776031904433
            ],
            [
              105.8359174660499,
              21.00670290248706
            ],
            [
              105.83589214513056,
              21.006682969834475
            ],
            [
              105.83586409784623,
              21.006660891859788
            ],
            [
              105.83573389354945,
              21.006545423490213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.19_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8638",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006809582599015,
          "to_latitude": 21.006545423490213,
          "to_longitude": 105.83573389354945,
          "from_longitude": 105.83604945308198
        }
      },
      {
        "id": 8631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591664444721,
            21.006663536020795,
            105.83591899738354,
            21.006666093769876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591899738354,
              21.006663536020795
            ],
            [
              105.83591691595306,
              21.006665794371965
            ],
            [
              105.83591664444721,
              21.006666093769876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.20_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8639",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006663536020795,
          "to_latitude": 21.006666093769876,
          "to_longitude": 105.83591664444721,
          "from_longitude": 105.83591899738354
        }
      },
      {
        "id": 8632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83618213911699,
            21.00665879843075,
            105.83619499728962,
            21.006669806085966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619499728962,
              21.006669806085966
            ],
            [
              105.83618457080374,
              21.006660880159227
            ],
            [
              105.8361822985977,
              21.00665893674386
            ],
            [
              105.83618213911699,
              21.00665879843075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.34_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8640",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006669806085966,
          "to_latitude": 21.00665879843075,
          "to_longitude": 105.83618213911699,
          "from_longitude": 105.83619499728962
        }
      },
      {
        "id": 8633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585294717679,
            21.005887637611835,
            105.83585776714813,
            21.005888159487995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585776714813,
              21.005888159487995
            ],
            [
              105.8358535997622,
              21.00588771569714
            ],
            [
              105.83585294717679,
              21.005887637611835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.42_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8641",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005888159487995,
          "to_latitude": 21.005887637611835,
          "to_longitude": 105.83585294717679,
          "from_longitude": 105.83585776714813
        }
      },
      {
        "id": 8634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83626265846195,
            21.005810850529823,
            105.83626793431299,
            21.00583052395025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83626793431299,
              21.00583052395025
            ],
            [
              105.836267414705,
              21.005828589988372
            ],
            [
              105.83626265846195,
              21.005810850529823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.44_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8642",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00583052395025,
          "to_latitude": 21.005810850529823,
          "to_longitude": 105.83626265846195,
          "from_longitude": 105.83626793431299
        }
      },
      {
        "id": 8635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659459081986,
            21.005025675476595,
            105.83674201352682,
            21.005377840637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83659459081986,
              21.005377840637
            ],
            [
              105.836603913924,
              21.005358115336506
            ],
            [
              105.83667667340403,
              21.005204173241165
            ],
            [
              105.83667693339899,
              21.00520370770524
            ],
            [
              105.83671695693825,
              21.005104361197105
            ],
            [
              105.83674201352682,
              21.005025675476595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.49_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8643",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005377840637,
          "to_latitude": 21.005025675476595,
          "to_longitude": 105.83674201352682,
          "from_longitude": 105.83659459081986
        }
      },
      {
        "id": 8636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676720325266,
            21.004307382806306,
            105.8368704295785,
            21.004963205648842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678409610947,
              21.004963205648842
            ],
            [
              105.83678201749952,
              21.004962685675068
            ],
            [
              105.83676720325266,
              21.004958969478434
            ],
            [
              105.83679870149567,
              21.00483855016706
            ],
            [
              105.83680778683848,
              21.00478602630061
            ],
            [
              105.8368644465746,
              21.004458454986967
            ],
            [
              105.8368704295785,
              21.004307382806306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.50_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DODV.8644",
          "diaChiLapD": "Ngõ 38, Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004963205648842,
          "to_latitude": 21.004307382806306,
          "to_longitude": 105.8368704295785,
          "from_longitude": 105.83678409610947
        }
      },
      {
        "id": 8637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83654504348243,
            21.007272200729265,
            105.83701212572265,
            21.00766032765253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701212572265,
              21.00766032765253
            ],
            [
              105.83673639885995,
              21.007423724398013
            ],
            [
              105.83654504348243,
              21.007272200729265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.23_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8645",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00766032765253,
          "to_latitude": 21.007272200729265,
          "to_longitude": 105.83654504348243,
          "from_longitude": 105.83701212572265
        }
      },
      {
        "id": 8638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83709861910721,
            21.007641586924823,
            105.83741389888976,
            21.007706269001407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709861910721,
              21.00767062260213
            ],
            [
              105.83715555180676,
              21.00765953094522
            ],
            [
              105.83721464085362,
              21.007644440019824
            ],
            [
              105.83723958137773,
              21.007643199129422
            ],
            [
              105.83724321341026,
              21.007643018680394
            ],
            [
              105.83726068383248,
              21.007641586924823
            ],
            [
              105.83734751370191,
              21.007664950660278
            ],
            [
              105.83741389888976,
              21.007706269001407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8646",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00767062260213,
          "to_latitude": 21.007706269001407,
          "to_longitude": 105.83741389888976,
          "from_longitude": 105.83709861910721
        }
      },
      {
        "id": 8639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706365667284,
            21.00761872143127,
            105.83738287577943,
            21.007659488281487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706365667284,
              21.00764909820699
            ],
            [
              105.83723380340273,
              21.007624592217937
            ],
            [
              105.8372423063624,
              21.007623367161393
            ],
            [
              105.83726938443047,
              21.00761872143127
            ],
            [
              105.83738287577943,
              21.007659488281487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.25_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8647",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00764909820699,
          "to_latitude": 21.007659488281487,
          "to_longitude": 105.83738287577943,
          "from_longitude": 105.83706365667284
        }
      },
      {
        "id": 8640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83672895876136,
            21.007423724398013,
            105.83673639885995,
            21.007432017358845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673639885995,
              21.007423724398013
            ],
            [
              105.83673448983608,
              21.007425850943633
            ],
            [
              105.836733360873,
              21.00742711017077
            ],
            [
              105.83672945202775,
              21.007431467579234
            ],
            [
              105.83672895876136,
              21.007432017358845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8648",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007423724398013,
          "to_latitude": 21.007432017358845,
          "to_longitude": 105.83672895876136,
          "from_longitude": 105.83673639885995
        }
      },
      {
        "id": 8641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372368600163,
            21.007629545820908,
            105.83723958137773,
            21.007643199129422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723958137773,
              21.007643199129422
            ],
            [
              105.83723875309077,
              21.007639042950203
            ],
            [
              105.83723785949705,
              21.007634560124842
            ],
            [
              105.83723697286815,
              21.007630118813548
            ],
            [
              105.8372368600163,
              21.007629545820908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8649",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007643199129422,
          "to_latitude": 21.007629545820908,
          "to_longitude": 105.8372368600163,
          "from_longitude": 105.83723958137773
        }
      },
      {
        "id": 8642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723380340273,
            21.007624592217937,
            105.83723484738144,
            21.007629823980206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723380340273,
              21.007624592217937
            ],
            [
              105.83723480586465,
              21.007629623668517
            ],
            [
              105.83723484738144,
              21.007629823980206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.25_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8650",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007624592217937,
          "to_latitude": 21.007629823980206,
          "to_longitude": 105.83723484738144,
          "from_longitude": 105.83723380340273
        }
      },
      {
        "id": 8643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704442546072,
            21.007656124146237,
            105.8374947302164,
            21.007989927943168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374485371532,
              21.007989927943168
            ],
            [
              105.8374947302164,
              21.007935187387307
            ],
            [
              105.83725476814104,
              21.007756383403034
            ],
            [
              105.83715461329327,
              21.007789237762683
            ],
            [
              105.83705608222446,
              21.007707452183777
            ],
            [
              105.83704457143324,
              21.007656771943807
            ],
            [
              105.83704442546072,
              21.007656124146237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8651",
          "diaChiLapD": "Ngõ 9, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007989927943168,
          "to_latitude": 21.007656124146237,
          "to_longitude": 105.83704442546072,
          "from_longitude": 105.8374485371532
        }
      },
      {
        "id": 8644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490197126928,
            21.007582702791744,
            105.83494520932513,
            21.007627413221226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494520932513,
              21.007582702791744
            ],
            [
              105.83491451901148,
              21.00761443824924
            ],
            [
              105.83491350620216,
              21.00761548554017
            ],
            [
              105.83490197126928,
              21.007627413221226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8652",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007582702791744,
          "to_latitude": 21.007627413221226,
          "to_longitude": 105.83490197126928,
          "from_longitude": 105.83494520932513
        }
      },
      {
        "id": 8645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490569502824,
            21.007585309859792,
            105.8349480815915,
            21.007630406305925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349480815915,
              21.007585309859792
            ],
            [
              105.83491806666174,
              21.007617243423574
            ],
            [
              105.8349168226153,
              21.00761856733271
            ],
            [
              105.83490569502824,
              21.007630406305925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8653",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007585309859792,
          "to_latitude": 21.007630406305925,
          "to_longitude": 105.83490569502824,
          "from_longitude": 105.8349480815915
        }
      },
      {
        "id": 8646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83514261455791,
            21.007778204482626,
            105.8351825854365,
            21.007820774551803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351825854365,
              21.007778204482626
            ],
            [
              105.83515453095356,
              21.00780808356387
            ],
            [
              105.83515401363859,
              21.007808634360327
            ],
            [
              105.83514261455791,
              21.007820774551803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8654",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007778204482626,
          "to_latitude": 21.007820774551803,
          "to_longitude": 105.83514261455791,
          "from_longitude": 105.8351825854365
        }
      },
      {
        "id": 8647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515251335336,
            21.007786764910236,
            105.83519093704582,
            21.007828728550216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83519093704582,
              21.007786764910236
            ],
            [
              105.83516469948364,
              21.007815419335007
            ],
            [
              105.83516427212106,
              21.007815886593324
            ],
            [
              105.83515251335336,
              21.007828728550216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8655",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007786764910236,
          "to_latitude": 21.007828728550216,
          "to_longitude": 105.83515251335336,
          "from_longitude": 105.83519093704582
        }
      },
      {
        "id": 8648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83464942740751,
            21.007856321672207,
            105.83468589697,
            21.007896162974113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83468589697,
              21.007856321672207
            ],
            [
              105.8346607119853,
              21.007883834601966
            ],
            [
              105.83466011252591,
              21.007884489670804
            ],
            [
              105.83464942740751,
              21.007896162974113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8656",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007856321672207,
          "to_latitude": 21.007896162974113,
          "to_longitude": 105.83464942740751,
          "from_longitude": 105.83468589697
        }
      },
      {
        "id": 8649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465189358088,
            21.00785927240212,
            105.8346884970113,
            21.007898219264803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346884970113,
              21.00785927240212
            ],
            [
              105.83466345628707,
              21.00788591662516
            ],
            [
              105.834662788837,
              21.007886626220976
            ],
            [
              105.83465189358088,
              21.007898219264803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8657",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00785927240212,
          "to_latitude": 21.007898219264803,
          "to_longitude": 105.83465189358088,
          "from_longitude": 105.8346884970113
        }
      },
      {
        "id": 8650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488418485013,
            21.00805050577132,
            105.83492146040669,
            21.008091911849906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83492146040669,
              21.00805050577132
            ],
            [
              105.83489715653695,
              21.00807750228355
            ],
            [
              105.83489459546854,
              21.00808034736274
            ],
            [
              105.83488418485013,
              21.008091911849906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8658",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00805050577132,
          "to_latitude": 21.008091911849906,
          "to_longitude": 105.83488418485013,
          "from_longitude": 105.83492146040669
        }
      },
      {
        "id": 8651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489468544481,
            21.008060117305952,
            105.8349340992294,
            21.00810066768432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349340992294,
              21.008060117305952
            ],
            [
              105.8349084415393,
              21.008086515300928
            ],
            [
              105.83490560386177,
              21.008089434898583
            ],
            [
              105.83489468544481,
              21.00810066768432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8659",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008060117305952,
          "to_latitude": 21.00810066768432,
          "to_longitude": 105.83489468544481,
          "from_longitude": 105.8349340992294
        }
      },
      {
        "id": 8652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83389560360492,
            21.007607682529596,
            105.83394013402899,
            21.00765529207629
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394013402899,
              21.007607682529596
            ],
            [
              105.83390976094897,
              21.00764028967651
            ],
            [
              105.83390515371964,
              21.00764517250899
            ],
            [
              105.83389875635442,
              21.007651947338363
            ],
            [
              105.83389560360492,
              21.00765529207629
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8660",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007607682529596,
          "to_latitude": 21.00765529207629,
          "to_longitude": 105.83389560360492,
          "from_longitude": 105.83394013402899
        }
      },
      {
        "id": 8653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338907722634,
            21.00760425618947,
            105.83393524015267,
            21.0076513487835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83393524015267,
              21.00760425618947
            ],
            [
              105.83390483073238,
              21.007636901449064
            ],
            [
              105.8339004445663,
              21.00764140745639
            ],
            [
              105.83390030014367,
              21.007641555389828
            ],
            [
              105.8338944994282,
              21.00764751529567
            ],
            [
              105.8338907722634,
              21.0076513487835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8661",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00760425618947,
          "to_latitude": 21.0076513487835,
          "to_longitude": 105.8338907722634,
          "from_longitude": 105.83393524015267
        }
      },
      {
        "id": 8654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83407285795754,
            21.007754724173235,
            105.83411426868057,
            21.00779996286235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411426868057,
              21.007754724173235
            ],
            [
              105.83408386174503,
              21.007787295387374
            ],
            [
              105.83407869156747,
              21.00778659634039
            ],
            [
              105.83407864633995,
              21.007786592949163
            ],
            [
              105.83407756931045,
              21.00779408506385
            ],
            [
              105.83407489742706,
              21.007797415706293
            ],
            [
              105.83407285795754,
              21.00779996286235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8662",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007754724173235,
          "to_latitude": 21.00779996286235,
          "to_longitude": 105.83407285795754,
          "from_longitude": 105.83411426868057
        }
      },
      {
        "id": 8655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83406044235058,
            21.00774468277063,
            105.83410234030305,
            21.007789829006168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83410234030305,
              21.00774468277063
            ],
            [
              105.83407198502113,
              21.007777202245666
            ],
            [
              105.83406669699197,
              21.007776585969452
            ],
            [
              105.83406568969575,
              21.007783991032667
            ],
            [
              105.83406185143747,
              21.00778825771364
            ],
            [
              105.83406044235058,
              21.007789829006168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8663",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00774468277063,
          "to_latitude": 21.007789829006168,
          "to_longitude": 105.83406044235058,
          "from_longitude": 105.83410234030305
        }
      },
      {
        "id": 8656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373832287363,
            21.007488382128212,
            105.83377415962144,
            21.00752719497949
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377415962144,
              21.007488382128212
            ],
            [
              105.83374825015397,
              21.007514324154386
            ],
            [
              105.83374446434401,
              21.00751383508884
            ],
            [
              105.83374415081634,
              21.007516267204846
            ],
            [
              105.8337434901277,
              21.00752131044029
            ],
            [
              105.83373998601444,
              21.007525298186938
            ],
            [
              105.83373832287363,
              21.00752719497949
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.6_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8664",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007488382128212,
          "to_latitude": 21.00752719497949,
          "to_longitude": 105.83373832287363,
          "from_longitude": 105.83377415962144
        }
      },
      {
        "id": 8657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373203755994,
            21.00748277886137,
            105.83376818981284,
            21.007522109902116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376818981284,
              21.00748277886137
            ],
            [
              105.83374302965296,
              21.007509759538
            ],
            [
              105.83373776555023,
              21.007509119650155
            ],
            [
              105.83373751226331,
              21.007510967987308
            ],
            [
              105.83373673432298,
              21.007516548313166
            ],
            [
              105.83373372416511,
              21.007520108220493
            ],
            [
              105.83373203755994,
              21.007522109902116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.7_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8665",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00748277886137,
          "to_latitude": 21.007522109902116,
          "to_longitude": 105.83373203755994,
          "from_longitude": 105.83376818981284
        }
      },
      {
        "id": 8658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467514518249,
            21.006315880045683,
            105.8352200723676,
            21.006959016834514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467514518249,
              21.006315880045683
            ],
            [
              105.8352200723676,
              21.006758464260997
            ],
            [
              105.83504047425058,
              21.00694985387614
            ],
            [
              105.8350318760054,
              21.006959016834514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8666",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 104.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006315880045683,
          "to_latitude": 21.006959016834514,
          "to_longitude": 105.8350318760054,
          "from_longitude": 105.83467514518249
        }
      },
      {
        "id": 8659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453473703509,
            21.00649868867775,
            105.83500570421984,
            21.0069135594603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453473703509,
              21.00649868867775
            ],
            [
              105.83460445653814,
              21.006555880447472
            ],
            [
              105.83460465664379,
              21.006556232627325
            ],
            [
              105.83460689568982,
              21.00656017325866
            ],
            [
              105.83460755040294,
              21.006561325273367
            ],
            [
              105.83461323884342,
              21.00657133672864
            ],
            [
              105.83463709998068,
              21.00661332898704
            ],
            [
              105.83472806397629,
              21.0066883925074
            ],
            [
              105.83475848647885,
              21.006701221736698
            ],
            [
              105.83476515493518,
              21.00670670954233
            ],
            [
              105.83476797943464,
              21.00670903323573
            ],
            [
              105.83491259344338,
              21.006828023795617
            ],
            [
              105.8349253548506,
              21.006838768281725
            ],
            [
              105.8349281230458,
              21.006841344248418
            ],
            [
              105.83493130217482,
              21.00684430387629
            ],
            [
              105.83500570421984,
              21.0069135594603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8667",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00649868867775,
          "to_latitude": 21.0069135594603,
          "to_longitude": 105.83500570421984,
          "from_longitude": 105.83453473703509
        }
      },
      {
        "id": 8660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427326955956,
            21.00667531378394,
            105.83482456601877,
            21.00711918943982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83427326955956,
              21.00667531378394
            ],
            [
              105.83440137834575,
              21.0067803080273
            ],
            [
              105.83440597592329,
              21.00678407632354
            ],
            [
              105.83440960914663,
              21.006787053606107
            ],
            [
              105.83440974150311,
              21.00678715773133
            ],
            [
              105.83456701170115,
              21.006910983825644
            ],
            [
              105.83457036217044,
              21.0069136210719
            ],
            [
              105.83457299676114,
              21.006915696409504
            ],
            [
              105.83458490214659,
              21.00692506950694
            ],
            [
              105.83471398155277,
              21.007026888418366
            ],
            [
              105.83473438565488,
              21.00704257220511
            ],
            [
              105.83473645388479,
              21.007044161672987
            ],
            [
              105.83474950954009,
              21.00705419750311
            ],
            [
              105.83482456601877,
              21.00711918943982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8668",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00667531378394,
          "to_latitude": 21.00711918943982,
          "to_longitude": 105.83482456601877,
          "from_longitude": 105.83427326955956
        }
      },
      {
        "id": 8661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473134017983,
            21.007024165668607,
            105.83476047433575,
            21.007055685718115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83476047433575,
              21.007024165668607
            ],
            [
              105.8347435798208,
              21.007042316657266
            ],
            [
              105.83473632456304,
              21.007043392759233
            ],
            [
              105.83473645388479,
              21.007044161672987
            ],
            [
              105.83473728551819,
              21.00704910546293
            ],
            [
              105.83473493185465,
              21.007051710167577
            ],
            [
              105.83473134017983,
              21.007055685718115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8669",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007024165668607,
          "to_latitude": 21.007055685718115,
          "to_longitude": 105.83473134017983,
          "from_longitude": 105.83476047433575
        }
      },
      {
        "id": 8662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83472968501056,
            21.007022831328257,
            105.83475832095579,
            21.00705434351441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475832095579,
              21.007022831328257
            ],
            [
              105.83474154978141,
              21.007040848937603
            ],
            [
              105.83473425939954,
              21.00704183579242
            ],
            [
              105.83473438565488,
              21.00704257220511
            ],
            [
              105.83473525547375,
              21.007047636839975
            ],
            [
              105.83473326806835,
              21.007050029296636
            ],
            [
              105.83472968501056,
              21.00705434351441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8670",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007022831328257,
          "to_latitude": 21.00705434351441,
          "to_longitude": 105.83472968501056,
          "from_longitude": 105.83475832095579
        }
      },
      {
        "id": 8663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456474906865,
            21.006879188180378,
            105.83460188387379,
            21.00691975259556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460188387379,
              21.006879188180378
            ],
            [
              105.83457036217044,
              21.0069136210719
            ],
            [
              105.83456699966166,
              21.006917293817935
            ],
            [
              105.83456474906865,
              21.00691975259556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8671",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006879188180378,
          "to_latitude": 21.00691975259556,
          "to_longitude": 105.83456474906865,
          "from_longitude": 105.83460188387379
        }
      },
      {
        "id": 8664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456141083221,
            21.006877069898056,
            105.83459824484132,
            21.006917066515037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459824484132,
              21.006877069898056
            ],
            [
              105.83456701170115,
              21.006910983825644
            ],
            [
              105.83456338901458,
              21.006914917976413
            ],
            [
              105.83456141083221,
              21.006917066515037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8672",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006877069898056,
          "to_latitude": 21.006917066515037,
          "to_longitude": 105.83456141083221,
          "from_longitude": 105.83459824484132
        }
      },
      {
        "id": 8665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344070163842,
            21.006749603125694,
            105.83444682957204,
            21.006792846058286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444682957204,
              21.006749603125694
            ],
            [
              105.83441551412977,
              21.00678324826853
            ],
            [
              105.83440996708218,
              21.006782749787988
            ],
            [
              105.83440974150311,
              21.00678715773133
            ],
            [
              105.83440959404874,
              21.006790046969527
            ],
            [
              105.83440851173388,
              21.006791221953065
            ],
            [
              105.8344070163842,
              21.006792846058286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.6_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8673",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006749603125694,
          "to_latitude": 21.006792846058286,
          "to_longitude": 105.8344070163842,
          "from_longitude": 105.83444682957204
        }
      },
      {
        "id": 8666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440343648465,
            21.006747343803763,
            105.83444321765508,
            21.006789944385183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444321765508,
              21.006747343803763
            ],
            [
              105.83441190124556,
              21.006780988047446
            ],
            [
              105.8344063412118,
              21.006780402920807
            ],
            [
              105.83440597592329,
              21.00678407632354
            ],
            [
              105.83440564254693,
              21.00678743434411
            ],
            [
              105.83440434356869,
              21.006788912067222
            ],
            [
              105.83440343648465,
              21.006789944385183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.7_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8674",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006747343803763,
          "to_latitude": 21.006789944385183,
          "to_longitude": 105.83440343648465,
          "from_longitude": 105.83444321765508
        }
      },
      {
        "id": 8667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456734428867,
            21.006915696409504,
            105.83457299676114,
            21.006921839868255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83457299676114,
              21.006915696409504
            ],
            [
              105.83456949456001,
              21.00691950261478
            ],
            [
              105.83456734428867,
              21.006921839868255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8675",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006915696409504,
          "to_latitude": 21.006921839868255,
          "to_longitude": 105.83456734428867,
          "from_longitude": 105.83457299676114
        }
      },
      {
        "id": 8668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8349208087031,
            21.006813041535974,
            105.83496257978896,
            21.006854792126557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496257978896,
              21.006813041535974
            ],
            [
              105.83493130217482,
              21.00684430387629
            ],
            [
              105.83492249989904,
              21.00685310112072
            ],
            [
              105.8349208087031,
              21.006854792126557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8676",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006813041535974,
          "to_latitude": 21.006854792126557,
          "to_longitude": 105.8349208087031,
          "from_longitude": 105.83496257978896
        }
      },
      {
        "id": 8669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83491774918653,
            21.00680918824394,
            105.83495874064737,
            21.00685223926636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83495874064737,
              21.00680918824394
            ],
            [
              105.8349281230458,
              21.006841344248418
            ],
            [
              105.83491905356742,
              21.006850868996796
            ],
            [
              105.83491774918653,
              21.00685223926636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8677",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00680918824394,
          "to_latitude": 21.00685223926636,
          "to_longitude": 105.83491774918653,
          "from_longitude": 105.83495874064737
        }
      },
      {
        "id": 8670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83475568215944,
            21.006678011865002,
            105.8348033531168,
            21.006719817549385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8348033531168,
              21.006678011865002
            ],
            [
              105.83476797943464,
              21.00670903323573
            ],
            [
              105.83475590997519,
              21.00671961772236
            ],
            [
              105.83475568215944,
              21.006719817549385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8678",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006678011865002,
          "to_latitude": 21.006719817549385,
          "to_longitude": 105.83475568215944,
          "from_longitude": 105.8348033531168
        }
      },
      {
        "id": 8671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83475322723092,
            21.0066735381706,
            105.83480004245072,
            21.006718050236408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480004245072,
              21.0066735381706
            ],
            [
              105.83476515493518,
              21.00670670954233
            ],
            [
              105.83475345686992,
              21.006717832335937
            ],
            [
              105.83475322723092,
              21.006718050236408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8679",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0066735381706,
          "to_latitude": 21.006718050236408,
          "to_longitude": 105.83475322723092,
          "from_longitude": 105.83480004245072
        }
      },
      {
        "id": 8672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459800794797,
            21.00653246744806,
            105.83463641939368,
            21.006572455549815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463641939368,
              21.00653246744806
            ],
            [
              105.83461260967348,
              21.006558174399004
            ],
            [
              105.8346072074615,
              21.006557601149893
            ],
            [
              105.83460689568982,
              21.00656017325866
            ],
            [
              105.83460631538424,
              21.00656496319972
            ],
            [
              105.83459890664157,
              21.006571644562268
            ],
            [
              105.83459800794797,
              21.006572455549815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8680",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00653246744806,
          "to_latitude": 21.006572455549815,
          "to_longitude": 105.83459800794797,
          "from_longitude": 105.83463641939368
        }
      },
      {
        "id": 8673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459671973775,
            21.00652930821523,
            105.8346347108407,
            21.006570161351757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346347108407,
              21.00652930821523
            ],
            [
              105.83461017015122,
              21.00655589036327
            ],
            [
              105.83460476793435,
              21.006555316210875
            ],
            [
              105.83460465664379,
              21.006556232627325
            ],
            [
              105.83460387586697,
              21.006562680067102
            ],
            [
              105.83459776690805,
              21.006569066926506
            ],
            [
              105.83459671973775,
              21.006570161351757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8681",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00652930821523,
          "to_latitude": 21.006570161351757,
          "to_longitude": 105.83459671973775,
          "from_longitude": 105.8346347108407
        }
      },
      {
        "id": 8674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453966603179,
            21.006527287725095,
            105.83502597359998,
            21.007113997175665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453966603179,
              21.006527287725095
            ],
            [
              105.83458418011138,
              21.006562620395897
            ],
            [
              105.8346215734209,
              21.006640151445456
            ],
            [
              105.83474208604416,
              21.00673902087487
            ],
            [
              105.83481935443899,
              21.006802413198102
            ],
            [
              105.83502597359998,
              21.006971926692575
            ],
            [
              105.8348942706899,
              21.007113997175665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8682",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 92.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006527287725095,
          "to_latitude": 21.007113997175665,
          "to_longitude": 105.8348942706899,
          "from_longitude": 105.83453966603179
        }
      },
      {
        "id": 8675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83474208604416,
            21.006721786700187,
            105.83475842004138,
            21.00673902087487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83474208604416,
              21.00673902087487
            ],
            [
              105.83475285456697,
              21.006727658770437
            ],
            [
              105.83475818384028,
              21.006722035342513
            ],
            [
              105.83475842004138,
              21.006721786700187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8683",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00673902087487,
          "to_latitude": 21.006721786700187,
          "to_longitude": 105.83475842004138,
          "from_longitude": 105.83474208604416
        }
      },
      {
        "id": 8676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83448739824433,
            21.006229761603688,
            105.83463943726377,
            21.00631342949019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448739824433,
              21.006251512869255
            ],
            [
              105.83449361798078,
              21.006229761603688
            ],
            [
              105.83456073886937,
              21.00624691982741
            ],
            [
              105.83455240094015,
              21.006276081608974
            ],
            [
              105.83461434260714,
              21.00630266146299
            ],
            [
              105.83463379682932,
              21.006311010313716
            ],
            [
              105.83463943726377,
              21.00631342949019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Đông Tác",
          "maTaiSan": "04.O17.DODV.8684",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006251512869255,
          "to_latitude": 21.00631342949019,
          "to_longitude": 105.83463943726377,
          "from_longitude": 105.83448739824433
        }
      },
      {
        "id": 8677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447811062395,
            21.00621868708494,
            105.83464174346074,
            21.006310766310055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447811062395,
              21.006249137764055
            ],
            [
              105.83448681653095,
              21.00621868708494
            ],
            [
              105.8345725136222,
              21.006240596411637
            ],
            [
              105.8345627160624,
              21.006274863156353
            ],
            [
              105.83461691721313,
              21.00629948754768
            ],
            [
              105.83463561718604,
              21.006307984613148
            ],
            [
              105.83464174346074,
              21.006310766310055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_Đông Tác",
          "maTaiSan": "04.O17.DODV.8685",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006249137764055,
          "to_latitude": 21.006310766310055,
          "to_longitude": 105.83464174346074,
          "from_longitude": 105.83447811062395
        }
      },
      {
        "id": 8678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83609006829293,
            21.009291283444988,
            105.83611756975955,
            21.009320991057795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611756975955,
              21.009291283444988
            ],
            [
              105.83609924022565,
              21.009311024970387
            ],
            [
              105.83609353360531,
              21.009312461136403
            ],
            [
              105.83609316380797,
              21.00931583649861
            ],
            [
              105.83609294685692,
              21.009317813820804
            ],
            [
              105.8360919956198,
              21.009318863525763
            ],
            [
              105.83609006829293,
              21.009320991057795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.1_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8686",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009291283444988,
          "to_latitude": 21.009320991057795,
          "to_longitude": 105.83609006829293,
          "from_longitude": 105.83611756975955
        }
      },
      {
        "id": 8679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608891169582,
            21.009290319716683,
            105.83611609653639,
            21.00932005648327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611609653639,
              21.009290319716683
            ],
            [
              105.83609776602546,
              21.009310058537043
            ],
            [
              105.8360919227755,
              21.009311142213352
            ],
            [
              105.83609165233756,
              21.009314568571206
            ],
            [
              105.83609147265665,
              21.009316847387417
            ],
            [
              105.8360906148618,
              21.00931792192357
            ],
            [
              105.83608891169582,
              21.00932005648327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.2_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8687",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009290319716683,
          "to_latitude": 21.00932005648327,
          "to_longitude": 105.83608891169582,
          "from_longitude": 105.83611609653639
        }
      },
      {
        "id": 8680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585861274734,
            21.009103395038515,
            105.83588637122567,
            21.009133589812407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588637122567,
              21.009103395038515
            ],
            [
              105.8358680407206,
              21.00912313654441
            ],
            [
              105.83586158596427,
              21.0091232296688
            ],
            [
              105.83586166478094,
              21.00912649985951
            ],
            [
              105.83586174638168,
              21.00912992448803
            ],
            [
              105.83585906668641,
              21.009133058292843
            ],
            [
              105.83585861274734,
              21.009133589812407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.4_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8688",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009103395038515,
          "to_latitude": 21.009133589812407,
          "to_longitude": 105.83585861274734,
          "from_longitude": 105.83588637122567
        }
      },
      {
        "id": 8681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585698353532,
            21.009101663863404,
            105.83588520538436,
            21.009132251106333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588520538436,
              21.009101663863404
            ],
            [
              105.83586687583612,
              21.009121404461254
            ],
            [
              105.83586037218053,
              21.00912152582575
            ],
            [
              105.83586049942929,
              21.00912554726181
            ],
            [
              105.83586058343101,
              21.009128194201786
            ],
            [
              105.83585761538065,
              21.009131538971143
            ],
            [
              105.83585698353532,
              21.009132251106333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.5_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8689",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009101663863404,
          "to_latitude": 21.009132251106333,
          "to_longitude": 105.83585698353532,
          "from_longitude": 105.83588520538436
        }
      },
      {
        "id": 8682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548653031202,
            21.008797058872762,
            105.83551027972887,
            21.008821192231082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83551027972887,
              21.008797058872762
            ],
            [
              105.83548945522932,
              21.00881821980853
            ],
            [
              105.8354879449464,
              21.008819754577157
            ],
            [
              105.83548653031202,
              21.008821192231082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.1_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8690",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008797058872762,
          "to_latitude": 21.008821192231082,
          "to_longitude": 105.83548653031202,
          "from_longitude": 105.83551027972887
        }
      },
      {
        "id": 8683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548401209076,
            21.00879457206765,
            105.83550719840606,
            21.008819121757398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83550719840606,
              21.00879457206765
            ],
            [
              105.83548692484204,
              21.008816037394713
            ],
            [
              105.83548560425434,
              21.00881743574822
            ],
            [
              105.83548401209076,
              21.008819121757398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.2_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8691",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00879457206765,
          "to_latitude": 21.008819121757398,
          "to_longitude": 105.83548401209076,
          "from_longitude": 105.83550719840606
        }
      },
      {
        "id": 8684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83525520168907,
            21.008607587821096,
            105.83527802159485,
            21.008632126649722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527802159485,
              21.008607587821096
            ],
            [
              105.83525842683993,
              21.008628657790037
            ],
            [
              105.83525703561655,
              21.00863015403683
            ],
            [
              105.83525520168907,
              21.008632126649722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.3_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8692",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008607587821096,
          "to_latitude": 21.008632126649722,
          "to_longitude": 105.83525520168907,
          "from_longitude": 105.83527802159485
        }
      },
      {
        "id": 8685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83525442284083,
            21.00860666731557,
            105.8352771257592,
            21.00863148647291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352771257592,
              21.00860666731557
            ],
            [
              105.83525761039351,
              21.008628002442954
            ],
            [
              105.83525660651341,
              21.008629099369486
            ],
            [
              105.83525442284083,
              21.00863148647291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.4_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8693",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00860666731557,
          "to_latitude": 21.00863148647291,
          "to_longitude": 105.83525442284083,
          "from_longitude": 105.8352771257592
        }
      },
      {
        "id": 8686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463196680019,
            21.008326998583392,
            105.83466198526742,
            21.008359197665563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466198526742,
              21.008326998583392
            ],
            [
              105.83464297952507,
              21.008347446885285
            ],
            [
              105.83463570903997,
              21.008348563704423
            ],
            [
              105.83463556584287,
              21.008348586084153
            ],
            [
              105.83463668612212,
              21.008354234780256
            ],
            [
              105.83463488923991,
              21.00835612410823
            ],
            [
              105.83463196680019,
              21.008359197665563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8694",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008326998583392,
          "to_latitude": 21.008359197665563,
          "to_longitude": 105.83463196680019,
          "from_longitude": 105.83466198526742
        }
      },
      {
        "id": 8687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83464894702607,
            21.008339743052666,
            105.83467967220925,
            21.008373028571203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467967220925,
              21.008339743052666
            ],
            [
              105.8346606674347,
              21.008360192254965
            ],
            [
              105.83465332732094,
              21.00836141509375
            ],
            [
              105.83465366633638,
              21.00836322168809
            ],
            [
              105.83465437308026,
              21.00836698196169
            ],
            [
              105.83465190382378,
              21.00836973355488
            ],
            [
              105.83464894702607,
              21.008373028571203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8695",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008339743052666,
          "to_latitude": 21.008373028571203,
          "to_longitude": 105.83464894702607,
          "from_longitude": 105.83467967220925
        }
      },
      {
        "id": 8688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440890802993,
            21.008143109566625,
            105.83444051002633,
            21.008177513019124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444051002633,
              21.008143109566625
            ],
            [
              105.83442150620391,
              21.008163558738207
            ],
            [
              105.83441490917285,
              21.00816383314926
            ],
            [
              105.83441514314809,
              21.008167162194027
            ],
            [
              105.83441538122692,
              21.008170537283153
            ],
            [
              105.8344147807582,
              21.008171184227066
            ],
            [
              105.83440890802993,
              21.008177513019124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8696",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008143109566625,
          "to_latitude": 21.008177513019124,
          "to_longitude": 105.83440890802993,
          "from_longitude": 105.83444051002633
        }
      },
      {
        "id": 8689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834400385568,
            21.00813789505703,
            105.83443060147077,
            21.008170571067243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443060147077,
              21.00813789505703
            ],
            [
              105.83441159572891,
              21.008158345140185
            ],
            [
              105.83440505153779,
              21.008158435033774
            ],
            [
              105.83440506515439,
              21.008158807999095
            ],
            [
              105.83440530232279,
              21.00816513302683
            ],
            [
              105.83440397251331,
              21.008166603675406
            ],
            [
              105.834400385568,
              21.008170571067243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8697",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00813789505703,
          "to_latitude": 21.008170571067243,
          "to_longitude": 105.834400385568,
          "from_longitude": 105.83443060147077
        }
      },
      {
        "id": 8690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366056284589,
            21.006517893401615,
            105.83405574661931,
            21.00683614069541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405574661931,
              21.00683614069541
            ],
            [
              105.83398599488254,
              21.00678005982589
            ],
            [
              105.8339657972884,
              21.006763783320956
            ],
            [
              105.83393802077298,
              21.006741399307437
            ],
            [
              105.83384961000019,
              21.006670152521746
            ],
            [
              105.83374952037822,
              21.00658855412955
            ],
            [
              105.83372917623952,
              21.00657082231285
            ],
            [
              105.83371659550382,
              21.00655985646146
            ],
            [
              105.83371243930125,
              21.00655623411262
            ],
            [
              105.83371071526936,
              21.00655495997777
            ],
            [
              105.83366056284589,
              21.006517893401615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8698",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00683614069541,
          "to_latitude": 21.006517893401615,
          "to_longitude": 105.83366056284589,
          "from_longitude": 105.83405574661931
        }
      },
      {
        "id": 8691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363480604221,
            21.006536141888205,
            105.83406342923065,
            21.006992274984388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363480604221,
              21.006536141888205
            ],
            [
              105.83376924598147,
              21.006651288579203
            ],
            [
              105.83380876722588,
              21.006685138433333
            ],
            [
              105.83381821161956,
              21.006722476670802
            ],
            [
              105.83387038720296,
              21.00676529495989
            ],
            [
              105.83406342923065,
              21.006923722247617
            ],
            [
              105.83400026933435,
              21.006992274984388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8699",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006536141888205,
          "to_latitude": 21.006992274984388,
          "to_longitude": 105.83400026933435,
          "from_longitude": 105.83363480604221
        }
      },
      {
        "id": 8692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83478576671476,
            21.007143719906885,
            105.8348444989839,
            21.007198582643934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83478576671476,
              21.007192007214716
            ],
            [
              105.83479387925935,
              21.007198582643934
            ],
            [
              105.834839042637,
              21.007149499315712
            ],
            [
              105.83484343085678,
              21.0071448514687
            ],
            [
              105.8348444989839,
              21.007143719906885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8700",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007192007214716,
          "to_latitude": 21.007143719906885,
          "to_longitude": 105.8348444989839,
          "from_longitude": 105.83478576671476
        }
      },
      {
        "id": 8693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83480593775381,
            21.007142178396983,
            105.83484259742387,
            21.00718204414601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480593775381,
              21.00718204414601
            ],
            [
              105.83484158491858,
              21.007143279879504
            ],
            [
              105.83484259742387,
              21.007142178396983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8701",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00718204414601,
          "to_latitude": 21.007142178396983,
          "to_longitude": 105.83484259742387,
          "from_longitude": 105.83480593775381
        }
      },
      {
        "id": 8694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396198197728,
            21.00674827539638,
            105.83398100210776,
            21.006767675048426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398100210776,
              21.00674827539638
            ],
            [
              105.8339657972884,
              21.006763783320956
            ],
            [
              105.83396437312439,
              21.00676523636396
            ],
            [
              105.83396198197728,
              21.006767675048426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8702",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00674827539638,
          "to_latitude": 21.006767675048426,
          "to_longitude": 105.83396198197728,
          "from_longitude": 105.83398100210776
        }
      },
      {
        "id": 8695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83393432289819,
            21.006726205217806,
            105.8339524039682,
            21.0067453067169
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339524039682,
              21.006726205217806
            ],
            [
              105.83393802077298,
              21.006741399307437
            ],
            [
              105.8339356387192,
              21.00674391652761
            ],
            [
              105.83393432289819,
              21.0067453067169
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8703",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006726205217806,
          "to_latitude": 21.0067453067169,
          "to_longitude": 105.83393432289819,
          "from_longitude": 105.8339524039682
        }
      },
      {
        "id": 8696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83371330964486,
            21.006547177390633,
            105.8337275741619,
            21.006563650751108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337275741619,
              21.006547177390633
            ],
            [
              105.83371659550382,
              21.00655985646146
            ],
            [
              105.83371522113124,
              21.006561442032677
            ],
            [
              105.83371330964486,
              21.006563650751108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8704",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006547177390633,
          "to_latitude": 21.006563650751108,
          "to_longitude": 105.83371330964486,
          "from_longitude": 105.8337275741619
        }
      },
      {
        "id": 8697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83370715589301,
            21.0065427881626,
            105.83372232004304,
            21.006558692381425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372232004304,
              21.0065427881626
            ],
            [
              105.83371071526936,
              21.00655495997777
            ],
            [
              105.83370973600765,
              21.00655598632317
            ],
            [
              105.83370715589301,
              21.006558692381425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.6_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8705",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0065427881626,
          "to_latitude": 21.006558692381425,
          "to_longitude": 105.83370715589301,
          "from_longitude": 105.83372232004304
        }
      },
      {
        "id": 8698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372613988047,
            21.00657082231285,
            105.83372917623952,
            21.006573991251543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372917623952,
              21.00657082231285
            ],
            [
              105.83372721193003,
              21.006572872322486
            ],
            [
              105.83372613988047,
              21.006573991251543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8706",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00657082231285,
          "to_latitude": 21.006573991251543,
          "to_longitude": 105.83372613988047,
          "from_longitude": 105.83372917623952
        }
      },
      {
        "id": 8699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376924598147,
            21.006627271086888,
            105.83379225438712,
            21.006651288579203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376924598147,
              21.006651288579203
            ],
            [
              105.83377982588875,
              21.006640244490566
            ],
            [
              105.83379183646623,
              21.00662770668269
            ],
            [
              105.83379225438712,
              21.006627271086888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8707",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006651288579203,
          "to_latitude": 21.006627271086888,
          "to_longitude": 105.83379225438712,
          "from_longitude": 105.83376924598147
        }
      },
      {
        "id": 8700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83491486747542,
            21.006838768281725,
            105.8349253548506,
            21.006849833662788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349253548506,
              21.006838768281725
            ],
            [
              105.83492492357209,
              21.00683922291349
            ],
            [
              105.83491644244285,
              21.00684817122719
            ],
            [
              105.83491486747542,
              21.006849833662788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8708",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006838768281725,
          "to_latitude": 21.006849833662788,
          "to_longitude": 105.83491486747542,
          "from_longitude": 105.8349253548506
        }
      },
      {
        "id": 8701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362994403058,
            21.004736522303208,
            105.83631254878993,
            21.004782234822873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631254878993,
              21.004736522303208
            ],
            [
              105.83630395982658,
              21.004766474816044
            ],
            [
              105.83630304741787,
              21.004769654136624
            ],
            [
              105.8362994403058,
              21.004782234822873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8709",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004736522303208,
          "to_latitude": 21.004782234822873,
          "to_longitude": 105.8362994403058,
          "from_longitude": 105.83631254878993
        }
      },
      {
        "id": 8702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83579711824521,
            21.004639636159045,
            105.83643946994148,
            21.004947102647545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83579711824521,
              21.004639636159045
            ],
            [
              105.83598872449329,
              21.00468861821917
            ],
            [
              105.83599116990773,
              21.004686781692346
            ],
            [
              105.83599224951882,
              21.004687025679747
            ],
            [
              105.83600660034922,
              21.004690268221726
            ],
            [
              105.83600773293199,
              21.004690524594007
            ],
            [
              105.83600882339012,
              21.004693751881728
            ],
            [
              105.83603014175866,
              21.004699199293622
            ],
            [
              105.83628521787145,
              21.00476440639121
            ],
            [
              105.83628922782563,
              21.004765429913455
            ],
            [
              105.83629374167668,
              21.004763842444675
            ],
            [
              105.83629538690569,
              21.004764266102427
            ],
            [
              105.83630395982658,
              21.004766474816044
            ],
            [
              105.83630521012226,
              21.004766796543358
            ],
            [
              105.83630677511742,
              21.00476991040052
            ],
            [
              105.83643946994148,
              21.004803828379153
            ],
            [
              105.83641444519164,
              21.004947102647545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8710",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004639636159045,
          "to_latitude": 21.004947102647545,
          "to_longitude": 105.83641444519164,
          "from_longitude": 105.83579711824521
        }
      },
      {
        "id": 8703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629085110323,
            21.00473439578123,
            105.83630397632867,
            21.004780038834205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630397632867,
              21.00473439578123
            ],
            [
              105.83629538690569,
              21.004764266102427
            ],
            [
              105.83629445557206,
              21.00476750151593
            ],
            [
              105.83629085110323,
              21.004780038834205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8711",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00473439578123,
          "to_latitude": 21.004780038834205,
          "to_longitude": 105.83629085110323,
          "from_longitude": 105.83630397632867
        }
      },
      {
        "id": 8704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83600173706392,
            21.00466006225638,
            105.83601585549182,
            21.00470613829721
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83601585549182,
              21.00466006225638
            ],
            [
              105.83600660034922,
              21.004690268221726
            ],
            [
              105.8360050321412,
              21.004695382761387
            ],
            [
              105.83600173706392,
              21.00470613829721
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8712",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00466006225638,
          "to_latitude": 21.00470613829721,
          "to_longitude": 105.83600173706392,
          "from_longitude": 105.83601585549182
        }
      },
      {
        "id": 8705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598817178958,
            21.004656407749078,
            105.83600097238124,
            21.00470267150937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83600097238124,
              21.004656407749078
            ],
            [
              105.83599224951882,
              21.004687025679747
            ],
            [
              105.83599099569476,
              21.004691423320523
            ],
            [
              105.83598817178958,
              21.00470267150937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8713",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004656407749078,
          "to_latitude": 21.00470267150937,
          "to_longitude": 105.83598817178958,
          "from_longitude": 105.83600097238124
        }
      },
      {
        "id": 8706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571987478244,
            21.00497908269912,
            105.83640785757702,
            21.00515493917723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83571987478244,
              21.00497908269912
            ],
            [
              105.83588686317948,
              21.00502198328959
            ],
            [
              105.83589028136805,
              21.005027457209525
            ],
            [
              105.8358914719555,
              21.00502776568469
            ],
            [
              105.83590411472117,
              21.00503104101017
            ],
            [
              105.8359065822615,
              21.005029721923975
            ],
            [
              105.8359099973098,
              21.005027896894042
            ],
            [
              105.83591076784995,
              21.005028084593423
            ],
            [
              105.83592861552526,
              21.005032438998384
            ],
            [
              105.836192388669,
              21.005099933823494
            ],
            [
              105.8361957936511,
              21.005105452059798
            ],
            [
              105.8361972201579,
              21.005105807244636
            ],
            [
              105.83620157866503,
              21.005106893185197
            ],
            [
              105.83620515119156,
              21.005107782471224
            ],
            [
              105.8362110414309,
              21.005104631081426
            ],
            [
              105.83640785757702,
              21.00515493917723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8714",
          "diaChiLapD": "Ngõ 27, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00497908269912,
          "to_latitude": 21.00515493917723,
          "to_longitude": 105.83640785757702,
          "from_longitude": 105.83571987478244
        }
      },
      {
        "id": 8707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358860470582,
            21.005019665929595,
            105.83589378726707,
            21.00504711502991
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589378726707,
              21.005019665929595
            ],
            [
              105.8358914719555,
              21.00502776568469
            ],
            [
              105.83588912929353,
              21.00503596312243
            ],
            [
              105.83588628182315,
              21.005046263941768
            ],
            [
              105.8358860470582,
              21.00504711502991
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8715",
          "diaChiLapD": "Ngõ 27, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005019665929595,
          "to_latitude": 21.00504711502991,
          "to_longitude": 105.8358860470582,
          "from_longitude": 105.83589378726707
        }
      },
      {
        "id": 8708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590061220296,
            21.005023632860848,
            105.8359083229937,
            21.00505083704317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359083229937,
              21.005023632860848
            ],
            [
              105.8359065822615,
              21.005029721923975
            ],
            [
              105.83590365179732,
              21.005039973473806
            ],
            [
              105.83590093096201,
              21.00504969651034
            ],
            [
              105.83590061220296,
              21.00505083704317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8716",
          "diaChiLapD": "Ngõ 27, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005023632860848,
          "to_latitude": 21.00505083704317,
          "to_longitude": 105.83590061220296,
          "from_longitude": 105.8359083229937
        }
      },
      {
        "id": 8709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361917113295,
            21.005097914616634,
            105.83619947601017,
            21.00512545055901
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619947601017,
              21.005097914616634
            ],
            [
              105.8361972201579,
              21.005105807244636
            ],
            [
              105.83619480484884,
              21.00511425613964
            ],
            [
              105.83619209883649,
              21.00512404684934
            ],
            [
              105.8361917113295,
              21.00512545055901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8717",
          "diaChiLapD": "Ngõ 27, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005097914616634,
          "to_latitude": 21.00512545055901,
          "to_longitude": 105.8361917113295,
          "from_longitude": 105.83619947601017
        }
      },
      {
        "id": 8710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361958484473,
            21.005098989690392,
            105.83620384022777,
            21.00512650778094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620384022777,
              21.005098989690392
            ],
            [
              105.83620157866503,
              21.005106893185197
            ],
            [
              105.83619916141747,
              21.005115339380108
            ],
            [
              105.83619626008591,
              21.00512511930757
            ],
            [
              105.8361958484473,
              21.00512650778094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8718",
          "diaChiLapD": "Ngõ 27, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005098989690392,
          "to_latitude": 21.00512650778094,
          "to_longitude": 105.8361958484473,
          "from_longitude": 105.83620384022777
        }
      },
      {
        "id": 8711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587771303111,
            21.00427886603185,
            105.8364042947178,
            21.0044134664463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83587771303111,
              21.00427886603185
            ],
            [
              105.83603880062182,
              21.004320042316035
            ],
            [
              105.83604100401148,
              21.004318790461124
            ],
            [
              105.83604419671734,
              21.004319876713566
            ],
            [
              105.83604488094016,
              21.004320109997717
            ],
            [
              105.83604483144101,
              21.00432158430489
            ],
            [
              105.83604567812382,
              21.004321800532963
            ],
            [
              105.8362410483299,
              21.0043717393147
            ],
            [
              105.83624529890494,
              21.0043696852702
            ],
            [
              105.83624757613173,
              21.00437029188786
            ],
            [
              105.83625009803774,
              21.00437096414201
            ],
            [
              105.8362540157554,
              21.00437200798958
            ],
            [
              105.83625538416656,
              21.004375402813263
            ],
            [
              105.8364042947178,
              21.0044134664463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8719",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00427886603185,
          "to_latitude": 21.0044134664463,
          "to_longitude": 105.8364042947178,
          "from_longitude": 105.83587771303111
        }
      },
      {
        "id": 8712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644692108571,
            21.004425328516476,
            105.8366405393907,
            21.004481141948695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83644692108571,
              21.00443856082788
            ],
            [
              105.83645068083557,
              21.00442731074272
            ],
            [
              105.83645134405499,
              21.004425328516476
            ],
            [
              105.8366405393907,
              21.004481141948695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8720",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00443856082788,
          "to_latitude": 21.004481141948695,
          "to_longitude": 105.8366405393907,
          "from_longitude": 105.83644692108571
        }
      },
      {
        "id": 8713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661188533712,
            21.004372855817323,
            105.8367480034677,
            21.004594911020472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83671317596865,
              21.004594911020472
            ],
            [
              105.83661188533712,
              21.004575336559636
            ],
            [
              105.8366405393907,
              21.004481141948695
            ],
            [
              105.83667347979335,
              21.004372855817323
            ],
            [
              105.8367480034677,
              21.004385171649094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8721",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004594911020472,
          "to_latitude": 21.004385171649094,
          "to_longitude": 105.8367480034677,
          "from_longitude": 105.83671317596865
        }
      },
      {
        "id": 8714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83628143544401,
            21.00476440639121,
            105.83628521787145,
            21.004777631938264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83628521787145,
              21.00476440639121
            ],
            [
              105.83628466685659,
              21.00476632935587
            ],
            [
              105.83628277613893,
              21.004772944836635
            ],
            [
              105.83628143544401,
              21.004777631938264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8722",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00476440639121,
          "to_latitude": 21.004777631938264,
          "to_longitude": 105.83628143544401,
          "from_longitude": 105.83628521787145
        }
      },
      {
        "id": 8715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83590401340298,
            21.005028084593423,
            105.83591076784995,
            21.005051706402863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591076784995,
              21.005028084593423
            ],
            [
              105.835904331346,
              21.00505059206759
            ],
            [
              105.83590401340298,
              21.005051706402863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8723",
          "diaChiLapD": "Ngõ 27, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005028084593423,
          "to_latitude": 21.005051706402863,
          "to_longitude": 105.83590401340298,
          "from_longitude": 105.83591076784995
        }
      },
      {
        "id": 8716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603660659195,
            21.00428694476288,
            105.83605015188272,
            21.00433368005626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605015188272,
              21.00428694476288
            ],
            [
              105.83604241348469,
              21.004314017216785
            ],
            [
              105.83604100401148,
              21.004318790461124
            ],
            [
              105.83603928771684,
              21.004324602115442
            ],
            [
              105.83603660659195,
              21.00433368005626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8724",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00428694476288,
          "to_latitude": 21.00433368005626,
          "to_longitude": 105.83603660659195,
          "from_longitude": 105.83605015188272
        }
      },
      {
        "id": 8717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603989797078,
            21.00428761135363,
            105.83605345959757,
            21.00433452195321
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605345959757,
              21.00428761135363
            ],
            [
              105.83604571836496,
              21.004314692853832
            ],
            [
              105.83604419671734,
              21.004319876713566
            ],
            [
              105.83604256178742,
              21.004325445000596
            ],
            [
              105.83603989797078,
              21.00433452195321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8725",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00428761135363,
          "to_latitude": 21.00433452195321,
          "to_longitude": 105.83603989797078,
          "from_longitude": 105.83605345959757
        }
      },
      {
        "id": 8718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362432755753,
            21.00434089780955,
            105.83625593254172,
            21.004386507849997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625593254172,
              21.00434089780955
            ],
            [
              105.8362481809546,
              21.004368013691277
            ],
            [
              105.83624757613173,
              21.00437029188786
            ],
            [
              105.83624591525194,
              21.004376553078018
            ],
            [
              105.8362432755753,
              21.004386507849997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8726",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00434089780955,
          "to_latitude": 21.004386507849997,
          "to_longitude": 105.8362432755753,
          "from_longitude": 105.83625593254172
        }
      },
      {
        "id": 8719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83624523864937,
            21.004341736990323,
            105.83625848904259,
            21.004387009430584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625848904259,
              21.004341736990323
            ],
            [
              105.83625074972328,
              21.004368810360276
            ],
            [
              105.83625009803774,
              21.00437096414201
            ],
            [
              105.83624820809972,
              21.0043772011673
            ],
            [
              105.83624523864937,
              21.004387009430584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8727",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004341736990323,
          "to_latitude": 21.004387009430584,
          "to_longitude": 105.83624523864937,
          "from_longitude": 105.83625848904259
        }
      },
      {
        "id": 8720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83643953306003,
            21.00439423956427,
            105.83645133045742,
            21.004436672362257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83645133045742,
              21.00439423956427
            ],
            [
              105.83644256373132,
              21.00442576998912
            ],
            [
              105.83643953306003,
              21.004436672362257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8728",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00439423956427,
          "to_latitude": 21.004436672362257,
          "to_longitude": 105.83643953306003,
          "from_longitude": 105.83645133045742
        }
      },
      {
        "id": 8721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364413988487,
            21.004395027736294,
            105.83645342209547,
            21.004437149128325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83645342209547,
              21.004395027736294
            ],
            [
              105.83644475465832,
              21.004425388899598
            ],
            [
              105.8364413988487,
              21.004437149128325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8729",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004395027736294,
          "to_latitude": 21.004437149128325,
          "to_longitude": 105.8364413988487,
          "from_longitude": 105.83645342209547
        }
      },
      {
        "id": 8722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360418976437,
            21.004321800532963,
            105.83604567812382,
            21.00433503238867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604567812382,
              21.004321800532963
            ],
            [
              105.83604447896356,
              21.00432599558316
            ],
            [
              105.83604258920185,
              21.004332611057055
            ],
            [
              105.8360418976437,
              21.00433503238867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8730",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004321800532963,
          "to_latitude": 21.00433503238867,
          "to_longitude": 105.8360418976437,
          "from_longitude": 105.83604567812382
        }
      },
      {
        "id": 8723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83517595071851,
            21.003537837133745,
            105.83593182933936,
            21.003719195815123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83517595071851,
              21.003537837133745
            ],
            [
              105.83529870817918,
              21.003569848418657
            ],
            [
              105.83530330373554,
              21.003567391672547
            ],
            [
              105.8353045888065,
              21.00356670523705
            ],
            [
              105.8353052740584,
              21.003567815551442
            ],
            [
              105.83530799562831,
              21.00357222077532
            ],
            [
              105.8355096189536,
              21.00361979671638
            ],
            [
              105.83551438538727,
              21.003617280416194
            ],
            [
              105.8355154892444,
              21.003616697836385
            ],
            [
              105.83551629477091,
              21.00361798820459
            ],
            [
              105.8355189073857,
              21.003622171766203
            ],
            [
              105.83552038322198,
              21.003622579102164
            ],
            [
              105.83568138631698,
              21.00366703487456
            ],
            [
              105.83568728214787,
              21.003663859089134
            ],
            [
              105.83569425021437,
              21.003665650296067
            ],
            [
              105.83569617476371,
              21.003666144846687
            ],
            [
              105.83569933129338,
              21.003666955799968
            ],
            [
              105.83569913593561,
              21.00366693779142
            ],
            [
              105.83570254560925,
              21.00367244427498
            ],
            [
              105.83588544679309,
              21.003719195815123
            ],
            [
              105.83593182933936,
              21.003562682900522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT99_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8731",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 97.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003537837133745,
          "to_latitude": 21.003562682900522,
          "to_longitude": 105.83593182933936,
          "from_longitude": 105.83517595071851
        }
      },
      {
        "id": 8724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569161760894,
            21.00363566610549,
            105.8357048810972,
            21.00368206218628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8357048810972,
              21.00363566610549
            ],
            [
              105.83569617476371,
              21.003666144846687
            ],
            [
              105.8356951784445,
              21.003669629866835
            ],
            [
              105.8356918780158,
              21.00368115226275
            ],
            [
              105.83569161760894,
              21.00368206218628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8732",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00363566610549,
          "to_latitude": 21.00368206218628,
          "to_longitude": 105.83569161760894,
          "from_longitude": 105.8357048810972
        }
      },
      {
        "id": 8725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568958616009,
            21.00363528422246,
            105.83570282059151,
            21.003681543773382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83570282059151,
              21.00363528422246
            ],
            [
              105.83569425021437,
              21.003665650296067
            ],
            [
              105.83569313905586,
              21.003669585686403
            ],
            [
              105.83568981558584,
              21.00368077038902
            ],
            [
              105.83568958616009,
              21.003681543773382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8733",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00363528422246,
          "to_latitude": 21.003681543773382,
          "to_longitude": 105.83568958616009,
          "from_longitude": 105.83570282059151
        }
      },
      {
        "id": 8726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551116408623,
            21.00358926778696,
            105.83552450306547,
            21.003635935193678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83552450306547,
              21.00358926778696
            ],
            [
              105.83551629477091,
              21.00361798820459
            ],
            [
              105.8355150286127,
              21.003622417515988
            ],
            [
              105.83551150185609,
              21.003634753922924
            ],
            [
              105.83551116408623,
              21.003635935193678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT98_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8734",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00358926778696,
          "to_latitude": 21.003635935193678,
          "to_longitude": 105.83551116408623,
          "from_longitude": 105.83552450306547
        }
      },
      {
        "id": 8727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355092019861,
            21.00358870196645,
            105.8355225608074,
            21.003635433599452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355225608074,
              21.00358870196645
            ],
            [
              105.83551438538727,
              21.003617280416194
            ],
            [
              105.83551305408933,
              21.003621930434395
            ],
            [
              105.83550955766874,
              21.00363418720852
            ],
            [
              105.8355092019861,
              21.003635433599452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8735",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00358870196645,
          "to_latitude": 21.003635433599452,
          "to_longitude": 105.8355092019861,
          "from_longitude": 105.8355225608074
        }
      },
      {
        "id": 8728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530115108393,
            21.003538611193637,
            105.83531362974794,
            21.003582253073823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83531362974794,
              21.003538611193637
            ],
            [
              105.8353052740584,
              21.003567815551442
            ],
            [
              105.83530492117923,
              21.003569047476617
            ],
            [
              105.83530115108393,
              21.003582253073823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT96_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8736",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003538611193637,
          "to_latitude": 21.003582253073823,
          "to_longitude": 105.83530115108393,
          "from_longitude": 105.83531362974794
        }
      },
      {
        "id": 8729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529919476618,
            21.00353802456399,
            105.83531169410844,
            21.00358175325521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83531169410844,
              21.00353802456399
            ],
            [
              105.83530330373554,
              21.003567391672547
            ],
            [
              105.83530298482887,
              21.00356850601168
            ],
            [
              105.83529919476618,
              21.00358175325521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8737",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00353802456399,
          "to_latitude": 21.00358175325521,
          "to_longitude": 105.83529919476618,
          "from_longitude": 105.83531169410844
        }
      },
      {
        "id": 8730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559611533794,
            21.00398796814572,
            105.83560852413459,
            21.004031364926778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560852413459,
              21.00398796814572
            ],
            [
              105.835600548924,
              21.00401605426082
            ],
            [
              105.83560024419987,
              21.00401712427272
            ],
            [
              105.83559634844607,
              21.004030561718025
            ],
            [
              105.83559611533794,
              21.004031364926778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8738",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00398796814572,
          "to_latitude": 21.004031364926778,
          "to_longitude": 105.83559611533794,
          "from_longitude": 105.83560852413459
        }
      },
      {
        "id": 8731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559221710632,
            21.003987769852255,
            105.83560439183425,
            21.004030363419574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560439183425,
              21.003987769852255
            ],
            [
              105.83559676941869,
              21.004014782106385
            ],
            [
              105.83559646661313,
              21.00401585120563
            ],
            [
              105.83559221710632,
              21.004030363419574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8739",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003987769852255,
          "to_latitude": 21.004030363419574,
          "to_longitude": 105.83559221710632,
          "from_longitude": 105.83560439183425
        }
      },
      {
        "id": 8732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540633657782,
            21.003946710042893,
            105.83541672719946,
            21.003983065294342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83541672719946,
              21.003946710042893
            ],
            [
              105.83541151730253,
              21.00396494554674
            ],
            [
              105.83541063980832,
              21.003968006369277
            ],
            [
              105.8354085000628,
              21.003975508225185
            ],
            [
              105.83540633657782,
              21.003983065294342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8740",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003946710042893,
          "to_latitude": 21.003983065294342,
          "to_longitude": 105.83540633657782,
          "from_longitude": 105.83541672719946
        }
      },
      {
        "id": 8733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538914384545,
            21.003941997841824,
            105.83539962792811,
            21.003978670583287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83539962792811,
              21.003941997841824
            ],
            [
              105.8353943792877,
              21.003960358180514
            ],
            [
              105.83539353861102,
              21.00396329417713
            ],
            [
              105.8353913903037,
              21.003970813236158
            ],
            [
              105.83538914384545,
              21.003978670583287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8741",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003941997841824,
          "to_latitude": 21.003978670583287,
          "to_longitude": 105.83538914384545,
          "from_longitude": 105.83539962792811
        }
      },
      {
        "id": 8734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520254761257,
            21.003886613003587,
            105.8352146509576,
            21.003929262196138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352146509576,
              21.003886613003587
            ],
            [
              105.8352056182633,
              21.003918442742705
            ],
            [
              105.83520518618172,
              21.00391996137888
            ],
            [
              105.83520254761257,
              21.003929262196138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8742",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003886613003587,
          "to_latitude": 21.003929262196138,
          "to_longitude": 105.83520254761257,
          "from_longitude": 105.8352146509576
        }
      },
      {
        "id": 8735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518359404015,
            21.00388186013496,
            105.83519626354044,
            21.003924233007723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83519626354044,
              21.00388186013496
            ],
            [
              105.83518686032855,
              21.003913308723646
            ],
            [
              105.83518634474119,
              21.003915031898536
            ],
            [
              105.83518359404015,
              21.003924233007723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8743",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00388186013496,
          "to_latitude": 21.003924233007723,
          "to_longitude": 105.83518359404015,
          "from_longitude": 105.83519626354044
        }
      },
      {
        "id": 8736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354981331145,
            21.00436213638964,
            105.83550976069924,
            21.004402810619467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83550976069924,
              21.00436213638964
            ],
            [
              105.8355043309115,
              21.004381130781226
            ],
            [
              105.83550325813295,
              21.00438487811098
            ],
            [
              105.83550141835354,
              21.004391319011617
            ],
            [
              105.8354981331145,
              21.004402810619467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8744",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00436213638964,
          "to_latitude": 21.004402810619467,
          "to_longitude": 105.8354981331145,
          "from_longitude": 105.83550976069924
        }
      },
      {
        "id": 8737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354927226207,
            21.00436059924321,
            105.83550439552026,
            21.004401427243504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83550439552026,
              21.00436059924321
            ],
            [
              105.8354989364831,
              21.00437969584273
            ],
            [
              105.83549789295824,
              21.00438334186757
            ],
            [
              105.83549605316357,
              21.004389780058574
            ],
            [
              105.8354927226207,
              21.004401427243504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8745",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00436059924321,
          "to_latitude": 21.004401427243504,
          "to_longitude": 105.8354927226207,
          "from_longitude": 105.83550439552026
        }
      },
      {
        "id": 8738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529061187834,
            21.004309376420146,
            105.83530215550124,
            21.00434976480618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83530215550124,
              21.004309376420146
            ],
            [
              105.83529621344927,
              21.00433017706151
            ],
            [
              105.83529565579573,
              21.004332119023715
            ],
            [
              105.83529382172361,
              21.004338549958902
            ],
            [
              105.83529061187834,
              21.00434976480618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8746",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004309376420146,
          "to_latitude": 21.00434976480618,
          "to_longitude": 105.83529061187834,
          "from_longitude": 105.83530215550124
        }
      },
      {
        "id": 8739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528636690602,
            21.004308241040647,
            105.83529792756154,
            21.004348680090644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83529792756154,
              21.004308241040647
            ],
            [
              105.8352919675106,
              21.00432909144764
            ],
            [
              105.83529142496992,
              21.00433098365823
            ],
            [
              105.83528957290923,
              21.004337466165513
            ],
            [
              105.83528636690602,
              21.004348680090644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8747",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004308241040647,
          "to_latitude": 21.004348680090644,
          "to_longitude": 105.83528636690602,
          "from_longitude": 105.83529792756154
        }
      },
      {
        "id": 8740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83510023827476,
            21.004262893405205,
            105.83511116020212,
            21.004301102858882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83511116020212,
              21.004262893405205
            ],
            [
              105.83510491423813,
              21.004284742370412
            ],
            [
              105.83510465759367,
              21.004285637823067
            ],
            [
              105.83510023827476,
              21.004301102858882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8748",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004262893405205,
          "to_latitude": 21.004301102858882,
          "to_longitude": 105.83510023827476,
          "from_longitude": 105.83511116020212
        }
      },
      {
        "id": 8741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509709909274,
            21.0042622324813,
            105.8351079823746,
            21.004300299938752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351079823746,
              21.0042622324813
            ],
            [
              105.83510178546071,
              21.004283907786423
            ],
            [
              105.83510147976064,
              21.004284975995855
            ],
            [
              105.83509709909274,
              21.004300299938752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8749",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0042622324813,
          "to_latitude": 21.004300299938752,
          "to_longitude": 105.83509709909274,
          "from_longitude": 105.8351079823746
        }
      },
      {
        "id": 8742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498614288179,
            21.004624799128575,
            105.83499289552014,
            21.004648423607087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499289552014,
              21.004624799128575
            ],
            [
              105.83498835632368,
              21.004640677523724
            ],
            [
              105.83498762604036,
              21.004643230009194
            ],
            [
              105.83498614288179,
              21.004648423607087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8750",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004624799128575,
          "to_latitude": 21.004648423607087,
          "to_longitude": 105.83498614288179,
          "from_longitude": 105.83499289552014
        }
      },
      {
        "id": 8743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498347375424,
            21.004624000075385,
            105.83499025652358,
            21.004647741217745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499025652358,
              21.004624000075385
            ],
            [
              105.83498570031529,
              21.004639932747487
            ],
            [
              105.83498498607663,
              21.004642430057466
            ],
            [
              105.83498347375424,
              21.004647741217745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8751",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004624000075385,
          "to_latitude": 21.004647741217745,
          "to_longitude": 105.83498347375424,
          "from_longitude": 105.83499025652358
        }
      },
      {
        "id": 8744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518397088869,
            21.004674033124836,
            105.83519132735901,
            21.004699765992218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83519132735901,
              21.004674033124836
            ],
            [
              105.8351863409556,
              21.00469147449297
            ],
            [
              105.83518575220708,
              21.004693533123486
            ],
            [
              105.83518397088869,
              21.004699765992218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8752",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004674033124836,
          "to_latitude": 21.004699765992218,
          "to_longitude": 105.83518397088869,
          "from_longitude": 105.83519132735901
        }
      },
      {
        "id": 8745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518117653925,
            21.004673278136412,
            105.83518854432593,
            21.004699051704364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518854432593,
              21.004673278136412
            ],
            [
              105.83518354660127,
              21.004690759301965
            ],
            [
              105.83518296821686,
              21.00469277904292
            ],
            [
              105.83518117653925,
              21.004699051704364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8753",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004673278136412,
          "to_latitude": 21.004699051704364,
          "to_longitude": 105.83518117653925,
          "from_longitude": 105.83518854432593
        }
      },
      {
        "id": 8746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537973204362,
            21.00472173712807,
            105.83538748849475,
            21.004748874675393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538748849475,
              21.00472173712807
            ],
            [
              105.83538309182579,
              21.004737120768528
            ],
            [
              105.8353818548842,
              21.004741444257956
            ],
            [
              105.83537973204362,
              21.004748874675393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8754",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00472173712807,
          "to_latitude": 21.004748874675393,
          "to_longitude": 105.83537973204362,
          "from_longitude": 105.83538748849475
        }
      },
      {
        "id": 8747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537667954214,
            21.004721356150082,
            105.83538432279511,
            21.004748093915975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538432279511,
              21.004721356150082
            ],
            [
              105.83538007707423,
              21.004736209759386
            ],
            [
              105.8353786882173,
              21.00474106238139
            ],
            [
              105.83537667954214,
              21.004748093915975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8755",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004721356150082,
          "to_latitude": 21.004748093915975,
          "to_longitude": 105.83537667954214,
          "from_longitude": 105.83538432279511
        }
      },
      {
        "id": 8748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530477631041,
            21.00512438555867,
            105.83531765788031,
            21.00516876012183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83531765788031,
              21.00512438555867
            ],
            [
              105.83530941544991,
              21.00515321451941
            ],
            [
              105.83530822284683,
              21.005157209919766
            ],
            [
              105.83530554775881,
              21.005166173110624
            ],
            [
              105.83530477631041,
              21.00516876012183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8756",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00512438555867,
          "to_latitude": 21.00516876012183,
          "to_longitude": 105.83530477631041,
          "from_longitude": 105.83531765788031
        }
      },
      {
        "id": 8749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83530282766148,
            21.005123888647308,
            105.83531547744286,
            21.00516825936307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83531547744286,
              21.005123888647308
            ],
            [
              105.83530722180265,
              21.00515276373729
            ],
            [
              105.83530609544604,
              21.005156737135103
            ],
            [
              105.83530355402894,
              21.005165695153764
            ],
            [
              105.83530282766148,
              21.00516825936307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8757",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005123888647308,
          "to_latitude": 21.00516825936307,
          "to_longitude": 105.83530282766148,
          "from_longitude": 105.83531547744286
        }
      },
      {
        "id": 8750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509449147829,
            21.005071286218215,
            105.83510701701654,
            21.005115005085116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83510701701654,
              21.005071286218215
            ],
            [
              105.8350987745679,
              21.005100118782984
            ],
            [
              105.83509673446123,
              21.005107209178366
            ],
            [
              105.83509506424504,
              21.00511301246882
            ],
            [
              105.83509449147829,
              21.005115005085116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8758",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005071286218215,
          "to_latitude": 21.005115005085116,
          "to_longitude": 105.83509449147829,
          "from_longitude": 105.83510701701654
        }
      },
      {
        "id": 8751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509278751062,
            21.005070819407702,
            105.83510532826293,
            21.005114569962053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83510532826293,
              21.005070819407702
            ],
            [
              105.83509710197939,
              21.005099618473746
            ],
            [
              105.83509503627475,
              21.005106775833404
            ],
            [
              105.8350934159757,
              21.005112388298315
            ],
            [
              105.83509278751062,
              21.005114569962053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8759",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005070819407702,
          "to_latitude": 21.005114569962053,
          "to_longitude": 105.83509278751062,
          "from_longitude": 105.83510532826293
        }
      },
      {
        "id": 8752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488619493484,
            21.005021597126866,
            105.83489767839826,
            21.005061762095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489767839826,
              21.005021597126866
            ],
            [
              105.83488949327234,
              21.00505019365966
            ],
            [
              105.83488703725915,
              21.00505877370563
            ],
            [
              105.83488619493484,
              21.005061762095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8760",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005021597126866,
          "to_latitude": 21.005061762095,
          "to_longitude": 105.83488619493484,
          "from_longitude": 105.83489767839826
        }
      },
      {
        "id": 8753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488437248583,
            21.00502111437074,
            105.83489574139502,
            21.005061295938095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489574139502,
              21.00502111437074
            ],
            [
              105.83488747978718,
              21.005050313730106
            ],
            [
              105.8348848960182,
              21.005059443563916
            ],
            [
              105.83488437248583,
              21.005061295938095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8761",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00502111437074,
          "to_latitude": 21.005061295938095,
          "to_longitude": 105.83488437248583,
          "from_longitude": 105.83489574139502
        }
      },
      {
        "id": 8754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83446635915908,
            21.004923950581667,
            105.83447549363166,
            21.004955677815314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447549363166,
              21.004923950581667
            ],
            [
              105.83447027798957,
              21.004942216796714
            ],
            [
              105.8344697230079,
              21.00494412261431
            ],
            [
              105.83446659416809,
              21.004954869179326
            ],
            [
              105.83446635915908,
              21.004955677815314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8762",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004923950581667,
          "to_latitude": 21.004955677815314,
          "to_longitude": 105.83446635915908,
          "from_longitude": 105.83447549363166
        }
      },
      {
        "id": 8755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83446420837791,
            21.004923168799767,
            105.8344735674725,
            21.00495515094105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344735674725,
              21.004923168799767
            ],
            [
              105.83446833662151,
              21.00494146760547
            ],
            [
              105.83446817322606,
              21.004942008535547
            ],
            [
              105.83446447470081,
              21.004954265377336
            ],
            [
              105.83446420837791,
              21.00495515094105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8763",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004923168799767,
          "to_latitude": 21.00495515094105,
          "to_longitude": 105.83446420837791,
          "from_longitude": 105.8344735674725
        }
      },
      {
        "id": 8756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83424938606296,
            21.004869810694956,
            105.83425856172676,
            21.004902441346026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425856172676,
              21.004869810694956
            ],
            [
              105.83425331951466,
              21.004888146582797
            ],
            [
              105.83425319128983,
              21.00488861237256
            ],
            [
              105.834249740556,
              21.004901151608525
            ],
            [
              105.83424938606296,
              21.004902441346026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8764",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004869810694956,
          "to_latitude": 21.004902441346026,
          "to_longitude": 105.83424938606296,
          "from_longitude": 105.83425856172676
        }
      },
      {
        "id": 8757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342473383358,
            21.004869759380675,
            105.83425650015906,
            21.00490193835093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425650015906,
              21.004869759380675
            ],
            [
              105.8342516184718,
              21.004886852469134
            ],
            [
              105.83425128353484,
              21.004888026497888
            ],
            [
              105.83424761378134,
              21.004900963323443
            ],
            [
              105.8342473383358,
              21.00490193835093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8765",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004869759380675,
          "to_latitude": 21.00490193835093,
          "to_longitude": 105.8342473383358,
          "from_longitude": 105.83425650015906
        }
      },
      {
        "id": 8758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340411070662,
            21.004815755553537,
            105.83405100480057,
            21.00484879257193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405100480057,
              21.004815755553537
            ],
            [
              105.83404578910961,
              21.004834021757418
            ],
            [
              105.83404556885046,
              21.004834715610954
            ],
            [
              105.83404143034909,
              21.00484777124581
            ],
            [
              105.8340411070662,
              21.00484879257193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8766",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004815755553537,
          "to_latitude": 21.00484879257193,
          "to_longitude": 105.8340411070662,
          "from_longitude": 105.83405100480057
        }
      },
      {
        "id": 8759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340387223144,
            21.004815276003896,
            105.83404822714816,
            21.00484822258066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83404822714816,
              21.004815276003896
            ],
            [
              105.83404300294083,
              21.004833567539745
            ],
            [
              105.83404275941362,
              21.00483440150731
            ],
            [
              105.83403903190062,
              21.004847159773277
            ],
            [
              105.8340387223144,
              21.00484822258066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8767",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004815276003896,
          "to_latitude": 21.00484822258066,
          "to_longitude": 105.8340387223144,
          "from_longitude": 105.83404822714816
        }
      },
      {
        "id": 8760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415059619716,
            21.004413038513448,
            105.83415740362724,
            21.004436864650764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415740362724,
              21.004413038513448
            ],
            [
              105.83415333427169,
              21.00442728245734
            ],
            [
              105.83415228879987,
              21.00443093029085
            ],
            [
              105.83415059619716,
              21.004436864650764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8768",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004413038513448,
          "to_latitude": 21.004436864650764,
          "to_longitude": 105.83415059619716,
          "from_longitude": 105.83415740362724
        }
      },
      {
        "id": 8761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414748495017,
            21.004412358420648,
            105.83415426320627,
            21.00443606880197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415426320627,
              21.004412358420648
            ],
            [
              105.83415024757089,
              21.004426403391516
            ],
            [
              105.83414914741677,
              21.004430250202685
            ],
            [
              105.83414748495017,
              21.00443606880197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8769",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004412358420648,
          "to_latitude": 21.00443606880197,
          "to_longitude": 105.83414748495017,
          "from_longitude": 105.83415426320627
        }
      },
      {
        "id": 8762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834354081808,
            21.00446390444237,
            105.83436105251258,
            21.004488287774297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436105251258,
              21.00446390444237
            ],
            [
              105.83435632737574,
              21.004480432250357
            ],
            [
              105.83435593674139,
              21.004481795326537
            ],
            [
              105.83435423182812,
              21.004487763167933
            ],
            [
              105.834354081808,
              21.004488287774297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8770",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00446390444237,
          "to_latitude": 21.004488287774297,
          "to_longitude": 105.834354081808,
          "from_longitude": 105.83436105251258
        }
      },
      {
        "id": 8763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435052265611,
            21.004463010558233,
            105.8343574886424,
            21.004487377608843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343574886424,
              21.004463010558233
            ],
            [
              105.83435278992458,
              21.004479446107577
            ],
            [
              105.83435237286582,
              21.004480900539107
            ],
            [
              105.83435065851978,
              21.004486901846068
            ],
            [
              105.83435052265611,
              21.004487377608843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8771",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004463010558233,
          "to_latitude": 21.004487377608843,
          "to_longitude": 105.83435052265611,
          "from_longitude": 105.8343574886424
        }
      },
      {
        "id": 8764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454608408805,
            21.004512373368637,
            105.83455356615066,
            21.004538544892117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455356615066,
              21.004512373368637
            ],
            [
              105.83454897877557,
              21.004528417280063
            ],
            [
              105.8345481805586,
              21.00453120854857
            ],
            [
              105.83454620862503,
              21.00453810802344
            ],
            [
              105.83454608408805,
              21.004538544892117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8772",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004512373368637,
          "to_latitude": 21.004538544892117,
          "to_longitude": 105.83454608408805,
          "from_longitude": 105.83455356615066
        }
      },
      {
        "id": 8765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454364228973,
            21.00451205971663,
            105.83455103470072,
            21.00453792099398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455103470072,
              21.00451205971663
            ],
            [
              105.83454653604069,
              21.004527797902693
            ],
            [
              105.83454564911331,
              21.0045308957997
            ],
            [
              105.83454367435942,
              21.004537807030296
            ],
            [
              105.83454364228973,
              21.00453792099398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8773",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00451205971663,
          "to_latitude": 21.00453792099398,
          "to_longitude": 105.83454364228973,
          "from_longitude": 105.83455103470072
        }
      },
      {
        "id": 8766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467228337553,
            21.004157373306544,
            105.83468209868026,
            21.004191709696553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83468209868026,
              21.004157373306544
            ],
            [
              105.83467791431232,
              21.004172026987067
            ],
            [
              105.83467720569077,
              21.00417449717165
            ],
            [
              105.8346769934717,
              21.00417525240587
            ],
            [
              105.83467277589565,
              21.004189988442658
            ],
            [
              105.83467228337553,
              21.004191709696553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8774",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004157373306544,
          "to_latitude": 21.004191709696553,
          "to_longitude": 105.83467228337553,
          "from_longitude": 105.83468209868026
        }
      },
      {
        "id": 8767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466509284443,
            21.004153738945746,
            105.8346754233379,
            21.004189871668217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346754233379,
              21.004153738945746
            ],
            [
              105.83467075965385,
              21.004170066847532
            ],
            [
              105.83467052938586,
              21.00417086281541
            ],
            [
              105.8346703077192,
              21.00417164880563
            ],
            [
              105.83466563254231,
              21.004187985795898
            ],
            [
              105.83466509284443,
              21.004189871668217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8775",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004153738945746,
          "to_latitude": 21.004189871668217,
          "to_longitude": 105.83466509284443,
          "from_longitude": 105.8346754233379
        }
      },
      {
        "id": 8768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445144214922,
            21.00409808057418,
            105.83446206987475,
            21.004135257065062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446206987475,
              21.00409808057418
            ],
            [
              105.83445793918142,
              21.004112530761
            ],
            [
              105.83445717397609,
              21.004115204448
            ],
            [
              105.83445228095178,
              21.004132326501136
            ],
            [
              105.83445144214922,
              21.004135257065062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8776",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00409808057418,
          "to_latitude": 21.004135257065062,
          "to_longitude": 105.83445144214922,
          "from_longitude": 105.83446206987475
        }
      },
      {
        "id": 8769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444956481272,
            21.004097638216145,
            105.83446018216436,
            21.004134777623808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446018216436,
              21.004097638216145
            ],
            [
              105.834456074113,
              21.004112008808043
            ],
            [
              105.8344552862655,
              21.00411476208992
            ],
            [
              105.83445039228424,
              21.004131885050928
            ],
            [
              105.83444956481272,
              21.004134777623808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8777",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004097638216145,
          "to_latitude": 21.004134777623808,
          "to_longitude": 105.83444956481272,
          "from_longitude": 105.83446018216436
        }
      },
      {
        "id": 8770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343354376827,
            21.00407453465653,
            105.83438382496932,
            21.004115894729406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343354376827,
              21.00407453465653
            ],
            [
              105.83438382496932,
              21.0040862688125
            ],
            [
              105.83438165379089,
              21.004094181778733
            ],
            [
              105.83438048757385,
              21.004098428140967
            ],
            [
              105.83437569552854,
              21.004115894729406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8778",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00407453465653,
          "to_latitude": 21.004115894729406,
          "to_longitude": 105.83437569552854,
          "from_longitude": 105.8343354376827
        }
      },
      {
        "id": 8771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434041286716,
            21.00405713671968,
            105.8343900455341,
            21.004116095019494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83434041286716,
              21.00405713671968
            ],
            [
              105.8343900455341,
              21.004069868253108
            ],
            [
              105.83438282030214,
              21.004094486769908
            ],
            [
              105.83438114189617,
              21.004100204288175
            ],
            [
              105.83437647863649,
              21.004116095019494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8779",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00405713671968,
          "to_latitude": 21.004116095019494,
          "to_longitude": 105.83437647863649,
          "from_longitude": 105.83434041286716
        }
      },
      {
        "id": 8772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8348747758896,
            21.00342766077215,
            105.83488781607376,
            21.0034732626695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488781607376,
              21.00342766077215
            ],
            [
              105.83487850412008,
              21.003460651608503
            ],
            [
              105.83487727692193,
              21.003464998531072
            ],
            [
              105.83487481178611,
              21.003473145074075
            ],
            [
              105.8348747758896,
              21.0034732626695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8780",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00342766077215,
          "to_latitude": 21.0034732626695,
          "to_longitude": 105.8348747758896,
          "from_longitude": 105.83488781607376
        }
      },
      {
        "id": 8773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487084688313,
            21.003425515160508,
            105.83488422506387,
            21.00347225858643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488422506387,
              21.003425515160508
            ],
            [
              105.83487404914003,
              21.003461405976346
            ],
            [
              105.83487330859607,
              21.003464015415116
            ],
            [
              105.83487122270401,
              21.003471000355958
            ],
            [
              105.83487084688313,
              21.00347225858643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8781",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003425515160508,
          "to_latitude": 21.00347225858643,
          "to_longitude": 105.83487084688313,
          "from_longitude": 105.83488422506387
        }
      },
      {
        "id": 8774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465064336605,
            21.00336902794704,
            105.83466385409976,
            21.00341597105279
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466385409976,
              21.00336902794704
            ],
            [
              105.83465446529368,
              21.00340239037534
            ],
            [
              105.83465308346292,
              21.003407298054796
            ],
            [
              105.83465064336605,
              21.00341597105279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8782",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00336902794704,
          "to_latitude": 21.00341597105279,
          "to_longitude": 105.83465064336605,
          "from_longitude": 105.83466385409976
        }
      },
      {
        "id": 8775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346381609029,
            21.00336729365802,
            105.8346511642815,
            21.00341278064928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346511642815,
              21.00336729365802
            ],
            [
              105.83464189661326,
              21.00339926271744
            ],
            [
              105.83463940887374,
              21.00340784381988
            ],
            [
              105.8346381609029,
              21.00341278064928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8783",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00336729365802,
          "to_latitude": 21.00341278064928,
          "to_longitude": 105.8346381609029,
          "from_longitude": 105.8346511642815
        }
      },
      {
        "id": 8776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445626795603,
            21.003320313545935,
            105.83446947261857,
            21.003366282931214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446947261857,
              21.003320313545935
            ],
            [
              105.83446036788281,
              21.003352184249472
            ],
            [
              105.83446022725137,
              21.00335266726154
            ],
            [
              105.83445769593072,
              21.003361369607582
            ],
            [
              105.83445626795603,
              21.003366282931214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8784",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003320313545935,
          "to_latitude": 21.003366282931214,
          "to_longitude": 105.83445626795603,
          "from_longitude": 105.83446947261857
        }
      },
      {
        "id": 8777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344386197149,
            21.00331631287558,
            105.83445147517932,
            21.003361772031532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83445147517932,
              21.00331631287558
            ],
            [
              105.83444241466688,
              21.003348007232425
            ],
            [
              105.83444234956082,
              21.003348234261296
            ],
            [
              105.83444005752482,
              21.00335655156303
            ],
            [
              105.8344386197149,
              21.003361772031532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8785",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00331631287558,
          "to_latitude": 21.003361772031532,
          "to_longitude": 105.8344386197149,
          "from_longitude": 105.83445147517932
        }
      },
      {
        "id": 8778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434882930773,
            21.00333391413102,
            105.8350964718013,
            21.00350912128734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350964718013,
              21.00336055085345
            ],
            [
              105.83505399940492,
              21.00350912128734
            ],
            [
              105.834881159623,
              21.003464940647614
            ],
            [
              105.83487850412008,
              21.003460651608503
            ],
            [
              105.83487774997886,
              21.003459433243076
            ],
            [
              105.83487404914003,
              21.003461405976346
            ],
            [
              105.83487187217634,
              21.003462566460772
            ],
            [
              105.83481517175453,
              21.003448072308114
            ],
            [
              105.8346693309056,
              21.003410363413938
            ],
            [
              105.83465971773826,
              21.003407877909343
            ],
            [
              105.83465516267961,
              21.003402570310406
            ],
            [
              105.83465446529368,
              21.00340239037534
            ],
            [
              105.83464204814071,
              21.003399182490536
            ],
            [
              105.83464189661326,
              21.00339926271744
            ],
            [
              105.83463616937344,
              21.0034023157049
            ],
            [
              105.83446603262928,
              21.00335882409109
            ],
            [
              105.83446263540097,
              21.003353297649056
            ],
            [
              105.83446022725137,
              21.00335266726154
            ],
            [
              105.83444241466688,
              21.003348007232425
            ],
            [
              105.8344402434795,
              21.003347438908747
            ],
            [
              105.83443419555651,
              21.003351634235887
            ],
            [
              105.83434882930773,
              21.00333391413102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8786",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00336055085345,
          "to_latitude": 21.00333391413102,
          "to_longitude": 105.83434882930773,
          "from_longitude": 105.8350964718013
        }
      },
      {
        "id": 8779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83476272697602,
            21.003775083417484,
            105.83477336352631,
            21.003812558034127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477336352631,
              21.003775083417484
            ],
            [
              105.83476552591836,
              21.003803033339533
            ],
            [
              105.83476460820816,
              21.003806302098333
            ],
            [
              105.83476272697602,
              21.003812558034127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8787",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003775083417484,
          "to_latitude": 21.003812558034127,
          "to_longitude": 105.83476272697602,
          "from_longitude": 105.83477336352631
        }
      },
      {
        "id": 8780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83476068138198,
            21.00377458829753,
            105.83477136875928,
            21.003812090260112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477136875928,
              21.00377458829753
            ],
            [
              105.83476342292079,
              21.003802620943844
            ],
            [
              105.83476256540158,
              21.003805643730015
            ],
            [
              105.83476068138198,
              21.003812090260112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8788",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00377458829753,
          "to_latitude": 21.003812090260112,
          "to_longitude": 105.83476068138198,
          "from_longitude": 105.83477136875928
        }
      },
      {
        "id": 8781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456516732171,
            21.00372453352405,
            105.83457517096616,
            21.00376097896201
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83457517096616,
              21.00372453352405
            ],
            [
              105.83456693508583,
              21.003753343455465
            ],
            [
              105.83456642503904,
              21.003755545311197
            ],
            [
              105.83456535234332,
              21.00376017599029
            ],
            [
              105.83456516732171,
              21.00376097896201
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8789",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00372453352405,
          "to_latitude": 21.00376097896201,
          "to_longitude": 105.83456516732171,
          "from_longitude": 105.83457517096616
        }
      },
      {
        "id": 8782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455435301795,
            21.003721963672998,
            105.8345659959191,
            21.003758884115825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345659959191,
              21.003721963672998
            ],
            [
              105.83455518005854,
              21.003756260705813
            ],
            [
              105.83455481425709,
              21.00375741862846
            ],
            [
              105.83455435301795,
              21.003758884115825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8790",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003721963672998,
          "to_latitude": 21.003758884115825,
          "to_longitude": 105.83455435301795,
          "from_longitude": 105.8345659959191
        }
      },
      {
        "id": 8783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436549954014,
            21.003673550201007,
            105.83437580073023,
            21.003710982506735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83437580073023,
              21.003673550201007
            ],
            [
              105.83436732954522,
              21.003704333019453
            ],
            [
              105.83436636031678,
              21.003707852222483
            ],
            [
              105.83436549954014,
              21.003710982506735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8791",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003673550201007,
          "to_latitude": 21.003710982506735,
          "to_longitude": 105.83436549954014,
          "from_longitude": 105.83437580073023
        }
      },
      {
        "id": 8784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343505335001,
            21.003669914444398,
            105.8343611160011,
            21.003707266838013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343611160011,
              21.003669914444398
            ],
            [
              105.83435287321504,
              21.00369921936834
            ],
            [
              105.83435152628205,
              21.003704004938978
            ],
            [
              105.8343505335001,
              21.003707266838013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8792",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003669914444398,
          "to_latitude": 21.003707266838013,
          "to_longitude": 105.8343505335001,
          "from_longitude": 105.8343611160011
        }
      },
      {
        "id": 8785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504067194609,
            21.00377683706023,
            105.83585839038273,
            21.004062644016734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585839038273,
              21.00377683706023
            ],
            [
              105.83577668578644,
              21.004062644016734
            ],
            [
              105.83562712783724,
              21.004024998480595
            ],
            [
              105.835600548924,
              21.00401605426082
            ],
            [
              105.83559676941869,
              21.004014782106385
            ],
            [
              105.83559321637729,
              21.004013586517466
            ],
            [
              105.83559278590874,
              21.004013493792097
            ],
            [
              105.83541436634229,
              21.003974969109503
            ],
            [
              105.83541382054501,
              21.00396556198364
            ],
            [
              105.83541151730253,
              21.00396494554674
            ],
            [
              105.8353943792877,
              21.003960358180514
            ],
            [
              105.83539314337777,
              21.00396002734337
            ],
            [
              105.83538152088232,
              21.00396636361258
            ],
            [
              105.83520995195508,
              21.003924583282725
            ],
            [
              105.83520629259334,
              21.00391862730469
            ],
            [
              105.8352056182633,
              21.003918442742705
            ],
            [
              105.83518686032855,
              21.003913308723646
            ],
            [
              105.83518634783817,
              21.003913168528754
            ],
            [
              105.8351846418451,
              21.003915478318966
            ],
            [
              105.83504067194609,
              21.003879445999313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8793",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 115.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00377683706023,
          "to_latitude": 21.003879445999313,
          "to_longitude": 105.83504067194609,
          "from_longitude": 105.83585839038273
        }
      },
      {
        "id": 8786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423481486045,
            21.003663636495972,
            105.83500426649965,
            21.003856483261078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83500426649965,
              21.00366956500592
            ],
            [
              105.83495083044558,
              21.003856483261078
            ],
            [
              105.8347703672705,
              21.00381035191772
            ],
            [
              105.83476982916929,
              21.003803877524795
            ],
            [
              105.83476552591836,
              21.003803033339533
            ],
            [
              105.83476342292079,
              21.003802620943844
            ],
            [
              105.83476126889073,
              21.003802198862935
            ],
            [
              105.8347553872804,
              21.003805342031093
            ],
            [
              105.83472690439463,
              21.003799241332295
            ],
            [
              105.83471526857605,
              21.003796267170518
            ],
            [
              105.83459480993862,
              21.0037654761952
            ],
            [
              105.83459140314788,
              21.00375996244846
            ],
            [
              105.83458552343396,
              21.00376310108538
            ],
            [
              105.83456939194932,
              21.00376031153792
            ],
            [
              105.83456755853926,
              21.003755795366267
            ],
            [
              105.83456642503904,
              21.003755545311197
            ],
            [
              105.83455944492565,
              21.00375400792335
            ],
            [
              105.83455518005854,
              21.003756260705813
            ],
            [
              105.83455357558044,
              21.00375710857286
            ],
            [
              105.83441244495482,
              21.003723767855544
            ],
            [
              105.83437283275993,
              21.003708732752145
            ],
            [
              105.83436943552661,
              21.003703206308632
            ],
            [
              105.83436732954522,
              21.003704333019453
            ],
            [
              105.83436354529998,
              21.003706357634666
            ],
            [
              105.83435657923937,
              21.003704579008172
            ],
            [
              105.83435317338535,
              21.003699058929165
            ],
            [
              105.83435287321504,
              21.00369921936834
            ],
            [
              105.83434729082313,
              21.003702204797708
            ],
            [
              105.83431050510868,
              21.0036928009858
            ],
            [
              105.83423481486045,
              21.003663636495972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8794",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 102.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00366956500592,
          "to_latitude": 21.003663636495972,
          "to_longitude": 105.83423481486045,
          "from_longitude": 105.83500426649965
        }
      },
      {
        "id": 8787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83426577507322,
            21.003697701654225,
            105.83495333529669,
            21.003873457036654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83495333529669,
              21.003873457036654
            ],
            [
              105.83460955644108,
              21.00378558014436
            ],
            [
              105.8345785019133,
              21.003777642224016
            ],
            [
              105.83426577507322,
              21.003697701654225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8795",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003873457036654,
          "to_latitude": 21.003697701654225,
          "to_longitude": 105.83426577507322,
          "from_longitude": 105.83495333529669
        }
      },
      {
        "id": 8788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558825342972,
            21.004013493792097,
            105.83559278590874,
            21.00402935500776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559278590874,
              21.004013493792097
            ],
            [
              105.83559226037413,
              21.0040153299201
            ],
            [
              105.83559091027128,
              21.004020057708463
            ],
            [
              105.83558825342972,
              21.00402935500776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8796",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004013493792097,
          "to_latitude": 21.00402935500776,
          "to_longitude": 105.83558825342972,
          "from_longitude": 105.83559278590874
        }
      },
      {
        "id": 8789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83471406275447,
            21.003796267170518,
            105.83471526857605,
            21.00380048121296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83471526857605,
              21.003796267170518
            ],
            [
              105.83471437032492,
              21.00379940305946
            ],
            [
              105.83471406275447,
              21.00380048121296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8797",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003796267170518,
          "to_latitude": 21.00380048121296,
          "to_longitude": 105.83471406275447,
          "from_longitude": 105.83471526857605
        }
      },
      {
        "id": 8790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8345785019133,
            21.00376407483686,
            105.83458237683166,
            21.003777642224016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345785019133,
              21.003777642224016
            ],
            [
              105.83458137767559,
              21.003767573413487
            ],
            [
              105.83458220038385,
              21.0037646898956
            ],
            [
              105.83458237683166,
              21.00376407483686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8798",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003777642224016,
          "to_latitude": 21.00376407483686,
          "to_longitude": 105.83458237683166,
          "from_longitude": 105.8345785019133
        }
      },
      {
        "id": 8791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551619792748,
            21.003622579102164,
            105.83552038322198,
            21.003637221967274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83552038322198,
              21.003622579102164
            ],
            [
              105.83552002846601,
              21.00362381916599
            ],
            [
              105.83551837548809,
              21.00362960340827
            ],
            [
              105.83551619792748,
              21.003637221967274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT99_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8799",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003622579102164,
          "to_latitude": 21.003637221967274,
          "to_longitude": 105.83551619792748,
          "from_longitude": 105.83552038322198
        }
      },
      {
        "id": 8792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346665645529,
            21.003410363413938,
            105.8346693309056,
            21.003420040592694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346693309056,
              21.003410363413938
            ],
            [
              105.83466909125642,
              21.003411201879473
            ],
            [
              105.83466878651159,
              21.00341226918036
            ],
            [
              105.83466776563928,
              21.003415840122084
            ],
            [
              105.8346665645529,
              21.003420040592694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8800",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003410363413938,
          "to_latitude": 21.003420040592694,
          "to_longitude": 105.8346665645529,
          "from_longitude": 105.8346693309056
        }
      },
      {
        "id": 8793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83493755166245,
            21.0041208456015,
            105.83575617883402,
            21.00442677209397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575617883402,
              21.0041208456015
            ],
            [
              105.83566872334187,
              21.00442677209397
            ],
            [
              105.83551014502879,
              21.004387287529173
            ],
            [
              105.83550673914192,
              21.004381771991607
            ],
            [
              105.8355043309115,
              21.004381130781226
            ],
            [
              105.8354989364831,
              21.00437969584273
            ],
            [
              105.83549584624572,
              21.004378873722075
            ],
            [
              105.83548996367782,
              21.004382018726762
            ],
            [
              105.83530104792044,
              21.004331412432112
            ],
            [
              105.83529826610145,
              21.004330701697402
            ],
            [
              105.83529621344927,
              21.00433017706151
            ],
            [
              105.8352919675106,
              21.00432909144764
            ],
            [
              105.835112245366,
              21.004283151045406
            ],
            [
              105.83511460689718,
              21.00428732771581
            ],
            [
              105.83510491423813,
              21.004284742370412
            ],
            [
              105.83510178546071,
              21.004283907786423
            ],
            [
              105.83509788604741,
              21.004282867435116
            ],
            [
              105.83509198921261,
              21.004286042302645
            ],
            [
              105.83493755166245,
              21.004246668749555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8801",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 117.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0041208456015,
          "to_latitude": 21.004246668749555,
          "to_longitude": 105.83493755166245,
          "from_longitude": 105.83575617883402
        }
      },
      {
        "id": 8794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83431381149944,
            21.004043635071557,
            105.83491072601507,
            21.00422645231965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83491072601507,
              21.004043635071557
            ],
            [
              105.83485846251206,
              21.00422645231965
            ],
            [
              105.83468170887495,
              21.004173066959137
            ],
            [
              105.83467791431232,
              21.004172026987067
            ],
            [
              105.83467075965385,
              21.004170066847532
            ],
            [
              105.8346691027226,
              21.00416961252142
            ],
            [
              105.83464936407024,
              21.00416420415624
            ],
            [
              105.83446261617999,
              21.004118315572985
            ],
            [
              105.83446035240353,
              21.00411320628456
            ],
            [
              105.83445793918142,
              21.004112530761
            ],
            [
              105.834456074113,
              21.004112008808043
            ],
            [
              105.83445459150138,
              21.00411159427034
            ],
            [
              105.83444869939258,
              21.004114755544297
            ],
            [
              105.83439248737847,
              21.004101644411648
            ],
            [
              105.83438908152363,
              21.004096126140347
            ],
            [
              105.83438282030214,
              21.004094486769908
            ],
            [
              105.83438165379089,
              21.004094181778733
            ],
            [
              105.83437757725332,
              21.00409311427891
            ],
            [
              105.83437169941543,
              21.00409624748028
            ],
            [
              105.83434416457638,
              21.004088880264785
            ],
            [
              105.83431381149944,
              21.004185853054217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8802",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004043635071557,
          "to_latitude": 21.004185853054217,
          "to_longitude": 105.83431381149944,
          "from_longitude": 105.83491072601507
        }
      },
      {
        "id": 8795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529266549762,
            21.004330701697402,
            105.83529826610145,
            21.004350290340586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83529826610145,
              21.004330701697402
            ],
            [
              105.83529768676799,
              21.00433272324983
            ],
            [
              105.83529597622854,
              21.004338710095006
            ],
            [
              105.83529266549762,
              21.004350290340586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8803",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004330701697402,
          "to_latitude": 21.004350290340586,
          "to_longitude": 105.83529266549762,
          "from_longitude": 105.83529826610145
        }
      },
      {
        "id": 8796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483222443247,
            21.00448857322641,
            105.83565350928866,
            21.00479326864962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83565350928866,
              21.00448857322641
            ],
            [
              105.83556640532203,
              21.00479326864962
            ],
            [
              105.83539174584547,
              21.00474422461308
            ],
            [
              105.83538833993416,
              21.00473870546071
            ],
            [
              105.83538309182579,
              21.004737120768528
            ],
            [
              105.83538007707423,
              21.004736209759386
            ],
            [
              105.83537783118848,
              21.004735531621556
            ],
            [
              105.83537194955602,
              21.004738674811463
            ],
            [
              105.83521520342263,
              21.004698860342653
            ],
            [
              105.83518860263834,
              21.00469205320105
            ],
            [
              105.8351863409556,
              21.00469147449297
            ],
            [
              105.83518354660127,
              21.004690759301965
            ],
            [
              105.83517528875863,
              21.004688645630324
            ],
            [
              105.83499441511012,
              21.004646899927792
            ],
            [
              105.8349909998081,
              21.00464141874847
            ],
            [
              105.83498835632368,
              21.004640677523724
            ],
            [
              105.83498570031529,
              21.004639932747487
            ],
            [
              105.83498304719745,
              21.00463918886023
            ],
            [
              105.8349771569536,
              21.00464234111116
            ],
            [
              105.83483222443247,
              21.00460559981081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8804",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 117.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00448857322641,
          "to_latitude": 21.00460559981081,
          "to_longitude": 105.83483222443247,
          "from_longitude": 105.83565350928866
        }
      },
      {
        "id": 8797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405189773863,
            21.00440613285185,
            105.83473623225863,
            21.004581063915797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83473623225863,
              21.004581063915797
            ],
            [
              105.83455647546414,
              21.00453497406932
            ],
            [
              105.83455306959294,
              21.004529455802107
            ],
            [
              105.83454897877557,
              21.004528417280063
            ],
            [
              105.83454653604069,
              21.004527797902693
            ],
            [
              105.83454641852552,
              21.00452776776922
            ],
            [
              105.83454054067467,
              21.004530900976718
            ],
            [
              105.83449970609026,
              21.004520603000806
            ],
            [
              105.83437289040879,
              21.00448505234521
            ],
            [
              105.83435856237206,
              21.00448105626177
            ],
            [
              105.83435632737574,
              21.004480432250357
            ],
            [
              105.83435278992458,
              21.004479446107577
            ],
            [
              105.83435243059125,
              21.00447934580458
            ],
            [
              105.83416002934027,
              21.004433773645083
            ],
            [
              105.83415663773766,
              21.00442822368656
            ],
            [
              105.83415333427169,
              21.00442728245734
            ],
            [
              105.83415024757089,
              21.004426403391516
            ],
            [
              105.83414586125642,
              21.00442515404975
            ],
            [
              105.83413997960845,
              21.004428297197638
            ],
            [
              105.83405189773863,
              21.00440613285185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8805",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004581063915797,
          "to_latitude": 21.00440613285185,
          "to_longitude": 105.83405189773863,
          "from_longitude": 105.83473623225863
        }
      },
      {
        "id": 8798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83392309143574,
            21.00480720285063,
            105.83459157855785,
            21.004978082539335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83392309143574,
              21.00480720285063
            ],
            [
              105.83403883135658,
              21.004836516240047
            ],
            [
              105.83404275941362,
              21.00483440150731
            ],
            [
              105.83404472919307,
              21.004833340499815
            ],
            [
              105.83404556885046,
              21.004834715610954
            ],
            [
              105.83404811888265,
              21.004838891372774
            ],
            [
              105.8342461058724,
              21.004889769812067
            ],
            [
              105.8342516184718,
              21.004886852469134
            ],
            [
              105.83425197619941,
              21.004886663747953
            ],
            [
              105.83425319128983,
              21.00488861237256
            ],
            [
              105.83425539437143,
              21.00489214583172
            ],
            [
              105.83425837944151,
              21.004892908877345
            ],
            [
              105.83440166284097,
              21.004929535907497
            ],
            [
              105.8344624710331,
              21.00494507946012
            ],
            [
              105.83446817322606,
              21.004942008535547
            ],
            [
              105.83446836790242,
              21.004941903710115
            ],
            [
              105.8344697230079,
              21.00494412261431
            ],
            [
              105.83447175857175,
              21.004947454569244
            ],
            [
              105.83459157855785,
              21.004978082539335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8806",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00480720285063,
          "to_latitude": 21.004978082539335,
          "to_longitude": 105.83459157855785,
          "from_longitude": 105.83392309143574
        }
      },
      {
        "id": 8799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83469290730942,
            21.005003983304075,
            105.83545813524432,
            21.005199587766366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83469290730942,
              21.005003983304075
            ],
            [
              105.83488316804538,
              21.005052618235155
            ],
            [
              105.83488747978718,
              21.005050313730106
            ],
            [
              105.83488904970078,
              21.00504947506151
            ],
            [
              105.83488949327234,
              21.00505019365966
            ],
            [
              105.8348924555815,
              21.005054990611246
            ],
            [
              105.83509313291422,
              21.005106289301587
            ],
            [
              105.83509503627475,
              21.005106775833404
            ],
            [
              105.83509673446123,
              21.005107209178366
            ],
            [
              105.83509749060345,
              21.005107402371436
            ],
            [
              105.83510242045661,
              21.005108661665982
            ],
            [
              105.83523973215557,
              21.005143761021113
            ],
            [
              105.83530170603503,
              21.00515960293488
            ],
            [
              105.83530502163369,
              21.005156498534326
            ],
            [
              105.83530609544604,
              21.005156737135103
            ],
            [
              105.83530822284683,
              21.005157209919766
            ],
            [
              105.83530986389859,
              21.005157573994616
            ],
            [
              105.83531099455548,
              21.005161977089333
            ],
            [
              105.83545813524432,
              21.005199587766366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8807",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005003983304075,
          "to_latitude": 21.005199587766366,
          "to_longitude": 105.83545813524432,
          "from_longitude": 105.83469290730942
        }
      },
      {
        "id": 8800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509526207328,
            21.005107402371436,
            105.83509749060345,
            21.005115202723406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509749060345,
              21.005107402371436
            ],
            [
              105.83509632538392,
              21.005111479829658
            ],
            [
              105.83509526207328,
              21.005115202723406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8808",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005107402371436,
          "to_latitude": 21.005115202723406,
          "to_longitude": 105.83509526207328,
          "from_longitude": 105.83509749060345
        }
      },
      {
        "id": 8801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425524509454,
            21.004892908877345,
            105.83425837944151,
            21.004903878562686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425837944151,
              21.004892908877345
            ],
            [
              105.83425571966788,
              21.004902215204332
            ],
            [
              105.83425524509454,
              21.004903878562686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DODV.8809",
          "diaChiLapD": "Ngõ 28, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004892908877345,
          "to_latitude": 21.004903878562686,
          "to_longitude": 105.83425524509454,
          "from_longitude": 105.83425837944151
        }
      },
      {
        "id": 8802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518623352835,
            21.00469205320105,
            105.83518860263834,
            21.004700343792393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83518860263834,
              21.00469205320105
            ],
            [
              105.83518771384003,
              21.004695158335977
            ],
            [
              105.83518662885423,
              21.004698960820548
            ],
            [
              105.83518623352835,
              21.004700343792393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8810",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00469205320105,
          "to_latitude": 21.004700343792393,
          "to_longitude": 105.83518623352835,
          "from_longitude": 105.83518860263834
        }
      },
      {
        "id": 8803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435651397136,
            21.00448105626177,
            105.83435856237206,
            21.004488909012743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435651397136,
              21.004488909012743
            ],
            [
              105.83435654315065,
              21.00448879416003
            ],
            [
              105.83435821891575,
              21.004482254719584
            ],
            [
              105.83435856237206,
              21.00448105626177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8811",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004488909012743,
          "to_latitude": 21.00448105626177,
          "to_longitude": 105.83435856237206,
          "from_longitude": 105.83435651397136
        }
      },
      {
        "id": 8804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466343126456,
            21.00416961252142,
            105.8346691027226,
            21.00418944626809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466343126456,
              21.00418944626809
            ],
            [
              105.83466714964875,
              21.00417644235682
            ],
            [
              105.83466886020692,
              21.00417045461388
            ],
            [
              105.8346691027226,
              21.00416961252142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8812",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00418944626809,
          "to_latitude": 21.00416961252142,
          "to_longitude": 105.8346691027226,
          "from_longitude": 105.83466343126456
        }
      },
      {
        "id": 8805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83333441653251,
            21.00345381284035,
            105.83400565915589,
            21.003620834718408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83333441653251,
              21.00345381284035
            ],
            [
              105.83364081880237,
              21.00353005309605
            ],
            [
              105.83367003718568,
              21.003537323667707
            ],
            [
              105.83400565915589,
              21.003620834718408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8813",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00345381284035,
          "to_latitude": 21.003620834718408,
          "to_longitude": 105.83400565915589,
          "from_longitude": 105.83333441653251
        }
      },
      {
        "id": 8806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83332001063023,
            21.00341532735944,
            105.83407577888505,
            21.003603381313987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83332001063023,
              21.00341532735944
            ],
            [
              105.8334631482849,
              21.003450944333256
            ],
            [
              105.83346599650685,
              21.003455476322948
            ],
            [
              105.83346659623591,
              21.00345643080584
            ],
            [
              105.83347245269339,
              21.00345325890167
            ],
            [
              105.83348263933453,
              21.00345578411723
            ],
            [
              105.83348508378845,
              21.003459661438452
            ],
            [
              105.83348609107355,
              21.003461259732138
            ],
            [
              105.83349194375366,
              21.00345810049097
            ],
            [
              105.83383898316586,
              21.003544460743363
            ],
            [
              105.83386307353483,
              21.003550455757843
            ],
            [
              105.83386367359986,
              21.003550604560033
            ],
            [
              105.83386445091293,
              21.003551839977945
            ],
            [
              105.83386712251448,
              21.00355608830958
            ],
            [
              105.83387297801998,
              21.003552919106124
            ],
            [
              105.83388497263907,
              21.00355590421392
            ],
            [
              105.8338884196459,
              21.00356139068201
            ],
            [
              105.83388932587347,
              21.003560899403396
            ],
            [
              105.83389427610294,
              21.003558219666715
            ],
            [
              105.83407577888505,
              21.003603381313987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8814",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00341532735944,
          "to_latitude": 21.003603381313987,
          "to_longitude": 105.83407577888505,
          "from_longitude": 105.83332001063023
        }
      },
      {
        "id": 8807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388841118132,
            21.003548422066466,
            105.83389267980722,
            21.003564195240035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83389267980722,
              21.003548422066466
            ],
            [
              105.83388932587347,
              21.003560899403396
            ],
            [
              105.83388879907801,
              21.0035628583717
            ],
            [
              105.83388841118132,
              21.003564195240035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8815",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003548422066466,
          "to_latitude": 21.003564195240035,
          "to_longitude": 105.83388841118132,
          "from_longitude": 105.83389267980722
        }
      },
      {
        "id": 8808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83386280804658,
            21.003541985820775,
            105.83386720864179,
            21.003557724414538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386720864179,
              21.003541985820775
            ],
            [
              105.83386445091293,
              21.003551839977945
            ],
            [
              105.83386305505397,
              21.003556824494606
            ],
            [
              105.83386280804658,
              21.003557724414538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8816",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003541985820775,
          "to_latitude": 21.003557724414538,
          "to_longitude": 105.83386280804658,
          "from_longitude": 105.83386720864179
        }
      },
      {
        "id": 8809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83348415866827,
            21.00344921947557,
            105.83348798736486,
            21.0034629862275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83348798736486,
              21.00344921947557
            ],
            [
              105.83348508378845,
              21.003459661438452
            ],
            [
              105.83348447833957,
              21.003461833952148
            ],
            [
              105.83348415866827,
              21.0034629862275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8817",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00344921947557,
          "to_latitude": 21.0034629862275,
          "to_longitude": 105.83348415866827,
          "from_longitude": 105.83348798736486
        }
      },
      {
        "id": 8810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83346521850306,
            21.003444289984667,
            105.83346910852171,
            21.00345827290753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83346910852171,
              21.003444289984667
            ],
            [
              105.83346599650685,
              21.003455476322948
            ],
            [
              105.8334655985331,
              21.003456904465633
            ],
            [
              105.83346527508454,
              21.0034580694046
            ],
            [
              105.83346521850306,
              21.00345827290753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8818",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003444289984667,
          "to_latitude": 21.00345827290753,
          "to_longitude": 105.83346521850306,
          "from_longitude": 105.83346910852171
        }
      },
      {
        "id": 8811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83386116580772,
            21.003550455757843,
            105.83386307353483,
            21.003557316072808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386307353483,
              21.003550455757843
            ],
            [
              105.83386168161856,
              21.003555457416482
            ],
            [
              105.83386116580772,
              21.003557316072808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8819",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003550455757843,
          "to_latitude": 21.003557316072808,
          "to_longitude": 105.83386116580772,
          "from_longitude": 105.83386307353483
        }
      },
      {
        "id": 8812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364081880237,
            21.00350356875543,
            105.83364818478188,
            21.00353005309605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364081880237,
              21.00353005309605
            ],
            [
              105.83364522178917,
              21.003514221463377
            ],
            [
              105.83364756517861,
              21.003505790113174
            ],
            [
              105.83364818478188,
              21.00350356875543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8820",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00353005309605,
          "to_latitude": 21.00350356875543,
          "to_longitude": 105.83364818478188,
          "from_longitude": 105.83364081880237
        }
      },
      {
        "id": 8813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334458883176,
            21.00317779831449,
            105.83418052872453,
            21.003634273689926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334458883176,
              21.003209586590625
            ],
            [
              105.83348778823466,
              21.00317779831449
            ],
            [
              105.83357118386897,
              21.003238196905944
            ],
            [
              105.83366823798818,
              21.003360597345406
            ],
            [
              105.83403304176043,
              21.00347238994297
            ],
            [
              105.83418052872453,
              21.003512430672675
            ],
            [
              105.83413957914206,
              21.00361551601555
            ],
            [
              105.83413212894364,
              21.003634273689926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8821",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 103.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003209586590625,
          "to_latitude": 21.003634273689926,
          "to_longitude": 105.83413212894364,
          "from_longitude": 105.8334458883176
        }
      },
      {
        "id": 8814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421311398774,
            21.00305344668764,
            105.8343596069826,
            21.003481000387414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343596069826,
              21.00305344668764
            ],
            [
              105.83428356390604,
              21.003300952877176
            ],
            [
              105.83427873068744,
              21.003314644181593
            ],
            [
              105.8342779522967,
              21.003316849158402
            ],
            [
              105.8342725594469,
              21.003319323333415
            ],
            [
              105.83427146092107,
              21.003319827298466
            ],
            [
              105.83427442264576,
              21.00332522129849
            ],
            [
              105.83421311398774,
              21.003481000387414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8822",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00305344668764,
          "to_latitude": 21.003481000387414,
          "to_longitude": 105.83421311398774,
          "from_longitude": 105.8343596069826
        }
      },
      {
        "id": 8815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427873068744,
            21.003314644181593,
            105.83429797402125,
            21.00332539312968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83427873068744,
              21.003314644181593
            ],
            [
              105.83428102509865,
              21.003315231774373
            ],
            [
              105.83429797402125,
              21.0033195627636
            ],
            [
              105.83429630682726,
              21.00332539312968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8823",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003314644181593,
          "to_latitude": 21.00332539312968,
          "to_longitude": 105.83429630682726,
          "from_longitude": 105.83427873068744
        }
      },
      {
        "id": 8816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83391248079963,
            21.003218831836254,
            105.83416151735828,
            21.00339663671324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416151735828,
              21.003332920080936
            ],
            [
              105.83407868708818,
              21.00339663671324
            ],
            [
              105.83399193820836,
              21.003281897768765
            ],
            [
              105.83397354600982,
              21.003257049816597
            ],
            [
              105.83395452291292,
              21.003231349598334
            ],
            [
              105.83391248079963,
              21.003218831836254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8824",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003332920080936,
          "to_latitude": 21.003218831836254,
          "to_longitude": 105.83391248079963,
          "from_longitude": 105.83416151735828
        }
      },
      {
        "id": 8817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397354600982,
            21.003235873134365,
            105.83400425875402,
            21.003257049816597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83397354600982,
              21.003257049816597
            ],
            [
              105.83399827294512,
              21.003239996787094
            ],
            [
              105.83400425875402,
              21.003235873134365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8825",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003257049816597,
          "to_latitude": 21.003235873134365,
          "to_longitude": 105.83400425875402,
          "from_longitude": 105.83397354600982
        }
      },
      {
        "id": 8818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83407747065046,
            21.00320031302251,
            105.83409718142404,
            21.00326514833186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83409718142404,
              21.00320031302251
            ],
            [
              105.83407764727055,
              21.003264563982412
            ],
            [
              105.83407747065046,
              21.00326514833186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8826",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00320031302251,
          "to_latitude": 21.00326514833186,
          "to_longitude": 105.83407747065046,
          "from_longitude": 105.83409718142404
        }
      },
      {
        "id": 8819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402708404759,
            21.003189533735778,
            105.83404616233992,
            21.003247892520292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83404616233992,
              21.003189533735778
            ],
            [
              105.83402736966234,
              21.00324701679805
            ],
            [
              105.83402708404759,
              21.003247892520292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8827",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003189533735778,
          "to_latitude": 21.003247892520292,
          "to_longitude": 105.83402708404759,
          "from_longitude": 105.83404616233992
        }
      },
      {
        "id": 8820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83389906500722,
            21.0031502720045,
            105.83391165358015,
            21.003196360268966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83391165358015,
              21.0031502720045
            ],
            [
              105.83389969658754,
              21.003194044916196
            ],
            [
              105.83389906500722,
              21.003196360268966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8828",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0031502720045,
          "to_latitude": 21.003196360268966,
          "to_longitude": 105.83389906500722,
          "from_longitude": 105.83391165358015
        }
      },
      {
        "id": 8821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385207756703,
            21.003139546349583,
            105.83390012864974,
            21.003195889297096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83386239694407,
              21.003139546349583
            ],
            [
              105.83385207756703,
              21.003173722588514
            ],
            [
              105.83390012864974,
              21.003186634539876
            ],
            [
              105.83389739662395,
              21.00319557360387
            ],
            [
              105.83389730123034,
              21.003195889297096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8829",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003139546349583,
          "to_latitude": 21.003195889297096,
          "to_longitude": 105.83389730123034,
          "from_longitude": 105.83386239694407
        }
      },
      {
        "id": 8822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696336685138,
            21.00926460001889,
            105.83699257566583,
            21.0092997960306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83699257566583,
              21.00926460001889
            ],
            [
              105.83696954250401,
              21.009289155474217
            ],
            [
              105.836968175591,
              21.009290696776233
            ],
            [
              105.83696982886656,
              21.009292208772134
            ],
            [
              105.83696924355841,
              21.0092929857146
            ],
            [
              105.83696876615056,
              21.00929362032008
            ],
            [
              105.83696797264248,
              21.00929311299761
            ],
            [
              105.83696338791675,
              21.009299778765733
            ],
            [
              105.83696336685138,
              21.0092997960306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8830",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00926460001889,
          "to_latitude": 21.0092997960306,
          "to_longitude": 105.83696336685138,
          "from_longitude": 105.83699257566583
        }
      },
      {
        "id": 8823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718184879028,
            21.0094437720071,
            105.8372169101958,
            21.00948061452476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372169101958,
              21.0094437720071
            ],
            [
              105.83719148048633,
              21.00947048353807
            ],
            [
              105.83719033484823,
              21.00947168685036
            ],
            [
              105.83718184879028,
              21.00948061452476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1.1_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8831",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0094437720071,
          "to_latitude": 21.00948061452476,
          "to_longitude": 105.83718184879028,
          "from_longitude": 105.8372169101958
        }
      },
      {
        "id": 8824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83686022674435,
            21.009230830839662,
            105.83735592635286,
            21.009608373615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735592635286,
              21.009608373615
            ],
            [
              105.83729415540516,
              21.009557385236658
            ],
            [
              105.8371939441499,
              21.009474665090025
            ],
            [
              105.83719033484823,
              21.00947168685036
            ],
            [
              105.8371875343765,
              21.009469375727406
            ],
            [
              105.8371810279629,
              21.009464005549752
            ],
            [
              105.83718074868726,
              21.009463774794945
            ],
            [
              105.83717773464973,
              21.00946128678721
            ],
            [
              105.83698817818,
              21.009304831410862
            ],
            [
              105.83696972032038,
              21.009302064392088
            ],
            [
              105.83696774263036,
              21.009303610504613
            ],
            [
              105.83694896840245,
              21.009298963622292
            ],
            [
              105.83692765522187,
              21.009295777094295
            ],
            [
              105.83686022674435,
              21.009230830839662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8832",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009608373615,
          "to_latitude": 21.009230830839662,
          "to_longitude": 105.83686022674435,
          "from_longitude": 105.83735592635286
        }
      },
      {
        "id": 8825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716828338937,
            21.009557385236658,
            105.83729415540516,
            21.009691669147234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716828338937,
              21.009691669147234
            ],
            [
              105.83728097604707,
              21.00957144735745
            ],
            [
              105.83728885242762,
              21.00956875492879
            ],
            [
              105.83728883276682,
              21.00956867915465
            ],
            [
              105.83728881435016,
              21.009568653954968
            ],
            [
              105.83728878115765,
              21.009568566505486
            ],
            [
              105.83728730898873,
              21.009564690785894
            ],
            [
              105.83729415540516,
              21.009557385236658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8833",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009691669147234,
          "to_latitude": 21.009557385236658,
          "to_longitude": 105.83729415540516,
          "from_longitude": 105.83716828338937
        }
      },
      {
        "id": 8826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696248727297,
            21.00929182787287,
            105.83698817818,
            21.009304831410862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698817818,
              21.009304831410862
            ],
            [
              105.83696924355841,
              21.0092929857146
            ],
            [
              105.83696738833659,
              21.00929182787287
            ],
            [
              105.83696669077236,
              21.009292828463984
            ],
            [
              105.83696251410959,
              21.009299016225565
            ],
            [
              105.83696248727297,
              21.009299033518825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8834",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009304831410862,
          "to_latitude": 21.009299033518825,
          "to_longitude": 105.83696248727297,
          "from_longitude": 105.83698817818
        }
      },
      {
        "id": 8827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8369575869101,
            21.0092525879787,
            105.83698615918841,
            21.00929777479658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698615918841,
              21.0092525879787
            ],
            [
              105.8369575869101,
              21.0092834300246
            ],
            [
              105.83696496520177,
              21.00929387565957
            ],
            [
              105.83696112590275,
              21.00929777479658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8835",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0092525879787,
          "to_latitude": 21.00929777479658,
          "to_longitude": 105.83696112590275,
          "from_longitude": 105.83698615918841
        }
      },
      {
        "id": 8828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696172755592,
            21.009257840209557,
            105.8369925908619,
            21.009298376094822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369925908619,
              21.009257840209557
            ],
            [
              105.83696489154097,
              21.009287740415594
            ],
            [
              105.83696527395718,
              21.00929404575371
            ],
            [
              105.83696175056521,
              21.009298362433302
            ],
            [
              105.83696172755592,
              21.009298376094822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.8836",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009257840209557,
          "to_latitude": 21.009298376094822,
          "to_longitude": 105.83696172755592,
          "from_longitude": 105.8369925908619
        }
      },
      {
        "id": 8829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83693400989925,
            21.00972647271806,
            105.8369649148869,
            21.00976472725832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369649148869,
              21.00972647271806
            ],
            [
              105.83694185388846,
              21.009755017851283
            ],
            [
              105.83693923158089,
              21.00975826339017
            ],
            [
              105.83693400989925,
              21.00976472725832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.2_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8837",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00972647271806,
          "to_latitude": 21.00976472725832,
          "to_longitude": 105.83693400989925,
          "from_longitude": 105.8369649148869
        }
      },
      {
        "id": 8830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83693664708099,
            21.00972801589915,
            105.8369670223573,
            21.009766907317626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369670223573,
              21.00972801589915
            ],
            [
              105.83694692364234,
              21.00975201227462
            ],
            [
              105.83694376529841,
              21.009756590900125
            ],
            [
              105.83694161551655,
              21.009759705857086
            ],
            [
              105.83693664708099,
              21.009766907317626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.1_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8838",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00972801589915,
          "to_latitude": 21.009766907317626,
          "to_longitude": 105.83693664708099,
          "from_longitude": 105.8369670223573
        }
      },
      {
        "id": 8831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364942164758,
            21.0093786087054,
            105.83708421719216,
            21.00987215772053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83708421719216,
              21.00987215772053
            ],
            [
              105.83694376529841,
              21.009756590900125
            ],
            [
              105.83694185388846,
              21.009755017851283
            ],
            [
              105.83693720872196,
              21.009751195669836
            ],
            [
              105.83693079805366,
              21.009745918947356
            ],
            [
              105.83692333017063,
              21.00973976407039
            ],
            [
              105.83671712067206,
              21.009570100251352
            ],
            [
              105.83671092358993,
              21.009565001308353
            ],
            [
              105.8367076544906,
              21.009562311320686
            ],
            [
              105.83670462118077,
              21.00955981617339
            ],
            [
              105.83670310887199,
              21.009558571739646
            ],
            [
              105.83669664025099,
              21.00955324922822
            ],
            [
              105.8364942164758,
              21.0093786087054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8839",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00987215772053,
          "to_latitude": 21.0093786087054,
          "to_longitude": 105.8364942164758,
          "from_longitude": 105.83708421719216
        }
      },
      {
        "id": 8832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669982904252,
            21.00953277462676,
            105.83673141985687,
            21.009564652117824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673141985687,
              21.00953277462676
            ],
            [
              105.83670462118077,
              21.00955981617339
            ],
            [
              105.83670206059162,
              21.0095624002447
            ],
            [
              105.83669982904252,
              21.009564652117824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.5_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8840",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00953277462676,
          "to_latitude": 21.009564652117824,
          "to_longitude": 105.83669982904252,
          "from_longitude": 105.83673141985687
        }
      },
      {
        "id": 8833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670285229056,
            21.009535031508648,
            105.83673423156338,
            21.009567240346847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673423156338,
              21.009535031508648
            ],
            [
              105.8367076544906,
              21.009562311320686
            ],
            [
              105.83670552549411,
              21.009564496754223
            ],
            [
              105.83670285229056,
              21.009567240346847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.4_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8841",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009535031508648,
          "to_latitude": 21.009567240346847,
          "to_longitude": 105.83670285229056,
          "from_longitude": 105.83673423156338
        }
      },
      {
        "id": 8834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676166861032,
            21.00973976407039,
            105.83692333017063,
            21.009841100978335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676166861032,
              21.009786833096655
            ],
            [
              105.83682864336966,
              21.009841100978335
            ],
            [
              105.83691166656261,
              21.009752247097794
            ],
            [
              105.83691638028435,
              21.00975197733448
            ],
            [
              105.83691710232522,
              21.009750708365413
            ],
            [
              105.83691822729823,
              21.009748731092856
            ],
            [
              105.83691798985467,
              21.009745480651414
            ],
            [
              105.83692333017063,
              21.00973976407039
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8842",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009786833096655,
          "to_latitude": 21.00973976407039,
          "to_longitude": 105.83692333017063,
          "from_longitude": 105.83676166861032
        }
      },
      {
        "id": 8835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83622580155692,
            21.00947541948551,
            105.83682531818374,
            21.01015621090768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83639784220773,
              21.00947541948551
            ],
            [
              105.83622580155692,
              21.00965860533332
            ],
            [
              105.83626932744929,
              21.009693985723526
            ],
            [
              105.83644920501632,
              21.009843011679262
            ],
            [
              105.8364521688465,
              21.009845466527405
            ],
            [
              105.83645446975545,
              21.00984737276367
            ],
            [
              105.8364652070143,
              21.009856269136247
            ],
            [
              105.83666666211093,
              21.010023543211698
            ],
            [
              105.83667914829823,
              21.010032469809406
            ],
            [
              105.83668150472957,
              21.010034154479342
            ],
            [
              105.83668485588518,
              21.010036550515554
            ],
            [
              105.83682531818374,
              21.01015621090768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8843",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 110.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00947541948551,
          "to_latitude": 21.01015621090768,
          "to_longitude": 105.83682531818374,
          "from_longitude": 105.83639784220773
        }
      },
      {
        "id": 8836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367061616325,
            21.009565001308353,
            105.83671092358993,
            21.009570074652693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83671092358993,
              21.009565001308353
            ],
            [
              105.83670824809518,
              21.009567851492832
            ],
            [
              105.8367061616325,
              21.009570074652693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8844",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009565001308353,
          "to_latitude": 21.009570074652693,
          "to_longitude": 105.8367061616325,
          "from_longitude": 105.83671092358993
        }
      },
      {
        "id": 8837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364396164647,
            21.00981502757994,
            105.83647666224397,
            21.009852785639737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647666224397,
              21.00981502757994
            ],
            [
              105.83644920501632,
              21.009843011679262
            ],
            [
              105.83644835185454,
              21.0098438820656
            ],
            [
              105.8364396164647,
              21.009852785639737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3.5_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8845",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00981502757994,
          "to_latitude": 21.009852785639737,
          "to_longitude": 105.8364396164647,
          "from_longitude": 105.83647666224397
        }
      },
      {
        "id": 8838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644263921593,
            21.00981753652956,
            105.83647927720631,
            21.009855284456417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647927720631,
              21.00981753652956
            ],
            [
              105.8364521688465,
              21.009845466527405
            ],
            [
              105.83645176811282,
              21.00984587946451
            ],
            [
              105.83644263921593,
              21.009855284456417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3.4_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8846",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00981753652956,
          "to_latitude": 21.009855284456417,
          "to_longitude": 105.83644263921593,
          "from_longitude": 105.83647927720631
        }
      },
      {
        "id": 8839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666954573071,
            21.010003686583616,
            105.83670581711016,
            21.010042834560483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670581711016,
              21.010003686583616
            ],
            [
              105.83667914829823,
              21.010032469809406
            ],
            [
              105.83667841684652,
              21.010033259211436
            ],
            [
              105.83666954573071,
              21.010042834560483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3.2_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8847",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010003686583616,
          "to_latitude": 21.010042834560483,
          "to_longitude": 105.83666954573071,
          "from_longitude": 105.83670581711016
        }
      },
      {
        "id": 8840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667181859497,
            21.010005416966404,
            105.83670824497679,
            21.010044712931524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670824497679,
              21.010005416966404
            ],
            [
              105.83668312881983,
              21.010032501721525
            ],
            [
              105.83668150472957,
              21.010034154479342
            ],
            [
              105.83668063912924,
              21.010035036669894
            ],
            [
              105.8366723645009,
              21.010044114119047
            ],
            [
              105.83667181859497,
              21.010044712931524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3.1_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8848",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010005416966404,
          "to_latitude": 21.010044712931524,
          "to_longitude": 105.83667181859497,
          "from_longitude": 105.83670824497679
        }
      },
      {
        "id": 8841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644511983891,
            21.00984737276367,
            105.83645446975545,
            21.009857334325435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83645446975545,
              21.00984737276367
            ],
            [
              105.83645383004402,
              21.00984805423005
            ],
            [
              105.83644998795272,
              21.00985214756312
            ],
            [
              105.83644511983891,
              21.009857334325435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8849",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00984737276367,
          "to_latitude": 21.009857334325435,
          "to_longitude": 105.83644511983891,
          "from_longitude": 105.83645446975545
        }
      },
      {
        "id": 8842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357209090079,
            21.008533503963687,
            105.83573177466369,
            21.00854522338025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573177466369,
              21.008533503963687
            ],
            [
              105.83573023908477,
              21.00853515898752
            ],
            [
              105.83572616877478,
              21.008539550586313
            ],
            [
              105.8357209090079,
              21.00854522338025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8850",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008533503963687,
          "to_latitude": 21.00854522338025,
          "to_longitude": 105.8357209090079,
          "from_longitude": 105.83573177466369
        }
      },
      {
        "id": 8843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83532772913485,
            21.00821033029134,
            105.83589827908102,
            21.008668554715165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83532772913485,
              21.00821033029134
            ],
            [
              105.83544457438022,
              21.00830531251515
            ],
            [
              105.83550365228174,
              21.00834670270464
            ],
            [
              105.83550672896673,
              21.008349222049098
            ],
            [
              105.8355094307288,
              21.008351434331214
            ],
            [
              105.83572276586962,
              21.008526127308052
            ],
            [
              105.83572527727543,
              21.008528183360177
            ],
            [
              105.83573177466369,
              21.008533503963687
            ],
            [
              105.83573338065239,
              21.008534819301456
            ],
            [
              105.83576596862095,
              21.008559871884223
            ],
            [
              105.83589827908102,
              21.008668554715165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8851",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00821033029134,
          "to_latitude": 21.008668554715165,
          "to_longitude": 105.83589827908102,
          "from_longitude": 105.83532772913485
        }
      },
      {
        "id": 8844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571418269675,
            21.008493381234235,
            105.83575708650444,
            21.008539731384552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575708650444,
              21.008493381234235
            ],
            [
              105.83572987551416,
              21.008522728782452
            ],
            [
              105.83572230209134,
              21.00852420120001
            ],
            [
              105.83572276586962,
              21.008526127308052
            ],
            [
              105.8357235831217,
              21.00852951761457
            ],
            [
              105.83572292914523,
              21.00853022805148
            ],
            [
              105.83571418269675,
              21.008539731384552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8.3_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8852",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008493381234235,
          "to_latitude": 21.008539731384552,
          "to_longitude": 105.83571418269675,
          "from_longitude": 105.83575708650444
        }
      },
      {
        "id": 8845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571631528604,
            21.008495035895482,
            105.83575948698078,
            21.008541472265126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575948698078,
              21.008495035895482
            ],
            [
              105.83573227695257,
              21.00852438343935
            ],
            [
              105.83572472562238,
              21.00852585032916
            ],
            [
              105.83572527727543,
              21.008528183360177
            ],
            [
              105.83572598359834,
              21.00853117227628
            ],
            [
              105.83572521862406,
              21.0085319871292
            ],
            [
              105.83571631528604,
              21.008541472265126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8.2_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8853",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008495035895482,
          "to_latitude": 21.008541472265126,
          "to_longitude": 105.83571631528604,
          "from_longitude": 105.83575948698078
        }
      },
      {
        "id": 8846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549751189517,
            21.008315013210986,
            105.83554096451478,
            21.00836282561454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554096451478,
              21.008315013210986
            ],
            [
              105.83551319322954,
              21.008344968637267
            ],
            [
              105.83550646266605,
              21.008345274456055
            ],
            [
              105.83550672896673,
              21.008349222049098
            ],
            [
              105.83550689987229,
              21.00835175746631
            ],
            [
              105.83550519409552,
              21.008353768291276
            ],
            [
              105.83549751189517,
              21.00836282561454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8.5_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8854",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008315013210986,
          "to_latitude": 21.00836282561454,
          "to_longitude": 105.83549751189517,
          "from_longitude": 105.83554096451478
        }
      },
      {
        "id": 8847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549983966606,
            21.008317119917294,
            105.83554364548918,
            21.008364725410573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554364548918,
              21.008317119917294
            ],
            [
              105.83551587516088,
              21.008347074436088
            ],
            [
              105.83550918020141,
              21.008347382789854
            ],
            [
              105.8355094307288,
              21.008351434331214
            ],
            [
              105.83550958084686,
              21.008353864173138
            ],
            [
              105.83550859621275,
              21.00835496190982
            ],
            [
              105.83549983966606,
              21.008364725410573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8.4_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8855",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008317119917294,
          "to_latitude": 21.008364725410573,
          "to_longitude": 105.83549983966606,
          "from_longitude": 105.83554364548918
        }
      },
      {
        "id": 8848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597958762739,
            21.008742419375228,
            105.83655614802984,
            21.009212098234258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83597958762739,
              21.008742419375228
            ],
            [
              105.8360859169638,
              21.008826461443032
            ],
            [
              105.83610915702077,
              21.00884402698642
            ],
            [
              105.83611564751689,
              21.00884931418882
            ],
            [
              105.83611722635357,
              21.00885058901102
            ],
            [
              105.83611879262563,
              21.00885185305624
            ],
            [
              105.83633905068871,
              21.009029601127192
            ],
            [
              105.8363416759084,
              21.00903188241652
            ],
            [
              105.83634332585626,
              21.009033316757797
            ],
            [
              105.8363521013947,
              21.009040942008426
            ],
            [
              105.83655614802984,
              21.009212098234258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.3_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8856",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008742419375228,
          "to_latitude": 21.009212098234258,
          "to_longitude": 105.83655614802984,
          "from_longitude": 105.83597958762739
        }
      },
      {
        "id": 8849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632833039037,
            21.009029601127192,
            105.83633905068871,
            21.009041165415706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633905068871,
              21.009029601127192
            ],
            [
              105.83633818250325,
              21.009030536618976
            ],
            [
              105.83632833039037,
              21.009041165415706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.3_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8857",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009029601127192,
          "to_latitude": 21.009041165415706,
          "to_longitude": 105.83632833039037,
          "from_longitude": 105.83633905068871
        }
      },
      {
        "id": 8850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610770408625,
            21.00882485735194,
            105.8361406916627,
            21.008861031013527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361406916627,
              21.00882485735194
            ],
            [
              105.83611865756073,
              21.00884901939974
            ],
            [
              105.83611722635357,
              21.00885058901102
            ],
            [
              105.83610770408625,
              21.008861031013527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.5_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8858",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00882485735194,
          "to_latitude": 21.008861031013527,
          "to_longitude": 105.83610770408625,
          "from_longitude": 105.8361406916627
        }
      },
      {
        "id": 8851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610903756706,
            21.008826519449876,
            105.83614286422146,
            21.008862120073715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83614286422146,
              21.008826519449876
            ],
            [
              105.83612153334039,
              21.00884896914104
            ],
            [
              105.83611879262563,
              21.00885185305624
            ],
            [
              105.83610903756706,
              21.008862120073715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.4_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8859",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008826519449876,
          "to_latitude": 21.008862120073715,
          "to_longitude": 105.83610903756706,
          "from_longitude": 105.83614286422146
        }
      },
      {
        "id": 8852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363308185923,
            21.00900784341725,
            105.83636384535022,
            21.00904319718525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636384535022,
              21.00900784341725
            ],
            [
              105.83634853459174,
              21.0090243573603
            ],
            [
              105.8363416759084,
              21.00903188241652
            ],
            [
              105.83634102197304,
              21.009032600081344
            ],
            [
              105.83633764985514,
              21.009036102199307
            ],
            [
              105.8363308185923,
              21.00904319718525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.2_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8860",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00900784341725,
          "to_latitude": 21.00904319718525,
          "to_longitude": 105.8363308185923,
          "from_longitude": 105.83636384535022
        }
      },
      {
        "id": 8853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633216850359,
            21.00901036502862,
            105.83636664120274,
            21.009044299711217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636664120274,
              21.00901036502862
            ],
            [
              105.83634332585626,
              21.009033316757797
            ],
            [
              105.83634274134691,
              21.009033892275113
            ],
            [
              105.83633216850359,
              21.009044299711217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5.1_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8861",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00901036502862,
          "to_latitude": 21.009044299711217,
          "to_longitude": 105.83633216850359,
          "from_longitude": 105.83636664120274
        }
      },
      {
        "id": 8854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662784707582,
            21.009184386163078,
            105.8366733288794,
            21.009206551946587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366733288794,
              21.009185515581528
            ],
            [
              105.83665648829991,
              21.00920436829654
            ],
            [
              105.8366545382101,
              21.009206551946587
            ],
            [
              105.83663968409934,
              21.009194209247575
            ],
            [
              105.83662912455826,
              21.0091854366556
            ],
            [
              105.83662784707582,
              21.009184386163078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.5_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8862",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009185515581528,
          "to_latitude": 21.009184386163078,
          "to_longitude": 105.83662784707582,
          "from_longitude": 105.8366733288794
        }
      },
      {
        "id": 8855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366269159218,
            21.00918593164266,
            105.83667667577022,
            21.009210481556703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667667577022,
              21.009188185316788
            ],
            [
              105.83666011368976,
              21.009207302211006
            ],
            [
              105.83665735923634,
              21.009210481556703
            ],
            [
              105.83663861032186,
              21.009195362525592
            ],
            [
              105.83662704057699,
              21.00918603399745
            ],
            [
              105.8366269159218,
              21.00918593164266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.4_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8863",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009188185316788,
          "to_latitude": 21.00918593164266,
          "to_longitude": 105.8366269159218,
          "from_longitude": 105.83667667577022
        }
      },
      {
        "id": 8856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665193512522,
            21.00918523096768,
            105.83675035542653,
            21.009236040798232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675035542653,
              21.009236040798232
            ],
            [
              105.83668804524633,
              21.00918523096768
            ],
            [
              105.8366638087479,
              21.009211862490837
            ],
            [
              105.83666024636786,
              21.009209015879495
            ],
            [
              105.83666062676883,
              21.009207716978285
            ],
            [
              105.83666011368976,
              21.009207302211006
            ],
            [
              105.83665648829991,
              21.00920436829654
            ],
            [
              105.8366558718275,
              21.009203869134435
            ],
            [
              105.83665424724155,
              21.00920422396016
            ],
            [
              105.83665193512522,
              21.009202376490954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8864",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009236040798232,
          "to_latitude": 21.009202376490954,
          "to_longitude": 105.83665193512522,
          "from_longitude": 105.83675035542653
        }
      },
      {
        "id": 8857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661009458761,
            21.008990529899297,
            105.83672302552493,
            21.009202376490954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665193512522,
              21.009202376490954
            ],
            [
              105.83664064504103,
              21.009193175749974
            ],
            [
              105.83663074948154,
              21.009185142344514
            ],
            [
              105.83663103813726,
              21.00918205279944
            ],
            [
              105.83662977667426,
              21.00918128764726
            ],
            [
              105.8366289949319,
              21.00918082000721
            ],
            [
              105.836626360349,
              21.009181863539464
            ],
            [
              105.83661009458761,
              21.00916786855374
            ],
            [
              105.83672302552493,
              21.00904775614681
            ],
            [
              105.83665334521719,
              21.008990529899297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8865",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009202376490954,
          "to_latitude": 21.008990529899297,
          "to_longitude": 105.83665334521719,
          "from_longitude": 105.83665193512522
        }
      },
      {
        "id": 8858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665334521719,
            21.008956050545038,
            105.83674232275754,
            21.00900389279074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665334521719,
              21.008990529899297
            ],
            [
              105.83668413651199,
              21.008956050545038
            ],
            [
              105.83674232275754,
              21.00900389279074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8866",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008990529899297,
          "to_latitude": 21.00900389279074,
          "to_longitude": 105.83674232275754,
          "from_longitude": 105.83665334521719
        }
      },
      {
        "id": 8859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83660145733622,
            21.008973404746172,
            105.83670762022071,
            21.00919003090425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662462445932,
              21.00919003090425
            ],
            [
              105.83662455200697,
              21.00918997526051
            ],
            [
              105.83660145733622,
              21.009171765077372
            ],
            [
              105.83670762022071,
              21.009053001048915
            ],
            [
              105.83664022867217,
              21.008997401089292
            ],
            [
              105.83665939817828,
              21.00897634208926
            ],
            [
              105.8366557352612,
              21.008973404746172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.2_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8867",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00919003090425,
          "to_latitude": 21.008973404746172,
          "to_longitude": 105.8366557352612,
          "from_longitude": 105.83662462445932
        }
      },
      {
        "id": 8860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659638041819,
            21.008976996688848,
            105.83670088263996,
            21.00919203615527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662350348985,
              21.00919203615527
            ],
            [
              105.83662315759986,
              21.009191761468998
            ],
            [
              105.83659638041819,
              21.00917037829609
            ],
            [
              105.83670088263996,
              21.009053433401768
            ],
            [
              105.83663349592312,
              21.008997837028552
            ],
            [
              105.83665246561367,
              21.008976996688848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.1_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8868",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00919203615527,
          "to_latitude": 21.008976996688848,
          "to_longitude": 105.83665246561367,
          "from_longitude": 105.83662350348985
        }
      },
      {
        "id": 8861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662587971766,
            21.009187785638403,
            105.83664064504103,
            21.00919678438039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662587971766,
              21.009187785638403
            ],
            [
              105.83662621498178,
              21.00918805224791
            ],
            [
              105.83663728794919,
              21.00919678438039
            ],
            [
              105.83663861032186,
              21.009195362525592
            ],
            [
              105.83663968409934,
              21.009194209247575
            ],
            [
              105.83664064504103,
              21.009193175749974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8A.3_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8869",
          "diaChiLapD": "Ngõ 21, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009187785638403,
          "to_latitude": 21.009193175749974,
          "to_longitude": 105.83664064504103,
          "from_longitude": 105.83662587971766
        }
      },
      {
        "id": 8862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83408309085526,
            21.00706715204164,
            105.8345719429908,
            21.007646248824216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83408309085526,
              21.00706715204164
            ],
            [
              105.8345719429908,
              21.00746455465537
            ],
            [
              105.83439199410404,
              21.00764540198782
            ],
            [
              105.83439115235365,
              21.007646248824216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8870",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 94.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00706715204164,
          "to_latitude": 21.007646248824216,
          "to_longitude": 105.83439115235365,
          "from_longitude": 105.83408309085526
        }
      },
      {
        "id": 8863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338532107345,
            21.00705015232113,
            105.83438221644532,
            21.007630681287758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396700112749,
              21.00705015232113
            ],
            [
              105.8338532107345,
              21.007186422250058
            ],
            [
              105.8339541258973,
              21.00726780787216
            ],
            [
              105.83395956723517,
              21.007272647787104
            ],
            [
              105.83396139325829,
              21.007274271870738
            ],
            [
              105.83400430916141,
              21.00731244142186
            ],
            [
              105.83411785246419,
              21.00740669743748
            ],
            [
              105.8341253299766,
              21.00741289575347
            ],
            [
              105.83412546812973,
              21.007413004366626
            ],
            [
              105.83412687959944,
              21.007414112028783
            ],
            [
              105.8342589814558,
              21.007517847558454
            ],
            [
              105.8342646857792,
              21.00752258139961
            ],
            [
              105.83426958996829,
              21.00752665077613
            ],
            [
              105.83428204613739,
              21.007536988547674
            ],
            [
              105.83428629031985,
              21.007540511094923
            ],
            [
              105.83429010254306,
              21.00754367446947
            ],
            [
              105.83438221644532,
              21.007630681287758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8871",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00705015232113,
          "to_latitude": 21.007630681287758,
          "to_longitude": 105.83438221644532,
          "from_longitude": 105.83396700112749
        }
      },
      {
        "id": 8864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83370561984745,
            21.007484449267174,
            105.83426458257756,
            21.007874566687384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83370561984745,
              21.007484449267174
            ],
            [
              105.83372800535896,
              21.007503069790094
            ],
            [
              105.83373706006108,
              21.00751060078343
            ],
            [
              105.83373751226331,
              21.007510967987308
            ],
            [
              105.83374415081634,
              21.007516267204846
            ],
            [
              105.83380858230765,
              21.007567961971517
            ],
            [
              105.83390014753246,
              21.007641441427992
            ],
            [
              105.83390030014367,
              21.007641555389828
            ],
            [
              105.83390515371964,
              21.00764517250899
            ],
            [
              105.83391608531589,
              21.007653957907714
            ],
            [
              105.83401028142089,
              21.007729517182867
            ],
            [
              105.83406087171917,
              21.007771724456653
            ],
            [
              105.83406669699197,
              21.007776585969452
            ],
            [
              105.83406945693167,
              21.007778887409675
            ],
            [
              105.83407869156747,
              21.00778659634039
            ],
            [
              105.83408946267099,
              21.007795576707334
            ],
            [
              105.83418413876745,
              21.007874566687384
            ],
            [
              105.83426458257756,
              21.00779035393244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8872",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007484449267174,
          "to_latitude": 21.00779035393244,
          "to_longitude": 105.83426458257756,
          "from_longitude": 105.83370561984745
        }
      },
      {
        "id": 8865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366452423391,
            21.007567961971517,
            105.83380858230765,
            21.00768995285465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366452423391,
              21.00768995285465
            ],
            [
              105.83372435639674,
              21.00763628001774
            ],
            [
              105.8337420109161,
              21.007644256621244
            ],
            [
              105.83379958127537,
              21.00757840039162
            ],
            [
              105.83380255478849,
              21.007574996920695
            ],
            [
              105.83380858230765,
              21.007567961971517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8873",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00768995285465,
          "to_latitude": 21.007567961971517,
          "to_longitude": 105.83380858230765,
          "from_longitude": 105.83366452423391
        }
      },
      {
        "id": 8866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83386864294062,
            21.007249213845817,
            105.83437495491226,
            21.007734463494135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343081973334,
              21.007734463494135
            ],
            [
              105.83437495491226,
              21.007657793782545
            ],
            [
              105.83412150326302,
              21.007457653075416
            ],
            [
              105.83410550500484,
              21.00744446581441
            ],
            [
              105.83386864294062,
              21.007249213845817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8874",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007734463494135,
          "to_latitude": 21.007249213845817,
          "to_longitude": 105.83386864294062,
          "from_longitude": 105.8343081973334
        }
      },
      {
        "id": 8867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83428072260901,
            21.007510563589786,
            105.8343202235906,
            21.007552563933437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343202235906,
              21.007510563589786
            ],
            [
              105.83429311033272,
              21.007539690951536
            ],
            [
              105.83428626566351,
              21.007540228506226
            ],
            [
              105.83428629031985,
              21.007540511094923
            ],
            [
              105.83428681695597,
              21.007546480639984
            ],
            [
              105.83428251523466,
              21.007550774888056
            ],
            [
              105.83428072260901,
              21.007552563933437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8875",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007510563589786,
          "to_latitude": 21.007552563933437,
          "to_longitude": 105.83428072260901,
          "from_longitude": 105.8343202235906
        }
      },
      {
        "id": 8868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83426322205875,
            21.007497195912837,
            105.83430160317128,
            21.007538200827362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430160317128,
              21.007497195912837
            ],
            [
              105.83427448895516,
              21.00752632417962
            ],
            [
              105.83426958996829,
              21.00752665077613
            ],
            [
              105.83426761018451,
              21.00752678332011
            ],
            [
              105.834268195563,
              21.007533111157812
            ],
            [
              105.83426381026753,
              21.007537598202145
            ],
            [
              105.83426322205875,
              21.007538200827362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8876",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007497195912837,
          "to_latitude": 21.007538200827362,
          "to_longitude": 105.83426322205875,
          "from_longitude": 105.83430160317128
        }
      },
      {
        "id": 8869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83412072066868,
            21.00738064159106,
            105.83415996991931,
            21.007424150857688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415996991931,
              21.00738064159106
            ],
            [
              105.834130784486,
              21.007411970309555
            ],
            [
              105.83412676336228,
              21.007412235695895
            ],
            [
              105.83412687959944,
              21.007414112028783
            ],
            [
              105.83412699801629,
              21.007416034415506
            ],
            [
              105.83412079133909,
              21.007424059285526
            ],
            [
              105.83412072066868,
              21.007424150857688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.6_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8877",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00738064159106,
          "to_latitude": 21.007424150857688,
          "to_longitude": 105.83412072066868,
          "from_longitude": 105.83415996991931
        }
      },
      {
        "id": 8870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8341188326304,
            21.00737967517668,
            105.83415811965402,
            21.007422791724913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415811965402,
              21.00737967517668
            ],
            [
              105.83412939332878,
              21.007410709902747
            ],
            [
              105.8341253055708,
              21.007410927744704
            ],
            [
              105.83412546812973,
              21.007413004366626
            ],
            [
              105.83412560685903,
              21.007414774008662
            ],
            [
              105.83411905146001,
              21.007422532329873
            ],
            [
              105.8341188326304,
              21.007422791724913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.7_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8878",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00737967517668,
          "to_latitude": 21.007422791724913,
          "to_longitude": 105.8341188326304,
          "from_longitude": 105.83415811965402
        }
      },
      {
        "id": 8871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83395415878555,
            21.00724684700829,
            105.8339953530624,
            21.007288956737366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339953530624,
              21.00724684700829
            ],
            [
              105.8339681130155,
              21.00727324087716
            ],
            [
              105.83396134541387,
              21.007273624492484
            ],
            [
              105.83396139325829,
              21.007274271870738
            ],
            [
              105.83396181866239,
              21.00728002875224
            ],
            [
              105.83395537728272,
              21.00728753450867
            ],
            [
              105.83395483158604,
              21.0072881712471
            ],
            [
              105.83395415878555,
              21.007288956737366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.8_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8879",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00724684700829,
          "to_latitude": 21.007288956737366,
          "to_longitude": 105.83395415878555,
          "from_longitude": 105.8339953530624
        }
      },
      {
        "id": 8872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83395231614658,
            21.007244749999785,
            105.8339921623846,
            21.00728745828342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339921623846,
              21.007244749999785
            ],
            [
              105.83396635549376,
              21.007272000328772
            ],
            [
              105.83395954760458,
              21.007272404012397
            ],
            [
              105.83395956723517,
              21.007272647787104
            ],
            [
              105.83396006114567,
              21.007278789106987
            ],
            [
              105.83395405786321,
              21.00728550871676
            ],
            [
              105.8339529671406,
              21.007286729803234
            ],
            [
              105.83395231614658,
              21.00728745828342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.9_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8880",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007244749999785,
          "to_latitude": 21.00728745828342,
          "to_longitude": 105.83395231614658,
          "from_longitude": 105.8339921623846
        }
      },
      {
        "id": 8873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425585503494,
            21.00752258139961,
            105.8342646857792,
            21.007532154621344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342646857792,
              21.00752258139961
            ],
            [
              105.834259531327,
              21.007528169633332
            ],
            [
              105.83425633213511,
              21.007531636540783
            ],
            [
              105.83425585503494,
              21.007532154621344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.4_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8881",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00752258139961,
          "to_latitude": 21.007532154621344,
          "to_longitude": 105.83425585503494,
          "from_longitude": 105.8342646857792
        }
      },
      {
        "id": 8874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410550500484,
            21.007425677748124,
            105.83412284241544,
            21.00744446581441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83410550500484,
              21.00744446581441
            ],
            [
              105.83411212521264,
              21.00743729156295
            ],
            [
              105.83412274488832,
              21.007425783000286
            ],
            [
              105.83412284241544,
              21.007425677748124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18.5_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8882",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00744446581441,
          "to_latitude": 21.007425677748124,
          "to_longitude": 105.83412284241544,
          "from_longitude": 105.83410550500484
        }
      },
      {
        "id": 8875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8339063997198,
            21.007653957907714,
            105.83391608531589,
            21.0076641034298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83391608531589,
              21.007653957907714
            ],
            [
              105.83391415641859,
              21.00765597793963
            ],
            [
              105.83391170154526,
              21.007658548806777
            ],
            [
              105.83390974203895,
              21.007660599698294
            ],
            [
              105.8339063997198,
              21.0076641034298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8883",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007653957907714,
          "to_latitude": 21.0076641034298,
          "to_longitude": 105.8339063997198,
          "from_longitude": 105.83391608531589
        }
      },
      {
        "id": 8876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352906811658,
            21.0080159121474,
            105.83551603409562,
            21.00819394066642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352906811658,
              21.00819394066642
            ],
            [
              105.83530002066249,
              21.008184011734237
            ],
            [
              105.83545814553601,
              21.0080159121474
            ],
            [
              105.83551603409562,
              21.008063979397313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8B.2_Ngõ 65, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8884",
          "diaChiLapD": "Ngõ 65, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00819394066642,
          "to_latitude": 21.008063979397313,
          "to_longitude": 105.83551603409562,
          "from_longitude": 105.8352906811658
        }
      },
      {
        "id": 8877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83529256249966,
            21.008022777449757,
            105.83550968890195,
            21.00819547414086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83529256249966,
              21.00819547414086
            ],
            [
              105.83530077663151,
              21.008186762860586
            ],
            [
              105.83545540871398,
              21.008022777449757
            ],
            [
              105.83550968890195,
              21.00806784835374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8B.1_Ngõ 65, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8885",
          "diaChiLapD": "Ngõ 65, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00819547414086,
          "to_latitude": 21.00806784835374,
          "to_longitude": 105.83550968890195,
          "from_longitude": 105.83529256249966
        }
      },
      {
        "id": 8878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528866454282,
            21.00809325935842,
            105.83535776445005,
            21.00819229495285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528866454282,
              21.00819229495285
            ],
            [
              105.83529795257311,
              21.008182278660534
            ],
            [
              105.83535776445005,
              21.008117779305294
            ],
            [
              105.83532797389624,
              21.00809325935842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8B.3_Ngõ 65, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8886",
          "diaChiLapD": "Ngõ 65, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00819229495285,
          "to_latitude": 21.00809325935842,
          "to_longitude": 105.83532797389624,
          "from_longitude": 105.83528866454282
        }
      },
      {
        "id": 8879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528685567556,
            21.008093053496577,
            105.83535198977373,
            21.008190819832294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528685567556,
              21.008190819832294
            ],
            [
              105.83529624127789,
              21.008180706416073
            ],
            [
              105.83535198977373,
              21.00812063384323
            ],
            [
              105.8353224736394,
              21.008096509967448
            ],
            [
              105.8353257507886,
              21.008093053496577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8B.4_Ngõ 65, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8887",
          "diaChiLapD": "Ngõ 65, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008190819832294,
          "to_latitude": 21.008093053496577,
          "to_longitude": 105.8353257507886,
          "from_longitude": 105.83528685567556
        }
      },
      {
        "id": 8880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356210711656,
            21.008019416069324,
            105.83571819309152,
            21.008124929047167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356210711656,
              21.008124929047167
            ],
            [
              105.83571819309152,
              21.008019416069324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8888",
          "diaChiLapD": "Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008124929047167,
          "to_latitude": 21.008019416069324,
          "to_longitude": 105.83571819309152,
          "from_longitude": 105.8356210711656
        }
      },
      {
        "id": 8881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549787663868,
            21.007837862109326,
            105.83571819309152,
            21.008019416069324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83571819309152,
              21.008019416069324
            ],
            [
              105.83561596474784,
              21.00793531657279
            ],
            [
              105.83551984070712,
              21.00785623697324
            ],
            [
              105.83549787663868,
              21.007837862109326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8889",
          "diaChiLapD": "Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008019416069324,
          "to_latitude": 21.007837862109326,
          "to_longitude": 105.83549787663868,
          "from_longitude": 105.83571819309152
        }
      },
      {
        "id": 8882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538981603675,
            21.007837862109326,
            105.83549787663868,
            21.007958916264837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83549787663868,
              21.007837862109326
            ],
            [
              105.83541079667486,
              21.007935410706207
            ],
            [
              105.83538981603675,
              21.007958916264837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8890",
          "diaChiLapD": "Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007837862109326,
          "to_latitude": 21.007958916264837,
          "to_longitude": 105.83538981603675,
          "from_longitude": 105.83549787663868
        }
      },
      {
        "id": 8883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534483409487,
            21.007882155182,
            105.83541079667486,
            21.007935410706207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83541079667486,
              21.007935410706207
            ],
            [
              105.83534483409487,
              21.007882155182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8891",
          "diaChiLapD": "Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007935410706207,
          "to_latitude": 21.007882155182,
          "to_longitude": 105.83534483409487,
          "from_longitude": 105.83541079667486
        }
      },
      {
        "id": 8884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549787663868,
            21.00781565086429,
            105.8355269510285,
            21.007837862109326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83549787663868,
              21.007837862109326
            ],
            [
              105.8355048836182,
              21.00783399622702
            ],
            [
              105.8355269510285,
              21.00781565086429
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Hoàng Tích Trí",
          "maTaiSan": "04.O17.DODV.8892",
          "diaChiLapD": "Hoàng Tích Trí",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007837862109326,
          "to_latitude": 21.00781565086429,
          "to_longitude": 105.8355269510285,
          "from_longitude": 105.83549787663868
        }
      },
      {
        "id": 8885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835384553419,
            21.007958916264837,
            105.83545068823489,
            21.00807949105393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538981603675,
              21.007958916264837
            ],
            [
              105.83545068823489,
              21.008009039987602
            ],
            [
              105.835384553419,
              21.00807949105393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Ngõ 65, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8893",
          "diaChiLapD": "Ngõ 65, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007958916264837,
          "to_latitude": 21.00807949105393,
          "to_longitude": 105.835384553419,
          "from_longitude": 105.83538981603675
        }
      },
      {
        "id": 8886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570347200242,
            21.008872294068112,
            105.83631462952945,
            21.009496828442803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358198039668,
              21.008872294068112
            ],
            [
              105.83570347200242,
              21.008996530464668
            ],
            [
              105.83578869982858,
              21.009066846159953
            ],
            [
              105.83583807654432,
              21.00910721534976
            ],
            [
              105.83584505419945,
              21.009112926491706
            ],
            [
              105.83585899587406,
              21.009124318132013
            ],
            [
              105.83586049942929,
              21.00912554726181
            ],
            [
              105.83586166478094,
              21.00912649985951
            ],
            [
              105.83607477766479,
              21.009300734589615
            ],
            [
              105.83608985964455,
              21.00931306538055
            ],
            [
              105.8360912686736,
              21.009314247101702
            ],
            [
              105.83609165233756,
              21.009314568571206
            ],
            [
              105.83609316380797,
              21.00931583649861
            ],
            [
              105.83622474291357,
              21.009426197212804
            ],
            [
              105.83631462952945,
              21.009496828442803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.3_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8894",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 103.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008872294068112,
          "to_latitude": 21.009496828442803,
          "to_longitude": 105.83631462952945,
          "from_longitude": 105.8358198039668
        }
      },
      {
        "id": 8887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503479276347,
            21.008453902757836,
            105.83578113788883,
            21.008941343020048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503479276347,
              21.008453902757836
            ],
            [
              105.83515974917965,
              21.00854949117956
            ],
            [
              105.83523467362265,
              21.008609601613593
            ],
            [
              105.83524202548637,
              21.008615499719323
            ],
            [
              105.83525245278892,
              21.008623865695913
            ],
            [
              105.83525605866942,
              21.00862675818906
            ],
            [
              105.83525761039351,
              21.008628002442954
            ],
            [
              105.83525842683993,
              21.008628657790037
            ],
            [
              105.83530465404051,
              21.008665744928333
            ],
            [
              105.83547156304078,
              21.008803359275664
            ],
            [
              105.83548659235574,
              21.008815750898282
            ],
            [
              105.83548692484204,
              21.008816037394713
            ],
            [
              105.83548945522932,
              21.00881821980853
            ],
            [
              105.83555477450015,
              21.00887453493019
            ],
            [
              105.83563825701604,
              21.008941343020048
            ],
            [
              105.83578113788883,
              21.00880237293508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.5_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8895",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 104.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008453902757836,
          "to_latitude": 21.00880237293508,
          "to_longitude": 105.83578113788883,
          "from_longitude": 105.83503479276347
        }
      },
      {
        "id": 8888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524928301897,
            21.008623865695913,
            105.83525245278892,
            21.00862726112243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83525245278892,
              21.008623865695913
            ],
            [
              105.8352508549942,
              21.008625577020926
            ],
            [
              105.83524928301897,
              21.00862726112243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.5_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8896",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008623865695913,
          "to_latitude": 21.00862726112243,
          "to_longitude": 105.83524928301897,
          "from_longitude": 105.83525245278892
        }
      },
      {
        "id": 8889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608715992975,
            21.009314247101702,
            105.8360912686736,
            21.009318647930126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360912686736,
              21.009314247101702
            ],
            [
              105.8360886381926,
              21.00931706363626
            ],
            [
              105.83608715992975,
              21.009318647930126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6.3_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8897",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009314247101702,
          "to_latitude": 21.009318647930126,
          "to_longitude": 105.83608715992975,
          "from_longitude": 105.8360912686736
        }
      },
      {
        "id": 8890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83429538047203,
            21.008067890576783,
            105.83503336594843,
            21.008550500448273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83429538047203,
              21.008067890576783
            ],
            [
              105.83440506515439,
              21.008158807999095
            ],
            [
              105.83441514314809,
              21.008167162194027
            ],
            [
              105.83441528036535,
              21.008167275327626
            ],
            [
              105.83442437365159,
              21.008174812415064
            ],
            [
              105.83443098343989,
              21.008180291486752
            ],
            [
              105.8345235900767,
              21.008257051410663
            ],
            [
              105.83463570903997,
              21.008348563704423
            ],
            [
              105.83464170092701,
              21.008353455088546
            ],
            [
              105.83465366633638,
              21.00836322168809
            ],
            [
              105.83465503265153,
              21.00836433679266
            ],
            [
              105.8346765796697,
              21.008381924391628
            ],
            [
              105.83483590443542,
              21.00851196691856
            ],
            [
              105.83487859112938,
              21.008550500448273
            ],
            [
              105.83503336594843,
              21.008385741542927
            ],
            [
              105.83490196919767,
              21.00827464101332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8898",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 123.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008067890576783,
          "to_latitude": 21.00827464101332,
          "to_longitude": 105.83490196919767,
          "from_longitude": 105.83429538047203
        }
      },
      {
        "id": 8891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441630479457,
            21.008174812415064,
            105.83442437365159,
            21.008183538296997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83442437365159,
              21.008174812415064
            ],
            [
              105.8344206810424,
              21.008178805615707
            ],
            [
              105.83441709265324,
              21.00818268630533
            ],
            [
              105.83441630479457,
              21.008183538296997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8899",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008174812415064,
          "to_latitude": 21.008183538296997,
          "to_longitude": 105.83441630479457,
          "from_longitude": 105.83442437365159
        }
      },
      {
        "id": 8892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83475390037626,
            21.007487146102555,
            105.83531038438593,
            21.007901383234664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475390037626,
              21.007487146102555
            ],
            [
              105.83490217276133,
              21.00760467534081
            ],
            [
              105.83491451901148,
              21.00761443824924
            ],
            [
              105.83491806666174,
              21.007617243423574
            ],
            [
              105.83493100708553,
              21.00762747580255
            ],
            [
              105.83514574921207,
              21.007801048083145
            ],
            [
              105.83514882171059,
              21.007803509648838
            ],
            [
              105.83515453095356,
              21.00780808356387
            ],
            [
              105.83515994356873,
              21.00781241867647
            ],
            [
              105.83516427212106,
              21.007815886593324
            ],
            [
              105.83526233605309,
              21.007894442943837
            ],
            [
              105.83531038438593,
              21.007901383234664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8900",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007487146102555,
          "to_latitude": 21.007901383234664,
          "to_longitude": 105.83531038438593,
          "from_longitude": 105.83475390037626
        }
      },
      {
        "id": 8893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351371091067,
            21.007803509648838,
            105.83514882171059,
            21.00781635047492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514882171059,
              21.007803509648838
            ],
            [
              105.83514826528608,
              21.007804119346854
            ],
            [
              105.8351371091067,
              21.00781635047492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8901",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007803509648838,
          "to_latitude": 21.00781635047492,
          "to_longitude": 105.8351371091067,
          "from_longitude": 105.83514882171059
        }
      },
      {
        "id": 8894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8345241692066,
            21.00777451847581,
            105.83517414577703,
            21.00827271739365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345241692066,
              21.00777451847581
            ],
            [
              105.83463356208233,
              21.007862274994935
            ],
            [
              105.83465915582129,
              21.00788265449295
            ],
            [
              105.8346607119853,
              21.007883834601966
            ],
            [
              105.83466345628707,
              21.00788591662516
            ],
            [
              105.83467676532403,
              21.00789601263451
            ],
            [
              105.8346814425139,
              21.007899560144192
            ],
            [
              105.83489050983619,
              21.00807697398929
            ],
            [
              105.83489459546854,
              21.00808034736274
            ],
            [
              105.83490399492901,
              21.008088106921864
            ],
            [
              105.83490560386177,
              21.008089434898583
            ],
            [
              105.83494113959159,
              21.008118774344265
            ],
            [
              105.83512759850198,
              21.00827271739365
            ],
            [
              105.83517414577703,
              21.008222860346827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8902",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00777451847581,
          "to_latitude": 21.008222860346827,
          "to_longitude": 105.83517414577703,
          "from_longitude": 105.8345241692066
        }
      },
      {
        "id": 8895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278560808102,
            21.00663543821599,
            105.83327591002893,
            21.007129625237702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327591002893,
              21.007129625237702
            ],
            [
              105.83314880071029,
              21.007019538276975
            ],
            [
              105.83314675668859,
              21.007017974864347
            ],
            [
              105.8331349967991,
              21.00700897940332
            ],
            [
              105.8331300548256,
              21.007005199209686
            ],
            [
              105.83311322352516,
              21.00699232483211
            ],
            [
              105.83292166236696,
              21.00683123641972
            ],
            [
              105.8329173142561,
              21.00682786970014
            ],
            [
              105.83289935282605,
              21.006813963024637
            ],
            [
              105.8328922177154,
              21.006808438589225
            ],
            [
              105.83278560808102,
              21.00672513643092
            ],
            [
              105.83280512508487,
              21.00663543821599
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21,3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8903",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007129625237702,
          "to_latitude": 21.00663543821599,
          "to_longitude": 105.83280512508487,
          "from_longitude": 105.83327591002893
        }
      },
      {
        "id": 8896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315411124597,
            21.006678408176473,
            105.83345822832908,
            21.006933449646294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345822832908,
              21.006933449646294
            ],
            [
              105.83336904755846,
              21.006859324186912
            ],
            [
              105.83330655879183,
              21.006807150950063
            ],
            [
              105.83330381782798,
              21.00680479701636
            ],
            [
              105.83328798673489,
              21.00679120150014
            ],
            [
              105.83328618809813,
              21.006789656758578
            ],
            [
              105.83328497612041,
              21.00678861585418
            ],
            [
              105.83315411124597,
              21.006678408176473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8904",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006933449646294,
          "to_latitude": 21.006678408176473,
          "to_longitude": 105.83315411124597,
          "from_longitude": 105.83345822832908
        }
      },
      {
        "id": 8897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315411124597,
            21.006618057549034,
            105.83321347617034,
            21.006678408176473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315411124597,
              21.006678408176473
            ],
            [
              105.83321347617034,
              21.006618057549034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8905",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006678408176473,
          "to_latitude": 21.006618057549034,
          "to_longitude": 105.83321347617034,
          "from_longitude": 105.83315411124597
        }
      },
      {
        "id": 8898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83302652172227,
            21.006462840231933,
            105.83321347617034,
            21.006618057549034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83321347617034,
              21.006618057549034
            ],
            [
              105.83313051163313,
              21.006549177261167
            ],
            [
              105.83312749087713,
              21.006546669339183
            ],
            [
              105.83312116139452,
              21.00654141374346
            ],
            [
              105.83311495174368,
              21.006536258722164
            ],
            [
              105.83302652172227,
              21.006462840231933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8906",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006618057549034,
          "to_latitude": 21.006462840231933,
          "to_longitude": 105.83302652172227,
          "from_longitude": 105.83321347617034
        }
      },
      {
        "id": 8899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288439158864,
            21.00677418202346,
            105.8329353872162,
            21.00682918695405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329353872162,
              21.00677418202346
            ],
            [
              105.83289935282605,
              21.006813963024637
            ],
            [
              105.83289843408362,
              21.006814976424188
            ],
            [
              105.83288439158864,
              21.00682918695405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21,5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8907",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00677418202346,
          "to_latitude": 21.00682918695405,
          "to_longitude": 105.83288439158864,
          "from_longitude": 105.8329353872162
        }
      },
      {
        "id": 8900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312339776884,
            21.00700897940332,
            105.8331349967991,
            21.007021334506888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83312339776884,
              21.007021334506888
            ],
            [
              105.83313295333872,
              21.007011155342266
            ],
            [
              105.8331349967991,
              21.00700897940332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21,3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8908",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007021334506888,
          "to_latitude": 21.00700897940332,
          "to_longitude": 105.8331349967991,
          "from_longitude": 105.83312339776884
        }
      },
      {
        "id": 8901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83307803490102,
            21.006510733911597,
            105.83314163394225,
            21.006633298657746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83307803490102,
              21.006633298657746
            ],
            [
              105.83313366035259,
              21.006574041200924
            ],
            [
              105.83310391729296,
              21.0065473871894
            ],
            [
              105.83311495174368,
              21.006536258722164
            ],
            [
              105.83312120071142,
              21.006529957035273
            ],
            [
              105.83314163394225,
              21.006510733911597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8909",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006633298657746,
          "to_latitude": 21.006510733911597,
          "to_longitude": 105.83314163394225,
          "from_longitude": 105.83307803490102
        }
      },
      {
        "id": 8902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83308771766464,
            21.006521608513825,
            105.8331560734375,
            21.00664118338859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331560734375,
              21.006521608513825
            ],
            [
              105.83313594547995,
              21.006543668080138
            ],
            [
              105.83313051163313,
              21.006549177261167
            ],
            [
              105.83312720789247,
              21.00655252543394
            ],
            [
              105.83315008831956,
              21.00657474116635
            ],
            [
              105.83308771766464,
              21.00664118338859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.8910",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006521608513825,
          "to_latitude": 21.00664118338859,
          "to_longitude": 105.83308771766464,
          "from_longitude": 105.8331560734375
        }
      },
      {
        "id": 8903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84088086952504,
            21.004268053494503,
            105.84104525728088,
            21.00428356389819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84088086952504,
              21.00428356389819
            ],
            [
              105.84097736074277,
              21.004276189233988
            ],
            [
              105.84104525728088,
              21.004268053494503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,2_Phương Mai",
          "maTaiSan": "04.O17.DODV.8911",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00428356389819,
          "to_latitude": 21.004268053494503,
          "to_longitude": 105.84104525728088,
          "from_longitude": 105.84088086952504
        }
      },
      {
        "id": 8904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84077926462514,
            21.004692119262575,
            105.84081184774658,
            21.005140379516956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84081184774658,
              21.005140379516956
            ],
            [
              105.84080993885716,
              21.004945763012778
            ],
            [
              105.84079707150966,
              21.004894690517247
            ],
            [
              105.84079652356498,
              21.004892515549585
            ],
            [
              105.84078253769763,
              21.00483803077592
            ],
            [
              105.84077926462514,
              21.004692119262575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.1_Ngách 2/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8912",
          "diaChiLapD": "Ngách 2/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005140379516956,
          "to_latitude": 21.004692119262575,
          "to_longitude": 105.84077926462514,
          "from_longitude": 105.84081184774658
        }
      },
      {
        "id": 8905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84019350719687,
            21.0051424300696,
            105.84070026616712,
            21.005142669900604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019350719687,
              21.005142669900604
            ],
            [
              105.84041737087895,
              21.005142564502965
            ],
            [
              105.84046353562465,
              21.005142542475422
            ],
            [
              105.84070026616712,
              21.0051424300696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.3_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8913",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005142669900604,
          "to_latitude": 21.0051424300696,
          "to_longitude": 105.84070026616712,
          "from_longitude": 105.84019350719687
        }
      },
      {
        "id": 8906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83984670842258,
            21.004495351305724,
            105.84012570064114,
            21.004900287361625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83984670842258,
              21.004495351305724
            ],
            [
              105.840091245206,
              21.00449691828746
            ],
            [
              105.84009632555627,
              21.00461905436273
            ],
            [
              105.84009741358618,
              21.004645201865532
            ],
            [
              105.84010290371566,
              21.004777134097722
            ],
            [
              105.84012570064114,
              21.004900287361625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.1_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8914",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004495351305724,
          "to_latitude": 21.004900287361625,
          "to_longitude": 105.84012570064114,
          "from_longitude": 105.83984670842258
        }
      },
      {
        "id": 8907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401490582838,
            21.00493201525153,
            105.84017332666949,
            21.005297379912694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401490582838,
              21.00493201525153
            ],
            [
              105.84015812065546,
              21.005091918066512
            ],
            [
              105.84015903210509,
              21.005107997289695
            ],
            [
              105.84015963836323,
              21.005118700219455
            ],
            [
              105.84017031527588,
              21.00513625678277
            ],
            [
              105.84017332666949,
              21.005297379912694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.2_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8915",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00493201525153,
          "to_latitude": 21.005297379912694,
          "to_longitude": 105.84017332666949,
          "from_longitude": 105.8401490582838
        }
      },
      {
        "id": 8908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401871646588,
            21.00535091559222,
            105.84074038978623,
            21.005353387125133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401871646588,
              21.005353314241066
            ],
            [
              105.84018966745043,
              21.005353315434196
            ],
            [
              105.84063737874044,
              21.005353387125133
            ],
            [
              105.84074038978623,
              21.00535091559222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.5_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8916",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005353314241066,
          "to_latitude": 21.00535091559222,
          "to_longitude": 105.84074038978623,
          "from_longitude": 105.8401871646588
        }
      },
      {
        "id": 8909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84079798993174,
            21.004721137303978,
            105.84110414201805,
            21.00515665563339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84110353354345,
              21.004721137303978
            ],
            [
              105.84110414201805,
              21.00515665563339
            ],
            [
              105.84082603377574,
              21.005151781289936
            ],
            [
              105.84081489316874,
              21.00514744484949
            ],
            [
              105.84079798993174,
              21.005140862556985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.2_Giải Phóng",
          "maTaiSan": "04.O17.DODV.8917",
          "diaChiLapD": "Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004721137303978,
          "to_latitude": 21.005140862556985,
          "to_longitude": 105.84079798993174,
          "from_longitude": 105.84110353354345
        }
      },
      {
        "id": 8910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84100100370169,
            21.004247149444218,
            105.8410550652876,
            21.004689248002553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84100100370169,
              21.004689248002553
            ],
            [
              105.84102904232125,
              21.004435258176517
            ],
            [
              105.8410550652876,
              21.00437908630423
            ],
            [
              105.84104938223085,
              21.00426796807919
            ],
            [
              105.84104225350058,
              21.004250394940765
            ],
            [
              105.84104138214141,
              21.004247149444218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,1_Phương Mai",
          "maTaiSan": "04.O17.DODV.8918",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004689248002553,
          "to_latitude": 21.004247149444218,
          "to_longitude": 105.84104138214141,
          "from_longitude": 105.84100100370169
        }
      },
      {
        "id": 8911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83922721301492,
            21.004620067810535,
            105.83995026907897,
            21.004634857335045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83922721301492,
              21.004620067810535
            ],
            [
              105.83923515475067,
              21.004620342973514
            ],
            [
              105.83926794135864,
              21.00462145936793
            ],
            [
              105.83933142933128,
              21.004627662158946
            ],
            [
              105.83940503783361,
              21.004634857335045
            ],
            [
              105.83953741195958,
              21.004633881080245
            ],
            [
              105.83995026907897,
              21.004634837397685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.9_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8919",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004620067810535,
          "to_latitude": 21.004634837397685,
          "to_longitude": 105.83995026907897,
          "from_longitude": 105.83922721301492
        }
      },
      {
        "id": 8912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387729458965,
            21.006302606030637,
            105.83944302374911,
            21.006501050127117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8387729458965,
              21.006501050127117
            ],
            [
              105.83877298528695,
              21.006501041803944
            ],
            [
              105.83877820722076,
              21.006500680068
            ],
            [
              105.83882868203258,
              21.00649712007237
            ],
            [
              105.83944302374911,
              21.00643562344654
            ],
            [
              105.83942845848445,
              21.006302606030637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,7_Ngách 4/26, Phương Mai",
          "maTaiSan": "04.O17.DODV.8920",
          "diaChiLapD": "Ngách 4/26, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006501050127117,
          "to_latitude": 21.006302606030637,
          "to_longitude": 105.83942845848445,
          "from_longitude": 105.8387729458965
        }
      },
      {
        "id": 8913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83875390354066,
            21.00593010323777,
            105.8393937095546,
            21.00622617019663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83875390354066,
              21.006218972025124
            ],
            [
              105.83875529559056,
              21.0062195269705
            ],
            [
              105.83878421873555,
              21.006226157708255
            ],
            [
              105.83878425054726,
              21.00622617019663
            ],
            [
              105.8393937095546,
              21.006157603012277
            ],
            [
              105.83936203513161,
              21.00593010323777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,5_Ngách 4/22, Phương Mai",
          "maTaiSan": "04.O17.DODV.8921",
          "diaChiLapD": "Ngách 4/22, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 92.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006218972025124,
          "to_latitude": 21.00593010323777,
          "to_longitude": 105.83936203513161,
          "from_longitude": 105.83875390354066
        }
      },
      {
        "id": 8914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83801307250961,
            21.004522036561845,
            105.83837247473454,
            21.004756152337475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83801307250961,
              21.004522036561845
            ],
            [
              105.83816880016893,
              21.004558863778517
            ],
            [
              105.83818390796688,
              21.00456244202081
            ],
            [
              105.83819190109833,
              21.004564686913476
            ],
            [
              105.83821147786962,
              21.00457018598206
            ],
            [
              105.83837247473454,
              21.004605982672977
            ],
            [
              105.83836783612831,
              21.004644981489857
            ],
            [
              105.83834141042698,
              21.004756152337475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,23_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8922",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004522036561845,
          "to_latitude": 21.004756152337475,
          "to_longitude": 105.83834141042698,
          "from_longitude": 105.83801307250961
        }
      },
      {
        "id": 8915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763978126304,
            21.004179313373843,
            105.83780437020076,
            21.004434914006968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83765806115937,
              21.004434914006968
            ],
            [
              105.83765809274273,
              21.004434713335534
            ],
            [
              105.83766218278707,
              21.004409243029347
            ],
            [
              105.83763978126304,
              21.00438775264726
            ],
            [
              105.83769395773749,
              21.004179313373843
            ],
            [
              105.83780437020076,
              21.00420623880562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.1_Ngõ 8, Phương Mai",
          "maTaiSan": "04.O17.DODV.8923",
          "diaChiLapD": "Ngõ 8, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004434914006968,
          "to_latitude": 21.00420623880562,
          "to_longitude": 105.83780437020076,
          "from_longitude": 105.83765806115937
        }
      },
      {
        "id": 8916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376966868407,
            21.00392232936235,
            105.83775730473329,
            21.004152655700832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376966868407,
              21.004152655700832
            ],
            [
              105.83771396108132,
              21.00408702633545
            ],
            [
              105.83775730473329,
              21.00392232936235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.1_Ngõ 8, Phương Mai",
          "maTaiSan": "04.O17.DODV.8924",
          "diaChiLapD": "Ngõ 8, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004152655700832,
          "to_latitude": 21.00392232936235,
          "to_longitude": 105.83775730473329,
          "from_longitude": 105.8376966868407
        }
      },
      {
        "id": 8917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774304375618,
            21.004384325736225,
            105.83776201792963,
            21.00445317353412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83776201792963,
              21.004384325736225
            ],
            [
              105.83774812622644,
              21.004434731756167
            ],
            [
              105.83774332468181,
              21.00445215060369
            ],
            [
              105.83774304375618,
              21.00445317353412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.2_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8925",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004384325736225,
          "to_latitude": 21.00445317353412,
          "to_longitude": 105.83774304375618,
          "from_longitude": 105.83776201792963
        }
      },
      {
        "id": 8918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774672716744,
            21.004385318190334,
            105.83776572664566,
            21.00445394030685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83776572664566,
              21.004385318190334
            ],
            [
              105.83775178087403,
              21.004435687444445
            ],
            [
              105.83774699397306,
              21.00445297254266
            ],
            [
              105.83774672716744,
              21.00445394030685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.3_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8926",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004385318190334,
          "to_latitude": 21.00445394030685,
          "to_longitude": 105.83774672716744,
          "from_longitude": 105.83776572664566
        }
      },
      {
        "id": 8919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83796884780939,
            21.00443751377536,
            105.83798883289505,
            21.00450910153821
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798883289505,
              21.00443751377536
            ],
            [
              105.83796904205494,
              21.004508403292036
            ],
            [
              105.83796884780939,
              21.00450910153821
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.5_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8927",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00443751377536,
          "to_latitude": 21.00450910153821,
          "to_longitude": 105.83796884780939,
          "from_longitude": 105.83798883289505
        }
      },
      {
        "id": 8920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83799152664474,
            21.004438047922495,
            105.83801187829701,
            21.004516334906985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83801187829701,
              21.004438047922495
            ],
            [
              105.83799156429119,
              21.00451618659272
            ],
            [
              105.83799152664474,
              21.004516334906985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8928",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004438047922495,
          "to_latitude": 21.004516334906985,
          "to_longitude": 105.83799152664474,
          "from_longitude": 105.83801187829701
        }
      },
      {
        "id": 8921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819888811877,
            21.004493279467983,
            105.8382189173581,
            21.00456503727822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382189173581,
              21.004493279467983
            ],
            [
              105.83820453543625,
              21.004545441094628
            ],
            [
              105.83820313070683,
              21.004550534976403
            ],
            [
              105.83819894474452,
              21.00456484280596
            ],
            [
              105.83819888811877,
              21.00456503727822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.7_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8929",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004493279467983,
          "to_latitude": 21.00456503727822,
          "to_longitude": 105.83819888811877,
          "from_longitude": 105.8382189173581
        }
      },
      {
        "id": 8922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83821525270953,
            21.004497130415757,
            105.83823513858898,
            21.00456975552097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823513858898,
              21.004497130415757
            ],
            [
              105.83822074894755,
              21.004549286662268
            ],
            [
              105.83821921128305,
              21.004554861714592
            ],
            [
              105.8382153695158,
              21.004569314171857
            ],
            [
              105.83821525270953,
              21.00456975552097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8930",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004497130415757,
          "to_latitude": 21.00456975552097,
          "to_longitude": 105.83821525270953,
          "from_longitude": 105.83823513858898
        }
      },
      {
        "id": 8923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8397183355337,
            21.004266895438633,
            105.83972068025162,
            21.004396057323813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397183355337,
              21.004396057323813
            ],
            [
              105.83972060587683,
              21.004270965736836
            ],
            [
              105.83972068025162,
              21.004266895438633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.5_Phương Mai",
          "maTaiSan": "04.O17.DODV.8931",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004396057323813,
          "to_latitude": 21.004266895438633,
          "to_longitude": 105.83972068025162,
          "from_longitude": 105.8397183355337
        }
      },
      {
        "id": 8924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83995008663692,
            21.00426943973053,
            105.83995170796462,
            21.00439479727318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83995008663692,
              21.00439479727318
            ],
            [
              105.83995132322661,
              21.004299173984208
            ],
            [
              105.83995169827482,
              21.004270114487287
            ],
            [
              105.83995170796462,
              21.00426943973053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.8_Phương Mai",
          "maTaiSan": "04.O17.DODV.8932",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00439479727318,
          "to_latitude": 21.00426943973053,
          "to_longitude": 105.83995170796462,
          "from_longitude": 105.83995008663692
        }
      },
      {
        "id": 8925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84076254369678,
            21.00425482525039,
            105.84076796798843,
            21.004400145372042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84076796798843,
              21.004400145372042
            ],
            [
              105.84076692869655,
              21.004313351517165
            ],
            [
              105.84076505121828,
              21.0042882972096
            ],
            [
              105.84076273714486,
              21.004257421065784
            ],
            [
              105.84076254369678,
              21.00425482525039
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,3_Phương Mai",
          "maTaiSan": "04.O17.DODV.8933",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004400145372042,
          "to_latitude": 21.00425482525039,
          "to_longitude": 105.84076254369678,
          "from_longitude": 105.84076796798843
        }
      },
      {
        "id": 8926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83942300023199,
            21.006097135842435,
            105.83969561926607,
            21.00612332207062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83942300023199,
              21.00612332207062
            ],
            [
              105.83969561926607,
              21.006097135842435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,4_Ngách 4/14, Phương Mai",
          "maTaiSan": "04.O17.DODV.8934",
          "diaChiLapD": "Ngách 4/14, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00612332207062,
          "to_latitude": 21.006097135842435,
          "to_longitude": 105.83969561926607,
          "from_longitude": 105.83942300023199
        }
      },
      {
        "id": 8927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83939334410012,
            21.005876446305397,
            105.83942300023199,
            21.00612332207062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83942300023199,
              21.00612332207062
            ],
            [
              105.83939334410012,
              21.005876446305397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,4_Ngách 4/14, Phương Mai",
          "maTaiSan": "04.O17.DODV.8935",
          "diaChiLapD": "Ngách 4/14, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00612332207062,
          "to_latitude": 21.005876446305397,
          "to_longitude": 105.83939334410012,
          "from_longitude": 105.83942300023199
        }
      },
      {
        "id": 8928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83868989982005,
            21.00568515416765,
            105.83892309399813,
            21.005850042795416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870226274234,
              21.00568515416765
            ],
            [
              105.83880753240352,
              21.005686956662938
            ],
            [
              105.83892309399813,
              21.005705604086465
            ],
            [
              105.83887299206657,
              21.00583037142863
            ],
            [
              105.83884545575026,
              21.005833329729153
            ],
            [
              105.83868989982005,
              21.005850042795416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,10_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8936",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00568515416765,
          "to_latitude": 21.005850042795416,
          "to_longitude": 105.83868989982005,
          "from_longitude": 105.83870226274234
        }
      },
      {
        "id": 8929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792614427915,
            21.0047386425381,
            105.83833867040265,
            21.004807353404267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83833867040265,
              21.004807353404267
            ],
            [
              105.83833652366248,
              21.004806518559615
            ],
            [
              105.83832707392285,
              21.004802837495177
            ],
            [
              105.83825300301325,
              21.00478742929914
            ],
            [
              105.83817528869263,
              21.004774110108066
            ],
            [
              105.83816004722415,
              21.004784835996485
            ],
            [
              105.83796281829501,
              21.0047386425381
            ],
            [
              105.83792614427915,
              21.004742952639983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,24_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8937",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004807353404267,
          "to_latitude": 21.004742952639983,
          "to_longitude": 105.83792614427915,
          "from_longitude": 105.83833867040265
        }
      },
      {
        "id": 8930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389859526741,
            21.005633744370634,
            105.83921975214271,
            21.005641461695575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389859526741,
              21.005633744370634
            ],
            [
              105.83907833679137,
              21.005638078732563
            ],
            [
              105.83912815942725,
              21.005640416316854
            ],
            [
              105.8391504383744,
              21.005641461695575
            ],
            [
              105.83921975214271,
              21.00563653244818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8938",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005633744370634,
          "to_latitude": 21.00563653244818,
          "to_longitude": 105.83921975214271,
          "from_longitude": 105.8389859526741
        }
      },
      {
        "id": 8931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83923457965123,
            21.005625858739645,
            105.83970435357266,
            21.005643458422945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923457965123,
              21.005643458422945
            ],
            [
              105.83946167946468,
              21.005630041931806
            ],
            [
              105.83948145958742,
              21.005628873143223
            ],
            [
              105.83970435357266,
              21.005625858739645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.8_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8939",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005643458422945,
          "to_latitude": 21.005625858739645,
          "to_longitude": 105.83970435357266,
          "from_longitude": 105.83923457965123
        }
      },
      {
        "id": 8932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765785917815,
            21.005923071929004,
            105.83807460106512,
            21.005970552177203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807460106512,
              21.005923071929004
            ],
            [
              105.83800929771716,
              21.00593051180423
            ],
            [
              105.83800765304875,
              21.005933464415875
            ],
            [
              105.83800465066346,
              21.00593391365047
            ],
            [
              105.83800269488269,
              21.00593420598987
            ],
            [
              105.83800030895472,
              21.005931536062796
            ],
            [
              105.83798237852974,
              21.005933578927277
            ],
            [
              105.83786623015938,
              21.005946812179594
            ],
            [
              105.83765785917815,
              21.005970552177203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.1_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8940",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005923071929004,
          "to_latitude": 21.005970552177203,
          "to_longitude": 105.83765785917815,
          "from_longitude": 105.83807460106512
        }
      },
      {
        "id": 8933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758053222789,
            21.0059884130176,
            105.83787281464039,
            21.006177204767067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758974898183,
              21.006177204767067
            ],
            [
              105.83758053222789,
              21.00601881361386
            ],
            [
              105.83764525181823,
              21.00601189703232
            ],
            [
              105.83764763584503,
              21.00601164233115
            ],
            [
              105.8376663441087,
              21.006009712189158
            ],
            [
              105.83787281464039,
              21.0059884130176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,14_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8941",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006177204767067,
          "to_latitude": 21.0059884130176,
          "to_longitude": 105.83787281464039,
          "from_longitude": 105.83758974898183
        }
      },
      {
        "id": 8934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764249517652,
            21.005990372295255,
            105.83764525181823,
            21.00601189703232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83764525181823,
              21.00601189703232
            ],
            [
              105.83764386916894,
              21.006001134685103
            ],
            [
              105.83764378972961,
              21.00600051636692
            ],
            [
              105.8376426088865,
              21.00599127134776
            ],
            [
              105.83764252638015,
              21.005990620528564
            ],
            [
              105.83764249517652,
              21.005990372295255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,14_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8942",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00601189703232,
          "to_latitude": 21.005990372295255,
          "to_longitude": 105.83764249517652,
          "from_longitude": 105.83764525181823
        }
      },
      {
        "id": 8935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766564787744,
            21.00617968734588,
            105.83794561154312,
            21.00620929041364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794561154312,
              21.00617968734588
            ],
            [
              105.83766564787744,
              21.00620929041364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,13_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8943",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00617968734588,
          "to_latitude": 21.00620929041364,
          "to_longitude": 105.83766564787744,
          "from_longitude": 105.83794561154312
        }
      },
      {
        "id": 8936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773204363747,
            21.005395534473966,
            105.83810207714643,
            21.00546859984466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773204363747,
              21.005395534473966
            ],
            [
              105.83804524612832,
              21.00546859984466
            ],
            [
              105.83805328584742,
              21.00544128466708
            ],
            [
              105.83809935982525,
              21.005453235973057
            ],
            [
              105.8381009056633,
              21.005449122299858
            ],
            [
              105.83810207714643,
              21.005446008533745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,17_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.8944",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005395534473966,
          "to_latitude": 21.005446008533745,
          "to_longitude": 105.83810207714643,
          "from_longitude": 105.83773204363747
        }
      },
      {
        "id": 8937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379009188721,
            21.00482935560165,
            105.83830547702908,
            21.004944711365123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379009188721,
              21.00482935560165
            ],
            [
              105.83790111539011,
              21.004829408827693
            ],
            [
              105.83795025465454,
              21.004842741476075
            ],
            [
              105.83795435623718,
              21.004860345914267
            ],
            [
              105.8382761674546,
              21.00493394296019
            ],
            [
              105.83829160014287,
              21.00494143507203
            ],
            [
              105.83830116217973,
              21.00494369390881
            ],
            [
              105.83830547702908,
              21.004944711365123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,26_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8945",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00482935560165,
          "to_latitude": 21.004944711365123,
          "to_longitude": 105.83830547702908,
          "from_longitude": 105.8379009188721
        }
      },
      {
        "id": 8938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83801596719181,
            21.00506185548097,
            105.83804955495431,
            21.00517084322655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83804955495431,
              21.00506185548097
            ],
            [
              105.83801596719181,
              21.00517084322655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,28_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8946",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00506185548097,
          "to_latitude": 21.00517084322655,
          "to_longitude": 105.83801596719181,
          "from_longitude": 105.83804955495431
        }
      },
      {
        "id": 8939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771934097923,
            21.005513363098856,
            105.83808819742461,
            21.005598797781804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83808819742461,
              21.005598797781804
            ],
            [
              105.83771934097923,
              21.005513363098856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,15_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.8947",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005598797781804,
          "to_latitude": 21.005513363098856,
          "to_longitude": 105.83771934097923,
          "from_longitude": 105.83808819742461
        }
      },
      {
        "id": 8940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766011826592,
            21.005513363098856,
            105.83771934097923,
            21.005709761014106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766011826592,
              21.005709761014106
            ],
            [
              105.83771934097923,
              21.005513363098856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,15_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.8948",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005709761014106,
          "to_latitude": 21.005513363098856,
          "to_longitude": 105.83771934097923,
          "from_longitude": 105.83766011826592
        }
      },
      {
        "id": 8941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771150179143,
            21.005314748099096,
            105.83774362759787,
            21.005486492802053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83771150179143,
              21.005486492802053
            ],
            [
              105.83773204363747,
              21.005395534473966
            ],
            [
              105.83774362759787,
              21.005314748099096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,17_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.8949",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005486492802053,
          "to_latitude": 21.005314748099096,
          "to_longitude": 105.83774362759787,
          "from_longitude": 105.83771150179143
        }
      },
      {
        "id": 8942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379958410344,
            21.005198217127894,
            105.83800884158201,
            21.00526041670116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379958410344,
              21.00526041670116
            ],
            [
              105.83800884158201,
              21.005198217127894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,30_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8950",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00526041670116,
          "to_latitude": 21.005198217127894,
          "to_longitude": 105.83800884158201,
          "from_longitude": 105.8379958410344
        }
      },
      {
        "id": 8943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862648648471,
            21.005323171790913,
            105.8390197601541,
            21.005407431882194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390197601541,
              21.005407431882194
            ],
            [
              105.83901819911185,
              21.005407100868368
            ],
            [
              105.83862648648471,
              21.005323171790913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,19_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8951",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005407431882194,
          "to_latitude": 21.005323171790913,
          "to_longitude": 105.83862648648471,
          "from_longitude": 105.8390197601541
        }
      },
      {
        "id": 8944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823865984039,
            21.005138843404378,
            105.83858504362324,
            21.005316849287947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858504362324,
              21.005316849287947
            ],
            [
              105.83823865984039,
              21.00523504163077
            ],
            [
              105.83825589159866,
              21.005190244413097
            ],
            [
              105.83827236027737,
              21.0051428090189
            ],
            [
              105.83827373668402,
              21.005138843404378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,29_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8952",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005316849287947,
          "to_latitude": 21.005138843404378,
          "to_longitude": 105.83827373668402,
          "from_longitude": 105.83858504362324
        }
      },
      {
        "id": 8945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825456745029,
            21.00494143507203,
            105.83829160014287,
            21.00507871528794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825456745029,
              21.00507871528794
            ],
            [
              105.83829160014287,
              21.00494143507203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,26_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8953",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00507871528794,
          "to_latitude": 21.00494143507203,
          "to_longitude": 105.83829160014287,
          "from_longitude": 105.83825456745029
        }
      },
      {
        "id": 8946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83795943510647,
            21.005131853259805,
            105.83821231791644,
            21.00529053949506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83821231791644,
              21.005131853259805
            ],
            [
              105.83820388580068,
              21.005161407665494
            ],
            [
              105.83818342380657,
              21.00518790157633
            ],
            [
              105.83818250513232,
              21.005189091131843
            ],
            [
              105.83812979020539,
              21.00525735003025
            ],
            [
              105.83812034243401,
              21.00529053949506
            ],
            [
              105.8379958410344,
              21.00526041670116
            ],
            [
              105.83795943510647,
              21.00525160795585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,30_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8954",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005131853259805,
          "to_latitude": 21.00525160795585,
          "to_longitude": 105.83795943510647,
          "from_longitude": 105.83821231791644
        }
      },
      {
        "id": 8947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814620153169,
            21.00546883070822,
            105.83848630677984,
            21.00555133785595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814620153169,
              21.00546883070822
            ],
            [
              105.8382929086542,
              21.00550442233081
            ],
            [
              105.83831343105064,
              21.00550940098432
            ],
            [
              105.83833032611636,
              21.0055134978904
            ],
            [
              105.83848630677984,
              21.00555133785595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,8_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.8955",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00546883070822,
          "to_latitude": 21.00555133785595,
          "to_longitude": 105.83848630677984,
          "from_longitude": 105.83814620153169
        }
      },
      {
        "id": 8948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850413836026,
            21.005556403807116,
            105.83891467410936,
            21.005649914809027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850413836026,
              21.005556403807116
            ],
            [
              105.83863295442863,
              21.005580906769584
            ],
            [
              105.83872112095386,
              21.005597677838427
            ],
            [
              105.83891467410936,
              21.005649914809027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,4_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.8956",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005556403807116,
          "to_latitude": 21.005649914809027,
          "to_longitude": 105.83891467410936,
          "from_longitude": 105.83850413836026
        }
      },
      {
        "id": 8949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884545575026,
            21.005833329729153,
            105.83884590627878,
            21.005845958205065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83884545575026,
              21.005833329729153
            ],
            [
              105.83884577300323,
              21.005842248414222
            ],
            [
              105.83884590627878,
              21.005845958205065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,10_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8957",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005833329729153,
          "to_latitude": 21.005845958205065,
          "to_longitude": 105.83884590627878,
          "from_longitude": 105.83884545575026
        }
      },
      {
        "id": 8950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635114012571,
            21.00357878094344,
            105.8364964561988,
            21.004061167139948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364964561988,
              21.00357878094344
            ],
            [
              105.83649573183796,
              21.00358118256704
            ],
            [
              105.83648851295928,
              21.003605149004063
            ],
            [
              105.83635114012571,
              21.004061167139948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,32_Ngõ 46, Phương Mai",
          "maTaiSan": "04.O17.DODV.8958",
          "diaChiLapD": "Ngõ 46, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00357878094344,
          "to_latitude": 21.004061167139948,
          "to_longitude": 105.83635114012571,
          "from_longitude": 105.8364964561988
        }
      },
      {
        "id": 8951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608168633498,
            21.00398439357141,
            105.83648526272044,
            21.004096125829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608168633498,
              21.00398439357141
            ],
            [
              105.83623310115159,
              21.004030399773864
            ],
            [
              105.83635114012571,
              21.004061167139948
            ],
            [
              105.83648526272044,
              21.004096125829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,32_Ngõ 46, Phương Mai",
          "maTaiSan": "04.O17.DODV.8959",
          "diaChiLapD": "Ngõ 46, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00398439357141,
          "to_latitude": 21.004096125829,
          "to_longitude": 105.83648526272044,
          "from_longitude": 105.83608168633498
        }
      },
      {
        "id": 8952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615400471658,
            21.00371693013747,
            105.83641481710343,
            21.003786181558073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641481710343,
              21.003786181558073
            ],
            [
              105.83615400471658,
              21.00371693013747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,33_Ngõ 46, Phương Mai",
          "maTaiSan": "04.O17.DODV.8960",
          "diaChiLapD": "Ngõ 46, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003786181558073,
          "to_latitude": 21.00371693013747,
          "to_longitude": 105.83615400471658,
          "from_longitude": 105.83641481710343
        }
      },
      {
        "id": 8953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611289473606,
            21.003662129265106,
            105.83616621513373,
            21.00382154676774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611289473606,
              21.00382154676774
            ],
            [
              105.83615400471658,
              21.00371693013747
            ],
            [
              105.83616621513373,
              21.003662129265106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,33_Ngõ 46, Phương Mai",
          "maTaiSan": "04.O17.DODV.8961",
          "diaChiLapD": "Ngõ 46, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00382154676774,
          "to_latitude": 21.003662129265106,
          "to_longitude": 105.83616621513373,
          "from_longitude": 105.83611289473606
        }
      },
      {
        "id": 8954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773285719575,
            21.00446016563094,
            105.83775859477277,
            21.004543739481925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775859477277,
              21.00446016563094
            ],
            [
              105.83773285719575,
              21.004543739481925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,21_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8962",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00446016563094,
          "to_latitude": 21.004543739481925,
          "to_longitude": 105.83773285719575,
          "from_longitude": 105.83775859477277
        }
      },
      {
        "id": 8955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766528064724,
            21.00449388317889,
            105.83792113130403,
            21.00478182829036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792113130403,
              21.00449388317889
            ],
            [
              105.83792100552904,
              21.004494441088013
            ],
            [
              105.83791971333565,
              21.00450022538095
            ],
            [
              105.83789590543218,
              21.00460672896307
            ],
            [
              105.83782190584681,
              21.004606312858392
            ],
            [
              105.83779523885467,
              21.00462160566327
            ],
            [
              105.83784816243083,
              21.004705480568067
            ],
            [
              105.83786455002281,
              21.00471879743452
            ],
            [
              105.83787316581316,
              21.00474952880426
            ],
            [
              105.83786194224756,
              21.00478182829036
            ],
            [
              105.83778925617459,
              21.004763953591365
            ],
            [
              105.83776127686775,
              21.004765235700003
            ],
            [
              105.83772461400834,
              21.004667810033542
            ],
            [
              105.83766528064724,
              21.00457428496333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,22_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.8963",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00449388317889,
          "to_latitude": 21.00457428496333,
          "to_longitude": 105.83766528064724,
          "from_longitude": 105.83792113130403
        }
      },
      {
        "id": 8956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890811890376,
            21.005633744370634,
            105.8389859526741,
            21.005858109740995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389859526741,
              21.005633744370634
            ],
            [
              105.83890811890376,
              21.005858109740995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8964",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005633744370634,
          "to_latitude": 21.005858109740995,
          "to_longitude": 105.83890811890376,
          "from_longitude": 105.8389859526741
        }
      },
      {
        "id": 8957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886879373269,
            21.005802224969788,
            105.83973147544226,
            21.005895115408602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886879373269,
              21.005895115408602
            ],
            [
              105.83886882735187,
              21.005895107113876
            ],
            [
              105.83887071055983,
              21.00589490996088
            ],
            [
              105.83973147544226,
              21.005802224969788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,3_Ngách 4/14, Phương Mai",
          "maTaiSan": "04.O17.DODV.8965",
          "diaChiLapD": "Ngách 4/14, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 89.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005895115408602,
          "to_latitude": 21.005802224969788,
          "to_longitude": 105.83973147544226,
          "from_longitude": 105.83886879373269
        }
      },
      {
        "id": 8958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84063737874044,
            21.005353387125133,
            105.84063893287562,
            21.00549277771345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84063737874044,
              21.005353387125133
            ],
            [
              105.84063890353829,
              21.00549011605551
            ],
            [
              105.84063893287562,
              21.00549277771345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.5_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8966",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005353387125133,
          "to_latitude": 21.00549277771345,
          "to_longitude": 105.84063893287562,
          "from_longitude": 105.84063737874044
        }
      },
      {
        "id": 8959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84050741457337,
            21.004640927257743,
            105.8405111638294,
            21.00466790883648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84050741457337,
              21.00466790883648
            ],
            [
              105.84050789164552,
              21.004664471517017
            ],
            [
              105.84050958277929,
              21.004652308470888
            ],
            [
              105.8405111638294,
              21.004640927257743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1.12_Ngách 2/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8967",
          "diaChiLapD": "Ngách 2/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00466790883648,
          "to_latitude": 21.004640927257743,
          "to_longitude": 105.8405111638294,
          "from_longitude": 105.84050741457337
        }
      },
      {
        "id": 8960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593082423525,
            21.00349648892548,
            105.83616723913245,
            21.004005659465957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593082423525,
              21.004005659465957
            ],
            [
              105.83608407531226,
              21.003499840165027
            ],
            [
              105.83616008228076,
              21.003516922530288
            ],
            [
              105.83616654606584,
              21.003498465878106
            ],
            [
              105.83616723913245,
              21.00349648892548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,35_Lương Định Của",
          "maTaiSan": "04.O17.DODV.8968",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004005659465957,
          "to_latitude": 21.00349648892548,
          "to_longitude": 105.83616723913245,
          "from_longitude": 105.83593082423525
        }
      },
      {
        "id": 8961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363466560835,
            21.003572870611073,
            105.83647267111331,
            21.00402926286257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363466560835,
              21.00402926286257
            ],
            [
              105.83641481710343,
              21.003786181558073
            ],
            [
              105.83646700522112,
              21.00360004218838
            ],
            [
              105.83647232165197,
              21.003574541489193
            ],
            [
              105.83647267111331,
              21.003572870611073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,33_Ngõ 46, Phương Mai",
          "maTaiSan": "04.O17.DODV.8969",
          "diaChiLapD": "Ngõ 46, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00402926286257,
          "to_latitude": 21.003572870611073,
          "to_longitude": 105.83647267111331,
          "from_longitude": 105.8363466560835
        }
      },
      {
        "id": 8962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83875131517384,
            21.0061731981337,
            105.83947498026978,
            21.006453693798246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83875131517384,
              21.006250128877006
            ],
            [
              105.83875663131211,
              21.00624993016139
            ],
            [
              105.83945211815687,
              21.0061731981337
            ],
            [
              105.83947498026978,
              21.006453693798246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,6_Ngách 4/22, Phương Mai",
          "maTaiSan": "04.O17.DODV.8970",
          "diaChiLapD": "Ngách 4/22, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 104.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006250128877006,
          "to_latitude": 21.006453693798246,
          "to_longitude": 105.83947498026978,
          "from_longitude": 105.83875131517384
        }
      },
      {
        "id": 8963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83885110662783,
            21.005825725797767,
            105.83971302163555,
            21.005935522543705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885110662783,
              21.005935522543705
            ],
            [
              105.83885114120886,
              21.00593551424423
            ],
            [
              105.83885282445414,
              21.005935336141512
            ],
            [
              105.83939334410012,
              21.005876446305397
            ],
            [
              105.83941418184652,
              21.00587398972832
            ],
            [
              105.83971302163555,
              21.005825725797767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,4_Ngách 4/14, Phương Mai",
          "maTaiSan": "04.O17.DODV.8971",
          "diaChiLapD": "Ngách 4/14, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 89.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005935522543705,
          "to_latitude": 21.005825725797767,
          "to_longitude": 105.83971302163555,
          "from_longitude": 105.83885110662783
        }
      },
      {
        "id": 8964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83939418518094,
            21.00473165232468,
            105.83939643186699,
            21.004868243651014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83939418518094,
              21.004868243651014
            ],
            [
              105.83939423943829,
              21.00486487435468
            ],
            [
              105.83939447804453,
              21.00485043964452
            ],
            [
              105.83939643186699,
              21.00473165232468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8972",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004868243651014,
          "to_latitude": 21.00473165232468,
          "to_longitude": 105.83939643186699,
          "from_longitude": 105.83939418518094
        }
      },
      {
        "id": 8965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83937227681012,
            21.00473101454875,
            105.83942726998357,
            21.004732464878188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83937227681012,
              21.00473101454875
            ],
            [
              105.83939643186699,
              21.00473165232468
            ],
            [
              105.83942726998357,
              21.004732464878188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8973",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00473101454875,
          "to_latitude": 21.004732464878188,
          "to_longitude": 105.83942726998357,
          "from_longitude": 105.83937227681012
        }
      },
      {
        "id": 8966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380622825577,
            21.006935944832684,
            105.83819984918061,
            21.006950334177496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380622825577,
              21.006950334177496
            ],
            [
              105.83819984918061,
              21.006935944832684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8974",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006950334177496,
          "to_latitude": 21.006935944832684,
          "to_longitude": 105.83819984918061,
          "from_longitude": 105.8380622825577
        }
      },
      {
        "id": 8967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798237852974,
            21.005933578927277,
            105.83798443006258,
            21.005948367264445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798443006258,
              21.005948367264445
            ],
            [
              105.8379842006104,
              21.005946722718264
            ],
            [
              105.8379827423365,
              21.00593620190783
            ],
            [
              105.83798237852974,
              21.005933578927277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.1_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8975",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005948367264445,
          "to_latitude": 21.005933578927277,
          "to_longitude": 105.83798237852974,
          "from_longitude": 105.83798443006258
        }
      },
      {
        "id": 8968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009632555627,
            21.00461843440871,
            105.8401238429674,
            21.00461905436273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009632555627,
              21.00461905436273
            ],
            [
              105.84011194908457,
              21.004618702665436
            ],
            [
              105.84012345635387,
              21.004618444446074
            ],
            [
              105.8401238429674,
              21.00461843440871
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.1_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8976",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00461905436273,
          "to_latitude": 21.00461843440871,
          "to_longitude": 105.8401238429674,
          "from_longitude": 105.84009632555627
        }
      },
      {
        "id": 8969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83762754291564,
            21.006205625258143,
            105.8379490969677,
            21.00623767845109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83762754291564,
              21.00623767845109
            ],
            [
              105.8379490969677,
              21.006205625258143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,13_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8977",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00623767845109,
          "to_latitude": 21.006205625258143,
          "to_longitude": 105.8379490969677,
          "from_longitude": 105.83762754291564
        }
      },
      {
        "id": 8970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400418462386,
            21.005397588030515,
            105.8401698795572,
            21.005398489177992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8400418462386,
              21.005398489177992
            ],
            [
              105.8401698795572,
              21.005397588030515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.3_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8978",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005398489177992,
          "to_latitude": 21.005397588030515,
          "to_longitude": 105.8401698795572,
          "from_longitude": 105.8400418462386
        }
      },
      {
        "id": 8971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84016472284117,
            21.00534390834019,
            105.84027403999858,
            21.005506078576296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018713247568,
              21.005346534795486
            ],
            [
              105.84019119778581,
              21.00534661498467
            ],
            [
              105.84019334394593,
              21.005346655873726
            ],
            [
              105.8401933285639,
              21.00534390834019
            ],
            [
              105.84018712017546,
              21.005343994085187
            ],
            [
              105.84017607395909,
              21.005344147066833
            ],
            [
              105.84017756912853,
              21.005376867128984
            ],
            [
              105.8401698795572,
              21.005397588030515
            ],
            [
              105.84016472284117,
              21.005411481598205
            ],
            [
              105.84018160900871,
              21.00549799851765
            ],
            [
              105.84027403999858,
              21.005506078576296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.3_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8979",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005346534795486,
          "to_latitude": 21.005506078576296,
          "to_longitude": 105.84027403999858,
          "from_longitude": 105.84018713247568
        }
      },
      {
        "id": 8972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83646090900868,
            21.003571494935176,
            105.83646717026993,
            21.003593838084967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646090900868,
              21.003593838084967
            ],
            [
              105.83646432681196,
              21.00358164129272
            ],
            [
              105.83646681941462,
              21.003572743110617
            ],
            [
              105.83646717026993,
              21.003571494935176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,34_Phương Mai",
          "maTaiSan": "04.O17.DODV.8980",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003593838084967,
          "to_latitude": 21.003571494935176,
          "to_longitude": 105.83646717026993,
          "from_longitude": 105.83646090900868
        }
      },
      {
        "id": 8973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384897478085,
            21.005837878950246,
            105.83849790138709,
            21.00589000182534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384897478085,
              21.005837878950246
            ],
            [
              105.83849524704377,
              21.005873035177412
            ],
            [
              105.83849730094803,
              21.005886173303473
            ],
            [
              105.83849790138709,
              21.00589000182534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.5_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8981",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005837878950246,
          "to_latitude": 21.00589000182534,
          "to_longitude": 105.83849790138709,
          "from_longitude": 105.8384897478085
        }
      },
      {
        "id": 8974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824954852938,
            21.005876567683124,
            105.83825583480318,
            21.0059176289407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824954852938,
              21.005876567683124
            ],
            [
              105.83825356569031,
              21.005902810066264
            ],
            [
              105.83825566513927,
              21.00591652874512
            ],
            [
              105.83825583480318,
              21.0059176289407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.3_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8982",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005876567683124,
          "to_latitude": 21.0059176289407,
          "to_longitude": 105.83825583480318,
          "from_longitude": 105.83824954852938
        }
      },
      {
        "id": 8975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83800285107799,
            21.00591205182125,
            105.83800564328176,
            21.005945965010177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83800285107799,
              21.00591205182125
            ],
            [
              105.83800465066346,
              21.00593391365047
            ],
            [
              105.8380055432898,
              21.005944766922962
            ],
            [
              105.83800564328176,
              21.005945965010177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.2_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.8983",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00591205182125,
          "to_latitude": 21.005945965010177,
          "to_longitude": 105.83800564328176,
          "from_longitude": 105.83800285107799
        }
      },
      {
        "id": 8976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823762390051,
            21.00656412419708,
            105.83823980835967,
            21.006582727999593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823762390051,
              21.00656412419708
            ],
            [
              105.83823840343032,
              21.00657074099606
            ],
            [
              105.83823842160638,
              21.006570895358013
            ],
            [
              105.83823948926415,
              21.006580017186913
            ],
            [
              105.83823980835967,
              21.006582727999593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.3_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.8984",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00656412419708,
          "to_latitude": 21.006582727999593,
          "to_longitude": 105.83823980835967,
          "from_longitude": 105.83823762390051
        }
      },
      {
        "id": 8977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803249404856,
            21.00665618406343,
            105.83879299092816,
            21.006742088816992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803249404856,
              21.006742088816992
            ],
            [
              105.83878803461897,
              21.006656745006744
            ],
            [
              105.83879291883471,
              21.006656192547776
            ],
            [
              105.83879299092816,
              21.00665618406343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,8_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8985",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006742088816992,
          "to_latitude": 21.00665618406343,
          "to_longitude": 105.83879299092816,
          "from_longitude": 105.83803249404856
        }
      },
      {
        "id": 8978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83962457983584,
            21.00557280303747,
            105.83962477159099,
            21.005614787080592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83962477159099,
              21.00557280303747
            ],
            [
              105.83962460346575,
              21.00560825543842
            ],
            [
              105.83962457983584,
              21.005613143807352
            ],
            [
              105.83962470060665,
              21.005614787080592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.10_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8986",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557280303747,
          "to_latitude": 21.005614787080592,
          "to_longitude": 105.83962470060665,
          "from_longitude": 105.83962477159099
        }
      },
      {
        "id": 8979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965088326231,
            21.005571565918576,
            105.83965095230252,
            21.005615012537774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839650891977,
              21.005571565918576
            ],
            [
              105.8396508852757,
              21.005609049778684
            ],
            [
              105.83965088326231,
              21.005612472107178
            ],
            [
              105.83965095230252,
              21.005615012537774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.11_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8987",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005571565918576,
          "to_latitude": 21.005615012537774,
          "to_longitude": 105.83965095230252,
          "from_longitude": 105.839650891977
        }
      },
      {
        "id": 8980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945759206597,
            21.005571222296897,
            105.83945761646252,
            21.005613698343495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945761646252,
              21.005571222296897
            ],
            [
              105.83945759429331,
              21.005610936296648
            ],
            [
              105.83945759206597,
              21.005613633336832
            ],
            [
              105.83945759723896,
              21.005613698343495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.7_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8988",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005571222296897,
          "to_latitude": 21.005613698343495,
          "to_longitude": 105.83945759723896,
          "from_longitude": 105.83945761646252
        }
      },
      {
        "id": 8981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83949390066967,
            21.00557165571361,
            105.83949404152173,
            21.005614202325088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83949390066967,
              21.00557165571361
            ],
            [
              105.8394940186292,
              21.005607358703912
            ],
            [
              105.8394940393524,
              21.00561398646507
            ],
            [
              105.83949404152173,
              21.005614202325088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.9_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8989",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557165571361,
          "to_latitude": 21.005614202325088,
          "to_longitude": 105.83949404152173,
          "from_longitude": 105.83949390066967
        }
      },
      {
        "id": 8982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929422061595,
            21.005572445431145,
            105.83929452391214,
            21.005617905949805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929422061595,
              21.005572445431145
            ],
            [
              105.83929445251614,
              21.005607375603855
            ],
            [
              105.83929451754122,
              21.005617282756102
            ],
            [
              105.83929452391214,
              21.005617905949805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.4_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8990",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005572445431145,
          "to_latitude": 21.005617905949805,
          "to_longitude": 105.83929452391214,
          "from_longitude": 105.83929422061595
        }
      },
      {
        "id": 8983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933261136846,
            21.00557219920452,
            105.83933275408764,
            21.00561732361181
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933261136846,
              21.00557219920452
            ],
            [
              105.83933272831887,
              21.00560738465319
            ],
            [
              105.83933274497073,
              21.00561603746467
            ],
            [
              105.83933275408764,
              21.00561732361181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.5_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8991",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557219920452,
          "to_latitude": 21.00561732361181,
          "to_longitude": 105.83933275408764,
          "from_longitude": 105.83933261136846
        }
      },
      {
        "id": 8984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83913041127641,
            21.005571969674893,
            105.83913100532415,
            21.00562019805969
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83913041127641,
              21.005571969674893
            ],
            [
              105.83913092413688,
              21.00561346039209
            ],
            [
              105.83913100532415,
              21.005620067081473
            ],
            [
              105.83913100317093,
              21.00562019805969
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.2_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8992",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005571969674893,
          "to_latitude": 21.00562019805969,
          "to_longitude": 105.83913100317093,
          "from_longitude": 105.83913041127641
        }
      },
      {
        "id": 8985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83913348173468,
            21.005571827181534,
            105.83913388058443,
            21.005620333858786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83913348173468,
              21.005571827181534
            ],
            [
              105.83913381073219,
              21.005611399452462
            ],
            [
              105.83913388058443,
              21.005619935485978
            ],
            [
              105.83913387030762,
              21.005620333858786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.3_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8993",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005571827181534,
          "to_latitude": 21.005620333858786,
          "to_longitude": 105.83913387030762,
          "from_longitude": 105.83913348173468
        }
      },
      {
        "id": 8986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877920331621,
            21.005975624179545,
            105.83885306985252,
            21.006226157708255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83878421873555,
              21.006226157708255
            ],
            [
              105.83877920331621,
              21.006184303503986
            ],
            [
              105.83885306985252,
              21.005975624179545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,5_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.8994",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006226157708255,
          "to_latitude": 21.005975624179545,
          "to_longitude": 105.83885306985252,
          "from_longitude": 105.83878421873555
        }
      },
      {
        "id": 8987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84062688626081,
            21.005492275039305,
            105.84065804819804,
            21.005493094633405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84062688626081,
              21.005493094633405
            ],
            [
              105.84063893287562,
              21.00549277771345
            ],
            [
              105.84065804819804,
              21.005492275039305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.5_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8995",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005493094633405,
          "to_latitude": 21.005492275039305,
          "to_longitude": 105.84065804819804,
          "from_longitude": 105.84062688626081
        }
      },
      {
        "id": 8988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84016465914391,
            21.00494150623068,
            105.84075469807462,
            21.004946164933358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84016465914391,
              21.004946164933358
            ],
            [
              105.84016763118558,
              21.004946142133214
            ],
            [
              105.84075469807462,
              21.00494150623068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.11_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8996",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004946164933358,
          "to_latitude": 21.00494150623068,
          "to_longitude": 105.84075469807462,
          "from_longitude": 105.84016465914391
        }
      },
      {
        "id": 8989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84030915239339,
            21.004771908713927,
            105.84030939744142,
            21.00484968870039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84030915239339,
              21.004771908713927
            ],
            [
              105.8403093808168,
              21.00481631282289
            ],
            [
              105.84030939568856,
              21.004849032064673
            ],
            [
              105.84030939744142,
              21.00484968870039
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.3_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.8997",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004771908713927,
          "to_latitude": 21.00484968870039,
          "to_longitude": 105.84030939744142,
          "from_longitude": 105.84030915239339
        }
      },
      {
        "id": 8990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743830720364,
            21.00401521344937,
            105.83760050906957,
            21.004073514243235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743830720364,
              21.00401521344937
            ],
            [
              105.83756518920546,
              21.004048297487945
            ],
            [
              105.8375797099307,
              21.004065098279888
            ],
            [
              105.83760050906957,
              21.004073514243235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,31_Ngõ 34, Phương Mai",
          "maTaiSan": "04.O17.DODV.8998",
          "diaChiLapD": "Ngõ 34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00401521344937,
          "to_latitude": 21.004073514243235,
          "to_longitude": 105.83760050906957,
          "from_longitude": 105.83743830720364
        }
      },
      {
        "id": 8991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771396108132,
            21.00408702633545,
            105.83774173207924,
            21.004111428467567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83771396108132,
              21.00408702633545
            ],
            [
              105.83774173207924,
              21.004093940225932
            ],
            [
              105.83773718968435,
              21.004111258443377
            ],
            [
              105.83773714542586,
              21.004111428467567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.1_Ngõ 8, Phương Mai",
          "maTaiSan": "04.O17.DODV.8999",
          "diaChiLapD": "Ngõ 8, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00408702633545,
          "to_latitude": 21.004111428467567,
          "to_longitude": 105.83773714542586,
          "from_longitude": 105.83771396108132
        }
      },
      {
        "id": 8992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83741291016919,
            21.003754273300892,
            105.83750073506793,
            21.00433343860756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748937360143,
              21.003754273300892
            ],
            [
              105.83748932271959,
              21.003755324905505
            ],
            [
              105.83748855210202,
              21.00377149823677
            ],
            [
              105.8374844909079,
              21.003856612882018
            ],
            [
              105.83743830720364,
              21.00401521344937
            ],
            [
              105.83741291016919,
              21.004102435516998
            ],
            [
              105.83750073506793,
              21.00433343860756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,31_Ngõ 34, Phương Mai",
          "maTaiSan": "04.O17.DODV.9000",
          "diaChiLapD": "Ngõ 34, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003754273300892,
          "to_latitude": 21.00433343860756,
          "to_longitude": 105.83750073506793,
          "from_longitude": 105.83748937360143
        }
      },
      {
        "id": 8993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382929086542,
            21.00549534397107,
            105.8382956685307,
            21.00550442233081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382929086542,
              21.00550442233081
            ],
            [
              105.83829440759024,
              21.005499489661943
            ],
            [
              105.8382956685307,
              21.00549534397107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,8_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9001",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00550442233081,
          "to_latitude": 21.00549534397107,
          "to_longitude": 105.8382956685307,
          "from_longitude": 105.8382929086542
        }
      },
      {
        "id": 8994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863295442863,
            21.005573508348412,
            105.83863480893365,
            21.005580906769584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863295442863,
              21.005580906769584
            ],
            [
              105.83863390943726,
              21.005577096777177
            ],
            [
              105.8386345878161,
              21.00557438917905
            ],
            [
              105.83863480893365,
              21.005573508348412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,4_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9002",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005580906769584,
          "to_latitude": 21.005573508348412,
          "to_longitude": 105.83863480893365,
          "from_longitude": 105.83863295442863
        }
      },
      {
        "id": 8995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838296469144,
            21.004802837495177,
            105.83832707392285,
            21.00491681536903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832707392285,
              21.004802837495177
            ],
            [
              105.838296469144,
              21.00491681536903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,24_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9003",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004802837495177,
          "to_latitude": 21.00491681536903,
          "to_longitude": 105.838296469144,
          "from_longitude": 105.83832707392285
        }
      },
      {
        "id": 8996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83828988444161,
            21.0055134978904,
            105.83833032611636,
            21.005696061825756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83833032611636,
              21.0055134978904
            ],
            [
              105.83830459954359,
              21.005639478172313
            ],
            [
              105.83828988444161,
              21.005696061825756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,8_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9004",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0055134978904,
          "to_latitude": 21.005696061825756,
          "to_longitude": 105.83828988444161,
          "from_longitude": 105.83833032611636
        }
      },
      {
        "id": 8997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371728424895,
            21.00377042749825,
            105.8374844909079,
            21.003856612882018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371728424895,
              21.00377042749825
            ],
            [
              105.8374844909079,
              21.003856612882018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,31_Phương Mai",
          "maTaiSan": "04.O17.DODV.9005",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00377042749825,
          "to_latitude": 21.003856612882018,
          "to_longitude": 105.8374844909079,
          "from_longitude": 105.8371728424895
        }
      },
      {
        "id": 8998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819190109833,
            21.00456250176432,
            105.83819250160239,
            21.004564686913476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819250160239,
              21.00456250176432
            ],
            [
              105.83819244974043,
              21.004562688084096
            ],
            [
              105.83819190109833,
              21.004564686913476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,23_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9006",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00456250176432,
          "to_latitude": 21.004564686913476,
          "to_longitude": 105.83819190109833,
          "from_longitude": 105.83819250160239
        }
      },
      {
        "id": 8999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83776330178888,
            21.004457900542175,
            105.83776424270106,
            21.004461309429242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83776424270106,
              21.004457900542175
            ],
            [
              105.83776363552369,
              21.0044600974648
            ],
            [
              105.83776330178888,
              21.004461309429242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,21_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9007",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004457900542175,
          "to_latitude": 21.004461309429242,
          "to_longitude": 105.83776330178888,
          "from_longitude": 105.83776424270106
        }
      },
      {
        "id": 9000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809610270072,
            21.004872787797034,
            105.83830607129764,
            21.004942443149663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83830607129764,
              21.004942443149663
            ],
            [
              105.83830146631881,
              21.004941316928928
            ],
            [
              105.83829268785117,
              21.00493916713592
            ],
            [
              105.83828702702351,
              21.004925176061455
            ],
            [
              105.83820159652439,
              21.004906517087232
            ],
            [
              105.83809610270072,
              21.004881356097542
            ],
            [
              105.83809836621859,
              21.004872787797034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,25_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9008",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004942443149663,
          "to_latitude": 21.004872787797034,
          "to_longitude": 105.83809836621859,
          "from_longitude": 105.83830607129764
        }
      },
      {
        "id": 9001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793503240412,
            21.005446312772623,
            105.83810357455434,
            21.0054922587418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793503240412,
              21.0054922587418
            ],
            [
              105.83794575387059,
              21.005450822893366
            ],
            [
              105.83805591849342,
              21.005477974253143
            ],
            [
              105.83806327396499,
              21.005446978189514
            ],
            [
              105.83810017931778,
              21.005456160193155
            ],
            [
              105.83810347895158,
              21.00544658963026
            ],
            [
              105.83810357455434,
              21.005446312772623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,16_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9009",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0054922587418,
          "to_latitude": 21.005446312772623,
          "to_longitude": 105.83810357455434,
          "from_longitude": 105.83793503240412
        }
      },
      {
        "id": 9002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84014969543117,
            21.00429305020929,
            105.84051135670857,
            21.004468107747762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84051135670857,
              21.00429305020929
            ],
            [
              105.84030594476323,
              21.004293157629736
            ],
            [
              105.84030152541243,
              21.004297697378643
            ],
            [
              105.84029938349391,
              21.00429772421142
            ],
            [
              105.84029295966216,
              21.004297804700144
            ],
            [
              105.84028813818404,
              21.004297865535918
            ],
            [
              105.84028294446692,
              21.004293459956337
            ],
            [
              105.84027826915256,
              21.00429351374766
            ],
            [
              105.84023869872944,
              21.004293967421056
            ],
            [
              105.84017463407042,
              21.004294522127044
            ],
            [
              105.84014969543117,
              21.004316440947424
            ],
            [
              105.84015243932079,
              21.004468107747762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,10_Phương Mai",
          "maTaiSan": "04.O17.DODV.9010",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00429305020929,
          "to_latitude": 21.004468107747762,
          "to_longitude": 105.84015243932079,
          "from_longitude": 105.84051135670857
        }
      },
      {
        "id": 9003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8385814760746,
            21.006524552619542,
            105.83858425833269,
            21.00654753682105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8385814760746,
              21.006524552619542
            ],
            [
              105.83858236058576,
              21.00653187973722
            ],
            [
              105.83858238781754,
              21.006532105409256
            ],
            [
              105.83858373201996,
              21.006543203935205
            ],
            [
              105.83858425833269,
              21.00654753682105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.6_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9011",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006524552619542,
          "to_latitude": 21.00654753682105,
          "to_longitude": 105.83858425833269,
          "from_longitude": 105.8385814760746
        }
      },
      {
        "id": 9004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807940978676,
            21.006685688745133,
            105.83879685138275,
            21.006763806153927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83807940978676,
              21.006763806153927
            ],
            [
              105.83879288316486,
              21.006686121076825
            ],
            [
              105.83879677928425,
              21.00668569632627
            ],
            [
              105.83879685138275,
              21.006685688745133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,9_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9012",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006763806153927,
          "to_latitude": 21.006685688745133,
          "to_longitude": 105.83879685138275,
          "from_longitude": 105.83807940978676
        }
      },
      {
        "id": 9005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818342380657,
            21.00518790157633,
            105.83822497932964,
            21.00519873252322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822497932964,
              21.00519873252322
            ],
            [
              105.83821841464112,
              21.00519702253322
            ],
            [
              105.83819244965923,
              21.005190254285058
            ],
            [
              105.83818342380657,
              21.00518790157633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,30_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9013",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00519873252322,
          "to_latitude": 21.00518790157633,
          "to_longitude": 105.83818342380657,
          "from_longitude": 105.83822497932964
        }
      },
      {
        "id": 9006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377909931191,
            21.00501944944366,
            105.83825438550122,
            21.005113645702835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377909931191,
              21.00508475254598
            ],
            [
              105.837851038571,
              21.005098165212306
            ],
            [
              105.83787116000785,
              21.00501944944366
            ],
            [
              105.83804955495431,
              21.00506185548097
            ],
            [
              105.8382492784694,
              21.005112355760122
            ],
            [
              105.83825438550122,
              21.005113645702835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,28_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9014",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00508475254598,
          "to_latitude": 21.005113645702835,
          "to_longitude": 105.83825438550122,
          "from_longitude": 105.8377909931191
        }
      },
      {
        "id": 9007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805692258646,
            21.00504807409497,
            105.83825754446403,
            21.00510369105604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825754446403,
              21.00510369105604
            ],
            [
              105.83825493048337,
              21.005103069866387
            ],
            [
              105.83805692258646,
              21.005055949143085
            ],
            [
              105.83805908898397,
              21.00504807409497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,27_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9015",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00510369105604,
          "to_latitude": 21.00504807409497,
          "to_longitude": 105.83805908898397,
          "from_longitude": 105.83825754446403
        }
      },
      {
        "id": 9008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83787840397208,
            21.00477005622097,
            105.83791209845613,
            21.00494108339031
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83787840397208,
              21.00494108339031
            ],
            [
              105.8379009188721,
              21.00482935560165
            ],
            [
              105.83790184920383,
              21.004824740960526
            ],
            [
              105.83791209845613,
              21.00477005622097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,26_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9016",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00494108339031,
          "to_latitude": 21.00477005622097,
          "to_longitude": 105.83791209845613,
          "from_longitude": 105.83787840397208
        }
      },
      {
        "id": 9009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849108959946,
            21.005873510885348,
            105.8384937788262,
            21.00589052342598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384937788262,
              21.00589052342598
            ],
            [
              105.83849309782825,
              21.005886221107893
            ],
            [
              105.83849108959946,
              21.005873510885348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.4_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.9017",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00589052342598,
          "to_latitude": 21.005873510885348,
          "to_longitude": 105.83849108959946,
          "from_longitude": 105.8384937788262
        }
      },
      {
        "id": 9010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926794135864,
            21.004412049339173,
            105.83930714744378,
            21.00462145936793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83930714744378,
              21.004412049339173
            ],
            [
              105.83926794135864,
              21.00462145936793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.9_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9018",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004412049339173,
          "to_latitude": 21.00462145936793,
          "to_longitude": 105.83926794135864,
          "from_longitude": 105.83930714744378
        }
      },
      {
        "id": 9011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84021498217136,
            21.004496452099133,
            105.84088252398664,
            21.004503938841154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84021498217136,
              21.004503938841154
            ],
            [
              105.84088252398664,
              21.004496452099133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,2_Ngách 2/2A, Phương Mai",
          "maTaiSan": "04.O17.DODV.9019",
          "diaChiLapD": "Ngách 2/2A, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 69.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004503938841154,
          "to_latitude": 21.004496452099133,
          "to_longitude": 105.84088252398664,
          "from_longitude": 105.84021498217136
        }
      },
      {
        "id": 9012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947669467796,
            21.004262007649622,
            105.83947702272187,
            21.004398238682942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947699401739,
              21.004398238682942
            ],
            [
              105.83947702272187,
              21.004315325204693
            ],
            [
              105.83947690131991,
              21.004295684274556
            ],
            [
              105.83947670705521,
              21.00426422048963
            ],
            [
              105.83947669467796,
              21.004262007649622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.2_Phương Mai",
          "maTaiSan": "04.O17.DODV.9020",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004398238682942,
          "to_latitude": 21.004262007649622,
          "to_longitude": 105.83947669467796,
          "from_longitude": 105.83947699401739
        }
      },
      {
        "id": 9013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8394824664842,
            21.00426219644604,
            105.83948273334697,
            21.00429135386247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394824664842,
              21.00429135386247
            ],
            [
              105.83948255475444,
              21.004281684404415
            ],
            [
              105.83948270459491,
              21.004265138391403
            ],
            [
              105.83948273334697,
              21.00426219644604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.3_Phương Mai",
          "maTaiSan": "04.O17.DODV.9021",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00429135386247,
          "to_latitude": 21.00426219644604,
          "to_longitude": 105.83948273334697,
          "from_longitude": 105.8394824664842
        }
      },
      {
        "id": 9014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84027811808944,
            21.004266875685158,
            105.84027826915256,
            21.00429351374766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027826915256,
              21.00429351374766
            ],
            [
              105.84027819131258,
              21.004279782405707
            ],
            [
              105.84027814651249,
              21.004272124184002
            ],
            [
              105.84027811808944,
              21.004266875685158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,10_Phương Mai",
          "maTaiSan": "04.O17.DODV.9022",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00429351374766,
          "to_latitude": 21.004266875685158,
          "to_longitude": 105.84027811808944,
          "from_longitude": 105.84027826915256
        }
      },
      {
        "id": 9015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83993492652165,
            21.004269136631343,
            105.83993497466503,
            21.00429456445446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83993492652165,
              21.00429456445446
            ],
            [
              105.83993495665614,
              21.004286886894665
            ],
            [
              105.83993495705184,
              21.00428678573154
            ],
            [
              105.83993497254427,
              21.004270304511525
            ],
            [
              105.83993497466503,
              21.004269136631343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.6_Phương Mai",
          "maTaiSan": "04.O17.DODV.9023",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00429456445446,
          "to_latitude": 21.004269136631343,
          "to_longitude": 105.83993497466503,
          "from_longitude": 105.83993492652165
        }
      },
      {
        "id": 9016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816303060574,
            21.005391362220635,
            105.83832053211648,
            21.005429082853905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816303060574,
              21.005391362220635
            ],
            [
              105.83822084709766,
              21.005405209340978
            ],
            [
              105.83824401010332,
              21.00541075667517
            ],
            [
              105.83832053211648,
              21.005429082853905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,10_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9024",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005391362220635,
          "to_latitude": 21.005429082853905,
          "to_longitude": 105.83832053211648,
          "from_longitude": 105.83816303060574
        }
      },
      {
        "id": 9017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382014128081,
            21.005405209340978,
            105.83822084709766,
            21.005472255705353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822084709766,
              21.005405209340978
            ],
            [
              105.83821817410347,
              21.005414516690937
            ],
            [
              105.8382180561633,
              21.005414927335956
            ],
            [
              105.83820184030097,
              21.005470781343206
            ],
            [
              105.8382014128081,
              21.005472255705353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,10_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9025",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005405209340978,
          "to_latitude": 21.005472255705353,
          "to_longitude": 105.8382014128081,
          "from_longitude": 105.83822084709766
        }
      },
      {
        "id": 9018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377419165587,
            21.005294093440412,
            105.83809576805908,
            21.00544443262431
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809432913428,
              21.00544443262431
            ],
            [
              105.83809441387433,
              21.005444105239558
            ],
            [
              105.83809576805908,
              21.00543891496269
            ],
            [
              105.83808106903999,
              21.005417953039885
            ],
            [
              105.83809168269921,
              21.005366349206586
            ],
            [
              105.8377419165587,
              21.005294093440412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,18_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9026",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00544443262431,
          "to_latitude": 21.005294093440412,
          "to_longitude": 105.8377419165587,
          "from_longitude": 105.83809432913428
        }
      },
      {
        "id": 9019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377419165587,
            21.00490830910629,
            105.83777103627713,
            21.005294093440412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377419165587,
              21.005294093440412
            ],
            [
              105.83776877668556,
              21.005156232043177
            ],
            [
              105.83777103627713,
              21.00490830910629
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,18_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9027",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005294093440412,
          "to_latitude": 21.00490830910629,
          "to_longitude": 105.83777103627713,
          "from_longitude": 105.8377419165587
        }
      },
      {
        "id": 9020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764715647679,
            21.005958365381783,
            105.83797557920822,
            21.006465136815166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83789704192695,
              21.005958365381783
            ],
            [
              105.8378982948741,
              21.005963811079386
            ],
            [
              105.83794226959503,
              21.006154810919615
            ],
            [
              105.83794561154312,
              21.00617968734588
            ],
            [
              105.8379490969677,
              21.006205625258143
            ],
            [
              105.8379557216146,
              21.00625492949875
            ],
            [
              105.83797557920822,
              21.006429709626516
            ],
            [
              105.83764715647679,
              21.006465136815166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,13_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.9028",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005958365381783,
          "to_latitude": 21.006465136815166,
          "to_longitude": 105.83764715647679,
          "from_longitude": 105.83789704192695
        }
      },
      {
        "id": 9021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384567141626,
            21.00652926073657,
            105.8384606871958,
            21.006560162162515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384567141626,
              21.00652926073657
            ],
            [
              105.83845843168618,
              21.006542619099587
            ],
            [
              105.83846030512488,
              21.00655719875765
            ],
            [
              105.8384606871958,
              21.006560162162515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.5_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9029",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00652926073657,
          "to_latitude": 21.006560162162515,
          "to_longitude": 105.8384606871958,
          "from_longitude": 105.8384567141626
        }
      },
      {
        "id": 9022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384527454005,
            21.00652942299869,
            105.83845662462262,
            21.00656057778989
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384527454005,
              21.00652942299869
            ],
            [
              105.83845442719596,
              21.006542928763437
            ],
            [
              105.83845625810287,
              21.006557643211586
            ],
            [
              105.83845662462262,
              21.00656057778989
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.4_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9030",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00652942299869,
          "to_latitude": 21.00656057778989,
          "to_longitude": 105.83845662462262,
          "from_longitude": 105.8384527454005
        }
      },
      {
        "id": 9023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381513697681,
            21.00656349584709,
            105.83815477107908,
            21.006591414939187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8381513697681,
              21.00656349584709
            ],
            [
              105.83815301762166,
              21.006577129137398
            ],
            [
              105.83815347245766,
              21.006580894248465
            ],
            [
              105.83815459181832,
              21.006589974275066
            ],
            [
              105.83815477107908,
              21.006591414939187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.2_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9031",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00656349584709,
          "to_latitude": 21.006591414939187,
          "to_longitude": 105.83815477107908,
          "from_longitude": 105.8381513697681
        }
      },
      {
        "id": 9024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814877578777,
            21.006563704621822,
            105.83815233891632,
            21.006591663562173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814877578777,
              21.006563704621822
            ],
            [
              105.8381505090592,
              21.00657730407218
            ],
            [
              105.83815215989772,
              21.006590266251667
            ],
            [
              105.83815233891632,
              21.006591663562173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.1_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9032",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006563704621822,
          "to_latitude": 21.006591663562173,
          "to_longitude": 105.83815233891632,
          "from_longitude": 105.83814877578777
        }
      },
      {
        "id": 9025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850173044614,
            21.006891011722047,
            105.83850496795796,
            21.00692299883236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850173044614,
              21.006891011722047
            ],
            [
              105.83850487843458,
              21.00692212585505
            ],
            [
              105.83850496795796,
              21.00692299883236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.4_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9033",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006891011722047,
          "to_latitude": 21.00692299883236,
          "to_longitude": 105.83850496795796,
          "from_longitude": 105.83850173044614
        }
      },
      {
        "id": 9026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849459279664,
            21.006891266372495,
            105.83849958892266,
            21.006923651269535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83849459279664,
              21.006891266372495
            ],
            [
              105.83849950712353,
              21.006923127802168
            ],
            [
              105.83849958892266,
              21.006923651269535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.3_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9034",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006891266372495,
          "to_latitude": 21.006923651269535,
          "to_longitude": 105.83849958892266,
          "from_longitude": 105.83849459279664
        }
      },
      {
        "id": 9027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83822163557561,
            21.006915074195884,
            105.8382266777832,
            21.006951930741796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822163557561,
              21.006915074195884
            ],
            [
              105.83822658656649,
              21.006951270933698
            ],
            [
              105.8382266777832,
              21.006951930741796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.2_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9035",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006915074195884,
          "to_latitude": 21.006951930741796,
          "to_longitude": 105.8382266777832,
          "from_longitude": 105.83822163557561
        }
      },
      {
        "id": 9028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819769807523,
            21.00691814654273,
            105.83820244826339,
            21.006954213302787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819769807523,
              21.00691814654273
            ],
            [
              105.83819984918061,
              21.006935944832684
            ],
            [
              105.83820203980414,
              21.00695134667239
            ],
            [
              105.83820244826339,
              21.006954213302787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9036",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00691814654273,
          "to_latitude": 21.006954213302787,
          "to_longitude": 105.83820244826339,
          "from_longitude": 105.83819769807523
        }
      },
      {
        "id": 9029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904575943177,
            21.006649194465954,
            105.83904809452234,
            21.006666679320123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904575943177,
              21.006649194465954
            ],
            [
              105.83904655754483,
              21.006655171685406
            ],
            [
              105.83904742042093,
              21.00666189374597
            ],
            [
              105.83904809452234,
              21.006666679320123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9037",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006649194465954,
          "to_latitude": 21.006666679320123,
          "to_longitude": 105.83904809452234,
          "from_longitude": 105.83904575943177
        }
      },
      {
        "id": 9030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905033233155,
            21.006648529728157,
            105.83905272447998,
            21.006666069397667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905033233155,
              21.006648529728157
            ],
            [
              105.83905119286459,
              21.006654832703973
            ],
            [
              105.83905186594119,
              21.006659779057173
            ],
            [
              105.83905272447998,
              21.006666069397667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.2_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9038",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006648529728157,
          "to_latitude": 21.006666069397667,
          "to_longitude": 105.83905272447998,
          "from_longitude": 105.83905033233155
        }
      },
      {
        "id": 9031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393497400461,
            21.00662239801674,
            105.83935288882358,
            21.00664536865243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393497400461,
              21.00662239801674
            ],
            [
              105.83935062962986,
              21.006629077492132
            ],
            [
              105.83935140011077,
              21.00663492749141
            ],
            [
              105.83935263853485,
              21.006643613114804
            ],
            [
              105.83935288882358,
              21.00664536865243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.4_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9039",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00662239801674,
          "to_latitude": 21.00664536865243,
          "to_longitude": 105.83935288882358,
          "from_longitude": 105.8393497400461
        }
      },
      {
        "id": 9032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936128681091,
            21.006620918489965,
            105.83936445370666,
            21.006644032648758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936128681091,
              21.006620918489965
            ],
            [
              105.83936209579564,
              21.006627289459946
            ],
            [
              105.83936330978311,
              21.006636937138076
            ],
            [
              105.83936435504809,
              21.006643418942552
            ],
            [
              105.83936445370666,
              21.006644032648758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.5_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9040",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006620918489965,
          "to_latitude": 21.006644032648758,
          "to_longitude": 105.83936445370666,
          "from_longitude": 105.83936128681091
        }
      },
      {
        "id": 9033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912725568841,
            21.005620020536544,
            105.83912815942725,
            21.005640416316854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912815942725,
              21.005640416316854
            ],
            [
              105.83912745054964,
              21.005624421893636
            ],
            [
              105.83912726465076,
              21.00562024720178
            ],
            [
              105.83912725568841,
              21.005620020536544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9041",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005640416316854,
          "to_latitude": 21.005620020536544,
          "to_longitude": 105.83912725568841,
          "from_longitude": 105.83912815942725
        }
      },
      {
        "id": 9034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946156387435,
            21.005613441227528,
            105.83946167946468,
            21.005630041931806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83946167946468,
              21.005630041931806
            ],
            [
              105.8394615928976,
              21.005617489340832
            ],
            [
              105.83946156387435,
              21.005613504456264
            ],
            [
              105.83946156448253,
              21.005613441227528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.8_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9042",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005630041931806,
          "to_latitude": 21.005613441227528,
          "to_longitude": 105.83946156448253,
          "from_longitude": 105.83946167946468
        }
      },
      {
        "id": 9035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83934122256049,
            21.005611848048023,
            105.83934139819092,
            21.005617109369545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83934122256049,
              21.005611848048023
            ],
            [
              105.83934135211462,
              21.005615750243464
            ],
            [
              105.83934139819092,
              21.005617109369545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.6_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9043",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005611848048023,
          "to_latitude": 21.005617109369545,
          "to_longitude": 105.83934139819092,
          "from_longitude": 105.83934122256049
        }
      },
      {
        "id": 9036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384254356291,
            21.00545439021152,
            105.83858293533463,
            21.005492109703518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384254356291,
              21.00545439021152
            ],
            [
              105.83848954352196,
              21.005469743534835
            ],
            [
              105.83850908410433,
              21.00547442351816
            ],
            [
              105.83858293533463,
              21.005492109703518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,6_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9044",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00545439021152,
          "to_latitude": 21.005492109703518,
          "to_longitude": 105.83858293533463,
          "from_longitude": 105.8384254356291
        }
      },
      {
        "id": 9037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870235813193,
            21.00551909489454,
            105.83885985989451,
            21.005556815019034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870235813193,
              21.00551909489454
            ],
            [
              105.83875977019134,
              21.005532845370197
            ],
            [
              105.83878110804463,
              21.005537955431713
            ],
            [
              105.83885985989451,
              21.005556815019034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,2_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9045",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00551909489454,
          "to_latitude": 21.005556815019034,
          "to_longitude": 105.83885985989451,
          "from_longitude": 105.83870235813193
        }
      },
      {
        "id": 9038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819724746216,
            21.0054191231793,
            105.8382130307403,
            21.005471142671954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382130307403,
              21.0054191231793
            ],
            [
              105.83819752795924,
              21.0054702154849
            ],
            [
              105.83819724746216,
              21.005471142671954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,11_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9046",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0054191231793,
          "to_latitude": 21.005471142671954,
          "to_longitude": 105.83819724746216,
          "from_longitude": 105.8382130307403
        }
      },
      {
        "id": 9039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824745319835,
            21.005430152566742,
            105.83826399164663,
            21.005483872942733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826399164663,
              21.005430152566742
            ],
            [
              105.83824816646084,
              21.005481554464104
            ],
            [
              105.83824745319835,
              21.005483872942733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,9_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9047",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005430152566742,
          "to_latitude": 21.005483872942733,
          "to_longitude": 105.83824745319835,
          "from_longitude": 105.83826399164663
        }
      },
      {
        "id": 9040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83851666840982,
            21.005491919640537,
            105.83853260053097,
            21.005547924018007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83853260053097,
              21.005491919640537
            ],
            [
              105.83851691367009,
              21.00554706022983
            ],
            [
              105.83851666840982,
              21.005547924018007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,5_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9048",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005491919640537,
          "to_latitude": 21.005547924018007,
          "to_longitude": 105.83851666840982,
          "from_longitude": 105.83853260053097
        }
      },
      {
        "id": 9041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83846530353956,
            21.005480773631376,
            105.83847947761105,
            21.005535703538012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83847947761105,
              21.005480773631376
            ],
            [
              105.83846586840937,
              21.005533510434674
            ],
            [
              105.83846530353956,
              21.005535703538012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,7_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9049",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005480773631376,
          "to_latitude": 21.005535703538012,
          "to_longitude": 105.83846530353956,
          "from_longitude": 105.83847947761105
        }
      },
      {
        "id": 9042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83847209664475,
            21.005469743534835,
            105.83848954352196,
            21.00553731936046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848954352196,
              21.005469743534835
            ],
            [
              105.83848796934569,
              21.00547584173612
            ],
            [
              105.83847266811615,
              21.0055351027407
            ],
            [
              105.83847209664475,
              21.00553731936046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,6_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9050",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005469743534835,
          "to_latitude": 21.00553731936046,
          "to_longitude": 105.83847209664475,
          "from_longitude": 105.83848954352196
        }
      },
      {
        "id": 9043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83873768807433,
            21.00554293953666,
            105.83875157856207,
            21.005596789614824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83875157856207,
              21.00554293953666
            ],
            [
              105.83873790742912,
              21.005595937695965
            ],
            [
              105.83873768807433,
              21.005596789614824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,3_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9051",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00554293953666,
          "to_latitude": 21.005596789614824,
          "to_longitude": 105.83873768807433,
          "from_longitude": 105.83875157856207
        }
      },
      {
        "id": 9044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878889470938,
            21.005555578066083,
            105.83880313816618,
            21.005611668981974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880313816618,
              21.005555578066083
            ],
            [
              105.83879035548453,
              21.00560645107825
            ],
            [
              105.83878889470938,
              21.005611668981974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,1_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9052",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005555578066083,
          "to_latitude": 21.005611668981974,
          "to_longitude": 105.83878889470938,
          "from_longitude": 105.83880313816618
        }
      },
      {
        "id": 9045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874311702144,
            21.005532845370197,
            105.83875977019134,
            21.005598020149364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83875977019134,
              21.005532845370197
            ],
            [
              105.83875895598979,
              21.005536033250564
            ],
            [
              105.83874332031766,
              21.005597220696668
            ],
            [
              105.83874311702144,
              21.005598020149364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E5,2_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9053",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005532845370197,
          "to_latitude": 21.005598020149364,
          "to_longitude": 105.83874311702144,
          "from_longitude": 105.83875977019134
        }
      },
      {
        "id": 9046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824743996935,
            21.005135537956598,
            105.83827236027737,
            21.0051428090189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827236027737,
              21.0051428090189
            ],
            [
              105.8382570685771,
              21.005138347434876
            ],
            [
              105.83824912310251,
              21.00513603005358
            ],
            [
              105.83824743996935,
              21.005135537956598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,29_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9054",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0051428090189,
          "to_latitude": 21.005135537956598,
          "to_longitude": 105.83824743996935,
          "from_longitude": 105.83827236027737
        }
      },
      {
        "id": 9047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83848211107839,
            21.004601861306597,
            105.83876533466976,
            21.00467113231111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848211107839,
              21.004601861306597
            ],
            [
              105.83855630107287,
              21.00462069204136
            ],
            [
              105.8385614671622,
              21.004617921687814
            ],
            [
              105.8385638377195,
              21.004618531427163
            ],
            [
              105.83858765986797,
              21.004624662566926
            ],
            [
              105.83858949678454,
              21.004625134935303
            ],
            [
              105.83859250602492,
              21.00463004900756
            ],
            [
              105.83860564986558,
              21.00463331082421
            ],
            [
              105.83863660518678,
              21.00464064242246
            ],
            [
              105.83876533466976,
              21.00467113231111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.3_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9055",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004601861306597,
          "to_latitude": 21.00467113231111,
          "to_longitude": 105.83876533466976,
          "from_longitude": 105.83848211107839
        }
      },
      {
        "id": 9048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842893690617,
            21.00426892069897,
            105.838492583051,
            21.004580967015773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83842893690617,
              21.004580967015773
            ],
            [
              105.83847056674912,
              21.00441798091029
            ],
            [
              105.83847411534558,
              21.00440408537551
            ],
            [
              105.838492583051,
              21.00426892069897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,20_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9056",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004580967015773,
          "to_latitude": 21.00426892069897,
          "to_longitude": 105.838492583051,
          "from_longitude": 105.83842893690617
        }
      },
      {
        "id": 9049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386556951239,
            21.00412690314612,
            105.83870612328795,
            21.004246050094856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870612328795,
              21.00412690314612
            ],
            [
              105.83870507988283,
              21.004129695640188
            ],
            [
              105.83869474823378,
              21.004157356334208
            ],
            [
              105.83866161714252,
              21.004246050094856
            ],
            [
              105.8386556951239,
              21.00424470366316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.1_Phương Mai",
          "maTaiSan": "04.O17.DODV.9057",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00412690314612,
          "to_latitude": 21.00424470366316,
          "to_longitude": 105.8386556951239,
          "from_longitude": 105.83870612328795
        }
      },
      {
        "id": 9050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867416947014,
            21.00412750477655,
            105.83870867269901,
            21.00424639740496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870867269901,
              21.00412750477655
            ],
            [
              105.83870771200321,
              21.004130811701362
            ],
            [
              105.83869964252398,
              21.00415862111928
            ],
            [
              105.83867416947014,
              21.00424639740496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.2_Phương Mai",
          "maTaiSan": "04.O17.DODV.9058",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00412750477655,
          "to_latitude": 21.00424639740496,
          "to_longitude": 105.83867416947014,
          "from_longitude": 105.83870867269901
        }
      },
      {
        "id": 9051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83889932179977,
            21.004173015235505,
            105.83893471652168,
            21.004303606352533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893471652168,
              21.004173015235505
            ],
            [
              105.8389334442256,
              21.00417770563159
            ],
            [
              105.83889932179977,
              21.004303606352533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.5_Phương Mai",
          "maTaiSan": "04.O17.DODV.9059",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004173015235505,
          "to_latitude": 21.004303606352533,
          "to_longitude": 105.83889932179977,
          "from_longitude": 105.83893471652168
        }
      },
      {
        "id": 9052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391234225005,
            21.004217254648893,
            105.83916037439158,
            21.004358321861286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83916037439158,
              21.004217254648893
            ],
            [
              105.83915982062378,
              21.00421936640947
            ],
            [
              105.83914938592592,
              21.004259204012506
            ],
            [
              105.8391234225005,
              21.004358321861286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.8_Phương Mai",
          "maTaiSan": "04.O17.DODV.9060",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004217254648893,
          "to_latitude": 21.004358321861286,
          "to_longitude": 105.8391234225005,
          "from_longitude": 105.83916037439158
        }
      },
      {
        "id": 9053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858426306801,
            21.004562103368723,
            105.83860489705306,
            21.004636975808648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83860489705306,
              21.004562103368723
            ],
            [
              105.83858765986797,
              21.004624662566926
            ],
            [
              105.83858504938001,
              21.0046341330982
            ],
            [
              105.83858426306801,
              21.004636975808648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.2_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9061",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004562103368723,
          "to_latitude": 21.004636975808648,
          "to_longitude": 105.83858426306801,
          "from_longitude": 105.83860489705306
        }
      },
      {
        "id": 9054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856025644461,
            21.0045560781159,
            105.83858084946733,
            21.00463156996383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858084946733,
              21.0045560781159
            ],
            [
              105.8385638377195,
              21.004618531427163
            ],
            [
              105.83856081405649,
              21.00462962799239
            ],
            [
              105.83856025644461,
              21.00463156996383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.1_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9062",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0045560781159,
          "to_latitude": 21.00463156996383,
          "to_longitude": 105.83856025644461,
          "from_longitude": 105.83858084946733
        }
      },
      {
        "id": 9055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83880656998669,
            21.004615782505898,
            105.83882603452071,
            21.004686646112628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882603452071,
              21.004615782505898
            ],
            [
              105.83880740173088,
              21.004683672209747
            ],
            [
              105.83880656998669,
              21.004686646112628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.5_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9063",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004615782505898,
          "to_latitude": 21.004686646112628,
          "to_longitude": 105.83880656998669,
          "from_longitude": 105.83882603452071
        }
      },
      {
        "id": 9056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878224081425,
            21.00461298363125,
            105.83881334437962,
            21.004681184986076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881334437962,
              21.00461298363125
            ],
            [
              105.83879585261876,
              21.00467197094443
            ],
            [
              105.83878381504036,
              21.004676475941896
            ],
            [
              105.83878308656945,
              21.00467865269127
            ],
            [
              105.83878224081425,
              21.004681184986076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.4_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9064",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00461298363125,
          "to_latitude": 21.004681184986076,
          "to_longitude": 105.83878224081425,
          "from_longitude": 105.83881334437962
        }
      },
      {
        "id": 9057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905272467646,
            21.004672468870925,
            105.83907103455898,
            21.004748719966024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83907103455898,
              21.004672468870925
            ],
            [
              105.83905661527267,
              21.004732520414585
            ],
            [
              105.83905297752348,
              21.004747665559254
            ],
            [
              105.83905272467646,
              21.004748719966024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.7_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9065",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004672468870925,
          "to_latitude": 21.004748719966024,
          "to_longitude": 105.83905272467646,
          "from_longitude": 105.83907103455898
        }
      },
      {
        "id": 9058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897821054529,
            21.00465337818259,
            105.83899850416974,
            21.004729912726773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899850416974,
              21.00465337818259
            ],
            [
              105.83898184010971,
              21.004715883106616
            ],
            [
              105.8389794216902,
              21.00472495076129
            ],
            [
              105.83897824251575,
              21.00472978160157
            ],
            [
              105.83897821054529,
              21.004729912726773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9066",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00465337818259,
          "to_latitude": 21.004729912726773,
          "to_longitude": 105.83897821054529,
          "from_longitude": 105.83899850416974
        }
      },
      {
        "id": 9059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83831972518894,
            21.004985180635618,
            105.83868113437211,
            21.005060899530676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83831972518894,
              21.004985180635618
            ],
            [
              105.83842074769261,
              21.00500884518828
            ],
            [
              105.83845090033482,
              21.005014872902322
            ],
            [
              105.83868113437211,
              21.005060899530676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.1_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9067",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004985180635618,
          "to_latitude": 21.005060899530676,
          "to_longitude": 105.83868113437211,
          "from_longitude": 105.83831972518894
        }
      },
      {
        "id": 9060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869891833507,
            21.005065888020507,
            105.83906239897516,
            21.00514078117739
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83869891833507,
              21.005065888020507
            ],
            [
              105.83892289624298,
              21.005112036371493
            ],
            [
              105.83895960398411,
              21.005119599681713
            ],
            [
              105.83906239897516,
              21.00514078117739
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.10_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9068",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005065888020507,
          "to_latitude": 21.00514078117739,
          "to_longitude": 105.83906239897516,
          "from_longitude": 105.83869891833507
        }
      },
      {
        "id": 9061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892004173815,
            21.005112036371493,
            105.83892289624298,
            21.005124654945444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892004173815,
              21.005124654945444
            ],
            [
              105.83892009521509,
              21.005124413520775
            ],
            [
              105.83892162433584,
              21.005117657990212
            ],
            [
              105.83892289624298,
              21.005112036371493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.10_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9069",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005124654945444,
          "to_latitude": 21.005112036371493,
          "to_longitude": 105.83892289624298,
          "from_longitude": 105.83892004173815
        }
      },
      {
        "id": 9062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83831972518894,
            21.00478181581394,
            105.8383712706439,
            21.004985180635618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83831972518894,
              21.004985180635618
            ],
            [
              105.8383712706439,
              21.00478181581394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.1_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9070",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004985180635618,
          "to_latitude": 21.00478181581394,
          "to_longitude": 105.8383712706439,
          "from_longitude": 105.83831972518894
        }
      },
      {
        "id": 9063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870487673337,
            21.004128278962046,
            105.83871194735654,
            21.004157372360325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870487673337,
              21.004157372360325
            ],
            [
              105.83870818262172,
              21.004143768855496
            ],
            [
              105.83871088673398,
              21.004132637733257
            ],
            [
              105.83871194735654,
              21.004128278962046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.3_Phương Mai",
          "maTaiSan": "04.O17.DODV.9071",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004157372360325,
          "to_latitude": 21.004128278962046,
          "to_longitude": 105.83871194735654,
          "from_longitude": 105.83870487673337
        }
      },
      {
        "id": 9064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921322468676,
            21.00427779696193,
            105.83927265739975,
            21.004536481897162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927265739975,
              21.00427779696193
            ],
            [
              105.8392695562458,
              21.004334711812454
            ],
            [
              105.83921322468676,
              21.004536481897162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.6_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9072",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00427779696193,
          "to_latitude": 21.004536481897162,
          "to_longitude": 105.83921322468676,
          "from_longitude": 105.83927265739975
        }
      },
      {
        "id": 9065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83860337037868,
            21.00463331082421,
            105.83860564986558,
            21.00464125327453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83860337037868,
              21.00464125327453
            ],
            [
              105.83860369492766,
              21.00464011993448
            ],
            [
              105.83860564986558,
              21.00463331082421
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.3_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9073",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00464125327453,
          "to_latitude": 21.00463331082421,
          "to_longitude": 105.83860564986558,
          "from_longitude": 105.83860337037868
        }
      },
      {
        "id": 9066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841911268817,
            21.00468019042539,
            105.83875158898447,
            21.004755526039258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83841911268817,
              21.00468019042539
            ],
            [
              105.83849960431803,
              21.004698063432546
            ],
            [
              105.83854628961917,
              21.004709113806662
            ],
            [
              105.83854783154594,
              21.004711878207473
            ],
            [
              105.83854834490276,
              21.00471200032333
            ],
            [
              105.83855320205973,
              21.00471315781122
            ],
            [
              105.83855333593708,
              21.004713189667722
            ],
            [
              105.83855592309322,
              21.00471228182515
            ],
            [
              105.83855718373064,
              21.004712560130038
            ],
            [
              105.83859786029262,
              21.004721550031277
            ],
            [
              105.83875158898447,
              21.004755526039258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.3_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9074",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00468019042539,
          "to_latitude": 21.004755526039258,
          "to_longitude": 105.83875158898447,
          "from_longitude": 105.83841911268817
        }
      },
      {
        "id": 9067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876201478927,
            21.004764037544234,
            105.83915079377431,
            21.004831966074487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83876201478927,
              21.004831966074487
            ],
            [
              105.83878211232079,
              21.004764037544234
            ],
            [
              105.83883983460379,
              21.004776380152016
            ],
            [
              105.83891251281369,
              21.004793515599168
            ],
            [
              105.83892564832362,
              21.004797004140947
            ],
            [
              105.83899133552062,
              21.00481445039967
            ],
            [
              105.83899149831895,
              21.00481449385519
            ],
            [
              105.83899872984364,
              21.004816424528276
            ],
            [
              105.83899909686012,
              21.004816522073824
            ],
            [
              105.83900021635695,
              21.004820974873585
            ],
            [
              105.83900255330515,
              21.004821593804575
            ],
            [
              105.83900546244472,
              21.004822363463138
            ],
            [
              105.83900885475764,
              21.00481923242101
            ],
            [
              105.83904583314006,
              21.0048241190284
            ],
            [
              105.83905758532944,
              21.00478869622342
            ],
            [
              105.83915079377431,
              21.00481669279673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9075",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004831966074487,
          "to_latitude": 21.00481669279673,
          "to_longitude": 105.83915079377431,
          "from_longitude": 105.83876201478927
        }
      },
      {
        "id": 9068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892564832362,
            21.004793599325822,
            105.83892649497086,
            21.004797004140947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892564832362,
              21.004797004140947
            ],
            [
              105.83892588722375,
              21.004796035609477
            ],
            [
              105.83892649497086,
              21.004793599325822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9076",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004797004140947,
          "to_latitude": 21.004793599325822,
          "to_longitude": 105.83892649497086,
          "from_longitude": 105.83892564832362
        }
      },
      {
        "id": 9069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855718373064,
            21.004708761467118,
            105.83855822248222,
            21.004712560130038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855718373064,
              21.004712560130038
            ],
            [
              105.83855757583693,
              21.004711122973607
            ],
            [
              105.83855822248222,
              21.004708761467118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.3_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9077",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004712560130038,
          "to_latitude": 21.004708761467118,
          "to_longitude": 105.83855822248222,
          "from_longitude": 105.83855718373064
        }
      },
      {
        "id": 9070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890455504294,
            21.005120876391757,
            105.8389069218918,
            21.0051305679195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890455504294,
              21.0051305679195
            ],
            [
              105.83890574081394,
              21.00512571175699
            ],
            [
              105.83890687014164,
              21.005121082582125
            ],
            [
              105.8389069218918,
              21.005120876391757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.7_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9078",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0051305679195,
          "to_latitude": 21.005120876391757,
          "to_longitude": 105.8389069218918,
          "from_longitude": 105.83890455504294
        }
      },
      {
        "id": 9071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897457144936,
            21.00481449385519,
            105.83899149831895,
            21.004887114386328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899149831895,
              21.00481449385519
            ],
            [
              105.83897457144936,
              21.004887114386328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9079",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00481449385519,
          "to_latitude": 21.004887114386328,
          "to_longitude": 105.83897457144936,
          "from_longitude": 105.83899149831895
        }
      },
      {
        "id": 9072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897362024994,
            21.004811500609787,
            105.83900541518307,
            21.004929629356734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897362024994,
              21.004929629356734
            ],
            [
              105.83900255330515,
              21.004821593804575
            ],
            [
              105.83900367733158,
              21.004817393687034
            ],
            [
              105.83900541518307,
              21.004811500609787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9080",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004929629356734,
          "to_latitude": 21.004811500609787,
          "to_longitude": 105.83900541518307,
          "from_longitude": 105.83897362024994
        }
      },
      {
        "id": 9073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874475485145,
            21.004760193512247,
            105.83877952746361,
            21.004879604121186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83874475485145,
              21.004879604121186
            ],
            [
              105.83877858644225,
              21.00476323479218
            ],
            [
              105.83877952746361,
              21.004760193512247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.5_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9081",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004879604121186,
          "to_latitude": 21.004760193512247,
          "to_longitude": 105.83877952746361,
          "from_longitude": 105.83874475485145
        }
      },
      {
        "id": 9074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853178195436,
            21.004707960739456,
            105.83855465140961,
            21.004790915405742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83853178195436,
              21.004790915405742
            ],
            [
              105.83855320205973,
              21.00471315781122
            ],
            [
              105.83855399300516,
              21.004710283465194
            ],
            [
              105.83855465140961,
              21.004707960739456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.2_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9082",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004790915405742,
          "to_latitude": 21.004707960739456,
          "to_longitude": 105.83855465140961,
          "from_longitude": 105.83853178195436
        }
      },
      {
        "id": 9075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388878353787,
            21.005123061256157,
            105.83891517539381,
            21.00523424751878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388878353787,
              21.00523424751878
            ],
            [
              105.83891172192773,
              21.005137107167982
            ],
            [
              105.83891510671924,
              21.005123337078278
            ],
            [
              105.83891517539381,
              21.005123061256157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.9_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9083",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00523424751878,
          "to_latitude": 21.005123061256157,
          "to_longitude": 105.83891517539381,
          "from_longitude": 105.8388878353787
        }
      },
      {
        "id": 9076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83888402571344,
            21.00417244330969,
            105.8389318219665,
            21.004303166001876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389318219665,
              21.00417244330969
            ],
            [
              105.83893032131407,
              21.004176546831815
            ],
            [
              105.83888402571344,
              21.004303166001876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.4_Phương Mai",
          "maTaiSan": "04.O17.DODV.9084",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00417244330969,
          "to_latitude": 21.004303166001876,
          "to_longitude": 105.83888402571344,
          "from_longitude": 105.8389318219665
        }
      },
      {
        "id": 9077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83887810272401,
            21.004301818679618,
            105.83888402571344,
            21.004303166001876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83887810272401,
              21.004301818679618
            ],
            [
              105.83888402571344,
              21.004303166001876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.4_Phương Mai",
          "maTaiSan": "04.O17.DODV.9085",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004301818679618,
          "to_latitude": 21.004303166001876,
          "to_longitude": 105.83888402571344,
          "from_longitude": 105.83887810272401
        }
      },
      {
        "id": 9078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390599024869,
            21.004737069410197,
            105.83906313907127,
            21.004750333869463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906313907127,
              21.004737069410197
            ],
            [
              105.83906067917671,
              21.004747147979046
            ],
            [
              105.8390599024869,
              21.004750333869463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9086",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004737069410197,
          "to_latitude": 21.004750333869463,
          "to_longitude": 105.8390599024869,
          "from_longitude": 105.83906313907127
        }
      },
      {
        "id": 9079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895701336338,
            21.004810346079356,
            105.83900032718839,
            21.00493231248697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895701336338,
              21.004929240637153
            ],
            [
              105.83896691179739,
              21.00493231248697
            ],
            [
              105.83899872984364,
              21.004816424528276
            ],
            [
              105.8389991615807,
              21.00481485078864
            ],
            [
              105.83900032718839,
              21.004810346079356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.7_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9087",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004929240637153,
          "to_latitude": 21.004810346079356,
          "to_longitude": 105.83900032718839,
          "from_longitude": 105.83895701336338
        }
      },
      {
        "id": 9080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384934682436,
            21.00503842234279,
            105.83849478919909,
            21.005043703684603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83849478919909,
              21.00503842234279
            ],
            [
              105.83849463582513,
              21.00503903277527
            ],
            [
              105.8384934682436,
              21.005043703684603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.4_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9088",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00503842234279,
          "to_latitude": 21.005043703684603,
          "to_longitude": 105.8384934682436,
          "from_longitude": 105.83849478919909
        }
      },
      {
        "id": 9081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910425682205,
            21.004216144885817,
            105.83915353511473,
            21.004358876441344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83910425682205,
              21.00435753570531
            ],
            [
              105.83910986524928,
              21.004358876441344
            ],
            [
              105.83914084723584,
              21.004257613982826
            ],
            [
              105.83915288899037,
              21.004218253488816
            ],
            [
              105.83915353511473,
              21.004216144885817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.7_Phương Mai",
          "maTaiSan": "04.O17.DODV.9089",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00435753570531,
          "to_latitude": 21.004216144885817,
          "to_longitude": 105.83915353511473,
          "from_longitude": 105.83910425682205
        }
      },
      {
        "id": 9082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83844746760914,
            21.005014872902322,
            105.83845090033482,
            21.00502885096202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844746760914,
              21.00502885096202
            ],
            [
              105.83844753246237,
              21.005028579675017
            ],
            [
              105.83844895637601,
              21.00502278840741
            ],
            [
              105.83845090033482,
              21.005014872902322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.1_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9090",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00502885096202,
          "to_latitude": 21.005014872902322,
          "to_longitude": 105.83845090033482,
          "from_longitude": 105.83844746760914
        }
      },
      {
        "id": 9083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843562872333,
            21.00441019019009,
            105.83847056674912,
            21.00441798091029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83847056674912,
              21.00441798091029
            ],
            [
              105.83843858532863,
              21.004410849431416
            ],
            [
              105.83843562872333,
              21.00441019019009
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,20_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9091",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00441798091029,
          "to_latitude": 21.00441019019009,
          "to_longitude": 105.83843562872333,
          "from_longitude": 105.83847056674912
        }
      },
      {
        "id": 9084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955384103426,
            21.004733676118647,
            105.83955600134176,
            21.004869175921215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83955384103426,
              21.004869175921215
            ],
            [
              105.83955393411364,
              21.004864149919076
            ],
            [
              105.83955419217135,
              21.004850444015613
            ],
            [
              105.8395542063,
              21.004849530785616
            ],
            [
              105.83955600134176,
              21.004733676118647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.4_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9092",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004869175921215,
          "to_latitude": 21.004733676118647,
          "to_longitude": 105.83955600134176,
          "from_longitude": 105.83955384103426
        }
      },
      {
        "id": 9085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395318453229,
            21.00473303837016,
            105.83958684042042,
            21.004734488638462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395318453229,
              21.00473303837016
            ],
            [
              105.83955600134176,
              21.004733676118647
            ],
            [
              105.83958684042042,
              21.004734488638462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.4_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9093",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00473303837016,
          "to_latitude": 21.004734488638462,
          "to_longitude": 105.83958684042042,
          "from_longitude": 105.8395318453229
        }
      },
      {
        "id": 9086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971472619137,
            21.004733875121868,
            105.83971651189138,
            21.004870115524884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971472619137,
              21.004870115524884
            ],
            [
              105.83971479872298,
              21.004864512463538
            ],
            [
              105.83971646686376,
              21.00473734553381
            ],
            [
              105.83971651189138,
              21.004733875121868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.7_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9094",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004870115524884,
          "to_latitude": 21.004733875121868,
          "to_longitude": 105.83971651189138,
          "from_longitude": 105.83971472619137
        }
      },
      {
        "id": 9087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83968109173605,
            21.00473294251679,
            105.83973608587124,
            21.004734392741575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83968109173605,
              21.00473294251679
            ],
            [
              105.83970524773954,
              21.004733577534484
            ],
            [
              105.83971651189138,
              21.004733875121868
            ],
            [
              105.83973608587124,
              21.004734392741575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.7_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9095",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00473294251679,
          "to_latitude": 21.004734392741575,
          "to_longitude": 105.83973608587124,
          "from_longitude": 105.83968109173605
        }
      },
      {
        "id": 9088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987351344503,
            21.004734701392675,
            105.83987507432113,
            21.004871042748196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987351344503,
              21.004871042748196
            ],
            [
              105.83987353306716,
              21.00486922084697
            ],
            [
              105.83987503304193,
              21.004738325334262
            ],
            [
              105.83987507432113,
              21.004734701392675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.10_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9096",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004871042748196,
          "to_latitude": 21.004734701392675,
          "to_longitude": 105.83987507432113,
          "from_longitude": 105.83987351344503
        }
      },
      {
        "id": 9089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83984454501065,
            21.00473389733208,
            105.83989954009752,
            21.004735345692833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83984454501065,
              21.00473389733208
            ],
            [
              105.83986870005737,
              21.004734533234497
            ],
            [
              105.83987507432113,
              21.004734701392675
            ],
            [
              105.83989954009752,
              21.004735345692833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.10_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9097",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00473389733208,
          "to_latitude": 21.004735345692833,
          "to_longitude": 105.83989954009752,
          "from_longitude": 105.83984454501065
        }
      },
      {
        "id": 9090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84074824903428,
            21.005160282144402,
            105.84076333469461,
            21.00532890492746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84074824903428,
              21.00532890492746
            ],
            [
              105.8407524811227,
              21.005270504100217
            ],
            [
              105.84076333469461,
              21.00523390511431
            ],
            [
              105.84076100427606,
              21.005160282144402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.4_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9098",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00532890492746,
          "to_latitude": 21.005160282144402,
          "to_longitude": 105.84076100427606,
          "from_longitude": 105.84074824903428
        }
      },
      {
        "id": 9091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84018714603502,
            21.00532890492746,
            105.84074824903428,
            21.005349300402525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018714603502,
              21.005349300402525
            ],
            [
              105.8401906542268,
              21.00534917379384
            ],
            [
              105.84074824903428,
              21.00532890492746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.4_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9099",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005349300402525,
          "to_latitude": 21.00532890492746,
          "to_longitude": 105.84074824903428,
          "from_longitude": 105.84018714603502
        }
      },
      {
        "id": 9092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84028261298012,
            21.004818363428118,
            105.84028280562771,
            21.004849679084387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84028261298012,
              21.004818363428118
            ],
            [
              105.84028279272542,
              21.004847718247234
            ],
            [
              105.84028280562771,
              21.004849679084387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.1_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9100",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004818363428118,
          "to_latitude": 21.004849679084387,
          "to_longitude": 105.84028280562771,
          "from_longitude": 105.84028261298012
        }
      },
      {
        "id": 9093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84074565786145,
            21.00425510827401,
            105.84074635620959,
            21.004283961046976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84074635620959,
              21.004283961046976
            ],
            [
              105.84074613476155,
              21.004274821506954
            ],
            [
              105.84074569948338,
              21.004256872066247
            ],
            [
              105.84074565786145,
              21.00425510827401
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,5_Phương Mai",
          "maTaiSan": "04.O17.DODV.9101",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004283961046976,
          "to_latitude": 21.00425510827401,
          "to_longitude": 105.84074565786145,
          "from_longitude": 105.84074635620959
        }
      },
      {
        "id": 9094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8404635163069,
            21.005139264768545,
            105.84046353562465,
            21.005142542475422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046353562465,
              21.005142542475422
            ],
            [
              105.84046351644268,
              21.005139460767627
            ],
            [
              105.8404635163069,
              21.005139264768545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.3_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9102",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005142542475422,
          "to_latitude": 21.005139264768545,
          "to_longitude": 105.8404635163069,
          "from_longitude": 105.84046353562465
        }
      },
      {
        "id": 9095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84078137430528,
            21.004894690517247,
            105.84079707150966,
            21.00489494419069
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84079707150966,
              21.004894690517247
            ],
            [
              105.84079288392635,
              21.004894759075075
            ],
            [
              105.84078236590163,
              21.004894928453535
            ],
            [
              105.84078137430528,
              21.00489494419069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5.1_Ngách 2/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9103",
          "diaChiLapD": "Ngách 2/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004894690517247,
          "to_latitude": 21.00489494419069,
          "to_longitude": 105.84078137430528,
          "from_longitude": 105.84079707150966
        }
      },
      {
        "id": 9096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8404023372193,
            21.0046459825602,
            105.84040362099422,
            21.0046594370119
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84040362099422,
              21.0046459825602
            ],
            [
              105.84040250980786,
              21.00465762158125
            ],
            [
              105.8404023372193,
              21.0046594370119
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1.11_Ngách 2/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9104",
          "diaChiLapD": "Ngách 2/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0046459825602,
          "to_latitude": 21.0046594370119,
          "to_longitude": 105.8404023372193,
          "from_longitude": 105.84040362099422
        }
      },
      {
        "id": 9097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773914784555,
            21.004122425314403,
            105.83838562572254,
            21.00426890654968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83838562572254,
              21.00426890654968
            ],
            [
              105.83818590540358,
              21.00422412198778
            ],
            [
              105.83818499844071,
              21.004223962970453
            ],
            [
              105.83812869552061,
              21.004214095205516
            ],
            [
              105.83773914784555,
              21.004122425314403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.5_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9105",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 69.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00426890654968,
          "to_latitude": 21.004122425314403,
          "to_longitude": 105.83773914784555,
          "from_longitude": 105.83838562572254
        }
      },
      {
        "id": 9098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823959728322,
            21.004159731366578,
            105.83825902246652,
            21.00422838001632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825902246652,
              21.004159731366578
            ],
            [
              105.838245043569,
              21.004209130756422
            ],
            [
              105.83824086310246,
              21.004223903721062
            ],
            [
              105.83823959728322,
              21.00422838001632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9106",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004159731366578,
          "to_latitude": 21.00422838001632,
          "to_longitude": 105.83823959728322,
          "from_longitude": 105.83825902246652
        }
      },
      {
        "id": 9099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810187707634,
            21.00417778935516,
            105.83844518946505,
            21.00425778166567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810187707634,
              21.00417778935516
            ],
            [
              105.83824273526942,
              21.004210883542722
            ],
            [
              105.83824418086577,
              21.004208974231112
            ],
            [
              105.838245043569,
              21.004209130756422
            ],
            [
              105.83824753154678,
              21.004209582760954
            ],
            [
              105.83824912119304,
              21.004209871189843
            ],
            [
              105.83824999251571,
              21.004212948702747
            ],
            [
              105.83825069555412,
              21.004213106014387
            ],
            [
              105.83830092996604,
              21.00422432956243
            ],
            [
              105.83844518946505,
              21.00425778166567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9107",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00417778935516,
          "to_latitude": 21.00425778166567,
          "to_longitude": 105.83844518946505,
          "from_longitude": 105.83810187707634
        }
      },
      {
        "id": 9100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83811534706346,
            21.004214095205516,
            105.83812869552061,
            21.00426082245099
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812869552061,
              21.004214095205516
            ],
            [
              105.83811534706346,
              21.00426082245099
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.5_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9108",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004214095205516,
          "to_latitude": 21.00426082245099,
          "to_longitude": 105.83811534706346,
          "from_longitude": 105.83812869552061
        }
      },
      {
        "id": 9101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83844518946505,
            21.004129024190423,
            105.83847838157519,
            21.00425778166567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844518946505,
              21.00425778166567
            ],
            [
              105.83847838157519,
              21.004129024190423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9109",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00425778166567,
          "to_latitude": 21.004129024190423,
          "to_longitude": 105.83847838157519,
          "from_longitude": 105.83844518946505
        }
      },
      {
        "id": 9102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83789886732733,
            21.004136636808756,
            105.8379020579597,
            21.004148155992098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379020579597,
              21.004136636808756
            ],
            [
              105.83790153278102,
              21.00413853165097
            ],
            [
              105.83789912753481,
              21.004147212647236
            ],
            [
              105.83789886732733,
              21.004148155992098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.2_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9110",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004136636808756,
          "to_latitude": 21.004148155992098,
          "to_longitude": 105.83789886732733,
          "from_longitude": 105.8379020579597
        }
      },
      {
        "id": 9103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818499844071,
            21.004216041724742,
            105.83818719149521,
            21.004223962970453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83818499844071,
              21.004223962970453
            ],
            [
              105.83818553020265,
              21.00422204190155
            ],
            [
              105.83818706323784,
              21.004216499388274
            ],
            [
              105.83818719149521,
              21.004216041724742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.5_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9111",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004223962970453,
          "to_latitude": 21.004216041724742,
          "to_longitude": 105.83818719149521,
          "from_longitude": 105.83818499844071
        }
      },
      {
        "id": 9104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824220351339,
            21.004160006361065,
            105.83826113913783,
            21.00422899401904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826113913783,
              21.004160006361065
            ],
            [
              105.83824753154678,
              21.004209582760954
            ],
            [
              105.8382434252192,
              21.004224541424897
            ],
            [
              105.83824220351339,
              21.00422899401904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.7_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9112",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004160006361065,
          "to_latitude": 21.00422899401904,
          "to_longitude": 105.83824220351339,
          "from_longitude": 105.83826113913783
        }
      },
      {
        "id": 9105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793056003061,
            21.00408661526579,
            105.83794939856054,
            21.004155617770326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794939856054,
              21.00408661526579
            ],
            [
              105.83793646547547,
              21.00413349580895
            ],
            [
              105.83793472530695,
              21.00414001391998
            ],
            [
              105.83793089920171,
              21.00415434616594
            ],
            [
              105.83793056003061,
              21.004155617770326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.4_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9113",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00408661526579,
          "to_latitude": 21.004155617770326,
          "to_longitude": 105.83793056003061,
          "from_longitude": 105.83794939856054
        }
      },
      {
        "id": 9106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790651264259,
            21.004080728563114,
            105.83792562597931,
            21.00414995641807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792562597931,
              21.004080728563114
            ],
            [
              105.83791269007229,
              21.004127621763732
            ],
            [
              105.8379104772545,
              21.004135623490725
            ],
            [
              105.83790703872256,
              21.004148050732702
            ],
            [
              105.83790651264259,
              21.00414995641807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.3_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9114",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004080728563114,
          "to_latitude": 21.00414995641807,
          "to_longitude": 105.83790651264259,
          "from_longitude": 105.83792562597931
        }
      },
      {
        "id": 9107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824626791616,
            21.004213106014387,
            105.83825069555412,
            21.004229950388456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825069555412,
              21.004213106014387
            ],
            [
              105.83824986296187,
              21.004216274112522
            ],
            [
              105.8382475733016,
              21.004224980737384
            ],
            [
              105.83824626791616,
              21.004229950388456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9115",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004213106014387,
          "to_latitude": 21.004229950388456,
          "to_longitude": 105.83824626791616,
          "from_longitude": 105.83825069555412
        }
      },
      {
        "id": 9108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912906241447,
            21.004212958019863,
            105.83913692228114,
            21.004251234799796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83913692228114,
              21.004212958019863
            ],
            [
              105.83913628458687,
              21.004216058322275
            ],
            [
              105.83913447836561,
              21.004224860122445
            ],
            [
              105.83912906241447,
              21.004251234799796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.6_Phương Mai",
          "maTaiSan": "04.O17.DODV.9116",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004212958019863,
          "to_latitude": 21.004251234799796,
          "to_longitude": 105.83912906241447,
          "from_longitude": 105.83913692228114
        }
      },
      {
        "id": 9109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015812065546,
            21.005091918066512,
            105.84016962655176,
            21.005091923591383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015812065546,
              21.005091918066512
            ],
            [
              105.84016577330365,
              21.005091922741684
            ],
            [
              105.84016962655176,
              21.005091923591383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1.2_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9117",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005091918066512,
          "to_latitude": 21.005091923591383,
          "to_longitude": 105.84016962655176,
          "from_longitude": 105.84015812065546
        }
      },
      {
        "id": 9110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766335625519,
            21.004438711299166,
            105.83799718317898,
            21.004519228990187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766335625519,
              21.004438711299166
            ],
            [
              105.83775859477277,
              21.00446016563094
            ],
            [
              105.83776330178888,
              21.004461309429242
            ],
            [
              105.83778822286128,
              21.004467363015603
            ],
            [
              105.83783597781432,
              21.004478962000316
            ],
            [
              105.83791745344621,
              21.004497212508742
            ],
            [
              105.83791800218255,
              21.004499881547293
            ],
            [
              105.83791971333565,
              21.00450022538095
            ],
            [
              105.83792141775088,
              21.00450056834454
            ],
            [
              105.83792299783117,
              21.004498798373344
            ],
            [
              105.83799718317898,
              21.004519228990187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,21_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9118",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004438711299166,
          "to_latitude": 21.004519228990187,
          "to_longitude": 105.83799718317898,
          "from_longitude": 105.83766335625519
        }
      },
      {
        "id": 9111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617115732802,
            21.003521275863786,
            105.83671504647704,
            21.003657481208297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617115732802,
              21.003521275863786
            ],
            [
              105.83638349058224,
              21.00357445053474
            ],
            [
              105.83646090900868,
              21.003593838084967
            ],
            [
              105.83646358478556,
              21.003594507773723
            ],
            [
              105.83646700522112,
              21.00360004218838
            ],
            [
              105.83647288548136,
              21.003596837549544
            ],
            [
              105.83648483305915,
              21.00359982992946
            ],
            [
              105.83648824059914,
              21.003605293955438
            ],
            [
              105.83648851295928,
              21.003605149004063
            ],
            [
              105.83649413182687,
              21.00360215881034
            ],
            [
              105.83671504647704,
              21.003657481208297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,34_Phương Mai",
          "maTaiSan": "04.O17.DODV.9119",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003521275863786,
          "to_latitude": 21.003657481208297,
          "to_longitude": 105.83671504647704,
          "from_longitude": 105.83617115732802
        }
      },
      {
        "id": 9112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389346117717,
            21.004208307462893,
            105.83927265739975,
            21.00427779696193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389346117717,
              21.004208307462893
            ],
            [
              105.8390907396823,
              21.004244146448645
            ],
            [
              105.83910800950322,
              21.004247339112315
            ],
            [
              105.83912906241447,
              21.004251234799796
            ],
            [
              105.83913552608445,
              21.004252430415537
            ],
            [
              105.83913762163499,
              21.00425701298306
            ],
            [
              105.83914084723584,
              21.004257613982826
            ],
            [
              105.83914938592592,
              21.004259204012506
            ],
            [
              105.83915162651282,
              21.004259621094423
            ],
            [
              105.83915881955862,
              21.004256738665394
            ],
            [
              105.83927265739975,
              21.00427779696193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.6_Phương Mai",
          "maTaiSan": "04.O17.DODV.9120",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004208307462893,
          "to_latitude": 21.00427779696193,
          "to_longitude": 105.83927265739975,
          "from_longitude": 105.8389346117717
        }
      },
      {
        "id": 9113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855372246794,
            21.004123198445253,
            105.83890289354645,
            21.004202140320714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855372246794,
              21.004123198445253
            ],
            [
              105.83869294274356,
              21.004153900459556
            ],
            [
              105.83869357113919,
              21.00415705232824
            ],
            [
              105.83869474823378,
              21.004157356334208
            ],
            [
              105.83869964252398,
              21.00415862111928
            ],
            [
              105.83870054509157,
              21.00415885421997
            ],
            [
              105.83870356501714,
              21.00415707534037
            ],
            [
              105.83870487673337,
              21.004157372360325
            ],
            [
              105.83872249048706,
              21.004161354594043
            ],
            [
              105.83890289354645,
              21.004202140320714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E8.3_Phương Mai",
          "maTaiSan": "04.O17.DODV.9121",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004123198445253,
          "to_latitude": 21.004202140320714,
          "to_longitude": 105.83890289354645,
          "from_longitude": 105.83855372246794
        }
      },
      {
        "id": 9114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83992755707095,
            21.00426933591514,
            105.83994785319938,
            21.004398142130075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83992755707095,
              21.004398092322663
            ],
            [
              105.83993503487748,
              21.004398142130075
            ],
            [
              105.83994488723543,
              21.004299144326357
            ],
            [
              105.83994772841858,
              21.00427058569086
            ],
            [
              105.83994785319938,
              21.00426933591514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.7_Phương Mai",
          "maTaiSan": "04.O17.DODV.9122",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004398092322663,
          "to_latitude": 21.00426933591514,
          "to_longitude": 105.83994785319938,
          "from_longitude": 105.83992755707095
        }
      },
      {
        "id": 9115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83972577509886,
            21.004292657372517,
            105.84008870258629,
            21.00429919294276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83972577509886,
              21.004292657372517
            ],
            [
              105.83990701375939,
              21.00429431019914
            ],
            [
              105.83993492652165,
              21.00429456445446
            ],
            [
              105.8399354950124,
              21.004294569778043
            ],
            [
              105.83994030095674,
              21.004299123604593
            ],
            [
              105.83994488723543,
              21.004299144326357
            ],
            [
              105.83995132322661,
              21.004299173984208
            ],
            [
              105.83995535161495,
              21.00429919294276
            ],
            [
              105.83996015939528,
              21.004294793995154
            ],
            [
              105.84008870258629,
              21.004295966030035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.6_Phương Mai",
          "maTaiSan": "04.O17.DODV.9123",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004292657372517,
          "to_latitude": 21.004295966030035,
          "to_longitude": 105.84008870258629,
          "from_longitude": 105.83972577509886
        }
      },
      {
        "id": 9116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83970507533415,
            21.004266861706967,
            105.83971744820379,
            21.004396655963394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83970507533415,
              21.00439658248581
            ],
            [
              105.83971324966255,
              21.004396655963394
            ],
            [
              105.8397173242464,
              21.004270665798447
            ],
            [
              105.83971744820379,
              21.004266861706967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.4_Phương Mai",
          "maTaiSan": "04.O17.DODV.9124",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00439658248581,
          "to_latitude": 21.004266861706967,
          "to_longitude": 105.83971744820379,
          "from_longitude": 105.83970507533415
        }
      },
      {
        "id": 9117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83945446539997,
            21.004261880226156,
            105.83947377855895,
            21.004401593875155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945446539997,
              21.004401533665423
            ],
            [
              105.83946423346289,
              21.004401593875155
            ],
            [
              105.83947146248298,
              21.004295773422438
            ],
            [
              105.8394736549019,
              21.004263675543964
            ],
            [
              105.83947377855895,
              21.004261880226156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.1_Phương Mai",
          "maTaiSan": "04.O17.DODV.9125",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004401533665423,
          "to_latitude": 21.004261880226156,
          "to_longitude": 105.83947377855895,
          "from_longitude": 105.83945446539997
        }
      },
      {
        "id": 9118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932885331257,
            21.00428760616349,
            105.8396952348861,
            21.004297109201815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932885331257,
              21.00428760616349
            ],
            [
              105.83946504675848,
              21.00429111281235
            ],
            [
              105.8394698553552,
              21.004295799413562
            ],
            [
              105.83947146248298,
              21.004295773422438
            ],
            [
              105.83947690131991,
              21.004295684274556
            ],
            [
              105.83947725525555,
              21.004295678915977
            ],
            [
              105.83948206338883,
              21.004291340495808
            ],
            [
              105.8394824664842,
              21.00429135386247
            ],
            [
              105.83951391855904,
              21.004292406342223
            ],
            [
              105.8396952348861,
              21.004297109201815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2.3_Phương Mai",
          "maTaiSan": "04.O17.DODV.9126",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00428760616349,
          "to_latitude": 21.004297109201815,
          "to_longitude": 105.8396952348861,
          "from_longitude": 105.83932885331257
        }
      },
      {
        "id": 9119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84075354026399,
            21.004254890670023,
            105.84075862543521,
            21.00440037046788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84075458107164,
              21.00440037046788
            ],
            [
              105.84075354026399,
              21.004313476362256
            ],
            [
              105.84075571329254,
              21.004288439981607
            ],
            [
              105.84075842660191,
              21.00425717139135
            ],
            [
              105.84075862543521,
              21.004254890670023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,4_Phương Mai",
          "maTaiSan": "04.O17.DODV.9127",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00440037046788,
          "to_latitude": 21.004254890670023,
          "to_longitude": 105.84075862543521,
          "from_longitude": 105.84075458107164
        }
      },
      {
        "id": 9120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84053493430056,
            21.004259804394955,
            105.84053523746991,
            21.00440204363077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84053523746991,
              21.00440204363077
            ],
            [
              105.84053493968946,
              21.004262655848844
            ],
            [
              105.84053493430056,
              21.004259804394955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,6_Phương Mai",
          "maTaiSan": "04.O17.DODV.9128",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00440204363077,
          "to_latitude": 21.004259804394955,
          "to_longitude": 105.84053493430056,
          "from_longitude": 105.84053523746991
        }
      },
      {
        "id": 9121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84052185054679,
            21.004259871197082,
            105.84053091793639,
            21.004402267805126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84052185054679,
              21.004402267805126
            ],
            [
              105.8405307454774,
              21.004262568174582
            ],
            [
              105.84053091793639,
              21.004259871197082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,7_Phương Mai",
          "maTaiSan": "04.O17.DODV.9129",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004402267805126,
          "to_latitude": 21.004259871197082,
          "to_longitude": 105.84053091793639,
          "from_longitude": 105.84052185054679
        }
      },
      {
        "id": 9122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84029882192145,
            21.00426628212621,
            105.84029955180051,
            21.004402547121067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029882192145,
              21.004402547121067
            ],
            [
              105.84029938349391,
              21.00429772421142
            ],
            [
              105.84029952419102,
              21.004271315030945
            ],
            [
              105.84029955180051,
              21.00426628212621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,8_Phương Mai",
          "maTaiSan": "04.O17.DODV.9130",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004402547121067,
          "to_latitude": 21.00426628212621,
          "to_longitude": 105.84029955180051,
          "from_longitude": 105.84029882192145
        }
      },
      {
        "id": 9123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84028543499724,
            21.004266402010177,
            105.84029521062345,
            21.00440277127682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84028543499724,
              21.00440277127682
            ],
            [
              105.84029295966216,
              21.004297804700144
            ],
            [
              105.84029482334068,
              21.00427178985152
            ],
            [
              105.84029521062345,
              21.004266402010177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,9_Phương Mai",
          "maTaiSan": "04.O17.DODV.9131",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00440277127682,
          "to_latitude": 21.004266402010177,
          "to_longitude": 105.84029521062345,
          "from_longitude": 105.84028543499724
        }
      },
      {
        "id": 9124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84054018687937,
            21.00428283186309,
            105.84086760903176,
            21.004288524605702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84054018687937,
              21.004285882553418
            ],
            [
              105.84070389891953,
              21.0042843568306
            ],
            [
              105.84074635620959,
              21.004283961046976
            ],
            [
              105.84074748537994,
              21.004283950953134
            ],
            [
              105.84075234646632,
              21.004288385258004
            ],
            [
              105.84075571329254,
              21.004288439981607
            ],
            [
              105.84076088955013,
              21.004288524605702
            ],
            [
              105.84076505121828,
              21.0042882972096
            ],
            [
              105.84076573489155,
              21.004288259509877
            ],
            [
              105.84077048712913,
              21.004283737072637
            ],
            [
              105.84086760903176,
              21.00428283186309
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,5_Phương Mai",
          "maTaiSan": "04.O17.DODV.9132",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004285882553418,
          "to_latitude": 21.00428283186309,
          "to_longitude": 105.84086760903176,
          "from_longitude": 105.84054018687937
        }
      },
      {
        "id": 9125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401463147649,
            21.00458213397827,
            105.84064363032962,
            21.00466311265711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401463147649,
              21.00458213397827
            ],
            [
              105.84014646459397,
              21.00462023938829
            ],
            [
              105.84036059861914,
              21.004641675250518
            ],
            [
              105.84040362099422,
              21.0046459825602
            ],
            [
              105.84050389490513,
              21.004656021716222
            ],
            [
              105.84050939007345,
              21.004652077293795
            ],
            [
              105.84050958277929,
              21.004652308470888
            ],
            [
              105.84051346024344,
              21.004656978865228
            ],
            [
              105.8405747307818,
              21.00466311265711
            ],
            [
              105.84064363032962,
              21.004662114819705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1.11_Ngách 2/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9133",
          "diaChiLapD": "Ngách 2/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00458213397827,
          "to_latitude": 21.004662114819705,
          "to_longitude": 105.84064363032962,
          "from_longitude": 105.8401463147649
        }
      },
      {
        "id": 9126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83828651288934,
            21.0063684426382,
            105.83871302588621,
            21.00655751385263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83828651288934,
              21.00655751385263
            ],
            [
              105.83844911874775,
              21.006539341113427
            ],
            [
              105.83845292346635,
              21.006543044558722
            ],
            [
              105.83845442719596,
              21.006542928763437
            ],
            [
              105.83845843168618,
              21.006542619099587
            ],
            [
              105.83846086034322,
              21.00654243190882
            ],
            [
              105.8384634696647,
              21.006537738542356
            ],
            [
              105.83854595207225,
              21.006528522103192
            ],
            [
              105.8385814760746,
              21.006524552619542
            ],
            [
              105.83871302588621,
              21.006509852002882
            ],
            [
              105.83869424654519,
              21.0063684426382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.6_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9134",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00655751385263,
          "to_latitude": 21.0063684426382,
          "to_longitude": 105.83869424654519,
          "from_longitude": 105.83828651288934
        }
      },
      {
        "id": 9127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774346648366,
            21.00656107397493,
            105.83826697106797,
            21.006630913062246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774346648366,
              21.006630913062246
            ],
            [
              105.83796040293925,
              21.006600457736017
            ],
            [
              105.83801836414963,
              21.006586914805247
            ],
            [
              105.83814612020713,
              21.006573722722393
            ],
            [
              105.83814865720423,
              21.006577433321187
            ],
            [
              105.8381505090592,
              21.00657730407218
            ],
            [
              105.83815301762166,
              21.006577129137398
            ],
            [
              105.83815522331184,
              21.00657697556514
            ],
            [
              105.83815724699382,
              21.006572478377432
            ],
            [
              105.83820755862725,
              21.006567249305796
            ],
            [
              105.83823762390051,
              21.00656412419708
            ],
            [
              105.83826697106797,
              21.00656107397493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8.3_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DODV.9135",
          "diaChiLapD": "Ngách 4/35, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006630913062246,
          "to_latitude": 21.00656107397493,
          "to_longitude": 105.83826697106797,
          "from_longitude": 105.83774346648366
        }
      },
      {
        "id": 9128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83812388264099,
            21.005853284975814,
            105.83866761883334,
            21.005914800479083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812388264099,
              21.005914800479083
            ],
            [
              105.8382477395815,
              21.0059009085699
            ],
            [
              105.83825073894448,
              21.00590301818225
            ],
            [
              105.83825356569031,
              21.005902810066264
            ],
            [
              105.83825563959743,
              21.005902657142066
            ],
            [
              105.83825788822817,
              21.00589951639231
            ],
            [
              105.83838385077438,
              21.00588564111063
            ],
            [
              105.83843929112263,
              21.005879422971336
            ],
            [
              105.83844480435336,
              21.00588334200052
            ],
            [
              105.83844883365963,
              21.005878352606786
            ],
            [
              105.83849108959946,
              21.005873510885348
            ],
            [
              105.83849524704377,
              21.005873035177412
            ],
            [
              105.83866761883334,
              21.005853284975814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A12.4_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.9136",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005914800479083,
          "to_latitude": 21.005853284975814,
          "to_longitude": 105.83866761883334,
          "from_longitude": 105.83812388264099
        }
      },
      {
        "id": 9129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900400368375,
            21.005599944324555,
            105.83970045936293,
            21.00561564655575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83900400368375,
              21.005599944324555
            ],
            [
              105.83912865287765,
              21.00561564655575
            ],
            [
              105.83913092413688,
              21.00561346039209
            ],
            [
              105.83913342596847,
              21.005611052704726
            ],
            [
              105.83913381073219,
              21.005611399452462
            ],
            [
              105.83913826357518,
              21.00561541671939
            ],
            [
              105.83916364203033,
              21.005614806558167
            ],
            [
              105.83916843167839,
              21.005610249656783
            ],
            [
              105.83917325176579,
              21.00561457672459
            ],
            [
              105.83929093153766,
              21.00561175283325
            ],
            [
              105.83929445251614,
              21.005607375603855
            ],
            [
              105.83929453180374,
              21.005607277664584
            ],
            [
              105.83929748869762,
              21.005611767465087
            ],
            [
              105.83932794666586,
              21.005611822293965
            ],
            [
              105.83933272831887,
              21.00560738465319
            ],
            [
              105.8393375606752,
              21.00561184081685
            ],
            [
              105.83934122256049,
              21.005611848048023
            ],
            [
              105.83934724970847,
              21.005611858969196
            ],
            [
              105.83945639730511,
              21.005612064909005
            ],
            [
              105.83945759429331,
              21.005610936296648
            ],
            [
              105.83946117281438,
              21.00560756135962
            ],
            [
              105.8394660122711,
              21.00561208251669
            ],
            [
              105.83948921427574,
              21.005611864202912
            ],
            [
              105.8394940186292,
              21.005607358703912
            ],
            [
              105.83949883019841,
              21.005611880900787
            ],
            [
              105.83962023652174,
              21.005612373106153
            ],
            [
              105.83962460346575,
              21.00560825543842
            ],
            [
              105.83962501298775,
              21.0056078695476
            ],
            [
              105.83962985148736,
              21.00561239070458
            ],
            [
              105.83964736884441,
              21.005612346735933
            ],
            [
              105.8396508852757,
              21.005609049778684
            ],
            [
              105.83965217319796,
              21.00560784213582
            ],
            [
              105.8396569838049,
              21.005612363429623
            ],
            [
              105.83970045936293,
              21.005612522871886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E6.6_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9137",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005599944324555,
          "to_latitude": 21.005612522871886,
          "to_longitude": 105.83970045936293,
          "from_longitude": 105.83900400368375
        }
      },
      {
        "id": 9130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388684372785,
            21.005121950212384,
            105.83891097639862,
            21.005237408170174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388684372785,
              21.005235092806046
            ],
            [
              105.838879168329,
              21.005237408170174
            ],
            [
              105.83890721297682,
              21.005135610168423
            ],
            [
              105.8389109537587,
              21.00512202980777
            ],
            [
              105.83891097639862,
              21.005121950212384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.8_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9138",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005235092806046,
          "to_latitude": 21.005121950212384,
          "to_longitude": 105.83891097639862,
          "from_longitude": 105.8388684372785
        }
      },
      {
        "id": 9131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386648041852,
            21.00507878703858,
            105.83869304030371,
            21.005188344655473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386648041852,
              21.005188344655473
            ],
            [
              105.83869254226572,
              21.005080717878105
            ],
            [
              105.83869304030371,
              21.00507878703858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.6_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9139",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005188344655473,
          "to_latitude": 21.00507878703858,
          "to_longitude": 105.83869304030371,
          "from_longitude": 105.8386648041852
        }
      },
      {
        "id": 9132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83864870746842,
            21.005078115688253,
            105.8386899758873,
            21.005187720712698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83864870746842,
              21.00518510281474
            ],
            [
              105.83866073391664,
              21.005187720712698
            ],
            [
              105.83868967721703,
              21.005079232642856
            ],
            [
              105.8386899758873,
              21.005078115688253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.5_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9140",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00518510281474,
          "to_latitude": 21.005078115688253,
          "to_longitude": 105.8386899758873,
          "from_longitude": 105.83864870746842
        }
      },
      {
        "id": 9133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843951362162,
            21.005031906310098,
            105.8384658553947,
            21.005137264501524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83843951362162,
              21.005137264501524
            ],
            [
              105.83846348917537,
              21.00504137111999
            ],
            [
              105.8384656672045,
              21.0050326551075
            ],
            [
              105.8384658553947,
              21.005031906310098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.3_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9141",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005137264501524,
          "to_latitude": 21.005031906310098,
          "to_longitude": 105.8384658553947,
          "from_longitude": 105.83843951362162
        }
      },
      {
        "id": 9134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842200602199,
            21.005030839113594,
            105.83845843727795,
            21.00513720593787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83842200602199,
              21.00513439720198
            ],
            [
              105.83843398351168,
              21.00513720593787
            ],
            [
              105.83845631785091,
              21.005040058836858
            ],
            [
              105.83845824570466,
              21.005031671024383
            ],
            [
              105.83845843727795,
              21.005030839113594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.2_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9142",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00513439720198,
          "to_latitude": 21.005030839113594,
          "to_longitude": 105.83845843727795,
          "from_longitude": 105.83842200602199
        }
      },
      {
        "id": 9135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832646249135,
            21.00500281272595,
            105.83865216048568,
            21.005078712092228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832646249135,
              21.00500281272595
            ],
            [
              105.83845395041487,
              21.005035016052496
            ],
            [
              105.83845474358992,
              21.005039771237634
            ],
            [
              105.83845631785091,
              21.005040058836858
            ],
            [
              105.83846348917537,
              21.00504137111999
            ],
            [
              105.8384645223162,
              21.005041560223695
            ],
            [
              105.83846827858615,
              21.005038148302418
            ],
            [
              105.83848797690474,
              21.00504249241648
            ],
            [
              105.8384934682436,
              21.005043703684603
            ],
            [
              105.83865216048568,
              21.005078712092228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.4_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9143",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00500281272595,
          "to_latitude": 21.005078712092228,
          "to_longitude": 105.83865216048568,
          "from_longitude": 105.83832646249135
        }
      },
      {
        "id": 9136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870060997455,
            21.005085113860105,
            105.8390524385953,
            21.005163105613477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870060997455,
              21.005085113860105
            ],
            [
              105.83874546950182,
              21.005097318461477
            ],
            [
              105.83890455504294,
              21.0051305679195
            ],
            [
              105.83890578478433,
              21.00513082469654
            ],
            [
              105.8389065557636,
              21.00513539211771
            ],
            [
              105.83890721297682,
              21.005135610168423
            ],
            [
              105.83891172192773,
              21.005137107167982
            ],
            [
              105.83891298720938,
              21.005137527253517
            ],
            [
              105.83891791585805,
              21.005133919866626
            ],
            [
              105.8390524385953,
              21.005163105613477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.7_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DODV.9144",
          "diaChiLapD": "Ngách 4/7, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005085113860105,
          "to_latitude": 21.005163105613477,
          "to_longitude": 105.8390524385953,
          "from_longitude": 105.83870060997455
        }
      },
      {
        "id": 9137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883963734557,
            21.00462646061888,
            105.83919007074802,
            21.00475556303711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883963734557,
              21.004689976816472
            ],
            [
              105.83897553317784,
              21.004718640128647
            ],
            [
              105.83898143360003,
              21.004715259175565
            ],
            [
              105.83898184010971,
              21.004715883106616
            ],
            [
              105.83898492436538,
              21.00472062067286
            ],
            [
              105.83905084173027,
              21.004734337262377
            ],
            [
              105.83905584317498,
              21.004731366286496
            ],
            [
              105.83905661527267,
              21.004732520414585
            ],
            [
              105.83905911587559,
              21.00473626098667
            ],
            [
              105.83906313907127,
              21.004737069410197
            ],
            [
              105.83914537909378,
              21.00475556303711
            ],
            [
              105.83916143851647,
              21.004745088642288
            ],
            [
              105.83919007074802,
              21.00462646061888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E10.8_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9145",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004689976816472,
          "to_latitude": 21.00462646061888,
          "to_longitude": 105.83919007074802,
          "from_longitude": 105.83883963734557
        }
      },
      {
        "id": 9138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84029414316194,
            21.005070261937078,
            105.84029450287393,
            21.00513691182047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029414316194,
              21.005070261937078
            ],
            [
              105.84029449871092,
              21.005136340099792
            ],
            [
              105.84029450287393,
              21.00513691182047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.1_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9146",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005070261937078,
          "to_latitude": 21.00513691182047,
          "to_longitude": 105.84029450287393,
          "from_longitude": 105.84029414316194
        }
      },
      {
        "id": 9139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84029688079185,
            21.00507080480737,
            105.8402971857336,
            21.005136949155297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029688079185,
              21.00507080480737
            ],
            [
              105.84029717855753,
              21.005135667514903
            ],
            [
              105.8402971857336,
              21.005136949155297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.2_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9147",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00507080480737,
          "to_latitude": 21.005136949155297,
          "to_longitude": 105.8402971857336,
          "from_longitude": 105.84029688079185
        }
      },
      {
        "id": 9140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046815998904,
            21.005074474306767,
            105.84046850904922,
            21.005139380113324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046815998904,
              21.005074474306767
            ],
            [
              105.84046850749556,
              21.0051392744437
            ],
            [
              105.84046850904922,
              21.005139380113324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.4_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9148",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005074474306767,
          "to_latitude": 21.005139380113324,
          "to_longitude": 105.84046850904922,
          "from_longitude": 105.84046815998904
        }
      },
      {
        "id": 9141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84047305553338,
            21.00507440948652,
            105.84047326032452,
            21.00513931690866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84047305553338,
              21.00507440948652
            ],
            [
              105.84047325853821,
              21.005139169691866
            ],
            [
              105.84047326032452,
              21.00513931690866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.5_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9149",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00507440948652,
          "to_latitude": 21.00513931690866,
          "to_longitude": 105.84047326032452,
          "from_longitude": 105.84047305553338
        }
      },
      {
        "id": 9142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8406563048066,
            21.005067366150662,
            105.84065762067416,
            21.00513846239688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8406563048066,
              21.005067366150662
            ],
            [
              105.84065758678781,
              21.005136705792363
            ],
            [
              105.84065762067416,
              21.00513846239688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.6_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9150",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005067366150662,
          "to_latitude": 21.00513846239688,
          "to_longitude": 105.84065762067416,
          "from_longitude": 105.8406563048066
        }
      },
      {
        "id": 9143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84065911791322,
            21.00506747419124,
            105.84065982718944,
            21.00513846053046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84065911791322,
              21.00506747419124
            ],
            [
              105.8406598082878,
              21.005136631593967
            ],
            [
              105.84065982718944,
              21.00513846053046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T2.7_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9151",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00506747419124,
          "to_latitude": 21.00513846053046,
          "to_longitude": 105.84065982718944,
          "from_longitude": 105.84065911791322
        }
      },
      {
        "id": 9144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83940006045451,
            21.004773503380914,
            105.83940189945335,
            21.004868277900815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83940006045451,
              21.004868277900815
            ],
            [
              105.8394001407926,
              21.00486406847673
            ],
            [
              105.8394004433536,
              21.004848514355544
            ],
            [
              105.83940189945335,
              21.004773503380914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.2_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9152",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004868277900815,
          "to_latitude": 21.004773503380914,
          "to_longitude": 105.83940189945335,
          "from_longitude": 105.83940006045451
        }
      },
      {
        "id": 9145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83940287785116,
            21.00477297254802,
            105.8394157013332,
            21.00486829381221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83940287785116,
              21.00486829381221
            ],
            [
              105.83940301051145,
              21.004864668516557
            ],
            [
              105.83940359292416,
              21.00484893327344
            ],
            [
              105.83940360011272,
              21.00484849878683
            ],
            [
              105.83940477631157,
              21.00477297254802
            ],
            [
              105.8394157013332,
              21.004773170666624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.3_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9153",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00486829381221,
          "to_latitude": 21.004773170666624,
          "to_longitude": 105.8394157013332,
          "from_longitude": 105.83940287785116
        }
      },
      {
        "id": 9146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84087609191106,
            21.004251280961586,
            105.84088252398664,
            21.004496452099133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84088252398664,
              21.004496452099133
            ],
            [
              105.84088086952504,
              21.00428356389819
            ],
            [
              105.84087641684492,
              21.00425348141802
            ],
            [
              105.84087609191106,
              21.004251280961586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E1,2_Ngách 2/2A, Phương Mai",
          "maTaiSan": "04.O17.DODV.9154",
          "diaChiLapD": "Ngách 2/2A, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004496452099133,
          "to_latitude": 21.004251280961586,
          "to_longitude": 105.84087609191106,
          "from_longitude": 105.84088252398664
        }
      },
      {
        "id": 9147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955699990938,
            21.00477546979892,
            105.83955826756124,
            21.00486919466174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83955699990938,
              21.00486919466174
            ],
            [
              105.83955702783514,
              21.004865245624984
            ],
            [
              105.8395571527409,
              21.00484802683373
            ],
            [
              105.83955716519256,
              21.00484629799996
            ],
            [
              105.83955826756124,
              21.00477546979892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.5_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9155",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00486919466174,
          "to_latitude": 21.00477546979892,
          "to_longitude": 105.83955826756124,
          "from_longitude": 105.83955699990938
        }
      },
      {
        "id": 9148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83956216870135,
            21.004772064094098,
            105.83957424641258,
            21.004869226129458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956233940754,
              21.004869226129458
            ],
            [
              105.8395623058617,
              21.00486477700938
            ],
            [
              105.83956218332091,
              21.00484803001888
            ],
            [
              105.83956216870135,
              21.00484610441564
            ],
            [
              105.83956332235303,
              21.004772064094098
            ],
            [
              105.83957424641258,
              21.004772262207254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.6_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9156",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004869226129458,
          "to_latitude": 21.004772262207254,
          "to_longitude": 105.83957424641258,
          "from_longitude": 105.83956233940754
        }
      },
      {
        "id": 9149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971716941187,
            21.004774846362984,
            105.83971924583004,
            21.00487012966426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971716941187,
              21.00487012966426
            ],
            [
              105.83971729019206,
              21.004864554364815
            ],
            [
              105.83971924583004,
              21.004774846362984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.8_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9157",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00487012966426,
          "to_latitude": 21.004774846362984,
          "to_longitude": 105.83971924583004,
          "from_longitude": 105.83971716941187
        }
      },
      {
        "id": 9150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971901337055,
            21.00477164910797,
            105.83973196896517,
            21.004870140437596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971901337055,
              21.004870140437596
            ],
            [
              105.83971912735717,
              21.004864554332997
            ],
            [
              105.83972104394401,
              21.00477164910797
            ],
            [
              105.83973196896517,
              21.004771847206257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.9_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9158",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004870140437596,
          "to_latitude": 21.004771847206257,
          "to_longitude": 105.83973196896517,
          "from_longitude": 105.83971901337055
        }
      },
      {
        "id": 9151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987547860059,
            21.004779701134794,
            105.83987668526485,
            21.004871053825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987547860059,
              21.004871053825
            ],
            [
              105.8398755039295,
              21.004869048540993
            ],
            [
              105.83987668526485,
              21.004779701134794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.11_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9159",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004871053825,
          "to_latitude": 21.004779701134794,
          "to_longitude": 105.83987668526485,
          "from_longitude": 105.83987547860059
        }
      },
      {
        "id": 9152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987775830315,
            21.004776593061333,
            105.83989055448347,
            21.004871067865743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987775830315,
              21.004871067865743
            ],
            [
              105.83987780156478,
              21.00486882946124
            ],
            [
              105.83987962945703,
              21.004776593061333
            ],
            [
              105.83989055448347,
              21.0047767920526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.12_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9160",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004871067865743,
          "to_latitude": 21.0047767920526,
          "to_longitude": 105.83989055448347,
          "from_longitude": 105.83987775830315
        }
      },
      {
        "id": 9153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905835210071,
            21.006654312376025,
            105.83905987868013,
            21.006665508449345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905835210071,
              21.006654312376025
            ],
            [
              105.8390592488465,
              21.006660898785807
            ],
            [
              105.83905987868013,
              21.006665508449345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.3_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9161",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006654312376025,
          "to_latitude": 21.006665508449345,
          "to_longitude": 105.83905987868013,
          "from_longitude": 105.83905835210071
        }
      },
      {
        "id": 9154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83882868203258,
            21.00649712007237,
            105.83901378442323,
            21.006532515919442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83901378442323,
              21.006511860521567
            ],
            [
              105.83883375756653,
              21.006532515919442
            ],
            [
              105.83882868203258,
              21.00649712007237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,7_Ngách 4/26, Phương Mai",
          "maTaiSan": "04.O17.DODV.9162",
          "diaChiLapD": "Ngách 4/26, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006511860521567,
          "to_latitude": 21.00649712007237,
          "to_longitude": 105.83882868203258,
          "from_longitude": 105.83901378442323
        }
      },
      {
        "id": 9155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83852140669626,
            21.004706854228875,
            105.83854971374089,
            21.004789188627328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83852140669626,
              21.004787370782836
            ],
            [
              105.83852826053227,
              21.004789188627328
            ],
            [
              105.83854834490276,
              21.00471200032333
            ],
            [
              105.83854891176807,
              21.004709820758144
            ],
            [
              105.83854971374089,
              21.004706854228875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.1_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9163",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004787370782836,
          "to_latitude": 21.004706854228875,
          "to_longitude": 105.83854971374089,
          "from_longitude": 105.83852140669626
        }
      },
      {
        "id": 9156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872602462182,
            21.00475816087059,
            105.83877080832919,
            21.004880498560077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872602462182,
              21.004878263908132
            ],
            [
              105.83873444094174,
              21.004880498560077
            ],
            [
              105.83876964388463,
              21.004762014347573
            ],
            [
              105.83877080832919,
              21.00475816087059
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.4_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9164",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004878263908132,
          "to_latitude": 21.00475816087059,
          "to_longitude": 105.83877080832919,
          "from_longitude": 105.83872602462182
        }
      },
      {
        "id": 9157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378685538279,
            21.004468618720235,
            105.83787276525264,
            21.00448336744186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83787276525264,
              21.004468618720235
            ],
            [
              105.8378686547579,
              21.0044830110743
            ],
            [
              105.8378685538279,
              21.00448336744186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.4_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9165",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004468618720235,
          "to_latitude": 21.00448336744186,
          "to_longitude": 105.8378685538279,
          "from_longitude": 105.83787276525264
        }
      },
      {
        "id": 9158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382213386117,
            21.004555121297095,
            105.83822592462639,
            21.004571510321536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822592462639,
              21.004555121297095
            ],
            [
              105.83822452515916,
              21.004560123927373
            ],
            [
              105.83822143007384,
              21.00457118109717
            ],
            [
              105.8382213386117,
              21.004571510321536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.9_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9166",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004555121297095,
          "to_latitude": 21.004571510321536,
          "to_longitude": 105.8382213386117,
          "from_longitude": 105.83822592462639
        }
      },
      {
        "id": 9159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769404677628,
            21.005450803414757,
            105.83812048815857,
            21.00581546103169
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769404677628,
              21.00581546103169
            ],
            [
              105.83802890566461,
              21.00575605037876
            ],
            [
              105.8380486972723,
              21.005735229329666
            ],
            [
              105.83808819742461,
              21.005598797781804
            ],
            [
              105.83811911979348,
              21.00545707130908
            ],
            [
              105.83812048815857,
              21.005450803414757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,15_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DODV.9167",
          "diaChiLapD": "Ngách 4/9, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00581546103169,
          "to_latitude": 21.005450803414757,
          "to_longitude": 105.83812048815857,
          "from_longitude": 105.83769404677628
        }
      },
      {
        "id": 9160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380235435736,
            21.004384257345578,
            105.83842359809871,
            21.00458761855952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380235435736,
              21.004506569662254
            ],
            [
              105.83819713662945,
              21.004548343465196
            ],
            [
              105.83820297418258,
              21.00454507033213
            ],
            [
              105.83820453543625,
              21.004545441094628
            ],
            [
              105.83822074894755,
              21.004549286662268
            ],
            [
              105.83822242288775,
              21.0045496839664
            ],
            [
              105.83822592462639,
              21.004555121297095
            ],
            [
              105.8382259391801,
              21.004555143806055
            ],
            [
              105.83826409373317,
              21.00456380027445
            ],
            [
              105.83836637107376,
              21.00458761855952
            ],
            [
              105.83842359809871,
              21.004384257345578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.9_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9168",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004506569662254,
          "to_latitude": 21.004384257345578,
          "to_longitude": 105.83842359809871,
          "from_longitude": 105.8380235435736
        }
      },
      {
        "id": 9161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84027355189446,
            21.004750105405964,
            105.84029809453754,
            21.00484968669834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029809453754,
              21.00484968669834
            ],
            [
              105.84029808764787,
              21.00484794079898
            ],
            [
              105.8402977708484,
              21.00475012944867
            ],
            [
              105.84027355189446,
              21.004750105405964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.2_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9169",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00484968669834,
          "to_latitude": 21.004750105405964,
          "to_longitude": 105.84027355189446,
          "from_longitude": 105.84029809453754
        }
      },
      {
        "id": 9162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8403093808168,
            21.00481631282289,
            105.840317000738,
            21.0048163158468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.840317000738,
              21.0048163158468
            ],
            [
              105.8403093808168,
              21.00481631282289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.3_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9170",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0048163158468,
          "to_latitude": 21.00481631282289,
          "to_longitude": 105.8403093808168,
          "from_longitude": 105.840317000738
        }
      },
      {
        "id": 9163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84065825597307,
            21.004773818439762,
            105.84065864738983,
            21.004852110729157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84065825597307,
              21.004773818439762
            ],
            [
              105.84065848545659,
              21.00481822163682
            ],
            [
              105.84065864183482,
              21.004851119015925
            ],
            [
              105.84065864738983,
              21.004852110729157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.10_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9171",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004773818439762,
          "to_latitude": 21.004852110729157,
          "to_longitude": 105.84065864738983,
          "from_longitude": 105.84065825597307
        }
      },
      {
        "id": 9164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84063381342123,
            21.00482064319164,
            105.84063416188218,
            21.004852021130827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84063416188218,
              21.00482064319164
            ],
            [
              105.84063383113009,
              21.00485037446482
            ],
            [
              105.84063381342123,
              21.004852021130827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.8_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9172",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00482064319164,
          "to_latitude": 21.004852021130827,
          "to_longitude": 105.84063381342123,
          "from_longitude": 105.84063416188218
        }
      },
      {
        "id": 9165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84062886761023,
            21.004750722719457,
            105.84064794164752,
            21.00485208051053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84064794164752,
              21.00485208051053
            ],
            [
              105.84064793402844,
              21.004850720291834
            ],
            [
              105.84064747692936,
              21.004750740999324
            ],
            [
              105.84062886761023,
              21.004750722719457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.9_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9173",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00485208051053,
          "to_latitude": 21.004750722719457,
          "to_longitude": 105.84062886761023,
          "from_longitude": 105.84064794164752
        }
      },
      {
        "id": 9166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84065848545659,
            21.00481822163682,
            105.84066610537732,
            21.004818224645085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84066610537732,
              21.004818224645085
            ],
            [
              105.84065848545659,
              21.00481822163682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.10_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9174",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004818224645085,
          "to_latitude": 21.00481822163682,
          "to_longitude": 105.84065848545659,
          "from_longitude": 105.84066610537732
        }
      },
      {
        "id": 9167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84048394908139,
            21.004775286260486,
            105.8404842731616,
            21.004849815148173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84048394908139,
              21.004775286260486
            ],
            [
              105.84048417851339,
              21.004819689459516
            ],
            [
              105.84048426601753,
              21.00484785247622
            ],
            [
              105.8404842731616,
              21.004849815148173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.7_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9175",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004775286260486,
          "to_latitude": 21.004849815148173,
          "to_longitude": 105.8404842731616,
          "from_longitude": 105.84048394908139
        }
      },
      {
        "id": 9168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84045780480591,
            21.004819636342095,
            105.8404579704985,
            21.004849739099484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84045780480591,
              21.004819636342095
            ],
            [
              105.84045796475687,
              21.00484888558051
            ],
            [
              105.8404579704985,
              21.004849739099484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.4_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9176",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004819636342095,
          "to_latitude": 21.004849739099484,
          "to_longitude": 105.8404579704985,
          "from_longitude": 105.84045780480591
        }
      },
      {
        "id": 9169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84048417851339,
            21.004819689459516,
            105.84049179747261,
            21.004819692480332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84049179747261,
              21.004819692480332
            ],
            [
              105.84048417851339,
              21.004819689459516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.7_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9177",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004819692480332,
          "to_latitude": 21.004819689459516,
          "to_longitude": 105.84048417851339,
          "from_longitude": 105.84049179747261
        }
      },
      {
        "id": 9170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84047632171958,
            21.004749564860898,
            105.84049468383128,
            21.004849792813147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84047672430889,
              21.004849792813147
            ],
            [
              105.84047671704197,
              21.00484815168977
            ],
            [
              105.84047632171958,
              21.004749564860898
            ],
            [
              105.84049468383128,
              21.00474958166854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.6_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9178",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004849792813147,
          "to_latitude": 21.00474958166854,
          "to_longitude": 105.84049468383128,
          "from_longitude": 105.84047672430889
        }
      },
      {
        "id": 9171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046313098122,
            21.004749616450034,
            105.84047321235144,
            21.004849782468703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046313098122,
              21.004749616450034
            ],
            [
              105.84047321235144,
              21.004749626279896
            ],
            [
              105.84047314927382,
              21.004848286727253
            ],
            [
              105.84047314899348,
              21.004849782468703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T1.5_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DODV.9179",
          "diaChiLapD": "Ngõ 2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004749616450034,
          "to_latitude": 21.004849782468703,
          "to_longitude": 105.84047314899348,
          "from_longitude": 105.84046313098122
        }
      },
      {
        "id": 9172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915246542688,
            21.0049140467807,
            105.83925747186122,
            21.004940601382458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925747186122,
              21.004940601382458
            ],
            [
              105.83916980469428,
              21.00491846386615
            ],
            [
              105.83915246542688,
              21.0049140467807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9180",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004940601382458,
          "to_latitude": 21.0049140467807,
          "to_longitude": 105.83915246542688,
          "from_longitude": 105.83925747186122
        }
      },
      {
        "id": 9173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905077879477,
            21.005290659085908,
            105.83913269960496,
            21.005310497378158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83913269960496,
              21.005310497378158
            ],
            [
              105.83905936202112,
              21.005293322833086
            ],
            [
              105.83905077879477,
              21.005290659085908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,2_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9181",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005310497378158,
          "to_latitude": 21.005290659085908,
          "to_longitude": 105.83905077879477,
          "from_longitude": 105.83913269960496
        }
      },
      {
        "id": 9174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377762513065,
            21.004106677408327,
            105.83807421352374,
            21.004177548022927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377762513065,
              21.004106677408327
            ],
            [
              105.83788642790239,
              21.004132883175465
            ],
            [
              105.8379020579597,
              21.004136636808756
            ],
            [
              105.83790660597113,
              21.0041377289991
            ],
            [
              105.8379104772545,
              21.004135623490725
            ],
            [
              105.83791245364809,
              21.004134547955246
            ],
            [
              105.83791593591701,
              21.004139947453584
            ],
            [
              105.83792867512913,
              21.004142931316526
            ],
            [
              105.83793453317415,
              21.004139712285426
            ],
            [
              105.83793472530695,
              21.00414001391998
            ],
            [
              105.83793800508569,
              21.0041451515762
            ],
            [
              105.83807421352374,
              21.004177548022927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.2_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9182",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004106677408327,
          "to_latitude": 21.004177548022927,
          "to_longitude": 105.83807421352374,
          "from_longitude": 105.8377762513065
        }
      },
      {
        "id": 9175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767918534858,
            21.004423663339757,
            105.83797313107318,
            21.00448968511024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767918534858,
              21.004423663339757
            ],
            [
              105.8377422888726,
              21.00443786938907
            ],
            [
              105.83774812622644,
              21.004434731756167
            ],
            [
              105.83775178087403,
              21.004435687444445
            ],
            [
              105.83775539024975,
              21.00443663161355
            ],
            [
              105.8377593046726,
              21.004442769632416
            ],
            [
              105.83787276525264,
              21.004468618720235
            ],
            [
              105.83795981714381,
              21.00448968511024
            ],
            [
              105.83797313107318,
              21.004431077022506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E7.4_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9183",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004423663339757,
          "to_latitude": 21.004431077022506,
          "to_longitude": 105.83797313107318,
          "from_longitude": 105.83767918534858
        }
      },
      {
        "id": 9176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912211348219,
            21.00481669279673,
            105.83915079377431,
            21.00492329551339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915079377431,
              21.00481669279673
            ],
            [
              105.83912211348219,
              21.00492329551339
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E3.6_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DODV.9184",
          "diaChiLapD": "Ngõ 6, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00481669279673,
          "to_latitude": 21.00492329551339,
          "to_longitude": 105.83912211348219,
          "from_longitude": 105.83915079377431
        }
      },
      {
        "id": 9177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395542063,
            21.004848026219484,
            105.83961191333091,
            21.004850190893865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395542063,
              21.004849530785616
            ],
            [
              105.83955578963521,
              21.00484954916891
            ],
            [
              105.83955654483778,
              21.004848026219484
            ],
            [
              105.8395571527409,
              21.00484802683373
            ],
            [
              105.83956218332091,
              21.00484803001888
            ],
            [
              105.8395629201156,
              21.004848030900533
            ],
            [
              105.83956373071292,
              21.004849532577815
            ],
            [
              105.83961191333091,
              21.004850190893865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.4_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9185",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004849530785616,
          "to_latitude": 21.004850190893865,
          "to_longitude": 105.83961191333091,
          "from_longitude": 105.8395542063
        }
      },
      {
        "id": 9178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83939447804453,
            21.004848493620965,
            105.83945079604369,
            21.004851082270804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83939447804453,
              21.00485043964452
            ],
            [
              105.83939875824603,
              21.004850592857647
            ],
            [
              105.83939971139331,
              21.00484851796547
            ],
            [
              105.8394004433536,
              21.004848514355544
            ],
            [
              105.83940360011272,
              21.00484849878683
            ],
            [
              105.83940464755655,
              21.004848493620965
            ],
            [
              105.83940578039604,
              21.004850686483255
            ],
            [
              105.83945079604369,
              21.004851082270804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E2B.1_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9186",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00485043964452,
          "to_latitude": 21.004851082270804,
          "to_longitude": 105.83945079604369,
          "from_longitude": 105.83939447804453
        }
      },
      {
        "id": 9179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903792051312,
            21.00661645745471,
            105.83948892518437,
            21.0066558013629
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903792051312,
              21.0066558013629
            ],
            [
              105.83904655754483,
              21.006655171685406
            ],
            [
              105.83905119286459,
              21.006654832703973
            ],
            [
              105.83905835210071,
              21.006654312376025
            ],
            [
              105.83930257395991,
              21.00663642919238
            ],
            [
              105.83934596246691,
              21.006631805668018
            ],
            [
              105.83934798534453,
              21.006629406662107
            ],
            [
              105.83935062962986,
              21.006629077492132
            ],
            [
              105.83935276638594,
              21.00662880953478
            ],
            [
              105.83935468325113,
              21.006630844981657
            ],
            [
              105.8393587139858,
              21.006630412328626
            ],
            [
              105.83936069898321,
              21.006627431832683
            ],
            [
              105.83936209579564,
              21.006627289459946
            ],
            [
              105.83936558251627,
              21.006626929941344
            ],
            [
              105.83936742927668,
              21.00662950134605
            ],
            [
              105.83948892518437,
              21.00661645745471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3.3_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9187",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0066558013629,
          "to_latitude": 21.00661645745471,
          "to_longitude": 105.83948892518437,
          "from_longitude": 105.83903792051312
        }
      },
      {
        "id": 9180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244629053166,
            21.006424981377872,
            105.8326258375425,
            21.00694951301942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244629053166,
              21.00694951301942
            ],
            [
              105.83252112906415,
              21.0067220940251
            ],
            [
              105.83260439436006,
              21.00648582641742
            ],
            [
              105.8326258375425,
              21.006424981377872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,1_Ngõ 110, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9188",
          "diaChiLapD": "Ngõ 110, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00694951301942,
          "to_latitude": 21.006424981377872,
          "to_longitude": 105.8326258375425,
          "from_longitude": 105.83244629053166
        }
      },
      {
        "id": 9181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326450100896,
            21.005930484138016,
            105.83278940400484,
            21.006380672865212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326450100896,
              21.006380672865212
            ],
            [
              105.83270155031663,
              21.006204395448545
            ],
            [
              105.83270626466745,
              21.006189695864993
            ],
            [
              105.83278940400484,
              21.005930484138016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,2_Ngõ 110, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9189",
          "diaChiLapD": "Ngõ 110, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006380672865212,
          "to_latitude": 21.005930484138016,
          "to_longitude": 105.83278940400484,
          "from_longitude": 105.8326450100896
        }
      },
      {
        "id": 9182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280795339168,
            21.00588317087131,
            105.83290541667932,
            21.006362001658452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83290541667932,
              21.00588317087131
            ],
            [
              105.83290440818041,
              21.005891407807447
            ],
            [
              105.83288462988077,
              21.006053065514177
            ],
            [
              105.83284495182782,
              21.006215341760694
            ],
            [
              105.83280795339168,
              21.006362001658452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9190",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00588317087131,
          "to_latitude": 21.006362001658452,
          "to_longitude": 105.83280795339168,
          "from_longitude": 105.83290541667932
        }
      },
      {
        "id": 9183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832786423572,
            21.006362001658452,
            105.83280795339168,
            21.006465111789776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83280795339168,
              21.006362001658452
            ],
            [
              105.832786423572,
              21.006465111789776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9191",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006362001658452,
          "to_latitude": 21.006465111789776,
          "to_longitude": 105.832786423572,
          "from_longitude": 105.83280795339168
        }
      },
      {
        "id": 9184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832786423572,
            21.006465111789776,
            105.83297907597074,
            21.006506828329393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832786423572,
              21.006465111789776
            ],
            [
              105.8327919957951,
              21.006466336394126
            ],
            [
              105.83297907597074,
              21.006506828329393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9192",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006465111789776,
          "to_latitude": 21.006506828329393,
          "to_longitude": 105.83297907597074,
          "from_longitude": 105.832786423572
        }
      },
      {
        "id": 9185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83297907597074,
            21.006506828329393,
            105.83304326448936,
            21.006528915053213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83297907597074,
              21.006506828329393
            ],
            [
              105.83304326448936,
              21.006528915053213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9193",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006506828329393,
          "to_latitude": 21.006528915053213,
          "to_longitude": 105.83304326448936,
          "from_longitude": 105.83297907597074
        }
      },
      {
        "id": 9186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83304326448936,
            21.006528915053213,
            105.83306444316928,
            21.006602734996846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83304326448936,
              21.006528915053213
            ],
            [
              105.83306444316928,
              21.006602734996846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9194",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006528915053213,
          "to_latitude": 21.006602734996846,
          "to_longitude": 105.83306444316928,
          "from_longitude": 105.83304326448936
        }
      },
      {
        "id": 9187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83284495182782,
            21.006215341760694,
            105.83300197657762,
            21.0062420864343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83284495182782,
              21.006215341760694
            ],
            [
              105.83300197657762,
              21.0062420864343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9195",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006215341760694,
          "to_latitude": 21.0062420864343,
          "to_longitude": 105.83300197657762,
          "from_longitude": 105.83284495182782
        }
      },
      {
        "id": 9188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83297742503798,
            21.006012032582934,
            105.83304450628385,
            21.006374892280423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83297742503798,
              21.006374892280423
            ],
            [
              105.83300197657762,
              21.0062420864343
            ],
            [
              105.83304450628385,
              21.006012032582934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9196",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006374892280423,
          "to_latitude": 21.006012032582934,
          "to_longitude": 105.83304450628385,
          "from_longitude": 105.83297742503798
        }
      },
      {
        "id": 9189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300197657762,
            21.0062420864343,
            105.83346565289925,
            21.006647503997666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300197657762,
              21.0062420864343
            ],
            [
              105.83302899044197,
              21.00624876453481
            ],
            [
              105.83306764223218,
              21.0062599794712
            ],
            [
              105.83312035566264,
              21.006265815499795
            ],
            [
              105.83317018637135,
              21.006302493623895
            ],
            [
              105.83323730427512,
              21.006358803612013
            ],
            [
              105.83340958361882,
              21.00651336365786
            ],
            [
              105.83342490748146,
              21.00652499984138
            ],
            [
              105.8334549632715,
              21.00655409097328
            ],
            [
              105.83346565289925,
              21.006585659652156
            ],
            [
              105.83345720075563,
              21.00661906439601
            ],
            [
              105.83343420050379,
              21.006647503997666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9197",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0062420864343,
          "to_latitude": 21.006647503997666,
          "to_longitude": 105.83343420050379,
          "from_longitude": 105.83300197657762
        }
      },
      {
        "id": 9190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83255454239749,
            21.006473391715982,
            105.83260439436006,
            21.00648582641742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83260439436006,
              21.00648582641742
            ],
            [
              105.8325986680755,
              21.006484399334788
            ],
            [
              105.83258420930663,
              21.006480792133637
            ],
            [
              105.8325770218231,
              21.006478999166298
            ],
            [
              105.83255454239749,
              21.006473391715982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,1_Ngõ 110, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9198",
          "diaChiLapD": "Ngõ 110, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00648582641742,
          "to_latitude": 21.006473391715982,
          "to_longitude": 105.83255454239749,
          "from_longitude": 105.83260439436006
        }
      },
      {
        "id": 9191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83264566666561,
            21.006190774540364,
            105.83270155031663,
            21.006204395448545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83270155031663,
              21.006204395448545
            ],
            [
              105.83269877154922,
              21.006203718979776
            ],
            [
              105.83267084127753,
              21.00619691072908
            ],
            [
              105.83264566666561,
              21.006190774540364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,2_Ngõ 110, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9199",
          "diaChiLapD": "Ngõ 110, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006204395448545,
          "to_latitude": 21.006190774540364,
          "to_longitude": 105.83264566666561,
          "from_longitude": 105.83270155031663
        }
      },
      {
        "id": 9192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439452138018,
            21.006058045651514,
            105.83477341045024,
            21.00644526935471
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477341045024,
              21.006058045651514
            ],
            [
              105.83477310648415,
              21.006058561980986
            ],
            [
              105.83466888891336,
              21.006235423397925
            ],
            [
              105.83461691721313,
              21.00629948754768
            ],
            [
              105.83461434260714,
              21.00630266146299
            ],
            [
              105.8345894391224,
              21.006333358676073
            ],
            [
              105.83457604028935,
              21.006347561667916
            ],
            [
              105.8345696439954,
              21.006354341031958
            ],
            [
              105.83449410097458,
              21.00643441479242
            ],
            [
              105.83439452138018,
              21.00644526935471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,9_Đông Tác",
          "maTaiSan": "04.O17.DODV.9200",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006058045651514,
          "to_latitude": 21.00644526935471,
          "to_longitude": 105.83439452138018,
          "from_longitude": 105.83477341045024
        }
      },
      {
        "id": 9193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83401732698339,
            21.005890005936077,
            105.83408275517361,
            21.006128061894835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83401732698339,
              21.006128061894835
            ],
            [
              105.83405240376062,
              21.00600848085621
            ],
            [
              105.83408240587288,
              21.005891365196124
            ],
            [
              105.83408275517361,
              21.005890005936077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9201",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006128061894835,
          "to_latitude": 21.005890005936077,
          "to_longitude": 105.83408275517361,
          "from_longitude": 105.83401732698339
        }
      },
      {
        "id": 9194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405240376062,
            21.00600848085621,
            105.83422672973077,
            21.00605754863992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405240376062,
              21.00600848085621
            ],
            [
              105.83422672973077,
              21.00605754863992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9202",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00600848085621,
          "to_latitude": 21.00605754863992,
          "to_longitude": 105.83422672973077,
          "from_longitude": 105.83405240376062
        }
      },
      {
        "id": 9195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83392307034593,
            21.006103045580588,
            105.83407709757566,
            21.006237867225877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83392307034593,
              21.006103045580588
            ],
            [
              105.83401732698339,
              21.006128061894835
            ],
            [
              105.83407709757566,
              21.006143224977702
            ],
            [
              105.83405157020742,
              21.006237867225877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9203",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006103045580588,
          "to_latitude": 21.006237867225877,
          "to_longitude": 105.83405157020742,
          "from_longitude": 105.83392307034593
        }
      },
      {
        "id": 9196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388738483464,
            21.006103045580588,
            105.83392307034593,
            21.006199477193682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83392307034593,
              21.006103045580588
            ],
            [
              105.83388738483464,
              21.006199477193682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9204",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006103045580588,
          "to_latitude": 21.006199477193682,
          "to_longitude": 105.83388738483464,
          "from_longitude": 105.83392307034593
        }
      },
      {
        "id": 9197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385343402445,
            21.006190918379637,
            105.83398333752665,
            21.006223665989047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385343402445,
              21.006190918379637
            ],
            [
              105.83388738483464,
              21.006199477193682
            ],
            [
              105.83398333752665,
              21.006223665989047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9205",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006190918379637,
          "to_latitude": 21.006223665989047,
          "to_longitude": 105.83398333752665,
          "from_longitude": 105.83385343402445
        }
      },
      {
        "id": 9198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337982488975,
            21.006190918379637,
            105.83385343402445,
            21.006363799695766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385343402445,
              21.006190918379637
            ],
            [
              105.8337982488975,
              21.006363799695766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9206",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006190918379637,
          "to_latitude": 21.006363799695766,
          "to_longitude": 105.8337982488975,
          "from_longitude": 105.83385343402445
        }
      },
      {
        "id": 9199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337158607305,
            21.006337914930437,
            105.8339761387422,
            21.006439827807917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337158607305,
              21.006337914930437
            ],
            [
              105.8337982488975,
              21.006363799695766
            ],
            [
              105.83382895367744,
              21.006373445648926
            ],
            [
              105.8339761387422,
              21.006439827807917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9207",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006337914930437,
          "to_latitude": 21.006439827807917,
          "to_longitude": 105.8339761387422,
          "from_longitude": 105.8337158607305
        }
      },
      {
        "id": 9200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83307658652483,
            21.005574303272336,
            105.83309680644732,
            21.005869801219532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83309680644732,
              21.005869801219532
            ],
            [
              105.83309650400001,
              21.00586539405466
            ],
            [
              105.83307658652483,
              21.005574303272336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,4_Ngõ 39, Đông Tác",
          "maTaiSan": "04.O17.DODV.9208",
          "diaChiLapD": "Ngõ 39, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005869801219532,
          "to_latitude": 21.005574303272336,
          "to_longitude": 105.83307658652483,
          "from_longitude": 105.83309680644732
        }
      },
      {
        "id": 9201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83321976949934,
            21.00538175627744,
            105.8332667651484,
            21.005859292697895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325740323012,
              21.005859292697895
            ],
            [
              105.83325745626307,
              21.005848402244528
            ],
            [
              105.83325821848018,
              21.00569602883139
            ],
            [
              105.83325115743438,
              21.005590191784254
            ],
            [
              105.8332667651484,
              21.00557882504717
            ],
            [
              105.83325940665507,
              21.00546185900297
            ],
            [
              105.8332481806203,
              21.005456656286274
            ],
            [
              105.83321976949934,
              21.00538175627744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,5_Ngõ 37, Đông Tác",
          "maTaiSan": "04.O17.DODV.9209",
          "diaChiLapD": "Ngõ 37, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005859292697895,
          "to_latitude": 21.00538175627744,
          "to_longitude": 105.83321976949934,
          "from_longitude": 105.83325740323012
        }
      },
      {
        "id": 9202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8331325135228,
            21.005590191784254,
            105.83325115743438,
            21.005599233124304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325115743438,
              21.005590191784254
            ],
            [
              105.8331325135228,
              21.005599233124304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,5_Ngõ 37, Đông Tác",
          "maTaiSan": "04.O17.DODV.9210",
          "diaChiLapD": "Ngõ 37, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005590191784254,
          "to_latitude": 21.005599233124304,
          "to_longitude": 105.8331325135228,
          "from_longitude": 105.83325115743438
        }
      },
      {
        "id": 9203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332667651484,
            21.00555229419636,
            105.83353412123608,
            21.00557882504717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332667651484,
              21.00557882504717
            ],
            [
              105.83353412123608,
              21.00555229419636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,5_Ngõ 37, Đông Tác",
          "maTaiSan": "04.O17.DODV.9211",
          "diaChiLapD": "Ngõ 37, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557882504717,
          "to_latitude": 21.00555229419636,
          "to_longitude": 105.83353412123608,
          "from_longitude": 105.8332667651484
        }
      },
      {
        "id": 9204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325821848018,
            21.005679406451254,
            105.8334910706754,
            21.00569602883139
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325821848018,
              21.00569602883139
            ],
            [
              105.83325827136173,
              21.005696024959583
            ],
            [
              105.8334910706754,
              21.005679406451254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,5_Ngõ 37, Đông Tác",
          "maTaiSan": "04.O17.DODV.9212",
          "diaChiLapD": "Ngõ 37, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00569602883139,
          "to_latitude": 21.005679406451254,
          "to_longitude": 105.8334910706754,
          "from_longitude": 105.83325821848018
        }
      },
      {
        "id": 9205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356965179117,
            21.005364566245007,
            105.83357996452635,
            21.00577299598927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83357996452635,
              21.00577299598927
            ],
            [
              105.83357627666943,
              21.005618521797658
            ],
            [
              105.83356991810675,
              21.005374761456935
            ],
            [
              105.83356965179117,
              21.005364566245007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2,10_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9213",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00577299598927,
          "to_latitude": 21.005364566245007,
          "to_longitude": 105.83356965179117,
          "from_longitude": 105.83357996452635
        }
      },
      {
        "id": 9206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335708811393,
            21.005199176980202,
            105.83358802123034,
            21.0053715991149
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358802123034,
              21.0053715991149
            ],
            [
              105.83358739289706,
              21.00537121109557
            ],
            [
              105.83357069608527,
              21.005360875969046
            ],
            [
              105.83352211941873,
              21.00529813645503
            ],
            [
              105.83349681719793,
              21.005226223591308
            ],
            [
              105.8333710163024,
              21.005245308784566
            ],
            [
              105.83335708811393,
              21.005199176980202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,11_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9214",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0053715991149,
          "to_latitude": 21.005199176980202,
          "to_longitude": 105.83335708811393,
          "from_longitude": 105.83358802123034
        }
      },
      {
        "id": 9207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325940665507,
            21.005395515033054,
            105.8333202660046,
            21.00546185900297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325940665507,
              21.00546185900297
            ],
            [
              105.8333202660046,
              21.005445021159616
            ],
            [
              105.83330833996591,
              21.005395515033054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,5_Ngõ 37, Đông Tác",
          "maTaiSan": "04.O17.DODV.9215",
          "diaChiLapD": "Ngõ 37, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00546185900297,
          "to_latitude": 21.005395515033054,
          "to_longitude": 105.83330833996591,
          "from_longitude": 105.83325940665507
        }
      },
      {
        "id": 9208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327446551266,
            21.005817764581863,
            105.83357291449612,
            21.005838258838033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327446551266,
              21.005838258838033
            ],
            [
              105.83340366326699,
              21.005829387907852
            ],
            [
              105.83343148640635,
              21.005827477725575
            ],
            [
              105.83357291449612,
              21.005817764581863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,6_Đông Tác",
          "maTaiSan": "04.O17.DODV.9216",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005838258838033,
          "to_latitude": 21.005817764581863,
          "to_longitude": 105.83357291449612,
          "from_longitude": 105.83327446551266
        }
      },
      {
        "id": 9209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340366326699,
            21.005829387907852,
            105.83340519872577,
            21.005850401162988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83340366326699,
              21.005829387907852
            ],
            [
              105.83340427547554,
              21.005837784006683
            ],
            [
              105.83340505298713,
              21.005848406650866
            ],
            [
              105.83340519872577,
              21.005850401162988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,6_Đông Tác",
          "maTaiSan": "04.O17.DODV.9217",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005829387907852,
          "to_latitude": 21.005850401162988,
          "to_longitude": 105.83340519872577,
          "from_longitude": 105.83340366326699
        }
      },
      {
        "id": 9210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362337737655,
            21.005806179224304,
            105.83401715497666,
            21.005855150389806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362337737655,
              21.005806179224304
            ],
            [
              105.83375778436914,
              21.005815275671136
            ],
            [
              105.83377511870492,
              21.005817599654364
            ],
            [
              105.83397221789001,
              21.0058440269828
            ],
            [
              105.83401715497666,
              21.005855150389806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,7_Đông Tác",
          "maTaiSan": "04.O17.DODV.9218",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005806179224304,
          "to_latitude": 21.005855150389806,
          "to_longitude": 105.83401715497666,
          "from_longitude": 105.83362337737655
        }
      },
      {
        "id": 9211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337732991077,
            21.005817599654364,
            105.83377511870492,
            21.005849676695036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377511870492,
              21.005817599654364
            ],
            [
              105.83377488293043,
              21.0058217339704
            ],
            [
              105.83377407090887,
              21.005836067624774
            ],
            [
              105.8337732991077,
              21.005849676695036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,7_Đông Tác",
          "maTaiSan": "04.O17.DODV.9219",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005817599654364,
          "to_latitude": 21.005849676695036,
          "to_longitude": 105.8337732991077,
          "from_longitude": 105.83377511870492
        }
      },
      {
        "id": 9212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83291908194671,
            21.005242870315787,
            105.8331007230628,
            21.005303953856792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83291908194671,
              21.00524416830862
            ],
            [
              105.83292241877916,
              21.00524401378506
            ],
            [
              105.8329469622491,
              21.005242870315787
            ],
            [
              105.83295818387825,
              21.005303953856792
            ],
            [
              105.8331007230628,
              21.005293132841555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,4_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9220",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00524416830862,
          "to_latitude": 21.005293132841555,
          "to_longitude": 105.8331007230628,
          "from_longitude": 105.83291908194671
        }
      },
      {
        "id": 9213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329516179924,
            21.005005819042378,
            105.83337864142668,
            21.00506025950213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329516179924,
              21.00506025950213
            ],
            [
              105.83295202457127,
              21.005060206931663
            ],
            [
              105.83316715324291,
              21.005032122208988
            ],
            [
              105.83318797161108,
              21.005033450102886
            ],
            [
              105.83322217527929,
              21.005035631949788
            ],
            [
              105.83323373221144,
              21.005040150210622
            ],
            [
              105.83337864142668,
              21.005005819042378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,5_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9221",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00506025950213,
          "to_latitude": 21.005005819042378,
          "to_longitude": 105.83337864142668,
          "from_longitude": 105.8329516179924
        }
      },
      {
        "id": 9214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313671586046,
            21.004863358923078,
            105.83323605861962,
            21.005033450102886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318797161108,
              21.005033450102886
            ],
            [
              105.83313671586046,
              21.004863358923078
            ],
            [
              105.83323605861962,
              21.00486493376731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,5_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9222",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005033450102886,
          "to_latitude": 21.00486493376731,
          "to_longitude": 105.83323605861962,
          "from_longitude": 105.83318797161108
        }
      },
      {
        "id": 9215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263514630158,
            21.00473043607004,
            105.8328727882713,
            21.004852911752845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328727882713,
              21.004779004060243
            ],
            [
              105.83287259647945,
              21.004779108869762
            ],
            [
              105.83274113007292,
              21.004850995906832
            ],
            [
              105.83272338654639,
              21.004852911752845
            ],
            [
              105.83263764954323,
              21.004845465917967
            ],
            [
              105.83263514630158,
              21.00473043607004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,8_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9223",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004779004060243,
          "to_latitude": 21.00473043607004,
          "to_longitude": 105.83263514630158,
          "from_longitude": 105.8328727882713
        }
      },
      {
        "id": 9216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329150479921,
            21.004895312506427,
            105.83294848143797,
            21.005223413320458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329150479921,
              21.004895312506427
            ],
            [
              105.83294362995227,
              21.005022876137502
            ],
            [
              105.83294493482317,
              21.005028699171874
            ],
            [
              105.83294775836009,
              21.00505287013184
            ],
            [
              105.83294848143797,
              21.005059064529632
            ],
            [
              105.83294621979728,
              21.005091767861497
            ],
            [
              105.83294318360441,
              21.005112815240093
            ],
            [
              105.83291995555979,
              21.005223413320458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,6_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9224",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004895312506427,
          "to_latitude": 21.005223413320458,
          "to_longitude": 105.83291995555979,
          "from_longitude": 105.8329150479921
        }
      },
      {
        "id": 9217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83294775836009,
            21.005052188702464,
            105.83295263798749,
            21.00505287013184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83294775836009,
              21.00505287013184
            ],
            [
              105.83294992377316,
              21.005052567792347
            ],
            [
              105.83295194309639,
              21.00505228603877
            ],
            [
              105.83295263798749,
              21.005052188702464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,6_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9225",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00505287013184,
          "to_latitude": 21.005052188702464,
          "to_longitude": 105.83295263798749,
          "from_longitude": 105.83294775836009
        }
      },
      {
        "id": 9218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328866445458,
            21.004757080515947,
            105.83301351474447,
            21.004799559613303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83301351474447,
              21.004757080515947
            ],
            [
              105.8328866445458,
              21.004799559613303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,7_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9226",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004757080515947,
          "to_latitude": 21.004799559613303,
          "to_longitude": 105.8328866445458,
          "from_longitude": 105.83301351474447
        }
      },
      {
        "id": 9219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328866445458,
            21.004799559613303,
            105.83295401839919,
            21.00501564465025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328866445458,
              21.004799559613303
            ],
            [
              105.83291550715937,
              21.004872325867836
            ],
            [
              105.83292118583718,
              21.00488667653205
            ],
            [
              105.83292341496015,
              21.004892308075853
            ],
            [
              105.83292497287705,
              21.004896238516967
            ],
            [
              105.83295401839919,
              21.00501564465025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,7_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9227",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004799559613303,
          "to_latitude": 21.00501564465025,
          "to_longitude": 105.83295401839919,
          "from_longitude": 105.8328866445458
        }
      },
      {
        "id": 9220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83292341496015,
            21.00485658924868,
            105.83306973228011,
            21.004892308075853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292341496015,
              21.004892308075853
            ],
            [
              105.83292370313178,
              21.00489223802042
            ],
            [
              105.83306973228011,
              21.00485658924868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,7_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9228",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004892308075853,
          "to_latitude": 21.00485658924868,
          "to_longitude": 105.83306973228011,
          "from_longitude": 105.83292341496015
        }
      },
      {
        "id": 9221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83291511190768,
            21.00488667653205,
            105.83292118583718,
            21.00488880445044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292118583718,
              21.00488667653205
            ],
            [
              105.83291789303533,
              21.004887830711052
            ],
            [
              105.83291599318935,
              21.004888495750503
            ],
            [
              105.83291511190768,
              21.00488880445044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,7_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9229",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00488667653205,
          "to_latitude": 21.00488880445044,
          "to_longitude": 105.83291511190768,
          "from_longitude": 105.83292118583718
        }
      },
      {
        "id": 9222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83258179501266,
            21.00443474600688,
            105.83281831073806,
            21.004616716147325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281831073806,
              21.004616716147325
            ],
            [
              105.83281592960171,
              21.00461644960492
            ],
            [
              105.83278255992369,
              21.004612699205214
            ],
            [
              105.8327359689951,
              21.004586340706364
            ],
            [
              105.83260978214574,
              21.004605479601132
            ],
            [
              105.83258179501266,
              21.00443474600688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,10_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9230",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004616716147325,
          "to_latitude": 21.00443474600688,
          "to_longitude": 105.83258179501266,
          "from_longitude": 105.83281831073806
        }
      },
      {
        "id": 9223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328222432426,
            21.004588915247943,
            105.83300335321081,
            21.004639494329815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328222432426,
              21.004639494329815
            ],
            [
              105.83282326028787,
              21.00463921115962
            ],
            [
              105.83300335321081,
              21.004588915247943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,9_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9231",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004639494329815,
          "to_latitude": 21.004588915247943,
          "to_longitude": 105.83300335321081,
          "from_longitude": 105.8328222432426
        }
      },
      {
        "id": 9224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83264794665976,
            21.003924604424935,
            105.83280145697503,
            21.004528149688877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83280145697503,
              21.004528149688877
            ],
            [
              105.83280126451888,
              21.004526921339934
            ],
            [
              105.8327882799186,
              21.00444364872245
            ],
            [
              105.83277793174777,
              21.00439659521315
            ],
            [
              105.83273495722636,
              21.00428818983171
            ],
            [
              105.83268902144594,
              21.004127820068362
            ],
            [
              105.83266103691578,
              21.00405637540225
            ],
            [
              105.83264794665976,
              21.00400882226316
            ],
            [
              105.83266219015275,
              21.003924604424935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3,11_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9232",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 69.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004528149688877,
          "to_latitude": 21.003924604424935,
          "to_longitude": 105.83266219015275,
          "from_longitude": 105.83280145697503
        }
      },
      {
        "id": 9225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83266219015275,
            21.00357877642024,
            105.83271626045162,
            21.003924604424935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83266219015275,
              21.003924604424935
            ],
            [
              105.83267153261144,
              21.003865291731913
            ],
            [
              105.83267833174207,
              21.003806470190415
            ],
            [
              105.83266294370337,
              21.00367276413044
            ],
            [
              105.83271626045162,
              21.00357877642024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,11_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9233",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003924604424935,
          "to_latitude": 21.00357877642024,
          "to_longitude": 105.83271626045162,
          "from_longitude": 105.83266219015275
        }
      },
      {
        "id": 9226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83248488973128,
            21.004053420310147,
            105.83266103691578,
            21.00407752548806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83266103691578,
              21.00405637540225
            ],
            [
              105.83257182700827,
              21.00407752548806
            ],
            [
              105.8325675553556,
              21.004053420310147
            ],
            [
              105.83248488973128,
              21.00407036904696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,11_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9234",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00405637540225,
          "to_latitude": 21.00407036904696,
          "to_longitude": 105.83248488973128,
          "from_longitude": 105.83266103691578
        }
      },
      {
        "id": 9227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8324663564102,
            21.00390265021057,
            105.83249759162219,
            21.004173808506167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8324663564102,
              21.00390265021057
            ],
            [
              105.83248488973128,
              21.00407036904696
            ],
            [
              105.83249759162219,
              21.004173808506167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,11_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9235",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00390265021057,
          "to_latitude": 21.004173808506167,
          "to_longitude": 105.83249759162219,
          "from_longitude": 105.8324663564102
        }
      },
      {
        "id": 9228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8327697364458,
            21.004009106765928,
            105.8330461910424,
            21.004217755179464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330461910424,
              21.004217755179464
            ],
            [
              105.83283344513318,
              21.00416750119889
            ],
            [
              105.83284469349061,
              21.004121279564632
            ],
            [
              105.8327697364458,
              21.00410463587103
            ],
            [
              105.83279121187887,
              21.004009106765928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9236",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004217755179464,
          "to_latitude": 21.004009106765928,
          "to_longitude": 105.83279121187887,
          "from_longitude": 105.8330461910424
        }
      },
      {
        "id": 9229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83305032289614,
            21.00420175598481,
            105.83318684699258,
            21.00422983560063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83305032289614,
              21.00420175598481
            ],
            [
              105.83318684699258,
              21.00422983560063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9237",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00420175598481,
          "to_latitude": 21.00422983560063,
          "to_longitude": 105.83318684699258,
          "from_longitude": 105.83305032289614
        }
      },
      {
        "id": 9230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83292599419255,
            21.004325298210503,
            105.83312711422302,
            21.00449619653069
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292599419255,
              21.00449619653069
            ],
            [
              105.8330563013196,
              21.004463675816066
            ],
            [
              105.83304761153168,
              21.004429310856704
            ],
            [
              105.83304415862908,
              21.004415655624683
            ],
            [
              105.83304853184876,
              21.00436065394982
            ],
            [
              105.83312711422302,
              21.004325298210503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,12_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9238",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00449619653069,
          "to_latitude": 21.004325298210503,
          "to_longitude": 105.83312711422302,
          "from_longitude": 105.83292599419255
        }
      },
      {
        "id": 9231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83304076160965,
            21.004429310856704,
            105.83304761153168,
            21.004430437286643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83304076160965,
              21.004430437286643
            ],
            [
              105.83304079524395,
              21.004430431702662
            ],
            [
              105.83304761153168,
              21.004429310856704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,12_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9239",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004430437286643,
          "to_latitude": 21.004429310856704,
          "to_longitude": 105.83304761153168,
          "from_longitude": 105.83304076160965
        }
      },
      {
        "id": 9232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83289489634566,
            21.004002091676504,
            105.83335595432861,
            21.004103924447524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83289489634566,
              21.004002091676504
            ],
            [
              105.83303525356678,
              21.004037332407115
            ],
            [
              105.83308927780483,
              21.004050896378185
            ],
            [
              105.83318198341618,
              21.004074173051116
            ],
            [
              105.83325092827712,
              21.004021760947833
            ],
            [
              105.8332530204733,
              21.00402022786628
            ],
            [
              105.83329130739178,
              21.004046384818164
            ],
            [
              105.8332954594979,
              21.00404963055542
            ],
            [
              105.83332284522218,
              21.00407103480434
            ],
            [
              105.83333178307427,
              21.00407869736857
            ],
            [
              105.83335595432861,
              21.004103924447524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9240",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004002091676504,
          "to_latitude": 21.004103924447524,
          "to_longitude": 105.83335595432861,
          "from_longitude": 105.83289489634566
        }
      },
      {
        "id": 9233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83303525356678,
            21.003871977386623,
            105.83307669808978,
            21.004037332407115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83303525356678,
              21.004037332407115
            ],
            [
              105.83307669808978,
              21.003871977386623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9241",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004037332407115,
          "to_latitude": 21.003871977386623,
          "to_longitude": 105.83307669808978,
          "from_longitude": 105.83303525356678
        }
      },
      {
        "id": 9234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278151274072,
            21.003794376989905,
            105.83307669808978,
            21.003871977386623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83307669808978,
              21.003871977386623
            ],
            [
              105.83278151274072,
              21.003794376989905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9242",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003871977386623,
          "to_latitude": 21.003794376989905,
          "to_longitude": 105.83278151274072,
          "from_longitude": 105.83307669808978
        }
      },
      {
        "id": 9235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8331382246794,
            21.004309696596444,
            105.83338702822343,
            21.00488132428677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331382246794,
              21.004309696596444
            ],
            [
              105.83313982198624,
              21.004313792133406
            ],
            [
              105.83327154517286,
              21.004651219359264
            ],
            [
              105.83328939581001,
              21.004663750031426
            ],
            [
              105.83338702822343,
              21.00488132428677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3,13_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9243",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004309696596444,
          "to_latitude": 21.00488132428677,
          "to_longitude": 105.83338702822343,
          "from_longitude": 105.8331382246794
        }
      },
      {
        "id": 9236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83316416486201,
            21.004651219359264,
            105.83327154517286,
            21.004753542216985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327154517286,
              21.004651219359264
            ],
            [
              105.83325251198403,
              21.00465818970339
            ],
            [
              105.83323835058675,
              21.00474605620737
            ],
            [
              105.83316416486201,
              21.004753542216985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,13_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9244",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004651219359264,
          "to_latitude": 21.004753542216985,
          "to_longitude": 105.83316416486201,
          "from_longitude": 105.83327154517286
        }
      },
      {
        "id": 9237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83328939581001,
            21.00452995635265,
            105.83339416682986,
            21.004663750031426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83328939581001,
              21.004663750031426
            ],
            [
              105.83339416682986,
              21.004608614461397
            ],
            [
              105.83335224670336,
              21.00452995635265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,13_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9245",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004663750031426,
          "to_latitude": 21.00452995635265,
          "to_longitude": 105.83335224670336,
          "from_longitude": 105.83328939581001
        }
      },
      {
        "id": 9238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83292407946053,
            21.003591011705254,
            105.8332979717898,
            21.004043847380405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292407946053,
              21.003591011705254
            ],
            [
              105.83296037926456,
              21.003638359113843
            ],
            [
              105.83299509013924,
              21.003683634160318
            ],
            [
              105.83305199699905,
              21.003757861864532
            ],
            [
              105.83313512186486,
              21.003859903417364
            ],
            [
              105.83323964219704,
              21.00399567412865
            ],
            [
              105.83328970334482,
              21.004043847380405
            ],
            [
              105.83329045673366,
              21.004043026272424
            ],
            [
              105.8332979717898,
              21.00403644741409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT3,17_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9246",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003591011705254,
          "to_latitude": 21.00403644741409,
          "to_longitude": 105.8332979717898,
          "from_longitude": 105.83292407946053
        }
      },
      {
        "id": 9239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315227972612,
            21.004177880497764,
            105.8333901004099,
            21.00431615902417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315227972612,
              21.00431615902417
            ],
            [
              105.83317940767863,
              21.004308450873076
            ],
            [
              105.83326462001769,
              21.00427324958438
            ],
            [
              105.83327806201711,
              21.004267696678067
            ],
            [
              105.83329398436084,
              21.0042579316265
            ],
            [
              105.83335376093903,
              21.004208146690754
            ],
            [
              105.8333901004099,
              21.004177880497764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,14_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9247",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00431615902417,
          "to_latitude": 21.004177880497764,
          "to_longitude": 105.8333901004099,
          "from_longitude": 105.83315227972612
        }
      },
      {
        "id": 9240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83334754605607,
            21.004198221763275,
            105.83335376093903,
            21.004208146690754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83334754605607,
              21.004198221763275
            ],
            [
              105.83334807303284,
              21.00419906550586
            ],
            [
              105.83335029831044,
              21.00420261783697
            ],
            [
              105.83335376093903,
              21.004208146690754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,14_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9248",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004198221763275,
          "to_latitude": 21.004208146690754,
          "to_longitude": 105.83335376093903,
          "from_longitude": 105.83334754605607
        }
      },
      {
        "id": 9241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340026944194,
            21.004152455475214,
            105.83359184683873,
            21.00427623817688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83340026944194,
              21.004152455475214
            ],
            [
              105.83340068859845,
              21.00415258980995
            ],
            [
              105.83341177530195,
              21.00415610236322
            ],
            [
              105.83342811311364,
              21.00417144675355
            ],
            [
              105.83353969221243,
              21.00427623817688
            ],
            [
              105.83359184683873,
              21.004231290229157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,15_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9249",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004152455475214,
          "to_latitude": 21.004231290229157,
          "to_longitude": 105.83359184683873,
          "from_longitude": 105.83340026944194
        }
      },
      {
        "id": 9242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83342811311364,
            21.004103114154077,
            105.83350022157295,
            21.00417144675355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83342811311364,
              21.00417144675355
            ],
            [
              105.8334284566127,
              21.004171120813446
            ],
            [
              105.83350022157295,
              21.004103114154077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,15_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9250",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00417144675355,
          "to_latitude": 21.004103114154077,
          "to_longitude": 105.83350022157295,
          "from_longitude": 105.83342811311364
        }
      },
      {
        "id": 9243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281992000447,
            21.003638359113843,
            105.83296037926456,
            21.003741708017767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83296037926456,
              21.003638359113843
            ],
            [
              105.83281992000447,
              21.003741708017767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,17_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9251",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003638359113843,
          "to_latitude": 21.003741708017767,
          "to_longitude": 105.83281992000447,
          "from_longitude": 105.83296037926456
        }
      },
      {
        "id": 9244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299509013924,
            21.003537361925588,
            105.83317265514611,
            21.003683634160318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299509013924,
              21.003683634160318
            ],
            [
              105.83317265514611,
              21.003537361925588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,17_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9252",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003683634160318,
          "to_latitude": 21.003537361925588,
          "to_longitude": 105.83317265514611,
          "from_longitude": 105.83299509013924
        }
      },
      {
        "id": 9245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8333568203703,
            21.00376549858513,
            105.83344878762958,
            21.0038638657161
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344878762958,
              21.0038638657161
            ],
            [
              105.8333568203703,
              21.00376549858513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,18_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9253",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0038638657161,
          "to_latitude": 21.00376549858513,
          "to_longitude": 105.8333568203703,
          "from_longitude": 105.83344878762958
        }
      },
      {
        "id": 9246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332954594979,
            21.00404211198219,
            105.83330378595801,
            21.00404963055542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83330378595801,
              21.00404211198219
            ],
            [
              105.83329884272027,
              21.004046575539434
            ],
            [
              105.83329622323221,
              21.004048940364548
            ],
            [
              105.8332954594979,
              21.00404963055542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9254",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00404211198219,
          "to_latitude": 21.00404963055542,
          "to_longitude": 105.8332954594979,
          "from_longitude": 105.83330378595801
        }
      },
      {
        "id": 9247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83334623239446,
            21.00369755742931,
            105.83366918335274,
            21.00399437279932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83334623239446,
              21.00399437279932
            ],
            [
              105.8334641572373,
              21.003869239617455
            ],
            [
              105.833468980034,
              21.00386543329071
            ],
            [
              105.83351116803244,
              21.003832142433854
            ],
            [
              105.83351846735263,
              21.00382638294541
            ],
            [
              105.8336287497015,
              21.00373895882977
            ],
            [
              105.83366918335274,
              21.00369755742931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,19_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9255",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00399437279932,
          "to_latitude": 21.00369755742931,
          "to_longitude": 105.83366918335274,
          "from_longitude": 105.83334623239446
        }
      },
      {
        "id": 9248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83346590058402,
            21.00386187340172,
            105.833468980034,
            21.00386543329071
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83346590058402,
              21.00386187340172
            ],
            [
              105.83346611743954,
              21.00386212524311
            ],
            [
              105.83346799743639,
              21.00386429732788
            ],
            [
              105.833468980034,
              21.00386543329071
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,19_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9256",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00386187340172,
          "to_latitude": 21.00386543329071,
          "to_longitude": 105.833468980034,
          "from_longitude": 105.83346590058402
        }
      },
      {
        "id": 9249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83334248885339,
            21.003787642104804,
            105.83355528769482,
            21.003980172544434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83334248885339,
              21.003980172544434
            ],
            [
              105.83340853253632,
              21.003911978967725
            ],
            [
              105.83344878762958,
              21.0038638657161
            ],
            [
              105.83345171905644,
              21.0038613819419
            ],
            [
              105.83345506309817,
              21.00385854750195
            ],
            [
              105.83346791954196,
              21.003849404415938
            ],
            [
              105.83352728583438,
              21.00380718324023
            ],
            [
              105.83355528769482,
              21.003787642104804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,18_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9257",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003980172544434,
          "to_latitude": 21.003787642104804,
          "to_longitude": 105.83355528769482,
          "from_longitude": 105.83334248885339
        }
      },
      {
        "id": 9250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83345171905644,
            21.0038613819419,
            105.8334598915237,
            21.003867879473166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345171905644,
              21.0038613819419
            ],
            [
              105.83345664533732,
              21.00386529859424
            ],
            [
              105.83345957850474,
              21.003867631715593
            ],
            [
              105.8334598915237,
              21.003867879473166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,18_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9258",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0038613819419,
          "to_latitude": 21.003867879473166,
          "to_longitude": 105.8334598915237,
          "from_longitude": 105.83345171905644
        }
      },
      {
        "id": 9251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357007436223,
            21.003656143111506,
            105.83369676261292,
            21.003774995880327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83357007436223,
              21.003774995880327
            ],
            [
              105.83362262131793,
              21.003725384455162
            ],
            [
              105.83363056356184,
              21.00371796710327
            ],
            [
              105.83369676261292,
              21.003656143111506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,20_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9259",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003774995880327,
          "to_latitude": 21.003656143111506,
          "to_longitude": 105.83369676261292,
          "from_longitude": 105.83357007436223
        }
      },
      {
        "id": 9252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363056356184,
            21.00371796710327,
            105.83363633818787,
            21.003724132241075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363056356184,
              21.00371796710327
            ],
            [
              105.83363062936286,
              21.00371803813584
            ],
            [
              105.83363605371798,
              21.003723829247196
            ],
            [
              105.83363633818787,
              21.003724132241075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,20_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9260",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00371796710327,
          "to_latitude": 21.003724132241075,
          "to_longitude": 105.83363633818787,
          "from_longitude": 105.83363056356184
        }
      },
      {
        "id": 9253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372132262473,
            21.003668588491582,
            105.83384826500462,
            21.003810196732264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384826500462,
              21.003810196732264
            ],
            [
              105.83376860151765,
              21.003721330199653
            ],
            [
              105.83376386094602,
              21.00371604154968
            ],
            [
              105.83372132262473,
              21.003668588491582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,21_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9261",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003810196732264,
          "to_latitude": 21.003668588491582,
          "to_longitude": 105.83372132262473,
          "from_longitude": 105.83384826500462
        }
      },
      {
        "id": 9254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376386094602,
            21.00370896669618,
            105.83377563809829,
            21.00371604154968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376386094602,
              21.00371604154968
            ],
            [
              105.83376395489469,
              21.00371598508946
            ],
            [
              105.83376836664492,
              21.003713333284356
            ],
            [
              105.83376910193324,
              21.003712890714695
            ],
            [
              105.83376934926359,
              21.003712741374088
            ],
            [
              105.83377508494634,
              21.003709298180087
            ],
            [
              105.83377563809829,
              21.00370896669618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,21_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9262",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00371604154968,
          "to_latitude": 21.00370896669618,
          "to_longitude": 105.83377563809829,
          "from_longitude": 105.83376386094602
        }
      },
      {
        "id": 9255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376836664492,
            21.003713333284356,
            105.83379100472357,
            21.003724029234544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376836664492,
              21.003713333284356
            ],
            [
              105.8337775964403,
              21.003724029234544
            ],
            [
              105.83378529035683,
              21.003719353479628
            ],
            [
              105.83379100472357,
              21.003715882389518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,21_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9263",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003713333284356,
          "to_latitude": 21.003715882389518,
          "to_longitude": 105.83379100472357,
          "from_longitude": 105.83376836664492
        }
      },
      {
        "id": 9256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374652453708,
            21.003663259752845,
            105.83382878883232,
            21.003760606590053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83374652453708,
              21.003663259752845
            ],
            [
              105.83379100472357,
              21.003715882389518
            ],
            [
              105.83379428127166,
              21.003719772790973
            ],
            [
              105.83382878883232,
              21.003760606590053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,21_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9264",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003663259752845,
          "to_latitude": 21.003760606590053,
          "to_longitude": 105.83382878883232,
          "from_longitude": 105.83374652453708
        }
      },
      {
        "id": 9257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390010905548,
            21.003750598085418,
            105.83418598097182,
            21.00389967847619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390010905548,
              21.003750598085418
            ],
            [
              105.83402707107184,
              21.00389967847619
            ],
            [
              105.83418598097182,
              21.00380584488164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,22_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9265",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003750598085418,
          "to_latitude": 21.00380584488164,
          "to_longitude": 105.83418598097182,
          "from_longitude": 105.83390010905548
        }
      },
      {
        "id": 9258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387090974392,
            21.004017657857396,
            105.83402242934632,
            21.004402198286737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83402242934632,
              21.004402198286737
            ],
            [
              105.83387090974392,
              21.004234975904584
            ],
            [
              105.83390460273851,
              21.00416588024443
            ],
            [
              105.8339807874764,
              21.004102590960354
            ],
            [
              105.83390282213182,
              21.004017657857396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,17_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9266",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004402198286737,
          "to_latitude": 21.004017657857396,
          "to_longitude": 105.83390282213182,
          "from_longitude": 105.83402242934632
        }
      },
      {
        "id": 9259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337651294609,
            21.00403840660894,
            105.83390460273851,
            21.00416588024443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390460273851,
              21.00416588024443
            ],
            [
              105.8337651294609,
              21.00403840660894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,17_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9267",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00416588024443,
          "to_latitude": 21.00403840660894,
          "to_longitude": 105.8337651294609,
          "from_longitude": 105.83390460273851
        }
      },
      {
        "id": 9260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363735726233,
            21.00442486438614,
            105.83388765133269,
            21.004656229778064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363735726233,
              21.00442486438614
            ],
            [
              105.8337515015833,
              21.004539970578318
            ],
            [
              105.83384515307598,
              21.00463127953725
            ],
            [
              105.8338543318998,
              21.004639022181575
            ],
            [
              105.83386043031213,
              21.004642170755684
            ],
            [
              105.83388765133269,
              21.004656229778064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,15_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9268",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00442486438614,
          "to_latitude": 21.004656229778064,
          "to_longitude": 105.83388765133269,
          "from_longitude": 105.83363735726233
        }
      },
      {
        "id": 9261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335911165893,
            21.004799021999524,
            105.8339312844174,
            21.005375592470575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335911165893,
              21.005375592470575
            ],
            [
              105.83362589026076,
              21.005265307183134
            ],
            [
              105.83362709942233,
              21.005263245518687
            ],
            [
              105.83362752629985,
              21.005262518137506
            ],
            [
              105.83366304829302,
              21.005201935500686
            ],
            [
              105.83368324586755,
              21.005150031147434
            ],
            [
              105.83368704868239,
              21.005140259487565
            ],
            [
              105.83370359843057,
              21.005088282677633
            ],
            [
              105.83370365325912,
              21.00508810989293
            ],
            [
              105.83371603004547,
              21.005058922942528
            ],
            [
              105.83372738934081,
              21.00501470316872
            ],
            [
              105.83372970178873,
              21.00499117635659
            ],
            [
              105.8337352742162,
              21.00493150002567
            ],
            [
              105.83375294626114,
              21.004850925196024
            ],
            [
              105.83376620263351,
              21.004818359481458
            ],
            [
              105.83378809154827,
              21.004800472251187
            ],
            [
              105.83381088647967,
              21.004799021999524
            ],
            [
              105.8339312844174,
              21.004829323352872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,12_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9269",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005375592470575,
          "to_latitude": 21.004829323352872,
          "to_longitude": 105.8339312844174,
          "from_longitude": 105.8335911165893
        }
      },
      {
        "id": 9262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362709942233,
            21.005263245518687,
            105.83372229431383,
            21.00528653858095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362709942233,
              21.005263245518687
            ],
            [
              105.83362719477532,
              21.005263268535515
            ],
            [
              105.83372229431383,
              21.00528653858095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,12_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9270",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005263245518687,
          "to_latitude": 21.00528653858095,
          "to_longitude": 105.83372229431383,
          "from_longitude": 105.83362709942233
        }
      },
      {
        "id": 9263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368324586755,
            21.005150031147434,
            105.833752167778,
            21.0051746361125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368324586755,
              21.005150031147434
            ],
            [
              105.83368327285437,
              21.005150040950735
            ],
            [
              105.833752167778,
              21.0051746361125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,12_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9271",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005150031147434,
          "to_latitude": 21.0051746361125,
          "to_longitude": 105.833752167778,
          "from_longitude": 105.83368324586755
        }
      },
      {
        "id": 9264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362112816724,
            21.00526065450776,
            105.83362752629985,
            21.005262518137506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362752629985,
              21.005262518137506
            ],
            [
              105.83362398859342,
              21.005261488626484
            ],
            [
              105.83362112816724,
              21.00526065450776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,12_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9272",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005262518137506,
          "to_latitude": 21.00526065450776,
          "to_longitude": 105.83362112816724,
          "from_longitude": 105.83362752629985
        }
      },
      {
        "id": 9265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359719557336,
            21.004735447557735,
            105.83374598334764,
            21.004839794418437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83359719557336,
              21.004839794418437
            ],
            [
              105.83361597406521,
              21.0048266246376
            ],
            [
              105.83373071069221,
              21.004746158759534
            ],
            [
              105.8337380547877,
              21.004741007816133
            ],
            [
              105.83374598334764,
              21.004735447557735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,14_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9273",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004839794418437,
          "to_latitude": 21.004735447557735,
          "to_longitude": 105.83374598334764,
          "from_longitude": 105.83359719557336
        }
      },
      {
        "id": 9266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337380547877,
            21.004741007816133,
            105.8337955836801,
            21.004785782515235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337380547877,
              21.004741007816133
            ],
            [
              105.83379512481852,
              21.004785427085725
            ],
            [
              105.8337955836801,
              21.004785782515235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,14_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9274",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004741007816133,
          "to_latitude": 21.004785782515235,
          "to_longitude": 105.8337955836801,
          "from_longitude": 105.8337380547877
        }
      },
      {
        "id": 9267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83369516925278,
            21.00486392088558,
            105.83373596742815,
            21.00508874487039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83373596742815,
              21.00486392088558
            ],
            [
              105.83372354317153,
              21.004942563297607
            ],
            [
              105.83372285649935,
              21.004946910274334
            ],
            [
              105.83372057045486,
              21.00497484379599
            ],
            [
              105.8337204523619,
              21.004980942955285
            ],
            [
              105.8337214099978,
              21.00500286316812
            ],
            [
              105.83372020054021,
              21.005014220834887
            ],
            [
              105.83371045108332,
              21.005040720464102
            ],
            [
              105.83369516925278,
              21.00508874487039
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,13_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9275",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00486392088558,
          "to_latitude": 21.00508874487039,
          "to_longitude": 105.83369516925278,
          "from_longitude": 105.83373596742815
        }
      },
      {
        "id": 9268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372354317153,
            21.004942563297607,
            105.83373256661409,
            21.004943803483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372354317153,
              21.004942563297607
            ],
            [
              105.83372901364514,
              21.004943315080972
            ],
            [
              105.8337322720596,
              21.00494376337751
            ],
            [
              105.83373256661409,
              21.004943803483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,13_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9276",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004942563297607,
          "to_latitude": 21.004943803483,
          "to_longitude": 105.83373256661409,
          "from_longitude": 105.83372354317153
        }
      },
      {
        "id": 9269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83274996181869,
            21.005267068234755,
            105.8327698517146,
            21.005268914199867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8327698517146,
              21.005267068234755
            ],
            [
              105.83276613888584,
              21.005267413369307
            ],
            [
              105.83274996181869,
              21.005268914199867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,3_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9277",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005267068234755,
          "to_latitude": 21.005268914199867,
          "to_longitude": 105.83274996181869,
          "from_longitude": 105.8327698517146
        }
      },
      {
        "id": 9270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83268924914293,
            21.00490097775,
            105.83274996181869,
            21.005268914199867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83274996181869,
              21.005268914199867
            ],
            [
              105.83268924914293,
              21.00490097775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,3_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9278",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005268914199867,
          "to_latitude": 21.00490097775,
          "to_longitude": 105.83268924914293,
          "from_longitude": 105.83274996181869
        }
      },
      {
        "id": 9271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327928680103,
            21.00675691068604,
            105.83331710191906,
            21.006796966935642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327928680103,
              21.006796966935642
            ],
            [
              105.83328467051795,
              21.006791263801844
            ],
            [
              105.83328618809813,
              21.006789656758578
            ],
            [
              105.83331710191906,
              21.00675691068604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,3_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9279",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006796966935642,
          "to_latitude": 21.00675691068604,
          "to_longitude": 105.83331710191906,
          "from_longitude": 105.83327928680103
        }
      },
      {
        "id": 9272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83328082988838,
            21.006759670522126,
            105.8333201394715,
            21.006798221186614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83328082988838,
              21.006798221186614
            ],
            [
              105.8332859126748,
              21.006793235784205
            ],
            [
              105.83328798673489,
              21.00679120150014
            ],
            [
              105.8333201394715,
              21.006759670522126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9280",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006798221186614,
          "to_latitude": 21.006759670522126,
          "to_longitude": 105.8333201394715,
          "from_longitude": 105.83328082988838
        }
      },
      {
        "id": 9273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83329704248943,
            21.00680479701636,
            105.83330381782798,
            21.006811405674238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83330381782798,
              21.00680479701636
            ],
            [
              105.83330295966911,
              21.006805633992357
            ],
            [
              105.83330040528601,
              21.00680812585421
            ],
            [
              105.83329704248943,
              21.006811405674238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22,1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9281",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00680479701636,
          "to_latitude": 21.006811405674238,
          "to_longitude": 105.83329704248943,
          "from_longitude": 105.83330381782798
        }
      },
      {
        "id": 9274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341928713756,
            21.006285798092645,
            105.83349452328486,
            21.006360277252448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83349452328486,
              21.006360277252448
            ],
            [
              105.83341928713756,
              21.006285798092645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9282",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006360277252448,
          "to_latitude": 21.006285798092645,
          "to_longitude": 105.83341928713756,
          "from_longitude": 105.83349452328486
        }
      },
      {
        "id": 9275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340958361882,
            21.006312382394153,
            105.83360268525061,
            21.00651336365786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83349452328486,
              21.006360277252448
            ],
            [
              105.83352056628308,
              21.00633883357926
            ],
            [
              105.83357335434596,
              21.006312382394153
            ],
            [
              105.83360268525061,
              21.006351240018194
            ],
            [
              105.83340958361882,
              21.00651336365786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,3_Ngõ 26, Đông Tác",
          "maTaiSan": "04.O17.DODV.9283",
          "diaChiLapD": "Ngõ 26, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006360277252448,
          "to_latitude": 21.00651336365786,
          "to_longitude": 105.83340958361882,
          "from_longitude": 105.83349452328486
        }
      },
      {
        "id": 9276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356991810675,
            21.005374592052352,
            105.83358717120869,
            21.005374761456935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356991810675,
              21.005374761456935
            ],
            [
              105.8335826400829,
              21.005374636825486
            ],
            [
              105.8335859602861,
              21.005374605209017
            ],
            [
              105.83358717120869,
              21.005374592052352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2,10_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9284",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005374761456935,
          "to_latitude": 21.005374592052352,
          "to_longitude": 105.83358717120869,
          "from_longitude": 105.83356991810675
        }
      },
      {
        "id": 9277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83380586967301,
            21.00441454598,
            105.83399963539874,
            21.004752156510605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83399963539874,
              21.00441454598
            ],
            [
              105.83396078928094,
              21.00446522032543
            ],
            [
              105.83391953754935,
              21.004528711481804
            ],
            [
              105.83391347577417,
              21.00453804169844
            ],
            [
              105.83389594833008,
              21.004571393382196
            ],
            [
              105.83388231185464,
              21.00459734212965
            ],
            [
              105.83386115976339,
              21.00464067685927
            ],
            [
              105.83386043031213,
              21.004642170755684
            ],
            [
              105.83384299487827,
              21.004677891144873
            ],
            [
              105.83380586967301,
              21.004752156510605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,16_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9285",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00441454598,
          "to_latitude": 21.004752156510605,
          "to_longitude": 105.83380586967301,
          "from_longitude": 105.83399963539874
        }
      },
      {
        "id": 9278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83391953754935,
            21.004528711481804,
            105.83392749065418,
            21.004532245676067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83391953754935,
              21.004528711481804
            ],
            [
              105.83392577816161,
              21.004531486324694
            ],
            [
              105.83392749065418,
              21.004532245676067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,16_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DODV.9286",
          "diaChiLapD": "Ngõ 19, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004528711481804,
          "to_latitude": 21.004532245676067,
          "to_longitude": 105.83392749065418,
          "from_longitude": 105.83391953754935
        }
      },
      {
        "id": 9279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488415257511,
            21.005925940765135,
            105.83494355066496,
            21.0060913264257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488415257511,
              21.0060913264257
            ],
            [
              105.83494355066496,
              21.005925940765135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2,18_Đông Tác",
          "maTaiSan": "04.O17.DODV.9287",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0060913264257,
          "to_latitude": 21.005925940765135,
          "to_longitude": 105.83494355066496,
          "from_longitude": 105.83488415257511
        }
      },
      {
        "id": 9280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355307645531,
            21.0089753173661,
            105.83596539419588,
            21.009478810780603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567458649728,
              21.0089753173661
            ],
            [
              105.83567366355183,
              21.008976265768656
            ],
            [
              105.8355307645531,
              21.009123083076787
            ],
            [
              105.83596539419588,
              21.009478810780603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7,1P_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9288",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0089753173661,
          "to_latitude": 21.009478810780603,
          "to_longitude": 105.83596539419588,
          "from_longitude": 105.83567458649728
        }
      },
      {
        "id": 9281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83501531063922,
            21.00869675729735,
            105.83566500462885,
            21.009109889829737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566500462885,
              21.00896608378223
            ],
            [
              105.83566437605224,
              21.008966692029773
            ],
            [
              105.83551656662556,
              21.009109889829737
            ],
            [
              105.83501531063922,
              21.00869675729735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B10,1P_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9289",
          "diaChiLapD": "Ngõ 43, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00896608378223,
          "to_latitude": 21.00869675729735,
          "to_longitude": 105.83501531063922,
          "from_longitude": 105.83566500462885
        }
      },
      {
        "id": 9282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83754245093084,
            21.009217921045085,
            105.83767324280286,
            21.009356469613536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767324280286,
              21.009356469613536
            ],
            [
              105.83766880116559,
              21.00935206116058
            ],
            [
              105.8376540747394,
              21.00933641754154
            ],
            [
              105.83754245093084,
              21.009217921045085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1,1_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.9290",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009356469613536,
          "to_latitude": 21.009217921045085,
          "to_longitude": 105.83754245093084,
          "from_longitude": 105.83767324280286
        }
      },
      {
        "id": 9283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807698627335,
            21.00867522873146,
            105.83820909757068,
            21.008782044631655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820909757068,
              21.008782044631655
            ],
            [
              105.83820446677815,
              21.00877786834959
            ],
            [
              105.83807698627335,
              21.00867522873146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1,2_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.9291",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008782044631655,
          "to_latitude": 21.00867522873146,
          "to_longitude": 105.83807698627335,
          "from_longitude": 105.83820909757068
        }
      },
      {
        "id": 9284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83848148751146,
            21.008456265341255,
            105.83862132067256,
            21.00856509771021
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848148751146,
              21.008456265341255
            ],
            [
              105.83848150876835,
              21.00845628239781
            ],
            [
              105.8386207294373,
              21.008564639979266
            ],
            [
              105.83862132067256,
              21.00856509771021
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1,3_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.9292",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008456265341255,
          "to_latitude": 21.00856509771021,
          "to_longitude": 105.83862132067256,
          "from_longitude": 105.83848148751146
        }
      },
      {
        "id": 9285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387087012736,
            21.008223080170723,
            105.83885512454684,
            21.008321294080353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885512454684,
              21.008321294080353
            ],
            [
              105.8387331923744,
              21.00823796202725
            ],
            [
              105.8387087012736,
              21.008223080170723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1,4_Đào Duy Anh",
          "maTaiSan": "04.O17.DODV.9293",
          "diaChiLapD": "Đào Duy Anh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008321294080353,
          "to_latitude": 21.008223080170723,
          "to_longitude": 105.8387087012736,
          "from_longitude": 105.83885512454684
        }
      },
      {
        "id": 9286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280845262706,
            21.004050896378185,
            105.83308927780483,
            21.004365271323596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83308927780483,
              21.004050896378185
            ],
            [
              105.83308556499199,
              21.00406527494172
            ],
            [
              105.83305032289614,
              21.00420175598481
            ],
            [
              105.8330461910424,
              21.004217755179464
            ],
            [
              105.83300809952587,
              21.004365271323596
            ],
            [
              105.83280845262706,
              21.00432898211573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3,16_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DODV.9294",
          "diaChiLapD": "Ngõ 41, Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004050896378185,
          "to_latitude": 21.00432898211573,
          "to_longitude": 105.83280845262706,
          "from_longitude": 105.83308927780483
        }
      },
      {
        "id": 9287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83117216935554,
            21.0049996066397,
            105.83137762743019,
            21.00528404318177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83137762743019,
              21.00528404318177
            ],
            [
              105.83134115225222,
              21.0049996066397
            ],
            [
              105.83117216935554,
              21.005045838045458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,4_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9295",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00528404318177,
          "to_latitude": 21.005045838045458,
          "to_longitude": 105.83117216935554,
          "from_longitude": 105.83137762743019
        }
      },
      {
        "id": 9288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323012177857,
            21.004862774293514,
            105.83243279283508,
            21.00563916984844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83243279283508,
              21.00563916984844
            ],
            [
              105.83234951300942,
              21.005153588263695
            ],
            [
              105.83234731127352,
              21.005140751543884
            ],
            [
              105.8323012177857,
              21.004862774293514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,2_Ngõ 43, Đông Tác",
          "maTaiSan": "04.O17.DODV.9296",
          "diaChiLapD": "Ngõ 43, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 87.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00563916984844,
          "to_latitude": 21.004862774293514,
          "to_longitude": 105.8323012177857,
          "from_longitude": 105.83243279283508
        }
      },
      {
        "id": 9289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109623205054,
            21.00527656238147,
            105.83140486560697,
            21.005361326449094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140486560697,
              21.00527656238147
            ],
            [
              105.8314006186789,
              21.00527772932153
            ],
            [
              105.83137762743019,
              21.00528404318177
            ],
            [
              105.83109623205054,
              21.005361326449094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,4_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9297",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00527656238147,
          "to_latitude": 21.005361326449094,
          "to_longitude": 105.83109623205054,
          "from_longitude": 105.83140486560697
        }
      },
      {
        "id": 9290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202063493697,
            21.005153588263695,
            105.83234951300942,
            21.005215814532608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202063493697,
              21.005215814532608
            ],
            [
              105.83203815048914,
              21.005212498969293
            ],
            [
              105.83232864769062,
              21.005157498304975
            ],
            [
              105.83234951300942,
              21.005153588263695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9298",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005215814532608,
          "to_latitude": 21.005153588263695,
          "to_longitude": 105.83234951300942,
          "from_longitude": 105.83202063493697
        }
      },
      {
        "id": 9291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.830592306126,
            21.004270272126142,
            105.83119303172074,
            21.004400646359926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830592306126,
              21.004400646359926
            ],
            [
              105.83066687269786,
              21.00438446335
            ],
            [
              105.83067036008022,
              21.004383706587525
            ],
            [
              105.83068616858336,
              21.004380275754148
            ],
            [
              105.8306872359319,
              21.004380043835063
            ],
            [
              105.83089266858242,
              21.00433545950634
            ],
            [
              105.83105045081064,
              21.00430121018851
            ],
            [
              105.831053592337,
              21.004300529171562
            ],
            [
              105.83107133668048,
              21.004296683302076
            ],
            [
              105.83107479523824,
              21.004295932994747
            ],
            [
              105.83107961415897,
              21.004294887012463
            ],
            [
              105.83119303172074,
              21.004270272126142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8,3_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9299",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004400646359926,
          "to_latitude": 21.004270272126142,
          "to_longitude": 105.83119303172074,
          "from_longitude": 105.830592306126
        }
      },
      {
        "id": 9292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83105023256554,
            21.00428576788981,
            105.831053592337,
            21.004300529171562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831053592337,
              21.004300529171562
            ],
            [
              105.83105070087672,
              21.004287831281886
            ],
            [
              105.83105023256554,
              21.00428576788981
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8,3_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9300",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004300529171562,
          "to_latitude": 21.00428576788981,
          "to_longitude": 105.83105023256554,
          "from_longitude": 105.831053592337
        }
      },
      {
        "id": 9293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83084423654675,
            21.00471997105457,
            105.8312868563784,
            21.00482104913785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83084423654675,
              21.00482104913785
            ],
            [
              105.83104166038635,
              21.004775964484917
            ],
            [
              105.83105769559677,
              21.00477230128336
            ],
            [
              105.83105800589654,
              21.00477223202625
            ],
            [
              105.83106335673251,
              21.004771010027355
            ],
            [
              105.83107346760187,
              21.004768700784915
            ],
            [
              105.8310799923576,
              21.004767211117727
            ],
            [
              105.83108252368424,
              21.00476663424092
            ],
            [
              105.83108457467021,
              21.00476616448381
            ],
            [
              105.8312868563784,
              21.00471997105457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A9,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9301",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00482104913785,
          "to_latitude": 21.00471997105457,
          "to_longitude": 105.8312868563784,
          "from_longitude": 105.83084423654675
        }
      },
      {
        "id": 9294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83107346760187,
            21.004768700784915,
            105.83107781924662,
            21.004785230255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83107346760187,
              21.004768700784915
            ],
            [
              105.8310755796363,
              21.004776476282796
            ],
            [
              105.83107781924662,
              21.004785230255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A9,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9302",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004768700784915,
          "to_latitude": 21.004785230255,
          "to_longitude": 105.83107781924662,
          "from_longitude": 105.83107346760187
        }
      },
      {
        "id": 9295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83088238307914,
            21.004840209973782,
            105.83127491132547,
            21.00492960143293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83088238307914,
              21.00492960143293
            ],
            [
              105.83105263267532,
              21.00489083019907
            ],
            [
              105.83106553909819,
              21.004887890403165
            ],
            [
              105.83107733212061,
              21.004885204430202
            ],
            [
              105.83107864726232,
              21.0048849053631
            ],
            [
              105.83108450630648,
              21.004883570688264
            ],
            [
              105.83109426273835,
              21.00488134898163
            ],
            [
              105.83127491132547,
              21.004840209973782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A10,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9303",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00492960143293,
          "to_latitude": 21.004840209973782,
          "to_longitude": 105.83127491132547,
          "from_longitude": 105.83088238307914
        }
      },
      {
        "id": 9296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83107538660845,
            21.004877971861614,
            105.83107733212061,
            21.004885204430202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83107733212061,
              21.004885204430202
            ],
            [
              105.83107643058852,
              21.004881856959553
            ],
            [
              105.83107538660845,
              21.004877971861614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A10,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9304",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004885204430202,
          "to_latitude": 21.004877971861614,
          "to_longitude": 105.83107538660845,
          "from_longitude": 105.83107733212061
        }
      },
      {
        "id": 9297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83064354941287,
            21.004693642284675,
            105.8308456679289,
            21.005300259711444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8308456679289,
              21.005300259711444
            ],
            [
              105.83081134430928,
              21.005197361014545
            ],
            [
              105.83081179363168,
              21.00519634178838
            ],
            [
              105.83081154118078,
              21.005195567149315
            ],
            [
              105.83081125644898,
              21.00519469511938
            ],
            [
              105.83081017155767,
              21.005193844156672
            ],
            [
              105.83080379530908,
              21.005174729593772
            ],
            [
              105.83080432779816,
              21.005173964671446
            ],
            [
              105.83080401568485,
              21.00517301148479
            ],
            [
              105.83080380336966,
              21.005172363101483
            ],
            [
              105.8308028103519,
              21.005171775432334
            ],
            [
              105.83078995026247,
              21.00513322257236
            ],
            [
              105.8307524099319,
              21.00502227645962
            ],
            [
              105.8307441039408,
              21.004997730277406
            ],
            [
              105.83071303036535,
              21.00490121504839
            ],
            [
              105.83070883014948,
              21.004888403411975
            ],
            [
              105.83070225442162,
              21.004868347752375
            ],
            [
              105.83070047473964,
              21.004862919919077
            ],
            [
              105.83064354941287,
              21.004693642284675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A11,3_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9305",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005300259711444,
          "to_latitude": 21.004693642284675,
          "to_longitude": 105.83064354941287,
          "from_longitude": 105.8308456679289
        }
      },
      {
        "id": 9298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83073564130946,
            21.00502227645962,
            105.8307524099319,
            21.005027425407466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307524099319,
              21.00502227645962
            ],
            [
              105.83075140552866,
              21.005022585749458
            ],
            [
              105.83074424791761,
              21.00502478300996
            ],
            [
              105.83073564130946,
              21.005027425407466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A11,3_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9306",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00502227645962,
          "to_latitude": 21.005027425407466,
          "to_longitude": 105.83073564130946,
          "from_longitude": 105.8307524099319
        }
      },
      {
        "id": 9299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316915993947,
            21.00577161672035,
            105.83216358145867,
            21.005911131095555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83216358145867,
              21.00577161672035
            ],
            [
              105.83169741306685,
              21.005909413652663
            ],
            [
              105.8316915993947,
              21.005911131095555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,1_Đông Tác",
          "maTaiSan": "04.O17.DODV.9307",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00577161672035,
          "to_latitude": 21.005911131095555,
          "to_longitude": 105.8316915993947,
          "from_longitude": 105.83216358145867
        }
      },
      {
        "id": 9300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320616032908,
            21.005264285492355,
            105.83221428643131,
            21.005729777039026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221428643131,
              21.005729777039026
            ],
            [
              105.83218564527462,
              21.0056440139688
            ],
            [
              105.83218418593317,
              21.005639644974302
            ],
            [
              105.83218282537105,
              21.005635569948495
            ],
            [
              105.83215794010745,
              21.00555600541171
            ],
            [
              105.83215648301749,
              21.005551348276768
            ],
            [
              105.83215290294798,
              21.005539902939518
            ],
            [
              105.83213015052709,
              21.005468205906237
            ],
            [
              105.8321160809291,
              21.00542582397394
            ],
            [
              105.832112080261,
              21.00541328959478
            ],
            [
              105.83211165248773,
              21.00541194858908
            ],
            [
              105.83211301924737,
              21.00541072345483
            ],
            [
              105.83211280801324,
              21.0054100976487
            ],
            [
              105.83211141351536,
              21.005405973110577
            ],
            [
              105.8321109626979,
              21.00540463944333
            ],
            [
              105.83210937094418,
              21.005404491870724
            ],
            [
              105.83208260313388,
              21.005325923748696
            ],
            [
              105.83208121565106,
              21.00532184975659
            ],
            [
              105.8320616032908,
              21.005264285492355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2,5_Đông Tác",
          "maTaiSan": "04.O17.DODV.9308",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005729777039026,
          "to_latitude": 21.005264285492355,
          "to_longitude": 105.8320616032908,
          "from_longitude": 105.83221428643131
        }
      },
      {
        "id": 9301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209328012283,
            21.00541328959478,
            105.832112080261,
            21.005419123318344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832112080261,
              21.00541328959478
            ],
            [
              105.83211073403223,
              21.00541370714454
            ],
            [
              105.83210360728397,
              21.00541591876282
            ],
            [
              105.83209328012283,
              21.005419123318344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2,5_Đông Tác",
          "maTaiSan": "04.O17.DODV.9309",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00541328959478,
          "to_latitude": 21.005419123318344,
          "to_longitude": 105.83209328012283,
          "from_longitude": 105.832112080261
        }
      },
      {
        "id": 9302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316460616934,
            21.005223840001676,
            105.83200431320573,
            21.005298651987047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316460616934,
              21.005298651987047
            ],
            [
              105.8317491703801,
              21.005277126442593
            ],
            [
              105.83174952393848,
              21.00527705245666
            ],
            [
              105.83175202382516,
              21.00527652901375
            ],
            [
              105.83176030554064,
              21.005274794991806
            ],
            [
              105.83176038431182,
              21.00527477654233
            ],
            [
              105.83183058984466,
              21.005260118199313
            ],
            [
              105.83189163932352,
              21.005247369742932
            ],
            [
              105.83190226723123,
              21.00524515005271
            ],
            [
              105.83191229370492,
              21.005243056140344
            ],
            [
              105.83191620783325,
              21.00524223855642
            ],
            [
              105.83200431320573,
              21.005223840001676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9310",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005298651987047,
          "to_latitude": 21.005223840001676,
          "to_longitude": 105.83200431320573,
          "from_longitude": 105.8316460616934
        }
      },
      {
        "id": 9303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83175667533564,
            21.005260197630587,
            105.83176038431182,
            21.00527477654233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176038431182,
              21.00527477654233
            ],
            [
              105.8317570520424,
              21.005261523532045
            ],
            [
              105.83175667533564,
              21.005260197630587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A3,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9311",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00527477654233,
          "to_latitude": 21.005260197630587,
          "to_longitude": 105.83175667533564,
          "from_longitude": 105.83176038431182
        }
      },
      {
        "id": 9304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141795183084,
            21.00493327900245,
            105.83219505107586,
            21.005114913093728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141795183084,
              21.005114913093728
            ],
            [
              105.83160752985609,
              21.005069475009325
            ],
            [
              105.83163500187513,
              21.005062890837664
            ],
            [
              105.83163654842598,
              21.005062519280656
            ],
            [
              105.8316387145599,
              21.00506199927673
            ],
            [
              105.83180780056387,
              21.005021380457933
            ],
            [
              105.83198416190751,
              21.004981257006566
            ],
            [
              105.83199035795214,
              21.004979812266175
            ],
            [
              105.8320205927299,
              21.00497276291477
            ],
            [
              105.83202152841803,
              21.004972551501847
            ],
            [
              105.83219505107586,
              21.00493327900245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9312",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005114913093728,
          "to_latitude": 21.00493327900245,
          "to_longitude": 105.83219505107586,
          "from_longitude": 105.83141795183084
        }
      },
      {
        "id": 9305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163724647001,
            21.00505639470598,
            105.8316387145599,
            21.00506199927673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316387145599,
              21.00506199927673
            ],
            [
              105.83163790192833,
              21.00505890156879
            ],
            [
              105.83163724647001,
              21.00505639470598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9313",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00506199927673,
          "to_latitude": 21.00505639470598,
          "to_longitude": 105.83163724647001,
          "from_longitude": 105.8316387145599
        }
      },
      {
        "id": 9306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83140594306309,
            21.00464675066656,
            105.83219591791861,
            21.00482515897507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140594306309,
              21.00482515897507
            ],
            [
              105.83156862682922,
              21.004788559416397
            ],
            [
              105.83159058774875,
              21.00478361895422
            ],
            [
              105.83159642862393,
              21.004782305124955
            ],
            [
              105.8316039987018,
              21.004780601685102
            ],
            [
              105.83180054975988,
              21.004734812294554
            ],
            [
              105.83195399160762,
              21.004700624894948
            ],
            [
              105.83195569778438,
              21.0047002462323
            ],
            [
              105.8319698630758,
              21.004697100602243
            ],
            [
              105.8319722253887,
              21.00469657421593
            ],
            [
              105.83219591791861,
              21.00464675066656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9314",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00482515897507,
          "to_latitude": 21.00464675066656,
          "to_longitude": 105.83219591791861,
          "from_longitude": 105.83140594306309
        }
      },
      {
        "id": 9307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83159403736902,
            21.004773442610997,
            105.83159642862393,
            21.004782305124955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83159642862393,
              21.004782305124955
            ],
            [
              105.83159575496273,
              21.00477981099598
            ],
            [
              105.83159403736902,
              21.004773442610997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A5,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9315",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004782305124955,
          "to_latitude": 21.004773442610997,
          "to_longitude": 105.83159403736902,
          "from_longitude": 105.83159642862393
        }
      },
      {
        "id": 9308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83137432814719,
            21.00437532311502,
            105.83214196814843,
            21.00454382294162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83137432814719,
              21.00454382294162
            ],
            [
              105.83152472154615,
              21.004509770950765
            ],
            [
              105.83152678792656,
              21.00450930291972
            ],
            [
              105.83152850750308,
              21.00450891245395
            ],
            [
              105.8315350121439,
              21.0045074409325
            ],
            [
              105.83153587001597,
              21.004507247063785
            ],
            [
              105.83154985723397,
              21.00450407975824
            ],
            [
              105.8315531974424,
              21.004503321890326
            ],
            [
              105.83177376410947,
              21.00445338410641
            ],
            [
              105.83190918275389,
              21.00442588199606
            ],
            [
              105.83191298908505,
              21.004425055907348
            ],
            [
              105.83191412752275,
              21.004424808278294
            ],
            [
              105.83193697317338,
              21.004419846287377
            ],
            [
              105.83194004936254,
              21.004419178218633
            ],
            [
              105.83214196814843,
              21.00437532311502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9316",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 81.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00454382294162,
          "to_latitude": 21.00437532311502,
          "to_longitude": 105.83214196814843,
          "from_longitude": 105.83137432814719
        }
      },
      {
        "id": 9309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154995078051,
            21.004490756713952,
            105.8315531974424,
            21.004503321890326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8315531974424,
              21.004503321890326
            ],
            [
              105.83155135268004,
              21.004497089609814
            ],
            [
              105.83154995078051,
              21.004490756713952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A6,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9317",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004503321890326,
          "to_latitude": 21.004490756713952,
          "to_longitude": 105.83154995078051,
          "from_longitude": 105.8315531974424
        }
      },
      {
        "id": 9310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83131815493016,
            21.00407392378557,
            105.83212182638725,
            21.004249441413798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83131815493016,
              21.004249441413798
            ],
            [
              105.83145996317677,
              21.00421847167858
            ],
            [
              105.83148477524894,
              21.00421305846494
            ],
            [
              105.83148514512123,
              21.004212978077344
            ],
            [
              105.83150479338751,
              21.00420868660384
            ],
            [
              105.83151488075104,
              21.0042064831286
            ],
            [
              105.83171999138906,
              21.004161682612754
            ],
            [
              105.83184260274132,
              21.004134905210037
            ],
            [
              105.83186753868574,
              21.004129459722996
            ],
            [
              105.8318707406999,
              21.004128760330985
            ],
            [
              105.83189262353272,
              21.004123980988414
            ],
            [
              105.83212182638725,
              21.00407392378557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7,3_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9318",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004249441413798,
          "to_latitude": 21.00407392378557,
          "to_longitude": 105.83212182638725,
          "from_longitude": 105.83131815493016
        }
      },
      {
        "id": 9311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150985955433,
            21.004185775002764,
            105.83151488075104,
            21.0042064831286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83151488075104,
              21.0042064831286
            ],
            [
              105.83151016330744,
              21.00418695493881
            ],
            [
              105.83150985955433,
              21.004185775002764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7,3_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9319",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0042064831286,
          "to_latitude": 21.004185775002764,
          "to_longitude": 105.83150985955433,
          "from_longitude": 105.83151488075104
        }
      },
      {
        "id": 9312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202017558952,
            21.005152788086793,
            105.83233213527829,
            21.005214503487405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202017558952,
              21.005214503487405
            ],
            [
              105.83203763790002,
              21.005210953345628
            ],
            [
              105.83233213527829,
              21.005152788086793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9320",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005214503487405,
          "to_latitude": 21.005152788086793,
          "to_longitude": 105.83233213527829,
          "from_longitude": 105.83202017558952
        }
      },
      {
        "id": 9313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83228967618588,
            21.00489709009876,
            105.83238625175525,
            21.00546837073089
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83238625175525,
              21.00546837073089
            ],
            [
              105.83233347450692,
              21.005160647733153
            ],
            [
              105.83232864769062,
              21.005157498304975
            ],
            [
              105.83233213527829,
              21.005152788086793
            ],
            [
              105.83233052426452,
              21.00514344396269
            ],
            [
              105.83232979282958,
              21.005139033473974
            ],
            [
              105.83228967618588,
              21.00489709009876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT4,3_Ngõ 43, Đông Tác",
          "maTaiSan": "04.O17.DODV.9321",
          "diaChiLapD": "Ngõ 43, Đông Tác",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00546837073089,
          "to_latitude": 21.00489709009876,
          "to_longitude": 105.83228967618588,
          "from_longitude": 105.83238625175525
        }
      },
      {
        "id": 9314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174282245349,
            21.005670823149856,
            105.83176648766029,
            21.005716042383824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176648766029,
              21.00571407950973
            ],
            [
              105.83175887665324,
              21.005716042383824
            ],
            [
              105.83174348301422,
              21.005672687793247
            ],
            [
              105.83174282245349,
              21.005670823149856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1,6_Đông Tác",
          "maTaiSan": "04.O17.DODV.9322",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00571407950973,
          "to_latitude": 21.005670823149856,
          "to_longitude": 105.83174282245349,
          "from_longitude": 105.83176648766029
        }
      },
      {
        "id": 9315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174687970194,
            21.005669273256956,
            105.8317635247851,
            21.005705273022617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317635247851,
              21.005704132309184
            ],
            [
              105.83175910167994,
              21.005705273022617
            ],
            [
              105.83174754366877,
              21.00567123181921
            ],
            [
              105.83174687970194,
              21.005669273256956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1,5_Đông Tác",
          "maTaiSan": "04.O17.DODV.9323",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005704132309184,
          "to_latitude": 21.005669273256956,
          "to_longitude": 105.83174687970194,
          "from_longitude": 105.8317635247851
        }
      },
      {
        "id": 9316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187765011242,
            21.005622575918878,
            105.83188810465968,
            21.005656577345185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188810465968,
              21.005656577345185
            ],
            [
              105.83187933100665,
              21.005628045769182
            ],
            [
              105.83187765011242,
              21.005622575918878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1,3_Đông Tác",
          "maTaiSan": "04.O17.DODV.9324",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005656577345185,
          "to_latitude": 21.005622575918878,
          "to_longitude": 105.83187765011242,
          "from_longitude": 105.83188810465968
        }
      },
      {
        "id": 9317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187465404102,
            21.005623646434334,
            105.83189867372425,
            21.005667674050283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189867372425,
              21.005664906719403
            ],
            [
              105.83188761790345,
              21.005667674050283
            ],
            [
              105.83187622944793,
              21.005629000284113
            ],
            [
              105.83187465404102,
              21.005623646434334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1,4_Đông Tác",
          "maTaiSan": "04.O17.DODV.9325",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005664906719403,
          "to_latitude": 21.005623646434334,
          "to_longitude": 105.83187465404102,
          "from_longitude": 105.83189867372425
        }
      },
      {
        "id": 9318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202633832222,
            21.00556948095191,
            105.83205785305441,
            21.00560069773138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83205785305441,
              21.00560069773138
            ],
            [
              105.83204950266648,
              21.00557183170832
            ],
            [
              105.83203020958823,
              21.005578280222572
            ],
            [
              105.8320269014001,
              21.005570764393116
            ],
            [
              105.83202633832222,
              21.00556948095191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1,1_Đông Tác",
          "maTaiSan": "04.O17.DODV.9326",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00560069773138,
          "to_latitude": 21.00556948095191,
          "to_longitude": 105.83202633832222,
          "from_longitude": 105.83205785305441
        }
      },
      {
        "id": 9319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202381167953,
            21.005570383884695,
            105.83205951918511,
            21.005609566348042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83205951918511,
              21.005606799910147
            ],
            [
              105.8320484633669,
              21.005609566348042
            ],
            [
              105.83203944808405,
              21.00557840125179
            ],
            [
              105.83202806494299,
              21.00558163625639
            ],
            [
              105.83202413383593,
              21.00557123856809
            ],
            [
              105.83202381167953,
              21.005570383884695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1,2_Đông Tác",
          "maTaiSan": "04.O17.DODV.9327",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005606799910147,
          "to_latitude": 21.005570383884695,
          "to_longitude": 105.83202381167953,
          "from_longitude": 105.83205951918511
        }
      },
      {
        "id": 9320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320622847617,
            21.005313629985952,
            105.83210859424337,
            21.005332377278435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83210716463905,
              21.005313629985952
            ],
            [
              105.83210859424337,
              21.00531766764284
            ],
            [
              105.83208260313388,
              21.005325923748696
            ],
            [
              105.8320802593367,
              21.005326668626967
            ],
            [
              105.8320622847617,
              21.005332377278435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9328",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005313629985952,
          "to_latitude": 21.005332377278435,
          "to_longitude": 105.8320622847617,
          "from_longitude": 105.83210716463905
        }
      },
      {
        "id": 9321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209215067198,
            21.005391737777042,
            105.83213763534243,
            21.00541596212966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321340520649,
              21.005391737777042
            ],
            [
              105.83213763534243,
              21.00540185445381
            ],
            [
              105.83211280801324,
              21.0054100976487
            ],
            [
              105.83210997763092,
              21.005411038196762
            ],
            [
              105.83209396740892,
              21.00541539866678
            ],
            [
              105.83209215067198,
              21.00541596212966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,6_Đông Tác",
          "maTaiSan": "04.O17.DODV.9329",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005391737777042,
          "to_latitude": 21.00541596212966,
          "to_longitude": 105.83209215067198,
          "from_longitude": 105.8321340520649
        }
      },
      {
        "id": 9322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83214351330739,
            21.00553639376673,
            105.83218607224313,
            21.0055597069187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83218248799672,
              21.00553639376673
            ],
            [
              105.83218607224313,
              21.00554651043739
            ],
            [
              105.8321594941673,
              21.00555490581295
            ],
            [
              105.83215881139131,
              21.005555106957846
            ],
            [
              105.83215863552262,
              21.005555828592115
            ],
            [
              105.83215794010745,
              21.00555600541171
            ],
            [
              105.83215693252286,
              21.00555626143507
            ],
            [
              105.83215606698711,
              21.005555767989502
            ],
            [
              105.83214351330739,
              21.0055597069187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,3_Đông Tác",
          "maTaiSan": "04.O17.DODV.9330",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00553639376673,
          "to_latitude": 21.0055597069187,
          "to_longitude": 105.83214351330739,
          "from_longitude": 105.83218248799672
        }
      },
      {
        "id": 9323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217300595483,
            21.005625038007786,
            105.83222052066182,
            21.005645354587102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8322185428704,
              21.005625038007786
            ],
            [
              105.83222052066182,
              21.00563062111229
            ],
            [
              105.83218998884689,
              21.00563876576132
            ],
            [
              105.83218858770617,
              21.005638994806002
            ],
            [
              105.83218564527462,
              21.0056440139688
            ],
            [
              105.83218485916333,
              21.005645354587102
            ],
            [
              105.83217856011726,
              21.005642105773152
            ],
            [
              105.83217300595483,
              21.005643784930946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,1_Đông Tác",
          "maTaiSan": "04.O17.DODV.9331",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005625038007786,
          "to_latitude": 21.005643784930946,
          "to_longitude": 105.83217300595483,
          "from_longitude": 105.8322185428704
        }
      },
      {
        "id": 9324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190760336313,
            21.00522392164042,
            105.83193271866678,
            21.005297057409212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193271866678,
              21.005294872836814
            ],
            [
              105.83192096036314,
              21.005297057409212
            ],
            [
              105.83191197690519,
              21.005249934852483
            ],
            [
              105.83191345260833,
              21.005247366993316
            ],
            [
              105.83191229370492,
              21.005243056140344
            ],
            [
              105.83191189397317,
              21.005241567771108
            ],
            [
              105.83190995201878,
              21.00524019081117
            ],
            [
              105.83190863169172,
              21.005231052100378
            ],
            [
              105.83190760336313,
              21.00522392164042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9332",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005294872836814,
          "to_latitude": 21.00522392164042,
          "to_longitude": 105.83190760336313,
          "from_longitude": 105.83193271866678
        }
      },
      {
        "id": 9325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190980790202,
            21.005223388800133,
            105.83193449584851,
            21.00529403709441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193449584851,
              21.005292205955392
            ],
            [
              105.83192464105204,
              21.00529403709441
            ],
            [
              105.83191526095548,
              21.005249107703193
            ],
            [
              105.83191698417913,
              21.005246250504985
            ],
            [
              105.83191620783325,
              21.00524223855642
            ],
            [
              105.83191580580396,
              21.00524016219824
            ],
            [
              105.83191323510702,
              21.005239363666615
            ],
            [
              105.83191134133915,
              21.005230542081474
            ],
            [
              105.83190980790202,
              21.005223388800133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3,1_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9333",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005292205955392,
          "to_latitude": 21.005223388800133,
          "to_longitude": 105.83190980790202,
          "from_longitude": 105.83193449584851
        }
      },
      {
        "id": 9326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174827850425,
            21.00526178909239,
            105.83178730993336,
            21.005343526863854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178730993336,
              21.005339678138046
            ],
            [
              105.83176659428405,
              21.005343526863854
            ],
            [
              105.83175406453609,
              21.005283055688352
            ],
            [
              105.8317491703801,
              21.005277126442593
            ],
            [
              105.83174827850425,
              21.005276045123345
            ],
            [
              105.8317520406162,
              21.00527331073696
            ],
            [
              105.83174993745139,
              21.005263151591976
            ],
            [
              105.83174965672887,
              21.00526178909239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3,5_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9334",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005339678138046,
          "to_latitude": 21.00526178909239,
          "to_longitude": 105.83174965672887,
          "from_longitude": 105.83178730993336
        }
      },
      {
        "id": 9327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83175162773263,
            21.005261077339092,
            105.83178010018962,
            21.00533013494652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178010018962,
              21.005328303821013
            ],
            [
              105.83177024635042,
              21.00533013494652
            ],
            [
              105.83175736287693,
              21.005283071182205
            ],
            [
              105.83175202382516,
              21.00527652901375
            ],
            [
              105.83175162773263,
              21.005276044110566
            ],
            [
              105.83175533799495,
              21.005273326235557
            ],
            [
              105.83175310838499,
              21.005262567063188
            ],
            [
              105.83175280098686,
              21.005261077339092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3,4_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9335",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005328303821013,
          "to_latitude": 21.005261077339092,
          "to_longitude": 105.83175280098686,
          "from_longitude": 105.83178010018962
        }
      },
      {
        "id": 9328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202085609688,
            21.00496690659929,
            105.83203093510411,
            21.00500249623691
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203093510411,
              21.00500249623691
            ],
            [
              105.83202367780386,
              21.00497525699546
            ],
            [
              105.83202085609688,
              21.004973770530857
            ],
            [
              105.83202152841803,
              21.004972551501847
            ],
            [
              105.83202243691268,
              21.004970904995552
            ],
            [
              105.83202203766186,
              21.00496932991448
            ],
            [
              105.83202129032138,
              21.00496690659929
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4,5_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9336",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00500249623691,
          "to_latitude": 21.00496690659929,
          "to_longitude": 105.83202129032138,
          "from_longitude": 105.83203093510411
        }
      },
      {
        "id": 9329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83198943032082,
            21.00497409876814,
            105.83200029639845,
            21.005009036338887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200029639845,
              21.005009036338887
            ],
            [
              105.8319926239382,
              21.004981688931856
            ],
            [
              105.83198943032082,
              21.00498130351096
            ],
            [
              105.83199035795214,
              21.004979812266175
            ],
            [
              105.83199151534969,
              21.004977952285177
            ],
            [
              105.83199109586492,
              21.004976544399785
            ],
            [
              105.8319904792153,
              21.00497409876814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4,4_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9337",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005009036338887,
          "to_latitude": 21.00497409876814,
          "to_longitude": 105.8319904792153,
          "from_longitude": 105.83200029639845
        }
      },
      {
        "id": 9330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163582180309,
            21.005056716893236,
            105.83164450288787,
            21.005089735262686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83164450288787,
              21.005089735262686
            ],
            [
              105.83163774689216,
              21.005064598070643
            ],
            [
              105.83163621481611,
              21.005063589427337
            ],
            [
              105.83163654842598,
              21.005062519280656
            ],
            [
              105.83163695290776,
              21.005061220271443
            ],
            [
              105.83163635296016,
              21.005058836879385
            ],
            [
              105.83163582180309,
              21.005056716893236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9338",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005089735262686,
          "to_latitude": 21.005056716893236,
          "to_longitude": 105.83163582180309,
          "from_longitude": 105.83164450288787
        }
      },
      {
        "id": 9331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160647724132,
            21.005063340020907,
            105.8316155524053,
            21.005097349112035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316155524053,
              21.005097349112035
            ],
            [
              105.8316085569209,
              21.005072040572685
            ],
            [
              105.83160710637145,
              21.005070989982386
            ],
            [
              105.83160752985609,
              21.005069475009325
            ],
            [
              105.83160780244867,
              21.005068502709253
            ],
            [
              105.83160686656937,
              21.00506486095796
            ],
            [
              105.83160647724132,
              21.005063340020907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4,1_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9339",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005097349112035,
          "to_latitude": 21.005063340020907,
          "to_longitude": 105.83160647724132,
          "from_longitude": 105.8316155524053
        }
      },
      {
        "id": 9332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83193648740524,
            21.004404679284207,
            105.8319486191745,
            21.004445653244264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319486191745,
              21.004445653244264
            ],
            [
              105.831943779309,
              21.004427130054783
            ],
            [
              105.83193874742526,
              21.004426196327376
            ],
            [
              105.83193697317338,
              21.004419846287377
            ],
            [
              105.83193648740524,
              21.004418106338434
            ],
            [
              105.83194112321631,
              21.004417517356085
            ],
            [
              105.83194068941131,
              21.004416128508147
            ],
            [
              105.83193771822759,
              21.004404679284207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6,5_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9340",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004445653244264,
          "to_latitude": 21.004404679284207,
          "to_longitude": 105.83193771822759,
          "from_longitude": 105.8319486191745
        }
      },
      {
        "id": 9333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83191269542493,
            21.004410080045833,
            105.83192194060976,
            21.004452372681968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83192194060976,
              21.004452372681968
            ],
            [
              105.83191617896196,
              21.00442875980139
            ],
            [
              105.83191340620479,
              21.00442881399923
            ],
            [
              105.83191298908505,
              21.004425055907348
            ],
            [
              105.83191281191175,
              21.004423459869418
            ],
            [
              105.8319150049652,
              21.004423459988953
            ],
            [
              105.8319147815022,
              21.004422189338698
            ],
            [
              105.83191269542493,
              21.004410080045833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6,4_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9341",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004452372681968,
          "to_latitude": 21.004410080045833,
          "to_longitude": 105.83191269542493,
          "from_longitude": 105.83192194060976
        }
      },
      {
        "id": 9334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153450145124,
            21.00449401278977,
            105.83154548997308,
            21.004534389884324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83154548997308,
              21.004534389884324
            ],
            [
              105.83154092380677,
              21.004514217860347
            ],
            [
              105.83153613233641,
              21.0045108442356
            ],
            [
              105.8315350121439,
              21.0045074409325
            ],
            [
              105.83153450145124,
              21.004505888975135
            ],
            [
              105.83153870863083,
              21.00450466983937
            ],
            [
              105.83153794224502,
              21.004502105711364
            ],
            [
              105.83153554179651,
              21.00449401278977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9342",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004534389884324,
          "to_latitude": 21.00449401278977,
          "to_longitude": 105.83153554179651,
          "from_longitude": 105.83154548997308
        }
      },
      {
        "id": 9335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83152694127938,
            21.004496010292893,
            105.83153765327691,
            21.004536058494832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83153765327691,
              21.004536058494832
            ],
            [
              105.83153306483014,
              21.004515684256784
            ],
            [
              105.83152952241566,
              21.004512063368097
            ],
            [
              105.83152850750308,
              21.00450891245395
            ],
            [
              105.83152820987941,
              21.004507970939787
            ],
            [
              105.83153040683807,
              21.004506071560357
            ],
            [
              105.8315289973212,
              21.004502009412327
            ],
            [
              105.83152694127938,
              21.004496010292893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6,1_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9343",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004536058494832,
          "to_latitude": 21.004496010292893,
          "to_longitude": 105.83152694127938,
          "from_longitude": 105.83153765327691
        }
      },
      {
        "id": 9336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83158984603429,
            21.004774397928834,
            105.83159965781512,
            21.0048139347808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83159965781512,
              21.0048139347808
            ],
            [
              105.83159238353178,
              21.004786392116845
            ],
            [
              105.83159003261532,
              21.00478463598962
            ],
            [
              105.83159058774875,
              21.00478361895422
            ],
            [
              105.83159127048775,
              21.00478237006943
            ],
            [
              105.83159096975119,
              21.00478069334451
            ],
            [
              105.83158984603429,
              21.004774397928834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5,2_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9344",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0048139347808,
          "to_latitude": 21.004774397928834,
          "to_longitude": 105.83158984603429,
          "from_longitude": 105.83159965781512
        }
      },
      {
        "id": 9337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83156754699723,
            21.00477959475265,
            105.83157746367328,
            21.004818273931846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157746367328,
              21.004818273931846
            ],
            [
              105.83157021240592,
              21.004791065348098
            ],
            [
              105.83156818944465,
              21.004789822457177
            ],
            [
              105.83156862682922,
              21.004788559416397
            ],
            [
              105.83156927250091,
              21.00478669651896
            ],
            [
              105.83156864453815,
              21.0047841172633
            ],
            [
              105.83156754699723,
              21.00477959475265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5,1_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9345",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004818273931846,
          "to_latitude": 21.00477959475265,
          "to_longitude": 105.83156754699723,
          "from_longitude": 105.83157746367328
        }
      },
      {
        "id": 9338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197033504743,
            21.004685940742387,
            105.83198104505873,
            21.00472768879313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198104505873,
              21.00472768879313
            ],
            [
              105.83197334778232,
              21.00469811685911
            ],
            [
              105.83197176350221,
              21.004697407441785
            ],
            [
              105.8319722253887,
              21.00469657421593
            ],
            [
              105.83197279869407,
              21.004695539026017
            ],
            [
              105.83197245758515,
              21.004694214757414
            ],
            [
              105.83197033504743,
              21.004685940742387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5,5_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9346",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00472768879313,
          "to_latitude": 21.004685940742387,
          "to_longitude": 105.83197033504743,
          "from_longitude": 105.83198104505873
        }
      },
      {
        "id": 9339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83195424937472,
            21.00468969098546,
            105.83196463912833,
            21.004731621248006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83196463912833,
              21.004731621248006
            ],
            [
              105.83195685114701,
              21.0047018230468
            ],
            [
              105.83195496563886,
              21.00470160465033
            ],
            [
              105.83195569778438,
              21.0047002462323
            ],
            [
              105.83195626902595,
              21.004699185762178
            ],
            [
              105.83195595203385,
              21.004697700601394
            ],
            [
              105.83195424937472,
              21.00468969098546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5,4_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9347",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004731621248006,
          "to_latitude": 21.00468969098546,
          "to_longitude": 105.83195424937472,
          "from_longitude": 105.83196463912833
        }
      },
      {
        "id": 9340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189128537107,
            21.00410223678077,
            105.83190430678255,
            21.004156860932625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190430678255,
              21.004156860932625
            ],
            [
              105.83189792726992,
              21.00413057932695
            ],
            [
              105.83189128537107,
              21.0041255049489
            ],
            [
              105.83189262353272,
              21.004123980988414
            ],
            [
              105.83189527118611,
              21.00412096662693
            ],
            [
              105.83189281910931,
              21.004106685056556
            ],
            [
              105.83189205670433,
              21.00410223678077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7,5_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9348",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004156860932625,
          "to_latitude": 21.00410223678077,
          "to_longitude": 105.83189205670433,
          "from_longitude": 105.83190430678255
        }
      },
      {
        "id": 9341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318701942991,
            21.0041068832491,
            105.83188362010362,
            21.004158097396612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188362010362,
              21.004158097396612
            ],
            [
              105.83187722558279,
              21.004133966445096
            ],
            [
              105.8318707406999,
              21.004128760330985
            ],
            [
              105.83187063828954,
              21.004128678637922
            ],
            [
              105.83187456757655,
              21.004124353754104
            ],
            [
              105.83187084647992,
              21.004109493095093
            ],
            [
              105.8318701942991,
              21.0041068832491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7,4_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9349",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004158097396612,
          "to_latitude": 21.0041068832491,
          "to_longitude": 105.8318701942991,
          "from_longitude": 105.83188362010362
        }
      },
      {
        "id": 9342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150447411914,
            21.0041866330834,
            105.83152027531301,
            21.004250136797165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83152027531301,
              21.004250136797165
            ],
            [
              105.83151086319674,
              21.00421258688822
            ],
            [
              105.83150447411914,
              21.00420922016384
            ],
            [
              105.83150479338751,
              21.00420868660384
            ],
            [
              105.83150820521216,
              21.004202974190864
            ],
            [
              105.83150597479876,
              21.004187896692002
            ],
            [
              105.83150578889139,
              21.0041866330834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7,2_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9350",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004250136797165,
          "to_latitude": 21.0041866330834,
          "to_longitude": 105.83150578889139,
          "from_longitude": 105.83152027531301
        }
      },
      {
        "id": 9343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314839656403,
            21.00419123324693,
            105.83149965347751,
            21.004254226189975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83149965347751,
              21.004254226189975
            ],
            [
              105.83149058601057,
              21.004218118854904
            ],
            [
              105.83148414883732,
              21.004214578042042
            ],
            [
              105.83148514512123,
              21.004212978077344
            ],
            [
              105.83148792802713,
              21.004208506157184
            ],
            [
              105.8314843609196,
              21.00419296099427
            ],
            [
              105.8314839656403,
              21.00419123324693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7,1_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9351",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004254226189975,
          "to_latitude": 21.00419123324693,
          "to_longitude": 105.8314839656403,
          "from_longitude": 105.83149965347751
        }
      },
      {
        "id": 9344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83107917944324,
            21.00427927736265,
            105.83109120722571,
            21.004343992111824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109120722571,
              21.004343992111824
            ],
            [
              105.83108415317307,
              21.004310168213152
            ],
            [
              105.83108269679634,
              21.00429989931824
            ],
            [
              105.83107917944324,
              21.00429541661932
            ],
            [
              105.83107961415897,
              21.004294887012463
            ],
            [
              105.83108315399899,
              21.004290576693016
            ],
            [
              105.8310813069758,
              21.004282542481093
            ],
            [
              105.83108055787838,
              21.00427927736265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8,5_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9352",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004343992111824,
          "to_latitude": 21.00427927736265,
          "to_longitude": 105.83108055787838,
          "from_longitude": 105.83109120722571
        }
      },
      {
        "id": 9345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83107411187333,
            21.004280266166138,
            105.83108788067547,
            21.004344610807113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83108788067547,
              21.004344610807113
            ],
            [
              105.83108062471494,
              21.00430107459795
            ],
            [
              105.83107411187333,
              21.004296895557893
            ],
            [
              105.83107479523824,
              21.004295932994747
            ],
            [
              105.83107796675587,
              21.00429146189312
            ],
            [
              105.8310765152539,
              21.00428398394376
            ],
            [
              105.83107579539154,
              21.004280266166138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8,4_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9353",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004344610807113,
          "to_latitude": 21.004280266166138,
          "to_longitude": 105.83107579539154,
          "from_longitude": 105.83108788067547
        }
      },
      {
        "id": 9346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83068530403587,
            21.004364202413388,
            105.83070296984106,
            21.00442934643164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83070296984106,
              21.00442934643164
            ],
            [
              105.83069180163253,
              21.004383470070216
            ],
            [
              105.83068616858336,
              21.004380275754148
            ],
            [
              105.83068530403587,
              21.00437978500554
            ],
            [
              105.8306891446583,
              21.004373857353933
            ],
            [
              105.83068755256211,
              21.004366694409757
            ],
            [
              105.83068753576593,
              21.00436661410458
            ],
            [
              105.83068699627738,
              21.004364202413388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8,2_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9354",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00442934643164,
          "to_latitude": 21.004364202413388,
          "to_longitude": 105.83068699627738,
          "from_longitude": 105.83070296984106
        }
      },
      {
        "id": 9347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83066613254974,
            21.00436851024866,
            105.83068405587123,
            21.004433023649103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068405587123,
              21.004433023649103
            ],
            [
              105.83067271078532,
              21.00438925086064
            ],
            [
              105.83066687269786,
              21.00438446335
            ],
            [
              105.83066613254974,
              21.004383856381192
            ],
            [
              105.83067005285037,
              21.004379638148723
            ],
            [
              105.83066828961763,
              21.004372488684744
            ],
            [
              105.83066787639207,
              21.004370818829383
            ],
            [
              105.8306673076567,
              21.00436851024866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8,1_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9355",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004433023649103,
          "to_latitude": 21.00436851024866,
          "to_longitude": 105.8306673076567,
          "from_longitude": 105.83068405587123
        }
      },
      {
        "id": 9348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83105212102106,
            21.004737286934898,
            105.83106602092103,
            21.004787999350512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83106602092103,
              21.004787999350512
            ],
            [
              105.8310616945982,
              21.00477899543806
            ],
            [
              105.83106399851066,
              21.004773306507833
            ],
            [
              105.83106335673251,
              21.004771010027355
            ],
            [
              105.83106218995115,
              21.00476683468998
            ],
            [
              105.83105940616372,
              21.004764898217164
            ],
            [
              105.83105212102106,
              21.004737286934898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9356",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004787999350512,
          "to_latitude": 21.004737286934898,
          "to_longitude": 105.83105212102106,
          "from_longitude": 105.83106602092103
        }
      },
      {
        "id": 9349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83107647586654,
            21.004733357301944,
            105.83108553198558,
            21.004783406915557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83108553198558,
              21.004783406915557
            ],
            [
              105.83108312098359,
              21.004774446941543
            ],
            [
              105.83108202190184,
              21.004769643531482
            ],
            [
              105.83108491906609,
              21.004768257383933
            ],
            [
              105.83108457467021,
              21.00476616448381
            ],
            [
              105.83108430659689,
              21.004764479469287
            ],
            [
              105.83108195715607,
              21.004764204618798
            ],
            [
              105.83107647586654,
              21.004733357301944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9,1_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9357",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004783406915557,
          "to_latitude": 21.004733357301944,
          "to_longitude": 105.83107647586654,
          "from_longitude": 105.83108553198558
        }
      },
      {
        "id": 9350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83108450630648,
            21.004875827191047,
            105.83109737996273,
            21.00492213249154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109737996273,
              21.00492213249154
            ],
            [
              105.83108739629583,
              21.00488540457741
            ],
            [
              105.83108450630648,
              21.004883570688264
            ],
            [
              105.83108581680696,
              21.004880696545605
            ],
            [
              105.83108536193585,
              21.004878976314203
            ],
            [
              105.83108453460403,
              21.004875827191047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10,1_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9358",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00492213249154,
          "to_latitude": 21.004875827191047,
          "to_longitude": 105.83108453460403,
          "from_longitude": 105.83109737996273
        }
      },
      {
        "id": 9351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83106489892882,
            21.004880290853183,
            105.83107820002081,
            21.004926076601397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83107820002081,
              21.004926076601397
            ],
            [
              105.8310678226703,
              21.00489033964114
            ],
            [
              105.83106489892882,
              21.00488935856209
            ],
            [
              105.83106553909819,
              21.004887890403165
            ],
            [
              105.8310663758731,
              21.0048859705743
            ],
            [
              105.83106607363166,
              21.004884021081324
            ],
            [
              105.83106549893925,
              21.004880290853183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10,3_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9359",
          "diaChiLapD": "Ngõ 1, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004926076601397,
          "to_latitude": 21.004880290853183,
          "to_longitude": 105.83106549893925,
          "from_longitude": 105.83107820002081
        }
      },
      {
        "id": 9352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83069091962723,
            21.004882576451998,
            105.83072442537255,
            21.004894042057813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83072442537255,
              21.004882576451998
            ],
            [
              105.83070991803511,
              21.00488736300483
            ],
            [
              105.8307092576255,
              21.00488865061603
            ],
            [
              105.83070883014948,
              21.004888403411975
            ],
            [
              105.83070782177452,
              21.004887821236522
            ],
            [
              105.83070678123201,
              21.004888204767028
            ],
            [
              105.83069091962723,
              21.004894042057813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11,4_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9360",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004882576451998,
          "to_latitude": 21.004894042057813,
          "to_longitude": 105.83069091962723,
          "from_longitude": 105.83072442537255
        }
      },
      {
        "id": 9353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306836471156,
            21.004861964804956,
            105.83071628737696,
            21.004872351345607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83071628737696,
              21.004861964804956
            ],
            [
              105.83070307793935,
              21.004866117280024
            ],
            [
              105.83070225442162,
              21.004868347752375
            ],
            [
              105.8307020355156,
              21.004868941337293
            ],
            [
              105.83069957180722,
              21.004867137877042
            ],
            [
              105.8306910785126,
              21.004869918815906
            ],
            [
              105.8306836471156,
              21.004872351345607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11,5_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9361",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004861964804956,
          "to_latitude": 21.004872351345607,
          "to_longitude": 105.8306836471156,
          "from_longitude": 105.83071628737696
        }
      },
      {
        "id": 9354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83079686232055,
            21.0051912720143,
            105.83082714394935,
            21.00519960757928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83082714394935,
              21.0051912720143
            ],
            [
              105.83081154118078,
              21.005195567149315
            ],
            [
              105.83080933800971,
              21.005196174033333
            ],
            [
              105.83079686232055,
              21.00519960757928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11,1_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9362",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0051912720143,
          "to_latitude": 21.00519960757928,
          "to_longitude": 105.83079686232055,
          "from_longitude": 105.83082714394935
        }
      },
      {
        "id": 9355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83078846690005,
            21.005168482110452,
            105.83081857063979,
            21.005177849193164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83081857063979,
              21.005168482110452
            ],
            [
              105.83080401568485,
              21.00517301148479
            ],
            [
              105.8308027097916,
              21.00517341798864
            ],
            [
              105.83078846690005,
              21.005177849193164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11,2_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.9363",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005168482110452,
          "to_latitude": 21.005177849193164,
          "to_longitude": 105.83078846690005,
          "from_longitude": 105.83081857063979
        }
      },
      {
        "id": 9356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83114258517647,
            21.005459590581832,
            105.83119122917337,
            21.00547275330659
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114258517647,
              21.00547275330659
            ],
            [
              105.83115670834432,
              21.00546893233446
            ],
            [
              105.83117083342509,
              21.00546510954538
            ],
            [
              105.83119122917337,
              21.005459590581832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,1_Đông Tác",
          "maTaiSan": "04.O17.DODV.9364",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00547275330659,
          "to_latitude": 21.005459590581832,
          "to_longitude": 105.83119122917337,
          "from_longitude": 105.83114258517647
        }
      },
      {
        "id": 9357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83114313845509,
            21.00546119937601,
            105.83119175364713,
            21.005474352928008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114313845509,
              21.005474352928008
            ],
            [
              105.83115727506893,
              21.005470528277247
            ],
            [
              105.83117141455753,
              21.00546670180485
            ],
            [
              105.83119175364713,
              21.00546119937601
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,2_Đông Tác",
          "maTaiSan": "04.O17.DODV.9365",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005474352928008,
          "to_latitude": 21.00546119937601,
          "to_longitude": 105.83119175364713,
          "from_longitude": 105.83114313845509
        }
      },
      {
        "id": 9358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118630931871,
            21.005593807509076,
            105.83123501442732,
            21.005607058158688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83118630931871,
              21.005607058158688
            ],
            [
              105.83120252227013,
              21.005602648008523
            ],
            [
              105.83121873713424,
              21.00559823604105
            ],
            [
              105.83123501442732,
              21.005593807509076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,3_Đông Tác",
          "maTaiSan": "04.O17.DODV.9366",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005607058158688,
          "to_latitude": 21.005593807509076,
          "to_longitude": 105.83123501442732,
          "from_longitude": 105.83118630931871
        }
      },
      {
        "id": 9359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8311874415124,
            21.00559585456856,
            105.8312356827249,
            21.00560898101728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311874415124,
              21.00560898101728
            ],
            [
              105.83120366790486,
              21.005604566285243
            ],
            [
              105.83121989717179,
              21.005600149731187
            ],
            [
              105.8312356827249,
              21.00559585456856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,4_Đông Tác",
          "maTaiSan": "04.O17.DODV.9367",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00560898101728,
          "to_latitude": 21.00559585456856,
          "to_longitude": 105.8312356827249,
          "from_longitude": 105.8311874415124
        }
      },
      {
        "id": 9360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83123550161784,
            21.00574687399688,
            105.83128561132136,
            21.005760433199676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83123550161784,
              21.005760433199676
            ],
            [
              105.83125001570659,
              21.00575650643895
            ],
            [
              105.83126453170823,
              21.005752577861177
            ],
            [
              105.83128561132136,
              21.00574687399688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,5_Đông Tác",
          "maTaiSan": "04.O17.DODV.9368",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005760433199676,
          "to_latitude": 21.00574687399688,
          "to_longitude": 105.83128561132136,
          "from_longitude": 105.83123550161784
        }
      },
      {
        "id": 9361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83123571818234,
            21.0057495074209,
            105.83128901843766,
            21.00576393214341
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83123571818234,
              21.00576393214341
            ],
            [
              105.8312502466741,
              21.00576000079615
            ],
            [
              105.8312647770788,
              21.0057560676319
            ],
            [
              105.83128901843766,
              21.0057495074209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,6_Đông Tác",
          "maTaiSan": "04.O17.DODV.9369",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00576393214341,
          "to_latitude": 21.0057495074209,
          "to_longitude": 105.83128901843766,
          "from_longitude": 105.83123571818234
        }
      },
      {
        "id": 9362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128548758721,
            21.00576878507827,
            105.83131396176987,
            21.005821431405156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128548758721,
              21.005821431405156
            ],
            [
              105.8312932911814,
              21.005807002186987
            ],
            [
              105.83131396176987,
              21.00576878507827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,7_Đông Tác",
          "maTaiSan": "04.O17.DODV.9370",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005821431405156,
          "to_latitude": 21.00576878507827,
          "to_longitude": 105.83131396176987,
          "from_longitude": 105.83128548758721
        }
      },
      {
        "id": 9363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128766465144,
            21.005769572234314,
            105.83131497994545,
            21.005821319610252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128766465144,
              21.005821319610252
            ],
            [
              105.83129474857527,
              21.005807898294638
            ],
            [
              105.83131497994545,
              21.005769572234314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,8_Đông Tác",
          "maTaiSan": "04.O17.DODV.9371",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005821319610252,
          "to_latitude": 21.005769572234314,
          "to_longitude": 105.83131497994545,
          "from_longitude": 105.83128766465144
        }
      },
      {
        "id": 9364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133252657967,
            21.005782791951038,
            105.8313563666564,
            21.005834018486855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8313563666564,
              21.005834018486855
            ],
            [
              105.83134454205623,
              21.00580740217224
            ],
            [
              105.83133344403436,
              21.005784670696144
            ],
            [
              105.83133252657967,
              21.005782791951038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,9_Đông Tác",
          "maTaiSan": "04.O17.DODV.9372",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005834018486855,
          "to_latitude": 21.005782791951038,
          "to_longitude": 105.83133252657967,
          "from_longitude": 105.8313563666564
        }
      },
      {
        "id": 9365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133575224174,
            21.005783576453236,
            105.83135781540341,
            21.005832832699912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83135781540341,
              21.005832832699912
            ],
            [
              105.83134654425153,
              21.005807672392205
            ],
            [
              105.83133575224174,
              21.005783576453236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,10_Đông Tác",
          "maTaiSan": "04.O17.DODV.9373",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005832832699912,
          "to_latitude": 21.005783576453236,
          "to_longitude": 105.83133575224174,
          "from_longitude": 105.83135781540341
        }
      },
      {
        "id": 9366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83151641219321,
            21.00575229311227,
            105.83152854764332,
            21.00578379525218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83152854764332,
              21.00578379525218
            ],
            [
              105.83151707582822,
              21.005754018644755
            ],
            [
              105.83151641219321,
              21.00575229311227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,11_Đông Tác",
          "maTaiSan": "04.O17.DODV.9374",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00578379525218,
          "to_latitude": 21.00575229311227,
          "to_longitude": 105.83151641219321,
          "from_longitude": 105.83152854764332
        }
      },
      {
        "id": 9367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83151807988015,
            21.005751735802946,
            105.8315309784183,
            21.005782948794998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8315309784183,
              21.005782948794998
            ],
            [
              105.8315187165344,
              21.005753279015575
            ],
            [
              105.83151807988015,
              21.005751735802946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12,12_Đông Tác",
          "maTaiSan": "04.O17.DODV.9375",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005782948794998,
          "to_latitude": 21.005751735802946,
          "to_longitude": 105.83151807988015,
          "from_longitude": 105.8315309784183
        }
      },
      {
        "id": 9368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83212327799578,
            21.006225371063778,
            105.83244491007703,
            21.006845213843643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244491007703,
              21.006225371063778
            ],
            [
              105.83219976849374,
              21.006808236598363
            ],
            [
              105.83212378643154,
              21.006844968389885
            ],
            [
              105.83212327799578,
              21.006845213843643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4,5_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9376",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006225371063778,
          "to_latitude": 21.006845213843643,
          "to_longitude": 105.83212327799578,
          "from_longitude": 105.83244491007703
        }
      },
      {
        "id": 9369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434496721657,
            21.002845030446654,
            105.83443156187695,
            21.003007536120926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443156187695,
              21.002845030446654
            ],
            [
              105.83440081541397,
              21.002958046477566
            ],
            [
              105.83438823742323,
              21.003004278335563
            ],
            [
              105.83434496721657,
              21.003007536120926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,77_Phương Mai",
          "maTaiSan": "04.O17.DODV.9377",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002845030446654,
          "to_latitude": 21.003007536120926,
          "to_longitude": 105.83434496721657,
          "from_longitude": 105.83443156187695
        }
      },
      {
        "id": 9370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398980821526,
            21.002844381022136,
            105.83434496721657,
            21.003007536120926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83434496721657,
              21.003007536120926
            ],
            [
              105.83411489853738,
              21.00291450602212
            ],
            [
              105.83398980821526,
              21.002844381022136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,77_Phương Mai",
          "maTaiSan": "04.O17.DODV.9378",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003007536120926,
          "to_latitude": 21.002844381022136,
          "to_longitude": 105.83398980821526,
          "from_longitude": 105.83434496721657
        }
      },
      {
        "id": 9371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611649447786,
            21.002820296447787,
            105.83612216235029,
            21.003075729401164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611649447786,
              21.003075729401164
            ],
            [
              105.836118194293,
              21.002980284425558
            ],
            [
              105.83611889251794,
              21.002941074680827
            ],
            [
              105.83612022979663,
              21.002866019093815
            ],
            [
              105.83612216235029,
              21.002820296447787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,68_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9379",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003075729401164,
          "to_latitude": 21.002820296447787,
          "to_longitude": 105.83612216235029,
          "from_longitude": 105.83611649447786
        }
      },
      {
        "id": 9372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83557404917852,
            21.002692015527163,
            105.83598430573034,
            21.00270824131101
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557404917852,
              21.00270824131101
            ],
            [
              105.83568181715681,
              21.002703918699172
            ],
            [
              105.8357773925082,
              21.002700084919706
            ],
            [
              105.83580941219158,
              21.00269880046917
            ],
            [
              105.83598430573034,
              21.002692015527163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,91_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9380",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00270824131101,
          "to_latitude": 21.002692015527163,
          "to_longitude": 105.83598430573034,
          "from_longitude": 105.83557404917852
        }
      },
      {
        "id": 9373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528064931801,
            21.00268721574795,
            105.83538548868425,
            21.002775497606212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538548868425,
              21.00268721574795
            ],
            [
              105.8353854178675,
              21.002689181514825
            ],
            [
              105.83538243142486,
              21.002773019137084
            ],
            [
              105.83531388126983,
              21.00277478717039
            ],
            [
              105.83528064931801,
              21.002775497606212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D6,4_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9381",
          "diaChiLapD": "Ngõ 28C, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00268721574795,
          "to_latitude": 21.002775497606212,
          "to_longitude": 105.83528064931801,
          "from_longitude": 105.83538548868425
        }
      },
      {
        "id": 9374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597424109728,
            21.00247281482846,
            105.83604582358585,
            21.002473415695118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604582358585,
              21.00247281482846
            ],
            [
              105.8360237222261,
              21.002473001059588
            ],
            [
              105.83601272011339,
              21.002473093032318
            ],
            [
              105.83597424109728,
              21.002473415695118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,1_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9382",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00247281482846,
          "to_latitude": 21.002473415695118,
          "to_longitude": 105.83597424109728,
          "from_longitude": 105.83604582358585
        }
      },
      {
        "id": 9375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598052232277,
            21.002455863475937,
            105.83604556084268,
            21.00245620549328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598052232277,
              21.00245620549328
            ],
            [
              105.83602417372948,
              21.002455975747285
            ],
            [
              105.83604556084268,
              21.002455863475937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,2_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9383",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00245620549328,
          "to_latitude": 21.002455863475937,
          "to_longitude": 105.83604556084268,
          "from_longitude": 105.83598052232277
        }
      },
      {
        "id": 9376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359033029488,
            21.002234965963353,
            105.83590342224224,
            21.00227520435265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359033029488,
              21.00227520435265
            ],
            [
              105.83590339041302,
              21.002245658505988
            ],
            [
              105.83590342224224,
              21.002234965963353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,4_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9384",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00227520435265,
          "to_latitude": 21.002234965963353,
          "to_longitude": 105.83590342224224,
          "from_longitude": 105.8359033029488
        }
      },
      {
        "id": 9377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571724643248,
            21.00223896141952,
            105.83571900869048,
            21.002277631747628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83571724643248,
              21.002277631747628
            ],
            [
              105.83571854841287,
              21.0022490617435
            ],
            [
              105.83571900869048,
              21.00223896141952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D3,8_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9385",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002277631747628,
          "to_latitude": 21.00223896141952,
          "to_longitude": 105.83571900869048,
          "from_longitude": 105.83571724643248
        }
      },
      {
        "id": 9378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83560219871735,
            21.002169413335043,
            105.83560396027352,
            21.002236550627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83560219871735,
              21.002236550627
            ],
            [
              105.83560223181317,
              21.002235238980695
            ],
            [
              105.83560287674398,
              21.002210792718245
            ],
            [
              105.83560316073482,
              21.002199951711344
            ],
            [
              105.83560396027352,
              21.002169413335043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2A,3_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9386",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002236550627,
          "to_latitude": 21.002169413335043,
          "to_longitude": 105.83560396027352,
          "from_longitude": 105.83560219871735
        }
      },
      {
        "id": 9379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83542327762532,
            21.002152120991585,
            105.83578406360625,
            21.002186648717018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83542327762532,
              21.002152120991585
            ],
            [
              105.83552787394167,
              21.00216213108705
            ],
            [
              105.83560396027352,
              21.002169413335043
            ],
            [
              105.83567644736874,
              21.00217635012239
            ],
            [
              105.83568296545255,
              21.00217697386386
            ],
            [
              105.83569847681923,
              21.00217845848412
            ],
            [
              105.8357049949032,
              21.00217908222474
            ],
            [
              105.83578406360625,
              21.002186648717018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2A,3_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9387",
          "diaChiLapD": "Ngõ 28D, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002152120991585,
          "to_latitude": 21.002186648717018,
          "to_longitude": 105.83578406360625,
          "from_longitude": 105.83542327762532
        }
      },
      {
        "id": 9380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83588174526051,
            21.00190552394578,
            105.83588546990883,
            21.00196072842789
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588174526051,
              21.00196072842789
            ],
            [
              105.83588187934984,
              21.001958726221112
            ],
            [
              105.83588546990883,
              21.00190552394578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2B,1_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9388",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00196072842789,
          "to_latitude": 21.00190552394578,
          "to_longitude": 105.83588546990883,
          "from_longitude": 105.83588174526051
        }
      },
      {
        "id": 9381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83587709667707,
            21.001906833469956,
            105.83587998312325,
            21.00196073409402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83587709667707,
              21.00196073409402
            ],
            [
              105.83587998312325,
              21.001906833469956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2B,2_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9389",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00196073409402,
          "to_latitude": 21.001906833469956,
          "to_longitude": 105.83587998312325,
          "from_longitude": 105.83587709667707
        }
      },
      {
        "id": 9382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586755135501,
            21.001906278626713,
            105.8358713916892,
            21.001960745741293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586755135501,
              21.001960745741293
            ],
            [
              105.8358713916892,
              21.001906278626713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2B,3_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9390",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001960745741293,
          "to_latitude": 21.001906278626713,
          "to_longitude": 105.8358713916892,
          "from_longitude": 105.83586755135501
        }
      },
      {
        "id": 9383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555847808793,
            21.001849952144266,
            105.83557676021418,
            21.001968089890656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83555847808793,
              21.001968089890656
            ],
            [
              105.8355595541666,
              21.0019611369954
            ],
            [
              105.83557676021418,
              21.001849952144266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2B,6_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9391",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001968089890656,
          "to_latitude": 21.001849952144266,
          "to_longitude": 105.83557676021418,
          "from_longitude": 105.83555847808793
        }
      },
      {
        "id": 9384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558428051464,
            21.001853677953086,
            105.83559663157591,
            21.001967893683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83558428051464,
              21.001967893683
            ],
            [
              105.83558501508332,
              21.001961104141458
            ],
            [
              105.83559663157591,
              21.001853677953086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2B,5_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9392",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001967893683,
          "to_latitude": 21.001853677953086,
          "to_longitude": 105.83559663157591,
          "from_longitude": 105.83558428051464
        }
      },
      {
        "id": 9385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561751404247,
            21.00185710435437,
            105.8356329671498,
            21.00196767183039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83561751404247,
              21.00196767183039
            ],
            [
              105.83561792380357,
              21.001964737044613
            ],
            [
              105.8356329671498,
              21.00185710435437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2B,4_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9393",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00196767183039,
          "to_latitude": 21.00185710435437,
          "to_longitude": 105.8356329671498,
          "from_longitude": 105.83561751404247
        }
      },
      {
        "id": 9386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528687250258,
            21.001849535090667,
            105.83530453083151,
            21.0019149141358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528687250258,
              21.0019149141358
            ],
            [
              105.83528878939399,
              21.001907814406188
            ],
            [
              105.83530453083151,
              21.001849535090667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D2C,3_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9394",
          "diaChiLapD": "Ngõ 30, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0019149141358,
          "to_latitude": 21.001849535090667,
          "to_longitude": 105.83530453083151,
          "from_longitude": 105.83528687250258
        }
      },
      {
        "id": 9387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83747308710959,
            21.001000455148905,
            105.83753860334811,
            21.001006034485357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753860334811,
              21.001006034485357
            ],
            [
              105.8375312321343,
              21.001005406882154
            ],
            [
              105.83752038220732,
              21.001004482974935
            ],
            [
              105.83747308710959,
              21.001000455148905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9395",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001006034485357,
          "to_latitude": 21.001000455148905,
          "to_longitude": 105.83747308710959,
          "from_longitude": 105.83753860334811
        }
      },
      {
        "id": 9388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743529056137,
            20.999505995416595,
            105.83772910755168,
            20.999520100655058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743529056137,
              20.999520100655058
            ],
            [
              105.83751411761912,
              20.999516316734212
            ],
            [
              105.83772910755168,
              20.999505995416595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,126_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9396",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999520100655058,
          "to_latitude": 20.999505995416595,
          "to_longitude": 105.83772910755168,
          "from_longitude": 105.83743529056137
        }
      },
      {
        "id": 9389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751411761912,
            20.999516316734212,
            105.83751674739544,
            20.999544441084677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751411761912,
              20.999516316734212
            ],
            [
              105.83751530625639,
              20.999529040950048
            ],
            [
              105.83751674739544,
              20.999544441084677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,126_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9397",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999516316734212,
          "to_latitude": 20.999544441084677,
          "to_longitude": 105.83751674739544,
          "from_longitude": 105.83751411761912
        }
      },
      {
        "id": 9390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83700933229909,
            20.99952250846109,
            105.83739530232747,
            20.99954011102223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83700933229909,
              20.99954011102223
            ],
            [
              105.83725000633972,
              20.9995291347321
            ],
            [
              105.83739530232747,
              20.99952250846109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,127_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9398",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99954011102223,
          "to_latitude": 20.99952250846109,
          "to_longitude": 105.83739530232747,
          "from_longitude": 105.83700933229909
        }
      },
      {
        "id": 9391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668113914234,
            20.999543680750264,
            105.83698982314424,
            20.999552822302284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668113914234,
              20.999552822302284
            ],
            [
              105.83696583321803,
              20.99954439121565
            ],
            [
              105.83698982314424,
              20.999543680750264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,128_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9399",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999552822302284,
          "to_latitude": 20.999543680750264,
          "to_longitude": 105.83698982314424,
          "from_longitude": 105.83668113914234
        }
      },
      {
        "id": 9392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83619535814981,
            20.999553405095444,
            105.83666841541404,
            20.99964927653267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619535814981,
              20.99964927653267
            ],
            [
              105.83627442787466,
              20.99959009895028
            ],
            [
              105.83634711036935,
              20.999568451040783
            ],
            [
              105.83665178966007,
              20.99955418409725
            ],
            [
              105.83666841541404,
              20.999553405095444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,129_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9400",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99964927653267,
          "to_latitude": 20.999553405095444,
          "to_longitude": 105.83666841541404,
          "from_longitude": 105.83619535814981
        }
      },
      {
        "id": 9393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665178966007,
            20.99955418409725,
            105.83665327420539,
            20.999578423900132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665178966007,
              20.99955418409725
            ],
            [
              105.83665197814746,
              20.999557284848592
            ],
            [
              105.83665327420539,
              20.999578423900132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,129_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9401",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99955418409725,
          "to_latitude": 20.999578423900132,
          "to_longitude": 105.83665327420539,
          "from_longitude": 105.83665178966007
        }
      },
      {
        "id": 9394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696583321803,
            20.99954439121565,
            105.83696709715225,
            20.999565626163424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696583321803,
              20.99954439121565
            ],
            [
              105.83696615808189,
              20.99954986948959
            ],
            [
              105.83696701648135,
              20.99956426810841
            ],
            [
              105.83696709715225,
              20.999565626163424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,128_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9402",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99954439121565,
          "to_latitude": 20.999565626163424,
          "to_longitude": 105.83696709715225,
          "from_longitude": 105.83696583321803
        }
      },
      {
        "id": 9395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725000633972,
            20.9995291347321,
            105.83725086170726,
            20.99955315994409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725000633972,
              20.9995291347321
            ],
            [
              105.83725012160642,
              20.99953238397158
            ],
            [
              105.83725026552236,
              20.999536458618543
            ],
            [
              105.8372506837638,
              20.999548157851617
            ],
            [
              105.83725086170726,
              20.99955315994409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,127_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9403",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.9995291347321,
          "to_latitude": 20.99955315994409,
          "to_longitude": 105.83725086170726,
          "from_longitude": 105.83725000633972
        }
      },
      {
        "id": 9396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83606085802903,
            20.999658680001815,
            105.83616171075336,
            20.999991198499227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83606085802903,
              20.999991198499227
            ],
            [
              105.83606940171093,
              20.999880270198
            ],
            [
              105.83607795468198,
              20.99984665347595
            ],
            [
              105.83609131732591,
              20.999794130289075
            ],
            [
              105.83611835460161,
              20.99971039309313
            ],
            [
              105.83614140941967,
              20.999682009786152
            ],
            [
              105.83614163773221,
              20.999681728664697
            ],
            [
              105.83616171075336,
              20.999658680001815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,130_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9404",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999991198499227,
          "to_latitude": 20.999658680001815,
          "to_longitude": 105.83616171075336,
          "from_longitude": 105.83606085802903
        }
      },
      {
        "id": 9397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83614140941967,
            20.999682009786152,
            105.83618718393888,
            20.99971134211421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83614140941967,
              20.999682009786152
            ],
            [
              105.83614197990562,
              20.999682376403506
            ],
            [
              105.83618718393888,
              20.99971134211421
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,130_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9405",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999682009786152,
          "to_latitude": 20.99971134211421,
          "to_longitude": 105.83618718393888,
          "from_longitude": 105.83614140941967
        }
      },
      {
        "id": 9398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83582333974718,
            21.000022275004515,
            105.83605899586051,
            21.000296346464122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83582333974718,
              21.000296346464122
            ],
            [
              105.83591928272051,
              21.00012700552649
            ],
            [
              105.83592674169728,
              21.00012785677008
            ],
            [
              105.83599445383257,
              21.00013558825395
            ],
            [
              105.83602496102789,
              21.00012568088209
            ],
            [
              105.83603906045614,
              21.000098368536676
            ],
            [
              105.83605899586051,
              21.000022275004515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,131_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9406",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000296346464122,
          "to_latitude": 21.000022275004515,
          "to_longitude": 105.83605899586051,
          "from_longitude": 105.83582333974718
        }
      },
      {
        "id": 9399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359261990836,
            21.00012785677008,
            105.83592674169728,
            21.00013249569345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592674169728,
              21.00012785677008
            ],
            [
              105.8359262164879,
              21.000132339349886
            ],
            [
              105.8359261990836,
              21.00013249569345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,131_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9407",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00012785677008,
          "to_latitude": 21.00013249569345,
          "to_longitude": 105.8359261990836,
          "from_longitude": 105.83592674169728
        }
      },
      {
        "id": 9400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585342902552,
            21.000132066997708,
            105.83594148547927,
            21.000672591033936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592281968662,
              21.000132066997708
            ],
            [
              105.83592289864328,
              21.000132427900358
            ],
            [
              105.83594148547927,
              21.000216425157475
            ],
            [
              105.83590878251763,
              21.000330339829063
            ],
            [
              105.83587772760453,
              21.000511877249654
            ],
            [
              105.83585342902552,
              21.000672591033936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,132_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9408",
          "diaChiLapD": "Ngõ 36, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000132066997708,
          "to_latitude": 21.000672591033936,
          "to_longitude": 105.83585342902552,
          "from_longitude": 105.83592281968662
        }
      },
      {
        "id": 9401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371394394003,
            20.999319496936348,
            105.83714659832485,
            20.999424712830805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371394394003,
              20.999319496936348
            ],
            [
              105.83713949916908,
              20.9993203836102
            ],
            [
              105.8371404063721,
              20.99933369992354
            ],
            [
              105.83714659832485,
              20.999424712830805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,135_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9409",
          "diaChiLapD": "Ngách 102/25, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999319496936348,
          "to_latitude": 20.999424712830805,
          "to_longitude": 105.83714659832485,
          "from_longitude": 105.8371394394003
        }
      },
      {
        "id": 9402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83705043605626,
            20.999315658170733,
            105.83740867503683,
            20.999343773428695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705043605626,
              20.999343773428695
            ],
            [
              105.83709762772307,
              20.999338489638458
            ],
            [
              105.8371404063721,
              20.99933369992354
            ],
            [
              105.83714788379307,
              20.999332862894857
            ],
            [
              105.83721117030835,
              20.999325776570096
            ],
            [
              105.83729567078701,
              20.999322278270807
            ],
            [
              105.83731599440364,
              20.99932143676071
            ],
            [
              105.83740867503683,
              20.999315658170733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,134_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9410",
          "diaChiLapD": "Ngách 102/25, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999343773428695,
          "to_latitude": 20.999315658170733,
          "to_longitude": 105.83740867503683,
          "from_longitude": 105.83705043605626
        }
      },
      {
        "id": 9403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83709761477076,
            20.99932478234684,
            105.83709762772307,
            20.999338489638458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709762772307,
              20.999338489638458
            ],
            [
              105.83709762276912,
              20.999333461404596
            ],
            [
              105.83709761557262,
              20.999326306084903
            ],
            [
              105.8370976152192,
              20.999325035247878
            ],
            [
              105.83709761477076,
              20.99932478234684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,134_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9411",
          "diaChiLapD": "Ngách 102/25, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999338489638458,
          "to_latitude": 20.99932478234684,
          "to_longitude": 105.83709761477076,
          "from_longitude": 105.83709762772307
        }
      },
      {
        "id": 9404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83643692064332,
            20.999348993551724,
            105.83702555949017,
            20.999392035181256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83643692064332,
              20.999392035181256
            ],
            [
              105.83676164828162,
              20.999373334060152
            ],
            [
              105.83677914320019,
              20.999372326775855
            ],
            [
              105.83681801758817,
              20.999370088074592
            ],
            [
              105.83702555949017,
              20.999348993551724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,136_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9412",
          "diaChiLapD": "Ngách 102/25, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999392035181256,
          "to_latitude": 20.999348993551724,
          "to_longitude": 105.83702555949017,
          "from_longitude": 105.83643692064332
        }
      },
      {
        "id": 9405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676150208954,
            20.999357804712773,
            105.83676164828162,
            20.999373334060152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676150208954,
              20.999357804712773
            ],
            [
              105.8367615132164,
              20.99935911072273
            ],
            [
              105.83676156379667,
              20.99936438892621
            ],
            [
              105.83676164828162,
              20.999373334060152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,136_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9413",
          "diaChiLapD": "Ngách 102/25, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999357804712773,
          "to_latitude": 20.999373334060152,
          "to_longitude": 105.83676164828162,
          "from_longitude": 105.83676150208954
        }
      },
      {
        "id": 9406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813121751984,
            20.999505415769395,
            105.83813276238705,
            20.999575847878248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83813121751984,
              20.999575847878248
            ],
            [
              105.83813144517619,
              20.99956545785617
            ],
            [
              105.83813153025478,
              20.999561746083167
            ],
            [
              105.83813157634305,
              20.999558113985984
            ],
            [
              105.83813181640235,
              20.999548394999465
            ],
            [
              105.83813276238705,
              20.999505415769395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H10,2_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9414",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999575847878248,
          "to_latitude": 20.999505415769395,
          "to_longitude": 105.83813276238705,
          "from_longitude": 105.83813121751984
        }
      },
      {
        "id": 9407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83812780959192,
            20.99950887056964,
            105.83812855057367,
            20.999575820398746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812780959192,
              20.999575820398746
            ],
            [
              105.83812797475733,
              20.999561645265693
            ],
            [
              105.83812801587871,
              20.99955712351571
            ],
            [
              105.83812811332155,
              20.99954839065051
            ],
            [
              105.83812855057367,
              20.99950887056964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H10,1_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9415",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999575820398746,
          "to_latitude": 20.99950887056964,
          "to_longitude": 105.83812855057367,
          "from_longitude": 105.83812780959192
        }
      },
      {
        "id": 9408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853403536308,
            20.99958403977313,
            105.83853626326317,
            20.99963685060381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83853626326317,
              20.99958403977313
            ],
            [
              105.838535757707,
              20.999595298260342
            ],
            [
              105.83853556901444,
              20.999599916478864
            ],
            [
              105.83853403536308,
              20.99963685060381
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9A,2_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9416",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99958403977313,
          "to_latitude": 20.99963685060381,
          "to_longitude": 105.83853403536308,
          "from_longitude": 105.83853626326317
        }
      },
      {
        "id": 9409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853875019378,
            20.99958396867406,
            105.83854207907034,
            20.99963614815393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83854207907034,
              20.99958396867406
            ],
            [
              105.83854133299081,
              20.99959555982982
            ],
            [
              105.83854104492461,
              20.999600123441084
            ],
            [
              105.83853875019378,
              20.99963614815393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9A,3_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9417",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99958396867406,
          "to_latitude": 20.99963614815393,
          "to_longitude": 105.83853875019378,
          "from_longitude": 105.83854207907034
        }
      },
      {
        "id": 9410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876805243507,
            20.99958884853202,
            105.83876929321349,
            20.99964410495051
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83876929321349,
              20.99958884853202
            ],
            [
              105.83876901507885,
              20.999599664221602
            ],
            [
              105.83876891613923,
              20.99960433528825
            ],
            [
              105.83876805243507,
              20.99964410495051
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9A,4_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9418",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99958884853202,
          "to_latitude": 20.99964410495051,
          "to_longitude": 105.83876805243507,
          "from_longitude": 105.83876929321349
        }
      },
      {
        "id": 9411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877099473524,
            20.99958914136297,
            105.83877420500713,
            20.999646352129997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83877420500713,
              20.99958914136297
            ],
            [
              105.83877334224672,
              20.999599642900957
            ],
            [
              105.83877099473524,
              20.999646352129997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H9A,5_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9419",
          "diaChiLapD": "Ngách 74/3, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99958914136297,
          "to_latitude": 20.999646352129997,
          "to_longitude": 105.83877099473524,
          "from_longitude": 105.83877420500713
        }
      },
      {
        "id": 9412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927026881969,
            20.998490559535842,
            105.83930360000046,
            20.99860614047111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83928799637236,
              20.99852942245298
            ],
            [
              105.83928418685971,
              20.99851742923732
            ],
            [
              105.83927652467393,
              20.998493292179763
            ],
            [
              105.83927568703632,
              20.998490559535842
            ],
            [
              105.83927026881969,
              20.99849206302086
            ],
            [
              105.83927810077465,
              20.998518868273283
            ],
            [
              105.83928163967029,
              20.998530972113507
            ],
            [
              105.83928486479213,
              20.998542004468582
            ],
            [
              105.83930360000046,
              20.99860614047111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,1_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9420",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99852942245298,
          "to_latitude": 20.99860614047111,
          "to_longitude": 105.83930360000046,
          "from_longitude": 105.83928799637236
        }
      },
      {
        "id": 9413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927853841278,
            20.998487006411494,
            105.83933600524941,
            20.99860000948297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929318894855,
              20.998528160337774
            ],
            [
              105.83928859100993,
              20.998516387783308
            ],
            [
              105.83927918330404,
              20.998492285524808
            ],
            [
              105.83927853841278,
              20.998490137220816
            ],
            [
              105.83929049371861,
              20.998487006411494
            ],
            [
              105.83930113491624,
              20.998513422071333
            ],
            [
              105.83930583807805,
              20.998525091138976
            ],
            [
              105.83931009551769,
              20.998535656855847
            ],
            [
              105.83933600524941,
              20.99860000948297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,2_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9421",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998528160337774,
          "to_latitude": 20.99860000948297,
          "to_longitude": 105.83933600524941,
          "from_longitude": 105.83929318894855
        }
      },
      {
        "id": 9414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920854830052,
            20.998480005375775,
            105.839537268694,
            20.998561205717063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920854830052,
              20.998561205717063
            ],
            [
              105.83928486479213,
              20.998542004468582
            ],
            [
              105.83929025690304,
              20.99854064833751
            ],
            [
              105.83931009551769,
              20.998535656855847
            ],
            [
              105.83932320792918,
              20.998532358662107
            ],
            [
              105.83941535013847,
              20.998509176493776
            ],
            [
              105.83944107357355,
              20.99850270449705
            ],
            [
              105.839537268694,
              20.998480005375775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4,3_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9422",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998561205717063,
          "to_latitude": 20.998480005375775,
          "to_longitude": 105.839537268694,
          "from_longitude": 105.83920854830052
        }
      },
      {
        "id": 9415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941217639375,
            20.998499249436094,
            105.83941535013847,
            20.998509176493776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83941217639375,
              20.998499249436094
            ],
            [
              105.83941535013847,
              20.998509176493776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_T4,3_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9423",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998499249436094,
          "to_latitude": 20.998509176493776,
          "to_longitude": 105.83941535013847,
          "from_longitude": 105.83941217639375
        }
      },
      {
        "id": 9416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953780631191,
            20.99855337085231,
            105.83955190437202,
            20.99855344037701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83955190437202,
              20.99855337085231
            ],
            [
              105.83953780631191,
              20.99855344037701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,4_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9424",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99855337085231,
          "to_latitude": 20.99855344037701,
          "to_longitude": 105.83953780631191,
          "from_longitude": 105.83955190437202
        }
      },
      {
        "id": 9417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953372602333,
            20.99852953596381,
            105.83953920001534,
            20.9986428391773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953920001534,
              20.99852953596381
            ],
            [
              105.83953780631191,
              20.99855344037701
            ],
            [
              105.83953727767327,
              20.998565029560613
            ],
            [
              105.83953699238596,
              20.998571288513727
            ],
            [
              105.83953635318598,
              20.99858529437075
            ],
            [
              105.83953619634443,
              20.998588722884943
            ],
            [
              105.83953372602333,
              20.9986428391773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_T4,4_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9425",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99852953596381,
          "to_latitude": 20.9986428391773,
          "to_longitude": 105.83953372602333,
          "from_longitude": 105.83953920001534
        }
      },
      {
        "id": 9418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919224350636,
            20.998735610617764,
            105.83920558068674,
            20.998737245266277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919224350636,
              20.998737245266277
            ],
            [
              105.83920558068674,
              20.998735610617764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,168_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9426",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998737245266277,
          "to_latitude": 20.998735610617764,
          "to_longitude": 105.83920558068674,
          "from_longitude": 105.83919224350636
        }
      },
      {
        "id": 9419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914704509485,
            20.998575799722886,
            105.83915249670974,
            20.998754410090573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914704509485,
              20.998575799722886
            ],
            [
              105.8391478032766,
              20.9986006256563
            ],
            [
              105.83915249670974,
              20.998754410090573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,169_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9427",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998575799722886,
          "to_latitude": 20.998754410090573,
          "to_longitude": 105.83915249670974,
          "from_longitude": 105.83914704509485
        }
      },
      {
        "id": 9420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391478032766,
            20.99859852806725,
            105.83915849187287,
            20.9986006256563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391478032766,
              20.9986006256563
            ],
            [
              105.83915849187287,
              20.99859852806725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,169_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9428",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.9986006256563,
          "to_latitude": 20.99859852806725,
          "to_longitude": 105.83915849187287,
          "from_longitude": 105.8391478032766
        }
      },
      {
        "id": 9421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83784082238827,
            20.99908305664069,
            105.83784711641576,
            20.999502321103527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83784082238827,
              20.99908305664069
            ],
            [
              105.83784086793717,
              20.999086909577496
            ],
            [
              105.83784359846565,
              20.999317308117128
            ],
            [
              105.83784711641576,
              20.999502321103527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,138_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9429",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99908305664069,
          "to_latitude": 20.999502321103527,
          "to_longitude": 105.83784711641576,
          "from_longitude": 105.83784082238827
        }
      },
      {
        "id": 9422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779132796472,
            20.999086909577496,
            105.83784086793717,
            20.99908726177322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779132796472,
              20.99908726177322
            ],
            [
              105.83779212914203,
              20.999087256927165
            ],
            [
              105.83781439750253,
              20.999087097658737
            ],
            [
              105.83784086793717,
              20.999086909577496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,138_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9430",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99908726177322,
          "to_latitude": 20.999086909577496,
          "to_longitude": 105.83784086793717,
          "from_longitude": 105.83779132796472
        }
      },
      {
        "id": 9423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749076345065,
            20.999046687031328,
            105.83779115154684,
            20.99912409835516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779115154684,
              20.999046687031328
            ],
            [
              105.83779078778541,
              20.999046997724715
            ],
            [
              105.83777037198878,
              20.999064405807488
            ],
            [
              105.83749076345065,
              20.99912409835516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,137_Ngách 102/19, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9431",
          "diaChiLapD": "Ngách 102/19, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999046687031328,
          "to_latitude": 20.99912409835516,
          "to_longitude": 105.83749076345065,
          "from_longitude": 105.83779115154684
        }
      },
      {
        "id": 9424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778776349729,
            20.999004815584343,
            105.83783734153774,
            20.999005063191415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778776349729,
              20.999005063191415
            ],
            [
              105.83781356192983,
              20.999004934421958
            ],
            [
              105.83783734153774,
              20.999004815584343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,139_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9432",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999005063191415,
          "to_latitude": 20.999004815584343,
          "to_longitude": 105.83783734153774,
          "from_longitude": 105.83778776349729
        }
      },
      {
        "id": 9425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783734153774,
            20.998928537357028,
            105.83791898773696,
            20.99907160097077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783771426299,
              20.99907160097077
            ],
            [
              105.83783734153774,
              20.999004815584343
            ],
            [
              105.83791898773696,
              20.998928537357028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,139_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9433",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99907160097077,
          "to_latitude": 20.998928537357028,
          "to_longitude": 105.83791898773696,
          "from_longitude": 105.83783771426299
        }
      },
      {
        "id": 9426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602701670246,
            21.001097670814495,
            105.83603698608125,
            21.001097866100224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602701670246,
              21.001097866100224
            ],
            [
              105.83603698608125,
              21.001097670814495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_I1,3_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9434",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001097866100224,
          "to_latitude": 21.001097670814495,
          "to_longitude": 105.83603698608125,
          "from_longitude": 105.83602701670246
        }
      },
      {
        "id": 9427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83582360959224,
            21.00106207821554,
            105.83582401375384,
            21.001066535813283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83582360959224,
              21.001066535813283
            ],
            [
              105.83582401375384,
              21.00106207821554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,97_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9435",
          "diaChiLapD": "Ngõ 36, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001066535813283,
          "to_latitude": 21.00106207821554,
          "to_longitude": 105.83582401375384,
          "from_longitude": 105.83582360959224
        }
      },
      {
        "id": 9428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559123212265,
            21.001362883502768,
            105.83596056594423,
            21.00144952471616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559123212265,
              21.001372191139946
            ],
            [
              105.8355916767328,
              21.00137067876374
            ],
            [
              105.8355939706225,
              21.001362883502768
            ],
            [
              105.83573968591188,
              21.0013947764087
            ],
            [
              105.83578191426099,
              21.001404015011186
            ],
            [
              105.83583465975474,
              21.001415730090624
            ],
            [
              105.83596056594423,
              21.00144952471616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,178_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9436",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001372191139946,
          "to_latitude": 21.00144952471616,
          "to_longitude": 105.83596056594423,
          "from_longitude": 105.83559123212265
        }
      },
      {
        "id": 9429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352986493564,
            21.00131392659867,
            105.83557712952293,
            21.00135577107461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352986493564,
              21.00131392659867
            ],
            [
              105.8353408913868,
              21.001320274018745
            ],
            [
              105.83544414616838,
              21.00133578960608
            ],
            [
              105.83557712952293,
              21.00135577107461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,177_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9437",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00131392659867,
          "to_latitude": 21.00135577107461,
          "to_longitude": 105.83557712952293,
          "from_longitude": 105.8352986493564
        }
      },
      {
        "id": 9430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534058947576,
            21.001320274018745,
            105.8353408913868,
            21.00133533047017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353408913868,
              21.001320274018745
            ],
            [
              105.83534072200862,
              21.001328726335004
            ],
            [
              105.83534062168111,
              21.001333685537684
            ],
            [
              105.83534058947576,
              21.00133533047017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,177_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9438",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001320274018745,
          "to_latitude": 21.00133533047017,
          "to_longitude": 105.83534058947576,
          "from_longitude": 105.8353408913868
        }
      },
      {
        "id": 9431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83493260433613,
            21.001251915824685,
            105.83528197140308,
            21.001312276050648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83493260433613,
              21.001251915824685
            ],
            [
              105.83506219795528,
              21.001274306068638
            ],
            [
              105.83508083043924,
              21.001277524985685
            ],
            [
              105.83528197140308,
              21.001312276050648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,176_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9439",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001251915824685,
          "to_latitude": 21.001312276050648,
          "to_longitude": 105.83528197140308,
          "from_longitude": 105.83493260433613
        }
      },
      {
        "id": 9432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835059734712,
            21.001274306068638,
            105.83506219795528,
            21.001289182542557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506219795528,
              21.001274306068638
            ],
            [
              105.83506125622003,
              21.001279995592853
            ],
            [
              105.83506006225313,
              21.001287200161176
            ],
            [
              105.835059734712,
              21.001289182542557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,176_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9440",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001274306068638,
          "to_latitude": 21.001289182542557,
          "to_longitude": 105.835059734712,
          "from_longitude": 105.83506219795528
        }
      },
      {
        "id": 9433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83485000843406,
            21.000594248385582,
            105.83522307413494,
            21.001204630597016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83485000843406,
              21.001204630597016
            ],
            [
              105.83486183914496,
              21.001196161732942
            ],
            [
              105.83488086082052,
              21.001182544436848
            ],
            [
              105.83488487450876,
              21.001137911708042
            ],
            [
              105.83506894026318,
              21.000842022445322
            ],
            [
              105.83522307413494,
              21.000594248385582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,175_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9441",
          "diaChiLapD": "Ngõ 120, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001204630597016,
          "to_latitude": 21.000594248385582,
          "to_longitude": 105.83522307413494,
          "from_longitude": 105.83485000843406
        }
      },
      {
        "id": 9434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352517981153,
            21.00000201663718,
            105.83552363561445,
            21.00054248808141
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352517981153,
              21.00054248808141
            ],
            [
              105.83537574673457,
              21.00029605154577
            ],
            [
              105.83552363561445,
              21.00000201663718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,174_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9442",
          "diaChiLapD": "Ngõ 120, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00054248808141,
          "to_latitude": 21.00000201663718,
          "to_longitude": 105.83552363561445,
          "from_longitude": 105.8352517981153
        }
      },
      {
        "id": 9435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534678642714,
            21.000281993141762,
            105.83537574673457,
            21.00029605154577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534678642714,
              21.000281993141762
            ],
            [
              105.83537574673457,
              21.00029605154577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,174_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9443",
          "diaChiLapD": "Ngõ 120, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000281993141762,
          "to_latitude": 21.00029605154577,
          "to_longitude": 105.83537574673457,
          "from_longitude": 105.83534678642714
        }
      },
      {
        "id": 9436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553725824949,
            20.99963560459939,
            105.83592426860882,
            20.999980442156193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83553725824949,
              20.999980442156193
            ],
            [
              105.8355855947262,
              20.999895490429616
            ],
            [
              105.83566915805193,
              20.99974862755664
            ],
            [
              105.83573346710283,
              20.99963560459939
            ],
            [
              105.83582750165593,
              20.999674629259506
            ],
            [
              105.83592426860882,
              20.999685804829916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,173_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9444",
          "diaChiLapD": "Ngõ 120, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999980442156193,
          "to_latitude": 20.999685804829916,
          "to_longitude": 105.83592426860882,
          "from_longitude": 105.83553725824949
        }
      },
      {
        "id": 9437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83556573102877,
            20.999884108829693,
            105.8355855947262,
            20.999895490429616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83556573102877,
              20.999884108829693
            ],
            [
              105.83556982901403,
              20.999886456973844
            ],
            [
              105.8355808049061,
              20.999892747359013
            ],
            [
              105.8355855947262,
              20.999895490429616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,173_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9445",
          "diaChiLapD": "Ngõ 120, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999884108829693,
          "to_latitude": 20.999895490429616,
          "to_longitude": 105.8355855947262,
          "from_longitude": 105.83556573102877
        }
      },
      {
        "id": 9438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835722987801,
            20.999364895186385,
            105.83591631056329,
            20.99963723061049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835722987801,
              20.999627807914244
            ],
            [
              105.83573796899867,
              20.999635351272193
            ],
            [
              105.83574170464378,
              20.99963723061049
            ],
            [
              105.83582199971634,
              20.999502349650115
            ],
            [
              105.83591631056329,
              20.999364895186385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,172_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9446",
          "diaChiLapD": "Ngõ 120, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999627807914244,
          "to_latitude": 20.999364895186385,
          "to_longitude": 105.83591631056329,
          "from_longitude": 105.835722987801
        }
      },
      {
        "id": 9439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83516050974305,
            20.999353693486416,
            105.83551409081502,
            21.00004714525298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83551409081502,
              20.999353693486416
            ],
            [
              105.83516050974305,
              21.00004714525298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,179_Trường Chinh",
          "maTaiSan": "04.O17.DODV.9447",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999353693486416,
          "to_latitude": 21.00004714525298,
          "to_longitude": 105.83516050974305,
          "from_longitude": 105.83551409081502
        }
      },
      {
        "id": 9440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83564218274904,
            21.00306909415159,
            105.83574831322072,
            21.003069312819406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83564218274904,
              21.00306909415159
            ],
            [
              105.83566753547325,
              21.003069116907863
            ],
            [
              105.83567164168973,
              21.003069126554454
            ],
            [
              105.83574831322072,
              21.003069312819406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4A,4_Ngõ 28A, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9448",
          "diaChiLapD": "Ngõ 28A, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00306909415159,
          "to_latitude": 21.003069312819406,
          "to_longitude": 105.83574831322072,
          "from_longitude": 105.83564218274904
        }
      },
      {
        "id": 9441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83566899892982,
            21.003069126554454,
            105.83567164168973,
            21.00313122184409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567164168973,
              21.003069126554454
            ],
            [
              105.83567158993797,
              21.00307227003394
            ],
            [
              105.83566952971042,
              21.003118062852764
            ],
            [
              105.83566932854852,
              21.003123048742964
            ],
            [
              105.83566899892982,
              21.00313122184409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D4A,4_Ngõ 28A, Lương Định Của",
          "maTaiSan": "04.O17.DODV.9449",
          "diaChiLapD": "Ngõ 28A, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003069126554454,
          "to_latitude": 21.00313122184409,
          "to_longitude": 105.83566899892982,
          "from_longitude": 105.83567164168973
        }
      },
      {
        "id": 9442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83206077330688,
            21.005316956116637,
            105.83209709594045,
            21.005328149440594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209709594045,
              21.005316956116637
            ],
            [
              105.83208121565106,
              21.00532184975659
            ],
            [
              105.83207934128464,
              21.005322427952677
            ],
            [
              105.83206077330688,
              21.005328149440594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,9_Đông Tác",
          "maTaiSan": "04.O17.DODV.9450",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005316956116637,
          "to_latitude": 21.005328149440594,
          "to_longitude": 105.83206077330688,
          "from_longitude": 105.83209709594045
        }
      },
      {
        "id": 9443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209086392031,
            21.00540186490887,
            105.8321238585151,
            21.005412360935704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321238585151,
              21.00540186490887
            ],
            [
              105.83211141351536,
              21.005405973110577
            ],
            [
              105.83210841604242,
              21.005406963249744
            ],
            [
              105.83209346888506,
              21.00541153258683
            ],
            [
              105.83209086392031,
              21.005412360935704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,7_Đông Tác",
          "maTaiSan": "04.O17.DODV.9451",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00540186490887,
          "to_latitude": 21.005412360935704,
          "to_longitude": 105.83209086392031,
          "from_longitude": 105.8321238585151
        }
      },
      {
        "id": 9444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8321419031688,
            21.00554640368404,
            105.83216948223784,
            21.00555520317714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83216948223784,
              21.00554640368404
            ],
            [
              105.83215779466197,
              21.005550244443942
            ],
            [
              105.83215712340812,
              21.005550441919596
            ],
            [
              105.83215694576597,
              21.005551190659318
            ],
            [
              105.83215648301749,
              21.005551348276768
            ],
            [
              105.83215566505191,
              21.005551627760102
            ],
            [
              105.83215489984367,
              21.005551187114307
            ],
            [
              105.8321419031688,
              21.00555520317714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,4_Đông Tác",
          "maTaiSan": "04.O17.DODV.9452",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00554640368404,
          "to_latitude": 21.00555520317714,
          "to_longitude": 105.8321419031688,
          "from_longitude": 105.83216948223784
        }
      },
      {
        "id": 9445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217145673653,
            21.005628490025746,
            105.83220508136421,
            21.00564035965555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220508136421,
              21.005628490025746
            ],
            [
              105.83218881535032,
              21.00563355988512
            ],
            [
              105.83218749126735,
              21.00563398184337
            ],
            [
              105.83218418593317,
              21.005639644974302
            ],
            [
              105.83218376955773,
              21.00564035965555
            ],
            [
              105.83217749004424,
              21.00563716403639
            ],
            [
              105.83217145673653,
              21.005639166182196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2,2_Đông Tác",
          "maTaiSan": "04.O17.DODV.9453",
          "diaChiLapD": "Đông Tác",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005628490025746,
          "to_latitude": 21.005639166182196,
          "to_longitude": 105.83217145673653,
          "from_longitude": 105.83220508136421
        }
      },
      {
        "id": 9446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329022495126,
            21.00678732864167,
            105.83295343869004,
            21.006843854653777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83295343869004,
              21.00678732864167
            ],
            [
              105.8329173142561,
              21.00682786970014
            ],
            [
              105.83291584786035,
              21.00682951442521
            ],
            [
              105.8329022495126,
              21.006843854653777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21,4_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9454",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00678732864167,
          "to_latitude": 21.006843854653777,
          "to_longitude": 105.8329022495126,
          "from_longitude": 105.83295343869004
        }
      },
      {
        "id": 9447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83311870931885,
            21.006977917830167,
            105.83315650996407,
            21.007017479910548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315650996407,
              21.006977917830167
            ],
            [
              105.8331300548256,
              21.007005199209686
            ],
            [
              105.833128972178,
              21.007006316380576
            ],
            [
              105.83311870931885,
              21.007017479910548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21,2_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9455",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006977917830167,
          "to_latitude": 21.007017479910548,
          "to_longitude": 105.83311870931885,
          "from_longitude": 105.83315650996407
        }
      },
      {
        "id": 9448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313487442459,
            21.00699161085276,
            105.83317124506245,
            21.007030770322935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83317124506245,
              21.00699161085276
            ],
            [
              105.83314675668859,
              21.007017974864347
            ],
            [
              105.8331454543939,
              21.00701937762672
            ],
            [
              105.83313487442459,
              21.007030770322935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21,1_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9456",
          "diaChiLapD": "Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00699161085276,
          "to_latitude": 21.007030770322935,
          "to_longitude": 105.83313487442459,
          "from_longitude": 105.83317124506245
        }
      },
      {
        "id": 9449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919403639614,
            20.99923614023864,
            105.83919697328348,
            20.999470584177942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919403639614,
              20.999470584177942
            ],
            [
              105.83919697328348,
              20.99923614023864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,147_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DODV.9457",
          "diaChiLapD": "Ngõ 74, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999470584177942,
          "to_latitude": 20.99923614023864,
          "to_longitude": 105.83919697328348,
          "from_longitude": 105.83919403639614
        }
      },
      {
        "id": 9450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900397134194,
            21.005163105613477,
            105.8390524385953,
            21.00535380306135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390524385953,
              21.005163105613477
            ],
            [
              105.83900397134194,
              21.00535380306135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.7_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9458",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005163105613477,
          "to_latitude": 21.00535380306135,
          "to_longitude": 105.83900397134194,
          "from_longitude": 105.8390524385953
        }
      },
      {
        "id": 9451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83906239897516,
            21.00505202022472,
            105.83908752688713,
            21.00514078117739
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906239897516,
              21.00514078117739
            ],
            [
              105.83908752688713,
              21.00505202022472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E4.10_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DODV.9459",
          "diaChiLapD": "Ngõ 4, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00514078117739,
          "to_latitude": 21.00505202022472,
          "to_longitude": 105.83908752688713,
          "from_longitude": 105.83906239897516
        }
      },
      {
        "id": 9452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83545813524432,
            21.00484408239161,
            105.83555976566832,
            21.005199587766366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83545813524432,
              21.005199587766366
            ],
            [
              105.83555976566832,
              21.00484408239161
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9460",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005199587766366,
          "to_latitude": 21.00484408239161,
          "to_longitude": 105.83555976566832,
          "from_longitude": 105.83545813524432
        }
      },
      {
        "id": 9453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752041157351,
            21.003861953033823,
            105.83775730473329,
            21.00392232936235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775730473329,
              21.00392232936235
            ],
            [
              105.83752041157351,
              21.003861953033823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_E9.1_Phương Mai",
          "maTaiSan": "04.O17.DODV.9461",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00392232936235,
          "to_latitude": 21.003861953033823,
          "to_longitude": 105.83752041157351,
          "from_longitude": 105.83775730473329
        }
      },
      {
        "id": 9454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503479276347,
            21.008301518290853,
            105.83517605334485,
            21.008453902757836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83517605334485,
              21.008301518290853
            ],
            [
              105.83503479276347,
              21.008453902757836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9.5_Ngõ 65, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DODV.9462",
          "diaChiLapD": "Ngõ 65, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008301518290853,
          "to_latitude": 21.008453902757836,
          "to_longitude": 105.83503479276347,
          "from_longitude": 105.83517605334485
        }
      },
      {
        "id": 9455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793110543009,
            21.005954349033917,
            105.83858072751897,
            21.006293858574633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793110543009,
              21.005954349033917
            ],
            [
              105.83793128689712,
              21.00595687446082
            ],
            [
              105.83794254370301,
              21.00611225683444
            ],
            [
              105.83795918123265,
              21.006220432754684
            ],
            [
              105.83797129822644,
              21.006293858574633
            ],
            [
              105.83826428675195,
              21.006259437175842
            ],
            [
              105.83858072751897,
              21.006222939723518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,12_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.9463",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 101,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005954349033917,
          "to_latitude": 21.006222939723518,
          "to_longitude": 105.83858072751897,
          "from_longitude": 105.83793110543009
        }
      },
      {
        "id": 9456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793462185106,
            21.005953970431005,
            105.83858270539902,
            21.006072736217202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793462185106,
              21.005953970431005
            ],
            [
              105.83793474147929,
              21.005955412292355
            ],
            [
              105.83794456323811,
              21.006072736217202
            ],
            [
              105.83820476605887,
              21.00604265737879
            ],
            [
              105.83858270539902,
              21.005999213820616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,11_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DODV.9464",
          "diaChiLapD": "Ngách 4/15, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005953970431005,
          "to_latitude": 21.005999213820616,
          "to_longitude": 105.83858270539902,
          "from_longitude": 105.83793462185106
        }
      },
      {
        "id": 9457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466347165236,
            21.00789601263451,
            105.83467676532403,
            21.00790787395852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467676532403,
              21.00789601263451
            ],
            [
              105.83467621497209,
              21.00789650397873
            ],
            [
              105.834670239566,
              21.007901835218973
            ],
            [
              105.83466347165236,
              21.00790787395852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12.3_Lương Định Của",
          "maTaiSan": "04.O17.DODV.9465",
          "diaChiLapD": "Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00789601263451,
          "to_latitude": 21.00790787395852,
          "to_longitude": 105.83466347165236,
          "from_longitude": 105.83467676532403
        }
      },
      {
        "id": 9458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82655213698303,
            21.002582547905106,
            105.82655979725561,
            21.002727706301553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82655213698303,
              21.002582547905106
            ],
            [
              105.82655225093535,
              21.002584551613147
            ],
            [
              105.82655979725561,
              21.002727706301553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT205_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9466",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002582547905106,
          "to_latitude": 21.002727706301553,
          "to_longitude": 105.82655979725561,
          "from_longitude": 105.82655213698303
        }
      },
      {
        "id": 9459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82637066070978,
            21.002711598809928,
            105.82655979725561,
            21.002727706301553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82655979725561,
              21.002727706301553
            ],
            [
              105.82637066070978,
              21.002711598809928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT205_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9467",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002727706301553,
          "to_latitude": 21.002711598809928,
          "to_longitude": 105.82637066070978,
          "from_longitude": 105.82655979725561
        }
      },
      {
        "id": 9460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82655225093535,
            21.002582117260495,
            105.82656643189135,
            21.002584551613147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82655225093535,
              21.002584551613147
            ],
            [
              105.82656580612381,
              21.002582938561115
            ],
            [
              105.82656643189135,
              21.002582865973878
            ],
            [
              105.82656641911154,
              21.002582117260495
            ],
            [
              105.82656581376631,
              21.002582230393795
            ],
            [
              105.82656578687488,
              21.002582237750197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT205_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9468",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002584551613147,
          "to_latitude": 21.002582237750197,
          "to_longitude": 105.82656578687488,
          "from_longitude": 105.82655225093535
        }
      },
      {
        "id": 9461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82657653784258,
            21.002730234431954,
            105.82692813164243,
            21.00281108346656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82657653784258,
              21.002730234431954
            ],
            [
              105.82669025914238,
              21.002761701557816
            ],
            [
              105.82676231287144,
              21.00278163898068
            ],
            [
              105.8267985858759,
              21.002791676484986
            ],
            [
              105.82692813164243,
              21.00281108346656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT203_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9469",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002730234431954,
          "to_latitude": 21.00281108346656,
          "to_longitude": 105.82692813164243,
          "from_longitude": 105.82657653784258
        }
      },
      {
        "id": 9462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82669025914238,
            21.002754991388557,
            105.82669213820063,
            21.002761701557816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82669025914238,
              21.002761701557816
            ],
            [
              105.82669148636109,
              21.002757315848477
            ],
            [
              105.82669213820063,
              21.002754991388557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT203_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9470",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002761701557816,
          "to_latitude": 21.002754991388557,
          "to_longitude": 105.82669213820063,
          "from_longitude": 105.82669025914238
        }
      },
      {
        "id": 9463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82697189540512,
            21.002813230077855,
            105.82717641062867,
            21.002840921017643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82697189540512,
              21.002813230077855
            ],
            [
              105.82707415253265,
              21.00282707648434
            ],
            [
              105.82717641062867,
              21.002840921017643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT184_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9471",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002813230077855,
          "to_latitude": 21.002840921017643,
          "to_longitude": 105.82717641062867,
          "from_longitude": 105.82697189540512
        }
      },
      {
        "id": 9464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82707415253265,
            21.002811523162947,
            105.82707595976105,
            21.00282707648434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82707415253265,
              21.00282707648434
            ],
            [
              105.8270759137947,
              21.002811909064302
            ],
            [
              105.82707595976105,
              21.002811523162947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT184_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9472",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00282707648434,
          "to_latitude": 21.002811523162947,
          "to_longitude": 105.82707595976105,
          "from_longitude": 105.82707415253265
        }
      },
      {
        "id": 9465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82707982107125,
            21.00282856892569,
            105.8271939762602,
            21.003217783207948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8271939762602,
              21.00282856892569
            ],
            [
              105.8271939745157,
              21.002828601450354
            ],
            [
              105.82719237285669,
              21.002836599181567
            ],
            [
              105.82718220198824,
              21.002887525589077
            ],
            [
              105.82717024048654,
              21.00294026082948
            ],
            [
              105.82714785304039,
              21.003045115973507
            ],
            [
              105.82719342900224,
              21.003063491936977
            ],
            [
              105.82718408579231,
              21.00313701848391
            ],
            [
              105.82708721781701,
              21.003134532948234
            ],
            [
              105.82707982107125,
              21.003217783207948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT183_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9473",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00282856892569,
          "to_latitude": 21.003217783207948,
          "to_longitude": 105.82707982107125,
          "from_longitude": 105.8271939762602
        }
      },
      {
        "id": 9466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82717024048654,
            21.00294026082948,
            105.82730207201095,
            21.002954123243626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82717024048654,
              21.00294026082948
            ],
            [
              105.82730207201095,
              21.002954123243626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT183_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9474",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00294026082948,
          "to_latitude": 21.002954123243626,
          "to_longitude": 105.82730207201095,
          "from_longitude": 105.82717024048654
        }
      },
      {
        "id": 9467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82721130911733,
            21.002846234911708,
            105.82744501953503,
            21.0028756515126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82721130911733,
              21.002846234911708
            ],
            [
              105.8273260727505,
              21.002860679740973
            ],
            [
              105.82744501953503,
              21.0028756515126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT181_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9475",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002846234911708,
          "to_latitude": 21.0028756515126,
          "to_longitude": 105.82744501953503,
          "from_longitude": 105.82721130911733
        }
      },
      {
        "id": 9468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8273260727505,
            21.002848021216916,
            105.82732807481432,
            21.002860679740973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8273260727505,
              21.002860679740973
            ],
            [
              105.82732699850284,
              21.00285482774878
            ],
            [
              105.82732807481432,
              21.002848021216916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT181_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9476",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002860679740973,
          "to_latitude": 21.002848021216916,
          "to_longitude": 105.82732807481432,
          "from_longitude": 105.8273260727505
        }
      },
      {
        "id": 9469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8274968537729,
            21.002881200050734,
            105.82793850424602,
            21.002899299112677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8274968537729,
              21.002881200050734
            ],
            [
              105.82765386462845,
              21.00289434455411
            ],
            [
              105.8277220581221,
              21.00289553127654
            ],
            [
              105.82793850424602,
              21.002899299112677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT178_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9477",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002881200050734,
          "to_latitude": 21.002899299112677,
          "to_longitude": 105.82793850424602,
          "from_longitude": 105.8274968537729
        }
      },
      {
        "id": 9470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772199719781,
            21.002890588211024,
            105.8277220581221,
            21.00289553127654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277220581221,
              21.00289553127654
            ],
            [
              105.82772199719781,
              21.002890588211024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT178_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9478",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00289553127654,
          "to_latitude": 21.002890588211024,
          "to_longitude": 105.82772199719781,
          "from_longitude": 105.8277220581221
        }
      },
      {
        "id": 9471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82802035337761,
            21.002884078609313,
            105.82829500053444,
            21.002892146635638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82802035337761,
              21.002892146635638
            ],
            [
              105.82815958427591,
              21.002891329273083
            ],
            [
              105.82823900024013,
              21.00289086337713
            ],
            [
              105.82829500053444,
              21.002884078609313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT165_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9479",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002892146635638,
          "to_latitude": 21.002884078609313,
          "to_longitude": 105.82829500053444,
          "from_longitude": 105.82802035337761
        }
      },
      {
        "id": 9472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8281594275481,
            21.002884191832642,
            105.82815958427591,
            21.002891329273083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82815958427591,
              21.002891329273083
            ],
            [
              105.82815946341974,
              21.002885812047342
            ],
            [
              105.8281594275481,
              21.002884191832642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT165_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9480",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002891329273083,
          "to_latitude": 21.002884191832642,
          "to_longitude": 105.8281594275481,
          "from_longitude": 105.82815958427591
        }
      },
      {
        "id": 9473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82836587947058,
            21.002863296364605,
            105.8284521861425,
            21.003242547760223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82836587947058,
              21.002863296364605
            ],
            [
              105.828366608576,
              21.002865913980976
            ],
            [
              105.82843481882149,
              21.003110278835972
            ],
            [
              105.8284521861425,
              21.003242547760223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9481",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002863296364605,
          "to_latitude": 21.003242547760223,
          "to_longitude": 105.8284521861425,
          "from_longitude": 105.82836587947058
        }
      },
      {
        "id": 9474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284521861425,
            21.003242547760223,
            105.82845830040993,
            21.003306627240168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284521861425,
              21.003242547760223
            ],
            [
              105.82845830040993,
              21.003306627240168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9482",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003242547760223,
          "to_latitude": 21.003306627240168,
          "to_longitude": 105.82845830040993,
          "from_longitude": 105.8284521861425
        }
      },
      {
        "id": 9475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284521861425,
            21.003233481066268,
            105.82858358268332,
            21.003242547760223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284521861425,
              21.003242547760223
            ],
            [
              105.82858358268332,
              21.003233481066268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9483",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003242547760223,
          "to_latitude": 21.003233481066268,
          "to_longitude": 105.82858358268332,
          "from_longitude": 105.8284521861425
        }
      },
      {
        "id": 9476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832184079989,
            21.003110278835972,
            105.82843481882149,
            21.003133952186513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82843481882149,
              21.003110278835972
            ],
            [
              105.82832184079989,
              21.003133952186513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9484",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003110278835972,
          "to_latitude": 21.003133952186513,
          "to_longitude": 105.82832184079989,
          "from_longitude": 105.82843481882149
        }
      },
      {
        "id": 9477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832184079989,
            21.003133952186513,
            105.82833187647131,
            21.003198897752547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832184079989,
              21.003133952186513
            ],
            [
              105.82833187647131,
              21.003198897752547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9485",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003133952186513,
          "to_latitude": 21.003198897752547,
          "to_longitude": 105.82833187647131,
          "from_longitude": 105.82832184079989
        }
      },
      {
        "id": 9478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829914498531,
            21.003086402056088,
            105.82832184079989,
            21.003136052900775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832184079989,
              21.003133952186513
            ],
            [
              105.82831074389908,
              21.003136052900775
            ],
            [
              105.82829914498531,
              21.003086402056088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9486",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003133952186513,
          "to_latitude": 21.003086402056088,
          "to_longitude": 105.82829914498531,
          "from_longitude": 105.82832184079989
        }
      },
      {
        "id": 9479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829619144961,
            21.003198897752547,
            105.82833187647131,
            21.003205156450186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82833187647131,
              21.003198897752547
            ],
            [
              105.82829619144961,
              21.003205156450186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT166_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9487",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003198897752547,
          "to_latitude": 21.003205156450186,
          "to_longitude": 105.82829619144961,
          "from_longitude": 105.82833187647131
        }
      },
      {
        "id": 9480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82838458712146,
            21.002837159036407,
            105.82853822769108,
            21.002867746745252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82838458712146,
              21.002867746745252
            ],
            [
              105.82846250110894,
              21.00285185461619
            ],
            [
              105.82850158347273,
              21.00284388218626
            ],
            [
              105.82853822769108,
              21.002837159036407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9488",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002867746745252,
          "to_latitude": 21.002837159036407,
          "to_longitude": 105.82853822769108,
          "from_longitude": 105.82838458712146
        }
      },
      {
        "id": 9481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82845965158705,
            21.00284479260544,
            105.82846250110894,
            21.00285185461619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82846250110894,
              21.00285185461619
            ],
            [
              105.82845965158705,
              21.00284479260544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9489",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00285185461619,
          "to_latitude": 21.00284479260544,
          "to_longitude": 105.82845965158705,
          "from_longitude": 105.82846250110894
        }
      },
      {
        "id": 9482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859579349312,
            21.002821423700304,
            105.82866115567298,
            21.003155969654422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859579349312,
              21.002821423700304
            ],
            [
              105.82866115567298,
              21.003155969654422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT168_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9490",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002821423700304,
          "to_latitude": 21.003155969654422,
          "to_longitude": 105.82866115567298,
          "from_longitude": 105.82859579349312
        }
      },
      {
        "id": 9483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859952493361,
            21.002800087470604,
            105.8288377497104,
            21.002824807088288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859952493361,
              21.002824807088288
            ],
            [
              105.82871877576203,
              21.002812433098438
            ],
            [
              105.8288377497104,
              21.002800087470604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT170_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9491",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002824807088288,
          "to_latitude": 21.002800087470604,
          "to_longitude": 105.8288377497104,
          "from_longitude": 105.82859952493361
        }
      },
      {
        "id": 9484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871841616607,
            21.002809322329266,
            105.82871877576203,
            21.002812433098438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82871877576203,
              21.002812433098438
            ],
            [
              105.82871841616607,
              21.002809322329266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT170_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9492",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002812433098438,
          "to_latitude": 21.002809322329266,
          "to_longitude": 105.82871841616607,
          "from_longitude": 105.82871877576203
        }
      },
      {
        "id": 9485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903879076466,
            21.002714284774314,
            105.82931520039905,
            21.00275860770898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903879076466,
              21.00275860770898
            ],
            [
              105.8290459743518,
              21.00275747078382
            ],
            [
              105.82931520039905,
              21.002714284774314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT173_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9493",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00275860770898,
          "to_latitude": 21.002714284774314,
          "to_longitude": 105.82931520039905,
          "from_longitude": 105.82903879076466
        }
      },
      {
        "id": 9486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931520039905,
            21.002714284774314,
            105.82934605347214,
            21.002867758877724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82931520039905,
              21.002714284774314
            ],
            [
              105.82934605347214,
              21.002867758877724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT173_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9494",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002714284774314,
          "to_latitude": 21.002867758877724,
          "to_longitude": 105.82934605347214,
          "from_longitude": 105.82931520039905
        }
      },
      {
        "id": 9487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82658052139091,
            21.002622299810245,
            105.8265991228934,
            21.002723023582153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265820897018,
              21.002622299810245
            ],
            [
              105.82658052139091,
              21.00267845203472
            ],
            [
              105.8265991228934,
              21.002723023582153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT204_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9495",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002622299810245,
          "to_latitude": 21.002723023582153,
          "to_longitude": 105.8265991228934,
          "from_longitude": 105.8265820897018
        }
      },
      {
        "id": 9488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265991228934,
            21.002723023582153,
            105.82674098748804,
            21.00275979594535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265991228934,
              21.002723023582153
            ],
            [
              105.82674098748804,
              21.00275979594535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT204_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9496",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002723023582153,
          "to_latitude": 21.00275979594535,
          "to_longitude": 105.82674098748804,
          "from_longitude": 105.8265991228934
        }
      },
      {
        "id": 9489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82659377727573,
            21.002723023582153,
            105.8265991228934,
            21.002727108645082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265991228934,
              21.002723023582153
            ],
            [
              105.82659380028002,
              21.002727094081727
            ],
            [
              105.82659377727573,
              21.002727108645082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT204_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9497",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002723023582153,
          "to_latitude": 21.002727108645082,
          "to_longitude": 105.82659377727573,
          "from_longitude": 105.8265991228934
        }
      },
      {
        "id": 9490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82676381349165,
            21.002758786365018,
            105.82706938833604,
            21.002808550960093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82676381349165,
              21.002758786365018
            ],
            [
              105.8268333926411,
              21.002777375516906
            ],
            [
              105.82691485433043,
              21.002791032196264
            ],
            [
              105.82691507188811,
              21.00279106455875
            ],
            [
              105.82706938833604,
              21.002808550960093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9498",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002758786365018,
          "to_latitude": 21.002808550960093,
          "to_longitude": 105.82706938833604,
          "from_longitude": 105.82676381349165
        }
      },
      {
        "id": 9491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82691456521029,
            21.00279106455875,
            105.82691507188811,
            21.00279494547795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82691507188811,
              21.00279106455875
            ],
            [
              105.82691475716507,
              21.00279347318826
            ],
            [
              105.82691456521029,
              21.00279494547795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9499",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00279106455875,
          "to_latitude": 21.00279494547795,
          "to_longitude": 105.82691456521029,
          "from_longitude": 105.82691507188811
        }
      },
      {
        "id": 9492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82711381867584,
            21.00281576140045,
            105.82743791395075,
            21.002857441769667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711381867584,
              21.00281576140045
            ],
            [
              105.82734565475936,
              21.00284594595851
            ],
            [
              105.82734597913513,
              21.002845987736737
            ],
            [
              105.82743791395075,
              21.002857441769667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT182_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9500",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00281576140045,
          "to_latitude": 21.002857441769667,
          "to_longitude": 105.82743791395075,
          "from_longitude": 105.82711381867584
        }
      },
      {
        "id": 9493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82734536549722,
            21.00284594595851,
            105.82734565475936,
            21.002850529435815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82734565475936,
              21.00284594595851
            ],
            [
              105.82734558126518,
              21.002847096123887
            ],
            [
              105.82734540970043,
              21.002849823800545
            ],
            [
              105.82734536549722,
              21.002850529435815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT182_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9501",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00284594595851,
          "to_latitude": 21.002850529435815,
          "to_longitude": 105.82734536549722,
          "from_longitude": 105.82734565475936
        }
      },
      {
        "id": 9494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746436621149,
            21.002725794613163,
            105.82748317935838,
            21.00286778248368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746436621149,
              21.00286778248368
            ],
            [
              105.82746676439089,
              21.002833460839803
            ],
            [
              105.82748317935838,
              21.00282228308836
            ],
            [
              105.82748269069758,
              21.002725794613163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT180_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9502",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00286778248368,
          "to_latitude": 21.002725794613163,
          "to_longitude": 105.82748269069758,
          "from_longitude": 105.82746436621149
        }
      },
      {
        "id": 9495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82739480191923,
            21.002595882192,
            105.82748269069758,
            21.002725794613163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82748269069758,
              21.002725794613163
            ],
            [
              105.82740537827452,
              21.002714026555545
            ],
            [
              105.82741494240524,
              21.002658614046563
            ],
            [
              105.82739480191923,
              21.002654213877417
            ],
            [
              105.82740101040068,
              21.002595882192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT180_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9503",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002725794613163,
          "to_latitude": 21.002595882192,
          "to_longitude": 105.82740101040068,
          "from_longitude": 105.82748269069758
        }
      },
      {
        "id": 9496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82748269069758,
            21.00259003032241,
            105.82752955497931,
            21.002725794613163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82748269069758,
              21.002725794613163
            ],
            [
              105.82749913786161,
              21.0027145272844
            ],
            [
              105.82752955497931,
              21.00259003032241
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT180_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9504",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002725794613163,
          "to_latitude": 21.00259003032241,
          "to_longitude": 105.82752955497931,
          "from_longitude": 105.82748269069758
        }
      },
      {
        "id": 9497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82749576012733,
            21.002868984704673,
            105.82786199922575,
            21.002882180599187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749576012733,
              21.002868984704673
            ],
            [
              105.8276484355504,
              21.002881196482438
            ],
            [
              105.82767805268034,
              21.002882180599187
            ],
            [
              105.82778282188798,
              21.00288200440626
            ],
            [
              105.82786199922575,
              21.002881871331166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT179_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9505",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002868984704673,
          "to_latitude": 21.002881871331166,
          "to_longitude": 105.82786199922575,
          "from_longitude": 105.82749576012733
        }
      },
      {
        "id": 9498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82778253250511,
            21.00288200440626,
            105.82778282188798,
            21.002892144537377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82778282188798,
              21.00288200440626
            ],
            [
              105.82778278146257,
              21.0028833946693
            ],
            [
              105.82778267839772,
              21.00288703065947
            ],
            [
              105.82778253250511,
              21.002892144537377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT179_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9506",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00288200440626,
          "to_latitude": 21.002892144537377,
          "to_longitude": 105.82778253250511,
          "from_longitude": 105.82778282188798
        }
      },
      {
        "id": 9499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789286624602,
            21.002220872455645,
            105.82813494835986,
            21.002895034020195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.827894876893,
              21.002895034020195
            ],
            [
              105.82789471818738,
              21.002880909224366
            ],
            [
              105.82789286624602,
              21.002715004454227
            ],
            [
              105.82789346649622,
              21.002684322524225
            ],
            [
              105.82790442389091,
              21.00268204548387
            ],
            [
              105.8279969204028,
              21.002662819281078
            ],
            [
              105.82798727563704,
              21.002571232908963
            ],
            [
              105.82813494835986,
              21.002541463116337
            ],
            [
              105.82808578048177,
              21.002220872455645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT177_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9507",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 96.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002895034020195,
          "to_latitude": 21.002220872455645,
          "to_longitude": 105.82808578048177,
          "from_longitude": 105.827894876893
        }
      },
      {
        "id": 9500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82808578048177,
            21.00215361809058,
            105.82831097513798,
            21.002220872455645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82808578048177,
              21.002220872455645
            ],
            [
              105.82823144966552,
              21.002190994012615
            ],
            [
              105.828229951691,
              21.00217506385985
            ],
            [
              105.82831097513798,
              21.00215361809058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT177_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9508",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002220872455645,
          "to_latitude": 21.00215361809058,
          "to_longitude": 105.82831097513798,
          "from_longitude": 105.82808578048177
        }
      },
      {
        "id": 9501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82788579480179,
            21.002544824803188,
            105.82790442389091,
            21.00268204548387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82790442389091,
              21.00268204548387
            ],
            [
              105.82790310147881,
              21.00267230519268
            ],
            [
              105.8278967219963,
              21.002625312413947
            ],
            [
              105.82788579480179,
              21.002544824803188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT177_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9509",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00268204548387,
          "to_latitude": 21.002544824803188,
          "to_longitude": 105.82788579480179,
          "from_longitude": 105.82790442389091
        }
      },
      {
        "id": 9502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772567536995,
            21.002544824803188,
            105.82788579480179,
            21.00256198985724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82788579480179,
              21.002544824803188
            ],
            [
              105.82787491016414,
              21.00254599142215
            ],
            [
              105.82772567536995,
              21.00256198985724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT177_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9510",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002544824803188,
          "to_latitude": 21.00256198985724,
          "to_longitude": 105.82772567536995,
          "from_longitude": 105.82788579480179
        }
      },
      {
        "id": 9503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82778716523029,
            21.002625312413947,
            105.8278967219963,
            21.002631660221674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278967219963,
              21.002625312413947
            ],
            [
              105.8278960662234,
              21.00262535082938
            ],
            [
              105.82778716523029,
              21.002631660221674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT177_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9511",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002625312413947,
          "to_latitude": 21.002631660221674,
          "to_longitude": 105.82778716523029,
          "from_longitude": 105.8278967219963
        }
      },
      {
        "id": 9504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282816092903,
            21.002309404396193,
            105.82840450826397,
            21.002445309128046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82828379009965,
              21.002309404396193
            ],
            [
              105.82828383786212,
              21.00230951887362
            ],
            [
              105.82831318373829,
              21.00237936894136
            ],
            [
              105.82838811077204,
              21.002363971001195
            ],
            [
              105.82840450826397,
              21.00241587462313
            ],
            [
              105.8282816092903,
              21.002445309128046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT176_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9512",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002309404396193,
          "to_latitude": 21.002445309128046,
          "to_longitude": 105.8282816092903,
          "from_longitude": 105.82828379009965
        }
      },
      {
        "id": 9505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8279172603161,
            21.002837958904838,
            105.82845610213282,
            21.002888768752857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279172603161,
              21.002888768752857
            ],
            [
              105.82799540804727,
              21.002885071973633
            ],
            [
              105.82819227873975,
              21.00287808334112
            ],
            [
              105.82824062754091,
              21.002876366794727
            ],
            [
              105.82831233568754,
              21.002870415137192
            ],
            [
              105.82845610213282,
              21.002837958904838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT164_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9513",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002888768752857,
          "to_latitude": 21.002837958904838,
          "to_longitude": 105.82845610213282,
          "from_longitude": 105.8279172603161
        }
      },
      {
        "id": 9506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82819227873975,
            21.00287808334112,
            105.82819245137647,
            21.00288322276597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819227873975,
              21.00287808334112
            ],
            [
              105.82819229018706,
              21.002878414769736
            ],
            [
              105.82819245137647,
              21.00288322276597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT164_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9514",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00287808334112,
          "to_latitude": 21.00288322276597,
          "to_longitude": 105.82819245137647,
          "from_longitude": 105.82819227873975
        }
      },
      {
        "id": 9507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284629593809,
            21.00272193221991,
            105.82847998807345,
            21.00284130200544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82847998807345,
              21.00284130200544
            ],
            [
              105.82847901056631,
              21.002834458496523
            ],
            [
              105.8284629593809,
              21.00272193221991
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT167_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9515",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00284130200544,
          "to_latitude": 21.00272193221991,
          "to_longitude": 105.8284629593809,
          "from_longitude": 105.82847998807345
        }
      },
      {
        "id": 9508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840325742836,
            21.002506785851164,
            105.82848432297146,
            21.00272193221991
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284629593809,
              21.00272193221991
            ],
            [
              105.82844189038556,
              21.002708592941616
            ],
            [
              105.82840325742836,
              21.002557833672498
            ],
            [
              105.82848432297146,
              21.00254276258764
            ],
            [
              105.82847640551799,
              21.002506785851164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT167_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9516",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00272193221991,
          "to_latitude": 21.002506785851164,
          "to_longitude": 105.82847640551799,
          "from_longitude": 105.8284629593809
        }
      },
      {
        "id": 9509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865005567295,
            21.002611668701775,
            105.82870235136393,
            21.00281090983549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82870235136393,
              21.00281090983549
            ],
            [
              105.82870066634051,
              21.00280449067647
            ],
            [
              105.82870020654995,
              21.002802744267704
            ],
            [
              105.82865005567295,
              21.002611668701775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT169_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9517",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00281090983549,
          "to_latitude": 21.002611668701775,
          "to_longitude": 105.82865005567295,
          "from_longitude": 105.82870235136393
        }
      },
      {
        "id": 9510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874432978386,
            21.002553638942665,
            105.82880894983973,
            21.00279834628479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82880894983973,
              21.00279834628479
            ],
            [
              105.82880756393611,
              21.002793144123558
            ],
            [
              105.82874906016765,
              21.002573321613266
            ],
            [
              105.82874432978386,
              21.002553638942665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT171_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9518",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00279834628479,
          "to_latitude": 21.002553638942665,
          "to_longitude": 105.82874432978386,
          "from_longitude": 105.82880894983973
        }
      },
      {
        "id": 9511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874432978386,
            21.00251803934048,
            105.82893497748327,
            21.002553638942665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874432978386,
              21.002553638942665
            ],
            [
              105.82893497748327,
              21.00251803934048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT171_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9519",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002553638942665,
          "to_latitude": 21.00251803934048,
          "to_longitude": 105.82893497748327,
          "from_longitude": 105.82874432978386
        }
      },
      {
        "id": 9512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890915775518,
            21.002400348159593,
            105.82893497748327,
            21.00251803934048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82893497748327,
              21.00251803934048
            ],
            [
              105.82890915775518,
              21.002400348159593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT171_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9520",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00251803934048,
          "to_latitude": 21.002400348159593,
          "to_longitude": 105.82890915775518,
          "from_longitude": 105.82893497748327
        }
      },
      {
        "id": 9513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82866820115686,
            21.002364466501156,
            105.82874906016765,
            21.00257496357637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874906016765,
              21.002573321613266
            ],
            [
              105.82874839694013,
              21.00257340342316
            ],
            [
              105.82873572447147,
              21.00257496357637
            ],
            [
              105.82872990245149,
              21.002558602869563
            ],
            [
              105.82870207719894,
              21.002506029629245
            ],
            [
              105.82866820115686,
              21.002364466501156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT171_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9521",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002573321613266,
          "to_latitude": 21.002364466501156,
          "to_longitude": 105.82866820115686,
          "from_longitude": 105.82874906016765
        }
      },
      {
        "id": 9514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82849883095355,
            21.002795018053273,
            105.82877923706226,
            21.00283174085892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82877923706226,
              21.002795018053273
            ],
            [
              105.8287072811413,
              21.002804338342344
            ],
            [
              105.82870066634051,
              21.00280449067647
            ],
            [
              105.82867974382677,
              21.002804970990635
            ],
            [
              105.8286478526066,
              21.00280672405778
            ],
            [
              105.82859190818523,
              21.002814560927895
            ],
            [
              105.82859039154982,
              21.00281477334333
            ],
            [
              105.82849883095355,
              21.00283174085892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9522",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002795018053273,
          "to_latitude": 21.00283174085892,
          "to_longitude": 105.82849883095355,
          "from_longitude": 105.82877923706226
        }
      },
      {
        "id": 9515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859190818523,
            21.002814560927895,
            105.82859277708678,
            21.002821941481525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859190818523,
              21.002814560927895
            ],
            [
              105.82859197986569,
              21.002815174773275
            ],
            [
              105.82859232493145,
              21.00281809322619
            ],
            [
              105.82859239456853,
              21.002818685404062
            ],
            [
              105.82859277708678,
              21.002821941481525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9523",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002814560927895,
          "to_latitude": 21.002821941481525,
          "to_longitude": 105.82859277708678,
          "from_longitude": 105.82859190818523
        }
      },
      {
        "id": 9516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82881672730774,
            21.00276561743947,
            105.82901750803903,
            21.002792406729743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82881672730774,
              21.002792406729743
            ],
            [
              105.82891711816187,
              21.002779011659975
            ],
            [
              105.82901750803903,
              21.00276561743947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT172_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9524",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002792406729743,
          "to_latitude": 21.00276561743947,
          "to_longitude": 105.82901750803903,
          "from_longitude": 105.82881672730774
        }
      },
      {
        "id": 9517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82891711816187,
            21.002779011659975,
            105.82891801950589,
            21.00278512301977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82891711816187,
              21.002779011659975
            ],
            [
              105.82891801950589,
              21.00278512301977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT172_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9525",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002779011659975,
          "to_latitude": 21.00278512301977,
          "to_longitude": 105.82891801950589,
          "from_longitude": 105.82891711816187
        }
      },
      {
        "id": 9518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899009076016,
            21.002516128867594,
            105.82902562494107,
            21.002744336720063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82902562494107,
              21.002744336720063
            ],
            [
              105.82899918046257,
              21.0025745035072
            ],
            [
              105.82899622071535,
              21.002555498673445
            ],
            [
              105.82899009076016,
              21.002516128867594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT174_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9526",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002744336720063,
          "to_latitude": 21.002516128867594,
          "to_longitude": 105.82899009076016,
          "from_longitude": 105.82902562494107
        }
      },
      {
        "id": 9519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899009076016,
            21.0024653783604,
            105.82914956851394,
            21.002516128867594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82899009076016,
              21.002516128867594
            ],
            [
              105.8290086501411,
              21.00250122736319
            ],
            [
              105.82914956851394,
              21.0024653783604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT174_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9527",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002516128867594,
          "to_latitude": 21.0024653783604,
          "to_longitude": 105.82914956851394,
          "from_longitude": 105.82899009076016
        }
      },
      {
        "id": 9520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899622071535,
            21.002553727446873,
            105.82900975864348,
            21.002555498673445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82899622071535,
              21.002555498673445
            ],
            [
              105.82900262396832,
              21.00255466271245
            ],
            [
              105.82900278928803,
              21.002554641133067
            ],
            [
              105.82900928095427,
              21.00255379119283
            ],
            [
              105.82900975864348,
              21.002553727446873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT174_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9528",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002555498673445,
          "to_latitude": 21.002553727446873,
          "to_longitude": 105.82900975864348,
          "from_longitude": 105.82899622071535
        }
      },
      {
        "id": 9521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291561261158,
            21.002358316810945,
            105.82957765455353,
            21.002459575700552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291561261158,
              21.002459575700552
            ],
            [
              105.82936689041038,
              21.002408946384037
            ],
            [
              105.82946810263402,
              21.002384633305454
            ],
            [
              105.82950192264978,
              21.002376510164563
            ],
            [
              105.82951760407471,
              21.002372743159885
            ],
            [
              105.82957765455353,
              21.002358316810945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT175_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9529",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002459575700552,
          "to_latitude": 21.002358316810945,
          "to_longitude": 105.82957765455353,
          "from_longitude": 105.8291561261158
        }
      },
      {
        "id": 9522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946810263402,
            21.002384633305454,
            105.82947207304134,
            21.002399406103248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946810263402,
              21.002384633305454
            ],
            [
              105.82947045167877,
              21.002393372319986
            ],
            [
              105.82947172541215,
              21.002398115279664
            ],
            [
              105.82947207304134,
              21.002399406103248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT175_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9530",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002384633305454,
          "to_latitude": 21.002399406103248,
          "to_longitude": 105.82947207304134,
          "from_longitude": 105.82946810263402
        }
      },
      {
        "id": 9523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82685940741544,
            21.002942219482147,
            105.82693778495826,
            21.003466068270306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82693778495826,
              21.002942219482147
            ],
            [
              105.82693751991509,
              21.00294421780513
            ],
            [
              105.82689366571637,
              21.00327554310511
            ],
            [
              105.82685940741544,
              21.003466068270306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT202_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9531",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002942219482147,
          "to_latitude": 21.003466068270306,
          "to_longitude": 105.82685940741544,
          "from_longitude": 105.82693778495826
        }
      },
      {
        "id": 9524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82670885597749,
            21.002921198846327,
            105.8269229476387,
            21.002959205397797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269229476387,
              21.002959205397797
            ],
            [
              105.82682380540675,
              21.002941605314465
            ],
            [
              105.82670885597749,
              21.002921198846327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT201_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9532",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002959205397797,
          "to_latitude": 21.002921198846327,
          "to_longitude": 105.82670885597749,
          "from_longitude": 105.8269229476387
        }
      },
      {
        "id": 9525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82682380540675,
            21.002929809677493,
            105.8268264894144,
            21.002941605314465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82682380540675,
              21.002941605314465
            ],
            [
              105.8268244260746,
              21.00293851145594
            ],
            [
              105.8268264894144,
              21.002929809677493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT201_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9533",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002941605314465,
          "to_latitude": 21.002929809677493,
          "to_longitude": 105.8268264894144,
          "from_longitude": 105.82682380540675
        }
      },
      {
        "id": 9526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82661275606763,
            21.002904221085927,
            105.82674674997301,
            21.003055706299577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82661275606763,
              21.003055706299577
            ],
            [
              105.82665383748922,
              21.002975634402468
            ],
            [
              105.82666321115595,
              21.00295736446185
            ],
            [
              105.82668296451322,
              21.002904221085927
            ],
            [
              105.82674674997301,
              21.002915911560127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT200_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9534",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003055706299577,
          "to_latitude": 21.002915911560127,
          "to_longitude": 105.82674674997301,
          "from_longitude": 105.82661275606763
        }
      },
      {
        "id": 9527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82665383748922,
            21.002975634402468,
            105.82665994048581,
            21.002978256637707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82665383748922,
              21.002975634402468
            ],
            [
              105.82665994048581,
              21.002978256637707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT200_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9535",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002975634402468,
          "to_latitude": 21.002978256637707,
          "to_longitude": 105.82665994048581,
          "from_longitude": 105.82665383748922
        }
      },
      {
        "id": 9528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82621999440894,
            21.00301994776413,
            105.82661192477572,
            21.00307784211384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82661192477572,
              21.00307784211384
            ],
            [
              105.82660641949977,
              21.003076866267214
            ],
            [
              105.82649725297192,
              21.00306175338975
            ],
            [
              105.82621999440894,
              21.00301994776413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT199_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9536",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00307784211384,
          "to_latitude": 21.00301994776413,
          "to_longitude": 105.82621999440894,
          "from_longitude": 105.82661192477572
        }
      },
      {
        "id": 9529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82599258784794,
            21.00301994776413,
            105.82621999440894,
            21.003151309487475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82621999440894,
              21.00301994776413
            ],
            [
              105.82620406872196,
              21.003079319231357
            ],
            [
              105.8261201759838,
              21.00307958281149
            ],
            [
              105.8260890709081,
              21.00309067276305
            ],
            [
              105.82601932204724,
              21.00307446503987
            ],
            [
              105.82599258784794,
              21.003151309487475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT199_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9537",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00301994776413,
          "to_latitude": 21.003151309487475,
          "to_longitude": 105.82599258784794,
          "from_longitude": 105.82621999440894
        }
      },
      {
        "id": 9530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82649725297192,
            21.002881887146884,
            105.82653711545966,
            21.00306175338975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82649725297192,
              21.00306175338975
            ],
            [
              105.82649729752843,
              21.003061460527768
            ],
            [
              105.82653711545966,
              21.002881887146884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT199_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9538",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00306175338975,
          "to_latitude": 21.002881887146884,
          "to_longitude": 105.82653711545966,
          "from_longitude": 105.82649725297192
        }
      },
      {
        "id": 9531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82629014613406,
            21.003201412431544,
            105.82656152125736,
            21.003255264850587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656152125736,
              21.003254552198033
            ],
            [
              105.82655895937192,
              21.003254644122816
            ],
            [
              105.82654143195292,
              21.003255264850587
            ],
            [
              105.82629014613406,
              21.003201412431544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT197_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9539",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003254552198033,
          "to_latitude": 21.003201412431544,
          "to_longitude": 105.82629014613406,
          "from_longitude": 105.82656152125736
        }
      },
      {
        "id": 9532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82654243229175,
            21.00332303545492,
            105.82676003609059,
            21.00354470461352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82654243229175,
              21.00332303545492
            ],
            [
              105.82655745844819,
              21.0033261779759
            ],
            [
              105.82676003609059,
              21.003368522828428
            ],
            [
              105.82671706012624,
              21.00354470461352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT196_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9540",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00332303545492,
          "to_latitude": 21.00354470461352,
          "to_longitude": 105.82671706012624,
          "from_longitude": 105.82654243229175
        }
      },
      {
        "id": 9533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8260877547179,
            21.00327270655326,
            105.8265306173536,
            21.003381995888603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265306173536,
              21.003381995888603
            ],
            [
              105.82652130069879,
              21.00338005944909
            ],
            [
              105.82639970068489,
              21.003354764293857
            ],
            [
              105.82610958300484,
              21.003278448105434
            ],
            [
              105.8260877547179,
              21.00327270655326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT206_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9541",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003381995888603,
          "to_latitude": 21.00327270655326,
          "to_longitude": 105.8260877547179,
          "from_longitude": 105.8265306173536
        }
      },
      {
        "id": 9534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82599596519162,
            21.003354764293857,
            105.82639970068489,
            21.003617251927864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82639970068489,
              21.003354764293857
            ],
            [
              105.82635523216715,
              21.003513074766325
            ],
            [
              105.82619821348598,
              21.003457724921144
            ],
            [
              105.826121936905,
              21.003617251927864
            ],
            [
              105.82599596519162,
              21.00357432141955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT206_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9542",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003354764293857,
          "to_latitude": 21.00357432141955,
          "to_longitude": 105.82599596519162,
          "from_longitude": 105.82639970068489
        }
      },
      {
        "id": 9535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82608626986705,
            21.003278448105434,
            105.82610958300484,
            21.003357162803447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82610958300484,
              21.003278448105434
            ],
            [
              105.82610943946578,
              21.003278932028714
            ],
            [
              105.82608626986705,
              21.003357162803447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT206_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9543",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003278448105434,
          "to_latitude": 21.003357162803447,
          "to_longitude": 105.82608626986705,
          "from_longitude": 105.82610958300484
        }
      },
      {
        "id": 9536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82644597815278,
            21.00340692764284,
            105.82651499842541,
            21.00362281615374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82651499842541,
              21.00340692764284
            ],
            [
              105.82650659941311,
              21.003438183960096
            ],
            [
              105.82648587756461,
              21.003515299153076
            ],
            [
              105.82646930383522,
              21.00357697611318
            ],
            [
              105.82644597815278,
              21.00362281615374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT195_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9544",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00340692764284,
          "to_latitude": 21.00362281615374,
          "to_longitude": 105.82644597815278,
          "from_longitude": 105.82651499842541
        }
      },
      {
        "id": 9537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650659941311,
            21.003438183960096,
            105.82651524546752,
            21.003441134367915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82650659941311,
              21.003438183960096
            ],
            [
              105.8265072142421,
              21.003438395039453
            ],
            [
              105.82651184756432,
              21.003439974865845
            ],
            [
              105.82651524546752,
              21.003441134367915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT195_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9545",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003438183960096,
          "to_latitude": 21.003441134367915,
          "to_longitude": 105.82651524546752,
          "from_longitude": 105.82650659941311
        }
      },
      {
        "id": 9538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638850446985,
            21.003630708039907,
            105.82643827093119,
            21.003726657434523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643827093119,
              21.003630708039907
            ],
            [
              105.82639881296159,
              21.003706782450426
            ],
            [
              105.82638850446985,
              21.003726657434523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT194_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9546",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003630708039907,
          "to_latitude": 21.003726657434523,
          "to_longitude": 105.82638850446985,
          "from_longitude": 105.82643827093119
        }
      },
      {
        "id": 9539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82624617048242,
            21.003659286647707,
            105.82638850446985,
            21.003726657434523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638850446985,
              21.003726657434523
            ],
            [
              105.82624617048242,
              21.003659286647707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT194_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9547",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003726657434523,
          "to_latitude": 21.003659286647707,
          "to_longitude": 105.82624617048242,
          "from_longitude": 105.82638850446985
        }
      },
      {
        "id": 9540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82639881296159,
            21.003706782450426,
            105.82641182284831,
            21.003712850390034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82639881296159,
              21.003706782450426
            ],
            [
              105.82640068860616,
              21.003707658506354
            ],
            [
              105.82640593749075,
              21.003710105154475
            ],
            [
              105.82641182284831,
              21.003712850390034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT194_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9548",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003706782450426,
          "to_latitude": 21.003712850390034,
          "to_longitude": 105.82641182284831,
          "from_longitude": 105.82639881296159
        }
      },
      {
        "id": 9541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82627413291468,
            21.00376002218992,
            105.82638701690371,
            21.00398883064964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638701690371,
              21.003764120586247
            ],
            [
              105.82638680769789,
              21.003764033085716
            ],
            [
              105.82637725067573,
              21.00376002218992
            ],
            [
              105.82636301628706,
              21.003804607755562
            ],
            [
              105.8263399073686,
              21.003859781583117
            ],
            [
              105.82632385352315,
              21.003891163571282
            ],
            [
              105.8262759250968,
              21.003984854072232
            ],
            [
              105.82627413291468,
              21.00398883064964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT193_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9549",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003764120586247,
          "to_latitude": 21.00398883064964,
          "to_longitude": 105.82627413291468,
          "from_longitude": 105.82638701690371
        }
      },
      {
        "id": 9542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82627413291468,
            21.00398883064964,
            105.82638322162305,
            21.004035459431
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82627413291468,
              21.00398883064964
            ],
            [
              105.82638322162305,
              21.004035459431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT193_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9550",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00398883064964,
          "to_latitude": 21.004035459431,
          "to_longitude": 105.82638322162305,
          "from_longitude": 105.82627413291468
        }
      },
      {
        "id": 9543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82632385352315,
            21.003891163571282,
            105.82642544888824,
            21.00393947167681
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82632385352315,
              21.003891163571282
            ],
            [
              105.82642544888824,
              21.00393947167681
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT193_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9551",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003891163571282,
          "to_latitude": 21.00393947167681,
          "to_longitude": 105.82642544888824,
          "from_longitude": 105.82632385352315
        }
      },
      {
        "id": 9544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746174687321,
            21.002934045119808,
            105.82749290816018,
            21.00320464408197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746174687321,
              21.002934045119808
            ],
            [
              105.82747511916129,
              21.00305016853721
            ],
            [
              105.82747732749792,
              21.003069344151516
            ],
            [
              105.82749290816018,
              21.00320464408197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT185_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9552",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002934045119808,
          "to_latitude": 21.00320464408197,
          "to_longitude": 105.82749290816018,
          "from_longitude": 105.82746174687321
        }
      },
      {
        "id": 9545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82747511916129,
            21.003049570537364,
            105.82748089181725,
            21.00305016853721
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82747511916129,
              21.00305016853721
            ],
            [
              105.82747690719185,
              21.003049982812275
            ],
            [
              105.82747934890435,
              21.00304972797389
            ],
            [
              105.82747982283328,
              21.003049679605315
            ],
            [
              105.82748089181725,
              21.003049570537364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT185_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9553",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00305016853721,
          "to_latitude": 21.003049570537364,
          "to_longitude": 105.82748089181725,
          "from_longitude": 105.82747511916129
        }
      },
      {
        "id": 9546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82733895062805,
            21.003216728643483,
            105.82750065233829,
            21.003259331405385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750065233829,
              21.003216728643483
            ],
            [
              105.8274999395648,
              21.00321689649605
            ],
            [
              105.82749838048252,
              21.00321726175363
            ],
            [
              105.82749434096762,
              21.003227956632987
            ],
            [
              105.82733895062805,
              21.003259331405385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT186_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9554",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003216728643483,
          "to_latitude": 21.003259331405385,
          "to_longitude": 105.82733895062805,
          "from_longitude": 105.82750065233829
        }
      },
      {
        "id": 9547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275023213071,
            21.00324623889365,
            105.82750950893985,
            21.003481599245188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8275023213071,
              21.00324623889365
            ],
            [
              105.82750950893985,
              21.003306889970222
            ],
            [
              105.82750770855081,
              21.00337531636684
            ],
            [
              105.8275059081911,
              21.003443748182164
            ],
            [
              105.82750823489444,
              21.003481599245188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9555",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00324623889365,
          "to_latitude": 21.003481599245188,
          "to_longitude": 105.82750823489444,
          "from_longitude": 105.8275023213071
        }
      },
      {
        "id": 9548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750770855081,
            21.00337531636684,
            105.82751656712671,
            21.0033754638798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750770855081,
              21.00337531636684
            ],
            [
              105.82751372456994,
              21.003375416280132
            ],
            [
              105.82751656712671,
              21.0033754638798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9556",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00337531636684,
          "to_latitude": 21.0033754638798,
          "to_longitude": 105.82751656712671,
          "from_longitude": 105.82750770855081
        }
      },
      {
        "id": 9549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82693337457195,
            21.003512614911912,
            105.82751517652792,
            21.004029975684855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751517652792,
              21.003515423865842
            ],
            [
              105.82751141543652,
              21.00351538434482
            ],
            [
              105.82725279442374,
              21.003512614911912
            ],
            [
              105.827226100903,
              21.003518727622648
            ],
            [
              105.82711185489265,
              21.003520631346124
            ],
            [
              105.82708730790569,
              21.00377401550804
            ],
            [
              105.8271253593014,
              21.003785734230245
            ],
            [
              105.82709032700323,
              21.00392059820579
            ],
            [
              105.82697772277892,
              21.003893210344113
            ],
            [
              105.82693337457195,
              21.004029975684855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT188_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9557",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 117.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003515423865842,
          "to_latitude": 21.004029975684855,
          "to_longitude": 105.82693337457195,
          "from_longitude": 105.82751517652792
        }
      },
      {
        "id": 9550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82707739463959,
            21.00350035536079,
            105.82751536611443,
            21.003791014121557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751536611443,
              21.00351177842299
            ],
            [
              105.82751157736968,
              21.00351160806849
            ],
            [
              105.8272839656951,
              21.00350132029706
            ],
            [
              105.8272546630772,
              21.00350035536079
            ],
            [
              105.82722058684311,
              21.003510227957502
            ],
            [
              105.82720201996847,
              21.003510386837455
            ],
            [
              105.827102783757,
              21.003511236702806
            ],
            [
              105.82708726246983,
              21.003674832361362
            ],
            [
              105.82707739463959,
              21.003778845363534
            ],
            [
              105.82711465594161,
              21.003791014121557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT187_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9558",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00351177842299,
          "to_latitude": 21.003791014121557,
          "to_longitude": 105.82711465594161,
          "from_longitude": 105.82751536611443
        }
      },
      {
        "id": 9551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268492018957,
            21.003791014121557,
            105.82711465594161,
            21.003908835028334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711465594161,
              21.003791014121557
            ],
            [
              105.82707759444585,
              21.003908835028334
            ],
            [
              105.8268492018957,
              21.003852765000495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT187_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9559",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003791014121557,
          "to_latitude": 21.003852765000495,
          "to_longitude": 105.8268492018957,
          "from_longitude": 105.82711465594161
        }
      },
      {
        "id": 9552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82728334810801,
            21.003390321205316,
            105.82728489671538,
            21.00350132029706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8272839656951,
              21.00350132029706
            ],
            [
              105.82728489671538,
              21.003463441653665
            ],
            [
              105.82728334810801,
              21.003390321205316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT187_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9560",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00350132029706,
          "to_latitude": 21.003390321205316,
          "to_longitude": 105.82728334810801,
          "from_longitude": 105.8272839656951
        }
      },
      {
        "id": 9553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82695914815156,
            21.003657171733643,
            105.82708726246983,
            21.003674832361362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82708726246983,
              21.003674832361362
            ],
            [
              105.82695914815156,
              21.003657171733643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT187_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9561",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003674832361362,
          "to_latitude": 21.003657171733643,
          "to_longitude": 105.82695914815156,
          "from_longitude": 105.82708726246983
        }
      },
      {
        "id": 9554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82748913383371,
            21.003563586700736,
            105.82750736148188,
            21.003769554178113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750736148188,
              21.003563586700736
            ],
            [
              105.82750067585454,
              21.003645456090148
            ],
            [
              105.82750011421892,
              21.003664837556645
            ],
            [
              105.82749957610427,
              21.003683425875426
            ],
            [
              105.82749936353706,
              21.003690753886538
            ],
            [
              105.82748913383371,
              21.003769554178113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT189_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9562",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003563586700736,
          "to_latitude": 21.003769554178113,
          "to_longitude": 105.82748913383371,
          "from_longitude": 105.82750736148188
        }
      },
      {
        "id": 9555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750662143147,
            21.00367482180489,
            105.82765321523462,
            21.003683786213667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750662143147,
              21.00367482180489
            ],
            [
              105.82750970908691,
              21.00367501092277
            ],
            [
              105.82765321523462,
              21.003683786213667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9563",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00367482180489,
          "to_latitude": 21.003683786213667,
          "to_longitude": 105.82765321523462,
          "from_longitude": 105.82750662143147
        }
      },
      {
        "id": 9556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82749957610427,
            21.003683425875426,
            105.8275059065756,
            21.00368368413105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8275059065756,
              21.00368368413105
            ],
            [
              105.82750453754002,
              21.003683628464277
            ],
            [
              105.82750168882794,
              21.00368351224925
            ],
            [
              105.82750089704307,
              21.003683480872848
            ],
            [
              105.82749957610427,
              21.003683425875426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT189_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9564",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00368368413105,
          "to_latitude": 21.003683425875426,
          "to_longitude": 105.82749957610427,
          "from_longitude": 105.8275059065756
        }
      },
      {
        "id": 9557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8273187152498,
            21.003777071653566,
            105.82756300842765,
            21.004332343367622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749658820048,
              21.003780533694094
            ],
            [
              105.82748829186407,
              21.003778564348146
            ],
            [
              105.82748682067533,
              21.003778124403187
            ],
            [
              105.82748710727718,
              21.003777071653566
            ],
            [
              105.82749682573169,
              21.00377878027886
            ],
            [
              105.8275573344217,
              21.003789399784317
            ],
            [
              105.82756300842765,
              21.003806317638723
            ],
            [
              105.82750749701142,
              21.00404628952717
            ],
            [
              105.82749618916,
              21.004095278632263
            ],
            [
              105.82739149843252,
              21.00407721813791
            ],
            [
              105.8273187152498,
              21.004332343367622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT190_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9565",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003780533694094,
          "to_latitude": 21.004332343367622,
          "to_longitude": 105.8273187152498,
          "from_longitude": 105.82749658820048
        }
      },
      {
        "id": 9558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750749701142,
            21.0039033704845,
            105.82771129008287,
            21.00406207943036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750749701142,
              21.00404628952717
            ],
            [
              105.82760471787876,
              21.00406207943036
            ],
            [
              105.8277068468876,
              21.004057240092628
            ],
            [
              105.8277102333688,
              21.004057370850134
            ],
            [
              105.82771129008287,
              21.003967306757563
            ],
            [
              105.82768544807273,
              21.00395690802482
            ],
            [
              105.82768667930448,
              21.0039033704845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT190_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9566",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00404628952717,
          "to_latitude": 21.0039033704845,
          "to_longitude": 105.82768667930448,
          "from_longitude": 105.82750749701142
        }
      },
      {
        "id": 9559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82732823189109,
            21.00374795980625,
            105.82744773161254,
            21.00377010681209
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82744773161254,
              21.00377010681209
            ],
            [
              105.82736510308371,
              21.003754965753078
            ],
            [
              105.82734101199195,
              21.003750374333926
            ],
            [
              105.82732823189109,
              21.00374795980625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT175_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9567",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00377010681209,
          "to_latitude": 21.00374795980625,
          "to_longitude": 105.82732823189109,
          "from_longitude": 105.82744773161254
        }
      },
      {
        "id": 9560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82726948353363,
            21.00374795980625,
            105.82732823189109,
            21.003886385804215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82732823189109,
              21.00374795980625
            ],
            [
              105.82732033247291,
              21.00377419811
            ],
            [
              105.82729493391363,
              21.00385488399585
            ],
            [
              105.82728079664295,
              21.00385247425498
            ],
            [
              105.82726948353363,
              21.003886385804215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT175_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9568",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00374795980625,
          "to_latitude": 21.003886385804215,
          "to_longitude": 105.82726948353363,
          "from_longitude": 105.82732823189109
        }
      },
      {
        "id": 9561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827338562744,
            21.003750374333926,
            105.82734101199195,
            21.003757814378453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82734101199195,
              21.003750374333926
            ],
            [
              105.82734049993832,
              21.003751882502403
            ],
            [
              105.827338562744,
              21.003757814378453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT175_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9569",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003750374333926,
          "to_latitude": 21.003757814378453,
          "to_longitude": 105.827338562744,
          "from_longitude": 105.82734101199195
        }
      },
      {
        "id": 9562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82711455629821,
            21.003900701169407,
            105.82727373307587,
            21.00427315630709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82727373307587,
              21.003900701169407
            ],
            [
              105.82724282827483,
              21.00399770170352
            ],
            [
              105.8272203380816,
              21.004063162196374
            ],
            [
              105.82721112849066,
              21.004089560411337
            ],
            [
              105.82719993531526,
              21.004111381679213
            ],
            [
              105.82712703605671,
              21.004249514209118
            ],
            [
              105.82711455629821,
              21.00427315630709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT191_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9570",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003900701169407,
          "to_latitude": 21.00427315630709,
          "to_longitude": 105.82711455629821,
          "from_longitude": 105.82727373307587
        }
      },
      {
        "id": 9563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82706369364138,
            21.00427315630709,
            105.82711455629821,
            21.004393209310173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711455629821,
              21.00427315630709
            ],
            [
              105.82706369364138,
              21.004393209310173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT191_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9571",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00427315630709,
          "to_latitude": 21.004393209310173,
          "to_longitude": 105.82706369364138,
          "from_longitude": 105.82711455629821
        }
      },
      {
        "id": 9564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82723060968718,
            21.003992697951592,
            105.82724282827483,
            21.00399770170352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724282827483,
              21.00399770170352
            ],
            [
              105.82723737663862,
              21.003995295809364
            ],
            [
              105.82723146609759,
              21.003992697951592
            ],
            [
              105.82723060968718,
              21.003994553729353
            ],
            [
              105.82723198094979,
              21.00399518745272
            ],
            [
              105.82723675777228,
              21.003997145013273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT191_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9572",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00399770170352,
          "to_latitude": 21.003997145013273,
          "to_longitude": 105.82723675777228,
          "from_longitude": 105.82724282827483
        }
      },
      {
        "id": 9565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82550586997448,
            21.003976875512702,
            105.82706772129603,
            21.004374549199643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82706772129603,
              21.004370227901028
            ],
            [
              105.82706673622495,
              21.00436985694533
            ],
            [
              105.8270573925025,
              21.004366337311872
            ],
            [
              105.82705372687705,
              21.004374549199643
            ],
            [
              105.8267511337809,
              21.00427786560882
            ],
            [
              105.82652231367322,
              21.004203101335236
            ],
            [
              105.8263966473854,
              21.00415862694839
            ],
            [
              105.8262639818655,
              21.004117330578403
            ],
            [
              105.8259551190026,
              21.004014288353495
            ],
            [
              105.82585842953395,
              21.003985613096088
            ],
            [
              105.82581484979617,
              21.003977115627254
            ],
            [
              105.82576064554286,
              21.003976875512702
            ],
            [
              105.82571942123116,
              21.003983380035944
            ],
            [
              105.82563026050013,
              21.00402013042564
            ],
            [
              105.82550676492085,
              21.00409970672628
            ],
            [
              105.82550589491773,
              21.004100144494984
            ],
            [
              105.82550586997448,
              21.004100156357914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT192_Ngõ 139, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9573",
          "diaChiLapD": "Ngõ 139, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 175.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004370227901028,
          "to_latitude": 21.004100156357914,
          "to_longitude": 105.82550586997448,
          "from_longitude": 105.82706772129603
        }
      },
      {
        "id": 9566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276303865632,
            21.003116360674206,
            105.82801225065887,
            21.003135452234805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82801225065887,
              21.003116360674206
            ],
            [
              105.82801192139333,
              21.003116478792244
            ],
            [
              105.82799296101018,
              21.003123234131728
            ],
            [
              105.82784771768002,
              21.003135452234805
            ],
            [
              105.82783501208975,
              21.00313537764261
            ],
            [
              105.82772416592942,
              21.003134725358862
            ],
            [
              105.8276303865632,
              21.003134173373702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT162_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9574",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003116360674206,
          "to_latitude": 21.003134173373702,
          "to_longitude": 105.8276303865632,
          "from_longitude": 105.82801225065887
        }
      },
      {
        "id": 9567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8276303865632,
            21.003134173373702,
            105.82769185897918,
            21.00362901990501
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8276303865632,
              21.003134173373702
            ],
            [
              105.82764090229725,
              21.003280475734652
            ],
            [
              105.82764816854433,
              21.003360256769938
            ],
            [
              105.82768038329128,
              21.003553196511128
            ],
            [
              105.82769185897918,
              21.00362901990501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT162_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9575",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003134173373702,
          "to_latitude": 21.00362901990501,
          "to_longitude": 105.82769185897918,
          "from_longitude": 105.8276303865632
        }
      },
      {
        "id": 9568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799837026235,
            21.002908904563956,
            105.82801371883998,
            21.00311416410982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82799837026235,
              21.002908904563956
            ],
            [
              105.82800248106253,
              21.002969201164234
            ],
            [
              105.82801013688707,
              21.003067960699116
            ],
            [
              105.82801091456471,
              21.003077989058387
            ],
            [
              105.82801371883998,
              21.00311416410982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT163_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9576",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002908904563956,
          "to_latitude": 21.00311416410982,
          "to_longitude": 105.82801371883998,
          "from_longitude": 105.82799837026235
        }
      },
      {
        "id": 9569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82800910187602,
            21.003077989058387,
            105.82801091456471,
            21.00307806381195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82801091456471,
              21.003077989058387
            ],
            [
              105.82800995869852,
              21.003078028934137
            ],
            [
              105.82800910187602,
              21.00307806381195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT163_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9577",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003077989058387,
          "to_latitude": 21.00307806381195,
          "to_longitude": 105.82800910187602,
          "from_longitude": 105.82801091456471
        }
      },
      {
        "id": 9570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82801567814437,
            21.0031604033215,
            105.828018296516,
            21.00316106547917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828018296516,
              21.0031604033215
            ],
            [
              105.82801681251343,
              21.00316077815405
            ],
            [
              105.82801567814437,
              21.00316106547917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT161_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9578",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0031604033215,
          "to_latitude": 21.00316106547917,
          "to_longitude": 105.82801567814437,
          "from_longitude": 105.828018296516
        }
      },
      {
        "id": 9571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82804487098376,
            21.00327188669109,
            105.82820341621115,
            21.00330167151843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82804487098376,
              21.00330167151843
            ],
            [
              105.82804784581889,
              21.003301113303653
            ],
            [
              105.82820341621115,
              21.00327188669109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT160_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9579",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00330167151843,
          "to_latitude": 21.00327188669109,
          "to_longitude": 105.82820341621115,
          "from_longitude": 105.82804487098376
        }
      },
      {
        "id": 9572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280404692915,
            21.003341032982828,
            105.8280703043743,
            21.003495000937974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280404692915,
              21.003341032982828
            ],
            [
              105.82804880149715,
              21.003384027600145
            ],
            [
              105.82805222210378,
              21.00340167898195
            ],
            [
              105.8280703043743,
              21.003495000937974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT159_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9580",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003341032982828,
          "to_latitude": 21.003495000937974,
          "to_longitude": 105.8280703043743,
          "from_longitude": 105.8280404692915
        }
      },
      {
        "id": 9573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82805222210378,
            21.0033990148797,
            105.82806810309414,
            21.00340167898195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82805222210378,
              21.00340167898195
            ],
            [
              105.82806429765158,
              21.00339965300682
            ],
            [
              105.82806766297301,
              21.003399089279046
            ],
            [
              105.82806810309414,
              21.0033990148797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT159_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9581",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00340167898195,
          "to_latitude": 21.0033990148797,
          "to_longitude": 105.82806810309414,
          "from_longitude": 105.82805222210378
        }
      },
      {
        "id": 9574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82790516552585,
            21.003499638056702,
            105.82808368153708,
            21.003534586119613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82808368153708,
              21.003499638056702
            ],
            [
              105.82808301297734,
              21.00350014892286
            ],
            [
              105.82806795597472,
              21.003511647093763
            ],
            [
              105.82791902209905,
              21.00353319368583
            ],
            [
              105.82790516552585,
              21.003534586119613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT158_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9582",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003499638056702,
          "to_latitude": 21.003534586119613,
          "to_longitude": 105.82790516552585,
          "from_longitude": 105.82808368153708
        }
      },
      {
        "id": 9575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789279742256,
            21.003449747458145,
            105.82790516552585,
            21.003534586119613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82790516552585,
              21.003534586119613
            ],
            [
              105.82789279742256,
              21.003449747458145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT158_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9583",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003534586119613,
          "to_latitude": 21.003449747458145,
          "to_longitude": 105.82789279742256,
          "from_longitude": 105.82790516552585
        }
      },
      {
        "id": 9576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82791902209905,
            21.00353319368583,
            105.82793183584818,
            21.003650341314344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82791902209905,
              21.00353319368583
            ],
            [
              105.82791908225542,
              21.003533636877656
            ],
            [
              105.82793183584818,
              21.003650341314344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT158_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9584",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00353319368583,
          "to_latitude": 21.003650341314344,
          "to_longitude": 105.82793183584818,
          "from_longitude": 105.82791902209905
        }
      },
      {
        "id": 9577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82807843367003,
            21.0035306776918,
            105.82813201812773,
            21.0037442604818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82813201812773,
              21.0037442604818
            ],
            [
              105.82809873981249,
              21.003739584708683
            ],
            [
              105.82809062571587,
              21.003656109114054
            ],
            [
              105.82807843367003,
              21.0035306776918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT154_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9585",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0037442604818,
          "to_latitude": 21.0035306776918,
          "to_longitude": 105.82807843367003,
          "from_longitude": 105.82813201812773
        }
      },
      {
        "id": 9578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82809062571587,
            21.003655402180087,
            105.828098362873,
            21.003656109114054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82809062571587,
              21.003656109114054
            ],
            [
              105.828098362873,
              21.003655402180087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT154_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9586",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003656109114054,
          "to_latitude": 21.003655402180087,
          "to_longitude": 105.828098362873,
          "from_longitude": 105.82809062571587
        }
      },
      {
        "id": 9579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817412019014,
            21.003749470340846,
            105.82818868098539,
            21.00391045893002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818714230719,
              21.003749470340846
            ],
            [
              105.82818868098539,
              21.00381164551014
            ],
            [
              105.82818598229724,
              21.00381152496376
            ],
            [
              105.82818017795469,
              21.003811265980083
            ],
            [
              105.82817475206228,
              21.0038110864452
            ],
            [
              105.82817458505295,
              21.00383729799925
            ],
            [
              105.82817412019014,
              21.00391045893002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT153_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9587",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003749470340846,
          "to_latitude": 21.00391045893002,
          "to_longitude": 105.82817412019014,
          "from_longitude": 105.82818714230719
        }
      },
      {
        "id": 9580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817458505295,
            21.00383729799925,
            105.82818621320112,
            21.00383785651725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82817458505295,
              21.00383729799925
            ],
            [
              105.82817475534779,
              21.00383730620292
            ],
            [
              105.82818044049841,
              21.003837579315103
            ],
            [
              105.82818621320112,
              21.00383785651725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT153_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9588",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00383729799925,
          "to_latitude": 21.00383785651725,
          "to_longitude": 105.82818621320112,
          "from_longitude": 105.82817458505295
        }
      },
      {
        "id": 9581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82803671877885,
            21.003924353382413,
            105.82818614530503,
            21.00393013953922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818614530503,
              21.00393013953922
            ],
            [
              105.82818600580879,
              21.00393013479863
            ],
            [
              105.82804767797856,
              21.003924828454277
            ],
            [
              105.82803671877885,
              21.003924353382413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT151_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9589",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00393013953922,
          "to_latitude": 21.003924353382413,
          "to_longitude": 105.82803671877885,
          "from_longitude": 105.82818614530503
        }
      },
      {
        "id": 9582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789989103543,
            21.003924353382413,
            105.82803671877885,
            21.00426933603208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82803671877885,
              21.003924353382413
            ],
            [
              105.8280217728837,
              21.004081179315683
            ],
            [
              105.82790074481495,
              21.004070085719306
            ],
            [
              105.82789989103543,
              21.004199358805128
            ],
            [
              105.82789994808844,
              21.00426933603208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT151_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9590",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003924353382413,
          "to_latitude": 21.00426933603208,
          "to_longitude": 105.82789994808844,
          "from_longitude": 105.82803671877885
        }
      },
      {
        "id": 9583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278326927351,
            21.004199358805128,
            105.82789989103543,
            21.004204496241602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82789989103543,
              21.004199358805128
            ],
            [
              105.8278326927351,
              21.004204496241602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT151_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9591",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004199358805128,
          "to_latitude": 21.004204496241602,
          "to_longitude": 105.8278326927351,
          "from_longitude": 105.82789989103543
        }
      },
      {
        "id": 9584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82817494691797,
            21.003960225551864,
            105.82818642288277,
            21.00415406332574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818438663361,
              21.003960225551864
            ],
            [
              105.82818642288277,
              21.0039603971925
            ],
            [
              105.82817494691797,
              21.00415406332574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT152_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9592",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003960225551864,
          "to_latitude": 21.00415406332574,
          "to_longitude": 105.82817494691797,
          "from_longitude": 105.82818438663361
        }
      },
      {
        "id": 9585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818803674124,
            21.003881437296776,
            105.82839852799711,
            21.003891168740783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82818803674124,
              21.003891168740783
            ],
            [
              105.82819066434955,
              21.00389113247146
            ],
            [
              105.82829816440854,
              21.00388963297452
            ],
            [
              105.82839852799711,
              21.003881437296776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT150_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9593",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003891168740783,
          "to_latitude": 21.003881437296776,
          "to_longitude": 105.82839852799711,
          "from_longitude": 105.82818803674124
        }
      },
      {
        "id": 9586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82819066434955,
            21.00389113247146,
            105.82819114548563,
            21.00390058239974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819066434955,
              21.00389113247146
            ],
            [
              105.82819072173122,
              21.00389229283881
            ],
            [
              105.82819091788252,
              21.003896115243798
            ],
            [
              105.82819114548563,
              21.00390058239974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT150_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9594",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00389113247146,
          "to_latitude": 21.00390058239974,
          "to_longitude": 105.82819114548563,
          "from_longitude": 105.82819066434955
        }
      },
      {
        "id": 9587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283973243002,
            21.00382730307444,
            105.82867689891627,
            21.00390105582314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283973243002,
              21.00390105582314
            ],
            [
              105.82839778201486,
              21.003901031917987
            ],
            [
              105.82853359434922,
              21.00389375562808
            ],
            [
              105.82853219290293,
              21.0038367769658
            ],
            [
              105.82863512035779,
              21.003832514867373
            ],
            [
              105.82867689891627,
              21.00382730307444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9595",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00390105582314,
          "to_latitude": 21.00382730307444,
          "to_longitude": 105.82867689891627,
          "from_longitude": 105.8283973243002
        }
      },
      {
        "id": 9588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283958007255,
            21.003909423506176,
            105.82840746976542,
            21.00415892015901
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283958007255,
              21.003909423506176
            ],
            [
              105.82840061748887,
              21.00402387232381
            ],
            [
              105.82840144748445,
              21.00407055878915
            ],
            [
              105.82840145592758,
              21.004071042877637
            ],
            [
              105.8284054323392,
              21.004138321148897
            ],
            [
              105.82840746976542,
              21.00415892015901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT149_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9596",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003909423506176,
          "to_latitude": 21.00415892015901,
          "to_longitude": 105.82840746976542,
          "from_longitude": 105.8283958007255
        }
      },
      {
        "id": 9589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840144748445,
            21.004070428074172,
            105.82840640613186,
            21.00407055878915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840144748445,
              21.00407055878915
            ],
            [
              105.82840273908305,
              21.00407052540602
            ],
            [
              105.82840475869813,
              21.004070471318247
            ],
            [
              105.82840640613186,
              21.004070428074172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT149_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9597",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00407055878915,
          "to_latitude": 21.004070428074172,
          "to_longitude": 105.82840640613186,
          "from_longitude": 105.82840144748445
        }
      },
      {
        "id": 9590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839289065528,
            21.004194335880264,
            105.82841575219422,
            21.004443297111475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284118830215,
              21.004194335880264
            ],
            [
              105.82841575219422,
              21.004271111198772
            ],
            [
              105.82841383620246,
              21.00428732531654
            ],
            [
              105.82840545728273,
              21.00435822966075
            ],
            [
              105.82840218399848,
              21.00438749568917
            ],
            [
              105.82839289065528,
              21.004443297111475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT147_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9598",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004194335880264,
          "to_latitude": 21.004443297111475,
          "to_longitude": 105.82839289065528,
          "from_longitude": 105.8284118830215
        }
      },
      {
        "id": 9591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839670704514,
            21.00437796834186,
            105.82892128680115,
            21.00446977060161
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839670704514,
              21.00444986576972
            ],
            [
              105.82839856747378,
              21.004450072585975
            ],
            [
              105.82852128783288,
              21.004463960292902
            ],
            [
              105.8285735475734,
              21.004466332475243
            ],
            [
              105.82866955887253,
              21.00446977060161
            ],
            [
              105.82870185266756,
              21.004466479171914
            ],
            [
              105.82892128680115,
              21.00437796834186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT146_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9599",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00444986576972,
          "to_latitude": 21.00437796834186,
          "to_longitude": 105.82892128680115,
          "from_longitude": 105.82839670704514
        }
      },
      {
        "id": 9592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82841383620246,
            21.00428732531654,
            105.8284199062576,
            21.00428866958027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82841383620246,
              21.00428732531654
            ],
            [
              105.82841559568621,
              21.004287715979032
            ],
            [
              105.82841785112534,
              21.00428821442125
            ],
            [
              105.8284199062576,
              21.00428866958027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT147_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9600",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00428732531654,
          "to_latitude": 21.00428866958027,
          "to_longitude": 105.8284199062576,
          "from_longitude": 105.82841383620246
        }
      },
      {
        "id": 9593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82840602709432,
            21.0040493913751,
            105.82863126339517,
            21.004182312317294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82840602709432,
              21.00406030926229
            ],
            [
              105.82854940087653,
              21.0040493913751
            ],
            [
              105.82856511474539,
              21.004182312317294
            ],
            [
              105.82863126339517,
              21.00417870434955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT148_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9601",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00406030926229,
          "to_latitude": 21.00417870434955,
          "to_longitude": 105.82863126339517,
          "from_longitude": 105.82840602709432
        }
      },
      {
        "id": 9594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82831543358954,
            21.00350830763406,
            105.82831786053627,
            21.00372248178632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82831757695232,
              21.003515134699867
            ],
            [
              105.82831771258661,
              21.00351130255126
            ],
            [
              105.82831771339393,
              21.00351127454729
            ],
            [
              105.82831774051868,
              21.003510438027185
            ],
            [
              105.82831786053627,
              21.00350830763406
            ],
            [
              105.82831543358954,
              21.00350845041264
            ],
            [
              105.82831543864602,
              21.00351128381084
            ],
            [
              105.82831543895503,
              21.003511339809414
            ],
            [
              105.828315449365,
              21.003515143247277
            ],
            [
              105.82831580105712,
              21.00372248178632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT155_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9602",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003515134699867,
          "to_latitude": 21.00372248178632,
          "to_longitude": 105.82831580105712,
          "from_longitude": 105.82831757695232
        }
      },
      {
        "id": 9595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82814458361246,
            21.003505372199577,
            105.82848004952224,
            21.003511649013312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82814458361246,
              21.003510791958615
            ],
            [
              105.82825991764493,
              21.003508924699542
            ],
            [
              105.82829514637599,
              21.003511649013312
            ],
            [
              105.82830299838672,
              21.00351136331831
            ],
            [
              105.82831248483812,
              21.003511295475754
            ],
            [
              105.82831543864602,
              21.00351128381084
            ],
            [
              105.82831771339393,
              21.00351127454729
            ],
            [
              105.82838186224946,
              21.003511013839763
            ],
            [
              105.82848004952224,
              21.003505372199577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9603",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003510791958615,
          "to_latitude": 21.003505372199577,
          "to_longitude": 105.82848004952224,
          "from_longitude": 105.82814458361246
        }
      },
      {
        "id": 9596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82825987238466,
            21.003508924699542,
            105.82826008467482,
            21.00351531331398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82825991764493,
              21.003508924699542
            ],
            [
              105.82825987238466,
              21.00350996092107
            ],
            [
              105.82826008467482,
              21.00351531331398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9604",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003508924699542,
          "to_latitude": 21.00351531331398,
          "to_longitude": 105.82826008467482,
          "from_longitude": 105.82825991764493
        }
      },
      {
        "id": 9597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848082318438,
            21.0034988282918,
            105.82883273993696,
            21.003520591419676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82848082318438,
              21.003520591419676
            ],
            [
              105.82857621687472,
              21.00351575906439
            ],
            [
              105.82865447788637,
              21.00351179406735
            ],
            [
              105.82876119237197,
              21.00350638804124
            ],
            [
              105.82883273993696,
              21.0034988282918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT156_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9605",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003520591419676,
          "to_latitude": 21.0034988282918,
          "to_longitude": 105.82883273993696,
          "from_longitude": 105.82848082318438
        }
      },
      {
        "id": 9598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82857587998376,
            21.003503349411506,
            105.82858018483964,
            21.00351575906439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82858018483964,
              21.003507100383317
            ],
            [
              105.82858018269158,
              21.00350705974856
            ],
            [
              105.82857997272205,
              21.003503349411506
            ],
            [
              105.82857837950641,
              21.003503455619047
            ],
            [
              105.82857587998376,
              21.003503519270666
            ],
            [
              105.82857598851734,
              21.00350732403711
            ],
            [
              105.82857621687472,
              21.00351575906439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT156_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9606",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003507100383317,
          "to_latitude": 21.00351575906439,
          "to_longitude": 105.82857621687472,
          "from_longitude": 105.82858018483964
        }
      },
      {
        "id": 9599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82889489673661,
            21.003451741092963,
            105.82912931650316,
            21.003484628559715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82889489673661,
              21.003484628559715
            ],
            [
              105.82889531386333,
              21.003484570528283
            ],
            [
              105.82912931650316,
              21.003451741092963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9607",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003484628559715,
          "to_latitude": 21.003451741092963,
          "to_longitude": 105.82912931650316,
          "from_longitude": 105.82889489673661
        }
      },
      {
        "id": 9600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82865910630461,
            21.003592260637134,
            105.8289230965481,
            21.003619449722326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289230965481,
              21.003592260637134
            ],
            [
              105.8289227067516,
              21.003592390793667
            ],
            [
              105.82890514756305,
              21.003598235281043
            ],
            [
              105.82865910630461,
              21.003619449722326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT157_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9608",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003592260637134,
          "to_latitude": 21.003619449722326,
          "to_longitude": 105.82865910630461,
          "from_longitude": 105.8289230965481
        }
      },
      {
        "id": 9601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892706404886,
            21.003673212887378,
            105.82904391483471,
            21.003777356657917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82892706404886,
              21.003673212887378
            ],
            [
              105.82894089094255,
              21.00373889503892
            ],
            [
              105.8289673158331,
              21.00373500440316
            ],
            [
              105.82901193588818,
              21.003728435591718
            ],
            [
              105.82903534863888,
              21.003726883618857
            ],
            [
              105.82904391483471,
              21.003777356657917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT145_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9609",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003673212887378,
          "to_latitude": 21.003777356657917,
          "to_longitude": 105.82904391483471,
          "from_longitude": 105.82892706404886
        }
      },
      {
        "id": 9602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82896550655772,
            21.003726294366878,
            105.8289673158331,
            21.00373500440316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289673158331,
              21.00373500440316
            ],
            [
              105.82896550655772,
              21.003726294366878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT145_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9610",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00373500440316,
          "to_latitude": 21.003726294366878,
          "to_longitude": 105.82896550655772,
          "from_longitude": 105.8289673158331
        }
      },
      {
        "id": 9603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82899636578207,
            21.00371014900395,
            105.82906729682826,
            21.003831354178672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82899636578207,
              21.003717381866544
            ],
            [
              105.82903972308611,
              21.00371014900395
            ],
            [
              105.8290587598143,
              21.003793827851567
            ],
            [
              105.82906729682826,
              21.003831354178672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT144_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9611",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003717381866544,
          "to_latitude": 21.003831354178672,
          "to_longitude": 105.82906729682826,
          "from_longitude": 105.82899636578207
        }
      },
      {
        "id": 9604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82906729682826,
            21.003813550808154,
            105.82916127819215,
            21.003831354178672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82906729682826,
              21.003831354178672
            ],
            [
              105.82916127819215,
              21.003813550808154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT144_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9612",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003831354178672,
          "to_latitude": 21.003813550808154,
          "to_longitude": 105.82916127819215,
          "from_longitude": 105.82906729682826
        }
      },
      {
        "id": 9605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903791428794,
            21.003842748792625,
            105.8292018701126,
            21.004270104868098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903791428794,
              21.003842748792625
            ],
            [
              105.82906061891696,
              21.00394824798875
            ],
            [
              105.8291025717031,
              21.003941838466805
            ],
            [
              105.82912942477452,
              21.003964783294983
            ],
            [
              105.82917709544499,
              21.004129445942336
            ],
            [
              105.8292018701126,
              21.004270104868098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT141_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9613",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003842748792625,
          "to_latitude": 21.004270104868098,
          "to_longitude": 105.8292018701126,
          "from_longitude": 105.82903791428794
        }
      },
      {
        "id": 9606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82895527367867,
            21.004270104868098,
            105.8292018701126,
            21.004365313900056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292018701126,
              21.004270104868098
            ],
            [
              105.82895527367867,
              21.004365313900056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT141_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9614",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004270104868098,
          "to_latitude": 21.004365313900056,
          "to_longitude": 105.82895527367867,
          "from_longitude": 105.8292018701126
        }
      },
      {
        "id": 9607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292018701126,
            21.004158308471098,
            105.82943886171874,
            21.004270104868098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292018701126,
              21.004270104868098
            ],
            [
              105.82943886171874,
              21.004158308471098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT141_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9615",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004270104868098,
          "to_latitude": 21.004158308471098,
          "to_longitude": 105.82943886171874,
          "from_longitude": 105.8292018701126
        }
      },
      {
        "id": 9608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82937918351693,
            21.003999509454747,
            105.82943886171874,
            21.004158308471098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82943886171874,
              21.004158308471098
            ],
            [
              105.82937918351693,
              21.003999509454747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT141_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9616",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004158308471098,
          "to_latitude": 21.003999509454747,
          "to_longitude": 105.82937918351693,
          "from_longitude": 105.82943886171874
        }
      },
      {
        "id": 9609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288675230037,
            21.00388023380029,
            105.8289051790562,
            21.00408586911285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82888876913624,
              21.00388023380029
            ],
            [
              105.8289051790562,
              21.00397594740575
            ],
            [
              105.8288675230037,
              21.003982899568825
            ],
            [
              105.82888023746438,
              21.004064857867032
            ],
            [
              105.82888349687403,
              21.00408586911285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT143_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9617",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00388023380029,
          "to_latitude": 21.00408586911285,
          "to_longitude": 105.82888349687403,
          "from_longitude": 105.82888876913624
        }
      },
      {
        "id": 9610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82877306546405,
            21.00408586911285,
            105.82888349687403,
            21.004099658108732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82888349687403,
              21.00408586911285
            ],
            [
              105.82877306546405,
              21.004099658108732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT143_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9618",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00408586911285,
          "to_latitude": 21.004099658108732,
          "to_longitude": 105.82877306546405,
          "from_longitude": 105.82888349687403
        }
      },
      {
        "id": 9611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82888023746438,
            21.0040642593597,
            105.82888499153587,
            21.004064857867032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82888023746438,
              21.004064857867032
            ],
            [
              105.82888419470625,
              21.004064359885447
            ],
            [
              105.82888499153587,
              21.0040642593597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT143_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9619",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004064857867032,
          "to_latitude": 21.0040642593597,
          "to_longitude": 105.82888499153587,
          "from_longitude": 105.82888023746438
        }
      },
      {
        "id": 9612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82888356123729,
            21.004101703267605,
            105.82892583192645,
            21.004317593500062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82888356123729,
              21.004101703267605
            ],
            [
              105.82890194323488,
              21.00421922832117
            ],
            [
              105.82891197977555,
              21.00428339886297
            ],
            [
              105.82892583192645,
              21.004317593500062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT142_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9620",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004101703267605,
          "to_latitude": 21.004317593500062,
          "to_longitude": 105.82892583192645,
          "from_longitude": 105.82888356123729
        }
      },
      {
        "id": 9613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890194323488,
            21.004218384911066,
            105.82890839268752,
            21.00421922832117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890194323488,
              21.00421922832117
            ],
            [
              105.82890241324475,
              21.00421916641923
            ],
            [
              105.82890753916841,
              21.00421849655175
            ],
            [
              105.82890839268752,
              21.004218384911066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT142_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9621",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00421922832117,
          "to_latitude": 21.004218384911066,
          "to_longitude": 105.82890839268752,
          "from_longitude": 105.82890194323488
        }
      },
      {
        "id": 9614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294552332734,
            21.004253623873662,
            105.82955808090473,
            21.004398629732066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294552332734,
              21.004253623873662
            ],
            [
              105.82955808090473,
              21.004398629732066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT136_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9622",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004253623873662,
          "to_latitude": 21.004398629732066,
          "to_longitude": 105.82955808090473,
          "from_longitude": 105.8294552332734
        }
      },
      {
        "id": 9615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82957113432049,
            21.004393050105147,
            105.82966201458389,
            21.00457284870258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82957113432049,
              21.004393050105147
            ],
            [
              105.82959016324173,
              21.00443069777702
            ],
            [
              105.82966201458389,
              21.00457284870258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT136_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9623",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004393050105147,
          "to_latitude": 21.00457284870258,
          "to_longitude": 105.82966201458389,
          "from_longitude": 105.82957113432049
        }
      },
      {
        "id": 9616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82969518824636,
            21.00463928459555,
            105.82981900276674,
            21.004893637440905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82969518824636,
              21.00463928459555
            ],
            [
              105.82974572252185,
              21.004743096264388
            ],
            [
              105.82975709593092,
              21.004766460578224
            ],
            [
              105.82981900276674,
              21.004893637440905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT137_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9624",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00463928459555,
          "to_latitude": 21.004893637440905,
          "to_longitude": 105.82981900276674,
          "from_longitude": 105.82969518824636
        }
      },
      {
        "id": 9617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82960679363113,
            21.00491831378646,
            105.8298447124135,
            21.005010266880767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298447124135,
              21.00491831378646
            ],
            [
              105.82984201244783,
              21.00491935753215
            ],
            [
              105.82960679363113,
              21.005010266880767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT138_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9625",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00491831378646,
          "to_latitude": 21.005010266880767,
          "to_longitude": 105.82960679363113,
          "from_longitude": 105.8298447124135
        }
      },
      {
        "id": 9618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959016324173,
            21.004423406890517,
            105.82960722010328,
            21.00443069777702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82959016324173,
              21.00443069777702
            ],
            [
              105.82959715487591,
              21.004427709244514
            ],
            [
              105.8296034191328,
              21.00442503135417
            ],
            [
              105.82960580085737,
              21.004424013550704
            ],
            [
              105.82960722010328,
              21.004423406890517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT136_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9626",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00443069777702,
          "to_latitude": 21.004423406890517,
          "to_longitude": 105.82960722010328,
          "from_longitude": 105.82959016324173
        }
      },
      {
        "id": 9619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82974572252185,
            21.004737287264707,
            105.82975931910863,
            21.004743096264388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82974572252185,
              21.004743096264388
            ],
            [
              105.82975849865147,
              21.004737638103578
            ],
            [
              105.82975931910863,
              21.004737287264707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT137_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9627",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004743096264388,
          "to_latitude": 21.004737287264707,
          "to_longitude": 105.82975931910863,
          "from_longitude": 105.82974572252185
        }
      },
      {
        "id": 9620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82983423121372,
            21.004935761032,
            105.82990868061695,
            21.00508879117245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82983423121372,
              21.004935761032
            ],
            [
              105.82986923616865,
              21.005007714797916
            ],
            [
              105.82987185236685,
              21.00501309159408
            ],
            [
              105.82990868061695,
              21.00508879117245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT139_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9628",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004935761032,
          "to_latitude": 21.00508879117245,
          "to_longitude": 105.82990868061695,
          "from_longitude": 105.82983423121372
        }
      },
      {
        "id": 9621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82986923616865,
            21.005001511982446,
            105.82988505539996,
            21.005007714797916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82986923616865,
              21.005007714797916
            ],
            [
              105.82987418771592,
              21.005005773104855
            ],
            [
              105.8298764873683,
              21.005004871310614
            ],
            [
              105.82988209349412,
              21.005002673522224
            ],
            [
              105.82988505539996,
              21.005001511982446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT139_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9629",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005007714797916,
          "to_latitude": 21.005001511982446,
          "to_longitude": 105.82988505539996,
          "from_longitude": 105.82986923616865
        }
      },
      {
        "id": 9622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900263792622,
            21.00509255231156,
            105.82992922002443,
            21.005433455819635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82992922002443,
              21.00509255231156
            ],
            [
              105.82992730470829,
              21.005093727716474
            ],
            [
              105.82982718613081,
              21.005137488566927
            ],
            [
              105.82975297881062,
              21.00516602109153
            ],
            [
              105.82954420591243,
              21.005241217160766
            ],
            [
              105.82944303130557,
              21.005269546796022
            ],
            [
              105.8294430063625,
              21.005269558659528
            ],
            [
              105.82942926100706,
              21.005269171323018
            ],
            [
              105.82923825282225,
              21.005338488961133
            ],
            [
              105.82923822787913,
              21.005338500824607
            ],
            [
              105.82923663921851,
              21.005339086629927
            ],
            [
              105.82900940693081,
              21.00543072581395
            ],
            [
              105.82900263792622,
              21.005433455819635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT140_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9630",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 104.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00509255231156,
          "to_latitude": 21.005433455819635,
          "to_longitude": 105.82900263792622,
          "from_longitude": 105.82992922002443
        }
      },
      {
        "id": 9623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82915790659797,
            21.00516227973961,
            105.82923822787913,
            21.005338500824607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82923822787913,
              21.005338500824607
            ],
            [
              105.82923729510227,
              21.005336455045022
            ],
            [
              105.82915790659797,
              21.00516227973961
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9631",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005338500824607,
          "to_latitude": 21.00516227973961,
          "to_longitude": 105.82915790659797,
          "from_longitude": 105.82923822787913
        }
      },
      {
        "id": 9624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82939964331098,
            21.005154866015378,
            105.8294430063625,
            21.005269558659528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294430063625,
              21.005269558659528
            ],
            [
              105.8294428736047,
              21.005269207048173
            ],
            [
              105.82939964331098,
              21.005154866015378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9632",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005269558659528,
          "to_latitude": 21.005154866015378,
          "to_longitude": 105.82939964331098,
          "from_longitude": 105.8294430063625
        }
      },
      {
        "id": 9625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82939114688327,
            21.005093211626228,
            105.82939964331098,
            21.005154866015378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82939964331098,
              21.005154866015378
            ],
            [
              105.82939114688327,
              21.005093211626228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT140_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9633",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005154866015378,
          "to_latitude": 21.005093211626228,
          "to_longitude": 105.82939114688327,
          "from_longitude": 105.82939964331098
        }
      },
      {
        "id": 9626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82871828759262,
            21.004408134485338,
            105.82908963081692,
            21.004566554350653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82871828759262,
              21.004566554350653
            ],
            [
              105.82890395978418,
              21.004487344062447
            ],
            [
              105.8289894509923,
              21.004450872685965
            ],
            [
              105.82908963081692,
              21.004408134485338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT134_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9634",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004566554350653,
          "to_latitude": 21.004408134485338,
          "to_longitude": 105.82908963081692,
          "from_longitude": 105.82871828759262
        }
      },
      {
        "id": 9627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82897841834664,
            21.004428226527864,
            105.8289894509923,
            21.004450872685965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289894509923,
              21.004450872685965
            ],
            [
              105.82898515954955,
              21.004442064546872
            ],
            [
              105.8289825522045,
              21.00443671569245
            ],
            [
              105.82897994677332,
              21.004431365022207
            ],
            [
              105.82897841834664,
              21.004428226527864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT134_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9635",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004450872685965,
          "to_latitude": 21.004428226527864,
          "to_longitude": 105.82897841834664,
          "from_longitude": 105.8289894509923
        }
      },
      {
        "id": 9628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291195531119,
            21.0043673227507,
            105.82918131892312,
            21.004602635756427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291195531119,
              21.0043673227507
            ],
            [
              105.82912363128635,
              21.004381401349676
            ],
            [
              105.82914716371432,
              21.004462605113158
            ],
            [
              105.82916223706344,
              21.004524401805313
            ],
            [
              105.82918131892312,
              21.004602635756427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9636",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0043673227507,
          "to_latitude": 21.004602635756427,
          "to_longitude": 105.82918131892312,
          "from_longitude": 105.8291195531119
        }
      },
      {
        "id": 9629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82914091183093,
            21.004262849690104,
            105.8294338654583,
            21.004390046854496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914091183093,
              21.004390046854496
            ],
            [
              105.82925277007438,
              21.004341480049934
            ],
            [
              105.82928255395264,
              21.004328547956458
            ],
            [
              105.8294338654583,
              21.004262849690104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT135_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9637",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004390046854496,
          "to_latitude": 21.004262849690104,
          "to_longitude": 105.8294338654583,
          "from_longitude": 105.82914091183093
        }
      },
      {
        "id": 9630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924002997302,
            21.00431533138631,
            105.82925277007438,
            21.004341480049934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925277007438,
              21.004341480049934
            ],
            [
              105.82924844554294,
              21.004332604336945
            ],
            [
              105.82924635090878,
              21.004328307957465
            ],
            [
              105.82924425818842,
              21.00432400976219
            ],
            [
              105.82924002997302,
              21.00431533138631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT135_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9638",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004341480049934,
          "to_latitude": 21.00431533138631,
          "to_longitude": 105.82924002997302,
          "from_longitude": 105.82925277007438
        }
      },
      {
        "id": 9631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82864353594259,
            21.004656503428425,
            105.82874604263135,
            21.004697877147365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82864353594259,
              21.004697877147365
            ],
            [
              105.8287125461923,
              21.004670023443296
            ],
            [
              105.82874604263135,
              21.004656503428425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9639",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004697877147365,
          "to_latitude": 21.004656503428425,
          "to_longitude": 105.82874604263135,
          "from_longitude": 105.82864353594259
        }
      },
      {
        "id": 9632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82868599947271,
            21.004542482502384,
            105.8287125461923,
            21.004670023443296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8287125461923,
              21.004670023443296
            ],
            [
              105.82870906513217,
              21.004654857151415
            ],
            [
              105.82868655103636,
              21.00454517052935
            ],
            [
              105.82868599947271,
              21.004542482502384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9640",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004670023443296,
          "to_latitude": 21.004542482502384,
          "to_longitude": 105.82868599947271,
          "from_longitude": 105.8287125461923
        }
      },
      {
        "id": 9633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867529154085,
            21.00454517052935,
            105.82868655103636,
            21.00454571399539
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82868655103636,
              21.00454517052935
            ],
            [
              105.82868439131552,
              21.004545284915764
            ],
            [
              105.82868435381374,
              21.004545286904808
            ],
            [
              105.8286787139342,
              21.004545552816197
            ],
            [
              105.82867529154085,
              21.00454571399539
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9641",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00454517052935,
          "to_latitude": 21.00454571399539,
          "to_longitude": 105.82867529154085,
          "from_longitude": 105.82868655103636
        }
      },
      {
        "id": 9634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82863649469542,
            21.004718365900903,
            105.8287263862559,
            21.004889634338888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82863649469542,
              21.004718365900903
            ],
            [
              105.82868048663327,
              21.00480406483569
            ],
            [
              105.82868065671614,
              21.0048043828466
            ],
            [
              105.8287263862559,
              21.004889634338888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9642",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004718365900903,
          "to_latitude": 21.004889634338888,
          "to_longitude": 105.8287263862559,
          "from_longitude": 105.82863649469542
        }
      },
      {
        "id": 9635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82867831257053,
            21.0048043828466,
            105.82868065671614,
            21.004805414906087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82868065671614,
              21.0048043828466
            ],
            [
              105.82867888925142,
              21.0048051610013
            ],
            [
              105.82867831257053,
              21.004805414906087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9643",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0048043828466,
          "to_latitude": 21.004805414906087,
          "to_longitude": 105.82867831257053,
          "from_longitude": 105.82868065671614
        }
      },
      {
        "id": 9636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82874623757506,
            21.004931488042438,
            105.82890978049906,
            21.00524168159537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82874623757506,
              21.004931488042438
            ],
            [
              105.82883594808537,
              21.005096748125823
            ],
            [
              105.82883642165123,
              21.005097677949532
            ],
            [
              105.82890978049906,
              21.00524168159537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9644",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004931488042438,
          "to_latitude": 21.00524168159537,
          "to_longitude": 105.82890978049906,
          "from_longitude": 105.82874623757506
        }
      },
      {
        "id": 9637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883004957395,
            21.005097677949532,
            105.82883642165123,
            21.005101043693184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883642165123,
              21.005097677949532
            ],
            [
              105.82883004957395,
              21.005101043693184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9645",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005097677949532,
          "to_latitude": 21.005101043693184,
          "to_longitude": 105.82883004957395,
          "from_longitude": 105.82883642165123
        }
      },
      {
        "id": 9638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82873335209317,
            21.00480537965589,
            105.82909487154566,
            21.00491105657119
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82873335209317,
              21.00491105657119
            ],
            [
              105.82893093323318,
              21.004831781935057
            ],
            [
              105.82895928198445,
              21.0048311877353
            ],
            [
              105.82901352043488,
              21.004819632321755
            ],
            [
              105.82909487154566,
              21.00480537965589
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9646",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00491105657119,
          "to_latitude": 21.00480537965589,
          "to_longitude": 105.82909487154566,
          "from_longitude": 105.82873335209317
        }
      },
      {
        "id": 9639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909487154566,
            21.00480537965589,
            105.82921438170949,
            21.00507368418017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82909487154566,
              21.00480537965589
            ],
            [
              105.82912794396354,
              21.00487041254236
            ],
            [
              105.82916578064014,
              21.00501868073686
            ],
            [
              105.82917010557239,
              21.005033197099035
            ],
            [
              105.82918216699396,
              21.00507368418017
            ],
            [
              105.82921438170949,
              21.00506720103255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9647",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00480537965589,
          "to_latitude": 21.00506720103255,
          "to_longitude": 105.82921438170949,
          "from_longitude": 105.82909487154566
        }
      },
      {
        "id": 9640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82893163201918,
            21.00471170448098,
            105.82895928198445,
            21.0048311877353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895928198445,
              21.0048311877353
            ],
            [
              105.82893163201918,
              21.00471170448098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9648",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0048311877353,
          "to_latitude": 21.00471170448098,
          "to_longitude": 105.82893163201918,
          "from_longitude": 105.82895928198445
        }
      },
      {
        "id": 9641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909487154566,
            21.004762318200704,
            105.82917962911861,
            21.00480537965589
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82909487154566,
              21.00480537965589
            ],
            [
              105.82917265178838,
              21.004794711189916
            ],
            [
              105.82916737128384,
              21.004764445403563
            ],
            [
              105.82917962911861,
              21.004762318200704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9649",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00480537965589,
          "to_latitude": 21.004762318200704,
          "to_longitude": 105.82917962911861,
          "from_longitude": 105.82909487154566
        }
      },
      {
        "id": 9642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82912794396354,
            21.004845522691763,
            105.82924374238586,
            21.00487041254236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912794396354,
              21.00487041254236
            ],
            [
              105.82924374238586,
              21.004845522691763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9650",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00487041254236,
          "to_latitude": 21.004845522691763,
          "to_longitude": 105.82924374238586,
          "from_longitude": 105.82912794396354
        }
      },
      {
        "id": 9643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924374238586,
            21.004845522691763,
            105.82926474781249,
            21.004931543005075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924374238586,
              21.004845522691763
            ],
            [
              105.82925729474283,
              21.00489976858089
            ],
            [
              105.82926474781249,
              21.004931543005075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9651",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004845522691763,
          "to_latitude": 21.004931543005075,
          "to_longitude": 105.82926474781249,
          "from_longitude": 105.82924374238586
        }
      },
      {
        "id": 9644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925729474283,
            21.00484801546674,
            105.82942060721535,
            21.00489976858089
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82925729474283,
              21.00489976858089
            ],
            [
              105.82936243597497,
              21.004875458058184
            ],
            [
              105.82936994721885,
              21.00486362441416
            ],
            [
              105.82942060721535,
              21.00484801546674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9652",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00489976858089,
          "to_latitude": 21.00484801546674,
          "to_longitude": 105.82942060721535,
          "from_longitude": 105.82925729474283
        }
      },
      {
        "id": 9645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292212922083,
            21.004750092372408,
            105.82924374238586,
            21.004845522691763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924374238586,
              21.004845522691763
            ],
            [
              105.82923848345416,
              21.004823168165075
            ],
            [
              105.8292212922083,
              21.004750092372408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9653",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004845522691763,
          "to_latitude": 21.004750092372408,
          "to_longitude": 105.8292212922083,
          "from_longitude": 105.82924374238586
        }
      },
      {
        "id": 9646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292212922083,
            21.004734264993687,
            105.82929751261163,
            21.004750092372408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292212922083,
              21.004750092372408
            ],
            [
              105.82929751261163,
              21.004734264993687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9654",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004750092372408,
          "to_latitude": 21.004734264993687,
          "to_longitude": 105.82929751261163,
          "from_longitude": 105.8292212922083
        }
      },
      {
        "id": 9647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82923848345416,
            21.004787939599282,
            105.82938733891834,
            21.004823168165075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82923848345416,
              21.004823168165075
            ],
            [
              105.82923858431785,
              21.00482314418975
            ],
            [
              105.82938733891834,
              21.004787939599282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9655",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004823168165075,
          "to_latitude": 21.004787939599282,
          "to_longitude": 105.82938733891834,
          "from_longitude": 105.82923848345416
        }
      },
      {
        "id": 9648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772416592942,
            21.003134725358862,
            105.82778331832768,
            21.00327966666602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82772416592942,
              21.003134725358862
            ],
            [
              105.82773137088331,
              21.00324733808854
            ],
            [
              105.82777816170373,
              21.003243895953865
            ],
            [
              105.82778331832768,
              21.00327966666602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT162_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9656",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003134725358862,
          "to_latitude": 21.00327966666602,
          "to_longitude": 105.82778331832768,
          "from_longitude": 105.82772416592942
        }
      },
      {
        "id": 9649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82783501208975,
            21.00313537764261,
            105.8278580022656,
            21.003250446239406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82783501208975,
              21.00313537764261
            ],
            [
              105.8278580022656,
              21.003250446239406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT162_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9657",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00313537764261,
          "to_latitude": 21.003250446239406,
          "to_longitude": 105.8278580022656,
          "from_longitude": 105.82783501208975
        }
      },
      {
        "id": 9650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82785191543276,
            21.003902960692784,
            105.82804774288931,
            21.003924828454277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82804767797856,
              21.003924828454277
            ],
            [
              105.82804774288931,
              21.003917590581228
            ],
            [
              105.82794690774007,
              21.00391005774693
            ],
            [
              105.82785191543276,
              21.003902960692784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT151_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9658",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003924828454277,
          "to_latitude": 21.003902960692784,
          "to_longitude": 105.82785191543276,
          "from_longitude": 105.82804767797856
        }
      },
      {
        "id": 9651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82794690774007,
            21.003815647230503,
            105.82794891278013,
            21.00391005774693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82794690774007,
              21.00391005774693
            ],
            [
              105.82794891278013,
              21.003815647230503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT151_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9659",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00391005774693,
          "to_latitude": 21.003815647230503,
          "to_longitude": 105.82794891278013,
          "from_longitude": 105.82794690774007
        }
      },
      {
        "id": 9652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290526212983,
            21.003793827851567,
            105.8290587598143,
            21.003794714010457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8290587598143,
              21.003793827851567
            ],
            [
              105.8290526212983,
              21.003794714010457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT144_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9660",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003793827851567,
          "to_latitude": 21.003794714010457,
          "to_longitude": 105.8290526212983,
          "from_longitude": 105.8290587598143
        }
      },
      {
        "id": 9653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82916223706344,
            21.004496292087566,
            105.82926372986253,
            21.004524401805313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82916223706344,
              21.004524401805313
            ],
            [
              105.82926372986253,
              21.004496292087566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9661",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004524401805313,
          "to_latitude": 21.004496292087566,
          "to_longitude": 105.82926372986253,
          "from_longitude": 105.82916223706344
        }
      },
      {
        "id": 9654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82790105029174,
            21.00210345613811,
            105.82852929628298,
            21.002688080997846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82790105029174,
              21.002688080997846
            ],
            [
              105.82800645965472,
              21.00266901959598
            ],
            [
              105.82799907421926,
              21.00257585332888
            ],
            [
              105.82814275780872,
              21.002547767581678
            ],
            [
              105.82813157991279,
              21.002464226495427
            ],
            [
              105.82811804547892,
              21.002363074912108
            ],
            [
              105.82822025849501,
              21.002332884016305
            ],
            [
              105.82828379009965,
              21.002309404396193
            ],
            [
              105.8283098426043,
              21.002299776576006
            ],
            [
              105.82837139123637,
              21.002270791469194
            ],
            [
              105.828360489132,
              21.00223131660006
            ],
            [
              105.82834272228074,
              21.002172666207006
            ],
            [
              105.82852929628298,
              21.00210345613811
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT176_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9662",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 117.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002688080997846,
          "to_latitude": 21.00210345613811,
          "to_longitude": 105.82852929628298,
          "from_longitude": 105.82790105029174
        }
      },
      {
        "id": 9655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82790056220979,
            21.002688080997846,
            105.82790511581123,
            21.00289520099349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82790105029174,
              21.002688080997846
            ],
            [
              105.82790056220979,
              21.00272601981169
            ],
            [
              105.82790473133885,
              21.002880950844013
            ],
            [
              105.82790511581123,
              21.00289520099349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT176_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9663",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002688080997846,
          "to_latitude": 21.00289520099349,
          "to_longitude": 105.82790511581123,
          "from_longitude": 105.82790105029174
        }
      },
      {
        "id": 9656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82322475134026,
            21.003986503237428,
            105.8233742027736,
            21.004286120694374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82322475134026,
              21.003986503237428
            ],
            [
              105.82326452355419,
              21.004066307037593
            ],
            [
              105.8232876726272,
              21.004112226259835
            ],
            [
              105.82329693728106,
              21.0041308078077
            ],
            [
              105.8233196787936,
              21.00417642189868
            ],
            [
              105.82335212847784,
              21.0042415068705
            ],
            [
              105.8233742027736,
              21.004286120694374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT287_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9664",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003986503237428,
          "to_latitude": 21.004286120694374,
          "to_longitude": 105.8233742027736,
          "from_longitude": 105.82322475134026
        }
      },
      {
        "id": 9657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82387442818252,
            21.00440795890251,
            105.82399091498662,
            21.004607146483654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82399091498662,
              21.004607146483654
            ],
            [
              105.82392129201364,
              21.004488337883476
            ],
            [
              105.82391981899171,
              21.00448581146084
            ],
            [
              105.82391213927612,
              21.004472639837015
            ],
            [
              105.82387442818252,
              21.00440795890251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT302_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9665",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004607146483654,
          "to_latitude": 21.00440795890251,
          "to_longitude": 105.82387442818252,
          "from_longitude": 105.82399091498662
        }
      },
      {
        "id": 9658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82344646507562,
            21.0030165058312,
            105.82377247005434,
            21.0035395672921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82344646507562,
              21.0030165058312
            ],
            [
              105.8234761118728,
              21.00307285839869
            ],
            [
              105.82349430064879,
              21.003107401960527
            ],
            [
              105.82359430506767,
              21.003270423099238
            ],
            [
              105.82360738388314,
              21.00329013056633
            ],
            [
              105.82368560049026,
              21.003419851189822
            ],
            [
              105.82370644606084,
              21.003454402642372
            ],
            [
              105.82377247005434,
              21.0035395672921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT280_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9666",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0030165058312,
          "to_latitude": 21.0035395672921,
          "to_longitude": 105.82377247005434,
          "from_longitude": 105.82344646507562
        }
      },
      {
        "id": 9659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82236166432085,
            21.002791945706505,
            105.8225944379433,
            21.003221145820326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82236166432085,
              21.002791945706505
            ],
            [
              105.82236453806277,
              21.00279926693697
            ],
            [
              105.82238343187763,
              21.002847380027397
            ],
            [
              105.82258800367946,
              21.003209491856207
            ],
            [
              105.82258735123786,
              21.003216457090048
            ],
            [
              105.82259301178439,
              21.003218564061456
            ],
            [
              105.8225944379433,
              21.003221145820326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT263_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9667",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002791945706505,
          "to_latitude": 21.003221145820326,
          "to_longitude": 105.8225944379433,
          "from_longitude": 105.82236166432085
        }
      },
      {
        "id": 9660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82489106165713,
            21.008708651209552,
            105.82551339743048,
            21.008915298892937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551339743048,
              21.008734016215822
            ],
            [
              105.82550406854519,
              21.00871715216004
            ],
            [
              105.82549936813409,
              21.008708651209552
            ],
            [
              105.82505698761912,
              21.008915298892937
            ],
            [
              105.82489106165713,
              21.008871034962727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9668",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008734016215822,
          "to_latitude": 21.008871034962727,
          "to_longitude": 105.82489106165713,
          "from_longitude": 105.82551339743048
        }
      },
      {
        "id": 9661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82536344565884,
            21.00834232416551,
            105.82556786714424,
            21.008728519681995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552465116933,
              21.008728519681995
            ],
            [
              105.8255154380623,
              21.008711894420312
            ],
            [
              105.82536344565884,
              21.008437576498796
            ],
            [
              105.82556786714424,
              21.00834232416551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9669",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008728519681995,
          "to_latitude": 21.00834232416551,
          "to_longitude": 105.82556786714424,
          "from_longitude": 105.82552465116933
        }
      },
      {
        "id": 9662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8255344516113,
            21.008500681830608,
            105.82596669739118,
            21.008707136114765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82596669739118,
              21.008500681830608
            ],
            [
              105.82577926658745,
              21.008587498248716
            ],
            [
              105.82575858882326,
              21.00859729562985
            ],
            [
              105.82568740004396,
              21.008631027024713
            ],
            [
              105.82568438554335,
              21.008636459205515
            ],
            [
              105.82568348989537,
              21.008636436454363
            ],
            [
              105.82567667683493,
              21.00863626718817
            ],
            [
              105.8255344516113,
              21.008707136114765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9670",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008500681830608,
          "to_latitude": 21.008707136114765,
          "to_longitude": 105.8255344516113,
          "from_longitude": 105.82596669739118
        }
      },
      {
        "id": 9663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82575858882326,
            21.00859729562985,
            105.82576537266094,
            21.00861015808107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82575858882326,
              21.00859729562985
            ],
            [
              105.82576014893877,
              21.008600253353183
            ],
            [
              105.82576275012478,
              21.008605188607635
            ],
            [
              105.82576414011928,
              21.008607821091545
            ],
            [
              105.82576537266094,
              21.00861015808107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9671",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00859729562985,
          "to_latitude": 21.00861015808107,
          "to_longitude": 105.82576537266094,
          "from_longitude": 105.82575858882326
        }
      },
      {
        "id": 9664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556992849734,
            21.008395650615505,
            105.8256890529801,
            21.008648230895048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256890529801,
              21.008648230895048
            ],
            [
              105.82568627242932,
              21.0086423390893
            ],
            [
              105.82568348989537,
              21.008636436454363
            ],
            [
              105.82556992849734,
              21.008395650615505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9672",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008648230895048,
          "to_latitude": 21.008395650615505,
          "to_longitude": 105.82556992849734,
          "from_longitude": 105.8256890529801
        }
      },
      {
        "id": 9665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82561487940296,
            21.007716950172824,
            105.82601549923041,
            21.00847328944541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82601549923041,
              21.00847328944541
            ],
            [
              105.82595353309272,
              21.00836510725239
            ],
            [
              105.82592900675351,
              21.008322287789735
            ],
            [
              105.82561487940296,
              21.007716950172824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9673",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00847328944541,
          "to_latitude": 21.007716950172824,
          "to_longitude": 105.82561487940296,
          "from_longitude": 105.82601549923041
        }
      },
      {
        "id": 9666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257402834912,
            21.008381057475216,
            105.82591721425052,
            21.008435051275313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82591721425052,
              21.008381057475216
            ],
            [
              105.82587436515043,
              21.00839998480488
            ],
            [
              105.82580072856855,
              21.008432509670854
            ],
            [
              105.82579497408933,
              21.008435051275313
            ],
            [
              105.8257402834912,
              21.0084141965041
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9674",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008381057475216,
          "to_latitude": 21.0084141965041,
          "to_longitude": 105.8257402834912,
          "from_longitude": 105.82591721425052
        }
      },
      {
        "id": 9667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82566435988275,
            21.00827040988131,
            105.8257402834912,
            21.0084141965041
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257402834912,
              21.0084141965041
            ],
            [
              105.82573599492954,
              21.008406074723293
            ],
            [
              105.82566435988275,
              21.00827040988131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9675",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0084141965041,
          "to_latitude": 21.00827040988131,
          "to_longitude": 105.82566435988275,
          "from_longitude": 105.8257402834912
        }
      },
      {
        "id": 9668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573599492954,
            21.008396038445152,
            105.82575192538036,
            21.008406074723293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82575192538036,
              21.008396038445152
            ],
            [
              105.82575133777938,
              21.008396408909707
            ],
            [
              105.82573599492954,
              21.008406074723293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9676",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008396038445152,
          "to_latitude": 21.008406074723293,
          "to_longitude": 105.82573599492954,
          "from_longitude": 105.82575192538036
        }
      },
      {
        "id": 9669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82494251450387,
            21.00793192947816,
            105.82550381556469,
            21.008173662328723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82550381556469,
              21.00793192947816
            ],
            [
              105.82550296313937,
              21.00793224432315
            ],
            [
              105.82538747063815,
              21.007974836177983
            ],
            [
              105.8251114470042,
              21.008095205562352
            ],
            [
              105.82494251450387,
              21.008173662328723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9677",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00793192947816,
          "to_latitude": 21.008173662328723,
          "to_longitude": 105.82494251450387,
          "from_longitude": 105.82550381556469
        }
      },
      {
        "id": 9670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8248470973599,
            21.007974616440457,
            105.82494251450387,
            21.008173662328723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82494251450387,
              21.008173662328723
            ],
            [
              105.8248470973599,
              21.007974616440457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9678",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008173662328723,
          "to_latitude": 21.007974616440457,
          "to_longitude": 105.8248470973599,
          "from_longitude": 105.82494251450387
        }
      },
      {
        "id": 9671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251114470042,
            21.008095205562352,
            105.82523145670427,
            21.008325809105877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8251114470042,
              21.008095205562352
            ],
            [
              105.82523145670427,
              21.008325809105877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9679",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008095205562352,
          "to_latitude": 21.008325809105877,
          "to_longitude": 105.82523145670427,
          "from_longitude": 105.8251114470042
        }
      },
      {
        "id": 9672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8250380774825,
            21.00766471318785,
            105.82537127108519,
            21.007812771741857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82537127108519,
              21.00766471318785
            ],
            [
              105.82536886263509,
              21.007665783450722
            ],
            [
              105.82536083030635,
              21.00766935221786
            ],
            [
              105.8250380774825,
              21.007812771741857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9680",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00766471318785,
          "to_latitude": 21.007812771741857,
          "to_longitude": 105.8250380774825,
          "from_longitude": 105.82537127108519
        }
      },
      {
        "id": 9673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82488953978765,
            21.007812771741857,
            105.82506490814501,
            21.00794714952155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8250380774825,
              21.007812771741857
            ],
            [
              105.82506490814501,
              21.00786590857409
            ],
            [
              105.82488953978765,
              21.00794714952155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9681",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007812771741857,
          "to_latitude": 21.00794714952155,
          "to_longitude": 105.82488953978765,
          "from_longitude": 105.8250380774825
        }
      },
      {
        "id": 9674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496521808979,
            21.007536991896227,
            105.82531140005497,
            21.00767696347363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82531140005497,
              21.007536991896227
            ],
            [
              105.82530249643969,
              21.007540592885924
            ],
            [
              105.82496521808979,
              21.00767696347363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9682",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007536991896227,
          "to_latitude": 21.00767696347363,
          "to_longitude": 105.82496521808979,
          "from_longitude": 105.82531140005497
        }
      },
      {
        "id": 9675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82530457733384,
            21.007558317656777,
            105.82547874333521,
            21.00790209647807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82530457733384,
              21.007558317656777
            ],
            [
              105.82535578818404,
              21.007659400529498
            ],
            [
              105.82536083030635,
              21.00766935221786
            ],
            [
              105.82540684449484,
              21.00776017974574
            ],
            [
              105.82541220655955,
              21.00777076304261
            ],
            [
              105.82547874333521,
              21.00790209647807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9683",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007558317656777,
          "to_latitude": 21.00790209647807,
          "to_longitude": 105.82547874333521,
          "from_longitude": 105.82530457733384
        }
      },
      {
        "id": 9676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540684449484,
            21.00775560437364,
            105.82541645070992,
            21.00776017974574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82540684449484,
              21.00776017974574
            ],
            [
              105.8254121671044,
              21.00775764476556
            ],
            [
              105.82541633654142,
              21.007755658217768
            ],
            [
              105.82541645070992,
              21.00775560437364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9684",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00776017974574,
          "to_latitude": 21.00775560437364,
          "to_longitude": 105.82541645070992,
          "from_longitude": 105.82540684449484
        }
      },
      {
        "id": 9677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8253302175253,
            21.00757023603732,
            105.82548810935657,
            21.007875889078502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8253302175253,
              21.00757023603732
            ],
            [
              105.82539322894034,
              21.007688126722368
            ],
            [
              105.82542425180931,
              21.007749518528577
            ],
            [
              105.82548810935657,
              21.007875889078502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9685",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00757023603732,
          "to_latitude": 21.007875889078502,
          "to_longitude": 105.82548810935657,
          "from_longitude": 105.8253302175253
        }
      },
      {
        "id": 9678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82541547449748,
            21.007749518528577,
            105.82542425180931,
            21.00775369968668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82542425180931,
              21.007749518528577
            ],
            [
              105.82541989721014,
              21.007751592684418
            ],
            [
              105.82541556564087,
              21.007753656792968
            ],
            [
              105.82541547449748,
              21.00775369968668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9686",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007749518528577,
          "to_latitude": 21.00775369968668,
          "to_longitude": 105.82541547449748,
          "from_longitude": 105.82542425180931
        }
      },
      {
        "id": 9679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82551208759118,
            21.00793293890188,
            105.82574993989539,
            21.008385175937875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82551208759118,
              21.00793293890188
            ],
            [
              105.82560605351152,
              21.008111601087386
            ],
            [
              105.82563891203534,
              21.008174075942275
            ],
            [
              105.82574993989539,
              21.008385175937875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9687",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00793293890188,
          "to_latitude": 21.008385175937875,
          "to_longitude": 105.82574993989539,
          "from_longitude": 105.82551208759118
        }
      },
      {
        "id": 9680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82559587106606,
            21.008111601087386,
            105.82560605351152,
            21.00811634365329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82560605351152,
              21.008111601087386
            ],
            [
              105.82560478034767,
              21.008112194361516
            ],
            [
              105.8255988405077,
              21.00811496085871
            ],
            [
              105.82559587106606,
              21.00811634365329
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9688",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008111601087386,
          "to_latitude": 21.00811634365329,
          "to_longitude": 105.82559587106606,
          "from_longitude": 105.82560605351152
        }
      },
      {
        "id": 9681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82546437136745,
            21.00801469656879,
            105.82554666328609,
            21.008047330113346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82554666328609,
              21.00801469656879
            ],
            [
              105.82554521495427,
              21.008015270821208
            ],
            [
              105.82550611960413,
              21.008030774682176
            ],
            [
              105.82546437136745,
              21.008047330113346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9689",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00801469656879,
          "to_latitude": 21.008047330113346,
          "to_longitude": 105.82546437136745,
          "from_longitude": 105.82554666328609
        }
      },
      {
        "id": 9682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545898060852,
            21.008036953796996,
            105.82558413564244,
            21.00827502077568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82558413564244,
              21.00827502077568
            ],
            [
              105.8255651784069,
              21.008239652957844
            ],
            [
              105.82553610502076,
              21.00818541070471
            ],
            [
              105.82546437136745,
              21.008047330113346
            ],
            [
              105.82545898060852,
              21.008036953796996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9690",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00827502077568,
          "to_latitude": 21.008036953796996,
          "to_longitude": 105.82545898060852,
          "from_longitude": 105.82558413564244
        }
      },
      {
        "id": 9683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535625016591,
            21.0073546378242,
            105.8256809749455,
            21.007521819657008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256809749455,
              21.0073546378242
            ],
            [
              105.82552648647113,
              21.00743417577725
            ],
            [
              105.82546644822884,
              21.00746508549289
            ],
            [
              105.82535625016591,
              21.007521819657008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9691",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0073546378242,
          "to_latitude": 21.007521819657008,
          "to_longitude": 105.82535625016591,
          "from_longitude": 105.8256809749455
        }
      },
      {
        "id": 9684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8254545503694,
            21.007442650141027,
            105.82546644822884,
            21.00746508549289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82546644822884,
              21.00746508549289
            ],
            [
              105.82546172448484,
              21.007456181812916
            ],
            [
              105.8254545503694,
              21.007442650141027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9692",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00746508549289,
          "to_latitude": 21.007442650141027,
          "to_longitude": 105.8254545503694,
          "from_longitude": 105.82546644822884
        }
      },
      {
        "id": 9685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82571178091375,
            21.007248605395,
            105.82591214315701,
            21.007345313380327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82571178091375,
              21.007345313380327
            ],
            [
              105.82585159177755,
              21.007277831408484
            ],
            [
              105.82591214315701,
              21.007248605395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9693",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007345313380327,
          "to_latitude": 21.007248605395,
          "to_longitude": 105.82591214315701,
          "from_longitude": 105.82571178091375
        }
      },
      {
        "id": 9686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591214315701,
            21.007228356436663,
            105.82593735427643,
            21.007248605395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82591214315701,
              21.007248605395
            ],
            [
              105.82593735427643,
              21.007228356436663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9694",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007248605395,
          "to_latitude": 21.007228356436663,
          "to_longitude": 105.82593735427643,
          "from_longitude": 105.82591214315701
        }
      },
      {
        "id": 9687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82593735427643,
            21.007179728017142,
            105.82604874115806,
            21.007228356436663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593735427643,
              21.007228356436663
            ],
            [
              105.82604874115806,
              21.007179728017142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9695",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007228356436663,
          "to_latitude": 21.007179728017142,
          "to_longitude": 105.82604874115806,
          "from_longitude": 105.82593735427643
        }
      },
      {
        "id": 9688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82584240177007,
            21.007260498821452,
            105.82585159177755,
            21.007277831408484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82585159177755,
              21.007277831408484
            ],
            [
              105.82584832013573,
              21.00727166469634
            ],
            [
              105.82584389375184,
              21.00726331326235
            ],
            [
              105.82584240177007,
              21.007260498821452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9696",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007277831408484,
          "to_latitude": 21.007260498821452,
          "to_longitude": 105.82584240177007,
          "from_longitude": 105.82585159177755
        }
      },
      {
        "id": 9689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8260906523714,
            21.007143909441368,
            105.82617517525635,
            21.007280051690522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8260906523714,
              21.007143909441368
            ],
            [
              105.82617517525635,
              21.007280051690522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9697",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007143909441368,
          "to_latitude": 21.007280051690522,
          "to_longitude": 105.82617517525635,
          "from_longitude": 105.8260906523714
        }
      },
      {
        "id": 9690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613115308502,
            21.006928707990006,
            105.82659001778897,
            21.007144018133552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613115308502,
              21.007144018133552
            ],
            [
              105.82631865155419,
              21.00705517781334
            ],
            [
              105.82637833169389,
              21.0070268997406
            ],
            [
              105.82638479508618,
              21.00702908668108
            ],
            [
              105.82644875345913,
              21.006997803548433
            ],
            [
              105.82659001778897,
              21.006928707990006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9698",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007144018133552,
          "to_latitude": 21.006928707990006,
          "to_longitude": 105.82659001778897,
          "from_longitude": 105.82613115308502
        }
      },
      {
        "id": 9691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82644171925948,
            21.006984538595052,
            105.82644875345913,
            21.006997803548433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82644875345913,
              21.006997803548433
            ],
            [
              105.82644430764486,
              21.00698942151517
            ],
            [
              105.82644171925948,
              21.006984538595052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9699",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006997803548433,
          "to_latitude": 21.006984538595052,
          "to_longitude": 105.82644171925948,
          "from_longitude": 105.82644875345913
        }
      },
      {
        "id": 9692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82618236312128,
            21.00637673748012,
            105.82645263270761,
            21.006889164453103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82645263270761,
              21.006889164453103
            ],
            [
              105.82633171310799,
              21.006659902947302
            ],
            [
              105.82620994239814,
              21.006429027791437
            ],
            [
              105.82618236312128,
              21.00637673748012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9700",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006889164453103,
          "to_latitude": 21.00637673748012,
          "to_longitude": 105.82618236312128,
          "from_longitude": 105.82645263270761
        }
      },
      {
        "id": 9693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82633171310799,
            21.00665140995103,
            105.82634974777207,
            21.006659902947302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82633171310799,
              21.006659902947302
            ],
            [
              105.82634272501879,
              21.006654717344297
            ],
            [
              105.82634972282848,
              21.006651421814095
            ],
            [
              105.82634974777207,
              21.00665140995103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9701",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006659902947302,
          "to_latitude": 21.00665140995103,
          "to_longitude": 105.82634974777207,
          "from_longitude": 105.82633171310799
        }
      },
      {
        "id": 9694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82616742978051,
            21.00582792343444,
            105.82721544484185,
            21.00630611981647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82616742978051,
              21.00630611981647
            ],
            [
              105.82721544484185,
              21.00582792343444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9702",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 121.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00630611981647,
          "to_latitude": 21.00582792343444,
          "to_longitude": 105.82721544484185,
          "from_longitude": 105.82616742978051
        }
      },
      {
        "id": 9695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82974736994612,
            21.00657355178758,
            105.83017165006632,
            21.00675813853387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83017165006632,
              21.00657355178758
            ],
            [
              105.82995672688591,
              21.006670096348437
            ],
            [
              105.8298167967114,
              21.006728941948886
            ],
            [
              105.82974736994612,
              21.00675813853387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9703",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00657355178758,
          "to_latitude": 21.00675813853387,
          "to_longitude": 105.82974736994612,
          "from_longitude": 105.83017165006632
        }
      },
      {
        "id": 9696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298167967114,
            21.006728941948886,
            105.82981939185804,
            21.006733811233445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298167967114,
              21.006728941948886
            ],
            [
              105.82981752276832,
              21.006730304086894
            ],
            [
              105.82981913722504,
              21.006733337377977
            ],
            [
              105.82981939185804,
              21.006733811233445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9704",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006728941948886,
          "to_latitude": 21.006733811233445,
          "to_longitude": 105.82981939185804,
          "from_longitude": 105.8298167967114
        }
      },
      {
        "id": 9697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83021111576983,
            21.006386870793413,
            105.83056400241081,
            21.006554563132394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83021111576983,
              21.006554563132394
            ],
            [
              105.8302862972133,
              21.00651614254139
            ],
            [
              105.83038586250652,
              21.006470294942655
            ],
            [
              105.83056400241081,
              21.006386870793413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9705",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006554563132394,
          "to_latitude": 21.006386870793413,
          "to_longitude": 105.83056400241081,
          "from_longitude": 105.83021111576983
        }
      },
      {
        "id": 9698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302862972133,
            21.00651614254139,
            105.83029326519456,
            21.006527987778874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302862972133,
              21.00651614254139
            ],
            [
              105.83028866360172,
              21.006520171127733
            ],
            [
              105.83029326519456,
              21.006527987778874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9706",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00651614254139,
          "to_latitude": 21.006527987778874,
          "to_longitude": 105.83029326519456,
          "from_longitude": 105.8302862972133
        }
      },
      {
        "id": 9699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83024583127245,
            21.005899685681708,
            105.83060750236429,
            21.00637574481584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83060750236429,
              21.00637574481584
            ],
            [
              105.8305997552567,
              21.006360425973014
            ],
            [
              105.83055969215943,
              21.006281189971897
            ],
            [
              105.8305046367274,
              21.006172304653838
            ],
            [
              105.83039378514103,
              21.00622704274477
            ],
            [
              105.83024583127245,
              21.005899685681708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9707",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00637574481584,
          "to_latitude": 21.005899685681708,
          "to_longitude": 105.83024583127245,
          "from_longitude": 105.83060750236429
        }
      },
      {
        "id": 9700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034638325263,
            21.006281189971897,
            105.83055969215943,
            21.00636433333794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83055969215943,
              21.006281189971897
            ],
            [
              105.83037893451407,
              21.00636433333794
            ],
            [
              105.83034638325263,
              21.00631177083942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9708",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006281189971897,
          "to_latitude": 21.00631177083942,
          "to_longitude": 105.83034638325263,
          "from_longitude": 105.83055969215943
        }
      },
      {
        "id": 9701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83062247444241,
            21.00600440460414,
            105.83099172071391,
            21.006309788617184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83095027080171,
              21.00600440460414
            ],
            [
              105.83099172071391,
              21.00612474859655
            ],
            [
              105.83099122287898,
              21.00612499760806
            ],
            [
              105.83086404440242,
              21.006188730560698
            ],
            [
              105.83084492148592,
              21.00619831411445
            ],
            [
              105.83062247444241,
              21.006309788617184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9709",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00600440460414,
          "to_latitude": 21.006309788617184,
          "to_longitude": 105.83062247444241,
          "from_longitude": 105.83095027080171
        }
      },
      {
        "id": 9702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83084492148592,
            21.00619831411445,
            105.83086916069637,
            21.006240167798705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83084492148592,
              21.00619831411445
            ],
            [
              105.83086428255737,
              21.006231745545538
            ],
            [
              105.83086916069637,
              21.006240167798705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9710",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00619831411445,
          "to_latitude": 21.006240167798705,
          "to_longitude": 105.83086916069637,
          "from_longitude": 105.83084492148592
        }
      },
      {
        "id": 9703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8302111933073,
            21.0055425726272,
            105.8303169495259,
            21.005672308278577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8302111933073,
              21.0055425726272
            ],
            [
              105.83021332282989,
              21.005559252959554
            ],
            [
              105.83021857717058,
              21.00560908723081
            ],
            [
              105.83028158853864,
              21.005592574159312
            ],
            [
              105.8302948963035,
              21.00563034629316
            ],
            [
              105.8303169495259,
              21.005672308278577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT124_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9711",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0055425726272,
          "to_latitude": 21.005672308278577,
          "to_longitude": 105.8303169495259,
          "from_longitude": 105.8302111933073
        }
      },
      {
        "id": 9704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82980971975124,
            21.005543269018943,
            105.83021990023238,
            21.006024017315536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83020637817734,
              21.005543269018943
            ],
            [
              105.8302080564054,
              21.005563568974406
            ],
            [
              105.83021990023238,
              21.005706716836873
            ],
            [
              105.83012855772216,
              21.005757824355054
            ],
            [
              105.83016806669886,
              21.005850502280648
            ],
            [
              105.82980971975124,
              21.006024017315536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT122_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9712",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005543269018943,
          "to_latitude": 21.006024017315536,
          "to_longitude": 105.82980971975124,
          "from_longitude": 105.83020637817734
        }
      },
      {
        "id": 9705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8298944260649,
            21.00512588780461,
            105.83016297374903,
            21.005549543194164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83016297374903,
              21.005549543194164
            ],
            [
              105.83016034226834,
              21.005544015639114
            ],
            [
              105.8301602546829,
              21.00554383180815
            ],
            [
              105.83015858174805,
              21.00554031106272
            ],
            [
              105.83015598529892,
              21.005534856498052
            ],
            [
              105.83015136077452,
              21.005525147693778
            ],
            [
              105.8301512761046,
              21.005524969267938
            ],
            [
              105.829961425988,
              21.00512588780461
            ],
            [
              105.8298944260649,
              21.00514938859455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT123_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9713",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005549543194164,
          "to_latitude": 21.00514938859455,
          "to_longitude": 105.8298944260649,
          "from_longitude": 105.83016297374903
        }
      },
      {
        "id": 9706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82975048425055,
            21.00534867177826,
            105.82994558770811,
            21.0055985238586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982189515685,
              21.0055985238586
            ],
            [
              105.82982183451145,
              21.005598167379848
            ],
            [
              105.82981941237169,
              21.00558370317938
            ],
            [
              105.82981939744457,
              21.005583612929527
            ],
            [
              105.8298193676053,
              21.005583435139396
            ],
            [
              105.82981864735585,
              21.005579121228752
            ],
            [
              105.82981804262106,
              21.00557551940052
            ],
            [
              105.82981801274683,
              21.005575335287993
            ],
            [
              105.8298166718238,
              21.00556732027189
            ],
            [
              105.82980298118801,
              21.005540650616528
            ],
            [
              105.82975048425055,
              21.005438384036452
            ],
            [
              105.82994558770811,
              21.00534867177826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT132_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9714",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0055985238586,
          "to_latitude": 21.00534867177826,
          "to_longitude": 105.82994558770811,
          "from_longitude": 105.82982189515685
        }
      },
      {
        "id": 9707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82968945910831,
            21.00528479423535,
            105.82985380667822,
            21.005599289650444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82982025747373,
              21.005599289650444
            ],
            [
              105.82981978722515,
              21.005598439297486
            ],
            [
              105.82981326864392,
              21.005586616233032
            ],
            [
              105.82981323562649,
              21.00558655949075
            ],
            [
              105.82981315206358,
              21.00558640725291
            ],
            [
              105.82981116116736,
              21.005582786892763
            ],
            [
              105.82980958137753,
              21.005579932270678
            ],
            [
              105.8298094987915,
              21.005579782737748
            ],
            [
              105.82979110208537,
              21.00554639615073
            ],
            [
              105.82968945910831,
              21.00535641476834
            ],
            [
              105.82985380667822,
              21.00528479423535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT121_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9715",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005599289650444,
          "to_latitude": 21.00528479423535,
          "to_longitude": 105.82985380667822,
          "from_longitude": 105.82982025747373
        }
      },
      {
        "id": 9708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82948188979069,
            21.00536608843867,
            105.82967498351695,
            21.00546025524544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82948188979069,
              21.00546025524544
            ],
            [
              105.82967498351695,
              21.00536608843867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT131_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9716",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00546025524544,
          "to_latitude": 21.00536608843867,
          "to_longitude": 105.82967498351695,
          "from_longitude": 105.82948188979069
        }
      },
      {
        "id": 9709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82953315417184,
            21.005451033398575,
            105.82970738247612,
            21.005531332284082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82953315417184,
              21.005531332284082
            ],
            [
              105.82970738247612,
              21.005451033398575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT120_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9717",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005531332284082,
          "to_latitude": 21.005451033398575,
          "to_longitude": 105.82970738247612,
          "from_longitude": 105.82953315417184
        }
      },
      {
        "id": 9710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82934604294286,
            21.00551618298849,
            105.82946640117798,
            21.005741388623562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82934604294286,
              21.00551618298849
            ],
            [
              105.82946640117798,
              21.005741388623562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT128_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9718",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00551618298849,
          "to_latitude": 21.005741388623562,
          "to_longitude": 105.82946640117798,
          "from_longitude": 105.82934604294286
        }
      },
      {
        "id": 9711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946640117798,
            21.005591245589418,
            105.82978319146002,
            21.005741388623562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946640117798,
              21.005741388623562
            ],
            [
              105.82953192552274,
              21.005710333374363
            ],
            [
              105.82959520493748,
              21.005680342205334
            ],
            [
              105.82959930236463,
              21.005678400166882
            ],
            [
              105.82960303427635,
              21.00567663152303
            ],
            [
              105.82960699643226,
              21.005674753369608
            ],
            [
              105.82978319146002,
              21.005591245589418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT128_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9719",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005741388623562,
          "to_latitude": 21.005591245589418,
          "to_longitude": 105.82978319146002,
          "from_longitude": 105.82946640117798
        }
      },
      {
        "id": 9712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82960303427635,
            21.00567663152303,
            105.82961174620586,
            21.005692160681228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960303427635,
              21.00567663152303
            ],
            [
              105.8296035987169,
              21.005677643095005
            ],
            [
              105.82960394358307,
              21.005678258317666
            ],
            [
              105.82960402906944,
              21.005678410546224
            ],
            [
              105.82960488196993,
              21.005679925615727
            ],
            [
              105.82960681715358,
              21.005683386378816
            ],
            [
              105.82960690264,
              21.005683538607386
            ],
            [
              105.8296091767291,
              21.005687580299536
            ],
            [
              105.82961081555852,
              21.005690501474504
            ],
            [
              105.82961170348511,
              21.005692088631346
            ],
            [
              105.82961174620586,
              21.005692160681228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT128_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9720",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00567663152303,
          "to_latitude": 21.005692160681228,
          "to_longitude": 105.82961174620586,
          "from_longitude": 105.82960303427635
        }
      },
      {
        "id": 9713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82948188979069,
            21.00546025524544,
            105.82960850658165,
            21.005693546667267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960850658165,
              21.005693546667267
            ],
            [
              105.82960826586148,
              21.005693153130697
            ],
            [
              105.82960340073784,
              21.00568515529133
            ],
            [
              105.82960330949032,
              21.005685004897305
            ],
            [
              105.82960125833254,
              21.005681618764047
            ],
            [
              105.8296003507666,
              21.00568013286424
            ],
            [
              105.82960025952406,
              21.005679983373394
            ],
            [
              105.829599882887,
              21.00567936288627
            ],
            [
              105.82959930236463,
              21.005678400166882
            ],
            [
              105.82956593443808,
              21.00562349219163
            ],
            [
              105.82948188979069,
              21.00546025524544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT131_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9721",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005693546667267,
          "to_latitude": 21.00546025524544,
          "to_longitude": 105.82948188979069,
          "from_longitude": 105.82960850658165
        }
      },
      {
        "id": 9714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82953315417184,
            21.005531332284082,
            105.82961517357823,
            21.005690633779807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82961517357823,
              21.005690633779807
            ],
            [
              105.82961508898873,
              21.005690469804936
            ],
            [
              105.8296119260556,
              21.005684327024642
            ],
            [
              105.8296106300021,
              21.005681816953338
            ],
            [
              105.82961055027178,
              21.005681661987026
            ],
            [
              105.82960873298097,
              21.00567812568151
            ],
            [
              105.82960793475104,
              21.005676582345522
            ],
            [
              105.82960785502075,
              21.00567642737921
            ],
            [
              105.82960756332585,
              21.00567586067163
            ],
            [
              105.82960699643226,
              21.005674753369608
            ],
            [
              105.82957746923095,
              21.005617403318517
            ],
            [
              105.82953315417184,
              21.005531332284082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT120_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9722",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005690633779807,
          "to_latitude": 21.005531332284082,
          "to_longitude": 105.82953315417184,
          "from_longitude": 105.82961517357823
        }
      },
      {
        "id": 9715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909719620906,
            21.005445694584708,
            105.8294503714736,
            21.00590140824266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294503714736,
              21.005759823017865
            ],
            [
              105.82944874441979,
              21.005757117655058
            ],
            [
              105.8294486541392,
              21.00575696815945
            ],
            [
              105.82944676975956,
              21.005753823276176
            ],
            [
              105.82944497090624,
              21.00575083784716
            ],
            [
              105.82944487770517,
              21.005750682043185
            ],
            [
              105.82926182527656,
              21.005445694584708
            ],
            [
              105.82909719620906,
              21.005524425225197
            ],
            [
              105.82929579963339,
              21.00590140824266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9723",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 105.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005759823017865,
          "to_latitude": 21.00590140824266,
          "to_longitude": 105.82929579963339,
          "from_longitude": 105.8294503714736
        }
      },
      {
        "id": 9716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82930052914803,
            21.00575561708747,
            105.82952940342408,
            21.006072479787232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82945937156964,
              21.00575561708747
            ],
            [
              105.82945956700252,
              21.005757040523168
            ],
            [
              105.82947542198134,
              21.00587197107811
            ],
            [
              105.82952940342408,
              21.005999011424187
            ],
            [
              105.82938819921837,
              21.006072479787232
            ],
            [
              105.82930052914803,
              21.00591428417472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9724",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00575561708747,
          "to_latitude": 21.00591428417472,
          "to_longitude": 105.82930052914803,
          "from_longitude": 105.82945937156964
        }
      },
      {
        "id": 9717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294513403245,
            21.00575407819581,
            105.82977373136426,
            21.006165652175227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946266517828,
              21.00575407819581
            ],
            [
              105.82946313261438,
              21.00575650830661
            ],
            [
              105.82948451682043,
              21.005867502748856
            ],
            [
              105.82954259729401,
              21.006005069184624
            ],
            [
              105.8294513403245,
              21.00604753928091
            ],
            [
              105.82951291265739,
              21.006165652175227
            ],
            [
              105.82977373136426,
              21.006053105058218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9725",
          "diaChiLapD": "Ngõ 6, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00575407819581,
          "to_latitude": 21.006053105058218,
          "to_longitude": 105.82977373136426,
          "from_longitude": 105.82946266517828
        }
      },
      {
        "id": 9718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8264237700726,
            21.008023866213893,
            105.8266738278281,
            21.008138750009824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8264237700726,
              21.008138750009824
            ],
            [
              105.8266738278281,
              21.008023866213893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9726",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008138750009824,
          "to_latitude": 21.008023866213893,
          "to_longitude": 105.8266738278281,
          "from_longitude": 105.8264237700726
        }
      },
      {
        "id": 9719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613233625162,
            21.008126374114063,
            105.82638487233682,
            21.008270591880663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613233625162,
              21.008270591880663
            ],
            [
              105.82619063940858,
              21.00824439796926
            ],
            [
              105.82620274351358,
              21.008224073505676
            ],
            [
              105.82626710106078,
              21.008191642297387
            ],
            [
              105.82638487233682,
              21.008126374114063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9727",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008270591880663,
          "to_latitude": 21.008126374114063,
          "to_longitude": 105.82638487233682,
          "from_longitude": 105.82613233625162
        }
      },
      {
        "id": 9720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638487233682,
            21.008126374114063,
            105.82644200385734,
            21.008236587498637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638487233682,
              21.008126374114063
            ],
            [
              105.82638507746886,
              21.008126768731263
            ],
            [
              105.82644200385734,
              21.008236587498637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9728",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008126374114063,
          "to_latitude": 21.008236587498637,
          "to_longitude": 105.82644200385734,
          "from_longitude": 105.82638487233682
        }
      },
      {
        "id": 9721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82638507746886,
            21.008116928141817,
            105.82641017381032,
            21.008126768731263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82638507746886,
              21.008126768731263
            ],
            [
              105.82640198944897,
              21.008120137308726
            ],
            [
              105.82640832424264,
              21.008117653382378
            ],
            [
              105.82641017381032,
              21.008116928141817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9729",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008126768731263,
          "to_latitude": 21.008116928141817,
          "to_longitude": 105.82641017381032,
          "from_longitude": 105.82638507746886
        }
      },
      {
        "id": 9722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82606415987722,
            21.007423080051822,
            105.82642319270485,
            21.00811740830012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82640671365101,
              21.00811740830012
            ],
            [
              105.8264062101873,
              21.008116463260123
            ],
            [
              105.82639782596806,
              21.008100674022494
            ],
            [
              105.82642319270485,
              21.008090154698117
            ],
            [
              105.8262568703359,
              21.00775473974902
            ],
            [
              105.82606415987722,
              21.007423080051822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9730",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00811740830012,
          "to_latitude": 21.007423080051822,
          "to_longitude": 105.82606415987722,
          "from_longitude": 105.82640671365101
        }
      },
      {
        "id": 9723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82590566033346,
            21.00750224129783,
            105.82625528818576,
            21.00818083022117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590566033346,
              21.00750224129783
            ],
            [
              105.82608742514721,
              21.007855027496586
            ],
            [
              105.82612427349315,
              21.007926545865995
            ],
            [
              105.82625528818576,
              21.00818083022117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9731",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00750224129783,
          "to_latitude": 21.00818083022117,
          "to_longitude": 105.82625528818576,
          "from_longitude": 105.82590566033346
        }
      },
      {
        "id": 9724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82608742514721,
            21.00785018736459,
            105.8260983495994,
            21.007855027496586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82608742514721,
              21.007855027496586
            ],
            [
              105.82609143509988,
              21.007853251255245
            ],
            [
              105.82609824884752,
              21.00785023211178
            ],
            [
              105.8260983495994,
              21.00785018736459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9732",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007855027496586,
          "to_latitude": 21.00785018736459,
          "to_longitude": 105.8260983495994,
          "from_longitude": 105.82608742514721
        }
      },
      {
        "id": 9725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591584965363,
            21.007474639814614,
            105.82627046346173,
            21.00815995793791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82591584965363,
              21.007474639814614
            ],
            [
              105.82610678780227,
              21.00784364226672
            ],
            [
              105.82611590734444,
              21.007861266920752
            ],
            [
              105.82627046346173,
              21.00815995793791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9733",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007474639814614,
          "to_latitude": 21.00815995793791,
          "to_longitude": 105.82627046346173,
          "from_longitude": 105.82591584965363
        }
      },
      {
        "id": 9726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82609692017587,
            21.00784364226672,
            105.82610678780227,
            21.00784790552657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82609692017587,
              21.00784790552657
            ],
            [
              105.82609706219839,
              21.00784784432096
            ],
            [
              105.82610085072125,
              21.00784620734798
            ],
            [
              105.82610678780227,
              21.00784364226672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9734",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00784790552657,
          "to_latitude": 21.00784364226672,
          "to_longitude": 105.82610678780227,
          "from_longitude": 105.82609692017587
        }
      },
      {
        "id": 9727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82567186372556,
            21.00732041032768,
            105.82614229418787,
            21.00757346166075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82567186372556,
              21.00757346166075
            ],
            [
              105.82579352257638,
              21.007513232194757
            ],
            [
              105.8258192598588,
              21.007500490128276
            ],
            [
              105.82595725786307,
              21.007432171444666
            ],
            [
              105.82602855705058,
              21.00740795129655
            ],
            [
              105.82606085372088,
              21.00739422083936
            ],
            [
              105.82614229418787,
              21.00732041032768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9735",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00757346166075,
          "to_latitude": 21.00732041032768,
          "to_longitude": 105.82614229418787,
          "from_longitude": 105.82567186372556
        }
      },
      {
        "id": 9728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82579352257638,
            21.007513232194757,
            105.82580427159476,
            21.007529651281974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82579352257638,
              21.007513232194757
            ],
            [
              105.825796453085,
              21.007517711528653
            ],
            [
              105.8258020919131,
              21.00752632172565
            ],
            [
              105.82580427159476,
              21.007529651281974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9736",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007513232194757,
          "to_latitude": 21.007529651281974,
          "to_longitude": 105.82580427159476,
          "from_longitude": 105.82579352257638
        }
      },
      {
        "id": 9729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82569220649404,
            21.00758059781028,
            105.82605647701989,
            21.00828444981741
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82569220649404,
              21.00758059781028
            ],
            [
              105.82605647701989,
              21.00828444981741
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9737",
          "diaChiLapD": "Ngõ 203, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00758059781028,
          "to_latitude": 21.00828444981741,
          "to_longitude": 105.82605647701989,
          "from_longitude": 105.82569220649404
        }
      },
      {
        "id": 9730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8268047145585,
            21.007820822761893,
            105.82712941748156,
            21.007962339330582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82712941748156,
              21.007820822761893
            ],
            [
              105.82712731120263,
              21.007821741645085
            ],
            [
              105.8268047145585,
              21.007962339330582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9738",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007820822761893,
          "to_latitude": 21.007962339330582,
          "to_longitude": 105.8268047145585,
          "from_longitude": 105.82712941748156
        }
      },
      {
        "id": 9731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82712993336331,
            21.007556493505415,
            105.82766872614185,
            21.007821751481252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82712993336331,
              21.007821751481252
            ],
            [
              105.82713199966322,
              21.007820734340633
            ],
            [
              105.82714255083141,
              21.00781553918234
            ],
            [
              105.82766872614185,
              21.007556493505415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9739",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007821751481252,
          "to_latitude": 21.007556493505415,
          "to_longitude": 105.82766872614185,
          "from_longitude": 105.82712993336331
        }
      },
      {
        "id": 9732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82705881958277,
            21.007659716814736,
            105.82720300816385,
            21.007928046591804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82705881958277,
              21.007659716814736
            ],
            [
              105.82711520762686,
              21.00776465241701
            ],
            [
              105.82713420452369,
              21.007800007217483
            ],
            [
              105.82713365692958,
              21.00780623853007
            ],
            [
              105.82713974313576,
              21.007810314114153
            ],
            [
              105.82714255083141,
              21.00781553918234
            ],
            [
              105.82720300816385,
              21.007928046591804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9740",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007659716814736,
          "to_latitude": 21.007928046591804,
          "to_longitude": 105.82720300816385,
          "from_longitude": 105.82705881958277
        }
      },
      {
        "id": 9733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82710137400309,
            21.00776465241701,
            105.82711520762686,
            21.007770280817503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711520762686,
              21.00776465241701
            ],
            [
              105.82710137400309,
              21.007770280817503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9741",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00776465241701,
          "to_latitude": 21.007770280817503,
          "to_longitude": 105.82710137400309,
          "from_longitude": 105.82711520762686
        }
      },
      {
        "id": 9734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82693974104153,
            21.007516445784773,
            105.82708484038359,
            21.007799647394116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82693974104153,
              21.007516445784773
            ],
            [
              105.82702406770761,
              21.007681033850563
            ],
            [
              105.82708484038359,
              21.007799647394116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9742",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007516445784773,
          "to_latitude": 21.007799647394116,
          "to_longitude": 105.82708484038359,
          "from_longitude": 105.82693974104153
        }
      },
      {
        "id": 9735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82702406770761,
            21.007672488430735,
            105.82704711263099,
            21.007681033850563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702406770761,
              21.007681033850563
            ],
            [
              105.82704623238143,
              21.007672815160497
            ],
            [
              105.82704711263099,
              21.007672488430735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9743",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007681033850563,
          "to_latitude": 21.007672488430735,
          "to_longitude": 105.82704711263099,
          "from_longitude": 105.82702406770761
        }
      },
      {
        "id": 9736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82668239160542,
            21.007033895298143,
            105.826903348998,
            21.007463023333834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82668239160542,
              21.007033895298143
            ],
            [
              105.82675770783126,
              21.007180169204712
            ],
            [
              105.82675812785745,
              21.007180984584473
            ],
            [
              105.82676546169023,
              21.007184728960617
            ],
            [
              105.82676676871529,
              21.007185395514977
            ],
            [
              105.82676539376068,
              21.007195095624517
            ],
            [
              105.82679640294282,
              21.00725531936527
            ],
            [
              105.826903348998,
              21.007463023333834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9744",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007033895298143,
          "to_latitude": 21.007463023333834,
          "to_longitude": 105.826903348998,
          "from_longitude": 105.82668239160542
        }
      },
      {
        "id": 9737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82675770783126,
            21.00717095290522,
            105.8267818311181,
            21.007180169204712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82675770783126,
              21.007180169204712
            ],
            [
              105.82677959559857,
              21.007171807379525
            ],
            [
              105.8267818311181,
              21.00717095290522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9745",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007180169204712,
          "to_latitude": 21.00717095290522,
          "to_longitude": 105.8267818311181,
          "from_longitude": 105.82675770783126
        }
      },
      {
        "id": 9738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82672509819203,
            21.007020092473944,
            105.82703364159048,
            21.007619641070264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82672509819203,
              21.007020092473944
            ],
            [
              105.8268585524931,
              21.00728818841902
            ],
            [
              105.82688240499559,
              21.007333343155548
            ],
            [
              105.82693637960054,
              21.007435519454727
            ],
            [
              105.82703364159048,
              21.007619641070264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9746",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007020092473944,
          "to_latitude": 21.007619641070264,
          "to_longitude": 105.82703364159048,
          "from_longitude": 105.82672509819203
        }
      },
      {
        "id": 9739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8269211113402,
            21.007435519454727,
            105.82693637960054,
            21.007440884746362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8269211113402,
              21.007440884746362
            ],
            [
              105.82692272418363,
              21.007440317812172
            ],
            [
              105.82692878483209,
              21.007438188361043
            ],
            [
              105.82693637960054,
              21.007435519454727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9747",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007440884746362,
          "to_latitude": 21.007435519454727,
          "to_longitude": 105.82693637960054,
          "from_longitude": 105.8269211113402
        }
      },
      {
        "id": 9740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82653997210267,
            21.00717331777945,
            105.82678308593968,
            21.007286374171564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82678308593968,
              21.00717331777945
            ],
            [
              105.82678106358007,
              21.00717462734772
            ],
            [
              105.82676546169023,
              21.007184728960617
            ],
            [
              105.82674922433542,
              21.007195241917714
            ],
            [
              105.82653997210267,
              21.007286374171564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9748",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00717331777945,
          "to_latitude": 21.007286374171564,
          "to_longitude": 105.82653997210267,
          "from_longitude": 105.82678308593968
        }
      },
      {
        "id": 9741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82647137063898,
            21.00713375851749,
            105.82653997210267,
            21.007286374171564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82653997210267,
              21.007286374171564
            ],
            [
              105.82647137063898,
              21.00713375851749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9749",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007286374171564,
          "to_latitude": 21.00713375851749,
          "to_longitude": 105.82647137063898,
          "from_longitude": 105.82653997210267
        }
      },
      {
        "id": 9742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82643423941501,
            21.007015836157453,
            105.82669950308836,
            21.007116249940974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82669950308836,
              21.007015836157453
            ],
            [
              105.8266992977535,
              21.007015928380806
            ],
            [
              105.8264757363873,
              21.007116249940974
            ],
            [
              105.82643423941501,
              21.007035668655284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9750",
          "diaChiLapD": "Ngõ 165, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007015836157453,
          "to_latitude": 21.007035668655284,
          "to_longitude": 105.82643423941501,
          "from_longitude": 105.82669950308836
        }
      },
      {
        "id": 9743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82670742286707,
            21.006642449942444,
            105.82722544245333,
            21.006884645537703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82670742286707,
              21.006884645537703
            ],
            [
              105.82680688975456,
              21.006838141119847
            ],
            [
              105.82696979176953,
              21.006761978059586
            ],
            [
              105.82709569882562,
              21.006703110790816
            ],
            [
              105.82709999830627,
              21.00669523453235
            ],
            [
              105.82710156975823,
              21.006695187153213
            ],
            [
              105.82711340566897,
              21.00669483246942
            ],
            [
              105.82722544245333,
              21.006642449942444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9751",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006884645537703,
          "to_latitude": 21.006642449942444,
          "to_longitude": 105.82722544245333,
          "from_longitude": 105.82670742286707
        }
      },
      {
        "id": 9744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82679445258253,
            21.006817707325403,
            105.82680688975456,
            21.006838141119847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82680688975456,
              21.006838141119847
            ],
            [
              105.82680430099145,
              21.006833889562824
            ],
            [
              105.82679445258253,
              21.006817707325403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9752",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006838141119847,
          "to_latitude": 21.006817707325403,
          "to_longitude": 105.82679445258253,
          "from_longitude": 105.82680688975456
        }
      },
      {
        "id": 9745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270924894118,
            21.006676478488288,
            105.82715684386224,
            21.00680907283106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270924894118,
              21.006676478488288
            ],
            [
              105.82709965894077,
              21.00669125295149
            ],
            [
              105.82710156975823,
              21.006695187153213
            ],
            [
              105.82715684386224,
              21.00680907283106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9753",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006676478488288,
          "to_latitude": 21.00680907283106,
          "to_longitude": 105.82715684386224,
          "from_longitude": 105.8270924894118
        }
      },
      {
        "id": 9746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82702988253104,
            21.006754771268607,
            105.82726379140318,
            21.006869338045213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82726379140318,
              21.006754771268607
            ],
            [
              105.82715684386224,
              21.00680907283106
            ],
            [
              105.82714861395195,
              21.006813251415604
            ],
            [
              105.82713818545395,
              21.006818177718316
            ],
            [
              105.82702988253104,
              21.006869338045213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9754",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006754771268607,
          "to_latitude": 21.006869338045213,
          "to_longitude": 105.82702988253104,
          "from_longitude": 105.82726379140318
        }
      },
      {
        "id": 9747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82723781559774,
            21.00648410657621,
            105.82756887621433,
            21.006639221280928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82756887621433,
              21.00648410657621
            ],
            [
              105.82740939597748,
              21.00655882917108
            ],
            [
              105.82736264530622,
              21.006580733895447
            ],
            [
              105.82723781559774,
              21.006639221280928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9755",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00648410657621,
          "to_latitude": 21.006639221280928,
          "to_longitude": 105.82723781559774,
          "from_longitude": 105.82756887621433
        }
      },
      {
        "id": 9748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82739677793298,
            21.00653560713681,
            105.82740939597748,
            21.00655882917108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82739677793298,
              21.00653560713681
            ],
            [
              105.82740322447133,
              21.006547474001923
            ],
            [
              105.8274079918161,
              21.006556245543408
            ],
            [
              105.82740939597748,
              21.00655882917108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9756",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00653560713681,
          "to_latitude": 21.00655882917108,
          "to_longitude": 105.82740939597748,
          "from_longitude": 105.82739677793298
        }
      },
      {
        "id": 9749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82759464283447,
            21.006298863410986,
            105.82796710811128,
            21.006473430689013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82759464283447,
              21.006473430689013
            ],
            [
              105.82775982254941,
              21.006396014782204
            ],
            [
              105.82777295671083,
              21.006389858636325
            ],
            [
              105.82796710811128,
              21.006298863410986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9757",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006473430689013,
          "to_latitude": 21.006298863410986,
          "to_longitude": 105.82796710811128,
          "from_longitude": 105.82759464283447
        }
      },
      {
        "id": 9750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827746713556,
            21.00637360084067,
            105.82775982254941,
            21.006396014782204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82775982254941,
              21.006396014782204
            ],
            [
              105.82775553155348,
              21.006388680677414
            ],
            [
              105.82774899549156,
              21.006377502520188
            ],
            [
              105.827746713556,
              21.00637360084067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9758",
          "diaChiLapD": "Ngách 95/8, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006396014782204,
          "to_latitude": 21.00637360084067,
          "to_longitude": 105.827746713556,
          "from_longitude": 105.82775982254941
        }
      },
      {
        "id": 9751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82327255813786,
            21.006318316488922,
            105.82339535867698,
            21.006514276858514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82327255813786,
              21.006514276858514
            ],
            [
              105.82331014542635,
              21.006454296856106
            ],
            [
              105.82339535867698,
              21.006318316488922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9759",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006514276858514,
          "to_latitude": 21.006318316488922,
          "to_longitude": 105.82339535867698,
          "from_longitude": 105.82327255813786
        }
      },
      {
        "id": 9752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82331014542635,
            21.006454296856106,
            105.82333288171408,
            21.006464175657662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82331014542635,
              21.006454296856106
            ],
            [
              105.82331971778771,
              21.006458455975505
            ],
            [
              105.82333288171408,
              21.006464175657662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9760",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006454296856106,
          "to_latitude": 21.006464175657662,
          "to_longitude": 105.82333288171408,
          "from_longitude": 105.82331014542635
        }
      },
      {
        "id": 9753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8234059399086,
            21.006046041104806,
            105.82374107741424,
            21.00630176513656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82374107741424,
              21.006046041104806
            ],
            [
              105.82368401555,
              21.006073302101246
            ],
            [
              105.82362515114188,
              21.006107229491715
            ],
            [
              105.82360042445781,
              21.006121481048694
            ],
            [
              105.82350519699969,
              21.00617991550604
            ],
            [
              105.8234059399086,
              21.00630176513656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9761",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006046041104806,
          "to_latitude": 21.00630176513656,
          "to_longitude": 105.8234059399086,
          "from_longitude": 105.82374107741424
        }
      },
      {
        "id": 9754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82362515114188,
            21.006107229491715,
            105.82363176854774,
            21.006118915036858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82362515114188,
              21.006107229491715
            ],
            [
              105.82363176854774,
              21.006118915036858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9762",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006107229491715,
          "to_latitude": 21.006118915036858,
          "to_longitude": 105.82363176854774,
          "from_longitude": 105.82362515114188
        }
      },
      {
        "id": 9755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82342382026796,
            21.006022149628535,
            105.82386110490658,
            21.006332266425446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82386110490658,
              21.006022149628535
            ],
            [
              105.82360922919968,
              21.006153502110884
            ],
            [
              105.823550656902,
              21.00619141490895
            ],
            [
              105.82350529043235,
              21.00622078067663
            ],
            [
              105.82344624453931,
              21.006289140819213
            ],
            [
              105.82342382026796,
              21.006332266425446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9763",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006022149628535,
          "to_latitude": 21.006332266425446,
          "to_longitude": 105.82342382026796,
          "from_longitude": 105.82386110490658
        }
      },
      {
        "id": 9756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82353555473232,
            21.006172535565824,
            105.823550656902,
            21.00619141490895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82353555473232,
              21.006172535565824
            ],
            [
              105.823550656902,
              21.00619141490895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9764",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006172535565824,
          "to_latitude": 21.00619141490895,
          "to_longitude": 105.823550656902,
          "from_longitude": 105.82353555473232
        }
      },
      {
        "id": 9757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8238808260763,
            21.005779459892295,
            105.82442044588834,
            21.006011251562054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82442044588834,
              21.005779459892295
            ],
            [
              105.82441604196283,
              21.00578130213121
            ],
            [
              105.82411903365566,
              21.005905584015352
            ],
            [
              105.8238808260763,
              21.006011251562054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9765",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005779459892295,
          "to_latitude": 21.006011251562054,
          "to_longitude": 105.8238808260763,
          "from_longitude": 105.82442044588834
        }
      },
      {
        "id": 9758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82440561022041,
            21.005761478950905,
            105.82441604196283,
            21.00578130213121
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82441604196283,
              21.00578130213121
            ],
            [
              105.82441206738035,
              21.00577375233888
            ],
            [
              105.82440561022041,
              21.005761478950905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9766",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00578130213121,
          "to_latitude": 21.005761478950905,
          "to_longitude": 105.82440561022041,
          "from_longitude": 105.82441604196283
        }
      },
      {
        "id": 9759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82443098840396,
            21.005525441353555,
            105.8249649906847,
            21.005770771260927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82443098840396,
              21.005770771260927
            ],
            [
              105.82471148158452,
              21.00564190822029
            ],
            [
              105.8249649906847,
              21.005525441353555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9767",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005770771260927,
          "to_latitude": 21.005525441353555,
          "to_longitude": 105.8249649906847,
          "from_longitude": 105.82443098840396
        }
      },
      {
        "id": 9760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82470116922913,
            21.005621585898638,
            105.82471148158452,
            21.00564190822029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82471148158452,
              21.00564190822029
            ],
            [
              105.82470116922913,
              21.005621585898638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9768",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00564190822029,
          "to_latitude": 21.005621585898638,
          "to_longitude": 105.82470116922913,
          "from_longitude": 105.82471148158452
        }
      },
      {
        "id": 9761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82500861189224,
            21.00526383519112,
            105.82554485892528,
            21.005507288202548
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82500861189224,
              21.005507288202548
            ],
            [
              105.82516102215318,
              21.00543809464914
            ],
            [
              105.82554485892528,
              21.00526383519112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9769",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005507288202548,
          "to_latitude": 21.00526383519112,
          "to_longitude": 105.82554485892528,
          "from_longitude": 105.82500861189224
        }
      },
      {
        "id": 9762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514885367773,
            21.005417484192705,
            105.82516102215318,
            21.00543809464914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82514885367773,
              21.005417484192705
            ],
            [
              105.82516102215318,
              21.00543809464914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9770",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005417484192705,
          "to_latitude": 21.00543809464914,
          "to_longitude": 105.82516102215318,
          "from_longitude": 105.82514885367773
        }
      },
      {
        "id": 9763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82486463388882,
            21.004461473402518,
            105.8250157759391,
            21.004530937952048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82486463388882,
              21.004530937952048
            ],
            [
              105.82488218595049,
              21.004522871066673
            ],
            [
              105.8250157759391,
              21.004461473402518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77_Ngõ 44, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9771",
          "diaChiLapD": "Ngõ 44, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004530937952048,
          "to_latitude": 21.004461473402518,
          "to_longitude": 105.8250157759391,
          "from_longitude": 105.82486463388882
        }
      },
      {
        "id": 9764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82488218595049,
            21.004522871066673,
            105.82502774759075,
            21.00480936835495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82488218595049,
              21.004522871066673
            ],
            [
              105.82502774759075,
              21.00480936835495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT77_Ngõ 44, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9772",
          "diaChiLapD": "Ngõ 44, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004522871066673,
          "to_latitude": 21.00480936835495,
          "to_longitude": 105.82502774759075,
          "from_longitude": 105.82488218595049
        }
      },
      {
        "id": 9765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82504360217041,
            21.004854177382914,
            105.82527807076721,
            21.00529277953883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504360217041,
              21.004854177382914
            ],
            [
              105.82513489397668,
              21.005066184721407
            ],
            [
              105.825150529643,
              21.005100732329336
            ],
            [
              105.82522949768294,
              21.00525298583537
            ],
            [
              105.82527807076721,
              21.00529277953883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_Ngõ 44, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9773",
          "diaChiLapD": "Ngõ 44, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004854177382914,
          "to_latitude": 21.00529277953883,
          "to_longitude": 105.82527807076721,
          "from_longitude": 105.82504360217041
        }
      },
      {
        "id": 9766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82512878510862,
            21.005066184721407,
            105.82513489397668,
            21.005068803890186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82513489397668,
              21.005066184721407
            ],
            [
              105.82512878510862,
              21.005068803890186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_Ngõ 44, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9774",
          "diaChiLapD": "Ngõ 44, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005066184721407,
          "to_latitude": 21.005068803890186,
          "to_longitude": 105.82512878510862,
          "from_longitude": 105.82513489397668
        }
      },
      {
        "id": 9767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82507226497071,
            21.005277059500283,
            105.82542228915612,
            21.005433131777185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82542228915612,
              21.005277059500283
            ],
            [
              105.82528070154328,
              21.00534019163169
            ],
            [
              105.825273319,
              21.005343483563543
            ],
            [
              105.82519790361762,
              21.00537711057484
            ],
            [
              105.82507226497071,
              21.005433131777185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9775",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005277059500283,
          "to_latitude": 21.005433131777185,
          "to_longitude": 105.82507226497071,
          "from_longitude": 105.82542228915612
        }
      },
      {
        "id": 9768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82519790361762,
            21.00537711057484,
            105.8252056954165,
            21.00539156959337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82519790361762,
              21.00537711057484
            ],
            [
              105.82520321540979,
              21.005386967029803
            ],
            [
              105.82520488008832,
              21.005390057027995
            ],
            [
              105.8252056954165,
              21.00539156959337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9776",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00537711057484,
          "to_latitude": 21.00539156959337,
          "to_longitude": 105.8252056954165,
          "from_longitude": 105.82519790361762
        }
      },
      {
        "id": 9769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82525090289735,
            21.004892418495896,
            105.8254549679137,
            21.00528022940391
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8254549679137,
              21.00528022940391
            ],
            [
              105.82545403265132,
              21.00527789729113
            ],
            [
              105.8254110331444,
              21.00517051882501
            ],
            [
              105.82536195293288,
              21.005071392813775
            ],
            [
              105.82534067180349,
              21.005030512076605
            ],
            [
              105.82532340386192,
              21.005027282775146
            ],
            [
              105.82525090289735,
              21.004892418495896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9777",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00528022940391,
          "to_latitude": 21.004892418495896,
          "to_longitude": 105.82525090289735,
          "from_longitude": 105.8254549679137
        }
      },
      {
        "id": 9770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82536195293288,
            21.00484337668836,
            105.82582020690353,
            21.005071392813775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82536195293288,
              21.005071392813775
            ],
            [
              105.82582020690353,
              21.00484337668836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9778",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005071392813775,
          "to_latitude": 21.00484337668836,
          "to_longitude": 105.82582020690353,
          "from_longitude": 105.82536195293288
        }
      },
      {
        "id": 9771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82542072828056,
            21.004957220465137,
            105.82588682241268,
            21.00527481834713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82546740882051,
              21.00527481834713
            ],
            [
              105.82542072828056,
              21.00516727528492
            ],
            [
              105.82576732500887,
              21.005003460221893
            ],
            [
              105.82578018137129,
              21.005005908816777
            ],
            [
              105.82588682241268,
              21.004957220465137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9779",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00527481834713,
          "to_latitude": 21.004957220465137,
          "to_longitude": 105.82588682241268,
          "from_longitude": 105.82546740882051
        }
      },
      {
        "id": 9772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82548042417994,
            21.005037499743906,
            105.8259453611576,
            21.005246887952147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259453611576,
              21.005037499743906
            ],
            [
              105.82591739095739,
              21.005050613044055
            ],
            [
              105.82571146627066,
              21.00514715647897
            ],
            [
              105.82551895081265,
              21.005237413202938
            ],
            [
              105.8255051979972,
              21.005236193712996
            ],
            [
              105.82548042417994,
              21.005246887952147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9780",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005037499743906,
          "to_latitude": 21.005246887952147,
          "to_longitude": 105.82548042417994,
          "from_longitude": 105.8259453611576
        }
      },
      {
        "id": 9773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591739095739,
            21.005050613044055,
            105.82592980912683,
            21.005072537331298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82591739095739,
              21.005050613044055
            ],
            [
              105.82592071962716,
              21.005056489545062
            ],
            [
              105.82592560240418,
              21.005065112443962
            ],
            [
              105.82592980912683,
              21.005072537331298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9781",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005050613044055,
          "to_latitude": 21.005072537331298,
          "to_longitude": 105.82592980912683,
          "from_longitude": 105.82591739095739
        }
      },
      {
        "id": 9774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82565753985688,
            21.00505463948005,
            105.82599373175952,
            21.00521148219122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82599373175952,
              21.00505463948005
            ],
            [
              105.8258291444526,
              21.005131423954147
            ],
            [
              105.82565753985688,
              21.00521148219122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9782",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00505463948005,
          "to_latitude": 21.00521148219122,
          "to_longitude": 105.82565753985688,
          "from_longitude": 105.82599373175952
        }
      },
      {
        "id": 9775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82582335816349,
            21.005119421947434,
            105.8258291444526,
            21.005131423954147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82582335816349,
              21.005119421947434
            ],
            [
              105.82582600660822,
              21.005124916072848
            ],
            [
              105.8258291444526,
              21.005131423954147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9783",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005119421947434,
          "to_latitude": 21.005131423954147,
          "to_longitude": 105.8258291444526,
          "from_longitude": 105.82582335816349
        }
      },
      {
        "id": 9776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82600272346785,
            21.00470998082446,
            105.82670155885536,
            21.005021285728123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600272346785,
              21.005021285728123
            ],
            [
              105.82600490253738,
              21.00502031592549
            ],
            [
              105.82670155885536,
              21.00470998082446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9784",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005021285728123,
          "to_latitude": 21.00470998082446,
          "to_longitude": 105.82670155885536,
          "from_longitude": 105.82600272346785
        }
      },
      {
        "id": 9777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587650461703,
            21.00470607672816,
            105.82601955274161,
            21.0049933954947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82587650461703,
              21.00470607672816
            ],
            [
              105.82593874057098,
              21.004831080335688
            ],
            [
              105.82601955274161,
              21.0049933954947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9785",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00470607672816,
          "to_latitude": 21.0049933954947,
          "to_longitude": 105.82601955274161,
          "from_longitude": 105.82587650461703
        }
      },
      {
        "id": 9778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82592098899599,
            21.004831080335688,
            105.82593874057098,
            21.00483836971553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593874057098,
              21.004831080335688
            ],
            [
              105.8259262375965,
              21.004836214436143
            ],
            [
              105.82592098899599,
              21.00483836971553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT85_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9786",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004831080335688,
          "to_latitude": 21.00483836971553,
          "to_longitude": 105.82592098899599,
          "from_longitude": 105.82593874057098
        }
      },
      {
        "id": 9779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82583539062344,
            21.004708367194233,
            105.82596904809601,
            21.004979636062256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82583539062344,
              21.004708367194233
            ],
            [
              105.82591660602739,
              21.004873200456714
            ],
            [
              105.82596904809601,
              21.004979636062256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9787",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004708367194233,
          "to_latitude": 21.004979636062256,
          "to_longitude": 105.82596904809601,
          "from_longitude": 105.82583539062344
        }
      },
      {
        "id": 9780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591660602739,
            21.004865681887505,
            105.82593319344552,
            21.004873200456714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82593319344552,
              21.004865681887505
            ],
            [
              105.82591660602739,
              21.004873200456714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT86_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9788",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004865681887505,
          "to_latitude": 21.004873200456714,
          "to_longitude": 105.82591660602739,
          "from_longitude": 105.82593319344552
        }
      },
      {
        "id": 9781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82536082685195,
            21.004610051460315,
            105.8258292836424,
            21.00486555072316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258292836424,
              21.00466430217673
            ],
            [
              105.82582860732025,
              21.004664803136503
            ],
            [
              105.82578630535707,
              21.004696099270845
            ],
            [
              105.82563755077712,
              21.004771852024767
            ],
            [
              105.82561367722144,
              21.00479843329432
            ],
            [
              105.8255630766337,
              21.004828939564305
            ],
            [
              105.82549311062604,
              21.00486555072316
            ],
            [
              105.82536082685195,
              21.004610051460315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9789",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00466430217673,
          "to_latitude": 21.004610051460315,
          "to_longitude": 105.82536082685195,
          "from_longitude": 105.8258292836424
        }
      },
      {
        "id": 9782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587651319497,
            21.004432130887682,
            105.82643339037372,
            21.004667433013903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643339037372,
              21.004432130887682
            ],
            [
              105.8261414167814,
              21.00455811239982
            ],
            [
              105.82614014628633,
              21.00455866050297
            ],
            [
              105.82607342153852,
              21.004591684801003
            ],
            [
              105.82606800149055,
              21.004598688537087
            ],
            [
              105.82606609143431,
              21.004598552387904
            ],
            [
              105.82605881492967,
              21.004598032219235
            ],
            [
              105.82587651319497,
              21.004667433013903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT118_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9790",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004432130887682,
          "to_latitude": 21.004667433013903,
          "to_longitude": 105.82587651319497,
          "from_longitude": 105.82643339037372
        }
      },
      {
        "id": 9783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613321183071,
            21.004543743042476,
            105.8261414167814,
            21.00455811239982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613321183071,
              21.004543743042476
            ],
            [
              105.82613876532476,
              21.004553469133608
            ],
            [
              105.8261414167814,
              21.00455811239982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT118_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9791",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004543743042476,
          "to_latitude": 21.00455811239982,
          "to_longitude": 105.8261414167814,
          "from_longitude": 105.82613321183071
        }
      },
      {
        "id": 9784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605498284553,
            21.004565493831674,
            105.82654952860072,
            21.00473273889325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605498284553,
              21.004576361623545
            ],
            [
              105.82606264080417,
              21.004591662157097
            ],
            [
              105.82606609143431,
              21.004598552387904
            ],
            [
              105.82613179086884,
              21.00472978944511
            ],
            [
              105.82616222330321,
              21.00473273889325
            ],
            [
              105.82632168456665,
              21.004663881331673
            ],
            [
              105.82654952860072,
              21.004565493831674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9792",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004576361623545,
          "to_latitude": 21.004565493831674,
          "to_longitude": 105.82654952860072,
          "from_longitude": 105.82605498284553
        }
      },
      {
        "id": 9785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82632168456665,
            21.004663881331673,
            105.82639033593216,
            21.00483104967829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82632168456665,
              21.004663881331673
            ],
            [
              105.82637126734531,
              21.00477767934731
            ],
            [
              105.82636856103004,
              21.004784037657664
            ],
            [
              105.82639033593216,
              21.00483104967829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9793",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004663881331673,
          "to_latitude": 21.00483104967829,
          "to_longitude": 105.82639033593216,
          "from_longitude": 105.82632168456665
        }
      },
      {
        "id": 9786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587207000118,
            21.004377209459154,
            105.82645455487265,
            21.00462804496428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82587207000118,
              21.00462804496428
            ],
            [
              105.82608413912352,
              21.00453427514725
            ],
            [
              105.82609004008451,
              21.004537228962693
            ],
            [
              105.82609230386049,
              21.004536097070307
            ],
            [
              105.82609803524196,
              21.00453323131259
            ],
            [
              105.82609767609586,
              21.00452844595169
            ],
            [
              105.826181002291,
              21.004495010479182
            ],
            [
              105.82639220739544,
              21.00440405893734
            ],
            [
              105.82639775575157,
              21.00440167005913
            ],
            [
              105.82645455487265,
              21.004377209459154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9794",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00462804496428,
          "to_latitude": 21.004377209459154,
          "to_longitude": 105.82645455487265,
          "from_longitude": 105.82587207000118
        }
      },
      {
        "id": 9787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82645455487265,
            21.004289357407508,
            105.82653279401407,
            21.004377209459154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82645455487265,
              21.004377209459154
            ],
            [
              105.82653279401407,
              21.004289357407508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9795",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004377209459154,
          "to_latitude": 21.004289357407508,
          "to_longitude": 105.82653279401407,
          "from_longitude": 105.82645455487265
        }
      },
      {
        "id": 9788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82632303502724,
            21.004252809776478,
            105.82639220739544,
            21.00440405893734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82639220739544,
              21.00440405893734
            ],
            [
              105.82632303502724,
              21.004252809776478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9796",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00440405893734,
          "to_latitude": 21.004252809776478,
          "to_longitude": 105.82632303502724,
          "from_longitude": 105.82639220739544
        }
      },
      {
        "id": 9789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82639775575157,
            21.00440167005913,
            105.82641092794621,
            21.004427945109374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82639775575157,
              21.00440167005913
            ],
            [
              105.82640112272894,
              21.004408389075966
            ],
            [
              105.82640472114117,
              21.004415564001974
            ],
            [
              105.82641092794621,
              21.004427945109374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT115_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9797",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00440167005913,
          "to_latitude": 21.004427945109374,
          "to_longitude": 105.82641092794621,
          "from_longitude": 105.82639775575157
        }
      },
      {
        "id": 9790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82596798752378,
            21.004216080130284,
            105.82610159376215,
            21.00455692646392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82610159376215,
              21.00455692646392
            ],
            [
              105.82609377736678,
              21.004539403859013
            ],
            [
              105.82609230386049,
              21.004536097070307
            ],
            [
              105.82609164647926,
              21.00453462167808
            ],
            [
              105.82596798752378,
              21.00425735932526
            ],
            [
              105.82609563933842,
              21.004216080130284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9798",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00455692646392,
          "to_latitude": 21.004216080130284,
          "to_longitude": 105.82609563933842,
          "from_longitude": 105.82610159376215
        }
      },
      {
        "id": 9791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82644548240658,
            21.0044005582331,
            105.82707249083748,
            21.004511402870005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82644548240658,
              21.00441351537191
            ],
            [
              105.82644839763663,
              21.0044169750281
            ],
            [
              105.8265280157704,
              21.004511402870005
            ],
            [
              105.8266827351903,
              21.0044452677525
            ],
            [
              105.82679202815162,
              21.0044005582331
            ],
            [
              105.82707249083748,
              21.004494787731183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT116_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9799",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00441351537191,
          "to_latitude": 21.004494787731183,
          "to_longitude": 105.82707249083748,
          "from_longitude": 105.82644548240658
        }
      },
      {
        "id": 9792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82643980516417,
            21.004415904877487,
            105.82691972835384,
            21.004703706352547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643980516417,
              21.004415904877487
            ],
            [
              105.82644309497202,
              21.004419963361
            ],
            [
              105.82652342617341,
              21.004518991491253
            ],
            [
              105.82660801189037,
              21.00448635356816
            ],
            [
              105.82671508278445,
              21.004703706352547
            ],
            [
              105.82691972835384,
              21.004607076722177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT117_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9800",
          "diaChiLapD": "Ngõ 123, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004415904877487,
          "to_latitude": 21.004607076722177,
          "to_longitude": 105.82691972835384,
          "from_longitude": 105.82643980516417
        }
      },
      {
        "id": 9793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82535143357286,
            21.00441972597984,
            105.82567389784697,
            21.004578101990322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82567389784697,
              21.00441972597984
            ],
            [
              105.82567343844704,
              21.004419968466895
            ],
            [
              105.82565057566659,
              21.00443200016886
            ],
            [
              105.82550872813185,
              21.004506648073217
            ],
            [
              105.82535143357286,
              21.004578101990322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9801",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00441972597984,
          "to_latitude": 21.004578101990322,
          "to_longitude": 105.82535143357286,
          "from_longitude": 105.82567389784697
        }
      },
      {
        "id": 9794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82523759230686,
            21.004361463952872,
            105.82535143357286,
            21.004578101990322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82535143357286,
              21.004578101990322
            ],
            [
              105.82523759230686,
              21.004361463952872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9802",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004578101990322,
          "to_latitude": 21.004361463952872,
          "to_longitude": 105.82523759230686,
          "from_longitude": 105.82535143357286
        }
      },
      {
        "id": 9795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82538695148287,
            21.004291615725627,
            105.82550872813185,
            21.004506648073217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82550872813185,
              21.004506648073217
            ],
            [
              105.82538695148287,
              21.004291615725627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT78_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9803",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004506648073217,
          "to_latitude": 21.004291615725627,
          "to_longitude": 105.82538695148287,
          "from_longitude": 105.82550872813185
        }
      },
      {
        "id": 9796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8255515026287,
            21.00406524313116,
            105.82574753578561,
            21.004169208049053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8255515026287,
              21.004169208049053
            ],
            [
              105.82555608381497,
              21.004160083103777
            ],
            [
              105.82574753578561,
              21.00406524313116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9804",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004169208049053,
          "to_latitude": 21.00406524313116,
          "to_longitude": 105.82574753578561,
          "from_longitude": 105.8255515026287
        }
      },
      {
        "id": 9797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556270809263,
            21.004190843798806,
            105.82580385328717,
            21.00462802731444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556270809263,
              21.004190843798806
            ],
            [
              105.82571816572026,
              21.004472680600905
            ],
            [
              105.82572081321807,
              21.00447748105485
            ],
            [
              105.82580385328717,
              21.00462802731444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9805",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004190843798806,
          "to_latitude": 21.00462802731444,
          "to_longitude": 105.82580385328717,
          "from_longitude": 105.82556270809263
        }
      },
      {
        "id": 9798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82570616734759,
            21.004472680600905,
            105.82571816572026,
            21.004478266560035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82570616734759,
              21.004478266560035
            ],
            [
              105.82571095288104,
              21.00447603856609
            ],
            [
              105.82571695206731,
              21.004473245586453
            ],
            [
              105.82571816572026,
              21.004472680600905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9806",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004478266560035,
          "to_latitude": 21.004472680600905,
          "to_longitude": 105.82571816572026,
          "from_longitude": 105.82570616734759
        }
      },
      {
        "id": 9799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82552266738335,
            21.004194130658806,
            105.82577418069633,
            21.004659855588773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82552266738335,
              21.004194130658806
            ],
            [
              105.8255598497846,
              21.004255083446733
            ],
            [
              105.82565085425303,
              21.00442371802984
            ],
            [
              105.82565043107248,
              21.00443194306374
            ],
            [
              105.82565057566659,
              21.00443200016886
            ],
            [
              105.8256566090898,
              21.00443438161428
            ],
            [
              105.82569600963816,
              21.004507391626554
            ],
            [
              105.82571114445933,
              21.00453690973393
            ],
            [
              105.82577418069633,
              21.004659855588773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9807",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004194130658806,
          "to_latitude": 21.004659855588773,
          "to_longitude": 105.82577418069633,
          "from_longitude": 105.82552266738335
        }
      },
      {
        "id": 9800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82571114445933,
            21.004526840948966,
            105.82573321873856,
            21.00453690973393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82571114445933,
              21.00453690973393
            ],
            [
              105.82573321873856,
              21.004526840948966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9808",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00453690973393,
          "to_latitude": 21.004526840948966,
          "to_longitude": 105.82573321873856,
          "from_longitude": 105.82571114445933
        }
      },
      {
        "id": 9801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82696015507655,
            21.005094916810886,
            105.82746852976918,
            21.005329662412873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746852976918,
              21.005094916810886
            ],
            [
              105.82718992611247,
              21.005223564673617
            ],
            [
              105.82696015507655,
              21.005329662412873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT99_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9809",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005094916810886,
          "to_latitude": 21.005329662412873,
          "to_longitude": 105.82696015507655,
          "from_longitude": 105.82746852976918
        }
      },
      {
        "id": 9802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82718992611247,
            21.005223564673617,
            105.82720246248364,
            21.00524700390214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82718992611247,
              21.005223564673617
            ],
            [
              105.82720064894129,
              21.00524361297028
            ],
            [
              105.82720246248364,
              21.00524700390214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT99_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9810",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005223564673617,
          "to_latitude": 21.00524700390214,
          "to_longitude": 105.82720246248364,
          "from_longitude": 105.82718992611247
        }
      },
      {
        "id": 9803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82692393751101,
            21.004885775755035,
            105.82743559842575,
            21.005126952833372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82743559842575,
              21.004885775755035
            ],
            [
              105.82723398784567,
              21.00497356220953
            ],
            [
              105.82707968449594,
              21.005043336718824
            ],
            [
              105.82692393751101,
              21.005126952833372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9811",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004885775755035,
          "to_latitude": 21.005126952833372,
          "to_longitude": 105.82692393751101,
          "from_longitude": 105.82743559842575
        }
      },
      {
        "id": 9804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82736164862987,
            21.004715771294887,
            105.8275380010946,
            21.005033034065132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82736164862987,
              21.004715771294887
            ],
            [
              105.82746885218559,
              21.004908633727688
            ],
            [
              105.8275380010946,
              21.005033034065132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT113_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9812",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004715771294887,
          "to_latitude": 21.005033034065132,
          "to_longitude": 105.8275380010946,
          "from_longitude": 105.82736164862987
        }
      },
      {
        "id": 9805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82745151527699,
            21.004908633727688,
            105.82746885218559,
            21.00491575415231
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746885218559,
              21.004908633727688
            ],
            [
              105.82745585694117,
              21.004913970848875
            ],
            [
              105.82745151527699,
              21.00491575415231
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT113_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9813",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004908633727688,
          "to_latitude": 21.00491575415231,
          "to_longitude": 105.82745151527699,
          "from_longitude": 105.82746885218559
        }
      },
      {
        "id": 9806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82733620743605,
            21.004513625974578,
            105.82778842735192,
            21.0046934759688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82733620743605,
              21.0046934759688
            ],
            [
              105.82778842735192,
              21.004513625974578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT114_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9814",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0046934759688,
          "to_latitude": 21.004513625974578,
          "to_longitude": 105.82778842735192,
          "from_longitude": 105.82733620743605
        }
      },
      {
        "id": 9807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82680308830722,
            21.0047954626195,
            105.82725785603664,
            21.005006349464654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82680308830722,
              21.005006349464654
            ],
            [
              105.8270739329818,
              21.004880752200577
            ],
            [
              105.82725785603664,
              21.0047954626195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9815",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005006349464654,
          "to_latitude": 21.0047954626195,
          "to_longitude": 105.82725785603664,
          "from_longitude": 105.82680308830722
        }
      },
      {
        "id": 9808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8270739329818,
            21.004880752200577,
            105.82707664423344,
            21.00488663980725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8270739329818,
              21.004880752200577
            ],
            [
              105.82707664423344,
              21.00488663980725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9816",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004880752200577,
          "to_latitude": 21.00488663980725,
          "to_longitude": 105.82707664423344,
          "from_longitude": 105.8270739329818
        }
      },
      {
        "id": 9809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8266962782956,
            21.00488776065372,
            105.82693272626966,
            21.005323016964777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266962782956,
              21.00488776065372
            ],
            [
              105.82670411619527,
              21.004902535506638
            ],
            [
              105.82676412408632,
              21.005015284690337
            ],
            [
              105.82676708556774,
              21.00503412065234
            ],
            [
              105.82676709752782,
              21.005034196465314
            ],
            [
              105.82677707317632,
              21.00503962607855
            ],
            [
              105.82680779992127,
              21.00509738089072
            ],
            [
              105.8268833349035,
              21.00523935798799
            ],
            [
              105.82693272626966,
              21.005323016964777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9817",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00488776065372,
          "to_latitude": 21.005323016964777,
          "to_longitude": 105.82693272626966,
          "from_longitude": 105.8266962782956
        }
      },
      {
        "id": 9810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82668791679909,
            21.004902535506638,
            105.82670411619527,
            21.004910292523693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82670411619527,
              21.004902535506638
            ],
            [
              105.82669498712832,
              21.004906907209428
            ],
            [
              105.82668791679909,
              21.004910292523693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9818",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004902535506638,
          "to_latitude": 21.004910292523693,
          "to_longitude": 105.82668791679909,
          "from_longitude": 105.82670411619527
        }
      },
      {
        "id": 9811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8267024605437,
            21.004620177081343,
            105.82724931750045,
            21.004852195866775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8267024605437,
              21.004852195866775
            ],
            [
              105.82690076355836,
              21.004754831125503
            ],
            [
              105.82708378819605,
              21.004663784412756
            ],
            [
              105.82711464422006,
              21.00464917289923
            ],
            [
              105.82717587849197,
              21.004620177081343
            ],
            [
              105.82721668635179,
              21.00464088484264
            ],
            [
              105.82724931750045,
              21.004701749162095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9819",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004852195866775,
          "to_latitude": 21.004701749162095,
          "to_longitude": 105.82724931750045,
          "from_longitude": 105.8267024605437
        }
      },
      {
        "id": 9812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82710557967332,
            21.004636831783152,
            105.82711464422006,
            21.00464917289923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82711464422006,
              21.00464917289923
            ],
            [
              105.8271103214526,
              21.004641119960105
            ],
            [
              105.82710802011783,
              21.004636831783152
            ],
            [
              105.82710557967332,
              21.004638019644222
            ],
            [
              105.82710665865666,
              21.004640071951933
            ],
            [
              105.82710782449827,
              21.004642351450723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9820",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00464917289923,
          "to_latitude": 21.004642351450723,
          "to_longitude": 105.82710782449827,
          "from_longitude": 105.82711464422006
        }
      },
      {
        "id": 9813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82662076662815,
            21.005341777929633,
            105.8269282569048,
            21.005483058667238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82662076662815,
              21.005483058667238
            ],
            [
              105.82676609208262,
              21.005416520175324
            ],
            [
              105.82692199563215,
              21.005344664354585
            ],
            [
              105.8269282569048,
              21.005341777929633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9821",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005483058667238,
          "to_latitude": 21.005341777929633,
          "to_longitude": 105.8269282569048,
          "from_longitude": 105.82662076662815
        }
      },
      {
        "id": 9814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82648210601312,
            21.005207990986815,
            105.82685520721671,
            21.005373742501696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82685520721671,
              21.005207990986815
            ],
            [
              105.82684602746484,
              21.005212069395416
            ],
            [
              105.82648210601312,
              21.005373742501696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9822",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005207990986815,
          "to_latitude": 21.005373742501696,
          "to_longitude": 105.82648210601312,
          "from_longitude": 105.82685520721671
        }
      },
      {
        "id": 9815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82611048934095,
            21.005591606467878,
            105.8266007255947,
            21.005822261114936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8266007255947,
              21.005591606467878
            ],
            [
              105.82658797684499,
              21.005597006456842
            ],
            [
              105.826584946984,
              21.005601030596576
            ],
            [
              105.82657889773935,
              21.005600852233595
            ],
            [
              105.82611048934095,
              21.005822261114936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT98_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9823",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005591606467878,
          "to_latitude": 21.005822261114936,
          "to_longitude": 105.82611048934095,
          "from_longitude": 105.8266007255947
        }
      },
      {
        "id": 9816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82608400998888,
            21.005536334296274,
            105.82658027054711,
            21.00571988330702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82608400998888,
              21.00571988330702
            ],
            [
              105.82648692298665,
              21.005536334296274
            ],
            [
              105.8265094886602,
              21.005582115871103
            ],
            [
              105.82654948625873,
              21.00556430328236
            ],
            [
              105.82656374915551,
              21.005563298271127
            ],
            [
              105.8265672210715,
              21.005556406045628
            ],
            [
              105.82658027054711,
              21.00555059543592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT96_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9824",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00571988330702,
          "to_latitude": 21.00555059543592,
          "to_longitude": 105.82658027054711,
          "from_longitude": 105.82608400998888
        }
      },
      {
        "id": 9817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82656975541741,
            21.005565138290404,
            105.82658752429947,
            21.00557313685614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82658752429947,
              21.005565138290404
            ],
            [
              105.82656975541741,
              21.00557313685614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9825",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005565138290404,
          "to_latitude": 21.00557313685614,
          "to_longitude": 105.82656975541741,
          "from_longitude": 105.82658752429947
        }
      },
      {
        "id": 9818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82656975541741,
            21.00557313685614,
            105.82678574266274,
            21.0059697198654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656975541741,
              21.00557313685614
            ],
            [
              105.826584946984,
              21.005601030596576
            ],
            [
              105.82678574266274,
              21.0059697198654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9826",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557313685614,
          "to_latitude": 21.0059697198654,
          "to_longitude": 105.82678574266274,
          "from_longitude": 105.82656975541741
        }
      },
      {
        "id": 9819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650022046705,
            21.005459235296303,
            105.82656975541741,
            21.00557313685614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656975541741,
              21.00557313685614
            ],
            [
              105.82656374915551,
              21.005563298271127
            ],
            [
              105.82650022046705,
              21.005459235296303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9827",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557313685614,
          "to_latitude": 21.005459235296303,
          "to_longitude": 105.82650022046705,
          "from_longitude": 105.82656975541741
        }
      },
      {
        "id": 9820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82593524658878,
            21.005075566820487,
            105.82636738369808,
            21.005384864952955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631536080362,
              21.005075566820487
            ],
            [
              105.82631763171196,
              21.005080795415505
            ],
            [
              105.82636738369808,
              21.00517760741653
            ],
            [
              105.82593524658878,
              21.005384864952955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9828",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005075566820487,
          "to_latitude": 21.005384864952955,
          "to_longitude": 105.82593524658878,
          "from_longitude": 105.82631536080362
        }
      },
      {
        "id": 9821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82572589632316,
            21.00506329139135,
            105.8262760373624,
            21.005355030140002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82572589632316,
              21.005355030140002
            ],
            [
              105.82581362342229,
              21.005313691923725
            ],
            [
              105.82581854107565,
              21.005282504919574
            ],
            [
              105.82599285646779,
              21.005201094782432
            ],
            [
              105.82607974593643,
              21.005160514692605
            ],
            [
              105.8262760373624,
              21.00506329139135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9829",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005355030140002,
          "to_latitude": 21.00506329139135,
          "to_longitude": 105.8262760373624,
          "from_longitude": 105.82572589632316
        }
      },
      {
        "id": 9822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82599285646779,
            21.005201094782432,
            105.82599554316998,
            21.00520549942734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82599285646779,
              21.005201094782432
            ],
            [
              105.82599554316998,
              21.00520549942734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9830",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005201094782432,
          "to_latitude": 21.00520549942734,
          "to_longitude": 105.82599554316998,
          "from_longitude": 105.82599285646779
        }
      },
      {
        "id": 9823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82631732630233,
            21.004923969691557,
            105.82663741704387,
            21.005072494434888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82631732630233,
              21.005072494434888
            ],
            [
              105.82640243540698,
              21.005033003087547
            ],
            [
              105.82647407039718,
              21.00499976423401
            ],
            [
              105.82663741704387,
              21.004923969691557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9831",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005072494434888,
          "to_latitude": 21.004923969691557,
          "to_longitude": 105.82663741704387,
          "from_longitude": 105.82631732630233
        }
      },
      {
        "id": 9824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82640243540698,
            21.005033003087547,
            105.82640962173319,
            21.005045615179583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82640243540698,
              21.005033003087547
            ],
            [
              105.82640650205964,
              21.005040140513156
            ],
            [
              105.8264087398488,
              21.005044070427193
            ],
            [
              105.82640962173319,
              21.005045615179583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9832",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005033003087547,
          "to_latitude": 21.005045615179583,
          "to_longitude": 105.82640962173319,
          "from_longitude": 105.82640243540698
        }
      },
      {
        "id": 9825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82572580454645,
            21.005371390738787,
            105.82615134123272,
            21.006212087985446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82572580454645,
              21.005371390738787
            ],
            [
              105.82586151796613,
              21.005639510067393
            ],
            [
              105.82598716799804,
              21.00588774659464
            ],
            [
              105.82613151192835,
              21.006172913737274
            ],
            [
              105.82615134123272,
              21.006212087985446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9833",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 103.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005371390738787,
          "to_latitude": 21.006212087985446,
          "to_longitude": 105.82615134123272,
          "from_longitude": 105.82572580454645
        }
      },
      {
        "id": 9826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259576865842,
            21.00588774659464,
            105.82598716799804,
            21.005899819495404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82598716799804,
              21.00588774659464
            ],
            [
              105.8259576865842,
              21.005899819495404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9834",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00588774659464,
          "to_latitude": 21.005899819495404,
          "to_longitude": 105.8259576865842,
          "from_longitude": 105.82598716799804
        }
      },
      {
        "id": 9827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82582939741566,
            21.005367743250645,
            105.8264369101846,
            21.005648649424607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82582939741566,
              21.005648649424607
            ],
            [
              105.8258305343483,
              21.005648124552323
            ],
            [
              105.8258515008344,
              21.00563842964084
            ],
            [
              105.82586151796613,
              21.005639510067393
            ],
            [
              105.82586649580874,
              21.005631495965606
            ],
            [
              105.82597330846588,
              21.00558210762096
            ],
            [
              105.8264369101846,
              21.005367743250645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT119_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9835",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005648649424607,
          "to_latitude": 21.005367743250645,
          "to_longitude": 105.8264369101846,
          "from_longitude": 105.82582939741566
        }
      },
      {
        "id": 9828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82597330846588,
            21.00558210762096,
            105.82623286148197,
            21.00607200006205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82597330846588,
              21.00558210762096
            ],
            [
              105.82623286148197,
              21.00607200006205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT119_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9836",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00558210762096,
          "to_latitude": 21.00607200006205,
          "to_longitude": 105.82623286148197,
          "from_longitude": 105.82597330846588
        }
      },
      {
        "id": 9829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8289188039671,
            21.00629858525415,
            105.82929173008438,
            21.00697834134295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82929173008438,
              21.00697834134295
            ],
            [
              105.8292886364481,
              21.00697273112386
            ],
            [
              105.8289188039671,
              21.00629858525415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9837",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00697834134295,
          "to_latitude": 21.00629858525415,
          "to_longitude": 105.8289188039671,
          "from_longitude": 105.82929173008438
        }
      },
      {
        "id": 9830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931099394617,
            21.00683080867035,
            105.82960060010498,
            21.00696376604319
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82960060010498,
              21.00683080867035
            ],
            [
              105.8294815654369,
              21.006885457817393
            ],
            [
              105.8294167298518,
              21.00691522350678
            ],
            [
              105.82931099394617,
              21.00696376604319
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9838",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00683080867035,
          "to_latitude": 21.00696376604319,
          "to_longitude": 105.82931099394617,
          "from_longitude": 105.82960060010498
        }
      },
      {
        "id": 9831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294167298518,
            21.00691522350678,
            105.82941970177177,
            21.00692108722006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8294167298518,
              21.00691522350678
            ],
            [
              105.82941751758133,
              21.006916780442886
            ],
            [
              105.82941970177177,
              21.00692108722006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9839",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00691522350678,
          "to_latitude": 21.00692108722006,
          "to_longitude": 105.82941970177177,
          "from_longitude": 105.8294167298518
        }
      },
      {
        "id": 9832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82861025043206,
            21.00685941448588,
            105.82918049498026,
            21.007112438850253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82861025043206,
              21.007109933347365
            ],
            [
              105.8286106998488,
              21.00710997270763
            ],
            [
              105.82863994124591,
              21.007112438850253
            ],
            [
              105.82918049498026,
              21.00685941448588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9840",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007109933347365,
          "to_latitude": 21.00685941448588,
          "to_longitude": 105.82918049498026,
          "from_longitude": 105.82861025043206
        }
      },
      {
        "id": 9833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82869722918723,
            21.006987905283736,
            105.8292626946588,
            21.007255933072152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82870199216825,
              21.007255933072152
            ],
            [
              105.82870050403201,
              21.00725330199406
            ],
            [
              105.82869722918723,
              21.007247446968726
            ],
            [
              105.82898916743461,
              21.00711350467288
            ],
            [
              105.8292626946588,
              21.006987905283736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9841",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007255933072152,
          "to_latitude": 21.006987905283736,
          "to_longitude": 105.8292626946588,
          "from_longitude": 105.82870199216825
        }
      },
      {
        "id": 9834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82833421819882,
            21.007131900072253,
            105.82866259677618,
            21.007413891394165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82860222072954,
              21.007131900072253
            ],
            [
              105.82866259677618,
              21.007259505678014
            ],
            [
              105.82859606641455,
              21.007291084864548
            ],
            [
              105.82844844427382,
              21.00736115418101
            ],
            [
              105.82833421819882,
              21.007413891394165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9842",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007131900072253,
          "to_latitude": 21.007413891394165,
          "to_longitude": 105.82833421819882,
          "from_longitude": 105.82860222072954
        }
      },
      {
        "id": 9835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82859606641455,
            21.007291084864548,
            105.82860210181444,
            21.007301808390192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859606641455,
              21.007291084864548
            ],
            [
              105.82859809964296,
              21.007294707741934
            ],
            [
              105.82860210181444,
              21.007301808390192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9843",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007291084864548,
          "to_latitude": 21.007301808390192,
          "to_longitude": 105.82860210181444,
          "from_longitude": 105.82859606641455
        }
      },
      {
        "id": 9836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82790379706317,
            21.007421937760718,
            105.82831446028592,
            21.0076207220171
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82831446028592,
              21.007421937760718
            ],
            [
              105.82830763660857,
              21.00742523703983
            ],
            [
              105.82808602086185,
              21.007532382330627
            ],
            [
              105.82790379706317,
              21.0076207220171
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9844",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007421937760718,
          "to_latitude": 21.0076207220171,
          "to_longitude": 105.82790379706317,
          "from_longitude": 105.82831446028592
        }
      },
      {
        "id": 9837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82830763660857,
            21.00742523703983,
            105.82831425787177,
            21.007436325337654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82830763660857,
              21.00742523703983
            ],
            [
              105.8283096579504,
              21.007428624236503
            ],
            [
              105.82831425787177,
              21.007436325337654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9845",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00742523703983,
          "to_latitude": 21.007436325337654,
          "to_longitude": 105.82831425787177,
          "from_longitude": 105.82830763660857
        }
      },
      {
        "id": 9838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82723256470733,
            21.007669108125242,
            105.8278042211331,
            21.007930945892397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82723256470733,
              21.007930945892397
            ],
            [
              105.82750841378984,
              21.007804598222606
            ],
            [
              105.8278042211331,
              21.007669108125242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9846",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007930945892397,
          "to_latitude": 21.007669108125242,
          "to_longitude": 105.8278042211331,
          "from_longitude": 105.82723256470733
        }
      },
      {
        "id": 9839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82750841378984,
            21.007804598222606,
            105.82751384660475,
            21.00781263490913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82750841378984,
              21.007804598222606
            ],
            [
              105.82751384660475,
              21.00781263490913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9847",
          "diaChiLapD": "Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007804598222606,
          "to_latitude": 21.00781263490913,
          "to_longitude": 105.82751384660475,
          "from_longitude": 105.82750841378984
        }
      },
      {
        "id": 9840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82846728663733,
            21.006955255396342,
            105.82853111837098,
            21.006983256357486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82853111837098,
              21.006955255396342
            ],
            [
              105.82846728663733,
              21.006983256357486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9848",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006955255396342,
          "to_latitude": 21.006983256357486,
          "to_longitude": 105.82846728663733,
          "from_longitude": 105.82853111837098
        }
      },
      {
        "id": 9841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82842995467433,
            21.006734730591713,
            105.82850241026192,
            21.00676539648923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82842995467433,
              21.00676539648923
            ],
            [
              105.82850241026192,
              21.006734730591713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT42_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9849",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00676539648923,
          "to_latitude": 21.006734730591713,
          "to_longitude": 105.82850241026192,
          "from_longitude": 105.82842995467433
        }
      },
      {
        "id": 9842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82823047398259,
            21.006510820258004,
            105.82829430653976,
            21.00653882113162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82829430653976,
              21.006510820258004
            ],
            [
              105.82823047398259,
              21.00653882113162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9850",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006510820258004,
          "to_latitude": 21.00653882113162,
          "to_longitude": 105.82823047398259,
          "from_longitude": 105.82829430653976
        }
      },
      {
        "id": 9843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766922445451,
            21.005278825169505,
            105.8279304040318,
            21.00577254389344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766922445451,
              21.005278825169505
            ],
            [
              105.82768279072623,
              21.005304469547905
            ],
            [
              105.82768442952894,
              21.005307567775326
            ],
            [
              105.82775096528981,
              21.005433342784325
            ],
            [
              105.82775270615042,
              21.005436632901922
            ],
            [
              105.82779308918427,
              21.00551297231888
            ],
            [
              105.82782099749458,
              21.00556572953721
            ],
            [
              105.82782242633697,
              21.005568428654804
            ],
            [
              105.82789187740708,
              21.005699716262885
            ],
            [
              105.82789309533364,
              21.005702018082765
            ],
            [
              105.8279304040318,
              21.00577254389344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT103_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9851",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005278825169505,
          "to_latitude": 21.00577254389344,
          "to_longitude": 105.8279304040318,
          "from_longitude": 105.82766922445451
        }
      },
      {
        "id": 9844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82780028013376,
            21.005568428654804,
            105.82782242633697,
            21.005578188253043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82782242633697,
              21.005568428654804
            ],
            [
              105.82781164110249,
              21.005573182408828
            ],
            [
              105.8278006956174,
              21.005578005586838
            ],
            [
              105.82780028013376,
              21.005578188253043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT103_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9852",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005568428654804,
          "to_latitude": 21.005578188253043,
          "to_longitude": 105.82780028013376,
          "from_longitude": 105.82782242633697
        }
      },
      {
        "id": 9845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766717513792,
            21.004969107303907,
            105.82815822178664,
            21.00520094175582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82815822178664,
              21.004969107303907
            ],
            [
              105.82797879550371,
              21.00505381868136
            ],
            [
              105.8279762300162,
              21.005060062523608
            ],
            [
              105.82796815612322,
              21.00506373186958
            ],
            [
              105.82796544947749,
              21.00506496258638
            ],
            [
              105.82796054565961,
              21.005067192121814
            ],
            [
              105.82795146973722,
              21.005066720530497
            ],
            [
              105.8278614739637,
              21.005109208930342
            ],
            [
              105.82777660598703,
              21.005149276986703
            ],
            [
              105.82766717513792,
              21.00520094175582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT104_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9853",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004969107303907,
          "to_latitude": 21.00520094175582,
          "to_longitude": 105.82766717513792,
          "from_longitude": 105.82815822178664
        }
      },
      {
        "id": 9846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82777017513003,
            21.005136235861826,
            105.82777660598703,
            21.005149276986703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82777017513003,
              21.005136235861826
            ],
            [
              105.82777117124309,
              21.00513825876139
            ],
            [
              105.82777660598703,
              21.005149276986703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT104_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9854",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005136235861826,
          "to_latitude": 21.005149276986703,
          "to_longitude": 105.82777660598703,
          "from_longitude": 105.82777017513003
        }
      },
      {
        "id": 9847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82795680661185,
            21.00504535131899,
            105.82825943712038,
            21.005548386683174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82795680661185,
              21.00504535131899
            ],
            [
              105.82796167986778,
              21.005056412011644
            ],
            [
              105.82796544947749,
              21.00506496258638
            ],
            [
              105.82801615444151,
              21.00518002274973
            ],
            [
              105.82797378246693,
              21.005261681877172
            ],
            [
              105.82810185815168,
              21.005499559404146
            ],
            [
              105.82819305248526,
              21.005548386683174
            ],
            [
              105.82825943712038,
              21.00551771250654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT104_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9855",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00504535131899,
          "to_latitude": 21.00551771250654,
          "to_longitude": 105.82825943712038,
          "from_longitude": 105.82795680661185
        }
      },
      {
        "id": 9848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82795828306811,
            21.00504465136158,
            105.82833149030469,
            21.005518271506887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82795828306811,
              21.00504465136158
            ],
            [
              105.82796394538532,
              21.005055597118748
            ],
            [
              105.82796815612322,
              21.00506373186958
            ],
            [
              105.8282020214495,
              21.00551570223158
            ],
            [
              105.82822363544716,
              21.005518271506887
            ],
            [
              105.82833149030469,
              21.005463385015403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT105_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9856",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00504465136158,
          "to_latitude": 21.005463385015403,
          "to_longitude": 105.82833149030469,
          "from_longitude": 105.82795828306811
        }
      },
      {
        "id": 9849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82763003774669,
            21.004797012061385,
            105.82845161249922,
            21.00515672062126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82763003774669,
              21.00515672062126
            ],
            [
              105.82794075931442,
              21.005015285994237
            ],
            [
              105.82812311925339,
              21.00493736890681
            ],
            [
              105.82845161249922,
              21.004797012061385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9857",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 94.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00515672062126,
          "to_latitude": 21.004797012061385,
          "to_longitude": 105.82845161249922,
          "from_longitude": 105.82763003774669
        }
      },
      {
        "id": 9850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82812311925339,
            21.00493736890681,
            105.8281356224563,
            21.004960528224995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82812311925339,
              21.00493736890681
            ],
            [
              105.8281356224563,
              21.004960528224995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT109_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9858",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00493736890681,
          "to_latitude": 21.004960528224995,
          "to_longitude": 105.8281356224563,
          "from_longitude": 105.82812311925339
        }
      },
      {
        "id": 9851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82814731134066,
            21.004955343731666,
            105.82845143626464,
            21.00547531541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82814731134066,
              21.004955343731666
            ],
            [
              105.82816173838421,
              21.004963878574184
            ],
            [
              105.82818408628923,
              21.00497709513606
            ],
            [
              105.82839023162788,
              21.005339445573455
            ],
            [
              105.8283900220746,
              21.005356198739133
            ],
            [
              105.82838988462798,
              21.005367195290024
            ],
            [
              105.82845143626464,
              21.00547531541
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT106_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9859",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004955343731666,
          "to_latitude": 21.00547531541,
          "to_longitude": 105.82845143626464,
          "from_longitude": 105.82814731134066
        }
      },
      {
        "id": 9852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283900220746,
            21.005256985514997,
            105.82862601538379,
            21.005356198739133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283900220746,
              21.005356198739133
            ],
            [
              105.82858431299492,
              21.005256985514997
            ],
            [
              105.82862601538379,
              21.005293827425625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT106_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9860",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005356198739133,
          "to_latitude": 21.005293827425625,
          "to_longitude": 105.82862601538379,
          "from_longitude": 105.8283900220746
        }
      },
      {
        "id": 9853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82837679215454,
            21.004853565067584,
            105.82861199442453,
            21.005267913469382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82837679215454,
              21.004853565067584
            ],
            [
              105.82837778677303,
              21.004855316099516
            ],
            [
              105.82838769417148,
              21.004872769703884
            ],
            [
              105.82861199442453,
              21.005267913469382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9861",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004853565067584,
          "to_latitude": 21.005267913469382,
          "to_longitude": 105.82861199442453,
          "from_longitude": 105.82837679215454
        }
      },
      {
        "id": 9854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82822187208716,
            21.004872769703884,
            105.82838769417148,
            21.004946542073288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82838769417148,
              21.004872769703884
            ],
            [
              105.82822187208716,
              21.004946542073288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT108_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9862",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004872769703884,
          "to_latitude": 21.004946542073288,
          "to_longitude": 105.82822187208716,
          "from_longitude": 105.82838769417148
        }
      },
      {
        "id": 9855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8285040267412,
            21.00473493140005,
            105.82890496499219,
            21.00530064961784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82851065852873,
              21.004794191996044
            ],
            [
              105.8285040267412,
              21.00477978348933
            ],
            [
              105.82860618824859,
              21.00473493140005
            ],
            [
              105.82890496499219,
              21.00530064961784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT130_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9863",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004794191996044,
          "to_latitude": 21.00530064961784,
          "to_longitude": 105.82890496499219,
          "from_longitude": 105.82851065852873
        }
      },
      {
        "id": 9856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8281336076,
            21.004573941599052,
            105.82850141932057,
            21.004798289749356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281336076,
              21.004573941599052
            ],
            [
              105.82844446131693,
              21.00459894394994
            ],
            [
              105.82850141932057,
              21.004798289749356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT129_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9864",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004573941599052,
          "to_latitude": 21.004798289749356,
          "to_longitude": 105.82850141932057,
          "from_longitude": 105.8281336076
        }
      },
      {
        "id": 9857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82823153754957,
            21.00467780717503,
            105.82847531560327,
            21.00480986702068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82823153754957,
              21.004755623348423
            ],
            [
              105.82840998950626,
              21.00467780717503
            ],
            [
              105.82847531560327,
              21.00480986702068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT130_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9865",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004755623348423,
          "to_latitude": 21.00480986702068,
          "to_longitude": 105.82847531560327,
          "from_longitude": 105.82823153754957
        }
      },
      {
        "id": 9858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.827669052071,
            21.004784852140283,
            105.8277685652639,
            21.004984745453314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277685652639,
              21.004984745453314
            ],
            [
              105.82775700925644,
              21.004961531822556
            ],
            [
              105.82775620390292,
              21.00495991354392
            ],
            [
              105.82775510967869,
              21.00495771589533
            ],
            [
              105.827669052071,
              21.004784852140283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT112_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9866",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004984745453314,
          "to_latitude": 21.004784852140283,
          "to_longitude": 105.827669052071,
          "from_longitude": 105.8277685652639
        }
      },
      {
        "id": 9859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275736152271,
            21.00495991354392,
            105.82775620390292,
            21.00504052297239
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82775620390292,
              21.00495991354392
            ],
            [
              105.8275736152271,
              21.00504052297239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT112_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9867",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00495991354392,
          "to_latitude": 21.00504052297239,
          "to_longitude": 105.8275736152271,
          "from_longitude": 105.82775620390292
        }
      },
      {
        "id": 9860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82776127371905,
            21.004552316792072,
            105.82792998734155,
            21.004910149501956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82792998734155,
              21.004910149501956
            ],
            [
              105.82792604208726,
              21.004903584169874
            ],
            [
              105.82776127371905,
              21.004552316792072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT110_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9868",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004910149501956,
          "to_latitude": 21.004552316792072,
          "to_longitude": 105.82776127371905,
          "from_longitude": 105.82792998734155
        }
      },
      {
        "id": 9861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82961077987346,
            21.00647977126418,
            105.82984348600735,
            21.006628190928026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82961077987346,
              21.00662518472885
            ],
            [
              105.82963487566361,
              21.006614350487375
            ],
            [
              105.82963679369064,
              21.006613487588442
            ],
            [
              105.82964463350045,
              21.006628190928026
            ],
            [
              105.82984348600735,
              21.00652490756621
            ],
            [
              105.8298142742397,
              21.00647977126418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9869",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00662518472885,
          "to_latitude": 21.00647977126418,
          "to_longitude": 105.8298142742397,
          "from_longitude": 105.82961077987346
        }
      },
      {
        "id": 9862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82964463350045,
            21.006628190928026,
            105.82971092425119,
            21.00675795763996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82964463350045,
              21.006628190928026
            ],
            [
              105.82971092425119,
              21.00675795763996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9870",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006628190928026,
          "to_latitude": 21.00675795763996,
          "to_longitude": 105.82971092425119,
          "from_longitude": 105.82964463350045
        }
      },
      {
        "id": 9863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8293807607767,
            21.006105850512238,
            105.82961793458998,
            21.006601916160044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8293807607767,
              21.006105850512238
            ],
            [
              105.82949458204551,
              21.006327466918794
            ],
            [
              105.82949324567781,
              21.006335065953415
            ],
            [
              105.82948895489618,
              21.00635945681821
            ],
            [
              105.82961793458998,
              21.006601916160044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9871",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006105850512238,
          "to_latitude": 21.006601916160044,
          "to_longitude": 105.82961793458998,
          "from_longitude": 105.8293807607767
        }
      },
      {
        "id": 9864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82946675702404,
            21.006335065953415,
            105.82949324567781,
            21.006346642546657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82949324567781,
              21.006335065953415
            ],
            [
              105.82946675702404,
              21.006346642546657
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9872",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006335065953415,
          "to_latitude": 21.006346642546657,
          "to_longitude": 105.82946675702404,
          "from_longitude": 105.82949324567781
        }
      },
      {
        "id": 9865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828757268467,
            21.005414859839195,
            105.8289326128697,
            21.005489075629544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.828757268467,
              21.005489075629544
            ],
            [
              105.8289326128697,
              21.005414859839195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9873",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005489075629544,
          "to_latitude": 21.005414859839195,
          "to_longitude": 105.8289326128697,
          "from_longitude": 105.828757268467
        }
      },
      {
        "id": 9866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8289326128697,
            21.005414859839195,
            105.82964529288803,
            21.006813747438144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289326128697,
              21.005414859839195
            ],
            [
              105.82964406338624,
              21.006811272269076
            ],
            [
              105.82964529288803,
              21.006813747438144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9874",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 174.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005414859839195,
          "to_latitude": 21.006813747438144,
          "to_longitude": 105.82964529288803,
          "from_longitude": 105.8289326128697
        }
      },
      {
        "id": 9867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82781475186327,
            21.004538240939056,
            105.82797102537806,
            21.00490244847062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8279465370989,
              21.00490244847062
            ],
            [
              105.82793303714323,
              21.004877667274584
            ],
            [
              105.82792890932504,
              21.00487009031332
            ],
            [
              105.82797102537806,
              21.004850153543185
            ],
            [
              105.82781475186327,
              21.004538240939056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT110A_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9875",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00490244847062,
          "to_latitude": 21.004538240939056,
          "to_longitude": 105.82781475186327,
          "from_longitude": 105.8279465370989
        }
      },
      {
        "id": 9868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578079493234,
            21.005583107734527,
            105.82613535809078,
            21.006293230193986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578079493234,
              21.005583107734527
            ],
            [
              105.82596228498394,
              21.0059465969859
            ],
            [
              105.82613535809078,
              21.006293230193986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9876",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005583107734527,
          "to_latitude": 21.006293230193986,
          "to_longitude": 105.82613535809078,
          "from_longitude": 105.82578079493234
        }
      },
      {
        "id": 9869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82596228498394,
            21.00593872079977,
            105.8259775556635,
            21.0059465969859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259775556635,
              21.00593872079977
            ],
            [
              105.82597753072496,
              21.005938733565962
            ],
            [
              105.82596228498394,
              21.0059465969859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9877",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00593872079977,
          "to_latitude": 21.0059465969859,
          "to_longitude": 105.82596228498394,
          "from_longitude": 105.8259775556635
        }
      },
      {
        "id": 9870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82610123187102,
            21.00588752914434,
            105.82672954531266,
            21.006180748986456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82672954531266,
              21.00588752914434
            ],
            [
              105.8264621285237,
              21.006018702040667
            ],
            [
              105.82613818916009,
              21.006164155248843
            ],
            [
              105.82613151192835,
              21.006172913737274
            ],
            [
              105.82612343196246,
              21.00617078160062
            ],
            [
              105.82610123187102,
              21.006180748986456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9878",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00588752914434,
          "to_latitude": 21.006180748986456,
          "to_longitude": 105.82610123187102,
          "from_longitude": 105.82672954531266
        }
      },
      {
        "id": 9871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82520474668712,
            21.002887321492384,
            105.82559527917984,
            21.00302685088381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82520474668712,
              21.00302685088381
            ],
            [
              105.8252418711514,
              21.003022763534485
            ],
            [
              105.82535814672168,
              21.003003607719595
            ],
            [
              105.8254044747209,
              21.002941716117245
            ],
            [
              105.82556094200851,
              21.002960095781038
            ],
            [
              105.82559527917984,
              21.002887321492384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT208_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9879",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00302685088381,
          "to_latitude": 21.002887321492384,
          "to_longitude": 105.82559527917984,
          "from_longitude": 105.82520474668712
        }
      },
      {
        "id": 9872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82520427919472,
            21.003040698354514,
            105.8252624809487,
            21.003390316928726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82520427919472,
              21.003040841434373
            ],
            [
              105.82520808159305,
              21.003040698354514
            ],
            [
              105.82525290783794,
              21.003328790328766
            ],
            [
              105.8252624809487,
              21.003390316928726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT207_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9880",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003040841434373,
          "to_latitude": 21.003390316928726,
          "to_longitude": 105.8252624809487,
          "from_longitude": 105.82520427919472
        }
      },
      {
        "id": 9873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514591644876,
            21.0033261850206,
            105.82525290783794,
            21.003335279370162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82525290783794,
              21.003328790328766
            ],
            [
              105.82520008165902,
              21.0033261850206
            ],
            [
              105.82514591644876,
              21.003335279370162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT207_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9881",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003328790328766,
          "to_latitude": 21.003335279370162,
          "to_longitude": 105.82514591644876,
          "from_longitude": 105.82525290783794
        }
      },
      {
        "id": 9874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82439064250259,
            21.003028037013713,
            105.82520470703918,
            21.00333025820159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82520470703918,
              21.003028037013713
            ],
            [
              105.8251925944608,
              21.00303312761664
            ],
            [
              105.82493360817588,
              21.003140691066914
            ],
            [
              105.8248741875436,
              21.00316345947045
            ],
            [
              105.82483062148717,
              21.003180152690156
            ],
            [
              105.8245995342064,
              21.003244618425875
            ],
            [
              105.82439064250259,
              21.00333025820159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26C_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9882",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 91.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003028037013713,
          "to_latitude": 21.00333025820159,
          "to_longitude": 105.82439064250259,
          "from_longitude": 105.82520470703918
        }
      },
      {
        "id": 9875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82439150772996,
            21.003013219878127,
            105.82520520172827,
            21.00329707936454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82439150772996,
              21.00329707936454
            ],
            [
              105.82457672309918,
              21.003240828798067
            ],
            [
              105.82463882075854,
              21.003223504827748
            ],
            [
              105.8247772704461,
              21.003180219733075
            ],
            [
              105.82488339654287,
              21.003149138427354
            ],
            [
              105.82518728449719,
              21.00302078817506
            ],
            [
              105.82520520172827,
              21.003013219878127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26D_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9883",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00329707936454,
          "to_latitude": 21.003013219878127,
          "to_longitude": 105.82520520172827,
          "from_longitude": 105.82439150772996
        }
      },
      {
        "id": 9876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82478022652894,
            21.00316345947045,
            105.82492633566194,
            21.003353209611237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8248741875436,
              21.00316345947045
            ],
            [
              105.82490887775474,
              21.003257758111594
            ],
            [
              105.82492633566194,
              21.00330521488256
            ],
            [
              105.82478022652894,
              21.003353209611237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26C_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9884",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00316345947045,
          "to_latitude": 21.003353209611237,
          "to_longitude": 105.82478022652894,
          "from_longitude": 105.8248741875436
        }
      },
      {
        "id": 9877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82490887775474,
            21.00319596503897,
            105.82506963118647,
            21.003257758111594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82490887775474,
              21.003257758111594
            ],
            [
              105.82506963118647,
              21.00319596503897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26C_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9885",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003257758111594,
          "to_latitude": 21.00319596503897,
          "to_longitude": 105.82506963118647,
          "from_longitude": 105.82490887775474
        }
      },
      {
        "id": 9878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82585842953395,
            21.00389191358903,
            105.82591289018661,
            21.003985613096088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82585842953395,
              21.003985613096088
            ],
            [
              105.82588455582102,
              21.003931627786855
            ],
            [
              105.82591289018661,
              21.00389191358903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT192_Ngõ 139, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9886",
          "diaChiLapD": "Ngõ 139, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003985613096088,
          "to_latitude": 21.00389191358903,
          "to_longitude": 105.82591289018661,
          "from_longitude": 105.82585842953395
        }
      },
      {
        "id": 9879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8259551190026,
            21.00379999378538,
            105.82614091515686,
            21.004014288353495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8259551190026,
              21.004014288353495
            ],
            [
              105.82596498438035,
              21.003992532315603
            ],
            [
              105.82604020440182,
              21.00395222837254
            ],
            [
              105.8260788576962,
              21.003927561511773
            ],
            [
              105.82614091515686,
              21.00379999378538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT192_Ngõ 139, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9887",
          "diaChiLapD": "Ngõ 139, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004014288353495,
          "to_latitude": 21.00379999378538,
          "to_longitude": 105.82614091515686,
          "from_longitude": 105.8259551190026
        }
      },
      {
        "id": 9880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82539798151045,
            21.003901471894206,
            105.82550431541512,
            21.0040938268547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82539798151045,
              21.003901471894206
            ],
            [
              105.82539855374493,
              21.003902507830677
            ],
            [
              105.825414219638,
              21.003930846489247
            ],
            [
              105.82550431541512,
              21.0040938268547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9888",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003901471894206,
          "to_latitude": 21.0040938268547,
          "to_longitude": 105.82550431541512,
          "from_longitude": 105.82539798151045
        }
      },
      {
        "id": 9881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82539661974621,
            21.003270985609245,
            105.82589945654901,
            21.003901471894206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82539798151045,
              21.003901471894206
            ],
            [
              105.82539820073887,
              21.003897959986308
            ],
            [
              105.82539661974621,
              21.00387755290822
            ],
            [
              105.8256266160393,
              21.003751738748708
            ],
            [
              105.82563137160103,
              21.0037491369667
            ],
            [
              105.8257897752755,
              21.003617392426683
            ],
            [
              105.82579096738854,
              21.003614836832217
            ],
            [
              105.82583341096644,
              21.003523849333213
            ],
            [
              105.82582127104575,
              21.00350876472487
            ],
            [
              105.82581019945798,
              21.003476668967945
            ],
            [
              105.82587979830372,
              21.003336578525367
            ],
            [
              105.82587984384118,
              21.00331146677783
            ],
            [
              105.82589945654901,
              21.003270985609245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9889",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003901471894206,
          "to_latitude": 21.003270985609245,
          "to_longitude": 105.82589945654901,
          "from_longitude": 105.82539798151045
        }
      },
      {
        "id": 9882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256266160393,
            21.003751738748708,
            105.82583093417414,
            21.003890223329453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256266160393,
              21.003751738748708
            ],
            [
              105.82574802046297,
              21.003890223329453
            ],
            [
              105.82583093417414,
              21.003782570041178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9890",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003751738748708,
          "to_latitude": 21.003782570041178,
          "to_longitude": 105.82583093417414,
          "from_longitude": 105.8256266160393
        }
      },
      {
        "id": 9883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82579096738854,
            21.003614836832217,
            105.82591432083225,
            21.003682576496796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82579096738854,
              21.003614836832217
            ],
            [
              105.82591432083225,
              21.003682576496796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9891",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003614836832217,
          "to_latitude": 21.003682576496796,
          "to_longitude": 105.82591432083225,
          "from_longitude": 105.82579096738854
        }
      },
      {
        "id": 9884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82538141705892,
            21.00328179225216,
            105.82586570441485,
            21.003932162368166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82540083485226,
              21.003932162368166
            ],
            [
              105.82540050445613,
              21.003931201130015
            ],
            [
              105.82538141705892,
              21.0038755483051
            ],
            [
              105.8254591382538,
              21.003832486675467
            ],
            [
              105.82556272172886,
              21.00376595114531
            ],
            [
              105.82579101254092,
              21.00360049155672
            ],
            [
              105.82580418733892,
              21.00357552656243
            ],
            [
              105.8258200117539,
              21.003517815753234
            ],
            [
              105.82579976839033,
              21.003470431307733
            ],
            [
              105.82586570441485,
              21.003349382415657
            ],
            [
              105.82572958437689,
              21.00328179225216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT30_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9892",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 105.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003932162368166,
          "to_latitude": 21.00328179225216,
          "to_longitude": 105.82572958437689,
          "from_longitude": 105.82540083485226
        }
      },
      {
        "id": 9885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540809535958,
            21.003956889136788,
            105.82548331475901,
            21.004096225615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82540809535958,
              21.003956889136788
            ],
            [
              105.82545300930776,
              21.004040087980275
            ],
            [
              105.82545320269067,
              21.004040446526904
            ],
            [
              105.82548331475901,
              21.004096225615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9893",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003956889136788,
          "to_latitude": 21.004096225615,
          "to_longitude": 105.82548331475901,
          "from_longitude": 105.82540809535958
        }
      },
      {
        "id": 9886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82545320269067,
            21.004035844588497,
            105.82546474860378,
            21.004040446526904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82545320269067,
              21.004040446526904
            ],
            [
              105.82546069937581,
              21.004037458422722
            ],
            [
              105.82546431479179,
              21.00403601740237
            ],
            [
              105.82546472268885,
              21.004035854649672
            ],
            [
              105.82546474860378,
              21.004035844588497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9894",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004040446526904,
          "to_latitude": 21.004035844588497,
          "to_longitude": 105.82546474860378,
          "from_longitude": 105.82545320269067
        }
      },
      {
        "id": 9887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496978785191,
            21.00395602713376,
            105.82537724365841,
            21.004159567651897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82537724365841,
              21.00395602713376
            ],
            [
              105.82516917154612,
              21.004059967802217
            ],
            [
              105.8251247323475,
              21.00408216689303
            ],
            [
              105.82496978785191,
              21.004159567651897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9895",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00395602713376,
          "to_latitude": 21.004159567651897,
          "to_longitude": 105.82496978785191,
          "from_longitude": 105.82537724365841
        }
      },
      {
        "id": 9888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82511955246494,
            21.004073374698315,
            105.8251247323475,
            21.00408216689303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82511955246494,
              21.004073374698315
            ],
            [
              105.82512011950179,
              21.0040743402092
            ],
            [
              105.82512217784597,
              21.004077831140126
            ],
            [
              105.8251247323475,
              21.00408216689303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9896",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004073374698315,
          "to_latitude": 21.00408216689303,
          "to_longitude": 105.8251247323475,
          "from_longitude": 105.82511955246494
        }
      },
      {
        "id": 9889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496765409147,
            21.00393503035661,
            105.82537936548404,
            21.004142880932136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82537936548404,
              21.00393503035661
            ],
            [
              105.82527544377469,
              21.00398749513693
            ],
            [
              105.82520487246705,
              21.00402312278232
            ],
            [
              105.82516079235837,
              21.004045376141317
            ],
            [
              105.8249773142269,
              21.004138004553006
            ],
            [
              105.82496765409147,
              21.004142880932136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9897",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00393503035661,
          "to_latitude": 21.004142880932136,
          "to_longitude": 105.82496765409147,
          "from_longitude": 105.82537936548404
        }
      },
      {
        "id": 9890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82516079235837,
            21.004045376141317,
            105.825164597716,
            21.00405136505813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82516079235837,
              21.004045376141317
            ],
            [
              105.82516199159889,
              21.00404726349197
            ],
            [
              105.82516417760499,
              21.004050705932414
            ],
            [
              105.825164597716,
              21.00405136505813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9898",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004045376141317,
          "to_latitude": 21.00405136505813,
          "to_longitude": 105.825164597716,
          "from_longitude": 105.82516079235837
        }
      },
      {
        "id": 9891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82405528168245,
            21.003359083608462,
            105.824459679046,
            21.004332191151757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82439385377184,
              21.003359083608462
            ],
            [
              105.82418391139029,
              21.003469062984394
            ],
            [
              105.82409323118075,
              21.003533382824713
            ],
            [
              105.82405528168245,
              21.00363109527189
            ],
            [
              105.824059106641,
              21.003703906705887
            ],
            [
              105.82405576192244,
              21.003719088994142
            ],
            [
              105.82407350572171,
              21.003812696692815
            ],
            [
              105.82415265199486,
              21.003947639551683
            ],
            [
              105.8243615192191,
              21.004184837167372
            ],
            [
              105.824459679046,
              21.004332191151757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9899",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 137.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003359083608462,
          "to_latitude": 21.004332191151757,
          "to_longitude": 105.824459679046,
          "from_longitude": 105.82439385377184
        }
      },
      {
        "id": 9892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82439702130888,
            21.002916588561835,
            105.82474834055851,
            21.003085571851027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82474834055851,
              21.002916588561835
            ],
            [
              105.82441086288695,
              21.003077071374367
            ],
            [
              105.82440159490707,
              21.00308276324216
            ],
            [
              105.82439702130888,
              21.003085571851027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9900",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002916588561835,
          "to_latitude": 21.003085571851027,
          "to_longitude": 105.82439702130888,
          "from_longitude": 105.82474834055851
        }
      },
      {
        "id": 9893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82441086288695,
            21.003077071374367,
            105.82441142396983,
            21.003239287432287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82441086288695,
              21.003077071374367
            ],
            [
              105.82441142396983,
              21.003239287432287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9901",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003077071374367,
          "to_latitude": 21.003239287432287,
          "to_longitude": 105.82441142396983,
          "from_longitude": 105.82441086288695
        }
      },
      {
        "id": 9894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82425158174316,
            21.00276944110397,
            105.82440598513516,
            21.003070561050833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82425158174316,
              21.00276944110397
            ],
            [
              105.82431445646706,
              21.00289206048965
            ],
            [
              105.82440598513516,
              21.003070561050833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26A_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9902",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00276944110397,
          "to_latitude": 21.003070561050833,
          "to_longitude": 105.82440598513516,
          "from_longitude": 105.82425158174316
        }
      },
      {
        "id": 9895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8243014603571,
            21.00289206048965,
            105.82431445646706,
            21.002897755954965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82431445646706,
              21.00289206048965
            ],
            [
              105.8243014603571,
              21.002897755954965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26A_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9903",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00289206048965,
          "to_latitude": 21.002897755954965,
          "to_longitude": 105.8243014603571,
          "from_longitude": 105.82431445646706
        }
      },
      {
        "id": 9896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82417198497667,
            21.002712982797277,
            105.82436295770154,
            21.003073421321485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82417198497667,
              21.002712982797277
            ],
            [
              105.82424931836054,
              21.002860146058143
            ],
            [
              105.82428451696516,
              21.002927128776754
            ],
            [
              105.82431054602888,
              21.002960100598425
            ],
            [
              105.82436295770154,
              21.003073421321485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9904",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002712982797277,
          "to_latitude": 21.003073421321485,
          "to_longitude": 105.82436295770154,
          "from_longitude": 105.82417198497667
        }
      },
      {
        "id": 9897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82424931836054,
            21.002849230896864,
            105.82427647896017,
            21.002860146058143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82424931836054,
              21.002860146058143
            ],
            [
              105.82424949015133,
              21.00286007658094
            ],
            [
              105.82427647896017,
              21.002849230896864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9905",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002860146058143,
          "to_latitude": 21.002849230896864,
          "to_longitude": 105.82427647896017,
          "from_longitude": 105.82424931836054
        }
      },
      {
        "id": 9898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82421773996009,
            21.002688989950514,
            105.8248802492106,
            21.00280938430712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82421773996009,
              21.00273513871263
            ],
            [
              105.82425172621264,
              21.00272866509403
            ],
            [
              105.8243307243,
              21.00271726496062
            ],
            [
              105.82443096848755,
              21.00278373391684
            ],
            [
              105.82446666247058,
              21.00280938430712
            ],
            [
              105.8248802492106,
              21.002688989950514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9906",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00273513871263,
          "to_latitude": 21.002688989950514,
          "to_longitude": 105.8248802492106,
          "from_longitude": 105.82421773996009
        }
      },
      {
        "id": 9899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82470930397537,
            21.002688989950514,
            105.82492113915177,
            21.002887948487132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8248802492106,
              21.002688989950514
            ],
            [
              105.82492113915177,
              21.002819640558457
            ],
            [
              105.82470930397537,
              21.002887948487132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9907",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002688989950514,
          "to_latitude": 21.002887948487132,
          "to_longitude": 105.82470930397537,
          "from_longitude": 105.8248802492106
        }
      },
      {
        "id": 9900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82440552088109,
            21.00280938430712,
            105.82446666247058,
            21.00287045863728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82446666247058,
              21.00280938430712
            ],
            [
              105.82440552088109,
              21.00287045863728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9908",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00280938430712,
          "to_latitude": 21.00287045863728,
          "to_longitude": 105.82440552088109,
          "from_longitude": 105.82446666247058
        }
      },
      {
        "id": 9901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82421543870275,
            21.002623934050785,
            105.8248895716419,
            21.002793994075795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82421543870275,
              21.002730668943304
            ],
            [
              105.82424903739141,
              21.002724207137458
            ],
            [
              105.82433362680726,
              21.002710716955054
            ],
            [
              105.8244666904701,
              21.002793994075795
            ],
            [
              105.8248895716419,
              21.00267448680869
            ],
            [
              105.82487540944142,
              21.002623934050785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25A_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9909",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002730668943304,
          "to_latitude": 21.002623934050785,
          "to_longitude": 105.82487540944142,
          "from_longitude": 105.82421543870275
        }
      },
      {
        "id": 9902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82401050664308,
            21.00268663887334,
            105.82419277029965,
            21.002784628228696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82419277029965,
              21.00268663887334
            ],
            [
              105.82401050664308,
              21.002784628228696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9910",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00268663887334,
          "to_latitude": 21.002784628228696,
          "to_longitude": 105.82401050664308,
          "from_longitude": 105.82419277029965
        }
      },
      {
        "id": 9903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82410259114222,
            21.002480022291977,
            105.82420353819516,
            21.002671735864116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82410259114222,
              21.002480022291977
            ],
            [
              105.82415332026729,
              21.00257636423752
            ],
            [
              105.82420353819516,
              21.002671735864116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9911",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002480022291977,
          "to_latitude": 21.002671735864116,
          "to_longitude": 105.82420353819516,
          "from_longitude": 105.82410259114222
        }
      },
      {
        "id": 9904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8241389236451,
            21.00257636423752,
            105.82415332026729,
            21.002582046600065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82415332026729,
              21.00257636423752
            ],
            [
              105.8241389236451,
              21.002582046600065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24B_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9912",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00257636423752,
          "to_latitude": 21.002582046600065,
          "to_longitude": 105.8241389236451,
          "from_longitude": 105.82415332026729
        }
      },
      {
        "id": 9905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82403482725142,
            21.002349441948123,
            105.82409409697566,
            21.002462847895853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82403482725142,
              21.002349441948123
            ],
            [
              105.82404219354973,
              21.002363535478754
            ],
            [
              105.82409409697566,
              21.002462847895853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24A_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9913",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002349441948123,
          "to_latitude": 21.002462847895853,
          "to_longitude": 105.82409409697566,
          "from_longitude": 105.82403482725142
        }
      },
      {
        "id": 9906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82402933183204,
            21.002363535478754,
            105.82404219354973,
            21.002369176078354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82402933183204,
              21.002369176078354
            ],
            [
              105.8240305005568,
              21.0023686637097
            ],
            [
              105.82404219354973,
              21.002363535478754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24A_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9914",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002369176078354,
          "to_latitude": 21.002363535478754,
          "to_longitude": 105.82404219354973,
          "from_longitude": 105.82402933183204
        }
      },
      {
        "id": 9907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82399022044076,
            21.003947639551683,
            105.82415265199486,
            21.004021537909313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82415265199486,
              21.003947639551683
            ],
            [
              105.82412088064206,
              21.00396357367753
            ],
            [
              105.82403402902685,
              21.004021537909313
            ],
            [
              105.82399022044076,
              21.00395259618457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9915",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003947639551683,
          "to_latitude": 21.00395259618457,
          "to_longitude": 105.82399022044076,
          "from_longitude": 105.82415265199486
        }
      },
      {
        "id": 9908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82482682320118,
            21.003885311090436,
            105.82489927822876,
            21.00391597544283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82482682320118,
              21.00391597544283
            ],
            [
              105.82489927822876,
              21.003885311090436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9916",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00391597544283,
          "to_latitude": 21.003885311090436,
          "to_longitude": 105.82489927822876,
          "from_longitude": 105.82482682320118
        }
      },
      {
        "id": 9909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82614305037177,
            21.0064210561967,
            105.82622811649976,
            21.00645836958526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82622811649976,
              21.0064210561967
            ],
            [
              105.82622809155129,
              21.006421067156545
            ],
            [
              105.82620994239814,
              21.006429027791437
            ],
            [
              105.82620688235733,
              21.006430370390465
            ],
            [
              105.82614305037177,
              21.00645836958526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Thủy Lợi",
          "maTaiSan": "04.O18.DODV.9917",
          "diaChiLapD": "Thủy Lợi",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0064210561967,
          "to_latitude": 21.00645836958526,
          "to_longitude": 105.82614305037177,
          "from_longitude": 105.82622811649976
        }
      },
      {
        "id": 9910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82485526567143,
            21.002870028313744,
            105.825210273143,
            21.002939744930924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82485526567143,
              21.002939744930924
            ],
            [
              105.82509010184936,
              21.00290977933585
            ],
            [
              105.82519484225081,
              21.00287513319761
            ],
            [
              105.825210273143,
              21.002870028313744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT209_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9918",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002939744930924,
          "to_latitude": 21.002870028313744,
          "to_longitude": 105.825210273143,
          "from_longitude": 105.82485526567143
        }
      },
      {
        "id": 9911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521396671056,
            21.00282139960576,
            105.825435687604,
            21.002827794223638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82521396671056,
              21.00282139960576
            ],
            [
              105.82521737222635,
              21.002821498708506
            ],
            [
              105.82522762434661,
              21.002821793134185
            ],
            [
              105.825435687604,
              21.002827794223638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT210_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9919",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00282139960576,
          "to_latitude": 21.002827794223638,
          "to_longitude": 105.825435687604,
          "from_longitude": 105.82521396671056
        }
      },
      {
        "id": 9912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521968716154,
            21.002821793134185,
            105.82522762434661,
            21.002990976319044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82522762434661,
              21.002821793134185
            ],
            [
              105.82522729289948,
              21.002828862496347
            ],
            [
              105.82521968716154,
              21.002990976319044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT210_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9920",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002821793134185,
          "to_latitude": 21.002990976319044,
          "to_longitude": 105.82521968716154,
          "from_longitude": 105.82522762434661
        }
      },
      {
        "id": 9913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521889725321,
            21.00252089241001,
            105.8252427995671,
            21.00280671631938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8252427995671,
              21.00252089241001
            ],
            [
              105.82523286550827,
              21.00263967615376
            ],
            [
              105.82523046935663,
              21.002668336341316
            ],
            [
              105.82522282042507,
              21.00272952102443
            ],
            [
              105.82521889725321,
              21.00280671631938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT212_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9921",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00252089241001,
          "to_latitude": 21.00280671631938,
          "to_longitude": 105.82521889725321,
          "from_longitude": 105.8252427995671
        }
      },
      {
        "id": 9914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82523286550827,
            21.00263967615376,
            105.82543724598762,
            21.002652749310005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82523286550827,
              21.00263967615376
            ],
            [
              105.82543724598762,
              21.002652749310005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT212_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9922",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00263967615376,
          "to_latitude": 21.002652749310005,
          "to_longitude": 105.82543724598762,
          "from_longitude": 105.82523286550827
        }
      },
      {
        "id": 9915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82522563044701,
            21.002667844961483,
            105.82523046935663,
            21.002668336341316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82523046935663,
              21.002668336341316
            ],
            [
              105.82522563044701,
              21.002667844961483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT212_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9923",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002668336341316,
          "to_latitude": 21.002667844961483,
          "to_longitude": 105.82522563044701,
          "from_longitude": 105.82523046935663
        }
      },
      {
        "id": 9916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82520025500952,
            21.002508646955263,
            105.82523058594651,
            21.002857359439115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82523058594651,
              21.002508646955263
            ],
            [
              105.82521457867973,
              21.00269267858892
            ],
            [
              105.82520025500952,
              21.002857359439115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT211_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9924",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002508646955263,
          "to_latitude": 21.002857359439115,
          "to_longitude": 105.82520025500952,
          "from_longitude": 105.82523058594651
        }
      },
      {
        "id": 9917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82521457867973,
            21.00269267858892,
            105.82522361325613,
            21.002694404210903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82521457867973,
              21.00269267858892
            ],
            [
              105.82522361325613,
              21.002694404210903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT211_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.9925",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00269267858892,
          "to_latitude": 21.002694404210903,
          "to_longitude": 105.82522361325613,
          "from_longitude": 105.82521457867973
        }
      },
      {
        "id": 9918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892124761925,
            21.001317549134235,
            105.8292002530162,
            21.001402395093063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292002530162,
              21.001317549134235
            ],
            [
              105.82918494018381,
              21.001322205796786
            ],
            [
              105.82909262434497,
              21.00135027975226
            ],
            [
              105.82908876804562,
              21.0013514519557
            ],
            [
              105.82892124761925,
              21.001402395093063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT227_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9926",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001317549134235,
          "to_latitude": 21.001402395093063,
          "to_longitude": 105.82892124761925,
          "from_longitude": 105.8292002530162
        }
      },
      {
        "id": 9919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918494018381,
            21.001322205796786,
            105.82926028832873,
            21.001522780552666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918494018381,
              21.001322205796786
            ],
            [
              105.82926028832873,
              21.001522780552666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT227_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9927",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001322205796786,
          "to_latitude": 21.001522780552666,
          "to_longitude": 105.82926028832873,
          "from_longitude": 105.82918494018381
        }
      },
      {
        "id": 9920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8291502025497,
            21.001236604570355,
            105.82918494018381,
            21.001322205796786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918494018381,
              21.001322205796786
            ],
            [
              105.8291502025497,
              21.001236604570355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT227_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9928",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001322205796786,
          "to_latitude": 21.001236604570355,
          "to_longitude": 105.8291502025497,
          "from_longitude": 105.82918494018381
        }
      },
      {
        "id": 9921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82885580315919,
            21.00120691754881,
            105.82915145055631,
            21.001312783067515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82915145055631,
              21.00120691754881
            ],
            [
              105.8291324215405,
              21.001213732052495
            ],
            [
              105.82904837820307,
              21.00124382594927
            ],
            [
              105.82904576619029,
              21.001244761768685
            ],
            [
              105.82885580315919,
              21.001312783067515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT228_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9929",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00120691754881,
          "to_latitude": 21.001312783067515,
          "to_longitude": 105.82885580315919,
          "from_longitude": 105.82915145055631
        }
      },
      {
        "id": 9922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82907099179342,
            21.001067001250842,
            105.8291324215405,
            21.001213732052495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8291324215405,
              21.001213732052495
            ],
            [
              105.82907099179342,
              21.001067001250842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT228_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9930",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001213732052495,
          "to_latitude": 21.001067001250842,
          "to_longitude": 105.82907099179342,
          "from_longitude": 105.8291324215405
        }
      },
      {
        "id": 9923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292981000981,
            21.001583126096516,
            105.82942626346866,
            21.001865653350684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82942626346866,
              21.001865653350684
            ],
            [
              105.82935874063354,
              21.001716803592174
            ],
            [
              105.8292981000981,
              21.001583126096516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT226_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9931",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001865653350684,
          "to_latitude": 21.001583126096516,
          "to_longitude": 105.8292981000981,
          "from_longitude": 105.82942626346866
        }
      },
      {
        "id": 9924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82935874063354,
            21.001706215863543,
            105.82939231508333,
            21.001716803592174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82935874063354,
              21.001716803592174
            ],
            [
              105.82937427531004,
              21.00171190467072
            ],
            [
              105.82939231508333,
              21.001706215863543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT226_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9932",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001716803592174,
          "to_latitude": 21.001706215863543,
          "to_longitude": 105.82939231508333,
          "from_longitude": 105.82935874063354
        }
      },
      {
        "id": 9925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82879562428245,
            21.005609460574927,
            105.8296432515033,
            21.0068147419064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8296432515033,
              21.0068147419064
            ],
            [
              105.82964183607702,
              21.006812041837115
            ],
            [
              105.82917350762452,
              21.005917570294457
            ],
            [
              105.8289932422808,
              21.00600284051412
            ],
            [
              105.82879562428245,
              21.005609460574927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.9933",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 183.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0068147419064,
          "to_latitude": 21.005609460574927,
          "to_longitude": 105.82879562428245,
          "from_longitude": 105.8296432515033
        }
      },
      {
        "id": 9926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8227379626097,
            21.002708212616152,
            105.8229549482823,
            21.003030233830653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8227379626097,
              21.002708212616152
            ],
            [
              105.82273811731176,
              21.002708710450207
            ],
            [
              105.82275992944955,
              21.002751900346578
            ],
            [
              105.82285158917338,
              21.0028951779893
            ],
            [
              105.8229549482823,
              21.003030233830653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT274_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9934",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002708212616152,
          "to_latitude": 21.003030233830653,
          "to_longitude": 105.8229549482823,
          "from_longitude": 105.8227379626097
        }
      },
      {
        "id": 9927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82275340252853,
            21.002704039137484,
            105.82296382028179,
            21.003024236857236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82275340252853,
              21.002704039137484
            ],
            [
              105.82275356989422,
              21.002704390587397
            ],
            [
              105.82277467136495,
              21.00274848353191
            ],
            [
              105.82284016977896,
              21.002854606905164
            ],
            [
              105.82284080563244,
              21.002855496220512
            ],
            [
              105.82291172171126,
              21.00295467284008
            ],
            [
              105.82296382028179,
              21.003024236857236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT275_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9935",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002704039137484,
          "to_latitude": 21.003024236857236,
          "to_longitude": 105.82296382028179,
          "from_longitude": 105.82275340252853
        }
      },
      {
        "id": 9928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82284080563244,
            21.002742611440084,
            105.82304542667977,
            21.002855496220512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82284080563244,
              21.002855496220512
            ],
            [
              105.82284622373861,
              21.00285250744079
            ],
            [
              105.82304542667977,
              21.002742611440084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT275_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9936",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002855496220512,
          "to_latitude": 21.002742611440084,
          "to_longitude": 105.82304542667977,
          "from_longitude": 105.82284080563244
        }
      },
      {
        "id": 9929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82332948067932,
            21.002531120728,
            105.82341622420687,
            21.00285799655058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82333414498662,
              21.002531120728
            ],
            [
              105.82333253455724,
              21.00254386944982
            ],
            [
              105.82332948067932,
              21.002568069953725
            ],
            [
              105.82335111867722,
              21.00266558254207
            ],
            [
              105.82338761176754,
              21.00278170924397
            ],
            [
              105.82341622420687,
              21.00285799655058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT243_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9937",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002531120728,
          "to_latitude": 21.00285799655058,
          "to_longitude": 105.82341622420687,
          "from_longitude": 105.82333414498662
        }
      },
      {
        "id": 9930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82338761176754,
            21.002735553631393,
            105.82367629168978,
            21.002819555985276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82338761176754,
              21.00278170924397
            ],
            [
              105.82348371826288,
              21.002735553631393
            ],
            [
              105.8235363889326,
              21.002785939175777
            ],
            [
              105.82361270984553,
              21.002739929798697
            ],
            [
              105.82367629168978,
              21.002819555985276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT243_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9938",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00278170924397,
          "to_latitude": 21.002819555985276,
          "to_longitude": 105.82367629168978,
          "from_longitude": 105.82338761176754
        }
      },
      {
        "id": 9931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82237521147822,
            21.00278850218219,
            105.8226057313986,
            21.003216978132965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82237521147822,
              21.00278850218219
            ],
            [
              105.8223770584178,
              21.00279520514603
            ],
            [
              105.82239068264148,
              21.002844623569327
            ],
            [
              105.82259765310698,
              21.00320546560038
            ],
            [
              105.8226057313986,
              21.003208382824244
            ],
            [
              105.82260268354241,
              21.003214575633002
            ],
            [
              105.8226040096475,
              21.003216978132965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT261_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9939",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00278850218219,
          "to_latitude": 21.003216978132965,
          "to_longitude": 105.8226040096475,
          "from_longitude": 105.82237521147822
        }
      },
      {
        "id": 9932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82656014692392,
            21.003151570872348,
            105.82658493037367,
            21.003246023374576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82656014692392,
              21.003246023374576
            ],
            [
              105.82657173269385,
              21.00320186794588
            ],
            [
              105.82658493037367,
              21.003151570872348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT198_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9940",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003246023374576,
          "to_latitude": 21.003151570872348,
          "to_longitude": 105.82658493037367,
          "from_longitude": 105.82656014692392
        }
      },
      {
        "id": 9933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82657173269385,
            21.00320186794588,
            105.8265756819279,
            21.003202887479002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82657173269385,
              21.00320186794588
            ],
            [
              105.82657557115797,
              21.003202859113657
            ],
            [
              105.8265756819279,
              21.003202887479002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT198_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.9941",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00320186794588,
          "to_latitude": 21.003202887479002,
          "to_longitude": 105.8265756819279,
          "from_longitude": 105.82657173269385
        }
      },
      {
        "id": 9934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82134223941128,
            21.002999822838596,
            105.82157655238335,
            21.00328372951467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82152311668203,
              21.002999822838596
            ],
            [
              105.82152646532754,
              21.003008861538298
            ],
            [
              105.82152756361917,
              21.003011821535306
            ],
            [
              105.82134223941128,
              21.003062070092493
            ],
            [
              105.82134487412245,
              21.003068606686135
            ],
            [
              105.82136073599224,
              21.00310795334342
            ],
            [
              105.82146213451436,
              21.003242284355068
            ],
            [
              105.82157655238335,
              21.00328372951467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT255_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9942",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002999822838596,
          "to_latitude": 21.00328372951467,
          "to_longitude": 105.82157655238335,
          "from_longitude": 105.82152311668203
        }
      },
      {
        "id": 9935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82118166950843,
            21.003068606686135,
            105.82134487412245,
            21.003172099311232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82134487412245,
              21.003068606686135
            ],
            [
              105.82118166950843,
              21.003172099311232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT255_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9943",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003068606686135,
          "to_latitude": 21.003172099311232,
          "to_longitude": 105.82118166950843,
          "from_longitude": 105.82134487412245
        }
      },
      {
        "id": 9936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82164451679802,
            21.002958559350223,
            105.82169787750483,
            21.00311230656709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82166167535982,
              21.002958559350223
            ],
            [
              105.8216693025392,
              21.002980984911062
            ],
            [
              105.82169787750483,
              21.003064984470615
            ],
            [
              105.82164451679802,
              21.00311230656709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT253_Trường Chinh",
          "maTaiSan": "04.O18.DODV.9944",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002958559350223,
          "to_latitude": 21.00311230656709,
          "to_longitude": 105.82164451679802,
          "from_longitude": 105.82166167535982
        }
      },
      {
        "id": 9937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82193334782708,
            21.00302804361443,
            105.8220311035491,
            21.003226000795404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82193334782708,
              21.00302804361443
            ],
            [
              105.82194319487886,
              21.003047984500448
            ],
            [
              105.82199791978096,
              21.003158803726897
            ],
            [
              105.8220311035491,
              21.003226000795404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT258_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9945",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00302804361443,
          "to_latitude": 21.003226000795404,
          "to_longitude": 105.8220311035491,
          "from_longitude": 105.82193334782708
        }
      },
      {
        "id": 9938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82199791978096,
            21.003030731676755,
            105.82224639349673,
            21.003158803726897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82199791978096,
              21.003158803726897
            ],
            [
              105.82212426264819,
              21.0031015223
            ],
            [
              105.82224521182415,
              21.00303134074138
            ],
            [
              105.82224634073937,
              21.003030758125217
            ],
            [
              105.82224639349673,
              21.003030731676755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT258_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9946",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003158803726897,
          "to_latitude": 21.003030731676755,
          "to_longitude": 105.82224639349673,
          "from_longitude": 105.82199791978096
        }
      },
      {
        "id": 9939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82179622417571,
            21.003047984500448,
            105.82194319487886,
            21.00312972364242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82179622417571,
              21.00312972364242
            ],
            [
              105.82194319487886,
              21.003047984500448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT258_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9947",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00312972364242,
          "to_latitude": 21.003047984500448,
          "to_longitude": 105.82194319487886,
          "from_longitude": 105.82179622417571
        }
      },
      {
        "id": 9940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82211910605706,
            21.00286496830492,
            105.8222274117168,
            21.003029767497836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8222274117168,
              21.003029767497836
            ],
            [
              105.82219631050074,
              21.00298244316582
            ],
            [
              105.82212903146404,
              21.00288007111241
            ],
            [
              105.82211910605706,
              21.00286496830492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT257_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9948",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003029767497836,
          "to_latitude": 21.00286496830492,
          "to_longitude": 105.82211910605706,
          "from_longitude": 105.8222274117168
        }
      },
      {
        "id": 9941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82219631050074,
            21.002974059354358,
            105.82220828779685,
            21.00298244316582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82219631050074,
              21.00298244316582
            ],
            [
              105.82220232692333,
              21.00297822944855
            ],
            [
              105.8222065467528,
              21.002975273573814
            ],
            [
              105.822208244648,
              21.002974083949937
            ],
            [
              105.82220828779685,
              21.002974059354358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT257_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9949",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00298244316582,
          "to_latitude": 21.002974059354358,
          "to_longitude": 105.82220828779685,
          "from_longitude": 105.82219631050074
        }
      },
      {
        "id": 9942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82221897225709,
            21.002973032256644,
            105.82240295685698,
            21.003276983748385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82221897225709,
              21.002973032256644
            ],
            [
              105.82228494812964,
              21.003079978189927
            ],
            [
              105.82228516746333,
              21.003080334808615
            ],
            [
              105.82232148672269,
              21.00313712322629
            ],
            [
              105.82240295685698,
              21.003276983748385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT259_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9950",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002973032256644,
          "to_latitude": 21.003276983748385,
          "to_longitude": 105.82240295685698,
          "from_longitude": 105.82221897225709
        }
      },
      {
        "id": 9943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227817655043,
            21.003079978189927,
            105.82228494812964,
            21.003083472072603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227817655043,
              21.003083472072603
            ],
            [
              105.82227830699354,
              21.00308340460357
            ],
            [
              105.82228258575083,
              21.00308119721826
            ],
            [
              105.82228494812964,
              21.003079978189927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT259_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9951",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003083472072603,
          "to_latitude": 21.003079978189927,
          "to_longitude": 105.82228494812964,
          "from_longitude": 105.82227817655043
        }
      },
      {
        "id": 9944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82225696275567,
            21.003067142126646,
            105.82238299528092,
            21.003287146670687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82225696275567,
              21.003067142126646
            ],
            [
              105.82227380601203,
              21.00309578882873
            ],
            [
              105.82231647437523,
              21.00316835487121
            ],
            [
              105.82238299528092,
              21.003287146670687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT260_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9952",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003067142126646,
          "to_latitude": 21.003287146670687,
          "to_longitude": 105.82238299528092,
          "from_longitude": 105.82225696275567
        }
      },
      {
        "id": 9945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227380601203,
            21.00309043645428,
            105.82228443119561,
            21.00309578882873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227380601203,
              21.00309578882873
            ],
            [
              105.82227907399984,
              21.003093134986152
            ],
            [
              105.82228431896793,
              21.003090492996577
            ],
            [
              105.82228437843828,
              21.003090462902765
            ],
            [
              105.82228443119561,
              21.00309043645428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT260_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9953",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00309578882873,
          "to_latitude": 21.00309043645428,
          "to_longitude": 105.82228443119561,
          "from_longitude": 105.82227380601203
        }
      },
      {
        "id": 9946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82208207498319,
            21.003304114324106,
            105.82241466803113,
            21.003725584651917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82241466803113,
              21.003304114324106
            ],
            [
              105.82241461430702,
              21.00330413987408
            ],
            [
              105.82241452220347,
              21.00330418277081
            ],
            [
              105.82218955209662,
              21.0034094054853
            ],
            [
              105.82211148669755,
              21.00344490728828
            ],
            [
              105.82208207498319,
              21.003487592285193
            ],
            [
              105.82211567792822,
              21.003535943262108
            ],
            [
              105.82223542930318,
              21.003659658914426
            ],
            [
              105.82228646426998,
              21.003725584651917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT265_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9954",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003304114324106,
          "to_latitude": 21.003725584651917,
          "to_longitude": 105.82228646426998,
          "from_longitude": 105.82241466803113
        }
      },
      {
        "id": 9947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82207041166018,
            21.003282167979098,
            105.82240114907302,
            21.00373327757134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227708978243,
              21.00373327757134
            ],
            [
              105.82222351245548,
              21.00365871931794
            ],
            [
              105.82215911196832,
              21.003590594630584
            ],
            [
              105.82210399070986,
              21.003539345267892
            ],
            [
              105.82207041166018,
              21.00348481970676
            ],
            [
              105.82211608914962,
              21.003425054688254
            ],
            [
              105.82221005783586,
              21.003389709998192
            ],
            [
              105.82239709098775,
              21.0032844501724
            ],
            [
              105.82240109824428,
              21.003282195321535
            ],
            [
              105.82240114907302,
              21.003282167979098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT264_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9955",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 76.99,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00373327757134,
          "to_latitude": 21.003282167979098,
          "to_longitude": 105.82240114907302,
          "from_longitude": 105.82227708978243
        }
      },
      {
        "id": 9948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8224632826717,
            21.003172395615536,
            105.8227206417447,
            21.003288902097434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8224632826717,
              21.003288902097434
            ],
            [
              105.82260355948159,
              21.0032253992959
            ],
            [
              105.8227206417447,
              21.003172395615536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT262_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9956",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003288902097434,
          "to_latitude": 21.003172395615536,
          "to_longitude": 105.8227206417447,
          "from_longitude": 105.8224632826717
        }
      },
      {
        "id": 9949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82260012147472,
            21.00321867150843,
            105.82260355948159,
            21.0032253992959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82260012147472,
              21.00321867150843
            ],
            [
              105.82260355948159,
              21.0032253992959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT262_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9957",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00321867150843,
          "to_latitude": 21.0032253992959,
          "to_longitude": 105.82260355948159,
          "from_longitude": 105.82260012147472
        }
      },
      {
        "id": 9950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82247295951336,
            21.003038192339368,
            105.82292315441848,
            21.003266707286272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82247295951336,
              21.003266707286272
            ],
            [
              105.82258735123786,
              21.003216457090048
            ],
            [
              105.8226057313986,
              21.003208382824244
            ],
            [
              105.82272215247207,
              21.003157240278338
            ],
            [
              105.82275768058443,
              21.003140383219282
            ],
            [
              105.82284340832557,
              21.003099708332186
            ],
            [
              105.82292315441848,
              21.003038192339368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT271_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9958",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003266707286272,
          "to_latitude": 21.003038192339368,
          "to_longitude": 105.82292315441848,
          "from_longitude": 105.82247295951336
        }
      },
      {
        "id": 9951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82275768058443,
            21.003140383219282,
            105.82276001679956,
            21.00314474445282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82276001679956,
              21.00314474445282
            ],
            [
              105.82275768058443,
              21.003140383219282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT271_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9959",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00314474445282,
          "to_latitude": 21.003140383219282,
          "to_longitude": 105.82275768058443,
          "from_longitude": 105.82276001679956
        }
      },
      {
        "id": 9952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82274794107315,
            21.00314990786759,
            105.82318755176557,
            21.003960116517273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274794107315,
              21.00314990786759
            ],
            [
              105.82274857341534,
              21.00315103384592
            ],
            [
              105.82298029378703,
              21.003562382548026
            ],
            [
              105.8230898946282,
              21.003741566906967
            ],
            [
              105.82318667339788,
              21.00395815353544
            ],
            [
              105.82318755176557,
              21.003960116517273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT270_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9960",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 99.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00314990786759,
          "to_latitude": 21.003960116517273,
          "to_longitude": 105.82318755176557,
          "from_longitude": 105.82274794107315
        }
      },
      {
        "id": 9953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82276475630735,
            21.00314271728043,
            105.8231919914013,
            21.003958303041426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8231919914013,
              21.003958303041426
            ],
            [
              105.8231918666809,
              21.003958010998847
            ],
            [
              105.82309359232542,
              21.003725485119613
            ],
            [
              105.8230831086482,
              21.003707182228116
            ],
            [
              105.82301761455435,
              21.00359335442002
            ],
            [
              105.82286149077675,
              21.003316295346195
            ],
            [
              105.82276518180367,
              21.0031434838706
            ],
            [
              105.82276475630735,
              21.00314271728043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT272_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9961",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 99.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003958303041426,
          "to_latitude": 21.00314271728043,
          "to_longitude": 105.82276475630735,
          "from_longitude": 105.8231919914013
        }
      },
      {
        "id": 9954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82279698873218,
            21.003036583590895,
            105.82296072538985,
            21.003147202257587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82279698873218,
              21.003147202257587
            ],
            [
              105.82286655623864,
              21.00310020339716
            ],
            [
              105.82296072538985,
              21.003036583590895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT273_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9962",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003147202257587,
          "to_latitude": 21.003036583590895,
          "to_longitude": 105.82296072538985,
          "from_longitude": 105.82279698873218
        }
      },
      {
        "id": 9955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82286079179448,
            21.003092478859674,
            105.82286655623864,
            21.00310020339716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82286655623864,
              21.00310020339716
            ],
            [
              105.82286079179448,
              21.003092478859674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT273_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9963",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00310020339716,
          "to_latitude": 21.003092478859674,
          "to_longitude": 105.82286079179448,
          "from_longitude": 105.82286655623864
        }
      },
      {
        "id": 9956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82380691609359,
            21.003812696692815,
            105.82407350572171,
            21.00390637428159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82407350572171,
              21.003812696692815
            ],
            [
              105.82395861669603,
              21.003862578161087
            ],
            [
              105.82395135466722,
              21.003850495614266
            ],
            [
              105.82382697988851,
              21.00390637428159
            ],
            [
              105.82380691609359,
              21.00387267622736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9964",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003812696692815,
          "to_latitude": 21.00387267622736,
          "to_longitude": 105.82380691609359,
          "from_longitude": 105.82407350572171
        }
      },
      {
        "id": 9957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82379984883221,
            21.003546468216484,
            105.82405576192244,
            21.003719088994142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82405576192244,
              21.003719088994142
            ],
            [
              105.82398459978599,
              21.003704947544694
            ],
            [
              105.82379984883221,
              21.003546468216484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9965",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003719088994142,
          "to_latitude": 21.003546468216484,
          "to_longitude": 105.82379984883221,
          "from_longitude": 105.82405576192244
        }
      },
      {
        "id": 9958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82380345608053,
            21.003540559003444,
            105.824059106641,
            21.003703906705887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82380345608053,
              21.003540559003444
            ],
            [
              105.82397820277146,
              21.003678201426645
            ],
            [
              105.82399790753067,
              21.003683818045943
            ],
            [
              105.824059106641,
              21.003703906705887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Khương Thượng",
          "maTaiSan": "04.O18.DODV.9966",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003540559003444,
          "to_latitude": 21.003703906705887,
          "to_longitude": 105.824059106641,
          "from_longitude": 105.82380345608053
        }
      },
      {
        "id": 9959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82339089771507,
            21.00307285839869,
            105.8234761118728,
            21.003107877812237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234761118728,
              21.00307285839869
            ],
            [
              105.82339089771507,
              21.003107877812237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT280_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9967",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00307285839869,
          "to_latitude": 21.003107877812237,
          "to_longitude": 105.82339089771507,
          "from_longitude": 105.8234761118728
        }
      },
      {
        "id": 9960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82368560049026,
            21.003332956223808,
            105.82378450585372,
            21.003419851189822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82368560049026,
              21.003419851189822
            ],
            [
              105.82378450585372,
              21.003332956223808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT280_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9968",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003419851189822,
          "to_latitude": 21.003332956223808,
          "to_longitude": 105.82378450585372,
          "from_longitude": 105.82368560049026
        }
      },
      {
        "id": 9961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82378450585372,
            21.003332956223808,
            105.82380617742957,
            21.003352743119525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82378450585372,
              21.003332956223808
            ],
            [
              105.82380617742957,
              21.003352743119525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT280_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9969",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003332956223808,
          "to_latitude": 21.003352743119525,
          "to_longitude": 105.82380617742957,
          "from_longitude": 105.82378450585372
        }
      },
      {
        "id": 9962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82241977842254,
            21.003328695367145,
            105.82255063896423,
            21.003566595577254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82255063896423,
              21.003566595577254
            ],
            [
              105.82247815429173,
              21.003434820771076
            ],
            [
              105.82246655703035,
              21.003413737664406
            ],
            [
              105.82241977842254,
              21.003328695367145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT268_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9970",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003566595577254,
          "to_latitude": 21.003328695367145,
          "to_longitude": 105.82241977842254,
          "from_longitude": 105.82255063896423
        }
      },
      {
        "id": 9963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82243421764231,
            21.003321282356136,
            105.82256615103212,
            21.003558194658208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82256615103212,
              21.003558194658208
            ],
            [
              105.8224961864057,
              21.00343255956654
            ],
            [
              105.82249519750196,
              21.003430784082976
            ],
            [
              105.82243421764231,
              21.003321282356136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT269_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9971",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003558194658208,
          "to_latitude": 21.003321282356136,
          "to_longitude": 105.82243421764231,
          "from_longitude": 105.82256615103212
        }
      },
      {
        "id": 9964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82248576310084,
            21.003430784082976,
            105.82249519750196,
            21.003435437037723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82248576310084,
              21.003435437037723
            ],
            [
              105.82248579283853,
              21.003435422442386
            ],
            [
              105.82248590987057,
              21.003435364973498
            ],
            [
              105.82248917905406,
              21.003433752269167
            ],
            [
              105.82249519750196,
              21.003430784082976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT269_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9972",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003435437037723,
          "to_latitude": 21.003430784082976,
          "to_longitude": 105.82249519750196,
          "from_longitude": 105.82248576310084
        }
      },
      {
        "id": 9965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82247815429173,
            21.003431928858763,
            105.82248408947599,
            21.003434820771076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82248408947599,
              21.003431928858763
            ],
            [
              105.82248403575184,
              21.003431954408747
            ],
            [
              105.82247989350087,
              21.003433973268198
            ],
            [
              105.82247815429173,
              21.003434820771076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT268_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9973",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003431928858763,
          "to_latitude": 21.003434820771076,
          "to_longitude": 105.82247815429173,
          "from_longitude": 105.82248408947599
        }
      },
      {
        "id": 9966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82258072284752,
            21.003581466354444,
            105.82275340445456,
            21.003885643642985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82258072284752,
              21.003581466354444
            ],
            [
              105.82265017807401,
              21.003703811601792
            ],
            [
              105.82266558776007,
              21.00373095499069
            ],
            [
              105.82275340445456,
              21.003885643642985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT267_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9974",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003581466354444,
          "to_latitude": 21.003885643642985,
          "to_longitude": 105.82275340445456,
          "from_longitude": 105.82258072284752
        }
      },
      {
        "id": 9967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82256633110343,
            21.003593332965405,
            105.82273388063867,
            21.003891274803756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82273388063867,
              21.003891274803756
            ],
            [
              105.8226363532151,
              21.00371784864499
            ],
            [
              105.82262300714775,
              21.003694115803558
            ],
            [
              105.82256633110343,
              21.003593332965405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT266_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9975",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003891274803756,
          "to_latitude": 21.003593332965405,
          "to_longitude": 105.82256633110343,
          "from_longitude": 105.82273388063867
        }
      },
      {
        "id": 9968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82264073745309,
            21.003703811601792,
            105.82265017807401,
            21.00370856033754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82264073745309,
              21.00370856033754
            ],
            [
              105.82264076622901,
              21.00370854574681
            ],
            [
              105.82264136094409,
              21.003708246613726
            ],
            [
              105.82264450142371,
              21.00370666704459
            ],
            [
              105.82265017807401,
              21.003703811601792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT267_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9976",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00370856033754,
          "to_latitude": 21.003703811601792,
          "to_longitude": 105.82265017807401,
          "from_longitude": 105.82264073745309
        }
      },
      {
        "id": 9969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82262300714775,
            21.003690412778,
            105.82263043936041,
            21.003694115803558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82263043936041,
              21.003690412778
            ],
            [
              105.82263038660292,
              21.003690439226578
            ],
            [
              105.82262986669335,
              21.003690698256072
            ],
            [
              105.82262452754526,
              21.003693357873015
            ],
            [
              105.82262300714775,
              21.003694115803558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT266_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9977",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003690412778,
          "to_latitude": 21.003694115803558,
          "to_longitude": 105.82262300714775,
          "from_longitude": 105.82263043936041
        }
      },
      {
        "id": 9970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8227632272513,
            21.003898848319164,
            105.82286294779358,
            21.004080483164266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82286294779358,
              21.004080483164266
            ],
            [
              105.82280261352892,
              21.003970588277006
            ],
            [
              105.82280037698095,
              21.003966513789514
            ],
            [
              105.8227632272513,
              21.003898848319164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT293_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9978",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004080483164266,
          "to_latitude": 21.003898848319164,
          "to_longitude": 105.8227632272513,
          "from_longitude": 105.82286294779358
        }
      },
      {
        "id": 9971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82274471977955,
            21.00390582037773,
            105.82284834044074,
            21.004088642188197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274471977955,
              21.00390582037773
            ],
            [
              105.82276638464407,
              21.00394404322133
            ],
            [
              105.82277553195624,
              21.003960183039297
            ],
            [
              105.82278554659977,
              21.003977852344725
            ],
            [
              105.82278313992484,
              21.00398766129069
            ],
            [
              105.8227842967356,
              21.003988130796436
            ],
            [
              105.82279348761222,
              21.003991863665593
            ],
            [
              105.82284834044074,
              21.004088642188197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT294_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9979",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00390582037773,
          "to_latitude": 21.004088642188197,
          "to_longitude": 105.82284834044074,
          "from_longitude": 105.82274471977955
        }
      },
      {
        "id": 9972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82279304348542,
            21.003970588277006,
            105.82280261352892,
            21.003975241904907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82279304348542,
              21.003975241904907
            ],
            [
              105.82279307802743,
              21.00397522638306
            ],
            [
              105.82279657469437,
              21.00397352495992
            ],
            [
              105.82280261352892,
              21.003970588277006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT293_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9980",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003975241904907,
          "to_latitude": 21.003970588277006,
          "to_longitude": 105.82280261352892,
          "from_longitude": 105.82279304348542
        }
      },
      {
        "id": 9973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82276638464407,
            21.00394012523385,
            105.82277418792799,
            21.00394404322133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82277418792799,
              21.00394012523385
            ],
            [
              105.82277413517049,
              21.003940151682496
            ],
            [
              105.8227693380935,
              21.00394256029607
            ],
            [
              105.82276638464407,
              21.00394404322133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT294_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9981",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00394012523385,
          "to_latitude": 21.00394404322133,
          "to_longitude": 105.82276638464407,
          "from_longitude": 105.82277418792799
        }
      },
      {
        "id": 9974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8224746160755,
            21.003984494055896,
            105.82279912894225,
            21.004088142584163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82279912894225,
              21.003984494055896
            ],
            [
              105.82279910014647,
              21.00398450503383
            ],
            [
              105.82279907132596,
              21.00398451149575
            ],
            [
              105.8227842967356,
              21.003988130796436
            ],
            [
              105.82277969366547,
              21.003989258598704
            ],
            [
              105.82256638820445,
              21.004088142584163
            ],
            [
              105.8224746160755,
              21.00399623373793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT292_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9982",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003984494055896,
          "to_latitude": 21.00399623373793,
          "to_longitude": 105.8224746160755,
          "from_longitude": 105.82279912894225
        }
      },
      {
        "id": 9975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82285359688932,
            21.004104157622322,
            105.82300024865685,
            21.004338766586596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82287192482467,
              21.004104157622322
            ],
            [
              105.82286828915215,
              21.004106295074887
            ],
            [
              105.82286545309832,
              21.00410796259603
            ],
            [
              105.82285359688932,
              21.004114932317307
            ],
            [
              105.82292973185302,
              21.004205185970655
            ],
            [
              105.82300024865685,
              21.004338766586596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT291_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9983",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004104157622322,
          "to_latitude": 21.004338766586596,
          "to_longitude": 105.82300024865685,
          "from_longitude": 105.82287192482467
        }
      },
      {
        "id": 9976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82290061923399,
            21.00397017469628,
            105.82317469968011,
            21.004092414223667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82290061923399,
              21.004092414223667
            ],
            [
              105.82296998681953,
              21.004058526632157
            ],
            [
              105.82302045148725,
              21.004037383736
            ],
            [
              105.82302931697079,
              21.00403352111692
            ],
            [
              105.82317469968011,
              21.00397017469628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT290_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9984",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004092414223667,
          "to_latitude": 21.00397017469628,
          "to_longitude": 105.82317469968011,
          "from_longitude": 105.82290061923399
        }
      },
      {
        "id": 9977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8230257151928,
            21.004026227807874,
            105.82302931697079,
            21.00403352111692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82302931697079,
              21.00403352111692
            ],
            [
              105.82302875476748,
              21.004032382155593
            ],
            [
              105.82302616068766,
              21.00402713249435
            ],
            [
              105.8230257151928,
              21.004026227807874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT290_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9985",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00403352111692,
          "to_latitude": 21.004026227807874,
          "to_longitude": 105.8230257151928,
          "from_longitude": 105.82302931697079
        }
      },
      {
        "id": 9978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288750698302,
            21.00399468307736,
            105.82307268220953,
            21.004077482230297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82288750698302,
              21.004077482230297
            ],
            [
              105.8230075563298,
              21.004023803737425
            ],
            [
              105.82305290284384,
              21.00400352738839
            ],
            [
              105.82307268220953,
              21.00399468307736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT289_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9986",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004077482230297,
          "to_latitude": 21.00399468307736,
          "to_longitude": 105.82307268220953,
          "from_longitude": 105.82288750698302
        }
      },
      {
        "id": 9979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82302102595051,
            21.003945162307197,
            105.82305290284384,
            21.00400352738839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82305290284384,
              21.00400352738839
            ],
            [
              105.82302102595051,
              21.003945162307197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT289_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9987",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00400352738839,
          "to_latitude": 21.003945162307197,
          "to_longitude": 105.82302102595051,
          "from_longitude": 105.82305290284384
        }
      },
      {
        "id": 9980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82326784006926,
            21.00381628178687,
            105.82351939815126,
            21.00392777921909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82326784006926,
              21.00392777921909
            ],
            [
              105.82338356424606,
              21.003876487844078
            ],
            [
              105.82351939815126,
              21.00381628178687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT286_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9988",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00392777921909,
          "to_latitude": 21.00381628178687,
          "to_longitude": 105.82351939815126,
          "from_longitude": 105.82326784006926
        }
      },
      {
        "id": 9981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8231959997756,
            21.00381327952422,
            105.82348316754108,
            21.003945553563415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8231959997756,
              21.003945553563415
            ],
            [
              105.8232010684157,
              21.00394320414082
            ],
            [
              105.82339138656903,
              21.003855004667425
            ],
            [
              105.82348316754108,
              21.00381327952422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT285_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9989",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003945553563415,
          "to_latitude": 21.00381327952422,
          "to_longitude": 105.82348316754108,
          "from_longitude": 105.8231959997756
        }
      },
      {
        "id": 9982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82337917427365,
            21.00386771615901,
            105.82338356424606,
            21.003876487844078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82337917427365,
              21.00386771615901
            ],
            [
              105.82338356424606,
              21.003876487844078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT286_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9990",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00386771615901,
          "to_latitude": 21.003876487844078,
          "to_longitude": 105.82338356424606,
          "from_longitude": 105.82337917427365
        }
      },
      {
        "id": 9983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8232010684157,
            21.00394320414082,
            105.8232056263544,
            21.003952087018494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8232056263544,
              21.003952087018494
            ],
            [
              105.82320424575151,
              21.00394939665781
            ],
            [
              105.8232027008493,
              21.003946388252356
            ],
            [
              105.82320115786092,
              21.003943378031163
            ],
            [
              105.8232010684157,
              21.00394320414082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT285_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9991",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003952087018494,
          "to_latitude": 21.00394320414082,
          "to_longitude": 105.8232010684157,
          "from_longitude": 105.8232056263544
        }
      },
      {
        "id": 9984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82315841778447,
            21.00319671782275,
            105.8235174651572,
            21.003807229959133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8235174651572,
              21.003807229959133
            ],
            [
              105.82344544980052,
              21.003678947913144
            ],
            [
              105.82335277939727,
              21.00352801705351
            ],
            [
              105.82328194667778,
              21.003405037475364
            ],
            [
              105.82315841778447,
              21.003206318740894
            ],
            [
              105.82316930837716,
              21.003198153280195
            ],
            [
              105.82317122326056,
              21.00319671782275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT278_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9992",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003807229959133,
          "to_latitude": 21.00319671782275,
          "to_longitude": 105.82317122326056,
          "from_longitude": 105.8235174651572
        }
      },
      {
        "id": 9985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8229850833328,
            21.003043573320554,
            105.8231550735839,
            21.00319617992344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8229850833328,
              21.003043573320554
            ],
            [
              105.82307736377253,
              21.003106151643156
            ],
            [
              105.8230811022199,
              21.00311048260747
            ],
            [
              105.82310319411216,
              21.003136077125664
            ],
            [
              105.8231550735839,
              21.00319617992344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT276_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9993",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003043573320554,
          "to_latitude": 21.00319617992344,
          "to_longitude": 105.8231550735839,
          "from_longitude": 105.8229850833328
        }
      },
      {
        "id": 9986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8230811022199,
            21.00310547675759,
            105.82308972764702,
            21.00311048260747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8230811022199,
              21.00311048260747
            ],
            [
              105.82308330076366,
              21.003109206553887
            ],
            [
              105.82308911304877,
              21.003105832891674
            ],
            [
              105.82308972764702,
              21.00310547675759
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT276_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9994",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00311048260747,
          "to_latitude": 21.00310547675759,
          "to_longitude": 105.82308972764702,
          "from_longitude": 105.8230811022199
        }
      },
      {
        "id": 9987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82301237930042,
            21.003041183271005,
            105.82317783127682,
            21.003184800290203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82301237930042,
              21.003041183271005
            ],
            [
              105.8230884549917,
              21.003091072814783
            ],
            [
              105.82311068653965,
              21.00310565179333
            ],
            [
              105.8231389466632,
              21.00313875662414
            ],
            [
              105.82317783127682,
              21.003184800290203
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT277_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9995",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003041183271005,
          "to_latitude": 21.003184800290203,
          "to_longitude": 105.82317783127682,
          "from_longitude": 105.82301237930042
        }
      },
      {
        "id": 9988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82307942620625,
            21.003091072814783,
            105.8230884549917,
            21.003098647588605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82307942620625,
              21.003098647588605
            ],
            [
              105.82307956982244,
              21.00309852676483
            ],
            [
              105.82308188973411,
              21.003096580832544
            ],
            [
              105.8230884549917,
              21.003091072814783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT277_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9996",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003098647588605,
          "to_latitude": 21.003091072814783,
          "to_longitude": 105.8230884549917,
          "from_longitude": 105.82307942620625
        }
      },
      {
        "id": 9989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82350252375872,
            21.003551209608958,
            105.82376263832619,
            21.003680867532804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82350252375872,
              21.003680867532804
            ],
            [
              105.8236163073284,
              21.00362269286419
            ],
            [
              105.82361744600446,
              21.00362213638537
            ],
            [
              105.82376263832619,
              21.003551209608958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT282_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9997",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003680867532804,
          "to_latitude": 21.003551209608958,
          "to_longitude": 105.82376263832619,
          "from_longitude": 105.82350252375872
        }
      },
      {
        "id": 9990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82349947155627,
            21.00354220657477,
            105.82375305013699,
            21.003670134793484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82349947155627,
              21.003670134793484
            ],
            [
              105.82362866323302,
              21.003604958715464
            ],
            [
              105.82366329248603,
              21.003587488913087
            ],
            [
              105.82375305013699,
              21.00354220657477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT281_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9998",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003670134793484,
          "to_latitude": 21.00354220657477,
          "to_longitude": 105.82375305013699,
          "from_longitude": 105.82349947155627
        }
      },
      {
        "id": 9991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82361367972848,
            21.003615719112574,
            105.82361744600446,
            21.00362213638537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82361367972848,
              21.003615719112574
            ],
            [
              105.82361383508929,
              21.00361598571622
            ],
            [
              105.8236148798087,
              21.003617763632423
            ],
            [
              105.82361744600446,
              21.00362213638537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT282_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.9999",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003615719112574,
          "to_latitude": 21.00362213638537,
          "to_longitude": 105.82361744600446,
          "from_longitude": 105.82361367972848
        }
      },
      {
        "id": 9992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82366329248603,
            21.003587488913087,
            105.82366594781065,
            21.00359215297654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82366594781065,
              21.00359215297654
            ],
            [
              105.82366580699131,
              21.003591907076817
            ],
            [
              105.82366329248603,
              21.003587488913087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT281_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10000",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00359215297654,
          "to_latitude": 21.003587488913087,
          "to_longitude": 105.82366329248603,
          "from_longitude": 105.82366594781065
        }
      },
      {
        "id": 9993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82316408882541,
            21.003195860721785,
            105.82347823814622,
            21.0036846295541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82347823814622,
              21.0036846295541
            ],
            [
              105.82347697887612,
              21.00368389319337
            ],
            [
              105.8234656810734,
              21.003677272999752
            ],
            [
              105.82344112008879,
              21.00363960890529
            ],
            [
              105.8233485167833,
              21.003488104167616
            ],
            [
              105.82326292926021,
              21.00333498207988
            ],
            [
              105.82318615142033,
              21.003195860721785
            ],
            [
              105.82317550973163,
              21.0032027586544
            ],
            [
              105.82316408882541,
              21.003210162549436
            ],
            [
              105.82316624095941,
              21.003212876276475
            ],
            [
              105.82316817106724,
              21.003211761391317
            ],
            [
              105.82317790596996,
              21.003206135976413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT279_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10001",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0036846295541,
          "to_latitude": 21.003206135976413,
          "to_longitude": 105.82317790596996,
          "from_longitude": 105.82347823814622
        }
      },
      {
        "id": 9994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82320780759972,
            21.003994127139002,
            105.82335226129119,
            21.004284477284656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82335226129119,
              21.004284477284656
            ],
            [
              105.82332132710424,
              21.004222301504807
            ],
            [
              105.82325532335103,
              21.004089634941696
            ],
            [
              105.82320780759972,
              21.003994127139002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT288_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10002",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004284477284656,
          "to_latitude": 21.003994127139002,
          "to_longitude": 105.82320780759972,
          "from_longitude": 105.82335226129119
        }
      },
      {
        "id": 9995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82332132710424,
            21.004216711481448,
            105.82333401326254,
            21.004222301504807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82333401326254,
              21.004216711481448
            ],
            [
              105.82333225153594,
              21.004217487747034
            ],
            [
              105.82332798635416,
              21.004219367221612
            ],
            [
              105.82332132710424,
              21.004222301504807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT288_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10003",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004216711481448,
          "to_latitude": 21.004222301504807,
          "to_longitude": 105.82332132710424,
          "from_longitude": 105.82333401326254
        }
      },
      {
        "id": 9996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82331441283685,
            21.00417642189868,
            105.8233196787936,
            21.004178747892233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82331441283685,
              21.004178747892233
            ],
            [
              105.82331744018751,
              21.00417741092295
            ],
            [
              105.8233196787936,
              21.00417642189868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT287_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10004",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004178747892233,
          "to_latitude": 21.00417642189868,
          "to_longitude": 105.8233196787936,
          "from_longitude": 105.82331441283685
        }
      },
      {
        "id": 9997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82336004512152,
            21.004298578756533,
            105.82351314950803,
            21.00459138521805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82351314950803,
              21.00459138521805
            ],
            [
              105.82342836577897,
              21.004429239735003
            ],
            [
              105.82342802652848,
              21.004428591052367
            ],
            [
              105.82336004512152,
              21.004298578756533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT296_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10005",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00459138521805,
          "to_latitude": 21.004298578756533,
          "to_longitude": 105.82336004512152,
          "from_longitude": 105.82351314950803
        }
      },
      {
        "id": 9998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82343209892112,
            21.00434321184724,
            105.82356930448161,
            21.004413836997823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82343209892112,
              21.004413836997823
            ],
            [
              105.82343310891527,
              21.00441331727177
            ],
            [
              105.8234380648833,
              21.00441076606402
            ],
            [
              105.82356930448161,
              21.00434321184724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT295_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10006",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004413836997823,
          "to_latitude": 21.00434321184724,
          "to_longitude": 105.82356930448161,
          "from_longitude": 105.82343209892112
        }
      },
      {
        "id": 9999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82338850949948,
            21.004313459024065,
            105.8234380648833,
            21.00441076606402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8234380648833,
              21.00441076606402
            ],
            [
              105.82338850949948,
              21.004313459024065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT295_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10007",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00441076606402,
          "to_latitude": 21.004313459024065,
          "to_longitude": 105.82338850949948,
          "from_longitude": 105.8234380648833
        }
      },
      {
        "id": 10000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82342802652848,
            21.004424394051014,
            105.82343748654645,
            21.004428591052367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82343748654645,
              21.004424394051014
            ],
            [
              105.82342836332704,
              21.00442844219685
            ],
            [
              105.82342802652848,
              21.004428591052367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT296_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10008",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004424394051014,
          "to_latitude": 21.004428591052367,
          "to_longitude": 105.82342802652848,
          "from_longitude": 105.82343748654645
        }
      },
      {
        "id": 10001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82346544274293,
            21.0044467750318,
            105.82359506214743,
            21.004489555648483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346544274293,
              21.004485667437724
            ],
            [
              105.82346668204548,
              21.00448591886166
            ],
            [
              105.82347710473273,
              21.004488879795613
            ],
            [
              105.8234794823293,
              21.004489555648483
            ],
            [
              105.82359506214743,
              21.0044467750318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT297_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10009",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004485667437724,
          "to_latitude": 21.0044467750318,
          "to_longitude": 105.82359506214743,
          "from_longitude": 105.82346544274293
        }
      },
      {
        "id": 10002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82344558305138,
            21.004432544445134,
            105.82358795677764,
            21.004653420018922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82344558305138,
              21.004432544445134
            ],
            [
              105.82347154260842,
              21.004485788764537
            ],
            [
              105.82347739119065,
              21.004487795439736
            ],
            [
              105.82347710473273,
              21.004488879795613
            ],
            [
              105.8234757525195,
              21.0044939949934
            ],
            [
              105.82348767635567,
              21.004517406769523
            ],
            [
              105.82351545909277,
              21.004567219930355
            ],
            [
              105.82352584557043,
              21.00458584300312
            ],
            [
              105.82357091902237,
              21.004637435849652
            ],
            [
              105.82358795677764,
              21.004653420018922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT298_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10010",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004432544445134,
          "to_latitude": 21.004653420018922,
          "to_longitude": 105.82358795677764,
          "from_longitude": 105.82344558305138
        }
      },
      {
        "id": 10003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82350969071632,
            21.004567219930355,
            105.82351545909277,
            21.00456983920036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82350969071632,
              21.00456983920036
            ],
            [
              105.82351054081212,
              21.004569453021954
            ],
            [
              105.8235121354637,
              21.004568729046824
            ],
            [
              105.82351545909277,
              21.004567219930355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT298_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10011",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00456983920036,
          "to_latitude": 21.004567219930355,
          "to_longitude": 105.82351545909277,
          "from_longitude": 105.82350969071632
        }
      },
      {
        "id": 10004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82353182300628,
            21.0046109807119,
            105.82363997286562,
            21.004722858640566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82353182300628,
              21.0046109807119
            ],
            [
              105.82363997286562,
              21.004722858640566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10012",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0046109807119,
          "to_latitude": 21.004722858640566,
          "to_longitude": 105.82363997286562,
          "from_longitude": 105.82353182300628
        }
      },
      {
        "id": 10005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82363997286562,
            21.004722858640566,
            105.82377565703428,
            21.00484799538428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82363997286562,
              21.004722858640566
            ],
            [
              105.82366530976003,
              21.004746225364006
            ],
            [
              105.82377565703428,
              21.00484799538428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10013",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004722858640566,
          "to_latitude": 21.00484799538428,
          "to_longitude": 105.82377565703428,
          "from_longitude": 105.82363997286562
        }
      },
      {
        "id": 10006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82366530976003,
            21.004742933688537,
            105.82366873422322,
            21.004746225364006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82366530976003,
              21.004746225364006
            ],
            [
              105.82366873422322,
              21.004742933688537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10014",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004746225364006,
          "to_latitude": 21.004742933688537,
          "to_longitude": 105.82366873422322,
          "from_longitude": 105.82366530976003
        }
      },
      {
        "id": 10007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82380695505096,
            21.004656787648372,
            105.82401036333563,
            21.00482990037931
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82401036333563,
              21.004656787648372
            ],
            [
              105.82399823658355,
              21.004685782155203
            ],
            [
              105.82391769686298,
              21.00474017060976
            ],
            [
              105.82380695505096,
              21.00482990037931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT304_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10015",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004656787648372,
          "to_latitude": 21.00482990037931,
          "to_longitude": 105.82380695505096,
          "from_longitude": 105.82401036333563
        }
      },
      {
        "id": 10008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82403998527914,
            21.00443525178279,
            105.8243226857529,
            21.004650877126753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82403998527914,
              21.004650877126753
            ],
            [
              105.82419571164826,
              21.00453438713798
            ],
            [
              105.8243226857529,
              21.00443525178279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT305_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10016",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004650877126753,
          "to_latitude": 21.00443525178279,
          "to_longitude": 105.8243226857529,
          "from_longitude": 105.82403998527914
        }
      },
      {
        "id": 10009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8243226857529,
            21.00435266661118,
            105.82443987320028,
            21.00443525178279
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8243226857529,
              21.00443525178279
            ],
            [
              105.82443987320028,
              21.00435266661118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT305_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10017",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00443525178279,
          "to_latitude": 21.00435266661118,
          "to_longitude": 105.82443987320028,
          "from_longitude": 105.8243226857529
        }
      },
      {
        "id": 10010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82419191727107,
            21.004529867697048,
            105.82419571164826,
            21.00453438713798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82419571164826,
              21.00453438713798
            ],
            [
              105.8241955635143,
              21.004534210822726
            ],
            [
              105.82419191727107,
              21.004529867697048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT305_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10018",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00453438713798,
          "to_latitude": 21.004529867697048,
          "to_longitude": 105.82419191727107,
          "from_longitude": 105.82419571164826
        }
      },
      {
        "id": 10011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82404082833749,
            21.004466826317728,
            105.82426665055783,
            21.0046311655238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82404082833749,
              21.0046311655238
            ],
            [
              105.82411775339497,
              21.00457872922875
            ],
            [
              105.82419830344155,
              21.004518193266584
            ],
            [
              105.82426665055783,
              21.004466826317728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT306_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10019",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0046311655238,
          "to_latitude": 21.004466826317728,
          "to_longitude": 105.82426665055783,
          "from_longitude": 105.82404082833749
        }
      },
      {
        "id": 10012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82419830344155,
            21.004518193266584,
            105.82420190393745,
            21.004522438161864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82420190393745,
              21.004522438161864
            ],
            [
              105.82419830344155,
              21.004518193266584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT306_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10020",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004522438161864,
          "to_latitude": 21.004518193266584,
          "to_longitude": 105.82419830344155,
          "from_longitude": 105.82420190393745
        }
      },
      {
        "id": 10013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82414230259545,
            21.00422655033431,
            105.82429891874322,
            21.004450268336317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82429891874322,
              21.004450268336317
            ],
            [
              105.82414230259545,
              21.00422655033431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT307_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10021",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004450268336317,
          "to_latitude": 21.00422655033431,
          "to_longitude": 105.82414230259545,
          "from_longitude": 105.82429891874322
        }
      },
      {
        "id": 10014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82446528878977,
            21.004165533323025,
            105.8249396286671,
            21.004343773161022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82446528878977,
              21.004343773161022
            ],
            [
              105.82458881338934,
              21.004294198156845
            ],
            [
              105.82470124219863,
              21.004256769237667
            ],
            [
              105.82472537615763,
              21.004247804281864
            ],
            [
              105.82483881782026,
              21.00420566455083
            ],
            [
              105.8249396286671,
              21.004165533323025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT308_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10022",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004343773161022,
          "to_latitude": 21.004165533323025,
          "to_longitude": 105.8249396286671,
          "from_longitude": 105.82446528878977
        }
      },
      {
        "id": 10015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82469456019973,
            21.00424120468272,
            105.82470124219863,
            21.004256769237667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82470124219863,
              21.004256769237667
            ],
            [
              105.82470095477329,
              21.00425610043558
            ],
            [
              105.82469993368751,
              21.004253725378803
            ],
            [
              105.82469642020907,
              21.004245537484692
            ],
            [
              105.82469456019973,
              21.00424120468272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT308_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10023",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004256769237667,
          "to_latitude": 21.00424120468272,
          "to_longitude": 105.82469456019973,
          "from_longitude": 105.82470124219863
        }
      },
      {
        "id": 10016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82353785454475,
            21.003770656347175,
            105.82365039588103,
            21.003832264520934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82353785454475,
              21.003832264520934
            ],
            [
              105.82353791881934,
              21.0038322335002
            ],
            [
              105.82354461922488,
              21.003828564685765
            ],
            [
              105.82365039588103,
              21.003770656347175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT284_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10024",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003832264520934,
          "to_latitude": 21.003770656347175,
          "to_longitude": 105.82365039588103,
          "from_longitude": 105.82353785454475
        }
      },
      {
        "id": 10017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82353557777327,
            21.003833659281803,
            105.82367471004544,
            21.004080214804336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82367471004544,
              21.004080214804336
            ],
            [
              105.82357652520852,
              21.003906222467002
            ],
            [
              105.82357628723874,
              21.00390580090791
            ],
            [
              105.82353557777327,
              21.003833659281803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT287_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10025",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004080214804336,
          "to_latitude": 21.003833659281803,
          "to_longitude": 105.82353557777327,
          "from_longitude": 105.82367471004544
        }
      },
      {
        "id": 10018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82357628723874,
            21.003902948921592,
            105.82358198986705,
            21.00390580090791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82358198986705,
              21.003902948921592
            ],
            [
              105.82358147763831,
              21.003903205207106
            ],
            [
              105.82357805414165,
              21.00390491713077
            ],
            [
              105.82357628723874,
              21.00390580090791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT287_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10026",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003902948921592,
          "to_latitude": 21.00390580090791,
          "to_longitude": 105.82357628723874,
          "from_longitude": 105.82358198986705
        }
      },
      {
        "id": 10019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82365787399067,
            21.00402560765093,
            105.82382161937133,
            21.00432307176938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82382161937133,
              21.00432307176938
            ],
            [
              105.82376491078233,
              21.004220054939474
            ],
            [
              105.8237511108469,
              21.00419498490426
            ],
            [
              105.82365787399067,
              21.00402560765093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT299_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10027",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00432307176938,
          "to_latitude": 21.00402560765093,
          "to_longitude": 105.82365787399067,
          "from_longitude": 105.82382161937133
        }
      },
      {
        "id": 10020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82367825675166,
            21.0040970970298,
            105.82383053849684,
            21.00437261488984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82383053849684,
              21.00437261488984
            ],
            [
              105.82376889300889,
              21.00426108103812
            ],
            [
              105.82376066138208,
              21.004246188719343
            ],
            [
              105.82367825675166,
              21.0040970970298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT300_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10028",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00437261488984,
          "to_latitude": 21.0040970970298,
          "to_longitude": 105.82367825675166,
          "from_longitude": 105.82383053849684
        }
      },
      {
        "id": 10021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82376889300889,
            21.0042564294975,
            105.82377839379541,
            21.00426108103812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82377839379541,
              21.0042564294975
            ],
            [
              105.82377823263263,
              21.004256507955088
            ],
            [
              105.82377354747355,
              21.004258802248376
            ],
            [
              105.82376889300889,
              21.00426108103812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT300_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10029",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0042564294975,
          "to_latitude": 21.00426108103812,
          "to_longitude": 105.82376889300889,
          "from_longitude": 105.82377839379541
        }
      },
      {
        "id": 10022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82368629141331,
            21.004376335293536,
            105.8238458381668,
            21.00445905009857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8238458381668,
              21.004376335293536
            ],
            [
              105.82384518307879,
              21.004376675368288
            ],
            [
              105.82368629141331,
              21.00445905009857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT301_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10030",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004376335293536,
          "to_latitude": 21.00445905009857,
          "to_longitude": 105.82368629141331,
          "from_longitude": 105.8238458381668
        }
      },
      {
        "id": 10023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8238423205899,
            21.00439507893727,
            105.82399489945931,
            21.004651721343222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8238423205899,
              21.00439507893727
            ],
            [
              105.82391397736413,
              21.004513982503205
            ],
            [
              105.8239289939193,
              21.004539541601872
            ],
            [
              105.82399489945931,
              21.004651721343222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT303_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10031",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00439507893727,
          "to_latitude": 21.004651721343222,
          "to_longitude": 105.82399489945931,
          "from_longitude": 105.8238423205899
        }
      },
      {
        "id": 10024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239289939193,
            21.00453663202184,
            105.82393465007833,
            21.004539541601872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82393465007833,
              21.00453663202184
            ],
            [
              105.82393160954341,
              21.00453819577605
            ],
            [
              105.8239289939193,
              21.004539541601872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT303_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10032",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00453663202184,
          "to_latitude": 21.004539541601872,
          "to_longitude": 105.8239289939193,
          "from_longitude": 105.82393465007833
        }
      },
      {
        "id": 10025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82390137379818,
            21.004472639837015,
            105.82391213927612,
            21.0044781989284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82390137379818,
              21.0044781989284
            ],
            [
              105.82390385031646,
              21.004476919711987
            ],
            [
              105.82390725816973,
              21.004475159986274
            ],
            [
              105.82391213927612,
              21.004472639837015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT302_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10033",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0044781989284,
          "to_latitude": 21.004472639837015,
          "to_longitude": 105.82391213927612,
          "from_longitude": 105.82390137379818
        }
      },
      {
        "id": 10026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82560344752115,
            21.002468384806182,
            105.82570188823729,
            21.002690926852473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82561388459285,
              21.002468384806182
            ],
            [
              105.82561367140266,
              21.00247089500617
            ],
            [
              105.82560344752115,
              21.002592017722296
            ],
            [
              105.82561706977492,
              21.00266834391774
            ],
            [
              105.8256277995911,
              21.002671200265294
            ],
            [
              105.82570188823729,
              21.002690926852473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT214_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10034",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002468384806182,
          "to_latitude": 21.002690926852473,
          "to_longitude": 105.82570188823729,
          "from_longitude": 105.82561388459285
        }
      },
      {
        "id": 10027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82560822320798,
            21.002671200265294,
            105.8256277995911,
            21.00277878922204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8256277995911,
              21.002671200265294
            ],
            [
              105.82560822320798,
              21.00277878922204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT214_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10035",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002671200265294,
          "to_latitude": 21.00277878922204,
          "to_longitude": 105.82560822320798,
          "from_longitude": 105.8256277995911
        }
      },
      {
        "id": 10028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82588781245724,
            21.002537477275368,
            105.82613957556937,
            21.003088172891534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613957556937,
              21.002537477275368
            ],
            [
              105.82613553109252,
              21.002545363112436
            ],
            [
              105.82605861272822,
              21.00269534609776
            ],
            [
              105.82605292265238,
              21.00270710302266
            ],
            [
              105.82599066819948,
              21.002815067128044
            ],
            [
              105.82594619694646,
              21.002960698921797
            ],
            [
              105.82588781245724,
              21.003088172891534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT215_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10036",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002537477275368,
          "to_latitude": 21.003088172891534,
          "to_longitude": 105.82588781245724,
          "from_longitude": 105.82613957556937
        }
      },
      {
        "id": 10029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605861272822,
            21.00269534609776,
            105.82621043523314,
            21.002769331418932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605861272822,
              21.00269534609776
            ],
            [
              105.82614136984412,
              21.00273589858773
            ],
            [
              105.82621043523314,
              21.002769331418932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT215_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10037",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00269534609776,
          "to_latitude": 21.002769331418932,
          "to_longitude": 105.82621043523314,
          "from_longitude": 105.82605861272822
        }
      },
      {
        "id": 10030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82676055489465,
            21.001917821709508,
            105.82689702401204,
            21.002105846616693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82676055489465,
              21.001917821709508
            ],
            [
              105.82676112107913,
              21.00191845754125
            ],
            [
              105.82677934486674,
              21.001938119898774
            ],
            [
              105.8267811819551,
              21.002004817926977
            ],
            [
              105.82678264126247,
              21.00210473487739
            ],
            [
              105.82689702401204,
              21.002105846616693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT218_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10038",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001917821709508,
          "to_latitude": 21.002105846616693,
          "to_longitude": 105.82689702401204,
          "from_longitude": 105.82676055489465
        }
      },
      {
        "id": 10031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82706278590936,
            21.00195728517587,
            105.82714317787429,
            21.002282383983644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82713860054083,
              21.00195728517587
            ],
            [
              105.82713865552658,
              21.001958361555594
            ],
            [
              105.82714317787429,
              21.002045760237564
            ],
            [
              105.82713421604912,
              21.002216246381202
            ],
            [
              105.8271214541047,
              21.002282383983644
            ],
            [
              105.82706278590936,
              21.00227592375433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT219_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10039",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00195728517587,
          "to_latitude": 21.00227592375433,
          "to_longitude": 105.82706278590936,
          "from_longitude": 105.82713860054083
        }
      },
      {
        "id": 10032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82706004703878,
            21.00227592375433,
            105.82706278590936,
            21.002427880658658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82706278590936,
              21.00227592375433
            ],
            [
              105.82706004703878,
              21.002427880658658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT219_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10040",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00227592375433,
          "to_latitude": 21.002427880658658,
          "to_longitude": 105.82706004703878,
          "from_longitude": 105.82706278590936
        }
      },
      {
        "id": 10033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82751505384606,
            21.00190538326701,
            105.82758304602991,
            21.002373960390507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82751505384606,
              21.00190538326701
            ],
            [
              105.82751517994981,
              21.001907495302405
            ],
            [
              105.82752335279969,
              21.00204375616803
            ],
            [
              105.8275349019099,
              21.00210764037725
            ],
            [
              105.82758304602991,
              21.002373960390507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT220_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10041",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00190538326701,
          "to_latitude": 21.002373960390507,
          "to_longitude": 105.82758304602991,
          "from_longitude": 105.82751505384606
        }
      },
      {
        "id": 10034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275349019099,
            21.002063806903276,
            105.82784155128032,
            21.00210764037725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8275349019099,
              21.00210764037725
            ],
            [
              105.82764738255023,
              21.002091562510753
            ],
            [
              105.82784155128032,
              21.002063806903276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT220_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10042",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00210764037725,
          "to_latitude": 21.002063806903276,
          "to_longitude": 105.82784155128032,
          "from_longitude": 105.8275349019099
        }
      },
      {
        "id": 10035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82764738255023,
            21.002091562510753,
            105.82766434318306,
            21.0022475649215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82764738255023,
              21.002091562510753
            ],
            [
              105.82766434318306,
              21.0022475649215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT220_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10043",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002091562510753,
          "to_latitude": 21.0022475649215,
          "to_longitude": 105.82766434318306,
          "from_longitude": 105.82764738255023
        }
      },
      {
        "id": 10036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82752212358945,
            21.001872058568026,
            105.82800041664186,
            21.00191603680384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82752212358945,
              21.001914656653955
            ],
            [
              105.82760182046843,
              21.00191603680384
            ],
            [
              105.8276783813841,
              21.001914042353153
            ],
            [
              105.82771565389814,
              21.001909483172085
            ],
            [
              105.82778204120272,
              21.00190136278112
            ],
            [
              105.82778495557041,
              21.00190100718855
            ],
            [
              105.82800041664186,
              21.001872058568026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT221_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10044",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001914656653955,
          "to_latitude": 21.001872058568026,
          "to_longitude": 105.82800041664186,
          "from_longitude": 105.82752212358945
        }
      },
      {
        "id": 10037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82810002841315,
            21.001827518860804,
            105.82822227521335,
            21.00196319464468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82820218431364,
              21.001827518860804
            ],
            [
              105.82820260138102,
              21.001829717092797
            ],
            [
              105.82820407841749,
              21.001837495723898
            ],
            [
              105.82822227521335,
              21.00193329377461
            ],
            [
              105.82820748204895,
              21.001943769121613
            ],
            [
              105.82810002841315,
              21.00196319464468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT222_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10045",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001827518860804,
          "to_latitude": 21.00196319464468,
          "to_longitude": 105.82810002841315,
          "from_longitude": 105.82820218431364
        }
      },
      {
        "id": 10038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82820407841749,
            21.001813787664787,
            105.82832012648473,
            21.001837495723898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82820407841749,
              21.001837495723898
            ],
            [
              105.82832012648473,
              21.001813787664787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT222_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10046",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001837495723898,
          "to_latitude": 21.001813787664787,
          "to_longitude": 105.82832012648473,
          "from_longitude": 105.82820407841749
        }
      },
      {
        "id": 10039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832012648473,
            21.001813787664787,
            105.82836660009859,
            21.002037740660505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832012648473,
              21.001813787664787
            ],
            [
              105.82836660009859,
              21.002037740660505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT222_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10047",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001813787664787,
          "to_latitude": 21.002037740660505,
          "to_longitude": 105.82836660009859,
          "from_longitude": 105.82832012648473
        }
      },
      {
        "id": 10040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82771464853484,
            21.001895312813474,
            105.82771565389814,
            21.001909483172085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82771464853484,
              21.001895312813474
            ],
            [
              105.82771477378034,
              21.001897094271662
            ],
            [
              105.8277150489988,
              21.001900955132797
            ],
            [
              105.82771565389814,
              21.001909483172085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT221_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10048",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001895312813474,
          "to_latitude": 21.001909483172085,
          "to_longitude": 105.82771565389814,
          "from_longitude": 105.82771464853484
        }
      },
      {
        "id": 10041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82832263706483,
            21.00170695262156,
            105.8288243765587,
            21.001809458022368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82832263706483,
              21.001809458022368
            ],
            [
              105.82832416590523,
              21.001809193163012
            ],
            [
              105.82846494679099,
              21.00178476581013
            ],
            [
              105.82857659208044,
              21.001762221588855
            ],
            [
              105.82865159423352,
              21.001747076079013
            ],
            [
              105.8288243765587,
              21.00170695262156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT224_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10049",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001809458022368,
          "to_latitude": 21.00170695262156,
          "to_longitude": 105.8288243765587,
          "from_longitude": 105.82832263706483
        }
      },
      {
        "id": 10042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883534809092,
            21.001692967826116,
            105.82888476709418,
            21.00187223723065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883534809092,
              21.001692967826116
            ],
            [
              105.82883773183427,
              21.001702251328364
            ],
            [
              105.82885483869396,
              21.001768861409328
            ],
            [
              105.82888476709418,
              21.00187223723065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT225_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10050",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001692967826116,
          "to_latitude": 21.00187223723065,
          "to_longitude": 105.82888476709418,
          "from_longitude": 105.82883534809092
        }
      },
      {
        "id": 10043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883773183427,
            21.001574827208366,
            105.82926955663163,
            21.001702251328364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883773183427,
              21.001702251328364
            ],
            [
              105.82901479702267,
              21.00166715315528
            ],
            [
              105.82919036756014,
              21.00160352643177
            ],
            [
              105.82919689985484,
              21.001601158866293
            ],
            [
              105.82926955663163,
              21.001574827208366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT225_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10051",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001702251328364,
          "to_latitude": 21.001574827208366,
          "to_longitude": 105.82926955663163,
          "from_longitude": 105.82883773183427
        }
      },
      {
        "id": 10044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8283222605276,
            21.001801357907713,
            105.82832416590523,
            21.001809193163012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8283222605276,
              21.001801357907713
            ],
            [
              105.82832263257988,
              21.001802893389748
            ],
            [
              105.82832338544057,
              21.00180598237572
            ],
            [
              105.82832416590523,
              21.001809193163012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT224_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10052",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001801357907713,
          "to_latitude": 21.001809193163012,
          "to_longitude": 105.82832416590523,
          "from_longitude": 105.8283222605276
        }
      },
      {
        "id": 10045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82653617225694,
            21.00216685182354,
            105.82657790901018,
            21.002466590176283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82657790901018,
              21.002466590176283
            ],
            [
              105.82656596479427,
              21.00239045101788
            ],
            [
              105.82655175671546,
              21.002287471708485
            ],
            [
              105.82655106846518,
              21.002283009494587
            ],
            [
              105.82654267888576,
              21.002228631689036
            ],
            [
              105.82653617225694,
              21.00216685182354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT216_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10053",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002466590176283,
          "to_latitude": 21.00216685182354,
          "to_longitude": 105.82653617225694,
          "from_longitude": 105.82657790901018
        }
      },
      {
        "id": 10046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82653156650967,
            21.00216320049775,
            105.82670143290409,
            21.002166953671896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82653156650967,
              21.002166953671896
            ],
            [
              105.82653617225694,
              21.00216685182354
            ],
            [
              105.82670143290409,
              21.00216320049775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT216_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10054",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002166953671896,
          "to_latitude": 21.00216320049775,
          "to_longitude": 105.82670143290409,
          "from_longitude": 105.82653156650967
        }
      },
      {
        "id": 10047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82651940545533,
            21.001938247221428,
            105.826763730891,
            21.001974392261097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.826763730891,
              21.001938247221428
            ],
            [
              105.82665127240143,
              21.001954679364317
            ],
            [
              105.8265220204968,
              21.00197400111114
            ],
            [
              105.82651940545533,
              21.001974392261097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT217_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10055",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001938247221428,
          "to_latitude": 21.001974392261097,
          "to_longitude": 105.82651940545533,
          "from_longitude": 105.826763730891
        }
      },
      {
        "id": 10048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82653825556972,
            21.002283009494587,
            105.82655106846518,
            21.002285968361974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82653825556972,
              21.002285968361974
            ],
            [
              105.82654573408576,
              21.00228424210929
            ],
            [
              105.82654967843268,
              21.002283330567515
            ],
            [
              105.82655106846518,
              21.002283009494587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT216_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10056",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002285968361974,
          "to_latitude": 21.002283009494587,
          "to_longitude": 105.82655106846518,
          "from_longitude": 105.82653825556972
        }
      },
      {
        "id": 10049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82652126992306,
            21.001966604613774,
            105.8265220204968,
            21.00197400111114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82652126992306,
              21.001966604613774
            ],
            [
              105.82652143773043,
              21.001968257608297
            ],
            [
              105.82652181916373,
              21.001972024020787
            ],
            [
              105.8265220204968,
              21.00197400111114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT217_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10057",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001966604613774,
          "to_latitude": 21.00197400111114,
          "to_longitude": 105.8265220204968,
          "from_longitude": 105.82652126992306
        }
      },
      {
        "id": 10050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82426848753248,
            21.002543630391934,
            105.82472972809572,
            21.002666140184868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82426848753248,
              21.002666140184868
            ],
            [
              105.82440125282295,
              21.002624007450024
            ],
            [
              105.8244939829391,
              21.00260344613439
            ],
            [
              105.82460271223287,
              21.002571802336412
            ],
            [
              105.8246056220001,
              21.002570955464186
            ],
            [
              105.82472972809572,
              21.002543630391934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10058",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002666140184868,
          "to_latitude": 21.002543630391934,
          "to_longitude": 105.82472972809572,
          "from_longitude": 105.82426848753248
        }
      },
      {
        "id": 10051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82472972809572,
            21.00244751250448,
            105.82522992280263,
            21.002543630391934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82472972809572,
              21.002543630391934
            ],
            [
              105.82479303423533,
              21.002532209432257
            ],
            [
              105.82494621496257,
              21.002493467424593
            ],
            [
              105.82505656646391,
              21.002470526205855
            ],
            [
              105.82522992280263,
              21.00244751250448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10059",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002543630391934,
          "to_latitude": 21.00244751250448,
          "to_longitude": 105.82522992280263,
          "from_longitude": 105.82472972809572
        }
      },
      {
        "id": 10052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82460271223287,
            21.002571802336412,
            105.82460651844545,
            21.002584085857656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82460271223287,
              21.002571802336412
            ],
            [
              105.82460338692631,
              21.00257398216906
            ],
            [
              105.82460481753935,
              21.002578595247844
            ],
            [
              105.82460651844545,
              21.002584085857656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10060",
          "diaChiLapD": "Ngõ 191, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002571802336412,
          "to_latitude": 21.002584085857656,
          "to_longitude": 105.82460651844545,
          "from_longitude": 105.82460271223287
        }
      },
      {
        "id": 10053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82411342052984,
            21.002229588916904,
            105.82493831738708,
            21.002453020255068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82411342052984,
              21.002453020255068
            ],
            [
              105.824282087128,
              21.002394119694138
            ],
            [
              105.82430637707648,
              21.00238716102018
            ],
            [
              105.82439452212344,
              21.002361910730354
            ],
            [
              105.82443931433244,
              21.002350640988862
            ],
            [
              105.82464132154719,
              21.002305950749154
            ],
            [
              105.82493831738708,
              21.002229588916904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 199, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10061",
          "diaChiLapD": "Ngõ 199, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002453020255068,
          "to_latitude": 21.002229588916904,
          "to_longitude": 105.82493831738708,
          "from_longitude": 105.82411342052984
        }
      },
      {
        "id": 10054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82430637707648,
            21.00238716102018,
            105.82431012552037,
            21.002397512824825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82430637707648,
              21.00238716102018
            ],
            [
              105.82430757058454,
              21.00239046105378
            ],
            [
              105.8243086048545,
              21.002393313857567
            ],
            [
              105.82431012552037,
              21.002397512824825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT_Ngõ 199, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10062",
          "diaChiLapD": "Ngõ 199, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00238716102018,
          "to_latitude": 21.002397512824825,
          "to_longitude": 105.82431012552037,
          "from_longitude": 105.82430637707648
        }
      },
      {
        "id": 10055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82620594517016,
            21.00173001849119,
            105.82640246100497,
            21.0019270387574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8263916382666,
              21.00173001849119
            ],
            [
              105.82640070773913,
              21.001761501613206
            ],
            [
              105.82640246100497,
              21.001767582660527
            ],
            [
              105.82620594517016,
              21.001823950519373
            ],
            [
              105.82621893393018,
              21.0019270387574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT236_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10063",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00173001849119,
          "to_latitude": 21.0019270387574,
          "to_longitude": 105.82621893393018,
          "from_longitude": 105.8263916382666
        }
      },
      {
        "id": 10056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82621893393018,
            21.0019270387574,
            105.82625667261387,
            21.001942853539262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82621893393018,
              21.0019270387574
            ],
            [
              105.82625667261387,
              21.001942853539262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT236_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10064",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0019270387574,
          "to_latitude": 21.001942853539262,
          "to_longitude": 105.82625667261387,
          "from_longitude": 105.82621893393018
        }
      },
      {
        "id": 10057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82625667261387,
            21.001942853539262,
            105.82648603294007,
            21.001956721884184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82625667261387,
              21.001942853539262
            ],
            [
              105.82648603294007,
              21.001956721884184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT236_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10065",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001942853539262,
          "to_latitude": 21.001956721884184,
          "to_longitude": 105.82648603294007,
          "from_longitude": 105.82625667261387
        }
      },
      {
        "id": 10058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82591395010789,
            21.0019270387574,
            105.82621893393018,
            21.001936768911023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82621893393018,
              21.0019270387574
            ],
            [
              105.82607869531749,
              21.001935799670225
            ],
            [
              105.82591395010789,
              21.001936768911023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT236_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10066",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0019270387574,
          "to_latitude": 21.001936768911023,
          "to_longitude": 105.82591395010789,
          "from_longitude": 105.82621893393018
        }
      },
      {
        "id": 10059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82760211174828,
            21.001409744916693,
            105.82769259912526,
            21.00149394728034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82760211174828,
              21.001409744916693
            ],
            [
              105.82760949266134,
              21.001435335394813
            ],
            [
              105.82761160384348,
              21.001442649400094
            ],
            [
              105.82768662813001,
              21.001426991176068
            ],
            [
              105.82769259912526,
              21.00149394728034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT233_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10067",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001409744916693,
          "to_latitude": 21.00149394728034,
          "to_longitude": 105.82769259912526,
          "from_longitude": 105.82760211174828
        }
      },
      {
        "id": 10060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82778349533153,
            21.001318232647296,
            105.82788090131194,
            21.001519361061547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82778349533153,
              21.001519361061547
            ],
            [
              105.82786291596736,
              21.001513768621294
            ],
            [
              105.82785991262797,
              21.001381491152678
            ],
            [
              105.8278625552379,
              21.00137738126199
            ],
            [
              105.82788090131194,
              21.001359036008285
            ],
            [
              105.8278777774547,
              21.001345821632277
            ],
            [
              105.82787209298418,
              21.00132176201531
            ],
            [
              105.82787125883534,
              21.001318232647296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT232_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10068",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001519361061547,
          "to_latitude": 21.001318232647296,
          "to_longitude": 105.82787125883534,
          "from_longitude": 105.82778349533153
        }
      },
      {
        "id": 10061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8278625552379,
            21.0013601568278,
            105.82803259245848,
            21.001510958935928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8278625552379,
              21.00137738126199
            ],
            [
              105.82800923799398,
              21.0013601568278
            ],
            [
              105.82803259245848,
              21.001510958935928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT232_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10069",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00137738126199,
          "to_latitude": 21.001510958935928,
          "to_longitude": 105.82803259245848,
          "from_longitude": 105.8278625552379
        }
      },
      {
        "id": 10062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82855485910584,
            21.001078814134363,
            105.82865912036841,
            21.001405697293055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82865912036841,
              21.001405697293055
            ],
            [
              105.82864806059918,
              21.00133835955424
            ],
            [
              105.82855485910584,
              21.00113177009894
            ],
            [
              105.8285653346261,
              21.00110477313526
            ],
            [
              105.82857186498458,
              21.001087942227592
            ],
            [
              105.82857540708824,
              21.001078814134363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT230_Trường Chinh",
          "maTaiSan": "04.O18.DODV.10070",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001405697293055,
          "to_latitude": 21.001078814134363,
          "to_longitude": 105.82857540708824,
          "from_longitude": 105.82865912036841
        }
      },
      {
        "id": 10063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282335979861,
            21.001466934092655,
            105.82847162930155,
            21.001774582820328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82847162930155,
              21.001774582820328
            ],
            [
              105.82846996348374,
              21.001768676597152
            ],
            [
              105.82840612859802,
              21.00154213275792
            ],
            [
              105.82838117398924,
              21.001466934092655
            ],
            [
              105.8282335979861,
              21.001487884490967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT223_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10071",
          "diaChiLapD": "Ngõ 72, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001774582820328,
          "to_latitude": 21.001487884490967,
          "to_longitude": 105.8282335979861,
          "from_longitude": 105.82847162930155
        }
      },
      {
        "id": 10064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82909403428219,
            21.005033197099035,
            105.82917010557239,
            21.005051148087453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82917010557239,
              21.005033197099035
            ],
            [
              105.82916952055804,
              21.00503333543309
            ],
            [
              105.82909403428219,
              21.005051148087453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT133_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.10072",
          "diaChiLapD": "Ngõ 43, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005033197099035,
          "to_latitude": 21.005051148087453,
          "to_longitude": 105.82909403428219,
          "from_longitude": 105.82917010557239
        }
      },
      {
        "id": 10065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82801574600985,
            21.003131871046005,
            105.8280421115165,
            21.003280420270023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82801574600985,
              21.003131871046005
            ],
            [
              105.828018296516,
              21.0031604033215
            ],
            [
              105.82801834672007,
              21.003160960368334
            ],
            [
              105.8280421115165,
              21.003280420270023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT161_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.10073",
          "diaChiLapD": "Ngõ 22, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003131871046005,
          "to_latitude": 21.003280420270023,
          "to_longitude": 105.8280421115165,
          "from_longitude": 105.82801574600985
        }
      },
      {
        "id": 10066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8263966473854,
            21.004059229799793,
            105.82643918678116,
            21.00415862694839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8263966473854,
              21.00415862694839
            ],
            [
              105.82643918678116,
              21.004059229799793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT192_Ngõ 139, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10074",
          "diaChiLapD": "Ngõ 139, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00415862694839,
          "to_latitude": 21.004059229799793,
          "to_longitude": 105.82643918678116,
          "from_longitude": 105.8263966473854
        }
      },
      {
        "id": 10067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8262639818655,
            21.004022462257815,
            105.8263115412314,
            21.004117330578403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8262639818655,
              21.004117330578403
            ],
            [
              105.8263115412314,
              21.004022462257815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT192_Ngõ 139, Khương Thượng",
          "maTaiSan": "04.O18.DODV.10075",
          "diaChiLapD": "Ngõ 139, Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004117330578403,
          "to_latitude": 21.004022462257815,
          "to_longitude": 105.8263115412314,
          "from_longitude": 105.8262639818655
        }
      },
      {
        "id": 10068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82375864364491,
            21.004220054939474,
            105.82376491078233,
            21.004223102954903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82375864364491,
              21.004223102954903
            ],
            [
              105.82375888826466,
              21.00422298344817
            ],
            [
              105.82376185443604,
              21.004221541089517
            ],
            [
              105.82376491078233,
              21.004220054939474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT299_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10076",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004223102954903,
          "to_latitude": 21.004220054939474,
          "to_longitude": 105.82376491078233,
          "from_longitude": 105.82375864364491
        }
      },
      {
        "id": 10069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82348060077442,
            21.003706947718978,
            105.82353767824436,
            21.003813396952356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82352759663125,
              21.003813396952356
            ],
            [
              105.82353119186422,
              21.003811605617972
            ],
            [
              105.82353767824436,
              21.00380837319512
            ],
            [
              105.82348060077442,
              21.003706947718978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT283_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10077",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003813396952356,
          "to_latitude": 21.003706947718978,
          "to_longitude": 105.82348060077442,
          "from_longitude": 105.82352759663125
        }
      },
      {
        "id": 10070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82353767824436,
            21.00380837319512,
            105.82364108752645,
            21.00399212855649
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82364108752645,
              21.00399212855649
            ],
            [
              105.82355130023316,
              21.00383257972286
            ],
            [
              105.82354461922488,
              21.003828564685765
            ],
            [
              105.82354441656497,
              21.003828442827377
            ],
            [
              105.82354468933185,
              21.003820831814316
            ],
            [
              105.82353767824436,
              21.00380837319512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT283_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.10078",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00399212855649,
          "to_latitude": 21.00380837319512,
          "to_longitude": 105.82353767824436,
          "from_longitude": 105.82364108752645
        }
      },
      {
        "id": 10071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8145510586873,
            21.01494999275791,
            105.81501600910583,
            21.015824275234984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8145510586873,
              21.015824275234984
            ],
            [
              105.81501600910583,
              21.015454777947333
            ],
            [
              105.81499551523044,
              21.015373736448257
            ],
            [
              105.81490768779848,
              21.015243323681833
            ],
            [
              105.81489583245917,
              21.015214385042714
            ],
            [
              105.81468409576985,
              21.01494999275791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B18A_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10079",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 127,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015824275234984,
          "to_latitude": 21.01494999275791,
          "to_longitude": 105.81468409576985,
          "from_longitude": 105.8145510586873
        }
      },
      {
        "id": 10072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81541531633638,
            21.015523717388373,
            105.81569152424214,
            21.015918618581047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81541531633638,
              21.015523717388373
            ],
            [
              105.81551314600239,
              21.015685330898968
            ],
            [
              105.81555182750174,
              21.015735920425758
            ],
            [
              105.815557398271,
              21.0157432053499
            ],
            [
              105.81569152424214,
              21.015918618581047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4B_Thái Hà",
          "maTaiSan": "04.O11.DODV.10080",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015523717388373,
          "to_latitude": 21.015918618581047,
          "to_longitude": 105.81569152424214,
          "from_longitude": 105.81541531633638
        }
      },
      {
        "id": 10073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81555182750174,
            21.01571697781887,
            105.81558030183895,
            21.015735920425758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81558030183895,
              21.01571697781887
            ],
            [
              105.81557807338542,
              21.015718459859933
            ],
            [
              105.81555905343454,
              21.01573111313067
            ],
            [
              105.81555182750174,
              21.015735920425758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4B_Thái Hà",
          "maTaiSan": "04.O11.DODV.10081",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01571697781887,
          "to_latitude": 21.015735920425758,
          "to_longitude": 105.81555182750174,
          "from_longitude": 105.81558030183895
        }
      },
      {
        "id": 10074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81546874374459,
            21.01542735422306,
            105.81594300484278,
            21.015900032765654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81594300484278,
              21.015781421778538
            ],
            [
              105.81575088367579,
              21.015900032765654
            ],
            [
              105.81564039422815,
              21.01575341136013
            ],
            [
              105.81562692994606,
              21.015735545032843
            ],
            [
              105.8155913730502,
              21.015688361971527
            ],
            [
              105.81546874374459,
              21.015506969270827
            ],
            [
              105.81547119961307,
              21.01542735422306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10082",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015781421778538,
          "to_latitude": 21.01542735422306,
          "to_longitude": 105.81547119961307,
          "from_longitude": 105.81594300484278
        }
      },
      {
        "id": 10075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81561662230605,
            21.01575341136013,
            105.81564039422815,
            21.015769782524266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81561662230605,
              21.015769782524266
            ],
            [
              105.81561795058066,
              21.01576886750917
            ],
            [
              105.81562417699598,
              21.015764579843008
            ],
            [
              105.81564039422815,
              21.01575341136013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10083",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015769782524266,
          "to_latitude": 21.01575341136013,
          "to_longitude": 105.81564039422815,
          "from_longitude": 105.81561662230605
        }
      },
      {
        "id": 10076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81510495795783,
            21.01529781338156,
            105.81544588096514,
            21.015467768445895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81544588096514,
              21.015399143127418
            ],
            [
              105.8154366136293,
              21.015398962642248
            ],
            [
              105.81541570269734,
              21.01539855164007
            ],
            [
              105.81541365565315,
              21.01545923849225
            ],
            [
              105.8152071131023,
              21.015467768445895
            ],
            [
              105.81510495795783,
              21.01529781338156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2_Thái Hà",
          "maTaiSan": "04.O11.DODV.10084",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015399143127418,
          "to_latitude": 21.01529781338156,
          "to_longitude": 105.81510495795783,
          "from_longitude": 105.81544588096514
        }
      },
      {
        "id": 10077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8156990874949,
            21.015287571125384,
            105.81599613627604,
            21.01579708272512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81599613627604,
              21.01579708272512
            ],
            [
              105.81599154229126,
              21.015785457637975
            ],
            [
              105.81587857889946,
              21.015499536211323
            ],
            [
              105.8156990874949,
              21.015287571125384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2_Ngõ 171, Thái Hà",
          "maTaiSan": "04.O11.DODV.10085",
          "diaChiLapD": "Ngõ 171, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01579708272512,
          "to_latitude": 21.015287571125384,
          "to_longitude": 105.8156990874949,
          "from_longitude": 105.81599613627604
        }
      },
      {
        "id": 10078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81587236146638,
            21.01517422879726,
            105.8160349759818,
            21.015783375157447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81601557082914,
              21.015783375157447
            ],
            [
              105.81601097084678,
              21.01577259281109
            ],
            [
              105.81596282045147,
              21.01565969448118
            ],
            [
              105.81592204769554,
              21.015512041580134
            ],
            [
              105.81601029250643,
              21.015377368895404
            ],
            [
              105.8160349759818,
              21.015348573021246
            ],
            [
              105.81587236146638,
              21.01517422879726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3A_Ngõ 171, Thái Hà",
          "maTaiSan": "04.O11.DODV.10086",
          "diaChiLapD": "Ngõ 171, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015783375157447,
          "to_latitude": 21.01517422879726,
          "to_longitude": 105.81587236146638,
          "from_longitude": 105.81601557082914
        }
      },
      {
        "id": 10079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8160582997936,
            21.0153081044288,
            105.81660712712917,
            21.015701252869253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8160582997936,
              21.015701252869253
            ],
            [
              105.81630286818059,
              21.015526059207428
            ],
            [
              105.8163517241028,
              21.015491061356727
            ],
            [
              105.81660712712917,
              21.0153081044288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3_Thái Hà",
          "maTaiSan": "04.O11.DODV.10087",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015701252869253,
          "to_latitude": 21.0153081044288,
          "to_longitude": 105.81660712712917,
          "from_longitude": 105.8160582997936
        }
      },
      {
        "id": 10080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81630286818059,
            21.015526059207428,
            105.81633282464395,
            21.015559595927613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81630286818059,
              21.015526059207428
            ],
            [
              105.81632487677393,
              21.015550698432108
            ],
            [
              105.81633059572432,
              21.015557101972213
            ],
            [
              105.81633282464395,
              21.015559595927613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3_Thái Hà",
          "maTaiSan": "04.O11.DODV.10088",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015526059207428,
          "to_latitude": 21.015559595927613,
          "to_longitude": 105.81633282464395,
          "from_longitude": 105.81630286818059
        }
      },
      {
        "id": 10081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81605111683608,
            21.015051984991185,
            105.81668051457834,
            21.01534442023712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81668051457834,
              21.015321224707005
            ],
            [
              105.81667849187001,
              21.015318796605147
            ],
            [
              105.8165720306699,
              21.015190922833384
            ],
            [
              105.81643063635718,
              21.015051984991185
            ],
            [
              105.81605111683608,
              21.01534442023712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Ngõ 169, Thái Hà",
          "maTaiSan": "04.O11.DODV.10089",
          "diaChiLapD": "Ngõ 169, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015321224707005,
          "to_latitude": 21.01534442023712,
          "to_longitude": 105.81605111683608,
          "from_longitude": 105.81668051457834
        }
      },
      {
        "id": 10082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81615484933249,
            21.014744911015843,
            105.81670312394718,
            21.015305850723273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81670312394718,
              21.015305850723273
            ],
            [
              105.81670052916337,
              21.01530276691492
            ],
            [
              105.81642559203479,
              21.014975825551588
            ],
            [
              105.81638369292655,
              21.014988714249164
            ],
            [
              105.81615484933249,
              21.014744911015843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5A_Ngõ 169, Thái Hà",
          "maTaiSan": "04.O11.DODV.10090",
          "diaChiLapD": "Ngõ 169, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015305850723273,
          "to_latitude": 21.014744911015843,
          "to_longitude": 105.81615484933249,
          "from_longitude": 105.81670312394718
        }
      },
      {
        "id": 10083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81670138900817,
            21.015126930455022,
            105.81687543688413,
            21.015247621642118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81670138900817,
              21.015247621642118
            ],
            [
              105.81677713740544,
              21.01519509479902
            ],
            [
              105.8168144437809,
              21.01516922518897
            ],
            [
              105.81687543688413,
              21.015126930455022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10091",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015247621642118,
          "to_latitude": 21.015126930455022,
          "to_longitude": 105.81687543688413,
          "from_longitude": 105.81670138900817
        }
      },
      {
        "id": 10084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8168144437809,
            21.01516922518897,
            105.81684630790124,
            21.01520737266947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8168144437809,
              21.01516922518897
            ],
            [
              105.8168442097152,
              21.01520486273825
            ],
            [
              105.81684630790124,
              21.01520737266947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10092",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01516922518897,
          "to_latitude": 21.01520737266947,
          "to_longitude": 105.81684630790124,
          "from_longitude": 105.8168144437809
        }
      },
      {
        "id": 10085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81667579259678,
            21.014823726082803,
            105.81692838765628,
            21.015154485684175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81692838765628,
              21.015154485684175
            ],
            [
              105.81667579259678,
              21.014823726082803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4A_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10093",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015154485684175,
          "to_latitude": 21.014823726082803,
          "to_longitude": 105.81667579259678,
          "from_longitude": 105.81692838765628
        }
      },
      {
        "id": 10086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637493118909,
            21.01446475592817,
            105.81659852235447,
            21.014729201161526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637493118909,
              21.01446475592817
            ],
            [
              105.81640879521372,
              21.01451391870309
            ],
            [
              105.81643645325599,
              21.014545302309227
            ],
            [
              105.8164749993419,
              21.01458904039619
            ],
            [
              105.81659852235447,
              21.014729201161526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10094",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01446475592817,
          "to_latitude": 21.014729201161526,
          "to_longitude": 105.81659852235447,
          "from_longitude": 105.81637493118909
        }
      },
      {
        "id": 10087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81643645325599,
            21.014515119499524,
            105.81647722306454,
            21.014545302309227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81647722306454,
              21.014515119499524
            ],
            [
              105.81647568631973,
              21.01451625771545
            ],
            [
              105.81646034566621,
              21.01452761445505
            ],
            [
              105.81643645325599,
              21.014545302309227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10095",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014515119499524,
          "to_latitude": 21.014545302309227,
          "to_longitude": 105.81643645325599,
          "from_longitude": 105.81647722306454
        }
      },
      {
        "id": 10088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81634937616457,
            21.01433478032443,
            105.81651219957456,
            21.01452442560138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81634937616457,
              21.01433478032443
            ],
            [
              105.81640629485335,
              21.014412060154857
            ],
            [
              105.81644528103914,
              21.01445342335921
            ],
            [
              105.8164889287709,
              21.014499734645483
            ],
            [
              105.81651219957456,
              21.01452442560138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10096",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01433478032443,
          "to_latitude": 21.01452442560138,
          "to_longitude": 105.81651219957456,
          "from_longitude": 105.81634937616457
        }
      },
      {
        "id": 10089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81647327014318,
            21.014499734645483,
            105.8164889287709,
            21.014510815621232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8164889287709,
              21.014499734645483
            ],
            [
              105.81648312566047,
              21.014503841471225
            ],
            [
              105.81647401853185,
              21.014510286351204
            ],
            [
              105.81647327014318,
              21.014510815621232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10097",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014499734645483,
          "to_latitude": 21.014510815621232,
          "to_longitude": 105.81647327014318,
          "from_longitude": 105.8164889287709
        }
      },
      {
        "id": 10090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81625429345726,
            21.014613096493196,
            105.8166502609572,
            21.01487539862054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8166502609572,
              21.014759770953447
            ],
            [
              105.81664366277126,
              21.01476449037658
            ],
            [
              105.81648859182297,
              21.01487539862054
            ],
            [
              105.81625429345726,
              21.014613096493196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10098",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014759770953447,
          "to_latitude": 21.014613096493196,
          "to_longitude": 105.81625429345726,
          "from_longitude": 105.8166502609572
        }
      },
      {
        "id": 10091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81699266431048,
            21.014809103325582,
            105.81728267348514,
            21.015020798312904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81699266431048,
              21.015020798312904
            ],
            [
              105.81710650213931,
              21.014937701128687
            ],
            [
              105.81728267348514,
              21.014809103325582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10099",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015020798312904,
          "to_latitude": 21.014809103325582,
          "to_longitude": 105.81728267348514,
          "from_longitude": 105.81699266431048
        }
      },
      {
        "id": 10092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81731107453733,
            21.01456180049295,
            105.81762237167486,
            21.014789848273207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81731107453733,
              21.014789848273207
            ],
            [
              105.8174745318109,
              21.014670104275925
            ],
            [
              105.81762237167486,
              21.01456180049295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Thái Hà",
          "maTaiSan": "04.O11.DODV.10100",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014789848273207,
          "to_latitude": 21.01456180049295,
          "to_longitude": 105.81762237167486,
          "from_longitude": 105.81731107453733
        }
      },
      {
        "id": 10093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81731741342526,
            21.01414046489283,
            105.81770891388024,
            21.014580504096724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81731741342526,
              21.01414046489283
            ],
            [
              105.81770175364046,
              21.014572458236376
            ],
            [
              105.81770406004645,
              21.01457504844679
            ],
            [
              105.81770891388024,
              21.014580504096724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C1_Thái Hà",
          "maTaiSan": "04.O11.DODV.10101",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01414046489283,
          "to_latitude": 21.014580504096724,
          "to_longitude": 105.81770891388024,
          "from_longitude": 105.81731741342526
        }
      },
      {
        "id": 10094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753206295538,
            21.014029573111152,
            105.8181211744724,
            21.014320603016476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81753206295538,
              21.014320603016476
            ],
            [
              105.81791300347558,
              21.014029573111152
            ],
            [
              105.81811960463224,
              21.014284415377656
            ],
            [
              105.8181211744724,
              21.01428634976813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1_Thái Hà",
          "maTaiSan": "04.O11.DODV.10102",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014320603016476,
          "to_latitude": 21.01428634976813,
          "to_longitude": 105.8181211744724,
          "from_longitude": 105.81753206295538
        }
      },
      {
        "id": 10095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81775388398924,
            21.01425994760356,
            105.81805146748697,
            21.01449364314225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81775388398924,
              21.01449364314225
            ],
            [
              105.81789356545393,
              21.014383950341706
            ],
            [
              105.81790651532113,
              21.014373780971674
            ],
            [
              105.81805146748697,
              21.01425994760356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Thái Hà",
          "maTaiSan": "04.O11.DODV.10103",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01449364314225,
          "to_latitude": 21.01425994760356,
          "to_longitude": 105.81805146748697,
          "from_longitude": 105.81775388398924
        }
      },
      {
        "id": 10096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81790651532113,
            21.014373780971674,
            105.8179391535822,
            21.01441098968581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81790651532113,
              21.014373780971674
            ],
            [
              105.81791647915264,
              21.01438527451354
            ],
            [
              105.8179369198812,
              21.014408855258967
            ],
            [
              105.8179391535822,
              21.01441098968581
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Thái Hà",
          "maTaiSan": "04.O11.DODV.10104",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014373780971674,
          "to_latitude": 21.01441098968581,
          "to_longitude": 105.8179391535822,
          "from_longitude": 105.81790651532113
        }
      },
      {
        "id": 10097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81829347105617,
            21.013559359666367,
            105.81856815578044,
            21.0140710530519
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81832791461422,
              21.0140710530519
            ],
            [
              105.81849078036258,
              21.013928872077162
            ],
            [
              105.81856815578044,
              21.01386132048055
            ],
            [
              105.81829347105617,
              21.013559359666367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10105",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0140710530519,
          "to_latitude": 21.013559359666367,
          "to_longitude": 105.81829347105617,
          "from_longitude": 105.81832791461422
        }
      },
      {
        "id": 10098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81849078036258,
            21.013928872077162,
            105.81852755650672,
            21.01397500272496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81849078036258,
              21.013928872077162
            ],
            [
              105.81851176785614,
              21.013955164322244
            ],
            [
              105.81852755650672,
              21.01397500272496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10106",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013928872077162,
          "to_latitude": 21.01397500272496,
          "to_longitude": 105.81852755650672,
          "from_longitude": 105.81849078036258
        }
      },
      {
        "id": 10099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81776206641047,
            21.0134751673461,
            105.81816213794988,
            21.01375399499223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81776206641047,
              21.01375399499223
            ],
            [
              105.81783536684253,
              21.01370293456576
            ],
            [
              105.81790048054127,
              21.01365633566638
            ],
            [
              105.81793168433016,
              21.01363795852861
            ],
            [
              105.81800909828509,
              21.01358327437652
            ],
            [
              105.81801382238301,
              21.01357993681868
            ],
            [
              105.81816213794988,
              21.0134751673461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10107",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01375399499223,
          "to_latitude": 21.0134751673461,
          "to_longitude": 105.81816213794988,
          "from_longitude": 105.81776206641047
        }
      },
      {
        "id": 10100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81800825400332,
            21.013573272480137,
            105.81801382238301,
            21.01357993681868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81800825400332,
              21.013573272480137
            ],
            [
              105.8180100317118,
              21.013575401873066
            ],
            [
              105.81801382238301,
              21.01357993681868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10108",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013573272480137,
          "to_latitude": 21.01357993681868,
          "to_longitude": 105.81801382238301,
          "from_longitude": 105.81800825400332
        }
      },
      {
        "id": 10101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81778949847359,
            21.01327436486934,
            105.81802850601305,
            21.01355979469797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81802850601305,
              21.01355979469797
            ],
            [
              105.81802261335767,
              21.013552757982755
            ],
            [
              105.81801718352537,
              21.013546275430343
            ],
            [
              105.81778949847359,
              21.01327436486934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6A_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10109",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01355979469797,
          "to_latitude": 21.01327436486934,
          "to_longitude": 105.81778949847359,
          "from_longitude": 105.81802850601305
        }
      },
      {
        "id": 10102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81749390719254,
            21.01294118011772,
            105.81774499859846,
            21.0132204916687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81774499859846,
              21.0132204916687
            ],
            [
              105.81749694605642,
              21.012944561653445
            ],
            [
              105.81749390719254,
              21.01294118011772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10110",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0132204916687,
          "to_latitude": 21.01294118011772,
          "to_longitude": 105.81749390719254,
          "from_longitude": 105.81774499859846
        }
      },
      {
        "id": 10103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8178946706708,
            21.013097965679766,
            105.81817823196316,
            21.01350760215392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8178946706708,
              21.013097965679766
            ],
            [
              105.81817823196316,
              21.013421557461403
            ],
            [
              105.81809175415887,
              21.013488807616483
            ],
            [
              105.81810410463437,
              21.013504209681983
            ],
            [
              105.81810552798733,
              21.01350591897047
            ],
            [
              105.81810693292138,
              21.01350760215392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6B_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10111",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013097965679766,
          "to_latitude": 21.01350760215392,
          "to_longitude": 105.81810693292138,
          "from_longitude": 105.8178946706708
        }
      },
      {
        "id": 10104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763484626022,
            21.01281746014227,
            105.81788386205075,
            21.013083592187353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81764084471412,
              21.01283105435409
            ],
            [
              105.81763484626022,
              21.012822952323198
            ],
            [
              105.81764229238148,
              21.01281746014227
            ],
            [
              105.817658159078,
              21.01283418934986
            ],
            [
              105.81788386205075,
              21.013083592187353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6A_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10112",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01283105435409,
          "to_latitude": 21.013083592187353,
          "to_longitude": 105.81788386205075,
          "from_longitude": 105.81764084471412
        }
      },
      {
        "id": 10105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81728331716057,
            21.012380896216094,
            105.81763843131574,
            21.01283283085864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81763843131574,
              21.01283283085864
            ],
            [
              105.81762825170568,
              21.012819845296374
            ],
            [
              105.81754575218817,
              21.012714589360595
            ],
            [
              105.81728331716057,
              21.012380896216094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C6B_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10113",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01283283085864,
          "to_latitude": 21.012380896216094,
          "to_longitude": 105.81728331716057,
          "from_longitude": 105.81763843131574
        }
      },
      {
        "id": 10106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81712135208622,
            21.01287468895255,
            105.81754091470964,
            21.013205793640605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81712135208622,
              21.013205793640605
            ],
            [
              105.817318009783,
              21.013050597646238
            ],
            [
              105.81733016720446,
              21.01304100387046
            ],
            [
              105.81754091470964,
              21.01287468895255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10114",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013205793640605,
          "to_latitude": 21.01287468895255,
          "to_longitude": 105.81754091470964,
          "from_longitude": 105.81712135208622
        }
      },
      {
        "id": 10107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81733016720446,
            21.01304100387046,
            105.81734583944375,
            21.013058237139035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81733016720446,
              21.01304100387046
            ],
            [
              105.81733429648382,
              21.013045547141367
            ],
            [
              105.81734583944375,
              21.013058237139035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10115",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01304100387046,
          "to_latitude": 21.013058237139035,
          "to_longitude": 105.81734583944375,
          "from_longitude": 105.81733016720446
        }
      },
      {
        "id": 10108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8170031619574,
            21.01229424268117,
            105.81713712311306,
            21.01244763354701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81713712311306,
              21.01229424268117
            ],
            [
              105.8170766607638,
              21.012363474615004
            ],
            [
              105.81707459135066,
              21.012371362506187
            ],
            [
              105.81706638135212,
              21.01237524426241
            ],
            [
              105.8170500746372,
              21.012393917319496
            ],
            [
              105.81704669533194,
              21.012397787620063
            ],
            [
              105.8170031619574,
              21.01244763354701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10116",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01229424268117,
          "to_latitude": 21.01244763354701,
          "to_longitude": 105.8170031619574,
          "from_longitude": 105.81713712311306
        }
      },
      {
        "id": 10109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81701617712265,
            21.012364867892604,
            105.8170500746372,
            21.012393917319496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8170500746372,
              21.012393917319496
            ],
            [
              105.81701779879964,
              21.012366259205777
            ],
            [
              105.81701617712265,
              21.012364867892604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10117",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012393917319496,
          "to_latitude": 21.012364867892604,
          "to_longitude": 105.81701617712265,
          "from_longitude": 105.8170500746372
        }
      },
      {
        "id": 10110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704461326461,
            21.012333056951736,
            105.81728283591094,
            21.012646226223268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81704461326461,
              21.012333056951736
            ],
            [
              105.81704666293092,
              21.01233567279297
            ],
            [
              105.81707234844151,
              21.012368412495718
            ],
            [
              105.81707459135066,
              21.012371362506187
            ],
            [
              105.81728283591094,
              21.012646226223268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38A_Ngõ 156, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10118",
          "diaChiLapD": "Ngõ 156, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012333056951736,
          "to_latitude": 21.012646226223268,
          "to_longitude": 105.81728283591094,
          "from_longitude": 105.81704461326461
        }
      },
      {
        "id": 10111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81695946138468,
            21.01244629163115,
            105.81724889806911,
            21.012807556692493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81695946138468,
              21.01244629163115
            ],
            [
              105.8169635523457,
              21.012450339224014
            ],
            [
              105.81703292737903,
              21.012518950398746
            ],
            [
              105.81724889806911,
              21.012807556692493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Ngõ 158, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10119",
          "diaChiLapD": "Ngõ 158, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01244629163115,
          "to_latitude": 21.012807556692493,
          "to_longitude": 105.81724889806911,
          "from_longitude": 105.81695946138468
        }
      },
      {
        "id": 10112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81668846492607,
            21.012694074450796,
            105.81706699560557,
            21.01317999655632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81668846492607,
              21.012694074450796
            ],
            [
              105.81669314080875,
              21.012700079247114
            ],
            [
              105.81706699560557,
              21.01317999655632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A40_Ngõ 160, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10120",
          "diaChiLapD": "Ngõ 160, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012694074450796,
          "to_latitude": 21.01317999655632,
          "to_longitude": 105.81706699560557,
          "from_longitude": 105.81668846492607
        }
      },
      {
        "id": 10113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81640483231122,
            21.012908552247875,
            105.81680589843583,
            21.013388605108933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81640483231122,
              21.012908552247875
            ],
            [
              105.8164139346482,
              21.012919449372692
            ],
            [
              105.81642914939833,
              21.012937658604155
            ],
            [
              105.81680589843583,
              21.013388605108933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Ngõ 172, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10121",
          "diaChiLapD": "Ngõ 172, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012908552247875,
          "to_latitude": 21.013388605108933,
          "to_longitude": 105.81680589843583,
          "from_longitude": 105.81640483231122
        }
      },
      {
        "id": 10114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81639230585328,
            21.0129182181815,
            105.81679326344604,
            21.01339636036289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81679326344604,
              21.01339636036289
            ],
            [
              105.81641655069753,
              21.012947129788987
            ],
            [
              105.81640158902331,
              21.012929290568763
            ],
            [
              105.81639230585328,
              21.0129182181815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Ngõ 172, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10122",
          "diaChiLapD": "Ngõ 172, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01339636036289,
          "to_latitude": 21.0129182181815,
          "to_longitude": 105.81639230585328,
          "from_longitude": 105.81679326344604
        }
      },
      {
        "id": 10115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81614802129862,
            21.012738683292955,
            105.81668314979227,
            21.013142149626038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81614802129862,
              21.013142149626038
            ],
            [
              105.81629053399473,
              21.01303693087979
            ],
            [
              105.81632807325913,
              21.01300921587386
            ],
            [
              105.81640870377444,
              21.01294968564024
            ],
            [
              105.81641386062584,
              21.012949152378923
            ],
            [
              105.81641655069753,
              21.012947129788987
            ],
            [
              105.81642914939833,
              21.012937658604155
            ],
            [
              105.81643071477822,
              21.012936482315915
            ],
            [
              105.81643252577491,
              21.01293183103441
            ],
            [
              105.81668314979227,
              21.012738683292955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10123",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013142149626038,
          "to_latitude": 21.012738683292955,
          "to_longitude": 105.81668314979227,
          "from_longitude": 105.81614802129862
        }
      },
      {
        "id": 10116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81626835779298,
            21.013010409206903,
            105.81629053399473,
            21.01303693087979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81629053399473,
              21.01303693087979
            ],
            [
              105.816275452936,
              21.013018894414316
            ],
            [
              105.81627092746828,
              21.013013484112168
            ],
            [
              105.81626835779298,
              21.013010409206903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10124",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01303693087979,
          "to_latitude": 21.013010409206903,
          "to_longitude": 105.81626835779298,
          "from_longitude": 105.81629053399473
        }
      },
      {
        "id": 10117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81608739743118,
            21.013145942228398,
            105.81648559679665,
            21.013593497002365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81608739743118,
              21.013145942228398
            ],
            [
              105.81609306452039,
              21.013152380352516
            ],
            [
              105.81648559679665,
              21.013593497002365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Ngõ 180, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10125",
          "diaChiLapD": "Ngõ 180, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013145942228398,
          "to_latitude": 21.013593497002365,
          "to_longitude": 105.81648559679665,
          "from_longitude": 105.81608739743118
        }
      },
      {
        "id": 10118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81607441556024,
            21.0131553610329,
            105.81649012845344,
            21.013669848590798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81607441556024,
              21.0131553610329
            ],
            [
              105.81607952217549,
              21.0131616826196
            ],
            [
              105.81649012845344,
              21.013669848590798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Ngõ 180, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10126",
          "diaChiLapD": "Ngõ 180, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0131553610329,
          "to_latitude": 21.013669848590798,
          "to_longitude": 105.81649012845344,
          "from_longitude": 105.81607441556024
        }
      },
      {
        "id": 10119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157012911172,
            21.013203567340433,
            105.81606685639385,
            21.013457721056483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157012911172,
              21.013457721056483
            ],
            [
              105.81583185819628,
              21.01336929044245
            ],
            [
              105.81584606022278,
              21.013367904534253
            ],
            [
              105.81585211368323,
              21.01335500450866
            ],
            [
              105.81589028653485,
              21.013328085011345
            ],
            [
              105.81592126483449,
              21.013306239116062
            ],
            [
              105.81606685639385,
              21.013203567340433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10127",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013457721056483,
          "to_latitude": 21.013203567340433,
          "to_longitude": 105.81606685639385,
          "from_longitude": 105.8157012911172
        }
      },
      {
        "id": 10120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8158991278217,
            21.013278606236774,
            105.81592126483449,
            21.013306239116062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81592126483449,
              21.013306239116062
            ],
            [
              105.81590701334721,
              21.013288448833986
            ],
            [
              105.8159023393761,
              21.013282616523476
            ],
            [
              105.8158991278217,
              21.013278606236774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10128",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013306239116062,
          "to_latitude": 21.013278606236774,
          "to_longitude": 105.8158991278217,
          "from_longitude": 105.81592126483449
        }
      },
      {
        "id": 10121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8158207467634,
            21.013334933443545,
            105.81621730001955,
            21.01385143650897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8158207467634,
              21.013334933443545
            ],
            [
              105.81583312302728,
              21.013351053623673
            ],
            [
              105.81584606022278,
              21.013367904534253
            ],
            [
              105.81621730001955,
              21.01385143650897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44.1_Ngõ 188, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10129",
          "diaChiLapD": "Ngõ 188, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013334933443545,
          "to_latitude": 21.01385143650897,
          "to_longitude": 105.81621730001955,
          "from_longitude": 105.8158207467634
        }
      },
      {
        "id": 10122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81571524697664,
            21.013525517281874,
            105.81608841964133,
            21.01405635097655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8160866123207,
              21.01405635097655
            ],
            [
              105.81608537205727,
              21.01405487472859
            ],
            [
              105.81605840448447,
              21.014022717293876
            ],
            [
              105.81608841964133,
              21.014000450565838
            ],
            [
              105.81571524697664,
              21.013525517281874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34A_Ngõ 192, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10130",
          "diaChiLapD": "Ngõ 192, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01405635097655,
          "to_latitude": 21.013525517281874,
          "to_longitude": 105.81571524697664,
          "from_longitude": 105.8160866123207
        }
      },
      {
        "id": 10123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8156233589651,
            21.013474037538426,
            105.81603827764357,
            21.01397578726841
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8156233589651,
              21.013474037538426
            ],
            [
              105.81562572618905,
              21.013476854197496
            ],
            [
              105.8158745344528,
              21.013772622774272
            ],
            [
              105.81603827764357,
              21.01397578726841
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10131",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013474037538426,
          "to_latitude": 21.01397578726841,
          "to_longitude": 105.81603827764357,
          "from_longitude": 105.8156233589651
        }
      },
      {
        "id": 10124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81563510543093,
            21.013772622774272,
            105.8158745344528,
            21.013941520943593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8158745344528,
              21.013772622774272
            ],
            [
              105.81563510543093,
              21.013941520943593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10132",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013772622774272,
          "to_latitude": 21.013941520943593,
          "to_longitude": 105.81563510543093,
          "from_longitude": 105.8158745344528
        }
      },
      {
        "id": 10125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81501900392401,
            21.013548797743525,
            105.81557700233559,
            21.013949799690355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81501900392401,
              21.013949799690355
            ],
            [
              105.81529206227121,
              21.013753567609516
            ],
            [
              105.81541711117157,
              21.013663702322066
            ],
            [
              105.81557700233559,
              21.013548797743525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10133",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013949799690355,
          "to_latitude": 21.013548797743525,
          "to_longitude": 105.81557700233559,
          "from_longitude": 105.81501900392401
        }
      },
      {
        "id": 10126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81539341148125,
            21.01363632344745,
            105.81541711117157,
            21.013663702322066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81541711117157,
              21.013663702322066
            ],
            [
              105.81540395209466,
              21.01364849993012
            ],
            [
              105.81539689727578,
              21.01364035139722
            ],
            [
              105.81539341148125,
              21.01363632344745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10134",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013663702322066,
          "to_latitude": 21.01363632344745,
          "to_longitude": 105.81539341148125,
          "from_longitude": 105.81541711117157
        }
      },
      {
        "id": 10127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81464833229695,
            21.01417220917262,
            105.81502622349322,
            21.014771687261184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81464833229695,
              21.014174785156605
            ],
            [
              105.81464893155793,
              21.014175312482173
            ],
            [
              105.8146576771229,
              21.014183934370998
            ],
            [
              105.81467322750764,
              21.01417220917262
            ],
            [
              105.81467632115708,
              21.014176474757335
            ],
            [
              105.81496344025992,
              21.014572382749478
            ],
            [
              105.81502622349322,
              21.014771687261184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10135",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014174785156605,
          "to_latitude": 21.014771687261184,
          "to_longitude": 105.81502622349322,
          "from_longitude": 105.81464833229695
        }
      },
      {
        "id": 10128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81441561005427,
            21.014325207737638,
            105.81500991899523,
            21.01514459168039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81443932108218,
              21.014325207737638
            ],
            [
              105.81444037042762,
              21.014326779750803
            ],
            [
              105.8144477137997,
              21.014337761271882
            ],
            [
              105.81441561005427,
              21.014359512037075
            ],
            [
              105.81470591899298,
              21.01472396202086
            ],
            [
              105.81472547707301,
              21.01481057193366
            ],
            [
              105.81500991899523,
              21.01514459168039
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10136",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 109,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014325207737638,
          "to_latitude": 21.01514459168039,
          "to_longitude": 105.81500991899523,
          "from_longitude": 105.81443932108218
        }
      },
      {
        "id": 10129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81446727140049,
            21.01400959851122,
            105.81490743426264,
            21.014327943494692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81446727140049,
              21.014327943494692
            ],
            [
              105.81465140776284,
              21.014189341306764
            ],
            [
              105.81467147458885,
              21.014182076300923
            ],
            [
              105.81467632115708,
              21.014176474757335
            ],
            [
              105.81468538528271,
              21.014165997264204
            ],
            [
              105.8147524288895,
              21.014121705272025
            ],
            [
              105.81490743426264,
              21.01400959851122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10137",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014327943494692,
          "to_latitude": 21.01400959851122,
          "to_longitude": 105.81490743426264,
          "from_longitude": 105.81446727140049
        }
      },
      {
        "id": 10130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81474092529174,
            21.01410791477574,
            105.8147524288895,
            21.014121705272025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8147524288895,
              21.014121705272025
            ],
            [
              105.81474163695748,
              21.014108770337746
            ],
            [
              105.81474092529174,
              21.01410791477574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10138",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014121705272025,
          "to_latitude": 21.01410791477574,
          "to_longitude": 105.81474092529174,
          "from_longitude": 105.8147524288895
        }
      },
      {
        "id": 10131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81434737276165,
            21.014388794349838,
            105.81447993559215,
            21.01456062217408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81434737276165,
              21.014388794349838
            ],
            [
              105.814359780514,
              21.014404879260606
            ],
            [
              105.81436207450186,
              21.014407850739754
            ],
            [
              105.81447993559215,
              21.01456062217408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10139",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014388794349838,
          "to_latitude": 21.01456062217408,
          "to_longitude": 105.81447993559215,
          "from_longitude": 105.81434737276165
        }
      },
      {
        "id": 10132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81394623699275,
            21.01437501831612,
            105.81440077467292,
            21.014722717979826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81394623699275,
              21.014722717979826
            ],
            [
              105.81413085342712,
              21.014577923312327
            ],
            [
              105.81434225781875,
              21.014412119408373
            ],
            [
              105.81436109445761,
              21.01441053892154
            ],
            [
              105.81436207450186,
              21.014407850739754
            ],
            [
              105.81436724102733,
              21.014393673328403
            ],
            [
              105.81440077467292,
              21.01437501831612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10140",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014722717979826,
          "to_latitude": 21.01437501831612,
          "to_longitude": 105.81440077467292,
          "from_longitude": 105.81394623699275
        }
      },
      {
        "id": 10133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.814118375575,
            21.014564517695067,
            105.81413085342712,
            21.014577923312327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81413085342712,
              21.014577923312327
            ],
            [
              105.81412597975876,
              21.014572688942458
            ],
            [
              105.81411903255898,
              21.014565223585453
            ],
            [
              105.814118375575,
              21.014564517695067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10141",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014577923312327,
          "to_latitude": 21.014564517695067,
          "to_longitude": 105.814118375575,
          "from_longitude": 105.81413085342712
        }
      },
      {
        "id": 10134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81659306943698,
            21.013246273260638,
            105.81706650336126,
            21.013629619759847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81659306943698,
              21.013629619759847
            ],
            [
              105.81682750465356,
              21.013442738407747
            ],
            [
              105.81684414076466,
              21.013429063154895
            ],
            [
              105.81706650336126,
              21.013246273260638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10142",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013629619759847,
          "to_latitude": 21.013246273260638,
          "to_longitude": 105.81706650336126,
          "from_longitude": 105.81659306943698
        }
      },
      {
        "id": 10135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81684414076466,
            21.013429063154895,
            105.81686440102295,
            21.013449234318337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81684414076466,
              21.013429063154895
            ],
            [
              105.81685102023975,
              21.01343591272304
            ],
            [
              105.81686110395196,
              21.01344595336609
            ],
            [
              105.81686440102295,
              21.013449234318337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10143",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013429063154895,
          "to_latitude": 21.013449234318337,
          "to_longitude": 105.81686440102295,
          "from_longitude": 105.81684414076466
        }
      },
      {
        "id": 10136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81667434730466,
            21.013080568989633,
            105.8173386014411,
            21.013611214770727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81667434730466,
              21.013611214770727
            ],
            [
              105.81682760802344,
              21.01349493454412
            ],
            [
              105.81699720939501,
              21.013357404964264
            ],
            [
              105.81705514613694,
              21.01331042355435
            ],
            [
              105.8173386014411,
              21.013080568989633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10144",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013611214770727,
          "to_latitude": 21.013080568989633,
          "to_longitude": 105.8173386014411,
          "from_longitude": 105.81667434730466
        }
      },
      {
        "id": 10137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704135376415,
            21.013295887302604,
            105.81705514613694,
            21.01331042355435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81705514613694,
              21.01331042355435
            ],
            [
              105.81704707833299,
              21.013301920471434
            ],
            [
              105.81704278177278,
              21.01329739425504
            ],
            [
              105.81704135376415,
              21.013295887302604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10145",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01331042355435,
          "to_latitude": 21.013295887302604,
          "to_longitude": 105.81704135376415,
          "from_longitude": 105.81705514613694
        }
      },
      {
        "id": 10138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81613112505475,
            21.013779091522128,
            105.81646485873085,
            21.014045107243373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81613112505475,
              21.014045107243373
            ],
            [
              105.81626688290832,
              21.01392714926309
            ],
            [
              105.81627308783335,
              21.01392175793356
            ],
            [
              105.81646485873085,
              21.013779091522128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10146",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014045107243373,
          "to_latitude": 21.013779091522128,
          "to_longitude": 105.81646485873085,
          "from_longitude": 105.81613112505475
        }
      },
      {
        "id": 10139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81625550350381,
            21.013913576857714,
            105.81626688290832,
            21.01392714926309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81625550350381,
              21.013913576857714
            ],
            [
              105.81625753288456,
              21.013915998611097
            ],
            [
              105.8162624117016,
              21.013921816379302
            ],
            [
              105.81626688290832,
              21.01392714926309
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10147",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013913576857714,
          "to_latitude": 21.01392714926309,
          "to_longitude": 105.81626688290832,
          "from_longitude": 105.81625550350381
        }
      },
      {
        "id": 10140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81653018978602,
            21.01371649321702,
            105.81763342145385,
            21.013758160479966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81653018978602,
              21.01371649321702
            ],
            [
              105.81653390391104,
              21.01372146391986
            ],
            [
              105.81656133583607,
              21.013758160479966
            ],
            [
              105.81686960301545,
              21.013743690510143
            ],
            [
              105.81763342145385,
              21.01371982363825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A33_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10148",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 118,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01371649321702,
          "to_latitude": 21.01371982363825,
          "to_longitude": 105.81763342145385,
          "from_longitude": 105.81653018978602
        }
      },
      {
        "id": 10141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81654299926927,
            21.013694585980208,
            105.8176196322236,
            21.01374206562055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81654299926927,
              21.01370721068928
            ],
            [
              105.81654704557727,
              21.013712946638062
            ],
            [
              105.81656759694904,
              21.01374206562055
            ],
            [
              105.81705501158024,
              21.0137217694861
            ],
            [
              105.8176196322236,
              21.013694585980208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A32_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10149",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 114,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01370721068928,
          "to_latitude": 21.013694585980208,
          "to_longitude": 105.8176196322236,
          "from_longitude": 105.81654299926927
        }
      },
      {
        "id": 10142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81712770100015,
            21.01332508256981,
            105.81770702338163,
            21.013774184741955
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81760723352437,
                21.013512018344883
              ],
              [
                105.81741007048639,
                21.01332508256981
              ]
            ],
            [
              [
                105.81760723352437,
                21.013512018344883
              ],
              [
                105.81712770100015,
                21.013535183870456
              ]
            ],
            [
              [
                105.81770702338163,
                21.013774184741955
              ],
              [
                105.81770053689537,
                21.013766911514196
              ],
              [
                105.81765005761225,
                21.013670693009367
              ],
              [
                105.81760723352437,
                21.013512018344883
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A8_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10150",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 128,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81741007048639,
            21.01332508256981
          ],
          "to_latitude": [
            105.81770053689537,
            21.013766911514196
          ],
          "to_longitude": [
            105.81770702338163,
            21.013774184741955
          ],
          "from_longitude": [
            105.81760723352437,
            21.013512018344883
          ]
        }
      },
      {
        "id": 10143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81771954790743,
            21.013612491789274,
            105.81822027449951,
            21.013946233826367
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81798985735065,
                21.013856087423513
              ],
              [
                105.81802606151139,
                21.013825053686574
              ],
              [
                105.81798728842769,
                21.013780832915767
              ],
              [
                105.81822027449951,
                21.013612491789274
              ]
            ],
            [
              [
                105.81798985735065,
                21.013856087423513
              ],
              [
                105.81805740456015,
                21.0139361038512
              ]
            ],
            [
              [
                105.81771954790743,
                21.01376540298197
              ],
              [
                105.81773251321516,
                21.013779601729887
              ],
              [
                105.81788469065012,
                21.013946233826367
              ],
              [
                105.81798985735065,
                21.013856087423513
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A7_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10151",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81802606151139,
            21.013825053686574
          ],
          "to_latitude": [
            105.81773251321516,
            21.013779601729887
          ],
          "to_longitude": [
            105.81771954790743,
            21.01376540298197
          ],
          "from_longitude": [
            105.81798985735065,
            21.013856087423513
          ]
        }
      },
      {
        "id": 10144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81770055891984,
            21.01377871659367,
            105.8178867927393,
            21.01400362293159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81770055891984,
              21.01377871659367
            ],
            [
              105.81771206623735,
              21.013792616156078
            ],
            [
              105.8178867927393,
              21.01400362293159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8D_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10152",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01377871659367,
          "to_latitude": 21.01400362293159,
          "to_longitude": 105.8178867927393,
          "from_longitude": 105.81770055891984
        }
      },
      {
        "id": 10145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81722949699758,
            21.013835346037272,
            105.81766658737205,
            21.014176158245963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81722949699758,
              21.014176158245963
            ],
            [
              105.81742222224935,
              21.014028333277793
            ],
            [
              105.81753220163097,
              21.013943976297238
            ],
            [
              105.81756728177841,
              21.013917068580493
            ],
            [
              105.81758286816277,
              21.013905113530047
            ],
            [
              105.81766658737205,
              21.013835346037272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10153",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014176158245963,
          "to_latitude": 21.013835346037272,
          "to_longitude": 105.81766658737205,
          "from_longitude": 105.81722949699758
        }
      },
      {
        "id": 10146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81741013397033,
            21.014015499586005,
            105.81742222224935,
            21.014028333277793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81742222224935,
              21.014028333277793
            ],
            [
              105.81741262535813,
              21.01401814581553
            ],
            [
              105.81741013397033,
              21.014015499586005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10154",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014028333277793,
          "to_latitude": 21.014015499586005,
          "to_longitude": 105.81741013397033,
          "from_longitude": 105.81742222224935
        }
      },
      {
        "id": 10147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743379285331,
            21.01391104903076,
            105.8177286507898,
            21.014091885953544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743379285331,
              21.01399594755638
            ],
            [
              105.8174378048784,
              21.013999797707967
            ],
            [
              105.8174429221433,
              21.014004703841785
            ],
            [
              105.81756624567943,
              21.01391104903076
            ],
            [
              105.81756728177841,
              21.013917068580493
            ],
            [
              105.81757422123573,
              21.01391885252856
            ],
            [
              105.81767830770507,
              21.01402879629563
            ],
            [
              105.8177286507898,
              21.014091885953544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10155",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01399594755638,
          "to_latitude": 21.014091885953544,
          "to_longitude": 105.8177286507898,
          "from_longitude": 105.81743379285331
        }
      },
      {
        "id": 10148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743775659874,
            21.013787630848093,
            105.81764618067028,
            21.013951043516894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743775659874,
              21.013951043516894
            ],
            [
              105.81759116494334,
              21.013831719833643
            ],
            [
              105.81764618067028,
              21.013787630848093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10156",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013951043516894,
          "to_latitude": 21.013787630848093,
          "to_longitude": 105.81764618067028,
          "from_longitude": 105.81743775659874
        }
      },
      {
        "id": 10149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743775659874,
            21.013951043516894,
            105.81745948286215,
            21.01397472201945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743775659874,
              21.013951043516894
            ],
            [
              105.8174552098682,
              21.01396940718372
            ],
            [
              105.81745948286215,
              21.01397472201945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10157",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013951043516894,
          "to_latitude": 21.01397472201945,
          "to_longitude": 105.81745948286215,
          "from_longitude": 105.81743775659874
        }
      },
      {
        "id": 10150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81719529811873,
            21.013972766741933,
            105.81741657594053,
            21.014152966848823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81741657594053,
              21.013972766741933
            ],
            [
              105.81732888311981,
              21.014044180820967
            ],
            [
              105.81723884196276,
              21.01411750381782
            ],
            [
              105.81719529811873,
              21.014152966848823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10158",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013972766741933,
          "to_latitude": 21.014152966848823,
          "to_longitude": 105.81719529811873,
          "from_longitude": 105.81741657594053
        }
      },
      {
        "id": 10151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81723884196276,
            21.01411750381782,
            105.81725928998611,
            21.014140154289844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81723884196276,
              21.01411750381782
            ],
            [
              105.81725836578168,
              21.01413913356583
            ],
            [
              105.81725928998611,
              21.014140154289844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10159",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01411750381782,
          "to_latitude": 21.014140154289844,
          "to_longitude": 105.81725928998611,
          "from_longitude": 105.81723884196276
        }
      },
      {
        "id": 10152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81683504029779,
            21.013808049748455,
            105.81719446351185,
            21.01418940525601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81719446351185,
              21.01418940525601
            ],
            [
              105.81718397979976,
              21.01417799545718
            ],
            [
              105.81688238219283,
              21.013849671709554
            ],
            [
              105.81683504029779,
              21.013808049748455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10160",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01418940525601,
          "to_latitude": 21.013808049748455,
          "to_longitude": 105.81683504029779,
          "from_longitude": 105.81719446351185
        }
      },
      {
        "id": 10153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81691733596463,
            21.014189094817954,
            105.81714099868316,
            21.01436088704563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81691733596463,
              21.01436088704563
            ],
            [
              105.81704346199977,
              21.014264010879117
            ],
            [
              105.81704520542196,
              21.01426267205237
            ],
            [
              105.81714099868316,
              21.014189094817954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10161",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01436088704563,
          "to_latitude": 21.014189094817954,
          "to_longitude": 105.81714099868316,
          "from_longitude": 105.81691733596463
        }
      },
      {
        "id": 10154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704520542196,
            21.01426267205237,
            105.81707262709565,
            21.014294619399493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81704520542196,
              21.01426267205237
            ],
            [
              105.8170651535733,
              21.014285914755842
            ],
            [
              105.81707134730392,
              21.014293128897283
            ],
            [
              105.81707262709565,
              21.014294619399493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10162",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01426267205237,
          "to_latitude": 21.014294619399493,
          "to_longitude": 105.81707262709565,
          "from_longitude": 105.81704520542196
        }
      },
      {
        "id": 10155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81667974057552,
            21.01414035031228,
            105.8169037844456,
            21.01456099721627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8167295636816,
              21.01456099721627
            ],
            [
              105.81672695784476,
              21.014558001073855
            ],
            [
              105.81671009565358,
              21.014538304041146
            ],
            [
              105.8169037844456,
              21.014390064045347
            ],
            [
              105.81667974057552,
              21.01414035031228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10163",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01456099721627,
          "to_latitude": 21.01414035031228,
          "to_longitude": 105.81667974057552,
          "from_longitude": 105.8167295636816
        }
      },
      {
        "id": 10156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8166012594325,
            21.01454512841121,
            105.81669437731044,
            21.014615829330594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8166012594325,
              21.014615829330594
            ],
            [
              105.81660566515407,
              21.014612484306316
            ],
            [
              105.81669437731044,
              21.01454512841121
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10164",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014615829330594,
          "to_latitude": 21.01454512841121,
          "to_longitude": 105.81669437731044,
          "from_longitude": 105.8166012594325
        }
      },
      {
        "id": 10157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81660566515407,
            21.014612484306316,
            105.81662654167003,
            21.014635571765936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81660566515407,
              21.014612484306316
            ],
            [
              105.81662070220544,
              21.01462911371603
            ],
            [
              105.8166260858674,
              21.014635069952654
            ],
            [
              105.81662654167003,
              21.014635571765936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DODV.10165",
          "diaChiLapD": "Ngõ 151A, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014612484306316,
          "to_latitude": 21.014635571765936,
          "to_longitude": 105.81662654167003,
          "from_longitude": 105.81660566515407
        }
      },
      {
        "id": 10158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81564913631559,
            21.014063199473117,
            105.81609202676684,
            21.014403288581637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81564913631559,
              21.014403288581637
            ],
            [
              105.81609130736389,
              21.01406375208636
            ],
            [
              105.81609202676684,
              21.014063199473117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10166",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014403288581637,
          "to_latitude": 21.014063199473117,
          "to_longitude": 105.81609202676684,
          "from_longitude": 105.81564913631559
        }
      },
      {
        "id": 10159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81612227259846,
            21.01406812601769,
            105.8163011671837,
            21.014289554885664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81612227259846,
              21.01406812601769
            ],
            [
              105.81620456489586,
              21.01416998478231
            ],
            [
              105.81621451574937,
              21.014182301330997
            ],
            [
              105.8163011671837,
              21.014289554885664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10167",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01406812601769,
          "to_latitude": 21.014289554885664,
          "to_longitude": 105.8163011671837,
          "from_longitude": 105.81612227259846
        }
      },
      {
        "id": 10160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81619095551895,
            21.01416998478231,
            105.81620456489586,
            21.014180065060305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81619095551895,
              21.014180065060305
            ],
            [
              105.81619266758861,
              21.014178796844295
            ],
            [
              105.81619721267042,
              21.01417543038773
            ],
            [
              105.81620456489586,
              21.01416998478231
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10168",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014180065060305,
          "to_latitude": 21.01416998478231,
          "to_longitude": 105.81620456489586,
          "from_longitude": 105.81619095551895
        }
      },
      {
        "id": 10161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81519154170996,
            21.01426633371136,
            105.81575016299807,
            21.014526824326385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81519154170996,
              21.014269722018295
            ],
            [
              105.81537803932397,
              21.014492928232922
            ],
            [
              105.81540636011417,
              21.014526824326385
            ],
            [
              105.81575016299807,
              21.01426633371136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18'_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10169",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014269722018295,
          "to_latitude": 21.01426633371136,
          "to_longitude": 105.81575016299807,
          "from_longitude": 105.81519154170996
        }
      },
      {
        "id": 10162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81535097176305,
            21.014492928232922,
            105.81537803932397,
            21.01451297503152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81537803932397,
              21.014492928232922
            ],
            [
              105.81535097176305,
              21.01451297503152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18'_Ngõ 151, Thái Hà",
          "maTaiSan": "04.O11.DODV.10170",
          "diaChiLapD": "Ngõ 151, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014492928232922,
          "to_latitude": 21.01451297503152,
          "to_longitude": 105.81535097176305,
          "from_longitude": 105.81537803932397
        }
      },
      {
        "id": 10163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81547981133536,
            21.015007694098546,
            105.81573830062294,
            21.015265185351687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81547981133536,
              21.015265185351687
            ],
            [
              105.81565430099509,
              21.01525827287987
            ],
            [
              105.8156350799366,
              21.015094352401015
            ],
            [
              105.81573830062294,
              21.015007694098546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2'_Thái Hà",
          "maTaiSan": "04.O11.DODV.10171",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015265185351687,
          "to_latitude": 21.015007694098546,
          "to_longitude": 105.81573830062294,
          "from_longitude": 105.81547981133536
        }
      },
      {
        "id": 10164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81553416710305,
            21.014837011657395,
            105.81574365384895,
            21.014965035086323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81553416710305,
              21.014965035086323
            ],
            [
              105.81562236231589,
              21.01495125988283
            ],
            [
              105.81562860122126,
              21.014945383379334
            ],
            [
              105.81563320455041,
              21.014941046591183
            ],
            [
              105.81574365384895,
              21.014837011657395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10172",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014965035086323,
          "to_latitude": 21.014837011657395,
          "to_longitude": 105.81574365384895,
          "from_longitude": 105.81553416710305
        }
      },
      {
        "id": 10165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8156170263786,
            21.01493090051339,
            105.81562860122126,
            21.014945383379334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81562860122126,
              21.014945383379334
            ],
            [
              105.81562447917906,
              21.014940225838387
            ],
            [
              105.81561802687335,
              21.014932153913474
            ],
            [
              105.8156170263786,
              21.01493090051339
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10173",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014945383379334,
          "to_latitude": 21.01493090051339,
          "to_longitude": 105.8156170263786,
          "from_longitude": 105.81562860122126
        }
      },
      {
        "id": 10166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8154644030785,
            21.014949475853538,
            105.81551930027881,
            21.015265185351687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81551706779058,
              21.014949475853538
            ],
            [
              105.81551821627176,
              21.014958663401863
            ],
            [
              105.81551930027881,
              21.014967315645084
            ],
            [
              105.8154644030785,
              21.014981558942097
            ],
            [
              105.81547981133536,
              21.015265185351687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2'_Thái Hà",
          "maTaiSan": "04.O11.DODV.10174",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014949475853538,
          "to_latitude": 21.015265185351687,
          "to_longitude": 105.81547981133536,
          "from_longitude": 105.81551706779058
        }
      },
      {
        "id": 10167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81555959372946,
            21.014719183921517,
            105.81583762026516,
            21.01493244035329
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81555959372946,
              21.01493244035329
            ],
            [
              105.81558585028615,
              21.014928644672025
            ],
            [
              105.81568248683071,
              21.014848248432777
            ],
            [
              105.81571759732883,
              21.014819037708566
            ],
            [
              105.81583762026516,
              21.014719183921517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10175",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01493244035329,
          "to_latitude": 21.014719183921517,
          "to_longitude": 105.81583762026516,
          "from_longitude": 105.81555959372946
        }
      },
      {
        "id": 10168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81568248683071,
            21.014848248432777,
            105.81569804670335,
            21.014864804967456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81568248683071,
              21.014848248432777
            ],
            [
              105.81569172483977,
              21.014858078229427
            ],
            [
              105.81569720785991,
              21.014863913890416
            ],
            [
              105.81569804670335,
              21.014864804967456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10176",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014848248432777,
          "to_latitude": 21.014864804967456,
          "to_longitude": 105.81569804670335,
          "from_longitude": 105.81568248683071
        }
      },
      {
        "id": 10169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81563905682926,
            21.0144370119497,
            105.81586414939737,
            21.014719705223772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81586414939737,
              21.014719705223772
            ],
            [
              105.81586407870637,
              21.014719618852887
            ],
            [
              105.81563905682926,
              21.0144370119497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22'_Ngõ 151B, Thái Hà",
          "maTaiSan": "04.O11.DODV.10177",
          "diaChiLapD": "Ngõ 151B, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014719705223772,
          "to_latitude": 21.0144370119497,
          "to_longitude": 105.81563905682926,
          "from_longitude": 105.81586414939737
        }
      },
      {
        "id": 10170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81576567692217,
            21.01460233217114,
            105.81608168040276,
            21.01491649210477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81587136361061,
              21.01491649210477
            ],
            [
              105.81576567692217,
              21.014815000076666
            ],
            [
              105.8158837246148,
              21.01471861780127
            ],
            [
              105.8158984738581,
              21.014706574785475
            ],
            [
              105.81602808434597,
              21.01460233217114
            ],
            [
              105.81608168040276,
              21.01466563343417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10178",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01491649210477,
          "to_latitude": 21.01466563343417,
          "to_longitude": 105.81608168040276,
          "from_longitude": 105.81587136361061
        }
      },
      {
        "id": 10171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81587536504429,
            21.01471023890257,
            105.8158837246148,
            21.01471861780127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8158837246148,
              21.01471861780127
            ],
            [
              105.81587572678787,
              21.014710602975132
            ],
            [
              105.81587536504429,
              21.01471023890257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21'_Thái Hà",
          "maTaiSan": "04.O11.DODV.10179",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01471861780127,
          "to_latitude": 21.01471023890257,
          "to_longitude": 105.81587536504429,
          "from_longitude": 105.8158837246148
        }
      },
      {
        "id": 10172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81581654050309,
            21.014309740705713,
            105.81603861847648,
            21.014572448933375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81603861847648,
              21.014572448933375
            ],
            [
              105.81603797078833,
              21.014571685199503
            ],
            [
              105.81581654050309,
              21.014309740705713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23'_Ngõ 151B, Thái Hà",
          "maTaiSan": "04.O11.DODV.10180",
          "diaChiLapD": "Ngõ 151B, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014572448933375,
          "to_latitude": 21.014309740705713,
          "to_longitude": 105.81581654050309,
          "from_longitude": 105.81603861847648
        }
      },
      {
        "id": 10173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81605008204569,
            21.014338073181108,
            105.81628825948079,
            21.014529697466035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81605008204569,
              21.014529697466035
            ],
            [
              105.8161640308614,
              21.014440269647526
            ],
            [
              105.81619838865605,
              21.01441330515347
            ],
            [
              105.81627807189358,
              21.014346600658484
            ],
            [
              105.81628825948079,
              21.014338073181108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B25'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10181",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014529697466035,
          "to_latitude": 21.014338073181108,
          "to_longitude": 105.81628825948079,
          "from_longitude": 105.81605008204569
        }
      },
      {
        "id": 10174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81627807189358,
            21.014346600658484,
            105.81629011444856,
            21.014361650269425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81627807189358,
              21.014346600658484
            ],
            [
              105.81628249511355,
              21.01435212796544
            ],
            [
              105.81628825490209,
              21.01435932796019
            ],
            [
              105.81629011444856,
              21.014361650269425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B25'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10182",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014346600658484,
          "to_latitude": 21.014361650269425,
          "to_longitude": 105.81629011444856,
          "from_longitude": 105.81627807189358
        }
      },
      {
        "id": 10175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81605307164403,
            21.01437749439097,
            105.81633564853504,
            21.014580251240428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81605307164403,
              21.014580251240428
            ],
            [
              105.81606130173842,
              21.014573317426755
            ],
            [
              105.81612188653484,
              21.014522279867535
            ],
            [
              105.81629302439924,
              21.01437810937693
            ],
            [
              105.81629375404472,
              21.01437749439097
            ],
            [
              105.81629453175425,
              21.014378464339227
            ],
            [
              105.81633564853504,
              21.01442971762764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10183",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014580251240428,
          "to_latitude": 21.01442971762764,
          "to_longitude": 105.81633564853504,
          "from_longitude": 105.81605307164403
        }
      },
      {
        "id": 10176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81605139281424,
            21.014561461315093,
            105.81606130173842,
            21.014573317426755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81606130173842,
              21.014573317426755
            ],
            [
              105.81605837476134,
              21.014569815200044
            ],
            [
              105.81605311917794,
              21.014563526845777
            ],
            [
              105.81605151772582,
              21.01456161245823
            ],
            [
              105.81605139281424,
              21.014561461315093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24'_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DODV.10184",
          "diaChiLapD": "Ngõ 165, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014573317426755,
          "to_latitude": 21.014561461315093,
          "to_longitude": 105.81605139281424,
          "from_longitude": 105.81606130173842
        }
      },
      {
        "id": 10177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81412610134356,
            21.013465530177857,
            105.81460374391511,
            21.014239050823214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81456361195012,
              21.014239050823214
            ],
            [
              105.81456287963468,
              21.014237758196934
            ],
            [
              105.8145216037696,
              21.01418490108895
            ],
            [
              105.81460374391511,
              21.014125718519608
            ],
            [
              105.81412610134356,
              21.013465530177857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10185",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014239050823214,
          "to_latitude": 21.013465530177857,
          "to_longitude": 105.81412610134356,
          "from_longitude": 105.81456361195012
        }
      },
      {
        "id": 10178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81443636620905,
            21.00922516938009,
            105.81460308479002,
            21.00956478101133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81460308479002,
              21.00922516938009
            ],
            [
              105.81459153776862,
              21.009250789638113
            ],
            [
              105.81452677478013,
              21.009394273120467
            ],
            [
              105.81443636620905,
              21.009517692161882
            ],
            [
              105.81453099216705,
              21.009542480879755
            ],
            [
              105.81457168334003,
              21.00956478101133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C3_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10186",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00922516938009,
          "to_latitude": 21.00956478101133,
          "to_longitude": 105.81457168334003,
          "from_longitude": 105.81460308479002
        }
      },
      {
        "id": 10179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81434455788163,
            21.009477741688787,
            105.81489534924326,
            21.010181180344805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81489534924326,
              21.009477741688787
            ],
            [
              105.81487065468271,
              21.00949179224294
            ],
            [
              105.81484828266858,
              21.009504521990817
            ],
            [
              105.81480734848378,
              21.00952781264849
            ],
            [
              105.81472750944508,
              21.009541577150756
            ],
            [
              105.81465054661345,
              21.009613653832933
            ],
            [
              105.81469630847023,
              21.009654285972843
            ],
            [
              105.81454889781962,
              21.00981354800977
            ],
            [
              105.81444836276182,
              21.009915291980228
            ],
            [
              105.81444378552355,
              21.009943375393444
            ],
            [
              105.81440091469028,
              21.010018916161524
            ],
            [
              105.81434455788163,
              21.010071412354858
            ],
            [
              105.81441845084218,
              21.010181180344805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C5_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10187",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009477741688787,
          "to_latitude": 21.010181180344805,
          "to_longitude": 105.81441845084218,
          "from_longitude": 105.81489534924326
        }
      },
      {
        "id": 10180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81444836276182,
            21.009915291980228,
            105.81462378147768,
            21.010064148889438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81444836276182,
              21.009915291980228
            ],
            [
              105.81462378147768,
              21.010064148889438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C5_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10188",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009915291980228,
          "to_latitude": 21.010064148889438,
          "to_longitude": 105.81462378147768,
          "from_longitude": 105.81444836276182
        }
      },
      {
        "id": 10181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81422464719786,
            21.00903488184172,
            105.81457547323188,
            21.00926126992402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81422464719786,
              21.00903488184172
            ],
            [
              105.81425187530995,
              21.009051482066734
            ],
            [
              105.81451432138901,
              21.0092114686706
            ],
            [
              105.81454169690133,
              21.009230402987622
            ],
            [
              105.81457547323188,
              21.00926126992402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10189",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00903488184172,
          "to_latitude": 21.00926126992402,
          "to_longitude": 105.81457547323188,
          "from_longitude": 105.81422464719786
        }
      },
      {
        "id": 10182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81417096115453,
            21.009070936368804,
            105.81419176155362,
            21.00909510208989
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81417096115453,
              21.009070936368804
            ],
            [
              105.81417454662933,
              21.009075102066674
            ],
            [
              105.81418480260524,
              21.009087018971666
            ],
            [
              105.81419164442683,
              21.009094966262747
            ],
            [
              105.81419176155362,
              21.00909510208989
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Đường Láng",
          "maTaiSan": "04.O11.DODV.10190",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009070936368804,
          "to_latitude": 21.00909510208989,
          "to_longitude": 105.81419176155362,
          "from_longitude": 105.81417096115453
        }
      },
      {
        "id": 10183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409949123652,
            21.00906419061206,
            105.81424127970955,
            21.00914293052573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424127970955,
              21.00906419061206
            ],
            [
              105.81419176155362,
              21.00909510208989
            ],
            [
              105.81418070255549,
              21.009102005991313
            ],
            [
              105.8141639693011,
              21.009112452405965
            ],
            [
              105.81409949123652,
              21.00914293052573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Đường Láng",
          "maTaiSan": "04.O11.DODV.10191",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00906419061206,
          "to_latitude": 21.00914293052573,
          "to_longitude": 105.81409949123652,
          "from_longitude": 105.81424127970955
        }
      },
      {
        "id": 10184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81402043261882,
            21.009133277804782,
            105.81420771479063,
            21.009339220866284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81407813858003,
              21.009133277804782
            ],
            [
              105.81408486422464,
              21.0091478246451
            ],
            [
              105.81408583752619,
              21.00914992631832
            ],
            [
              105.81405301758318,
              21.009161458585258
            ],
            [
              105.81402043261882,
              21.00917747515588
            ],
            [
              105.81407317579986,
              21.00924969521275
            ],
            [
              105.81420771479063,
              21.009339220866284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10192",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009133277804782,
          "to_latitude": 21.009339220866284,
          "to_longitude": 105.81420771479063,
          "from_longitude": 105.81407813858003
        }
      },
      {
        "id": 10185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81364353825302,
            21.009422049261726,
            105.81366089525027,
            21.00943065963322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81364353825302,
              21.009422049261726
            ],
            [
              105.81364480025596,
              21.00942364374904
            ],
            [
              105.81365036153458,
              21.00943065963322
            ],
            [
              105.81366089525027,
              21.00942426318168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Đường Láng",
          "maTaiSan": "04.O11.DODV.10193",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009422049261726,
          "to_latitude": 21.00942426318168,
          "to_longitude": 105.81366089525027,
          "from_longitude": 105.81364353825302
        }
      },
      {
        "id": 10186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81366089525027,
            21.009282409043674,
            105.81387589164515,
            21.00954208233125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81387589164515,
              21.009282409043674
            ],
            [
              105.81370824837153,
              21.009389623742223
            ],
            [
              105.81370781147561,
              21.009389942864637
            ],
            [
              105.81366385961202,
              21.009422094807054
            ],
            [
              105.81366089525027,
              21.00942426318168
            ],
            [
              105.8136696104124,
              21.009431244113987
            ],
            [
              105.81380799807347,
              21.00954208233125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Đường Láng",
          "maTaiSan": "04.O11.DODV.10194",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009282409043674,
          "to_latitude": 21.00954208233125,
          "to_longitude": 105.81380799807347,
          "from_longitude": 105.81387589164515
        }
      },
      {
        "id": 10187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81389197180874,
            21.009258308058126,
            105.81445540264582,
            21.00971044530758
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81389197180874,
                21.009258308058126
              ],
              [
                105.81389978683112,
                21.009263374826048
              ],
              [
                105.81410329787894,
                21.009395282546798
              ]
            ],
            [
              [
                105.81410329787894,
                21.009395282546798
              ],
              [
                105.81409605172016,
                21.00940713054083
              ],
              [
                105.8140582296122,
                21.00946897219197
              ],
              [
                105.8140581925821,
                21.00952224931393
              ]
            ],
            [
              [
                105.81410329787894,
                21.009395282546798
              ],
              [
                105.81411749288287,
                21.009404190923405
              ],
              [
                105.81420834345916,
                21.00942476174391
              ],
              [
                105.81429604856969,
                21.009485020982623
              ],
              [
                105.81425017147117,
                21.00956669728133
              ]
            ],
            [
              [
                105.81425017147117,
                21.00956669728133
              ],
              [
                105.81427726798103,
                21.009580298505856
              ],
              [
                105.81440163631791,
                21.009642724315754
              ],
              [
                105.81445540264582,
                21.0096644949292
              ]
            ],
            [
              [
                105.81425017147117,
                21.00956669728133
              ],
              [
                105.8142366307923,
                21.009590805115927
              ],
              [
                105.81416520470351,
                21.00971044530758
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Đường Láng",
          "maTaiSan": "04.O11.DODV.10195",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 108,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81389978683112,
            21.009263374826048
          ],
          "to_latitude": [
            105.8142366307923,
            21.009590805115927
          ],
          "to_longitude": [
            105.81425017147117,
            21.00956669728133
          ],
          "from_longitude": [
            105.81389197180874,
            21.009258308058126
          ]
        }
      },
      {
        "id": 10188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81295958768844,
            21.009699259937392,
            105.81320349558354,
            21.010008330477934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81309346763653,
              21.010008330477934
            ],
            [
              105.81295958768844,
              21.00986290448285
            ],
            [
              105.81309551782725,
              21.009769129432126
            ],
            [
              105.81309555044788,
              21.009769113921365
            ],
            [
              105.81320349558354,
              21.009699259937392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Đường Láng",
          "maTaiSan": "04.O11.DODV.10196",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010008330477934,
          "to_latitude": 21.009699259937392,
          "to_longitude": 105.81320349558354,
          "from_longitude": 105.81309346763653
        }
      },
      {
        "id": 10189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81323853866643,
            21.00946777176799,
            105.81357870686392,
            21.0096815156365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81323853866643,
              21.0096815156365
            ],
            [
              105.81337576842948,
              21.00959438643852
            ],
            [
              105.81340254411296,
              21.00957738704295
            ],
            [
              105.81340677154479,
              21.00957470231096
            ],
            [
              105.81354394922941,
              21.00948953594678
            ],
            [
              105.81357870686392,
              21.00946777176799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Đường Láng",
          "maTaiSan": "04.O11.DODV.10197",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0096815156365,
          "to_latitude": 21.00946777176799,
          "to_longitude": 105.81357870686392,
          "from_longitude": 105.81323853866643
        }
      },
      {
        "id": 10190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81308340621649,
            21.009752567892786,
            105.81309551782725,
            21.009769129432126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81309551782725,
              21.009769129432126
            ],
            [
              105.81309457297733,
              21.009767838716662
            ],
            [
              105.81309023341771,
              21.009761904464142
            ],
            [
              105.81308676409537,
              21.00975716012192
            ],
            [
              105.81308340621649,
              21.009752567892786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Đường Láng",
          "maTaiSan": "04.O11.DODV.10198",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009769129432126,
          "to_latitude": 21.009752567892786,
          "to_longitude": 105.81308340621649,
          "from_longitude": 105.81309551782725
        }
      },
      {
        "id": 10191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81339516147867,
            21.009568685399323,
            105.81340254411296,
            21.00957738704295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81339516147867,
              21.009568685399323
            ],
            [
              105.81339705892928,
              21.009570921756456
            ],
            [
              105.81339947430864,
              21.009573768930245
            ],
            [
              105.81340109005139,
              21.00957567424568
            ],
            [
              105.81340254411296,
              21.00957738704295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Đường Láng",
          "maTaiSan": "04.O11.DODV.10199",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009568685399323,
          "to_latitude": 21.00957738704295,
          "to_longitude": 105.81340254411296,
          "from_longitude": 105.81339516147867
        }
      },
      {
        "id": 10192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81257450515575,
            21.00998462827535,
            105.81292514509256,
            21.010182344160686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81292514509256,
              21.010182344160686
            ],
            [
              105.81272125357361,
              21.009990514850923
            ],
            [
              105.81271449412854,
              21.00998462827535
            ],
            [
              105.81270745403843,
              21.009989084107215
            ],
            [
              105.81257450515575,
              21.01007323065348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Đường Láng",
          "maTaiSan": "04.O11.DODV.10200",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010182344160686,
          "to_latitude": 21.01007323065348,
          "to_longitude": 105.81257450515575,
          "from_longitude": 105.81292514509256
        }
      },
      {
        "id": 10193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81243494355657,
            21.01007323065348,
            105.81257450515575,
            21.0103115538107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81257450515575,
              21.01007323065348
            ],
            [
              105.81246014472524,
              21.010145612309003
            ],
            [
              105.81243494355657,
              21.010161561717453
            ],
            [
              105.8125046186135,
              21.010244585331677
            ],
            [
              105.81257339206162,
              21.0103115538107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Đường Láng",
          "maTaiSan": "04.O11.DODV.10201",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01007323065348,
          "to_latitude": 21.0103115538107,
          "to_longitude": 105.81257339206162,
          "from_longitude": 105.81257450515575
        }
      },
      {
        "id": 10194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81269909180659,
            21.009979243624,
            105.81270745403843,
            21.009989084107215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81270745403843,
              21.009989084107215
            ],
            [
              105.81270299699854,
              21.009983835053305
            ],
            [
              105.81269909180659,
              21.009979243624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Đường Láng",
          "maTaiSan": "04.O11.DODV.10202",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009989084107215,
          "to_latitude": 21.009979243624,
          "to_longitude": 105.81269909180659,
          "from_longitude": 105.81270745403843
        }
      },
      {
        "id": 10195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81244577945704,
            21.01012870470862,
            105.81246014472524,
            21.010145612309003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81246014472524,
              21.010145612309003
            ],
            [
              105.8124600188738,
              21.010145463877127
            ],
            [
              105.81245383172762,
              21.010138149263533
            ],
            [
              105.81244577945704,
              21.01012870470862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Đường Láng",
          "maTaiSan": "04.O11.DODV.10203",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010145612309003,
          "to_latitude": 21.01012870470862,
          "to_longitude": 105.81244577945704,
          "from_longitude": 105.81246014472524
        }
      },
      {
        "id": 10196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8120743625914,
            21.01016946874907,
            105.81242053085457,
            21.010388851673472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8120743625914,
              21.010388851673472
            ],
            [
              105.81235949000215,
              21.010208152813785
            ],
            [
              105.81238983212894,
              21.010188923635692
            ],
            [
              105.81242053085457,
              21.01016946874907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Đường Láng",
          "maTaiSan": "04.O11.DODV.10204",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010388851673472,
          "to_latitude": 21.01016946874907,
          "to_longitude": 105.81242053085457,
          "from_longitude": 105.8120743625914
        }
      },
      {
        "id": 10197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81234792195724,
            21.010190711591413,
            105.81235949000215,
            21.010208152813785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81235949000215,
              21.010208152813785
            ],
            [
              105.81235795284299,
              21.010205837047348
            ],
            [
              105.81235213389678,
              21.010197061957594
            ],
            [
              105.81234792195724,
              21.010190711591413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Đường Láng",
          "maTaiSan": "04.O11.DODV.10205",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010208152813785,
          "to_latitude": 21.010190711591413,
          "to_longitude": 105.81234792195724,
          "from_longitude": 105.81235949000215
        }
      },
      {
        "id": 10198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81204088286475,
            21.010386794921953,
            105.81226834105485,
            21.010692018617863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81204088286475,
              21.010386794921953
            ],
            [
              105.81205657726186,
              21.010404730410887
            ],
            [
              105.81217818374945,
              21.01056861356903
            ],
            [
              105.81226834105485,
              21.010692018617863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10206",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010386794921953,
          "to_latitude": 21.010692018617863,
          "to_longitude": 105.81226834105485,
          "from_longitude": 105.81204088286475
        }
      },
      {
        "id": 10199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81188525413977,
            21.010482279021158,
            105.81209820206992,
            21.010800709641977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81188525413977,
              21.010482279021158
            ],
            [
              105.81188983442789,
              21.01048897538456
            ],
            [
              105.81189340903369,
              21.010494337057636
            ],
            [
              105.81198366575714,
              21.01062976467207
            ],
            [
              105.81209820206992,
              21.010800709641977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Đường Láng",
          "maTaiSan": "04.O11.DODV.10207",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010482279021158,
          "to_latitude": 21.010800709641977,
          "to_longitude": 105.81209820206992,
          "from_longitude": 105.81188525413977
        }
      },
      {
        "id": 10200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81178605833384,
            21.010537207048404,
            105.81179288089614,
            21.010546938405415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81178605833384,
              21.010537207048404
            ],
            [
              105.81178620665528,
              21.01053741950284
            ],
            [
              105.81178820748856,
              21.010540275903097
            ],
            [
              105.81179288089614,
              21.010546938405415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Đường Láng",
          "maTaiSan": "04.O11.DODV.10208",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010537207048404,
          "to_latitude": 21.010546938405415,
          "to_longitude": 105.81179288089614,
          "from_longitude": 105.81178605833384
        }
      },
      {
        "id": 10201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81145301999756,
            21.01041212568153,
            105.8120220174849,
            21.010759386635502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8120220174849,
              21.01041212568153
            ],
            [
              105.81189780914013,
              21.01048800068665
            ],
            [
              105.81189340903369,
              21.010494337057636
            ],
            [
              105.81189275235596,
              21.01049528225864
            ],
            [
              105.81188598518676,
              21.01049525493333
            ],
            [
              105.81179332084655,
              21.01054664907973
            ],
            [
              105.81179288089614,
              21.010546938405415
            ],
            [
              105.81166597075583,
              21.010630539711347
            ],
            [
              105.81166556327939,
              21.01063078643004
            ],
            [
              105.81145301999756,
              21.010759386635502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Đường Láng",
          "maTaiSan": "04.O11.DODV.10209",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01041212568153,
          "to_latitude": 21.010759386635502,
          "to_longitude": 105.81145301999756,
          "from_longitude": 105.8120220174849
        }
      },
      {
        "id": 10202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81138458404784,
            21.01074943874397,
            105.81179040678359,
            21.01116381041147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81138458404784,
              21.01074943874397
            ],
            [
              105.81140631541163,
              21.01077361030336
            ],
            [
              105.81141164471651,
              21.01077953987959
            ],
            [
              105.81162448388862,
              21.011016265527044
            ],
            [
              105.81179040678359,
              21.01116381041147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Đường Láng",
          "maTaiSan": "04.O11.DODV.10210",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01074943874397,
          "to_latitude": 21.01116381041147,
          "to_longitude": 105.81179040678359,
          "from_longitude": 105.81138458404784
        }
      },
      {
        "id": 10203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81143852442933,
            21.010848923302582,
            105.81180126108985,
            21.011203825861667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81143852442933,
              21.010848923302582
            ],
            [
              105.81160982308867,
              21.011022609283515
            ],
            [
              105.81180126108985,
              21.011203825861667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Đường Láng",
          "maTaiSan": "04.O11.DODV.10211",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010848923302582,
          "to_latitude": 21.011203825861667,
          "to_longitude": 105.81180126108985,
          "from_longitude": 105.81143852442933
        }
      },
      {
        "id": 10204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81136098039067,
            21.010915511493987,
            105.8116115125161,
            21.01133526385642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81136098039067,
              21.010915511493987
            ],
            [
              105.81138939845522,
              21.011006818891513
            ],
            [
              105.81145425180262,
              21.011119993127124
            ],
            [
              105.8114775709451,
              21.01115191493631
            ],
            [
              105.81148454449891,
              21.01116146132601
            ],
            [
              105.8116115125161,
              21.01133526385642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10212",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010915511493987,
          "to_latitude": 21.01133526385642,
          "to_longitude": 105.8116115125161,
          "from_longitude": 105.81136098039067
        }
      },
      {
        "id": 10205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81146834816202,
            21.01116146132601,
            105.81148454449891,
            21.011172194860592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81148454449891,
              21.01116146132601
            ],
            [
              105.81147292680112,
              21.011169159853672
            ],
            [
              105.81146964306029,
              21.011171336037226
            ],
            [
              105.81146834816202,
              21.011172194860592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10213",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01116146132601,
          "to_latitude": 21.011172194860592,
          "to_longitude": 105.81146834816202,
          "from_longitude": 105.81148454449891
        }
      },
      {
        "id": 10206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81164817155211,
            21.011392257968065,
            105.81204675114667,
            21.01192667096829
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81204675114667,
              21.01192667096829
            ],
            [
              105.81202115648885,
              21.011890954803164
            ],
            [
              105.81200264115687,
              21.011865118689496
            ],
            [
              105.81192488902806,
              21.011756620838472
            ],
            [
              105.81190969727508,
              21.011740406305844
            ],
            [
              105.8119001483925,
              21.01172761879375
            ],
            [
              105.81187136043975,
              21.011688383505792
            ],
            [
              105.81186394583769,
              21.01167822233298
            ],
            [
              105.81179537996204,
              21.011584258698242
            ],
            [
              105.81164817155211,
              21.011392257968065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10214",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01192667096829,
          "to_latitude": 21.011392257968065,
          "to_longitude": 105.81164817155211,
          "from_longitude": 105.81204675114667
        }
      },
      {
        "id": 10207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81184972397146,
            21.01167822233298,
            105.81186394583769,
            21.01168783099558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81184972397146,
              21.01168783099558
            ],
            [
              105.8118558213786,
              21.011683710276692
            ],
            [
              105.81185584821864,
              21.011683692987287
            ],
            [
              105.81186394583769,
              21.01167822233298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10215",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01168783099558,
          "to_latitude": 21.01167822233298,
          "to_longitude": 105.81186394583769,
          "from_longitude": 105.81184972397146
        }
      },
      {
        "id": 10208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81204766567157,
            21.011807223508534,
            105.81234488507673,
            21.011963232014544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81204766567157,
              21.011963232014544
            ],
            [
              105.8120516635345,
              21.011961657574957
            ],
            [
              105.8121387746138,
              21.011920762717576
            ],
            [
              105.8121226813709,
              21.01189634938445
            ],
            [
              105.81225017415906,
              21.011807223508534
            ],
            [
              105.81234488507673,
              21.011920683868766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10216",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011963232014544,
          "to_latitude": 21.011920683868766,
          "to_longitude": 105.81234488507673,
          "from_longitude": 105.81204766567157
        }
      },
      {
        "id": 10209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81169749620025,
            21.01130702884321,
            105.8121574146,
            21.01173578101168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81169749620025,
              21.01130702884321
            ],
            [
              105.81169777649161,
              21.01130727589409
            ],
            [
              105.81200171826382,
              21.011584237900923
            ],
            [
              105.8121574146,
              21.01173578101168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10217",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01130702884321,
          "to_latitude": 21.01173578101168,
          "to_longitude": 105.8121574146,
          "from_longitude": 105.81169749620025
        }
      },
      {
        "id": 10210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81178806019574,
            21.010872103523624,
            105.81215687738126,
            21.011236075402554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81178806019574,
              21.011236075402554
            ],
            [
              105.81192015367134,
              21.01108797498208
            ],
            [
              105.81192405583064,
              21.011083650670646
            ],
            [
              105.81192454772419,
              21.011076606751097
            ],
            [
              105.81193118720817,
              21.011072409378652
            ],
            [
              105.81194070727065,
              21.011063526761056
            ],
            [
              105.81202095923186,
              21.01098864729632
            ],
            [
              105.812049967961,
              21.010962948386997
            ],
            [
              105.81215687738126,
              21.010872103523624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24'_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10218",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011236075402554,
          "to_latitude": 21.010872103523624,
          "to_longitude": 105.81215687738126,
          "from_longitude": 105.81178806019574
        }
      },
      {
        "id": 10211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81193445037606,
            21.011057190659404,
            105.81194070727065,
            21.011063526761056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81193445037606,
              21.011057190659404
            ],
            [
              105.81193656760347,
              21.01105933475772
            ],
            [
              105.81194070727065,
              21.011063526761056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24'_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10219",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011057190659404,
          "to_latitude": 21.011063526761056,
          "to_longitude": 105.81194070727065,
          "from_longitude": 105.81193445037606
        }
      },
      {
        "id": 10212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81207940074519,
            21.011126549888512,
            105.81236562145865,
            21.01140271495338
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81229275636971,
                21.01119484156754
              ],
              [
                105.81218081159275,
                21.011126549888512
              ],
              [
                105.8120845326378,
                21.01121749306787
              ],
              [
                105.81208226583917,
                21.011219527118826
              ]
            ],
            [
              [
                105.81208226583917,
                21.011219527118826
              ],
              [
                105.81207940074519,
                21.011222096930144
              ]
            ],
            [
              [
                105.81208226583917,
                21.011219527118826
              ],
              [
                105.81227596043345,
                21.01140271495338
              ],
              [
                105.81236562145865,
                21.011313732559636
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B27_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10220",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81218081159275,
            21.011126549888512
          ],
          "to_latitude": [
            105.81227596043345,
            21.01140271495338
          ],
          "to_longitude": [
            105.81208226583917,
            21.011219527118826
          ],
          "from_longitude": [
            105.81229275636971,
            21.01119484156754
          ]
        }
      },
      {
        "id": 10213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8122763562228,
            21.011418076082652,
            105.81270006292021,
            21.01183325967072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81270006292021,
              21.01183325967072
            ],
            [
              105.81254630968616,
              21.011700666568373
            ],
            [
              105.81248388359745,
              21.011634824834275
            ],
            [
              105.81248234185203,
              21.011613370577884
            ],
            [
              105.81248143782302,
              21.011600791111693
            ],
            [
              105.8122763562228,
              21.011418076082652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10221",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01183325967072,
          "to_latitude": 21.011418076082652,
          "to_longitude": 105.8122763562228,
          "from_longitude": 105.81270006292021
        }
      },
      {
        "id": 10214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81248234185203,
            21.011613171072725,
            105.81248799394523,
            21.011613370577884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81248799394523,
              21.011613171072725
            ],
            [
              105.81248447400996,
              21.011613295364487
            ],
            [
              105.81248234185203,
              21.011613370577884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10222",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011613171072725,
          "to_latitude": 21.011613370577884,
          "to_longitude": 105.81248234185203,
          "from_longitude": 105.81248799394523
        }
      },
      {
        "id": 10215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81247577671535,
            21.011412771437485,
            105.81291747634523,
            21.011587070098223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81247577671535,
              21.011587070098223
            ],
            [
              105.81259151706458,
              21.011524358113395
            ],
            [
              105.81265789937878,
              21.011412771437485
            ],
            [
              105.81281857363527,
              21.011504753838757
            ],
            [
              105.81283330584439,
              21.011531831808863
            ],
            [
              105.81291747634523,
              21.01158457839216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10223",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011587070098223,
          "to_latitude": 21.01158457839216,
          "to_longitude": 105.81291747634523,
          "from_longitude": 105.81247577671535
        }
      },
      {
        "id": 10216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81259151706458,
            21.011524358113395,
            105.8128223312272,
            21.011672245068876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81259151706458,
              21.011524358113395
            ],
            [
              105.81273626392387,
              21.01160265054217
            ],
            [
              105.81275002792907,
              21.011627503072226
            ],
            [
              105.8128223312272,
              21.011672245068876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10224",
          "diaChiLapD": "Ngõ 151, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011524358113395,
          "to_latitude": 21.011672245068876,
          "to_longitude": 105.8128223312272,
          "from_longitude": 105.81259151706458
        }
      },
      {
        "id": 10217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81253309437065,
            21.011106412632785,
            105.8128883809425,
            21.011412406059556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8128883809425,
              21.011106412632785
            ],
            [
              105.81288814662334,
              21.011106839172918
            ],
            [
              105.81288804565186,
              21.01110702210748
            ],
            [
              105.81283252629737,
              21.01120791603987
            ],
            [
              105.81278051855189,
              21.011266858078063
            ],
            [
              105.81274908625258,
              21.01129014080646
            ],
            [
              105.81264835329354,
              21.011412406059556
            ],
            [
              105.81253309437065,
              21.01133380654616
            ],
            [
              105.81257171912003,
              21.01128817975952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10225",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011106412632785,
          "to_latitude": 21.01128817975952,
          "to_longitude": 105.81257171912003,
          "from_longitude": 105.8128883809425
        }
      },
      {
        "id": 10218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81231618290236,
            21.010777893238785,
            105.81269292243806,
            21.01105402686966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81269292243806,
              21.01105402686966
            ],
            [
              105.81259179134834,
              21.010991054413385
            ],
            [
              105.81252846586077,
              21.010951621968587
            ],
            [
              105.81250408153977,
              21.010934946477196
            ],
            [
              105.81241075285651,
              21.010871121939523
            ],
            [
              105.81237350353722,
              21.01083535668921
            ],
            [
              105.81231618290236,
              21.010777893238785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10226",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01105402686966,
          "to_latitude": 21.010777893238785,
          "to_longitude": 105.81231618290236,
          "from_longitude": 105.81269292243806
        }
      },
      {
        "id": 10219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81250408153977,
            21.010929242621327,
            105.81250823592279,
            21.010934946477196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81250823592279,
              21.010929242621327
            ],
            [
              105.81250666812363,
              21.010931395275918
            ],
            [
              105.81250450929498,
              21.010934359142414
            ],
            [
              105.81250408153977,
              21.010934946477196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10227",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010929242621327,
          "to_latitude": 21.010934946477196,
          "to_longitude": 105.81250408153977,
          "from_longitude": 105.81250823592279
        }
      },
      {
        "id": 10220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81272962777496,
            21.01089281017313,
            105.81307553658752,
            21.011126075393378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81280342104465,
              21.01089281017313
            ],
            [
              105.81273147049022,
              21.01104753460754
            ],
            [
              105.81272962777496,
              21.011051497744386
            ],
            [
              105.81274078901534,
              21.01105490560737
            ],
            [
              105.81286714790443,
              21.01109348860494
            ],
            [
              105.81286967036125,
              21.011094258750976
            ],
            [
              105.81289530057053,
              21.011102084723433
            ],
            [
              105.8129612801426,
              21.011126075393378
            ],
            [
              105.81307553658752,
              21.01094886516812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10228",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01089281017313,
          "to_latitude": 21.01094886516812,
          "to_longitude": 105.81307553658752,
          "from_longitude": 105.81280342104465
        }
      },
      {
        "id": 10221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8128643724143,
            21.01109348860494,
            105.81286714790443,
            21.01109892304256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81286714790443,
              21.01109348860494
            ],
            [
              105.81286512909848,
              21.01109743993915
            ],
            [
              105.8128643724143,
              21.01109892304256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B17_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10229",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01109348860494,
          "to_latitude": 21.01109892304256,
          "to_longitude": 105.8128643724143,
          "from_longitude": 105.81286714790443
        }
      },
      {
        "id": 10222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81303346055276,
            21.01107241580817,
            105.81329598154322,
            21.01132134007842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81315082264634,
              21.011084320298032
            ],
            [
              105.8131433267231,
              21.011094128150283
            ],
            [
              105.81310828108549,
              21.01107241580817
            ],
            [
              105.81303346055276,
              21.011170540603736
            ],
            [
              105.81310925010857,
              21.01122281681858
            ],
            [
              105.81314021351638,
              21.011239576393244
            ],
            [
              105.81314494943693,
              21.011242139705175
            ],
            [
              105.81316186908882,
              21.011251297072192
            ],
            [
              105.81329598154322,
              21.01132134007842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10230",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011084320298032,
          "to_latitude": 21.01132134007842,
          "to_longitude": 105.81329598154322,
          "from_longitude": 105.81315082264634
        }
      },
      {
        "id": 10223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8131343978777,
            21.011239576393244,
            105.81314021351638,
            21.011249885636495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81314021351638,
              21.011239576393244
            ],
            [
              105.8131371189662,
              21.011245063843752
            ],
            [
              105.81313636629066,
              21.011246398799635
            ],
            [
              105.81313456745869,
              21.011249583147734
            ],
            [
              105.8131343978777,
              21.011249885636495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B20_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10231",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011239576393244,
          "to_latitude": 21.011249885636495,
          "to_longitude": 105.8131343978777,
          "from_longitude": 105.81314021351638
        }
      },
      {
        "id": 10224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81309021140721,
            21.011328946480027,
            105.81329591600462,
            21.01165827484747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81329591600462,
              21.011328946480027
            ],
            [
              105.81329144152525,
              21.01133575201676
            ],
            [
              105.81315484187064,
              21.01154351518793
            ],
            [
              105.81309021140721,
              21.01165827484747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10232",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011328946480027,
          "to_latitude": 21.01165827484747,
          "to_longitude": 105.81309021140721,
          "from_longitude": 105.81329591600462
        }
      },
      {
        "id": 10225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81326002178602,
            21.011305796674897,
            105.81362388044889,
            21.011455460029456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81362388044889,
              21.011455460029456
            ],
            [
              105.81358210323194,
              21.01143960941504
            ],
            [
              105.81356495114464,
              21.01143229849864
            ],
            [
              105.81345122003209,
              21.01138382311951
            ],
            [
              105.81343919534369,
              21.01138137414474
            ],
            [
              105.81335594943543,
              21.011349078819414
            ],
            [
              105.81329689071417,
              21.011324031870366
            ],
            [
              105.81326002178602,
              21.011305796674897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10233",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011455460029456,
          "to_latitude": 21.011305796674897,
          "to_longitude": 105.81326002178602,
          "from_longitude": 105.81362388044889
        }
      },
      {
        "id": 10226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81355874105668,
            21.01143229849864,
            105.81356495114464,
            21.011443478547424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81356495114464,
              21.01143229849864
            ],
            [
              105.81356211426429,
              21.011437406271682
            ],
            [
              105.81355896395907,
              21.011443075544964
            ],
            [
              105.81355874105668,
              21.011443478547424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10234",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01143229849864,
          "to_latitude": 21.011443478547424,
          "to_longitude": 105.81355874105668,
          "from_longitude": 105.81356495114464
        }
      },
      {
        "id": 10227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81345100528208,
            21.011504488268724,
            105.8136278285432,
            21.01178631815812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8136278285432,
              21.011504488268724
            ],
            [
              105.81358034430382,
              21.011580171029383
            ],
            [
              105.81355089491623,
              21.01162710901079
            ],
            [
              105.81345100528208,
              21.01178631815812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10235",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011504488268724,
          "to_latitude": 21.01178631815812,
          "to_longitude": 105.81345100528208,
          "from_longitude": 105.8136278285432
        }
      },
      {
        "id": 10228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81358034430382,
            21.011580171029383,
            105.81359135613623,
            21.011585816846267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81358034430382,
              21.011580171029383
            ],
            [
              105.8135867501878,
              21.01158345524912
            ],
            [
              105.81359052658954,
              21.01158539268235
            ],
            [
              105.81359135613623,
              21.011585816846267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10236",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011580171029383,
          "to_latitude": 21.011585816846267,
          "to_longitude": 105.81359135613623,
          "from_longitude": 105.81358034430382
        }
      },
      {
        "id": 10229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81291129951337,
            21.01175016957273,
            105.81344403726588,
            21.012024440696706
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81333281749465,
                21.01180333724763
              ],
              [
                105.81331533176628,
                21.011794106734776
              ],
              [
                105.81323210045959,
                21.01175016957273
              ]
            ],
            [
              [
                105.81344403726588,
                21.011816673755767
              ],
              [
                105.81344400160243,
                21.011816660377843
              ],
              [
                105.81335290065547,
                21.011771984121374
              ],
              [
                105.81333281749465,
                21.01180333724763
              ]
            ],
            [
              [
                105.81333281749465,
                21.01180333724763
              ],
              [
                105.813266548248,
                21.011906793671912
              ],
              [
                105.81318515946104,
                21.012024440696706
              ],
              [
                105.81307474189205,
                21.011962846104925
              ],
              [
                105.81291129951337,
                21.011865530982814
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B25_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10237",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81331533176628,
            21.011794106734776
          ],
          "to_latitude": [
            105.813266548248,
            21.011906793671912
          ],
          "to_longitude": [
            105.81333281749465,
            21.01180333724763
          ],
          "from_longitude": [
            105.81333281749465,
            21.01180333724763
          ]
        }
      },
      {
        "id": 10230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81342686161389,
            21.01180965273428,
            105.81354107524075,
            21.012082377725854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8134478632139,
              21.01180965273428
            ],
            [
              105.8134482070085,
              21.01180990218826
            ],
            [
              105.81352731573335,
              21.011866806733355
            ],
            [
              105.81342686161389,
              21.012021997392687
            ],
            [
              105.81354107524075,
              21.012082377725854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10238",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01180965273428,
          "to_latitude": 21.012082377725854,
          "to_longitude": 105.81354107524075,
          "from_longitude": 105.8134478632139
        }
      },
      {
        "id": 10231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81325118082822,
            21.012364099321193,
            105.81376374536426,
            21.012670020273575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81325118082822,
              21.012670020273575
            ],
            [
              105.81343105692844,
              21.012571499757968
            ],
            [
              105.81346900263446,
              21.01254379878188
            ],
            [
              105.81348914152493,
              21.012529097294063
            ],
            [
              105.81353843260901,
              21.012490315446918
            ],
            [
              105.81353893355943,
              21.012489921953797
            ],
            [
              105.81354787336922,
              21.0124863231991
            ],
            [
              105.81358820041282,
              21.012470089941925
            ],
            [
              105.81376374536426,
              21.012364099321193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15A_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10239",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012670020273575,
          "to_latitude": 21.012364099321193,
          "to_longitude": 105.81376374536426,
          "from_longitude": 105.81325118082822
        }
      },
      {
        "id": 10232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8132792387209,
            21.012047007084263,
            105.81355114869588,
            21.012513637425673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81355114869588,
              21.012513637425673
            ],
            [
              105.81354675714972,
              21.01250558536719
            ],
            [
              105.81353843260901,
              21.012490315446918
            ],
            [
              105.81346401133256,
              21.01235382401521
            ],
            [
              105.8132792387209,
              21.01223131813562
            ],
            [
              105.81340376979522,
              21.012047007084263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10240",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012513637425673,
          "to_latitude": 21.012047007084263,
          "to_longitude": 105.81340376979522,
          "from_longitude": 105.81355114869588
        }
      },
      {
        "id": 10233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81346900263446,
            21.01254379878188,
            105.81348072792173,
            21.012559312576514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81346900263446,
              21.01254379878188
            ],
            [
              105.8134750193261,
              21.012551759072455
            ],
            [
              105.81347968347532,
              21.01255793021104
            ],
            [
              105.81347994895461,
              21.01255828300736
            ],
            [
              105.81348072792173,
              21.012559312576514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15A_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10241",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01254379878188,
          "to_latitude": 21.012559312576514,
          "to_longitude": 105.81348072792173,
          "from_longitude": 105.81346900263446
        }
      },
      {
        "id": 10234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81367634252842,
            21.01198808829839,
            105.81379376963021,
            21.012356273766446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81379376963021,
              21.012356273766446
            ],
            [
              105.81378829998671,
              21.012346844027814
            ],
            [
              105.81367634252842,
              21.012153767011426
            ],
            [
              105.81377332931017,
              21.012071562817464
            ],
            [
              105.81372952780919,
              21.012038114390737
            ],
            [
              105.81375613242923,
              21.01198808829839
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10242",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012356273766446,
          "to_latitude": 21.01198808829839,
          "to_longitude": 105.81375613242923,
          "from_longitude": 105.81379376963021
        }
      },
      {
        "id": 10235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81268884323268,
            21.012560960580043,
            105.81291072345564,
            21.012863573330964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81291072345564,
              21.012863573330964
            ],
            [
              105.81289978153515,
              21.012848652788048
            ],
            [
              105.81268884323268,
              21.012560960580043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10243",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012863573330964,
          "to_latitude": 21.012560960580043,
          "to_longitude": 105.81268884323268,
          "from_longitude": 105.81291072345564
        }
      },
      {
        "id": 10236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81297317486678,
            21.012366694497025,
            105.81319122490392,
            21.0126704937995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81319122490392,
              21.0126704937995
            ],
            [
              105.81306842587868,
              21.01253966541119
            ],
            [
              105.81303828997413,
              21.01250755963884
            ],
            [
              105.81297317486678,
              21.012438187589076
            ],
            [
              105.81301094871459,
              21.012366694497025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10244",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0126704937995,
          "to_latitude": 21.012366694497025,
          "to_longitude": 105.81301094871459,
          "from_longitude": 105.81319122490392
        }
      },
      {
        "id": 10237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81305975418464,
            21.01253966541119,
            105.81306842587868,
            21.012547301194733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81306842587868,
              21.01253966541119
            ],
            [
              105.81306455530542,
              21.012543073723617
            ],
            [
              105.81306242232462,
              21.012544951792748
            ],
            [
              105.81306094895722,
              21.01254624864362
            ],
            [
              105.81305975418464,
              21.012547301194733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10245",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01253966541119,
          "to_latitude": 21.012547301194733,
          "to_longitude": 105.81305975418464,
          "from_longitude": 105.81306842587868
        }
      },
      {
        "id": 10238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81277991980107,
            21.011969893507406,
            105.81304897199611,
            21.012373476299693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8129210777238,
              21.012373476299693
            ],
            [
              105.81292197116099,
              21.01237255435091
            ],
            [
              105.81298012096656,
              21.012312527950264
            ],
            [
              105.81304897199611,
              21.01220633967393
            ],
            [
              105.81277991980107,
              21.011969893507406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10246",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012373476299693,
          "to_latitude": 21.011969893507406,
          "to_longitude": 105.81277991980107,
          "from_longitude": 105.8129210777238
        }
      },
      {
        "id": 10239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8126361506704,
            21.011848999259904,
            105.81292102472503,
            21.012339198124575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81292102472503,
              21.012339198124575
            ],
            [
              105.81281670297426,
              21.01223770663916
            ],
            [
              105.81270593718035,
              21.012129945843938
            ],
            [
              105.8126361506704,
              21.01206205197006
            ],
            [
              105.81274976754442,
              21.011946455137313
            ],
            [
              105.81278021470831,
              21.01193288862703
            ],
            [
              105.81285985899655,
              21.011848999259904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10247",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012339198124575,
          "to_latitude": 21.011848999259904,
          "to_longitude": 105.81285985899655,
          "from_longitude": 105.81292102472503
        }
      },
      {
        "id": 10240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81280490362097,
            21.01223770663916,
            105.81281670297426,
            21.01224791981032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81281670297426,
              21.01223770663916
            ],
            [
              105.81280988033592,
              21.01224361202339
            ],
            [
              105.81280797792456,
              21.012245258664624
            ],
            [
              105.81280596732321,
              21.012246998855233
            ],
            [
              105.81280490362097,
              21.01224791981032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10248",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01223770663916,
          "to_latitude": 21.01224791981032,
          "to_longitude": 105.81280490362097,
          "from_longitude": 105.81281670297426
        }
      },
      {
        "id": 10241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81237312961761,
            21.01171629315817,
            105.81270934237833,
            21.012156557377384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81270934237833,
              21.012148414153845
            ],
            [
              105.81270580443848,
              21.01215160951393
            ],
            [
              105.81270032522184,
              21.012156557377384
            ],
            [
              105.81264672573654,
              21.012104978890505
            ],
            [
              105.81261834659672,
              21.012092963291327
            ],
            [
              105.81237312961761,
              21.01188120618474
            ],
            [
              105.81253291596029,
              21.01171629315817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.10249",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012148414153845,
          "to_latitude": 21.01171629315817,
          "to_longitude": 105.81253291596029,
          "from_longitude": 105.81270934237833
        }
      },
      {
        "id": 10242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81200548727601,
            21.011890954803164,
            105.81202115648885,
            21.011902404843656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81202115648885,
              21.011890954803164
            ],
            [
              105.8120103277276,
              21.011898866377592
            ],
            [
              105.81200714578463,
              21.011901191118497
            ],
            [
              105.81200548727601,
              21.011902404843656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2A_Láng Hạ",
          "maTaiSan": "04.O11.DODV.10250",
          "diaChiLapD": "Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011890954803164,
          "to_latitude": 21.011902404843656,
          "to_longitude": 105.81200548727601,
          "from_longitude": 105.81202115648885
        }
      },
      {
        "id": 10243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81397642531579,
            21.011421514650568,
            105.81454744609249,
            21.011811800190376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81451258124096,
              21.011421514650568
            ],
            [
              105.81451197647169,
              21.011422982582
            ],
            [
              105.81451015841434,
              21.01142740445879
            ],
            [
              105.81454744609249,
              21.0114458433095
            ],
            [
              105.81442928611101,
              21.011626498201412
            ],
            [
              105.81432772103564,
              21.011811800190376
            ],
            [
              105.81397642531579,
              21.011616561900478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B15_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10251",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011421514650568,
          "to_latitude": 21.011616561900478,
          "to_longitude": 105.81397642531579,
          "from_longitude": 105.81451258124096
        }
      },
      {
        "id": 10244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81404815361999,
            21.011354590495074,
            105.81453790067854,
            21.011577042062697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81449988522671,
              21.01141660495774
            ],
            [
              105.81449910097983,
              21.01141850097528
            ],
            [
              105.81449605043034,
              21.011425883211746
            ],
            [
              105.81453790067854,
              21.011445070678565
            ],
            [
              105.81445360127866,
              21.011577042062697
            ],
            [
              105.81404815361999,
              21.011354590495074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B14_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10252",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01141660495774,
          "to_latitude": 21.011354590495074,
          "to_longitude": 105.81404815361999,
          "from_longitude": 105.81449988522671
        }
      },
      {
        "id": 10245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81396560929223,
            21.01116795113273,
            105.81446451345937,
            21.011394402204996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81396560929223,
              21.01116795113273
            ],
            [
              105.81419321442509,
              21.01127421001936
            ],
            [
              105.81419568799917,
              21.011275365154685
            ],
            [
              105.8142602577366,
              21.011306278982104
            ],
            [
              105.81430332874955,
              21.0113254958928
            ],
            [
              105.81446451345937,
              21.011394402204996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10253",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01116795113273,
          "to_latitude": 21.011394402204996,
          "to_longitude": 105.81446451345937,
          "from_longitude": 105.81396560929223
        }
      },
      {
        "id": 10246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8138854555584,
            21.01092654938465,
            105.81409652859652,
            21.0111704998299
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.8138854555584,
                21.01112866925271
              ],
              [
                105.81393879800208,
                21.011153603340354
              ],
              [
                105.81395717681491,
                21.011158310666342
              ]
            ],
            [
              [
                105.81395035950406,
                21.0111704998299
              ],
              [
                105.81395590602745,
                21.01116041946122
              ],
              [
                105.81395717681491,
                21.011158310666342
              ],
              [
                105.81409652859652,
                21.01092654938465
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B12_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10254",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81393879800208,
            21.011153603340354
          ],
          "to_latitude": [
            105.81395590602745,
            21.01116041946122
          ],
          "to_longitude": [
            105.81395035950406,
            21.0111704998299
          ],
          "from_longitude": [
            105.8138854555584,
            21.01112866925271
          ]
        }
      },
      {
        "id": 10247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8136433182574,
            21.011182339408546,
            105.81383024728078,
            21.01144808796525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8136433182574,
              21.01144808796525
            ],
            [
              105.81369345940682,
              21.01138361148455
            ],
            [
              105.81373398002789,
              21.011324559723814
            ],
            [
              105.81373641176356,
              21.01132101647098
            ],
            [
              105.81374897177623,
              21.01130245376828
            ],
            [
              105.81383024728078,
              21.011182339408546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10255",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01144808796525,
          "to_latitude": 21.011182339408546,
          "to_longitude": 105.81383024728078,
          "from_longitude": 105.8136433182574
        }
      },
      {
        "id": 10248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81374897177623,
            21.01130245376828,
            105.81375930747691,
            21.011306878030403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81374897177623,
              21.01130245376828
            ],
            [
              105.81375537181182,
              21.01130519336315
            ],
            [
              105.8137578670413,
              21.011306262594626
            ],
            [
              105.81375930747691,
              21.011306878030403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B21_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.10256",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01130245376828,
          "to_latitude": 21.011306878030403,
          "to_longitude": 105.81375930747691,
          "from_longitude": 105.81374897177623
        }
      },
      {
        "id": 10249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81378012582735,
            21.010886172056026,
            105.81388068875664,
            21.011127152951218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81388068875664,
              21.011127152951218
            ],
            [
              105.81386876507298,
              21.011116190594063
            ],
            [
              105.81385586305765,
              21.011104328783492
            ],
            [
              105.81382041942324,
              21.01106973303578
            ],
            [
              105.8138028020495,
              21.01105253672426
            ],
            [
              105.81384850085851,
              21.010975462439816
            ],
            [
              105.81378012582735,
              21.010925890589558
            ],
            [
              105.81381014610852,
              21.010886172056026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10257",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011127152951218,
          "to_latitude": 21.010886172056026,
          "to_longitude": 105.81381014610852,
          "from_longitude": 105.81388068875664
        }
      },
      {
        "id": 10250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81385397293262,
            21.011116190594063,
            105.81386876507298,
            21.01113164242331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81386876507298,
              21.011116190594063
            ],
            [
              105.81385830679686,
              21.01112711548749
            ],
            [
              105.81385478977332,
              21.011130788578363
            ],
            [
              105.81385397293262,
              21.01113164242331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B11_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10258",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011116190594063,
          "to_latitude": 21.01113164242331,
          "to_longitude": 105.81385397293262,
          "from_longitude": 105.81386876507298
        }
      },
      {
        "id": 10251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81321684673712,
            21.010769397573135,
            105.81379828394807,
            21.011070446774468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81321684673712,
              21.010769397573135
            ],
            [
              105.81325895761117,
              21.010834422073245
            ],
            [
              105.81329549787601,
              21.01086675463088
            ],
            [
              105.81333265413598,
              21.010885312708158
            ],
            [
              105.813500986134,
              21.010951422823123
            ],
            [
              105.81360974021081,
              21.010989648655254
            ],
            [
              105.81360991946929,
              21.010989711927365
            ],
            [
              105.81364341541018,
              21.011002003674715
            ],
            [
              105.81369580818628,
              21.01102122946137
            ],
            [
              105.81372356339148,
              21.011033331869495
            ],
            [
              105.813778068214,
              21.011058880968918
            ],
            [
              105.81379828394807,
              21.011070446774468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B10_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10259",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010769397573135,
          "to_latitude": 21.011070446774468,
          "to_longitude": 105.81379828394807,
          "from_longitude": 105.81321684673712
        }
      },
      {
        "id": 10252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81364341541018,
            21.010989320393485,
            105.81365100151184,
            21.011002003674715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81365100151184,
              21.010989320393485
            ],
            [
              105.81365022935464,
              21.01099060938001
            ],
            [
              105.81364851443983,
              21.010993479008423
            ],
            [
              105.81364341541018,
              21.011002003674715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B10_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10260",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010989320393485,
          "to_latitude": 21.011002003674715,
          "to_longitude": 105.81364341541018,
          "from_longitude": 105.81365100151184
        }
      },
      {
        "id": 10253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81275432682448,
            21.01045949846515,
            105.81319084266337,
            21.010727192936614
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81275432682448,
                21.01045949846515
              ],
              [
                105.81282699349887,
                21.01049986621518
              ],
              [
                105.81290425538243,
                21.010537951199012
              ],
              [
                105.81293045461885,
                21.010550865048288
              ],
              [
                105.81299009737076,
                21.01058364098484
              ]
            ],
            [
              [
                105.81299009737076,
                21.01058364098484
              ],
              [
                105.81291054148555,
                21.010697383499362
              ]
            ],
            [
              [
                105.81299009737076,
                21.01058364098484
              ],
              [
                105.81300164290835,
                21.010589986102023
              ],
              [
                105.81308272323673,
                21.010643435646816
              ],
              [
                105.81318052077191,
                21.010716077453733
              ],
              [
                105.81319084266337,
                21.010727192936614
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10261",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81282699349887,
            21.01049986621518
          ],
          "to_latitude": [
            105.81300164290835,
            21.010589986102023
          ],
          "to_longitude": [
            105.81299009737076,
            21.01058364098484
          ],
          "from_longitude": [
            105.81275432682448,
            21.01045949846515
          ]
        }
      },
      {
        "id": 10254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81264171936452,
            21.010487409530086,
            105.81284006097776,
            21.01072750823912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81264171936452,
              21.010487409530086
            ],
            [
              105.8126453750287,
              21.01049086047054
            ],
            [
              105.81264950346552,
              21.010494754444906
            ],
            [
              105.81284006097776,
              21.01067456862987
            ],
            [
              105.8127976072814,
              21.01072750823912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B8_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10262",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010487409530086,
          "to_latitude": 21.01072750823912,
          "to_longitude": 105.8127976072814,
          "from_longitude": 105.81264171936452
        }
      },
      {
        "id": 10255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8123421045684,
            21.010262389351016,
            105.8128897574049,
            21.010738299294143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8123421045684,
              21.010738299294143
            ],
            [
              105.81243548024125,
              21.010650593462348
            ],
            [
              105.81245380935428,
              21.01063424060426
            ],
            [
              105.81252646259784,
              21.01058083081179
            ],
            [
              105.81254470463253,
              21.010567762500163
            ],
            [
              105.81259121952593,
              21.010533289039554
            ],
            [
              105.81262012820991,
              21.010512951177706
            ],
            [
              105.81262178184991,
              21.010511730244332
            ],
            [
              105.81264344303302,
              21.01049574351991
            ],
            [
              105.81264950346552,
              21.010494754444906
            ],
            [
              105.81265002627966,
              21.010494668851045
            ],
            [
              105.81265226665117,
              21.01048953503773
            ],
            [
              105.81265462587831,
              21.01048787537808
            ],
            [
              105.81273983285755,
              21.010439797819906
            ],
            [
              105.81274120994725,
              21.010439020788404
            ],
            [
              105.81274811944259,
              21.010435135515955
            ],
            [
              105.81277323206336,
              21.01040088526188
            ],
            [
              105.81284119212806,
              21.010317682173113
            ],
            [
              105.8128897574049,
              21.010262389351016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B30_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10263",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010738299294143,
          "to_latitude": 21.010262389351016,
          "to_longitude": 105.8128897574049,
          "from_longitude": 105.8123421045684
        }
      },
      {
        "id": 10256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81261573893455,
            21.01050547533946,
            105.81262178184991,
            21.010511730244332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81262178184991,
              21.010511730244332
            ],
            [
              105.81261890405115,
              21.01050875339671
            ],
            [
              105.81261573893455,
              21.01050547533946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B30_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10264",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010511730244332,
          "to_latitude": 21.01050547533946,
          "to_longitude": 105.81261573893455,
          "from_longitude": 105.81262178184991
        }
      },
      {
        "id": 10257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81329641390624,
            21.009489865824417,
            105.81360606289223,
            21.009854444000503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81329641390624,
              21.009854444000503
            ],
            [
              105.8134775101915,
              21.009681759874933
            ],
            [
              105.8134854843226,
              21.009674123794547
            ],
            [
              105.81349160678903,
              21.00966826147473
            ],
            [
              105.81353896353926,
              21.00962291609214
            ],
            [
              105.81356305140827,
              21.00959345322254
            ],
            [
              105.81360606289223,
              21.009489865824417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10265",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009854444000503,
          "to_latitude": 21.009489865824417,
          "to_longitude": 105.81360606289223,
          "from_longitude": 105.81329641390624
        }
      },
      {
        "id": 10258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8134854843226,
            21.009674123794547,
            105.81349402184004,
            21.009683111655814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81349402184004,
              21.009683111655814
            ],
            [
              105.81349361843561,
              21.009682689066764
            ],
            [
              105.81349047139344,
              21.00967937390675
            ],
            [
              105.8134854843226,
              21.009674123794547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.10266",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009683111655814,
          "to_latitude": 21.009674123794547,
          "to_longitude": 105.8134854843226,
          "from_longitude": 105.81349402184004
        }
      },
      {
        "id": 10259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81458317801923,
            21.009709812272323,
            105.81515248235895,
            21.009949005819127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81515248235895,
              21.009709812272323
            ],
            [
              105.81515031442915,
              21.009711442145985
            ],
            [
              105.81514723449213,
              21.009713757422627
            ],
            [
              105.8151235587422,
              21.009722527375537
            ],
            [
              105.81501705346382,
              21.009805473373618
            ],
            [
              105.81488407458899,
              21.009904895423904
            ],
            [
              105.81487235858046,
              21.009907016308745
            ],
            [
              105.81483812422724,
              21.009886609212458
            ],
            [
              105.81476479280524,
              21.009949005819127
            ],
            [
              105.81471642576109,
              21.009909923470037
            ],
            [
              105.81458317801923,
              21.0098014820727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C2_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10267",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009709812272323,
          "to_latitude": 21.0098014820727,
          "to_longitude": 105.81458317801923,
          "from_longitude": 105.81515248235895
        }
      },
      {
        "id": 10260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81471642576109,
            21.009723377038945,
            105.81483075084729,
            21.009909923470037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81471642576109,
              21.009909923470037
            ],
            [
              105.81483075084729,
              21.009775127408393
            ],
            [
              105.81476545963761,
              21.009723377038945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_C2_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10268",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009909923470037,
          "to_latitude": 21.009723377038945,
          "to_longitude": 105.81476545963761,
          "from_longitude": 105.81471642576109
        }
      },
      {
        "id": 10261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81460062852041,
            21.009286550104353,
            105.81509550070464,
            21.00972282435537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81460062852041,
              21.009286550104353
            ],
            [
              105.81484587668942,
              21.009496856972113
            ],
            [
              105.81484828266858,
              21.009504521990817
            ],
            [
              105.81485640947388,
              21.009506593017758
            ],
            [
              105.81487481298436,
              21.00952296532889
            ],
            [
              105.8148797970563,
              21.00952747837909
            ],
            [
              105.81509550070464,
              21.00972282435537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10269",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009286550104353,
          "to_latitude": 21.00972282435537,
          "to_longitude": 105.81509550070464,
          "from_longitude": 105.81460062852041
        }
      },
      {
        "id": 10262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8148797970563,
            21.00949995678797,
            105.81491850838269,
            21.00952747837909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8148797970563,
              21.00952747837909
            ],
            [
              105.81489695366056,
              21.009515283023283
            ],
            [
              105.81490706937807,
              21.009508089140382
            ],
            [
              105.81491850838269,
              21.00949995678797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_C4_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10270",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 5.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00952747837909,
          "to_latitude": 21.00949995678797,
          "to_longitude": 105.81491850838269,
          "from_longitude": 105.8148797970563
        }
      },
      {
        "id": 10263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637485806297,
            21.011200648480223,
            105.81670898073688,
            21.01162116875181
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637485806297,
              21.011200648480223
            ],
            [
              105.81649992793047,
              21.0113624117629
            ],
            [
              105.81653807561962,
              21.011411751799688
            ],
            [
              105.81670898073688,
              21.01162116875181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10271",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011200648480223,
          "to_latitude": 21.01162116875181,
          "to_longitude": 105.81670898073688,
          "from_longitude": 105.81637485806297
        }
      },
      {
        "id": 10264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81649992793047,
            21.011339593956972,
            105.81653202204267,
            21.0113624117629
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81649992793047,
              21.0113624117629
            ],
            [
              105.81650380291401,
              21.011359657290832
            ],
            [
              105.81651822580965,
              21.011349402378418
            ],
            [
              105.81653202204267,
              21.011339593956972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10272",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0113624117629,
          "to_latitude": 21.011339593956972,
          "to_longitude": 105.81653202204267,
          "from_longitude": 105.81649992793047
        }
      },
      {
        "id": 10265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8160600909475,
            21.01118697370376,
            105.81634000863086,
            21.0113814121193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8160600909475,
              21.0113814121193
            ],
            [
              105.8161760480485,
              21.011300865170497
            ],
            [
              105.81618689111143,
              21.011293333528123
            ],
            [
              105.81634000863086,
              21.01118697370376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10273",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0113814121193,
          "to_latitude": 21.01118697370376,
          "to_longitude": 105.81634000863086,
          "from_longitude": 105.8160600909475
        }
      },
      {
        "id": 10266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81616554891113,
            21.011288512401105,
            105.8161760480485,
            21.011300865170497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81616554891113,
              21.011288512401105
            ],
            [
              105.81617009321955,
              21.011293860295574
            ],
            [
              105.81617528130566,
              21.0112999629104
            ],
            [
              105.8161760480485,
              21.011300865170497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10274",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011288512401105,
          "to_latitude": 21.011300865170497,
          "to_longitude": 105.8161760480485,
          "from_longitude": 105.81616554891113
        }
      },
      {
        "id": 10267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81604326435055,
            21.011371711759598,
            105.81627032275574,
            21.011654918243785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81604326435055,
              21.011371711759598
            ],
            [
              105.81605589435515,
              21.01138746761638
            ],
            [
              105.81627032275574,
              21.011654918243785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10275",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011371711759598,
          "to_latitude": 21.011654918243785,
          "to_longitude": 105.81627032275574,
          "from_longitude": 105.81604326435055
        }
      },
      {
        "id": 10268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816034314007,
            21.01137794811917,
            105.81627029732168,
            21.011671989376957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816034314007,
              21.01137794811917
            ],
            [
              105.81604717635278,
              21.01139397654048
            ],
            [
              105.81627029732168,
              21.011671989376957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10276",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01137794811917,
          "to_latitude": 21.011671989376957,
          "to_longitude": 105.81627029732168,
          "from_longitude": 105.816034314007
        }
      },
      {
        "id": 10269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81580327618055,
            21.011076537735867,
            105.81604670362482,
            21.01136931616243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81604670362482,
              21.01136931616243
            ],
            [
              105.81602678649188,
              21.011350258727138
            ],
            [
              105.81580327618055,
              21.011076537735867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10277",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01136931616243,
          "to_latitude": 21.011076537735867,
          "to_longitude": 105.81580327618055,
          "from_longitude": 105.81604670362482
        }
      },
      {
        "id": 10270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81581332009246,
            21.011107251728887,
            105.81602246389167,
            21.011386202648055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81602246389167,
              21.011386202648055
            ],
            [
              105.816017546545,
              21.0113800038912
            ],
            [
              105.8160186901693,
              21.011363009587996
            ],
            [
              105.81601483969241,
              21.01135736119998
            ],
            [
              105.81581332009246,
              21.011107251728887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10278",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011386202648055,
          "to_latitude": 21.011107251728887,
          "to_longitude": 105.81581332009246,
          "from_longitude": 105.81602246389167
        }
      },
      {
        "id": 10271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81579893101068,
            21.01142305566675,
            105.81601070198963,
            21.011572524795266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81579893101068,
              21.011572524795266
            ],
            [
              105.8158940617958,
              21.011505380625618
            ],
            [
              105.81590481607495,
              21.01149779071647
            ],
            [
              105.81601070198963,
              21.01142305566675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_16_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10279",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 27.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011572524795266,
          "to_latitude": 21.01142305566675,
          "to_longitude": 105.81601070198963,
          "from_longitude": 105.81579893101068
        }
      },
      {
        "id": 10272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81585571143796,
            21.011480117862156,
            105.81586624568028,
            21.011495036553683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81585571143796,
              21.011480117862156
            ],
            [
              105.81585692800876,
              21.011481840807765
            ],
            [
              105.81585873882423,
              21.01148440813216
            ],
            [
              105.81586624568028,
              21.011495036553683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_16_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10280",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 2.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011480117862156,
          "to_latitude": 21.011495036553683,
          "to_longitude": 105.81586624568028,
          "from_longitude": 105.81585571143796
        }
      },
      {
        "id": 10273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81585692800876,
            21.011473989493606,
            105.8158940617958,
            21.011505380625618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8158940617958,
              21.011505380625618
            ],
            [
              105.81588542287308,
              21.011492649498972
            ],
            [
              105.81588285377217,
              21.011489496905995
            ],
            [
              105.81587651272643,
              21.01148788433848
            ],
            [
              105.81587556874474,
              21.01148764408989
            ],
            [
              105.81587613224919,
              21.01148124382272
            ],
            [
              105.8158696441318,
              21.011473989493606
            ],
            [
              105.81585692800876,
              21.011481840807765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_16_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10281",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 5.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011505380625618,
          "to_latitude": 21.011481840807765,
          "to_longitude": 105.81585692800876,
          "from_longitude": 105.8158940617958
        }
      },
      {
        "id": 10274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81577431038903,
            21.011400230029356,
            105.81597720908275,
            21.01153564931776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81597720908275,
              21.011400230029356
            ],
            [
              105.81589602188787,
              21.01145261818486
            ],
            [
              105.81585571143796,
              21.011480117862156
            ],
            [
              105.81577431038903,
              21.01153564931776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_16_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10282",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 25.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011400230029356,
          "to_latitude": 21.01153564931776,
          "to_longitude": 105.81577431038903,
          "from_longitude": 105.81597720908275
        }
      },
      {
        "id": 10275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81577182506628,
            21.011560817944858,
            105.81602596748623,
            21.011887623790404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81577182506628,
              21.011560817944858
            ],
            [
              105.8157860812515,
              21.011579152870887
            ],
            [
              105.81602596748623,
              21.011887623790404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10283",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011560817944858,
          "to_latitude": 21.011887623790404,
          "to_longitude": 105.81602596748623,
          "from_longitude": 105.81577182506628
        }
      },
      {
        "id": 10276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81575172601987,
            21.01157482040504,
            105.81601665034681,
            21.0118964623024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81575172601987,
              21.01157482040504
            ],
            [
              105.81576607729991,
              21.011592246229295
            ],
            [
              105.81601665034681,
              21.0118964623024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10284",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01157482040504,
          "to_latitude": 21.0118964623024,
          "to_longitude": 105.81601665034681,
          "from_longitude": 105.81575172601987
        }
      },
      {
        "id": 10277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81553288642863,
            21.01126491411887,
            105.8157703167305,
            21.01156186930799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157703167305,
              21.01156186930799
            ],
            [
              105.81575564493444,
              21.01154352192136
            ],
            [
              105.81553288642863,
              21.01126491411887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10285",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01156186930799,
          "to_latitude": 21.01126491411887,
          "to_longitude": 105.81553288642863,
          "from_longitude": 105.8157703167305
        }
      },
      {
        "id": 10278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8155363160699,
            21.011296136734067,
            105.81575703970599,
            21.01157111879164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81575703970599,
              21.01157111879164
            ],
            [
              105.81574298327713,
              21.011553608455948
            ],
            [
              105.8155363160699,
              21.011296136734067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10286",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01157111879164,
          "to_latitude": 21.011296136734067,
          "to_longitude": 105.8155363160699,
          "from_longitude": 105.81575703970599
        }
      },
      {
        "id": 10279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81552568862362,
            21.01161329147291,
            105.81573781141408,
            21.011769453977543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81552568862362,
              21.011769453977543
            ],
            [
              105.8156320528286,
              21.011691150014684
            ],
            [
              105.81566093702749,
              21.011669885952507
            ],
            [
              105.81573781141408,
              21.01161329147291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_21_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10287",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 28.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011769453977543,
          "to_latitude": 21.01161329147291,
          "to_longitude": 105.81573781141408,
          "from_longitude": 105.81552568862362
        }
      },
      {
        "id": 10280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81563301207922,
            21.011646603620626,
            105.8156385007132,
            21.01165437939628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81563301207922,
              21.011646603620626
            ],
            [
              105.81563407557539,
              21.011648125881695
            ],
            [
              105.81563559182412,
              21.011650299006508
            ],
            [
              105.8156385007132,
              21.01165437939628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_21_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10288",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 2.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011646603620626,
          "to_latitude": 21.01165437939628,
          "to_longitude": 105.8156385007132,
          "from_longitude": 105.81563301207922
        }
      },
      {
        "id": 10281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81563407557539,
            21.01164381778599,
            105.81566093702749,
            21.011669885952507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81566093702749,
              21.011669885952507
            ],
            [
              105.8156518191107,
              21.0116580449834
            ],
            [
              105.81564365940557,
              21.011652953761104
            ],
            [
              105.81564509759713,
              21.011649792794174
            ],
            [
              105.815645055956,
              21.01164974150978
            ],
            [
              105.81564023053073,
              21.01164381778599
            ],
            [
              105.81563407557539,
              21.011648125881695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_21_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10289",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 5.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011669885952507,
          "to_latitude": 21.011648125881695,
          "to_longitude": 105.81563407557539,
          "from_longitude": 105.81566093702749
        }
      },
      {
        "id": 10282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81550606530733,
            21.011594433664122,
            105.81570507209341,
            21.011734724967138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81570507209341,
              21.011594433664122
            ],
            [
              105.81563301207922,
              21.011646603620626
            ],
            [
              105.81562703518112,
              21.01165093073359
            ],
            [
              105.81550606530733,
              21.011734724967138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_21_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10290",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 25.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011594433664122,
          "to_latitude": 21.011734724967138,
          "to_longitude": 105.81550606530733,
          "from_longitude": 105.81570507209341
        }
      },
      {
        "id": 10283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81549026080921,
            21.011759280004426,
            105.81572914899905,
            21.012064698701117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81549026080921,
              21.011759280004426
            ],
            [
              105.81550756182844,
              21.011781402048566
            ],
            [
              105.81572914899905,
              21.012064698701117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10291",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011759280004426,
          "to_latitude": 21.012064698701117,
          "to_longitude": 105.81572914899905,
          "from_longitude": 105.81549026080921
        }
      },
      {
        "id": 10284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81547939544575,
            21.011766968872514,
            105.81572287136648,
            21.01207103784525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81547939544575,
              21.011766968872514
            ],
            [
              105.81549710913986,
              21.011789093456066
            ],
            [
              105.81572287136648,
              21.01207103784525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10292",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011766968872514,
          "to_latitude": 21.01207103784525,
          "to_longitude": 105.81572287136648,
          "from_longitude": 105.81547939544575
        }
      },
      {
        "id": 10285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81528258052352,
            21.011505999294553,
            105.81549526180618,
            21.01175574067214
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81549526180618,
              21.01175574067214
            ],
            [
              105.8154829659733,
              21.011746068220624
            ],
            [
              105.81547475843145,
              21.011739608828766
            ],
            [
              105.81528258052352,
              21.011505999294553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10293",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01175574067214,
          "to_latitude": 21.011505999294553,
          "to_longitude": 105.81528258052352,
          "from_longitude": 105.81549526180618
        }
      },
      {
        "id": 10286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81527049075196,
            21.011506803287286,
            105.81547787281984,
            21.011768046495252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81547787281984,
              21.011768046495252
            ],
            [
              105.81547065522116,
              21.011755240793455
            ],
            [
              105.81527049075196,
              21.011506803287286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10294",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011768046495252,
          "to_latitude": 21.011506803287286,
          "to_longitude": 105.81527049075196,
          "from_longitude": 105.81547787281984
        }
      },
      {
        "id": 10287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81524767164018,
            21.011810046486723,
            105.81545660133979,
            21.01195861617246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81524767164018,
              21.01195861617246
            ],
            [
              105.81535523492057,
              21.01188212753967
            ],
            [
              105.81545660133979,
              21.011810046486723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_26_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10295",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 27.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01195861617246,
          "to_latitude": 21.011810046486723,
          "to_longitude": 105.81545660133979,
          "from_longitude": 105.81524767164018
        }
      },
      {
        "id": 10288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81533954459579,
            21.01186291789882,
            105.81535523492057,
            21.01188212753967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81535523492057,
              21.01188212753967
            ],
            [
              105.81534903935652,
              21.011874542110625
            ],
            [
              105.81533994163154,
              21.011863405546023
            ],
            [
              105.81533954459579,
              21.01186291789882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_26_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10296",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 2.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01188212753967,
          "to_latitude": 21.01186291789882,
          "to_longitude": 105.81533954459579,
          "from_longitude": 105.81535523492057
        }
      },
      {
        "id": 10289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81534903935652,
            21.01184511948028,
            105.81536677129017,
            21.011874542110625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81534903935652,
              21.011874542110625
            ],
            [
              105.81534939587422,
              21.011874300143553
            ],
            [
              105.81536677129017,
              21.011862502012374
            ],
            [
              105.81536420315022,
              21.011859348503748
            ],
            [
              105.81535604248121,
              21.011854257272418
            ],
            [
              105.81535748163762,
              21.01185109539993
            ],
            [
              105.81535723082916,
              21.011850787697725
            ],
            [
              105.81535261457071,
              21.01184511948028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_26_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10297",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 5.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011874542110625,
          "to_latitude": 21.01184511948028,
          "to_longitude": 105.81535261457071,
          "from_longitude": 105.81534903935652
        }
      },
      {
        "id": 10290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81523164343669,
            21.01176888982976,
            105.81545983860583,
            21.011928916241175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81545983860583,
              21.01176888982976
            ],
            [
              105.81535261457071,
              21.01184511948028
            ],
            [
              105.81523164343669,
              21.011928916241175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_26_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10298",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 29.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01176888982976,
          "to_latitude": 21.011928916241175,
          "to_longitude": 105.81523164343669,
          "from_longitude": 105.81545983860583
        }
      },
      {
        "id": 10291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81520980542133,
            21.011940286058135,
            105.8153976203198,
            21.012294937268152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81523353286155,
              21.011940286058135
            ],
            [
              105.81522690765283,
              21.01196356237944
            ],
            [
              105.81522150394969,
              21.011982566901125
            ],
            [
              105.81520980542133,
              21.012023691834692
            ],
            [
              105.8153976203198,
              21.012294937268152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10299",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011940286058135,
          "to_latitude": 21.012294937268152,
          "to_longitude": 105.8153976203198,
          "from_longitude": 105.81523353286155
        }
      },
      {
        "id": 10292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81477171244376,
            21.011750684906662,
            105.8151883059167,
            21.01203308231276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8151883059167,
              21.01195488394213
            ],
            [
              105.81518633296437,
              21.011955403718304
            ],
            [
              105.8151479227499,
              21.01196550879229
            ],
            [
              105.81502209408195,
              21.01203308231276
            ],
            [
              105.81495259386905,
              21.01196069809718
            ],
            [
              105.81477171244376,
              21.011750684906662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10300",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01195488394213,
          "to_latitude": 21.011750684906662,
          "to_longitude": 105.81477171244376,
          "from_longitude": 105.8151883059167
        }
      },
      {
        "id": 10293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81497127781681,
            21.01166740173324,
            105.81518057183399,
            21.011921021032208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81518057183399,
              21.011921021032208
            ],
            [
              105.81515338123043,
              21.01188807146412
            ],
            [
              105.81511031841578,
              21.011835888712447
            ],
            [
              105.81497127781681,
              21.01166740173324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10301",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011921021032208,
          "to_latitude": 21.01166740173324,
          "to_longitude": 105.81497127781681,
          "from_longitude": 105.81518057183399
        }
      },
      {
        "id": 10294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81514176838621,
            21.01188807146412,
            105.81515338123043,
            21.011897876543117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81514176838621,
              21.011897876543117
            ],
            [
              105.81514378397053,
              21.011896174237457
            ],
            [
              105.81514593648687,
              21.01189435746898
            ],
            [
              105.81514945347044,
              21.011891387967147
            ],
            [
              105.81515338123043,
              21.01188807146412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10302",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011897876543117,
          "to_latitude": 21.01188807146412,
          "to_longitude": 105.81515338123043,
          "from_longitude": 105.81514176838621
        }
      },
      {
        "id": 10295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81492937651026,
            21.01165300263473,
            105.81514016276854,
            21.01193077258267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81492937651026,
              21.01165300263473
            ],
            [
              105.81499871272136,
              21.011740379435647
            ],
            [
              105.81502998804321,
              21.01177979283795
            ],
            [
              105.81511111451043,
              21.01188202902507
            ],
            [
              105.8151230512016,
              21.011896419882166
            ],
            [
              105.81514016276854,
              21.01193077258267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10303",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01165300263473,
          "to_latitude": 21.01193077258267,
          "to_longitude": 105.81514016276854,
          "from_longitude": 105.81492937651026
        }
      },
      {
        "id": 10296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81499871272136,
            21.011730840639846,
            105.81501375719941,
            21.011740379435647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81499871272136,
              21.011740379435647
            ],
            [
              105.81501265479407,
              21.011731539599516
            ],
            [
              105.81501375719941,
              21.011730840639846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10304",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011740379435647,
          "to_latitude": 21.011730840639846,
          "to_longitude": 105.81501375719941,
          "from_longitude": 105.81499871272136
        }
      },
      {
        "id": 10297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81505345195306,
            21.01140880499172,
            105.81522549951508,
            21.011576334850652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81505345195306,
              21.011576334850652
            ],
            [
              105.81514161816033,
              21.01151473233906
            ],
            [
              105.81517502652393,
              21.01149138913061
            ],
            [
              105.81519052475133,
              21.0114809214339
            ],
            [
              105.81522549951508,
              21.011457301549502
            ],
            [
              105.81518957883851,
              21.01140880499172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_56_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10305",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 28.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011576334850652,
          "to_latitude": 21.01140880499172,
          "to_longitude": 105.81518957883851,
          "from_longitude": 105.81505345195306
        }
      },
      {
        "id": 10298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81503385815083,
            21.011465805694417,
            105.81525737058807,
            21.011605090848832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81503385815083,
              21.011605090848832
            ],
            [
              105.81514085478454,
              21.011531090750992
            ],
            [
              105.81518959551899,
              21.011497380898916
            ],
            [
              105.81523525046966,
              21.011465805694417
            ],
            [
              105.81525737058807,
              21.0114916422366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_56_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10306",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 29.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011605090848832,
          "to_latitude": 21.0114916422366,
          "to_longitude": 105.81525737058807,
          "from_longitude": 105.81503385815083
        }
      },
      {
        "id": 10299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81514161816033,
            21.01151473233906,
            105.81514602641658,
            21.011519830719084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81514602641658,
              21.011519830719084
            ],
            [
              105.81514564498856,
              21.01151938273866
            ],
            [
              105.81514334724311,
              21.011516776190515
            ],
            [
              105.81514262017323,
              21.01151591618731
            ],
            [
              105.81514161816033,
              21.01151473233906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_56_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10307",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 0.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011519830719084,
          "to_latitude": 21.01151473233906,
          "to_longitude": 105.81514161816033,
          "from_longitude": 105.81514602641658
        }
      },
      {
        "id": 10300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81513328180212,
            21.01151591618731,
            105.81514262017323,
            21.011531090750992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81514262017323,
              21.01151591618731
            ],
            [
              105.81513328180212,
              21.011522446102266
            ],
            [
              105.81513682050635,
              21.01152648554839
            ],
            [
              105.81513692891019,
              21.011526608771376
            ],
            [
              105.81513845047546,
              21.01152834561614
            ],
            [
              105.81514037372739,
              21.011530541183024
            ],
            [
              105.81514085478454,
              21.011531090750992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_56_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10308",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01151591618731,
          "to_latitude": 21.011531090750992,
          "to_longitude": 105.81514085478454,
          "from_longitude": 105.81514262017323
        }
      },
      {
        "id": 10301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81524974141972,
            21.011072410173764,
            105.81579755696562,
            21.011498095532115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81527778668139,
              21.011498095532115
            ],
            [
              105.81524974141972,
              21.011462745677516
            ],
            [
              105.81550725540231,
              21.011276255056423
            ],
            [
              105.81552246692827,
              21.01126207011373
            ],
            [
              105.81553115894778,
              21.011253963529736
            ],
            [
              105.81576691780013,
              21.011085192456935
            ],
            [
              105.81578477350644,
              21.011072410173764
            ],
            [
              105.81579755696562,
              21.01108773899231
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10309",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011498095532115,
          "to_latitude": 21.01108773899231,
          "to_longitude": 105.81579755696562,
          "from_longitude": 105.81527778668139
        }
      },
      {
        "id": 10302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81575240881067,
            21.011068826873196,
            105.81576691780013,
            21.011085192456935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81575240881067,
              21.011068826873196
            ],
            [
              105.81575294210488,
              21.011069430382715
            ],
            [
              105.81575321696488,
              21.01106973887231
            ],
            [
              105.81575462518133,
              21.01107132722233
            ],
            [
              105.81576691780013,
              21.011085192456935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10310",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011068826873196,
          "to_latitude": 21.011085192456935,
          "to_longitude": 105.81576691780013,
          "from_longitude": 105.81575240881067
        }
      },
      {
        "id": 10303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81519859560036,
            21.011169864323918,
            105.81552015525939,
            21.011450157020956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81519859560036,
              21.01139814434511
            ],
            [
              105.81523775202139,
              21.011450157020956
            ],
            [
              105.81537143782043,
              21.011357068342367
            ],
            [
              105.8155029079004,
              21.011265522959846
            ],
            [
              105.81550488005055,
              21.011264149633373
            ],
            [
              105.81552015525939,
              21.011253540266413
            ],
            [
              105.81544966268407,
              21.011169864323918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10311",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01139814434511,
          "to_latitude": 21.011169864323918,
          "to_longitude": 105.81544966268407,
          "from_longitude": 105.81519859560036
        }
      },
      {
        "id": 10304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81537143782043,
            21.011357068342367,
            105.81537672904419,
            21.011362861584058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81537143782043,
              21.011357068342367
            ],
            [
              105.81537233195021,
              21.011358047673422
            ],
            [
              105.81537410473123,
              21.011359990151558
            ],
            [
              105.81537587943114,
              21.01136193171726
            ],
            [
              105.81537672904419,
              21.011362861584058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10312",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011357068342367,
          "to_latitude": 21.011362861584058,
          "to_longitude": 105.81537672904419,
          "from_longitude": 105.81537143782043
        }
      },
      {
        "id": 10305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81516507357908,
            21.010065948435905,
            105.8153620413978,
            21.010213146470495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81516507357908,
              21.010213146470495
            ],
            [
              105.8152767465217,
              21.010129690585554
            ],
            [
              105.8153620413978,
              21.010065948435905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_78_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10313",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 26.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010213146470495,
          "to_latitude": 21.010065948435905,
          "to_longitude": 105.8153620413978,
          "from_longitude": 105.81516507357908
        }
      },
      {
        "id": 10306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81513205560199,
            21.01002402551173,
            105.81537401725265,
            21.010206312746615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81513205560199,
              21.010206312746615
            ],
            [
              105.81523333078586,
              21.01013001510487
            ],
            [
              105.81537401725265,
              21.01002402551173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_78_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10314",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 32.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010206312746615,
          "to_latitude": 21.01002402551173,
          "to_longitude": 105.81537401725265,
          "from_longitude": 105.81513205560199
        }
      },
      {
        "id": 10307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8152688938257,
            21.010119654501608,
            105.8152767465217,
            21.010129690585554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152688938257,
              21.010119654501608
            ],
            [
              105.81527281969508,
              21.01012467299754
            ],
            [
              105.8152767465217,
              21.010129690585554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_78_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10315",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010119654501608,
          "to_latitude": 21.010129690585554,
          "to_longitude": 105.8152767465217,
          "from_longitude": 105.8152688938257
        }
      },
      {
        "id": 10308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81523333078586,
            21.01012467299754,
            105.81527281969508,
            21.01014487676985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81527281969508,
              21.01012467299754
            ],
            [
              105.81524559694867,
              21.01014487676985
            ],
            [
              105.81524347730868,
              21.010142294659225
            ],
            [
              105.81523333078586,
              21.01013001510487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_78_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10316",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 5.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01012467299754,
          "to_latitude": 21.01013001510487,
          "to_longitude": 105.81523333078586,
          "from_longitude": 105.81527281969508
        }
      },
      {
        "id": 10309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81512306172722,
            21.010233391263085,
            105.81531783878226,
            21.01047693134855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81512306172722,
              21.010233391263085
            ],
            [
              105.81512575152593,
              21.010236281354615
            ],
            [
              105.81531783878226,
              21.01047693134855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10317",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010233391263085,
          "to_latitude": 21.01047693134855,
          "to_longitude": 105.81531783878226,
          "from_longitude": 105.81512306172722
        }
      },
      {
        "id": 10310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81511395436287,
            21.010239934520825,
            105.81529697256538,
            21.01047276266807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81511395436287,
              21.010239934520825
            ],
            [
              105.815116235431,
              21.01024283921557
            ],
            [
              105.81529697256538,
              21.01047276266807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10318",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010239934520825,
          "to_latitude": 21.01047276266807,
          "to_longitude": 105.81529697256538,
          "from_longitude": 105.81511395436287
        }
      },
      {
        "id": 10311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81487863191703,
            21.009929650077684,
            105.81512950314463,
            21.01022870245821
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81512950314463,
              21.01022870245821
            ],
            [
              105.81512866750182,
              21.010227686716224
            ],
            [
              105.81511662850879,
              21.010213030793953
            ],
            [
              105.81511113505366,
              21.0102121430412
            ],
            [
              105.81498986436503,
              21.010065622303657
            ],
            [
              105.81487863191703,
              21.009929650077684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10319",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01022870245821,
          "to_latitude": 21.009929650077684,
          "to_longitude": 105.81487863191703,
          "from_longitude": 105.81512950314463
        }
      },
      {
        "id": 10312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81487441394005,
            21.009942573798874,
            105.81511577595455,
            21.01023864772687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81511577595455,
              21.01023864772687
            ],
            [
              105.81511506336365,
              21.010237795782956
            ],
            [
              105.81496936768968,
              21.01006324381638
            ],
            [
              105.81487441394005,
              21.009942573798874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10320",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01023864772687,
          "to_latitude": 21.009942573798874,
          "to_longitude": 105.81487441394005,
          "from_longitude": 105.81511577595455
        }
      },
      {
        "id": 10313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81487808430667,
            21.010269449601363,
            105.81507505075531,
            21.01041664730896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81487808430667,
              21.01041664730896
            ],
            [
              105.81498441853236,
              21.010337181444736
            ],
            [
              105.81498975564926,
              21.010333193419825
            ],
            [
              105.81507505075531,
              21.010269449601363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_73_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10321",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 26.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01041664730896,
          "to_latitude": 21.010269449601363,
          "to_longitude": 105.81507505075531,
          "from_longitude": 105.81487808430667
        }
      },
      {
        "id": 10314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8148476471674,
            21.01022932339626,
            105.81508774346645,
            21.010401503211835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8148476471674,
              21.010401503211835
            ],
            [
              105.81494489420108,
              21.010331764399044
            ],
            [
              105.81508774346645,
              21.01022932339626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_73_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10322",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 31.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010401503211835,
          "to_latitude": 21.01022932339626,
          "to_longitude": 105.81508774346645,
          "from_longitude": 105.8148476471674
        }
      },
      {
        "id": 10315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81497778853002,
            21.010328581044,
            105.81498441853236,
            21.010337181444736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81497778853002,
              21.010328581044
            ],
            [
              105.81498017487378,
              21.01033156472047
            ],
            [
              105.81498056532307,
              21.010332080400378
            ],
            [
              105.81498441853236,
              21.010337181444736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_73_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10323",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010328581044,
          "to_latitude": 21.010337181444736,
          "to_longitude": 105.81498441853236,
          "from_longitude": 105.81497778853002
        }
      },
      {
        "id": 10316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81494489420108,
            21.010331764399044,
            105.81498056532307,
            21.01034837774253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81498056532307,
              21.010332080400378
            ],
            [
              105.81495860695577,
              21.01034837774253
            ],
            [
              105.81495535058252,
              21.010344422745774
            ],
            [
              105.81494489420108,
              21.010331764399044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_73_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10324",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 5.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010332080400378,
          "to_latitude": 21.010331764399044,
          "to_longitude": 105.81494489420108,
          "from_longitude": 105.81498056532307
        }
      },
      {
        "id": 10317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81516989104195,
            21.010260555882297,
            105.81567119734322,
            21.010594189139834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81567119734322,
              21.010260555882297
            ],
            [
              105.81564317261716,
              21.010279207298908
            ],
            [
              105.81516989104195,
              21.010594189139834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10325",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010260555882297,
          "to_latitude": 21.010594189139834,
          "to_longitude": 105.81516989104195,
          "from_longitude": 105.81567119734322
        }
      },
      {
        "id": 10318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81485929489399,
            21.01044737479615,
            105.81514730510924,
            21.01067520048315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81485929489399,
              21.01044737479615
            ],
            [
              105.81496048943096,
              21.010567795433115
            ],
            [
              105.81497922537372,
              21.010590090410172
            ],
            [
              105.81505074674578,
              21.01067520048315
            ],
            [
              105.81514730510924,
              21.010609353251407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10326",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01044737479615,
          "to_latitude": 21.010609353251407,
          "to_longitude": 105.81514730510924,
          "from_longitude": 105.81485929489399
        }
      },
      {
        "id": 10319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81497619337314,
            21.010590090410172,
            105.81497922537372,
            21.01059238016409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81497619337314,
              21.01059238016409
            ],
            [
              105.8149762163749,
              21.010592364699043
            ],
            [
              105.81497639274811,
              21.01059225095076
            ],
            [
              105.81497922537372,
              21.010590090410172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10327",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01059238016409,
          "to_latitude": 21.010590090410172,
          "to_longitude": 105.81497922537372,
          "from_longitude": 105.81497619337314
        }
      },
      {
        "id": 10320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81479178495712,
            21.010457581866618,
            105.8150368508274,
            21.010864070126836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8148472889774,
              21.010457581866618
            ],
            [
              105.8149322048439,
              21.010559883128874
            ],
            [
              105.81496401352206,
              21.01059819206297
            ],
            [
              105.8150368508274,
              21.010685258554716
            ],
            [
              105.81479178495712,
              21.010864070126836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.2_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10328",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010457581866618,
          "to_latitude": 21.010864070126836,
          "to_longitude": 105.81479178495712,
          "from_longitude": 105.8148472889774
        }
      },
      {
        "id": 10321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81496401352206,
            21.01059076379962,
            105.81497554107682,
            21.01059819206297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81497554107682,
              21.01059076379962
            ],
            [
              105.81497539460052,
              21.010590891856353
            ],
            [
              105.81497097848391,
              21.01059372566018
            ],
            [
              105.81496401352206,
              21.01059819206297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.2_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10329",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01059076379962,
          "to_latitude": 21.01059819206297,
          "to_longitude": 105.81496401352206,
          "from_longitude": 105.81497554107682
        }
      },
      {
        "id": 10322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81459709059898,
            21.01011695475812,
            105.81484590410406,
            21.010421677940762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81484590410406,
              21.010421677940762
            ],
            [
              105.81459709059898,
              21.01011695475812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10330",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010421677940762,
          "to_latitude": 21.01011695475812,
          "to_longitude": 105.81459709059898,
          "from_longitude": 105.81484590410406
        }
      },
      {
        "id": 10323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81457960966576,
            21.010118860251943,
            105.8148300079726,
            21.01043289899644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8148300079726,
              21.01043289899644
            ],
            [
              105.81457960966576,
              21.010118860251943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.2_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10331",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01043289899644,
          "to_latitude": 21.010118860251943,
          "to_longitude": 105.81457960966576,
          "from_longitude": 105.8148300079726
        }
      },
      {
        "id": 10324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81458722038853,
            21.010442369360252,
            105.81484509497237,
            21.010624352692712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81458722038853,
              21.010624352692712
            ],
            [
              105.8147559286558,
              21.010503224110877
            ],
            [
              105.81484362965077,
              21.010443369928417
            ],
            [
              105.81484509497237,
              21.010442369360252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_72_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10332",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 30.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010624352692712,
          "to_latitude": 21.010442369360252,
          "to_longitude": 105.81484509497237,
          "from_longitude": 105.81458722038853
        }
      },
      {
        "id": 10325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81457584157997,
            21.01043059903406,
            105.81480782160492,
            21.01059345171704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81457584157997,
              21.01059345171704
            ],
            [
              105.81470876363166,
              21.01049989121456
            ],
            [
              105.8147942995071,
              21.01044005826191
            ],
            [
              105.81480782160492,
              21.01043059903406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_72_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10333",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 27.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01059345171704,
          "to_latitude": 21.01043059903406,
          "to_longitude": 105.81480782160492,
          "from_longitude": 105.81457584157997
        }
      },
      {
        "id": 10326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81483385336233,
            21.01043018444571,
            105.81484362965077,
            21.010443369928417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81483385336233,
              21.01043018444571
            ],
            [
              105.81483784858365,
              21.010435575652952
            ],
            [
              105.81484012185116,
              21.01043863845375
            ],
            [
              105.81484362965077,
              21.010443369928417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_72_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10334",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01043018444571,
          "to_latitude": 21.010443369928417,
          "to_longitude": 105.81484362965077,
          "from_longitude": 105.81483385336233
        }
      },
      {
        "id": 10327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8147942995071,
            21.01043863845375,
            105.81484012185116,
            21.010459680125564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81484012185116,
              21.01043863845375
            ],
            [
              105.81481049492406,
              21.010459680125564
            ],
            [
              105.81480569710696,
              21.010453867340207
            ],
            [
              105.81480404615003,
              21.01045185923965
            ],
            [
              105.8147959998042,
              21.010442118513577
            ],
            [
              105.8147942995071,
              21.01044005826191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_72_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10335",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 6.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01043863845375,
          "to_latitude": 21.01044005826191,
          "to_longitude": 105.8147942995071,
          "from_longitude": 105.81484012185116
        }
      },
      {
        "id": 10328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81431803525632,
            21.010269330251624,
            105.8145702130167,
            21.0106226041673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8145702130167,
              21.0106226041673
            ],
            [
              105.81456695056205,
              21.01061859138562
            ],
            [
              105.81456602382693,
              21.01061745143139
            ],
            [
              105.81431803525632,
              21.010312618213998
            ],
            [
              105.81437480959066,
              21.010269330251624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10336",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0106226041673,
          "to_latitude": 21.010269330251624,
          "to_longitude": 105.81437480959066,
          "from_longitude": 105.8145702130167
        }
      },
      {
        "id": 10329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81424200237558,
            21.010321265086652,
            105.81456020136301,
            21.010629670178435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424200237558,
              21.01037129268171
            ],
            [
              105.81430892002984,
              21.010321265086652
            ],
            [
              105.8145569350805,
              21.010625661027795
            ],
            [
              105.81456020136301,
              21.010629670178435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.1_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10337",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01037129268171,
          "to_latitude": 21.010629670178435,
          "to_longitude": 105.81456020136301,
          "from_longitude": 105.81424200237558
        }
      },
      {
        "id": 10330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81456595592404,
            21.010621919541457,
            105.81476664283409,
            21.010867485492085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81476664283409,
              21.010867485492085
            ],
            [
              105.81456782206,
              21.010634007130637
            ],
            [
              105.81456595592404,
              21.01063152044351
            ],
            [
              105.81457115196966,
              21.010621919541457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10338",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010867485492085,
          "to_latitude": 21.010621919541457,
          "to_longitude": 105.81457115196966,
          "from_longitude": 105.81476664283409
        }
      },
      {
        "id": 10331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81433165827461,
            21.01064862830141,
            105.81455712806843,
            21.01080876329824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81433165827461,
              21.01080876329824
            ],
            [
              105.81446379611437,
              21.010714914990068
            ],
            [
              105.8144756003581,
              21.0107065316261
            ],
            [
              105.81455712806843,
              21.01064862830141
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_69_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10339",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 29.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01080876329824,
          "to_latitude": 21.01064862830141,
          "to_longitude": 105.81455712806843,
          "from_longitude": 105.81433165827461
        }
      },
      {
        "id": 10332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81433412003503,
            21.01063097198687,
            105.81452844814615,
            21.01077265308611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81433412003503,
              21.01077265308611
            ],
            [
              105.81442928502342,
              21.01070326955489
            ],
            [
              105.81452844814615,
              21.01063097198687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_69_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10340",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 25.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01077265308611,
          "to_latitude": 21.01063097198687,
          "to_longitude": 105.81452844814615,
          "from_longitude": 105.81433412003503
        }
      },
      {
        "id": 10333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81445686362234,
            21.010706052274127,
            105.81446379611437,
            21.010714914990068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81445686362234,
              21.010706052274127
            ],
            [
              105.81445879992593,
              21.010708529594336
            ],
            [
              105.81445924742475,
              21.010709100099312
            ],
            [
              105.81446317328388,
              21.010714118613954
            ],
            [
              105.81446379611437,
              21.010714914990068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_69_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10341",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010706052274127,
          "to_latitude": 21.010714914990068,
          "to_longitude": 105.81446379611437,
          "from_longitude": 105.81445686362234
        }
      },
      {
        "id": 10334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81442928502342,
            21.01070326955489,
            105.81445924742475,
            21.010720351672866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81445924742475,
              21.010709100099312
            ],
            [
              105.81444338410415,
              21.010720351672866
            ],
            [
              105.81443833252828,
              21.01071421763915
            ],
            [
              105.81443429488947,
              21.01070933965933
            ],
            [
              105.81442928502342,
              21.01070326955489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_69_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10342",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010709100099312,
          "to_latitude": 21.01070326955489,
          "to_longitude": 105.81442928502342,
          "from_longitude": 105.81445924742475
        }
      },
      {
        "id": 10335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409841482049,
            21.010545040099,
            105.81432346729757,
            21.010809059014115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81432346729757,
              21.010809059014115
            ],
            [
              105.81432309826063,
              21.010808589294893
            ],
            [
              105.81430752363673,
              21.010788675380972
            ],
            [
              105.81430291608791,
              21.010792042085594
            ],
            [
              105.81409841482049,
              21.010545040099
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10343",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010809059014115,
          "to_latitude": 21.010545040099,
          "to_longitude": 105.81409841482049,
          "from_longitude": 105.81432346729757
        }
      },
      {
        "id": 10336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409057631686,
            21.01054682805705,
            105.81432024423677,
            21.010820171110925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81432024423677,
              21.0108144305835
            ],
            [
              105.8143188933133,
              21.01081541976108
            ],
            [
              105.81431239927956,
              21.010820171110925
            ],
            [
              105.81409057631686,
              21.01054682805705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10344",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0108144305835,
          "to_latitude": 21.01054682805705,
          "to_longitude": 105.81409057631686,
          "from_longitude": 105.81432024423677
        }
      },
      {
        "id": 10337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81432350586134,
            21.010837431043743,
            105.81447524765517,
            21.0110331533803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81432350586134,
              21.010837431043743
            ],
            [
              105.81442372414655,
              21.010964628175188
            ],
            [
              105.81442389849198,
              21.010964849534936
            ],
            [
              105.81447524765517,
              21.0110331533803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10345",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010837431043743,
          "to_latitude": 21.0110331533803,
          "to_longitude": 105.81447524765517,
          "from_longitude": 105.81432350586134
        }
      },
      {
        "id": 10338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81433597226928,
            21.010831239365526,
            105.81472769354066,
            21.011057342755496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81433597226928,
              21.010831239365526
            ],
            [
              105.81441535110777,
              21.01093156311966
            ],
            [
              105.8144159080307,
              21.010932267682037
            ],
            [
              105.81451487118741,
              21.011057342755496
            ],
            [
              105.81472769354066,
              21.01090864604053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10346",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010831239365526,
          "to_latitude": 21.01090864604053,
          "to_longitude": 105.81472769354066,
          "from_longitude": 105.81433597226928
        }
      },
      {
        "id": 10339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8144117571685,
            21.010932267682037,
            105.8144159080307,
            21.01093488523387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8144117571685,
              21.01093488523387
            ],
            [
              105.81441228729163,
              21.010934551211765
            ],
            [
              105.8144159080307,
              21.010932267682037
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10347",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01093488523387,
          "to_latitude": 21.010932267682037,
          "to_longitude": 105.8144159080307,
          "from_longitude": 105.8144117571685
        }
      },
      {
        "id": 10340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81442372414655,
            21.0109596532926,
            105.81443144051921,
            21.010964628175188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81443144051921,
              21.0109596532926
            ],
            [
              105.8144308663454,
              21.010960023654746
            ],
            [
              105.81442774816301,
              21.010962033810895
            ],
            [
              105.81442372414655,
              21.010964628175188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10348",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0109596532926,
          "to_latitude": 21.010964628175188,
          "to_longitude": 105.81442372414655,
          "from_longitude": 105.81443144051921
        }
      },
      {
        "id": 10341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81504292871789,
            21.011011523105633,
            105.81524568023833,
            21.01115370329713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81504292871789,
              21.01115370329713
            ],
            [
              105.81513722059299,
              21.011087581502117
            ],
            [
              105.81516672828957,
              21.011066888990054
            ],
            [
              105.81524568023833,
              21.011011523105633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_57_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10349",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 26.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01115370329713,
          "to_latitude": 21.011011523105633,
          "to_longitude": 105.81524568023833,
          "from_longitude": 105.81504292871789
        }
      },
      {
        "id": 10342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81513125497392,
            21.011081413030208,
            105.81513722059299,
            21.011087581502117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81513722059299,
              21.011087581502117
            ],
            [
              105.8151347064597,
              21.011084981409496
            ],
            [
              105.81513167287393,
              21.011081846384357
            ],
            [
              105.81513125497392,
              21.011081413030208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_57_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10350",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011087581502117,
          "to_latitude": 21.011081413030208,
          "to_longitude": 105.81513125497392,
          "from_longitude": 105.81513722059299
        }
      },
      {
        "id": 10343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81479601131204,
            21.010699260789586,
            105.81525892331248,
            21.010954605948612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81479601131204,
              21.010876417796375
            ],
            [
              105.81504751846738,
              21.010699260789586
            ],
            [
              105.81505109813189,
              21.010703584559263
            ],
            [
              105.81513050824132,
              21.0107994991622
            ],
            [
              105.81525892331248,
              21.010954605948612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10351",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010876417796375,
          "to_latitude": 21.010954605948612,
          "to_longitude": 105.81525892331248,
          "from_longitude": 105.81479601131204
        }
      },
      {
        "id": 10344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81505109813189,
            21.01069751936618,
            105.81505939819657,
            21.010703584559263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81505109813189,
              21.010703584559263
            ],
            [
              105.81505799552895,
              21.010698544023242
            ],
            [
              105.8150593761567,
              21.010697534826644
            ],
            [
              105.81505939819657,
              21.01069751936618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10352",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010703584559263,
          "to_latitude": 21.01069751936618,
          "to_longitude": 105.81505939819657,
          "from_longitude": 105.81505109813189
        }
      },
      {
        "id": 10345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81478937992169,
            21.010894309988977,
            105.81500900301064,
            21.01116785951153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81500900301064,
              21.01116785951153
            ],
            [
              105.81500407644174,
              21.01116172489724
            ],
            [
              105.81478937992169,
              21.010894309988977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10353",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01116785951153,
          "to_latitude": 21.010894309988977,
          "to_longitude": 105.81478937992169,
          "from_longitude": 105.81500900301064
        }
      },
      {
        "id": 10346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8147762500174,
            21.010899477003072,
            105.81499960755467,
            21.011174502595924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81499960755467,
              21.011174502595924
            ],
            [
              105.81499471526524,
              21.011168480720734
            ],
            [
              105.8147762500174,
              21.010899477003072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10354",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011174502595924,
          "to_latitude": 21.010899477003072,
          "to_longitude": 105.8147762500174,
          "from_longitude": 105.81499960755467
        }
      },
      {
        "id": 10347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81501021996496,
            21.011166999228635,
            105.81519446517953,
            21.011393016635193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81501021996496,
              21.011166999228635
            ],
            [
              105.81501524869051,
              21.01117316948256
            ],
            [
              105.81519446517953,
              21.011393016635193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10355",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011166999228635,
          "to_latitude": 21.011393016635193,
          "to_longitude": 105.81519446517953,
          "from_longitude": 105.81501021996496
        }
      },
      {
        "id": 10348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81500200600624,
            21.011172806587265,
            105.81518061099973,
            21.011397311922646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81500200600624,
              21.011172806587265
            ],
            [
              105.81500724161515,
              21.011179521400457
            ],
            [
              105.81518061099973,
              21.011397311922646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10356",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011172806587265,
          "to_latitude": 21.011397311922646,
          "to_longitude": 105.81518061099973,
          "from_longitude": 105.81500200600624
        }
      },
      {
        "id": 10349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81479574077703,
            21.011198665142345,
            105.81497710695184,
            21.01132625754925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81479574077703,
              21.01132625754925
            ],
            [
              105.81490486159429,
              21.011249488968705
            ],
            [
              105.81491514937245,
              21.01124225180775
            ],
            [
              105.81497710695184,
              21.011198665142345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_62_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10357",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 23.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01132625754925,
          "to_latitude": 21.011198665142345,
          "to_longitude": 105.81497710695184,
          "from_longitude": 105.81479574077703
        }
      },
      {
        "id": 10350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81491135924817,
            21.011236903879464,
            105.81491514937245,
            21.01124225180775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81491514937245,
              21.01124225180775
            ],
            [
              105.81491216672075,
              21.01123804530593
            ],
            [
              105.81491135924817,
              21.011236903879464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D32_62_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10358",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 32,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01124225180775,
          "to_latitude": 21.011236903879464,
          "to_longitude": 105.81491135924817,
          "from_longitude": 105.81491514937245
        }
      },
      {
        "id": 10351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82319932140653,
            21.009490401176677,
            105.82380216965055,
            21.009799494476635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82319932140653,
              21.009799494476635
            ],
            [
              105.82350914269408,
              21.009640643314384
            ],
            [
              105.82380216965055,
              21.009490401176677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_39_Thái Hà",
          "maTaiSan": "04.O11.DODV.10359",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009799494476635,
          "to_latitude": 21.009490401176677,
          "to_longitude": 105.82380216965055,
          "from_longitude": 105.82319932140653
        }
      },
      {
        "id": 10352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82350914269408,
            21.009640643314384,
            105.82351622975396,
            21.009654798714852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82350914269408,
              21.009640643314384
            ],
            [
              105.8235127710571,
              21.009647842539785
            ],
            [
              105.82351622975396,
              21.009654798714852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_39_Thái Hà",
          "maTaiSan": "04.O11.DODV.10360",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009640643314384,
          "to_latitude": 21.009654798714852,
          "to_longitude": 105.82351622975396,
          "from_longitude": 105.82350914269408
        }
      },
      {
        "id": 10353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82384839193341,
            21.00863822046092,
            105.82400878161533,
            21.009423505700163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82384839193341,
              21.009423505700163
            ],
            [
              105.82391138413074,
              21.009211431010044
            ],
            [
              105.82400878161533,
              21.008883468249515
            ],
            [
              105.82389441807227,
              21.00863822046092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10361",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009423505700163,
          "to_latitude": 21.00863822046092,
          "to_longitude": 105.82389441807227,
          "from_longitude": 105.82384839193341
        }
      },
      {
        "id": 10354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82391138413074,
            21.009211431010044,
            105.82396919850252,
            21.009223431357764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82391138413074,
              21.009211431010044
            ],
            [
              105.82394444271151,
              21.009218279087193
            ],
            [
              105.82396919850252,
              21.009223431357764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_40_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10362",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009211431010044,
          "to_latitude": 21.009223431357764,
          "to_longitude": 105.82396919850252,
          "from_longitude": 105.82391138413074
        }
      },
      {
        "id": 10355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82363338021095,
            21.00847963187275,
            105.82371235589271,
            21.008719219864258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82371235589271,
              21.008719219864258
            ],
            [
              105.82371188338196,
              21.008718303569385
            ],
            [
              105.82369972212679,
              21.00869464368912
            ],
            [
              105.82367444487994,
              21.008645471511482
            ],
            [
              105.82367691484649,
              21.00861424854164
            ],
            [
              105.82363338021095,
              21.008529204540732
            ],
            [
              105.82369439221652,
              21.00847963187275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10363",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008719219864258,
          "to_latitude": 21.00847963187275,
          "to_longitude": 105.82369439221652,
          "from_longitude": 105.82371235589271
        }
      },
      {
        "id": 10356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8233605528097,
            21.008661276686276,
            105.8236448674768,
            21.00881513257819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8236448674768,
              21.008770674196004
            ],
            [
              105.82364190153693,
              21.008767532980638
            ],
            [
              105.82363217853164,
              21.00875188353771
            ],
            [
              105.82362391684516,
              21.00875176362959
            ],
            [
              105.82362519358966,
              21.008741779840022
            ],
            [
              105.82356180632856,
              21.008661276686276
            ],
            [
              105.8233605528097,
              21.00881513257819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10364",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": 42.509,
          "from_latitude": 21.008770674196004,
          "to_latitude": 21.00881513257819,
          "to_longitude": 105.8233605528097,
          "from_longitude": 105.8236448674768
        }
      },
      {
        "id": 10357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82344155551498,
            21.008634885094526,
            105.82377290584822,
            21.008880790030734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82344155551498,
              21.008880790030734
            ],
            [
              105.82356641375952,
              21.008794460526033
            ],
            [
              105.82360843491449,
              21.008758502909895
            ],
            [
              105.82362001942826,
              21.0087550741926
            ],
            [
              105.8236212601799,
              21.008754706896653
            ],
            [
              105.82363738256238,
              21.00873684522608
            ],
            [
              105.82368919195163,
              21.008696688080544
            ],
            [
              105.82369972212679,
              21.00869464368912
            ],
            [
              105.82370448708477,
              21.008693719204427
            ],
            [
              105.82372124824983,
              21.00867307249467
            ],
            [
              105.82377290584822,
              21.008634885094526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10365",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008880790030734,
          "to_latitude": 21.008634885094526,
          "to_longitude": 105.82377290584822,
          "from_longitude": 105.82344155551498
        }
      },
      {
        "id": 10358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82356641375952,
            21.008794460526033,
            105.82358515324314,
            21.00881686470679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82356641375952,
              21.008794460526033
            ],
            [
              105.82358015514095,
              21.00881088875784
            ],
            [
              105.82358496250559,
              21.008816638016658
            ],
            [
              105.82358515324314,
              21.00881686470679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10366",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008794460526033,
          "to_latitude": 21.00881686470679,
          "to_longitude": 105.82358515324314,
          "from_longitude": 105.82356641375952
        }
      },
      {
        "id": 10359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82350788077767,
            21.008871444938023,
            105.82351905187946,
            21.008884175155536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82350788077767,
              21.008871444938023
            ],
            [
              105.82350844994596,
              21.008872096120168
            ],
            [
              105.82351830752698,
              21.00888332701445
            ],
            [
              105.82351905187946,
              21.008884175155536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10367",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008871444938023,
          "to_latitude": 21.008884175155536,
          "to_longitude": 105.82351905187946,
          "from_longitude": 105.82350788077767
        }
      },
      {
        "id": 10360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82343450095355,
            21.00868052205799,
            105.82378439388837,
            21.009076571559316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82378439388837,
              21.00868052205799
            ],
            [
              105.82364760123065,
              21.008780678511815
            ],
            [
              105.82364860706531,
              21.00880164876046
            ],
            [
              105.82359529815271,
              21.008837329475604
            ],
            [
              105.82356679968424,
              21.008855795807424
            ],
            [
              105.82351905187947,
              21.00888417515553
            ],
            [
              105.8234899522222,
              21.008908464656468
            ],
            [
              105.82349176698536,
              21.008982366050407
            ],
            [
              105.82343450095355,
              21.009076571559316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10368",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": 60.881,
          "from_latitude": 21.00868052205799,
          "to_latitude": 21.009076571559316,
          "to_longitude": 105.82343450095355,
          "from_longitude": 105.82378439388837
        }
      },
      {
        "id": 10361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82322842572486,
            21.00898287425807,
            105.82344115620403,
            21.009205728838698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82322842572486,
              21.00898287425807
            ],
            [
              105.82322877288475,
              21.008983209482405
            ],
            [
              105.82331087139589,
              21.009072037600212
            ],
            [
              105.82338049494842,
              21.009070553641436
            ],
            [
              105.8234083229369,
              21.009144756395084
            ],
            [
              105.82340988826434,
              21.009167250919138
            ],
            [
              105.82344115620403,
              21.009205728838698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10369",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00898287425807,
          "to_latitude": 21.009205728838698,
          "to_longitude": 105.82344115620403,
          "from_longitude": 105.82322842572486
        }
      },
      {
        "id": 10362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82315682861298,
            21.00887516210918,
            105.82323991699863,
            21.00897436807373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82323991699863,
              21.00897436807373
            ],
            [
              105.82323968850557,
              21.00897409730771
            ],
            [
              105.82323412402528,
              21.008967451184116
            ],
            [
              105.82315682861298,
              21.00887516210918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10370",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00897436807373,
          "to_latitude": 21.00887516210918,
          "to_longitude": 105.82315682861298,
          "from_longitude": 105.82323991699863
        }
      },
      {
        "id": 10363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82302298436167,
            21.008803881148413,
            105.82325374959275,
            21.008974003833185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82325374959275,
              21.008803881148413
            ],
            [
              105.82315682861298,
              21.00887516210918
            ],
            [
              105.82302298436167,
              21.008974003833185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10371",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008803881148413,
          "to_latitude": 21.008974003833185,
          "to_longitude": 105.82302298436167,
          "from_longitude": 105.82325374959275
        }
      },
      {
        "id": 10364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82300955483923,
            21.008876412764504,
            105.82335536009836,
            21.00913963665815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82300955483923,
              21.00913963665815
            ],
            [
              105.82311051468673,
              21.009060572099855
            ],
            [
              105.82314229278175,
              21.009036009576384
            ],
            [
              105.82316393793809,
              21.009019280074973
            ],
            [
              105.82323042985153,
              21.00896788724934
            ],
            [
              105.82323412402528,
              21.008967451184116
            ],
            [
              105.8232386170128,
              21.00896692002848
            ],
            [
              105.82324306702793,
              21.00895803061605
            ],
            [
              105.8232512571689,
              21.008951645823405
            ],
            [
              105.82335536009836,
              21.008876412764504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10372",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00913963665815,
          "to_latitude": 21.008876412764504,
          "to_longitude": 105.82335536009836,
          "from_longitude": 105.82300955483923
        }
      },
      {
        "id": 10365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82314758559325,
            21.009046437926187,
            105.82335025027952,
            21.00926903150256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82314758559325,
              21.009046437926187
            ],
            [
              105.82314894429518,
              21.009047932517582
            ],
            [
              105.82314967395082,
              21.009048731957105
            ],
            [
              105.82316052739384,
              21.00904914913516
            ],
            [
              105.82335025027952,
              21.00926903150256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_8_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10374",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009046437926187,
          "to_latitude": 21.00926903150256,
          "to_longitude": 105.82335025027952,
          "from_longitude": 105.82314758559325
        }
      },
      {
        "id": 10366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82306914768024,
            21.009106573029996,
            105.82327706564041,
            21.009350447509767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82306914768024,
              21.009106573029996
            ],
            [
              105.82307460985427,
              21.009112981201774
            ],
            [
              105.82327706564041,
              21.009350447509767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10375",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009106573029996,
          "to_latitude": 21.009350447509767,
          "to_longitude": 105.82327706564041,
          "from_longitude": 105.82306914768024
        }
      },
      {
        "id": 10367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82297833836532,
            21.009117169767187,
            105.82309665731495,
            21.009422507683897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8230556863463,
              21.009117169767187
            ],
            [
              105.82305644761972,
              21.009117946473527
            ],
            [
              105.82305928951159,
              21.00912083389419
            ],
            [
              105.8229821880167,
              21.00918385101637
            ],
            [
              105.82309665731495,
              21.00932494418156
            ],
            [
              105.82297833836532,
              21.009422507683897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10376",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009117169767187,
          "to_latitude": 21.009422507683897,
          "to_longitude": 105.82297833836532,
          "from_longitude": 105.8230556863463
        }
      },
      {
        "id": 10368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82270977664052,
            21.009257688350093,
            105.82315566722505,
            21.009787325384433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82270977664052,
              21.009257688350093
            ],
            [
              105.82282233162238,
              21.00937897054084
            ],
            [
              105.82300851457782,
              21.00959129593683
            ],
            [
              105.82303966169079,
              21.009630205978016
            ],
            [
              105.82305240218206,
              21.009630831951775
            ],
            [
              105.8230490494179,
              21.00964272545862
            ],
            [
              105.82315566722505,
              21.009787325384433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10377",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 75.812,
          "from_latitude": 21.009257688350093,
          "to_latitude": 21.009787325384433,
          "to_longitude": 105.82315566722505,
          "from_longitude": 105.82270977664052
        }
      },
      {
        "id": 10369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82303080619785,
            21.009401725270525,
            105.82332263474233,
            21.00964961517985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82332263474233,
              21.009442413641185
            ],
            [
              105.82327837828763,
              21.009401725270525
            ],
            [
              105.82316103164952,
              21.009526920221884
            ],
            [
              105.82303572726605,
              21.00964540401754
            ],
            [
              105.82303080619785,
              21.00964961517985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11_Thái Hà",
          "maTaiSan": "04.O11.DODV.10379",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.29,
          "chatLieu": "HDPE",
          "chieuDai": 44.084,
          "from_latitude": 21.009442413641185,
          "to_latitude": 21.00964961517985,
          "to_longitude": 105.82303080619785,
          "from_longitude": 105.82332263474233
        }
      },
      {
        "id": 10370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82268071524663,
            21.009116292416046,
            105.82295655476021,
            21.00924881915595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82268071524663,
              21.00924881915595
            ],
            [
              105.82268211574466,
              21.009247761932013
            ],
            [
              105.82276566069929,
              21.009184837530455
            ],
            [
              105.8227751110812,
              21.00918145712328
            ],
            [
              105.82283044437924,
              21.009161663612304
            ],
            [
              105.8229085815171,
              21.009116292416046
            ],
            [
              105.82295655476021,
              21.009163219723234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10380",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00924881915595,
          "to_latitude": 21.009163219723234,
          "to_longitude": 105.82295655476021,
          "from_longitude": 105.82268071524663
        }
      },
      {
        "id": 10371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8227751110812,
            21.00918145712328,
            105.8229759720043,
            21.009401622063496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8227751110812,
              21.00918145712328
            ],
            [
              105.8229759720043,
              21.009401622063496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10381",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00918145712328,
          "to_latitude": 21.009401622063496,
          "to_longitude": 105.8229759720043,
          "from_longitude": 105.8227751110812
        }
      },
      {
        "id": 10372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82299378017451,
            21.009591295936815,
            105.82300851457782,
            21.00960339276473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82300851457782,
              21.009591295936815
            ],
            [
              105.82300415467793,
              21.009594874834956
            ],
            [
              105.82299725524638,
              21.009600537115038
            ],
            [
              105.82299378017451,
              21.00960339276473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10382",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009591295936815,
          "to_latitude": 21.00960339276473,
          "to_longitude": 105.82299378017451,
          "from_longitude": 105.82300851457782
        }
      },
      {
        "id": 10373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82268594534045,
            21.00928498088432,
            105.82274723484664,
            21.009337562817144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274723484664,
              21.009323263742303
            ],
            [
              105.82274059537484,
              21.0093294251387
            ],
            [
              105.82273053709132,
              21.009337562817144
            ],
            [
              105.82272047880778,
              21.009327860199857
            ],
            [
              105.82270472083057,
              21.009311897828578
            ],
            [
              105.82268594534045,
              21.00928498088432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13_Thái Hà",
          "maTaiSan": "04.O11.DODV.10383",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": 9.822,
          "from_latitude": 21.009323263742303,
          "to_latitude": 21.00928498088432,
          "to_longitude": 105.82268594534045,
          "from_longitude": 105.82274723484664
        }
      },
      {
        "id": 10374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82207563400341,
            21.009259185773754,
            105.82252575934595,
            21.009468392324198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82252575934595,
              21.009259185773754
            ],
            [
              105.8225257382739,
              21.00925920213371
            ],
            [
              105.82252398244492,
              21.0092605392679
            ],
            [
              105.8223713984691,
              21.009376717296668
            ],
            [
              105.82228659840364,
              21.009288424740525
            ],
            [
              105.82207563400341,
              21.009468392324198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14_Thái Hà",
          "maTaiSan": "04.O11.DODV.10384",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009259185773754,
          "to_latitude": 21.009468392324198,
          "to_longitude": 105.82207563400341,
          "from_longitude": 105.82252575934595
        }
      },
      {
        "id": 10375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82206820082759,
            21.00925601150957,
            105.82252190204849,
            21.009449501103905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82252190204849,
              21.00925601150957
            ],
            [
              105.82252188097644,
              21.009256027869508
            ],
            [
              105.82252119983796,
              21.00925653335617
            ],
            [
              105.8223879925898,
              21.00935538055093
            ],
            [
              105.82229569082929,
              21.009263253020098
            ],
            [
              105.82206820082759,
              21.009449501103905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_14A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10385",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00925601150957,
          "to_latitude": 21.009449501103905,
          "to_longitude": 105.82206820082759,
          "from_longitude": 105.82252190204849
        }
      },
      {
        "id": 10376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82225648695187,
            21.00901579557922,
            105.82248657963636,
            21.009270825357895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82225648695187,
              21.00901579557922
            ],
            [
              105.82235344062673,
              21.00912325823314
            ],
            [
              105.82237153320322,
              21.009143311866428
            ],
            [
              105.82248657963636,
              21.009270825357895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10386",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00901579557922,
          "to_latitude": 21.009270825357895,
          "to_longitude": 105.82248657963636,
          "from_longitude": 105.82225648695187
        }
      },
      {
        "id": 10377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82235344062673,
            21.00911197645507,
            105.8223697983776,
            21.00912325823314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223697983776,
              21.00911197645507
            ],
            [
              105.82236811756147,
              21.00911313528936
            ],
            [
              105.82236448953374,
              21.009115637600438
            ],
            [
              105.82235344062673,
              21.00912325823314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10387",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00911197645507,
          "to_latitude": 21.00912325823314,
          "to_longitude": 105.82235344062673,
          "from_longitude": 105.8223697983776
        }
      },
      {
        "id": 10378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8222683928833,
            21.00900306388357,
            105.82227719510564,
            21.009008618541813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227070991975,
              21.00900462890005
            ],
            [
              105.82226893575962,
              21.00900613141081
            ],
            [
              105.8222683928833,
              21.009006591067013
            ],
            [
              105.82227031816787,
              21.009008618541813
            ],
            [
              105.82227265965197,
              21.009006729418097
            ],
            [
              105.82227719510564,
              21.00900306388357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62_Thái Hà",
          "maTaiSan": "04.O11.DODV.10389",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00900462890005,
          "to_latitude": 21.00900306388357,
          "to_longitude": 105.82227719510564,
          "from_longitude": 105.82227070991975
        }
      },
      {
        "id": 10379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8217770114665,
            21.008990784527796,
            105.82225775041255,
            21.009661128230995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82225775041255,
              21.008990784527796
            ],
            [
              105.8222574545853,
              21.00899103976412
            ],
            [
              105.8217770114665,
              21.00940141228023
            ],
            [
              105.8220221935141,
              21.009661128230995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_63_Thái Hà",
          "maTaiSan": "04.O11.DODV.10390",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": 105.99,
          "from_latitude": 21.008990784527796,
          "to_latitude": 21.009661128230995,
          "to_longitude": 105.8220221935141,
          "from_longitude": 105.82225775041255
        }
      },
      {
        "id": 10380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82161327926514,
            21.008750719892593,
            105.82201552255192,
            21.009138586239615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82201552255192,
              21.008750719892593
            ],
            [
              105.82201543355329,
              21.008750803419428
            ],
            [
              105.8220056558515,
              21.008759926278948
            ],
            [
              105.82200533532237,
              21.008760237634224
            ],
            [
              105.82161327926514,
              21.009138586239615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_65_Thái Hà",
          "maTaiSan": "04.O11.DODV.10394",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008750719892593,
          "to_latitude": 21.009138586239615,
          "to_longitude": 105.82161327926514,
          "from_longitude": 105.82201552255192
        }
      },
      {
        "id": 10381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8218429789618,
            21.008556860776306,
            105.8221867191168,
            21.008905013265903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8221867191168,
              21.008905013265903
            ],
            [
              105.82214011573257,
              21.008860568884263
            ],
            [
              105.82212992665751,
              21.00886046378884
            ],
            [
              105.8221231255653,
              21.008854747727874
            ],
            [
              105.82212536358101,
              21.008846484394986
            ],
            [
              105.82196369383759,
              21.008697533497493
            ],
            [
              105.8219616721843,
              21.00869564738788
            ],
            [
              105.82191629052375,
              21.00864481706712
            ],
            [
              105.8218429789618,
              21.008556860776306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_66_Thái Hà",
          "maTaiSan": "04.O11.DODV.10396",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": 53.411,
          "from_latitude": 21.008905013265903,
          "to_latitude": 21.008556860776306,
          "to_longitude": 105.8218429789618,
          "from_longitude": 105.8221867191168
        }
      },
      {
        "id": 10382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82195732152654,
            21.008697533497493,
            105.82196369383759,
            21.008702142736325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82195732152654,
              21.008702142736325
            ],
            [
              105.82195737708739,
              21.008702100919507
            ],
            [
              105.82196105810635,
              21.008699439393105
            ],
            [
              105.82196369383759,
              21.008697533497493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_66_Thái Hà",
          "maTaiSan": "04.O11.DODV.10397",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008702142736325,
          "to_latitude": 21.008697533497493,
          "to_longitude": 105.82196369383759,
          "from_longitude": 105.82195732152654
        }
      },
      {
        "id": 10383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82180586613586,
            21.008575286102797,
            105.82198683838786,
            21.0087557277462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82198683838786,
              21.0087557277462
            ],
            [
              105.82190445995501,
              21.0086686570887
            ],
            [
              105.821896613042,
              21.008660970585968
            ],
            [
              105.82188531849089,
              21.00864990911461
            ],
            [
              105.82180586613586,
              21.008575286102797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_66A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10398",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0087557277462,
          "to_latitude": 21.008575286102797,
          "to_longitude": 105.82180586613586,
          "from_longitude": 105.82198683838786
        }
      },
      {
        "id": 10384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.821896613042,
            21.008649296843355,
            105.82191035420414,
            21.008660970585968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.821896613042,
              21.008660970585968
            ],
            [
              105.82190718412572,
              21.00865199022358
            ],
            [
              105.82191006218486,
              21.00864954483485
            ],
            [
              105.82191033409889,
              21.008649314101774
            ],
            [
              105.82191035420414,
              21.008649296843355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_66A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10399",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008660970585968,
          "to_latitude": 21.008649296843355,
          "to_longitude": 105.82191035420414,
          "from_longitude": 105.821896613042
        }
      },
      {
        "id": 10385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8216199286908,
            21.008263380872968,
            105.82183024731384,
            21.008545501034366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82179880815448,
              21.008545501034366
            ],
            [
              105.82180450803268,
              21.008539825627366
            ],
            [
              105.82180744695789,
              21.00853690213906
            ],
            [
              105.82178467781283,
              21.008515093471864
            ],
            [
              105.82183024731384,
              21.008467112545777
            ],
            [
              105.82181417218038,
              21.008427776037973
            ],
            [
              105.8216199286908,
              21.008263380872968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67_Thái Hà",
          "maTaiSan": "04.O11.DODV.10401",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008545501034366,
          "to_latitude": 21.008263380872968,
          "to_longitude": 105.8216199286908,
          "from_longitude": 105.82179880815448
        }
      },
      {
        "id": 10386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149359154066,
            21.008137844978776,
            105.82177989286683,
            21.00856433568849
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82177989286683,
              21.008546618443937
            ],
            [
              105.8217784714838,
              21.008545442983028
            ],
            [
              105.82176727426426,
              21.00853616761717
            ],
            [
              105.82174127079382,
              21.00856433568849
            ],
            [
              105.82149359154066,
              21.008371719639275
            ],
            [
              105.82164226489711,
              21.008205959630796
            ],
            [
              105.82155424153667,
              21.008137844978776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_68_Thái Hà",
          "maTaiSan": "04.O11.DODV.10402",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008546618443937,
          "to_latitude": 21.008137844978776,
          "to_longitude": 105.82155424153667,
          "from_longitude": 105.82177989286683
        }
      },
      {
        "id": 10387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82150259715233,
            21.008396209633325,
            105.82174467030559,
            21.0085840403422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82174467030559,
              21.0085840403422
            ],
            [
              105.82174344352923,
              21.008583096070733
            ],
            [
              105.82150259715233,
              21.008396209633325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_68A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10403",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0085840403422,
          "to_latitude": 21.008396209633325,
          "to_longitude": 105.82150259715233,
          "from_longitude": 105.82174467030559
        }
      },
      {
        "id": 10388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149738528167,
            21.008825369035122,
            105.82150790067072,
            21.008833265771692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82149738528167,
              21.008825369035122
            ],
            [
              105.82150650789823,
              21.00883222113842
            ],
            [
              105.82150790067072,
              21.008833265771692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Thái Hà",
          "maTaiSan": "04.O11.DODV.10405",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008825369035122,
          "to_latitude": 21.008833265771692,
          "to_longitude": 105.82150790067072,
          "from_longitude": 105.82149738528167
        }
      },
      {
        "id": 10389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131103717929,
            21.00863221819825,
            105.82153092651829,
            21.008810074441104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82153092651829,
              21.008810074441104
            ],
            [
              105.82153025793235,
              21.008809534829023
            ],
            [
              105.82151987348442,
              21.00880113346072
            ],
            [
              105.82131103717929,
              21.00863221819825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_70A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10406",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008810074441104,
          "to_latitude": 21.00863221819825,
          "to_longitude": 105.82131103717929,
          "from_longitude": 105.82153092651829
        }
      },
      {
        "id": 10390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82127228431062,
            21.008410654762184,
            105.82152317438417,
            21.008817940137945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82152317438417,
              21.008817940137945
            ],
            [
              105.82152249047259,
              21.008817412341777
            ],
            [
              105.82151378690754,
              21.00881067473269
            ],
            [
              105.82127228431062,
              21.00862376856354
            ],
            [
              105.82145885240473,
              21.008410654762184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_69_Thái Hà",
          "maTaiSan": "04.O11.DODV.10407",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008817940137945,
          "to_latitude": 21.008410654762184,
          "to_longitude": 105.82145885240473,
          "from_longitude": 105.82152317438417
        }
      },
      {
        "id": 10391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82105313529068,
            21.00869061985306,
            105.82140608325724,
            21.008943820954432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82140608325724,
              21.008943820954432
            ],
            [
              105.82140605432694,
              21.008943807545666
            ],
            [
              105.82139070835319,
              21.00893644895895
            ],
            [
              105.82137406233306,
              21.00892846709615
            ],
            [
              105.82105313529068,
              21.00869061985306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71_Thái Hà",
          "maTaiSan": "04.O11.DODV.10408",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008943820954432,
          "to_latitude": 21.00869061985306,
          "to_longitude": 105.82105313529068,
          "from_longitude": 105.82140608325724
        }
      },
      {
        "id": 10392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.821411397929,
            21.008924579130575,
            105.82177693784932,
            21.009342566684147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141252170021,
              21.008924579130575
            ],
            [
              105.82141323252687,
              21.008925279313708
            ],
            [
              105.82141418897398,
              21.008926216763307
            ],
            [
              105.821411397929,
              21.008941572458117
            ],
            [
              105.82146207747806,
              21.00899975219268
            ],
            [
              105.8215796930355,
              21.009132148512204
            ],
            [
              105.82177693784932,
              21.009342566684147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72_Thái Hà",
          "maTaiSan": "04.O11.DODV.10409",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008924579130575,
          "to_latitude": 21.009342566684147,
          "to_longitude": 105.82177693784932,
          "from_longitude": 105.82141252170021
        }
      },
      {
        "id": 10393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131864694608,
            21.008931363147177,
            105.82144523358046,
            21.009176308799393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82140543695986,
              21.008931363147177
            ],
            [
              105.82140637368991,
              21.00893221307891
            ],
            [
              105.82140900500461,
              21.00893459393076
            ],
            [
              105.82141081313023,
              21.008964948099365
            ],
            [
              105.82131864694608,
              21.009052782022742
            ],
            [
              105.82144523358046,
              21.009176308799393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73_Thái Hà",
          "maTaiSan": "04.O11.DODV.10410",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008931363147177,
          "to_latitude": 21.009176308799393,
          "to_longitude": 105.82144523358046,
          "from_longitude": 105.82140543695986
        }
      },
      {
        "id": 10394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82313421170095,
            21.007987631813855,
            105.82352222670791,
            21.0082993539348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82313421170095,
              21.0082993539348
            ],
            [
              105.82330482909201,
              21.00815505996632
            ],
            [
              105.82330897599256,
              21.008154217967157
            ],
            [
              105.82330950828613,
              21.008154109714386
            ],
            [
              105.8233118872596,
              21.008150993812915
            ],
            [
              105.82333493055448,
              21.008119132084083
            ],
            [
              105.82336910274665,
              21.008092499484448
            ],
            [
              105.82337926302606,
              21.0080845805068
            ],
            [
              105.82345297586771,
              21.00802907188436
            ],
            [
              105.82352222670791,
              21.007987631813855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10411",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0082993539348,
          "to_latitude": 21.007987631813855,
          "to_longitude": 105.82352222670791,
          "from_longitude": 105.82313421170095
        }
      },
      {
        "id": 10395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82336910274665,
            21.008092499484448,
            105.8233907246421,
            21.008109598640473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82336910274665,
              21.008092499484448
            ],
            [
              105.82338489433286,
              21.008104987874436
            ],
            [
              105.82338986386867,
              21.008108919062195
            ],
            [
              105.8233907246421,
              21.008109598640473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10412",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008092499484448,
          "to_latitude": 21.008109598640473,
          "to_longitude": 105.8233907246421,
          "from_longitude": 105.82336910274665
        }
      },
      {
        "id": 10396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8231626735276,
            21.00801071642896,
            105.82355023762214,
            21.008342387819415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8231626735276,
              21.008342387819415
            ],
            [
              105.82332631129326,
              21.00817913091428
            ],
            [
              105.82334293696118,
              21.00816324129109
            ],
            [
              105.82335611309674,
              21.00815064797411
            ],
            [
              105.82338591393201,
              21.008122165936843
            ],
            [
              105.82347754847537,
              21.008052589536252
            ],
            [
              105.82355023762214,
              21.00801071642896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10413",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008342387819415,
          "to_latitude": 21.00801071642896,
          "to_longitude": 105.82355023762214,
          "from_longitude": 105.8231626735276
        }
      },
      {
        "id": 10397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82333238253631,
            21.008155951826318,
            105.82334293696118,
            21.00816324129109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82333238253631,
              21.008155951826318
            ],
            [
              105.82333295898009,
              21.008156351876803
            ],
            [
              105.82334293696118,
              21.00816324129109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10414",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008155951826318,
          "to_latitude": 21.00816324129109,
          "to_longitude": 105.82334293696118,
          "from_longitude": 105.82333238253631
        }
      },
      {
        "id": 10398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82321192679044,
            21.007932339976982,
            105.82332032746598,
            21.00816705210249
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82332032746598,
              21.00816705210249
            ],
            [
              105.82331981739624,
              21.008166477407972
            ],
            [
              105.82330897599256,
              21.008154217967157
            ],
            [
              105.82329242983127,
              21.00813551088791
            ],
            [
              105.82321192679044,
              21.00797657012819
            ],
            [
              105.8232901618053,
              21.007932339976982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10415",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00816705210249,
          "to_latitude": 21.007932339976982,
          "to_longitude": 105.8232901618053,
          "from_longitude": 105.82332032746598
        }
      },
      {
        "id": 10399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8235721774702,
            21.008023353887005,
            105.82370102951553,
            21.00826887864947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8235721774702,
              21.008023353887005
            ],
            [
              105.82363520785893,
              21.008146839748225
            ],
            [
              105.82364222605706,
              21.008159851995163
            ],
            [
              105.82370102951553,
              21.00826887864947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42A_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10416",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008023353887005,
          "to_latitude": 21.00826887864947,
          "to_longitude": 105.82370102951553,
          "from_longitude": 105.8235721774702
        }
      },
      {
        "id": 10400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82370571451636,
            21.008287735227764,
            105.82383704172774,
            21.008556785777326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82370571451636,
              21.008287735227764
            ],
            [
              105.82378929949247,
              21.00844673035823
            ],
            [
              105.82379028386033,
              21.00844854649906
            ],
            [
              105.82383704172774,
              21.00853479240503
            ],
            [
              105.82383316677564,
              21.008556785777326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10417",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008287735227764,
          "to_latitude": 21.008556785777326,
          "to_longitude": 105.82383316677564,
          "from_longitude": 105.82370571451636
        }
      },
      {
        "id": 10401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82379028386033,
            21.008437539454917,
            105.8238138590526,
            21.00844854649906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82379028386033,
              21.00844854649906
            ],
            [
              105.82379300385075,
              21.00844727603988
            ],
            [
              105.82380600609125,
              21.008441205733988
            ],
            [
              105.8238138590526,
              21.008437539454917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10418",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00844854649906,
          "to_latitude": 21.008437539454917,
          "to_longitude": 105.8238138590526,
          "from_longitude": 105.82379028386033
        }
      },
      {
        "id": 10402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82364222605706,
            21.008152788820276,
            105.82365564990907,
            21.008159851995163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82364222605706,
              21.008159851995163
            ],
            [
              105.82364755013552,
              21.008157050609906
            ],
            [
              105.82365564990907,
              21.008152788820276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_42A_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10419",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008159851995163,
          "to_latitude": 21.008152788820276,
          "to_longitude": 105.82365564990907,
          "from_longitude": 105.82364222605706
        }
      },
      {
        "id": 10403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82312817217907,
            21.00833796630765,
            105.82329532684331,
            21.00854640656722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82312817217907,
              21.00833796630765
            ],
            [
              105.82313569537895,
              21.00834414773523
            ],
            [
              105.82318125678019,
              21.008381572057957
            ],
            [
              105.82329532684331,
              21.00854640656722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10420",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00833796630765,
          "to_latitude": 21.00854640656722,
          "to_longitude": 105.82329532684331,
          "from_longitude": 105.82312817217907
        }
      },
      {
        "id": 10404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82280615361954,
            21.00838441399109,
            105.82312870688399,
            21.008746820385912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82280615361954,
              21.008746820385912
            ],
            [
              105.82295537065096,
              21.00863304188549
            ],
            [
              105.82297900078711,
              21.00861502383881
            ],
            [
              105.82298067951311,
              21.008613310427243
            ],
            [
              105.82306059912996,
              21.008531715649603
            ],
            [
              105.82305920518341,
              21.008506433833986
            ],
            [
              105.82312870688399,
              21.00838441399109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10421",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008746820385912,
          "to_latitude": 21.00838441399109,
          "to_longitude": 105.82312870688399,
          "from_longitude": 105.82280615361954
        }
      },
      {
        "id": 10405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82294350532656,
            21.008618944790083,
            105.82295537065096,
            21.00863304188549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82294350532656,
              21.008618944790083
            ],
            [
              105.82294529069225,
              21.008621065966253
            ],
            [
              105.82295113573804,
              21.00862801151818
            ],
            [
              105.82295273520162,
              21.00862991049612
            ],
            [
              105.82295537065096,
              21.00863304188549
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_21_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10422",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008618944790083,
          "to_latitude": 21.00863304188549,
          "to_longitude": 105.82295537065096,
          "from_longitude": 105.82294350532656
        }
      },
      {
        "id": 10406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82293312782222,
            21.00812465251913,
            105.82309116270174,
            21.008288391038718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82293312782222,
              21.00812465251913
            ],
            [
              105.82294922106867,
              21.008143431929568
            ],
            [
              105.82296732598441,
              21.00816095937696
            ],
            [
              105.82299177854097,
              21.00818516765251
            ],
            [
              105.82309116270174,
              21.008288391038718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10423",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": 24.473,
          "from_latitude": 21.00812465251913,
          "to_latitude": 21.008288391038718,
          "to_longitude": 105.82309116270174,
          "from_longitude": 105.82293312782222
        }
      },
      {
        "id": 10407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82301121744904,
            21.008217577751946,
            105.82302298319223,
            21.008230469100916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82302298319223,
              21.008217577751946
            ],
            [
              105.82301627629815,
              21.008225122335528
            ],
            [
              105.82301121744904,
              21.008230469100916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10424",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": 1.88,
          "from_latitude": 21.008217577751946,
          "to_latitude": 21.008230469100916,
          "to_longitude": 105.82301121744904,
          "from_longitude": 105.82302298319223
        }
      },
      {
        "id": 10408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.822877318175,
            21.007843594425644,
            105.82329367924028,
            21.008096913128515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.822877318175,
              21.008096913128515
            ],
            [
              105.82288045811266,
              21.008091710710985
            ],
            [
              105.82290496461397,
              21.008070505205964
            ],
            [
              105.82299820481822,
              21.007998369031924
            ],
            [
              105.82317890574785,
              21.007911808587963
            ],
            [
              105.82317029930884,
              21.007899634068437
            ],
            [
              105.82329367924028,
              21.007843594425644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10425",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": 53.644,
          "from_latitude": 21.008096913128515,
          "to_latitude": 21.007843594425644,
          "to_longitude": 105.82329367924028,
          "from_longitude": 105.822877318175
        }
      },
      {
        "id": 10409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82239438833298,
            21.008059902371752,
            105.82284931476372,
            21.00811097797847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82239438833298,
              21.008109685286104
            ],
            [
              105.82247303574681,
              21.00811097797847
            ],
            [
              105.8226015435641,
              21.0081082062671
            ],
            [
              105.82261123954937,
              21.008102264033543
            ],
            [
              105.8226257528476,
              21.00809336934667
            ],
            [
              105.8226451706947,
              21.008081468493174
            ],
            [
              105.82269650300968,
              21.008059902371752
            ],
            [
              105.8227754914628,
              21.008062349407332
            ],
            [
              105.82284931476372,
              21.00807763727389
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10426",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008109685286104,
          "to_latitude": 21.00807763727389,
          "to_longitude": 105.82284931476372,
          "from_longitude": 105.82239438833298
        }
      },
      {
        "id": 10410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8226257528476,
            21.00809336934667,
            105.8226309567232,
            21.008099870009573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8226257528476,
              21.00809336934667
            ],
            [
              105.82262637366635,
              21.008094144024486
            ],
            [
              105.82262784001122,
              21.00809597590731
            ],
            [
              105.82263092089654,
              21.008099826827834
            ],
            [
              105.8226309567232,
              21.008099870009573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10427",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00809336934667,
          "to_latitude": 21.008099870009573,
          "to_longitude": 105.8226309567232,
          "from_longitude": 105.8226257528476
        }
      },
      {
        "id": 10411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82251025592616,
            21.00810028111457,
            105.82265384430278,
            21.008469276399317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82262951820879,
              21.00810065825492
            ],
            [
              105.82262934670592,
              21.008100430567445
            ],
            [
              105.8226292468119,
              21.00810028111457
            ],
            [
              105.82262907515302,
              21.008100375879984
            ],
            [
              105.8226293686035,
              21.008100740268592
            ],
            [
              105.82262983348254,
              21.008101318796616
            ],
            [
              105.82264604375632,
              21.00824949076099
            ],
            [
              105.82265384430278,
              21.008456624507946
            ],
            [
              105.82251025592616,
              21.008469276399317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_28.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10428",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00810065825492,
          "to_latitude": 21.008469276399317,
          "to_longitude": 105.82251025592616,
          "from_longitude": 105.82262951820879
        }
      },
      {
        "id": 10412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82246935714163,
            21.00832488283345,
            105.8226488829803,
            21.008367454460714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8226488829803,
              21.00832488283345
            ],
            [
              105.82260078538387,
              21.00832895672545
            ],
            [
              105.82254244734303,
              21.008333338581625
            ],
            [
              105.82246935714163,
              21.008338659407695
            ],
            [
              105.82247069824552,
              21.008367454460714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_28.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10429",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48.68,
          "chatLieu": "HDPE",
          "chieuDai": 21.918,
          "from_latitude": 21.00832488283345,
          "to_latitude": 21.008367454460714,
          "to_longitude": 105.82247069824552,
          "from_longitude": 105.8226488829803
        }
      },
      {
        "id": 10413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82211504477661,
            21.008098987747097,
            105.82263119447593,
            21.008185866449583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82211504477661,
              21.008098987747097
            ],
            [
              105.82230013076222,
              21.008185866449583
            ],
            [
              105.8223417687029,
              21.0081577103607
            ],
            [
              105.82238404296989,
              21.0081291248636
            ],
            [
              105.82259866203688,
              21.008136685407255
            ],
            [
              105.82263035957067,
              21.008129906110383
            ],
            [
              105.82263119447593,
              21.00812972775162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10430",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008098987747097,
          "to_latitude": 21.00812972775162,
          "to_longitude": 105.82263119447593,
          "from_longitude": 105.82211504477661
        }
      },
      {
        "id": 10414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82232397738461,
            21.00815752334687,
            105.8223309481084,
            21.00816502738063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8223309481084,
              21.00816502738063
            ],
            [
              105.82232787057994,
              21.008161507109303
            ],
            [
              105.82232397738461,
              21.00815752334687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10431",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": 1.093,
          "from_latitude": 21.00816502738063,
          "to_latitude": 21.00815752334687,
          "to_longitude": 105.82232397738461,
          "from_longitude": 105.8223309481084
        }
      },
      {
        "id": 10415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82191248998083,
            21.00807646182611,
            105.82210255239234,
            21.008449554891264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82210255239234,
              21.008077511539764
            ],
            [
              105.82210205433972,
              21.00807842710875
            ],
            [
              105.82209809885975,
              21.00807646182611
            ],
            [
              105.82209774477401,
              21.00807696844079
            ],
            [
              105.82191248998083,
              21.008297624843088
            ],
            [
              105.82194794990073,
              21.008361150986335
            ],
            [
              105.82193863497928,
              21.008421565895528
            ],
            [
              105.8219250284173,
              21.008449554891264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10432",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008077511539764,
          "to_latitude": 21.008449554891264,
          "to_longitude": 105.8219250284173,
          "from_longitude": 105.82210255239234
        }
      },
      {
        "id": 10416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82179511439166,
            21.00801166761011,
            105.82210209509644,
            21.008118616025342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82210209509644,
              21.008076912199385
            ],
            [
              105.82210116475781,
              21.008076175143863
            ],
            [
              105.82209049420733,
              21.008067692997567
            ],
            [
              105.82201027632678,
              21.00801166761011
            ],
            [
              105.82191397863926,
              21.008118616025342
            ],
            [
              105.82179511439166,
              21.00802754227531
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10433",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008076912199385,
          "to_latitude": 21.00802754227531,
          "to_longitude": 105.82179511439166,
          "from_longitude": 105.82210209509644
        }
      },
      {
        "id": 10417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82198382399373,
            21.007852350807518,
            105.82233700687758,
            21.008109315995913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233700687758,
              21.007852350807518
            ],
            [
              105.82226089905548,
              21.007988501722092
            ],
            [
              105.82218412082068,
              21.008109315995913
            ],
            [
              105.82212622642912,
              21.008077333947245
            ],
            [
              105.82211735384577,
              21.008072574349516
            ],
            [
              105.82213495988974,
              21.008036335808825
            ],
            [
              105.82209889614244,
              21.007988829552538
            ],
            [
              105.82209364489314,
              21.007966829717432
            ],
            [
              105.82198382399373,
              21.007891144053488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10434",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48.29,
          "chatLieu": "HDPE",
          "chieuDai": 68.175,
          "from_latitude": 21.007852350807518,
          "to_latitude": 21.007891144053488,
          "to_longitude": 105.82198382399373,
          "from_longitude": 105.82233700687758
        }
      },
      {
        "id": 10418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82211647359877,
            21.00807567886361,
            105.82212314111216,
            21.00808500538425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82212314111216,
              21.00807567886361
            ],
            [
              105.82212041836435,
              21.008079894911962
            ],
            [
              105.82211647359877,
              21.00808500538425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10435",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": 1.245,
          "from_latitude": 21.00807567886361,
          "to_latitude": 21.00808500538425,
          "to_longitude": 105.82211647359877,
          "from_longitude": 105.82212314111216
        }
      },
      {
        "id": 10419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82251134782881,
            21.008596735403362,
            105.82276915783106,
            21.008787803456233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82276915783106,
              21.008787803456233
            ],
            [
              105.82276880642998,
              21.00878722076715
            ],
            [
              105.82265449607253,
              21.008596735403362
            ],
            [
              105.82251134782881,
              21.008656890414763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10436",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008787803456233,
          "to_latitude": 21.008656890414763,
          "to_longitude": 105.82251134782881,
          "from_longitude": 105.82276915783106
        }
      },
      {
        "id": 10420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82276037173857,
            21.008801635506867,
            105.82291040740782,
            21.009005134247193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82276037173857,
              21.008801635506867
            ],
            [
              105.82276137634301,
              21.008802232199166
            ],
            [
              105.82279525288449,
              21.008822325087294
            ],
            [
              105.82287651723122,
              21.008919773344218
            ],
            [
              105.82286608482796,
              21.008951578551283
            ],
            [
              105.82291040740782,
              21.009005134247193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10437",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008801635506867,
          "to_latitude": 21.009005134247193,
          "to_longitude": 105.82291040740782,
          "from_longitude": 105.82276037173857
        }
      },
      {
        "id": 10421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82259811151187,
            21.0087902614783,
            105.82275470248598,
            21.00917067876018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82275470248598,
              21.0087902614783
            ],
            [
              105.82268386854969,
              21.008899034562834
            ],
            [
              105.82264456881363,
              21.00893560654458
            ],
            [
              105.82263981563962,
              21.008940262175027
            ],
            [
              105.82263880723563,
              21.008941253374005
            ],
            [
              105.82263475534391,
              21.00894521825823
            ],
            [
              105.82262549489278,
              21.008954290781006
            ],
            [
              105.82259811151187,
              21.008981112615484
            ],
            [
              105.82260183342218,
              21.00898713902126
            ],
            [
              105.82261647356101,
              21.00901083938769
            ],
            [
              105.82262344085764,
              21.00902211953278
            ],
            [
              105.82264063247673,
              21.00904995066643
            ],
            [
              105.82263860543719,
              21.009060769386547
            ],
            [
              105.82261801934804,
              21.00917067876018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10438",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0087902614783,
          "to_latitude": 21.00917067876018,
          "to_longitude": 105.82261801934804,
          "from_longitude": 105.82275470248598
        }
      },
      {
        "id": 10422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8220307933325,
            21.008514517182647,
            105.82264477592192,
            21.009020050973966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82264477592192,
              21.009020050973966
            ],
            [
              105.8226357246035,
              21.009016389691027
            ],
            [
              105.82262065672623,
              21.009010290778193
            ],
            [
              105.82261900248294,
              21.009011558776944
            ],
            [
              105.822616473561,
              21.00901083938769
            ],
            [
              105.82261301207254,
              21.00900985534467
            ],
            [
              105.8226098132604,
              21.009005902930106
            ],
            [
              105.82259064150117,
              21.00899814565619
            ],
            [
              105.82253934697108,
              21.00894331750634
            ],
            [
              105.82248081985651,
              21.008944795359554
            ],
            [
              105.82235178431671,
              21.00880489936795
            ],
            [
              105.82213457116754,
              21.008596702713454
            ],
            [
              105.8220307933325,
              21.008514517182647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15a_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10439",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009020050973966,
          "to_latitude": 21.008514517182647,
          "to_longitude": 105.8220307933325,
          "from_longitude": 105.82264477592192
        }
      },
      {
        "id": 10423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82204233923886,
            21.008508426947387,
            105.82264348975647,
            21.009003538970596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82264348975647,
              21.009003538970596
            ],
            [
              105.82263248366624,
              21.008998964038824
            ],
            [
              105.82260652061561,
              21.008988168623507
            ],
            [
              105.82260447251672,
              21.008988654524696
            ],
            [
              105.82260183342218,
              21.008987139021272
            ],
            [
              105.82259521589832,
              21.008983337713026
            ],
            [
              105.822591066875,
              21.00897432278602
            ],
            [
              105.82254505236745,
              21.008937072112012
            ],
            [
              105.82249438608,
              21.008934376998983
            ],
            [
              105.82240891521256,
              21.00885054696998
            ],
            [
              105.82223563874105,
              21.00867191119529
            ],
            [
              105.82204233923886,
              21.008508426947387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_15_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10440",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009003538970596,
          "to_latitude": 21.008508426947387,
          "to_longitude": 105.82204233923886,
          "from_longitude": 105.82264348975647
        }
      },
      {
        "id": 10424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82253935500735,
            21.008790549050964,
            105.82265574871633,
            21.008952410333226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82265574871633,
              21.008952410333226
            ],
            [
              105.82265167055068,
              21.00894930217181
            ],
            [
              105.82263981563962,
              21.008940262175027
            ],
            [
              105.82263504972114,
              21.008936628046673
            ],
            [
              105.8225873485644,
              21.00889210191221
            ],
            [
              105.82253935500735,
              21.00882809636942
            ],
            [
              105.82254148695566,
              21.00882693896588
            ],
            [
              105.82256834520116,
              21.008806447689576
            ],
            [
              105.82255545810857,
              21.008790549050964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10441",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008952410333226,
          "to_latitude": 21.008790549050964,
          "to_longitude": 105.82255545810857,
          "from_longitude": 105.82265574871633
        }
      },
      {
        "id": 10425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82263880723563,
            21.008941253374005,
            105.8226546180682,
            21.00895373722059
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8226546180682,
              21.00895373722059
            ],
            [
              105.82265039477406,
              21.008950650534967
            ],
            [
              105.82263880723563,
              21.008941253374005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10442",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00895373722059,
          "to_latitude": 21.008941253374005,
          "to_longitude": 105.82263880723563,
          "from_longitude": 105.8226546180682
        }
      },
      {
        "id": 10426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82264110683072,
            21.008834680111434,
            105.8227626065782,
            21.009164191271108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82264110683072,
              21.009164191271108
            ],
            [
              105.82265384442401,
              21.009083690078775
            ],
            [
              105.82265667011038,
              21.009023621738965
            ],
            [
              105.82265671537193,
              21.00899749025112
            ],
            [
              105.82265675967678,
              21.008971359671033
            ],
            [
              105.82272532854529,
              21.00888138377265
            ],
            [
              105.8227626065782,
              21.008834680111434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10443",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009164191271108,
          "to_latitude": 21.008834680111434,
          "to_longitude": 105.8227626065782,
          "from_longitude": 105.82264110683072
        }
      },
      {
        "id": 10427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.822642453953,
            21.00899023402029,
            105.82265671537193,
            21.00899749025112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.822642453953,
              21.00899023402029
            ],
            [
              105.82265671537193,
              21.00899749025112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10444",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00899023402029,
          "to_latitude": 21.00899749025112,
          "to_longitude": 105.82265671537193,
          "from_longitude": 105.822642453953
        }
      },
      {
        "id": 10428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82201008089608,
            21.010037142470548,
            105.82237319193047,
            21.01043961291604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82201008089608,
              21.010037142470548
            ],
            [
              105.82217232359065,
              21.0102169724977
            ],
            [
              105.82219317639758,
              21.01024008558708
            ],
            [
              105.82237319193047,
              21.01043961291604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Thái Hà",
          "maTaiSan": "04.O11.DODV.10445",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010037142470548,
          "to_latitude": 21.01043961291604,
          "to_longitude": 105.82237319193047,
          "from_longitude": 105.82201008089608
        }
      },
      {
        "id": 10429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82217232359065,
            21.010208451010836,
            105.82218363121093,
            21.0102169724977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82217232359065,
              21.0102169724977
            ],
            [
              105.82217933188974,
              21.010211681853427
            ],
            [
              105.82218310809407,
              21.010208831087354
            ],
            [
              105.8221835774843,
              21.01020847656077
            ],
            [
              105.82218363121093,
              21.010208451010836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80_Thái Hà",
          "maTaiSan": "04.O11.DODV.10446",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0102169724977,
          "to_latitude": 21.010208451010836,
          "to_longitude": 105.82218363121093,
          "from_longitude": 105.82217232359065
        }
      },
      {
        "id": 10430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82145447969025,
            21.01001834450741,
            105.8220089582917,
            21.010364013561233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8220089582917,
              21.01001834450741
            ],
            [
              105.82200891618679,
              21.0100183844528
            ],
            [
              105.82200009793085,
              21.01002672228989
            ],
            [
              105.82177755787829,
              21.010237127302123
            ],
            [
              105.82174574787929,
              21.01023874053488
            ],
            [
              105.82167008050381,
              21.010238541408103
            ],
            [
              105.82158216403315,
              21.010357404301708
            ],
            [
              105.82157741570815,
              21.010364013561233
            ],
            [
              105.82145447969025,
              21.010291329138187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10447",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01001834450741,
          "to_latitude": 21.010291329138187,
          "to_longitude": 105.82145447969025,
          "from_longitude": 105.8220089582917
        }
      },
      {
        "id": 10431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82145907374064,
            21.01001166170281,
            105.82200331282336,
            21.01034749888671
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82200331282336,
              21.01001166170281
            ],
            [
              105.82200326974672,
              21.010011699846437
            ],
            [
              105.82199335974913,
              21.01002056773201
            ],
            [
              105.8217671199325,
              21.010223018718335
            ],
            [
              105.82173647138917,
              21.010223130595865
            ],
            [
              105.82172568447174,
              21.010228377146646
            ],
            [
              105.8216667763358,
              21.010226417084127
            ],
            [
              105.82157350074151,
              21.01034749888671
            ],
            [
              105.82145907374064,
              21.010281665008517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79_Thái Hà",
          "maTaiSan": "04.O11.DODV.10448",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01001166170281,
          "to_latitude": 21.010281665008517,
          "to_longitude": 105.82145907374064,
          "from_longitude": 105.82200331282336
        }
      },
      {
        "id": 10432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82177025304848,
            21.0097381538556,
            105.82202141484301,
            21.010021488081208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82177025304848,
              21.0097381538556
            ],
            [
              105.82181583395196,
              21.009783158325558
            ],
            [
              105.8218694087571,
              21.00984545800981
            ],
            [
              105.8218703119614,
              21.00984650771383
            ],
            [
              105.82187545723018,
              21.009852488421856
            ],
            [
              105.82189225806695,
              21.009872027180435
            ],
            [
              105.82189538493138,
              21.009875663826055
            ],
            [
              105.82193841663957,
              21.009927122389975
            ],
            [
              105.82202141484301,
              21.010021488081208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77A_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10449",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0097381538556,
          "to_latitude": 21.010021488081208,
          "to_longitude": 105.82202141484301,
          "from_longitude": 105.82177025304848
        }
      },
      {
        "id": 10433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82188653556051,
            21.009872027180435,
            105.82189225806695,
            21.009876740763023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82189225806695,
              21.009872027180435
            ],
            [
              105.82189122387122,
              21.009872877596308
            ],
            [
              105.82188653556051,
              21.009876740763023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77A_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10450",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009872027180435,
          "to_latitude": 21.009876740763023,
          "to_longitude": 105.82188653556051,
          "from_longitude": 105.82189225806695
        }
      },
      {
        "id": 10434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82192654491192,
            21.009201742980142,
            105.82263939380374,
            21.009802035757943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82192654491192,
              21.009802035757943
            ],
            [
              105.82203651547427,
              21.009698750121615
            ],
            [
              105.82203383326652,
              21.009662443638007
            ],
            [
              105.82214045107368,
              21.00957105141675
            ],
            [
              105.82216459096132,
              21.009574181288446
            ],
            [
              105.82227456153262,
              21.009472147443226
            ],
            [
              105.82238520265139,
              21.00937574730515
            ],
            [
              105.82253607690427,
              21.00928059905526
            ],
            [
              105.82263939380374,
              21.009201742980142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78A_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10451",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": 102.242,
          "from_latitude": 21.009802035757943,
          "to_latitude": 21.009201742980142,
          "to_longitude": 105.82263939380374,
          "from_longitude": 105.82192654491192
        }
      },
      {
        "id": 10435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82186239521847,
            21.00937784085998,
            105.8223600080508,
            21.00985066918265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82186239521847,
              21.00985066918265
            ],
            [
              105.82186852703308,
              21.009845520074883
            ],
            [
              105.82186883343338,
              21.0098452629817
            ],
            [
              105.8218694087571,
              21.00984545800981
            ],
            [
              105.82187008815639,
              21.00984568776111
            ],
            [
              105.82187102443123,
              21.009843818977853
            ],
            [
              105.82203270116096,
              21.00969200036313
            ],
            [
              105.82202933907557,
              21.009656139511243
            ],
            [
              105.8223600080508,
              21.00937784085998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10452",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00985066918265,
          "to_latitude": 21.00937784085998,
          "to_longitude": 105.8223600080508,
          "from_longitude": 105.82186239521847
        }
      },
      {
        "id": 10436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82155334559427,
            21.009473553853134,
            105.82194360700936,
            21.009887009581472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82155334559427,
              21.009473553853134
            ],
            [
              105.82158553210513,
              21.009501409725228
            ],
            [
              105.82159894315285,
              21.00952331883515
            ],
            [
              105.82163917628694,
              21.009569953928846
            ],
            [
              105.82177630422287,
              21.009721126648767
            ],
            [
              105.82194360700936,
              21.009887009581472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10453",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": 61.275,
          "from_latitude": 21.009473553853134,
          "to_latitude": 21.009887009581472,
          "to_longitude": 105.82194360700936,
          "from_longitude": 105.82155334559427
        }
      },
      {
        "id": 10437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82166670052688,
            21.009611085520106,
            105.8216764865115,
            21.00962277945053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82166670052688,
              21.00962277945053
            ],
            [
              105.82167111126952,
              21.00961807569609
            ],
            [
              105.82167345819904,
              21.00961525881179
            ],
            [
              105.82167546985936,
              21.00961275491564
            ],
            [
              105.8216764865115,
              21.009611085520106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_77_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10454",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.48,
          "chatLieu": "HDPE",
          "chieuDai": 1.65,
          "from_latitude": 21.00962277945053,
          "to_latitude": 21.009611085520106,
          "to_longitude": 105.8216764865115,
          "from_longitude": 105.82166670052688
        }
      },
      {
        "id": 10438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82278114265344,
            21.00755421633067,
            105.82336543639646,
            21.007845460236844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82336543639646,
              21.00755421633067
            ],
            [
              105.82333246165717,
              21.007571342989447
            ],
            [
              105.82319181703103,
              21.007644388132384
            ],
            [
              105.82319245741519,
              21.007681342388242
            ],
            [
              105.82285878588657,
              21.007845460236844
            ],
            [
              105.82278114265344,
              21.00770800494512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_44_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10455",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00755421633067,
          "to_latitude": 21.00770800494512,
          "to_longitude": 105.82278114265344,
          "from_longitude": 105.82336543639646
        }
      },
      {
        "id": 10439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334888474729,
            21.007584986267414,
            105.8235315703431,
            21.0079404561999
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8235315703431,
              21.0079404561999
            ],
            [
              105.82345385921246,
              21.007785840245447
            ],
            [
              105.82339742664917,
              21.007673560335235
            ],
            [
              105.82334888474729,
              21.007584986267414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10456",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0079404561999,
          "to_latitude": 21.007584986267414,
          "to_longitude": 105.82334888474729,
          "from_longitude": 105.8235315703431
        }
      },
      {
        "id": 10440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82345385921246,
            21.007779232922466,
            105.8234692102811,
            21.007785840245447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82345385921246,
              21.007785840245447
            ],
            [
              105.82345412694768,
              21.007785725143307
            ],
            [
              105.82346587080028,
              21.007780670761257
            ],
            [
              105.8234692102811,
              21.007779232922466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10457",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007785840245447,
          "to_latitude": 21.007779232922466,
          "to_longitude": 105.8234692102811,
          "from_longitude": 105.82345385921246
        }
      },
      {
        "id": 10441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82274891003084,
            21.007336694456725,
            105.82325236500508,
            21.007648226219384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274891003084,
              21.007648226219384
            ],
            [
              105.82323896525676,
              21.007344985871562
            ],
            [
              105.82325236500508,
              21.007336694456725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_45A_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10458",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007648226219384,
          "to_latitude": 21.007336694456725,
          "to_longitude": 105.82325236500508,
          "from_longitude": 105.82274891003084
        }
      },
      {
        "id": 10442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82274183893615,
            21.007328113569486,
            105.8232479307695,
            21.007639231743326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82274183893615,
              21.007639231743326
            ],
            [
              105.82323437830394,
              21.007336445464713
            ],
            [
              105.8232479307695,
              21.007328113569486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_45_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10459",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007639231743326,
          "to_latitude": 21.007328113569486,
          "to_longitude": 105.8232479307695,
          "from_longitude": 105.82274183893615
        }
      },
      {
        "id": 10443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82287656564657,
            21.00670568187858,
            105.82317241264606,
            21.007271210635242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82287656564657,
              21.00670568187858
            ],
            [
              105.8229245824625,
              21.00676824479011
            ],
            [
              105.82316764731354,
              21.00726153844202
            ],
            [
              105.82317241264606,
              21.007271210635242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10460",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00670568187858,
          "to_latitude": 21.007271210635242,
          "to_longitude": 105.82317241264606,
          "from_longitude": 105.82287656564657
        }
      },
      {
        "id": 10444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82316764731354,
            21.00724474855194,
            105.82320476476792,
            21.00726153844202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82316764731354,
              21.00726153844202
            ],
            [
              105.82317422855584,
              21.00725856208761
            ],
            [
              105.8231847898154,
              21.007253784413912
            ],
            [
              105.82320476476792,
              21.00724474855194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10461",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00726153844202,
          "to_latitude": 21.00724474855194,
          "to_longitude": 105.82320476476792,
          "from_longitude": 105.82316764731354
        }
      },
      {
        "id": 10445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82267762925105,
            21.00629913356135,
            105.82281882278764,
            21.0065572428442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82267762925105,
              21.00629913356135
            ],
            [
              105.82275350866952,
              21.00642188479898
            ],
            [
              105.82276663839718,
              21.00644312575319
            ],
            [
              105.82277176718247,
              21.006451379038918
            ],
            [
              105.82277310450264,
              21.006453540319804
            ],
            [
              105.8227939982945,
              21.00648719382487
            ],
            [
              105.82281882278764,
              21.0065572428442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47A_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10462",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00629913356135,
          "to_latitude": 21.0065572428442,
          "to_longitude": 105.82281882278764,
          "from_longitude": 105.82267762925105
        }
      },
      {
        "id": 10446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82275350866952,
            21.006396819001267,
            105.82279654809452,
            21.00642188479899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82275350866952,
              21.00642188479899
            ],
            [
              105.82277132253148,
              21.006411506401946
            ],
            [
              105.82277332358258,
              21.006410340596727
            ],
            [
              105.82278986417013,
              21.006400710806446
            ],
            [
              105.82279217971366,
              21.00639936193183
            ],
            [
              105.82279654809452,
              21.006396819001267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47A_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10463",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00642188479899,
          "to_latitude": 21.006396819001267,
          "to_longitude": 105.82279654809452,
          "from_longitude": 105.82275350866952
        }
      },
      {
        "id": 10447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82230414772141,
            21.006490422119427,
            105.82249867430785,
            21.006746605713477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82249867430785,
              21.006746605713477
            ],
            [
              105.8224984951466,
              21.006746384385373
            ],
            [
              105.82246756318592,
              21.006707817023717
            ],
            [
              105.82230414772141,
              21.006490422119427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10464",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006746605713477,
          "to_latitude": 21.006490422119427,
          "to_longitude": 105.82230414772141,
          "from_longitude": 105.82249867430785
        }
      },
      {
        "id": 10448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.822316370113,
            21.006750364245097,
            105.82248106457524,
            21.00692465386054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.822316370113,
              21.00692465386054
            ],
            [
              105.82233826164853,
              21.006898331902025
            ],
            [
              105.82233955933275,
              21.006896771176994
            ],
            [
              105.82234392063164,
              21.006891527641354
            ],
            [
              105.82234497850642,
              21.006890438656605
            ],
            [
              105.82248106457524,
              21.006750364245097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10465",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00692465386054,
          "to_latitude": 21.006750364245097,
          "to_longitude": 105.82248106457524,
          "from_longitude": 105.822316370113
        }
      },
      {
        "id": 10449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82218108473015,
            21.006713558418962,
            105.82233826164853,
            21.006898331902025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233826164853,
              21.006898331902025
            ],
            [
              105.82231475347358,
              21.006876609964774
            ],
            [
              105.82223384385979,
              21.006801846029138
            ],
            [
              105.82218108473015,
              21.006713558418962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10466",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006898331902025,
          "to_latitude": 21.006713558418962,
          "to_longitude": 105.82218108473015,
          "from_longitude": 105.82233826164853
        }
      },
      {
        "id": 10450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82233955933275,
            21.006896771176994,
            105.8223501362881,
            21.006905213117772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233955933275,
              21.006896771176994
            ],
            [
              105.82234672399073,
              21.006902489213683
            ],
            [
              105.82234955952504,
              21.006904753452208
            ],
            [
              105.8223501362881,
              21.006905213117772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10467",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006896771176994,
          "to_latitude": 21.006905213117772,
          "to_longitude": 105.8223501362881,
          "from_longitude": 105.82233955933275
        }
      },
      {
        "id": 10451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8219962248759,
            21.00693776056196,
            105.82230833903175,
            21.007292927123604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8219962248759,
              21.007292927123604
            ],
            [
              105.82203549610944,
              21.00725242729327
            ],
            [
              105.82208439626855,
              21.007196212652623
            ],
            [
              105.8221161168045,
              21.007159426317866
            ],
            [
              105.82211809565814,
              21.007158770950834
            ],
            [
              105.82211822292423,
              21.00715847407739
            ],
            [
              105.82211949372083,
              21.00715551619077
            ],
            [
              105.82212782886322,
              21.007145935132655
            ],
            [
              105.82226170200009,
              21.00699584213963
            ],
            [
              105.8222983812806,
              21.00695016211922
            ],
            [
              105.82230833903175,
              21.00693776056196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10468",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007292927123604,
          "to_latitude": 21.00693776056196,
          "to_longitude": 105.82230833903175,
          "from_longitude": 105.8219962248759
        }
      },
      {
        "id": 10452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8222983812806,
            21.00695016211922,
            105.82230614088081,
            21.006956146444768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8222983812806,
              21.00695016211922
            ],
            [
              105.82230474892444,
              21.00695507291025
            ],
            [
              105.82230609549038,
              21.00695611324465
            ],
            [
              105.82230614088081,
              21.006956146444768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10469",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00695016211922,
          "to_latitude": 21.006956146444768,
          "to_longitude": 105.82230614088081,
          "from_longitude": 105.8222983812806
        }
      },
      {
        "id": 10453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82194373180693,
            21.007000167298603,
            105.82212554462778,
            21.00716511626953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82212554462778,
              21.00716511626953
            ],
            [
              105.82212501779189,
              21.007164640103934
            ],
            [
              105.82211822292423,
              21.00715847407739
            ],
            [
              105.82194373180693,
              21.007000167298603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10470",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00716511626953,
          "to_latitude": 21.007000167298603,
          "to_longitude": 105.82194373180693,
          "from_longitude": 105.82212554462778
        }
      },
      {
        "id": 10454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82176797572036,
            21.00716686929562,
            105.82204555341,
            21.007446475226345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.821978768015,
              21.007446475226345
            ],
            [
              105.82204555341,
              21.00737302462786
            ],
            [
              105.82198870716954,
              21.007320743102085
            ],
            [
              105.82198453948438,
              21.00731691006307
            ],
            [
              105.82183440425514,
              21.00716686929562
            ],
            [
              105.82177867752328,
              21.007171973372415
            ],
            [
              105.82176797572036,
              21.00716848349277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10471",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007446475226345,
          "to_latitude": 21.00716848349277,
          "to_longitude": 105.82176797572036,
          "from_longitude": 105.821978768015
        }
      },
      {
        "id": 10455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82256398433667,
            21.0060681350655,
            105.82264284149021,
            21.00610805229188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82256398433667,
              21.00610805229188
            ],
            [
              105.82256675455683,
              21.006106650642085
            ],
            [
              105.82262199485363,
              21.006078687978793
            ],
            [
              105.82264284149021,
              21.0060681350655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_47_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10472",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00610805229188,
          "to_latitude": 21.0060681350655,
          "to_longitude": 105.82264284149021,
          "from_longitude": 105.82256398433667
        }
      },
      {
        "id": 10456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8224471402255,
            21.00601380065646,
            105.82267454308008,
            21.00627079977863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8224471402255,
              21.00601380065646
            ],
            [
              105.82255555066148,
              21.006099114970453
            ],
            [
              105.82256062625837,
              21.00610310889913
            ],
            [
              105.82256398433667,
              21.00610805229188
            ],
            [
              105.82267454308008,
              21.00627079977863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_47_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10473",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00601380065646,
          "to_latitude": 21.00627079977863,
          "to_longitude": 105.82267454308008,
          "from_longitude": 105.8224471402255
        }
      },
      {
        "id": 10457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82214142305104,
            21.006000552008175,
            105.82240753639314,
            21.006190273595653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82214142305104,
              21.006190273595653
            ],
            [
              105.82226011267191,
              21.006087679733028
            ],
            [
              105.82228126461588,
              21.006046472550807
            ],
            [
              105.82230051725836,
              21.006037290350765
            ],
            [
              105.82233857930865,
              21.006019137634777
            ],
            [
              105.82234612763318,
              21.006015537929333
            ],
            [
              105.82240753639314,
              21.006000552008175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10474",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006190273595653,
          "to_latitude": 21.006000552008175,
          "to_longitude": 105.82240753639314,
          "from_longitude": 105.82214142305104
        }
      },
      {
        "id": 10458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82229793178152,
            21.005972348127838,
            105.82233857930865,
            21.006019137634777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82233857930865,
              21.006019137634777
            ],
            [
              105.82232048867827,
              21.005998313517505
            ],
            [
              105.82230423529215,
              21.005979605815977
            ],
            [
              105.82230133424244,
              21.00597626524511
            ],
            [
              105.82229793178152,
              21.005972348127838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10475",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006019137634777,
          "to_latitude": 21.005972348127838,
          "to_longitude": 105.82229793178152,
          "from_longitude": 105.82233857930865
        }
      },
      {
        "id": 10459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82188420251931,
            21.006215362955786,
            105.8221312424304,
            21.00646165329824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82188420251931,
              21.00646165329824
            ],
            [
              105.82199168810139,
              21.00634492762733
            ],
            [
              105.82200354296359,
              21.0063320535689
            ],
            [
              105.82201177099401,
              21.00632311884179
            ],
            [
              105.82204800516949,
              21.006285224137123
            ],
            [
              105.82205478192503,
              21.006281594751503
            ],
            [
              105.82205596061921,
              21.00628096402462
            ],
            [
              105.82205928185817,
              21.006272863191302
            ],
            [
              105.8221312424304,
              21.006215362955786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10476",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00646165329824,
          "to_latitude": 21.006215362955786,
          "to_longitude": 105.8221312424304,
          "from_longitude": 105.82188420251931
        }
      },
      {
        "id": 10460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82196379076105,
            21.006323862707553,
            105.82199168810139,
            21.00634492762733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82199168810139,
              21.00634492762733
            ],
            [
              105.8219772279939,
              21.006334007895415
            ],
            [
              105.8219648706204,
              21.006324680332614
            ],
            [
              105.82196379076105,
              21.006323862707553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10477",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00634492762733,
          "to_latitude": 21.006323862707553,
          "to_longitude": 105.82196379076105,
          "from_longitude": 105.82199168810139
        }
      },
      {
        "id": 10461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82202571062585,
            21.00625464917723,
            105.82216778287271,
            21.00639525571178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82202571062585,
              21.00625464917723
            ],
            [
              105.82202675476577,
              21.00625561871667
            ],
            [
              105.82205478192503,
              21.006281594751503
            ],
            [
              105.82209828351203,
              21.006321915160047
            ],
            [
              105.82212666703974,
              21.006346392812482
            ],
            [
              105.82215171823523,
              21.006366585387546
            ],
            [
              105.82214360005356,
              21.00637116513
            ],
            [
              105.82216778287271,
              21.00639525571178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10478",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00625464917723,
          "to_latitude": 21.00639525571178,
          "to_longitude": 105.82216778287271,
          "from_longitude": 105.82202571062585
        }
      },
      {
        "id": 10462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82184515299409,
            21.006467962143898,
            105.8221410443814,
            21.00674697943444
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.82184515299409,
                21.006467962143898
              ],
              [
                105.8218464965415,
                21.00646950649786
              ],
              [
                105.82200777777459,
                21.0066547473299
              ]
            ],
            [
              [
                105.82200777777459,
                21.0066547473299
              ],
              [
                105.8221410443814,
                21.00657115117456
              ]
            ],
            [
              [
                105.82200777777459,
                21.0066547473299
              ],
              [
                105.82208221900939,
                21.00674697943444
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10479",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8218464965415,
            21.00646950649786
          ],
          "to_latitude": [
            105.82208221900939,
            21.00674697943444
          ],
          "to_longitude": [
            105.82200777777459,
            21.0066547473299
          ],
          "from_longitude": [
            105.82184515299409,
            21.006467962143898
          ]
        }
      },
      {
        "id": 10463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82154045766417,
            21.006494189972422,
            105.82184984921125,
            21.00686494763804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82154045766417,
              21.00686494763804
            ],
            [
              105.82164893123438,
              21.00672882149236
            ],
            [
              105.82168838520884,
              21.006684462270023
            ],
            [
              105.82170310850813,
              21.00666790908433
            ],
            [
              105.8217318495601,
              21.00663559373052
            ],
            [
              105.82173911231429,
              21.00663270807648
            ],
            [
              105.82174001430583,
              21.00662581119068
            ],
            [
              105.82184984921125,
              21.006494189972422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10480",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00686494763804,
          "to_latitude": 21.006494189972422,
          "to_longitude": 105.82184984921125,
          "from_longitude": 105.82154045766417
        }
      },
      {
        "id": 10464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82168418773999,
            21.00665392905217,
            105.82170310850813,
            21.00666790908433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82170310850813,
              21.00666790908433
            ],
            [
              105.82169230036646,
              21.00665992344456
            ],
            [
              105.82168568569128,
              21.006655038209388
            ],
            [
              105.82168418773999,
              21.00665392905217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10481",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00666790908433,
          "to_latitude": 21.00665392905217,
          "to_longitude": 105.82168418773999,
          "from_longitude": 105.82170310850813
        }
      },
      {
        "id": 10465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82171758218712,
            21.006615431220663,
            105.82195116438993,
            21.006883932972663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82171758218712,
              21.006615431220663
            ],
            [
              105.82171896762091,
              21.006616544533752
            ],
            [
              105.82173911231429,
              21.00663270807648
            ],
            [
              105.82175207583407,
              21.006650845520927
            ],
            [
              105.82185056129248,
              21.00686781659859
            ],
            [
              105.82191901740043,
              21.006865861999405
            ],
            [
              105.82195116438993,
              21.006883932972663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10482",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006615431220663,
          "to_latitude": 21.006883932972663,
          "to_longitude": 105.82195116438993,
          "from_longitude": 105.82171758218712
        }
      },
      {
        "id": 10466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82150673098702,
            21.006861224757422,
            105.82170340065014,
            21.007082075502314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82150673098702,
              21.006861224757422
            ],
            [
              105.82150768665207,
              21.00686219743673
            ],
            [
              105.8215079826764,
              21.006862505814908
            ],
            [
              105.82162190942715,
              21.00697852731061
            ],
            [
              105.82170340065014,
              21.007082075502314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10483",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006861224757422,
          "to_latitude": 21.007082075502314,
          "to_longitude": 105.82170340065014,
          "from_longitude": 105.82150673098702
        }
      },
      {
        "id": 10467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82139972680389,
            21.006995742301964,
            105.82157293567764,
            21.007191915536314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82139972680389,
              21.006995742301964
            ],
            [
              105.82140167314628,
              21.006998109300085
            ],
            [
              105.82141207876727,
              21.007010754845904
            ],
            [
              105.82157293567764,
              21.007191915536314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10484",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006995742301964,
          "to_latitude": 21.007191915536314,
          "to_longitude": 105.82157293567764,
          "from_longitude": 105.82139972680389
        }
      },
      {
        "id": 10468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82141207876727,
            21.0068930468826,
            105.8215086837153,
            21.007010754845904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141207876727,
              21.007010754845904
            ],
            [
              105.8215086837153,
              21.0068930468826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10485",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007010754845904,
          "to_latitude": 21.0068930468826,
          "to_longitude": 105.8215086837153,
          "from_longitude": 105.82141207876727
        }
      },
      {
        "id": 10469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82093155753039,
            21.007273055708144,
            105.82125937818891,
            21.007666817044147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82093155753039,
              21.007666817044147
            ],
            [
              105.82109546807284,
              21.007469936450246
            ],
            [
              105.8211483130665,
              21.00740646185854
            ],
            [
              105.82125937818891,
              21.007273055708144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10486",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007666817044147,
          "to_latitude": 21.007273055708144,
          "to_longitude": 105.82125937818891,
          "from_longitude": 105.82093155753039
        }
      },
      {
        "id": 10470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82109351033863,
            21.00737671382636,
            105.8211483130665,
            21.00740646185854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82109351033863,
              21.00737671382636
            ],
            [
              105.82109676927983,
              21.007378484687205
            ],
            [
              105.821117591507,
              21.007389785666977
            ],
            [
              105.8211483130665,
              21.00740646185854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10487",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00737671382636,
          "to_latitude": 21.00740646185854,
          "to_longitude": 105.8211483130665,
          "from_longitude": 105.82109351033863
        }
      },
      {
        "id": 10471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82063174136798,
            21.00775900043391,
            105.82085441723422,
            21.00801709683941
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063174136798,
              21.00801709683941
            ],
            [
              105.8207371982834,
              21.0078947094355
            ],
            [
              105.82085441723422,
              21.00775900043391
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10488",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00801709683941,
          "to_latitude": 21.00775900043391,
          "to_longitude": 105.82085441723422,
          "from_longitude": 105.82063174136798
        }
      },
      {
        "id": 10472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82069140193181,
            21.007860797317402,
            105.8207371982834,
            21.0078947094355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8207371982834,
              21.0078947094355
            ],
            [
              105.82069262077856,
              21.007861700989892
            ],
            [
              105.82069140193181,
              21.007860797317402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10489",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0078947094355,
          "to_latitude": 21.007860797317402,
          "to_longitude": 105.82069140193181,
          "from_longitude": 105.8207371982834
        }
      },
      {
        "id": 10473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82022044228493,
            21.008118604579927,
            105.82054944787006,
            21.008516404140295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82022044228493,
              21.008516404140295
            ],
            [
              105.82038714933157,
              21.008314840126424
            ],
            [
              105.82045851453245,
              21.008228553051932
            ],
            [
              105.82054944787006,
              21.008118604579927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10490",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008516404140295,
          "to_latitude": 21.008118604579927,
          "to_longitude": 105.82054944787006,
          "from_longitude": 105.82022044228493
        }
      },
      {
        "id": 10474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82041279407693,
            21.0082021968988,
            105.82045851453245,
            21.008228553051932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82045851453245,
              21.008228553051932
            ],
            [
              105.82041399928484,
              21.00820289289658
            ],
            [
              105.82041279407693,
              21.0082021968988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10491",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008228553051932,
          "to_latitude": 21.0082021968988,
          "to_longitude": 105.82041279407693,
          "from_longitude": 105.82045851453245
        }
      },
      {
        "id": 10475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81979321865823,
            21.00862364372853,
            105.8201296831576,
            21.009037271387015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81979321865823,
              21.009037271387015
            ],
            [
              105.81999874960245,
              21.008784605749845
            ],
            [
              105.82010438697354,
              21.008654741833578
            ],
            [
              105.8201296831576,
              21.00862364372853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10492",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009037271387015,
          "to_latitude": 21.00862364372853,
          "to_longitude": 105.8201296831576,
          "from_longitude": 105.81979321865823
        }
      },
      {
        "id": 10476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82005248146389,
            21.008622225573596,
            105.82010438697354,
            21.008654741833578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82010438697354,
              21.008654741833578
            ],
            [
              105.82006015543989,
              21.008626622538685
            ],
            [
              105.82005248146389,
              21.008622225573596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10493",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008654741833578,
          "to_latitude": 21.008622225573596,
          "to_longitude": 105.82005248146389,
          "from_longitude": 105.82010438697354
        }
      },
      {
        "id": 10477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81971323519961,
            21.009027976790204,
            105.82022662448281,
            21.009399409501494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81971323519961,
              21.009027976790204
            ],
            [
              105.81971999810342,
              21.00903287132111
            ],
            [
              105.82022662448281,
              21.009399409501494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_60_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10494",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009027976790204,
          "to_latitude": 21.009399409501494,
          "to_longitude": 105.82022662448281,
          "from_longitude": 105.81971323519961
        }
      },
      {
        "id": 10478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81883965927717,
            21.009071238324562,
            105.81970281083233,
            21.01009252328889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81883965927717,
              21.01009252328889
            ],
            [
              105.81904675451533,
              21.00985714532276
            ],
            [
              105.8190611294455,
              21.009839924739776
            ],
            [
              105.81912939934496,
              21.00975814304016
            ],
            [
              105.81970281083233,
              21.009071238324562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10495",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01009252328889,
          "to_latitude": 21.009071238324562,
          "to_longitude": 105.81970281083233,
          "from_longitude": 105.81883965927717
        }
      },
      {
        "id": 10479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8191123954626,
            21.00974750809206,
            105.81912939934496,
            21.00975814304016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8191123954626,
              21.00974750809206
            ],
            [
              105.81911760463824,
              21.00975076715369
            ],
            [
              105.8191261147776,
              21.00975608865275
            ],
            [
              105.81912939934496,
              21.00975814304016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10496",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00974750809206,
          "to_latitude": 21.00975814304016,
          "to_longitude": 105.81912939934496,
          "from_longitude": 105.8191123954626
        }
      },
      {
        "id": 10480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8214706161465,
            21.00965813179958,
            105.82166905719058,
            21.009869717075297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82166905719058,
              21.00965813179958
            ],
            [
              105.82164575124514,
              21.009681848827594
            ],
            [
              105.8216256346781,
              21.009701879988953
            ],
            [
              105.82160250062509,
              21.009726292963716
            ],
            [
              105.82158058670883,
              21.009749530200303
            ],
            [
              105.82154102413114,
              21.009790844450027
            ],
            [
              105.82151889590381,
              21.009817135330024
            ],
            [
              105.82149743823291,
              21.009840922312232
            ],
            [
              105.8214706161465,
              21.009869717075297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Thái Hà",
          "maTaiSan": "04.O11.DODV.10497",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": 31.223,
          "from_latitude": 21.00965813179958,
          "to_latitude": 21.009869717075297,
          "to_longitude": 105.8214706161465,
          "from_longitude": 105.82166905719058
        }
      },
      {
        "id": 10481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82083443228149,
            21.009424735329933,
            105.8215006293949,
            21.009717501547016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8215006293949,
              21.009479921092165
            ],
            [
              105.82149475601616,
              21.00948662107435
            ],
            [
              105.82148335662876,
              21.009498827579428
            ],
            [
              105.82143842962664,
              21.009543584757576
            ],
            [
              105.82124821415059,
              21.009708856981128
            ],
            [
              105.82115187031529,
              21.009717501547016
            ],
            [
              105.82083443228149,
              21.009424735329933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76B_Thái Hà",
          "maTaiSan": "04.O11.DODV.10498",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": 92.831,
          "from_latitude": 21.009479921092165,
          "to_latitude": 21.009424735329933,
          "to_longitude": 105.82083443228149,
          "from_longitude": 105.8215006293949
        }
      },
      {
        "id": 10482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82085029189926,
            21.00942083456298,
            105.82148965377048,
            21.00970827218955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82148965377048,
              21.009471599687412
            ],
            [
              105.82147254404786,
              21.00949014218232
            ],
            [
              105.82139408943996,
              21.009553365608536
            ],
            [
              105.8212968593599,
              21.009645383814313
            ],
            [
              105.82123479416691,
              21.009695584689986
            ],
            [
              105.82116749874424,
              21.00970808766183
            ],
            [
              105.8211665070722,
              21.00970827218955
            ],
            [
              105.82116371377441,
              21.00970564734143
            ],
            [
              105.82085029189926,
              21.00942083456298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10499",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": 89.324,
          "from_latitude": 21.009471599687412,
          "to_latitude": 21.00942083456298,
          "to_longitude": 105.82085029189926,
          "from_longitude": 105.82148965377048
        }
      },
      {
        "id": 10483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82105623845416,
            21.009129750518557,
            105.82142518421462,
            21.00935542978295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82142518421462,
              21.00935542978295
            ],
            [
              105.82142514936466,
              21.00935538930594
            ],
            [
              105.82133915070256,
              21.00925626331705
            ],
            [
              105.82124462093459,
              21.009158691357616
            ],
            [
              105.82116130031002,
              21.009223529693017
            ],
            [
              105.82115668866348,
              21.00922698872722
            ],
            [
              105.82105623845416,
              21.009129750518557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10500",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00935542978295,
          "to_latitude": 21.009129750518557,
          "to_longitude": 105.82105623845416,
          "from_longitude": 105.82142518421462
        }
      },
      {
        "id": 10484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82079033933896,
            21.008988042371588,
            105.82105392149427,
            21.009163000942348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82105392149427,
              21.009112921019696
            ],
            [
              105.82099316793834,
              21.009163000942348
            ],
            [
              105.82079033933896,
              21.008988042371588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.4_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DODV.10501",
          "diaChiLapD": "Ngõ 7, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009112921019696,
          "to_latitude": 21.008988042371588,
          "to_longitude": 105.82079033933896,
          "from_longitude": 105.82105392149427
        }
      },
      {
        "id": 10485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82186627113737,
            21.010426412306167,
            105.82227018233682,
            21.010601069416456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227018233682,
              21.010601069416456
            ],
            [
              105.82226398060867,
              21.010596097312533
            ],
            [
              105.82225529778142,
              21.01058913203313
            ],
            [
              105.8220524087385,
              21.010426412306167
            ],
            [
              105.8219416646544,
              21.010549515210936
            ],
            [
              105.82186627113737,
              21.010492046630656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82_Thái Hà",
          "maTaiSan": "04.O11.DODV.10504",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010601069416456,
          "to_latitude": 21.010492046630656,
          "to_longitude": 105.82186627113737,
          "from_longitude": 105.82227018233682
        }
      },
      {
        "id": 10486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82189685717086,
            21.010267868727173,
            105.82227704250205,
            21.01059279251145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227704250205,
              21.01059279251145
            ],
            [
              105.82227123673584,
              21.01058793320424
            ],
            [
              105.82226270296229,
              21.01058078475304
            ],
            [
              105.82211982178119,
              21.010461137259423
            ],
            [
              105.82189685717086,
              21.010267868727173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81_Thái Hà",
          "maTaiSan": "04.O11.DODV.10505",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01059279251145,
          "to_latitude": 21.010267868727173,
          "to_longitude": 105.82189685717086,
          "from_longitude": 105.82227704250205
        }
      },
      {
        "id": 10487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82176982042665,
            21.010630362997723,
            105.82203545074321,
            21.01086899311763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82203545074321,
              21.01086899311763
            ],
            [
              105.8220270439074,
              21.010862352579423
            ],
            [
              105.82201866200597,
              21.01085452796804
            ],
            [
              105.82201899728416,
              21.010843886495984
            ],
            [
              105.82200558350134,
              21.010842219687756
            ],
            [
              105.82188791532325,
              21.010728634601826
            ],
            [
              105.82176982042665,
              21.010630362997723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84_Thái Hà",
          "maTaiSan": "04.O11.DODV.10506",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": 38.906,
          "from_latitude": 21.01086899311763,
          "to_latitude": 21.010630362997723,
          "to_longitude": 105.82176982042665,
          "from_longitude": 105.82203545074321
        }
      },
      {
        "id": 10488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82185950791767,
            21.011016817590487,
            105.82188279073154,
            21.011036765076884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82185950791767,
              21.011016817590487
            ],
            [
              105.82186812298639,
              21.011023852465282
            ],
            [
              105.82187482850574,
              21.01103011214681
            ],
            [
              105.82188279073154,
              21.011036765076884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103_Thái Hà",
          "maTaiSan": "04.O11.DODV.10508",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.3,
          "chatLieu": "HDPE",
          "chieuDai": 3.278,
          "from_latitude": 21.011016817590487,
          "to_latitude": 21.011036765076884,
          "to_longitude": 105.82188279073154,
          "from_longitude": 105.82185950791767
        }
      },
      {
        "id": 10489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82146917314378,
            21.01094827751171,
            105.82168257277304,
            21.011177152821883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82146917314378,
              21.01094827751171
            ],
            [
              105.82158187920649,
              21.011069156923497
            ],
            [
              105.82158247040473,
              21.011069790844235
            ],
            [
              105.82168257277304,
              21.011177152821883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102A_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10509",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01094827751171,
          "to_latitude": 21.011177152821883,
          "to_longitude": 105.82168257277304,
          "from_longitude": 105.82146917314378
        }
      },
      {
        "id": 10490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82158247040473,
            21.0110538194594,
            105.82160034949223,
            21.011069790844235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82158247040473,
              21.011069790844235
            ],
            [
              105.82159349670484,
              21.01105994120243
            ],
            [
              105.82159845322413,
              21.01105551313421
            ],
            [
              105.82160034949223,
              21.0110538194594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102A_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10510",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011069790844235,
          "to_latitude": 21.0110538194594,
          "to_longitude": 105.82160034949223,
          "from_longitude": 105.82158247040473
        }
      },
      {
        "id": 10491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8212333151798,
            21.010689986506552,
            105.82145014721418,
            21.01092397228805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8212333151798,
              21.010689986506552
            ],
            [
              105.82133958927737,
              21.010804668479462
            ],
            [
              105.82135390044022,
              21.010820111427382
            ],
            [
              105.82145014721418,
              21.01092397228805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10511",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010689986506552,
          "to_latitude": 21.01092397228805,
          "to_longitude": 105.82145014721418,
          "from_longitude": 105.8212333151798
        }
      },
      {
        "id": 10492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82133958927737,
            21.010790135723713,
            105.8213550849335,
            21.010804668479462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8213550849335,
              21.010790135723713
            ],
            [
              105.82135394132338,
              21.010791207955318
            ],
            [
              105.82135008176488,
              21.010794827752463
            ],
            [
              105.82133958927737,
              21.010804668479462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10512",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010790135723713,
          "to_latitude": 21.010804668479462,
          "to_longitude": 105.82133958927737,
          "from_longitude": 105.8213550849335
        }
      },
      {
        "id": 10493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82070236791428,
            21.010049060285574,
            105.82128876586259,
            21.010708207663978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82128876586259,
              21.010708207663978
            ],
            [
              105.82111174007078,
              21.01053387504625
            ],
            [
              105.8210044517072,
              21.010403673173375
            ],
            [
              105.8210064633675,
              21.01039553555307
            ],
            [
              105.82099472870188,
              21.01038145120786
            ],
            [
              105.82098500568759,
              21.010378947324572
            ],
            [
              105.82088878144218,
              21.010269715358728
            ],
            [
              105.82079959799434,
              21.010155162553513
            ],
            [
              105.82070236791428,
              21.010049060285574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99A_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10513",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": 95.754,
          "from_latitude": 21.010708207663978,
          "to_latitude": 21.010049060285574,
          "to_longitude": 105.82070236791428,
          "from_longitude": 105.82128876586259
        }
      },
      {
        "id": 10494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82089328935835,
            21.010282151533914,
            105.82089973667104,
            21.010287870200237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82089973667104,
              21.010282151533914
            ],
            [
              105.82089707954879,
              21.01028466042412
            ],
            [
              105.82089707954879,
              21.01028466042412
            ],
            [
              105.82089328935835,
              21.010287870200237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99A_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10514",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": 0.923,
          "from_latitude": 21.010282151533914,
          "to_latitude": 21.010287870200237,
          "to_longitude": 105.82089328935835,
          "from_longitude": 105.82089973667104
        }
      },
      {
        "id": 10495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82099356585697,
            21.00994210327006,
            105.82140868377566,
            21.01040263451145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82099356585697,
              21.01040263451145
            ],
            [
              105.82099732118071,
              21.010398951096033
            ],
            [
              105.8210869332737,
              21.01031106913976
            ],
            [
              105.8211587726806,
              21.010230664917653
            ],
            [
              105.82126561610352,
              21.010118352319353
            ],
            [
              105.82136377415263,
              21.01000776158306
            ],
            [
              105.82140868377566,
              21.00994210327006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10515",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01040263451145,
          "to_latitude": 21.00994210327006,
          "to_longitude": 105.82140868377566,
          "from_longitude": 105.82099356585697
        }
      },
      {
        "id": 10496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82066151366753,
            21.010060855344456,
            105.82117644830787,
            21.01063371727064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82066151366753,
              21.010060855344456
            ],
            [
              105.82079759698566,
              21.010182874454387
            ],
            [
              105.8208318602676,
              21.010225822034972
            ],
            [
              105.82096455378841,
              21.010402108246645
            ],
            [
              105.82107687129827,
              21.010531371164678
            ],
            [
              105.82117644830787,
              21.01063371727064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10517",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": 83.223,
          "from_latitude": 21.010060855344456,
          "to_latitude": 21.01063371727064,
          "to_longitude": 105.82117644830787,
          "from_longitude": 105.82066151366753
        }
      },
      {
        "id": 10497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82092161954303,
            21.01033438834873,
            105.82093393509224,
            21.010345069196028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82092161954303,
              21.010345069196028
            ],
            [
              105.82092818708418,
              21.010339745891123
            ],
            [
              105.82092818708418,
              21.010339745891123
            ],
            [
              105.82093393509224,
              21.01033438834873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10518",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": 1.804,
          "from_latitude": 21.010345069196028,
          "to_latitude": 21.01033438834873,
          "to_longitude": 105.82093393509224,
          "from_longitude": 105.82092161954303
        }
      },
      {
        "id": 10498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82041319235505,
            21.01003503171063,
            105.82064922673514,
            21.01026110825217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063633961546,
              21.01003503171063
            ],
            [
              105.82063279821048,
              21.010038888234906
            ],
            [
              105.82064922673514,
              21.010058293376353
            ],
            [
              105.82062307519618,
              21.010083958237363
            ],
            [
              105.82057077211826,
              21.010126524337842
            ],
            [
              105.8205077402094,
              21.01018286180443
            ],
            [
              105.82041319235505,
              21.01026110825217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_100_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10519",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": 36.587,
          "from_latitude": 21.01003503171063,
          "to_latitude": 21.01026110825217,
          "to_longitude": 105.82041319235505,
          "from_longitude": 105.82063633961546
        }
      },
      {
        "id": 10499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82013830285035,
            21.009940904715343,
            105.82065274739779,
            21.01010365908049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82065274739779,
              21.010030525692468
            ],
            [
              105.82064400964435,
              21.010039526945377
            ],
            [
              105.82064310757805,
              21.010040442652276
            ],
            [
              105.82058214008735,
              21.00999169953677
            ],
            [
              105.82047625162744,
              21.01008206681421
            ],
            [
              105.82032455812103,
              21.009940904715343
            ],
            [
              105.82031792765859,
              21.009946698367646
            ],
            [
              105.82013830285035,
              21.01010365908049
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_100A_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10520",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010030525692468,
          "to_latitude": 21.01010365908049,
          "to_longitude": 105.82013830285035,
          "from_longitude": 105.82065274739779
        }
      },
      {
        "id": 10500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82047991238714,
            21.009580049799073,
            105.82063955891242,
            21.009704305640177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063955891242,
              21.009681841619997
            ],
            [
              105.82062911024894,
              21.009692412138254
            ],
            [
              105.82061636975767,
              21.009704305640177
            ],
            [
              105.82059725902528,
              21.009685213439028
            ],
            [
              105.82056406668875,
              21.009655792666706
            ],
            [
              105.8205462970602,
              21.00964076929065
            ],
            [
              105.82052483938931,
              21.00962167708221
            ],
            [
              105.82047991238714,
              21.009580049799073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_101_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10521",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": 23.232,
          "from_latitude": 21.009681841619997,
          "to_latitude": 21.009580049799073,
          "to_longitude": 105.82047991238714,
          "from_longitude": 105.82063955891242
        }
      },
      {
        "id": 10501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82057683461495,
            21.009470931944712,
            105.8208041697178,
            21.010031726102298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82065386688562,
              21.010031726102298
            ],
            [
              105.82065976568072,
              21.0100254689972
            ],
            [
              105.8206778895573,
              21.010006244908475
            ],
            [
              105.8207619874145,
              21.009911399636806
            ],
            [
              105.8208041697178,
              21.00985108818919
            ],
            [
              105.82080263604682,
              21.009838706918092
            ],
            [
              105.82068804025417,
              21.00973361723515
            ],
            [
              105.82057683461495,
              21.00961485522355
            ],
            [
              105.82069788652623,
              21.009470931944712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_101A_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10522",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010031726102298,
          "to_latitude": 21.009470931944712,
          "to_longitude": 105.82069788652623,
          "from_longitude": 105.82065386688562
        }
      },
      {
        "id": 10502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82085576160404,
            21.01067627091019,
            105.82118486001659,
            21.010981113783373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82085576160404,
              21.010981113783373
            ],
            [
              105.82099651030666,
              21.010845272190192
            ],
            [
              105.82100501545368,
              21.010837001849303
            ],
            [
              105.82103618772551,
              21.01080530893709
            ],
            [
              105.82109761711263,
              21.010746957585592
            ],
            [
              105.82118486001659,
              21.01067627091019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10523",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010981113783373,
          "to_latitude": 21.01067627091019,
          "to_longitude": 105.82118486001659,
          "from_longitude": 105.82085576160404
        }
      },
      {
        "id": 10503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82103618772551,
            21.01080530893709,
            105.8210423569665,
            21.010811205244913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8210423569665,
              21.010811205244913
            ],
            [
              105.8210367705996,
              21.010806004319452
            ],
            [
              105.82103618772551,
              21.01080530893709
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10524",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010811205244913,
          "to_latitude": 21.01080530893709,
          "to_longitude": 105.82103618772551,
          "from_longitude": 105.8210423569665
        }
      },
      {
        "id": 10504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82050329537877,
            21.010682182590063,
            105.82082858245593,
            21.01101409437174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82082858245593,
              21.01101409437174
            ],
            [
              105.82082368625845,
              21.01100950882089
            ],
            [
              105.8207051884452,
              21.010901330909366
            ],
            [
              105.82059613472858,
              21.01077449455716
            ],
            [
              105.82050329537877,
              21.010682182590063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10525",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01101409437174,
          "to_latitude": 21.010682182590063,
          "to_longitude": 105.82050329537877,
          "from_longitude": 105.82082858245593
        }
      },
      {
        "id": 10505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82084267861197,
            21.01098065372272,
            105.82109595046394,
            21.011327205203376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82085456134324,
              21.010989413910686
            ],
            [
              105.82084866780693,
              21.010983978718603
            ],
            [
              105.82084571675382,
              21.01098065372272
            ],
            [
              105.82084267861197,
              21.010984266836587
            ],
            [
              105.82085096514393,
              21.01099282378093
            ],
            [
              105.82100114344283,
              21.011147898748817
            ],
            [
              105.82095399365282,
              21.011201018327377
            ],
            [
              105.82109595046394,
              21.011327205203376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10526",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010989413910686,
          "to_latitude": 21.011327205203376,
          "to_longitude": 105.82109595046394,
          "from_longitude": 105.82085456134324
        }
      },
      {
        "id": 10506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82047929760652,
            21.011028740935146,
            105.82081869431865,
            21.011287411930404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82047929760652,
              21.011287411930404
            ],
            [
              105.8206006252043,
              21.011214039375904
            ],
            [
              105.82064061917843,
              21.01118583741511
            ],
            [
              105.82071643750491,
              21.011124213955217
            ],
            [
              105.82081869431865,
              21.011028740935146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10527",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011287411930404,
          "to_latitude": 21.011028740935146,
          "to_longitude": 105.82081869431865,
          "from_longitude": 105.82047929760652
        }
      },
      {
        "id": 10507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82063670742704,
            21.01117992027007,
            105.82064061917843,
            21.01118583741511
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063670742704,
              21.01117992027007
            ],
            [
              105.82063969670372,
              21.011184430119563
            ],
            [
              105.82064061917843,
              21.01118583741511
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10528",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01117992027007,
          "to_latitude": 21.01118583741511,
          "to_longitude": 105.82064061917843,
          "from_longitude": 105.82063670742704
        }
      },
      {
        "id": 10508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82067167482566,
            21.011530391726488,
            105.82098411527396,
            21.011884692855222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82067167482566,
              21.011530391726488
            ],
            [
              105.82081027147214,
              21.01168377676384
            ],
            [
              105.82085389694367,
              21.011734195818097
            ],
            [
              105.82098411527396,
              21.011884692855222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10529",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011530391726488,
          "to_latitude": 21.011884692855222,
          "to_longitude": 105.82098411527396,
          "from_longitude": 105.82067167482566
        }
      },
      {
        "id": 10509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82084540474278,
            21.011734195818097,
            105.82085389694367,
            21.01174009602129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82085389694367,
              21.011734195818097
            ],
            [
              105.82085130577627,
              21.01173599580515
            ],
            [
              105.82084647033061,
              21.01173935384728
            ],
            [
              105.82084540474278,
              21.01174009602129
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10530",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011734195818097,
          "to_latitude": 21.01174009602129,
          "to_longitude": 105.82084540474278,
          "from_longitude": 105.82085389694367
        }
      },
      {
        "id": 10510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82029229918822,
            21.011114477447997,
            105.82065881419916,
            21.0115158232764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82029229918822,
              21.011114477447997
            ],
            [
              105.8204363120438,
              21.011268475290397
            ],
            [
              105.82045737208215,
              21.01129098152836
            ],
            [
              105.82046257381505,
              21.011296558263385
            ],
            [
              105.82049743585365,
              21.011335510749095
            ],
            [
              105.82065881419916,
              21.0115158232764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10531",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011114477447997,
          "to_latitude": 21.0115158232764,
          "to_longitude": 105.82065881419916,
          "from_longitude": 105.82029229918822
        }
      },
      {
        "id": 10511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82040940996562,
            21.011268475290397,
            105.8204363120438,
            21.011286361548233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204363120438,
              21.011268475290397
            ],
            [
              105.82041978110051,
              21.01127946598305
            ],
            [
              105.82041034059834,
              21.011285742866544
            ],
            [
              105.82040943777633,
              21.011286346059272
            ],
            [
              105.82040940996562,
              21.011286361548233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10532",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011268475290397,
          "to_latitude": 21.011286361548233,
          "to_longitude": 105.82040940996562,
          "from_longitude": 105.8204363120438
        }
      },
      {
        "id": 10512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8199174574471,
            21.010703709569736,
            105.82026152381128,
            21.011079989740896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82026152381128,
              21.011079989740896
            ],
            [
              105.82015441155082,
              21.01096126466102
            ],
            [
              105.82012483737722,
              21.010928484559766
            ],
            [
              105.82006736426897,
              21.010864778721967
            ],
            [
              105.82006664037158,
              21.0108639756285
            ],
            [
              105.82006225152729,
              21.01085911122211
            ],
            [
              105.82006090148522,
              21.010857614757793
            ],
            [
              105.82005032470704,
              21.01084589125615
            ],
            [
              105.8199174574471,
              21.010703709569736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10533",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011079989740896,
          "to_latitude": 21.010703709569736,
          "to_longitude": 105.8199174574471,
          "from_longitude": 105.82026152381128
        }
      },
      {
        "id": 10513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82014566630836,
            21.01096126466102,
            105.82015441155082,
            21.010968911084547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82015441155082,
              21.01096126466102
            ],
            [
              105.82014603197555,
              21.010968585063424
            ],
            [
              105.82014566630836,
              21.010968911084547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10534",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01096126466102,
          "to_latitude": 21.010968911084547,
          "to_longitude": 105.82014566630836,
          "from_longitude": 105.82015441155082
        }
      },
      {
        "id": 10514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82005765633548,
            21.010462127057448,
            105.82056744096015,
            21.010870346034633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82005765633548,
              21.010870346034633
            ],
            [
              105.8200587362114,
              21.010869578506323
            ],
            [
              105.82006664037158,
              21.0108639756285
            ],
            [
              105.82018639279904,
              21.01077909210038
            ],
            [
              105.82023550759101,
              21.01075036936726
            ],
            [
              105.8202596408216,
              21.010747891998342
            ],
            [
              105.82056744096015,
              21.010462127057448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_90_Ngõ 99, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10535",
          "diaChiLapD": "Ngõ 99, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010870346034633,
          "to_latitude": 21.010462127057448,
          "to_longitude": 105.82056744096015,
          "from_longitude": 105.82005765633548
        }
      },
      {
        "id": 10515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82005332460356,
            21.010453969031314,
            105.82055771668288,
            21.010865723417766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82005332460356,
              21.010865723417766
            ],
            [
              105.82005538819178,
              21.010864193340748
            ],
            [
              105.82006225152729,
              21.01085911122211
            ],
            [
              105.82020774188801,
              21.010751382948825
            ],
            [
              105.82024838601014,
              21.01073742184444
            ],
            [
              105.82038401681507,
              21.010613554212274
            ],
            [
              105.82055771668288,
              21.010453969031314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_90A_Ngõ 99, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10536",
          "diaChiLapD": "Ngõ 99, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010865723417766,
          "to_latitude": 21.010453969031314,
          "to_longitude": 105.82055771668288,
          "from_longitude": 105.82005332460356
        }
      },
      {
        "id": 10516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81987527212132,
            21.010417246762888,
            105.82032601787313,
            21.01070196728079
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81987527212132,
              21.01070196728079
            ],
            [
              105.81988935938521,
              21.01069107693169
            ],
            [
              105.81998711681261,
              21.010607248812093
            ],
            [
              105.8199960532608,
              21.010599741475623
            ],
            [
              105.82019139719415,
              21.010417246762888
            ],
            [
              105.82032601787313,
              21.01054040504083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91_Ngõ 109, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10537",
          "diaChiLapD": "Ngõ 109, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01070196728079,
          "to_latitude": 21.01054040504083,
          "to_longitude": 105.82032601787313,
          "from_longitude": 105.81987527212132
        }
      },
      {
        "id": 10517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81986002930635,
            21.010267706962008,
            105.82017656921447,
            21.010701009237373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81986002930635,
              21.010701009237373
            ],
            [
              105.81988176995702,
              21.01068227993374
            ],
            [
              105.82007697771522,
              21.010507735309304
            ],
            [
              105.82017527501267,
              21.01042029699334
            ],
            [
              105.82017656921447,
              21.010419145462816
            ],
            [
              105.82017473087403,
              21.010417360513525
            ],
            [
              105.82002058996763,
              21.010267706962008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91A_Ngõ 109, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10538",
          "diaChiLapD": "Ngõ 109, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010701009237373,
          "to_latitude": 21.010267706962008,
          "to_longitude": 105.82002058996763,
          "from_longitude": 105.81986002930635
        }
      },
      {
        "id": 10518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8199116992127,
            21.010362890829466,
            105.82007697771522,
            21.010507735309304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007697771522,
              21.010507735309304
            ],
            [
              105.82007504077502,
              21.010506037543973
            ],
            [
              105.8199116992127,
              21.010362890829466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91A_Ngõ 109, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10539",
          "diaChiLapD": "Ngõ 109, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010507735309304,
          "to_latitude": 21.010362890829466,
          "to_longitude": 105.8199116992127,
          "from_longitude": 105.82007697771522
        }
      },
      {
        "id": 10519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81998711681261,
            21.010607248812093,
            105.82012878721429,
            21.010738832900653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81998711681261,
              21.010607248812093
            ],
            [
              105.82000211501277,
              21.010621179274494
            ],
            [
              105.82012878721429,
              21.010738832900653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91_Ngõ 109, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10540",
          "diaChiLapD": "Ngõ 109, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010607248812093,
          "to_latitude": 21.010738832900653,
          "to_longitude": 105.82012878721429,
          "from_longitude": 105.81998711681261
        }
      },
      {
        "id": 10520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81949716936724,
            21.010479207403897,
            105.81982566357901,
            21.01068294135667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81949716936724,
              21.010479207403897
            ],
            [
              105.81959008391725,
              21.010529423752594
            ],
            [
              105.819654334308,
              21.010591371953232
            ],
            [
              105.81968725197981,
              21.01062310996978
            ],
            [
              105.81974854632331,
              21.01068220733349
            ],
            [
              105.81982566357901,
              21.01068294135667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10541",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010479207403897,
          "to_latitude": 21.01068294135667,
          "to_longitude": 105.81982566357901,
          "from_longitude": 105.81949716936724
        }
      },
      {
        "id": 10521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81964241865505,
            21.010591371953232,
            105.819654334308,
            21.010603075566564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.819654334308,
              21.010591371953232
            ],
            [
              105.81965173570258,
              21.010593922540096
            ],
            [
              105.81964698244117,
              21.01059858892926
            ],
            [
              105.81964241865505,
              21.010603075566564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10542",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010591371953232,
          "to_latitude": 21.010603075566564,
          "to_longitude": 105.81964241865505,
          "from_longitude": 105.819654334308
        }
      },
      {
        "id": 10522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81942395765624,
            21.010092412567044,
            105.81996996780894,
            21.010505086993387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81942395765624,
              21.010505086993387
            ],
            [
              105.81946358358545,
              21.010459155815663
            ],
            [
              105.81976555753185,
              21.010092412567044
            ],
            [
              105.81994071520539,
              21.01025483879992
            ],
            [
              105.81996996780894,
              21.010281965381978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10543",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010505086993387,
          "to_latitude": 21.010281965381978,
          "to_longitude": 105.81996996780894,
          "from_longitude": 105.81942395765624
        }
      },
      {
        "id": 10523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81981537742209,
            21.01020598904414,
            105.82004896934839,
            21.010427420852366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81981537742209,
              21.010427420852366
            ],
            [
              105.81996996780894,
              21.010281965381978
            ],
            [
              105.81997346175778,
              21.010278677958453
            ],
            [
              105.82004896934839,
              21.01020598904414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10544",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010427420852366,
          "to_latitude": 21.01020598904414,
          "to_longitude": 105.82004896934839,
          "from_longitude": 105.81981537742209
        }
      },
      {
        "id": 10524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81886563668535,
            21.010168527743684,
            105.81925697373381,
            21.010467771204333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81925697373381,
              21.010467771204333
            ],
            [
              105.81924221316338,
              21.010461407674708
            ],
            [
              105.81910032592099,
              21.01035105603332
            ],
            [
              105.81908753212014,
              21.01034110533727
            ],
            [
              105.81886563668535,
              21.010168527743684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10545",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010467771204333,
          "to_latitude": 21.010168527743684,
          "to_longitude": 105.81886563668535,
          "from_longitude": 105.81925697373381
        }
      },
      {
        "id": 10525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81907854688642,
            21.01034110533727,
            105.81908753212014,
            21.01035236848294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81908753212014,
              21.01034110533727
            ],
            [
              105.81908410615506,
              21.010345396805196
            ],
            [
              105.81907854688642,
              21.01035236848294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10546",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01034110533727,
          "to_latitude": 21.01035236848294,
          "to_longitude": 105.81907854688642,
          "from_longitude": 105.81908753212014
        }
      },
      {
        "id": 10526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82102937255173,
            21.01154933835267,
            105.82136530517575,
            21.011901064060297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82102937255173,
              21.011901064060297
            ],
            [
              105.82120401985469,
              21.011725168590257
            ],
            [
              105.82122257315716,
              21.011704942498366
            ],
            [
              105.82136530517575,
              21.01154933835267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Thái Hà",
          "maTaiSan": "04.O11.DODV.10547",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011901064060297,
          "to_latitude": 21.01154933835267,
          "to_longitude": 105.82136530517575,
          "from_longitude": 105.82102937255173
        }
      },
      {
        "id": 10527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82138944559127,
            21.011219483103186,
            105.82166618856218,
            21.011523030038067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82138944559127,
              21.011523030038067
            ],
            [
              105.82148102277968,
              21.011422583528326
            ],
            [
              105.82153672058293,
              21.011361491299372
            ],
            [
              105.82166618856218,
              21.011219483103186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Thái Hà",
          "maTaiSan": "04.O11.DODV.10548",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011523030038067,
          "to_latitude": 21.011219483103186,
          "to_longitude": 105.82166618856218,
          "from_longitude": 105.82138944559127
        }
      },
      {
        "id": 10528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82122257315716,
            21.011704942498366,
            105.82123669997245,
            21.01171691885275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82122257315716,
              21.011704942498366
            ],
            [
              105.82122700022941,
              21.011708695718564
            ],
            [
              105.82123458453492,
              21.01171512712431
            ],
            [
              105.82123669997245,
              21.01171691885275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Thái Hà",
          "maTaiSan": "04.O11.DODV.10549",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011704942498366,
          "to_latitude": 21.01171691885275,
          "to_longitude": 105.82123669997245,
          "from_longitude": 105.82122257315716
        }
      },
      {
        "id": 10529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82148102277968,
            21.011422583528326,
            105.82150120798302,
            21.011437947521216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82148102277968,
              21.011422583528326
            ],
            [
              105.82149416823758,
              21.011432591048138
            ],
            [
              105.82150120798302,
              21.011437947521216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85_Thái Hà",
          "maTaiSan": "04.O11.DODV.10550",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011422583528326,
          "to_latitude": 21.011437947521216,
          "to_longitude": 105.82150120798302,
          "from_longitude": 105.82148102277968
        }
      },
      {
        "id": 10530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81780045092042,
            21.010997493504295,
            105.81825369167794,
            21.01163930287724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81809508489744,
              21.010997493504295
            ],
            [
              105.81825369167794,
              21.011114667143886
            ],
            [
              105.81823614083514,
              21.01113498294094
            ],
            [
              105.81823049925292,
              21.011141513309674
            ],
            [
              105.81812946494371,
              21.011258463120395
            ],
            [
              105.81803012524692,
              21.01137345014206
            ],
            [
              105.81793153577343,
              21.01148757001636
            ],
            [
              105.81780790489309,
              21.011630674645296
            ],
            [
              105.81780045092042,
              21.01163930287724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10551",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010997493504295,
          "to_latitude": 21.01163930287724,
          "to_longitude": 105.81780045092042,
          "from_longitude": 105.81809508489744
        }
      },
      {
        "id": 10531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81764510519567,
            21.011525732391664,
            105.81805268802738,
            21.01183232320421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81764510519567,
              21.011525732391664
            ],
            [
              105.81779933815706,
              21.011638451965393
            ],
            [
              105.81780045092042,
              21.01163930287724
            ],
            [
              105.81805268802738,
              21.01183232320421
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10552",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011525732391664,
          "to_latitude": 21.01183232320421,
          "to_longitude": 105.81805268802738,
          "from_longitude": 105.81764510519567
        }
      },
      {
        "id": 10532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81793153577343,
            21.01148757001636,
            105.81817047621722,
            21.011668768516277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81793153577343,
              21.01148757001636
            ],
            [
              105.81817047621722,
              21.011668768516277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10553",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01148757001636,
          "to_latitude": 21.011668768516277,
          "to_longitude": 105.81817047621722,
          "from_longitude": 105.81793153577343
        }
      },
      {
        "id": 10533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81803012524692,
            21.01137345014206,
            105.81827266881923,
            21.01155356717804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81803012524692,
              21.01137345014206
            ],
            [
              105.81827266881923,
              21.01155356717804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10554",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01137345014206,
          "to_latitude": 21.01155356717804,
          "to_longitude": 105.81827266881923,
          "from_longitude": 105.81803012524692
        }
      },
      {
        "id": 10534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81812946494371,
            21.011258463120395,
            105.81837233957248,
            21.011442679080844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81812946494371,
              21.011258463120395
            ],
            [
              105.81837233957248,
              21.011442679080844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10555",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011258463120395,
          "to_latitude": 21.011442679080844,
          "to_longitude": 105.81837233957248,
          "from_longitude": 105.81812946494371
        }
      },
      {
        "id": 10535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81823049925292,
            21.011141513309674,
            105.81846789679294,
            21.01132416308484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81823049925292,
              21.011141513309674
            ],
            [
              105.81846789679294,
              21.01132416308484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10556",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011141513309674,
          "to_latitude": 21.01132416308484,
          "to_longitude": 105.81846789679294,
          "from_longitude": 105.81823049925292
        }
      },
      {
        "id": 10536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81817827695174,
            21.01089983213043,
            105.81855887065838,
            21.011175025277936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81817827695174,
              21.01089983213043
            ],
            [
              105.81843576689559,
              21.011098967715352
            ],
            [
              105.81855887065838,
              21.011175025277936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A71_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10557",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01089983213043,
          "to_latitude": 21.011175025277936,
          "to_longitude": 105.81855887065838,
          "from_longitude": 105.81817827695174
        }
      },
      {
        "id": 10537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81757998332635,
            21.011728306933996,
            105.81790824912854,
            21.011954134993058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81757998332635,
              21.011728306933996
            ],
            [
              105.81768899350271,
              21.011807416197982
            ],
            [
              105.8177378464748,
              21.011842922150105
            ],
            [
              105.8178361734501,
              21.011914383542045
            ],
            [
              105.81785926000686,
              21.01193142848494
            ],
            [
              105.81790824912854,
              21.011954134993058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10558",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011728306933996,
          "to_latitude": 21.011954134993058,
          "to_longitude": 105.81790824912854,
          "from_longitude": 105.81757998332635
        }
      },
      {
        "id": 10538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8177378464748,
            21.011838478100266,
            105.81774145252025,
            21.011842922150105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81774145252025,
              21.011838478100266
            ],
            [
              105.81774018935441,
              21.011840035013083
            ],
            [
              105.8177378464748,
              21.011842922150105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10559",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011838478100266,
          "to_latitude": 21.011842922150105,
          "to_longitude": 105.8177378464748,
          "from_longitude": 105.81774145252025
        }
      },
      {
        "id": 10539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81771375913073,
            21.011956904558208,
            105.81795497968254,
            21.01218776022737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81795497968254,
              21.011956904558208
            ],
            [
              105.81795464179949,
              21.011957211473476
            ],
            [
              105.81782735358958,
              21.012072654284612
            ],
            [
              105.81771375913073,
              21.01218776022737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10560",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011956904558208,
          "to_latitude": 21.01218776022737,
          "to_longitude": 105.81771375913073,
          "from_longitude": 105.81795497968254
        }
      },
      {
        "id": 10540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81796274070705,
            21.011969130373195,
            105.81823975384226,
            21.012261464917337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81796274070705,
              21.011969130373195
            ],
            [
              105.81800096665835,
              21.01200306965093
            ],
            [
              105.81808931474188,
              21.012093193482364
            ],
            [
              105.81813171488957,
              21.01214078391413
            ],
            [
              105.81814181136578,
              21.012153871911806
            ],
            [
              105.81815928499314,
              21.012176522134546
            ],
            [
              105.81816259283599,
              21.01217789810301
            ],
            [
              105.81816366391757,
              21.012178865729048
            ],
            [
              105.81816532274075,
              21.012180364336565
            ],
            [
              105.81816901273514,
              21.012185670217658
            ],
            [
              105.81823975384226,
              21.012261464917337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10561",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011969130373195,
          "to_latitude": 21.012261464917337,
          "to_longitude": 105.81823975384226,
          "from_longitude": 105.81796274070705
        }
      },
      {
        "id": 10541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81814181136578,
            21.01214535514565,
            105.81815219114108,
            21.012153871911806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81815219114108,
              21.01214535514565
            ],
            [
              105.81814938144618,
              21.012147660144404
            ],
            [
              105.81814181136578,
              21.012153871911806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10562",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01214535514565,
          "to_latitude": 21.012153871911806,
          "to_longitude": 105.81814181136578,
          "from_longitude": 105.81815219114108
        }
      },
      {
        "id": 10542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81795330107789,
            21.01217730502591,
            105.8182008914497,
            21.012481409763485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8182008914497,
              21.012201684703264
            ],
            [
              105.81820058195541,
              21.01220190567584
            ],
            [
              105.81819297199904,
              21.012207331829146
            ],
            [
              105.81816515118774,
              21.01217730502591
            ],
            [
              105.81816366391757,
              21.012178865729048
            ],
            [
              105.8180117495442,
              21.012338220988298
            ],
            [
              105.81805019925662,
              21.012392359863
            ],
            [
              105.81795330107789,
              21.012481409763485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10563",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012201684703264,
          "to_latitude": 21.012481409763485,
          "to_longitude": 105.81795330107789,
          "from_longitude": 105.8182008914497
        }
      },
      {
        "id": 10543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81850212868855,
            21.012560866692834,
            105.81889401281828,
            21.012974086402455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81889401281828,
              21.012974086402455
            ],
            [
              105.81885211732404,
              21.012936387704354
            ],
            [
              105.8188140272688,
              21.01288918227659
            ],
            [
              105.81874082289629,
              21.012812125236596
            ],
            [
              105.81867526361316,
              21.0127431152647
            ],
            [
              105.81851287058295,
              21.01257217491264
            ],
            [
              105.81850212868855,
              21.012560866692834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10564",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012974086402455,
          "to_latitude": 21.012560866692834,
          "to_longitude": 105.81850212868855,
          "from_longitude": 105.81889401281828
        }
      },
      {
        "id": 10544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81826003687658,
            21.012279812190158,
            105.81849251631321,
            21.012550128394828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81849251631321,
              21.012550128394828
            ],
            [
              105.81841150998085,
              21.012455937470335
            ],
            [
              105.81835977398038,
              21.012395781152428
            ],
            [
              105.81826003687658,
              21.012279812190158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10565",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012550128394828,
          "to_latitude": 21.012279812190158,
          "to_longitude": 105.81826003687658,
          "from_longitude": 105.81849251631321
        }
      },
      {
        "id": 10545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81833822551347,
            21.01257217491264,
            105.81851287058295,
            21.012747485126045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81851287058295,
              21.01257217491264
            ],
            [
              105.81833822551347,
              21.012747485126045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10566",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01257217491264,
          "to_latitude": 21.012747485126045,
          "to_longitude": 105.81833822551347,
          "from_longitude": 105.81851287058295
        }
      },
      {
        "id": 10546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81835977398038,
            21.012390262092268,
            105.81836754407344,
            21.012395781152428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81836754407344,
              21.012390262092268
            ],
            [
              105.81836679287551,
              21.01239080222252
            ],
            [
              105.81836138309224,
              21.01239469044321
            ],
            [
              105.81835977398038,
              21.012395781152428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10567",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012390262092268,
          "to_latitude": 21.012395781152428,
          "to_longitude": 105.81835977398038,
          "from_longitude": 105.81836754407344
        }
      },
      {
        "id": 10547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81813269194544,
            21.012100168290438,
            105.81849524410977,
            21.012497903295987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81849524410977,
              21.012497903295987
            ],
            [
              105.81833676023851,
              21.012324040076106
            ],
            [
              105.8182805963528,
              21.012262426443062
            ],
            [
              105.81813269194544,
              21.012100168290438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10568",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012497903295987,
          "to_latitude": 21.012100168290438,
          "to_longitude": 105.81813269194544,
          "from_longitude": 105.81849524410977
        }
      },
      {
        "id": 10548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81826405750289,
            21.012262426443062,
            105.8182805963528,
            21.012277308075415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8182805963528,
              21.012262426443062
            ],
            [
              105.81826903983361,
              21.012272824879954
            ],
            [
              105.81826590206498,
              21.012275648104602
            ],
            [
              105.81826442124864,
              21.01227698116419
            ],
            [
              105.81826405750289,
              21.012277308075415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10569",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012262426443062,
          "to_latitude": 21.012277308075415,
          "to_longitude": 105.81826405750289,
          "from_longitude": 105.8182805963528
        }
      },
      {
        "id": 10549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8202692745382,
            21.01181146274318,
            105.82074659257964,
            21.012223708965003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82074659257964,
              21.012223708965003
            ],
            [
              105.8207410876407,
              21.012215114214154
            ],
            [
              105.82072441365432,
              21.012189070598655
            ],
            [
              105.82051949099477,
              21.01205493430008
            ],
            [
              105.82047946676973,
              21.01202207098764
            ],
            [
              105.82036395877816,
              21.011927228150302
            ],
            [
              105.8203677951196,
              21.011919704737707
            ],
            [
              105.82037372711457,
              21.011908069665523
            ],
            [
              105.8202692745382,
              21.01181146274318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A2_Ngõ 89, Thái Hà",
          "maTaiSan": "04.O11.DODV.10570",
          "diaChiLapD": "Ngõ 89, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012223708965003,
          "to_latitude": 21.01181146274318,
          "to_longitude": 105.8202692745382,
          "from_longitude": 105.82074659257964
        }
      },
      {
        "id": 10550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82020648589982,
            21.011846011287002,
            105.8203677951196,
            21.011930011214268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8203677951196,
              21.011919704737707
            ],
            [
              105.82035041486822,
              21.011922276924345
            ],
            [
              105.82029815396771,
              21.011930011214268
            ],
            [
              105.82020648589982,
              21.011846011287002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Ngõ 89, Thái Hà",
          "maTaiSan": "04.O11.DODV.10571",
          "diaChiLapD": "Ngõ 89, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011919704737707,
          "to_latitude": 21.011846011287002,
          "to_longitude": 105.82020648589982,
          "from_longitude": 105.8203677951196
        }
      },
      {
        "id": 10551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82014027209131,
            21.011938493969907,
            105.82051949099477,
            21.012154949262406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82051949099477,
              21.01205493430008
            ],
            [
              105.82042012937494,
              21.012137869947043
            ],
            [
              105.82038693275523,
              21.012154949262406
            ],
            [
              105.82018662034181,
              21.0119846422602
            ],
            [
              105.82017332547618,
              21.011971404514604
            ],
            [
              105.82014027209131,
              21.011938493969907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Ngõ 89, Thái Hà",
          "maTaiSan": "04.O11.DODV.10572",
          "diaChiLapD": "Ngõ 89, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01205493430008,
          "to_latitude": 21.011938493969907,
          "to_longitude": 105.82014027209131,
          "from_longitude": 105.82051949099477
        }
      },
      {
        "id": 10552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82006111660408,
            21.011971404514604,
            105.82017332547618,
            21.012069343070607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82017332547618,
              21.011971404514604
            ],
            [
              105.82006111660408,
              21.012069343070607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Ngõ 89, Thái Hà",
          "maTaiSan": "04.O11.DODV.10573",
          "diaChiLapD": "Ngõ 89, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011971404514604,
          "to_latitude": 21.012069343070607,
          "to_longitude": 105.82006111660408,
          "from_longitude": 105.82017332547618
        }
      },
      {
        "id": 10553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82047946676973,
            21.01183523749186,
            105.82063810937694,
            21.01202207098764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82047946676973,
              21.01202207098764
            ],
            [
              105.82048220980094,
              21.012018840464844
            ],
            [
              105.82063810937694,
              21.01183523749186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Ngõ 89, Thái Hà",
          "maTaiSan": "04.O11.DODV.10574",
          "diaChiLapD": "Ngõ 89, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01202207098764,
          "to_latitude": 21.01183523749186,
          "to_longitude": 105.82063810937694,
          "from_longitude": 105.82047946676973
        }
      },
      {
        "id": 10554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82035530240348,
            21.012226060156014,
            105.8207173621945,
            21.012571039762868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8207173621945,
              21.012226060156014
            ],
            [
              105.82059024371937,
              21.01236707362092
            ],
            [
              105.82051121550542,
              21.0124464121562
            ],
            [
              105.82049785566527,
              21.012459824473726
            ],
            [
              105.82042088183019,
              21.01252083651555
            ],
            [
              105.82035530240348,
              21.012571039762868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10575",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012226060156014,
          "to_latitude": 21.012571039762868,
          "to_longitude": 105.82035530240348,
          "from_longitude": 105.8207173621945
        }
      },
      {
        "id": 10555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82051121550542,
            21.0124464121562,
            105.82051895962613,
            21.012453922179056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82051121550542,
              21.0124464121562
            ],
            [
              105.82051563298815,
              21.012450697441594
            ],
            [
              105.82051584092152,
              21.0124509023747
            ],
            [
              105.82051895962613,
              21.012453922179056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10576",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0124464121562,
          "to_latitude": 21.012453922179056,
          "to_longitude": 105.82051895962613,
          "from_longitude": 105.82051121550542
        }
      },
      {
        "id": 10556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82077131504171,
            21.011969601016386,
            105.82095279438116,
            21.012174696211776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82077131504171,
              21.012174696211776
            ],
            [
              105.82087834946653,
              21.012060778781738
            ],
            [
              105.82088704361664,
              21.012051525725905
            ],
            [
              105.82094037810087,
              21.011994761608044
            ],
            [
              105.82095279438116,
              21.011969601016386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1_Thái Hà",
          "maTaiSan": "04.O11.DODV.10577",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012174696211776,
          "to_latitude": 21.011969601016386,
          "to_longitude": 105.82095279438116,
          "from_longitude": 105.82077131504171
        }
      },
      {
        "id": 10557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82088704361664,
            21.012051525725905,
            105.82089211681252,
            21.012055929774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82088704361664,
              21.012051525725905
            ],
            [
              105.82089153307767,
              21.012055429492605
            ],
            [
              105.82089211681252,
              21.012055929774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A1_Thái Hà",
          "maTaiSan": "04.O11.DODV.10578",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 4.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012051525725905,
          "to_latitude": 21.012055929774,
          "to_longitude": 105.82089211681252,
          "from_longitude": 105.82088704361664
        }
      },
      {
        "id": 10558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82047637213908,
            21.011574262330274,
            105.82069247281237,
            21.01178705982572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82069247281237,
              21.011574262330274
            ],
            [
              105.82069245177435,
              21.011574285012433
            ],
            [
              105.82069207591657,
              21.01157468243884
            ],
            [
              105.8205423590797,
              21.011732994178985
            ],
            [
              105.82052108956182,
              21.01174061701743
            ],
            [
              105.82047637213908,
              21.01178705982572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10579",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011574262330274,
          "to_latitude": 21.01178705982572,
          "to_longitude": 105.82047637213908,
          "from_longitude": 105.82069247281237
        }
      },
      {
        "id": 10559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82071201326555,
            21.011637415674706,
            105.82098444402418,
            21.011926311079034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82071201326555,
              21.011637415674706
            ],
            [
              105.82077812624217,
              21.01171314039176
            ],
            [
              105.82088003326336,
              21.01181843206471
            ],
            [
              105.82098444402418,
              21.011926311079034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10580",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011637415674706,
          "to_latitude": 21.011926311079034,
          "to_longitude": 105.82098444402418,
          "from_longitude": 105.82071201326555
        }
      },
      {
        "id": 10560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82088003326336,
            21.011798011496605,
            105.82090333260578,
            21.01181843206471
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82088003326336,
              21.01181843206471
            ],
            [
              105.8208957458153,
              21.01180466062577
            ],
            [
              105.82090275821024,
              21.011798514655695
            ],
            [
              105.82090330867226,
              21.01179803238631
            ],
            [
              105.82090333260578,
              21.011798011496605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10581",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01181843206471,
          "to_latitude": 21.011798011496605,
          "to_longitude": 105.82090333260578,
          "from_longitude": 105.82088003326336
        }
      },
      {
        "id": 10561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82046692291938,
            21.011358176242517,
            105.8206713852024,
            21.01158770199693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82046692291938,
              21.011358176242517
            ],
            [
              105.82056297665265,
              21.011466005511814
            ],
            [
              105.8205786481615,
              21.011483597967903
            ],
            [
              105.8206713852024,
              21.01158770199693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10582",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011358176242517,
          "to_latitude": 21.01158770199693,
          "to_longitude": 105.8206713852024,
          "from_longitude": 105.82046692291938
        }
      },
      {
        "id": 10562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82056297665265,
            21.011454032758454,
            105.8205795570627,
            21.011466005511814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82056297665265,
              21.011466005511814
            ],
            [
              105.82057306670104,
              21.011458719292104
            ],
            [
              105.8205790962031,
              21.01145436556217
            ],
            [
              105.82057953023357,
              21.011454051855612
            ],
            [
              105.8205795570627,
              21.011454032758454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10583",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011466005511814,
          "to_latitude": 21.011454032758454,
          "to_longitude": 105.8205795570627,
          "from_longitude": 105.82056297665265
        }
      },
      {
        "id": 10563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81992745760488,
            21.011671183134954,
            105.82020281172632,
            21.011893751173595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994785818723,
              21.01179364490063
            ],
            [
              105.81994414327825,
              21.01179153389502
            ],
            [
              105.81994301246381,
              21.011790902568855
            ],
            [
              105.81992745760488,
              21.011814359373748
            ],
            [
              105.81995719040188,
              21.011849208935278
            ],
            [
              105.81997057518642,
              21.011875384095575
            ],
            [
              105.81999401452434,
              21.011893751173595
            ],
            [
              105.82020281172632,
              21.011671183134954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10584",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01179364490063,
          "to_latitude": 21.011671183134954,
          "to_longitude": 105.82020281172632,
          "from_longitude": 105.81994785818723
        }
      },
      {
        "id": 10564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82023168323886,
            21.011377093521286,
            105.82042509465964,
            21.011645787013695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8203501963985,
              21.011377093521286
            ],
            [
              105.82035203365675,
              21.011378677956817
            ],
            [
              105.82036718503495,
              21.011393686097747
            ],
            [
              105.8203730566525,
              21.011399510703004
            ],
            [
              105.82042509465964,
              21.01145852687977
            ],
            [
              105.82030993032167,
              21.01156683624612
            ],
            [
              105.82023168323886,
              21.011645787013695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10585",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011377093521286,
          "to_latitude": 21.011645787013695,
          "to_longitude": 105.82023168323886,
          "from_longitude": 105.8203501963985
        }
      },
      {
        "id": 10565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8199480107079,
            21.01134998207334,
            105.8204118673369,
            21.01180342340948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8199480107079,
              21.01180342340948
            ],
            [
              105.82002196713238,
              21.011700400755792
            ],
            [
              105.82004651061945,
              21.011664722407126
            ],
            [
              105.8200690453019,
              21.011645527702374
            ],
            [
              105.82006907121362,
              21.011645516738767
            ],
            [
              105.82016439641042,
              21.011564318690425
            ],
            [
              105.82035107073715,
              21.01140611271344
            ],
            [
              105.82035748719764,
              21.011398640990976
            ],
            [
              105.82036544096079,
              21.011395773735902
            ],
            [
              105.82036718503495,
              21.011393686097747
            ],
            [
              105.8203687417991,
              21.011391822459817
            ],
            [
              105.82036883094538,
              21.01138543259911
            ],
            [
              105.8204118673369,
              21.01134998207334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10586",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01180342340948,
          "to_latitude": 21.01134998207334,
          "to_longitude": 105.8204118673369,
          "from_longitude": 105.8199480107079
        }
      },
      {
        "id": 10566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82006141150616,
            21.01163612643733,
            105.82006907121362,
            21.011645516738767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82006650112164,
              21.011641319182164
            ],
            [
              105.82006202196293,
              21.01163612643733
            ],
            [
              105.82006141150616,
              21.01163654396081
            ],
            [
              105.82006589035964,
              21.011641856836405
            ],
            [
              105.82006907121362,
              21.011645516738767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10587",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011641319182164,
          "to_latitude": 21.011645516738767,
          "to_longitude": 105.82006907121362,
          "from_longitude": 105.82006650112164
        }
      },
      {
        "id": 10567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81995373959349,
            21.011341425069133,
            105.82039084990721,
            21.011769228514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81995373959349,
              21.011769228514
            ],
            [
              105.82004242840112,
              21.011652254057953
            ],
            [
              105.82006038797715,
              21.011635302439785
            ],
            [
              105.82008850000283,
              21.011612268329475
            ],
            [
              105.82011654211304,
              21.011589291455017
            ],
            [
              105.82016367386738,
              21.01154915969378
            ],
            [
              105.82016369881227,
              21.01154914783159
            ],
            [
              105.82017894725176,
              21.011536162695865
            ],
            [
              105.82020313068863,
              21.011515573572673
            ],
            [
              105.82032422788126,
              21.011413197879172
            ],
            [
              105.82033400136481,
              21.01138618942178
            ],
            [
              105.82039084990721,
              21.011341425069133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10588",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011769228514,
          "to_latitude": 21.011341425069133,
          "to_longitude": 105.82039084990721,
          "from_longitude": 105.81995373959349
        }
      },
      {
        "id": 10568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82008850000283,
            21.011612268329475,
            105.82009313588705,
            21.01161780083504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82008850000283,
              21.011612268329475
            ],
            [
              105.82008915258608,
              21.01161304647601
            ],
            [
              105.82009313588705,
              21.01161780083504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10589",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011612268329475,
          "to_latitude": 21.01161780083504,
          "to_longitude": 105.82009313588705,
          "from_longitude": 105.82008850000283
        }
      },
      {
        "id": 10569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82011140295998,
            21.011488525974542,
            105.82016367386738,
            21.01154915969378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82016367386738,
              21.01154915969378
            ],
            [
              105.82011140295998,
              21.011488525974542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10590",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01154915969378,
          "to_latitude": 21.011488525974542,
          "to_longitude": 105.82011140295998,
          "from_longitude": 105.82016367386738
        }
      },
      {
        "id": 10570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8197235503809,
            21.011605645815536,
            105.8199499573781,
            21.011790706521477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8199499573781,
              21.011790706521477
            ],
            [
              105.81994733791515,
              21.011789091528378
            ],
            [
              105.81991172199237,
              21.01176355729815
            ],
            [
              105.81989079854901,
              21.0117551307485
            ],
            [
              105.8197235503809,
              21.011605645815536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DODV.10591",
          "diaChiLapD": "Ngõ 98, Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011790706521477,
          "to_latitude": 21.011605645815536,
          "to_longitude": 105.8197235503809,
          "from_longitude": 105.8199499573781
        }
      },
      {
        "id": 10571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81969054369284,
            21.0111980547104,
            105.8202621712325,
            21.01161346780422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81969054369284,
              21.01161346780422
            ],
            [
              105.81986751053509,
              21.011465038673204
            ],
            [
              105.82000915238439,
              21.011361772979402
            ],
            [
              105.82007594263844,
              21.011320576438507
            ],
            [
              105.82016543915596,
              21.011265373370744
            ],
            [
              105.82016846379389,
              21.01126350898199
            ],
            [
              105.8202621712325,
              21.0111980547104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10592",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01161346780422,
          "to_latitude": 21.0111980547104,
          "to_longitude": 105.8202621712325,
          "from_longitude": 105.81969054369284
        }
      },
      {
        "id": 10572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82016543915596,
            21.011265373370744,
            105.82023011436168,
            21.011339823566683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82016543915596,
              21.011265373370744
            ],
            [
              105.82023011436168,
              21.011339823566683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10593",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011265373370744,
          "to_latitude": 21.011339823566683,
          "to_longitude": 105.82023011436168,
          "from_longitude": 105.82016543915596
        }
      },
      {
        "id": 10573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81966160993296,
            21.011184458743713,
            105.8202469285289,
            21.011611993116773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81966160993296,
              21.011611993116773
            ],
            [
              105.81971916547307,
              21.011562772664302
            ],
            [
              105.81989189392604,
              21.01142382704234
            ],
            [
              105.81997318739015,
              21.011365280108198
            ],
            [
              105.81998395092765,
              21.011357313256617
            ],
            [
              105.82010900922623,
              21.011281671320663
            ],
            [
              105.82016965119097,
              21.011247921681267
            ],
            [
              105.8202469285289,
              21.011184458743713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10594",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011611993116773,
          "to_latitude": 21.011184458743713,
          "to_longitude": 105.8202469285289,
          "from_longitude": 105.81966160993296
        }
      },
      {
        "id": 10574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81997318739015,
            21.011365280108198,
            105.8199786415966,
            21.011372174353927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8199786415966,
              21.011372174353927
            ],
            [
              105.81997459025177,
              21.01136693618977
            ],
            [
              105.81997318739015,
              21.011365280108198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10595",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011372174353927,
          "to_latitude": 21.011365280108198,
          "to_longitude": 105.81997318739015,
          "from_longitude": 105.8199786415966
        }
      },
      {
        "id": 10575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82007169339744,
            21.011314857810188,
            105.82007594263844,
            21.011320576438507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007169339744,
              21.011314857810188
            ],
            [
              105.8200739630097,
              21.01131793410341
            ],
            [
              105.82007594263844,
              21.011320576438507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10596",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011314857810188,
          "to_latitude": 21.011320576438507,
          "to_longitude": 105.82007594263844,
          "from_longitude": 105.82007169339744
        }
      },
      {
        "id": 10576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8201270882776,
            21.010977761933976,
            105.82033083946538,
            21.011210953248327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8201270882776,
              21.010977761933976
            ],
            [
              105.82026561876563,
              21.01113630881544
            ],
            [
              105.82027482413825,
              21.011146844846593
            ],
            [
              105.82028961850374,
              21.01116376775252
            ],
            [
              105.82028991959076,
              21.011164121269477
            ],
            [
              105.82033083946538,
              21.011210953248327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10597",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010977761933976,
          "to_latitude": 21.011210953248327,
          "to_longitude": 105.82033083946538,
          "from_longitude": 105.8201270882776
        }
      },
      {
        "id": 10577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82026561876563,
            21.011122663460092,
            105.82028385508677,
            21.01113630881544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82026561876563,
              21.01113630881544
            ],
            [
              105.82027529639095,
              21.011129067053144
            ],
            [
              105.82028305516216,
              21.011123261639963
            ],
            [
              105.82028382826262,
              21.011122683460403
            ],
            [
              105.82028385508677,
              21.011122663460092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10598",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01113630881544,
          "to_latitude": 21.011122663460092,
          "to_longitude": 105.82028385508677,
          "from_longitude": 105.82026561876563
        }
      },
      {
        "id": 10578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81975481949588,
            21.0107132604862,
            105.82009368649418,
            21.010936678840046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81975481949588,
              21.0107140014344
            ],
            [
              105.81987411605901,
              21.0107132604862
            ],
            [
              105.81989435729562,
              21.010724667368255
            ],
            [
              105.81993370693768,
              21.010758622476303
            ],
            [
              105.81994244958678,
              21.01076616655743
            ],
            [
              105.82009368649418,
              21.010936678840046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10599",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0107140014344,
          "to_latitude": 21.010936678840046,
          "to_longitude": 105.82009368649418,
          "from_longitude": 105.81975481949588
        }
      },
      {
        "id": 10579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993370693768,
            21.01074684221112,
            105.81994802981588,
            21.010758622476303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81993370693768,
              21.010758622476303
            ],
            [
              105.81994694102723,
              21.01074773894322
            ],
            [
              105.81994800492043,
              21.010746863105304
            ],
            [
              105.81994802981588,
              21.01074684221112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10600",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010758622476303,
          "to_latitude": 21.01074684221112,
          "to_longitude": 105.81994802981588,
          "from_longitude": 105.81993370693768
        }
      },
      {
        "id": 10580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81932550061461,
            21.010714949446506,
            105.8197232115759,
            21.011019792902363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81932550061461,
              21.010933089461446
            ],
            [
              105.81943918800347,
              21.011019792902363
            ],
            [
              105.81949452617512,
              21.010961058669643
            ],
            [
              105.81954828130436,
              21.010906782246753
            ],
            [
              105.81954831200571,
              21.010906767647242
            ],
            [
              105.8195666460599,
              21.010888466661452
            ],
            [
              105.8196056552404,
              21.010854821414068
            ],
            [
              105.81968045569,
              21.010757197043198
            ],
            [
              105.8197232115759,
              21.010714949446506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10601",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010933089461446,
          "to_latitude": 21.010714949446506,
          "to_longitude": 105.8197232115759,
          "from_longitude": 105.81932550061461
        }
      },
      {
        "id": 10581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81954381510903,
            21.01090096168106,
            105.81954828130436,
            21.010906782246753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81954381510903,
              21.01090096168106
            ],
            [
              105.81954443380879,
              21.010902229542495
            ],
            [
              105.81954828130436,
              21.010906782246753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10602",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01090096168106,
          "to_latitude": 21.010906782246753,
          "to_longitude": 105.81954828130436,
          "from_longitude": 105.81954381510903
        }
      },
      {
        "id": 10582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81948696818915,
            21.010955903316514,
            105.81980199680653,
            21.011189161458496
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81948696818915,
                21.010955903316514
              ],
              [
                105.8194899999504,
                21.010957631944894
              ],
              [
                105.81949452617512,
                21.010961058669643
              ],
              [
                105.81968007589532,
                21.011123969796245
              ]
            ],
            [
              [
                105.81980199680653,
                21.011002277688778
              ],
              [
                105.81968007589532,
                21.011123969796245
              ]
            ],
            [
              [
                105.81968007589532,
                21.011123969796245
              ],
              [
                105.819614758564,
                21.011189161458496
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A26_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10603",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8194899999504,
            21.010957631944894
          ],
          "to_latitude": [
            105.819614758564,
            21.011189161458496
          ],
          "to_longitude": [
            105.81968007589532,
            21.011123969796245
          ],
          "from_longitude": [
            105.81948696818915,
            21.010955903316514
          ]
        }
      },
      {
        "id": 10583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8194469807702,
            21.01068784083013,
            105.81968515853346,
            21.010982165749866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8194469807702,
              21.010982165749866
            ],
            [
              105.8195161516113,
              21.010903259048092
            ],
            [
              105.8195337487832,
              21.01090044293631
            ],
            [
              105.81958258872142,
              21.010856944004022
            ],
            [
              105.81962448675453,
              21.010800630986168
            ],
            [
              105.81962397891925,
              21.010781624103608
            ],
            [
              105.81968515853346,
              21.01068784083013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10604",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010982165749866,
          "to_latitude": 21.01068784083013,
          "to_longitude": 105.81968515853346,
          "from_longitude": 105.8194469807702
        }
      },
      {
        "id": 10584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81958258872142,
            21.010856944004022,
            105.81959080505402,
            21.01086287747395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81959080505402,
              21.01086287747395
            ],
            [
              105.81958825144345,
              21.01086116370558
            ],
            [
              105.81958258872142,
              21.010856944004022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10605",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01086287747395,
          "to_latitude": 21.010856944004022,
          "to_longitude": 105.81958258872142,
          "from_longitude": 105.81959080505402
        }
      },
      {
        "id": 10585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81904936561722,
            21.010915694941,
            105.81932792100163,
            21.011188149095624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81932792100163,
              21.01091692538686
            ],
            [
              105.81932466083526,
              21.010921105867432
            ],
            [
              105.81931596737039,
              21.010915694941
            ],
            [
              105.81925380930873,
              21.010981142224548
            ],
            [
              105.81925809561521,
              21.011015323206518
            ],
            [
              105.8192587532168,
              21.011042564096474
            ],
            [
              105.81912197088974,
              21.011188149095624
            ],
            [
              105.81904936561722,
              21.01112502697709
            ],
            [
              105.8190648083807,
              21.01110762963969
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A27_Trung Liệt",
          "maTaiSan": "04.O11.DODV.10606",
          "diaChiLapD": "Trung Liệt",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01091692538686,
          "to_latitude": 21.01110762963969,
          "to_longitude": 105.8190648083807,
          "from_longitude": 105.81932792100163
        }
      },
      {
        "id": 10586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81998258805443,
            21.012290809018733,
            105.82028057040209,
            21.012590481638956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81998258805443,
              21.012290809018733
            ],
            [
              105.82011965634018,
              21.012429428783914
            ],
            [
              105.82014391248077,
              21.01245395913669
            ],
            [
              105.82017103947422,
              21.01248139216854
            ],
            [
              105.82028057040209,
              21.012590481638956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10607",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012290809018733,
          "to_latitude": 21.012590481638956,
          "to_longitude": 105.82028057040209,
          "from_longitude": 105.81998258805443
        }
      },
      {
        "id": 10587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82011965634018,
            21.012408714087375,
            105.82014215972852,
            21.012429428783914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82014215972852,
              21.012408714087375
            ],
            [
              105.82014169170318,
              21.012409144473075
            ],
            [
              105.82013303854434,
              21.01241711019572
            ],
            [
              105.82011965634018,
              21.012429428783914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A29_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10608",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012408714087375,
          "to_latitude": 21.012429428783914,
          "to_longitude": 105.82011965634018,
          "from_longitude": 105.82014215972852
        }
      },
      {
        "id": 10588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82010698754543,
            21.01235968843467,
            105.82032658357056,
            21.01257548120002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82010698754543,
              21.01235968843467
            ],
            [
              105.82016297323722,
              21.01242252527079
            ],
            [
              105.82021576511636,
              21.012472465847637
            ],
            [
              105.82023460555448,
              21.01249028873341
            ],
            [
              105.82027777691313,
              21.012531005844213
            ],
            [
              105.82032658357056,
              21.01257548120002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10609",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01235968843467,
          "to_latitude": 21.01257548120002,
          "to_longitude": 105.82032658357056,
          "from_longitude": 105.82010698754543
        }
      },
      {
        "id": 10589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82021232521271,
            21.012472465847637,
            105.82021576511636,
            21.0124750900472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82021576511636,
              21.012472465847637
            ],
            [
              105.82021270359039,
              21.012474800996912
            ],
            [
              105.82021232521271,
              21.0124750900472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A28_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10610",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012472465847637,
          "to_latitude": 21.0124750900472,
          "to_longitude": 105.82021232521271,
          "from_longitude": 105.82021576511636
        }
      },
      {
        "id": 10590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81962947045199,
            21.012225160814562,
            105.81996924527527,
            21.012532818558615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81996924527527,
              21.012225160814562
            ],
            [
              105.81996868724185,
              21.012225666601427
            ],
            [
              105.81962947045199,
              21.012532818558615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A30_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10611",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012225160814562,
          "to_latitude": 21.012532818558615,
          "to_longitude": 105.81962947045199,
          "from_longitude": 105.81996924527527
        }
      },
      {
        "id": 10591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8196114556774,
            21.012198722959507,
            105.81994271057448,
            21.012516806210765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994271057448,
              21.012198722959507
            ],
            [
              105.81994268378469,
              21.0121987492822
            ],
            [
              105.8196114556774,
              21.012516806210765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A31_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10612",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012198722959507,
          "to_latitude": 21.012516806210765,
          "to_longitude": 105.8196114556774,
          "from_longitude": 105.81994271057448
        }
      },
      {
        "id": 10592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81957140376434,
            21.011843923040026,
            105.81990326346956,
            21.012209312806966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81957140376434,
              21.011843923040026
            ],
            [
              105.81973527454934,
              21.012024350895377
            ],
            [
              105.81974438290368,
              21.012034379807226
            ],
            [
              105.81990326346956,
              21.012209312806966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10613",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011843923040026,
          "to_latitude": 21.012209312806966,
          "to_longitude": 105.81990326346956,
          "from_longitude": 105.81957140376434
        }
      },
      {
        "id": 10593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81973527454934,
            21.01200496337823,
            105.81976015534688,
            21.012024350895377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81973527454934,
              21.012024350895377
            ],
            [
              105.8197376001797,
              21.012022538655707
            ],
            [
              105.81975975688823,
              21.01200527420172
            ],
            [
              105.81976015534688,
              21.01200496337823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10614",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.02,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012024350895377,
          "to_latitude": 21.01200496337823,
          "to_longitude": 105.81976015534688,
          "from_longitude": 105.81973527454934
        }
      },
      {
        "id": 10594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81979801632953,
            21.0120440361372,
            105.820099161652,
            21.012352699948593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81979801632953,
              21.0120440361372
            ],
            [
              105.81994394355024,
              21.01219094203621
            ],
            [
              105.81994593601213,
              21.012193114632193
            ],
            [
              105.82004466792594,
              21.012300769595655
            ],
            [
              105.820099161652,
              21.012352699948593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10615",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0120440361372,
          "to_latitude": 21.012352699948593,
          "to_longitude": 105.820099161652,
          "from_longitude": 105.81979801632953
        }
      },
      {
        "id": 10595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81994024076101,
            21.012193114632193,
            105.81994593601213,
            21.0121973863427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994593601213,
              21.012193114632193
            ],
            [
              105.8199441302012,
              21.012194469143104
            ],
            [
              105.81994026279075,
              21.01219736907521
            ],
            [
              105.81994024076101,
              21.0121973863427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10616",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012193114632193,
          "to_latitude": 21.0121973863427,
          "to_longitude": 105.81994024076101,
          "from_longitude": 105.81994593601213
        }
      },
      {
        "id": 10596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8189797462383,
            21.01181183004642,
            105.81958217888233,
            21.012278645578377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81958217888233,
              21.01181183004642
            ],
            [
              105.81958181458303,
              21.01181205489835
            ],
            [
              105.81954828941006,
              21.011832738608526
            ],
            [
              105.81921231683906,
              21.012135608177136
            ],
            [
              105.81915659961041,
              21.0121119815381
            ],
            [
              105.8189797462383,
              21.012278645578377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10617",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01181183004642,
          "to_latitude": 21.012278645578377,
          "to_longitude": 105.8189797462383,
          "from_longitude": 105.81958217888233
        }
      },
      {
        "id": 10597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81936750172375,
            21.011571967731953,
            105.8197735911816,
            21.01201407905085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81936750172375,
              21.011571967731953
            ],
            [
              105.81937562057344,
              21.011583823240432
            ],
            [
              105.81949404310744,
              21.01170928118712
            ],
            [
              105.81957197602463,
              21.011794253608144
            ],
            [
              105.8195752196839,
              21.011797790378477
            ],
            [
              105.8197735911816,
              21.01201407905085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Ngõ 117, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10618",
          "diaChiLapD": "Ngõ 117, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011571967731953,
          "to_latitude": 21.01201407905085,
          "to_longitude": 105.8197735911816,
          "from_longitude": 105.81936750172375
        }
      },
      {
        "id": 10598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81956846087516,
            21.011794253608144,
            105.81957197602463,
            21.01179701364134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81957197602463,
              21.011794253608144
            ],
            [
              105.81956868883282,
              21.01179683460752
            ],
            [
              105.81956846087516,
              21.01179701364134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Ngõ 117, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10619",
          "diaChiLapD": "Ngõ 117, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011794253608144,
          "to_latitude": 21.01179701364134,
          "to_longitude": 105.81956846087516,
          "from_longitude": 105.81957197602463
        }
      },
      {
        "id": 10599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81927126183881,
            21.011527495564135,
            105.81954225865053,
            21.011808168253644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81927126183881,
              21.011527495564135
            ],
            [
              105.81933886403556,
              21.011584369655587
            ],
            [
              105.81943655533759,
              21.011691859303752
            ],
            [
              105.81945516189208,
              21.011712333478798
            ],
            [
              105.81954225865053,
              21.011808168253644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10620",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011527495564135,
          "to_latitude": 21.011808168253644,
          "to_longitude": 105.81954225865053,
          "from_longitude": 105.81927126183881
        }
      },
      {
        "id": 10600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81945516189208,
            21.01169535487644,
            105.81947779918441,
            21.011712333478798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81947779918441,
              21.01169535487644
            ],
            [
              105.81947674730782,
              21.011696143040627
            ],
            [
              105.819468632182,
              21.011702230135118
            ],
            [
              105.81945516189208,
              21.011712333478798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10621",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01169535487644,
          "to_latitude": 21.011712333478798,
          "to_longitude": 105.81945516189208,
          "from_longitude": 105.81947779918441
        }
      },
      {
        "id": 10601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81930082295165,
            21.01121126410372,
            105.81944095085885,
            21.011534467953986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81930082295165,
              21.011534467953986
            ],
            [
              105.81931306212029,
              21.011520866027286
            ],
            [
              105.81944095085885,
              21.011376188998657
            ],
            [
              105.81938660842566,
              21.011324729169008
            ],
            [
              105.8193943514757,
              21.01125157560693
            ],
            [
              105.8193481154118,
              21.01121126410372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A84_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10622",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011534467953986,
          "to_latitude": 21.01121126410372,
          "to_longitude": 105.8193481154118,
          "from_longitude": 105.81930082295165
        }
      },
      {
        "id": 10602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8193087739442,
            21.011070655714942,
            105.81961092865765,
            21.011540247358802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8193087739442,
              21.011540247358802
            ],
            [
              105.8193202820476,
              21.01152893321432
            ],
            [
              105.81947569584239,
              21.011376144509534
            ],
            [
              105.81946729276315,
              21.011359095912397
            ],
            [
              105.81961092865765,
              21.01119927528103
            ],
            [
              105.8194556393628,
              21.011070655714942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10623",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011540247358802,
          "to_latitude": 21.011070655714942,
          "to_longitude": 105.8194556393628,
          "from_longitude": 105.8193087739442
        }
      },
      {
        "id": 10603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81892387460091,
            21.011336013652542,
            105.81921772810892,
            21.011611508377765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81892387460091,
              21.01154652604603
            ],
            [
              105.81902056473376,
              21.01161093770562
            ],
            [
              105.81905794108106,
              21.011611508377765
            ],
            [
              105.81910951722217,
              21.01156839021081
            ],
            [
              105.81913750811127,
              21.011544990126477
            ],
            [
              105.81919109504187,
              21.01150019038137
            ],
            [
              105.81921772810892,
              21.011477924952672
            ],
            [
              105.81920073675138,
              21.011431746147043
            ],
            [
              105.81919784636536,
              21.01139495714918
            ],
            [
              105.81913607085305,
              21.011336013652542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10624",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01154652604603,
          "to_latitude": 21.011336013652542,
          "to_longitude": 105.81913607085305,
          "from_longitude": 105.81892387460091
        }
      },
      {
        "id": 10604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81910951722217,
            21.01156839021081,
            105.81912083738762,
            21.011580614193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81910951722217,
              21.01156839021081
            ],
            [
              105.81911659728831,
              21.011576036272665
            ],
            [
              105.8191207551537,
              21.011580527878998
            ],
            [
              105.81912083738762,
              21.011580614193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10625",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01156839021081,
          "to_latitude": 21.011580614193,
          "to_longitude": 105.81912083738762,
          "from_longitude": 105.81910951722217
        }
      },
      {
        "id": 10605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81910372467104,
            21.011588769314514,
            105.81933371548448,
            21.011796715252114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81910372467104,
              21.011589901364648
            ],
            [
              105.81910389674516,
              21.01159005769792
            ],
            [
              105.81910666428094,
              21.011592544509487
            ],
            [
              105.81911823568913,
              21.011588769314514
            ],
            [
              105.81933371548448,
              21.011796715252114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A40_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10626",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011589901364648,
          "to_latitude": 21.011796715252114,
          "to_longitude": 105.81933371548448,
          "from_longitude": 105.81910372467104
        }
      },
      {
        "id": 10606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81902541930404,
            21.011614435315717,
            105.81931807400626,
            21.012002456151453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81902612926021,
              21.011614435315717
            ],
            [
              105.81902608101598,
              21.011615289098057
            ],
            [
              105.81902541930404,
              21.011627286243012
            ],
            [
              105.8192303264069,
              21.011818457879826
            ],
            [
              105.81930839302284,
              21.011891291304146
            ],
            [
              105.81931807400626,
              21.011900323029174
            ],
            [
              105.81931511182302,
              21.011902883491043
            ],
            [
              105.81919993998619,
              21.012002456151453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10627",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011614435315717,
          "to_latitude": 21.012002456151453,
          "to_longitude": 105.81919993998619,
          "from_longitude": 105.81902612926021
        }
      },
      {
        "id": 10607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8187806546095,
            21.01136070546398,
            105.81896887318705,
            21.01153765476046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81883519452172,
              21.01136070546398
            ],
            [
              105.8187806546095,
              21.011419490746597
            ],
            [
              105.81881498533761,
              21.01147167301097
            ],
            [
              105.81887740760419,
              21.0115135976065
            ],
            [
              105.81891089210501,
              21.01153568205332
            ],
            [
              105.81891388288003,
              21.01153765476046
            ],
            [
              105.81896887318705,
              21.011464814852335
            ],
            [
              105.81894980859681,
              21.011452398712915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10628",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01136070546398,
          "to_latitude": 21.011452398712915,
          "to_longitude": 105.81894980859681,
          "from_longitude": 105.81883519452172
        }
      },
      {
        "id": 10608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81890328829651,
            21.01153568205332,
            105.81891089210501,
            21.01154398589092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81890328829651,
              21.01154398589092
            ],
            [
              105.81890881520954,
              21.011537950117972
            ],
            [
              105.81891089210501,
              21.01153568205332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10629",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01154398589092,
          "to_latitude": 21.01153568205332,
          "to_longitude": 105.81891089210501,
          "from_longitude": 105.81890328829651
        }
      },
      {
        "id": 10609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81860993153612,
            21.01148649762338,
            105.81900999910228,
            21.01170403134792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81860993153612,
              21.01170403134792
            ],
            [
              105.81871092508794,
              21.011585623646496
            ],
            [
              105.8187530236162,
              21.011539689761154
            ],
            [
              105.8187793367493,
              21.01151501341744
            ],
            [
              105.81880214528056,
              21.011493622715253
            ],
            [
              105.8188097430207,
              21.01148649762338
            ],
            [
              105.8189104065533,
              21.011558983147914
            ],
            [
              105.8189503965722,
              21.01158777887161
            ],
            [
              105.81900999910228,
              21.01162926808262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10630",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01170403134792,
          "to_latitude": 21.01162926808262,
          "to_longitude": 105.81900999910228,
          "from_longitude": 105.81860993153612
        }
      },
      {
        "id": 10610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8188026953984,
            21.011558983147914,
            105.8189104065533,
            21.011710919487378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8189104065533,
              21.011558983147914
            ],
            [
              105.81886632288108,
              21.011621167561092
            ],
            [
              105.8188026953984,
              21.011710919487378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10631",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011558983147914,
          "to_latitude": 21.011710919487378,
          "to_longitude": 105.8188026953984,
          "from_longitude": 105.8189104065533
        }
      },
      {
        "id": 10611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81859112102786,
            21.01146553074864,
            105.81880266241697,
            21.01170168945137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81859112102786,
              21.01170168945137
            ],
            [
              105.8187037400685,
              21.011570903992812
            ],
            [
              105.81871246393129,
              21.011561611159664
            ],
            [
              105.81880266241697,
              21.01146553074864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10632",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01170168945137,
          "to_latitude": 21.01146553074864,
          "to_longitude": 105.81880266241697,
          "from_longitude": 105.81859112102786
        }
      },
      {
        "id": 10612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81871246393129,
            21.011561611159664,
            105.81871907219185,
            21.011566178599068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81871907219185,
              21.011566178599068
            ],
            [
              105.81871635512722,
              21.011564302120075
            ],
            [
              105.81871333099933,
              21.011562210343822
            ],
            [
              105.81871246393129,
              21.011561611159664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10633",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011566178599068,
          "to_latitude": 21.011561611159664,
          "to_longitude": 105.81871246393129,
          "from_longitude": 105.81871907219185
        }
      },
      {
        "id": 10613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81858373674223,
            21.011720261015,
            105.8188146513295,
            21.012033877596227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81858373674223,
              21.011720261015
            ],
            [
              105.81858444522312,
              21.011720707414014
            ],
            [
              105.81870555042258,
              21.011796710334213
            ],
            [
              105.8188146513295,
              21.01186025309952
            ],
            [
              105.8186609660768,
              21.012033877596227
            ],
            [
              105.8186404514143,
              21.01201592077667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10634",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011720261015,
          "to_latitude": 21.01201592077667,
          "to_longitude": 105.8186404514143,
          "from_longitude": 105.81858373674223
        }
      },
      {
        "id": 10614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81835007378453,
            21.011751096372873,
            105.81858582852561,
            21.01197387728846
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.8184642173241,
                21.01186403097203
              ],
              [
                105.8184757176653,
                21.011853393437512
              ],
              [
                105.81850908519631,
                21.011819125244838
              ],
              [
                105.81856588640363,
                21.011751096372873
              ]
            ],
            [
              [
                105.81835007378453,
                21.01196934988029
              ],
              [
                105.81842385604172,
                21.01190136222149
              ],
              [
                105.81843588374916,
                21.011890238022932
              ],
              [
                105.81844767409353,
                21.011879332643154
              ],
              [
                105.8184642173241,
                21.01186403097203
              ]
            ],
            [
              [
                105.8184642173241,
                21.01186403097203
              ],
              [
                105.81858582852561,
                21.01197387728846
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10635",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8184757176653,
            21.011853393437512
          ],
          "to_latitude": [
            105.81858582852561,
            21.01197387728846
          ],
          "to_longitude": [
            105.8184642173241,
            21.01186403097203
          ],
          "from_longitude": [
            105.8184642173241,
            21.01186403097203
          ]
        }
      },
      {
        "id": 10615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81843043216857,
            21.011884697652395,
            105.81843588374916,
            21.011890238022932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81843588374916,
              21.011890238022932
            ],
            [
              105.81843322760172,
              21.01188753840609
            ],
            [
              105.81843061692435,
              21.011884886441557
            ],
            [
              105.81843043216857,
              21.011884697652395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10636",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011890238022932,
          "to_latitude": 21.011884697652395,
          "to_longitude": 105.81843043216857,
          "from_longitude": 105.81843588374916
        }
      },
      {
        "id": 10616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81833595154181,
            21.01171286476432,
            105.81857936007603,
            21.01195588293301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81833595154181,
              21.01195588293301
            ],
            [
              105.81844944160333,
              21.011858789259232
            ],
            [
              105.81847871977237,
              21.011833993020332
            ],
            [
              105.81848596557252,
              21.01182512098635
            ],
            [
              105.81851475764309,
              21.011789864089938
            ],
            [
              105.81857936007603,
              21.01171286476432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10637",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01195588293301,
          "to_latitude": 21.01171286476432,
          "to_longitude": 105.81857936007603,
          "from_longitude": 105.81833595154181
        }
      },
      {
        "id": 10617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81848596557252,
            21.01182512098635,
            105.81849006838038,
            21.011828162289437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81849006838038,
              21.011828162289437
            ],
            [
              105.81848897025212,
              21.011827350150668
            ],
            [
              105.81848705888147,
              21.011825931341857
            ],
            [
              105.81848596557252,
              21.01182512098635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.10638",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011828162289437,
          "to_latitude": 21.01182512098635,
          "to_longitude": 105.81848596557252,
          "from_longitude": 105.81849006838038
        }
      },
      {
        "id": 10618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81813535645207,
            21.0119423634483,
            105.81852783898631,
            21.012213748239542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81852783898631,
              21.012213748239542
            ],
            [
              105.81832095553922,
              21.011997550497384
            ],
            [
              105.8183091141886,
              21.011985176322643
            ],
            [
              105.8182699042357,
              21.011944201187774
            ],
            [
              105.818268145449,
              21.0119423634483
            ],
            [
              105.81826594532633,
              21.01194408654847
            ],
            [
              105.81813535645207,
              21.012046369169738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10639",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012213748239542,
          "to_latitude": 21.012046369169738,
          "to_longitude": 105.81813535645207,
          "from_longitude": 105.81852783898631
        }
      },
      {
        "id": 10619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8183091141886,
            21.011976861794082,
            105.81831857064446,
            21.011985176322643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8183091141886,
              21.011985176322643
            ],
            [
              105.81831132465204,
              21.011983232784626
            ],
            [
              105.81831610553019,
              21.01197902875091
            ],
            [
              105.81831857064446,
              21.011976861794082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10640",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011985176322643,
          "to_latitude": 21.011976861794082,
          "to_longitude": 105.81831857064446,
          "from_longitude": 105.8183091141886
        }
      },
      {
        "id": 10620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81834503142551,
            21.011989983078305,
            105.8186980140614,
            21.012334546404816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81834503142551,
              21.011989983078305
            ],
            [
              105.81848573088655,
              21.012127326748185
            ],
            [
              105.8185007165615,
              21.012141954692197
            ],
            [
              105.81862595674002,
              21.012264207408695
            ],
            [
              105.8186287167526,
              21.012266901103047
            ],
            [
              105.8186980140614,
              21.012334546404816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10641",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011989983078305,
          "to_latitude": 21.012334546404816,
          "to_longitude": 105.8186980140614,
          "from_longitude": 105.81834503142551
        }
      },
      {
        "id": 10621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81847340822785,
            21.012127326748185,
            105.81848573088655,
            21.01213605068678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81847340822785,
              21.01213605068678
            ],
            [
              105.8184791278228,
              21.012132002004392
            ],
            [
              105.8184855143318,
              21.01212748043536
            ],
            [
              105.81848573088655,
              21.012127326748185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10642",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01213605068678,
          "to_latitude": 21.012127326748185,
          "to_longitude": 105.81848573088655,
          "from_longitude": 105.81847340822785
        }
      },
      {
        "id": 10622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8186115565336,
            21.011958650559283,
            105.81888093821092,
            21.012278079814735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8186115565336,
              21.012278079814735
            ],
            [
              105.81861311288863,
              21.012276488065677
            ],
            [
              105.81861858069335,
              21.012270895167518
            ],
            [
              105.8186287167526,
              21.012266901103047
            ],
            [
              105.81863041281422,
              21.012266232683576
            ],
            [
              105.81863334977811,
              21.012257813116797
            ],
            [
              105.818753177717,
              21.012145697880847
            ],
            [
              105.81888093821092,
              21.011958650559283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A56_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10643",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012278079814735,
          "to_latitude": 21.011958650559283,
          "to_longitude": 105.81888093821092,
          "from_longitude": 105.8186115565336
        }
      },
      {
        "id": 10623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81869916342592,
            21.01197048188188,
            105.8191241462929,
            21.01236990958003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.818700110584,
              21.012368657547107
            ],
            [
              105.81869946096792,
              21.012369303770868
            ],
            [
              105.81869916342592,
              21.012369599654736
            ],
            [
              105.8186995142937,
              21.01236990958003
            ],
            [
              105.8187003198014,
              21.012369098218734
            ],
            [
              105.81870043272808,
              21.012368991997747
            ],
            [
              105.81870884672766,
              21.01236107807738
            ],
            [
              105.8191241462929,
              21.01197048188188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A54_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10644",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012368657547107,
          "to_latitude": 21.01197048188188,
          "to_longitude": 105.8191241462929,
          "from_longitude": 105.818700110584
        }
      },
      {
        "id": 10624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81853787154898,
            21.012223223839154,
            105.81869707525767,
            21.012510416582238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81854345643771,
              21.012223223839154
            ],
            [
              105.81867345303219,
              21.012364268769666
            ],
            [
              105.81868497675124,
              21.012376771800216
            ],
            [
              105.81869707525767,
              21.01238989890422
            ],
            [
              105.81853787154898,
              21.012510416582238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A55_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10645",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012223223839154,
          "to_latitude": 21.012510416582238,
          "to_longitude": 105.81853787154898,
          "from_longitude": 105.81854345643771
        }
      },
      {
        "id": 10625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81868497675124,
            21.01236584312555,
            105.81869728738471,
            21.012376771800216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81868497675124,
              21.012376771800216
            ],
            [
              105.81868729528811,
              21.012374713930694
            ],
            [
              105.81869702892057,
              21.012366072885776
            ],
            [
              105.81869728738471,
              21.01236584312555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A55_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10646",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012376771800216,
          "to_latitude": 21.01236584312555,
          "to_longitude": 105.81869728738471,
          "from_longitude": 105.81868497675124
        }
      },
      {
        "id": 10626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81856627711211,
            21.012464921755665,
            105.81898856042999,
            21.012746484963742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81898856042999,
              21.012746484963742
            ],
            [
              105.81872788901792,
              21.012464921755665
            ],
            [
              105.81869241086223,
              21.012475554551212
            ],
            [
              105.81856627711211,
              21.012582534526587
            ],
            [
              105.81858669130435,
              21.012605864233194
            ],
            [
              105.81857310838086,
              21.01261703476363
            ],
            [
              105.81857284119984,
              21.012617253726866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Thái Hà",
          "maTaiSan": "04.O11.DODV.10647",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012746484963742,
          "to_latitude": 21.012617253726866,
          "to_longitude": 105.81857284119984,
          "from_longitude": 105.81898856042999
        }
      },
      {
        "id": 10627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81887851788434,
            21.01225163633223,
            105.81920678356927,
            21.012551627515858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81887851788434,
              21.012551627515858
            ],
            [
              105.81887926368987,
              21.01255098082849
            ],
            [
              105.81904512277164,
              21.012407224175778
            ],
            [
              105.81920678356927,
              21.01225163633223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A88_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10648",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012551627515858,
          "to_latitude": 21.01225163633223,
          "to_longitude": 105.81920678356927,
          "from_longitude": 105.81887851788434
        }
      },
      {
        "id": 10628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81904512277164,
            21.012407224175778,
            105.81935213879643,
            21.012699664384698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81904512277164,
              21.012407224175778
            ],
            [
              105.81904986468118,
              21.012411740971498
            ],
            [
              105.81935213879643,
              21.012699664384698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A88_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10649",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012407224175778,
          "to_latitude": 21.012699664384698,
          "to_longitude": 105.81935213879643,
          "from_longitude": 105.81904512277164
        }
      },
      {
        "id": 10629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81886293097476,
            21.01220011276455,
            105.81923264232634,
            21.01253612637077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81886293097476,
              21.01253612637077
            ],
            [
              105.81886378764518,
              21.01253534818191
            ],
            [
              105.81923264232634,
              21.01220011276455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10650",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01253612637077,
          "to_latitude": 21.01220011276455,
          "to_longitude": 105.81923264232634,
          "from_longitude": 105.81886293097476
        }
      },
      {
        "id": 10630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81891162876829,
            21.012562142503203,
            105.81920426362475,
            21.01285114134226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81891162876829,
              21.012562142503203
            ],
            [
              105.81905472834701,
              21.012702662505454
            ],
            [
              105.81907110628161,
              21.012718745115198
            ],
            [
              105.81910051796005,
              21.012747988229567
            ],
            [
              105.81920426362475,
              21.01285114134226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A52_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10651",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012562142503203,
          "to_latitude": 21.01285114134226,
          "to_longitude": 105.81920426362475,
          "from_longitude": 105.81891162876829
        }
      },
      {
        "id": 10631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81903933192032,
            21.012702662505454,
            105.81905472834701,
            21.01271536193397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81905472834701,
              21.012702662505454
            ],
            [
              105.81904653696701,
              21.01270941923978
            ],
            [
              105.81904298998465,
              21.012712344706564
            ],
            [
              105.81903986721844,
              21.01271491948541
            ],
            [
              105.81903933192032,
              21.01271536193397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A52_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10652",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012702662505454,
          "to_latitude": 21.01271536193397,
          "to_longitude": 105.81903933192032,
          "from_longitude": 105.81905472834701
        }
      },
      {
        "id": 10632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81916521718128,
            21.012565438985877,
            105.81954088753389,
            21.01293377106607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81921269578068,
              21.012896755280327
            ],
            [
              105.81917382687399,
              21.01293224235579
            ],
            [
              105.81917204508457,
              21.01293377106607
            ],
            [
              105.81916521718128,
              21.012927924831164
            ],
            [
              105.8192050080325,
              21.012888639763336
            ],
            [
              105.81940399163224,
              21.01269218434286
            ],
            [
              105.81954088753389,
              21.012565438985877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10653",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012896755280327,
          "to_latitude": 21.012565438985877,
          "to_longitude": 105.81954088753389,
          "from_longitude": 105.81921269578068
        }
      },
      {
        "id": 10633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81908545667591,
            21.012398710388506,
            105.81940399163224,
            21.01269218434286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81940399163224,
              21.01269218434286
            ],
            [
              105.81908545667591,
              21.012398710388506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10654",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01269218434286,
          "to_latitude": 21.012398710388506,
          "to_longitude": 105.81908545667591,
          "from_longitude": 105.81940399163224
        }
      },
      {
        "id": 10634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81917492126243,
            21.012585794795505,
            105.81955649911198,
            21.012944241114592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81921535349427,
              21.012899559650634
            ],
            [
              105.81917601723373,
              21.012935248590054
            ],
            [
              105.81917492126243,
              21.01293624290072
            ],
            [
              105.8191838983087,
              21.012944241114592
            ],
            [
              105.8192225004163,
              21.012907105124864
            ],
            [
              105.81955649911198,
              21.012585794795505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10655",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012899559650634,
          "to_latitude": 21.012585794795505,
          "to_longitude": 105.81955649911198,
          "from_longitude": 105.81921535349427
        }
      },
      {
        "id": 10635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81943280105754,
            21.01237500101177,
            105.81996988987869,
            21.012893768168897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81996988987869,
              21.012893768168897
            ],
            [
              105.819964548031,
              21.012888610290354
            ],
            [
              105.81943280105754,
              21.01237500101177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Ngõ 121, Thái Hà",
          "maTaiSan": "04.O11.DODV.10656",
          "diaChiLapD": "Ngõ 121, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012893768168897,
          "to_latitude": 21.01237500101177,
          "to_longitude": 105.81943280105754,
          "from_longitude": 105.81996988987869
        }
      },
      {
        "id": 10636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81940117666053,
            21.01240029464713,
            105.81994291307868,
            21.01291458930512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994291307868,
              21.01291458930512
            ],
            [
              105.81994209022469,
              21.01291381017236
            ],
            [
              105.81940117666053,
              21.01240029464713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Ngõ 121, Thái Hà",
          "maTaiSan": "04.O11.DODV.10657",
          "diaChiLapD": "Ngõ 121, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01291458930512,
          "to_latitude": 21.01240029464713,
          "to_longitude": 105.81940117666053,
          "from_longitude": 105.81994291307868
        }
      },
      {
        "id": 10637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81998242180238,
            21.01262698325551,
            105.82026531289559,
            21.012856812743912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81998242180238,
              21.012856812743912
            ],
            [
              105.8201329662575,
              21.012734506444307
            ],
            [
              105.82019373117663,
              21.012685138489115
            ],
            [
              105.82026531289559,
              21.01262698325551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Thái Hà",
          "maTaiSan": "04.O11.DODV.10658",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012856812743912,
          "to_latitude": 21.01262698325551,
          "to_longitude": 105.82026531289559,
          "from_longitude": 105.81998242180238
        }
      },
      {
        "id": 10638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82019373117663,
            21.012685138489115,
            105.82021310866187,
            21.012706001760222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82019373117663,
              21.012685138489115
            ],
            [
              105.82021025614105,
              21.012702932796113
            ],
            [
              105.82021310866187,
              21.012706001760222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Thái Hà",
          "maTaiSan": "04.O11.DODV.10659",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012685138489115,
          "to_latitude": 21.012706001760222,
          "to_longitude": 105.82021310866187,
          "from_longitude": 105.82019373117663
        }
      },
      {
        "id": 10639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81951417002969,
            21.012904717940888,
            105.81989848951959,
            21.013203361850465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81951417002969,
              21.013203361850465
            ],
            [
              105.81955932136754,
              21.013169658118027
            ],
            [
              105.81971137479486,
              21.01305615679537
            ],
            [
              105.81979806793984,
              21.012992409325488
            ],
            [
              105.81989848951959,
              21.012904717940888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Thái Hà",
          "maTaiSan": "04.O11.DODV.10660",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013203361850465,
          "to_latitude": 21.012904717940888,
          "to_longitude": 105.81989848951959,
          "from_longitude": 105.81951417002969
        }
      },
      {
        "id": 10640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81955932136754,
            21.013169658118027,
            105.819574760602,
            21.01318715513498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81955932136754,
              21.013169658118027
            ],
            [
              105.81956309044763,
              21.013173932099576
            ],
            [
              105.819574760602,
              21.01318715513498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Thái Hà",
          "maTaiSan": "04.O11.DODV.10661",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013169658118027,
          "to_latitude": 21.01318715513498,
          "to_longitude": 105.819574760602,
          "from_longitude": 105.81955932136754
        }
      },
      {
        "id": 10641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81926134434059,
            21.012932570658442,
            105.81951043825549,
            21.013232678167235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81947117057672,
              21.013232678167235
            ],
            [
              105.8194717663121,
              21.013232205619754
            ],
            [
              105.81951043825549,
              21.01320151466017
            ],
            [
              105.81926134434059,
              21.012932570658442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10662",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013232678167235,
          "to_latitude": 21.012932570658442,
          "to_longitude": 105.81926134434059,
          "from_longitude": 105.81947117057672
        }
      },
      {
        "id": 10642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81901376640378,
            21.012770762521022,
            105.81941963059705,
            21.013218044817265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81901376640378,
              21.012770762521022
            ],
            [
              105.81926795636068,
              21.013050892328415
            ],
            [
              105.81929437780097,
              21.013080009803243
            ],
            [
              105.81941963059705,
              21.013218044817265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10663",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012770762521022,
          "to_latitude": 21.013218044817265,
          "to_longitude": 105.81941963059705,
          "from_longitude": 105.81901376640378
        }
      },
      {
        "id": 10643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81929437780097,
            21.01303937474199,
            105.8193477859572,
            21.013080009803243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81929437780097,
              21.013080009803243
            ],
            [
              105.81930593824795,
              21.01307121360447
            ],
            [
              105.81934640939345,
              21.013040421888917
            ],
            [
              105.8193477859572,
              21.01303937474199
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DODV.10664",
          "diaChiLapD": "Ngõ 131, Thái Hà]",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013080009803243,
          "to_latitude": 21.01303937474199,
          "to_longitude": 105.8193477859572,
          "from_longitude": 105.81929437780097
        }
      },
      {
        "id": 10644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81895176266858,
            21.013017233822428,
            105.81926249976334,
            21.013372941765088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81926249976334,
              21.013372941765088
            ],
            [
              105.81911002623981,
              21.01319840187613
            ],
            [
              105.81908809582154,
              21.013173297469827
            ],
            [
              105.81895176266858,
              21.013017233822428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10665",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013372941765088,
          "to_latitude": 21.013017233822428,
          "to_longitude": 105.81895176266858,
          "from_longitude": 105.81926249976334
        }
      },
      {
        "id": 10645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81907226885549,
            21.013173297469827,
            105.81908809582154,
            21.01318537394113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81907226885549,
              21.01318537394113
            ],
            [
              105.81907346243966,
              21.013184463161334
            ],
            [
              105.81907778175831,
              21.013181167265405
            ],
            [
              105.81908809582154,
              21.013173297469827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10666",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01318537394113,
          "to_latitude": 21.013173297469827,
          "to_longitude": 105.81908809582154,
          "from_longitude": 105.81907226885549
        }
      },
      {
        "id": 10646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81859315441416,
            21.012609913018213,
            105.81893654535995,
            21.01299015424042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81893654535995,
              21.01299015424042
            ],
            [
              105.81882079569098,
              21.01286051829414
            ],
            [
              105.81878915014325,
              21.012825929741346
            ],
            [
              105.81875072979712,
              21.01278393841321
            ],
            [
              105.81863131747316,
              21.012653423948727
            ],
            [
              105.81859315441416,
              21.012609913018213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10667",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01299015424042,
          "to_latitude": 21.012609913018213,
          "to_longitude": 105.81859315441416,
          "from_longitude": 105.81893654535995
        }
      },
      {
        "id": 10647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81873211028665,
            21.01278393841321,
            105.81875072979712,
            21.01279705435723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81873211028665,
              21.01279705435723
            ],
            [
              105.81873263348558,
              21.012796686032484
            ],
            [
              105.81873683056506,
              21.01279372944371
            ],
            [
              105.81875072979712,
              21.01278393841321
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10668",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01279705435723,
          "to_latitude": 21.01278393841321,
          "to_longitude": 105.81875072979712,
          "from_longitude": 105.81873211028665
        }
      },
      {
        "id": 10648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81867526361316,
            21.01273868713775,
            105.81868118796733,
            21.0127431152647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81867526361316,
              21.0127431152647
            ],
            [
              105.81867534600107,
              21.01274305344881
            ],
            [
              105.81867997415965,
              21.012739593495976
            ],
            [
              105.81868118796733,
              21.01273868713775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10669",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0127431152647,
          "to_latitude": 21.01273868713775,
          "to_longitude": 105.81868118796733,
          "from_longitude": 105.81867526361316
        }
      },
      {
        "id": 10649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81891861079797,
            21.013010331050484,
            105.81924513360306,
            21.013387992765864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81891861079797,
              21.013010331050484
            ],
            [
              105.81898521380796,
              21.013100097483438
            ],
            [
              105.81899066119661,
              21.01310591514462
            ],
            [
              105.81905776665026,
              21.013177577545438
            ],
            [
              105.8191005678786,
              21.013223285262267
            ],
            [
              105.81924513360306,
              21.013387992765864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A60_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10670",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013010331050484,
          "to_latitude": 21.013387992765864,
          "to_longitude": 105.81924513360306,
          "from_longitude": 105.81891861079797
        }
      },
      {
        "id": 10650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81899066119661,
            21.013101244711624,
            105.81899644571524,
            21.01310591514462
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81899644571524,
              21.013101244711624
            ],
            [
              105.81899270301837,
              21.013104266861703
            ],
            [
              105.81899066119661,
              21.01310591514462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A60_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DODV.10671",
          "diaChiLapD": "Ngõ 133, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013101244711624,
          "to_latitude": 21.01310591514462,
          "to_longitude": 105.81899066119661,
          "from_longitude": 105.81899644571524
        }
      },
      {
        "id": 10651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81898191959773,
            21.01340691177539,
            105.81924738297805,
            21.01360761066268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81898191959773,
              21.01360761066268
            ],
            [
              105.8190808206226,
              21.01353283869748
            ],
            [
              105.81909465181587,
              21.013522382287345
            ],
            [
              105.81924738297805,
              21.01340691177539
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Thái Hà",
          "maTaiSan": "04.O11.DODV.10672",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01360761066268,
          "to_latitude": 21.01340691177539,
          "to_longitude": 105.81924738297805,
          "from_longitude": 105.81898191959773
        }
      },
      {
        "id": 10652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81909465181587,
            21.013522382287345,
            105.81910296565326,
            21.013532936217622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909465181587,
              21.013522382287345
            ],
            [
              105.8191015669778,
              21.013531168109214
            ],
            [
              105.81910296565326,
              21.013532936217622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Thái Hà",
          "maTaiSan": "04.O11.DODV.10673",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013522382287345,
          "to_latitude": 21.013532936217622,
          "to_longitude": 105.81910296565326,
          "from_longitude": 105.81909465181587
        }
      },
      {
        "id": 10653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81888793110252,
            21.00242581177802,
            105.81947732820225,
            21.00310422316091
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8194719758208,
              21.00310422316091
            ],
            [
              105.81947507951307,
              21.00307229444746
            ],
            [
              105.81947732820225,
              21.003048838520243
            ],
            [
              105.819448122716,
              21.002885768451463
            ],
            [
              105.8194309828065,
              21.00285183718824
            ],
            [
              105.8193544003765,
              21.00278975934102
            ],
            [
              105.81924701466079,
              21.00269510032835
            ],
            [
              105.81888793110252,
              21.00242581177802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Đường Láng",
          "maTaiSan": "04.O11.DODV.10674",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00310422316091,
          "to_latitude": 21.00242581177802,
          "to_longitude": 105.81888793110252,
          "from_longitude": 105.8194719758208
        }
      },
      {
        "id": 10654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8186602163926,
            21.002914206411603,
            105.8188787927253,
            21.003421433096218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81882434853546,
              21.003421433096218
            ],
            [
              105.81882050608661,
              21.003415825370485
            ],
            [
              105.8187087946937,
              21.00325273837051
            ],
            [
              105.81869868213894,
              21.00323677276885
            ],
            [
              105.8186625430112,
              21.003167093602567
            ],
            [
              105.81867636020539,
              21.0031164978291
            ],
            [
              105.8186602163926,
              21.00307135894978
            ],
            [
              105.81869948511562,
              21.003045236577037
            ],
            [
              105.81875827941012,
              21.00302508274455
            ],
            [
              105.81882081629007,
              21.003021327970643
            ],
            [
              105.8188787927253,
              21.002994252074934
            ],
            [
              105.81883086235639,
              21.002914206411603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47_Đường Láng",
          "maTaiSan": "04.O11.DODV.10675",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003421433096218,
          "to_latitude": 21.002914206411603,
          "to_longitude": 105.81883086235639,
          "from_longitude": 105.81882434853546
        }
      },
      {
        "id": 10655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81878926230011,
            21.003233721097363,
            105.81898906352866,
            21.003350123084694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81898906352866,
              21.003233721097363
            ],
            [
              105.81878926230011,
              21.003350123084694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Đường Láng",
          "maTaiSan": "04.O11.DODV.10676",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003233721097363,
          "to_latitude": 21.003350123084694,
          "to_longitude": 105.81878926230011,
          "from_longitude": 105.81898906352866
        }
      },
      {
        "id": 10656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81844418626441,
            21.002356173214693,
            105.81884987579073,
            21.003076833747453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81844418626441,
              21.003040761357326
            ],
            [
              105.81845162720236,
              21.003051520966864
            ],
            [
              105.81846913832963,
              21.003076833747453
            ],
            [
              105.81875149040403,
              21.00289826417178
            ],
            [
              105.81884987579073,
              21.002574352863377
            ],
            [
              105.81857031236487,
              21.002356173214693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A55_Đường Láng",
          "maTaiSan": "04.O11.DODV.10677",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003040761357326,
          "to_latitude": 21.002356173214693,
          "to_longitude": 105.81857031236487,
          "from_longitude": 105.81844418626441
        }
      },
      {
        "id": 10657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81830312958358,
            21.003268333297918,
            105.8185690372814,
            21.003472824635082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8185690372814,
              21.003472824635082
            ],
            [
              105.81842620007625,
              21.003304227500053
            ],
            [
              105.81834727990442,
              21.003281735400392
            ],
            [
              105.81830825034976,
              21.00326988842614
            ],
            [
              105.81830312958358,
              21.003268333297918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Đường Láng",
          "maTaiSan": "04.O11.DODV.10678",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003472824635082,
          "to_latitude": 21.003268333297918,
          "to_longitude": 105.81830312958358,
          "from_longitude": 105.8185690372814
        }
      },
      {
        "id": 10658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81834727990442,
            21.00307938800596,
            105.81846126405198,
            21.003281735400392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81834727990442,
              21.003281735400392
            ],
            [
              105.81837289033493,
              21.00318381699448
            ],
            [
              105.81840015894385,
              21.00312276040458
            ],
            [
              105.81846126405198,
              21.00307938800596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A53_Đường Láng",
          "maTaiSan": "04.O11.DODV.10679",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003281735400392,
          "to_latitude": 21.00307938800596,
          "to_longitude": 105.81846126405198,
          "from_longitude": 105.81834727990442
        }
      },
      {
        "id": 10659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81807211783348,
            21.003290873045536,
            105.81816603634195,
            21.00364371912405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81807211783348,
              21.00364371912405
            ],
            [
              105.81807257407807,
              21.003642013441997
            ],
            [
              105.81807431048004,
              21.00363552804379
            ],
            [
              105.81811547476076,
              21.003481728032128
            ],
            [
              105.81816603634195,
              21.003290873045536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Đường Láng",
          "maTaiSan": "04.O11.DODV.10680",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00364371912405,
          "to_latitude": 21.003290873045536,
          "to_longitude": 105.81816603634195,
          "from_longitude": 105.81807211783348
        }
      },
      {
        "id": 10660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81795052217579,
            21.003293005717612,
            105.81811547476076,
            21.003481728032128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81811547476076,
              21.003481728032128
            ],
            [
              105.81802011718295,
              21.00345868794785
            ],
            [
              105.81803556224847,
              21.00339435622536
            ],
            [
              105.81795052217579,
              21.00337705891882
            ],
            [
              105.8179656722239,
              21.003293005717612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Đường Láng",
          "maTaiSan": "04.O11.DODV.10681",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003481728032128,
          "to_latitude": 21.003293005717612,
          "to_longitude": 105.8179656722239,
          "from_longitude": 105.81811547476076
        }
      },
      {
        "id": 10661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81785944053381,
            21.003590620624887,
            105.81831680716134,
            21.00368449063826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81831680716134,
              21.00368449063826
            ],
            [
              105.81825112665283,
              21.003665315631295
            ],
            [
              105.81810741547817,
              21.00363810300046
            ],
            [
              105.81808720030378,
              21.003634274732136
            ],
            [
              105.81808343746009,
              21.003633562105843
            ],
            [
              105.81807475529881,
              21.003635431970075
            ],
            [
              105.81807431048004,
              21.00363552804379
            ],
            [
              105.81807210366972,
              21.003636002909914
            ],
            [
              105.81806740058502,
              21.00363043362585
            ],
            [
              105.81799999503517,
              21.003621231229697
            ],
            [
              105.81785944053381,
              21.003590620624887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A56_Đường Láng",
          "maTaiSan": "04.O11.DODV.10682",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00368449063826,
          "to_latitude": 21.003590620624887,
          "to_longitude": 105.81785944053381,
          "from_longitude": 105.81831680716134
        }
      },
      {
        "id": 10662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81808388716715,
            21.003634274732136,
            105.81808720030378,
            21.003646186177395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81808720030378,
              21.003634274732136
            ],
            [
              105.81808528336374,
              21.003641166547855
            ],
            [
              105.81808431354362,
              21.003644647736174
            ],
            [
              105.81808388716715,
              21.003646186177395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A56_Đường Láng",
          "maTaiSan": "04.O11.DODV.10683",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003634274732136,
          "to_latitude": 21.003646186177395,
          "to_longitude": 105.81808388716715,
          "from_longitude": 105.81808720030378
        }
      },
      {
        "id": 10663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763555231994,
            21.003534065763063,
            105.81785042987389,
            21.003597693750052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81785042987389,
              21.003597693750052
            ],
            [
              105.81783557940955,
              21.003591819151453
            ],
            [
              105.81780756787042,
              21.00357801692343
            ],
            [
              105.8177962115294,
              21.0035683753274
            ],
            [
              105.81778941241426,
              21.003562602943997
            ],
            [
              105.81778138605796,
              21.003555788711427
            ],
            [
              105.81771263790823,
              21.003545547614962
            ],
            [
              105.81763555231994,
              21.003534065763063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Đường Láng",
          "maTaiSan": "04.O11.DODV.10684",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003597693750052,
          "to_latitude": 21.003534065763063,
          "to_longitude": 105.81763555231994,
          "from_longitude": 105.81785042987389
        }
      },
      {
        "id": 10664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81777611355476,
            21.003591819151453,
            105.81783557940955,
            21.00376766054653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81783557940955,
              21.003591819151453
            ],
            [
              105.81777611355476,
              21.003750583566394
            ],
            [
              105.81782262229093,
              21.00376766054653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Đường Láng",
          "maTaiSan": "04.O11.DODV.10685",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003591819151453,
          "to_latitude": 21.00376766054653,
          "to_longitude": 105.81782262229093,
          "from_longitude": 105.81783557940955
        }
      },
      {
        "id": 10665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8177962115294,
            21.003355183721578,
            105.81782286982062,
            21.0035683753274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8177962115294,
              21.0035683753274
            ],
            [
              105.81779685303465,
              21.00356324551916
            ],
            [
              105.81779734151873,
              21.003559335804848
            ],
            [
              105.81782286982062,
              21.003355183721578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Đường Láng",
          "maTaiSan": "04.O11.DODV.10686",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0035683753274,
          "to_latitude": 21.003355183721578,
          "to_longitude": 105.81782286982062,
          "from_longitude": 105.8177962115294
        }
      },
      {
        "id": 10666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81764093601362,
            21.003545547614962,
            105.81771263790823,
            21.003798048077503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81771263790823,
              21.003545547614962
            ],
            [
              105.81764093601362,
              21.003744027026528
            ],
            [
              105.8176725606747,
              21.00375400842793
            ],
            [
              105.81765939414262,
              21.003798048077503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Đường Láng",
          "maTaiSan": "04.O11.DODV.10687",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003545547614962,
          "to_latitude": 21.003798048077503,
          "to_longitude": 105.81765939414262,
          "from_longitude": 105.81771263790823
        }
      },
      {
        "id": 10667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81809327074257,
            21.00393799230534,
            105.81815845729705,
            21.003979815491274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81815845729705,
              21.003979815491274
            ],
            [
              105.81814699603464,
              21.003972463206146
            ],
            [
              105.81813834650839,
              21.003966912894356
            ],
            [
              105.81813240641789,
              21.003963101822105
            ],
            [
              105.81809327074257,
              21.00393799230534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49_Đường Láng",
          "maTaiSan": "04.O11.DODV.10688",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003979815491274,
          "to_latitude": 21.00393799230534,
          "to_longitude": 105.81809327074257,
          "from_longitude": 105.81815845729705
        }
      },
      {
        "id": 10668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81795067241448,
            21.003721093549057,
            105.81829172126845,
            21.00408819359095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81829172126845,
              21.003721093549057
            ],
            [
              105.81821109055518,
              21.003810613575343
            ],
            [
              105.81812090174317,
              21.003906493978928
            ],
            [
              105.81809327074257,
              21.00393799230534
            ],
            [
              105.81798638774397,
              21.004059833992734
            ],
            [
              105.81795067241448,
              21.00408819359095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A49_Đường Láng",
          "maTaiSan": "04.O11.DODV.10689",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003721093549057,
          "to_latitude": 21.00408819359095,
          "to_longitude": 105.81795067241448,
          "from_longitude": 105.81829172126845
        }
      },
      {
        "id": 10669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743026925773,
            21.003983497517893,
            105.81796040366106,
            21.004194626638476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81796040366106,
              21.004194626638476
            ],
            [
              105.81794784361927,
              21.00418456812851
            ],
            [
              105.81778935776713,
              21.004057624900355
            ],
            [
              105.8177722450631,
              21.00404594527794
            ],
            [
              105.8176173322045,
              21.00400204923432
            ],
            [
              105.81759916649273,
              21.003999061002364
            ],
            [
              105.81750457077628,
              21.003983497517893
            ],
            [
              105.81749236290119,
              21.004024664799733
            ],
            [
              105.81743026925773,
              21.004009927877437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Đường Láng",
          "maTaiSan": "04.O11.DODV.10690",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004194626638476,
          "to_latitude": 21.004009927877437,
          "to_longitude": 105.81743026925773,
          "from_longitude": 105.81796040366106
        }
      },
      {
        "id": 10670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81756473505105,
            21.00391067746625,
            105.81761345203512,
            21.003999061002364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81759916649273,
              21.003999061002364
            ],
            [
              105.81759973601157,
              21.003996076708784
            ],
            [
              105.81761345203512,
              21.003924182464793
            ],
            [
              105.81756473505105,
              21.00391067746625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Đường Láng",
          "maTaiSan": "04.O11.DODV.10691",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003999061002364,
          "to_latitude": 21.00391067746625,
          "to_longitude": 105.81756473505105,
          "from_longitude": 105.81759916649273
        }
      },
      {
        "id": 10671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81778935776713,
            21.003968870684112,
            105.81786855120325,
            21.004057624900355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81778935776713,
              21.004057624900355
            ],
            [
              105.81786855120325,
              21.003968870684112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Đường Láng",
          "maTaiSan": "04.O11.DODV.10692",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004057624900355,
          "to_latitude": 21.003968870684112,
          "to_longitude": 105.81786855120325,
          "from_longitude": 105.81778935776713
        }
      },
      {
        "id": 10672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81754419154763,
            21.00400204923432,
            105.8176173322045,
            21.004107240569333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176173322045,
              21.00400204923432
            ],
            [
              105.81758584337837,
              21.004107240569333
            ],
            [
              105.81754419154763,
              21.004099795712794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Đường Láng",
          "maTaiSan": "04.O11.DODV.10693",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00400204923432,
          "to_latitude": 21.004099795712794,
          "to_longitude": 105.81754419154763,
          "from_longitude": 105.8176173322045
        }
      },
      {
        "id": 10673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81755112149203,
            21.00418741343274,
            105.81787282009059,
            21.00443264231735
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81755112149203,
                21.004284264944943
              ],
              [
                105.81768635416233,
                21.00436935158698
              ]
            ],
            [
              [
                105.81768635416233,
                21.00436935158698
              ],
              [
                105.81776366913999,
                21.004298045978953
              ],
              [
                105.8177787207833,
                21.004284164198655
              ],
              [
                105.81778985039888,
                21.0042738988211
              ],
              [
                105.81787282009059,
                21.00418741343274
              ]
            ],
            [
              [
                105.81768635416233,
                21.00436935158698
              ],
              [
                105.81761741564011,
                21.00443264231735
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Đường Láng",
          "maTaiSan": "04.O11.DODV.10694",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81768635416233,
            21.00436935158698
          ],
          "to_latitude": [
            105.81761741564011,
            21.00443264231735
          ],
          "to_longitude": [
            105.81768635416233,
            21.00436935158698
          ],
          "from_longitude": [
            105.81755112149203,
            21.004284264944943
          ]
        }
      },
      {
        "id": 10674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8177787207833,
            21.004284164198655,
            105.81783601990215,
            21.00432912889482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8177787207833,
              21.004284164198655
            ],
            [
              105.81783136668764,
              21.004325476919725
            ],
            [
              105.81783453806786,
              21.0043279672373
            ],
            [
              105.81783601990215,
              21.00432912889482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Đường Láng",
          "maTaiSan": "04.O11.DODV.10695",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004284164198655,
          "to_latitude": 21.00432912889482,
          "to_longitude": 105.81783601990215,
          "from_longitude": 105.8177787207833
        }
      },
      {
        "id": 10675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81732258315846,
            21.00428961855803,
            105.81776382357266,
            21.004511715335358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8177398479363,
              21.004416044408245
            ],
            [
              105.8177487829814,
              21.00442421182764
            ],
            [
              105.81776382357266,
              21.00443795625158
            ],
            [
              105.81767629242222,
              21.004511715335358
            ],
            [
              105.81732258315846,
              21.00428961855803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A52_Đường Láng",
          "maTaiSan": "04.O11.DODV.10696",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004416044408245,
          "to_latitude": 21.00428961855803,
          "to_longitude": 105.81732258315846,
          "from_longitude": 105.8177398479363
        }
      },
      {
        "id": 10676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81832939801122,
            21.00337078940333,
            105.81875067649163,
            21.003680621189176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81832939801122,
              21.003680621189176
            ],
            [
              105.81841068181782,
              21.003617584243997
            ],
            [
              105.81854209911552,
              21.003526744154687
            ],
            [
              105.8185453638722,
              21.00352448754423
            ],
            [
              105.81856597006319,
              21.00351024298396
            ],
            [
              105.8185724418469,
              21.00349867221174
            ],
            [
              105.81857294099221,
              21.003497779227864
            ],
            [
              105.81858298165525,
              21.00349738771294
            ],
            [
              105.81860005631998,
              21.003471407322763
            ],
            [
              105.81875067649163,
              21.00337078940333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Đường Láng",
          "maTaiSan": "04.O11.DODV.10697",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003680621189176,
          "to_latitude": 21.00337078940333,
          "to_longitude": 105.81875067649163,
          "from_longitude": 105.81832939801122
        }
      },
      {
        "id": 10677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82116033405156,
            21.006694258496765,
            105.82153799959217,
            21.007154802544882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82116033405156,
              21.007154802544882
            ],
            [
              105.82128179912246,
              21.00700667868533
            ],
            [
              105.82130026908278,
              21.006984158794413
            ],
            [
              105.82130297507655,
              21.006980858889786
            ],
            [
              105.82133150075917,
              21.006946073408916
            ],
            [
              105.82138459629596,
              21.006881326697425
            ],
            [
              105.82145014105355,
              21.006801398945882
            ],
            [
              105.82144893141789,
              21.00678056370772
            ],
            [
              105.82146959510547,
              21.006777673897876
            ],
            [
              105.82153799959217,
              21.006694258496765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10698",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007154802544882,
          "to_latitude": 21.006694258496765,
          "to_longitude": 105.82153799959217,
          "from_longitude": 105.82116033405156
        }
      },
      {
        "id": 10678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82130297507655,
            21.006980858889786,
            105.82137518775173,
            21.00702659125445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82130297507655,
              21.006980858889786
            ],
            [
              105.82133195704714,
              21.006999212610694
            ],
            [
              105.82137069654294,
              21.00702374789217
            ],
            [
              105.82137518775173,
              21.00702659125445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10699",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006980858889786,
          "to_latitude": 21.00702659125445,
          "to_longitude": 105.82137518775173,
          "from_longitude": 105.82130297507655
        }
      },
      {
        "id": 10679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82087793202062,
            21.00648390149012,
            105.82145884542861,
            21.006944764453486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82144552339736,
              21.006938170815282
            ],
            [
              105.82145070353063,
              21.00694245962762
            ],
            [
              105.82145336709777,
              21.006944764453486
            ],
            [
              105.82145884542861,
              21.006939567031957
            ],
            [
              105.8214499579399,
              21.00693259546358
            ],
            [
              105.82138459629596,
              21.006881326697425
            ],
            [
              105.82087793202062,
              21.00648390149012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10700",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006938170815282,
          "to_latitude": 21.00648390149012,
          "to_longitude": 105.82087793202062,
          "from_longitude": 105.82144552339736
        }
      },
      {
        "id": 10680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82072930709008,
            21.006558126589496,
            105.82127474501179,
            21.00695208189991
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82072930709008,
              21.006558126589496
            ],
            [
              105.82073047214618,
              21.006559045876397
            ],
            [
              105.82074763388513,
              21.006572562112467
            ],
            [
              105.8207477942497,
              21.006572688694234
            ],
            [
              105.82082393196512,
              21.0066326589249
            ],
            [
              105.82083787396353,
              21.00661586661139
            ],
            [
              105.82127474501179,
              21.00695208189991
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A33_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10701",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006558126589496,
          "to_latitude": 21.00695208189991,
          "to_longitude": 105.82127474501179,
          "from_longitude": 105.82072930709008
        }
      },
      {
        "id": 10681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82184716972664,
            21.00604514066904,
            105.82205625489716,
            21.00622265654622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184716972664,
              21.00604514066904
            ],
            [
              105.82198437610958,
              21.006161631203664
            ],
            [
              105.82205010201302,
              21.006217434899963
            ],
            [
              105.82205625489716,
              21.00622265654622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10702",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00604514066904,
          "to_latitude": 21.00622265654622,
          "to_longitude": 105.82205625489716,
          "from_longitude": 105.82184716972664
        }
      },
      {
        "id": 10682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82193324555423,
            21.005822159732507,
            105.82229356913918,
            21.00622222098177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82193324555423,
              21.00622222098177
            ],
            [
              105.82198395398386,
              21.006171666295824
            ],
            [
              105.82198390162714,
              21.00616175633426
            ],
            [
              105.82198437610958,
              21.006161631203664
            ],
            [
              105.82199554080528,
              21.006158677919487
            ],
            [
              105.82211872632526,
              21.006025358116208
            ],
            [
              105.82213954201737,
              21.006002830420364
            ],
            [
              105.8221406206179,
              21.00600166275598
            ],
            [
              105.82216025040066,
              21.005978625043408
            ],
            [
              105.822280366668,
              21.005837651552227
            ],
            [
              105.82229356913918,
              21.005822159732507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10703",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00622222098177,
          "to_latitude": 21.005822159732507,
          "to_longitude": 105.82229356913918,
          "from_longitude": 105.82193324555423
        }
      },
      {
        "id": 10683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82213954201737,
            21.006002830420364,
            105.82221350152237,
            21.006057570924025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82213954201737,
              21.006002830420364
            ],
            [
              105.82217189319812,
              21.00602677496469
            ],
            [
              105.82220950797377,
              21.00605461679164
            ],
            [
              105.82221350152237,
              21.006057570924025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10704",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006002830420364,
          "to_latitude": 21.006057570924025,
          "to_longitude": 105.82221350152237,
          "from_longitude": 105.82213954201737
        }
      },
      {
        "id": 10684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82151011029822,
            21.00574708782288,
            105.82219316225441,
            21.00607909826049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82219316225441,
              21.00607909826049
            ],
            [
              105.82218297172146,
              21.006070948892702
            ],
            [
              105.82213053312984,
              21.00602900160657
            ],
            [
              105.82211872632526,
              21.006025358116208
            ],
            [
              105.82211185240538,
              21.006016164849754
            ],
            [
              105.82192703952812,
              21.005851659324495
            ],
            [
              105.8219255045851,
              21.00585029292789
            ],
            [
              105.82192388334943,
              21.005851611340876
            ],
            [
              105.82177188772998,
              21.005982311660556
            ],
            [
              105.82151011029822,
              21.00574708782288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10705",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00607909826049,
          "to_latitude": 21.00574708782288,
          "to_longitude": 105.82151011029822,
          "from_longitude": 105.82219316225441
        }
      },
      {
        "id": 10685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82164769649593,
            21.005507616813265,
            105.82195304969878,
            21.005895443237787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82165998775164,
              21.005507616813265
            ],
            [
              105.82166078500536,
              21.00550829399812
            ],
            [
              105.82174150569836,
              21.00557666882036
            ],
            [
              105.82172729775543,
              21.005592813067587
            ],
            [
              105.82195304969878,
              21.00581233452867
            ],
            [
              105.82193310347037,
              21.005841347694655
            ],
            [
              105.82186570288005,
              21.005895443237787
            ],
            [
              105.82164769649593,
              21.005687441069714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10706",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005507616813265,
          "to_latitude": 21.005687441069714,
          "to_longitude": 105.82164769649593,
          "from_longitude": 105.82165998775164
        }
      },
      {
        "id": 10686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8220787624434,
            21.005132322135065,
            105.8224465983167,
            21.005651045873858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8220787624434,
              21.005132322135065
            ],
            [
              105.82218750715903,
              21.005337627103785
            ],
            [
              105.8223029185252,
              21.005636073138053
            ],
            [
              105.82230492679301,
              21.005649391463106
            ],
            [
              105.82230517632681,
              21.005651045873858
            ],
            [
              105.82230780082959,
              21.005650787515478
            ],
            [
              105.8224465983167,
              21.005637080563886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10707",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005132322135065,
          "to_latitude": 21.005637080563886,
          "to_longitude": 105.8224465983167,
          "from_longitude": 105.8220787624434
        }
      },
      {
        "id": 10687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82063958401469,
            21.006123111391425,
            105.82113168629377,
            21.006691318396957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063958401469,
              21.006691318396957
            ],
            [
              105.82072597729763,
              21.00659053149582
            ],
            [
              105.8207292860303,
              21.006588395663748
            ],
            [
              105.82074616809614,
              21.006577508031377
            ],
            [
              105.8207477942497,
              21.00657268869423
            ],
            [
              105.82074783779854,
              21.006572561129083
            ],
            [
              105.82075203002807,
              21.006560138689704
            ],
            [
              105.8208792598502,
              21.00641170911442
            ],
            [
              105.82091195327443,
              21.006373567979004
            ],
            [
              105.82096096470957,
              21.006316390274165
            ],
            [
              105.82113168629377,
              21.006123111391425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10708",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87,
          "chatLieu": "HDPE",
          "chieuDai": 81.335,
          "from_latitude": 21.006691318396957,
          "to_latitude": 21.006123111391425,
          "to_longitude": 105.82113168629377,
          "from_longitude": 105.82063958401469
        }
      },
      {
        "id": 10688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82114869262028,
            21.00554230321669,
            105.82164918920864,
            21.00610682181871
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82114869262028,
              21.00610682181871
            ],
            [
              105.82128792249432,
              21.005950388358062
            ],
            [
              105.82142360089763,
              21.005797129614226
            ],
            [
              105.82143062724631,
              21.005789193361707
            ],
            [
              105.82164918920864,
              21.00554230321669
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10709",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00610682181871,
          "to_latitude": 21.00554230321669,
          "to_longitude": 105.82164918920864,
          "from_longitude": 105.82114869262028
        }
      },
      {
        "id": 10689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82141824853895,
            21.005781049991082,
            105.82143062724631,
            21.005789193361707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141824853895,
              21.005781049991082
            ],
            [
              105.82142220842078,
              21.00578365656253
            ],
            [
              105.82143062724631,
              21.005789193361707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A36_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10710",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005781049991082,
          "to_latitude": 21.005789193361707,
          "to_longitude": 105.82143062724631,
          "from_longitude": 105.82141824853895
        }
      },
      {
        "id": 10690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82168588627054,
            21.005105103446667,
            105.82206359853505,
            21.005502181703665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82168588627054,
              21.005502181703665
            ],
            [
              105.82186492458733,
              21.005296311616714
            ],
            [
              105.82191061821587,
              21.00524377329584
            ],
            [
              105.82193205913157,
              21.00522196247879
            ],
            [
              105.82194279918868,
              21.005211035748786
            ],
            [
              105.82205848095141,
              21.00510959101328
            ],
            [
              105.82206359853505,
              21.005105103446667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10711",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005502181703665,
          "to_latitude": 21.005105103446667,
          "to_longitude": 105.82206359853505,
          "from_longitude": 105.82168588627054
        }
      },
      {
        "id": 10691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82183790692203,
            21.005301901000404,
            105.82185163326645,
            21.00531159486544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82183790692203,
              21.005301901000404
            ],
            [
              105.82184272603227,
              21.00530513201513
            ],
            [
              105.82184607879645,
              21.00530732298806
            ],
            [
              105.82185163326645,
              21.00531159486544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10712",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.12,
          "chatLieu": "HDPE",
          "chieuDai": 1.787,
          "from_latitude": 21.005301901000404,
          "to_latitude": 21.00531159486544,
          "to_longitude": 105.82185163326645,
          "from_longitude": 105.82183790692203
        }
      },
      {
        "id": 10692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82178189592649,
            21.004983084740974,
            105.82202009579133,
            21.005298805312588
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.82194880360078,
                21.005125931723054
              ],
              [
                105.82194414467195,
                21.005122312408062
              ],
              [
                105.82183468452016,
                21.00503727370244
              ],
              [
                105.82181642350302,
                21.00500936362089
              ],
              [
                105.82178600857023,
                21.004983084740974
              ]
            ],
            [
              [
                105.82194880360078,
                21.005125931723054
              ],
              [
                105.8219595694275,
                21.005115320997376
              ],
              [
                105.82201014095335,
                21.005080932037426
              ],
              [
                105.82202009579133,
                21.005074162136697
              ]
            ],
            [
              [
                105.82178189592649,
                21.005298805312588
              ],
              [
                105.82182742125903,
                21.005251009771918
              ],
              [
                105.82191223058163,
                21.00516197732699
              ],
              [
                105.82191600670359,
                21.00515825495416
              ],
              [
                105.82194880360078,
                21.005125931723054
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10713",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.82194414467195,
            21.005122312408062
          ],
          "to_latitude": [
            105.82182742125903,
            21.005251009771918
          ],
          "to_longitude": [
            105.82178189592649,
            21.005298805312588
          ],
          "from_longitude": [
            105.82194880360078,
            21.005125931723054
          ]
        }
      },
      {
        "id": 10693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82201354584578,
            21.005078616501667,
            105.82204935218239,
            21.005098117939088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82201354584578,
              21.005078616501667
            ],
            [
              105.82202142820618,
              21.00508603458216
            ],
            [
              105.82202211120753,
              21.005090899149607
            ],
            [
              105.82202278099582,
              21.005092012304253
            ],
            [
              105.82203081594226,
              21.005091042527216
            ],
            [
              105.82204154478221,
              21.005096363468905
            ],
            [
              105.82204935218239,
              21.005098117939088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10714",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.78,
          "chatLieu": "HDPE",
          "chieuDai": 4.783,
          "from_latitude": 21.005078616501667,
          "to_latitude": 21.005098117939088,
          "to_longitude": 105.82204935218239,
          "from_longitude": 105.82201354584578
        }
      },
      {
        "id": 10694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82121360484423,
            21.004896205797973,
            105.8218020771755,
            21.00534398487251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8218020771755,
              21.00534398487251
            ],
            [
              105.82179670969987,
              21.005339903812583
            ],
            [
              105.82178338899827,
              21.005329766172856
            ],
            [
              105.82178011267241,
              21.00532727282541
            ],
            [
              105.82177736083067,
              21.005325178881883
            ],
            [
              105.82176749031649,
              21.00531766652333
            ],
            [
              105.82121360484423,
              21.004896205797973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10715",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00534398487251,
          "to_latitude": 21.004896205797973,
          "to_longitude": 105.82121360484423,
          "from_longitude": 105.8218020771755
        }
      },
      {
        "id": 10695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111898150234,
            21.005031868332093,
            105.82175052241817,
            21.005404536901885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82175052241817,
              21.005404536901885
            ],
            [
              105.82174575407176,
              21.005400961464915
            ],
            [
              105.82173793651125,
              21.005395088486722
            ],
            [
              105.82173697260097,
              21.00539436513901
            ],
            [
              105.82172803278455,
              21.005387659383345
            ],
            [
              105.82171549409001,
              21.005377522478863
            ],
            [
              105.82171456079092,
              21.00537676827347
            ],
            [
              105.82130021798247,
              21.005041844343125
            ],
            [
              105.82123587442585,
              21.005111601310393
            ],
            [
              105.82111898150234,
              21.005031868332093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A40_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10716",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005404536901885,
          "to_latitude": 21.005031868332093,
          "to_longitude": 105.82111898150234,
          "from_longitude": 105.82175052241817
        }
      },
      {
        "id": 10696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82133223676365,
            21.00542934850454,
            105.8216895485261,
            21.005832392693243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82133223676365,
              21.005832392693243
            ],
            [
              105.82141471488603,
              21.005736288621947
            ],
            [
              105.82141689595258,
              21.005721793030325
            ],
            [
              105.82141944022801,
              21.005720935329492
            ],
            [
              105.82143211072048,
              21.00571666423161
            ],
            [
              105.82146059056409,
              21.005687609933574
            ],
            [
              105.82148424898496,
              21.005660925488705
            ],
            [
              105.82149874439357,
              21.005644574840726
            ],
            [
              105.8216895485261,
              21.00542934850454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10717",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005832392693243,
          "to_latitude": 21.00542934850454,
          "to_longitude": 105.8216895485261,
          "from_longitude": 105.82133223676365
        }
      },
      {
        "id": 10697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82149874439357,
            21.005644574840726,
            105.82152402899581,
            21.00566456858199
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82149874439357,
              21.005644574840726
            ],
            [
              105.82150145500167,
              21.005646691568366
            ],
            [
              105.8215111198873,
              21.005654241063475
            ],
            [
              105.82151352259774,
              21.005656164179875
            ],
            [
              105.82151430224665,
              21.00565678815994
            ],
            [
              105.82151711942505,
              21.00565904437322
            ],
            [
              105.82152385123923,
              21.00566442763325
            ],
            [
              105.82152402899581,
              21.00566456858199
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10718",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005644574840726,
          "to_latitude": 21.00566456858199,
          "to_longitude": 105.82152402899581,
          "from_longitude": 105.82149874439357
        }
      },
      {
        "id": 10698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82093297789065,
            21.005339090855376,
            105.82144968474117,
            21.005745760896026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82093297789065,
              21.005339090855376
            ],
            [
              105.82124658734574,
              21.005575218961194
            ],
            [
              105.82141439596258,
              21.00571668289098
            ],
            [
              105.82141944022801,
              21.005720935329492
            ],
            [
              105.82142959737736,
              21.005729499487853
            ],
            [
              105.82144033137325,
              21.00573818821658
            ],
            [
              105.82144398443344,
              21.005741149430502
            ],
            [
              105.82144968474117,
              21.005745760896026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A37_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10719",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005339090855376,
          "to_latitude": 21.005745760896026,
          "to_longitude": 105.82144968474117,
          "from_longitude": 105.82093297789065
        }
      },
      {
        "id": 10699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8207474260822,
            21.005852421573977,
            105.82130588708613,
            21.006482333358957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82130588708613,
              21.005852421573977
            ],
            [
              105.82110116183847,
              21.006083341409198
            ],
            [
              105.82108069625778,
              21.006106425488213
            ],
            [
              105.8207474260822,
              21.006482333358957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10720",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005852421573977,
          "to_latitude": 21.006482333358957,
          "to_longitude": 105.8207474260822,
          "from_longitude": 105.82130588708613
        }
      },
      {
        "id": 10700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82108069625778,
            21.006106425488213,
            105.8211098060482,
            21.00612795980617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82108069625778,
              21.006106425488213
            ],
            [
              105.82109027298033,
              21.006113154397813
            ],
            [
              105.82109945177525,
              21.00612011193685
            ],
            [
              105.82110361088857,
              21.006123267620083
            ],
            [
              105.82110864023404,
              21.006127077557803
            ],
            [
              105.8211098060482,
              21.00612795980617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A35_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.10721",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006106425488213,
          "to_latitude": 21.00612795980617,
          "to_longitude": 105.8211098060482,
          "from_longitude": 105.82108069625778
        }
      },
      {
        "id": 10701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82158822605969,
            21.00439029368314,
            105.82202135613953,
            21.005025220972723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82202135613953,
              21.005025220972723
            ],
            [
              105.82187238224482,
              21.004776983722294
            ],
            [
              105.82186784123778,
              21.004768548727498
            ],
            [
              105.82186142084937,
              21.004756620993195
            ],
            [
              105.82179547795475,
              21.004649111155054
            ],
            [
              105.8217909995971,
              21.004640873662268
            ],
            [
              105.82178169502235,
              21.004623757257555
            ],
            [
              105.82173641592989,
              21.00457332014088
            ],
            [
              105.82173186591218,
              21.004571317974595
            ],
            [
              105.82172977843925,
              21.004570399534575
            ],
            [
              105.82173087744187,
              21.004565229571387
            ],
            [
              105.8217174040857,
              21.00454353495605
            ],
            [
              105.82169463698854,
              21.004506874462695
            ],
            [
              105.82168258172413,
              21.00450315265321
            ],
            [
              105.82168259213407,
              21.004502243052425
            ],
            [
              105.82168268941388,
              21.00449330694885
            ],
            [
              105.82158822605969,
              21.00439029368314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10722",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005025220972723,
          "to_latitude": 21.00439029368314,
          "to_longitude": 105.82158822605969,
          "from_longitude": 105.82202135613953
        }
      },
      {
        "id": 10702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8217174040857,
            21.004530360126168,
            105.8217502153258,
            21.00454353495605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8217502153258,
              21.004530360126168
            ],
            [
              105.82174295187839,
              21.004533276491617
            ],
            [
              105.82172532400514,
              21.00454035438939
            ],
            [
              105.8217244160754,
              21.00454071916076
            ],
            [
              105.8217174040857,
              21.00454353495605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10723",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004530360126168,
          "to_latitude": 21.00454353495605,
          "to_longitude": 105.8217174040857,
          "from_longitude": 105.8217502153258
        }
      },
      {
        "id": 10703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82128256396061,
            21.00419625866481,
            105.82156621457719,
            21.004545339481275
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.82128256396061,
                21.00429062539881
              ],
              [
                105.82136437899014,
                21.0044870557206
              ]
            ],
            [
              [
                105.82136437899014,
                21.0044870557206
              ],
              [
                105.82136647946115,
                21.004485780161705
              ],
              [
                105.82156621457719,
                21.004371997566924
              ],
              [
                105.82156048365992,
                21.004367056572775
              ],
              [
                105.82152947293213,
                21.004340320772968
              ],
              [
                105.82136812898179,
                21.004221076719432
              ],
              [
                105.82133688353738,
                21.00419625866481
              ]
            ],
            [
              [
                105.82136437899014,
                21.0044870557206
              ],
              [
                105.82138865520828,
                21.004545339481275
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10724",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.82136437899014,
            21.0044870557206
          ],
          "to_latitude": [
            105.82138865520828,
            21.004545339481275
          ],
          "to_longitude": [
            105.82136437899014,
            21.0044870557206
          ],
          "from_longitude": [
            105.82128256396061,
            21.00429062539881
          ]
        }
      },
      {
        "id": 10704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82156048365992,
            21.004352586767382,
            105.8215853899005,
            21.004367056572775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82156048365992,
              21.004367056572775
            ],
            [
              105.82156270044604,
              21.004365768708123
            ],
            [
              105.8215853899005,
              21.004352586767382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10725",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004367056572775,
          "to_latitude": 21.004352586767382,
          "to_longitude": 105.8215853899005,
          "from_longitude": 105.82156048365992
        }
      },
      {
        "id": 10705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82086480518403,
            21.004158523266703,
            105.82135451074541,
            21.004370640762186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82135451074541,
              21.004174650581643
            ],
            [
              105.82134781106855,
              21.004177586790174
            ],
            [
              105.82132899209643,
              21.004185834655303
            ],
            [
              105.82129783305574,
              21.004158523266703
            ],
            [
              105.82114709788061,
              21.004236530743352
            ],
            [
              105.82114812524547,
              21.00425658919152
            ],
            [
              105.82110535785728,
              21.00427611646853
            ],
            [
              105.82111474552019,
              21.004300702208813
            ],
            [
              105.82104513575374,
              21.00432681704557
            ],
            [
              105.82097967346989,
              21.004257967223747
            ],
            [
              105.82087838505764,
              21.00435731858935
            ],
            [
              105.82086480518403,
              21.004370640762186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10726",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004174650581643,
          "to_latitude": 21.004370640762186,
          "to_longitude": 105.82086480518403,
          "from_longitude": 105.82135451074541
        }
      },
      {
        "id": 10706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82064971834902,
            21.003685827915668,
            105.82083116342353,
            21.003998322747332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82064971834902,
              21.003685827915668
            ],
            [
              105.82077580183028,
              21.003774939422673
            ],
            [
              105.82083116342353,
              21.003817611011023
            ],
            [
              105.82072802311122,
              21.003924083235948
            ],
            [
              105.8208024714891,
              21.003998322747332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10727",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003685827915668,
          "to_latitude": 21.003998322747332,
          "to_longitude": 105.8208024714891,
          "from_longitude": 105.82064971834902
        }
      },
      {
        "id": 10707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82087183771148,
            21.003843809888266,
            105.82127051424779,
            21.004140611300983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82087183771148,
              21.003843809888266
            ],
            [
              105.82092056629321,
              21.003879086190413
            ],
            [
              105.82111745973575,
              21.004026229719045
            ],
            [
              105.82127051424779,
              21.004140611300983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10728",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003843809888266,
          "to_latitude": 21.004140611300983,
          "to_longitude": 105.82127051424779,
          "from_longitude": 105.82087183771148
        }
      },
      {
        "id": 10708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82077580183028,
            21.003760549384154,
            105.82079055891175,
            21.003774939422673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82077580183028,
              21.003774939422673
            ],
            [
              105.82079055891175,
              21.003760549384154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A14_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10729",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003774939422673,
          "to_latitude": 21.003760549384154,
          "to_longitude": 105.82079055891175,
          "from_longitude": 105.82077580183028
        }
      },
      {
        "id": 10709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111745973575,
            21.004012326880126,
            105.821133446812,
            21.004026229719045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82111745973575,
              21.004026229719045
            ],
            [
              105.821133446812,
              21.004012326880126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10730",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004026229719045,
          "to_latitude": 21.004012326880126,
          "to_longitude": 105.821133446812,
          "from_longitude": 105.82111745973575
        }
      },
      {
        "id": 10710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82056782925083,
            21.003720522288475,
            105.82078584479835,
            21.004189173740002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82056943668476,
              21.003720522288475
            ],
            [
              105.82058000368913,
              21.003727907622434
            ],
            [
              105.82058048658311,
              21.003728266585654
            ],
            [
              105.8206299488164,
              21.00376527544206
            ],
            [
              105.82067752656015,
              21.003859413519212
            ],
            [
              105.82062440985135,
              21.003932648651478
            ],
            [
              105.82060494205491,
              21.004028411536027
            ],
            [
              105.82056782925083,
              21.004088540425645
            ],
            [
              105.82067681672368,
              21.00414983911615
            ],
            [
              105.82069413751833,
              21.004131809359606
            ],
            [
              105.82074609455765,
              21.00416996950435
            ],
            [
              105.82078584479835,
              21.004189173740002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10731",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003720522288475,
          "to_latitude": 21.004189173740002,
          "to_longitude": 105.82078584479835,
          "from_longitude": 105.82056943668476
        }
      },
      {
        "id": 10711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82053917238693,
            21.003671283414167,
            105.820582423007,
            21.003840303064937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82053917238693,
              21.003840303064937
            ],
            [
              105.82054554263706,
              21.003788971191362
            ],
            [
              105.82055124233077,
              21.003745464284773
            ],
            [
              105.82055191287819,
              21.003723241327595
            ],
            [
              105.82055459508595,
              21.003708843353667
            ],
            [
              105.82055761257192,
              21.0036988273706
            ],
            [
              105.82056331226559,
              21.00368787238842
            ],
            [
              105.82057337054914,
              21.003679108402256
            ],
            [
              105.820582423007,
              21.003671283414167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10732",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 19.862,
          "from_latitude": 21.003840303064937,
          "to_latitude": 21.003671283414167,
          "to_longitude": 105.820582423007,
          "from_longitude": 105.82053917238693
        }
      },
      {
        "id": 10712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82054611318759,
            21.003784616065214,
            105.82055611877836,
            21.003785978408803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82055090705254,
              21.003785528199597
            ],
            [
              105.82055090705254,
              21.003785528199597
            ],
            [
              105.82055611877836,
              21.003785978408803
            ],
            [
              105.82054611318759,
              21.003784616065214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A13A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10733",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003785528199597,
          "to_latitude": 21.003784616065214,
          "to_longitude": 105.82054611318759,
          "from_longitude": 105.82055090705254
        }
      },
      {
        "id": 10713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.819855421624,
            21.003773707046026,
            105.82053946651182,
            21.003925217910727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82053946651182,
              21.003852041313618
            ],
            [
              105.82052522139483,
              21.003850297207514
            ],
            [
              105.82037238524504,
              21.003837300340095
            ],
            [
              105.82035967060952,
              21.003834880454434
            ],
            [
              105.8203014134086,
              21.00382379139453
            ],
            [
              105.82023767702698,
              21.003803650371925
            ],
            [
              105.82014291702416,
              21.003773707046026
            ],
            [
              105.82013096458749,
              21.00389193670193
            ],
            [
              105.82011385671018,
              21.003905108718488
            ],
            [
              105.82010514617106,
              21.00391181651327
            ],
            [
              105.81996512070468,
              21.0038589667357
            ],
            [
              105.81992475058782,
              21.003925217910727
            ],
            [
              105.819855421624,
              21.003922981283466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10734",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 130,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003852041313618,
          "to_latitude": 21.003922981283466,
          "to_longitude": 105.819855421624,
          "from_longitude": 105.82053946651182
        }
      },
      {
        "id": 10714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82008484602486,
            21.003905108718488,
            105.82011385671018,
            21.003991735315314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82011385671018,
              21.003905108718488
            ],
            [
              105.82008484602486,
              21.003991735315314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10735",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003905108718488,
          "to_latitude": 21.003991735315314,
          "to_longitude": 105.82008484602486,
          "from_longitude": 105.82011385671018
        }
      },
      {
        "id": 10715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82020122744524,
            21.00368770157612,
            105.82026455207595,
            21.003803650371925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82023767702698,
              21.003803650371925
            ],
            [
              105.82026455207595,
              21.003746206508826
            ],
            [
              105.82020122744524,
              21.00368770157612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10736",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003803650371925,
          "to_latitude": 21.00368770157612,
          "to_longitude": 105.82020122744524,
          "from_longitude": 105.82023767702698
        }
      },
      {
        "id": 10716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8201713495565,
            21.003834880454434,
            105.82035967060952,
            21.004012710559813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82035967060952,
              21.003834880454434
            ],
            [
              105.82033083177481,
              21.00393426616634
            ],
            [
              105.82027416855136,
              21.00399009230494
            ],
            [
              105.8201713495565,
              21.004012710559813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A12_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10737",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003834880454434,
          "to_latitude": 21.004012710559813,
          "to_longitude": 105.8201713495565,
          "from_longitude": 105.82035967060952
        }
      },
      {
        "id": 10717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8203605979619,
            21.00386719688696,
            105.82052478860753,
            21.004406640207012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82052478860753,
              21.00386719688696
            ],
            [
              105.82049834979455,
              21.003967474282188
            ],
            [
              105.82047060551784,
              21.004069126356818
            ],
            [
              105.8204622000334,
              21.004095741654652
            ],
            [
              105.82044301135059,
              21.00415777483036
            ],
            [
              105.82044225427211,
              21.00416022171195
            ],
            [
              105.82043679218894,
              21.004177881764637
            ],
            [
              105.8203605979619,
              21.004406640207012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10738",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00386719688696,
          "to_latitude": 21.004406640207012,
          "to_longitude": 105.8203605979619,
          "from_longitude": 105.82052478860753
        }
      },
      {
        "id": 10718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82037612542098,
            21.003975931868787,
            105.82051582501467,
            21.00444324817091
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82051582501467,
              21.003975931868787
            ],
            [
              105.8204370980532,
              21.00423928896736
            ],
            [
              105.82043484803225,
              21.004246815574756
            ],
            [
              105.82037612542098,
              21.00444324817091
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10739",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003975931868787,
          "to_latitude": 21.00444324817091,
          "to_longitude": 105.82037612542098,
          "from_longitude": 105.82051582501467
        }
      },
      {
        "id": 10719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82042460487877,
            21.004236214082926,
            105.8204370980532,
            21.00423928896736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82042460487877,
              21.004236214082926
            ],
            [
              105.82042728055573,
              21.004236874993733
            ],
            [
              105.8204335719253,
              21.00423842260721
            ],
            [
              105.8204370980532,
              21.00423928896736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A10_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10740",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004236214082926,
          "to_latitude": 21.00423928896736,
          "to_longitude": 105.8204370980532,
          "from_longitude": 105.82042460487877
        }
      },
      {
        "id": 10720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82044225427211,
            21.00416022171195,
            105.82044797464233,
            21.00416112988218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82044797464233,
              21.00416112988218
            ],
            [
              105.82044750966364,
              21.00416105715561
            ],
            [
              105.82044505284512,
              21.004160666158587
            ],
            [
              105.82044225427211,
              21.00416022171195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A11_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10741",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00416112988218,
          "to_latitude": 21.00416022171195,
          "to_longitude": 105.82044225427211,
          "from_longitude": 105.82044797464233
        }
      },
      {
        "id": 10721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81977625930065,
            21.004385605009897,
            105.8203668357997,
            21.00443904890184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81977625930065,
              21.004385605009897
            ],
            [
              105.81983123169796,
              21.00438591377869
            ],
            [
              105.81989960043525,
              21.0043910525915
            ],
            [
              105.81997871934519,
              21.00439079608824
            ],
            [
              105.82000229588152,
              21.004391186504495
            ],
            [
              105.82006090005741,
              21.004392156929477
            ],
            [
              105.82006629732568,
              21.00438832652739
            ],
            [
              105.82007590459224,
              21.00439751880245
            ],
            [
              105.82008797453607,
              21.00439908379214
            ],
            [
              105.8201533533745,
              21.004402526769756
            ],
            [
              105.82016124637191,
              21.00439629343035
            ],
            [
              105.82016415698557,
              21.00439523798277
            ],
            [
              105.82017189515184,
              21.00440209232554
            ],
            [
              105.8203668357997,
              21.00443904890184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10742",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": 63,
          "from_latitude": 21.004385605009897,
          "to_latitude": 21.00443904890184,
          "to_longitude": 105.8203668357997,
          "from_longitude": 105.81977625930065
        }
      },
      {
        "id": 10722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82015788756053,
            21.004229476794517,
            105.82020142473098,
            21.004414252243688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82015788756053,
              21.004414252243688
            ],
            [
              105.82016038574635,
              21.00439933061776
            ],
            [
              105.82016124637191,
              21.004396293430347
            ],
            [
              105.82019470060587,
              21.004278106488073
            ],
            [
              105.82020142473098,
              21.004229476794517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10743",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004414252243688,
          "to_latitude": 21.004229476794517,
          "to_longitude": 105.82020142473098,
          "from_longitude": 105.82015788756053
        }
      },
      {
        "id": 10723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993533836797,
            21.004268996102354,
            105.82007215244641,
            21.004405788287812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82006543279459,
              21.004405788287812
            ],
            [
              105.82006605123203,
              21.00439328191988
            ],
            [
              105.82006629732568,
              21.00438832652739
            ],
            [
              105.82007215244641,
              21.004270074774247
            ],
            [
              105.81993533836797,
              21.004268996102354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A60_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10745",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004405788287812,
          "to_latitude": 21.004268996102354,
          "to_longitude": 105.81993533836797,
          "from_longitude": 105.82006543279459
        }
      },
      {
        "id": 10724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81975803852936,
            21.004204489619777,
            105.81980421257708,
            21.004408152784848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8197744702676,
              21.004408152784848
            ],
            [
              105.81976936235547,
              21.00438958985227
            ],
            [
              105.81975803852936,
              21.004348425570008
            ],
            [
              105.81979314221184,
              21.004229625590195
            ],
            [
              105.81980421257708,
              21.004204489619777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10746",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004408152784848,
          "to_latitude": 21.004204489619777,
          "to_longitude": 105.81980421257708,
          "from_longitude": 105.8197744702676
        }
      },
      {
        "id": 10725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8193917698206,
            21.00410339850554,
            105.81973220822356,
            21.00441317234585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81943470420316,
              21.00410339850554
            ],
            [
              105.8193917698206,
              21.004249174904196
            ],
            [
              105.81956558298039,
              21.004308369312135
            ],
            [
              105.81958029946581,
              21.004322622746308
            ],
            [
              105.81958599304832,
              21.004328136635596
            ],
            [
              105.8195913166272,
              21.004337230896898
            ],
            [
              105.8196238266176,
              21.004355697107428
            ],
            [
              105.81965715964179,
              21.00437463083312
            ],
            [
              105.81968229039485,
              21.004388905460043
            ],
            [
              105.81973220822356,
              21.00441317234585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10747",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00410339850554,
          "to_latitude": 21.00441317234585,
          "to_longitude": 105.81973220822356,
          "from_longitude": 105.81943470420316
        }
      },
      {
        "id": 10726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81958029946581,
            21.00417125026111,
            105.81966790144986,
            21.004322622746308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81958029946581,
              21.004322622746308
            ],
            [
              105.81959698356498,
              21.00431937117206
            ],
            [
              105.81966790144986,
              21.004194575639005
            ],
            [
              105.81965864273982,
              21.00418404703615
            ],
            [
              105.81966742394238,
              21.00417125026111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10749",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004322622746308,
          "to_latitude": 21.00417125026111,
          "to_longitude": 105.81966742394238,
          "from_longitude": 105.81958029946581
        }
      },
      {
        "id": 10727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81912255041561,
            21.00426086437045,
            105.81939685939211,
            21.004611216559383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81939685939211,
              21.00426086437045
            ],
            [
              105.819396572827,
              21.00426122975105
            ],
            [
              105.81912255041561,
              21.004611216559383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10750",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00426086437045,
          "to_latitude": 21.004611216559383,
          "to_longitude": 105.81912255041561,
          "from_longitude": 105.81939685939211
        }
      },
      {
        "id": 10728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81928173133724,
            21.00433087414482,
            105.81957890172487,
            21.004753994155696
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81957890172487,
                21.00433087414482
              ],
              [
                105.81957192591243,
                21.00434313925296
              ],
              [
                105.81945365512277,
                21.004551083613798
              ],
              [
                105.81941510202174,
                21.00456579855755
              ]
            ],
            [
              [
                105.81941510202174,
                21.00456579855755
              ],
              [
                105.81939894263857,
                21.004571965919308
              ],
              [
                105.81934890778452,
                21.00467901805784
              ],
              [
                105.81940796495658,
                21.004698633672128
              ]
            ],
            [
              [
                105.81941510202174,
                21.00456579855755
              ],
              [
                105.81939081544081,
                21.00455745132787
              ],
              [
                105.81928173133724,
                21.004753994155696
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10751",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81957192591243,
            21.00434313925296
          ],
          "to_latitude": [
            105.81939081544081,
            21.00455745132787
          ],
          "to_longitude": [
            105.81941510202174,
            21.00456579855755
          ],
          "from_longitude": [
            105.81957890172487,
            21.00433087414482
          ]
        }
      },
      {
        "id": 10729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8201568238728,
            21.004424871897214,
            105.82024571022119,
            21.00471156486171
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82024571022119,
              21.004424871897214
            ],
            [
              105.82024479992059,
              21.00442761938816
            ],
            [
              105.82019677761069,
              21.004572626547397
            ],
            [
              105.82018186258011,
              21.00462449404151
            ],
            [
              105.8201568238728,
              21.00471156486171
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10752",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004424871897214,
          "to_latitude": 21.00471156486171,
          "to_longitude": 105.8201568238728,
          "from_longitude": 105.82024571022119
        }
      },
      {
        "id": 10730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993753806493,
            21.004545925218736,
            105.82018186258011,
            21.00462449404151
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82018186258011,
              21.00462449404151
            ],
            [
              105.81993753806493,
              21.004545925218736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10753",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00462449404151,
          "to_latitude": 21.004545925218736,
          "to_longitude": 105.81993753806493,
          "from_longitude": 105.82018186258011
        }
      },
      {
        "id": 10731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81995988211698,
            21.004503468817823,
            105.82019677761069,
            21.004572626547397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82019677761069,
              21.004572626547397
            ],
            [
              105.81995988211698,
              21.004503468817823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10754",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004572626547397,
          "to_latitude": 21.004503468817823,
          "to_longitude": 105.81995988211698,
          "from_longitude": 105.82019677761069
        }
      },
      {
        "id": 10732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82027100236053,
            21.004479423083673,
            105.82034788650212,
            21.00476221320951
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82034788650212,
              21.004479423083673
            ],
            [
              105.82031053724776,
              21.0046086057556
            ],
            [
              105.82030707928892,
              21.004620564904332
            ],
            [
              105.82028246487918,
              21.00470570711039
            ],
            [
              105.82027100236053,
              21.00476221320951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10755",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004479423083673,
          "to_latitude": 21.00476221320951,
          "to_longitude": 105.82027100236053,
          "from_longitude": 105.82034788650212
        }
      },
      {
        "id": 10733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82031053724776,
            21.0046086057556,
            105.82032067269645,
            21.004611465300737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82031053724776,
              21.0046086057556
            ],
            [
              105.82031551297779,
              21.004610010681244
            ],
            [
              105.82031900512936,
              21.00461099462698
            ],
            [
              105.82032067269645,
              21.004611465300737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10756",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0046086057556,
          "to_latitude": 21.004611465300737,
          "to_longitude": 105.82032067269645,
          "from_longitude": 105.82031053724776
        }
      },
      {
        "id": 10734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81979932952876,
            21.004501760760174,
            105.8202776454249,
            21.004781291694602
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81989247323266,
                21.00463143234468
              ],
              [
                105.81994601900149,
                21.004501760760174
              ]
            ],
            [
              [
                105.81989247323266,
                21.00463143234468
              ],
              [
                105.81985539139617,
                21.004616665253277
              ],
              [
                105.81983346076684,
                21.004631275833137
              ],
              [
                105.81979932952876,
                21.004703068039863
              ]
            ],
            [
              [
                105.8202776454249,
                21.004781291694602
              ],
              [
                105.82027038134699,
                21.004778468889665
              ],
              [
                105.82007481973854,
                21.004713330196967
              ],
              [
                105.81989247323266,
                21.00463143234468
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10757",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81994601900149,
            21.004501760760174
          ],
          "to_latitude": [
            105.82027038134699,
            21.004778468889665
          ],
          "to_longitude": [
            105.8202776454249,
            21.004781291694602
          ],
          "from_longitude": [
            105.81989247323266,
            21.00463143234468
          ]
        }
      },
      {
        "id": 10735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82031139210892,
            21.004335858184373,
            105.82076724406444,
            21.00473586926586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82076724406444,
              21.004335858184373
            ],
            [
              105.82047555383326,
              21.004643848032217
            ],
            [
              105.82040648695246,
              21.00473586926586
            ],
            [
              105.82031139210892,
              21.004697088462397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10758",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": 68.878,
          "from_latitude": 21.004335858184373,
          "to_latitude": 21.004697088462397,
          "to_longitude": 105.82031139210892,
          "from_longitude": 105.82076724406444
        }
      },
      {
        "id": 10736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82029953377727,
            21.00469455361474,
            105.82031139210892,
            21.00469708846241
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82029953377727,
              21.00469455361474
            ],
            [
              105.82030355708889,
              21.004695805603667
            ],
            [
              105.8203062392966,
              21.004696118601316
            ],
            [
              105.82031139210892,
              21.00469708846241
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10759",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": 1.268,
          "from_latitude": 21.00469455361474,
          "to_latitude": 21.00469708846241,
          "to_longitude": 105.82031139210892,
          "from_longitude": 105.82029953377727
        }
      },
      {
        "id": 10737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82021135313026,
            21.004802433495968,
            105.82026303956037,
            21.004946863990746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82026303956037,
              21.004802433495968
            ],
            [
              105.82024932076457,
              21.00485371174411
            ],
            [
              105.82021135313026,
              21.004946863990746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10760",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004802433495968,
          "to_latitude": 21.004946863990746,
          "to_longitude": 105.82021135313026,
          "from_longitude": 105.82026303956037
        }
      },
      {
        "id": 10738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82009825934443,
            21.00490463478983,
            105.82022441832682,
            21.004951987363434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82022441832682,
              21.004951987363434
            ],
            [
              105.82021386200671,
              21.004947802096158
            ],
            [
              105.8202113839735,
              21.004946875584075
            ],
            [
              105.82021135313026,
              21.004946863990746
            ],
            [
              105.82009825934443,
              21.00490463478983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10761",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004951987363434,
          "to_latitude": 21.00490463478983,
          "to_longitude": 105.82009825934443,
          "from_longitude": 105.82022441832682
        }
      },
      {
        "id": 10739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81982998674512,
            21.0048479029525,
            105.82015164828081,
            21.005066271238565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82015164828081,
              21.005066271238565
            ],
            [
              105.82013886164705,
              21.005059847676176
            ],
            [
              105.81995879746046,
              21.004969370890716
            ],
            [
              105.81995412530529,
              21.004921772500115
            ],
            [
              105.81984643546019,
              21.00488131632564
            ],
            [
              105.81985163632088,
              21.004858079211594
            ],
            [
              105.81982998674512,
              21.0048479029525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10762",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005066271238565,
          "to_latitude": 21.0048479029525,
          "to_longitude": 105.81982998674512,
          "from_longitude": 105.82015164828081
        }
      },
      {
        "id": 10740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81995412530529,
            21.00482130070162,
            105.82000349647043,
            21.004921772500115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81995412530529,
              21.004921772500115
            ],
            [
              105.82000349647043,
              21.00482130070162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10763",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004921772500115,
          "to_latitude": 21.00482130070162,
          "to_longitude": 105.82000349647043,
          "from_longitude": 105.81995412530529
        }
      },
      {
        "id": 10741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81942881090892,
            21.00492313095883,
            105.82014080656299,
            21.005078683255974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82014080656299,
              21.005078683255974
            ],
            [
              105.82012792318008,
              21.005071804932026
            ],
            [
              105.81998476184839,
              21.004995360837736
            ],
            [
              105.81993265430275,
              21.0049911165067
            ],
            [
              105.81973259797371,
              21.00494116789378
            ],
            [
              105.8197189920305,
              21.00493777044662
            ],
            [
              105.81965529265997,
              21.00496569252505
            ],
            [
              105.81953650922046,
              21.00496233277494
            ],
            [
              105.81942881090892,
              21.00492313095883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10764",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 100,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005078683255974,
          "to_latitude": 21.00492313095883,
          "to_longitude": 105.81942881090892,
          "from_longitude": 105.82014080656299
        }
      },
      {
        "id": 10742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81964157852455,
            21.004737654600007,
            105.81973259797371,
            21.00494116789378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81973259797371,
              21.00494116789378
            ],
            [
              105.81972244338587,
              21.004825621652884
            ],
            [
              105.81971555543242,
              21.004747253712583
            ],
            [
              105.81970788707142,
              21.00473785823201
            ],
            [
              105.81964157852455,
              21.004737654600007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10765",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00494116789378,
          "to_latitude": 21.004737654600007,
          "to_longitude": 105.81964157852455,
          "from_longitude": 105.81973259797371
        }
      },
      {
        "id": 10743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81959449772265,
            21.00481337101404,
            105.81972244338587,
            21.0048290871324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81972244338587,
              21.004825621652884
            ],
            [
              105.81962889928869,
              21.0048290871324
            ],
            [
              105.81961238279553,
              21.00481337101404
            ],
            [
              105.81959449772265,
              21.004813887979893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10766",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004825621652884,
          "to_latitude": 21.004813887979893,
          "to_longitude": 105.81959449772265,
          "from_longitude": 105.81972244338587
        }
      },
      {
        "id": 10744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81987031786204,
            21.005093359343068,
            105.8201099750942,
            21.005325566062382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81987031786204,
              21.005325566062382
            ],
            [
              105.81989190635053,
              21.005305708465585
            ],
            [
              105.81992860544803,
              21.0052827829666
            ],
            [
              105.81992701008753,
              21.005278100188516
            ],
            [
              105.81992867745475,
              21.005276772539613
            ],
            [
              105.81993226501648,
              21.0052739146039
            ],
            [
              105.81993953935391,
              21.005277203438904
            ],
            [
              105.81998236018785,
              21.005251004944338
            ],
            [
              105.81998867994139,
              21.005244184930778
            ],
            [
              105.81999795924563,
              21.005234172198307
            ],
            [
              105.82001418997193,
              21.00521376775314
            ],
            [
              105.8201099750942,
              21.005093359343068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10767",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005325566062382,
          "to_latitude": 21.005093359343068,
          "to_longitude": 105.8201099750942,
          "from_longitude": 105.81987031786204
        }
      },
      {
        "id": 10745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81998867994139,
            21.005244184930778,
            105.81999462631158,
            21.005248951520063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81998867994139,
              21.005244184930778
            ],
            [
              105.81999084980953,
              21.005245924029538
            ],
            [
              105.81999338680828,
              21.005247959424764
            ],
            [
              105.81999462631158,
              21.005248951520063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10768",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005244184930778,
          "to_latitude": 21.005248951520063,
          "to_longitude": 105.81999462631158,
          "from_longitude": 105.81998867994139
        }
      },
      {
        "id": 10746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81984410144152,
            21.00540223100031,
            105.81998792819871,
            21.005535060184734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81984410144152,
              21.00540223100031
            ],
            [
              105.81984876824019,
              21.005404481042085
            ],
            [
              105.8199702871641,
              21.005463039913927
            ],
            [
              105.81998792819871,
              21.005471538298874
            ],
            [
              105.81997768792122,
              21.005535060184734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10769",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00540223100031,
          "to_latitude": 21.005535060184734,
          "to_longitude": 105.81997768792122,
          "from_longitude": 105.81984410144152
        }
      },
      {
        "id": 10747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81956607499427,
            21.005370709742383,
            105.81979359234299,
            21.005525287132606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81979359234299,
              21.005525287132606
            ],
            [
              105.81978562146779,
              21.005520741643462
            ],
            [
              105.81978488139579,
              21.005520317981254
            ],
            [
              105.81978373095063,
              21.005520662233195
            ],
            [
              105.81977823321523,
              21.00552230910543
            ],
            [
              105.81977038819241,
              21.00551579848812
            ],
            [
              105.81956607499427,
              21.00545917186393
            ],
            [
              105.81956919951577,
              21.005394948132146
            ],
            [
              105.81957037914437,
              21.005370709742383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10770",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005525287132606,
          "to_latitude": 21.005370709742383,
          "to_longitude": 105.81957037914437,
          "from_longitude": 105.81979359234299
        }
      },
      {
        "id": 10748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81976264512808,
            21.005584478413073,
            105.81993655458467,
            21.005656761503467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81976264512808,
              21.005584478413073
            ],
            [
              105.81977109901112,
              21.00558799370308
            ],
            [
              105.81986384775566,
              21.00562654471695
            ],
            [
              105.81993655458467,
              21.005656761503467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B18_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10771",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005584478413073,
          "to_latitude": 21.005656761503467,
          "to_longitude": 105.81993655458467,
          "from_longitude": 105.81976264512808
        }
      },
      {
        "id": 10749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81944252800763,
            21.005381146671553,
            105.81975586357584,
            21.005597448013713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81946605746143,
              21.005381146671553
            ],
            [
              105.81944252800763,
              21.00550426428788
            ],
            [
              105.81952038964147,
              21.005539284192405
            ],
            [
              105.8197440647303,
              21.00559454135164
            ],
            [
              105.81974877754833,
              21.00559570639733
            ],
            [
              105.81975586357584,
              21.005597448013713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10772",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005381146671553,
          "to_latitude": 21.005597448013713,
          "to_longitude": 105.81975586357584,
          "from_longitude": 105.81946605746143
        }
      },
      {
        "id": 10750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81959589377804,
            21.005344561806144,
            105.81985701504594,
            21.005835854159212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81959589377804,
              21.005835854159212
            ],
            [
              105.81968360886471,
              21.00568151984822
            ],
            [
              105.81970279472502,
              21.00564776142234
            ],
            [
              105.81971406919725,
              21.0056279228118
            ],
            [
              105.81973047954092,
              21.005609218305857
            ],
            [
              105.81973959213572,
              21.005605129759907
            ],
            [
              105.8197440647303,
              21.00559454135164
            ],
            [
              105.81974472090243,
              21.005592988251703
            ],
            [
              105.81975229370974,
              21.005586290469527
            ],
            [
              105.81978071048619,
              21.00552696866916
            ],
            [
              105.81978373095063,
              21.005520662233195
            ],
            [
              105.81978671623591,
              21.005514430934554
            ],
            [
              105.81981263501773,
              21.005459866721942
            ],
            [
              105.81985701504594,
              21.005344561806144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B27_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10773",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005835854159212,
          "to_latitude": 21.005344561806144,
          "to_longitude": 105.81985701504594,
          "from_longitude": 105.81959589377804
        }
      },
      {
        "id": 10751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81970279472502,
            21.00564776142234,
            105.81972377145271,
            21.005655185185304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81972377145271,
              21.005655185185304
            ],
            [
              105.81972321827531,
              21.005654990945885
            ],
            [
              105.81971255353993,
              21.00565121533334
            ],
            [
              105.81970279472502,
              21.00564776142234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B27_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10774",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005655185185304,
          "to_latitude": 21.00564776142234,
          "to_longitude": 105.81970279472502,
          "from_longitude": 105.81972377145271
        }
      },
      {
        "id": 10752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81906626962987,
            21.006388972002974,
            105.81926262510736,
            21.006435643410143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81906626962987,
              21.006388972002974
            ],
            [
              105.8192192536658,
              21.006435643410143
            ],
            [
              105.81924578311178,
              21.006418167403826
            ],
            [
              105.81925081364439,
              21.006420086935155
            ],
            [
              105.81926262510736,
              21.00642458957298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10775",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006388972002974,
          "to_latitude": 21.00642458957298,
          "to_longitude": 105.81926262510736,
          "from_longitude": 105.81906626962987
        }
      },
      {
        "id": 10753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81924228699224,
            21.00593627600884,
            105.81954374192985,
            21.006405362909586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81954374192985,
              21.00593627600884
            ],
            [
              105.81946731405974,
              21.00607402682927
            ],
            [
              105.81942920496346,
              21.00613013910882
            ],
            [
              105.81940193277832,
              21.006170296067342
            ],
            [
              105.81924228699224,
              21.006405362909586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B26_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10776",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00593627600884,
          "to_latitude": 21.006405362909586,
          "to_longitude": 105.81924228699224,
          "from_longitude": 105.81954374192985
        }
      },
      {
        "id": 10754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81942920496346,
            21.00613013910882,
            105.8194437010612,
            21.006137997849837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81942920496346,
              21.00613013910882
            ],
            [
              105.81943138043754,
              21.00613131818591
            ],
            [
              105.81943401223924,
              21.006132746163306
            ],
            [
              105.81944196936111,
              21.00613705870135
            ],
            [
              105.8194437010612,
              21.006137997849837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B26_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10777",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00613013910882,
          "to_latitude": 21.006137997849837,
          "to_longitude": 105.8194437010612,
          "from_longitude": 105.81942920496346
        }
      },
      {
        "id": 10755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81909981076076,
            21.005741926664733,
            105.81951014265854,
            21.00588256385234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909981076076,
              21.005741926664733
            ],
            [
              105.81925641954653,
              21.00577980575304
            ],
            [
              105.81927087639606,
              21.00578330210699
            ],
            [
              105.81928401565894,
              21.005786479640168
            ],
            [
              105.81933981284406,
              21.005829589470267
            ],
            [
              105.81951014265854,
              21.00588256385234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10778",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005741926664733,
          "to_latitude": 21.00588256385234,
          "to_longitude": 105.81951014265854,
          "from_longitude": 105.81909981076076
        }
      },
      {
        "id": 10756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81925142444416,
            21.00577980575304,
            105.81925641954653,
            21.005797411134836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81925641954653,
              21.00577980575304
            ],
            [
              105.81925316372073,
              21.005791281584997
            ],
            [
              105.81925199159527,
              21.005795407754285
            ],
            [
              105.81925142444416,
              21.005797411134836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10779",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00577980575304,
          "to_latitude": 21.005797411134836,
          "to_longitude": 105.81925142444416,
          "from_longitude": 105.81925641954653
        }
      },
      {
        "id": 10757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81881611923698,
            21.005755243117868,
            105.81908176073445,
            21.006137063956135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81908176073445,
              21.005755243117868
            ],
            [
              105.81908173284076,
              21.00575524325211
            ],
            [
              105.81908029875072,
              21.005755255573224
            ],
            [
              105.81898853869544,
              21.005755968123573
            ],
            [
              105.81894498458072,
              21.005774409366612
            ],
            [
              105.8189188639833,
              21.005838945163305
            ],
            [
              105.81891382196618,
              21.00587545894234
            ],
            [
              105.8189168589448,
              21.006039802967415
            ],
            [
              105.81890718800426,
              21.006137063956135
            ],
            [
              105.81881611923698,
              21.00613229953061
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10780",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005755243117868,
          "to_latitude": 21.00613229953061,
          "to_longitude": 105.81881611923698,
          "from_longitude": 105.81908176073445
        }
      },
      {
        "id": 10758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81909020006292,
            21.00528367528798,
            105.81915580403623,
            21.00571555891146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909020006292,
              21.00571555891146
            ],
            [
              105.81909539000434,
              21.005578210723478
            ],
            [
              105.81909626974858,
              21.005554703587
            ],
            [
              105.81910375728134,
              21.005505941999925
            ],
            [
              105.81910397272532,
              21.00550452921728
            ],
            [
              105.81911912917256,
              21.00543231001197
            ],
            [
              105.81915580403623,
              21.00528367528798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10781",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00571555891146,
          "to_latitude": 21.00528367528798,
          "to_longitude": 105.81915580403623,
          "from_longitude": 105.81909020006292
        }
      },
      {
        "id": 10759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8191025573069,
            21.005459516721295,
            105.81911303661049,
            21.00546134056562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8191025573069,
              21.005459516721295
            ],
            [
              105.81910586689044,
              21.005460065002197
            ],
            [
              105.8191092196546,
              21.005460690994298
            ],
            [
              105.81911303661049,
              21.00546134056562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10782",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": 1.107,
          "from_latitude": 21.005459516721295,
          "to_latitude": 21.00546134056562,
          "to_longitude": 105.81911303661049,
          "from_longitude": 105.8191025573069
        }
      },
      {
        "id": 10760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81840761339994,
            21.005480160410755,
            105.81907719264913,
            21.0059691795723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81907719264913,
              21.005567173238042
            ],
            [
              105.81907636277558,
              21.0055670381347
            ],
            [
              105.81889912633963,
              21.005520956552513
            ],
            [
              105.81873598529295,
              21.005480160410755
            ],
            [
              105.8186531533522,
              21.00552034064677
            ],
            [
              105.81855731754003,
              21.005502457932092
            ],
            [
              105.81847917654515,
              21.005708941412855
            ],
            [
              105.81845351951942,
              21.005733290285306
            ],
            [
              105.81840761339994,
              21.0059691795723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B25_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10783",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 126,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005567173238042,
          "to_latitude": 21.0059691795723,
          "to_longitude": 105.81840761339994,
          "from_longitude": 105.81907719264913
        }
      },
      {
        "id": 10761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81847917654515,
            21.005708941412855,
            105.8185827789111,
            21.005728808238132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81847917654515,
              21.005708941412855
            ],
            [
              105.8185827789111,
              21.005728808238132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B25_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10784",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005708941412855,
          "to_latitude": 21.005728808238132,
          "to_longitude": 105.8185827789111,
          "from_longitude": 105.81847917654515
        }
      },
      {
        "id": 10762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81862095036351,
            21.005494647863095,
            105.8190762924387,
            21.00603018158703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8190762924387,
              21.005576885467725
            ],
            [
              105.81907550299668,
              21.005576932621924
            ],
            [
              105.81889218294087,
              21.00553072043909
            ],
            [
              105.81887777805353,
              21.00552722199547
            ],
            [
              105.81874337649958,
              21.005494647863095
            ],
            [
              105.81868423204678,
              21.00551814443783
            ],
            [
              105.81862095036351,
              21.00585160369221
            ],
            [
              105.81868484700675,
              21.005854624737328
            ],
            [
              105.81866475979733,
              21.00603018158703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10785",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005576885467725,
          "to_latitude": 21.00603018158703,
          "to_longitude": 105.81866475979733,
          "from_longitude": 105.8190762924387
        }
      },
      {
        "id": 10763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81879519326425,
            21.00553072043909,
            105.81889218294087,
            21.005684452273325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81889218294087,
              21.00553072043909
            ],
            [
              105.81886476987215,
              21.00561540263195
            ],
            [
              105.81879519326425,
              21.005684452273325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10786",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00553072043909,
          "to_latitude": 21.005684452273325,
          "to_longitude": 105.81879519326425,
          "from_longitude": 105.81889218294087
        }
      },
      {
        "id": 10764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81897491152532,
            21.004835671818018,
            105.81912361291099,
            21.005204046503664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81897491152532,
              21.005204046503664
            ],
            [
              105.81897588523125,
              21.00520145587561
            ],
            [
              105.81906588927069,
              21.00496206106486
            ],
            [
              105.81907209097945,
              21.004944356847787
            ],
            [
              105.81908869636912,
              21.00492490630011
            ],
            [
              105.81912361291099,
              21.004835671818018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10787",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005204046503664,
          "to_latitude": 21.004835671818018,
          "to_longitude": 105.81912361291099,
          "from_longitude": 105.81897491152532
        }
      },
      {
        "id": 10765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81906588927069,
            21.00496206106486,
            105.81918682465022,
            21.0049908393728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81906588927069,
              21.00496206106486
            ],
            [
              105.81914727766575,
              21.004984030595903
            ],
            [
              105.81918682465022,
              21.0049908393728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10788",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00496206106486,
          "to_latitude": 21.0049908393728,
          "to_longitude": 105.81918682465022,
          "from_longitude": 105.81906588927069
        }
      },
      {
        "id": 10766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81869472702384,
            21.00499690574599,
            105.81912854434209,
            21.005266189921723
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.8187704389193,
                21.00512336218147
              ],
              [
                105.81875879844341,
                21.0051177386798
              ],
              [
                105.81872788575936,
                21.005111384141788
              ],
              [
                105.81869472702384,
                21.005111362097534
              ]
            ],
            [
              [
                105.8187704389193,
                21.00512336218147
              ],
              [
                105.8187719319113,
                21.00512004286075
              ],
              [
                105.81877355331868,
                21.00511646008279
              ],
              [
                105.81877361129159,
                21.005116331545477
              ],
              [
                105.8188273160553,
                21.00499690574599
              ]
            ],
            [
              [
                105.81885837026002,
                21.005165825333705
              ],
              [
                105.81879779546011,
                21.005136573064824
              ],
              [
                105.8187704389193,
                21.00512336218147
              ]
            ],
            [
              [
                105.81885837026002,
                21.005165825333705
              ],
              [
                105.81882604916314,
                21.005226599198036
              ]
            ],
            [
              [
                105.81912854434209,
                21.005266189921723
              ],
              [
                105.81890554435537,
                21.005184339447368
              ],
              [
                105.8188687145561,
                21.00517082009508
              ],
              [
                105.81885837026002,
                21.005165825333705
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10789",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 134,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81875879844341,
            21.0051177386798
          ],
          "to_latitude": [
            105.81890554435537,
            21.005184339447368
          ],
          "to_longitude": [
            105.81912854434209,
            21.005266189921723
          ],
          "from_longitude": [
            105.8187704389193,
            21.00512336218147
          ]
        }
      },
      {
        "id": 10767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81879779546011,
            21.005128079882425,
            105.81880232763415,
            21.005136573064824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81880232763415,
              21.005128079882425
            ],
            [
              105.8188016238316,
              21.005129366755828
            ],
            [
              105.81879997861799,
              21.005132464614917
            ],
            [
              105.81879779546011,
              21.005136573064824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B28_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10790",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005128079882425,
          "to_latitude": 21.005136573064824,
          "to_longitude": 105.81879779546011,
          "from_longitude": 105.81880232763415
        }
      },
      {
        "id": 10768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81856973946212,
            21.005095794014895,
            105.81867927935706,
            21.005361216078768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81866506645882,
              21.005095794014895
            ],
            [
              105.81866505601157,
              21.005095818452304
            ],
            [
              105.81866440202475,
              21.005097421215478
            ],
            [
              105.81856973946212,
              21.00532951034402
            ],
            [
              105.81867927935706,
              21.005361216078768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B10_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10791",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005095794014895,
          "to_latitude": 21.005361216078768,
          "to_longitude": 105.81867927935706,
          "from_longitude": 105.81866506645882
        }
      },
      {
        "id": 10769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81882765357537,
            21.008996155431,
            105.81957312874711,
            21.00927402367196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81957312874711,
              21.00902861097343
            ],
            [
              105.81956670179774,
              21.00903661742798
            ],
            [
              105.81937612862235,
              21.00927402367196
            ],
            [
              105.81914393088842,
              21.009132853352877
            ],
            [
              105.81887629061676,
              21.009006144128982
            ],
            [
              105.81882765357537,
              21.008996155431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A78_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10792",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 109,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00902861097343,
          "to_latitude": 21.008996155431,
          "to_longitude": 105.81882765357537,
          "from_longitude": 105.81957312874711
        }
      },
      {
        "id": 10770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81797858608526,
            21.008561331283357,
            105.81890452368145,
            21.00938497470167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81890452368145,
              21.00938497470167
            ],
            [
              105.81890318551619,
              21.009383887332298
            ],
            [
              105.818431947027,
              21.00900013643323
            ],
            [
              105.81822576269609,
              21.00893138427737
            ],
            [
              105.81808924389425,
              21.00888010050041
            ],
            [
              105.81797858608526,
              21.008814231624314
            ],
            [
              105.81808871117678,
              21.008561331283357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10793",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 133,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00938497470167,
          "to_latitude": 21.008561331283357,
          "to_longitude": 105.81808871117678,
          "from_longitude": 105.81890452368145
        }
      },
      {
        "id": 10771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81827470646813,
            21.008656500769074,
            105.81905817082796,
            21.009217938343657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81905817082796,
              21.009217938343657
            ],
            [
              105.8190495981538,
              21.00921155133201
            ],
            [
              105.81866121573623,
              21.008922137315736
            ],
            [
              105.8186133070659,
              21.008914296540286
            ],
            [
              105.81855517984799,
              21.008862667635903
            ],
            [
              105.81851687043697,
              21.008828640336688
            ],
            [
              105.81836272522524,
              21.008784294349866
            ],
            [
              105.81837296555841,
              21.00870898543313
            ],
            [
              105.81827470646813,
              21.008656500769074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10794",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 113,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009217938343657,
          "to_latitude": 21.008656500769074,
          "to_longitude": 105.81827470646813,
          "from_longitude": 105.81905817082796
        }
      },
      {
        "id": 10772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81855517984799,
            21.0086996216548,
            105.81859756989795,
            21.008862667635903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81855517984799,
              21.008862667635903
            ],
            [
              105.81859756989795,
              21.0086996216548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A77_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10795",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008862667635903,
          "to_latitude": 21.0086996216548,
          "to_longitude": 105.81859756989795,
          "from_longitude": 105.81855517984799
        }
      },
      {
        "id": 10773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81907090145302,
            21.008664261583544,
            105.81952925841414,
            21.00900886182118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81907090145302,
              21.008664261583544
            ],
            [
              105.81910933876908,
              21.008693159612438
            ],
            [
              105.819295193384,
              21.008832888480224
            ],
            [
              105.81952925841414,
              21.00900886182118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10796",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008664261583544,
          "to_latitude": 21.00900886182118,
          "to_longitude": 105.81952925841414,
          "from_longitude": 105.81907090145302
        }
      },
      {
        "id": 10774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81910933876908,
            21.00868917383566,
            105.81911305685854,
            21.008693159612438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81910933876908,
              21.008693159612438
            ],
            [
              105.81911217036763,
              21.008690123782316
            ],
            [
              105.81911305685854,
              21.00868917383566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A73_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10797",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008693159612438,
          "to_latitude": 21.00868917383566,
          "to_longitude": 105.81911305685854,
          "from_longitude": 105.81910933876908
        }
      },
      {
        "id": 10775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81854054322879,
            21.008192106173926,
            105.81905995591998,
            21.008642738859304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8185481046885,
              21.008216349873507
            ],
            [
              105.81854683285594,
              21.00821525314947
            ],
            [
              105.81854054322879,
              21.0082098206787
            ],
            [
              105.81855921599156,
              21.008192106173926
            ],
            [
              105.8187083554964,
              21.00830795122802
            ],
            [
              105.81880771334421,
              21.008392408243882
            ],
            [
              105.81886408875927,
              21.008463056639798
            ],
            [
              105.81888319662161,
              21.008486999591344
            ],
            [
              105.81893516662502,
              21.008552359082408
            ],
            [
              105.81905995591998,
              21.008642738859304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10798",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008216349873507,
          "to_latitude": 21.008642738859304,
          "to_longitude": 105.81905995591998,
          "from_longitude": 105.8185481046885
        }
      },
      {
        "id": 10776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8184156946828,
            21.008226003059665,
            105.81852267297907,
            21.008263297922266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8184156946828,
              21.008263297922266
            ],
            [
              105.8184215142755,
              21.008262442577735
            ],
            [
              105.81848570949217,
              21.00825300220086
            ],
            [
              105.81850682459057,
              21.0082420031977
            ],
            [
              105.81852267297907,
              21.008226003059665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75A_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10799",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008263297922266,
          "to_latitude": 21.008226003059665,
          "to_longitude": 105.81852267297907,
          "from_longitude": 105.8184156946828
        }
      },
      {
        "id": 10777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81812817955661,
            21.00808851296749,
            105.81840106582196,
            21.008259520522834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81812817955661,
              21.00808851296749
            ],
            [
              105.81821152406643,
              21.00814699100451
            ],
            [
              105.81826579592352,
              21.008176273747694
            ],
            [
              105.81832006683454,
              21.008205555574193
            ],
            [
              105.81839157497296,
              21.008253197503674
            ],
            [
              105.81840106582196,
              21.008259520522834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74A_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10800",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00808851296749,
          "to_latitude": 21.008259520522834,
          "to_longitude": 105.81840106582196,
          "from_longitude": 105.81812817955661
        }
      },
      {
        "id": 10778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81838847566043,
            21.008253197503674,
            105.81839157497296,
            21.0082605872658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81839157497296,
              21.008253197503674
            ],
            [
              105.81838911944182,
              21.00825905048852
            ],
            [
              105.81838847566043,
              21.0082605872658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74A_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10801",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008253197503674,
          "to_latitude": 21.0082605872658,
          "to_longitude": 105.81838847566043,
          "from_longitude": 105.81839157497296
        }
      },
      {
        "id": 10779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81784619235673,
            21.007947304641608,
            105.81811610805474,
            21.008082557307244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81784619235673,
              21.007947304641608
            ],
            [
              105.81794824815289,
              21.008006630441162
            ],
            [
              105.81798349185752,
              21.008012458477733
            ],
            [
              105.81801022188436,
              21.00801687774906
            ],
            [
              105.81803884277456,
              21.008021610379394
            ],
            [
              105.81811610805474,
              21.008082557307244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10802",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007947304641608,
          "to_latitude": 21.008082557307244,
          "to_longitude": 105.81811610805474,
          "from_longitude": 105.81784619235673
        }
      },
      {
        "id": 10780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81798349185752,
            21.008010492748085,
            105.81798505864275,
            21.008012458477733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81798505864275,
              21.008010492748085
            ],
            [
              105.81798409280405,
              21.00801170952273
            ],
            [
              105.81798349185752,
              21.008012458477733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A74_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10803",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008010492748085,
          "to_latitude": 21.008012458477733,
          "to_longitude": 105.81798349185752,
          "from_longitude": 105.81798505864275
        }
      },
      {
        "id": 10781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81820439534214,
            21.00745388318822,
            105.81867980626785,
            21.00792731057475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81867980626785,
              21.00745388318822
            ],
            [
              105.81867096238685,
              21.0074725186834
            ],
            [
              105.81866899291926,
              21.00747666132952
            ],
            [
              105.81853480163424,
              21.0077594490852
            ],
            [
              105.81848106405711,
              21.007862487678647
            ],
            [
              105.81834309841575,
              21.007828823078622
            ],
            [
              105.81829361338934,
              21.00792731057475
            ],
            [
              105.81820439534214,
              21.007881296458716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A56_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10804",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00745388318822,
          "to_latitude": 21.007881296458716,
          "to_longitude": 105.81820439534214,
          "from_longitude": 105.81867980626785
        }
      },
      {
        "id": 10782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81839025343747,
            21.007455749522908,
            105.8186844450507,
            21.00810895020116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8186844450507,
              21.007455749522908
            ],
            [
              105.8186755830594,
              21.00747494420365
            ],
            [
              105.81867400113259,
              21.007478363306266
            ],
            [
              105.81850300877497,
              21.00784876326062
            ],
            [
              105.81847905083632,
              21.007878361659994
            ],
            [
              105.81846925096073,
              21.007980971248365
            ],
            [
              105.8184643394232,
              21.00799064766893
            ],
            [
              105.8184440804268,
              21.008030566622942
            ],
            [
              105.81843460443405,
              21.00805782736195
            ],
            [
              105.81839025343747,
              21.00810895020116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A56A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10805",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007455749522908,
          "to_latitude": 21.00810895020116,
          "to_longitude": 105.81839025343747,
          "from_longitude": 105.8186844450507
        }
      },
      {
        "id": 10783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8184643394232,
            21.00799064766893,
            105.8186366373363,
            21.008045699843738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8184643394232,
              21.00799064766893
            ],
            [
              105.8186366373363,
              21.008045699843738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A56A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10806",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00799064766893,
          "to_latitude": 21.008045699843738,
          "to_longitude": 105.8186366373363,
          "from_longitude": 105.8184643394232
        }
      },
      {
        "id": 10784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81870777413032,
            21.00706002472448,
            105.81887476400794,
            21.007446661012974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81870777413032,
              21.007446661012974
            ],
            [
              105.81874928215451,
              21.007402006241332
            ],
            [
              105.81877932406074,
              21.00732977597398
            ],
            [
              105.8187956418495,
              21.007283653605526
            ],
            [
              105.8188017281026,
              21.007266451246544
            ],
            [
              105.81881278567054,
              21.007235199642338
            ],
            [
              105.81887476400794,
              21.00706002472448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10807",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007446661012974,
          "to_latitude": 21.00706002472448,
          "to_longitude": 105.81887476400794,
          "from_longitude": 105.81870777413032
        }
      },
      {
        "id": 10785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81876558227323,
            21.00727560323767,
            105.8187956418495,
            21.007283653605526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81876558227323,
              21.00727560323767
            ],
            [
              105.81877131676038,
              21.00727713913653
            ],
            [
              105.81878595112055,
              21.00728105919057
            ],
            [
              105.8187956418495,
              21.007283653605526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A57_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10808",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00727560323767,
          "to_latitude": 21.007283653605526,
          "to_longitude": 105.8187956418495,
          "from_longitude": 105.81876558227323
        }
      },
      {
        "id": 10786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81840308140443,
            21.007115683628058,
            105.81875985736511,
            21.007289750042695
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81854308915254,
                21.007220892856758
              ],
              [
                105.81840308140443,
                21.00717265458609
              ]
            ],
            [
              [
                105.81854308915254,
                21.007220892856758
              ],
              [
                105.81858183712194,
                21.007115683628058
              ]
            ],
            [
              [
                105.81875985736511,
                21.007289750042695
              ],
              [
                105.81875656843253,
                21.007288823798863
              ],
              [
                105.8187516898416,
                21.007287607137478
              ],
              [
                105.81874539299034,
                21.007289130467495
              ],
              [
                105.81874361200184,
                21.007289560843258
              ],
              [
                105.81873828963627,
                21.007282991980052
              ],
              [
                105.81854308915254,
                21.007220892856758
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A50_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10809",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81840308140443,
            21.00717265458609
          ],
          "to_latitude": [
            105.81875656843253,
            21.007288823798863
          ],
          "to_longitude": [
            105.81875985736511,
            21.007289750042695
          ],
          "from_longitude": [
            105.81854308915254,
            21.007220892856758
          ]
        }
      },
      {
        "id": 10787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81869804685488,
            21.007054741806577,
            105.81883795752631,
            21.007413392238465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81869804685488,
              21.007413392238465
            ],
            [
              105.8187432903543,
              21.00729460421016
            ],
            [
              105.81874488444052,
              21.007290417304514
            ],
            [
              105.81874539299034,
              21.007289130467495
            ],
            [
              105.81876286472753,
              21.007244888490593
            ],
            [
              105.81876688185298,
              21.0072347168797
            ],
            [
              105.81883795752631,
              21.007054741806577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10810",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007413392238465,
          "to_latitude": 21.007054741806577,
          "to_longitude": 105.81883795752631,
          "from_longitude": 105.81869804685488
        }
      },
      {
        "id": 10788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81876286472753,
            21.007244888490593,
            105.8187764038013,
            21.007248864395788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81876286472753,
              21.007244888490593
            ],
            [
              105.8187672136474,
              21.00724616550657
            ],
            [
              105.8187740270229,
              21.007248166796636
            ],
            [
              105.8187764038013,
              21.007248864395788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10811",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007244888490593,
          "to_latitude": 21.007248864395788,
          "to_longitude": 105.8187764038013,
          "from_longitude": 105.81876286472753
        }
      },
      {
        "id": 10789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81874919151072,
            21.007218791572196,
            105.8191271774841,
            21.007955520211407
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81878857397358,
                21.007218791572196
              ],
              [
                105.8188081033094,
                21.00722481698252
              ],
              [
                105.81881177137562,
                21.007225947337275
              ],
              [
                105.81881278567054,
                21.007235199642338
              ],
              [
                105.81882010007257,
                21.007231715021955
              ],
              [
                105.81886615127205,
                21.007261321651967
              ],
              [
                105.8191271774841,
                21.00742911816788
              ],
              [
                105.81901683520437,
                21.007574088046727
              ]
            ],
            [
              [
                105.81901683520437,
                21.007574088046727
              ],
              [
                105.81881456970903,
                21.007428531554815
              ]
            ],
            [
              [
                105.81901683520437,
                21.007574088046727
              ],
              [
                105.8190042496665,
                21.007590618075852
              ],
              [
                105.81877289375026,
                21.007894580991554
              ],
              [
                105.81874919151072,
                21.007955520211407
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A59_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10812",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 141,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8188081033094,
            21.00722481698252
          ],
          "to_latitude": [
            105.8190042496665,
            21.007590618075852
          ],
          "to_longitude": [
            105.81901683520437,
            21.007574088046727
          ],
          "from_longitude": [
            105.81878857397358,
            21.007218791572196
          ]
        }
      },
      {
        "id": 10790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81842682630769,
            21.006835582076388,
            105.81888438957195,
            21.00699392172221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81888438957195,
              21.00699392172221
            ],
            [
              105.81886325130799,
              21.006987418124474
            ],
            [
              105.8185024499335,
              21.00687638752928
            ],
            [
              105.81842682630769,
              21.006835582076388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A55_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10813",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00699392172221,
          "to_latitude": 21.006835582076388,
          "to_longitude": 105.81842682630769,
          "from_longitude": 105.81888438957195
        }
      },
      {
        "id": 10791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81849918189671,
            21.006670428319655,
            105.81892334041717,
            21.006935812986686
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81869354811433,
                21.006670428319655
              ],
              [
                105.8186474330397,
                21.006845242357816
              ]
            ],
            [
              [
                105.8186474330397,
                21.006845242357816
              ],
              [
                105.8186361711403,
                21.00686971982097
              ],
              [
                105.81849918189671,
                21.0068288536999
              ]
            ],
            [
              [
                105.8186474330397,
                21.006845242357816
              ],
              [
                105.81889343671905,
                21.006925997884682
              ],
              [
                105.81892334041717,
                21.006935812986686
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A54_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10814",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8186474330397,
            21.006845242357816
          ],
          "to_latitude": [
            105.81889343671905,
            21.006925997884682
          ],
          "to_longitude": [
            105.8186474330397,
            21.006845242357816
          ],
          "from_longitude": [
            105.81869354811433,
            21.006670428319655
          ]
        }
      },
      {
        "id": 10792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81889948082663,
            21.00658042409971,
            105.81913265421966,
            21.006892792328575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81913265421966,
              21.00658042409971
            ],
            [
              105.81913182536297,
              21.006581533640315
            ],
            [
              105.81889948082663,
              21.006892792328575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A54_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10815",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00658042409971,
          "to_latitude": 21.006892792328575,
          "to_longitude": 105.81889948082663,
          "from_longitude": 105.81913265421966
        }
      },
      {
        "id": 10793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81861818404433,
            21.00642292695024,
            105.81903946144507,
            21.00652615505659
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81861818404433,
              21.00642292695024
            ],
            [
              105.81866060476301,
              21.00645146633229
            ],
            [
              105.81871721132597,
              21.00647771551959
            ],
            [
              105.81875757232042,
              21.00649137777736
            ],
            [
              105.81878224577905,
              21.006494773535913
            ],
            [
              105.81881906729478,
              21.00649984052622
            ],
            [
              105.81885482137399,
              21.0064933188072
            ],
            [
              105.81888978013788,
              21.00649705074111
            ],
            [
              105.81895430211577,
              21.006501451510438
            ],
            [
              105.81898132168075,
              21.006505565758758
            ],
            [
              105.81901010527618,
              21.006510902613634
            ],
            [
              105.81903946144507,
              21.00652615505659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A52_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10816",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00642292695024,
          "to_latitude": 21.00652615505659,
          "to_longitude": 105.81903946144507,
          "from_longitude": 105.81861818404433
        }
      },
      {
        "id": 10794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81878019584367,
            21.006494773535913,
            105.81878224577905,
            21.00650038431631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81878224577905,
              21.006494773535913
            ],
            [
              105.81878191500464,
              21.006495675645798
            ],
            [
              105.81878026123597,
              21.006500205162524
            ],
            [
              105.81878019584367,
              21.00650038431631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A52_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10817",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006494773535913,
          "to_latitude": 21.00650038431631,
          "to_longitude": 105.81878019584367,
          "from_longitude": 105.81878224577905
        }
      },
      {
        "id": 10795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81841123243512,
            21.006388685526396,
            105.81864831523285,
            21.006503912383568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81864831523285,
              21.00644695872601
            ],
            [
              105.8186480366326,
              21.006447374647905
            ],
            [
              105.81861025938053,
              21.006503912383568
            ],
            [
              105.81841123243512,
              21.006388685526396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A51_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10818",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00644695872601,
          "to_latitude": 21.006388685526396,
          "to_longitude": 105.81841123243512,
          "from_longitude": 105.81864831523285
        }
      },
      {
        "id": 10796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81831827320934,
            21.007164813337805,
            105.81864082642883,
            21.00742913889323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81833308745684,
              21.007164813337805
            ],
            [
              105.81831827320934,
              21.00726707219926
            ],
            [
              105.81832824185781,
              21.00730899005456
            ],
            [
              105.81844277993525,
              21.007352822087093
            ],
            [
              105.81845669284517,
              21.007358146545915
            ],
            [
              105.81862883552648,
              21.007424021979237
            ],
            [
              105.81864082642883,
              21.00742913889323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10819",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007164813337805,
          "to_latitude": 21.00742913889323,
          "to_longitude": 105.81864082642883,
          "from_longitude": 105.81833308745684
        }
      },
      {
        "id": 10797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81843985539768,
            21.007352822087093,
            105.81844277993525,
            21.007361919014297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81844277993525,
              21.007352822087093
            ],
            [
              105.81844136874003,
              21.00735721133691
            ],
            [
              105.81844003225585,
              21.007361366291335
            ],
            [
              105.81843985539768,
              21.007361919014297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A32_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10820",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007352822087093,
          "to_latitude": 21.007361919014297,
          "to_longitude": 105.81843985539768,
          "from_longitude": 105.81844277993525
        }
      },
      {
        "id": 10798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81829538953424,
            21.006622905701835,
            105.81840982021266,
            21.007134326810405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81831746852032,
              21.007134326810405
            ],
            [
              105.81831765153895,
              21.007133845412948
            ],
            [
              105.81840982021266,
              21.00689272620493
            ],
            [
              105.8183251802176,
              21.006856387173706
            ],
            [
              105.8183331995947,
              21.00678780081427
            ],
            [
              105.81829538953424,
              21.006718056490612
            ],
            [
              105.81833112423271,
              21.00667357048217
            ],
            [
              105.81833732237622,
              21.006622905701835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10821",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007134326810405,
          "to_latitude": 21.006622905701835,
          "to_longitude": 105.81833732237622,
          "from_longitude": 105.81831746852032
        }
      },
      {
        "id": 10799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81797054998263,
            21.00703800911178,
            105.81825895921456,
            21.007209279812223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81797054998263,
              21.007209279812223
            ],
            [
              105.81805977732012,
              21.007062114270465
            ],
            [
              105.81807439256329,
              21.00703800911178
            ],
            [
              105.81808195956881,
              21.00704063003499
            ],
            [
              105.81809310769313,
              21.007044490133747
            ],
            [
              105.81825895921456,
              21.007101913080515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10822",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007209279812223,
          "to_latitude": 21.007101913080515,
          "to_longitude": 105.81825895921456,
          "from_longitude": 105.81797054998263
        }
      },
      {
        "id": 10800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81808195956881,
            21.006961484116538,
            105.8181104442548,
            21.00704063003499
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81808195956881,
              21.00704063003499
            ],
            [
              105.8181104442548,
              21.006961484116538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10823",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00704063003499,
          "to_latitude": 21.006961484116538,
          "to_longitude": 105.8181104442548,
          "from_longitude": 105.81808195956881
        }
      },
      {
        "id": 10801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81805977732012,
            21.007062114270465,
            105.81807578833654,
            21.007069959522042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81805977732012,
              21.007062114270465
            ],
            [
              105.81807457794324,
              21.007069367403243
            ],
            [
              105.81807578833654,
              21.007069959522042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A42_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10824",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007062114270465,
          "to_latitude": 21.007069959522042,
          "to_longitude": 105.81807578833654,
          "from_longitude": 105.81805977732012
        }
      },
      {
        "id": 10802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81759470169217,
            21.007238694960808,
            105.81796891832893,
            21.007318714552753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81759470169217,
              21.007318714552753
            ],
            [
              105.8176677505488,
              21.007269874687715
            ],
            [
              105.81773217319035,
              21.007266579980644
            ],
            [
              105.81775902312918,
              21.007267082321615
            ],
            [
              105.81778184950616,
              21.00726882154448
            ],
            [
              105.81778673170308,
              21.007271936802464
            ],
            [
              105.81778678479242,
              21.007271970870026
            ],
            [
              105.8177946655331,
              21.00726914834764
            ],
            [
              105.8178997223256,
              21.007270663098975
            ],
            [
              105.81796891832893,
              21.007238694960808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10825",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007318714552753,
          "to_latitude": 21.007238694960808,
          "to_longitude": 105.81796891832893,
          "from_longitude": 105.81759470169217
        }
      },
      {
        "id": 10803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81773217319035,
            21.00726133093342,
            105.81773244180928,
            21.007266579980644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81773217319035,
              21.007266579980644
            ],
            [
              105.81773223392037,
              21.00726536845965
            ],
            [
              105.81773237921458,
              21.00726255330209
            ],
            [
              105.81773244180928,
              21.00726133093342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A41_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10826",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007266579980644,
          "to_latitude": 21.00726133093342,
          "to_longitude": 105.81773244180928,
          "from_longitude": 105.81773217319035
        }
      },
      {
        "id": 10804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81770364748301,
            21.007261584458128,
            105.8177887039034,
            21.00742173811858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8177887039034,
              21.007261584458128
            ],
            [
              105.81778748914772,
              21.007267958054946
            ],
            [
              105.81778673170308,
              21.007271936802464
            ],
            [
              105.81778322494527,
              21.007290344284943
            ],
            [
              105.81770364748301,
              21.00742173811858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A40_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10827",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007261584458128,
          "to_latitude": 21.00742173811858,
          "to_longitude": 105.81770364748301,
          "from_longitude": 105.8177887039034
        }
      },
      {
        "id": 10805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81735012953773,
            21.007085424806668,
            105.81769365184564,
            21.007261156926017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81769365184564,
              21.007261156926017
            ],
            [
              105.8176915521843,
              21.007254120930224
            ],
            [
              105.8176902656964,
              21.007249802454268
            ],
            [
              105.81768901169943,
              21.007229734229906
            ],
            [
              105.8173915746737,
              21.007095033909984
            ],
            [
              105.81735012953773,
              21.007085424806668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A39_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10828",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007261156926017,
          "to_latitude": 21.007085424806668,
          "to_longitude": 105.81735012953773,
          "from_longitude": 105.81769365184564
        }
      },
      {
        "id": 10806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81751747121612,
            21.007368457201803,
            105.81754598621025,
            21.00741200156712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81753621682351,
              21.007368457201803
            ],
            [
              105.8175384088559,
              21.00737037958024
            ],
            [
              105.81754598621025,
              21.007377017133653
            ],
            [
              105.81751747121612,
              21.00741200156712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10829",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007368457201803,
          "to_latitude": 21.00741200156712,
          "to_longitude": 105.81751747121612,
          "from_longitude": 105.81753621682351
        }
      },
      {
        "id": 10807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81733446672007,
            21.007334163798422,
            105.81762412494852,
            21.007587019964898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81733446672007,
              21.007417704718865
            ],
            [
              105.81739981243068,
              21.007334163798422
            ],
            [
              105.81749952597868,
              21.007376285605663
            ],
            [
              105.81751747121612,
              21.00741200156712
            ],
            [
              105.81762412494852,
              21.007500391249103
            ],
            [
              105.81758303916477,
              21.00755552478864
            ],
            [
              105.81748986336825,
              21.007587019964898
            ],
            [
              105.81735854963561,
              21.007485720472967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A44_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10830",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007417704718865,
          "to_latitude": 21.007485720472967,
          "to_longitude": 105.81735854963561,
          "from_longitude": 105.81733446672007
        }
      },
      {
        "id": 10808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81799548417303,
            21.00734048734486,
            105.81827848017534,
            21.00765768956864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81799548417303,
              21.00765768956864
            ],
            [
              105.8181039090545,
              21.00749605052918
            ],
            [
              105.81812704486123,
              21.00746156063627
            ],
            [
              105.81812786731876,
              21.007460333711407
            ],
            [
              105.8181298919871,
              21.007457315324892
            ],
            [
              105.81823874058597,
              21.00736708519656
            ],
            [
              105.81823962997244,
              21.00736649020917
            ],
            [
              105.81827507692897,
              21.00734276358531
            ],
            [
              105.81827848017534,
              21.00734048734486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A72_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10831",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00765768956864,
          "to_latitude": 21.00734048734486,
          "to_longitude": 105.81827848017534,
          "from_longitude": 105.81799548417303
        }
      },
      {
        "id": 10809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81823962997244,
            21.00736649020917,
            105.81824721258916,
            21.007374969387516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81824721258916,
              21.007374969387516
            ],
            [
              105.81824229378444,
              21.00736955289365
            ],
            [
              105.81823962997244,
              21.00736649020917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A72_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10832",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007374969387516,
          "to_latitude": 21.00736649020917,
          "to_longitude": 105.81823962997244,
          "from_longitude": 105.81824721258916
        }
      },
      {
        "id": 10810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81781392901186,
            21.007387876532476,
            105.8181414073382,
            21.00751019091184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8181414073382,
              21.00746659934595
            ],
            [
              105.8181369729122,
              21.007462342071967
            ],
            [
              105.81813435633528,
              21.007459827418298
            ],
            [
              105.81812794592888,
              21.007460461591897
            ],
            [
              105.81812786731876,
              21.007460333711407
            ],
            [
              105.81812462788037,
              21.007455077141316
            ],
            [
              105.81801573566682,
              21.007387876532476
            ],
            [
              105.81792738936228,
              21.00751019091184
            ],
            [
              105.8178293471045,
              21.007450723863553
            ],
            [
              105.81781392901186,
              21.00744136915584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10833",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00746659934595,
          "to_latitude": 21.00744136915584,
          "to_longitude": 105.81781392901186,
          "from_longitude": 105.8181414073382
        }
      },
      {
        "id": 10811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81805744555237,
            21.007349614197757,
            105.81829506669645,
            21.007599531837034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81805744555237,
              21.007599531837034
            ],
            [
              105.81812454972916,
              21.007503551066154
            ],
            [
              105.81816722910854,
              21.007460760482843
            ],
            [
              105.8181890734085,
              21.007438856050292
            ],
            [
              105.81827955822426,
              21.007362671768846
            ],
            [
              105.81829506669645,
              21.007349614197757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10834",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007599531837034,
          "to_latitude": 21.007349614197757,
          "to_longitude": 105.81829506669645,
          "from_longitude": 105.81805744555237
        }
      },
      {
        "id": 10812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8182716474656,
            21.007355754948073,
            105.81827955822426,
            21.007362671768846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8182716474656,
              21.007355754948073
            ],
            [
              105.81827170739157,
              21.007355807950404
            ],
            [
              105.81827649444682,
              21.00735999488006
            ],
            [
              105.81827955822426,
              21.007362671768846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A45A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10835",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007355754948073,
          "to_latitude": 21.007362671768846,
          "to_longitude": 105.81827955822426,
          "from_longitude": 105.8182716474656
        }
      },
      {
        "id": 10813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81770755658175,
            21.007540532695533,
            105.81798263393651,
            21.007692736976335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81798263393651,
              21.007692736976335
            ],
            [
              105.81798194930002,
              21.007692425943645
            ],
            [
              105.81780075508732,
              21.00760976259996
            ],
            [
              105.81770755658175,
              21.007540532695533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10836",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007692736976335,
          "to_latitude": 21.007540532695533,
          "to_longitude": 105.81770755658175,
          "from_longitude": 105.81798263393651
        }
      },
      {
        "id": 10814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81784943461255,
            21.007615483680798,
            105.81822011104732,
            21.00789501472646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81784943461255,
              21.00789501472646
            ],
            [
              105.81789903046865,
              21.007820337721217
            ],
            [
              105.81797391640669,
              21.007717277136422
            ],
            [
              105.8179922126858,
              21.007690314542018
            ],
            [
              105.81804299165127,
              21.007615483680798
            ],
            [
              105.81814947532483,
              21.007668189999666
            ],
            [
              105.81817105351672,
              21.007666805483353
            ],
            [
              105.81822011104732,
              21.00769013755864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10837",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00789501472646,
          "to_latitude": 21.00769013755864,
          "to_longitude": 105.81822011104732,
          "from_longitude": 105.81784943461255
        }
      },
      {
        "id": 10815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81798642839068,
            21.00768723072343,
            105.8179922126858,
            21.007690314542018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81798642839068,
              21.00768723072343
            ],
            [
              105.81798731494283,
              21.007687703366653
            ],
            [
              105.81799127501203,
              21.00768981595091
            ],
            [
              105.8179922126858,
              21.007690314542018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10838",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00768723072343,
          "to_latitude": 21.007690314542018,
          "to_longitude": 105.8179922126858,
          "from_longitude": 105.81798642839068
        }
      },
      {
        "id": 10816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81774499260153,
            21.007700532703907,
            105.81796384938289,
            21.008026866780806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81796384938289,
              21.007700532703907
            ],
            [
              105.81788534658425,
              21.007808682293575
            ],
            [
              105.81783666229022,
              21.007884362013794
            ],
            [
              105.81781815649023,
              21.00791313024917
            ],
            [
              105.81774499260153,
              21.008026866780806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A71_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10839",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007700532703907,
          "to_latitude": 21.008026866780806,
          "to_longitude": 105.81774499260153,
          "from_longitude": 105.81796384938289
        }
      },
      {
        "id": 10817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81781815649023,
            21.00791313024917,
            105.81782308528831,
            21.00791490850355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81781815649023,
              21.00791313024917
            ],
            [
              105.81782238076701,
              21.007914655372403
            ],
            [
              105.81782308528831,
              21.00791490850355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A71_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10840",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00791313024917,
          "to_latitude": 21.00791490850355,
          "to_longitude": 105.81782308528831,
          "from_longitude": 105.81781815649023
        }
      },
      {
        "id": 10818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.817220224825,
            21.007571100362437,
            105.81774401162976,
            21.00805672499406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81774401162976,
              21.00805672499406
            ],
            [
              105.81774356705776,
              21.008056515774907
            ],
            [
              105.81754430612904,
              21.00796199378918
            ],
            [
              105.81750060712343,
              21.00793633435271
            ],
            [
              105.81755704994086,
              21.00783712448402
            ],
            [
              105.81755809350639,
              21.00782210239721
            ],
            [
              105.81740082573558,
              21.007709484630414
            ],
            [
              105.81739330714129,
              21.00768432338235
            ],
            [
              105.81736157294937,
              21.007663566968205
            ],
            [
              105.817220224825,
              21.007571100362437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10841",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00805672499406,
          "to_latitude": 21.007571100362437,
          "to_longitude": 105.817220224825,
          "from_longitude": 105.81774401162976
        }
      },
      {
        "id": 10819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81723507196148,
            21.007779405653192,
            105.81774271541147,
            21.00806797503571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81774271541147,
              21.008059094970918
            ],
            [
              105.81774228163162,
              21.00805892453873
            ],
            [
              105.81772699982413,
              21.008052878598725
            ],
            [
              105.81771837793929,
              21.00806797503571
            ],
            [
              105.81747981762142,
              21.007944072820546
            ],
            [
              105.81749944440936,
              21.007909359606003
            ],
            [
              105.81723507196148,
              21.007779405653192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10842",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008059094970918,
          "to_latitude": 21.007779405653192,
          "to_longitude": 105.81723507196148,
          "from_longitude": 105.81774271541147
        }
      },
      {
        "id": 10820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81762321220621,
            21.00801479706793,
            105.81777635660904,
            21.008396308649356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81762321220621,
              21.008396308649356
            ],
            [
              105.81767319771913,
              21.008240399811015
            ],
            [
              105.81768645786073,
              21.00820906362722
            ],
            [
              105.81770199528266,
              21.00817234500196
            ],
            [
              105.81774331301715,
              21.008073441686168
            ],
            [
              105.81777635660904,
              21.00801479706793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70.1_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10843",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008396308649356,
          "to_latitude": 21.00801479706793,
          "to_longitude": 105.81777635660904,
          "from_longitude": 105.81762321220621
        }
      },
      {
        "id": 10821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81767824462763,
            21.008207012117364,
            105.81768645786073,
            21.00820906362722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81767824462763,
              21.008207012117364
            ],
            [
              105.81768645786073,
              21.00820906362722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70.1_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10844",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008207012117364,
          "to_latitude": 21.00820906362722,
          "to_longitude": 105.81768645786073,
          "from_longitude": 105.81767824462763
        }
      },
      {
        "id": 10822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753988029476,
            21.008100371405753,
            105.81771155191343,
            21.008592942557694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81771155191343,
              21.008100371405753
            ],
            [
              105.81766389200858,
              21.00821491387399
            ],
            [
              105.81761856521703,
              21.008335567200845
            ],
            [
              105.81760674921118,
              21.00837703156516
            ],
            [
              105.8175822912942,
              21.0084628545895
            ],
            [
              105.81755920072942,
              21.00854594961465
            ],
            [
              105.81753988029476,
              21.008592942557694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10845",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008100371405753,
          "to_latitude": 21.008592942557694,
          "to_longitude": 105.81753988029476,
          "from_longitude": 105.81771155191343
        }
      },
      {
        "id": 10823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81760674921118,
            21.00837703156516,
            105.817617973027,
            21.008379761389882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.817617973027,
              21.008379761389882
            ],
            [
              105.81761687694475,
              21.008379495687556
            ],
            [
              105.81761188675924,
              21.008378281337
            ],
            [
              105.81760674921118,
              21.00837703156516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A70_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10846",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008379761389882,
          "to_latitude": 21.00837703156516,
          "to_longitude": 105.81760674921118,
          "from_longitude": 105.817617973027
        }
      },
      {
        "id": 10824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175980827425,
            21.008321294702725,
            105.81786887199267,
            21.008472329859824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175980827425,
              21.00844397260692
            ],
            [
              105.81760209935084,
              21.008444454601086
            ],
            [
              105.81783484388686,
              21.008472329859824
            ],
            [
              105.81786887199267,
              21.008321294702725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10847",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00844397260692,
          "to_latitude": 21.008321294702725,
          "to_longitude": 105.81786887199267,
          "from_longitude": 105.8175980827425
        }
      },
      {
        "id": 10825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81748427530493,
            21.008661485604367,
            105.81781210003653,
            21.00885232698742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81748427530493,
              21.008661485604367
            ],
            [
              105.81748563714592,
              21.008662154677072
            ],
            [
              105.81749910027682,
              21.008668769375046
            ],
            [
              105.81772569834621,
              21.008780077485174
            ],
            [
              105.817720969872,
              21.00881135641685
            ],
            [
              105.81781210003653,
              21.00885232698742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A60_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10848",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008661485604367,
          "to_latitude": 21.00885232698742,
          "to_longitude": 105.81781210003653,
          "from_longitude": 105.81748427530493
        }
      },
      {
        "id": 10826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81724825782902,
            21.008756151438007,
            105.81755037322009,
            21.00896343247976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81724825782902,
              21.008788081233764
            ],
            [
              105.81724842688939,
              21.00878821500289
            ],
            [
              105.81725839225811,
              21.00879600897221
            ],
            [
              105.8172993664282,
              21.008756151438007
            ],
            [
              105.81755037322009,
              21.00896343247976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A61_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10849",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008788081233764,
          "to_latitude": 21.00896343247976,
          "to_longitude": 105.81755037322009,
          "from_longitude": 105.81724825782902
        }
      },
      {
        "id": 10827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81700547022457,
            21.008578336900968,
            105.81725026567302,
            21.008786029392216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81725026567302,
              21.008786029392216
            ],
            [
              105.81725012652409,
              21.008785912640796
            ],
            [
              105.81700547022457,
              21.008578336900968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A62_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10850",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008786029392216,
          "to_latitude": 21.008578336900968,
          "to_longitude": 105.81700547022457,
          "from_longitude": 105.81725026567302
        }
      },
      {
        "id": 10828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81723009664265,
            21.00887557113712,
            105.81762843793206,
            21.0092142776835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81723009664265,
              21.00887557113712
            ],
            [
              105.8174275102375,
              21.00904661659219
            ],
            [
              105.81752728168026,
              21.009129869268563
            ],
            [
              105.81762843793206,
              21.0092142776835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10851",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00887557113712,
          "to_latitude": 21.0092142776835,
          "to_longitude": 105.81762843793206,
          "from_longitude": 105.81723009664265
        }
      },
      {
        "id": 10829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81751414745406,
            21.009129869268563,
            105.81752728168026,
            21.009141434977085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81752728168026,
              21.009129869268563
            ],
            [
              105.81751477353188,
              21.00914088370989
            ],
            [
              105.81751414745406,
              21.009141434977085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A16_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10852",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009129869268563,
          "to_latitude": 21.009141434977085,
          "to_longitude": 105.81751414745406,
          "from_longitude": 105.81752728168026
        }
      },
      {
        "id": 10830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81692142799957,
            21.008722761923043,
            105.81721363588234,
            21.008907698574145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81721363588234,
              21.008861827181445
            ],
            [
              105.81721206300732,
              21.0088633846744
            ],
            [
              105.81720257504796,
              21.008872779552906
            ],
            [
              105.81719142069154,
              21.008864264191335
            ],
            [
              105.81714892306054,
              21.008907698574145
            ],
            [
              105.81703167308785,
              21.00881238387125
            ],
            [
              105.81692142799957,
              21.008722761923043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10853",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008861827181445,
          "to_latitude": 21.008722761923043,
          "to_longitude": 105.81692142799957,
          "from_longitude": 105.81721363588234
        }
      },
      {
        "id": 10831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81686138082893,
            21.00881238387125,
            105.81703167308785,
            21.00915090127844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81703167308785,
              21.00881238387125
            ],
            [
              105.81700669343185,
              21.008855102755582
            ],
            [
              105.81691381674355,
              21.00901396854986
            ],
            [
              105.81692100115505,
              21.00902386234609
            ],
            [
              105.81686138082893,
              21.00915090127844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10854",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00881238387125,
          "to_latitude": 21.00915090127844,
          "to_longitude": 105.81686138082893,
          "from_longitude": 105.81703167308785
        }
      },
      {
        "id": 10832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81711571150736,
            21.008865141187766,
            105.81727835628645,
            21.009331605817792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81721719659593,
              21.008865141187766
            ],
            [
              105.81721703683905,
              21.008865302729582
            ],
            [
              105.81720420855291,
              21.008878292239494
            ],
            [
              105.81719227399628,
              21.00886905171948
            ],
            [
              105.81714908997328,
              21.00891309004525
            ],
            [
              105.81714709837973,
              21.00891512374309
            ],
            [
              105.81727323305594,
              21.009026771218917
            ],
            [
              105.81727835628645,
              21.009091259688393
            ],
            [
              105.81726083547267,
              21.00919301029665
            ],
            [
              105.81721586468777,
              21.009263987872668
            ],
            [
              105.81717401039408,
              21.009331605817792
            ],
            [
              105.81711571150736,
              21.009320108571504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A17_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10855",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008865141187766,
          "to_latitude": 21.009320108571504,
          "to_longitude": 105.81711571150736,
          "from_longitude": 105.81721719659593
        }
      },
      {
        "id": 10833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81673637236946,
            21.00845008396766,
            105.8169858899319,
            21.008845331293074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8169858899319,
              21.00845008396766
            ],
            [
              105.81698268084588,
              21.008455522356673
            ],
            [
              105.81685287912615,
              21.00867552065178
            ],
            [
              105.8167901628563,
              21.008766925807684
            ],
            [
              105.81673637236946,
              21.008845331293074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10856",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00845008396766,
          "to_latitude": 21.008845331293074,
          "to_longitude": 105.81673637236946,
          "from_longitude": 105.8169858899319
        }
      },
      {
        "id": 10834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81703160445231,
            21.008102115231775,
            105.8172095656583,
            21.008464440208442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8170321024505,
              21.008464440208442
            ],
            [
              105.81703588337486,
              21.008455555966787
            ],
            [
              105.81703748465112,
              21.008451795365254
            ],
            [
              105.81703160445231,
              21.008450002688424
            ],
            [
              105.8170796978473,
              21.008306858482158
            ],
            [
              105.81715415099858,
              21.0082310398788
            ],
            [
              105.8172095656583,
              21.008102115231775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64A_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10857",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008464440208442,
          "to_latitude": 21.008102115231775,
          "to_longitude": 105.8172095656583,
          "from_longitude": 105.8170321024505
        }
      },
      {
        "id": 10835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81674242379714,
            21.008141487749594,
            105.81707674551943,
            21.008462414852108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81702558421058,
              21.008462414852108
            ],
            [
              105.81702916139662,
              21.00845232758542
            ],
            [
              105.81703013054585,
              21.008449596989422
            ],
            [
              105.81702037438234,
              21.008447183435322
            ],
            [
              105.81707674551943,
              21.008277660447035
            ],
            [
              105.81702118208545,
              21.008222773384144
            ],
            [
              105.81674242379714,
              21.008141487749594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A64_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10858",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008462414852108,
          "to_latitude": 21.008141487749594,
          "to_longitude": 105.81674242379714,
          "from_longitude": 105.81702558421058
        }
      },
      {
        "id": 10836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8166509363047,
            21.008344732287675,
            105.81697638384784,
            21.00843742118191
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8166509363047,
              21.008344732287675
            ],
            [
              105.81679990093637,
              21.008387158262725
            ],
            [
              105.81680376797215,
              21.008388259705196
            ],
            [
              105.81697638384784,
              21.00843742118191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10859",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008344732287675,
          "to_latitude": 21.00843742118191,
          "to_longitude": 105.81697638384784,
          "from_longitude": 105.8166509363047
        }
      },
      {
        "id": 10837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81679866198978,
            21.008387158262725,
            105.81679990093637,
            21.00839192422273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81679990093637,
              21.008387158262725
            ],
            [
              105.81679961546881,
              21.008388257958554
            ],
            [
              105.81679900021574,
              21.0083906210473
            ],
            [
              105.81679866198978,
              21.00839192422273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A65_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10860",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008387158262725,
          "to_latitude": 21.00839192422273,
          "to_longitude": 105.81679866198978,
          "from_longitude": 105.81679990093637
        }
      },
      {
        "id": 10838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81646371270665,
            21.008355224159573,
            105.81679797610217,
            21.008475160518888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81679797610217,
              21.00839173693361
            ],
            [
              105.81679598285893,
              21.008396471288076
            ],
            [
              105.8167951196713,
              21.008398523953023
            ],
            [
              105.81664567221284,
              21.008355224159573
            ],
            [
              105.81658914709327,
              21.008475160518888
            ],
            [
              105.81646371270665,
              21.00841054024515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A66_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10861",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00839173693361,
          "to_latitude": 21.00841054024515,
          "to_longitude": 105.81646371270665,
          "from_longitude": 105.81679797610217
        }
      },
      {
        "id": 10839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81643537147873,
            21.006245493825237,
            105.81653703913574,
            21.006404995876167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81643537147873,
              21.006404995876167
            ],
            [
              105.81648620581224,
              21.006325244403875
            ],
            [
              105.81650158867345,
              21.006301111303404
            ],
            [
              105.81653703913574,
              21.006245493825237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10862",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006404995876167,
          "to_latitude": 21.006245493825237,
          "to_longitude": 105.81653703913574,
          "from_longitude": 105.81643537147873
        }
      },
      {
        "id": 10840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81645043077724,
            21.00627807149531,
            105.81650158867345,
            21.006301111303404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81650158867345,
              21.006301111303404
            ],
            [
              105.81647673146055,
              21.006289916714092
            ],
            [
              105.81645088301735,
              21.006278277068372
            ],
            [
              105.81645043077724,
              21.00627807149531
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10863",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006301111303404,
          "to_latitude": 21.00627807149531,
          "to_longitude": 105.81645043077724,
          "from_longitude": 105.81650158867345
        }
      },
      {
        "id": 10841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8163835789084,
            21.00635431122554,
            105.81670456652688,
            21.00651202355552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8163835789084,
              21.0063878264668
            ],
            [
              105.81638529689158,
              21.006388722357677
            ],
            [
              105.81659538966059,
              21.006498097095754
            ],
            [
              105.81660987377767,
              21.006505187490998
            ],
            [
              105.81662296082216,
              21.00651202355552
            ],
            [
              105.81670456652688,
              21.00635431122554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Đường Láng",
          "maTaiSan": "04.O11.DODV.10864",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0063878264668,
          "to_latitude": 21.00635431122554,
          "to_longitude": 105.81670456652688,
          "from_longitude": 105.8163835789084
        }
      },
      {
        "id": 10842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81659538966059,
            21.006343616086962,
            105.81667689947083,
            21.006498097095754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81659538966059,
              21.006498097095754
            ],
            [
              105.81667689947083,
              21.006343616086962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Đường Láng",
          "maTaiSan": "04.O11.DODV.10865",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006498097095754,
          "to_latitude": 21.006343616086962,
          "to_longitude": 105.81667689947083,
          "from_longitude": 105.81659538966059
        }
      },
      {
        "id": 10843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81618203168958,
            21.00643454164294,
            105.81638222093169,
            21.006732245841143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81638222093169,
              21.00643454164294
            ],
            [
              105.81629119582693,
              21.00657929612762
            ],
            [
              105.81628410544135,
              21.006589841007234
            ],
            [
              105.81628304571196,
              21.00659165796669
            ],
            [
              105.81627849422328,
              21.006599463821427
            ],
            [
              105.8162778442798,
              21.006600577909982
            ],
            [
              105.81627124543883,
              21.006601931886667
            ],
            [
              105.81618203168958,
              21.006732245841143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10866",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00643454164294,
          "to_latitude": 21.006732245841143,
          "to_longitude": 105.81618203168958,
          "from_longitude": 105.81638222093169
        }
      },
      {
        "id": 10844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81628003203775,
            21.006549555960294,
            105.81630249571364,
            21.006561326265505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81630249571364,
              21.006561326265505
            ],
            [
              105.81628862544594,
              21.00655437248175
            ],
            [
              105.81628003203775,
              21.006549555960294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10867",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.24,
          "chatLieu": "HDPE",
          "chieuDai": 2.675,
          "from_latitude": 21.006561326265505,
          "to_latitude": 21.006549555960294,
          "to_longitude": 105.81628003203775,
          "from_longitude": 105.81630249571364
        }
      },
      {
        "id": 10845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81649089643138,
            21.006533165726857,
            105.8166074626111,
            21.006716921434283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81649089643138,
              21.006716921434283
            ],
            [
              105.81649415301543,
              21.006712260505918
            ],
            [
              105.81649478682873,
              21.006711364172073
            ],
            [
              105.81649402782165,
              21.00670834380421
            ],
            [
              105.81649219621794,
              21.006701062633642
            ],
            [
              105.81655788843186,
              21.00659684726721
            ],
            [
              105.81657270030823,
              21.006600709760274
            ],
            [
              105.8166074626111,
              21.006533165726857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2_Đường Láng",
          "maTaiSan": "04.O11.DODV.10868",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006716921434283,
          "to_latitude": 21.006533165726857,
          "to_longitude": 105.8166074626111,
          "from_longitude": 105.81649089643138
        }
      },
      {
        "id": 10846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8164302301854,
            21.00695062426529,
            105.81667262228679,
            21.007074706730265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81667262228679,
              21.00695062426529
            ],
            [
              105.81666661195995,
              21.006951420851166
            ],
            [
              105.81653334269103,
              21.00696905997765
            ],
            [
              105.8164302301854,
              21.007074706730265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37_Đường Láng",
          "maTaiSan": "04.O11.DODV.10869",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00695062426529,
          "to_latitude": 21.007074706730265,
          "to_longitude": 105.8164302301854,
          "from_longitude": 105.81667262228679
        }
      },
      {
        "id": 10847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81673770487629,
            21.00708574653956,
            105.81690473496556,
            21.007214326920845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81673770487629,
              21.00708574653956
            ],
            [
              105.81690473496556,
              21.007214326920845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Đường Láng",
          "maTaiSan": "04.O11.DODV.10870",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00708574653956,
          "to_latitude": 21.007214326920845,
          "to_longitude": 105.81690473496556,
          "from_longitude": 105.81673770487629
        }
      },
      {
        "id": 10848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81664915525002,
            21.00707824212196,
            105.81674512729812,
            21.007227731786365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81674512729812,
              21.00707824212196
            ],
            [
              105.81673967551745,
              21.007083754495678
            ],
            [
              105.81673770487629,
              21.00708574653956
            ],
            [
              105.81664915525002,
              21.007175283096952
            ],
            [
              105.81670975862765,
              21.007227731786365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Đường Láng",
          "maTaiSan": "04.O11.DODV.10871",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00707824212196,
          "to_latitude": 21.007227731786365,
          "to_longitude": 105.81670975862765,
          "from_longitude": 105.81674512729812
        }
      },
      {
        "id": 10849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690189038201,
            21.00697386797697,
            105.81705367998481,
            21.007209326753962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81690189038201,
              21.007209326753962
            ],
            [
              105.81690225092673,
              21.007208766828136
            ],
            [
              105.81705367998481,
              21.00697386797697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36_Đường Láng",
          "maTaiSan": "04.O11.DODV.10872",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007209326753962,
          "to_latitude": 21.00697386797697,
          "to_longitude": 105.81705367998481,
          "from_longitude": 105.81690189038201
        }
      },
      {
        "id": 10850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81685777011015,
            21.007248252412573,
            105.81704467502203,
            21.007523679257865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8169616997845,
              21.007248252412573
            ],
            [
              105.81696160042465,
              21.00724837663184
            ],
            [
              105.81695878485499,
              21.007251886545134
            ],
            [
              105.81685777011015,
              21.007377810894464
            ],
            [
              105.81704467502203,
              21.007523679257865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Đường Láng",
          "maTaiSan": "04.O11.DODV.10873",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007248252412573,
          "to_latitude": 21.007523679257865,
          "to_longitude": 105.81704467502203,
          "from_longitude": 105.8169616997845
        }
      },
      {
        "id": 10851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690923449017,
            21.007384326479038,
            105.81717259461801,
            21.00766344198381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81717259461801,
              21.007384326479038
            ],
            [
              105.81704467502203,
              21.007523679257865
            ],
            [
              105.81690923449017,
              21.00766344198381
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Đường Láng",
          "maTaiSan": "04.O11.DODV.10874",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007384326479038,
          "to_latitude": 21.00766344198381,
          "to_longitude": 105.81690923449017,
          "from_longitude": 105.81717259461801
        }
      },
      {
        "id": 10852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8167443067522,
            21.00724712447366,
            105.81695996609646,
            21.00737761250344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81695996609646,
              21.00724712447366
            ],
            [
              105.8169598475288,
              21.007247254204504
            ],
            [
              105.81695698763106,
              21.00725039581335
            ],
            [
              105.81684114377677,
              21.00737761250344
            ],
            [
              105.8167443067522,
              21.007331306294386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10875",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00724712447366,
          "to_latitude": 21.007331306294386,
          "to_longitude": 105.8167443067522,
          "from_longitude": 105.81695996609646
        }
      },
      {
        "id": 10853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81698546469772,
            21.006962474010685,
            105.81719777326059,
            21.007263725341787
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81698546469772,
                21.007263725341787
              ],
              [
                105.81698615149702,
                21.00726337430179
              ],
              [
                105.81698617643656,
                21.00726336153686
              ],
              [
                105.81698624356522,
                21.007263324182237
              ],
              [
                105.81698631932112,
                21.00726328136684
              ],
              [
                105.8169863941201,
                21.00726323945923
              ],
              [
                105.81698646891905,
                21.007263197551666
              ],
              [
                105.8169865437229,
                21.00726315654727
              ],
              [
                105.81698661948373,
                21.007263114635055
              ],
              [
                105.81698669332087,
                21.007263072732098
              ],
              [
                105.81698676908168,
                21.00726303081991
              ],
              [
                105.81698684291877,
                21.007262988916917
              ],
              [
                105.81698691867959,
                21.00726294700471
              ],
              [
                105.81698699347851,
                21.007262905097125
              ],
              [
                105.8169870673107,
                21.00726286229094
              ],
              [
                105.81698714307156,
                21.007262820378738
              ],
              [
                105.8169872178705,
                21.007262778471166
              ],
              [
                105.8169872936313,
                21.00726273655895
              ],
              [
                105.81698736650654,
                21.007262694660596
              ],
              [
                105.81698744226736,
                21.007262652748388
              ],
              [
                105.81698751609956,
                21.007262609942217
              ],
              [
                105.8169875908936,
                21.00726256713142
              ],
              [
                105.81698766569255,
                21.00726252522383
              ],
              [
                105.81698773952473,
                21.007262482417662
              ],
              [
                105.81698781431881,
                21.007262439606862
              ],
              [
                105.81698788815098,
                21.00726239680069
              ],
              [
                105.81698796390685,
                21.007262353985283
              ],
              [
                105.81698803773907,
                21.007262311179094
              ],
              [
                105.81698811253307,
                21.00726226836831
              ],
              [
                105.81698818636035,
                21.00726222465892
              ],
              [
                105.81698826020235,
                21.007262183659154
              ],
              [
                105.8169883340296,
                21.00726213994978
              ],
              [
                105.81698840785688,
                21.007262096240396
              ],
              [
                105.81698848265094,
                21.0072620534296
              ],
              [
                105.81698855647822,
                21.007262009720222
              ],
              [
                105.81698863127224,
                21.00726196690942
              ],
              [
                105.81698870413767,
                21.007261923204673
              ],
              [
                105.81698877795998,
                21.007261878592082
              ],
              [
                105.81698885178723,
                21.007261834882698
              ],
              [
                105.81698892561455,
                21.00726179117332
              ],
              [
                105.81698899751805,
                21.007261747473173
              ],
              [
                105.81698907134529,
                21.00726170376379
              ],
              [
                105.81698914421074,
                21.007261660059022
              ],
              [
                105.81698921899493,
                21.007261615441823
              ],
              [
                105.81698929281723,
                21.007261570829247
              ],
              [
                105.81698936471588,
                21.007261526225896
              ],
              [
                105.81698943853823,
                21.00726148161329
              ],
              [
                105.81698951139875,
                21.00726143700533
              ],
              [
                105.81698958425429,
                21.00726139149416
              ],
              [
                105.81698965615291,
                21.007261346890797
              ],
              [
                105.8169897309322,
                21.0072613013704
              ],
              [
                105.81698980283574,
                21.00726125767025
              ],
              [
                105.8169898756864,
                21.007261211255884
              ],
              [
                105.81698994661822,
                21.007261165753935
              ],
              [
                105.81699002139753,
                21.007261120233522
              ],
              [
                105.81699009232446,
                21.007261073828378
              ],
              [
                105.81699016421818,
                21.007261028321828
              ],
              [
                105.81699023803064,
                21.007260981902828
              ],
              [
                105.81699030799574,
                21.007260935502295
              ],
              [
                105.81699038085127,
                21.007260889991123
              ],
              [
                105.8169904517782,
                21.007260843585986
              ],
              [
                105.81699052462395,
                21.007260796268405
              ],
              [
                105.81699059458902,
                21.00726074986787
              ],
              [
                105.81699066743472,
                21.00726070255029
              ],
              [
                105.81699073835674,
                21.007260655241947
              ],
              [
                105.81699081024061,
                21.007260607928973
              ],
              [
                105.81699088020079,
                21.007260560625244
              ],
              [
                105.81699095208464,
                21.00726051331227
              ],
              [
                105.81699102204477,
                21.007260466008553
              ],
              [
                105.81699109391882,
                21.007260416889178
              ],
              [
                105.81699116387405,
                21.007260368682232
              ],
              [
                105.81699123478624,
                21.00726031956748
              ],
              [
                105.81699130474642,
                21.007260272263746
              ],
              [
                105.8169913756585,
                21.007260223148993
              ],
              [
                105.81699144657075,
                21.007260174034226
              ],
              [
                105.81699151555915,
                21.007260124928703
              ],
              [
                105.8169915855095,
                21.007260075818568
              ],
              [
                105.81699165545487,
                21.007260025805216
              ],
              [
                105.81699172444824,
                21.007259977602892
              ],
              [
                105.81699179342688,
                21.00725992669096
              ],
              [
                105.81699186337228,
                21.007259876677626
              ],
              [
                105.81699193235086,
                21.007259825765686
              ],
              [
                105.81699200229627,
                21.007259775752335
              ],
              [
                105.81699207127488,
                21.0072597248404
              ],
              [
                105.81699214025349,
                21.007259673928477
              ],
              [
                105.81699220826535,
                21.007259622117957
              ],
              [
                105.81699227723904,
                21.0072595703028
              ],
              [
                105.81699234525088,
                21.007259518492297
              ],
              [
                105.81699241326766,
                21.007259467584973
              ],
              [
                105.81699248126965,
                21.007259413968047
              ],
              [
                105.81699254928148,
                21.00725936215751
              ],
              [
                105.81699261632653,
                21.00725930944842
              ],
              [
                105.8169926843384,
                21.00725925763789
              ],
              [
                105.81699275041665,
                21.00725920403021
              ],
              [
                105.8169928193805,
                21.007259150408643
              ],
              [
                105.81699288545879,
                21.007259096800954
              ],
              [
                105.81699295249894,
                21.007259043188636
              ],
              [
                105.81699301857721,
                21.00725898958095
              ],
              [
                105.8169930865743,
                21.00725893506082
              ],
              [
                105.81699315264767,
                21.0072588805499
              ],
              [
                105.81699321872593,
                21.00725882694222
              ],
              [
                105.81699328479439,
                21.00725877152811
              ],
              [
                105.81699335085298,
                21.007258714307593
              ],
              [
                105.81699341692143,
                21.007258658893488
              ],
              [
                105.81699348203294,
                21.007258604387207
              ],
              [
                105.8169935471297,
                21.007258547171308
              ],
              [
                105.81699361319812,
                21.007258491757206
              ],
              [
                105.81699367733302,
                21.007258434545925
              ],
              [
                105.81699374147284,
                21.007258378237854
              ],
              [
                105.8169938065696,
                21.007258321021947
              ],
              [
                105.81699387070444,
                21.007258263810662
              ],
              [
                105.81699393483937,
                21.007258206599396
              ],
              [
                105.81699399895948,
                21.0072581466785
              ],
              [
                105.81699406308452,
                21.00725808766081
              ],
              [
                105.81699412721453,
                21.00725802954632
              ],
              [
                105.8169941894208,
                21.00725797144107
              ],
              [
                105.816994253536,
                21.00725791061697
              ],
              [
                105.81699431574219,
                21.007257852511717
              ],
              [
                105.81699437986238,
                21.00725779259082
              ],
              [
                105.81699444205384,
                21.007257731775958
              ],
              [
                105.81699450521218,
                21.007257671859687
              ],
              [
                105.81699456644179,
                21.00725761104943
              ],
              [
                105.81699462862834,
                21.007257549331364
              ],
              [
                105.81699468985306,
                21.007257487617917
              ],
              [
                105.81699475204458,
                21.00725742680305
              ],
              [
                105.81699481230248,
                21.007257364191016
              ],
              [
                105.81699487448907,
                21.007257302472937
              ],
              [
                105.8169949615267,
                21.00725721173225
              ],
              [
                105.81699504856923,
                21.007257121894774
              ],
              [
                105.81699513463509,
                21.007257029352306
              ],
              [
                105.81699522071088,
                21.007256938616255
              ],
              [
                105.8169953067817,
                21.007256846976983
              ],
              [
                105.81699539188575,
                21.007256754439123
              ],
              [
                105.81699547602302,
                21.007256661002675
              ],
              [
                105.81699556111724,
                21.00725656665841
              ],
              [
                105.81699564621638,
                21.007256473217346
              ],
              [
                105.81699573035857,
                21.00725638068412
              ],
              [
                105.81699581545273,
                21.00725628633985
              ],
              [
                105.81699589765648,
                21.007256191106237
              ],
              [
                105.81699598371253,
                21.007256096757345
              ],
              [
                105.8169960668781,
                21.007256001519103
              ],
              [
                105.81699615005353,
                21.007255908087284
              ],
              [
                105.81699623225231,
                21.007255811950476
              ],
              [
                105.81699631637973,
                21.0072557167076
              ],
              [
                105.81699640051208,
                21.007255622367964
              ],
              [
                105.81699648272074,
                21.00725552803755
              ],
              [
                105.81699656684813,
                21.007255432794704
              ],
              [
                105.81699665098543,
                21.00725533935826
              ],
              [
                105.81699673511778,
                21.00725524501861
              ],
              [
                105.81699681925507,
                21.007255151582154
              ],
              [
                105.81699690146858,
                21.00725505815496
              ],
              [
                105.81699698561077,
                21.007254965621723
              ],
              [
                105.81699706975297,
                21.007254873088485
              ],
              [
                105.81699715486195,
                21.00725478145383
              ],
              [
                105.81699723900412,
                21.00725468892059
              ],
              [
                105.81699732315123,
                21.007254597290558
              ],
              [
                105.81699740922696,
                21.007254506554492
              ],
              [
                105.81699749531255,
                21.00725441762482
              ],
              [
                105.81699758043625,
                21.007254328699794
              ],
              [
                105.81699766652676,
                21.007254240673344
              ],
              [
                105.81699775261235,
                21.00725415174369
              ],
              [
                105.81699783966961,
                21.007254064615836
              ],
              [
                105.81699792769366,
                21.007253978386554
              ],
              [
                105.81699801476077,
                21.0072538930651
              ],
              [
                105.8169981037614,
                21.00725380954082
              ],
              [
                105.81699819276209,
                21.007253726016536
              ],
              [
                105.81699828272953,
                21.00725364339085
              ],
              [
                105.81699837174,
                21.007253561672986
              ],
              [
                105.81699846364593,
                21.007253481747675
              ],
              [
                105.81699855459,
                21.00725340182699
              ],
              [
                105.81699864555372,
                21.0072533255191
              ],
              [
                105.81699873843624,
                21.007253248298785
              ],
              [
                105.8169988313286,
                21.007253172884884
              ],
              [
                105.8169989242308,
                21.00725309927738
              ],
              [
                105.81699901906165,
                21.00725302656385
              ],
              [
                105.81699911583097,
                21.00725295655071
              ],
              [
                105.81699921067654,
                21.00725288654679
              ],
              [
                105.81699930745076,
                21.00725281743686
              ],
              [
                105.81699940616348,
                21.00725275102729
              ],
              [
                105.81699950488114,
                21.007252685520935
              ],
              [
                105.81699960457043,
                21.007252621816374
              ],
              [
                105.81699970523141,
                21.0072525599136
              ],
              [
                105.81699980590722,
                21.007252500720426
              ],
              [
                105.81699990947347,
                21.007252442416625
              ],
              [
                105.81700001208769,
                21.007252385923834
              ],
              [
                105.81700011471177,
                21.007252331237463
              ],
              [
                105.81700022119313,
                21.00725227833904
              ],
              [
                105.81700032767942,
                21.007252226343812
              ],
              [
                105.8170004351472,
                21.00725217795678
              ],
              [
                105.81700054454362,
                21.007252130463726
              ],
              [
                105.8170006539548,
                21.00725208568028
              ],
              [
                105.81700073746465,
                21.007252053666384
              ],
              [
                105.81700082097933,
                21.007252022555694
              ],
              [
                105.81700090449895,
                21.007251992348202
              ],
              [
                105.8170009899521,
                21.007251963937883
              ],
              [
                105.8170010763672,
                21.007251935522962
              ],
              [
                105.81700116182529,
                21.00725190801584
              ],
              [
                105.81700124825508,
                21.007251882310545
              ],
              [
                105.81700133565163,
                21.007251857503793
              ],
              [
                105.81700142401498,
                21.007251833595657
              ],
              [
                105.81700151237344,
                21.007251808784304
              ],
              [
                105.81700160075155,
                21.007251787585773
              ],
              [
                105.81700169009153,
                21.007251766382623
              ],
              [
                105.81700177943148,
                21.007251745179474
              ],
              [
                105.81700186973819,
                21.007251724874912
              ],
              [
                105.81700196004496,
                21.00725170457035
              ],
              [
                105.81700205036645,
                21.007251686975412
              ],
              [
                105.81700214260678,
                21.007251668468033
              ],
              [
                105.81700223485197,
                21.007251650863843
              ],
              [
                105.81700232421652,
                21.007251634176725
              ],
              [
                105.81700241646666,
                21.00725161747575
              ],
              [
                105.81700250968356,
                21.00725160167336
              ],
              [
                105.81700260194354,
                21.007251586778803
              ],
              [
                105.81700269516047,
                21.007251570976393
              ],
              [
                105.81700278934908,
                21.007251556975806
              ],
              [
                105.81700288257582,
                21.00725154297982
              ],
              [
                105.81700297579766,
                21.007251528080637
              ],
              [
                105.817003070953,
                21.00725151497863
              ],
              [
                105.81700316418473,
                21.007251501885857
              ],
              [
                105.81700325934008,
                21.00725148878384
              ],
              [
                105.81700335353364,
                21.007251475686456
              ],
              [
                105.8170034467653,
                21.00725146259368
              ],
              [
                105.81700354288743,
                21.007251450390257
              ],
              [
                105.81700363804775,
                21.007251438191446
              ],
              [
                105.81700373224129,
                21.007251425094058
              ],
              [
                105.81700392159512,
                21.007251399797866
              ],
              [
                105.81700401676031,
                21.007251388502265
              ],
              [
                105.81700411191572,
                21.007251375400248
              ],
              [
                105.81700420706619,
                21.007251361395024
              ],
              [
                105.81700430126958,
                21.00725135010405
              ],
              [
                105.81700439642496,
                21.00725133700203
              ],
              [
                105.81700449157542,
                21.00725132299681
              ],
              [
                105.8170045867308,
                21.007251309894816
              ],
              [
                105.81700468188617,
                21.007251296792784
              ],
              [
                105.81700477510805,
                21.00725128189361
              ],
              [
                105.81700487025851,
                21.007251267888396
              ],
              [
                105.81700496539914,
                21.00725125207677
              ],
              [
                105.81700505766402,
                21.007251238085406
              ],
              [
                105.81700515184279,
                21.0072512222784
              ],
              [
                105.81700524601665,
                21.00725120556818
              ],
              [
                105.81700533922866,
                21.007251188862593
              ],
              [
                105.81700543339262,
                21.007251170345967
              ],
              [
                105.81700552564281,
                21.007251153644994
              ],
              [
                105.8170056178831,
                21.007251135137604
              ],
              [
                105.81700571108524,
                21.007251116625607
              ],
              [
                105.81700580234894,
                21.007251095413213
              ],
              [
                105.81700589457944,
                21.00725107509941
              ],
              [
                105.81700598680987,
                21.00725105478561
              ],
              [
                105.81700607614002,
                21.007251031776057
              ],
              [
                105.81700616643197,
                21.00725100876189
              ],
              [
                105.81700625672401,
                21.007250985747703
              ],
              [
                105.81700634700616,
                21.007250960927113
              ],
              [
                105.81700643632155,
                21.00725093520794
              ],
              [
                105.81700652563195,
                21.007250908585565
              ],
              [
                105.81700660245302,
                21.00725088473284
              ],
              [
                105.81700668023102,
                21.007250859972274
              ],
              [
                105.81700675608029,
                21.00725083431775
              ],
              [
                105.8170068348103,
                21.007250807746168
              ],
              [
                105.81700691064974,
                21.007250780285222
              ],
              [
                105.81700698552247,
                21.00725075192571
              ],
              [
                105.81700706232382,
                21.00725072446015
              ],
              [
                105.81700713622976,
                21.00725069520204
              ],
              [
                105.81700721205448,
                21.007250665031492
              ],
              [
                105.8170072859506,
                21.00725063396696
              ],
              [
                105.81700735984668,
                21.007250602902438
              ],
              [
                105.81700743373298,
                21.007250570031513
              ],
              [
                105.81700750761922,
                21.00725053716058
              ],
              [
                105.81700758053867,
                21.00725050339106
              ],
              [
                105.8170076534631,
                21.00725047052474
              ],
              [
                105.81700772541089,
                21.007250434953434
              ],
              [
                105.81700779832056,
                21.00725039937751
              ],
              [
                105.81700787026342,
                21.007250362902994
              ],
              [
                105.81700794123948,
                21.007250325529885
              ],
              [
                105.8170080122156,
                21.00725028815679
              ],
              [
                105.81700808222985,
                21.007250250788303
              ],
              [
                105.81700815319608,
                21.007250211608785
              ],
              [
                105.8170082222386,
                21.007250172438503
              ],
              [
                105.81700829224299,
                21.007250133263625
              ],
              [
                105.81700836319943,
                21.007250092277694
              ],
              [
                105.81700843127027,
                21.00725005130563
              ],
              [
                105.81700850029804,
                21.007250009425736
              ],
              [
                105.817008567407,
                21.00724996845828
              ],
              [
                105.81700863642989,
                21.007249925675186
              ],
              [
                105.81700870352411,
                21.00724988199812
              ],
              [
                105.81700877253222,
                21.007249836505416
              ],
              [
                105.81700883962151,
                21.007249791925144
              ],
              [
                105.81700890575385,
                21.00724974825269
              ],
              [
                105.81700897282846,
                21.007249700962802
              ],
              [
                105.81700903894603,
                21.007249654580747
              ],
              [
                105.81700910505874,
                21.007249607295474
              ],
              [
                105.81700917020957,
                21.00724956001483
              ],
              [
                105.81700923632224,
                21.00724951272955
              ],
              [
                105.81700929953958,
                21.00724946365173
              ],
              [
                105.81700936468546,
                21.00724941546787
              ],
              [
                105.8170094278978,
                21.00724936548685
              ],
              [
                105.81700949303878,
                21.007249316399783
              ],
              [
                105.817009557213,
                21.00724926641415
              ],
              [
                105.81700962042045,
                21.007249215529903
              ],
              [
                105.81700968458973,
                21.007249164641053
              ],
              [
                105.81700974586856,
                21.007249112862848
              ],
              [
                105.81700980906614,
                21.007249060172203
              ],
              [
                105.81700987130682,
                21.007249008389397
              ],
              [
                105.81700993257581,
                21.007248954804766
              ],
              [
                105.81700999481154,
                21.00724890211874
              ],
              [
                105.81701005703256,
                21.007248846723105
              ],
              [
                105.81701011733473,
                21.007248792239896
              ],
              [
                105.81701017859879,
                21.00724873775208
              ],
              [
                105.81701023793909,
                21.007248683273502
              ],
              [
                105.81701029919331,
                21.007248626979262
              ],
              [
                105.81701035756198,
                21.00724857069889
              ],
              [
                105.81701041785428,
                21.00724851440928
              ],
              [
                105.81701047622293,
                21.00724845812891
              ],
              [
                105.81701053650542,
                21.00724840003289
              ],
              [
                105.8170105939122,
                21.007248343757116
              ],
              [
                105.81701065418977,
                21.007248284757903
              ],
              [
                105.81701071254857,
                21.007248226671123
              ],
              [
                105.81701076994061,
                21.007248167685745
              ],
              [
                105.8170108273326,
                21.00724810870038
              ],
              [
                105.81701088568653,
                21.007248049710388
              ],
              [
                105.8170109430736,
                21.00724798982181
              ],
              [
                105.81701099950378,
                21.00724793084105
              ],
              [
                105.817011055929,
                21.007247870957087
              ],
              [
                105.81701111330624,
                21.007247809262104
              ],
              [
                105.81701116876471,
                21.00724774847957
              ],
              [
                105.81701122326133,
                21.007247687701625
              ],
              [
                105.81701127967672,
                21.007247626011267
              ],
              [
                105.81701133512533,
                21.0072475634223
              ],
              [
                105.81701138961698,
                21.00724750174117
              ],
              [
                105.81701144507055,
                21.00724744005542
              ],
              [
                105.81701149955241,
                21.00724737656788
              ],
              [
                105.8170115540392,
                21.007247313983527
              ],
              [
                105.81701160756407,
                21.007247251403815
              ],
              [
                105.81701166300776,
                21.007247187911656
              ],
              [
                105.81701171556591,
                21.007247124433345
              ],
              [
                105.81701177004281,
                21.007247060042594
              ],
              [
                105.81701182163418,
                21.007246995665692
              ],
              [
                105.81701187515415,
                21.007246932182774
              ],
              [
                105.81701192770244,
                21.007246866898054
              ],
              [
                105.8170119792938,
                21.007246802521152
              ],
              [
                105.81701203088514,
                21.007246738144264
              ],
              [
                105.81701208343833,
                21.00724667376275
              ],
              [
                105.81701213501984,
                21.007246607579443
              ],
              [
                105.81701218660625,
                21.007246542299335
              ],
              [
                105.81701223722594,
                21.00724647612065
              ],
              [
                105.81701228977916,
                21.007246411739143
              ],
              [
                105.81701233847505,
                21.007246345569683
              ],
              [
                105.81701239005167,
                21.00724627848317
              ],
              [
                105.81701243970946,
                21.007246212309106
              ],
              [
                105.81701249033402,
                21.00724614703362
              ],
              [
                105.8170125399918,
                21.00724608085955
              ],
              [
                105.81701258868279,
                21.007246013786897
              ],
              [
                105.81701263833567,
                21.007245946709624
              ],
              [
                105.81701268702669,
                21.00724587963696
              ],
              [
                105.8170127366795,
                21.007245812559685
              ],
              [
                105.81701278440866,
                21.007245745491648
              ],
              [
                105.81701283406645,
                21.00724567931758
              ],
              [
                105.81701288179069,
                21.007245611346338
              ],
              [
                105.81701292951492,
                21.007245543375085
              ],
              [
                105.81701297724408,
                21.00724547630706
              ],
              [
                105.8170130259351,
                21.00724540923439
              ],
              [
                105.81701307270728,
                21.007245343074175
              ],
              [
                105.81701312042664,
                21.007245274199718
              ],
              [
                105.81701316719389,
                21.00724520713629
              ],
              [
                105.81701321395624,
                21.007245139169676
              ],
              [
                105.81701325976165,
                21.007245072110848
              ],
              [
                105.81701330748592,
                21.00724500413963
              ],
              [
                105.81701335329133,
                21.007244937080824
              ],
              [
                105.81701339909185,
                21.007244869118797
              ],
              [
                105.81701344393049,
                21.007244801161416
              ],
              [
                105.8170134906978,
                21.007244734097995
              ],
              [
                105.81701353553146,
                21.00724466523739
              ],
              [
                105.81701358133199,
                21.007244597275392
              ],
              [
                105.81701362618048,
                21.007244531124403
              ],
              [
                105.81701367101914,
                21.007244463167012
              ],
              [
                105.8170137158627,
                21.007244396112817
              ],
              [
                105.81701376070134,
                21.007244328155426
              ],
              [
                105.81701380457814,
                21.007244260202654
              ],
              [
                105.81701384845984,
                21.007244193153088
              ],
              [
                105.81701389137969,
                21.007244126108137
              ],
              [
                105.81701393622325,
                21.007244059053946
              ],
              [
                105.8170139791382,
                21.0072439911058
              ],
              [
                105.81701402301988,
                21.007243924056212
              ],
              [
                105.8170140668868,
                21.007243854297045
              ],
              [
                105.81701411172054,
                21.007243785436447
              ],
              [
                105.81701415654936,
                21.007243715672633
              ],
              [
                105.81701420041632,
                21.00724364591345
              ],
              [
                105.8170142452451,
                21.007243576149655
              ],
              [
                105.81701428816002,
                21.007243508201498
              ],
              [
                105.81701433298393,
                21.007243437534484
              ],
              [
                105.81701437589885,
                21.007243369586337
              ],
              [
                105.81701442073258,
                21.007243300725733
              ],
              [
                105.81701446364748,
                21.007243232777572
              ],
              [
                105.81701450751444,
                21.007243163018384
              ],
              [
                105.81701455138634,
                21.007243094162412
              ],
              [
                105.8170145952582,
                21.00724302530644
              ],
              [
                105.8170146381633,
                21.00724295555187
              ],
              [
                105.81701472591195,
                21.007242818743116
              ],
              [
                105.81709178336155,
                21.007121597730556
              ],
              [
                105.81719777326059,
                21.007184611970857
              ]
            ],
            [
              [
                105.81709178336155,
                21.007121597730556
              ],
              [
                105.81719452725042,
                21.006962474010685
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20_Đường Láng",
          "maTaiSan": "04.O11.DODV.10876",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81698615149702,
            21.00726337430179
          ],
          "to_latitude": [
            105.81719452725042,
            21.006962474010685
          ],
          "to_longitude": [
            105.81709178336155,
            21.007121597730556
          ],
          "from_longitude": [
            105.81698546469772,
            21.007263725341787
          ]
        }
      },
      {
        "id": 10854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8159922793004,
            21.006750364328727,
            105.81617154278722,
            21.006998680579965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81617154278722,
              21.006750364328727
            ],
            [
              105.81607259285074,
              21.00688743139302
            ],
            [
              105.81601448643043,
              21.006967920328517
            ],
            [
              105.8159922793004,
              21.006998680579965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Đường Láng",
          "maTaiSan": "04.O11.DODV.10877",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006750364328727,
          "to_latitude": 21.006998680579965,
          "to_longitude": 105.8159922793004,
          "from_longitude": 105.81617154278722
        }
      },
      {
        "id": 10855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81599334332384,
            21.006956613023956,
            105.81601448643043,
            21.006967920328517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81601448643043,
              21.006967920328517
            ],
            [
              105.81600468518802,
              21.006962679823125
            ],
            [
              105.81599334332384,
              21.006956613023956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A5_Đường Láng",
          "maTaiSan": "04.O11.DODV.10878",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006967920328517,
          "to_latitude": 21.006956613023956,
          "to_longitude": 105.81599334332384,
          "from_longitude": 105.81601448643043
        }
      },
      {
        "id": 10856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81572436069483,
            21.007011470028203,
            105.81598385828879,
            21.007364069981588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81598385828879,
              21.007011470028203
            ],
            [
              105.81584050089462,
              21.007206261154256
            ],
            [
              105.81581560458604,
              21.007240088994546
            ],
            [
              105.81572436069483,
              21.007364069981588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Đường Láng",
          "maTaiSan": "04.O11.DODV.10879",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007011470028203,
          "to_latitude": 21.007364069981588,
          "to_longitude": 105.81572436069483,
          "from_longitude": 105.81598385828879
        }
      },
      {
        "id": 10857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81580037732938,
            21.007231132412265,
            105.81581560458604,
            21.007240088994546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81581560458604,
              21.007240088994546
            ],
            [
              105.81580364955741,
              21.00723305866798
            ],
            [
              105.81580037732938,
              21.007231132412265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A6_Đường Láng",
          "maTaiSan": "04.O11.DODV.10880",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007240088994546,
          "to_latitude": 21.007231132412265,
          "to_longitude": 105.81580037732938,
          "from_longitude": 105.81581560458604
        }
      },
      {
        "id": 10858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81569866579649,
            21.00738742598762,
            105.81596018878766,
            21.007541911482434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81596018878766,
              21.007541911482434
            ],
            [
              105.81583251014906,
              21.007468751505357
            ],
            [
              105.81582416188706,
              21.007463967381497
            ],
            [
              105.81571244664791,
              21.007399969909724
            ],
            [
              105.81569866579649,
              21.00738742598762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Đường Láng",
          "maTaiSan": "04.O11.DODV.10881",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007541911482434,
          "to_latitude": 21.00738742598762,
          "to_longitude": 105.81569866579649,
          "from_longitude": 105.81596018878766
        }
      },
      {
        "id": 10859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81572741055888,
            21.007468751505357,
            105.81583251014906,
            21.007608381416322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81583251014906,
              21.007468751505357
            ],
            [
              105.81582503066447,
              21.007478687649588
            ],
            [
              105.81572741055888,
              21.007608381416322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A7_Đường Láng",
          "maTaiSan": "04.O11.DODV.10882",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007468751505357,
          "to_latitude": 21.007608381416322,
          "to_longitude": 105.81572741055888,
          "from_longitude": 105.81583251014906
        }
      },
      {
        "id": 10860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81597063007858,
            21.007201932212723,
            105.81628743593224,
            21.007559599926168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81597063007858,
              21.007559599926168
            ],
            [
              105.81597579668305,
              21.007552933714116
            ],
            [
              105.81601221927473,
              21.00749486663876
            ],
            [
              105.81610540432342,
              21.00739445226718
            ],
            [
              105.81615618571715,
              21.007362707740672
            ],
            [
              105.81616759722645,
              21.007355575314644
            ],
            [
              105.81624814063149,
              21.007220084097074
            ],
            [
              105.81628743593224,
              21.007201932212723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Đường Láng",
          "maTaiSan": "04.O11.DODV.10883",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007559599926168,
          "to_latitude": 21.007201932212723,
          "to_longitude": 105.81628743593224,
          "from_longitude": 105.81597063007858
        }
      },
      {
        "id": 10861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8160793136757,
            21.007314252360466,
            105.81615618571715,
            21.007362707740672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81615618571715,
              21.007362707740672
            ],
            [
              105.8160793136757,
              21.007314252360466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Đường Láng",
          "maTaiSan": "04.O11.DODV.10884",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007362707740672,
          "to_latitude": 21.007314252360466,
          "to_longitude": 105.8160793136757,
          "from_longitude": 105.81615618571715
        }
      },
      {
        "id": 10862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81604062873286,
            21.00750160669452,
            105.81618246055564,
            21.007733202900006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81606976759329,
              21.007733202900006
            ],
            [
              105.81610509496021,
              21.007698725258155
            ],
            [
              105.81612174240327,
              21.007695193285038
            ],
            [
              105.81614708279918,
              21.007663077599577
            ],
            [
              105.81617842249037,
              21.007623358663974
            ],
            [
              105.81618246055564,
              21.007618240572604
            ],
            [
              105.81616950383608,
              21.0076084990692
            ],
            [
              105.81604062873286,
              21.00750160669452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Đường Láng",
          "maTaiSan": "04.O11.DODV.10885",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007733202900006,
          "to_latitude": 21.00750160669452,
          "to_longitude": 105.81604062873286,
          "from_longitude": 105.81606976759329
        }
      },
      {
        "id": 10863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81617842249037,
            21.007623358663974,
            105.81618756167546,
            21.00762836207399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81618756167546,
              21.00762836207399
            ],
            [
              105.81618750379171,
              21.007628330738605
            ],
            [
              105.81618694616918,
              21.007628027218484
            ],
            [
              105.81618214454099,
              21.00762539656143
            ],
            [
              105.81617842249037,
              21.007623358663974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Đường Láng",
          "maTaiSan": "04.O11.DODV.10886",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00762836207399,
          "to_latitude": 21.007623358663974,
          "to_longitude": 105.81617842249037,
          "from_longitude": 105.81618756167546
        }
      },
      {
        "id": 10864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81601427216879,
            21.007271439503917,
            105.81643783174587,
            21.007615071283187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81602503172938,
              21.007505676462753
            ],
            [
              105.81601704270099,
              21.007499915139224
            ],
            [
              105.81601427216879,
              21.00749791512726
            ],
            [
              105.81602772870019,
              21.007482651976332
            ],
            [
              105.81620201774963,
              21.007615071283187
            ],
            [
              105.81632678896304,
              21.007455377252832
            ],
            [
              105.81637127837851,
              21.007381684394982
            ],
            [
              105.81643783174587,
              21.007271439503917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Đường Láng",
          "maTaiSan": "04.O11.DODV.10887",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007505676462753,
          "to_latitude": 21.007271439503917,
          "to_longitude": 105.81643783174587,
          "from_longitude": 105.81602503172938
        }
      },
      {
        "id": 10865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637127837851,
            21.007381684394982,
            105.81647059489345,
            21.00747144679466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637127837851,
              21.007381684394982
            ],
            [
              105.81647059489345,
              21.00747144679466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A22_Đường Láng",
          "maTaiSan": "04.O11.DODV.10888",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007381684394982,
          "to_latitude": 21.00747144679466,
          "to_longitude": 105.81647059489345,
          "from_longitude": 105.81637127837851
        }
      },
      {
        "id": 10866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81614191488599,
            21.007630654832013,
            105.81618881236226,
            21.007711043836576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81618579080452,
              21.007630654832013
            ],
            [
              105.81618620095738,
              21.007630903059237
            ],
            [
              105.81618881236226,
              21.007632472088172
            ],
            [
              105.81614191488599,
              21.00769620751584
            ],
            [
              105.81616566863332,
              21.007711043836576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A38_Đường Láng",
          "maTaiSan": "04.O11.DODV.10889",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007630654832013,
          "to_latitude": 21.007711043836576,
          "to_longitude": 105.81616566863332,
          "from_longitude": 105.81618579080452
        }
      },
      {
        "id": 10867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8161697981362,
            21.007631911135384,
            105.81648557696482,
            21.007808593958224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8161697981362,
              21.007730871575696
            ],
            [
              105.8161736644386,
              21.007725829276247
            ],
            [
              105.81617611908796,
              21.007722629107253
            ],
            [
              105.8162994100516,
              21.007790146617182
            ],
            [
              105.81635615927742,
              21.00780794168047
            ],
            [
              105.81637815835926,
              21.007808593958224
            ],
            [
              105.81648557696482,
              21.007631911135384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A24_Đường Láng",
          "maTaiSan": "04.O11.DODV.10890",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007730871575696,
          "to_latitude": 21.007631911135384,
          "to_longitude": 105.81648557696482,
          "from_longitude": 105.8161697981362
        }
      },
      {
        "id": 10868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81624401595387,
            21.00781755705001,
            105.81663091748315,
            21.007952331378437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81624401595387,
              21.007952331378437
            ],
            [
              105.81631089664077,
              21.007820858191806
            ],
            [
              105.81636189154757,
              21.00781755705001
            ],
            [
              105.81637502280718,
              21.007821008520477
            ],
            [
              105.81639331332848,
              21.007825816276203
            ],
            [
              105.81648021449145,
              21.007863327739834
            ],
            [
              105.81649524373252,
              21.007869814877917
            ],
            [
              105.8165056119452,
              21.007874057274346
            ],
            [
              105.81658278021324,
              21.007905636008672
            ],
            [
              105.81663091748315,
              21.007925333854658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Đường Láng",
          "maTaiSan": "04.O11.DODV.10891",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007952331378437,
          "to_latitude": 21.007925333854658,
          "to_longitude": 105.81663091748315,
          "from_longitude": 105.81624401595387
        }
      },
      {
        "id": 10869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81658278021324,
            21.0077407313235,
            105.81668075446639,
            21.007905636008672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81658278021324,
              21.007905636008672
            ],
            [
              105.81658340453838,
              21.007904586171165
            ],
            [
              105.81658663777395,
              21.007902170778976
            ],
            [
              105.81658752502678,
              21.00789764676651
            ],
            [
              105.81668075446639,
              21.0077407313235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Đường Láng",
          "maTaiSan": "04.O11.DODV.10892",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007905636008672,
          "to_latitude": 21.0077407313235,
          "to_longitude": 105.81668075446639,
          "from_longitude": 105.81658278021324
        }
      },
      {
        "id": 10870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81642528598235,
            21.007863327739834,
            105.81648021449145,
            21.007972626294105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648021449145,
              21.007863327739834
            ],
            [
              105.81647996327189,
              21.00786382752723
            ],
            [
              105.81642528598235,
              21.007972626294105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Đường Láng",
          "maTaiSan": "04.O11.DODV.10893",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007863327739834,
          "to_latitude": 21.007972626294105,
          "to_longitude": 105.81642528598235,
          "from_longitude": 105.81648021449145
        }
      },
      {
        "id": 10871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637502280718,
            21.007814602588297,
            105.81637827280967,
            21.007821008520477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637827280967,
              21.007814602588297
            ],
            [
              105.81637708606674,
              21.007816943126887
            ],
            [
              105.81637632091395,
              21.007818451576075
            ],
            [
              105.8163758431535,
              21.00781939051588
            ],
            [
              105.81637502280718,
              21.007821008520477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A25_Đường Láng",
          "maTaiSan": "04.O11.DODV.10894",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007814602588297,
          "to_latitude": 21.007821008520477,
          "to_longitude": 105.81637502280718,
          "from_longitude": 105.81637827280967
        }
      },
      {
        "id": 10872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81529543723062,
            21.00743462880789,
            105.81567527918857,
            21.007903554553863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81529543723062,
              21.007903554553863
            ],
            [
              105.81536611350467,
              21.007817950256953
            ],
            [
              105.81536713599124,
              21.007816710644466
            ],
            [
              105.81537323938478,
              21.00780931286252
            ],
            [
              105.815377215644,
              21.0078044976672
            ],
            [
              105.81547694972943,
              21.007683705216404
            ],
            [
              105.81551419897706,
              21.007638589289595
            ],
            [
              105.81551850012578,
              21.00763337962979
            ],
            [
              105.81567527918857,
              21.00743462880789
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Đường Láng",
          "maTaiSan": "04.O11.DODV.10895",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007903554553863,
          "to_latitude": 21.00743462880789,
          "to_longitude": 105.81567527918857,
          "from_longitude": 105.81529543723062
        }
      },
      {
        "id": 10873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81550008989753,
            21.007630076227784,
            105.81551419897706,
            21.007638589289595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81551419897706,
              21.007638589289595
            ],
            [
              105.81550889214977,
              21.007635387483955
            ],
            [
              105.81550008989753,
              21.007630076227784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A8_Đường Láng",
          "maTaiSan": "04.O11.DODV.10896",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007638589289595,
          "to_latitude": 21.007630076227784,
          "to_longitude": 105.81550008989753,
          "from_longitude": 105.81551419897706
        }
      },
      {
        "id": 10874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8152210461293,
            21.00787780671724,
            105.81554621276804,
            21.00811161077433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152210461293,
              21.00787780671724
            ],
            [
              105.81528197501342,
              21.007933194367023
            ],
            [
              105.81547824980451,
              21.00811161077433
            ],
            [
              105.81554621276804,
              21.00804781160311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A26_Đường Láng",
          "maTaiSan": "04.O11.DODV.10897",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00787780671724,
          "to_latitude": 21.00804781160311,
          "to_longitude": 105.81554621276804,
          "from_longitude": 105.8152210461293
        }
      },
      {
        "id": 10875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81531142970192,
            21.007765659694677,
            105.81578633393389,
            21.00822976048753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81531142970192,
              21.007765659694677
            ],
            [
              105.81537173602513,
              21.00779817422385
            ],
            [
              105.81537602128597,
              21.007800483118913
            ],
            [
              105.815377215644,
              21.0078044976672
            ],
            [
              105.81537755661087,
              21.007805644037152
            ],
            [
              105.8153847316863,
              21.007806060370545
            ],
            [
              105.81539509685707,
              21.007819131007096
            ],
            [
              105.8156048566254,
              21.008006441783287
            ],
            [
              105.81557604145011,
              21.008032847575038
            ],
            [
              105.81578633393389,
              21.00822976048753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10898",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007765659694677,
          "to_latitude": 21.00822976048753,
          "to_longitude": 105.81578633393389,
          "from_longitude": 105.81531142970192
        }
      },
      {
        "id": 10876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81529617405968,
            21.007784589486402,
            105.81577739380938,
            21.00823819885309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81529617405968,
              21.007784589486402
            ],
            [
              105.81536203277575,
              21.007810974302487
            ],
            [
              105.81536509053979,
              21.007812197978723
            ],
            [
              105.81536713599124,
              21.007816710644466
            ],
            [
              105.81536755365197,
              21.007817632646297
            ],
            [
              105.81537378611455,
              21.007816816976348
            ],
            [
              105.81538930206081,
              21.00782614343903
            ],
            [
              105.8154434246764,
              21.007869854185067
            ],
            [
              105.815584928355,
              21.00800417625115
            ],
            [
              105.81555668796564,
              21.008030858382682
            ],
            [
              105.81577739380938,
              21.00823819885309
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A9_Đường Láng",
          "maTaiSan": "04.O11.DODV.10899",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007784589486402,
          "to_latitude": 21.00823819885309,
          "to_longitude": 105.81577739380938,
          "from_longitude": 105.81529617405968
        }
      },
      {
        "id": 10877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81506950524714,
            21.008008444005068,
            105.8152263854381,
            21.008191981837484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81506950524714,
              21.008191981837484
            ],
            [
              105.81515082845887,
              21.008090348516717
            ],
            [
              105.8152187401376,
              21.00801673071946
            ],
            [
              105.8152263854381,
              21.008008444005068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A27_Đường Láng",
          "maTaiSan": "04.O11.DODV.10900",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008191981837484,
          "to_latitude": 21.008008444005068,
          "to_longitude": 105.8152263854381,
          "from_longitude": 105.81506950524714
        }
      },
      {
        "id": 10878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81518900679613,
            21.00799082132564,
            105.8152187401376,
            21.00801673071946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152187401376,
              21.00801673071946
            ],
            [
              105.81521401605677,
              21.00801261565892
            ],
            [
              105.81520069234301,
              21.008001004349595
            ],
            [
              105.81518900679613,
              21.00799082132564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A27_Đường Láng",
          "maTaiSan": "04.O11.DODV.10901",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00801673071946,
          "to_latitude": 21.00799082132564,
          "to_longitude": 105.81518900679613,
          "from_longitude": 105.8152187401376
        }
      },
      {
        "id": 10879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81535118382524,
            21.008279607240762,
            105.81562026190794,
            21.008567074698767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81535118382524,
              21.008567074698767
            ],
            [
              105.81562026190794,
              21.008279607240762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10902",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008567074698767,
          "to_latitude": 21.008279607240762,
          "to_longitude": 105.81562026190794,
          "from_longitude": 105.81535118382524
        }
      },
      {
        "id": 10880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81514224298765,
            21.00815875097292,
            105.8153335190206,
            21.008363632564976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81514224298765,
              21.008363632564976
            ],
            [
              105.81517092519621,
              21.008332910047848
            ],
            [
              105.8153335190206,
              21.00815875097292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10903",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008363632564976,
          "to_latitude": 21.00815875097292,
          "to_longitude": 105.8153335190206,
          "from_longitude": 105.81514224298765
        }
      },
      {
        "id": 10881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81566462629655,
            21.008194360169828,
            105.81616063156214,
            21.008850091422318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81566462629655,
              21.008850091422318
            ],
            [
              105.81592462174271,
              21.008586449937493
            ],
            [
              105.81578590068753,
              21.008465177338316
            ],
            [
              105.81606441018923,
              21.008194360169828
            ],
            [
              105.81616063156214,
              21.008285863644122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT66_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10904",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008850091422318,
          "to_latitude": 21.008285863644122,
          "to_longitude": 105.81616063156214,
          "from_longitude": 105.81566462629655
        }
      },
      {
        "id": 10882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81567673399104,
            21.00834991154568,
            105.81617453628658,
            21.00886094077546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81567673399104,
              21.00886094077546
            ],
            [
              105.81595084879669,
              21.00857672648132
            ],
            [
              105.81581336120243,
              21.008449144358828
            ],
            [
              105.8159202911418,
              21.00834991154568
            ],
            [
              105.8159641794783,
              21.008383172054213
            ],
            [
              105.81597942332466,
              21.008385957680527
            ],
            [
              105.81604261914985,
              21.008437497256768
            ],
            [
              105.81617453628658,
              21.008519929128383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10905",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00886094077546,
          "to_latitude": 21.008519929128383,
          "to_longitude": 105.81617453628658,
          "from_longitude": 105.81567673399104
        }
      },
      {
        "id": 10883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81569629376654,
            21.008602257930928,
            105.8162297542012,
            21.008878208878475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81569629376654,
              21.008878208878475
            ],
            [
              105.81595146948399,
              21.0086314338604
            ],
            [
              105.81611377393013,
              21.008794145336537
            ],
            [
              105.8162297542012,
              21.008673484092206
            ],
            [
              105.81614431311957,
              21.008602257930928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10906",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008878208878475,
          "to_latitude": 21.008602257930928,
          "to_longitude": 105.81614431311957,
          "from_longitude": 105.81569629376654
        }
      },
      {
        "id": 10884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8163578641885,
            21.008718478456355,
            105.81647922226134,
            21.009015935585435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8163578641885,
              21.009015935585435
            ],
            [
              105.81636485241553,
              21.009013644893482
            ],
            [
              105.81637409133494,
              21.009010619918044
            ],
            [
              105.81642431196504,
              21.008994172359607
            ],
            [
              105.81644968147614,
              21.008942455531358
            ],
            [
              105.81644410834335,
              21.008857619449184
            ],
            [
              105.81644730107215,
              21.008806919471564
            ],
            [
              105.8164790895899,
              21.008718844900393
            ],
            [
              105.81647922226134,
              21.008718478456355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10907",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009015935585435,
          "to_latitude": 21.008718478456355,
          "to_longitude": 105.81647922226134,
          "from_longitude": 105.8163578641885
        }
      },
      {
        "id": 10885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81612386632871,
            21.008802137475,
            105.8163578641885,
            21.009015935585435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8163578641885,
              21.009015935585435
            ],
            [
              105.81612386632871,
              21.008802137475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10908",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009015935585435,
          "to_latitude": 21.008802137475,
          "to_longitude": 105.81612386632871,
          "from_longitude": 105.8163578641885
        }
      },
      {
        "id": 10886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81648268713292,
            21.008982538600865,
            105.81662972146273,
            21.0091512364705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648268713292,
              21.0091512364705
            ],
            [
              105.8165862201156,
              21.009048549340434
            ],
            [
              105.81658916017138,
              21.009045635869413
            ],
            [
              105.81662972146273,
              21.008982538600865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10909",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0091512364705,
          "to_latitude": 21.008982538600865,
          "to_longitude": 105.81662972146273,
          "from_longitude": 105.81648268713292
        }
      },
      {
        "id": 10887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8165862201156,
            21.009048549340434,
            105.8167829373484,
            21.00921333049158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8165862201156,
              21.009048549340434
            ],
            [
              105.81666947942539,
              21.00910498724121
            ],
            [
              105.81670318277574,
              21.00914499386153
            ],
            [
              105.8167829373484,
              21.00921333049158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10910",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009048549340434,
          "to_latitude": 21.00921333049158,
          "to_longitude": 105.8167829373484,
          "from_longitude": 105.8165862201156
        }
      },
      {
        "id": 10888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81649617949775,
            21.009163399638812,
            105.81693927255078,
            21.009543474998992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81693927255078,
              21.009543474998992
            ],
            [
              105.81693662360796,
              21.009541202548508
            ],
            [
              105.81649617949775,
              21.009163399638812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10911",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009543474998992,
          "to_latitude": 21.009163399638812,
          "to_longitude": 105.81649617949775,
          "from_longitude": 105.81693927255078
        }
      },
      {
        "id": 10889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81723395447132,
            21.011375673280913,
            105.81747560164492,
            21.01177982650065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81723395447132,
              21.01177982650065
            ],
            [
              105.81747560164492,
              21.011488133436142
            ],
            [
              105.81744332813896,
              21.011461939518306
            ],
            [
              105.81733853947198,
              21.01137687379132
            ],
            [
              105.81733706024377,
              21.011375673280913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10912",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01177982650065,
          "to_latitude": 21.011375673280913,
          "to_longitude": 105.81733706024377,
          "from_longitude": 105.81723395447132
        }
      },
      {
        "id": 10890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81726643279681,
            21.01131944432932,
            105.81739992556915,
            21.011440481318832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81726643279681,
              21.011440481318832
            ],
            [
              105.81733706024377,
              21.011375673280913
            ],
            [
              105.81738880542513,
              21.01132819131673
            ],
            [
              105.81739992556915,
              21.01131944432932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_168_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10913",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011440481318832,
          "to_latitude": 21.01131944432932,
          "to_longitude": 105.81739992556915,
          "from_longitude": 105.81726643279681
        }
      },
      {
        "id": 10891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81675371328804,
            21.010764646262686,
            105.81711856852549,
            21.01103185130328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81711856852549,
              21.011017668741076
            ],
            [
              105.81711473416276,
              21.01101427837134
            ],
            [
              105.81709692198602,
              21.01103185130328
            ],
            [
              105.8168163102901,
              21.010764646262686
            ],
            [
              105.81675371328804,
              21.010824576949126
            ],
            [
              105.81681941793656,
              21.01088615255669
            ],
            [
              105.8167596428207,
              21.01093895858513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10914",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011017668741076,
          "to_latitude": 21.01093895858513,
          "to_longitude": 105.8167596428207,
          "from_longitude": 105.81711856852549
        }
      },
      {
        "id": 10892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81718929814377,
            21.01084847372966,
            105.81729734705198,
            21.0109557478945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81718929814377,
              21.0109557478945
            ],
            [
              105.81722811386649,
              21.0109172095136
            ],
            [
              105.81723711005421,
              21.010908278543678
            ],
            [
              105.81729734705198,
              21.01084847372966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10915",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0109557478945,
          "to_latitude": 21.01084847372966,
          "to_longitude": 105.81729734705198,
          "from_longitude": 105.81718929814377
        }
      },
      {
        "id": 10893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172318095198,
            21.01090378786064,
            105.81723711005421,
            21.010908278543678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8172318095198,
              21.01090378786064
            ],
            [
              105.81723206561459,
              21.0109040061151
            ],
            [
              105.81723244345788,
              21.01090432494637
            ],
            [
              105.81723321558503,
              21.010904978788055
            ],
            [
              105.81723468543484,
              21.01090622450602
            ],
            [
              105.81723711005421,
              21.010908278543678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10916",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01090378786064,
          "to_latitude": 21.010908278543678,
          "to_longitude": 105.81723711005421,
          "from_longitude": 105.8172318095198
        }
      },
      {
        "id": 10894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81733757245976,
            21.0107952032277,
            105.81764188326281,
            21.011143957240336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81733757245976,
              21.0107952032277
            ],
            [
              105.81733769717746,
              21.01079531914506
            ],
            [
              105.81764188326281,
              21.01107185868754
            ],
            [
              105.81757379582497,
              21.011143957240336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10917",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0107952032277,
          "to_latitude": 21.011143957240336,
          "to_longitude": 105.81757379582497,
          "from_longitude": 105.81733757245976
        }
      },
      {
        "id": 10895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81734445395942,
            21.010788494415294,
            105.81776293428977,
            21.01106540882086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81734445395942,
              21.010788494415294
            ],
            [
              105.81734606344695,
              21.010789946300804
            ],
            [
              105.81765126425329,
              21.01106540882086
            ],
            [
              105.81776293428977,
              21.010954745218555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10918",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010788494415294,
          "to_latitude": 21.010954745218555,
          "to_longitude": 105.81776293428977,
          "from_longitude": 105.81734445395942
        }
      },
      {
        "id": 10896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81705558071354,
            21.01053599154566,
            105.81734227921038,
            21.010790636479484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81734227921038,
              21.010790636479484
            ],
            [
              105.81734215357505,
              21.01079052869559
            ],
            [
              105.81707816553566,
              21.010560403936573
            ],
            [
              105.81705558071354,
              21.01053599154566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10919",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010790636479484,
          "to_latitude": 21.01053599154566,
          "to_longitude": 105.81705558071354,
          "from_longitude": 105.81734227921038
        }
      },
      {
        "id": 10897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81742757755966,
            21.0107066221502,
            105.81743535588059,
            21.010713215381067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81742757755966,
              21.0107066221502
            ],
            [
              105.81742878068926,
              21.01070764153434
            ],
            [
              105.8174298562696,
              21.01070855495032
            ],
            [
              105.81743055784447,
              21.01070914771054
            ],
            [
              105.81743182669213,
              21.010710223682327
            ],
            [
              105.81743313032507,
              21.01071132839031
            ],
            [
              105.81743535588059,
              21.010713215381067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10920",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0107066221502,
          "to_latitude": 21.010713215381067,
          "to_longitude": 105.81743535588059,
          "from_longitude": 105.81742757755966
        }
      },
      {
        "id": 10898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81760238448601,
            21.010560703128604,
            105.81799959928084,
            21.010822658348452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81760238448601,
              21.010560703128604
            ],
            [
              105.81765731979557,
              21.010605680047988
            ],
            [
              105.81780890142389,
              21.010741636188087
            ],
            [
              105.81790137946228,
              21.010822658348452
            ],
            [
              105.81799959928084,
              21.010724614115386
            ],
            [
              105.81792928844129,
              21.01064606688157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10921",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010560703128604,
          "to_latitude": 21.01064606688157,
          "to_longitude": 105.81792928844129,
          "from_longitude": 105.81760238448601
        }
      },
      {
        "id": 10899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81764681305819,
            21.010605680047988,
            105.81765731979557,
            21.010615773526176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81764681305819,
              21.010615773526176
            ],
            [
              105.81765475737659,
              21.010608169915162
            ],
            [
              105.81765731979557,
              21.010605680047988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10922",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010615773526176,
          "to_latitude": 21.010605680047988,
          "to_longitude": 105.81765731979557,
          "from_longitude": 105.81764681305819
        }
      },
      {
        "id": 10900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763217466907,
            21.010508794186563,
            105.81763887893297,
            21.010514400824796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81763217466907,
              21.010508794186563
            ],
            [
              105.81763326457894,
              21.010509689467835
            ],
            [
              105.81763488492442,
              21.01051101574982
            ],
            [
              105.81763701480514,
              21.010512821003967
            ],
            [
              105.81763887893297,
              21.010514400824796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10923",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010508794186563,
          "to_latitude": 21.010514400824796,
          "to_longitude": 105.81763887893297,
          "from_longitude": 105.81763217466907
        }
      },
      {
        "id": 10901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81782420437563,
            21.010319515827742,
            105.81812573325364,
            21.010596178389964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81782420437563,
              21.010319515827742
            ],
            [
              105.81782573280356,
              21.010320920227322
            ],
            [
              105.81812573325364,
              21.010596178389964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT12_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10924",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010319515827742,
          "to_latitude": 21.010596178389964,
          "to_longitude": 105.81812573325364,
          "from_longitude": 105.81782420437563
        }
      },
      {
        "id": 10902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81741348361088,
            21.01040126222901,
            105.81742216726053,
            21.010409111446002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81741348361088,
              21.010409111446002
            ],
            [
              105.81741365207529,
              21.01040895889445
            ],
            [
              105.81741823032841,
              21.010404820891672
            ],
            [
              105.81742216726053,
              21.01040126222901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10925",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010409111446002,
          "to_latitude": 21.01040126222901,
          "to_longitude": 105.81742216726053,
          "from_longitude": 105.81741348361088
        }
      },
      {
        "id": 10903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172780697996,
            21.01031313363651,
            105.81755384964399,
            21.010550661201684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81755384964399,
              21.010550661201684
            ],
            [
              105.81741848298577,
              21.010434069814785
            ],
            [
              105.81738687455935,
              21.01040684571133
            ],
            [
              105.8172780697996,
              21.01031313363651
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10926",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010550661201684,
          "to_latitude": 21.01031313363651,
          "to_longitude": 105.8172780697996,
          "from_longitude": 105.81755384964399
        }
      },
      {
        "id": 10904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81741848298577,
            21.010422427054255,
            105.81742941991514,
            21.010434069814785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81742941991514,
              21.010422427054255
            ],
            [
              105.8174291846456,
              21.010422676572148
            ],
            [
              105.81742401935055,
              21.010428175850194
            ],
            [
              105.81741848298577,
              21.010434069814785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10927",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010422427054255,
          "to_latitude": 21.010434069814785,
          "to_longitude": 105.81741848298577,
          "from_longitude": 105.81742941991514
        }
      },
      {
        "id": 10905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81794765991503,
            21.010196491700018,
            105.81795519857968,
            21.010203589157573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81794765991503,
              21.010196491700018
            ],
            [
              105.81794915760204,
              21.010197903472086
            ],
            [
              105.81795096273257,
              21.01019960099281
            ],
            [
              105.81795519857968,
              21.010203589157573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10928",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010196491700018,
          "to_latitude": 21.010203589157573,
          "to_longitude": 105.81795519857968,
          "from_longitude": 105.81794765991503
        }
      },
      {
        "id": 10906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81795096273257,
            21.010181796520513,
            105.81796192885476,
            21.01019960099281
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81795096273257,
              21.01019960099281
            ],
            [
              105.81795130231396,
              21.01019925342419
            ],
            [
              105.81796192885476,
              21.010188383479406
            ],
            [
              105.8179587398163,
              21.010185677382808
            ],
            [
              105.81795863931305,
              21.010185592059255
            ],
            [
              105.81795435247842,
              21.01018195549532
            ],
            [
              105.81795416596417,
              21.010181796520513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10929",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01019960099281,
          "to_latitude": 21.010181796520513,
          "to_longitude": 105.81795416596417,
          "from_longitude": 105.81795096273257
        }
      },
      {
        "id": 10907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81805840770502,
            21.010086553125866,
            105.81835135103694,
            21.01035113705935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81805840770502,
              21.010086553125866
            ],
            [
              105.81835135103694,
              21.01035113705935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10930",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010086553125866,
          "to_latitude": 21.01035113705935,
          "to_longitude": 105.81835135103694,
          "from_longitude": 105.81805840770502
        }
      },
      {
        "id": 10908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81776236926729,
            21.009833834780135,
            105.81805340785345,
            21.01009151601273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81805340785345,
              21.01009151601273
            ],
            [
              105.81805337594399,
              21.01009148545637
            ],
            [
              105.81801061954859,
              21.01005112702951
            ],
            [
              105.81778824573361,
              21.009856484416975
            ],
            [
              105.81776236926729,
              21.009833834780135
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10931",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01009151601273,
          "to_latitude": 21.009833834780135,
          "to_longitude": 105.81776236926729,
          "from_longitude": 105.81805340785345
        }
      },
      {
        "id": 10909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81777067568363,
            21.009829846852533,
            105.8180611228962,
            21.01008385838893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8180611228962,
              21.01008385838893
            ],
            [
              105.81777067568363,
              21.009829846852533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10932",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01008385838893,
          "to_latitude": 21.009829846852533,
          "to_longitude": 105.81777067568363,
          "from_longitude": 105.8180611228962
        }
      },
      {
        "id": 10910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81812313002705,
            21.00984865937933,
            105.81861075262351,
            21.010249817324894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81812313002705,
              21.010030037703007
            ],
            [
              105.81830670126024,
              21.00984865937933
            ],
            [
              105.81842449747982,
              21.009952121248393
            ],
            [
              105.81842923156378,
              21.009956279521546
            ],
            [
              105.81859795959664,
              21.010104486075793
            ],
            [
              105.81860387765386,
              21.010109676456942
            ],
            [
              105.81861075262351,
              21.01011571488158
            ],
            [
              105.81860615097328,
              21.010120669545973
            ],
            [
              105.81848622020829,
              21.010249817324894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_171_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10933",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010030037703007,
          "to_latitude": 21.010249817324894,
          "to_longitude": 105.81848622020829,
          "from_longitude": 105.81812313002705
        }
      },
      {
        "id": 10911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81842923156378,
            21.009949802881316,
            105.81843496861597,
            21.009956279521546
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81843496861597,
              21.009949802881316
            ],
            [
              105.81843186632477,
              21.00995330155218
            ],
            [
              105.81843023824307,
              21.00995514203203
            ],
            [
              105.81842923156378,
              21.009956279521546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_171_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10934",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009949802881316,
          "to_latitude": 21.009956279521546,
          "to_longitude": 105.81842923156378,
          "from_longitude": 105.81843496861597
        }
      },
      {
        "id": 10912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81652753571284,
            21.010482031078094,
            105.81681319077624,
            21.010737782071875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81652753571284,
              21.010482031078094
            ],
            [
              105.81681319077624,
              21.010737782071875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10935",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010482031078094,
          "to_latitude": 21.010737782071875,
          "to_longitude": 105.81681319077624,
          "from_longitude": 105.81652753571284
        }
      },
      {
        "id": 10913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81625234447813,
            21.0102348055293,
            105.81653338423205,
            21.010476221450283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81653338423205,
              21.010476221450283
            ],
            [
              105.816532425184,
              21.01047533275963
            ],
            [
              105.81652561023091,
              21.010468998605766
            ],
            [
              105.81651818360416,
              21.0104755853562
            ],
            [
              105.81625234447813,
              21.0102348055293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10936",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010476221450283,
          "to_latitude": 21.0102348055293,
          "to_longitude": 105.81625234447813,
          "from_longitude": 105.81653338423205
        }
      },
      {
        "id": 10914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8167263934037,
            21.01026927378748,
            105.81703071638213,
            21.010526277488502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8167263934037,
              21.01027736808356
            ],
            [
              105.81673119087833,
              21.010275684020783
            ],
            [
              105.81674944950245,
              21.01026927378748
            ],
            [
              105.81703071638213,
              21.010526277488502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10937",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01027736808356,
          "to_latitude": 21.010526277488502,
          "to_longitude": 105.81703071638213,
          "from_longitude": 105.8167263934037
        }
      },
      {
        "id": 10915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81674560640397,
            21.01025826562391,
            105.81703887526632,
            21.010517796743965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81674560640397,
              21.01025826562391
            ],
            [
              105.81674719930683,
              21.01025967694985
            ],
            [
              105.81703874767817,
              21.010517683549832
            ],
            [
              105.81703887526632,
              21.010517796743965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10938",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01025826562391,
          "to_latitude": 21.010517796743965,
          "to_longitude": 105.81703887526632,
          "from_longitude": 105.81674560640397
        }
      },
      {
        "id": 10916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81646547186888,
            21.010020203904947,
            105.81673813700169,
            21.010265649236096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81673813700169,
              21.010265649236096
            ],
            [
              105.81647567660491,
              21.010028669674423
            ],
            [
              105.81646547186888,
              21.010020203904947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10939",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010265649236096,
          "to_latitude": 21.010020203904947,
          "to_longitude": 105.81646547186888,
          "from_longitude": 105.81673813700169
        }
      },
      {
        "id": 10917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81647480515046,
            21.01001418065543,
            105.8167468760783,
            21.010257021203977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8167468760783,
              21.010257021203977
            ],
            [
              105.81674177339465,
              21.010252468135135
            ],
            [
              105.81647480515046,
              21.01001418065543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10940",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010257021203977,
          "to_latitude": 21.01001418065543,
          "to_longitude": 105.81647480515046,
          "from_longitude": 105.8167468760783
        }
      },
      {
        "id": 10918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81699173307813,
            21.01005460474048,
            105.81725964366413,
            21.010293379116384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81699173307813,
              21.01005460474048
            ],
            [
              105.8171463236534,
              21.010192383328185
            ],
            [
              105.81716153111844,
              21.0102059363992
            ],
            [
              105.81725964366413,
              21.010293379116384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10941",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01005460474048,
          "to_latitude": 21.010293379116384,
          "to_longitude": 105.81725964366413,
          "from_longitude": 105.81699173307813
        }
      },
      {
        "id": 10919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81716153111844,
            21.01018570973337,
            105.8171906072228,
            21.0102059363992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81716153111844,
              21.0102059363992
            ],
            [
              105.8171725650382,
              21.010196029193423
            ],
            [
              105.81718405457794,
              21.01018570973337
            ],
            [
              105.81718790395156,
              21.01018868093249
            ],
            [
              105.8171906072228,
              21.01019103531133
            ],
            [
              105.81717913713919,
              21.01020192461532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT32_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10942",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0102059363992,
          "to_latitude": 21.01020192461532,
          "to_longitude": 105.81717913713919,
          "from_longitude": 105.81716153111844
        }
      },
      {
        "id": 10920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81701189206012,
            21.010023227355816,
            105.81727595102655,
            21.010264274657615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81701189206012,
              21.010023227355816
            ],
            [
              105.8170166609028,
              21.010029005893692
            ],
            [
              105.81707785690851,
              21.010089002540823
            ],
            [
              105.81713001420592,
              21.010133504334796
            ],
            [
              105.81715272449146,
              21.01015288061911
            ],
            [
              105.81727595102655,
              21.010264274657615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10943",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010023227355816,
          "to_latitude": 21.010264274657615,
          "to_longitude": 105.81727595102655,
          "from_longitude": 105.81701189206012
        }
      },
      {
        "id": 10921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81711493887484,
            21.010133504334796,
            105.81713001420592,
            21.010144388366097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81711493887484,
              21.010144388366097
            ],
            [
              105.81711893622328,
              21.010141502325187
            ],
            [
              105.81712411303612,
              21.010137764295706
            ],
            [
              105.81712929081051,
              21.01013402626146
            ],
            [
              105.81713001420592,
              21.010133504334796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10944",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010144388366097,
          "to_latitude": 21.010133504334796,
          "to_longitude": 105.81713001420592,
          "from_longitude": 105.81711493887484
        }
      },
      {
        "id": 10922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.817207542892,
            21.009799058041757,
            105.8175068174179,
            21.01005039225042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.817207542892,
              21.009805573638374
            ],
            [
              105.81720773586535,
              21.00980550587276
            ],
            [
              105.81722610193924,
              21.009799058041757
            ],
            [
              105.8175068174179,
              21.01005039225042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10945",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009805573638374,
          "to_latitude": 21.01005039225042,
          "to_longitude": 105.8175068174179,
          "from_longitude": 105.817207542892
        }
      },
      {
        "id": 10923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81722627339539,
            21.009786916025735,
            105.81751658801191,
            21.010042369806712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81722627339539,
              21.009786916025735
            ],
            [
              105.81751658801191,
              21.010042369806712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10946",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009786916025735,
          "to_latitude": 21.010042369806712,
          "to_longitude": 105.81751658801191,
          "from_longitude": 105.81722627339539
        }
      },
      {
        "id": 10924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81694312513991,
            21.009554990727892,
            105.81722123822082,
            21.009791931445665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81722123822082,
              21.009791931445665
            ],
            [
              105.8172204351621,
              21.009791248848913
            ],
            [
              105.81694312513991,
              21.009554990727892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10947",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009791931445665,
          "to_latitude": 21.009554990727892,
          "to_longitude": 105.81694312513991,
          "from_longitude": 105.81722123822082
        }
      },
      {
        "id": 10925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81695011538257,
            21.009548650829856,
            105.81722847478915,
            21.0097847232544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81722847478915,
              21.0097847232544
            ],
            [
              105.8172274356648,
              21.009783790693966
            ],
            [
              105.81722124433644,
              21.009778219509933
            ],
            [
              105.81721576569149,
              21.009777930594108
            ],
            [
              105.81695011538257,
              21.009548650829856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT37_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10948",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0097847232544,
          "to_latitude": 21.009548650829856,
          "to_longitude": 105.81695011538257,
          "from_longitude": 105.81722847478915
        }
      },
      {
        "id": 10926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81669899916618,
            21.009790670402467,
            105.81695455901128,
            21.010018506862167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81695455901128,
              21.010018506862167
            ],
            [
              105.81684320932706,
              21.009920502825263
            ],
            [
              105.81684113223966,
              21.0099186738232
            ],
            [
              105.81683886545888,
              21.00991663166659
            ],
            [
              105.81669899916618,
              21.009790670402467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10949",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010018506862167,
          "to_latitude": 21.009790670402467,
          "to_longitude": 105.81669899916618,
          "from_longitude": 105.81695455901128
        }
      },
      {
        "id": 10927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81675024737004,
            21.009796849097484,
            105.81697246720667,
            21.00999950728775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81697246720667,
              21.00999950728775
            ],
            [
              105.8168723347242,
              21.009901761932337
            ],
            [
              105.81687230571048,
              21.009901733168324
            ],
            [
              105.81675024737004,
              21.009796849097484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10950",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00999950728775,
          "to_latitude": 21.009796849097484,
          "to_longitude": 105.81675024737004,
          "from_longitude": 105.81697246720667
        }
      },
      {
        "id": 10928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81683886545888,
            21.009906064482838,
            105.81685040944329,
            21.00991663166659
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81685040944329,
              21.009906064482838
            ],
            [
              105.81684909625707,
              21.009907266660633
            ],
            [
              105.81684333814339,
              21.009912537540743
            ],
            [
              105.81683886545888,
              21.00991663166659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10951",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009906064482838,
          "to_latitude": 21.00991663166659,
          "to_longitude": 105.81683886545888,
          "from_longitude": 105.81685040944329
        }
      },
      {
        "id": 10929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816858030541,
            21.009901761932337,
            105.8168723347242,
            21.009912943917413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816858030541,
              21.009912943917413
            ],
            [
              105.81686521998874,
              21.009907323842384
            ],
            [
              105.81687059531019,
              21.009903121511613
            ],
            [
              105.8168723347242,
              21.009901761932337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10952",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009912943917413,
          "to_latitude": 21.009901761932337,
          "to_longitude": 105.8168723347242,
          "from_longitude": 105.816858030541
        }
      },
      {
        "id": 10930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81723935842518,
            21.009487006727756,
            105.81749190391,
            21.009761888862215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81723935842518,
              21.009761888862215
            ],
            [
              105.81731751048511,
              21.009683911767244
            ],
            [
              105.81736215060573,
              21.009635212069245
            ],
            [
              105.81738627695184,
              21.0096088917283
            ],
            [
              105.81739132482811,
              21.009603384886585
            ],
            [
              105.81739917485027,
              21.009592428054678
            ],
            [
              105.81739981598936,
              21.009588637739448
            ],
            [
              105.81740444987514,
              21.00958553456955
            ],
            [
              105.81749190391,
              21.009487006727756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10953",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009761888862215,
          "to_latitude": 21.009487006727756,
          "to_longitude": 105.81749190391,
          "from_longitude": 105.81723935842518
        }
      },
      {
        "id": 10931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81736215060573,
            21.009635212069245,
            105.81736847495485,
            21.009640657963867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81736215060573,
              21.009635212069245
            ],
            [
              105.81736546741108,
              21.009638068403234
            ],
            [
              105.81736765735995,
              21.00963995556661
            ],
            [
              105.81736847495485,
              21.009640657963867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10954",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009635212069245,
          "to_latitude": 21.009640657963867,
          "to_longitude": 105.81736847495485,
          "from_longitude": 105.81736215060573
        }
      },
      {
        "id": 10932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81745236449551,
            21.00955047502593,
            105.81773893111513,
            21.00981363471821
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81745236449551,
              21.00955047502593
            ],
            [
              105.81746335727802,
              21.00956057179326
            ],
            [
              105.81773893111513,
              21.00981363471821
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10955",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00955047502593,
          "to_latitude": 21.00981363471821,
          "to_longitude": 105.81773893111513,
          "from_longitude": 105.81745236449551
        }
      },
      {
        "id": 10933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81745370203518,
            21.009548977371086,
            105.8177531410072,
            21.009807227577628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81745370203518,
              21.009548977371086
            ],
            [
              105.81746491470305,
              21.009558649468044
            ],
            [
              105.8177531410072,
              21.009807227577628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10956",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009548977371086,
          "to_latitude": 21.009807227577628,
          "to_longitude": 105.8177531410072,
          "from_longitude": 105.81745370203518
        }
      },
      {
        "id": 10934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81760412203303,
            21.009304049116736,
            105.81799332952083,
            21.009676065976596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81760412203303,
              21.00937878745119
            ],
            [
              105.81767978987085,
              21.009309596994044
            ],
            [
              105.81768585711582,
              21.009304049116736
            ],
            [
              105.81773216998207,
              21.009343817900355
            ],
            [
              105.8178379642784,
              21.009434664684125
            ],
            [
              105.81793795725336,
              21.009520529076408
            ],
            [
              105.81799043507884,
              21.009565591778298
            ],
            [
              105.81799332952083,
              21.00956807709686
            ],
            [
              105.8179863762582,
              21.009575016602195
            ],
            [
              105.81788512527099,
              21.009676065976596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_172_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10957",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00937878745119,
          "to_latitude": 21.009676065976596,
          "to_longitude": 105.81788512527099,
          "from_longitude": 105.81760412203303
        }
      },
      {
        "id": 10935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81793795725336,
            21.00951364898869,
            105.81794476366906,
            21.009520529076408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81793795725336,
              21.009520529076408
            ],
            [
              105.81793955959088,
              21.009518906402857
            ],
            [
              105.8179411189098,
              21.009517332710367
            ],
            [
              105.81794476366906,
              21.00951364898869
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_172_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10958",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009520529076408,
          "to_latitude": 21.00951364898869,
          "to_longitude": 105.81794476366906,
          "from_longitude": 105.81793795725336
        }
      },
      {
        "id": 10936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81635829473336,
            21.009591862614897,
            105.81636477803032,
            21.00959735836617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81635829473336,
              21.009591862614897
            ],
            [
              105.81636016850926,
              21.009593452339846
            ],
            [
              105.81636107784125,
              21.009594221140876
            ],
            [
              105.81636272355298,
              21.009595616862597
            ],
            [
              105.81636477803032,
              21.00959735836617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10959",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009591862614897,
          "to_latitude": 21.00959735836617,
          "to_longitude": 105.81636477803032,
          "from_longitude": 105.81635829473336
        }
      },
      {
        "id": 10937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81617457702393,
            21.009770445714917,
            105.81645148136334,
            21.010008431515676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81617457702393,
              21.00977641083565
            ],
            [
              105.81619156736201,
              21.009770445714917
            ],
            [
              105.81645148136334,
              21.010008431515676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10960",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00977641083565,
          "to_latitude": 21.010008431515676,
          "to_longitude": 105.81645148136334,
          "from_longitude": 105.81617457702393
        }
      },
      {
        "id": 10938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81618974127389,
            21.00976135444829,
            105.8164597337409,
            21.010002105452298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81618974127389,
              21.00976135444829
            ],
            [
              105.8164597337409,
              21.010002105452298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT52_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10961",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00976135444829,
          "to_latitude": 21.010002105452298,
          "to_longitude": 105.8164597337409,
          "from_longitude": 105.81618974127389
        }
      },
      {
        "id": 10939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81590230254517,
            21.009401182048958,
            105.81619956694497,
            21.00975159942244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81619956694497,
              21.00975159942244
            ],
            [
              105.81619385985837,
              21.009746272458578
            ],
            [
              105.81619197166275,
              21.009744507574702
            ],
            [
              105.81618030931517,
              21.009748529587025
            ],
            [
              105.81590230254517,
              21.009501113675345
            ],
            [
              105.8160067110932,
              21.009401182048958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10962",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00975159942244,
          "to_latitude": 21.009401182048958,
          "to_longitude": 105.8160067110932,
          "from_longitude": 105.81619956694497
        }
      },
      {
        "id": 10940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81608557642608,
            21.0098481060133,
            105.81609430496813,
            21.009856107401067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81608557642608,
              21.0098481060133
            ],
            [
              105.81608709031674,
              21.009849494240754
            ],
            [
              105.81609430496813,
              21.009856107401067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10963",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0098481060133,
          "to_latitude": 21.009856107401067,
          "to_longitude": 105.81609430496813,
          "from_longitude": 105.81608557642608
        }
      },
      {
        "id": 10941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81578001832915,
            21.009623241967457,
            105.81606996654862,
            21.009880271673282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81606996654862,
              21.009880271673282
            ],
            [
              105.81606258567884,
              21.009873693631782
            ],
            [
              105.81586792656665,
              21.009700203705393
            ],
            [
              105.81582866030149,
              21.009665975347804
            ],
            [
              105.81578001832915,
              21.009623241967457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10964",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009880271673282,
          "to_latitude": 21.009623241967457,
          "to_longitude": 105.81578001832915,
          "from_longitude": 105.81606996654862
        }
      },
      {
        "id": 10942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81548040965266,
            21.00912867370888,
            105.81612255221376,
            21.009480875332617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81611433486826,
              21.009263533285846
            ],
            [
              105.81611995537496,
              21.00927328377785
            ],
            [
              105.81612255221376,
              21.009277474948558
            ],
            [
              105.81592483591248,
              21.00946536062934
            ],
            [
              105.81592007622478,
              21.009461077761127
            ],
            [
              105.81591632743792,
              21.009457668887023
            ],
            [
              105.81591459321388,
              21.009456092036313
            ],
            [
              105.81590984305717,
              21.009451792863995
            ],
            [
              105.81588038813143,
              21.009480875332617
            ],
            [
              105.81559117728537,
              21.0092251595376
            ],
            [
              105.81558147217349,
              21.00922276462507
            ],
            [
              105.81557843140678,
              21.009214055817942
            ],
            [
              105.81548040965266,
              21.00912867370888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10965",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009263533285846,
          "to_latitude": 21.00912867370888,
          "to_longitude": 105.81548040965266,
          "from_longitude": 105.81611433486826
        }
      },
      {
        "id": 10943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81612657277066,
            21.00928027102918,
            105.81639695654042,
            21.0095238212074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81639695654042,
              21.0095238212074
            ],
            [
              105.81622873651283,
              21.009372296903052
            ],
            [
              105.81621378941377,
              21.00935883281664
            ],
            [
              105.81612657277066,
              21.00928027102918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10966",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0095238212074,
          "to_latitude": 21.00928027102918,
          "to_longitude": 105.81612657277066,
          "from_longitude": 105.81639695654042
        }
      },
      {
        "id": 10944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81621378941377,
            21.009342909267872,
            105.81623122571818,
            21.00935883281664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81623122571818,
              21.009342909267872
            ],
            [
              105.81623056911506,
              21.00934350854801
            ],
            [
              105.81622555750812,
              21.009348085760436
            ],
            [
              105.81621378941377,
              21.00935883281664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10967",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009342909267872,
          "to_latitude": 21.00935883281664,
          "to_longitude": 105.81621378941377,
          "from_longitude": 105.81623122571818
        }
      },
      {
        "id": 10945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81626867150432,
            21.009373198850746,
            105.81627363136003,
            21.009377917623034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81626867150432,
              21.009377917623034
            ],
            [
              105.8162704255895,
              21.009376249075398
            ],
            [
              105.81627363136003,
              21.009373198850746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10968",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009377917623034,
          "to_latitude": 21.009373198850746,
          "to_longitude": 105.81627363136003,
          "from_longitude": 105.81626867150432
        }
      },
      {
        "id": 10946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81659935154306,
            21.009465943091882,
            105.81680924805863,
            21.009656995466308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81680924805863,
              21.009656995466308
            ],
            [
              105.81659935154306,
              21.009465943091882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44A_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10969",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009656995466308,
          "to_latitude": 21.009465943091882,
          "to_longitude": 105.81659935154306,
          "from_longitude": 105.81680924805863
        }
      },
      {
        "id": 10947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81544569545622,
            21.00943921112498,
            105.81597287684639,
            21.010055744178292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81545736501361,
              21.00943921112498
            ],
            [
              105.81550711587217,
              21.009451851845387
            ],
            [
              105.8154899061199,
              21.0095082370651
            ],
            [
              105.81545711405906,
              21.009574626145216
            ],
            [
              105.81544569545622,
              21.009583322049906
            ],
            [
              105.81586453862215,
              21.010055744178292
            ],
            [
              105.81596374311408,
              21.00996809162273
            ],
            [
              105.81596569130508,
              21.009969922157083
            ],
            [
              105.81597287684639,
              21.009976665526892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10970",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00943921112498,
          "to_latitude": 21.009976665526892,
          "to_longitude": 105.81597287684639,
          "from_longitude": 105.81545736501361
        }
      },
      {
        "id": 10948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81595969682088,
            21.009989750569368,
            105.81623658748923,
            21.010222955789583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81595969682088,
              21.009989750569368
            ],
            [
              105.81597787801023,
              21.010004122267322
            ],
            [
              105.81623311731985,
              21.010220020685107
            ],
            [
              105.81623658748923,
              21.010222955789583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10971",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009989750569368,
          "to_latitude": 21.010222955789583,
          "to_longitude": 105.81623658748923,
          "from_longitude": 105.81595969682088
        }
      },
      {
        "id": 10949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81596913698411,
            21.010004122267322,
            105.81617530296874,
            21.010206405440567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81597787801023,
              21.010004122267322
            ],
            [
              105.81596913698411,
              21.010012928206347
            ],
            [
              105.81617530296874,
              21.010206405440567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10972",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010004122267322,
          "to_latitude": 21.010206405440567,
          "to_longitude": 105.81617530296874,
          "from_longitude": 105.81597787801023
        }
      },
      {
        "id": 10950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81587217840887,
            21.01008393343447,
            105.81631804012753,
            21.01068393674761
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81587217840887,
              21.01008393343447
            ],
            [
              105.81627498621982,
              21.01065991780124
            ],
            [
              105.81631337996619,
              21.01068133703231
            ],
            [
              105.81631804012753,
              21.01068393674761
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT23_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10973",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01008393343447,
          "to_latitude": 21.01068393674761,
          "to_longitude": 105.81631804012753,
          "from_longitude": 105.81587217840887
        }
      },
      {
        "id": 10951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81630606259688,
            21.010688128668416,
            105.81718337975246,
            21.011710580459635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81631370164335,
              21.010688128668416
            ],
            [
              105.8163162932709,
              21.01069082592022
            ],
            [
              105.81630606259688,
              21.01070075992887
            ],
            [
              105.8163624688666,
              21.01078666993906
            ],
            [
              105.81683509965134,
              21.01131860429693
            ],
            [
              105.81718337975246,
              21.011710580459635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10974",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 146.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010688128668416,
          "to_latitude": 21.011710580459635,
          "to_longitude": 105.81718337975246,
          "from_longitude": 105.81631370164335
        }
      },
      {
        "id": 10952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.815572801836,
            21.009231830334894,
            105.81585579111795,
            21.00946826857978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.815572801836,
              21.009231830334894
            ],
            [
              105.81565246060899,
              21.009298729123522
            ],
            [
              105.81577508845812,
              21.009401715414093
            ],
            [
              105.81579428637687,
              21.00940920417665
            ],
            [
              105.8158301803378,
              21.00944842188843
            ],
            [
              105.81585579111795,
              21.00946826857978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10975",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009231830334894,
          "to_latitude": 21.00946826857978,
          "to_longitude": 105.81585579111795,
          "from_longitude": 105.815572801836
        }
      },
      {
        "id": 10953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81583229725639,
            21.008980631541704,
            105.8161073719709,
            21.009220965912057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8161073719709,
              21.009220965912057
            ],
            [
              105.81597807154996,
              21.009102271294733
            ],
            [
              105.81596854786532,
              21.009094324292384
            ],
            [
              105.81583229725639,
              21.008980631541704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10976",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009220965912057,
          "to_latitude": 21.008980631541704,
          "to_longitude": 105.81583229725639,
          "from_longitude": 105.8161073719709
        }
      },
      {
        "id": 10954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81596031424048,
            21.009094324292384,
            105.81596854786532,
            21.009102501860134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81596854786532,
              21.009094324292384
            ],
            [
              105.81596663536142,
              21.009096223919823
            ],
            [
              105.81596182013418,
              21.009101006119064
            ],
            [
              105.81596031424048,
              21.009102501860134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10977",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009094324292384,
          "to_latitude": 21.009102501860134,
          "to_longitude": 105.81596031424048,
          "from_longitude": 105.81596854786532
        }
      },
      {
        "id": 10955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81546550500916,
            21.008873185778743,
            105.81566676027812,
            21.009073270644446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81546550500916,
              21.009073270644446
            ],
            [
              105.81557885622408,
              21.008960578062116
            ],
            [
              105.81566676027812,
              21.008873185778743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10978",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009073270644446,
          "to_latitude": 21.008873185778743,
          "to_longitude": 105.81566676027812,
          "from_longitude": 105.81546550500916
        }
      },
      {
        "id": 10956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81556953019046,
            21.00895306454212,
            105.81557885622408,
            21.008960578062116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81557885622408,
              21.008960578062116
            ],
            [
              105.81556953019046,
              21.00895306454212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10979",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008960578062116,
          "to_latitude": 21.00895306454212,
          "to_longitude": 105.81556953019046,
          "from_longitude": 105.81557885622408
        }
      },
      {
        "id": 10957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8153082205944,
            21.00858792744232,
            105.81562809279458,
            21.008880758619025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8153082205944,
              21.00858792744232
            ],
            [
              105.81550551922946,
              21.008768547268534
            ],
            [
              105.81552299630454,
              21.00878454640159
            ],
            [
              105.81562809279458,
              21.008880758619025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10980",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00858792744232,
          "to_latitude": 21.008880758619025,
          "to_longitude": 105.81562809279458,
          "from_longitude": 105.8153082205944
        }
      },
      {
        "id": 10958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81550551922946,
            21.008735640350697,
            105.81553690980502,
            21.008768547268534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81550551922946,
              21.008768547268534
            ],
            [
              105.81551058744247,
              21.00876323457765
            ],
            [
              105.81552636976531,
              21.008746689475785
            ],
            [
              105.81553690980502,
              21.008735640350697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10981",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008768547268534,
          "to_latitude": 21.008735640350697,
          "to_longitude": 105.81553690980502,
          "from_longitude": 105.81550551922946
        }
      },
      {
        "id": 10959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81683625959403,
            21.005415311537465,
            105.81707746340454,
            21.00577186903013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81683625959403,
              21.00577186903013
            ],
            [
              105.816889974919,
              21.005685687983345
            ],
            [
              105.81689353984399,
              21.00568431331998
            ],
            [
              105.81689361641334,
              21.005681769460676
            ],
            [
              105.81689366290057,
              21.005680237361815
            ],
            [
              105.81697321122404,
              21.005564543946456
            ],
            [
              105.81698053176034,
              21.00555406477373
            ],
            [
              105.81707746340454,
              21.005415311537465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68_Đường Láng",
          "maTaiSan": "04.O11.DODV.10982",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00577186903013,
          "to_latitude": 21.005415311537465,
          "to_longitude": 105.81707746340454,
          "from_longitude": 105.81683625959403
        }
      },
      {
        "id": 10960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81695340092419,
            21.005537598180364,
            105.81698053176034,
            21.00555406477373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81695340092419,
              21.005537598180364
            ],
            [
              105.81695843662351,
              21.005540654018095
            ],
            [
              105.81697184812232,
              21.00554879547284
            ],
            [
              105.81698053176034,
              21.00555406477373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68_Đường Láng",
          "maTaiSan": "04.O11.DODV.10983",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005537598180364,
          "to_latitude": 21.00555406477373,
          "to_longitude": 105.81698053176034,
          "from_longitude": 105.81695340092419
        }
      },
      {
        "id": 10961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81709453375123,
            21.005018474606175,
            105.81736674038379,
            21.00538491813639
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81709453375123,
              21.00538491813639
            ],
            [
              105.81720271201031,
              21.00522808149195
            ],
            [
              105.81722314726643,
              21.005198454118005
            ],
            [
              105.81736674038379,
              21.005018474606175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10984",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00538491813639,
          "to_latitude": 21.005018474606175,
          "to_longitude": 105.81736674038379,
          "from_longitude": 105.81709453375123
        }
      },
      {
        "id": 10962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816868257142,
            21.005667033969594,
            105.81712770577214,
            21.00583236915902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816868257142,
              21.005667033969594
            ],
            [
              105.81688415530334,
              21.00567627356388
            ],
            [
              105.81689361641334,
              21.005681769460676
            ],
            [
              105.81698075234529,
              21.00573240259053
            ],
            [
              105.81712770577214,
              21.00583236915902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A67_Đường Láng",
          "maTaiSan": "04.O11.DODV.10985",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005667033969594,
          "to_latitude": 21.00583236915902,
          "to_longitude": 105.81712770577214,
          "from_longitude": 105.816868257142
        }
      },
      {
        "id": 10963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81717785439486,
            21.005217318745405,
            105.81720271201031,
            21.00522808149195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81717785439486,
              21.005217318745405
            ],
            [
              105.81717962263859,
              21.00521808432237
            ],
            [
              105.81719189429592,
              21.005223398708385
            ],
            [
              105.81720271201031,
              21.00522808149195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A68A_Đường Láng",
          "maTaiSan": "04.O11.DODV.10986",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005217318745405,
          "to_latitude": 21.00522808149195,
          "to_longitude": 105.81720271201031,
          "from_longitude": 105.81717785439486
        }
      },
      {
        "id": 10964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81464355106964,
            21.008289438467965,
            105.81498669908784,
            21.008661086400743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81498669908784,
              21.008289438467965
            ],
            [
              105.81475029779695,
              21.008545476027752
            ],
            [
              105.81473790930188,
              21.00855889318863
            ],
            [
              105.81464355106964,
              21.008661086400743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A28_Đường Láng",
          "maTaiSan": "04.O11.DODV.10987",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008289438467965,
          "to_latitude": 21.008661086400743,
          "to_longitude": 105.81464355106964,
          "from_longitude": 105.81498669908784
        }
      },
      {
        "id": 10965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81471395340483,
            21.008543143570154,
            105.81473790930188,
            21.00855889318863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81471395340483,
              21.008543143570154
            ],
            [
              105.8147208015323,
              21.00854764589597
            ],
            [
              105.81473300827776,
              21.008555673158625
            ],
            [
              105.81473790930188,
              21.00855889318863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A28_Đường Láng",
          "maTaiSan": "04.O11.DODV.10988",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008543143570154,
          "to_latitude": 21.00855889318863,
          "to_longitude": 105.81473790930188,
          "from_longitude": 105.81471395340483
        }
      },
      {
        "id": 10966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.815822329701,
            21.009007099662778,
            105.81605833675721,
            21.009211501298203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81605833675721,
              21.009211501298203
            ],
            [
              105.81594513833744,
              21.00911346214195
            ],
            [
              105.81594159243227,
              21.009110391005922
            ],
            [
              105.815822329701,
              21.009007099662778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10989",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009211501298203,
          "to_latitude": 21.009007099662778,
          "to_longitude": 105.815822329701,
          "from_longitude": 105.81605833675721
        }
      },
      {
        "id": 10967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81594159243227,
            21.009097403831138,
            105.81595451801418,
            21.009110391005922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81594159243227,
              21.009110391005922
            ],
            [
              105.81594381669494,
              21.009108156592653
            ],
            [
              105.81594816285464,
              21.00910379032161
            ],
            [
              105.81595031057985,
              21.00910163214642
            ],
            [
              105.81595451801418,
              21.009097403831138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT61_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10990",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009110391005922,
          "to_latitude": 21.009097403831138,
          "to_longitude": 105.81595451801418,
          "from_longitude": 105.81594159243227
        }
      },
      {
        "id": 10968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81643323983586,
            21.00855213143187,
            105.81647816704898,
            21.00870186650458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81647816704898,
              21.00855213143187
            ],
            [
              105.81645470381868,
              21.008630316355745
            ],
            [
              105.81643323983586,
              21.00870186650458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10991",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00855213143187,
          "to_latitude": 21.00870186650458,
          "to_longitude": 105.81643323983586,
          "from_longitude": 105.81647816704898
        }
      },
      {
        "id": 10969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81633835561178,
            21.008675673056636,
            105.81650298966645,
            21.008726596444692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81633835561178,
              21.00872538743972
            ],
            [
              105.8163658011947,
              21.008675673056636
            ],
            [
              105.81643323983586,
              21.00870186650458
            ],
            [
              105.81645257728792,
              21.008709377106314
            ],
            [
              105.81647922226134,
              21.008718478456355
            ],
            [
              105.81650298966645,
              21.008726596444692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10992",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00872538743972,
          "to_latitude": 21.008726596444692,
          "to_longitude": 105.81650298966645,
          "from_longitude": 105.81633835561178
        }
      },
      {
        "id": 10970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81517000913814,
            21.009341538953393,
            105.81526618322172,
            21.00943721967137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81517000913814,
              21.009341538953393
            ],
            [
              105.81517499576783,
              21.009348469925484
            ],
            [
              105.81517074516177,
              21.0093530786882
            ],
            [
              105.81526618322172,
              21.00943721967137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_Yên Lãng",
          "maTaiSan": "04.O11.DODV.10993",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009341538953393,
          "to_latitude": 21.00943721967137,
          "to_longitude": 105.81526618322172,
          "from_longitude": 105.81517000913814
        }
      },
      {
        "id": 10971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81558755347307,
            21.009298729123522,
            105.81565246060899,
            21.009367020806728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81558755347307,
              21.009367020806728
            ],
            [
              105.81565246060899,
              21.009298729123522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10994",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009367020806728,
          "to_latitude": 21.009298729123522,
          "to_longitude": 105.81565246060899,
          "from_longitude": 105.81558755347307
        }
      },
      {
        "id": 10972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81511888983712,
            21.008288676188453,
            105.81517092519621,
            21.008332910047848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81517092519621,
              21.008332910047848
            ],
            [
              105.81517060629785,
              21.008332638800372
            ],
            [
              105.81511888983712,
              21.008288676188453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10995",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008332910047848,
          "to_latitude": 21.008288676188453,
          "to_longitude": 105.81511888983712,
          "from_longitude": 105.81517092519621
        }
      },
      {
        "id": 10973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81681319077624,
            21.010648293710343,
            105.81690522565661,
            21.010737782071875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81681319077624,
              21.010737782071875
            ],
            [
              105.81690522565661,
              21.010648293710343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10996",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010737782071875,
          "to_latitude": 21.010648293710343,
          "to_longitude": 105.81690522565661,
          "from_longitude": 105.81681319077624
        }
      },
      {
        "id": 10974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81703887526632,
            21.010435927753157,
            105.81712454507785,
            21.010517796743965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81703887526632,
              21.010517796743965
            ],
            [
              105.81712454507785,
              21.010435927753157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10997",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010517796743965,
          "to_latitude": 21.010435927753157,
          "to_longitude": 105.81712454507785,
          "from_longitude": 105.81703887526632
        }
      },
      {
        "id": 10975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8177531410072,
            21.009697149140838,
            105.81786069150556,
            21.009807227577628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8177531410072,
              21.009807227577628
            ],
            [
              105.81786069150556,
              21.009697149140838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10998",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009807227577628,
          "to_latitude": 21.009697149140838,
          "to_longitude": 105.81786069150556,
          "from_longitude": 105.8177531410072
        }
      },
      {
        "id": 10976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81669569172081,
            21.009656995466308,
            105.81680924805863,
            21.009767265568183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81669569172081,
              21.009767265568183
            ],
            [
              105.81680924805863,
              21.009656995466308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44A_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.10999",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009767265568183,
          "to_latitude": 21.009656995466308,
          "to_longitude": 105.81680924805863,
          "from_longitude": 105.81669569172081
        }
      },
      {
        "id": 10977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81643097331474,
            21.009414970572358,
            105.81653398043102,
            21.009532151062356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81643097331474,
              21.009532151062356
            ],
            [
              105.81643886399627,
              21.00952317395415
            ],
            [
              105.81653398043102,
              21.009414970572358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57A_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11000",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009532151062356,
          "to_latitude": 21.009414970572358,
          "to_longitude": 105.81653398043102,
          "from_longitude": 105.81643097331474
        }
      },
      {
        "id": 10978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81624416167067,
            21.00915607043383,
            105.81653398043102,
            21.009414970572358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81624416167067,
              21.00915607043383
            ],
            [
              105.81626729134399,
              21.00917673283784
            ],
            [
              105.81653398043102,
              21.009414970572358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57A_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11001",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00915607043383,
          "to_latitude": 21.009414970572358,
          "to_longitude": 105.81653398043102,
          "from_longitude": 105.81624416167067
        }
      },
      {
        "id": 10979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81612412067398,
            21.009010619918044,
            105.81637409133494,
            21.009246984371902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81612412067398,
              21.009246984371902
            ],
            [
              105.81613060872562,
              21.009241156336007
            ],
            [
              105.81637409133494,
              21.009010619918044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59A_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11002",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009246984371902,
          "to_latitude": 21.009010619918044,
          "to_longitude": 105.81637409133494,
          "from_longitude": 105.81612412067398
        }
      },
      {
        "id": 10980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81623658748923,
            21.010090711439624,
            105.81637177456668,
            21.010222955789583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81623658748923,
              21.010222955789583
            ],
            [
              105.81623667169244,
              21.010222874095135
            ],
            [
              105.81637177456668,
              21.010090711439624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT47_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11003",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010222955789583,
          "to_latitude": 21.010090711439624,
          "to_longitude": 105.81637177456668,
          "from_longitude": 105.81623658748923
        }
      },
      {
        "id": 10981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81625234447813,
            21.0101466050861,
            105.81633994048887,
            21.0102348055293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81625234447813,
              21.0102348055293
            ],
            [
              105.81633994048887,
              21.0101466050861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11004",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0102348055293,
          "to_latitude": 21.0101466050861,
          "to_longitude": 105.81633994048887,
          "from_longitude": 105.81625234447813
        }
      },
      {
        "id": 10982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81638831417628,
            21.010020203904947,
            105.81646547186888,
            21.01009789765786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81638831417628,
              21.01009789765786
            ],
            [
              105.81646547186888,
              21.010020203904947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11005",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01009789765786,
          "to_latitude": 21.010020203904947,
          "to_longitude": 105.81646547186888,
          "from_longitude": 105.81638831417628
        }
      },
      {
        "id": 10983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81695230994067,
            21.010526277488502,
            105.81703071638213,
            21.010602513354897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81695230994067,
              21.010602513354897
            ],
            [
              105.81703071638213,
              21.010526277488502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11006",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010602513354897,
          "to_latitude": 21.010526277488502,
          "to_longitude": 105.81703071638213,
          "from_longitude": 105.81695230994067
        }
      },
      {
        "id": 10984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81355279279099,
            21.01026016599279,
            105.81395405600459,
            21.010531180225083
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81383877387263,
                21.010443974280136
              ],
              [
                105.81383271000098,
                21.01044024856019
              ],
              [
                105.81377808296703,
                21.010397092515607
              ],
              [
                105.81370969363807,
                21.010344652943104
              ],
              [
                105.81367671823168,
                21.010322073649967
              ],
              [
                105.81364902793214,
                21.010303112703696
              ],
              [
                105.81358630962069,
                21.01026016599279
              ],
              [
                105.81357563059997,
                21.010262128282616
              ],
              [
                105.81355279279099,
                21.01036834516577
              ]
            ],
            [
              [
                105.81395405600459,
                21.010514803233374
              ],
              [
                105.81383877387263,
                21.010443974280136
              ]
            ],
            [
              [
                105.81378057919311,
                21.010531180225083
              ],
              [
                105.81383877387263,
                21.010443974280136
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11007",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81383271000098,
            21.01044024856019
          ],
          "to_latitude": [
            105.81383877387263,
            21.010443974280136
          ],
          "to_longitude": [
            105.81378057919311,
            21.010531180225083
          ],
          "from_longitude": [
            105.81383877387263,
            21.010443974280136
          ]
        }
      },
      {
        "id": 10985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81291832254065,
            21.010195324906977,
            105.81354734658156,
            21.0105071974563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81354734658156,
              21.010396570907
            ],
            [
              105.81353744332056,
              21.01044775996554
            ],
            [
              105.81350162577853,
              21.0105071974563
            ],
            [
              105.81331675542647,
              21.010394805448488
            ],
            [
              105.81325303644641,
              21.010471582752825
            ],
            [
              105.81324882241076,
              21.010476389101765
            ],
            [
              105.81315330058274,
              21.01040830769463
            ],
            [
              105.81291832254065,
              21.01023884571607
            ],
            [
              105.81294753436235,
              21.010200976487777
            ],
            [
              105.81294438628433,
              21.010198357712177
            ],
            [
              105.81294074442926,
              21.010195324906977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B7_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11008",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010396570907,
          "to_latitude": 21.010195324906977,
          "to_longitude": 105.81294074442926,
          "from_longitude": 105.81354734658156
        }
      },
      {
        "id": 10986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81364902793214,
            21.010299485993905,
            105.81365153519216,
            21.010303112703696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81364902793214,
              21.010303112703696
            ],
            [
              105.81365029062165,
              21.010301284853817
            ],
            [
              105.81365143497834,
              21.010299630989678
            ],
            [
              105.81365153519216,
              21.010299485993905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B6_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11009",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010303112703696,
          "to_latitude": 21.010299485993905,
          "to_longitude": 105.81365153519216,
          "from_longitude": 105.81364902793214
        }
      },
      {
        "id": 10987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81336806623763,
            21.010112645345103,
            105.81363464062667,
            21.01021703478953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81363464062667,
              21.010131750496807
            ],
            [
              105.81355121820047,
              21.01021703478953
            ],
            [
              105.81348335284133,
              21.010178354208254
            ],
            [
              105.81347400404854,
              21.010173025592685
            ],
            [
              105.81336806623763,
              21.010112645345103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B51_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11010",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010131750496807,
          "to_latitude": 21.010112645345103,
          "to_longitude": 105.81336806623763,
          "from_longitude": 105.81363464062667
        }
      },
      {
        "id": 10988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81318185689335,
            21.0098720791726,
            105.81335656779125,
            21.010104336407448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81335656779125,
              21.010104336407448
            ],
            [
              105.81325728254359,
              21.010038265561512
            ],
            [
              105.81322383092547,
              21.010016003678764
            ],
            [
              105.81318185689335,
              21.00998807140013
            ],
            [
              105.81322918278919,
              21.009938949847882
            ],
            [
              105.81330008200769,
              21.0098720791726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11011",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010104336407448,
          "to_latitude": 21.0098720791726,
          "to_longitude": 105.81330008200769,
          "from_longitude": 105.81335656779125
        }
      },
      {
        "id": 10989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81321586767228,
            21.010016003678764,
            105.81322383092547,
            21.010025120087466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81322383092547,
              21.010016003678764
            ],
            [
              105.81321852049841,
              21.010022083399754
            ],
            [
              105.81321659516378,
              21.010024286543576
            ],
            [
              105.81321586767228,
              21.010025120087466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B5_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11012",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010016003678764,
          "to_latitude": 21.010025120087466,
          "to_longitude": 105.81321586767228,
          "from_longitude": 105.81322383092547
        }
      },
      {
        "id": 10990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81377179738993,
            21.009902729518863,
            105.81423210326797,
            21.010237269313937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81423210326797,
              21.010237269313937
            ],
            [
              105.81402473052047,
              21.010105484139594
            ],
            [
              105.81391867021554,
              21.010033724144026
            ],
            [
              105.81389991174309,
              21.01002071072801
            ],
            [
              105.8138999057594,
              21.010015351900925
            ],
            [
              105.81389987602307,
              21.009990719190792
            ],
            [
              105.81380685868538,
              21.00992239765069
            ],
            [
              105.81377179738993,
              21.009902729518863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11013",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010237269313937,
          "to_latitude": 21.009902729518863,
          "to_longitude": 105.81377179738993,
          "from_longitude": 105.81423210326797
        }
      },
      {
        "id": 10991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8138999057594,
            21.010013593694097,
            105.81390662474277,
            21.010015351900925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8138999057594,
              21.010015351900925
            ],
            [
              105.81390189120077,
              21.010014832983636
            ],
            [
              105.81390662474277,
              21.010013593694097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B3_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11014",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010015351900925,
          "to_latitude": 21.010013593694097,
          "to_longitude": 105.81390662474277,
          "from_longitude": 105.8138999057594
        }
      },
      {
        "id": 10992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81390629859037,
            21.009818606745736,
            105.81420037680438,
            21.010030246671658
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.81398538537866,
                21.00989921505407
              ],
              [
                105.81404347121519,
                21.009818606745736
              ]
            ],
            [
              [
                105.81390629859037,
                21.010013226736792
              ],
              [
                105.81390666945425,
                21.010012615283607
              ],
              [
                105.81398538537866,
                21.00989921505407
              ]
            ],
            [
              [
                105.81398538537866,
                21.00989921505407
              ],
              [
                105.81413292499948,
                21.009981817632028
              ],
              [
                105.81420037680438,
                21.010030246671658
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B2_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11015",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.81404347121519,
            21.009818606745736
          ],
          "to_latitude": [
            105.81413292499948,
            21.009981817632028
          ],
          "to_longitude": [
            105.81398538537866,
            21.00989921505407
          ],
          "from_longitude": [
            105.81398538537866,
            21.00989921505407
          ]
        }
      },
      {
        "id": 10993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81352813672929,
            21.009606931436895,
            105.81395754336027,
            21.00989590480306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81395754336027,
              21.00967319331822
            ],
            [
              105.81378141307754,
              21.00989590480306
            ],
            [
              105.81366824231289,
              21.009808119239217
            ],
            [
              105.81364857790173,
              21.00977685858246
            ],
            [
              105.81363489639965,
              21.00974131601259
            ],
            [
              105.81352813672929,
              21.009654738021215
            ],
            [
              105.81356651573418,
              21.009609692068043
            ],
            [
              105.81356434606835,
              21.009607989016086
            ],
            [
              105.81356300232852,
              21.009606931436895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B1_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11016",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00967319331822,
          "to_latitude": 21.009606931436895,
          "to_longitude": 105.81356300232852,
          "from_longitude": 105.81395754336027
        }
      },
      {
        "id": 10994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81418947754089,
            21.01127421001936,
            105.81419321442509,
            21.01128097230719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81418947754089,
              21.01128097230719
            ],
            [
              105.81419187798922,
              21.011276628937402
            ],
            [
              105.81419311344533,
              21.011274391148234
            ],
            [
              105.81419321442509,
              21.01127421001936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B13_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11017",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01128097230719,
          "to_latitude": 21.01127421001936,
          "to_longitude": 105.81419321442509,
          "from_longitude": 105.81418947754089
        }
      },
      {
        "id": 10995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81290425538243,
            21.010525185998087,
            105.81291160156667,
            21.010537951199012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81290425538243,
              21.010537951199012
            ],
            [
              105.81291058573733,
              21.01052695124387
            ],
            [
              105.81291160156667,
              21.010525185998087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B9_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11018",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010537951199012,
          "to_latitude": 21.010525185998087,
          "to_longitude": 105.81291160156667,
          "from_longitude": 105.81290425538243
        }
      },
      {
        "id": 10996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81710650213931,
            21.014937701128687,
            105.81715044206621,
            21.014991675619164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81715044206621,
              21.014991675619164
            ],
            [
              105.81713835236116,
              21.0149768250106
            ],
            [
              105.81710650213931,
              21.014937701128687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3A_Thái Hà",
          "maTaiSan": "04.O11.DODV.11019",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014991675619164,
          "to_latitude": 21.014937701128687,
          "to_longitude": 105.81710650213931,
          "from_longitude": 105.81715044206621
        }
      },
      {
        "id": 10997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8174745318109,
            21.014670104275925,
            105.81751732298886,
            21.014721562379957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81751732298886,
              21.014721562379957
            ],
            [
              105.81750841481738,
              21.01471085045363
            ],
            [
              105.8174745318109,
              21.014670104275925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A3_Thái Hà",
          "maTaiSan": "04.O11.DODV.11020",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014721562379957,
          "to_latitude": 21.014670104275925,
          "to_longitude": 105.8174745318109,
          "from_longitude": 105.81751732298886
        }
      },
      {
        "id": 10998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81383425309414,
            21.01184577658453,
            105.81429835154978,
            21.01231888248124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81429835154978,
              21.01184577658453
            ],
            [
              105.81407274343586,
              21.012174019907633
            ],
            [
              105.81405276291656,
              21.012186156439032
            ],
            [
              105.81395813248453,
              21.012243636283852
            ],
            [
              105.81383425309414,
              21.01231888248124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.11021",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01184577658453,
          "to_latitude": 21.01231888248124,
          "to_longitude": 105.81383425309414,
          "from_longitude": 105.81429835154978
        }
      },
      {
        "id": 10999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81395813248453,
            21.012243636283852,
            105.81396447676472,
            21.012250097437164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81395813248453,
              21.012243636283852
            ],
            [
              105.81395986583223,
              21.012245403739083
            ],
            [
              105.81396447676472,
              21.012250097437164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DODV.11022",
          "diaChiLapD": "Ngõ 105, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.012243636283852,
          "to_latitude": 21.012250097437164,
          "to_longitude": 105.81396447676472,
          "from_longitude": 105.81395813248453
        }
      },
      {
        "id": 11000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81683509965134,
            21.01112134553181,
            105.8169762211218,
            21.01131860429693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81683509965134,
              21.01131860429693
            ],
            [
              105.81693173347884,
              21.011177480680495
            ],
            [
              105.8169762211218,
              21.01112134553181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT22_Yên Lãng",
          "maTaiSan": "04.O11.DODV.11023",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01131860429693,
          "to_latitude": 21.01112134553181,
          "to_longitude": 105.8169762211218,
          "from_longitude": 105.81683509965134
        }
      },
      {
        "id": 11001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8173686526818,
            21.01169543036408,
            105.81749933135899,
            21.011926243072814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8173686526818,
              21.011926243072814
            ],
            [
              105.81740239919999,
              21.011841959734614
            ],
            [
              105.81741346912455,
              21.01182522483956
            ],
            [
              105.81749933135899,
              21.01169543036408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11024",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011926243072814,
          "to_latitude": 21.01169543036408,
          "to_longitude": 105.81749933135899,
          "from_longitude": 105.8173686526818
        }
      },
      {
        "id": 11002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81740184553739,
            21.011817139885316,
            105.81741346912455,
            21.01182522483956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81741346912455,
              21.01182522483956
            ],
            [
              105.81740184553739,
              21.011817139885316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A76_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11025",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01182522483956,
          "to_latitude": 21.011817139885316,
          "to_longitude": 105.81740184553739,
          "from_longitude": 105.81741346912455
        }
      },
      {
        "id": 11003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8171052014249,
            21.011001830853917,
            105.81739625358375,
            21.011301015344262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81739625358375,
              21.011301015344262
            ],
            [
              105.81717790946873,
              21.011104510048575
            ],
            [
              105.81710778118334,
              21.011041423955486
            ],
            [
              105.8171052014249,
              21.011038966918075
            ],
            [
              105.81713808812907,
              21.011005931492935
            ],
            [
              105.8171346656501,
              21.011002222112666
            ],
            [
              105.81713432300297,
              21.011001830853917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11026",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011301015344262,
          "to_latitude": 21.011001830853917,
          "to_longitude": 105.81713432300297,
          "from_longitude": 105.81739625358375
        }
      },
      {
        "id": 11004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81711917495936,
            21.011000066548462,
            105.81752575343859,
            21.011304058731536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81713607849888,
              21.011000066548462
            ],
            [
              105.81713633836978,
              21.011000448269193
            ],
            [
              105.81714145146893,
              21.011006376891032
            ],
            [
              105.81712091751075,
              21.0110286190364
            ],
            [
              105.81711917495936,
              21.01103028753739
            ],
            [
              105.81740866944048,
              21.0112972154341
            ],
            [
              105.81741685027197,
              21.011304058731536
            ],
            [
              105.81752575343859,
              21.011191136951528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT3_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11027",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011000066548462,
          "to_latitude": 21.011191136951528,
          "to_longitude": 105.81752575343859,
          "from_longitude": 105.81713607849888
        }
      },
      {
        "id": 11005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81710382594427,
            21.011104510048575,
            105.81717790946873,
            21.011179388447975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81710382594427,
              21.011179388447975
            ],
            [
              105.8171778865114,
              21.011104533642754
            ],
            [
              105.81717790946873,
              21.011104510048575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT1_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11028",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011179388447975,
          "to_latitude": 21.011104510048575,
          "to_longitude": 105.81717790946873,
          "from_longitude": 105.81710382594427
        }
      },
      {
        "id": 11006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172282127417,
            21.01089739402242,
            105.81723484504228,
            21.010904978788055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81723321558503,
              21.010904978788055
            ],
            [
              105.81723484504228,
              21.010903209667564
            ],
            [
              105.81723353440525,
              21.010902055314205
            ],
            [
              105.8172282127417,
              21.01089739402242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11029",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010904978788055,
          "to_latitude": 21.01089739402242,
          "to_longitude": 105.8172282127417,
          "from_longitude": 105.81723321558503
        }
      },
      {
        "id": 11007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81715229524777,
            21.01082902110275,
            105.81729504985594,
            21.0109733813809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81715229524777,
              21.0109733813809
            ],
            [
              105.8172282127417,
              21.01089739402242
            ],
            [
              105.81729504985594,
              21.01082902110275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT4_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11030",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0109733813809,
          "to_latitude": 21.01082902110275,
          "to_longitude": 105.81729504985594,
          "from_longitude": 105.81715229524777
        }
      },
      {
        "id": 11008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8169061724666,
            21.010824658901647,
            105.81713495054123,
            21.011009942367256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81713495054123,
              21.011001192871074
            ],
            [
              105.8171287491958,
              21.010995910766823
            ],
            [
              105.81711314744359,
              21.011009942367256
            ],
            [
              105.8169061724666,
              21.010824658901647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11031",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011001192871074,
          "to_latitude": 21.010824658901647,
          "to_longitude": 105.8169061724666,
          "from_longitude": 105.81713495054123
        }
      },
      {
        "id": 11009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81879108035996,
            21.011484803554247,
            105.81880214528056,
            21.011493622715253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81880214528056,
              21.011493622715253
            ],
            [
              105.81879498443304,
              21.011487915355037
            ],
            [
              105.81879229284272,
              21.01148577139925
            ],
            [
              105.81879108035996,
              21.011484803554247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A43_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DODV.11032",
          "diaChiLapD": "Ngõ 117, Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011493622715253,
          "to_latitude": 21.011484803554247,
          "to_longitude": 105.81879108035996,
          "from_longitude": 105.81880214528056
        }
      },
      {
        "id": 11010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82259369255358,
            21.00661950855131,
            105.82259902526138,
            21.00662302523444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82259369255358,
              21.00661950855131
            ],
            [
              105.82259514736741,
              21.006620467973054
            ],
            [
              105.8225953249974,
              21.006620585437307
            ],
            [
              105.82259591967389,
              21.00662097907987
            ],
            [
              105.82259902526138,
              21.00662302523444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Tây Sơn",
          "maTaiSan": "04.O11.DODV.11033",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00661950855131,
          "to_latitude": 21.00662302523444,
          "to_longitude": 105.82259902526138,
          "from_longitude": 105.82259369255358
        }
      },
      {
        "id": 11011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82253434833352,
            21.006461120107115,
            105.82276135437377,
            21.006701284568113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82253434833352,
              21.006701284568113
            ],
            [
              105.82259902526138,
              21.00662302523444
            ],
            [
              105.82264044516897,
              21.006572905447353
            ],
            [
              105.82264529566363,
              21.006567241345376
            ],
            [
              105.8226910108011,
              21.006513872649293
            ],
            [
              105.82276135437377,
              21.006461120107115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38_Tây Sơn",
          "maTaiSan": "04.O11.DODV.11034",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006701284568113,
          "to_latitude": 21.006461120107115,
          "to_longitude": 105.82276135437377,
          "from_longitude": 105.82253434833352
        }
      },
      {
        "id": 11012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8184215142755,
            21.008262442577735,
            105.81842349832225,
            21.008272785836404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81842349832225,
              21.008272785836404
            ],
            [
              105.81842320826827,
              21.008271278840436
            ],
            [
              105.81842164298833,
              21.00826311486366
            ],
            [
              105.8184215142755,
              21.008262442577735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A75A_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11035",
          "diaChiLapD": "Ngõ 51, Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008272785836404,
          "to_latitude": 21.008262442577735,
          "to_longitude": 105.8184215142755,
          "from_longitude": 105.81842349832225
        }
      },
      {
        "id": 11013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82128794288474,
            21.00448722981904,
            105.82171509993354,
            21.00479125075275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82128794288474,
              21.004789144265164
            ],
            [
              105.82131149424525,
              21.00479125075275
            ],
            [
              105.82138259987461,
              21.004763377225682
            ],
            [
              105.82139500598647,
              21.00475686017709
            ],
            [
              105.8214443158527,
              21.00473096058934
            ],
            [
              105.82149171110316,
              21.00468599495784
            ],
            [
              105.8215530410941,
              21.004657403510194
            ],
            [
              105.82152725695958,
              21.004580562107126
            ],
            [
              105.82168259213407,
              21.004502243052425
            ],
            [
              105.82168499210127,
              21.004501032882665
            ],
            [
              105.82171509993354,
              21.00448722981904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A23_Tây Sơn",
          "maTaiSan": "04.O11.DODV.11036",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004789144265164,
          "to_latitude": 21.00448722981904,
          "to_longitude": 105.82171509993354,
          "from_longitude": 105.82128794288474
        }
      },
      {
        "id": 11014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82158283157104,
            21.004548410632985,
            105.82176491146564,
            21.0047608003408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82158283157104,
              21.0047608003408
            ],
            [
              105.82165828037027,
              21.004708051612596
            ],
            [
              105.82164592680091,
              21.004682875061892
            ],
            [
              105.8217014889237,
              21.004642075377294
            ],
            [
              105.82168860574161,
              21.004614683961893
            ],
            [
              105.82173310905095,
              21.004579297410753
            ],
            [
              105.82173186591218,
              21.004571317974595
            ],
            [
              105.82173149705906,
              21.004568950588585
            ],
            [
              105.8217322633583,
              21.00456871837225
            ],
            [
              105.82176491146564,
              21.004548410632985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A21_Tây Sơn",
          "maTaiSan": "04.O11.DODV.11037",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0047608003408,
          "to_latitude": 21.004548410632985,
          "to_longitude": 105.82176491146564,
          "from_longitude": 105.82158283157104
        }
      },
      {
        "id": 11015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81611937517052,
            21.009015935585435,
            105.8163578641885,
            21.009242656279696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81611937517052,
              21.009242656279696
            ],
            [
              105.81612553727174,
              21.009237206453438
            ],
            [
              105.8163578641885,
              21.009015935585435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11038",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009242656279696,
          "to_latitude": 21.009015935585435,
          "to_longitude": 105.8163578641885,
          "from_longitude": 105.81611937517052
        }
      },
      {
        "id": 11016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81616600313009,
            21.009278006300967,
            105.816424301092,
            21.0095044855781
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81616600313009,
              21.009278006300967
            ],
            [
              105.81627363136003,
              21.009373198850746
            ],
            [
              105.81627742023686,
              21.009376549717924
            ],
            [
              105.816424301092,
              21.0095044855781
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11039",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009278006300967,
          "to_latitude": 21.0095044855781,
          "to_longitude": 105.816424301092,
          "from_longitude": 105.81616600313009
        }
      },
      {
        "id": 11017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8159727303752,
            21.00977734151046,
            105.81615830862127,
            21.0099605219261
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8159727303752,
              21.0099605219261
            ],
            [
              105.81605535108858,
              21.009877916595954
            ],
            [
              105.81605896951275,
              21.009874509427462
            ],
            [
              105.81606258567884,
              21.009873693631782
            ],
            [
              105.81606301797889,
              21.00987005425815
            ],
            [
              105.81608557642608,
              21.0098481060133
            ],
            [
              105.81615830862127,
              21.00977734151046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11040",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0099605219261,
          "to_latitude": 21.00977734151046,
          "to_longitude": 105.81615830862127,
          "from_longitude": 105.8159727303752
        }
      },
      {
        "id": 11018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81591465871188,
            21.009526864182607,
            105.8161853182905,
            21.009765746384353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8161853182905,
              21.009765746384353
            ],
            [
              105.81617951904649,
              21.00976063031424
            ],
            [
              105.81591465871188,
              21.009526864182607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11041",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009765746384353,
          "to_latitude": 21.009526864182607,
          "to_longitude": 105.81591465871188,
          "from_longitude": 105.8161853182905
        }
      },
      {
        "id": 11019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81621127181066,
            21.00955200375908,
            105.81638716190467,
            21.009724626173448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81621127181066,
              21.009724626173448
            ],
            [
              105.81630142279708,
              21.009636150658192
            ],
            [
              105.81636302396221,
              21.00957569381796
            ],
            [
              105.81638716190467,
              21.00955200375908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11042",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009724626173448,
          "to_latitude": 21.00955200375908,
          "to_longitude": 105.81638716190467,
          "from_longitude": 105.81621127181066
        }
      },
      {
        "id": 11020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81623297100361,
            21.009562264385394,
            105.81640035056745,
            21.009727389841764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81623297100361,
              21.009727389841764
            ],
            [
              105.81630368851549,
              21.009657624881235
            ],
            [
              105.81636477803032,
              21.00959735836617
            ],
            [
              105.81640035056745,
              21.009562264385394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11043",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009727389841764,
          "to_latitude": 21.009562264385394,
          "to_longitude": 105.81640035056745,
          "from_longitude": 105.81623297100361
        }
      },
      {
        "id": 11021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81636107784125,
            21.00957569381796,
            105.81637215248398,
            21.009594221140876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81636107784125,
              21.009594221140876
            ],
            [
              105.81636140508782,
              21.009593904344285
            ],
            [
              105.81637215248398,
              21.009583487336855
            ],
            [
              105.81636974809746,
              21.009581433188558
            ],
            [
              105.81636872164536,
              21.00958106056342
            ],
            [
              105.81636858864107,
              21.00958101242718
            ],
            [
              105.8163683819602,
              21.00958032877166
            ],
            [
              105.81636302396221,
              21.00957569381796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11044",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009594221140876,
          "to_latitude": 21.00957569381796,
          "to_longitude": 105.81636302396221,
          "from_longitude": 105.81636107784125
        }
      },
      {
        "id": 11022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8164515895993,
            21.009529651368382,
            105.81669390222987,
            21.0097436971901
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8164515895993,
              21.009529651368382
            ],
            [
              105.81649329609843,
              21.00956649265261
            ],
            [
              105.81656706300883,
              21.00963165435587
            ],
            [
              105.81669390222987,
              21.0097436971901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11045",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009529651368382,
          "to_latitude": 21.0097436971901,
          "to_longitude": 105.81669390222987,
          "from_longitude": 105.8164515895993
        }
      },
      {
        "id": 11023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81648279163167,
            21.00956649265261,
            105.81649329609843,
            21.009577712375023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81648279163167,
              21.009577712375023
            ],
            [
              105.81648634444657,
              21.009573918027666
            ],
            [
              105.81649163014401,
              21.009568270973496
            ],
            [
              105.81649329609843,
              21.00956649265261
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11046",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009577712375023,
          "to_latitude": 21.00956649265261,
          "to_longitude": 105.81649329609843,
          "from_longitude": 105.81648279163167
        }
      },
      {
        "id": 11024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81645596212816,
            21.009569905338978,
            105.8166787541816,
            21.009766138337252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8166787541816,
              21.009766138337252
            ],
            [
              105.81655837993817,
              21.009660113389636
            ],
            [
              105.81650058988832,
              21.009609212869755
            ],
            [
              105.81645596212816,
              21.009569905338978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11047",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009766138337252,
          "to_latitude": 21.009569905338978,
          "to_longitude": 105.81645596212816,
          "from_longitude": 105.8166787541816
        }
      },
      {
        "id": 11025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81650058988832,
            21.009600719407196,
            105.81650895741869,
            21.009609212869755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81650058988832,
              21.009609212869755
            ],
            [
              105.81650267244551,
              21.009607099258602
            ],
            [
              105.81650672850063,
              21.009602981850136
            ],
            [
              105.81650895741869,
              21.009600719407196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11048",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009609212869755,
          "to_latitude": 21.009600719407196,
          "to_longitude": 105.81650895741869,
          "from_longitude": 105.81650058988832
        }
      },
      {
        "id": 11026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81591606119449,
            21.009258349418317,
            105.81611005315467,
            21.009448684847545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81591606119449,
              21.009448684847545
            ],
            [
              105.81601182615516,
              21.00935566732663
            ],
            [
              105.81610804016967,
              21.009260344368187
            ],
            [
              105.81611005315467,
              21.009258349418317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11049",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009448684847545,
          "to_latitude": 21.009258349418317,
          "to_longitude": 105.81611005315467,
          "from_longitude": 105.81591606119449
        }
      },
      {
        "id": 11027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81610804016967,
            21.009260344368187,
            105.81611279340974,
            21.009265033714954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610804016967,
              21.009260344368187
            ],
            [
              105.81610863396332,
              21.00926093042537
            ],
            [
              105.81611075866662,
              21.009263028370718
            ],
            [
              105.81611279340974,
              21.009265033714954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT72_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11050",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009260344368187,
          "to_latitude": 21.009265033714954,
          "to_longitude": 105.81611279340974,
          "from_longitude": 105.81610804016967
        }
      },
      {
        "id": 11028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81625698456125,
            21.010271554596873,
            105.8165175770633,
            21.010491924051582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8165175770633,
              21.010491924051582
            ],
            [
              105.8165166397124,
              21.010491133708605
            ],
            [
              105.81625698456125,
              21.010271554596873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT25_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11051",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010491924051582,
          "to_latitude": 21.010271554596873,
          "to_longitude": 105.81625698456125,
          "from_longitude": 105.8165175770633
        }
      },
      {
        "id": 11029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81651516512106,
            21.010494317437857,
            105.81677098805824,
            21.01072400990383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81651516512106,
              21.010494317437857
            ],
            [
              105.81651772766793,
              21.010496620115454
            ],
            [
              105.81677098805824,
              21.01072400990383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11052",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010494317437857,
          "to_latitude": 21.01072400990383,
          "to_longitude": 105.81677098805824,
          "from_longitude": 105.81651516512106
        }
      },
      {
        "id": 11030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8173617636309,
            21.010619785487968,
            105.81753086253579,
            21.010786928169466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8173617636309,
              21.010786928169466
            ],
            [
              105.81743535588059,
              21.010713215381067
            ],
            [
              105.81744348581115,
              21.010705072564015
            ],
            [
              105.81753086253579,
              21.010619785487968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11053",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010786928169466,
          "to_latitude": 21.010619785487968,
          "to_longitude": 105.81753086253579,
          "from_longitude": 105.8173617636309
        }
      },
      {
        "id": 11031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8173973924301,
            21.01060721455497,
            105.81752104606674,
            21.010725960747045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8173973924301,
              21.010725960747045
            ],
            [
              105.81743342648048,
              21.010691357499606
            ],
            [
              105.81744133335458,
              21.0106837640136
            ],
            [
              105.81752104606674,
              21.01060721455497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11054",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010725960747045,
          "to_latitude": 21.01060721455497,
          "to_longitude": 105.81752104606674,
          "from_longitude": 105.8173973924301
        }
      },
      {
        "id": 11032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743182669213,
            21.010691357499606,
            105.81744204508277,
            21.010710223682327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743182669213,
              21.010710223682327
            ],
            [
              105.81743229742887,
              21.010709760774695
            ],
            [
              105.81744204508277,
              21.01070016773127
            ],
            [
              105.81743714182439,
              21.010695148564068
            ],
            [
              105.81743709252723,
              21.010695104542712
            ],
            [
              105.81743342648048,
              21.010691357499606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11055",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010710223682327,
          "to_latitude": 21.010691357499606,
          "to_longitude": 105.81743342648048,
          "from_longitude": 105.81743182669213
        }
      },
      {
        "id": 11033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753724384745,
            21.010572358725273,
            105.81787379204401,
            21.010904880956808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81753724384745,
              21.010597722388738
            ],
            [
              105.81754026840244,
              21.0105998990885
            ],
            [
              105.81754585105816,
              21.01060391240552
            ],
            [
              105.81757749901335,
              21.010572358725273
            ],
            [
              105.81787379204401,
              21.01084279744737
            ],
            [
              105.8178132512376,
              21.010904880956808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11056",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010597722388738,
          "to_latitude": 21.010904880956808,
          "to_longitude": 105.8178132512376,
          "from_longitude": 105.81753724384745
        }
      },
      {
        "id": 11034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730126463619,
            21.01029113521031,
            105.81752274268128,
            21.01048890250251
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81752274268128,
              21.01048890250251
            ],
            [
              105.81742216726053,
              21.01040126222901
            ],
            [
              105.8173895606413,
              21.010372849756564
            ],
            [
              105.81730126463619,
              21.01029113521031
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11057",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01048890250251,
          "to_latitude": 21.01029113521031,
          "to_longitude": 105.81730126463619,
          "from_longitude": 105.81752274268128
        }
      },
      {
        "id": 11035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175302969714,
            21.01006918581861,
            105.81781815522861,
            21.010325566721352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175302969714,
              21.01006918581861
            ],
            [
              105.81781815522861,
              21.010325566721352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11058",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01006918581861,
          "to_latitude": 21.010325566721352,
          "to_longitude": 105.81781815522861,
          "from_longitude": 105.8175302969714
        }
      },
      {
        "id": 11036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175390745499,
            21.010059690463294,
            105.81782580013714,
            21.01031792028358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81782580013714,
              21.01031792028358
            ],
            [
              105.81782469140046,
              21.01031692393244
            ],
            [
              105.8175390745499,
              21.010059690463294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11059",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01031792028358,
          "to_latitude": 21.010059690463294,
          "to_longitude": 105.8175390745499,
          "from_longitude": 105.81782580013714
        }
      },
      {
        "id": 11037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81788081493546,
            21.01013654458566,
            105.81799949429735,
            21.010255022427376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81788081493546,
              21.010255022427376
            ],
            [
              105.81792562096265,
              21.010210293281084
            ],
            [
              105.81795416596417,
              21.010181796520513
            ],
            [
              105.81799949429735,
              21.01013654458566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11060",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010255022427376,
          "to_latitude": 21.01013654458566,
          "to_longitude": 105.81799949429735,
          "from_longitude": 105.81788081493546
        }
      },
      {
        "id": 11038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81789458133028,
            21.010142939969242,
            105.81801610662578,
            21.010263949700825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81789458133028,
              21.010263949700825
            ],
            [
              105.81792717458858,
              21.010231494509373
            ],
            [
              105.81795519857968,
              21.010203589157573
            ],
            [
              105.81801610662578,
              21.010142939969242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11061",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010263949700825,
          "to_latitude": 21.010142939969242,
          "to_longitude": 105.81801610662578,
          "from_longitude": 105.81789458133028
        }
      },
      {
        "id": 11039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81728688446424,
            21.009208164200565,
            105.81765306277843,
            21.00939905353716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81728688446424,
              21.009208164200565
            ],
            [
              105.81752096463237,
              21.009373098309965
            ],
            [
              105.81755780060219,
              21.00939905353716
            ],
            [
              105.81756070326979,
              21.00939616551979
            ],
            [
              105.81764896956616,
              21.009308344177676
            ],
            [
              105.81765306277843,
              21.009274867134366
            ],
            [
              105.8176274687148,
              21.00924841533126
            ],
            [
              105.81762827449288,
              21.009247655457763
            ],
            [
              105.81763063636316,
              21.009245435716803
            ],
            [
              105.81763474760514,
              21.009241568211454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_174_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11062",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009208164200565,
          "to_latitude": 21.009241568211454,
          "to_longitude": 105.81763474760514,
          "from_longitude": 105.81728688446424
        }
      },
      {
        "id": 11040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81720922908237,
            21.00927653800503,
            105.81767413747053,
            21.00959098612527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81720922908237,
              21.00950851457527
            ],
            [
              105.81734536388392,
              21.009550876997526
            ],
            [
              105.81739981598936,
              21.009588637739448
            ],
            [
              105.8174032021697,
              21.00959098612527
            ],
            [
              105.81750099267015,
              21.00948413867094
            ],
            [
              105.81750541399168,
              21.00947945045098
            ],
            [
              105.81757418592322,
              21.009406526705767
            ],
            [
              105.81766898930734,
              21.009306000752645
            ],
            [
              105.81766482952129,
              21.009285780301514
            ],
            [
              105.81766819238985,
              21.009282439360334
            ],
            [
              105.8176686860687,
              21.00928195105148
            ],
            [
              105.81767413747053,
              21.00927653800503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_173_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11063",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00950851457527,
          "to_latitude": 21.00927653800503,
          "to_longitude": 105.81767413747053,
          "from_longitude": 105.81720922908237
        }
      },
      {
        "id": 11041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81854209911552,
            21.003526744154687,
            105.81858603313013,
            21.00359464443025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81854209911552,
              21.003526744154687
            ],
            [
              105.81856839103051,
              21.003567377708727
            ],
            [
              105.81858035258807,
              21.003585866174564
            ],
            [
              105.81858603313013,
              21.00359464443025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A48_Đường Láng",
          "maTaiSan": "04.O11.DODV.11064",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003526744154687,
          "to_latitude": 21.00359464443025,
          "to_longitude": 105.81858603313013,
          "from_longitude": 105.81854209911552
        }
      },
      {
        "id": 11042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81898906352866,
            21.002870987327487,
            105.81907538953169,
            21.003292163580056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8189963876535,
              21.003292163580056
            ],
            [
              105.81899638078265,
              21.00329213832272
            ],
            [
              105.8189949334207,
              21.003280547832837
            ],
            [
              105.81898906352866,
              21.003233721097363
            ],
            [
              105.8190555112436,
              21.002965894881726
            ],
            [
              105.81907538953169,
              21.002870987327487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A46_Đường Láng",
          "maTaiSan": "04.O11.DODV.11065",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003292163580056,
          "to_latitude": 21.002870987327487,
          "to_longitude": 105.81907538953169,
          "from_longitude": 105.8189963876535
        }
      },
      {
        "id": 11043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81740111498456,
            21.004490003660308,
            105.81813638478873,
            21.004960407148584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81740111498456,
              21.004960407148584
            ],
            [
              105.81749721231252,
              21.004842384982243
            ],
            [
              105.817674965842,
              21.004760087894635
            ],
            [
              105.8176753925146,
              21.004759838360194
            ],
            [
              105.81813638478873,
              21.004490003660308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Đường Láng",
          "maTaiSan": "04.O11.DODV.11066",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004960407148584,
          "to_latitude": 21.004490003660308,
          "to_longitude": 105.81813638478873,
          "from_longitude": 105.81740111498456
        }
      },
      {
        "id": 11044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81762827600481,
            21.004723418016358,
            105.8176753925146,
            21.004759838360194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176753925146,
              21.004759838360194
            ],
            [
              105.81767187747573,
              21.004757122980575
            ],
            [
              105.81762827600481,
              21.004723418016358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B16_Đường Láng",
          "maTaiSan": "04.O11.DODV.11067",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004759838360194,
          "to_latitude": 21.004723418016358,
          "to_longitude": 105.81762827600481,
          "from_longitude": 105.8176753925146
        }
      },
      {
        "id": 11045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81817632257831,
            21.004495131697,
            105.8183690054369,
            21.004815815093515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81824052888695,
              21.004815815093515
            ],
            [
              105.8183690054369,
              21.00463051426703
            ],
            [
              105.81827625973915,
              21.004565349666752
            ],
            [
              105.81817632257831,
              21.004495131697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Đường Láng",
          "maTaiSan": "04.O11.DODV.11068",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004815815093515,
          "to_latitude": 21.004495131697,
          "to_longitude": 105.81817632257831,
          "from_longitude": 105.81824052888695
        }
      },
      {
        "id": 11046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81827625973915,
            21.004535705172422,
            105.81830023042882,
            21.004565349666752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81827625973915,
              21.004565349666752
            ],
            [
              105.81830021322891,
              21.004535726029363
            ],
            [
              105.81830023042882,
              21.004535705172422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23_Đường Láng",
          "maTaiSan": "04.O11.DODV.11069",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004565349666752,
          "to_latitude": 21.004535705172422,
          "to_longitude": 105.81830023042882,
          "from_longitude": 105.81827625973915
        }
      },
      {
        "id": 11047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81821315803825,
            21.004464387562518,
            105.81846354889947,
            21.00462582441535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81846354889947,
              21.00462582441535
            ],
            [
              105.81834656874666,
              21.004550402811603
            ],
            [
              105.81821315803825,
              21.004464387562518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Đường Láng",
          "maTaiSan": "04.O11.DODV.11070",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00462582441535,
          "to_latitude": 21.004464387562518,
          "to_longitude": 105.81821315803825,
          "from_longitude": 105.81846354889947
        }
      },
      {
        "id": 11048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8183388703631,
            21.004550402811603,
            105.81834656874666,
            21.004561062696215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8183388703631,
              21.004561062696215
            ],
            [
              105.81834656874666,
              21.004550402811603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Đường Láng",
          "maTaiSan": "04.O11.DODV.11071",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004561062696215,
          "to_latitude": 21.004550402811603,
          "to_longitude": 105.81834656874666,
          "from_longitude": 105.8183388703631
        }
      },
      {
        "id": 11049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81821293961444,
            21.00418977235769,
            105.81849669842538,
            21.00442361687694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81821293961444,
              21.00442361687694
            ],
            [
              105.8183530687049,
              21.0043081374167
            ],
            [
              105.81849669842538,
              21.00418977235769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Đường Láng",
          "maTaiSan": "04.O11.DODV.11072",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00442361687694,
          "to_latitude": 21.00418977235769,
          "to_longitude": 105.81849669842538,
          "from_longitude": 105.81821293961444
        }
      },
      {
        "id": 11050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8183181709504,
            21.004277217885075,
            105.8183530687049,
            21.0043081374167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8183530687049,
              21.0043081374167
            ],
            [
              105.8183181709504,
              21.004277217885075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22_Đường Láng",
          "maTaiSan": "04.O11.DODV.11073",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0043081374167,
          "to_latitude": 21.004277217885075,
          "to_longitude": 105.8183181709504,
          "from_longitude": 105.8183530687049
        }
      },
      {
        "id": 11051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81345001857223,
            21.011805695321456,
            105.81388488008847,
            21.011996094037784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81345001857223,
              21.011805695321456
            ],
            [
              105.81345062925139,
              21.011806024790115
            ],
            [
              105.81361434510507,
              21.011893943599233
            ],
            [
              105.81382497504895,
              21.011996094037784
            ],
            [
              105.81388488008847,
              21.011880260198577
            ],
            [
              105.81386049478938,
              21.01186611937559
            ],
            [
              105.81377375819608,
              21.01181582155402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_B24_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.11074",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 115,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011805695321456,
          "to_latitude": 21.01181582155402,
          "to_longitude": 105.81377375819608,
          "from_longitude": 105.81345001857223
        }
      },
      {
        "id": 11052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81382502204892,
            21.01175136134349,
            105.8139010461552,
            21.01186611937559
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81386049478938,
              21.01186611937559
            ],
            [
              105.8139010461552,
              21.011798185080526
            ],
            [
              105.81382502204892,
              21.01175136134349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.11075",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01186611937559,
          "to_latitude": 21.01175136134349,
          "to_longitude": 105.81382502204892,
          "from_longitude": 105.81386049478938
        }
      },
      {
        "id": 11053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81347713611225,
            21.010178354208254,
            105.81348335284133,
            21.010186527437725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81347713611225,
              21.010186527437725
            ],
            [
              105.81347848756539,
              21.010184748842313
            ],
            [
              105.8134808065476,
              21.010181701101374
            ],
            [
              105.81348335284133,
              21.010178354208254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B51_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DODV.11076",
          "diaChiLapD": "Ngõ 426, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010186527437725,
          "to_latitude": 21.010178354208254,
          "to_longitude": 105.81348335284133,
          "from_longitude": 105.81347713611225
        }
      },
      {
        "id": 11054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8145606229881,
            21.010629372804416,
            105.81475563585364,
            21.010876369963267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8145606229881,
              21.010629372804416
            ],
            [
              105.81456360425501,
              21.01063315118962
            ],
            [
              105.81475563585364,
              21.010876369963267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Yên Lãng",
          "maTaiSan": "04.O11.DODV.11077",
          "diaChiLapD": "Yên Lãng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010629372804416,
          "to_latitude": 21.010876369963267,
          "to_longitude": 105.81475563585364,
          "from_longitude": 105.8145606229881
        }
      },
      {
        "id": 11055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753206295538,
            21.014320603016476,
            105.81773796884303,
            21.01455911240901
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81773796884303,
              21.01455911240901
            ],
            [
              105.8177338652996,
              21.014554359477696
            ],
            [
              105.81773076272532,
              21.01455076780207
            ],
            [
              105.81753206295538,
              21.014320603016476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A2A_Thái Hà",
          "maTaiSan": "04.O11.DODV.11078",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01455911240901,
          "to_latitude": 21.014320603016476,
          "to_longitude": 105.81753206295538,
          "from_longitude": 105.81773796884303
        }
      },
      {
        "id": 11056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82086706693062,
            21.006401486481433,
            105.8208792598502,
            21.00641170911442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8208792598502,
              21.00641170911442
            ],
            [
              105.82087391228343,
              21.006407225993822
            ],
            [
              105.82086864105926,
              21.006402807537228
            ],
            [
              105.82086706693062,
              21.006401486481433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A34_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11079",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00641170911442,
          "to_latitude": 21.006401486481433,
          "to_longitude": 105.82086706693062,
          "from_longitude": 105.8208792598502
        }
      },
      {
        "id": 11057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81956919951577,
            21.00530267968162,
            105.81964330236092,
            21.005394948132146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81956919951577,
              21.005394948132146
            ],
            [
              105.81958312082281,
              21.00535516253777
            ],
            [
              105.81964330236092,
              21.00530267968162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B4_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11080",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005394948132146,
          "to_latitude": 21.00530267968162,
          "to_longitude": 105.81964330236092,
          "from_longitude": 105.81956919951577
        }
      },
      {
        "id": 11058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8187087946937,
            21.003131468615937,
            105.81894978497414,
            21.00325273837051
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8187087946937,
              21.00325273837051
            ],
            [
              105.81879102418834,
              21.003210082571282
            ],
            [
              105.81879914314804,
              21.003198074833666
            ],
            [
              105.81890103395045,
              21.0031518921325
            ],
            [
              105.81894978497414,
              21.003131468615937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A47_Đường Láng",
          "maTaiSan": "04.O11.DODV.11081",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00325273837051,
          "to_latitude": 21.003131468615937,
          "to_longitude": 105.81894978497414,
          "from_longitude": 105.8187087946937
        }
      },
      {
        "id": 11059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81678539514212,
            21.005793000305403,
            105.81814636363256,
            21.00655900272127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81814636363256,
              21.00655900272127
            ],
            [
              105.81782652239772,
              21.006370806028702
            ],
            [
              105.81759933909142,
              21.006219030680395
            ],
            [
              105.81723937000756,
              21.00600510385023
            ],
            [
              105.81703973382687,
              21.005921479517653
            ],
            [
              105.81680266503562,
              21.005801725709322
            ],
            [
              105.81678539514212,
              21.005793000305403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_A67A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11082",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 165,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00655900272127,
          "to_latitude": 21.005793000305403,
          "to_longitude": 105.81678539514212,
          "from_longitude": 105.81814636363256
        }
      },
      {
        "id": 11060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763757995775,
            21.01039227647488,
            105.81776176350556,
            21.01051573932771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81763757995775,
              21.01051573932771
            ],
            [
              105.81763887893297,
              21.010514400824796
            ],
            [
              105.81770668500153,
              21.01044456257491
            ],
            [
              105.81776176350556,
              21.01039227647488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11083",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01051573932771,
          "to_latitude": 21.01039227647488,
          "to_longitude": 105.81776176350556,
          "from_longitude": 105.81763757995775
        }
      },
      {
        "id": 11061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81762615333022,
            21.010345129211334,
            105.8177859389399,
            21.01050247613584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81762615333022,
              21.01050247613584
            ],
            [
              105.81762946280355,
              21.010499216742186
            ],
            [
              105.81769894453964,
              21.010430795737552
            ],
            [
              105.8177859389399,
              21.010345129211334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11084",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01050247613584,
          "to_latitude": 21.010345129211334,
          "to_longitude": 105.8177859389399,
          "from_longitude": 105.81762615333022
        }
      },
      {
        "id": 11062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81762946280355,
            21.010499216742186,
            105.81763901601622,
            21.01051101574982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81763488492442,
              21.01051101574982
            ],
            [
              105.81763901601622,
              21.01050743085893
            ],
            [
              105.81763680771124,
              21.01050553204591
            ],
            [
              105.81763607805496,
              21.010504905098802
            ],
            [
              105.81762946280355,
              21.010499216742186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11085",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01051101574982,
          "to_latitude": 21.010499216742186,
          "to_longitude": 105.81762946280355,
          "from_longitude": 105.81763488492442
        }
      },
      {
        "id": 11063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81777067568363,
            21.009699146811972,
            105.81789588971768,
            21.009829846852533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81777067568363,
              21.009829846852533
            ],
            [
              105.81789588971768,
              21.009699146811972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11086",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009829846852533,
          "to_latitude": 21.009699146811972,
          "to_longitude": 105.81789588971768,
          "from_longitude": 105.81777067568363
        }
      },
      {
        "id": 11064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81768191019914,
            21.009833834780135,
            105.81776236926729,
            21.00991453916619
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81776236926729,
              21.009833834780135
            ],
            [
              105.81768191019914,
              21.00991453916619
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT17_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11087",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009833834780135,
          "to_latitude": 21.00991453916619,
          "to_longitude": 105.81768191019914,
          "from_longitude": 105.81776236926729
        }
      },
      {
        "id": 11065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81743975595076,
            21.01006918581861,
            105.8175302969714,
            21.01015536693238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81743975595076,
              21.01015536693238
            ],
            [
              105.8175302969714,
              21.01006918581861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11088",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01015536693238,
          "to_latitude": 21.01006918581861,
          "to_longitude": 105.8175302969714,
          "from_longitude": 105.81743975595076
        }
      },
      {
        "id": 11066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175390745499,
            21.009967487145826,
            105.81762968460035,
            21.010059690463294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175390745499,
              21.010059690463294
            ],
            [
              105.81762968460035,
              21.009967487145826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11089",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010059690463294,
          "to_latitude": 21.009967487145826,
          "to_longitude": 105.81762968460035,
          "from_longitude": 105.8175390745499
        }
      },
      {
        "id": 11067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81730126463619,
            21.010186436366208,
            105.81740605847881,
            21.01029113521031
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81730126463619,
              21.01029113521031
            ],
            [
              105.81740605847881,
              21.010186436366208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11090",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01029113521031,
          "to_latitude": 21.010186436366208,
          "to_longitude": 105.81740605847881,
          "from_longitude": 105.81730126463619
        }
      },
      {
        "id": 11068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81715584955643,
            21.01031313363651,
            105.8172780697996,
            21.010436316653337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8172780697996,
              21.01031313363651
            ],
            [
              105.81715584955643,
              21.010436316653337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11091",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01031313363651,
          "to_latitude": 21.010436316653337,
          "to_longitude": 105.81715584955643,
          "from_longitude": 105.8172780697996
        }
      },
      {
        "id": 11069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81689652833242,
            21.01053599154566,
            105.81705558071354,
            21.010694625903962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81705558071354,
              21.01053599154566
            ],
            [
              105.81700019761546,
              21.01059281038232
            ],
            [
              105.816949488649,
              21.010642611259666
            ],
            [
              105.81689652833242,
              21.010694625903962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT7_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11092",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01053599154566,
          "to_latitude": 21.010694625903962,
          "to_longitude": 105.81689652833242,
          "from_longitude": 105.81705558071354
        }
      },
      {
        "id": 11070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81647480515046,
            21.009917514159895,
            105.81657350328668,
            21.01001418065543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81647480515046,
              21.01001418065543
            ],
            [
              105.81657350328668,
              21.009917514159895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11093",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01001418065543,
          "to_latitude": 21.009917514159895,
          "to_longitude": 105.81657350328668,
          "from_longitude": 105.81647480515046
        }
      },
      {
        "id": 11071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81578001832915,
            21.009537964929194,
            105.81586170775363,
            21.009623241967457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81578001832915,
              21.009623241967457
            ],
            [
              105.81586170775363,
              21.009537964929194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11094",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009623241967457,
          "to_latitude": 21.009537964929194,
          "to_longitude": 105.81586170775363,
          "from_longitude": 105.81578001832915
        }
      },
      {
        "id": 11072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81670655535439,
            21.00953172286286,
            105.8169509860762,
            21.009776968294247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81670655535439,
              21.009776968294247
            ],
            [
              105.81671861140487,
              21.00976487220598
            ],
            [
              105.81693927255078,
              21.009543474998992
            ],
            [
              105.8169509860762,
              21.00953172286286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11095",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009776968294247,
          "to_latitude": 21.00953172286286,
          "to_longitude": 105.8169509860762,
          "from_longitude": 105.81670655535439
        }
      },
      {
        "id": 11073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81613690619149,
            21.009045730810723,
            105.81635777427773,
            21.009258647860904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81613690619149,
              21.009258647860904
            ],
            [
              105.81614365440176,
              21.009252194445992
            ],
            [
              105.81624416167067,
              21.00915607043383
            ],
            [
              105.81635777427773,
              21.009045730810723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11096",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009258647860904,
          "to_latitude": 21.009045730810723,
          "to_longitude": 105.81635777427773,
          "from_longitude": 105.81613690619149
        }
      },
      {
        "id": 11074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81635777427773,
            21.009045730810723,
            105.81648268713292,
            21.0091512364705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81635777427773,
              21.009045730810723
            ],
            [
              105.81648268713292,
              21.0091512364705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11097",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009045730810723,
          "to_latitude": 21.0091512364705,
          "to_longitude": 105.81648268713292,
          "from_longitude": 105.81635777427773
        }
      },
      {
        "id": 11075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81602935984388,
            21.00928027102918,
            105.81612657277066,
            21.0093774549224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81612657277066,
              21.00928027102918
            ],
            [
              105.81602935984388,
              21.0093774549224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11098",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00928027102918,
          "to_latitude": 21.0093774549224,
          "to_longitude": 105.81602935984388,
          "from_longitude": 105.81612657277066
        }
      },
      {
        "id": 11076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.815572801836,
            21.00899252172746,
            105.81583096042071,
            21.009231830334894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81583096042071,
              21.00899252172746
            ],
            [
              105.81582881607194,
              21.008994770207828
            ],
            [
              105.81582343510354,
              21.009000410472815
            ],
            [
              105.81582099836986,
              21.009002664870962
            ],
            [
              105.81580028975465,
              21.008993833025315
            ],
            [
              105.81558147217349,
              21.00922276462507
            ],
            [
              105.815572801836,
              21.009231830334894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11099",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00899252172746,
          "to_latitude": 21.009231830334894,
          "to_longitude": 105.815572801836,
          "from_longitude": 105.81583096042071
        }
      },
      {
        "id": 11077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81552007562372,
            21.008880758619025,
            105.81562809279458,
            21.008991546051668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81562809279458,
              21.008880758619025
            ],
            [
              105.81552007562372,
              21.008991546051668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11100",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008880758619025,
          "to_latitude": 21.008991546051668,
          "to_longitude": 105.81552007562372,
          "from_longitude": 105.81562809279458
        }
      },
      {
        "id": 11078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81515399001056,
            21.008567855901283,
            105.81524320125182,
            21.008649351382907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81515399001056,
              21.008567855901283
            ],
            [
              105.81524320125182,
              21.008649351382907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11101",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008567855901283,
          "to_latitude": 21.008649351382907,
          "to_longitude": 105.81524320125182,
          "from_longitude": 105.81515399001056
        }
      },
      {
        "id": 11079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81524320125182,
            21.00858792744232,
            105.8153082205944,
            21.008649351382907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81524320125182,
              21.008649351382907
            ],
            [
              105.8153082205944,
              21.00858792744232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11102",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008649351382907,
          "to_latitude": 21.00858792744232,
          "to_longitude": 105.8153082205944,
          "from_longitude": 105.81524320125182
        }
      },
      {
        "id": 11080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81566676027812,
            21.008873185778743,
            105.81579252648199,
            21.008983785696113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81566676027812,
              21.008873185778743
            ],
            [
              105.81579252648199,
              21.008983785696113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11103",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008873185778743,
          "to_latitude": 21.008983785696113,
          "to_longitude": 105.81579252648199,
          "from_longitude": 105.81566676027812
        }
      },
      {
        "id": 11081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81562026190794,
            21.008167140997397,
            105.8158811996566,
            21.008347606111137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81562026190794,
              21.008279607240762
            ],
            [
              105.81569597374043,
              21.008347606111137
            ],
            [
              105.8158811996566,
              21.008167140997397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11104",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008279607240762,
          "to_latitude": 21.008167140997397,
          "to_longitude": 105.8158811996566,
          "from_longitude": 105.81562026190794
        }
      },
      {
        "id": 11082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8153335190206,
            21.00815875097292,
            105.81552326415775,
            21.00833562517357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8153335190206,
              21.00815875097292
            ],
            [
              105.81552326415775,
              21.00833562517357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11105",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00815875097292,
          "to_latitude": 21.00833562517357,
          "to_longitude": 105.81552326415775,
          "from_longitude": 105.8153335190206
        }
      },
      {
        "id": 11083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763404086378,
            21.00981363471821,
            105.81773893111513,
            21.009918027123774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81773893111513,
              21.00981363471821
            ],
            [
              105.81763404086378,
              21.009918027123774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT39_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11106",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00981363471821,
          "to_latitude": 21.009918027123774,
          "to_longitude": 105.81763404086378,
          "from_longitude": 105.81773893111513
        }
      },
      {
        "id": 11084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81727039640795,
            21.009602414980844,
            105.81742024871934,
            21.009751875643495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81727039640795,
              21.009751875643495
            ],
            [
              105.8173339693991,
              21.009688468984365
            ],
            [
              105.81742024871934,
              21.009602414980844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11107",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009751875643495,
          "to_latitude": 21.009602414980844,
          "to_longitude": 105.81742024871934,
          "from_longitude": 105.81727039640795
        }
      },
      {
        "id": 11085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81732843033393,
            21.00968238525061,
            105.8173339693991,
            21.009688468984365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8173339693991,
              21.009688468984365
            ],
            [
              105.81732843033393,
              21.00968238525061
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11108",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009688468984365,
          "to_latitude": 21.00968238525061,
          "to_longitude": 105.81732843033393,
          "from_longitude": 105.8173339693991
        }
      },
      {
        "id": 11086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81751658801191,
            21.009932549831014,
            105.81762357562208,
            21.010042369806712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81751658801191,
              21.010042369806712
            ],
            [
              105.81751687114827,
              21.010042078510253
            ],
            [
              105.81762357562208,
              21.009932549831014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11109",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010042369806712,
          "to_latitude": 21.009932549831014,
          "to_longitude": 105.81762357562208,
          "from_longitude": 105.81751658801191
        }
      },
      {
        "id": 11087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8173807781166,
            21.01005039225042,
            105.8175068174179,
            21.0101755664189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8175068174179,
              21.01005039225042
            ],
            [
              105.8173807781166,
              21.0101755664189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11110",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01005039225042,
          "to_latitude": 21.0101755664189,
          "to_longitude": 105.8173807781166,
          "from_longitude": 105.8175068174179
        }
      },
      {
        "id": 11088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81686023182611,
            21.009554990727892,
            105.81694312513991,
            21.009630688086354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81694312513991,
              21.009554990727892
            ],
            [
              105.81686023182611,
              21.009630688086354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT36_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11111",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009554990727892,
          "to_latitude": 21.009630688086354,
          "to_longitude": 105.81686023182611,
          "from_longitude": 105.81694312513991
        }
      },
      {
        "id": 11089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81660368403588,
            21.009790670402467,
            105.81669899916618,
            21.009882533623536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81669899916618,
              21.009790670402467
            ],
            [
              105.81660368403588,
              21.009882533623536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11112",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009790670402467,
          "to_latitude": 21.009882533623536,
          "to_longitude": 105.81660368403588,
          "from_longitude": 105.81669899916618
        }
      },
      {
        "id": 11090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756645289428,
            21.003479299977545,
            105.83758718786233,
            21.003486228649106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756645289428,
              21.003479299977545
            ],
            [
              105.83757714079194,
              21.003482871123968
            ],
            [
              105.8375832021907,
              21.003484897940464
            ],
            [
              105.83758513431265,
              21.00348554236778
            ],
            [
              105.83758718786233,
              21.003486228649106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,44_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DODV.11113",
          "diaChiLapD": "Ngách 15/2, Phương Mai",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003479299977545,
          "to_latitude": 21.003486228649106,
          "to_longitude": 105.83758718786233,
          "from_longitude": 105.83756645289428
        }
      },
      {
        "id": 11091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617667353404,
            21.006658764768563,
            105.83617970724298,
            21.00666134392602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617970724298,
              21.00666134392602
            ],
            [
              105.83617951299466,
              21.00666118049342
            ],
            [
              105.8361793158306,
              21.00666101165579
            ],
            [
              105.83617667353404,
              21.006658764768563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.33_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DODV.11114",
          "diaChiLapD": "Ngách 9/14, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00666134392602,
          "to_latitude": 21.006658764768563,
          "to_longitude": 105.83617667353404,
          "from_longitude": 105.83617970724298
        }
      },
      {
        "id": 11092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83547982488689,
            21.005803006623516,
            105.83586015690187,
            21.005857570288885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586015690187,
              21.005852493832958
            ],
            [
              105.83585995495916,
              21.00585250295378
            ],
            [
              105.83574421879318,
              21.005857570288885
            ],
            [
              105.83547982488689,
              21.005803006623516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.46_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DODV.11115",
          "diaChiLapD": "Ngõ 11, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005852493832958,
          "to_latitude": 21.005803006623516,
          "to_longitude": 105.83547982488689,
          "from_longitude": 105.83586015690187
        }
      },
      {
        "id": 11093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82594373163265,
            21.00836510725239,
            105.82595353309272,
            21.008372660638326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82594373163265,
              21.008372660638326
            ],
            [
              105.82594685270942,
              21.00837034135872
            ],
            [
              105.82595353309272,
              21.00836510725239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT5_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.11116",
          "diaChiLapD": "Ngõ 249, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 95.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.008372660638326,
          "to_latitude": 21.00836510725239,
          "to_longitude": 105.82595353309272,
          "from_longitude": 105.82594373163265
        }
      },
      {
        "id": 11094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82860449423309,
            21.00100823981334,
            105.82901967938535,
            21.001089793081224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82860449423309,
              21.001089793081224
            ],
            [
              105.82870735204219,
              21.001051653088354
            ],
            [
              105.8287944831503,
              21.001020739551365
            ],
            [
              105.82880056739971,
              21.001018581021913
            ],
            [
              105.82883788755765,
              21.00100823981334
            ],
            [
              105.82896834445404,
              21.00105266836394
            ],
            [
              105.8289690218992,
              21.001052898097466
            ],
            [
              105.82901967938535,
              21.0010701469035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT229_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11117",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001089793081224,
          "to_latitude": 21.0010701469035,
          "to_longitude": 105.82901967938535,
          "from_longitude": 105.82860449423309
        }
      },
      {
        "id": 11095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8287944831503,
            21.001020739551365,
            105.82885307120928,
            21.00116553423133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8287944831503,
              21.001020739551365
            ],
            [
              105.82885307120928,
              21.00116553423133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT229_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11118",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001020739551365,
          "to_latitude": 21.00116553423133,
          "to_longitude": 105.82885307120928,
          "from_longitude": 105.8287944831503
        }
      },
      {
        "id": 11096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82879201849008,
            21.00099923668212,
            105.82880056739971,
            21.001018581021913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82880056739971,
              21.001018581021913
            ],
            [
              105.82879790543863,
              21.001012560425657
            ],
            [
              105.82879201849008,
              21.00099923668212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT229_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11119",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001018581021913,
          "to_latitude": 21.00099923668212,
          "to_longitude": 105.82879201849008,
          "from_longitude": 105.82880056739971
        }
      },
      {
        "id": 11097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82824749258774,
            21.00110477313526,
            105.8285653346261,
            21.00121991158472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8285653346261,
              21.00110477313526
            ],
            [
              105.82824749258774,
              21.00121991158472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT230_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11120",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00110477313526,
          "to_latitude": 21.00121991158472,
          "to_longitude": 105.82824749258774,
          "from_longitude": 105.8285653346261
        }
      },
      {
        "id": 11098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82819676787153,
            21.00120755655993,
            105.82824584556708,
            21.00138266487282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82819676787153,
              21.00120755655993
            ],
            [
              105.82819818565197,
              21.00121261586312
            ],
            [
              105.82820119587542,
              21.001223360459317
            ],
            [
              105.82820502237465,
              21.00123700857215
            ],
            [
              105.82824584556708,
              21.00138266487282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT231_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11121",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00120755655993,
          "to_latitude": 21.00138266487282,
          "to_longitude": 105.82824584556708,
          "from_longitude": 105.82819676787153
        }
      },
      {
        "id": 11099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82791561651919,
            21.00123700857215,
            105.82820502237465,
            21.001342688561294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82820502237465,
              21.00123700857215
            ],
            [
              105.82791561651919,
              21.001342688561294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT231_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11122",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00123700857215,
          "to_latitude": 21.001342688561294,
          "to_longitude": 105.82791561651919,
          "from_longitude": 105.82820502237465
        }
      },
      {
        "id": 11100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82743354279958,
            21.001442649400094,
            105.82761160384348,
            21.001495852534333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82761160384348,
              21.001442649400094
            ],
            [
              105.82743354279958,
              21.001495852534333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT233_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11123",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001442649400094,
          "to_latitude": 21.001495852534333,
          "to_longitude": 105.82743354279958,
          "from_longitude": 105.82761160384348
        }
      },
      {
        "id": 11101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82643462786052,
            21.001635982846093,
            105.82691700152034,
            21.001761244802136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82643462786052,
              21.001761244802136
            ],
            [
              105.82677322605358,
              21.001673472392618
            ],
            [
              105.82691700152034,
              21.001635982846093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT235_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11124",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001761244802136,
          "to_latitude": 21.001635982846093,
          "to_longitude": 105.82691700152034,
          "from_longitude": 105.82643462786052
        }
      },
      {
        "id": 11102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82693732257222,
            21.001501231163385,
            105.82741442206793,
            21.00162901418009
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82741442206793,
              21.001501231163385
            ],
            [
              105.8271946272882,
              21.00156264130615
            ],
            [
              105.82693732257222,
              21.00162901418009
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT234_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11125",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001501231163385,
          "to_latitude": 21.00162901418009,
          "to_longitude": 105.82693732257222,
          "from_longitude": 105.82741442206793
        }
      },
      {
        "id": 11103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8271855210182,
            21.00151997013226,
            105.8271946272882,
            21.00156264130615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8271855210182,
              21.00151997013226
            ],
            [
              105.82719266349032,
              21.001553444253368
            ],
            [
              105.8271946272882,
              21.00156264130615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT234_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11126",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00151997013226,
          "to_latitude": 21.00156264130615,
          "to_longitude": 105.8271946272882,
          "from_longitude": 105.8271855210182
        }
      },
      {
        "id": 11104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82676007142223,
            21.001632537891584,
            105.82677322605358,
            21.001673472392618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82676007142223,
              21.001632537891584
            ],
            [
              105.8267722591965,
              21.001670467535124
            ],
            [
              105.82677322605358,
              21.001673472392618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT235_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11127",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001632537891584,
          "to_latitude": 21.001673472392618,
          "to_longitude": 105.82677322605358,
          "from_longitude": 105.82676007142223
        }
      },
      {
        "id": 11105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82405769600008,
            21.00222322749806,
            105.82458021746179,
            21.002352895373384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82405769600008,
              21.002352895373384
            ],
            [
              105.82432077275737,
              21.002287610708787
            ],
            [
              105.82458021746179,
              21.00222322749806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT242_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11128",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002352895373384,
          "to_latitude": 21.00222322749806,
          "to_longitude": 105.82458021746179,
          "from_longitude": 105.82405769600008
        }
      },
      {
        "id": 11106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82430995745229,
            21.002251488814323,
            105.82432077275737,
            21.002287610708787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82432077275737,
              21.002287610708787
            ],
            [
              105.82430995745229,
              21.002251488814323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT242_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11129",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002287610708787,
          "to_latitude": 21.002251488814323,
          "to_longitude": 105.82430995745229,
          "from_longitude": 105.82432077275737
        }
      },
      {
        "id": 11107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82459544970597,
            21.002120701937905,
            105.82503451682909,
            21.002216462597485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82459544970597,
              21.002216462597485
            ],
            [
              105.82503346101836,
              21.002120931959016
            ],
            [
              105.82503451682909,
              21.002120701937905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT241_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11130",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002216462597485,
          "to_latitude": 21.002120701937905,
          "to_longitude": 105.82503451682909,
          "from_longitude": 105.82459544970597
        }
      },
      {
        "id": 11108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82502275148995,
            21.00207293396425,
            105.82504641989021,
            21.002169025630927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82504641989021,
              21.002169025630927
            ],
            [
              105.82503451682909,
              21.002120701937905
            ],
            [
              105.82503316115931,
              21.002115200626758
            ],
            [
              105.82502275148995,
              21.00207293396425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT241_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11131",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002169025630927,
          "to_latitude": 21.00207293396425,
          "to_longitude": 105.82502275148995,
          "from_longitude": 105.82504641989021
        }
      },
      {
        "id": 11109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82505807631341,
            21.002027641854593,
            105.8253996484777,
            21.002165171931235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82505807631341,
              21.002109307335473
            ],
            [
              105.82524064733946,
              21.002064352926556
            ],
            [
              105.8253897373001,
              21.002027641854593
            ],
            [
              105.8253996484777,
              21.002094507601974
            ],
            [
              105.82536213854395,
              21.002099469366456
            ],
            [
              105.8253656806684,
              21.002165171931235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT240_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11132",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002109307335473,
          "to_latitude": 21.002165171931235,
          "to_longitude": 105.8253656806684,
          "from_longitude": 105.82505807631341
        }
      },
      {
        "id": 11110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82523109685685,
            21.002020742626136,
            105.82524064733946,
            21.002064352926556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82524064733946,
              21.002064352926556
            ],
            [
              105.82523109685685,
              21.002020742626136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT240_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11133",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002064352926556,
          "to_latitude": 21.002020742626136,
          "to_longitude": 105.82523109685685,
          "from_longitude": 105.82524064733946
        }
      },
      {
        "id": 11111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82580660154727,
            21.001876575689806,
            105.825877706034,
            21.00235788758067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82580660154727,
              21.001876575689806
            ],
            [
              105.82580754947953,
              21.001905690242484
            ],
            [
              105.82581278501387,
              21.00206621623915
            ],
            [
              105.825877706034,
              21.002070919594676
            ],
            [
              105.82586929005745,
              21.002165360343536
            ],
            [
              105.82585469568599,
              21.002329129387498
            ],
            [
              105.82583805803621,
              21.00235788758067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT237_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11134",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001876575689806,
          "to_latitude": 21.00235788758067,
          "to_longitude": 105.82583805803621,
          "from_longitude": 105.82580660154727
        }
      },
      {
        "id": 11112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82586929005745,
            21.00216001798512,
            105.82613243293922,
            21.002165360343536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82586929005745,
              21.002165360343536
            ],
            [
              105.82613243293922,
              21.00216001798512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT237_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11135",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002165360343536,
          "to_latitude": 21.00216001798512,
          "to_longitude": 105.82613243293922,
          "from_longitude": 105.82586929005745
        }
      },
      {
        "id": 11113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82573779288003,
            21.001885188573237,
            105.82577221854596,
            21.00231249005187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82577221854596,
              21.001885188573237
            ],
            [
              105.82576495076246,
              21.001975397548893
            ],
            [
              105.82575870221015,
              21.002052959103814
            ],
            [
              105.82573779288003,
              21.00231249005187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT238_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11136",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001885188573237,
          "to_latitude": 21.00231249005187,
          "to_longitude": 105.82573779288003,
          "from_longitude": 105.82577221854596
        }
      },
      {
        "id": 11114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82551139135911,
            21.00205214447469,
            105.82575870221015,
            21.002052959103814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82575870221015,
              21.002052959103814
            ],
            [
              105.82551139135911,
              21.00205214447469
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT238_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11137",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002052959103814,
          "to_latitude": 21.00205214447469,
          "to_longitude": 105.82551139135911,
          "from_longitude": 105.82575870221015
        }
      },
      {
        "id": 11115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82525139585405,
            21.001978287518295,
            105.82548571184533,
            21.002312219621988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82540057720729,
              21.001978287518295
            ],
            [
              105.82540160738112,
              21.00198791363843
            ],
            [
              105.82541287566868,
              21.00209324849182
            ],
            [
              105.82548234142796,
              21.002091478205703
            ],
            [
              105.82548571184533,
              21.002287903009158
            ],
            [
              105.82535942918066,
              21.002289292160174
            ],
            [
              105.82535026574806,
              21.002300687452724
            ],
            [
              105.82534318336083,
              21.002301513924362
            ],
            [
              105.82525139585405,
              21.002312219621988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT239_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11138",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001978287518295,
          "to_latitude": 21.002312219621988,
          "to_longitude": 105.82525139585405,
          "from_longitude": 105.82540057720729
        }
      },
      {
        "id": 11116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8253418158533,
            21.002301513924362,
            105.82534318336083,
            21.002383773873916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82534318336083,
              21.002301513924362
            ],
            [
              105.8253418158533,
              21.002383773873916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT239_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11139",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002301513924362,
          "to_latitude": 21.002383773873916,
          "to_longitude": 105.8253418158533,
          "from_longitude": 105.82534318336083
        }
      },
      {
        "id": 11117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82153002022781,
            21.002983733529163,
            105.82165693366592,
            21.003011135099825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82153002022781,
              21.00299776719101
            ],
            [
              105.82153359174417,
              21.00300693036357
            ],
            [
              105.82153523260395,
              21.003011135099825
            ],
            [
              105.82165693366592,
              21.002983733529163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT254_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11140",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00299776719101,
          "to_latitude": 21.002983733529163,
          "to_longitude": 105.82165693366592,
          "from_longitude": 105.82153002022781
        }
      },
      {
        "id": 11118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8218830172003,
            21.002897099094646,
            105.82192609194684,
            21.00302301876853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8218830172003,
              21.002897099094646
            ],
            [
              105.82188983253859,
              21.002919513210056
            ],
            [
              105.82189217845719,
              21.00292723261151
            ],
            [
              105.8219168500102,
              21.003008366069302
            ],
            [
              105.82192609194684,
              21.00302301876853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT251_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11141",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002897099094646,
          "to_latitude": 21.00302301876853,
          "to_longitude": 105.82192609194684,
          "from_longitude": 105.8218830172003
        }
      },
      {
        "id": 11119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8216914280508,
            21.00286374265378,
            105.82209852906928,
            21.002972532275166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8216914280508,
              21.002972532275166
            ],
            [
              105.82185915377586,
              21.002927710911415
            ],
            [
              105.82188983253859,
              21.002919513210056
            ],
            [
              105.82209852906928,
              21.00286374265378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT252_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11142",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002972532275166,
          "to_latitude": 21.00286374265378,
          "to_longitude": 105.82209852906928,
          "from_longitude": 105.8216914280508
        }
      },
      {
        "id": 11120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8218503041552,
            21.002906182748188,
            105.82185915377586,
            21.002927710911415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82185915377586,
              21.002927710911415
            ],
            [
              105.8218503041552,
              21.002906182748188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT252_Trường Chinh",
          "maTaiSan": "04.O18.DODV.11143",
          "diaChiLapD": "Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002927710911415,
          "to_latitude": 21.002906182748188,
          "to_longitude": 105.8218503041552,
          "from_longitude": 105.82185915377586
        }
      },
      {
        "id": 11121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82214386813081,
            21.002857071007245,
            105.8222123390272,
            21.002966312669336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8222123390272,
              21.002966312669336
            ],
            [
              105.82215860127961,
              21.00289858265586
            ],
            [
              105.82214571648673,
              21.002862278221365
            ],
            [
              105.82214386813081,
              21.002857071007245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT256_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.11144",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.002966312669336,
          "to_latitude": 21.002857071007245,
          "to_longitude": 105.82214386813081,
          "from_longitude": 105.8222123390272
        }
      },
      {
        "id": 11122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82213289640423,
            21.002862278221365,
            105.82214571648673,
            21.00286553215097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82213289640423,
              21.00286553215097
            ],
            [
              105.8221369546216,
              21.00286450454716
            ],
            [
              105.82214571648673,
              21.002862278221365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT256_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DODV.11145",
          "diaChiLapD": "Ngõ 592, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00286553215097,
          "to_latitude": 21.002862278221365,
          "to_longitude": 105.82214571648673,
          "from_longitude": 105.82213289640423
        }
      },
      {
        "id": 11123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8230075563298,
            21.004023803737425,
            105.82301237750657,
            21.00403263115393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82301237750657,
              21.00403263115393
            ],
            [
              105.82301037208356,
              21.00402895929462
            ],
            [
              105.82300885151152,
              21.004026177479865
            ],
            [
              105.8230075563298,
              21.004023803737425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT289_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DODV.11146",
          "diaChiLapD": "Ngõ 554, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00403263115393,
          "to_latitude": 21.004023803737425,
          "to_longitude": 105.8230075563298,
          "from_longitude": 105.82301237750657
        }
      },
      {
        "id": 11124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540400959356,
            21.003930846489247,
            105.825414219638,
            21.003936292786058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82540400959356,
              21.003936292786058
            ],
            [
              105.8254041544088,
              21.00393621530946
            ],
            [
              105.8254046857221,
              21.00393593182918
            ],
            [
              105.825414219638,
              21.003930846489247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT31_Khương Thượng",
          "maTaiSan": "04.O18.DODV.11147",
          "diaChiLapD": "Khương Thượng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 98.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003936292786058,
          "to_latitude": 21.003930846489247,
          "to_longitude": 105.825414219638,
          "from_longitude": 105.82540400959356
        }
      },
      {
        "id": 11125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82955808090473,
            21.004393050105147,
            105.82957113432049,
            21.004398629732066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82955808090473,
              21.004398629732066
            ],
            [
              105.82957113432049,
              21.004393050105147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT136_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DODV.11148",
          "diaChiLapD": "Ngõ 10, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004398629732066,
          "to_latitude": 21.004393050105147,
          "to_longitude": 105.82957113432049,
          "from_longitude": 105.82955808090473
        }
      },
      {
        "id": 11126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81778941241426,
            21.003554246927933,
            105.81779247177639,
            21.003562602943997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81779247177639,
              21.003554246927933
            ],
            [
              105.81779209361365,
              21.00355527842558
            ],
            [
              105.81778941241426,
              21.003562602943997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A58_Đường Láng",
          "maTaiSan": "04.O11.DODV.11149",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003554246927933,
          "to_latitude": 21.003562602943997,
          "to_longitude": 105.81778941241426,
          "from_longitude": 105.81779247177639
        }
      },
      {
        "id": 11127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81696530673231,
            21.010026347670472,
            105.81697405426667,
            21.01003376362832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81696530673231,
              21.010026347670472
            ],
            [
              105.81696710513937,
              21.010027872717746
            ],
            [
              105.81696847062312,
              21.010029032229607
            ],
            [
              105.81697153785406,
              21.010031630542834
            ],
            [
              105.81697405426667,
              21.01003376362832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11150",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010026347670472,
          "to_latitude": 21.01003376362832,
          "to_longitude": 105.81697405426667,
          "from_longitude": 105.81696530673231
        }
      },
      {
        "id": 11128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81675463177523,
            21.010024200418147,
            105.81696743431469,
            21.010236496957106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81675463177523,
              21.010236496957106
            ],
            [
              105.81684478272699,
              21.010148021159644
            ],
            [
              105.81696530673231,
              21.010026347670472
            ],
            [
              105.81696743431469,
              21.010024200418147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11151",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010236496957106,
          "to_latitude": 21.010024200418147,
          "to_longitude": 105.81696743431469,
          "from_longitude": 105.81675463177523
        }
      },
      {
        "id": 11129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8167763310501,
            21.010038463036153,
            105.81698329104755,
            21.01023926055321
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8167763310501,
              21.01023926055321
            ],
            [
              105.8168470485333,
              21.010169495371372
            ],
            [
              105.81697710952359,
              21.010044408858537
            ],
            [
              105.81698329104755,
              21.010038463036153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11152",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01023926055321,
          "to_latitude": 21.010038463036153,
          "to_longitude": 105.81698329104755,
          "from_longitude": 105.8167763310501
        }
      },
      {
        "id": 11130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81696298224361,
            21.010027872717746,
            105.81697710952359,
            21.010044408858537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81697710952359,
              21.010044408858537
            ],
            [
              105.81697056739051,
              21.01003871470432
            ],
            [
              105.81696943491568,
              21.010038303753173
            ],
            [
              105.81696940792901,
              21.01003829394723
            ],
            [
              105.81696920124473,
              21.01003761029258
            ],
            [
              105.81696298224361,
              21.010032256910165
            ],
            [
              105.81696710513937,
              21.010027872717746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11153",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010044408858537,
          "to_latitude": 21.010027872717746,
          "to_longitude": 105.81696710513937,
          "from_longitude": 105.81697710952359
        }
      },
      {
        "id": 11131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81698534677076,
            21.00981137214867,
            105.81718614617294,
            21.01000368701157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81698534677076,
              21.01000368701157
            ],
            [
              105.81698839358546,
              21.01000076940818
            ],
            [
              105.81718614617294,
              21.00981137214867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11154",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01000368701157,
          "to_latitude": 21.00981137214867,
          "to_longitude": 105.81718614617294,
          "from_longitude": 105.81698534677076
        }
      },
      {
        "id": 11132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81700452121355,
            21.00982422306454,
            105.81719728766276,
            21.01001955860638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81700452121355,
              21.01001955860638
            ],
            [
              105.81701176860801,
              21.010012214887727
            ],
            [
              105.81719728766276,
              21.00982422306454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11155",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01001955860638,
          "to_latitude": 21.00982422306454,
          "to_longitude": 105.81719728766276,
          "from_longitude": 105.81700452121355
        }
      },
      {
        "id": 11133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81698839358546,
            21.01000076940818,
            105.81699909107316,
            21.010010690589972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81698839358546,
              21.01000076940818
            ],
            [
              105.81698989307233,
              21.01000216040615
            ],
            [
              105.81699069261319,
              21.010002903537128
            ],
            [
              105.81699909107316,
              21.010010690589972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11156",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01000076940818,
          "to_latitude": 21.010010690589972,
          "to_longitude": 105.81699909107316,
          "from_longitude": 105.81698839358546
        }
      },
      {
        "id": 11134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81698989307233,
            21.00999766888366,
            105.81701176860801,
            21.010012214887727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81701176860801,
              21.010012214887727
            ],
            [
              105.81700605764982,
              21.01000724384283
            ],
            [
              105.81700479252106,
              21.010006142559277
            ],
            [
              105.81700400412556,
              21.010005856408288
            ],
            [
              105.81700363305967,
              21.0100057218024
            ],
            [
              105.81700342637532,
              21.010005038147806
            ],
            [
              105.816994924381,
              21.00999766888366
            ],
            [
              105.81698989307233,
              21.01000216040615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_Thái Thịnh",
          "maTaiSan": "04.O11.DODV.11157",
          "diaChiLapD": "Thái Thịnh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010012214887727,
          "to_latitude": 21.01000216040615,
          "to_longitude": 105.81698989307233,
          "from_longitude": 105.81701176860801
        }
      },
      {
        "id": 11135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82198870716954,
            21.007287328232685,
            105.82201584087588,
            21.007320743102085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82198870716954,
              21.007320743102085
            ],
            [
              105.82201584087588,
              21.007291044888902
            ],
            [
              105.82201389232401,
              21.00728950616861
            ],
            [
              105.82201113998205,
              21.007287328232685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33_Tây Sơn",
          "maTaiSan": "04.O11.DODV.11158",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007320743102085,
          "to_latitude": 21.007287328232685,
          "to_longitude": 105.82201113998205,
          "from_longitude": 105.82198870716954
        }
      },
      {
        "id": 11136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81633213102609,
            21.00662039087791,
            105.8165468159082,
            21.006739449732425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8165468159082,
              21.006739449732425
            ],
            [
              105.81651669312168,
              21.00672345446507
            ],
            [
              105.81649884841926,
              21.006713227917693
            ],
            [
              105.81649546757232,
              21.006707955734395
            ],
            [
              105.81649402782165,
              21.00670834380421
            ],
            [
              105.81649095719072,
              21.006709172344795
            ],
            [
              105.81638153443632,
              21.006648006761118
            ],
            [
              105.81633213102609,
              21.00662039087791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Đường Láng",
          "maTaiSan": "04.O11.DODV.11159",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006739449732425,
          "to_latitude": 21.00662039087791,
          "to_longitude": 105.81633213102609,
          "from_longitude": 105.8165468159082
        }
      },
      {
        "id": 11137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81637753550108,
            21.006648006761118,
            105.81638153443632,
            21.006653946607187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81638153443632,
              21.006648006761118
            ],
            [
              105.81637841237726,
              21.006652642654917
            ],
            [
              105.81637753550108,
              21.006653946607187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Đường Láng",
          "maTaiSan": "04.O11.DODV.11160",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006648006761118,
          "to_latitude": 21.006653946607187,
          "to_longitude": 105.81637753550108,
          "from_longitude": 105.81638153443632
        }
      },
      {
        "id": 11138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8165468159082,
            21.006739449732425,
            105.81673814976322,
            21.00695914748696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81667217137765,
              21.00695914748696
            ],
            [
              105.81673814976322,
              21.006863509545614
            ],
            [
              105.8166765245146,
              21.006808319045955
            ],
            [
              105.8165468159082,
              21.006739449732425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A4_Đường Láng",
          "maTaiSan": "04.O11.DODV.11161",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00695914748696,
          "to_latitude": 21.006739449732425,
          "to_longitude": 105.8165468159082,
          "from_longitude": 105.81667217137765
        }
      },
      {
        "id": 11139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035237945055,
            21.003778079524835,
            105.8303601392911,
            21.003809464919357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303601392911,
              21.003809464919357
            ],
            [
              105.83035332397954,
              21.00378190098455
            ],
            [
              105.83035237945055,
              21.003778079524835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KH_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.11162",
          "diaChiLapD": "Ngõ 1A, Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003809464919357,
          "to_latitude": 21.003778079524835,
          "to_longitude": 105.83035237945055,
          "from_longitude": 105.8303601392911
        }
      },
      {
        "id": 11140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82030288050336,
            21.007880556911164,
            105.82032372578642,
            21.007901815502542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82032372578642,
              21.007880556911164
            ],
            [
              105.82031873064645,
              21.007885650807022
            ],
            [
              105.82030288050336,
              21.007901815502542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT76_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11163",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007880556911164,
          "to_latitude": 21.007901815502542,
          "to_longitude": 105.82030288050336,
          "from_longitude": 105.82032372578642
        }
      },
      {
        "id": 11141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82032110425578,
            21.007894608401237,
            105.82034209389568,
            21.00791602936842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82034209389568,
              21.007894608401237
            ],
            [
              105.8203370356432,
              21.00789977034393
            ],
            [
              105.82032110425578,
              21.00791602936842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT75_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11164",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007894608401237,
          "to_latitude": 21.00791602936842,
          "to_longitude": 105.82032110425578,
          "from_longitude": 105.82034209389568
        }
      },
      {
        "id": 11142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82032654726174,
            21.007769332191973,
            105.82041954228858,
            21.007882714670384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82032654726174,
              21.007882714670384
            ],
            [
              105.82036318005652,
              21.007843338900145
            ],
            [
              105.82035719947437,
              21.007838493912473
            ],
            [
              105.82036480899737,
              21.007830051792475
            ],
            [
              105.82041954228858,
              21.007769332191973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT79_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11165",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007882714670384,
          "to_latitude": 21.007769332191973,
          "to_longitude": 105.82041954228858,
          "from_longitude": 105.82032654726174
        }
      },
      {
        "id": 11143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82033728646752,
            21.0077861226065,
            105.82043146820752,
            21.007890931050742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82033728646752,
              21.007890931050742
            ],
            [
              105.8203831770049,
              21.0078398626242
            ],
            [
              105.82043146820752,
              21.0077861226065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT80_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11166",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007890931050742,
          "to_latitude": 21.0077861226065,
          "to_longitude": 105.82043146820752,
          "from_longitude": 105.82033728646752
        }
      },
      {
        "id": 11144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82017053145101,
            21.0076537961751,
            105.82026862810497,
            21.007763358119618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82017053145101,
              21.007763358119618
            ],
            [
              105.82021484064492,
              21.00771386987804
            ],
            [
              105.82026862810497,
              21.0076537961751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT82_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11167",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007763358119618,
          "to_latitude": 21.0076537961751,
          "to_longitude": 105.82026862810497,
          "from_longitude": 105.82017053145101
        }
      },
      {
        "id": 11145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8201597507305,
            21.007644254331193,
            105.82025460356054,
            21.007755110315202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8201597507305,
              21.007755110315202
            ],
            [
              105.82020176893329,
              21.00770513543942
            ],
            [
              105.82025460356054,
              21.007644254331193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT81_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11168",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007755110315202,
          "to_latitude": 21.007644254331193,
          "to_longitude": 105.82025460356054,
          "from_longitude": 105.8201597507305
        }
      },
      {
        "id": 11146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82000204577318,
            21.007516898512097,
            105.82011212522828,
            21.0076344601899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82000204577318,
              21.0076344601899
            ],
            [
              105.8200480393433,
              21.00758534052057
            ],
            [
              105.82011212522828,
              21.007516898512097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT84_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11169",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0076344601899,
          "to_latitude": 21.007516898512097,
          "to_longitude": 105.82011212522828,
          "from_longitude": 105.82000204577318
        }
      },
      {
        "id": 11147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81999246958254,
            21.007507895282377,
            105.82009578838782,
            21.007627134185373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81999246958254,
              21.007627134185373
            ],
            [
              105.81999256713974,
              21.007627032553792
            ],
            [
              105.8200312963825,
              21.007586685717293
            ],
            [
              105.82002582698225,
              21.007581209607476
            ],
            [
              105.82003258784832,
              21.00757412462658
            ],
            [
              105.82009578838782,
              21.007507895282377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT83_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11170",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007627134185373,
          "to_latitude": 21.007507895282377,
          "to_longitude": 105.82009578838782,
          "from_longitude": 105.81999246958254
        }
      },
      {
        "id": 11148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81999353785064,
            21.00764369174328,
            105.82001411308718,
            21.007667548486292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82001411308718,
              21.00764369174328
            ],
            [
              105.82001006226987,
              21.007648388175976
            ],
            [
              105.81999353785064,
              21.007667548486292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT73_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11171",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00764369174328,
          "to_latitude": 21.007667548486292,
          "to_longitude": 105.81999353785064,
          "from_longitude": 105.82001411308718
        }
      },
      {
        "id": 11149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82001407930655,
            21.007659293387984,
            105.8200345057275,
            21.007682219091603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8200345057275,
              21.007659293387984
            ],
            [
              105.8200295057178,
              21.00766490484858
            ],
            [
              105.82001407930655,
              21.007682219091603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT74_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11172",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007659293387984,
          "to_latitude": 21.007682219091603,
          "to_longitude": 105.82001407930655,
          "from_longitude": 105.8200345057275
        }
      },
      {
        "id": 11150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81972224455919,
            21.007428911079533,
            105.81974963541217,
            21.007454060623495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81974963541217,
              21.007428911079533
            ],
            [
              105.81974397807691,
              21.007434105697236
            ],
            [
              105.81972224455919,
              21.007454060623495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT67_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11173",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007428911079533,
          "to_latitude": 21.007454060623495,
          "to_longitude": 105.81972224455919,
          "from_longitude": 105.81974963541217
        }
      },
      {
        "id": 11151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81970728050021,
            21.0074140890469,
            105.81973070915902,
            21.007440152538024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81973070915902,
              21.0074140890469
            ],
            [
              105.81972464858887,
              21.00742083102929
            ],
            [
              105.81970728050021,
              21.007440152538024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT68_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11174",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0074140890469,
          "to_latitude": 21.007440152538024,
          "to_longitude": 105.81970728050021,
          "from_longitude": 105.81973070915902
        }
      },
      {
        "id": 11152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81942202583555,
            21.007189099312413,
            105.81944341256629,
            21.007214256931178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81944341256629,
              21.007189099312413
            ],
            [
              105.81943642032815,
              21.007197324355115
            ],
            [
              105.81942202583555,
              21.007214256931178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT69_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11175",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007189099312413,
          "to_latitude": 21.007214256931178,
          "to_longitude": 105.81942202583555,
          "from_longitude": 105.81944341256629
        }
      },
      {
        "id": 11153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81940157023168,
            21.00717252760564,
            105.81942225294075,
            21.007196790346054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81942225294075,
              21.00717252760564
            ],
            [
              105.81941526440168,
              21.007180725532844
            ],
            [
              105.81940157023168,
              21.007196790346054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT70_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11176",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00717252760564,
          "to_latitude": 21.007196790346054,
          "to_longitude": 105.81940157023168,
          "from_longitude": 105.81942225294075
        }
      },
      {
        "id": 11154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81960915553202,
            21.00693979514495,
            105.81963259937935,
            21.006965533533428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81963259937935,
              21.00693979514495
            ],
            [
              105.81962450556921,
              21.0069486812438
            ],
            [
              105.81960915553202,
              21.006965533533428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT65_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11177",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00693979514495,
          "to_latitude": 21.006965533533428,
          "to_longitude": 105.81960915553202,
          "from_longitude": 105.81963259937935
        }
      },
      {
        "id": 11155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81962983960676,
            21.0069545405676,
            105.81965130679548,
            21.00697833924055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81965130679548,
              21.0069545405676
            ],
            [
              105.81964386838081,
              21.006962786735063
            ],
            [
              105.81962983960676,
              21.00697833924055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT64_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11178",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0069545405676,
          "to_latitude": 21.00697833924055,
          "to_longitude": 105.81962983960676,
          "from_longitude": 105.81965130679548
        }
      },
      {
        "id": 11156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81991015634132,
            21.007176428154942,
            105.81993281688969,
            21.007208560373538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81993281688969,
              21.007176428154942
            ],
            [
              105.81992652202507,
              21.00718535347173
            ],
            [
              105.81991015634132,
              21.007208560373538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT63_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11179",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007176428154942,
          "to_latitude": 21.007208560373538,
          "to_longitude": 105.81991015634132,
          "from_longitude": 105.81993281688969
        }
      },
      {
        "id": 11157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81992922738615,
            21.00719370966194,
            105.81995474129978,
            21.007219604389903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81995474129978,
              21.00719370966194
            ],
            [
              105.81994860005439,
              21.007199942620073
            ],
            [
              105.81992922738615,
              21.007219604389903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT62_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11180",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00719370966194,
          "to_latitude": 21.007219604389903,
          "to_longitude": 105.81992922738615,
          "from_longitude": 105.81995474129978
        }
      },
      {
        "id": 11158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82013462809613,
            21.006956313340236,
            105.82016393410203,
            21.006987234771366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82016393410203,
              21.006956313340236
            ],
            [
              105.82015401560089,
              21.00696677806713
            ],
            [
              105.82013462809613,
              21.006987234771366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT57_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11181",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006956313340236,
          "to_latitude": 21.006987234771366,
          "to_longitude": 105.82013462809613,
          "from_longitude": 105.82016393410203
        }
      },
      {
        "id": 11159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82012004168153,
            21.00694231986519,
            105.82014589252258,
            21.00697272786766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82014589252258,
              21.00694231986519
            ],
            [
              105.82013716279812,
              21.006952588281187
            ],
            [
              105.82012004168153,
              21.00697272786766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT58_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11182",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00694231986519,
          "to_latitude": 21.00697272786766,
          "to_longitude": 105.82012004168153,
          "from_longitude": 105.82014589252258
        }
      },
      {
        "id": 11160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81982808965186,
            21.00671765115565,
            105.81985622120241,
            21.006750688560107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81985622120241,
              21.00671765115565
            ],
            [
              105.81984657768358,
              21.0067289762199
            ],
            [
              105.81982808965186,
              21.006750688560107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT59_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11183",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00671765115565,
          "to_latitude": 21.006750688560107,
          "to_longitude": 105.81982808965186,
          "from_longitude": 105.81985622120241
        }
      },
      {
        "id": 11161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81981888618479,
            21.006709361330422,
            105.81984553364882,
            21.006743085263228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81984553364882,
              21.006709361330422
            ],
            [
              105.8198362617278,
              21.006721095572754
            ],
            [
              105.81981888618479,
              21.006743085263228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT60_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11184",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006709361330422,
          "to_latitude": 21.006743085263228,
          "to_longitude": 105.81981888618479,
          "from_longitude": 105.81984553364882
        }
      },
      {
        "id": 11162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82048543576346,
            21.007027872338725,
            105.82052184459432,
            21.00715787882468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82048543576346,
              21.00715787882468
            ],
            [
              105.82050962733972,
              21.007115162353216
            ],
            [
              105.82052184459432,
              21.007093590368854
            ],
            [
              105.8204914710857,
              21.00706595532454
            ],
            [
              105.82052148525639,
              21.007027872338725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT92_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11185",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00715787882468,
          "to_latitude": 21.007027872338725,
          "to_longitude": 105.82052148525639,
          "from_longitude": 105.82048543576346
        }
      },
      {
        "id": 11163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82049137750157,
            21.007035899608418,
            105.82053170230908,
            21.007162660770188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82049137750157,
              21.007162660770188
            ],
            [
              105.82051744154968,
              21.007117599522907
            ],
            [
              105.82053170230908,
              21.007092945807518
            ],
            [
              105.82050357932935,
              21.007069481859734
            ],
            [
              105.82052948200584,
              21.007035899608418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT91_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11186",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007162660770188,
          "to_latitude": 21.007035899608418,
          "to_longitude": 105.82052948200584,
          "from_longitude": 105.82049137750157
        }
      },
      {
        "id": 11164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8205974081193,
            21.00715720385747,
            105.8206896719648,
            21.00724799412468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8205974081193,
              21.00724799412468
            ],
            [
              105.82063278442355,
              21.00721318294929
            ],
            [
              105.8206896719648,
              21.00715720385747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11187",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00724799412468,
          "to_latitude": 21.00715720385747,
          "to_longitude": 105.8206896719648,
          "from_longitude": 105.8205974081193
        }
      },
      {
        "id": 11165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82061154271145,
            21.007165037995847,
            105.82069688469964,
            21.00725936986627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82061154271145,
              21.00725936986627
            ],
            [
              105.82064484961987,
              21.007222554468775
            ],
            [
              105.82069688469964,
              21.007165037995847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT89_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11188",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00725936986627,
          "to_latitude": 21.007165037995847,
          "to_longitude": 105.82069688469964,
          "from_longitude": 105.82061154271145
        }
      },
      {
        "id": 11166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82078002854826,
            21.007293885548112,
            105.82086237770851,
            21.007394966434806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82078002854826,
              21.007394966434806
            ],
            [
              105.82079273153578,
              21.007379373263884
            ],
            [
              105.82086237770851,
              21.007293885548112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT88_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11189",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007394966434806,
          "to_latitude": 21.007293885548112,
          "to_longitude": 105.82086237770851,
          "from_longitude": 105.82078002854826
        }
      },
      {
        "id": 11167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82076393073974,
            21.007287963927077,
            105.82085152891217,
            21.007382011398686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82076393073974,
              21.007382011398686
            ],
            [
              105.82077577606327,
              21.00736929372648
            ],
            [
              105.82085152891217,
              21.007287963927077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT87_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11190",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007382011398686,
          "to_latitude": 21.007287963927077,
          "to_longitude": 105.82085152891217,
          "from_longitude": 105.82076393073974
        }
      },
      {
        "id": 11168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82044295852646,
            21.005969694370084,
            105.8205022697342,
            21.00601465567789
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82044295852646,
              21.00601263614523
            ],
            [
              105.82044713780334,
              21.006007519082704
            ],
            [
              105.82045006444672,
              21.006003936320425
            ],
            [
              105.82046414818674,
              21.00601465567789
            ],
            [
              105.8205022697342,
              21.005969694370084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT9_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11191",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00601263614523,
          "to_latitude": 21.005969694370084,
          "to_longitude": 105.8205022697342,
          "from_longitude": 105.82044295852646
        }
      },
      {
        "id": 11169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82044094464068,
            21.005957807346405,
            105.820489455061,
            21.00601110494657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82044094464068,
              21.00601110494657
            ],
            [
              105.82044547576393,
              21.00600612709151
            ],
            [
              105.820489455061,
              21.005957807346405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT8_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11192",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00601110494657,
          "to_latitude": 21.005957807346405,
          "to_longitude": 105.820489455061,
          "from_longitude": 105.82044094464068
        }
      },
      {
        "id": 11170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82069038931968,
            21.006147477934313,
            105.82073374051598,
            21.006200716401
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82069038931968,
              21.006200716401
            ],
            [
              105.82069249706608,
              21.006198127521376
            ],
            [
              105.82073374051598,
              21.006147477934313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT11_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11193",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006200716401,
          "to_latitude": 21.006147477934313,
          "to_longitude": 105.82073374051598,
          "from_longitude": 105.82069038931968
        }
      },
      {
        "id": 11171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82069236940235,
            21.00615081203214,
            105.82073630388034,
            21.006202221565943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82069236940235,
              21.006202221565943
            ],
            [
              105.82069481233042,
              21.006199362811262
            ],
            [
              105.82073630388034,
              21.00615081203214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT10_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11194",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006202221565943,
          "to_latitude": 21.00615081203214,
          "to_longitude": 105.82073630388034,
          "from_longitude": 105.82069236940235
        }
      },
      {
        "id": 11172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82064229893216,
            21.00575469138999,
            105.82070410677933,
            21.005822480427227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82064229893216,
              21.005822480427227
            ],
            [
              105.82066907280998,
              21.005793115643527
            ],
            [
              105.82070410677933,
              21.00575469138999
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT16_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11195",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005822480427227,
          "to_latitude": 21.00575469138999,
          "to_longitude": 105.82070410677933,
          "from_longitude": 105.82064229893216
        }
      },
      {
        "id": 11173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8206357766307,
            21.005749501333145,
            105.82069008117996,
            21.005817588380754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8206357766307,
              21.005817588380754
            ],
            [
              105.82066083961314,
              21.005786164359037
            ],
            [
              105.82069008117996,
              21.005749501333145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT15_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11196",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005817588380754,
          "to_latitude": 21.005749501333145,
          "to_longitude": 105.82069008117996,
          "from_longitude": 105.8206357766307
        }
      },
      {
        "id": 11174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82086010340781,
            21.0059102378195,
            105.82090830010118,
            21.005985866331933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82086010340781,
              21.005985866331933
            ],
            [
              105.82088132994699,
              21.005958403372308
            ],
            [
              105.8208868925535,
              21.005951206719192
            ],
            [
              105.82087794693703,
              21.005944319398314
            ],
            [
              105.82090830010118,
              21.0059102378195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT14_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11197",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005985866331933,
          "to_latitude": 21.0059102378195,
          "to_longitude": 105.82090830010118,
          "from_longitude": 105.82086010340781
        }
      },
      {
        "id": 11175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8208625807845,
            21.005922234618712,
            105.820923254617,
            21.005987724064994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8208625807845,
              21.005987724064994
            ],
            [
              105.82088322421744,
              21.005960047142853
            ],
            [
              105.820888934368,
              21.005952391841483
            ],
            [
              105.82089379016729,
              21.00595595333209
            ],
            [
              105.820923254617,
              21.005922234618712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT13_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11198",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005987724064994,
          "to_latitude": 21.005922234618712,
          "to_longitude": 105.820923254617,
          "from_longitude": 105.8208625807845
        }
      },
      {
        "id": 11176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82087905890111,
            21.005486510802967,
            105.82090403642036,
            21.005516835218124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82087905890111,
              21.005516835218124
            ],
            [
              105.82088975118403,
              21.00550383858416
            ],
            [
              105.82090403642036,
              21.005486510802967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 45, Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11199",
          "diaChiLapD": "Ngõ 45, Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005516835218124,
          "to_latitude": 21.005486510802967,
          "to_longitude": 105.82090403642036,
          "from_longitude": 105.82087905890111
        }
      },
      {
        "id": 11177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82088149190155,
            21.00548891400028,
            105.8209072805008,
            21.005518670584568
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82088149190155,
              21.005518670584568
            ],
            [
              105.82089238461754,
              21.00550609208149
            ],
            [
              105.8209072805008,
              21.00548891400028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT20_Ngõ 45, Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11200",
          "diaChiLapD": "Ngõ 45, Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005518670584568,
          "to_latitude": 21.00548891400028,
          "to_longitude": 105.8209072805008,
          "from_longitude": 105.82088149190155
        }
      },
      {
        "id": 11178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82110673553996,
            21.00565543182268,
            105.82112998340885,
            21.005685964699904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82110673553996,
              21.005685964699904
            ],
            [
              105.8211142906415,
              21.005676041517344
            ],
            [
              105.82112998340885,
              21.00565543182268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT19_Ngõ 45, Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11201",
          "diaChiLapD": "Ngõ 45, Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005685964699904,
          "to_latitude": 21.00565543182268,
          "to_longitude": 105.82112998340885,
          "from_longitude": 105.82110673553996
        }
      },
      {
        "id": 11179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8211084672234,
            21.005657808191856,
            105.82113279642968,
            21.005687251574162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8211084672234,
              21.005687251574162
            ],
            [
              105.82111631991562,
              21.00567774785998
            ],
            [
              105.82113279642968,
              21.005657808191856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT18_Ngõ 45, Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11202",
          "diaChiLapD": "Ngõ 45, Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005687251574162,
          "to_latitude": 21.005657808191856,
          "to_longitude": 105.82113279642968,
          "from_longitude": 105.8211084672234
        }
      },
      {
        "id": 11180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82013066169749,
            21.00526005156595,
            105.8201555928833,
            21.00528708338547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8201555928833,
              21.00526005156595
            ],
            [
              105.82014501054198,
              21.00527152568629
            ],
            [
              105.82013066169749,
              21.00528708338547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT56_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11203",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00526005156595,
          "to_latitude": 21.00528708338547,
          "to_longitude": 105.82013066169749,
          "from_longitude": 105.8201555928833
        }
      },
      {
        "id": 11181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82015077295253,
            21.005275337396426,
            105.82017479460377,
            21.005299832184182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82017479460377,
              21.005275337396426
            ],
            [
              105.82016356192563,
              21.005286791167904
            ],
            [
              105.82015077295253,
              21.005299832184182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11204",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005275337396426,
          "to_latitude": 21.005299832184182,
          "to_longitude": 105.82015077295253,
          "from_longitude": 105.82017479460377
        }
      },
      {
        "id": 11182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82043326382814,
            21.00550150747508,
            105.820458898627,
            21.0055326704818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.820458898627,
              21.00550150747508
            ],
            [
              105.8204535926027,
              21.005507957714666
            ],
            [
              105.82043326382814,
              21.0055326704818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11205",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00550150747508,
          "to_latitude": 21.0055326704818,
          "to_longitude": 105.82043326382814,
          "from_longitude": 105.820458898627
        }
      },
      {
        "id": 11183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204520960772,
            21.005517349004464,
            105.82047879792052,
            21.005544229534465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82047879792052,
              21.005517349004464
            ],
            [
              105.82047352604768,
              21.00552265649654
            ],
            [
              105.8204520960772,
              21.005544229534465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT53_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11206",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005517349004464,
          "to_latitude": 21.005544229534465,
          "to_longitude": 105.8204520960772,
          "from_longitude": 105.82047879792052
        }
      },
      {
        "id": 11184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82063994047618,
            21.005308678417418,
            105.82065987815994,
            21.005330142217606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82065987815994,
              21.005308678417418
            ],
            [
              105.82065536946702,
              21.00531353152417
            ],
            [
              105.82063994047618,
              21.005330142217606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT48_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11207",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005308678417418,
          "to_latitude": 21.005330142217606,
          "to_longitude": 105.82063994047618,
          "from_longitude": 105.82065987815994
        }
      },
      {
        "id": 11185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82061939070695,
            21.005293194688726,
            105.82063995774813,
            21.005318651079342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82063995774813,
              21.005293194688726
            ],
            [
              105.82063510141177,
              21.005299204699828
            ],
            [
              105.82061939070695,
              21.005318651079342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT49_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11208",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005293194688726,
          "to_latitude": 21.005318651079342,
          "to_longitude": 105.82061939070695,
          "from_longitude": 105.82063995774813
        }
      },
      {
        "id": 11186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82033156969737,
            21.005070389063086,
            105.82035330598539,
            21.005094688601258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82035330598539,
              21.005070389063086
            ],
            [
              105.82034810040429,
              21.005076208362055
            ],
            [
              105.82033156969737,
              21.005094688601258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT50_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11209",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005070389063086,
          "to_latitude": 21.005094688601258,
          "to_longitude": 105.82033156969737,
          "from_longitude": 105.82035330598539
        }
      },
      {
        "id": 11187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8203135677021,
            21.005055545326204,
            105.8203342096111,
            21.00507909894033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8203342096111,
              21.005055545326204
            ],
            [
              105.82032904196629,
              21.005061442119356
            ],
            [
              105.8203135677021,
              21.00507909894033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT51_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11210",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005055545326204,
          "to_latitude": 21.00507909894033,
          "to_longitude": 105.8203135677021,
          "from_longitude": 105.8203342096111
        }
      },
      {
        "id": 11188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82049715678968,
            21.004845804126017,
            105.82051837896772,
            21.004868771654063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82051837896772,
              21.004845804126017
            ],
            [
              105.82051115191594,
              21.004853625696214
            ],
            [
              105.82049715678968,
              21.004868771654063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11211",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004845804126017,
          "to_latitude": 21.004868771654063,
          "to_longitude": 105.82049715678968,
          "from_longitude": 105.82051837896772
        }
      },
      {
        "id": 11189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82051739220927,
            21.004861606280233,
            105.82053857614217,
            21.004883300074734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82053857614217,
              21.004861606280233
            ],
            [
              105.82053216175312,
              21.00486817476897
            ],
            [
              105.82051739220927,
              21.004883300074734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11212",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004861606280233,
          "to_latitude": 21.004883300074734,
          "to_longitude": 105.82051739220927,
          "from_longitude": 105.82053857614217
        }
      },
      {
        "id": 11190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82080325449347,
            21.005084754516197,
            105.82082379452117,
            21.00510564227038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82082379452117,
              21.005084754516197
            ],
            [
              105.82081811069357,
              21.00509053458701
            ],
            [
              105.82080325449347,
              21.00510564227038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT44_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11213",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005084754516197,
          "to_latitude": 21.00510564227038,
          "to_longitude": 105.82080325449347,
          "from_longitude": 105.82082379452117
        }
      },
      {
        "id": 11191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82082433593968,
            21.005099895927692,
            105.82084314747004,
            21.00512131367193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82084314747004,
              21.005099895927692
            ],
            [
              105.82083670839897,
              21.005107226871118
            ],
            [
              105.82082433593968,
              21.00512131367193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT43_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11214",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005099895927692,
          "to_latitude": 21.00512131367193,
          "to_longitude": 105.82082433593968,
          "from_longitude": 105.82084314747004
        }
      },
      {
        "id": 11192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82100534683782,
            21.00489293602072,
            105.82102303469085,
            21.00491209751935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82102303469085,
              21.00489293602072
            ],
            [
              105.82101862563883,
              21.00489771278483
            ],
            [
              105.82100534683782,
              21.00491209751935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT38_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11215",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00489293602072,
          "to_latitude": 21.00491209751935,
          "to_longitude": 105.82100534683782,
          "from_longitude": 105.82102303469085
        }
      },
      {
        "id": 11193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82068152523748,
            21.004640257567562,
            105.82069875213331,
            21.004660341716267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82069875213331,
              21.004640257567562
            ],
            [
              105.82069361961744,
              21.00464624091177
            ],
            [
              105.82068152523748,
              21.004660341716267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11216",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004640257567562,
          "to_latitude": 21.004660341716267,
          "to_longitude": 105.82068152523748,
          "from_longitude": 105.82069875213331
        }
      },
      {
        "id": 11194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82069965619263,
            21.004653856419097,
            105.82071620361785,
            21.00467172528758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82071620361785,
              21.004653856419097
            ],
            [
              105.82071155064077,
              21.00465888093294
            ],
            [
              105.82069965619263,
              21.00467172528758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT40_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11217",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004653856419097,
          "to_latitude": 21.00467172528758,
          "to_longitude": 105.82069965619263,
          "from_longitude": 105.82071620361785
        }
      },
      {
        "id": 11195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82027450086055,
            21.007264179754287,
            105.82030218141604,
            21.007300712355125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82027450086055,
              21.007300712355125
            ],
            [
              105.8202781852301,
              21.00729584877911
            ],
            [
              105.82030218141604,
              21.007264179754287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT104_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11218",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007300712355125,
          "to_latitude": 21.007264179754287,
          "to_longitude": 105.82030218141604,
          "from_longitude": 105.82027450086055
        }
      },
      {
        "id": 11196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82028946626009,
            21.007276653192417,
            105.8203172011522,
            21.007313099347638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82028946626009,
              21.007313099347638
            ],
            [
              105.82029232178768,
              21.007309347123734
            ],
            [
              105.8203172011522,
              21.007276653192417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT103_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11219",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007313099347638,
          "to_latitude": 21.007276653192417,
          "to_longitude": 105.8203172011522,
          "from_longitude": 105.82028946626009
        }
      },
      {
        "id": 11197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82045041489543,
            21.00739063882815,
            105.82048000407694,
            21.007447114735502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82045138508933,
              21.007447114735502
            ],
            [
              105.82045716699922,
              21.007439023745956
            ],
            [
              105.82046102447295,
              21.007433626427158
            ],
            [
              105.82045041489543,
              21.007424835878833
            ],
            [
              105.82048000407694,
              21.00739063882815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT102_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11220",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007447114735502,
          "to_latitude": 21.00739063882815,
          "to_longitude": 105.82048000407694,
          "from_longitude": 105.82045138508933
        }
      },
      {
        "id": 11198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204533253366,
            21.00740110026751,
            105.82049100465862,
            21.00744872035294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204533253366,
              21.00744872035294
            ],
            [
              105.82045950507406,
              21.007440909252473
            ],
            [
              105.82049100465862,
              21.00740110026751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT101_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11221",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00744872035294,
          "to_latitude": 21.00740110026751,
          "to_longitude": 105.82049100465862,
          "from_longitude": 105.8204533253366
        }
      },
      {
        "id": 11199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8205763022457,
            21.007528293218986,
            105.82064059353017,
            21.007597827509368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8205763022457,
              21.007597827509368
            ],
            [
              105.82058263919708,
              21.0075909739712
            ],
            [
              105.82064059353017,
              21.007528293218986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT100_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11222",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007597827509368,
          "to_latitude": 21.007528293218986,
          "to_longitude": 105.82064059353017,
          "from_longitude": 105.8205763022457
        }
      },
      {
        "id": 11200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82058535448742,
            21.00753572563101,
            105.82064795029099,
            21.007605024135415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82058535448742,
              21.007605024135415
            ],
            [
              105.82059116470622,
              21.007598592234462
            ],
            [
              105.82064795029099,
              21.00753572563101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT99_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11223",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007605024135415,
          "to_latitude": 21.00753572563101,
          "to_longitude": 105.82064795029099,
          "from_longitude": 105.82058535448742
        }
      },
      {
        "id": 11201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8192095011105,
            21.003475531323826,
            105.82038154575514,
            21.003784758985173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81952465318342,
              21.003784758985173
            ],
            [
              105.81938275548625,
              21.003758198969805
            ],
            [
              105.81921610576525,
              21.00376133870003
            ],
            [
              105.8192095011105,
              21.003628549843963
            ],
            [
              105.81927105560028,
              21.003607775576864
            ],
            [
              105.81956005606341,
              21.003510240126698
            ],
            [
              105.81972070611522,
              21.003475531323826
            ],
            [
              105.82002881249534,
              21.003533991769867
            ],
            [
              105.82038154575514,
              21.00360091839101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11224",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 173.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003784758985173,
          "to_latitude": 21.00360091839101,
          "to_longitude": 105.82038154575514,
          "from_longitude": 105.81952465318342
        }
      },
      {
        "id": 11202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82002194657281,
            21.003533991769867,
            105.82014523567105,
            21.003729275107272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82014523567105,
              21.003729275107272
            ],
            [
              105.82010634369409,
              21.003723288035456
            ],
            [
              105.82010876655683,
              21.003656559404295
            ],
            [
              105.82002194657281,
              21.003647340258865
            ],
            [
              105.82002881249534,
              21.003533991769867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11225",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003729275107272,
          "to_latitude": 21.003533991769867,
          "to_longitude": 105.82002881249534,
          "from_longitude": 105.82014523567105
        }
      },
      {
        "id": 11203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81927105560028,
            21.003607775576864,
            105.81936576608072,
            21.00365301038886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81936576608072,
              21.00365301038886
            ],
            [
              105.81927411324388,
              21.003633833451232
            ],
            [
              105.81927105560028,
              21.003607775576864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11226",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00365301038886,
          "to_latitude": 21.003607775576864,
          "to_longitude": 105.81927105560028,
          "from_longitude": 105.81936576608072
        }
      },
      {
        "id": 11204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81954255030641,
            21.003465695901237,
            105.81956005606341,
            21.003510240126698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81956005606341,
              21.003510240126698
            ],
            [
              105.81955539555025,
              21.003498384210484
            ],
            [
              105.81954255030641,
              21.003465695901237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B24A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11227",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003510240126698,
          "to_latitude": 21.003465695901237,
          "to_longitude": 105.81954255030641,
          "from_longitude": 105.81956005606341
        }
      },
      {
        "id": 11205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8189650721574,
            21.00365710482722,
            105.81918522565695,
            21.003768220210343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81918522565695,
              21.00365710482722
            ],
            [
              105.8189888540497,
              21.003768220210343
            ],
            [
              105.81898135982,
              21.003756345396514
            ],
            [
              105.8189650721574,
              21.003730530012817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B23A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11228",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00365710482722,
          "to_latitude": 21.003730530012817,
          "to_longitude": 105.8189650721574,
          "from_longitude": 105.81918522565695
        }
      },
      {
        "id": 11206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81883040976213,
            21.00386917404857,
            105.81913499970763,
            21.003955234900626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81913499970763,
              21.003955234900626
            ],
            [
              105.81903972232857,
              21.003925820932736
            ],
            [
              105.81902204829515,
              21.003951596506592
            ],
            [
              105.81883040976213,
              21.00386917404857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11229",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003955234900626,
          "to_latitude": 21.00386917404857,
          "to_longitude": 105.81883040976213,
          "from_longitude": 105.81913499970763
        }
      },
      {
        "id": 11207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81876097967603,
            21.003915927568215,
            105.8190177547252,
            21.00398089170388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81876097967603,
              21.003936907862006
            ],
            [
              105.81880573121931,
              21.003952040241167
            ],
            [
              105.8188355405258,
              21.003915927568215
            ],
            [
              105.81887776854579,
              21.00392002829423
            ],
            [
              105.8190177547252,
              21.00398089170388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11230",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003936907862006,
          "to_latitude": 21.00398089170388,
          "to_longitude": 105.8190177547252,
          "from_longitude": 105.81876097967603
        }
      },
      {
        "id": 11208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81872978409966,
            21.003827081447835,
            105.81888680503957,
            21.003967341367392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81888680503957,
              21.003827081447835
            ],
            [
              105.81885560035983,
              21.003850372288316
            ],
            [
              105.81883040976213,
              21.00386917404857
            ],
            [
              105.81876097967603,
              21.003936907862006
            ],
            [
              105.81872978409966,
              21.003967341367392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11231",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003827081447835,
          "to_latitude": 21.003967341367392,
          "to_longitude": 105.81872978409966,
          "from_longitude": 105.81888680503957
        }
      },
      {
        "id": 11209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81882601187236,
            21.00381817634501,
            105.81885560035983,
            21.003850372288316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81885560035983,
              21.003850372288316
            ],
            [
              105.81885049043143,
              21.00384481401661
            ],
            [
              105.81882601187236,
              21.00381817634501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B22A_Đường Láng",
          "maTaiSan": "04.O11.DODV.11232",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.003850372288316,
          "to_latitude": 21.00381817634501,
          "to_longitude": 105.81882601187236,
          "from_longitude": 105.81885560035983
        }
      },
      {
        "id": 11210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82146178384492,
            21.005228574134232,
            105.82151711286849,
            21.005289865975914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82151711286849,
              21.005228574134232
            ],
            [
              105.82151419206194,
              21.00523181004635
            ],
            [
              105.82146178384492,
              21.005289865975914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11233",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005228574134232,
          "to_latitude": 21.005289865975914,
          "to_longitude": 105.82146178384492,
          "from_longitude": 105.82151711286849
        }
      },
      {
        "id": 11211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82145858666682,
            21.005226966976878,
            105.82151511972843,
            21.00528689443253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82151511972843,
              21.005226966976878
            ],
            [
              105.82151246880076,
              21.00522977706921
            ],
            [
              105.82145858666682,
              21.00528689443253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT24_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11234",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005226966976878,
          "to_latitude": 21.00528689443253,
          "to_longitude": 105.82145858666682,
          "from_longitude": 105.82151511972843
        }
      },
      {
        "id": 11212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82153376296279,
            21.005119182377147,
            105.82154686976733,
            21.00513546129355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82153376296279,
              21.00513546129355
            ],
            [
              105.82153480909945,
              21.00513416191835
            ],
            [
              105.82154686976733,
              21.005119182377147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT26_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11235",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00513546129355,
          "to_latitude": 21.005119182377147,
          "to_longitude": 105.82154686976733,
          "from_longitude": 105.82153376296279
        }
      },
      {
        "id": 11213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82153185558863,
            21.00511811861893,
            105.82154457372792,
            21.005134058755107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82153185558863,
              21.005134058755107
            ],
            [
              105.82153310705381,
              21.005132490130137
            ],
            [
              105.82154457372792,
              21.00511811861893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT27_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11236",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005134058755107,
          "to_latitude": 21.00511811861893,
          "to_longitude": 105.82154457372792,
          "from_longitude": 105.82153185558863
        }
      },
      {
        "id": 11214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82141006781427,
            21.005013821629408,
            105.82143367812219,
            21.005041020674316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141006781427,
              21.005041020674316
            ],
            [
              105.82142126648725,
              21.00502812001639
            ],
            [
              105.82143367812219,
              21.005013821629408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT28_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11237",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005041020674316,
          "to_latitude": 21.005013821629408,
          "to_longitude": 105.82143367812219,
          "from_longitude": 105.82141006781427
        }
      },
      {
        "id": 11215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82140564681477,
            21.00501097115105,
            105.8214300997506,
            21.005037637744753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82140564681477,
              21.005037637744753
            ],
            [
              105.8214174965535,
              21.005024714977143
            ],
            [
              105.8214300997506,
              21.00501097115105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT29_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11238",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005037637744753,
          "to_latitude": 21.00501097115105,
          "to_longitude": 105.8214300997506,
          "from_longitude": 105.82140564681477
        }
      },
      {
        "id": 11216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131332845842,
            21.004949087392866,
            105.82132847222576,
            21.004967001029662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82131332845842,
              21.004967001029662
            ],
            [
              105.82131494260537,
              21.00496509104237
            ],
            [
              105.82132847222576,
              21.004949087392866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT30_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11239",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004967001029662,
          "to_latitude": 21.004949087392866,
          "to_longitude": 105.82132847222576,
          "from_longitude": 105.82131332845842
        }
      },
      {
        "id": 11217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82131141111896,
            21.00494670734101,
            105.82132642292065,
            21.0049655344075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82131141111896,
              21.0049655344075
            ],
            [
              105.82131253265403,
              21.00496412718565
            ],
            [
              105.82132642292065,
              21.00494670734101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT31_Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11240",
          "diaChiLapD": "Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0049655344075,
          "to_latitude": 21.00494670734101,
          "to_longitude": 105.82132642292065,
          "from_longitude": 105.82131141111896
        }
      },
      {
        "id": 11218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82075032157043,
            21.006860337817976,
            105.82076297428104,
            21.006875267774753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82075032157043,
              21.006875267774753
            ],
            [
              105.82075575385346,
              21.00686885756227
            ],
            [
              105.82076297428104,
              21.006860337817976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT98_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11241",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.006875267774753,
          "to_latitude": 21.006860337817976,
          "to_longitude": 105.82076297428104,
          "from_longitude": 105.82075032157043
        }
      },
      {
        "id": 11219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82075279648537,
            21.006862236402043,
            105.82076578374445,
            21.00687720048864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82075279648537,
              21.00687720048864
            ],
            [
              105.82075818944234,
              21.006870986466193
            ],
            [
              105.82076578374445,
              21.006862236402043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11242",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00687720048864,
          "to_latitude": 21.006862236402043,
          "to_longitude": 105.82076578374445,
          "from_longitude": 105.82075279648537
        }
      },
      {
        "id": 11220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82097726789918,
            21.00703656659723,
            105.82098993280322,
            21.007052606696128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82097726789918,
              21.007052606696128
            ],
            [
              105.82098145482384,
              21.007047303518974
            ],
            [
              105.82098993280322,
              21.00703656659723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11243",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007052606696128,
          "to_latitude": 21.00703656659723,
          "to_longitude": 105.82098993280322,
          "from_longitude": 105.82097726789918
        }
      },
      {
        "id": 11221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82097974282982,
            21.00703822582826,
            105.82099274096329,
            21.007054541212895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82097974282982,
              21.007054541212895
            ],
            [
              105.82098398541868,
              21.007049215186456
            ],
            [
              105.82099274096329,
              21.00703822582826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11244",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007054541212895,
          "to_latitude": 21.00703822582826,
          "to_longitude": 105.82099274096329,
          "from_longitude": 105.82097974282982
        }
      },
      {
        "id": 11222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82059154327042,
            21.006775305459467,
            105.82111760726433,
            21.007277072684122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104250541163,
              21.007277072684122
            ],
            [
              105.82111760726433,
              21.00719319081305
            ],
            [
              105.82059154327042,
              21.006775305459467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT96_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11246",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 69.22,
          "chatLieu": "HDPE",
          "chieuDai": 83.77,
          "from_latitude": 21.007277072684122,
          "to_latitude": 21.006775305459467,
          "to_longitude": 105.82059154327042,
          "from_longitude": 105.82104250541163
        }
      },
      {
        "id": 11223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82118354892333,
            21.004681972477517,
            105.82120467897056,
            21.004704350738823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82120467897056,
              21.004681972477517
            ],
            [
              105.82119970033196,
              21.004687245156802
            ],
            [
              105.82119806310016,
              21.004688979124932
            ],
            [
              105.82118354892333,
              21.004704350738823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT33_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11247",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004681972477517,
          "to_latitude": 21.004704350738823,
          "to_longitude": 105.82118354892333,
          "from_longitude": 105.82120467897056
        }
      },
      {
        "id": 11224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82087800239236,
            21.004444674423393,
            105.82089839645266,
            21.004467084323906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82089839645266,
              21.004444674423393
            ],
            [
              105.82089520205207,
              21.00444818441959
            ],
            [
              105.82089382810553,
              21.004449694017683
            ],
            [
              105.82087800239236,
              21.004467084323906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT35_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11248",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004444674423393,
          "to_latitude": 21.004467084323906,
          "to_longitude": 105.82087800239236,
          "from_longitude": 105.82089839645266
        }
      },
      {
        "id": 11225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82077703088353,
            21.004362180813644,
            105.82126827130284,
            21.00474636024592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82077703088353,
              21.004362180813644
            ],
            [
              105.82089382810553,
              21.004449694017683
            ],
            [
              105.8209096570204,
              21.00446155383244
            ],
            [
              105.82111286025217,
              21.004620498285973
            ],
            [
              105.821113104651,
              21.00462068949446
            ],
            [
              105.82119806310016,
              21.004688979124932
            ],
            [
              105.82120206089631,
              21.00469219248902
            ],
            [
              105.82124907986281,
              21.004727335125693
            ],
            [
              105.82126827130284,
              21.00474636024592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11249",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004362180813644,
          "to_latitude": 21.00474636024592,
          "to_longitude": 105.82126827130284,
          "from_longitude": 105.82077703088353
        }
      },
      {
        "id": 11226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111286025217,
            21.004614765404916,
            105.82111793448459,
            21.004620498285973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82111286025217,
              21.004620498285973
            ],
            [
              105.82111557946315,
              21.00461768696711
            ],
            [
              105.82111793448459,
              21.004614765404916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ Thịnh Quang",
          "maTaiSan": "04.O11.DODV.11250",
          "diaChiLapD": "Ngõ Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004620498285973,
          "to_latitude": 21.004614765404916,
          "to_longitude": 105.82111793448459,
          "from_longitude": 105.82111286025217
        }
      },
      {
        "id": 11227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82226711964039,
            21.00563057113824,
            105.82244320570041,
            21.005654537362464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82244320570041,
              21.00563057113824
            ],
            [
              105.82230706056943,
              21.005649102056275
            ],
            [
              105.82230492679301,
              21.005649391463106
            ],
            [
              105.82226711964039,
              21.005654537362464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A20.1_Tây Sơn",
          "maTaiSan": "04.O11.DODV.11251",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00563057113824,
          "to_latitude": 21.005654537362464,
          "to_longitude": 105.82226711964039,
          "from_longitude": 105.82244320570041
        }
      },
      {
        "id": 11228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375303212528,
            21.001324227931857,
            105.83753572244375,
            21.001324494764862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753572244375,
              21.001324227931857
            ],
            [
              105.83753267904586,
              21.001324378389885
            ],
            [
              105.83753079050402,
              21.00132447168188
            ],
            [
              105.8375304703043,
              21.001324488612156
            ],
            [
              105.8375303212528,
              21.001324494764862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,117_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DODV.11252",
          "diaChiLapD": "Ngõ 102, Trường Chinh",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20.64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001324227931857,
          "to_latitude": 21.001324494764862,
          "to_longitude": 105.8375303212528,
          "from_longitude": 105.83753572244375
        }
      },
      {
        "id": 11229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716406378704,
            21.001567215603153,
            105.83717736233396,
            21.00157309071386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717736233396,
              21.00157309071386
            ],
            [
              105.83717132245921,
              21.0015704242905
            ],
            [
              105.83716406378704,
              21.001567215603153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,113_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DODV.11253",
          "diaChiLapD": "Ngõ 91, Lương Định Của",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00157309071386,
          "to_latitude": 21.001567215603153,
          "to_longitude": 105.83716406378704,
          "from_longitude": 105.83717736233396
        }
      },
      {
        "id": 11230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509006778826,
            21.002961398220393,
            105.8351028240218,
            21.002981612217944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351028240218,
              21.00296226937513
            ],
            [
              105.8350983586696,
              21.0029616752832
            ],
            [
              105.83509628721546,
              21.002961398220393
            ],
            [
              105.83509006778826,
              21.002981612217944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT6,73_Phương Mai",
          "maTaiSan": "04.O17.DODV.11254",
          "diaChiLapD": "Phương Mai",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00296226937513,
          "to_latitude": 21.002981612217944,
          "to_longitude": 105.83509006778826,
          "from_longitude": 105.8351028240218
        }
      },
      {
        "id": 11231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397878498212,
            21.020464975645254,
            105.83406108115099,
            21.020596292541924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405415925108,
              21.020596292541924
            ],
            [
              105.83406108115099,
              21.02059379460525
            ],
            [
              105.83405940378593,
              21.020590909803165
            ],
            [
              105.83405269983002,
              21.0205933317035
            ],
            [
              105.8340468301491,
              21.020595452352058
            ],
            [
              105.83404038232753,
              21.02058114257483
            ],
            [
              105.83397878498212,
              21.020464975645254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.4_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11255",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020596292541924,
          "to_latitude": 21.020464975645254,
          "to_longitude": 105.83397878498212,
          "from_longitude": 105.83405415925108
        }
      },
      {
        "id": 11232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83261015861864,
            21.022194508609584,
            105.83274803973805,
            21.022338219401792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83261015861864,
              21.022194508609584
            ],
            [
              105.83263536437035,
              21.022235214567793
            ],
            [
              105.83265326435026,
              21.02225763890003
            ],
            [
              105.83274803973805,
              21.022338219401792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.6_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11256",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022194508609584,
          "to_latitude": 21.022338219401792,
          "to_longitude": 105.83274803973805,
          "from_longitude": 105.83261015861864
        }
      },
      {
        "id": 11233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390541690669,
            21.026070845137088,
            105.83392580280817,
            21.026097074887275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83392580280817,
              21.026097074887275
            ],
            [
              105.83392321589487,
              21.026092217391028
            ],
            [
              105.8339143410736,
              21.026075554886276
            ],
            [
              105.83391183194323,
              21.026070845137088
            ],
            [
              105.83390541690669,
              21.02607354110358
            ],
            [
              105.8339068587026,
              21.026076609510007
            ],
            [
              105.8339076238956,
              21.02607823788289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11257",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026097074887275,
          "to_latitude": 21.02607823788289,
          "to_longitude": 105.8339076238956,
          "from_longitude": 105.83392580280817
        }
      },
      {
        "id": 11234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436864143185,
            21.026373888098124,
            105.83444812239287,
            21.026636752860636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83437703412594,
              21.026373888098124
            ],
            [
              105.83439938752878,
              21.026426819318143
            ],
            [
              105.83441908459109,
              21.026479531439854
            ],
            [
              105.83442008556186,
              21.02648221090859
            ],
            [
              105.83444812239287,
              21.026557238692657
            ],
            [
              105.83439287418659,
              21.02658071630056
            ],
            [
              105.83440537751409,
              21.026622218586546
            ],
            [
              105.83436864143185,
              21.026636752860636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.8_Hồ Giám",
          "maTaiSan": "04.O14.DODV.11258",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026373888098124,
          "to_latitude": 21.026636752860636,
          "to_longitude": 105.83436864143185,
          "from_longitude": 105.83437703412594
        }
      },
      {
        "id": 11235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83277800151482,
            21.021557472718136,
            105.83296507822816,
            21.021629269293335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83296507822816,
              21.021557472718136
            ],
            [
              105.83289163287306,
              21.021586479943046
            ],
            [
              105.83281226561631,
              21.021568557502878
            ],
            [
              105.83277800151482,
              21.021629269293335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.2_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11259",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021557472718136,
          "to_latitude": 21.021629269293335,
          "to_longitude": 105.83277800151482,
          "from_longitude": 105.83296507822816
        }
      },
      {
        "id": 11236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263907363286,
            21.021144497504125,
            105.83285787162923,
            21.02128602783486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83264212416384,
              21.021144497504125
            ],
            [
              105.83264063399902,
              21.021156092263904
            ],
            [
              105.83263907363286,
              21.021168730591445
            ],
            [
              105.83269190522245,
              21.021196458367125
            ],
            [
              105.83285787162923,
              21.02128602783486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.2_Ngách 221/65, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11260",
          "diaChiLapD": "Ngách 221/65, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021144497504125,
          "to_latitude": 21.02128602783486,
          "to_longitude": 105.83285787162923,
          "from_longitude": 105.83264212416384
        }
      },
      {
        "id": 11237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83282918838947,
            21.021338666386264,
            105.83290383902633,
            21.02138084542617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83282918838947,
              21.021338666386264
            ],
            [
              105.83290383902633,
              21.02138084542617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.2_Ngách 221/65, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11261",
          "diaChiLapD": "Ngách 221/65, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021338666386264,
          "to_latitude": 21.02138084542617,
          "to_longitude": 105.83290383902633,
          "from_longitude": 105.83282918838947
        }
      },
      {
        "id": 11238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615580221847,
            21.025735872435412,
            105.83642740116208,
            21.025837282466863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83615580221847,
              21.025837282466863
            ],
            [
              105.83621421030739,
              21.025806609943892
            ],
            [
              105.83623352221852,
              21.025798455522853
            ],
            [
              105.83625777583015,
              21.02578821383767
            ],
            [
              105.83626947499461,
              21.025783432450595
            ],
            [
              105.8362946096192,
              21.02577315933128
            ],
            [
              105.83629964569776,
              21.025774711593638
            ],
            [
              105.83632752432857,
              21.025783303244292
            ],
            [
              105.83642740116208,
              21.025735872435412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.12_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11262",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025837282466863,
          "to_latitude": 21.025735872435412,
          "to_longitude": 105.83642740116208,
          "from_longitude": 105.83615580221847
        }
      },
      {
        "id": 11239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83833186597344,
            21.029390527483265,
            105.83838363421707,
            21.029437176745912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83838363421707,
              21.029390527483265
            ],
            [
              105.83834212907442,
              21.02942792863105
            ],
            [
              105.83833186597344,
              21.029437176745912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.7_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11263",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029390527483265,
          "to_latitude": 21.029437176745912,
          "to_longitude": 105.83833186597344,
          "from_longitude": 105.83838363421707
        }
      },
      {
        "id": 11240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83896302954474,
            21.01937778411982,
            105.83918133494993,
            21.019558903703853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917887570871,
              21.01937778411982
            ],
            [
              105.83918133494993,
              21.01938061894773
            ],
            [
              105.83911985615002,
              21.019429281717155
            ],
            [
              105.8390113098224,
              21.019516852652632
            ],
            [
              105.83896302954474,
              21.019558903703853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.1_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11264",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01937778411982,
          "to_latitude": 21.019558903703853,
          "to_longitude": 105.83896302954474,
          "from_longitude": 105.83917887570871
        }
      },
      {
        "id": 11241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807465825774,
            21.019502409257814,
            105.83821223228077,
            21.019622738059798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83821223228077,
              21.019622738059798
            ],
            [
              105.83813651497462,
              21.01954900888707
            ],
            [
              105.83810062547947,
              21.019535122528122
            ],
            [
              105.83807465825774,
              21.019502409257814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.5_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11265",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019622738059798,
          "to_latitude": 21.019502409257814,
          "to_longitude": 105.83807465825774,
          "from_longitude": 105.83821223228077
        }
      },
      {
        "id": 11242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83427947852351,
            21.02272667266652,
            105.83428772586012,
            21.022730428984307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83427947852351,
              21.022730428984307
            ],
            [
              105.8342802816655,
              21.022730062852006
            ],
            [
              105.8342837821013,
              21.02272846774961
            ],
            [
              105.83428442787948,
              21.022728173743843
            ],
            [
              105.83428772586012,
              21.02272667266652
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11266",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022730428984307,
          "to_latitude": 21.02272667266652,
          "to_longitude": 105.83428772586012,
          "from_longitude": 105.83427947852351
        }
      },
      {
        "id": 11243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927335169733,
            21.024287118685223,
            105.83956233629799,
            21.02434769347062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956233629799,
              21.024287118685223
            ],
            [
              105.83955091252469,
              21.024298777878972
            ],
            [
              105.83953603084427,
              21.024308112987942
            ],
            [
              105.83950720181268,
              21.02433112128546
            ],
            [
              105.83927335169733,
              21.02434769347062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11267",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024287118685223,
          "to_latitude": 21.02434769347062,
          "to_longitude": 105.83927335169733,
          "from_longitude": 105.83956233629799
        }
      },
      {
        "id": 11244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83693644093823,
            21.025754059432725,
            105.83709834936978,
            21.025920671012052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709455618751,
              21.025754059432725
            ],
            [
              105.8370946962551,
              21.025754335129545
            ],
            [
              105.83709834936978,
              21.025761469771563
            ],
            [
              105.83698549561151,
              21.025808086904433
            ],
            [
              105.83696705058742,
              21.025814532761313
            ],
            [
              105.83696511103072,
              21.02584448595966
            ],
            [
              105.83693644093823,
              21.02586110817052
            ],
            [
              105.83695134875916,
              21.025920671012052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.6_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11268",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025754059432725,
          "to_latitude": 21.025920671012052,
          "to_longitude": 105.83695134875916,
          "from_longitude": 105.83709455618751
        }
      },
      {
        "id": 11245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84136868113687,
            21.027024145160173,
            105.84149130468154,
            21.0270613435211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84149130468154,
              21.027026408009498
            ],
            [
              105.841451704458,
              21.02702450397038
            ],
            [
              105.84144426708029,
              21.027024145160173
            ],
            [
              105.84144454064827,
              21.027059513988537
            ],
            [
              105.84136868113687,
              21.0270613435211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.5_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11269",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027026408009498,
          "to_latitude": 21.0270613435211,
          "to_longitude": 105.84136868113687,
          "from_longitude": 105.84149130468154
        }
      },
      {
        "id": 11246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8369996567462,
            21.02581471469617,
            105.83721779368992,
            21.025886742133736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369996567462,
              21.025886742133736
            ],
            [
              105.83700974658288,
              21.025876123860236
            ],
            [
              105.83721671120124,
              21.02581471469617
            ],
            [
              105.83721779368992,
              21.025825609463098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.3_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11270",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025886742133736,
          "to_latitude": 21.025825609463098,
          "to_longitude": 105.83721779368992,
          "from_longitude": 105.8369996567462
        }
      },
      {
        "id": 11247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712423196737,
            21.025958599774945,
            105.83724266477272,
            21.026029995062018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713784500239,
              21.026029995062018
            ],
            [
              105.83712423196737,
              21.02599610449837
            ],
            [
              105.83723287043922,
              21.025958981930714
            ],
            [
              105.8372339899998,
              21.025958599774945
            ],
            [
              105.83724262635926,
              21.02595868280468
            ],
            [
              105.83724266477272,
              21.02595867087368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.1_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11271",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026029995062018,
          "to_latitude": 21.02595867087368,
          "to_longitude": 105.83724266477272,
          "from_longitude": 105.83713784500239
        }
      },
      {
        "id": 11248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83660917590761,
            21.02616347358054,
            105.83679167833462,
            21.02630608359768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83660917590761,
              21.02630608359768
            ],
            [
              105.83669335256909,
              21.026247358285634
            ],
            [
              105.83669430069698,
              21.026245389110528
            ],
            [
              105.83670770197868,
              21.02621756712894
            ],
            [
              105.83676792307087,
              21.026177171331334
            ],
            [
              105.8367905957156,
              21.026164097615485
            ],
            [
              105.83679167833462,
              21.02616347358054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.6_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11272",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02630608359768,
          "to_latitude": 21.02616347358054,
          "to_longitude": 105.83679167833462,
          "from_longitude": 105.83660917590761
        }
      },
      {
        "id": 11249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83653444896473,
            21.025749853973053,
            105.83663661727114,
            21.025882964428437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83656561401287,
              21.025882964428437
            ],
            [
              105.83656934192737,
              21.025880923773347
            ],
            [
              105.83657127637997,
              21.02587969309959
            ],
            [
              105.83655848305905,
              21.02586768716428
            ],
            [
              105.83653444896473,
              21.025829860080638
            ],
            [
              105.8365794123464,
              21.025806138838796
            ],
            [
              105.83656729051611,
              21.025786721364028
            ],
            [
              105.83663661727114,
              21.025749853973053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.1_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11273",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025882964428437,
          "to_latitude": 21.025749853973053,
          "to_longitude": 105.83663661727114,
          "from_longitude": 105.83656561401287
        }
      },
      {
        "id": 11250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83619087571353,
            21.02538693360058,
            105.83631085764772,
            21.02577019634088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631085764772,
              21.02577019634088
            ],
            [
              105.83630813751861,
              21.025765096570844
            ],
            [
              105.83626195145473,
              21.025678455292933
            ],
            [
              105.83623883836962,
              21.02561273751976
            ],
            [
              105.83630407230275,
              21.025557849407555
            ],
            [
              105.83629324695977,
              21.025539922176467
            ],
            [
              105.83624457295915,
              21.025470992687165
            ],
            [
              105.83619087571353,
              21.02538693360058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.13_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11274",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02577019634088,
          "to_latitude": 21.02538693360058,
          "to_longitude": 105.83619087571353,
          "from_longitude": 105.83631085764772
        }
      },
      {
        "id": 11251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635535328645,
            21.02552162646003,
            105.83647626235398,
            21.02571862124791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646570457132,
              21.02552162646003
            ],
            [
              105.83641606407546,
              21.025556567029238
            ],
            [
              105.83635535328645,
              21.025613484158864
            ],
            [
              105.83637582673468,
              21.025641274101407
            ],
            [
              105.8363904385822,
              21.025661107472782
            ],
            [
              105.83641392473278,
              21.025699879336084
            ],
            [
              105.83642521758293,
              21.025716590327054
            ],
            [
              105.83642659014552,
              21.02571862124791
            ],
            [
              105.8364571063927,
              21.02570097210901
            ],
            [
              105.83647172492263,
              21.025692517392102
            ],
            [
              105.83647462116318,
              21.025697800550752
            ],
            [
              105.83647626235398,
              21.025699872601397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.14_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11275",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02552162646003,
          "to_latitude": 21.025699872601397,
          "to_longitude": 105.83647626235398,
          "from_longitude": 105.83646570457132
        }
      },
      {
        "id": 11252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83642567496005,
            21.025543508092927,
            105.83654591988531,
            21.025819061628567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83654591988531,
              21.025543508092927
            ],
            [
              105.83650580838061,
              21.025576790464076
            ],
            [
              105.83649579848489,
              21.025595876936197
            ],
            [
              105.83647374014771,
              21.025611280615916
            ],
            [
              105.83642567496005,
              21.025644248929513
            ],
            [
              105.83645802175266,
              21.02569261279202
            ],
            [
              105.83645744049029,
              21.02569791937771
            ],
            [
              105.8364571063927,
              21.02570097210901
            ],
            [
              105.83645582307548,
              21.025712695032585
            ],
            [
              105.83645708897514,
              21.025714747251936
            ],
            [
              105.83647354568915,
              21.025741428812072
            ],
            [
              105.83652314964337,
              21.025819061628567
            ],
            [
              105.8365242298225,
              21.025818172962953
            ],
            [
              105.83652446156216,
              21.025817982146183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.16_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11276",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025543508092927,
          "to_latitude": 21.025817982146183,
          "to_longitude": 105.83652446156216,
          "from_longitude": 105.83654591988531
        }
      },
      {
        "id": 11253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372456215854,
            21.026367364292728,
            105.83389435544579,
            21.026507064955364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372456215854,
              21.02649232683598
            ],
            [
              105.83373133391851,
              21.026507064955364
            ],
            [
              105.83389435544579,
              21.026441328380926
            ],
            [
              105.83389282976738,
              21.026437189162777
            ],
            [
              105.83388521894324,
              21.026416538146755
            ],
            [
              105.83386709635822,
              21.026367364292728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.20_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11277",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02649232683598,
          "to_latitude": 21.026367364292728,
          "to_longitude": 105.83386709635822,
          "from_longitude": 105.83372456215854
        }
      },
      {
        "id": 11254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569099451334,
            21.026949094328607,
            105.83575162446797,
            21.027124478820955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573460164418,
              21.027124478820955
            ],
            [
              105.8357273694844,
              21.027109680729062
            ],
            [
              105.83569099451334,
              21.027035232479058
            ],
            [
              105.83569685832475,
              21.027022355312436
            ],
            [
              105.83574852128191,
              21.026998226518078
            ],
            [
              105.83575131275705,
              21.026983855163014
            ],
            [
              105.83575162446797,
              21.026972642824646
            ],
            [
              105.83573574990298,
              21.026949094328607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.9_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11278",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027124478820955,
          "to_latitude": 21.026949094328607,
          "to_longitude": 105.83573574990298,
          "from_longitude": 105.83573460164418
        }
      },
      {
        "id": 11255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615987155845,
            21.026121880746437,
            105.83627607769284,
            21.026259830356846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627607769284,
              21.026259830356846
            ],
            [
              105.83621712252011,
              21.0261905567212
            ],
            [
              105.836203839843,
              21.026174466080796
            ],
            [
              105.83618779680194,
              21.026155030828157
            ],
            [
              105.83615987155845,
              21.026121880746437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.6_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11279",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026259830356846,
          "to_latitude": 21.026121880746437,
          "to_longitude": 105.83615987155845,
          "from_longitude": 105.83627607769284
        }
      },
      {
        "id": 11256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360697359965,
            21.0259613487494,
            105.83615987155845,
            21.026121880746437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83615987155845,
              21.026121880746437
            ],
            [
              105.83614433139856,
              21.02609619000224
            ],
            [
              105.83611101789009,
              21.026041115369985
            ],
            [
              105.8360697359965,
              21.0259613487494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.6_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11280",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026121880746437,
          "to_latitude": 21.0259613487494,
          "to_longitude": 105.8360697359965,
          "from_longitude": 105.83615987155845
        }
      },
      {
        "id": 11257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83731959368677,
            21.0257888261811,
            105.83748743343374,
            21.02620399675862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748743343374,
              21.02620399675862
            ],
            [
              105.83747667412312,
              21.02618759121514
            ],
            [
              105.83740570801419,
              21.026077191088653
            ],
            [
              105.83736919400125,
              21.02607560147228
            ],
            [
              105.83731959368677,
              21.02598717358017
            ],
            [
              105.83732405310631,
              21.02589805138002
            ],
            [
              105.83732617257795,
              21.025880515707662
            ],
            [
              105.8373901462339,
              21.025867693780985
            ],
            [
              105.83737106307456,
              21.0257888261811
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.3_Ngõ 17, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11281",
          "diaChiLapD": "Ngõ 17, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02620399675862,
          "to_latitude": 21.0257888261811,
          "to_longitude": 105.83737106307456,
          "from_longitude": 105.83748743343374
        }
      },
      {
        "id": 11258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635523661032,
            21.02695600043222,
            105.83650357761735,
            21.02721928290381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635523661032,
              21.02695600043222
            ],
            [
              105.83635657746476,
              21.02695838105634
            ],
            [
              105.83650357761735,
              21.02721928290381
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.5_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11282",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02695600043222,
          "to_latitude": 21.02721928290381,
          "to_longitude": 105.83650357761735,
          "from_longitude": 105.83635523661032
        }
      },
      {
        "id": 11259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376883104471,
            21.026506136228175,
            105.83783329355482,
            21.026737160080174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783329355482,
              21.026737160080174
            ],
            [
              105.83783206942744,
              21.026733636316237
            ],
            [
              105.83782491809961,
              21.02671302297116
            ],
            [
              105.83773911081897,
              21.026556354465345
            ],
            [
              105.83771709454487,
              21.026555701504183
            ],
            [
              105.8376883104471,
              21.026506136228175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.5_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11283",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026737160080174,
          "to_latitude": 21.026506136228175,
          "to_longitude": 105.8376883104471,
          "from_longitude": 105.83783329355482
        }
      },
      {
        "id": 11260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774029590899,
            21.026652020256385,
            105.83801389912666,
            21.026800265776373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83774029590899,
              21.026800265776373
            ],
            [
              105.83782989730541,
              21.026747250457525
            ],
            [
              105.83784514910566,
              21.02673935701714
            ],
            [
              105.83801389912666,
              21.026652020256385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.4_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11284",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026800265776373,
          "to_latitude": 21.026652020256385,
          "to_longitude": 105.83801389912666,
          "from_longitude": 105.83774029590899
        }
      },
      {
        "id": 11261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83775091951814,
            21.02782904125624,
            105.83783321147368,
            21.027864449282806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775091951814,
              21.027864449282806
            ],
            [
              105.83783321147368,
              21.02782904125624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.5_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11285",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027864449282806,
          "to_latitude": 21.02782904125624,
          "to_longitude": 105.83783321147368,
          "from_longitude": 105.83775091951814
        }
      },
      {
        "id": 11262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377436209023,
            21.027557596836786,
            105.83821054253512,
            21.02769320800711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83821054253512,
              21.027557596836786
            ],
            [
              105.83808283412492,
              21.027602455299245
            ],
            [
              105.83804599112099,
              21.027612464840804
            ],
            [
              105.8380342271404,
              21.027613926424195
            ],
            [
              105.83803242468817,
              21.027614507046966
            ],
            [
              105.83800683505699,
              21.02762275132078
            ],
            [
              105.8379347012296,
              21.02764526554541
            ],
            [
              105.83783557600432,
              21.027675322768587
            ],
            [
              105.83781904980138,
              21.027674674388514
            ],
            [
              105.83777013272642,
              21.027689194440566
            ],
            [
              105.83776027878523,
              21.027690736917453
            ],
            [
              105.8377436209023,
              21.02769320800711
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.6_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11286",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027557596836786,
          "to_latitude": 21.02769320800711,
          "to_longitude": 105.8377436209023,
          "from_longitude": 105.83821054253512
        }
      },
      {
        "id": 11263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83986143725106,
            21.0283823524202,
            105.84014501377571,
            21.028389952072644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84014501377571,
              21.0283823524202
            ],
            [
              105.84014247416047,
              21.0283823721935
            ],
            [
              105.83986143725106,
              21.028389952072644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.3_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11287",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0283823524202,
          "to_latitude": 21.028389952072644,
          "to_longitude": 105.83986143725106,
          "from_longitude": 105.84014501377571
        }
      },
      {
        "id": 11264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84024300073234,
            21.029039263279817,
            105.8404875949804,
            21.029110981780068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84024300073234,
              21.029110981780068
            ],
            [
              105.84046524663177,
              21.029042790270122
            ],
            [
              105.84047674032972,
              21.029039263279817
            ],
            [
              105.84048081299522,
              21.02905186387286
            ],
            [
              105.8404875949804,
              21.029084413187178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11288",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029110981780068,
          "to_latitude": 21.029084413187178,
          "to_longitude": 105.8404875949804,
          "from_longitude": 105.84024300073234
        }
      },
      {
        "id": 11265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84105379266106,
            21.02816031727584,
            105.84146945966882,
            21.028251851477165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146945966882,
              21.028244929368338
            ],
            [
              105.84146218504397,
              21.02824512614012
            ],
            [
              105.84145897239421,
              21.02824521248904
            ],
            [
              105.84121319331082,
              21.028251851477165
            ],
            [
              105.84121074470048,
              21.028214774579883
            ],
            [
              105.84120438977287,
              21.028195087774865
            ],
            [
              105.84116767894253,
              21.028196920466577
            ],
            [
              105.84114670361613,
              21.02816031727584
            ],
            [
              105.84105379266106,
              21.028172071634653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.6_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11289",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028244929368338,
          "to_latitude": 21.028172071634653,
          "to_longitude": 105.84105379266106,
          "from_longitude": 105.84146945966882
        }
      },
      {
        "id": 11266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83894225103255,
            21.02584042157003,
            105.83901822662821,
            21.025858798505205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895078373567,
              21.025857292302828
            ],
            [
              105.83894298648472,
              21.025858798505205
            ],
            [
              105.83894225103255,
              21.02585424266477
            ],
            [
              105.83895007870755,
              21.025852672183518
            ],
            [
              105.83895424526642,
              21.02585183601877
            ],
            [
              105.83901822662821,
              21.02584042157003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.6_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11290",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025857292302828,
          "to_latitude": 21.02584042157003,
          "to_longitude": 105.83901822662821,
          "from_longitude": 105.83895078373567
        }
      },
      {
        "id": 11267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895424526642,
            21.02585183601877,
            105.83897007337369,
            21.025964482844543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897007337369,
              21.025964482844543
            ],
            [
              105.83896619589098,
              21.025935494071394
            ],
            [
              105.83896609920887,
              21.02591856183127
            ],
            [
              105.83895960834653,
              21.025884139517082
            ],
            [
              105.83895424526642,
              21.02585183601877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.6_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11291",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025964482844543,
          "to_latitude": 21.02585183601877,
          "to_longitude": 105.83895424526642,
          "from_longitude": 105.83897007337369
        }
      },
      {
        "id": 11268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661344186433,
            21.025506839473472,
            105.83667619032157,
            21.025618942933413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667619032157,
              21.025506839473472
            ],
            [
              105.83662066392195,
              21.02554014893396
            ],
            [
              105.83661485158147,
              21.025549468984526
            ],
            [
              105.83661344186433,
              21.025559570342065
            ],
            [
              105.83663380102276,
              21.02559548892034
            ],
            [
              105.83661819166183,
              21.025618942933413
            ],
            [
              105.83661405373938,
              21.02561681000559
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.4_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11292",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025506839473472,
          "to_latitude": 21.02561681000559,
          "to_longitude": 105.83661405373938,
          "from_longitude": 105.83667619032157
        }
      },
      {
        "id": 11269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670912130883,
            21.024477237794027,
            105.83684037172029,
            21.024764807040118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670912130883,
              21.024477237794027
            ],
            [
              105.83671585522048,
              21.024516359394486
            ],
            [
              105.83673227782238,
              21.024569351094648
            ],
            [
              105.83674714014798,
              21.02457616053382
            ],
            [
              105.83676229394014,
              21.0246153707695
            ],
            [
              105.83677021850217,
              21.024633407083716
            ],
            [
              105.83681379365439,
              21.024722288430144
            ],
            [
              105.83683503036191,
              21.024764807040118
            ],
            [
              105.83684037172029,
              21.024763148633095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.3_Linh Quang B",
          "maTaiSan": "04.O14.DODV.11293",
          "diaChiLapD": "Linh Quang B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024477237794027,
          "to_latitude": 21.024763148633095,
          "to_longitude": 105.83684037172029,
          "from_longitude": 105.83670912130883
        }
      },
      {
        "id": 11270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367328921015,
            21.024532213853384,
            105.83690344901764,
            21.02490623035015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367328921015,
              21.024532213853384
            ],
            [
              105.83674758174166,
              21.024560654533506
            ],
            [
              105.83675637179931,
              21.024568813456668
            ],
            [
              105.83680568345558,
              21.02468667901684
            ],
            [
              105.8368230276051,
              21.02473034671385
            ],
            [
              105.83684037172029,
              21.024763148633095
            ],
            [
              105.83688937376678,
              21.024853443893853
            ],
            [
              105.83689841764424,
              21.024887249486383
            ],
            [
              105.83690344901764,
              21.02490623035015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.3_Linh Quang B",
          "maTaiSan": "04.O14.DODV.11294",
          "diaChiLapD": "Linh Quang B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024532213853384,
          "to_latitude": 21.02490623035015,
          "to_longitude": 105.83690344901764,
          "from_longitude": 105.8367328921015
        }
      },
      {
        "id": 11271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83808004131348,
            21.023487909158085,
            105.83837635171543,
            21.02374447523945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837635171543,
              21.02374447523945
            ],
            [
              105.83836877522651,
              21.023738992990822
            ],
            [
              105.83836829748226,
              21.023738541927834
            ],
            [
              105.83835334167067,
              21.02372447298302
            ],
            [
              105.83823219148805,
              21.023666086043114
            ],
            [
              105.83821002713911,
              21.023654529250493
            ],
            [
              105.83818115006977,
              21.023635577430095
            ],
            [
              105.83815650100374,
              21.02361749715291
            ],
            [
              105.83808004131348,
              21.023487909158085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.7_Linh Quang",
          "maTaiSan": "04.O14.DODV.11295",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02374447523945,
          "to_latitude": 21.023487909158085,
          "to_longitude": 105.83808004131348,
          "from_longitude": 105.83837635171543
        }
      },
      {
        "id": 11272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375102436314,
            21.025502754704824,
            105.83778515477444,
            21.02584849834809
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778515477444,
              21.025502754704824
            ],
            [
              105.83777984207192,
              21.0255079942799
            ],
            [
              105.83773439633761,
              21.025542611980892
            ],
            [
              105.83765222726551,
              21.025583612085377
            ],
            [
              105.83755532694211,
              21.025620118007176
            ],
            [
              105.8375102436314,
              21.025639208364492
            ],
            [
              105.83751064937056,
              21.02567498574061
            ],
            [
              105.83751078747,
              21.025687135216806
            ],
            [
              105.83753645167657,
              21.025770296827687
            ],
            [
              105.83753170407883,
              21.025793521298805
            ],
            [
              105.83754657052214,
              21.025836511046734
            ],
            [
              105.83753653936157,
              21.02584849834809
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.6_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11296",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025502754704824,
          "to_latitude": 21.02584849834809,
          "to_longitude": 105.83753653936157,
          "from_longitude": 105.83778515477444
        }
      },
      {
        "id": 11273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83652325203809,
            21.021688852613796,
            105.8368629802681,
            21.021800664159695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83652325203809,
              21.021688852613796
            ],
            [
              105.83652346206588,
              21.021689081902878
            ],
            [
              105.83652708549548,
              21.02169298858887
            ],
            [
              105.8365293593116,
              21.02170086796736
            ],
            [
              105.83653076107544,
              21.021705725835613
            ],
            [
              105.8365871725839,
              21.0217440602891
            ],
            [
              105.83660096516192,
              21.021753847521914
            ],
            [
              105.83673216550724,
              21.021751151828866
            ],
            [
              105.83673118160756,
              21.021800664159695
            ],
            [
              105.8368629802681,
              21.02179864554859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11297",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021688852613796,
          "to_latitude": 21.02179864554859,
          "to_longitude": 105.8368629802681,
          "from_longitude": 105.83652325203809
        }
      },
      {
        "id": 11274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83628056361422,
            21.02150440013558,
            105.83652574737029,
            21.021687639957165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83652574737029,
              21.021687639957165
            ],
            [
              105.83652551136488,
              21.021687409892625
            ],
            [
              105.83644520748537,
              21.02160857856004
            ],
            [
              105.83643111450904,
              21.02158011502919
            ],
            [
              105.83642827218998,
              21.02153153918701
            ],
            [
              105.83642803609696,
              21.021527499325394
            ],
            [
              105.83640469812276,
              21.021508614780057
            ],
            [
              105.83628056361422,
              21.02150440013558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.11298",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021687639957165,
          "to_latitude": 21.02150440013558,
          "to_longitude": 105.83628056361422,
          "from_longitude": 105.83652574737029
        }
      },
      {
        "id": 11275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357532183798,
            21.02180523854206,
            105.83601708443844,
            21.02213498035716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83601708443844,
              21.02213498035716
            ],
            [
              105.83601556334699,
              21.022133390934275
            ],
            [
              105.83596339488585,
              21.022078796380153
            ],
            [
              105.83586927588813,
              21.022063773172626
            ],
            [
              105.83584807360815,
              21.02200021454798
            ],
            [
              105.83576537151393,
              21.02199832585451
            ],
            [
              105.8357532183798,
              21.0218679338718
            ],
            [
              105.8357559472591,
              21.02180523854206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11299",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02213498035716,
          "to_latitude": 21.02180523854206,
          "to_longitude": 105.8357559472591,
          "from_longitude": 105.83601708443844
        }
      },
      {
        "id": 11276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721428911451,
            21.01914421129879,
            105.83741795131945,
            21.019215783934435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722063759731,
              21.019215783934435
            ],
            [
              105.83721552793233,
              21.01918619327917
            ],
            [
              105.83721428911451,
              21.01916330897651
            ],
            [
              105.83726057261075,
              21.019157072027426
            ],
            [
              105.8373909262339,
              21.019145277369365
            ],
            [
              105.83741795131945,
              21.01914421129879
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.11300",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019215783934435,
          "to_latitude": 21.01914421129879,
          "to_longitude": 105.83741795131945,
          "from_longitude": 105.83722063759731
        }
      },
      {
        "id": 11277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372148856739,
            21.019215783934435,
            105.83730643795114,
            21.01945377836418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372148856739,
              21.019216751596765
            ],
            [
              105.83722063759731,
              21.019215783934435
            ],
            [
              105.8372255344937,
              21.019248788919146
            ],
            [
              105.83724224374622,
              21.019296693149656
            ],
            [
              105.83725475743519,
              21.01933245250809
            ],
            [
              105.8372627210287,
              21.019358212993826
            ],
            [
              105.83727704636131,
              21.01939815439114
            ],
            [
              105.83730643795114,
              21.01945377836418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.11301",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019216751596765,
          "to_latitude": 21.01945377836418,
          "to_longitude": 105.83730643795114,
          "from_longitude": 105.8372148856739
        }
      },
      {
        "id": 11278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83742482121933,
            21.019094524560433,
            105.83751023099153,
            21.019391651966153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742482121933,
              21.019094524560433
            ],
            [
              105.83742546053364,
              21.019100484496185
            ],
            [
              105.83743005288243,
              21.01914319972541
            ],
            [
              105.83748792783956,
              21.019316086266002
            ],
            [
              105.83744719093063,
              21.019327312475934
            ],
            [
              105.8374721100262,
              21.019391651966153
            ],
            [
              105.83751023099153,
              21.019380700564405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11302",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019094524560433,
          "to_latitude": 21.019380700564405,
          "to_longitude": 105.83751023099153,
          "from_longitude": 105.83742482121933
        }
      },
      {
        "id": 11279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743005288243,
            21.01913827310972,
            105.83752480619155,
            21.01914319972541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743005288243,
              21.01914319972541
            ],
            [
              105.83744313460643,
              21.019142519324994
            ],
            [
              105.83748180181267,
              21.019140508963144
            ],
            [
              105.83752480619155,
              21.01913827310972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11303",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01914319972541,
          "to_latitude": 21.01913827310972,
          "to_longitude": 105.83752480619155,
          "from_longitude": 105.83743005288243
        }
      },
      {
        "id": 11280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83748180181267,
            21.019140508963144,
            105.83754114281223,
            21.019320622184715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748180181267,
              21.019140508963144
            ],
            [
              105.83754114281223,
              21.019320622184715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11304",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019140508963144,
          "to_latitude": 21.019320622184715,
          "to_longitude": 105.83754114281223,
          "from_longitude": 105.83748180181267
        }
      },
      {
        "id": 11281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732147287445,
            21.019420470262702,
            105.83740898935568,
            21.019527723493447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83740898935568,
              21.019420470262702
            ],
            [
              105.83732147287445,
              21.019468329354996
            ],
            [
              105.83735006442963,
              21.019502986209343
            ],
            [
              105.83737047299917,
              21.019527723493447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11305",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019420470262702,
          "to_latitude": 21.019527723493447,
          "to_longitude": 105.83737047299917,
          "from_longitude": 105.83740898935568
        }
      },
      {
        "id": 11282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737047299917,
            21.019488535017285,
            105.83756720412093,
            21.01957794110751
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737047299917,
              21.019527723493447
            ],
            [
              105.83741190130559,
              21.01957794110751
            ],
            [
              105.83756720412093,
              21.019488535017285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11306",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019527723493447,
          "to_latitude": 21.019488535017285,
          "to_longitude": 105.83756720412093,
          "from_longitude": 105.83737047299917
        }
      },
      {
        "id": 11283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375449919642,
            21.019737847315906,
            105.83778161544768,
            21.019831965856834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375449919642,
              21.019760072127315
            ],
            [
              105.83755249893706,
              21.01975366653445
            ],
            [
              105.83756168423302,
              21.019745829196935
            ],
            [
              105.837633073719,
              21.019831309202804
            ],
            [
              105.83763362185789,
              21.019831965856834
            ],
            [
              105.83763368704254,
              21.019831924890774
            ],
            [
              105.83778161544768,
              21.019737847315906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11307",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019760072127315,
          "to_latitude": 21.019737847315906,
          "to_longitude": 105.83778161544768,
          "from_longitude": 105.8375449919642
        }
      },
      {
        "id": 11284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83744803675513,
            21.019601427670096,
            105.83756168423302,
            21.019745829196935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83744803675513,
              21.019601427670096
            ],
            [
              105.83754248590405,
              21.01972284184599
            ],
            [
              105.83756168423302,
              21.019745829196935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11308",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019601427670096,
          "to_latitude": 21.019745829196935,
          "to_longitude": 105.83756168423302,
          "from_longitude": 105.83744803675513
        }
      },
      {
        "id": 11285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766021683437,
            21.01925186324651,
            105.83771764699803,
            21.019425477988275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766612721963,
              21.019425477988275
            ],
            [
              105.83771764699803,
              21.019413262883496
            ],
            [
              105.83766021683437,
              21.01925186324651
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.5_Ngõ 98, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11309",
          "diaChiLapD": "Ngõ 98, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019425477988275,
          "to_latitude": 21.01925186324651,
          "to_longitude": 105.83766021683437,
          "from_longitude": 105.83766612721963
        }
      },
      {
        "id": 11286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378042526101,
            21.019116121059092,
            105.83791504453959,
            21.019284473181795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378042526101,
              21.019116121059092
            ],
            [
              105.83780432975318,
              21.01911632661414
            ],
            [
              105.83786764834556,
              21.019284473181795
            ],
            [
              105.83791504453959,
              21.01927198568547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.6_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11310",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019116121059092,
          "to_latitude": 21.01927198568547,
          "to_longitude": 105.83791504453959,
          "from_longitude": 105.8378042526101
        }
      },
      {
        "id": 11287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793076315452,
            21.019102439115393,
            105.83801851506017,
            21.019374111979236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793076315452,
              21.019102439115393
            ],
            [
              105.83793085321668,
              21.019102718671064
            ],
            [
              105.83801851506017,
              21.019374111979236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.6_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11311",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019102439115393,
          "to_latitude": 21.019374111979236,
          "to_longitude": 105.83801851506017,
          "from_longitude": 105.83793076315452
        }
      },
      {
        "id": 11288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809242262556,
            21.019091164909845,
            105.83823933675214,
            21.019285363775655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812744490217,
              21.019285363775655
            ],
            [
              105.83811135154009,
              21.01921087202532
            ],
            [
              105.83809242262556,
              21.019100283362665
            ],
            [
              105.83822492392427,
              21.019092059671944
            ],
            [
              105.83823933675214,
              21.019091164909845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.7_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11312",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019285363775655,
          "to_latitude": 21.019091164909845,
          "to_longitude": 105.83823933675214,
          "from_longitude": 105.83812744490217
        }
      },
      {
        "id": 11289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824957310631,
            21.01903561699897,
            105.83843474248002,
            21.01908895576297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824957310631,
              21.01908895576297
            ],
            [
              105.83843474248002,
              21.01907762269269
            ],
            [
              105.8384341318556,
              21.019038603734764
            ],
            [
              105.83843408629876,
              21.01903561699897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.8_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11313",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01908895576297,
          "to_latitude": 21.01903561699897,
          "to_longitude": 105.83843408629876,
          "from_longitude": 105.83824957310631
        }
      },
      {
        "id": 11290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843474248002,
            21.019065611832186,
            105.83863075602868,
            21.01907762269269
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83843474248002,
              21.01907762269269
            ],
            [
              105.83863075602868,
              21.019065611832186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.8_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11314",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01907762269269,
          "to_latitude": 21.019065611832186,
          "to_longitude": 105.83863075602868,
          "from_longitude": 105.83843474248002
        }
      },
      {
        "id": 11291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823933675214,
            21.019046796280048,
            105.83830336712649,
            21.019313766756085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824548288942,
              21.019046796280048
            ],
            [
              105.83824509173851,
              21.019049615361943
            ],
            [
              105.83823933675214,
              21.019091164909845
            ],
            [
              105.83824880366929,
              21.019212030090795
            ],
            [
              105.83829709390511,
              21.019211323344948
            ],
            [
              105.83830336712649,
              21.019313766756085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.7_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11315",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019046796280048,
          "to_latitude": 21.019313766756085,
          "to_longitude": 105.83830336712649,
          "from_longitude": 105.83824548288942
        }
      },
      {
        "id": 11292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838333485709,
            21.019283314356528,
            105.8384337116993,
            21.01931679767091
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384337116993,
              21.019283314356528
            ],
            [
              105.83842869132378,
              21.019283846713158
            ],
            [
              105.8384189784049,
              21.019284876390866
            ],
            [
              105.83836320338104,
              21.019290788306197
            ],
            [
              105.8383569667849,
              21.01931329214844
            ],
            [
              105.838333485709,
              21.01931679767091
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.1_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11316",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019283314356528,
          "to_latitude": 21.01931679767091,
          "to_longitude": 105.838333485709,
          "from_longitude": 105.8384337116993
        }
      },
      {
        "id": 11293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815258914835,
            21.019502158059307,
            105.83824527425062,
            21.019610955265154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824527425062,
              21.019610955265154
            ],
            [
              105.83823857060653,
              21.01960394676907
            ],
            [
              105.83823743452717,
              21.019602913660403
            ],
            [
              105.83817932489103,
              21.01955017084711
            ],
            [
              105.83815258914835,
              21.019502158059307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.5_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11317",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019610955265154,
          "to_latitude": 21.019502158059307,
          "to_longitude": 105.83815258914835,
          "from_longitude": 105.83824527425062
        }
      },
      {
        "id": 11294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83834134266706,
            21.019552951662053,
            105.83842477354467,
            21.019807833424554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83835513690521,
              21.019552951662053
            ],
            [
              105.83835660819486,
              21.019554925179957
            ],
            [
              105.83842477354467,
              21.01964625736552
            ],
            [
              105.8383625276929,
              21.01967513041058
            ],
            [
              105.8383846239703,
              21.019717117140512
            ],
            [
              105.83834134266706,
              21.019738501112396
            ],
            [
              105.83837671780574,
              21.019807833424554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.4_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11318",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019552951662053,
          "to_latitude": 21.019807833424554,
          "to_longitude": 105.83837671780574,
          "from_longitude": 105.83835513690521
        }
      },
      {
        "id": 11295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849494822205,
            21.01956824478432,
            105.83852744038755,
            21.019624576539904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850463558505,
              21.019624576539904
            ],
            [
              105.83849494822205,
              21.01960695453137
            ],
            [
              105.83852744038755,
              21.01956824478432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11319",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019624576539904,
          "to_latitude": 21.01956824478432,
          "to_longitude": 105.83852744038755,
          "from_longitude": 105.83850463558505
        }
      },
      {
        "id": 11296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83852149846253,
            21.01961798388332,
            105.83856373679517,
            21.01971837217878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83852149846253,
              21.01961798388332
            ],
            [
              105.83856373679517,
              21.01971837217878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11320",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01961798388332,
          "to_latitude": 21.01971837217878,
          "to_longitude": 105.83856373679517,
          "from_longitude": 105.83852149846253
        }
      },
      {
        "id": 11297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856488415772,
            21.019616827945562,
            105.83859646684772,
            21.019696226364577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856488415772,
              21.019616827945562
            ],
            [
              105.83856528321536,
              21.019617832169253
            ],
            [
              105.83859646684772,
              21.019696226364577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11321",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019616827945562,
          "to_latitude": 21.019696226364577,
          "to_longitude": 105.83859646684772,
          "from_longitude": 105.83856488415772
        }
      },
      {
        "id": 11298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794819313826,
            21.019721398331512,
            105.83801424914499,
            21.019799720204976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794819313826,
              21.019721398331512
            ],
            [
              105.83801424914499,
              21.019799720204976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.8_Ngõ 98, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11322",
          "diaChiLapD": "Ngõ 98, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019721398331512,
          "to_latitude": 21.019799720204976,
          "to_longitude": 105.83801424914499,
          "from_longitude": 105.83794819313826
        }
      },
      {
        "id": 11299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793418516561,
            21.019779598348762,
            105.83803255058919,
            21.019884799061547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793418516561,
              21.019884799061547
            ],
            [
              105.83799214267846,
              21.019816489974218
            ],
            [
              105.8380126744711,
              21.019801452216484
            ],
            [
              105.83801424914499,
              21.019799720204976
            ],
            [
              105.83803255058919,
              21.019779598348762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.8_Ngõ 98, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11323",
          "diaChiLapD": "Ngõ 98, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019884799061547,
          "to_latitude": 21.019779598348762,
          "to_longitude": 105.83803255058919,
          "from_longitude": 105.83793418516561
        }
      },
      {
        "id": 11300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83809842273158,
            21.019602913660403,
            105.83823743452717,
            21.01971949576185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823743452717,
              21.019602913660403
            ],
            [
              105.83823286877839,
              21.019606477699103
            ],
            [
              105.83821223228077,
              21.019622738059798
            ],
            [
              105.83820831284969,
              21.019624731820162
            ],
            [
              105.83820002901551,
              21.019630342819557
            ],
            [
              105.83818686609874,
              21.019639737980146
            ],
            [
              105.83812867173269,
              21.019692662787815
            ],
            [
              105.83809842273158,
              21.01971949576185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.5_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11324",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019602913660403,
          "to_latitude": 21.01971949576185,
          "to_longitude": 105.83809842273158,
          "from_longitude": 105.83823743452717
        }
      },
      {
        "id": 11301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810876759898,
            21.01970720615807,
            105.83816465732257,
            21.01987938490734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814010512947,
              21.01970720615807
            ],
            [
              105.83816465732257,
              21.01973595491477
            ],
            [
              105.83812942852101,
              21.019774025952138
            ],
            [
              105.83814830356052,
              21.019817809742342
            ],
            [
              105.83810876759898,
              21.01987938490734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.6_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11325",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01970720615807,
          "to_latitude": 21.01987938490734,
          "to_longitude": 105.83810876759898,
          "from_longitude": 105.83814010512947
        }
      },
      {
        "id": 11302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380133573615,
            21.019837247727555,
            105.83814025680643,
            21.020114548382402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83802022005189,
              21.019837247727555
            ],
            [
              105.8380133573615,
              21.019847395834528
            ],
            [
              105.83806867384037,
              21.020007261225558
            ],
            [
              105.83814025680643,
              21.020114548382402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.7_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11326",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019837247727555,
          "to_latitude": 21.020114548382402,
          "to_longitude": 105.83814025680643,
          "from_longitude": 105.83802022005189
        }
      },
      {
        "id": 11303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814010512947,
            21.01957105436831,
            105.83835599228375,
            21.01970720615807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814010512947,
              21.01970720615807
            ],
            [
              105.83815373605668,
              21.019694111800888
            ],
            [
              105.83820048076633,
              21.019649208030106
            ],
            [
              105.83820972804429,
              21.01964334570312
            ],
            [
              105.83826983292828,
              21.01960524057774
            ],
            [
              105.8383057651701,
              21.019590114246256
            ],
            [
              105.83835599228375,
              21.01957105436831
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.6_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11327",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01970720615807,
          "to_latitude": 21.01957105436831,
          "to_longitude": 105.83835599228375,
          "from_longitude": 105.83814010512947
        }
      },
      {
        "id": 11304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893250530517,
            21.01905003454387,
            105.83894441614169,
            21.0193419942691
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893250530517,
              21.01905003454387
            ],
            [
              105.83894441614169,
              21.0193419942691
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11328",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01905003454387,
          "to_latitude": 21.0193419942691,
          "to_longitude": 105.83894441614169,
          "from_longitude": 105.83893250530517
        }
      },
      {
        "id": 11305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909548716933,
            21.019172240510965,
            105.83925383334775,
            21.019449599925704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925383334775,
              21.019449599925704
            ],
            [
              105.83915404618023,
              21.019326141973398
            ],
            [
              105.83909548716933,
              21.019241432101218
            ],
            [
              105.83911389471321,
              21.01921521014226
            ],
            [
              105.8391212432208,
              21.01920474166192
            ],
            [
              105.83916967393849,
              21.019172240510965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.2_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11329",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019449599925704,
          "to_latitude": 21.019172240510965,
          "to_longitude": 105.83916967393849,
          "from_longitude": 105.83925383334775
        }
      },
      {
        "id": 11306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876849397943,
            21.01947701759805,
            105.83895104852094,
            21.01961752961217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895104852094,
              21.01956564574506
            ],
            [
              105.83892402250314,
              21.01958701833472
            ],
            [
              105.83888163697745,
              21.01961752961217
            ],
            [
              105.83876849397943,
              21.01947701759805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.3_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11330",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01956564574506,
          "to_latitude": 21.01947701759805,
          "to_longitude": 105.83876849397943,
          "from_longitude": 105.83895104852094
        }
      },
      {
        "id": 11307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83887787940657,
            21.01963246397478,
            105.8389525912891,
            21.01974976655126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83887787940657,
              21.01963246397478
            ],
            [
              105.8389525912891,
              21.01974976655126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.2_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11331",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01963246397478,
          "to_latitude": 21.01974976655126,
          "to_longitude": 105.8389525912891,
          "from_longitude": 105.83887787940657
        }
      },
      {
        "id": 11308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83934794301038,
            21.019017417516647,
            105.83945481878261,
            21.019378295774228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83938965091757,
              21.019017417516647
            ],
            [
              105.83941073746291,
              21.01916340623459
            ],
            [
              105.83945475448111,
              21.019262515591283
            ],
            [
              105.83945481878261,
              21.019262660692927
            ],
            [
              105.83943797317396,
              21.019280572550034
            ],
            [
              105.83935136043071,
              21.019339892821357
            ],
            [
              105.83936965227583,
              21.019362784177776
            ],
            [
              105.83934794301038,
              21.019378295774228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.9_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11332",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019017417516647,
          "to_latitude": 21.019378295774228,
          "to_longitude": 105.83934794301038,
          "from_longitude": 105.83938965091757
        }
      },
      {
        "id": 11309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958017157178,
            21.018942447661004,
            105.83978209377571,
            21.018999921201747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397819232996,
              21.018942447661004
            ],
            [
              105.83978209377571,
              21.018947466934748
            ],
            [
              105.83978142867622,
              21.01898673694971
            ],
            [
              105.83958017157178,
              21.018999921201747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11333",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018942447661004,
          "to_latitude": 21.018999921201747,
          "to_longitude": 105.83958017157178,
          "from_longitude": 105.8397819232996
        }
      },
      {
        "id": 11310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83978142867622,
            21.01898673694971,
            105.83978652837077,
            21.019135223366327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83978142867622,
              21.01898673694971
            ],
            [
              105.83978144365447,
              21.01898717855198
            ],
            [
              105.83978652837077,
              21.019135223366327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11334",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01898673694971,
          "to_latitude": 21.019135223366327,
          "to_longitude": 105.83978652837077,
          "from_longitude": 105.83978142867622
        }
      },
      {
        "id": 11311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83950575321307,
            21.019145200038416,
            105.83979336508172,
            21.01936106480147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979336508172,
              21.019145200038416
            ],
            [
              105.83979234193352,
              21.01914561063686
            ],
            [
              105.8397869593521,
              21.0191477679138
            ],
            [
              105.83972500613393,
              21.019159754240928
            ],
            [
              105.83969939500064,
              21.01917694255707
            ],
            [
              105.83964334192981,
              21.01922973355362
            ],
            [
              105.83950575321307,
              21.01936106480147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_5.2_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11335",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019145200038416,
          "to_latitude": 21.01936106480147,
          "to_longitude": 105.83950575321307,
          "from_longitude": 105.83979336508172
        }
      },
      {
        "id": 11312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8397863173608,
            21.019369646986018,
            105.83980019658023,
            21.01953684379459
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980019658023,
              21.01953645619327
            ],
            [
              105.83979692647955,
              21.01953655724075
            ],
            [
              105.83978754896707,
              21.01953684379459
            ],
            [
              105.8397863173608,
              21.019369646986018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.3_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11336",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01953645619327,
          "to_latitude": 21.019369646986018,
          "to_longitude": 105.8397863173608,
          "from_longitude": 105.83980019658023
        }
      },
      {
        "id": 11313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83978497080311,
            21.01919257128504,
            105.8397863173608,
            21.019369646986018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397863173608,
              21.019369646986018
            ],
            [
              105.83978497080311,
              21.01919257128504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.3_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11337",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019369646986018,
          "to_latitude": 21.01919257128504,
          "to_longitude": 105.83978497080311,
          "from_longitude": 105.8397863173608
        }
      },
      {
        "id": 11314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954448116553,
            21.019533844849846,
            105.83977706337639,
            21.019578204477686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83977706337639,
              21.019578204477686
            ],
            [
              105.83977435256787,
              21.019544019117546
            ],
            [
              105.83960795437098,
              21.019559285771233
            ],
            [
              105.83956522812416,
              21.019552106484518
            ],
            [
              105.8395612559193,
              21.019533844849846
            ],
            [
              105.83954448116553,
              21.01953657950627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.4_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11338",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019578204477686,
          "to_latitude": 21.01953657950627,
          "to_longitude": 105.83954448116553,
          "from_longitude": 105.83977706337639
        }
      },
      {
        "id": 11315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395391159564,
            21.01964541979276,
            105.83980630535066,
            21.019747308141657
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980630535066,
              21.01964541979276
            ],
            [
              105.83979854220976,
              21.019651583779936
            ],
            [
              105.83973546520039,
              21.019674905697084
            ],
            [
              105.83961856438148,
              21.01972002972896
            ],
            [
              105.83954789457144,
              21.019747308141657
            ],
            [
              105.8395391159564,
              21.01970267714103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.5_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11339",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01964541979276,
          "to_latitude": 21.01970267714103,
          "to_longitude": 105.8395391159564,
          "from_longitude": 105.83980630535066
        }
      },
      {
        "id": 11316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83951815074408,
            21.01972002972896,
            105.83966815944967,
            21.019875447174886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83961856438148,
              21.01972002972896
            ],
            [
              105.83966815944967,
              21.01982365559842
            ],
            [
              105.83954302486505,
              21.019875447174886
            ],
            [
              105.83951815074408,
              21.01981742403802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.5_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11340",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01972002972896,
          "to_latitude": 21.01981742403802,
          "to_longitude": 105.83951815074408,
          "from_longitude": 105.83961856438148
        }
      },
      {
        "id": 11317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855524456811,
            21.01987673010157,
            105.83864520980747,
            21.019955598718123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83864520980747,
              21.01987673010157
            ],
            [
              105.83859141691427,
              21.019902720917678
            ],
            [
              105.83855524456811,
              21.019955598718123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.5_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11341",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01987673010157,
          "to_latitude": 21.019955598718123,
          "to_longitude": 105.83855524456811,
          "from_longitude": 105.83864520980747
        }
      },
      {
        "id": 11318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386503784499,
            21.0198457476454,
            105.83877382317527,
            21.019888773490226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386503784499,
              21.019888773490226
            ],
            [
              105.83869435654097,
              21.019872092701828
            ],
            [
              105.83869620399732,
              21.019874759843834
            ],
            [
              105.83877382317527,
              21.0198457476454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.5_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11342",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019888773490226,
          "to_latitude": 21.0198457476454,
          "to_longitude": 105.83877382317527,
          "from_longitude": 105.8386503784499
        }
      },
      {
        "id": 11319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936418927979,
            21.019621814138358,
            105.83943482241993,
            21.0196508300954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936418927979,
              21.0196508300954
            ],
            [
              105.83943482241993,
              21.019621814138358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.3_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11343",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0196508300954,
          "to_latitude": 21.019621814138358,
          "to_longitude": 105.83943482241993,
          "from_longitude": 105.83936418927979
        }
      },
      {
        "id": 11320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921216632578,
            21.019578403732115,
            105.83929847333404,
            21.019698694613854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929847333404,
              21.019698694613854
            ],
            [
              105.83922155083485,
              21.01958796140694
            ],
            [
              105.83921590161422,
              21.019582700001862
            ],
            [
              105.83921473058548,
              21.0195816110725
            ],
            [
              105.83921216632578,
              21.019578403732115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.5_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11344",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019698694613854,
          "to_latitude": 21.019578403732115,
          "to_longitude": 105.83921216632578,
          "from_longitude": 105.83929847333404
        }
      },
      {
        "id": 11321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969519894596,
            21.01980081925888,
            105.83980284869705,
            21.019810715318773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980284869705,
              21.01980081925888
            ],
            [
              105.83969519894596,
              21.019810715318773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.6_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11345",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01980081925888,
          "to_latitude": 21.019810715318773,
          "to_longitude": 105.83969519894596,
          "from_longitude": 105.83980284869705
        }
      },
      {
        "id": 11322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009393022015,
            21.018924081818703,
            105.84009582802551,
            21.018978835600677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009582802551,
              21.018978835600677
            ],
            [
              105.84009482220883,
              21.018950355563913
            ],
            [
              105.84009401061462,
              21.018927614831203
            ],
            [
              105.84009393022015,
              21.018924081818703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.10_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11346",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": 6.065,
          "from_latitude": 21.018978835600677,
          "to_latitude": 21.018924081818703,
          "to_longitude": 105.84009393022015,
          "from_longitude": 105.84009582802551
        }
      },
      {
        "id": 11323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84009650180693,
            21.0189238650003,
            105.84015919519462,
            21.018978287907228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009756907855,
              21.0189238650003
            ],
            [
              105.84009650180693,
              21.01892739388354
            ],
            [
              105.84009959985374,
              21.018978287907228
            ],
            [
              105.84015919519462,
              21.018974141088698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.10.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11347.1",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 12.262,
          "from_latitude": 21.0189238650003,
          "to_latitude": 21.018974141088698,
          "to_longitude": 105.84015919519462,
          "from_longitude": 105.84009756907855
        }
      },
      {
        "id": 11324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046461512733,
            21.018937875876958,
            105.8405406867454,
            21.01916501907819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84052634647097,
              21.018937875876958
            ],
            [
              105.8405406867454,
              21.019159863345873
            ],
            [
              105.84046461512733,
              21.01916501907819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.11_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11348",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018937875876958,
          "to_latitude": 21.01916501907819,
          "to_longitude": 105.84046461512733,
          "from_longitude": 105.84052634647097
        }
      },
      {
        "id": 11325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84063687513623,
            21.018933556034053,
            105.8407000778313,
            21.019148264091918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84063687513623,
              21.018933556034053
            ],
            [
              105.84063687528307,
              21.01893358222679
            ],
            [
              105.84063829622247,
              21.018997959663608
            ],
            [
              105.84067062806753,
              21.01903699705829
            ],
            [
              105.84069745062921,
              21.01904717569394
            ],
            [
              105.8407000778313,
              21.019148264091918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.11_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11349",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018933556034053,
          "to_latitude": 21.019148264091918,
          "to_longitude": 105.8407000778313,
          "from_longitude": 105.84063687513623
        }
      },
      {
        "id": 11326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060190451403,
            21.01929889367519,
            105.8406037682319,
            21.01947404146784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060190451403,
              21.01947404146784
            ],
            [
              105.8406037682319,
              21.01929889367519
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.12_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11350",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01947404146784,
          "to_latitude": 21.01929889367519,
          "to_longitude": 105.8406037682319,
          "from_longitude": 105.84060190451403
        }
      },
      {
        "id": 11327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8410160254797,
            21.018918962732464,
            105.84102425158476,
            21.019162440950193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8410160254797,
              21.018918962732464
            ],
            [
              105.8410160277024,
              21.018919016011644
            ],
            [
              105.84102425158476,
              21.019162440950193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.13_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11351",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018918962732464,
          "to_latitude": 21.019162440950193,
          "to_longitude": 105.84102425158476,
          "from_longitude": 105.8410160254797
        }
      },
      {
        "id": 11328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83711810951976,
            21.029529790846816,
            105.83717115311384,
            21.02954573826492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83711810951976,
              21.02954573826492
            ],
            [
              105.83717115311384,
              21.029529790846816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.3_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11352",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02954573826492,
          "to_latitude": 21.029529790846816,
          "to_longitude": 105.83717115311384,
          "from_longitude": 105.83711810951976
        }
      },
      {
        "id": 11329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735499127653,
            21.029455755777025,
            105.83747248350797,
            21.029588790285825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735499127653,
              21.02946141399229
            ],
            [
              105.8373562241396,
              21.02946529358494
            ],
            [
              105.83736207100888,
              21.02948204318993
            ],
            [
              105.83746768299036,
              21.029455755777025
            ],
            [
              105.83747248350797,
              21.02947159635335
            ],
            [
              105.83745791132986,
              21.02953936646789
            ],
            [
              105.83743802822782,
              21.029588790285825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.6_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11353",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 29.085,
          "from_latitude": 21.02946141399229,
          "to_latitude": 21.029588790285825,
          "to_longitude": 105.83743802822782,
          "from_longitude": 105.83735499127653
        }
      },
      {
        "id": 11330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83693351936701,
            21.02977409915958,
            105.8371715503745,
            21.029849037766482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83693351936701,
              21.029849037766482
            ],
            [
              105.83695354653057,
              21.029836920004787
            ],
            [
              105.83695595647887,
              21.02983624337055
            ],
            [
              105.83707767305862,
              21.02980132535034
            ],
            [
              105.8371715503745,
              21.02977409915958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.12_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11354",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.5,
          "chatLieu": "HDPE",
          "chieuDai": 26.181,
          "from_latitude": 21.029849037766482,
          "to_latitude": 21.02977409915958,
          "to_longitude": 105.8371715503745,
          "from_longitude": 105.83693351936701
        }
      },
      {
        "id": 11331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695595647887,
            21.02983624337055,
            105.8370101326487,
            21.029967785786543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695595647887,
              21.02983624337055
            ],
            [
              105.8369767345005,
              21.0298903660693
            ],
            [
              105.8370101326487,
              21.029967785786543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.12_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11355",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02983624337055,
          "to_latitude": 21.029967785786543,
          "to_longitude": 105.8370101326487,
          "from_longitude": 105.83695595647887
        }
      },
      {
        "id": 11332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83729873451108,
            21.02989364400871,
            105.83750095911276,
            21.02995592903892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83729873451108,
              21.02995592903892
            ],
            [
              105.8373359166793,
              21.029944352689085
            ],
            [
              105.83750095911276,
              21.02989364400871
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.14_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11356",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": 22.123,
          "from_latitude": 21.02995592903892,
          "to_latitude": 21.02989364400871,
          "to_longitude": 105.83750095911276,
          "from_longitude": 105.83729873451108
        }
      },
      {
        "id": 11333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712277128315,
            21.02995592903892,
            105.83730975270421,
            21.030006635267167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730975270421,
              21.029980225663888
            ],
            [
              105.83729982349053,
              21.029958333474447
            ],
            [
              105.83729873451108,
              21.02995592903892
            ],
            [
              105.83712277128315,
              21.030006635267167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.14_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11357",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029980225663888,
          "to_latitude": 21.030006635267167,
          "to_longitude": 105.83712277128315,
          "from_longitude": 105.83730975270421
        }
      },
      {
        "id": 11334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83742209150049,
            21.02962618903052,
            105.83749561170329,
            21.02992684573902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749561170329,
              21.02992684573902
            ],
            [
              105.83748596488633,
              21.02990126340896
            ],
            [
              105.8374850873106,
              21.029898643869675
            ],
            [
              105.83744866450733,
              21.02979009241974
            ],
            [
              105.83746505890373,
              21.02974575918521
            ],
            [
              105.83742209150049,
              21.02962618903052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.13_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11359",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02992684573902,
          "to_latitude": 21.02962618903052,
          "to_longitude": 105.83742209150049,
          "from_longitude": 105.83749561170329
        }
      },
      {
        "id": 11335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749254498893,
            21.029659676755895,
            105.83757710416036,
            21.029903440157447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83757710416036,
              21.029903440157447
            ],
            [
              105.83756711063741,
              21.029878653472785
            ],
            [
              105.83749254498893,
              21.029659676755895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.12.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11360",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029903440157447,
          "to_latitude": 21.029659676755895,
          "to_longitude": 105.83749254498893,
          "from_longitude": 105.83757710416036
        }
      },
      {
        "id": 11336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756595020596,
            21.029840756377777,
            105.83775861899603,
            21.029875245639676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775861899603,
              21.029848267047996
            ],
            [
              105.83769525180894,
              21.02986547900029
            ],
            [
              105.83768251131767,
              21.029840756377777
            ],
            [
              105.83763154934363,
              21.029854525940134
            ],
            [
              105.8376264245856,
              21.02985286510118
            ],
            [
              105.8376238380076,
              21.02985702949725
            ],
            [
              105.83756595020596,
              21.029875245639676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.12.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11361",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 22.854,
          "from_latitude": 21.029848267047996,
          "to_latitude": 21.029875245639676,
          "to_longitude": 105.83756595020596,
          "from_longitude": 105.83775861899603
        }
      },
      {
        "id": 11337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773873137864,
            21.02961321437385,
            105.8379209518862,
            21.029807740719445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379209518862,
              21.029774858634696
            ],
            [
              105.83781041884673,
              21.029807740719445
            ],
            [
              105.83773873137864,
              21.02961321437385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.10_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11362",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": 34.843,
          "from_latitude": 21.029774858634696,
          "to_latitude": 21.02961321437385,
          "to_longitude": 105.83773873137864,
          "from_longitude": 105.8379209518862
        }
      },
      {
        "id": 11338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805064445153,
            21.02949682985611,
            105.83816018867542,
            21.029735973602417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816018867542,
              21.029735973602417
            ],
            [
              105.83816008759939,
              21.029735618230987
            ],
            [
              105.83815590396205,
              21.029715872732037
            ],
            [
              105.83815526245513,
              21.02971366570871
            ],
            [
              105.83808316624062,
              21.02949682985611
            ],
            [
              105.83805064445153,
              21.029506531161076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.8_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11365",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 31.22,
          "from_latitude": 21.029735973602417,
          "to_latitude": 21.029506531161076,
          "to_longitude": 105.83805064445153,
          "from_longitude": 105.83816018867542
        }
      },
      {
        "id": 11339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815255665958,
            21.029660813119,
            105.83830947763339,
            21.02970436973428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815255665958,
              21.02970436973428
            ],
            [
              105.83822968192162,
              21.02968459693659
            ],
            [
              105.83830947763339,
              21.029660813119
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.8_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11366",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": 17.014,
          "from_latitude": 21.02970436973428,
          "to_latitude": 21.029660813119,
          "to_longitude": 105.83830947763339,
          "from_longitude": 105.83815255665958
        }
      },
      {
        "id": 11340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773185439004,
            21.029280936399438,
            105.83780782037094,
            21.02932822662946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773185439004,
              21.029280936399438
            ],
            [
              105.83773253552447,
              21.029282145168455
            ],
            [
              105.83775857916713,
              21.02932822662946
            ],
            [
              105.83780782037094,
              21.02931352201693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.10_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11367",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": 11.298,
          "from_latitude": 21.029280936399438,
          "to_latitude": 21.02931352201693,
          "to_longitude": 105.83780782037094,
          "from_longitude": 105.83773185439004
        }
      },
      {
        "id": 11341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83784939458197,
            21.0291942083434,
            105.83796472956877,
            21.029303507753387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83784939458197,
              21.029303507753387
            ],
            [
              105.83786615839382,
              21.029298500622193
            ],
            [
              105.83789365103665,
              21.029289425194925
            ],
            [
              105.83793623110027,
              21.02927659441772
            ],
            [
              105.83795232435571,
              21.02927659441772
            ],
            [
              105.83796305319568,
              21.02927690736378
            ],
            [
              105.83796472956877,
              21.02924623867317
            ],
            [
              105.83796467955734,
              21.0291942083434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.15_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11370",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": 21.457,
          "from_latitude": 21.029303507753387,
          "to_latitude": 21.0291942083434,
          "to_longitude": 105.83796467955734,
          "from_longitude": 105.83784939458197
        }
      },
      {
        "id": 11342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83796305319568,
            21.02927690736378,
            105.83812566206024,
            21.02935796030026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812566206024,
              21.02935796030026
            ],
            [
              105.83808140566842,
              21.02933167286716
            ],
            [
              105.83801267405686,
              21.029288486357647
            ],
            [
              105.83796305319568,
              21.02927690736378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.15_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11371",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": 19.356,
          "from_latitude": 21.02935796030026,
          "to_latitude": 21.02927690736378,
          "to_longitude": 105.83796305319568,
          "from_longitude": 105.83812566206024
        }
      },
      {
        "id": 11343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83711665889757,
            21.02951740601212,
            105.83712503091091,
            21.029531801495395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83711665889757,
              21.029525427165
            ],
            [
              105.83711716820729,
              21.029527054318137
            ],
            [
              105.837118995939,
              21.029531801495395
            ],
            [
              105.83712503091091,
              21.029529923823898
            ],
            [
              105.83712067231211,
              21.02951740601212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.2_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11372",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 28.016,
          "from_latitude": 21.029525427165,
          "to_latitude": 21.02951740601212,
          "to_longitude": 105.83712067231211,
          "from_longitude": 105.83711665889757
        }
      },
      {
        "id": 11344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696403189529,
            21.02951740601212,
            105.83712067231211,
            21.029563206361974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712067231211,
              21.02951740601212
            ],
            [
              105.83696403189529,
              21.029563206361974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.2_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11373",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": 17.054,
          "from_latitude": 21.02951740601212,
          "to_latitude": 21.029563206361974,
          "to_longitude": 105.83696403189529,
          "from_longitude": 105.83712067231211
        }
      },
      {
        "id": 11345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676941461806,
            21.02945543611029,
            105.83698354436717,
            21.029529297932832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676941461806,
              21.029468347622934
            ],
            [
              105.8368064776624,
              21.02945543611029
            ],
            [
              105.83681131210895,
              21.029457897084306
            ],
            [
              105.8368403814683,
              21.029529297932832
            ],
            [
              105.83690408392464,
              21.02951271183285
            ],
            [
              105.83698354436717,
              21.029490179769468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.1_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11374",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": 28.644,
          "from_latitude": 21.029468347622934,
          "to_latitude": 21.029490179769468,
          "to_longitude": 105.83698354436717,
          "from_longitude": 105.83676941461806
        }
      },
      {
        "id": 11346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83674874747571,
            21.02930223104368,
            105.83681131210895,
            21.029457897084317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681131210895,
              21.029457897084317
            ],
            [
              105.83674874747571,
              21.02930223104368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.1_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11375",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029457897084317,
          "to_latitude": 21.02930223104368,
          "to_longitude": 105.83674874747571,
          "from_longitude": 105.83681131210895
        }
      },
      {
        "id": 11347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737911062029,
            21.029345095065057,
            105.83749221732323,
            21.02944599100944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737911062029,
              21.02944599100944
            ],
            [
              105.83742474515729,
              21.029429276132557
            ],
            [
              105.83749221732323,
              21.029393392980115
            ],
            [
              105.83747422312719,
              21.029345095065057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.7_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11376",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02944599100944,
          "to_latitude": 21.029345095065057,
          "to_longitude": 105.83747422312719,
          "from_longitude": 105.83737911062029
        }
      },
      {
        "id": 11348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725654264997,
            21.02944599100944,
            105.83737911062029,
            21.02948332050865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725654264997,
              21.02948332050865
            ],
            [
              105.83728002613607,
              21.02947616877089
            ],
            [
              105.83737911062029,
              21.02944599100944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.7_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11377",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02948332050865,
          "to_latitude": 21.02944599100944,
          "to_longitude": 105.83737911062029,
          "from_longitude": 105.83725654264997
        }
      },
      {
        "id": 11349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746083404347,
            21.029162647705594,
            105.83759085680522,
            21.02934644289959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746083404347,
              21.029162647705594
            ],
            [
              105.83753047539835,
              21.02934644289959
            ],
            [
              105.837586768208,
              21.02932646723557
            ],
            [
              105.83758776016262,
              21.02932855240655
            ],
            [
              105.83759085680522,
              21.0293343855195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.8_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11378",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029162647705594,
          "to_latitude": 21.0293343855195,
          "to_longitude": 105.83759085680522,
          "from_longitude": 105.83746083404347
        }
      },
      {
        "id": 11350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837586768208,
            21.029274618678713,
            105.83773215578819,
            21.02932646723557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837586768208,
              21.02932646723557
            ],
            [
              105.83762612808022,
              21.029312455849883
            ],
            [
              105.837639140513,
              21.029307997570864
            ],
            [
              105.83765404643006,
              21.02930289048146
            ],
            [
              105.83773215578819,
              21.029274618678713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.8_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11379",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02932646723557,
          "to_latitude": 21.029274618678713,
          "to_longitude": 105.83773215578819,
          "from_longitude": 105.837586768208
        }
      },
      {
        "id": 11351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753190970246,
            21.029071305177933,
            105.83762612808022,
            21.029312455849883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753190970246,
              21.029071305177933
            ],
            [
              105.83762612808022,
              21.029312455849883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.8_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11380",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029071305177933,
          "to_latitude": 21.029312455849883,
          "to_longitude": 105.83762612808022,
          "from_longitude": 105.83753190970246
        }
      },
      {
        "id": 11352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746533951935,
            21.028961690274365,
            105.83764088092872,
            21.029314387426446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83764088092872,
              21.029314387426446
            ],
            [
              105.8376397572628,
              21.029309563430374
            ],
            [
              105.837639140513,
              21.029307997570864
            ],
            [
              105.83763020273253,
              21.029285342711372
            ],
            [
              105.83760836405226,
              21.029221857995733
            ],
            [
              105.83757420391014,
              21.029135323210802
            ],
            [
              105.83753189381412,
              21.029017155249765
            ],
            [
              105.83748412657388,
              21.02903110866583
            ],
            [
              105.83746533951935,
              21.02896986063146
            ],
            [
              105.83749555907136,
              21.028961690274365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.9_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11381",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029314387426446,
          "to_latitude": 21.028961690274365,
          "to_longitude": 105.83749555907136,
          "from_longitude": 105.83764088092872
        }
      },
      {
        "id": 11353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753189381412,
            21.028951100700926,
            105.83758141805049,
            21.029017155249765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753189381412,
              21.029017155249765
            ],
            [
              105.83756698301147,
              21.029006209678165
            ],
            [
              105.83755332113711,
              21.028957623075108
            ],
            [
              105.83758141805049,
              21.028951100700926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.9_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11382",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029017155249765,
          "to_latitude": 21.028951100700926,
          "to_longitude": 105.83758141805049,
          "from_longitude": 105.83753189381412
        }
      },
      {
        "id": 11354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8376107387398,
            21.028903264626674,
            105.83781043524512,
            21.029261465949315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376107387398,
              21.02891618852109
            ],
            [
              105.83766073010533,
              21.028903264626674
            ],
            [
              105.837687452087,
              21.028976752859712
            ],
            [
              105.83767942487106,
              21.02897966013664
            ],
            [
              105.83780805077906,
              21.029253962885782
            ],
            [
              105.83781043524512,
              21.029261465949315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.11_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11383",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02891618852109,
          "to_latitude": 21.029261465949315,
          "to_longitude": 105.83781043524512,
          "from_longitude": 105.8376107387398
        }
      },
      {
        "id": 11355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766073010533,
            21.028850748200554,
            105.83784578148882,
            21.028903264626674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766073010533,
              21.028903264626674
            ],
            [
              105.83784578148882,
              21.028850748200554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.11_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11384",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028903264626674,
          "to_latitude": 21.028850748200554,
          "to_longitude": 105.83784578148882,
          "from_longitude": 105.83766073010533
        }
      },
      {
        "id": 11356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83775919204827,
            21.028904780414972,
            105.83783429730525,
            21.02926022470403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83781377903502,
              21.02926022470403
            ],
            [
              105.83781174967137,
              21.029253086599287
            ],
            [
              105.83775919204827,
              21.029133742617113
            ],
            [
              105.83783429730525,
              21.029110706197436
            ],
            [
              105.83778481238046,
              21.028904780414972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.12_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11385",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02926022470403,
          "to_latitude": 21.028904780414972,
          "to_longitude": 105.83778481238046,
          "from_longitude": 105.83781377903502
        }
      },
      {
        "id": 11357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779973850035,
            21.02886514912261,
            105.83796385231776,
            21.029252105358264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783564950757,
              21.029252105358264
            ],
            [
              105.83783408336284,
              21.029245398518235
            ],
            [
              105.83783104982348,
              21.029235882660362
            ],
            [
              105.83779973850035,
              21.029137715735093
            ],
            [
              105.83782004212607,
              21.029131888361704
            ],
            [
              105.83796385231776,
              21.02909219301969
            ],
            [
              105.83793286138923,
              21.02886514912261
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.13_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11386",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029252105358264,
          "to_latitude": 21.02886514912261,
          "to_longitude": 105.83793286138923,
          "from_longitude": 105.83783564950757
        }
      },
      {
        "id": 11358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379681224113,
            21.029095748738,
            105.83802882682745,
            21.02912978919202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379681224113,
              21.029095748738
            ],
            [
              105.83802882682745,
              21.02912978919202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.14_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11387",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029095748738,
          "to_latitude": 21.02912978919202,
          "to_longitude": 105.83802882682745,
          "from_longitude": 105.8379681224113
        }
      },
      {
        "id": 11359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83780769420267,
            21.02882118511922,
            105.83797952663242,
            21.029250788137446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783919778328,
              21.029250788137446
            ],
            [
              105.83783770433578,
              21.029244351002813
            ],
            [
              105.83783514201536,
              21.02923497643555
            ],
            [
              105.83783364628438,
              21.029229507566747
            ],
            [
              105.83780769420267,
              21.02914025704361
            ],
            [
              105.83784358268272,
              21.029129970442057
            ],
            [
              105.8379681224113,
              21.029095748738
            ],
            [
              105.83797224244353,
              21.029094316697265
            ],
            [
              105.83793334641723,
              21.028828868795294
            ],
            [
              105.83797952663242,
              21.02882118511922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.14_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11388",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029250788137446,
          "to_latitude": 21.02882118511922,
          "to_longitude": 105.83797952663242,
          "from_longitude": 105.83783919778328
        }
      },
      {
        "id": 11360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667911648497,
            21.029117981442052,
            105.83686966760486,
            21.02922300787784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83685672378527,
              21.029117981442052
            ],
            [
              105.83686966760486,
              21.029172222201947
            ],
            [
              105.83682267392733,
              21.029192072382383
            ],
            [
              105.8367219655328,
              21.029220516396084
            ],
            [
              105.8367128977762,
              21.02921746386501
            ],
            [
              105.83671128143166,
              21.029216920852637
            ],
            [
              105.83667911648497,
              21.02922300787784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.2_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11389",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029117981442052,
          "to_latitude": 21.02922300787784,
          "to_longitude": 105.83667911648497,
          "from_longitude": 105.83685672378527
        }
      },
      {
        "id": 11361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667408466017,
            21.029120121438993,
            105.8367128977762,
            21.029217463865006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367128977762,
              21.029217463865006
            ],
            [
              105.8366975539914,
              21.029185527164685
            ],
            [
              105.83668917208995,
              21.029163933889897
            ],
            [
              105.83667408466017,
              21.029120121438993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.2_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11390",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": 11.522,
          "from_latitude": 21.029217463865006,
          "to_latitude": 21.029120121438993,
          "to_longitude": 105.83667408466017,
          "from_longitude": 105.8367128977762
        }
      },
      {
        "id": 11362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83675374223048,
            21.028757409851426,
            105.83687246180239,
            21.029076261843542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675374223048,
              21.028759552000665
            ],
            [
              105.83676891672745,
              21.028757409851426
            ],
            [
              105.83681419220328,
              21.028906104105243
            ],
            [
              105.83684018787582,
              21.028901266768663
            ],
            [
              105.83687246180239,
              21.029009136090643
            ],
            [
              105.83684247002698,
              21.02901693037973
            ],
            [
              105.83684984743633,
              21.029047196294066
            ],
            [
              105.83683134358964,
              21.029076261843542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11391",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028759552000665,
          "to_latitude": 21.029076261843542,
          "to_longitude": 105.83683134358964,
          "from_longitude": 105.83675374223048
        }
      },
      {
        "id": 11363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83658319939173,
            21.028742103349135,
            105.83675374223048,
            21.02880269899386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674827482785,
              21.028742103349135
            ],
            [
              105.83675006826032,
              21.028747830892303
            ],
            [
              105.83675374223048,
              21.028759552000654
            ],
            [
              105.83660606148086,
              21.028780404263642
            ],
            [
              105.83658319939173,
              21.02880269899386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11392",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028742103349135,
          "to_latitude": 21.02880269899386,
          "to_longitude": 105.83658319939173,
          "from_longitude": 105.83674827482785
        }
      },
      {
        "id": 11364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83688727713198,
            21.028737396334154,
            105.8369854548663,
            21.02874623527165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688727713198,
              21.02874623527165
            ],
            [
              105.8369854548663,
              21.028737396334154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11393",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02874623527165,
          "to_latitude": 21.028737396334154,
          "to_longitude": 105.8369854548663,
          "from_longitude": 105.83688727713198
        }
      },
      {
        "id": 11365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706292103282,
            21.02871133500581,
            105.83715981583468,
            21.028871367895363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709019840438,
              21.028871367895363
            ],
            [
              105.83709376153035,
              21.028858929234918
            ],
            [
              105.83706292103282,
              21.028726669406947
            ],
            [
              105.83706627379699,
              21.028726043512496
            ],
            [
              105.83707103217817,
              21.028730389303753
            ],
            [
              105.83707465569843,
              21.028724478778063
            ],
            [
              105.83715981583468,
              21.02871133500581
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.5_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11394",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": 27.187,
          "from_latitude": 21.028871367895363,
          "to_latitude": 21.02871133500581,
          "to_longitude": 105.83715981583468,
          "from_longitude": 105.83709019840438
        }
      },
      {
        "id": 11366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83715981583468,
            21.02868348272128,
            105.83734329571638,
            21.028733006582982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83715981583468,
              21.02871133500581
            ],
            [
              105.83733013610714,
              21.02868348272128
            ],
            [
              105.83734329571638,
              21.028733006582982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.5_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11395",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": 27.187,
          "from_latitude": 21.02871133500581,
          "to_latitude": 21.028733006582982,
          "to_longitude": 105.83734329571638,
          "from_longitude": 105.83715981583468
        }
      },
      {
        "id": 11367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760279115553,
            21.028603493131918,
            105.83766028797565,
            21.028854585012276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83760279115553,
              21.028603493131918
            ],
            [
              105.83760492688658,
              21.02861314257866
            ],
            [
              105.83761061931854,
              21.028629354483037
            ],
            [
              105.83762525564597,
              21.028671044694246
            ],
            [
              105.83766028797565,
              21.02884842222031
            ],
            [
              105.83764248173787,
              21.028854585012276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11396",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028603493131918,
          "to_latitude": 21.028854585012276,
          "to_longitude": 105.83764248173787,
          "from_longitude": 105.83760279115553
        }
      },
      {
        "id": 11368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375209523486,
            21.028629354483037,
            105.83761061931854,
            21.028838896258566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83761061931854,
              21.028629354483037
            ],
            [
              105.8375209523486,
              21.02864829053554
            ],
            [
              105.83757055804335,
              21.028838896258566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11397",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028629354483037,
          "to_latitude": 21.028838896258566,
          "to_longitude": 105.83757055804335,
          "from_longitude": 105.83761061931854
        }
      },
      {
        "id": 11369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83847300207508,
            21.029564498457276,
            105.83867844563107,
            21.029585954211356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83847300207508,
              21.029570864998853
            ],
            [
              105.83859608625202,
              21.029585954211356
            ],
            [
              105.83867843582917,
              21.029564498457276
            ],
            [
              105.83867844563107,
              21.029564530924937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.6_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11398",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029570864998853,
          "to_latitude": 21.029564530924937,
          "to_longitude": 105.83867844563107,
          "from_longitude": 105.83847300207508
        }
      },
      {
        "id": 11370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363831616848,
            21.02842747889967,
            105.83657395470996,
            21.028462783688838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363831616848,
              21.028454276663503
            ],
            [
              105.83640534452059,
              21.028447317510462
            ],
            [
              105.83640762545942,
              21.028454384849287
            ],
            [
              105.83642590561979,
              21.028449364238604
            ],
            [
              105.83649224165363,
              21.02842747889967
            ],
            [
              105.83650811091897,
              21.028462783688838
            ],
            [
              105.83657395470996,
              21.028439231583775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.4_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11399",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028454276663503,
          "to_latitude": 21.028439231583775,
          "to_longitude": 105.83657395470996,
          "from_longitude": 105.8363831616848
        }
      },
      {
        "id": 11371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640762545942,
            21.028454384849287,
            105.83657723223207,
            21.028583553823857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640762545942,
              21.028454384849287
            ],
            [
              105.83645335430086,
              21.028583553823857
            ],
            [
              105.83654557396771,
              21.02855209613616
            ],
            [
              105.83657723223207,
              21.028502478094982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.4_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11400",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028454384849287,
          "to_latitude": 21.028502478094982,
          "to_longitude": 105.83657723223207,
          "from_longitude": 105.83640762545942
        }
      },
      {
        "id": 11372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363393607173,
            21.028331127386576,
            105.83638901580315,
            21.02840996675926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363393607173,
              21.028336639566405
            ],
            [
              105.83635771244033,
              21.028331343128347
            ],
            [
              105.83635952271617,
              21.028331127386576
            ],
            [
              105.83637325781694,
              21.02837084830292
            ],
            [
              105.83638063389272,
              21.02839244169179
            ],
            [
              105.83638901580315,
              21.02840996675926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.4_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11402",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 11.448,
          "from_latitude": 21.028336639566405,
          "to_latitude": 21.02840996675926,
          "to_longitude": 105.83638901580315,
          "from_longitude": 105.8363393607173
        }
      },
      {
        "id": 11373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83631716530468,
            21.028209680152937,
            105.83654156650996,
            21.028278215756476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631716530468,
              21.028277027626586
            ],
            [
              105.83633300380383,
              21.02827061994248
            ],
            [
              105.83633671278811,
              21.02826914026791
            ],
            [
              105.83634107137793,
              21.028278215756476
            ],
            [
              105.83649697478604,
              21.028224701658264
            ],
            [
              105.83654156650996,
              21.028209680152937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.5_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11403",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": 25.705,
          "from_latitude": 21.028277027626586,
          "to_latitude": 21.028209680152937,
          "to_longitude": 105.83654156650996,
          "from_longitude": 105.83631716530468
        }
      },
      {
        "id": 11374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83625772841265,
            21.02789870646514,
            105.83639216107596,
            21.027959810860153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83638490742625,
              21.02789870646514
            ],
            [
              105.83639111646019,
              21.027912878204223
            ],
            [
              105.83639216107596,
              21.027914923382426
            ],
            [
              105.83630091605889,
              21.02794444419155
            ],
            [
              105.83625772841265,
              21.027959810860153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.2_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11405",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02789870646514,
          "to_latitude": 21.027959810860153,
          "to_longitude": 105.83625772841265,
          "from_longitude": 105.83638490742625
        }
      },
      {
        "id": 11375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83639216107596,
            21.02783406380255,
            105.83662010458032,
            21.027914923382426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83639216107596,
              21.027914923382426
            ],
            [
              105.8365092338794,
              21.027870169069562
            ],
            [
              105.83651119904751,
              21.027869438630447
            ],
            [
              105.83657584813461,
              21.027848459447604
            ],
            [
              105.83658557113993,
              21.02785096303757
            ],
            [
              105.83658858862589,
              21.02784470406214
            ],
            [
              105.83662010458032,
              21.02783406380255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.2_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11406",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": 25.76,
          "from_latitude": 21.027914923382426,
          "to_latitude": 21.02783406380255,
          "to_longitude": 105.83662010458032,
          "from_longitude": 105.83639216107596
        }
      },
      {
        "id": 11376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83664483113073,
            21.02776417883076,
            105.83681786603795,
            21.027817712225755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681786603795,
              21.02776417883076
            ],
            [
              105.83674683891859,
              21.02778899916648
            ],
            [
              105.83674206121078,
              21.027782036051903
            ],
            [
              105.83669512255734,
              21.027798935292825
            ],
            [
              105.83664483113073,
              21.027817712225755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.5_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11407",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 19.647,
          "from_latitude": 21.02776417883076,
          "to_latitude": 21.027817712225755,
          "to_longitude": 105.83664483113073,
          "from_longitude": 105.83681786603795
        }
      },
      {
        "id": 11377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83672613580556,
            21.02770098225586,
            105.83675740024071,
            21.027780862494055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674378548525,
              21.027764676582557
            ],
            [
              105.83675005165695,
              21.027778049577666
            ],
            [
              105.83675170054703,
              21.027780862494055
            ],
            [
              105.83675740024071,
              21.027778671851284
            ],
            [
              105.83672613580556,
              21.02770098225586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.1_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11408",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": 11.808,
          "from_latitude": 21.027764676582557,
          "to_latitude": 21.02770098225586,
          "to_longitude": 105.83672613580556,
          "from_longitude": 105.83674378548525
        }
      },
      {
        "id": 11378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83694264146379,
            21.02769040956354,
            105.83704544251242,
            21.027958869800315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83704544251242,
              21.027958869800315
            ],
            [
              105.83701934152377,
              21.027880554273437
            ],
            [
              105.83699040862511,
              21.027808827239486
            ],
            [
              105.83695416914176,
              21.027718987703025
            ],
            [
              105.83695115772649,
              21.02771152293564
            ],
            [
              105.83694723614506,
              21.027701800074443
            ],
            [
              105.83694264146379,
              21.02769040956354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.8_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11409",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027958869800315,
          "to_latitude": 21.02769040956354,
          "to_longitude": 105.83694264146379,
          "from_longitude": 105.83704544251242
        }
      },
      {
        "id": 11379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708810143311,
            21.02763608459327,
            105.83716355961452,
            21.02780261787637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83708810143311,
              21.02763608459327
            ],
            [
              105.83709780435241,
              21.027659867462482
            ],
            [
              105.83710041093167,
              21.02766271062195
            ],
            [
              105.83710957743804,
              21.027664512588743
            ],
            [
              105.83716355961452,
              21.02780261787637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.9_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11410",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02763608459327,
          "to_latitude": 21.02780261787637,
          "to_longitude": 105.83716355961452,
          "from_longitude": 105.83708810143311
        }
      },
      {
        "id": 11380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83700290595412,
            21.02766271062195,
            105.83710041093167,
            21.027803942475224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710041093167,
              21.02766271062195
            ],
            [
              105.83700290595412,
              21.027698478663375
            ],
            [
              105.83702469890326,
              21.027752931798332
            ],
            [
              105.83704582129596,
              21.027803942475224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.9_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11411",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": 23.382,
          "from_latitude": 21.02766271062195,
          "to_latitude": 21.027803942475224,
          "to_longitude": 105.83704582129596,
          "from_longitude": 105.83710041093167
        }
      },
      {
        "id": 11381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716230181983,
            21.027850597848367,
            105.83719812463188,
            21.027975009337588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716230181983,
              21.027850597848367
            ],
            [
              105.83717582353884,
              21.027897558481122
            ],
            [
              105.83719812463188,
              21.027975009337588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.3_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11412",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027850597848367,
          "to_latitude": 21.027975009337588,
          "to_longitude": 105.83719812463188,
          "from_longitude": 105.83716230181983
        }
      },
      {
        "id": 11382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83709804815597,
            21.027897558481122,
            105.83717582353884,
            21.027916449304794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717582353884,
              21.027897558481122
            ],
            [
              105.83709804815597,
              21.027916449304794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.3_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11413",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027897558481122,
          "to_latitude": 21.027916449304794,
          "to_longitude": 105.83709804815597,
          "from_longitude": 105.83717582353884
        }
      },
      {
        "id": 11383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719812463188,
            21.02797166597162,
            105.83723767653616,
            21.0281000494635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723412995325,
              21.0281000494635
            ],
            [
              105.83720514204124,
              21.02799937986995
            ],
            [
              105.83719812463188,
              21.027975009337588
            ],
            [
              105.83723710441004,
              21.02797171485286
            ],
            [
              105.83723767653616,
              21.02797166597162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.3_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11414",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0281000494635,
          "to_latitude": 21.02797166597162,
          "to_longitude": 105.83723767653616,
          "from_longitude": 105.83723412995325
        }
      },
      {
        "id": 11384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712296245083,
            21.02799937986995,
            105.83720514204124,
            21.028016883361996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720514204124,
              21.02799937986995
            ],
            [
              105.83720508534945,
              21.02799939189096
            ],
            [
              105.83712296245083,
              21.028016883361996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.3_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11415",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02799937986995,
          "to_latitude": 21.028016883361996,
          "to_longitude": 105.83712296245083,
          "from_longitude": 105.83720514204124
        }
      },
      {
        "id": 11385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83715913904331,
            21.028232325932443,
            105.837298618878,
            21.02834500452532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83715913904331,
              21.028318324790646
            ],
            [
              105.8371656748844,
              21.02834500452532
            ],
            [
              105.8372132901443,
              21.028336254514713
            ],
            [
              105.837298618878,
              21.0283194932726
            ],
            [
              105.83728669748568,
              21.028267985176413
            ],
            [
              105.83728438872008,
              21.02825800960991
            ],
            [
              105.83727844429448,
              21.028232325932443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.2_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11416",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028318324790646,
          "to_latitude": 21.028232325932443,
          "to_longitude": 105.83727844429448,
          "from_longitude": 105.83715913904331
        }
      },
      {
        "id": 11386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83724974448718,
            21.02810832558856,
            105.83727844429448,
            21.028232325932443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724974448718,
              21.02810832558856
            ],
            [
              105.83726878337731,
              21.028190584994107
            ],
            [
              105.83727844429448,
              21.028232325932443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.2_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11417",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02810832558856,
          "to_latitude": 21.028232325932443,
          "to_longitude": 105.83727844429448,
          "from_longitude": 105.83724974448718
        }
      },
      {
        "id": 11387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83711919956755,
            21.02825800960991,
            105.83728438872008,
            21.02829001006114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728438872008,
              21.02825800960991
            ],
            [
              105.83728427916914,
              21.02825803092346
            ],
            [
              105.83712291391241,
              21.02829001006114
            ],
            [
              105.83711919956755,
              21.028262965983966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.2_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11418",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02825800960991,
          "to_latitude": 21.028262965983966,
          "to_longitude": 105.83711919956755,
          "from_longitude": 105.83728438872008
        }
      },
      {
        "id": 11388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371280697483,
            21.028190584994107,
            105.83726878337731,
            21.02822065871363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83726878337731,
              21.028190584994107
            ],
            [
              105.8371859515618,
              21.028209143081785
            ],
            [
              105.8371280697483,
              21.02822065871363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.2_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11419",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028190584994107,
          "to_latitude": 21.02822065871363,
          "to_longitude": 105.8371280697483,
          "from_longitude": 105.83726878337731
        }
      },
      {
        "id": 11389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701306831533,
            21.028090426068374,
            105.83702781705912,
            21.02829353413335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701306831533,
              21.02829353413335
            ],
            [
              105.83701971463087,
              21.02820200584605
            ],
            [
              105.83702781705912,
              21.028090426068374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.3_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11420",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02829353413335,
          "to_latitude": 21.028090426068374,
          "to_longitude": 105.83702781705912,
          "from_longitude": 105.83701306831533
        }
      },
      {
        "id": 11390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698655878081,
            21.02829353413335,
            105.83701306831533,
            21.028550552937453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698655878081,
              21.028550552937453
            ],
            [
              105.83701306831533,
              21.02829353413335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.3_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11421",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028550552937453,
          "to_latitude": 21.02829353413335,
          "to_longitude": 105.83701306831533,
          "from_longitude": 105.83698655878081
        }
      },
      {
        "id": 11391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698889077745,
            21.028190139608757,
            105.83700148645497,
            21.02834003092434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698889077745,
              21.02834003092434
            ],
            [
              105.83700148645497,
              21.028190139608757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.2_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11422",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02834003092434,
          "to_latitude": 21.028190139608757,
          "to_longitude": 105.83700148645497,
          "from_longitude": 105.83698889077745
        }
      },
      {
        "id": 11392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730881300167,
            21.02837057542243,
            105.8373512059428,
            21.028579585583273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373512059428,
              21.028579585583273
            ],
            [
              105.8373437610646,
              21.02854827680125
            ],
            [
              105.83734259091446,
              21.02854115613575
            ],
            [
              105.8373471997925,
              21.028529590253303
            ],
            [
              105.83730881300167,
              21.02837057542243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11423",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028579585583273,
          "to_latitude": 21.02837057542243,
          "to_longitude": 105.83730881300167,
          "from_longitude": 105.8373512059428
        }
      },
      {
        "id": 11393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726662691389,
            21.02842092375047,
            105.83734259091446,
            21.028548428116757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734259091446,
              21.02854115613575
            ],
            [
              105.83729560612313,
              21.028548428116757
            ],
            [
              105.83726662691389,
              21.02842092375047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11424",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02854115613575,
          "to_latitude": 21.02842092375047,
          "to_longitude": 105.83726662691389,
          "from_longitude": 105.83734259091446
        }
      },
      {
        "id": 11394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702446152508,
            21.028572310767064,
            105.83715811103615,
            21.02861018733492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702446152508,
              21.028591754748405
            ],
            [
              105.83715057275622,
              21.028572310767064
            ],
            [
              105.83715204142341,
              21.028577762615207
            ],
            [
              105.83715811103615,
              21.02861018733492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11425",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028591754748405,
          "to_latitude": 21.02861018733492,
          "to_longitude": 105.83715811103615,
          "from_longitude": 105.83702446152508
        }
      },
      {
        "id": 11395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83715057275622,
            21.02844028441448,
            105.83722206606417,
            21.028572310767064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83715057275622,
              21.028572310767064
            ],
            [
              105.837161022956,
              21.028570722033646
            ],
            [
              105.83722206606417,
              21.02856143984718
            ],
            [
              105.83719304733684,
              21.02844028441448
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11426",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028572310767064,
          "to_latitude": 21.02844028441448,
          "to_longitude": 105.83719304733684,
          "from_longitude": 105.83715057275622
        }
      },
      {
        "id": 11396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83707022360493,
            21.028428764450748,
            105.837161022956,
            21.028570722033646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837161022956,
              21.028570722033646
            ],
            [
              105.83716101600513,
              21.028570683229294
            ],
            [
              105.83713879539103,
              21.028428764450748
            ],
            [
              105.83707022360493,
              21.028434262131505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11427",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028570722033646,
          "to_latitude": 21.028434262131505,
          "to_longitude": 105.83707022360493,
          "from_longitude": 105.837161022956
        }
      },
      {
        "id": 11397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83799646050251,
            21.028202198237693,
            105.8381440190163,
            21.028410333167237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83799646050251,
              21.028410333167237
            ],
            [
              105.8381440190163,
              21.028376950781446
            ],
            [
              105.83812070057992,
              21.02828271600767
            ],
            [
              105.83811090579857,
              21.0282852102109
            ],
            [
              105.8380880776539,
              21.028202198237693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.7_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11428",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38.11,
          "chatLieu": "HDPE",
          "chieuDai": 37.036,
          "from_latitude": 21.028410333167237,
          "to_latitude": 21.028202198237693,
          "to_longitude": 105.8380880776539,
          "from_longitude": 105.83799646050251
        }
      },
      {
        "id": 11398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83791559077564,
            21.028201306854584,
            105.8380038235146,
            21.028434374991363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791559077564,
              21.028201306854584
            ],
            [
              105.83798264025503,
              21.028413965390826
            ],
            [
              105.83799646050251,
              21.028410333167244
            ],
            [
              105.83799731058373,
              21.028412680969797
            ],
            [
              105.8380038235146,
              21.028434374991363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.7_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11429",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028201306854584,
          "to_latitude": 21.028434374991363,
          "to_longitude": 105.8380038235146,
          "from_longitude": 105.83791559077564
        }
      },
      {
        "id": 11399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83821084983425,
            21.028310937757226,
            105.8384034836788,
            21.02836040222114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83821084983425,
              21.02836040222114
            ],
            [
              105.8384034836788,
              21.028310937757226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11430",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02836040222114,
          "to_latitude": 21.028310937757226,
          "to_longitude": 105.8384034836788,
          "from_longitude": 105.83821084983425
        }
      },
      {
        "id": 11400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384034836788,
            21.028290761631308,
            105.83848205709926,
            21.028330480913354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83840895334981,
              21.028330480913354
            ],
            [
              105.83840890229574,
              21.02833029690767
            ],
            [
              105.83840853811739,
              21.028328997159466
            ],
            [
              105.83840819749668,
              21.028327781294827
            ],
            [
              105.83840781072271,
              21.028326397658326
            ],
            [
              105.8384055476894,
              21.028315496080637
            ],
            [
              105.8384034836788,
              21.028310937757226
            ],
            [
              105.83848205709926,
              21.028290761631308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11431",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028330480913354,
          "to_latitude": 21.028290761631308,
          "to_longitude": 105.83848205709926,
          "from_longitude": 105.83840895334981
        }
      },
      {
        "id": 11401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877006029137,
            21.027930976871783,
            105.83886719627932,
            21.028215889849715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886719627932,
              21.028215889849715
            ],
            [
              105.83886481938372,
              21.028206307545513
            ],
            [
              105.8388634991186,
              21.028205208514198
            ],
            [
              105.83884696404048,
              21.028191467165648
            ],
            [
              105.83881736184762,
              21.028098637268773
            ],
            [
              105.838838200448,
              21.028074637011063
            ],
            [
              105.83883902846073,
              21.028049659725884
            ],
            [
              105.83877006029137,
              21.027930976871783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11432",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028215889849715,
          "to_latitude": 21.027930976871783,
          "to_longitude": 105.83877006029137,
          "from_longitude": 105.83886719627932
        }
      },
      {
        "id": 11402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388634991186,
            21.028069087950758,
            105.83893199440152,
            21.028205208514198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388634991186,
              21.028205208514198
            ],
            [
              105.83893199440152,
              21.028187279440534
            ],
            [
              105.83888298970517,
              21.028069087950758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11433",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028205208514198,
          "to_latitude": 21.028069087950758,
          "to_longitude": 105.83888298970517,
          "from_longitude": 105.8388634991186
        }
      },
      {
        "id": 11403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897326887715,
            21.027960934172583,
            105.83902511602244,
            21.02816912496213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902511602244,
              21.02816912496213
            ],
            [
              105.83902233156994,
              21.02816355768892
            ],
            [
              105.83902137351994,
              21.02816168822929
            ],
            [
              105.83900871291334,
              21.028137025883854
            ],
            [
              105.83897328838265,
              21.028025780938997
            ],
            [
              105.83898996202947,
              21.02800687371013
            ],
            [
              105.83897326887715,
              21.027960934172583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.11_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11434",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02816912496213,
          "to_latitude": 21.027960934172583,
          "to_longitude": 105.83897326887715,
          "from_longitude": 105.83902511602244
        }
      },
      {
        "id": 11404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83887252004017,
            21.02798054972474,
            105.83902137351994,
            21.028177899608067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902137351994,
              21.02816168822929
            ],
            [
              105.83901115936165,
              21.02816711099396
            ],
            [
              105.83896931431634,
              21.028177899608067
            ],
            [
              105.83891322568914,
              21.028033819687412
            ],
            [
              105.83889245792176,
              21.02801838852063
            ],
            [
              105.83887252004017,
              21.02798054972474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.11_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11435",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02816168822929,
          "to_latitude": 21.02798054972474,
          "to_longitude": 105.83887252004017,
          "from_longitude": 105.83902137351994
        }
      },
      {
        "id": 11405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83906287107516,
            21.027994569592995,
            105.83911075774375,
            21.028147255911133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906287107516,
              21.028147255911133
            ],
            [
              105.83908826148189,
              21.0281358981498
            ],
            [
              105.83911075774375,
              21.028098176052826
            ],
            [
              105.83906487580813,
              21.02799977868451
            ],
            [
              105.83907754813598,
              21.027994569592995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.7_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11436",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028147255911133,
          "to_latitude": 21.027994569592995,
          "to_longitude": 105.83907754813598,
          "from_longitude": 105.83906287107516
        }
      },
      {
        "id": 11406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900348189194,
            21.027868116279283,
            105.83906487580813,
            21.02799977868451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906487580813,
              21.02799977868451
            ],
            [
              105.83900348189194,
              21.027868116279283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.7_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11437",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02799977868451,
          "to_latitude": 21.027868116279283,
          "to_longitude": 105.83900348189194,
          "from_longitude": 105.83906487580813
        }
      },
      {
        "id": 11407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388479531793,
            21.027827418854518,
            105.83898572909271,
            21.0278973676007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83898572909271,
              21.027827418854518
            ],
            [
              105.8388479531793,
              21.0278973676007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.6_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11438",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027827418854518,
          "to_latitude": 21.0278973676007,
          "to_longitude": 105.8388479531793,
          "from_longitude": 105.83898572909271
        }
      },
      {
        "id": 11408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892292547306,
            21.027692573186563,
            105.83898572909271,
            21.027827418854518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83898572909271,
              21.027827418854518
            ],
            [
              105.83896722460318,
              21.027787687068056
            ],
            [
              105.83892292547306,
              21.027692573186563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.6_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11439",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027827418854518,
          "to_latitude": 21.027692573186563,
          "to_longitude": 105.83892292547306,
          "from_longitude": 105.83898572909271
        }
      },
      {
        "id": 11409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83853105571804,
            21.027603493120925,
            105.83889797257217,
            21.027722084988397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83853105571804,
              21.027722084988397
            ],
            [
              105.83867891091143,
              21.027668347504708
            ],
            [
              105.83884542310581,
              21.027619050233387
            ],
            [
              105.83889797257217,
              21.027603493120925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.4_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11440",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027722084988397,
          "to_latitude": 21.027603493120925,
          "to_longitude": 105.83889797257217,
          "from_longitude": 105.83853105571804
        }
      },
      {
        "id": 11410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849288923348,
            21.027579147706998,
            105.83884542310581,
            21.027676588185084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83884542310581,
              21.027619050233387
            ],
            [
              105.83884472789124,
              21.027617216508045
            ],
            [
              105.83883906855606,
              21.027602293381243
            ],
            [
              105.83877766460749,
              21.027579147706998
            ],
            [
              105.83849288923348,
              21.027676588185084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.4_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11441",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027619050233387,
          "to_latitude": 21.027676588185084,
          "to_longitude": 105.83849288923348,
          "from_longitude": 105.83884542310581
        }
      },
      {
        "id": 11411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816516632737,
            21.027669374555742,
            105.83840579246193,
            21.027743926830617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816516632737,
              21.027743926830617
            ],
            [
              105.83823756962619,
              21.02771919376804
            ],
            [
              105.83834278338077,
              21.027689997776573
            ],
            [
              105.83840579246193,
              21.027669374555742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.3_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11442",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027743926830617,
          "to_latitude": 21.027669374555742,
          "to_longitude": 105.83840579246193,
          "from_longitude": 105.83816516632737
        }
      },
      {
        "id": 11412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793963234018,
            21.027743926830617,
            105.83816598637367,
            21.027830926683087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793963234018,
              21.027830926683087
            ],
            [
              105.83797274754197,
              21.027819874246443
            ],
            [
              105.83802907257001,
              21.027800955965585
            ],
            [
              105.8380372768826,
              21.027793774654267
            ],
            [
              105.83804967594516,
              21.02778493873976
            ],
            [
              105.83811856105933,
              21.027759148230402
            ],
            [
              105.83816516632737,
              21.027743926830617
            ],
            [
              105.83816598637367,
              21.02774692510092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.3_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11443",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027830926683087,
          "to_latitude": 21.02774692510092,
          "to_longitude": 105.83816598637367,
          "from_longitude": 105.83793963234018
        }
      },
      {
        "id": 11413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377516130152,
            21.02764753038573,
            105.83823534779651,
            21.027793798510178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377516130152,
              21.027692810957795
            ],
            [
              105.83775164957497,
              21.02769315580878
            ],
            [
              105.83775317281814,
              21.02770699380658
            ],
            [
              105.8377967090413,
              21.027793798510178
            ],
            [
              105.83798668243443,
              21.027730582525148
            ],
            [
              105.83823534779651,
              21.02764753038573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.4_Ngõ 36, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11444",
          "diaChiLapD": "Ngõ 36, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027692810957795,
          "to_latitude": 21.02764753038573,
          "to_longitude": 105.83823534779651,
          "from_longitude": 105.8377516130152
        }
      },
      {
        "id": 11414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829881064005,
            21.027453679544188,
            105.83882484668243,
            21.027628056576727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882484668243,
              21.027453679544188
            ],
            [
              105.83881177122579,
              21.027458014478686
            ],
            [
              105.83829881064005,
              21.027628056576727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.3_Ngõ 36, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11445",
          "diaChiLapD": "Ngõ 36, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027453679544188,
          "to_latitude": 21.027628056576727,
          "to_longitude": 105.83829881064005,
          "from_longitude": 105.83882484668243
        }
      },
      {
        "id": 11415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859308414587,
            21.027533694421027,
            105.83882792072531,
            21.027623758753652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859856328968,
              21.027623758753652
            ],
            [
              105.83859430888867,
              21.027614576798538
            ],
            [
              105.83859308414587,
              21.027612151362927
            ],
            [
              105.83860355671578,
              21.027608729793922
            ],
            [
              105.8387434249216,
              21.02756492464249
            ],
            [
              105.83882792072531,
              21.027533694421027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.1_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11446",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027623758753652,
          "to_latitude": 21.027533694421027,
          "to_longitude": 105.83882792072531,
          "from_longitude": 105.83859856328968
        }
      },
      {
        "id": 11416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384258926035,
            21.027612151362927,
            105.83859308414587,
            21.027666249657642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384258926035,
              21.027666249657642
            ],
            [
              105.83857020444815,
              21.027619175651253
            ],
            [
              105.83859308414587,
              21.027612151362927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.1_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11447",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027666249657642,
          "to_latitude": 21.027612151362927,
          "to_longitude": 105.83859308414587,
          "from_longitude": 105.8384258926035
        }
      },
      {
        "id": 11417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721784285986,
            21.027594078748024,
            105.8372779145456,
            21.027715521593127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721976920624,
              21.027597658193432
            ],
            [
              105.8372189517982,
              21.027596334480187
            ],
            [
              105.83721784285986,
              21.027594659042094
            ],
            [
              105.83721921337329,
              21.027594078748024
            ],
            [
              105.83722131077518,
              21.0275968729288
            ],
            [
              105.83722375322719,
              21.027600127860836
            ],
            [
              105.83722793253375,
              21.027605697330863
            ],
            [
              105.83724605033214,
              21.027627092187824
            ],
            [
              105.8372779145456,
              21.027715521593127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.3_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11448",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027597658193432,
          "to_latitude": 21.027715521593127,
          "to_longitude": 105.8372779145456,
          "from_longitude": 105.83721976920624
        }
      },
      {
        "id": 11418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722375322719,
            21.027550631440967,
            105.83734258452769,
            21.027600127860836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722375322719,
              21.027600127860836
            ],
            [
              105.8372358104684,
              21.027594331226073
            ],
            [
              105.83734258452769,
              21.027550631440967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.3_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11449",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027600127860836,
          "to_latitude": 21.027550631440967,
          "to_longitude": 105.83734258452769,
          "from_longitude": 105.83722375322719
        }
      },
      {
        "id": 11419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716635171203,
            21.02774669463438,
            105.8372325814307,
            21.02792218450987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716635171203,
              21.02774669463438
            ],
            [
              105.83720882279462,
              21.027834211964826
            ],
            [
              105.83721007132436,
              21.027838833028603
            ],
            [
              105.83721999973878,
              21.027875596801337
            ],
            [
              105.8372325814307,
              21.02792218450987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.4_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11450",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02774669463438,
          "to_latitude": 21.02792218450987,
          "to_longitude": 105.8372325814307,
          "from_longitude": 105.83716635171203
        }
      },
      {
        "id": 11420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372325814307,
            21.02792218450987,
            105.83727918536832,
            21.028096839763755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83727918536832,
              21.028096839763755
            ],
            [
              105.83724947497718,
              21.027985495835456
            ],
            [
              105.8372325814307,
              21.02792218450987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.4_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11451",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028096839763755,
          "to_latitude": 21.02792218450987,
          "to_longitude": 105.8372325814307,
          "from_longitude": 105.83727918536832
        }
      },
      {
        "id": 11421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721999973878,
            21.02783033572039,
            105.83734210652297,
            21.027875596801337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721999973878,
              21.027875596801337
            ],
            [
              105.83722017266464,
              21.02787555440168
            ],
            [
              105.83730995420045,
              21.02785343590007
            ],
            [
              105.83731605411784,
              21.027837035853402
            ],
            [
              105.83734210652297,
              21.02783033572039
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.4_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11452",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027875596801337,
          "to_latitude": 21.02783033572039,
          "to_longitude": 105.83734210652297,
          "from_longitude": 105.83721999973878
        }
      },
      {
        "id": 11422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83724947497718,
            21.02797036665435,
            105.83732710976815,
            21.027985495835456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724947497718,
              21.027985495835456
            ],
            [
              105.83732710976815,
              21.02797036665435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.4_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11453",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027985495835456,
          "to_latitude": 21.02797036665435,
          "to_longitude": 105.83732710976815,
          "from_longitude": 105.83724947497718
        }
      },
      {
        "id": 11423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758520798983,
            21.027615737736056,
            105.83766825387087,
            21.02776786040229
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766825387087,
              21.027615737736056
            ],
            [
              105.83766784212149,
              21.0276162527949
            ],
            [
              105.83766666783816,
              21.02762354307139
            ],
            [
              105.8376576770613,
              21.027628742955574
            ],
            [
              105.83758520798983,
              21.027669779268763
            ],
            [
              105.837586841269,
              21.02768248047225
            ],
            [
              105.83762423177892,
              21.02776786040229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.7_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11454",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027615737736056,
          "to_latitude": 21.02776786040229,
          "to_longitude": 105.83762423177892,
          "from_longitude": 105.83766825387087
        }
      },
      {
        "id": 11424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375285991185,
            21.02755301215089,
            105.83758520798983,
            21.027669779268763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758520798983,
              21.027669779268763
            ],
            [
              105.8375285991185,
              21.02755301215089
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.7_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11455",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027669779268763,
          "to_latitude": 21.02755301215089,
          "to_longitude": 105.8375285991185,
          "from_longitude": 105.83758520798983
        }
      },
      {
        "id": 11425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84092141798496,
            21.028876649153982,
            105.84113225120883,
            21.028922063796305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84113225120883,
              21.028876649153982
            ],
            [
              105.84108598310283,
              21.028887915232907
            ],
            [
              105.84104400898457,
              21.028899384549632
            ],
            [
              105.84101781242177,
              21.028904396034214
            ],
            [
              105.84101409627299,
              21.028905106282227
            ],
            [
              105.84093002412705,
              21.028921189367768
            ],
            [
              105.84092141798496,
              21.028922063796305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11457",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 22.505,
          "from_latitude": 21.028876649153982,
          "to_latitude": 21.028922063796305,
          "to_longitude": 105.84092141798496,
          "from_longitude": 105.84113225120883
        }
      },
      {
        "id": 11426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84112858911433,
            21.02821453788644,
            105.84146796351311,
            21.028334950043792
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146796351311,
              21.028331260416284
            ],
            [
              105.84115342200623,
              21.028334950043792
            ],
            [
              105.84112858911433,
              21.02821453788644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.4_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11458",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028331260416284,
          "to_latitude": 21.02821453788644,
          "to_longitude": 105.84112858911433,
          "from_longitude": 105.84146796351311
        }
      },
      {
        "id": 11427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84078248537077,
            21.027768431884702,
            105.84081566655908,
            21.02809091632276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8407952850659,
              21.027768431884702
            ],
            [
              105.84079564955316,
              21.02777166994076
            ],
            [
              105.8407969004197,
              21.027785435185624
            ],
            [
              105.84078248537077,
              21.02780100033398
            ],
            [
              105.84081130796925,
              21.02794696004226
            ],
            [
              105.84079789692156,
              21.027992337580187
            ],
            [
              105.8408082904833,
              21.028051484833227
            ],
            [
              105.84081566655908,
              21.02809091632276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.5_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11459",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": 36.895,
          "from_latitude": 21.027768431884702,
          "to_latitude": 21.02809091632276,
          "to_longitude": 105.84081566655908,
          "from_longitude": 105.8407952850659
        }
      },
      {
        "id": 11428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8407969004197,
            21.027754287325422,
            105.84118435177055,
            21.02805221446617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8407969004197,
              21.027785435185624
            ],
            [
              105.84107739866724,
              21.027754287325422
            ],
            [
              105.84109315664443,
              21.027828769165612
            ],
            [
              105.84111528488077,
              21.02784754609395
            ],
            [
              105.84115752967517,
              21.0279226537862
            ],
            [
              105.84118435177055,
              21.02805221446617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.5_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11460",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": 64.887,
          "from_latitude": 21.027785435185624,
          "to_latitude": 21.02805221446617,
          "to_longitude": 105.84118435177055,
          "from_longitude": 105.8407969004197
        }
      },
      {
        "id": 11429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84072279507784,
            21.027822197240116,
            105.84078071031043,
            21.028092168114465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84076358028955,
              21.027822197240116
            ],
            [
              105.84077464440772,
              21.02787070430371
            ],
            [
              105.84078071031043,
              21.027919146967548
            ],
            [
              105.84077296389265,
              21.027992676849237
            ],
            [
              105.84072279507784,
              21.028028326651544
            ],
            [
              105.84074659968725,
              21.028092168114465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.1_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11461",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 30.562,
          "from_latitude": 21.027822197240116,
          "to_latitude": 21.028092168114465,
          "to_longitude": 105.84074659968725,
          "from_longitude": 105.84076358028955
        }
      },
      {
        "id": 11430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84072540614656,
            21.028152140934345,
            105.84092680516872,
            21.02821863845496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84072540614656,
              21.028187086700097
            ],
            [
              105.84073422878545,
              21.028185712633505
            ],
            [
              105.8407370523038,
              21.02818519828847
            ],
            [
              105.84075975762292,
              21.028177104253245
            ],
            [
              105.84079717560155,
              21.02816687902523
            ],
            [
              105.84089092410998,
              21.028152140934345
            ],
            [
              105.84091852757375,
              21.028172437123526
            ],
            [
              105.84092680516872,
              21.02821863845496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.2_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11462",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028187086700097,
          "to_latitude": 21.02821863845496,
          "to_longitude": 105.84092680516872,
          "from_longitude": 105.84072540614656
        }
      },
      {
        "id": 11431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8407345297165,
            21.02818519828847,
            105.84075296990144,
            21.02830778932543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8407370523038,
              21.02818519828847
            ],
            [
              105.8407345297165,
              21.028211714311045
            ],
            [
              105.84075296990144,
              21.02830778932543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.2_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11463",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 13.756,
          "from_latitude": 21.02818519828847,
          "to_latitude": 21.02830778932543,
          "to_longitude": 105.84075296990144,
          "from_longitude": 105.8407370523038
        }
      },
      {
        "id": 11432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84077234654863,
            21.02840593257931,
            105.84104910263409,
            21.028635377594547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077234654863,
              21.028635377594547
            ],
            [
              105.84077916188726,
              21.028634762221134
            ],
            [
              105.8407894982373,
              21.028633636278315
            ],
            [
              105.84079321795619,
              21.028633220468116
            ],
            [
              105.84091935077394,
              21.02862097153492
            ],
            [
              105.84104910263409,
              21.028603759439743
            ],
            [
              105.84101772180401,
              21.02840593257931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.6_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11464",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": 51.131,
          "from_latitude": 21.028635377594547,
          "to_latitude": 21.02840593257931,
          "to_longitude": 105.84101772180401,
          "from_longitude": 105.84077234654863
        }
      },
      {
        "id": 11433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84059245657288,
            21.028443175192855,
            105.84076930883181,
            21.028515195353545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84076930883181,
              21.028443175192855
            ],
            [
              105.84076798963477,
              21.028443641455635
            ],
            [
              105.84074668358991,
              21.028471069765008
            ],
            [
              105.84061257313992,
              21.028489533665557
            ],
            [
              105.84059547404983,
              21.02850236450969
            ],
            [
              105.84059245657288,
              21.028515195353545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.4_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11465",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 21.726,
          "from_latitude": 21.028443175192855,
          "to_latitude": 21.028515195353545,
          "to_longitude": 105.84059245657288,
          "from_longitude": 105.84076930883181
        }
      },
      {
        "id": 11434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84075238332852,
            21.028555565561476,
            105.84079321795619,
            21.028633220468116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84075238332852,
              21.028555565561476
            ],
            [
              105.84075573608371,
              21.028614086696926
            ],
            [
              105.84078959897666,
              21.02861346080285
            ],
            [
              105.84079321795619,
              21.028633220468116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.6_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11466",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": 12.23,
          "from_latitude": 21.028555565561476,
          "to_latitude": 21.028633220468116,
          "to_longitude": 105.84079321795619,
          "from_longitude": 105.84075238332852
        }
      },
      {
        "id": 11435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84019761941802,
            21.028703829932912,
            105.84048681256473,
            21.028734030920397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019786998682,
              21.028734030920397
            ],
            [
              105.84019761941802,
              21.028710610702085
            ],
            [
              105.84048681256473,
              21.028703829932912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.7_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11467",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028734030920397,
          "to_latitude": 21.028703829932912,
          "to_longitude": 105.84048681256473,
          "from_longitude": 105.84019786998682
        }
      },
      {
        "id": 11436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401997286715,
            21.02887673352014,
            105.8403397767608,
            21.028923338737673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401997286715,
              21.02890806905072
            ],
            [
              105.84031708404189,
              21.02887673352014
            ],
            [
              105.8403397767608,
              21.028923338737673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.7_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11468",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02890806905072,
          "to_latitude": 21.028923338737673,
          "to_longitude": 105.8403397767608,
          "from_longitude": 105.8401997286715
        }
      },
      {
        "id": 11437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84018341336741,
            21.028734030920397,
            105.8402001979471,
            21.028951112497325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8402001979471,
              21.028951112497325
            ],
            [
              105.8401997286715,
              21.02890806905072
            ],
            [
              105.84019786998682,
              21.028734030920397
            ],
            [
              105.8401905914359,
              21.02873439023788
            ],
            [
              105.84018507301303,
              21.02873502717968
            ],
            [
              105.84018341336741,
              21.028734998347915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.7_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11469",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028951112497325,
          "to_latitude": 21.028734998347915,
          "to_longitude": 105.84018341336741,
          "from_longitude": 105.8402001979471
        }
      },
      {
        "id": 11438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84020882431442,
            21.028568122470926,
            105.8403243709269,
            21.028660542114217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84020882431442,
              21.028660542114217
            ],
            [
              105.84021197809346,
              21.02856894521232
            ],
            [
              105.8403243709269,
              21.028568122470926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.6_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11470",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028660542114217,
          "to_latitude": 21.028568122470926,
          "to_longitude": 105.8403243709269,
          "from_longitude": 105.84020882431442
        }
      },
      {
        "id": 11439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84020782569883,
            21.028416999484406,
            105.8402140785723,
            21.028514610529808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84020782569883,
              21.028416999484406
            ],
            [
              105.8402088967225,
              21.02845566836422
            ],
            [
              105.8402140785723,
              21.028514610529808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.6_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11471",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028416999484406,
          "to_latitude": 21.028514610529808,
          "to_longitude": 105.8402140785723,
          "from_longitude": 105.84020782569883
        }
      },
      {
        "id": 11440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84019112481316,
            21.028514610529808,
            105.84056074052997,
            21.028557123792037
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84056074052997,
              21.028556920315943
            ],
            [
              105.84046877655037,
              21.028557123792037
            ],
            [
              105.84041339750397,
              21.028514834058143
            ],
            [
              105.8402140785723,
              21.028514610529808
            ],
            [
              105.84019290125737,
              21.02851573942301
            ],
            [
              105.84019112481316,
              21.028515811426026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.6_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11472",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028556920315943,
          "to_latitude": 21.028515811426026,
          "to_longitude": 105.84019112481316,
          "from_longitude": 105.84056074052997
        }
      },
      {
        "id": 11441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84048560811398,
            21.027817109998857,
            105.84065277919574,
            21.028165183843438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84064112614423,
              21.028165183843438
            ],
            [
              105.84065277919574,
              21.02814634192208
            ],
            [
              105.84060577403842,
              21.028012517143196
            ],
            [
              105.84058070851053,
              21.02801230505073
            ],
            [
              105.84053215602754,
              21.02790551984758
            ],
            [
              105.84049996951667,
              21.027845120741176
            ],
            [
              105.84049460510118,
              21.02783541932806
            ],
            [
              105.84048783777182,
              21.027822250137323
            ],
            [
              105.84048560811398,
              21.027817109998857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.1B_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11473",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": 44.396,
          "from_latitude": 21.028165183843438,
          "to_latitude": 21.027817109998857,
          "to_longitude": 105.84048560811398,
          "from_longitude": 105.84064112614423
        }
      },
      {
        "id": 11442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84035018486637,
            21.027787464739983,
            105.84075853516059,
            21.02788478699539
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84035018486637,
              21.02788478699539
            ],
            [
              105.84038672996705,
              21.02787383379049
            ],
            [
              105.84039317133714,
              21.02787563623008
            ],
            [
              105.84039678825056,
              21.027871017251893
            ],
            [
              105.84048027200471,
              21.02784128711753
            ],
            [
              105.84048328949069,
              21.027842538913
            ],
            [
              105.84048865390615,
              21.027841913015692
            ],
            [
              105.84049971802433,
              21.027837844680988
            ],
            [
              105.8405010591282,
              21.027833776346167
            ],
            [
              105.84058410655001,
              21.02781574166697
            ],
            [
              105.84075853516059,
              21.027787464739983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.1A_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11474",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 44.477,
          "from_latitude": 21.02788478699539,
          "to_latitude": 21.027787464739983,
          "to_longitude": 105.84075853516059,
          "from_longitude": 105.84035018486637
        }
      },
      {
        "id": 11443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008841611447,
            21.027939445231237,
            105.84017951335785,
            21.028213929712624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84017951335785,
              21.028213929712624
            ],
            [
              105.84014567281153,
              21.028105163783287
            ],
            [
              105.84014930433706,
              21.02809210421382
            ],
            [
              105.84009953369727,
              21.027955848824366
            ],
            [
              105.84009896234828,
              21.027955006230908
            ],
            [
              105.84008961564805,
              21.02794286071146
            ],
            [
              105.84008841611447,
              21.027939445231237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11477",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028213929712624,
          "to_latitude": 21.027939445231237,
          "to_longitude": 105.84008841611447,
          "from_longitude": 105.84017951335785
        }
      },
      {
        "id": 11444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84004500505667,
            21.027955848824366,
            105.84010667437786,
            21.028144256437052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84009953369727,
              21.027955848824366
            ],
            [
              105.84009919852528,
              21.027955950737912
            ],
            [
              105.84004500505667,
              21.02797237801665
            ],
            [
              105.84010667437786,
              21.028144256437052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11478",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027955848824366,
          "to_latitude": 21.028144256437052,
          "to_longitude": 105.84010667437786,
          "from_longitude": 105.84009953369727
        }
      },
      {
        "id": 11445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400853224392,
            21.028113188108186,
            105.84017006447868,
            21.028304362103853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401536908712,
              21.02829098745112
            ],
            [
              105.84015371582218,
              21.028290976489174
            ],
            [
              105.84016173381043,
              21.028287983337087
            ],
            [
              105.840166971312,
              21.028285802371098
            ],
            [
              105.84017006447868,
              21.02829460615085
            ],
            [
              105.84015677101502,
              21.02829969916681
            ],
            [
              105.84014460358577,
              21.028304362103853
            ],
            [
              105.84011822850019,
              21.02820576118155
            ],
            [
              105.8400853224392,
              21.028113188108186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.1_Ngõ 46, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11479",
          "diaChiLapD": "Ngõ 46, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02829098745112,
          "to_latitude": 21.028113188108186,
          "to_longitude": 105.8400853224392,
          "from_longitude": 105.8401536908712
        }
      },
      {
        "id": 11446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399226190383,
            21.02797669016057,
            105.84003363505326,
            21.028010488597175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84003363505326,
              21.02797669016057
            ],
            [
              105.83998300834843,
              21.027994215276934
            ],
            [
              105.83995853320054,
              21.028001726040202
            ],
            [
              105.83993104055772,
              21.028010488597175
            ],
            [
              105.83992407171792,
              21.027992569897883
            ],
            [
              105.8399226190383,
              21.027988957861112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.1_Ngõ 46, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11480",
          "diaChiLapD": "Ngõ 46, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02797669016057,
          "to_latitude": 21.027988957861112,
          "to_longitude": 105.8399226190383,
          "from_longitude": 105.84003363505326
        }
      },
      {
        "id": 11447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941177816575,
            21.02814145136968,
            105.83949451110846,
            21.028393224062416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83941177816575,
              21.02814145136968
            ],
            [
              105.83941457460514,
              21.028149843856816
            ],
            [
              105.83944938699594,
              21.02825427239038
            ],
            [
              105.83946970067338,
              21.02831749071201
            ],
            [
              105.83949451110846,
              21.028393224062416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11481",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 29.173,
          "from_latitude": 21.02814145136968,
          "to_latitude": 21.028393224062416,
          "to_longitude": 105.83949451110846,
          "from_longitude": 105.83941177816575
        }
      },
      {
        "id": 11448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926407806356,
            21.02817213060817,
            105.83937766659494,
            21.02844049630854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83937766659494,
              21.02817213060817
            ],
            [
              105.83926407806356,
              21.028205738366765
            ],
            [
              105.83933644519776,
              21.02844049630854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11482",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02817213060817,
          "to_latitude": 21.02844049630854,
          "to_longitude": 105.83933644519776,
          "from_longitude": 105.83937766659494
        }
      },
      {
        "id": 11449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904939283681,
            21.028249378829706,
            105.83913511691996,
            21.028590165778812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390496781843,
              21.028249378829706
            ],
            [
              105.83905094876606,
              21.028252429065294
            ],
            [
              105.8390572146245,
              21.028306952786842
            ],
            [
              105.83904939283681,
              21.028308738214136
            ],
            [
              105.83908488055802,
              21.02841440632857
            ],
            [
              105.83913511691996,
              21.028590165778812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.12_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11483",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028249378829706,
          "to_latitude": 21.028590165778812,
          "to_longitude": 105.83913511691996,
          "from_longitude": 105.8390496781843
        }
      },
      {
        "id": 11450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83881524477837,
            21.028306889628364,
            105.83889959995065,
            21.02869960887279
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83881524477837,
              21.028306889628364
            ],
            [
              105.83881803935682,
              21.028315290823056
            ],
            [
              105.8388433211911,
              21.02845041523347
            ],
            [
              105.83889959995065,
              21.02869960887279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.9a_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11484",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": 44.367,
          "from_latitude": 21.028306889628364,
          "to_latitude": 21.02869960887279,
          "to_longitude": 105.83889959995065,
          "from_longitude": 105.83881524477837
        }
      },
      {
        "id": 11451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83898019762572,
            21.02826147304317,
            105.83918832031686,
            21.028532172753263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918832031686,
              21.028520828411384
            ],
            [
              105.83915076939051,
              21.02839251993043
            ],
            [
              105.83911925343608,
              21.028292376648363
            ],
            [
              105.83908648016362,
              21.02826147304317
            ],
            [
              105.8390569758605,
              21.028268670845645
            ],
            [
              105.83905328285722,
              21.0282727396662
            ],
            [
              105.83904725285518,
              21.02827117442855
            ],
            [
              105.83900559482238,
              21.028281736420745
            ],
            [
              105.83898019762572,
              21.02828713477126
            ],
            [
              105.83905027034115,
              21.028532172753263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11485",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": 70.817,
          "from_latitude": 21.028520828411384,
          "to_latitude": 21.028532172753263,
          "to_longitude": 105.83905027034115,
          "from_longitude": 105.83918832031686
        }
      },
      {
        "id": 11452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915328322703,
            21.028748810401797,
            105.83944899679679,
            21.028811712716653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83944899679679,
              21.02880138547233
            ],
            [
              105.83942351581425,
              21.028752878711646
            ],
            [
              105.83940809310626,
              21.028748810401797
            ],
            [
              105.83930985720055,
              21.028769777842477
            ],
            [
              105.83920491574852,
              21.02880232431267
            ],
            [
              105.83917608200181,
              21.028808583248725
            ],
            [
              105.83915328322703,
              21.028811712716653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.3_Yên Thế",
          "maTaiSan": "04.O14.DODV.11486",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": 35.084,
          "from_latitude": 21.02880138547233,
          "to_latitude": 21.028811712716653,
          "to_longitude": 105.83915328322703,
          "from_longitude": 105.83944899679679
        }
      },
      {
        "id": 11453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944899679679,
            21.028774555642993,
            105.83949968984822,
            21.02880138547233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83949968984822,
              21.028774555642993
            ],
            [
              105.8394990091455,
              21.028774800164012
            ],
            [
              105.839492163604,
              21.028778931537335
            ],
            [
              105.83946441949578,
              21.02879387474934
            ],
            [
              105.83944899679679,
              21.02880138547233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.3_Yên Thế",
          "maTaiSan": "04.O14.DODV.11487",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": 6.053,
          "from_latitude": 21.028774555642993,
          "to_latitude": 21.02880138547233,
          "to_longitude": 105.83944899679679,
          "from_longitude": 105.83949968984822
        }
      },
      {
        "id": 11454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946374888546,
            21.028521923725652,
            105.83954756791782,
            21.028691541108433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83951336975562,
              21.028521923725652
            ],
            [
              105.83952946301108,
              21.02858826855557
            ],
            [
              105.83954756791782,
              21.028663375873684
            ],
            [
              105.83946374888546,
              21.028691541108433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.5_Yên Thế",
          "maTaiSan": "04.O14.DODV.11488",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028521923725652,
          "to_latitude": 21.028691541108433,
          "to_longitude": 105.83946374888546,
          "from_longitude": 105.83951336975562
        }
      },
      {
        "id": 11455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920315175426,
            21.028622794031403,
            105.83948062177836,
            21.028762267118736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947617536772,
              21.028732674601475
            ],
            [
              105.83948062177836,
              21.028731292397236
            ],
            [
              105.83946374888546,
              21.028691541108433
            ],
            [
              105.83943558569518,
              21.028702181306805
            ],
            [
              105.8392645948663,
              21.028762267118736
            ],
            [
              105.83920315175426,
              21.028622794031403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.5_Yên Thế",
          "maTaiSan": "04.O14.DODV.11489",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 44.071,
          "from_latitude": 21.028732674601475,
          "to_latitude": 21.028622794031403,
          "to_longitude": 105.83920315175426,
          "from_longitude": 105.83947617536772
        }
      },
      {
        "id": 11456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947745484478,
            21.028636469749227,
            105.83966110901036,
            21.02873542130673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947745484478,
              21.02873542130673
            ],
            [
              105.83948198428745,
              21.02873271818136
            ],
            [
              105.83950297618492,
              21.02872033223231
            ],
            [
              105.8395026409067,
              21.0286984259437
            ],
            [
              105.83962266976154,
              21.028654926303307
            ],
            [
              105.83966110901036,
              21.028644876035223
            ],
            [
              105.83965923885857,
              21.028636469749227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.4_Yên Thế",
          "maTaiSan": "04.O14.DODV.11490",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 24.034,
          "from_latitude": 21.02873542130673,
          "to_latitude": 21.028636469749227,
          "to_longitude": 105.83965923885857,
          "from_longitude": 105.83947745484478
        }
      },
      {
        "id": 11457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965923885857,
            21.028456705035165,
            105.83973682781588,
            21.028636469749227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83965923885857,
              21.028636469749227
            ],
            [
              105.83973196978542,
              21.028621440959448
            ],
            [
              105.83969501925921,
              21.028486032287347
            ],
            [
              105.83970467864022,
              21.028465037958114
            ],
            [
              105.83973682781588,
              21.028456705035165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.4_Yên Thế",
          "maTaiSan": "04.O14.DODV.11492",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": 29.216,
          "from_latitude": 21.028636469749227,
          "to_latitude": 21.028456705035165,
          "to_longitude": 105.83973682781588,
          "from_longitude": 105.83965923885857
        }
      },
      {
        "id": 11458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952239411255,
            21.02879029149011,
            105.83955866005019,
            21.028893466192994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83952239411255,
              21.02879029149011
            ],
            [
              105.83955866005019,
              21.028891058373205
            ],
            [
              105.839551390751,
              21.028893243030275
            ],
            [
              105.83955064547281,
              21.028893466192994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.1_Yên Thế",
          "maTaiSan": "04.O14.DODV.11493",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02879029149011,
          "to_latitude": 21.028893466192994,
          "to_longitude": 105.83955064547281,
          "from_longitude": 105.83952239411255
        }
      },
      {
        "id": 11459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83955866005019,
            21.028891058373198,
            105.83971922992298,
            21.02924483041475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83955866005019,
              21.028891058373198
            ],
            [
              105.839590162734,
              21.028963770292226
            ],
            [
              105.83964395313943,
              21.029087926274254
            ],
            [
              105.83968033789277,
              21.02918411890568
            ],
            [
              105.83971922992298,
              21.02924483041475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.1_Yên Thế",
          "maTaiSan": "04.O14.DODV.11494",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": 42.677,
          "from_latitude": 21.028891058373198,
          "to_latitude": 21.02924483041475,
          "to_longitude": 105.83971922992298,
          "from_longitude": 105.83955866005019
        }
      },
      {
        "id": 11460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83984998760879,
            21.029144061813522,
            105.83987080583057,
            21.02921362104186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987080583057,
              21.02921362104186
            ],
            [
              105.83984998760879,
              21.029144061813522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.3_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11495.1",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02921362104186,
          "to_latitude": 21.029144061813522,
          "to_longitude": 105.83984998760879,
          "from_longitude": 105.83987080583057
        }
      },
      {
        "id": 11461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83977656221901,
            21.029156892602977,
            105.83987958971227,
            21.029238602620488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83987958971227,
              21.029210828265683
            ],
            [
              105.83986080696971,
              21.02921680011243
            ],
            [
              105.83983310718796,
              21.029224823862158
            ],
            [
              105.83978791543659,
              21.029238602620488
            ],
            [
              105.83977900866174,
              21.02920745834365
            ],
            [
              105.83978058553065,
              21.029181302392683
            ],
            [
              105.83978058553065,
              21.029166906876377
            ],
            [
              105.83977656221901,
              21.029156892602977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.3_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11496",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": 19.263,
          "from_latitude": 21.029210828265683,
          "to_latitude": 21.029156892602977,
          "to_longitude": 105.83977656221901,
          "from_longitude": 105.83987958971227
        }
      },
      {
        "id": 11462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015835356212,
            21.028249354942453,
            105.84039016525604,
            21.02825301914127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015835356212,
              21.02825301914127
            ],
            [
              105.84018244885631,
              21.028249354942453
            ],
            [
              105.84039016525604,
              21.02825239119912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.2_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11497",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02825301914127,
          "to_latitude": 21.02825239119912,
          "to_longitude": 105.84039016525604,
          "from_longitude": 105.84015835356212
        }
      },
      {
        "id": 11463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015835356212,
            21.02825301914127,
            105.84046523613775,
            21.028339006490903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015835356212,
              21.02825301914127
            ],
            [
              105.84019168840382,
              21.028339006490903
            ],
            [
              105.84029986664045,
              21.028335468734586
            ],
            [
              105.84046523613775,
              21.028337134417644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.2_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11498",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02825301914127,
          "to_latitude": 21.028337134417644,
          "to_longitude": 105.84046523613775,
          "from_longitude": 105.84015835356212
        }
      },
      {
        "id": 11464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83795159566084,
            21.02754147727465,
            105.8383066863467,
            21.027648283962765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8383066863467,
              21.02754147727465
            ],
            [
              105.83803419662286,
              21.02762343841332
            ],
            [
              105.83795159566084,
              21.027648283962765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.3_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11499",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02754147727465,
          "to_latitude": 21.027648283962765,
          "to_longitude": 105.83795159566084,
          "from_longitude": 105.8383066863467
        }
      },
      {
        "id": 11465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843783212362,
            21.027047837241767,
            105.83859977148755,
            21.027218442394453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859977148755,
              21.027047837241767
            ],
            [
              105.83843783212362,
              21.02710330149922
            ],
            [
              105.83847156542963,
              21.027218442394453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11500",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027047837241767,
          "to_latitude": 21.027218442394453,
          "to_longitude": 105.83847156542963,
          "from_longitude": 105.83859977148755
        }
      },
      {
        "id": 11466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83860980677719,
            21.027069606904508,
            105.83872281761792,
            21.027314749610532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83860980677719,
              21.027069606904508
            ],
            [
              105.83871619655004,
              21.02730038760701
            ],
            [
              105.83872281761792,
              21.027314749610532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.2.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11501",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027069606904508,
          "to_latitude": 21.027314749610532,
          "to_longitude": 105.83872281761792,
          "from_longitude": 105.83860980677719
        }
      },
      {
        "id": 11467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807153921079,
            21.027086383739043,
            105.83813303520307,
            21.02722012709314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809820395295,
              21.027086383739043
            ],
            [
              105.83813303520307,
              21.0272021398121
            ],
            [
              105.83807153921079,
              21.02722012709314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.9_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11502",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027086383739043,
          "to_latitude": 21.02722012709314,
          "to_longitude": 105.83807153921079,
          "from_longitude": 105.83809820395295
        }
      },
      {
        "id": 11468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83785858395234,
            21.027086383739043,
            105.83809820395295,
            21.027144942656637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83809820395295,
              21.027086383739043
            ],
            [
              105.83804973402977,
              21.027098594831468
            ],
            [
              105.83803751253053,
              21.027101324987104
            ],
            [
              105.83793593860688,
              21.027124017706466
            ],
            [
              105.83785858395234,
              21.027144942656637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.9_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11503",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027086383739043,
          "to_latitude": 21.027144942656637,
          "to_longitude": 105.83785858395234,
          "from_longitude": 105.83809820395295
        }
      },
      {
        "id": 11469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774088561772,
            21.026930954614503,
            105.83803878417713,
            21.027090288563485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803878417713,
              21.027090288563485
            ],
            [
              105.8380385145092,
              21.02708954563684
            ],
            [
              105.83801030047296,
              21.02701137887115
            ],
            [
              105.8379927242034,
              21.027005806793557
            ],
            [
              105.83797188906382,
              21.026930954614503
            ],
            [
              105.83790769864929,
              21.02694103836324
            ],
            [
              105.83774088561772,
              21.026967244393504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.8_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11504",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027090288563485,
          "to_latitude": 21.026967244393504,
          "to_longitude": 105.83774088561772,
          "from_longitude": 105.83803878417713
        }
      },
      {
        "id": 11470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790769864929,
            21.02694103836324,
            105.8379110272803,
            21.02698459614132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790769864929,
              21.02694103836324
            ],
            [
              105.83790780137238,
              21.02694237733656
            ],
            [
              105.8379110272803,
              21.02698459614132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.8_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11505",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02694103836324,
          "to_latitude": 21.02698459614132,
          "to_longitude": 105.8379110272803,
          "from_longitude": 105.83790769864929
        }
      },
      {
        "id": 11471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769493947746,
            21.027138854827975,
            105.83783220720902,
            21.02718249828941
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769493947746,
              21.02718249828941
            ],
            [
              105.83783106060491,
              21.027139219056618
            ],
            [
              105.83783220720902,
              21.027138854827975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.7_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11506",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02718249828941,
          "to_latitude": 21.027138854827975,
          "to_longitude": 105.83783220720902,
          "from_longitude": 105.83769493947746
        }
      },
      {
        "id": 11472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783220720902,
            21.027100178546767,
            105.83798496019455,
            21.027138854827975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783220720902,
              21.027138854827975
            ],
            [
              105.83792719459348,
              21.02711174354277
            ],
            [
              105.83798496019455,
              21.027100178546767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.7_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11507",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027138854827975,
          "to_latitude": 21.027100178546767,
          "to_longitude": 105.83798496019455,
          "from_longitude": 105.83783220720902
        }
      },
      {
        "id": 11473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778970362145,
            21.027021878321154,
            105.83783487788979,
            21.027145420744905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783487788979,
              21.027145420744905
            ],
            [
              105.83783316410535,
              21.02714121023471
            ],
            [
              105.83783220720902,
              21.027138854827975
            ],
            [
              105.83778970362145,
              21.027034359028097
            ],
            [
              105.83783406426173,
              21.027021878321154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.7_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11508",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027145420744905,
          "to_latitude": 21.027021878321154,
          "to_longitude": 105.83783406426173,
          "from_longitude": 105.83783487788979
        }
      },
      {
        "id": 11474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778814636214,
            21.02716074564027,
            105.83783716323995,
            21.0272773685331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778814636214,
              21.02716074564027
            ],
            [
              105.83779086459045,
              21.027167212872378
            ],
            [
              105.83783716323995,
              21.0272773685331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.6_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11509",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02716074564027,
          "to_latitude": 21.0272773685331,
          "to_longitude": 105.83783716323995,
          "from_longitude": 105.83778814636214
        }
      },
      {
        "id": 11475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758768279692,
            21.027167212872378,
            105.83779086459045,
            21.027374894605305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779086459045,
              21.027167212872378
            ],
            [
              105.83758768279692,
              21.027229458589254
            ],
            [
              105.83760389441571,
              21.0272855528779
            ],
            [
              105.8376278465016,
              21.027374894605305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.6_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11510",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027167212872378,
          "to_latitude": 21.027374894605305,
          "to_longitude": 105.8376278465016,
          "from_longitude": 105.83779086459045
        }
      },
      {
        "id": 11476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837433104589,
            21.026862137284645,
            105.83760322930867,
            21.027219376111873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83760322930867,
              21.027219376111873
            ],
            [
              105.83760208990165,
              21.027215347027553
            ],
            [
              105.83757333531234,
              21.027144393275357
            ],
            [
              105.83754706103217,
              21.02708845873623
            ],
            [
              105.83753207967592,
              21.027094660889034
            ],
            [
              105.837433104589,
              21.02691958436996
            ],
            [
              105.83747095142233,
              21.026901757142326
            ],
            [
              105.83745187375915,
              21.026862137284645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.5_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11511",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027219376111873,
          "to_latitude": 21.026862137284645,
          "to_longitude": 105.83745187375915,
          "from_longitude": 105.83760322930867
        }
      },
      {
        "id": 11477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758946589906,
            21.027412245151115,
            105.83773398117594,
            21.027687292419806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759786054863,
              21.027412245151115
            ],
            [
              105.83758946589906,
              21.027414631265763
            ],
            [
              105.83763029573286,
              21.027517435491223
            ],
            [
              105.83767314137305,
              21.027605149566117
            ],
            [
              105.8376787753352,
              21.027607289547642
            ],
            [
              105.83767774402779,
              21.027612620932842
            ],
            [
              105.83773398117594,
              21.027687292419806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.6.1_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11512",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027412245151115,
          "to_latitude": 21.027687292419806,
          "to_longitude": 105.83773398117594,
          "from_longitude": 105.83759786054863
        }
      },
      {
        "id": 11478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83724635755277,
            21.027085076154293,
            105.83735081525978,
            21.027135059996798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735081525978,
              21.027085076154293
            ],
            [
              105.83724635755277,
              21.027135059996798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.4_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11513",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027085076154293,
          "to_latitude": 21.027135059996798,
          "to_longitude": 105.83724635755277,
          "from_longitude": 105.83735081525978
        }
      },
      {
        "id": 11479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733170674611,
            21.027025292886982,
            105.83755777283694,
            21.027426241136283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755777283694,
              21.027426241136283
            ],
            [
              105.83755645485905,
              21.027424171118426
            ],
            [
              105.8375192398659,
              21.02737052684385
            ],
            [
              105.83747890796802,
              21.02730086570432
            ],
            [
              105.83746742869225,
              21.027292155556253
            ],
            [
              105.83744736508392,
              21.027256526479373
            ],
            [
              105.83735081525978,
              21.027085076154293
            ],
            [
              105.83734340899368,
              21.02707254788886
            ],
            [
              105.8373566798901,
              21.027064276762236
            ],
            [
              105.83733170674611,
              21.027025292886982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.4_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.11514",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027426241136283,
          "to_latitude": 21.027025292886982,
          "to_longitude": 105.83733170674611,
          "from_longitude": 105.83755777283694
        }
      },
      {
        "id": 11480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824724806085,
            21.027017301386387,
            105.8383588232819,
            21.027032663287947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824724806085,
              21.027032015750702
            ],
            [
              105.8382665024465,
              21.027032663287947
            ],
            [
              105.83832559271804,
              21.02702145022732
            ],
            [
              105.8383588232819,
              21.027017301386387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.11_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11515",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027032015750702,
          "to_latitude": 21.027017301386387,
          "to_longitude": 105.8383588232819,
          "from_longitude": 105.83824724806085
        }
      },
      {
        "id": 11481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810651927008,
            21.027039034861424,
            105.83817998024251,
            21.02706637159817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810651927008,
              21.02706637159817
            ],
            [
              105.83811423361024,
              21.027063788296157
            ],
            [
              105.8381423106687,
              21.027052922338168
            ],
            [
              105.83815523602452,
              21.027047724714244
            ],
            [
              105.83817684899847,
              21.027039034861424
            ],
            [
              105.83817743127614,
              21.02704011405233
            ],
            [
              105.83817998024251,
              21.02704676004706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.10_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11516",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02706637159817,
          "to_latitude": 21.02704676004706,
          "to_longitude": 105.83817998024251,
          "from_longitude": 105.83810651927008
        }
      },
      {
        "id": 11482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83817684899847,
            21.027012693871033,
            105.83832752933593,
            21.027039034861424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83817684899847,
              21.027039034861424
            ],
            [
              105.83821635948942,
              21.02702988285354
            ],
            [
              105.83825477705761,
              21.02702140551206
            ],
            [
              105.83826437952914,
              21.027026340354638
            ],
            [
              105.83832752933593,
              21.027012693871033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.10_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11517",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027039034861424,
          "to_latitude": 21.027012693871033,
          "to_longitude": 105.83832752933593,
          "from_longitude": 105.83817684899847
        }
      },
      {
        "id": 11483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810716829653,
            21.027032015750702,
            105.83824724806085,
            21.027181036137996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824724806085,
              21.027032015750702
            ],
            [
              105.83821866954702,
              21.02703612538145
            ],
            [
              105.83818747227018,
              21.02704708621489
            ],
            [
              105.83811291582207,
              21.027073281339877
            ],
            [
              105.83810716829653,
              21.02708334718147
            ],
            [
              105.83813630829547,
              21.027181036137996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.11_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11518",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027032015750702,
          "to_latitude": 21.027181036137996,
          "to_longitude": 105.83813630829547,
          "from_longitude": 105.83824724806085
        }
      },
      {
        "id": 11484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806546748441,
            21.0269825217552,
            105.83815523602452,
            21.027047724714244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815523602452,
              21.027047724714244
            ],
            [
              105.83814190069559,
              21.0269825217552
            ],
            [
              105.83806546748441,
              21.026998236982408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.10_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11519",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027047724714244,
          "to_latitude": 21.026998236982408,
          "to_longitude": 105.83806546748441,
          "from_longitude": 105.83815523602452
        }
      },
      {
        "id": 11485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379819712872,
            21.026893283988933,
            105.8383768354056,
            21.02703030740868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836769551628,
              21.02703030740868
            ],
            [
              105.83836868919322,
              21.027029949349735
            ],
            [
              105.8383768354056,
              21.027027010746743
            ],
            [
              105.83831291628304,
              21.026893283988933
            ],
            [
              105.83821952710333,
              21.026906648684832
            ],
            [
              105.83813376980703,
              21.026910429553624
            ],
            [
              105.83808211822328,
              21.026901755759127
            ],
            [
              105.83802602750224,
              21.026905738105643
            ],
            [
              105.8379819712872,
              21.02692000754895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.12_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11520",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02703030740868,
          "to_latitude": 21.02692000754895,
          "to_longitude": 105.8379819712872,
          "from_longitude": 105.83836769551628
        }
      },
      {
        "id": 11486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773246830121,
            21.02688781542011,
            105.8383843135698,
            21.027033040602337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836878632827,
              21.027033040602337
            ],
            [
              105.83836918700526,
              21.027032954627266
            ],
            [
              105.8383843135698,
              21.027029674514733
            ],
            [
              105.83831685687137,
              21.02688781542011
            ],
            [
              105.83825715011854,
              21.026897844680523
            ],
            [
              105.83822363348347,
              21.026903474389965
            ],
            [
              105.83812552027732,
              21.026905212552233
            ],
            [
              105.83808623366325,
              21.02689830864956
            ],
            [
              105.83808119384062,
              21.026898333487416
            ],
            [
              105.8380143291551,
              21.02690086144821
            ],
            [
              105.8379566426031,
              21.026926381758624
            ],
            [
              105.83773246830121,
              21.026965913864125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.13_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11521",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027033040602337,
          "to_latitude": 21.026965913864125,
          "to_longitude": 105.83773246830121,
          "from_longitude": 105.83836878632827
        }
      },
      {
        "id": 11487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824208601135,
            21.026844164534957,
            105.83825715011854,
            21.026897844680523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825715011854,
              21.026897844680523
            ],
            [
              105.83824208601135,
              21.026844164534957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.13_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11522",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026897844680523,
          "to_latitude": 21.026844164534957,
          "to_longitude": 105.83824208601135,
          "from_longitude": 105.83825715011854
        }
      },
      {
        "id": 11488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8380668339345,
            21.02683956637744,
            105.83816014436478,
            21.026898333487416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83808119384062,
              21.026898333487416
            ],
            [
              105.8380668339345,
              21.026860475221042
            ],
            [
              105.83816014436478,
              21.02683956637744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.13_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11523",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026898333487416,
          "to_latitude": 21.02683956637744,
          "to_longitude": 105.83816014436478,
          "from_longitude": 105.83808119384062
        }
      },
      {
        "id": 11489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83834157201107,
            21.02650269306012,
            105.83849830447753,
            21.026857093718377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83834157201107,
              21.02650269306012
            ],
            [
              105.83835105805487,
              21.02652414299871
            ],
            [
              105.83840744148605,
              21.026667302227207
            ],
            [
              105.83843176650288,
              21.02671730393692
            ],
            [
              105.83844838284725,
              21.026751459574196
            ],
            [
              105.83846281546047,
              21.026776846651234
            ],
            [
              105.83849830447753,
              21.026857093718377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11524",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02650269306012,
          "to_latitude": 21.026857093718377,
          "to_longitude": 105.83849830447753,
          "from_longitude": 105.83834157201107
        }
      },
      {
        "id": 11490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836530285603,
            21.026776846651234,
            105.83846281546047,
            21.0268204237832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83846281546047,
              21.026776846651234
            ],
            [
              105.83836530285603,
              21.0268204237832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11525",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026776846651234,
          "to_latitude": 21.0268204237832,
          "to_longitude": 105.83836530285603,
          "from_longitude": 105.83846281546047
        }
      },
      {
        "id": 11491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829299392414,
            21.02650269306012,
            105.83834157201107,
            21.026529586636848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83834157201107,
              21.02650269306012
            ],
            [
              105.83829299392414,
              21.026529586636848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11526",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02650269306012,
          "to_latitude": 21.026529586636848,
          "to_longitude": 105.83829299392414,
          "from_longitude": 105.83834157201107
        }
      },
      {
        "id": 11492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83822858016171,
            21.026522931690604,
            105.83834597033484,
            21.02678408188912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827192245172,
              21.026522931690604
            ],
            [
              105.83827244788763,
              21.026523824194356
            ],
            [
              105.83827695806261,
              21.026534026444313
            ],
            [
              105.83827511135814,
              21.026559893913422
            ],
            [
              105.83830658135122,
              21.026625245016895
            ],
            [
              105.83831131552122,
              21.026635074936912
            ],
            [
              105.83834539197301,
              21.026712529916544
            ],
            [
              105.83833591736031,
              21.026725122388974
            ],
            [
              105.83834597033484,
              21.026747657012304
            ],
            [
              105.83822858016171,
              21.02678408188912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.2_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11527",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026522931690604,
          "to_latitude": 21.02678408188912,
          "to_longitude": 105.83822858016171,
          "from_longitude": 105.83827192245172
        }
      },
      {
        "id": 11493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803420001124,
            21.026625245016895,
            105.83830658135122,
            21.026779817472555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83830658135122,
              21.026625245016895
            ],
            [
              105.83825467517967,
              21.026653088922036
            ],
            [
              105.83826237656778,
              21.02666746187945
            ],
            [
              105.83814677799802,
              21.026732847836207
            ],
            [
              105.83811304754875,
              21.02674548216662
            ],
            [
              105.83803420001124,
              21.026779817472555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.2_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11528",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026625245016895,
          "to_latitude": 21.026779817472555,
          "to_longitude": 105.83803420001124,
          "from_longitude": 105.83830658135122
        }
      },
      {
        "id": 11494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83800592549841,
            21.026524782545643,
            105.83830105533575,
            21.02679046038557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826749410633,
              21.026524782545643
            ],
            [
              105.83826801349261,
              21.026525625401973
            ],
            [
              105.83827219872386,
              21.026534145646497
            ],
            [
              105.83826883195066,
              21.026561371829846
            ],
            [
              105.83830105533575,
              21.026622103752146
            ],
            [
              105.83824869158184,
              21.02665275983729
            ],
            [
              105.83825651420477,
              21.026667824969284
            ],
            [
              105.83811295563105,
              21.026740225862795
            ],
            [
              105.83800592549841,
              21.02679046038557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.3_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11529",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026524782545643,
          "to_latitude": 21.02679046038557,
          "to_longitude": 105.83800592549841,
          "from_longitude": 105.83826749410633
        }
      },
      {
        "id": 11495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83801389912666,
            21.026541311510798,
            105.83824330935062,
            21.026652020256385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83801389912666,
              21.026652020256385
            ],
            [
              105.83814622792134,
              21.026583532459345
            ],
            [
              105.83824330935062,
              21.026541311510798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.4_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11530",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026652020256385,
          "to_latitude": 21.026541311510798,
          "to_longitude": 105.83824330935062,
          "from_longitude": 105.83801389912666
        }
      },
      {
        "id": 11496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765346854237,
            21.0264656817889,
            105.83783011180628,
            21.026739307534157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83765346854237,
              21.0264656817889
            ],
            [
              105.83781247713634,
              21.026739307534157
            ],
            [
              105.83782708659757,
              21.026731980871055
            ],
            [
              105.83782864611004,
              21.02673561317586
            ],
            [
              105.83783011180628,
              21.02673881149266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.6_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11531",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0264656817889,
          "to_latitude": 21.02673881149266,
          "to_longitude": 105.83783011180628,
          "from_longitude": 105.83765346854237
        }
      },
      {
        "id": 11497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773719505618,
            21.026739307534157,
            105.83781247713634,
            21.026778521509538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83781247713634,
              21.026739307534157
            ],
            [
              105.83773719505618,
              21.026778521509538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.6_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11532",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026739307534157,
          "to_latitude": 21.026778521509538,
          "to_longitude": 105.83773719505618,
          "from_longitude": 105.83781247713634
        }
      },
      {
        "id": 11498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749368946405,
            21.026579153541952,
            105.83773653936771,
            21.026804316100193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83772592794689,
              21.026796411149743
            ],
            [
              105.83773196345113,
              21.026802584752318
            ],
            [
              105.83773309001751,
              21.026804316100193
            ],
            [
              105.83773653936771,
              21.026802004921702
            ],
            [
              105.83773094463588,
              21.026793624380897
            ],
            [
              105.83772801678084,
              21.02678923739902
            ],
            [
              105.8377234358511,
              21.02679133015243
            ],
            [
              105.83769760931125,
              21.026803983271694
            ],
            [
              105.83766727726342,
              21.02676336391374
            ],
            [
              105.83764114697654,
              21.026728371706056
            ],
            [
              105.83762814038265,
              21.026734351882
            ],
            [
              105.83761520051911,
              21.02671439748885
            ],
            [
              105.83759052186242,
              21.026670785891998
            ],
            [
              105.8375476967024,
              21.026686889024035
            ],
            [
              105.83749368946405,
              21.026602311664305
            ],
            [
              105.83753356608331,
              21.026579153541952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.7_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11533",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026796411149743,
          "to_latitude": 21.026579153541952,
          "to_longitude": 105.83753356608331,
          "from_longitude": 105.83772592794689
        }
      },
      {
        "id": 11499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763319826087,
            21.026798626533203,
            105.83773043566883,
            21.02697963255538
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83772193933696,
              21.026798626533203
            ],
            [
              105.8377258449886,
              21.026806011011217
            ],
            [
              105.83770480480538,
              21.02681718094483
            ],
            [
              105.83773043566883,
              21.026873660564345
            ],
            [
              105.83770882122191,
              21.026911869497965
            ],
            [
              105.83771065716492,
              21.026953262383937
            ],
            [
              105.83764122915831,
              21.02697963255538
            ],
            [
              105.83763319826087,
              21.026963612810395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.8.1_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11534",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026798626533203,
          "to_latitude": 21.026963612810395,
          "to_longitude": 105.83763319826087,
          "from_longitude": 105.83772193933696
        }
      },
      {
        "id": 11500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771208840749,
            21.026797478291375,
            105.83791201081861,
            21.0269197769276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377240060469,
              21.026797478291375
            ],
            [
              105.83772830622681,
              21.026804020828525
            ],
            [
              105.83772967743836,
              21.02680614929256
            ],
            [
              105.83771208840749,
              21.026817396161054
            ],
            [
              105.83775164965171,
              21.026898409138436
            ],
            [
              105.83789097829217,
              21.026863644099308
            ],
            [
              105.83791201081861,
              21.0269197769276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.8_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11535",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026797478291375,
          "to_latitude": 21.0269197769276,
          "to_longitude": 105.83791201081861,
          "from_longitude": 105.8377240060469
        }
      },
      {
        "id": 11501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375414482031,
            21.02671439748885,
            105.83761520051911,
            21.026797989827525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83761520051911,
              21.02671439748885
            ],
            [
              105.8375414482031,
              21.026771040562316
            ],
            [
              105.83754552279983,
              21.026797989827525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.7_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11536",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02671439748885,
          "to_latitude": 21.026797989827525,
          "to_longitude": 105.83754552279983,
          "from_longitude": 105.83761520051911
        }
      },
      {
        "id": 11502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756135680066,
            21.02676336391374,
            105.83766727726342,
            21.0269413899221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766727726342,
              21.02676336391374
            ],
            [
              105.83756135680066,
              21.026815608688704
            ],
            [
              105.83758345398627,
              21.02686035029006
            ],
            [
              105.83762347804928,
              21.0269413899221
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.7_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11537",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02676336391374,
          "to_latitude": 21.0269413899221,
          "to_longitude": 105.83762347804928,
          "from_longitude": 105.83766727726342
        }
      },
      {
        "id": 11503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751959074722,
            21.02686035029006,
            105.83758345398627,
            21.026940210121722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758345398627,
              21.02686035029006
            ],
            [
              105.83758051476703,
              21.02686150463408
            ],
            [
              105.83751959074722,
              21.02688543120044
            ],
            [
              105.83754631355238,
              21.026940210121722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.7_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11538",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02686035029006,
          "to_latitude": 21.026940210121722,
          "to_longitude": 105.83754631355238,
          "from_longitude": 105.83758345398627
        }
      },
      {
        "id": 11504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83642759157841,
            21.026743504460793,
            105.83677588197145,
            21.026936580233325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367663501968,
              21.026743504460793
            ],
            [
              105.83677588197145,
              21.026760085895432
            ],
            [
              105.83642759157841,
              21.026936580233325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.4_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11539",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026743504460793,
          "to_latitude": 21.026936580233325,
          "to_longitude": 105.83642759157841,
          "from_longitude": 105.8367663501968
        }
      },
      {
        "id": 11505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83677588197145,
            21.026575546296904,
            105.83714004588433,
            21.026760085895432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83714004588433,
              21.026575546296904
            ],
            [
              105.83709987170162,
              21.02659590489886
            ],
            [
              105.83708803139201,
              21.02659574460212
            ],
            [
              105.83708174203682,
              21.026605091402946
            ],
            [
              105.8369293344187,
              21.026682323758703
            ],
            [
              105.83691802370853,
              21.026681908844672
            ],
            [
              105.83691120569958,
              21.026691511143685
            ],
            [
              105.83677588197145,
              21.026760085895432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.4_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11540",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026575546296904,
          "to_latitude": 21.026760085895432,
          "to_longitude": 105.83677588197145,
          "from_longitude": 105.83714004588433
        }
      },
      {
        "id": 11506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360320012474,
            21.027515678777252,
            105.83608084154247,
            21.02763794460699
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360320012474,
              21.027515678777252
            ],
            [
              105.83604468351385,
              21.02754742613713
            ],
            [
              105.83608084154247,
              21.02763794460699
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.7_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11541",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027515678777252,
          "to_latitude": 21.02763794460699,
          "to_longitude": 105.83608084154247,
          "from_longitude": 105.8360320012474
        }
      },
      {
        "id": 11507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608084154247,
            21.02763794460699,
            105.83615407611833,
            21.027822369506374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608084154247,
              21.02763794460699
            ],
            [
              105.83609112659626,
              21.027663846347036
            ],
            [
              105.83610579360682,
              21.027700782894577
            ],
            [
              105.83615407611833,
              21.027822369506374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.7_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11542",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02763794460699,
          "to_latitude": 21.027822369506374,
          "to_longitude": 105.83615407611833,
          "from_longitude": 105.83608084154247
        }
      },
      {
        "id": 11508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83609112659626,
            21.02760546554099,
            105.83633807197882,
            21.027663846347036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83609112659626,
              21.027663846347036
            ],
            [
              105.83619382681104,
              21.02760546554099
            ],
            [
              105.83621056597063,
              21.027630149604356
            ],
            [
              105.83624705608715,
              21.027611178606765
            ],
            [
              105.83631851791904,
              21.027622495002472
            ],
            [
              105.83633807197882,
              21.027608215521624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.7_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11543",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027663846347036,
          "to_latitude": 21.027608215521624,
          "to_longitude": 105.83633807197882,
          "from_longitude": 105.83609112659626
        }
      },
      {
        "id": 11509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83604468351385,
            21.0274757276956,
            105.83619433447241,
            21.02754742613713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604468351385,
              21.02754742613713
            ],
            [
              105.83604473148725,
              21.027547403320686
            ],
            [
              105.83619433447241,
              21.0274757276956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.7_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11544",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02754742613713,
          "to_latitude": 21.0274757276956,
          "to_longitude": 105.83619433447241,
          "from_longitude": 105.83604468351385
        }
      },
      {
        "id": 11510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83875323257075,
            21.027047796846823,
            105.83916274284239,
            21.027185975270278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83875323257075,
              21.027185975270278
            ],
            [
              105.83877534904487,
              21.027177698340918
            ],
            [
              105.83878254357874,
              21.027163195936755
            ],
            [
              105.8387886355597,
              21.02715091276942
            ],
            [
              105.8391108081036,
              21.027062111781042
            ],
            [
              105.83916274284239,
              21.027047796846823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11546",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027185975270278,
          "to_latitude": 21.027047796846823,
          "to_longitude": 105.83916274284239,
          "from_longitude": 105.83875323257075
        }
      },
      {
        "id": 11511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884199762461,
            21.02714767012321,
            105.83885069647637,
            21.027173270894618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83884199762461,
              21.02714767012321
            ],
            [
              105.83884530581649,
              21.02715713462982
            ],
            [
              105.83885069647637,
              21.027173270894618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.3.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11547",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": 2.975,
          "from_latitude": 21.02714767012321,
          "to_latitude": 21.027173270894618,
          "to_longitude": 105.83885069647637,
          "from_longitude": 105.83884199762461
        }
      },
      {
        "id": 11512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83868197904928,
            21.02694262233888,
            105.83895123951174,
            21.02702291023195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868197904928,
              21.02702291023195
            ],
            [
              105.83869916795913,
              21.02701443722859
            ],
            [
              105.83870288364736,
              21.027014338516093
            ],
            [
              105.83872308285679,
              21.02701380624907
            ],
            [
              105.83885217484143,
              21.026956904152147
            ],
            [
              105.83887411440062,
              21.026973406191054
            ],
            [
              105.83895123951174,
              21.02694262233888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.4_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11548",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02702291023195,
          "to_latitude": 21.02694262233888,
          "to_longitude": 105.83895123951174,
          "from_longitude": 105.83868197904928
        }
      },
      {
        "id": 11513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387222516639,
            21.027005916278792,
            105.83891303353735,
            21.027115076063136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8387222516639,
              21.027115076063136
            ],
            [
              105.8387401364946,
              21.02710667097772
            ],
            [
              105.83887709927049,
              21.02704229832377
            ],
            [
              105.83888331659762,
              21.027017175224746
            ],
            [
              105.83891303353735,
              21.027005916278792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.4_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11549",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027115076063136,
          "to_latitude": 21.027005916278792,
          "to_longitude": 105.83891303353735,
          "from_longitude": 105.8387222516639
        }
      },
      {
        "id": 11514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83865234104397,
            21.026876535077484,
            105.83892709079204,
            21.026955082568815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83865234104397,
              21.026955082568815
            ],
            [
              105.83866592536924,
              21.02694816914669
            ],
            [
              105.83866900119831,
              21.026946501079866
            ],
            [
              105.83879803928927,
              21.026876535077484
            ],
            [
              105.83886384790615,
              21.026949521434393
            ],
            [
              105.83892709079204,
              21.026925191856847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11550",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026955082568815,
          "to_latitude": 21.026925191856847,
          "to_longitude": 105.83892709079204,
          "from_longitude": 105.83865234104397
        }
      },
      {
        "id": 11515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386249932721,
            21.026859165120204,
            105.83866900119831,
            21.026946501079866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83866900119831,
              21.026946501079866
            ],
            [
              105.83866239137565,
              21.026936896291577
            ],
            [
              105.8386249932721,
              21.026859165120204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11551",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026946501079866,
          "to_latitude": 21.026859165120204,
          "to_longitude": 105.8386249932721,
          "from_longitude": 105.83866900119831
        }
      },
      {
        "id": 11516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858249390063,
            21.026662836899636,
            105.83900131614267,
            21.026795230531476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83858249390063,
              21.026795230531476
            ],
            [
              105.83859952102316,
              21.0267905139365
            ],
            [
              105.83867843645385,
              21.026758020611467
            ],
            [
              105.83880872407858,
              21.026707491213827
            ],
            [
              105.83881014188623,
              21.026706941383566
            ],
            [
              105.83900131614267,
              21.026662836899636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.6_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11552",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026795230531476,
          "to_latitude": 21.026662836899636,
          "to_longitude": 105.83900131614267,
          "from_longitude": 105.83858249390063
        }
      },
      {
        "id": 11517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867843645385,
            21.026758020611467,
            105.83878242574502,
            21.026819905842334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867843645385,
              21.026758020611467
            ],
            [
              105.83871231249556,
              21.026819905842334
            ],
            [
              105.83878242574502,
              21.026795184758136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.6_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11553",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026758020611467,
          "to_latitude": 21.026795184758136,
          "to_longitude": 105.83878242574502,
          "from_longitude": 105.83867843645385
        }
      },
      {
        "id": 11518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83923196210515,
            21.029184786759497,
            105.83930099126222,
            21.029401291674315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83930099126222,
              21.029401291674315
            ],
            [
              105.83929776745742,
              21.029380148700195
            ],
            [
              105.83929637044558,
              21.02937599806328
            ],
            [
              105.83923196210515,
              21.029184786759497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.3_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11554",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029401291674315,
          "to_latitude": 21.029184786759497,
          "to_longitude": 105.83923196210515,
          "from_longitude": 105.83930099126222
        }
      },
      {
        "id": 11519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900517552475,
            21.029399582064805,
            105.83921883020312,
            21.02946115408502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921883020312,
              21.029399582064805
            ],
            [
              105.8391799381729,
              21.02940834453958
            ],
            [
              105.83913292615412,
              21.0294226911395
            ],
            [
              105.83907570111566,
              21.029439363407096
            ],
            [
              105.83900517552475,
              21.02946115408502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.4_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11555",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": 23.678,
          "from_latitude": 21.029399582064805,
          "to_latitude": 21.02946115408502,
          "to_longitude": 105.83900517552475,
          "from_longitude": 105.83921883020312
        }
      },
      {
        "id": 11520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904440036028,
            21.029203689204284,
            105.83914296847733,
            21.029452030629987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914296847733,
              21.029452030629987
            ],
            [
              105.83913461873426,
              21.02942671115845
            ],
            [
              105.83913292615412,
              21.0294226911395
            ],
            [
              105.83911621964148,
              21.029427558509934
            ],
            [
              105.83905491231174,
              21.02923753165763
            ],
            [
              105.83904440036028,
              21.029203689204284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.4_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11556",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 31.118,
          "from_latitude": 21.029452030629987,
          "to_latitude": 21.029203689204284,
          "to_longitude": 105.83904440036028,
          "from_longitude": 105.83914296847733
        }
      },
      {
        "id": 11521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83905491231174,
            21.02922832252049,
            105.839079606821,
            21.02923753165763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905491231174,
              21.02923753165763
            ],
            [
              105.83906686632974,
              21.029233329654872
            ],
            [
              105.839079606821,
              21.02922832252049
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.4_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11557",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": 2.763,
          "from_latitude": 21.02923753165763,
          "to_latitude": 21.02922832252049,
          "to_longitude": 105.839079606821,
          "from_longitude": 105.83905491231174
        }
      },
      {
        "id": 11522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929999718522,
            21.029139283804287,
            105.83938557416721,
            21.029360716234645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83935939736466,
              21.029360716234645
            ],
            [
              105.83934087071825,
              21.029304759536583
            ],
            [
              105.83929999718522,
              21.02917531396068
            ],
            [
              105.83938557416721,
              21.029139283804287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11558",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": 31.185,
          "from_latitude": 21.029360716234645,
          "to_latitude": 21.029139283804287,
          "to_longitude": 105.83938557416721,
          "from_longitude": 105.83935939736466
        }
      },
      {
        "id": 11523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929637044558,
            21.029327917519073,
            105.83946928147456,
            21.029375998063262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929637044558,
              21.029375998063262
            ],
            [
              105.8393455645863,
              21.029364845105572
            ],
            [
              105.83946928147456,
              21.029327917519073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.11559",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": 18.756,
          "from_latitude": 21.029375998063262,
          "to_latitude": 21.029327917519073,
          "to_longitude": 105.83946928147456,
          "from_longitude": 105.83929637044558
        }
      },
      {
        "id": 11524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924841843586,
            21.028028639605264,
            105.83945653301718,
            21.028082466722456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924841843586,
              21.028082466722456
            ],
            [
              105.83943617306763,
              21.028028639605264
            ],
            [
              105.83944400462451,
              21.02803681383403
            ],
            [
              105.83945653301718,
              21.028058126746128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11560",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": 24.319,
          "from_latitude": 21.028082466722456,
          "to_latitude": 21.028058126746128,
          "to_longitude": 105.83945653301718,
          "from_longitude": 105.83924841843586
        }
      },
      {
        "id": 11525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943617306763,
            21.028015556189516,
            105.83947327874604,
            21.028028639605264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943617306763,
              21.028028639605264
            ],
            [
              105.83946198932838,
              21.02801893820409
            ],
            [
              105.83947327874604,
              21.028015556189516
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11561",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": 4.123,
          "from_latitude": 21.028028639605264,
          "to_latitude": 21.028015556189516,
          "to_longitude": 105.83947327874604,
          "from_longitude": 105.83943617306763
        }
      },
      {
        "id": 11526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944589608193,
            21.027886248008702,
            105.83947327874604,
            21.028015556189516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947327874604,
              21.028015556189516
            ],
            [
              105.83944649362434,
              21.027945815432066
            ],
            [
              105.83946699746105,
              21.02793919023705
            ],
            [
              105.83944589608193,
              21.027886248008702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11562",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 16.721,
          "from_latitude": 21.028015556189516,
          "to_latitude": 21.027886248008702,
          "to_longitude": 105.83944589608193,
          "from_longitude": 105.83947327874604
        }
      },
      {
        "id": 11527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83950322827914,
            21.027982956731126,
            105.83959561114014,
            21.028016137558705
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83959561114014,
              21.028016137558705
            ],
            [
              105.83958437765928,
              21.02799483452177
            ],
            [
              105.83958037596219,
              21.027982956731126
            ],
            [
              105.83953675589387,
              21.02799922245109
            ],
            [
              105.83951764515248,
              21.028005481420042
            ],
            [
              105.83950322827914,
              21.028009549750166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11563",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": 12.559,
          "from_latitude": 21.028016137558705,
          "to_latitude": 21.028009549750166,
          "to_longitude": 105.83950322827914,
          "from_longitude": 105.83959561114014
        }
      },
      {
        "id": 11528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958037596219,
            21.027946021200723,
            105.83970707616633,
            21.027982956731126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958037596219,
              21.027982956731126
            ],
            [
              105.83965041449859,
              21.02796229452623
            ],
            [
              105.83970707616633,
              21.027946021200723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11564",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": 13.791,
          "from_latitude": 21.027982956731126,
          "to_latitude": 21.027946021200723,
          "to_longitude": 105.83970707616633,
          "from_longitude": 105.83958037596219
        }
      },
      {
        "id": 11529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931245604462,
            21.027672414689174,
            105.83934833058889,
            21.027779845406624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932294275489,
              21.027672414689174
            ],
            [
              105.8393252389791,
              21.027678925525368
            ],
            [
              105.8393252389791,
              21.027678925525368
            ],
            [
              105.83932965153642,
              21.027684336369717
            ],
            [
              105.83932620237053,
              21.027689716085536
            ],
            [
              105.83931245604462,
              21.0276937844243
            ],
            [
              105.83931949684218,
              21.02771224842029
            ],
            [
              105.83932620237053,
              21.02772789587354
            ],
            [
              105.83933156678602,
              21.02774135268181
            ],
            [
              105.8393389428618,
              21.027758877825846
            ],
            [
              105.83934833058889,
              21.027779845406624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.15_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11566",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": 13.94,
          "from_latitude": 21.027672414689174,
          "to_latitude": 21.027779845406624,
          "to_longitude": 105.83934833058889,
          "from_longitude": 105.83932294275489
        }
      },
      {
        "id": 11530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933237872755,
            21.027670107349298,
            105.83939161983105,
            21.027750215011704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933237872755,
              21.027670107349298
            ],
            [
              105.83933548625335,
              21.02767547341087
            ],
            [
              105.839340509417,
              21.027685011034073
            ],
            [
              105.83937484128968,
              21.027750215011704
            ],
            [
              105.83939161983105,
              21.027749195548985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.13_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11567",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027670107349298,
          "to_latitude": 21.027749195548985,
          "to_longitude": 105.83939161983105,
          "from_longitude": 105.83933237872755
        }
      },
      {
        "id": 11531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392118549886,
            21.027645876254358,
            105.83944421956508,
            21.027720597487395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392118549886,
              21.027720597487395
            ],
            [
              105.83922972674057,
              21.027714673776053
            ],
            [
              105.83933357843733,
              21.027683144153084
            ],
            [
              105.8393373607446,
              21.027679032560584
            ],
            [
              105.83934363672086,
              21.02768032761089
            ],
            [
              105.83944421956508,
              21.02764997154121
            ],
            [
              105.83944223767764,
              21.027645876254358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.15.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11568",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": 26.067,
          "from_latitude": 21.027720597487395,
          "to_latitude": 21.027645876254358,
          "to_longitude": 105.83944223767764,
          "from_longitude": 105.8392118549886
        }
      },
      {
        "id": 11532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904633069862,
            21.027720597487395,
            105.8392118549886,
            21.027767562160612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392118549886,
              21.027720597487395
            ],
            [
              105.83916738509348,
              21.027731500839323
            ],
            [
              105.83904633069862,
              21.027767562160612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.15_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11569",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": 17.98,
          "from_latitude": 21.027720597487395,
          "to_latitude": 21.027767562160612,
          "to_longitude": 105.83904633069862,
          "from_longitude": 105.8392118549886
        }
      },
      {
        "id": 11533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908376245195,
            21.027954128753223,
            105.83917708839341,
            21.028050780702134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908376245195,
              21.027954128753223
            ],
            [
              105.83909209954135,
              21.027965175574224
            ],
            [
              105.83913786108498,
              21.0279737953842
            ],
            [
              105.83915026629803,
              21.027996327676842
            ],
            [
              105.83917708839341,
              21.028050780702134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11572",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 15.801,
          "from_latitude": 21.027954128753223,
          "to_latitude": 21.028050780702134,
          "to_longitude": 105.83917708839341,
          "from_longitude": 105.83908376245195
        }
      },
      {
        "id": 11534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904867763714,
            21.027875842462166,
            105.83908376245195,
            21.027954128753223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904867763714,
              21.027875842462166
            ],
            [
              105.83905638898214,
              21.027893993487478
            ],
            [
              105.83906336180357,
              21.0279097592554
            ],
            [
              105.83908376245195,
              21.027954128753223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11573",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": 9.405,
          "from_latitude": 21.027875842462166,
          "to_latitude": 21.027954128753223,
          "to_longitude": 105.83908376245195,
          "from_longitude": 105.83904867763714
        }
      },
      {
        "id": 11535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903948980925,
            21.02763907166306,
            105.83921416789974,
            21.027685196821416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903948980925,
              21.027685196821416
            ],
            [
              105.83919872829439,
              21.02764314913436
            ],
            [
              105.83921416789974,
              21.02763907166306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.14_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11575",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027685196821416,
          "to_latitude": 21.02763907166306,
          "to_longitude": 105.83921416789974,
          "from_longitude": 105.83903948980925
        }
      },
      {
        "id": 11536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921416789974,
            21.027599328399308,
            105.83936635897547,
            21.02763907166306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921416789974,
              21.02763907166306
            ],
            [
              105.83931260431257,
              21.02761336606074
            ],
            [
              105.83936635897547,
              21.027599328399308
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.14_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11576",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02763907166306,
          "to_latitude": 21.027599328399308,
          "to_longitude": 105.83936635897547,
          "from_longitude": 105.83921416789974
        }
      },
      {
        "id": 11537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931260431257,
            21.02761336606074,
            105.839333631941,
            21.027679722745763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932729567952,
              21.02767135088537
            ],
            [
              105.83932956166986,
              21.02767744458192
            ],
            [
              105.83933042869346,
              21.027679722745763
            ],
            [
              105.839333631941,
              21.027678989776454
            ],
            [
              105.8393304515721,
              21.02767057931129
            ],
            [
              105.8393285767535,
              21.027665622647614
            ],
            [
              105.83931579409327,
              21.02762380150481
            ],
            [
              105.83931260431257,
              21.02761336606074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.14_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11577",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02767135088537,
          "to_latitude": 21.02761336606074,
          "to_longitude": 105.83931260431257,
          "from_longitude": 105.83932729567952
        }
      },
      {
        "id": 11538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890281946019,
            21.027474465018447,
            105.83907424350652,
            21.027516216100125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890281946019,
              21.027516216100125
            ],
            [
              105.83892005474934,
              21.027507771747956
            ],
            [
              105.83892346230137,
              21.027505899718022
            ],
            [
              105.8389194746325,
              21.027494015819926
            ],
            [
              105.83898128318533,
              21.027474465018447
            ],
            [
              105.83899419820975,
              21.027508156533774
            ],
            [
              105.83907424350652,
              21.027484461155318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11579",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027516216100125,
          "to_latitude": 21.027484461155318,
          "to_longitude": 105.83907424350652,
          "from_longitude": 105.83890281946019
        }
      },
      {
        "id": 11539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892346230137,
            21.027505899718022,
            105.83898346417617,
            21.02765307104102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892346230137,
              21.027505899718022
            ],
            [
              105.83893960981449,
              21.02757468174643
            ],
            [
              105.83898346417617,
              21.02765307104102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11580",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027505899718022,
          "to_latitude": 21.02765307104102,
          "to_longitude": 105.83898346417617,
          "from_longitude": 105.83892346230137
        }
      },
      {
        "id": 11540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878923619525,
            21.027174487198188,
            105.8390864479704,
            21.027268055075922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83878923619525,
              21.027268055075922
            ],
            [
              105.8387900457428,
              21.02726779998689
            ],
            [
              105.8388140965727,
              21.027258101773985
            ],
            [
              105.8388162733875,
              21.02725737568409
            ],
            [
              105.83898054188981,
              21.027202616733998
            ],
            [
              105.8390864479704,
              21.027174487198188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11581",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027268055075922,
          "to_latitude": 21.027174487198188,
          "to_longitude": 105.8390864479704,
          "from_longitude": 105.83878923619525
        }
      },
      {
        "id": 11541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388162733875,
            21.02725737568409,
            105.83890323600014,
            21.027485959700716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388162733875,
              21.02725737568409
            ],
            [
              105.838817892458,
              21.027260815298558
            ],
            [
              105.8388476071189,
              21.027343347916158
            ],
            [
              105.83890323600014,
              21.027485959700716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11582",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02725737568409,
          "to_latitude": 21.027485959700716,
          "to_longitude": 105.83890323600014,
          "from_longitude": 105.8388162733875
        }
      },
      {
        "id": 11542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908951089802,
            21.02708303524664,
            105.83917385120802,
            21.027333625221857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908951089802,
              21.02708303524664
            ],
            [
              105.83909188137909,
              21.027088722884763
            ],
            [
              105.83912485620363,
              21.027167782732622
            ],
            [
              105.83917385120802,
              21.027321729184838
            ],
            [
              105.83912906981726,
              21.027333625221857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11583",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02708303524664,
          "to_latitude": 21.027333625221857,
          "to_longitude": 105.83912906981726,
          "from_longitude": 105.83908951089802
        }
      },
      {
        "id": 11543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912485620363,
            21.027144641158195,
            105.83921665034606,
            21.027167782732622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912485620363,
              21.027167782732622
            ],
            [
              105.8391256178783,
              21.02716756852326
            ],
            [
              105.83920706026204,
              21.027144641158195
            ],
            [
              105.83921665034606,
              21.027148215753716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11584",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027167782732622,
          "to_latitude": 21.027148215753716,
          "to_longitude": 105.83921665034606,
          "from_longitude": 105.83912485620363
        }
      },
      {
        "id": 11544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392234094571,
            21.02704411700561,
            105.83934302339415,
            21.02741113036001
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392262764075,
              21.02704411700561
            ],
            [
              105.839252415878,
              21.027118649286226
            ],
            [
              105.83934302339415,
              21.0273769988396
            ],
            [
              105.8392234094571,
              21.02741113036001
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11585",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02704411700561,
          "to_latitude": 21.02741113036001,
          "to_longitude": 105.8392234094571,
          "from_longitude": 105.8392262764075
        }
      },
      {
        "id": 11545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915614177226,
            21.027455139026234,
            105.83944541585139,
            21.0276442736035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83944541585139,
              21.0276442736035
            ],
            [
              105.83944530665224,
              21.027644014014477
            ],
            [
              105.83936886519538,
              21.027460542210147
            ],
            [
              105.8393672399253,
              21.027455139026234
            ],
            [
              105.83915614177226,
              21.027507537202307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.12_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11586",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0276442736035,
          "to_latitude": 21.027507537202307,
          "to_longitude": 105.83915614177226,
          "from_longitude": 105.83944541585139
        }
      },
      {
        "id": 11546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8398146851579,
            21.026655484651616,
            105.83983630119332,
            21.026738378631688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8398146851579,
              21.026655484651616
            ],
            [
              105.83982468030328,
              21.02668397170354
            ],
            [
              105.83983630119332,
              21.026738378631688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11587",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026655484651616,
          "to_latitude": 21.026738378631688,
          "to_longitude": 105.83983630119332,
          "from_longitude": 105.8398146851579
        }
      },
      {
        "id": 11547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83989860256348,
            21.02687984933068,
            105.83994822342468,
            21.026995202117842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83992228340873,
              21.02687984933068
            ],
            [
              105.83990229322481,
              21.026885747655278
            ],
            [
              105.83989860256348,
              21.026886921176537
            ],
            [
              105.83994822342468,
              21.026995202117842
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.2.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11588",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 15.634,
          "from_latitude": 21.02687984933068,
          "to_latitude": 21.026995202117842,
          "to_longitude": 105.83994822342468,
          "from_longitude": 105.83992228340873
        }
      },
      {
        "id": 11548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83973377455578,
            21.026727315602795,
            105.83986270979554,
            21.02677087527451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83986270979554,
              21.026727315602795
            ],
            [
              105.83984226468046,
              21.026734086873233
            ],
            [
              105.83983937561064,
              21.026735603200315
            ],
            [
              105.83983630119332,
              21.026738378631688
            ],
            [
              105.83983197862793,
              21.026742280223026
            ],
            [
              105.83979056094567,
              21.026754230268015
            ],
            [
              105.83973377455578,
              21.02677087527451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11589",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026727315602795,
          "to_latitude": 21.02677087527451,
          "to_longitude": 105.83973377455578,
          "from_longitude": 105.83986270979554
        }
      },
      {
        "id": 11549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83995744141406,
            21.02702363579264,
            105.83999903014949,
            21.027121173487807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83995744141406,
              21.02702363579264
            ],
            [
              105.8399742870081,
              21.027059835935187
            ],
            [
              105.83999903014949,
              21.027121173487807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11590",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02702363579264,
          "to_latitude": 21.027121173487807,
          "to_longitude": 105.83999903014949,
          "from_longitude": 105.83995744141406
        }
      },
      {
        "id": 11550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83990427727026,
            21.02688280361423,
            105.83992345514805,
            21.026901003962006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83990966664571,
              21.026901003962006
            ],
            [
              105.83990427727026,
              21.026889422100627
            ],
            [
              105.83992345514805,
              21.02688280361423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.3.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11591",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": 3.523,
          "from_latitude": 21.026901003962006,
          "to_latitude": 21.02688280361423,
          "to_longitude": 105.83992345514805,
          "from_longitude": 105.83990966664571
        }
      },
      {
        "id": 11551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83981143938495,
            21.026968601316252,
            105.83986138691535,
            21.0270191922608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83984462310346,
              21.026968601316252
            ],
            [
              105.83986138691535,
              21.027011788497994
            ],
            [
              105.83981143938495,
              21.0270191922608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11592",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": 10.345,
          "from_latitude": 21.026968601316252,
          "to_latitude": 21.0270191922608,
          "to_longitude": 105.83981143938495,
          "from_longitude": 105.83984462310346
        }
      },
      {
        "id": 11552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83983995258018,
            21.027017438572887,
            105.83997686716393,
            21.027091560306197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83997686716393,
              21.027017438572887
            ],
            [
              105.83995954349852,
              21.027022966057253
            ],
            [
              105.83995744141406,
              21.02702363579264
            ],
            [
              105.83995465848872,
              21.027024523857854
            ],
            [
              105.83983995258018,
              21.027063017607258
            ],
            [
              105.8398498355172,
              21.027091560306197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11593",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027017438572887,
          "to_latitude": 21.027091560306197,
          "to_longitude": 105.8398498355172,
          "from_longitude": 105.83997686716393
        }
      },
      {
        "id": 11553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83981822699069,
            21.027138658258867,
            105.84002495615711,
            21.027240763893307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002495615711,
              21.027138658258867
            ],
            [
              105.84001066683051,
              21.02714009632324
            ],
            [
              105.84000971811224,
              21.02714040810525
            ],
            [
              105.83981822699069,
              21.027203384515424
            ],
            [
              105.8398309444603,
              21.027240763893307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11594",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027138658258867,
          "to_latitude": 21.027240763893307,
          "to_longitude": 105.8398309444603,
          "from_longitude": 105.84002495615711
        }
      },
      {
        "id": 11554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84000971811224,
            21.02714040810525,
            105.84010043028206,
            21.027402418808144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84000971811224,
              21.02714040810525
            ],
            [
              105.84010043028206,
              21.02735488718688
            ],
            [
              105.84008041169622,
              21.027402418808144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11595",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02714040810525,
          "to_latitude": 21.027402418808144,
          "to_longitude": 105.84008041169622,
          "from_longitude": 105.84000971811224
        }
      },
      {
        "id": 11555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83985539278865,
            21.02730065415354,
            105.84001447370294,
            21.02749137685262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84001447370294,
              21.02749137685262
            ],
            [
              105.84001395393031,
              21.027489780716508
            ],
            [
              105.8399952508535,
              21.027432160804636
            ],
            [
              105.83996691893145,
              21.02733057351899
            ],
            [
              105.83987727885537,
              21.027345354927064
            ],
            [
              105.83985539278865,
              21.02730065415354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11596",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02749137685262,
          "to_latitude": 21.02730065415354,
          "to_longitude": 105.83985539278865,
          "from_longitude": 105.84001447370294
        }
      },
      {
        "id": 11556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83973775497876,
            21.027432160804636,
            105.8399952508535,
            21.02750376197023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8399952508535,
              21.027432160804636
            ],
            [
              105.83997073275322,
              21.027438978405144
            ],
            [
              105.83973775497876,
              21.02750376197023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11597",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027432160804636,
          "to_latitude": 21.02750376197023,
          "to_longitude": 105.83973775497876,
          "from_longitude": 105.8399952508535
        }
      },
      {
        "id": 11557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84019397947439,
            21.027769167862076,
            105.84032918310295,
            21.027829905924786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84032918310295,
              21.027769167862076
            ],
            [
              105.84019397947439,
              21.027809957983308
            ],
            [
              105.8401965673278,
              21.02781494182301
            ],
            [
              105.84020434346577,
              21.027829905924786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11598",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027769167862076,
          "to_latitude": 21.027829905924786,
          "to_longitude": 105.84020434346577,
          "from_longitude": 105.84032918310295
        }
      },
      {
        "id": 11558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8398727022158,
            21.027809957983308,
            105.84019397947439,
            21.027908297360852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019397947439,
              21.027809957983308
            ],
            [
              105.84012273889488,
              21.027831826524473
            ],
            [
              105.84009969799482,
              21.027838402009063
            ],
            [
              105.8398727022158,
              21.027908297360852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11599",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027809957983308,
          "to_latitude": 21.027908297360852,
          "to_longitude": 105.8398727022158,
          "from_longitude": 105.84019397947439
        }
      },
      {
        "id": 11559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84028053533801,
            21.027589709460745,
            105.84034189960144,
            21.027750399286052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84034189960144,
              21.027750399286052
            ],
            [
              105.84028053533801,
              21.027589709460745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11600",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027750399286052,
          "to_latitude": 21.027589709460745,
          "to_longitude": 105.84028053533801,
          "from_longitude": 105.84034189960144
        }
      },
      {
        "id": 11560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83972931136483,
            21.027651443505725,
            105.83983228981072,
            21.027944680947012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983228981072,
              21.027944680947012
            ],
            [
              105.83982591388123,
              21.027923271742917
            ],
            [
              105.83982585250342,
              21.027919356576923
            ],
            [
              105.83982560542754,
              21.027903760049423
            ],
            [
              105.83972931136483,
              21.027651443505725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11601",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027944680947012,
          "to_latitude": 21.027651443505725,
          "to_longitude": 105.83972931136483,
          "from_longitude": 105.83983228981072
        }
      },
      {
        "id": 11561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83974496237092,
            21.027915949890417,
            105.83982579853563,
            21.02793381620479
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83982579853563,
              21.027915949890417
            ],
            [
              105.83974496237092,
              21.02793381620479
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11602",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": 8.633,
          "from_latitude": 21.027915949890417,
          "to_latitude": 21.02793381620479,
          "to_longitude": 105.83974496237092,
          "from_longitude": 105.83982579853563
        }
      },
      {
        "id": 11562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395726257102,
            21.027596222990507,
            105.8396340523548,
            21.027612500670628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395726257102,
              21.027612500670628
            ],
            [
              105.83963009471493,
              21.02759726768908
            ],
            [
              105.8396340523548,
              21.027596222990507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.8_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11603",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027612500670628,
          "to_latitude": 21.027596222990507,
          "to_longitude": 105.8396340523548,
          "from_longitude": 105.8395726257102
        }
      },
      {
        "id": 11563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396340523548,
            21.02758964063629,
            105.83973144025639,
            21.027741765172923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8396340523548,
              21.02759622299052
            ],
            [
              105.83964103249511,
              21.027594809304706
            ],
            [
              105.83966654593638,
              21.02758964063629
            ],
            [
              105.83969861377498,
              21.02767089243975
            ],
            [
              105.8397188511342,
              21.027725068950495
            ],
            [
              105.83973144025639,
              21.027741765172923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.8_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11604",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02759622299052,
          "to_latitude": 21.027741765172923,
          "to_longitude": 105.83973144025639,
          "from_longitude": 105.8396340523548
        }
      },
      {
        "id": 11564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83963009471493,
            21.02759726768908,
            105.83971300463925,
            21.027830279880533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83963009471493,
              21.02759726768908
            ],
            [
              105.83969697769902,
              21.027774160521847
            ],
            [
              105.83971300463925,
              21.027830279880533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.8_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11605",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02759726768908,
          "to_latitude": 21.027830279880533,
          "to_longitude": 105.83971300463925,
          "from_longitude": 105.83963009471493
        }
      },
      {
        "id": 11565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83963980859768,
            21.027590992007095,
            105.83964103249511,
            21.027594809304706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83964103249511,
              21.027594809304706
            ],
            [
              105.83963985659504,
              21.02759114531793
            ],
            [
              105.83963980859768,
              21.027590992007095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.8_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11606",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027594809304706,
          "to_latitude": 21.027590992007095,
          "to_longitude": 105.83963980859768,
          "from_longitude": 105.83964103249511
        }
      },
      {
        "id": 11566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953080479766,
            21.02723867747551,
            105.83967161442087,
            21.027583909049703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953080479766,
              21.02723867747551
            ],
            [
              105.83953516338748,
              21.027254324978475
            ],
            [
              105.8395418131562,
              21.02727527209395
            ],
            [
              105.83957346207124,
              21.027371068781633
            ],
            [
              105.8396130572882,
              21.027490918783776
            ],
            [
              105.83966768971017,
              21.02757108189589
            ],
            [
              105.83967126754366,
              21.027582779927982
            ],
            [
              105.83967161442087,
              21.027583909049703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.6_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11607",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": 41.224,
          "from_latitude": 21.02723867747551,
          "to_latitude": 21.027583909049703,
          "to_longitude": 105.83967161442087,
          "from_longitude": 105.83953080479766
        }
      },
      {
        "id": 11567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908204489222,
            21.026556284637362,
            105.83921555934454,
            21.026965192110023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908204489222,
              21.026556284637362
            ],
            [
              105.83908251083902,
              21.02655754775328
            ],
            [
              105.83909216061524,
              21.02658365295225
            ],
            [
              105.83911816082909,
              21.02665997342641
            ],
            [
              105.83915192796388,
              21.02676579004613
            ],
            [
              105.83921555934454,
              21.026965192110023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.9_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11610",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026556284637362,
          "to_latitude": 21.026965192110023,
          "to_longitude": 105.83921555934454,
          "from_longitude": 105.83908204489222
        }
      },
      {
        "id": 11568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84114773383065,
            21.02727298373042,
            105.84149613641475,
            21.02731733219443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84149613641475,
              21.02727687742162
            ],
            [
              105.84145833257332,
              21.02727298373042
            ],
            [
              105.84145473053368,
              21.02727310632791
            ],
            [
              105.84125809877449,
              21.027279830910008
            ],
            [
              105.84125816297012,
              21.027307383386344
            ],
            [
              105.84114773383065,
              21.02731733219443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11611",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02727687742162,
          "to_latitude": 21.02731733219443,
          "to_longitude": 105.84114773383065,
          "from_longitude": 105.84149613641475
        }
      },
      {
        "id": 11569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84145473053368,
            21.02727310632791,
            105.8414650914151,
            21.027557598044634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84145473053368,
              21.02727310632791
            ],
            [
              105.8414650914151,
              21.027557598044634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11612",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02727310632791,
          "to_latitude": 21.027557598044634,
          "to_longitude": 105.8414650914151,
          "from_longitude": 105.84145473053368
        }
      },
      {
        "id": 11570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.841128563964,
            21.02718380022648,
            105.84149434118083,
            21.027244144981793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84149434118083,
              21.02718380022648
            ],
            [
              105.84145874935011,
              21.02718442073187
            ],
            [
              105.84132872956182,
              21.02718675759086
            ],
            [
              105.8411292465926,
              21.02719263987243
            ],
            [
              105.841128563964,
              21.027244144981793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.2_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11613",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02718380022648,
          "to_latitude": 21.027244144981793,
          "to_longitude": 105.841128563964,
          "from_longitude": 105.84149434118083
        }
      },
      {
        "id": 11571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84116296068231,
            21.0271231173823,
            105.84149427079103,
            21.0271858001606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84149427079103,
              21.02718017233125
            ],
            [
              105.84145871074338,
              21.0271809706141
            ],
            [
              105.84116331433997,
              21.0271858001606
            ],
            [
              105.84116296068231,
              21.0271231173823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.3_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11614",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02718017233125,
          "to_latitude": 21.0271231173823,
          "to_longitude": 105.84116296068231,
          "from_longitude": 105.84149427079103
        }
      },
      {
        "id": 11572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84129180251747,
            21.027117108558265,
            105.84149305766975,
            21.02711727342434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84149305766975,
              21.02711727342434
            ],
            [
              105.84148823502404,
              21.027117256643717
            ],
            [
              105.84148812823895,
              21.027117256268916
            ],
            [
              105.84144808059249,
              21.027117225920104
            ],
            [
              105.84129180251747,
              21.027117108558265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.4_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11615",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02711727342434,
          "to_latitude": 21.027117108558265,
          "to_longitude": 105.84129180251747,
          "from_longitude": 105.84149305766975
        }
      },
      {
        "id": 11573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84130050754786,
            21.027084455547424,
            105.84144808059249,
            21.027117225920104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84144808059249,
              21.027117225920104
            ],
            [
              105.84144636993736,
              21.027084455547424
            ],
            [
              105.84130050754786,
              21.02708690599858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.4_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11616",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027117225920104,
          "to_latitude": 21.02708690599858,
          "to_longitude": 105.84130050754786,
          "from_longitude": 105.84144808059249
        }
      },
      {
        "id": 11574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8412068110359,
            21.026885885461503,
            105.8414885947352,
            21.02690420687889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8412068110359,
              21.02690420687889
            ],
            [
              105.84144098556386,
              21.026888389032347
            ],
            [
              105.84144434967568,
              21.02688821251615
            ],
            [
              105.84144900442111,
              21.02688796819457
            ],
            [
              105.8414885947352,
              21.026885885461503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.6_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11617",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02690420687889,
          "to_latitude": 21.026885885461503,
          "to_longitude": 105.8414885947352,
          "from_longitude": 105.8412068110359
        }
      },
      {
        "id": 11575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84144434967568,
            21.02675971423324,
            105.84145128006168,
            21.02688821251615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84145101565154,
              21.02675971423324
            ],
            [
              105.84145128006168,
              21.026783854249057
            ],
            [
              105.84144434967568,
              21.02688821251615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.6_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11618",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02675971423324,
          "to_latitude": 21.02688821251615,
          "to_longitude": 105.84144434967568,
          "from_longitude": 105.84145101565154
        }
      },
      {
        "id": 11576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84111820826377,
            21.026623357616746,
            105.8414503439052,
            21.026750112827724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84111820826377,
              21.02671922173752
            ],
            [
              105.84129464729611,
              21.026723216420045
            ],
            [
              105.84131924283264,
              21.026750112827724
            ],
            [
              105.8414503439052,
              21.026750069337087
            ],
            [
              105.84144204339441,
              21.026623357616746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.7_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11619",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02671922173752,
          "to_latitude": 21.026623357616746,
          "to_longitude": 105.84144204339441,
          "from_longitude": 105.84111820826377
        }
      },
      {
        "id": 11577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84129034909894,
            21.026594591547074,
            105.84148347972368,
            21.02662414516737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84148347972368,
              21.02662075994823
            ],
            [
              105.84144681359706,
              21.026622774919048
            ],
            [
              105.84144204339441,
              21.026623357616746
            ],
            [
              105.84144264095218,
              21.026594591547074
            ],
            [
              105.84133084386981,
              21.026596564549568
            ],
            [
              105.84129034909894,
              21.02662414516737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.7_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.11620",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02662075994823,
          "to_latitude": 21.02662414516737,
          "to_longitude": 105.84129034909894,
          "from_longitude": 105.84148347972368
        }
      },
      {
        "id": 11578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839343145965,
            21.02600720052828,
            105.83966581912641,
            21.026404494331796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83966581912641,
              21.026404494331796
            ],
            [
              105.83965591485735,
              21.02637710875108
            ],
            [
              105.8395925669002,
              21.026219091109557
            ],
            [
              105.8395576365923,
              21.02612067138938
            ],
            [
              105.83951736566473,
              21.02600720052828
            ],
            [
              105.839343145965,
              21.026051681628385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.2_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11621",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026404494331796,
          "to_latitude": 21.026051681628385,
          "to_longitude": 105.839343145965,
          "from_longitude": 105.83966581912641
        }
      },
      {
        "id": 11579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946355418897,
            21.026241023284953,
            105.83966037960285,
            21.026406149699852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83966037960285,
              21.026406149699852
            ],
            [
              105.83960933726314,
              21.026275042437078
            ],
            [
              105.83954007510833,
              21.02629638069538
            ],
            [
              105.83952742230132,
              21.02626107115437
            ],
            [
              105.83947493666021,
              21.026275904652053
            ],
            [
              105.83946355418897,
              21.026241023284953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.3_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11622",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026406149699852,
          "to_latitude": 21.026241023284953,
          "to_longitude": 105.83946355418897,
          "from_longitude": 105.83966037960285
        }
      },
      {
        "id": 11580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393531260289,
            21.026403574408377,
            105.83965237188025,
            21.026486423887768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393531260289,
              21.026486423887768
            ],
            [
              105.83936573786305,
              21.02648280565147
            ],
            [
              105.8395238961957,
              21.026437426520328
            ],
            [
              105.83962099876133,
              21.02641332604882
            ],
            [
              105.83965237188025,
              21.026403574408377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.7_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11623",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026486423887768,
          "to_latitude": 21.026403574408377,
          "to_longitude": 105.83965237188025,
          "from_longitude": 105.8393531260289
        }
      },
      {
        "id": 11581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929200748638,
            21.026114650382304,
            105.8395413786922,
            21.02648729932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936717428567,
              21.02648729932
            ],
            [
              105.83936604892442,
              21.026483896112303
            ],
            [
              105.83936573786305,
              21.02648280565147
            ],
            [
              105.83932737087031,
              21.026348217037924
            ],
            [
              105.83929200748638,
              21.026200769761527
            ],
            [
              105.8395413786922,
              21.026114650382304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.6_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11624",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02648729932,
          "to_latitude": 21.026114650382304,
          "to_longitude": 105.8395413786922,
          "from_longitude": 105.83936717428567
        }
      },
      {
        "id": 11582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83906434297077,
            21.026486423887768,
            105.8393531260289,
            21.02655622896353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906896618218,
              21.026554351240293
            ],
            [
              105.83906434297077,
              21.026554900630202
            ],
            [
              105.83906440524001,
              21.02655622896353
            ],
            [
              105.83906907090994,
              21.02655570284791
            ],
            [
              105.83908790857372,
              21.026553580359558
            ],
            [
              105.83909662145143,
              21.026551344343943
            ],
            [
              105.83919997111879,
              21.026528562699195
            ],
            [
              105.8393321557496,
              21.026492694593816
            ],
            [
              105.8393531260289,
              21.026486423887768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.7_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11625",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026554351240293,
          "to_latitude": 21.026486423887768,
          "to_longitude": 105.8393531260289,
          "from_longitude": 105.83906896618218
        }
      },
      {
        "id": 11583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83930545904121,
            21.026116600317163,
            105.83959592363878,
            21.02647805193298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83937581422667,
              21.02647805193298
            ],
            [
              105.83933481935858,
              21.02634455293334
            ],
            [
              105.83930545904121,
              21.026204696521074
            ],
            [
              105.83953836048563,
              21.026122136740085
            ],
            [
              105.8395576365923,
              21.02612067138938
            ],
            [
              105.83956658806491,
              21.026119990425187
            ],
            [
              105.83958888453496,
              21.026118295192727
            ],
            [
              105.83959592363878,
              21.026116600317163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.5_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11626",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02647805193298,
          "to_latitude": 21.026116600317163,
          "to_longitude": 105.83959592363878,
          "from_longitude": 105.83937581422667
        }
      },
      {
        "id": 11584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877533824247,
            21.026379207974294,
            105.8388712861127,
            21.026620989876225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388712861127,
              21.026620989876225
            ],
            [
              105.83886931878988,
              21.02661514127834
            ],
            [
              105.83885843150992,
              21.026582775610997
            ],
            [
              105.83883405494251,
              21.02652409786182
            ],
            [
              105.8387979614179,
              21.026435032973044
            ],
            [
              105.83877533824247,
              21.026379207974294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.11_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11627",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026620989876225,
          "to_latitude": 21.026379207974294,
          "to_longitude": 105.83877533824247,
          "from_longitude": 105.8388712861127
        }
      },
      {
        "id": 11585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8387979614179,
            21.026427786009528,
            105.8388727533246,
            21.026438342586143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8387979614179,
              21.026435032973044
            ],
            [
              105.83880820146088,
              21.026432708149567
            ],
            [
              105.83882972015098,
              21.02642782215274
            ],
            [
              105.83884095174707,
              21.026438342586143
            ],
            [
              105.8388727533246,
              21.026427786009528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.11_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11628",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026435032973044,
          "to_latitude": 21.026427786009528,
          "to_longitude": 105.8388727533246,
          "from_longitude": 105.8387979614179
        }
      },
      {
        "id": 11586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83860220498799,
            21.026627324547125,
            105.83882726551478,
            21.02666542751024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83860220498799,
              21.026633844845314
            ],
            [
              105.8386793777949,
              21.02666542751024
            ],
            [
              105.83873137722092,
              21.026652030065197
            ],
            [
              105.83882726551478,
              21.026627324547125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.12_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11629",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026633844845314,
          "to_latitude": 21.026627324547125,
          "to_longitude": 105.83882726551478,
          "from_longitude": 105.83860220498799
        }
      },
      {
        "id": 11587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83882726551478,
            21.026569977975857,
            105.83902519771763,
            21.026627324547125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882726551478,
              21.026627324547125
            ],
            [
              105.83886205202985,
              21.026617246407522
            ],
            [
              105.83886931878988,
              21.02661514127834
            ],
            [
              105.83894586561215,
              21.026592962578178
            ],
            [
              105.83902519771763,
              21.026569977975857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.12_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11630",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026627324547125,
          "to_latitude": 21.026569977975857,
          "to_longitude": 105.83902519771763,
          "from_longitude": 105.83882726551478
        }
      },
      {
        "id": 11588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892892313439,
            21.02653905241036,
            105.83894586561215,
            21.026592962578178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83894586561215,
              21.026592962578178
            ],
            [
              105.83894511437396,
              21.026590571840917
            ],
            [
              105.83893488501971,
              21.026558021385263
            ],
            [
              105.83892892313439,
              21.02653905241036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.12_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11631",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026592962578178,
          "to_latitude": 21.02653905241036,
          "to_longitude": 105.83892892313439,
          "from_longitude": 105.83894586561215
        }
      },
      {
        "id": 11589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893488501971,
            21.026545853884155,
            105.83898307402767,
            21.026558021385263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893488501971,
              21.026558021385263
            ],
            [
              105.83893491191972,
              21.026558014929996
            ],
            [
              105.83898307402767,
              21.026545853884155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.12_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11632",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026558021385263,
          "to_latitude": 21.026545853884155,
          "to_longitude": 105.83898307402767,
          "from_longitude": 105.83893488501971
        }
      },
      {
        "id": 11590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869650631554,
            21.026501471330775,
            105.83873137722092,
            21.026652030065197
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83873137722092,
              21.026652030065197
            ],
            [
              105.83872971267088,
              21.026645962295607
            ],
            [
              105.83869650631554,
              21.026524943383233
            ],
            [
              105.83871168043581,
              21.026514526645627
            ],
            [
              105.83870676273379,
              21.026501471330775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.12_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11633",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026652030065197,
          "to_latitude": 21.026501471330775,
          "to_longitude": 105.83870676273379,
          "from_longitude": 105.83873137722092
        }
      },
      {
        "id": 11591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83847951747806,
            21.02639423143127,
            105.83871670536766,
            21.026481470066923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83847951747806,
              21.026481470066923
            ],
            [
              105.83849378161509,
              21.026476224269462
            ],
            [
              105.83850944067235,
              21.02647046488486
            ],
            [
              105.83869795791315,
              21.0264011269655
            ],
            [
              105.83871670536766,
              21.02639423143127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11634",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026481470066923,
          "to_latitude": 21.02639423143127,
          "to_longitude": 105.83871670536766,
          "from_longitude": 105.83847951747806
        }
      },
      {
        "id": 11592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867921701413,
            21.026356232575342,
            105.83869795791315,
            21.0264011269655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83869795791315,
              21.0264011269655
            ],
            [
              105.83867921701413,
              21.026356232575342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11635",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0264011269655,
          "to_latitude": 21.026356232575342,
          "to_longitude": 105.83867921701413,
          "from_longitude": 105.83869795791315
        }
      },
      {
        "id": 11593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850944067235,
            21.02641866249891,
            105.83872398136215,
            21.026485682296475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850944067235,
              21.02647046488486
            ],
            [
              105.83850958875733,
              21.0264707974439
            ],
            [
              105.83851623577867,
              21.026485682296475
            ],
            [
              105.83862326602625,
              21.02644551297575
            ],
            [
              105.8386409551685,
              21.02644289581552
            ],
            [
              105.83870685740328,
              21.02641866249891
            ],
            [
              105.83872398136215,
              21.026435732954205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11636",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02647046488486,
          "to_latitude": 21.026435732954205,
          "to_longitude": 105.83872398136215,
          "from_longitude": 105.83850944067235
        }
      },
      {
        "id": 11594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386409551685,
            21.02644289581552,
            105.8386562723649,
            21.02647834583547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386409551685,
              21.02644289581552
            ],
            [
              105.8386562723649,
              21.02647834583547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11637",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02644289581552,
          "to_latitude": 21.02647834583547,
          "to_longitude": 105.8386562723649,
          "from_longitude": 105.8386409551685
        }
      },
      {
        "id": 11595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842022799661,
            21.026301357180103,
            105.83848346700879,
            21.026435120683708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83842022799661,
              21.026301357180103
            ],
            [
              105.83844158121671,
              21.026346524140347
            ],
            [
              105.83845836862264,
              21.02638203196357
            ],
            [
              105.83848346700879,
              21.026435120683708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11638",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026301357180103,
          "to_latitude": 21.026435120683708,
          "to_longitude": 105.83848346700879,
          "from_longitude": 105.83842022799661
        }
      },
      {
        "id": 11596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83848346700879,
            21.026435120683708,
            105.83856958008577,
            21.02661726810507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848346700879,
              21.026435120683708
            ],
            [
              105.83849480952394,
              21.026459112161643
            ],
            [
              105.83849378161509,
              21.026476224269462
            ],
            [
              105.83850624986705,
              21.026484253862087
            ],
            [
              105.83854610051195,
              21.026567930631344
            ],
            [
              105.83856958008577,
              21.02661726810507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11639",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026435120683708,
          "to_latitude": 21.02661726810507,
          "to_longitude": 105.83856958008577,
          "from_longitude": 105.83848346700879
        }
      },
      {
        "id": 11597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83845836862264,
            21.02630380214087,
            105.83865507367882,
            21.02638203196357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83845836862264,
              21.02638203196357
            ],
            [
              105.83865507367882,
              21.02630380214087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11640",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02638203196357,
          "to_latitude": 21.02630380214087,
          "to_longitude": 105.83865507367882,
          "from_longitude": 105.83845836862264
        }
      },
      {
        "id": 11598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854610051195,
            21.026545003364895,
            105.83860743289944,
            21.026567930631344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83854610051195,
              21.026567930631344
            ],
            [
              105.83860743289944,
              21.026545003364895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11641",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026567930631344,
          "to_latitude": 21.026545003364895,
          "to_longitude": 105.83860743289944,
          "from_longitude": 105.83854610051195
        }
      },
      {
        "id": 11599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83840687202904,
            21.026208755743003,
            105.83857966535604,
            21.026279085423425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83840687202904,
              21.026279085423425
            ],
            [
              105.83857966535604,
              21.026208755743003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11642",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026279085423425,
          "to_latitude": 21.026208755743003,
          "to_longitude": 105.83857966535604,
          "from_longitude": 105.83840687202904
        }
      },
      {
        "id": 11600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856329650004,
            21.02617188742034,
            105.83859699876953,
            21.026247798783857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83856329650004,
              21.02617188742034
            ],
            [
              105.83857966535604,
              21.026208755743003
            ],
            [
              105.83859699876953,
              21.026247798783857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11643",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02617188742034,
          "to_latitude": 21.026247798783857,
          "to_longitude": 105.83859699876953,
          "from_longitude": 105.83856329650004
        }
      },
      {
        "id": 11601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83826297728154,
            21.025952708876765,
            105.83840687202904,
            21.026279085423425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83840687202904,
              21.026279085423425
            ],
            [
              105.83840546407448,
              21.026275892246318
            ],
            [
              105.8382774494584,
              21.02598553562678
            ],
            [
              105.83826297728154,
              21.025952708876765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11644",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026279085423425,
          "to_latitude": 21.025952708876765,
          "to_longitude": 105.83826297728154,
          "from_longitude": 105.83840687202904
        }
      },
      {
        "id": 11602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899379972519,
            21.026346686426653,
            105.83905628041833,
            21.026530325786318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905628041833,
              21.026530325786318
            ],
            [
              105.83899379972519,
              21.026346686426653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.3_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11645",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026530325786318,
          "to_latitude": 21.026346686426653,
          "to_longitude": 105.83899379972519,
          "from_longitude": 105.83905628041833
        }
      },
      {
        "id": 11603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893473580648,
            21.026180505733812,
            105.83899379972519,
            21.026346686426653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899379972519,
              21.026346686426653
            ],
            [
              105.83893473580648,
              21.026180505733812
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.3_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11646",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026346686426653,
          "to_latitude": 21.026180505733812,
          "to_longitude": 105.83893473580648,
          "from_longitude": 105.83899379972519
        }
      },
      {
        "id": 11604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879763820372,
            21.026147613801044,
            105.83893257385878,
            21.02623301796107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893257385878,
              21.026147613801044
            ],
            [
              105.83880460984713,
              21.026186940890675
            ],
            [
              105.83879763820372,
              21.02623301796107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.4_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11647",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026147613801044,
          "to_latitude": 21.02623301796107,
          "to_longitude": 105.83879763820372,
          "from_longitude": 105.83893257385878
        }
      },
      {
        "id": 11605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897371408754,
            21.026158301759814,
            105.83927504337494,
            21.026246997473066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897371408754,
              21.026246997473066
            ],
            [
              105.83908901336913,
              21.026198053811182
            ],
            [
              105.83927504337494,
              21.026158301759814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.1_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11648",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026246997473066,
          "to_latitude": 21.026158301759814,
          "to_longitude": 105.83927504337494,
          "from_longitude": 105.83897371408754
        }
      },
      {
        "id": 11606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897371408754,
            21.026246997473066,
            105.83907710017795,
            21.026545971651597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897371408754,
              21.026246997473066
            ],
            [
              105.83899247243207,
              21.0262972306926
            ],
            [
              105.83900857870813,
              21.02634457132636
            ],
            [
              105.83907710017795,
              21.026545971651597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.1_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11649",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026246997473066,
          "to_latitude": 21.026545971651597,
          "to_longitude": 105.83907710017795,
          "from_longitude": 105.83897371408754
        }
      },
      {
        "id": 11607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908901336913,
            21.026198053811182,
            105.83910747297395,
            21.02626726610358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908901336913,
              21.026198053811182
            ],
            [
              105.83910747297395,
              21.02626726610358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.1_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11650",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026198053811182,
          "to_latitude": 21.02626726610358,
          "to_longitude": 105.83910747297395,
          "from_longitude": 105.83908901336913
        }
      },
      {
        "id": 11608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838967432267,
            21.026138816631605,
            105.83929341651817,
            21.02626501285336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897176103119,
              21.02626501285336
            ],
            [
              105.83897284561233,
              21.026264225311788
            ],
            [
              105.83897572089772,
              21.02626196481183
            ],
            [
              105.838967432267,
              21.02623411689497
            ],
            [
              105.83898977192605,
              21.026224663747378
            ],
            [
              105.83908782769961,
              21.02619057736445
            ],
            [
              105.83920109677655,
              21.026168045748378
            ],
            [
              105.83928893335933,
              21.026138816631605
            ],
            [
              105.83929341651817,
              21.02614968195217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.2_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11651",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02626501285336,
          "to_latitude": 21.02614968195217,
          "to_longitude": 105.83929341651817,
          "from_longitude": 105.83897176103119
        }
      },
      {
        "id": 11609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908897091415,
            21.025917304145963,
            105.83920109677655,
            21.026168045748378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920109677655,
              21.026168045748378
            ],
            [
              105.83919555476733,
              21.0261244122147
            ],
            [
              105.8391718332666,
              21.02599200662911
            ],
            [
              105.83916076286658,
              21.025938444148863
            ],
            [
              105.83914430397041,
              21.025917304145963
            ],
            [
              105.83912672849318,
              21.025920106859736
            ],
            [
              105.83908897091415,
              21.02592612798045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.2_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11652",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026168045748378,
          "to_latitude": 21.02592612798045,
          "to_longitude": 105.83908897091415,
          "from_longitude": 105.83920109677655
        }
      },
      {
        "id": 11610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912062837017,
            21.02586303431457,
            105.83912672849318,
            21.025920106859736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912672849318,
              21.025920106859736
            ],
            [
              105.83912062837017,
              21.02586303431457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.2_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11653",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025920106859736,
          "to_latitude": 21.02586303431457,
          "to_longitude": 105.83912062837017,
          "from_longitude": 105.83912672849318
        }
      },
      {
        "id": 11611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907888934648,
            21.02599200662911,
            105.8391718332666,
            21.026012463631368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391718332666,
              21.02599200662911
            ],
            [
              105.83910741858062,
              21.026012463631368
            ],
            [
              105.83910221345153,
              21.025996183434344
            ],
            [
              105.83907888934648,
              21.026002822494906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.2_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11654",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02599200662911,
          "to_latitude": 21.026002822494906,
          "to_longitude": 105.83907888934648,
          "from_longitude": 105.8391718332666
        }
      },
      {
        "id": 11612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892369677567,
            21.026078729955334,
            105.83895707916597,
            21.026203000463727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892369677567,
              21.026078729955334
            ],
            [
              105.83892891104904,
              21.026122948599074
            ],
            [
              105.83893541406655,
              21.02614475825105
            ],
            [
              105.8389506590789,
              21.02619102559616
            ],
            [
              105.83895707916597,
              21.026203000463727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.5_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11655",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026078729955334,
          "to_latitude": 21.026203000463727,
          "to_longitude": 105.83895707916597,
          "from_longitude": 105.83892369677567
        }
      },
      {
        "id": 11613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892194848569,
            21.02603174194673,
            105.83907210371966,
            21.026112883858236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892194848569,
              21.02606233966752
            ],
            [
              105.83902908191234,
              21.02603174194673
            ],
            [
              105.8390429474145,
              21.026063224914303
            ],
            [
              105.839056432361,
              21.02607655859415
            ],
            [
              105.83907210371966,
              21.026112883858236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.5_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11656",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02606233966752,
          "to_latitude": 21.026112883858236,
          "to_longitude": 105.83907210371966,
          "from_longitude": 105.83892194848569
        }
      },
      {
        "id": 11614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941058568435,
            21.02559950952352,
            105.8395172968035,
            21.025889255343152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395172968035,
              21.025889255343152
            ],
            [
              105.83943548350645,
              21.02568809512
            ],
            [
              105.83941058568435,
              21.02562687835397
            ],
            [
              105.83948237174167,
              21.02559950952352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.3_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11657",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025889255343152,
          "to_latitude": 21.02559950952352,
          "to_longitude": 105.83948237174167,
          "from_longitude": 105.8395172968035
        }
      },
      {
        "id": 11615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395430204464,
            21.02552846160184,
            105.8395969333034,
            21.025557038894807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395430204464,
              21.02552846160184
            ],
            [
              105.83955340268643,
              21.025557038894807
            ],
            [
              105.8395969333034,
              21.025552941940173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11658",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02552846160184,
          "to_latitude": 21.025552941940173,
          "to_longitude": 105.8395969333034,
          "from_longitude": 105.8395430204464
        }
      },
      {
        "id": 11616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83940010108873,
            21.02548784894968,
            105.8395430204464,
            21.025583280963236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83940010108873,
              21.025583280963236
            ],
            [
              105.83945556341378,
              21.02556200637133
            ],
            [
              105.8395430204464,
              21.02552846160184
            ],
            [
              105.8395304255552,
              21.02548784894968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11659",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025583280963236,
          "to_latitude": 21.02548784894968,
          "to_longitude": 105.8395304255552,
          "from_longitude": 105.83940010108873
        }
      },
      {
        "id": 11617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927882845282,
            21.02510447638716,
            105.83945556341378,
            21.02556200637133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945556341378,
              21.02556200637133
            ],
            [
              105.8393559036799,
              21.025316049434046
            ],
            [
              105.83927882845282,
              21.025125829943935
            ],
            [
              105.83929081948695,
              21.02510447638716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11660",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02556200637133,
          "to_latitude": 21.02510447638716,
          "to_longitude": 105.83929081948695,
          "from_longitude": 105.83945556341378
        }
      },
      {
        "id": 11618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393559036799,
            21.025285095392334,
            105.83947721838653,
            21.025330659106412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393559036799,
              21.025316049434046
            ],
            [
              105.83935819965735,
              21.025315306491798
            ],
            [
              105.83945154823373,
              21.025285095392334
            ],
            [
              105.83947721838653,
              21.025330659106412
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11661",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025316049434046,
          "to_latitude": 21.025330659106412,
          "to_longitude": 105.83947721838653,
          "from_longitude": 105.8393559036799
        }
      },
      {
        "id": 11619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936489398333,
            21.025646366907647,
            105.83953857102112,
            21.025995997571346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936489398333,
              21.025646366907647
            ],
            [
              105.83945394861323,
              21.025852592826464
            ],
            [
              105.8395012810377,
              21.025962200990744
            ],
            [
              105.83951504510875,
              21.025995997571346
            ],
            [
              105.83953031337973,
              21.025992106082114
            ],
            [
              105.83953857102112,
              21.025989839776173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.7_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11662",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025646366907647,
          "to_latitude": 21.025989839776173,
          "to_longitude": 105.83953857102112,
          "from_longitude": 105.83936489398333
        }
      },
      {
        "id": 11620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932618163541,
            21.025852592826464,
            105.83945394861323,
            21.025891129909628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83945394861323,
              21.025852592826464
            ],
            [
              105.83936170579454,
              21.025887751751053
            ],
            [
              105.83932618163541,
              21.025891129909628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.7_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11663",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025852592826464,
          "to_latitude": 21.025891129909628,
          "to_longitude": 105.83932618163541,
          "from_longitude": 105.83945394861323
        }
      },
      {
        "id": 11621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931615831608,
            21.025887751751053,
            105.83939308506493,
            21.02598330506156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936170579454,
              21.025887751751053
            ],
            [
              105.83939308506493,
              21.025952822194455
            ],
            [
              105.83936398778913,
              21.02598330506156
            ],
            [
              105.83931615831608,
              21.025979924621897
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.7_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11664",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025887751751053,
          "to_latitude": 21.025979924621897,
          "to_longitude": 105.83931615831608,
          "from_longitude": 105.83936170579454
        }
      },
      {
        "id": 11622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83913087238984,
            21.025053814478508,
            105.8392657467423,
            21.025419773535706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924174009985,
              21.025419773535706
            ],
            [
              105.83925712624676,
              21.025413404853698
            ],
            [
              105.8392657467423,
              21.025409800904516
            ],
            [
              105.83924362731386,
              21.025353915669825
            ],
            [
              105.83916360111631,
              21.02515172221094
            ],
            [
              105.83913087238984,
              21.025053814478508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.5_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11665",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025419773535706,
          "to_latitude": 21.025053814478508,
          "to_longitude": 105.83913087238984,
          "from_longitude": 105.83924174009985
        }
      },
      {
        "id": 11623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83875920488438,
            21.024301391181922,
            105.83931730723326,
            21.024961955990854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393159582768,
              21.0248711058746
            ],
            [
              105.83931730723326,
              21.024883864465206
            ],
            [
              105.83931724046992,
              21.024890497159785
            ],
            [
              105.83909580512093,
              21.02489111203668
            ],
            [
              105.83905786279401,
              21.024904126806632
            ],
            [
              105.83894808952809,
              21.024941779074155
            ],
            [
              105.83889777728986,
              21.02495280541709
            ],
            [
              105.83885602304433,
              21.024961955990854
            ],
            [
              105.83883871059297,
              21.02488348718584
            ],
            [
              105.83875920488438,
              21.024523132987024
            ],
            [
              105.83894215413905,
              21.02444479821489
            ],
            [
              105.83890325429795,
              21.024379510953306
            ],
            [
              105.83888091841041,
              21.024301391181922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_26.6_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11666",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 139.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0248711058746,
          "to_latitude": 21.024301391181922,
          "to_longitude": 105.83888091841041,
          "from_longitude": 105.8393159582768
        }
      },
      {
        "id": 11624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83889777728986,
            21.02495280541709,
            105.83895533085041,
            21.02502040685351
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83889777728986,
              21.02495280541709
            ],
            [
              105.83892511646171,
              21.02502040685351
            ],
            [
              105.83895533085041,
              21.02501250183126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_26.6_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11667",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02495280541709,
          "to_latitude": 21.02501250183126,
          "to_longitude": 105.83895533085041,
          "from_longitude": 105.83889777728986
        }
      },
      {
        "id": 11625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904252894929,
            21.024946132772488,
            105.83931295595252,
            21.025638181726258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931295595252,
              21.025638181726258
            ],
            [
              105.83930241502581,
              21.025623695475552
            ],
            [
              105.83928520956295,
              21.025601339818913
            ],
            [
              105.8391550624565,
              21.025251237173887
            ],
            [
              105.83904252894929,
              21.024946132772488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.6_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11668",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025638181726258,
          "to_latitude": 21.024946132772488,
          "to_longitude": 105.83904252894929,
          "from_longitude": 105.83931295595252
        }
      },
      {
        "id": 11626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871683903114,
            21.02488348718584,
            105.83883871059297,
            21.024898062020245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883871059297,
              21.02488348718584
            ],
            [
              105.83871683903114,
              21.024898062020245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_26.6_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11669",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02488348718584,
          "to_latitude": 21.024898062020245,
          "to_longitude": 105.83871683903114,
          "from_longitude": 105.83883871059297
        }
      },
      {
        "id": 11627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83961488875374,
            21.025692542100675,
            105.83976549044354,
            21.026080763194432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83976549044354,
              21.026080763194432
            ],
            [
              105.8397637300814,
              21.026077346867066
            ],
            [
              105.83972173680263,
              21.025995780063507
            ],
            [
              105.83971781857973,
              21.025943228241026
            ],
            [
              105.83961488875374,
              21.025692542100675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.2_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11670",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026080763194432,
          "to_latitude": 21.025692542100675,
          "to_longitude": 105.83961488875374,
          "from_longitude": 105.83976549044354
        }
      },
      {
        "id": 11628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952620555507,
            21.025397863232737,
            105.83982758125454,
            21.02543395912787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83982758125454,
              21.025397863232737
            ],
            [
              105.83982643661945,
              21.02539788875617
            ],
            [
              105.8397286558493,
              21.025400055204273
            ],
            [
              105.83968732102227,
              21.02540097103805
            ],
            [
              105.8396729085345,
              21.025403921672545
            ],
            [
              105.83958236324413,
              21.02542246025592
            ],
            [
              105.83952620555507,
              21.02543395912787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11671",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025397863232737,
          "to_latitude": 21.02543395912787,
          "to_longitude": 105.83952620555507,
          "from_longitude": 105.83982758125454
        }
      },
      {
        "id": 11629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83957067678088,
            21.025349778715146,
            105.83958236324413,
            21.02542246025592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958236324413,
              21.02542246025592
            ],
            [
              105.83957067678088,
              21.025349778715146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11672",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02542246025592,
          "to_latitude": 21.025349778715146,
          "to_longitude": 105.83957067678088,
          "from_longitude": 105.83958236324413
        }
      },
      {
        "id": 11630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83968480126204,
            21.025248725734166,
            105.8397286558493,
            21.025400055204273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397286558493,
              21.025400055204273
            ],
            [
              105.83972278755526,
              21.025248725734166
            ],
            [
              105.83968480126204,
              21.02524891509921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11673",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025400055204273,
          "to_latitude": 21.02524891509921,
          "to_longitude": 105.83968480126204,
          "from_longitude": 105.8397286558493
        }
      },
      {
        "id": 11631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971663317477,
            21.02560948519725,
            105.83983092764203,
            21.02561107409238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983092764203,
              21.02561107409238
            ],
            [
              105.83971663317477,
              21.02560948519725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11674",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02561107409238,
          "to_latitude": 21.02560948519725,
          "to_longitude": 105.83971663317477,
          "from_longitude": 105.83983092764203
        }
      },
      {
        "id": 11632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969783881959,
            21.025522620233936,
            105.83971663317477,
            21.02560948519725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969783881959,
              21.025522620233936
            ],
            [
              105.8397046458234,
              21.025591989350747
            ],
            [
              105.83971663317477,
              21.02560948519725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11675",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025522620233936,
          "to_latitude": 21.02560948519725,
          "to_longitude": 105.83971663317477,
          "from_longitude": 105.83969783881959
        }
      },
      {
        "id": 11633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396979282339,
            21.02560948519725,
            105.83971663317477,
            21.025783498440084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971663317477,
              21.02560948519725
            ],
            [
              105.83971630989319,
              21.025629570852644
            ],
            [
              105.8397150906859,
              21.025752650895015
            ],
            [
              105.8396979282339,
              21.025756021561122
            ],
            [
              105.8396990374028,
              21.025783498440084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11676",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02560948519725,
          "to_latitude": 21.025783498440084,
          "to_longitude": 105.8396990374028,
          "from_longitude": 105.83971663317477
        }
      },
      {
        "id": 11634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83965813903129,
            21.025305684222953,
            105.8396729085345,
            21.025403921672545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8396729085345,
              21.025403921672545
            ],
            [
              105.83966894404051,
              21.025356670183694
            ],
            [
              105.8396585495853,
              21.025333642357964
            ],
            [
              105.83965813903129,
              21.025305684222953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11677",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025403921672545,
          "to_latitude": 21.025305684222953,
          "to_longitude": 105.83965813903129,
          "from_longitude": 105.8396729085345
        }
      },
      {
        "id": 11635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909261723697,
            21.0256679213889,
            105.83922123123175,
            21.02567834514976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83922123123175,
              21.0256679213889
            ],
            [
              105.83909261723697,
              21.02567834514976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.5_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11678",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0256679213889,
          "to_latitude": 21.02567834514976,
          "to_longitude": 105.83909261723697,
          "from_longitude": 105.83922123123175
        }
      },
      {
        "id": 11636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83925926331298,
            21.025660451508877,
            105.83927014560297,
            21.025774528050967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927014560297,
              21.025660451508877
            ],
            [
              105.83927012651392,
              21.025660650312066
            ],
            [
              105.83925926331298,
              21.025774528050967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.5_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11679",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025660451508877,
          "to_latitude": 21.025774528050967,
          "to_longitude": 105.83925926331298,
          "from_longitude": 105.83927014560297
        }
      },
      {
        "id": 11637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83898342482814,
            21.025631181170468,
            105.83909782318857,
            21.02565177183401
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909782318857,
              21.025631181170468
            ],
            [
              105.83908871164613,
              21.02563282121838
            ],
            [
              105.83898342482814,
              21.02565177183401
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.3_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11680",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025631181170468,
          "to_latitude": 21.02565177183401,
          "to_longitude": 105.83898342482814,
          "from_longitude": 105.83909782318857
        }
      },
      {
        "id": 11638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907583806236,
            21.0255869116,
            105.83908871164613,
            21.02563282121838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908871164613,
              21.02563282121838
            ],
            [
              105.83907583806236,
              21.0255869116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.3_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11681",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02563282121838,
          "to_latitude": 21.0255869116,
          "to_longitude": 105.83907583806236,
          "from_longitude": 105.83908871164613
        }
      },
      {
        "id": 11639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879678024917,
            21.025105016212354,
            105.83897779738952,
            21.025364332071504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879774362565,
              21.025364332071504
            ],
            [
              105.83880055852124,
              21.02536297238573
            ],
            [
              105.83880243944652,
              21.025361807886522
            ],
            [
              105.83880408453525,
              21.02536078906614
            ],
            [
              105.83879681619501,
              21.025336122673647
            ],
            [
              105.83879678024917,
              21.025335028144834
            ],
            [
              105.83897779738952,
              21.025293921946396
            ],
            [
              105.83894123775484,
              21.025105016212354
            ],
            [
              105.83881669389784,
              21.025128069310057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.10_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11682",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025364332071504,
          "to_latitude": 21.025128069310057,
          "to_longitude": 105.83881669389784,
          "from_longitude": 105.83879774362565
        }
      },
      {
        "id": 11640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83898167301857,
            21.025306424204818,
            105.83904279255249,
            21.025514718461643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904277316616,
              21.025514718461643
            ],
            [
              105.83904279255249,
              21.02550340008121
            ],
            [
              105.83898167301857,
              21.025306424204818
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.2_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11683",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025514718461643,
          "to_latitude": 21.025306424204818,
          "to_longitude": 105.83898167301857,
          "from_longitude": 105.83904277316616
        }
      },
      {
        "id": 11641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83887491210102,
            21.02550340008121,
            105.83904279255249,
            21.02553846312372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904279255249,
              21.02550340008121
            ],
            [
              105.83899962883368,
              21.025513753514694
            ],
            [
              105.83887491210102,
              21.02553846312372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.2_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11684",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02550340008121,
          "to_latitude": 21.02553846312372,
          "to_longitude": 105.83887491210102,
          "from_longitude": 105.83904279255249
        }
      },
      {
        "id": 11642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863831467373,
            21.024869936316442,
            105.83877941679977,
            21.025299718286934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863831467373,
              21.024869936316442
            ],
            [
              105.83877941679977,
              21.025299718286934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.11_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11685",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024869936316442,
          "to_latitude": 21.025299718286934,
          "to_longitude": 105.83877941679977,
          "from_longitude": 105.83863831467373
        }
      },
      {
        "id": 11643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886440650902,
            21.025516144151712,
            105.83905247480494,
            21.02555177335222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905247480494,
              21.025519509160887
            ],
            [
              105.83904648410535,
              21.025521169034562
            ],
            [
              105.8390393958699,
              21.025516144151712
            ],
            [
              105.83900520817575,
              21.025521787252686
            ],
            [
              105.83886440650902,
              21.02555177335222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.1_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11686",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025519509160887,
          "to_latitude": 21.02555177335222,
          "to_longitude": 105.83886440650902,
          "from_longitude": 105.83905247480494
        }
      },
      {
        "id": 11644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83894607919643,
            21.025777637714143,
            105.838954136972,
            21.025831109396844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838954136972,
              21.025831109396844
            ],
            [
              105.83895014952924,
              21.025804645672817
            ],
            [
              105.83894607919643,
              21.025777637714143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.8_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11687",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025831109396844,
          "to_latitude": 21.025777637714143,
          "to_longitude": 105.83894607919643,
          "from_longitude": 105.838954136972
        }
      },
      {
        "id": 11645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953302274715,
            21.024544855311905,
            105.83984179798301,
            21.024609735449086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83984179798301,
              21.02456057693031
            ],
            [
              105.83983682329881,
              21.02456105310766
            ],
            [
              105.83982447598517,
              21.024562231367092
            ],
            [
              105.83968431523714,
              21.02457145437797
            ],
            [
              105.8396086197073,
              21.024609735449086
            ],
            [
              105.83957014457258,
              21.024576714791838
            ],
            [
              105.83953302274715,
              21.024544855311905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.6_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11688",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02456057693031,
          "to_latitude": 21.024544855311905,
          "to_longitude": 105.83953302274715,
          "from_longitude": 105.83984179798301
        }
      },
      {
        "id": 11646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947465258045,
            21.024576714791838,
            105.83957014457258,
            21.024646857167262
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83957014457258,
              21.024576714791838
            ],
            [
              105.83956902461105,
              21.02457753773729
            ],
            [
              105.83947465258045,
              21.024646857167262
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.6_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11689",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024576714791838,
          "to_latitude": 21.024646857167262,
          "to_longitude": 105.83947465258045,
          "from_longitude": 105.83957014457258
        }
      },
      {
        "id": 11647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969967091006,
            21.024562231367092,
            105.83982694014719,
            21.024668559009307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83982447598517,
              21.024562231367092
            ],
            [
              105.83982694014719,
              21.02466759277744
            ],
            [
              105.83969967091006,
              21.024668559009307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.6_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11690",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024562231367092,
          "to_latitude": 21.024668559009307,
          "to_longitude": 105.83969967091006,
          "from_longitude": 105.83982447598517
        }
      },
      {
        "id": 11648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83956806124074,
            21.02441804508764,
            105.83983983970867,
            21.024516775292067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956806124074,
              21.02441804508764
            ],
            [
              105.83964054808891,
              21.024516775292067
            ],
            [
              105.83982244680529,
              21.02450601396432
            ],
            [
              105.83983116224537,
              21.024505288993268
            ],
            [
              105.83983983970867,
              21.024505684205565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.5_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11691",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02441804508764,
          "to_latitude": 21.024505684205565,
          "to_longitude": 105.83983983970867,
          "from_longitude": 105.83956806124074
        }
      },
      {
        "id": 11649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83957247857045,
            21.024360788070798,
            105.83983467046708,
            21.02439061136564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983467046708,
              21.024360831897386
            ],
            [
              105.83982452313322,
              21.024360788070798
            ],
            [
              105.83981714520681,
              21.024362947076906
            ],
            [
              105.83980586510448,
              21.024366248057483
            ],
            [
              105.83976928586328,
              21.024368912543224
            ],
            [
              105.8397447262377,
              21.024380595965656
            ],
            [
              105.83957449244998,
              21.02439061136564
            ],
            [
              105.83957247857045,
              21.024371981498103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.5.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11692",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024360831897386,
          "to_latitude": 21.024371981498103,
          "to_longitude": 105.83957247857045,
          "from_longitude": 105.83983467046708
        }
      },
      {
        "id": 11650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927614434383,
            21.024300328072048,
            105.83958013399673,
            21.024565565313466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958013399673,
              21.024300328072048
            ],
            [
              105.83957083728447,
              21.02430942787178
            ],
            [
              105.83951907385853,
              21.02434677424336
            ],
            [
              105.83949687606496,
              21.02434834974698
            ],
            [
              105.83927614434383,
              21.024364016281407
            ],
            [
              105.83927737072973,
              21.024565565313466
            ],
            [
              105.83933859464992,
              21.02456157621318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11693",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024300328072048,
          "to_latitude": 21.02456157621318,
          "to_longitude": 105.83933859464992,
          "from_longitude": 105.83958013399673
        }
      },
      {
        "id": 11651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83937173362682,
            21.02434834974698,
            105.83950173012911,
            21.024468832319876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83949687606496,
              21.02434834974698
            ],
            [
              105.83949695998399,
              21.024349936295263
            ],
            [
              105.83950173012911,
              21.02444040935376
            ],
            [
              105.83946775688514,
              21.02446489090407
            ],
            [
              105.83937173362682,
              21.024468832319876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11694",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02434834974698,
          "to_latitude": 21.024468832319876,
          "to_longitude": 105.83937173362682,
          "from_longitude": 105.83949687606496
        }
      },
      {
        "id": 11652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83922763653608,
            21.02428968034778,
            105.83956578783766,
            21.02462477321673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956578783766,
              21.02428968034778
            ],
            [
              105.8395515103894,
              21.024302469109006
            ],
            [
              105.83954425951042,
              21.024313401382717
            ],
            [
              105.83951042157312,
              21.024339706783064
            ],
            [
              105.83922763653608,
              21.02436000418315
            ],
            [
              105.8392554397222,
              21.02462477321673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11695",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02428968034778,
          "to_latitude": 21.02462477321673,
          "to_longitude": 105.8392554397222,
          "from_longitude": 105.83956578783766
        }
      },
      {
        "id": 11653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916274399314,
            21.02386321488184,
            105.83931752499508,
            21.023869902697438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931752499508,
              21.023869902697438
            ],
            [
              105.83929238696113,
              21.02386881643305
            ],
            [
              105.83916274399314,
              21.02386321488184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.11696",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023869902697438,
          "to_latitude": 21.02386321488184,
          "to_longitude": 105.83916274399314,
          "from_longitude": 105.83931752499508
        }
      },
      {
        "id": 11654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931752499508,
            21.023869902697438,
            105.83949965394388,
            21.023872970148194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83949965394388,
              21.02387084275554
            ],
            [
              105.83938851384129,
              21.023872970148194
            ],
            [
              105.83931752499508,
              21.023869902697438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.11697",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02387084275554,
          "to_latitude": 21.023869902697438,
          "to_longitude": 105.83931752499508,
          "from_longitude": 105.83949965394388
        }
      },
      {
        "id": 11655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916726323403,
            21.023838432532962,
            105.83934420781881,
            21.023838654083157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83934420781881,
              21.023838654083157
            ],
            [
              105.83930144039908,
              21.023838600497093
            ],
            [
              105.83926452190934,
              21.023838554166684
            ],
            [
              105.83916726323403,
              21.023838432532962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.11698",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023838654083157,
          "to_latitude": 21.023838432532962,
          "to_longitude": 105.83916726323403,
          "from_longitude": 105.83934420781881
        }
      },
      {
        "id": 11656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83951495569163,
            21.023289861216796,
            105.83952927431417,
            21.023555204401127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83951495569163,
              21.023289861216796
            ],
            [
              105.83952413106057,
              21.023374553592397
            ],
            [
              105.83952922653299,
              21.02348465028237
            ],
            [
              105.83952927431417,
              21.023555204401127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.32_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11699",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023289861216796,
          "to_latitude": 21.023555204401127,
          "to_longitude": 105.83952927431417,
          "from_longitude": 105.83951495569163
        }
      },
      {
        "id": 11657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915033964108,
            21.02412266454961,
            105.83953633008731,
            21.024124602256027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915033964108,
              21.02412266454961
            ],
            [
              105.83926592512533,
              21.024123256652018
            ],
            [
              105.83943108242467,
              21.02412410258971
            ],
            [
              105.83952840485327,
              21.024124582331964
            ],
            [
              105.83953223657188,
              21.024124602256027
            ],
            [
              105.83953633008731,
              21.024124474565603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11700",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02412266454961,
          "to_latitude": 21.024124474565603,
          "to_longitude": 105.83953633008731,
          "from_longitude": 105.83915033964108
        }
      },
      {
        "id": 11658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952538235577,
            21.023893181618924,
            105.83953603084427,
            21.024308112987942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953603084427,
              21.024308112987942
            ],
            [
              105.83952953705433,
              21.024277986433166
            ],
            [
              105.83952709089932,
              21.02426633790137
            ],
            [
              105.83952840485327,
              21.024124582331964
            ],
            [
              105.83952972273663,
              21.023982325452213
            ],
            [
              105.83952928074393,
              21.02396094383266
            ],
            [
              105.83952538235577,
              21.023893181618924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11701",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024308112987942,
          "to_latitude": 21.023893181618924,
          "to_longitude": 105.83952538235577,
          "from_longitude": 105.83953603084427
        }
      },
      {
        "id": 11659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83882615043515,
            21.02340331423383,
            105.83897538548557,
            21.023474019117845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882615043515,
              21.023474019117845
            ],
            [
              105.83882617440328,
              21.023474004548074
            ],
            [
              105.83894163566593,
              21.02340331423383
            ],
            [
              105.83897538548557,
              21.023448097549487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.11702",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023474019117845,
          "to_latitude": 21.023448097549487,
          "to_longitude": 105.83897538548557,
          "from_longitude": 105.83882615043515
        }
      },
      {
        "id": 11660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878553371576,
            21.023161964384776,
            105.83894163566593,
            21.02340331423383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83894163566593,
              21.02340331423383
            ],
            [
              105.83878553371576,
              21.023161964384776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.11703",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02340331423383,
          "to_latitude": 21.023161964384776,
          "to_longitude": 105.83878553371576,
          "from_longitude": 105.83894163566593
        }
      },
      {
        "id": 11661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737508131483,
            21.02391402522318,
            105.83791948977382,
            21.024079204947565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737508131483,
              21.023915080816916
            ],
            [
              105.83738866062663,
              21.02391419654045
            ],
            [
              105.83739080201985,
              21.02391402522318
            ],
            [
              105.83739311728276,
              21.02391845948836
            ],
            [
              105.83741458890688,
              21.02392983102499
            ],
            [
              105.83741598381333,
              21.02393035163909
            ],
            [
              105.83743066950088,
              21.023939988978388
            ],
            [
              105.83744818342483,
              21.02400473610348
            ],
            [
              105.83745061162824,
              21.02404506209588
            ],
            [
              105.83762115740315,
              21.024056656907398
            ],
            [
              105.83763593245139,
              21.024057661684033
            ],
            [
              105.83774858874489,
              21.024050350688743
            ],
            [
              105.8378384052285,
              21.02407157476323
            ],
            [
              105.83791948977382,
              21.024079204947565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11704",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023915080816916,
          "to_latitude": 21.024079204947565,
          "to_longitude": 105.83791948977382,
          "from_longitude": 105.83737508131483
        }
      },
      {
        "id": 11662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837375647776,
            21.02391650474978,
            105.83788870308535,
            21.024227613709513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837375647776,
              21.023917465246928
            ],
            [
              105.83738948158945,
              21.02391650474978
            ],
            [
              105.83739437421193,
              21.023924762313953
            ],
            [
              105.83742083943928,
              21.023947313746866
            ],
            [
              105.83743683041709,
              21.02400463484483
            ],
            [
              105.83743989461902,
              21.02405548928855
            ],
            [
              105.8374891456386,
              21.024058246374043
            ],
            [
              105.8375933896667,
              21.02406408086758
            ],
            [
              105.83769034654794,
              21.02406215366407
            ],
            [
              105.83774918478058,
              21.024057510312478
            ],
            [
              105.83788870308535,
              21.024092783037123
            ],
            [
              105.83787439426042,
              21.02415489231525
            ],
            [
              105.8378793056704,
              21.024227613709513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.11705",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023917465246928,
          "to_latitude": 21.024227613709513,
          "to_longitude": 105.8378793056704,
          "from_longitude": 105.837375647776
        }
      },
      {
        "id": 11663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83748415649313,
            21.024058246374043,
            105.8374891456386,
            21.02411589206641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374891456386,
              21.024058246374043
            ],
            [
              105.8374890688008,
              21.02405912920148
            ],
            [
              105.83748415649313,
              21.02411589206641
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.11706",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024058246374043,
          "to_latitude": 21.02411589206641,
          "to_longitude": 105.83748415649313,
          "from_longitude": 105.8374891456386
        }
      },
      {
        "id": 11664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83734046098904,
            21.024258093708116,
            105.83760986449684,
            21.02430766091789
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734046098904,
              21.024290706714645
            ],
            [
              105.83735481432264,
              21.02429078146502
            ],
            [
              105.83735670672826,
              21.024291156017547
            ],
            [
              105.83743974376647,
              21.02430766091789
            ],
            [
              105.83745040859249,
              21.024258093708116
            ],
            [
              105.83760986449684,
              21.02426722855767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.20_Linh Quang",
          "maTaiSan": "04.O14.DODV.11707",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024290706714645,
          "to_latitude": 21.02426722855767,
          "to_longitude": 105.83760986449684,
          "from_longitude": 105.83734046098904
        }
      },
      {
        "id": 11665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743974376647,
            21.02430766091789,
            105.83745873879194,
            21.024467743176878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83743974376647,
              21.02430766091789
            ],
            [
              105.83744735407254,
              21.02441960415147
            ],
            [
              105.83745873879194,
              21.024467743176878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.20_Linh Quang",
          "maTaiSan": "04.O14.DODV.11708",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02430766091789,
          "to_latitude": 21.024467743176878,
          "to_longitude": 105.83745873879194,
          "from_longitude": 105.83743974376647
        }
      },
      {
        "id": 11666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83744735407254,
            21.02441855893562,
            105.83753176499937,
            21.02441960415147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83744735407254,
              21.02441960415147
            ],
            [
              105.83753176499937,
              21.02441855893562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.20_Linh Quang",
          "maTaiSan": "04.O14.DODV.11709",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02441960415147,
          "to_latitude": 21.02441855893562,
          "to_longitude": 105.83753176499937,
          "from_longitude": 105.83744735407254
        }
      },
      {
        "id": 11667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719017175308,
            21.024527864146023,
            105.83726085495357,
            21.02460628762415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719017175308,
              21.02460628762415
            ],
            [
              105.8372391700972,
              21.024552224249263
            ],
            [
              105.83726085495357,
              21.024527864146023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.11710",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02460628762415,
          "to_latitude": 21.024527864146023,
          "to_longitude": 105.83726085495357,
          "from_longitude": 105.83719017175308
        }
      },
      {
        "id": 11668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725047133838,
            21.02433972128427,
            105.83733472132317,
            21.024527864146023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725047133838,
              21.024519965093937
            ],
            [
              105.83725847790068,
              21.024526319594727
            ],
            [
              105.83726085495357,
              21.024527864146023
            ],
            [
              105.83727229886829,
              21.0245090957319
            ],
            [
              105.83730060918494,
              21.02443740662631
            ],
            [
              105.83733472132317,
              21.02433972128427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.11711",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024519965093937,
          "to_latitude": 21.02433972128427,
          "to_longitude": 105.83733472132317,
          "from_longitude": 105.83725047133838
        }
      },
      {
        "id": 11669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726085495357,
            21.024527864146023,
            105.83742100492186,
            21.02468233482855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83726085495357,
              21.024527864146023
            ],
            [
              105.8372971993594,
              21.024556856616986
            ],
            [
              105.83742100492186,
              21.02459111146056
            ],
            [
              105.83739759928594,
              21.02468233482855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.11712",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024527864146023,
          "to_latitude": 21.02468233482855,
          "to_longitude": 105.83739759928594,
          "from_longitude": 105.83726085495357
        }
      },
      {
        "id": 11670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712783434602,
            21.02486073361609,
            105.83730668910451,
            21.024912512585193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712783434602,
              21.024900686659983
            ],
            [
              105.8371311717312,
              21.024900898747763
            ],
            [
              105.83713977999331,
              21.02490319662602
            ],
            [
              105.83714139952475,
              21.02490362942697
            ],
            [
              105.83719159498634,
              21.024911462571982
            ],
            [
              105.8372070957148,
              21.024912512585193
            ],
            [
              105.83721661507185,
              21.02486073361609
            ],
            [
              105.83730668910451,
              21.024881489723533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.15_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11713",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024900686659983,
          "to_latitude": 21.024881489723533,
          "to_longitude": 105.83730668910451,
          "from_longitude": 105.83712783434602
        }
      },
      {
        "id": 11671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713893119764,
            21.02490319662602,
            105.83715751271374,
            21.025080171788552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713977999331,
              21.02490319662602
            ],
            [
              105.83713970551432,
              21.024907947946573
            ],
            [
              105.83713893119764,
              21.02495706451841
            ],
            [
              105.83715751271374,
              21.025080171788552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.15_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11714",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02490319662602,
          "to_latitude": 21.025080171788552,
          "to_longitude": 105.83715751271374,
          "from_longitude": 105.83713977999331
        }
      },
      {
        "id": 11672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372872764297,
            21.02517645423515,
            105.8373525823603,
            21.025403420666734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373525823603,
              21.025403420666734
            ],
            [
              105.83732498136406,
              21.025285900916526
            ],
            [
              105.8372872764297,
              21.02517645423515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.9_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11715",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025403420666734,
          "to_latitude": 21.02517645423515,
          "to_longitude": 105.8372872764297,
          "from_longitude": 105.8373525823603
        }
      },
      {
        "id": 11673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373525823603,
            21.025403420666734,
            105.83762517767083,
            21.025417618213496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373525823603,
              21.025403420666734
            ],
            [
              105.83743519057202,
              21.025404871835548
            ],
            [
              105.83750450776364,
              21.025407176028548
            ],
            [
              105.83753539173742,
              21.0254082026397
            ],
            [
              105.83762517767083,
              21.025417618213496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.9_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11716",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025403420666734,
          "to_latitude": 21.025417618213496,
          "to_longitude": 105.83762517767083,
          "from_longitude": 105.8373525823603
        }
      },
      {
        "id": 11674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83739609278943,
            21.02544134321558,
            105.8374256749066,
            21.025561667637394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83739609278943,
              21.02544134321558
            ],
            [
              105.83739647077851,
              21.025442879543263
            ],
            [
              105.8374256749066,
              21.025561667637394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.8_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11717",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02544134321558,
          "to_latitude": 21.025561667637394,
          "to_longitude": 105.8374256749066,
          "from_longitude": 105.83739609278943
        }
      },
      {
        "id": 11675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83822936947931,
            21.023864562456264,
            105.83867625264075,
            21.02431997323611
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822936947931,
              21.023864562456264
            ],
            [
              105.83823031232346,
              21.02386561187072
            ],
            [
              105.83830043398746,
              21.02394344653487
            ],
            [
              105.83836310095637,
              21.024080406767304
            ],
            [
              105.83848785975579,
              21.024033142972016
            ],
            [
              105.83858725048795,
              21.024100195948964
            ],
            [
              105.83864971918382,
              21.024117038318472
            ],
            [
              105.83867625264075,
              21.024128187828776
            ],
            [
              105.83866040788095,
              21.02431997323611
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.6_Linh Quang",
          "maTaiSan": "04.O14.DODV.11718",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023864562456264,
          "to_latitude": 21.02431997323611,
          "to_longitude": 105.83866040788095,
          "from_longitude": 105.83822936947931
        }
      },
      {
        "id": 11676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825791173334,
            21.024080406767304,
            105.83836310095637,
            21.02413177942593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836310095637,
              21.024080406767304
            ],
            [
              105.83835216985183,
              21.024091931583857
            ],
            [
              105.83825791173334,
              21.02413177942593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.6_Linh Quang",
          "maTaiSan": "04.O14.DODV.11719",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024080406767304,
          "to_latitude": 21.02413177942593,
          "to_longitude": 105.83825791173334,
          "from_longitude": 105.83836310095637
        }
      },
      {
        "id": 11677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895813915774,
            21.023838966736236,
            105.83912379685114,
            21.024123074449637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912379685114,
              21.023838966736236
            ],
            [
              105.83912360636855,
              21.023841715280255
            ],
            [
              105.83912147593242,
              21.02387254827461
            ],
            [
              105.83908720722742,
              21.02392174790234
            ],
            [
              105.83901549233983,
              21.024009152525682
            ],
            [
              105.8390105664511,
              21.02401714234946
            ],
            [
              105.83900064125292,
              21.024033238874555
            ],
            [
              105.83899708778891,
              21.02403876245146
            ],
            [
              105.83900319846735,
              21.024053567743408
            ],
            [
              105.83895813915774,
              21.024123074449637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.14_Linh Quang",
          "maTaiSan": "04.O14.DODV.11720",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023838966736236,
          "to_latitude": 21.024123074449637,
          "to_longitude": 105.83895813915774,
          "from_longitude": 105.83912379685114
        }
      },
      {
        "id": 11678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877867411299,
            21.023889911083128,
            105.8390105664511,
            21.02401714234946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390105664511,
              21.02401714234946
            ],
            [
              105.83889273697963,
              21.023925129684194
            ],
            [
              105.83885664596092,
              21.02396569350904
            ],
            [
              105.83877867411299,
              21.023889911083128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.14_Linh Quang",
          "maTaiSan": "04.O14.DODV.11721",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02401714234946,
          "to_latitude": 21.023889911083128,
          "to_longitude": 105.83877867411299,
          "from_longitude": 105.8390105664511
        }
      },
      {
        "id": 11679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943098018817,
            21.023140374110962,
            105.83951525959561,
            21.023283317672966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83951525959561,
              21.023283317672966
            ],
            [
              105.8394990414043,
              21.023250657700704
            ],
            [
              105.83948561972744,
              21.023228825308475
            ],
            [
              105.83943656704197,
              21.023149418508286
            ],
            [
              105.83943098018817,
              21.023140374110962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.30_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11722",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023283317672966,
          "to_latitude": 21.023140374110962,
          "to_longitude": 105.83943098018817,
          "from_longitude": 105.83951525959561
        }
      },
      {
        "id": 11680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392742186902,
            21.022813934428736,
            105.8393679361185,
            21.022976186671436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393679361185,
              21.022976186671436
            ],
            [
              105.83933221956185,
              21.022921529167892
            ],
            [
              105.83931512783897,
              21.022895375787762
            ],
            [
              105.8392742186902,
              21.022813934428736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.28_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11723",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022976186671436,
          "to_latitude": 21.022813934428736,
          "to_longitude": 105.8392742186902,
          "from_longitude": 105.8393679361185
        }
      },
      {
        "id": 11681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393679361185,
            21.022976186671436,
            105.8394089483597,
            21.023056496147134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83938763980021,
              21.023056496147134
            ],
            [
              105.83938947451342,
              21.023055330966915
            ],
            [
              105.8394089483597,
              21.023044923663612
            ],
            [
              105.83939399493448,
              21.02301702284143
            ],
            [
              105.83938482859104,
              21.023002658963343
            ],
            [
              105.8393679361185,
              21.022976186671436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.28_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11724",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023056496147134,
          "to_latitude": 21.022976186671436,
          "to_longitude": 105.8393679361185,
          "from_longitude": 105.83938763980021
        }
      },
      {
        "id": 11682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83938482859104,
            21.02299660685318,
            105.83946380910838,
            21.023002658963343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83938482859104,
              21.023002658963343
            ],
            [
              105.83946380910838,
              21.02299660685318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.28_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11725",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023002658963343,
          "to_latitude": 21.02299660685318,
          "to_longitude": 105.83946380910838,
          "from_longitude": 105.83938482859104
        }
      },
      {
        "id": 11683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83933221956185,
            21.022913283287433,
            105.83943569618539,
            21.022921529167892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933221956185,
              21.022921529167892
            ],
            [
              105.83943569618539,
              21.022913283287433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.28_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11726",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022921529167892,
          "to_latitude": 21.022913283287433,
          "to_longitude": 105.83943569618539,
          "from_longitude": 105.83933221956185
        }
      },
      {
        "id": 11684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83920999096675,
            21.022702070617775,
            105.83947919554066,
            21.02298741377706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920999096675,
              21.022721458731457
            ],
            [
              105.83921468726294,
              21.022721097753763
            ],
            [
              105.83946137606593,
              21.022702070617775
            ],
            [
              105.83947919554066,
              21.02298741377706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.26_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11727",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022721458731457,
          "to_latitude": 21.02298741377706,
          "to_longitude": 105.83947919554066,
          "from_longitude": 105.83920999096675
        }
      },
      {
        "id": 11685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931730928286,
            21.022575067125917,
            105.83947207121804,
            21.022579451929147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947207121804,
              21.022575067125917
            ],
            [
              105.83946741501195,
              21.022575199397288
            ],
            [
              105.83931730928286,
              21.022579451929147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.25.1_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11728",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022575067125917,
          "to_latitude": 21.022579451929147,
          "to_longitude": 105.83931730928286,
          "from_longitude": 105.83947207121804
        }
      },
      {
        "id": 11686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893540479586,
            21.02257116400555,
            105.83895562542521,
            21.02270420025466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895562542521,
              21.02270420025466
            ],
            [
              105.8389554516421,
              21.022703056728208
            ],
            [
              105.83893540479586,
              21.02257116400555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.24_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11729",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02270420025466,
          "to_latitude": 21.02257116400555,
          "to_longitude": 105.83893540479586,
          "from_longitude": 105.83895562542521
        }
      },
      {
        "id": 11687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886008221441,
            21.022707775886772,
            105.83886275422505,
            21.02281655051198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886008221441,
              21.022707775886772
            ],
            [
              105.83886275422505,
              21.02281655051198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.24_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11730",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022707775886772,
          "to_latitude": 21.02281655051198,
          "to_longitude": 105.83886275422505,
          "from_longitude": 105.83886008221441
        }
      },
      {
        "id": 11688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83894200648706,
            21.021973629462256,
            105.83915131950981,
            21.02202561249691
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915131950981,
              21.02202561249691
            ],
            [
              105.8391472032633,
              21.022024590485557
            ],
            [
              105.83894200648706,
              21.021973629462256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.20_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11731",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02202561249691,
          "to_latitude": 21.021973629462256,
          "to_longitude": 105.83894200648706,
          "from_longitude": 105.83915131950981
        }
      },
      {
        "id": 11689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916448832154,
            21.022021125726514,
            105.8394715470488,
            21.02203629014963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83916448832154,
              21.02202205837486
            ],
            [
              105.83916477342783,
              21.022022122903504
            ],
            [
              105.83918704404464,
              21.022027088235173
            ],
            [
              105.83918801492894,
              21.022027304734607
            ],
            [
              105.83922727028822,
              21.022028674527828
            ],
            [
              105.83929452142674,
              21.02203629014963
            ],
            [
              105.8394715470488,
              21.022021125726514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.19_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11732",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02202205837486,
          "to_latitude": 21.022021125726514,
          "to_longitude": 105.8394715470488,
          "from_longitude": 105.83916448832154
        }
      },
      {
        "id": 11690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83918704404464,
            21.021838660728672,
            105.83923734648084,
            21.022027088235173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918704404464,
              21.022027088235173
            ],
            [
              105.83918932705103,
              21.022018536093192
            ],
            [
              105.83923734648084,
              21.021838660728672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.19_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11733",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022027088235173,
          "to_latitude": 21.021838660728672,
          "to_longitude": 105.83923734648084,
          "from_longitude": 105.83918704404464
        }
      },
      {
        "id": 11691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927252006241,
            21.021676772998983,
            105.83943454367981,
            21.02174033481863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927252006241,
              21.02174033481863
            ],
            [
              105.8392759211449,
              21.021683707622774
            ],
            [
              105.83943454367981,
              21.021676772998983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.17_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11734",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02174033481863,
          "to_latitude": 21.021676772998983,
          "to_longitude": 105.83943454367981,
          "from_longitude": 105.83927252006241
        }
      },
      {
        "id": 11692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839209940411,
            21.021825110014895,
            105.83941257698592,
            21.02189024513835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839209940411,
              21.021826051473322
            ],
            [
              105.83921289021814,
              21.021825949306358
            ],
            [
              105.8392369108597,
              21.021825110014895
            ],
            [
              105.83929026472728,
              21.02185291982938
            ],
            [
              105.83935550418258,
              21.02189024513835
            ],
            [
              105.83939523928994,
              21.021852047741337
            ],
            [
              105.83941257698592,
              21.021835380807893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.18_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11735",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021826051473322,
          "to_latitude": 21.021835380807893,
          "to_longitude": 105.83941257698592,
          "from_longitude": 105.839209940411
        }
      },
      {
        "id": 11693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83939523928994,
            21.021852047741337,
            105.8394717366114,
            21.02192439682452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83939523928994,
              21.021852047741337
            ],
            [
              105.8394717366114,
              21.02192439682452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.18_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11736",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021852047741337,
          "to_latitude": 21.02192439682452,
          "to_longitude": 105.8394717366114,
          "from_longitude": 105.83939523928994
        }
      },
      {
        "id": 11694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392369108597,
            21.02175109016931,
            105.83926487868938,
            21.021825110014895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392369108597,
              21.021825110014895
            ],
            [
              105.83926487868938,
              21.02175109016931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.18_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11737",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021825110014895,
          "to_latitude": 21.02175109016931,
          "to_longitude": 105.83926487868938,
          "from_longitude": 105.8392369108597
        }
      },
      {
        "id": 11695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921662825111,
            21.022761436053322,
            105.8393109247106,
            21.022952905641695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393109247106,
              21.022952905641695
            ],
            [
              105.83930432429351,
              21.02294203360181
            ],
            [
              105.83926867013483,
              21.022870332860638
            ],
            [
              105.83924952147798,
              21.022832107208732
            ],
            [
              105.83923636293342,
              21.02280571221891
            ],
            [
              105.83921662825111,
              21.022761436053322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.27_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11738",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022952905641695,
          "to_latitude": 21.022761436053322,
          "to_longitude": 105.83921662825111,
          "from_longitude": 105.8393109247106
        }
      },
      {
        "id": 11696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83911798840876,
            21.022832107208732,
            105.83924952147798,
            21.022864539710834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924952147798,
              21.022832107208732
            ],
            [
              105.83924845198801,
              21.022832492742904
            ],
            [
              105.83916929517261,
              21.022861002455322
            ],
            [
              105.83911798840876,
              21.022864539710834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.27_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11739",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022832107208732,
          "to_latitude": 21.022864539710834,
          "to_longitude": 105.83911798840876,
          "from_longitude": 105.83924952147798
        }
      },
      {
        "id": 11697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912404868028,
            21.022176946526532,
            105.83916085983377,
            21.022394718200008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912404868028,
              21.022394718200008
            ],
            [
              105.83912445434628,
              21.022394666521354
            ],
            [
              105.83914323121195,
              21.022392258922302
            ],
            [
              105.83913130095175,
              21.0223058332668
            ],
            [
              105.83916085983377,
              21.022176946526532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.22_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11740",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022394718200008,
          "to_latitude": 21.022176946526532,
          "to_longitude": 105.83916085983377,
          "from_longitude": 105.83912404868028
        }
      },
      {
        "id": 11698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914323121195,
            21.022392258922302,
            105.83920759319471,
            21.02263921042587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920759319471,
              21.02263921042587
            ],
            [
              105.8391676575085,
              21.022503590718227
            ],
            [
              105.83914892115371,
              21.022433482854886
            ],
            [
              105.83914323121195,
              21.022392258922302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.22_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11741",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02263921042587,
          "to_latitude": 21.022392258922302,
          "to_longitude": 105.83914323121195,
          "from_longitude": 105.83920759319471
        }
      },
      {
        "id": 11699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83912422710696,
            21.02216600767962,
            105.83950827682456,
            21.022183677534787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83912422710696,
              21.02216600767962
            ],
            [
              105.83912865896606,
              21.02216618452391
            ],
            [
              105.83915781446967,
              21.022168398093207
            ],
            [
              105.83917989354717,
              21.02217007483154
            ],
            [
              105.83925603280952,
              21.02218101834548
            ],
            [
              105.83926937493415,
              21.022183677534787
            ],
            [
              105.83927091474511,
              21.022183621162693
            ],
            [
              105.83938454650718,
              21.022179507140194
            ],
            [
              105.83950827682456,
              21.022169845279056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.21_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11742",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02216600767962,
          "to_latitude": 21.022169845279056,
          "to_longitude": 105.83950827682456,
          "from_longitude": 105.83912422710696
        }
      },
      {
        "id": 11700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915781446967,
            21.02203881186998,
            105.83918452193979,
            21.022168398093207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915781446967,
              21.022168398093207
            ],
            [
              105.8391591911884,
              21.02216171738522
            ],
            [
              105.83918452193979,
              21.02203881186998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.21_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11743",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022168398093207,
          "to_latitude": 21.02203881186998,
          "to_longitude": 105.83918452193979,
          "from_longitude": 105.83915781446967
        }
      },
      {
        "id": 11701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83908570261403,
            21.021419556434232,
            105.83911452172498,
            21.021537540501484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908570261403,
              21.021419556434232
            ],
            [
              105.83911452172498,
              21.021537540501484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.13_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11744",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021419556434232,
          "to_latitude": 21.021537540501484,
          "to_longitude": 105.83911452172498,
          "from_longitude": 105.83908570261403
        }
      },
      {
        "id": 11702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903599700416,
            21.02158255931922,
            105.83924534237983,
            21.021596378157085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924534237983,
              21.02158255931922
            ],
            [
              105.83923349081219,
              21.02158272256997
            ],
            [
              105.83912023095621,
              21.021584283038706
            ],
            [
              105.83903599700416,
              21.021596378157085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.16_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11745",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02158255931922,
          "to_latitude": 21.021596378157085,
          "to_longitude": 105.83903599700416,
          "from_longitude": 105.83924534237983
        }
      },
      {
        "id": 11703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927010271003,
            21.02155644960976,
            105.83927193642005,
            21.021668886425157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927193642005,
              21.021668886425157
            ],
            [
              105.83927010271003,
              21.02155644960976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.15_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11746",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021668886425157,
          "to_latitude": 21.02155644960976,
          "to_longitude": 105.83927010271003,
          "from_longitude": 105.83927193642005
        }
      },
      {
        "id": 11704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924466724442,
            21.021555613084335,
            105.8395223371696,
            21.02155813368507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924466724442,
              21.021556120792308
            ],
            [
              105.83927010271003,
              21.02155644960976
            ],
            [
              105.83940014694369,
              21.02155813368507
            ],
            [
              105.8395223371696,
              21.021555613084335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.15_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11747",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021556120792308,
          "to_latitude": 21.021555613084335,
          "to_longitude": 105.8395223371696,
          "from_longitude": 105.83924466724442
        }
      },
      {
        "id": 11705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921688415313,
            21.02136780257099,
            105.83927304059198,
            21.021507297063682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921688415313,
              21.021375929367625
            ],
            [
              105.83921954538732,
              21.02137531649705
            ],
            [
              105.83925213248273,
              21.02136780257099
            ],
            [
              105.83926523967341,
              21.02139415536937
            ],
            [
              105.83927304059198,
              21.021507297063682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.14_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11749",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021375929367625,
          "to_latitude": 21.021507297063682,
          "to_longitude": 105.83927304059198,
          "from_longitude": 105.83921688415313
        }
      },
      {
        "id": 11706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910812571898,
            21.02110760815165,
            105.83918334113615,
            21.021209916094616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83918334113615,
              21.02110760815165
            ],
            [
              105.83915496081133,
              21.021186463169503
            ],
            [
              105.83911114938485,
              21.021208402730732
            ],
            [
              105.83910812571898,
              21.021209916094616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.12_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11750",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02110760815165,
          "to_latitude": 21.021209916094616,
          "to_longitude": 105.83910812571898,
          "from_longitude": 105.83918334113615
        }
      },
      {
        "id": 11707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83864479924944,
            21.020829866740712,
            105.83870615482707,
            21.02094737615289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83864479924944,
              21.020829866740712
            ],
            [
              105.83870615482707,
              21.02094737615289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.6_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11751",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020829866740712,
          "to_latitude": 21.02094737615289,
          "to_longitude": 105.83870615482707,
          "from_longitude": 105.83864479924944
        }
      },
      {
        "id": 11708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855067970704,
            21.020881045855994,
            105.83858414013311,
            21.021043471935517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855067970704,
              21.020881045855994
            ],
            [
              105.83855480824135,
              21.02088736793699
            ],
            [
              105.8385697359675,
              21.020910225377868
            ],
            [
              105.83857089033745,
              21.021000536601814
            ],
            [
              105.83858414013311,
              21.021043471935517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.6_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11752",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020881045855994,
          "to_latitude": 21.021043471935517,
          "to_longitude": 105.83858414013311,
          "from_longitude": 105.83855067970704
        }
      },
      {
        "id": 11709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842350645585,
            21.020918786868556,
            105.83849813544411,
            21.02116463611617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848127492398,
              21.020918786868556
            ],
            [
              105.83848170247354,
              21.020921617269405
            ],
            [
              105.83848983606435,
              21.020975488770304
            ],
            [
              105.83842350645585,
              21.02100252946881
            ],
            [
              105.83849813544411,
              21.02116463611617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.6_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11753",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020918786868556,
          "to_latitude": 21.02116463611617,
          "to_longitude": 105.83849813544411,
          "from_longitude": 105.83848127492398
        }
      },
      {
        "id": 11710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823544171722,
            21.02095477443798,
            105.83831100875221,
            21.02123952873964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823544171722,
              21.02095477443798
            ],
            [
              105.83823946573645,
              21.02096219174873
            ],
            [
              105.83831100875221,
              21.021094058419997
            ],
            [
              105.8382576453647,
              21.021120908699316
            ],
            [
              105.83828249592028,
              21.02118164361237
            ],
            [
              105.83830626950989,
              21.02123952873964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.6_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11754",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02095477443798,
          "to_latitude": 21.02123952873964,
          "to_longitude": 105.83830626950989,
          "from_longitude": 105.83823544171722
        }
      },
      {
        "id": 11711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83807190603976,
            21.020868750698003,
            105.83815430803377,
            21.020988994549494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815430803377,
              21.020988994549494
            ],
            [
              105.83807190603976,
              21.020868750698003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.5_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11755",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020988994549494,
          "to_latitude": 21.020868750698003,
          "to_longitude": 105.83807190603976,
          "from_longitude": 105.83815430803377
        }
      },
      {
        "id": 11712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816783337015,
            21.02078557527728,
            105.83824989469252,
            21.02093332391404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824989469252,
              21.02093332391404
            ],
            [
              105.83821190668802,
              21.020875362500874
            ],
            [
              105.83820884575815,
              21.02085386099667
            ],
            [
              105.83816783337015,
              21.02078557527728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.5_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11756",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02093332391404,
          "to_latitude": 21.02078557527728,
          "to_longitude": 105.83816783337015,
          "from_longitude": 105.83824989469252
        }
      },
      {
        "id": 11713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83770086874179,
            21.020793528951046,
            105.8377412970273,
            21.02086784487622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83770086874179,
              21.020793528951046
            ],
            [
              105.83770205160619,
              21.020795703507112
            ],
            [
              105.83770398287118,
              21.02079925359963
            ],
            [
              105.8377412970273,
              21.02086784487622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.22_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11757",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020793528951046,
          "to_latitude": 21.02086784487622,
          "to_longitude": 105.8377412970273,
          "from_longitude": 105.83770086874179
        }
      },
      {
        "id": 11714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760254462547,
            21.02079925359963,
            105.83770398287118,
            21.020830161068485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83770398287118,
              21.02079925359963
            ],
            [
              105.83764937831398,
              21.020817626726487
            ],
            [
              105.83760254462547,
              21.020830161068485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.22_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11758",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02079925359963,
          "to_latitude": 21.020830161068485,
          "to_longitude": 105.83760254462547,
          "from_longitude": 105.83770398287118
        }
      },
      {
        "id": 11715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763040797157,
            21.020975830512732,
            105.83783944737449,
            21.021065572915624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763040797157,
              21.021000289618424
            ],
            [
              105.83771767019945,
              21.020975830512732
            ],
            [
              105.83777123482837,
              21.021065572915624
            ],
            [
              105.83783944737449,
              21.02103099392191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.21_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11759",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021000289618424,
          "to_latitude": 21.02103099392191,
          "to_longitude": 105.83783944737449,
          "from_longitude": 105.83763040797157
        }
      },
      {
        "id": 11716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370151209152,
            21.020958050745573,
            105.83748592959427,
            21.02095825870165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748592959427,
              21.02095825870165
            ],
            [
              105.83728652805186,
              21.020958170956373
            ],
            [
              105.83728070330513,
              21.020958168015778
            ],
            [
              105.8370151209152,
              21.020958050745573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.20_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11760",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02095825870165,
          "to_latitude": 21.020958050745573,
          "to_longitude": 105.8370151209152,
          "from_longitude": 105.83748592959427
        }
      },
      {
        "id": 11717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706528722905,
            21.02076268879844,
            105.8375160811371,
            21.020765652082996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375160811371,
              21.02076268879844
            ],
            [
              105.8375143554323,
              21.020762700908374
            ],
            [
              105.83706528722905,
              21.020765652082996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.23_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11761",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02076268879844,
          "to_latitude": 21.020765652082996,
          "to_longitude": 105.83706528722905,
          "from_longitude": 105.8375160811371
        }
      },
      {
        "id": 11718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752082395583,
            21.02032181466734,
            105.83752673056767,
            21.02051836316431
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752082395583,
              21.02032181466734
            ],
            [
              105.83752510003764,
              21.02046410913344
            ],
            [
              105.83752522772195,
              21.020468369010338
            ],
            [
              105.83752628525706,
              21.020503565135524
            ],
            [
              105.83752673056767,
              21.02051836316431
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.24_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11762",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02032181466734,
          "to_latitude": 21.02051836316431,
          "to_longitude": 105.83752673056767,
          "from_longitude": 105.83752082395583
        }
      },
      {
        "id": 11719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751971854589,
            21.02051836316431,
            105.83752673056767,
            21.020753941232908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752673056767,
              21.02051836316431
            ],
            [
              105.83752668906254,
              21.020617029704372
            ],
            [
              105.83751971854589,
              21.020753941232908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.24_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11763",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02051836316431,
          "to_latitude": 21.020753941232908,
          "to_longitude": 105.83751971854589,
          "from_longitude": 105.83752673056767
        }
      },
      {
        "id": 11720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8385249903415,
            21.020557634274322,
            105.83866182545647,
            21.020726464198535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8385249903415,
              21.020557634274322
            ],
            [
              105.83866182545647,
              21.02070195320523
            ],
            [
              105.8386345323691,
              21.020725325000026
            ],
            [
              105.83863320256577,
              21.020726464198535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.4_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11764",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020557634274322,
          "to_latitude": 21.020726464198535,
          "to_longitude": 105.83863320256577,
          "from_longitude": 105.8385249903415
        }
      },
      {
        "id": 11721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83868324110644,
            21.020658254358455,
            105.83876568717534,
            21.0207245401966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868324110644,
              21.0207245401966
            ],
            [
              105.83876568717534,
              21.020658254358455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.4_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11765",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0207245401966,
          "to_latitude": 21.020658254358455,
          "to_longitude": 105.83876568717534,
          "from_longitude": 105.83868324110644
        }
      },
      {
        "id": 11722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83866182545647,
            21.02070195320523,
            105.83889800683194,
            21.020944037413276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83889800683194,
              21.020944037413276
            ],
            [
              105.83870500125518,
              21.0207474903886
            ],
            [
              105.83868324110644,
              21.0207245401966
            ],
            [
              105.83867130287719,
              21.0207119485134
            ],
            [
              105.83866182545647,
              21.02070195320523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.4_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11766",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020944037413276,
          "to_latitude": 21.02070195320523,
          "to_longitude": 105.83866182545647,
          "from_longitude": 105.83889800683194
        }
      },
      {
        "id": 11723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83816106933645,
            21.020135793517703,
            105.83829183190703,
            21.020290975959007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83816106933645,
              21.020135793517703
            ],
            [
              105.83829183190703,
              21.020290975959007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.2_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11767",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020135793517703,
          "to_latitude": 21.020290975959007,
          "to_longitude": 105.83829183190703,
          "from_longitude": 105.83816106933645
        }
      },
      {
        "id": 11724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823573603536,
            21.020290975959007,
            105.83841878316284,
            21.020441635668128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823573603536,
              21.02030891042509
            ],
            [
              105.83824194213486,
              21.02030692707244
            ],
            [
              105.83829183190703,
              21.020290975959007
            ],
            [
              105.83830212202108,
              21.020303187407748
            ],
            [
              105.83841878316284,
              21.020441635668128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.2_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11768",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02030891042509,
          "to_latitude": 21.020441635668128,
          "to_longitude": 105.83841878316284,
          "from_longitude": 105.83823573603536
        }
      },
      {
        "id": 11725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83830212202108,
            21.020230510914306,
            105.83839109002966,
            21.020303187407748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83830212202108,
              21.020303187407748
            ],
            [
              105.83830228193791,
              21.020303056555445
            ],
            [
              105.83839109002966,
              21.020230510914306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.2_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11769",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020303187407748,
          "to_latitude": 21.020230510914306,
          "to_longitude": 105.83839109002966,
          "from_longitude": 105.83830212202108
        }
      },
      {
        "id": 11726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810014657658,
            21.01990196792676,
            105.83846346581069,
            21.02015324953029
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810014657658,
              21.02015324953029
            ],
            [
              105.83810733272833,
              21.020148306909682
            ],
            [
              105.83840216270578,
              21.019945487529345
            ],
            [
              105.83846346581069,
              21.01990196792676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.1_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11770",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02015324953029,
          "to_latitude": 21.01990196792676,
          "to_longitude": 105.83846346581069,
          "from_longitude": 105.83810014657658
        }
      },
      {
        "id": 11727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83840216270578,
            21.019945487529345,
            105.838529827334,
            21.020082175300086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83840216270578,
              21.019945487529345
            ],
            [
              105.83840225851321,
              21.019945590024587
            ],
            [
              105.838529827334,
              21.020082175300086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.1_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11771",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019945487529345,
          "to_latitude": 21.020082175300086,
          "to_longitude": 105.838529827334,
          "from_longitude": 105.83840216270578
        }
      },
      {
        "id": 11728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83616295738939,
            21.020671646200718,
            105.83635676971025,
            21.020750327667084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83616295738939,
              21.020750327667084
            ],
            [
              105.83635676971025,
              21.020685746302888
            ],
            [
              105.83635141560492,
              21.020673710978844
            ],
            [
              105.83635049887646,
              21.020671646200718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.11772",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020750327667084,
          "to_latitude": 21.020671646200718,
          "to_longitude": 105.83635049887646,
          "from_longitude": 105.83616295738939
        }
      },
      {
        "id": 11729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635676971025,
            21.020619672636666,
            105.83657389741602,
            21.020685746302888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635676971025,
              21.020685746302888
            ],
            [
              105.83642379684868,
              21.02066316128532
            ],
            [
              105.83657389741602,
              21.020619672636666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.11773",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020685746302888,
          "to_latitude": 21.020619672636666,
          "to_longitude": 105.83657389741602,
          "from_longitude": 105.83635676971025
        }
      },
      {
        "id": 11730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701824297633,
            21.020368039763905,
            105.83704118367932,
            21.020660274178542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701824297633,
              21.020368039763905
            ],
            [
              105.83701895507205,
              21.02037221908865
            ],
            [
              105.83703976258965,
              21.02049409434324
            ],
            [
              105.83704118367932,
              21.020660274178542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11774",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020368039763905,
          "to_latitude": 21.020660274178542,
          "to_longitude": 105.83704118367932,
          "from_longitude": 105.83701824297633
        }
      },
      {
        "id": 11731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83703976258965,
            21.02049409434324,
            105.83721522750216,
            21.020496876206973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83703976258965,
              21.02049409434324
            ],
            [
              105.83721522750216,
              21.020496876206973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11775",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02049409434324,
          "to_latitude": 21.020496876206973,
          "to_longitude": 105.83721522750216,
          "from_longitude": 105.83703976258965
        }
      },
      {
        "id": 11732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83664144513406,
            21.020927996330535,
            105.83694920430908,
            21.020957276782312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83694920430908,
              21.020957276782312
            ],
            [
              105.83688645176922,
              21.020954632907703
            ],
            [
              105.83673355331425,
              21.02094808255886
            ],
            [
              105.83664979783794,
              21.02094315379067
            ],
            [
              105.83664144513406,
              21.020927996330535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.11_Văn Chương",
          "maTaiSan": "04.O14.DODV.11776",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020957276782312,
          "to_latitude": 21.020927996330535,
          "to_longitude": 105.83664144513406,
          "from_longitude": 105.83694920430908
        }
      },
      {
        "id": 11733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83613952631526,
            21.020898410526627,
            105.83633064549481,
            21.021037848652586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83613952631526,
              21.021037848652586
            ],
            [
              105.83613952709084,
              21.02103781522949
            ],
            [
              105.83614230939536,
              21.020955229739553
            ],
            [
              105.83614410041483,
              21.020902063507084
            ],
            [
              105.83633064549481,
              21.020898410526627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.11777",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021037848652586,
          "to_latitude": 21.020898410526627,
          "to_longitude": 105.83633064549481,
          "from_longitude": 105.83613952631526
        }
      },
      {
        "id": 11734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634142317125,
            21.020958700147915,
            105.83647899148762,
            21.021042241132612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634288070535,
              21.021042241132612
            ],
            [
              105.83634288055926,
              21.021042214939847
            ],
            [
              105.83634142317125,
              21.02095925287778
            ],
            [
              105.83647899148762,
              21.020958700147915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.11778",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021042241132612,
          "to_latitude": 21.020958700147915,
          "to_longitude": 105.83647899148762,
          "from_longitude": 105.83634288070535
        }
      },
      {
        "id": 11735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629879504862,
            21.021705725835613,
            105.83653076107544,
            21.021905264123056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629879504862,
              21.021905264123056
            ],
            [
              105.83637381932691,
              21.021841575632408
            ],
            [
              105.83641588041692,
              21.021803744116724
            ],
            [
              105.83645161276569,
              21.021770417380953
            ],
            [
              105.83653076107544,
              21.021705725835613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11779",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021905264123056,
          "to_latitude": 21.021705725835613,
          "to_longitude": 105.83653076107544,
          "from_longitude": 105.83629879504862
        }
      },
      {
        "id": 11736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83655114956788,
            21.021632522920374,
            105.83662006164741,
            21.02168820779552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83655114956788,
              21.02168820779552
            ],
            [
              105.83662006164741,
              21.021632522920374
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.11780",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02168820779552,
          "to_latitude": 21.021632522920374,
          "to_longitude": 105.83662006164741,
          "from_longitude": 105.83655114956788
        }
      },
      {
        "id": 11737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662006164741,
            21.021408576606863,
            105.83685437249665,
            21.021632522920374
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662006164741,
              21.021632522920374
            ],
            [
              105.83666957800638,
              21.021589017655533
            ],
            [
              105.83670464890109,
              21.02156112339962
            ],
            [
              105.83670818572666,
              21.02155847400767
            ],
            [
              105.83680106571208,
              21.02148890602943
            ],
            [
              105.83683154244358,
              21.02146623149896
            ],
            [
              105.83683418459641,
              21.021464265730348
            ],
            [
              105.83683814974435,
              21.021461316164686
            ],
            [
              105.83683832793062,
              21.021461183417383
            ],
            [
              105.83684738099076,
              21.02145444779608
            ],
            [
              105.83685437249665,
              21.021408576606863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.11781",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021632522920374,
          "to_latitude": 21.021408576606863,
          "to_longitude": 105.83685437249665,
          "from_longitude": 105.83662006164741
        }
      },
      {
        "id": 11738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617429381148,
            21.021782684046233,
            105.83640918783746,
            21.02180386501252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640918783746,
              21.021782684046233
            ],
            [
              105.83639000971115,
              21.02180386501252
            ],
            [
              105.83617429381148,
              21.021802914156105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.11782",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021782684046233,
          "to_latitude": 21.021802914156105,
          "to_longitude": 105.83617429381148,
          "from_longitude": 105.83640918783746
        }
      },
      {
        "id": 11739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640918783746,
            21.021737129804794,
            105.83647221825278,
            21.021788486089008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641640519885,
              21.021788486089008
            ],
            [
              105.83641324054658,
              21.02178594372198
            ],
            [
              105.83640918783746,
              21.021782684046233
            ],
            [
              105.83643704034193,
              21.021765686603825
            ],
            [
              105.83647221825278,
              21.021737129804794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.11783",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021788486089008,
          "to_latitude": 21.021737129804794,
          "to_longitude": 105.83647221825278,
          "from_longitude": 105.83641640519885
        }
      },
      {
        "id": 11740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598917699457,
            21.02160030948816,
            105.83625315571977,
            21.021929363430345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625315571977,
              21.021929363430345
            ],
            [
              105.83624836212572,
              21.021924694751682
            ],
            [
              105.83615014463084,
              21.021830045585084
            ],
            [
              105.83610364568129,
              21.021798490628832
            ],
            [
              105.83598917699457,
              21.021734253306526
            ],
            [
              105.83603708701582,
              21.021642427324494
            ],
            [
              105.83603755923848,
              21.02160030948816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.11784",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021929363430345,
          "to_latitude": 21.02160030948816,
          "to_longitude": 105.83603755923848,
          "from_longitude": 105.83625315571977
        }
      },
      {
        "id": 11741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673568411677,
            21.021859187155332,
            105.83673656849976,
            21.022009741139655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673656849976,
              21.022009741139655
            ],
            [
              105.83673568411677,
              21.021859187155332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.11_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11785",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022009741139655,
          "to_latitude": 21.021859187155332,
          "to_longitude": 105.83673568411677,
          "from_longitude": 105.83673656849976
        }
      },
      {
        "id": 11742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365983688166,
            21.02198744883016,
            105.83665640864388,
            21.022046768405882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665640864388,
              21.022046768405882
            ],
            [
              105.83665579578084,
              21.0220258925047
            ],
            [
              105.8365983688166,
              21.022022478987175
            ],
            [
              105.8365993758416,
              21.02198744883016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.11_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11786",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022046768405882,
          "to_latitude": 21.02198744883016,
          "to_longitude": 105.8365993758416,
          "from_longitude": 105.83665640864388
        }
      },
      {
        "id": 11743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83688579302492,
            21.02176349012842,
            105.837245110716,
            21.02177814501988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837245110716,
              21.02177814501988
            ],
            [
              105.83721308449276,
              21.02177209119526
            ],
            [
              105.83720094392226,
              21.0217704619255
            ],
            [
              105.83714897371053,
              21.02176349012842
            ],
            [
              105.8370439377818,
              21.02176370809971
            ],
            [
              105.83688579302492,
              21.0217709786636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.12_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11787",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02177814501988,
          "to_latitude": 21.0217709786636,
          "to_longitude": 105.83688579302492,
          "from_longitude": 105.837245110716
        }
      },
      {
        "id": 11744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687110251593,
            21.02176370809971,
            105.8370439377818,
            21.02182506822906
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8370439377818,
              21.02176370809971
            ],
            [
              105.83703999359189,
              21.02178902409112
            ],
            [
              105.83687110251593,
              21.02182506822906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.12_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11788",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02176370809971,
          "to_latitude": 21.02182506822906,
          "to_longitude": 105.83687110251593,
          "from_longitude": 105.8370439377818
        }
      },
      {
        "id": 11745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750537614722,
            21.02154822441088,
            105.83767133766196,
            21.02162757726379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750537614722,
              21.02154822441088
            ],
            [
              105.83767133766196,
              21.02162757726379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.16_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11789",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02154822441088,
          "to_latitude": 21.02162757726379,
          "to_longitude": 105.83767133766196,
          "from_longitude": 105.83750537614722
        }
      },
      {
        "id": 11746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83683734328378,
            21.022085485003288,
            105.83690684371801,
            21.022215543410514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83683734328378,
              21.022215543410514
            ],
            [
              105.83689896992152,
              21.022148220102842
            ],
            [
              105.83690684371801,
              21.022085485003288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.9_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11790",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022215543410514,
          "to_latitude": 21.022085485003288,
          "to_longitude": 105.83690684371801,
          "from_longitude": 105.83683734328378
        }
      },
      {
        "id": 11747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666943234715,
            21.022215543410514,
            105.83685217354402,
            21.02231200187099
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666943234715,
              21.02231200187099
            ],
            [
              105.83676077397055,
              21.022290415288328
            ],
            [
              105.83683734328378,
              21.022215543410514
            ],
            [
              105.83684473738177,
              21.022220773726943
            ],
            [
              105.83685217354402,
              21.022225956868443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.9_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11791",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02231200187099,
          "to_latitude": 21.022225956868443,
          "to_longitude": 105.83685217354402,
          "from_longitude": 105.83666943234715
        }
      },
      {
        "id": 11748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670045782519,
            21.022324537733844,
            105.83675300408582,
            21.022484211248333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670045782519,
              21.022324537733844
            ],
            [
              105.8367019148574,
              21.022328609523665
            ],
            [
              105.83675300408582,
              21.02247141976284
            ],
            [
              105.83674748925016,
              21.022484211248333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.8_Linh Quang",
          "maTaiSan": "04.O14.DODV.11792",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022324537733844,
          "to_latitude": 21.022484211248333,
          "to_longitude": 105.83674748925016,
          "from_longitude": 105.83670045782519
        }
      },
      {
        "id": 11749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673802388863,
            21.02253148095048,
            105.83690987368944,
            21.022597092777563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673802388863,
              21.022581529565297
            ],
            [
              105.83674369587541,
              21.02258414178364
            ],
            [
              105.83677185222008,
              21.022597092777563
            ],
            [
              105.83684773713763,
              21.022561071902178
            ],
            [
              105.83685064136141,
              21.022559693745258
            ],
            [
              105.83690987368944,
              21.02253148095048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.11793",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022581529565297,
          "to_latitude": 21.02253148095048,
          "to_longitude": 105.83690987368944,
          "from_longitude": 105.83673802388863
        }
      },
      {
        "id": 11750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83690845393755,
            21.022658238317558,
            105.83700203772487,
            21.02270540361336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83690845393755,
              21.02270540361336
            ],
            [
              105.83690924630442,
              21.022705004102562
            ],
            [
              105.83700203772487,
              21.022658238317558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.11794",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02270540361336,
          "to_latitude": 21.022658238317558,
          "to_longitude": 105.83700203772487,
          "from_longitude": 105.83690845393755
        }
      },
      {
        "id": 11751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666922788666,
            21.022334039517933,
            105.83673469835597,
            21.022787578723356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83667532911544,
              21.022334039517933
            ],
            [
              105.83673469835597,
              21.022465003900788
            ],
            [
              105.83673054729243,
              21.022487392652142
            ],
            [
              105.83672829414486,
              21.022534247616946
            ],
            [
              105.83670175230145,
              21.022592372362443
            ],
            [
              105.83669867478541,
              21.022599415483878
            ],
            [
              105.83667211868959,
              21.022658265583548
            ],
            [
              105.83666922788666,
              21.02269825286291
            ],
            [
              105.83667647548408,
              21.022729289001568
            ],
            [
              105.83669240987011,
              21.022787578723356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.7_Linh Quang",
          "maTaiSan": "04.O14.DODV.11795",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022334039517933,
          "to_latitude": 21.022787578723356,
          "to_longitude": 105.83669240987011,
          "from_longitude": 105.83667532911544
        }
      },
      {
        "id": 11752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363139877306,
            21.022800381880398,
            105.83671319180385,
            21.023177198543582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670819994846,
              21.02280397403532
            ],
            [
              105.83671319180385,
              21.022802618126658
            ],
            [
              105.8367124299406,
              21.022800381880398
            ],
            [
              105.83670766623224,
              21.022802283116896
            ],
            [
              105.83669109740997,
              21.022808898544195
            ],
            [
              105.83666563201113,
              21.02282117397781
            ],
            [
              105.83661464453205,
              21.022841775316422
            ],
            [
              105.83658072014356,
              21.022895899852013
            ],
            [
              105.83640429742857,
              21.02295714441342
            ],
            [
              105.83635104166879,
              21.02302399374465
            ],
            [
              105.83638304837716,
              21.023075827703384
            ],
            [
              105.8363139877306,
              21.02311052773025
            ],
            [
              105.83635875039329,
              21.023177198543582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.11796",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 70,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02280397403532,
          "to_latitude": 21.023177198543582,
          "to_longitude": 105.83635875039329,
          "from_longitude": 105.83670819994846
        }
      },
      {
        "id": 11753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83689899159344,
            21.023175687257098,
            105.83708480482842,
            21.02327892352086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83689899159344,
              21.023175687257098
            ],
            [
              105.83696163378286,
              21.023203546930237
            ],
            [
              105.83701484969936,
              21.023226099564717
            ],
            [
              105.83705674580378,
              21.023243800681264
            ],
            [
              105.83707822672822,
              21.023261856975093
            ],
            [
              105.83708480482842,
              21.02327892352086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.11_Linh Quang",
          "maTaiSan": "04.O14.DODV.11797",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023175687257098,
          "to_latitude": 21.02327892352086,
          "to_longitude": 105.83708480482842,
          "from_longitude": 105.83689899159344
        }
      },
      {
        "id": 11754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708480482842,
            21.0232755357008,
            105.8371584076572,
            21.023455071863985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709156021423,
              21.0232755357008
            ],
            [
              105.8370879004871,
              21.02327737099859
            ],
            [
              105.83708480482842,
              21.02327892352086
            ],
            [
              105.83713043716436,
              21.02337275428022
            ],
            [
              105.83713963348133,
              21.023388902003333
            ],
            [
              105.83714790295224,
              21.023411687560113
            ],
            [
              105.8371584076572,
              21.023455071863985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.11_Linh Quang",
          "maTaiSan": "04.O14.DODV.11798",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0232755357008,
          "to_latitude": 21.023455071863985,
          "to_longitude": 105.8371584076572,
          "from_longitude": 105.83709156021423
        }
      },
      {
        "id": 11755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668119503905,
            21.022494454231243,
            105.8367464142403,
            21.022708478203636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674373023543,
              21.022494454231243
            ],
            [
              105.83674528276093,
              21.022518395564486
            ],
            [
              105.8367464142403,
              21.02253584027596
            ],
            [
              105.83673802388863,
              21.022581529565297
            ],
            [
              105.83673654598827,
              21.022589578234673
            ],
            [
              105.83673326416768,
              21.022592073728312
            ],
            [
              105.83669330872729,
              21.02263766358999
            ],
            [
              105.8366875099388,
              21.022649392473205
            ],
            [
              105.83668119503905,
              21.022678100883876
            ],
            [
              105.83668128977015,
              21.022695077406578
            ],
            [
              105.83668539621343,
              21.022708478203636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.11799",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022494454231243,
          "to_latitude": 21.022708478203636,
          "to_longitude": 105.83668539621343,
          "from_longitude": 105.83674373023543
        }
      },
      {
        "id": 11756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668539621343,
            21.022708478203636,
            105.83673333538565,
            21.02287134014145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668539621343,
              21.022708478203636
            ],
            [
              105.83670322785332,
              21.02275922299783
            ],
            [
              105.83671578484305,
              21.02280598252279
            ],
            [
              105.83673333538565,
              21.02287134014145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.11800",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022708478203636,
          "to_latitude": 21.02287134014145,
          "to_longitude": 105.83673333538565,
          "from_longitude": 105.83668539621343
        }
      },
      {
        "id": 11757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83675785304071,
            21.02294366707557,
            105.8368773102518,
            21.02315130483054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675785304071,
              21.02294366707557
            ],
            [
              105.83679563728572,
              21.023035272166787
            ],
            [
              105.83682423419081,
              21.023097669754122
            ],
            [
              105.83682944412755,
              21.023107463965196
            ],
            [
              105.83685582857244,
              21.023141781280387
            ],
            [
              105.8368773102518,
              21.023146191697947
            ],
            [
              105.8368749444543,
              21.02314993365155
            ],
            [
              105.83687382562378,
              21.02315130483054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.13_Linh Quang",
          "maTaiSan": "04.O14.DODV.11801",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02294366707557,
          "to_latitude": 21.02315130483054,
          "to_longitude": 105.83687382562378,
          "from_longitude": 105.83675785304071
        }
      },
      {
        "id": 11758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701528469021,
            21.02318311787605,
            105.83726254333193,
            21.02323235436987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701528469021,
              21.023217169964116
            ],
            [
              105.83701768804251,
              21.023212745890177
            ],
            [
              105.83707376483034,
              21.02323235436987
            ],
            [
              105.83710098756845,
              21.02322003590285
            ],
            [
              105.8372148757147,
              21.02318311787605
            ],
            [
              105.83723025447925,
              21.023216280792344
            ],
            [
              105.83726254333193,
              21.02320591451723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.12_Linh Quang",
          "maTaiSan": "04.O14.DODV.11802",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023217169964116,
          "to_latitude": 21.02320591451723,
          "to_longitude": 105.83726254333193,
          "from_longitude": 105.83701528469021
        }
      },
      {
        "id": 11759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704646514839,
            21.02308743735817,
            105.83710098756845,
            21.02322003590285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710098756845,
              21.02322003590285
            ],
            [
              105.83704646514839,
              21.023130948356144
            ],
            [
              105.83708210416232,
              21.023111373523204
            ],
            [
              105.83706885868644,
              21.02308743735817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.12_Linh Quang",
          "maTaiSan": "04.O14.DODV.11803",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02322003590285,
          "to_latitude": 21.02308743735817,
          "to_longitude": 105.83706885868644,
          "from_longitude": 105.83710098756845
        }
      },
      {
        "id": 11760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83637114883417,
            21.023145729122216,
            105.83687288500155,
            21.023316000524087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687129447979,
              21.023150241545913
            ],
            [
              105.83687245241923,
              21.023148121402304
            ],
            [
              105.83687288500155,
              21.02314685927712
            ],
            [
              105.83686944248511,
              21.023145729122216
            ],
            [
              105.83686627539201,
              21.02314861605298
            ],
            [
              105.83684556047761,
              21.02316748515444
            ],
            [
              105.83683081563805,
              21.02321238106833
            ],
            [
              105.83682957809245,
              21.02321764662726
            ],
            [
              105.83682393500374,
              21.023217976069176
            ],
            [
              105.83676807215242,
              21.023221009361375
            ],
            [
              105.83665703847268,
              21.023236278139944
            ],
            [
              105.83661683525825,
              21.023241806728965
            ],
            [
              105.83660421939877,
              21.023246737160264
            ],
            [
              105.83659840972425,
              21.023316000524087
            ],
            [
              105.83649851950192,
              21.023308664510672
            ],
            [
              105.83637114883417,
              21.023218483532702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.14_Linh Quang",
          "maTaiSan": "04.O14.DODV.11804",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023150241545913,
          "to_latitude": 21.023218483532702,
          "to_longitude": 105.83637114883417,
          "from_longitude": 105.83687129447979
        }
      },
      {
        "id": 11761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83655784736632,
            21.02315957748171,
            105.83661683525825,
            21.023241806728965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661683525825,
              21.023241806728965
            ],
            [
              105.8365998119031,
              21.02319317601246
            ],
            [
              105.83655784736632,
              21.02315957748171
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.14_Linh Quang",
          "maTaiSan": "04.O14.DODV.11805",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023241806728965,
          "to_latitude": 21.02315957748171,
          "to_longitude": 105.83655784736632,
          "from_longitude": 105.83661683525825
        }
      },
      {
        "id": 11762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83642185455405,
            21.023459532257757,
            105.83717266418867,
            21.02355024134322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717266418867,
              21.023459532257757
            ],
            [
              105.83717231047484,
              21.023459585482584
            ],
            [
              105.837140255549,
              21.02346430634798
            ],
            [
              105.83706177699564,
              21.02347487103447
            ],
            [
              105.83686999812056,
              21.023504837170044
            ],
            [
              105.83675404707847,
              21.02352295549594
            ],
            [
              105.8365791783154,
              21.02355024134322
            ],
            [
              105.83655697540624,
              21.023501576489302
            ],
            [
              105.83642185455405,
              21.023518269657302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.11806",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023459532257757,
          "to_latitude": 21.023518269657302,
          "to_longitude": 105.83642185455405,
          "from_longitude": 105.83717266418867
        }
      },
      {
        "id": 11763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83641313079755,
            21.023518269657302,
            105.83642594244213,
            21.023627688402694
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641313079755,
              21.023627688402694
            ],
            [
              105.83642594244213,
              21.023581800354975
            ],
            [
              105.83642185455405,
              21.023518269657302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.11807",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023627688402694,
          "to_latitude": 21.023518269657302,
          "to_longitude": 105.83642185455405,
          "from_longitude": 105.83641313079755
        }
      },
      {
        "id": 11764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83639202223533,
            21.02332044604187,
            105.83642185455405,
            21.023518269657302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83642185455405,
              21.023518269657302
            ],
            [
              105.83641142470705,
              21.023445156237273
            ],
            [
              105.83639202223533,
              21.02332044604187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.11808",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023518269657302,
          "to_latitude": 21.02332044604187,
          "to_longitude": 105.83639202223533,
          "from_longitude": 105.83642185455405
        }
      },
      {
        "id": 11765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83685111111903,
            21.023350696581314,
            105.83686999812056,
            21.023504837170044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83686999812056,
              21.023504837170044
            ],
            [
              105.83685111111903,
              21.023350696581314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.11809",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023504837170044,
          "to_latitude": 21.023350696581314,
          "to_longitude": 105.83685111111903,
          "from_longitude": 105.83686999812056
        }
      },
      {
        "id": 11766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370853818722,
            21.023592431139054,
            105.83725941367206,
            21.023733092083592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372461594204,
              21.023592431139054
            ],
            [
              105.83724475205064,
              21.023592951098042
            ],
            [
              105.8372256430961,
              21.023600007210927
            ],
            [
              105.83725941367206,
              21.02368442060245
            ],
            [
              105.83715821820824,
              21.02370800332475
            ],
            [
              105.8370853818722,
              21.023733092083592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.4_Linh Quang",
          "maTaiSan": "04.O14.DODV.11810",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023592431139054,
          "to_latitude": 21.023733092083592,
          "to_longitude": 105.8370853818722,
          "from_longitude": 105.8372461594204
        }
      },
      {
        "id": 11767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725941367206,
            21.02368442060245,
            105.8373215699532,
            21.02382607149593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725941367206,
              21.02368442060245
            ],
            [
              105.83725957420134,
              21.023684743166136
            ],
            [
              105.83728628410027,
              21.023738371524495
            ],
            [
              105.8373215699532,
              21.02382607149593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.4_Linh Quang",
          "maTaiSan": "04.O14.DODV.11811",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02368442060245,
          "to_latitude": 21.02382607149593,
          "to_longitude": 105.8373215699532,
          "from_longitude": 105.83725941367206
        }
      },
      {
        "id": 11768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716880024129,
            21.023738371524495,
            105.83728628410027,
            21.023769194292452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728628410027,
              21.023738371524495
            ],
            [
              105.83716880024129,
              21.023769194292452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.4_Linh Quang",
          "maTaiSan": "04.O14.DODV.11812",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023738371524495,
          "to_latitude": 21.023769194292452,
          "to_longitude": 105.83716880024129,
          "from_longitude": 105.83728628410027
        }
      },
      {
        "id": 11769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695278199448,
            21.023825391126636,
            105.83736549245984,
            21.023950553205914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83736549245984,
              21.023877404909268
            ],
            [
              105.8373650696503,
              21.023877488281066
            ],
            [
              105.83716388113521,
              21.02391676093312
            ],
            [
              105.83711808373884,
              21.023929688386556
            ],
            [
              105.83708976123101,
              21.02393768312271
            ],
            [
              105.83705936760202,
              21.0239410337381
            ],
            [
              105.8369730292756,
              21.023950553205914
            ],
            [
              105.83695278199448,
              21.023825391126636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.11813",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023877404909268,
          "to_latitude": 21.023825391126636,
          "to_longitude": 105.83695278199448,
          "from_longitude": 105.83736549245984
        }
      },
      {
        "id": 11770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702755851544,
            21.023807740003917,
            105.83707911666866,
            21.0239410337381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705936760202,
              21.0239410337381
            ],
            [
              105.83702755851544,
              21.023819563143686
            ],
            [
              105.83707911666866,
              21.023807740003917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.11814",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0239410337381,
          "to_latitude": 21.023807740003917,
          "to_longitude": 105.83707911666866,
          "from_longitude": 105.83705936760202
        }
      },
      {
        "id": 11771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373283289853,
            21.023960359619267,
            105.83734386954467,
            21.02414313125891
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734015702181,
              21.02414309534387
            ],
            [
              105.83734386954467,
              21.02414313125891
            ],
            [
              105.83734306466026,
              21.02413939587755
            ],
            [
              105.83733926128049,
              21.02413945524826
            ],
            [
              105.83732906026474,
              21.024139615665057
            ],
            [
              105.8373283289853,
              21.024111509152046
            ],
            [
              105.83733257096728,
              21.02401067229612
            ],
            [
              105.83734348272748,
              21.023960359619267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.11815",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02414309534387,
          "to_latitude": 21.023960359619267,
          "to_longitude": 105.83734348272748,
          "from_longitude": 105.83734015702181
        }
      },
      {
        "id": 11772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720842299955,
            21.024004247365887,
            105.8372167218828,
            21.024152033086803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721661228203,
              21.024152033086803
            ],
            [
              105.83721661329268,
              21.024151180439077
            ],
            [
              105.8372167218828,
              21.024085696218915
            ],
            [
              105.83720842299955,
              21.024004247365887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.11816",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024152033086803,
          "to_latitude": 21.024004247365887,
          "to_longitude": 105.83720842299955,
          "from_longitude": 105.83721661228203
        }
      },
      {
        "id": 11773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366735892783,
            21.02431470685529,
            105.83671598285132,
            21.024316177385447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83671598285132,
              21.024316177385447
            ],
            [
              105.8366735892783,
              21.02431470685529
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.3_Linh Quang B",
          "maTaiSan": "04.O14.DODV.11817",
          "diaChiLapD": "Linh Quang B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024316177385447,
          "to_latitude": 21.02431470685529,
          "to_longitude": 105.8366735892783,
          "from_longitude": 105.83671598285132
        }
      },
      {
        "id": 11774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670339415087,
            21.024205670332325,
            105.83677027791977,
            21.024419390650102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670339415087,
              21.024205670332325
            ],
            [
              105.83675458783623,
              21.024212973930616
            ],
            [
              105.83675127545713,
              21.024257918361737
            ],
            [
              105.83677027791977,
              21.02428829781696
            ],
            [
              105.83675193903397,
              21.024419390650102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.4_Linh Quang B",
          "maTaiSan": "04.O14.DODV.11818",
          "diaChiLapD": "Linh Quang B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024205670332325,
          "to_latitude": 21.024419390650102,
          "to_longitude": 105.83675193903397,
          "from_longitude": 105.83670339415087
        }
      },
      {
        "id": 11775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420570111758,
            21.0274816155028,
            105.83434045848097,
            21.027576768245698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420570111758,
              21.027576768245698
            ],
            [
              105.83421999001806,
              21.02757022023061
            ],
            [
              105.83422071842524,
              21.02756782943777
            ],
            [
              105.83422264660555,
              21.02756148838254
            ],
            [
              105.83423602915502,
              21.027546446383447
            ],
            [
              105.83434045848097,
              21.0274816155028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.16_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11819",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027576768245698,
          "to_latitude": 21.0274816155028,
          "to_longitude": 105.83434045848097,
          "from_longitude": 105.83420570111758
        }
      },
      {
        "id": 11776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83422071842524,
            21.027543148994365,
            105.83449166579115,
            21.02762861830297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83422071842524,
              21.02756782943777
            ],
            [
              105.8342407524566,
              21.027602938803312
            ],
            [
              105.83425309618069,
              21.027603134761822
            ],
            [
              105.83430482983769,
              21.02762861830297
            ],
            [
              105.83449166579115,
              21.027543148994365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.16_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11820",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02756782943777,
          "to_latitude": 21.027543148994365,
          "to_longitude": 105.83449166579115,
          "from_longitude": 105.83422071842524
        }
      },
      {
        "id": 11777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405176828488,
            21.02721019859434,
            105.83411665659534,
            21.027342750819333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405176828488,
              21.02721019859434
            ],
            [
              105.83411665659534,
              21.027342750819333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.17_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11821",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02721019859434,
          "to_latitude": 21.027342750819333,
          "to_longitude": 105.83411665659534,
          "from_longitude": 105.83405176828488
        }
      },
      {
        "id": 11778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411665659534,
            21.027342750819333,
            105.83421489033405,
            21.02754341680323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421489033405,
              21.02754341680323
            ],
            [
              105.83411665659534,
              21.027342750819333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.17_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11822",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02754341680323,
          "to_latitude": 21.027342750819333,
          "to_longitude": 105.83411665659534,
          "from_longitude": 105.83421489033405
        }
      },
      {
        "id": 11779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83409992136797,
            21.027305074531895,
            105.8342210839936,
            21.027348789664686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83409992136797,
              21.027348789664686
            ],
            [
              105.83410794671497,
              21.027345894307814
            ],
            [
              105.83411665659534,
              21.027342750819333
            ],
            [
              105.8342210839936,
              21.027305074531895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.17_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11823",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027348789664686,
          "to_latitude": 21.027305074531895,
          "to_longitude": 105.8342210839936,
          "from_longitude": 105.83409992136797
        }
      },
      {
        "id": 11780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343671233025,
            21.027230613468255,
            105.83459001343766,
            21.027557160367923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343671233025,
              21.027230613468255
            ],
            [
              105.83440623578176,
              21.02730389155576
            ],
            [
              105.83446985362372,
              21.02739738726359
            ],
            [
              105.83450151026494,
              21.027380917895776
            ],
            [
              105.83454310767549,
              21.02744710157868
            ],
            [
              105.83458031860938,
              21.027529920668304
            ],
            [
              105.83459001343766,
              21.027557160367923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.15_Ngõ 67, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11824",
          "diaChiLapD": "Ngõ 67, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027230613468255,
          "to_latitude": 21.027557160367923,
          "to_longitude": 105.83459001343766,
          "from_longitude": 105.8343671233025
        }
      },
      {
        "id": 11781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83451586603354,
            21.02716638164521,
            105.8346432306237,
            21.02755078502457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346072085595,
              21.02755078502457
            ],
            [
              105.83459193060871,
              21.027528283037256
            ],
            [
              105.83451586603354,
              21.027384140598908
            ],
            [
              105.8346432306237,
              21.02729446221152
            ],
            [
              105.83459429803199,
              21.027245239151874
            ],
            [
              105.83453480789093,
              21.02716638164521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.14_Ngõ 67, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11825",
          "diaChiLapD": "Ngõ 67, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02755078502457,
          "to_latitude": 21.02716638164521,
          "to_longitude": 105.83453480789093,
          "from_longitude": 105.8346072085595
        }
      },
      {
        "id": 11782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490940783499,
            21.027130942370608,
            105.8349711342652,
            21.027411477675088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496830344909,
              21.027411477675088
            ],
            [
              105.83496299435787,
              21.027405478340523
            ],
            [
              105.83491939542589,
              21.02731953845311
            ],
            [
              105.83491876901924,
              21.027316232118412
            ],
            [
              105.83490940783499,
              21.02726686002218
            ],
            [
              105.83492331340115,
              21.027209461455296
            ],
            [
              105.8349711342652,
              21.02715095972179
            ],
            [
              105.83493436632536,
              21.027130942370608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.10_Ngõ 63, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11826",
          "diaChiLapD": "Ngõ 63, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027411477675088,
          "to_latitude": 21.027130942370608,
          "to_longitude": 105.83493436632536,
          "from_longitude": 105.83496830344909
        }
      },
      {
        "id": 11783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83486157713519,
            21.027316232118412,
            105.83491876901924,
            21.02733535234074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83491876901924,
              21.027316232118412
            ],
            [
              105.83491830811145,
              21.02731638612291
            ],
            [
              105.83486157713519,
              21.02733535234074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.10_Ngõ 63, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11827",
          "diaChiLapD": "Ngõ 63, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027316232118412,
          "to_latitude": 21.02733535234074,
          "to_longitude": 105.83486157713519,
          "from_longitude": 105.83491876901924
        }
      },
      {
        "id": 11784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83496830344909,
            21.027339908005924,
            105.83516162798135,
            21.027419934607078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83497344344752,
              21.027419934607078
            ],
            [
              105.83497172009531,
              21.027417101528464
            ],
            [
              105.83496830344909,
              21.027411477675088
            ],
            [
              105.83516162798135,
              21.027339908005924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.10_Ngõ 63, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11828",
          "diaChiLapD": "Ngõ 63, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027419934607078,
          "to_latitude": 21.027339908005924,
          "to_longitude": 105.83516162798135,
          "from_longitude": 105.83497344344752
        }
      },
      {
        "id": 11785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490940783499,
            21.027182614236644,
            105.83507116825274,
            21.02726686002218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83490940783499,
              21.02726686002218
            ],
            [
              105.83503005724074,
              21.027221045943406
            ],
            [
              105.83503400805226,
              21.02720806348233
            ],
            [
              105.83507116825274,
              21.027182614236644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.10_Ngõ 63, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11829",
          "diaChiLapD": "Ngõ 63, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02726686002218,
          "to_latitude": 21.027182614236644,
          "to_longitude": 105.83507116825274,
          "from_longitude": 105.83490940783499
        }
      },
      {
        "id": 11786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390926507144,
            21.026719576522495,
            105.8341670644138,
            21.026820659744356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390926507144,
              21.026820659744356
            ],
            [
              105.83410307465421,
              21.026744667623753
            ],
            [
              105.8341670644138,
              21.026719576522495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.3_Hồ Giám",
          "maTaiSan": "04.O14.DODV.11830",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026820659744356,
          "to_latitude": 21.026719576522495,
          "to_longitude": 105.8341670644138,
          "from_longitude": 105.83390926507144
        }
      },
      {
        "id": 11787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415350921747,
            21.026659314442213,
            105.83432060025518,
            21.026719576522495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415350921747,
              21.026692515598757
            ],
            [
              105.8341544908029,
              21.02669447800472
            ],
            [
              105.8341670644138,
              21.026719576522495
            ],
            [
              105.8341702637125,
              21.026718305348435
            ],
            [
              105.83418633508407,
              21.026711921109985
            ],
            [
              105.83432060025518,
              21.026659314442213
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.3_Hồ Giám",
          "maTaiSan": "04.O14.DODV.11831",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026692515598757,
          "to_latitude": 21.026659314442213,
          "to_longitude": 105.83432060025518,
          "from_longitude": 105.83415350921747
        }
      },
      {
        "id": 11788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343904557064,
            21.02668226727367,
            105.83449324482046,
            21.026726245597455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343904557064,
              21.026726245597455
            ],
            [
              105.83449324482046,
              21.02668226727367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.6_Ngõ 15, Hồ Giám",
          "maTaiSan": "04.O14.DODV.11832",
          "diaChiLapD": "Ngõ 15, Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026726245597455,
          "to_latitude": 21.02668226727367,
          "to_longitude": 105.83449324482046,
          "from_longitude": 105.8343904557064
        }
      },
      {
        "id": 11789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83376197940167,
            21.026524977100507,
            105.83393795136028,
            21.026607004527584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83376197940167,
              21.026607004527584
            ],
            [
              105.83377543720626,
              21.026601521010427
            ],
            [
              105.8339305594095,
              21.026538312413255
            ],
            [
              105.83393795136028,
              21.026524977100507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.19_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11833",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026607004527584,
          "to_latitude": 21.026524977100507,
          "to_longitude": 105.83393795136028,
          "from_longitude": 105.83376197940167
        }
      },
      {
        "id": 11790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83377543720626,
            21.026601521010427,
            105.83382615404429,
            21.026713389483636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377543720626,
              21.026601521010427
            ],
            [
              105.83379636070724,
              21.026647673405414
            ],
            [
              105.83382615404429,
              21.026713389483636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.19_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11834",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026601521010427,
          "to_latitude": 21.026713389483636,
          "to_longitude": 105.83382615404429,
          "from_longitude": 105.83377543720626
        }
      },
      {
        "id": 11791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83379636070724,
            21.026593169787,
            105.83398045411761,
            21.026647673405414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83379636070724,
              21.026647673405414
            ],
            [
              105.83379934849255,
              21.0266464249527
            ],
            [
              105.83391528168744,
              21.02659795853875
            ],
            [
              105.83394585207344,
              21.02660776576303
            ],
            [
              105.83398045411761,
              21.026593169787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.19_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11835",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026647673405414,
          "to_latitude": 21.026593169787,
          "to_longitude": 105.83398045411761,
          "from_longitude": 105.83379636070724
        }
      },
      {
        "id": 11792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548101028309,
            21.02445930232586,
            105.83580484978471,
            21.024595100940232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548101028309,
              21.024567381757123
            ],
            [
              105.83549061106909,
              21.024562218734406
            ],
            [
              105.8354933508768,
              21.024561191857973
            ],
            [
              105.83551296923625,
              21.024595100940232
            ],
            [
              105.83568309062395,
              21.02451769070653
            ],
            [
              105.83574629874757,
              21.024487379538563
            ],
            [
              105.83580484978471,
              21.02445930232586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.5_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11836",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024567381757123,
          "to_latitude": 21.02445930232586,
          "to_longitude": 105.83580484978471,
          "from_longitude": 105.83548101028309
        }
      },
      {
        "id": 11793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573121872593,
            21.024487379538563,
            105.83577735080941,
            21.02456869588368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83574629874757,
              21.024487379538563
            ],
            [
              105.83574672298846,
              21.024488242741878
            ],
            [
              105.83577735080941,
              21.024550517616316
            ],
            [
              105.83576524858285,
              21.0245552874003
            ],
            [
              105.83573121872593,
              21.02456869588368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.5_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.11837",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024487379538563,
          "to_latitude": 21.02456869588368,
          "to_longitude": 105.83573121872593,
          "from_longitude": 105.83574629874757
        }
      },
      {
        "id": 11794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83588188406179,
            21.024219944348708,
            105.83600574378377,
            21.024495364244302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588188406179,
              21.024219944348708
            ],
            [
              105.83600574378377,
              21.024495364244302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11838",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024219944348708,
          "to_latitude": 21.024495364244302,
          "to_longitude": 105.83600574378377,
          "from_longitude": 105.83588188406179
        }
      },
      {
        "id": 11795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83580731802493,
            21.02412914680589,
            105.83593595173912,
            21.024261850061986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592821429731,
              21.02412914680589
            ],
            [
              105.83592951123961,
              21.024132629583523
            ],
            [
              105.83593189103597,
              21.024137257745465
            ],
            [
              105.83591524649268,
              21.024146025855053
            ],
            [
              105.83593595173912,
              21.024191804225705
            ],
            [
              105.83588188406179,
              21.024219944348708
            ],
            [
              105.83580731802493,
              21.024261850061986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11839",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02412914680589,
          "to_latitude": 21.024261850061986,
          "to_longitude": 105.83580731802493,
          "from_longitude": 105.83592821429731
        }
      },
      {
        "id": 11796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83580731802493,
            21.024261850061986,
            105.83593983269702,
            21.024533300256888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83580731802493,
              21.024261850061986
            ],
            [
              105.83585186499806,
              21.024340882669637
            ],
            [
              105.83593983269702,
              21.024533300256888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11840",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024261850061986,
          "to_latitude": 21.024533300256888,
          "to_longitude": 105.83593983269702,
          "from_longitude": 105.83580731802493
        }
      },
      {
        "id": 11797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570847081374,
            21.024130168638507,
            105.83592776285873,
            21.024249656359682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592521569693,
              21.024130168638507
            ],
            [
              105.8359269212875,
              21.02413396101971
            ],
            [
              105.83592776285873,
              21.024136345909373
            ],
            [
              105.83589668002382,
              21.02414791633023
            ],
            [
              105.83583726928333,
              21.024168950847073
            ],
            [
              105.83574778728168,
              21.02422253806775
            ],
            [
              105.83570847081374,
              21.024249656359682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.11841",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024130168638507,
          "to_latitude": 21.024249656359682,
          "to_longitude": 105.83570847081374,
          "from_longitude": 105.83592521569693
        }
      },
      {
        "id": 11798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83624150135176,
            21.024267694819066,
            105.83654883558617,
            21.02501524905534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83654883558617,
              21.02501524905534
            ],
            [
              105.83654326080052,
              21.024996281691813
            ],
            [
              105.83651103348228,
              21.024989134038556
            ],
            [
              105.83624150135176,
              21.024267694819066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.6_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11842",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02501524905534,
          "to_latitude": 21.024267694819066,
          "to_longitude": 105.83624150135176,
          "from_longitude": 105.83654883558617
        }
      },
      {
        "id": 11799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362567731873,
            21.024261352259806,
            105.8365527787778,
            21.025014036501048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365527787778,
              21.025014036501048
            ],
            [
              105.83654541878202,
              21.02499511495112
            ],
            [
              105.83653126234692,
              21.024982411201183
            ],
            [
              105.8362567731873,
              21.024261352259806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.5_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11843",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025014036501048,
          "to_latitude": 21.024261352259806,
          "to_longitude": 105.8362567731873,
          "from_longitude": 105.8365527787778
        }
      },
      {
        "id": 11800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83639973153937,
            21.025130469545513,
            105.83717036303112,
            21.025348841879225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717036303112,
              21.025130469545513
            ],
            [
              105.8371688387945,
              21.025130908789407
            ],
            [
              105.83715574876918,
              21.025134675537608
            ],
            [
              105.83706235833148,
              21.02516021954279
            ],
            [
              105.83701533538793,
              21.025173395073743
            ],
            [
              105.83695077675502,
              21.025189774818177
            ],
            [
              105.836893070228,
              21.02520439746958
            ],
            [
              105.83683536753071,
              21.02521970833892
            ],
            [
              105.83675126822811,
              21.025247024645576
            ],
            [
              105.83668125799866,
              21.025268078195445
            ],
            [
              105.83650413879627,
              21.025317739801096
            ],
            [
              105.83639973153937,
              21.025348841879225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.13_Ngách 68, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11844",
          "diaChiLapD": "Ngách 68, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025130469545513,
          "to_latitude": 21.025348841879225,
          "to_longitude": 105.83639973153937,
          "from_longitude": 105.83717036303112
        }
      },
      {
        "id": 11801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83635480851336,
            21.02511726771528,
            105.83716584103655,
            21.025347531846755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635480851336,
              21.025347531846755
            ],
            [
              105.83648910130648,
              21.025310755076074
            ],
            [
              105.83666837642392,
              21.02526179732932
            ],
            [
              105.8366685329884,
              21.025261754107575
            ],
            [
              105.83684737100822,
              21.02520620660259
            ],
            [
              105.83693292238301,
              21.02518069587241
            ],
            [
              105.8371651369662,
              21.025117459954753
            ],
            [
              105.83716584103655,
              21.02511726771528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.14_Ngách 68, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11845",
          "diaChiLapD": "Ngách 68, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025347531846755,
          "to_latitude": 21.02511726771528,
          "to_longitude": 105.83716584103655,
          "from_longitude": 105.83635480851336
        }
      },
      {
        "id": 11802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83574883996944,
            21.02643647393731,
            105.83586524192359,
            21.026519951994217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586524192359,
              21.02643647393731
            ],
            [
              105.83574883996944,
              21.026519951994217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.7_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11846",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02643647393731,
          "to_latitude": 21.026519951994217,
          "to_longitude": 105.83574883996944,
          "from_longitude": 105.83586524192359
        }
      },
      {
        "id": 11803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362434618383,
            21.026498326955366,
            105.83635727622172,
            21.026691694671783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635727622172,
              21.026691694671783
            ],
            [
              105.83631073979127,
              21.0266264606338
            ],
            [
              105.83630081963925,
              21.026595777251586
            ],
            [
              105.8362434618383,
              21.026498326955366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.6_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.11847",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026691694671783,
          "to_latitude": 21.026498326955366,
          "to_longitude": 105.8362434618383,
          "from_longitude": 105.83635727622172
        }
      },
      {
        "id": 11804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647195554005,
            21.0264464230943,
            105.8365689808921,
            21.026556812847105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365689808921,
              21.026556812847105
            ],
            [
              105.83654145066511,
              21.026526039077375
            ],
            [
              105.83653384958117,
              21.026517542816265
            ],
            [
              105.8365020573247,
              21.02648101569958
            ],
            [
              105.83648481006311,
              21.026459562278124
            ],
            [
              105.83647195554005,
              21.0264464230943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.1_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11848",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026556812847105,
          "to_latitude": 21.0264464230943,
          "to_longitude": 105.83647195554005,
          "from_longitude": 105.8365689808921
        }
      },
      {
        "id": 11805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623618213154,
            21.0261187665548,
            105.83646141823873,
            21.026435652504734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646141823873,
              21.026435652504734
            ],
            [
              105.83639965445943,
              21.026372522025152
            ],
            [
              105.83636868792992,
              21.02634087003146
            ],
            [
              105.83636694832515,
              21.026339092011625
            ],
            [
              105.83636018188295,
              21.026331558989167
            ],
            [
              105.83633682121615,
              21.026300466613232
            ],
            [
              105.83633293798185,
              21.02629467166309
            ],
            [
              105.83631757093659,
              21.026271740335275
            ],
            [
              105.83630242997845,
              21.026256398581687
            ],
            [
              105.83626673312668,
              21.026220228423824
            ],
            [
              105.83623618213154,
              21.026186314495888
            ],
            [
              105.83631542556836,
              21.0261187665548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.4_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11849",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026435652504734,
          "to_latitude": 21.0261187665548,
          "to_longitude": 105.83631542556836,
          "from_longitude": 105.83646141823873
        }
      },
      {
        "id": 11806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633293798185,
            21.026249784416027,
            105.83642011772307,
            21.02629467166309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633293798185,
              21.02629467166309
            ],
            [
              105.83639349586436,
              21.026249784416027
            ],
            [
              105.83642011772307,
              21.02628311699583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.4_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11850",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02629467166309,
          "to_latitude": 21.02628311699583,
          "to_longitude": 105.83642011772307,
          "from_longitude": 105.83633293798185
        }
      },
      {
        "id": 11807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83653384958117,
            21.026408019623293,
            105.83665267331324,
            21.026517542816265
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83653384958117,
              21.026517542816265
            ],
            [
              105.83659920547214,
              21.026462912310247
            ],
            [
              105.83662873532172,
              21.026432595770643
            ],
            [
              105.83665267331324,
              21.026408019623293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.1_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11851",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026517542816265,
          "to_latitude": 21.026408019623293,
          "to_longitude": 105.83665267331324,
          "from_longitude": 105.83653384958117
        }
      },
      {
        "id": 11808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662873532172,
            21.026432595770643,
            105.83666684720723,
            21.02646898519107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662873532172,
              21.026432595770643
            ],
            [
              105.83666684720723,
              21.02646898519107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.1_Lương Sử B",
          "maTaiSan": "04.O14.DODV.11852",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026432595770643,
          "to_latitude": 21.02646898519107,
          "to_longitude": 105.83666684720723,
          "from_longitude": 105.83662873532172
        }
      },
      {
        "id": 11809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367194953626,
            21.02633042316322,
            105.8369239328985,
            21.02641787831166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369239328985,
              21.026390622845444
            ],
            [
              105.83692336103377,
              21.026389683598172
            ],
            [
              105.83688738214163,
              21.02633042316322
            ],
            [
              105.8367194953626,
              21.02641787831166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.5_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11853",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026390622845444,
          "to_latitude": 21.02641787831166,
          "to_longitude": 105.8367194953626,
          "from_longitude": 105.8369239328985
        }
      },
      {
        "id": 11810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83675495489344,
            21.026049785905407,
            105.83687999463513,
            21.02612005750372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675495489344,
              21.02612005750372
            ],
            [
              105.8367565678161,
              21.026118959377246
            ],
            [
              105.83676020644982,
              21.026115744967175
            ],
            [
              105.83682206431158,
              21.026062677909884
            ],
            [
              105.83684103135086,
              21.026049785905407
            ],
            [
              105.83687999463513,
              21.02608446580614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.5_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11854",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02612005750372,
          "to_latitude": 21.02608446580614,
          "to_longitude": 105.83687999463513,
          "from_longitude": 105.83675495489344
        }
      },
      {
        "id": 11811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83682206431158,
            21.026062677909884,
            105.83684708450733,
            21.026094462049734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83682206431158,
              21.026062677909884
            ],
            [
              105.83682258846682,
              21.02606334281253
            ],
            [
              105.83684708450733,
              21.026094462049734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.5_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11855",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026062677909884,
          "to_latitude": 21.026094462049734,
          "to_longitude": 105.83684708450733,
          "from_longitude": 105.83682206431158
        }
      },
      {
        "id": 11812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676020644982,
            21.026115744967175,
            105.83691656286491,
            21.026196825871185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676020644982,
              21.026115744967175
            ],
            [
              105.83682492761957,
              21.026196825871185
            ],
            [
              105.83691656286491,
              21.02614108683757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.5_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11856",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026115744967175,
          "to_latitude": 21.02614108683757,
          "to_longitude": 105.83691656286491,
          "from_longitude": 105.83676020644982
        }
      },
      {
        "id": 11813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365980508536,
            21.02588440695918,
            105.83666569999144,
            21.026000221646097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666547595247,
              21.02588440695918
            ],
            [
              105.8365980508536,
              21.02591624489356
            ],
            [
              105.83665687403922,
              21.025989265616325
            ],
            [
              105.83666569999144,
              21.026000221646097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.3_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11857",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02588440695918,
          "to_latitude": 21.026000221646097,
          "to_longitude": 105.83666569999144,
          "from_longitude": 105.83666547595247
        }
      },
      {
        "id": 11814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665687403922,
            21.025867605440823,
            105.83680016224493,
            21.025989265616325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665687403922,
              21.025989265616325
            ],
            [
              105.83665767374666,
              21.025988799231698
            ],
            [
              105.83680016224493,
              21.02590564743914
            ],
            [
              105.83678199894447,
              21.025867605440823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.3_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11858",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025989265616325,
          "to_latitude": 21.025867605440823,
          "to_longitude": 105.83678199894447,
          "from_longitude": 105.83665687403922
        }
      },
      {
        "id": 11815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83665947688459,
            21.02597895081415,
            105.83681731603248,
            21.026043653797135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681731603248,
              21.02597895081415
            ],
            [
              105.8367017308242,
              21.026043653797135
            ],
            [
              105.83666569999144,
              21.026000221646097
            ],
            [
              105.83666093466216,
              21.026003401855448
            ],
            [
              105.83665947688459,
              21.026004374572935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.3_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11859",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02597895081415,
          "to_latitude": 21.026004374572935,
          "to_longitude": 105.83665947688459,
          "from_longitude": 105.83681731603248
        }
      },
      {
        "id": 11816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8369277669746,
            21.025323389109207,
            105.83716428112736,
            21.025417628553626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369277669746,
              21.025417628553626
            ],
            [
              105.83698171261352,
              21.025393781715437
            ],
            [
              105.83703094660827,
              21.02536963379347
            ],
            [
              105.83705646112479,
              21.02535971004853
            ],
            [
              105.83716428112736,
              21.025323389109207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.1_Ngách 62, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11860",
          "diaChiLapD": "Ngách 62, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025417628553626,
          "to_latitude": 21.025323389109207,
          "to_longitude": 105.83716428112736,
          "from_longitude": 105.8369277669746
        }
      },
      {
        "id": 11817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676232890406,
            21.0254080216997,
            105.8369277669746,
            21.025471710221286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676232890406,
              21.025471710221286
            ],
            [
              105.83685083257521,
              21.025442191852648
            ],
            [
              105.8369277669746,
              21.025417628553626
            ],
            [
              105.83692661179033,
              21.02541421373242
            ],
            [
              105.83692449834325,
              21.0254080216997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.1_Ngách 62, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11861",
          "diaChiLapD": "Ngách 62, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025471710221286,
          "to_latitude": 21.0254080216997,
          "to_longitude": 105.83692449834325,
          "from_longitude": 105.83676232890406
        }
      },
      {
        "id": 11818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691518201196,
            21.025314723502643,
            105.83715879359922,
            21.02541700036594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83715879359922,
              21.025314723502643
            ],
            [
              105.8370535315667,
              21.02535328629314
            ],
            [
              105.83699033388059,
              21.025376348626516
            ],
            [
              105.83692593591455,
              21.02540343076752
            ],
            [
              105.83692248131139,
              21.02540445305576
            ],
            [
              105.83691518201196,
              21.02540641103586
            ],
            [
              105.83691684350522,
              21.025410568524624
            ],
            [
              105.83691941524033,
              21.02541700036594
            ],
            [
              105.83692298350064,
              21.025415658680643
            ],
            [
              105.83692109725857,
              21.025409153014525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.2_Ngách 62, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11862",
          "diaChiLapD": "Ngách 62, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025314723502643,
          "to_latitude": 21.025409153014525,
          "to_longitude": 105.83692109725857,
          "from_longitude": 105.83715879359922
        }
      },
      {
        "id": 11819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83690186785591,
            21.025338842301988,
            105.83692248131139,
            21.02540445305576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83692248131139,
              21.02540445305576
            ],
            [
              105.83692201903668,
              21.025402982173702
            ],
            [
              105.83690186785591,
              21.025338842301988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.2_Ngách 62, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11863",
          "diaChiLapD": "Ngách 62, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02540445305576,
          "to_latitude": 21.025338842301988,
          "to_longitude": 105.83690186785591,
          "from_longitude": 105.83692248131139
        }
      },
      {
        "id": 11820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367391970846,
            21.025401289528908,
            105.83691518201196,
            21.025453379311955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367391970846,
              21.025401289528908
            ],
            [
              105.83676361283152,
              21.025453379311955
            ],
            [
              105.83688953246106,
              21.025414206527522
            ],
            [
              105.83691518201196,
              21.02540641103586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.2_Ngách 62, Lương Sử C",
          "maTaiSan": "04.O14.DODV.11864",
          "diaChiLapD": "Ngách 62, Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025401289528908,
          "to_latitude": 21.02540641103586,
          "to_longitude": 105.83691518201196,
          "from_longitude": 105.8367391970846
        }
      },
      {
        "id": 11821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710237813946,
            21.025504232772334,
            105.83714901980089,
            21.025618465034547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710237813946,
              21.025504232772334
            ],
            [
              105.83710406474869,
              21.02550806316865
            ],
            [
              105.83714901980089,
              21.025618465034547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.10_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11865",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025504232772334,
          "to_latitude": 21.025618465034547,
          "to_longitude": 105.83714901980089,
          "from_longitude": 105.83710237813946
        }
      },
      {
        "id": 11822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704893658796,
            21.025333507035388,
            105.83726738882119,
            21.02552050183557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83726738882119,
              21.025333507035388
            ],
            [
              105.83726026118866,
              21.025336574246165
            ],
            [
              105.83722659174565,
              21.02534889467711
            ],
            [
              105.83724780060165,
              21.02542755764291
            ],
            [
              105.83721712799164,
              21.025437679325165
            ],
            [
              105.83721131099672,
              21.025439956987377
            ],
            [
              105.83713227169238,
              21.02548069846888
            ],
            [
              105.83704893658796,
              21.02552050183557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.11_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11866",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025333507035388,
          "to_latitude": 21.02552050183557,
          "to_longitude": 105.83704893658796,
          "from_longitude": 105.83726738882119
        }
      },
      {
        "id": 11823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721712799164,
            21.025437679325165,
            105.8372987443271,
            21.02572917663293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721712799164,
              21.025437679325165
            ],
            [
              105.83721956525366,
              21.02544638523611
            ],
            [
              105.83722072056649,
              21.025450510891066
            ],
            [
              105.8372987443271,
              21.02572917663293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.11_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11867",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025437679325165,
          "to_latitude": 21.02572917663293,
          "to_longitude": 105.8372987443271,
          "from_longitude": 105.83721712799164
        }
      },
      {
        "id": 11824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83772701861156,
            21.025586895426578,
            105.83788762203844,
            21.02568522165109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83788762203844,
              21.02566797504884
            ],
            [
              105.83781979040755,
              21.025586895426578
            ],
            [
              105.83772701861156,
              21.025626941610867
            ],
            [
              105.83774947413977,
              21.02568522165109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.4_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11868",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02566797504884,
          "to_latitude": 21.02568522165109,
          "to_longitude": 105.83774947413977,
          "from_longitude": 105.83788762203844
        }
      },
      {
        "id": 11825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83788762203844,
            21.02565442241301,
            105.83797899200928,
            21.025799829851703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83797899200928,
              21.025799829851703
            ],
            [
              105.83796022045736,
              21.02576574890712
            ],
            [
              105.83789924002916,
              21.025683622145177
            ],
            [
              105.83789153218244,
              21.02567324144462
            ],
            [
              105.83788762203844,
              21.02566797504884
            ],
            [
              105.837907381187,
              21.025655521605465
            ],
            [
              105.83790865835188,
              21.02565442241301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.4_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11869",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025799829851703,
          "to_latitude": 21.02565442241301,
          "to_longitude": 105.83790865835188,
          "from_longitude": 105.83797899200928
        }
      },
      {
        "id": 11826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83774046032889,
            21.025656427703705,
            105.83791101395468,
            21.025774937129484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83791101395468,
              21.025656427703705
            ],
            [
              105.83790917482197,
              21.025658014695757
            ],
            [
              105.83789153218244,
              21.02567324144462
            ],
            [
              105.83788152562153,
              21.025681877687024
            ],
            [
              105.83777269765959,
              21.025732193165233
            ],
            [
              105.83776670907666,
              21.02576298463496
            ],
            [
              105.83774046032889,
              21.025774937129484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.4.2_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11870",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025656427703705,
          "to_latitude": 21.025774937129484,
          "to_longitude": 105.83774046032889,
          "from_longitude": 105.83791101395468
        }
      },
      {
        "id": 11827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83677234824229,
            21.024430862332085,
            105.83702698531614,
            21.02489347119379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369897563433,
              21.02489347119379
            ],
            [
              105.83698885553673,
              21.02487875670167
            ],
            [
              105.8369858606193,
              21.024836154660285
            ],
            [
              105.8369884989536,
              21.024811611036462
            ],
            [
              105.83698947565523,
              21.02480252793307
            ],
            [
              105.83700022747426,
              21.02471698945609
            ],
            [
              105.83702698531614,
              21.024493499691346
            ],
            [
              105.83691545696065,
              21.024461329295338
            ],
            [
              105.83677234824229,
              21.024430862332085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.2_Linh Quang A",
          "maTaiSan": "04.O14.DODV.11871",
          "diaChiLapD": "Linh Quang A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02489347119379,
          "to_latitude": 21.024430862332085,
          "to_longitude": 105.83677234824229,
          "from_longitude": 105.8369897563433
        }
      },
      {
        "id": 11828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699318299514,
            21.024429300966816,
            105.83707293048991,
            21.024893473636318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83699586695323,
              21.024893473636318
            ],
            [
              105.83699505016396,
              21.024878644924645
            ],
            [
              105.83699484607207,
              21.024874310457683
            ],
            [
              105.83699318299514,
              21.024839196807974
            ],
            [
              105.8370031885415,
              21.02476869163831
            ],
            [
              105.83701441149125,
              21.024690347724363
            ],
            [
              105.837034911944,
              21.024490880170283
            ],
            [
              105.83704699123072,
              21.02446615820868
            ],
            [
              105.83707293048991,
              21.024429300966816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.1_Linh Quang A",
          "maTaiSan": "04.O14.DODV.11872",
          "diaChiLapD": "Linh Quang A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024893473636318,
          "to_latitude": 21.024429300966816,
          "to_longitude": 105.83707293048991,
          "from_longitude": 105.83699586695323
        }
      },
      {
        "id": 11829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699484607207,
            21.024874310457683,
            105.83706977936703,
            21.024875275715274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83699484607207,
              21.024874310457683
            ],
            [
              105.83706977936703,
              21.024875275715274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.1_Linh Quang A",
          "maTaiSan": "04.O14.DODV.11873",
          "diaChiLapD": "Linh Quang A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024874310457683,
          "to_latitude": 21.024875275715274,
          "to_longitude": 105.83706977936703,
          "from_longitude": 105.83699484607207
        }
      },
      {
        "id": 11830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593489301622,
            21.02356383968157,
            105.83611134825473,
            21.023862931918963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611134825473,
              21.023862931918963
            ],
            [
              105.83607561108917,
              21.023804064788877
            ],
            [
              105.83604757521861,
              21.023758234855862
            ],
            [
              105.83596026440244,
              21.02360333849071
            ],
            [
              105.83593489301622,
              21.02356383968157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.3_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11874",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023862931918963,
          "to_latitude": 21.02356383968157,
          "to_longitude": 105.83593489301622,
          "from_longitude": 105.83611134825473
        }
      },
      {
        "id": 11831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359247618746,
            21.023862931918963,
            105.83614708359246,
            21.024099154175033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359247618746,
              21.024099154175033
            ],
            [
              105.8360630178216,
              21.024054920240133
            ],
            [
              105.83612900421116,
              21.024033807254302
            ],
            [
              105.83614708359246,
              21.023909736500094
            ],
            [
              105.83611134825473,
              21.023862931918963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.3_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11875",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024099154175033,
          "to_latitude": 21.023862931918963,
          "to_longitude": 105.83611134825473,
          "from_longitude": 105.8359247618746
        }
      },
      {
        "id": 11832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615769287223,
            21.023816319465414,
            105.83625533661952,
            21.02385548370752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83615769287223,
              21.02385548370752
            ],
            [
              105.83625533661952,
              21.023816319465414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.2_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11876",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02385548370752,
          "to_latitude": 21.023816319465414,
          "to_longitude": 105.83625533661952,
          "from_longitude": 105.83615769287223
        }
      },
      {
        "id": 11833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615769287223,
            21.02385548370752,
            105.83630301666521,
            21.02389807241439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83615769287223,
              21.02385548370752
            ],
            [
              105.83616846053066,
              21.02387603058628
            ],
            [
              105.83617943340789,
              21.02389697838968
            ],
            [
              105.83626813570869,
              21.023865960014838
            ],
            [
              105.83630301666521,
              21.02389807241439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.2_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11877",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02385548370752,
          "to_latitude": 21.02389807241439,
          "to_longitude": 105.83630301666521,
          "from_longitude": 105.83615769287223
        }
      },
      {
        "id": 11834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608266019785,
            21.023661379361723,
            105.83628881924211,
            21.023776056401488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608266019785,
              21.023776056401488
            ],
            [
              105.83608329736934,
              21.02377577146345
            ],
            [
              105.83626127330648,
              21.023696151620207
            ],
            [
              105.83628881924211,
              21.023661379361723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.4_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11878",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023776056401488,
          "to_latitude": 21.023661379361723,
          "to_longitude": 105.83628881924211,
          "from_longitude": 105.83608266019785
        }
      },
      {
        "id": 11835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605232009953,
            21.023724657681754,
            105.83609971771183,
            21.023804608343678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83609971771183,
              21.023804608343678
            ],
            [
              105.83608266019785,
              21.023776056401488
            ],
            [
              105.83606190589506,
              21.023741316581546
            ],
            [
              105.83605232009953,
              21.023724657681754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.4_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11879",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023804608343678,
          "to_latitude": 21.023724657681754,
          "to_longitude": 105.83605232009953,
          "from_longitude": 105.83609971771183
        }
      },
      {
        "id": 11836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633110404169,
            21.02385285576574,
            105.83650200007945,
            21.023948978163915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633110404169,
              21.023948978163915
            ],
            [
              105.83633114338643,
              21.023948960809186
            ],
            [
              105.83635849649065,
              21.02393686942784
            ],
            [
              105.83643119199752,
              21.023890461981917
            ],
            [
              105.83650200007945,
              21.02385285576574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.11880",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023948978163915,
          "to_latitude": 21.02385285576574,
          "to_longitude": 105.83650200007945,
          "from_longitude": 105.83633110404169
        }
      },
      {
        "id": 11837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633894316009,
            21.023737695526044,
            105.83643119199752,
            21.023890461981917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83643119199752,
              21.023890461981917
            ],
            [
              105.83643114355169,
              21.02389039989776
            ],
            [
              105.83638082218145,
              21.02382595670447
            ],
            [
              105.83633894316009,
              21.023737695526044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.11881",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023890461981917,
          "to_latitude": 21.023737695526044,
          "to_longitude": 105.83633894316009,
          "from_longitude": 105.83643119199752
        }
      },
      {
        "id": 11838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8349099551095,
            21.02307390761017,
            105.83492451404682,
            21.02317553461853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349099551095,
              21.02307390761017
            ],
            [
              105.83492451404682,
              21.02317553461853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.11882",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02307390761017,
          "to_latitude": 21.02317553461853,
          "to_longitude": 105.83492451404682,
          "from_longitude": 105.8349099551095
        }
      },
      {
        "id": 11839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503644051217,
            21.023075436327723,
            105.83505199367042,
            21.023157272422562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503644051217,
              21.023075436327723
            ],
            [
              105.83505199367042,
              21.023157272422562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.11883",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023075436327723,
          "to_latitude": 21.023157272422562,
          "to_longitude": 105.83505199367042,
          "from_longitude": 105.83503644051217
        }
      },
      {
        "id": 11840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83527910837024,
            21.022370301967733,
            105.83542144774412,
            21.022381014212986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527910837024,
              21.022370301967733
            ],
            [
              105.83542144774412,
              21.022381014212986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11884",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022370301967733,
          "to_latitude": 21.022381014212986,
          "to_longitude": 105.83542144774412,
          "from_longitude": 105.83527910837024
        }
      },
      {
        "id": 11841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521087123118,
            21.02236631784827,
            105.83523037935933,
            21.02247186039339
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521087123118,
              21.02236631784827
            ],
            [
              105.83523037935933,
              21.02247186039339
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11885",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02236631784827,
          "to_latitude": 21.02247186039339,
          "to_longitude": 105.83523037935933,
          "from_longitude": 105.83521087123118
        }
      },
      {
        "id": 11842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509340474022,
            21.022349134821674,
            105.83527910837024,
            21.022370301967733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509340474022,
              21.022365262509098
            ],
            [
              105.83521087123118,
              21.02236631784827
            ],
            [
              105.83522824641526,
              21.022366473686166
            ],
            [
              105.83527910837024,
              21.022370301967733
            ],
            [
              105.8352787611812,
              21.022353739452136
            ],
            [
              105.83527802462734,
              21.022349134821674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11886",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022365262509098,
          "to_latitude": 21.022349134821674,
          "to_longitude": 105.83527802462734,
          "from_longitude": 105.83509340474022
        }
      },
      {
        "id": 11843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591089016701,
            21.022609709259957,
            105.83627430714225,
            21.02263354021695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591089016701,
              21.02263184266224
            ],
            [
              105.83591392738572,
              21.022632231476063
            ],
            [
              105.83594407259473,
              21.02263354021695
            ],
            [
              105.83619802389346,
              21.022615216433593
            ],
            [
              105.83627430714225,
              21.022609709259957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.13_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11887",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02263184266224,
          "to_latitude": 21.022609709259957,
          "to_longitude": 105.83627430714225,
          "from_longitude": 105.83591089016701
        }
      },
      {
        "id": 11844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361946023291,
            21.02257385387335,
            105.83619802389346,
            21.022615216433593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619802389346,
              21.022615216433593
            ],
            [
              105.83619792694674,
              21.02261404723567
            ],
            [
              105.8361946023291,
              21.02257385387335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.13_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11888",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022615216433593,
          "to_latitude": 21.02257385387335,
          "to_longitude": 105.8361946023291,
          "from_longitude": 105.83619802389346
        }
      },
      {
        "id": 11845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594407259473,
            21.02248654232742,
            105.83595731432405,
            21.02263354021695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83594407259473,
              21.02263354021695
            ],
            [
              105.83595302754861,
              21.022534130766218
            ],
            [
              105.83595731432405,
              21.02248654232742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.13_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11889",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02263354021695,
          "to_latitude": 21.02248654232742,
          "to_longitude": 105.83595731432405,
          "from_longitude": 105.83594407259473
        }
      },
      {
        "id": 11846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83595302754861,
            21.022514839641,
            105.83621891627111,
            21.022534130766218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83595302754861,
              21.022534130766218
            ],
            [
              105.83621891627111,
              21.022514839641
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.13_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11890",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022534130766218,
          "to_latitude": 21.022514839641,
          "to_longitude": 105.83621891627111,
          "from_longitude": 105.83595302754861
        }
      },
      {
        "id": 11847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591991506506,
            21.022764249318154,
            105.83617363358107,
            21.02287944072345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591991506506,
              21.022778610080582
            ],
            [
              105.8361449099645,
              21.022764249318154
            ],
            [
              105.8361659356516,
              21.022771602965594
            ],
            [
              105.83617167877424,
              21.022852175692087
            ],
            [
              105.83617363358107,
              21.02287944072345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.12_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11891",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022778610080582,
          "to_latitude": 21.02287944072345,
          "to_longitude": 105.83617363358107,
          "from_longitude": 105.83591991506506
        }
      },
      {
        "id": 11848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617167877424,
            21.022835430572744,
            105.83635910465014,
            21.022852175692087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617167877424,
              21.022852175692087
            ],
            [
              105.83635910465014,
              21.022835430572744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.12_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11892",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022852175692087,
          "to_latitude": 21.022835430572744,
          "to_longitude": 105.83635910465014,
          "from_longitude": 105.83617167877424
        }
      },
      {
        "id": 11849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359054450782,
            21.022778610080582,
            105.83591991506506,
            21.022894765301793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591991506506,
              21.022778610080582
            ],
            [
              105.83591286318227,
              21.02283528047235
            ],
            [
              105.8359054450782,
              21.022894765301793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.12_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11893",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022778610080582,
          "to_latitude": 21.022894765301793,
          "to_longitude": 105.8359054450782,
          "from_longitude": 105.83591991506506
        }
      },
      {
        "id": 11850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584227671825,
            21.022925781144536,
            105.83636695817968,
            21.023009891478228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584227671825,
              21.02300542528447
            ],
            [
              105.8358423122704,
              21.023005417883976
            ],
            [
              105.8358441391631,
              21.023005605808624
            ],
            [
              105.83600441511516,
              21.023009891478228
            ],
            [
              105.83617051417261,
              21.022999898210422
            ],
            [
              105.8361786690559,
              21.02294369116186
            ],
            [
              105.83636695817968,
              21.022925781144536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.8_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11894",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02300542528447,
          "to_latitude": 21.022925781144536,
          "to_longitude": 105.83636695817968,
          "from_longitude": 105.83584227671825
        }
      },
      {
        "id": 11851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584192885004,
            21.023006872151797,
            105.83602775525216,
            21.02330051176856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584192885004,
              21.023006872151797
            ],
            [
              105.8358419567518,
              21.023006872917904
            ],
            [
              105.8358432935098,
              21.02300697383214
            ],
            [
              105.83595552626454,
              21.023018023241594
            ],
            [
              105.83596110960642,
              21.02301802108925
            ],
            [
              105.83596207478672,
              21.02302497839265
            ],
            [
              105.83598286735798,
              21.02317478420919
            ],
            [
              105.83600482623076,
              21.023238729275718
            ],
            [
              105.83602775525216,
              21.02330051176856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.7_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11895",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023006872151797,
          "to_latitude": 21.02330051176856,
          "to_longitude": 105.83602775525216,
          "from_longitude": 105.83584192885004
        }
      },
      {
        "id": 11852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83596207478672,
            21.023017002014413,
            105.836185447763,
            21.02307667444786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83596207478672,
              21.02302497839265
            ],
            [
              105.83609694949995,
              21.023017002014413
            ],
            [
              105.83610097819634,
              21.02307667444786
            ],
            [
              105.836185447763,
              21.023063677276255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.7_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11896",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02302497839265,
          "to_latitude": 21.023063677276255,
          "to_longitude": 105.836185447763,
          "from_longitude": 105.83596207478672
        }
      },
      {
        "id": 11853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83583394351714,
            21.023049167807635,
            105.83584816987822,
            21.023269454641866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583394351714,
              21.023049167807635
            ],
            [
              105.83584816987822,
              21.023269454641866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.6_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11897",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023049167807635,
          "to_latitude": 21.023269454641866,
          "to_longitude": 105.83584816987822,
          "from_longitude": 105.83583394351714
        }
      },
      {
        "id": 11854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597515225357,
            21.022185790696,
            105.8359879696726,
            21.022352291620535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83597515225357,
              21.022352291620535
            ],
            [
              105.8359879696726,
              21.022185790696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11898",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022352291620535,
          "to_latitude": 21.022185790696,
          "to_longitude": 105.8359879696726,
          "from_longitude": 105.83597515225357
        }
      },
      {
        "id": 11855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83583583560487,
            21.02218503681475,
            105.83600389390834,
            21.022196824293445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83600389390834,
              21.02218503681475
            ],
            [
              105.83599987417739,
              21.02218527695904
            ],
            [
              105.8359879696726,
              21.022185790696
            ],
            [
              105.83593195466631,
              21.022188212968796
            ],
            [
              105.83592267110299,
              21.02218847356236
            ],
            [
              105.83583583560487,
              21.022196824293445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11899",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02218503681475,
          "to_latitude": 21.022196824293445,
          "to_longitude": 105.83583583560487,
          "from_longitude": 105.83600389390834
        }
      },
      {
        "id": 11856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83627337514112,
            21.021944476110633,
            105.83635336890774,
            21.022017405867015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634926824347,
              21.022017405867015
            ],
            [
              105.83635336890774,
              21.02201322274557
            ],
            [
              105.83629491259858,
              21.02196271559056
            ],
            [
              105.83627337514112,
              21.021944476110633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.11900",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022017405867015,
          "to_latitude": 21.021944476110633,
          "to_longitude": 105.83627337514112,
          "from_longitude": 105.83634926824347
        }
      },
      {
        "id": 11857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603022428225,
            21.022361699349023,
            105.83616978911833,
            21.02237705699273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603022428225,
              21.022362410711
            ],
            [
              105.83616978911833,
              21.022361699349023
            ],
            [
              105.83616943478258,
              21.02237440583433
            ],
            [
              105.83616884351991,
              21.02237705699273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.5_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11901",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022362410711,
          "to_latitude": 21.02237705699273,
          "to_longitude": 105.83616884351991,
          "from_longitude": 105.83603022428225
        }
      },
      {
        "id": 11858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83616978911833,
            21.022349741147256,
            105.8364303553676,
            21.022361699349023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83616978911833,
              21.022361699349023
            ],
            [
              105.83640773195192,
              21.022350850463692
            ],
            [
              105.8364303553676,
              21.022349741147256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.5_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11902",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022361699349023,
          "to_latitude": 21.022349741147256,
          "to_longitude": 105.8364303553676,
          "from_longitude": 105.83616978911833
        }
      },
      {
        "id": 11859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835408124798,
            21.021455529007895,
            105.8356417508124,
            21.02151968181848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83541402308721,
              21.021455529007895
            ],
            [
              105.83540966097299,
              21.021461650806533
            ],
            [
              105.835408124798,
              21.021464776280236
            ],
            [
              105.8354226169017,
              21.02147513011035
            ],
            [
              105.83544410242786,
              21.021514812492583
            ],
            [
              105.83561691907451,
              21.021516866529677
            ],
            [
              105.8356417508124,
              21.02151968181848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11903",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021455529007895,
          "to_latitude": 21.02151968181848,
          "to_longitude": 105.8356417508124,
          "from_longitude": 105.83541402308721
        }
      },
      {
        "id": 11860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350253509571,
            21.021280701028143,
            105.83503856255224,
            21.02162288034945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503856255224,
              21.02162288034945
            ],
            [
              105.83503174262012,
              21.021446247753783
            ],
            [
              105.83502991478699,
              21.02139890069786
            ],
            [
              105.8350253509571,
              21.021280701028143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.11904",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02162288034945,
          "to_latitude": 21.021280701028143,
          "to_longitude": 105.8350253509571,
          "from_longitude": 105.83503856255224
        }
      },
      {
        "id": 11861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350253509571,
            21.021072806196468,
            105.8352085348943,
            21.021280701028143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352085348943,
              21.021072806196468
            ],
            [
              105.83508234094009,
              21.021119507382195
            ],
            [
              105.83505321838967,
              21.021149079201102
            ],
            [
              105.83502888269544,
              21.02119231497076
            ],
            [
              105.8350253509571,
              21.021280701028143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.11905",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021072806196468,
          "to_latitude": 21.021280701028143,
          "to_longitude": 105.8350253509571,
          "from_longitude": 105.8352085348943
        }
      },
      {
        "id": 11862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83536110975717,
            21.020998111805564,
            105.83554108259965,
            21.02118708819306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536110975717,
              21.020998111805564
            ],
            [
              105.83536214625454,
              21.021000092904465
            ],
            [
              105.83536968747214,
              21.02101448396632
            ],
            [
              105.83543679609477,
              21.021142528671934
            ],
            [
              105.83549871139678,
              21.021167373918637
            ],
            [
              105.83554108259965,
              21.02118708819306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.11906",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020998111805564,
          "to_latitude": 21.02118708819306,
          "to_longitude": 105.83554108259965,
          "from_longitude": 105.83536110975717
        }
      },
      {
        "id": 11863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524353548668,
            21.02101448396632,
            105.83536968747214,
            21.021061447901193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536968747214,
              21.02101448396632
            ],
            [
              105.83524353548668,
              21.021061447901193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.11907",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02101448396632,
          "to_latitude": 21.021061447901193,
          "to_longitude": 105.83524353548668,
          "from_longitude": 105.83536968747214
        }
      },
      {
        "id": 11864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504515296768,
            21.02194696266549,
            105.83505256610941,
            21.022189472566485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83505256610941,
              21.022189472566485
            ],
            [
              105.83504515296768,
              21.022081315392345
            ],
            [
              105.8350458862003,
              21.02194696266549
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_8.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11908",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022189472566485,
          "to_latitude": 21.02194696266549,
          "to_longitude": 105.8350458862003,
          "from_longitude": 105.83505256610941
        }
      },
      {
        "id": 11865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504404795363,
            21.02182593958911,
            105.83531152669941,
            21.02194696266549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350458862003,
              21.02194696266549
            ],
            [
              105.8350459655765,
              21.02193254140382
            ],
            [
              105.83504404795363,
              21.021868383086208
            ],
            [
              105.83531152669941,
              21.02182593958911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_8.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11909",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02194696266549,
          "to_latitude": 21.02182593958911,
          "to_longitude": 105.83531152669941,
          "from_longitude": 105.8350458862003
        }
      },
      {
        "id": 11866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967390312641,
            21.019985560374163,
            105.8398018631482,
            21.02000143397067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8398018631482,
              21.019985560374163
            ],
            [
              105.83971093383374,
              21.019995233896587
            ],
            [
              105.83967390312641,
              21.02000143397067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.6_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11910",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019985560374163,
          "to_latitude": 21.02000143397067,
          "to_longitude": 105.83967390312641,
          "from_longitude": 105.8398018631482
        }
      },
      {
        "id": 11867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83563179830475,
            21.020901189491475,
            105.8357313846321,
            21.021230739106024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83563179830475,
              21.020901189491475
            ],
            [
              105.83563228299317,
              21.020902717171513
            ],
            [
              105.83563751345814,
              21.02091915814947
            ],
            [
              105.8356404028547,
              21.02092824212828
            ],
            [
              105.83565020589056,
              21.02103105129243
            ],
            [
              105.8356657609891,
              21.021056085397007
            ],
            [
              105.8356871224748,
              21.02111649102393
            ],
            [
              105.83566554695115,
              21.021131259961482
            ],
            [
              105.83572985457181,
              21.02122788340438
            ],
            [
              105.8357313846321,
              21.021230739106024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11911",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020901189491475,
          "to_latitude": 21.021230739106024,
          "to_longitude": 105.8357313846321,
          "from_longitude": 105.83563179830475
        }
      },
      {
        "id": 11868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354141599504,
            21.022362419540276,
            105.83545502444005,
            21.02268507031442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543315800193,
              21.02268507031442
            ],
            [
              105.83542664871868,
              21.022577242021462
            ],
            [
              105.83542473069004,
              21.022561904768363
            ],
            [
              105.8354141599504,
              21.022477386949383
            ],
            [
              105.8354143154397,
              21.022477323863217
            ],
            [
              105.83545502444005,
              21.022456072469293
            ],
            [
              105.83544929918696,
              21.0223637857071
            ],
            [
              105.83544921557856,
              21.022362419540276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11912",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02268507031442,
          "to_latitude": 21.022362419540276,
          "to_longitude": 105.83544921557856,
          "from_longitude": 105.83543315800193
        }
      },
      {
        "id": 11869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83646200386625,
            21.022152040098707,
            105.83653776558491,
            21.02235613713421
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646942240074,
              21.02235613713421
            ],
            [
              105.83646958503672,
              21.022354251306805
            ],
            [
              105.83647886240763,
              21.02228269656806
            ],
            [
              105.8364667710396,
              21.022244023999857
            ],
            [
              105.83646525724726,
              21.02223270773062
            ],
            [
              105.836464364452,
              21.02221872570425
            ],
            [
              105.8364632829555,
              21.02220143971079
            ],
            [
              105.83646218821086,
              21.022165741569744
            ],
            [
              105.83646200386625,
              21.02215821772066
            ],
            [
              105.83646496639514,
              21.022157980055038
            ],
            [
              105.83653776558491,
              21.022152040098707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11913",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02235613713421,
          "to_latitude": 21.022152040098707,
          "to_longitude": 105.83653776558491,
          "from_longitude": 105.83646942240074
        }
      },
      {
        "id": 11870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629078457442,
            21.02228269656806,
            105.83647886240763,
            21.02228953122375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647886240763,
              21.02228269656806
            ],
            [
              105.83640839184763,
              21.022283762093977
            ],
            [
              105.83639301642742,
              21.022284949571528
            ],
            [
              105.83635605517264,
              21.022289243701497
            ],
            [
              105.83629078457442,
              21.02228953122375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11914",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02228269656806,
          "to_latitude": 21.02228953122375,
          "to_longitude": 105.83629078457442,
          "from_longitude": 105.83647886240763
        }
      },
      {
        "id": 11871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670316888401,
            21.021192959570488,
            105.8369079035297,
            21.021193319814238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670316888401,
              21.021192959570488
            ],
            [
              105.8367804352262,
              21.0211930952115
            ],
            [
              105.8369079035297,
              21.021193319814238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.11915",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021192959570488,
          "to_latitude": 21.021193319814238,
          "to_longitude": 105.8369079035297,
          "from_longitude": 105.83670316888401
        }
      },
      {
        "id": 11872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83654463440557,
            21.021163549095043,
            105.83670316888401,
            21.021192959570488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670316888401,
              21.021192959570488
            ],
            [
              105.83656635089757,
              21.021187340683056
            ],
            [
              105.83654463440557,
              21.021163549095043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.11916",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021192959570488,
          "to_latitude": 21.021163549095043,
          "to_longitude": 105.83654463440557,
          "from_longitude": 105.83670316888401
        }
      },
      {
        "id": 11873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371298721574,
            21.024161972807523,
            105.83731409113453,
            21.02416797660567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371298721574,
              21.024161972807523
            ],
            [
              105.83716404430511,
              21.02416271685778
            ],
            [
              105.8372350114601,
              21.024165495376955
            ],
            [
              105.83731352088923,
              21.02416797660567
            ],
            [
              105.83731399872646,
              21.0241653440671
            ],
            [
              105.83731409113453,
              21.024163459488495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.8_Linh Quang",
          "maTaiSan": "04.O14.DODV.11917",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024161972807523,
          "to_latitude": 21.024163459488495,
          "to_longitude": 105.83731409113453,
          "from_longitude": 105.8371298721574
        }
      },
      {
        "id": 11874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670435498406,
            21.02415587271712,
            105.83712261242938,
            21.024166354851456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670435498406,
              21.024158576056955
            ],
            [
              105.83670628055987,
              21.024158516906432
            ],
            [
              105.83679045444066,
              21.02415587271712
            ],
            [
              105.83698966134575,
              21.024165133209667
            ],
            [
              105.83712261242938,
              21.024166354851456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.7_Linh Quang",
          "maTaiSan": "04.O14.DODV.11918",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024158576056955,
          "to_latitude": 21.024166354851456,
          "to_longitude": 105.83712261242938,
          "from_longitude": 105.83670435498406
        }
      },
      {
        "id": 11875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708517180115,
            21.024161972807523,
            105.8371298721574,
            21.02440344827291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371298721574,
              21.024161972807523
            ],
            [
              105.83712826685202,
              21.024218185582775
            ],
            [
              105.8371180744198,
              21.02428897348706
            ],
            [
              105.83710452677116,
              21.024342571502277
            ],
            [
              105.83708517180115,
              21.02440344827291
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.8_Linh Quang",
          "maTaiSan": "04.O14.DODV.11919",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024161972807523,
          "to_latitude": 21.02440344827291,
          "to_longitude": 105.83708517180115,
          "from_longitude": 105.8371298721574
        }
      },
      {
        "id": 11876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696677796837,
            21.024166354851456,
            105.83712261242938,
            21.024391041488702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712261242938,
              21.024166354851456
            ],
            [
              105.8371185772159,
              21.02425428452973
            ],
            [
              105.8371066901082,
              21.024304289478717
            ],
            [
              105.83707693617677,
              21.024391041488702
            ],
            [
              105.8370120410584,
              21.0243632859965
            ],
            [
              105.83699726947764,
              21.024308455365336
            ],
            [
              105.83696749191229,
              21.0242948295627
            ],
            [
              105.83696677796837,
              21.02423673052921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.7_Linh Quang",
          "maTaiSan": "04.O14.DODV.11920",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024166354851456,
          "to_latitude": 21.02423673052921,
          "to_longitude": 105.83696677796837,
          "from_longitude": 105.83712261242938
        }
      },
      {
        "id": 11877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83880748300342,
            21.026344508342852,
            105.83899992512444,
            21.026420529928313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899992512444,
              21.026344508342852
            ],
            [
              105.83899971210624,
              21.026344606038638
            ],
            [
              105.83899379972519,
              21.026346686426653
            ],
            [
              105.83892300286388,
              21.026371602447842
            ],
            [
              105.8389102739373,
              21.026385412290992
            ],
            [
              105.83880748300342,
              21.026420529928313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.3_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11921",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026344508342852,
          "to_latitude": 21.026420529928313,
          "to_longitude": 105.83880748300342,
          "from_longitude": 105.83899992512444
        }
      },
      {
        "id": 11878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893595801717,
            21.025845791648127,
            105.83896137054478,
            21.02594702132142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896137054478,
              21.02594702132142
            ],
            [
              105.83895721894491,
              21.025925777228984
            ],
            [
              105.83894835751528,
              21.02588805358785
            ],
            [
              105.83894019063248,
              21.025861327773043
            ],
            [
              105.83893595801717,
              21.02584829708407
            ],
            [
              105.83894363107184,
              21.025846773430246
            ],
            [
              105.83894857026475,
              21.025845791648127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.7_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11922",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02594702132142,
          "to_latitude": 21.025845791648127,
          "to_longitude": 105.83894857026475,
          "from_longitude": 105.83896137054478
        }
      },
      {
        "id": 11879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640773195192,
            21.022350850463692,
            105.83650740148474,
            21.022775341423493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83640773195192,
              21.022350850463692
            ],
            [
              105.83641016165767,
              21.02236011733111
            ],
            [
              105.83646996313097,
              21.02258998266457
            ],
            [
              105.83645602659132,
              21.022630276264273
            ],
            [
              105.83650740148474,
              21.022775341423493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.5_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11923",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022350850463692,
          "to_latitude": 21.022775341423493,
          "to_longitude": 105.83650740148474,
          "from_longitude": 105.83640773195192
        }
      },
      {
        "id": 11880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663974951197,
            21.02080078488737,
            105.83679009459948,
            21.02080253606212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663974951197,
              21.02080253606212
            ],
            [
              105.83679009459948,
              21.02080078488737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.11_Văn Chương",
          "maTaiSan": "04.O14.DODV.11924",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02080253606212,
          "to_latitude": 21.02080078488737,
          "to_longitude": 105.83679009459948,
          "from_longitude": 105.83663974951197
        }
      },
      {
        "id": 11881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663611392544,
            21.02072523799586,
            105.83664144513406,
            21.020927996330535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663611392544,
              21.02072523799586
            ],
            [
              105.83663949319092,
              21.02079107812388
            ],
            [
              105.83663963131124,
              21.02079376453502
            ],
            [
              105.83663974951197,
              21.02080253606212
            ],
            [
              105.83664144513406,
              21.020927996330535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.11_Văn Chương",
          "maTaiSan": "04.O14.DODV.11925",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02072523799586,
          "to_latitude": 21.020927996330535,
          "to_longitude": 105.83664144513406,
          "from_longitude": 105.83663611392544
        }
      },
      {
        "id": 11882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505542663852,
            21.0214499514949,
            105.835408124798,
            21.021464776280236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.835408124798,
              21.021464776280236
            ],
            [
              105.83537790360016,
              21.021455483311755
            ],
            [
              105.83533817741149,
              21.02145400925962
            ],
            [
              105.8352365655587,
              21.0214499514949
            ],
            [
              105.83505542663852,
              21.02145406088189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11926",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021464776280236,
          "to_latitude": 21.02145406088189,
          "to_longitude": 105.83505542663852,
          "from_longitude": 105.835408124798
        }
      },
      {
        "id": 11883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83574138326176,
            21.021512941077088,
            105.83591925496056,
            21.021529659761605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83574138326176,
              21.021529659761605
            ],
            [
              105.83574656844237,
              21.021526060227547
            ],
            [
              105.83576546641459,
              21.021512941077088
            ],
            [
              105.83591925496056,
              21.021517022105495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.11927",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021529659761605,
          "to_latitude": 21.021517022105495,
          "to_longitude": 105.83591925496056,
          "from_longitude": 105.83574138326176
        }
      },
      {
        "id": 11884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83583459458323,
            21.021579093047137,
            105.83605679587366,
            21.021597630874567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583459458323,
              21.021597630874567
            ],
            [
              105.83584793114476,
              21.021587575682393
            ],
            [
              105.83605679587366,
              21.021579093047137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.11928",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021597630874567,
          "to_latitude": 21.021579093047137,
          "to_longitude": 105.83605679587366,
          "from_longitude": 105.83583459458323
        }
      },
      {
        "id": 11885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83589695749326,
            21.021307032808334,
            105.83616604799639,
            21.021353226067912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589695749326,
              21.021307032808334
            ],
            [
              105.83592715766622,
              21.021317386172658
            ],
            [
              105.83594900435214,
              21.0213179544154
            ],
            [
              105.83596607734653,
              21.0213263915187
            ],
            [
              105.83616604799639,
              21.021353226067912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11929",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021307032808334,
          "to_latitude": 21.021353226067912,
          "to_longitude": 105.83616604799639,
          "from_longitude": 105.83589695749326
        }
      },
      {
        "id": 11886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83594233999554,
            21.02126311542187,
            105.83618622024731,
            21.021270019479427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83594233999554,
              21.02126311542187
            ],
            [
              105.83618622024731,
              21.021270019479427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.11930",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02126311542187,
          "to_latitude": 21.021270019479427,
          "to_longitude": 105.83618622024731,
          "from_longitude": 105.83594233999554
        }
      },
      {
        "id": 11887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859208102737,
            21.020389433134,
            105.83892953520241,
            21.02057672344734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859208102737,
              21.020389433134
            ],
            [
              105.83859274408782,
              21.0203901687018
            ],
            [
              105.83875938521656,
              21.02057499559653
            ],
            [
              105.83892953520241,
              21.02057672344734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11931",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020389433134,
          "to_latitude": 21.02057672344734,
          "to_longitude": 105.83892953520241,
          "from_longitude": 105.83859208102737
        }
      },
      {
        "id": 11888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83869421245251,
            21.020212691737093,
            105.83885085742416,
            21.020357056461553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83869421245251,
              21.020212691737093
            ],
            [
              105.83885085742416,
              21.020357056461553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.11932",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020212691737093,
          "to_latitude": 21.020357056461553,
          "to_longitude": 105.83885085742416,
          "from_longitude": 105.83869421245251
        }
      },
      {
        "id": 11889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83938148085484,
            21.02306159272073,
            105.83942694029288,
            21.02313220452726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83942694029288,
              21.02313220452726
            ],
            [
              105.83941029832337,
              21.0231047744911
            ],
            [
              105.83938148085484,
              21.02306159272073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.29_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11933",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02313220452726,
          "to_latitude": 21.02306159272073,
          "to_longitude": 105.83938148085484,
          "from_longitude": 105.83942694029288
        }
      },
      {
        "id": 11890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929913921132,
            21.023059525581164,
            105.8393896480655,
            21.023164982287593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393896480655,
              21.023059525581164
            ],
            [
              105.83938148085484,
              21.02306159272073
            ],
            [
              105.83931634011414,
              21.023078084593504
            ],
            [
              105.83929913921132,
              21.023082439011475
            ],
            [
              105.83929983383159,
              21.023084857124555
            ],
            [
              105.8393228447252,
              21.023164982287593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.29_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11934",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023059525581164,
          "to_latitude": 21.023164982287593,
          "to_longitude": 105.8393228447252,
          "from_longitude": 105.8393896480655
        }
      },
      {
        "id": 11891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395292106865,
            21.023663415335164,
            105.83953297122747,
            21.023821323488054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953297122747,
              21.023821323488054
            ],
            [
              105.8395292106865,
              21.023663415335164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.33_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11935",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023821323488054,
          "to_latitude": 21.023663415335164,
          "to_longitude": 105.8395292106865,
          "from_longitude": 105.83953297122747
        }
      },
      {
        "id": 11892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395292106865,
            21.02356738378998,
            105.83954763696748,
            21.023663415335164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954763696748,
              21.023663310829946
            ],
            [
              105.839545353288,
              21.023663324812542
            ],
            [
              105.8395292106865,
              21.023663415335164
            ],
            [
              105.8395297561654,
              21.02356738378998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.33_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.11936",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023663310829946,
          "to_latitude": 21.02356738378998,
          "to_longitude": 105.8395297561654,
          "from_longitude": 105.83954763696748
        }
      },
      {
        "id": 11893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83967038177896,
            21.026269022969593,
            105.83971357387833,
            21.0262888881069
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83967038177896,
              21.026282182689766
            ],
            [
              105.83970600515106,
              21.026269022969593
            ],
            [
              105.83971357387833,
              21.0262888881069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.1_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11937",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026282182689766,
          "to_latitude": 21.0262888881069,
          "to_longitude": 105.83971357387833,
          "from_longitude": 105.83967038177896
        }
      },
      {
        "id": 11894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83964637997559,
            21.02622674036219,
            105.83967996224035,
            21.026304314016578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83964637997559,
              21.02622674036219
            ],
            [
              105.83967038177896,
              21.026282182689766
            ],
            [
              105.83967996224035,
              21.026304314016578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.1_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11938",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02622674036219,
          "to_latitude": 21.026304314016578,
          "to_longitude": 105.83967996224035,
          "from_longitude": 105.83964637997559
        }
      },
      {
        "id": 11895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83961005622172,
            21.026177556135142,
            105.83969716444608,
            21.026400941216036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83966981078507,
              21.026400941216036
            ],
            [
              105.83963483709947,
              21.026301836184647
            ],
            [
              105.83961005622172,
              21.026240063398113
            ],
            [
              105.83964637997559,
              21.02622674036219
            ],
            [
              105.83966298059865,
              21.02623606636447
            ],
            [
              105.83967738683346,
              21.026230611123374
            ],
            [
              105.8396806605245,
              21.026212232443683
            ],
            [
              105.83969716444608,
              21.026204981172384
            ],
            [
              105.83968555625698,
              21.026177556135142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.1_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.11939",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026400941216036,
          "to_latitude": 21.026177556135142,
          "to_longitude": 105.83968555625698,
          "from_longitude": 105.83966981078507
        }
      },
      {
        "id": 11896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755822973387,
            21.025506463234798,
            105.83778755933339,
            21.025846020497767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778755933339,
              21.025506463234798
            ],
            [
              105.83778178997198,
              21.025510549837364
            ],
            [
              105.83772456793257,
              21.025562127561013
            ],
            [
              105.837679516993,
              21.025578223618446
            ],
            [
              105.83764649223765,
              21.02559281165992
            ],
            [
              105.83762048690652,
              21.025604594033915
            ],
            [
              105.8375795219556,
              21.025622311096726
            ],
            [
              105.83755822973387,
              21.025631905223175
            ],
            [
              105.83759865533334,
              21.02572685344236
            ],
            [
              105.83760014064922,
              21.025738214994927
            ],
            [
              105.83761406270558,
              21.02579106987375
            ],
            [
              105.83762770127497,
              21.025817290100843
            ],
            [
              105.83764484658916,
              21.02581893351749
            ],
            [
              105.83765727019156,
              21.025846020497767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.5_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11940",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025506463234798,
          "to_latitude": 21.025846020497767,
          "to_longitude": 105.83765727019156,
          "from_longitude": 105.83778755933339
        }
      },
      {
        "id": 11897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751064937056,
            21.02567498574061,
            105.83755823363968,
            21.025842675909292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751064937056,
              21.02567498574061
            ],
            [
              105.83751092113165,
              21.02567507111168
            ],
            [
              105.83752232543227,
              21.0256786486146
            ],
            [
              105.83755165287121,
              21.02577453936889
            ],
            [
              105.83754308500703,
              21.025789641905856
            ],
            [
              105.83755823363968,
              21.025842675909292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.6_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11941",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02567498574061,
          "to_latitude": 21.025842675909292,
          "to_longitude": 105.83755823363968,
          "from_longitude": 105.83751064937056
        }
      },
      {
        "id": 11898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83745221810415,
            21.025184567807162,
            105.83752960656624,
            21.02522545123733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752960656624,
              21.025184567807162
            ],
            [
              105.83745221810415,
              21.02522545123733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.7_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11942",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025184567807162,
          "to_latitude": 21.02522545123733,
          "to_longitude": 105.83745221810415,
          "from_longitude": 105.83752960656624
        }
      },
      {
        "id": 11899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730054861648,
            21.025438410316557,
            105.83744399454179,
            21.025450582137953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730054861648,
              21.025450582137953
            ],
            [
              105.83735605556993,
              21.0254437938957
            ],
            [
              105.83739609278943,
              21.02544134321558
            ],
            [
              105.83744399454179,
              21.025438410316557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.8_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11943",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025450582137953,
          "to_latitude": 21.025438410316557,
          "to_longitude": 105.83744399454179,
          "from_longitude": 105.83730054861648
        }
      },
      {
        "id": 11900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83744399454179,
            21.025438410316557,
            105.83767398512573,
            21.02545385266891
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83744399454179,
              21.025438410316557
            ],
            [
              105.837496746794,
              21.02543893995848
            ],
            [
              105.83751119902108,
              21.025439305043314
            ],
            [
              105.8375921607332,
              21.025441348629002
            ],
            [
              105.83767398512573,
              21.02545385266891
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.8_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11944",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025438410316557,
          "to_latitude": 21.02545385266891,
          "to_longitude": 105.83767398512573,
          "from_longitude": 105.83744399454179
        }
      },
      {
        "id": 11901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702780147264,
            21.025337193642358,
            105.83726837718064,
            21.02554307624588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83726837718064,
              21.025337193642358
            ],
            [
              105.8372616437337,
              21.025339530011458
            ],
            [
              105.83723893886526,
              21.025350249242607
            ],
            [
              105.8372590259983,
              21.02543734570394
            ],
            [
              105.83722316009802,
              21.025446703537796
            ],
            [
              105.83722072056649,
              21.025450510891066
            ],
            [
              105.83721612658647,
              21.025448538287176
            ],
            [
              105.83720745901066,
              21.025451645592934
            ],
            [
              105.83717688082461,
              21.025465240599402
            ],
            [
              105.83714485961309,
              21.02548210514573
            ],
            [
              105.83710237813946,
              21.025504232772334
            ],
            [
              105.83702780147264,
              21.02554307624588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.10_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11945",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025337193642358,
          "to_latitude": 21.02554307624588,
          "to_longitude": 105.83702780147264,
          "from_longitude": 105.83726837718064
        }
      },
      {
        "id": 11902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83740089496455,
            21.024880094887987,
            105.83745551163425,
            21.02498568133237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83742329736596,
              21.02498568133237
            ],
            [
              105.8374214782472,
              21.024981550818254
            ],
            [
              105.83740089496455,
              21.02493480288976
            ],
            [
              105.8374055336654,
              21.02492170589419
            ],
            [
              105.83745551163425,
              21.024880094887987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.12_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11946",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02498568133237,
          "to_latitude": 21.024880094887987,
          "to_longitude": 105.83745551163425,
          "from_longitude": 105.83742329736596
        }
      },
      {
        "id": 11903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718816937989,
            21.024925546280887,
            105.83753615277321,
            21.025185557083212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83718816937989,
              21.025185557083212
            ],
            [
              105.83726934821253,
              21.025159323454563
            ],
            [
              105.83726737972637,
              21.02512637995294
            ],
            [
              105.83726369587454,
              21.025062063311328
            ],
            [
              105.83727012694212,
              21.02506018997811
            ],
            [
              105.8373130645338,
              21.025041729862718
            ],
            [
              105.83737069228623,
              21.025013711757076
            ],
            [
              105.83742329736596,
              21.02498568133237
            ],
            [
              105.83753615277321,
              21.024925546280887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.12_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11947",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025185557083212,
          "to_latitude": 21.024925546280887,
          "to_longitude": 105.83753615277321,
          "from_longitude": 105.83718816937989
        }
      },
      {
        "id": 11904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716100900372,
            21.02462559938196,
            105.83735835668118,
            21.0247784485426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716100900372,
              21.02462559938196
            ],
            [
              105.83716412801577,
              21.024628396665786
            ],
            [
              105.83716519095101,
              21.024629245882647
            ],
            [
              105.83723537789483,
              21.02468538072645
            ],
            [
              105.83724580363288,
              21.024688590036853
            ],
            [
              105.83725401207265,
              21.024703013842796
            ],
            [
              105.83726056226507,
              21.02471472259406
            ],
            [
              105.83727772846787,
              21.02472719198182
            ],
            [
              105.83731439097116,
              21.024737686676886
            ],
            [
              105.83731612342125,
              21.02474938120994
            ],
            [
              105.83734651608255,
              21.024759608702986
            ],
            [
              105.83735835668118,
              21.0247784485426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.11948",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02462559938196,
          "to_latitude": 21.0247784485426,
          "to_longitude": 105.83735835668118,
          "from_longitude": 105.83716100900372
        }
      },
      {
        "id": 11905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713206544988,
            21.024629245882647,
            105.83716519095101,
            21.024871373615532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713367352121,
              21.024871373615532
            ],
            [
              105.83713206544988,
              21.02476807176557
            ],
            [
              105.83713503637176,
              21.024702137202915
            ],
            [
              105.83713617715394,
              21.024672995413482
            ],
            [
              105.83715848061357,
              21.024636576957576
            ],
            [
              105.83716519095101,
              21.024629245882647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.11949",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024871373615532,
          "to_latitude": 21.024629245882647,
          "to_longitude": 105.83716519095101,
          "from_longitude": 105.83713367352121
        }
      },
      {
        "id": 11906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373400514471,
            21.024083885968107,
            105.83736146755668,
            21.024291156017547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735670672826,
              21.024291156017547
            ],
            [
              105.8373575348381,
              21.0242847535071
            ],
            [
              105.83736065828002,
              21.024254757440644
            ],
            [
              105.83736146755668,
              21.02422827546766
            ],
            [
              105.83735783703074,
              21.024200894066517
            ],
            [
              105.83734341377239,
              21.024126633420984
            ],
            [
              105.8373409286716,
              21.024098851671017
            ],
            [
              105.8373400514471,
              21.024083885968107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.20_Linh Quang",
          "maTaiSan": "04.O14.DODV.11950",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024291156017547,
          "to_latitude": 21.024083885968107,
          "to_longitude": 105.8373400514471,
          "from_longitude": 105.83735670672826
        }
      },
      {
        "id": 11907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755422178966,
            21.023521208086944,
            105.8375852280902,
            21.023601200196495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375852280902,
              21.023601200196495
            ],
            [
              105.83755653601854,
              21.02352717964118
            ],
            [
              105.83755422178966,
              21.023521208086944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11951",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023601200196495,
          "to_latitude": 21.023521208086944,
          "to_longitude": 105.83755422178966,
          "from_longitude": 105.8375852280902
        }
      },
      {
        "id": 11908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725492845629,
            21.023438882171305,
            105.83766862786261,
            21.023617582945292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725492845629,
              21.023617331385477
            ],
            [
              105.8372581106011,
              21.0236164504324
            ],
            [
              105.8372818049028,
              21.023617582945292
            ],
            [
              105.83740803778919,
              21.023574685188056
            ],
            [
              105.83743297901994,
              21.02356620847073
            ],
            [
              105.83749352554818,
              21.023555363399357
            ],
            [
              105.83753467300792,
              21.023538040178735
            ],
            [
              105.83754662644434,
              21.02353300816697
            ],
            [
              105.83755422178966,
              21.023521208086944
            ],
            [
              105.8375563218575,
              21.023517945238073
            ],
            [
              105.83757457644606,
              21.023505189441355
            ],
            [
              105.8376454994036,
              21.023470840121213
            ],
            [
              105.83766862786261,
              21.023438882171305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11952",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023617331385477,
          "to_latitude": 21.023438882171305,
          "to_longitude": 105.83766862786261,
          "from_longitude": 105.83725492845629
        }
      },
      {
        "id": 11909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83652396353311,
            21.023518999227953,
            105.83720888314718,
            21.023839457709354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720888314718,
              21.023518999227953
            ],
            [
              105.8370152222885,
              21.023630932374633
            ],
            [
              105.83697837493328,
              21.023650859980425
            ],
            [
              105.83693010221772,
              21.023671853605865
            ],
            [
              105.83683463718073,
              21.02371420762871
            ],
            [
              105.83670885219708,
              21.023758295922157
            ],
            [
              105.83663944854518,
              21.02378363134631
            ],
            [
              105.83662386957711,
              21.023790088352186
            ],
            [
              105.83652396353311,
              21.023839457709354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.8_Linh Quang",
          "maTaiSan": "04.O14.DODV.11953",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023518999227953,
          "to_latitude": 21.023839457709354,
          "to_longitude": 105.83652396353311,
          "from_longitude": 105.83720888314718
        }
      },
      {
        "id": 11910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368773102518,
            21.023146191697947,
            105.83700845653577,
            21.02320959029776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368773102518,
              21.023146191697947
            ],
            [
              105.83695138879597,
              21.023184789525025
            ],
            [
              105.83700180889521,
              21.023207291793067
            ],
            [
              105.83700845653577,
              21.02320959029776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.13_Linh Quang",
          "maTaiSan": "04.O14.DODV.11954",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023146191697947,
          "to_latitude": 21.02320959029776,
          "to_longitude": 105.83700845653577,
          "from_longitude": 105.8368773102518
        }
      },
      {
        "id": 11911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370899969046,
            21.023259151697566,
            105.83720391668486,
            21.023511468212426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8370899969046,
              21.023259151697566
            ],
            [
              105.83714065609114,
              21.023360993691306
            ],
            [
              105.83715985164858,
              21.023407356500023
            ],
            [
              105.83717642125225,
              21.023457555576126
            ],
            [
              105.83717832275758,
              21.02346128465763
            ],
            [
              105.83720391668486,
              21.023511468212426
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.11955",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023259151697566,
          "to_latitude": 21.023511468212426,
          "to_longitude": 105.83720391668486,
          "from_longitude": 105.8370899969046
        }
      },
      {
        "id": 11912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695232458967,
            21.022822908303898,
            105.83699881830981,
            21.022956750816945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696593891673,
              21.022822908303898
            ],
            [
              105.83699881830981,
              21.02292058185618
            ],
            [
              105.83695232458967,
              21.022956750816945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.11956",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022822908303898,
          "to_latitude": 21.022956750816945,
          "to_longitude": 105.83695232458967,
          "from_longitude": 105.83696593891673
        }
      },
      {
        "id": 11913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83705457139025,
            21.022829925548468,
            105.83718027216666,
            21.02288429415236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705457139025,
              21.02288429415236
            ],
            [
              105.83705566157077,
              21.022883822723752
            ],
            [
              105.83718027216666,
              21.022829925548468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.11957",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02288429415236,
          "to_latitude": 21.022829925548468,
          "to_longitude": 105.83718027216666,
          "from_longitude": 105.83705457139025
        }
      },
      {
        "id": 11914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702370334633,
            21.022800728094868,
            105.83707948775206,
            21.022932204944894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702370334633,
              21.022800728094868
            ],
            [
              105.83705457139025,
              21.02288429415236
            ],
            [
              105.83705669580982,
              21.022890045359105
            ],
            [
              105.83707948775206,
              21.022932204944894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.11958",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022800728094868,
          "to_latitude": 21.022932204944894,
          "to_longitude": 105.83707948775206,
          "from_longitude": 105.83702370334633
        }
      },
      {
        "id": 11915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673662852938,
            21.02275713422079,
            105.83712092895503,
            21.022893282205978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673662852938,
              21.022893282205978
            ],
            [
              105.8367370828493,
              21.022893157132483
            ],
            [
              105.83673875380067,
              21.022892637687537
            ],
            [
              105.83674198138526,
              21.02288438712064
            ],
            [
              105.83695815008757,
              21.022825899269716
            ],
            [
              105.83696593891673,
              21.022822908303898
            ],
            [
              105.83702370334633,
              21.022800728094868
            ],
            [
              105.83712092895503,
              21.02275713422079
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.11959",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022893282205978,
          "to_latitude": 21.02275713422079,
          "to_longitude": 105.83712092895503,
          "from_longitude": 105.83673662852938
        }
      },
      {
        "id": 11916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766927488718,
            21.02382174808272,
            105.83777561437047,
            21.02399740429877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766927488718,
              21.02382174808272
            ],
            [
              105.83769118053483,
              21.023915965594064
            ],
            [
              105.83775067154333,
              21.02391246341207
            ],
            [
              105.83777561437047,
              21.02399740429877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11960",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02382174808272,
          "to_latitude": 21.02399740429877,
          "to_longitude": 105.83777561437047,
          "from_longitude": 105.83766927488718
        }
      },
      {
        "id": 11917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83760135622296,
            21.02381870415365,
            105.83784500753646,
            21.02395833405444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83784500753646,
              21.02395833405444
            ],
            [
              105.83783425319213,
              21.02392454597165
            ],
            [
              105.8378210046385,
              21.023825897073742
            ],
            [
              105.8377400871319,
              21.023844176779004
            ],
            [
              105.83770766371853,
              21.023836503732966
            ],
            [
              105.83768244170587,
              21.023823484258777
            ],
            [
              105.83766927488718,
              21.02382174808272
            ],
            [
              105.8376461816978,
              21.02381870415365
            ],
            [
              105.83760135622296,
              21.023819620402286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11961",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02395833405444,
          "to_latitude": 21.023819620402286,
          "to_longitude": 105.83760135622296,
          "from_longitude": 105.83784500753646
        }
      },
      {
        "id": 11918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83741237254091,
            21.023815962462017,
            105.83760135622296,
            21.023853713767238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83760041612642,
              21.023815962462017
            ],
            [
              105.83760056018392,
              21.023816435944752
            ],
            [
              105.83760135622296,
              21.023819620402286
            ],
            [
              105.83747562495141,
              21.023840402221715
            ],
            [
              105.83741237254091,
              21.023853713767238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.11962",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023815962462017,
          "to_latitude": 21.023853713767238,
          "to_longitude": 105.83741237254091,
          "from_longitude": 105.83760041612642
        }
      },
      {
        "id": 11919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83797659270547,
            21.023977582082576,
            105.83813109054888,
            21.024017958750907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83813109054888,
              21.023977582082576
            ],
            [
              105.8381150879165,
              21.023984993308677
            ],
            [
              105.83805339554193,
              21.02401356548835
            ],
            [
              105.83802961635202,
              21.02401739400464
            ],
            [
              105.83797659270547,
              21.024017958750907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11963",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023977582082576,
          "to_latitude": 21.024017958750907,
          "to_longitude": 105.83797659270547,
          "from_longitude": 105.83813109054888
        }
      },
      {
        "id": 11920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810985973267,
            21.023868237008262,
            105.83819211776586,
            21.02415282166697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83819211776586,
              21.023868237008262
            ],
            [
              105.83818577293403,
              21.023877781023664
            ],
            [
              105.83814006131139,
              21.023958002061473
            ],
            [
              105.83813109054888,
              21.023977582082576
            ],
            [
              105.83812538438693,
              21.0239900367462
            ],
            [
              105.83814913897987,
              21.024052018979372
            ],
            [
              105.8381836384229,
              21.02411957172079
            ],
            [
              105.83810985973267,
              21.02415282166697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11964",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023868237008262,
          "to_latitude": 21.02415282166697,
          "to_longitude": 105.83810985973267,
          "from_longitude": 105.83819211776586
        }
      },
      {
        "id": 11921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899538642517,
            21.02363192308884,
            105.83907232354687,
            21.023701933509077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899538642517,
              21.02363192308884
            ],
            [
              105.83907232354687,
              21.023701933509077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.11_Linh Quang",
          "maTaiSan": "04.O14.DODV.11965",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02363192308884,
          "to_latitude": 21.023701933509077,
          "to_longitude": 105.83907232354687,
          "from_longitude": 105.83899538642517
        }
      },
      {
        "id": 11922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892057485501,
            21.023551724057253,
            105.83906314057485,
            21.02371151024261
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892525016552,
              21.023707624052523
            ],
            [
              105.838924065015,
              21.02371003427809
            ],
            [
              105.83892312956367,
              21.02371151024261
            ],
            [
              105.83892057485501,
              21.023709850075086
            ],
            [
              105.8389230738225,
              21.02370636214647
            ],
            [
              105.83892760212608,
              21.023700041635912
            ],
            [
              105.83893522311689,
              21.023689404722013
            ],
            [
              105.83897649875558,
              21.02364825259838
            ],
            [
              105.83899538642517,
              21.02363192308884
            ],
            [
              105.83903170178297,
              21.023600525842998
            ],
            [
              105.83906314057485,
              21.023551724057253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.11_Linh Quang",
          "maTaiSan": "04.O14.DODV.11966",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023707624052523,
          "to_latitude": 21.023551724057253,
          "to_longitude": 105.83906314057485,
          "from_longitude": 105.83892525016552
        }
      },
      {
        "id": 11923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895950156753,
            21.023843879195102,
            105.83914140564681,
            21.024133774533784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914140564681,
              21.023843879195102
            ],
            [
              105.83913988721834,
              21.023847587191362
            ],
            [
              105.83912923838999,
              21.02387361010121
            ],
            [
              105.8390571557245,
              21.023973157678707
            ],
            [
              105.83900764701522,
              21.02403350960317
            ],
            [
              105.83900541438867,
              21.02403628718907
            ],
            [
              105.83901378155453,
              21.02405275050492
            ],
            [
              105.83900829146208,
              21.024061342852026
            ],
            [
              105.83895950156753,
              21.024133774533784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.15_Linh Quang",
          "maTaiSan": "04.O14.DODV.11967",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023843879195102,
          "to_latitude": 21.024133774533784,
          "to_longitude": 105.83895950156753,
          "from_longitude": 105.83914140564681
        }
      },
      {
        "id": 11924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598881747109,
            21.023611849218916,
            105.83605232009953,
            21.023732530779284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598881747109,
              21.023611849218916
            ],
            [
              105.83605232009953,
              21.023724657681754
            ],
            [
              105.83603913292029,
              21.023731859778433
            ],
            [
              105.8360374983957,
              21.023732530779284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.4_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11968",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023611849218916,
          "to_latitude": 21.023732530779284,
          "to_longitude": 105.8360374983957,
          "from_longitude": 105.83598881747109
        }
      },
      {
        "id": 11925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83613332210322,
            21.02329438741198,
            105.83619552997348,
            21.023505534326798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619552997348,
              21.023505534326798
            ],
            [
              105.83613332210322,
              21.02329438741198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.5_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11969",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023505534326798,
          "to_latitude": 21.02329438741198,
          "to_longitude": 105.83613332210322,
          "from_longitude": 105.83619552997348
        }
      },
      {
        "id": 11926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592978934483,
            21.023219394626828,
            105.83629770425875,
            21.02358025499038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592978934483,
              21.02353513864513
            ],
            [
              105.83593295635153,
              21.023534819596097
            ],
            [
              105.8359899429548,
              21.023526266871333
            ],
            [
              105.83604750916473,
              21.02358025499038
            ],
            [
              105.83611565081988,
              21.023551835180395
            ],
            [
              105.83616690748096,
              21.023530456791867
            ],
            [
              105.83619552997348,
              21.023505534326798
            ],
            [
              105.83619555491897,
              21.02350552246225
            ],
            [
              105.83620120321402,
              21.02350060463994
            ],
            [
              105.83622289215766,
              21.023480767085577
            ],
            [
              105.83629770425875,
              21.023454091951507
            ],
            [
              105.83625547906685,
              21.023219394626828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.5_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11970",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02353513864513,
          "to_latitude": 21.023219394626828,
          "to_longitude": 105.83625547906685,
          "from_longitude": 105.83592978934483
        }
      },
      {
        "id": 11927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356472009974,
            21.02326570982681,
            105.83586196407404,
            21.023451632448076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356472009974,
              21.023451632448076
            ],
            [
              105.83569547195096,
              21.023447905213885
            ],
            [
              105.83584771088228,
              21.02340006031564
            ],
            [
              105.8358569903956,
              21.02338197643347
            ],
            [
              105.83585738751225,
              21.02336140898456
            ],
            [
              105.83585746691685,
              21.023357706280933
            ],
            [
              105.83584816987822,
              21.023269454641866
            ],
            [
              105.8358501604696,
              21.023268808086513
            ],
            [
              105.835861895921,
              21.023265736355235
            ],
            [
              105.83586193815658,
              21.023265719889633
            ],
            [
              105.83586196407404,
              21.02326570982681
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.6_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11971",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023451632448076,
          "to_latitude": 21.02326570982681,
          "to_longitude": 105.83586196407404,
          "from_longitude": 105.8356472009974
        }
      },
      {
        "id": 11928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573473966442,
            21.023244496891646,
            105.83573919739575,
            21.023318170806345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573473966442,
              21.023244496891646
            ],
            [
              105.83573919739575,
              21.023318170806345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.9_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11972",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023244496891646,
          "to_latitude": 21.023318170806345,
          "to_longitude": 105.83573919739575,
          "from_longitude": 105.83573473966442
        }
      },
      {
        "id": 11929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559663213461,
            21.023249852858843,
            105.83566826000337,
            21.023275776604976
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559663213461,
              21.023253107579784
            ],
            [
              105.83560032551269,
              21.023275776604976
            ],
            [
              105.83566826000337,
              21.023268534070056
            ],
            [
              105.83566657245518,
              21.023249852858843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.9_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11973",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023253107579784,
          "to_latitude": 21.023249852858843,
          "to_longitude": 105.83566657245518,
          "from_longitude": 105.83559663213461
        }
      },
      {
        "id": 11930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83553880024658,
            21.02291713286589,
            105.83562198387244,
            21.022933364206022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562198387244,
              21.02291713286589
            ],
            [
              105.83553880024658,
              21.022933364206022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.10_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11974",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02291713286589,
          "to_latitude": 21.022933364206022,
          "to_longitude": 105.83553880024658,
          "from_longitude": 105.83562198387244
        }
      },
      {
        "id": 11931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83554919804021,
            21.02280505175202,
            105.83563888776098,
            21.022988577901437
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554919804021,
              21.02280505175202
            ],
            [
              105.83557008591875,
              21.022878652187252
            ],
            [
              105.83561077422426,
              21.022874213461318
            ],
            [
              105.83561272998074,
              21.02287801906937
            ],
            [
              105.83562198387244,
              21.02291713286589
            ],
            [
              105.83563886412534,
              21.022988479566187
            ],
            [
              105.83563888776098,
              21.022988577901437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.10_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11975",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02280505175202,
          "to_latitude": 21.022988577901437,
          "to_longitude": 105.83563888776098,
          "from_longitude": 105.83554919804021
        }
      },
      {
        "id": 11932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83578501631654,
            21.02284856551577,
            105.83588193178221,
            21.02288630322271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588193178221,
              21.022882422698917
            ],
            [
              105.83588188843375,
              21.022882412073272
            ],
            [
              105.83587326216927,
              21.02288031202136
            ],
            [
              105.83586805785562,
              21.022878761472974
            ],
            [
              105.8358180789885,
              21.022854255148005
            ],
            [
              105.83580378876418,
              21.02284856551577
            ],
            [
              105.83578501631654,
              21.02288630322271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.11_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11976",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022882422698917,
          "to_latitude": 21.02288630322271,
          "to_longitude": 105.83578501631654,
          "from_longitude": 105.83588193178221
        }
      },
      {
        "id": 11933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562889437096,
            21.022759336297874,
            105.83583621706214,
            21.022854255148005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358180789885,
              21.022854255148005
            ],
            [
              105.83583621706214,
              21.022807296835506
            ],
            [
              105.83571486305257,
              21.02280662052321
            ],
            [
              105.83568968093935,
              21.02280691225606
            ],
            [
              105.83568039911404,
              21.022759336297874
            ],
            [
              105.83562889437096,
              21.02276309476452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.11_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11977",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022854255148005,
          "to_latitude": 21.02276309476452,
          "to_longitude": 105.83562889437096,
          "from_longitude": 105.8358180789885
        }
      },
      {
        "id": 11934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571485976854,
            21.02280662052321,
            105.83571486305257,
            21.022898195674447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83571486305257,
              21.02280662052321
            ],
            [
              105.83571485976854,
              21.022898195674447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.11_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11978",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02280662052321,
          "to_latitude": 21.022898195674447,
          "to_longitude": 105.83571485976854,
          "from_longitude": 105.83571486305257
        }
      },
      {
        "id": 11935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586805785562,
            21.022631096749997,
            105.83590442726607,
            21.022878761472974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590442726607,
              21.022631096749997
            ],
            [
              105.83589913630126,
              21.02270580128283
            ],
            [
              105.83589079549196,
              21.022801321140577
            ],
            [
              105.83586805785562,
              21.022878761472974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.11_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.11979",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022631096749997,
          "to_latitude": 21.022878761472974,
          "to_longitude": 105.83586805785562,
          "from_longitude": 105.83590442726607
        }
      },
      {
        "id": 11936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509429619626,
            21.02244276195933,
            105.8351488537474,
            21.02262442346759
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514126084965,
              21.02262442346759
            ],
            [
              105.8351474290617,
              21.02262442118025
            ],
            [
              105.8351488537474,
              21.02262441960403
            ],
            [
              105.83514807452887,
              21.0226195812476
            ],
            [
              105.83514365154944,
              21.02259353954055
            ],
            [
              105.83513502923529,
              21.02256140091813
            ],
            [
              105.835117992828,
              21.022514989318438
            ],
            [
              105.83509429619626,
              21.02244276195933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11980",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02262442346759,
          "to_latitude": 21.02244276195933,
          "to_longitude": 105.83509429619626,
          "from_longitude": 105.83514126084965
        }
      },
      {
        "id": 11937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515492731304,
            21.022648624660647,
            105.8352962708278,
            21.022672191092074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515492731304,
              21.022672191092074
            ],
            [
              105.83516051412394,
              21.02267125953213
            ],
            [
              105.8352962708278,
              21.022648624660647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11981",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022672191092074,
          "to_latitude": 21.022648624660647,
          "to_longitude": 105.8352962708278,
          "from_longitude": 105.83515492731304
        }
      },
      {
        "id": 11938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351488537474,
            21.02262441960403,
            105.83516546678628,
            21.022796540346373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83516546678628,
              21.022796540346373
            ],
            [
              105.8351620454723,
              21.022742565140604
            ],
            [
              105.83515492731304,
              21.022672191092074
            ],
            [
              105.8351521948596,
              21.022645171925408
            ],
            [
              105.8351488537474,
              21.02262441960403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.11982",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022796540346373,
          "to_latitude": 21.02262441960403,
          "to_longitude": 105.8351488537474,
          "from_longitude": 105.83516546678628
        }
      },
      {
        "id": 11939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83513342146726,
            21.02279502944376,
            105.83539827933608,
            21.022840446174254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83513342146726,
              21.02279502944376
            ],
            [
              105.83514593455695,
              21.022799970054734
            ],
            [
              105.83514914018997,
              21.022801234182232
            ],
            [
              105.83516079907571,
              21.022805835761446
            ],
            [
              105.83527685411447,
              21.02281007009755
            ],
            [
              105.83536120785642,
              21.022819979659243
            ],
            [
              105.83539827933608,
              21.022840446174254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11983",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02279502944376,
          "to_latitude": 21.022840446174254,
          "to_longitude": 105.83539827933608,
          "from_longitude": 105.83513342146726
        }
      },
      {
        "id": 11940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351450343086,
            21.022801234182232,
            105.83514928872121,
            21.02296710416894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514928872121,
              21.02296710416894
            ],
            [
              105.83514824903258,
              21.0229467632565
            ],
            [
              105.83514669684895,
              21.02288035232115
            ],
            [
              105.83514665250539,
              21.022863928318497
            ],
            [
              105.8351450343086,
              21.022826942929655
            ],
            [
              105.83514914018997,
              21.022801234182232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.11984",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02296710416894,
          "to_latitude": 21.022801234182232,
          "to_longitude": 105.83514914018997,
          "from_longitude": 105.83514928872121
        }
      },
      {
        "id": 11941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83514212384262,
            21.022931935446366,
            105.83540544573823,
            21.02304827322785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514212384262,
              21.02304827322785
            ],
            [
              105.83515002546001,
              21.023047388107816
            ],
            [
              105.83521623141783,
              21.023021190158378
            ],
            [
              105.83527177755816,
              21.02301104243306
            ],
            [
              105.83540544573823,
              21.022986619405454
            ],
            [
              105.8353992113908,
              21.022931935446366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.11985",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02304827322785,
          "to_latitude": 21.022931935446366,
          "to_longitude": 105.8353992113908,
          "from_longitude": 105.83514212384262
        }
      },
      {
        "id": 11942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83511529901,
            21.023052115337226,
            105.83516149327774,
            21.02319793644344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351482041315,
              21.02319793644344
            ],
            [
              105.83511529901,
              21.023122039353847
            ],
            [
              105.83516149327774,
              21.023102309217457
            ],
            [
              105.83515338602783,
              21.023052115337226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.11986",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02319793644344,
          "to_latitude": 21.023052115337226,
          "to_longitude": 105.83515338602783,
          "from_longitude": 105.8351482041315
        }
      },
      {
        "id": 11943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83514215819535,
            21.02299947218248,
            105.83539148970286,
            21.023053231757384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514215819535,
              21.023053231757384
            ],
            [
              105.83515012408884,
              21.023053005675035
            ],
            [
              105.83515338602783,
              21.023052115337226
            ],
            [
              105.83517687087111,
              21.023045711773168
            ],
            [
              105.835238619373,
              21.0230276990392
            ],
            [
              105.83524253862885,
              21.02302703218088
            ],
            [
              105.83539148970286,
              21.02299947218248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.11987",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023053231757384,
          "to_latitude": 21.02299947218248,
          "to_longitude": 105.83539148970286,
          "from_longitude": 105.83514215819535
        }
      },
      {
        "id": 11944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670661211079,
            21.02393605490067,
            105.8367508730613,
            21.02414138565782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673286288555,
              21.02414138565782
            ],
            [
              105.8367400778973,
              21.02413519469388
            ],
            [
              105.83673680717303,
              21.024075734432163
            ],
            [
              105.83670661211079,
              21.023946603723907
            ],
            [
              105.8367508730613,
              21.02393605490067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11988",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02414138565782,
          "to_latitude": 21.02393605490067,
          "to_longitude": 105.8367508730613,
          "from_longitude": 105.83673286288555
        }
      },
      {
        "id": 11945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659823401268,
            21.024084715379995,
            105.83673286288555,
            21.024149697706985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673219259103,
              21.024149697706985
            ],
            [
              105.83673275506285,
              21.02414309508683
            ],
            [
              105.83673286288555,
              21.02414138565782
            ],
            [
              105.8366865951327,
              21.024142103732274
            ],
            [
              105.8366614562754,
              21.024140704568456
            ],
            [
              105.83665598467788,
              21.024084715379995
            ],
            [
              105.83659823401268,
              21.024091358160604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11989",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024149697706985,
          "to_latitude": 21.024091358160604,
          "to_longitude": 105.83659823401268,
          "from_longitude": 105.83673219259103
        }
      },
      {
        "id": 11946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673286288555,
            21.02414138565782,
            105.8368836286382,
            21.024152355039245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368836286382,
              21.024152355039245
            ],
            [
              105.83685269031052,
              21.024150850764347
            ],
            [
              105.83676412777726,
              21.024146418144635
            ],
            [
              105.83675309467168,
              21.024145895267868
            ],
            [
              105.83673286288555,
              21.02414138565782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.5_Linh Quang",
          "maTaiSan": "04.O14.DODV.11990",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024152355039245,
          "to_latitude": 21.02414138565782,
          "to_longitude": 105.83673286288555,
          "from_longitude": 105.8368836286382
        }
      },
      {
        "id": 11947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83704186289421,
            21.02405461085903,
            105.83710277756413,
            21.024055729894258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710277756413,
              21.02405461085903
            ],
            [
              105.83709973221373,
              21.024054666493292
            ],
            [
              105.83704186289421,
              21.024055729894258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.6_Linh Quang",
          "maTaiSan": "04.O14.DODV.11991",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02405461085903,
          "to_latitude": 21.024055729894258,
          "to_longitude": 105.83704186289421,
          "from_longitude": 105.83710277756413
        }
      },
      {
        "id": 11948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83685668543713,
            21.023791845897986,
            105.83701226701443,
            21.024156863541364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83685668543713,
              21.023791845897986
            ],
            [
              105.83689104883233,
              21.02409089392213
            ],
            [
              105.83693851034363,
              21.024103608074224
            ],
            [
              105.83694060751459,
              21.024156863541364
            ],
            [
              105.83701226701443,
              21.024155110888337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.6.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.11992",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023791845897986,
          "to_latitude": 21.024155110888337,
          "to_longitude": 105.83701226701443,
          "from_longitude": 105.83685668543713
        }
      },
      {
        "id": 11949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710017993937,
            21.023880339039554,
            105.83736685721954,
            21.024139781338963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83736685721954,
              21.023880451084416
            ],
            [
              105.83736393118502,
              21.023880339039554
            ],
            [
              105.83727534185613,
              21.023897953590435
            ],
            [
              105.83712019963015,
              21.02393752698232
            ],
            [
              105.83710017993937,
              21.023958940686224
            ],
            [
              105.83710277756413,
              21.02405461085903
            ],
            [
              105.83710385049689,
              21.024139781338963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.6_Linh Quang",
          "maTaiSan": "04.O14.DODV.11993",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023880451084416,
          "to_latitude": 21.024139781338963,
          "to_longitude": 105.83710385049689,
          "from_longitude": 105.83736685721954
        }
      },
      {
        "id": 11950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719011993638,
            21.024139615665057,
            105.83732906026474,
            21.02415266142859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719011993638,
              21.024151846467195
            ],
            [
              105.83721661228203,
              21.024152033086803
            ],
            [
              105.83730602418018,
              21.02415266142859
            ],
            [
              105.8373288582916,
              21.024152370171418
            ],
            [
              105.83732906026474,
              21.024139615665057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.11994",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024151846467195,
          "to_latitude": 21.024139615665057,
          "to_longitude": 105.83732906026474,
          "from_longitude": 105.83719011993638
        }
      },
      {
        "id": 11951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83654842684777,
            21.024913946281778,
            105.83678162838727,
            21.025012362227663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83655822587944,
              21.025012362227663
            ],
            [
              105.83655034920255,
              21.024994286830022
            ],
            [
              105.83654842684777,
              21.024989753072898
            ],
            [
              105.83660206747771,
              21.024971428357606
            ],
            [
              105.8367815342806,
              21.024913976551165
            ],
            [
              105.83678162838727,
              21.024913946281778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.4_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11995",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025012362227663,
          "to_latitude": 21.024913946281778,
          "to_longitude": 105.83678162838727,
          "from_longitude": 105.83655822587944
        }
      },
      {
        "id": 11952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83674535476842,
            21.024832137373355,
            105.83678162838727,
            21.024913946281778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674535476842,
              21.024832137373355
            ],
            [
              105.83678162838727,
              21.024913946281778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.4_Lương Sử C",
          "maTaiSan": "04.O14.DODV.11996",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024832137373355,
          "to_latitude": 21.024913946281778,
          "to_longitude": 105.83678162838727,
          "from_longitude": 105.83674535476842
        }
      },
      {
        "id": 11953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83658952784971,
            21.025412926855953,
            105.83664656975604,
            21.02551155911979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83664656975604,
              21.02551155911979
            ],
            [
              105.83658952784971,
              21.025412926855953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.3_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11997",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02551155911979,
          "to_latitude": 21.025412926855953,
          "to_longitude": 105.83658952784971,
          "from_longitude": 105.83664656975604
        }
      },
      {
        "id": 11954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662470025858,
            21.025418789521034,
            105.83669140802165,
            21.025529187373206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83665932858578,
              21.025418789521034
            ],
            [
              105.83669140802165,
              21.025488365045337
            ],
            [
              105.83664656975604,
              21.02551155911979
            ],
            [
              105.83662470025858,
              21.025522872094754
            ],
            [
              105.8366290675174,
              21.025528866076602
            ],
            [
              105.83662930211436,
              21.025529187373206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.3_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11998",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025418789521034,
          "to_latitude": 21.025529187373206,
          "to_longitude": 105.83662930211436,
          "from_longitude": 105.83665932858578
        }
      },
      {
        "id": 11955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83660140621468,
            21.025522872094754,
            105.83662470025858,
            21.02558844312138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662470025858,
              21.025522872094754
            ],
            [
              105.83661074938796,
              21.02553115554027
            ],
            [
              105.83660140621468,
              21.025561971607807
            ],
            [
              105.83661630909573,
              21.02558844312138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.3_Lương Sử A",
          "maTaiSan": "04.O14.DODV.11999",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025522872094754,
          "to_latitude": 21.02558844312138,
          "to_longitude": 105.83661630909573,
          "from_longitude": 105.83662470025858
        }
      },
      {
        "id": 11956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661665122548,
            21.025618942933413,
            105.83672956842501,
            21.025834258998017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661819166183,
              21.025618942933413
            ],
            [
              105.83661665122548,
              21.025631133186003
            ],
            [
              105.8366323671378,
              21.025660386238314
            ],
            [
              105.83668233280866,
              21.025743871017436
            ],
            [
              105.83672956842501,
              21.025834258998017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_24.4_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12000",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025618942933413,
          "to_latitude": 21.025834258998017,
          "to_longitude": 105.83672956842501,
          "from_longitude": 105.83661819166183
        }
      },
      {
        "id": 11957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890899609791,
            21.0259836306355,
            105.83894290103309,
            21.026080737177814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83890899609791,
              21.026080737177814
            ],
            [
              105.83890991315116,
              21.02608061252535
            ],
            [
              105.83892369677567,
              21.026078729955334
            ],
            [
              105.83892194848569,
              21.02606233966752
            ],
            [
              105.83892086516545,
              21.02605217833629
            ],
            [
              105.83891659285469,
              21.02601262922286
            ],
            [
              105.83894290103309,
              21.0259836306355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.5_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12001",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026080737177814,
          "to_latitude": 21.0259836306355,
          "to_longitude": 105.83894290103309,
          "from_longitude": 105.83890899609791
        }
      },
      {
        "id": 11958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872594643985,
            21.025635606965945,
            105.83893595801717,
            21.02584829708407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83872594643985,
              21.025635606965945
            ],
            [
              105.83876161781984,
              21.0256631924928
            ],
            [
              105.83883373842558,
              21.025667735854054
            ],
            [
              105.8388785204282,
              21.025710530946963
            ],
            [
              105.83889876912325,
              21.025740362963795
            ],
            [
              105.83892134716379,
              21.02580859032632
            ],
            [
              105.83892244427173,
              21.025811073292996
            ],
            [
              105.83893595801717,
              21.02584829708407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.7_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12002",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025635606965945,
          "to_latitude": 21.02584829708407,
          "to_longitude": 105.83893595801717,
          "from_longitude": 105.83872594643985
        }
      },
      {
        "id": 11959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83881495716469,
            21.02558346567387,
            105.83893442684112,
            21.025768053669832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893442684112,
              21.02576188699175
            ],
            [
              105.83892591336911,
              21.02576502343066
            ],
            [
              105.83891768886465,
              21.025768053669832
            ],
            [
              105.83888999738548,
              21.02571453702954
            ],
            [
              105.8388695442164,
              21.025692173866172
            ],
            [
              105.83885008732484,
              21.025678170532306
            ],
            [
              105.8388352164239,
              21.02564888914244
            ],
            [
              105.83881495716469,
              21.02558346567387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.9_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12003",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02576188699175,
          "to_latitude": 21.02558346567387,
          "to_longitude": 105.83881495716469,
          "from_longitude": 105.83893442684112
        }
      },
      {
        "id": 11960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83889003115797,
            21.02562051678872,
            105.83894607919643,
            21.025777637714143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83889003115797,
              21.02562051678872
            ],
            [
              105.83893587896061,
              21.025737730360692
            ],
            [
              105.83894607919643,
              21.025777637714143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.8_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12004",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02562051678872,
          "to_latitude": 21.025777637714143,
          "to_longitude": 105.83894607919643,
          "from_longitude": 105.83889003115797
        }
      },
      {
        "id": 11961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877941679977,
            21.025299718286934,
            105.83885924988577,
            21.02553263510943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885924988577,
              21.02553263510943
            ],
            [
              105.83883583051012,
              21.02546171757065
            ],
            [
              105.83880569126502,
              21.025370451948344
            ],
            [
              105.83880243944652,
              21.025361807886522
            ],
            [
              105.83878596355265,
              21.025318007873164
            ],
            [
              105.83877941679977,
              21.025299718286934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.11_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12005",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02553263510943,
          "to_latitude": 21.025299718286934,
          "to_longitude": 105.83877941679977,
          "from_longitude": 105.83885924988577
        }
      },
      {
        "id": 11962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395172968035,
            21.025889255343152,
            105.83959580691939,
            21.026082189917496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83959580691939,
              21.026082189917496
            ],
            [
              105.8395172968035,
              21.025889255343152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.3_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12006",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026082189917496,
          "to_latitude": 21.025889255343152,
          "to_longitude": 105.8395172968035,
          "from_longitude": 105.83959580691939
        }
      },
      {
        "id": 11963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83922123123175,
            21.025647993481638,
            105.83933435862703,
            21.0256679213889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83933435862703,
              21.025647993481638
            ],
            [
              105.83932434161946,
              21.025649936977715
            ],
            [
              105.83927014560297,
              21.025660451508877
            ],
            [
              105.83923911439743,
              21.025666471981864
            ],
            [
              105.83922123123175,
              21.0256679213889
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.5_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12007",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025647993481638,
          "to_latitude": 21.0256679213889,
          "to_longitude": 105.83922123123175,
          "from_longitude": 105.83933435862703
        }
      },
      {
        "id": 11964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897805543971,
            21.025260271106987,
            105.83916942803499,
            21.025643780971564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83916942803499,
              21.025643780971564
            ],
            [
              105.83911089958306,
              21.025638730818393
            ],
            [
              105.83906513038244,
              21.025520453814316
            ],
            [
              105.83904039363131,
              21.025456529234372
            ],
            [
              105.83898834657202,
              21.025304908190186
            ],
            [
              105.83897805543971,
              21.025260271106987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12008",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025643780971564,
          "to_latitude": 21.025260271106987,
          "to_longitude": 105.83897805543971,
          "from_longitude": 105.83916942803499
        }
      },
      {
        "id": 11965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916942803499,
            21.025615130095673,
            105.83927370524957,
            21.025643780971564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83916942803499,
              21.025643780971564
            ],
            [
              105.83927370524957,
              21.025615130095673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12009",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025643780971564,
          "to_latitude": 21.025615130095673,
          "to_longitude": 105.83927370524957,
          "from_longitude": 105.83916942803499
        }
      },
      {
        "id": 11966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958097288047,
            21.026427113549932,
            105.83967179633335,
            21.02673378699475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83958097288047,
              21.026427113549932
            ],
            [
              105.83958159206023,
              21.02642911203432
            ],
            [
              105.8396107442877,
              21.026522999900557
            ],
            [
              105.8396531232055,
              21.02667099503349
            ],
            [
              105.83967179633335,
              21.02673378699475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.4_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12010",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026427113549932,
          "to_latitude": 21.02673378699475,
          "to_longitude": 105.83967179633335,
          "from_longitude": 105.83958097288047
        }
      },
      {
        "id": 11967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83957993153493,
            21.02667099503349,
            105.8396531232055,
            21.02675092774899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83960130603653,
              21.02675092774899
            ],
            [
              105.83957993153493,
              21.026689838142463
            ],
            [
              105.8396531232055,
              21.02667099503349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.4_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12011",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02675092774899,
          "to_latitude": 21.02667099503349,
          "to_longitude": 105.8396531232055,
          "from_longitude": 105.83960130603653
        }
      },
      {
        "id": 11968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83937941160514,
            21.026522999900557,
            105.8396107442877,
            21.026586714439425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83937941160514,
              21.026586714439425
            ],
            [
              105.8396107442877,
              21.026522999900557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.4_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.12012",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026586714439425,
          "to_latitude": 21.026522999900557,
          "to_longitude": 105.8396107442877,
          "from_longitude": 105.83937941160514
        }
      },
      {
        "id": 11969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83963438800646,
            21.02650535732269,
            105.83979323984269,
            21.026612849162916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83979323984269,
              21.026612849162916
            ],
            [
              105.83977215288289,
              21.026540933038945
            ],
            [
              105.83974761912401,
              21.026514706283947
            ],
            [
              105.83971829292743,
              21.02650535732269
            ],
            [
              105.83963438800646,
              21.02652154458859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12013",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026612849162916,
          "to_latitude": 21.02652154458859,
          "to_longitude": 105.83963438800646,
          "from_longitude": 105.83979323984269
        }
      },
      {
        "id": 11970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83970966687075,
            21.026612849162916,
            105.83982954248971,
            21.02669440568603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83982954248971,
              21.0266422811327
            ],
            [
              105.8398080660883,
              21.026648532719555
            ],
            [
              105.83980513674551,
              21.026649385376345
            ],
            [
              105.83979323984269,
              21.026612849162916
            ],
            [
              105.83972444685817,
              21.026631410482626
            ],
            [
              105.83970966687075,
              21.02666271690041
            ],
            [
              105.83971860440819,
              21.02669440568603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12014",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0266422811327,
          "to_latitude": 21.02669440568603,
          "to_longitude": 105.83971860440819,
          "from_longitude": 105.83982954248971
        }
      },
      {
        "id": 11971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010659940664,
            21.0274568803808,
            105.8401676240668,
            21.027472425557836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84010659940664,
              21.027472425557836
            ],
            [
              105.84011250254842,
              21.027470923238546
            ],
            [
              105.84011265817229,
              21.02747088363114
            ],
            [
              105.8401676240668,
              21.0274568803808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12015",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027472425557836,
          "to_latitude": 21.0274568803808,
          "to_longitude": 105.8401676240668,
          "from_longitude": 105.84010659940664
        }
      },
      {
        "id": 11972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010405314866,
            21.02746524939004,
            105.84018807083677,
            21.027700456659435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84010405314866,
              21.02746524939004
            ],
            [
              105.84010425808619,
              21.027465769536917
            ],
            [
              105.84010659940664,
              21.027472425557836
            ],
            [
              105.84014025030835,
              21.027568157139203
            ],
            [
              105.84010463746583,
              21.027598004841057
            ],
            [
              105.84012458692648,
              21.027663955322872
            ],
            [
              105.840147375719,
              21.027700456659435
            ],
            [
              105.84018807083677,
              21.02769565819403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12016",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02746524939004,
          "to_latitude": 21.02769565819403,
          "to_longitude": 105.84018807083677,
          "from_longitude": 105.84010405314866
        }
      },
      {
        "id": 11973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83983318603516,
            21.027537667335622,
            105.8398666170475,
            21.02767211269687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983318603516,
              21.027537667335622
            ],
            [
              105.83983505410016,
              21.027542274479657
            ],
            [
              105.8398666170475,
              21.02762130952556
            ],
            [
              105.83984596436008,
              21.027653961643683
            ],
            [
              105.83984831128959,
              21.027661472424953
            ],
            [
              105.83985233460119,
              21.02767211269687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.4.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12017",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": 16.213,
          "from_latitude": 21.027537667335622,
          "to_latitude": 21.02767211269687,
          "to_longitude": 105.83985233460119,
          "from_longitude": 105.83983318603516
        }
      },
      {
        "id": 11974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83985693161533,
            21.027465454664846,
            105.84010484915554,
            21.02754028501849
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83985693161533,
              21.02754028501849
            ],
            [
              105.8400478230003,
              21.02748986721271
            ],
            [
              105.84010484915554,
              21.027476482447454
            ],
            [
              105.84010198192759,
              21.027466449166415
            ],
            [
              105.8401016271439,
              21.027465454664846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.4_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12018",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02754028501849,
          "to_latitude": 21.027465454664846,
          "to_longitude": 105.8401016271439,
          "from_longitude": 105.83985693161533
        }
      },
      {
        "id": 11975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83977669132474,
            21.027552288842802,
            105.83988133497978,
            21.027845221541416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83977847270134,
              21.027552288842802
            ],
            [
              105.83977984565341,
              21.02755626708004
            ],
            [
              105.83977669132474,
              21.027572943498765
            ],
            [
              105.83982551144601,
              21.027717165600826
            ],
            [
              105.83983715342974,
              21.027728101224056
            ],
            [
              105.83988133497978,
              21.027845221541416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12019",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027552288842802,
          "to_latitude": 21.027845221541416,
          "to_longitude": 105.83988133497978,
          "from_longitude": 105.83977847270134
        }
      },
      {
        "id": 11976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954222395668,
            21.027617268944415,
            105.83960640964403,
            21.027778515373324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954222395668,
              21.027617268944415
            ],
            [
              105.8395429275686,
              21.02761922636668
            ],
            [
              105.83954418814749,
              21.027622722839958
            ],
            [
              105.83954789079012,
              21.027633003102537
            ],
            [
              105.83957992282687,
              21.027716238521936
            ],
            [
              105.83958830473729,
              21.027735641363066
            ],
            [
              105.83960640964403,
              21.027778515373324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.11_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12020",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": 19.063,
          "from_latitude": 21.027617268944415,
          "to_latitude": 21.027778515373324,
          "to_longitude": 105.83960640964403,
          "from_longitude": 105.83954222395668
        }
      },
      {
        "id": 11977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902479675587,
            21.027336937914228,
            105.83904260665541,
            21.02739781485607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904260665541,
              21.02739781485607
            ],
            [
              105.83902479675587,
              21.027336937914228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12021",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02739781485607,
          "to_latitude": 21.027336937914228,
          "to_longitude": 105.83902479675587,
          "from_longitude": 105.83904260665541
        }
      },
      {
        "id": 11978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895130072162,
            21.02708374321501,
            105.83913192827947,
            21.027421622676712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908679718454,
              21.02708374321501
            ],
            [
              105.83908880160452,
              21.027090201301863
            ],
            [
              105.83913192827947,
              21.02722902426302
            ],
            [
              105.8390937223092,
              21.027245226924666
            ],
            [
              105.83907599311729,
              21.02727318695168
            ],
            [
              105.83910684682596,
              21.02738010271568
            ],
            [
              105.83904750768465,
              21.02739653700111
            ],
            [
              105.83904260665541,
              21.02739781485607
            ],
            [
              105.83895130072162,
              21.027421622676712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12022",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02708374321501,
          "to_latitude": 21.027421622676712,
          "to_longitude": 105.83895130072162,
          "from_longitude": 105.83908679718454
        }
      },
      {
        "id": 11979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84111169689746,
            21.02700112381117,
            105.84144426708029,
            21.027071982118496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84144426708029,
              21.027024145160173
            ],
            [
              105.84144408879538,
              21.02700112381117
            ],
            [
              105.84111201902513,
              21.027020863563823
            ],
            [
              105.84111169689746,
              21.027071982118496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.5_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12023",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027024145160173,
          "to_latitude": 21.027071982118496,
          "to_longitude": 105.84111169689746,
          "from_longitude": 105.84144426708029
        }
      },
      {
        "id": 11980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84105604139485,
            21.026551014711043,
            105.84145211176968,
            21.02658463300929
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84145211176968,
              21.026555149742787
            ],
            [
              105.8413289598302,
              21.026558262794126
            ],
            [
              105.84132507016818,
              21.026551014711043
            ],
            [
              105.84128291722045,
              21.026551223249793
            ],
            [
              105.84126332532354,
              21.02656550618055
            ],
            [
              105.84113507292722,
              21.026576011907924
            ],
            [
              105.84112453943217,
              21.026569751387612
            ],
            [
              105.84105604139485,
              21.026573804224984
            ],
            [
              105.84105712668523,
              21.02658463300929
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.9_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12024",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026555149742787,
          "to_latitude": 21.02658463300929,
          "to_longitude": 105.84105712668523,
          "from_longitude": 105.84145211176968
        }
      },
      {
        "id": 11981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84144154919001,
            21.02632737619838,
            105.84145299489016,
            21.026555149742787
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84145211176968,
              21.026555149742787
            ],
            [
              105.84144154919001,
              21.026471040658667
            ],
            [
              105.8414421774751,
              21.026451176595263
            ],
            [
              105.8414437099979,
              21.02640268223389
            ],
            [
              105.84145299489016,
              21.02632737619838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.9_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12025",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026555149742787,
          "to_latitude": 21.02632737619838,
          "to_longitude": 105.84145299489016,
          "from_longitude": 105.84145211176968
        }
      },
      {
        "id": 11982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84142019678863,
            21.027705220628917,
            105.84146172604311,
            21.028002613277085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146172604311,
              21.028002613277085
            ],
            [
              105.84145597384125,
              21.027705220628917
            ],
            [
              105.84142019678863,
              21.027709713256435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12026",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028002613277085,
          "to_latitude": 21.027709713256435,
          "to_longitude": 105.84142019678863,
          "from_longitude": 105.84146172604311
        }
      },
      {
        "id": 11983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84116676160454,
            21.027346506990536,
            105.84123423875484,
            21.027623492222926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84123423875484,
              21.027623492222926
            ],
            [
              105.84120571802092,
              21.027567100634684
            ],
            [
              105.84118960715662,
              21.02753709128986
            ],
            [
              105.84117457956174,
              21.02740817013866
            ],
            [
              105.84116676160454,
              21.027346506990536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.2_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12028",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027623492222926,
          "to_latitude": 21.027346506990536,
          "to_longitude": 105.84116676160454,
          "from_longitude": 105.84123423875484
        }
      },
      {
        "id": 11984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84123423875484,
            21.027568755026152,
            105.84147596609704,
            21.027623492222926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84123423875484,
              21.027623492222926
            ],
            [
              105.8414124600909,
              21.027601020751465
            ],
            [
              105.84147104843618,
              21.02756912348828
            ],
            [
              105.84147596609704,
              21.027568755026152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.2_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12029",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027623492222926,
          "to_latitude": 21.027568755026152,
          "to_longitude": 105.84147596609704,
          "from_longitude": 105.84123423875484
        }
      },
      {
        "id": 11985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84097146443914,
            21.027724207825887,
            105.84121150899145,
            21.028077635281033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84097146443914,
              21.027724207825887
            ],
            [
              105.84097755163465,
              21.02775191116023
            ],
            [
              105.84097980100944,
              21.027760175357948
            ],
            [
              105.84108376879159,
              21.027749906038206
            ],
            [
              105.84110924977412,
              21.02781875480229
            ],
            [
              105.84112601358598,
              21.02785036263383
            ],
            [
              105.84116691726751,
              21.027922653786188
            ],
            [
              105.84117932248955,
              21.0279792974791
            ],
            [
              105.84121150899145,
              21.028065671244345
            ],
            [
              105.84113796631455,
              21.028077635281033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12031",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": 60.278,
          "from_latitude": 21.027724207825887,
          "to_latitude": 21.028077635281033,
          "to_longitude": 105.84113796631455,
          "from_longitude": 105.84097146443914
        }
      },
      {
        "id": 11986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84076717551741,
            21.028372614132437,
            105.84097853564718,
            21.028458742492028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84076717551741,
              21.028446855532597
            ],
            [
              105.84076808478137,
              21.028446883552817
            ],
            [
              105.8407694678864,
              21.028446834262752
            ],
            [
              105.84077181273885,
              21.028458742492028
            ],
            [
              105.84096206838917,
              21.028421239247535
            ],
            [
              105.84097853564718,
              21.028372614132437
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.5_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12034",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028446855532597,
          "to_latitude": 21.028372614132437,
          "to_longitude": 105.84097853564718,
          "from_longitude": 105.84076717551741
        }
      },
      {
        "id": 11987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84033202254032,
            21.027862273342908,
            105.84044545987467,
            21.028127108341042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84033202254032,
              21.027862273342908
            ],
            [
              105.84033429344981,
              21.027867692293288
            ],
            [
              105.84033752841248,
              21.02787688504416
            ],
            [
              105.84033941622567,
              21.02788554807009
            ],
            [
              105.84038236839298,
              21.02798578677049
            ],
            [
              105.84044545987467,
              21.028127108341042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12035",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": 31.625,
          "from_latitude": 21.027862273342908,
          "to_latitude": 21.028127108341042,
          "to_longitude": 105.84044545987467,
          "from_longitude": 105.84033202254032
        }
      },
      {
        "id": 11988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84029332989182,
            21.02789737499327,
            105.84046914420355,
            21.02826547964131
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029332989182,
              21.02789737499327
            ],
            [
              105.84034667901778,
              21.02803236438486
            ],
            [
              105.84034272213358,
              21.028052935859446
            ],
            [
              105.84037519521662,
              21.02812470800347
            ],
            [
              105.8404297285644,
              21.02822672118614
            ],
            [
              105.84046914420355,
              21.02826547964131
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12037",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02789737499327,
          "to_latitude": 21.02826547964131,
          "to_longitude": 105.84046914420355,
          "from_longitude": 105.84029332989182
        }
      },
      {
        "id": 11989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396625015878,
            21.028028013708727,
            105.83987504947333,
            21.028089038637294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8396625015878,
              21.028064498193288
            ],
            [
              105.83966527073734,
              21.028073340615514
            ],
            [
              105.83967120158613,
              21.028089038637294
            ],
            [
              105.83977446662912,
              21.028057430857213
            ],
            [
              105.83987504947333,
              21.028028013708727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.7_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12039",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": 25.106,
          "from_latitude": 21.028064498193288,
          "to_latitude": 21.028028013708727,
          "to_longitude": 105.83987504947333,
          "from_longitude": 105.8396625015878
        }
      },
      {
        "id": 11990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83957233944024,
            21.02808029376561,
            105.83961635513276,
            21.028115143574066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83957233944024,
              21.028115143574066
            ],
            [
              105.83961635513276,
              21.028102022813872
            ],
            [
              105.83961124531764,
              21.028089043447352
            ],
            [
              105.8396078032795,
              21.02808029376561
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12040",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028115143574066,
          "to_latitude": 21.02808029376561,
          "to_longitude": 105.8396078032795,
          "from_longitude": 105.83957233944024
        }
      },
      {
        "id": 11991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83961635513276,
            21.028087673181485,
            105.83980466887513,
            21.0283821034443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83961635513276,
              21.028102022813872
            ],
            [
              105.8396644919427,
              21.028087673181485
            ],
            [
              105.83969440549497,
              21.02818859425121
            ],
            [
              105.83966237419544,
              21.02819855418954
            ],
            [
              105.83969808567336,
              21.028303050484553
            ],
            [
              105.83972608334791,
              21.02831611824189
            ],
            [
              105.83978008257668,
              21.02830534438579
            ],
            [
              105.83979294496417,
              21.02833388772249
            ],
            [
              105.83980466887513,
              21.0283821034443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12041",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028102022813872,
          "to_latitude": 21.0283821034443,
          "to_longitude": 105.83980466887513,
          "from_longitude": 105.83961635513276
        }
      },
      {
        "id": 11992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947340674942,
            21.028122196627077,
            105.83977155078666,
            21.028400185756222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947340674942,
              21.028122196627077
            ],
            [
              105.8394764697681,
              21.028130778376994
            ],
            [
              105.83949123127118,
              21.02815574463506
            ],
            [
              105.8395789981104,
              21.028400185756222
            ],
            [
              105.83963382415273,
              21.02838450246239
            ],
            [
              105.83966397273616,
              21.028393074206598
            ],
            [
              105.8396911661644,
              21.02838553169607
            ],
            [
              105.83970581984028,
              21.0283689141072
            ],
            [
              105.83973540289375,
              21.02836239849516
            ],
            [
              105.83974434092042,
              21.02838144576746
            ],
            [
              105.83977155078666,
              21.028377010248008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12042",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028122196627077,
          "to_latitude": 21.028377010248008,
          "to_longitude": 105.83977155078666,
          "from_longitude": 105.83947340674942
        }
      },
      {
        "id": 11993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84094080857061,
            21.028711413241798,
            105.84096193096333,
            21.028717359233582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84094080857061,
              21.028717359233582
            ],
            [
              105.84096193096333,
              21.028711413241798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12044",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": 2.292,
          "from_latitude": 21.028717359233582,
          "to_latitude": 21.028711413241798,
          "to_longitude": 105.84096193096333,
          "from_longitude": 105.84094080857061
        }
      },
      {
        "id": 11994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8408677183782,
            21.028717359233582,
            105.84101995068332,
            21.02893160224986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84101995068332,
              21.02893160224986
            ],
            [
              105.84101792207464,
              21.028921713464555
            ],
            [
              105.84101618474897,
              21.028914523784973
            ],
            [
              105.84100920490397,
              21.028899181311807
            ],
            [
              105.84099881134223,
              21.0288725808492
            ],
            [
              105.84098774722408,
              21.028848171008935
            ],
            [
              105.84094080857061,
              21.028717359233582
            ],
            [
              105.8408677183782,
              21.02873582310362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12045",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": 33.003,
          "from_latitude": 21.02893160224986,
          "to_latitude": 21.02873582310362,
          "to_longitude": 105.8408677183782,
          "from_longitude": 105.84101995068332
        }
      },
      {
        "id": 11995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84114253883436,
            21.028675440939953,
            105.84148782378969,
            21.02870989887257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84114253883436,
              21.028675440939953
            ],
            [
              105.84120375669836,
              21.028679515119897
            ],
            [
              105.84121471348635,
              21.028695862526067
            ],
            [
              105.84123255017529,
              21.02870989887257
            ],
            [
              105.84131864986419,
              21.028705428271273
            ],
            [
              105.8413658744971,
              21.02870612583379
            ],
            [
              105.84145792105157,
              21.02870142522183
            ],
            [
              105.84148152313408,
              21.02870329280391
            ],
            [
              105.84148782378969,
              21.028703191172745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.1.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12046",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028675440939953,
          "to_latitude": 21.028703191172745,
          "to_longitude": 105.84148782378969,
          "from_longitude": 105.84114253883436
        }
      },
      {
        "id": 11996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8411972948409,
            21.028727733985694,
            105.84147404115089,
            21.02885943709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8411972948409,
              21.02885943709
            ],
            [
              105.84134028234647,
              21.028806984300964
            ],
            [
              105.84139239188632,
              21.028779528641262
            ],
            [
              105.84141695041662,
              21.028763949378433
            ],
            [
              105.84147404115089,
              21.028727733985694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12047",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": 32.412,
          "from_latitude": 21.02885943709,
          "to_latitude": 21.028727733985694,
          "to_longitude": 105.84147404115089,
          "from_longitude": 105.8411972948409
        }
      },
      {
        "id": 11997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84118522490608,
            21.02855533487909,
            105.84125831510747,
            21.02861408669861
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84125703768383,
              21.02855533487909
            ],
            [
              105.84125831510747,
              21.02860970543835
            ],
            [
              105.84118522490608,
              21.02861408669861
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.2_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12049",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": 13.634,
          "from_latitude": 21.02855533487909,
          "to_latitude": 21.02861408669861,
          "to_longitude": 105.84118522490608,
          "from_longitude": 105.84125703768383
        }
      },
      {
        "id": 11998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84146765927085,
            21.028413988031676,
            105.84147321396152,
            21.028659728169107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146765927085,
              21.028413988031676
            ],
            [
              105.84146948410915,
              21.028521416362846
            ],
            [
              105.84146987070314,
              21.028546068799077
            ],
            [
              105.84147012509739,
              21.02856225237619
            ],
            [
              105.84147321396152,
              21.028659728169107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.3_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12050",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028413988031676,
          "to_latitude": 21.028659728169107,
          "to_longitude": 105.84147321396152,
          "from_longitude": 105.84146765927085
        }
      },
      {
        "id": 11999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84114046235848,
            21.02839738299165,
            105.8414830708233,
            21.028425739143653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8414830708233,
              21.02839771428537
            ],
            [
              105.84147919972989,
              21.02839738299165
            ],
            [
              105.84146864770366,
              21.028397786562216
            ],
            [
              105.84146765927085,
              21.028413988031676
            ],
            [
              105.84120736619248,
              21.02841706969482
            ],
            [
              105.84117699200287,
              21.028425739143653
            ],
            [
              105.84114046235848,
              21.028424725785065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.3_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12051",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02839771428537,
          "to_latitude": 21.028424725785065,
          "to_longitude": 105.84114046235848,
          "from_longitude": 105.8414830708233
        }
      },
      {
        "id": 12000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8411987684822,
            21.027802168511958,
            105.84146354582529,
            21.028375539967477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146287524192,
              21.028375539967477
            ],
            [
              105.84146354582529,
              21.028305126720912
            ],
            [
              105.84146287527784,
              21.028263504653236
            ],
            [
              105.84146052833934,
              21.028233148701496
            ],
            [
              105.84145494602336,
              21.028212421064264
            ],
            [
              105.84143869127661,
              21.028180109244314
            ],
            [
              105.84138421250532,
              21.02809736276498
            ],
            [
              105.84132689185655,
              21.02800396327272
            ],
            [
              105.8411987684822,
              21.027802168511958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.4.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12052.1",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": 70.814,
          "from_latitude": 21.028375539967477,
          "to_latitude": 21.027802168511958,
          "to_longitude": 105.8411987684822,
          "from_longitude": 105.84146287524192
        }
      },
      {
        "id": 12001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84057339791121,
            21.028794369053443,
            105.84066630866356,
            21.028817487454905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84066630866356,
              21.028817487454905
            ],
            [
              105.84062317820323,
              21.028811789091908
            ],
            [
              105.84059495873919,
              21.028794369053443
            ],
            [
              105.84057339791121,
              21.028799166069795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12053",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028817487454905,
          "to_latitude": 21.028799166069795,
          "to_longitude": 105.84057339791121,
          "from_longitude": 105.84066630866356
        }
      },
      {
        "id": 12002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84066475100785,
            21.028752088591286,
            105.84073905056327,
            21.02901226851673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84073512067094,
              21.02901226851673
            ],
            [
              105.84072434605412,
              21.028979341536306
            ],
            [
              105.84066475100785,
              21.028820892174327
            ],
            [
              105.84066630866356,
              21.028817487454905
            ],
            [
              105.84067478571191,
              21.028803716568287
            ],
            [
              105.84067527719164,
              21.028778996560586
            ],
            [
              105.84073905056327,
              21.028752088591286
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12054",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02901226851673,
          "to_latitude": 21.028752088591286,
          "to_longitude": 105.84073905056327,
          "from_longitude": 105.84073512067094
        }
      },
      {
        "id": 12003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84039810492251,
            21.02878450521654,
            105.84046524663177,
            21.029042790270122
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84046524663177,
              21.029042790270122
            ],
            [
              105.84041189512003,
              21.02886148101219
            ],
            [
              105.84041431242244,
              21.028851140718988
            ],
            [
              105.8404122056295,
              21.02883021353755
            ],
            [
              105.84039810492251,
              21.02878450521654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12055",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029042790270122,
          "to_latitude": 21.02878450521654,
          "to_longitude": 105.84039810492251,
          "from_longitude": 105.84046524663177
        }
      },
      {
        "id": 12004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84047674032972,
            21.028986203796133,
            105.8406674328877,
            21.029039263279817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84047674032972,
              21.029039263279817
            ],
            [
              105.8406674328877,
              21.028986203796133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12056",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029039263279817,
          "to_latitude": 21.028986203796133,
          "to_longitude": 105.8406674328877,
          "from_longitude": 105.84047674032972
        }
      },
      {
        "id": 12005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013409685069,
            21.0283823524202,
            105.84014951748398,
            21.028484908172636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84014501377571,
              21.0283823524202
            ],
            [
              105.8401480145413,
              21.028409512843588
            ],
            [
              105.84014951748398,
              21.028423120590848
            ],
            [
              105.84013409685069,
              21.028436771307742
            ],
            [
              105.8401350852779,
              21.028484908172636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.3_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12057",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0283823524202,
          "to_latitude": 21.028484908172636,
          "to_longitude": 105.8401350852779,
          "from_longitude": 105.84014501377571
        }
      },
      {
        "id": 12006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401885053548,
            21.028452844485333,
            105.84044358625415,
            21.028467402047948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401885053548,
              21.028452844485333
            ],
            [
              105.84019404800367,
              21.028453613741103
            ],
            [
              105.8402088967225,
              21.02845566836422
            ],
            [
              105.84029354311411,
              21.028467402047948
            ],
            [
              105.84031912898662,
              21.028457971517927
            ],
            [
              105.84035468297816,
              21.02845809568455
            ],
            [
              105.84036642764083,
              21.028466394254643
            ],
            [
              105.84044358625415,
              21.028467245814216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.4_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12058",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028452844485333,
          "to_latitude": 21.028467245814216,
          "to_longitude": 105.84044358625415,
          "from_longitude": 105.8401885053548
        }
      },
      {
        "id": 12007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83986027715032,
            21.028501142476124,
            105.84019457772109,
            21.02865695574474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019457772109,
              21.028534215583488
            ],
            [
              105.84019397029414,
              21.028534145423812
            ],
            [
              105.84014500127732,
              21.028528417978666
            ],
            [
              105.84004669303766,
              21.02851064051204
            ],
            [
              105.83999563071528,
              21.028501142476124
            ],
            [
              105.8399440362755,
              21.028505699386063
            ],
            [
              105.83989904409252,
              21.028520373179347
            ],
            [
              105.83989502308077,
              21.028594582819085
            ],
            [
              105.8398646541015,
              21.028620514395904
            ],
            [
              105.83986027715032,
              21.02865695574474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.5_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12059",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028534215583488,
          "to_latitude": 21.02865695574474,
          "to_longitude": 105.83986027715032,
          "from_longitude": 105.84019457772109
        }
      },
      {
        "id": 12008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013576840763,
            21.02852841797867,
            105.8401450012773,
            21.028672605264575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401450012773,
              21.02852841797867
            ],
            [
              105.84013576840763,
              21.028672605264575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.5_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12060",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": 15.993,
          "from_latitude": 21.02852841797867,
          "to_latitude": 21.028672605264575,
          "to_longitude": 105.84013576840763,
          "from_longitude": 105.8401450012773
        }
      },
      {
        "id": 12009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84018531458801,
            21.02865264462113,
            105.8405068707653,
            21.028669999402453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018531458801,
              21.028669999402453
            ],
            [
              105.84019169871063,
              21.028669513537466
            ],
            [
              105.84020882431442,
              21.028660542114217
            ],
            [
              105.84041840786708,
              21.02865264462113
            ],
            [
              105.84043805411653,
              21.028660175232332
            ],
            [
              105.8405068707653,
              21.028658725935085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.6_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12061",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028669999402453,
          "to_latitude": 21.028658725935085,
          "to_longitude": 105.8405068707653,
          "from_longitude": 105.84018531458801
        }
      },
      {
        "id": 12010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399457506771,
            21.02876423084168,
            105.84018712900054,
            21.02878205845941
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018712900054,
              21.02876423084168
            ],
            [
              105.84018645853669,
              21.028764413895683
            ],
            [
              105.84014691634758,
              21.028768288797956
            ],
            [
              105.8400932721658,
              21.028773295947804
            ],
            [
              105.84005974456005,
              21.028775173628826
            ],
            [
              105.84002353473755,
              21.028776425416464
            ],
            [
              105.83998061938672,
              21.028777677204086
            ],
            [
              105.8399457506771,
              21.02878205845941
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.8_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12062",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 25.188,
          "from_latitude": 21.02876423084168,
          "to_latitude": 21.02878205845941,
          "to_longitude": 105.8399457506771,
          "from_longitude": 105.84018712900054
        }
      },
      {
        "id": 12011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83994239791295,
            21.028767960246327,
            105.8401509396592,
            21.028855913893747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015026911621,
              21.028767960246327
            ],
            [
              105.8401509396592,
              21.028842144239125
            ],
            [
              105.84011405928928,
              21.0288440219193
            ],
            [
              105.84008522554257,
              21.028851532640598
            ],
            [
              105.83994239791295,
              21.028855913893747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.8_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12063",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": 30.018,
          "from_latitude": 21.028767960246327,
          "to_latitude": 21.028855913893747,
          "to_longitude": 105.83994239791295,
          "from_longitude": 105.84015026911621
        }
      },
      {
        "id": 12012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8378376489752,
            21.029465187458545,
            105.83792213659459,
            21.029774858634696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379209518862,
              21.029774858634696
            ],
            [
              105.8379114969501,
              21.029747472552344
            ],
            [
              105.83785500823375,
              21.029568756585256
            ],
            [
              105.8378376489752,
              21.029514115865663
            ],
            [
              105.83791869262237,
              21.029486711965834
            ],
            [
              105.83792213659459,
              21.029465187458545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.10_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12064",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029774858634696,
          "to_latitude": 21.029465187458545,
          "to_longitude": 105.83792213659459,
          "from_longitude": 105.8379209518862
        }
      },
      {
        "id": 12013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379209518862,
            21.02970525130212,
            105.838156808731,
            21.029774858634696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838156808731,
              21.029736944066148
            ],
            [
              105.83815432855513,
              21.02971628694777
            ],
            [
              105.83814921575224,
              21.02970525130212
            ],
            [
              105.83804561542208,
              21.029735606954013
            ],
            [
              105.83797637072294,
              21.029756332302437
            ],
            [
              105.83792146273117,
              21.02977468811789
            ],
            [
              105.8379209518862,
              21.029774858634696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.10_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12065",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27.72,
          "chatLieu": "HDPE",
          "chieuDai": 28.585,
          "from_latitude": 21.029736944066148,
          "to_latitude": 21.029774858634696,
          "to_longitude": 105.8379209518862,
          "from_longitude": 105.838156808731
        }
      },
      {
        "id": 12014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752745691734,
            21.029591843488117,
            105.83764281654315,
            21.029885712941883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763882691254,
              21.029885712941883
            ],
            [
              105.83762925160772,
              21.029860364202793
            ],
            [
              105.8376001508422,
              21.029783170025503
            ],
            [
              105.83764281654315,
              21.02972942765125
            ],
            [
              105.83760943850271,
              21.029637922208373
            ],
            [
              105.83753855978328,
              21.029628881443447
            ],
            [
              105.83752745691734,
              21.029591843488117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.12.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12066",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029885712941883,
          "to_latitude": 21.029591843488117,
          "to_longitude": 105.83752745691734,
          "from_longitude": 105.83763882691254
        }
      },
      {
        "id": 12015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83689469205304,
            21.02964485292508,
            105.83691355521303,
            21.0297224632701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83691355521303,
              21.0297224632701
            ],
            [
              105.8369051733116,
              21.029700870073096
            ],
            [
              105.83689511502806,
              21.029677086258957
            ],
            [
              105.83689469205304,
              21.029665047310203
            ],
            [
              105.83689880306146,
              21.02964485292508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.1_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12070",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": 8.988,
          "from_latitude": 21.0297224632701,
          "to_latitude": 21.02964485292508,
          "to_longitude": 105.83689880306146,
          "from_longitude": 105.83691355521303
        }
      },
      {
        "id": 12016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83711810951976,
            21.02954573826492,
            105.83719681921791,
            21.029646089883318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83711810951976,
              21.02954573826492
            ],
            [
              105.83714858377648,
              21.029641723474636
            ],
            [
              105.83719681921791,
              21.029646089883318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.3_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12071",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": 16.126,
          "from_latitude": 21.02954573826492,
          "to_latitude": 21.029646089883318,
          "to_longitude": 105.83719681921791,
          "from_longitude": 105.83711810951976
        }
      },
      {
        "id": 12017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83690014416531,
            21.029526391171277,
            105.83711810951976,
            21.029638281079684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83711353574392,
              21.029526391171277
            ],
            [
              105.8371139328574,
              21.029528077339492
            ],
            [
              105.83711810951976,
              21.02954573826492
            ],
            [
              105.8370704644378,
              21.029560670690824
            ],
            [
              105.83694037730841,
              21.029609177188597
            ],
            [
              105.83690483803338,
              21.029623259716843
            ],
            [
              105.83690014416531,
              21.029638281079684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.3_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12072",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": 27.708,
          "from_latitude": 21.029526391171277,
          "to_latitude": 21.029638281079684,
          "to_longitude": 105.83690014416531,
          "from_longitude": 105.83711353574392
        }
      },
      {
        "id": 12018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837077010339,
            21.029292256043345,
            105.83720982998047,
            21.029510523785135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720982998047,
              21.02949647833934
            ],
            [
              105.83715014069145,
              21.029510523785135
            ],
            [
              105.837077010339,
              21.029292256043345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.4_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12073",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02949647833934,
          "to_latitude": 21.029292256043345,
          "to_longitude": 105.837077010339,
          "from_longitude": 105.83720982998047
        }
      },
      {
        "id": 12019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722484645163,
            21.029204760113153,
            105.83727239430932,
            21.029229597229502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722484645163,
              21.029204760113153
            ],
            [
              105.83723689310911,
              21.029229597229502
            ],
            [
              105.83727239430932,
              21.029217995755083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.5_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12074",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029204760113153,
          "to_latitude": 21.029217995755083,
          "to_longitude": 105.83727239430932,
          "from_longitude": 105.83722484645163
        }
      },
      {
        "id": 12020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83718687804395,
            21.029107760115338,
            105.83739510599024,
            21.029504563370015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721270734269,
              21.029504563370015
            ],
            [
              105.83721250835933,
              21.02950338564413
            ],
            [
              105.837218167728,
              21.029491978076344
            ],
            [
              105.83719549303922,
              21.02940573169302
            ],
            [
              105.83718687804395,
              21.02929876028235
            ],
            [
              105.8371879189551,
              21.029229692941684
            ],
            [
              105.83719055902478,
              21.029212002068107
            ],
            [
              105.83722484645163,
              21.029204760113153
            ],
            [
              105.83722500596147,
              21.02920472681173
            ],
            [
              105.83726366719229,
              21.029197048734428
            ],
            [
              105.83726322732653,
              21.029166525585342
            ],
            [
              105.83731701081237,
              21.029132023190858
            ],
            [
              105.83739510599024,
              21.029107760115338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.5_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12075",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029504563370015,
          "to_latitude": 21.029107760115338,
          "to_longitude": 105.83739510599024,
          "from_longitude": 105.83721270734269
        }
      },
      {
        "id": 12021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83717092065257,
            21.029195361136214,
            105.83720982998047,
            21.02950584231344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720849215786,
              21.02950584231344
            ],
            [
              105.83720814860382,
              21.029504789040878
            ],
            [
              105.83720982998047,
              21.02949647833934
            ],
            [
              105.83719559029586,
              21.029436750591504
            ],
            [
              105.83718506682551,
              21.029386995091368
            ],
            [
              105.8371871509928,
              21.02937088036902
            ],
            [
              105.83717835685124,
              21.02930434621731
            ],
            [
              105.8371815766959,
              21.0292974478074
            ],
            [
              105.83718174657001,
              21.029255004535347
            ],
            [
              105.83717092065257,
              21.029195361136214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.4_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12076",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02950584231344,
          "to_latitude": 21.029195361136214,
          "to_longitude": 105.83717092065257,
          "from_longitude": 105.83720849215786
        }
      },
      {
        "id": 12022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83724271271046,
            21.02948204318993,
            105.83736207100888,
            21.029515215394024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724271271046,
              21.029515215394024
            ],
            [
              105.8372896513729,
              21.0295023846371
            ],
            [
              105.83734088217321,
              21.029487925316047
            ],
            [
              105.83736207100888,
              21.02948204318993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.6_Thanh Miến",
          "maTaiSan": "04.O14.DODV.12077",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": 12.94,
          "from_latitude": 21.029515215394024,
          "to_latitude": 21.02948204318993,
          "to_longitude": 105.83736207100888,
          "from_longitude": 105.83724271271046
        }
      },
      {
        "id": 12023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687909788866,
            21.028725015467725,
            105.83707106292125,
            21.029205919162788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687909788866,
              21.028725015467725
            ],
            [
              105.83688044648044,
              21.02872981126631
            ],
            [
              105.83688519766041,
              21.0288069610475
            ],
            [
              105.83689373638987,
              21.02892168431994
            ],
            [
              105.83691667263234,
              21.028993138291522
            ],
            [
              105.83691394755893,
              21.029031275834868
            ],
            [
              105.83692928899337,
              21.029087799878752
            ],
            [
              105.83698557490385,
              21.02918649798429
            ],
            [
              105.83704804759374,
              21.029172003543927
            ],
            [
              105.83707106292125,
              21.029205919162788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12078",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028725015467725,
          "to_latitude": 21.029205919162788,
          "to_longitude": 105.83707106292125,
          "from_longitude": 105.83687909788866
        }
      },
      {
        "id": 12024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83688416404088,
            21.028724353762072,
            105.83707155432013,
            21.02912578191796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688416404088,
              21.028724353762072
            ],
            [
              105.83688533144247,
              21.028729434967552
            ],
            [
              105.83688727713198,
              21.02874623527165
            ],
            [
              105.83688962418377,
              21.028766517330737
            ],
            [
              105.83689162657708,
              21.02883534299003
            ],
            [
              105.8369009941926,
              21.028917777386074
            ],
            [
              105.83692338286426,
              21.028990404628455
            ],
            [
              105.837023476956,
              21.02896758518266
            ],
            [
              105.83707155432013,
              21.02912578191796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.3_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12079",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028724353762072,
          "to_latitude": 21.02912578191796,
          "to_longitude": 105.83707155432013,
          "from_longitude": 105.83688416404088
        }
      },
      {
        "id": 12025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706377784532,
            21.028700892720646,
            105.8372728877407,
            21.02907662192253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372728877407,
              21.02906723353607
            ],
            [
              105.8372078441715,
              21.02907662192253
            ],
            [
              105.83715554110258,
              21.029009025525188
            ],
            [
              105.83713274232778,
              21.028980234457897
            ],
            [
              105.8371139668646,
              21.028901997832666
            ],
            [
              105.83710237757107,
              21.028850865297557
            ],
            [
              105.83707239458853,
              21.02873585018649
            ],
            [
              105.8370671742186,
              21.028714925632038
            ],
            [
              105.83706473776284,
              21.028705167464903
            ],
            [
              105.83706377784532,
              21.028700892720646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12080",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": 51.814,
          "from_latitude": 21.02906723353607,
          "to_latitude": 21.028700892720646,
          "to_longitude": 105.83706377784532,
          "from_longitude": 105.8372728877407
        }
      },
      {
        "id": 12026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83781880102664,
            21.028487033572816,
            105.83819681196734,
            21.02857003937707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83781880102664,
              21.02857003937707
            ],
            [
              105.83788623699242,
              21.028553640603793
            ],
            [
              105.83802432784576,
              21.02851901248529
            ],
            [
              105.83814864194856,
              21.028487033572816
            ],
            [
              105.83819681196734,
              21.02852209859214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12081",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": 41.834,
          "from_latitude": 21.02857003937707,
          "to_latitude": 21.02852209859214,
          "to_longitude": 105.83819681196734,
          "from_longitude": 105.83781880102664
        }
      },
      {
        "id": 12027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83775576911775,
            21.02855177170944,
            105.83781880102664,
            21.028583809056826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775576911775,
              21.028583809056826
            ],
            [
              105.83779399060053,
              21.028575985375348
            ],
            [
              105.83781880102664,
              21.02857003937707
            ],
            [
              105.83781605580845,
              21.028557087168707
            ],
            [
              105.8378142213506,
              21.02855177170944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.8_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12082",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": 8.809,
          "from_latitude": 21.028583809056826,
          "to_latitude": 21.02855177170944,
          "to_longitude": 105.8378142213506,
          "from_longitude": 105.83775576911775
        }
      },
      {
        "id": 12028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767806888808,
            21.028569838756006,
            105.8377454593868,
            21.02863474121166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767806888808,
              21.02863474121166
            ],
            [
              105.83770489097448,
              21.02862910816316
            ],
            [
              105.8377454593868,
              21.02861940680106
            ],
            [
              105.83774187213264,
              21.028575938015795
            ],
            [
              105.83774036423118,
              21.028569838756006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.7_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12084",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": 12.729,
          "from_latitude": 21.02863474121166,
          "to_latitude": 21.028569838756006,
          "to_longitude": 105.83774036423118,
          "from_longitude": 105.83767806888808
        }
      },
      {
        "id": 12029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83888495937293,
            21.028289787212735,
            105.83892110525156,
            21.028469192084337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83888495937293,
              21.028289787212735
            ],
            [
              105.83888648070949,
              21.028297169645978
            ],
            [
              105.8388864730338,
              21.028297173296743
            ],
            [
              105.83888621587312,
              21.028314652824548
            ],
            [
              105.83888615377053,
              21.028318854922112
            ],
            [
              105.83890165923195,
              21.028384070342188
            ],
            [
              105.83891305862835,
              21.028431012485388
            ],
            [
              105.83891909359126,
              21.028457925973758
            ],
            [
              105.83892110525156,
              21.028469192084337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.11_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12086",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 20.273,
          "from_latitude": 21.028289787212735,
          "to_latitude": 21.028469192084337,
          "to_longitude": 105.83892110525156,
          "from_longitude": 105.83888495937293
        }
      },
      {
        "id": 12030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391060188542,
            21.02821234021184,
            105.83925411819241,
            21.028263332462267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925411819241,
              21.02821234021184
            ],
            [
              105.83913073658239,
              21.02825521408498
            ],
            [
              105.8391060188542,
              21.028263332462267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.13_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12087",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": 16.398,
          "from_latitude": 21.02821234021184,
          "to_latitude": 21.028263332462267,
          "to_longitude": 105.8391060188542,
          "from_longitude": 105.83925411819241
        }
      },
      {
        "id": 12031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909034739084,
            21.02823940167215,
            105.83916258782402,
            21.028361929293155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909034739084,
              21.02823940167215
            ],
            [
              105.83909118925338,
              21.02824199699234
            ],
            [
              105.83909503724682,
              21.028253841831877
            ],
            [
              105.83910097177973,
              21.028258970851336
            ],
            [
              105.8391310369146,
              21.028284952717048
            ],
            [
              105.83914180070056,
              21.02830434690301
            ],
            [
              105.83915856451243,
              21.028347846645723
            ],
            [
              105.83916258782402,
              21.028361929293155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.13_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12088",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": 15.914,
          "from_latitude": 21.02823940167215,
          "to_latitude": 21.028361929293155,
          "to_longitude": 105.83916258782402,
          "from_longitude": 105.83909034739084
        }
      },
      {
        "id": 12032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669676486826,
            21.028528995699254,
            105.83671714087309,
            21.028653802435894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669945676225,
              21.028653802435894
            ],
            [
              105.83669885298377,
              21.028574935082286
            ],
            [
              105.8367092446081,
              21.028567849650297
            ],
            [
              105.83671714087309,
              21.028532349362756
            ],
            [
              105.83669676486826,
              21.028528995699254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12089",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028653802435894,
          "to_latitude": 21.028528995699254,
          "to_longitude": 105.83669676486826,
          "from_longitude": 105.83669945676225
        }
      },
      {
        "id": 12033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83659493103917,
            21.028647810939617,
            105.83672602990866,
            21.02866959835189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83659493103917,
              21.02866959835189
            ],
            [
              105.83669945676225,
              21.028653802435894
            ],
            [
              105.83672358829277,
              21.028647810939617
            ],
            [
              105.83672397640242,
              21.028649264121903
            ],
            [
              105.83672602990866,
              21.028665563512558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12090",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02866959835189,
          "to_latitude": 21.028665563512558,
          "to_longitude": 105.83672602990866,
          "from_longitude": 105.83659493103917
        }
      },
      {
        "id": 12034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83672358829277,
            21.02862890875955,
            105.83689565254765,
            21.028647810939617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83689565254765,
              21.02862890875955
            ],
            [
              105.83674896507239,
              21.028647577680662
            ],
            [
              105.83672358829277,
              21.028647810939617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12091",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02862890875955,
          "to_latitude": 21.028647810939617,
          "to_longitude": 105.83672358829277,
          "from_longitude": 105.83689565254765
        }
      },
      {
        "id": 12035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83743116453721,
            21.02820133851134,
            105.83750735777492,
            21.028547173062748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750735777492,
              21.028547173062748
            ],
            [
              105.83749782308205,
              21.028521496239417
            ],
            [
              105.83747800385503,
              21.02850105727593
            ],
            [
              105.83744951730404,
              21.02839322722593
            ],
            [
              105.83748216113429,
              21.028383870745092
            ],
            [
              105.83743116453721,
              21.02820133851134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12092",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028547173062748,
          "to_latitude": 21.02820133851134,
          "to_longitude": 105.83743116453721,
          "from_longitude": 105.83750735777492
        }
      },
      {
        "id": 12036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373507181017,
            21.028174525985506,
            105.83744951730404,
            21.02839707678263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83744951730404,
              21.02839322722593
            ],
            [
              105.83741393259946,
              21.02839707678263
            ],
            [
              105.83739768509048,
              21.02838226987761
            ],
            [
              105.8373507181017,
              21.028174525985506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12093",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02839322722593,
          "to_latitude": 21.028174525985506,
          "to_longitude": 105.8373507181017,
          "from_longitude": 105.83744951730404
        }
      },
      {
        "id": 12037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375610053494,
            21.028481967661055,
            105.83763020686389,
            21.028497521511017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375610053494,
              21.028497521511017
            ],
            [
              105.8375693019624,
              21.028495657038732
            ],
            [
              105.83763020686389,
              21.028481967661055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.5_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12094",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028497521511017,
          "to_latitude": 21.028481967661055,
          "to_longitude": 105.83763020686389,
          "from_longitude": 105.8375610053494
        }
      },
      {
        "id": 12038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83748192193019,
            21.028231202888012,
            105.83756808308253,
            21.028533877493068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756808308253,
              21.028533877493068
            ],
            [
              105.83756135184115,
              21.028507192416637
            ],
            [
              105.8375610053494,
              21.028497521511017
            ],
            [
              105.83755991848132,
              21.02846725806402
            ],
            [
              105.83748192193019,
              21.028231202888012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.5_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12095",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028533877493068,
          "to_latitude": 21.028231202888012,
          "to_longitude": 105.83748192193019,
          "from_longitude": 105.83756808308253
        }
      },
      {
        "id": 12039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83769438820795,
            21.02845405182579,
            105.83775890168117,
            21.028472033075904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83769438820795,
              21.028472033075904
            ],
            [
              105.83769476185243,
              21.02847192917103
            ],
            [
              105.83775890168117,
              21.02845405182579
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12096",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028472033075904,
          "to_latitude": 21.02845405182579,
          "to_longitude": 105.83775890168117,
          "from_longitude": 105.83769438820795
        }
      },
      {
        "id": 12040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83759785779866,
            21.028150860129248,
            105.83770432725025,
            21.028503955486983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83770432725025,
              21.028503955486983
            ],
            [
              105.83769710663692,
              21.028478704435322
            ],
            [
              105.83769438820795,
              21.02847203307589
            ],
            [
              105.83764925505356,
              21.02832707229204
            ],
            [
              105.83766515135828,
              21.028293318228904
            ],
            [
              105.83762345297403,
              21.02817612545141
            ],
            [
              105.8376043248501,
              21.028169348857162
            ],
            [
              105.83759785779866,
              21.028150860129248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12097",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028503955486983,
          "to_latitude": 21.028150860129248,
          "to_longitude": 105.83759785779866,
          "from_longitude": 105.83770432725025
        }
      },
      {
        "id": 12041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871953714734,
            21.02796758981399,
            105.83880998204666,
            21.02823011965074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83880998204666,
              21.02823011965074
            ],
            [
              105.83880216027384,
              21.02821609144614
            ],
            [
              105.83879927604099,
              21.028210914853435
            ],
            [
              105.83880776680265,
              21.028209368201402
            ],
            [
              105.83878146456048,
              21.02812328173751
            ],
            [
              105.83875266182002,
              21.02811159430308
            ],
            [
              105.8387263856571,
              21.02802947194358
            ],
            [
              105.83873669231865,
              21.02801341600338
            ],
            [
              105.83871953714734,
              21.02796758981399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.9_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12098",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02823011965074,
          "to_latitude": 21.02796758981399,
          "to_longitude": 105.83871953714734,
          "from_longitude": 105.83880998204666
        }
      },
      {
        "id": 12042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867659073313,
            21.028041844207063,
            105.83879927604099,
            21.028241202991392
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879927604099,
              21.028210914853435
            ],
            [
              105.83871405114648,
              21.028241202991392
            ],
            [
              105.83867997932107,
              21.02809452420991
            ],
            [
              105.83868106500748,
              21.028062810323082
            ],
            [
              105.83867659073313,
              21.028041844207063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.9_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12099",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028210914853435,
          "to_latitude": 21.028041844207063,
          "to_longitude": 105.83867659073313,
          "from_longitude": 105.83879927604099
        }
      },
      {
        "id": 12043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728177283895,
            21.028144483769353,
            105.83733726601568,
            21.028360825928953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728177283895,
              21.028144483769353
            ],
            [
              105.83728732568306,
              21.02816826628469
            ],
            [
              105.83730703642726,
              21.028234913783105
            ],
            [
              105.83731435576372,
              21.028265280222115
            ],
            [
              105.837322540804,
              21.028299242643854
            ],
            [
              105.83733726601568,
              21.028360825928953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.1_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12100",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028144483769353,
          "to_latitude": 21.028360825928953,
          "to_longitude": 105.83733726601568,
          "from_longitude": 105.83728177283895
        }
      },
      {
        "id": 12044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733726601568,
            21.028360825928953,
            105.83737325601558,
            21.028536295726145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83733726601568,
              21.028360825928953
            ],
            [
              105.83737325601558,
              21.028536295726145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.1_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12101",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028360825928953,
          "to_latitude": 21.028536295726145,
          "to_longitude": 105.83737325601558,
          "from_longitude": 105.83733726601568
        }
      },
      {
        "id": 12045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366203482966,
            21.02840175707743,
            105.83683200223487,
            21.028430394835116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83683200223487,
              21.02840175707743
            ],
            [
              105.83683083086785,
              21.028401995873498
            ],
            [
              105.83670757588403,
              21.02842711315547
            ],
            [
              105.83663244036332,
              21.028430394835116
            ],
            [
              105.8366203482966,
              21.02841755811192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.1_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12102",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02840175707743,
          "to_latitude": 21.02841755811192,
          "to_longitude": 105.8366203482966,
          "from_longitude": 105.83683200223487
        }
      },
      {
        "id": 12046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83682419947677,
            21.028330671761495,
            105.83699724183727,
            21.028468130873776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83699724183727,
              21.028343782451657
            ],
            [
              105.83699178667176,
              21.028343171627682
            ],
            [
              105.83698850023947,
              21.028342794902088
            ],
            [
              105.83694096354829,
              21.028337362961334
            ],
            [
              105.83685767028913,
              21.028330671761495
            ],
            [
              105.8368370001947,
              21.028359243973977
            ],
            [
              105.83683200223487,
              21.02840175707743
            ],
            [
              105.83682419947677,
              21.028468130873776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.1_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12103",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028343782451657,
          "to_latitude": 21.028468130873776,
          "to_longitude": 105.83682419947677,
          "from_longitude": 105.83699724183727
        }
      },
      {
        "id": 12047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697089824906,
            21.02834003092434,
            105.83699747369313,
            21.02852232933964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697089824906,
              21.02852232933964
            ],
            [
              105.8369766814579,
              21.02845970843464
            ],
            [
              105.83698760074417,
              21.028349167953873
            ],
            [
              105.83698850023947,
              21.028342794902088
            ],
            [
              105.83698889077745,
              21.02834003092434
            ],
            [
              105.83699227283637,
              21.028340649242946
            ],
            [
              105.83699747369313,
              21.02834119989951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.2_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12104",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02852232933964,
          "to_latitude": 21.02834119989951,
          "to_longitude": 105.83699747369313,
          "from_longitude": 105.83697089824906
        }
      },
      {
        "id": 12048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366009044373,
            21.02807716732492,
            105.83683382096903,
            21.028172016881353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366009044373,
              21.028172016881353
            ],
            [
              105.8366291261935,
              21.028160129811784
            ],
            [
              105.83664009885314,
              21.028122536977598
            ],
            [
              105.83680215602313,
              21.02807716732492
            ],
            [
              105.83683382096903,
              21.028128552107066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.4_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12105",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028172016881353,
          "to_latitude": 21.028128552107066,
          "to_longitude": 105.83683382096903,
          "from_longitude": 105.8366009044373
        }
      },
      {
        "id": 12049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662056293431,
            21.02811785149335,
            105.8370086504815,
            21.028316573121415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8370086504815,
              21.02812275643595
            ],
            [
              105.83700234965043,
              21.02812230693541
            ],
            [
              105.83693992550653,
              21.02811785149335
            ],
            [
              105.83685355675273,
              21.028119077722003
            ],
            [
              105.83683382096903,
              21.028128552107066
            ],
            [
              105.83681314548235,
              21.028162875163382
            ],
            [
              105.8367922804,
              21.028234753351988
            ],
            [
              105.83678338812746,
              21.028266054945217
            ],
            [
              105.83662056293431,
              21.028316573121415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.4_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12106",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02812275643595,
          "to_latitude": 21.028316573121415,
          "to_longitude": 105.83662056293431,
          "from_longitude": 105.8370086504815
        }
      },
      {
        "id": 12050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83648544340267,
            21.027969633967153,
            105.83659181807761,
            21.028008110700007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83659181807761,
              21.027969633967153
            ],
            [
              105.83657261975036,
              21.02797026131856
            ],
            [
              105.83648544340267,
              21.028008110700007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.3_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.12107",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027969633967153,
          "to_latitude": 21.028008110700007,
          "to_longitude": 105.83648544340267,
          "from_longitude": 105.83659181807761
        }
      },
      {
        "id": 12051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365292646184,
            21.027828704154835,
            105.83659785179502,
            21.02805369847173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83657234344764,
              21.027828704154835
            ],
            [
              105.83657992466618,
              21.02784448771165
            ],
            [
              105.83658094247511,
              21.027846728116696
            ],
            [
              105.83658167784955,
              21.027848350300253
            ],
            [
              105.83657615901886,
              21.027855117300795
            ],
            [
              105.8365766719859,
              21.027856362128105
            ],
            [
              105.83659785179502,
              21.027907745251767
            ],
            [
              105.83659181807761,
              21.027969633967153
            ],
            [
              105.83658845407672,
              21.02800413827932
            ],
            [
              105.83658555175491,
              21.02802690564742
            ],
            [
              105.8365292646184,
              21.02805369847173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.3_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.12108",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027828704154835,
          "to_latitude": 21.02805369847173,
          "to_longitude": 105.8365292646184,
          "from_longitude": 105.83657234344764
        }
      },
      {
        "id": 12052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83680323138674,
            21.027742475486683,
            105.83688564325459,
            21.02796425045721
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83680323138674,
              21.027742475486683
            ],
            [
              105.83681534042962,
              21.027756809659056
            ],
            [
              105.83684675122437,
              21.02784845944927
            ],
            [
              105.83686820889528,
              21.02789853124744
            ],
            [
              105.83688161994299,
              21.027936085085024
            ],
            [
              105.83688564325459,
              21.02796425045721
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.5_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.12109",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": 26.193,
          "from_latitude": 21.027742475486683,
          "to_latitude": 21.02796425045721,
          "to_longitude": 105.83688564325459,
          "from_longitude": 105.83680323138674
        }
      },
      {
        "id": 12053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736147693463,
            21.02692562087721,
            105.83756166161804,
            21.027424233989237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756166161804,
              21.027424233989237
            ],
            [
              105.83756050500004,
              21.02742246214343
            ],
            [
              105.83755485894596,
              21.027403521369003
            ],
            [
              105.8375546328668,
              21.027402831517023
            ],
            [
              105.83751541233694,
              21.02733494064945
            ],
            [
              105.83744722179537,
              21.027210730171735
            ],
            [
              105.83736147693463,
              21.02705454349477
            ],
            [
              105.83739605520708,
              21.02697064084683
            ],
            [
              105.83737197091835,
              21.02692562087721
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.5.1_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.12110",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027424233989237,
          "to_latitude": 21.02692562087721,
          "to_longitude": 105.83737197091835,
          "from_longitude": 105.83756166161804
        }
      },
      {
        "id": 12054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83759562852453,
            21.027357586177033,
            105.83768963308809,
            21.027412245151115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759562852453,
              21.02740670764714
            ],
            [
              105.83759685576483,
              21.027409755398736
            ],
            [
              105.83759786054863,
              21.027412245151115
            ],
            [
              105.83768827375458,
              21.02738495600891
            ],
            [
              105.83768963308809,
              21.027357586177033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.6.1_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12111",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02740670764714,
          "to_latitude": 21.027357586177033,
          "to_longitude": 105.83768963308809,
          "from_longitude": 105.83759562852453
        }
      },
      {
        "id": 12055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814886907437,
            21.027917500443735,
            105.83822359529712,
            21.027955762301627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814886907437,
              21.027955762301627
            ],
            [
              105.83822359529712,
              21.02793301391524
            ],
            [
              105.83821969030993,
              21.027917500443735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.4_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12112",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027955762301627,
          "to_latitude": 21.027917500443735,
          "to_longitude": 105.83821969030993,
          "from_longitude": 105.83814886907437
        }
      },
      {
        "id": 12056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83812181784288,
            21.02785280964562,
            105.83822925559197,
            21.027881202728004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83812181784288,
              21.027881202728004
            ],
            [
              105.83822925559197,
              21.02785280964562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.4_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12113",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027881202728004,
          "to_latitude": 21.02785280964562,
          "to_longitude": 105.83822925559197,
          "from_longitude": 105.83812181784288
        }
      },
      {
        "id": 12057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83808107663137,
            21.02777643369115,
            105.83828747869575,
            21.027989539551612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83808107663137,
              21.02777643369115
            ],
            [
              105.83808152465735,
              21.027777589414598
            ],
            [
              105.83812181784288,
              21.027881202728004
            ],
            [
              105.83814886907437,
              21.027955762301627
            ],
            [
              105.83814889151321,
              21.027955818190847
            ],
            [
              105.83820366075904,
              21.02798485780562
            ],
            [
              105.83823269287457,
              21.02798218025429
            ],
            [
              105.83825159818542,
              21.027989539551612
            ],
            [
              105.83828747869575,
              21.027986655715978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.4_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12114",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02777643369115,
          "to_latitude": 21.027986655715978,
          "to_longitude": 105.83828747869575,
          "from_longitude": 105.83808107663137
        }
      },
      {
        "id": 12058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83789326400081,
            21.02785704875343,
            105.83797072735545,
            21.028148958782236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83792383797136,
              21.028148958782236
            ],
            [
              105.83792061533471,
              21.028118863023334
            ],
            [
              105.83797072735545,
              21.02810158943307
            ],
            [
              105.83794863972298,
              21.02803245451395
            ],
            [
              105.83789326400081,
              21.02785704875343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.6_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12115",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028148958782236,
          "to_latitude": 21.02785704875343,
          "to_longitude": 105.83789326400081,
          "from_longitude": 105.83792383797136
        }
      },
      {
        "id": 12059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790032877687,
            21.027857518843938,
            105.83805442528,
            21.028105925754843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790032877687,
              21.027857518843938
            ],
            [
              105.83797103326738,
              21.02805804988131
            ],
            [
              105.83798705007312,
              21.028105925754843
            ],
            [
              105.83805442528,
              21.028086555589823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.5_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12116",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027857518843938,
          "to_latitude": 21.028086555589823,
          "to_longitude": 105.83805442528,
          "from_longitude": 105.83790032877687
        }
      },
      {
        "id": 12060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790032877687,
            21.027809306468605,
            105.83803709435173,
            21.027857518843938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790032877687,
              21.027857518843938
            ],
            [
              105.83793713795498,
              21.027844543315446
            ],
            [
              105.83803709435173,
              21.027809306468605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.5_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12117",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027857518843938,
          "to_latitude": 21.027809306468605,
          "to_longitude": 105.83803709435173,
          "from_longitude": 105.83790032877687
        }
      },
      {
        "id": 12061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83822643082743,
            21.02739530634859,
            105.83832476088726,
            21.02741251333658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83822643082743,
              21.02741251333658
            ],
            [
              105.83823187145303,
              21.027411561618177
            ],
            [
              105.83832476088726,
              21.02739530634859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12118",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02741251333658,
          "to_latitude": 21.02739530634859,
          "to_longitude": 105.83832476088726,
          "from_longitude": 105.83822643082743
        }
      },
      {
        "id": 12062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820989411608,
            21.027371997510816,
            105.83824400578233,
            21.027470483151514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83824400578233,
              21.027470483151514
            ],
            [
              105.83822643082743,
              21.02741251333658
            ],
            [
              105.83821414642107,
              21.027371997510816
            ],
            [
              105.83821066587014,
              21.02737337492049
            ],
            [
              105.83820989411608,
              21.027373679497746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12119",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027470483151514,
          "to_latitude": 21.027373679497746,
          "to_longitude": 105.83820989411608,
          "from_longitude": 105.83824400578233
        }
      },
      {
        "id": 12063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823348902101,
            21.02724642025372,
            105.83834889723343,
            21.027349876176913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823348902101,
              21.027349876176913
            ],
            [
              105.8382335029885,
              21.027349621399253
            ],
            [
              105.83823622717813,
              21.027299173819447
            ],
            [
              105.83825748860872,
              21.02726683660749
            ],
            [
              105.83834889723343,
              21.02724642025372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12120",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027349876176913,
          "to_latitude": 21.02724642025372,
          "to_longitude": 105.83834889723343,
          "from_longitude": 105.83823348902101
        }
      },
      {
        "id": 12064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820957136771,
            21.02732687464705,
            105.83831173928577,
            21.027371997510816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83831173928577,
              21.02732687464705
            ],
            [
              105.83823348902101,
              21.027349876176913
            ],
            [
              105.83820957136771,
              21.027356906435855
            ],
            [
              105.83821414642107,
              21.027371997510816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12121",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02732687464705,
          "to_latitude": 21.027371997510816,
          "to_longitude": 105.83821414642107,
          "from_longitude": 105.83831173928577
        }
      },
      {
        "id": 12065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815239946489,
            21.027237747863744,
            105.83820636287189,
            21.02737769005149
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820636287189,
              21.02737769005149
            ],
            [
              105.8381860793979,
              21.02735163450592
            ],
            [
              105.83815239946489,
              21.027237747863744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.2_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12122",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02737769005149,
          "to_latitude": 21.027237747863744,
          "to_longitude": 105.83815239946489,
          "from_longitude": 105.83820636287189
        }
      },
      {
        "id": 12066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83797133704722,
            21.027225615290423,
            105.83815891177953,
            21.02728812042203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83797133704722,
              21.027254179230685
            ],
            [
              105.83798034168595,
              21.02728812042203
            ],
            [
              105.83815189349626,
              21.02723603604076
            ],
            [
              105.83814978290154,
              21.0272281315023
            ],
            [
              105.83815855914048,
              21.02722569018957
            ],
            [
              105.83815891177953,
              21.027225615290423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.2.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12123",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027254179230685,
          "to_latitude": 21.027225615290423,
          "to_longitude": 105.83815891177953,
          "from_longitude": 105.83797133704722
        }
      },
      {
        "id": 12067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83782746708565,
            21.02728812042203,
            105.83798509983427,
            21.027339668228013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798034168595,
              21.02728812042203
            ],
            [
              105.83798509983427,
              21.027309467225987
            ],
            [
              105.83782746708565,
              21.027339668228013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.2_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12124",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02728812042203,
          "to_latitude": 21.027339668228013,
          "to_longitude": 105.83782746708565,
          "from_longitude": 105.83798034168595
        }
      },
      {
        "id": 12068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786550893504,
            21.02737660322124,
            105.83821227797456,
            21.027413038687065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83821227797456,
              21.02737660322124
            ],
            [
              105.83821158892331,
              21.02737673035919
            ],
            [
              105.83820636287189,
              21.02737769005149
            ],
            [
              105.83801391482113,
              21.027413038687065
            ],
            [
              105.83799221457785,
              21.027393566464532
            ],
            [
              105.83786550893504,
              21.027410593333023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.2_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12125",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02737660322124,
          "to_latitude": 21.027410593333023,
          "to_longitude": 105.83786550893504,
          "from_longitude": 105.83821227797456
        }
      },
      {
        "id": 12069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83814867031559,
            21.027195695030574,
            105.83818781362395,
            21.027312701573518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83818781362395,
              21.027312701573518
            ],
            [
              105.83817766115253,
              21.027282354556274
            ],
            [
              105.83816853728105,
              21.027251528276373
            ],
            [
              105.8381578679064,
              21.027218888716206
            ],
            [
              105.83814867031559,
              21.027195695030574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.3_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12126",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027312701573518,
          "to_latitude": 21.027195695030574,
          "to_longitude": 105.83814867031559,
          "from_longitude": 105.83818781362395
        }
      },
      {
        "id": 12070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813463623959,
            21.02715884773752,
            105.83829074652745,
            21.027195695030574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83813463623959,
              21.027183946433798
            ],
            [
              105.8381358385701,
              21.02718374179889
            ],
            [
              105.8381373801122,
              21.027183469556842
            ],
            [
              105.83814062724991,
              21.02719058720614
            ],
            [
              105.83814867031559,
              21.027195695030574
            ],
            [
              105.83829074652745,
              21.02715884773752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.3_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12127",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027183946433798,
          "to_latitude": 21.02715884773752,
          "to_longitude": 105.83829074652745,
          "from_longitude": 105.83813463623959
        }
      },
      {
        "id": 12071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83851437851719,
            21.027393509537383,
            105.83869263842838,
            21.02745308751567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83851437851719,
              21.027452469452477
            ],
            [
              105.8385189053539,
              21.02745308751567
            ],
            [
              105.83853677348637,
              21.027448223165706
            ],
            [
              105.83869263842838,
              21.027393509537383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12128",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027452469452477,
          "to_latitude": 21.027393509537383,
          "to_longitude": 105.83869263842838,
          "from_longitude": 105.83851437851719
        }
      },
      {
        "id": 12072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83845558767558,
            21.027244399499246,
            105.83851645545747,
            21.027463124464564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83851645545747,
              21.027463124464564
            ],
            [
              105.83851470524132,
              21.027453436999355
            ],
            [
              105.83851437851719,
              21.027452469452477
            ],
            [
              105.83848177918927,
              21.027356222011644
            ],
            [
              105.83845558767558,
              21.027269745644293
            ],
            [
              105.83845850029037,
              21.027244399499246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.1_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12129",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027463124464564,
          "to_latitude": 21.027244399499246,
          "to_longitude": 105.83845850029037,
          "from_longitude": 105.83851645545747
        }
      },
      {
        "id": 12073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843604838005,
            21.02711651360373,
            105.83850479846787,
            21.02746717364198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850472617053,
              21.02746717364198
            ],
            [
              105.83850185999844,
              21.027458169999534
            ],
            [
              105.83850278749857,
              21.027455267887753
            ],
            [
              105.83850479846787,
              21.027448969735556
            ],
            [
              105.83847059010854,
              21.027333188099956
            ],
            [
              105.83845767994022,
              21.027292601314294
            ],
            [
              105.83844952764271,
              21.02725506744502
            ],
            [
              105.83844556014103,
              21.027233709531693
            ],
            [
              105.83846705432035,
              21.027226482545178
            ],
            [
              105.83843604838005,
              21.02711651360373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.2_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12130",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02746717364198,
          "to_latitude": 21.02711651360373,
          "to_longitude": 105.83843604838005,
          "from_longitude": 105.83850472617053
        }
      },
      {
        "id": 12074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.838281719398,
            21.027455267887753,
            105.83850278749857,
            21.027538065140035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838281719398,
              21.027538065140035
            ],
            [
              105.83832603239719,
              21.027524272154597
            ],
            [
              105.83848257820985,
              21.027468112008435
            ],
            [
              105.83850278749857,
              21.027455267887753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.2_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12131",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027538065140035,
          "to_latitude": 21.027455267887753,
          "to_longitude": 105.83850278749857,
          "from_longitude": 105.838281719398
        }
      },
      {
        "id": 12075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83767400292248,
            21.027688229160415,
            105.83775120148299,
            21.027881999326777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83772461062809,
              21.027688229160415
            ],
            [
              105.83771977811773,
              21.027691443149923
            ],
            [
              105.83772091316611,
              21.027697617408638
            ],
            [
              105.83768512160049,
              21.02771451418666
            ],
            [
              105.83767400292248,
              21.027719211523536
            ],
            [
              105.83775091951814,
              21.027864449282806
            ],
            [
              105.83775120148299,
              21.027864982601574
            ],
            [
              105.83771196461548,
              21.027881999326777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.5_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12132",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027688229160415,
          "to_latitude": 21.027881999326777,
          "to_longitude": 105.83771196461548,
          "from_longitude": 105.83772461062809
        }
      },
      {
        "id": 12076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766608394818,
            21.027508391984377,
            105.83799630529629,
            21.027613739657582
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83766608394818,
              21.027613739657582
            ],
            [
              105.83766656617195,
              21.027613441025068
            ],
            [
              105.83767170956415,
              21.027610254408795
            ],
            [
              105.8376787753352,
              21.027607289547642
            ],
            [
              105.83776561051756,
              21.0275683962198
            ],
            [
              105.83779435062844,
              21.02756133594043
            ],
            [
              105.83782192809024,
              21.027559171432692
            ],
            [
              105.83799630529629,
              21.027508391984377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.8_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12133",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027613739657582,
          "to_latitude": 21.027508391984377,
          "to_longitude": 105.83799630529629,
          "from_longitude": 105.83766608394818
        }
      },
      {
        "id": 12077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83782491809961,
            21.026566289832125,
            105.83814588235772,
            21.02671302297116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814588235772,
              21.026566289832125
            ],
            [
              105.83802161081735,
              21.02662539047016
            ],
            [
              105.83793584094497,
              21.02666888781248
            ],
            [
              105.83782522621895,
              21.026712900421256
            ],
            [
              105.83782491809961,
              21.02671302297116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.5_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12134",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026566289832125,
          "to_latitude": 21.02671302297116,
          "to_longitude": 105.83782491809961,
          "from_longitude": 105.83814588235772
        }
      },
      {
        "id": 12078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83796225172517,
            21.02609508417103,
            105.83817450862519,
            21.026156936402618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83817450862519,
              21.026156936402618
            ],
            [
              105.8381654234731,
              21.02613887156182
            ],
            [
              105.83814082296115,
              21.02611664617017
            ],
            [
              105.83813019443409,
              21.026112288115254
            ],
            [
              105.83810379522834,
              21.026101463029754
            ],
            [
              105.83807361519817,
              21.02609508417103
            ],
            [
              105.83796225172517,
              21.026150490189416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12135",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026156936402618,
          "to_latitude": 21.026150490189416,
          "to_longitude": 105.83796225172517,
          "from_longitude": 105.83817450862519
        }
      },
      {
        "id": 12079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83817450862519,
            21.026156936402618,
            105.83823647275233,
            21.026281246839005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823647275233,
              21.026281246839005
            ],
            [
              105.83817450862519,
              21.026156936402618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12136",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026281246839005,
          "to_latitude": 21.026156936402618,
          "to_longitude": 105.83817450862519,
          "from_longitude": 105.83823647275233
        }
      },
      {
        "id": 12080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876449368032,
            21.027736802438472,
            105.83886095167767,
            21.027818470101227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83876449368032,
              21.027736802438472
            ],
            [
              105.8387645111679,
              21.02773683306178
            ],
            [
              105.83881062130864,
              21.027818470101227
            ],
            [
              105.83886095167767,
              21.027808964697382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12137",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027736802438472,
          "to_latitude": 21.027808964697382,
          "to_longitude": 105.83886095167767,
          "from_longitude": 105.83876449368032
        }
      },
      {
        "id": 12081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83858072656001,
            21.02780644161895,
            105.83864469345188,
            21.027919355870644
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386194903202,
              21.02780644161895
            ],
            [
              105.83864469345188,
              21.02786134525904
            ],
            [
              105.83858072656001,
              21.027919355870644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12138",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02780644161895,
          "to_latitude": 21.027919355870644,
          "to_longitude": 105.83858072656001,
          "from_longitude": 105.8386194903202
        }
      },
      {
        "id": 12082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83852773658548,
            21.027656801139244,
            105.83892199318176,
            21.027817156924048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83852773658548,
              21.02778499455474
            ],
            [
              105.83853977369901,
              21.02780449809728
            ],
            [
              105.83858241748513,
              21.027817156924048
            ],
            [
              105.8386194903202,
              21.02780644161895
            ],
            [
              105.83876449368032,
              21.027736802438472
            ],
            [
              105.83876890324258,
              21.027734684308662
            ],
            [
              105.83879380641758,
              21.027696777863678
            ],
            [
              105.83890586291686,
              21.02765797000189
            ],
            [
              105.83892096740783,
              21.027657097037476
            ],
            [
              105.83892199318176,
              21.027656801139244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12139",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02778499455474,
          "to_latitude": 21.027656801139244,
          "to_longitude": 105.83892199318176,
          "from_longitude": 105.83852773658548
        }
      },
      {
        "id": 12083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735374981502,
            21.026293908282575,
            105.83766328374064,
            21.02647031371259
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735374981502,
              21.02647031371259
            ],
            [
              105.83746594168873,
              21.026410538725465
            ],
            [
              105.83766328374064,
              21.026306749301263
            ],
            [
              105.83766209106108,
              21.026304720214778
            ],
            [
              105.83765574490964,
              21.026293908282575
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.1_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12140",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02647031371259,
          "to_latitude": 21.026293908282575,
          "to_longitude": 105.83765574490964,
          "from_longitude": 105.83735374981502
        }
      },
      {
        "id": 12084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766328374064,
            21.02616261070675,
            105.83794077059433,
            21.026306749301263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794077059433,
              21.02616261070675
            ],
            [
              105.83783668450563,
              21.026218003367475
            ],
            [
              105.83767851752454,
              21.026298736739772
            ],
            [
              105.83766328374064,
              21.026306749301263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.1_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12141",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02616261070675,
          "to_latitude": 21.026306749301263,
          "to_longitude": 105.83766328374064,
          "from_longitude": 105.83794077059433
        }
      },
      {
        "id": 12085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755460879111,
            21.02599057832838,
            105.83783903252062,
            21.026157431750363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755460879111,
              21.026157431750363
            ],
            [
              105.83783903252062,
              21.02599057832838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.2_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12142",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026157431750363,
          "to_latitude": 21.02599057832838,
          "to_longitude": 105.83783903252062,
          "from_longitude": 105.83755460879111
        }
      },
      {
        "id": 12086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783903252062,
            21.025831460162916,
            105.83796850377786,
            21.026010095363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378534763991,
              21.026010095363
            ],
            [
              105.83784265998648,
              21.02599500973313
            ],
            [
              105.83783903252062,
              21.02599057832838
            ],
            [
              105.83794453892013,
              21.02593029313812
            ],
            [
              105.83796850377786,
              21.025916600516506
            ],
            [
              105.83790541408379,
              21.025831460162916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.2_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12143",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026010095363,
          "to_latitude": 21.025831460162916,
          "to_longitude": 105.83790541408379,
          "from_longitude": 105.8378534763991
        }
      },
      {
        "id": 12087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698630422607,
            21.026088867282986,
            105.83707616302233,
            21.026127120290564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698630422607,
              21.026127120290564
            ],
            [
              105.83707613807141,
              21.026088878244483
            ],
            [
              105.83707616302233,
              21.026088867282986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.4_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12144",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026127120290564,
          "to_latitude": 21.026088867282986,
          "to_longitude": 105.83707616302233,
          "from_longitude": 105.83698630422607
        }
      },
      {
        "id": 12088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83703124372771,
            21.02599746543116,
            105.83714382245518,
            21.02613207500418
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.83707613807141,
                21.026088878244483
              ],
              [
                105.83703124372771,
                21.02599746543116
              ]
            ],
            [
              [
                105.83714382245518,
                21.02613207500418
              ],
              [
                105.83714017437403,
                21.02612670523682
              ],
              [
                105.8371364841175,
                21.026121362773697
              ],
              [
                105.83712986044696,
                21.026111665860476
              ],
              [
                105.8370968613335,
                21.026108652565114
              ],
              [
                105.83707616302233,
                21.026088867282986
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.4_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12145",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.83703124372771,
            21.02599746543116
          ],
          "to_latitude": [
            105.83714017437403,
            21.02612670523682
          ],
          "to_longitude": [
            105.83714382245518,
            21.02613207500418
          ],
          "from_longitude": [
            105.83707613807141,
            21.026088878244483
          ]
        }
      },
      {
        "id": 12089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371364841175,
            21.0260823991096,
            105.83721583286336,
            21.026121362773697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371364841175,
              21.026121362773697
            ],
            [
              105.83721583286336,
              21.0260823991096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.4_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12146",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026121362773697,
          "to_latitude": 21.0260823991096,
          "to_longitude": 105.83721583286336,
          "from_longitude": 105.8371364841175
        }
      },
      {
        "id": 12090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83639615385108,
            21.026543509936605,
            105.83653175685778,
            21.026609282157587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83639615385108,
              21.026609282157587
            ],
            [
              105.8364812155679,
              21.026561029980613
            ],
            [
              105.83650420518747,
              21.026559404901494
            ],
            [
              105.83652993395025,
              21.026544561223186
            ],
            [
              105.83653175685778,
              21.026543509936605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.2_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12147",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026609282157587,
          "to_latitude": 21.026543509936605,
          "to_longitude": 105.83653175685778,
          "from_longitude": 105.83639615385108
        }
      },
      {
        "id": 12091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644324245344,
            21.02644226678881,
            105.83654937085684,
            21.02656620528679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83654937085684,
              21.02656620528679
            ],
            [
              105.83653447801373,
              21.026546548541784
            ],
            [
              105.83653175685778,
              21.026543509936605
            ],
            [
              105.83652156050182,
              21.026532122579095
            ],
            [
              105.83649977768287,
              21.026507793930346
            ],
            [
              105.8364774285751,
              21.02648283400137
            ],
            [
              105.83644324245344,
              21.02644226678881
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.2_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12148",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02656620528679,
          "to_latitude": 21.02644226678881,
          "to_longitude": 105.83644324245344,
          "from_longitude": 105.83654937085684
        }
      },
      {
        "id": 12092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568843477619,
            21.026375364607272,
            105.83583774731657,
            21.02645556347544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583774731657,
              21.026399636535693
            ],
            [
              105.83575407602908,
              21.02645556347544
            ],
            [
              105.83568843477619,
              21.026375364607272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.7_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12149",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026399636535693,
          "to_latitude": 21.026375364607272,
          "to_longitude": 105.83568843477619,
          "from_longitude": 105.83583774731657
        }
      },
      {
        "id": 12093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83579990893077,
            21.02634893942543,
            105.83602329907238,
            21.02674569492755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602329907238,
              21.02674569492755
            ],
            [
              105.83601516236368,
              21.026738441401605
            ],
            [
              105.83599163754954,
              21.02670948229637
            ],
            [
              105.83594732842042,
              21.02665358285582
            ],
            [
              105.8359595880296,
              21.02661369496346
            ],
            [
              105.83598323826976,
              21.02659528391658
            ],
            [
              105.83594203441537,
              21.026539362885064
            ],
            [
              105.83586524192359,
              21.02643647393731
            ],
            [
              105.83583774731657,
              21.026399636535693
            ],
            [
              105.83579990893077,
              21.02634893942543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.7_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12150",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02674569492755,
          "to_latitude": 21.02634893942543,
          "to_longitude": 105.83579990893077,
          "from_longitude": 105.83602329907238
        }
      },
      {
        "id": 12094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358527925736,
            21.026728253722318,
            105.83592058349207,
            21.026753758867244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8358527925736,
              21.026753758867244
            ],
            [
              105.83588185319121,
              21.026739663034725
            ],
            [
              105.83589523608036,
              21.026751984055764
            ],
            [
              105.83592058349207,
              21.026728253722318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.8_Ngõ 51, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12151",
          "diaChiLapD": "Ngõ 51, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026753758867244,
          "to_latitude": 21.026728253722318,
          "to_longitude": 105.83592058349207,
          "from_longitude": 105.8358527925736
        }
      },
      {
        "id": 12095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357877564852,
            21.02683976730506,
            105.83589129715854,
            21.0268739750966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589129715854,
              21.026855416150983
            ],
            [
              105.83585335801648,
              21.0268739750966
            ],
            [
              105.8357877564852,
              21.02683976730506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.8_Ngõ 51, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12152",
          "diaChiLapD": "Ngõ 51, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026855416150983,
          "to_latitude": 21.02683976730506,
          "to_longitude": 105.8357877564852,
          "from_longitude": 105.83589129715854
        }
      },
      {
        "id": 12096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83579417313227,
            21.026614392319672,
            105.83592005796989,
            21.02690452031175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592005796989,
              21.02690452031175
            ],
            [
              105.83591593306579,
              21.026897480995643
            ],
            [
              105.83589129715854,
              21.026855416150983
            ],
            [
              105.83583305133749,
              21.026771725265725
            ],
            [
              105.8358527925736,
              21.026753758867244
            ],
            [
              105.83584858053229,
              21.02672556919946
            ],
            [
              105.83584286618081,
              21.026704687668744
            ],
            [
              105.83579417313227,
              21.026650839276535
            ],
            [
              105.83583186530309,
              21.026614392319672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.8_Ngõ 51, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12153",
          "diaChiLapD": "Ngõ 51, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02690452031175,
          "to_latitude": 21.026614392319672,
          "to_longitude": 105.83583186530309,
          "from_longitude": 105.83592005796989
        }
      },
      {
        "id": 12097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83476239745737,
            21.027054430961865,
            105.83480794105418,
            21.02710230403441
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83476239745737,
              21.027054430961865
            ],
            [
              105.83476263937666,
              21.027054685386542
            ],
            [
              105.83480794105418,
              21.02710230403441
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.13_Ngõ 65, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12154",
          "diaChiLapD": "Ngõ 65, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027054430961865,
          "to_latitude": 21.02710230403441,
          "to_longitude": 105.83480794105418,
          "from_longitude": 105.83476239745737
        }
      },
      {
        "id": 12098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466403031964,
            21.02700659146147,
            105.83477455350415,
            21.02726623952408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477417830485,
              21.02726623952408
            ],
            [
              105.83476885717711,
              21.02726049495108
            ],
            [
              105.83470777169923,
              21.027194554207476
            ],
            [
              105.83467157888634,
              21.027144442185957
            ],
            [
              105.83466403031964,
              21.027117202793665
            ],
            [
              105.83476239745737,
              21.027054430961865
            ],
            [
              105.83477455350415,
              21.027046673116885
            ],
            [
              105.8347425810998,
              21.02700659146147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.13_Ngõ 65, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12155",
          "diaChiLapD": "Ngõ 65, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02726623952408,
          "to_latitude": 21.02700659146147,
          "to_longitude": 105.8347425810998,
          "from_longitude": 105.83477417830485
        }
      },
      {
        "id": 12099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83469029051528,
            21.027113421449357,
            105.83489558919138,
            21.0274964493289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475378421954,
              21.0274964493289
            ],
            [
              105.83474581833993,
              21.027481863439935
            ],
            [
              105.83474145150817,
              21.027473870575196
            ],
            [
              105.83474481719693,
              21.027460152155886
            ],
            [
              105.83471649014801,
              21.027402170589795
            ],
            [
              105.83471519020294,
              21.027400913361262
            ],
            [
              105.83469107710931,
              21.027370977867587
            ],
            [
              105.83469029051528,
              21.027370000827993
            ],
            [
              105.83469512963165,
              21.027363643673244
            ],
            [
              105.83470832523174,
              21.02734631017526
            ],
            [
              105.8347619795185,
              21.02729189314327
            ],
            [
              105.83481205393595,
              21.02723381484057
            ],
            [
              105.83486394826758,
              21.02717656939327
            ],
            [
              105.83486724035401,
              21.02716587532464
            ],
            [
              105.83489558919138,
              21.027113421449357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.12_Ngõ 65, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12156",
          "diaChiLapD": "Ngõ 65, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0274964493289,
          "to_latitude": 21.027113421449357,
          "to_longitude": 105.83489558919138,
          "from_longitude": 105.83475378421954
        }
      },
      {
        "id": 12100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463907821898,
            21.027465978327413,
            105.83478429888925,
            21.027525920930966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463907821898,
              21.027525920930966
            ],
            [
              105.83473688542189,
              21.027485550892514
            ],
            [
              105.83474581833993,
              21.027481863439935
            ],
            [
              105.8347542320049,
              21.02747839079332
            ],
            [
              105.83476471116124,
              21.02747406349319
            ],
            [
              105.83478429888925,
              21.027465978327413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.11_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12157",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027525920930966,
          "to_latitude": 21.027465978327413,
          "to_longitude": 105.83478429888925,
          "from_longitude": 105.83463907821898
        }
      },
      {
        "id": 12101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467979783443,
            21.027206240190115,
            105.83482496255711,
            21.027500662658525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8347424184129,
              21.027500662658525
            ],
            [
              105.83473664029823,
              21.02748921285991
            ],
            [
              105.83473688542189,
              21.027485550892514
            ],
            [
              105.83473815045168,
              21.027466544476866
            ],
            [
              105.8347263294348,
              21.027440319607127
            ],
            [
              105.83470526886319,
              21.027402448766527
            ],
            [
              105.83467979783443,
              21.027368961236675
            ],
            [
              105.8347409534926,
              21.027302549617332
            ],
            [
              105.83477417830485,
              21.02726623952408
            ],
            [
              105.83478256602174,
              21.027257073083767
            ],
            [
              105.83482496255711,
              21.027206240190115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.13_Ngõ 65, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12158",
          "diaChiLapD": "Ngõ 65, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027500662658525,
          "to_latitude": 21.027206240190115,
          "to_longitude": 105.83482496255711,
          "from_longitude": 105.8347424184129
        }
      },
      {
        "id": 12102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83478429888925,
            21.02741225917559,
            105.83496527743134,
            21.027465978327413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496527743134,
              21.027423134178143
            ],
            [
              105.83496340625143,
              21.027420186212698
            ],
            [
              105.83495851067016,
              21.02741225917559
            ],
            [
              105.83479835401316,
              21.027461681341546
            ],
            [
              105.83478429888925,
              21.027465978327413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.11_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12159",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027423134178143,
          "to_latitude": 21.027465978327413,
          "to_longitude": 105.83478429888925,
          "from_longitude": 105.83496527743134
        }
      },
      {
        "id": 12103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390671202415,
            21.02687775057197,
            105.83400659197537,
            21.027099140319798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400659197537,
              21.027099140319798
            ],
            [
              105.83397622502171,
              21.027041361008916
            ],
            [
              105.8339504513689,
              21.02698335142456
            ],
            [
              105.83391637621287,
              21.02690279834349
            ],
            [
              105.83390671202415,
              21.02687775057197
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.18_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12160",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027099140319798,
          "to_latitude": 21.02687775057197,
          "to_longitude": 105.83390671202415,
          "from_longitude": 105.83400659197537
        }
      },
      {
        "id": 12104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8339880422167,
            21.027030470116017,
            105.83419951171506,
            21.027106699145712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339880422167,
              21.027106699145712
            ],
            [
              105.8339990374063,
              21.027102097491657
            ],
            [
              105.83400659197537,
              21.027099140319798
            ],
            [
              105.83418207014093,
              21.027030470116017
            ],
            [
              105.83419951171506,
              21.02706354911644
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.18_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12161",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027106699145712,
          "to_latitude": 21.02706354911644,
          "to_longitude": 105.83419951171506,
          "from_longitude": 105.8339880422167
        }
      },
      {
        "id": 12105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388521894324,
            21.026390280756555,
            105.8339546917609,
            21.026416538146755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388521894324,
              21.026416538146755
            ],
            [
              105.83392921580291,
              21.02639829315502
            ],
            [
              105.8339485354459,
              21.026390280756555
            ],
            [
              105.8339546917609,
              21.026402464858855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.20_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12162",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026416538146755,
          "to_latitude": 21.026402464858855,
          "to_longitude": 105.8339546917609,
          "from_longitude": 105.83388521894324
        }
      },
      {
        "id": 12106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363033497251,
            21.026210083539297,
            105.83382514501442,
            21.0264979353381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83382514501442,
              21.026234670574397
            ],
            [
              105.83381177588475,
              21.026210083539297
            ],
            [
              105.83376401273698,
              21.02623039005705
            ],
            [
              105.83363033497251,
              21.026287223199308
            ],
            [
              105.83365117704888,
              21.026332589307707
            ],
            [
              105.83372456215854,
              21.02649232683598
            ],
            [
              105.83372219241396,
              21.026493652644252
            ],
            [
              105.83371453460998,
              21.0264979353381
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.20_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12163",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026234670574397,
          "to_latitude": 21.0264979353381,
          "to_longitude": 105.83371453460998,
          "from_longitude": 105.83382514501442
        }
      },
      {
        "id": 12107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611464637703,
            21.025984123529987,
            105.83617646927476,
            21.026016933598918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611464637703,
              21.026016933598918
            ],
            [
              105.83611640932162,
              21.026015998224207
            ],
            [
              105.83617646927476,
              21.025984123529987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.7_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12164",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026016933598918,
          "to_latitude": 21.025984123529987,
          "to_longitude": 105.83617646927476,
          "from_longitude": 105.83611464637703
        }
      },
      {
        "id": 12108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629964569776,
            21.025774711593638,
            105.83635795387953,
            21.02585115727209
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635795387953,
              21.02585115727209
            ],
            [
              105.83630557996399,
              21.025783104064526
            ],
            [
              105.83629964569776,
              21.025774711593638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.12_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12165",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02585115727209,
          "to_latitude": 21.025774711593638,
          "to_longitude": 105.83629964569776,
          "from_longitude": 105.83635795387953
        }
      },
      {
        "id": 12109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607149399313,
            21.025843566074094,
            105.8362100811381,
            21.02615597892837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362100811381,
              21.02615597892837
            ],
            [
              105.83618823432936,
              21.026131024616603
            ],
            [
              105.83615797803516,
              21.026087504348396
            ],
            [
              105.83614616047471,
              21.026071408305558
            ],
            [
              105.83612048021295,
              21.02602836230316
            ],
            [
              105.83611464637703,
              21.026016933598918
            ],
            [
              105.83609275542955,
              21.025974054121857
            ],
            [
              105.83608169372253,
              21.02595238508318
            ],
            [
              105.83607149399313,
              21.025876038798632
            ],
            [
              105.83614351786937,
              21.025843566074094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.7_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12166",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02615597892837,
          "to_latitude": 21.025843566074094,
          "to_longitude": 105.83614351786937,
          "from_longitude": 105.8362100811381
        }
      },
      {
        "id": 12110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83637582673468,
            21.025590325815404,
            105.83643830242913,
            21.025641274101407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83637582673468,
              21.025641274101407
            ],
            [
              105.83643830242913,
              21.025590325815404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.14_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12167",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025641274101407,
          "to_latitude": 21.025590325815404,
          "to_longitude": 105.83643830242913,
          "from_longitude": 105.83637582673468
        }
      },
      {
        "id": 12111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629324695977,
            21.02547766227214,
            105.83637696205425,
            21.025539922176467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83637696205425,
              21.02547766227214
            ],
            [
              105.83629324695977,
              21.025539922176467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.13_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12168",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02547766227214,
          "to_latitude": 21.025539922176467,
          "to_longitude": 105.83629324695977,
          "from_longitude": 105.83637696205425
        }
      },
      {
        "id": 12112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602885057594,
            21.025447573245952,
            105.83620128091022,
            21.02580900744764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602885057594,
              21.025447573245952
            ],
            [
              105.83619522268093,
              21.025791152512518
            ],
            [
              105.8362009698937,
              21.02580809310855
            ],
            [
              105.83620128091022,
              21.02580900744764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.10_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12169",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025447573245952,
          "to_latitude": 21.02580900744764,
          "to_longitude": 105.83620128091022,
          "from_longitude": 105.83602885057594
        }
      },
      {
        "id": 12113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8358184867104,
            21.025941168242895,
            105.83589321823683,
            21.02605160215724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83589321823683,
              21.02605160215724
            ],
            [
              105.83587164359442,
              21.026025241049368
            ],
            [
              105.83584661874092,
              21.025994653553226
            ],
            [
              105.8358184867104,
              21.025957696438745
            ],
            [
              105.83584405405493,
              21.025941168242895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.8_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12170",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02605160215724,
          "to_latitude": 21.025941168242895,
          "to_longitude": 105.83584405405493,
          "from_longitude": 105.83589321823683
        }
      },
      {
        "id": 12114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360485659468,
            21.02576136403877,
            105.83627534419723,
            21.02591769403176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627534419723,
              21.02576136403877
            ],
            [
              105.83620692484568,
              21.025793634836305
            ],
            [
              105.83619522268093,
              21.025791152512518
            ],
            [
              105.83618962834224,
              21.025801791989796
            ],
            [
              105.83610594268067,
              21.025841259726445
            ],
            [
              105.83610186561975,
              21.02584451963307
            ],
            [
              105.8360485659468,
              21.02588713471297
            ],
            [
              105.83605010433858,
              21.02591769403176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.9_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12171",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02576136403877,
          "to_latitude": 21.02591769403176,
          "to_longitude": 105.83605010433858,
          "from_longitude": 105.83627534419723
        }
      },
      {
        "id": 12115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355902177333,
            21.025927870565486,
            105.83607216785954,
            21.026338405011995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83607216785954,
              21.025927870565486
            ],
            [
              105.83607036742363,
              21.0259286381245
            ],
            [
              105.8360050647996,
              21.025966077119158
            ],
            [
              105.83601041342236,
              21.02599814325149
            ],
            [
              105.83596817703192,
              21.026021495071483
            ],
            [
              105.83591882162547,
              21.02604878289577
            ],
            [
              105.83589321823683,
              21.02605160215724
            ],
            [
              105.83563524227222,
              21.02619392004716
            ],
            [
              105.83567710685776,
              21.026250884750162
            ],
            [
              105.83561186743005,
              21.02629608017037
            ],
            [
              105.83561152115023,
              21.026321457930457
            ],
            [
              105.8355902177333,
              21.026338405011995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.8_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12172",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025927870565486,
          "to_latitude": 21.026338405011995,
          "to_longitude": 105.8355902177333,
          "from_longitude": 105.83607216785954
        }
      },
      {
        "id": 12116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364663491673,
            21.025637857281755,
            105.83658790665723,
            21.025829860080638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83658790665723,
              21.025637857281755
            ],
            [
              105.83654381083151,
              21.02565774639734
            ],
            [
              105.83650590860975,
              21.025678198454404
            ],
            [
              105.83648452863011,
              21.025696561371117
            ],
            [
              105.8364663491673,
              21.025721810975085
            ],
            [
              105.8364866989531,
              21.025758120712442
            ],
            [
              105.83650355156817,
              21.025784893353908
            ],
            [
              105.83651689642917,
              21.025804543268414
            ],
            [
              105.83653177541194,
              21.02582535079332
            ],
            [
              105.83653444896473,
              21.025829860080638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.1_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12173",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025637857281755,
          "to_latitude": 21.025829860080638,
          "to_longitude": 105.83653444896473,
          "from_longitude": 105.83658790665723
        }
      },
      {
        "id": 12117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83644356635557,
            21.02580295305042,
            105.83649497219838,
            21.02583203316793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83644356635557,
              21.02583203316793
            ],
            [
              105.83649497219838,
              21.02580295305042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.2_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12174",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02583203316793,
          "to_latitude": 21.02580295305042,
          "to_longitude": 105.83649497219838,
          "from_longitude": 105.83644356635557
        }
      },
      {
        "id": 12118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364041959018,
            21.025881996123633,
            105.83659433140207,
            21.026039198932157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83644930329325,
              21.026039198932157
            ],
            [
              105.8364041959018,
              21.02598350043453
            ],
            [
              105.83640610744501,
              21.02596126541982
            ],
            [
              105.83654928247256,
              21.025881996123633
            ],
            [
              105.8365794698113,
              21.025920043127947
            ],
            [
              105.83659387858434,
              21.0259129578153
            ],
            [
              105.83659433140207,
              21.025912734298053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.2_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12175",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026039198932157,
          "to_latitude": 21.025912734298053,
          "to_longitude": 105.83659433140207,
          "from_longitude": 105.83644930329325
        }
      },
      {
        "id": 12119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623262344324,
            21.025782662061708,
            105.83638802180644,
            21.02597003838815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83624870685736,
              21.025782662061708
            ],
            [
              105.83625089259266,
              21.02578646833517
            ],
            [
              105.83623262344324,
              21.025796510786577
            ],
            [
              105.83623352221852,
              21.025798455522853
            ],
            [
              105.83623973472349,
              21.025811889323304
            ],
            [
              105.83624039107356,
              21.025815213570276
            ],
            [
              105.83626405857001,
              21.025865974850706
            ],
            [
              105.83636828330908,
              21.02597003838815
            ],
            [
              105.83638802180644,
              21.025967647125054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.11_Lương Sử B",
          "maTaiSan": "04.O14.DODV.12176",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025782662061708,
          "to_latitude": 21.025967647125054,
          "to_longitude": 105.83638802180644,
          "from_longitude": 105.83624870685736
        }
      },
      {
        "id": 12120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647041775365,
            21.025762379104734,
            105.83654928247256,
            21.025881996123633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83654928247256,
              21.025881996123633
            ],
            [
              105.83654824181555,
              21.025880485634218
            ],
            [
              105.83649584508252,
              21.02580439481724
            ],
            [
              105.83649497219838,
              21.02580295305042
            ],
            [
              105.83647041775365,
              21.025762379104734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.2_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12177",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025881996123633,
          "to_latitude": 21.025762379104734,
          "to_longitude": 105.83647041775365,
          "from_longitude": 105.83654928247256
        }
      },
      {
        "id": 12121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650131492507,
            21.02603605538463,
            105.83667356040935,
            21.026172290454944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650131492507,
              21.026172290454944
            ],
            [
              105.83652449170316,
              21.026141290733793
            ],
            [
              105.83667356040935,
              21.02603605538463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.4_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12178",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026172290454944,
          "to_latitude": 21.02603605538463,
          "to_longitude": 105.83667356040935,
          "from_longitude": 105.83650131492507
        }
      },
      {
        "id": 12122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650994426327,
            21.025922779000677,
            105.83666603302908,
            21.026026627542784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650994426327,
              21.025981986757316
            ],
            [
              105.83653762842536,
              21.025951409249025
            ],
            [
              105.83658350721025,
              21.025922779000677
            ],
            [
              105.83666603302908,
              21.026026627542784
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.4_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12179",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025981986757316,
          "to_latitude": 21.026026627542784,
          "to_longitude": 105.83666603302908,
          "from_longitude": 105.83650994426327
        }
      },
      {
        "id": 12123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666603302908,
            21.026026627542784,
            105.83672985403504,
            21.026106566724877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666603302908,
              21.026026627542784
            ],
            [
              105.83667356040935,
              21.02603605538463
            ],
            [
              105.8366910971885,
              21.02605802220138
            ],
            [
              105.83672985403504,
              21.026106566724877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.4_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12180",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026026627542784,
          "to_latitude": 21.026106566724877,
          "to_longitude": 105.83672985403504,
          "from_longitude": 105.83666603302908
        }
      },
      {
        "id": 12124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662751029792,
            21.026169478449443,
            105.83669430069698,
            21.026245389110528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669430069698,
              21.026245389110528
            ],
            [
              105.83669304050918,
              21.026244016992198
            ],
            [
              105.83667968756556,
              21.026229480280165
            ],
            [
              105.8366659759784,
              21.026224628786593
            ],
            [
              105.83662751029792,
              21.026169478449443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.6_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12181",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026245389110528,
          "to_latitude": 21.026169478449443,
          "to_longitude": 105.83662751029792,
          "from_longitude": 105.83669430069698
        }
      },
      {
        "id": 12125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676792307087,
            21.026177171331334,
            105.83684054366343,
            21.026272794992583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684054366343,
              21.026272794992583
            ],
            [
              105.8367697101306,
              21.02617952266021
            ],
            [
              105.83676920328067,
              21.026178855865798
            ],
            [
              105.83676792307087,
              21.026177171331334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.6_Lương Sử A",
          "maTaiSan": "04.O14.DODV.12182",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026272794992583,
          "to_latitude": 21.026177171331334,
          "to_longitude": 105.83676792307087,
          "from_longitude": 105.83684054366343
        }
      },
      {
        "id": 12126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723287043922,
            21.025958981930714,
            105.83735345589378,
            21.026185530755246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735345589378,
              21.026185530755246
            ],
            [
              105.83728707947886,
              21.026069770832567
            ],
            [
              105.83725548978616,
              21.026013718790747
            ],
            [
              105.83725177468781,
              21.026004789753667
            ],
            [
              105.83724829880694,
              21.02599494186413
            ],
            [
              105.83723287043922,
              21.025958981930714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.1_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12183",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026185530755246,
          "to_latitude": 21.025958981930714,
          "to_longitude": 105.83723287043922,
          "from_longitude": 105.83735345589378
        }
      },
      {
        "id": 12127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723992855342,
            21.025917451308803,
            105.83732131347949,
            21.02611251350161
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83732131347949,
              21.02611251350161
            ],
            [
              105.83725880241191,
              21.02600346444849
            ],
            [
              105.83724634615135,
              21.02596070125937
            ],
            [
              105.83723992855342,
              21.025917451308803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.2_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12184",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02611251350161,
          "to_latitude": 21.025917451308803,
          "to_longitude": 105.83723992855342,
          "from_longitude": 105.83732131347949
        }
      },
      {
        "id": 12128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716669722914,
            21.02566751064208,
            105.83723992855342,
            21.025917451308803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723992855342,
              21.025917451308803
            ],
            [
              105.83722748162675,
              21.02581968449635
            ],
            [
              105.83722652882604,
              21.025812202371014
            ],
            [
              105.83718760674601,
              21.0257001468371
            ],
            [
              105.83716669722914,
              21.02566751064208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.2_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12185",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025917451308803,
          "to_latitude": 21.02566751064208,
          "to_longitude": 105.83716669722914,
          "from_longitude": 105.83723992855342
        }
      },
      {
        "id": 12129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721779368992,
            21.025825077110035,
            105.83722336979662,
            21.025901637006903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722336979662,
              21.025901637006903
            ],
            [
              105.83722023181608,
              21.025850144353537
            ],
            [
              105.83721779368992,
              21.025825609463098
            ],
            [
              105.83722005569768,
              21.025825153941426
            ],
            [
              105.8372204342972,
              21.025825077110035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.3_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12186",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025901637006903,
          "to_latitude": 21.025825077110035,
          "to_longitude": 105.8372204342972,
          "from_longitude": 105.83722336979662
        }
      },
      {
        "id": 12130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83686790008805,
            21.02554959993178,
            105.83692998818341,
            21.025700312717614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687075967453,
              21.02554959993178
            ],
            [
              105.83687170632916,
              21.025551676299507
            ],
            [
              105.83686790008805,
              21.025560363262407
            ],
            [
              105.83687870398714,
              21.025567026464397
            ],
            [
              105.83692998818341,
              21.025693505327293
            ],
            [
              105.83691406178313,
              21.025700312717614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.4_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12187",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02554959993178,
          "to_latitude": 21.025700312717614,
          "to_longitude": 105.83691406178313,
          "from_longitude": 105.83687075967453
        }
      },
      {
        "id": 12131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83688006996597,
            21.025556330999592,
            105.83693648348876,
            21.025686245770267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83693648348876,
              21.025686245770267
            ],
            [
              105.83688006996597,
              21.025556330999592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.5_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12188",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025686245770267,
          "to_latitude": 21.025556330999592,
          "to_longitude": 105.83688006996597,
          "from_longitude": 105.83693648348876
        }
      },
      {
        "id": 12132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83680767293791,
            21.025533837229588,
            105.83709916861254,
            21.02575244254022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83709916861254,
              21.02575244254022
            ],
            [
              105.83709866198578,
              21.02575129884387
            ],
            [
              105.8370670434026,
              21.025680571334142
            ],
            [
              105.83702521198511,
              21.02569405009154
            ],
            [
              105.83699108669248,
              21.025599582844762
            ],
            [
              105.836976540039,
              21.025554220507246
            ],
            [
              105.83694795874413,
              21.025533837229588
            ],
            [
              105.83688006996597,
              21.025556330999592
            ],
            [
              105.83686790008805,
              21.025560363262407
            ],
            [
              105.83686281090033,
              21.025562049333185
            ],
            [
              105.8368260955689,
              21.025587275476344
            ],
            [
              105.83683467275804,
              21.025620881043015
            ],
            [
              105.83683223163888,
              21.025686965635455
            ],
            [
              105.83680767293791,
              21.025720783929913
            ],
            [
              105.83682091467848,
              21.02574659882513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.5_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12189",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02575244254022,
          "to_latitude": 21.02574659882513,
          "to_longitude": 105.83682091467848,
          "from_longitude": 105.83709916861254
        }
      },
      {
        "id": 12133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83679937880875,
            21.025528452717406,
            105.83710814145165,
            21.025750076186295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710814145165,
              21.025750076186295
            ],
            [
              105.83710605756514,
              21.025745734715024
            ],
            [
              105.83707104763745,
              21.02567633357287
            ],
            [
              105.83702881057035,
              21.02568762852784
            ],
            [
              105.83699848212507,
              21.02561070667192
            ],
            [
              105.83698371073496,
              21.025552660575457
            ],
            [
              105.8369687833464,
              21.02554145368752
            ],
            [
              105.83695097629894,
              21.025528452717406
            ],
            [
              105.83687075967453,
              21.02554959993178
            ],
            [
              105.83685808281774,
              21.025552941916548
            ],
            [
              105.83683799237502,
              21.025566377770797
            ],
            [
              105.83681136863727,
              21.025586300203276
            ],
            [
              105.83682893713862,
              21.025622056359776
            ],
            [
              105.83682692387274,
              21.0256772414595
            ],
            [
              105.83679937880875,
              21.025717208236227
            ],
            [
              105.83681418333718,
              21.025750006389433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.4_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12190",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025750076186295,
          "to_latitude": 21.025750006389433,
          "to_longitude": 105.83681418333718,
          "from_longitude": 105.83710814145165
        }
      },
      {
        "id": 12134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83690550470672,
            21.025790235240372,
            105.83698549561151,
            21.025808086904433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83690550470672,
              21.02579593841681
            ],
            [
              105.83695088318265,
              21.025790235240372
            ],
            [
              105.83698114999251,
              21.02579839141774
            ],
            [
              105.8369848293002,
              21.025806599865653
            ],
            [
              105.83698549561151,
              21.025808086904433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.6_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.12191",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02579593841681,
          "to_latitude": 21.025808086904433,
          "to_longitude": 105.83698549561151,
          "from_longitude": 105.83690550470672
        }
      },
      {
        "id": 12135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83745559232098,
            21.02510154402512,
            105.83771468072649,
            21.025439806846812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377058507831,
              21.025439806846812
            ],
            [
              105.83770943986032,
              21.02542996842364
            ],
            [
              105.83771468072649,
              21.025399305282725
            ],
            [
              105.83769826362644,
              21.02537148107467
            ],
            [
              105.83765923843332,
              21.025310568453452
            ],
            [
              105.8376160103826,
              21.02530304616638
            ],
            [
              105.83754479258603,
              21.025205390891795
            ],
            [
              105.83752960656624,
              21.025184567807162
            ],
            [
              105.83751338326479,
              21.02516232183352
            ],
            [
              105.83745559232098,
              21.02510154402512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.7_Lương Sử C",
          "maTaiSan": "04.O14.DODV.12192",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025439806846812,
          "to_latitude": 21.02510154402512,
          "to_longitude": 105.83745559232098,
          "from_longitude": 105.8377058507831
        }
      },
      {
        "id": 12136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225347492407,
            21.021530334081778,
            105.83233929986307,
            21.021618448168137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83233929986307,
              21.021618448168137
            ],
            [
              105.83225347492407,
              21.021530334081778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12193",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021618448168137,
          "to_latitude": 21.021530334081778,
          "to_longitude": 105.83225347492407,
          "from_longitude": 105.83233929986307
        }
      },
      {
        "id": 12137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83037263068529,
            21.01918897348103,
            105.83046996048616,
            21.01921892266185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83037263068529,
              21.01921892266185
            ],
            [
              105.83046996048616,
              21.01918897348103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12194",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01921892266185,
          "to_latitude": 21.01918897348103,
          "to_longitude": 105.83046996048616,
          "from_longitude": 105.83037263068529
        }
      },
      {
        "id": 12138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83045068199957,
            21.019143314740717,
            105.8305830174571,
            21.01918897348103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83045068199957,
              21.019143314740717
            ],
            [
              105.83045073074146,
              21.019143431018783
            ],
            [
              105.83046996048616,
              21.01918897348103
            ],
            [
              105.8305830174571,
              21.019154183846318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12195",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019143314740717,
          "to_latitude": 21.019154183846318,
          "to_longitude": 105.8305830174571,
          "from_longitude": 105.83045068199957
        }
      },
      {
        "id": 12139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83060687958263,
            21.019145627598498,
            105.83071124152606,
            21.019168167015746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83060687958263,
              21.019145627598498
            ],
            [
              105.83066767981524,
              21.01916482500985
            ],
            [
              105.83067768216081,
              21.019157162883086
            ],
            [
              105.83069929294221,
              21.019168167015746
            ],
            [
              105.83071003617815,
              21.019151160117328
            ],
            [
              105.83071124152606,
              21.019149252039416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12196",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019145627598498,
          "to_latitude": 21.019149252039416,
          "to_longitude": 105.83071124152606,
          "from_longitude": 105.83060687958263
        }
      },
      {
        "id": 12140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83069929294221,
            21.019168167015746,
            105.83095019420867,
            21.019292814080625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83069929294221,
              21.019168167015746
            ],
            [
              105.83081401959471,
              21.019226584590772
            ],
            [
              105.83087490636407,
              21.019257744716548
            ],
            [
              105.83089725073296,
              21.01926815272442
            ],
            [
              105.83091094399131,
              21.01927453124998
            ],
            [
              105.8309280832264,
              21.01928251423011
            ],
            [
              105.83095019420867,
              21.019292814080625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12197",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019168167015746,
          "to_latitude": 21.019292814080625,
          "to_longitude": 105.83095019420867,
          "from_longitude": 105.83069929294221
        }
      },
      {
        "id": 12141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83036969575856,
            21.019239946903838,
            105.8304674759407,
            21.019456241823384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83036969575856,
              21.019239946903838
            ],
            [
              105.83038726247841,
              21.01927423608504
            ],
            [
              105.83040676569387,
              21.01931861205872
            ],
            [
              105.83042020919237,
              21.019348161368892
            ],
            [
              105.83042860775456,
              21.019363738030243
            ],
            [
              105.83044174944327,
              21.019388118752396
            ],
            [
              105.83045756534945,
              21.01942030924851
            ],
            [
              105.8304674759407,
              21.01943984819916
            ],
            [
              105.83046393910823,
              21.019441465980876
            ],
            [
              105.83043008483956,
              21.019456241823384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12198",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019239946903838,
          "to_latitude": 21.019456241823384,
          "to_longitude": 105.83043008483956,
          "from_longitude": 105.83036969575856
        }
      },
      {
        "id": 12142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8304674759407,
            21.01943984819916,
            105.8305670073839,
            21.01965767892093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8305670073839,
              21.01965767892093
            ],
            [
              105.83056002947349,
              21.01964120926762
            ],
            [
              105.83050172468586,
              21.0195129803981
            ],
            [
              105.83048705786894,
              21.019478453073436
            ],
            [
              105.8304674759407,
              21.01943984819916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12199",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01965767892093,
          "to_latitude": 21.01943984819916,
          "to_longitude": 105.8304674759407,
          "from_longitude": 105.8305670073839
        }
      },
      {
        "id": 12143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83069677811186,
            21.019905311929747,
            105.83081274595364,
            21.020186892659588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83077770015565,
              21.020186892659588
            ],
            [
              105.83079682380647,
              21.020175562237732
            ],
            [
              105.83081274595364,
              21.020166127964846
            ],
            [
              105.83074762335606,
              21.020020721550885
            ],
            [
              105.83069677811186,
              21.019905311929747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12200",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020186892659588,
          "to_latitude": 21.019905311929747,
          "to_longitude": 105.83069677811186,
          "from_longitude": 105.83077770015565
        }
      },
      {
        "id": 12144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8305756213063,
            21.01966819104348,
            105.83068169430392,
            21.019880726709303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068169430392,
              21.019880726709303
            ],
            [
              105.83067197128962,
              21.019864452500734
            ],
            [
              105.83065990134581,
              21.019840980082996
            ],
            [
              105.83064260764091,
              21.019805916445595
            ],
            [
              105.83061497434366,
              21.019752097824615
            ],
            [
              105.8306099452064,
              21.019743647748285
            ],
            [
              105.83060164789254,
              21.01972684221874
            ],
            [
              105.8305756213063,
              21.01966819104348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12201",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": 26.003,
          "from_latitude": 21.019880726709303,
          "to_latitude": 21.01966819104348,
          "to_longitude": 105.8305756213063,
          "from_longitude": 105.83068169430392
        }
      },
      {
        "id": 12145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8310598018757,
            21.020613155132093,
            105.83126602579917,
            21.020775189367217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8310598018757,
              21.020775189367217
            ],
            [
              105.83107740673275,
              21.02077203600062
            ],
            [
              105.83111714506676,
              21.020748486253275
            ],
            [
              105.8311853893255,
              21.020715717085462
            ],
            [
              105.83126602579917,
              21.020659027748277
            ],
            [
              105.83126464677403,
              21.02063151503958
            ],
            [
              105.83126373501973,
              21.020613155132093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.5_Ngõ 225, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12204",
          "diaChiLapD": "Ngõ 225, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020775189367217,
          "to_latitude": 21.020613155132093,
          "to_longitude": 105.83126373501973,
          "from_longitude": 105.8310598018757
        }
      },
      {
        "id": 12146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83119836528108,
            21.020150976584496,
            105.83124650971871,
            21.02026047227725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83119836528108,
              21.020150976584496
            ],
            [
              105.83119908266232,
              21.020152320690205
            ],
            [
              105.83122558523725,
              21.020212787622743
            ],
            [
              105.83124650971871,
              21.02026047227725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.2_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12205",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020150976584496,
          "to_latitude": 21.02026047227725,
          "to_longitude": 105.83124650971871,
          "from_longitude": 105.83119836528108
        }
      },
      {
        "id": 12147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124650971871,
            21.02026047227725,
            105.83127611486154,
            21.020323054745184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124650971871,
              21.02026047227725
            ],
            [
              105.83126857712139,
              21.02030921443854
            ],
            [
              105.8312720155567,
              21.020315527433333
            ],
            [
              105.83127449295495,
              21.020319925772256
            ],
            [
              105.83127600309678,
              21.020322849356162
            ],
            [
              105.83127611486154,
              21.020323054745184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.2_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12206",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02026047227725,
          "to_latitude": 21.020323054745184,
          "to_longitude": 105.83127611486154,
          "from_longitude": 105.83124650971871
        }
      },
      {
        "id": 12148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83103382169529,
            21.020165033152928,
            105.83118900341837,
            21.020210650936427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83103382169529,
              21.020210650936427
            ],
            [
              105.83109240333604,
              21.020193430160102
            ],
            [
              105.83118900341837,
              21.020165033152928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.1_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12207",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020210650936427,
          "to_latitude": 21.020165033152928,
          "to_longitude": 105.83118900341837,
          "from_longitude": 105.83103382169529
        }
      },
      {
        "id": 12149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83086087139199,
            21.020295682682217,
            105.83097225064583,
            21.02053573147104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83086087139199,
              21.020295682682217
            ],
            [
              105.83097225064583,
              21.02053573147104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.6_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12208",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020295682682217,
          "to_latitude": 21.02053573147104,
          "to_longitude": 105.83097225064583,
          "from_longitude": 105.83086087139199
        }
      },
      {
        "id": 12150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83094818921172,
            21.02053573147104,
            105.83107656341035,
            21.020755258207036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83094818921172,
              21.020545331972627
            ],
            [
              105.83097225064583,
              21.02053573147104
            ],
            [
              105.83107656341035,
              21.020755258207036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.6_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12209",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020545331972627,
          "to_latitude": 21.020755258207036,
          "to_longitude": 105.83107656341035,
          "from_longitude": 105.83094818921172
        }
      },
      {
        "id": 12151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83144305787107,
            21.020491096205053,
            105.83161460878955,
            21.020544374417064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161460878955,
              21.020491096205053
            ],
            [
              105.8315692550847,
              21.02050015598882
            ],
            [
              105.831573993111,
              21.020537165033737
            ],
            [
              105.83150418550359,
              21.020544374417064
            ],
            [
              105.83149494275641,
              21.020491953991662
            ],
            [
              105.83144305787107,
              21.0205006003789
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.4_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12210",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020491096205053,
          "to_latitude": 21.0205006003789,
          "to_longitude": 105.83144305787107,
          "from_longitude": 105.83161460878955
        }
      },
      {
        "id": 12152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83147772130368,
            21.02015964911539,
            105.83163414847927,
            21.020246063013833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83163414847927,
              21.02015964911539
            ],
            [
              105.83151225493751,
              21.020228712160414
            ],
            [
              105.83147772130368,
              21.020246063013833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12211",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02015964911539,
          "to_latitude": 21.020246063013833,
          "to_longitude": 105.83147772130368,
          "from_longitude": 105.83163414847927
        }
      },
      {
        "id": 12153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83120456822307,
            21.020491860297874,
            105.83129574958078,
            21.020525063966897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83129574958078,
              21.02049641134049
            ],
            [
              105.83129195492783,
              21.02049802949648
            ],
            [
              105.83122917476423,
              21.020523508269893
            ],
            [
              105.83122048403939,
              21.020524668931664
            ],
            [
              105.83121727517772,
              21.020525063966897
            ],
            [
              105.83120456822307,
              21.020491860297874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12212",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02049641134049,
          "to_latitude": 21.020491860297874,
          "to_longitude": 105.83120456822307,
          "from_longitude": 105.83129574958078
        }
      },
      {
        "id": 12154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83131176162269,
            21.020507044838194,
            105.83137303247476,
            21.02052887446306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83131176162269,
              21.02052887446306
            ],
            [
              105.8313147253045,
              21.02052832797935
            ],
            [
              105.83134178088719,
              21.020523334593282
            ],
            [
              105.83135725552262,
              21.02051160916957
            ],
            [
              105.83137303247476,
              21.020507044838194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.4_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12213",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02052887446306,
          "to_latitude": 21.020507044838194,
          "to_longitude": 105.83137303247476,
          "from_longitude": 105.83131176162269
        }
      },
      {
        "id": 12155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141222794896,
            21.020666805106803,
            105.83156488935384,
            21.02069535426423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83156488935384,
              21.020666805106803
            ],
            [
              105.83151508420804,
              21.02068655733495
            ],
            [
              105.83146806573455,
              21.02069535426423
            ],
            [
              105.83141222794896,
              21.020680213675796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12214",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020666805106803,
          "to_latitude": 21.020680213675796,
          "to_longitude": 105.83141222794896,
          "from_longitude": 105.83156488935384
        }
      },
      {
        "id": 12156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83151481792402,
            21.02072201054346,
            105.83159142167526,
            21.02078082350763
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83159142167526,
              21.02072201054346
            ],
            [
              105.83151481792402,
              21.020762748339667
            ],
            [
              105.83152501306847,
              21.02078082350763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12215",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02072201054346,
          "to_latitude": 21.02078082350763,
          "to_longitude": 105.83152501306847,
          "from_longitude": 105.83159142167526
        }
      },
      {
        "id": 12157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83155031570418,
            21.0206262746339,
            105.83170777259521,
            21.020781326879945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83155031570418,
              21.020636481040288
            ],
            [
              105.83161478704089,
              21.0206262746339
            ],
            [
              105.83164835613296,
              21.02065580040296
            ],
            [
              105.83170777259521,
              21.020749361241318
            ],
            [
              105.8316489224351,
              21.020781326879945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12216",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020636481040288,
          "to_latitude": 21.020781326879945,
          "to_longitude": 105.8316489224351,
          "from_longitude": 105.83155031570418
        }
      },
      {
        "id": 12158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83140221942003,
            21.02028180685963,
            105.83143268770858,
            21.020357610094383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140221942003,
              21.02028180685963
            ],
            [
              105.83140453409924,
              21.020287566248232
            ],
            [
              105.83143268770858,
              21.020357610094383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12217",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02028180685963,
          "to_latitude": 21.020357610094383,
          "to_longitude": 105.83143268770858,
          "from_longitude": 105.83140221942003
        }
      },
      {
        "id": 12159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83130414782795,
            21.02023163249063,
            105.83147772130368,
            21.020326228062977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83130414782795,
              21.020326228062977
            ],
            [
              105.83139858004677,
              21.020283496516843
            ],
            [
              105.83140221942003,
              21.02028180685963
            ],
            [
              105.83146029683287,
              21.020254845337497
            ],
            [
              105.83147772130368,
              21.020246063013833
            ],
            [
              105.83146928585718,
              21.020232711232932
            ],
            [
              105.83146860649923,
              21.02023163249063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12218",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020326228062977,
          "to_latitude": 21.02023163249063,
          "to_longitude": 105.83146860649923,
          "from_longitude": 105.83130414782795
        }
      },
      {
        "id": 12160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8315154185472,
            21.02025017406797,
            105.83161767179307,
            21.020308808203676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8315154185472,
              21.020308808203676
            ],
            [
              105.8315371005129,
              21.020296871775088
            ],
            [
              105.83155106859319,
              21.02028877563015
            ],
            [
              105.83161767179307,
              21.02025017406797
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12219",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020308808203676,
          "to_latitude": 21.02025017406797,
          "to_longitude": 105.83161767179307,
          "from_longitude": 105.8315154185472
        }
      },
      {
        "id": 12161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83151225493751,
            21.020228712160414,
            105.83155106859319,
            21.02028877563015
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83155106859319,
              21.02028877563015
            ],
            [
              105.83151768664787,
              21.020237119919496
            ],
            [
              105.83151693855106,
              21.02023596202965
            ],
            [
              105.83151225493751,
              21.020228712160414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12220",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02028877563015,
          "to_latitude": 21.020228712160414,
          "to_longitude": 105.83151225493751,
          "from_longitude": 105.83155106859319
        }
      },
      {
        "id": 12162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109151362139,
            21.020779947064124,
            105.83114460020182,
            21.020904369921467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109151362139,
              21.020779947064124
            ],
            [
              105.83114460020182,
              21.02089436649112
            ],
            [
              105.83113816512369,
              21.02089706154464
            ],
            [
              105.83112071026297,
              21.020904369921467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12221",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020779947064124,
          "to_latitude": 21.020904369921467,
          "to_longitude": 105.83112071026297,
          "from_longitude": 105.83109151362139
        }
      },
      {
        "id": 12163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83114460020182,
            21.02089436649112,
            105.83122838549667,
            21.021068343541206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114460020182,
              21.02089436649112
            ],
            [
              105.83115524361746,
              21.020917306065826
            ],
            [
              105.83122838549667,
              21.021068343541206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12222",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02089436649112,
          "to_latitude": 21.021068343541206,
          "to_longitude": 105.83122838549667,
          "from_longitude": 105.83114460020182
        }
      },
      {
        "id": 12164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83134880486911,
            21.02132769040398,
            105.83136867509646,
            21.021367305522112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83134880486911,
              21.02132769040398
            ],
            [
              105.83136867509646,
              21.021367305522112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12223",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": 4.848,
          "from_latitude": 21.02132769040398,
          "to_latitude": 21.021367305522112,
          "to_longitude": 105.83136867509646,
          "from_longitude": 105.83134880486911
        }
      },
      {
        "id": 12165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128466120257,
            21.02119056654492,
            105.83134880486911,
            21.021327690403975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83134880486911,
              21.021327690403975
            ],
            [
              105.83133270289271,
              21.021292904636535
            ],
            [
              105.83131131701944,
              21.021246705352908
            ],
            [
              105.83128466120257,
              21.02119056654492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12224",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021327690403975,
          "to_latitude": 21.02119056654492,
          "to_longitude": 105.83128466120257,
          "from_longitude": 105.83134880486911
        }
      },
      {
        "id": 12166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133270289271,
            21.021270961219738,
            105.83138953294939,
            21.021292904636535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133270289271,
              21.021292904636535
            ],
            [
              105.83138953294939,
              21.021270961219738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12225",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021292904636535,
          "to_latitude": 21.021270961219738,
          "to_longitude": 105.83138953294939,
          "from_longitude": 105.83133270289271
        }
      },
      {
        "id": 12167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314447518517,
            21.0210592473439,
            105.83161741328098,
            21.02131751579001
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161741328098,
              21.02131751579001
            ],
            [
              105.8316167634314,
              21.02131655522675
            ],
            [
              105.83153667575623,
              21.02119798668639
            ],
            [
              105.8314447518517,
              21.0210592473439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.4_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12227",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02131751579001,
          "to_latitude": 21.0210592473439,
          "to_longitude": 105.8314447518517,
          "from_longitude": 105.83161741328098
        }
      },
      {
        "id": 12168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83149319026379,
            21.021307159600582,
            105.83160037989802,
            21.021351259698346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83149319026379,
              21.021351259698346
            ],
            [
              105.83160037989802,
              21.021307159600582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.2_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12229",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021351259698346,
          "to_latitude": 21.021307159600582,
          "to_longitude": 105.83160037989802,
          "from_longitude": 105.83149319026379
        }
      },
      {
        "id": 12169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83140093638384,
            21.021351259698346,
            105.83149748275828,
            21.021384987255782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140093638384,
              21.021384987255782
            ],
            [
              105.83149319026379,
              21.021351259698346
            ],
            [
              105.83149739226539,
              21.021359951660443
            ],
            [
              105.83149748275828,
              21.021360137282326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.2_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12230",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021384987255782,
          "to_latitude": 21.021360137282326,
          "to_longitude": 105.83149748275828,
          "from_longitude": 105.83140093638384
        }
      },
      {
        "id": 12170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83184401807725,
            21.021075985442856,
            105.83200564072193,
            21.02124336214564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200564072193,
              21.021075985442856
            ],
            [
              105.83186861679228,
              21.021194133528166
            ],
            [
              105.83185162884672,
              21.021229771138206
            ],
            [
              105.83184401807725,
              21.02124336214564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.9_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12231",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021075985442856,
          "to_latitude": 21.02124336214564,
          "to_longitude": 105.83184401807725,
          "from_longitude": 105.83200564072193
        }
      },
      {
        "id": 12171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164590682449,
            21.0212652184929,
            105.8316904752617,
            21.02129147741672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83164590682449,
              21.02129147741672
            ],
            [
              105.83168052882888,
              21.0212652184929
            ],
            [
              105.8316904752617,
              21.02127451732883
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.5_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12232",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02129147741672,
          "to_latitude": 21.02127451732883,
          "to_longitude": 105.8316904752617,
          "from_longitude": 105.83164590682449
        }
      },
      {
        "id": 12172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177966420193,
            21.020889442146498,
            105.83182760592034,
            21.020945797424492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83177966420193,
              21.020889442146498
            ],
            [
              105.83182760592034,
              21.020945797424492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.7_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12233",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020889442146498,
          "to_latitude": 21.020945797424492,
          "to_longitude": 105.83182760592034,
          "from_longitude": 105.83177966420193
        }
      },
      {
        "id": 12173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187686450708,
            21.020979417506087,
            105.83192101311674,
            21.02102083932823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83187686450708,
              21.020979417506087
            ],
            [
              105.83192101311674,
              21.02102083932823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.8_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12234",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020979417506087,
          "to_latitude": 21.02102083932823,
          "to_longitude": 105.83192101311674,
          "from_longitude": 105.83187686450708
        }
      },
      {
        "id": 12174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83186861679228,
            21.021194133528166,
            105.83191369660236,
            21.021238666919626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83186861679228,
              21.021194133528166
            ],
            [
              105.83191369660236,
              21.021238666919626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.9_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12235",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021194133528166,
          "to_latitude": 21.021238666919626,
          "to_longitude": 105.83191369660236,
          "from_longitude": 105.83186861679228
        }
      },
      {
        "id": 12175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318282834632,
            21.020984593168983,
            105.83195734385576,
            21.021238922070324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318282834632,
              21.021238922070324
            ],
            [
              105.83183026531364,
              21.021235313931847
            ],
            [
              105.83186596724408,
              21.021170327553705
            ],
            [
              105.83184154869917,
              21.021147294621922
            ],
            [
              105.83184715288834,
              21.02110042689601
            ],
            [
              105.83185388458766,
              21.02108756186395
            ],
            [
              105.83192101311674,
              21.02102083932823
            ],
            [
              105.83193285581915,
              21.021009025035756
            ],
            [
              105.831956993713,
              21.020984942623013
            ],
            [
              105.83195734385576,
              21.020984593168983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.8_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12236",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021238922070324,
          "to_latitude": 21.020984593168983,
          "to_longitude": 105.83195734385576,
          "from_longitude": 105.8318282834632
        }
      },
      {
        "id": 12176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83176110416673,
            21.02143473637103,
            105.8318355263108,
            21.02155784862378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318355263108,
              21.02155784862378
            ],
            [
              105.83181432598455,
              21.021531432731116
            ],
            [
              105.83176110416673,
              21.02143473637103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.3_Ngõ 221/9, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12237",
          "diaChiLapD": "Ngõ 221/9, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02155784862378,
          "to_latitude": 21.02143473637103,
          "to_longitude": 105.83176110416673,
          "from_longitude": 105.8318355263108
        }
      },
      {
        "id": 12177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163015617733,
            21.021434443890833,
            105.83176110416673,
            21.02146882632921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176110416673,
              21.02143473637103
            ],
            [
              105.83172480450536,
              21.021449904692787
            ],
            [
              105.8317154472397,
              21.021434443890833
            ],
            [
              105.83170896745436,
              21.021437058797773
            ],
            [
              105.83163022902976,
              21.02146882632921
            ],
            [
              105.83163015617733,
              21.02146869933076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.3_Ngõ 221/9, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12238",
          "diaChiLapD": "Ngõ 221/9, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02143473637103,
          "to_latitude": 21.02146869933076,
          "to_longitude": 105.83163015617733,
          "from_longitude": 105.83176110416673
        }
      },
      {
        "id": 12178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83155400408572,
            21.021336991321643,
            105.83163015617733,
            21.02146869933076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83163015617733,
              21.02146869933076
            ],
            [
              105.8316032442253,
              21.021422084526822
            ],
            [
              105.83155446837452,
              21.021337796534514
            ],
            [
              105.83155400408572,
              21.021336991321643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.3_Ngõ 221/9, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12239",
          "diaChiLapD": "Ngõ 221/9, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02146869933076,
          "to_latitude": 21.021336991321643,
          "to_longitude": 105.83155400408572,
          "from_longitude": 105.83163015617733
        }
      },
      {
        "id": 12179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316032442253,
            21.021389162511994,
            105.83167499903043,
            21.021422084526822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316032442253,
              21.021422084526822
            ],
            [
              105.83167499903043,
              21.021389162511994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.3_Ngõ 221/9, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12240",
          "diaChiLapD": "Ngõ 221/9, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021422084526822,
          "to_latitude": 21.021389162511994,
          "to_longitude": 105.83167499903043,
          "from_longitude": 105.8316032442253
        }
      },
      {
        "id": 12180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83192739743313,
            21.02132987468396,
            105.83201171884956,
            21.02143556274487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83195965352823,
              21.02143556274487
            ],
            [
              105.83192739743313,
              21.021381267823383
            ],
            [
              105.83194680020696,
              21.021356429991634
            ],
            [
              105.83196077750274,
              21.021338538297734
            ],
            [
              105.83199641738342,
              21.021350450016246
            ],
            [
              105.83201154601504,
              21.021330105851625
            ],
            [
              105.83201171884956,
              21.02132987468396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.12_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12241",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02143556274487,
          "to_latitude": 21.02132987468396,
          "to_longitude": 105.83201171884956,
          "from_longitude": 105.83195965352823
        }
      },
      {
        "id": 12181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83156906809832,
            21.021275710073816,
            105.83169415297523,
            21.021346095339233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83156906809832,
              21.021346095339233
            ],
            [
              105.83160462493797,
              21.021330896360066
            ],
            [
              105.83166236234769,
              21.02130309913488
            ],
            [
              105.83169415297523,
              21.021284246041528
            ],
            [
              105.83169053922332,
              21.021278764880865
            ],
            [
              105.83168852810921,
              21.021275710073816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.6_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12242",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021346095339233,
          "to_latitude": 21.021275710073816,
          "to_longitude": 105.83168852810921,
          "from_longitude": 105.83156906809832
        }
      },
      {
        "id": 12182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169415297523,
            21.021246977158892,
            105.83180676701393,
            21.021284246041528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83169415297523,
              21.021284246041528
            ],
            [
              105.83171931893752,
              21.02126981049089
            ],
            [
              105.83171934387802,
              21.021269797723804
            ],
            [
              105.83175764913241,
              21.021251840374955
            ],
            [
              105.83177912706734,
              21.021246977158892
            ],
            [
              105.83180676701393,
              21.021250541599947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.6_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12243",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021284246041528,
          "to_latitude": 21.021250541599947,
          "to_longitude": 105.83180676701393,
          "from_longitude": 105.83169415297523
        }
      },
      {
        "id": 12183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83201445713142,
            21.021351810546353,
            105.83213550552873,
            21.021388905250898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83201445713142,
              21.021351810546353
            ],
            [
              105.83205378230086,
              21.021363541645304
            ],
            [
              105.83211491727013,
              21.021385139442977
            ],
            [
              105.83213129461213,
              21.021388905250898
            ],
            [
              105.83213531858286,
              21.021363949345087
            ],
            [
              105.83213550552873,
              21.0213627941097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.14_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12244",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021351810546353,
          "to_latitude": 21.0213627941097,
          "to_longitude": 105.83213550552873,
          "from_longitude": 105.83201445713142
        }
      },
      {
        "id": 12184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213129461213,
            21.021388905250898,
            105.83231369465784,
            21.021445431679098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83213129461213,
              21.021388905250898
            ],
            [
              105.8321520863164,
              21.021393686362085
            ],
            [
              105.83216381620124,
              21.021396382905642
            ],
            [
              105.83222973270068,
              21.02141425585405
            ],
            [
              105.83231369465784,
              21.021445431679098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.14_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12245",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021388905250898,
          "to_latitude": 21.021445431679098,
          "to_longitude": 105.83231369465784,
          "from_longitude": 105.83213129461213
        }
      },
      {
        "id": 12185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8313975548347,
            21.021371783376484,
            105.8314989777932,
            21.02140663691542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8313975548347,
              21.02140663691542
            ],
            [
              105.83141224370819,
              21.021402734540928
            ],
            [
              105.8314650421633,
              21.021383394027044
            ],
            [
              105.83147965131897,
              21.021378127264267
            ],
            [
              105.8314989777932,
              21.021371783376484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.1_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12246",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02140663691542,
          "to_latitude": 21.021371783376484,
          "to_longitude": 105.8314989777932,
          "from_longitude": 105.8313975548347
        }
      },
      {
        "id": 12186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831494484926,
            21.02135165934961,
            105.83155296472817,
            21.021371783376484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831494484926,
              21.021361120194005
            ],
            [
              105.83149457170127,
              21.021361329317884
            ],
            [
              105.8314989777932,
              21.021371783376484
            ],
            [
              105.83152843849788,
              21.02136211394811
            ],
            [
              105.83155296472817,
              21.02135165934961
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.1_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12247",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021361120194005,
          "to_latitude": 21.02135165934961,
          "to_longitude": 105.83155296472817,
          "from_longitude": 105.831494484926
        }
      },
      {
        "id": 12187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83139540282022,
            21.02142241141207,
            105.83148489777862,
            21.02158645202704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83139540282022,
              21.02142241141207
            ],
            [
              105.83142100586038,
              21.021467883747253
            ],
            [
              105.83142771824787,
              21.021480326273274
            ],
            [
              105.83144260777756,
              21.021507926482965
            ],
            [
              105.83146918658458,
              21.021553278427092
            ],
            [
              105.83148489777862,
              21.02158645202704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12248",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02142241141207,
          "to_latitude": 21.02158645202704,
          "to_longitude": 105.83148489777862,
          "from_longitude": 105.83139540282022
        }
      },
      {
        "id": 12188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83148489777862,
            21.02158645202704,
            105.83154101014237,
            21.021712798042184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83148489777862,
              21.02158645202704
            ],
            [
              105.83149034184854,
              21.02159794694523
            ],
            [
              105.83152759258485,
              21.021683497100817
            ],
            [
              105.83154101014237,
              21.021712798042184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12249",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02158645202704,
          "to_latitude": 21.021712798042184,
          "to_longitude": 105.83154101014237,
          "from_longitude": 105.83148489777862
        }
      },
      {
        "id": 12189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83150805351652,
            21.021652092275712,
            105.83171503834501,
            21.02172627810753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171503834501,
              21.021652092275712
            ],
            [
              105.83167535555435,
              21.0216676429371
            ],
            [
              105.83167437816286,
              21.02166815894124
            ],
            [
              105.8315613941585,
              21.021714659286648
            ],
            [
              105.83153423035954,
              21.021718042792692
            ],
            [
              105.83153005738298,
              21.021719097385606
            ],
            [
              105.83152281807857,
              21.021720927483976
            ],
            [
              105.83150805351652,
              21.02172627810753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12250",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021652092275712,
          "to_latitude": 21.02172627810753,
          "to_longitude": 105.83150805351652,
          "from_longitude": 105.83171503834501
        }
      },
      {
        "id": 12190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154991029615,
            21.021686678047345,
            105.83181598754896,
            21.021816521473134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83181598754896,
              21.021686678047345
            ],
            [
              105.83170476071722,
              21.021740565495374
            ],
            [
              105.83169212216052,
              21.02175546095032
            ],
            [
              105.83156592371493,
              21.02180964641978
            ],
            [
              105.83156292275727,
              21.021811108057104
            ],
            [
              105.83154991029615,
              21.021816521473134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12251",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021686678047345,
          "to_latitude": 21.021816521473134,
          "to_longitude": 105.83154991029615,
          "from_longitude": 105.83181598754896
        }
      },
      {
        "id": 12191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83182968718754,
            21.021280668249215,
            105.83191876738606,
            21.021543848171454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83191876738606,
              21.021280668249215
            ],
            [
              105.83191851086217,
              21.0212810732452
            ],
            [
              105.83189880951291,
              21.02131226039555
            ],
            [
              105.83189647457695,
              21.021313953619384
            ],
            [
              105.831856781605,
              21.02134273570552
            ],
            [
              105.83182968718754,
              21.021366763015752
            ],
            [
              105.83187159779634,
              21.021419009194037
            ],
            [
              105.83184676401304,
              21.021434020303104
            ],
            [
              105.83184668827471,
              21.021434066737967
            ],
            [
              105.83190949475073,
              21.021543848171454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.11_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12252",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021280668249215,
          "to_latitude": 21.021543848171454,
          "to_longitude": 105.83190949475073,
          "from_longitude": 105.83191876738606
        }
      },
      {
        "id": 12192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164692083547,
            21.02182873651258,
            105.83185575971719,
            21.02193012441394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83164692083547,
              21.02193012441394
            ],
            [
              105.83167967679006,
              21.021916187367527
            ],
            [
              105.83172566096329,
              21.021896540477048
            ],
            [
              105.83177833341472,
              21.02187301764706
            ],
            [
              105.83182753047647,
              21.021851447407776
            ],
            [
              105.83182903254264,
              21.021850829482094
            ],
            [
              105.83185575971719,
              21.021839833882193
            ],
            [
              105.83185005959668,
              21.02182952977588
            ],
            [
              105.83184955304392,
              21.02182873651258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12253",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02193012441394,
          "to_latitude": 21.02182873651258,
          "to_longitude": 105.83184955304392,
          "from_longitude": 105.83164692083547
        }
      },
      {
        "id": 12193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83185575971719,
            21.021722018141624,
            105.83200978034364,
            21.021839833882193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83185575971719,
              21.021839833882193
            ],
            [
              105.83187599038871,
              21.0218298301793
            ],
            [
              105.83188632373734,
              21.021820602880165
            ],
            [
              105.83189366585097,
              21.021813895754942
            ],
            [
              105.8319053676811,
              21.021803207570212
            ],
            [
              105.83194409884658,
              21.021774436498056
            ],
            [
              105.83197044738147,
              21.021752417072484
            ],
            [
              105.83200978034364,
              21.021722018141624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12254",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021839833882193,
          "to_latitude": 21.021722018141624,
          "to_longitude": 105.83200978034364,
          "from_longitude": 105.83185575971719
        }
      },
      {
        "id": 12194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83201808826672,
            21.021645927446784,
            105.83209903447724,
            21.021769222526977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209903447724,
              21.021645927446784
            ],
            [
              105.8320591722633,
              21.021680450399423
            ],
            [
              105.83202427402166,
              21.021710674099833
            ],
            [
              105.83201808826672,
              21.02171652383335
            ],
            [
              105.83205980338593,
              21.021769222526977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.4_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12255",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021645927446784,
          "to_latitude": 21.021769222526977,
          "to_longitude": 105.83205980338593,
          "from_longitude": 105.83209903447724
        }
      },
      {
        "id": 12195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213393289813,
            21.021659964786693,
            105.83227951361182,
            21.021743496431508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227951361182,
              21.021659964786693
            ],
            [
              105.83227187721616,
              21.02166494911266
            ],
            [
              105.83226938333372,
              21.021667122731465
            ],
            [
              105.83225283203463,
              21.021678817377182
            ],
            [
              105.83219250511122,
              21.021717561906495
            ],
            [
              105.83213393289813,
              21.021743496431508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.10_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12256",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021659964786693,
          "to_latitude": 21.021743496431508,
          "to_longitude": 105.83213393289813,
          "from_longitude": 105.83227951361182
        }
      },
      {
        "id": 12196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209903447724,
            21.021645927446784,
            105.832129858106,
            21.021735357040807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209903447724,
              21.021645927446784
            ],
            [
              105.83212097618204,
              21.021672449868085
            ],
            [
              105.832129858106,
              21.021683717493996
            ],
            [
              105.83210808026963,
              21.021715847857013
            ],
            [
              105.83211943692115,
              21.021735357040807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.4_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12257",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021645927446784,
          "to_latitude": 21.021735357040807,
          "to_longitude": 105.83211943692115,
          "from_longitude": 105.83209903447724
        }
      },
      {
        "id": 12197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209903447724,
            21.021580402513614,
            105.83217631188612,
            21.021645927446784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209903447724,
              21.021645927446784
            ],
            [
              105.83212183077708,
              21.021625711051282
            ],
            [
              105.83215538097208,
              21.02159526360472
            ],
            [
              105.83217631188612,
              21.021580402513614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.4_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12258",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021645927446784,
          "to_latitude": 21.021580402513614,
          "to_longitude": 105.83217631188612,
          "from_longitude": 105.83209903447724
        }
      },
      {
        "id": 12198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221206739037,
            21.02152035707674,
            105.83226547117006,
            21.02156477521088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83226547117006,
              21.02152035707674
            ],
            [
              105.83225347492407,
              21.021530334081778
            ],
            [
              105.83222580064191,
              21.021553352679764
            ],
            [
              105.83221206739037,
              21.02156477521088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12259",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02152035707674,
          "to_latitude": 21.02156477521088,
          "to_longitude": 105.83221206739037,
          "from_longitude": 105.83226547117006
        }
      },
      {
        "id": 12199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83219654148519,
            21.021576455801785,
            105.83226938333372,
            21.021667122731465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83226938333372,
              21.021667122731465
            ],
            [
              105.83219654148519,
              21.021576455801785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.10_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12260",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021667122731465,
          "to_latitude": 21.021576455801785,
          "to_longitude": 105.83219654148519,
          "from_longitude": 105.83226938333372
        }
      },
      {
        "id": 12200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83227977679661,
            21.021675318312845,
            105.83240511759445,
            21.021821081482795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227977679661,
              21.021675318312845
            ],
            [
              105.8323029847981,
              21.021697388843048
            ],
            [
              105.83231651378226,
              21.021711555649766
            ],
            [
              105.83233145570952,
              21.02172741902217
            ],
            [
              105.83234110147448,
              21.02173834238299
            ],
            [
              105.83236874327937,
              21.021761805655615
            ],
            [
              105.8323728217249,
              21.021767290848985
            ],
            [
              105.8323824521519,
              21.021783768208664
            ],
            [
              105.83239081127934,
              21.021798070502562
            ],
            [
              105.83240511759445,
              21.021821081482795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.8_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12261",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021675318312845,
          "to_latitude": 21.021821081482795,
          "to_longitude": 105.83240511759445,
          "from_longitude": 105.83227977679661
        }
      },
      {
        "id": 12201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240511759445,
            21.021821081482795,
            105.832428683031,
            21.02186005047504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832428683031,
              21.02186005047504
            ],
            [
              105.83240676769174,
              21.021823736112555
            ],
            [
              105.83240511759445,
              21.021821081482795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.8_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12262",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02186005047504,
          "to_latitude": 21.021821081482795,
          "to_longitude": 105.83240511759445,
          "from_longitude": 105.832428683031
        }
      },
      {
        "id": 12202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320163325809,
            21.021909461701785,
            105.83211974668012,
            21.022142687752893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83205684559178,
              21.022142687752893
            ],
            [
              105.83205638717499,
              21.02214207489968
            ],
            [
              105.83205239560546,
              21.02213612321016
            ],
            [
              105.8320163325809,
              21.022078847330295
            ],
            [
              105.83201768141677,
              21.02207783273378
            ],
            [
              105.83202537016159,
              21.022072048808983
            ],
            [
              105.8320538564103,
              21.022046070919227
            ],
            [
              105.83205156748377,
              21.022037507807735
            ],
            [
              105.83206011392609,
              21.02202177337908
            ],
            [
              105.83206953044161,
              21.022003345794708
            ],
            [
              105.83208265841184,
              21.021992745459805
            ],
            [
              105.83211974668012,
              21.021961400969836
            ],
            [
              105.83207567308204,
              21.021909461701785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.2_Ngõ 181/6, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12263",
          "diaChiLapD": "Ngõ 181/6, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022142687752893,
          "to_latitude": 21.021909461701785,
          "to_longitude": 105.83207567308204,
          "from_longitude": 105.83205684559178
        }
      },
      {
        "id": 12203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8317551796251,
            21.022134510312736,
            105.83202298473441,
            21.0222701576105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317551796251,
              21.0222701576105
            ],
            [
              105.83176112122696,
              21.022267631139204
            ],
            [
              105.83177786758557,
              21.022256744874333
            ],
            [
              105.83178433704839,
              21.02225399724052
            ],
            [
              105.8318209298438,
              21.022236666052603
            ],
            [
              105.83187544780203,
              21.022210948361867
            ],
            [
              105.83190881930759,
              21.0221957699394
            ],
            [
              105.83190968194873,
              21.022195377333645
            ],
            [
              105.8319220206817,
              21.022191285892657
            ],
            [
              105.83193570043204,
              21.02219170581973
            ],
            [
              105.83202298473441,
              21.022134510312736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12264",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0222701576105,
          "to_latitude": 21.022134510312736,
          "to_longitude": 105.83202298473441,
          "from_longitude": 105.8317551796251
        }
      },
      {
        "id": 12204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188804576501,
            21.022143146429645,
            105.83190881930759,
            21.0221957699394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190881930759,
              21.0221957699394
            ],
            [
              105.831908546934,
              21.0221952257241
            ],
            [
              105.83188804576501,
              21.02216237727479
            ],
            [
              105.83189198419367,
              21.022143146429645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12265",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0221957699394,
          "to_latitude": 21.022143146429645,
          "to_longitude": 105.83189198419367,
          "from_longitude": 105.83190881930759
        }
      },
      {
        "id": 12205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83185611152847,
            21.02161436205662,
            105.83194981712829,
            21.021787879043067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83191901389266,
              21.021787879043067
            ],
            [
              105.83191327214398,
              21.021781169072398
            ],
            [
              105.83191199578955,
              21.02177964254331
            ],
            [
              105.83194981712829,
              21.02175524391317
            ],
            [
              105.83192329594691,
              21.021709040931125
            ],
            [
              105.831919212727,
              21.02170633316485
            ],
            [
              105.83190828874396,
              21.02170221098815
            ],
            [
              105.83189647328841,
              21.02168444093389
            ],
            [
              105.83185611152847,
              21.02161436205662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.2_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12266",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021787879043067,
          "to_latitude": 21.02161436205662,
          "to_longitude": 105.83185611152847,
          "from_longitude": 105.83191901389266
        }
      },
      {
        "id": 12206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83200725965277,
            21.02217965542084,
            105.83207359594653,
            21.022226485543882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83200725965277,
              21.02217965542084
            ],
            [
              105.83200995167691,
              21.02218180908747
            ],
            [
              105.8320123150941,
              21.02218403300656
            ],
            [
              105.83207359594653,
              21.022226485543882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.1_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12267",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02217965542084,
          "to_latitude": 21.022226485543882,
          "to_longitude": 105.83207359594653,
          "from_longitude": 105.83200725965277
        }
      },
      {
        "id": 12207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221402377873,
            21.021908672791422,
            105.83229574906461,
            21.02196589052112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221402377873,
              21.02193322065918
            ],
            [
              105.8322492892638,
              21.021908672791422
            ],
            [
              105.83229028616809,
              21.02195916294142
            ],
            [
              105.83229307476749,
              21.021962597806354
            ],
            [
              105.83229337987277,
              21.02196297295791
            ],
            [
              105.83229574906461,
              21.02196589052112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.4_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12268",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02193322065918,
          "to_latitude": 21.02196589052112,
          "to_longitude": 105.83229574906461,
          "from_longitude": 105.83221402377873
        }
      },
      {
        "id": 12208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8317012135701,
            21.02141860770752,
            105.83170896745436,
            21.021437058797773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83170896745436,
              21.021437058797773
            ],
            [
              105.8317012135701,
              21.02141860770752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.3_Ngõ 221/9, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12269",
          "diaChiLapD": "Ngõ 221/9, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021437058797773,
          "to_latitude": 21.02141860770752,
          "to_longitude": 105.8317012135701,
          "from_longitude": 105.83170896745436
        }
      },
      {
        "id": 12209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83206082535067,
            21.021542703302153,
            105.83212064306124,
            21.021608607985616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83206082535067,
              21.021542703302153
            ],
            [
              105.83211868985329,
              21.021606456125234
            ],
            [
              105.83212064306124,
              21.021608607985616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12270",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021542703302153,
          "to_latitude": 21.021608607985616,
          "to_longitude": 105.83212064306124,
          "from_longitude": 105.83206082535067
        }
      },
      {
        "id": 12210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83211679451618,
            21.022025588885317,
            105.83220904161489,
            21.022088492990424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220904161489,
              21.022025588885317
            ],
            [
              105.83220112683455,
              21.022030856376595
            ],
            [
              105.8321498901797,
              21.022068337966793
            ],
            [
              105.83212939713931,
              21.022082722720807
            ],
            [
              105.83212117731019,
              21.022088492990424
            ],
            [
              105.8321200054383,
              21.022087073435625
            ],
            [
              105.83211679451618,
              21.02208276902249
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.3_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12271",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022025588885317,
          "to_latitude": 21.02208276902249,
          "to_longitude": 105.83211679451618,
          "from_longitude": 105.83220904161489
        }
      },
      {
        "id": 12211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202753569999,
            21.022088492990424,
            105.83212117731019,
            21.022172101924966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83212117731019,
              21.022088492990424
            ],
            [
              105.83211389718602,
              21.022093543308653
            ],
            [
              105.83207679778212,
              21.022117686444627
            ],
            [
              105.83206030388821,
              21.022130722983395
            ],
            [
              105.83206046058525,
              21.022142665552277
            ],
            [
              105.83202753569999,
              21.022172101924966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.3_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12272",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022088492990424,
          "to_latitude": 21.022172101924966,
          "to_longitude": 105.83202753569999,
          "from_longitude": 105.83212117731019
        }
      },
      {
        "id": 12212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209976776244,
            21.022082722720807,
            105.8321488136784,
            21.02217278456246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83212939713931,
              21.022082722720807
            ],
            [
              105.8321488136784,
              21.022104557109895
            ],
            [
              105.83214299115947,
              21.022129752135992
            ],
            [
              105.83209976776244,
              21.02217278456246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.3_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12273",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022082722720807,
          "to_latitude": 21.02217278456246,
          "to_longitude": 105.83209976776244,
          "from_longitude": 105.83212939713931
        }
      },
      {
        "id": 12213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83182903254264,
            21.021850829482094,
            105.83187242081567,
            21.021948573704517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83182903254264,
              21.021850829482094
            ],
            [
              105.83183113822477,
              21.021854802403706
            ],
            [
              105.83187242081567,
              21.021932713154648
            ],
            [
              105.83184159373093,
              21.021948573704517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12274",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021850829482094,
          "to_latitude": 21.021948573704517,
          "to_longitude": 105.83184159373093,
          "from_longitude": 105.83182903254264
        }
      },
      {
        "id": 12214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323895358386,
            21.021554724744618,
            105.8325644600277,
            21.021776261167403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323895358386,
              21.021776261167403
            ],
            [
              105.83238980698755,
              21.02177606474398
            ],
            [
              105.83240118658516,
              21.021767815002356
            ],
            [
              105.8324749631036,
              21.02171455930718
            ],
            [
              105.83246931631719,
              21.021684238607452
            ],
            [
              105.83249047626576,
              21.021657960505262
            ],
            [
              105.83252312092512,
              21.0216174193724
            ],
            [
              105.83255056635589,
              21.02158333644629
            ],
            [
              105.8325644600277,
              21.021554724744618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.9_Ngõ 203/43, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12275",
          "diaChiLapD": "Ngõ 203/43, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021776261167403,
          "to_latitude": 21.021554724744618,
          "to_longitude": 105.8325644600277,
          "from_longitude": 105.8323895358386
        }
      },
      {
        "id": 12215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160456412624,
            21.02181748964051,
            105.83177953954265,
            21.02189372201982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83160456412624,
              21.02189372201982
            ],
            [
              105.83160536641908,
              21.021893375775132
            ],
            [
              105.83166810657718,
              21.021866318211654
            ],
            [
              105.83173627610347,
              21.021837698594343
            ],
            [
              105.83177953954265,
              21.02181748964051
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12276",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02189372201982,
          "to_latitude": 21.02181748964051,
          "to_longitude": 105.83177953954265,
          "from_longitude": 105.83160456412624
        }
      },
      {
        "id": 12216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83195260849314,
            21.021527713450265,
            105.83204923292624,
            21.021680067280137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83204923292624,
              21.021680067280137
            ],
            [
              105.83204622873916,
              21.021675744692605
            ],
            [
              105.83204546641736,
              21.02167479913292
            ],
            [
              105.83202016055739,
              21.02164235716713
            ],
            [
              105.83199789586004,
              21.02160217594613
            ],
            [
              105.83197635441118,
              21.021566472080316
            ],
            [
              105.83195260849314,
              21.021527713450265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12277",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021680067280137,
          "to_latitude": 21.021527713450265,
          "to_longitude": 105.83195260849314,
          "from_longitude": 105.83204923292624
        }
      },
      {
        "id": 12217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83200448480345,
            21.021529253506202,
            105.83208531387555,
            21.02164143057638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83208531387555,
              21.02164143057638
            ],
            [
              105.83207470885124,
              21.02162804518709
            ],
            [
              105.83207453999852,
              21.02162778046516
            ],
            [
              105.83202978141495,
              21.02157286208392
            ],
            [
              105.83200448480345,
              21.021529253506202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12278",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02164143057638,
          "to_latitude": 21.021529253506202,
          "to_longitude": 105.83200448480345,
          "from_longitude": 105.83208531387555
        }
      },
      {
        "id": 12218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208322601587,
            21.021463153326557,
            105.83218765414875,
            21.021567679298492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83218765414875,
              21.021567679298492
            ],
            [
              105.83215643709839,
              21.021525731837514
            ],
            [
              105.83213290116377,
              21.02149847025281
            ],
            [
              105.8321095949254,
              21.02148156933114
            ],
            [
              105.83208322601587,
              21.021463153326557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.5_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12279",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021567679298492,
          "to_latitude": 21.021463153326557,
          "to_longitude": 105.83208322601587,
          "from_longitude": 105.83218765414875
        }
      },
      {
        "id": 12219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83226547117006,
            21.021459530701385,
            105.8323250174357,
            21.02152035707674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323250174357,
              21.021459530701385
            ],
            [
              105.83230495770398,
              21.021478999410768
            ],
            [
              105.83226547117006,
              21.02152035707674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12280",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021459530701385,
          "to_latitude": 21.02152035707674,
          "to_longitude": 105.83226547117006,
          "from_longitude": 105.8323250174357
        }
      },
      {
        "id": 12220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83179540970686,
            21.02218403300656,
            105.8320123150941,
            21.022297399021447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83179540970686,
              21.022297399021447
            ],
            [
              105.83189550302231,
              21.022251845819362
            ],
            [
              105.83195216894848,
              21.022225759018394
            ],
            [
              105.8320123150941,
              21.02218403300656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.1_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12281",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022297399021447,
          "to_latitude": 21.02218403300656,
          "to_longitude": 105.8320123150941,
          "from_longitude": 105.83179540970686
        }
      },
      {
        "id": 12221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83193285581915,
            21.021009025035756,
            105.83197091754379,
            21.021044632752908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193285581915,
              21.021009025035756
            ],
            [
              105.83197091754379,
              21.021044632752908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.8_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12282",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021009025035756,
          "to_latitude": 21.021044632752908,
          "to_longitude": 105.83197091754379,
          "from_longitude": 105.83193285581915
        }
      },
      {
        "id": 12222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190942034058,
            21.020944881558215,
            105.83200447104485,
            21.02102381369354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190942034058,
              21.020944881558215
            ],
            [
              105.83195734385576,
              21.020984593168983
            ],
            [
              105.83200447104485,
              21.02102381369354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.8_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12283",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020944881558215,
          "to_latitude": 21.02102381369354,
          "to_longitude": 105.83200447104485,
          "from_longitude": 105.83190942034058
        }
      },
      {
        "id": 12223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83173547517912,
            21.02107763009026,
            105.8318138616596,
            21.021176336820115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318138616596,
              21.021176336820115
            ],
            [
              105.83173547517912,
              21.02107763009026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.7_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12284",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021176336820115,
          "to_latitude": 21.02107763009026,
          "to_longitude": 105.83173547517912,
          "from_longitude": 105.8318138616596
        }
      },
      {
        "id": 12224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162760251977,
            21.021946102975154,
            105.83167377130175,
            21.022051534545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83162760251977,
              21.021946102975154
            ],
            [
              105.83167377130175,
              21.02204524185235
            ],
            [
              105.83166916359957,
              21.022047007605714
            ],
            [
              105.83165734579265,
              21.022051534545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12285",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021946102975154,
          "to_latitude": 21.022051534545,
          "to_longitude": 105.83165734579265,
          "from_longitude": 105.83162760251977
        }
      },
      {
        "id": 12225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83167377130175,
            21.02204524185235,
            105.83178323047733,
            21.02228602556905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83167377130175,
              21.02204524185235
            ],
            [
              105.83170664988634,
              21.022115844198737
            ],
            [
              105.83176905998815,
              21.022249860215414
            ],
            [
              105.83177786758557,
              21.022256744874333
            ],
            [
              105.83177635087755,
              21.02227171871948
            ],
            [
              105.83178323047733,
              21.02228602556905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_86.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12286",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02204524185235,
          "to_latitude": 21.02228602556905,
          "to_longitude": 105.83178323047733,
          "from_longitude": 105.83167377130175
        }
      },
      {
        "id": 12226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83199665537148,
            21.022202851278305,
            105.8320982530756,
            21.02228546752323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8320982530756,
              21.022202851278305
            ],
            [
              105.83208619865658,
              21.02221483823137
            ],
            [
              105.83207359594653,
              21.022226485543882
            ],
            [
              105.83206656593119,
              21.022248004932315
            ],
            [
              105.8320206824814,
              21.02228546752323
            ],
            [
              105.83199665537148,
              21.02227216494992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.1_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12287",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022202851278305,
          "to_latitude": 21.02227216494992,
          "to_longitude": 105.83199665537148,
          "from_longitude": 105.8320982530756
        }
      },
      {
        "id": 12227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83156592371493,
            21.02180964641978,
            105.83161829953816,
            21.021923609398453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83156592371493,
              21.02180964641978
            ],
            [
              105.83157115554945,
              21.021821030372468
            ],
            [
              105.83160456412624,
              21.02189372201982
            ],
            [
              105.83161829953816,
              21.021923609398453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12288",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02180964641978,
          "to_latitude": 21.021923609398453,
          "to_longitude": 105.83161829953816,
          "from_longitude": 105.83156592371493
        }
      },
      {
        "id": 12228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83153005738298,
            21.021719097385606,
            105.8315674316489,
            21.021801586801402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83153005738298,
              21.021719097385606
            ],
            [
              105.83153383512824,
              21.021727435549753
            ],
            [
              105.83153495705885,
              21.02172991212567
            ],
            [
              105.8315674316489,
              21.021801586801402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12289",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021719097385606,
          "to_latitude": 21.021801586801402,
          "to_longitude": 105.8315674316489,
          "from_longitude": 105.83153005738298
        }
      },
      {
        "id": 12229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189366585097,
            21.021813895754942,
            105.83193433338982,
            21.021883417641018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193433338982,
              21.021883417641018
            ],
            [
              105.83189781184944,
              21.021820982949812
            ],
            [
              105.83189366585097,
              21.021813895754942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12290",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021883417641018,
          "to_latitude": 21.021813895754942,
          "to_longitude": 105.83189366585097,
          "from_longitude": 105.83193433338982
        }
      },
      {
        "id": 12230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83412533730079,
            21.026589131889548,
            105.83434882455099,
            21.026695859880494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83414511122095,
              21.026695859880494
            ],
            [
              105.83414423653294,
              21.02669460959348
            ],
            [
              105.83412533730079,
              21.02666753242871
            ],
            [
              105.83412815142019,
              21.026666539531153
            ],
            [
              105.83413362796733,
              21.026664608673368
            ],
            [
              105.83426388401966,
              21.026618669440552
            ],
            [
              105.83430398833771,
              21.026604870146198
            ],
            [
              105.83434882455099,
              21.026589131889548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.1_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12291",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026695859880494,
          "to_latitude": 21.026589131889548,
          "to_longitude": 105.83434882455099,
          "from_longitude": 105.83414511122095
        }
      },
      {
        "id": 12231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83401007106707,
            21.026470200097798,
            105.83414632163098,
            21.026695377944492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83414632163098,
              21.026695377944492
            ],
            [
              105.83414553018214,
              21.026693700024474
            ],
            [
              105.83412815142019,
              21.026666539531153
            ],
            [
              105.83411040584963,
              21.026638808191265
            ],
            [
              105.83409183718467,
              21.026594200541773
            ],
            [
              105.83403705940883,
              21.026470200097798
            ],
            [
              105.83401007106707,
              21.02648019024024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.2_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12292",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026695377944492,
          "to_latitude": 21.02648019024024,
          "to_longitude": 105.83401007106707,
          "from_longitude": 105.83414632163098
        }
      },
      {
        "id": 12232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124804548477,
            21.019717020662586,
            105.83134100401213,
            21.019968311353207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124804548477,
              21.019717020662586
            ],
            [
              105.83127780527856,
              21.019786351276505
            ],
            [
              105.8312893069578,
              21.019813502002826
            ],
            [
              105.83134100401213,
              21.019968311353207
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12293",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019717020662586,
          "to_latitude": 21.019968311353207,
          "to_longitude": 105.83134100401213,
          "from_longitude": 105.83124804548477
        }
      },
      {
        "id": 12233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8311042847046,
            21.019968311353207,
            105.83134100401213,
            21.020018893965545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83134100401213,
              21.019968311353207
            ],
            [
              105.83112803209035,
              21.020018893965545
            ],
            [
              105.8311042847046,
              21.019968675992025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12294",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019968311353207,
          "to_latitude": 21.019968675992025,
          "to_longitude": 105.8311042847046,
          "from_longitude": 105.83134100401213
        }
      },
      {
        "id": 12234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83139954186296,
            21.01963648882335,
            105.83143114873901,
            21.019756624570903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141056234996,
              21.01963648882335
            ],
            [
              105.83141029215587,
              21.01963651001492
            ],
            [
              105.83139954186296,
              21.01963731313853
            ],
            [
              105.83140307390089,
              21.01965860294609
            ],
            [
              105.8314041666666,
              21.01966519204863
            ],
            [
              105.83143114873901,
              21.019756624570903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12295",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01963648882335,
          "to_latitude": 21.019756624570903,
          "to_longitude": 105.83143114873901,
          "from_longitude": 105.83141056234996
        }
      },
      {
        "id": 12235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83136308348989,
            21.019756624570903,
            105.83143114873901,
            21.019775786775188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83143114873901,
              21.019756624570903
            ],
            [
              105.83142972052612,
              21.01975702626097
            ],
            [
              105.83136308348989,
              21.019775786775188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12296",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019756624570903,
          "to_latitude": 21.019775786775188,
          "to_longitude": 105.83136308348989,
          "from_longitude": 105.83143114873901
        }
      },
      {
        "id": 12236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83132799638202,
            21.01965860294609,
            105.83140307390089,
            21.0196759288305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140307390089,
              21.01965860294609
            ],
            [
              105.83140029257841,
              21.019659245184645
            ],
            [
              105.83132799638202,
              21.0196759288305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12297",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01965860294609,
          "to_latitude": 21.0196759288305,
          "to_longitude": 105.83132799638202,
          "from_longitude": 105.83140307390089
        }
      },
      {
        "id": 12237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83138623440576,
            21.01948755071034,
            105.83139954186296,
            21.01963731313853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83139954186296,
              21.01963731313853
            ],
            [
              105.8313931515344,
              21.019598791128278
            ],
            [
              105.83138849498135,
              21.019564490444967
            ],
            [
              105.83138623440576,
              21.01953100811444
            ],
            [
              105.83138801071713,
              21.01948755071034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.5_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12298",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01963731313853,
          "to_latitude": 21.01948755071034,
          "to_longitude": 105.83138801071713,
          "from_longitude": 105.83139954186296
        }
      },
      {
        "id": 12238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8313472936146,
            21.019779728684295,
            105.8314879538189,
            21.019835269035454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8314879538189,
              21.019779728684295
            ],
            [
              105.83148789911868,
              21.01977975243553
            ],
            [
              105.83138246755831,
              21.01982394049237
            ],
            [
              105.8313472936146,
              21.019835269035454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.3_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12299",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019779728684295,
          "to_latitude": 21.019835269035454,
          "to_longitude": 105.8313472936146,
          "from_longitude": 105.8314879538189
        }
      },
      {
        "id": 12239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133728094357,
            21.01982394049237,
            105.83140751770433,
            21.019920371027688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83138246755831,
              21.01982394049237
            ],
            [
              105.83140751770433,
              21.019897096675038
            ],
            [
              105.83133728094357,
              21.019920371027688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.3_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12300",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01982394049237,
          "to_latitude": 21.019920371027688,
          "to_longitude": 105.83133728094357,
          "from_longitude": 105.83138246755831
        }
      },
      {
        "id": 12240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83128452555358,
            21.019386644606275,
            105.83137906712389,
            21.019463910063354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8313034788165,
              21.019386644606275
            ],
            [
              105.83130150968309,
              21.019391156804865
            ],
            [
              105.83128452555358,
              21.019430076663756
            ],
            [
              105.83137906712389,
              21.019463910063354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12301",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019386644606275,
          "to_latitude": 21.019463910063354,
          "to_longitude": 105.83137906712389,
          "from_longitude": 105.8313034788165
        }
      },
      {
        "id": 12241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83098481971605,
            21.01930804220736,
            105.83128452555358,
            21.019430076663756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83098481971605,
              21.01930804220736
            ],
            [
              105.83104516551168,
              21.019336870076547
            ],
            [
              105.83112910921382,
              21.019371601715903
            ],
            [
              105.83117395865037,
              21.019390157909182
            ],
            [
              105.83121635655819,
              21.019405680395828
            ],
            [
              105.83128452555358,
              21.019430076663756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12302",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01930804220736,
          "to_latitude": 21.019430076663756,
          "to_longitude": 105.83128452555358,
          "from_longitude": 105.83098481971605
        }
      },
      {
        "id": 12242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83148948386417,
            21.01982458437499,
            105.83155511880854,
            21.01998368900019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83148948386417,
              21.01982458437499
            ],
            [
              105.83155511880854,
              21.01998368900019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.2_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12303",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01982458437499,
          "to_latitude": 21.01998368900019,
          "to_longitude": 105.83155511880854,
          "from_longitude": 105.83148948386417
        }
      },
      {
        "id": 12243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83144945941959,
            21.020019876656033,
            105.83157304849605,
            21.020118841066946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157304849605,
              21.020019876656033
            ],
            [
              105.83157252739011,
              21.020020100493618
            ],
            [
              105.83146641274986,
              21.020065686523644
            ],
            [
              105.8314837572488,
              21.02010528943209
            ],
            [
              105.83144945941959,
              21.020118841066946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.2_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12304",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020019876656033,
          "to_latitude": 21.020118841066946,
          "to_longitude": 105.83144945941959,
          "from_longitude": 105.83157304849605
        }
      },
      {
        "id": 12244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83155511880854,
            21.019979193250155,
            105.83163464351267,
            21.020140846977885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83156905776414,
              21.019979193250155
            ],
            [
              105.83156888684448,
              21.019979249182423
            ],
            [
              105.83155511880854,
              21.01998368900019
            ],
            [
              105.83157304849605,
              21.020019876656033
            ],
            [
              105.83160538227449,
              21.02008513643241
            ],
            [
              105.83163464351267,
              21.020140846977885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.2_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12305",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019979193250155,
          "to_latitude": 21.020140846977885,
          "to_longitude": 105.83163464351267,
          "from_longitude": 105.83156905776414
        }
      },
      {
        "id": 12245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141034931498,
            21.019496104757607,
            105.83141876781524,
            21.01964022650523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141876781524,
              21.019496104757607
            ],
            [
              105.83141329728709,
              21.019627827079162
            ],
            [
              105.83141501110298,
              21.01963972681709
            ],
            [
              105.83141075502702,
              21.019640183877115
            ],
            [
              105.83141034931498,
              21.01964022650523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.4_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12306",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019496104757607,
          "to_latitude": 21.01964022650523,
          "to_longitude": 105.83141034931498,
          "from_longitude": 105.83141876781524
        }
      },
      {
        "id": 12246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141501110298,
            21.01963972681709,
            105.831495116116,
            21.019801327711054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141501110298,
              21.01963972681709
            ],
            [
              105.83141945524714,
              21.019670589057657
            ],
            [
              105.83143114316245,
              21.019694023900804
            ],
            [
              105.83145832846044,
              21.01971661796117
            ],
            [
              105.83147646796455,
              21.019758387436088
            ],
            [
              105.83148703460125,
              21.01977598750484
            ],
            [
              105.83148973299986,
              21.01978519894824
            ],
            [
              105.831495116116,
              21.019801327711054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.4_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12307",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01963972681709,
          "to_latitude": 21.019801327711054,
          "to_longitude": 105.831495116116,
          "from_longitude": 105.83141501110298
        }
      },
      {
        "id": 12247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83151112416213,
            21.01980930893306,
            105.83157190372849,
            21.019981002149343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83151112416213,
              21.01980930893306
            ],
            [
              105.83154021751892,
              21.01991508244986
            ],
            [
              105.83157190372849,
              21.01998049262571
            ],
            [
              105.83156997358202,
              21.019980932899784
            ],
            [
              105.83156966517524,
              21.019981002149343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.1_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12308",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01980930893306,
          "to_latitude": 21.019981002149343,
          "to_longitude": 105.83156966517524,
          "from_longitude": 105.83151112416213
        }
      },
      {
        "id": 12248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157190372849,
            21.01998049262571,
            105.83166859375805,
            21.020167829210013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83157190372849,
              21.01998049262571
            ],
            [
              105.83158052066817,
              21.02000055357064
            ],
            [
              105.83161231823402,
              21.020065915322007
            ],
            [
              105.83161841308034,
              21.020078027573067
            ],
            [
              105.83164637023846,
              21.02012842269269
            ],
            [
              105.83165467057027,
              21.02012625591991
            ],
            [
              105.83166859375805,
              21.020167829210013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99.1_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12309",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01998049262571,
          "to_latitude": 21.020167829210013,
          "to_longitude": 105.83166859375805,
          "from_longitude": 105.83157190372849
        }
      },
      {
        "id": 12249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83157342231651,
            21.02022684041864,
            105.83168283203216,
            21.020313441100598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168283203216,
              21.02022684041864
            ],
            [
              105.83157342231651,
              21.020286783125773
            ],
            [
              105.83158945414749,
              21.020313441100598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.1_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12310",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02022684041864,
          "to_latitude": 21.020313441100598,
          "to_longitude": 105.83158945414749,
          "from_longitude": 105.83168283203216
        }
      },
      {
        "id": 12250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83145032927537,
            21.019449733486972,
            105.83161125449043,
            21.019500615691115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83145032927537,
              21.019482652313428
            ],
            [
              105.83161125449043,
              21.019500615691115
            ],
            [
              105.8316009628258,
              21.019452629806352
            ],
            [
              105.83160034265286,
              21.019449733486972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12311",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019482652313428,
          "to_latitude": 21.019449733486972,
          "to_longitude": 105.83160034265286,
          "from_longitude": 105.83145032927537
        }
      },
      {
        "id": 12251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83161125449043,
            21.019500615691115,
            105.83177253022289,
            21.019511180694682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161125449043,
              21.019500615691115
            ],
            [
              105.83163367757878,
              21.019502999869733
            ],
            [
              105.83177253022289,
              21.019511180694682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12312",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019500615691115,
          "to_latitude": 21.019511180694682,
          "to_longitude": 105.83177253022289,
          "from_longitude": 105.83161125449043
        }
      },
      {
        "id": 12252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160267519492,
            21.019500615691115,
            105.83161125449043,
            21.01968162682799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161125449043,
              21.019500615691115
            ],
            [
              105.83160267519492,
              21.01968162682799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12313",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019500615691115,
          "to_latitude": 21.01968162682799,
          "to_longitude": 105.83160267519492,
          "from_longitude": 105.83161125449043
        }
      },
      {
        "id": 12253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83169026936882,
            21.019766589352077,
            105.83178929594062,
            21.019767437558173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178929594062,
              21.019766589352077
            ],
            [
              105.83169026936882,
              21.019767437558173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.2_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12314",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019766589352077,
          "to_latitude": 21.019767437558173,
          "to_longitude": 105.83169026936882,
          "from_longitude": 105.83178929594062
        }
      },
      {
        "id": 12254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83178799501228,
            21.019930591498863,
            105.83198082607157,
            21.0199374290839
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178799501228,
              21.019930591498863
            ],
            [
              105.83190673058432,
              21.019930986325722
            ],
            [
              105.83192518698846,
              21.0199374290839
            ],
            [
              105.83198082607157,
              21.01993257489769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.2_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12315",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019930591498863,
          "to_latitude": 21.01993257489769,
          "to_longitude": 105.83198082607157,
          "from_longitude": 105.83178799501228
        }
      },
      {
        "id": 12255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83196903909212,
            21.019854543363707,
            105.83198767476294,
            21.01997791308011
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198767476294,
              21.01997791308011
            ],
            [
              105.83198082607157,
              21.01993257489769
            ],
            [
              105.83196903909212,
              21.019854543363707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.2_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12316",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01997791308011,
          "to_latitude": 21.019854543363707,
          "to_longitude": 105.83196903909212,
          "from_longitude": 105.83198767476294
        }
      },
      {
        "id": 12256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197789260014,
            21.019473899832345,
            105.83216168997826,
            21.019495892794033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197789260014,
              21.019495892794033
            ],
            [
              105.83210229556941,
              21.019481007020786
            ],
            [
              105.83216168997826,
              21.019473899832345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12317",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 219,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019495892794033,
          "to_latitude": 21.019473899832345,
          "to_longitude": 105.83216168997826,
          "from_longitude": 105.83197789260014
        }
      },
      {
        "id": 12257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83193944759971,
            21.01949949952988,
            105.83194459813645,
            21.019579645460936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83193944759971,
              21.01949949952988
            ],
            [
              105.83194459813645,
              21.019579645460936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12318",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01949949952988,
          "to_latitude": 21.019579645460936,
          "to_longitude": 105.83194459813645,
          "from_longitude": 105.83193944759971
        }
      },
      {
        "id": 12258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83181501060409,
            21.01944637845886,
            105.83197789260014,
            21.019504094912755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83181501060409,
              21.019504094912755
            ],
            [
              105.83193944759971,
              21.01949949952988
            ],
            [
              105.83195153794557,
              21.019499052914778
            ],
            [
              105.83197789260014,
              21.019495892794033
            ],
            [
              105.83197289316,
              21.019450440789317
            ],
            [
              105.83197244735996,
              21.01944637845886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12319",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019504094912755,
          "to_latitude": 21.01944637845886,
          "to_longitude": 105.83197244735996,
          "from_longitude": 105.83181501060409
        }
      },
      {
        "id": 12259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83210229556941,
            21.019481007020786,
            105.83211034060747,
            21.019540152361735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83211034060747,
              21.019540152361735
            ],
            [
              105.8321023627454,
              21.019481498046368
            ],
            [
              105.83210229556941,
              21.019481007020786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12320",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019540152361735,
          "to_latitude": 21.019481007020786,
          "to_longitude": 105.83210229556941,
          "from_longitude": 105.83211034060747
        }
      },
      {
        "id": 12260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174410920363,
            21.020563089335276,
            105.83189614919515,
            21.020591730751153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83174410920363,
              21.020591730751153
            ],
            [
              105.83189614919515,
              21.020563089335276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.1_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12321",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020591730751153,
          "to_latitude": 21.020563089335276,
          "to_longitude": 105.83189614919515,
          "from_longitude": 105.83174410920363
        }
      },
      {
        "id": 12261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83170930826599,
            21.020243657236623,
            105.83193297348889,
            21.02026743103775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83170930826599,
              21.02026743103775
            ],
            [
              105.83182913729743,
              21.02024691362541
            ],
            [
              105.8318696391967,
              21.020243657236623
            ],
            [
              105.83193297348889,
              21.020245450194192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.2_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12322",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02026743103775,
          "to_latitude": 21.020245450194192,
          "to_longitude": 105.83193297348889,
          "from_longitude": 105.83170930826599
        }
      },
      {
        "id": 12262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83191952347445,
            21.020096708825378,
            105.83213413522073,
            21.02012623094155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83191952347445,
              21.020096708825378
            ],
            [
              105.8319204373755,
              21.020102437122063
            ],
            [
              105.83192062165311,
              21.020104268863456
            ],
            [
              105.83194226355872,
              21.02009760741214
            ],
            [
              105.8320692392884,
              21.02012623094155
            ],
            [
              105.83208653790886,
              21.020118008268422
            ],
            [
              105.83210362906432,
              21.020112790733286
            ],
            [
              105.83213413522073,
              21.020108336730335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.3_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12323",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020096708825378,
          "to_latitude": 21.020108336730335,
          "to_longitude": 105.83213413522073,
          "from_longitude": 105.83191952347445
        }
      },
      {
        "id": 12263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83184323649515,
            21.020156673959754,
            105.83191124856941,
            21.020176634090063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184323649515,
              21.020156673959754
            ],
            [
              105.8318567280166,
              21.020164641260447
            ],
            [
              105.83191124856941,
              21.020176634090063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.3_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12324",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020156673959754,
          "to_latitude": 21.020176634090063,
          "to_longitude": 105.83191124856941,
          "from_longitude": 105.83184323649515
        }
      },
      {
        "id": 12264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316937194125,
            21.02010402147543,
            105.83192062165311,
            21.020181541955314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316937194125,
              21.020181541955314
            ],
            [
              105.83175813859556,
              21.020166479116224
            ],
            [
              105.83176474857926,
              21.020173299559296
            ],
            [
              105.83183612255256,
              21.020163227321053
            ],
            [
              105.83184323649515,
              21.020156673959754
            ],
            [
              105.83189283364537,
              21.020107270698094
            ],
            [
              105.83190047190652,
              21.02010402147543
            ],
            [
              105.83192062165311,
              21.020104268863456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.3_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12325",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020181541955314,
          "to_latitude": 21.020104268863456,
          "to_longitude": 105.83192062165311,
          "from_longitude": 105.8316937194125
        }
      },
      {
        "id": 12265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83207227351146,
            21.020025334296122,
            105.83209574268652,
            21.020107036933215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83207227351146,
              21.020106673029574
            ],
            [
              105.83209574268652,
              21.020107036933215
            ],
            [
              105.8320949311771,
              21.020096531885404
            ],
            [
              105.83209306465632,
              21.020054175268616
            ],
            [
              105.8320917945169,
              21.020025334296122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.4_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12326",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020106673029574,
          "to_latitude": 21.020025334296122,
          "to_longitude": 105.8320917945169,
          "from_longitude": 105.83207227351146
        }
      },
      {
        "id": 12266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194103710221,
            21.020012594507524,
            105.83209306465632,
            21.020054175268616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83209306465632,
              21.020054175268616
            ],
            [
              105.83203427951642,
              21.02005219753608
            ],
            [
              105.83202883361301,
              21.020021470281193
            ],
            [
              105.83201420193669,
              21.020012594507524
            ],
            [
              105.83196553077482,
              21.02001475282238
            ],
            [
              105.83194103710221,
              21.020019934290637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.4_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12327",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020054175268616,
          "to_latitude": 21.020019934290637,
          "to_longitude": 105.83194103710221,
          "from_longitude": 105.83209306465632
        }
      },
      {
        "id": 12267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187639557322,
            21.020051085617478,
            105.83207227351146,
            21.020121822066734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83207113394725,
              21.020121822066734
            ],
            [
              105.8320712028648,
              21.020120893214774
            ],
            [
              105.83207227351146,
              21.020106673029574
            ],
            [
              105.8320602693651,
              21.020106366846896
            ],
            [
              105.83195132989324,
              21.020088382694677
            ],
            [
              105.83188522320026,
              21.020096112449085
            ],
            [
              105.83187639557322,
              21.020051085617478
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.4_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12328",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020121822066734,
          "to_latitude": 21.020051085617478,
          "to_longitude": 105.83187639557322,
          "from_longitude": 105.83207113394725
        }
      },
      {
        "id": 12268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83215725692375,
            21.02010329020392,
            105.8321995345148,
            21.02037719936358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83215725692375,
              21.02010329020392
            ],
            [
              105.83215810216929,
              21.020106179097
            ],
            [
              105.83215849201356,
              21.020116971626216
            ],
            [
              105.83217020232077,
              21.02018775152673
            ],
            [
              105.83218649421012,
              21.020292976961358
            ],
            [
              105.8321995345148,
              21.02037719936358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.5_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12329",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02010329020392,
          "to_latitude": 21.02037719936358,
          "to_longitude": 105.8321995345148,
          "from_longitude": 105.83215725692375
        }
      },
      {
        "id": 12269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83181498778951,
            21.020292976961358,
            105.83218649421012,
            21.020321322718466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83218649421012,
              21.020292976961358
            ],
            [
              105.83209823833278,
              21.020298485745595
            ],
            [
              105.83189331037174,
              21.020315458016718
            ],
            [
              105.83181498778951,
              21.020321322718466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.5_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12330",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020292976961358,
          "to_latitude": 21.020321322718466,
          "to_longitude": 105.83181498778951,
          "from_longitude": 105.83218649421012
        }
      },
      {
        "id": 12270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320639815197,
            21.01958190142086,
            105.83219208895805,
            21.019915932250676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219208895805,
              21.01958190142086
            ],
            [
              105.83207869179321,
              21.019614135854198
            ],
            [
              105.83208868309029,
              21.019651127317633
            ],
            [
              105.8320639815197,
              21.019657663752646
            ],
            [
              105.83211131344558,
              21.019855209499084
            ],
            [
              105.8320768282944,
              21.019893232334073
            ],
            [
              105.83207939763734,
              21.019915932250676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.1_Ngách 1, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12331",
          "diaChiLapD": "Ngách 1, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01958190142086,
          "to_latitude": 21.019915932250676,
          "to_longitude": 105.83207939763734,
          "from_longitude": 105.83219208895805
        }
      },
      {
        "id": 12271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217389998862,
            21.0194768162245,
            105.83220313283307,
            21.019605401681275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217389998862,
              21.0194768162245
            ],
            [
              105.83220313283307,
              21.019605401681275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.2_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12332",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0194768162245,
          "to_latitude": 21.019605401681275,
          "to_longitude": 105.83220313283307,
          "from_longitude": 105.83217389998862
        }
      },
      {
        "id": 12272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83220313283307,
            21.019605401681275,
            105.83223001077415,
            21.01967659873017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220313283307,
              21.019605401681275
            ],
            [
              105.8322218896516,
              21.019652115915758
            ],
            [
              105.83222371877368,
              21.019657913797577
            ],
            [
              105.8322275914617,
              21.019670189543266
            ],
            [
              105.83223001077415,
              21.01967659873017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.2_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12333",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019605401681275,
          "to_latitude": 21.01967659873017,
          "to_longitude": 105.83223001077415,
          "from_longitude": 105.83220313283307
        }
      },
      {
        "id": 12273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217657219384,
            21.019598313955676,
            105.83225290517122,
            21.019789555076798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217657219384,
              21.019598313955676
            ],
            [
              105.83222028037468,
              21.019716614926406
            ],
            [
              105.83225290517122,
              21.019789555076798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.4_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12334",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019598313955676,
          "to_latitude": 21.019789555076798,
          "to_longitude": 105.83225290517122,
          "from_longitude": 105.83217657219384
        }
      },
      {
        "id": 12274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225290517122,
            21.019772791029553,
            105.83233494997228,
            21.01999830130083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227446406048,
              21.01999830130083
            ],
            [
              105.83233494997228,
              21.01997896807843
            ],
            [
              105.83225290517122,
              21.019789555076798
            ],
            [
              105.83226049351208,
              21.01978615524427
            ],
            [
              105.83227040499415,
              21.019781715272053
            ],
            [
              105.83226679139055,
              21.019772791029553
            ],
            [
              105.83225673062778,
              21.01977702579675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.4_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12335",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01999830130083,
          "to_latitude": 21.01977702579675,
          "to_longitude": 105.83225673062778,
          "from_longitude": 105.83227446406048
        }
      },
      {
        "id": 12275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83228436620513,
            21.019791405537273,
            105.83250151328788,
            21.019841728555626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83228436620513,
              21.019841728555626
            ],
            [
              105.8322947967462,
              21.019839122297697
            ],
            [
              105.83229589997748,
              21.019837016902613
            ],
            [
              105.8322964453982,
              21.019835974622453
            ],
            [
              105.83233419471948,
              21.019817936776644
            ],
            [
              105.83242794474316,
              21.019791405537273
            ],
            [
              105.83244496908681,
              21.01983082836124
            ],
            [
              105.83248356898879,
              21.019821668630037
            ],
            [
              105.83250151328788,
              21.019823023258382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.5_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12336",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019841728555626,
          "to_latitude": 21.019823023258382,
          "to_longitude": 105.83250151328788,
          "from_longitude": 105.83228436620513
        }
      },
      {
        "id": 12276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8322405026232,
            21.019700988654854,
            105.83229589997748,
            21.019837016902613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83229589997748,
              21.019837016902613
            ],
            [
              105.83229289033721,
              21.019829626090583
            ],
            [
              105.83229124702976,
              21.0198255903949
            ],
            [
              105.8322405026232,
              21.019700988654854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.5_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12337",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019837016902613,
          "to_latitude": 21.019700988654854,
          "to_longitude": 105.8322405026232,
          "from_longitude": 105.83229589997748
        }
      },
      {
        "id": 12277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263533464985,
            21.01968291086016,
            105.83289081009652,
            21.019713492544767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263533464985,
              21.019704458629327
            ],
            [
              105.83264335653365,
              21.01970360555741
            ],
            [
              105.83268860846303,
              21.019698794761243
            ],
            [
              105.83283800738673,
              21.01968291086016
            ],
            [
              105.83286469277802,
              21.019713492544767
            ],
            [
              105.83289081009652,
              21.01971089795942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12338",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019704458629327,
          "to_latitude": 21.01971089795942,
          "to_longitude": 105.83289081009652,
          "from_longitude": 105.83263533464985
        }
      },
      {
        "id": 12278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83268860846303,
            21.019698794761243,
            105.83269995859023,
            21.019800820726676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83268860846303,
              21.019698794761243
            ],
            [
              105.8326888140072,
              21.019700643558803
            ],
            [
              105.83269995859023,
              21.019800820726676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12339",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019698794761243,
          "to_latitude": 21.019800820726676,
          "to_longitude": 105.83269995859023,
          "from_longitude": 105.83268860846303
        }
      },
      {
        "id": 12279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278655951699,
            21.01943638104362,
            105.83283398208934,
            21.019620473499845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278655951699,
              21.01943638104362
            ],
            [
              105.83278656796983,
              21.01943651738913
            ],
            [
              105.8327948842648,
              21.019573562667702
            ],
            [
              105.83283398208934,
              21.019620473499845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12340",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01943638104362,
          "to_latitude": 21.019620473499845,
          "to_longitude": 105.83283398208934,
          "from_longitude": 105.83278655951699
        }
      },
      {
        "id": 12280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265180534602,
            21.01944387902649,
            105.83266594489504,
            21.019609621961855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265180534602,
              21.01944387902649
            ],
            [
              105.83265184653644,
              21.019444367469323
            ],
            [
              105.83266594489504,
              21.019609621961855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12341",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01944387902649,
          "to_latitude": 21.019609621961855,
          "to_longitude": 105.83266594489504,
          "from_longitude": 105.83265180534602
        }
      },
      {
        "id": 12281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8330540339368,
            21.019420987365226,
            105.83306432305346,
            21.019509992196383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330540339368,
              21.019420987365226
            ],
            [
              105.83305406221561,
              21.01942122929089
            ],
            [
              105.83306432305346,
              21.019509992196383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12342",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019420987365226,
          "to_latitude": 21.019509992196383,
          "to_longitude": 105.83306432305346,
          "from_longitude": 105.8330540339368
        }
      },
      {
        "id": 12282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329328449021,
            21.01966423434309,
            105.83315509543974,
            21.019690267921284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329328449021,
              21.019689750875447
            ],
            [
              105.8329745232358,
              21.019683055311553
            ],
            [
              105.8329878481545,
              21.019687003989528
            ],
            [
              105.83301290185679,
              21.01968412104621
            ],
            [
              105.83302148208035,
              21.019690267921284
            ],
            [
              105.83307128039914,
              21.01968427592224
            ],
            [
              105.83309224576102,
              21.01968849336598
            ],
            [
              105.83315509543974,
              21.019681536906916
            ],
            [
              105.83315222597273,
              21.01966423434309
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.1_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12343",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019689750875447,
          "to_latitude": 21.01966423434309,
          "to_longitude": 105.83315222597273,
          "from_longitude": 105.8329328449021
        }
      },
      {
        "id": 12283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313553031176,
            21.01956418194816,
            105.83316133590313,
            21.01966423434309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83316133590313,
              21.01966304804723
            ],
            [
              105.83315222597273,
              21.01966423434309
            ],
            [
              105.83314803288377,
              21.019626853260892
            ],
            [
              105.83313553031176,
              21.01956418194816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.1_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12344",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01966304804723,
          "to_latitude": 21.01956418194816,
          "to_longitude": 105.83313553031176,
          "from_longitude": 105.83316133590313
        }
      },
      {
        "id": 12284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83292671168398,
            21.019689028001075,
            105.83316032536268,
            21.019721485293303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292671168398,
              21.01969828386257
            ],
            [
              105.83297186726254,
              21.01969102301523
            ],
            [
              105.83298916617224,
              21.01969412758891
            ],
            [
              105.83300918345182,
              21.01969149240578
            ],
            [
              105.83302000467881,
              21.019697745725633
            ],
            [
              105.83306964376798,
              21.01969218173192
            ],
            [
              105.83309052928162,
              21.01969535905238
            ],
            [
              105.83315551510968,
              21.019689028001075
            ],
            [
              105.83315635762544,
              21.019694712381753
            ],
            [
              105.83316032536268,
              21.019721485293303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.2_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12345",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01969828386257,
          "to_latitude": 21.019721485293303,
          "to_longitude": 105.83316032536268,
          "from_longitude": 105.83292671168398
        }
      },
      {
        "id": 12285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83316032536268,
            21.019721485293303,
            105.8331884630622,
            21.019878919483144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331884630622,
              21.019878919483144
            ],
            [
              105.83316032536268,
              21.019721485293303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.2_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12346",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019878919483144,
          "to_latitude": 21.019721485293303,
          "to_longitude": 105.83316032536268,
          "from_longitude": 105.8331884630622
        }
      },
      {
        "id": 12286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314111045729,
            21.019488652281268,
            105.83318963705726,
            21.019787746779325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314111045729,
              21.019488652281268
            ],
            [
              105.83315104723462,
              21.01958314049959
            ],
            [
              105.83316198417397,
              21.019627536410788
            ],
            [
              105.83317219522944,
              21.019695896613275
            ],
            [
              105.83317671125913,
              21.01972612709144
            ],
            [
              105.83318963705726,
              21.019787746779325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.3_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12347",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019488652281268,
          "to_latitude": 21.019787746779325,
          "to_longitude": 105.83318963705726,
          "from_longitude": 105.83314111045729
        }
      },
      {
        "id": 12287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318963705726,
            21.019787746779325,
            105.83320266474156,
            21.019889690418843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318963705726,
              21.019787746779325
            ],
            [
              105.83320266474156,
              21.019889690418843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.3_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12348",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019787746779325,
          "to_latitude": 21.019889690418843,
          "to_longitude": 105.83320266474156,
          "from_longitude": 105.83318963705726
        }
      },
      {
        "id": 12288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83211574550249,
            21.02006984282229,
            105.83221418831882,
            21.020088616986428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83211574550249,
              21.02008617936899
            ],
            [
              105.83216818345275,
              21.020081368779824
            ],
            [
              105.83220527348773,
              21.02006984282229
            ],
            [
              105.8322139303974,
              21.02008807631359
            ],
            [
              105.83221418831882,
              21.020088616986428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.6_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12349",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02008617936899,
          "to_latitude": 21.020088616986428,
          "to_longitude": 105.83221418831882,
          "from_longitude": 105.83211574550249
        }
      },
      {
        "id": 12289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83220527348773,
            21.0200027293518,
            105.83226434938237,
            21.02006984282229
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220527348773,
              21.02006984282229
            ],
            [
              105.83221692204418,
              21.02006622351428
            ],
            [
              105.83226434938237,
              21.02004950310463
            ],
            [
              105.83224982096462,
              21.0200027293518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.6_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12350",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02006984282229,
          "to_latitude": 21.0200027293518,
          "to_longitude": 105.83224982096462,
          "from_longitude": 105.83220527348773
        }
      },
      {
        "id": 12290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208863766367,
            21.019934897296057,
            105.83226705623974,
            21.020011003463555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83226705623974,
              21.020011003463555
            ],
            [
              105.83226422404037,
              21.02000559887701
            ],
            [
              105.83226130680963,
              21.020000126061387
            ],
            [
              105.83223043877535,
              21.019995009483406
            ],
            [
              105.83220770775027,
              21.019934897296057
            ],
            [
              105.83208863766367,
              21.019947029330837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.8_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12351",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020011003463555,
          "to_latitude": 21.019947029330837,
          "to_longitude": 105.83208863766367,
          "from_longitude": 105.83226705623974
        }
      },
      {
        "id": 12291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234516084735,
            21.01991155693375,
            105.83247926201196,
            21.019954981761334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234516084735,
              21.019954981761334
            ],
            [
              105.83247926201196,
              21.01991155693375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12352",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019954981761334,
          "to_latitude": 21.01991155693375,
          "to_longitude": 105.83247926201196,
          "from_longitude": 105.83234516084735
        }
      },
      {
        "id": 12292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83229516039263,
            21.01984207315943,
            105.83234131072368,
            21.019940188622062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83229516039263,
              21.01984207315943
            ],
            [
              105.83234131072368,
              21.019940188622062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12353",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01984207315943,
          "to_latitude": 21.019940188622062,
          "to_longitude": 105.83234131072368,
          "from_longitude": 105.83229516039263
        }
      },
      {
        "id": 12293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83233005113154,
            21.019940188622062,
            105.83236914166456,
            21.020006627308813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83233005113154,
              21.01994455842103
            ],
            [
              105.83233859421765,
              21.019941243332404
            ],
            [
              105.83234131072368,
              21.019940188622062
            ],
            [
              105.83234516084735,
              21.019954981761334
            ],
            [
              105.83236914166456,
              21.020006627308813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12354",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01994455842103,
          "to_latitude": 21.020006627308813,
          "to_longitude": 105.83236914166456,
          "from_longitude": 105.83233005113154
        }
      },
      {
        "id": 12294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83236010602707,
            21.019909546791364,
            105.83256978517666,
            21.020009836113893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83236010602707,
              21.020009765067588
            ],
            [
              105.83236563780012,
              21.02000759735648
            ],
            [
              105.8323754892059,
              21.020009836113893
            ],
            [
              105.83249398395773,
              21.019980704395596
            ],
            [
              105.83250083282392,
              21.019978913201317
            ],
            [
              105.832523568571,
              21.019972968903275
            ],
            [
              105.83253212725543,
              21.01997073127548
            ],
            [
              105.83256978517666,
              21.019909546791364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.1_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12355",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020009765067588,
          "to_latitude": 21.019909546791364,
          "to_longitude": 105.83256978517666,
          "from_longitude": 105.83236010602707
        }
      },
      {
        "id": 12295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8325158729746,
            21.019920314257487,
            105.832523568571,
            21.019972968903275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832523568571,
              21.019972968903275
            ],
            [
              105.8325158729746,
              21.019920314257487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.1_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12356",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.88,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019972968903275,
          "to_latitude": 21.019920314257487,
          "to_longitude": 105.8325158729746,
          "from_longitude": 105.832523568571
        }
      },
      {
        "id": 12296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249402954013,
            21.019978913201317,
            105.83254757745038,
            21.02010570773128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83250083282392,
              21.019978913201317
            ],
            [
              105.83250154776691,
              21.019980509312816
            ],
            [
              105.8325034714982,
              21.019984805568846
            ],
            [
              105.83253038623253,
              21.020044917107125
            ],
            [
              105.83254757745038,
              21.020078000272008
            ],
            [
              105.83249402954013,
              21.02010570773128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.1_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12357",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019978913201317,
          "to_latitude": 21.02010570773128,
          "to_longitude": 105.83249402954013,
          "from_longitude": 105.83250083282392
        }
      },
      {
        "id": 12297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263880071549,
            21.0200010894849,
            105.83267820085126,
            21.02010121956312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263880071549,
              21.0200010894849
            ],
            [
              105.83263932996296,
              21.020002159021686
            ],
            [
              105.8326617693457,
              21.020047482272876
            ],
            [
              105.83267820085126,
              21.02010121956312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.2_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12358",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0200010894849,
          "to_latitude": 21.02010121956312,
          "to_longitude": 105.83267820085126,
          "from_longitude": 105.83263880071549
        }
      },
      {
        "id": 12298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83269694089655,
            21.019980469683833,
            105.83273955298463,
            21.020086301434144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83269694089655,
              21.019980469683833
            ],
            [
              105.8326977124525,
              21.019982386162003
            ],
            [
              105.83273955298463,
              21.020086301434144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.2_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12359",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019980469683833,
          "to_latitude": 21.020086301434144,
          "to_longitude": 105.83273955298463,
          "from_longitude": 105.83269694089655
        }
      },
      {
        "id": 12299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83273285305532,
            21.019960043585982,
            105.83277076472791,
            21.0200460788855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83273285305532,
              21.019960043585982
            ],
            [
              105.8327634199089,
              21.0200294106112
            ],
            [
              105.83277076472791,
              21.0200460788855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.2_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12360",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019960043585982,
          "to_latitude": 21.0200460788855,
          "to_longitude": 105.83277076472791,
          "from_longitude": 105.83273285305532
        }
      },
      {
        "id": 12300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8327634199089,
            21.01997034290457,
            105.8328651592447,
            21.0200294106112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8327634199089,
              21.0200294106112
            ],
            [
              105.83276701185163,
              21.02002732554524
            ],
            [
              105.8328651592447,
              21.01997034290457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.2_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12361",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0200294106112,
          "to_latitude": 21.01997034290457,
          "to_longitude": 105.8328651592447,
          "from_longitude": 105.8327634199089
        }
      },
      {
        "id": 12301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83227988402324,
            21.020050825981627,
            105.83235258147597,
            21.020364348210595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227988402324,
              21.02005207805997
            ],
            [
              105.83228265140022,
              21.020051349165655
            ],
            [
              105.83228472634185,
              21.020050825981627
            ],
            [
              105.83228775700822,
              21.020061311137784
            ],
            [
              105.83229037084524,
              21.02007035588386
            ],
            [
              105.83232888519169,
              21.020183035267507
            ],
            [
              105.83234512470754,
              21.020221785424358
            ],
            [
              105.83229554948088,
              21.020241146556927
            ],
            [
              105.83231393288676,
              21.0202973419429
            ],
            [
              105.83233900634802,
              21.020293318226578
            ],
            [
              105.83235258147597,
              21.020364348210595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.7_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12362",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02005207805997,
          "to_latitude": 21.020364348210595,
          "to_longitude": 105.83235258147597,
          "from_longitude": 105.83227988402324
        }
      },
      {
        "id": 12302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83216363132334,
            21.020061311137784,
            105.83228775700822,
            21.020106138498896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83228775700822,
              21.020061311137784
            ],
            [
              105.83221374812075,
              21.020090419266587
            ],
            [
              105.83216363132334,
              21.020106138498896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_100.7_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12363",
          "diaChiLapD": "Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020061311137784,
          "to_latitude": 21.020106138498896,
          "to_longitude": 105.83216363132334,
          "from_longitude": 105.83228775700822
        }
      },
      {
        "id": 12303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83266710896879,
            21.019907505688714,
            105.83275014680541,
            21.01998417597448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83268653473901,
              21.01998417597448
            ],
            [
              105.83266710896879,
              21.019942796806937
            ],
            [
              105.83273544432033,
              21.019907505688714
            ],
            [
              105.83275014680541,
              21.019933977671602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.2_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12364",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01998417597448,
          "to_latitude": 21.019933977671602,
          "to_longitude": 105.83275014680541,
          "from_longitude": 105.83268653473901
        }
      },
      {
        "id": 12304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323738047491,
            21.020052979649364,
            105.83244841176902,
            21.020248775033696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323738047491,
              21.020052979649364
            ],
            [
              105.83240723125796,
              21.020121457523903
            ],
            [
              105.83239623460607,
              21.020124847848614
            ],
            [
              105.83244841176902,
              21.020248775033696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.4_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12365",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020052979649364,
          "to_latitude": 21.020248775033696,
          "to_longitude": 105.83244841176902,
          "from_longitude": 105.8323738047491
        }
      },
      {
        "id": 12305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244205973422,
            21.020376228932925,
            105.83250232282634,
            21.020394135839258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83250232282634,
              21.020376228932925
            ],
            [
              105.83250199149313,
              21.02037632719957
            ],
            [
              105.83244205973422,
              21.020394135839258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.4_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12366",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020376228932925,
          "to_latitude": 21.020394135839258,
          "to_longitude": 105.83244205973422,
          "from_longitude": 105.83250232282634
        }
      },
      {
        "id": 12306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244841176902,
            21.020240096847154,
            105.8325693177467,
            21.020542153936436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325693177467,
              21.020542153936436
            ],
            [
              105.83254963357284,
              21.02049339848697
            ],
            [
              105.83250288136456,
              21.020377549423095
            ],
            [
              105.83250232282634,
              21.020376228932925
            ],
            [
              105.83244841176902,
              21.020248775033696
            ],
            [
              105.83247295915679,
              21.020240096847154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.4_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12367",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020542153936436,
          "to_latitude": 21.020240096847154,
          "to_longitude": 105.83247295915679,
          "from_longitude": 105.8325693177467
        }
      },
      {
        "id": 12307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83238432340076,
            21.02004354186756,
            105.83247857460098,
            21.020211411858853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83238432340076,
              21.02004354186756
            ],
            [
              105.8324104198997,
              21.020102345044368
            ],
            [
              105.83241308712083,
              21.020108354692738
            ],
            [
              105.8324257616751,
              21.020135680239843
            ],
            [
              105.83244603506951,
              21.020128741809614
            ],
            [
              105.8324469307908,
              21.0201299784565
            ],
            [
              105.83247857460098,
              21.020211411858853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12368",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02004354186756,
          "to_latitude": 21.020211411858853,
          "to_longitude": 105.83247857460098,
          "from_longitude": 105.83238432340076
        }
      },
      {
        "id": 12308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83247857460098,
            21.020176671589688,
            105.83260821350972,
            21.020211411858853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83260821350972,
              21.020176671589688
            ],
            [
              105.83248600678161,
              21.020208990972325
            ],
            [
              105.83247857460098,
              21.020211411858853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12369",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020176671589688,
          "to_latitude": 21.020211411858853,
          "to_longitude": 105.83247857460098,
          "from_longitude": 105.83260821350972
        }
      },
      {
        "id": 12309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83255366246478,
            21.02040558264292,
            105.83259543363671,
            21.020526800451375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83259543363671,
              21.020526800451375
            ],
            [
              105.83258443241155,
              21.02049489280094
            ],
            [
              105.83255366246478,
              21.02040558264292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.5_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12370",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020526800451375,
          "to_latitude": 21.02040558264292,
          "to_longitude": 105.83255366246478,
          "from_longitude": 105.83259543363671
        }
      },
      {
        "id": 12310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249548233682,
            21.020219767936187,
            105.83255366246478,
            21.020408681461383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83254243522919,
              21.020408681461383
            ],
            [
              105.83255366246478,
              21.02040558264292
            ],
            [
              105.83249548233682,
              21.020219767936187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.5_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12371",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020408681461383,
          "to_latitude": 21.020219767936187,
          "to_longitude": 105.83249548233682,
          "from_longitude": 105.83254243522919
        }
      },
      {
        "id": 12311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83322548035144,
            21.019375610556914,
            105.83333142645706,
            21.01967830851038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83322548035144,
              21.019375610556914
            ],
            [
              105.83323833381021,
              21.01941451811695
            ],
            [
              105.8332883779141,
              21.019565999318278
            ],
            [
              105.83329079466128,
              21.019573150951945
            ],
            [
              105.83333142645706,
              21.01967830851038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12372",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019375610556914,
          "to_latitude": 21.01967830851038,
          "to_longitude": 105.83333142645706,
          "from_longitude": 105.83322548035144
        }
      },
      {
        "id": 12312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83219110113976,
            21.019462061269742,
            105.83234463748794,
            21.01946924524418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219110113976,
              21.01946924524418
            ],
            [
              105.83234463748794,
              21.019462061269742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12373",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01946924524418,
          "to_latitude": 21.019462061269742,
          "to_longitude": 105.83234463748794,
          "from_longitude": 105.83219110113976
        }
      },
      {
        "id": 12313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234138581707,
            21.019425100973148,
            105.83248530985945,
            21.019462061269742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234138581707,
              21.019425100973148
            ],
            [
              105.83234180546535,
              21.0194279160772
            ],
            [
              105.83234463748794,
              21.019462061269742
            ],
            [
              105.83238857064029,
              21.01945762100755
            ],
            [
              105.83248530985945,
              21.019451475344358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12374",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019425100973148,
          "to_latitude": 21.019451475344358,
          "to_longitude": 105.83248530985945,
          "from_longitude": 105.83234138581707
        }
      },
      {
        "id": 12314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83333889867528,
            21.01962814901308,
            105.83342873401185,
            21.019701968898485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83342873401185,
              21.01962814901308
            ],
            [
              105.83333889867528,
              21.019701968898485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12375",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01962814901308,
          "to_latitude": 21.019701968898485,
          "to_longitude": 105.83333889867528,
          "from_longitude": 105.83342873401185
        }
      },
      {
        "id": 12315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83337084833987,
            21.019691607520453,
            105.83348223994557,
            21.019834559518962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83348223994557,
              21.019691607520453
            ],
            [
              105.83337084833987,
              21.019791806938294
            ],
            [
              105.83340729712921,
              21.019834559518962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12376",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019691607520453,
          "to_latitude": 21.019834559518962,
          "to_longitude": 105.83340729712921,
          "from_longitude": 105.83348223994557
        }
      },
      {
        "id": 12316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83343020754573,
            21.019569263306497,
            105.83351224885052,
            21.019593389785598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83343020754573,
              21.019569263306497
            ],
            [
              105.83348726093678,
              21.019593389785598
            ],
            [
              105.83351224885052,
              21.0195838674895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12377",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019569263306497,
          "to_latitude": 21.0195838674895,
          "to_longitude": 105.83351224885052,
          "from_longitude": 105.83343020754573
        }
      },
      {
        "id": 12317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315661734208,
            21.019392773040586,
            105.83353053273413,
            21.019417152955107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315661734208,
              21.019417152955107
            ],
            [
              105.83321647097239,
              21.019414109467483
            ],
            [
              105.8332355616163,
              21.019413165117715
            ],
            [
              105.83323833381021,
              21.01941451811695
            ],
            [
              105.83324075285155,
              21.019412907560262
            ],
            [
              105.83324632780847,
              21.01941263186498
            ],
            [
              105.83353053273413,
              21.019392773040586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12378",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019417152955107,
          "to_latitude": 21.019392773040586,
          "to_longitude": 105.83353053273413,
          "from_longitude": 105.83315661734208
        }
      },
      {
        "id": 12318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83352758921424,
            21.01934813476071,
            105.83371980961735,
            21.019392773040586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83352758921424,
              21.01934813476071
            ],
            [
              105.83352865423076,
              21.01935352269478
            ],
            [
              105.83352867983922,
              21.019354149407018
            ],
            [
              105.83353053273413,
              21.019392773040586
            ],
            [
              105.8335345825328,
              21.01939258971241
            ],
            [
              105.83371980961735,
              21.019384208693847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12379",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01934813476071,
          "to_latitude": 21.019384208693847,
          "to_longitude": 105.83371980961735,
          "from_longitude": 105.83352758921424
        }
      },
      {
        "id": 12319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83380388550475,
            21.01955723340713,
            105.83391293207318,
            21.0198909063635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83380388550475,
              21.0195702907417
            ],
            [
              105.83380743585579,
              21.019569207534012
            ],
            [
              105.83384670005597,
              21.01955723340713
            ],
            [
              105.83391293207318,
              21.019767412368218
            ],
            [
              105.8338632022536,
              21.019777935753908
            ],
            [
              105.8338759492505,
              21.0198909063635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12380",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0195702907417,
          "to_latitude": 21.0198909063635,
          "to_longitude": 105.8338759492505,
          "from_longitude": 105.83380388550475
        }
      },
      {
        "id": 12320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83391672112703,
            21.019366225821635,
            105.83397814749513,
            21.019556914160287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83391672112703,
              21.019366225821635
            ],
            [
              105.83391899991167,
              21.01937330044484
            ],
            [
              105.83397814749513,
              21.019556914160287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12381",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019366225821635,
          "to_latitude": 21.019556914160287,
          "to_longitude": 105.83397814749513,
          "from_longitude": 105.83391672112703
        }
      },
      {
        "id": 12321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83405705178758,
            21.019359450076927,
            105.83409586210401,
            21.01952600762283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405705178758,
              21.019359450076927
            ],
            [
              105.83409586210401,
              21.01952600762283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12382",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019359450076927,
          "to_latitude": 21.01952600762283,
          "to_longitude": 105.83409586210401,
          "from_longitude": 105.83405705178758
        }
      },
      {
        "id": 12322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425872321142,
            21.01934617546369,
            105.8342985516024,
            21.019571713925504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425872321142,
              21.01934617546369
            ],
            [
              105.83426461132207,
              21.019394065342052
            ],
            [
              105.8342985516024,
              21.019571713925504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12383",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01934617546369,
          "to_latitude": 21.019571713925504,
          "to_longitude": 105.8342985516024,
          "from_longitude": 105.83425872321142
        }
      },
      {
        "id": 12323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421993292284,
            21.019643404543068,
            105.83424813675317,
            21.019794373827743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421993292284,
              21.019643404543068
            ],
            [
              105.83424813675317,
              21.019794373827743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.3_Ngách 3, Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12384",
          "diaChiLapD": "Ngách 3, Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019643404543068,
          "to_latitude": 21.019794373827743,
          "to_longitude": 105.83424813675317,
          "from_longitude": 105.83421993292284
        }
      },
      {
        "id": 12324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441597410598,
            21.01957417814956,
            105.83464941505612,
            21.0197100457283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441597410598,
              21.019617121110304
            ],
            [
              105.8344162208813,
              21.01961704041606
            ],
            [
              105.83442311313412,
              21.019614755772157
            ],
            [
              105.8345384257181,
              21.01957417814956
            ],
            [
              105.83456258735467,
              21.0196512789531
            ],
            [
              105.83463133546813,
              21.019647913133998
            ],
            [
              105.83464941505612,
              21.0197100457283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.2_Ngách 2, Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12385",
          "diaChiLapD": "Ngách 2, Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019617121110304,
          "to_latitude": 21.0197100457283,
          "to_longitude": 105.83464941505612,
          "from_longitude": 105.83441597410598
        }
      },
      {
        "id": 12325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8345384257181,
            21.019504467988813,
            105.83457628275178,
            21.01957417814956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345384257181,
              21.01957417814956
            ],
            [
              105.83457628275178,
              21.019567088170064
            ],
            [
              105.8345693996556,
              21.019504467988813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.2_Ngách 2, Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12386",
          "diaChiLapD": "Ngách 2, Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01957417814956,
          "to_latitude": 21.019504467988813,
          "to_longitude": 105.8345693996556,
          "from_longitude": 105.8345384257181
        }
      },
      {
        "id": 12326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463133546813,
            21.019597953593568,
            105.83467743745489,
            21.019647913133998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463133546813,
              21.019647913133998
            ],
            [
              105.83464196942225,
              21.019645741992793
            ],
            [
              105.83464343089791,
              21.019613639654555
            ],
            [
              105.83467743745489,
              21.019597953593568
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.2_Ngách 2, Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12387",
          "diaChiLapD": "Ngách 2, Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019647913133998,
          "to_latitude": 21.019597953593568,
          "to_longitude": 105.83467743745489,
          "from_longitude": 105.83463133546813
        }
      },
      {
        "id": 12327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83461910903138,
            21.019320044038693,
            105.83464973701408,
            21.01947781431832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83461910903138,
              21.019320044038693
            ],
            [
              105.8346197482216,
              21.01932686566449
            ],
            [
              105.83463336604665,
              21.01947781431832
            ],
            [
              105.83464973701408,
              21.01947614160718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12388",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019320044038693,
          "to_latitude": 21.01947614160718,
          "to_longitude": 105.83464973701408,
          "from_longitude": 105.83461910903138
        }
      },
      {
        "id": 12328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83477048993942,
            21.01926240374368,
            105.83486899698224,
            21.019534180053018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83485105538479,
              21.01926240374368
            ],
            [
              105.83485137872788,
              21.01926709169659
            ],
            [
              105.83485192188137,
              21.019274976887445
            ],
            [
              105.83486899698224,
              21.019521610565203
            ],
            [
              105.83477048993942,
              21.019534180053018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12389",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01926240374368,
          "to_latitude": 21.019534180053018,
          "to_longitude": 105.83477048993942,
          "from_longitude": 105.83485105538479
        }
      },
      {
        "id": 12329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83496807147121,
            21.019499533434175,
            105.8350439596993,
            21.01954427622723
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496807147121,
              21.01950435953452
            ],
            [
              105.83503802729096,
              21.019499533434175
            ],
            [
              105.8350439596993,
              21.01954427622723
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112.3_Ngõ 234, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12390",
          "diaChiLapD": "Ngõ 234, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01950435953452,
          "to_latitude": 21.01954427622723,
          "to_longitude": 105.8350439596993,
          "from_longitude": 105.83496807147121
        }
      },
      {
        "id": 12330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488235723365,
            21.019286697434495,
            105.83515454279267,
            21.0193050250051
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488235723365,
              21.0193050250051
            ],
            [
              105.83492650273462,
              21.019301430272634
            ],
            [
              105.83495667183615,
              21.019299376387693
            ],
            [
              105.83498592165483,
              21.019297385719437
            ],
            [
              105.83515454279267,
              21.019286697434495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12391",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0193050250051,
          "to_latitude": 21.019286697434495,
          "to_longitude": 105.83515454279267,
          "from_longitude": 105.83488235723365
        }
      },
      {
        "id": 12331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83514984097856,
            21.019246321110796,
            105.83529244641302,
            21.019286697434495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514984097856,
              21.019246321110796
            ],
            [
              105.83515006834844,
              21.019248283603712
            ],
            [
              105.8351505837316,
              21.019252733968244
            ],
            [
              105.83515454279267,
              21.019286697434495
            ],
            [
              105.83529244641302,
              21.019278048464454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12392",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019246321110796,
          "to_latitude": 21.019278048464454,
          "to_longitude": 105.83529244641302,
          "from_longitude": 105.83514984097856
        }
      },
      {
        "id": 12332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83495313320026,
            21.019253967964413,
            105.83497410857665,
            21.019557146862983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83497373044769,
              21.019253967964413
            ],
            [
              105.83497410857665,
              21.019258992550686
            ],
            [
              105.83497103528073,
              21.01926610607469
            ],
            [
              105.83495667183615,
              21.019299376387693
            ],
            [
              105.83495313320026,
              21.019307571548353
            ],
            [
              105.83496807147121,
              21.01950435953452
            ],
            [
              105.83497207855184,
              21.019557146862983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_112.3_Ngõ 234, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12393",
          "diaChiLapD": "Ngõ 234, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019253967964413,
          "to_latitude": 21.019557146862983,
          "to_longitude": 105.83497207855184,
          "from_longitude": 105.83497373044769
        }
      },
      {
        "id": 12333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83088134055994,
            21.020210650936427,
            105.83103382169529,
            21.020275620527553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83103382169529,
              21.020210650936427
            ],
            [
              105.83101692056533,
              21.020218748723842
            ],
            [
              105.83092357856131,
              21.02025790338403
            ],
            [
              105.83088134055994,
              21.020275620527553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.1_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12394",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020210650936427,
          "to_latitude": 21.020275620527553,
          "to_longitude": 105.83088134055994,
          "from_longitude": 105.83103382169529
        }
      },
      {
        "id": 12334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537644773281,
            21.019518104008622,
            105.83543129290844,
            21.019626873666315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543129290844,
              21.019626873666315
            ],
            [
              105.83540110413318,
              21.019518169886688
            ],
            [
              105.83537742991386,
              21.01951810731414
            ],
            [
              105.83537644773281,
              21.019518104008622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.3_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12395",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019626873666315,
          "to_latitude": 21.019518104008622,
          "to_longitude": 105.83537644773281,
          "from_longitude": 105.83543129290844
        }
      },
      {
        "id": 12335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537843157636,
            21.019424902318438,
            105.83540110413318,
            21.019518169886688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540110413318,
              21.019518169886688
            ],
            [
              105.83537843157636,
              21.019424902318438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.3_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12396",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019518169886688,
          "to_latitude": 21.019424902318438,
          "to_longitude": 105.83537843157636,
          "from_longitude": 105.83540110413318
        }
      },
      {
        "id": 12336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575429221827,
            21.019579861995705,
            105.83581607160635,
            21.01959586584326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575511758957,
              21.01959586584326
            ],
            [
              105.83575429221827,
              21.01959103132771
            ],
            [
              105.83581607160635,
              21.019579861995705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.4_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12397",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01959586584326,
          "to_latitude": 21.019579861995705,
          "to_longitude": 105.83581607160635,
          "from_longitude": 105.83575511758957
        }
      },
      {
        "id": 12337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83563572009182,
            21.019448215500194,
            105.8356976400425,
            21.019517050043024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83564343521022,
              21.019517050043024
            ],
            [
              105.83563572009182,
              21.019453486522586
            ],
            [
              105.8356976400425,
              21.019448215500194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.4_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12398",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019517050043024,
          "to_latitude": 21.019448215500194,
          "to_longitude": 105.8356976400425,
          "from_longitude": 105.83564343521022
        }
      },
      {
        "id": 12338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549827249212,
            21.019261359476506,
            105.83552874520934,
            21.019497684691977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83549827249212,
              21.019261359476506
            ],
            [
              105.83550865253792,
              21.01933690292591
            ],
            [
              105.83551939965591,
              21.01941910675119
            ],
            [
              105.83552874520934,
              21.019497684691977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12399",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019261359476506,
          "to_latitude": 21.019497684691977,
          "to_longitude": 105.83552874520934,
          "from_longitude": 105.83549827249212
        }
      },
      {
        "id": 12339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503838709022,
            21.019637808568415,
            105.83520449753136,
            21.01974162885986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83520449753136,
              21.019733908469544
            ],
            [
              105.83516870067767,
              21.01974162885986
            ],
            [
              105.83515531853728,
              21.01970582392292
            ],
            [
              105.835120580793,
              21.01971025949648
            ],
            [
              105.83510617211722,
              21.019637808568415
            ],
            [
              105.83503838709022,
              21.01964119965761
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.5_Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12400",
          "diaChiLapD": "Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019733908469544,
          "to_latitude": 21.01964119965761,
          "to_longitude": 105.83503838709022,
          "from_longitude": 105.83520449753136
        }
      },
      {
        "id": 12340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518900516378,
            21.01966741692386,
            105.83520449753136,
            21.019733908469544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83520449753136,
              21.019733908469544
            ],
            [
              105.83518900516378,
              21.01966741692386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.5_Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12401",
          "diaChiLapD": "Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019733908469544,
          "to_latitude": 21.01966741692386,
          "to_longitude": 105.83518900516378,
          "from_longitude": 105.83520449753136
        }
      },
      {
        "id": 12341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83479807193964,
            21.019874647980597,
            105.83489594075525,
            21.020135434234902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489594075525,
              21.019874647980597
            ],
            [
              105.8348222417759,
              21.0198914014079
            ],
            [
              105.83479807193964,
              21.01990548296856
            ],
            [
              105.83484235381214,
              21.020135434234902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12403",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019874647980597,
          "to_latitude": 21.020135434234902,
          "to_longitude": 105.83484235381214,
          "from_longitude": 105.83489594075525
        }
      },
      {
        "id": 12342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83536270859736,
            21.019696005676025,
            105.83559933560296,
            21.019751681990215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536270859736,
              21.019751681990215
            ],
            [
              105.83536333891834,
              21.019751551540136
            ],
            [
              105.83541735884289,
              21.019740315682736
            ],
            [
              105.8354440727113,
              21.01973827235611
            ],
            [
              105.8354977454489,
              21.019732751070457
            ],
            [
              105.83559933560296,
              21.019696005676025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.7_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12404",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019751681990215,
          "to_latitude": 21.019696005676025,
          "to_longitude": 105.83559933560296,
          "from_longitude": 105.83536270859736
        }
      },
      {
        "id": 12343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352204109648,
            21.01980241514963,
            105.83538708923183,
            21.01989292511886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8352204109648,
              21.01989292511886
            ],
            [
              105.83538708923183,
              21.019850055221678
            ],
            [
              105.83538247301456,
              21.019830931349897
            ],
            [
              105.83537558907639,
              21.01980241514963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.8_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12405",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01989292511886,
          "to_latitude": 21.01980241514963,
          "to_longitude": 105.83537558907639,
          "from_longitude": 105.8352204109648
        }
      },
      {
        "id": 12344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524492830603,
            21.01980241514963,
            105.83537558907639,
            21.0198326066581
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83524492830603,
              21.0198326066581
            ],
            [
              105.83537558907639,
              21.01980241514963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.8_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12406",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0198326066581,
          "to_latitude": 21.01980241514963,
          "to_longitude": 105.83537558907639,
          "from_longitude": 105.83524492830603
        }
      },
      {
        "id": 12345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441969805699,
            21.020104888097066,
            105.83466267874702,
            21.020138375353202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441969805699,
              21.02013442448633
            ],
            [
              105.83443024169986,
              21.020133304250127
            ],
            [
              105.83448799988471,
              21.020125230823034
            ],
            [
              105.83454834958587,
              21.020106765887288
            ],
            [
              105.83456109007713,
              21.020138375353202
            ],
            [
              105.83466267874702,
              21.020104888097066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.4.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12407",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": 28.707,
          "from_latitude": 21.02013442448633,
          "to_latitude": 21.020104888097066,
          "to_longitude": 105.83466267874702,
          "from_longitude": 105.83441969805699
        }
      },
      {
        "id": 12346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447965082622,
            21.01979148215089,
            105.83460138439497,
            21.019819142697934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447965082622,
              21.019819142697934
            ],
            [
              105.83447982183998,
              21.01981910392355
            ],
            [
              105.83460138439497,
              21.01979148215089
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.4_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12408",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019819142697934,
          "to_latitude": 21.01979148215089,
          "to_longitude": 105.83460138439497,
          "from_longitude": 105.83447965082622
        }
      },
      {
        "id": 12347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438951274717,
            21.019854124640734,
            105.83443112449142,
            21.020048634745766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438951274717,
              21.019854124640734
            ],
            [
              105.8344000000454,
              21.019888089046823
            ],
            [
              105.83440725430155,
              21.019887067141372
            ],
            [
              105.83441477221741,
              21.019917524917403
            ],
            [
              105.83442059935219,
              21.019941130096274
            ],
            [
              105.83443112449142,
              21.019985093481814
            ],
            [
              105.83441963779104,
              21.01998839963028
            ],
            [
              105.83441890132613,
              21.020048634745766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.9_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12409",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 23.731,
          "from_latitude": 21.019854124640734,
          "to_latitude": 21.020048634745766,
          "to_longitude": 105.83441890132613,
          "from_longitude": 105.83438951274717
        }
      },
      {
        "id": 12348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441890132613,
            21.020048634745766,
            105.8344242974194,
            21.02009768990041
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441890132613,
              21.020048634745766
            ],
            [
              105.83442094465524,
              21.020074843448473
            ],
            [
              105.8344242974194,
              21.02009768990041
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.9_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12410",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": 5.463,
          "from_latitude": 21.020048634745766,
          "to_latitude": 21.02009768990041,
          "to_longitude": 105.8344242974194,
          "from_longitude": 105.83441890132613
        }
      },
      {
        "id": 12349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833188814632,
            21.01992353731364,
            105.83350875045322,
            21.020012951557913
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833188814632,
              21.019934924898312
            ],
            [
              105.83319123894974,
              21.019939110307135
            ],
            [
              105.83320123588354,
              21.01993845346957
            ],
            [
              105.83320912590985,
              21.019943646297317
            ],
            [
              105.83330547612522,
              21.01992353731364
            ],
            [
              105.83336289492685,
              21.01992355678878
            ],
            [
              105.83338115728085,
              21.020012951557913
            ],
            [
              105.83350875045322,
              21.02000482872013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.4_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12411",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019934924898312,
          "to_latitude": 21.02000482872013,
          "to_longitude": 105.83350875045322,
          "from_longitude": 105.833188814632
        }
      },
      {
        "id": 12350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661731218197,
            21.01917953633636,
            105.83683725139308,
            21.019191472231206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661731218197,
              21.019191472231206
            ],
            [
              105.8366814515889,
              21.019187991870293
            ],
            [
              105.83683725139308,
              21.01917953633636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12412",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019191472231206,
          "to_latitude": 21.01917953633636,
          "to_longitude": 105.83683725139308,
          "from_longitude": 105.83661731218197
        }
      },
      {
        "id": 12351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365889679816,
            21.01919301072596,
            105.83660639199836,
            21.019360110865186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365889679816,
              21.01919301072596
            ],
            [
              105.83660639199836,
              21.019360110865186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12413",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01919301072596,
          "to_latitude": 21.019360110865186,
          "to_longitude": 105.83660639199836,
          "from_longitude": 105.8365889679816
        }
      },
      {
        "id": 12352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83616689601548,
            21.019425717899612,
            105.83638772629575,
            21.01950202766277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83638772629575,
              21.019425717899612
            ],
            [
              105.836383897064,
              21.019427502530302
            ],
            [
              105.83638120000306,
              21.019428759530758
            ],
            [
              105.83637364036153,
              21.01943228224144
            ],
            [
              105.83629621043335,
              21.01946768273148
            ],
            [
              105.83623137149189,
              21.019483056429003
            ],
            [
              105.83620797266208,
              21.01948994111962
            ],
            [
              105.83616689601548,
              21.01950202766277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.1_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12414",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019425717899612,
          "to_latitude": 21.01950202766277,
          "to_longitude": 105.83616689601548,
          "from_longitude": 105.83638772629575
        }
      },
      {
        "id": 12353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83619232201224,
            21.019405143008065,
            105.83620797266208,
            21.01948994111962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620797266208,
              21.01948994111962
            ],
            [
              105.83620776237615,
              21.01948880228478
            ],
            [
              105.83619232201224,
              21.019405143008065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.1_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12415",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01948994111962,
          "to_latitude": 21.019405143008065,
          "to_longitude": 105.83619232201224,
          "from_longitude": 105.83620797266208
        }
      },
      {
        "id": 12354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605685671354,
            21.019666383003493,
            105.83629133317258,
            21.01976073692222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629133317258,
              21.01976073692222
            ],
            [
              105.83628582045529,
              21.0197597542207
            ],
            [
              105.83628520675472,
              21.01975941491605
            ],
            [
              105.83628034352003,
              21.019756735476502
            ],
            [
              105.83621308918431,
              21.019740061113684
            ],
            [
              105.83620117502532,
              21.01968187800049
            ],
            [
              105.83619945435396,
              21.01967347473504
            ],
            [
              105.83618596369234,
              21.019666383003493
            ],
            [
              105.83605685671354,
              21.019690693825055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.4_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12416",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01976073692222,
          "to_latitude": 21.019690693825055,
          "to_longitude": 105.83605685671354,
          "from_longitude": 105.83629133317258
        }
      },
      {
        "id": 12355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620117502532,
            21.0196711600358,
            105.83627325954474,
            21.01968187800049
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620117502532,
              21.01968187800049
            ],
            [
              105.83620803678649,
              21.019680857948284
            ],
            [
              105.83627325954474,
              21.0196711600358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.4_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12417",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01968187800049,
          "to_latitude": 21.0196711600358,
          "to_longitude": 105.83627325954474,
          "from_longitude": 105.83620117502532
        }
      },
      {
        "id": 12356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83628034352003,
            21.019545425711108,
            105.83634600559276,
            21.019756735476502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83628034352003,
              21.019756735476502
            ],
            [
              105.83629017340078,
              21.019745448334504
            ],
            [
              105.83629942909893,
              21.019716586375317
            ],
            [
              105.83631278588497,
              21.01967448647451
            ],
            [
              105.83632868545693,
              21.019650211838893
            ],
            [
              105.83634600559276,
              21.019622270354287
            ],
            [
              105.83633299553794,
              21.019545425711108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.4_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12418",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019756735476502,
          "to_latitude": 21.019545425711108,
          "to_longitude": 105.83633299553794,
          "from_longitude": 105.83628034352003
        }
      },
      {
        "id": 12357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633501673559,
            21.019643075951365,
            105.83651488848636,
            21.01975263882571
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633501673559,
              21.019643075951365
            ],
            [
              105.8363351929261,
              21.019643275600792
            ],
            [
              105.83643292356044,
              21.01975263882571
            ],
            [
              105.83651488848636,
              21.01968968119109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.3_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12419",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019643075951365,
          "to_latitude": 21.01968968119109,
          "to_longitude": 105.83651488848636,
          "from_longitude": 105.83633501673559
        }
      },
      {
        "id": 12358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634783687425,
            21.019544350827708,
            105.83651283592432,
            21.019632533477967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634783687425,
              21.019625494891812
            ],
            [
              105.8363490640728,
              21.019625964856978
            ],
            [
              105.83636625537422,
              21.019632533477967
            ],
            [
              105.83641347167999,
              21.01962051876125
            ],
            [
              105.83641911904023,
              21.019619081963235
            ],
            [
              105.83648830593269,
              21.019561256065458
            ],
            [
              105.83651283592432,
              21.019544350827708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.2_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12420",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019625494891812,
          "to_latitude": 21.019544350827708,
          "to_longitude": 105.83651283592432,
          "from_longitude": 105.83634783687425
        }
      },
      {
        "id": 12359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634624523582,
            21.019463510237454,
            105.83641406681578,
            21.019632533477967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636625537422,
              21.019632533477967
            ],
            [
              105.83634679636758,
              21.0195317218862
            ],
            [
              105.83634624523582,
              21.01952776757216
            ],
            [
              105.83635685057232,
              21.019515987066548
            ],
            [
              105.8363769762046,
              21.01950295755947
            ],
            [
              105.83639870164528,
              21.019484766388437
            ],
            [
              105.83641406681578,
              21.019463510237454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.2_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12421",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019632533477967,
          "to_latitude": 21.019463510237454,
          "to_longitude": 105.83641406681578,
          "from_longitude": 105.83636625537422
        }
      },
      {
        "id": 12360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670524456163,
            21.019429869280092,
            105.83674647569252,
            21.019491169686773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670524456163,
              21.019429869280092
            ],
            [
              105.83674647569252,
              21.019491169686773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.1_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12422",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019429869280092,
          "to_latitude": 21.019491169686773,
          "to_longitude": 105.83674647569252,
          "from_longitude": 105.83670524456163
        }
      },
      {
        "id": 12361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83666381917028,
            21.019449956607403,
            105.83672754055473,
            21.01955756577878
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83666381917028,
              21.019449956607403
            ],
            [
              105.83672754055473,
              21.01955756577878
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.1_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12423",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019449956607403,
          "to_latitude": 21.01955756577878,
          "to_longitude": 105.83672754055473,
          "from_longitude": 105.83666381917028
        }
      },
      {
        "id": 12362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83664149910192,
            21.01953020308868,
            105.83686562117708,
            21.019656234451467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83686562117708,
              21.01954251802724
            ],
            [
              105.83686550870553,
              21.019542359613187
            ],
            [
              105.83685688710754,
              21.01953020308868
            ],
            [
              105.83664149910192,
              21.019656234451467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.3_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12424",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01954251802724,
          "to_latitude": 21.019656234451467,
          "to_longitude": 105.83664149910192,
          "from_longitude": 105.83686562117708
        }
      },
      {
        "id": 12363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83696916565273,
            21.019680100676013,
            105.83703385126262,
            21.01990588726447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697595355886,
              21.019680100676013
            ],
            [
              105.8369750600006,
              21.01968098119974
            ],
            [
              105.83696916565273,
              21.019687285800988
            ],
            [
              105.83697781093072,
              21.019727984072947
            ],
            [
              105.83699501534795,
              21.01977550292256
            ],
            [
              105.83700495811898,
              21.01981409571601
            ],
            [
              105.83701486151494,
              21.019846839425487
            ],
            [
              105.83703385126262,
              21.01990588726447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.5_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12425",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019680100676013,
          "to_latitude": 21.01990588726447,
          "to_longitude": 105.83703385126262,
          "from_longitude": 105.83697595355886
        }
      },
      {
        "id": 12364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83689145391959,
            21.019171312490084,
            105.83696943156703,
            21.019175396653456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83689145391959,
              21.019175396653456
            ],
            [
              105.83696943156703,
              21.019171312490084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12426",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019175396653456,
          "to_latitude": 21.019171312490084,
          "to_longitude": 105.83696943156703,
          "from_longitude": 105.83689145391959
        }
      },
      {
        "id": 12365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695815079696,
            21.01912876021774,
            105.83715906189785,
            21.019171312490084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695815079696,
              21.01912876021774
            ],
            [
              105.83695874810734,
              21.019135125909923
            ],
            [
              105.83696582229885,
              21.019157696085372
            ],
            [
              105.83696943156703,
              21.019171312490084
            ],
            [
              105.83715906189785,
              21.019161377818563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12427",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01912876021774,
          "to_latitude": 21.019161377818563,
          "to_longitude": 105.83715906189785,
          "from_longitude": 105.83695815079696
        }
      },
      {
        "id": 12366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83685830874292,
            21.019133637957474,
            105.83715455085142,
            21.019608652033483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687363531854,
              21.019133637957474
            ],
            [
              105.83687344134653,
              21.019138526255883
            ],
            [
              105.83685830874292,
              21.019174894043243
            ],
            [
              105.8368725883391,
              21.019269064358607
            ],
            [
              105.83689173227685,
              21.01932473882482
            ],
            [
              105.83691952346206,
              21.01935476073606
            ],
            [
              105.83715455085142,
              21.019608652033483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12428",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019133637957474,
          "to_latitude": 21.019608652033483,
          "to_longitude": 105.83715455085142,
          "from_longitude": 105.83687363531854
        }
      },
      {
        "id": 12367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687759110698,
            21.019133285677956,
            105.83717312818645,
            21.01959416502495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83687951476814,
              21.019133285677956
            ],
            [
              105.83687875524512,
              21.019139222691923
            ],
            [
              105.83687759110698,
              21.019174358406534
            ],
            [
              105.83688933659961,
              21.019289847333756
            ],
            [
              105.83693089762966,
              21.019348719110265
            ],
            [
              105.83696991270753,
              21.01939299827373
            ],
            [
              105.8369812373933,
              21.019384812634772
            ],
            [
              105.83700765205829,
              21.019413630983774
            ],
            [
              105.83717312818645,
              21.01959416502495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_116.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12429",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019133285677956,
          "to_latitude": 21.01959416502495,
          "to_longitude": 105.83717312818645,
          "from_longitude": 105.83687951476814
        }
      },
      {
        "id": 12368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728166632223,
            21.01947664970469,
            105.83743055419808,
            21.019675008146557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83729709803168,
              21.01947664970469
            ],
            [
              105.83728548993537,
              21.01948713907657
            ],
            [
              105.83728166632223,
              21.019490789759736
            ],
            [
              105.83736557325186,
              21.019589313147065
            ],
            [
              105.83743055419808,
              21.019675008146557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12430",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01947664970469,
          "to_latitude": 21.019675008146557,
          "to_longitude": 105.83743055419808,
          "from_longitude": 105.83729709803168
        }
      },
      {
        "id": 12369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749357195825,
            21.019751937062985,
            105.83760092409007,
            21.01991538060248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83760092409007,
              21.01991538060248
            ],
            [
              105.83752572009621,
              21.019798843053433
            ],
            [
              105.83752091828967,
              21.019791836906343
            ],
            [
              105.83749357195825,
              21.019751937062985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_118.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12431",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01991538060248,
          "to_latitude": 21.019751937062985,
          "to_longitude": 105.83749357195825,
          "from_longitude": 105.83760092409007
        }
      },
      {
        "id": 12370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735115751017,
            21.019686504554496,
            105.8375054814961,
            21.019751937062985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735115751017,
              21.019728553720057
            ],
            [
              105.83744611272093,
              21.019686504554496
            ],
            [
              105.83749357195825,
              21.019751937062985
            ],
            [
              105.83749950063726,
              21.01974782168928
            ],
            [
              105.8375054814961,
              21.019743575994248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_118.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12432",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019728553720057,
          "to_latitude": 21.019743575994248,
          "to_longitude": 105.8375054814961,
          "from_longitude": 105.83735115751017
        }
      },
      {
        "id": 12371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698959766699,
            21.019929023775394,
            105.83719251972455,
            21.020025603326985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719251972455,
              21.019929023775394
            ],
            [
              105.83718885332674,
              21.01993033884967
            ],
            [
              105.83718179306027,
              21.01993664016423
            ],
            [
              105.83715468272051,
              21.019955890320258
            ],
            [
              105.83707102005273,
              21.019987825459044
            ],
            [
              105.83705025701019,
              21.019988857954182
            ],
            [
              105.83698959766699,
              21.020025603326985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.7_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12433",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019929023775394,
          "to_latitude": 21.020025603326985,
          "to_longitude": 105.83698959766699,
          "from_longitude": 105.83719251972455
        }
      },
      {
        "id": 12372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83724650178456,
            21.01980719341251,
            105.83729628377391,
            21.019927801302412
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83729628377391,
              21.019927801302412
            ],
            [
              105.83729042145606,
              21.01991606567044
            ],
            [
              105.83724650178456,
              21.01982814618785
            ],
            [
              105.8372607728837,
              21.01980719341251
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.8_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12434",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019927801302412,
          "to_latitude": 21.01980719341251,
          "to_longitude": 105.8372607728837,
          "from_longitude": 105.83729628377391
        }
      },
      {
        "id": 12373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713342040059,
            21.01982491913859,
            105.83721571345292,
            21.01995259756429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721571345292,
              21.01995259756429
            ],
            [
              105.83717639924694,
              21.019891306817012
            ],
            [
              105.83713342040059,
              21.01982491913859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.6_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12435",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01995259756429,
          "to_latitude": 21.01982491913859,
          "to_longitude": 105.83713342040059,
          "from_longitude": 105.83721571345292
        }
      },
      {
        "id": 12374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702706554274,
            21.019725091652376,
            105.83713342040059,
            21.01983215029025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712315429416,
              21.01983215029025
            ],
            [
              105.83712496305044,
              21.01983099158497
            ],
            [
              105.83713342040059,
              21.01982491913859
            ],
            [
              105.83709761869048,
              21.01977970924029
            ],
            [
              105.83708839168314,
              21.019768187964722
            ],
            [
              105.83705816266797,
              21.019751417535474
            ],
            [
              105.83702706554274,
              21.019725091652376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.6_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12436",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01983215029025,
          "to_latitude": 21.019725091652376,
          "to_longitude": 105.83702706554274,
          "from_longitude": 105.83712315429416
        }
      },
      {
        "id": 12375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723405556354,
            21.019953693218067,
            105.837300306723,
            21.020093200590846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723405556354,
              21.019953693218067
            ],
            [
              105.83723584402118,
              21.019959409046933
            ],
            [
              105.83724227502924,
              21.01997996276407
            ],
            [
              105.837300306723,
              21.020093200590846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.8_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12437",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019953693218067,
          "to_latitude": 21.020093200590846,
          "to_longitude": 105.837300306723,
          "from_longitude": 105.83723405556354
        }
      },
      {
        "id": 12376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83606897508083,
            21.02009825779333,
            105.83624600833875,
            21.020364842564415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83621919799988,
              21.02009825779333
            ],
            [
              105.83621630422161,
              21.020098741695694
            ],
            [
              105.83618084808451,
              21.020108224620934
            ],
            [
              105.83607010967562,
              21.020151037124105
            ],
            [
              105.83606897508083,
              21.020151476248316
            ],
            [
              105.83606932023005,
              21.0201521311956
            ],
            [
              105.836157986609,
              21.020320358862858
            ],
            [
              105.83620344348205,
              21.020302092603377
            ],
            [
              105.83624600833875,
              21.020364842564415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.8_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12438",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02009825779333,
          "to_latitude": 21.020364842564415,
          "to_longitude": 105.83624600833875,
          "from_longitude": 105.83621919799988
        }
      },
      {
        "id": 12377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645185013972,
            21.01996674433791,
            105.83652133584623,
            21.020103994889183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83648468135073,
              21.020103994889183
            ],
            [
              105.83647740703931,
              21.020095729131477
            ],
            [
              105.83645185013972,
              21.020066687904748
            ],
            [
              105.83652133584623,
              21.02001962875564
            ],
            [
              105.83649336994512,
              21.01996674433791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.9_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12439",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020103994889183,
          "to_latitude": 21.01996674433791,
          "to_longitude": 105.83649336994512,
          "from_longitude": 105.83648468135073
        }
      },
      {
        "id": 12378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607710380258,
            21.020045557683105,
            105.83611624379951,
            21.02012415622372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611624379951,
              21.02012415622372
            ],
            [
              105.83607710380258,
              21.020045557683105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.7_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12440",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02012415622372,
          "to_latitude": 21.020045557683105,
          "to_longitude": 105.83607710380258,
          "from_longitude": 105.83611624379951
        }
      },
      {
        "id": 12379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360769213309,
            21.01997341189898,
            105.83617483346077,
            21.020007480468102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617483346077,
              21.01997341189898
            ],
            [
              105.8360769213309,
              21.020007480468102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.6_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12441",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01997341189898,
          "to_latitude": 21.020007480468102,
          "to_longitude": 105.8360769213309,
          "from_longitude": 105.83617483346077
        }
      },
      {
        "id": 12380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623564829571,
            21.01980597650312,
            105.8364001247178,
            21.019904505431253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83623564829571,
              21.019841001092292
            ],
            [
              105.83630518107799,
              21.019904505431253
            ],
            [
              105.8364001247178,
              21.01980597650312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.5_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12442",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019841001092292,
          "to_latitude": 21.01980597650312,
          "to_longitude": 105.8364001247178,
          "from_longitude": 105.83623564829571
        }
      },
      {
        "id": 12381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362357382343,
            21.019749995872154,
            105.83631299343021,
            21.019840913940662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83631299343021,
              21.019749995872154
            ],
            [
              105.83626951339369,
              21.019799108371785
            ],
            [
              105.83625645673764,
              21.01982085625699
            ],
            [
              105.8362357382343,
              21.019840913940662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.5_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12443",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019749995872154,
          "to_latitude": 21.019840913940662,
          "to_longitude": 105.8362357382343,
          "from_longitude": 105.83631299343021
        }
      },
      {
        "id": 12382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83618047836246,
            21.019840913940662,
            105.8362357382343,
            21.019894415287602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362357382343,
              21.019840913940662
            ],
            [
              105.83623564829571,
              21.019841001092292
            ],
            [
              105.83618047836246,
              21.019894415287602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.5_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12444",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019840913940662,
          "to_latitude": 21.019894415287602,
          "to_longitude": 105.83618047836246,
          "from_longitude": 105.8362357382343
        }
      },
      {
        "id": 12383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83616891342976,
            21.01988514149299,
            105.83629252544026,
            21.01999592061507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617303570809,
              21.019889595239274
            ],
            [
              105.83616891342976,
              21.019886097446353
            ],
            [
              105.83616971807284,
              21.01988514149299
            ],
            [
              105.83617391025028,
              21.01988875365172
            ],
            [
              105.83618047836246,
              21.019894415287602
            ],
            [
              105.83629252544026,
              21.01999592061507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.5_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12445",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019889595239274,
          "to_latitude": 21.01999592061507,
          "to_longitude": 105.83629252544026,
          "from_longitude": 105.83617303570809
        }
      },
      {
        "id": 12384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340402077056,
            21.02629455340542,
            105.8343383855691,
            21.026413422476036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343383855691,
              21.02629455340542
            ],
            [
              105.83433564405321,
              21.026294761952045
            ],
            [
              105.83431982861515,
              21.026295958638375
            ],
            [
              105.834127819041,
              21.026369596489847
            ],
            [
              105.8341217845752,
              21.026382771604027
            ],
            [
              105.8340402077056,
              21.026413422476036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.9_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12446",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02629455340542,
          "to_latitude": 21.026413422476036,
          "to_longitude": 105.8340402077056,
          "from_longitude": 105.8343383855691
        }
      },
      {
        "id": 12385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83431982861515,
            21.026295958638375,
            105.83439126453014,
            21.026512459982936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83431982861515,
              21.026295958638375
            ],
            [
              105.83435231265719,
              21.026371149784623
            ],
            [
              105.83439126453014,
              21.026474036343462
            ],
            [
              105.83437974672512,
              21.026512459982936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.9_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12447",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026295958638375,
          "to_latitude": 21.026512459982936,
          "to_longitude": 105.83437974672512,
          "from_longitude": 105.83431982861515
        }
      },
      {
        "id": 12386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397814421589,
            21.02611599211042,
            105.83426480858382,
            21.02626701353668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83426480858382,
              21.02611599211042
            ],
            [
              105.8342445540068,
              21.026124019065463
            ],
            [
              105.8340994458869,
              21.02618152464029
            ],
            [
              105.83402935268602,
              21.026224127537304
            ],
            [
              105.83402649084545,
              21.02624524539168
            ],
            [
              105.83397814421589,
              21.02626701353668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.10_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12448",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02611599211042,
          "to_latitude": 21.02626701353668,
          "to_longitude": 105.83397814421589,
          "from_longitude": 105.83426480858382
        }
      },
      {
        "id": 12387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340638837651,
            21.026124019065463,
            105.8342929088496,
            21.02632560967145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342445540068,
              21.026124019065463
            ],
            [
              105.8342929088496,
              21.0262335605415
            ],
            [
              105.83428991344321,
              21.026244154693877
            ],
            [
              105.8340638837651,
              21.02632560967145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.10_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12449",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026124019065463,
          "to_latitude": 21.02632560967145,
          "to_longitude": 105.8340638837651,
          "from_longitude": 105.8342445540068
        }
      },
      {
        "id": 12388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83392580280817,
            21.026013845507347,
            105.83416688251641,
            21.026097074887275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416688251641,
              21.026013845507347
            ],
            [
              105.83397138079206,
              21.026090359911713
            ],
            [
              105.83394714315668,
              21.02608933167692
            ],
            [
              105.83392580280817,
              21.026097074887275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12450",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026013845507347,
          "to_latitude": 21.026097074887275,
          "to_longitude": 105.83392580280817,
          "from_longitude": 105.83416688251641
        }
      },
      {
        "id": 12389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83370283363601,
            21.026097074887275,
            105.83392580280817,
            21.02619174208779
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83392580280817,
              21.026097074887275
            ],
            [
              105.83382469534726,
              21.026135319084354
            ],
            [
              105.83373815599272,
              21.026175387667653
            ],
            [
              105.83370283363601,
              21.02619174208779
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12451",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026097074887275,
          "to_latitude": 21.02619174208779,
          "to_longitude": 105.83370283363601,
          "from_longitude": 105.83392580280817
        }
      },
      {
        "id": 12390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385136820962,
            21.02666753242871,
            105.83412533730079,
            21.026741916464847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385136820962,
              21.02673050025925
            ],
            [
              105.83388702889596,
              21.026741916464847
            ],
            [
              105.83407359590892,
              21.026684682814654
            ],
            [
              105.83409600322366,
              21.026677808671177
            ],
            [
              105.83409602857188,
              21.02667786906284
            ],
            [
              105.83412533730079,
              21.02666753242871
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.1_Hồ Giám",
          "maTaiSan": "04.O14.DODV.12452",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02673050025925,
          "to_latitude": 21.02666753242871,
          "to_longitude": 105.83412533730079,
          "from_longitude": 105.83385136820962
        }
      },
      {
        "id": 12391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335203477949,
            21.025902078547222,
            105.83400966678424,
            21.026067457939803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335203477949,
              21.026067457939803
            ],
            [
              105.83354139073526,
              21.02605812746711
            ],
            [
              105.83381085686355,
              21.025938652182507
            ],
            [
              105.83400966678424,
              21.025902078547222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.1_Ngõ 61, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12453",
          "diaChiLapD": "Ngõ 61, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026067457939803,
          "to_latitude": 21.025902078547222,
          "to_longitude": 105.83400966678424,
          "from_longitude": 105.8335203477949
        }
      },
      {
        "id": 12392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83354139073526,
            21.02605812746711,
            105.833574188386,
            21.02612405468082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83354139073526,
              21.02605812746711
            ],
            [
              105.83355423443979,
              21.026087192574238
            ],
            [
              105.833574188386,
              21.02612405468082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.1_Ngõ 61, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12454",
          "diaChiLapD": "Ngõ 61, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02605812746711,
          "to_latitude": 21.02612405468082,
          "to_longitude": 105.833574188386,
          "from_longitude": 105.83354139073526
        }
      },
      {
        "id": 12393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83351882265632,
            21.025876904343964,
            105.83408471588632,
            21.02606445135757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83351882265632,
              21.02606445135757
            ],
            [
              105.8335201858701,
              21.026063769064695
            ],
            [
              105.83353680141752,
              21.02604851075821
            ],
            [
              105.83370661623519,
              21.025974994125946
            ],
            [
              105.83379500486141,
              21.025936728360673
            ],
            [
              105.83380873421684,
              21.0259307846732
            ],
            [
              105.83408471588632,
              21.025876904343964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.2_Ngõ 61, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12455",
          "diaChiLapD": "Ngõ 61, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02606445135757,
          "to_latitude": 21.025876904343964,
          "to_longitude": 105.83408471588632,
          "from_longitude": 105.83351882265632
        }
      },
      {
        "id": 12394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83378362434212,
            21.025825505196984,
            105.83379537401092,
            21.025936728360673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83379500486141,
              21.025936728360673
            ],
            [
              105.83379537401092,
              21.025865765687673
            ],
            [
              105.83378362434212,
              21.025825505196984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.2_Ngõ 61, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12456",
          "diaChiLapD": "Ngõ 61, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025936728360673,
          "to_latitude": 21.025825505196984,
          "to_longitude": 105.83378362434212,
          "from_longitude": 105.83379500486141
        }
      },
      {
        "id": 12395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366095564337,
            21.02585584657666,
            105.83370661623519,
            21.025974994125946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83370661623519,
              21.025974994125946
            ],
            [
              105.83366095564337,
              21.025875682717594
            ],
            [
              105.8336630733342,
              21.02585584657666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.2_Ngõ 61, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12457",
          "diaChiLapD": "Ngõ 61, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025974994125946,
          "to_latitude": 21.02585584657666,
          "to_longitude": 105.8336630733342,
          "from_longitude": 105.83370661623519
        }
      },
      {
        "id": 12396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83433705373697,
            21.025585958377366,
            105.83442484138634,
            21.025836646666384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343745509563,
              21.025836646666384
            ],
            [
              105.83439961050173,
              21.02583048747175
            ],
            [
              105.8343975314712,
              21.02582372078605
            ],
            [
              105.83433934936231,
              21.025660160583
            ],
            [
              105.83433705373697,
              21.025653366958974
            ],
            [
              105.83434006890883,
              21.02565237668491
            ],
            [
              105.83442299570888,
              21.025625138660825
            ],
            [
              105.83442484138634,
              21.025619708457025
            ],
            [
              105.83441384257395,
              21.025585958377366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.4_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12458",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025836646666384,
          "to_latitude": 21.025585958377366,
          "to_longitude": 105.83441384257395,
          "from_longitude": 105.8343745509563
        }
      },
      {
        "id": 12397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410660367419,
            21.025836646666384,
            105.83440292921183,
            21.025916699231452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440292921183,
              21.025916699231452
            ],
            [
              105.83440140457462,
              21.025912402851844
            ],
            [
              105.8343745509563,
              21.025836646666384
            ],
            [
              105.8342730196669,
              21.02586160136427
            ],
            [
              105.83415590709303,
              21.02588522791708
            ],
            [
              105.83414198882,
              21.025880556068596
            ],
            [
              105.83410660367419,
              21.025884221489548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.4_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12459",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025916699231452,
          "to_latitude": 21.025884221489548,
          "to_longitude": 105.83410660367419,
          "from_longitude": 105.83440292921183
        }
      },
      {
        "id": 12398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343024417309,
            21.025463557791355,
            105.83452256571351,
            21.025915036125884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441016383524,
              21.025915036125884
            ],
            [
              105.8344104439531,
              21.025910055280058
            ],
            [
              105.83441851493468,
              21.02584779441555
            ],
            [
              105.83448491764953,
              21.025746784458285
            ],
            [
              105.83452256571351,
              21.025645710550204
            ],
            [
              105.83444048316763,
              21.02546893337087
            ],
            [
              105.83442806653836,
              21.025463557791355
            ],
            [
              105.83430891197489,
              21.025503951967785
            ],
            [
              105.8343024417309,
              21.025515045489243
            ],
            [
              105.83431102396884,
              21.02553928835914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.5_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12460",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025915036125884,
          "to_latitude": 21.02553928835914,
          "to_longitude": 105.83431102396884,
          "from_longitude": 105.83441016383524
        }
      },
      {
        "id": 12399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83458768445017,
            21.025838373731247,
            105.83483773625014,
            21.026186900721918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83458768445017,
              21.025838373731247
            ],
            [
              105.83458830228237,
              21.02583979688915
            ],
            [
              105.83459494424265,
              21.025855056770638
            ],
            [
              105.83464054054843,
              21.025959819180283
            ],
            [
              105.83470736039547,
              21.026054861687175
            ],
            [
              105.83472841371866,
              21.026063478069243
            ],
            [
              105.83477518035636,
              21.026082618139363
            ],
            [
              105.83478545675288,
              21.026098561073113
            ],
            [
              105.83481879924673,
              21.026150286683404
            ],
            [
              105.83483773625014,
              21.026186900721918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.6_Ngách 23, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12461",
          "diaChiLapD": "Ngách 23, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025838373731247,
          "to_latitude": 21.026186900721918,
          "to_longitude": 105.83483773625014,
          "from_longitude": 105.83458768445017
        }
      },
      {
        "id": 12400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83460234817868,
            21.025830239594374,
            105.83512481220197,
            21.02616521383132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460234817868,
              21.025830239594374
            ],
            [
              105.83460502115264,
              21.025836210332184
            ],
            [
              105.83460964330212,
              21.02584652955747
            ],
            [
              105.83464372644514,
              21.025922623251933
            ],
            [
              105.83471103695675,
              21.025916602577087
            ],
            [
              105.834753100234,
              21.025898237707768
            ],
            [
              105.83484314893894,
              21.025906785459274
            ],
            [
              105.8348808619615,
              21.025885991469234
            ],
            [
              105.83495481135105,
              21.02596048396086
            ],
            [
              105.83497476847644,
              21.02596262416307
            ],
            [
              105.83503078273834,
              21.026036666355665
            ],
            [
              105.83506497590881,
              21.026081864659513
            ],
            [
              105.83507991661435,
              21.026087014634854
            ],
            [
              105.83508424599215,
              21.026092778521342
            ],
            [
              105.83511433990783,
              21.026136216690315
            ],
            [
              105.83512481220197,
              21.02616521383132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.8_Ngách 25, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12462",
          "diaChiLapD": "Ngách 25, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025830239594374,
          "to_latitude": 21.02616521383132,
          "to_longitude": 105.83512481220197,
          "from_longitude": 105.83460234817868
        }
      },
      {
        "id": 12401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503078273834,
            21.025938821493927,
            105.83527504985334,
            21.026081392978814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503078273834,
              21.026036666355665
            ],
            [
              105.83503747692059,
              21.026032449754844
            ],
            [
              105.83518611776456,
              21.025938821493927
            ],
            [
              105.83527504985334,
              21.026081392978814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.8_Ngách 25, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12463",
          "diaChiLapD": "Ngách 25, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026036666355665,
          "to_latitude": 21.026081392978814,
          "to_longitude": 105.83527504985334,
          "from_longitude": 105.83503078273834
        }
      },
      {
        "id": 12402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489493060435,
            21.025677296493242,
            105.83506215718393,
            21.02587184834403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489912063122,
              21.02568576376696
            ],
            [
              105.83489493060435,
              21.025679107709255
            ],
            [
              105.83489792580879,
              21.025677296493242
            ],
            [
              105.83490250813409,
              21.025684089721356
            ],
            [
              105.83492419657063,
              21.025716240063346
            ],
            [
              105.83506215718393,
              21.02587184834403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.11_Ngách 39, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12464",
          "diaChiLapD": "Ngách 39, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02568576376696,
          "to_latitude": 21.02587184834403,
          "to_longitude": 105.83506215718393,
          "from_longitude": 105.83489912063122
        }
      },
      {
        "id": 12403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8347433333784,
            21.02570150786593,
            105.83489120734785,
            21.02578185472044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489120734785,
              21.02570150786593
            ],
            [
              105.8347433333784,
              21.02578185472044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.9_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12465",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02570150786593,
          "to_latitude": 21.02578185472044,
          "to_longitude": 105.8347433333784,
          "from_longitude": 105.83489120734785
        }
      },
      {
        "id": 12404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463667333136,
            21.025763216240886,
            105.8347433333784,
            21.025846128642964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83472797032775,
              21.025763216240886
            ],
            [
              105.83472875827609,
              21.02576443804762
            ],
            [
              105.83473670824354,
              21.02577393796948
            ],
            [
              105.8347433333784,
              21.02578185472044
            ],
            [
              105.83463667333136,
              21.025846128642964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.9_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12466",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025763216240886,
          "to_latitude": 21.025846128642964,
          "to_longitude": 105.83463667333136,
          "from_longitude": 105.83472797032775
        }
      },
      {
        "id": 12405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83482091659266,
            21.02554097979235,
            105.83489791087563,
            21.02568636131747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489791087563,
              21.02568636131747
            ],
            [
              105.83489620737777,
              21.025683807235506
            ],
            [
              105.83488868690144,
              21.02567404147141
            ],
            [
              105.8348831044541,
              21.025666794315217
            ],
            [
              105.83485477723559,
              21.02561667260385
            ],
            [
              105.83482091659266,
              21.02554097979235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.10_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12467",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02568636131747,
          "to_latitude": 21.02554097979235,
          "to_longitude": 105.83482091659266,
          "from_longitude": 105.83489791087563
        }
      },
      {
        "id": 12406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83477308617766,
            21.02567404147141,
            105.83488868690144,
            21.02573192751411
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488868690144,
              21.02567404147141
            ],
            [
              105.83488194955852,
              21.025677415570595
            ],
            [
              105.83477308617766,
              21.02573192751411
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.10_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12468",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02567404147141,
          "to_latitude": 21.02573192751411,
          "to_longitude": 105.83477308617766,
          "from_longitude": 105.83488868690144
        }
      },
      {
        "id": 12407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83461683306004,
            21.0254169103179,
            105.83467552761823,
            21.025657271716042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83467552761823,
              21.025657271716042
            ],
            [
              105.83466008840455,
              21.025624603832156
            ],
            [
              105.83465256609419,
              21.025598781461227
            ],
            [
              105.83461683306004,
              21.0254169103179
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.1_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12469",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025657271716042,
          "to_latitude": 21.0254169103179,
          "to_longitude": 105.83461683306004,
          "from_longitude": 105.83467552761823
        }
      },
      {
        "id": 12408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447463284995,
            21.025829005670666,
            105.83459206616507,
            21.025887284379667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447463284995,
              21.025887284379667
            ],
            [
              105.83459206616507,
              21.025829005670666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.7_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12470",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025887284379667,
          "to_latitude": 21.025829005670666,
          "to_longitude": 105.83459206616507,
          "from_longitude": 105.83447463284995
        }
      },
      {
        "id": 12409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467552761823,
            21.025657271716042,
            105.83475185742375,
            21.0257576218452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475185742375,
              21.0257576218452
            ],
            [
              105.83475077241606,
              21.025756435819154
            ],
            [
              105.83474873742556,
              21.025753952911018
            ],
            [
              105.83472694871723,
              21.025745426848975
            ],
            [
              105.83471978544037,
              21.0257426240805
            ],
            [
              105.83471485341211,
              21.025735049468977
            ],
            [
              105.83467552761823,
              21.025657271716042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.1_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12471",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0257576218452,
          "to_latitude": 21.025657271716042,
          "to_longitude": 105.83467552761823,
          "from_longitude": 105.83475185742375
        }
      },
      {
        "id": 12410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83464059013349,
            21.025458988716494,
            105.83472833269906,
            21.025616952804707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83472833269906,
              21.02559073309313
            ],
            [
              105.83466594094777,
              21.025616952804707
            ],
            [
              105.83465665420701,
              21.025587480135187
            ],
            [
              105.83465108966406,
              21.025553553466057
            ],
            [
              105.83464059013349,
              21.025458988716494
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.2_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12472",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02559073309313,
          "to_latitude": 21.025458988716494,
          "to_longitude": 105.83464059013349,
          "from_longitude": 105.83472833269906
        }
      },
      {
        "id": 12411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463227772768,
            21.025402236810248,
            105.83480117144914,
            21.02546110421641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463227772768,
              21.02546110421641
            ],
            [
              105.8346372768932,
              21.02545983232996
            ],
            [
              105.83464059013349,
              21.025458988716494
            ],
            [
              105.83470500093753,
              21.02544259521005
            ],
            [
              105.83480117144914,
              21.025402236810248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.2_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12473",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02546110421641,
          "to_latitude": 21.025402236810248,
          "to_longitude": 105.83480117144914,
          "from_longitude": 105.83463227772768
        }
      },
      {
        "id": 12412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483480217129,
            21.025359600345993,
            105.83496323865901,
            21.02565340892995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496323865901,
              21.02565340892995
            ],
            [
              105.83495928241074,
              21.025645200891358
            ],
            [
              105.83493793817783,
              21.025607315200926
            ],
            [
              105.83483480217129,
              21.025359600345993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12474",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02565340892995,
          "to_latitude": 21.025359600345993,
          "to_longitude": 105.83483480217129,
          "from_longitude": 105.83496323865901
        }
      },
      {
        "id": 12413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8348010924844,
            21.02512234718846,
            105.83484334196504,
            21.025359600345993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83483480217129,
              21.025359600345993
            ],
            [
              105.83482861833834,
              21.025339542107236
            ],
            [
              105.83481613627022,
              21.025289590989193
            ],
            [
              105.83484026231764,
              21.025239853069237
            ],
            [
              105.83484334196504,
              21.025232304163854
            ],
            [
              105.8348010924844,
              21.02512234718846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12475",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025359600345993,
          "to_latitude": 21.02512234718846,
          "to_longitude": 105.8348010924844,
          "from_longitude": 105.83483480217129
        }
      },
      {
        "id": 12414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449919488946,
            21.025166302025486,
            105.83461239157877,
            21.02537043958527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83461239157877,
              21.02537043958527
            ],
            [
              105.83460421399786,
              21.02536566824554
            ],
            [
              105.83456864592621,
              21.02534491691064
            ],
            [
              105.83449919488946,
              21.025166302025486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.3_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12476",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02537043958527,
          "to_latitude": 21.025166302025486,
          "to_longitude": 105.83449919488946,
          "from_longitude": 105.83461239157877
        }
      },
      {
        "id": 12415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459045235254,
            21.025185050835773,
            105.83467398062551,
            21.02536566824554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460421399786,
              21.02536566824554
            ],
            [
              105.8346615537572,
              21.025324508745268
            ],
            [
              105.83467398062551,
              21.02527280323806
            ],
            [
              105.83466405064561,
              21.025189994759202
            ],
            [
              105.8346547831307,
              21.025185050835773
            ],
            [
              105.83459045235254,
              21.02520163089525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.3_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12477",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02536566824554,
          "to_latitude": 21.02520163089525,
          "to_longitude": 105.83459045235254,
          "from_longitude": 105.83460421399786
        }
      },
      {
        "id": 12416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435537376558,
            21.02509158643651,
            105.83465865211876,
            21.025138375077503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465865211876,
              21.025109164679325
            ],
            [
              105.83464904607928,
              21.02511166768886
            ],
            [
              105.83454653615036,
              21.025138375077503
            ],
            [
              105.83453546597178,
              21.02510372664634
            ],
            [
              105.83448774447974,
              21.025112597438564
            ],
            [
              105.83446827598944,
              21.02509158643651
            ],
            [
              105.83435537376558,
              21.025111067429375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.4_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12478",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025109164679325,
          "to_latitude": 21.025111067429375,
          "to_longitude": 105.83435537376558,
          "from_longitude": 105.83465865211876
        }
      },
      {
        "id": 12417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344117778989,
            21.02497786343647,
            105.83464904607928,
            21.02511166768886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83464904607928,
              21.02511166768886
            ],
            [
              105.83461424424745,
              21.02501133227905
            ],
            [
              105.83454004767857,
              21.02497786343647
            ],
            [
              105.83442127075783,
              21.02498440300691
            ],
            [
              105.8344117778989,
              21.02498528958056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_67.4_Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12479",
          "diaChiLapD": "Ngách 4, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02511166768886,
          "to_latitude": 21.02498528958056,
          "to_longitude": 105.8344117778989,
          "from_longitude": 105.83464904607928
        }
      },
      {
        "id": 12418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83469990383186,
            21.02466875369755,
            105.83478012053135,
            21.024845547895247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83478012053135,
              21.02466875369755
            ],
            [
              105.83477346017177,
              21.02467039683728
            ],
            [
              105.83476674309182,
              21.02467498386092
            ],
            [
              105.8347246572021,
              21.024694582640826
            ],
            [
              105.83471817233706,
              21.02469854620178
            ],
            [
              105.83473794662085,
              21.02475357649628
            ],
            [
              105.8347600914338,
              21.024815200426477
            ],
            [
              105.83469990383186,
              21.024845547895247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.4_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12480",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02466875369755,
          "to_latitude": 21.024845547895247,
          "to_longitude": 105.83469990383186,
          "from_longitude": 105.83478012053135
        }
      },
      {
        "id": 12419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83475781094509,
            21.024833318179795,
            105.83495604788968,
            21.024916733879387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475966591037,
              21.024916733879387
            ],
            [
              105.83475781094509,
              21.024912544801037
            ],
            [
              105.83495604788968,
              21.024833318179795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12481",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024916733879387,
          "to_latitude": 21.024833318179795,
          "to_longitude": 105.83495604788968,
          "from_longitude": 105.83475966591037
        }
      },
      {
        "id": 12420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83461863645688,
            21.02475357649628,
            105.83473794662085,
            21.02481860886915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83473794662085,
              21.02475357649628
            ],
            [
              105.83461863645688,
              21.02481860886915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.4_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12482",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02475357649628,
          "to_latitude": 21.02481860886915,
          "to_longitude": 105.83461863645688,
          "from_longitude": 105.83473794662085
        }
      },
      {
        "id": 12421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83493889996635,
            21.024699513570813,
            105.83505744538111,
            21.02476156899664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83493889996635,
              21.02476156899664
            ],
            [
              105.8349413871687,
              21.02476026698264
            ],
            [
              105.83494493524027,
              21.02475840879393
            ],
            [
              105.83505744538111,
              21.024699513570813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.1_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12483",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02476156899664,
          "to_latitude": 21.024699513570813,
          "to_longitude": 105.83505744538111,
          "from_longitude": 105.83493889996635
        }
      },
      {
        "id": 12422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494493524027,
            21.02475840879393,
            105.83512207902415,
            21.02482528238673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494493524027,
              21.02475840879393
            ],
            [
              105.83497606266796,
              21.02482528238673
            ],
            [
              105.83498334877018,
              21.02482216391235
            ],
            [
              105.83512207902415,
              21.024764812681546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.1_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12484",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02475840879393,
          "to_latitude": 21.024764812681546,
          "to_longitude": 105.83512207902415,
          "from_longitude": 105.83494493524027
        }
      },
      {
        "id": 12423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488064657489,
            21.024362143797614,
            105.83502609873582,
            21.024662495981623
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488540677493,
              21.024662495981623
            ],
            [
              105.83488746465915,
              21.024661491430106
            ],
            [
              105.83490574350174,
              21.02464093194022
            ],
            [
              105.83488064657489,
              21.024576766398017
            ],
            [
              105.83496085118101,
              21.02453622436266
            ],
            [
              105.83495465000738,
              21.024522721808104
            ],
            [
              105.83491012633611,
              21.024431562160437
            ],
            [
              105.83492326110861,
              21.024406463933214
            ],
            [
              105.8349230134017,
              21.02440482399195
            ],
            [
              105.83501816209582,
              21.024362143797614
            ],
            [
              105.83502276133245,
              21.02436282573059
            ],
            [
              105.83502609873582,
              21.024369607005273
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.3_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12485",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024662495981623,
          "to_latitude": 21.024369607005273,
          "to_longitude": 105.83502609873582,
          "from_longitude": 105.83488540677493
        }
      },
      {
        "id": 12424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83481129603103,
            21.024344734316557,
            105.8349230134017,
            21.02440482399195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349230134017,
              21.02440482399195
            ],
            [
              105.8348957862691,
              21.024344734316557
            ],
            [
              105.83484374834076,
              21.02434803363096
            ],
            [
              105.83481129603103,
              21.024357627100073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.3_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12486",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02440482399195,
          "to_latitude": 21.024357627100073,
          "to_longitude": 105.83481129603103,
          "from_longitude": 105.8349230134017
        }
      },
      {
        "id": 12425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488670636578,
            21.024409049560884,
            105.83537488016967,
            21.024664903015253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83537488016967,
              21.024409049560884
            ],
            [
              105.83531800874465,
              21.02444041424057
            ],
            [
              105.83528233523339,
              21.024456486184867
            ],
            [
              105.83524792069505,
              21.02447199194023
            ],
            [
              105.83506918404393,
              21.024558134863486
            ],
            [
              105.8349140648787,
              21.024629898857583
            ],
            [
              105.83491252094588,
              21.024650294903264
            ],
            [
              105.83488840243291,
              21.024664051981485
            ],
            [
              105.83488670636578,
              21.024664903015253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.2_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12487",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024409049560884,
          "to_latitude": 21.024664903015253,
          "to_longitude": 105.83488670636578,
          "from_longitude": 105.83537488016967
        }
      },
      {
        "id": 12426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506942095397,
            21.024490512303323,
            105.83543167609963,
            21.024691860465378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543167609963,
              21.024490512303323
            ],
            [
              105.83543091714085,
              21.024491039901168
            ],
            [
              105.83541336913758,
              21.024502901425684
            ],
            [
              105.83506942095397,
              21.024691860465378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.1_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12488",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024490512303323,
          "to_latitude": 21.024691860465378,
          "to_longitude": 105.83506942095397,
          "from_longitude": 105.83543167609963
        }
      },
      {
        "id": 12427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83507857655513,
            21.024358816982154,
            105.83528233523339,
            21.024456486184867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528233523339,
              21.024456486184867
            ],
            [
              105.83523397236573,
              21.024358816982154
            ],
            [
              105.83516614774192,
              21.02438932947002
            ],
            [
              105.83507857655513,
              21.02442611242496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.2_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12489",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024456486184867,
          "to_latitude": 21.02442611242496,
          "to_longitude": 105.83507857655513,
          "from_longitude": 105.83528233523339
        }
      },
      {
        "id": 12428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354109175496,
            21.024294099026488,
            105.83548417729587,
            21.024388735685243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548417729587,
              21.024388735685243
            ],
            [
              105.83544426551938,
              21.024337177944364
            ],
            [
              105.8354109175496,
              21.024294099026488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.12490",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024388735685243,
          "to_latitude": 21.024294099026488,
          "to_longitude": 105.8354109175496,
          "from_longitude": 105.83548417729587
        }
      },
      {
        "id": 12429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548417729587,
            21.024272092224667,
            105.83563964651117,
            21.024388735685243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548417729587,
              21.024388735685243
            ],
            [
              105.83559150989527,
              21.024307645353453
            ],
            [
              105.83563553643769,
              21.024272092224667
            ],
            [
              105.83563767361676,
              21.024274442750034
            ],
            [
              105.83563964651117,
              21.024276832017964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.12491",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024388735685243,
          "to_latitude": 21.024276832017964,
          "to_longitude": 105.83563964651117,
          "from_longitude": 105.83548417729587
        }
      },
      {
        "id": 12430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835556195258,
            21.024267097927567,
            105.83559150989527,
            21.024307645353453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83559150989527,
              21.024307645353453
            ],
            [
              105.835556195258,
              21.024267097927567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.12492",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024307645353453,
          "to_latitude": 21.024267097927567,
          "to_longitude": 105.835556195258,
          "from_longitude": 105.83559150989527
        }
      },
      {
        "id": 12431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558005105411,
            21.024113532982167,
            105.83569954582813,
            21.024226815556887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83569954582813,
              21.024214343531597
            ],
            [
              105.83568293826124,
              21.024226815556887
            ],
            [
              105.83558005105411,
              21.024113532982167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.12493",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024214343531597,
          "to_latitude": 21.024113532982167,
          "to_longitude": 105.83558005105411,
          "from_longitude": 105.83569954582813
        }
      },
      {
        "id": 12432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569954582813,
            21.024139289661743,
            105.8358130560734,
            21.024222689624406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83571032817524,
              21.024222689624406
            ],
            [
              105.83570525101213,
              21.024218761163517
            ],
            [
              105.83569954582813,
              21.024214343531597
            ],
            [
              105.8358130560734,
              21.024139289661743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.12494",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024222689624406,
          "to_latitude": 21.024139289661743,
          "to_longitude": 105.8358130560734,
          "from_longitude": 105.83571032817524
        }
      },
      {
        "id": 12433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344823951785,
            21.025902842057185,
            105.83348124308755,
            21.0259701764087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83348124308755,
              21.0259701764087
            ],
            [
              105.83344823951785,
              21.025902842057185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12495",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0259701764087,
          "to_latitude": 21.025902842057185,
          "to_longitude": 105.83344823951785,
          "from_longitude": 105.83348124308755
        }
      },
      {
        "id": 12434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340846361672,
            21.025821688144372,
            105.83343139814339,
            21.02586848015727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83343139814339,
              21.02586848015727
            ],
            [
              105.83343101476629,
              21.02586769803792
            ],
            [
              105.83340846361672,
              21.025821688144372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12496",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02586848015727,
          "to_latitude": 21.025821688144372,
          "to_longitude": 105.83340846361672,
          "from_longitude": 105.83343139814339
        }
      },
      {
        "id": 12435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83342150119513,
            21.025797840544165,
            105.8335987882139,
            21.02587265729522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83342150119513,
              21.02587265729522
            ],
            [
              105.8334273825487,
              21.025870175316697
            ],
            [
              105.83343139814339,
              21.02586848015727
            ],
            [
              105.8335987882139,
              21.025797840544165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12497",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02587265729522,
          "to_latitude": 21.025797840544165,
          "to_longitude": 105.8335987882139,
          "from_longitude": 105.83342150119513
        }
      },
      {
        "id": 12436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336615014098,
            21.025579130159162,
            105.83387904962566,
            21.025761845574376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336615014098,
              21.025761845574376
            ],
            [
              105.83337709985832,
              21.025756172961593
            ],
            [
              105.83347852110946,
              21.025703622237792
            ],
            [
              105.83349668275137,
              21.025732001934973
            ],
            [
              105.83351359844927,
              21.02573041967687
            ],
            [
              105.83353034284323,
              21.0257511451728
            ],
            [
              105.83363591897262,
              21.02570362120631
            ],
            [
              105.83364648704367,
              21.02569886450974
            ],
            [
              105.83363133107075,
              21.025670210870466
            ],
            [
              105.83387904962566,
              21.025579130159162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12498",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025761845574376,
          "to_latitude": 21.025579130159162,
          "to_longitude": 105.83387904962566,
          "from_longitude": 105.83336615014098
        }
      },
      {
        "id": 12437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363591897262,
            21.02570362120631,
            105.83366843966517,
            21.02575106348394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363591897262,
              21.02570362120631
            ],
            [
              105.83363626424833,
              21.025704125319074
            ],
            [
              105.83366843966517,
              21.02575106348394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12499",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02570362120631,
          "to_latitude": 21.02575106348394,
          "to_longitude": 105.83366843966517,
          "from_longitude": 105.83363591897262
        }
      },
      {
        "id": 12438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83323892787344,
            21.02539628837127,
            105.83345553399329,
            21.025491070087647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83323892787344,
              21.025491070087647
            ],
            [
              105.83325097185046,
              21.02548617612009
            ],
            [
              105.83325590076595,
              21.025479358824033
            ],
            [
              105.83345553399329,
              21.02539628837127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.5_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12500",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025491070087647,
          "to_latitude": 21.02539628837127,
          "to_longitude": 105.83345553399329,
          "from_longitude": 105.83323892787344
        }
      },
      {
        "id": 12439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325590076595,
            21.025479358824033,
            105.83332677198267,
            21.0256234704646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325590076595,
              21.025479358824033
            ],
            [
              105.83330332952607,
              21.025575801183148
            ],
            [
              105.83332677198267,
              21.0256234704646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.5_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12501",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025479358824033,
          "to_latitude": 21.0256234704646,
          "to_longitude": 105.83332677198267,
          "from_longitude": 105.83325590076595
        }
      },
      {
        "id": 12440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83330332952607,
            21.025518419681468,
            105.83343800423029,
            21.025575801183148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83330332952607,
              21.025575801183148
            ],
            [
              105.83343800423029,
              21.025518419681468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.5_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12502",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025575801183148,
          "to_latitude": 21.025518419681468,
          "to_longitude": 105.83343800423029,
          "from_longitude": 105.83330332952607
        }
      },
      {
        "id": 12441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318706295724,
            21.025253378375236,
            105.833550653648,
            21.025381045987732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318706295724,
              21.025381045987732
            ],
            [
              105.83320091754892,
              21.025376869345155
            ],
            [
              105.83325197021804,
              21.02534577231508
            ],
            [
              105.83345334455886,
              21.025283479226626
            ],
            [
              105.833550653648,
              21.025253378375236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.6_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12503",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025381045987732,
          "to_latitude": 21.025253378375236,
          "to_longitude": 105.833550653648,
          "from_longitude": 105.83318706295724
        }
      },
      {
        "id": 12442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83345334455886,
            21.025283479226626,
            105.83350410076073,
            21.02542280202867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345334455886,
              21.025283479226626
            ],
            [
              105.83350410076073,
              21.02542280202867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.6_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12504",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025283479226626,
          "to_latitude": 21.02542280202867,
          "to_longitude": 105.83350410076073,
          "from_longitude": 105.83345334455886
        }
      },
      {
        "id": 12443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83595055777802,
            21.025770408060108,
            105.8360923729177,
            21.025831693438626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83595055777802,
              21.025831693438626
            ],
            [
              105.83604566103519,
              21.025790594426073
            ],
            [
              105.8360923729177,
              21.025770408060108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.11_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12505",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025831693438626,
          "to_latitude": 21.025770408060108,
          "to_longitude": 105.8360923729177,
          "from_longitude": 105.83595055777802
        }
      },
      {
        "id": 12444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83544713914849,
            21.025726405915936,
            105.8357897638191,
            21.025834239511497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83544713914849,
              21.02581526643161
            ],
            [
              105.83545054487077,
              21.02581910646442
            ],
            [
              105.83545266737356,
              21.02582140919241
            ],
            [
              105.83548179118334,
              21.02581954005521
            ],
            [
              105.8355045070857,
              21.025834239511497
            ],
            [
              105.8355813258711,
              21.025786610860724
            ],
            [
              105.83559112384182,
              21.02578039460687
            ],
            [
              105.8356847386196,
              21.025731814482857
            ],
            [
              105.8357019984119,
              21.025738121779142
            ],
            [
              105.83573198890593,
              21.025726405915936
            ],
            [
              105.8357897638191,
              21.0258333905931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.6_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12506",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02581526643161,
          "to_latitude": 21.0258333905931,
          "to_longitude": 105.8357897638191,
          "from_longitude": 105.83544713914849
        }
      },
      {
        "id": 12445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355813258711,
            21.025786610860724,
            105.83576277183037,
            21.02605698957834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355813258711,
              21.025786610860724
            ],
            [
              105.83559894939299,
              21.0258131278092
            ],
            [
              105.83576118246603,
              21.02602367311304
            ],
            [
              105.83576277183037,
              21.02603301546973
            ],
            [
              105.83572880054344,
              21.02605698957834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.6_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12507",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025786610860724,
          "to_latitude": 21.02605698957834,
          "to_longitude": 105.83572880054344,
          "from_longitude": 105.8355813258711
        }
      },
      {
        "id": 12446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83539270752763,
            21.025840191822578,
            105.83557170050027,
            21.026053004266867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83539270752763,
              21.025840191822578
            ],
            [
              105.83539507248068,
              21.025844398258428
            ],
            [
              105.83541129005887,
              21.02585840797345
            ],
            [
              105.83545281231056,
              21.02593027579975
            ],
            [
              105.8355066014167,
              21.02599080750737
            ],
            [
              105.83557170050027,
              21.026053004266867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.4_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12508",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025840191822578,
          "to_latitude": 21.026053004266867,
          "to_longitude": 105.83557170050027,
          "from_longitude": 105.83539270752763
        }
      },
      {
        "id": 12447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83544426551938,
            21.02431732780191,
            105.83547129366981,
            21.024337177944364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83544426551938,
              21.024337177944364
            ],
            [
              105.83547129366981,
              21.02431732780191
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_65.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.12509",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024337177944364,
          "to_latitude": 21.02431732780191,
          "to_longitude": 105.83547129366981,
          "from_longitude": 105.83544426551938
        }
      },
      {
        "id": 12448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299975113853,
            21.024846490837376,
            105.83312917715186,
            21.024978340824163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299975113853,
              21.024978340824163
            ],
            [
              105.83300716536623,
              21.024975938062127
            ],
            [
              105.83300914054175,
              21.02497528077599
            ],
            [
              105.83312917715186,
              21.02493539351053
            ],
            [
              105.83310795613419,
              21.024895170327465
            ],
            [
              105.8330812692147,
              21.024846490837376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.11_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12510",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024978340824163,
          "to_latitude": 21.024846490837376,
          "to_longitude": 105.8330812692147,
          "from_longitude": 105.83299975113853
        }
      },
      {
        "id": 12449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312917715186,
            21.02493539351053,
            105.83315655252336,
            21.024993007922284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83312917715186,
              21.02493539351053
            ],
            [
              105.83315655252336,
              21.024993007922284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.11_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12511",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02493539351053,
          "to_latitude": 21.024993007922284,
          "to_longitude": 105.83315655252336,
          "from_longitude": 105.83312917715186
        }
      },
      {
        "id": 12450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83302770480194,
            21.02462857277064,
            105.83307732566313,
            21.024679897472488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83302770480194,
              21.02462857277064
            ],
            [
              105.83305620327043,
              21.02466925698698
            ],
            [
              105.83307732566313,
              21.024679897472488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12514",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": 7.883,
          "from_latitude": 21.02462857277064,
          "to_latitude": 21.024679897472488,
          "to_longitude": 105.83307732566313,
          "from_longitude": 105.83302770480194
        }
      },
      {
        "id": 12451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83297192164572,
            21.024664953848866,
            105.83304421718434,
            21.024687486640868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83297192164572,
              21.02468245633608
            ],
            [
              105.83299359049748,
              21.024664953848866
            ],
            [
              105.83302074786212,
              21.024669961135718
            ],
            [
              105.83304421718434,
              21.024687486640868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12515",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": 8.965,
          "from_latitude": 21.02468245633608,
          "to_latitude": 21.024687486640868,
          "to_longitude": 105.83304421718434,
          "from_longitude": 105.83297192164572
        }
      },
      {
        "id": 12452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83309140276427,
            21.024612063629206,
            105.83313004787699,
            21.02466025951594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331032257906,
              21.02466025951594
            ],
            [
              105.83312669511284,
              21.024651496762015
            ],
            [
              105.83313004787699,
              21.0246302157862
            ],
            [
              105.8331166368293,
              21.024624895542182
            ],
            [
              105.83310605981646,
              21.024646013565373
            ],
            [
              105.83309140276427,
              21.024612063629206
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12516",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": 13.161,
          "from_latitude": 21.02466025951594,
          "to_latitude": 21.024612063629206,
          "to_longitude": 105.83309140276427,
          "from_longitude": 105.8331032257906
        }
      },
      {
        "id": 12453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83308067245368,
            21.024646013565373,
            105.83310605981644,
            21.02465461197318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83310605981644,
              21.024646013565373
            ],
            [
              105.83308067245368,
              21.02465461197318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12517",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024646013565373,
          "to_latitude": 21.02465461197318,
          "to_longitude": 105.83308067245368,
          "from_longitude": 105.83310605981644
        }
      },
      {
        "id": 12454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832811048371,
            21.0245854691056,
            105.8328753448005,
            21.024694361431717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832811048371,
              21.024590705284275
            ],
            [
              105.83281399640582,
              21.024589753301626
            ],
            [
              105.83282528950996,
              21.0245854691056
            ],
            [
              105.8328316143944,
              21.02459876249256
            ],
            [
              105.8328753448005,
              21.024694361431717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12518",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024590705284275,
          "to_latitude": 21.024694361431717,
          "to_longitude": 105.8328753448005,
          "from_longitude": 105.832811048371
        }
      },
      {
        "id": 12455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328753448005,
            21.024694361431717,
            105.83291436487393,
            21.02477966138305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328753448005,
              21.024694361431717
            ],
            [
              105.83291436487393,
              21.02477966138305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12519",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024694361431717,
          "to_latitude": 21.02477966138305,
          "to_longitude": 105.83291436487393,
          "from_longitude": 105.8328753448005
        }
      },
      {
        "id": 12456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280508641613,
            21.024466345345104,
            105.83307633726625,
            21.024576072444585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83280508641613,
              21.024576072444585
            ],
            [
              105.83281264705722,
              21.024573014137047
            ],
            [
              105.83307633726625,
              21.024466345345104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12520",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024576072444585,
          "to_latitude": 21.024466345345104,
          "to_longitude": 105.83307633726625,
          "from_longitude": 105.83280508641613
        }
      },
      {
        "id": 12457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315980359164,
            21.02462827821553,
            105.83333975289212,
            21.02468803431418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83333975289212,
              21.02462827821553
            ],
            [
              105.83320540115021,
              21.02467301245244
            ],
            [
              105.83315980359164,
              21.02468803431418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12521",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.28,
          "chatLieu": "HDPE",
          "chieuDai": 19.842,
          "from_latitude": 21.02462827821553,
          "to_latitude": 21.02468803431418,
          "to_longitude": 105.83315980359164,
          "from_longitude": 105.83333975289212
        }
      },
      {
        "id": 12458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83323063055953,
            21.02432101538404,
            105.83335635910808,
            21.024647559621503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335225088781,
              21.024647559621503
            ],
            [
              105.83335074462731,
              21.024645928446656
            ],
            [
              105.83335635910808,
              21.024616132787862
            ],
            [
              105.83328796277472,
              21.024449014414476
            ],
            [
              105.83329279847683,
              21.024441795792267
            ],
            [
              105.83328427474133,
              21.024438060957106
            ],
            [
              105.83326516399991,
              21.02437734748928
            ],
            [
              105.83323063055953,
              21.02432101538404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.12522",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": 39.538,
          "from_latitude": 21.024647559621503,
          "to_latitude": 21.02432101538404,
          "to_longitude": 105.83323063055953,
          "from_longitude": 105.83335225088781
        }
      },
      {
        "id": 12459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833470902193,
            21.024545606803954,
            105.83364518342708,
            21.024587544137972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833470902193,
              21.024587544137972
            ],
            [
              105.83364518342708,
              21.024545606803954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.7_Văn Hương",
          "maTaiSan": "04.O14.DODV.12523",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024587544137972,
          "to_latitude": 21.024545606803954,
          "to_longitude": 105.83364518342708,
          "from_longitude": 105.833470902193
        }
      },
      {
        "id": 12460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336831545894,
            21.024587544137972,
            105.833470902193,
            21.024618583954858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336831545894,
              21.024618583954858
            ],
            [
              105.83338418957058,
              21.02461378140449
            ],
            [
              105.833470902193,
              21.024587544137972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.7_Văn Hương",
          "maTaiSan": "04.O14.DODV.12524",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024618583954858,
          "to_latitude": 21.024587544137972,
          "to_longitude": 105.833470902193,
          "from_longitude": 105.83336831545894
        }
      },
      {
        "id": 12461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362323110522,
            21.024560208440466,
            105.83366821661828,
            21.024738534503555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362323110522,
              21.024560208440466
            ],
            [
              105.83362702896882,
              21.024567804904674
            ],
            [
              105.8336264014498,
              21.024572768485783
            ],
            [
              105.83362686869485,
              21.024574620514002
            ],
            [
              105.83365084585483,
              21.02466967567712
            ],
            [
              105.83366821661828,
              21.024738534503555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.8_Văn Hương",
          "maTaiSan": "04.O14.DODV.12525",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024560208440466,
          "to_latitude": 21.024738534503555,
          "to_longitude": 105.83366821661828,
          "from_longitude": 105.83362323110522
        }
      },
      {
        "id": 12462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335831980186,
            21.02466967567712,
            105.83365084585483,
            21.02468760477128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83365084585483,
              21.02466967567712
            ],
            [
              105.83364984376212,
              21.024669903685485
            ],
            [
              105.83361491554521,
              21.024677842618566
            ],
            [
              105.8335831980186,
              21.02468760477128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.8_Văn Hương",
          "maTaiSan": "04.O14.DODV.12526",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02466967567712,
          "to_latitude": 21.02468760477128,
          "to_longitude": 105.8335831980186,
          "from_longitude": 105.83365084585483
        }
      },
      {
        "id": 12463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344439698405,
            21.024570322399807,
            105.83360763762035,
            21.024618804544545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83360763762035,
              21.024570322399807
            ],
            [
              105.8335127194829,
              21.024601624584808
            ],
            [
              105.83344439698405,
              21.024618804544545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.12527",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024570322399807,
          "to_latitude": 21.024618804544545,
          "to_longitude": 105.83344439698405,
          "from_longitude": 105.83360763762035
        }
      },
      {
        "id": 12464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344294606918,
            21.02461322611863,
            105.83347163574398,
            21.024770715240027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344294606918,
              21.02461322611863
            ],
            [
              105.83344387730064,
              21.024616690837828
            ],
            [
              105.83344439698405,
              21.024618804544545
            ],
            [
              105.83346689953045,
              21.024710613550493
            ],
            [
              105.83346399225405,
              21.024723049835668
            ],
            [
              105.83346260343751,
              21.024728988112805
            ],
            [
              105.83347163574398,
              21.024770715240027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.12528",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02461322611863,
          "to_latitude": 21.024770715240027,
          "to_longitude": 105.83347163574398,
          "from_longitude": 105.83344294606918
        }
      },
      {
        "id": 12465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327862856926,
            21.024665464472825,
            105.83332977182343,
            21.024781898661846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327862856926,
              21.024665464472825
            ],
            [
              105.83327976420351,
              21.024667800968103
            ],
            [
              105.8332813923878,
              21.024671534105014
            ],
            [
              105.83332977182343,
              21.024781898661846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.12529",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 13.945,
          "from_latitude": 21.024665464472825,
          "to_latitude": 21.024781898661846,
          "to_longitude": 105.83332977182343,
          "from_longitude": 105.83327862856926
        }
      },
      {
        "id": 12466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83319901349407,
            21.024781898661857,
            105.83332977182343,
            21.024903060095436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83332977182343,
              21.024781898661857
            ],
            [
              105.83328562661882,
              21.02483119253721
            ],
            [
              105.8332808880381,
              21.024835557551867
            ],
            [
              105.83324892685775,
              21.024866744404072
            ],
            [
              105.83319901349407,
              21.024903060095436
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.12530",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024781898661857,
          "to_latitude": 21.024903060095436,
          "to_longitude": 105.83319901349407,
          "from_longitude": 105.83332977182343
        }
      },
      {
        "id": 12467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83400021614031,
            21.02440746400106,
            105.83409964629918,
            21.02452221376128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400021614031,
              21.02440746400106
            ],
            [
              105.834030266235,
              21.024449369833057
            ],
            [
              105.83402816463638,
              21.02445185497244
            ],
            [
              105.8340018762829,
              21.024470538814473
            ],
            [
              105.83400105108717,
              21.024474205433485
            ],
            [
              105.8340393245974,
              21.02452221376128
            ],
            [
              105.83409964629918,
              21.02448570768792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.14_Văn Hương",
          "maTaiSan": "04.O14.DODV.12531",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02440746400106,
          "to_latitude": 21.02448570768792,
          "to_longitude": 105.83409964629918,
          "from_longitude": 105.83400021614031
        }
      },
      {
        "id": 12468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362686869485,
            21.024456587733827,
            105.83393093824381,
            21.024574620514002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83393093824381,
              21.024456587733827
            ],
            [
              105.83389615998351,
              21.02447625525179
            ],
            [
              105.83383601375357,
              21.024504191509866
            ],
            [
              105.83382137192197,
              21.024510539798587
            ],
            [
              105.8337695101205,
              21.024536358001953
            ],
            [
              105.8336973322534,
              21.02455583037703
            ],
            [
              105.83362686869485,
              21.024574620514002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.8_Văn Hương",
          "maTaiSan": "04.O14.DODV.12532",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024456587733827,
          "to_latitude": 21.024574620514002,
          "to_longitude": 105.83362686869485,
          "from_longitude": 105.83393093824381
        }
      },
      {
        "id": 12469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83393781463708,
            21.02443809392814,
            105.83425677892805,
            21.024599717698898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425677892805,
              21.02449186740467
            ],
            [
              105.83424692076349,
              21.024477407279555
            ],
            [
              105.83422139653048,
              21.024483346532694
            ],
            [
              105.8341547214393,
              21.024502833655916
            ],
            [
              105.83407764682407,
              21.024550097112343
            ],
            [
              105.83406002603456,
              21.024580886814125
            ],
            [
              105.83403614484345,
              21.02459546905932
            ],
            [
              105.8340232777553,
              21.024599717698898
            ],
            [
              105.83402276061214,
              21.024598753785554
            ],
            [
              105.83401164892538,
              21.024578057614438
            ],
            [
              105.8340117083124,
              21.02454861313203
            ],
            [
              105.83394836582812,
              21.024445366431966
            ],
            [
              105.83393891422398,
              21.024439309694895
            ],
            [
              105.83393781463708,
              21.02443809392814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.12_Văn Hương",
          "maTaiSan": "04.O14.DODV.12533",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02449186740467,
          "to_latitude": 21.02443809392814,
          "to_longitude": 105.83393781463708,
          "from_longitude": 105.83425677892805
        }
      },
      {
        "id": 12470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83403085376257,
            21.024437156585442,
            105.83436243226981,
            21.024633838661572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436243226981,
              21.024437156585442
            ],
            [
              105.83425677892805,
              21.02449186740467
            ],
            [
              105.83424495536534,
              21.024504023181578
            ],
            [
              105.83403085376257,
              21.024633838661572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.12_Văn Hương",
          "maTaiSan": "04.O14.DODV.12534",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024437156585442,
          "to_latitude": 21.024633838661572,
          "to_longitude": 105.83403085376257,
          "from_longitude": 105.83436243226981
        }
      },
      {
        "id": 12471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83422179416381,
            21.0244665451431,
            105.83447630424266,
            21.024837478727417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344687642956,
              21.0244665451431
            ],
            [
              105.83446802599833,
              21.02446802011702
            ],
            [
              105.83443616021225,
              21.024565375077113
            ],
            [
              105.83443555865674,
              21.024573788847515
            ],
            [
              105.83444746722004,
              21.024624956749435
            ],
            [
              105.83447630424266,
              21.024696906054835
            ],
            [
              105.83440648068996,
              21.02472783811888
            ],
            [
              105.83441018383272,
              21.024737593733498
            ],
            [
              105.83444630074618,
              21.024810145001226
            ],
            [
              105.83439634723071,
              21.024827780760802
            ],
            [
              105.8343271107047,
              21.024835773451986
            ],
            [
              105.83431959368947,
              21.02483664129484
            ],
            [
              105.83430011865305,
              21.02482343234942
            ],
            [
              105.83422179416381,
              21.024837478727417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.8_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12535",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0244665451431,
          "to_latitude": 21.024837478727417,
          "to_longitude": 105.83422179416381,
          "from_longitude": 105.8344687642956
        }
      },
      {
        "id": 12472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343271107047,
            21.024835773451986,
            105.83434175917007,
            21.024921540352633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343271107047,
              21.024835773451986
            ],
            [
              105.83434175917007,
              21.024921540352633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.8_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12536",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024835773451986,
          "to_latitude": 21.024921540352633,
          "to_longitude": 105.83434175917007,
          "from_longitude": 105.8343271107047
        }
      },
      {
        "id": 12473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344691493414,
            21.024468667902394,
            105.83459623646908,
            21.02472998640334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344729607818,
              21.024468667902394
            ],
            [
              105.83447198197764,
              21.02447014134671
            ],
            [
              105.8344691493414,
              21.02448041676965
            ],
            [
              105.834522158245,
              21.02450326203251
            ],
            [
              105.83452379679304,
              21.024517309959073
            ],
            [
              105.83456817397567,
              21.02464025667862
            ],
            [
              105.83459623646908,
              21.02471044486362
            ],
            [
              105.8345948189584,
              21.024719169734457
            ],
            [
              105.83456470235075,
              21.02472998640334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.7_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12537",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024468667902394,
          "to_latitude": 21.02472998640334,
          "to_longitude": 105.83456470235075,
          "from_longitude": 105.8344729607818
        }
      },
      {
        "id": 12474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346077703355,
            21.024578127075227,
            105.83472746526427,
            21.024727232321336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83472746526427,
              21.024578127075227
            ],
            [
              105.83465707550556,
              21.02460064578072
            ],
            [
              105.83461489983239,
              21.024614138278988
            ],
            [
              105.8346077703355,
              21.024624824079996
            ],
            [
              105.83464902896961,
              21.024727232321336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.6_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12538",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024578127075227,
          "to_latitude": 21.024727232321336,
          "to_longitude": 105.83464902896961,
          "from_longitude": 105.83472746526427
        }
      },
      {
        "id": 12475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83470832396573,
            21.024506848290546,
            105.83477697996294,
            21.024644281817515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83470832396573,
              21.024506848290546
            ],
            [
              105.83472171181597,
              21.02454153953101
            ],
            [
              105.83473297999502,
              21.024568555643977
            ],
            [
              105.83474178752593,
              21.024586610306706
            ],
            [
              105.83477697996294,
              21.024644281817515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.5_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12539",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024506848290546,
          "to_latitude": 21.024644281817515,
          "to_longitude": 105.83477697996294,
          "from_longitude": 105.83470832396573
        }
      },
      {
        "id": 12476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83641347167999,
            21.019607272126343,
            105.83654652219971,
            21.01966149768376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83641347167999,
              21.01962051876125
            ],
            [
              105.83641582290653,
              21.0196236576448
            ],
            [
              105.83643197328243,
              21.019645215861093
            ],
            [
              105.836501261465,
              21.019607272126343
            ],
            [
              105.83654652219971,
              21.01966149768376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_115.2_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12540",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01962051876125,
          "to_latitude": 21.01966149768376,
          "to_longitude": 105.83654652219971,
          "from_longitude": 105.83641347167999
        }
      },
      {
        "id": 12477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83424552122288,
            21.02421707164448,
            105.83436418514093,
            21.024344006653497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83424552122288,
              21.02421707164448
            ],
            [
              105.83430490239142,
              21.024261899993895
            ],
            [
              105.83435257402284,
              21.024310149372525
            ],
            [
              105.83435597501611,
              21.024320065438882
            ],
            [
              105.83436418514093,
              21.024344006653497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.9_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12541",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02421707164448,
          "to_latitude": 21.024344006653497,
          "to_longitude": 105.83436418514093,
          "from_longitude": 105.83424552122288
        }
      },
      {
        "id": 12478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436418514093,
            21.024344006653497,
            105.8344518064451,
            21.024470723563837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344518064451,
              21.024470723563837
            ],
            [
              105.83436904847778,
              21.024351004455262
            ],
            [
              105.83436418514093,
              21.024344006653497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.9_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12542",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024470723563837,
          "to_latitude": 21.024344006653497,
          "to_longitude": 105.83436418514093,
          "from_longitude": 105.8344518064451
        }
      },
      {
        "id": 12479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83436097235212,
            21.024279836803757,
            105.83451142770214,
            21.024358627700888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436097235212,
              21.024318471119507
            ],
            [
              105.83436563516803,
              21.024314847994226
            ],
            [
              105.83436781839715,
              21.024313549286333
            ],
            [
              105.83439441891606,
              21.024297730690023
            ],
            [
              105.8344170877612,
              21.024279836803757
            ],
            [
              105.83442084906184,
              21.024280857960292
            ],
            [
              105.83446518930847,
              21.02432788894391
            ],
            [
              105.83449440776333,
              21.024358627700888
            ],
            [
              105.83449829193827,
              21.024356490582694
            ],
            [
              105.83451142770214,
              21.024346249506937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.10_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12543",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024318471119507,
          "to_latitude": 21.024346249506937,
          "to_longitude": 105.83451142770214,
          "from_longitude": 105.83436097235212
        }
      },
      {
        "id": 12480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83429842273864,
            21.024085280183375,
            105.83457634016794,
            21.024309802033024
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83429842273864,
              21.024085280183375
            ],
            [
              105.83430354180828,
              21.024088967321195
            ],
            [
              105.83430480440157,
              21.024090087447497
            ],
            [
              105.83434697497285,
              21.024127558550347
            ],
            [
              105.83436275053276,
              21.02413877055109
            ],
            [
              105.83439063400267,
              21.024106301054868
            ],
            [
              105.8344414128768,
              21.024146087314186
            ],
            [
              105.83445222585001,
              21.024147677225262
            ],
            [
              105.83450545909935,
              21.024198406622464
            ],
            [
              105.83450011160981,
              21.024213655793627
            ],
            [
              105.83457634016794,
              21.024309802033024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12544",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024085280183375,
          "to_latitude": 21.024309802033024,
          "to_longitude": 105.83457634016794,
          "from_longitude": 105.83429842273864
        }
      },
      {
        "id": 12481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834241671264,
            21.024090087447497,
            105.83430480440157,
            21.024174702238668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430480440157,
              21.024090087447497
            ],
            [
              105.8342985803084,
              21.02409924776577
            ],
            [
              105.83428455120533,
              21.02411784893036
            ],
            [
              105.83425815684215,
              21.024157919862255
            ],
            [
              105.834241671264,
              21.024174702238668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12545",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024090087447497,
          "to_latitude": 21.024174702238668,
          "to_longitude": 105.834241671264,
          "from_longitude": 105.83430480440157
        }
      },
      {
        "id": 12482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834011831853,
            21.024108233642504,
            105.83425921606846,
            21.024163382026142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425921606846,
              21.02415222796967
            ],
            [
              105.8342578099219,
              21.02415141474006
            ],
            [
              105.83423575244345,
              21.02413862490187
            ],
            [
              105.83420160196592,
              21.024108233642504
            ],
            [
              105.83417788855402,
              21.024120622958808
            ],
            [
              105.83413128931069,
              21.024147353905555
            ],
            [
              105.8341247415052,
              21.024151415300764
            ],
            [
              105.83408595523812,
              21.024139877163368
            ],
            [
              105.83406140514789,
              21.024163382026142
            ],
            [
              105.834011831853,
              21.024117217494823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.12546",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02415222796967,
          "to_latitude": 21.024117217494823,
          "to_longitude": 105.834011831853,
          "from_longitude": 105.83425921606846
        }
      },
      {
        "id": 12483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423575244345,
            21.02400329187496,
            105.83433634703587,
            21.02413862490187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83423575244345,
              21.02413862490187
            ],
            [
              105.83426670719923,
              21.024091565038226
            ],
            [
              105.83427850473528,
              21.02407387352945
            ],
            [
              105.83433634703587,
              21.02400329187496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.12547",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02413862490187,
          "to_latitude": 21.02400329187496,
          "to_longitude": 105.83433634703587,
          "from_longitude": 105.83423575244345
        }
      },
      {
        "id": 12484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83406530782035,
            21.02383109965217,
            105.83438380029295,
            21.023995375350918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438380029295,
              21.023995375350918
            ],
            [
              105.83428420713523,
              21.02383109965217
            ],
            [
              105.83406530782035,
              21.023928354893005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.12548",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023995375350918,
          "to_latitude": 21.023928354893005,
          "to_longitude": 105.83406530782035,
          "from_longitude": 105.83438380029295
        }
      },
      {
        "id": 12485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385664489566,
            21.024038261708004,
            105.83390442422254,
            21.024089462992645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385664489566,
              21.024038261708004
            ],
            [
              105.83385686137787,
              21.024038613807125
            ],
            [
              105.8338880981708,
              21.024089462992645
            ],
            [
              105.83390442422254,
              21.024079968641477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.3_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12549",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024038261708004,
          "to_latitude": 21.024079968641477,
          "to_longitude": 105.83390442422254,
          "from_longitude": 105.83385664489566
        }
      },
      {
        "id": 12486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327887520568,
            21.024190026644938,
            105.83353164697945,
            21.02426709004501
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353164697945,
              21.024190026644938
            ],
            [
              105.83352889755702,
              21.024190882827813
            ],
            [
              105.8334183846453,
              21.024225274539695
            ],
            [
              105.83332883754242,
              21.0242543373994
            ],
            [
              105.83331878067476,
              21.024253089646773
            ],
            [
              105.83327887520568,
              21.02426709004501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.5_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12550",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024190026644938,
          "to_latitude": 21.02426709004501,
          "to_longitude": 105.83327887520568,
          "from_longitude": 105.83353164697945
        }
      },
      {
        "id": 12487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358901125506,
            21.024336530206547,
            105.83364875529897,
            21.024537646345152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358901125506,
              21.024336530206547
            ],
            [
              105.83359782909436,
              21.0243606184181
            ],
            [
              105.83360276403151,
              21.02437409832934
            ],
            [
              105.83363733181385,
              21.024497023848962
            ],
            [
              105.83364875529897,
              21.024537646345152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12551",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024336530206547,
          "to_latitude": 21.024537646345152,
          "to_longitude": 105.83364875529897,
          "from_longitude": 105.83358901125506
        }
      },
      {
        "id": 12488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353598708386,
            21.024209160945794,
            105.83358901125506,
            21.024336530206547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353598708386,
              21.024209160945794
            ],
            [
              105.83356215636694,
              21.024269240686063
            ],
            [
              105.83358901125506,
              21.024336530206547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12552",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024209160945794,
          "to_latitude": 21.024336530206547,
          "to_longitude": 105.83358901125506,
          "from_longitude": 105.83353598708386
        }
      },
      {
        "id": 12489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83342644446202,
            21.024269240686063,
            105.83356215636694,
            21.02431946116552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356215636694,
              21.024269240686063
            ],
            [
              105.8335614904598,
              21.024269549239495
            ],
            [
              105.83353912304271,
              21.024279906843766
            ],
            [
              105.83342644446202,
              21.02431946116552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12553",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024269240686063,
          "to_latitude": 21.02431946116552,
          "to_longitude": 105.83342644446202,
          "from_longitude": 105.83356215636694
        }
      },
      {
        "id": 12490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83349107403075,
            21.0243606184181,
            105.83359782909436,
            21.02439421860104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83359782909436,
              21.0243606184181
            ],
            [
              105.83349107403075,
              21.02439421860104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12554",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0243606184181,
          "to_latitude": 21.02439421860104,
          "to_longitude": 105.83349107403075,
          "from_longitude": 105.83359782909436
        }
      },
      {
        "id": 12491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83361671178633,
            21.024379775370868,
            105.83366293356855,
            21.024535123689773
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366293356855,
              21.024535123689773
            ],
            [
              105.83365176877483,
              21.024497600693962
            ],
            [
              105.83361671178633,
              21.024379775370868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.1_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12555",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024535123689773,
          "to_latitude": 21.024379775370868,
          "to_longitude": 105.83361671178633,
          "from_longitude": 105.83366293356855
        }
      },
      {
        "id": 12492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355884471663,
            21.024223130177617,
            105.83361671178633,
            21.024379775370868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83361671178633,
              21.024379775370868
            ],
            [
              105.83361481359901,
              21.024373861333007
            ],
            [
              105.83355884471663,
              21.024223130177617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.1_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12556",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024379775370868,
          "to_latitude": 21.024223130177617,
          "to_longitude": 105.83355884471663,
          "from_longitude": 105.83361671178633
        }
      },
      {
        "id": 12493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360564033501,
            21.024264537780343,
            105.83366218029599,
            21.02428048585338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366218029599,
              21.024264537780343
            ],
            [
              105.83360564033501,
              21.02428048585338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12557",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024264537780343,
          "to_latitude": 21.02428048585338,
          "to_longitude": 105.83360564033501,
          "from_longitude": 105.83366218029599
        }
      },
      {
        "id": 12494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83361812724195,
            21.02421760425011,
            105.8336445342074,
            21.024227414440507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83361812724195,
              21.024227414440507
            ],
            [
              105.8336445342074,
              21.02421760425011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12558",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024227414440507,
          "to_latitude": 21.02421760425011,
          "to_longitude": 105.8336445342074,
          "from_longitude": 105.83361812724195
        }
      },
      {
        "id": 12495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336434454898,
            21.024214709334874,
            105.8337536728522,
            21.024535845838287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337536728522,
              21.024535845838287
            ],
            [
              105.83375317151473,
              21.024534264041492
            ],
            [
              105.83374691039332,
              21.02451445994118
            ],
            [
              105.8337217009748,
              21.024431356869947
            ],
            [
              105.8337076230898,
              21.02438982429591
            ],
            [
              105.83369041496076,
              21.024341730692054
            ],
            [
              105.83368056976892,
              21.02431471385064
            ],
            [
              105.83366560171898,
              21.0242736381506
            ],
            [
              105.83366218029599,
              21.024264537780343
            ],
            [
              105.8336445342074,
              21.02421760425011
            ],
            [
              105.8336434454898,
              21.024214709334874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12559",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024535845838287,
          "to_latitude": 21.024214709334874,
          "to_longitude": 105.8336434454898,
          "from_longitude": 105.8337536728522
        }
      },
      {
        "id": 12496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368056976892,
            21.024287510264816,
            105.83375506622097,
            21.02431471385064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368056976892,
              21.02431471385064
            ],
            [
              105.83369296104729,
              21.02431018937676
            ],
            [
              105.83375506622097,
              21.024287510264816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12560",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02431471385064,
          "to_latitude": 21.024287510264816,
          "to_longitude": 105.83375506622097,
          "from_longitude": 105.83368056976892
        }
      },
      {
        "id": 12497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336434454898,
            21.02418225073272,
            105.83373859357484,
            21.024214709334874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336434454898,
              21.024214709334874
            ],
            [
              105.83369887517333,
              21.024195800505467
            ],
            [
              105.83373859357484,
              21.02418225073272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12561",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024214709334874,
          "to_latitude": 21.02418225073272,
          "to_longitude": 105.83373859357484,
          "from_longitude": 105.8336434454898
        }
      },
      {
        "id": 12498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368926545762,
            21.024165110904608,
            105.83369887517333,
            21.024195800505467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369887517333,
              21.024195800505467
            ],
            [
              105.83369861269074,
              21.024194960890767
            ],
            [
              105.83368926545762,
              21.024165110904608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12562",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024195800505467,
          "to_latitude": 21.024165110904608,
          "to_longitude": 105.83368926545762,
          "from_longitude": 105.83369887517333
        }
      },
      {
        "id": 12499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83380777346011,
            21.0243047562754,
            105.83385660711562,
            21.024481604531626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384652681906,
              21.024481604531626
            ],
            [
              105.83382395556707,
              21.024438362259854
            ],
            [
              105.83381767057719,
              21.024406656499046
            ],
            [
              105.83382902163636,
              21.024397233513103
            ],
            [
              105.83380777346011,
              21.024347821152165
            ],
            [
              105.83385660711562,
              21.0243047562754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12563",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024481604531626,
          "to_latitude": 21.0243047562754,
          "to_longitude": 105.83385660711562,
          "from_longitude": 105.83384652681906
        }
      },
      {
        "id": 12500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83377507088322,
            21.024481604531626,
            105.83384652681906,
            21.024509451703175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83377507088322,
              21.024509451703175
            ],
            [
              105.83379863305392,
              21.024502543041166
            ],
            [
              105.83384652681906,
              21.024481604531626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12564",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024509451703175,
          "to_latitude": 21.024481604531626,
          "to_longitude": 105.83384652681906,
          "from_longitude": 105.83377507088322
        }
      },
      {
        "id": 12501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83384652681906,
            21.024415604358598,
            105.83395774902324,
            21.024481604531626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384652681906,
              21.024481604531626
            ],
            [
              105.83388208702227,
              21.024466058212663
            ],
            [
              105.83388954463518,
              21.024461085528714
            ],
            [
              105.83395774902324,
              21.024415604358598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12565",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024481604531626,
          "to_latitude": 21.024415604358598,
          "to_longitude": 105.83395774902324,
          "from_longitude": 105.83384652681906
        }
      },
      {
        "id": 12502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83389278309407,
            21.024272422592105,
            105.83396645962269,
            21.024408642047852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396645962269,
              21.024408642047852
            ],
            [
              105.83396610780116,
              21.024408171387034
            ],
            [
              105.83389278309407,
              21.024310163534626
            ],
            [
              105.83393822836756,
              21.024272422592105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.13_Văn Hương",
          "maTaiSan": "04.O14.DODV.12566",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024408642047852,
          "to_latitude": 21.024272422592105,
          "to_longitude": 105.83393822836756,
          "from_longitude": 105.83396645962269
        }
      },
      {
        "id": 12503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414360829619,
            21.024286667235923,
            105.83428747058728,
            21.024411173679685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83414360829619,
              21.024286667235923
            ],
            [
              105.83414525672676,
              21.024287974244732
            ],
            [
              105.83414571452825,
              21.02429106734719
            ],
            [
              105.83414704408477,
              21.02429557784818
            ],
            [
              105.83420027456665,
              21.024341149947507
            ],
            [
              105.83422570390054,
              21.024359825816965
            ],
            [
              105.83428747058728,
              21.024411173679685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.16_Văn Hương",
          "maTaiSan": "04.O14.DODV.12567",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024286667235923,
          "to_latitude": 21.024411173679685,
          "to_longitude": 105.83428747058728,
          "from_longitude": 105.83414360829619
        }
      },
      {
        "id": 12504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420027456665,
            21.024315300887906,
            105.83422286262223,
            21.024341149947507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420027456665,
              21.024341149947507
            ],
            [
              105.8342008117845,
              21.024340534926754
            ],
            [
              105.83422286262223,
              21.024315300887906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.16_Văn Hương",
          "maTaiSan": "04.O14.DODV.12568",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024341149947507,
          "to_latitude": 21.024315300887906,
          "to_longitude": 105.83422286262223,
          "from_longitude": 105.83420027456665
        }
      },
      {
        "id": 12505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265231715382,
            21.024097297191748,
            105.83307987408853,
            21.024221893170054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265231715382,
              21.024221893170054
            ],
            [
              105.83265403664674,
              21.024220928237394
            ],
            [
              105.83265669743315,
              21.024217622055694
            ],
            [
              105.83285965925432,
              21.0241327259716
            ],
            [
              105.83287905704178,
              21.024144189535544
            ],
            [
              105.83288458745662,
              21.024147985799114
            ],
            [
              105.83297071245984,
              21.02410951388747
            ],
            [
              105.8330058717442,
              21.024097297191748
            ],
            [
              105.8330399379602,
              21.024108410698666
            ],
            [
              105.83307987408853,
              21.02411689777362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12569",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024221893170054,
          "to_latitude": 21.02411689777362,
          "to_longitude": 105.83307987408853,
          "from_longitude": 105.83265231715382
        }
      },
      {
        "id": 12506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265669743315,
            21.024217622055694,
            105.83271427682688,
            21.024340827122955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265669743315,
              21.024217622055694
            ],
            [
              105.83271427682688,
              21.024340827122955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12570",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024217622055694,
          "to_latitude": 21.024340827122955,
          "to_longitude": 105.83271427682688,
          "from_longitude": 105.83265669743315
        }
      },
      {
        "id": 12507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83262468939921,
            21.024132455702443,
            105.83265581010413,
            21.024200579852963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265581010413,
              21.024200579852963
            ],
            [
              105.83262468939921,
              21.024132455702443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.5_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12571",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024200579852963,
          "to_latitude": 21.024132455702443,
          "to_longitude": 105.83262468939921,
          "from_longitude": 105.83265581010413
        }
      },
      {
        "id": 12508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83258881553125,
            21.024051340258556,
            105.83262468939921,
            21.024137115319398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83261161391303,
              21.024137115319398
            ],
            [
              105.83261220533011,
              21.024136904682514
            ],
            [
              105.83262468939921,
              21.024132455702443
            ],
            [
              105.83258881553125,
              21.024051340258556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.5_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12572",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024137115319398,
          "to_latitude": 21.024051340258556,
          "to_longitude": 105.83258881553125,
          "from_longitude": 105.83261161391303
        }
      },
      {
        "id": 12509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83256735504648,
            21.023972029804007,
            105.83274455330071,
            21.02404971112607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83256735504648,
              21.02404971112607
            ],
            [
              105.83256901341969,
              21.024049000299453
            ],
            [
              105.83258437338775,
              21.02403962009217
            ],
            [
              105.8326839216067,
              21.023998993454764
            ],
            [
              105.83274455330071,
              21.023972029804007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.6_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12573",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02404971112607,
          "to_latitude": 21.023972029804007,
          "to_longitude": 105.83274455330071,
          "from_longitude": 105.83256735504648
        }
      },
      {
        "id": 12510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83274455330071,
            21.023902845037075,
            105.8328708256033,
            21.023972029804007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83274455330071,
              21.023972029804007
            ],
            [
              105.8328708256033,
              21.023902845037075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.6_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12574",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023972029804007,
          "to_latitude": 21.023902845037075,
          "to_longitude": 105.8328708256033,
          "from_longitude": 105.83274455330071
        }
      },
      {
        "id": 12511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328708256033,
            21.023902845037075,
            105.83292459329208,
            21.024023910900194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328708256033,
              21.023902845037075
            ],
            [
              105.83289486252626,
              21.023937247642813
            ],
            [
              105.83288558202956,
              21.023953840155297
            ],
            [
              105.83292459329208,
              21.024023910900194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.6_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12575",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023902845037075,
          "to_latitude": 21.024023910900194,
          "to_longitude": 105.83292459329208,
          "from_longitude": 105.8328708256033
        }
      },
      {
        "id": 12512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328708256033,
            21.02390066492422,
            105.83298987899802,
            21.023903782105585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8328708256033,
              21.023902845037075
            ],
            [
              105.83288312792683,
              21.02390066492422
            ],
            [
              105.83298987899802,
              21.023903782105585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.6_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12576",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023902845037075,
          "to_latitude": 21.023903782105585,
          "to_longitude": 105.83298987899802,
          "from_longitude": 105.8328708256033
        }
      },
      {
        "id": 12513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83298196779934,
            21.023903782105585,
            105.8330248814616,
            21.02403222229531
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83298987899802,
              21.023903782105585
            ],
            [
              105.8330248814616,
              21.023962386161696
            ],
            [
              105.83298196779934,
              21.02398433423978
            ],
            [
              105.83300381675878,
              21.024024025985774
            ],
            [
              105.83298786438978,
              21.02403222229531
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.6_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12577",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023903782105585,
          "to_latitude": 21.02403222229531,
          "to_longitude": 105.83298786438978,
          "from_longitude": 105.83298987899802
        }
      },
      {
        "id": 12514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83274455330071,
            21.023972029804007,
            105.83285168185392,
            21.024121540672688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83274455330071,
              21.023972029804007
            ],
            [
              105.83278402623193,
              21.02404555947667
            ],
            [
              105.83282113420164,
              21.024074850871994
            ],
            [
              105.83285168185392,
              21.024121540672688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.6_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12578",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023972029804007,
          "to_latitude": 21.024121540672688,
          "to_longitude": 105.83285168185392,
          "from_longitude": 105.83274455330071
        }
      },
      {
        "id": 12515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249680250293,
            21.023854435316082,
            105.8325480079137,
            21.023968539153895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83249680250293,
              21.023854435316082
            ],
            [
              105.83254300717368,
              21.023957395267885
            ],
            [
              105.8325480079137,
              21.023968539153895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.8_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12579",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023854435316082,
          "to_latitude": 21.023968539153895,
          "to_longitude": 105.8325480079137,
          "from_longitude": 105.83249680250293
        }
      },
      {
        "id": 12516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8325347695762,
            21.023968539153895,
            105.83257349861653,
            21.024025338259595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325347695762,
              21.023973147174893
            ],
            [
              105.832539598097,
              21.023971467025632
            ],
            [
              105.8325480079137,
              21.023968539153895
            ],
            [
              105.83257349861653,
              21.024025338259595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.8_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12580",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023973147174893,
          "to_latitude": 21.024025338259595,
          "to_longitude": 105.83257349861653,
          "from_longitude": 105.8325347695762
        }
      },
      {
        "id": 12517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83247742649368,
            21.02378472531078,
            105.83262542108987,
            21.0238512135195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83247742649368,
              21.0238512135195
            ],
            [
              105.83247887721465,
              21.023850181259192
            ],
            [
              105.83248694048882,
              21.023844441543808
            ],
            [
              105.83257324682297,
              21.023805547140995
            ],
            [
              105.83262542108987,
              21.02378472531078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12581",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0238512135195,
          "to_latitude": 21.02378472531078,
          "to_longitude": 105.83262542108987,
          "from_longitude": 105.83247742649368
        }
      },
      {
        "id": 12518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83262542108987,
            21.02370690610429,
            105.83278004132336,
            21.02378472531078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83262542108987,
              21.02378472531078
            ],
            [
              105.8327113251693,
              21.023749282221576
            ],
            [
              105.83278004132336,
              21.02370690610429
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12582",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02378472531078,
          "to_latitude": 21.02370690610429,
          "to_longitude": 105.83278004132336,
          "from_longitude": 105.83262542108987
        }
      },
      {
        "id": 12519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278004132336,
            21.02370690610429,
            105.83282804627633,
            21.023780124832115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278004132336,
              21.02370690610429
            ],
            [
              105.83280494948718,
              21.023744897484203
            ],
            [
              105.83282804627633,
              21.023780124832115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12583",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02370690610429,
          "to_latitude": 21.023780124832115,
          "to_longitude": 105.83282804627633,
          "from_longitude": 105.83278004132336
        }
      },
      {
        "id": 12520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83262542108987,
            21.02378472531078,
            105.83272233753776,
            21.023959292926794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83262542108987,
              21.02378472531078
            ],
            [
              105.83265123331397,
              21.023836021294244
            ],
            [
              105.83267282120873,
              21.023872202629544
            ],
            [
              105.83272233753776,
              21.023959292926794
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12584",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02378472531078,
          "to_latitude": 21.023959292926794,
          "to_longitude": 105.83272233753776,
          "from_longitude": 105.83262542108987
        }
      },
      {
        "id": 12521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281785717156,
            21.023787969549502,
            105.83286949815233,
            21.023884852089466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83286949815233,
              21.023884852089466
            ],
            [
              105.83281785717156,
              21.023787969549502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12585",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023884852089466,
          "to_latitude": 21.023787969549502,
          "to_longitude": 105.83281785717156,
          "from_longitude": 105.83286949815233
        }
      },
      {
        "id": 12522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281770083053,
            21.023688608274835,
            105.83295292929947,
            21.023787969549502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281785717156,
              21.023787969549502
            ],
            [
              105.83281770083053,
              21.023787706573646
            ],
            [
              105.83282804627633,
              21.023780124832115
            ],
            [
              105.83295292929947,
              21.023688608274835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12586",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023787969549502,
          "to_latitude": 21.023688608274835,
          "to_longitude": 105.83295292929947,
          "from_longitude": 105.83281785717156
        }
      },
      {
        "id": 12523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280494948718,
            21.02369853204604,
            105.83287195323659,
            21.023744897484203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83280494948718,
              21.023744897484203
            ],
            [
              105.83281430254908,
              21.023738425245924
            ],
            [
              105.83287195323659,
              21.02369853204604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.9_Ngõ 107B, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12587",
          "diaChiLapD": "Ngõ 107B, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023744897484203,
          "to_latitude": 21.02369853204604,
          "to_longitude": 105.83287195323659,
          "from_longitude": 105.83280494948718
        }
      },
      {
        "id": 12524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83247098481358,
            21.023733353334574,
            105.83272526923413,
            21.023835584950593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83247098481358,
              21.023835584950593
            ],
            [
              105.83247428701242,
              21.023834316015375
            ],
            [
              105.83261723649872,
              21.02377935777677
            ],
            [
              105.83268963240378,
              21.023751549845947
            ],
            [
              105.83272526923413,
              21.023733353334574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.10_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12588",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023835584950593,
          "to_latitude": 21.023733353334574,
          "to_longitude": 105.83272526923413,
          "from_longitude": 105.83247098481358
        }
      },
      {
        "id": 12525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83272526923413,
            21.023624565539535,
            105.83284827955325,
            21.023733353334574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272526923413,
              21.023733353334574
            ],
            [
              105.83278898003647,
              21.023695979388815
            ],
            [
              105.83279555117639,
              21.023660784700155
            ],
            [
              105.83284827955325,
              21.023624565539535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.10_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12589",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023733353334574,
          "to_latitude": 21.023624565539535,
          "to_longitude": 105.83284827955325,
          "from_longitude": 105.83272526923413
        }
      },
      {
        "id": 12526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83267769532075,
            21.023659442401954,
            105.83272526923413,
            21.023733353334574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272526923413,
              21.023733353334574
            ],
            [
              105.83267769532075,
              21.023659442401954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.10_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12590",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023733353334574,
          "to_latitude": 21.023659442401954,
          "to_longitude": 105.83267769532075,
          "from_longitude": 105.83272526923413
        }
      },
      {
        "id": 12527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83243836223795,
            21.02352453233101,
            105.83286695786755,
            21.023766079409057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83243836223795,
              21.02376132979877
            ],
            [
              105.83244134159786,
              21.023760304508414
            ],
            [
              105.83246096649555,
              21.023766079409057
            ],
            [
              105.83257799702,
              21.023713712965513
            ],
            [
              105.83257084013044,
              21.023702161442113
            ],
            [
              105.83266496076962,
              21.02364649651555
            ],
            [
              105.83286695786755,
              21.02352453233101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.11_Ngõ 109, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12591",
          "diaChiLapD": "Ngõ 109, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02376132979877,
          "to_latitude": 21.02352453233101,
          "to_longitude": 105.83286695786755,
          "from_longitude": 105.83243836223795
        }
      },
      {
        "id": 12528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83243531648137,
            21.023724406453088,
            105.8324503692077,
            21.023753775173077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8324503692077,
              21.023753775173077
            ],
            [
              105.83243531648137,
              21.023724406453088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.12_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12592",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023753775173077,
          "to_latitude": 21.023724406453088,
          "to_longitude": 105.83243531648137,
          "from_longitude": 105.8324503692077
        }
      },
      {
        "id": 12529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83241154473502,
            21.02367137890156,
            105.83243531648137,
            21.023729183856716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83242327004416,
              21.023729183856716
            ],
            [
              105.83243531648137,
              21.023724406453088
            ],
            [
              105.83242331420226,
              21.0236976322158
            ],
            [
              105.83241154473502,
              21.02367137890156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.12_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12593",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023729183856716,
          "to_latitude": 21.02367137890156,
          "to_longitude": 105.83241154473502,
          "from_longitude": 105.83242327004416
        }
      },
      {
        "id": 12530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83239679273599,
            21.02353968311271,
            105.8326468661283,
            21.023671742843774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83239679273599,
              21.023671742843774
            ],
            [
              105.83240070354428,
              21.02366954873953
            ],
            [
              105.8324647015488,
              21.02363364576312
            ],
            [
              105.8326468661283,
              21.02353968311271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.13_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12594",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023671742843774,
          "to_latitude": 21.02353968311271,
          "to_longitude": 105.8326468661283,
          "from_longitude": 105.83239679273599
        }
      },
      {
        "id": 12531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83264264345839,
            21.023499708405655,
            105.83270346584483,
            21.02353968311271
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326468661283,
              21.02353968311271
            ],
            [
              105.83264264345839,
              21.023531796961876
            ],
            [
              105.83270346584483,
              21.023499708405655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.13_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12595",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02353968311271,
          "to_latitude": 21.023499708405655,
          "to_longitude": 105.83270346584483,
          "from_longitude": 105.8326468661283
        }
      },
      {
        "id": 12532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234224103761,
            21.023506563549766,
            105.83237729223521,
            21.023588729499075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234224103761,
              21.023506563549766
            ],
            [
              105.83237729223521,
              21.023583192254087
            ],
            [
              105.83237721641069,
              21.023583223334803
            ],
            [
              105.8323626141781,
              21.023588729499075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.14_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12596",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023506563549766,
          "to_latitude": 21.023588729499075,
          "to_longitude": 105.8323626141781,
          "from_longitude": 105.83234224103761
        }
      },
      {
        "id": 12533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83237721641069,
            21.023583223334803,
            105.83240606151679,
            21.023646448752526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237721641069,
              21.023583223334803
            ],
            [
              105.8323914140095,
              21.02361434398501
            ],
            [
              105.83240606151679,
              21.023646448752526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.14_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12597",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023583223334803,
          "to_latitude": 21.023646448752526,
          "to_longitude": 105.83240606151679,
          "from_longitude": 105.83237721641069
        }
      },
      {
        "id": 12534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326125560959,
            21.023989063143095,
            105.83346617929084,
            21.02404592625256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83346617929084,
              21.023989063143095
            ],
            [
              105.8334584618173,
              21.023990378121876
            ],
            [
              105.83345193171223,
              21.02399970249056
            ],
            [
              105.83340194866325,
              21.024014595912163
            ],
            [
              105.83335891981379,
              21.0240121783768
            ],
            [
              105.83326125560959,
              21.02404592625256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12598",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023989063143095,
          "to_latitude": 21.02404592625256,
          "to_longitude": 105.83326125560959,
          "from_longitude": 105.83346617929084
        }
      },
      {
        "id": 12535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326125560959,
            21.02404592625256,
            105.83328963908069,
            21.024122763543733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83326125560959,
              21.02404592625256
            ],
            [
              105.83327771765398,
              21.02409049195626
            ],
            [
              105.83328963908069,
              21.024122763543733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12599",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02404592625256,
          "to_latitude": 21.024122763543733,
          "to_longitude": 105.83328963908069,
          "from_longitude": 105.83326125560959
        }
      },
      {
        "id": 12536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83308042334055,
            21.0240147135506,
            105.83327729264356,
            21.02409213229504
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.83325118923267,
                21.024024097681387
              ],
              [
                105.83327729264356,
                21.0240147135506
              ]
            ],
            [
              [
                105.83326125560959,
                21.02404592625256
              ],
              [
                105.83325118923267,
                21.024024097681387
              ]
            ],
            [
              [
                105.83325118923267,
                21.024024097681387
              ],
              [
                105.83308042334055,
                21.02409213229504
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12600",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.83327729264356,
            21.0240147135506
          ],
          "to_latitude": [
            105.83308042334055,
            21.02409213229504
          ],
          "to_longitude": [
            105.83325118923267,
            21.024024097681387
          ],
          "from_longitude": [
            105.83325118923267,
            21.024024097681387
          ]
        }
      },
      {
        "id": 12537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318538442055,
            21.02407250958259,
            105.83327771765398,
            21.024119921022095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327771765398,
              21.02409049195626
            ],
            [
              105.83320706326042,
              21.024119921022095
            ],
            [
              105.83318538442055,
              21.024079694665815
            ],
            [
              105.83319975223162,
              21.02407250958259
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12601",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02409049195626,
          "to_latitude": 21.02407250958259,
          "to_longitude": 105.83319975223162,
          "from_longitude": 105.83327771765398
        }
      },
      {
        "id": 12538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326137950193,
            21.024089884210436,
            105.83350003382202,
            21.024183906629606
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.83350003382202,
                21.024093955719298
              ],
              [
                105.83349538607278,
                21.024094754366395
              ],
              [
                105.83349252124664,
                21.02409440531032
              ],
              [
                105.83346073116596,
                21.024089884210436
              ],
              [
                105.83340863279959,
                21.024103243488128
              ],
              [
                105.83336502758056,
                21.024112747722167
              ],
              [
                105.8333282343669,
                21.024124529014465
              ]
            ],
            [
              [
                105.8333282343669,
                21.024124529014465
              ],
              [
                105.83329672366122,
                21.02413461887169
              ]
            ],
            [
              [
                105.8333282343669,
                21.024124529014465
              ],
              [
                105.83332875779345,
                21.024126105285166
              ],
              [
                105.83334391957268,
                21.02417176664034
              ]
            ],
            [
              [
                105.83329672366122,
                21.02413461887169
              ],
              [
                105.83329791860812,
                21.02413793507445
              ],
              [
                105.83331173826794,
                21.024176303152924
              ],
              [
                105.8332900864303,
                21.02418265503331
              ]
            ],
            [
              [
                105.83329672366122,
                21.02413461887169
              ],
              [
                105.83326137950193,
                21.02414593601862
              ],
              [
                105.83327539415252,
                21.024183906629606
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.6_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12602",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.83349538607278,
            21.024094754366395
          ],
          "to_latitude": [
            105.83326137950193,
            21.02414593601862
          ],
          "to_longitude": [
            105.83329672366122,
            21.02413461887169
          ],
          "from_longitude": [
            105.83350003382202,
            21.024093955719298
          ]
        }
      },
      {
        "id": 12539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344863325901,
            21.023917886568107,
            105.8334687586376,
            21.023968642406953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344863325901,
              21.023917886568107
            ],
            [
              105.8334687586376,
              21.02396540048788
            ],
            [
              105.83346352980777,
              21.023967229850488
            ],
            [
              105.8334594924908,
              21.023968642406953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.78_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12603",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023917886568107,
          "to_latitude": 21.023968642406953,
          "to_longitude": 105.8334594924908,
          "from_longitude": 105.83344863325901
        }
      },
      {
        "id": 12540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334687586376,
            21.02396540048788,
            105.83354511711435,
            21.024180049551227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334687586376,
              21.02396540048788
            ],
            [
              105.83347507256364,
              21.02398467776307
            ],
            [
              105.8334751018845,
              21.023984760715972
            ],
            [
              105.83354511711435,
              21.024180049551227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.78_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12604",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02396540048788,
          "to_latitude": 21.024180049551227,
          "to_longitude": 105.83354511711435,
          "from_longitude": 105.8334687586376
        }
      },
      {
        "id": 12541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83347507256364,
            21.023958369273043,
            105.83357785334499,
            21.02398654500063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83347507256364,
              21.02398467776307
            ],
            [
              105.83353307114567,
              21.02396688723603
            ],
            [
              105.83356433080111,
              21.023958369273043
            ],
            [
              105.83357785334499,
              21.02398654500063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.78_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12605",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02398467776307,
          "to_latitude": 21.02398654500063,
          "to_longitude": 105.83357785334499,
          "from_longitude": 105.83347507256364
        }
      },
      {
        "id": 12542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83326742260057,
            21.023513088737886,
            105.83339711650163,
            21.02381296130073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339711650163,
              21.02381296130073
            ],
            [
              105.8333818121701,
              21.02379755051945
            ],
            [
              105.83335716192938,
              21.023735895303805
            ],
            [
              105.83335564737634,
              21.02371523063835
            ],
            [
              105.83334270410943,
              21.02367634071903
            ],
            [
              105.83332670160323,
              21.02363639004807
            ],
            [
              105.83331622924506,
              21.02360538039028
            ],
            [
              105.83326742260057,
              21.023513088737886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.10_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12606",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02381296130073,
          "to_latitude": 21.023513088737886,
          "to_longitude": 105.83326742260057,
          "from_longitude": 105.83339711650163
        }
      },
      {
        "id": 12543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83339711650163,
            21.02381296130073,
            105.83346570459922,
            21.023989939026986
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83346570459922,
              21.023987601343418
            ],
            [
              105.8334585816509,
              21.02398943818176
            ],
            [
              105.83345609191778,
              21.023989939026986
            ],
            [
              105.83342354390723,
              21.02388696480704
            ],
            [
              105.83339711650163,
              21.02381296130073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.10_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12607",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023987601343418,
          "to_latitude": 21.02381296130073,
          "to_longitude": 105.83339711650163,
          "from_longitude": 105.83346570459922
        }
      },
      {
        "id": 12544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83340480679746,
            21.023685742410795,
            105.83362571072234,
            21.023791528951364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83340480679746,
              21.023791528951364
            ],
            [
              105.8335692416955,
              21.023714905587035
            ],
            [
              105.83361043047421,
              21.023696268005693
            ],
            [
              105.83362571072234,
              21.023689351189184
            ],
            [
              105.83362446433351,
              21.023686126466412
            ],
            [
              105.83362431789982,
              21.023685742410795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.1_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12608",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023791528951364,
          "to_latitude": 21.023685742410795,
          "to_longitude": 105.83362431789982,
          "from_longitude": 105.83340480679746
        }
      },
      {
        "id": 12545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336258713897,
            21.02368502847527,
            105.83367816079281,
            21.02379671141825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336258713897,
              21.02368502847527
            ],
            [
              105.83362594053271,
              21.023685179877983
            ],
            [
              105.83362796355996,
              21.023689499114315
            ],
            [
              105.83367808105308,
              21.02379655735784
            ],
            [
              105.83367816079281,
              21.02379671141825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12609",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02368502847527,
          "to_latitude": 21.02379671141825,
          "to_longitude": 105.83367816079281,
          "from_longitude": 105.8336258713897
        }
      },
      {
        "id": 12546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83367816079281,
            21.023713210007365,
            105.83387220193757,
            21.02379671141825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83367816079281,
              21.02379671141825
            ],
            [
              105.83387220193757,
              21.023713210007365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12610",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02379671141825,
          "to_latitude": 21.023713210007365,
          "to_longitude": 105.83387220193757,
          "from_longitude": 105.83367816079281
        }
      },
      {
        "id": 12547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83343871431407,
            21.023787996157814,
            105.83391970531808,
            21.023910988480406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83343871431407,
              21.023910988480406
            ],
            [
              105.83344217198524,
              21.02390948121681
            ],
            [
              105.83367899797739,
              21.02380627245553
            ],
            [
              105.83372108754672,
              21.02388198300467
            ],
            [
              105.83381310724286,
              21.023844030036102
            ],
            [
              105.83384691829434,
              21.02382448560194
            ],
            [
              105.83387664418684,
              21.02381055124327
            ],
            [
              105.83391970531808,
              21.023787996157814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.9_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12611",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023910988480406,
          "to_latitude": 21.023787996157814,
          "to_longitude": 105.83391970531808,
          "from_longitude": 105.83343871431407
        }
      },
      {
        "id": 12548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388570599772,
            21.023666451750874,
            105.83397447156011,
            21.02370709581013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388570599772,
              21.02370709581013
            ],
            [
              105.83389014773425,
              21.02370506255259
            ],
            [
              105.83397447156011,
              21.023666451750874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.4_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12612",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02370709581013,
          "to_latitude": 21.023666451750874,
          "to_longitude": 105.83397447156011,
          "from_longitude": 105.83388570599772
        }
      },
      {
        "id": 12549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362796355996,
            21.023607923988337,
            105.83380973264839,
            21.023689499114315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362796355996,
              21.023689499114315
            ],
            [
              105.83380973264839,
              21.023607923988337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12613",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023689499114315,
          "to_latitude": 21.023607923988337,
          "to_longitude": 105.83380973264839,
          "from_longitude": 105.83362796355996
        }
      },
      {
        "id": 12550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83383655796413,
            21.023529091753474,
            105.83398818831498,
            21.023595520370577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398818831498,
              21.023529091753474
            ],
            [
              105.83393343277943,
              21.02355310148317
            ],
            [
              105.83393300573645,
              21.023553288737958
            ],
            [
              105.83383655796413,
              21.023595520370577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.6_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12614",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023529091753474,
          "to_latitude": 21.023595520370577,
          "to_longitude": 105.83383655796413,
          "from_longitude": 105.83398818831498
        }
      },
      {
        "id": 12551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343864621914,
            21.023906920439774,
            105.83453170649162,
            21.023970496541743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343864621914,
              21.023970496541743
            ],
            [
              105.83446527011932,
              21.0239293184996
            ],
            [
              105.83451879610027,
              21.02391098055384
            ],
            [
              105.83453170649162,
              21.023906920439774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.12615",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023970496541743,
          "to_latitude": 21.023906920439774,
          "to_longitude": 105.83453170649162,
          "from_longitude": 105.8343864621914
        }
      },
      {
        "id": 12552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83450227280818,
            21.02394603806528,
            105.83465064620948,
            21.024256721635556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450227280818,
              21.02394603806528
            ],
            [
              105.83454988775762,
              21.024031638679972
            ],
            [
              105.83456526470431,
              21.024059377998945
            ],
            [
              105.83457642666035,
              21.02411139226614
            ],
            [
              105.8346148899901,
              21.024161687397893
            ],
            [
              105.83465064620948,
              21.024256721635556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.12616",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02394603806528,
          "to_latitude": 21.024256721635556,
          "to_longitude": 105.83465064620948,
          "from_longitude": 105.83450227280818
        }
      },
      {
        "id": 12553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435559627759,
            21.023971437711026,
            105.83443101238484,
            21.02402452074858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443101238484,
              21.023971437711026
            ],
            [
              105.83442001225285,
              21.02397372535275
            ],
            [
              105.83436663152658,
              21.02401384921577
            ],
            [
              105.83435559627759,
              21.02402452074858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.12617",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023971437711026,
          "to_latitude": 21.02402452074858,
          "to_longitude": 105.83435559627759,
          "from_longitude": 105.83443101238484
        }
      },
      {
        "id": 12554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344271994432,
            21.02396666752606,
            105.83450891821667,
            21.024068918852556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344271994432,
              21.02396666752606
            ],
            [
              105.83443032750093,
              21.02397058300871
            ],
            [
              105.83443101238484,
              21.023971437711026
            ],
            [
              105.83450891821667,
              21.024068918852556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.12618",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02396666752606,
          "to_latitude": 21.024068918852556,
          "to_longitude": 105.83450891821667,
          "from_longitude": 105.8344271994432
        }
      },
      {
        "id": 12555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454988775762,
            21.02402196731745,
            105.83459951207435,
            21.024031638679972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83454988775762,
              21.024031638679972
            ],
            [
              105.83459951207435,
              21.02402196731745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.12619",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024031638679972,
          "to_latitude": 21.02402196731745,
          "to_longitude": 105.83459951207435,
          "from_longitude": 105.83454988775762
        }
      },
      {
        "id": 12556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83460656847978,
            21.023908466113625,
            105.83476368708533,
            21.02424222377174
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460656847978,
              21.023908466113625
            ],
            [
              105.83460681758312,
              21.02390897521261
            ],
            [
              105.83461057440513,
              21.023916624242844
            ],
            [
              105.83466071474362,
              21.02401900697381
            ],
            [
              105.83473554859663,
              21.02414749720261
            ],
            [
              105.83473833456806,
              21.02416201910729
            ],
            [
              105.8347400782601,
              21.024171105108167
            ],
            [
              105.83476368708533,
              21.02424222377174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.8_Văn Hương",
          "maTaiSan": "04.O14.DODV.12620",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023908466113625,
          "to_latitude": 21.02424222377174,
          "to_longitude": 105.83476368708533,
          "from_longitude": 105.83460656847978
        }
      },
      {
        "id": 12557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473833456806,
            21.0241498098321,
            105.83486148210922,
            21.024242550315932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83473833456806,
              21.02416201910729
            ],
            [
              105.83474359951063,
              21.02415942088521
            ],
            [
              105.8347630753846,
              21.0241498098321
            ],
            [
              105.834778013426,
              21.02415279031072
            ],
            [
              105.83479152486937,
              21.024178755800342
            ],
            [
              105.83481303124071,
              21.024183782353024
            ],
            [
              105.83486148210922,
              21.024242550315932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.8_Văn Hương",
          "maTaiSan": "04.O14.DODV.12621",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02416201910729,
          "to_latitude": 21.024242550315932,
          "to_longitude": 105.83486148210922,
          "from_longitude": 105.83473833456806
        }
      },
      {
        "id": 12558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83468852010641,
            21.02388353327971,
            105.83488972385967,
            21.02408994375765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83468852010641,
              21.02388353327971
            ],
            [
              105.83468906768896,
              21.02388478065391
            ],
            [
              105.8346961643353,
              21.023896885151565
            ],
            [
              105.83474380924005,
              21.023978173572825
            ],
            [
              105.83483108506134,
              21.024084119647853
            ],
            [
              105.8348450442329,
              21.02408994375765
            ],
            [
              105.83488972385967,
              21.024068860867917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12622",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02388353327971,
          "to_latitude": 21.024068860867917,
          "to_longitude": 105.83488972385967,
          "from_longitude": 105.83468852010641
        }
      },
      {
        "id": 12559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346961643353,
            21.02377476482462,
            105.83504299716174,
            21.023896885151565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346961643353,
              21.023896885151565
            ],
            [
              105.83469848491552,
              21.023896068087005
            ],
            [
              105.83478546388163,
              21.023865434675816
            ],
            [
              105.83490990339486,
              21.02382266587292
            ],
            [
              105.83491342619237,
              21.02382145542074
            ],
            [
              105.83495600238562,
              21.023801157807334
            ],
            [
              105.83504299716174,
              21.02377476482462
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12623",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023896885151565,
          "to_latitude": 21.02377476482462,
          "to_longitude": 105.83504299716174,
          "from_longitude": 105.8346961643353
        }
      },
      {
        "id": 12560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490640744648,
            21.02381332292776,
            105.83499167010027,
            21.024048880861045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83490640744648,
              21.02381332292776
            ],
            [
              105.83490822951195,
              21.02381765216646
            ],
            [
              105.83490990339486,
              21.02382266587292
            ],
            [
              105.8349774718057,
              21.024025152129898
            ],
            [
              105.83499167010027,
              21.024045757211827
            ],
            [
              105.83499160861342,
              21.024048880861045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.13_Văn Hương",
          "maTaiSan": "04.O14.DODV.12624",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02381332292776,
          "to_latitude": 21.024048880861045,
          "to_longitude": 105.83499160861342,
          "from_longitude": 105.83490640744648
        }
      },
      {
        "id": 12561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83492476830682,
            21.024048880861045,
            105.8350965581746,
            21.024349092965313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499160861342,
              21.024048880861045
            ],
            [
              105.83498249215474,
              21.024054466895944
            ],
            [
              105.83492476830682,
              21.024092715483416
            ],
            [
              105.83496616328448,
              21.024148083095998
            ],
            [
              105.8349745813273,
              21.02415320369077
            ],
            [
              105.83501910632566,
              21.0242067837974
            ],
            [
              105.83502818405559,
              21.02424260533398
            ],
            [
              105.8350965581746,
              21.024349092965313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.13_Văn Hương",
          "maTaiSan": "04.O14.DODV.12625",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024048880861045,
          "to_latitude": 21.024349092965313,
          "to_longitude": 105.8350965581746,
          "from_longitude": 105.83499160861342
        }
      },
      {
        "id": 12562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499160861342,
            21.024048880861045,
            105.83512532937172,
            21.02415631063658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499160861342,
              21.024048880861045
            ],
            [
              105.83501300822391,
              21.024062722472454
            ],
            [
              105.83506557972896,
              21.024149022982073
            ],
            [
              105.83507689389504,
              21.02415631063658
            ],
            [
              105.83512532937172,
              21.024140626778955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.13_Văn Hương",
          "maTaiSan": "04.O14.DODV.12626",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024048880861045,
          "to_latitude": 21.024140626778955,
          "to_longitude": 105.83512532937172,
          "from_longitude": 105.83499160861342
        }
      },
      {
        "id": 12563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509134494815,
            21.02375493019406,
            105.83526928681843,
            21.024284515085363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509134494815,
              21.02375493019406
            ],
            [
              105.835091387843,
              21.02375503204771
            ],
            [
              105.8351743757955,
              21.02395174678057
            ],
            [
              105.835185526739,
              21.02400053925808
            ],
            [
              105.83519180488194,
              21.024038911689104
            ],
            [
              105.8351996862643,
              21.024043364716242
            ],
            [
              105.83524759996844,
              21.02404679017931
            ],
            [
              105.83525309157153,
              21.02405034449097
            ],
            [
              105.83524311488597,
              21.024154491812787
            ],
            [
              105.83525932817643,
              21.024232867090294
            ],
            [
              105.83526928681843,
              21.024284515085363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.12627",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02375493019406,
          "to_latitude": 21.024284515085363,
          "to_longitude": 105.83526928681843,
          "from_longitude": 105.83509134494815
        }
      },
      {
        "id": 12564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509327622701,
            21.023753995810203,
            105.83533491216996,
            21.023997473612393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509327622701,
              21.023753995810203
            ],
            [
              105.83509341451591,
              21.023754298614325
            ],
            [
              105.83516875025084,
              21.023919310742173
            ],
            [
              105.83519940020194,
              21.02390946683354
            ],
            [
              105.83524107503732,
              21.023898847100618
            ],
            [
              105.83533491216996,
              21.02390452002892
            ],
            [
              105.83533348247022,
              21.023955595312973
            ],
            [
              105.83533231032789,
              21.023997473612393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12628",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023753995810203,
          "to_latitude": 21.023997473612393,
          "to_longitude": 105.83533231032789,
          "from_longitude": 105.83509327622701
        }
      },
      {
        "id": 12565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83533348247022,
            21.023954353760185,
            105.8355865653644,
            21.024043714657672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83533348247022,
              21.023955595312973
            ],
            [
              105.8353983238757,
              21.02396032855588
            ],
            [
              105.83551360431203,
              21.023954353760185
            ],
            [
              105.8355240349785,
              21.0239586352811
            ],
            [
              105.83554598822273,
              21.024040052387388
            ],
            [
              105.83555174882929,
              21.024043714657672
            ],
            [
              105.8355865653644,
              21.024036151622955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12629",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023955595312973,
          "to_latitude": 21.024036151622955,
          "to_longitude": 105.8355865653644,
          "from_longitude": 105.83533348247022
        }
      },
      {
        "id": 12566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515485554078,
            21.02366005159364,
            105.83536252231748,
            21.023737850893532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515485554078,
              21.023737850893532
            ],
            [
              105.83526174418876,
              21.0236974757573
            ],
            [
              105.83536252231748,
              21.02366005159364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12630",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023737850893532,
          "to_latitude": 21.02366005159364,
          "to_longitude": 105.83536252231748,
          "from_longitude": 105.83515485554078
        }
      },
      {
        "id": 12567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535664416611,
            21.023593079926854,
            105.83548432063785,
            21.02366005159364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535664416611,
              21.023650655326815
            ],
            [
              105.83536026168147,
              21.023655914191117
            ],
            [
              105.83536252231748,
              21.02366005159364
            ],
            [
              105.83548432063785,
              21.023593079926854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12631",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023650655326815,
          "to_latitude": 21.023593079926854,
          "to_longitude": 105.83548432063785,
          "from_longitude": 105.83535664416611
        }
      },
      {
        "id": 12568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353287796661,
            21.02366005159364,
            105.83538325152524,
            21.023793676573337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536252231748,
              21.02366005159364
            ],
            [
              105.83536692681035,
              21.023693466394985
            ],
            [
              105.83538325152524,
              21.023723813105214
            ],
            [
              105.8353800044491,
              21.023731862325043
            ],
            [
              105.83533666756945,
              21.023751326490455
            ],
            [
              105.8353287796661,
              21.02375951411567
            ],
            [
              105.83534242245192,
              21.023793676573337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12632",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02366005159364,
          "to_latitude": 21.023793676573337,
          "to_longitude": 105.83534242245192,
          "from_longitude": 105.83536252231748
        }
      },
      {
        "id": 12569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524010942634,
            21.023633765839342,
            105.83537549422581,
            21.02368113430438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83524010942634,
              21.02368113430438
            ],
            [
              105.83536604488525,
              21.023633765839342
            ],
            [
              105.8353715998476,
              21.02364316911264
            ],
            [
              105.83537549422581,
              21.0236497751296
            ],
            [
              105.83537054784095,
              21.023652738514702
            ],
            [
              105.83536955692193,
              21.023651171772745
            ],
            [
              105.835365690484,
              21.023646127292054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.12633",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02368113430438,
          "to_latitude": 21.023646127292054,
          "to_longitude": 105.835365690484,
          "from_longitude": 105.83524010942634
        }
      },
      {
        "id": 12570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83536604488525,
            21.023522017605764,
            105.83561087231558,
            21.023633765839342
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536604488525,
              21.023633765839342
            ],
            [
              105.83542964582875,
              21.02360287660876
            ],
            [
              105.8354803959725,
              21.023578622338437
            ],
            [
              105.83559498863116,
              21.023530246333394
            ],
            [
              105.83561087231558,
              21.023522017605764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.12634",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023633765839342,
          "to_latitude": 21.023522017605764,
          "to_longitude": 105.83561087231558,
          "from_longitude": 105.83536604488525
        }
      },
      {
        "id": 12571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83554643421374,
            21.023564245568043,
            105.83578791521403,
            21.024026038015823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554643421374,
              21.023564245568043
            ],
            [
              105.83555132825246,
              21.02356897970661
            ],
            [
              105.83562348037586,
              21.023638745178694
            ],
            [
              105.83563086612097,
              21.0236524821469
            ],
            [
              105.8356425792494,
              21.023655237234838
            ],
            [
              105.83570381026013,
              21.023708480370143
            ],
            [
              105.83570576074425,
              21.02371582483217
            ],
            [
              105.83563475856853,
              21.02377921599775
            ],
            [
              105.8357811961964,
              21.023962287974257
            ],
            [
              105.83578791521403,
              21.023971347706475
            ],
            [
              105.83578654021,
              21.023980436375993
            ],
            [
              105.83572723471335,
              21.024026038015823
            ],
            [
              105.83571959587492,
              21.024025406263636
            ],
            [
              105.83571539527081,
              21.02402064704595
            ],
            [
              105.83567113882789,
              21.023971443730936
            ],
            [
              105.83559719333357,
              21.023884411141122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.12635",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 90,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023564245568043,
          "to_latitude": 21.023884411141122,
          "to_longitude": 105.83559719333357,
          "from_longitude": 105.83554643421374
        }
      },
      {
        "id": 12572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355507136677,
            21.023562269968174,
            105.83583923505348,
            21.024085383046025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355507136677,
              21.023562269968174
            ],
            [
              105.8356387275598,
              21.023644555669836
            ],
            [
              105.83571319904746,
              21.023711202616216
            ],
            [
              105.83581970371861,
              21.023847711800162
            ],
            [
              105.83583923505348,
              21.02387274529834
            ],
            [
              105.83583908388404,
              21.02387651699943
            ],
            [
              105.83577062975034,
              21.023927610052347
            ],
            [
              105.83576664818146,
              21.023934532052447
            ],
            [
              105.8358154399156,
              21.02399606824827
            ],
            [
              105.83581671487201,
              21.024001475001032
            ],
            [
              105.83570286255339,
              21.024085383046025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.12636",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 81,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023562269968174,
          "to_latitude": 21.024085383046025,
          "to_longitude": 105.83570286255339,
          "from_longitude": 105.8355507136677
        }
      },
      {
        "id": 12573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559965090137,
            21.023644555669836,
            105.8356387275598,
            21.023683955693762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356387275598,
              21.023644555669836
            ],
            [
              105.8356382511659,
              21.023645035815775
            ],
            [
              105.83563086612097,
              21.0236524821469
            ],
            [
              105.83559965090137,
              21.023683955693762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.12637",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023644555669836,
          "to_latitude": 21.023683955693762,
          "to_longitude": 105.83559965090137,
          "from_longitude": 105.8356387275598
        }
      },
      {
        "id": 12574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555470814395,
            21.02356042583245,
            105.83602452654848,
            21.023961222085212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83555470814395,
              21.02356042583245
            ],
            [
              105.83555947164464,
              21.023564694548703
            ],
            [
              105.8357161294492,
              21.023705022812894
            ],
            [
              105.83584431235698,
              21.023867037263276
            ],
            [
              105.83592603544844,
              21.02381392709885
            ],
            [
              105.83593890885759,
              21.023816010787232
            ],
            [
              105.83598053242598,
              21.02388661711246
            ],
            [
              105.83602452654848,
              21.023961222085212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.7_Văn Hương",
          "maTaiSan": "04.O14.DODV.12638",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02356042583245,
          "to_latitude": 21.023961222085212,
          "to_longitude": 105.83602452654848,
          "from_longitude": 105.83555470814395
        }
      },
      {
        "id": 12575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350300716558,
            21.023575947487526,
            105.83508013325337,
            21.02374306359549
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83508013325337,
              21.02374306359549
            ],
            [
              105.8350300716558,
              21.023673506461865
            ],
            [
              105.83503509253819,
              21.023609818089838
            ],
            [
              105.835064836141,
              21.023575947487526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.12639",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02374306359549,
          "to_latitude": 21.023575947487526,
          "to_longitude": 105.835064836141,
          "from_longitude": 105.83508013325337
        }
      },
      {
        "id": 12576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83508139331727,
            21.02340308273493,
            105.83516500328335,
            21.023554949338216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83508139331727,
              21.023554949338216
            ],
            [
              105.83508396709696,
              21.02355416896074
            ],
            [
              105.83514116500186,
              21.02351497542488
            ],
            [
              105.83511315281947,
              21.023476791901924
            ],
            [
              105.83511586968595,
              21.02340308273493
            ],
            [
              105.83516500328335,
              21.02340621681879
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12640",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023554949338216,
          "to_latitude": 21.02340621681879,
          "to_longitude": 105.83516500328335,
          "from_longitude": 105.83508139331727
        }
      },
      {
        "id": 12577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505098767819,
            21.023478586549725,
            105.83508139331727,
            21.023554949338216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83508139331727,
              21.023554949338216
            ],
            [
              105.83506866030719,
              21.023522969997153
            ],
            [
              105.83505098767819,
              21.023478586549725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.12641",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023554949338216,
          "to_latitude": 21.023478586549725,
          "to_longitude": 105.83505098767819,
          "from_longitude": 105.83508139331727
        }
      },
      {
        "id": 12578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83457537636927,
            21.023504828487898,
            105.83464735549369,
            21.02389802791369
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83464735549369,
              21.02389802791369
            ],
            [
              105.83464711816669,
              21.023897387789535
            ],
            [
              105.8346381020599,
              21.02387296099603
            ],
            [
              105.83462298097245,
              21.023785940950862
            ],
            [
              105.8345946015013,
              21.023621621238018
            ],
            [
              105.83457975565926,
              21.023589915063923
            ],
            [
              105.83457537636927,
              21.023504828487898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12642",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02389802791369,
          "to_latitude": 21.023504828487898,
          "to_longitude": 105.83457537636927,
          "from_longitude": 105.83464735549369
        }
      },
      {
        "id": 12579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83566151836581,
            21.023490697590624,
            105.8356833328937,
            21.02351189111422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566151836581,
              21.02351189111422
            ],
            [
              105.8356833328937,
              21.02349386035997
            ],
            [
              105.8356779431722,
              21.023490978462657
            ],
            [
              105.83567756931781,
              21.023490697590624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12643",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02351189111422,
          "to_latitude": 21.023490697590624,
          "to_longitude": 105.83567756931781,
          "from_longitude": 105.83566151836581
        }
      },
      {
        "id": 12580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356833328937,
            21.023423453606664,
            105.83584940548445,
            21.02349386035997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356833328937,
              21.02349386035997
            ],
            [
              105.83572667813867,
              21.023460731198455
            ],
            [
              105.83574037618897,
              21.023450261462486
            ],
            [
              105.83584940548445,
              21.023423453606664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12644",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349386035997,
          "to_latitude": 21.023423453606664,
          "to_longitude": 105.83584940548445,
          "from_longitude": 105.8356833328937
        }
      },
      {
        "id": 12581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83572667813867,
            21.023460731198455,
            105.83583085740655,
            21.02358548732405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572667813867,
              21.023460731198455
            ],
            [
              105.83583085740655,
              21.02358548732405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.12645",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023460731198455,
          "to_latitude": 21.02358548732405,
          "to_longitude": 105.83583085740655,
          "from_longitude": 105.83572667813867
        }
      },
      {
        "id": 12582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356638647547,
            21.023577512125073,
            105.8336280162777,
            21.023684447124594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336280162777,
              21.023684447124594
            ],
            [
              105.83362791735405,
              21.02368430309364
            ],
            [
              105.83362697935269,
              21.02368290769419
            ],
            [
              105.83361661040207,
              21.023672411571642
            ],
            [
              105.83359389769305,
              21.02363362465369
            ],
            [
              105.83356638647547,
              21.023577512125073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.3_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12646",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023684447124594,
          "to_latitude": 21.023577512125073,
          "to_longitude": 105.83356638647547,
          "from_longitude": 105.8336280162777
        }
      },
      {
        "id": 12583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356638647547,
            21.023487128301422,
            105.83376797729026,
            21.023577512125073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356638647547,
              21.023577512125073
            ],
            [
              105.83362854685483,
              21.023553738990767
            ],
            [
              105.83376797729026,
              21.023487128301422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.3_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12647",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023577512125073,
          "to_latitude": 21.023487128301422,
          "to_longitude": 105.83376797729026,
          "from_longitude": 105.83356638647547
        }
      },
      {
        "id": 12584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334803356824,
            21.023577512125073,
            105.83356638647547,
            21.023614714031204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356638647547,
              21.023577512125073
            ],
            [
              105.8334803356824,
              21.023614714031204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.3_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12648",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023577512125073,
          "to_latitude": 21.023614714031204,
          "to_longitude": 105.8334803356824,
          "from_longitude": 105.83356638647547
        }
      },
      {
        "id": 12585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833779474228,
            21.023476271188205,
            105.8338346245085,
            21.023588626509586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338346245085,
              21.023588626509586
            ],
            [
              105.833779474228,
              21.023476271188205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.5_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12649",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023588626509586,
          "to_latitude": 21.023476271188205,
          "to_longitude": 105.833779474228,
          "from_longitude": 105.8338346245085
        }
      },
      {
        "id": 12586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83378182054432,
            21.02342326859113,
            105.83389490745189,
            21.023475065623703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83378182054432,
              21.023475065623703
            ],
            [
              105.83386151570996,
              21.023438623631186
            ],
            [
              105.83389490745189,
              21.02342326859113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.5_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12650",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023475065623703,
          "to_latitude": 21.02342326859113,
          "to_longitude": 105.83389490745189,
          "from_longitude": 105.83378182054432
        }
      },
      {
        "id": 12587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373793774808,
            21.023410914301778,
            105.83378182054432,
            21.0234796771911
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83378182054432,
              21.023475065623703
            ],
            [
              105.833779474228,
              21.023476271188205
            ],
            [
              105.83377285257149,
              21.0234796771911
            ],
            [
              105.83373793774808,
              21.023410914301778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.5_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12651",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023475065623703,
          "to_latitude": 21.023410914301778,
          "to_longitude": 105.83373793774808,
          "from_longitude": 105.83378182054432
        }
      },
      {
        "id": 12588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323488970124,
            21.023399364427057,
            105.83254153622278,
            21.0234885387508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323488970124,
              21.0234885387508
            ],
            [
              105.83237662153955,
              21.023476071346877
            ],
            [
              105.83244464113248,
              21.02344113501807
            ],
            [
              105.83248841493403,
              21.02342199369979
            ],
            [
              105.83250391820147,
              21.023415389310344
            ],
            [
              105.83254153622278,
              21.023399364427057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12652",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0234885387508,
          "to_latitude": 21.023399364427057,
          "to_longitude": 105.83254153622278,
          "from_longitude": 105.8323488970124
        }
      },
      {
        "id": 12589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83253924281738,
            21.023313150668223,
            105.83271535691262,
            21.023399364427057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83253924281738,
              21.023394681597612
            ],
            [
              105.83253935934096,
              21.023394877026853
            ],
            [
              105.83254153622278,
              21.023399364427057
            ],
            [
              105.8325814477985,
              21.023381294247304
            ],
            [
              105.83266916421218,
              21.023336700780906
            ],
            [
              105.83271535691262,
              21.023313150668223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12653",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023394681597612,
          "to_latitude": 21.023313150668223,
          "to_longitude": 105.83271535691262,
          "from_longitude": 105.83253924281738
        }
      },
      {
        "id": 12590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83250391820147,
            21.023415389310344,
            105.83254821324365,
            21.02350631500634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83250391820147,
              21.023415389310344
            ],
            [
              105.83250397356487,
              21.023415484781115
            ],
            [
              105.83254821324365,
              21.023490987313416
            ],
            [
              105.83251703375994,
              21.02350631500634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12654",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023415389310344,
          "to_latitude": 21.02350631500634,
          "to_longitude": 105.83251703375994,
          "from_longitude": 105.83250391820147
        }
      },
      {
        "id": 12591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234286212134,
            21.023379342653016,
            105.83252456142017,
            21.02346924199794
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234286212134,
              21.02346924199794
            ],
            [
              105.83252456142017,
              21.023379342653016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12655",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02346924199794,
          "to_latitude": 21.023379342653016,
          "to_longitude": 105.83252456142017,
          "from_longitude": 105.83234286212134
        }
      },
      {
        "id": 12592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252456142017,
            21.023314839233045,
            105.83264829853064,
            21.02339644148939
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83253676008161,
              21.02339644148939
            ],
            [
              105.83253662532591,
              21.02339625427841
            ],
            [
              105.83252456142017,
              21.023379342653016
            ],
            [
              105.8325826060978,
              21.02334908412095
            ],
            [
              105.83264829853064,
              21.023314839233045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12656",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02339644148939,
          "to_latitude": 21.023314839233045,
          "to_longitude": 105.83264829853064,
          "from_longitude": 105.83253676008161
        }
      },
      {
        "id": 12593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83254595427546,
            21.02327161877892,
            105.8325826060978,
            21.02334908412095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325826060978,
              21.02334908412095
            ],
            [
              105.83258215929679,
              21.023348138826083
            ],
            [
              105.83254595427546,
              21.02327161877892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12657",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02334908412095,
          "to_latitude": 21.02327161877892,
          "to_longitude": 105.83254595427546,
          "from_longitude": 105.8325826060978
        }
      },
      {
        "id": 12594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8327191542477,
            21.02329780255664,
            105.83278703193938,
            21.02342850087173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8327191542477,
              21.02329780255664
            ],
            [
              105.83272157862751,
              21.02330303028969
            ],
            [
              105.83272279036268,
              21.023304696224713
            ],
            [
              105.83273283933585,
              21.023307998888175
            ],
            [
              105.83276971633647,
              21.023364847897398
            ],
            [
              105.83278703193938,
              21.02339893930542
            ],
            [
              105.83272579923842,
              21.02342850087173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.12658",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02329780255664,
          "to_latitude": 21.02342850087173,
          "to_longitude": 105.83272579923842,
          "from_longitude": 105.8327191542477
        }
      },
      {
        "id": 12595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83272279036268,
            21.023224417611015,
            105.83286354668755,
            21.023304696224713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272279036268,
              21.023304696224713
            ],
            [
              105.83272852944778,
              21.02330190245321
            ],
            [
              105.83274274829678,
              21.023295618653233
            ],
            [
              105.83280346147507,
              21.023265019111733
            ],
            [
              105.83286354668755,
              21.023224417611015
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.12659",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023304696224713,
          "to_latitude": 21.023224417611015,
          "to_longitude": 105.83286354668755,
          "from_longitude": 105.83272279036268
        }
      },
      {
        "id": 12596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83287216582555,
            21.023212768973977,
            105.83296700817866,
            21.023352023334727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83287216582555,
              21.023212768973977
            ],
            [
              105.83287401715455,
              21.023215104677796
            ],
            [
              105.83292760593278,
              21.023297605571262
            ],
            [
              105.83296700817866,
              21.023352023334727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12660",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023212768973977,
          "to_latitude": 21.023352023334727,
          "to_longitude": 105.83296700817866,
          "from_longitude": 105.83287216582555
        }
      },
      {
        "id": 12597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83296311499686,
            21.023352023334727,
            105.83303271318789,
            21.023503937230313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83296700817866,
              21.023352023334727
            ],
            [
              105.83296913900564,
              21.02335497186088
            ],
            [
              105.83296311499686,
              21.023369681464406
            ],
            [
              105.8329838607833,
              21.02340181321666
            ],
            [
              105.83298009671825,
              21.023413275500474
            ],
            [
              105.83300551815645,
              21.023457077488306
            ],
            [
              105.83303271318789,
              21.023503937230313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12661",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023352023334727,
          "to_latitude": 21.023503937230313,
          "to_longitude": 105.83303271318789,
          "from_longitude": 105.83296700817866
        }
      },
      {
        "id": 12598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83296251223676,
            21.023457077488306,
            105.83300551815645,
            21.023560034807744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300551815645,
              21.023457077488306
            ],
            [
              105.83296251223676,
              21.023486463157365
            ],
            [
              105.83300059722114,
              21.023560034807744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12662",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023457077488306,
          "to_latitude": 21.023560034807744,
          "to_longitude": 105.83300059722114,
          "from_longitude": 105.83300551815645
        }
      },
      {
        "id": 12599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83289806358862,
            21.023352023334727,
            105.83296700817866,
            21.02343617605563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83296700817866,
              21.023352023334727
            ],
            [
              105.83289806358862,
              21.02339635883163
            ],
            [
              105.83292177457656,
              21.02343617605563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12663",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023352023334727,
          "to_latitude": 21.02343617605563,
          "to_longitude": 105.83292177457656,
          "from_longitude": 105.83296700817866
        }
      },
      {
        "id": 12600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83283397157538,
            21.023297605571262,
            105.83292760593278,
            21.023407319748177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292760593278,
              21.023297605571262
            ],
            [
              105.83284825319444,
              21.023345846979964
            ],
            [
              105.83285481454523,
              21.02335634644261
            ],
            [
              105.83283397157538,
              21.023369925513737
            ],
            [
              105.83285447528644,
              21.023407319748177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12664",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023297605571262,
          "to_latitude": 21.023407319748177,
          "to_longitude": 105.83285447528644,
          "from_longitude": 105.83292760593278
        }
      },
      {
        "id": 12601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288149142541,
            21.023143438474047,
            105.833011579271,
            21.023210710923227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83288149142541,
              21.023210710923227
            ],
            [
              105.83299013857646,
              21.023154525786026
            ],
            [
              105.833011579271,
              21.023143438474047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.12665",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023210710923227,
          "to_latitude": 21.023143438474047,
          "to_longitude": 105.833011579271,
          "from_longitude": 105.83288149142541
        }
      },
      {
        "id": 12602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300842431302,
            21.02308867526099,
            105.83310854226097,
            21.023143438474047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300842431302,
              21.023139000128396
            ],
            [
              105.8330090754663,
              21.02313984596983
            ],
            [
              105.833011579271,
              21.023143438474047
            ],
            [
              105.83310854226097,
              21.02308867526099
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.12666",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023139000128396,
          "to_latitude": 21.02308867526099,
          "to_longitude": 105.83310854226097,
          "from_longitude": 105.83300842431302
        }
      },
      {
        "id": 12603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299013857646,
            21.023154525786026,
            105.83304498215186,
            21.02324002913561
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299013857646,
              21.023154525786026
            ],
            [
              105.83304498215186,
              21.02324002913561
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.12667",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023154525786026,
          "to_latitude": 21.02324002913561,
          "to_longitude": 105.83304498215186,
          "from_longitude": 105.83299013857646
        }
      },
      {
        "id": 12604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313218276186,
            21.023078238318835,
            105.83323784610255,
            21.023219992933562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83323784610255,
              21.023219992933562
            ],
            [
              105.83320335527742,
              21.023172424708207
            ],
            [
              105.8331879812998,
              21.02316610429386
            ],
            [
              105.83313218276186,
              21.023078238318835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.12668",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023219992933562,
          "to_latitude": 21.023078238318835,
          "to_longitude": 105.83313218276186,
          "from_longitude": 105.83323784610255
        }
      },
      {
        "id": 12605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313218276186,
            21.023035264143218,
            105.83320089131968,
            21.023078238318835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83313218276186,
              21.023078238318835
            ],
            [
              105.83315626029008,
              21.023063179241593
            ],
            [
              105.83320089131968,
              21.023035264143218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.12669",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023078238318835,
          "to_latitude": 21.023035264143218,
          "to_longitude": 105.83320089131968,
          "from_longitude": 105.83313218276186
        }
      },
      {
        "id": 12606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83320089131968,
            21.023013518594198,
            105.83324209347518,
            21.023035264143218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83320089131968,
              21.023035264143218
            ],
            [
              105.83324209347518,
              21.023013518594198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.12670",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023035264143218,
          "to_latitude": 21.023013518594198,
          "to_longitude": 105.83324209347518,
          "from_longitude": 105.83320089131968
        }
      },
      {
        "id": 12607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325067226981,
            21.023005350258767,
            105.8332953777446,
            21.02307686348008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332953777446,
              21.02307686348008
            ],
            [
              105.83325067226981,
              21.023005350258767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12671",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02307686348008,
          "to_latitude": 21.023005350258767,
          "to_longitude": 105.83325067226981,
          "from_longitude": 105.8332953777446
        }
      },
      {
        "id": 12608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325067226981,
            21.022943907532447,
            105.83337271342876,
            21.023005350258767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325067226981,
              21.023005350258767
            ],
            [
              105.83332149761341,
              21.02296765675195
            ],
            [
              105.83333339007957,
              21.02296132830232
            ],
            [
              105.83337271342876,
              21.022943907532447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12672",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023005350258767,
          "to_latitude": 21.022943907532447,
          "to_longitude": 105.83337271342876,
          "from_longitude": 105.83325067226981
        }
      },
      {
        "id": 12609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336833878822,
            21.022912040683952,
            105.83344464274926,
            21.022943907532447
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336833878822,
              21.02293585595114
            ],
            [
              105.83336865974641,
              21.0229363132163
            ],
            [
              105.83337271342876,
              21.022943907532447
            ],
            [
              105.83344464274926,
              21.022912040683952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12673",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02293585595114,
          "to_latitude": 21.022912040683952,
          "to_longitude": 105.83344464274926,
          "from_longitude": 105.83336833878822
        }
      },
      {
        "id": 12610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83332149761341,
            21.02296765675195,
            105.83336864466021,
            21.023061156915222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83332149761341,
              21.02296765675195
            ],
            [
              105.83332203162647,
              21.02296871542375
            ],
            [
              105.83336864466021,
              21.023061156915222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12674",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02296765675195,
          "to_latitude": 21.023061156915222,
          "to_longitude": 105.83336864466021,
          "from_longitude": 105.83332149761341
        }
      },
      {
        "id": 12611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83328092521477,
            21.022734884243604,
            105.83339904510582,
            21.022922839066087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339904510582,
              21.022922839066087
            ],
            [
              105.83339872237123,
              21.0229225814222
            ],
            [
              105.83339174947493,
              21.022916964116586
            ],
            [
              105.83332333926866,
              21.022811260807618
            ],
            [
              105.83330986087302,
              21.02277663310586
            ],
            [
              105.83328092521477,
              21.022734884243604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12675",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022922839066087,
          "to_latitude": 21.022734884243604,
          "to_longitude": 105.83328092521477,
          "from_longitude": 105.83339904510582
        }
      },
      {
        "id": 12612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83346022239694,
            21.022817689629722,
            105.83362033817414,
            21.022885620906745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83346022239694,
              21.022885620906745
            ],
            [
              105.83362033817414,
              21.022817689629722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.12676",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022885620906745,
          "to_latitude": 21.022817689629722,
          "to_longitude": 105.83362033817414,
          "from_longitude": 105.83346022239694
        }
      },
      {
        "id": 12613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356249769187,
            21.022495436328615,
            105.83359407903134,
            21.022562485030054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83359407903134,
              21.022562485030054
            ],
            [
              105.83356249769187,
              21.022495436328615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12677",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022562485030054,
          "to_latitude": 21.022495436328615,
          "to_longitude": 105.83356249769187,
          "from_longitude": 105.83359407903134
        }
      },
      {
        "id": 12614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83371570759208,
            21.0223722582538,
            105.8337501230859,
            21.022449765353496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83371570759208,
              21.022449765353496
            ],
            [
              105.8337501230859,
              21.022435694228434
            ],
            [
              105.83371950357265,
              21.0223722582538
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12678",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022449765353496,
          "to_latitude": 21.0223722582538,
          "to_longitude": 105.83371950357265,
          "from_longitude": 105.83371570759208
        }
      },
      {
        "id": 12615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83345478775938,
            21.02267723294953,
            105.8335904688615,
            21.022746409231427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335904688615,
              21.02267723294953
            ],
            [
              105.83345478775938,
              21.022746409231427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12679",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02267723294953,
          "to_latitude": 21.022746409231427,
          "to_longitude": 105.83345478775938,
          "from_longitude": 105.8335904688615
        }
      },
      {
        "id": 12616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83369135986045,
            21.022763262536944,
            105.83380196379163,
            21.022811362173663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369135986045,
              21.022811362173663
            ],
            [
              105.83369215638113,
              21.022811015947433
            ],
            [
              105.83380196379163,
              21.022763262536944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12680",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022811362173663,
          "to_latitude": 21.022763262536944,
          "to_longitude": 105.83380196379163,
          "from_longitude": 105.83369135986045
        }
      },
      {
        "id": 12617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83348849599865,
            21.022853923588663,
            105.83358214495398,
            21.022893745046638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83348849599865,
              21.022893745046638
            ],
            [
              105.83353537806461,
              21.022873809654666
            ],
            [
              105.83355061449541,
              21.022867331124928
            ],
            [
              105.83358214495398,
              21.022853923588663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12681",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022893745046638,
          "to_latitude": 21.022853923588663,
          "to_longitude": 105.83358214495398,
          "from_longitude": 105.83348849599865
        }
      },
      {
        "id": 12618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355061449541,
            21.022867331124928,
            105.83360737045928,
            21.022978053769855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355061449541,
              21.022867331124928
            ],
            [
              105.83360737045928,
              21.022978053769855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12682",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022867331124928,
          "to_latitude": 21.022978053769855,
          "to_longitude": 105.83360737045928,
          "from_longitude": 105.83355061449541
        }
      },
      {
        "id": 12619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465707550556,
            21.02460064578072,
            105.83469307869835,
            21.02468783248521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465707550556,
              21.02460064578072
            ],
            [
              105.83469307869835,
              21.02468783248521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.6_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.12683",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02460064578072,
          "to_latitude": 21.02468783248521,
          "to_longitude": 105.83469307869835,
          "from_longitude": 105.83465707550556
        }
      },
      {
        "id": 12620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83362455088763,
            21.022835891526125,
            105.83366984320443,
            21.022967943970674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83362455088763,
              21.022835891526125
            ],
            [
              105.83366406116845,
              21.022916811899115
            ],
            [
              105.83365843914598,
              21.022938404831635
            ],
            [
              105.83366984320443,
              21.022967943970674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12684",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022835891526125,
          "to_latitude": 21.022967943970674,
          "to_longitude": 105.83366984320443,
          "from_longitude": 105.83362455088763
        }
      },
      {
        "id": 12621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355789148237,
            21.02308380703804,
            105.83363607915285,
            21.02311937263289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355789148237,
              21.02311937263289
            ],
            [
              105.83363607915285,
              21.02308380703804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12685",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02311937263289,
          "to_latitude": 21.02308380703804,
          "to_longitude": 105.83363607915285,
          "from_longitude": 105.83355789148237
        }
      },
      {
        "id": 12622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83381259815673,
            21.022742094833067,
            105.83391029696584,
            21.022951015672668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83381259815673,
              21.022742094833067
            ],
            [
              105.83381283634228,
              21.022742544374132
            ],
            [
              105.83385149376036,
              21.0228152233518
            ],
            [
              105.83384326833632,
              21.02281894431567
            ],
            [
              105.83386152135516,
              21.022857408941988
            ],
            [
              105.83384223437218,
              21.022865160129136
            ],
            [
              105.8338337849199,
              21.022868556127097
            ],
            [
              105.83383616900653,
              21.02287293320417
            ],
            [
              105.83387328614253,
              21.02294108012232
            ],
            [
              105.83390093035041,
              21.022929261384125
            ],
            [
              105.83391029696584,
              21.022951015672668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.12686",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022742094833067,
          "to_latitude": 21.022951015672668,
          "to_longitude": 105.83391029696584,
          "from_longitude": 105.83381259815673
        }
      },
      {
        "id": 12623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83384744021363,
            21.022688629970332,
            105.83395912334903,
            21.02273760028617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384744021363,
              21.02273760028617
            ],
            [
              105.8338921729744,
              21.02271798604446
            ],
            [
              105.83395647953564,
              21.02268978912483
            ],
            [
              105.83395912334903,
              21.022688629970332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12687",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02273760028617,
          "to_latitude": 21.022688629970332,
          "to_longitude": 105.83395912334903,
          "from_longitude": 105.83384744021363
        }
      },
      {
        "id": 12624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8338921729744,
            21.02271798604446,
            105.83397609529064,
            21.022932092055335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8338921729744,
              21.02271798604446
            ],
            [
              105.833946892987,
              21.022837078795252
            ],
            [
              105.83394460863879,
              21.02286516309928
            ],
            [
              105.83397609529064,
              21.022932092055335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12688",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02271798604446,
          "to_latitude": 21.022932092055335,
          "to_longitude": 105.83397609529064,
          "from_longitude": 105.8338921729744
        }
      },
      {
        "id": 12625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83394985157071,
            21.022673324865433,
            105.8340289334441,
            21.022825949825535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83395169173342,
              21.022680792102854
            ],
            [
              105.83395023021232,
              21.022675727666474
            ],
            [
              105.83394985157071,
              21.02267458513828
            ],
            [
              105.83395340774229,
              21.022673324865433
            ],
            [
              105.8339555600031,
              21.02267908771994
            ],
            [
              105.83395912334903,
              21.022688629970332
            ],
            [
              105.83396679689088,
              21.022709177721897
            ],
            [
              105.8340289334441,
              21.022825949825535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12689",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022680792102854,
          "to_latitude": 21.022825949825535,
          "to_longitude": 105.8340289334441,
          "from_longitude": 105.83395169173342
        }
      },
      {
        "id": 12626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83413917322282,
            21.02261229081235,
            105.83418590280361,
            21.022722500624884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413917322282,
              21.02261229081235
            ],
            [
              105.83418590280361,
              21.022722500624884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12690",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02261229081235,
          "to_latitude": 21.022722500624884,
          "to_longitude": 105.83418590280361,
          "from_longitude": 105.83413917322282
        }
      },
      {
        "id": 12627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83428627399196,
            21.022745474974304,
            105.83439215262989,
            21.02299007609085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439215262989,
              21.02299007609085
            ],
            [
              105.83438306654743,
              21.022972763412916
            ],
            [
              105.83437486062557,
              21.022949908751084
            ],
            [
              105.83436997700355,
              21.02293856563658
            ],
            [
              105.83434446946784,
              21.02289213770633
            ],
            [
              105.83432866447109,
              21.022857631699154
            ],
            [
              105.83429375417515,
              21.022762037731063
            ],
            [
              105.83428627399196,
              21.022745474974304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12691",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02299007609085,
          "to_latitude": 21.022745474974304,
          "to_longitude": 105.83428627399196,
          "from_longitude": 105.83439215262989
        }
      },
      {
        "id": 12628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83432866447109,
            21.022796224413852,
            105.83439615521152,
            21.022857631699154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83432866447109,
              21.022857631699154
            ],
            [
              105.83433468256835,
              21.022855026182953
            ],
            [
              105.83439615521152,
              21.022828405577222
            ],
            [
              105.83438220342045,
              21.022796224413852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12692",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022857631699154,
          "to_latitude": 21.022796224413852,
          "to_longitude": 105.83438220342045,
          "from_longitude": 105.83432866447109
        }
      },
      {
        "id": 12629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438270173403,
            21.022894057359817,
            105.83450844442613,
            21.022968747662915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438270173403,
              21.022968747662915
            ],
            [
              105.83438316356805,
              21.022968416623772
            ],
            [
              105.8343914200098,
              21.022962491615598
            ],
            [
              105.83443721332215,
              21.02293950210002
            ],
            [
              105.83448296517926,
              21.02292133870083
            ],
            [
              105.83448070125218,
              21.02290709693626
            ],
            [
              105.83450844442613,
              21.022894057359817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12693",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022968747662915,
          "to_latitude": 21.022894057359817,
          "to_longitude": 105.83450844442613,
          "from_longitude": 105.83438270173403
        }
      },
      {
        "id": 12630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441446830663,
            21.023043240560472,
            105.83446680623301,
            21.02318466889083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441446830663,
              21.023043240560472
            ],
            [
              105.8344301344103,
              21.02308130323149
            ],
            [
              105.83445803555024,
              21.023137087640063
            ],
            [
              105.8344625051949,
              21.0231485429758
            ],
            [
              105.83445416097383,
              21.023183119581056
            ],
            [
              105.83445702939886,
              21.02318359957119
            ],
            [
              105.83446680623301,
              21.02318466889083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12694",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023043240560472,
          "to_latitude": 21.02318466889083,
          "to_longitude": 105.83446680623301,
          "from_longitude": 105.83441446830663
        }
      },
      {
        "id": 12631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434917064903,
            21.0231485429758,
            105.8344625051949,
            21.023196216567573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83434917064903,
              21.023196216567573
            ],
            [
              105.8344625051949,
              21.0231485429758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12695",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023196216567573,
          "to_latitude": 21.0231485429758,
          "to_longitude": 105.8344625051949,
          "from_longitude": 105.83434917064903
        }
      },
      {
        "id": 12632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440091517033,
            21.023183119581056,
            105.83445416097383,
            21.023257981679762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440091517033,
              21.023257981679762
            ],
            [
              105.83443264599805,
              21.023238055340094
            ],
            [
              105.83444870753468,
              21.023228589333865
            ],
            [
              105.83445416097383,
              21.023183119581056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12696",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": 10.963,
          "from_latitude": 21.023257981679762,
          "to_latitude": 21.023183119581056,
          "to_longitude": 105.83445416097383,
          "from_longitude": 105.83440091517033
        }
      },
      {
        "id": 12633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83443520784633,
            21.023061239416545,
            105.83450183731746,
            21.02330399984489
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443520784633,
              21.023061239416545
            ],
            [
              105.83443999553992,
              21.023071073712355
            ],
            [
              105.8344645636131,
              21.02312153732286
            ],
            [
              105.83447211996443,
              21.0231401978852
            ],
            [
              105.83446830394493,
              21.0232008987971
            ],
            [
              105.83447433490231,
              21.02321944219829
            ],
            [
              105.83450183731746,
              21.02330399984489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12697",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023061239416545,
          "to_latitude": 21.02330399984489,
          "to_longitude": 105.83450183731746,
          "from_longitude": 105.83443520784633
        }
      },
      {
        "id": 12634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438995328984,
            21.02326005300022,
            105.83445591338234,
            21.02343187172011
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440292927942,
              21.02326511557549
            ],
            [
              105.83439718649731,
              21.02326005300022
            ],
            [
              105.83439399771652,
              21.023261990704473
            ],
            [
              105.83439476207913,
              21.023263990305004
            ],
            [
              105.83439661199371,
              21.02326883334783
            ],
            [
              105.83445591338234,
              21.023387887175943
            ],
            [
              105.83438995328984,
              21.02343187172011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12698",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 24.981,
          "from_latitude": 21.02326511557549,
          "to_latitude": 21.02343187172011,
          "to_longitude": 105.83438995328984,
          "from_longitude": 105.83440292927942
        }
      },
      {
        "id": 12635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484454575404,
            21.023359633844482,
            105.83491771959713,
            21.023457070191416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83484454575404,
              21.023359633844482
            ],
            [
              105.83485818615424,
              21.02344332372835
            ],
            [
              105.83491771959713,
              21.0234424480039
            ],
            [
              105.83491724646008,
              21.023455433264225
            ],
            [
              105.83491718823639,
              21.023457070191416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.12699",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023359633844482,
          "to_latitude": 21.023457070191416,
          "to_longitude": 105.83491718823639,
          "from_longitude": 105.83484454575404
        }
      },
      {
        "id": 12636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83451570675592,
            21.023349536495026,
            105.83466171697783,
            21.02336370691409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466171697783,
              21.02336370691409
            ],
            [
              105.8346598237874,
              21.023349536495026
            ],
            [
              105.83459304613814,
              21.02334997980659
            ],
            [
              105.83451570675592,
              21.02335835825239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.12700",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02336370691409,
          "to_latitude": 21.02335835825239,
          "to_longitude": 105.83451570675592,
          "from_longitude": 105.83466171697783
        }
      },
      {
        "id": 12637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569700191573,
            21.020651293190163,
            105.83606840760707,
            21.020747093732496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83606840760707,
              21.020747093732496
            ],
            [
              105.83606698719232,
              21.020743889753657
            ],
            [
              105.8360259807057,
              21.020651293190163
            ],
            [
              105.83589607979528,
              21.02066207210168
            ],
            [
              105.83585075654514,
              21.020665851721823
            ],
            [
              105.83570961606416,
              21.02069908838408
            ],
            [
              105.83569700191573,
              21.020677883953258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12701",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020747093732496,
          "to_latitude": 21.020677883953258,
          "to_longitude": 105.83569700191573,
          "from_longitude": 105.83606840760707
        }
      },
      {
        "id": 12638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83527579650246,
            21.02073217365236,
            105.83543053970676,
            21.02095385515944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543053970676,
              21.02095385515944
            ],
            [
              105.83542560205044,
              21.020937649381075
            ],
            [
              105.8353699160169,
              21.020754874616966
            ],
            [
              105.83528302589154,
              21.02075690364829
            ],
            [
              105.83527579650246,
              21.02073217365236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.12702",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02095385515944,
          "to_latitude": 21.02073217365236,
          "to_longitude": 105.83527579650246,
          "from_longitude": 105.83543053970676
        }
      },
      {
        "id": 12639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353650884979,
            21.020561579502303,
            105.83547732080646,
            21.020655729615576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353650884979,
              21.020561579502303
            ],
            [
              105.83538847607406,
              21.020650027553895
            ],
            [
              105.83539877300802,
              21.020655729615576
            ],
            [
              105.83547732080646,
              21.02063262322468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.16_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12703",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020561579502303,
          "to_latitude": 21.02063262322468,
          "to_longitude": 105.83547732080646,
          "from_longitude": 105.8353650884979
        }
      },
      {
        "id": 12640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83545771938859,
            21.02053051700576,
            105.83548897887299,
            21.02062225837091
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83545771938859,
              21.02053051700576
            ],
            [
              105.83548897887299,
              21.02062225837091
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.16_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12704",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02053051700576,
          "to_latitude": 21.02062225837091,
          "to_longitude": 105.83548897887299,
          "from_longitude": 105.83545771938859
        }
      },
      {
        "id": 12641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83523622445006,
            21.02040773844254,
            105.83527095782883,
            21.020498168475616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523622445006,
              21.02040773844254
            ],
            [
              105.83527095782883,
              21.020498168475616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.15_Ngách 59, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12705",
          "diaChiLapD": "Ngách 59, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02040773844254,
          "to_latitude": 21.020498168475616,
          "to_longitude": 105.83527095782883,
          "from_longitude": 105.83523622445006
        }
      },
      {
        "id": 12642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83560821536528,
            21.020276735961314,
            105.83562646407962,
            21.020385704278684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562646407962,
              21.020385704278684
            ],
            [
              105.83560821536528,
              21.020276735961314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.17_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12706",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020385704278684,
          "to_latitude": 21.020276735961314,
          "to_longitude": 105.83560821536528,
          "from_longitude": 105.83562646407962
        }
      },
      {
        "id": 12643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575511111007,
            21.02037082344432,
            105.8357895446763,
            21.020468781217534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8357682510656,
              21.02037082344432
            ],
            [
              105.8357895446763,
              21.02045979565977
            ],
            [
              105.83575511111007,
              21.020468781217534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.17_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12707",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02037082344432,
          "to_latitude": 21.020468781217534,
          "to_longitude": 105.83575511111007,
          "from_longitude": 105.8357682510656
        }
      },
      {
        "id": 12644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83581339668332,
            21.02035486474733,
            105.83585196228589,
            21.020459514243083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83581339668332,
              21.02035486474733
            ],
            [
              105.83581485593722,
              21.020358825439462
            ],
            [
              105.83585196228589,
              21.020459514243083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.17_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12708",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02035486474733,
          "to_latitude": 21.020459514243083,
          "to_longitude": 105.83585196228589,
          "from_longitude": 105.83581339668332
        }
      },
      {
        "id": 12645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8355880076389,
            21.01992374150283,
            105.83576125541752,
            21.019992451559435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355880076389,
              21.019992451559435
            ],
            [
              105.83574078965094,
              21.01992374150283
            ],
            [
              105.83576125541752,
              21.01996015829245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.10_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12709",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019992451559435,
          "to_latitude": 21.01996015829245,
          "to_longitude": 105.83576125541752,
          "from_longitude": 105.8355880076389
        }
      },
      {
        "id": 12646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83563255489953,
            21.02011653786559,
            105.83575422083877,
            21.020155746404154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83563255489953,
              21.020135845329484
            ],
            [
              105.8356770776873,
              21.02011653786559
            ],
            [
              105.83569628153457,
              21.020155746404154
            ],
            [
              105.83575422083877,
              21.020135056103882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.10_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12710",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020135845329484,
          "to_latitude": 21.020135056103882,
          "to_longitude": 105.83575422083877,
          "from_longitude": 105.83563255489953
        }
      },
      {
        "id": 12647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509399207054,
            21.019955198074964,
            105.83533547200031,
            21.02002582320136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83533547200031,
              21.02002582320136
            ],
            [
              105.83532963691522,
              21.020025339728498
            ],
            [
              105.83533071919179,
              21.02001568075553
            ],
            [
              105.83524268866611,
              21.020018239224452
            ],
            [
              105.83517884428686,
              21.020024675663578
            ],
            [
              105.83510661494611,
              21.020025499965904
            ],
            [
              105.83509399207054,
              21.019955198074964
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.12_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12711",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02002582320136,
          "to_latitude": 21.019955198074964,
          "to_longitude": 105.83509399207054,
          "from_longitude": 105.83533547200031
        }
      },
      {
        "id": 12648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83532322228331,
            21.019898778352495,
            105.83534007776878,
            21.02001568075553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83533071919179,
              21.02001568075553
            ],
            [
              105.83532322228331,
              21.01997055004865
            ],
            [
              105.83534007776878,
              21.019968813471245
            ],
            [
              105.83532481857107,
              21.019898778352495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.12_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12712",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02001568075553,
          "to_latitude": 21.019898778352495,
          "to_longitude": 105.83532481857107,
          "from_longitude": 105.83533071919179
        }
      },
      {
        "id": 12649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83518779511996,
            21.020179563537063,
            105.83521780162312,
            21.02029612990354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83521780162312,
              21.02029612990354
            ],
            [
              105.83521389850793,
              21.02027204743963
            ],
            [
              105.83520651726813,
              21.020242326968194
            ],
            [
              105.83518779511996,
              21.020179563537063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.14_Ngách 53, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12713",
          "diaChiLapD": "Ngách 53, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02029612990354,
          "to_latitude": 21.020179563537063,
          "to_longitude": 105.83518779511996,
          "from_longitude": 105.83521780162312
        }
      },
      {
        "id": 12650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83501354383469,
            21.020294459575553,
            105.8350911061479,
            21.02031537785536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350911061479,
              21.020294459575553
            ],
            [
              105.8350882726041,
              21.020295224065862
            ],
            [
              105.83501354383469,
              21.02031537785536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.14_Ngách 53, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12714",
          "diaChiLapD": "Ngách 53, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020294459575553,
          "to_latitude": 21.02031537785536,
          "to_longitude": 105.83501354383469,
          "from_longitude": 105.8350911061479
        }
      },
      {
        "id": 12651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473417653185,
            21.02041714864893,
            105.83477640399046,
            21.02061387052458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477640399046,
              21.02061387052458
            ],
            [
              105.83473417653185,
              21.02041714864893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_109.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12715",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": 22.218,
          "from_latitude": 21.02061387052458,
          "to_latitude": 21.02041714864893,
          "to_longitude": 105.83473417653185,
          "from_longitude": 105.83477640399046
        }
      },
      {
        "id": 12652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453917619956,
            21.020656809613186,
            105.83458445962863,
            21.02086572354294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83458445962863,
              21.02086572354294
            ],
            [
              105.83458134709647,
              21.02081930135717
            ],
            [
              105.8345707311486,
              21.020771313667243
            ],
            [
              105.83455854129889,
              21.020716216292826
            ],
            [
              105.83455509031134,
              21.020706809888154
            ],
            [
              105.83453917619956,
              21.020656809613186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12716",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02086572354294,
          "to_latitude": 21.020656809613186,
          "to_longitude": 105.83453917619956,
          "from_longitude": 105.83458445962863
        }
      },
      {
        "id": 12653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8341180779527,
            21.02070923357486,
            105.83430609325055,
            21.02074843250522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430609325055,
              21.02070923357486
            ],
            [
              105.83420835057078,
              21.020728278739583
            ],
            [
              105.83419616792803,
              21.020730652547407
            ],
            [
              105.8341180779527,
              21.02074843250522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.2_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12719",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02070923357486,
          "to_latitude": 21.02074843250522,
          "to_longitude": 105.8341180779527,
          "from_longitude": 105.83430609325055
        }
      },
      {
        "id": 12654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340468301491,
            21.020595452352058,
            105.83411459962876,
            21.020743099765767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411459962876,
              21.020743099765767
            ],
            [
              105.83409199869503,
              21.020689410952333
            ],
            [
              105.8340843401159,
              21.020672227635764
            ],
            [
              105.8340468301491,
              21.020595452352058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.4_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12720",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020743099765767,
          "to_latitude": 21.020595452352058,
          "to_longitude": 105.8340468301491,
          "from_longitude": 105.83411459962876
        }
      },
      {
        "id": 12655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420835057078,
            21.020728278739583,
            105.83424026429527,
            21.02086304854679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420835057078,
              21.020728278739583
            ],
            [
              105.83420938592204,
              21.020732650685922
            ],
            [
              105.83424026429527,
              21.02086304854679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.2_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12721",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020728278739583,
          "to_latitude": 21.02086304854679,
          "to_longitude": 105.83424026429527,
          "from_longitude": 105.83420835057078
        }
      },
      {
        "id": 12656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83422030666236,
            21.020440845796582,
            105.83429640785975,
            21.020690681889963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83429640785975,
              21.020690681889963
            ],
            [
              105.83429635788612,
              21.020690517748285
            ],
            [
              105.83422030666236,
              21.020440845796582
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.3_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12722",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020690681889963,
          "to_latitude": 21.020440845796582,
          "to_longitude": 105.83422030666236,
          "from_longitude": 105.83429640785975
        }
      },
      {
        "id": 12657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445187407268,
            21.020238132908474,
            105.83454683042193,
            21.02046042017695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83454683042193,
              21.02046042017695
            ],
            [
              105.83454652382993,
              21.02045390130649
            ],
            [
              105.83453779839724,
              21.02043187925451
            ],
            [
              105.83452596448346,
              21.020402012596602
            ],
            [
              105.8344944541453,
              21.020318564832543
            ],
            [
              105.83445187407268,
              21.020238132908474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12724",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": 26.599,
          "from_latitude": 21.02046042017695,
          "to_latitude": 21.020238132908474,
          "to_longitude": 105.83445187407268,
          "from_longitude": 105.83454683042193
        }
      },
      {
        "id": 12658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435430872339,
            21.020179608485602,
            105.83445187407268,
            21.02027068124145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435430872339,
              21.02027068124145
            ],
            [
              105.83439286547541,
              21.020258162652937
            ],
            [
              105.83445187407268,
              21.020238132908474
            ],
            [
              105.83442806947224,
              21.020179608485602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12725",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": 17.7,
          "from_latitude": 21.02027068124145,
          "to_latitude": 21.020179608485602,
          "to_longitude": 105.83442806947224,
          "from_longitude": 105.83435430872339
        }
      },
      {
        "id": 12659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83432983359347,
            21.020074355027614,
            105.83441935231953,
            21.02019838637659
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440434080305,
              21.020074355027614
            ],
            [
              105.83441321208801,
              21.02014593628193
            ],
            [
              105.83441935231953,
              21.0201652120983
            ],
            [
              105.83432983359347,
              21.02019838637659
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.8_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12727",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": 20.212,
          "from_latitude": 21.020074355027614,
          "to_latitude": 21.02019838637659,
          "to_longitude": 105.83432983359347,
          "from_longitude": 105.83440434080305
        }
      },
      {
        "id": 12660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83432639713327,
            21.019927906257028,
            105.83440532514183,
            21.020074355027614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83432639713327,
              21.019946371215735
            ],
            [
              105.83439345235377,
              21.019927906257028
            ],
            [
              105.83440450729155,
              21.01997115452837
            ],
            [
              105.83440532514183,
              21.020014927081878
            ],
            [
              105.83440127867776,
              21.020027295812017
            ],
            [
              105.83440434080305,
              21.020074355027614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.8_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12728",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": 23.688,
          "from_latitude": 21.019946371215735,
          "to_latitude": 21.020074355027614,
          "to_longitude": 105.83440434080305,
          "from_longitude": 105.83432639713327
        }
      },
      {
        "id": 12661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83394336790938,
            21.020256615808876,
            105.83421851798465,
            21.020317716004133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394336790938,
              21.020317716004133
            ],
            [
              105.83394762332655,
              21.020316446885097
            ],
            [
              105.83400768525054,
              21.02030253810769
            ],
            [
              105.83421851798465,
              21.020256615808876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.8_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12729",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020317716004133,
          "to_latitude": 21.020256615808876,
          "to_longitude": 105.83421851798465,
          "from_longitude": 105.83394336790938
        }
      },
      {
        "id": 12662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83390574002203,
            21.020114874641465,
            105.83407854705878,
            21.020118426075328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83407854705878,
              21.020118426075328
            ],
            [
              105.83390574002203,
              21.020114874641465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.9_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12730",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020118426075328,
          "to_latitude": 21.020114874641465,
          "to_longitude": 105.83390574002203,
          "from_longitude": 105.83407854705878
        }
      },
      {
        "id": 12663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83407427105782,
            21.020146260025438,
            105.83407532926401,
            21.020277365957387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83407532926401,
              21.020277365957387
            ],
            [
              105.83407528851092,
              21.020272287327618
            ],
            [
              105.83407427105782,
              21.020146260025438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.9_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12731",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020277365957387,
          "to_latitude": 21.020146260025438,
          "to_longitude": 105.83407427105782,
          "from_longitude": 105.83407532926401
        }
      },
      {
        "id": 12664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398955768303,
            21.020446400408456,
            105.8340274640378,
            21.020528733109188
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398955768303,
              21.020446400408456
            ],
            [
              105.83402336742445,
              21.020521369338294
            ],
            [
              105.83402490851623,
              21.020524140099496
            ],
            [
              105.8340274640378,
              21.020528733109188
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12732",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020446400408456,
          "to_latitude": 21.020528733109188,
          "to_longitude": 105.8340274640378,
          "from_longitude": 105.83398955768303
        }
      },
      {
        "id": 12665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340274640378,
            21.020528733109188,
            105.83409194011722,
            21.020657780330072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8340274640378,
              21.020528733109188
            ],
            [
              105.83404618879025,
              21.020563504845413
            ],
            [
              105.83406535485241,
              21.020598924736596
            ],
            [
              105.83408696357617,
              21.02064752694571
            ],
            [
              105.83409194011722,
              21.020657780330072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12733",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020528733109188,
          "to_latitude": 21.020657780330072,
          "to_longitude": 105.83409194011722,
          "from_longitude": 105.8340274640378
        }
      },
      {
        "id": 12666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402224508148,
            21.02047606250451,
            105.83412140353842,
            21.02053145030704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83402224508148,
              21.02053145030704
            ],
            [
              105.8340274640378,
              21.020528733109188
            ],
            [
              105.83402817384037,
              21.020528363823047
            ],
            [
              105.83408147708751,
              21.020495955803757
            ],
            [
              105.83412140353842,
              21.02047606250451
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12734",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02053145030704,
          "to_latitude": 21.02047606250451,
          "to_longitude": 105.83412140353842,
          "from_longitude": 105.83402224508148
        }
      },
      {
        "id": 12667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83409332514316,
            21.02040524109598,
            105.83412140353842,
            21.02047606250451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83412140353842,
              21.02047606250451
            ],
            [
              105.83409332514316,
              21.02040524109598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12735",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02047606250451,
          "to_latitude": 21.02040524109598,
          "to_longitude": 105.83409332514316,
          "from_longitude": 105.83412140353842
        }
      },
      {
        "id": 12668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83408147708751,
            21.020495955803757,
            105.8341154821433,
            21.020559333583172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83408147708751,
              21.020495955803757
            ],
            [
              105.8341154821433,
              21.020559333583172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12736",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020495955803757,
          "to_latitude": 21.020559333583172,
          "to_longitude": 105.8341154821433,
          "from_longitude": 105.83408147708751
        }
      },
      {
        "id": 12669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357546866742,
            21.020590307752613,
            105.83362222503912,
            21.020687191473893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83357546866742,
              21.020590307752613
            ],
            [
              105.83362222503912,
              21.020687191473893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12737",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020590307752613,
          "to_latitude": 21.020687191473893,
          "to_longitude": 105.83362222503912,
          "from_longitude": 105.83357546866742
        }
      },
      {
        "id": 12670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385153549717,
            21.020314052021938,
            105.83394284398345,
            21.02037140170333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394284398345,
              21.02037140170333
            ],
            [
              105.83393102886286,
              21.020314274698368
            ],
            [
              105.83385153549717,
              21.020314052021938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.7_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12738",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02037140170333,
          "to_latitude": 21.020314052021938,
          "to_longitude": 105.83385153549717,
          "from_longitude": 105.83394284398345
        }
      },
      {
        "id": 12671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353998293123,
            21.020294860898982,
            105.8336195010948,
            21.02036659764731
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83361669465201,
              21.020300084021635
            ],
            [
              105.83361700913305,
              21.02029643526085
            ],
            [
              105.8336170176966,
              21.020294860898982
            ],
            [
              105.8336195010948,
              21.020295135050425
            ],
            [
              105.83361926817943,
              21.020300131925303
            ],
            [
              105.83361895542366,
              21.020306859769157
            ],
            [
              105.83361412429093,
              21.020310123310995
            ],
            [
              105.83361293812258,
              21.020315995565742
            ],
            [
              105.83357674442914,
              21.020317089711867
            ],
            [
              105.83353998293123,
              21.02036659764731
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.11_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12739",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020300084021635,
          "to_latitude": 21.02036659764731,
          "to_longitude": 105.83353998293123,
          "from_longitude": 105.83361669465201
        }
      },
      {
        "id": 12672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83348551689811,
            21.020293668885905,
            105.83359687903045,
            21.020450389905115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83359687903045,
              21.020293668885905
            ],
            [
              105.83359465830506,
              21.020307248907134
            ],
            [
              105.83349363731587,
              21.020311048845443
            ],
            [
              105.83348551689811,
              21.020326024352688
            ],
            [
              105.83354161267248,
              21.020450389905115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.11.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12740",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020293668885905,
          "to_latitude": 21.020450389905115,
          "to_longitude": 105.83354161267248,
          "from_longitude": 105.83359687903045
        }
      },
      {
        "id": 12673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83361895542366,
            21.02030680717882,
            105.83383723163772,
            21.020309883600397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83361895542366,
              21.020306859769157
            ],
            [
              105.83362231431943,
              21.02030680717882
            ],
            [
              105.83374135557618,
              21.020309883600397
            ],
            [
              105.83383723163772,
              21.020308275571047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.11_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12741",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020306859769157,
          "to_latitude": 21.020308275571047,
          "to_longitude": 105.83383723163772,
          "from_longitude": 105.83361895542366
        }
      },
      {
        "id": 12674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83384079815,
            21.020133834101667,
            105.83384090988534,
            21.020297985718518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384079815,
              21.020297985718518
            ],
            [
              105.83384090988534,
              21.020133834101667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12742",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020297985718518,
          "to_latitude": 21.020133834101667,
          "to_longitude": 105.83384090988534,
          "from_longitude": 105.83384079815
        }
      },
      {
        "id": 12675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359557916572,
            21.019967105170345,
            105.83378199270258,
            21.020300039049953
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83378199270258,
              21.019967105170345
            ],
            [
              105.83365257611166,
              21.019972738546176
            ],
            [
              105.83366397550805,
              21.020089161602314
            ],
            [
              105.83359557916572,
              21.02009542090338
            ],
            [
              105.83359687903045,
              21.020293668885895
            ],
            [
              105.83361483959294,
              21.020294476864425
            ],
            [
              105.83361472913309,
              21.02029641030568
            ],
            [
              105.83361444436177,
              21.020300039049953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.11.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12743",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": 57.992,
          "from_latitude": 21.019967105170345,
          "to_latitude": 21.020300039049953,
          "to_longitude": 105.83361444436177,
          "from_longitude": 105.83378199270258
        }
      },
      {
        "id": 12676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83309394151722,
            21.020580813915608,
            105.83318981558375,
            21.02060896191875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318981558375,
              21.020580813915608
            ],
            [
              105.83318836153643,
              21.02058124103732
            ],
            [
              105.83309394151722,
              21.02060896191875
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.8_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12744",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020580813915608,
          "to_latitude": 21.02060896191875,
          "to_longitude": 105.83309394151722,
          "from_longitude": 105.83318981558375
        }
      },
      {
        "id": 12677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281295615753,
            21.02046519154138,
            105.83290000225027,
            21.020611976451743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281295615753,
              21.02046519154138
            ],
            [
              105.83286395930638,
              21.020563845788793
            ],
            [
              105.83287775530485,
              21.02058227807857
            ],
            [
              105.83290000225027,
              21.020611976451743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.11_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12745",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02046519154138,
          "to_latitude": 21.020611976451743,
          "to_longitude": 105.83290000225027,
          "from_longitude": 105.83281295615753
        }
      },
      {
        "id": 12678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249076259803,
            21.020562093913952,
            105.83256277836577,
            21.020639046144712
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83249076259803,
              21.020639046144712
            ],
            [
              105.83252388148811,
              21.020586583742837
            ],
            [
              105.83256277836577,
              21.020562093913952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.9_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12746",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020639046144712,
          "to_latitude": 21.020562093913952,
          "to_longitude": 105.83256277836577,
          "from_longitude": 105.83249076259803
        }
      },
      {
        "id": 12679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83255940934909,
            21.020508895766618,
            105.83270412610504,
            21.020562093913952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83255940934909,
              21.020556339703273
            ],
            [
              105.83256006468362,
              21.02055759468726
            ],
            [
              105.83256277836577,
              21.020562093913952
            ],
            [
              105.83270412610504,
              21.020508895766618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.9_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12747",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020556339703273,
          "to_latitude": 21.020508895766618,
          "to_longitude": 105.83270412610504,
          "from_longitude": 105.83255940934909
        }
      },
      {
        "id": 12680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83247259975103,
            21.020619655195947,
            105.83281715660917,
            21.020845651529218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83247259975103,
              21.020619655195947
            ],
            [
              105.83247323666829,
              21.02061984536881
            ],
            [
              105.83247982448783,
              21.020621797511062
            ],
            [
              105.83247456453998,
              21.020658551068852
            ],
            [
              105.83266955556462,
              21.02076801311264
            ],
            [
              105.83281715660917,
              21.020845651529218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.7_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12748",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020619655195947,
          "to_latitude": 21.020845651529218,
          "to_longitude": 105.83281715660917,
          "from_longitude": 105.83247259975103
        }
      },
      {
        "id": 12681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83204146069401,
            21.020482640154686,
            105.83210663367223,
            21.020491722049737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83210663367223,
              21.020482640154686
            ],
            [
              105.83210650390531,
              21.020482657950772
            ],
            [
              105.83204146069401,
              21.020491722049737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.8_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12749",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020482640154686,
          "to_latitude": 21.020491722049737,
          "to_longitude": 105.83204146069401,
          "from_longitude": 105.83210663367223
        }
      },
      {
        "id": 12682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83241030934657,
            21.020497477388115,
            105.83246559322679,
            21.020651841630748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83246447279025,
              21.020651841630748
            ],
            [
              105.83246190331036,
              21.020650791110853
            ],
            [
              105.83245641741446,
              21.020648577059355
            ],
            [
              105.83246559322679,
              21.020612113240166
            ],
            [
              105.83244362452204,
              21.02056199334794
            ],
            [
              105.83241030934657,
              21.020497477388115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12750",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020651841630748,
          "to_latitude": 21.020497477388115,
          "to_longitude": 105.83241030934657,
          "from_longitude": 105.83246447279025
        }
      },
      {
        "id": 12683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234779667299,
            21.020406986658223,
            105.83241030934657,
            21.020497477388115
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241030934657,
              21.020497477388115
            ],
            [
              105.83237285107195,
              21.020435573045802
            ],
            [
              105.83234779667299,
              21.020406986658223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12751",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020497477388115,
          "to_latitude": 21.020406986658223,
          "to_longitude": 105.83234779667299,
          "from_longitude": 105.83241030934657
        }
      },
      {
        "id": 12684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83226593399897,
            21.020497477388115,
            105.83241030934657,
            21.020620491294505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241030934657,
              21.020497477388115
            ],
            [
              105.83235756234858,
              21.020506598851835
            ],
            [
              105.83227243447983,
              21.020551417009994
            ],
            [
              105.83226597123804,
              21.02062009459677
            ],
            [
              105.83226593399897,
              21.020620491294505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12752",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020497477388115,
          "to_latitude": 21.020620491294505,
          "to_longitude": 105.83226593399897,
          "from_longitude": 105.83241030934657
        }
      },
      {
        "id": 12685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8321480419673,
            21.020612373650803,
            105.8323072971598,
            21.020623339091916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321480419673,
              21.020612373650803
            ],
            [
              105.83226593399897,
              21.020620491294505
            ],
            [
              105.8323072971598,
              21.020623339091916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.6_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12753",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020612373650803,
          "to_latitude": 21.020623339091916,
          "to_longitude": 105.8323072971598,
          "from_longitude": 105.8321480419673
        }
      },
      {
        "id": 12686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83241906839723,
            21.020658551068852,
            105.83247456453998,
            21.020882593806846
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241906839723,
              21.020882593806846
            ],
            [
              105.83243978018919,
              21.020799863669367
            ],
            [
              105.83247456453998,
              21.020658551068852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.7_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12754",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020882593806846,
          "to_latitude": 21.020658551068852,
          "to_longitude": 105.83247456453998,
          "from_longitude": 105.83241906839723
        }
      },
      {
        "id": 12687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83239864304018,
            21.02075273926973,
            105.83244139777022,
            21.020886089888403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83239864304018,
              21.020886089888403
            ],
            [
              105.83240752661978,
              21.02085000505044
            ],
            [
              105.83242861850769,
              21.02075273926973
            ],
            [
              105.83243173749752,
              21.020753310198323
            ],
            [
              105.83244139777022,
              21.020755073886427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.5_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12755",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020886089888403,
          "to_latitude": 21.020755073886427,
          "to_longitude": 105.83244139777022,
          "from_longitude": 105.83239864304018
        }
      },
      {
        "id": 12688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83242861850769,
            21.020650488730727,
            105.83244968173665,
            21.02075273926973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83242861850769,
              21.02075273926973
            ],
            [
              105.83243589821308,
              21.02073480354186
            ],
            [
              105.83243716071776,
              21.020729676983994
            ],
            [
              105.83244943451882,
              21.020652049809193
            ],
            [
              105.83244968173665,
              21.020650488730727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.5_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12756",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02075273926973,
          "to_latitude": 21.020650488730727,
          "to_longitude": 105.83244968173665,
          "from_longitude": 105.83242861850769
        }
      },
      {
        "id": 12689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234271904145,
            21.020617666029565,
            105.83244968173665,
            21.020650488730727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244968173665,
              21.020650488730727
            ],
            [
              105.83244620111125,
              21.020649409250428
            ],
            [
              105.83238103763037,
              21.020629189726364
            ],
            [
              105.83234271904145,
              21.020617666029565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.5_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12757",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020650488730727,
          "to_latitude": 21.020617666029565,
          "to_longitude": 105.83234271904145,
          "from_longitude": 105.83244968173665
        }
      },
      {
        "id": 12690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188352025284,
            21.02081957940752,
            105.83240626517208,
            21.020896912957735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83240626517208,
              21.020896912957735
            ],
            [
              105.8323938134661,
              21.020894841380557
            ],
            [
              105.83228815581538,
              21.020881223847756
            ],
            [
              105.83228793727828,
              21.020837343564413
            ],
            [
              105.8321794012617,
              21.02083755757431
            ],
            [
              105.83218012370246,
              21.02085593194776
            ],
            [
              105.83199820557556,
              21.02085560974998
            ],
            [
              105.83199558246312,
              21.02081957940752
            ],
            [
              105.83188352025284,
              21.02082627846677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.4_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12758",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020896912957735,
          "to_latitude": 21.02082627846677,
          "to_longitude": 105.83188352025284,
          "from_longitude": 105.83240626517208
        }
      },
      {
        "id": 12691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83187383740291,
            21.020857294833075,
            105.83240421026083,
            21.020934568149688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83240421026083,
              21.020905205581883
            ],
            [
              105.83239008009319,
              21.020908884919272
            ],
            [
              105.83235037857416,
              21.020931411425753
            ],
            [
              105.83234908973343,
              21.020931447539066
            ],
            [
              105.8322364958341,
              21.020934568149688
            ],
            [
              105.8321880838247,
              21.020919514326664
            ],
            [
              105.83215963251884,
              21.02090390399804
            ],
            [
              105.83211761090622,
              21.020913294466638
            ],
            [
              105.8320938755755,
              21.020927744752814
            ],
            [
              105.83208215585489,
              21.020914564427137
            ],
            [
              105.8319743136726,
              21.02086333989208
            ],
            [
              105.8319271782025,
              21.020857294833075
            ],
            [
              105.83187383740291,
              21.020864836605732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.2_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12759",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020905205581883,
          "to_latitude": 21.020864836605732,
          "to_longitude": 105.83187383740291,
          "from_longitude": 105.83240421026083
        }
      },
      {
        "id": 12692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83253262608906,
            21.021018683005998,
            105.83258083577282,
            21.02109691330576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83253262608906,
              21.02109691330576
            ],
            [
              105.83258083577282,
              21.021018683005998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12760",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02109691330576,
          "to_latitude": 21.021018683005998,
          "to_longitude": 105.83258083577282,
          "from_longitude": 105.83253262608906
        }
      },
      {
        "id": 12693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83250198473323,
            21.020975856890395,
            105.83258097496815,
            21.021018683005998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83258083577282,
              21.021018683005998
            ],
            [
              105.83258097496815,
              21.02101845742181
            ],
            [
              105.83250198473323,
              21.020975856890395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12761",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021018683005998,
          "to_latitude": 21.020975856890395,
          "to_longitude": 105.83250198473323,
          "from_longitude": 105.83258083577282
        }
      },
      {
        "id": 12694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240293422827,
            21.020922105735856,
            105.83250903063305,
            21.020975856890395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83240293422827,
              21.020922105735856
            ],
            [
              105.83250198473323,
              21.020975856890395
            ],
            [
              105.83250441532748,
              21.02097354899776
            ],
            [
              105.83250903063305,
              21.020968828738585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_94.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12762",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020922105735856,
          "to_latitude": 21.020968828738585,
          "to_longitude": 105.83250903063305,
          "from_longitude": 105.83240293422827
        }
      },
      {
        "id": 12695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318885549704,
            21.02102286391993,
            105.83229664062236,
            21.02127066196612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318885549704,
              21.02127066196612
            ],
            [
              105.83188891774945,
              21.021270162515158
            ],
            [
              105.83189598428204,
              21.021260430032843
            ],
            [
              105.8319515481231,
              21.021256143435917
            ],
            [
              105.83204003871663,
              21.021202854797767
            ],
            [
              105.83206005993358,
              21.02120505014565
            ],
            [
              105.83220368878058,
              21.02109715822427
            ],
            [
              105.83225923262347,
              21.021058603292193
            ],
            [
              105.83228490140587,
              21.02103407979601
            ],
            [
              105.83229664062236,
              21.02102286391993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.10_Ngõ 221/32, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12763",
          "diaChiLapD": "Ngõ 221/32, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02127066196612,
          "to_latitude": 21.02102286391993,
          "to_longitude": 105.83229664062236,
          "from_longitude": 105.8318885549704
        }
      },
      {
        "id": 12696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225815127771,
            21.02097271057141,
            105.83228490140587,
            21.02103407979601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83228490140587,
              21.02103407979601
            ],
            [
              105.83225819319537,
              21.02099923672719
            ],
            [
              105.83225815127771,
              21.02099168416965
            ],
            [
              105.8322796595437,
              21.02097271057141
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.10_Ngõ 221/32, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12764",
          "diaChiLapD": "Ngõ 221/32, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02103407979601,
          "to_latitude": 21.02097271057141,
          "to_longitude": 105.8322796595437,
          "from_longitude": 105.83228490140587
        }
      },
      {
        "id": 12697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202588415156,
            21.0213157015435,
            105.83218260530332,
            21.021361683936796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202588415156,
              21.0213157015435
            ],
            [
              105.83205847960139,
              21.02133250645639
            ],
            [
              105.83211731255543,
              21.02135774467038
            ],
            [
              105.83218260530332,
              21.021361683936796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.13_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12765",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0213157015435,
          "to_latitude": 21.021361683936796,
          "to_longitude": 105.83218260530332,
          "from_longitude": 105.83202588415156
        }
      },
      {
        "id": 12698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197792655928,
            21.0212906765532,
            105.83202588415156,
            21.021331695549204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197792655928,
              21.0212906765532
            ],
            [
              105.83202588415156,
              21.0213157015435
            ],
            [
              105.83201555398125,
              21.02133121527692
            ],
            [
              105.83201523438494,
              21.021331695549204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.13_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12766",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0212906765532,
          "to_latitude": 21.021331695549204,
          "to_longitude": 105.83201523438494,
          "from_longitude": 105.83197792655928
        }
      },
      {
        "id": 12699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83233010437507,
            21.02108729405408,
            105.83252094166689,
            21.021184496566093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83233010437507,
              21.021184496566093
            ],
            [
              105.83236785967325,
              21.021139228267938
            ],
            [
              105.83241117469872,
              21.02108729405408
            ],
            [
              105.83245881877087,
              21.021101590151368
            ],
            [
              105.83252094166689,
              21.02111245176133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12767",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021184496566093,
          "to_latitude": 21.02111245176133,
          "to_longitude": 105.83252094166689,
          "from_longitude": 105.83233010437507
        }
      },
      {
        "id": 12700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83220377836517,
            21.021184496566093,
            105.83233010437507,
            21.021362033763477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220377836517,
              21.021362033763477
            ],
            [
              105.83222440565932,
              21.02133043198526
            ],
            [
              105.8323292437675,
              21.021185600904705
            ],
            [
              105.83233010437507,
              21.021184496566093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12768",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021362033763477,
          "to_latitude": 21.021184496566093,
          "to_longitude": 105.83233010437507,
          "from_longitude": 105.83220377836517
        }
      },
      {
        "id": 12701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221954908409,
            21.02113025636471,
            105.83240591648067,
            21.021393947906198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221954908409,
              21.021393947906198
            ],
            [
              105.83222192637216,
              21.021390179764364
            ],
            [
              105.83225948726,
              21.021330667688304
            ],
            [
              105.83228674616284,
              21.021276321876936
            ],
            [
              105.83231887157847,
              21.02123426500285
            ],
            [
              105.83231927008332,
              21.02123378976326
            ],
            [
              105.83240591648067,
              21.02113025636471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.5_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12769",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021393947906198,
          "to_latitude": 21.02113025636471,
          "to_longitude": 105.83240591648067,
          "from_longitude": 105.83221954908409
        }
      },
      {
        "id": 12702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83242637440995,
            21.02111806568411,
            105.83257298913091,
            21.021137566772268
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83242637440995,
              21.02111806568411
            ],
            [
              105.83247329552715,
              21.021120484301658
            ],
            [
              105.83253520120064,
              21.021128951618255
            ],
            [
              105.83253757450487,
              21.021129492775184
            ],
            [
              105.83257298913091,
              21.021137566772268
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12770",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02111806568411,
          "to_latitude": 21.021137566772268,
          "to_longitude": 105.83257298913091,
          "from_longitude": 105.83242637440995
        }
      },
      {
        "id": 12703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83257298913091,
            21.02112608232688,
            105.83263134315222,
            21.021162654508064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83258238924928,
              21.02112608232688
            ],
            [
              105.83258143757496,
              21.021127244015602
            ],
            [
              105.83257298913091,
              21.021137566772268
            ],
            [
              105.83263134315222,
              21.021162654508064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12771",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02112608232688,
          "to_latitude": 21.021162654508064,
          "to_longitude": 105.83263134315222,
          "from_longitude": 105.83258238924928
        }
      },
      {
        "id": 12704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83253757450487,
            21.021014709181305,
            105.83260198189281,
            21.021129492775184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83253757450487,
              21.021129492775184
            ],
            [
              105.8325417246468,
              21.021120517895472
            ],
            [
              105.83254312197374,
              21.021117496996364
            ],
            [
              105.83255469503595,
              21.021092470712464
            ],
            [
              105.83260198189281,
              21.021014709181305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12772",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021129492775184,
          "to_latitude": 21.021014709181305,
          "to_longitude": 105.83260198189281,
          "from_longitude": 105.83253757450487
        }
      },
      {
        "id": 12705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83277248312845,
            21.02128794978876,
            105.83283756928921,
            21.021323284988753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83283756928921,
              21.021323284988753
            ],
            [
              105.83283712061925,
              21.021323041508623
            ],
            [
              105.83277248312845,
              21.02128794978876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.2_Ngách 221/65, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12773",
          "diaChiLapD": "Ngách 221/65, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021323284988753,
          "to_latitude": 21.02128794978876,
          "to_longitude": 105.83277248312845,
          "from_longitude": 105.83283756928921
        }
      },
      {
        "id": 12706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314363951119,
            21.020785472623636,
            105.83321013440141,
            21.020820158794457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314363951119,
              21.020820158794457
            ],
            [
              105.83315297697114,
              21.020810566880716
            ],
            [
              105.83321013440141,
              21.020785472623636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.5_Ngách 221/60, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12774",
          "diaChiLapD": "Ngách 221/60, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020820158794457,
          "to_latitude": 21.020785472623636,
          "to_longitude": 105.83321013440141,
          "from_longitude": 105.83314363951119
        }
      },
      {
        "id": 12707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312774578744,
            21.020837463839953,
            105.83325551471158,
            21.02088715389071
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83312774578744,
              21.020837463839953
            ],
            [
              105.8331285653669,
              21.02083780666377
            ],
            [
              105.83325551471158,
              21.02088715389071
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.5_Ngách 221/60, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12775",
          "diaChiLapD": "Ngách 221/60, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020837463839953,
          "to_latitude": 21.02088715389071,
          "to_longitude": 105.83325551471158,
          "from_longitude": 105.83312774578744
        }
      },
      {
        "id": 12708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83279190495591,
            21.02080635397911,
            105.83298484062801,
            21.021116915884022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83279190495591,
              21.021116915884022
            ],
            [
              105.83279202219181,
              21.021116720213588
            ],
            [
              105.83298484062801,
              21.02080635397911
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.3_Ngách 221/58, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12776",
          "diaChiLapD": "Ngách 221/58, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021116915884022,
          "to_latitude": 21.02080635397911,
          "to_longitude": 105.83298484062801,
          "from_longitude": 105.83279190495591
        }
      },
      {
        "id": 12709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358214495398,
            21.022813310762533,
            105.83367765570502,
            21.022853923588663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358214495398,
              21.022853923588663
            ],
            [
              105.83362455088763,
              21.022835891526125
            ],
            [
              105.83367765570502,
              21.022813310762533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12777",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022853923588663,
          "to_latitude": 21.022813310762533,
          "to_longitude": 105.83367765570502,
          "from_longitude": 105.83358214495398
        }
      },
      {
        "id": 12710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83293144955591,
            21.020777120645914,
            105.83302465357256,
            21.020828152515282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83302465357256,
              21.020828152515282
            ],
            [
              105.83298484062801,
              21.02080635397911
            ],
            [
              105.83294793591364,
              21.020786147124618
            ],
            [
              105.83293144955591,
              21.020777120645914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.3_Ngách 221/58, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12778",
          "diaChiLapD": "Ngách 221/58, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020828152515282,
          "to_latitude": 21.020777120645914,
          "to_longitude": 105.83293144955591,
          "from_longitude": 105.83302465357256
        }
      },
      {
        "id": 12711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83284336388297,
            21.021152795610035,
            105.83297243556672,
            21.02123591956035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83284336388297,
              21.021152795610035
            ],
            [
              105.83297243556672,
              21.02123591956035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.4_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12779",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021152795610035,
          "to_latitude": 21.02123591956035,
          "to_longitude": 105.83297243556672,
          "from_longitude": 105.83284336388297
        }
      },
      {
        "id": 12712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832938463375,
            21.02123591956035,
            105.83297243556672,
            21.02132439325969
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83297243556672,
              21.02123591956035
            ],
            [
              105.832938463375,
              21.02132439325969
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.4_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12780",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02123591956035,
          "to_latitude": 21.02132439325969,
          "to_longitude": 105.832938463375,
          "from_longitude": 105.83297243556672
        }
      },
      {
        "id": 12713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83272610374479,
            21.021092074210962,
            105.83284336388297,
            21.021152795610035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272610374479,
              21.021092074210962
            ],
            [
              105.83279286608283,
              21.021125597486535
            ],
            [
              105.83279333201696,
              21.021125831850046
            ],
            [
              105.83284336388297,
              21.021152795610035
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.4_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12781",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021092074210962,
          "to_latitude": 21.021152795610035,
          "to_longitude": 105.83284336388297,
          "from_longitude": 105.83272610374479
        }
      },
      {
        "id": 12714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83310314658868,
            21.01992498898093,
            105.8331857715662,
            21.019990801571325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83310314658868,
              21.019990801571325
            ],
            [
              105.83310647118428,
              21.019974186733837
            ],
            [
              105.83311011682267,
              21.019955968003824
            ],
            [
              105.8331857715662,
              21.01992498898093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.5_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12782",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019990801571325,
          "to_latitude": 21.01992498898093,
          "to_longitude": 105.8331857715662,
          "from_longitude": 105.83310314658868
        }
      },
      {
        "id": 12715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83298634228397,
            21.01998890899667,
            105.83312883311635,
            21.02000073733355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83298634228397,
              21.02000073733355
            ],
            [
              105.83305526196877,
              21.01998890899667
            ],
            [
              105.83310314658868,
              21.019990801571325
            ],
            [
              105.83311195916299,
              21.019991150408938
            ],
            [
              105.83312860894453,
              21.019991686666756
            ],
            [
              105.83312883311635,
              21.019991692794587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.5_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12783",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02000073733355,
          "to_latitude": 21.019991692794587,
          "to_longitude": 105.83312883311635,
          "from_longitude": 105.83298634228397
        }
      },
      {
        "id": 12716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8331216859476,
            21.01994100224328,
            105.83319563018847,
            21.020152737488484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83319056646654,
              21.01994100224328
            ],
            [
              105.83312537355097,
              21.01996271533318
            ],
            [
              105.8331216859476,
              21.019968358675918
            ],
            [
              105.83313227204626,
              21.019999121229667
            ],
            [
              105.83314326749834,
              21.020049888197356
            ],
            [
              105.83317904697775,
              21.020116919161893
            ],
            [
              105.83319563018847,
              21.020152737488484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.6_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12784",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01994100224328,
          "to_latitude": 21.020152737488484,
          "to_longitude": 105.83319563018847,
          "from_longitude": 105.83319056646654
        }
      },
      {
        "id": 12717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83318990590602,
            21.02013546909078,
            105.83331841107987,
            21.020252689281513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83318990590602,
              21.020155575458578
            ],
            [
              105.8331942012558,
              21.02015370190563
            ],
            [
              105.83319563018847,
              21.020152737488484
            ],
            [
              105.83319898620213,
              21.0201589189752
            ],
            [
              105.83328314239073,
              21.02013546909078
            ],
            [
              105.83331841107987,
              21.020252689281513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.6_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12785",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020155575458578,
          "to_latitude": 21.020252689281513,
          "to_longitude": 105.83331841107987,
          "from_longitude": 105.83318990590602
        }
      },
      {
        "id": 12718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83266435011873,
            21.020327154750547,
            105.8326894311757,
            21.020395268913013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326894311757,
              21.020395268913013
            ],
            [
              105.83266435011873,
              21.02033122662926
            ],
            [
              105.8326747119441,
              21.020327154750547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.10_Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12786",
          "diaChiLapD": "Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020395268913013,
          "to_latitude": 21.020327154750547,
          "to_longitude": 105.8326747119441,
          "from_longitude": 105.8326894311757
        }
      },
      {
        "id": 12719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83267090593499,
            21.02026618098278,
            105.83284403351931,
            21.020327154750547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83267090593499,
              21.020320140876567
            ],
            [
              105.8326733804386,
              21.020324703594973
            ],
            [
              105.8326747119441,
              21.020327154750547
            ],
            [
              105.832701424983,
              21.020314713032196
            ],
            [
              105.8327132256825,
              21.020306096307824
            ],
            [
              105.83284403351931,
              21.02026618098278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.10_Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12787",
          "diaChiLapD": "Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020320140876567,
          "to_latitude": 21.02026618098278,
          "to_longitude": 105.83284403351931,
          "from_longitude": 105.83267090593499
        }
      },
      {
        "id": 12720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83303465500465,
            21.020249919828014,
            105.83326483727191,
            21.020284084638853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83303465500465,
              21.020249919828014
            ],
            [
              105.83307675180669,
              21.020269173628137
            ],
            [
              105.83318432769434,
              21.020284084638853
            ],
            [
              105.83320585223565,
              21.02028331533493
            ],
            [
              105.83322533650629,
              21.0202809988638
            ],
            [
              105.83326483727191,
              21.020267756483083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.9_Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12788",
          "diaChiLapD": "Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020249919828014,
          "to_latitude": 21.020267756483083,
          "to_longitude": 105.83326483727191,
          "from_longitude": 105.83303465500465
        }
      },
      {
        "id": 12721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249047626576,
            21.021657960505262,
            105.83254517654412,
            21.02171330519318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83249047626576,
              21.021657960505262
            ],
            [
              105.83249124632512,
              21.02165873983174
            ],
            [
              105.83254517654412,
              21.02171330519318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.9_Ngõ 203/43, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12789",
          "diaChiLapD": "Ngõ 203/43, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021657960505262,
          "to_latitude": 21.02171330519318,
          "to_longitude": 105.83254517654412,
          "from_longitude": 105.83249047626576
        }
      },
      {
        "id": 12722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252312092512,
            21.0216174193724,
            105.83257025778605,
            21.02167096662399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83252312092512,
              21.0216174193724
            ],
            [
              105.83252353332819,
              21.02161788793373
            ],
            [
              105.83257025778605,
              21.02167096662399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.9_Ngõ 203/43, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12790",
          "diaChiLapD": "Ngõ 203/43, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0216174193724,
          "to_latitude": 21.02167096662399,
          "to_longitude": 105.83257025778605,
          "from_longitude": 105.83252312092512
        }
      },
      {
        "id": 12723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8324385621394,
            21.02185673295151,
            105.83291649098699,
            21.02196329142714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8324385621394,
              21.021861885347995
            ],
            [
              105.83244151875829,
              21.021860236041796
            ],
            [
              105.83244787240794,
              21.02185673295151
            ],
            [
              105.8324976555419,
              21.021956163707777
            ],
            [
              105.83250182296113,
              21.02196329142714
            ],
            [
              105.83275377295485,
              21.02186427770943
            ],
            [
              105.83291649098699,
              21.021873736168434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.7_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12791",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021861885347995,
          "to_latitude": 21.021873736168434,
          "to_longitude": 105.83291649098699,
          "from_longitude": 105.8324385621394
        }
      },
      {
        "id": 12724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83236774296333,
            21.022015939388364,
            105.83251567915457,
            21.02206407842079
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83251567915457,
              21.022015939388364
            ],
            [
              105.83237895206157,
              21.02206407842079
            ],
            [
              105.83236774296333,
              21.022035462227272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.6_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12792",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022015939388364,
          "to_latitude": 21.022035462227272,
          "to_longitude": 105.83236774296333,
          "from_longitude": 105.83251567915457
        }
      },
      {
        "id": 12725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83212334571753,
            21.02176851786509,
            105.83234564300956,
            21.02192863313633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234564300956,
              21.02192863313633
            ],
            [
              105.83234516846474,
              21.02192806100792
            ],
            [
              105.83230507231535,
              21.02187954091189
            ],
            [
              105.83226208122443,
              21.021883813981514
            ],
            [
              105.8321909982736,
              21.02179566566981
            ],
            [
              105.83215446345099,
              21.02181457548654
            ],
            [
              105.83212334571753,
              21.02176851786509
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.5_Ngõ 181/22, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12793",
          "diaChiLapD": "Ngõ 181/22, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02192863313633,
          "to_latitude": 21.02176851786509,
          "to_longitude": 105.83212334571753,
          "from_longitude": 105.83234564300956
        }
      },
      {
        "id": 12726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83231268013127,
            21.021881838482585,
            105.8324319169456,
            21.0219541524641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83231268013127,
              21.0219541524641
            ],
            [
              105.83232457355807,
              21.021944363828325
            ],
            [
              105.8324319169456,
              21.021881838482585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.4_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12794",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0219541524641,
          "to_latitude": 21.021881838482585,
          "to_longitude": 105.8324319169456,
          "from_longitude": 105.83231268013127
        }
      },
      {
        "id": 12727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83222604291362,
            21.0219513197937,
            105.83231268013127,
            21.022015756296327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83222604291362,
              21.022015756296327
            ],
            [
              105.83227423382523,
              21.021982245710046
            ],
            [
              105.83229574906461,
              21.02196589052112
            ],
            [
              105.83231268013127,
              21.0219541524641
            ],
            [
              105.83231171474367,
              21.02195301822232
            ],
            [
              105.8323099439351,
              21.0219513197937
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.4_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12795",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022015756296327,
          "to_latitude": 21.0219513197937,
          "to_longitude": 105.8323099439351,
          "from_longitude": 105.83222604291362
        }
      },
      {
        "id": 12728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213938826049,
            21.022061313202045,
            105.83229912425706,
            21.022329889446578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83218970453113,
              21.022329889446578
            ],
            [
              105.83218944587277,
              21.0223288700687
            ],
            [
              105.8321888793104,
              21.02232662471548
            ],
            [
              105.83218551614257,
              21.02232088943837
            ],
            [
              105.83217513625448,
              21.02230318735075
            ],
            [
              105.83217583006069,
              21.02229681351517
            ],
            [
              105.83213938826049,
              21.02222798366621
            ],
            [
              105.83224351370446,
              21.02212968210746
            ],
            [
              105.83227129150583,
              21.022103458358803
            ],
            [
              105.83225901644403,
              21.022086261419375
            ],
            [
              105.83229912425706,
              21.022061313202045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.3_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12796",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022329889446578,
          "to_latitude": 21.022061313202045,
          "to_longitude": 105.83229912425706,
          "from_longitude": 105.83218970453113
        }
      },
      {
        "id": 12729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83224351370446,
            21.02212968210746,
            105.83226644513307,
            21.022162398491368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83224351370446,
              21.02212968210746
            ],
            [
              105.83224450178467,
              21.022131091721988
            ],
            [
              105.83226644513307,
              21.022162398491368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.3_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12797",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02212968210746,
          "to_latitude": 21.022162398491368,
          "to_longitude": 105.83226644513307,
          "from_longitude": 105.83224351370446
        }
      },
      {
        "id": 12730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83227129150583,
            21.022103458358803,
            105.83232067742551,
            21.02218401914042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227129150583,
              21.022103458358803
            ],
            [
              105.83229246585132,
              21.022129670186604
            ],
            [
              105.83230250826212,
              21.022147923982367
            ],
            [
              105.83232067742551,
              21.02218401914042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.3_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12798",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022103458358803,
          "to_latitude": 21.02218401914042,
          "to_longitude": 105.83232067742551,
          "from_longitude": 105.83227129150583
        }
      },
      {
        "id": 12731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83228006976326,
            21.02221914878756,
            105.83240565070157,
            21.022288763352762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83228006976326,
              21.022288763352762
            ],
            [
              105.83231927843134,
              21.022266980814617
            ],
            [
              105.83240565070157,
              21.02221914878756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.4_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12799",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022288763352762,
          "to_latitude": 21.02221914878756,
          "to_longitude": 105.83240565070157,
          "from_longitude": 105.83228006976326
        }
      },
      {
        "id": 12732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240565070157,
            21.02221914878756,
            105.83244771521566,
            21.022296546975536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83240565070157,
              21.02221914878756
            ],
            [
              105.83240746411495,
              21.022221762875915
            ],
            [
              105.83244771521566,
              21.022279803070905
            ],
            [
              105.83241893916026,
              21.022296546975536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.4_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12800",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02221914878756,
          "to_latitude": 21.022296546975536,
          "to_longitude": 105.83241893916026,
          "from_longitude": 105.83240565070157
        }
      },
      {
        "id": 12733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320645667919,
            21.022288763352762,
            105.83228006976326,
            21.022399663365896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8320645667919,
              21.022399663365896
            ],
            [
              105.832172932232,
              21.022343897421404
            ],
            [
              105.83228006976326,
              21.022288763352762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.4_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12801",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022399663365896,
          "to_latitude": 21.022288763352762,
          "to_longitude": 105.83228006976326,
          "from_longitude": 105.8320645667919
        }
      },
      {
        "id": 12734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8320695976005,
            21.022366963261565,
            105.83237722189233,
            21.022470848055956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8320695976005,
              21.022470848055956
            ],
            [
              105.83207120585296,
              21.022470468963004
            ],
            [
              105.83208016073122,
              21.02246835225464
            ],
            [
              105.83213061548295,
              21.02244647770795
            ],
            [
              105.8321898113994,
              21.022419169032222
            ],
            [
              105.83222364590127,
              21.022411665688978
            ],
            [
              105.83229041177265,
              21.022379533753586
            ],
            [
              105.83231576424973,
              21.022366963261565
            ],
            [
              105.83232698818081,
              21.02237485309711
            ],
            [
              105.83237722189233,
              21.02244469927443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.2_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12802",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022470848055956,
          "to_latitude": 21.02244469927443,
          "to_longitude": 105.83237722189233,
          "from_longitude": 105.8320695976005
        }
      },
      {
        "id": 12735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213061548295,
            21.02244647770795,
            105.83217388000145,
            21.02254101335636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83213061548295,
              21.02244647770795
            ],
            [
              105.83213166620394,
              21.022448773982834
            ],
            [
              105.83217388000145,
              21.02254101335636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.2_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12803",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02244647770795,
          "to_latitude": 21.02254101335636,
          "to_longitude": 105.83217388000145,
          "from_longitude": 105.83213061548295
        }
      },
      {
        "id": 12736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83209323074475,
            21.022525827313988,
            105.83217972969254,
            21.022604085050954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217388000145,
              21.02254101335636
            ],
            [
              105.83217972969254,
              21.022560231541366
            ],
            [
              105.83217206616008,
              21.0225797479719
            ],
            [
              105.832120990129,
              21.022604085050954
            ],
            [
              105.83209323074475,
              21.022525827313988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.2_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12804",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02254101335636,
          "to_latitude": 21.022525827313988,
          "to_longitude": 105.83209323074475,
          "from_longitude": 105.83217388000145
        }
      },
      {
        "id": 12737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83217388000145,
            21.022526523173713,
            105.83221075024511,
            21.02254101335636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83217388000145,
              21.02254101335636
            ],
            [
              105.83221075024511,
              21.022526523173713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.2_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12805",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02254101335636,
          "to_latitude": 21.022526523173713,
          "to_longitude": 105.83221075024511,
          "from_longitude": 105.83217388000145
        }
      },
      {
        "id": 12738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319213014067,
            21.022581449808822,
            105.83198471916396,
            21.02273034842283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319213014067,
              21.022581449808822
            ],
            [
              105.83198471916396,
              21.022723625043895
            ],
            [
              105.83197115938275,
              21.02272871149385
            ],
            [
              105.83196648457196,
              21.02273034842283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12806",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022581449808822,
          "to_latitude": 21.02273034842283,
          "to_longitude": 105.83196648457196,
          "from_longitude": 105.8319213014067
        }
      },
      {
        "id": 12739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83198471916396,
            21.022723625043895,
            105.83205551879702,
            21.02288235004399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83198471916396,
              21.022723625043895
            ],
            [
              105.83205551879702,
              21.02288235004399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_84.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12807",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022723625043895,
          "to_latitude": 21.02288235004399,
          "to_longitude": 105.83205551879702,
          "from_longitude": 105.83198471916396
        }
      },
      {
        "id": 12740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83206227625179,
            21.0229089593917,
            105.832166556063,
            21.023134423264295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83206227625179,
              21.0229089593917
            ],
            [
              105.83211721930957,
              21.023027752709673
            ],
            [
              105.832166556063,
              21.023134423264295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12808",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0229089593917,
          "to_latitude": 21.023134423264295,
          "to_longitude": 105.832166556063,
          "from_longitude": 105.83206227625179
        }
      },
      {
        "id": 12741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832166556063,
            21.023134423264295,
            105.8322189984024,
            21.02324754659376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832166556063,
              21.023134423264295
            ],
            [
              105.8322084460594,
              21.023224784590642
            ],
            [
              105.8322189984024,
              21.02324754659376
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12809",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023134423264295,
          "to_latitude": 21.02324754659376,
          "to_longitude": 105.8322189984024,
          "from_longitude": 105.832166556063
        }
      },
      {
        "id": 12742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221718997326,
            21.023163111219922,
            105.83247079935764,
            21.02326813134572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221718997326,
              21.02326264380286
            ],
            [
              105.83222096085707,
              21.023261412321308
            ],
            [
              105.83223204848528,
              21.02326813134572
            ],
            [
              105.8323118424507,
              21.023232451908854
            ],
            [
              105.83230562189644,
              21.023215405090472
            ],
            [
              105.83235370235147,
              21.023191911772535
            ],
            [
              105.83241264588713,
              21.023163111219922
            ],
            [
              105.83242336444886,
              21.023188950584228
            ],
            [
              105.83247079935764,
              21.023164759491433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12810",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02326264380286,
          "to_latitude": 21.023164759491433,
          "to_longitude": 105.83247079935764,
          "from_longitude": 105.83221718997326
        }
      },
      {
        "id": 12743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83235370235147,
            21.023191911772535,
            105.8323754526001,
            21.023229643194313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83235370235147,
              21.023191911772535
            ],
            [
              105.83235538082774,
              21.023194823681976
            ],
            [
              105.8323754526001,
              21.023229643194313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12811",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023191911772535,
          "to_latitude": 21.023229643194313,
          "to_longitude": 105.8323754526001,
          "from_longitude": 105.83235370235147
        }
      },
      {
        "id": 12744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83223512268158,
            21.023277607382788,
            105.83228284923412,
            21.023373705407046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83223512268158,
              21.023277607382788
            ],
            [
              105.83224360003622,
              21.02330089076233
            ],
            [
              105.83228284923412,
              21.023373705407046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12812",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023277607382788,
          "to_latitude": 21.023373705407046,
          "to_longitude": 105.83228284923412,
          "from_longitude": 105.83223512268158
        }
      },
      {
        "id": 12745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83226929096512,
            21.023373705407046,
            105.83232075993935,
            21.023456844120865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83226929096512,
              21.02337907277709
            ],
            [
              105.83227123567596,
              21.023378302745954
            ],
            [
              105.83228284923412,
              21.023373705407046
            ],
            [
              105.83232075993935,
              21.023456844120865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12813",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02337907277709,
          "to_latitude": 21.023456844120865,
          "to_longitude": 105.83232075993935,
          "from_longitude": 105.83226929096512
        }
      },
      {
        "id": 12746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83251894849701,
            21.02315006811948,
            105.83267174880969,
            21.02332816620771
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83267174880969,
              21.02332816620771
            ],
            [
              105.8326714793511,
              21.0233274548831
            ],
            [
              105.8326633238657,
              21.02330584452863
            ],
            [
              105.83260893833689,
              21.023223104481044
            ],
            [
              105.8325858601817,
              21.023188782036563
            ],
            [
              105.8325598284072,
              21.02315006811948
            ],
            [
              105.83251894849701,
              21.023172875024205
            ],
            [
              105.8325371903003,
              21.023202095355295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12814",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02332816620771,
          "to_latitude": 21.023202095355295,
          "to_longitude": 105.8325371903003,
          "from_longitude": 105.83267174880969
        }
      },
      {
        "id": 12747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8325858601817,
            21.023126562543133,
            105.83268736467768,
            21.023188782036563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8325858601817,
              21.023188782036563
            ],
            [
              105.83266814631362,
              21.023126562543133
            ],
            [
              105.83268736467768,
              21.023147144157154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12815",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023188782036563,
          "to_latitude": 21.023147144157154,
          "to_longitude": 105.83268736467768,
          "from_longitude": 105.8325858601817
        }
      },
      {
        "id": 12748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326633238657,
            21.02322175742336,
            105.83281452162717,
            21.02330584452863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326633238657,
              21.02330584452863
            ],
            [
              105.83281452162717,
              21.02322175742336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12816",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02330584452863,
          "to_latitude": 21.02322175742336,
          "to_longitude": 105.83281452162717,
          "from_longitude": 105.8326633238657
        }
      },
      {
        "id": 12749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832741127351,
            21.023089330202026,
            105.83283077363764,
            21.023238597036162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83283077363764,
              21.023238597036162
            ],
            [
              105.83282989774743,
              21.023237124553763
            ],
            [
              105.83282956517915,
              21.023216738607978
            ],
            [
              105.832741127351,
              21.023089330202026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12817",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023238597036162,
          "to_latitude": 21.023089330202026,
          "to_longitude": 105.832741127351,
          "from_longitude": 105.83283077363764
        }
      },
      {
        "id": 12750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263928290124,
            21.02295333178024,
            105.832741127351,
            21.023089330202026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832741127351,
              21.023089330202026
            ],
            [
              105.83263928290124,
              21.02295333178024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12818",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023089330202026,
          "to_latitude": 21.02295333178024,
          "to_longitude": 105.83263928290124,
          "from_longitude": 105.832741127351
        }
      },
      {
        "id": 12751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832741127351,
            21.022954306490693,
            105.83288976777327,
            21.023089330202026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832741127351,
              21.023089330202026
            ],
            [
              105.83279174339496,
              21.02305300398511
            ],
            [
              105.83282825098813,
              21.023028114817524
            ],
            [
              105.832813917457,
              21.02300023744289
            ],
            [
              105.83288455308448,
              21.02295746440832
            ],
            [
              105.83288976777327,
              21.022954306490693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12819",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023089330202026,
          "to_latitude": 21.022954306490693,
          "to_longitude": 105.83288976777327,
          "from_longitude": 105.832741127351
        }
      },
      {
        "id": 12752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328679646992,
            21.02289435177933,
            105.83290259658969,
            21.02295746440832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83288455308448,
              21.02295746440832
            ],
            [
              105.8328679646992,
              21.022929940402054
            ],
            [
              105.83287165269833,
              21.022914656048194
            ],
            [
              105.83290259658969,
              21.02289435177933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12820",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02295746440832,
          "to_latitude": 21.02289435177933,
          "to_longitude": 105.83290259658969,
          "from_longitude": 105.83288455308448
        }
      },
      {
        "id": 12753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83284721915992,
            21.023125407121096,
            105.8329915371435,
            21.02320939656126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83284721915992,
              21.02320939656126
            ],
            [
              105.83290510836717,
              21.023177843460207
            ],
            [
              105.8329915371435,
              21.023125407121096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12821",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02320939656126,
          "to_latitude": 21.023125407121096,
          "to_longitude": 105.8329915371435,
          "from_longitude": 105.83284721915992
        }
      },
      {
        "id": 12754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329915371435,
            21.02307235101438,
            105.83307485424264,
            21.023142003640825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300375420832,
              21.023142003640825
            ],
            [
              105.833003447921,
              21.023141589657868
            ],
            [
              105.8329915371435,
              21.023125407121096
            ],
            [
              105.83307485424264,
              21.02307235101438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12822",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023142003640825,
          "to_latitude": 21.02307235101438,
          "to_longitude": 105.83307485424264,
          "from_longitude": 105.83300375420832
        }
      },
      {
        "id": 12755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281187319758,
            21.023101224388906,
            105.83290510836717,
            21.023177843460207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83290510836717,
              21.023177843460207
            ],
            [
              105.83285520112683,
              21.023101224388906
            ],
            [
              105.83281187319758,
              21.02313376113828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12823",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023177843460207,
          "to_latitude": 21.02313376113828,
          "to_longitude": 105.83281187319758,
          "from_longitude": 105.83290510836717
        }
      },
      {
        "id": 12756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329625230837,
            21.022824691594767,
            105.83313778744362,
            21.023092019529347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330936818245,
              21.023092019529347
            ],
            [
              105.83309288008029,
              21.023090733652534
            ],
            [
              105.83308609518667,
              21.0230798234237
            ],
            [
              105.83313778744362,
              21.02304589260424
            ],
            [
              105.83301092693816,
              21.022872783345484
            ],
            [
              105.83299944926475,
              21.02286979479113
            ],
            [
              105.8329625230837,
              21.022824691594767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12824",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023092019529347,
          "to_latitude": 21.022824691594767,
          "to_longitude": 105.8329625230837,
          "from_longitude": 105.8330936818245
        }
      },
      {
        "id": 12757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313778744362,
            21.02296356395052,
            105.83328800552194,
            21.02304589260424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83313778744362,
              21.02304589260424
            ],
            [
              105.83313940538407,
              21.02304500584174
            ],
            [
              105.83328800552194,
              21.02296356395052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12825",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02304589260424,
          "to_latitude": 21.02296356395052,
          "to_longitude": 105.83328800552194,
          "from_longitude": 105.83313778744362
        }
      },
      {
        "id": 12758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833228155072,
            21.02285508731531,
            105.83329303570193,
            21.022954210807804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833228155072,
              21.02285508731531
            ],
            [
              105.83329303570193,
              21.022954210807804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12826",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02285508731531,
          "to_latitude": 21.022954210807804,
          "to_longitude": 105.83329303570193,
          "from_longitude": 105.833228155072
        }
      },
      {
        "id": 12759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83306933082861,
            21.022741881228278,
            105.833228155072,
            21.02285508731531
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83306933082861,
              21.022769756451527
            ],
            [
              105.83311836500546,
              21.022741881228278
            ],
            [
              105.83315405731646,
              21.022778041290405
            ],
            [
              105.833228155072,
              21.02285508731531
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12827",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022769756451527,
          "to_latitude": 21.02285508731531,
          "to_longitude": 105.833228155072,
          "from_longitude": 105.83306933082861
        }
      },
      {
        "id": 12760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313195926172,
            21.02258020203437,
            105.83325615264363,
            21.022723443060475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325615264363,
              21.02258020203437
            ],
            [
              105.83322327600692,
              21.022630931001533
            ],
            [
              105.83320991672082,
              21.022644562873023
            ],
            [
              105.83318803184244,
              21.022672126288363
            ],
            [
              105.83315879420178,
              21.022702574487926
            ],
            [
              105.83314924962588,
              21.022713000190432
            ],
            [
              105.8331359828499,
              21.02271921884309
            ],
            [
              105.83313466644358,
              21.02271983677412
            ],
            [
              105.83313195926172,
              21.022723443060475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12828",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02258020203437,
          "to_latitude": 21.022723443060475,
          "to_longitude": 105.83313195926172,
          "from_longitude": 105.83325615264363
        }
      },
      {
        "id": 12761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83305604735597,
            21.0224703480551,
            105.83329289202489,
            21.02265073141514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83305604735597,
              21.02265073141514
            ],
            [
              105.83305961073145,
              21.022648515514387
            ],
            [
              105.83307708416721,
              21.022649034186077
            ],
            [
              105.83321468205708,
              21.022540291209644
            ],
            [
              105.83329289202489,
              21.0224703480551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12829",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02265073141514,
          "to_latitude": 21.0224703480551,
          "to_longitude": 105.83329289202489,
          "from_longitude": 105.83305604735597
        }
      },
      {
        "id": 12762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83329314243603,
            21.022354356961245,
            105.83341381378594,
            21.022470574440725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83329314243603,
              21.022470574440725
            ],
            [
              105.83329729812205,
              21.02246640737887
            ],
            [
              105.83335867344178,
              21.022407383555752
            ],
            [
              105.83341381378594,
              21.022354356961245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12830",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022470574440725,
          "to_latitude": 21.022354356961245,
          "to_longitude": 105.83341381378594,
          "from_longitude": 105.83329314243603
        }
      },
      {
        "id": 12763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335867344178,
            21.022407383555752,
            105.83344423835683,
            21.022485664869507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335867344178,
              21.022407383555752
            ],
            [
              105.83343208246325,
              21.022485664869507
            ],
            [
              105.83344423835683,
              21.022480181443118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12831",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022407383555752,
          "to_latitude": 21.022480181443118,
          "to_longitude": 105.83344423835683,
          "from_longitude": 105.83335867344178
        }
      },
      {
        "id": 12764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300395605296,
            21.02257731687798,
            105.8330714681229,
            21.022643841058397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330714681229,
              21.022643841058397
            ],
            [
              105.83303434654228,
              21.022611003712967
            ],
            [
              105.83300395605296,
              21.02257731687798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.12832",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022643841058397,
          "to_latitude": 21.02257731687798,
          "to_longitude": 105.83300395605296,
          "from_longitude": 105.8330714681229
        }
      },
      {
        "id": 12765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329476810967,
            21.022405996401094,
            105.83306861037656,
            21.022530388182172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329476810967,
              21.022530388182172
            ],
            [
              105.83295035473934,
              21.022528363608387
            ],
            [
              105.83295398825187,
              21.02252561265766
            ],
            [
              105.832954229656,
              21.02252542992737
            ],
            [
              105.83295628733539,
              21.02252387172404
            ],
            [
              105.83301905902105,
              21.02247634283717
            ],
            [
              105.83300677822581,
              21.02246436842586
            ],
            [
              105.83306861037656,
              21.022405996401094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12833",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022530388182172,
          "to_latitude": 21.022405996401094,
          "to_longitude": 105.83306861037656,
          "from_longitude": 105.8329476810967
        }
      },
      {
        "id": 12766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288833016157,
            21.022481477948496,
            105.83300737936348,
            21.02271039785536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83290686766932,
              21.022481477948496
            ],
            [
              105.83292275757672,
              21.022496527332446
            ],
            [
              105.8329476810967,
              21.022530388182172
            ],
            [
              105.83295730330337,
              21.022543460389894
            ],
            [
              105.83300737936348,
              21.022604260448944
            ],
            [
              105.8329403526398,
              21.02265730628941
            ],
            [
              105.83293762563542,
              21.022670505813416
            ],
            [
              105.83288833016157,
              21.02271039785536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12834",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022481477948496,
          "to_latitude": 21.02271039785536,
          "to_longitude": 105.83288833016157,
          "from_longitude": 105.83290686766932
        }
      },
      {
        "id": 12767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278799136397,
            21.022275946427413,
            105.83303507759209,
            21.022437800455503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278799136397,
              21.022366905287647
            ],
            [
              105.83282514676691,
              21.022401522780296
            ],
            [
              105.83286911357368,
              21.022437800455503
            ],
            [
              105.83303507759209,
              21.022275946427413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.9_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12835",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022366905287647,
          "to_latitude": 21.022275946427413,
          "to_longitude": 105.83303507759209,
          "from_longitude": 105.83278799136397
        }
      },
      {
        "id": 12768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832867754407,
            21.022353785061963,
            105.83296819740913,
            21.022447172642494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832867754407,
              21.022447172642494
            ],
            [
              105.83287117007339,
              21.022443996438472
            ],
            [
              105.832872747272,
              21.022442530008174
            ],
            [
              105.83287389859332,
              21.02244146037227
            ],
            [
              105.83296819740913,
              21.022353785061963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12836",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022447172642494,
          "to_latitude": 21.022353785061963,
          "to_longitude": 105.83296819740913,
          "from_longitude": 105.832867754407
        }
      },
      {
        "id": 12769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281825658551,
            21.02240593986517,
            105.83290686766932,
            21.022481477948496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281825658551,
              21.02240593986517
            ],
            [
              105.8328594448469,
              21.022440250377407
            ],
            [
              105.832867754407,
              21.022447172642494
            ],
            [
              105.83286862421477,
              21.022447897285126
            ],
            [
              105.83288108498739,
              21.022451749936987
            ],
            [
              105.83290463469474,
              21.022477975341452
            ],
            [
              105.83290686766932,
              21.022481477948496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12837",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02240593986517,
          "to_latitude": 21.022481477948496,
          "to_longitude": 105.83290686766932,
          "from_longitude": 105.83281825658551
        }
      },
      {
        "id": 12770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265507797142,
            21.022500560302632,
            105.83278945288487,
            21.02259915683158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265507797142,
              21.022500560302632
            ],
            [
              105.83265650306038,
              21.022501844033393
            ],
            [
              105.8326603316571,
              21.022505292767892
            ],
            [
              105.83276454491556,
              21.02259915683158
            ],
            [
              105.83278945288487,
              21.02257895349182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.5_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12838",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022500560302632,
          "to_latitude": 21.02257895349182,
          "to_longitude": 105.83278945288487,
          "from_longitude": 105.83265507797142
        }
      },
      {
        "id": 12771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83255231818333,
            21.02250965132615,
            105.83264288628408,
            21.02260139077438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83255231818333,
              21.02260139077438
            ],
            [
              105.832581280335,
              21.022578653946166
            ],
            [
              105.8326130035441,
              21.022544515746223
            ],
            [
              105.83264288628408,
              21.02250965132615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.5_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12839",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02260139077438,
          "to_latitude": 21.02250965132615,
          "to_longitude": 105.83264288628408,
          "from_longitude": 105.83255231818333
        }
      },
      {
        "id": 12772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83264288628408,
            21.02240096278068,
            105.83277829232506,
            21.022514958151223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83264908109085,
              21.022514958151223
            ],
            [
              105.83264429949348,
              21.022510874599146
            ],
            [
              105.83264288628408,
              21.02250965132615
            ],
            [
              105.83265507797142,
              21.022500560302632
            ],
            [
              105.83266522429007,
              21.02249299309538
            ],
            [
              105.83268726519755,
              21.022476363413052
            ],
            [
              105.83277829232506,
              21.02240096278068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.5_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12840",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022514958151223,
          "to_latitude": 21.02240096278068,
          "to_longitude": 105.83277829232506,
          "from_longitude": 105.83264908109085
        }
      },
      {
        "id": 12773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83247858591291,
            21.022472889498232,
            105.8326130035441,
            21.022544515746223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326130035441,
              21.022544515746223
            ],
            [
              105.83260990764826,
              21.02254048807006
            ],
            [
              105.83255794990127,
              21.022472889498232
            ],
            [
              105.83247858591291,
              21.02253083231647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.5_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12841",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022544515746223,
          "to_latitude": 21.02253083231647,
          "to_longitude": 105.83247858591291,
          "from_longitude": 105.8326130035441
        }
      },
      {
        "id": 12774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83258834688206,
            21.02268112360749,
            105.83275749670067,
            21.0227397811143
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83258834688206,
              21.02270214519878
            ],
            [
              105.83264221274781,
              21.02268112360749
            ],
            [
              105.83266413301637,
              21.022698890198274
            ],
            [
              105.83270284517329,
              21.0227397811143
            ],
            [
              105.83275749670067,
              21.022708144562714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.4_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12842",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02270214519878,
          "to_latitude": 21.022708144562714,
          "to_longitude": 105.83275749670067,
          "from_longitude": 105.83258834688206
        }
      },
      {
        "id": 12775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83255414030936,
            21.022602627401277,
            105.8326197786393,
            21.022752720013948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83255414030936,
              21.022602627401277
            ],
            [
              105.83255477910491,
              21.022602981047942
            ],
            [
              105.83256135915929,
              21.022624481703556
            ],
            [
              105.83258159589454,
              21.022675675841832
            ],
            [
              105.83258834688206,
              21.02270214519878
            ],
            [
              105.83259189495655,
              21.022716055547203
            ],
            [
              105.8326197786393,
              21.022752720013948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.4_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12843",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022602627401277,
          "to_latitude": 21.022752720013948,
          "to_longitude": 105.8326197786393,
          "from_longitude": 105.83255414030936
        }
      },
      {
        "id": 12776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326197786393,
            21.022745838503607,
            105.832837726074,
            21.022898499613213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326197786393,
              21.022752720013948
            ],
            [
              105.83263262233746,
              21.022770264590516
            ],
            [
              105.83267325167242,
              21.022745838503607
            ],
            [
              105.83274662767782,
              21.022853021643716
            ],
            [
              105.83278505273077,
              21.022898499613213
            ],
            [
              105.832837726074,
              21.02286246408422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.4_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12844",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022752720013948,
          "to_latitude": 21.02286246408422,
          "to_longitude": 105.832837726074,
          "from_longitude": 105.8326197786393
        }
      },
      {
        "id": 12777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83248591205965,
            21.022752720013948,
            105.8326197786393,
            21.022992316781274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326197786393,
              21.022752720013948
            ],
            [
              105.83261954467426,
              21.022752856642924
            ],
            [
              105.83253488408297,
              21.02280165227322
            ],
            [
              105.83253979859533,
              21.02282118302072
            ],
            [
              105.83253932232316,
              21.022821513222425
            ],
            [
              105.83248591205965,
              21.02285859187878
            ],
            [
              105.83251196890988,
              21.022903394321936
            ],
            [
              105.8325258714743,
              21.022911532966056
            ],
            [
              105.83255250388561,
              21.0229496279518
            ],
            [
              105.83252896902837,
              21.02296419564432
            ],
            [
              105.83254831194706,
              21.022992316781274
            ],
            [
              105.83257748964452,
              21.022975467919014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.4_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12845",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022752720013948,
          "to_latitude": 21.022975467919014,
          "to_longitude": 105.83257748964452,
          "from_longitude": 105.8326197786393
        }
      },
      {
        "id": 12778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83237891908846,
            21.02262931313413,
            105.83254381250084,
            21.022729629312746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83237891908846,
              21.022729629312746
            ],
            [
              105.8323926848622,
              21.022720756409807
            ],
            [
              105.83242329047728,
              21.022701028333184
            ],
            [
              105.83254381250084,
              21.02262931313413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.2_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12846",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022729629312746,
          "to_latitude": 21.02262931313413,
          "to_longitude": 105.83254381250084,
          "from_longitude": 105.83237891908846
        }
      },
      {
        "id": 12779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323926848622,
            21.022720756409807,
            105.8324243223576,
            21.02276369530162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323926848622,
              21.022720756409807
            ],
            [
              105.83239361828615,
              21.022722023581288
            ],
            [
              105.8324243223576,
              21.02276369530162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.2_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12847",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022720756409807,
          "to_latitude": 21.02276369530162,
          "to_longitude": 105.8324243223576,
          "from_longitude": 105.8323926848622
        }
      },
      {
        "id": 12780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832275847001,
            21.022719542607547,
            105.83237891908846,
            21.022798175402677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832275847001,
              21.022798175402677
            ],
            [
              105.83232812824546,
              21.022763406461284
            ],
            [
              105.83237891908846,
              21.022729629312746
            ],
            [
              105.8323710854514,
              21.02272023347319
            ],
            [
              105.8323705111605,
              21.022719542607547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.2_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12848",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022798175402677,
          "to_latitude": 21.022719542607547,
          "to_longitude": 105.8323705111605,
          "from_longitude": 105.832275847001
        }
      },
      {
        "id": 12781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83226334626983,
            21.022785195535914,
            105.83237749892864,
            21.02296334101186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83226617340077,
              21.022785195535914
            ],
            [
              105.83226681556987,
              21.022785983618007
            ],
            [
              105.83226879535836,
              21.022789108118324
            ],
            [
              105.83226334626983,
              21.022806537203877
            ],
            [
              105.83228909775265,
              21.022840186358653
            ],
            [
              105.8323121254481,
              21.022870813101658
            ],
            [
              105.8323128581127,
              21.022871786804874
            ],
            [
              105.83237749892864,
              21.02296334101186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.1_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12849",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022785195535914,
          "to_latitude": 21.02296334101186,
          "to_longitude": 105.83237749892864,
          "from_longitude": 105.83226617340077
        }
      },
      {
        "id": 12782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323121254481,
            21.022857257519806,
            105.83236952389963,
            21.022870813101658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323121254481,
              21.022870813101658
            ],
            [
              105.83233399677562,
              21.02286052944313
            ],
            [
              105.8323501215945,
              21.02286764380932
            ],
            [
              105.83236952389963,
              21.022857257519806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.1_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12850",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022870813101658,
          "to_latitude": 21.022857257519806,
          "to_longitude": 105.83236952389963,
          "from_longitude": 105.8323121254481
        }
      },
      {
        "id": 12783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83225038959858,
            21.02265372628789,
            105.83232812824546,
            21.022763406461284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83232812824546,
              21.022763406461284
            ],
            [
              105.83232495773386,
              21.022758933855552
            ],
            [
              105.83231959015647,
              21.022751360393926
            ],
            [
              105.83225059514749,
              21.022654016120292
            ],
            [
              105.83225038959858,
              21.02265372628789
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.2_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12851",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022763406461284,
          "to_latitude": 21.02265372628789,
          "to_longitude": 105.83225038959858,
          "from_longitude": 105.83232812824546
        }
      },
      {
        "id": 12784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213996534926,
            21.022644495500977,
            105.83225038959858,
            21.022686961376127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83225038959858,
              21.02265372628789
            ],
            [
              105.83216306542417,
              21.022686961376127
            ],
            [
              105.83213996534926,
              21.022644495500977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.2_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12852",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02265372628789,
          "to_latitude": 21.022644495500977,
          "to_longitude": 105.83213996534926,
          "from_longitude": 105.83225038959858
        }
      },
      {
        "id": 12785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83241288825457,
            21.022584183061042,
            105.83245880318927,
            21.022672275288784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83245794498448,
              21.022672275288784
            ],
            [
              105.83245519145605,
              21.022668229676093
            ],
            [
              105.83245880318927,
              21.02265237034916
            ],
            [
              105.83242687147032,
              21.02260494929779
            ],
            [
              105.83241677981154,
              21.022589962714704
            ],
            [
              105.83241288825457,
              21.022584183061042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.3_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12853",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022672275288784,
          "to_latitude": 21.022584183061042,
          "to_longitude": 105.83241288825457,
          "from_longitude": 105.83245794498448
        }
      },
      {
        "id": 12786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234474702365,
            21.022584183061042,
            105.83241288825457,
            21.02262452034275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241288825457,
              21.022584183061042
            ],
            [
              105.83240875665142,
              21.022586628438763
            ],
            [
              105.83234669824614,
              21.02262336550558
            ],
            [
              105.83234474702365,
              21.02262452034275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.3_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12854",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022584183061042,
          "to_latitude": 21.02262452034275,
          "to_longitude": 105.83234474702365,
          "from_longitude": 105.83241288825457
        }
      },
      {
        "id": 12787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83228427848596,
            21.02257159310723,
            105.83234474702365,
            21.02262452034275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234474702365,
              21.02262452034275
            ],
            [
              105.83231468277145,
              21.02257159310723
            ],
            [
              105.83228427848596,
              21.02258703618645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.3_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12855",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02262452034275,
          "to_latitude": 21.02258703618645,
          "to_longitude": 105.83228427848596,
          "from_longitude": 105.83234474702365
        }
      },
      {
        "id": 12788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83242687147032,
            21.02257818076804,
            105.83246555901829,
            21.02260494929779
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83242687147032,
              21.02260494929779
            ],
            [
              105.83246555901829,
              21.02257818076804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.3_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12856",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02260494929779,
          "to_latitude": 21.02257818076804,
          "to_longitude": 105.83246555901829,
          "from_longitude": 105.83242687147032
        }
      },
      {
        "id": 12789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83245317074368,
            21.02225907494508,
            105.83264793045254,
            21.02234064736205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83264793045254,
              21.02225907494508
            ],
            [
              105.8326428807826,
              21.02226232327279
            ],
            [
              105.83264203240299,
              21.02226285852184
            ],
            [
              105.83258547874293,
              21.022298575176805
            ],
            [
              105.83252520442146,
              21.02233590602262
            ],
            [
              105.83250102328385,
              21.02231063745806
            ],
            [
              105.83245317074368,
              21.02234064736205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.7_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12857",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225907494508,
          "to_latitude": 21.02234064736205,
          "to_longitude": 105.83245317074368,
          "from_longitude": 105.83264793045254
        }
      },
      {
        "id": 12790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326120318909,
            21.02221015412198,
            105.83264203240299,
            21.02226285852184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83264203240299,
              21.02226285852184
            ],
            [
              105.83264006031038,
              21.022255576446895
            ],
            [
              105.8326120318909,
              21.02221015412198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.7_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12858",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02226285852184,
          "to_latitude": 21.02221015412198,
          "to_longitude": 105.8326120318909,
          "from_longitude": 105.83264203240299
        }
      },
      {
        "id": 12791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244983812553,
            21.02221895149445,
            105.83253441662912,
            21.02222674721528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244983812553,
              21.02221895149445
            ],
            [
              105.83248213464894,
              21.02222674721528
            ],
            [
              105.83252432048275,
              21.02222608189388
            ],
            [
              105.83253441662912,
              21.02222542008951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.5_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12859",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02221895149445,
          "to_latitude": 21.02222542008951,
          "to_longitude": 105.83253441662912,
          "from_longitude": 105.83244983812553
        }
      },
      {
        "id": 12792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83253441662912,
            21.022198301856825,
            105.83260230139508,
            21.02222542008951
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83253441662912,
              21.02222542008951
            ],
            [
              105.83254939599303,
              21.022224276447133
            ],
            [
              105.83260230139508,
              21.0222066755675
            ],
            [
              105.83259968996776,
              21.02219859546173
            ],
            [
              105.83259951710515,
              21.022198301856825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.5_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12860",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02222542008951,
          "to_latitude": 21.022198301856825,
          "to_longitude": 105.83259951710515,
          "from_longitude": 105.83253441662912
        }
      },
      {
        "id": 12793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244684504595,
            21.022118689562816,
            105.83248037457052,
            21.02221895149445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244983812553,
              21.02221895149445
            ],
            [
              105.83244907975585,
              21.022212480894595
            ],
            [
              105.83244684504595,
              21.022193414832167
            ],
            [
              105.83247766925169,
              21.022190207456198
            ],
            [
              105.83248037457052,
              21.022118689562816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.5_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12861",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02221895149445,
          "to_latitude": 21.022118689562816,
          "to_longitude": 105.83248037457052,
          "from_longitude": 105.83244983812553
        }
      },
      {
        "id": 12794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83260140856459,
            21.02208792724248,
            105.83279833966398,
            21.022198166146392
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.83278920434597,
                21.022135664920118
              ],
              [
                105.83277001512413,
                21.02209900397165
              ],
              [
                105.83279833966398,
                21.02208792724248
              ]
            ],
            [
              [
                105.83278934337997,
                21.022135929787304
              ],
              [
                105.83278920434597,
                21.022135664920118
              ]
            ],
            [
              [
                105.83260140856459,
                21.022198166146392
              ],
              [
                105.83260299937722,
                21.022196899264458
              ],
              [
                105.83261015861864,
                21.022194508609584
              ],
              [
                105.83265296812156,
                21.022180215065944
              ],
              [
                105.83268114896316,
                21.02218361955007
              ],
              [
                105.83269746949892,
                21.022184067132525
              ],
              [
                105.83278920434597,
                21.022135664920118
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.6_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12862",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.83277001512413,
            21.02209900397165
          ],
          "to_latitude": [
            105.83260299937722,
            21.022196899264458
          ],
          "to_longitude": [
            105.83260140856459,
            21.022198166146392
          ],
          "from_longitude": [
            105.83278920434597,
            21.022135664920118
          ]
        }
      },
      {
        "id": 12795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278799136397,
            21.022193631121056,
            105.83297052361235,
            21.022366905287647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278799136397,
              21.022366905287647
            ],
            [
              105.83288428383715,
              21.022275497171403
            ],
            [
              105.83297052361235,
              21.022193631121056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.9_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12863",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022366905287647,
          "to_latitude": 21.022193631121056,
          "to_longitude": 105.83297052361235,
          "from_longitude": 105.83278799136397
        }
      },
      {
        "id": 12796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83369255832145,
            21.02269976336407,
            105.8338850742276,
            21.022780535586854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369255832145,
              21.022780535586854
            ],
            [
              105.83387967360292,
              21.02269976336407
            ],
            [
              105.833884665841,
              21.022709369062003
            ],
            [
              105.8338850742276,
              21.02271015196138
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12864",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022780535586854,
          "to_latitude": 21.02271015196138,
          "to_longitude": 105.8338850742276,
          "from_longitude": 105.83369255832145
        }
      },
      {
        "id": 12797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387967360292,
            21.02261690936535,
            105.83407555710322,
            21.02269976336407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387967360292,
              21.02269976336407
            ],
            [
              105.83391234525648,
              21.022685726948566
            ],
            [
              105.83407555710322,
              21.02261690936535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12865",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02269976336407,
          "to_latitude": 21.02261690936535,
          "to_longitude": 105.83407555710322,
          "from_longitude": 105.83387967360292
        }
      },
      {
        "id": 12798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83430230600457,
            21.022451206440326,
            105.8344711261744,
            21.022530335108847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430230600457,
              21.022530335108847
            ],
            [
              105.83435443476526,
              21.022503016098142
            ],
            [
              105.83436310772073,
              21.02249916556027
            ],
            [
              105.8344711261744,
              21.022451206440326
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12866",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022530335108847,
          "to_latitude": 21.022451206440326,
          "to_longitude": 105.8344711261744,
          "from_longitude": 105.83430230600457
        }
      },
      {
        "id": 12799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402374387802,
            21.022054325743632,
            105.83416892356104,
            21.022145221065298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83416892356104,
              21.022124684871855
            ],
            [
              105.8341102561109,
              21.022145221065298
            ],
            [
              105.83402374387802,
              21.022054325743632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12867",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022124684871855,
          "to_latitude": 21.022054325743632,
          "to_longitude": 105.83402374387802,
          "from_longitude": 105.83416892356104
        }
      },
      {
        "id": 12800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411321636706,
            21.022530335108847,
            105.8343072582353,
            21.022605080319526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411321636706,
              21.022605080319526
            ],
            [
              105.83416003548193,
              21.022588559254782
            ],
            [
              105.83415984193678,
              21.022583165247468
            ],
            [
              105.83416437161493,
              21.0225786169768
            ],
            [
              105.83416719357174,
              21.022578700684814
            ],
            [
              105.83417108830945,
              21.02257881526029
            ],
            [
              105.83417496067254,
              21.022582689163418
            ],
            [
              105.83428696152161,
              21.02253674558928
            ],
            [
              105.83430230600457,
              21.022530335108847
            ],
            [
              105.83430593325367,
              21.022535798982165
            ],
            [
              105.8343072582353,
              21.022538970927705
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12868",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022605080319526,
          "to_latitude": 21.022538970927705,
          "to_longitude": 105.8343072582353,
          "from_longitude": 105.83411321636706
        }
      },
      {
        "id": 12801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447191818256,
            21.022591192763773,
            105.83463565755048,
            21.022639495831335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447191818256,
              21.022639495831335
            ],
            [
              105.83463565755048,
              21.022591192763773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.12869",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022639495831335,
          "to_latitude": 21.022591192763773,
          "to_longitude": 105.83463565755048,
          "from_longitude": 105.83447191818256
        }
      },
      {
        "id": 12802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342530886608,
            21.02250796282006,
            105.8344100859453,
            21.022570189216776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8342530886608,
              21.022570189216776
            ],
            [
              105.83428446668516,
              21.022557935718005
            ],
            [
              105.83438129514799,
              21.022520122359143
            ],
            [
              105.8344100859453,
              21.02250796282006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12870",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022570189216776,
          "to_latitude": 21.02250796282006,
          "to_longitude": 105.8344100859453,
          "from_longitude": 105.8342530886608
        }
      },
      {
        "id": 12803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439958307991,
            21.022428290682047,
            105.83461269833315,
            21.02250796282006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439958307991,
              21.022487053228463
            ],
            [
              105.83440064048557,
              21.02248916158495
            ],
            [
              105.8344100859453,
              21.02250796282006
            ],
            [
              105.8344302060642,
              21.02249937200085
            ],
            [
              105.8344735134979,
              21.022480880987228
            ],
            [
              105.83455819250587,
              21.02244556945519
            ],
            [
              105.83461269833315,
              21.022428290682047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12871",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022487053228463,
          "to_latitude": 21.022428290682047,
          "to_longitude": 105.83461269833315,
          "from_longitude": 105.83439958307991
        }
      },
      {
        "id": 12804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83428446668516,
            21.022557935718005,
            105.834359830314,
            21.022743967055618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428446668516,
              21.022557935718005
            ],
            [
              105.83429480312881,
              21.022584552701865
            ],
            [
              105.83433567077596,
              21.022683575893605
            ],
            [
              105.834359830314,
              21.022743967055618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12872",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022557935718005,
          "to_latitude": 21.022743967055618,
          "to_longitude": 105.834359830314,
          "from_longitude": 105.83428446668516
        }
      },
      {
        "id": 12805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440704744426,
            21.022148530324678,
            105.83449626746456,
            21.022213354558364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440704744426,
              21.022199293727606
            ],
            [
              105.83445260611653,
              21.022148530324678
            ],
            [
              105.83449626746456,
              21.022213354558364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12873",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022199293727606,
          "to_latitude": 21.022213354558364,
          "to_longitude": 105.83449626746456,
          "from_longitude": 105.83440704744426
        }
      },
      {
        "id": 12806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444779964107,
            21.022339052948727,
            105.83451405659972,
            21.02237023124856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444779964107,
              21.02237023124856
            ],
            [
              105.83451405659972,
              21.022339052948727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12874",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02237023124856,
          "to_latitude": 21.022339052948727,
          "to_longitude": 105.83451405659972,
          "from_longitude": 105.83444779964107
        }
      },
      {
        "id": 12807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452281987076,
            21.02237797263795,
            105.83472522578703,
            21.02244061902679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452281987076,
              21.02244061902679
            ],
            [
              105.83458730522979,
              21.022408121651516
            ],
            [
              105.8346178778915,
              21.022396710236315
            ],
            [
              105.83463505683602,
              21.022390298853715
            ],
            [
              105.83470430545515,
              21.022380364076884
            ],
            [
              105.83472522578703,
              21.02237797263795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12875",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02244061902679,
          "to_latitude": 21.02237797263795,
          "to_longitude": 105.83472522578703,
          "from_longitude": 105.83452281987076
        }
      },
      {
        "id": 12808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83472522578703,
            21.02235954850864,
            105.83483549404562,
            21.02237797263795
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83472522578703,
              21.02237797263795
            ],
            [
              105.83483549404562,
              21.02235954850864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12876",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02237797263795,
          "to_latitude": 21.02235954850864,
          "to_longitude": 105.83483549404562,
          "from_longitude": 105.83472522578703
        }
      },
      {
        "id": 12809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83468569494497,
            21.022267472403907,
            105.83470430545515,
            21.022380364076884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83470430545515,
              21.022380364076884
            ],
            [
              105.83469843677055,
              21.022370923478878
            ],
            [
              105.83468647874355,
              21.022287172923416
            ],
            [
              105.83468569494497,
              21.022281681555825
            ],
            [
              105.83468580439484,
              21.022267472403907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12877",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022380364076884,
          "to_latitude": 21.022267472403907,
          "to_longitude": 105.83468580439484,
          "from_longitude": 105.83470430545515
        }
      },
      {
        "id": 12810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465401454244,
            21.02228504798409,
            105.83468647874355,
            21.022287172923416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83468647874355,
              21.022287172923416
            ],
            [
              105.83465401454244,
              21.02228504798409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12878",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022287172923416,
          "to_latitude": 21.02228504798409,
          "to_longitude": 105.83465401454244,
          "from_longitude": 105.83468647874355
        }
      },
      {
        "id": 12811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462246736653,
            21.022406513929305,
            105.83470436348733,
            21.022579774589477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346598753595,
              21.022579774589477
            ],
            [
              105.83462246736653,
              21.02242589345924
            ],
            [
              105.83470436348733,
              21.022406513929305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12879",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022579774589477,
          "to_latitude": 21.022406513929305,
          "to_longitude": 105.83470436348733,
          "from_longitude": 105.8346598753595
        }
      },
      {
        "id": 12812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83469900732727,
            21.022367769515238,
            105.8349768354114,
            21.022406513929305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83470818288896,
              21.022392232377218
            ],
            [
              105.83470560034107,
              21.022382448685345
            ],
            [
              105.83469900732727,
              21.02238355678068
            ],
            [
              105.83470134197063,
              21.022393564785567
            ],
            [
              105.83470436348733,
              21.022406513929305
            ],
            [
              105.83477185969856,
              21.022392834829876
            ],
            [
              105.8349768354114,
              21.022367769515238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12880",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022392232377218,
          "to_latitude": 21.022367769515238,
          "to_longitude": 105.8349768354114,
          "from_longitude": 105.83470818288896
        }
      },
      {
        "id": 12813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489534839417,
            21.02201345969928,
            105.83498979517711,
            21.02202921220137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83498979517711,
              21.02201345969928
            ],
            [
              105.83489534839417,
              21.02202921220137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_121.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12881",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02201345969928,
          "to_latitude": 21.02202921220137,
          "to_longitude": 105.83489534839417,
          "from_longitude": 105.83498979517711
        }
      },
      {
        "id": 12814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83481075879055,
            21.02168928778786,
            105.8350051372078,
            21.021735988409198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350051372078,
              21.02168928778786
            ],
            [
              105.83499922128063,
              21.021690709601607
            ],
            [
              105.83499432944316,
              21.021691884323726
            ],
            [
              105.83481075879055,
              21.021735988409198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120.2.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12882",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02168928778786,
          "to_latitude": 21.021735988409198,
          "to_longitude": 105.83481075879055,
          "from_longitude": 105.8350051372078
        }
      },
      {
        "id": 12815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83471229734242,
            21.021516218150474,
            105.83473678130323,
            21.021618935976917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83471498087158,
              21.021516218150474
            ],
            [
              105.83473678130323,
              21.021589690495727
            ],
            [
              105.83471229734242,
              21.021603382495112
            ],
            [
              105.8347183779901,
              21.021618935976917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12883",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021516218150474,
          "to_latitude": 21.021618935976917,
          "to_longitude": 105.8347183779901,
          "from_longitude": 105.83471498087158
        }
      },
      {
        "id": 12816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8347183779901,
            21.021618935976917,
            105.83498249507244,
            21.02189129034306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8347183779901,
              21.021618935976917
            ],
            [
              105.8347769233991,
              21.0217721353009
            ],
            [
              105.83480760977004,
              21.021845451118313
            ],
            [
              105.83481971375835,
              21.021869021853945
            ],
            [
              105.8348365556142,
              21.021886049863806
            ],
            [
              105.83484778604839,
              21.02189129034306
            ],
            [
              105.83498249507244,
              21.02186927417024
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12884",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021618935976917,
          "to_latitude": 21.02186927417024,
          "to_longitude": 105.83498249507244,
          "from_longitude": 105.8347183779901
        }
      },
      {
        "id": 12817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83451341656077,
            21.021896484691617,
            105.83489671322927,
            21.02198597257081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489509612778,
              21.021896484691617
            ],
            [
              105.83489608779146,
              21.02189974317179
            ],
            [
              105.83489671322927,
              21.021902362160667
            ],
            [
              105.83485427649873,
              21.02190562968086
            ],
            [
              105.8348117959206,
              21.021911043465686
            ],
            [
              105.83476055323978,
              21.021928478798483
            ],
            [
              105.83475527620871,
              21.021930274112812
            ],
            [
              105.83471190233105,
              21.021958568208944
            ],
            [
              105.83468036442808,
              21.021976979844823
            ],
            [
              105.83465565415055,
              21.02198597257081
            ],
            [
              105.83464149375673,
              21.021978871363835
            ],
            [
              105.83463215025914,
              21.02197752263573
            ],
            [
              105.83459826841823,
              21.02197768889353
            ],
            [
              105.83451341656077,
              21.021972906274005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12885",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021896484691617,
          "to_latitude": 21.021972906274005,
          "to_longitude": 105.83451341656077,
          "from_longitude": 105.83489509612778
        }
      },
      {
        "id": 12818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462582568676,
            21.021749297242117,
            105.83476055323978,
            21.021928478798483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83476055323978,
              21.021928478798483
            ],
            [
              105.83469428117273,
              21.021841809123266
            ],
            [
              105.8346876326546,
              21.021833114800735
            ],
            [
              105.83462582568676,
              21.021749297242117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12886",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021928478798483,
          "to_latitude": 21.021749297242117,
          "to_longitude": 105.83462582568676,
          "from_longitude": 105.83476055323978
        }
      },
      {
        "id": 12819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462817702402,
            21.021841809123266,
            105.83469428117273,
            21.021895037143643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83469428117273,
              21.021841809123266
            ],
            [
              105.83469193676136,
              21.021843696624355
            ],
            [
              105.83462817702402,
              21.021895037143643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.12887",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021841809123266,
          "to_latitude": 21.021895037143643,
          "to_longitude": 105.83462817702402,
          "from_longitude": 105.83469428117273
        }
      },
      {
        "id": 12820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83460524604953,
            21.021989482434567,
            105.83467214885937,
            21.022066941951902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465713974147,
              21.021989482434567
            ],
            [
              105.83467214885937,
              21.02204208028263
            ],
            [
              105.83460524604953,
              21.022066941951902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.12888",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021989482434567,
          "to_latitude": 21.022066941951902,
          "to_longitude": 105.83460524604953,
          "from_longitude": 105.83465713974147
        }
      },
      {
        "id": 12821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444950695213,
            21.021624419352957,
            105.83466170874178,
            21.021695692523128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444950695213,
              21.021695692523128
            ],
            [
              105.83448511824628,
              21.0216839240289
            ],
            [
              105.83462052725838,
              21.021635773539693
            ],
            [
              105.83462476598774,
              21.02163426603376
            ],
            [
              105.83466170874178,
              21.021624419352957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12889",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021695692523128,
          "to_latitude": 21.021624419352957,
          "to_longitude": 105.83466170874178,
          "from_longitude": 105.83444950695213
        }
      },
      {
        "id": 12822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466170874178,
            21.021614580814514,
            105.83474449375454,
            21.021720728502423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466170874178,
              21.021624419352957
            ],
            [
              105.83470288037817,
              21.021614580814514
            ],
            [
              105.83474449375454,
              21.021720728502423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12890",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021624419352957,
          "to_latitude": 21.021720728502423,
          "to_longitude": 105.83474449375454,
          "from_longitude": 105.83466170874178
        }
      },
      {
        "id": 12823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418301748951,
            21.02164957190554,
            105.8343963378051,
            21.02166118128988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343963378051,
              21.02164957190554
            ],
            [
              105.834396082188,
              21.02164962012715
            ],
            [
              105.83433497354541,
              21.02166118128988
            ],
            [
              105.83430329344334,
              21.02165472508699
            ],
            [
              105.83418301748951,
              21.021659016421275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12891",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02164957190554,
          "to_latitude": 21.021659016421275,
          "to_longitude": 105.83418301748951,
          "from_longitude": 105.8343963378051
        }
      },
      {
        "id": 12824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343210960484,
            21.02131951509956,
            105.83450068475418,
            21.021404257742898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343210960484,
              21.021404257742898
            ],
            [
              105.83442382175481,
              21.02136577596677
            ],
            [
              105.83450068475418,
              21.02131951509956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12892",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021404257742898,
          "to_latitude": 21.02131951509956,
          "to_longitude": 105.83450068475418,
          "from_longitude": 105.8343210960484
        }
      },
      {
        "id": 12825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421453919985,
            21.021404257742898,
            105.83433662058154,
            21.02155953789048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421453919985,
              21.02155953789048
            ],
            [
              105.83421648203259,
              21.021511231166244
            ],
            [
              105.83429463886776,
              21.02150539957646
            ],
            [
              105.83429919847768,
              21.021446588166096
            ],
            [
              105.83430400107726,
              21.02141018364848
            ],
            [
              105.8343210960484,
              21.021404257742898
            ],
            [
              105.83432583980273,
              21.02141225781305
            ],
            [
              105.83433217710456,
              21.0214229443836
            ],
            [
              105.83433662058154,
              21.02142089123648
            ],
            [
              105.83433575585165,
              21.021419177546097
            ],
            [
              105.83432999078586,
              21.021410713712736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12893",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02155953789048,
          "to_latitude": 21.021410713712736,
          "to_longitude": 105.83432999078586,
          "from_longitude": 105.83421453919985
        }
      },
      {
        "id": 12826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83443710923638,
            21.02137420018372,
            105.83448575652386,
            21.021465347919364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443710923638,
              21.02137420018372
            ],
            [
              105.8344394024445,
              21.021378497312156
            ],
            [
              105.83448575652386,
              21.021465347919364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12894",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02137420018372,
          "to_latitude": 21.021465347919364,
          "to_longitude": 105.83448575652386,
          "from_longitude": 105.83443710923638
        }
      },
      {
        "id": 12827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441845918513,
            21.021299488939047,
            105.83452680149941,
            21.021382103661118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441845918513,
              21.021382103661118
            ],
            [
              105.83442901096203,
              21.021377589066894
            ],
            [
              105.83443710923638,
              21.02137420018372
            ],
            [
              105.83445785686287,
              21.02136551777011
            ],
            [
              105.83448050859191,
              21.021352405634065
            ],
            [
              105.83448958253614,
              21.021343872617148
            ],
            [
              105.83450213733639,
              21.021331269755915
            ],
            [
              105.83451692172827,
              21.02130963184376
            ],
            [
              105.83452680149941,
              21.021299488939047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12895",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021382103661118,
          "to_latitude": 21.021299488939047,
          "to_longitude": 105.83452680149941,
          "from_longitude": 105.83441845918513
        }
      },
      {
        "id": 12828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83433187427737,
            21.021382103661118,
            105.83441845918513,
            21.021419566157856
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83433187427737,
              21.02141001260421
            ],
            [
              105.83433777978166,
              21.0214181289106
            ],
            [
              105.83433884017802,
              21.021419566157856
            ],
            [
              105.83437804731233,
              21.021398814647128
            ],
            [
              105.83441845918513,
              21.021382103661118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12896",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02141001260421,
          "to_latitude": 21.021382103661118,
          "to_longitude": 105.83441845918513,
          "from_longitude": 105.83433187427737
        }
      },
      {
        "id": 12829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83465819381593,
            21.02125515214362,
            105.83477708525878,
            21.021412072415444
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83465819381593,
              21.021271713190924
            ],
            [
              105.83473190514123,
              21.02125515214362
            ],
            [
              105.83477708525878,
              21.021412072415444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12897",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021271713190924,
          "to_latitude": 21.021412072415444,
          "to_longitude": 105.83477708525878,
          "from_longitude": 105.83465819381593
        }
      },
      {
        "id": 12830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454711165456,
            21.021271713190924,
            105.83470767496536,
            21.021427337536053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83454711165456,
              21.021272528310707
            ],
            [
              105.8345500652867,
              21.02127933677293
            ],
            [
              105.83455226545226,
              21.021283854743018
            ],
            [
              105.83465819381593,
              21.021271713190924
            ],
            [
              105.83470767496536,
              21.02141085499406
            ],
            [
              105.83467738566011,
              21.021427337536053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12898",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021272528310707,
          "to_latitude": 21.021427337536053,
          "to_longitude": 105.83467738566011,
          "from_longitude": 105.83454711165456
        }
      },
      {
        "id": 12831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484697618302,
            21.021238456837107,
            105.83497443661453,
            21.021331153683473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496066932501,
              21.021238456837107
            ],
            [
              105.83497443661453,
              21.02130471298779
            ],
            [
              105.83484697618302,
              21.021331153683473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12899",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021238456837107,
          "to_latitude": 21.021331153683473,
          "to_longitude": 105.83484697618302,
          "from_longitude": 105.83496066932501
        }
      },
      {
        "id": 12832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83476446243522,
            21.02120953797033,
            105.83498534398463,
            21.02124777379841
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83476446243522,
              21.02124777379841
            ],
            [
              105.83477984449517,
              21.0212445117326
            ],
            [
              105.83491253088864,
              21.02120953797033
            ],
            [
              105.83496066932501,
              21.021238456837107
            ],
            [
              105.83498166302473,
              21.021235968365854
            ],
            [
              105.83498534398463,
              21.0212353586843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12900",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02124777379841,
          "to_latitude": 21.0212353586843,
          "to_longitude": 105.83498534398463,
          "from_longitude": 105.83476446243522
        }
      },
      {
        "id": 12833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498304591643,
            21.02091784519919,
            105.83503716030052,
            21.0210865646173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83503716030052,
              21.0210865646173
            ],
            [
              105.83499675099868,
              21.02099884769072
            ],
            [
              105.83498304591643,
              21.02091784519919
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.12901",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0210865646173,
          "to_latitude": 21.02091784519919,
          "to_longitude": 105.83498304591643,
          "from_longitude": 105.83503716030052
        }
      },
      {
        "id": 12834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515859402888,
            21.02084910968554,
            105.83523029840508,
            21.021029260582125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523029840508,
              21.021029260582125
            ],
            [
              105.83522792889742,
              21.02102370293876
            ],
            [
              105.83522722626401,
              21.021015687567072
            ],
            [
              105.83522397446136,
              21.020978666838044
            ],
            [
              105.83521334241355,
              21.02092955476056
            ],
            [
              105.83520416581591,
              21.020877848701993
            ],
            [
              105.83516663672556,
              21.020889120951853
            ],
            [
              105.83515859402888,
              21.02084910968554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12902",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021029260582125,
          "to_latitude": 21.02084910968554,
          "to_longitude": 105.83515859402888,
          "from_longitude": 105.83523029840508
        }
      },
      {
        "id": 12835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83522722626401,
            21.020858939221352,
            105.83532876132108,
            21.021015687567072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83522722626401,
              21.021015687567072
            ],
            [
              105.83523165523326,
              21.021014142983464
            ],
            [
              105.83532876132108,
              21.020980282064592
            ],
            [
              105.83529439429327,
              21.020858939221352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_119.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.12903",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021015687567072,
          "to_latitude": 21.020858939221352,
          "to_longitude": 105.83529439429327,
          "from_longitude": 105.83522722626401
        }
      },
      {
        "id": 12836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83458265113697,
            21.021027316469763,
            105.83476461496906,
            21.021067255254685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459647344004,
              21.021065137105808
            ],
            [
              105.83458412197123,
              21.021067255254685
            ],
            [
              105.83458265113697,
              21.021060518097777
            ],
            [
              105.8345950428059,
              21.02105836362281
            ],
            [
              105.83460154329029,
              21.02105723340638
            ],
            [
              105.83475549034434,
              21.021027316469763
            ],
            [
              105.83476461496906,
              21.02106608322404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_109.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12904",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021065137105808,
          "to_latitude": 21.02106608322404,
          "to_longitude": 105.83476461496906,
          "from_longitude": 105.83459647344004
        }
      },
      {
        "id": 12837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83460154329029,
            21.02105723340638,
            105.83463029980405,
            21.02118673746417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460154329029,
              21.02105723340638
            ],
            [
              105.83463029980405,
              21.02118673746417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_109.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12905",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02105723340638,
          "to_latitude": 21.02118673746417,
          "to_longitude": 105.83463029980405,
          "from_longitude": 105.83460154329029
        }
      },
      {
        "id": 12838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83264538282711,
            21.02160075663545,
            105.83272222078949,
            21.021658238253
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83272222078949,
              21.02160075663545
            ],
            [
              105.8327157332443,
              21.021605609808187
            ],
            [
              105.83264538282711,
              21.021658238253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.2_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12906",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02160075663545,
          "to_latitude": 21.021658238253,
          "to_longitude": 105.83264538282711,
          "from_longitude": 105.83272222078949
        }
      },
      {
        "id": 12839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177912706734,
            21.021246977158892,
            105.83181067795736,
            21.02130751593825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83177912706734,
              21.021246977158892
            ],
            [
              105.83181067795736,
              21.02130751593825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.6_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12907",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021246977158892,
          "to_latitude": 21.02130751593825,
          "to_longitude": 105.83181067795736,
          "from_longitude": 105.83177912706734
        }
      },
      {
        "id": 12840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335809533513,
            21.023232698903218,
            105.8339817278206,
            21.023411019164275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335809533513,
              21.023360096146803
            ],
            [
              105.83358100036682,
              21.023360074238987
            ],
            [
              105.83361188151491,
              21.023411019164275
            ],
            [
              105.83395285803319,
              21.02324417863344
            ],
            [
              105.8339817278206,
              21.023232698903218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.11_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12908",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023360096146803,
          "to_latitude": 21.023232698903218,
          "to_longitude": 105.8339817278206,
          "from_longitude": 105.8335809533513
        }
      },
      {
        "id": 12841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83310380485409,
            21.0232773830629,
            105.8332538492567,
            21.023375791842934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332538492567,
              21.0232773830629
            ],
            [
              105.83317666710293,
              21.023328780690928
            ],
            [
              105.8331391530979,
              21.023353640540357
            ],
            [
              105.83310380485409,
              21.023375791842934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.12_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12909",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0232773830629,
          "to_latitude": 21.023375791842934,
          "to_longitude": 105.83310380485409,
          "from_longitude": 105.8332538492567
        }
      },
      {
        "id": 12842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83321440175715,
            21.0234043251492,
            105.83333638228655,
            21.02351382292774
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83333638228655,
              21.0234043251492
            ],
            [
              105.83321440175715,
              21.023469130263226
            ],
            [
              105.83322655349596,
              21.02349006075359
            ],
            [
              105.83324034865788,
              21.02351382292774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.12_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12910",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0234043251492,
          "to_latitude": 21.02351382292774,
          "to_longitude": 105.83324034865788,
          "from_longitude": 105.83333638228655
        }
      },
      {
        "id": 12843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83316483935396,
            21.02347936904405,
            105.83322655349596,
            21.023502471276593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83322655349596,
              21.02349006075359
            ],
            [
              105.83321433157157,
              21.023496314939905
            ],
            [
              105.8331964555024,
              21.023502471276593
            ],
            [
              105.83317892691247,
              21.02350018166797
            ],
            [
              105.83316483935396,
              21.02347936904405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.12_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12911",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02349006075359,
          "to_latitude": 21.02347936904405,
          "to_longitude": 105.83316483935396,
          "from_longitude": 105.83322655349596
        }
      },
      {
        "id": 12844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83442456067151,
            21.022957193292072,
            105.83464416904658,
            21.0230650544832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83442456067151,
              21.0230650544832
            ],
            [
              105.83442560316146,
              21.023064654659855
            ],
            [
              105.83442969439136,
              21.02306308284935
            ],
            [
              105.83443520784633,
              21.023061239416545
            ],
            [
              105.83448360853825,
              21.023045059145492
            ],
            [
              105.83450698371863,
              21.02303294693342
            ],
            [
              105.83453901740144,
              21.023015420761343
            ],
            [
              105.83458857319602,
              21.02298762294788
            ],
            [
              105.83460588884537,
              21.022979835285994
            ],
            [
              105.83464416904658,
              21.022957193292072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.12912",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0230650544832,
          "to_latitude": 21.022957193292072,
          "to_longitude": 105.83464416904658,
          "from_longitude": 105.83442456067151
        }
      },
      {
        "id": 12845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83179894297493,
            21.02231906003012,
            105.83190553075028,
            21.022548866355006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83190553075028,
              21.022548866355006
            ],
            [
              105.8318615600109,
              21.022454798345013
            ],
            [
              105.83185409979946,
              21.022438836922316
            ],
            [
              105.83179894297493,
              21.02231906003012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12913",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022548866355006,
          "to_latitude": 21.02231906003012,
          "to_longitude": 105.83179894297493,
          "from_longitude": 105.83190553075028
        }
      },
      {
        "id": 12846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415460059811,
            21.026692080310955,
            105.83436402701888,
            21.027141160767336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83415460059811,
              21.026692080310955
            ],
            [
              105.83415556389994,
              21.026693695968763
            ],
            [
              105.8341702637125,
              21.026718305348435
            ],
            [
              105.83417203782832,
              21.026721275477023
            ],
            [
              105.8342494901296,
              21.026909021630267
            ],
            [
              105.83426638700388,
              21.026918555357465
            ],
            [
              105.83429611371763,
              21.02695600529267
            ],
            [
              105.83430455340093,
              21.0269802000819
            ],
            [
              105.83436402701888,
              21.027141160767336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.4_Ngõ 7, Hồ Giám",
          "maTaiSan": "04.O14.DODV.12914",
          "diaChiLapD": "Ngõ 7, Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026692080310955,
          "to_latitude": 21.027141160767336,
          "to_longitude": 105.83436402701888,
          "from_longitude": 105.83415460059811
        }
      },
      {
        "id": 12847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466530022494,
            21.026063478069243,
            105.83472841371866,
            21.026216167065858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83472841371866,
              21.026063478069243
            ],
            [
              105.83470120286346,
              21.026083760738985
            ],
            [
              105.8347045314335,
              21.026160307042435
            ],
            [
              105.83468921823062,
              21.026202634094375
            ],
            [
              105.83466530022494,
              21.026216167065858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.6_Ngách 23, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12915",
          "diaChiLapD": "Ngách 23, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026063478069243,
          "to_latitude": 21.026216167065858,
          "to_longitude": 105.83466530022494,
          "from_longitude": 105.83472841371866
        }
      },
      {
        "id": 12848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83367275919221,
            21.02451445994118,
            105.83374691039332,
            21.02453733990764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83374691039332,
              21.02451445994118
            ],
            [
              105.83367275919221,
              21.02453733990764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.12916",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02451445994118,
          "to_latitude": 21.02453733990764,
          "to_longitude": 105.83367275919221,
          "from_longitude": 105.83374691039332
        }
      },
      {
        "id": 12849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83382839364815,
            21.02463241974411,
            105.83398792937038,
            21.024707056802598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83382839364815,
              21.024707056802598
            ],
            [
              105.83398792937038,
              21.02463241974411
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.11_Văn Hương",
          "maTaiSan": "04.O14.DODV.12917",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024707056802598,
          "to_latitude": 21.02463241974411,
          "to_longitude": 105.83398792937038,
          "from_longitude": 105.83382839364815
        }
      },
      {
        "id": 12850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83445036854084,
            21.023597561654547,
            105.8346136501832,
            21.02390786413692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8346136501832,
              21.02390786413692
            ],
            [
              105.8346135228872,
              21.023907289408083
            ],
            [
              105.83461219346621,
              21.02390124975243
            ],
            [
              105.8345521328268,
              21.02390250009022
            ],
            [
              105.83455143506531,
              21.023900541712173
            ],
            [
              105.83454261721198,
              21.023875781554025
            ],
            [
              105.83453313180212,
              21.023823379701838
            ],
            [
              105.83452611314439,
              21.02379763071623
            ],
            [
              105.83452747876024,
              21.023725659663196
            ],
            [
              105.8344596014287,
              21.023712801127306
            ],
            [
              105.83445036854084,
              21.023597561654547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.7_Văn Hương",
          "maTaiSan": "04.O14.DODV.12918",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02390786413692,
          "to_latitude": 21.023597561654547,
          "to_longitude": 105.83445036854084,
          "from_longitude": 105.8346136501832
        }
      },
      {
        "id": 12851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83384938036896,
            21.023514502887732,
            105.83399428609597,
            21.023574948903967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398656314318,
              21.02352227947924
            ],
            [
              105.83398850174098,
              21.023525600159537
            ],
            [
              105.83398939713196,
              21.023526944282136
            ],
            [
              105.83399428609597,
              21.02352506779638
            ],
            [
              105.83399158406637,
              21.02352008712135
            ],
            [
              105.83398855545192,
              21.023514502887732
            ],
            [
              105.83384938036896,
              21.023574948903967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.12919",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02352227947924,
          "to_latitude": 21.023574948903967,
          "to_longitude": 105.83384938036896,
          "from_longitude": 105.83398656314318
        }
      },
      {
        "id": 12852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8341596141473,
            21.022061679171895,
            105.83425839853844,
            21.022062168347862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8341596141473,
              21.022061679171895
            ],
            [
              105.83425839853844,
              21.022062168347862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_79.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12920",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022061679171895,
          "to_latitude": 21.022062168347862,
          "to_longitude": 105.83425839853844,
          "from_longitude": 105.8341596141473
        }
      },
      {
        "id": 12853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83344639067859,
            21.022900446464046,
            105.8335129551772,
            21.02302729962502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344639067859,
              21.022900446464046
            ],
            [
              105.83344665320851,
              21.022900949176574
            ],
            [
              105.8335129551772,
              21.02302729962502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12921",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022900446464046,
          "to_latitude": 21.02302729962502,
          "to_longitude": 105.8335129551772,
          "from_longitude": 105.83344639067859
        }
      },
      {
        "id": 12854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335129551772,
            21.02302729962502,
            105.83355789148237,
            21.02311937263289
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335129551772,
              21.02302729962502
            ],
            [
              105.83353763024029,
              21.023077857693
            ],
            [
              105.83355789148237,
              21.02311937263289
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12922",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02302729962502,
          "to_latitude": 21.02311937263289,
          "to_longitude": 105.83355789148237,
          "from_longitude": 105.8335129551772
        }
      },
      {
        "id": 12855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355789148237,
            21.02311937263289,
            105.8336048965912,
            21.023215684252097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355789148237,
              21.02311937263289
            ],
            [
              105.8336048965912,
              21.023215684252097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12923",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02311937263289,
          "to_latitude": 21.023215684252097,
          "to_longitude": 105.8336048965912,
          "from_longitude": 105.83355789148237
        }
      },
      {
        "id": 12856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8335129551772,
            21.022980881814725,
            105.83359409156381,
            21.02302729962502
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335129551772,
              21.02302729962502
            ],
            [
              105.83359409156381,
              21.022980881814725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12924",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02302729962502,
          "to_latitude": 21.022980881814725,
          "to_longitude": 105.83359409156381,
          "from_longitude": 105.8335129551772
        }
      },
      {
        "id": 12857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83346310172743,
            21.023077857693,
            105.83353763024029,
            21.023126710704492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353763024029,
              21.023077857693
            ],
            [
              105.83346310172743,
              21.023126710704492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12925",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023077857693,
          "to_latitude": 21.023126710704492,
          "to_longitude": 105.83346310172743,
          "from_longitude": 105.83353763024029
        }
      },
      {
        "id": 12858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314566079613,
            21.022342393303504,
            105.83329314243603,
            21.022470574440725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83329314243603,
              21.022470574440725
            ],
            [
              105.83329289202489,
              21.0224703480551
            ],
            [
              105.83314566079613,
              21.022342393303504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12926",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022470574440725,
          "to_latitude": 21.022342393303504,
          "to_longitude": 105.83314566079613,
          "from_longitude": 105.83329314243603
        }
      },
      {
        "id": 12859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83208418561865,
            21.022806537203877,
            105.83226334626983,
            21.02288940488483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83226334626983,
              21.022806537203877
            ],
            [
              105.83221808544873,
              21.022836608428122
            ],
            [
              105.83208418561865,
              21.02288940488483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_82.1_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12927",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022806537203877,
          "to_latitude": 21.02288940488483,
          "to_longitude": 105.83208418561865,
          "from_longitude": 105.83226334626983
        }
      },
      {
        "id": 12860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83274973987221,
            21.022158228162816,
            105.83295082666848,
            21.022350400991602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83274973987221,
              21.022350400991602
            ],
            [
              105.83275672428957,
              21.02234372538758
            ],
            [
              105.83284281655693,
              21.022261434703186
            ],
            [
              105.83295082666848,
              21.022158228162816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.8_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12928",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022350400991602,
          "to_latitude": 21.022158228162816,
          "to_longitude": 105.83295082666848,
          "from_longitude": 105.83274973987221
        }
      },
      {
        "id": 12861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83433516013598,
            21.020648418190014,
            105.8345443439448,
            21.02070007411515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83433516013598,
              21.02070007411515
            ],
            [
              105.83445624551902,
              21.020668164454122
            ],
            [
              105.83453117209298,
              21.020648418190014
            ],
            [
              105.83453917619956,
              21.020656809613186
            ],
            [
              105.83454354850437,
              21.020654590617163
            ],
            [
              105.8345443439448,
              21.020653706974244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_108.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12929",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02070007411515,
          "to_latitude": 21.020653706974244,
          "to_longitude": 105.8345443439448,
          "from_longitude": 105.83433516013598
        }
      },
      {
        "id": 12862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535841197903,
            21.02020645559959,
            105.83540338155787,
            21.0203282937234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540338155787,
              21.0203282937234
            ],
            [
              105.8353853782328,
              21.020311867595506
            ],
            [
              105.83537101864302,
              21.020278137670573
            ],
            [
              105.83536648601027,
              21.020244318838383
            ],
            [
              105.83535841197903,
              21.02020645559959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.13_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12930",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0203282937234,
          "to_latitude": 21.02020645559959,
          "to_longitude": 105.83535841197903,
          "from_longitude": 105.83540338155787
        }
      },
      {
        "id": 12863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.835343781117,
            21.019952681238983,
            105.83541966163048,
            21.02020645559959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535841197903,
              21.02020645559959
            ],
            [
              105.83535059959091,
              21.020181886532203
            ],
            [
              105.83534396354743,
              21.02013656914857
            ],
            [
              105.83534394233422,
              21.020119636512696
            ],
            [
              105.835343781117,
              21.019992261044084
            ],
            [
              105.83534811167148,
              21.019983416181876
            ],
            [
              105.8353643247255,
              21.01997238316268
            ],
            [
              105.83541966163048,
              21.019952681238983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.13_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12931",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02020645559959,
          "to_latitude": 21.019952681238983,
          "to_longitude": 105.83541966163048,
          "from_longitude": 105.83535841197903
        }
      },
      {
        "id": 12864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540055621532,
            21.01982768848243,
            105.83542729263421,
            21.01992207143477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83542729263421,
              21.01992207143477
            ],
            [
              105.83540055621532,
              21.01982768848243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.9_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12932",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01992207143477,
          "to_latitude": 21.01982768848243,
          "to_longitude": 105.83540055621532,
          "from_longitude": 105.83542729263421
        }
      },
      {
        "id": 12865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84147489105605,
            21.027925332711234,
            105.84254487997458,
            21.028711528478034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84148311021976,
              21.02840027653143
            ],
            [
              105.84147997775302,
              21.028400594612116
            ],
            [
              105.84147489105605,
              21.028400674880515
            ],
            [
              105.84147502867394,
              21.028404796505043
            ],
            [
              105.84148318113516,
              21.028404854613157
            ],
            [
              105.84157907981174,
              21.02840553614608
            ],
            [
              105.84161566292563,
              21.028405793148984
            ],
            [
              105.84163125182559,
              21.028630307570406
            ],
            [
              105.84167226768929,
              21.02868208227388
            ],
            [
              105.84175833943182,
              21.028711528478034
            ],
            [
              105.84185618596452,
              21.028692607515442
            ],
            [
              105.8421653480178,
              21.02860166939079
            ],
            [
              105.84254487997458,
              21.027925332711234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.5_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12933",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 194,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02840027653143,
          "to_latitude": 21.027925332711234,
          "to_longitude": 105.84254487997458,
          "from_longitude": 105.84148311021976
        }
      },
      {
        "id": 12866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84137896846258,
            21.022033442328127,
            105.8413987146142,
            21.022033553291756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8413987146142,
              21.022033553291756
            ],
            [
              105.84137896846258,
              21.022033442328127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.10_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12934",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022033553291756,
          "to_latitude": 21.022033442328127,
          "to_longitude": 105.84137896846258,
          "from_longitude": 105.8413987146142
        }
      },
      {
        "id": 12867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8411103508015,
            21.019294870325552,
            105.8413943120922,
            21.019630861336964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8413943120922,
              21.019630861336964
            ],
            [
              105.84138490063621,
              21.01963058453571
            ],
            [
              105.8411133797229,
              21.019622576287315
            ],
            [
              105.8411121888178,
              21.019622576756703
            ],
            [
              105.84111216074798,
              21.01961757213662
            ],
            [
              105.8411103508015,
              21.019294870325552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.2_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12935",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019630861336964,
          "to_latitude": 21.019294870325552,
          "to_longitude": 105.8411103508015,
          "from_longitude": 105.8413943120922
        }
      },
      {
        "id": 12868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84119947502519,
            21.020581843666122,
            105.84140973003683,
            21.020583003342498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84140973003683,
              21.020583003342498
            ],
            [
              105.84139500499,
              21.020582793473103
            ],
            [
              105.84134554436054,
              21.02058208522709
            ],
            [
              105.8411995279319,
              21.020581843666122
            ],
            [
              105.84119947502519,
              21.020581843927772
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.5_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12936",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020583003342498,
          "to_latitude": 21.020581843927772,
          "to_longitude": 105.84119947502519,
          "from_longitude": 105.84140973003683
        }
      },
      {
        "id": 12869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84113686107496,
            21.020507890425492,
            105.84119947553607,
            21.020631323219874
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84113686107496,
              21.020631323219874
            ],
            [
              105.84119947553607,
              21.02063132247712
            ],
            [
              105.84119947502519,
              21.020581843927772
            ],
            [
              105.84119844355251,
              21.020507890425492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.5_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12937",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020631323219874,
          "to_latitude": 21.020507890425492,
          "to_longitude": 105.84119844355251,
          "from_longitude": 105.84113686107496
        }
      },
      {
        "id": 12870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84134554436054,
            21.020336692053196,
            105.84134594046537,
            21.02058208522709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84134554436054,
              21.02058208522709
            ],
            [
              105.84134594046537,
              21.020336692053196
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.5_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12938",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02058208522709,
          "to_latitude": 21.020336692053196,
          "to_longitude": 105.84134594046537,
          "from_longitude": 105.84134554436054
        }
      },
      {
        "id": 12871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84116611470324,
            21.020907730281753,
            105.84116643464903,
            21.021055279646067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84116611470324,
              21.021055279646067
            ],
            [
              105.84116628514836,
              21.02097659815408
            ],
            [
              105.84116643464903,
              21.020907730281753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.8_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12939",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021055279646067,
          "to_latitude": 21.020907730281753,
          "to_longitude": 105.84116643464903,
          "from_longitude": 105.84116611470324
        }
      },
      {
        "id": 12872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84116628514836,
            21.020962218903136,
            105.84142576793752,
            21.02097659815408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84116628514836,
              21.02097659815408
            ],
            [
              105.84140195121044,
              21.020963539811873
            ],
            [
              105.84142576793752,
              21.020962218903136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.8_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12940",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02097659815408,
          "to_latitude": 21.020962218903136,
          "to_longitude": 105.84142576793752,
          "from_longitude": 105.84116628514836
        }
      },
      {
        "id": 12873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84129319083574,
            21.021217053270853,
            105.84143654440402,
            21.02122054430019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84129319083574,
              21.02122054430019
            ],
            [
              105.84140522498237,
              21.021217816975856
            ],
            [
              105.84143654440402,
              21.021217053270853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.9_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12941",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02122054430019,
          "to_latitude": 21.021217053270853,
          "to_longitude": 105.84143654440402,
          "from_longitude": 105.84129319083574
        }
      },
      {
        "id": 12874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84138169013087,
            21.022278636675633,
            105.84140143630972,
            21.022278746734656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84140143630972,
              21.022278746734656
            ],
            [
              105.84138169013087,
              21.022278636675633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.11_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12942",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022278746734656,
          "to_latitude": 21.022278636675633,
          "to_longitude": 105.84138169013087,
          "from_longitude": 105.84140143630972
        }
      },
      {
        "id": 12875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84138524446325,
            21.022598840444584,
            105.8414049906843,
            21.02259895050177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8414049906843,
              21.02259895050177
            ],
            [
              105.84138524446325,
              21.022598840444584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.12_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12943",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02259895050177,
          "to_latitude": 21.022598840444584,
          "to_longitude": 105.84138524446325,
          "from_longitude": 105.8414049906843
        }
      },
      {
        "id": 12876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84138370339728,
            21.023130275378186,
            105.84141089087015,
            21.023130428104157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84141089087015,
              21.023130428104157
            ],
            [
              105.84140174885239,
              21.023130376686193
            ],
            [
              105.84139272612738,
              21.023130326484033
            ],
            [
              105.84138370339728,
              21.023130275378186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.13_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12944",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023130428104157,
          "to_latitude": 21.023130275378186,
          "to_longitude": 105.84138370339728,
          "from_longitude": 105.84141089087015
        }
      },
      {
        "id": 12877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84138270361865,
            21.02315508281756,
            105.84141116575995,
            21.023155241882673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84141116575995,
              21.023155241882673
            ],
            [
              105.84140245087491,
              21.023155192867808
            ],
            [
              105.84139257677087,
              21.02315513874854
            ],
            [
              105.84138270361865,
              21.02315508281756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.14_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12945",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023155241882673,
          "to_latitude": 21.02315508281756,
          "to_longitude": 105.84138270361865,
          "from_longitude": 105.84141116575995
        }
      },
      {
        "id": 12878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84139433815143,
            21.02341810058944,
            105.84141408544231,
            21.023418210637146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84141408544231,
              21.023418210637146
            ],
            [
              105.84139433815143,
              21.02341810058944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.15_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12946",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023418210637146,
          "to_latitude": 21.02341810058944,
          "to_longitude": 105.84139433815143,
          "from_longitude": 105.84141408544231
        }
      },
      {
        "id": 12879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84115911899619,
            21.020894915787633,
            105.84142292090773,
            21.020896776946692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84142292090773,
              21.020894915787633
            ],
            [
              105.84140120147255,
              21.02089507019578
            ],
            [
              105.84115911899619,
              21.020896776946692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.7_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12947",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020894915787633,
          "to_latitude": 21.020896776946692,
          "to_longitude": 105.84115911899619,
          "from_longitude": 105.84142292090773
        }
      },
      {
        "id": 12880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84141498354386,
            21.026137011465384,
            105.84144571792173,
            21.026137067137228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84144571792173,
              21.026137067137228
            ],
            [
              105.84143035073788,
              21.026137040205196
            ],
            [
              105.84141498354386,
              21.026137011465384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.10_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12948",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026137067137228,
          "to_latitude": 21.026137011465384,
          "to_longitude": 105.84141498354386,
          "from_longitude": 105.84144571792173
        }
      },
      {
        "id": 12881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84139814776124,
            21.02582270113372,
            105.84143918866485,
            21.025822705815628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84143918866485,
              21.025822705815628
            ],
            [
              105.84141866821818,
              21.025822704379102
            ],
            [
              105.84139814776124,
              21.02582270113372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.11_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12949",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025822705815628,
          "to_latitude": 21.02582270113372,
          "to_longitude": 105.84139814776124,
          "from_longitude": 105.84143918866485
        }
      },
      {
        "id": 12882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84137656912331,
            21.02508118011674,
            105.8414149366432,
            21.025081221520246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8414149366432,
              21.025081221520246
            ],
            [
              105.84141116559675,
              21.025081217597137
            ],
            [
              105.84137656912331,
              21.02508118011674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.12_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12950",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025081221520246,
          "to_latitude": 21.02508118011674,
          "to_longitude": 105.84137656912331,
          "from_longitude": 105.8414149366432
        }
      },
      {
        "id": 12883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84139624436105,
            21.02358978564484,
            105.84141599071256,
            21.023589895696347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84141599071256,
              21.023589895696347
            ],
            [
              105.84139624436105,
              21.02358978564484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.16_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12951",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023589895696347,
          "to_latitude": 21.02358978564484,
          "to_longitude": 105.84139624436105,
          "from_longitude": 105.84141599071256
        }
      },
      {
        "id": 12884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84054339323846,
            21.028092168114465,
            105.84076537515043,
            21.028365774953382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84054339323846,
              21.028365774953382
            ],
            [
              105.84073772445508,
              21.02832750575396
            ],
            [
              105.84073486503961,
              21.028313422385242
            ],
            [
              105.84073692650455,
              21.028309419728167
            ],
            [
              105.84073352393574,
              21.02830622458577
            ],
            [
              105.84073155848961,
              21.028298276356185
            ],
            [
              105.84070470170401,
              21.028170975321512
            ],
            [
              105.84076537515043,
              21.028148811742955
            ],
            [
              105.84074659968725,
              21.028092168114465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.1_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12952",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": 52.414,
          "from_latitude": 21.028365774953382,
          "to_latitude": 21.028092168114465,
          "to_longitude": 105.84074659968725,
          "from_longitude": 105.84054339323846
        }
      },
      {
        "id": 12885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83700961109774,
            21.02711917379324,
            105.83721620641175,
            21.02759947043824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721620641175,
              21.02759947043824
            ],
            [
              105.83721557251668,
              21.02759758853296
            ],
            [
              105.83719031697152,
              21.027557141906847
            ],
            [
              105.83715847690429,
              21.027480599678963
            ],
            [
              105.83700961109774,
              21.027187500503825
            ],
            [
              105.8371567085916,
              21.02711917379324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.1_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.12953",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02759947043824,
          "to_latitude": 21.02711917379324,
          "to_longitude": 105.8371567085916,
          "from_longitude": 105.83721620641175
        }
      },
      {
        "id": 12886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719031697152,
            21.02747470588,
            105.83739847660215,
            21.027557141906847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719031697152,
              21.027557141906847
            ],
            [
              105.83721168499079,
              21.027549870536227
            ],
            [
              105.83728546004772,
              21.027524766221134
            ],
            [
              105.83739847660215,
              21.02747470588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.1_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.12954",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027557141906847,
          "to_latitude": 21.02747470588,
          "to_longitude": 105.83739847660215,
          "from_longitude": 105.83719031697152
        }
      },
      {
        "id": 12887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83521381744758,
            21.023238548343713,
            105.83566957771885,
            21.0234978795494
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83566957771885,
              21.0234978795494
            ],
            [
              105.8356692059348,
              21.023497452344944
            ],
            [
              105.83564775127098,
              21.023472692918467
            ],
            [
              105.8356023365747,
              21.023411023556594
            ],
            [
              105.8355457424286,
              21.023343785632736
            ],
            [
              105.83552440202244,
              21.023297228131987
            ],
            [
              105.83544254383322,
              21.02324302766871
            ],
            [
              105.83524007649979,
              21.023306885551314
            ],
            [
              105.83521381744758,
              21.023238548343713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_72.8_Văn Hương",
          "maTaiSan": "04.O14.DODV.12955",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0234978795494,
          "to_latitude": 21.023238548343713,
          "to_longitude": 105.83521381744758,
          "from_longitude": 105.83566957771885
        }
      },
      {
        "id": 12888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83934420781881,
            21.023838654083157,
            105.83951838473682,
            21.02383887194965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83951838473682,
              21.02383887194965
            ],
            [
              105.83934420781881,
              21.023838654083157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.12956",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02383887194965,
          "to_latitude": 21.023838654083157,
          "to_longitude": 105.83934420781881,
          "from_longitude": 105.83951838473682
        }
      },
      {
        "id": 12889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926452190934,
            21.02370546360047,
            105.83926740264356,
            21.023838554166684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83926452190934,
              21.023838554166684
            ],
            [
              105.83926740264356,
              21.02370546360047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.12957",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023838554166684,
          "to_latitude": 21.02370546360047,
          "to_longitude": 105.83926740264356,
          "from_longitude": 105.83926452190934
        }
      },
      {
        "id": 12890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903444212949,
            21.023205958331403,
            105.83918419434283,
            21.023269201996104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903444212949,
              21.023269201996104
            ],
            [
              105.83910422614926,
              21.023231335128187
            ],
            [
              105.83911717796958,
              21.023249494655804
            ],
            [
              105.83918419434283,
              21.023205958331403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.13_Linh Quang",
          "maTaiSan": "04.O14.DODV.12958",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023269201996104,
          "to_latitude": 21.023205958331403,
          "to_longitude": 105.83918419434283,
          "from_longitude": 105.83903444212949
        }
      },
      {
        "id": 12891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884407514572,
            21.02372882879143,
            105.83896411479692,
            21.02378317530596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83884407514572,
              21.02378317530596
            ],
            [
              105.83896411479692,
              21.02372882879143
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.12_Linh Quang",
          "maTaiSan": "04.O14.DODV.12959",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02378317530596,
          "to_latitude": 21.02372882879143,
          "to_longitude": 105.83896411479692,
          "from_longitude": 105.83884407514572
        }
      },
      {
        "id": 12892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892659049792,
            21.02370946419106,
            105.83907993703535,
            21.023842254989777
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83907993703535,
              21.023842254989777
            ],
            [
              105.83903394546431,
              21.023810649600406
            ],
            [
              105.83899487489653,
              21.023755055679526
            ],
            [
              105.83897460198882,
              21.02372910131596
            ],
            [
              105.83896411479692,
              21.02372882879143
            ],
            [
              105.83892659049792,
              21.02371541135236
            ],
            [
              105.83892850738809,
              21.023711394296527
            ],
            [
              105.83892958940638,
              21.02370946419106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.12_Linh Quang",
          "maTaiSan": "04.O14.DODV.12960",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023842254989777,
          "to_latitude": 21.02370946419106,
          "to_longitude": 105.83892958940638,
          "from_longitude": 105.83907993703535
        }
      },
      {
        "id": 12893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877349668234,
            21.02359311404899,
            105.83892659049792,
            21.02371541135236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892659049792,
              21.02371541135236
            ],
            [
              105.83891676531911,
              21.023709150803796
            ],
            [
              105.83887414712818,
              21.02368452599763
            ],
            [
              105.83884512708744,
              21.02365654186441
            ],
            [
              105.8388243466767,
              21.023636330870133
            ],
            [
              105.83879394294496,
              21.023606560654198
            ],
            [
              105.83877349668234,
              21.02359311404899
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.12_Linh Quang",
          "maTaiSan": "04.O14.DODV.12961",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02371541135236,
          "to_latitude": 21.02359311404899,
          "to_longitude": 105.83877349668234,
          "from_longitude": 105.83892659049792
        }
      },
      {
        "id": 12894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892760212608,
            21.023700041635912,
            105.83906289313589,
            21.023796296382923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892760212608,
              21.023700041635912
            ],
            [
              105.83893312087676,
              21.023704480851357
            ],
            [
              105.83897658149651,
              21.023718395584503
            ],
            [
              105.83906289313589,
              21.023796296382923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.11_Linh Quang",
          "maTaiSan": "04.O14.DODV.12962",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023700041635912,
          "to_latitude": 21.023796296382923,
          "to_longitude": 105.83906289313589,
          "from_longitude": 105.83892760212608
        }
      },
      {
        "id": 12895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83855244762182,
            21.023577635612586,
            105.83876339688202,
            21.023658259109105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855917055185,
              21.023658259109105
            ],
            [
              105.83855864758648,
              21.023657290722788
            ],
            [
              105.83855244762182,
              21.023645774487875
            ],
            [
              105.83863036823419,
              21.02361750417713
            ],
            [
              105.83874417338599,
              21.023577635612586
            ],
            [
              105.83876339688202,
              21.023577999646747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.12963",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023658259109105,
          "to_latitude": 21.023577999646747,
          "to_longitude": 105.83876339688202,
          "from_longitude": 105.83855917055185
        }
      },
      {
        "id": 12896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83827724699559,
            21.023535124237302,
            105.83855244762182,
            21.02367961017052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83855244762182,
              21.023645774487875
            ],
            [
              105.83846798291765,
              21.02367961017052
            ],
            [
              105.83845437815638,
              21.023678862533778
            ],
            [
              105.83834567372972,
              21.0236097309951
            ],
            [
              105.83834546848865,
              21.02357302501961
            ],
            [
              105.83827724699559,
              21.023535124237302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.12964",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023645774487875,
          "to_latitude": 21.023535124237302,
          "to_longitude": 105.83827724699559,
          "from_longitude": 105.83855244762182
        }
      },
      {
        "id": 12897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83824663944156,
            21.023738541927834,
            105.83836829748226,
            21.023853783177167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836829748226,
              21.023738541927834
            ],
            [
              105.83836287692918,
              21.023749941129715
            ],
            [
              105.83828801648173,
              21.02382108939925
            ],
            [
              105.83825966859757,
              21.023843972279447
            ],
            [
              105.83824663944156,
              21.023853783177167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.7_Linh Quang",
          "maTaiSan": "04.O14.DODV.12965",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023738541927834,
          "to_latitude": 21.023853783177167,
          "to_longitude": 105.83824663944156,
          "from_longitude": 105.83836829748226
        }
      },
      {
        "id": 12898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8384561999272,
            21.02359781378503,
            105.83873150145854,
            21.023698034634645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384561999272,
              21.02369223841549
            ],
            [
              105.83845770504158,
              21.023694919890534
            ],
            [
              105.83845963296761,
              21.023698034634645
            ],
            [
              105.83849729686214,
              21.023683784863948
            ],
            [
              105.83873150145854,
              21.02359781378503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.8_Linh Quang",
          "maTaiSan": "04.O14.DODV.12966",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02369223841549,
          "to_latitude": 21.02359781378503,
          "to_longitude": 105.83873150145854,
          "from_longitude": 105.8384561999272
        }
      },
      {
        "id": 12899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382825839695,
            21.023698034634645,
            105.83845963296761,
            21.023841362824587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83845963296761,
              21.023698034634645
            ],
            [
              105.83841012170589,
              21.023722864446476
            ],
            [
              105.8383858067615,
              21.02374424347036
            ],
            [
              105.8382825839695,
              21.023841362824587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.8_Linh Quang",
          "maTaiSan": "04.O14.DODV.12967",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023698034634645,
          "to_latitude": 21.023841362824587,
          "to_longitude": 105.8382825839695,
          "from_longitude": 105.83845963296761
        }
      },
      {
        "id": 12900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798043552075,
            21.023775333581153,
            105.83818084121698,
            21.023867700068642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83818084121698,
              21.023867700068642
            ],
            [
              105.83810492798663,
              21.023838135039068
            ],
            [
              105.83803334304193,
              21.023813265278594
            ],
            [
              105.83798043552075,
              21.023782786575303
            ],
            [
              105.83798522737692,
              21.023776280532623
            ],
            [
              105.8379858531486,
              21.023775333581153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.4_Linh Quang",
          "maTaiSan": "04.O14.DODV.12968",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023867700068642,
          "to_latitude": 21.023775333581153,
          "to_longitude": 105.8379858531486,
          "from_longitude": 105.83818084121698
        }
      },
      {
        "id": 12901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83781268679179,
            21.023745046715486,
            105.83798043552075,
            21.023782786575303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798043552075,
              21.023782786575303
            ],
            [
              105.83791215431187,
              21.023745046715486
            ],
            [
              105.83785430256039,
              21.02375542704996
            ],
            [
              105.83781268679179,
              21.02376366171377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.4_Linh Quang",
          "maTaiSan": "04.O14.DODV.12969",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023782786575303,
          "to_latitude": 21.02376366171377,
          "to_longitude": 105.83781268679179,
          "from_longitude": 105.83798043552075
        }
      },
      {
        "id": 12902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83759230018039,
            21.02376834338987,
            105.83775542366578,
            21.023817386754867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775542366578,
              21.02376834338987
            ],
            [
              105.83759230018039,
              21.023807932132552
            ],
            [
              105.83759499329922,
              21.0238169781982
            ],
            [
              105.83759511583021,
              21.023817386754867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.12970",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02376834338987,
          "to_latitude": 21.023817386754867,
          "to_longitude": 105.83759511583021,
          "from_longitude": 105.83775542366578
        }
      },
      {
        "id": 12903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373766202001,
            21.023807932132552,
            105.83759230018039,
            21.02385164258114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759230018039,
              21.023807932132552
            ],
            [
              105.8373766202001,
              21.02385164258114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.12971",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023807932132552,
          "to_latitude": 21.02385164258114,
          "to_longitude": 105.8373766202001,
          "from_longitude": 105.83759230018039
        }
      },
      {
        "id": 12904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395221078001,
            21.019975943679377,
            105.83953066000083,
            21.02009611416525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83953066000083,
              21.02009611416525
            ],
            [
              105.83952970568141,
              21.020082702396895
            ],
            [
              105.8395221078001,
              21.019975943679377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12972",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02009611416525,
          "to_latitude": 21.019975943679377,
          "to_longitude": 105.8395221078001,
          "from_longitude": 105.83953066000083
        }
      },
      {
        "id": 12905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396157800885,
            21.019982322350792,
            105.83964189893786,
            21.020080183213505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83964189893786,
              21.020080183213505
            ],
            [
              105.83963903087604,
              21.020049006362218
            ],
            [
              105.8396157800885,
              21.019982322350792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12973",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020080183213505,
          "to_latitude": 21.019982322350792,
          "to_longitude": 105.8396157800885,
          "from_longitude": 105.83964189893786
        }
      },
      {
        "id": 12906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953014335225,
            21.020279751102645,
            105.83954700137878,
            21.020553597372547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954700137878,
              21.020553597372547
            ],
            [
              105.83954239147467,
              21.02027985316406
            ],
            [
              105.83954239090328,
              21.020279751102645
            ],
            [
              105.83953606130315,
              21.020279946729772
            ],
            [
              105.83953014335225,
              21.02028012948625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.8_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12974",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020553597372547,
          "to_latitude": 21.02028012948625,
          "to_longitude": 105.83953014335225,
          "from_longitude": 105.83954700137878
        }
      },
      {
        "id": 12907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83954239090328,
            21.020115743995984,
            105.8395429050782,
            21.020279751102645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954239090328,
              21.020279751102645
            ],
            [
              105.8395429050782,
              21.020115743995984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.8_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12975",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020279751102645,
          "to_latitude": 21.020115743995984,
          "to_longitude": 105.8395429050782,
          "from_longitude": 105.83954239090328
        }
      },
      {
        "id": 12908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839543246126,
            21.020584684986567,
            105.8395522110931,
            21.02079460760935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954964960701,
              21.020584684986567
            ],
            [
              105.8395522110931,
              21.02079460760935
            ],
            [
              105.839543246126,
              21.020794539858656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.12_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12976",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020584684986567,
          "to_latitude": 21.020794539858656,
          "to_longitude": 105.839543246126,
          "from_longitude": 105.83954964960701
        }
      },
      {
        "id": 12909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395522110931,
            21.02079460760935,
            105.83955604135578,
            21.02110863669193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8395522110931,
              21.02079460760935
            ],
            [
              105.83955604135578,
              21.02110863669193
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.12_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12977",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02079460760935,
          "to_latitude": 21.02110863669193,
          "to_longitude": 105.83955604135578,
          "from_longitude": 105.8395522110931
        }
      },
      {
        "id": 12910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841535810582,
            21.020012174063073,
            105.83947574676826,
            21.020512440879532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83947574676826,
              21.020012174063073
            ],
            [
              105.83896480656165,
              21.02001392624169
            ],
            [
              105.83896354374146,
              21.020402391673237
            ],
            [
              105.83878792575797,
              21.020402659841718
            ],
            [
              105.83862752054475,
              21.02034878677712
            ],
            [
              105.8386126138125,
              21.02034377963907
            ],
            [
              105.83842402211519,
              21.020508062701538
            ],
            [
              105.83841535810582,
              21.020512440879532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.9_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12978",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 161.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020012174063073,
          "to_latitude": 21.020512440879532,
          "to_longitude": 105.83841535810582,
          "from_longitude": 105.83947574676826
        }
      },
      {
        "id": 12911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83937440367896,
            21.02483783534212,
            105.83951463120663,
            21.02483962976113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83951463120663,
              21.02483962976113
            ],
            [
              105.83941393295466,
              21.024838341140963
            ],
            [
              105.83937440367896,
              21.02483783534212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12980",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02483962976113,
          "to_latitude": 21.02483783534212,
          "to_longitude": 105.83937440367896,
          "from_longitude": 105.83951463120663
        }
      },
      {
        "id": 12912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83951463120663,
            21.02467288210069,
            105.8395980808295,
            21.024840391994285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83951463120663,
              21.02483962976113
            ],
            [
              105.8395980808295,
              21.024840391994285
            ],
            [
              105.83959698401468,
              21.02467288210069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12981",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02483962976113,
          "to_latitude": 21.02467288210069,
          "to_longitude": 105.83959698401468,
          "from_longitude": 105.83951463120663
        }
      },
      {
        "id": 12913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941809694848,
            21.024870878358378,
            105.83954083681131,
            21.024886878749054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954083681131,
              21.024870878358378
            ],
            [
              105.83944373037382,
              21.02488500719964
            ],
            [
              105.83941809694848,
              21.024886878749054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12982",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024870878358378,
          "to_latitude": 21.024886878749054,
          "to_longitude": 105.83941809694848,
          "from_longitude": 105.83954083681131
        }
      },
      {
        "id": 12914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932870121454,
            21.024886878749054,
            105.83941809694848,
            21.024889382924513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83941809694848,
              21.024886878749054
            ],
            [
              105.83932870121454,
              21.024889382924513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12983",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024886878749054,
          "to_latitude": 21.024889382924513,
          "to_longitude": 105.83932870121454,
          "from_longitude": 105.83941809694848
        }
      },
      {
        "id": 12915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941724290514,
            21.024863549106822,
            105.83942286562024,
            21.025017124324158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83941724290514,
              21.024863549106822
            ],
            [
              105.8394172862667,
              21.024864764630678
            ],
            [
              105.83941809694848,
              21.024886878749054
            ],
            [
              105.83942286562024,
              21.025017124324158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.3_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12984",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024863549106822,
          "to_latitude": 21.025017124324158,
          "to_longitude": 105.83942286562024,
          "from_longitude": 105.83941724290514
        }
      },
      {
        "id": 12916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.839299429505,
            21.024688652436485,
            105.83929966995221,
            21.024835051024496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929966995221,
              21.024835051024496
            ],
            [
              105.839299429505,
              21.024688652436485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12985",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024835051024496,
          "to_latitude": 21.024688652436485,
          "to_longitude": 105.839299429505,
          "from_longitude": 105.83929966995221
        }
      },
      {
        "id": 12917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926986693548,
            21.024667942008087,
            105.83929966995221,
            21.024835051024496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929966995221,
              21.024835051024496
            ],
            [
              105.83927008842717,
              21.024835018170947
            ],
            [
              105.83926986693548,
              21.024667942008087
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.12986",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024835051024496,
          "to_latitude": 21.024667942008087,
          "to_longitude": 105.83926986693548,
          "from_longitude": 105.83929966995221
        }
      },
      {
        "id": 12918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83662882202049,
            21.029437761412275,
            105.83680190621898,
            21.02948971034851
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662882202049,
              21.02948971034851
            ],
            [
              105.83679511898136,
              21.029437761412275
            ],
            [
              105.83680190621898,
              21.029446665733975
            ],
            [
              105.83676547340553,
              21.02945763936919
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.3_Văn Miếu",
          "maTaiSan": "04.O14.DODV.12987",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": 23.407,
          "from_latitude": 21.02948971034851,
          "to_latitude": 21.02945763936919,
          "to_longitude": 105.83676547340553,
          "from_longitude": 105.83662882202049
        }
      },
      {
        "id": 12919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360205383531,
            21.019170279817132,
            105.83640314405032,
            21.01989526467063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630984324597,
              21.019170279817132
            ],
            [
              105.83630959910393,
              21.019174799846706
            ],
            [
              105.83630723018642,
              21.019194016743437
            ],
            [
              105.83630496806668,
              21.01921047737887
            ],
            [
              105.83630333456986,
              21.01922236370749
            ],
            [
              105.83630254788288,
              21.019228091305088
            ],
            [
              105.8363122925349,
              21.01930123072638
            ],
            [
              105.83632469299862,
              21.019334148270616
            ],
            [
              105.83634706481253,
              21.01936476324444
            ],
            [
              105.83637708710573,
              21.019415903393725
            ],
            [
              105.836383897064,
              21.019427502530302
            ],
            [
              105.83639124700197,
              21.019440021203096
            ],
            [
              105.83640314405032,
              21.019460287049085
            ],
            [
              105.8363892656265,
              21.019481463630978
            ],
            [
              105.83634523887478,
              21.019509801977897
            ],
            [
              105.8363352465804,
              21.01953146344759
            ],
            [
              105.83634310980545,
              21.019567023549286
            ],
            [
              105.8363473682583,
              21.01962305487854
            ],
            [
              105.83633339526088,
              21.01964469888786
            ],
            [
              105.83631726661444,
              21.0196701578734
            ],
            [
              105.83630891418379,
              21.01969677359098
            ],
            [
              105.83629135039618,
              21.019751060600324
            ],
            [
              105.83628520675472,
              21.01975941491605
            ],
            [
              105.8362739963949,
              21.019774658642618
            ],
            [
              105.83625555362654,
              21.019797081522274
            ],
            [
              105.83624197681607,
              21.019813185008022
            ],
            [
              105.83621711659454,
              21.01983764458969
            ],
            [
              105.8362127265463,
              21.0198418480976
            ],
            [
              105.83621045497505,
              21.01984402338897
            ],
            [
              105.83620661211218,
              21.01984164060804
            ],
            [
              105.83619454103719,
              21.019834158028985
            ],
            [
              105.83616740550542,
              21.019883760089726
            ],
            [
              105.83616337318416,
              21.019888459514295
            ],
            [
              105.83607183206954,
              21.019856249805336
            ],
            [
              105.8360205383531,
              21.01989526467063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114.4_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12988",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019170279817132,
          "to_latitude": 21.01989526467063,
          "to_longitude": 105.8360205383531,
          "from_longitude": 105.83630984324597
        }
      },
      {
        "id": 12920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372251280759,
            21.02328750716736,
            105.83758485709603,
            21.023549125035395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758485709603,
              21.02328750716736
            ],
            [
              105.83739075132864,
              21.023421642341326
            ],
            [
              105.83736856322399,
              21.023444742230602
            ],
            [
              105.8372251280759,
              21.02352896581386
            ],
            [
              105.83723184728113,
              21.02354666120463
            ],
            [
              105.83722735310856,
              21.023548769773768
            ],
            [
              105.83722637578961,
              21.023549125035395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.7_Linh Quang",
          "maTaiSan": "04.O14.DODV.12989",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02328750716736,
          "to_latitude": 21.023549125035395,
          "to_longitude": 105.83722637578961,
          "from_longitude": 105.83758485709603
        }
      },
      {
        "id": 12921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720391668486,
            21.023222915997515,
            105.83746395792019,
            21.023511468212426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720391668486,
              21.023511468212426
            ],
            [
              105.83726173853645,
              21.023482993071813
            ],
            [
              105.83732199448703,
              21.023449747748224
            ],
            [
              105.83742264950236,
              21.0233824868131
            ],
            [
              105.83746395792019,
              21.023348002478635
            ],
            [
              105.8374160691839,
              21.02327357241878
            ],
            [
              105.83738562610971,
              21.023222915997515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.12990",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023511468212426,
          "to_latitude": 21.023222915997515,
          "to_longitude": 105.83738562610971,
          "from_longitude": 105.83720391668486
        }
      },
      {
        "id": 12922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83651677076193,
            21.022921548476912,
            105.8367520721812,
            21.023052526246055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367520721812,
              21.022921548476912
            ],
            [
              105.83674908262154,
              21.022923840211057
            ],
            [
              105.83656928350808,
              21.023015279152947
            ],
            [
              105.83656270793104,
              21.023018480003913
            ],
            [
              105.83651677076193,
              21.023052526246055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.15_Linh Quang",
          "maTaiSan": "04.O14.DODV.12991",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022921548476912,
          "to_latitude": 21.023052526246055,
          "to_longitude": 105.83651677076193,
          "from_longitude": 105.8367520721812
        }
      },
      {
        "id": 12923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83656928350808,
            21.023015279152947,
            105.83661389901914,
            21.023110732739955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83656928350808,
              21.023015279152947
            ],
            [
              105.83661389901914,
              21.023110732739955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.15_Linh Quang",
          "maTaiSan": "04.O14.DODV.12992",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023015279152947,
          "to_latitude": 21.023110732739955,
          "to_longitude": 105.83661389901914,
          "from_longitude": 105.83656928350808
        }
      },
      {
        "id": 12924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83684773713763,
            21.022561071902178,
            105.83694116256491,
            21.02277284080926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83694116256491,
              21.02277284080926
            ],
            [
              105.83690845393755,
              21.02270540361336
            ],
            [
              105.83689478366783,
              21.022677218952506
            ],
            [
              105.83689674116539,
              21.022663062131606
            ],
            [
              105.83684773713763,
              21.022561071902178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.12993",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02277284080926,
          "to_latitude": 21.022561071902178,
          "to_longitude": 105.83684773713763,
          "from_longitude": 105.83694116256491
        }
      },
      {
        "id": 12925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83285539774135,
            21.020238824120376,
            105.83304171934775,
            21.020264194246558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83285539774135,
              21.020264194246558
            ],
            [
              105.83295182680057,
              21.02023952920696
            ],
            [
              105.83298205468587,
              21.02023970453631
            ],
            [
              105.83301539155393,
              21.020238824120376
            ],
            [
              105.83303465500465,
              21.020249919828014
            ],
            [
              105.8330412366901,
              21.020239859998018
            ],
            [
              105.83304171934775,
              21.020239122409613
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.9_Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12994",
          "diaChiLapD": "Ngách 49, Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020264194246558,
          "to_latitude": 21.020239122409613,
          "to_longitude": 105.83304171934775,
          "from_longitude": 105.83285539774135
        }
      },
      {
        "id": 12926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83331897737267,
            21.022916964116586,
            105.83339174947493,
            21.022946741399014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339174947493,
              21.022916964116586
            ],
            [
              105.83339166981312,
              21.02291699702299
            ],
            [
              105.83331897737267,
              21.022946741399014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.12995",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022916964116586,
          "to_latitude": 21.022946741399014,
          "to_longitude": 105.83331897737267,
          "from_longitude": 105.83339174947493
        }
      },
      {
        "id": 12927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341573558093,
            21.022649604585343,
            105.83345478775938,
            21.022746409231427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83345478775938,
              21.022746409231427
            ],
            [
              105.83341573558093,
              21.022668784230024
            ],
            [
              105.83345032381263,
              21.022649604585343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.12996",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022746409231427,
          "to_latitude": 21.022649604585343,
          "to_longitude": 105.83345032381263,
          "from_longitude": 105.83345478775938
        }
      },
      {
        "id": 12928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397442841174,
            21.022585277915848,
            105.83422632763867,
            21.02268118010125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83422632763867,
              21.022599296803378
            ],
            [
              105.8342241581405,
              21.02260029286167
            ],
            [
              105.83422185726936,
              21.02260153704763
            ],
            [
              105.83421000309866,
              21.022585342578182
            ],
            [
              105.83420992770438,
              21.022585277915848
            ],
            [
              105.83413917322282,
              21.02261229081235
            ],
            [
              105.83407791580389,
              21.022636263823006
            ],
            [
              105.8340700968281,
              21.022647160724972
            ],
            [
              105.83405751335748,
              21.02264460488971
            ],
            [
              105.83397442841174,
              21.02268118010125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12997",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022599296803378,
          "to_latitude": 21.02268118010125,
          "to_longitude": 105.83397442841174,
          "from_longitude": 105.83422632763867
        }
      },
      {
        "id": 12929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343914200098,
            21.022962491615598,
            105.83443591497809,
            21.02305416551106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8343914200098,
              21.022962491615598
            ],
            [
              105.83443591497809,
              21.02305416551106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.12998",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022962491615598,
          "to_latitude": 21.02305416551106,
          "to_longitude": 105.83443591497809,
          "from_longitude": 105.8343914200098
        }
      },
      {
        "id": 12930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83293383089853,
            21.02468803431418,
            105.83316692823793,
            21.024777937534036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315879783434,
              21.024703056640487
            ],
            [
              105.83316094969192,
              21.024707881056116
            ],
            [
              105.83316692823793,
              21.024704699189137
            ],
            [
              105.83315980359164,
              21.02468803431418
            ],
            [
              105.83293383089853,
              21.024777937534036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.12999",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": 28.793,
          "from_latitude": 21.024703056640487,
          "to_latitude": 21.024777937534036,
          "to_longitude": 105.83293383089853,
          "from_longitude": 105.83315879783434
        }
      },
      {
        "id": 12931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396645962269,
            21.024149187254157,
            105.83423371599768,
            21.024408642047852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83423371599768,
              21.024149187254157
            ],
            [
              105.83421091766826,
              21.024183057070086
            ],
            [
              105.8341783212358,
              21.02422311415347
            ],
            [
              105.83411845498165,
              21.024287859116047
            ],
            [
              105.83410355295459,
              21.024303975262995
            ],
            [
              105.83409049960241,
              21.024316930091217
            ],
            [
              105.83408245941577,
              21.024324910666255
            ],
            [
              105.83397782227976,
              21.02440044105715
            ],
            [
              105.83396645962269,
              21.024408642047852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.13_Văn Hương",
          "maTaiSan": "04.O14.DODV.13000",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024149187254157,
          "to_latitude": 21.024408642047852,
          "to_longitude": 105.83396645962269,
          "from_longitude": 105.83423371599768
        }
      },
      {
        "id": 12932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397782227976,
            21.02440044105715,
            105.83398341869736,
            21.024407276308157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398341869736,
              21.024407276308157
            ],
            [
              105.83397782227976,
              21.02440044105715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.13_Văn Hương",
          "maTaiSan": "04.O14.DODV.13001",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024407276308157,
          "to_latitude": 21.02440044105715,
          "to_longitude": 105.83397782227976,
          "from_longitude": 105.83398341869736
        }
      },
      {
        "id": 12933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415237367521,
            21.024045757045275,
            105.83420160196592,
            21.024108233642504
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420160196592,
              21.024108233642504
            ],
            [
              105.83419751851899,
              21.024096513569017
            ],
            [
              105.83415237367521,
              21.024045757045275
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.13002",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024108233642504,
          "to_latitude": 21.024045757045275,
          "to_longitude": 105.83415237367521,
          "from_longitude": 105.83420160196592
        }
      },
      {
        "id": 12934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453170649162,
            21.023877125076876,
            105.8346264620795,
            21.023925448349594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453831652011,
              21.023925448349594
            ],
            [
              105.83453393947805,
              21.023913179661967
            ],
            [
              105.83453170649162,
              21.023906920439774
            ],
            [
              105.83455143506531,
              21.023900541712173
            ],
            [
              105.83457641818467,
              21.02389246352414
            ],
            [
              105.8346264620795,
              21.023877125076876
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.13003",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023925448349594,
          "to_latitude": 21.023877125076876,
          "to_longitude": 105.8346264620795,
          "from_longitude": 105.83453831652011
        }
      },
      {
        "id": 12935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346381020599,
            21.023846491577018,
            105.83474235941127,
            21.02387296099603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83474235941127,
              21.023846491577018
            ],
            [
              105.8346381020599,
              21.02387296099603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.13004",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023846491577018,
          "to_latitude": 21.02387296099603,
          "to_longitude": 105.8346381020599,
          "from_longitude": 105.83474235941127
        }
      },
      {
        "id": 12936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473046789626,
            21.02359239524138,
            105.83480558838193,
            21.023853005399975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83480558838193,
              21.02359239524138
            ],
            [
              105.83480478183198,
              21.023666961221167
            ],
            [
              105.83473046789626,
              21.02367795691241
            ],
            [
              105.83477097951565,
              21.023834908163508
            ],
            [
              105.83480348442666,
              21.023851540449645
            ],
            [
              105.83480405246122,
              21.023853005399975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.11_Văn Hương",
          "maTaiSan": "04.O14.DODV.13005",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02359239524138,
          "to_latitude": 21.023853005399975,
          "to_longitude": 105.83480405246122,
          "from_longitude": 105.83480558838193
        }
      },
      {
        "id": 12937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490080245558,
            21.023753267854694,
            105.83506015571264,
            21.023799077702243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83490080245558,
              21.023799077702243
            ],
            [
              105.83506015571264,
              21.023753267854694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.12_Văn Hương",
          "maTaiSan": "04.O14.DODV.13006",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023799077702243,
          "to_latitude": 21.023753267854694,
          "to_longitude": 105.83506015571264,
          "from_longitude": 105.83490080245558
        }
      },
      {
        "id": 12938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83508013325337,
            21.02368700321866,
            105.83523004124467,
            21.023755746870215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83523004124467,
              21.02368700321866
            ],
            [
              105.83508013325337,
              21.02374306359549
            ],
            [
              105.83508959340145,
              21.023755663179728
            ],
            [
              105.83508965639685,
              21.023755746870215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.1_Văn Hương",
          "maTaiSan": "04.O14.DODV.13007",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02368700321866,
          "to_latitude": 21.023755746870215,
          "to_longitude": 105.83508965639685,
          "from_longitude": 105.83523004124467
        }
      },
      {
        "id": 12939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8347886026844,
            21.023799077702243,
            105.83490400686492,
            21.023831540251877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8347886026844,
              21.023831540251877
            ],
            [
              105.83483291363449,
              21.023818720071883
            ],
            [
              105.83490080245558,
              21.023799077702243
            ],
            [
              105.83490400686492,
              21.023814111484686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.12_Văn Hương",
          "maTaiSan": "04.O14.DODV.13008",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023831540251877,
          "to_latitude": 21.023814111484686,
          "to_longitude": 105.83490400686492,
          "from_longitude": 105.8347886026844
        }
      },
      {
        "id": 12940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83536071178084,
            21.022561904768363,
            105.83542473069004,
            21.022569840610036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536071178084,
              21.022569840610036
            ],
            [
              105.83542329256207,
              21.022562083444754
            ],
            [
              105.83542473069004,
              21.022561904768363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13009",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022569840610036,
          "to_latitude": 21.022561904768363,
          "to_longitude": 105.83542473069004,
          "from_longitude": 105.83536071178084
        }
      },
      {
        "id": 12941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83536799295071,
            21.022347606389204,
            105.83573453419534,
            21.02236160401798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536799295071,
              21.022347606389204
            ],
            [
              105.83554554164289,
              21.02236020314093
            ],
            [
              105.83567555255576,
              21.022361297694825
            ],
            [
              105.83573453419534,
              21.02236160401798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13010",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022347606389204,
          "to_latitude": 21.02236160401798,
          "to_longitude": 105.83573453419534,
          "from_longitude": 105.83536799295071
        }
      },
      {
        "id": 12942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83573416321943,
            21.02236160401798,
            105.83591632553194,
            21.022373575361105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83573416321943,
              21.022373575361105
            ],
            [
              105.83573422338922,
              21.02237159881204
            ],
            [
              105.83573453419534,
              21.02236160401798
            ],
            [
              105.83573712489152,
              21.02236161928814
            ],
            [
              105.83591632553194,
              21.02236251711043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13011",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022373575361105,
          "to_latitude": 21.02236251711043,
          "to_longitude": 105.83591632553194,
          "from_longitude": 105.83573416321943
        }
      },
      {
        "id": 12943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702787162481,
            21.0217704619255,
            105.83720094392226,
            21.021947819618898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83702787162481,
              21.02192841861796
            ],
            [
              105.83705251417963,
              21.021947819618898
            ],
            [
              105.83712329470885,
              21.02188203453525
            ],
            [
              105.8371649236865,
              21.021835243182768
            ],
            [
              105.8372004562807,
              21.021771337742862
            ],
            [
              105.83720094392226,
              21.0217704619255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.12_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13012",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02192841861796,
          "to_latitude": 21.0217704619255,
          "to_longitude": 105.83720094392226,
          "from_longitude": 105.83702787162481
        }
      },
      {
        "id": 12944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786282005657,
            21.021035412973678,
            105.83790128055615,
            21.021097035613213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790128055615,
              21.021097035613213
            ],
            [
              105.83790055741713,
              21.021095877630156
            ],
            [
              105.83786282005657,
              21.021035412973678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.17_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13013",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021097035613213,
          "to_latitude": 21.021035412973678,
          "to_longitude": 105.83786282005657,
          "from_longitude": 105.83790128055615
        }
      },
      {
        "id": 12945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83794738479587,
            21.02095326641468,
            105.83799995765598,
            21.021049187160067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83794738479587,
              21.02095326641468
            ],
            [
              105.83799920833991,
              21.02104782066177
            ],
            [
              105.83799995765598,
              21.021049187160067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.17_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13014",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02095326641468,
          "to_latitude": 21.021049187160067,
          "to_longitude": 105.83799995765598,
          "from_longitude": 105.83794738479587
        }
      },
      {
        "id": 12946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650207934951,
            21.019151144084674,
            105.83661916984528,
            21.019197725631486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650207934951,
              21.019197725631486
            ],
            [
              105.8365889679816,
              21.01919301072596
            ],
            [
              105.83661731218197,
              21.019191472231206
            ],
            [
              105.83661898953092,
              21.019155033350255
            ],
            [
              105.83661916984528,
              21.019151144084674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_114.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13015",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019197725631486,
          "to_latitude": 21.019151144084674,
          "to_longitude": 105.83661916984528,
          "from_longitude": 105.83650207934951
        }
      },
      {
        "id": 12947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837513766606,
            21.019355028844746,
            105.83848002575048,
            21.019439514661766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837513766606,
              21.019358107254376
            ],
            [
              105.8384180652291,
              21.019355028844746
            ],
            [
              105.8384689885415,
              21.019439514661766
            ],
            [
              105.83847001023875,
              21.01943901556238
            ],
            [
              105.83848002575048,
              21.019434467237573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.2_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13016",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019358107254376,
          "to_latitude": 21.019434467237573,
          "to_longitude": 105.83848002575048,
          "from_longitude": 105.83837513766606
        }
      },
      {
        "id": 12948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825015613525,
            21.019439514661766,
            105.83847440754393,
            21.019599756526095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8384689885415,
              21.019439514661766
            ],
            [
              105.83847440754393,
              21.019450920050566
            ],
            [
              105.83847020659209,
              21.019470693352925
            ],
            [
              105.83845352390732,
              21.01949144675744
            ],
            [
              105.8383901683504,
              21.019540510520248
            ],
            [
              105.83837240312704,
              21.019549679088474
            ],
            [
              105.83835509763354,
              21.0195498845085
            ],
            [
              105.83830979562988,
              21.019573049651907
            ],
            [
              105.83827200189089,
              21.019589533670406
            ],
            [
              105.83826550455652,
              21.019591898715777
            ],
            [
              105.83825015613525,
              21.019599756526095
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.2_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13017",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019439514661766,
          "to_latitude": 21.019599756526095,
          "to_longitude": 105.83825015613525,
          "from_longitude": 105.8384689885415
        }
      },
      {
        "id": 12949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8381989008574,
            21.01963010620695,
            105.83820972804429,
            21.01964334570312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83820741241082,
              21.019635910938916
            ],
            [
              105.83820148891272,
              21.01963010620695
            ],
            [
              105.8381989008574,
              21.019632275857738
            ],
            [
              105.83820428668695,
              21.019637920658983
            ],
            [
              105.83820758824596,
              21.01964138180067
            ],
            [
              105.83820972804429,
              21.01964334570312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.6_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13018",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.91,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019635910938916,
          "to_latitude": 21.01964334570312,
          "to_longitude": 105.83820972804429,
          "from_longitude": 105.83820741241082
        }
      },
      {
        "id": 12950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83800996231135,
            21.019807063757668,
            105.8380317357809,
            21.019820218519943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8380317357809,
              21.019820218519943
            ],
            [
              105.83801558665853,
              21.01981046197598
            ],
            [
              105.83801102213633,
              21.01980770614769
            ],
            [
              105.83800996231135,
              21.019807063757668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.7_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13019",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019820218519943,
          "to_latitude": 21.019807063757668,
          "to_longitude": 105.83800996231135,
          "from_longitude": 105.8380317357809
        }
      },
      {
        "id": 12951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879913650168,
            21.019664866918298,
            105.83889995358197,
            21.01984828331417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83889995358197,
              21.01984828331417
            ],
            [
              105.83880911119213,
              21.019706503421336
            ],
            [
              105.83880857237017,
              21.019704933566388
            ],
            [
              105.83879913650168,
              21.01969031807038
            ],
            [
              105.83880203387118,
              21.01968274018994
            ],
            [
              105.83880633369905,
              21.01967325591477
            ],
            [
              105.83880099726228,
              21.019665267024905
            ],
            [
              105.83880062659301,
              21.019664866918298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.4_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13020",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01984828331417,
          "to_latitude": 21.019664866918298,
          "to_longitude": 105.83880062659301,
          "from_longitude": 105.83889995358197
        }
      },
      {
        "id": 12952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884046631873,
            21.019452148304296,
            105.83895637974595,
            21.01956960718264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83884046631873,
              21.019452148304296
            ],
            [
              105.83895104852094,
              21.01956564574506
            ],
            [
              105.83895493479079,
              21.01956804360372
            ],
            [
              105.83895637974595,
              21.01956960718264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.3_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13021",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019452148304296,
          "to_latitude": 21.01956960718264,
          "to_longitude": 105.83895637974595,
          "from_longitude": 105.83884046631873
        }
      },
      {
        "id": 12953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83890935205854,
            21.019819634326634,
            105.83911559714315,
            21.019939094913738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83911559714315,
              21.019819634326634
            ],
            [
              105.83903359857399,
              21.019871746541085
            ],
            [
              105.83890935205854,
              21.019939094913738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.6_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13022",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019819634326634,
          "to_latitude": 21.019939094913738,
          "to_longitude": 105.83890935205854,
          "from_longitude": 105.83911559714315
        }
      },
      {
        "id": 12954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941302807874,
            21.02328905602592,
            105.83952784947631,
            21.023301799108626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83952784947631,
              21.02328905602592
            ],
            [
              105.83952603839205,
              21.023289115546127
            ],
            [
              105.83951952981663,
              21.023289325607998
            ],
            [
              105.83951495569163,
              21.023289861216796
            ],
            [
              105.83941302807874,
              21.023301799108626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.32_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13023",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02328905602592,
          "to_latitude": 21.023301799108626,
          "to_longitude": 105.83941302807874,
          "from_longitude": 105.83952784947631
        }
      },
      {
        "id": 12955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393474899134,
            21.023136232221905,
            105.83944281633697,
            21.023214075458025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83944281633697,
              21.023136232221905
            ],
            [
              105.83944255807103,
              21.023136322915658
            ],
            [
              105.83943098018817,
              21.023140374110962
            ],
            [
              105.8394309389049,
              21.02314038876629
            ],
            [
              105.8393474899134,
              21.023177891462293
            ],
            [
              105.83935206950262,
              21.023214075458025
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.30_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13024",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023136232221905,
          "to_latitude": 21.023214075458025,
          "to_longitude": 105.83935206950262,
          "from_longitude": 105.83944281633697
        }
      },
      {
        "id": 12956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83946535182676,
            21.02313022836771,
            105.83955483650598,
            21.023369713393677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83946535182676,
              21.02313022836771
            ],
            [
              105.83947728179736,
              21.023150060260253
            ],
            [
              105.83952459256525,
              21.023227100169997
            ],
            [
              105.83954504584354,
              21.023279765493488
            ],
            [
              105.83955483650598,
              21.023369713393677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.31_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13025",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02313022836771,
          "to_latitude": 21.023369713393677,
          "to_longitude": 105.83955483650598,
          "from_longitude": 105.83946535182676
        }
      },
      {
        "id": 12957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941635020963,
            21.023048766475227,
            105.83946535182676,
            21.023139464056968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839444808422,
              21.023139464056968
            ],
            [
              105.83944527765604,
              21.02313925399974
            ],
            [
              105.83946535182676,
              21.02313022836771
            ],
            [
              105.83941635020963,
              21.023048766475227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.31_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13026",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023139464056968,
          "to_latitude": 21.023048766475227,
          "to_longitude": 105.83941635020963,
          "from_longitude": 105.839444808422
        }
      },
      {
        "id": 12958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393183280937,
            21.022960936732503,
            105.83932710596613,
            21.022965100553403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393183280937,
              21.022965100553403
            ],
            [
              105.83931971063743,
              21.022964444313438
            ],
            [
              105.83932443393566,
              21.022962203592172
            ],
            [
              105.83932710596613,
              21.022960936732503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.27_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13027",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022965100553403,
          "to_latitude": 21.022960936732503,
          "to_longitude": 105.83932710596613,
          "from_longitude": 105.8393183280937
        }
      },
      {
        "id": 12959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390797187747,
            21.02098420706604,
            105.83908067415952,
            21.02110233853442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908067415952,
              21.02110233853442
            ],
            [
              105.83908066251345,
              21.021100944918413
            ],
            [
              105.8390797187747,
              21.02098420706604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.11_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13028",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02110233853442,
          "to_latitude": 21.02098420706604,
          "to_longitude": 105.8390797187747,
          "from_longitude": 105.83908067415952
        }
      },
      {
        "id": 12960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900785731028,
            21.02103564324865,
            105.83906577142517,
            21.021110439898308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906577142517,
              21.021110439898308
            ],
            [
              105.83906436406188,
              21.021108720781204
            ],
            [
              105.83900785731028,
              21.02103564324865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.11_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13029",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": 10.238,
          "from_latitude": 21.021110439898308,
          "to_latitude": 21.02103564324865,
          "to_longitude": 105.83900785731028,
          "from_longitude": 105.83906577142517
        }
      },
      {
        "id": 12961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733008086017,
            21.02050299586382,
            105.83752628525706,
            21.02054257036058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752628525706,
              21.020503565135524
            ],
            [
              105.83743075679428,
              21.02050299586382
            ],
            [
              105.83739631435931,
              21.020542244301424
            ],
            [
              105.83733008086017,
              21.02054257036058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.24_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13030",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020503565135524,
          "to_latitude": 21.02054257036058,
          "to_longitude": 105.83733008086017,
          "from_longitude": 105.83752628525706
        }
      },
      {
        "id": 12962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83752522772195,
            21.020467897615323,
            105.83754135246812,
            21.020468369010338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83754135246812,
              21.020467897615323
            ],
            [
              105.83753364633772,
              21.02046810355901
            ],
            [
              105.83753360497933,
              21.02046810466587
            ],
            [
              105.83752563168153,
              21.020468357085864
            ],
            [
              105.83752522772195,
              21.020468369010338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.24_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13031",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020467897615323,
          "to_latitude": 21.020468369010338,
          "to_longitude": 105.83752522772195,
          "from_longitude": 105.83754135246812
        }
      },
      {
        "id": 12963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83567173890067,
            21.0214835207892,
            105.83583459458323,
            21.021597630874567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83583459458323,
              21.021597630874567
            ],
            [
              105.83579010646315,
              21.02157300902744
            ],
            [
              105.83574468006967,
              21.02152933646654
            ],
            [
              105.83574656844237,
              21.021526060227547
            ],
            [
              105.83574111995387,
              21.02152625680497
            ],
            [
              105.83571278824105,
              21.02151389268439
            ],
            [
              105.83569129376183,
              21.021504376250068
            ],
            [
              105.8356764286591,
              21.021490618219183
            ],
            [
              105.83567173890067,
              21.021486278689647
            ],
            [
              105.83567567243396,
              21.0214835207892
            ],
            [
              105.83567990131093,
              21.021487152649122
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13032",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021597630874567,
          "to_latitude": 21.021487152649122,
          "to_longitude": 105.83567990131093,
          "from_longitude": 105.83583459458323
        }
      },
      {
        "id": 12964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83547484457364,
            21.02148324734873,
            105.83566949016635,
            21.02149687281103
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83547484457364,
              21.021484313170944
            ],
            [
              105.8356592625198,
              21.02148324734873
            ],
            [
              105.83566900947149,
              21.021496234787065
            ],
            [
              105.83566949016635,
              21.02149687281103
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13033",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021484313170944,
          "to_latitude": 21.02149687281103,
          "to_longitude": 105.83566949016635,
          "from_longitude": 105.83547484457364
        }
      },
      {
        "id": 12965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356592625198,
            21.02140931623752,
            105.8357378487322,
            21.02148324734873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356592625198,
              21.02148324734873
            ],
            [
              105.83569244413306,
              21.021459471343675
            ],
            [
              105.83572533848822,
              21.02142040245544
            ],
            [
              105.8357378487322,
              21.02140931623752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13034",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02148324734873,
          "to_latitude": 21.02140931623752,
          "to_longitude": 105.8357378487322,
          "from_longitude": 105.8356592625198
        }
      },
      {
        "id": 12966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83715468272051,
            21.019955890320258,
            105.83718953486029,
            21.020022780439344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83715468272051,
              21.019955890320258
            ],
            [
              105.83718953486029,
              21.020022780439344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_117.7_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13035",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019955890320258,
          "to_latitude": 21.020022780439344,
          "to_longitude": 105.83718953486029,
          "from_longitude": 105.83715468272051
        }
      },
      {
        "id": 12967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411109629975,
            21.024281561801526,
            105.8341333993628,
            21.024299932373744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8341333993628,
              21.024299932373744
            ],
            [
              105.83413243851314,
              21.02429927321685
            ],
            [
              105.83412913681268,
              21.024296999738073
            ],
            [
              105.83412252917535,
              21.024291345448752
            ],
            [
              105.83411845498165,
              21.024287859116047
            ],
            [
              105.83411109629975,
              21.024281561801526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.15_Văn Hương",
          "maTaiSan": "04.O14.DODV.13036",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024299932373744,
          "to_latitude": 21.024281561801526,
          "to_longitude": 105.83411109629975,
          "from_longitude": 105.8341333993628
        }
      },
      {
        "id": 12968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708082030006,
            21.026583359878178,
            105.83709748916176,
            21.026611989480347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83708082030006,
              21.026583359878178
            ],
            [
              105.83708803139201,
              21.02659574460212
            ],
            [
              105.83709748916176,
              21.026611989480347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.2_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13037",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026583359878178,
          "to_latitude": 21.026611989480347,
          "to_longitude": 105.83709748916176,
          "from_longitude": 105.83708082030006
        }
      },
      {
        "id": 12969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83691099814422,
            21.026669842038046,
            105.83692783599768,
            21.026698762566586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83691099814422,
              21.026669842038046
            ],
            [
              105.83691802370853,
              21.026681908844672
            ],
            [
              105.83692783599768,
              21.026698762566586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.3_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13038",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026669842038046,
          "to_latitude": 21.026698762566586,
          "to_longitude": 105.83692783599768,
          "from_longitude": 105.83691099814422
        }
      },
      {
        "id": 12970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83092047857089,
            21.019250565982457,
            105.83094700112522,
            21.01929772826398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83094700112522,
              21.019250565982457
            ],
            [
              105.83094340359575,
              21.019254595680692
            ],
            [
              105.83094030129048,
              21.019258071089414
            ],
            [
              105.8309280832264,
              21.01928251423011
            ],
            [
              105.83092704713461,
              21.01928458677284
            ],
            [
              105.83092047857089,
              21.01929772826398
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13039",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019250565982457,
          "to_latitude": 21.01929772826398,
          "to_longitude": 105.83092047857089,
          "from_longitude": 105.83094700112522
        }
      },
      {
        "id": 12971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83121233080529,
            21.0209491539593,
            105.83156231285916,
            21.021098305183866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83121233080529,
              21.021098305183866
            ],
            [
              105.83122339885125,
              21.021093719618253
            ],
            [
              105.8312708674334,
              21.021074295019307
            ],
            [
              105.83135679663134,
              21.021049669533166
            ],
            [
              105.83148104523485,
              21.02099160628329
            ],
            [
              105.83156231285916,
              21.0209491539593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13040",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021098305183866,
          "to_latitude": 21.0209491539593,
          "to_longitude": 105.83156231285916,
          "from_longitude": 105.83121233080529
        }
      },
      {
        "id": 12972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793653630396,
            21.025916402161986,
            105.8379566137556,
            21.02595125163881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379566137556,
              21.02595125163881
            ],
            [
              105.83794463897797,
              21.025930466967125
            ],
            [
              105.83794453892013,
              21.02593029313812
            ],
            [
              105.83793653630396,
              21.025916402161986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.1_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13041",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02595125163881,
          "to_latitude": 21.025916402161986,
          "to_longitude": 105.83793653630396,
          "from_longitude": 105.8379566137556
        }
      },
      {
        "id": 12973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83656268347468,
            21.027750202891212,
            105.83660072097904,
            21.027839622741748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83658381465332,
              21.027824420637106
            ],
            [
              105.83659024879232,
              21.027839622741748
            ],
            [
              105.83660072097904,
              21.027834914203627
            ],
            [
              105.83659425768114,
              21.027820520627383
            ],
            [
              105.8365727890143,
              21.027772709363877
            ],
            [
              105.83656268347468,
              21.027750202891212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.1.1_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.13042",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027824420637106,
          "to_latitude": 21.027750202891212,
          "to_longitude": 105.83656268347468,
          "from_longitude": 105.83658381465332
        }
      },
      {
        "id": 12974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83621022893391,
            21.02703236118852,
            105.83623393384161,
            21.027074962559293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83621022893391,
              21.02703236118852
            ],
            [
              105.83623393384161,
              21.027074962559293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.6_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13044",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02703236118852,
          "to_latitude": 21.027074962559293,
          "to_longitude": 105.83623393384161,
          "from_longitude": 105.83621022893391
        }
      },
      {
        "id": 12975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83702577265339,
            21.02757855171559,
            105.83706366461266,
            21.027672236418674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705411223961,
              21.027648778703817
            ],
            [
              105.83706366461266,
              21.02766886000541
            ],
            [
              105.83705482332304,
              21.027672236418674
            ],
            [
              105.83704823025045,
              21.027650975392245
            ],
            [
              105.83702577265339,
              21.02757855171559
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.6_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.13045",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027648778703817,
          "to_latitude": 21.02757855171559,
          "to_longitude": 105.83702577265339,
          "from_longitude": 105.83705411223961
        }
      },
      {
        "id": 12976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84133902086138,
            21.020170781448517,
            105.8413978589888,
            21.020177031044422
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8413978589888,
              21.020170781448517
            ],
            [
              105.84138937830541,
              21.020171466492958
            ],
            [
              105.84137406979498,
              21.020172701050477
            ],
            [
              105.84133902086138,
              21.020177031044422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.5.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13046",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020170781448517,
          "to_latitude": 21.020177031044422,
          "to_longitude": 105.84133902086138,
          "from_longitude": 105.8413978589888
        }
      },
      {
        "id": 12977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8413334854022,
            21.01969539497832,
            105.84139519170489,
            21.019696880704824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84139519170489,
              21.01969539497832
            ],
            [
              105.84137276858237,
              21.01969594485738
            ],
            [
              105.84137249157558,
              21.019695951646867
            ],
            [
              105.8413334854022,
              21.019696880704824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.3_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13047",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01969539497832,
          "to_latitude": 21.019696880704824,
          "to_longitude": 105.8413334854022,
          "from_longitude": 105.84139519170489
        }
      },
      {
        "id": 12978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84133491782838,
            21.01976077747549,
            105.84139555854657,
            21.019762219997055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84139555854657,
              21.01976077747549
            ],
            [
              105.84137349408437,
              21.01976130209704
            ],
            [
              105.84133491782838,
              21.019762219997055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.4_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13048",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01976077747549,
          "to_latitude": 21.019762219997055,
          "to_longitude": 105.84133491782838,
          "from_longitude": 105.84139555854657
        }
      },
      {
        "id": 12979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84017503230693,
            21.027912027208338,
            105.84027868397152,
            21.02816471175209
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84017503230693,
              21.027912027208338
            ],
            [
              105.8401764864753,
              21.02791556065518
            ],
            [
              105.84018140788159,
              21.02793389444453
            ],
            [
              105.84018341953288,
              21.02795235841078
            ],
            [
              105.84022499377984,
              21.028071904718672
            ],
            [
              105.84024812783284,
              21.02808066727151
            ],
            [
              105.84027868397152,
              21.02816471175209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13049",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": 30.916,
          "from_latitude": 21.027912027208338,
          "to_latitude": 21.02816471175209,
          "to_longitude": 105.84027868397152,
          "from_longitude": 105.84017503230693
        }
      },
      {
        "id": 12980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83960975065591,
            21.02926263925887,
            105.83961867721447,
            21.029287016506128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83961867721447,
              21.029287016506128
            ],
            [
              105.83961722156289,
              21.02928304680608
            ],
            [
              105.83960975065591,
              21.02926263925887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13050",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029287016506128,
          "to_latitude": 21.02926263925887,
          "to_longitude": 105.83960975065591,
          "from_longitude": 105.83961867721447
        }
      },
      {
        "id": 12981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84142702043349,
            21.026583968894712,
            105.8414827700754,
            21.026584916746117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84142702043349,
              21.026584916746117
            ],
            [
              105.84144481103274,
              21.026584614655015
            ],
            [
              105.84146260162674,
              21.02658431165883
            ],
            [
              105.8414827700754,
              21.026583968894712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.8_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13051",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026584916746117,
          "to_latitude": 21.026583968894712,
          "to_longitude": 105.8414827700754,
          "from_longitude": 105.84142702043349
        }
      },
      {
        "id": 12982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83112248300498,
            21.019331450302133,
            105.83115081170496,
            21.019386489429472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83115081170496,
              21.019331450302133
            ],
            [
              105.83114851964936,
              21.019335669625665
            ],
            [
              105.83112957477054,
              21.01937055441011
            ],
            [
              105.83112910921382,
              21.019371601715903
            ],
            [
              105.83112248300498,
              21.019386489429472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_97.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13052",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019331450302133,
          "to_latitude": 21.019386489429472,
          "to_longitude": 105.83112248300498,
          "from_longitude": 105.83115081170496
        }
      },
      {
        "id": 12983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315472896227,
            21.0253014223939,
            105.83318519860791,
            21.02531245551539
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315472896227,
              21.02531245551539
            ],
            [
              105.83316022438751,
              21.025310465887685
            ],
            [
              105.83318519860791,
              21.0253014223939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.7_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13053",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02531245551539,
          "to_latitude": 21.0253014223939,
          "to_longitude": 105.83318519860791,
          "from_longitude": 105.83315472896227
        }
      },
      {
        "id": 12984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435974963005,
            21.02654290459963,
            105.83437704599541,
            21.026588787489498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83437704599541,
              21.026588787489498
            ],
            [
              105.8343755761688,
              21.02658500658185
            ],
            [
              105.83436510079112,
              21.026558028112426
            ],
            [
              105.83435974963005,
              21.02654290459963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.5_Hồ Giám",
          "maTaiSan": "04.O14.DODV.13054",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026588787489498,
          "to_latitude": 21.02654290459963,
          "to_longitude": 105.83435974963005,
          "from_longitude": 105.83437704599541
        }
      },
      {
        "id": 12985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418254614334,
            21.02596842865757,
            105.83425425017258,
            21.026030001024175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418254614334,
              21.02596842865757
            ],
            [
              105.83418418312063,
              21.025970958686123
            ],
            [
              105.83418746783597,
              21.025976223722132
            ],
            [
              105.83419935960609,
              21.025980107960752
            ],
            [
              105.83420772017992,
              21.02598283894256
            ],
            [
              105.83423907787193,
              21.02599308122246
            ],
            [
              105.83425425017258,
              21.026030001024175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13055",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02596842865757,
          "to_latitude": 21.026030001024175,
          "to_longitude": 105.83425425017258,
          "from_longitude": 105.83418254614334
        }
      },
      {
        "id": 12986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83904771666073,
            21.02656676701029,
            105.83907178833434,
            21.02665362677758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83904771666073,
              21.02656676701029
            ],
            [
              105.83905419945664,
              21.026590113137114
            ],
            [
              105.83905429763624,
              21.02659046671598
            ],
            [
              105.83907178833434,
              21.02665362677758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.10_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13056",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02656676701029,
          "to_latitude": 21.02665362677758,
          "to_longitude": 105.83907178833434,
          "from_longitude": 105.83904771666073
        }
      },
      {
        "id": 12987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84070710741085,
            21.027599220126024,
            105.84074174925,
            21.02778082233945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84070710741085,
              21.027599220126024
            ],
            [
              105.84073634663932,
              21.027752531226085
            ],
            [
              105.84074133499188,
              21.027778658460612
            ],
            [
              105.84074174925,
              21.02778082233945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.6_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13057",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027599220126024,
          "to_latitude": 21.02778082233945,
          "to_longitude": 105.84074174925,
          "from_longitude": 105.84070710741085
        }
      },
      {
        "id": 12988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83924969802959,
            21.026517714368175,
            105.8392781815647,
            21.026613164558313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924969802959,
              21.026517714368175
            ],
            [
              105.83925572747606,
              21.026537918605896
            ],
            [
              105.8392781815647,
              21.026613164558313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.8_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13058",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026517714368175,
          "to_latitude": 21.026613164558313,
          "to_longitude": 105.8392781815647,
          "from_longitude": 105.83924969802959
        }
      },
      {
        "id": 12989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84010528941005,
            21.02727500973248,
            105.84028782476324,
            21.027341156781223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84010528941005,
              21.027341156781223
            ],
            [
              105.84028782476324,
              21.02727500973248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.5_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13059",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027341156781223,
          "to_latitude": 21.02727500973248,
          "to_longitude": 105.84028782476324,
          "from_longitude": 105.84010528941005
        }
      },
      {
        "id": 12990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83387937008834,
            21.025987186055513,
            105.83405637220817,
            21.026057182283754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83405637220817,
              21.025987186055513
            ],
            [
              105.83389206370092,
              21.026052162246536
            ],
            [
              105.83387937008834,
              21.026057182283754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13060",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025987186055513,
          "to_latitude": 21.026057182283754,
          "to_longitude": 105.83387937008834,
          "from_longitude": 105.83405637220817
        }
      },
      {
        "id": 12991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366601664505,
            21.026057182283754,
            105.83387937008834,
            21.026141554309497
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83387937008834,
              21.026057182283754
            ],
            [
              105.83366601664505,
              21.026141554309497
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13061",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026057182283754,
          "to_latitude": 21.026141554309497,
          "to_longitude": 105.83366601664505,
          "from_longitude": 105.83387937008834
        }
      },
      {
        "id": 12992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83389206370092,
            21.026052162246536,
            105.83390480036667,
            21.026079266951676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83390480036667,
              21.026079266951676
            ],
            [
              105.83390449273426,
              21.026078612719832
            ],
            [
              105.8338952120897,
              21.026058862275974
            ],
            [
              105.83389206370092,
              21.026052162246536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13062",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026079266951676,
          "to_latitude": 21.026052162246536,
          "to_longitude": 105.83389206370092,
          "from_longitude": 105.83390480036667
        }
      },
      {
        "id": 12993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535658538759,
            21.025341763036828,
            105.83553452332873,
            21.025414463292922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83553452332873,
              21.025341763036828
            ],
            [
              105.83535658538759,
              21.025414463292922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13063",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025341763036828,
          "to_latitude": 21.025414463292922,
          "to_longitude": 105.83535658538759,
          "from_longitude": 105.83553452332873
        }
      },
      {
        "id": 12994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83510384530665,
            21.025414463292922,
            105.83535658538759,
            21.025567156492126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535658538759,
              21.025414463292922
            ],
            [
              105.83523173060678,
              21.025472520642165
            ],
            [
              105.83510384530665,
              21.02554022746831
            ],
            [
              105.83510843678842,
              21.02555298101751
            ],
            [
              105.83511904676973,
              21.025567156492126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13064",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025414463292922,
          "to_latitude": 21.025567156492126,
          "to_longitude": 105.83511904676973,
          "from_longitude": 105.83535658538759
        }
      },
      {
        "id": 12995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561247729436,
            21.025188728950265,
            105.8356569391007,
            21.02531984854385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83565153799441,
              21.02531984854385
            ],
            [
              105.8356528745566,
              21.02531542340751
            ],
            [
              105.8356569391007,
              21.025301979729324
            ],
            [
              105.83561247729436,
              21.025188728950265
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.6_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13065",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02531984854385,
          "to_latitude": 21.025188728950265,
          "to_longitude": 105.83561247729436,
          "from_longitude": 105.83565153799441
        }
      },
      {
        "id": 12996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558700794144,
            21.025301979729324,
            105.8356569391007,
            21.025327934238486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356569391007,
              21.025301979729324
            ],
            [
              105.83558700794144,
              21.025327934238486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.6_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13066",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025301979729324,
          "to_latitude": 21.025327934238486,
          "to_longitude": 105.83558700794144,
          "from_longitude": 105.8356569391007
        }
      },
      {
        "id": 12997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83565521944404,
            21.025235077617584,
            105.83595437288116,
            21.02532906679369
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83565521944404,
              21.02532120786988
            ],
            [
              105.83565740852072,
              21.025314226938388
            ],
            [
              105.8356594315288,
              21.025307137532863
            ],
            [
              105.83568823539986,
              21.025296385829936
            ],
            [
              105.83570319868167,
              21.02532906679369
            ],
            [
              105.83595437288116,
              21.025235077617584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.8_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13067",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02532120786988,
          "to_latitude": 21.025235077617584,
          "to_longitude": 105.83595437288116,
          "from_longitude": 105.83565521944404
        }
      },
      {
        "id": 12998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575505726638,
            21.02499965516026,
            105.83584154072476,
            21.025217184258292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584154072476,
              21.02499965516026
            ],
            [
              105.83575505726638,
              21.025031930551876
            ],
            [
              105.8358285967385,
              21.025217184258292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.7_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13068",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02499965516026,
          "to_latitude": 21.025217184258292,
          "to_longitude": 105.8358285967385,
          "from_longitude": 105.83584154072476
        }
      },
      {
        "id": 12999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83557932499814,
            21.025343565236078,
            105.83561387353981,
            21.025418866122543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557932499814,
              21.025343565236078
            ],
            [
              105.83557940080223,
              21.025343703056805
            ],
            [
              105.83558204276348,
              21.025348419353797
            ],
            [
              105.83561387353981,
              21.025418866122543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_62.5_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13069",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025343565236078,
          "to_latitude": 21.025418866122543,
          "to_longitude": 105.83561387353981,
          "from_longitude": 105.83557932499814
        }
      },
      {
        "id": 13000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83544142021007,
            21.02581788497108,
            105.83563926567669,
            21.026009656268922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83544142021007,
              21.02581788497108
            ],
            [
              105.83544468134403,
              21.025821673327346
            ],
            [
              105.83544780370639,
              21.025825590622993
            ],
            [
              105.8354739524348,
              21.025824999712018
            ],
            [
              105.83550792160547,
              21.025843973032327
            ],
            [
              105.83563926567669,
              21.026009656268922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.5_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13070",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02581788497108,
          "to_latitude": 21.026009656268922,
          "to_longitude": 105.83563926567669,
          "from_longitude": 105.83544142021007
        }
      },
      {
        "id": 13001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83528085215183,
            21.02585396819296,
            105.83538435042342,
            21.025900011674977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83528085215183,
              21.025900011674977
            ],
            [
              105.83538435042342,
              21.02585396819296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13071",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025900011674977,
          "to_latitude": 21.02585396819296,
          "to_longitude": 105.83538435042342,
          "from_longitude": 105.83528085215183
        }
      },
      {
        "id": 13002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83293558762705,
            21.024817676043796,
            105.83311820310003,
            21.025226296189707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83293558762705,
              21.024817676043796
            ],
            [
              105.83301615956803,
              21.024991435780702
            ],
            [
              105.83302315721821,
              21.02501482390659
            ],
            [
              105.83304960787784,
              21.025068419002896
            ],
            [
              105.83311820310003,
              21.025226296189707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.11_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13072",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": 49.09,
          "from_latitude": 21.024817676043796,
          "to_latitude": 21.025226296189707,
          "to_longitude": 105.83311820310003,
          "from_longitude": 105.83293558762705
        }
      },
      {
        "id": 13003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633977836999,
            21.023969851851046,
            105.83647266223937,
            21.024122348500672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634465659027,
              21.023969851851046
            ],
            [
              105.83633977836999,
              21.023973478798005
            ],
            [
              105.83634804712008,
              21.023978042769123
            ],
            [
              105.83637427298436,
              21.024009790360406
            ],
            [
              105.83643181162782,
              21.024082919582746
            ],
            [
              105.83647266223937,
              21.024122348500672
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.13073",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023969851851046,
          "to_latitude": 21.024122348500672,
          "to_longitude": 105.83647266223937,
          "from_longitude": 105.83634465659027
        }
      },
      {
        "id": 13004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647266223937,
            21.024122348500672,
            105.83654249108194,
            21.024196632069717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647266223937,
              21.024122348500672
            ],
            [
              105.83648562659069,
              21.024133312191577
            ],
            [
              105.83651932549645,
              21.024169757699596
            ],
            [
              105.83654249108194,
              21.024196632069717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.2_Linh Quang",
          "maTaiSan": "04.O14.DODV.13074",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024122348500672,
          "to_latitude": 21.024196632069717,
          "to_longitude": 105.83654249108194,
          "from_longitude": 105.83647266223937
        }
      },
      {
        "id": 13005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83656400422086,
            21.024214785341357,
            105.83670933433007,
            21.024234756985578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83656400422086,
              21.024234756985578
            ],
            [
              105.83656528297847,
              21.02421877177123
            ],
            [
              105.836627800922,
              21.02422136629803
            ],
            [
              105.83670876872654,
              21.02422186126585
            ],
            [
              105.83670933433007,
              21.024214785341357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.3_Linh Quang B",
          "maTaiSan": "04.O14.DODV.13075",
          "diaChiLapD": "Linh Quang B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024234756985578,
          "to_latitude": 21.024214785341357,
          "to_longitude": 105.83670933433007,
          "from_longitude": 105.83656400422086
        }
      },
      {
        "id": 13006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633259102207,
            21.023777276724957,
            105.83673189000409,
            21.023975227556512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633259102207,
              21.02397083427781
            ],
            [
              105.83633681557629,
              21.023975227556512
            ],
            [
              105.83633846932648,
              21.023972139432335
            ],
            [
              105.83634395516098,
              21.023968964722062
            ],
            [
              105.83636358660961,
              21.023957604986688
            ],
            [
              105.8364340422531,
              21.023919582338458
            ],
            [
              105.83652380384687,
              21.0238679073221
            ],
            [
              105.83664295831653,
              21.023810258267414
            ],
            [
              105.83673189000409,
              21.023777276724957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.13076",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02397083427781,
          "to_latitude": 21.023777276724957,
          "to_longitude": 105.83673189000409,
          "from_longitude": 105.83633259102207
        }
      },
      {
        "id": 13007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676950018108,
            21.02358959886443,
            105.83724482760603,
            21.023761812760725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676950018108,
              21.023761812760725
            ],
            [
              105.83683558618273,
              21.023739112956196
            ],
            [
              105.83689735283086,
              21.02371486096588
            ],
            [
              105.83696747985663,
              21.023678330941426
            ],
            [
              105.83719397696635,
              21.023600108895735
            ],
            [
              105.83724356337817,
              21.023590256311916
            ],
            [
              105.83724482760603,
              21.02358959886443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.6_Linh Quang",
          "maTaiSan": "04.O14.DODV.13077",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023761812760725,
          "to_latitude": 21.02358959886443,
          "to_longitude": 105.83724482760603,
          "from_longitude": 105.83676950018108
        }
      },
      {
        "id": 13008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83652156050182,
            21.026523222523565,
            105.83653648921486,
            21.026532122579095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83652156050182,
              21.026532122579095
            ],
            [
              105.83652235375018,
              21.026531532485244
            ],
            [
              105.83652869541623,
              21.026527071883873
            ],
            [
              105.83653416795322,
              21.026523222523565
            ],
            [
              105.83653648921486,
              21.026525625420355
            ],
            [
              105.83653091644106,
              21.026529613466945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.2_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13078",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026532122579095,
          "to_latitude": 21.026529613466945,
          "to_longitude": 105.83653091644106,
          "from_longitude": 105.83652156050182
        }
      },
      {
        "id": 13009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83614433139856,
            21.026087588768895,
            105.83615605546113,
            21.02609619000224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83614433139856,
              21.02609619000224
            ],
            [
              105.83615096420971,
              21.02609132424266
            ],
            [
              105.83615559558112,
              21.02608792612587
            ],
            [
              105.83615605546113,
              21.026087588768895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.6_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13079",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02609619000224,
          "to_latitude": 21.026087588768895,
          "to_longitude": 105.83615605546113,
          "from_longitude": 105.83614433139856
        }
      },
      {
        "id": 13010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610186561975,
            21.02584451963307,
            105.83610698854771,
            21.02585612434901
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83610186561975,
              21.02584451963307
            ],
            [
              105.83610422539576,
              21.025849864145236
            ],
            [
              105.83610450602119,
              21.02585050405407
            ],
            [
              105.83610698854771,
              21.02585612434901
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.9_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13080",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02584451963307,
          "to_latitude": 21.02585612434901,
          "to_longitude": 105.83610698854771,
          "from_longitude": 105.83610186561975
        }
      },
      {
        "id": 13011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83653311228827,
            21.026526039077375,
            105.83654145066511,
            21.02653212536746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83654145066511,
              21.026526039077375
            ],
            [
              105.83653875456079,
              21.026528006914795
            ],
            [
              105.83653678469871,
              21.02652944460061
            ],
            [
              105.83653577581644,
              21.026530181174184
            ],
            [
              105.83653311228827,
              21.02653212536746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.1_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13081",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026526039077375,
          "to_latitude": 21.02653212536746,
          "to_longitude": 105.83653311228827,
          "from_longitude": 105.83654145066511
        }
      },
      {
        "id": 13012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83639965445943,
            21.026300991279257,
            105.83651443081816,
            21.026372522025152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83651443081816,
              21.026333300432928
            ],
            [
              105.83648889881958,
              21.026300991279257
            ],
            [
              105.83640064570321,
              21.02637172773286
            ],
            [
              105.83639965445943,
              21.026372522025152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.4_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13082",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026333300432928,
          "to_latitude": 21.026372522025152,
          "to_longitude": 105.83639965445943,
          "from_longitude": 105.83651443081816
        }
      },
      {
        "id": 13013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629914193426,
            21.026256398581687,
            105.83630242997845,
            21.026259518226205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630242997845,
              21.026256398581687
            ],
            [
              105.83630138978518,
              21.026257385500667
            ],
            [
              105.83629940222181,
              21.02625927126949
            ],
            [
              105.83629914193426,
              21.026259518226205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.4_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13083",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026256398581687,
          "to_latitude": 21.026259518226205,
          "to_longitude": 105.83629914193426,
          "from_longitude": 105.83630242997845
        }
      },
      {
        "id": 13014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608940735532,
            21.025974054121857,
            105.83609275542955,
            21.02597572438417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83609275542955,
              21.025974054121857
            ],
            [
              105.83609252710882,
              21.025974168147275
            ],
            [
              105.83609094996187,
              21.02597495447966
            ],
            [
              105.83608940735532,
              21.02597572438417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.7_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13084",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025974054121857,
          "to_latitude": 21.02597572438417,
          "to_longitude": 105.83608940735532,
          "from_longitude": 105.83609275542955
        }
      },
      {
        "id": 13015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632529728516,
            21.02564084708354,
            105.83655740002743,
            21.02576795398212
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83655740002743,
              21.02564084708354
            ],
            [
              105.8364737506944,
              21.02568782602218
            ],
            [
              105.83647285240352,
              21.025688382309927
            ],
            [
              105.83645744049029,
              21.02569791937771
            ],
            [
              105.83645360668693,
              21.025700292034042
            ],
            [
              105.8364431567402,
              21.025706030125217
            ],
            [
              105.83643224347279,
              21.025713212942044
            ],
            [
              105.83642521758293,
              21.025716590327054
            ],
            [
              105.83641316717012,
              21.025722383255694
            ],
            [
              105.83632529728516,
              21.02576795398212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.15_Lương Sử A",
          "maTaiSan": "04.O14.DODV.13085",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02564084708354,
          "to_latitude": 21.02576795398212,
          "to_longitude": 105.83632529728516,
          "from_longitude": 105.83655740002743
        }
      },
      {
        "id": 13016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570809375584,
            21.022373070435556,
            105.83570916817284,
            21.02241917444526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83570916817284,
              21.022373070435556
            ],
            [
              105.83570910894322,
              21.022375560914288
            ],
            [
              105.83570809375584,
              21.02241917444526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13086",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022373070435556,
          "to_latitude": 21.02241917444526,
          "to_longitude": 105.83570809375584,
          "from_longitude": 105.83570916817284
        }
      },
      {
        "id": 13017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83230521433966,
            21.021192718452657,
            105.83261046789706,
            21.021433033970045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83261046789706,
              21.021192718452657
            ],
            [
              105.83250224205766,
              21.0212984024722
            ],
            [
              105.83250158469751,
              21.021298515882975
            ],
            [
              105.83242068324694,
              21.02131247103452
            ],
            [
              105.83233920728723,
              21.02138791415209
            ],
            [
              105.83230521433966,
              21.021433033970045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.4_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13087",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021192718452657,
          "to_latitude": 21.021433033970045,
          "to_longitude": 105.83230521433966,
          "from_longitude": 105.83261046789706
        }
      },
      {
        "id": 13018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83258760164071,
            21.021272665063197,
            105.83271726737712,
            21.02145360750601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83258760164071,
              21.02145360750601
            ],
            [
              105.83265025501606,
              21.021387254336798
            ],
            [
              105.83271726737712,
              21.02131628510325
            ],
            [
              105.83264562460734,
              21.021272665063197
            ],
            [
              105.83259055636483,
              21.021310418438393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13088",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02145360750601,
          "to_latitude": 21.021310418438393,
          "to_longitude": 105.83259055636483,
          "from_longitude": 105.83258760164071
        }
      },
      {
        "id": 13019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83265025501606,
            21.021387254336798,
            105.83274918869058,
            21.021426998702783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83265025501606,
              21.021387254336798
            ],
            [
              105.83265095730108,
              21.021387781994775
            ],
            [
              105.83269630920311,
              21.02142184366139
            ],
            [
              105.83270774738855,
              21.021405096982313
            ],
            [
              105.83274918869058,
              21.021426998702783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13089",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021387254336798,
          "to_latitude": 21.021426998702783,
          "to_longitude": 105.83274918869058,
          "from_longitude": 105.83265025501606
        }
      },
      {
        "id": 13020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83346399225405,
            21.02470725358293,
            105.8335288179424,
            21.024723049835668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335288179424,
              21.02470725358293
            ],
            [
              105.83346399225405,
              21.024723049835668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.6_Văn Hương",
          "maTaiSan": "04.O14.DODV.13090",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02470725358293,
          "to_latitude": 21.024723049835668,
          "to_longitude": 105.83346399225405,
          "from_longitude": 105.8335288179424
        }
      },
      {
        "id": 13021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356657609891,
            21.021056085397007,
            105.8358125878212,
            21.0210592279662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356657609891,
              21.021056085397007
            ],
            [
              105.8358125878212,
              21.0210592279662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13091",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021056085397007,
          "to_latitude": 21.0210592279662,
          "to_longitude": 105.8358125878212,
          "from_longitude": 105.8356657609891
        }
      },
      {
        "id": 13022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351416977586,
            21.024676287150413,
            105.83531935756115,
            21.024761323835055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351416977586,
              21.024761323835055
            ],
            [
              105.8353193172642,
              21.02467630631603
            ],
            [
              105.83531935756115,
              21.024676287150413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13092",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024761323835055,
          "to_latitude": 21.024676287150413,
          "to_longitude": 105.83531935756115,
          "from_longitude": 105.8351416977586
        }
      },
      {
        "id": 13023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83531935756115,
            21.02460390208285,
            105.83547488387804,
            21.024676287150413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83531935756115,
              21.024676287150413
            ],
            [
              105.83547488387804,
              21.02460390208285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13093",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024676287150413,
          "to_latitude": 21.02460390208285,
          "to_longitude": 105.83547488387804,
          "from_longitude": 105.83531935756115
        }
      },
      {
        "id": 13024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548269948562,
            21.02427530069568,
            105.83578658416253,
            21.024462594255112
          ],
          "type": "MultiLineString",
          "coordinates": [
            [
              [
                105.83578658416253,
                21.02427530069568
              ],
              [
                105.8355285683931,
                21.02444983293364
              ]
            ],
            [
              [
                105.8355285683931,
                21.02444983293364
              ],
              [
                105.83550112667005,
                21.024415669641456
              ],
              [
                105.83549032847982,
                21.024418636487997
              ],
              [
                105.83548898722036,
                21.024417557402405
              ],
              [
                105.83548601206918,
                21.024414847895954
              ],
              [
                105.83548269948562,
                21.02441183385431
              ]
            ],
            [
              [
                105.8355285683931,
                21.02444983293364
              ],
              [
                105.8355087782011,
                21.024462594255112
              ]
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.4_Linh Quang",
          "maTaiSan": "04.O14.DODV.13094",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": [
            105.8355285683931,
            21.02444983293364
          ],
          "to_latitude": [
            105.8355087782011,
            21.024462594255112
          ],
          "to_longitude": [
            105.8355285683931,
            21.02444983293364
          ],
          "from_longitude": [
            105.83578658416253,
            21.02427530069568
          ]
        }
      },
      {
        "id": 13025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83577735080941,
            21.024523092156098,
            105.83583965150069,
            21.024550517616316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577735080941,
              21.024550517616316
            ],
            [
              105.83583965150069,
              21.024523092156098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.5_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13095",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024550517616316,
          "to_latitude": 21.024523092156098,
          "to_longitude": 105.83583965150069,
          "from_longitude": 105.83577735080941
        }
      },
      {
        "id": 13026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83544257386887,
            21.024249656359682,
            105.83570847081374,
            21.02444827190934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83544257386887,
              21.02444827190934
            ],
            [
              105.83548402062685,
              21.024416380513884
            ],
            [
              105.83548601206918,
              21.024414847895954
            ],
            [
              105.83550559412791,
              21.02439978167004
            ],
            [
              105.83563345161429,
              21.024301400329104
            ],
            [
              105.83570847081374,
              21.024249656359682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.13096",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02444827190934,
          "to_latitude": 21.024249656359682,
          "to_longitude": 105.83570847081374,
          "from_longitude": 105.83544257386887
        }
      },
      {
        "id": 13027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83298009556512,
            21.024635144836935,
            105.83339818490127,
            21.024780356137175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83298009556512,
              21.024780356137175
            ],
            [
              105.83309978915074,
              21.024735916486538
            ],
            [
              105.83322677072468,
              21.02468319412514
            ],
            [
              105.8332813923878,
              21.024671534105014
            ],
            [
              105.83334387016306,
              21.024654235124192
            ],
            [
              105.83339818490127,
              21.024635144836935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.13097",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 46.436,
          "from_latitude": 21.024780356137175,
          "to_latitude": 21.024635144836935,
          "to_longitude": 105.83339818490127,
          "from_longitude": 105.83298009556512
        }
      },
      {
        "id": 13028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83400021614031,
            21.024325740459428,
            105.83418526791854,
            21.024442188790918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83400021614031,
              21.02440746400106
            ],
            [
              105.83400050353958,
              21.024407248527666
            ],
            [
              105.83410940270856,
              21.024325740459428
            ],
            [
              105.83418526791854,
              21.02441851331338
            ],
            [
              105.83414950671823,
              21.024442188790918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.14_Văn Hương",
          "maTaiSan": "04.O14.DODV.13098",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02440746400106,
          "to_latitude": 21.024442188790918,
          "to_longitude": 105.83414950671823,
          "from_longitude": 105.83400021614031
        }
      },
      {
        "id": 13029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83414571452825,
            21.024201893567515,
            105.83421685791784,
            21.02429106734719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421685791784,
              21.024201893567515
            ],
            [
              105.8341811646966,
              21.02424866956813
            ],
            [
              105.83414571452825,
              21.02429106734719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.16_Văn Hương",
          "maTaiSan": "04.O14.DODV.13099",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024201893567515,
          "to_latitude": 21.02429106734719,
          "to_longitude": 105.83414571452825,
          "from_longitude": 105.83421685791784
        }
      },
      {
        "id": 13030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83428276216301,
            21.02422974816113,
            105.83436781839715,
            21.024313549286333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428276216301,
              21.02422974816113
            ],
            [
              105.83430890685045,
              21.024251644115008
            ],
            [
              105.83431380531869,
              21.02426236302801
            ],
            [
              105.83436781839715,
              21.024313549286333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.10_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.13100",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.97,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02422974816113,
          "to_latitude": 21.024313549286333,
          "to_longitude": 105.83436781839715,
          "from_longitude": 105.83428276216301
        }
      },
      {
        "id": 13031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83477697996294,
            21.024644281817515,
            105.83493204842762,
            21.02475583103491
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83493204842762,
              21.02475583103491
            ],
            [
              105.83488112135161,
              21.024660642830117
            ],
            [
              105.83479337897431,
              21.024674669765126
            ],
            [
              105.83478946560058,
              21.024667417120778
            ],
            [
              105.83477697996294,
              21.024644281817515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.5_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.13101",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02475583103491,
          "to_latitude": 21.024644281817515,
          "to_longitude": 105.83477697996294,
          "from_longitude": 105.83493204842762
        }
      },
      {
        "id": 13032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83476889436123,
            21.024644281817515,
            105.83477697996294,
            21.024647039304917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83477697996294,
              21.024644281817515
            ],
            [
              105.83477135816004,
              21.024646198955537
            ],
            [
              105.83476889436123,
              21.024647039304917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.5_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.13102",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024644281817515,
          "to_latitude": 21.024647039304917,
          "to_longitude": 105.83476889436123,
          "from_longitude": 105.83477697996294
        }
      },
      {
        "id": 13033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83472309837451,
            21.024912544801037,
            105.83479715047346,
            21.025112199854057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83475781094509,
              21.024912544801037
            ],
            [
              105.83472309837451,
              21.024926039534588
            ],
            [
              105.83477153161974,
              21.025045528610217
            ],
            [
              105.83479150251216,
              21.025097501420845
            ],
            [
              105.83479715047346,
              21.025112199854057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13103",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024912544801037,
          "to_latitude": 21.025112199854057,
          "to_longitude": 105.83479715047346,
          "from_longitude": 105.83475781094509
        }
      },
      {
        "id": 13034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8342830374189,
            21.026119763061473,
            105.83437703412594,
            21.026373888098124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83437703412594,
              21.026373888098124
            ],
            [
              105.83436362604435,
              21.02634387654046
            ],
            [
              105.83432184653876,
              21.02621707203421
            ],
            [
              105.8342830374189,
              21.026119763061473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.8_Hồ Giám",
          "maTaiSan": "04.O14.DODV.13104",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026373888098124,
          "to_latitude": 21.026119763061473,
          "to_longitude": 105.8342830374189,
          "from_longitude": 105.83437703412594
        }
      },
      {
        "id": 13035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439752586114,
            21.026298664951664,
            105.83460987282658,
            21.02648814287606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460987282658,
              21.026298664951664
            ],
            [
              105.83456561634922,
              21.02635026201427
            ],
            [
              105.83460406371505,
              21.026402593063324
            ],
            [
              105.8345050613969,
              21.026449875825815
            ],
            [
              105.83442643106784,
              21.026480329971314
            ],
            [
              105.83442008556186,
              21.02648221090859
            ],
            [
              105.83440159552482,
              21.026487692070344
            ],
            [
              105.83439752586114,
              21.02648814287606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.7_Hồ Giám",
          "maTaiSan": "04.O14.DODV.13105",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026298664951664,
          "to_latitude": 21.02648814287606,
          "to_longitude": 105.83439752586114,
          "from_longitude": 105.83460987282658
        }
      },
      {
        "id": 13036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83478545675288,
            21.026052200623262,
            105.8349204442235,
            21.026098561073113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349204442235,
              21.026062644682863
            ],
            [
              105.83487383397814,
              21.02608730841615
            ],
            [
              105.83484981348337,
              21.026052200623262
            ],
            [
              105.83478545675288,
              21.026098561073113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.6_Ngách 23, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13106",
          "diaChiLapD": "Ngách 23, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026062644682863,
          "to_latitude": 21.026098561073113,
          "to_longitude": 105.83478545675288,
          "from_longitude": 105.8349204442235
        }
      },
      {
        "id": 13037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498378826178,
            21.02566503548927,
            105.83512513695221,
            21.025788928318235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83498378826178,
              21.02566503548927
            ],
            [
              105.83511100269834,
              21.025788928318235
            ],
            [
              105.83512513695221,
              21.02577382565872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.1_Ngách 41, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13107",
          "diaChiLapD": "Ngách 41, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02566503548927,
          "to_latitude": 21.02577382565872,
          "to_longitude": 105.83512513695221,
          "from_longitude": 105.83498378826178
        }
      },
      {
        "id": 13038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83511100269834,
            21.025788928318235,
            105.83522073207774,
            21.025891944007345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83511100269834,
              21.025788928318235
            ],
            [
              105.83522073207774,
              21.025891944007345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.1_Ngách 41, Ngõ Thông Phong, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13108",
          "diaChiLapD": "Ngách 41, Ngõ Thông Phong, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025788928318235,
          "to_latitude": 21.025891944007345,
          "to_longitude": 105.83522073207774,
          "from_longitude": 105.83511100269834
        }
      },
      {
        "id": 13039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83523951383863,
            21.02589266130562,
            105.83535044952532,
            21.02613970233786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83525307089756,
              21.02589266130562
            ],
            [
              105.83523951383863,
              21.0259084530098
            ],
            [
              105.83529919877888,
              21.026006934565807
            ],
            [
              105.83535044952532,
              21.02613970233786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_61.2_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13109",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02589266130562,
          "to_latitude": 21.02613970233786,
          "to_longitude": 105.83535044952532,
          "from_longitude": 105.83525307089756
        }
      },
      {
        "id": 13040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359895328721,
            21.02561749576716,
            105.83363133107075,
            21.025670210870466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363133107075,
              21.025670210870466
            ],
            [
              105.83359895328721,
              21.02561749576716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.4_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13110",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025670210870466,
          "to_latitude": 21.02561749576716,
          "to_longitude": 105.83359895328721,
          "from_longitude": 105.83363133107075
        }
      },
      {
        "id": 13041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363552022439,
            21.025321955531613,
            105.83390331420185,
            21.025556310986993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83384860334866,
              21.025321955531613
            ],
            [
              105.83390331420185,
              21.025453404379665
            ],
            [
              105.83363552022439,
              21.025556310986993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.8_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13111",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025321955531613,
          "to_latitude": 21.025556310986993,
          "to_longitude": 105.83363552022439,
          "from_longitude": 105.83384860334866
        }
      },
      {
        "id": 13042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83917385120802,
            21.02730400895355,
            105.83925613152107,
            21.02734793477349
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917385120802,
              21.027321729184838
            ],
            [
              105.83924191720035,
              21.02730400895355
            ],
            [
              105.83925613152107,
              21.02734793477349
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_32.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13112",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027321729184838,
          "to_latitude": 21.02734793477349,
          "to_longitude": 105.83925613152107,
          "from_longitude": 105.83917385120802
        }
      },
      {
        "id": 13043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83934978449456,
            21.027394366953427,
            105.83936723992528,
            21.027455139026248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936723992528,
              21.027455139026248
            ],
            [
              105.83934978449456,
              21.027394366953427
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.12_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13113",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027455139026248,
          "to_latitude": 21.027394366953427,
          "to_longitude": 105.83934978449456,
          "from_longitude": 105.83936723992528
        }
      },
      {
        "id": 13044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697190280627,
            21.024876609172317,
            105.83712976400103,
            21.0250832881133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712976400103,
              21.0250832881133
            ],
            [
              105.83709153134826,
              21.02492150400091
            ],
            [
              105.8370569533615,
              21.0249075459768
            ],
            [
              105.83698033920705,
              21.02490567673085
            ],
            [
              105.8369795697489,
              21.024893467133477
            ],
            [
              105.83697851632475,
              21.024876745527177
            ],
            [
              105.83697190280627,
              21.024876609172317
            ],
            [
              105.83697218930436,
              21.024893464617154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.3A_Lương Sử C",
          "maTaiSan": "04.O14.DODV.13114",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0250832881133,
          "to_latitude": 21.024893464617154,
          "to_longitude": 105.83697218930436,
          "from_longitude": 105.83712976400103
        }
      },
      {
        "id": 13045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663751365962,
            21.02490567673085,
            105.83698033920705,
            21.025018401336514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698033920705,
              21.02490567673085
            ],
            [
              105.83688420400797,
              21.024927376477443
            ],
            [
              105.83685229705914,
              21.02493850132472
            ],
            [
              105.83673018912164,
              21.024990924571785
            ],
            [
              105.83663751365962,
              21.025018401336514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.3A_Lương Sử C",
          "maTaiSan": "04.O14.DODV.13115",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02490567673085,
          "to_latitude": 21.025018401336514,
          "to_longitude": 105.83663751365962,
          "from_longitude": 105.83698033920705
        }
      },
      {
        "id": 13046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83689770232586,
            21.024759278919646,
            105.8369884989536,
            21.024816742730593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369884989536,
              21.024811611036462
            ],
            [
              105.83692911959261,
              21.024816742730593
            ],
            [
              105.8369079883994,
              21.024799335929924
            ],
            [
              105.83689770232586,
              21.024759278919646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_23.2_Linh Quang A",
          "maTaiSan": "04.O14.DODV.13116",
          "diaChiLapD": "Linh Quang A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024811611036462,
          "to_latitude": 21.024759278919646,
          "to_longitude": 105.83689770232586,
          "from_longitude": 105.8369884989536
        }
      },
      {
        "id": 13047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83711901641041,
            21.024691496476134,
            105.83712124448799,
            21.024859631602975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712124448799,
              21.024691496476134
            ],
            [
              105.83711901641041,
              21.024859631602975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.13117",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024691496476134,
          "to_latitude": 21.024859631602975,
          "to_longitude": 105.83711901641041,
          "from_longitude": 105.83712124448799
        }
      },
      {
        "id": 13048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712124448799,
            21.024566895950432,
            105.83720234322537,
            21.024691496476134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712124448799,
              21.024691496476134
            ],
            [
              105.83712259588859,
              21.02466716782967
            ],
            [
              105.83713417790878,
              21.02464539010804
            ],
            [
              105.83714859921493,
              21.024624208988516
            ],
            [
              105.83714935141651,
              21.024623333675496
            ],
            [
              105.83716365092076,
              21.024606699076713
            ],
            [
              105.83720234322537,
              21.024566895950432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.13118",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024691496476134,
          "to_latitude": 21.024566895950432,
          "to_longitude": 105.83720234322537,
          "from_longitude": 105.83712124448799
        }
      },
      {
        "id": 13049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372756676652,
            21.024246406436564,
            105.83734153919697,
            21.02444809803247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83734153919697,
              21.024246406436564
            ],
            [
              105.83733700786313,
              21.02428458179807
            ],
            [
              105.83731879453026,
              21.02434126201632
            ],
            [
              105.83729593676514,
              21.02440484675584
            ],
            [
              105.8372756676652,
              21.02444809803247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.19_Linh Quang",
          "maTaiSan": "04.O14.DODV.13119",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024246406436564,
          "to_latitude": 21.02444809803247,
          "to_longitude": 105.8372756676652,
          "from_longitude": 105.83734153919697
        }
      },
      {
        "id": 13050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716779690143,
            21.02444809803247,
            105.8372756676652,
            21.024551688211055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8372756676652,
              21.02444809803247
            ],
            [
              105.83725746666825,
              21.02449097422336
            ],
            [
              105.8372454441007,
              21.02451017090334
            ],
            [
              105.83721944142921,
              21.024551688211055
            ],
            [
              105.83716779690143,
              21.02452861850535
            ],
            [
              105.8371716645609,
              21.024465299777237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.19_Linh Quang",
          "maTaiSan": "04.O14.DODV.13120",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02444809803247,
          "to_latitude": 21.024465299777237,
          "to_longitude": 105.8371716645609,
          "from_longitude": 105.8372756676652
        }
      },
      {
        "id": 13051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84023061619936,
            21.027461789770392,
            105.8402943073483,
            21.027589709460745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8402943073483,
              21.02758384633289
            ],
            [
              105.840287564345,
              21.027586717577645
            ],
            [
              105.84028053533801,
              21.027589709460745
            ],
            [
              105.84023061619936,
              21.027461789770392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13121",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02758384633289,
          "to_latitude": 21.027461789770392,
          "to_longitude": 105.84023061619936,
          "from_longitude": 105.8402943073483
        }
      },
      {
        "id": 13052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83787990471075,
            21.02919335081334,
            105.83805553679248,
            21.029244218548307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83787990471075,
              21.0292118146244
            ],
            [
              105.83788962771605,
              21.02920868516486
            ],
            [
              105.8379023682163,
              21.029204929813684
            ],
            [
              105.83791644981144,
              21.02920086151619
            ],
            [
              105.83793623110027,
              21.029196793219413
            ],
            [
              105.83797411730485,
              21.02919335081334
            ],
            [
              105.83798920472564,
              21.029195541435314
            ],
            [
              105.8380535776845,
              21.02920148740695
            ],
            [
              105.83804620160872,
              21.02924217037512
            ],
            [
              105.83805071424885,
              21.029244218548307
            ],
            [
              105.83805331555992,
              21.029239988105463
            ],
            [
              105.83805553679248,
              21.029236360654732
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.13_Thanh Miến",
          "maTaiSan": "04.O14.DODV.13122",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": 24.455,
          "from_latitude": 21.0292118146244,
          "to_latitude": 21.029236360654732,
          "to_longitude": 105.83805553679248,
          "from_longitude": 105.83787990471075
        }
      },
      {
        "id": 13053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591077274403,
            21.027190054024143,
            105.83593447758983,
            21.027232654534718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591077274403,
              21.027190054024143
            ],
            [
              105.83593447758983,
              21.027232654534718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.7_Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13123",
          "diaChiLapD": "Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027190054024143,
          "to_latitude": 21.027232654534718,
          "to_longitude": 105.83593447758983,
          "from_longitude": 105.83591077274403
        }
      },
      {
        "id": 13054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83823042954928,
            21.02772521281444,
            105.83841186878689,
            21.02796468721979
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83841186878689,
              21.027952184257423
            ],
            [
              105.8383619264401,
              21.02796468721979
            ],
            [
              105.83832621587824,
              21.027872386822253
            ],
            [
              105.83828406484176,
              21.02787060030306
            ],
            [
              105.83823987661624,
              21.02774656178566
            ],
            [
              105.83823095668139,
              21.027726407889336
            ],
            [
              105.83823042954928,
              21.02772521281444
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.2_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13124",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027952184257423,
          "to_latitude": 21.02772521281444,
          "to_longitude": 105.83823042954928,
          "from_longitude": 105.83841186878689
        }
      },
      {
        "id": 13055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83811465930056,
            21.02774656178566,
            105.83823987661624,
            21.027785159501022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823987661624,
              21.02774656178566
            ],
            [
              105.83811465930056,
              21.027785159501022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.2_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13125",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02774656178566,
          "to_latitude": 21.027785159501022,
          "to_longitude": 105.83811465930056,
          "from_longitude": 105.83823987661624
        }
      },
      {
        "id": 13056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459206616507,
            21.02576389519843,
            105.83470281391742,
            21.025839143797597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459979203542,
              21.025831603361382
            ],
            [
              105.83460241101453,
              21.025837379267927
            ],
            [
              105.83459898937636,
              21.025839143797597
            ],
            [
              105.83459542980651,
              21.02583393069917
            ],
            [
              105.83459206616507,
              21.025829005670666
            ],
            [
              105.83470281391742,
              21.02576389519843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_63.7_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13126",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025831603361382,
          "to_latitude": 21.02576389519843,
          "to_longitude": 105.83470281391742,
          "from_longitude": 105.83459979203542
        }
      },
      {
        "id": 13057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83305880179101,
            21.022683159927787,
            105.8331359828499,
            21.022723287962567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8331359828499,
              21.02271921884309
            ],
            [
              105.83313211485286,
              21.02271803108276
            ],
            [
              105.83312039813372,
              21.022711839068084
            ],
            [
              105.83309613417487,
              21.022723287962567
            ],
            [
              105.83305880179101,
              21.022683159927787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13127",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02271921884309,
          "to_latitude": 21.022683159927787,
          "to_longitude": 105.83305880179101,
          "from_longitude": 105.8331359828499
        }
      },
      {
        "id": 13058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300178103785,
            21.022406577312253,
            105.83314824249207,
            21.022579226107517
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314824249207,
              21.022406577312253
            ],
            [
              105.83308590279267,
              21.0224998126896
            ],
            [
              105.83305527760542,
              21.022532270115843
            ],
            [
              105.83300395605296,
              21.02257731687798
            ],
            [
              105.83300178103785,
              21.022579226107517
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13128",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022406577312253,
          "to_latitude": 21.022579226107517,
          "to_longitude": 105.83300178103785,
          "from_longitude": 105.83314824249207
        }
      },
      {
        "id": 13059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83213593499744,
            21.022308283654308,
            105.83218551614257,
            21.022337145532195
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83213593499744,
              21.022308283654308
            ],
            [
              105.8321556377982,
              21.022337145532195
            ],
            [
              105.83218551614257,
              21.02232088943837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.3_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13129",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022308283654308,
          "to_latitude": 21.02232088943837,
          "to_longitude": 105.83218551614257,
          "from_longitude": 105.83213593499744
        }
      },
      {
        "id": 13060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190553075028,
            21.02239967401015,
            105.83207103187627,
            21.022548866355006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83204429695925,
              21.02239967401015
            ],
            [
              105.83207103187627,
              21.022459746735187
            ],
            [
              105.83206715499306,
              21.022471516649528
            ],
            [
              105.83205439763067,
              21.022502277847003
            ],
            [
              105.8319944473779,
              21.022523850176434
            ],
            [
              105.8319157784849,
              21.022547606813998
            ],
            [
              105.83190553075028,
              21.022548866355006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13130",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02239967401015,
          "to_latitude": 21.022548866355006,
          "to_longitude": 105.83190553075028,
          "from_longitude": 105.83204429695925
        }
      },
      {
        "id": 13061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194981712829,
            21.02169320539555,
            105.83202748340328,
            21.02175524391317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202748340328,
              21.02169320539555
            ],
            [
              105.83201044092733,
              21.021708962414607
            ],
            [
              105.83195599953713,
              21.02175052413214
            ],
            [
              105.83194981712829,
              21.02175524391317
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.2_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13131",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02169320539555,
          "to_latitude": 21.02175524391317,
          "to_longitude": 105.83194981712829,
          "from_longitude": 105.83202748340328
        }
      },
      {
        "id": 13062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83204546641736,
            21.021608607985616,
            105.83212064306124,
            21.02167479913292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83204546641736,
              21.02167479913292
            ],
            [
              105.83208531387555,
              21.02164143057638
            ],
            [
              105.83208687368908,
              21.02164012501224
            ],
            [
              105.83212064306124,
              21.021608607985616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13132",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02167479913292,
          "to_latitude": 21.021608607985616,
          "to_longitude": 105.83212064306124,
          "from_longitude": 105.83204546641736
        }
      },
      {
        "id": 13063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8318112506707,
            21.02126075501303,
            105.83196077750274,
            21.021338538297734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318112506707,
              21.02126075501303
            ],
            [
              105.83189331355565,
              21.021304074255617
            ],
            [
              105.83189647457695,
              21.021313953619384
            ],
            [
              105.83190808487198,
              21.021313475931876
            ],
            [
              105.83192100378703,
              21.021319647704058
            ],
            [
              105.83196077750274,
              21.021338538297734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.12_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13133",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02126075501303,
          "to_latitude": 21.021338538297734,
          "to_longitude": 105.83196077750274,
          "from_longitude": 105.8318112506707
        }
      },
      {
        "id": 13064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83453779839724,
            21.020429240991607,
            105.83454975347559,
            21.02043187925451
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83453779839724,
              21.02043187925451
            ],
            [
              105.83453998130884,
              21.020431397964845
            ],
            [
              105.83454306255467,
              21.02043071807452
            ],
            [
              105.8345450244811,
              21.020430284836667
            ],
            [
              105.83454975347559,
              21.020429240991607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13134",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02043187925451,
          "to_latitude": 21.020429240991607,
          "to_longitude": 105.83454975347559,
          "from_longitude": 105.83453779839724
        }
      },
      {
        "id": 13065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83432528481947,
            21.02145428393632,
            105.83438106346601,
            21.02160685388881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83432528481947,
              21.02145428393632
            ],
            [
              105.8343456980192,
              21.02149648810788
            ],
            [
              105.83435515882475,
              21.02152601325355
            ],
            [
              105.83438106346601,
              21.02160685388881
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13135",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02145428393632,
          "to_latitude": 21.02160685388881,
          "to_longitude": 105.83438106346601,
          "from_longitude": 105.83432528481947
        }
      },
      {
        "id": 13066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438106346601,
            21.02160685388881,
            105.83441010747228,
            21.021701597799325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438106346601,
              21.02160685388881
            ],
            [
              105.83439597620362,
              21.02164820710153
            ],
            [
              105.8343963378051,
              21.02164957190554
            ],
            [
              105.83441010747228,
              21.021701597799325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13136",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02160685388881,
          "to_latitude": 21.021701597799325,
          "to_longitude": 105.83441010747228,
          "from_longitude": 105.83438106346601
        }
      },
      {
        "id": 13067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462927539776,
            21.021177003232747,
            105.83479911407056,
            21.02122045622975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83462927539776,
              21.02122045622975
            ],
            [
              105.83463608416601,
              21.02121871482291
            ],
            [
              105.83479911407056,
              21.021177003232747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13137",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02122045622975,
          "to_latitude": 21.021177003232747,
          "to_longitude": 105.83479911407056,
          "from_longitude": 105.83462927539776
        }
      },
      {
        "id": 13068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83479911407056,
            21.021127957853302,
            105.83495964135776,
            21.021177003232747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83479911407056,
              21.021177003232747
            ],
            [
              105.83488061774354,
              21.021159656911998
            ],
            [
              105.83495964135776,
              21.021127957853302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_111.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13138",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021177003232747,
          "to_latitude": 21.021127957853302,
          "to_longitude": 105.83495964135776,
          "from_longitude": 105.83479911407056
        }
      },
      {
        "id": 13069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540687544728,
            21.01992768679568,
            105.83554308021655,
            21.020121631274172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540687544728,
              21.01992768679568
            ],
            [
              105.835407850317,
              21.019927931297676
            ],
            [
              105.83541742747225,
              21.019930320256602
            ],
            [
              105.8355169335627,
              21.019941465046347
            ],
            [
              105.83552788254333,
              21.01999497064064
            ],
            [
              105.83554308021655,
              21.020073281377506
            ],
            [
              105.83552756953318,
              21.020121631274172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.11_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13139",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01992768679568,
          "to_latitude": 21.020121631274172,
          "to_longitude": 105.83552756953318,
          "from_longitude": 105.83540687544728
        }
      },
      {
        "id": 13070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719209294209,
            21.01927101836226,
            105.83728166632223,
            21.019490789759736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728166632223,
              21.019490789759736
            ],
            [
              105.83726625952161,
              21.019466489392503
            ],
            [
              105.83722971785693,
              21.0193905627153
            ],
            [
              105.83719209294209,
              21.01927101836226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13140",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019490789759736,
          "to_latitude": 21.01927101836226,
          "to_longitude": 105.83719209294209,
          "from_longitude": 105.83728166632223
        }
      },
      {
        "id": 13071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83894018624352,
            21.020791184258023,
            105.83940875852373,
            21.0210079853297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83894018624352,
              21.0210079853297
            ],
            [
              105.83894134061241,
              21.021006954477656
            ],
            [
              105.83900311771619,
              21.020946494194035
            ],
            [
              105.83903309171956,
              21.020818234959428
            ],
            [
              105.83940750763728,
              21.020816387649113
            ],
            [
              105.83940875852373,
              21.020791184258023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.10_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13141",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0210079853297,
          "to_latitude": 21.020791184258023,
          "to_longitude": 105.83940875852373,
          "from_longitude": 105.83894018624352
        }
      },
      {
        "id": 13072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893876781434,
            21.020778666163565,
            105.83909762236866,
            21.02100635116902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893876781434,
              21.02100635116902
            ],
            [
              105.8389394292431,
              21.021005762618017
            ],
            [
              105.83894538622323,
              21.0209957002326
            ],
            [
              105.83894655411893,
              21.020993820283927
            ],
            [
              105.83899543903031,
              21.020941980727628
            ],
            [
              105.8390296848869,
              21.020796654792576
            ],
            [
              105.83909670211212,
              21.020797466779545
            ],
            [
              105.83909762236866,
              21.020778666163565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.9_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13142",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02100635116902,
          "to_latitude": 21.020778666163565,
          "to_longitude": 105.83909762236866,
          "from_longitude": 105.83893876781434
        }
      },
      {
        "id": 13073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83892976120694,
            21.020821889300688,
            105.83933877480499,
            21.020995981053613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892976120694,
              21.020995981053613
            ],
            [
              105.83893056177244,
              21.020995956704354
            ],
            [
              105.83898304683025,
              21.020941441326958
            ],
            [
              105.83900718670893,
              21.020824705973325
            ],
            [
              105.83901122010096,
              21.02083347084298
            ],
            [
              105.83903434407355,
              21.020836911550482
            ],
            [
              105.839039708498,
              21.020825957826887
            ],
            [
              105.83933877480499,
              21.020821889300688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.8.1_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13143",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.47,
          "chatLieu": "HDPE",
          "chieuDai": 57.304,
          "from_latitude": 21.020995981053613,
          "to_latitude": 21.020821889300688,
          "to_longitude": 105.83933877480499,
          "from_longitude": 105.83892976120694
        }
      },
      {
        "id": 13074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893192992731,
            21.0208089795545,
            105.83930516353912,
            21.02099847809606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83893192992731,
              21.02099847809606
            ],
            [
              105.83893197434571,
              21.02099740392677
            ],
            [
              105.83898765711007,
              21.020942927903036
            ],
            [
              105.83901615551568,
              21.020810544372033
            ],
            [
              105.83902151993117,
              21.02081085733588
            ],
            [
              105.83902463702275,
              21.020818075949883
            ],
            [
              105.83903057238904,
              21.020811483262705
            ],
            [
              105.83930516353912,
              21.0208089795545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.8.2_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13144",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": 54.357,
          "from_latitude": 21.02099847809606,
          "to_latitude": 21.0208089795545,
          "to_longitude": 105.83930516353912,
          "from_longitude": 105.83893192992731
        }
      },
      {
        "id": 13075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593211833825,
            21.02406403404005,
            105.83614086192718,
            21.02413576318552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593211833825,
              21.02412781703976
            ],
            [
              105.83593346541882,
              21.024130975313707
            ],
            [
              105.83593551037089,
              21.02413576318552
            ],
            [
              105.83614086192718,
              21.02406403404005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.13145",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02412781703976,
          "to_latitude": 21.02406403404005,
          "to_longitude": 105.83614086192718,
          "from_longitude": 105.83593211833825
        }
      },
      {
        "id": 13076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83614086192718,
            21.02398067804872,
            105.83633654199141,
            21.02406403404005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83614086192718,
              21.02406403404005
            ],
            [
              105.83623690280473,
              21.02402963947964
            ],
            [
              105.83633654199141,
              21.02398067804872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_22.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.13146",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 22.38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02406403404005,
          "to_latitude": 21.02398067804872,
          "to_longitude": 105.83633654199141,
          "from_longitude": 105.83614086192718
        }
      },
      {
        "id": 13077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83865277601573,
            21.029226866787067,
            105.8387162094838,
            21.02958095491754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8387162094838,
              21.02958095491754
            ],
            [
              105.83870848727855,
              21.029549316093767
            ],
            [
              105.8387077970784,
              21.029546488799074
            ],
            [
              105.83868067442083,
              21.029435371768002
            ],
            [
              105.83867852799617,
              21.0294265768392
            ],
            [
              105.83870469964512,
              21.029397137279194
            ],
            [
              105.83866971049123,
              21.029282398283726
            ],
            [
              105.83865277601573,
              21.029226866787067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.5_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13147",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02958095491754,
          "to_latitude": 21.029226866787067,
          "to_longitude": 105.83865277601573,
          "from_longitude": 105.8387162094838
        }
      },
      {
        "id": 13078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83857774241432,
            21.02939458409865,
            105.83868067442083,
            21.02945194117144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868067442083,
              21.029435371768002
            ],
            [
              105.8385915316567,
              21.02945194117144
            ],
            [
              105.83857774241432,
              21.02939458409865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.5_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13148",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029435371768002,
          "to_latitude": 21.02939458409865,
          "to_longitude": 105.83857774241432,
          "from_longitude": 105.83868067442083
        }
      },
      {
        "id": 13079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867844563109,
            21.029513597409156,
            105.83887379514559,
            21.02956453092495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867844563109,
              21.02956453092495
            ],
            [
              105.83870848727855,
              21.029549316093767
            ],
            [
              105.83871163764276,
              21.029547720818513
            ],
            [
              105.83877959728073,
              21.029530231592677
            ],
            [
              105.83883437497656,
              21.029516133938294
            ],
            [
              105.83887379514559,
              21.029513597409156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.6_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13149",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02956453092495,
          "to_latitude": 21.029513597409156,
          "to_longitude": 105.83887379514559,
          "from_longitude": 105.83867844563109
        }
      },
      {
        "id": 13080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263430514405,
            21.019405379840048,
            105.83263645910503,
            21.019444732724637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263430514405,
              21.019405379840048
            ],
            [
              105.83263465208967,
              21.019411732326336
            ],
            [
              105.83263547317863,
              21.019426729060115
            ],
            [
              105.83263552991812,
              21.019427765684128
            ],
            [
              105.83263645910503,
              21.019444732724637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13150",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019405379840048,
          "to_latitude": 21.019444732724637,
          "to_longitude": 105.83263645910503,
          "from_longitude": 105.83263430514405
        }
      },
      {
        "id": 13081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83675036758605,
            21.020494853426495,
            105.83675739452987,
            21.020507742183728
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675036758605,
              21.020494853426495
            ],
            [
              105.83675134729718,
              21.02049665415122
            ],
            [
              105.83675343786676,
              21.02050048527755
            ],
            [
              105.83675739452987,
              21.020507742183728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_7.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13151",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020494853426495,
          "to_latitude": 21.020507742183728,
          "to_longitude": 105.83675739452987,
          "from_longitude": 105.83675036758605
        }
      },
      {
        "id": 13082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83911012772438,
            21.022397177381684,
            105.839125389974,
            21.0224010507318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839125389974,
              21.022397177381684
            ],
            [
              105.83912522666947,
              21.0223972188324
            ],
            [
              105.83912494136885,
              21.02239729159458
            ],
            [
              105.83911728526624,
              21.022399234263965
            ],
            [
              105.83911012772438,
              21.0224010507318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.23_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13152",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022397177381684,
          "to_latitude": 21.0224010507318,
          "to_longitude": 105.83911012772438,
          "from_longitude": 105.839125389974
        }
      },
      {
        "id": 13083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902041807573,
            21.01951279922162,
            105.83903254617071,
            21.019528734583727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903254617071,
              21.019528734583727
            ],
            [
              105.83902377013663,
              21.019517203063195
            ],
            [
              105.8390213251467,
              21.019513992421796
            ],
            [
              105.83902041807573,
              21.01951279922162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_4.2_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13153",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019528734583727,
          "to_latitude": 21.01951279922162,
          "to_longitude": 105.83902041807573,
          "from_longitude": 105.83903254617071
        }
      },
      {
        "id": 13084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83564630122633,
            21.01920135847641,
            105.83581624213656,
            21.019253152746348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83564630122633,
              21.019253152746348
            ],
            [
              105.83581624213656,
              21.019238509332492
            ],
            [
              105.83581232889792,
              21.01920502707894
            ],
            [
              105.83581190154287,
              21.01920135847641
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_114.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13154",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019253152746348,
          "to_latitude": 21.01920135847641,
          "to_longitude": 105.83581190154287,
          "from_longitude": 105.83564630122633
        }
      },
      {
        "id": 13085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83811252674955,
            21.02075183788227,
            105.83868131400351,
            21.021007922441278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868131400351,
              21.02075183788227
            ],
            [
              105.83867905057882,
              21.020754261552003
            ],
            [
              105.8386072979846,
              21.02083109666463
            ],
            [
              105.8384637476881,
              21.02090912658514
            ],
            [
              105.8383931021725,
              21.020831461601453
            ],
            [
              105.83824989469252,
              21.02093332391404
            ],
            [
              105.8382228990026,
              21.02095175083788
            ],
            [
              105.83815430803377,
              21.020988994549494
            ],
            [
              105.83811252674955,
              21.021007922441278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.5_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13155",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02075183788227,
          "to_latitude": 21.021007922441278,
          "to_longitude": 105.83811252674955,
          "from_longitude": 105.83868131400351
        }
      },
      {
        "id": 13086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83576147968802,
            21.026150223720535,
            105.83621745218719,
            21.026322707205406
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83621745218719,
              21.026167565359838
            ],
            [
              105.8362173955917,
              21.026167594541267
            ],
            [
              105.836203839843,
              21.026174466080796
            ],
            [
              105.836195106458,
              21.02617889417244
            ],
            [
              105.83615025106911,
              21.026192687459186
            ],
            [
              105.83613310355344,
              21.026179271291983
            ],
            [
              105.8360959765429,
              21.026150223720535
            ],
            [
              105.83609011078968,
              21.026153426485774
            ],
            [
              105.83601035245749,
              21.02619697821473
            ],
            [
              105.83599174312138,
              21.026191054230267
            ],
            [
              105.83583424860959,
              21.026266109463464
            ],
            [
              105.83576147968802,
              21.026322707205406
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.5_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13156",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026167565359838,
          "to_latitude": 21.026322707205406,
          "to_longitude": 105.83576147968802,
          "from_longitude": 105.83621745218719
        }
      },
      {
        "id": 13087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83551793945887,
            21.022998492664644,
            105.83584402190581,
            21.023249852858843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584402190581,
              21.022998722014588
            ],
            [
              105.83584143481518,
              21.022998492664644
            ],
            [
              105.8357431437758,
              21.022999464320964
            ],
            [
              105.83576563627919,
              21.02321232037303
            ],
            [
              105.83574519141122,
              21.023243248762146
            ],
            [
              105.83573473966442,
              21.023244496891646
            ],
            [
              105.83566657245518,
              21.023249852858843
            ],
            [
              105.83565075454139,
              21.023052051444285
            ],
            [
              105.83565066655818,
              21.023050936395393
            ],
            [
              105.83561304190928,
              21.023051020993854
            ],
            [
              105.83551793945887,
              21.023069020685547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_18.9_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13157",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022998722014588,
          "to_latitude": 21.023069020685547,
          "to_longitude": 105.83551793945887,
          "from_longitude": 105.83584402190581
        }
      },
      {
        "id": 13088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842474366459,
            21.020096769236996,
            105.8387510184136,
            21.020523044524612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83842474366459,
              21.020523044524612
            ],
            [
              105.83859208102737,
              21.020389433134
            ],
            [
              105.83861757952457,
              21.020369073412198
            ],
            [
              105.83862050324113,
              21.02036310675544
            ],
            [
              105.83862752054475,
              21.02034878677712
            ],
            [
              105.83869421245251,
              21.020212691737093
            ],
            [
              105.8387510184136,
              21.020096769236996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13158",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020523044524612,
          "to_latitude": 21.020096769236996,
          "to_longitude": 105.8387510184136,
          "from_longitude": 105.83842474366459
        }
      },
      {
        "id": 13089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356452928274,
            21.021509847277393,
            105.835972544956,
            21.021727271843112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356452928274,
              21.021509847277393
            ],
            [
              105.83564767453304,
              21.021510339575034
            ],
            [
              105.8356974985543,
              21.021520611921474
            ],
            [
              105.83574138326176,
              21.021529659761605
            ],
            [
              105.83579181654767,
              21.02158072047783
            ],
            [
              105.83589214850133,
              21.02166940632627
            ],
            [
              105.835972544956,
              21.021727271843112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13159",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021509847277393,
          "to_latitude": 21.021727271843112,
          "to_longitude": 105.835972544956,
          "from_longitude": 105.8356452928274
        }
      },
      {
        "id": 13090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83626378435676,
            21.0212432028435,
            105.83683304517258,
            21.021492752467168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83683304517258,
              21.0212432028435
            ],
            [
              105.83682707587188,
              21.021243500469108
            ],
            [
              105.83656205782532,
              21.021256695052426
            ],
            [
              105.83650561380706,
              21.021274059836983
            ],
            [
              105.83644171161718,
              21.021287633417625
            ],
            [
              105.83639867889148,
              21.021301803448594
            ],
            [
              105.836378726047,
              21.021338609476217
            ],
            [
              105.83629172194564,
              21.021400980354624
            ],
            [
              105.83626670236247,
              21.021452278215428
            ],
            [
              105.83626378435676,
              21.021492752467168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10.9_Văn Chương",
          "maTaiSan": "04.O14.DODV.13160",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0212432028435,
          "to_latitude": 21.021492752467168,
          "to_longitude": 105.83626378435676,
          "from_longitude": 105.83683304517258
        }
      },
      {
        "id": 13091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83699710769045,
            21.021534180862275,
            105.83748902129791,
            21.02165321147822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83748902129791,
              21.02153800367609
            ],
            [
              105.83745820783201,
              21.021534817981987
            ],
            [
              105.83745257440488,
              21.021534798751137
            ],
            [
              105.8373607552458,
              21.021534507453136
            ],
            [
              105.8372711467674,
              21.021536295282647
            ],
            [
              105.83713246155445,
              21.021535207588915
            ],
            [
              105.83700155424052,
              21.021534180862275
            ],
            [
              105.83699710769045,
              21.02165321147822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.15_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13161",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02153800367609,
          "to_latitude": 21.02165321147822,
          "to_longitude": 105.83699710769045,
          "from_longitude": 105.83748902129791
        }
      },
      {
        "id": 13092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713502352285,
            21.021623032120143,
            105.8374404306858,
            21.021924665860894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374404306858,
              21.021623032120143
            ],
            [
              105.83733061080623,
              21.021721932017442
            ],
            [
              105.83730493771671,
              21.021746930466733
            ],
            [
              105.8372814713068,
              21.021770880245885
            ],
            [
              105.83721040915815,
              21.021843405729367
            ],
            [
              105.83713502352285,
              21.021924665860894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.13_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13162",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021623032120143,
          "to_latitude": 21.021924665860894,
          "to_longitude": 105.83713502352285,
          "from_longitude": 105.8374404306858
        }
      },
      {
        "id": 13093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915826349244,
            21.019582700001862,
            105.83930036524157,
            21.019894249717144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921590161422,
              21.019582700001862
            ],
            [
              105.83920482705726,
              21.019591556550566
            ],
            [
              105.83918001516503,
              21.019606335564507
            ],
            [
              105.83915826349244,
              21.019620467221795
            ],
            [
              105.83918198897439,
              21.01965736697991
            ],
            [
              105.83930036524157,
              21.019852649632796
            ],
            [
              105.83929224438003,
              21.019894249717144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.5_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13163",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41.65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019582700001862,
          "to_latitude": 21.019894249717144,
          "to_longitude": 105.83929224438003,
          "from_longitude": 105.83921590161422
        }
      },
      {
        "id": 13094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758016612126,
            21.01907217938645,
            105.83785711395387,
            21.019135720796314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758016612126,
              21.019135720796314
            ],
            [
              105.83763034995837,
              21.019134928142513
            ],
            [
              105.8378042526101,
              21.019116121059092
            ],
            [
              105.83785711395387,
              21.019110404324277
            ],
            [
              105.83785501399585,
              21.019076484167613
            ],
            [
              105.83785483410824,
              21.01907217938645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.6_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13164",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019135720796314,
          "to_latitude": 21.01907217938645,
          "to_longitude": 105.83785483410824,
          "from_longitude": 105.83758016612126
        }
      },
      {
        "id": 13095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83785711395387,
            21.019096533614803,
            105.8380835343392,
            21.019110404324277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83785711395387,
              21.019110404324277
            ],
            [
              105.83793076315452,
              21.019102439115393
            ],
            [
              105.8379439147967,
              21.019101016785584
            ],
            [
              105.8380835343392,
              21.019096533614803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.6_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13165",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019110404324277,
          "to_latitude": 21.019096533614803,
          "to_longitude": 105.8380835343392,
          "from_longitude": 105.83785711395387
        }
      },
      {
        "id": 13096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842931502947,
            21.01916938527538,
            105.8385801312825,
            21.019298945799346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8385801312825,
              21.01916938527538
            ],
            [
              105.83849960928544,
              21.01920959269176
            ],
            [
              105.83849622119162,
              21.01921181596776
            ],
            [
              105.83842931502947,
              21.01924760540719
            ],
            [
              105.83842945310097,
              21.01924872923997
            ],
            [
              105.8384337116993,
              21.019283314356528
            ],
            [
              105.83843805768643,
              21.019298945799346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.1_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13166",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01916938527538,
          "to_latitude": 21.019298945799346,
          "to_longitude": 105.83843805768643,
          "from_longitude": 105.8385801312825
        }
      },
      {
        "id": 13097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842625182984,
            21.019298945799346,
            105.83849905835564,
            21.01954418130641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83842625182984,
              21.01954418130641
            ],
            [
              105.83844386538439,
              21.01952612395444
            ],
            [
              105.83849465238504,
              21.01946870950338
            ],
            [
              105.83849905835564,
              21.01945549620551
            ],
            [
              105.83849386732082,
              21.019437550355565
            ],
            [
              105.83846596342674,
              21.01939964322235
            ],
            [
              105.83845548101414,
              21.019382105516364
            ],
            [
              105.8384450977878,
              21.019345831755025
            ],
            [
              105.83843805768643,
              21.019298945799346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.1_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13167",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01954418130641,
          "to_latitude": 21.019298945799346,
          "to_longitude": 105.83843805768643,
          "from_longitude": 105.83842625182984
        }
      },
      {
        "id": 13098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83835784314284,
            21.019535016248277,
            105.8386666504266,
            21.019640447584138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83835784314284,
              21.01955282271168
            ],
            [
              105.83835896119824,
              21.01955424519769
            ],
            [
              105.83836083007995,
              21.01955661688404
            ],
            [
              105.83838923559135,
              21.019569307173718
            ],
            [
              105.83841664092155,
              21.019601485822648
            ],
            [
              105.83845541204681,
              21.019633357347555
            ],
            [
              105.83846403815853,
              21.019640447584138
            ],
            [
              105.83850463558505,
              21.019624576539904
            ],
            [
              105.83852149846253,
              21.01961798388332
            ],
            [
              105.83852695315473,
              21.019615813644943
            ],
            [
              105.8385421495316,
              21.019627732637804
            ],
            [
              105.83856488415772,
              21.019616827945562
            ],
            [
              105.83859471824947,
              21.01960251742055
            ],
            [
              105.83860531129415,
              21.01958048525384
            ],
            [
              105.8386666504266,
              21.019535016248277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13168",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01955282271168,
          "to_latitude": 21.019535016248277,
          "to_longitude": 105.8386666504266,
          "from_longitude": 105.83835784314284
        }
      },
      {
        "id": 13099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83844003781341,
            21.019633357347555,
            105.8385043901093,
            21.019780243801492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83845541204681,
              21.019633357347555
            ],
            [
              105.83844003781341,
              21.019649845602604
            ],
            [
              105.8385043901093,
              21.019780243801492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.3_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13169",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019633357347555,
          "to_latitude": 21.019780243801492,
          "to_longitude": 105.8385043901093,
          "from_longitude": 105.83845541204681
        }
      },
      {
        "id": 13100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771319289343,
            21.019414393405,
            105.8379529220276,
            21.01989886930617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379529220276,
              21.01989886930617
            ],
            [
              105.83794739211282,
              21.019894467139316
            ],
            [
              105.83793418516561,
              21.019884799061547
            ],
            [
              105.8379110534672,
              21.019867868277206
            ],
            [
              105.8378611381564,
              21.019799859351355
            ],
            [
              105.83771724726464,
              21.01952062653066
            ],
            [
              105.83771319289343,
              21.019512757742145
            ],
            [
              105.83771564231817,
              21.019511945423215
            ],
            [
              105.83785283691763,
              21.019466441827312
            ],
            [
              105.83789069278134,
              21.0194466111518
            ],
            [
              105.83792750014662,
              21.019414393405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.8_Ngõ 98, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13170",
          "diaChiLapD": "Ngõ 98, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01989886930617,
          "to_latitude": 21.019414393405,
          "to_longitude": 105.83792750014662,
          "from_longitude": 105.8379529220276
        }
      },
      {
        "id": 13101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386604177764,
            21.019037789999604,
            105.8391727357843,
            21.01906350740725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391727357843,
              21.01903813827482
            ],
            [
              105.83916698517653,
              21.019037789999604
            ],
            [
              105.83913349493656,
              21.019040081398114
            ],
            [
              105.83893250530517,
              21.01905003454387
            ],
            [
              105.8386604177764,
              21.01906350740725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13171",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01903813827482,
          "to_latitude": 21.01906350740725,
          "to_longitude": 105.8386604177764,
          "from_longitude": 105.8391727357843
        }
      },
      {
        "id": 13102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83927038390239,
            21.01951084672244,
            105.8392900553152,
            21.019535877629355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392900553152,
              21.01951084672244
            ],
            [
              105.83927038390239,
              21.019526438577127
            ],
            [
              105.83927950239877,
              21.01953573291683
            ],
            [
              105.83927964557886,
              21.019535877629355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.4_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13172",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01951084672244,
          "to_latitude": 21.019535877629355,
          "to_longitude": 105.83927964557886,
          "from_longitude": 105.8392900553152
        }
      },
      {
        "id": 13103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83882208866255,
            21.01952415205285,
            105.83903905224581,
            21.019667400302954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903905224581,
              21.01952415205285
            ],
            [
              105.83903254617071,
              21.019528734583727
            ],
            [
              105.83899306130004,
              21.01955654900699
            ],
            [
              105.83894462599861,
              21.01959066690338
            ],
            [
              105.83887787940657,
              21.01963246397478
            ],
            [
              105.83882208866255,
              21.019667400302954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_4.2_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13173",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01952415205285,
          "to_latitude": 21.019667400302954,
          "to_longitude": 105.83882208866255,
          "from_longitude": 105.83903905224581
        }
      },
      {
        "id": 13104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393540433225,
            21.01897139514747,
            105.83956937852179,
            21.019020593946724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83956937852179,
              21.01900062840546
            ],
            [
              105.83938965091757,
              21.019017417516647
            ],
            [
              105.83935564618339,
              21.019020593946724
            ],
            [
              105.8393542037556,
              21.018976341310488
            ],
            [
              105.8393540433225,
              21.01897139514747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.9_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13174",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01900062840546,
          "to_latitude": 21.01897139514747,
          "to_longitude": 105.8393540433225,
          "from_longitude": 105.83956937852179
        }
      },
      {
        "id": 13105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952046289201,
            21.01957406986836,
            105.83980034511974,
            21.019607884689915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980034511974,
              21.01957449019211
            ],
            [
              105.83979656899297,
              21.01957406986836
            ],
            [
              105.83977706337639,
              21.019578204477686
            ],
            [
              105.83970105672076,
              21.019594319265718
            ],
            [
              105.83952046289201,
              21.019607884689915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_5.4_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13175",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01957449019211,
          "to_latitude": 21.019607884689915,
          "to_longitude": 105.83952046289201,
          "from_longitude": 105.83980034511974
        }
      },
      {
        "id": 13106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919821758141,
            21.019533505343897,
            105.83940782813195,
            21.019984719833996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83928247682039,
              21.019533505343897
            ],
            [
              105.83928261746347,
              21.01953371239135
            ],
            [
              105.83928318482015,
              21.01953453604141
            ],
            [
              105.83929075887816,
              21.019534460735688
            ],
            [
              105.83930324009977,
              21.019537515273292
            ],
            [
              105.8393235198718,
              21.019569776799607
            ],
            [
              105.83936418927979,
              21.0196508300954
            ],
            [
              105.83936603373427,
              21.01965450614521
            ],
            [
              105.83932447379519,
              21.019694668914475
            ],
            [
              105.83940782813195,
              21.019848714210816
            ],
            [
              105.83940539308402,
              21.0199043385897
            ],
            [
              105.83931891895361,
              21.019932459027068
            ],
            [
              105.83929525247949,
              21.0199324141249
            ],
            [
              105.83920030387912,
              21.019956664456494
            ],
            [
              105.83919821758141,
              21.019984719833996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.3_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13176",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019533505343897,
          "to_latitude": 21.019984719833996,
          "to_longitude": 105.83919821758141,
          "from_longitude": 105.83928247682039
        }
      },
      {
        "id": 13107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84041148311523,
            21.018897839921625,
            105.84058061585013,
            21.01894303538403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84041148311523,
              21.01894303538403
            ],
            [
              105.84052634647097,
              21.018937875876958
            ],
            [
              105.84058061585013,
              21.018935578171735
            ],
            [
              105.84057819485184,
              21.018902841971194
            ],
            [
              105.84057815281567,
              21.018902378825413
            ],
            [
              105.84057782916857,
              21.018897839921625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.11_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13177",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01894303538403,
          "to_latitude": 21.018897839921625,
          "to_longitude": 105.84057782916857,
          "from_longitude": 105.84041148311523
        }
      },
      {
        "id": 13108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84058101801162,
            21.018897660680643,
            105.84075794691498,
            21.01894282278125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84058101801162,
              21.018897660680643
            ],
            [
              105.84058146769188,
              21.018902202574527
            ],
            [
              105.84058152562527,
              21.01890275596408
            ],
            [
              105.84058435098936,
              21.01893521016719
            ],
            [
              105.84059727810768,
              21.01894282278125
            ],
            [
              105.84060055716049,
              21.018942605158735
            ],
            [
              105.84060632384057,
              21.018942217179156
            ],
            [
              105.84061090558089,
              21.018934483721335
            ],
            [
              105.84063687513623,
              21.018933556034053
            ],
            [
              105.84063691360839,
              21.018933554940695
            ],
            [
              105.84075794691498,
              21.018929249945465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.12_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13178",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018897660680643,
          "to_latitude": 21.018929249945465,
          "to_longitude": 105.84075794691498,
          "from_longitude": 105.84058101801162
        }
      },
      {
        "id": 13109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84113450224768,
            21.018879564536725,
            105.84120390539067,
            21.019139765617435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84113450224768,
              21.018879564536725
            ],
            [
              105.84113485466472,
              21.018884599166213
            ],
            [
              105.84113542316366,
              21.01891461045921
            ],
            [
              105.84120390539067,
              21.018912478017352
            ],
            [
              105.8411977684117,
              21.019139765617435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT2.1.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13179",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018879564536725,
          "to_latitude": 21.019139765617435,
          "to_longitude": 105.8411977684117,
          "from_longitude": 105.84113450224768
        }
      },
      {
        "id": 13110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84095573515107,
            21.018915419266094,
            105.84110958185258,
            21.01928114017736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84099257427822,
              21.01928114017736
            ],
            [
              105.84099025272268,
              21.01917403842706
            ],
            [
              105.84097738666513,
              21.019034255999923
            ],
            [
              105.84096529969719,
              21.019013503688132
            ],
            [
              105.84095573515107,
              21.01892124608414
            ],
            [
              105.84097043457301,
              21.01892068928656
            ],
            [
              105.8410160254797,
              21.018918962732464
            ],
            [
              105.84110958185258,
              21.018915419266094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.13_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13180",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01928114017736,
          "to_latitude": 21.018915419266094,
          "to_longitude": 105.84110958185258,
          "from_longitude": 105.84099257427822
        }
      },
      {
        "id": 13111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936486044888,
            21.026779694938526,
            105.8396379266814,
            21.027591811489806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83949180794698,
              21.026779694938526
            ],
            [
              105.83936486044888,
              21.02682538696591
            ],
            [
              105.83937059136403,
              21.026842781842824
            ],
            [
              105.83939842783114,
              21.026927275496316
            ],
            [
              105.83951577704526,
              21.027283470924644
            ],
            [
              105.83959642253363,
              21.027528253424155
            ],
            [
              105.8396378684715,
              21.027591724164644
            ],
            [
              105.8396379266814,
              21.027591811489806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_30.10_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13181",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026779694938526,
          "to_latitude": 21.027591811489806,
          "to_longitude": 105.8396379266814,
          "from_longitude": 105.83949180794698
        }
      },
      {
        "id": 13112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979830741204,
            21.026075641543976,
            105.83985467217833,
            21.026238954865832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83985467217833,
              21.026238954865832
            ],
            [
              105.83982591247059,
              21.026103702024113
            ],
            [
              105.8397990521273,
              21.026079957076867
            ],
            [
              105.83979846506537,
              21.026076823083827
            ],
            [
              105.83979830741204,
              21.026075641543976
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13.7_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13183",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026238954865832,
          "to_latitude": 21.026075641543976,
          "to_longitude": 105.83979830741204,
          "from_longitude": 105.83985467217833
        }
      },
      {
        "id": 13113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83980424122781,
            21.026238954865832,
            105.83985467217833,
            21.02634064665136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980424122781,
              21.02634064665136
            ],
            [
              105.83985167844112,
              21.02629425048503
            ],
            [
              105.83985467217833,
              21.026238954865832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_13.7_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13184",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02634064665136,
          "to_latitude": 21.026238954865832,
          "to_longitude": 105.83985467217833,
          "from_longitude": 105.83980424122781
        }
      },
      {
        "id": 13114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83980084591528,
            21.025397863232737,
            105.8398371949827,
            21.0260755776018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980101316747,
              21.0260755776018
            ],
            [
              105.83980084591528,
              21.026074228109938
            ],
            [
              105.83980146221857,
              21.026067298252347
            ],
            [
              105.83982597726906,
              21.026028377455564
            ],
            [
              105.8398371949827,
              21.026010311798977
            ],
            [
              105.83983092764203,
              21.02561107409238
            ],
            [
              105.83982758125454,
              21.025397863232737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13185",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0260755776018,
          "to_latitude": 21.025397863232737,
          "to_longitude": 105.83982758125454,
          "from_longitude": 105.83980101316747
        }
      },
      {
        "id": 13115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8386868243528,
            21.023270968902743,
            105.83888118516599,
            21.02363280109676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83882342650865,
              21.02363280109676
            ],
            [
              105.83882366127123,
              21.02363246393993
            ],
            [
              105.83887345856377,
              21.023561114807492
            ],
            [
              105.83888118516599,
              21.023554223944114
            ],
            [
              105.83882615043515,
              21.023474019117845
            ],
            [
              105.8386868243528,
              21.023270968902743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17.10_Linh Quang",
          "maTaiSan": "04.O14.DODV.13186",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02363280109676,
          "to_latitude": 21.023270968902743,
          "to_longitude": 105.8386868243528,
          "from_longitude": 105.83882342650865
        }
      },
      {
        "id": 13116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392053374286,
            21.0225341812593,
            105.83947307853649,
            21.022707459154766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392053374286,
              21.022707459154766
            ],
            [
              105.8392079916196,
              21.022707302446165
            ],
            [
              105.83945957307539,
              21.0226924059982
            ],
            [
              105.83946014270205,
              21.022658924322563
            ],
            [
              105.83947307853649,
              21.02262388677758
            ],
            [
              105.83947207121804,
              21.022575067125917
            ],
            [
              105.83947112550922,
              21.0225341812593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.25.1_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13187",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": 44.228,
          "from_latitude": 21.022707459154766,
          "to_latitude": 21.0225341812593,
          "to_longitude": 105.83947112550922,
          "from_longitude": 105.8392053374286
        }
      },
      {
        "id": 13117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936357178644,
            21.02229774407498,
            105.83954017736815,
            21.02229984319013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936357178644,
              21.02229984319013
            ],
            [
              105.83947472983759,
              21.022298913431506
            ],
            [
              105.83954017736815,
              21.02229774407498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.25.2_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13188",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": 18.361,
          "from_latitude": 21.02229984319013,
          "to_latitude": 21.02229774407498,
          "to_longitude": 105.83954017736815,
          "from_longitude": 105.83936357178644
        }
      },
      {
        "id": 13118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83876453995394,
            21.022656043217395,
            105.83919361458922,
            21.022711350557397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83876453995394,
              21.022711350557397
            ],
            [
              105.83886008221441,
              21.022707775886772
            ],
            [
              105.83895562542521,
              21.02270420025466
            ],
            [
              105.83919238176668,
              21.02265629317041
            ],
            [
              105.83919361458922,
              21.022656043217395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.24_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13189",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022711350557397,
          "to_latitude": 21.022656043217395,
          "to_longitude": 105.83919361458922,
          "from_longitude": 105.83876453995394
        }
      },
      {
        "id": 13119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909814827472,
            21.021940051121586,
            105.839173095034,
            21.022250136088978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.839173095034,
              21.021940051121586
            ],
            [
              105.83915337809616,
              21.022017525719246
            ],
            [
              105.83915131950981,
              21.02202561249691
            ],
            [
              105.83914480868512,
              21.022051194995022
            ],
            [
              105.83910376229943,
              21.02221247560137
            ],
            [
              105.83909814827472,
              21.022250136088978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.20_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13190",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021940051121586,
          "to_latitude": 21.022250136088978,
          "to_longitude": 105.83909814827472,
          "from_longitude": 105.839173095034
        }
      },
      {
        "id": 13120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909805306311,
            21.022261829685185,
            105.83911956997368,
            21.02247125955027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909805306311,
              21.022261829685185
            ],
            [
              105.83909825366257,
              21.022297675814574
            ],
            [
              105.83910737143486,
              21.022380555746146
            ],
            [
              105.83911012772438,
              21.0224010507318
            ],
            [
              105.83911956997368,
              21.02247125955027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.23_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13191",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022261829685185,
          "to_latitude": 21.02247125955027,
          "to_longitude": 105.83911956997368,
          "from_longitude": 105.83909805306311
        }
      },
      {
        "id": 13121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83911956997368,
            21.02247125955027,
            105.83916506913391,
            21.022641670167946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83911956997368,
              21.02247125955027
            ],
            [
              105.83916506913391,
              21.022641670167946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.23_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13192",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02247125955027,
          "to_latitude": 21.022641670167946,
          "to_longitude": 105.83916506913391,
          "from_longitude": 105.83911956997368
        }
      },
      {
        "id": 13122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392302479696,
            21.021731048716024,
            105.83949592391652,
            21.021757259775537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392302479696,
              21.021731048716024
            ],
            [
              105.83923858315501,
              21.021732880874165
            ],
            [
              105.83927252006241,
              21.02174033481863
            ],
            [
              105.83932955602255,
              21.021757259775537
            ],
            [
              105.8394085554061,
              21.02173768184964
            ],
            [
              105.83949592391652,
              21.0217332186645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.17_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13193",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021731048716024,
          "to_latitude": 21.0217332186645,
          "to_longitude": 105.83949592391652,
          "from_longitude": 105.8392302479696
        }
      },
      {
        "id": 13123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83917891732193,
            21.02158272256997,
            105.83923349081219,
            21.021909356163597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923349081219,
              21.02158272256997
            ],
            [
              105.83922538736017,
              21.021657872784473
            ],
            [
              105.83920246464824,
              21.021796442768284
            ],
            [
              105.83917891732193,
              21.021909356163597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.16_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13194",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02158272256997,
          "to_latitude": 21.021909356163597,
          "to_longitude": 105.83917891732193,
          "from_longitude": 105.83923349081219
        }
      },
      {
        "id": 13124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83888096543976,
            21.021389294061194,
            105.8392319174348,
            21.021558389372018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83888096543976,
              21.021472778036664
            ],
            [
              105.83908570261403,
              21.021419556434232
            ],
            [
              105.83920211667339,
              21.021389294061194
            ],
            [
              105.83922390112106,
              21.021490463187487
            ],
            [
              105.8392319174348,
              21.021558389372018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.13_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13195",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021472778036664,
          "to_latitude": 21.021558389372018,
          "to_longitude": 105.8392319174348,
          "from_longitude": 105.83888096543976
        }
      },
      {
        "id": 13125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83923258164384,
            21.02130739610766,
            105.8394290887548,
            21.021510141807415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925213248273,
              21.02136780257099
            ],
            [
              105.83923258164384,
              21.02132849411832
            ],
            [
              105.83928336672066,
              21.021310732748557
            ],
            [
              105.83937714602926,
              21.021308585475264
            ],
            [
              105.8394290887548,
              21.02130739610766
            ],
            [
              105.83942870190006,
              21.021510141807415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.14_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13196",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02136780257099,
          "to_latitude": 21.021510141807415,
          "to_longitude": 105.83942870190006,
          "from_longitude": 105.83925213248273
        }
      },
      {
        "id": 13126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83875858779912,
            21.020832463341026,
            105.8389045868728,
            21.020991869472088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83875858779912,
              21.020832463341026
            ],
            [
              105.8388678103359,
              21.020955340243397
            ],
            [
              105.8389045868728,
              21.020991869472088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.7_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13197",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020832463341026,
          "to_latitude": 21.020991869472088,
          "to_longitude": 105.8389045868728,
          "from_longitude": 105.83875858779912
        }
      },
      {
        "id": 13127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389045868728,
            21.020991869472088,
            105.8389847576311,
            21.02107150324335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389045868728,
              21.020991869472088
            ],
            [
              105.83891408137092,
              21.02100130016664
            ],
            [
              105.8389847576311,
              21.02107150324335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.7_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13198",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020991869472088,
          "to_latitude": 21.02107150324335,
          "to_longitude": 105.8389847576311,
          "from_longitude": 105.8389045868728
        }
      },
      {
        "id": 13128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810351733551,
            21.02075383706267,
            105.83868545684052,
            21.021023656727735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868545684052,
              21.02075383706267
            ],
            [
              105.83868327482601,
              21.020757916842665
            ],
            [
              105.83864479924944,
              21.020829866740712
            ],
            [
              105.83855067970704,
              21.020881045855994
            ],
            [
              105.83848127492398,
              21.020918786868556
            ],
            [
              105.8384647686469,
              21.020927762276248
            ],
            [
              105.83838766174826,
              21.020848924241395
            ],
            [
              105.83826951940017,
              21.020928426566815
            ],
            [
              105.83823544171722,
              21.02095477443798
            ],
            [
              105.83822313264056,
              21.020964291846692
            ],
            [
              105.83815481264466,
              21.020997179783194
            ],
            [
              105.83810351733551,
              21.021023656727735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.6_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13200",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02075383706267,
          "to_latitude": 21.021023656727735,
          "to_longitude": 105.83810351733551,
          "from_longitude": 105.83868545684052
        }
      },
      {
        "id": 13129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751231945926,
            21.02073082820089,
            105.83830108957392,
            21.020799613639525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751231945926,
              21.020768993756082
            ],
            [
              105.83751373753067,
              21.020769189095848
            ],
            [
              105.83761755396388,
              21.020783441984364
            ],
            [
              105.83765103704128,
              21.020786164702102
            ],
            [
              105.8376530668891,
              21.020799613639525
            ],
            [
              105.83770086874179,
              21.020793528951046
            ],
            [
              105.83787147479349,
              21.02077181063577
            ],
            [
              105.83799894348499,
              21.02073619632836
            ],
            [
              105.8380990929088,
              21.02073082820089
            ],
            [
              105.83830108957392,
              21.020738670836703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.22_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13201",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020768993756082,
          "to_latitude": 21.020738670836703,
          "to_longitude": 105.83830108957392,
          "from_longitude": 105.83751231945926
        }
      },
      {
        "id": 13130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83749956274207,
            21.020842187867736,
            105.83774977645277,
            21.021252794893755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83749956274207,
              21.020842187867736
            ],
            [
              105.83750413699754,
              21.0208432374716
            ],
            [
              105.8375487400127,
              21.02085345911525
            ],
            [
              105.83759590091152,
              21.02085265875972
            ],
            [
              105.83760610879949,
              21.020916283944253
            ],
            [
              105.83763040797157,
              21.021000289618424
            ],
            [
              105.83765407447815,
              21.021082107183034
            ],
            [
              105.83767441884478,
              21.021121132827034
            ],
            [
              105.83770425645018,
              21.02116627350474
            ],
            [
              105.83774977645277,
              21.021252794893755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.21_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13202",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020842187867736,
          "to_latitude": 21.021252794893755,
          "to_longitude": 105.83774977645277,
          "from_longitude": 105.83749956274207
        }
      },
      {
        "id": 13131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746015356083,
            21.02079668140277,
            105.83755354537458,
            21.021063903853108
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755354537458,
              21.02079668140277
            ],
            [
              105.83754306628903,
              21.020846737263252
            ],
            [
              105.8375487400127,
              21.02085345911525
            ],
            [
              105.8375405957342,
              21.020860132491308
            ],
            [
              105.83752784828239,
              21.02092843921601
            ],
            [
              105.83751678904838,
              21.02099243467017
            ],
            [
              105.83751976149145,
              21.021063903853108
            ],
            [
              105.83746542981281,
              21.021062432662543
            ],
            [
              105.83746015356083,
              21.021062288834457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.19_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13203",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02079668140277,
          "to_latitude": 21.021062288834457,
          "to_longitude": 105.83746015356083,
          "from_longitude": 105.83755354537458
        }
      },
      {
        "id": 13132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751976149145,
            21.021063903853108,
            105.83756047948727,
            21.021351291523448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756047948727,
              21.021351291523448
            ],
            [
              105.83752030333397,
              21.021127503475057
            ],
            [
              105.83751976149145,
              21.021063903853108
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.19_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13204",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021351291523448,
          "to_latitude": 21.021063903853108,
          "to_longitude": 105.83751976149145,
          "from_longitude": 105.83756047948727
        }
      },
      {
        "id": 13133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368765684337,
            21.02124339301047,
            105.8374651610373,
            21.02124557825036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8374651610373,
              21.02124339301047
            ],
            [
              105.83735469141058,
              21.021243803206506
            ],
            [
              105.83735154005629,
              21.021243815107567
            ],
            [
              105.8371700023579,
              21.021244489226312
            ],
            [
              105.8369599013973,
              21.021245268477404
            ],
            [
              105.83695602569244,
              21.021245283032187
            ],
            [
              105.8368765684337,
              21.02124557825036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.18_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13205",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02124339301047,
          "to_latitude": 21.02124557825036,
          "to_longitude": 105.8368765684337,
          "from_longitude": 105.8374651610373
        }
      },
      {
        "id": 13134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83705739620538,
            21.020283733810302,
            105.83724140449633,
            21.020364978455344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705739620538,
              21.020364978455344
            ],
            [
              105.83723876751584,
              21.02028489839995
            ],
            [
              105.83724140449633,
              21.020283733810302
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_7.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13206",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020364978455344,
          "to_latitude": 21.020283733810302,
          "to_longitude": 105.83724140449633,
          "from_longitude": 105.83705739620538
        }
      },
      {
        "id": 13135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83724140449633,
            21.020220767259424,
            105.83744509353978,
            21.020283733810302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83724140449633,
              21.020283733810302
            ],
            [
              105.83725408860855,
              21.020277636940126
            ],
            [
              105.8373836976351,
              21.020226212473766
            ],
            [
              105.83744509353978,
              21.020220767259424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_7.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13207",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020283733810302,
          "to_latitude": 21.020220767259424,
          "to_longitude": 105.83744509353978,
          "from_longitude": 105.83724140449633
        }
      },
      {
        "id": 13136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661728632075,
            21.02048803285199,
            105.83679982467422,
            21.02057282488998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661728632075,
              21.02057282488998
            ],
            [
              105.83675739452987,
              21.020507742183728
            ],
            [
              105.83679982467422,
              21.02048803285199
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_7.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13208",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02057282488998,
          "to_latitude": 21.02048803285199,
          "to_longitude": 105.83679982467422,
          "from_longitude": 105.83661728632075
        }
      },
      {
        "id": 13137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83679982467422,
            21.020386133741294,
            105.83701243076213,
            21.02048803285199
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83679982467422,
              21.02048803285199
            ],
            [
              105.83690153961038,
              21.020439144027428
            ],
            [
              105.8369538307777,
              21.020414253120187
            ],
            [
              105.83701243076213,
              21.020386133741294
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_7.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13209",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02048803285199,
          "to_latitude": 21.020386133741294,
          "to_longitude": 105.83701243076213,
          "from_longitude": 105.83679982467422
        }
      },
      {
        "id": 13138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83613952631526,
            21.021037848652586,
            105.83650694250777,
            21.021045785195128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83613952631526,
              21.021037848652586
            ],
            [
              105.83634288070535,
              21.021042241132612
            ],
            [
              105.83650694250777,
              21.021045785195128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13210",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021037848652586,
          "to_latitude": 21.021045785195128,
          "to_longitude": 105.83650694250777,
          "from_longitude": 105.83613952631526
        }
      },
      {
        "id": 13139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83653189161437,
            21.02198994922854,
            105.83703059449478,
            21.022084768896942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83703059449478,
              21.02200088167872
            ],
            [
              105.83700830783647,
              21.021995960399334
            ],
            [
              105.83694943303438,
              21.02198994922854
            ],
            [
              105.83677872228016,
              21.02200731724967
            ],
            [
              105.83673656849976,
              21.022009741139655
            ],
            [
              105.83672909056807,
              21.022010171732404
            ],
            [
              105.83666428939196,
              21.022045947448664
            ],
            [
              105.83665640864388,
              21.022046768405882
            ],
            [
              105.83658306106888,
              21.022054411878752
            ],
            [
              105.83653189161437,
              21.022084768896942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.11_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13211",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02200088167872,
          "to_latitude": 21.022084768896942,
          "to_longitude": 105.83653189161437,
          "from_longitude": 105.83703059449478
        }
      },
      {
        "id": 13140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723599359357,
            21.02166062064873,
            105.83735721908386,
            21.02175910749523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723599359357,
              21.02175910749523
            ],
            [
              105.83732595397639,
              21.02166062064873
            ],
            [
              105.83733067520578,
              21.02166335133509
            ],
            [
              105.83735721908386,
              21.02167801275118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.14_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13212",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02175910749523,
          "to_latitude": 21.02167801275118,
          "to_longitude": 105.83735721908386,
          "from_longitude": 105.83723599359357
        }
      },
      {
        "id": 13141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83746409929738,
            21.02147496924423,
            105.83753531878563,
            21.021598435519675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83746409929738,
              21.021598435519675
            ],
            [
              105.83750537614722,
              21.02154822441088
            ],
            [
              105.83753531878563,
              21.02148591984452
            ],
            [
              105.83752203899398,
              21.021481659697372
            ],
            [
              105.83750118355465,
              21.02147496924423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.16_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13213",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021598435519675,
          "to_latitude": 21.02147496924423,
          "to_longitude": 105.83750118355465,
          "from_longitude": 105.83746409929738
        }
      },
      {
        "id": 13142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83753531878563,
            21.021388701824527,
            105.83761813651371,
            21.02148591984452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753531878563,
              21.02148591984452
            ],
            [
              105.83754276831247,
              21.021470419976648
            ],
            [
              105.83756266839872,
              21.021421978755722
            ],
            [
              105.83761813651371,
              21.021388701824527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.16_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13214",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02148591984452,
          "to_latitude": 21.021388701824527,
          "to_longitude": 105.83761813651371,
          "from_longitude": 105.83753531878563
        }
      },
      {
        "id": 13143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83460770857194,
            21.023055747857384,
            105.8351511120365,
            21.023100919766136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83514217997394,
              21.023056278224043
            ],
            [
              105.83515086085099,
              21.023055747857384
            ],
            [
              105.8351511120365,
              21.023059221325603
            ],
            [
              105.8351422029359,
              21.02305936442673
            ],
            [
              105.8351350146673,
              21.023059479206765
            ],
            [
              105.83512550123905,
              21.023076511945053
            ],
            [
              105.83503644051217,
              21.023075436327723
            ],
            [
              105.8349099551095,
              21.02307390761017
            ],
            [
              105.83480105596365,
              21.023072591447995
            ],
            [
              105.83460770857194,
              21.023100919766136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_19.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13215",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023056278224043,
          "to_latitude": 21.023100919766136,
          "to_longitude": 105.83460770857194,
          "from_longitude": 105.83514217997394
        }
      },
      {
        "id": 13144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612590686353,
            21.02194974598471,
            105.8364493375105,
            21.02212045391124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612590686353,
              21.022050379697156
            ],
            [
              105.83621584414944,
              21.02197173644925
            ],
            [
              105.83625435835212,
              21.02194974598471
            ],
            [
              105.8362951358709,
              21.02197480594751
            ],
            [
              105.83634926824347,
              21.022017405867015
            ],
            [
              105.83643312154747,
              21.0220833949096
            ],
            [
              105.8364493375105,
              21.02212045391124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13216",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022050379697156,
          "to_latitude": 21.02212045391124,
          "to_longitude": 105.8364493375105,
          "from_longitude": 105.83612590686353
        }
      },
      {
        "id": 13145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83601355119805,
            21.022050379697156,
            105.83618942637527,
            21.02225274629718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83618942637527,
              21.02225274629718
            ],
            [
              105.83601355119805,
              21.02224559475098
            ],
            [
              105.83601695835422,
              21.022219338453585
            ],
            [
              105.83602641421537,
              21.022146459632495
            ],
            [
              105.83603743962287,
              21.022127857740937
            ],
            [
              105.83608472399361,
              21.02208598218721
            ],
            [
              105.83612590686353,
              21.022050379697156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13217",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02225274629718,
          "to_latitude": 21.022050379697156,
          "to_longitude": 105.83612590686353,
          "from_longitude": 105.83618942637527
        }
      },
      {
        "id": 13146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568309216434,
            21.020782501475377,
            105.83609734578236,
            21.020904042210745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83568309216434,
              21.020904042210745
            ],
            [
              105.83576489954538,
              21.020875562540866
            ],
            [
              105.83587723282972,
              21.020843780451088
            ],
            [
              105.8360212090463,
              21.020799284573446
            ],
            [
              105.83609734578236,
              21.020782501475377
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13218",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": 45.153,
          "from_latitude": 21.020904042210745,
          "to_latitude": 21.020782501475377,
          "to_longitude": 105.83609734578236,
          "from_longitude": 105.83568309216434
        }
      },
      {
        "id": 13147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350393976545,
            21.021659596914738,
            105.83504574011324,
            21.021853189779083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504574011324,
              21.021853189779083
            ],
            [
              105.83504084239568,
              21.02169974344427
            ],
            [
              105.8350393976545,
              21.021667121574588
            ],
            [
              105.83503956161336,
              21.021659596914738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_8.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13219",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021853189779083,
          "to_latitude": 21.021659596914738,
          "to_longitude": 105.83503956161336,
          "from_longitude": 105.83504574011324
        }
      },
      {
        "id": 13148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502103137366,
            21.02165356023523,
            105.8353291806432,
            21.021659596914738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502103137366,
              21.021657748664843
            ],
            [
              105.83502252038384,
              21.021657898515777
            ],
            [
              105.83503956161336,
              21.021659596914738
            ],
            [
              105.8353291806432,
              21.02165356023523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_8.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13220",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021657748664843,
          "to_latitude": 21.02165356023523,
          "to_longitude": 105.8353291806432,
          "from_longitude": 105.83502103137366
        }
      },
      {
        "id": 13149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755611984085,
            21.019087284598694,
            105.83770198296988,
            21.01950600715754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755611984085,
              21.019087284598694
            ],
            [
              105.83755648723626,
              21.019093300066107
            ],
            [
              105.83756143820555,
              21.019169906091278
            ],
            [
              105.83759084351404,
              21.019256394907277
            ],
            [
              105.83766612721963,
              21.019425477988275
            ],
            [
              105.83770198296988,
              21.01950600715754
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.5_Ngõ 98, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13221",
          "diaChiLapD": "Ngõ 98, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019087284598694,
          "to_latitude": 21.01950600715754,
          "to_longitude": 105.83770198296988,
          "from_longitude": 105.83755611984085
        }
      },
      {
        "id": 13150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046535729834,
            21.019230829649594,
            105.84069000146769,
            21.019279080759006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84069000146769,
              21.019230829649594
            ],
            [
              105.84067712329542,
              21.019232811741386
            ],
            [
              105.84063337160376,
              21.019271443849
            ],
            [
              105.84046535729834,
              21.019279080759006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.12_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13222",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019230829649594,
          "to_latitude": 21.019279080759006,
          "to_longitude": 105.84046535729834,
          "from_longitude": 105.84069000146769
        }
      },
      {
        "id": 13151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83911559714315,
            21.019819634326634,
            105.83918653371512,
            21.01993042510885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83911559714315,
              21.019819634326634
            ],
            [
              105.83918653371512,
              21.01993042510885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.6_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13223",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019819634326634,
          "to_latitude": 21.01993042510885,
          "to_longitude": 105.83918653371512,
          "from_longitude": 105.83911559714315
        }
      },
      {
        "id": 13152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538666515187,
            21.02093659039751,
            105.83559390871649,
            21.021006514536218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538666515187,
              21.021006514536218
            ],
            [
              105.83545074580864,
              21.0209785274745
            ],
            [
              105.83548762618759,
              21.020964757092198
            ],
            [
              105.83553020625119,
              21.020952238561982
            ],
            [
              105.83557412742766,
              21.020940032994265
            ],
            [
              105.83559390871649,
              21.02093659039751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.5.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13224",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": 22.964,
          "from_latitude": 21.021006514536218,
          "to_latitude": 21.02093659039751,
          "to_longitude": 105.83559390871649,
          "from_longitude": 105.83538666515187
        }
      },
      {
        "id": 13153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83677573710739,
            21.021934786626915,
            105.83712563437795,
            21.02233325743605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712563437795,
              21.021934786626915
            ],
            [
              105.8371027596302,
              21.021959444307424
            ],
            [
              105.83695925879272,
              21.02213094735816
            ],
            [
              105.83689670754183,
              21.02220875082253
            ],
            [
              105.83686421430502,
              21.022249167372372
            ],
            [
              105.83686068620835,
              21.022253555430034
            ],
            [
              105.8368100174957,
              21.022306413881523
            ],
            [
              105.83677573710739,
              21.02233325743605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.10_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13225",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021934786626915,
          "to_latitude": 21.02233325743605,
          "to_longitude": 105.83677573710739,
          "from_longitude": 105.83712563437795
        }
      },
      {
        "id": 13154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83732595397639,
            21.021453622891986,
            105.83748018373426,
            21.02166062064873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83732595397639,
              21.02166062064873
            ],
            [
              105.83740350380364,
              21.021605884681243
            ],
            [
              105.83744083037242,
              21.021562312760775
            ],
            [
              105.83745257440488,
              21.021534798751137
            ],
            [
              105.83746168343681,
              21.021513458601866
            ],
            [
              105.83748018373426,
              21.021453622891986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.14_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13226",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02166062064873,
          "to_latitude": 21.021453622891986,
          "to_longitude": 105.83748018373426,
          "from_longitude": 105.83732595397639
        }
      },
      {
        "id": 13155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570282483876,
            21.02126311542187,
            105.83594233999554,
            21.021473978501586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83570282483876,
              21.021473978501586
            ],
            [
              105.83571036950512,
              21.021461361331443
            ],
            [
              105.83573854099029,
              21.021430480788705
            ],
            [
              105.83579967082645,
              21.021372710997763
            ],
            [
              105.8358207207253,
              21.021357351207882
            ],
            [
              105.83589695749326,
              21.021307032808334
            ],
            [
              105.83590108989476,
              21.02130371212035
            ],
            [
              105.83592307732692,
              21.02128604268377
            ],
            [
              105.83593881466855,
              21.02126731106073
            ],
            [
              105.83594233999554,
              21.02126311542187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13227",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021473978501586,
          "to_latitude": 21.02126311542187,
          "to_longitude": 105.83594233999554,
          "from_longitude": 105.83570282483876
        }
      },
      {
        "id": 13156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584000461538,
            21.021174183731166,
            105.83599440540749,
            21.021327699351758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584000461538,
              21.021327699351758
            ],
            [
              105.83590570037491,
              21.021267945285036
            ],
            [
              105.83597783947906,
              21.021193198527584
            ],
            [
              105.83598137423624,
              21.021189141938066
            ],
            [
              105.83599440540749,
              21.021174183731166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13228",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021327699351758,
          "to_latitude": 21.021174183731166,
          "to_longitude": 105.83599440540749,
          "from_longitude": 105.83584000461538
        }
      },
      {
        "id": 13157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593343463325,
            21.02104907166936,
            105.83606704255784,
            21.021174183731166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83599440540749,
              21.021174183731166
            ],
            [
              105.8360303973445,
              21.021132902922037
            ],
            [
              105.83606195522383,
              21.021083802111846
            ],
            [
              105.83606704255784,
              21.021049372409486
            ],
            [
              105.83593343463325,
              21.02104907166936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13229",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021174183731166,
          "to_latitude": 21.02104907166936,
          "to_longitude": 105.83593343463325,
          "from_longitude": 105.83599440540749
        }
      },
      {
        "id": 13158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83549887005907,
            21.02297847836735,
            105.83584425489575,
            21.023010672179474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584425489575,
              21.022995649909326
            ],
            [
              105.83584151510037,
              21.022995638083515
            ],
            [
              105.83574399964564,
              21.022992853034605
            ],
            [
              105.83571786996873,
              21.02297847836735
            ],
            [
              105.83564214119778,
              21.02298806153072
            ],
            [
              105.83563888776098,
              21.022988577901437
            ],
            [
              105.83549887005907,
              21.023010672179474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_18.10_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13230",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022995649909326,
          "to_latitude": 21.023010672179474,
          "to_longitude": 105.83549887005907,
          "from_longitude": 105.83584425489575
        }
      },
      {
        "id": 13159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605678423919,
            21.026179271291983,
            105.83613310355344,
            21.02623418132727
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605678423919,
              21.02623418132727
            ],
            [
              105.83613054335012,
              21.02618111290677
            ],
            [
              105.83613310355344,
              21.026179271291983
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.5_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13232",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02623418132727,
          "to_latitude": 21.026179271291983,
          "to_longitude": 105.83613310355344,
          "from_longitude": 105.83605678423919
        }
      },
      {
        "id": 13160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584807971023,
            21.026144007269252,
            105.83609011078968,
            21.026247367342478
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584807971023,
              21.026247367342478
            ],
            [
              105.8359929880739,
              21.02617830995277
            ],
            [
              105.83601130755115,
              21.02618487123125
            ],
            [
              105.83608486536033,
              21.026144007269252
            ],
            [
              105.83609011078968,
              21.026153426485774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.5_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13233",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026247367342478,
          "to_latitude": 21.026153426485774,
          "to_longitude": 105.83609011078968,
          "from_longitude": 105.83584807971023
        }
      },
      {
        "id": 13161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620755056572,
            21.026319478975783,
            105.83633122644281,
            21.026433210441954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620755056572,
              21.026433210441954
            ],
            [
              105.8363282135078,
              21.026322249526977
            ],
            [
              105.83633122644281,
              21.026319478975783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.3_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13234",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026433210441954,
          "to_latitude": 21.026319478975783,
          "to_longitude": 105.83633122644281,
          "from_longitude": 105.83620755056572
        }
      },
      {
        "id": 13162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83638201641942,
            21.026431090902495,
            105.8364293483796,
            21.026470001520025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83638201641942,
              21.026470001520025
            ],
            [
              105.8364293483796,
              21.026431090902495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.3_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13235",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026470001520025,
          "to_latitude": 21.026431090902495,
          "to_longitude": 105.8364293483796,
          "from_longitude": 105.83638201641942
        }
      },
      {
        "id": 13163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612901072888,
            21.0262783918667,
            105.8364293483796,
            21.026441875563513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364293483796,
              21.026431090902495
            ],
            [
              105.8363700229424,
              21.026363585850834
            ],
            [
              105.83633167545128,
              21.02631995186298
            ],
            [
              105.83633122644281,
              21.026319478975783
            ],
            [
              105.83630654366995,
              21.026293502630914
            ],
            [
              105.83629218514696,
              21.0262783918667
            ],
            [
              105.83628775781983,
              21.026282498014005
            ],
            [
              105.83615856734708,
              21.026412202066215
            ],
            [
              105.83612901072888,
              21.026441875563513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.3_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13236",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026431090902495,
          "to_latitude": 21.026441875563513,
          "to_longitude": 105.83612901072888,
          "from_longitude": 105.8364293483796
        }
      },
      {
        "id": 13164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143529936468,
            21.0205006003789,
            105.83145218520252,
            21.0205818279141
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144305787107,
              21.0205006003789
            ],
            [
              105.83143529936468,
              21.02050264075172
            ],
            [
              105.83144423214033,
              21.02054930012301
            ],
            [
              105.83145206808211,
              21.020581350680796
            ],
            [
              105.83145218520252,
              21.0205818279141
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.4_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13238",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0205006003789,
          "to_latitude": 21.0205818279141,
          "to_longitude": 105.83145218520252,
          "from_longitude": 105.83144305787107
        }
      },
      {
        "id": 13165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133676218932,
            21.0205818279141,
            105.83145218520252,
            21.020611636684038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83145218520252,
              21.0205818279141
            ],
            [
              105.83144885825558,
              21.020582786239878
            ],
            [
              105.83137910854848,
              21.020602873466153
            ],
            [
              105.83133676218932,
              21.020611636684038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.4_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13239",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0205818279141,
          "to_latitude": 21.020611636684038,
          "to_longitude": 105.83133676218932,
          "from_longitude": 105.83145218520252
        }
      },
      {
        "id": 13166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83127725507082,
            21.020321112463016,
            105.83133676218932,
            21.020611636684038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133676218932,
              21.020611636684038
            ],
            [
              105.83131366653983,
              21.020536202046866
            ],
            [
              105.83131176162269,
              21.02052887446306
            ],
            [
              105.83130413405515,
              21.02049952713389
            ],
            [
              105.83130086370052,
              21.020475294244925
            ],
            [
              105.83130463948422,
              21.020452052079776
            ],
            [
              105.83130408878205,
              21.020443466911274
            ],
            [
              105.83130222201349,
              21.020433303015395
            ],
            [
              105.83129500698548,
              21.020401325285402
            ],
            [
              105.83127725507082,
              21.02033854941135
            ],
            [
              105.8312876647836,
              21.020333217383644
            ],
            [
              105.83128009715392,
              21.020321447414553
            ],
            [
              105.83127988270239,
              21.020321112463016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.4_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13240",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020611636684038,
          "to_latitude": 21.020321112463016,
          "to_longitude": 105.83127988270239,
          "from_longitude": 105.83133676218932
        }
      },
      {
        "id": 13167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83126762300525,
            21.02032189304887,
            105.83133521291121,
            21.020619006439944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83127836327323,
              21.02032189304887
            ],
            [
              105.83127846530849,
              21.02032207861451
            ],
            [
              105.83128038714436,
              21.020325517734143
            ],
            [
              105.83128357481806,
              21.020330845632813
            ],
            [
              105.83126762300525,
              21.020338917128235
            ],
            [
              105.83127159254207,
              21.0203495855865
            ],
            [
              105.83127484452795,
              21.0203611354857
            ],
            [
              105.83128858003857,
              21.020407912299365
            ],
            [
              105.83129587709136,
              21.020442713109023
            ],
            [
              105.83129745846561,
              21.020452238916686
            ],
            [
              105.83129578973659,
              21.020473634528432
            ],
            [
              105.83129574958078,
              21.02049641134049
            ],
            [
              105.83130921511074,
              21.02054931784982
            ],
            [
              105.8313231000883,
              21.020593031093686
            ],
            [
              105.83133521291121,
              21.020619006439944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13241",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02032189304887,
          "to_latitude": 21.020619006439944,
          "to_longitude": 105.83133521291121,
          "from_longitude": 105.83127836327323
        }
      },
      {
        "id": 13168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133521291121,
            21.020589309849182,
            105.83145192204766,
            21.020619006439944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133521291121,
              21.020619006439944
            ],
            [
              105.83145189288392,
              21.020589309849182
            ],
            [
              105.83145192204766,
              21.02058936480332
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13242",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020619006439944,
          "to_latitude": 21.02058936480332,
          "to_longitude": 105.83145192204766,
          "from_longitude": 105.83133521291121
        }
      },
      {
        "id": 13169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83145192204766,
            21.02058936480332,
            105.83154261965659,
            21.020620468362996
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83145192204766,
              21.02058936480332
            ],
            [
              105.83146523244372,
              21.02061428112594
            ],
            [
              105.83154258462093,
              21.020620395373086
            ],
            [
              105.83154261965659,
              21.020620468362996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13243",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02058936480332,
          "to_latitude": 21.020620468362996,
          "to_longitude": 105.83154261965659,
          "from_longitude": 105.83145192204766
        }
      },
      {
        "id": 13170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154261965659,
            21.020620468362996,
            105.83159983400395,
            21.020739512746335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83154261965659,
              21.020620468362996
            ],
            [
              105.83155031570418,
              21.020636481040288
            ],
            [
              105.83156488935384,
              21.020666805106803
            ],
            [
              105.83159142167526,
              21.02072201054346
            ],
            [
              105.83159983400395,
              21.020739512746335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13244",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020620468362996,
          "to_latitude": 21.020739512746335,
          "to_longitude": 105.83159983400395,
          "from_longitude": 105.83154261965659
        }
      },
      {
        "id": 13171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83154585064433,
            21.020739512746335,
            105.83159986028696,
            21.020774119068356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83159983400395,
              21.020739512746335
            ],
            [
              105.83159986028696,
              21.020739568617753
            ],
            [
              105.83154585064433,
              21.020774119068356
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.3_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13245",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020739512746335,
          "to_latitude": 21.020774119068356,
          "to_longitude": 105.83154585064433,
          "from_longitude": 105.83159983400395
        }
      },
      {
        "id": 13172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83142920549385,
            21.02045132041241,
            105.83144305787107,
            21.0205006003789
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83144305787107,
              21.0205006003789
            ],
            [
              105.83142920549385,
              21.02045132041241
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_98.4_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13246",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0205006003789,
          "to_latitude": 21.02045132041241,
          "to_longitude": 105.83142920549385,
          "from_longitude": 105.83144305787107
        }
      },
      {
        "id": 13173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83095927390383,
            21.019262996242958,
            105.83102370365927,
            21.019710699538045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8309767532181,
              21.019262996242958
            ],
            [
              105.83095927390383,
              21.01934033715866
            ],
            [
              105.83098155734936,
              21.019580533971006
            ],
            [
              105.83102367527762,
              21.019710612063896
            ],
            [
              105.83102370365927,
              21.019710699538045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13247",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019262996242958,
          "to_latitude": 21.019710699538045,
          "to_longitude": 105.83102370365927,
          "from_longitude": 105.8309767532181
        }
      },
      {
        "id": 13174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83102370365927,
            21.019654776667057,
            105.83122033454019,
            21.019710699538045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83102370365927,
              21.019710699538045
            ],
            [
              105.83122033454019,
              21.019654776667057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13248",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019710699538045,
          "to_latitude": 21.019654776667057,
          "to_longitude": 105.83122033454019,
          "from_longitude": 105.83102370365927
        }
      },
      {
        "id": 13175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83122033454019,
            21.019654776667057,
            105.83124804548477,
            21.019717020662586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83122033454019,
              21.019654776667057
            ],
            [
              105.83124804548477,
              21.019717020662586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13249",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019654776667057,
          "to_latitude": 21.019717020662586,
          "to_longitude": 105.83124804548477,
          "from_longitude": 105.83122033454019
        }
      },
      {
        "id": 13176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83104037110071,
            21.019717020662586,
            105.83124804548477,
            21.01977495470357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124804548477,
              21.019717020662586
            ],
            [
              105.83104037110071,
              21.019774316229356
            ],
            [
              105.83104057761331,
              21.01977495470357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13250",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019717020662586,
          "to_latitude": 21.01977495470357,
          "to_longitude": 105.83104057761331,
          "from_longitude": 105.83124804548477
        }
      },
      {
        "id": 13177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83104057761331,
            21.01977495470357,
            105.83108678694964,
            21.01991372258211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83104057761331,
              21.01977495470357
            ],
            [
              105.8310802766365,
              21.01989745389355
            ],
            [
              105.83108678694964,
              21.01991372258211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_97.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13251",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01977495470357,
          "to_latitude": 21.01991372258211,
          "to_longitude": 105.83108678694964,
          "from_longitude": 105.83104057761331
        }
      },
      {
        "id": 13178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83164592637557,
            21.020142234254042,
            105.8317509914537,
            21.020643668076954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165424164613,
              21.020142234254042
            ],
            [
              105.831653111616,
              21.020143152035885
            ],
            [
              105.83164592637557,
              21.020146564322825
            ],
            [
              105.83168283203216,
              21.02022684041864
            ],
            [
              105.8316878435983,
              21.020236156160536
            ],
            [
              105.83170927895468,
              21.020276368588423
            ],
            [
              105.83172621393952,
              21.020319301853625
            ],
            [
              105.83173476131063,
              21.0203550828496
            ],
            [
              105.83174524668328,
              21.02039946932443
            ],
            [
              105.83174510431081,
              21.0203996425365
            ],
            [
              105.83172661107652,
              21.020416573586193
            ],
            [
              105.83171378158393,
              21.02043708893549
            ],
            [
              105.83174410920363,
              21.020591730751153
            ],
            [
              105.8317509914537,
              21.02062682259748
            ],
            [
              105.83169661433878,
              21.020643668076954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_100.1_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13252",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020142234254042,
          "to_latitude": 21.020643668076954,
          "to_longitude": 105.83169661433878,
          "from_longitude": 105.83165424164613
        }
      },
      {
        "id": 13179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177111867339,
            21.019457872051376,
            105.83178929594062,
            21.019766589352077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83177111867339,
              21.019457872051376
            ],
            [
              105.83177178900513,
              21.019461486188387
            ],
            [
              105.8317802052867,
              21.019506767041445
            ],
            [
              105.83177791245443,
              21.019637277957557
            ],
            [
              105.83178929594062,
              21.019766589352077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_101.2_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13253",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019457872051376,
          "to_latitude": 21.019766589352077,
          "to_longitude": 105.83178929594062,
          "from_longitude": 105.83177111867339
        }
      },
      {
        "id": 13180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83178120837877,
            21.019766589352077,
            105.83179178981673,
            21.0200016711708
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178929594062,
              21.019766589352077
            ],
            [
              105.83178955167658,
              21.01978997620013
            ],
            [
              105.83178120837877,
              21.019803470529183
            ],
            [
              105.83178799501228,
              21.019930591498863
            ],
            [
              105.83179178981673,
              21.0200016711708
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_101.2_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13254",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019766589352077,
          "to_latitude": 21.0200016711708,
          "to_longitude": 105.83179178981673,
          "from_longitude": 105.83178929594062
        }
      },
      {
        "id": 13181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83178906352789,
            21.0194565814821,
            105.83181108497473,
            21.019915316156343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83179196299702,
              21.0194565814821
            ],
            [
              105.83179197289417,
              21.01946270168402
            ],
            [
              105.83179219254288,
              21.019596302891454
            ],
            [
              105.83179061936806,
              21.019626414158466
            ],
            [
              105.83178906352789,
              21.019656182115263
            ],
            [
              105.83179428101056,
              21.019715805557254
            ],
            [
              105.8318068556542,
              21.019884525136803
            ],
            [
              105.83181108497473,
              21.019915316156343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_101.3_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13255",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0194565814821,
          "to_latitude": 21.019915316156343,
          "to_longitude": 105.83181108497473,
          "from_longitude": 105.83179196299702
        }
      },
      {
        "id": 13182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83168105952159,
            21.020176957667612,
            105.83190085226012,
            21.020408426562017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168105952159,
              21.020176957667612
            ],
            [
              105.8316816366022,
              21.02018526541567
            ],
            [
              105.83168142703046,
              21.020204196221645
            ],
            [
              105.83170930826599,
              21.02026743103775
            ],
            [
              105.8317139104934,
              21.02027786968197
            ],
            [
              105.83176143538697,
              21.020406187832027
            ],
            [
              105.83176945233852,
              21.020408426562017
            ],
            [
              105.83187122924399,
              21.020397803699474
            ],
            [
              105.83190085226012,
              21.020395755729783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_100.2_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13256",
          "diaChiLapD": "Ngõ Đoàn Kết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020176957667612,
          "to_latitude": 21.020395755729783,
          "to_longitude": 105.83190085226012,
          "from_longitude": 105.83168105952159
        }
      },
      {
        "id": 13183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83222253504441,
            21.01962281435933,
            105.83263275155451,
            21.019694049474865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83222253504441,
              21.019694049474865
            ],
            [
              105.83223547926279,
              21.01969128369707
            ],
            [
              105.83245354066669,
              21.019637173011162
            ],
            [
              105.83263275155451,
              21.01962281435933
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13257",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019694049474865,
          "to_latitude": 21.01962281435933,
          "to_longitude": 105.83263275155451,
          "from_longitude": 105.83222253504441
        }
      },
      {
        "id": 13184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83263275155451,
            21.01962281435933,
            105.83263733136306,
            21.01974407602367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263275155451,
              21.01962281435933
            ],
            [
              105.83263522980788,
              21.01970238172618
            ],
            [
              105.83263533464985,
              21.019704458629327
            ],
            [
              105.83263733136306,
              21.01974407602367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13258",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01962281435933,
          "to_latitude": 21.01974407602367,
          "to_longitude": 105.83263733136306,
          "from_longitude": 105.83263275155451
        }
      },
      {
        "id": 13185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252601875641,
            21.01974407602367,
            105.83263733136306,
            21.019754997098484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83263733136306,
              21.01974407602367
            ],
            [
              105.83252601875641,
              21.019754997098484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_102.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13259",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01974407602367,
          "to_latitude": 21.019754997098484,
          "to_longitude": 105.83252601875641,
          "from_longitude": 105.83263733136306
        }
      },
      {
        "id": 13186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252684860253,
            21.01943348626499,
            105.83283857304558,
            21.019450833082097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83252684860253,
              21.019450833082097
            ],
            [
              105.83263645910503,
              21.019444732724637
            ],
            [
              105.83265180534602,
              21.01944387902649
            ],
            [
              105.83270916766084,
              21.019440687137745
            ],
            [
              105.83278655951699,
              21.01943638104362
            ],
            [
              105.83283857304558,
              21.01943348626499
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_104.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13260",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019450833082097,
          "to_latitude": 21.01943348626499,
          "to_longitude": 105.83283857304558,
          "from_longitude": 105.83252684860253
        }
      },
      {
        "id": 13187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83285183172096,
            21.019417445498018,
            105.83311385402044,
            21.019432959801495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83285183172096,
              21.019432959801495
            ],
            [
              105.83293625069851,
              21.01942796168616
            ],
            [
              105.83298745252566,
              21.01942492993375
            ],
            [
              105.8330540339368,
              21.019420987365226
            ],
            [
              105.83311385402044,
              21.019417445498018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13261",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019432959801495,
          "to_latitude": 21.019417445498018,
          "to_longitude": 105.83311385402044,
          "from_longitude": 105.83285183172096
        }
      },
      {
        "id": 13188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323673229878,
            21.019909166906018,
            105.83282230065444,
            21.020038058848616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323673229878,
              21.02002489669821
            ],
            [
              105.83237206310673,
              21.020023083311866
            ],
            [
              105.83249599412397,
              21.019986361388003
            ],
            [
              105.8325018878796,
              21.019984418610274
            ],
            [
              105.8325034714982,
              21.019984805568846
            ],
            [
              105.83250400225316,
              21.019983720908286
            ],
            [
              105.8325096521174,
              21.01998185880772
            ],
            [
              105.83251311437414,
              21.019989335916154
            ],
            [
              105.83253446152135,
              21.020038058848616
            ],
            [
              105.83263880071549,
              21.0200010894849
            ],
            [
              105.83268653473901,
              21.01998417597448
            ],
            [
              105.8326968517171,
              21.019980520700965
            ],
            [
              105.83269694089655,
              21.019980469683833
            ],
            [
              105.83273285305532,
              21.019960043585982
            ],
            [
              105.83282230065444,
              21.019909166906018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_103.2_Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13262",
          "diaChiLapD": "Ngách 32, Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02002489669821,
          "to_latitude": 21.019909166906018,
          "to_longitude": 105.83282230065444,
          "from_longitude": 105.8323673229878
        }
      },
      {
        "id": 13189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341414523368,
            21.019347937578996,
            105.83365264590377,
            21.01981360464823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353114833476,
              21.019347937578996
            ],
            [
              105.83353154058652,
              21.01935360790599
            ],
            [
              105.8335315677605,
              21.019353997062538
            ],
            [
              105.8335345825328,
              21.01939258971241
            ],
            [
              105.8335348414906,
              21.01939591230917
            ],
            [
              105.83349503058078,
              21.019399692281375
            ],
            [
              105.83349870758985,
              21.019446321287163
            ],
            [
              105.83350175820692,
              21.019494825745962
            ],
            [
              105.8334831178149,
              21.019511358475505
            ],
            [
              105.83345147370484,
              21.01953292626464
            ],
            [
              105.83343020754573,
              21.019569263306497
            ],
            [
              105.83341414523368,
              21.019596707896113
            ],
            [
              105.83342873401185,
              21.01962814901308
            ],
            [
              105.8334358745726,
              21.01964353838975
            ],
            [
              105.83348223994557,
              21.019691607520453
            ],
            [
              105.8335276416717,
              21.01973867607074
            ],
            [
              105.83354800744766,
              21.01976793377424
            ],
            [
              105.8335947423271,
              21.019749556266795
            ],
            [
              105.83361026951253,
              21.01977623096879
            ],
            [
              105.83362300983794,
              21.01976966983332
            ],
            [
              105.83365264590377,
              21.01981360464823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_106.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13263",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019347937578996,
          "to_latitude": 21.01981360464823,
          "to_longitude": 105.83365264590377,
          "from_longitude": 105.83353114833476
        }
      },
      {
        "id": 13190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83373663946249,
            21.019336513638144,
            105.83379674903374,
            21.01952659394176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83373663946249,
              21.019336513638144
            ],
            [
              105.83373803200304,
              21.019340944354035
            ],
            [
              105.83373871528369,
              21.019343072614504
            ],
            [
              105.83376369213153,
              21.019420504646952
            ],
            [
              105.83379674903374,
              21.01948591760433
            ],
            [
              105.83379074360424,
              21.01952659394176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13264",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019336513638144,
          "to_latitude": 21.01952659394176,
          "to_longitude": 105.83379074360424,
          "from_longitude": 105.83373663946249
        }
      },
      {
        "id": 13191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364739490061,
            21.01952659394176,
            105.83379074360424,
            21.01985225944096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83379074360424,
              21.01952659394176
            ],
            [
              105.83364739490061,
              21.019585075791035
            ],
            [
              105.83372830074175,
              21.01975551149797
            ],
            [
              105.83371354788599,
              21.019772261843027
            ],
            [
              105.8337283056929,
              21.01985225944096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13265",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01952659394176,
          "to_latitude": 21.01985225944096,
          "to_longitude": 105.8337283056929,
          "from_longitude": 105.83379074360424
        }
      },
      {
        "id": 13192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83379074360424,
            21.01952659394176,
            105.83382176122265,
            21.019629730115838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83379074360424,
              21.01952659394176
            ],
            [
              105.83380388550475,
              21.0195702907417
            ],
            [
              105.83382176122265,
              21.019629730115838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_106.4_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13266",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01952659394176,
          "to_latitude": 21.019629730115838,
          "to_longitude": 105.83382176122265,
          "from_longitude": 105.83379074360424
        }
      },
      {
        "id": 13193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397785615531,
            21.019323762435114,
            105.83405302964447,
            21.01963546532948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83397864496034,
              21.019323762435114
            ],
            [
              105.8339785779327,
              21.01932675875874
            ],
            [
              105.83397849969325,
              21.01933016068506
            ],
            [
              105.83397785615531,
              21.01935939669466
            ],
            [
              105.83405302964447,
              21.01963546532948
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_106.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13267",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019323762435114,
          "to_latitude": 21.01963546532948,
          "to_longitude": 105.83405302964447,
          "from_longitude": 105.83397864496034
        }
      },
      {
        "id": 13194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83375760582841,
            21.01935939669466,
            105.83397785615531,
            21.019384000861027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83375760582841,
              21.019384000861027
            ],
            [
              105.83391672112703,
              21.019366225821635
            ],
            [
              105.83397785615531,
              21.01935939669466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_106.5_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13268",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019384000861027,
          "to_latitude": 21.01935939669466,
          "to_longitude": 105.83397785615531,
          "from_longitude": 105.83375760582841
        }
      },
      {
        "id": 13195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402862279254,
            21.019341741744114,
            105.8343548599791,
            21.019361321852085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83402862279254,
              21.019361321852085
            ],
            [
              105.83405705178758,
              21.019359450076927
            ],
            [
              105.83425872321142,
              21.01934617546369
            ],
            [
              105.83432608291754,
              21.019341741744114
            ],
            [
              105.8343540271543,
              21.019351987243827
            ],
            [
              105.8343548599791,
              21.01935229115833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13269",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019361321852085,
          "to_latitude": 21.01935229115833,
          "to_longitude": 105.8343548599791,
          "from_longitude": 105.83402862279254
        }
      },
      {
        "id": 13196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83402023727815,
            21.01961905077249,
            105.83441755476531,
            21.019931512420662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441755476531,
              21.01961905077249
            ],
            [
              105.83441712709073,
              21.019619122418664
            ],
            [
              105.8344047023386,
              21.01962118852401
            ],
            [
              105.83439347604991,
              21.019623056362974
            ],
            [
              105.83421993292284,
              21.019643404543068
            ],
            [
              105.83407498178428,
              21.019665069253275
            ],
            [
              105.83409952379793,
              21.019838269515073
            ],
            [
              105.83402023727815,
              21.019850458906788
            ],
            [
              105.83403350527524,
              21.019931512420662
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.3_Ngách 3, Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13270",
          "diaChiLapD": "Ngách 3, Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01961905077249,
          "to_latitude": 21.019931512420662,
          "to_longitude": 105.83403350527524,
          "from_longitude": 105.83441755476531
        }
      },
      {
        "id": 13197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435268343509,
            21.019436042462345,
            105.83444475541235,
            21.01977607437058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435268343509,
              21.019436042462345
            ],
            [
              105.8343971697025,
              21.019606408080698
            ],
            [
              105.8344047023386,
              21.01962118852401
            ],
            [
              105.8344123419551,
              21.019636177990154
            ],
            [
              105.83441671043255,
              21.019650218858672
            ],
            [
              105.83442779319729,
              21.0197010458214
            ],
            [
              105.8344319455199,
              21.01971548336186
            ],
            [
              105.83444237899576,
              21.019747643864278
            ],
            [
              105.83444475541235,
              21.019754970903087
            ],
            [
              105.83440302411385,
              21.01976475791894
            ],
            [
              105.83436068181464,
              21.01977607437058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13271",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019436042462345,
          "to_latitude": 21.01977607437058,
          "to_longitude": 105.83436068181464,
          "from_longitude": 105.83435268343509
        }
      },
      {
        "id": 13198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83438652989513,
            21.019323299517172,
            105.83457966080317,
            21.019339196225385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438652989513,
              21.019339196225385
            ],
            [
              105.83457966080317,
              21.019323299517172
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_112.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13272",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019339196225385,
          "to_latitude": 21.019323299517172,
          "to_longitude": 105.83457966080317,
          "from_longitude": 105.83438652989513
        }
      },
      {
        "id": 13199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83457742913524,
            21.019281137121872,
            105.83483644320145,
            21.019323299517172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83457742913524,
              21.019281137121872
            ],
            [
              105.83457773458387,
              21.019286932516206
            ],
            [
              105.83457807562661,
              21.019293419605646
            ],
            [
              105.83457966080317,
              21.019323299517172
            ],
            [
              105.83461910903138,
              21.019320044038693
            ],
            [
              105.83483644320145,
              21.01930214596595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_112.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13273",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019281137121872,
          "to_latitude": 21.01930214596595,
          "to_longitude": 105.83483644320145,
          "from_longitude": 105.83457742913524
        }
      },
      {
        "id": 13200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83519637153356,
            21.019416638165236,
            105.83536180807086,
            21.019447303888235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536180807086,
              21.019416638165236
            ],
            [
              105.83535816716105,
              21.019417176302376
            ],
            [
              105.83535154632816,
              21.01941879216809
            ],
            [
              105.83530507947616,
              21.01943013723446
            ],
            [
              105.83519637153356,
              21.019447303888235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.2_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13274",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019416638165236,
          "to_latitude": 21.019447303888235,
          "to_longitude": 105.83519637153356,
          "from_longitude": 105.83536180807086
        }
      },
      {
        "id": 13201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535154632816,
            21.01941879216809,
            105.83540022190276,
            21.01963557437598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535154632816,
              21.01941879216809
            ],
            [
              105.83536547608684,
              21.01948883381811
            ],
            [
              105.83540022190276,
              21.01963557437598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.2_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13275",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01941879216809,
          "to_latitude": 21.01963557437598,
          "to_longitude": 105.83540022190276,
          "from_longitude": 105.83535154632816
        }
      },
      {
        "id": 13202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540130822607,
            21.01960023408793,
            105.8356403452488,
            21.019635839463948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540130822607,
              21.01963078737287
            ],
            [
              105.83540383684432,
              21.01963054643871
            ],
            [
              105.83541873250428,
              21.019628934189054
            ],
            [
              105.83544555680909,
              21.019635839463948
            ],
            [
              105.83545886071691,
              21.01963324419177
            ],
            [
              105.8356403452488,
              21.01960023408793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.4_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13276",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01963078737287,
          "to_latitude": 21.01960023408793,
          "to_longitude": 105.8356403452488,
          "from_longitude": 105.83540130822607
        }
      },
      {
        "id": 13203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356403452488,
            21.019595845126894,
            105.83587073587455,
            21.019658542180633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356403452488,
              21.01960023408793
            ],
            [
              105.83564204669379,
              21.019610199128742
            ],
            [
              105.83575511758957,
              21.01959586584326
            ],
            [
              105.8357552896653,
              21.019595845126894
            ],
            [
              105.83576420179931,
              21.019642369760433
            ],
            [
              105.83582513532254,
              21.01963355928544
            ],
            [
              105.83582868472162,
              21.019658542180633
            ],
            [
              105.83587073587455,
              21.019651972336703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.4_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13277",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01960023408793,
          "to_latitude": 21.019651972336703,
          "to_longitude": 105.83587073587455,
          "from_longitude": 105.8356403452488
        }
      },
      {
        "id": 13204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356329429395,
            21.01949544403569,
            105.8357645873434,
            21.01960023408793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8356403452488,
              21.01960023408793
            ],
            [
              105.8356329429395,
              21.01951892067981
            ],
            [
              105.83564343521022,
              21.019517050043024
            ],
            [
              105.8357645873434,
              21.01949544403569
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.4_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13278",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01960023408793,
          "to_latitude": 21.01949544403569,
          "to_longitude": 105.8357645873434,
          "from_longitude": 105.8356403452488
        }
      },
      {
        "id": 13205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534264340096,
            21.019250083613194,
            105.83564059701894,
            21.019281329056835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534264340096,
              21.019281329056835
            ],
            [
              105.83534352660996,
              21.01928083426894
            ],
            [
              105.83536074197758,
              21.01927118729431
            ],
            [
              105.83549827249212,
              21.019261359476506
            ],
            [
              105.83564059701894,
              21.019250083613194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13279",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019281329056835,
          "to_latitude": 21.019250083613194,
          "to_longitude": 105.83564059701894,
          "from_longitude": 105.83534264340096
        }
      },
      {
        "id": 13206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83581624213656,
            21.01922997908586,
            105.83599052955023,
            21.019238509332492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83581624213656,
              21.019238509332492
            ],
            [
              105.83599052955023,
              21.01922997908586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_114.1_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13280",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019238509332492,
          "to_latitude": 21.01922997908586,
          "to_longitude": 105.83599052955023,
          "from_longitude": 105.83581624213656
        }
      },
      {
        "id": 13207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83500910738441,
            21.019712363310354,
            105.8353554403113,
            21.019872402416965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353554403113,
              21.019712363310354
            ],
            [
              105.83535206181581,
              21.019713039255343
            ],
            [
              105.8352656967199,
              21.019730294943034
            ],
            [
              105.83516748927607,
              21.019747622279887
            ],
            [
              105.83515533703152,
              21.019757509925654
            ],
            [
              105.83500910738441,
              21.01979487612983
            ],
            [
              105.83501079652108,
              21.01980557465624
            ],
            [
              105.83502827234427,
              21.019872402416965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.6_Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13281",
          "diaChiLapD": "Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019712363310354,
          "to_latitude": 21.019872402416965,
          "to_longitude": 105.83502827234427,
          "from_longitude": 105.8353554403113
        }
      },
      {
        "id": 13208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489594075525,
            21.019874647980597,
            105.83494443291387,
            21.020123018774132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489594075525,
              21.019874647980597
            ],
            [
              105.83494443291387,
              21.020123018774132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13282",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019874647980597,
          "to_latitude": 21.020123018774132,
          "to_longitude": 105.83494443291387,
          "from_longitude": 105.83489594075525
        }
      },
      {
        "id": 13209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463385534259,
            21.020123018774132,
            105.83494443291387,
            21.020275406698072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83494443291387,
              21.020123018774132
            ],
            [
              105.83489310743695,
              21.02013514719442
            ],
            [
              105.83487611718854,
              21.020150177138156
            ],
            [
              105.83475865741282,
              21.020172482472596
            ],
            [
              105.83477688515596,
              21.020207982757437
            ],
            [
              105.83467608702495,
              21.020262515495617
            ],
            [
              105.83463385534259,
              21.020275406698072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13283",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020123018774132,
          "to_latitude": 21.020275406698072,
          "to_longitude": 105.83463385534259,
          "from_longitude": 105.83494443291387
        }
      },
      {
        "id": 13210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538550491026,
            21.019741650060002,
            105.83576039059643,
            21.019832619940434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538550491026,
              21.019832619940434
            ],
            [
              105.83538579964632,
              21.01983251462219
            ],
            [
              105.83539491528711,
              21.01982924715076
            ],
            [
              105.83540055621532,
              21.01982768848243
            ],
            [
              105.83556081786251,
              21.01978340206326
            ],
            [
              105.83558160130917,
              21.019769459869895
            ],
            [
              105.835680425595,
              21.019741650060002
            ],
            [
              105.83569626216438,
              21.019764568339035
            ],
            [
              105.83576039059643,
              21.019743361698765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.9_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13284",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019832619940434,
          "to_latitude": 21.019743361698765,
          "to_longitude": 105.83576039059643,
          "from_longitude": 105.83538550491026
        }
      },
      {
        "id": 13211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592136468246,
            21.01918843333636,
            105.83606491396964,
            21.019648781824802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602823666212,
              21.01918843333636
            ],
            [
              105.83602866436593,
              21.01919130077681
            ],
            [
              105.8360200267318,
              21.019243722089453
            ],
            [
              105.83603413265567,
              21.01936118658527
            ],
            [
              105.83606491396964,
              21.019617509344492
            ],
            [
              105.83601693385766,
              21.019628492624516
            ],
            [
              105.83598266203046,
              21.01964419470479
            ],
            [
              105.83592465627223,
              21.019648781824802
            ],
            [
              105.83592136468246,
              21.019623284633916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_114.2_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13286",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01918843333636,
          "to_latitude": 21.019623284633916,
          "to_longitude": 105.83592136468246,
          "from_longitude": 105.83602823666212
        }
      },
      {
        "id": 13212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365444704743,
            21.019375730462208,
            105.83675937511673,
            21.019525117362086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675932788925,
              21.019375730462208
            ],
            [
              105.8367553397693,
              21.019377829301366
            ],
            [
              105.83675937511673,
              21.019388849555863
            ],
            [
              105.83674151458091,
              21.019405950536648
            ],
            [
              105.83670524456163,
              21.019429869280092
            ],
            [
              105.83666381917028,
              21.019449956607403
            ],
            [
              105.8365444704743,
              21.019525117362086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.1_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13287",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019375730462208,
          "to_latitude": 21.019525117362086,
          "to_longitude": 105.8365444704743,
          "from_longitude": 105.83675932788925
        }
      },
      {
        "id": 13213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83684961641991,
            21.019494314035132,
            105.83701529421633,
            21.019714098633152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701529421633,
              21.019714098633152
            ],
            [
              105.83698174034917,
              21.019685204319757
            ],
            [
              105.83696702974414,
              21.019664815974508
            ],
            [
              105.83694306172461,
              21.019630556304996
            ],
            [
              105.83691724236876,
              21.019592064201564
            ],
            [
              105.83689065898783,
              21.01955352888496
            ],
            [
              105.83688054139668,
              21.019537899600632
            ],
            [
              105.83687258473086,
              21.01952560974136
            ],
            [
              105.83685021584992,
              21.01949519895513
            ],
            [
              105.83684961641991,
              21.019494314035132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.2_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13288",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019714098633152,
          "to_latitude": 21.019494314035132,
          "to_longitude": 105.83684961641991,
          "from_longitude": 105.83701529421633
        }
      },
      {
        "id": 13214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668693562043,
            21.01919383675466,
            105.83684961641991,
            21.019494314035132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684961641991,
              21.019494314035132
            ],
            [
              105.83681781276177,
              21.019450651495774
            ],
            [
              105.83676568354564,
              21.01936969366281
            ],
            [
              105.8366888662979,
              21.01921918707436
            ],
            [
              105.83668693562043,
              21.01919383675466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.2_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13289",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019494314035132,
          "to_latitude": 21.01919383675466,
          "to_longitude": 105.83668693562043,
          "from_longitude": 105.83684961641991
        }
      },
      {
        "id": 13215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83664811410306,
            21.01954251802724,
            105.83688225180903,
            21.01966857517866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688225180903,
              21.019542734271578
            ],
            [
              105.83688194127619,
              21.019542939024703
            ],
            [
              105.83688096557995,
              21.019543580597755
            ],
            [
              105.83686562117708,
              21.01954251802724
            ],
            [
              105.83664811410306,
              21.01966857517866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.3_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13290",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019542734271578,
          "to_latitude": 21.01966857517866,
          "to_longitude": 105.83664811410306,
          "from_longitude": 105.83688225180903
        }
      },
      {
        "id": 13216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.836762237167,
            21.01967726660386,
            105.83697370117929,
            21.020017744448726
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83697370117929,
              21.01967726660386
            ],
            [
              105.83697295903987,
              21.019678561865454
            ],
            [
              105.83696167741824,
              21.019690847928196
            ],
            [
              105.83696659833439,
              21.019712651020633
            ],
            [
              105.8369240015725,
              21.01974107280171
            ],
            [
              105.83685533634358,
              21.01978341776111
            ],
            [
              105.83680786204286,
              21.01981315692668
            ],
            [
              105.83680700385044,
              21.019814686693465
            ],
            [
              105.8368048539141,
              21.019834003677982
            ],
            [
              105.836762237167,
              21.019859545137724
            ],
            [
              105.83686034659934,
              21.020017744448726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.4_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13291",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01967726660386,
          "to_latitude": 21.020017744448726,
          "to_longitude": 105.83686034659934,
          "from_longitude": 105.83697370117929
        }
      },
      {
        "id": 13217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719050975091,
            21.01991007345267,
            105.83762546395792,
            21.02017224831997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719050975091,
              21.02017224831997
            ],
            [
              105.83728505951098,
              21.020145564163776
            ],
            [
              105.83740367024055,
              21.020086726872055
            ],
            [
              105.83752419373278,
              21.020026940729416
            ],
            [
              105.83759390231474,
              21.01997248807254
            ],
            [
              105.83762321890575,
              21.01991148179489
            ],
            [
              105.83762546395792,
              21.01991007345267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13292",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02017224831997,
          "to_latitude": 21.01991007345267,
          "to_longitude": 105.83762546395792,
          "from_longitude": 105.83719050975091
        }
      },
      {
        "id": 13218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83737463213843,
            21.01979873513221,
            105.83750746432466,
            21.01990045801194
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83737463213843,
              21.01990045801194
            ],
            [
              105.83740216207188,
              21.01988520884341
            ],
            [
              105.83740603450076,
              21.019883063591
            ],
            [
              105.8373947080237,
              21.019864573459483
            ],
            [
              105.83739216352157,
              21.01986041941513
            ],
            [
              105.83742805899553,
              21.019834546891218
            ],
            [
              105.83743738898023,
              21.01982229930995
            ],
            [
              105.83750746432466,
              21.01979873513221
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.8_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13293",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01990045801194,
          "to_latitude": 21.01979873513221,
          "to_longitude": 105.83750746432466,
          "from_longitude": 105.83737463213843
        }
      },
      {
        "id": 13219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591282413025,
            21.0200627532896,
            105.83620663876943,
            21.020201827269535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620663876943,
              21.0200627532896
            ],
            [
              105.8361940006917,
              21.0200650346387
            ],
            [
              105.83620129125485,
              21.020090019002478
            ],
            [
              105.83611624379951,
              21.02012415622372
            ],
            [
              105.83605993819174,
              21.02013505128223
            ],
            [
              105.83598542939373,
              21.020177195203583
            ],
            [
              105.8359703327732,
              21.02018573441247
            ],
            [
              105.8359267511659,
              21.020201827269535
            ],
            [
              105.83591282413025,
              21.020175478219393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.7_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13294",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0200627532896,
          "to_latitude": 21.020175478219393,
          "to_longitude": 105.83591282413025,
          "from_longitude": 105.83620663876943
        }
      },
      {
        "id": 13220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598542939373,
            21.020177195203583,
            105.83608007242722,
            21.02041340810051
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598542939373,
              21.020177195203583
            ],
            [
              105.83602078404664,
              21.02027460213507
            ],
            [
              105.8360201907416,
              21.020292760753794
            ],
            [
              105.83608007242722,
              21.02041340810051
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.7_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13295",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020177195203583,
          "to_latitude": 21.02041340810051,
          "to_longitude": 105.83608007242722,
          "from_longitude": 105.83598542939373
        }
      },
      {
        "id": 13221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83627044292385,
            21.020096470221095,
            105.83661407928764,
            21.0202373073627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83627044292385,
              21.0202373073627
            ],
            [
              105.83627137865919,
              21.020237088698206
            ],
            [
              105.83628166304,
              21.020234674399095
            ],
            [
              105.83629468913053,
              21.0202340042932
            ],
            [
              105.8363353828053,
              21.02020720337227
            ],
            [
              105.83635129964908,
              21.02019964280613
            ],
            [
              105.83643770500544,
              21.02013905059148
            ],
            [
              105.83648468135073,
              21.020103994889183
            ],
            [
              105.83649476502063,
              21.020096470221095
            ],
            [
              105.8365742427702,
              21.02018444986681
            ],
            [
              105.83661407928764,
              21.020165066757126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.9_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13296",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0202373073627,
          "to_latitude": 21.020165066757126,
          "to_longitude": 105.83661407928764,
          "from_longitude": 105.83627044292385
        }
      },
      {
        "id": 13222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83622096449461,
            21.020086413263947,
            105.83629468913053,
            21.0202340042932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629468913053,
              21.0202340042932
            ],
            [
              105.83626682073114,
              21.020196182422673
            ],
            [
              105.83624376319091,
              21.020149055367767
            ],
            [
              105.83622096449461,
              21.020086413263947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.9_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13297",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0202340042932,
          "to_latitude": 21.020086413263947,
          "to_longitude": 105.83622096449461,
          "from_longitude": 105.83629468913053
        }
      },
      {
        "id": 13223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83617988365752,
            21.019993575932894,
            105.83619638607725,
            21.020059462666357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83619638607725,
              21.020059462666357
            ],
            [
              105.83617988365752,
              21.019993575932894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.6_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13298",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020059462666357,
          "to_latitude": 21.019993575932894,
          "to_longitude": 105.83617988365752,
          "from_longitude": 105.83619638607725
        }
      },
      {
        "id": 13224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615667341765,
            21.01979840241392,
            105.83620909567215,
            21.019993575932894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617988365752,
              21.019993575932894
            ],
            [
              105.83617483346077,
              21.01997341189898
            ],
            [
              105.8361607936741,
              21.01991735639044
            ],
            [
              105.83615667341765,
              21.019903524803038
            ],
            [
              105.83616809970152,
              21.019885078997003
            ],
            [
              105.8361700393301,
              21.019882891789567
            ],
            [
              105.83620661211218,
              21.01984164060804
            ],
            [
              105.83620909567215,
              21.019838839243693
            ],
            [
              105.83616030259869,
              21.01979840241392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.6_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13299",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019993575932894,
          "to_latitude": 21.01979840241392,
          "to_longitude": 105.83616030259869,
          "from_longitude": 105.83617988365752
        }
      },
      {
        "id": 13225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368229375998,
            21.020266300564046,
            105.83688656920899,
            21.02038057978208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368674188777,
              21.02038057978208
            ],
            [
              105.83688656920899,
              21.02037066481152
            ],
            [
              105.83688173698573,
              21.02036320537786
            ],
            [
              105.83682319571862,
              21.02026670122875
            ],
            [
              105.8368229375998,
              21.020266300564046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13300",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02038057978208,
          "to_latitude": 21.020266300564046,
          "to_longitude": 105.8368229375998,
          "from_longitude": 105.8368674188777
        }
      },
      {
        "id": 13226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368229375998,
            21.02012664813936,
            105.83710848045342,
            21.020266300564046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368229375998,
              21.020266300564046
            ],
            [
              105.8368965130964,
              21.020230708342197
            ],
            [
              105.83710848045342,
              21.02012664813936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13301",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020266300564046,
          "to_latitude": 21.02012664813936,
          "to_longitude": 105.83710848045342,
          "from_longitude": 105.8368229375998
        }
      },
      {
        "id": 13227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83674668397094,
            21.02038057978208,
            105.83688489968206,
            21.020443090635126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83674668397094,
              21.020443090635126
            ],
            [
              105.8368674188777,
              21.02038057978208
            ],
            [
              105.83688267941656,
              21.02040434890292
            ],
            [
              105.83688489968206,
              21.020407804551663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13302",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020443090635126,
          "to_latitude": 21.020407804551663,
          "to_longitude": 105.83688489968206,
          "from_longitude": 105.83674668397094
        }
      },
      {
        "id": 13228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364356952912,
            21.020407837959098,
            105.83664030539138,
            21.02060728292709
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83644537283297,
              21.02060728292709
            ],
            [
              105.83644447402948,
              21.02060601741436
            ],
            [
              105.8364356952912,
              21.02059363312785
            ],
            [
              105.83664030539138,
              21.0204960938976
            ],
            [
              105.83659169035586,
              21.020407837959098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13303",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 36.671,
          "from_latitude": 21.02060728292709,
          "to_latitude": 21.020407837959098,
          "to_longitude": 105.83659169035586,
          "from_longitude": 105.83644537283297
        }
      },
      {
        "id": 13229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612322594198,
            21.020596477137925,
            105.83644219432152,
            21.020715915978585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612322594198,
              21.020701945974327
            ],
            [
              105.836128917666,
              21.020715915978585
            ],
            [
              105.8364320152902,
              21.020596477137925
            ],
            [
              105.83644204633232,
              21.020607280317396
            ],
            [
              105.83644219432152,
              21.020608579310124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13304",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 43,
          "chatLieu": "HDPE",
          "chieuDai": 37.559,
          "from_latitude": 21.020701945974327,
          "to_latitude": 21.020608579310124,
          "to_longitude": 105.83644219432152,
          "from_longitude": 105.83612322594198
        }
      },
      {
        "id": 13230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597747417313,
            21.020521308287726,
            105.8360867052742,
            21.020740029098857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360867052742,
              21.020740029098857
            ],
            [
              105.8360844271688,
              21.020736894368184
            ],
            [
              105.83606987339589,
              21.020716682208214
            ],
            [
              105.8360337846465,
              21.020656032887327
            ],
            [
              105.83603161923284,
              21.020651715281975
            ],
            [
              105.83597747417313,
              21.02054374989504
            ],
            [
              105.83601902920446,
              21.020521308287726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13305",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020740029098857,
          "to_latitude": 21.020521308287726,
          "to_longitude": 105.83601902920446,
          "from_longitude": 105.8360867052742
        }
      },
      {
        "id": 13231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83608232226044,
            21.020686297783598,
            105.8361715056993,
            21.02071759416343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83608232226044,
              21.02071759416343
            ],
            [
              105.83612322594198,
              21.020701945974313
            ],
            [
              105.8361715056993,
              21.020686297783598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13306",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": 9.902,
          "from_latitude": 21.02071759416343,
          "to_latitude": 21.020686297783598,
          "to_longitude": 105.8361715056993,
          "from_longitude": 105.83608232226044
        }
      },
      {
        "id": 13232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661525195402,
            21.019725722101544,
            105.83696170940027,
            21.020370323102203
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696170940027,
              21.020370323102203
            ],
            [
              105.83696010510569,
              21.02036744068031
            ],
            [
              105.83695833167431,
              21.02036424386557
            ],
            [
              105.83695124475868,
              21.02035147012123
            ],
            [
              105.83694321456295,
              21.020340493013624
            ],
            [
              105.83693176130724,
              21.020316347488635
            ],
            [
              105.83689290718178,
              21.020246310305353
            ],
            [
              105.8368965130964,
              21.020230708342197
            ],
            [
              105.83687829829128,
              21.020221920173487
            ],
            [
              105.83687240223405,
              21.020212236817187
            ],
            [
              105.83683009127378,
              21.02013744669991
            ],
            [
              105.83682979592106,
              21.02013692428319
            ],
            [
              105.83677299994815,
              21.020080790142174
            ],
            [
              105.83672223359554,
              21.019975689657585
            ],
            [
              105.8366950004754,
              21.019889843034413
            ],
            [
              105.8366670075414,
              21.01980160118003
            ],
            [
              105.83661525195402,
              21.019725722101544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13307",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020370323102203,
          "to_latitude": 21.019725722101544,
          "to_longitude": 105.83661525195402,
          "from_longitude": 105.83696170940027
        }
      },
      {
        "id": 13233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312830398276,
            21.02472672185229,
            105.83401055771009,
            21.0252563990285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83312830398276,
              21.0252563990285
            ],
            [
              105.83313068126914,
              21.025255567255233
            ],
            [
              105.83374438751733,
              21.02503141798737
            ],
            [
              105.83370361155656,
              21.02493250443114
            ],
            [
              105.83373097924851,
              21.024861380492105
            ],
            [
              105.83373160795834,
              21.02485974528459
            ],
            [
              105.83401055771009,
              21.02472672185229
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.10_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13308",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 122,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0252563990285,
          "to_latitude": 21.02472672185229,
          "to_longitude": 105.83401055771009,
          "from_longitude": 105.83312830398276
        }
      },
      {
        "id": 13234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548566144555,
            21.02463748637539,
            105.8357166797336,
            21.025344285824797
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83557807552992,
              21.025344285824797
            ],
            [
              105.83557789171365,
              21.025343927245014
            ],
            [
              105.83556902678406,
              21.02532647446012
            ],
            [
              105.83548566144555,
              21.025116830391323
            ],
            [
              105.83551956180811,
              21.025102254722036
            ],
            [
              105.83551706886153,
              21.025093377444506
            ],
            [
              105.83559155286623,
              21.02506980497746
            ],
            [
              105.83558836746452,
              21.025062341938295
            ],
            [
              105.83568746573161,
              21.02502939225749
            ],
            [
              105.83566474776275,
              21.024967531923505
            ],
            [
              105.83568795415701,
              21.02493839371045
            ],
            [
              105.83562935623662,
              21.024806559019943
            ],
            [
              105.83558069438577,
              21.024697077235494
            ],
            [
              105.83557959102124,
              21.024680514828372
            ],
            [
              105.8357166797336,
              21.02463748637539
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.4_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13309",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 112,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025344285824797,
          "to_latitude": 21.02463748637539,
          "to_longitude": 105.8357166797336,
          "from_longitude": 105.83557807552992
        }
      },
      {
        "id": 13235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83562935623662,
            21.02466253108953,
            105.83575537004157,
            21.024806559019943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562935623662,
              21.024806559019943
            ],
            [
              105.83575003035668,
              21.02477765089487
            ],
            [
              105.83575537004157,
              21.02476723397217
            ],
            [
              105.83572621032853,
              21.02466253108953
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.4_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13310",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024806559019943,
          "to_latitude": 21.02466253108953,
          "to_longitude": 105.83572621032853,
          "from_longitude": 105.83562935623662
        }
      },
      {
        "id": 13236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83582342456027,
            21.025399312153866,
            105.83604566103519,
            21.025790594426073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83582342456027,
              21.025399312153866
            ],
            [
              105.83582686637246,
              21.02540427651502
            ],
            [
              105.83582779277579,
              21.025405829119034
            ],
            [
              105.8358544600728,
              21.025403880767957
            ],
            [
              105.83589030656756,
              21.025480228372434
            ],
            [
              105.83597678224156,
              21.025651294707053
            ],
            [
              105.83604566103519,
              21.025790594426073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.11_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13311",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025399312153866,
          "to_latitude": 21.025790594426073,
          "to_longitude": 105.83604566103519,
          "from_longitude": 105.83582342456027
        }
      },
      {
        "id": 13237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571064986818,
            21.025429969588153,
            105.83596870798728,
            21.02593468513713
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83574379860192,
              21.025429969588153
            ],
            [
              105.83574474744461,
              21.0254331316264
            ],
            [
              105.835746381856,
              21.025439857124617
            ],
            [
              105.83571064986818,
              21.0254584151205
            ],
            [
              105.83578548340827,
              21.025595698603826
            ],
            [
              105.83579498711033,
              21.02560917586017
            ],
            [
              105.83580145196697,
              21.02561922225378
            ],
            [
              105.83580996758458,
              21.025636130291353
            ],
            [
              105.83585812184742,
              21.02574541306093
            ],
            [
              105.83587782855766,
              21.02573956718468
            ],
            [
              105.83588263042891,
              21.02574794265638
            ],
            [
              105.83596870798728,
              21.025908829809698
            ],
            [
              105.83592063563702,
              21.02593468513713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.9_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13312",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025429969588153,
          "to_latitude": 21.02593468513713,
          "to_longitude": 105.83592063563702,
          "from_longitude": 105.83574379860192
        }
      },
      {
        "id": 13238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83527337975656,
            21.025888522342743,
            105.83570479779793,
            21.026150582017426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83527337975656,
              21.025888522342743
            ],
            [
              105.83527385044147,
              21.02588926067414
            ],
            [
              105.83528085215183,
              21.025900011674977
            ],
            [
              105.83529875524923,
              21.025927505473117
            ],
            [
              105.83535008505298,
              21.026010243316378
            ],
            [
              105.83540395093482,
              21.026068326943875
            ],
            [
              105.83549176696725,
              21.026150582017426
            ],
            [
              105.83558252034767,
              21.02607720265083
            ],
            [
              105.83558720731334,
              21.02605857773713
            ],
            [
              105.83570479779793,
              21.025978851362513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_61.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13313",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025888522342743,
          "to_latitude": 21.025978851362513,
          "to_longitude": 105.83570479779793,
          "from_longitude": 105.83527337975656
        }
      },
      {
        "id": 13239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8339973961821,
            21.023843968241263,
            105.83437844658324,
            21.023999654896826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83437844658324,
              21.023999654896826
            ],
            [
              105.83437777822188,
              21.023999003338574
            ],
            [
              105.83436378593309,
              21.023985322208627
            ],
            [
              105.83428330207977,
              21.023847727925542
            ],
            [
              105.8342469849469,
              21.02385306799536
            ],
            [
              105.83405712211393,
              21.02394354842242
            ],
            [
              105.8339973961821,
              21.023843968241263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_71.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.13314",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023999654896826,
          "to_latitude": 21.023843968241263,
          "to_longitude": 105.8339973961821,
          "from_longitude": 105.83437844658324
        }
      },
      {
        "id": 13240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353954422982,
            21.023838944704913,
            105.83397598994769,
            21.02421135641045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353954422982,
              21.02421135641045
            ],
            [
              105.8335408704699,
              21.024210947975792
            ],
            [
              105.83355931080003,
              21.024186015327686
            ],
            [
              105.83361528269164,
              21.024159256659058
            ],
            [
              105.83365126583664,
              21.024132413501864
            ],
            [
              105.83360519338689,
              21.02404023049572
            ],
            [
              105.83366044375765,
              21.024008114716377
            ],
            [
              105.83366397503829,
              21.02400617805613
            ],
            [
              105.83372480283994,
              21.023972821887423
            ],
            [
              105.83375482969245,
              21.023955221694568
            ],
            [
              105.83377977992969,
              21.023943815403435
            ],
            [
              105.83389367405567,
              21.023886094677955
            ],
            [
              105.83392501897339,
              21.023861608137317
            ],
            [
              105.83397598994769,
              21.023838944704913
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74.4_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13315",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02421135641045,
          "to_latitude": 21.023838944704913,
          "to_longitude": 105.83397598994769,
          "from_longitude": 105.83353954422982
        }
      },
      {
        "id": 13241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83256683110356,
            21.023621113552892,
            105.83333351510349,
            21.02404838685482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83256683110356,
              21.02404838685482
            ],
            [
              105.83256838066937,
              21.024047657593144
            ],
            [
              105.8325762101976,
              21.024033431421273
            ],
            [
              105.83259229309628,
              21.02402673566908
            ],
            [
              105.83273070060497,
              21.0239694258946
            ],
            [
              105.83286239833382,
              21.0238970876078
            ],
            [
              105.83297154318623,
              21.023888667036804
            ],
            [
              105.83301132657317,
              21.02390257151221
            ],
            [
              105.83333351510349,
              21.023758536672034
            ],
            [
              105.83328037953258,
              21.023648432080027
            ],
            [
              105.8332341305249,
              21.023666525342282
            ],
            [
              105.83321190345762,
              21.023621113552892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76.7_Ngõ 107A, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13316",
          "diaChiLapD": "Ngõ 107A, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 114,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02404838685482,
          "to_latitude": 21.023621113552892,
          "to_longitude": 105.83321190345762,
          "from_longitude": 105.83256683110356
        }
      },
      {
        "id": 13242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83332897264052,
            21.022611679652986,
            105.8334683137392,
            21.022892042017418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334683137392,
              21.022892042017418
            ],
            [
              105.83346771660875,
              21.022891569847765
            ],
            [
              105.83346022239694,
              21.022885620906745
            ],
            [
              105.83339399884754,
              21.022770070801535
            ],
            [
              105.83332897264052,
              21.022673309989315
            ],
            [
              105.83335266119211,
              21.02265110919266
            ],
            [
              105.83335439808837,
              21.02263389426393
            ],
            [
              105.83338145829893,
              21.022611679652986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13317",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022892042017418,
          "to_latitude": 21.022611679652986,
          "to_longitude": 105.83338145829893,
          "from_longitude": 105.8334683137392
        }
      },
      {
        "id": 13243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358207784899,
            21.022661204815854,
            105.8336461713614,
            21.022817436143296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364164949647,
              21.022817436143296
            ],
            [
              105.83364027232363,
              21.02281456431851
            ],
            [
              105.8336461713614,
              21.022798328856556
            ],
            [
              105.83359357441402,
              21.022683165459068
            ],
            [
              105.8335904688615,
              21.02267723294953
            ],
            [
              105.83358207784899,
              21.022661204815854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13318",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022817436143296,
          "to_latitude": 21.022661204815854,
          "to_longitude": 105.83358207784899,
          "from_longitude": 105.83364164949647
        }
      },
      {
        "id": 13244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358618497147,
            21.022500250908013,
            105.833743746264,
            21.02256611132527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358618497147,
              21.02256611132527
            ],
            [
              105.83359407903134,
              21.022562485030054
            ],
            [
              105.83366176913165,
              21.022531393063645
            ],
            [
              105.8336921825635,
              21.022524025423518
            ],
            [
              105.833743746264,
              21.022500250908013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13319",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02256611132527,
          "to_latitude": 21.022500250908013,
          "to_longitude": 105.833743746264,
          "from_longitude": 105.83358618497147
        }
      },
      {
        "id": 13245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83365716984473,
            21.02232756336125,
            105.833743746264,
            21.022500250908013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833743746264,
              21.022500250908013
            ],
            [
              105.83374353248949,
              21.022499866279272
            ],
            [
              105.83371570759208,
              21.022449765353496
            ],
            [
              105.83371188295159,
              21.022442879856577
            ],
            [
              105.83365716984473,
              21.02232756336125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13320",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022500250908013,
          "to_latitude": 21.02232756336125,
          "to_longitude": 105.83365716984473,
          "from_longitude": 105.833743746264
        }
      },
      {
        "id": 13246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83368798145183,
            21.02279701566569,
            105.83377831003152,
            21.023003281103083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83368798145183,
              21.02279701566569
            ],
            [
              105.83368845833822,
              21.02279904558213
            ],
            [
              105.83369135986045,
              21.022811362173663
            ],
            [
              105.83371520359901,
              21.022868805954857
            ],
            [
              105.83377831003152,
              21.023003281103083
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13321",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02279701566569,
          "to_latitude": 21.023003281103083,
          "to_longitude": 105.83377831003152,
          "from_longitude": 105.83368798145183
        }
      },
      {
        "id": 13247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83399405369184,
            21.02263270753155,
            105.83420064105714,
            21.023070812352124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83406489964902,
              21.02263270753155
            ],
            [
              105.83406521233316,
              21.022633579415743
            ],
            [
              105.8340700968281,
              21.022647160724972
            ],
            [
              105.83410253985146,
              21.0227205159234
            ],
            [
              105.83414095766267,
              21.022818404082873
            ],
            [
              105.83420046130112,
              21.022912153207862
            ],
            [
              105.83420064105714,
              21.022912474777137
            ],
            [
              105.83406291556815,
              21.022996303730448
            ],
            [
              105.83400010128283,
              21.023028194786658
            ],
            [
              105.83399405369184,
              21.023070812352124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.11_Văn Chương",
          "maTaiSan": "04.O14.DODV.13322",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02263270753155,
          "to_latitude": 21.023070812352124,
          "to_longitude": 105.83399405369184,
          "from_longitude": 105.83406489964902
        }
      },
      {
        "id": 13248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83401876878187,
            21.0230239021452,
            105.83441136293746,
            21.02318076434612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441136293746,
              21.02302991517924
            ],
            [
              105.83440957822843,
              21.02303039270817
            ],
            [
              105.8344005562144,
              21.0230239021452
            ],
            [
              105.83430993955012,
              21.023058708024923
            ],
            [
              105.83428200543224,
              21.023051962484743
            ],
            [
              105.83412725323922,
              21.02312498845308
            ],
            [
              105.83401876878187,
              21.02318076434612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_80.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13323",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02302991517924,
          "to_latitude": 21.02318076434612,
          "to_longitude": 105.83401876878187,
          "from_longitude": 105.83441136293746
        }
      },
      {
        "id": 13249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83448360322652,
            21.02336370691409,
            105.83466550343636,
            21.023462041475337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448360322652,
              21.023462041475337
            ],
            [
              105.83459438793618,
              21.023436185931615
            ],
            [
              105.83466550343636,
              21.02340813236046
            ],
            [
              105.83466181782654,
              21.023364887835857
            ],
            [
              105.83466171697783,
              21.02336370691409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_73.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.13324",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023462041475337,
          "to_latitude": 21.02336370691409,
          "to_longitude": 105.83466171697783,
          "from_longitude": 105.83448360322652
        }
      },
      {
        "id": 13250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83466171697783,
            21.023349095124786,
            105.83483717345402,
            21.02336370691409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83466171697783,
              21.02336370691409
            ],
            [
              105.83482630938417,
              21.023349095124786
            ],
            [
              105.83483156856198,
              21.023363435083645
            ],
            [
              105.83483288605619,
              21.023363185649508
            ],
            [
              105.83483717345402,
              21.02336280873447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_73.4_Văn Hương",
          "maTaiSan": "04.O14.DODV.13325",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02336370691409,
          "to_latitude": 21.02336280873447,
          "to_longitude": 105.83483717345402,
          "from_longitude": 105.83466171697783
        }
      },
      {
        "id": 13251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473579274212,
            21.023268308796865,
            105.83493418407001,
            21.023442115178845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83493418407001,
              21.023442115178845
            ],
            [
              105.83493403448865,
              21.023440648174642
            ],
            [
              105.83493391132659,
              21.023439433943356
            ],
            [
              105.83491755838907,
              21.023336796007
            ],
            [
              105.83488880558448,
              21.02332919381573
            ],
            [
              105.83487891551074,
              21.023308308347865
            ],
            [
              105.83487327006942,
              21.023268308796865
            ],
            [
              105.83473579274212,
              21.023275745116283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_73.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.13326",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023442115178845,
          "to_latitude": 21.023275745116283,
          "to_longitude": 105.83473579274212,
          "from_longitude": 105.83493418407001
        }
      },
      {
        "id": 13252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83491771959713,
            21.0232646494658,
            105.8350264725387,
            21.0234424480039
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83491771959713,
              21.0234424480039
            ],
            [
              105.83493418407001,
              21.023442115178845
            ],
            [
              105.8350208416206,
              21.023440363810202
            ],
            [
              105.8350264725387,
              21.023354590428646
            ],
            [
              105.83500489741637,
              21.0232646494658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_73.3_Văn Hương",
          "maTaiSan": "04.O14.DODV.13327",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0234424480039,
          "to_latitude": 21.0232646494658,
          "to_longitude": 105.83500489741637,
          "from_longitude": 105.83491771959713
        }
      },
      {
        "id": 13253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8353539997497,
            21.02084870486493,
            105.83570149491234,
            21.02097046833097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353539997497,
              21.02097046833097
            ],
            [
              105.83541899059878,
              21.020947694993495
            ],
            [
              105.83541864976455,
              21.020945397059492
            ],
            [
              105.83541934009442,
              21.020942050837313
            ],
            [
              105.83542227128709,
              21.02093843709348
            ],
            [
              105.83542560205044,
              21.020937649381075
            ],
            [
              105.83542946024176,
              21.02093673714288
            ],
            [
              105.8354336099651,
              21.02093842475751
            ],
            [
              105.8354363510626,
              21.020941612319753
            ],
            [
              105.83570149491234,
              21.02084870486493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13328",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02097046833097,
          "to_latitude": 21.02084870486493,
          "to_longitude": 105.83570149491234,
          "from_longitude": 105.8353539997497
        }
      },
      {
        "id": 13254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83570149491234,
            21.020757369413314,
            105.83599569673396,
            21.02086170735298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83570523729146,
              21.02086170735298
            ],
            [
              105.83570446669715,
              21.020857381987145
            ],
            [
              105.83570149491234,
              21.02084870486493
            ],
            [
              105.83599569673396,
              21.020757369413314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13329",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02086170735298,
          "to_latitude": 21.020757369413314,
          "to_longitude": 105.83599569673396,
          "from_longitude": 105.83570523729146
        }
      },
      {
        "id": 13255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83531514313022,
            21.020320608120745,
            105.83546539365564,
            21.02073320404127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83537405285333,
              21.020322029389234
            ],
            [
              105.83537471183871,
              21.02032186357256
            ],
            [
              105.83537967147838,
              21.020320608120745
            ],
            [
              105.83543191970058,
              21.02037292185628
            ],
            [
              105.83544001169092,
              21.020381023773016
            ],
            [
              105.83544224933122,
              21.020390194054112
            ],
            [
              105.83546539365564,
              21.020492647778052
            ],
            [
              105.83545771938859,
              21.02053051700576
            ],
            [
              105.83545597141169,
              21.020532229071563
            ],
            [
              105.83536817218366,
              21.020555466693096
            ],
            [
              105.8353650884979,
              21.020561579502303
            ],
            [
              105.83535850439374,
              21.020574632713842
            ],
            [
              105.83532357978181,
              21.020592010644343
            ],
            [
              105.83531514313022,
              21.02061269977491
            ],
            [
              105.83536099135176,
              21.02073320404127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.16_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13330",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020322029389234,
          "to_latitude": 21.02073320404127,
          "to_longitude": 105.83536099135176,
          "from_longitude": 105.83537405285333
        }
      },
      {
        "id": 13256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504653558629,
            21.02031816995182,
            105.83538347614612,
            21.02048867193686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8353738610854,
              21.02031816995182
            ],
            [
              105.83537313397338,
              21.020318366812692
            ],
            [
              105.8353710208193,
              21.020318936286305
            ],
            [
              105.835375076645,
              21.020334163691277
            ],
            [
              105.83538347614612,
              21.02036172225431
            ],
            [
              105.83528852962083,
              21.020394809365975
            ],
            [
              105.83523622445006,
              21.02040773844254
            ],
            [
              105.83522798212014,
              21.020409775943225
            ],
            [
              105.83515951489528,
              21.020433063045132
            ],
            [
              105.83516908372951,
              21.02045416143954
            ],
            [
              105.83504653558629,
              21.02048867193686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.15_Ngách 59, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13331",
          "diaChiLapD": "Ngách 59, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02031816995182,
          "to_latitude": 21.02048867193686,
          "to_longitude": 105.83504653558629,
          "from_longitude": 105.8353738610854
        }
      },
      {
        "id": 13257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83537487614477,
            21.020307650919534,
            105.83594877848887,
            21.02038637919606
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83537487614477,
              21.020327355270528
            ],
            [
              105.83537544414332,
              21.02032726125538
            ],
            [
              105.83537851436269,
              21.02032667533952
            ],
            [
              105.83539935257954,
              21.020349485983626
            ],
            [
              105.83542440109838,
              21.02037277271949
            ],
            [
              105.83543191970058,
              21.02037292185628
            ],
            [
              105.83546324769425,
              21.020373543855754
            ],
            [
              105.83550442459904,
              21.020378707656754
            ],
            [
              105.83555463307769,
              21.020383465793394
            ],
            [
              105.83561943490015,
              21.02038637919606
            ],
            [
              105.83562646407962,
              21.020385704278684
            ],
            [
              105.83576040113714,
              21.020372844590913
            ],
            [
              105.8357682510656,
              21.02037082344432
            ],
            [
              105.83581339668332,
              21.02035486474733
            ],
            [
              105.83583751376653,
              21.02034633903305
            ],
            [
              105.83587009785823,
              21.020328972501265
            ],
            [
              105.83594877848887,
              21.020307650919534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.17_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13332",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020327355270528,
          "to_latitude": 21.020307650919534,
          "to_longitude": 105.83594877848887,
          "from_longitude": 105.83537487614477
        }
      },
      {
        "id": 13258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540626490814,
            21.019924979218985,
            105.8356553066709,
            21.020226532852003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83540626490814,
              21.019924979218985
            ],
            [
              105.83540699712889,
              21.019925009042154
            ],
            [
              105.83542595393546,
              21.01992576135704
            ],
            [
              105.83556018388049,
              21.01993100732997
            ],
            [
              105.8355880076389,
              21.019992451559435
            ],
            [
              105.83560722851163,
              21.02003489537429
            ],
            [
              105.83563255489953,
              21.020135845329484
            ],
            [
              105.8356553066709,
              21.020226532852003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.10_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13333",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019924979218985,
          "to_latitude": 21.020226532852003,
          "to_longitude": 105.8356553066709,
          "from_longitude": 105.83540626490814
        }
      },
      {
        "id": 13259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83554918838433,
            21.02021355116151,
            105.83572178793601,
            21.020247254757216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83554918838433,
              21.020247254757216
            ],
            [
              105.8356553066709,
              21.020226532852003
            ],
            [
              105.83572178793601,
              21.02021355116151
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.10_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13334",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020247254757216,
          "to_latitude": 21.02021355116151,
          "to_longitude": 105.83572178793601,
          "from_longitude": 105.83554918838433
        }
      },
      {
        "id": 13260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83493626541525,
            21.020204953024194,
            105.83536150646273,
            21.0203283802457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536150646273,
              21.020262377063023
            ],
            [
              105.83535968625138,
              21.02026320974243
            ],
            [
              105.83534229902317,
              21.020261193328743
            ],
            [
              105.83528709867848,
              21.020276247472136
            ],
            [
              105.83528140341876,
              21.020278071951775
            ],
            [
              105.83521780162312,
              21.02029612990354
            ],
            [
              105.83519913436477,
              21.020301430455483
            ],
            [
              105.83510666560396,
              21.020326180284126
            ],
            [
              105.83509844630656,
              21.0203283802457
            ],
            [
              105.83509805136516,
              21.02032655676896
            ],
            [
              105.8350911061479,
              21.020294459575553
            ],
            [
              105.83507173970409,
              21.020204953024194
            ],
            [
              105.83493626541525,
              21.020233002005746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_113.14_Ngách 53, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13335",
          "diaChiLapD": "Ngách 53, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020262377063023,
          "to_latitude": 21.020233002005746,
          "to_longitude": 105.83493626541525,
          "from_longitude": 105.83536150646273
        }
      },
      {
        "id": 13261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8345576559887,
            21.020590142888476,
            105.83490257944474,
            21.020684358504557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345576559887,
              21.020684358504557
            ],
            [
              105.83455942064475,
              21.020683912685975
            ],
            [
              105.83457242067108,
              21.020680624392057
            ],
            [
              105.83458293558931,
              21.020677966095754
            ],
            [
              105.8346947210287,
              21.020665319795125
            ],
            [
              105.83474517095307,
              21.02064103020209
            ],
            [
              105.83476658399672,
              21.02061642335792
            ],
            [
              105.83477640399046,
              21.020613870524592
            ],
            [
              105.8348676723698,
              21.020590142888476
            ],
            [
              105.83490257944474,
              21.020683638592995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.1_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13336",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020684358504557,
          "to_latitude": 21.020683638592995,
          "to_longitude": 105.83490257944474,
          "from_longitude": 105.8345576559887
        }
      },
      {
        "id": 13262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456614127485,
            21.020457208086242,
            105.83457998255142,
            21.020725016880967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83457998255142,
              21.020725016880967
            ],
            [
              105.83457830849949,
              21.020718754010147
            ],
            [
              105.83457290910354,
              21.020685671859585
            ],
            [
              105.83457927935365,
              21.020679099619187
            ],
            [
              105.83457190327786,
              21.020675344052265
            ],
            [
              105.83456829507453,
              21.020597709697302
            ],
            [
              105.8345677567545,
              21.02058688900449
            ],
            [
              105.83456614127485,
              21.020566778517768
            ],
            [
              105.83456713693856,
              21.020501619563834
            ],
            [
              105.83457010811284,
              21.020486692756414
            ],
            [
              105.83456876728012,
              21.020465286601763
            ],
            [
              105.8345672090505,
              21.020457208086242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.2_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13337",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": 30.492,
          "from_latitude": 21.020725016880967,
          "to_latitude": 21.020457208086242,
          "to_longitude": 105.8345672090505,
          "from_longitude": 105.83457998255142
        }
      },
      {
        "id": 13263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411161736377,
            21.020698663613153,
            105.83434732426565,
            21.021014586528217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83430102546876,
              21.020698663613153
            ],
            [
              105.83430166746817,
              21.020700631299214
            ],
            [
              105.83430609325055,
              21.02070923357486
            ],
            [
              105.83431400248796,
              21.02072460829971
            ],
            [
              105.83434732426565,
              21.020851359580078
            ],
            [
              105.83428796617649,
              21.020864896514297
            ],
            [
              105.83429418798292,
              21.020904279083407
            ],
            [
              105.83426536596988,
              21.020910948050485
            ],
            [
              105.83427094251918,
              21.020935156007635
            ],
            [
              105.83419394877285,
              21.020952186369254
            ],
            [
              105.83420266042133,
              21.020993002792665
            ],
            [
              105.83411161736377,
              21.021014586528217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.2_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13338",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020698663613153,
          "to_latitude": 21.021014586528217,
          "to_longitude": 105.83411161736377,
          "from_longitude": 105.83430102546876
        }
      },
      {
        "id": 13264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83425748331202,
            21.02062935117712,
            105.834540367257,
            21.020700467755105
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425748331202,
              21.020700467755105
            ],
            [
              105.83429640785975,
              21.020690681889963
            ],
            [
              105.83435112508774,
              21.020676926558043
            ],
            [
              105.83444717896309,
              21.020652779155615
            ],
            [
              105.834540367257,
              21.02062935117712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.3_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13339",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020700467755105,
          "to_latitude": 21.02062935117712,
          "to_longitude": 105.834540367257,
          "from_longitude": 105.83425748331202
        }
      },
      {
        "id": 13265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83394155745357,
            21.020258476305624,
            105.83411688023077,
            21.020313212372155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83394155745357,
              21.020313212372155
            ],
            [
              105.83394723604012,
              21.020312537823045
            ],
            [
              105.83400087828838,
              21.020296641815907
            ],
            [
              105.83406694384115,
              21.020281178036022
            ],
            [
              105.83407532926401,
              21.020277365957387
            ],
            [
              105.83411664226239,
              21.02025858405302
            ],
            [
              105.83411688023077,
              21.020258476305624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.9_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13340",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020313212372155,
          "to_latitude": 21.020258476305624,
          "to_longitude": 105.83411688023077,
          "from_longitude": 105.83394155745357
        }
      },
      {
        "id": 13266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83406101097088,
            21.020054356949885,
            105.83411688023077,
            21.020258476305624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411688023077,
              21.020258476305624
            ],
            [
              105.83411680266498,
              21.020258193976826
            ],
            [
              105.83407854705878,
              21.020118426075328
            ],
            [
              105.83406101097088,
              21.020054356949885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.9_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13341",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020258476305624,
          "to_latitude": 21.020054356949885,
          "to_longitude": 105.83406101097088,
          "from_longitude": 105.83411688023077
        }
      },
      {
        "id": 13267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83355586171594,
            21.020360334480145,
            105.83395813831737,
            21.020498741027865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83395813831737,
              21.020360334480145
            ],
            [
              105.83395582256355,
              21.02036201047605
            ],
            [
              105.83394284398345,
              21.02037140170333
            ],
            [
              105.83355586171594,
              21.020498741027865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.7_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13342",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020360334480145,
          "to_latitude": 21.020498741027865,
          "to_longitude": 105.83355586171594,
          "from_longitude": 105.83395813831737
        }
      },
      {
        "id": 13268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8337924492204,
            21.020295822856948,
            105.83391437094134,
            21.020299480262736
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8337924492204,
              21.020295822856948
            ],
            [
              105.83384079815,
              21.020297985718518
            ],
            [
              105.83384603971592,
              21.020298124410477
            ],
            [
              105.83391437094134,
              21.020299480262736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13343",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020295822856948,
          "to_latitude": 21.020299480262736,
          "to_longitude": 105.83391437094134,
          "from_longitude": 105.8337924492204
        }
      },
      {
        "id": 13269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336073113917,
            21.020285678418595,
            105.83379249219078,
            21.02030337811704
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336073113917,
              21.020285678418595
            ],
            [
              105.83364158061954,
              21.020297814191395
            ],
            [
              105.8337924492204,
              21.020295822856948
            ],
            [
              105.83379236716809,
              21.020298366738594
            ],
            [
              105.83379249219078,
              21.02030337811704
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13344",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020285678418595,
          "to_latitude": 21.02030337811704,
          "to_longitude": 105.83379249219078,
          "from_longitude": 105.8336073113917
        }
      },
      {
        "id": 13270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83328637278636,
            21.020242648832618,
            105.83335093372945,
            21.020375679163802
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83328637278636,
              21.020242648832618
            ],
            [
              105.83328716088992,
              21.020244598646038
            ],
            [
              105.83329575088389,
              21.020265825691094
            ],
            [
              105.83334499951373,
              21.02036324464475
            ],
            [
              105.83335093372945,
              21.020375679163802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_105.8_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13345",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020242648832618,
          "to_latitude": 21.020375679163802,
          "to_longitude": 105.83335093372945,
          "from_longitude": 105.83328637278636
        }
      },
      {
        "id": 13271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335093372945,
            21.020375679163802,
            105.83340971020597,
            21.020512814961375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335093372945,
              21.020375679163802
            ],
            [
              105.83340971020597,
              21.020512814961375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_105.8_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13346",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020375679163802,
          "to_latitude": 21.020512814961375,
          "to_longitude": 105.83340971020597,
          "from_longitude": 105.83335093372945
        }
      },
      {
        "id": 13272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315433830059,
            21.020375679163802,
            105.83335093372945,
            21.02046690865372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335093372945,
              21.020375679163802
            ],
            [
              105.83315433830059,
              21.02046690865372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_105.8_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13347",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020375679163802,
          "to_latitude": 21.02046690865372,
          "to_longitude": 105.83315433830059,
          "from_longitude": 105.83335093372945
        }
      },
      {
        "id": 13273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315433830059,
            21.02046690865372,
            105.83322577778807,
            21.020696282982556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315433830059,
              21.02046690865372
            ],
            [
              105.83318981558375,
              21.020580813915608
            ],
            [
              105.83322577778807,
              21.020696282982556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_105.8_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13348",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02046690865372,
          "to_latitude": 21.020696282982556,
          "to_longitude": 105.83322577778807,
          "from_longitude": 105.83315433830059
        }
      },
      {
        "id": 13274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332887421673,
            21.020241274259863,
            105.8334378797973,
            21.0207136150331
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332887421673,
              21.020241274259863
            ],
            [
              105.83328989689898,
              21.02024359169596
            ],
            [
              105.8333053011689,
              21.020274459793814
            ],
            [
              105.8333455796389,
              21.020348368407966
            ],
            [
              105.83338754117725,
              21.020439165381468
            ],
            [
              105.8334378797973,
              21.020557000863295
            ],
            [
              105.8333672278174,
              21.0205810241502
            ],
            [
              105.83341123214454,
              21.0207136150331
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_105.7_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13349",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020241274259863,
          "to_latitude": 21.0207136150331,
          "to_longitude": 105.83341123214454,
          "from_longitude": 105.8332887421673
        }
      },
      {
        "id": 13275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83270184890405,
            21.020336774331465,
            105.83319795234593,
            21.020496382805042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83270184890405,
              21.020496382805042
            ],
            [
              105.83270417056762,
              21.020495768085524
            ],
            [
              105.83281295615753,
              21.02046519154138
            ],
            [
              105.8330963886827,
              21.02035060157987
            ],
            [
              105.83310921469827,
              21.020375727881294
            ],
            [
              105.83319795234593,
              21.020336774331465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_105.11_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13350",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020496382805042,
          "to_latitude": 21.020336774331465,
          "to_longitude": 105.83319795234593,
          "from_longitude": 105.83270184890405
        }
      },
      {
        "id": 13276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83210432133528,
            21.020390876748596,
            105.83248281671362,
            21.020599708061585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83248281671362,
              21.020599708061585
            ],
            [
              105.83248122100294,
              21.02059905019489
            ],
            [
              105.83247218181072,
              21.02059531534306
            ],
            [
              105.83243979855115,
              21.02053422708179
            ],
            [
              105.83238083985259,
              21.020421990101624
            ],
            [
              105.83237037334523,
              21.020421763126606
            ],
            [
              105.83234705792592,
              21.020390876748596
            ],
            [
              105.83210432133528,
              21.020397909136687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94.8_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13351",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020599708061585,
          "to_latitude": 21.020397909136687,
          "to_longitude": 105.83210432133528,
          "from_longitude": 105.83248281671362
        }
      },
      {
        "id": 13277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83210113881039,
            21.020397909136687,
            105.83210726169902,
            21.02052925625828
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83210432133528,
              21.020397909136687
            ],
            [
              105.83210663367223,
              21.020482640154686
            ],
            [
              105.83210726169902,
              21.020505672016903
            ],
            [
              105.83210113881039,
              21.02052925625828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94.8_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13352",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020397909136687,
          "to_latitude": 21.02052925625828,
          "to_longitude": 105.83210113881039,
          "from_longitude": 105.83210432133528
        }
      },
      {
        "id": 13278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8329074984506,
            21.021173560668462,
            105.83310834382382,
            21.02125267261746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329074984506,
              21.021183599452
            ],
            [
              105.83290787520365,
              21.02118301864035
            ],
            [
              105.83291402546789,
              21.021173560668462
            ],
            [
              105.83299978693883,
              21.021207793789852
            ],
            [
              105.83302840161507,
              21.02122023195274
            ],
            [
              105.83310834382382,
              21.02125267261746
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93.6_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13353",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021183599452,
          "to_latitude": 21.02125267261746,
          "to_longitude": 105.83310834382382,
          "from_longitude": 105.8329074984506
        }
      },
      {
        "id": 13279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280195952112,
            21.021361839151893,
            105.83302483237027,
            21.021429872428868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83302483237027,
              21.021429872428868
            ],
            [
              105.83296129690466,
              21.021419315989764
            ],
            [
              105.83288415248049,
              21.02140201946807
            ],
            [
              105.83280195952112,
              21.021361839151893
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93.6_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13354",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021429872428868,
          "to_latitude": 21.021361839151893,
          "to_longitude": 105.83280195952112,
          "from_longitude": 105.83302483237027
        }
      },
      {
        "id": 13280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83302483237027,
            21.02125267261746,
            105.83310834382382,
            21.021429872428868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83310834382382,
              21.02125267261746
            ],
            [
              105.83302483237027,
              21.021429872428868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93.6_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13355",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02125267261746,
          "to_latitude": 21.021429872428868,
          "to_longitude": 105.83302483237027,
          "from_longitude": 105.83310834382382
        }
      },
      {
        "id": 13281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83299979466521,
            21.02071426476804,
            105.83314363951119,
            21.020820158794457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314363951119,
              21.020820158794457
            ],
            [
              105.83311935467603,
              21.020794205270114
            ],
            [
              105.83307691224283,
              21.020763326875567
            ],
            [
              105.83299979466521,
              21.02071426476804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93.5_Ngách 221/60, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13356",
          "diaChiLapD": "Ngách 221/60, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020820158794457,
          "to_latitude": 21.02071426476804,
          "to_longitude": 105.83299979466521,
          "from_longitude": 105.83314363951119
        }
      },
      {
        "id": 13282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83289910650876,
            21.020820158794457,
            105.83314363951119,
            21.021180710485822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83289910650876,
              21.021180710485822
            ],
            [
              105.8328992801432,
              21.02118045041021
            ],
            [
              105.83291612229958,
              21.021155327850465
            ],
            [
              105.8329597584245,
              21.021108587276025
            ],
            [
              105.83300246615954,
              21.021041228820508
            ],
            [
              105.83303147907604,
              21.02098856062688
            ],
            [
              105.83309476952569,
              21.020872628006494
            ],
            [
              105.83312227012402,
              21.020844712841253
            ],
            [
              105.83312774578744,
              21.020837463839953
            ],
            [
              105.83313104417029,
              21.020833096852947
            ],
            [
              105.83314363951119,
              21.020820158794457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_93.5_Ngách 221/60, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13357",
          "diaChiLapD": "Ngách 221/60, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021180710485822,
          "to_latitude": 21.020820158794457,
          "to_longitude": 105.83314363951119,
          "from_longitude": 105.83289910650876
        }
      },
      {
        "id": 13283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83244000256185,
            21.021861217144718,
            105.83251567915457,
            21.022015939388364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83244000256185,
              21.02186437300385
            ],
            [
              105.83244311771047,
              21.021862341761093
            ],
            [
              105.8324457222726,
              21.021861217144718
            ],
            [
              105.83249708752538,
              21.021976874711104
            ],
            [
              105.83251567915457,
              21.022015939388364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_87.6_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13358",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02186437300385,
          "to_latitude": 21.022015939388364,
          "to_longitude": 105.83251567915457,
          "from_longitude": 105.83244000256185
        }
      },
      {
        "id": 13284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83251567915457,
            21.022005894861362,
            105.83294265058818,
            21.022047132534382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83251567915457,
              21.022015939388364
            ],
            [
              105.83252485088227,
              21.022035212656462
            ],
            [
              105.83253059683152,
              21.022044924902147
            ],
            [
              105.83260123752636,
              21.022047132534382
            ],
            [
              105.83285455032095,
              21.022005894861362
            ],
            [
              105.83286642970894,
              21.022022334082138
            ],
            [
              105.83294265058818,
              21.0220179180017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_87.6_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13359",
          "diaChiLapD": "Ngõ 181, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022015939388364,
          "to_latitude": 21.0220179180017,
          "to_longitude": 105.83294265058818,
          "from_longitude": 105.83251567915457
        }
      },
      {
        "id": 13285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83231288241659,
            21.021371638450972,
            105.8327789422616,
            21.021510579213757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83231288241659,
              21.021436532571077
            ],
            [
              105.83231387326968,
              21.02143549624024
            ],
            [
              105.83232637277592,
              21.02142762760385
            ],
            [
              105.83235943669723,
              21.021406814482944
            ],
            [
              105.83239246609149,
              21.021371638450972
            ],
            [
              105.83258760164071,
              21.02145360750601
            ],
            [
              105.83272322741374,
              21.021510579213757
            ],
            [
              105.8327789422616,
              21.021420537685074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_89.3_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13360",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021436532571077,
          "to_latitude": 21.021420537685074,
          "to_longitude": 105.8327789422616,
          "from_longitude": 105.83231288241659
        }
      },
      {
        "id": 13286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83381470625929,
            21.022249840965245,
            105.83409404632336,
            21.02262052913834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83409404632336,
              21.02262052913834
            ],
            [
              105.83409277977108,
              21.0226174842591
            ],
            [
              105.834071694177,
              21.02256215951828
            ],
            [
              105.8340646689541,
              21.022533993510848
            ],
            [
              105.8339892796955,
              21.022429572049994
            ],
            [
              105.83396798751123,
              21.022397799871758
            ],
            [
              105.83390710662658,
              21.022428234444394
            ],
            [
              105.83387376993915,
              21.02235865815619
            ],
            [
              105.8338768312454,
              21.022340892989305
            ],
            [
              105.83381470625929,
              21.022249840965245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.12_Văn Chương",
          "maTaiSan": "04.O14.DODV.13361",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02262052913834,
          "to_latitude": 21.022249840965245,
          "to_longitude": 105.83381470625929,
          "from_longitude": 105.83409404632336
        }
      },
      {
        "id": 13287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8339831604532,
            21.022127922530565,
            105.83417137869377,
            21.0225902034899
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83417137869377,
              21.0225902034899
            ],
            [
              105.83417084824711,
              21.022588749191275
            ],
            [
              105.83416719357174,
              21.022578700684814
            ],
            [
              105.83413118364273,
              21.022489284701056
            ],
            [
              105.83408258376014,
              21.02239475226533
            ],
            [
              105.83402944049004,
              21.022304539631723
            ],
            [
              105.83400604330004,
              21.022279302669734
            ],
            [
              105.83407385426653,
              21.022217923948226
            ],
            [
              105.8339831604532,
              21.022127922530565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.13_Văn Chương",
          "maTaiSan": "04.O14.DODV.13362",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 63,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0225902034899,
          "to_latitude": 21.022127922530565,
          "to_longitude": 105.8339831604532,
          "from_longitude": 105.83417137869377
        }
      },
      {
        "id": 13288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415639966634,
            21.022019597493518,
            105.83436929204441,
            21.02250534398344
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83436929204441,
              21.02250534398344
            ],
            [
              105.83436834327463,
              21.022504087737193
            ],
            [
              105.83436310772073,
              21.02249916556027
            ],
            [
              105.834349151421,
              21.022486047857907
            ],
            [
              105.83430022783347,
              21.022385402250176
            ],
            [
              105.83428424895655,
              21.022337268341968
            ],
            [
              105.8342721804713,
              21.02229067873182
            ],
            [
              105.83425159118119,
              21.022249866386357
            ],
            [
              105.83421024398247,
              21.02221106797459
            ],
            [
              105.83416892356104,
              21.022124684871855
            ],
            [
              105.83415639966634,
              21.02208026251224
            ],
            [
              105.8341596141473,
              21.022061679171895
            ],
            [
              105.8341668930879,
              21.022019597493518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13363",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02250534398344,
          "to_latitude": 21.022019597493518,
          "to_longitude": 105.8341668930879,
          "from_longitude": 105.83436929204441
        }
      },
      {
        "id": 13289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83442989453066,
            21.02247242457072,
            105.83471408014357,
            21.022931946513232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443309980689,
              21.02247242457072
            ],
            [
              105.83443419069341,
              21.02247467185923
            ],
            [
              105.8344302060642,
              21.02249937200085
            ],
            [
              105.83442989453066,
              21.022501300105805
            ],
            [
              105.83443228944269,
              21.02250917621063
            ],
            [
              105.83447191818256,
              21.022639495831335
            ],
            [
              105.83454804677056,
              21.0228108197419
            ],
            [
              105.83456925099587,
              21.022866852864674
            ],
            [
              105.83464438790058,
              21.022853246511463
            ],
            [
              105.834660671826,
              21.02292970759281
            ],
            [
              105.83471408014357,
              21.022931946513232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13364",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02247242457072,
          "to_latitude": 21.022931946513232,
          "to_longitude": 105.83471408014357,
          "from_longitude": 105.83443309980689
        }
      },
      {
        "id": 13290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83437065669918,
            21.022059448056325,
            105.83448917318945,
            21.022454883436964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448917318945,
              21.022454883436964
            ],
            [
              105.83448831653443,
              21.022451337970104
            ],
            [
              105.83444779964107,
              21.02237023124856
            ],
            [
              105.83439267461105,
              21.022252381675653
            ],
            [
              105.83441666817976,
              21.022226776795215
            ],
            [
              105.83440704744426,
              21.022199293727606
            ],
            [
              105.83437065669918,
              21.022089335831655
            ],
            [
              105.83444505810583,
              21.022059448056325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13365",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022454883436964,
          "to_latitude": 21.022059448056325,
          "to_longitude": 105.83444505810583,
          "from_longitude": 105.83448917318945
        }
      },
      {
        "id": 13291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83467580494487,
            21.02214778518193,
            105.8348469611872,
            21.02236652111135
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8348469611872,
              21.02236652111135
            ],
            [
              105.83484546575723,
              21.022360554529065
            ],
            [
              105.83484454475712,
              21.022356684219464
            ],
            [
              105.8348040639322,
              21.022191891009353
            ],
            [
              105.83476496791485,
              21.02214778518193
            ],
            [
              105.83467580494487,
              21.022179050696153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13366",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02236652111135,
          "to_latitude": 21.022179050696153,
          "to_longitude": 105.83467580494487,
          "from_longitude": 105.8348469611872
        }
      },
      {
        "id": 13292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499586586603,
            21.022125109669872,
            105.83501136976611,
            21.022328019428944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83501136976611,
              21.022125109669872
            ],
            [
              105.83499883470247,
              21.022125713142678
            ],
            [
              105.83499586586603,
              21.022125855072034
            ],
            [
              105.83500261540162,
              21.022328019428944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_121.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13367",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022125109669872,
          "to_latitude": 21.022328019428944,
          "to_longitude": 105.83500261540162,
          "from_longitude": 105.83501136976611
        }
      },
      {
        "id": 13293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83484454475712,
            21.02232121306187,
            105.83495544819891,
            21.022356684219464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83495544819891,
              21.02232121306187
            ],
            [
              105.83493047543924,
              21.022329200925753
            ],
            [
              105.83484454475712,
              21.022356684219464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13368",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02232121306187,
          "to_latitude": 21.022356684219464,
          "to_longitude": 105.83484454475712,
          "from_longitude": 105.83495544819891
        }
      },
      {
        "id": 13294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83497228174032,
            21.02190261081765,
            105.83499586586603,
            21.022125855072034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83497228174032,
              21.02190261081765
            ],
            [
              105.834976877904,
              21.021959417293594
            ],
            [
              105.83498979517711,
              21.02201345969928
            ],
            [
              105.83499386969044,
              21.02206382603896
            ],
            [
              105.83499401629561,
              21.022070459498394
            ],
            [
              105.83499586586603,
              21.022125855072034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_121.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13369",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02190261081765,
          "to_latitude": 21.022125855072034,
          "to_longitude": 105.83499586586603,
          "from_longitude": 105.83497228174032
        }
      },
      {
        "id": 13295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83482285002967,
            21.021365954343274,
            105.83500237755553,
            21.021564387326862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83482285002967,
              21.02139441829101
            ],
            [
              105.83496687982642,
              21.021365954343274
            ],
            [
              105.83498661125456,
              21.021482979429674
            ],
            [
              105.83498967248637,
              21.021564387326862
            ],
            [
              105.8349948035704,
              21.021564198654204
            ],
            [
              105.83500237755553,
              21.02156391760181
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_120.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13370",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02139441829101,
          "to_latitude": 21.02156391760181,
          "to_longitude": 105.83500237755553,
          "from_longitude": 105.83482285002967
        }
      },
      {
        "id": 13296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498967248637,
            21.021564387326862,
            105.8349998681658,
            21.02184177061896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8349998681658,
              21.02184177061896
            ],
            [
              105.83499843078599,
              21.021802868597543
            ],
            [
              105.83499432944316,
              21.021691884323726
            ],
            [
              105.83499401680842,
              21.02168342084427
            ],
            [
              105.83499012478472,
              21.021578065335625
            ],
            [
              105.83499018730129,
              21.02157806322228
            ],
            [
              105.83498967248637,
              21.021564387326862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_120.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13371",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02184177061896,
          "to_latitude": 21.021564387326862,
          "to_longitude": 105.83498967248637,
          "from_longitude": 105.8349998681658
        }
      },
      {
        "id": 13297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83485856095209,
            21.021897210738054,
            105.83495308620677,
            21.02191026025408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83495308620677,
              21.021897210738054
            ],
            [
              105.83490332388003,
              21.021904080159185
            ],
            [
              105.83485856095209,
              21.02191026025408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_110.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13372",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021897210738054,
          "to_latitude": 21.02191026025408,
          "to_longitude": 105.83485856095209,
          "from_longitude": 105.83495308620677
        }
      },
      {
        "id": 13298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8345056219485,
            21.02191026025408,
            105.83485856095209,
            21.021989482434567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8345056219485,
              21.021980736630145
            ],
            [
              105.83458347806359,
              21.02198357371346
            ],
            [
              105.83464054576658,
              21.0219839765212
            ],
            [
              105.83465713974147,
              21.021989482434567
            ],
            [
              105.83469587589566,
              21.02197725056157
            ],
            [
              105.83472862197189,
              21.021955025001613
            ],
            [
              105.83476658932533,
              21.021936715479374
            ],
            [
              105.83478353181432,
              21.02192844821528
            ],
            [
              105.83483199713511,
              21.021914445210047
            ],
            [
              105.83485856095209,
              21.02191026025408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_110.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13373",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021980736630145,
          "to_latitude": 21.02191026025408,
          "to_longitude": 105.83485856095209,
          "from_longitude": 105.8345056219485
        }
      },
      {
        "id": 13299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418527708487,
            21.021681381594426,
            105.83444742475069,
            21.021907378730806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444366604141,
              21.021681381594426
            ],
            [
              105.83444412377372,
              21.02168221573422
            ],
            [
              105.83444742475069,
              21.021690246362258
            ],
            [
              105.83441994932534,
              21.021715405876648
            ],
            [
              105.83441379542366,
              21.02171797322245
            ],
            [
              105.8341972186169,
              21.021768898039003
            ],
            [
              105.83419140913793,
              21.021817468499435
            ],
            [
              105.83418527708487,
              21.02190073969808
            ],
            [
              105.83423640461662,
              21.021907378730806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_110.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13374",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021681381594426,
          "to_latitude": 21.021907378730806,
          "to_longitude": 105.83423640461662,
          "from_longitude": 105.83444366604141
        }
      },
      {
        "id": 13300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83503716030052,
            21.021016578044076,
            105.83521657835627,
            21.02110369743111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83505233653773,
              21.02110369743111
            ],
            [
              105.83504891640227,
              21.02109983848795
            ],
            [
              105.83503716030052,
              21.0210865646173
            ],
            [
              105.83507522476762,
              21.02106449262723
            ],
            [
              105.83521657835627,
              21.021016578044076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.13375",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02110369743111,
          "to_latitude": 21.021016578044076,
          "to_longitude": 105.83521657835627,
          "from_longitude": 105.83505233653773
        }
      },
      {
        "id": 13301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83456718287054,
            21.020920312226323,
            105.83459133606365,
            21.021089772458087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459133606365,
              21.021089772458087
            ],
            [
              105.83458267763949,
              21.021079625671856
            ],
            [
              105.83457625411445,
              21.0210458422887
            ],
            [
              105.83456718287054,
              21.020993669609865
            ],
            [
              105.83457049055164,
              21.020989251969933
            ],
            [
              105.83458951621354,
              21.020957240488066
            ],
            [
              105.83458619197471,
              21.020920312226323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13376",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021089772458087,
          "to_latitude": 21.020920312226323,
          "to_longitude": 105.83458619197471,
          "from_longitude": 105.83459133606365
        }
      },
      {
        "id": 13302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83451389671784,
            21.02108789373851,
            105.83461904083993,
            21.02122915316801
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83451389671784,
              21.02122915316801
            ],
            [
              105.83461904083993,
              21.021223370572955
            ],
            [
              105.83459133606365,
              21.021089772458087
            ],
            [
              105.83460127912788,
              21.02108789373851
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13377",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02122915316801,
          "to_latitude": 21.02108789373851,
          "to_longitude": 105.83460127912788,
          "from_longitude": 105.83451389671784
        }
      },
      {
        "id": 13303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459387536853,
            21.020806148895925,
            105.83503990165516,
            21.020893103676823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459387536853,
              21.020877645061944
            ],
            [
              105.8345946206823,
              21.02087777959821
            ],
            [
              105.83460547192408,
              21.020879719769965
            ],
            [
              105.83462959952341,
              21.020893103676823
            ],
            [
              105.83476904096081,
              21.020872882663785
            ],
            [
              105.83483833789386,
              21.020856893303307
            ],
            [
              105.83494470101093,
              21.02083011541268
            ],
            [
              105.83503990165516,
              21.020806148895925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.3_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13378",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020877645061944,
          "to_latitude": 21.020806148895925,
          "to_longitude": 105.83503990165516,
          "from_longitude": 105.83459387536853
        }
      },
      {
        "id": 13304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83231101241635,
            21.0214237378638,
            105.83291231158447,
            21.021706166088563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83231101241635,
              21.021435680045585
            ],
            [
              105.83231199075293,
              21.021434295131396
            ],
            [
              105.83231945190603,
              21.0214237378638
            ],
            [
              105.83232637277592,
              21.02142762760385
            ],
            [
              105.8324314538733,
              21.021486689139557
            ],
            [
              105.83247346651696,
              21.02150511523379
            ],
            [
              105.8326730598972,
              21.021575618513392
            ],
            [
              105.83272222078949,
              21.02160075663545
            ],
            [
              105.83277800151482,
              21.021629269293335
            ],
            [
              105.83278469379931,
              21.021632701295268
            ],
            [
              105.83285348874598,
              21.02166374517062
            ],
            [
              105.83291231158447,
              21.021706166088563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_89.2_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13379",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021435680045585,
          "to_latitude": 21.021706166088563,
          "to_longitude": 105.83291231158447,
          "from_longitude": 105.83231101241635
        }
      },
      {
        "id": 13305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335146934066,
            21.023360096146803,
            105.8335809533513,
            21.023726646299252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335937215675,
              21.023726646299252
            ],
            [
              105.83336075561888,
              21.023726149069777
            ],
            [
              105.83336790525244,
              21.023723576880748
            ],
            [
              105.8333713115785,
              21.02372235167611
            ],
            [
              105.83335146934066,
              21.0236700366222
            ],
            [
              105.8333571821865,
              21.02366615637968
            ],
            [
              105.83347000084935,
              21.02361087178282
            ],
            [
              105.83339419790813,
              21.023479822349575
            ],
            [
              105.8334925347718,
              21.023410193247933
            ],
            [
              105.83349432952917,
              21.023397504994428
            ],
            [
              105.8335809533513,
              21.023360096146803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74.11_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13380",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023726646299252,
          "to_latitude": 21.023360096146803,
          "to_longitude": 105.8335809533513,
          "from_longitude": 105.83335937215675
        }
      },
      {
        "id": 13306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357104580585,
            21.023151208629248,
            105.83397985865146,
            21.023360096146803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8335809533513,
              21.023360096146803
            ],
            [
              105.83357104580585,
              21.023336555205013
            ],
            [
              105.83388087291677,
              21.023176941533073
            ],
            [
              105.83393909631778,
              21.023151208629248
            ],
            [
              105.83397985865146,
              21.02322127867347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74.11_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13381",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023360096146803,
          "to_latitude": 21.02322127867347,
          "to_longitude": 105.83397985865146,
          "from_longitude": 105.8335809533513
        }
      },
      {
        "id": 13307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83323804340924,
            21.023253073450043,
            105.8334625337987,
            21.02373133808385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336057282334,
              21.02373133808385
            ],
            [
              105.83336261806866,
              21.023730656063616
            ],
            [
              105.83336935130737,
              21.02372759094812
            ],
            [
              105.83336790525244,
              21.023723576880748
            ],
            [
              105.83334817842939,
              21.0236688342981
            ],
            [
              105.83334639654782,
              21.023664111038734
            ],
            [
              105.83336329327922,
              21.023656388778242
            ],
            [
              105.8334625337987,
              21.023606873674048
            ],
            [
              105.83333638228655,
              21.0234043251492
            ],
            [
              105.8332538492567,
              21.0232773830629
            ],
            [
              105.83323804340924,
              21.023253073450043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74.12_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13382",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02373133808385,
          "to_latitude": 21.023253073450043,
          "to_longitude": 105.83323804340924,
          "from_longitude": 105.83336057282334
        }
      },
      {
        "id": 13308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83306776205418,
            21.023253073450043,
            105.83323804340924,
            21.023359162100217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83323804340924,
              21.023253073450043
            ],
            [
              105.83320477091344,
              21.0232732744782
            ],
            [
              105.83315483322278,
              21.023304255826474
            ],
            [
              105.83306776205418,
              21.023359162100217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74.12_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13383",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023253073450043,
          "to_latitude": 21.023359162100217,
          "to_longitude": 105.83306776205418,
          "from_longitude": 105.83323804340924
        }
      },
      {
        "id": 13309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83354026915671,
            21.023939814300423,
            105.83403708617607,
            21.024213012079766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83354026915671,
              21.024213012079766
            ],
            [
              105.83354128358427,
              21.024212580786283
            ],
            [
              105.83357761705341,
              21.02419026017548
            ],
            [
              105.83362615161865,
              21.024165893563573
            ],
            [
              105.83365531893487,
              21.024141797098235
            ],
            [
              105.83385664489566,
              21.024038261708004
            ],
            [
              105.83403708617607,
              21.023939814300423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_74.3_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13384",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024213012079766,
          "to_latitude": 21.023939814300423,
          "to_longitude": 105.83403708617607,
          "from_longitude": 105.83354026915671
        }
      },
      {
        "id": 13310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8340990676024,
            21.020673564446803,
            105.83425748331202,
            21.020733783098436
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8340990676024,
              21.020673564446803
            ],
            [
              105.8341256325795,
              21.020733783098436
            ],
            [
              105.8341295269842,
              21.02073263587379
            ],
            [
              105.83425744392764,
              21.020700477883743
            ],
            [
              105.83425748331202,
              21.020700467755105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.3_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13385",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020673564446803,
          "to_latitude": 21.020700467755105,
          "to_longitude": 105.83425748331202,
          "from_longitude": 105.8340990676024
        }
      },
      {
        "id": 13311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83457998255142,
            21.020725016880956,
            105.83460862748008,
            21.02087320468635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460862748008,
              21.02087320468635
            ],
            [
              105.83460455529745,
              21.020849151037208
            ],
            [
              105.8345860156886,
              21.020739648411816
            ],
            [
              105.83457998255142,
              21.020725016880956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.2_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13386",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02087320468635,
          "to_latitude": 21.020725016880956,
          "to_longitude": 105.83457998255142,
          "from_longitude": 105.83460862748008
        }
      },
      {
        "id": 13312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722825366641,
            21.01990045801194,
            105.83737463213843,
            21.019956052995237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722825366641,
              21.019956052995237
            ],
            [
              105.83723405556354,
              21.019953693218067
            ],
            [
              105.83727558925324,
              21.019936797210622
            ],
            [
              105.83729628377391,
              21.019927801302412
            ],
            [
              105.83730250220526,
              21.019925098054085
            ],
            [
              105.83730726389759,
              21.019933535111146
            ],
            [
              105.83731060465524,
              21.01993213131493
            ],
            [
              105.83733138604342,
              21.019922326589747
            ],
            [
              105.83733389592516,
              21.019921141850347
            ],
            [
              105.83734096414841,
              21.019919023319286
            ],
            [
              105.83737463213843,
              21.01990045801194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.8_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13387",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019956052995237,
          "to_latitude": 21.01990045801194,
          "to_longitude": 105.83737463213843,
          "from_longitude": 105.83722825366641
        }
      },
      {
        "id": 13313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84128626248732,
            21.01897972575143,
            105.84128710409335,
            21.01912975055417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84128626248732,
              21.01897972575143
            ],
            [
              105.8412869654516,
              21.019105036347128
            ],
            [
              105.84128710409335,
              21.01912975055417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13388",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01897972575143,
          "to_latitude": 21.01912975055417,
          "to_longitude": 105.84128710409335,
          "from_longitude": 105.84128626248732
        }
      },
      {
        "id": 13314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8412869654516,
            21.01910475872494,
            105.84134465930471,
            21.019383060215215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8412869654516,
              21.019105036347128
            ],
            [
              105.84134309797311,
              21.01910475872494
            ],
            [
              105.84134354525676,
              21.019184485817956
            ],
            [
              105.84134465930471,
              21.019383060215215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13389",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019105036347128,
          "to_latitude": 21.019383060215215,
          "to_longitude": 105.84134465930471,
          "from_longitude": 105.8412869654516
        }
      },
      {
        "id": 13315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84054306963944,
            21.028296924407933,
            105.84074558961788,
            21.028362622500904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84074558961788,
              21.028296924407933
            ],
            [
              105.84074495260872,
              21.028297068460116
            ],
            [
              105.84074090001153,
              21.02829871383699
            ],
            [
              105.8407422411154,
              21.028308415219954
            ],
            [
              105.8407211187137,
              21.028311231750294
            ],
            [
              105.8406953996384,
              21.028316028218896
            ],
            [
              105.84063908140266,
              21.028326863505505
            ],
            [
              105.84062316134987,
              21.02834717077241
            ],
            [
              105.84054306963944,
              21.028362622500904
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.3_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13390",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": 23.822,
          "from_latitude": 21.028296924407933,
          "to_latitude": 21.028362622500904,
          "to_longitude": 105.84054306963944,
          "from_longitude": 105.84074558961788
        }
      },
      {
        "id": 13316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83224902627114,
            21.020902806417453,
            105.83240480490846,
            21.020913039905142
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83240480490846,
              21.020902806417453
            ],
            [
              105.83238915333241,
              21.020904483525875
            ],
            [
              105.83224902627114,
              21.020913039905142
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_94.3_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13391",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020902806417453,
          "to_latitude": 21.020913039905142,
          "to_longitude": 105.83224902627114,
          "from_longitude": 105.83240480490846
        }
      },
      {
        "id": 13317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83708328018149,
            21.027283159999424,
            105.83722201585277,
            21.027596514490835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371231815135,
              21.027283159999424
            ],
            [
              105.83708328018149,
              21.027307622406383
            ],
            [
              105.83719606869609,
              21.027530588643057
            ],
            [
              105.83719965110595,
              21.027540384522823
            ],
            [
              105.83721168499079,
              21.027549870536227
            ],
            [
              105.837208207088,
              21.027563781936802
            ],
            [
              105.83722178774146,
              21.02759597729307
            ],
            [
              105.83722201585277,
              21.027596514490835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.2_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.13392",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027283159999424,
          "to_latitude": 21.027596514490835,
          "to_longitude": 105.83722201585277,
          "from_longitude": 105.8371231815135
        }
      },
      {
        "id": 13318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83540465683811,
            21.02323071707078,
            105.83567110833307,
            21.023496342871233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83567110833307,
              21.023496342871233
            ],
            [
              105.8356705229695,
              21.023495567168677
            ],
            [
              105.83565406855284,
              21.02347371323161
            ],
            [
              105.83561035589328,
              21.02340708945681
            ],
            [
              105.83555459771623,
              21.023338989366305
            ],
            [
              105.83553341591217,
              21.023287748776262
            ],
            [
              105.83544982961546,
              21.02323071707078
            ],
            [
              105.83540465683811,
              21.023245069893548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_72.9_Văn Hương",
          "maTaiSan": "04.O14.DODV.13393",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023496342871233,
          "to_latitude": 21.023245069893548,
          "to_longitude": 105.83540465683811,
          "from_longitude": 105.83567110833307
        }
      },
      {
        "id": 13319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8388787721804,
            21.023024081851403,
            105.83919885715702,
            21.02381152179429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905341714066,
              21.02381152179429
            ],
            [
              105.83905886120831,
              21.02381017803717
            ],
            [
              105.83914423627174,
              21.023743754706672
            ],
            [
              105.83914792571439,
              21.02372196521695
            ],
            [
              105.83917002833013,
              21.023577931195774
            ],
            [
              105.83918897660764,
              21.023490624298905
            ],
            [
              105.83919884071122,
              21.023400889198676
            ],
            [
              105.83919885715702,
              21.023400733763182
            ],
            [
              105.83917359542288,
              21.023395579069692
            ],
            [
              105.83911853367275,
              21.023417649122653
            ],
            [
              105.83903444212949,
              21.023269201996104
            ],
            [
              105.83901173041754,
              21.02322910886971
            ],
            [
              105.83888955676369,
              21.02304072202757
            ],
            [
              105.8388787721804,
              21.023024081851403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_17.13_Linh Quang",
          "maTaiSan": "04.O14.DODV.13394",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 110,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02381152179429,
          "to_latitude": 21.023024081851403,
          "to_longitude": 105.8388787721804,
          "from_longitude": 105.83905341714066
        }
      },
      {
        "id": 13320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83980115040589,
            21.01966600470985,
            105.83980812439293,
            21.01988229285219
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980566356419,
              21.01966600470985
            ],
            [
              105.83980284869705,
              21.01980081925888
            ],
            [
              105.83980115040589,
              21.019882164703297
            ],
            [
              105.83980812439293,
              21.01988229285219
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_5.6_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13395",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01966600470985,
          "to_latitude": 21.01988229285219,
          "to_longitude": 105.83980812439293,
          "from_longitude": 105.83980566356419
        }
      },
      {
        "id": 13321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83979313338516,
            21.019882164703297,
            105.83980510428381,
            21.020040023576566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83980115040589,
              21.019882164703297
            ],
            [
              105.83980510428381,
              21.019965341075032
            ],
            [
              105.8398018631482,
              21.019985560374163
            ],
            [
              105.83979313338516,
              21.020040023576566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_5.6_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13396",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019882164703297,
          "to_latitude": 21.020040023576566,
          "to_longitude": 105.83979313338516,
          "from_longitude": 105.83980115040589
        }
      },
      {
        "id": 13322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952970568141,
            21.020048156257815,
            105.83977299692386,
            21.020082702396895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83952970568141,
              21.020082702396895
            ],
            [
              105.83964189893786,
              21.020080183213505
            ],
            [
              105.83977299692386,
              21.020048156257815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_5.7_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13397",
          "diaChiLapD": "Ngõ Thiên Hùng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020082702396895,
          "to_latitude": 21.020048156257815,
          "to_longitude": 105.83977299692386,
          "from_longitude": 105.83952970568141
        }
      },
      {
        "id": 13323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398464383286,
            21.023523118050246,
            105.83443471199094,
            21.023682409862086
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83443471199094,
              21.02353737357258
            ],
            [
              105.83426480308097,
              21.023612959707897
            ],
            [
              105.83410611952728,
              21.02365726346777
            ],
            [
              105.83407516848979,
              21.02366590465938
            ],
            [
              105.83401316203361,
              21.023682409862086
            ],
            [
              105.8339866103684,
              21.023599255598135
            ],
            [
              105.83400348560218,
              21.023567269174826
            ],
            [
              105.83398818831498,
              21.023529091753474
            ],
            [
              105.83398700404571,
              21.023526140405707
            ],
            [
              105.83398464383286,
              21.023523118050246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_75.6_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13398",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02353737357258,
          "to_latitude": 21.023523118050246,
          "to_longitude": 105.83398464383286,
          "from_longitude": 105.83443471199094
        }
      },
      {
        "id": 13324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83381558010414,
            21.02359694192465,
            105.83432967683578,
            21.02383278594258
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83432967683578,
              21.02370029211773
            ],
            [
              105.83406240227585,
              21.023800674971607
            ],
            [
              105.83396121699215,
              21.023832602448977
            ],
            [
              105.83396063600922,
              21.02383278594258
            ],
            [
              105.83396032527725,
              21.023832265402877
            ],
            [
              105.83388570599772,
              21.02370709581013
            ],
            [
              105.83388386780291,
              21.02370401218433
            ],
            [
              105.83381762485415,
              21.02360015008677
            ],
            [
              105.83381558010414,
              21.02359694192465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_75.4_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13399",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02370029211773,
          "to_latitude": 21.02359694192465,
          "to_longitude": 105.83381558010414,
          "from_longitude": 105.83432967683578
        }
      },
      {
        "id": 13325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83314714680539,
            21.024446823723576,
            105.83334913730255,
            21.02464855476863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83314714680539,
              21.02449220232706
            ],
            [
              105.83318838576513,
              21.024477180445572
            ],
            [
              105.83327891032583,
              21.024446823723576
            ],
            [
              105.833316125974,
              21.02453726796071
            ],
            [
              105.83334462444246,
              21.02461738461041
            ],
            [
              105.83334710842855,
              21.02464301168988
            ],
            [
              105.83334850118476,
              21.02464712717902
            ],
            [
              105.83334913730255,
              21.02464855476863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_70.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.13400",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 45,
          "chatLieu": "HDPE",
          "chieuDai": 38.176,
          "from_latitude": 21.02449220232706,
          "to_latitude": 21.02464855476863,
          "to_longitude": 105.83334913730255,
          "from_longitude": 105.83314714680539
        }
      },
      {
        "id": 13326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327891032583,
            21.02439487302885,
            105.83342240850291,
            21.024446823723576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83327891032583,
              21.024446823723576
            ],
            [
              105.83342240850291,
              21.02439487302885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_70.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.13401",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": 15.988,
          "from_latitude": 21.024446823723576,
          "to_latitude": 21.02439487302885,
          "to_longitude": 105.83342240850291,
          "from_longitude": 105.83327891032583
        }
      },
      {
        "id": 13327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750705696582,
            21.02101899812093,
            105.8380884412685,
            21.02142288303921
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750705696582,
              21.02142288303921
            ],
            [
              105.83751325657327,
              21.021420244003536
            ],
            [
              105.83756982748986,
              21.021396160988743
            ],
            [
              105.83770995263959,
              21.021351617614698
            ],
            [
              105.83776430951981,
              21.021331753558936
            ],
            [
              105.83785423813099,
              21.02124714282355
            ],
            [
              105.83793016521875,
              21.02119146623375
            ],
            [
              105.83789547835227,
              21.021100034897515
            ],
            [
              105.83790128055615,
              21.021097035613213
            ],
            [
              105.83798196558914,
              21.02105532559145
            ],
            [
              105.83799995765598,
              21.021049187160067
            ],
            [
              105.8380884412685,
              21.02101899812093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.17_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13402",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 86,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02142288303921,
          "to_latitude": 21.02101899812093,
          "to_longitude": 105.8380884412685,
          "from_longitude": 105.83750705696582
        }
      },
      {
        "id": 13328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603796297794,
            21.019174994390774,
            105.83623075929313,
            21.019227766098204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83603796297794,
              21.019227766098204
            ],
            [
              105.83623075929313,
              21.01921593548233
            ],
            [
              105.83623014391672,
              21.019180447226418
            ],
            [
              105.8362301587233,
              21.019174994390774
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_114.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13403",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019227766098204,
          "to_latitude": 21.019174994390774,
          "to_longitude": 105.8362301587233,
          "from_longitude": 105.83603796297794
        }
      },
      {
        "id": 13329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83623075929313,
            21.019197778973364,
            105.83649251777291,
            21.01921593548233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83623075929313,
              21.01921593548233
            ],
            [
              105.83626748879881,
              21.019212998278064
            ],
            [
              105.83630096396818,
              21.01921074635437
            ],
            [
              105.83630496806668,
              21.01921047737887
            ],
            [
              105.83638795690844,
              21.01920489568589
            ],
            [
              105.83649251777291,
              21.019197778973364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_114.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13404",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01921593548233,
          "to_latitude": 21.019197778973364,
          "to_longitude": 105.83649251777291,
          "from_longitude": 105.83623075929313
        }
      },
      {
        "id": 13330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919211854935,
            21.019020593946724,
            105.83935564618339,
            21.019038016460666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919211854935,
              21.019038016460666
            ],
            [
              105.83931909647487,
              21.019024007829806
            ],
            [
              105.83935564618339,
              21.019020593946724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.9_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13405",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019038016460666,
          "to_latitude": 21.019020593946724,
          "to_longitude": 105.83935564618339,
          "from_longitude": 105.83919211854935
        }
      },
      {
        "id": 13331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862726918784,
            21.019665973468857,
            105.83880239168374,
            21.01996826056722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8386844926698,
              21.01996826056722
            ],
            [
              105.8386503784499,
              21.019888773490226
            ],
            [
              105.83864520980747,
              21.01987673010157
            ],
            [
              105.83862726918784,
              21.01983492885591
            ],
            [
              105.8387343337394,
              21.01978969593175
            ],
            [
              105.83870420706923,
              21.01973294862622
            ],
            [
              105.83874660532292,
              21.01971089240332
            ],
            [
              105.83878268425613,
              21.019690523842982
            ],
            [
              105.83879168971792,
              21.019682536489388
            ],
            [
              105.83880239168374,
              21.019671554712925
            ],
            [
              105.83879907023898,
              21.01966643265336
            ],
            [
              105.83879877427226,
              21.019665973468857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_4.5_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13406",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01996826056722,
          "to_latitude": 21.019665973468857,
          "to_longitude": 105.83879877427226,
          "from_longitude": 105.8386844926698
        }
      },
      {
        "id": 13332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915093789892,
            21.019580340673286,
            105.83921320002044,
            21.0197182546419
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920914987343,
              21.019580340673286
            ],
            [
              105.83921201872266,
              21.019583485091818
            ],
            [
              105.83921258032431,
              21.01958413986773
            ],
            [
              105.83915093789892,
              21.01962248412952
            ],
            [
              105.83921213267725,
              21.019716613331546
            ],
            [
              105.83921320002044,
              21.0197182546419
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.6_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13407",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019580340673286,
          "to_latitude": 21.0197182546419,
          "to_longitude": 105.83921320002044,
          "from_longitude": 105.83920914987343
        }
      },
      {
        "id": 13333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909365043826,
            21.0197182546419,
            105.83921320002044,
            21.019819634326634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921320002044,
              21.0197182546419
            ],
            [
              105.83920637796264,
              21.019722239901245
            ],
            [
              105.83909365043826,
              21.019788085513206
            ],
            [
              105.83911559714315,
              21.019819634326634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.6_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13408",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0197182546419,
          "to_latitude": 21.019819634326634,
          "to_longitude": 105.83911559714315,
          "from_longitude": 105.83921320002044
        }
      },
      {
        "id": 13334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83915337809616,
            21.022017525719246,
            105.8391651607954,
            21.02201983312814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83915337809616,
              21.022017525719246
            ],
            [
              105.83915670922245,
              21.022018177669626
            ],
            [
              105.83916259527372,
              21.022019330045346
            ],
            [
              105.83916494411905,
              21.022019791745684
            ],
            [
              105.8391651607954,
              21.02201983312814
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.20_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13409",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022017525719246,
          "to_latitude": 21.02201983312814,
          "to_longitude": 105.8391651607954,
          "from_longitude": 105.83915337809616
        }
      },
      {
        "id": 13335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83906577142517,
            21.020961903623096,
            105.83949959414814,
            21.02120666371272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83910608641675,
              21.02120666371272
            ],
            [
              105.8391090427582,
              21.021204980875027
            ],
            [
              105.83911774863923,
              21.02120120580803
            ],
            [
              105.83906577142517,
              21.021110439898315
            ],
            [
              105.83908067415952,
              21.02110233853442
            ],
            [
              105.83909684763243,
              21.021093545354052
            ],
            [
              105.83915367877188,
              21.02108165314892
            ],
            [
              105.83936968955766,
              21.021075167982477
            ],
            [
              105.83937366482951,
              21.020962504690587
            ],
            [
              105.8394954584044,
              21.020961903623096
            ],
            [
              105.83949959414814,
              21.021118652947905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.11_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13410",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02120666371272,
          "to_latitude": 21.021118652947905,
          "to_longitude": 105.83949959414814,
          "from_longitude": 105.83910608641675
        }
      },
      {
        "id": 13336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83918334113615,
            21.02110247088989,
            105.83950765528462,
            21.021219093319054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83950765528462,
              21.021216561390766
            ],
            [
              105.83936962172524,
              21.021219093319054
            ],
            [
              105.83936633889024,
              21.02110247088989
            ],
            [
              105.83918334113615,
              21.02110760815165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.12_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13411",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021216561390766,
          "to_latitude": 21.02110760815165,
          "to_longitude": 105.83918334113615,
          "from_longitude": 105.83950765528462
        }
      },
      {
        "id": 13337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83891408137092,
            21.020991591184544,
            105.83892548315733,
            21.02100130016664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83891408137092,
              21.02100130016664
            ],
            [
              105.8389210704581,
              21.020995348680046
            ],
            [
              105.83892540273307,
              21.020991659322966
            ],
            [
              105.83892548315733,
              21.020991591184544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.7_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13412",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02100130016664,
          "to_latitude": 21.020991591184544,
          "to_longitude": 105.83892548315733,
          "from_longitude": 105.83891408137092
        }
      },
      {
        "id": 13338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391938520885,
            21.02135150154686,
            105.83921105640991,
            21.021389294061198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921105640991,
              21.02135150154686
            ],
            [
              105.83920969136204,
              21.021352187505702
            ],
            [
              105.8391938520885,
              21.021359637941533
            ],
            [
              105.83920211667342,
              21.021389294061198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.13_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13413",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.97,
          "chatLieu": "HDPE",
          "chieuDai": 5.397,
          "from_latitude": 21.02135150154686,
          "to_latitude": 21.021389294061198,
          "to_longitude": 105.83920211667342,
          "from_longitude": 105.83921105640991
        }
      },
      {
        "id": 13339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83723186496174,
            21.020272240276157,
            105.83723876751584,
            21.02028489839995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83723186496174,
              21.020272240276157
            ],
            [
              105.83723255697784,
              21.020273511318585
            ],
            [
              105.8372346213165,
              21.020277294697095
            ],
            [
              105.83723876751584,
              21.02028489839995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_7.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13414",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020272240276157,
          "to_latitude": 21.02028489839995,
          "to_longitude": 105.83723876751584,
          "from_longitude": 105.83723186496174
        }
      },
      {
        "id": 13340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83598137423624,
            21.021189141938066,
            105.83598897437646,
            21.021194936700038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83598137423624,
              21.021189141938066
            ],
            [
              105.83598240407628,
              21.021189927197337
            ],
            [
              105.83598634761717,
              21.02119293542132
            ],
            [
              105.83598897437646,
              21.021194936700038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_11.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13415",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021189141938066,
          "to_latitude": 21.021194936700038,
          "to_longitude": 105.83598897437646,
          "from_longitude": 105.83598137423624
        }
      },
      {
        "id": 13341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83487716913321,
            21.024876862632592,
            105.83531783538128,
            21.02556883264306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83511596712383,
              21.02556883264306
            ],
            [
              105.8351058351723,
              21.025553951209776
            ],
            [
              105.83505754104732,
              21.025483006214785
            ],
            [
              105.83496891039105,
              21.02526853471225
            ],
            [
              105.83490177107993,
              21.025106070934903
            ],
            [
              105.83487716913321,
              21.025031647767523
            ],
            [
              105.83531783538128,
              21.024876862632592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13416",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02556883264306,
          "to_latitude": 21.024876862632592,
          "to_longitude": 105.83531783538128,
          "from_longitude": 105.83511596712383
        }
      },
      {
        "id": 13342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83496891039105,
            21.025104849172898,
            105.83539151422868,
            21.02526853471225
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83496891039105,
              21.02526853471225
            ],
            [
              105.83539151422868,
              21.025104849172898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.1_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13417",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 50,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02526853471225,
          "to_latitude": 21.025104849172898,
          "to_longitude": 105.83539151422868,
          "from_longitude": 105.83496891039105
        }
      },
      {
        "id": 13343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83542482522259,
            21.02474609070182,
            105.83558360415239,
            21.02534517610818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8355757457209,
              21.02534517610818
            ],
            [
              105.8355755601111,
              21.025345013536693
            ],
            [
              105.83555675380971,
              21.025328409851458
            ],
            [
              105.83547594474733,
              21.025124159010485
            ],
            [
              105.83547472809195,
              21.02512092783465
            ],
            [
              105.83547009882015,
              21.025122456250013
            ],
            [
              105.83545358188861,
              21.025127908860334
            ],
            [
              105.83542482522259,
              21.025043203120298
            ],
            [
              105.83558360415239,
              21.02498734902248
            ],
            [
              105.83549838643314,
              21.024767907750803
            ],
            [
              105.83555274166257,
              21.02474609070182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13418",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02534517610818,
          "to_latitude": 21.02474609070182,
          "to_longitude": 105.83555274166257,
          "from_longitude": 105.8355757457209
        }
      },
      {
        "id": 13344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83565354404233,
            21.025181619165096,
            105.83592613068797,
            21.02532058926532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592613068797,
              21.025181619165096
            ],
            [
              105.8358285967385,
              21.025217184258292
            ],
            [
              105.83567886691614,
              21.025271781996096
            ],
            [
              105.8356871390596,
              21.025291096521755
            ],
            [
              105.83565848879641,
              21.025302482435723
            ],
            [
              105.83565475619598,
              21.02531472771187
            ],
            [
              105.83565354404233,
              21.02532058926532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.7_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13419",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025181619165096,
          "to_latitude": 21.02532058926532,
          "to_longitude": 105.83565354404233,
          "from_longitude": 105.83592613068797
        }
      },
      {
        "id": 13345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83582216320198,
            21.025399797964713,
            105.83600183655115,
            21.02576013843868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591634827556,
              21.02576013843868
            ],
            [
              105.83600183655115,
              21.02572253521857
            ],
            [
              105.83598859894028,
              21.025694157790614
            ],
            [
              105.8359651414464,
              21.025645151301212
            ],
            [
              105.83593563699726,
              21.025588927970738
            ],
            [
              105.83588758840773,
              21.025497390330848
            ],
            [
              105.835878002952,
              21.025477532208484
            ],
            [
              105.8358371219752,
              21.02540819668071
            ],
            [
              105.83582755503924,
              21.025408912988766
            ],
            [
              105.83582495577294,
              21.025404954290437
            ],
            [
              105.83582216320198,
              21.025399797964713
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62.10_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13420",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02576013843868,
          "to_latitude": 21.025399797964713,
          "to_longitude": 105.83582216320198,
          "from_longitude": 105.83591634827556
        }
      },
      {
        "id": 13346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313849918366,
            21.025038503532897,
            105.83435987813367,
            21.02552642216896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435987813367,
              21.02538889678821
            ],
            [
              105.83397895935845,
              21.02552642216896
            ],
            [
              105.83379012755829,
              21.025038503532897
            ],
            [
              105.83315919072469,
              21.025261219059654
            ],
            [
              105.83314281946211,
              21.02527593871431
            ],
            [
              105.83313849918366,
              21.025278026455226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_69.9_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13421",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 172,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02538889678821,
          "to_latitude": 21.025278026455226,
          "to_longitude": 105.83313849918366,
          "from_longitude": 105.83435987813367
        }
      },
      {
        "id": 13347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83313993895658,
            21.02506945811248,
            105.83384860334866,
            21.025387249974234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83313993895658,
              21.025281081330665
            ],
            [
              105.83314422056375,
              21.025278786037685
            ],
            [
              105.83375692513899,
              21.02506945811248
            ],
            [
              105.83384860334866,
              21.025321955531613
            ],
            [
              105.83361582692481,
              21.025387249974234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_69.8_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13422",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 124,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025281081330665,
          "to_latitude": 21.025387249974234,
          "to_longitude": 105.83361582692481,
          "from_longitude": 105.83313993895658
        }
      },
      {
        "id": 13348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83393597533926,
            21.024439310557266,
            105.83438909532025,
            21.0247127671182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83438909532025,
              21.02450793425501
            ],
            [
              105.83421677958131,
              21.024604653157535
            ],
            [
              105.834035038852,
              21.0247127671182
            ],
            [
              105.83398792937038,
              21.02463241974411
            ],
            [
              105.8339848895656,
              21.024627249243114
            ],
            [
              105.8340159876523,
              21.024610073684006
            ],
            [
              105.83400016257214,
              21.024548460203192
            ],
            [
              105.83394723713337,
              21.024458460582313
            ],
            [
              105.83393722839634,
              21.024440618603553
            ],
            [
              105.83393597533926,
              21.024439310557266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.11_Văn Hương",
          "maTaiSan": "04.O14.DODV.13423",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 80,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02450793425501,
          "to_latitude": 21.024439310557266,
          "to_longitude": 105.83393597533926,
          "from_longitude": 105.83438909532025
        }
      },
      {
        "id": 13349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83630654366995,
            21.026278389659844,
            105.83632107079136,
            21.026293502630914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83630654366995,
              21.026293502630914
            ],
            [
              105.83630924430531,
              21.026291140066345
            ],
            [
              105.83630974307242,
              21.02629070316317
            ],
            [
              105.83631758239358,
              21.02628379559872
            ],
            [
              105.83632107079136,
              21.026280721936146
            ],
            [
              105.83631908182258,
              21.026278389659844
            ],
            [
              105.836315724586,
              21.026281349774457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.3_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13424",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.87,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026293502630914,
          "to_latitude": 21.026281349774457,
          "to_longitude": 105.836315724586,
          "from_longitude": 105.83630654366995
        }
      },
      {
        "id": 13350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83615134985628,
            21.026412202066215,
            105.83619329366115,
            21.026543056903552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83615610239868,
              21.026543056903552
            ],
            [
              105.83618338200067,
              21.0265239108252
            ],
            [
              105.83615134985628,
              21.026484891924092
            ],
            [
              105.83619329366115,
              21.02644632128173
            ],
            [
              105.83615856734708,
              21.026412202066215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_58.3_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13425",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026543056903552,
          "to_latitude": 21.026412202066215,
          "to_longitude": 105.83615856734708,
          "from_longitude": 105.83615610239868
        }
      },
      {
        "id": 13351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.832934249221,
            21.019385767419898,
            105.83293625069851,
            21.01942796168616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83293625069851,
              21.01942796168616
            ],
            [
              105.83293539322932,
              21.019409884234147
            ],
            [
              105.83293457189379,
              21.019392596926593
            ],
            [
              105.832934249221,
              21.019385767419898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_104.3_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13426",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01942796168616,
          "to_latitude": 21.019385767419898,
          "to_longitude": 105.832934249221,
          "from_longitude": 105.83293625069851
        }
      },
      {
        "id": 13352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344431285028,
            21.019742056485597,
            105.83489594075525,
            21.019919901837323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344431285028,
              21.019743173743972
            ],
            [
              105.8344433476032,
              21.019743132927307
            ],
            [
              105.83444901430958,
              21.019742056485597
            ],
            [
              105.83445511240055,
              21.01976007298982
            ],
            [
              105.83450138242033,
              21.019919901837323
            ],
            [
              105.83451760482413,
              21.019916588708124
            ],
            [
              105.83479281869201,
              21.019860387286283
            ],
            [
              105.83485802548357,
              21.019851243666704
            ],
            [
              105.83489594075525,
              21.019874647980597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.5_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13427",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019743173743972,
          "to_latitude": 21.019874647980597,
          "to_longitude": 105.83489594075525,
          "from_longitude": 105.8344431285028
        }
      },
      {
        "id": 13353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83413496663181,
            21.020886799719452,
            105.83459657530747,
            21.021034528116584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413496663181,
              21.021034528116584
            ],
            [
              105.8341849593987,
              21.02101707652645
            ],
            [
              105.83429904037135,
              21.02099042105362
            ],
            [
              105.8344065457056,
              21.02096194254051
            ],
            [
              105.83455447437494,
              21.020908647356492
            ],
            [
              105.83457056147331,
              21.020898643586975
            ],
            [
              105.83458976746786,
              21.020887445327805
            ],
            [
              105.83458976746786,
              21.020887445327805
            ],
            [
              105.83459657530747,
              21.020886799719452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.4_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13428",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 51,
          "chatLieu": "HDPE",
          "chieuDai": 50.916,
          "from_latitude": 21.021034528116584,
          "to_latitude": 21.020886799719452,
          "to_longitude": 105.83459657530747,
          "from_longitude": 105.83413496663181
        }
      },
      {
        "id": 13354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83885891108454,
            21.020735746060343,
            105.83900777368609,
            21.020994065150028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83892803948555,
              21.020994065150028
            ],
            [
              105.83892824257376,
              21.02099391331009
            ],
            [
              105.83898338224323,
              21.020934556134254
            ],
            [
              105.83900115186277,
              21.020838476368553
            ],
            [
              105.83900718683468,
              21.0207996688883
            ],
            [
              105.83900777368609,
              21.020735746060343
            ],
            [
              105.83885891108454,
              21.02073699791548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.8_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13429",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 29.7,
          "chatLieu": "HDPE",
          "chieuDai": 46.44,
          "from_latitude": 21.020994065150028,
          "to_latitude": 21.02073699791548,
          "to_longitude": 105.83885891108454,
          "from_longitude": 105.83892803948555
        }
      },
      {
        "id": 13355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83238349772488,
            21.02215285938045,
            105.83244684504595,
            21.022204613206316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83238349772488,
              21.02215285938045
            ],
            [
              105.83240491105198,
              21.022204613206316
            ],
            [
              105.83244684504595,
              21.022193414832167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.5_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13430",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02215285938045,
          "to_latitude": 21.022193414832167,
          "to_longitude": 105.83244684504595,
          "from_longitude": 105.83238349772488
        }
      },
      {
        "id": 13356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8343671233025,
            21.027178257823586,
            105.83449990010615,
            21.027230613468255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83449990010615,
              21.027178257823586
            ],
            [
              105.83442856525527,
              21.027202829612055
            ],
            [
              105.8343671233025,
              21.027230613468255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57.15_Ngõ 67, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13431",
          "diaChiLapD": "Ngõ 67, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027178257823586,
          "to_latitude": 21.027230613468255,
          "to_longitude": 105.8343671233025,
          "from_longitude": 105.83449990010615
        }
      },
      {
        "id": 13357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362652411519,
            21.025773589169194,
            105.83626947499461,
            21.025783432450595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83626947499461,
              21.025783432450595
            ],
            [
              105.83626690973789,
              21.02577746843688
            ],
            [
              105.8362652411519,
              21.025773589169194
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.12_Lương Sử B",
          "maTaiSan": "04.O14.DODV.13432",
          "diaChiLapD": "Lương Sử B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025783432450595,
          "to_latitude": 21.025773589169194,
          "to_longitude": 105.8362652411519,
          "from_longitude": 105.83626947499461
        }
      },
      {
        "id": 13358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647285240352,
            21.025688382309927,
            105.83647846454863,
            21.0256978349367
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647285240352,
              21.025688382309927
            ],
            [
              105.83647370019982,
              21.025689810651954
            ],
            [
              105.83647725550611,
              21.025695800502312
            ],
            [
              105.83647846454863,
              21.0256978349367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_58.15_Lương Sử A",
          "maTaiSan": "04.O14.DODV.13433",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025688382309927,
          "to_latitude": 21.0256978349367,
          "to_longitude": 105.83647846454863,
          "from_longitude": 105.83647285240352
        }
      },
      {
        "id": 13359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83520449753136,
            21.0196986910609,
            105.83536558596575,
            21.019733908469544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536558596575,
              21.0196986910609
            ],
            [
              105.83520759325903,
              21.019733241141644
            ],
            [
              105.83520449753136,
              21.019733908469544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.5_Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13434",
          "diaChiLapD": "Ngách 15, Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0196986910609,
          "to_latitude": 21.019733908469544,
          "to_longitude": 105.83520449753136,
          "from_longitude": 105.83536558596575
        }
      },
      {
        "id": 13360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83802022005189,
            21.019717280142217,
            105.83811932340748,
            21.019837247727555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83811932340748,
              21.019717280142217
            ],
            [
              105.83806824168545,
              21.019766236346317
            ],
            [
              105.8380550658642,
              21.01978571966461
            ],
            [
              105.8380317357809,
              21.019820218519943
            ],
            [
              105.83802022005189,
              21.019837247727555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_6.7_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13435",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019717280142217,
          "to_latitude": 21.019837247727555,
          "to_longitude": 105.83802022005189,
          "from_longitude": 105.83811932340748
        }
      },
      {
        "id": 13361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444237899576,
            21.019746862426032,
            105.83444461890744,
            21.019747643864278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444237899576,
              21.019747643864278
            ],
            [
              105.83444442689196,
              21.019746930206576
            ],
            [
              105.83444461890744,
              21.019746862426032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13436",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019747643864278,
          "to_latitude": 21.019746862426032,
          "to_longitude": 105.83444461890744,
          "from_longitude": 105.83444237899576
        }
      },
      {
        "id": 13362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454683042193,
            21.02046042017696,
            105.83455066878753,
            21.02055913909171
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455066878753,
              21.02055913909171
            ],
            [
              105.83454894949219,
              21.020505508661945
            ],
            [
              105.83454683042193,
              21.02046042017696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13437",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02055913909171,
          "to_latitude": 21.02046042017696,
          "to_longitude": 105.83454683042193,
          "from_longitude": 105.83455066878753
        }
      },
      {
        "id": 13363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390735459655,
            21.019248502377852,
            105.8391848780115,
            21.01937778411982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390735459655,
              21.019248502377852
            ],
            [
              105.83913487559764,
              21.019327056670946
            ],
            [
              105.83917887570871,
              21.01937778411982
            ],
            [
              105.83918020582644,
              21.019377047753398
            ],
            [
              105.8391848780115,
              21.019374459549418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_4.1_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13438",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 30,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019248502377852,
          "to_latitude": 21.019374459549418,
          "to_longitude": 105.8391848780115,
          "from_longitude": 105.8390735459655
        }
      },
      {
        "id": 13364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903905224581,
            21.019401604060164,
            105.83919441094218,
            21.01952415205285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919441094218,
              21.019401604060164
            ],
            [
              105.8390601553021,
              21.019509287483082
            ],
            [
              105.83903905224581,
              21.01952415205285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_4.2_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13439",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019401604060164,
          "to_latitude": 21.01952415205285,
          "to_longitude": 105.83903905224581,
          "from_longitude": 105.83919441094218
        }
      },
      {
        "id": 13365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83282918838947,
            21.02128602783486,
            105.83285787162923,
            21.021338666386264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83285787162923,
              21.02128602783486
            ],
            [
              105.83283756928921,
              21.021323284988753
            ],
            [
              105.83282918838947,
              21.021338666386264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.2_Ngách 221/65, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13440",
          "diaChiLapD": "Ngách 221/65, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02128602783486,
          "to_latitude": 21.021338666386264,
          "to_longitude": 105.83282918838947,
          "from_longitude": 105.83285787162923
        }
      },
      {
        "id": 13366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83358207784899,
            21.02256611132527,
            105.83361632681786,
            21.022661204815854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358207784899,
              21.022661204815854
            ],
            [
              105.83361632681786,
              21.02263931803194
            ],
            [
              105.83358648423125,
              21.022566645470437
            ],
            [
              105.83358618497147,
              21.02256611132527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_78.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13441",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022661204815854,
          "to_latitude": 21.02256611132527,
          "to_longitude": 105.83358618497147,
          "from_longitude": 105.83358207784899
        }
      },
      {
        "id": 13367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592670736684,
            21.021253693949,
            105.83593881466855,
            21.02126731106073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592670736684,
              21.021253693949
            ],
            [
              105.83592783030362,
              21.021254958362878
            ],
            [
              105.83593057275718,
              21.021258041135944
            ],
            [
              105.83593881466855,
              21.02126731106073
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13442",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021253693949,
          "to_latitude": 21.02126731106073,
          "to_longitude": 105.83593881466855,
          "from_longitude": 105.83592670736684
        }
      },
      {
        "id": 13368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726686734744,
            21.021758266361267,
            105.8372814713068,
            21.021770880245885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83726686734744,
              21.021758266361267
            ],
            [
              105.8372814713068,
              21.021770880245885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.13_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13443",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021758266361267,
          "to_latitude": 21.021770880245885,
          "to_longitude": 105.8372814713068,
          "from_longitude": 105.83726686734744
        }
      },
      {
        "id": 13369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83683382226064,
            21.022220903077674,
            105.83686421430502,
            21.022249167372372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684970195984,
              21.022228667860933
            ],
            [
              105.8368404765389,
              21.022220903077674
            ],
            [
              105.83683382226064,
              21.022227748832336
            ],
            [
              105.83684401204839,
              21.02223490732427
            ],
            [
              105.83685321289138,
              21.02224137068315
            ],
            [
              105.83686421430502,
              21.022249167372372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.10_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13444",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022228667860933,
          "to_latitude": 21.022249167372372,
          "to_longitude": 105.83686421430502,
          "from_longitude": 105.83684970195984
        }
      },
      {
        "id": 13370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326468661283,
            21.02353968311271,
            105.83266856270123,
            21.023580198491356
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83266856270123,
              21.023580198491356
            ],
            [
              105.8326468661283,
              21.02353968311271
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.13_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13445",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023580198491356,
          "to_latitude": 21.02353968311271,
          "to_longitude": 105.8326468661283,
          "from_longitude": 105.83266856270123
        }
      },
      {
        "id": 13371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83422147492868,
            21.022605262915164,
            105.83428627399196,
            21.022745474974304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428627399196,
              21.022745474974304
            ],
            [
              105.83427947852351,
              21.022730428984307
            ],
            [
              105.834273524574,
              21.022717245830066
            ],
            [
              105.83425523080454,
              21.022671472561907
            ],
            [
              105.83422147492868,
              21.022605262915164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13446",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022745474974304,
          "to_latitude": 21.022605262915164,
          "to_longitude": 105.83422147492868,
          "from_longitude": 105.83428627399196
        }
      },
      {
        "id": 13372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8393109247106,
            21.022952905641695,
            105.8393656971537,
            21.023043127448062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393656971537,
              21.023043127448062
            ],
            [
              105.8393183280937,
              21.022965100553403
            ],
            [
              105.8393109247106,
              21.022952905641695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.27_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13447",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023043127448062,
          "to_latitude": 21.022952905641695,
          "to_longitude": 105.8393109247106,
          "from_longitude": 105.8393656971537
        }
      },
      {
        "id": 13373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83771866198097,
            21.02337627815328,
            105.83789386364727,
            21.02376865589353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83778751445465,
              21.02376865589353
            ],
            [
              105.83778723165314,
              21.02376779922457
            ],
            [
              105.83773812365506,
              21.02361828129598
            ],
            [
              105.83773533414387,
              21.02360920138484
            ],
            [
              105.83771866198097,
              21.02356220480138
            ],
            [
              105.8377673095205,
              21.023522581937268
            ],
            [
              105.83789386364727,
              21.02342944948441
            ],
            [
              105.83783563860862,
              21.02337627815328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.13448",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02376865589353,
          "to_latitude": 21.02337627815328,
          "to_longitude": 105.83783563860862,
          "from_longitude": 105.83778751445465
        }
      },
      {
        "id": 13374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768102845892,
            21.02360920138484,
            105.83773533414387,
            21.02362649462445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773533414387,
              21.02360920138484
            ],
            [
              105.83768102845892,
              21.02362649462445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.13449",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02360920138484,
          "to_latitude": 21.02362649462445,
          "to_longitude": 105.83768102845892,
          "from_longitude": 105.83773533414387
        }
      },
      {
        "id": 13375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773812365506,
            21.023589666179195,
            105.83780745579536,
            21.02361828129598
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83773812365506,
              21.02361828129598
            ],
            [
              105.83780745579536,
              21.023589666179195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.3_Linh Quang",
          "maTaiSan": "04.O14.DODV.13450",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02361828129598,
          "to_latitude": 21.023589666179195,
          "to_longitude": 105.83780745579536,
          "from_longitude": 105.83773812365506
        }
      },
      {
        "id": 13376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83849561100159,
            21.029219942801006,
            105.83852666695145,
            21.029324997914415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83852666695145,
              21.029324997914415
            ],
            [
              105.83849561100159,
              21.029219942801006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.5_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13451",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029324997914415,
          "to_latitude": 21.029219942801006,
          "to_longitude": 105.83849561100159,
          "from_longitude": 105.83852666695145
        }
      },
      {
        "id": 13377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83852666695145,
            21.029282398283726,
            105.83866971049123,
            21.029324997914415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83852666695145,
              21.029324997914415
            ],
            [
              105.83866971049123,
              21.029282398283726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.5_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13452",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029324997914415,
          "to_latitude": 21.029282398283726,
          "to_longitude": 105.83866971049123,
          "from_longitude": 105.83852666695145
        }
      },
      {
        "id": 13378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83852666695145,
            21.029324997914415,
            105.8385289418959,
            21.029332692989993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8385289418959,
              21.029332692989993
            ],
            [
              105.83852666695145,
              21.029324997914415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.5_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13453",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029332692989993,
          "to_latitude": 21.029324997914415,
          "to_longitude": 105.83852666695145,
          "from_longitude": 105.8385289418959
        }
      },
      {
        "id": 13379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524815041805,
            21.021322648530838,
            105.83571523625317,
            21.02144663577486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83524815041805,
              21.02144663577486
            ],
            [
              105.83524821675643,
              21.02144507467827
            ],
            [
              105.83524874557332,
              21.021432764753055
            ],
            [
              105.83525346346003,
              21.021322648530838
            ],
            [
              105.83542105401169,
              21.021325659660494
            ],
            [
              105.83571523625317,
              21.02133244828783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13454",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02144663577486,
          "to_latitude": 21.02133244828783,
          "to_longitude": 105.83571523625317,
          "from_longitude": 105.83524815041805
        }
      },
      {
        "id": 13380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647277851374,
            21.02420705511079,
            105.83674523650014,
            21.024420202291754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670336628526,
              21.02420705511079
            ],
            [
              105.83670710036142,
              21.02420716048184
            ],
            [
              105.83670980578198,
              21.02420773155743
            ],
            [
              105.83670933433007,
              21.024214785341357
            ],
            [
              105.83674523650014,
              21.024217315668984
            ],
            [
              105.83673743164937,
              21.02428570820249
            ],
            [
              105.83671957753248,
              21.024283720434934
            ],
            [
              105.83671710016596,
              21.024306086496953
            ],
            [
              105.83671598285132,
              21.024316177385447
            ],
            [
              105.83670446249299,
              21.024420202291754
            ],
            [
              105.83652065039296,
              21.024390490841352
            ],
            [
              105.83647277851374,
              21.024295613267572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.3_Linh Quang B",
          "maTaiSan": "04.O14.DODV.13455",
          "diaChiLapD": "Linh Quang B",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02420705511079,
          "to_latitude": 21.024295613267572,
          "to_longitude": 105.83647277851374,
          "from_longitude": 105.83670336628526
        }
      },
      {
        "id": 13381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83189167418826,
            21.019587510349492,
            105.83189282604165,
            21.019625556666785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83189282604165,
              21.019625556666785
            ],
            [
              105.83189167418826,
              21.019587510349492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.3_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13456",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019625556666785,
          "to_latitude": 21.019587510349492,
          "to_longitude": 105.83189167418826,
          "from_longitude": 105.83189282604165
        }
      },
      {
        "id": 13382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83179061936806,
            21.019625556666785,
            105.83190320475745,
            21.019825514161735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83179061936806,
              21.019626414158466
            ],
            [
              105.83179147836248,
              21.01962640724836
            ],
            [
              105.83180299636696,
              21.019626310280515
            ],
            [
              105.83189282604165,
              21.019625556666785
            ],
            [
              105.83190320475745,
              21.019825514161735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_101.3_Ngõ Vạn Anh, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13457",
          "diaChiLapD": "Ngõ Vạn Anh, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019626414158466,
          "to_latitude": 21.019825514161735,
          "to_longitude": 105.83190320475745,
          "from_longitude": 105.83179061936806
        }
      },
      {
        "id": 13383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435552241981,
            21.01978655178806,
            105.83436970886702,
            21.019837968690503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435552241981,
              21.01978655178806
            ],
            [
              105.8343670570397,
              21.019832836933134
            ],
            [
              105.83436970886702,
              21.019837968690503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.7_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13458",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01978655178806,
          "to_latitude": 21.019837968690503,
          "to_longitude": 105.83436970886702,
          "from_longitude": 105.83435552241981
        }
      },
      {
        "id": 13384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83416948789952,
            21.019834934728006,
            105.83437744926127,
            21.01992102101752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83437744926127,
              21.019834934728006
            ],
            [
              105.83437724954013,
              21.019835000739835
            ],
            [
              105.8343702748602,
              21.01983786023701
            ],
            [
              105.83436970886702,
              21.019837968690503
            ],
            [
              105.83436578539225,
              21.019838727676266
            ],
            [
              105.83427778208875,
              21.019863748332643
            ],
            [
              105.83416948789952,
              21.01992102101752
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.7_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13459",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 23.769,
          "from_latitude": 21.019834934728006,
          "to_latitude": 21.01992102101752,
          "to_longitude": 105.83416948789952,
          "from_longitude": 105.83437744926127
        }
      },
      {
        "id": 13385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83172991778832,
            21.020767019913343,
            105.83189710436199,
            21.021239308535154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317482809776,
              21.020767019913343
            ],
            [
              105.83172991778832,
              21.020773758342372
            ],
            [
              105.8317322664866,
              21.020799522176304
            ],
            [
              105.83174852717671,
              21.020849561689705
            ],
            [
              105.83177966420193,
              21.020889442146498
            ],
            [
              105.83177975628315,
              21.02088939382529
            ],
            [
              105.83178461643942,
              21.020886842836333
            ],
            [
              105.83183544073444,
              21.020902884848134
            ],
            [
              105.83188921859954,
              21.020950888352782
            ],
            [
              105.83185347133679,
              21.0209882706104
            ],
            [
              105.83189710436199,
              21.02103264669794
            ],
            [
              105.83184306627014,
              21.02108184316702
            ],
            [
              105.83183644470436,
              21.02111976401054
            ],
            [
              105.83183296246264,
              21.021135562178525
            ],
            [
              105.8318138616596,
              21.021176336820115
            ],
            [
              105.83180140685349,
              21.021237180219654
            ],
            [
              105.83180097230405,
              21.021239308535154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_91.7_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13460",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 64,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020767019913343,
          "to_latitude": 21.021239308535154,
          "to_longitude": 105.83180097230405,
          "from_longitude": 105.8317482809776
        }
      },
      {
        "id": 13386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83171800905716,
            21.02118055388973,
            105.83178506627291,
            21.021234789069137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83172574210035,
              21.021234789069137
            ],
            [
              105.83171800905716,
              21.021227204570945
            ],
            [
              105.83178506627291,
              21.02118055388973
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.5_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13461",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021234789069137,
          "to_latitude": 21.02118055388973,
          "to_longitude": 105.83178506627291,
          "from_longitude": 105.83172574210035
        }
      },
      {
        "id": 13387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83168052882888,
            21.021234789069137,
            105.83172574210035,
            21.0212652184929
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168052882888,
              21.0212652184929
            ],
            [
              105.8316831152759,
              21.021263256687728
            ],
            [
              105.83172574210035,
              21.021234789069137
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_91.5_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13462",
          "diaChiLapD": "Ngõ 221, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0212652184929,
          "to_latitude": 21.021234789069137,
          "to_longitude": 105.83172574210035,
          "from_longitude": 105.83168052882888
        }
      },
      {
        "id": 13388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83419436320933,
            21.023453312849224,
            105.83425914427318,
            21.02347788215931
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83419436320933,
              21.02347788215931
            ],
            [
              105.83425914427318,
              21.023453312849224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13463",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02347788215931,
          "to_latitude": 21.023453312849224,
          "to_longitude": 105.83425914427318,
          "from_longitude": 105.83419436320933
        }
      },
      {
        "id": 13389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398791799266,
            21.02333241761435,
            105.83419436320933,
            21.023514502887732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398855545192,
              21.023514502887732
            ],
            [
              105.83403454392983,
              21.02349421767395
            ],
            [
              105.83398791799266,
              21.023397825747175
            ],
            [
              105.83412390031438,
              21.02333241761435
            ],
            [
              105.83419436320933,
              21.02347788215931
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_75.7_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13464",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 50.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023514502887732,
          "to_latitude": 21.02347788215931,
          "to_longitude": 105.83419436320933,
          "from_longitude": 105.83398855545192
        }
      },
      {
        "id": 13390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83278728441182,
            21.024177572964106,
            105.83321276599769,
            21.024532375835427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83278728441182,
              21.024532375835427
            ],
            [
              105.83279007231492,
              21.024530998315612
            ],
            [
              105.83302035646616,
              21.02443907844856
            ],
            [
              105.83295248012071,
              21.024286528501904
            ],
            [
              105.83321276599769,
              21.024177572964106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13465",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024532375835427,
          "to_latitude": 21.024177572964106,
          "to_longitude": 105.83321276599769,
          "from_longitude": 105.83278728441182
        }
      },
      {
        "id": 13391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240404397641,
            21.020102345044368,
            105.8324104198997,
            21.020104422049368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8324104198997,
              21.020102345044368
            ],
            [
              105.83240570037428,
              21.020103882847554
            ],
            [
              105.83240404397641,
              21.020104422049368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103.3_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13466",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020102345044368,
          "to_latitude": 21.020104422049368,
          "to_longitude": 105.83240404397641,
          "from_longitude": 105.8324104198997
        }
      },
      {
        "id": 13392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221252673897,
            21.019657913797577,
            105.83222371877368,
            21.019659893325645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221252673897,
              21.019659893325645
            ],
            [
              105.83222063005107,
              21.0196584591019
            ],
            [
              105.83222109710624,
              21.01965837642986
            ],
            [
              105.83222352080516,
              21.019657949088664
            ],
            [
              105.83222371877368,
              21.019657913797577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_102.2_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13467",
          "diaChiLapD": "Ngõ Kiến Thiết, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019659893325645,
          "to_latitude": 21.019657913797577,
          "to_longitude": 105.83222371877368,
          "from_longitude": 105.83221252673897
        }
      },
      {
        "id": 13393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315635762544,
            21.019693721359094,
            105.83316561582289,
            21.019694712381753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315635762544,
              21.019694712381753
            ],
            [
              105.83315979555942,
              21.019694344188505
            ],
            [
              105.8331653562505,
              21.01969374972723
            ],
            [
              105.83316561582289,
              21.019693721359094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.2_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13468",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019694712381753,
          "to_latitude": 21.019693721359094,
          "to_longitude": 105.83316561582289,
          "from_longitude": 105.83315635762544
        }
      },
      {
        "id": 13394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83316539433882,
            21.019695896613275,
            105.83317219522944,
            21.019696796116595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83317219522944,
              21.019695896613275
            ],
            [
              105.83316888754331,
              21.0196963337171
            ],
            [
              105.83316577692376,
              21.019696746371782
            ],
            [
              105.83316539433882,
              21.019696796116595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_105.3_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13469",
          "diaChiLapD": "Ngõ Tiến Bộ, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019695896613275,
          "to_latitude": 21.019696796116595,
          "to_longitude": 105.83316539433882,
          "from_longitude": 105.83317219522944
        }
      },
      {
        "id": 13395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8344119888797,
            21.019920849279647,
            105.83441559286383,
            21.01992160669248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344119888797,
              21.01992160669248
            ],
            [
              105.83441559286383,
              21.019920849279647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.8_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13470",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.45,
          "chatLieu": "HDPE",
          "chieuDai": 0.768,
          "from_latitude": 21.01992160669248,
          "to_latitude": 21.019920849279647,
          "to_longitude": 105.83441559286383,
          "from_longitude": 105.8344119888797
        }
      },
      {
        "id": 13396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439345235377,
            21.019917926168382,
            105.83441104491494,
            21.019927906257028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439345235377,
              21.019927906257028
            ],
            [
              105.8343999063628,
              21.019925793740267
            ],
            [
              105.83440493550907,
              21.019921412223812
            ],
            [
              105.83441104491494,
              21.019917926168382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.8_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13471",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.15,
          "chatLieu": "HDPE",
          "chieuDai": 2.167,
          "from_latitude": 21.019927906257028,
          "to_latitude": 21.019917926168382,
          "to_longitude": 105.83441104491494,
          "from_longitude": 105.83439345235377
        }
      },
      {
        "id": 13397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455373583743,
            21.020597709697288,
            105.83456829507453,
            21.020598023927196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455373583743,
              21.020597737776086
            ],
            [
              105.83455597977857,
              21.020598023927196
            ],
            [
              105.83456829507453,
              21.020597709697288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_109.2_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13472",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020597737776086,
          "to_latitude": 21.020597709697288,
          "to_longitude": 105.83456829507453,
          "from_longitude": 105.83455373583743
        }
      },
      {
        "id": 13398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435112508774,
            21.020676926558043,
            105.83435567966733,
            21.02069173335014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435112508774,
              21.020676926558043
            ],
            [
              105.8343542659691,
              21.02068713655141
            ],
            [
              105.83435549452825,
              21.02069113542072
            ],
            [
              105.83435567966733,
              21.02069173335014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.3_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13473",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020676926558043,
          "to_latitude": 21.02069173335014,
          "to_longitude": 105.83435567966733,
          "from_longitude": 105.83435112508774
        }
      },
      {
        "id": 13399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372949224226,
            21.020429125091457,
            105.8339792926473,
            21.02054729886106
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8339792926473,
              21.020429125091457
            ],
            [
              105.83397831511897,
              21.020429786528204
            ],
            [
              105.833920587148,
              21.020468826906047
            ],
            [
              105.83380832890896,
              21.020505943357268
            ],
            [
              105.83380173165276,
              21.020520115671665
            ],
            [
              105.83373784168171,
              21.02054729886106
            ],
            [
              105.83372949224226,
              21.02053457542146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13474",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020429125091457,
          "to_latitude": 21.02053457542146,
          "to_longitude": 105.83372949224226,
          "from_longitude": 105.8339792926473
        }
      },
      {
        "id": 13400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83347564009212,
            21.02053457542146,
            105.83372949224226,
            21.020626430044565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372949224226,
              21.02053457542146
            ],
            [
              105.83357546866742,
              21.020590307752613
            ],
            [
              105.83347564009212,
              21.020626430044565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_108.6_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13475",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02053457542146,
          "to_latitude": 21.020626430044565,
          "to_longitude": 105.83347564009212,
          "from_longitude": 105.83372949224226
        }
      },
      {
        "id": 13401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83538247301456,
            21.019830046172892,
            105.83538560312243,
            21.019830931349897
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83538247301456,
              21.019830931349897
            ],
            [
              105.83538535303889,
              21.01983005101398
            ],
            [
              105.83538560312243,
              21.019830046172892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.8_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13476",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019830931349897,
          "to_latitude": 21.019830046172892,
          "to_longitude": 105.83538560312243,
          "from_longitude": 105.83538247301456
        }
      },
      {
        "id": 13402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83532855996525,
            21.020119636512696,
            105.83534394233422,
            21.020123683529484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534394233422,
              21.020119636512696
            ],
            [
              105.8353347690087,
              21.020122049815217
            ],
            [
              105.83532994412703,
              21.020123319055383
            ],
            [
              105.83532855996525,
              21.020123683529484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_113.13_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13477",
          "diaChiLapD": "Ngõ Hòa Bình, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020119636512696,
          "to_latitude": 21.020123683529484,
          "to_longitude": 105.83532855996525,
          "from_longitude": 105.83534394233422
        }
      },
      {
        "id": 13403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361700393301,
            21.019882891789567,
            105.83617560001174,
            21.019887127671307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617560001174,
              21.019887127671307
            ],
            [
              105.83617171165031,
              21.01988383105071
            ],
            [
              105.8361700393301,
              21.019882891789567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_115.6_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13478",
          "diaChiLapD": "Ngõ Đại Đồng, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019887127671307,
          "to_latitude": 21.019882891789567,
          "to_longitude": 105.8361700393301,
          "from_longitude": 105.83617560001174
        }
      },
      {
        "id": 13404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83687833308764,
            21.019537899600632,
            105.83688054139668,
            21.01953934207486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688054139668,
              21.019537899600632
            ],
            [
              105.83687858325045,
              21.01953917916705
            ],
            [
              105.83687833308764,
              21.01953934207486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.2_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13479",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019537899600632,
          "to_latitude": 21.01953934207486,
          "to_longitude": 105.83687833308764,
          "from_longitude": 105.83688054139668
        }
      },
      {
        "id": 13405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733138604342,
            21.019922326589747,
            105.83733460899059,
            21.01992618284438
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83733460899059,
              21.01992618284438
            ],
            [
              105.83733284450463,
              21.019924071664263
            ],
            [
              105.83733171047608,
              21.01992271608855
            ],
            [
              105.83733138604342,
              21.019922326589747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_117.8_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13480",
          "diaChiLapD": "Ngõ Liên Hoa, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01992618284438,
          "to_latitude": 21.019922326589747,
          "to_longitude": 105.83733138604342,
          "from_longitude": 105.83733460899059
        }
      },
      {
        "id": 13406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733516610653,
            21.019502986209343,
            105.83735006442963,
            21.01951290107266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735006442963,
              21.019502986209343
            ],
            [
              105.83733961403985,
              21.019509940990897
            ],
            [
              105.83733736945968,
              21.019511433326795
            ],
            [
              105.83733733209048,
              21.019511459704212
            ],
            [
              105.83733516610653,
              21.01951290107266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13481",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.89,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019502986209343,
          "to_latitude": 21.01951290107266,
          "to_longitude": 105.83733516610653,
          "from_longitude": 105.83735006442963
        }
      },
      {
        "id": 13407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841217546275,
            21.01924872923997,
            105.83842945310097,
            21.019249953363683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83842945310097,
              21.01924872923997
            ],
            [
              105.83841822379225,
              21.019249525231015
            ],
            [
              105.83841459287774,
              21.019249783384605
            ],
            [
              105.83841217546275,
              21.019249953363683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_6.1_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13482",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 1.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01924872923997,
          "to_latitude": 21.019249953363683,
          "to_longitude": 105.83841217546275,
          "from_longitude": 105.83842945310097
        }
      },
      {
        "id": 13408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907260616647,
            21.019237848977042,
            105.83909548716933,
            21.019241432101218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83907260616647,
              21.019237848977042
            ],
            [
              105.83907413091774,
              21.019239576548156
            ],
            [
              105.83909548716933,
              21.019241432101218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_3.2_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13483",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019237848977042,
          "to_latitude": 21.019241432101218,
          "to_longitude": 105.83909548716933,
          "from_longitude": 105.83907260616647
        }
      },
      {
        "id": 13409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84059416122392,
            21.018896974315652,
            105.84073568690542,
            21.01923513713459
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84059416122392,
              21.018896974315652
            ],
            [
              105.84059468533283,
              21.0189007219085
            ],
            [
              105.84059479165627,
              21.018901499058273
            ],
            [
              105.84060055716049,
              21.018942605158735
            ],
            [
              105.84060103429223,
              21.01894603775876
            ],
            [
              105.84061208245254,
              21.019165207172378
            ],
            [
              105.84068640418515,
              21.0191651858139
            ],
            [
              105.84069000146769,
              21.019230829649594
            ],
            [
              105.84073568690542,
              21.01923513713459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.12_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13484",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018896974315652,
          "to_latitude": 21.01923513713459,
          "to_longitude": 105.84073568690542,
          "from_longitude": 105.84059416122392
        }
      },
      {
        "id": 13410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84046955145836,
            21.01923513713459,
            105.84073660472947,
            21.019474079320425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84073568690542,
              21.01923513713459
            ],
            [
              105.84073660472947,
              21.019455477495313
            ],
            [
              105.84066390248104,
              21.019454756555852
            ],
            [
              105.840637378932,
              21.019474079320425
            ],
            [
              105.84060190451403,
              21.01947404146784
            ],
            [
              105.84046955145836,
              21.019473898824288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_1.12_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13485",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 102,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01923513713459,
          "to_latitude": 21.019473898824288,
          "to_longitude": 105.84046955145836,
          "from_longitude": 105.84073568690542
        }
      },
      {
        "id": 13411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84096880782853,
            21.018884308274853,
            105.84097043457301,
            21.01892068928656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84097043457301,
              21.01892068928656
            ],
            [
              105.84096937214977,
              21.018896940673535
            ],
            [
              105.84096913360479,
              21.01889162638464
            ],
            [
              105.8409690256911,
              21.018889193635008
            ],
            [
              105.84096889698361,
              21.018886312086256
            ],
            [
              105.84096880782853,
              21.018884308274853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_1.13_Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13486",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01892068928656,
          "to_latitude": 21.018884308274853,
          "to_longitude": 105.84096880782853,
          "from_longitude": 105.84097043457301
        }
      },
      {
        "id": 13412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84134354525676,
            21.019183468601575,
            105.84138183324664,
            21.019184485817956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84134354525676,
              21.019184485817956
            ],
            [
              105.84137261858397,
              21.019183468601575
            ],
            [
              105.84138183324664,
              21.01918382315166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13487",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019184485817956,
          "to_latitude": 21.01918382315166,
          "to_longitude": 105.84138183324664,
          "from_longitude": 105.84134354525676
        }
      },
      {
        "id": 13413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8414421774751,
            21.026450586353192,
            105.8414659962871,
            21.026451176595263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8414659962871,
              21.026450586353192
            ],
            [
              105.8414608954851,
              21.026450712753423
            ],
            [
              105.84144585086385,
              21.026451086161572
            ],
            [
              105.8414421774751,
              21.026451176595263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_33.9_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13488",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.47,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026450586353192,
          "to_latitude": 21.026451176595263,
          "to_longitude": 105.8414421774751,
          "from_longitude": 105.8414659962871
        }
      },
      {
        "id": 13414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84019710984482,
            21.02790503828132,
            105.84020512858837,
            21.027926618387824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019710984482,
              21.02790503828132
            ],
            [
              105.84019865270359,
              21.027908947933472
            ],
            [
              105.84020128358414,
              21.027915600715296
            ],
            [
              105.84020512858837,
              21.027926618387824
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.4A_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13490",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.17,
          "chatLieu": "HDPE",
          "chieuDai": 2.532,
          "from_latitude": 21.02790503828132,
          "to_latitude": 21.027926618387824,
          "to_longitude": 105.84020512858837,
          "from_longitude": 105.84019710984482
        }
      },
      {
        "id": 13415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728669748568,
            21.028263734896562,
            105.83730773422529,
            21.028267985176413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728669748568,
              21.028267985176413
            ],
            [
              105.83730106150729,
              21.0282650828454
            ],
            [
              105.83730743537916,
              21.028263795980784
            ],
            [
              105.83730773422529,
              21.028263734896562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.2_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13491",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028267985176413,
          "to_latitude": 21.028263734896562,
          "to_longitude": 105.83730773422529,
          "from_longitude": 105.83728669748568
        }
      },
      {
        "id": 13416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730763863093,
            21.028265280222115,
            105.83731435576372,
            21.02826694361467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730763863093,
              21.02826694361467
            ],
            [
              105.83730825832791,
              21.028266799660525
            ],
            [
              105.83731435576372,
              21.028265280222115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.1_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13492",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.72,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02826694361467,
          "to_latitude": 21.028265280222115,
          "to_longitude": 105.83731435576372,
          "from_longitude": 105.83730763863093
        }
      },
      {
        "id": 13417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719412653706,
            21.027838833028603,
            105.83721007132436,
            21.027842213712603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83721007132436,
              21.027838833028603
            ],
            [
              105.83720372089012,
              21.02784017938646
            ],
            [
              105.83719523827091,
              21.0278419779174
            ],
            [
              105.83719412653706,
              21.027842213712603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_47.4_Ngõ 15, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13493",
          "diaChiLapD": "Ngõ 15, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027838833028603,
          "to_latitude": 21.027842213712603,
          "to_longitude": 105.83719412653706,
          "from_longitude": 105.83721007132436
        }
      },
      {
        "id": 13418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8370038604131,
            21.028196082997106,
            105.83701971463087,
            21.02820200584605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83701971463087,
              21.02820200584605
            ],
            [
              105.83701002615655,
              21.028201143990014
            ],
            [
              105.8370038604131,
              21.028200595373423
            ],
            [
              105.83700438549019,
              21.028196082997106
            ],
            [
              105.83701043370415,
              21.02819660238587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.3_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13494",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.78,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02820200584605,
          "to_latitude": 21.02819660238587,
          "to_longitude": 105.83701043370415,
          "from_longitude": 105.83701971463087
        }
      },
      {
        "id": 13419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83700213645879,
            21.02812275643595,
            105.83701112828348,
            21.028188850218033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8370086504815,
              21.02812275643595
            ],
            [
              105.83700213645879,
              21.028188187254358
            ],
            [
              105.83700519859136,
              21.028188365471586
            ],
            [
              105.83701112828348,
              21.028188850218033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_48.4_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13495",
          "diaChiLapD": "Ngõ 221, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02812275643595,
          "to_latitude": 21.028188850218033,
          "to_longitude": 105.83701112828348,
          "from_longitude": 105.8370086504815
        }
      },
      {
        "id": 13420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706712419968,
            21.02870045624903,
            105.83707813508646,
            21.028723941762944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706712419968,
              21.02870045624903
            ],
            [
              105.83706784560255,
              21.028705259649527
            ],
            [
              105.83707331459458,
              21.02871602921033
            ],
            [
              105.83707813508646,
              21.028723941762944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.5_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13496",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.58,
          "chatLieu": "HDPE",
          "chieuDai": 2.867,
          "from_latitude": 21.02870045624903,
          "to_latitude": 21.028723941762944,
          "to_longitude": 105.83707813508646,
          "from_longitude": 105.83706712419968
        }
      },
      {
        "id": 13421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83897817057183,
            21.028266920879446,
            105.83898019762572,
            21.02828713477126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897817057183,
              21.028266920879446
            ],
            [
              105.83897935656489,
              21.028273249327427
            ],
            [
              105.83898019762572,
              21.02828713477126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13497",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.5,
          "chatLieu": "HDPE",
          "chieuDai": 2.251,
          "from_latitude": 21.028266920879446,
          "to_latitude": 21.02828713477126,
          "to_longitude": 105.83898019762572,
          "from_longitude": 105.83897817057183
        }
      },
      {
        "id": 13422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84141695041662,
            21.028763949378448,
            105.8414298924741,
            21.028776460897493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84141695041662,
              21.028763949378448
            ],
            [
              105.8414217320503,
              21.02876813292579
            ],
            [
              105.8414298924741,
              21.028776460897493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13498",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028763949378448,
          "to_latitude": 21.028776460897493,
          "to_longitude": 105.8414298924741,
          "from_longitude": 105.84141695041662
        }
      },
      {
        "id": 13423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84146796351311,
            21.028331260416284,
            105.84148300607303,
            21.028393561373495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146796351311,
              21.028331260416284
            ],
            [
              105.84146919186205,
              21.028393441177727
            ],
            [
              105.84147646519003,
              21.028393526217144
            ],
            [
              105.8414793995401,
              21.028393561373495
            ],
            [
              105.84148300607303,
              21.028393548945594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.4_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13499",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028331260416284,
          "to_latitude": 21.028393548945594,
          "to_longitude": 105.84148300607303,
          "from_longitude": 105.84146796351311
        }
      },
      {
        "id": 13424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84146945966882,
            21.028244929368338,
            105.84148303617367,
            21.028395661840026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84146945966882,
              21.028244929368338
            ],
            [
              105.84147646519003,
              21.028393526217144
            ],
            [
              105.84147656568454,
              21.028395661840026
            ],
            [
              105.84147930467311,
              21.028395512802643
            ],
            [
              105.84148303617367,
              21.028395483498322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.6_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.13500",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028244929368338,
          "to_latitude": 21.028395483498322,
          "to_longitude": 105.84148303617367,
          "from_longitude": 105.84146945966882
        }
      },
      {
        "id": 13425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84101781242177,
            21.028904396034214,
            105.8410268693302,
            21.02892967939288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8410268693302,
              21.02892967939288
            ],
            [
              105.84102362175038,
              21.02891952283686
            ],
            [
              105.8410208656526,
              21.02891207186916
            ],
            [
              105.84101781242177,
              21.028904396034214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37.2_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13501",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.99,
          "chatLieu": "HDPE",
          "chieuDai": 2.954,
          "from_latitude": 21.02892967939288,
          "to_latitude": 21.028904396034214,
          "to_longitude": 105.84101781242177,
          "from_longitude": 105.8410268693302
        }
      },
      {
        "id": 13426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84073063029138,
            21.02829495008653,
            105.8407451995095,
            21.028296266609914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84073063029138,
              21.028296266609914
            ],
            [
              105.8407451995095,
              21.02829495008653
            ],
            [
              105.8407451995095,
              21.02829495008653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.1_Ngõ 14, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13502",
          "diaChiLapD": "Ngõ 14, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.46,
          "chatLieu": "HDPE",
          "chieuDai": 1.521,
          "from_latitude": 21.028296266609914,
          "to_latitude": 21.02829495008653,
          "to_longitude": 105.8407451995095,
          "from_longitude": 105.84073063029138
        }
      },
      {
        "id": 13427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401480145413,
            21.02840186544281,
            105.84019366908413,
            21.028409512843588
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018700429807,
              21.02840198693873
            ],
            [
              105.84019043965368,
              21.028401985319462
            ],
            [
              105.84019331349027,
              21.02840186544281
            ],
            [
              105.84019366908413,
              21.028408496047103
            ],
            [
              105.84018789672815,
              21.02840862301954
            ],
            [
              105.8401480145413,
              21.028409512843588
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.3_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13503",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.13,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02840198693873,
          "to_latitude": 21.028409512843588,
          "to_longitude": 105.8401480145413,
          "from_longitude": 105.84018700429807
        }
      },
      {
        "id": 13428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401868044732,
            21.028339006490903,
            105.84019338602012,
            21.028400491286714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84019168840382,
              21.028339006490903
            ],
            [
              105.84019338602012,
              21.02840038831599
            ],
            [
              105.84018875728438,
              21.028400476218582
            ],
            [
              105.8401868044732,
              21.028400491286714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.2_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13504",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028339006490903,
          "to_latitude": 21.028400491286714,
          "to_longitude": 105.8401868044732,
          "from_longitude": 105.84019168840382
        }
      },
      {
        "id": 13429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83877959728073,
            21.029530231592673,
            105.83878975880305,
            21.02956094070585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83877959728073,
              21.029530231592673
            ],
            [
              105.8387811332763,
              21.029535152900483
            ],
            [
              105.83878975880305,
              21.02956094070585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.6_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13505",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029530231592673,
          "to_latitude": 21.02956094070585,
          "to_longitude": 105.83878975880305,
          "from_longitude": 105.83877959728073
        }
      },
      {
        "id": 13430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728002613607,
            21.02947616877089,
            105.83728245626482,
            21.029483411489306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728002613607,
              21.02947616877089
            ],
            [
              105.83728044485422,
              21.029477416769275
            ],
            [
              105.83728167852448,
              21.029481096746345
            ],
            [
              105.83728192209841,
              21.029481818125106
            ],
            [
              105.83728245626482,
              21.029483411489306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.7_Thanh Miến",
          "maTaiSan": "04.O14.DODV.13506",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02947616877089,
          "to_latitude": 21.029483411489306,
          "to_longitude": 105.83728245626482,
          "from_longitude": 105.83728002613607
        }
      },
      {
        "id": 13431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368531701596,
            21.0296633805916,
            105.83689469205305,
            21.02966504731021
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83689469205305,
              21.02966504731021
            ],
            [
              105.83688814096811,
              21.0296633805916
            ],
            [
              105.8368531701596,
              21.02966369090089
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.1_Thanh Miến",
          "maTaiSan": "04.O14.DODV.13507",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02966504731021,
          "to_latitude": 21.02966369090089,
          "to_longitude": 105.8368531701596,
          "from_longitude": 105.83689469205305
        }
      },
      {
        "id": 13432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83684685828663,
            21.027672780370523,
            105.83688849320474,
            21.027739866177715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684685828663,
              21.027672780370523
            ],
            [
              105.83686450705122,
              21.027719590658116
            ],
            [
              105.83687307050573,
              21.027739866177715
            ],
            [
              105.83688849320474,
              21.02773454604385
            ],
            [
              105.8368871856286,
              21.0277313193978
            ],
            [
              105.83687986148601,
              21.027713856401846
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.4_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.13508",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.76,
          "chatLieu": "HDPE",
          "chieuDai": 12.081,
          "from_latitude": 21.027672780370523,
          "to_latitude": 21.027713856401846,
          "to_longitude": 105.83687986148601,
          "from_longitude": 105.83684685828663
        }
      },
      {
        "id": 13433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803430327505,
            21.027090657483274,
            105.83803751253053,
            21.027101324987104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803430327505,
              21.027090657483274
            ],
            [
              105.83803445425312,
              21.027091163447245
            ],
            [
              105.8380357618457,
              21.027095505117902
            ],
            [
              105.83803751253053,
              21.027101324987104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.9_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13509",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027090657483274,
          "to_latitude": 21.027101324987104,
          "to_longitude": 105.83803751253053,
          "from_longitude": 105.83803430327505
        }
      },
      {
        "id": 13434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83802977806094,
            21.027615382197848,
            105.83803419662286,
            21.02762343841332
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803419662286,
              21.02762343841332
            ],
            [
              105.83803376373702,
              21.027622237453972
            ],
            [
              105.83803350087265,
              21.027621507138985
            ],
            [
              105.83803282346587,
              21.027619587516114
            ],
            [
              105.83803133962016,
              21.027615382197848
            ],
            [
              105.83802977806094,
              21.02761586047249
            ],
            [
              105.83803058092371,
              21.027620259726756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.3_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13510",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02762343841332,
          "to_latitude": 21.027620259726756,
          "to_longitude": 105.83803058092371,
          "from_longitude": 105.83803419662286
        }
      },
      {
        "id": 13435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803242468817,
            21.027614507046966,
            105.83803430537209,
            21.027619143956457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83803242468817,
              21.027614507046966
            ],
            [
              105.83803253315514,
              21.027614808188826
            ],
            [
              105.83803430537209,
              21.027619143956457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.6_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13511",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027614507046966,
          "to_latitude": 21.027619143956457,
          "to_longitude": 105.83803430537209,
          "from_longitude": 105.83803242468817
        }
      },
      {
        "id": 13436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83896722460318,
            21.027782006589135,
            105.8389784147014,
            21.027787687068056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389784147014,
              21.027782006589135
            ],
            [
              105.83897504759952,
              21.027783715840627
            ],
            [
              105.83896969668862,
              21.027786432037292
            ],
            [
              105.83896722460318,
              21.027787687068056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.6_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13512",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.32,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027782006589135,
          "to_latitude": 21.027787687068056,
          "to_longitude": 105.83896722460318,
          "from_longitude": 105.8389784147014
        }
      },
      {
        "id": 13437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83793341269697,
            21.027833984933828,
            105.83793713795498,
            21.027844543315446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83793341269697,
              21.027833984933828
            ],
            [
              105.83793371378562,
              21.02783484151178
            ],
            [
              105.83793469322269,
              21.02783761409541
            ],
            [
              105.83793713795498,
              21.027844543315446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.5_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13513",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027833984933828,
          "to_latitude": 21.027844543315446,
          "to_longitude": 105.83793713795498,
          "from_longitude": 105.83793341269697
        }
      },
      {
        "id": 13438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83789326400081,
            21.027835487722257,
            105.83793107427367,
            21.02785704875343
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379283156874,
              21.027835487722257
            ],
            [
              105.83792870914975,
              21.0278361758456
            ],
            [
              105.83793107427367,
              21.027843492037338
            ],
            [
              105.83789326400081,
              21.02785704875343
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_51.6_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13514",
          "diaChiLapD": "Ngõ 34, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027835487722257,
          "to_latitude": 21.02785704875343,
          "to_longitude": 105.83789326400081,
          "from_longitude": 105.8379283156874
        }
      },
      {
        "id": 13439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871619655004,
            21.027284678503012,
            105.83874261878472,
            21.02730038760701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871619655004,
              21.02730038760701
            ],
            [
              105.83872944308835,
              21.027293103717536
            ],
            [
              105.83874261878472,
              21.027284678503012
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.2.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13515",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02730038760701,
          "to_latitude": 21.027284678503012,
          "to_longitude": 105.83874261878472,
          "from_longitude": 105.83871619655004
        }
      },
      {
        "id": 13440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843176650288,
            21.026707602168592,
            105.83846790679281,
            21.02671730393692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83843176650288,
              21.02671730393692
            ],
            [
              105.83843431404249,
              21.026716297831637
            ],
            [
              105.83846790679281,
              21.026707602168592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13516",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02671730393692,
          "to_latitude": 21.026707602168592,
          "to_longitude": 105.83846790679281,
          "from_longitude": 105.83843176650288
        }
      },
      {
        "id": 13441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83841780348129,
            21.02035943573432,
            105.83951057012148,
            21.020579850174652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83841780348129,
              21.02051520352845
            ],
            [
              105.83842554708185,
              21.020508967440456
            ],
            [
              105.83860902665546,
              21.02035943573432
            ],
            [
              105.83862050324113,
              21.02036310675544
            ],
            [
              105.83878559789721,
              21.020415919801195
            ],
            [
              105.83896507060264,
              21.020422204475153
            ],
            [
              105.83898122382193,
              21.020579850174652
            ],
            [
              105.83917659338925,
              21.02057601959196
            ],
            [
              105.83951057012148,
              21.020569471589702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.11_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13517",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 136.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02051520352845,
          "to_latitude": 21.020569471589702,
          "to_longitude": 105.83951057012148,
          "from_longitude": 105.83841780348129
        }
      },
      {
        "id": 13442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916959812518,
            21.02006189152638,
            105.83917659338925,
            21.02057601959196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917659338925,
              21.02057601959196
            ],
            [
              105.83916959812518,
              21.02006189152638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.11_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13518",
          "diaChiLapD": "Ngõ Trung Tiền, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02057601959196,
          "to_latitude": 21.02006189152638,
          "to_longitude": 105.83916959812518,
          "from_longitude": 105.83917659338925
        }
      },
      {
        "id": 13443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83981556288938,
            21.024322445348826,
            105.83982845794539,
            21.02448894949941
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83981556288938,
              21.024322445348826
            ],
            [
              105.83981714520681,
              21.024362947076906
            ],
            [
              105.8398174663532,
              21.024371162108665
            ],
            [
              105.83981883072356,
              21.0244060721573
            ],
            [
              105.83982845794539,
              21.02448894949941
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.6.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13519",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.49,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024322445348826,
          "to_latitude": 21.02448894949941,
          "to_longitude": 105.83982845794539,
          "from_longitude": 105.83981556288938
        }
      },
      {
        "id": 13444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8398174663532,
            21.024369618705528,
            105.83983498423545,
            21.024371162108665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8398174663532,
              21.024371162108665
            ],
            [
              105.83982866445632,
              21.024370224363032
            ],
            [
              105.83983498423545,
              21.024369618705528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13.6.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13520",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024371162108665,
          "to_latitude": 21.024369618705528,
          "to_longitude": 105.83983498423545,
          "from_longitude": 105.8398174663532
        }
      },
      {
        "id": 13445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83959630719717,
            21.024880759811285,
            105.8395993448907,
            21.02505516544384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83959630719717,
              21.02505516544384
            ],
            [
              105.8395993448907,
              21.024880759811285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13521",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02505516544384,
          "to_latitude": 21.024880759811285,
          "to_longitude": 105.8395993448907,
          "from_longitude": 105.83959630719717
        }
      },
      {
        "id": 13446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83959630719717,
            21.025053281304277,
            105.83965074780824,
            21.02507857645956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83965074780824,
              21.02507857645956
            ],
            [
              105.83964863404738,
              21.025053281304277
            ],
            [
              105.83963319307567,
              21.025053973536224
            ],
            [
              105.83962739850394,
              21.025054233369424
            ],
            [
              105.83959630719717,
              21.02505516544384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13522",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02507857645956,
          "to_latitude": 21.02505516544384,
          "to_longitude": 105.83959630719717,
          "from_longitude": 105.83965074780824
        }
      },
      {
        "id": 13447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83941367154628,
            21.024838341140963,
            105.83941393295466,
            21.024863643508144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83941367154628,
              21.024863643508144
            ],
            [
              105.83941367777891,
              21.024862866705774
            ],
            [
              105.83941376512499,
              21.024854584624862
            ],
            [
              105.83941393295466,
              21.024838341140963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.2_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13523",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024863643508144,
          "to_latitude": 21.024838341140963,
          "to_longitude": 105.83941393295466,
          "from_longitude": 105.83941367154628
        }
      },
      {
        "id": 13448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83956344805488,
            21.02610382244741,
            105.83961005622172,
            21.026240063398113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83959171161491,
              21.02610382244741
            ],
            [
              105.83957815825718,
              21.026106281093856
            ],
            [
              105.83956344805488,
              21.026110021704014
            ],
            [
              105.83956658806491,
              21.026119990425187
            ],
            [
              105.83959350838069,
              21.02620546405782
            ],
            [
              105.83961005622172,
              21.026240063398113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.1_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13524",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02610382244741,
          "to_latitude": 21.026240063398113,
          "to_longitude": 105.83961005622172,
          "from_longitude": 105.83959171161491
        }
      },
      {
        "id": 13449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83940845036899,
            21.02568809512,
            105.83943548350645,
            21.025697722328378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943548350645,
              21.02568809512
            ],
            [
              105.83941832965415,
              21.025694204286673
            ],
            [
              105.83941392670056,
              21.025695772336604
            ],
            [
              105.83940952374682,
              21.025697340386426
            ],
            [
              105.83940845036899,
              21.025697722328378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.3_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13525",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02568809512,
          "to_latitude": 21.025697722328378,
          "to_longitude": 105.83940845036899,
          "from_longitude": 105.83943548350645
        }
      },
      {
        "id": 13450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83932147564342,
            21.025636158911293,
            105.83932434161946,
            21.025649936977715
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83932434161946,
              21.025649936977715
            ],
            [
              105.83932233083907,
              21.025640278805575
            ],
            [
              105.83932147564342,
              21.025636158911293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.5_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13526",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025649936977715,
          "to_latitude": 21.025636158911293,
          "to_longitude": 105.83932147564342,
          "from_longitude": 105.83932434161946
        }
      },
      {
        "id": 13451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8390478175267,
            21.025520453814316,
            105.83906513038244,
            21.02552634948618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83905348615559,
              21.02552196725967
            ],
            [
              105.8390478175267,
              21.025523935349796
            ],
            [
              105.83904876898733,
              21.02552634948618
            ],
            [
              105.8390544477582,
              21.0255243027656
            ],
            [
              105.83906513038244,
              21.025520453814316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_27.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13527",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.73,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02552196725967,
          "to_latitude": 21.025520453814316,
          "to_longitude": 105.83906513038244,
          "from_longitude": 105.83905348615559
        }
      },
      {
        "id": 13452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83883104912418,
            21.02546171757065,
            105.83883583051012,
            21.02546304811807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83883583051012,
              21.02546171757065
            ],
            [
              105.83883519754063,
              21.025461894111537
            ],
            [
              105.8388340487753,
              21.02546221319611
            ],
            [
              105.83883104912418,
              21.02546304811807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.11_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13528",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.51,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02546171757065,
          "to_latitude": 21.02546304811807,
          "to_longitude": 105.83883104912418,
          "from_longitude": 105.83883583051012
        }
      },
      {
        "id": 13453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392657467423,
            21.025409800904516,
            105.83933398625352,
            21.025571688353544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392657467423,
              21.025409800904516
            ],
            [
              105.83933398625352,
              21.025571688353544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.5_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13529",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 19.27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025409800904516,
          "to_latitude": 21.025571688353544,
          "to_longitude": 105.83933398625352,
          "from_longitude": 105.8392657467423
        }
      },
      {
        "id": 13454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921760627756,
            21.025316049434046,
            105.8393559036799,
            21.025362861338433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921760627756,
              21.025362861338433
            ],
            [
              105.83922009893115,
              21.025361850976743
            ],
            [
              105.83924362731386,
              21.025353915669825
            ],
            [
              105.8393559036799,
              21.025316049434046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_26.4_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13530",
          "diaChiLapD": "Ngõ 62, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025362861338433,
          "to_latitude": 21.025316049434046,
          "to_longitude": 105.8393559036799,
          "from_longitude": 105.83921760627756
        }
      },
      {
        "id": 13455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903264187867,
            21.024835018170947,
            105.83927008842717,
            21.024880870409547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903264187867,
              21.024880870409547
            ],
            [
              105.83903271949632,
              21.0248751435937
            ],
            [
              105.83903294294583,
              21.024837718355318
            ],
            [
              105.83927008842717,
              21.024835018170947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_13A.4_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13531",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 29.43,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024880870409547,
          "to_latitude": 21.024835018170947,
          "to_longitude": 105.83927008842717,
          "from_longitude": 105.83903264187867
        }
      },
      {
        "id": 13456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83902519771763,
            21.026555134627152,
            105.83906209895,
            21.026569977975857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902519771763,
              21.026569977975857
            ],
            [
              105.83904217306713,
              21.026564440563575
            ],
            [
              105.83906085219903,
              21.026555134627152
            ],
            [
              105.83906209895,
              21.026562179935123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_14.12_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13532",
          "diaChiLapD": "Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.85,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026569977975857,
          "to_latitude": 21.026562179935123,
          "to_longitude": 105.83906209895,
          "from_longitude": 105.83902519771763
        }
      },
      {
        "id": 13457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900005950368,
            21.02634457132636,
            105.83900857870813,
            21.026347204702905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83900005950368,
              21.026347204702905
            ],
            [
              105.83900037073461,
              21.02634712278059
            ],
            [
              105.83900857870813,
              21.02634457132636
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.1_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13533",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026347204702905,
          "to_latitude": 21.02634457132636,
          "to_longitude": 105.83900857870813,
          "from_longitude": 105.83900005950368
        }
      },
      {
        "id": 13458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83893994305207,
            21.025804645672817,
            105.83895014952924,
            21.02580660001679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895014952924,
              21.025804645672817
            ],
            [
              105.83894687838466,
              21.02580527238861
            ],
            [
              105.83894325072146,
              21.0258059677016
            ],
            [
              105.83893994305207,
              21.02580660001679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_25.8_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DODV.13534",
          "diaChiLapD": "Ngõ 35, Nguyễn Như Đổ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025804645672817,
          "to_latitude": 21.02580660001679,
          "to_longitude": 105.83893994305207,
          "from_longitude": 105.83895014952924
        }
      },
      {
        "id": 13459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83984931696254,
            21.026756420631198,
            105.83989860256348,
            21.026886921176537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83989860256348,
              21.026886921176537
            ],
            [
              105.83984931696254,
              21.026756420631198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_28.2.1_Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13535",
          "diaChiLapD": "Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 15.33,
          "from_latitude": 21.026886921176537,
          "to_latitude": 21.026756420631198,
          "to_longitude": 105.83984931696254,
          "from_longitude": 105.83989860256348
        }
      },
      {
        "id": 13460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910358328424,
            21.027993477184094,
            105.83914869695164,
            21.028014184664965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83914869695164,
              21.027993477184094
            ],
            [
              105.8391352024115,
              21.027999335915812
            ],
            [
              105.83913207254508,
              21.028000805549027
            ],
            [
              105.83912894364052,
              21.028002275177432
            ],
            [
              105.83910358328424,
              21.028014184664965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_29.5_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13536",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.96,
          "chatLieu": "HDPE",
          "chieuDai": 5.221,
          "from_latitude": 21.027993477184094,
          "to_latitude": 21.028014184664965,
          "to_longitude": 105.83910358328424,
          "from_longitude": 105.83914869695164
        }
      },
      {
        "id": 13461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83870288364736,
            21.027014338516093,
            105.83873842191639,
            21.027090459361524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83870288364736,
              21.027014338516093
            ],
            [
              105.83870612501731,
              21.027021280957303
            ],
            [
              105.83873842191639,
              21.027090459361524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_31.4_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13537",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027014338516093,
          "to_latitude": 21.027090459361524,
          "to_longitude": 105.83873842191639,
          "from_longitude": 105.83870288364736
        }
      },
      {
        "id": 13462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83842088034999,
            21.026346524140347,
            105.83844158121671,
            21.02635470010968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83844158121671,
              21.026346524140347
            ],
            [
              105.83843707646824,
              21.026348301312062
            ],
            [
              105.83842657530994,
              21.026352444684854
            ],
            [
              105.83842634205944,
              21.02635253706032
            ],
            [
              105.83842088034999,
              21.02635470010968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13538",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026346524140347,
          "to_latitude": 21.02635470010968,
          "to_longitude": 105.83842088034999,
          "from_longitude": 105.83844158121671
        }
      },
      {
        "id": 13463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825445125402,
            21.02598553562678,
            105.8382774494584,
            21.025994895282533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825445125402,
              21.025994895282533
            ],
            [
              105.83826530871758,
              21.02599047648793
            ],
            [
              105.83827413232923,
              21.025986886037998
            ],
            [
              105.8382774494584,
              21.02598553562678
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.3_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13539",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025994895282533,
          "to_latitude": 21.02598553562678,
          "to_longitude": 105.8382774494584,
          "from_longitude": 105.83825445125402
        }
      },
      {
        "id": 13464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83721960589486,
            21.02581968449635,
            105.83722748162675,
            21.025820749330435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722748162675,
              21.02581968449635
            ],
            [
              105.83722385569473,
              21.025820174732726
            ],
            [
              105.83722017593752,
              21.025820673363036
            ],
            [
              105.83721960589486,
              21.025820749330435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_60.2_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DODV.13540",
          "diaChiLapD": "Ngõ 23, Quốc Tử Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02581968449635,
          "to_latitude": 21.025820749330435,
          "to_longitude": 105.83721960589486,
          "from_longitude": 105.83722748162675
        }
      },
      {
        "id": 13465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366910971885,
            21.02604870578918,
            105.83670461424579,
            21.02605802220138
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8366910971885,
              21.02605802220138
            ],
            [
              105.83669942591999,
              21.026052281886074
            ],
            [
              105.83670402871118,
              21.026049108797878
            ],
            [
              105.83670461424579,
              21.02604870578918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_59.4_Lương Sử A",
          "maTaiSan": "04.O14.DODV.13541",
          "diaChiLapD": "Lương Sử A",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02605802220138,
          "to_latitude": 21.02604870578918,
          "to_longitude": 105.83670461424579,
          "from_longitude": 105.8366910971885
        }
      },
      {
        "id": 13466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597051804426,
            21.027393816051475,
            105.83600717125151,
            21.02741079438574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83597051804426,
              21.02741079438574
            ],
            [
              105.83598451394971,
              21.027404511406377
            ],
            [
              105.83600717125151,
              21.027393816051475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_56.4.3_Văn Miếu",
          "maTaiSan": "04.O14.DODV.13542",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 18,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02741079438574,
          "to_latitude": 21.027393816051475,
          "to_longitude": 105.83600717125151,
          "from_longitude": 105.83597051804426
        }
      },
      {
        "id": 13467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83609284900449,
            21.027700782894577,
            105.83610579360682,
            21.027705830525527
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83610579360682,
              21.027700782894577
            ],
            [
              105.83610360497575,
              21.02770163636284
            ],
            [
              105.83609665798944,
              21.02770434496334
            ],
            [
              105.83609284900449,
              21.027705830525527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.7_Văn Miếu",
          "maTaiSan": "04.O14.DODV.13543",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027700782894577,
          "to_latitude": 21.027705830525527,
          "to_longitude": 105.83609284900449,
          "from_longitude": 105.83610579360682
        }
      },
      {
        "id": 13468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83432832659543,
            21.026620505667413,
            105.83440444357208,
            21.02675584875843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83432832659543,
              21.026620505667413
            ],
            [
              105.83432957989358,
              21.026622544416476
            ],
            [
              105.8343807667105,
              21.02670574028686
            ],
            [
              105.8343904557064,
              21.026726245597455
            ],
            [
              105.83440444357208,
              21.02675584875843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.6_Ngõ 15, Hồ Giám",
          "maTaiSan": "04.O14.DODV.13544",
          "diaChiLapD": "Ngõ 15, Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026620505667413,
          "to_latitude": 21.02675584875843,
          "to_longitude": 105.83440444357208,
          "from_longitude": 105.83432832659543
        }
      },
      {
        "id": 13469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440444357208,
            21.02675584875843,
            105.83451875618803,
            21.027068300808413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440444357208,
              21.02675584875843
            ],
            [
              105.83443321367932,
              21.02681673659322
            ],
            [
              105.83446561060238,
              21.026896983477535
            ],
            [
              105.8344841052548,
              21.026968188663812
            ],
            [
              105.83451875618803,
              21.027068300808413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.6_Ngõ 15, Hồ Giám",
          "maTaiSan": "04.O14.DODV.13545",
          "diaChiLapD": "Ngõ 15, Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 52,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02675584875843,
          "to_latitude": 21.027068300808413,
          "to_longitude": 105.83451875618803,
          "from_longitude": 105.83440444357208
        }
      },
      {
        "id": 13470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83439741068125,
            21.026479531439854,
            105.83441908459109,
            21.026486398985607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83439741068125,
              21.026486115704454
            ],
            [
              105.83440189616952,
              21.026486398985607
            ],
            [
              105.83441908459109,
              21.026479531439854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_66.8_Hồ Giám",
          "maTaiSan": "04.O14.DODV.13546",
          "diaChiLapD": "Hồ Giám",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026486115704454,
          "to_latitude": 21.026479531439854,
          "to_longitude": 105.83441908459109,
          "from_longitude": 105.83439741068125
        }
      },
      {
        "id": 13471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452508776821,
            21.024487310841828,
            105.83472746526427,
            21.024578127075227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452515649381,
              21.024487310841828
            ],
            [
              105.83452508776821,
              21.024488098790112
            ],
            [
              105.83452510552942,
              21.024499930933075
            ],
            [
              105.83457306655654,
              21.02450146409653
            ],
            [
              105.8346350801581,
              21.024514361287828
            ],
            [
              105.8346975492096,
              21.024506598592943
            ],
            [
              105.83472746526427,
              21.024578127075227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.6_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.13547",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024487310841828,
          "to_latitude": 21.024578127075227,
          "to_longitude": 105.83472746526427,
          "from_longitude": 105.83452515649381
        }
      },
      {
        "id": 13472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435597501611,
            21.024316775470773,
            105.8343601125143,
            21.024320065438882
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435597501611,
              21.024320065438882
            ],
            [
              105.83435639546957,
              21.02431973098983
            ],
            [
              105.83435986636972,
              21.02431697087144
            ],
            [
              105.8343601125143,
              21.024316775470773
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_68.9_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DODV.13548",
          "diaChiLapD": "Ngõ 53, Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.56,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024320065438882,
          "to_latitude": 21.024316775470773,
          "to_longitude": 105.8343601125143,
          "from_longitude": 105.83435597501611
        }
      },
      {
        "id": 13473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8354274014209,
            21.02449377933914,
            105.83547488387804,
            21.02460390208285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543313152268,
              21.02449377933914
            ],
            [
              105.83543152522994,
              21.024494857549556
            ],
            [
              105.8354274014209,
              21.024498011089136
            ],
            [
              105.83546026696995,
              21.024567583013432
            ],
            [
              105.83547488387804,
              21.02460390208285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_64.3_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13549",
          "diaChiLapD": "Ngõ Thông Phong, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02449377933914,
          "to_latitude": 21.02460390208285,
          "to_longitude": 105.83547488387804,
          "from_longitude": 105.83543313152268
        }
      },
      {
        "id": 13474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8360630178216,
            21.024054920240133,
            105.83606685093258,
            21.02406381530919
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83606685093258,
              21.02406381530919
            ],
            [
              105.83606630978977,
              21.02406251732762
            ],
            [
              105.8360630178216,
              21.024054920240133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.3_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13550",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.06,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02406381530919,
          "to_latitude": 21.024054920240133,
          "to_longitude": 105.8360630178216,
          "from_longitude": 105.83606685093258
        }
      },
      {
        "id": 13475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701226701443,
            21.02415134820846,
            105.8371040877026,
            21.024158661150583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371040877026,
              21.024158661150583
            ],
            [
              105.83710399492422,
              21.02415134820846
            ],
            [
              105.83708956641559,
              21.024153658315964
            ],
            [
              105.83701226701443,
              21.024155110888337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_20.6.1_Linh Quang",
          "maTaiSan": "04.O14.DODV.13551",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024158661150583,
          "to_latitude": 21.024155110888337,
          "to_longitude": 105.83701226701443,
          "from_longitude": 105.8371040877026
        }
      },
      {
        "id": 13476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372454441007,
            21.02451017090334,
            105.83726141046174,
            21.02452323462101
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725150477046,
              21.024518459752645
            ],
            [
              105.83725949000537,
              21.02452323462101
            ],
            [
              105.83726141046174,
              21.024519329564914
            ],
            [
              105.8372538750786,
              21.024515006803252
            ],
            [
              105.8372454441007,
              21.02451017090334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.19_Linh Quang",
          "maTaiSan": "04.O14.DODV.13552",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024518459752645,
          "to_latitude": 21.02451017090334,
          "to_longitude": 105.8372454441007,
          "from_longitude": 105.83725150477046
        }
      },
      {
        "id": 13477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83714935141651,
            21.024623333675496,
            105.83716279331496,
            21.024632061243032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8371592607955,
              21.024627446949896
            ],
            [
              105.83716279331496,
              21.024630561939276
            ],
            [
              105.83716151838708,
              21.024632061243032
            ],
            [
              105.83715757283817,
              21.024629230995597
            ],
            [
              105.83714935141651,
              21.024623333675496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.13553",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024627446949896,
          "to_latitude": 21.024623333675496,
          "to_longitude": 105.83714935141651,
          "from_longitude": 105.8371592607955
        }
      },
      {
        "id": 13478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750450776364,
            21.025407176028548,
            105.83750641953203,
            21.02541531910518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750641953203,
              21.02541531910518
            ],
            [
              105.83750490690373,
              21.025408882057764
            ],
            [
              105.83750450776364,
              21.025407176028548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.9_Lương Sử C",
          "maTaiSan": "04.O14.DODV.13554",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02541531910518,
          "to_latitude": 21.025407176028548,
          "to_longitude": 105.83750450776364,
          "from_longitude": 105.83750641953203
        }
      },
      {
        "id": 13479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750932805403,
            21.02540870577775,
            105.8375131049199,
            21.025439305043314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750932805403,
              21.025415390587906
            ],
            [
              105.8375097062932,
              21.02540870577775
            ],
            [
              105.8375131049199,
              21.02540902955573
            ],
            [
              105.83751269935533,
              21.02541547243668
            ],
            [
              105.83751119902108,
              21.025439305043314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_15.8_Lương Sử C",
          "maTaiSan": "04.O14.DODV.13555",
          "diaChiLapD": "Lương Sử C",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.45,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025415390587906,
          "to_latitude": 21.025439305043314,
          "to_longitude": 105.83751119902108,
          "from_longitude": 105.83750932805403
        }
      },
      {
        "id": 13480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83813019443409,
            21.026089927228856,
            105.83814059950515,
            21.026112288115254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83814059950515,
              21.026089927228856
            ],
            [
              105.83813848729194,
              21.0260934304018
            ],
            [
              105.83813019443409,
              21.026112288115254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13556",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026089927228856,
          "to_latitude": 21.026112288115254,
          "to_longitude": 105.83813019443409,
          "from_longitude": 105.83814059950515
        }
      },
      {
        "id": 13481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783436056041,
            21.026728676842577,
            105.83784514910566,
            21.02673935701714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783657026684,
              21.026735459425723
            ],
            [
              105.83783506283176,
              21.02673219447613
            ],
            [
              105.83783436056041,
              21.026730988520672
            ],
            [
              105.83783791091531,
              21.026728676842577
            ],
            [
              105.83784096255697,
              21.0267331797284
            ],
            [
              105.83784514910566,
              21.02673935701714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_54.4_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13557",
          "diaChiLapD": "Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026735459425723,
          "to_latitude": 21.02673935701714,
          "to_longitude": 105.83784514910566,
          "from_longitude": 105.83783657026684
        }
      },
      {
        "id": 13482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8383703089942,
            21.027035985008247,
            105.83843783212362,
            21.02710330149922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837210441065,
              21.027035985008247
            ],
            [
              105.8383716124194,
              21.02703660072203
            ],
            [
              105.8383703089942,
              21.027038510238306
            ],
            [
              105.83839689614402,
              21.027059169541815
            ],
            [
              105.83841633091423,
              21.02707538411974
            ],
            [
              105.83843783212362,
              21.02710330149922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55.2_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13558",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027035985008247,
          "to_latitude": 21.02710330149922,
          "to_longitude": 105.83843783212362,
          "from_longitude": 105.83837210441065
        }
      },
      {
        "id": 13483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83818100475109,
            21.027038523298256,
            105.83818747227018,
            21.02704708621489
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.838183158893,
              21.027045586448118
            ],
            [
              105.83818100475109,
              21.02703918418399
            ],
            [
              105.83818351478581,
              21.027038523298256
            ],
            [
              105.83818625114179,
              21.027044444887803
            ],
            [
              105.83818747227018,
              21.02704708621489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_50.11_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13559",
          "diaChiLapD": "Ngõ 56, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.04,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027045586448118,
          "to_latitude": 21.02704708621489,
          "to_longitude": 105.83818747227018,
          "from_longitude": 105.838183158893
        }
      },
      {
        "id": 13484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83930086864011,
            21.023838600497093,
            105.83930144039908,
            21.023855037459654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83930144039908,
              21.023838600497093
            ],
            [
              105.83930119183924,
              21.02384575253956
            ],
            [
              105.83930115963793,
              21.023846530373405
            ],
            [
              105.8393008776088,
              21.023854749287295
            ],
            [
              105.83930086864011,
              21.023855037459654
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.17_Linh Quang",
          "maTaiSan": "04.O14.DODV.13560",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.82,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023838600497093,
          "to_latitude": 21.023855037459654,
          "to_longitude": 105.83930086864011,
          "from_longitude": 105.83930144039908
        }
      },
      {
        "id": 13485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83929238696113,
            21.02385462075088,
            105.83929288084053,
            21.02386881643305
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83929238696113,
              21.02386881643305
            ],
            [
              105.83929262230411,
              21.02386205284168
            ],
            [
              105.83929286583074,
              21.0238550326946
            ],
            [
              105.83929288084053,
              21.02385462075088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_17.16_Linh Quang",
          "maTaiSan": "04.O14.DODV.13561",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.57,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02386881643305,
          "to_latitude": 21.02385462075088,
          "to_longitude": 105.83929288084053,
          "from_longitude": 105.83929238696113
        }
      },
      {
        "id": 13486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83717315392627,
            21.02346128465763,
            105.83717832275758,
            21.023463510352318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717315392627,
              21.023463510352318
            ],
            [
              105.8371735245872,
              21.02346339110878
            ],
            [
              105.83717832275758,
              21.02346128465763
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.9_Linh Quang",
          "maTaiSan": "04.O14.DODV.13562",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023463510352318,
          "to_latitude": 21.02346128465763,
          "to_longitude": 105.83717832275758,
          "from_longitude": 105.83717315392627
        }
      },
      {
        "id": 13487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8367094140785,
            21.02280598252279,
            105.83671578484305,
            21.022807785988082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367094140785,
              21.022807785988082
            ],
            [
              105.83671308001247,
              21.022806719311177
            ],
            [
              105.83671578484305,
              21.02280598252279
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.13563",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022807785988082,
          "to_latitude": 21.02280598252279,
          "to_longitude": 105.83671578484305,
          "from_longitude": 105.8367094140785
        }
      },
      {
        "id": 13488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83673727001151,
            21.022518395564486,
            105.83674528276093,
            21.022518493693468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83673727001151,
              21.022518493693468
            ],
            [
              105.83674078274505,
              21.02251841860599
            ],
            [
              105.83674528276093,
              21.022518395564486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16.18_Linh Quang",
          "maTaiSan": "04.O14.DODV.13564",
          "diaChiLapD": "Linh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022518493693468,
          "to_latitude": 21.022518395564486,
          "to_longitude": 105.83674528276093,
          "from_longitude": 105.83673727001151
        }
      },
      {
        "id": 13489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83716783477595,
            21.021215495579987,
            105.8371700023579,
            21.021244489226312
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83716783477595,
              21.021215495579987
            ],
            [
              105.8371685859193,
              21.021228045787197
            ],
            [
              105.8371700023579,
              21.021244489226312
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_9.18_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13565",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021215495579987,
          "to_latitude": 21.021244489226312,
          "to_longitude": 105.8371700023579,
          "from_longitude": 105.83716783477595
        }
      },
      {
        "id": 13490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695463476427,
            21.021216239790018,
            105.83695661353717,
            21.02128269778461
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369560434754,
              21.021216239790018
            ],
            [
              105.83695661353717,
              21.021229441210686
            ],
            [
              105.83695602569244,
              21.021245283032187
            ],
            [
              105.83695463476427,
              21.02128269778461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.4_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13566",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021216239790018,
          "to_latitude": 21.02128269778461,
          "to_longitude": 105.83695463476427,
          "from_longitude": 105.8369560434754
        }
      },
      {
        "id": 13491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83695941032897,
            21.021216226764164,
            105.83696099136775,
            21.021281471541318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695960847109,
              21.021216226764164
            ],
            [
              105.83695941032897,
              21.02122898486918
            ],
            [
              105.8369599013973,
              21.021245268477404
            ],
            [
              105.83696099136775,
              21.021281471541318
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.3_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13567",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021216226764164,
          "to_latitude": 21.021281471541318,
          "to_longitude": 105.83696099136775,
          "from_longitude": 105.83695960847109
        }
      },
      {
        "id": 13492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837351054137,
            21.021214852024464,
            105.83735172087364,
            21.021278834054336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837351054137,
              21.021214852024464
            ],
            [
              105.83735172087364,
              21.021227099162854
            ],
            [
              105.83735154005629,
              21.021243815107567
            ],
            [
              105.83735115938697,
              21.021278834054336
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.1_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13568",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.08,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021214852024464,
          "to_latitude": 21.021278834054336,
          "to_longitude": 105.83735115938697,
          "from_longitude": 105.837351054137
        }
      },
      {
        "id": 13493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83735367453102,
            21.02121483829159,
            105.83735675086989,
            21.021277373111957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83735512801152,
              21.02121483829159
            ],
            [
              105.83735367453102,
              21.02122725212535
            ],
            [
              105.83735469141058,
              21.021243803206506
            ],
            [
              105.83735675086989,
              21.021277373111957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_D1.2_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13569",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02121483829159,
          "to_latitude": 21.021277373111957,
          "to_longitude": 105.83735675086989,
          "from_longitude": 105.83735512801152
        }
      },
      {
        "id": 13494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728068774116,
            21.020943148387698,
            105.8372807793339,
            21.021049254197393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728076458054,
              21.021049254197393
            ],
            [
              105.8372807793339,
              21.021031394685384
            ],
            [
              105.83728070330513,
              21.020958168015778
            ],
            [
              105.83728068774116,
              21.020943148387698
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1.1_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13570",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021049254197393,
          "to_latitude": 21.020943148387698,
          "to_longitude": 105.83728068774116,
          "from_longitude": 105.83728076458054
        }
      },
      {
        "id": 13495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83728275106766,
            21.02094311581458,
            105.83728730495247,
            21.021049261985947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83728730495247,
              21.02094311581458
            ],
            [
              105.83728652805186,
              21.020958170956373
            ],
            [
              105.83728275106766,
              21.021031339818364
            ],
            [
              105.83728358608474,
              21.021049261985947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_E1.2_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13571",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 11.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02094311581458,
          "to_latitude": 21.021049261985947,
          "to_longitude": 105.83728358608474,
          "from_longitude": 105.83728730495247
        }
      },
      {
        "id": 13496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661495903549,
            21.02079107812388,
            105.83663949319092,
            21.020792441633233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661495903549,
              21.020792441633233
            ],
            [
              105.83661747463682,
              21.020792278421823
            ],
            [
              105.83663949319092,
              21.02079107812388
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.11_Văn Chương",
          "maTaiSan": "04.O14.DODV.13572",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.55,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020792441633233,
          "to_latitude": 21.02079107812388,
          "to_longitude": 105.83663949319092,
          "from_longitude": 105.83661495903549
        }
      },
      {
        "id": 13497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83677996653213,
            21.0211930952115,
            105.8367804352262,
            21.021236153284278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83677996653213,
              21.021236153284278
            ],
            [
              105.83677998493216,
              21.02123427900474
            ],
            [
              105.8367799886172,
              21.021233905052036
            ],
            [
              105.83678004108553,
              21.02122899938641
            ],
            [
              105.83678010012473,
              21.021223719753877
            ],
            [
              105.8367804352262,
              21.0211930952115
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.13573",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.76,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021236153284278,
          "to_latitude": 21.0211930952115,
          "to_longitude": 105.8367804352262,
          "from_longitude": 105.83677996653213
        }
      },
      {
        "id": 13498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83669309350178,
            21.021542819513044,
            105.83670818572666,
            21.02155847400767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83670818572666,
              21.02155847400767
            ],
            [
              105.83670674017014,
              21.021556974541685
            ],
            [
              105.83669890282282,
              21.02154884614722
            ],
            [
              105.83669627585903,
              21.021546121398906
            ],
            [
              105.83669511090544,
              21.021544914100172
            ],
            [
              105.83669309350178,
              21.021542819513044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13574",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02155847400767,
          "to_latitude": 21.021542819513044,
          "to_longitude": 105.83669309350178,
          "from_longitude": 105.83670818572666
        }
      },
      {
        "id": 13499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661016006613,
            21.021523042211157,
            105.83669580059964,
            21.021551383992634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83669181278017,
              21.021543794068766
            ],
            [
              105.83669380946013,
              21.02154613985402
            ],
            [
              105.83669580059964,
              21.02154835469907
            ],
            [
              105.83669215238093,
              21.021551383992634
            ],
            [
              105.83668708060634,
              21.0215478917885
            ],
            [
              105.83666578438938,
              21.021533231548784
            ],
            [
              105.83661016006613,
              21.021523042211157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_10.7.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13575",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 39,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021543794068766,
          "to_latitude": 21.021523042211157,
          "to_longitude": 105.83661016006613,
          "from_longitude": 105.83669181278017
        }
      },
      {
        "id": 13500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83599157169375,
            21.022216991620326,
            105.83601695835422,
            21.02222520198505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83599696842228,
              21.02222520198505
            ],
            [
              105.83599157169375,
              21.022224851866486
            ],
            [
              105.83599257065464,
              21.022216991620326
            ],
            [
              105.835998288836,
              21.022217541577742
            ],
            [
              105.83601695835422,
              21.022219338453585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13576",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02222520198505,
          "to_latitude": 21.022219338453585,
          "to_longitude": 105.83601695835422,
          "from_longitude": 105.83599696842228
        }
      },
      {
        "id": 13501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83603797286668,
            21.02201128146077,
            105.83612185267732,
            21.02204547525167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612185267732,
              21.02204547525167
            ],
            [
              105.83611890662387,
              21.022043319287594
            ],
            [
              105.83603797286668,
              21.02201128146077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_10.5.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13577",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02204547525167,
          "to_latitude": 21.02201128146077,
          "to_longitude": 105.83603797286668,
          "from_longitude": 105.83612185267732
        }
      },
      {
        "id": 13502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346178778915,
            21.022396710236315,
            105.83462059117781,
            21.022414774044584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83462059117781,
              21.022414774044584
            ],
            [
              105.83461931844568,
              21.022406299921062
            ],
            [
              105.83461851927585,
              21.02240098475727
            ],
            [
              105.8346178778915,
              21.022396710236315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_79.7_Văn Chương",
          "maTaiSan": "04.O14.DODV.13578",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.01,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022414774044584,
          "to_latitude": 21.022396710236315,
          "to_longitude": 105.8346178778915,
          "from_longitude": 105.83462059117781
        }
      },
      {
        "id": 13503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83490073598739,
            21.021895928621646,
            105.83490332388003,
            21.021904080159185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83490332388003,
              21.021904080159185
            ],
            [
              105.83490254351644,
              21.021901625414337
            ],
            [
              105.83490145664099,
              21.021898198498697
            ],
            [
              105.83490073598739,
              21.021895928621646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_110.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13579",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 0.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021904080159185,
          "to_latitude": 21.021895928621646,
          "to_longitude": 105.83490073598739,
          "from_longitude": 105.83490332388003
        }
      },
      {
        "id": 13504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83462294803698,
            21.021603382495112,
            105.83471229734242,
            21.021625804460008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83462461692041,
              21.021625804460008
            ],
            [
              105.83462362973269,
              21.02162196608619
            ],
            [
              105.83462294803698,
              21.021619608403828
            ],
            [
              105.83467193964911,
              21.02161427742644
            ],
            [
              105.83471229734242,
              21.021603382495112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.6_Văn Chương",
          "maTaiSan": "04.O14.DODV.13580",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021625804460008,
          "to_latitude": 21.021603382495112,
          "to_longitude": 105.83471229734242,
          "from_longitude": 105.83462461692041
        }
      },
      {
        "id": 13505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8346165454978,
            21.021620624220557,
            105.83462183862602,
            21.021635773539693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83462183862602,
              21.02162630312427
            ],
            [
              105.83462104726854,
              21.02162290907892
            ],
            [
              105.83462043427838,
              21.021620624220557
            ],
            [
              105.8346165454978,
              21.02162157542968
            ],
            [
              105.83461806183708,
              21.021626981010275
            ],
            [
              105.83462052725838,
              21.021635773539693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13581",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.68,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02162630312427,
          "to_latitude": 21.021635773539693,
          "to_longitude": 105.83462052725838,
          "from_longitude": 105.83462183862602
        }
      },
      {
        "id": 13506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435515882475,
            21.02152227423085,
            105.83436558794858,
            21.02152601325355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435515882475,
              21.02152601325355
            ],
            [
              105.83436182739521,
              21.021523622223693
            ],
            [
              105.83436514247907,
              21.021522433577047
            ],
            [
              105.83436558794858,
              21.02152227423085
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_110.3_Văn Chương",
          "maTaiSan": "04.O14.DODV.13582",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.16,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02152601325355,
          "to_latitude": 21.02152227423085,
          "to_longitude": 105.83436558794858,
          "from_longitude": 105.83435515882475
        }
      },
      {
        "id": 13507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83501670241472,
            21.02139890069786,
            105.83502991478699,
            21.02139982452701
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502991478699,
              21.02139890069786
            ],
            [
              105.83502287587807,
              21.021399392285247
            ],
            [
              105.83502088632841,
              21.021399531213515
            ],
            [
              105.83501912083699,
              21.02139965549342
            ],
            [
              105.83501670241472,
              21.02139982452701
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13583",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.37,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02139890069786,
          "to_latitude": 21.02139982452701,
          "to_longitude": 105.83501670241472,
          "from_longitude": 105.83502991478699
        }
      },
      {
        "id": 13508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502296676423,
            21.021868383086208,
            105.83504404795363,
            21.02187039542617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502296676423,
              21.021870377933535
            ],
            [
              105.83502529099708,
              21.02187039542617
            ],
            [
              105.83504404795363,
              21.021868383086208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_8.2_Văn Chương",
          "maTaiSan": "04.O14.DODV.13584",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021870377933535,
          "to_latitude": 21.021868383086208,
          "to_longitude": 105.83504404795363,
          "from_longitude": 105.83502296676423
        }
      },
      {
        "id": 13509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612134553505,
            21.020955084567564,
            105.83614230939536,
            21.020955229739553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612134553505,
              21.020955122340713
            ],
            [
              105.83612333371754,
              21.020955084567564
            ],
            [
              105.83614230939536,
              21.020955229739553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_11.8_Văn Chương",
          "maTaiSan": "04.O14.DODV.13585",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020955122340713,
          "to_latitude": 21.020955229739553,
          "to_longitude": 105.83614230939536,
          "from_longitude": 105.83612134553505
        }
      },
      {
        "id": 13510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83586894055505,
            21.020828059041825,
            105.8358772328297,
            21.02084378045109
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586894055505,
              21.020828059041825
            ],
            [
              105.83586969265117,
              21.020829410181648
            ],
            [
              105.8358772328297,
              21.02084378045109
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13586",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020828059041825,
          "to_latitude": 21.02084378045109,
          "to_longitude": 105.8358772328297,
          "from_longitude": 105.83586894055505
        }
      },
      {
        "id": 13511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350572327564,
            21.023522969997153,
            105.83506866030719,
            21.023525196551965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350572327564,
              21.023525196551965
            ],
            [
              105.83505943961269,
              21.02352486145924
            ],
            [
              105.83506866030719,
              21.023522969997153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_73.2_Văn Hương",
          "maTaiSan": "04.O14.DODV.13587",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023525196551965,
          "to_latitude": 21.023522969997153,
          "to_longitude": 105.83506866030719,
          "from_longitude": 105.8350572327564
        }
      },
      {
        "id": 13512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410940270856,
            21.024300385495735,
            105.83413231779696,
            21.024325740459428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83413111479682,
              21.024302901636418
            ],
            [
              105.83412983603543,
              21.024301815007448
            ],
            [
              105.83412830332435,
              21.024300385495735
            ],
            [
              105.83412617620723,
              21.02430226018216
            ],
            [
              105.83412928771114,
              21.0243052752373
            ],
            [
              105.83413231779696,
              21.02430818049842
            ],
            [
              105.83410940270856,
              21.024325740459428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.14_Văn Hương",
          "maTaiSan": "04.O14.DODV.13588",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024302901636418,
          "to_latitude": 21.024325740459428,
          "to_longitude": 105.83410940270856,
          "from_longitude": 105.83413111479682
        }
      },
      {
        "id": 13513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388954463518,
            21.024461085528714,
            105.83389526207468,
            21.024472037986474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83389526207468,
              21.024472037986474
            ],
            [
              105.83389492760125,
              21.024471400144023
            ],
            [
              105.8338931569298,
              21.02446800547881
            ],
            [
              105.83388954463518,
              21.024461085528714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.10_Văn Hương",
          "maTaiSan": "04.O14.DODV.13589",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024472037986474,
          "to_latitude": 21.024461085528714,
          "to_longitude": 105.83388954463518,
          "from_longitude": 105.83389526207468
        }
      },
      {
        "id": 13514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363733181385,
            21.02449531164701,
            105.83364298074032,
            21.024497023848962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364298074032,
              21.02449531164701
            ],
            [
              105.8336418081216,
              21.024495666942492
            ],
            [
              105.83363997476908,
              21.02449622328275
            ],
            [
              105.83363733181385,
              21.024497023848962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.2_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13590",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02449531164701,
          "to_latitude": 21.024497023848962,
          "to_longitude": 105.83363733181385,
          "from_longitude": 105.83364298074032
        }
      },
      {
        "id": 13515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83363875832717,
            21.024496780978073,
            105.83365176877483,
            21.024500252394823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336433929477,
              21.024496780978073
            ],
            [
              105.83364011722608,
              21.024497795097155
            ],
            [
              105.83363875832717,
              21.024498212724946
            ],
            [
              105.83363945653196,
              21.024500252394823
            ],
            [
              105.83364408587248,
              21.024499255124482
            ],
            [
              105.83365176877483,
              21.024497600693962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_74.1_Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTaiSan": "04.O14.DODV.13591",
          "diaChiLapD": "Ngách 28, Ngõ Văn Hương, Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.05,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024496780978073,
          "to_latitude": 21.024497600693962,
          "to_longitude": 105.83365176877483,
          "from_longitude": 105.8336433929477
        }
      },
      {
        "id": 13516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83338418957058,
            21.02461378140449,
            105.83338960344618,
            21.024634507346363
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83338418957058,
              21.02461378140449
            ],
            [
              105.83338835514193,
              21.024629727280193
            ],
            [
              105.8333894865018,
              21.024634063533288
            ],
            [
              105.83338960344618,
              21.024634507346363
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70.7_Văn Hương",
          "maTaiSan": "04.O14.DODV.13592",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.36,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02461378140449,
          "to_latitude": 21.024634507346363,
          "to_longitude": 105.83338960344618,
          "from_longitude": 105.83338418957058
        }
      },
      {
        "id": 13517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353010686054,
            21.022862991380254,
            105.83353537806461,
            21.022873809654666
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353010686054,
              21.022862991380254
            ],
            [
              105.83353056325029,
              21.02286393030301
            ],
            [
              105.8335326796982,
              21.02286827166404
            ],
            [
              105.83353537806461,
              21.022873809654666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_78.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13593",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.31,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022862991380254,
          "to_latitude": 21.022873809654666,
          "to_longitude": 105.83353537806461,
          "from_longitude": 105.83353010686054
        }
      },
      {
        "id": 13518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315160028187,
            21.023055707115883,
            105.83315626029008,
            21.023063179241593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83315160028187,
              21.023055707115883
            ],
            [
              105.83315175849631,
              21.02305596105005
            ],
            [
              105.83315322804555,
              21.02305831939513
            ],
            [
              105.83315597977972,
              21.023062729906897
            ],
            [
              105.83315626029008,
              21.023063179241593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_77.10_Văn Chương",
          "maTaiSan": "04.O14.DODV.13594",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023055707115883,
          "to_latitude": 21.023063179241593,
          "to_longitude": 105.83315626029008,
          "from_longitude": 105.83315160028187
        }
      },
      {
        "id": 13519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83108940172946,
            21.020188694766993,
            105.83109240333604,
            21.020193430160102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83108940172946,
              21.020188694766993
            ],
            [
              105.83109067010817,
              21.02019069553509
            ],
            [
              105.83109187639212,
              21.020192600864746
            ],
            [
              105.83109240333604,
              21.020193430160102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_98.1_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13595",
          "diaChiLapD": "Ngõ 227, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.61,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020188694766993,
          "to_latitude": 21.020193430160102,
          "to_longitude": 105.83109240333604,
          "from_longitude": 105.83108940172946
        }
      },
      {
        "id": 13520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83236785967325,
            21.021139228267938,
            105.83237916443807,
            21.021146273193295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83236785967325,
              21.021139228267938
            ],
            [
              105.83236842245321,
              21.021139579577856
            ],
            [
              105.832374269352,
              21.021143224378857
            ],
            [
              105.83237617872985,
              21.02114441271055
            ],
            [
              105.83237916443807,
              21.021146273193295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_92.1_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13596",
          "diaChiLapD": "Ngách 221/56, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.41,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021139228267938,
          "to_latitude": 21.021146273193295,
          "to_longitude": 105.83237916443807,
          "from_longitude": 105.83236785967325
        }
      },
      {
        "id": 13521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83279286608283,
            21.021118537622623,
            105.83279734718144,
            21.021125597486535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83279286608283,
              21.021125597486535
            ],
            [
              105.83279566593285,
              21.021121185978913
            ],
            [
              105.83279723750066,
              21.021118707965808
            ],
            [
              105.83279734718144,
              21.021118537622623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_93.4_Ngách 221/73, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13597",
          "diaChiLapD": "Ngách 221/73, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021125597486535,
          "to_latitude": 21.021118537622623,
          "to_longitude": 105.83279734718144,
          "from_longitude": 105.83279286608283
        }
      },
      {
        "id": 13522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221549901631,
            21.021539862847234,
            105.83222580064191,
            21.021553352679764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221549901631,
              21.021539862847234
            ],
            [
              105.83221605621229,
              21.02154059444241
            ],
            [
              105.8322188324374,
              21.021544228078906
            ],
            [
              105.83222580064191,
              21.021553352679764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_89.1_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13598",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021539862847234,
          "to_latitude": 21.021553352679764,
          "to_longitude": 105.83222580064191,
          "from_longitude": 105.83221549901631
        }
      },
      {
        "id": 13523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83204849027021,
            21.021672426917107,
            105.8320591722633,
            21.021680450399423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83205180489517,
              21.021677749669802
            ],
            [
              105.83204849027021,
              21.02167417105137
            ],
            [
              105.83205070756627,
              21.021672426917107
            ],
            [
              105.83205412129648,
              21.02167566272864
            ],
            [
              105.8320591722633,
              21.021680450399423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_88.4_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13599",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.07,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021677749669802,
          "to_latitude": 21.021680450399423,
          "to_longitude": 105.8320591722633,
          "from_longitude": 105.83205180489517
        }
      },
      {
        "id": 13524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83139928996465,
            21.021480326273274,
            105.83142771824787,
            21.02149194973244
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83139928996465,
              21.02149194973244
            ],
            [
              105.831413756055,
              21.02148603515914
            ],
            [
              105.83142771824787,
              21.021480326273274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_90.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13600",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02149194973244,
          "to_latitude": 21.021480326273274,
          "to_longitude": 105.83142771824787,
          "from_longitude": 105.83139928996465
        }
      },
      {
        "id": 13525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323824521519,
            21.021778789926394,
            105.8323916210117,
            21.021783768208664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8323824521519,
              21.021783768208664
            ],
            [
              105.83238757942631,
              21.021780984669537
            ],
            [
              105.83239131506208,
              21.0217789558105
            ],
            [
              105.8323916210117,
              21.021778789926394
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87.8_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13601",
          "diaChiLapD": "Ngõ 203, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021783768208664,
          "to_latitude": 21.021778789926394,
          "to_longitude": 105.8323916210117,
          "from_longitude": 105.8323824521519
        }
      },
      {
        "id": 13526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83184225236646,
            21.022454798345013,
            105.8318615600109,
            21.022464731598205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184225236646,
              21.022464731598205
            ],
            [
              105.83185561406518,
              21.02245793000149
            ],
            [
              105.8318615600109,
              21.022454798345013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13602",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.28,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022464731598205,
          "to_latitude": 21.022454798345013,
          "to_longitude": 105.8318615600109,
          "from_longitude": 105.83184225236646
        }
      },
      {
        "id": 13527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8321692549496,
            21.022337550391548,
            105.832172932232,
            21.022343897421404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.832172932232,
              21.022343897421404
            ],
            [
              105.83217090807557,
              21.022340403717912
            ],
            [
              105.83216947738755,
              21.022337936786457
            ],
            [
              105.8321692549496,
              21.022337550391548
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.4_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13603",
          "diaChiLapD": "Ngõ 163, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022343897421404,
          "to_latitude": 21.022337550391548,
          "to_longitude": 105.8321692549496,
          "from_longitude": 105.832172932232
        }
      },
      {
        "id": 13528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83286911357368,
            21.022437800455503,
            105.83291810507994,
            21.02248513076718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83286911357368,
              21.022437800455503
            ],
            [
              105.83287389859332,
              21.02244146037227
            ],
            [
              105.83290134893863,
              21.022462455976274
            ],
            [
              105.83291810507994,
              21.022480877370697
            ],
            [
              105.8329158022919,
              21.02248299045029
            ],
            [
              105.83291347079579,
              21.02248513076718
            ],
            [
              105.83291208810847,
              21.022483514444488
            ],
            [
              105.83291420854155,
              21.02248147722554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_85.9_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13604",
          "diaChiLapD": "Ngõ Huy Văn, Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8.22,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022437800455503,
          "to_latitude": 21.02248147722554,
          "to_longitude": 105.83291420854155,
          "from_longitude": 105.83286911357368
        }
      },
      {
        "id": 13529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328594448469,
            21.022435173382256,
            105.83286331266793,
            21.022440250377407
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83286331266793,
              21.022435173382256
            ],
            [
              105.83286030720437,
              21.02243894099574
            ],
            [
              105.8328594448469,
              21.022440250377407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13605",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.69,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022435173382256,
          "to_latitude": 21.022440250377407,
          "to_longitude": 105.8328594448469,
          "from_longitude": 105.83286331266793
        }
      },
      {
        "id": 13530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315405731646,
            21.022765026663595,
            105.83317324774012,
            21.022778041290405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83317324774012,
              21.022765026663595
            ],
            [
              105.83317310890386,
              21.02276514385961
            ],
            [
              105.83315405731646,
              21.022778041290405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_81.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13606",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.46,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022765026663595,
          "to_latitude": 21.022778041290405,
          "to_longitude": 105.83315405731646,
          "from_longitude": 105.83317324774012
        }
      },
      {
        "id": 13531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8321053429676,
            21.023027752709673,
            105.83211721930957,
            21.023032490419023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321053429676,
              21.023032490419023
            ],
            [
              105.83210621932092,
              21.023032141099872
            ],
            [
              105.83210987062594,
              21.023030684399853
            ],
            [
              105.83211352289287,
              21.02302922769504
            ],
            [
              105.83211721930957,
              21.023027752709673
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13607",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023032490419023,
          "to_latitude": 21.023027752709673,
          "to_longitude": 105.83211721930957,
          "from_longitude": 105.8321053429676
        }
      },
      {
        "id": 13532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83005011908946,
            21.017190533508455,
            105.83014000932742,
            21.017365513056813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005011908946,
              21.017360768903668
            ],
            [
              105.8300541066496,
              21.017365513056813
            ],
            [
              105.83005682495535,
              21.017364093471755
            ],
            [
              105.83006383217877,
              21.01735460432002
            ],
            [
              105.8300696008748,
              21.017335986895045
            ],
            [
              105.83010279322552,
              21.017307193598775
            ],
            [
              105.83013028641714,
              21.017282469136152
            ],
            [
              105.83014000932742,
              21.017251797841194
            ],
            [
              105.83007873763579,
              21.017209311948818
            ],
            [
              105.83007974283197,
              21.017190533508455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_96_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.13608",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017360768903668,
          "to_latitude": 21.017190533508455,
          "to_longitude": 105.83007974283197,
          "from_longitude": 105.83005011908946
        }
      },
      {
        "id": 13533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82977463954165,
            21.01714440582451,
            105.83007974283197,
            21.01727877403554
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83007974283197,
              21.017190533508455
            ],
            [
              105.83005352412727,
              21.017182041057147
            ],
            [
              105.83002929184973,
              21.017174592070308
            ],
            [
              105.82996273985724,
              21.01714440582451
            ],
            [
              105.8299350113564,
              21.017192665844718
            ],
            [
              105.82990495003875,
              21.017176763089225
            ],
            [
              105.82987646640517,
              21.017209380213696
            ],
            [
              105.82985035883681,
              21.017192112355602
            ],
            [
              105.82977463954165,
              21.01727877403554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_96_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.13609",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017190533508455,
          "to_latitude": 21.01727877403554,
          "to_longitude": 105.82977463954165,
          "from_longitude": 105.83007974283197
        }
      },
      {
        "id": 13534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766127757915,
            21.005287960784653,
            105.82772337915094,
            21.005313029273317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766127757915,
              21.005313029273317
            ],
            [
              105.82767529340354,
              21.005307519172607
            ],
            [
              105.82768279072623,
              21.005304469547905
            ],
            [
              105.82772337915094,
              21.005287960784653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT97_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13610",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005313029273317,
          "to_latitude": 21.005287960784653,
          "to_longitude": 105.82772337915094,
          "from_longitude": 105.82766127757915
        }
      },
      {
        "id": 13535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766338799243,
            21.005293282043898,
            105.82772405006794,
            21.00531705282147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766338799243,
              21.00531705282147
            ],
            [
              105.8276768189434,
              21.00531031175701
            ],
            [
              105.82768442952894,
              21.005307567775326
            ],
            [
              105.82772405006794,
              21.005293282043898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT96_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13611",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00531705282147,
          "to_latitude": 21.005293282043898,
          "to_longitude": 105.82772405006794,
          "from_longitude": 105.82766338799243
        }
      },
      {
        "id": 13536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772780969795,
            21.005424427199507,
            105.827783059006,
            21.00543989089136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82772780969795,
              21.00543989089136
            ],
            [
              105.82774170702636,
              21.0054359150404
            ],
            [
              105.82775096528981,
              21.005433342784325
            ],
            [
              105.827783059006,
              21.005424427199507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT95_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13612",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00543989089136,
          "to_latitude": 21.005424427199507,
          "to_longitude": 105.827783059006,
          "from_longitude": 105.82772780969795
        }
      },
      {
        "id": 13537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82773010871072,
            21.005431313050888,
            105.82778238808692,
            21.005444275715238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82773010871072,
              21.005444275715238
            ],
            [
              105.82774303830645,
              21.005438365342684
            ],
            [
              105.82775270615042,
              21.005436632901922
            ],
            [
              105.82778238808692,
              21.005431313050888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT94_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13613",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005444275715238,
          "to_latitude": 21.005431313050888,
          "to_longitude": 105.82778238808692,
          "from_longitude": 105.82773010871072
        }
      },
      {
        "id": 13538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82779844276214,
            21.005560280110586,
            105.8278349780813,
            21.00557466557298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82779844276214,
              21.00557466557298
            ],
            [
              105.82780987425878,
              21.00557006493468
            ],
            [
              105.82782099749458,
              21.00556572953721
            ],
            [
              105.8278349780813,
              21.005560280110586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT93_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13614",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00557466557298,
          "to_latitude": 21.005560280110586,
          "to_longitude": 105.8278349780813,
          "from_longitude": 105.82779844276214
        }
      },
      {
        "id": 13539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786789261117,
            21.005683287485162,
            105.82793086675721,
            21.0057078273927
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82786789261117,
              21.0057078273927
            ],
            [
              105.82788204106225,
              21.005703861147015
            ],
            [
              105.82789187740708,
              21.005699716262885
            ],
            [
              105.82793086675721,
              21.005683287485162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT55_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13615",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0057078273927,
          "to_latitude": 21.005683287485162,
          "to_longitude": 105.82793086675721,
          "from_longitude": 105.82786789261117
        }
      },
      {
        "id": 13540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82786946848367,
            21.005687356698175,
            105.82793120181555,
            21.005710850053035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82786946848367,
              21.005710850053035
            ],
            [
              105.82788308135116,
              21.005705870282355
            ],
            [
              105.82789309533364,
              21.005702018082765
            ],
            [
              105.82793120181555,
              21.005687356698175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT54_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DODV.13616",
          "diaChiLapD": "Ngõ 95, Chùa Bộc",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.005710850053035,
          "to_latitude": 21.005687356698175,
          "to_longitude": 105.82793120181555,
          "from_longitude": 105.82786946848367
        }
      },
      {
        "id": 13541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81976438051674,
            21.005101875189425,
            105.81986152494561,
            21.00523813715088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81976438051674,
              21.00523813715088
            ],
            [
              105.8198337040221,
              21.005184855098147
            ],
            [
              105.81986152494561,
              21.005101875189425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.13617",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00523813715088,
          "to_latitude": 21.005101875189425,
          "to_longitude": 105.81986152494561,
          "from_longitude": 105.81976438051674
        }
      },
      {
        "id": 13542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8198337040221,
            21.005184855098147,
            105.81994038429472,
            21.00528766687933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81994038429472,
              21.00528766687933
            ],
            [
              105.8199321906133,
              21.00528019059151
            ],
            [
              105.81993010182585,
              21.00527815122965
            ],
            [
              105.81992867745475,
              21.005276772539613
            ],
            [
              105.8198337040221,
              21.005184855098147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A1_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.13618",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 35,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00528766687933,
          "to_latitude": 21.005184855098147,
          "to_longitude": 105.8198337040221,
          "from_longitude": 105.81994038429472
        }
      },
      {
        "id": 13543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82955800334723,
            21.03046899501305,
            105.82965076654068,
            21.030580209059643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82955800334723,
              21.030580209059643
            ],
            [
              105.82958116550154,
              21.030552268628043
            ],
            [
              105.82960620377482,
              21.030524879038403
            ],
            [
              105.82962225688753,
              21.030504657916225
            ],
            [
              105.82965076654068,
              21.03046899501305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Ngách 59/11, Giảng Võ",
          "maTaiSan": "04.O13.DODV.13619",
          "diaChiLapD": "Ngách 59/11, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.030580209059643,
          "to_latitude": 21.03046899501305,
          "to_longitude": 105.82965076654068,
          "from_longitude": 105.82955800334723
        }
      },
      {
        "id": 13544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82953719399772,
            21.03048016202152,
            105.82960620377482,
            21.030524879038403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82953719399772,
              21.03048016202152
            ],
            [
              105.82960620377482,
              21.030524879038403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_2_Ngách 59/11, Giảng Võ",
          "maTaiSan": "04.O13.DODV.13620",
          "diaChiLapD": "Ngách 59/11, Giảng Võ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 63.26,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.03048016202152,
          "to_latitude": 21.030524879038403,
          "to_longitude": 105.82960620377482,
          "from_longitude": 105.82953719399772
        }
      },
      {
        "id": 13545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177000806275,
            21.001025301578885,
            105.83226948907941,
            21.00116042871506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83177000806275,
              21.00116042871506
            ],
            [
              105.831801302451,
              21.00115102314479
            ],
            [
              105.83226948907941,
              21.001025301578885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.3_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13621",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00116042871506,
          "to_latitude": 21.001025301578885,
          "to_longitude": 105.83226948907941,
          "from_longitude": 105.83177000806275
        }
      },
      {
        "id": 13546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83176717912468,
            21.001013404675405,
            105.83226829968497,
            21.001148944464003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83176717912468,
              21.001148944464003
            ],
            [
              105.83178218744537,
              21.001145245576677
            ],
            [
              105.83226829968497,
              21.001013404675405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.4_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13622",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001148944464003,
          "to_latitude": 21.001013404675405,
          "to_longitude": 105.83226829968497,
          "from_longitude": 105.83176717912468
        }
      },
      {
        "id": 13547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83207152516162,
            21.00124598462435,
            105.83207606670608,
            21.0012598143063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83207606670608,
              21.0012598143063
            ],
            [
              105.83207152516162,
              21.00124598462435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.5_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13623",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0012598143063,
          "to_latitude": 21.00124598462435,
          "to_longitude": 105.83207152516162,
          "from_longitude": 105.83207606670608
        }
      },
      {
        "id": 13548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83186857720172,
            21.001191864398148,
            105.83227841223898,
            21.001292388056275
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83186857720172,
              21.001292388056275
            ],
            [
              105.83207152516162,
              21.00124598462435
            ],
            [
              105.83227841223898,
              21.001191864398148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.5_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13624",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001292388056275,
          "to_latitude": 21.001191864398148,
          "to_longitude": 105.83227841223898,
          "from_longitude": 105.83186857720172
        }
      },
      {
        "id": 13549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8322832897559,
            21.000970335997447,
            105.83231529372117,
            21.001269131303978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8322903385433,
              21.001269131303978
            ],
            [
              105.83229881180816,
              21.001247341084596
            ],
            [
              105.83231529372117,
              21.00116628443739
            ],
            [
              105.8322832897559,
              21.000970335997447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.6_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13625",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001269131303978,
          "to_latitude": 21.000970335997447,
          "to_longitude": 105.8322832897559,
          "from_longitude": 105.8322903385433
        }
      },
      {
        "id": 13550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83246140819814,
            21.00085456829944,
            105.8326301884714,
            21.001223926563075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326301884714,
              21.001223926563075
            ],
            [
              105.8326223682168,
              21.001205636544494
            ],
            [
              105.8325879957144,
              21.00110869427985
            ],
            [
              105.8325346951385,
              21.001108694841854
            ],
            [
              105.83249471267064,
              21.000957364211043
            ],
            [
              105.83246539121119,
              21.000866886894784
            ],
            [
              105.83246140819814,
              21.00085456829944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13626",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001223926563075,
          "to_latitude": 21.00085456829944,
          "to_longitude": 105.83246140819814,
          "from_longitude": 105.8326301884714
        }
      },
      {
        "id": 13551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83233887691691,
            21.001219347101994,
            105.83257370279233,
            21.001255743899474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83233887691691,
              21.001255743899474
            ],
            [
              105.83240858922647,
              21.001244939135603
            ],
            [
              105.83257370279233,
              21.001219347101994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.6.1_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13627",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001255743899474,
          "to_latitude": 21.001219347101994,
          "to_longitude": 105.83257370279233,
          "from_longitude": 105.83233887691691
        }
      },
      {
        "id": 13552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240858922647,
            21.001244939135603,
            105.83241502249385,
            21.001269996597085
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241502249385,
              21.001269996597085
            ],
            [
              105.83240858922647,
              21.001244939135603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.6.1_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13628",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001269996597085,
          "to_latitude": 21.001244939135603,
          "to_longitude": 105.83240858922647,
          "from_longitude": 105.83241502249385
        }
      },
      {
        "id": 13553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83249471267064,
            21.00090930300489,
            105.83269315222981,
            21.000957364211043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83249471267064,
              21.000957364211043
            ],
            [
              105.83269315222981,
              21.00090930300489
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13629",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000957364211043,
          "to_latitude": 21.00090930300489,
          "to_longitude": 105.83269315222981,
          "from_longitude": 105.83249471267064
        }
      },
      {
        "id": 13554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83246539121119,
            21.000799847433925,
            105.83271694698712,
            21.000866886894784
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83246539121119,
              21.000866886894784
            ],
            [
              105.83271694698712,
              21.000799847433925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13630",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.000866886894784,
          "to_latitude": 21.000799847433925,
          "to_longitude": 105.83271694698712,
          "from_longitude": 105.83246539121119
        }
      },
      {
        "id": 13555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83261443649353,
            21.00105208620868,
            105.83293674184321,
            21.001131716694573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83293674184321,
              21.001131716694573
            ],
            [
              105.83293352091442,
              21.00112136793255
            ],
            [
              105.83293196748039,
              21.001116157595018
            ],
            [
              105.83293196444961,
              21.001116131416286
            ],
            [
              105.83291279445237,
              21.00105208620868
            ],
            [
              105.83261443649353,
              21.001127019674996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13631",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001131716694573,
          "to_latitude": 21.001127019674996,
          "to_longitude": 105.83261443649353,
          "from_longitude": 105.83293674184321
        }
      },
      {
        "id": 13556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83276502002524,
            21.000841484535837,
            105.83304752524757,
            21.001116131416286
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83293196444961,
              21.001116131416286
            ],
            [
              105.83304752524757,
              21.001082000223903
            ],
            [
              105.83297702923025,
              21.000841484535837
            ],
            [
              105.83276504691105,
              21.000884306769578
            ],
            [
              105.83276502002524,
              21.00088431503014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.7_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13632",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001116131416286,
          "to_latitude": 21.00088431503014,
          "to_longitude": 105.83276502002524,
          "from_longitude": 105.83293196444961
        }
      },
      {
        "id": 13557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83109676145806,
            21.001478558415403,
            105.83109857152434,
            21.00148426790575
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83109857152434,
              21.00148426790575
            ],
            [
              105.83109676145806,
              21.001478558415403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.2_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13633",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.00148426790575,
          "to_latitude": 21.001478558415403,
          "to_longitude": 105.83109676145806,
          "from_longitude": 105.83109857152434
        }
      },
      {
        "id": 13558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.830958899861,
            21.00141034859527,
            105.83137142464227,
            21.001507898067032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.830958899861,
              21.001507898067032
            ],
            [
              105.83109676145806,
              21.001478558415403
            ],
            [
              105.83137142464227,
              21.00141034859527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.2_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13634",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001507898067032,
          "to_latitude": 21.00141034859527,
          "to_longitude": 105.83137142464227,
          "from_longitude": 105.830958899861
        }
      },
      {
        "id": 13559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83086126799107,
            21.001236647639097,
            105.83144828065883,
            21.001530296041732
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8308947426631,
              21.001530296041732
            ],
            [
              105.83089111179935,
              21.001521627433927
            ],
            [
              105.83086126799107,
              21.001461744660382
            ],
            [
              105.83098654607068,
              21.00137287162295
            ],
            [
              105.83144828065883,
              21.001236647639097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_5.1_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DODV.13635",
          "diaChiLapD": "Tôn Thất Tùng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.001530296041732,
          "to_latitude": 21.001236647639097,
          "to_longitude": 105.83144828065883,
          "from_longitude": 105.8308947426631
        }
      },
      {
        "id": 13560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84077398303039,
            21.028404131219293,
            105.84105781981374,
            21.02865065744141
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077398303039,
              21.02865065744141
            ],
            [
              105.8407880663043,
              21.028650363807298
            ],
            [
              105.84078809800447,
              21.028650355521556
            ],
            [
              105.840792022663,
              21.0286497734084
            ],
            [
              105.84079470073202,
              21.02864939616792
            ],
            [
              105.8407947314752,
              21.02864938879013
            ],
            [
              105.84105781981374,
              21.028609705436676
            ],
            [
              105.8410252770021,
              21.028404131219293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35.6.1_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13636",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": 52.875,
          "from_latitude": 21.02865065744141,
          "to_latitude": 21.028404131219293,
          "to_longitude": 105.8410252770021,
          "from_longitude": 105.84077398303039
        }
      },
      {
        "id": 13561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907784617696,
            21.02888635754367,
            105.83955063676378,
            21.02901591034159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954818504448,
              21.028887358106225
            ],
            [
              105.83954887023384,
              21.028887055756652
            ],
            [
              105.83955033821493,
              21.02888635754367
            ],
            [
              105.83955063676378,
              21.028887102131304
            ],
            [
              105.83954849594751,
              21.02888807553621
            ],
            [
              105.83953807301151,
              21.028892812914787
            ],
            [
              105.83950609308255,
              21.02891729819581
            ],
            [
              105.8391630063132,
              21.02901591034159
            ],
            [
              105.83914959526551,
              21.028982738026336
            ],
            [
              105.83907784617696,
              21.029005270165612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.2_Yên Thế",
          "maTaiSan": "04.O14.DODV.13638",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": 55.169,
          "from_latitude": 21.028887358106225,
          "to_latitude": 21.029005270165612,
          "to_longitude": 105.83907784617696,
          "from_longitude": 105.83954818504448
        }
      },
      {
        "id": 13562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931220423787,
            21.028852865208293,
            105.83954675034211,
            21.028954259899052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936786007098,
              21.028884159873638
            ],
            [
              105.83935780178746,
              21.028852865208293
            ],
            [
              105.83931220423787,
              21.028868512541788
            ],
            [
              105.83934640240005,
              21.028954259899052
            ],
            [
              105.83949995457105,
              21.028910281561128
            ],
            [
              105.83953622451614,
              21.028890327341113
            ],
            [
              105.83954647062865,
              21.028884249550497
            ],
            [
              105.83954675034211,
              21.02888403591222
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.2_Yên Thế",
          "maTaiSan": "04.O14.DODV.13639",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47,
          "chatLieu": "HDPE",
          "chieuDai": 41.158,
          "from_latitude": 21.028884159873638,
          "to_latitude": 21.02888403591222,
          "to_longitude": 105.83954675034211,
          "from_longitude": 105.83936786007098
        }
      },
      {
        "id": 13563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060822795136,
            20.99837182880358,
            105.84063530159733,
            20.998373167390408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060822795136,
              20.998373167390408
            ],
            [
              105.84061779916472,
              20.998372325291875
            ],
            [
              105.84061783086864,
              20.998372318812752
            ],
            [
              105.84063530159733,
              20.99837182880358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.13640",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998373167390408,
          "to_latitude": 20.99837182880358,
          "to_longitude": 105.84063530159733,
          "from_longitude": 105.84060822795136
        }
      },
      {
        "id": 13564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84061277158003,
            20.998546357172916,
            105.84075456517374,
            20.99855009221458
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84061277158003,
              20.99855009221458
            ],
            [
              105.84061633847432,
              20.998549999634296
            ],
            [
              105.84075453730688,
              20.998546361826673
            ],
            [
              105.84075456517374,
              20.998546357172916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_H2,1_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.13641",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 13.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.99855009221458,
          "to_latitude": 20.998546357172916,
          "to_longitude": 105.84075456517374,
          "from_longitude": 105.84061277158003
        }
      },
      {
        "id": 13565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84022620030885,
            20.998731664145787,
            105.84051060927405,
            20.998968752923467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84051060927405,
              20.998968752923467
            ],
            [
              105.84051001258128,
              20.99894240427177
            ],
            [
              105.84050457579738,
              20.99893664053714
            ],
            [
              105.84050338026927,
              20.998859336677203
            ],
            [
              105.84050864108741,
              20.998844494195662
            ],
            [
              105.84050750900474,
              20.998783848206028
            ],
            [
              105.84050655312946,
              20.998733544179025
            ],
            [
              105.84049995984772,
              20.998733036602445
            ],
            [
              105.84049771973926,
              20.99873286250101
            ],
            [
              105.84048212373493,
              20.998731664145787
            ],
            [
              105.84022620030885,
              20.99873313249176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,164_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.13642",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.998968752923467,
          "to_latitude": 20.99873313249176,
          "to_longitude": 105.84022620030885,
          "from_longitude": 105.84051060927405
        }
      },
      {
        "id": 13566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84049019316328,
            20.999093418423694,
            105.84051536111971,
            20.999094406937036
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84049019316328,
              20.999093418423694
            ],
            [
              105.84049356920687,
              20.99909359414256
            ],
            [
              105.84051536111971,
              20.999094406937036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT6,164_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DODV.13643",
          "diaChiLapD": "Ngõ 78, Giải Phóng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 55.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 20.999093418423694,
          "to_latitude": 20.999094406937036,
          "to_longitude": 105.84051536111971,
          "from_longitude": 105.84049019316328
        }
      },
      {
        "id": 13567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987958971227,
            21.02917530516969,
            105.83999136624345,
            21.029210828265697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83999136624345,
              21.02917530516969
            ],
            [
              105.83987958971227,
              21.029210828265697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.3_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13644",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02917530516969,
          "to_latitude": 21.029210828265697,
          "to_longitude": 105.83987958971227,
          "from_longitude": 105.83999136624345
        }
      },
      {
        "id": 13568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83987958971227,
            21.029210828265697,
            105.83988446727501,
            21.029223895477223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83988446727501,
              21.029223895477223
            ],
            [
              105.83988071087695,
              21.029213476394908
            ],
            [
              105.83987958971227,
              21.029210828265697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_39.3_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.13645",
          "diaChiLapD": "Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.029223895477223,
          "to_latitude": 21.029210828265697,
          "to_longitude": 105.83987958971227,
          "from_longitude": 105.83988446727501
        }
      },
      {
        "id": 13569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82458718839969,
            21.016704186816263,
            105.82485470567187,
            21.01683711355171
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82458718839969,
              21.01683711355171
            ],
            [
              105.82459602714367,
              21.01683438361173
            ],
            [
              105.82463744361286,
              21.016821589174153
            ],
            [
              105.82485470567187,
              21.016704186816263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_D11_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.13646",
          "diaChiLapD": "Ngõ 61, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01683711355171,
          "to_latitude": 21.016704186816263,
          "to_longitude": 105.82485470567187,
          "from_longitude": 105.82458718839969
        }
      },
      {
        "id": 13570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82250034865763,
            21.025599904904162,
            105.822541902215,
            21.02564622367628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82250034865763,
              21.02564622367628
            ],
            [
              105.82250036682618,
              21.02564620371744
            ],
            [
              105.82252255670367,
              21.025620836788878
            ],
            [
              105.8225314734843,
              21.025611188761026
            ],
            [
              105.822541902215,
              21.025599904904162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_CQ13.10_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13647",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 6.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02564622367628,
          "to_latitude": 21.025599904904162,
          "to_longitude": 105.822541902215,
          "from_longitude": 105.82250034865763
        }
      },
      {
        "id": 13571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82255116090565,
            21.025636072644833,
            105.82259234703456,
            21.025687990553656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82255116090565,
              21.025687990553656
            ],
            [
              105.82255117810728,
              21.025687969696268
            ],
            [
              105.82257154910626,
              21.025662301752547
            ],
            [
              105.82258082118943,
              21.02565060890734
            ],
            [
              105.82259234703456,
              21.025636072644833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_CQ13.6_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13648",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025687990553656,
          "to_latitude": 21.025636072644833,
          "to_longitude": 105.82259234703456,
          "from_longitude": 105.82255116090565
        }
      },
      {
        "id": 13572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82334065331202,
            21.02628409217712,
            105.82339593482355,
            21.026347540447627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82334065331202,
              21.026347540447627
            ],
            [
              105.82334067241773,
              21.026347515968034
            ],
            [
              105.82335442870883,
              21.026329224084392
            ],
            [
              105.82339593482355,
              21.02628409217712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_CQ13.5_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13649",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026347540447627,
          "to_latitude": 21.02628409217712,
          "to_longitude": 105.82339593482355,
          "from_longitude": 105.82334065331202
        }
      },
      {
        "id": 13573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82397271397097,
            21.026829719133765,
            105.82403771055253,
            21.02689110203618
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82397271397097,
              21.02689110203618
            ],
            [
              105.82397273503537,
              21.02689108386962
            ],
            [
              105.82398946061555,
              21.02687656838555
            ],
            [
              105.82403771055253,
              21.026829719133765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_CQ13.3_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13650",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 9.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02689110203618,
          "to_latitude": 21.026829719133765,
          "to_longitude": 105.82403771055253,
          "from_longitude": 105.82397271397097
        }
      },
      {
        "id": 13574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8246820589475,
            21.02743362606205,
            105.82474203080326,
            21.027489602542808
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8246820589475,
              21.027489602542808
            ],
            [
              105.82468207808292,
              21.02748958348228
            ],
            [
              105.82470862746621,
              21.02746382571547
            ],
            [
              105.82474203080326,
              21.02743362606205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_CQ13.4_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13651",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.79,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027489602542808,
          "to_latitude": 21.02743362606205,
          "to_longitude": 105.82474203080326,
          "from_longitude": 105.8246820589475
        }
      },
      {
        "id": 13575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.825396896568,
            21.02801517221573,
            105.82545623954107,
            21.028069342151145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825396896568,
              21.028069342151145
            ],
            [
              105.82541521270595,
              21.028052620359205
            ],
            [
              105.82545621847171,
              21.028015189479284
            ],
            [
              105.82545623954107,
              21.02801517221573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_G8*_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13652",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 8.54,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028069342151145,
          "to_latitude": 21.02801517221573,
          "to_longitude": 105.82545623954107,
          "from_longitude": 105.825396896568
        }
      },
      {
        "id": 13576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82478376785662,
            21.02753874822306,
            105.82480788957412,
            21.027562148554974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82478376785662,
              21.027562148554974
            ],
            [
              105.82479253252261,
              21.02755563353366
            ],
            [
              105.82480788957412,
              21.02753874822306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_CQ13.4_Giảng Võ",
          "maTaiSan": "04.O13.DODV.13653",
          "diaChiLapD": "Giảng Võ",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027562148554974,
          "to_latitude": 21.02753874822306,
          "to_longitude": 105.82480788957412,
          "from_longitude": 105.82478376785662
        }
      },
      {
        "id": 13577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83867370922141,
            21.028225600988712,
            105.83868438065063,
            21.02826012100673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83868438065063,
              21.02826012100673
            ],
            [
              105.83867790242971,
              21.028239832256556
            ],
            [
              105.83867370922141,
              21.028225600988712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT46.8A_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13654",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02826012100673,
          "to_latitude": 21.028225600988712,
          "to_longitude": 105.83867370922141,
          "from_longitude": 105.83868438065063
        }
      },
      {
        "id": 13578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874614442648,
            21.028323798848945,
            105.83875240325028,
            21.028350608138485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83874614442648,
              21.028323798848945
            ],
            [
              105.83874812855696,
              21.028332303741188
            ],
            [
              105.83875240325028,
              21.028350608138485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.9_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.13655",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028323798848945,
          "to_latitude": 21.028350608138485,
          "to_longitude": 105.83875240325028,
          "from_longitude": 105.83874614442648
        }
      },
      {
        "id": 13579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8383066863467,
            21.02741975285198,
            105.83866275029534,
            21.02754147727465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83866275029534,
              21.02741975285198
            ],
            [
              105.8385157773648,
              21.02746899423952
            ],
            [
              105.83851572071373,
              21.02746901348655
            ],
            [
              105.8383066863467,
              21.02754147727465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.3_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13656",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02741975285198,
          "to_latitude": 21.02754147727465,
          "to_longitude": 105.8383066863467,
          "from_longitude": 105.83866275029534
        }
      },
      {
        "id": 13580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83850520321583,
            21.02745214001576,
            105.8385157773648,
            21.02746899423952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83850993309952,
              21.027465374940913
            ],
            [
              105.83850603303638,
              21.027456763879094
            ],
            [
              105.83850520321583,
              21.02745356694969
            ],
            [
              105.83850920105108,
              21.02745214001576
            ],
            [
              105.83851378925034,
              21.027463899931472
            ],
            [
              105.8385138351861,
              21.02746402886581
            ],
            [
              105.83851572758613,
              21.027468866227352
            ],
            [
              105.8385157773648,
              21.02746899423952
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_53.3_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13657",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027465374940913,
          "to_latitude": 21.02746899423952,
          "to_longitude": 105.8385157773648,
          "from_longitude": 105.83850993309952
        }
      },
      {
        "id": 13581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83898262775722,
            21.028889197507763,
            105.83955278614621,
            21.029070362995224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954995759935,
              21.02889174353134
            ],
            [
              105.83955145473372,
              21.02889109756128
            ],
            [
              105.83955278614621,
              21.028890577957373
            ],
            [
              105.83955212039199,
              21.028889197507763
            ],
            [
              105.8395494951033,
              21.028890583367634
            ],
            [
              105.83954026104455,
              21.028895460295278
            ],
            [
              105.83951104130969,
              21.028929248687994
            ],
            [
              105.83904498910965,
              21.029062226393595
            ],
            [
              105.83898262775722,
              21.029070362995224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_41.2.2_Yên Thế",
          "maTaiSan": "04.O14.DODV.13658",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 56,
          "chatLieu": "HDPE",
          "chieuDai": 63.901,
          "from_latitude": 21.02889174353134,
          "to_latitude": 21.029070362995224,
          "to_longitude": 105.83898262775722,
          "from_longitude": 105.83954995759935
        }
      },
      {
        "id": 13582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8395324087737,
            21.029045495305738,
            105.83962062790206,
            21.029078509975616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83961193202984,
              21.02904804544631
            ],
            [
              105.83961590181984,
              21.029046758623643
            ],
            [
              105.83961931857891,
              21.029045495305738
            ],
            [
              105.83962062790206,
              21.02904858869962
            ],
            [
              105.83961314412056,
              21.029051126675256
            ],
            [
              105.8395324087737,
              21.029078509975616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT41.2.3_Yên Thế",
          "maTaiSan": "04.O14.DODV.13659",
          "diaChiLapD": "Yên Thế",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02904804544631,
          "to_latitude": 21.029078509975616,
          "to_longitude": 105.8395324087737,
          "from_longitude": 105.83961193202984
        }
      },
      {
        "id": 13583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81316457409372,
            21.014902537033795,
            105.81368209459512,
            21.01559560763247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81368209459512,
              21.014902537033795
            ],
            [
              105.81365308994526,
              21.0149196717939
            ],
            [
              105.81348095375104,
              21.015021363850977
            ],
            [
              105.81338909449849,
              21.015075630931698
            ],
            [
              105.81320804239868,
              21.015182589618934
            ],
            [
              105.8132005268029,
              21.015187029683208
            ],
            [
              105.81318653693715,
              21.015195294251235
            ],
            [
              105.81316457409372,
              21.015208269325797
            ],
            [
              105.81344086442337,
              21.01559560763247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.13660",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 115.2,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.014902537033795,
          "to_latitude": 21.01559560763247,
          "to_longitude": 105.81344086442337,
          "from_longitude": 105.81368209459512
        }
      },
      {
        "id": 13584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81318189373869,
            21.015166563076107,
            105.8132005268029,
            21.015187029683208
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81318189373869,
              21.015166563076107
            ],
            [
              105.813193808438,
              21.015179364484432
            ],
            [
              105.8132005268029,
              21.015187029683208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_87_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DODV.13661",
          "diaChiLapD": "Hoàng Ngọc Phách",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.98,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015166563076107,
          "to_latitude": 21.015187029683208,
          "to_longitude": 105.8132005268029,
          "from_longitude": 105.81318189373869
        }
      },
      {
        "id": 13585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83613862976773,
            21.02387603058628,
            105.83616846053066,
            21.023889579275764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83613862976773,
              21.023889579275764
            ],
            [
              105.83614099486621,
              21.023888294103113
            ],
            [
              105.83616846053066,
              21.02387603058628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.2_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13662",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023889579275764,
          "to_latitude": 21.02387603058628,
          "to_longitude": 105.83616846053066,
          "from_longitude": 105.83613862976773
        }
      },
      {
        "id": 13586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83588777314256,
            21.022834832945247,
            105.83591286318227,
            21.02283528047235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588777314256,
              21.022834832945247
            ],
            [
              105.83589079753764,
              21.022835164016065
            ],
            [
              105.83591286318227,
              21.02283528047235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_18.12_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13663",
          "diaChiLapD": "Ngách 33, Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022834832945247,
          "to_latitude": 21.02283528047235,
          "to_longitude": 105.83591286318227,
          "from_longitude": 105.83588777314256
        }
      },
      {
        "id": 13587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81436960403538,
            21.021841020168313,
            105.81437609155154,
            21.02187982497446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81437609155154,
              21.021841020168313
            ],
            [
              105.81437532150882,
              21.021848906320454
            ],
            [
              105.81437508040774,
              21.021850211735153
            ],
            [
              105.81436960403538,
              21.02187982497446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_35b_Thành Công",
          "maTaiSan": "04.O9C.DODV.13664",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021841020168313,
          "to_latitude": 21.02187982497446,
          "to_longitude": 105.81436960403538,
          "from_longitude": 105.81437609155154
        }
      },
      {
        "id": 13588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8143917521376,
            21.021843185605494,
            105.8143995881335,
            21.021882383450272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8143995881335,
              21.021843185605494
            ],
            [
              105.81439808908974,
              21.0218537614464
            ],
            [
              105.81439789445706,
              21.021855120832264
            ],
            [
              105.8143917521376,
              21.021882383450272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36b_Thành Công",
          "maTaiSan": "04.O9C.DODV.13665",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021843185605494,
          "to_latitude": 21.021882383450272,
          "to_longitude": 105.8143917521376,
          "from_longitude": 105.8143995881335
        }
      },
      {
        "id": 13589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81475927087189,
            21.021881647715475,
            105.81476415190147,
            21.021921492581733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81476415190147,
              21.021881647715475
            ],
            [
              105.81476233058955,
              21.021889385355973
            ],
            [
              105.81476224161337,
              21.02189036036444
            ],
            [
              105.81476214332193,
              21.021891391417633
            ],
            [
              105.81475927087189,
              21.021921492581733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_37b_Thành Công",
          "maTaiSan": "04.O9C.DODV.13666",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021881647715475,
          "to_latitude": 21.021921492581733,
          "to_longitude": 105.81475927087189,
          "from_longitude": 105.81476415190147
        }
      },
      {
        "id": 13590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8147871031755,
            21.02188469425707,
            105.81479319813627,
            21.021924908036514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81479319813627,
              21.02188469425707
            ],
            [
              105.81479197467611,
              21.02189287071203
            ],
            [
              105.8147918867946,
              21.021893516037295
            ],
            [
              105.81479178463103,
              21.02189418852781
            ],
            [
              105.8147871031755,
              21.021924908036514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38b_Thành Công",
          "maTaiSan": "04.O9C.DODV.13667",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02188469425707,
          "to_latitude": 21.021924908036514,
          "to_longitude": 105.8147871031755,
          "from_longitude": 105.81479319813627
        }
      },
      {
        "id": 13591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81258100543329,
            21.02452824413906,
            105.81258976339426,
            21.024563197839218
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81258100543329,
              21.02452824413906
            ],
            [
              105.81258892075323,
              21.024559838246518
            ],
            [
              105.81258976339426,
              21.024563197839218
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_07_La Thành",
          "maTaiSan": "04.O9C.DODV.13668",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02452824413906,
          "to_latitude": 21.024563197839218,
          "to_longitude": 105.81258976339426,
          "from_longitude": 105.81258100543329
        }
      },
      {
        "id": 13592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81272418637722,
            21.024325598334126,
            105.8127287810128,
            21.024345322734725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81272418637722,
              21.024325598334126
            ],
            [
              105.8127287810128,
              21.024345322734725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_La Thành",
          "maTaiSan": "04.O9C.DODV.13669",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024325598334126,
          "to_latitude": 21.024345322734725,
          "to_longitude": 105.8127287810128,
          "from_longitude": 105.81272418637722
        }
      },
      {
        "id": 13593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81262166615744,
            21.02432759959409,
            105.81279364835784,
            21.024355633556585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81262166615744,
              21.024330696482927
            ],
            [
              105.81266262753262,
              21.02432759959409
            ],
            [
              105.81266498149765,
              21.024355633556585
            ],
            [
              105.8127287810128,
              21.024345322734725
            ],
            [
              105.81279364835784,
              21.024334838785574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_06_La Thành",
          "maTaiSan": "04.O9C.DODV.13670",
          "diaChiLapD": "La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024330696482927,
          "to_latitude": 21.024334838785574,
          "to_longitude": 105.81279364835784,
          "from_longitude": 105.81262166615744
        }
      },
      {
        "id": 13594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81424259369746,
            21.02375051961043,
            105.81424436885374,
            21.023764993292485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424436885374,
              21.02375051961043
            ],
            [
              105.81424400053298,
              21.02375514770345
            ],
            [
              105.81424390148068,
              21.02375639372796
            ],
            [
              105.81424264367678,
              21.023764630859105
            ],
            [
              105.81424259369746,
              21.023764993292485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34c_Thành Công",
          "maTaiSan": "04.O9C.DODV.13671",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1.6,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02375051961043,
          "to_latitude": 21.023764993292485,
          "to_longitude": 105.81424259369746,
          "from_longitude": 105.81424436885374
        }
      },
      {
        "id": 13595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81411315320833,
            21.023743100908,
            105.81424259369746,
            21.023764993292485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81411315320833,
              21.023743100908
            ],
            [
              105.81414453115067,
              21.023751898978333
            ],
            [
              105.81422970397715,
              21.02376322506359
            ],
            [
              105.81424259369746,
              21.023764993292485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34c_Thành Công",
          "maTaiSan": "04.O9C.DODV.13672",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 13.7,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.023743100908,
          "to_latitude": 21.023764993292485,
          "to_longitude": 105.81424259369746,
          "from_longitude": 105.81411315320833
        }
      },
      {
        "id": 13596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81690015530728,
            21.02075799530762,
            105.81709243502958,
            21.02101074125615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81709243502958,
              21.02101074125615
            ],
            [
              105.81696368900161,
              21.020844023319174
            ],
            [
              105.81695892570099,
              21.02083756193029
            ],
            [
              105.81695638757681,
              21.02083412650316
            ],
            [
              105.81690015530728,
              21.02075799530762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_19k_Thành Công",
          "maTaiSan": "04.O9C.DODV.13673",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 34.4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02101074125615,
          "to_latitude": 21.02075799530762,
          "to_longitude": 105.81690015530728,
          "from_longitude": 105.81709243502958
        }
      },
      {
        "id": 13597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753121303423,
            21.01833800273864,
            105.81767222573086,
            21.018530210960982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81753121303423,
              21.01833800273864
            ],
            [
              105.81767222573086,
              21.018530210960982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.13674",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01833800273864,
          "to_latitude": 21.018530210960982,
          "to_longitude": 105.81767222573086,
          "from_longitude": 105.81753121303423
        }
      },
      {
        "id": 13598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8176498612118,
            21.018529690716665,
            105.81767222573086,
            21.018530210960982
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8176498612118,
              21.018529690716665
            ],
            [
              105.81767222573086,
              21.018530210960982
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A19_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DODV.13675",
          "diaChiLapD": "Ngõ 57, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018529690716665,
          "to_latitude": 21.018530210960982,
          "to_longitude": 105.81767222573086,
          "from_longitude": 105.8176498612118
        }
      },
      {
        "id": 13599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82312293790588,
            21.020026841427097,
            105.82312425187126,
            21.020051216825063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82312293790588,
              21.020051216825063
            ],
            [
              105.82312381086804,
              21.020035001499085
            ],
            [
              105.82312425187126,
              21.020026841427097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT131_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.13676",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 23.84,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020051216825063,
          "to_latitude": 21.020026841427097,
          "to_longitude": 105.82312425187126,
          "from_longitude": 105.82312293790588
        }
      },
      {
        "id": 13600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82305316785508,
            21.020047795204736,
            105.82312293790588,
            21.020051216825063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82305316785508,
              21.020047795204736
            ],
            [
              105.82311839331251,
              21.020050994042258
            ],
            [
              105.82312293790588,
              21.020051216825063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT131_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.13677",
          "diaChiLapD": "Ngõ 34, Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 47.9,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020047795204736,
          "to_latitude": 21.020051216825063,
          "to_longitude": 105.82312293790588,
          "from_longitude": 105.82305316785508
        }
      },
      {
        "id": 13601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8251482409027,
            21.019523409496056,
            105.82518532840079,
            21.01953617856748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8251482409027,
              21.01953617856748
            ],
            [
              105.82516250672562,
              21.019531266268654
            ],
            [
              105.82518532840079,
              21.019523409496056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B43_Hoàng Cầu",
          "maTaiSan": "04.O12.DODV.13678",
          "diaChiLapD": "Hoàng Cầu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.1,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01953617856748,
          "to_latitude": 21.019523409496056,
          "to_longitude": 105.82518532840079,
          "from_longitude": 105.8251482409027
        }
      },
      {
        "id": 13602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174750009209,
            21.022691903392037,
            105.8319169431435,
            21.023046196283865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83174750009209,
              21.022691903392037
            ],
            [
              105.83179790292614,
              21.022797291695724
            ],
            [
              105.83182594487648,
              21.02285592645466
            ],
            [
              105.83186964391027,
              21.022947297864793
            ],
            [
              105.8319169431435,
              21.023046196283865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_Đ9_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.13679",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022691903392037,
          "to_latitude": 21.023046196283865,
          "to_longitude": 105.8319169431435,
          "from_longitude": 105.83174750009209
        }
      },
      {
        "id": 13603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83186964391027,
            21.022932884839232,
            105.8318990622221,
            21.022947297864793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318990622221,
              21.022932884839232
            ],
            [
              105.83188526927128,
              21.022939642485014
            ],
            [
              105.83186964391027,
              21.022947297864793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Đ9_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DODV.13680",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.022932884839232,
          "to_latitude": 21.022947297864793,
          "to_longitude": 105.83186964391027,
          "from_longitude": 105.8318990622221
        }
      },
      {
        "id": 13604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80576757419232,
            21.01754175480757,
            105.80610045245365,
            21.01803046308291
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80576757419232,
              21.01803046308291
            ],
            [
              105.80581947913679,
              21.01790752992751
            ],
            [
              105.80595153679543,
              21.017734839169073
            ],
            [
              105.8059583490471,
              21.017725472021034
            ],
            [
              105.80610045245365,
              21.01754175480757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_M85_Ngõ 176, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DODV.13681",
          "diaChiLapD": "Ngõ 176, Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 49.93,
          "chatLieu": "HDPE",
          "chieuDai": 64.574,
          "from_latitude": 21.01803046308291,
          "to_latitude": 21.01754175480757,
          "to_longitude": 105.80610045245365,
          "from_longitude": 105.80576757419232
        }
      },
      {
        "id": 13605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80527128531216,
            21.016880667229984,
            105.80536558758973,
            21.01695530489952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80527128531216,
              21.01695530489952
            ],
            [
              105.8052894833523,
              21.01694327535544
            ],
            [
              105.80536558758973,
              21.016880667229984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_99_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.13682",
          "diaChiLapD": "Ngõ 157, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.8,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01695530489952,
          "to_latitude": 21.016880667229984,
          "to_longitude": 105.80536558758973,
          "from_longitude": 105.80527128531216
        }
      },
      {
        "id": 13606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81665532034677,
            21.007009384629015,
            105.81673770487629,
            21.00708574653956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81665532034677,
              21.007009384629015
            ],
            [
              105.81673770487629,
              21.00708574653956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A18_Đường Láng",
          "maTaiSan": "04.O11.DODV.13684",
          "diaChiLapD": "Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.03,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.007009384629015,
          "to_latitude": 21.00708574653956,
          "to_longitude": 105.81673770487629,
          "from_longitude": 105.81665532034677
        }
      },
      {
        "id": 13607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82890689314272,
            21.015708334410263,
            105.82905226943596,
            21.015794754368645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82890689314272,
              21.015794754368645
            ],
            [
              105.82894140680573,
              21.015778092404226
            ],
            [
              105.82901764807245,
              21.015730045048787
            ],
            [
              105.82903515009772,
              21.015719348668878
            ],
            [
              105.82904049158041,
              21.015715838898917
            ],
            [
              105.82905226943596,
              21.015708334410263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_134_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.13685",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17.83,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015794754368645,
          "to_latitude": 21.015708334410263,
          "to_longitude": 105.82905226943596,
          "from_longitude": 105.82890689314272
        }
      },
      {
        "id": 13608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82878737392139,
            21.017362700742925,
            105.8289726404922,
            21.017414831190813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82878737392139,
              21.017412827412432
            ],
            [
              105.82879952286837,
              21.017414831190813
            ],
            [
              105.82881956761273,
              21.017412678695226
            ],
            [
              105.8288613713525,
              21.017398811026666
            ],
            [
              105.8289726404922,
              21.017362700742925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A90_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.13686",
          "diaChiLapD": "Ngõ 64, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20.23,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.017412827412432,
          "to_latitude": 21.017362700742925,
          "to_longitude": 105.8289726404922,
          "from_longitude": 105.82878737392139
        }
      },
      {
        "id": 13609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82709862870584,
            21.013987203860616,
            105.82716166597675,
            21.01410238298156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82709862870584,
              21.013987203860616
            ],
            [
              105.82716166597675,
              21.01410238298156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT45_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DODV.13687",
          "diaChiLapD": "Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 14.34,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.013987203860616,
          "to_latitude": 21.01410238298156,
          "to_longitude": 105.82716166597675,
          "from_longitude": 105.82709862870584
        }
      },
      {
        "id": 13610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568467278804,
            21.012834301572838,
            105.82579027279759,
            21.01288036828019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568467278804,
              21.01288036828019
            ],
            [
              105.82568570725498,
              21.012879917066325
            ],
            [
              105.82579027279759,
              21.012834301572838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT21_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DODV.13688",
          "diaChiLapD": "Ngõ 100, Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.11,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01288036828019,
          "to_latitude": 21.012834301572838,
          "to_longitude": 105.82579027279759,
          "from_longitude": 105.82568467278804
        }
      },
      {
        "id": 13611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81180716396673,
            21.01933402638475,
            105.81188925120578,
            21.019616156401558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81187312565042,
              21.019616156401558
            ],
            [
              105.81187318129369,
              21.019615773167313
            ],
            [
              105.8118734714772,
              21.01961375758383
            ],
            [
              105.81187445241581,
              21.019606956111144
            ],
            [
              105.81187643562035,
              21.019593205837815
            ],
            [
              105.81188925120578,
              21.01949997128093
            ],
            [
              105.81188426771998,
              21.019489927686962
            ],
            [
              105.81188294307336,
              21.01948725864791
            ],
            [
              105.81181957585078,
              21.01935955198778
            ],
            [
              105.81182076478488,
              21.019353500105055
            ],
            [
              105.81181862185967,
              21.01934982967778
            ],
            [
              105.8118161556017,
              21.019345605308313
            ],
            [
              105.81181163186768,
              21.019343544053623
            ],
            [
              105.81181080271689,
              21.019341778586913
            ],
            [
              105.81181031612434,
              21.01934074219687
            ],
            [
              105.81180716396673,
              21.01933402638475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_55g_Nguyên Hồng",
          "maTaiSan": "04.O9C.DODV.13689",
          "diaChiLapD": "Nguyên Hồng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 33.48,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019616156401558,
          "to_latitude": 21.01933402638475,
          "to_longitude": 105.81180716396673,
          "from_longitude": 105.81187312565042
        }
      },
      {
        "id": 13612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82696084504792,
            21.020714006701784,
            105.82698220378164,
            21.020724978151293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82698220378164,
              21.020714006701784
            ],
            [
              105.82696084504792,
              21.020724978151293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT2_Đường La Thành",
          "maTaiSan": "04.O13.DODV.13690",
          "diaChiLapD": "Đường La Thành",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 2.53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020714006701784,
          "to_latitude": 21.020724978151293,
          "to_longitude": 105.82696084504792,
          "from_longitude": 105.82698220378164
        }
      },
      {
        "id": 13613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83443101238484,
            21.02394603806528,
            105.83450227280818,
            21.023971437711026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450227280818,
              21.02394603806528
            ],
            [
              105.83444154937762,
              21.02396924717941
            ],
            [
              105.83443101238484,
              21.023971437711026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_71.5_Văn Hương",
          "maTaiSan": "04.O14.DODV.13691",
          "diaChiLapD": "Văn Hương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.94,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02394603806528,
          "to_latitude": 21.023971437711026,
          "to_longitude": 105.83443101238484,
          "from_longitude": 105.83450227280818
        }
      },
      {
        "id": 13614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83820636287189,
            21.02737769005149,
            105.83823242210401,
            21.027473219209988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83823242210401,
              21.027473219209988
            ],
            [
              105.8382066584085,
              21.027378069754754
            ],
            [
              105.83820636287189,
              21.02737769005149
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_52.2_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.13692",
          "diaChiLapD": "Ngõ 38, Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.92,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027473219209988,
          "to_latitude": 21.02737769005149,
          "to_longitude": 105.83820636287189,
          "from_longitude": 105.83823242210401
        }
      },
      {
        "id": 13615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921590161422,
            21.019540450354224,
            105.83927512879106,
            21.019582700001862
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83927512879106,
              21.019540450354224
            ],
            [
              105.83924246795975,
              21.01956084550719
            ],
            [
              105.8392180725034,
              21.019579388010147
            ],
            [
              105.83921590161422,
              21.019582700001862
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_3.5_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13693",
          "diaChiLapD": "Ngõ Tương Thuận, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.77,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019540450354224,
          "to_latitude": 21.019582700001862,
          "to_longitude": 105.83921590161422,
          "from_longitude": 105.83927512879106
        }
      },
      {
        "id": 13616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84091472511699,
            21.01689329741551,
            105.84091722568782,
            21.016955663800417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84091472511699,
              21.016955663800417
            ],
            [
              105.84091495433499,
              21.01690561415801
            ],
            [
              105.84091722568782,
              21.01689329741551
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT71.A1_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DODV.13694",
          "diaChiLapD": "Ngách 222/16, Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 6.93,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016955663800417,
          "to_latitude": 21.01689329741551,
          "to_longitude": 105.84091722568782,
          "from_longitude": 105.84091472511699
        }
      },
      {
        "id": 13617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363285607147,
            21.018622454144335,
            105.83636303381658,
            21.018623923371564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636303381658,
              21.018622454144335
            ],
            [
              105.8363285607147,
              21.018623923371564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT21.1_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DODV.13695",
          "diaChiLapD": "Ngõ Lệnh Cư",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3.59,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.018622454144335,
          "to_latitude": 21.018623923371564,
          "to_longitude": 105.8363285607147,
          "from_longitude": 105.83636303381658
        }
      },
      {
        "id": 13618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548310561704,
            21.019112518031417,
            105.83555694426441,
            21.01911896934294
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548310561704,
              21.01911896934294
            ],
            [
              105.83550816594524,
              21.019117476065144
            ],
            [
              105.83555694426441,
              21.019112518031417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.10_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.13696",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 7.71,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01911896934294,
          "to_latitude": 21.019112518031417,
          "to_longitude": 105.83555694426441,
          "from_longitude": 105.83548310561704
        }
      },
      {
        "id": 13619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83409584083746,
            21.01644086773839,
            105.83425921936644,
            21.0165805631052
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83425921936644,
              21.01644086773839
            ],
            [
              105.83425239202772,
              21.016447361080495
            ],
            [
              105.83421605498013,
              21.016476991612887
            ],
            [
              105.8341960861667,
              21.016493275314875
            ],
            [
              105.83413659373826,
              21.01654686901601
            ],
            [
              105.8341313471924,
              21.016551519250275
            ],
            [
              105.83409584083746,
              21.0165805631052
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.13697",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01644086773839,
          "to_latitude": 21.0165805631052,
          "to_longitude": 105.83409584083746,
          "from_longitude": 105.83425921936644
        }
      },
      {
        "id": 13620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83395828321314,
            21.0165805631052,
            105.83409584083746,
            21.016696166488334
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83409584083746,
              21.0165805631052
            ],
            [
              105.83409114961924,
              21.01658440042313
            ],
            [
              105.83407389581252,
              21.016598512996467
            ],
            [
              105.83397189172948,
              21.01668455747028
            ],
            [
              105.83395828321314,
              21.016696166488334
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT8.2_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DODV.13698",
          "diaChiLapD": "Ngõ Thổ Quan",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 42,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0165805631052,
          "to_latitude": 21.016696166488334,
          "to_longitude": 105.83395828321314,
          "from_longitude": 105.83409584083746
        }
      },
      {
        "id": 13621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83084189807397,
            21.016150432559872,
            105.83108046582434,
            21.016356115811465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83084189807397,
              21.016150432559872
            ],
            [
              105.83090203264166,
              21.01619745612623
            ],
            [
              105.8309270675979,
              21.01621819925623
            ],
            [
              105.83097599781419,
              21.016260065909712
            ],
            [
              105.83100904005823,
              21.01628655055579
            ],
            [
              105.83105454600779,
              21.0163230714641
            ],
            [
              105.83108046582434,
              21.01634361063474
            ],
            [
              105.83107999408581,
              21.01635013783742
            ],
            [
              105.83107990600466,
              21.016356115811465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_174_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DODV.13699",
          "diaChiLapD": "Ngách 95, Ngõ Xã Đàn 2",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 34.14,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.016150432559872,
          "to_latitude": 21.016356115811465,
          "to_longitude": 105.83107990600466,
          "from_longitude": 105.83084189807397
        }
      },
      {
        "id": 13622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900056306247,
            21.0161699584706,
            105.83902579280755,
            21.01617391346918
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83902579280755,
              21.0161699584706
            ],
            [
              105.83901938133407,
              21.016170963765887
            ],
            [
              105.83900056306247,
              21.01617391346918
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT90.2_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DODV.13701",
          "diaChiLapD": "Ngõ Hồ Bãi Cát",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2.66,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.0161699584706,
          "to_latitude": 21.01617391346918,
          "to_longitude": 105.83900056306247,
          "from_longitude": 105.83902579280755
        }
      },
      {
        "id": 13623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502741599017,
            21.011254466951623,
            105.83515459140425,
            21.011415444235322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502741599017,
              21.011415444235322
            ],
            [
              105.83509014623311,
              21.011348615670848
            ],
            [
              105.83512315398924,
              21.011300720945204
            ],
            [
              105.83515272498681,
              21.01125924875279
            ],
            [
              105.83515459140425,
              21.011254466951623
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_317_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DODV.13702",
          "diaChiLapD": "Ngõ 46, Phạm Ngọc Thạch",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 62,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011415444235322,
          "to_latitude": 21.011254466951623,
          "to_longitude": 105.83515459140425,
          "from_longitude": 105.83502741599017
        }
      },
      {
        "id": 13624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82440289249132,
            21.02413527198447,
            105.82441551183834,
            21.024179694666298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82441551183834,
              21.024179694666298
            ],
            [
              105.82440289249132,
              21.02413527198447
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_G1_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DODV.13703",
          "diaChiLapD": "Ngách 318/100, La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.024179694666298,
          "to_latitude": 21.02413527198447,
          "to_longitude": 105.82440289249132,
          "from_longitude": 105.82441551183834
        }
      },
      {
        "id": 13625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81981473439987,
            21.021750329703227,
            105.81982939235193,
            21.021765679841668
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81981976485545,
              21.02176462160005
            ],
            [
              105.81981901331903,
              21.02176299580188
            ],
            [
              105.81981473439987,
              21.02175366351599
            ],
            [
              105.81982204356358,
              21.021750329703227
            ],
            [
              105.81982725057445,
              21.021761205645678
            ],
            [
              105.81982939235193,
              21.021765679841668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT34_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DODV.13704",
          "diaChiLapD": "Ngõ 5, Láng Hạ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46.09,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02176462160005,
          "to_latitude": 21.021765679841668,
          "to_longitude": 105.81982939235193,
          "from_longitude": 105.81981976485545
        }
      },
      {
        "id": 13626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83343139814339,
            21.02586848015727,
            105.83344823951785,
            21.025902842057185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344823951785,
              21.025902842057185
            ],
            [
              105.83343139814339,
              21.02586848015727
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_69.3_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13705",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.19,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025902842057185,
          "to_latitude": 21.02586848015727,
          "to_longitude": 105.83343139814339,
          "from_longitude": 105.83344823951785
        }
      },
      {
        "id": 13627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80117006549699,
            21.026527692501624,
            105.80119257616842,
            21.02667323394168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80117006549699,
              21.026527692501624
            ],
            [
              105.80118192915913,
              21.026620417202672
            ],
            [
              105.80119257616842,
              21.02667323394168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_57_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.13706",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 16.29,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.026527692501624,
          "to_latitude": 21.02667323394168,
          "to_longitude": 105.80119257616842,
          "from_longitude": 105.80117006549699
        }
      },
      {
        "id": 13628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81291433265345,
            21.011118895016903,
            105.81308980867101,
            21.011227921735234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81308980867101,
              21.011227921735234
            ],
            [
              105.8129836863079,
              21.01115294597797
            ],
            [
              105.81296291013798,
              21.011141259912755
            ],
            [
              105.81291433265345,
              21.011118895016903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.13707",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 21.95,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011227921735234,
          "to_latitude": 21.011118895016903,
          "to_longitude": 105.81291433265345,
          "from_longitude": 105.81308980867101
        }
      },
      {
        "id": 13629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81302610352046,
            21.011222725738342,
            105.81309428707006,
            21.011320648106295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81302610352046,
              21.011320648106295
            ],
            [
              105.81308811513698,
              21.01122994673731
            ],
            [
              105.81308980867101,
              21.011227921735234
            ],
            [
              105.81309157581019,
              21.011225811477935
            ],
            [
              105.81309403276951,
              21.011223015083438
            ],
            [
              105.81309428707006,
              21.011222725738342
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_B19_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DODV.13708",
          "diaChiLapD": "Ngõ 508, Đường Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12.96,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.011320648106295,
          "to_latitude": 21.011222725738342,
          "to_longitude": 105.81309428707006,
          "from_longitude": 105.81302610352046
        }
      },
      {
        "id": 13630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82911648103932,
            21.015677066144043,
            105.82912182196249,
            21.015684767209393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912182196249,
              21.015684767209393
            ],
            [
              105.8291190748982,
              21.015680806404934
            ],
            [
              105.82911668079083,
              21.015677354202737
            ],
            [
              105.82911648103932,
              21.015677066144043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_134_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DODV.13709",
          "diaChiLapD": "Ngõ 85, Nguyễn Lương Bằng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 12,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.015684767209393,
          "to_latitude": 21.015677066144043,
          "to_longitude": 105.82911648103932,
          "from_longitude": 105.82912182196249
        }
      },
      {
        "id": 13631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83420208993529,
            21.01476647093037,
            105.83421849849749,
            21.014785140517446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83420208993529,
              21.01476647093037
            ],
            [
              105.8342163039148,
              21.014782643926264
            ],
            [
              105.83421849849749,
              21.014785140517446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_184_Đê La Thành",
          "maTaiSan": "04.O16B.DODV.13710",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.01476647093037,
          "to_latitude": 21.014785140517446,
          "to_longitude": 105.83421849849749,
          "from_longitude": 105.83420208993529
        }
      },
      {
        "id": 13632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81084376773242,
            21.025256193329955,
            105.81086625645715,
            21.02528643312951
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81084376773242,
              21.02528643312951
            ],
            [
              105.81086625645715,
              21.025256193329955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.13711",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02528643312951,
          "to_latitude": 21.025256193329955,
          "to_longitude": 105.81086625645715,
          "from_longitude": 105.81084376773242
        }
      },
      {
        "id": 13633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81084376773242,
            21.02528643312951,
            105.8109436629306,
            21.02538528927063
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81084376773242,
              21.02528643312951
            ],
            [
              105.81092427371802,
              21.02534615635832
            ],
            [
              105.8109273219837,
              21.02537401903919
            ],
            [
              105.81093658600645,
              21.025380408555048
            ],
            [
              105.8109436629306,
              21.02538528927063
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.13712",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02528643312951,
          "to_latitude": 21.02538528927063,
          "to_longitude": 105.8109436629306,
          "from_longitude": 105.81084376773242
        }
      },
      {
        "id": 13634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81081435455032,
            21.025273848052557,
            105.81084376773242,
            21.025292135439308
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81081435455032,
              21.025292135439308
            ],
            [
              105.81082680304876,
              21.025273848052557
            ],
            [
              105.81084376773242,
              21.02528643312951
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_05_Đê La Thành",
          "maTaiSan": "04.O9A.DODV.13713",
          "diaChiLapD": "Đê La Thành",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.025292135439308,
          "to_latitude": 21.02528643312951,
          "to_longitude": 105.81084376773242,
          "from_longitude": 105.81081435455032
        }
      },
      {
        "id": 13635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81440846541162,
            21.021304986578546,
            105.81442131284625,
            21.021435658389446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81442131284625,
              21.021304986578546
            ],
            [
              105.814414930033,
              21.021366960476023
            ],
            [
              105.81441705024396,
              21.021370682459885
            ],
            [
              105.81441670938976,
              21.02137593816576
            ],
            [
              105.81441387470059,
              21.021378819485555
            ],
            [
              105.81441267435666,
              21.021391432481053
            ],
            [
              105.81440846541162,
              21.021435658389446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_16b_Thành Công",
          "maTaiSan": "04.O9C.DODV.13714",
          "diaChiLapD": "Thành Công",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.021304986578546,
          "to_latitude": 21.021435658389446,
          "to_longitude": 105.81440846541162,
          "from_longitude": 105.81442131284625
        }
      },
      {
        "id": 13636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80257365005095,
            21.026483839984365,
            105.80280608012063,
            21.0266125412997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80257365005095,
              21.0266125412997
            ],
            [
              105.80280608012063,
              21.026483839984365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_49_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DODV.1647",
          "diaChiLapD": "Ngõ 1194, Đường Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": 115.767,
          "from_latitude": 21.0266125412997,
          "to_latitude": 21.026483839984365,
          "to_longitude": 105.80280608012063,
          "from_longitude": 105.80257365005095
        }
      },
      {
        "id": 13637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80438904658745,
            21.022105501191305,
            105.80441788033413,
            21.02218531468995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80440993945417,
              21.02218531468995
            ],
            [
              105.80439269418797,
              21.022184475658353
            ],
            [
              105.80438904658745,
              21.022105501191305
            ],
            [
              105.80441788033413,
              21.022108943760244
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_151a_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DODV.1842.1",
          "diaChiLapD": "Ngõ 121, Chùa Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02218531468995,
          "to_latitude": 21.022108943760244,
          "to_longitude": 105.80441788033413,
          "from_longitude": 105.80440993945417
        }
      },
      {
        "id": 13638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80620245270796,
            21.019966413604465,
            105.80662457160209,
            21.02074953385805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80662457160209,
              21.019966413604465
            ],
            [
              105.80659620963033,
              21.0200178055443
            ],
            [
              105.8064688046997,
              21.020251903354488
            ],
            [
              105.80647416911518,
              21.020298222128755
            ],
            [
              105.80638967953537,
              21.02043717836599
            ],
            [
              105.8063118954749,
              21.020534823211573
            ],
            [
              105.8062555690854,
              21.020642482839254
            ],
            [
              105.80620245270796,
              21.02074953385805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_171_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1490",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 101,
          "chatLieu": "HDPE",
          "chieuDai": 98.246,
          "from_latitude": 21.019966413604465,
          "to_latitude": 21.02074953385805,
          "to_longitude": 105.80620245270796,
          "from_longitude": 105.80662457160209
        }
      },
      {
        "id": 13639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80672864366228,
            21.020023694560848,
            105.80674118562426,
            21.02004534648252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80672864366228,
              21.02004534648252
            ],
            [
              105.8067366902855,
              21.02003157601405
            ],
            [
              105.80674118562426,
              21.020023694560848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_189_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1388",
          "diaChiLapD": "Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": 2.729,
          "from_latitude": 21.02004534648252,
          "to_latitude": 21.020023694560848,
          "to_longitude": 105.80674118562426,
          "from_longitude": 105.80672864366228
        }
      },
      {
        "id": 13640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.805631669136,
            21.01888966358261,
            105.80563500072797,
            21.018898835082236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80563500072797,
              21.01888966358261
            ],
            [
              105.80563239909428,
              21.018896256084815
            ],
            [
              105.805631669136,
              21.018898835082236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1471",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 35.249,
          "from_latitude": 21.01888966358261,
          "to_latitude": 21.018898835082236,
          "to_longitude": 105.805631669136,
          "from_longitude": 105.80563500072797
        }
      },
      {
        "id": 13641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80576204258239,
            21.018866136704563,
            105.80578249442766,
            21.01890082844805
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80577671625872,
              21.018866136704563
            ],
            [
              105.80578249442766,
              21.018870157621866
            ],
            [
              105.80576204258239,
              21.01890082844805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1471",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 35.249,
          "from_latitude": 21.018866136704563,
          "to_latitude": 21.01890082844805,
          "to_longitude": 105.80576204258239,
          "from_longitude": 105.80577671625872
        }
      },
      {
        "id": 13642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80578249442766,
            21.018759367036438,
            105.80584921437892,
            21.018870157621866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80578249442766,
              21.018870157621866
            ],
            [
              105.80581669258984,
              21.018810380790757
            ],
            [
              105.80584921437892,
              21.018759367036438
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1471",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 35.249,
          "from_latitude": 21.018870157621866,
          "to_latitude": 21.018759367036438,
          "to_longitude": 105.80584921437892,
          "from_longitude": 105.80578249442766
        }
      },
      {
        "id": 13643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80577847111604,
            21.01877783213987,
            105.80583056503461,
            21.018854196271775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80583056503461,
              21.018782087561952
            ],
            [
              105.80582507450025,
              21.01877783213987
            ],
            [
              105.80577847111604,
              21.018854196271775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1471",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 35.249,
          "from_latitude": 21.018782087561952,
          "to_latitude": 21.018854196271775,
          "to_longitude": 105.80577847111604,
          "from_longitude": 105.80583056503461
        }
      },
      {
        "id": 13644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80571979779698,
            21.01849115332715,
            105.80584653217119,
            21.01877783213987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80582507450025,
              21.01877783213987
            ],
            [
              105.80584653217119,
              21.01872619243799
            ],
            [
              105.80571979779698,
              21.018627920471182
            ],
            [
              105.80583479750558,
              21.01849115332715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_131_Ngõ 103, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1471",
          "diaChiLapD": "Ngõ 103, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 35.249,
          "from_latitude": 21.01877783213987,
          "to_latitude": 21.01849115332715,
          "to_longitude": 105.80583479750558,
          "from_longitude": 105.80582507450025
        }
      },
      {
        "id": 13645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80640786798544,
            21.01711141847585,
            105.80642522718196,
            21.01713701099699
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80642522718196,
              21.01711141847585
            ],
            [
              105.80641332991159,
              21.017123820078126
            ],
            [
              105.80640786798544,
              21.01713701099699
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "",
          "maTaiSan": "04.O9A.DODV.1358.1",
          "diaChiLapD": "76 Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": 3.415,
          "from_latitude": 21.01711141847585,
          "to_latitude": 21.01713701099699,
          "to_longitude": 105.80640786798544,
          "from_longitude": 105.80642522718196
        }
      },
      {
        "id": 13646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80823531445333,
            21.02034845482581,
            105.8083072615287,
            21.020375033631932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8083072615287,
              21.02034845482581
            ],
            [
              105.80825753261777,
              21.020375033631932
            ],
            [
              105.80823531445333,
              21.020357931527258
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "",
          "maTaiSan": "04.O9A.DODV.1359.1",
          "diaChiLapD": "64 Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": 8.936,
          "from_latitude": 21.02034845482581,
          "to_latitude": 21.020357931527258,
          "to_longitude": 105.80823531445333,
          "from_longitude": 105.8083072615287
        }
      },
      {
        "id": 13647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80866570077144,
            21.021076709294338,
            105.80871512062384,
            21.02110926779782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80871512062384,
              21.021076709294338
            ],
            [
              105.80866879434909,
              21.02110097266825
            ],
            [
              105.80866570077144,
              21.02110926779782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "",
          "maTaiSan": "04.O9A.DODV.1359.2",
          "diaChiLapD": "60 Nguyễn Chí Thanh",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": 6.487,
          "from_latitude": 21.021076709294338,
          "to_latitude": 21.02110926779782,
          "to_longitude": 105.80866570077144,
          "from_longitude": 105.80871512062384
        }
      },
      {
        "id": 13648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83343990621142,
            21.01710416856178,
            105.83344426480126,
            21.017109489074983
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83344426480126,
              21.017109489074983
            ],
            [
              105.83343990621142,
              21.01710416856178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "",
          "maTaiSan": "04.O16A.DODV.6276.1",
          "diaChiLapD": "62 Thổ Quan",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": 0.743,
          "from_latitude": 21.017109489074983,
          "to_latitude": 21.01710416856178,
          "to_longitude": 105.83343990621142,
          "from_longitude": 105.83344426480126
        }
      },
      {
        "id": 13649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84146337292445,
            21.028323277695627,
            105.84148295567067,
            21.028391733023252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84148295567067,
              21.02839058183969
            ],
            [
              105.84147926325184,
              21.028391733023252
            ],
            [
              105.84146555744067,
              21.02839150029984
            ],
            [
              105.84146689854452,
              21.028323277695627
            ],
            [
              105.84146337292445,
              21.02832328182512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.4.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12052.1",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02839058183969,
          "to_latitude": 21.02832328182512,
          "to_longitude": 105.84146337292445,
          "from_longitude": 105.84148295567067
        }
      },
      {
        "id": 13650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998288248549,
            21.02856338670005,
            105.84014089570196,
            21.02859253368094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84014089570196,
              21.02859253368094
            ],
            [
              105.83998288248549,
              21.02856338670005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.5_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12060.1",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": 16.74,
          "from_latitude": 21.02859253368094,
          "to_latitude": 21.02856338670005,
          "to_longitude": 105.83998288248549,
          "from_longitude": 105.84014089570196
        }
      },
      {
        "id": 13651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012374072054,
            21.028747402142027,
            105.84019136174133,
            21.028762316773594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018677263843,
              21.028760858858256
            ],
            [
              105.84019136174133,
              21.028762316773594
            ],
            [
              105.84019113122827,
              21.028747402142027
            ],
            [
              105.84012374072054,
              21.028747402142027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.8.1_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DODV.12062.1",
          "diaChiLapD": "Ngõ Yên Thế, Nguyễn Thái Học",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": 25.188,
          "from_latitude": 21.028760858858256,
          "to_latitude": 21.028747402142027,
          "to_longitude": 105.84012374072054,
          "from_longitude": 105.84018677263843
        }
      },
      {
        "id": 13652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80450172925725,
            21.01883260150046,
            105.80452219145626,
            21.018870646290047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80450172925725,
              21.018870646290047
            ],
            [
              105.8045131441799,
              21.018849537125845
            ],
            [
              105.80451543379846,
              21.018845251181766
            ],
            [
              105.80451966016443,
              21.018843572974415
            ],
            [
              105.80451891331563,
              21.018838737862506
            ],
            [
              105.80452219145626,
              21.01883260150046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_118_Ngõ 131, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DODV.1475",
          "diaChiLapD": "Ngõ 131, Pháo Đài Láng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": 4.93,
          "from_latitude": 21.018870646290047,
          "to_latitude": 21.01883260150046,
          "to_longitude": 105.80452219145626,
          "from_longitude": 105.80450172925725
        }
      },
      {
        "id": 13653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83676891672745,
            21.028753661078717,
            105.8367967956599,
            21.028757409851426
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83676891672745,
              21.028757409851426
            ],
            [
              105.8367967956599,
              21.028753661078717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_45.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11391.1",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 46,
          "chatLieu": "HDPE",
          "chieuDai": 2.928,
          "from_latitude": 21.028757409851426,
          "to_latitude": 21.028753661078717,
          "to_longitude": 105.8367967956599,
          "from_longitude": 105.83676891672745
        }
      },
      {
        "id": 13654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83936897215226,
            21.028154825331452,
            105.83941151391629,
            21.02828818756693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936897215226,
              21.028154825331452
            ],
            [
              105.83937176701065,
              21.028162248669428
            ],
            [
              105.83937766659494,
              21.02817213060817
            ],
            [
              105.83939560468225,
              21.02822360634013
            ],
            [
              105.83940834517352,
              21.02825615292877
            ],
            [
              105.83941151391629,
              21.02828818756693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.10_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12043",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 15.524,
          "from_latitude": 21.028154825331452,
          "to_latitude": 21.02828818756693,
          "to_longitude": 105.83941151391629,
          "from_longitude": 105.83936897215226
        }
      },
      {
        "id": 13655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83990488900977,
            21.028010488597175,
            105.83993104055772,
            21.02801893820492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83993104055772,
              21.028010488597175
            ],
            [
              105.83990488900977,
              21.02801893820492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_40.1_Ngõ 46, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11480.1",
          "diaChiLapD": "Ngõ 46, Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028010488597175,
          "to_latitude": 21.02801893820492,
          "to_longitude": 105.83990488900977,
          "from_longitude": 105.83993104055772
        }
      },
      {
        "id": 13656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84037943950031,
            21.027848761646595,
            105.84051678230192,
            21.028159109240427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84051678230192,
              21.028159109240427
            ],
            [
              105.84046225110842,
              21.02802741330738
            ],
            [
              105.84038600024033,
              21.02785988040599
            ],
            [
              105.84038338003751,
              21.027855991434713
            ],
            [
              105.84037943950031,
              21.027848761646595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.2_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11475",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 27,
          "chatLieu": "HDPE",
          "chieuDai": 37.223,
          "from_latitude": 21.028159109240427,
          "to_latitude": 21.027848761646595,
          "to_longitude": 105.84037943950031,
          "from_longitude": 105.84051678230192
        }
      },
      {
        "id": 13657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013078112287,
            21.02789737499327,
            105.84029332989182,
            21.027950167770545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84029332989182,
              21.02789737499327
            ],
            [
              105.84027453074737,
              21.02790346018759
            ],
            [
              105.8402366445428,
              21.027915978134086
            ],
            [
              105.84020512858837,
              21.027926618387824
            ],
            [
              105.84018702368164,
              21.02793381620646
            ],
            [
              105.84018217133013,
              21.02794090176683
            ],
            [
              105.84017696539807,
              21.027936945691927
            ],
            [
              105.84017025986975,
              21.027939136332385
            ],
            [
              105.84013078112287,
              21.027950167770545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.4_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11476",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": 18.416,
          "from_latitude": 21.02789737499327,
          "to_latitude": 21.027950167770545,
          "to_longitude": 105.84013078112287,
          "from_longitude": 105.84029332989182
        }
      },
      {
        "id": 13658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84021015772562,
            21.02788156875008,
            105.8402823582727,
            21.027926931336722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84027118995458,
              21.02788156875008
            ],
            [
              105.84027336206732,
              21.02788668918855
            ],
            [
              105.84027692732678,
              21.027898869691608
            ],
            [
              105.8402823582727,
              21.027900926454606
            ],
            [
              105.84027927425632,
              21.027905441614536
            ],
            [
              105.84027960953453,
              21.027906693408646
            ],
            [
              105.84021015772562,
              21.027926931336722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.4B_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11476.1",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 10.914,
          "from_latitude": 21.02788156875008,
          "to_latitude": 21.027926931336722,
          "to_longitude": 105.84021015772562,
          "from_longitude": 105.84027118995458
        }
      },
      {
        "id": 13659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8404647655468,
            21.027818303522483,
            105.84049795788333,
            21.02792460971602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8404817869864,
              21.027818303522483
            ],
            [
              105.8404848890798,
              21.027823086643888
            ],
            [
              105.8404935992935,
              21.027841991253787
            ],
            [
              105.8404647655468,
              21.027851692666506
            ],
            [
              105.84049795788333,
              21.02792460971602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.1C_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11473.1",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027818303522483,
          "to_latitude": 21.02792460971602,
          "to_longitude": 105.84049795788333,
          "from_longitude": 105.8404817869864
        }
      },
      {
        "id": 13660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84048935699961,
            21.027815939039865,
            105.8405045356903,
            21.027833021371745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84048935699961,
              21.027815939039865
            ],
            [
              105.84049183265486,
              21.02782115646072
            ],
            [
              105.8405045356903,
              21.027833021371745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_36.1A_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.11474.1",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 2,
          "chatLieu": "HDPE",
          "chieuDai": 44.477,
          "from_latitude": 21.027815939039865,
          "to_latitude": 21.027833021371745,
          "to_longitude": 105.8405045356903,
          "from_longitude": 105.84048935699961
        }
      },
      {
        "id": 13661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84127581072971,
            21.027671441822026,
            105.84142019678863,
            21.027735909572154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84141182121014,
              21.027671441822026
            ],
            [
              105.84141863682423,
              21.02770259003639
            ],
            [
              105.84142019678863,
              21.02770971325644
            ],
            [
              105.84128153376757,
              21.027727125186274
            ],
            [
              105.84127581072971,
              21.027735909572154
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_34.1_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12027",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 44,
          "chatLieu": "HDPE",
          "chieuDai": 20.009,
          "from_latitude": 21.027671441822026,
          "to_latitude": 21.027735909572154,
          "to_longitude": 105.84127581072971,
          "from_longitude": 105.84141182121014
        }
      },
      {
        "id": 13662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84125703768383,
            21.028543210351117,
            105.84148533449182,
            21.02855533487909
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84148533449182,
              21.028543210351117
            ],
            [
              105.8414825775459,
              21.028543245671532
            ],
            [
              105.84147356238206,
              21.028545238302755
            ],
            [
              105.8414705448961,
              21.02855243609069
            ],
            [
              105.84146417464598,
              21.02854742893343
            ],
            [
              105.8414139436853,
              21.028552394425677
            ],
            [
              105.84136463666047,
              21.028552783819357
            ],
            [
              105.84132402922408,
              21.02855400082693
            ],
            [
              105.84125703768383,
              21.02855533487909
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_38.2_Lê Duẩn",
          "maTaiSan": "04.O14.DODV.12048",
          "diaChiLapD": "Lê Duẩn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": 24.534,
          "from_latitude": 21.028543210351117,
          "to_latitude": 21.02855533487909,
          "to_longitude": 105.84125703768383,
          "from_longitude": 105.84148533449182
        }
      },
      {
        "id": 13663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83692797210435,
            21.029766588488116,
            105.83695595647887,
            21.02983624337055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83695595647887,
              21.02983624337055
            ],
            [
              105.83692797210435,
              21.029766588488116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_42.12_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11354.1",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02983624337055,
          "to_latitude": 21.029766588488116,
          "to_longitude": 105.83692797210435,
          "from_longitude": 105.83695595647887
        }
      },
      {
        "id": 13664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837536162894,
            21.02932822662946,
            105.83775857916713,
            21.029409205138823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837536162894,
              21.029409205138823
            ],
            [
              105.83756870467035,
              21.029398154672194
            ],
            [
              105.83768873468016,
              21.02935308148225
            ],
            [
              105.83775857916713,
              21.02932822662946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_43.10_Thanh Miến",
          "maTaiSan": "04.O14.DODV.11368",
          "diaChiLapD": "Thanh Miến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 31,
          "chatLieu": "HDPE",
          "chieuDai": 24.799,
          "from_latitude": 21.029409205138823,
          "to_latitude": 21.02932822662946,
          "to_longitude": 105.83775857916713,
          "from_longitude": 105.837536162894
        }
      },
      {
        "id": 13665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783020056674,
            21.02844047914805,
            105.83783701691311,
            21.028473128353543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783701691311,
              21.028473128353543
            ],
            [
              105.83783189085347,
              21.02844714565585
            ],
            [
              105.83783020056674,
              21.02844047914805
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_46.6.1_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DODV.12097.1",
          "diaChiLapD": "Nguyễn Khuyến",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028473128353543,
          "to_latitude": 21.02844047914805,
          "to_longitude": 105.83783020056674,
          "from_longitude": 105.83783701691311
        }
      },
      {
        "id": 13666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8394447168275,
            21.027629629841215,
            105.83956810437539,
            21.027754418303136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8394447168275,
              21.027655291677263
            ],
            [
              105.83946038509224,
              21.027650082467417
            ],
            [
              105.83953256510036,
              21.027629629841215
            ],
            [
              105.83956810437539,
              21.02771819445363
            ],
            [
              105.83955880043416,
              21.02773438956669
            ],
            [
              105.83955947099061,
              21.027754418303136
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.12_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11565",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 24.299,
          "from_latitude": 21.027655291677263,
          "to_latitude": 21.027754418303136,
          "to_longitude": 105.83955947099061,
          "from_longitude": 105.8394447168275
        }
      },
      {
        "id": 13667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944267950775,
            21.0276346370293,
            105.83946038509224,
            21.027650082467403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83946038509224,
              21.027650082467403
            ],
            [
              105.83945411049247,
              21.0276346370293
            ],
            [
              105.83944267950775,
              21.027637708448452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.12_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11565.1",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.027650082467403,
          "to_latitude": 21.027637708448452,
          "to_longitude": 105.83944267950775,
          "from_longitude": 105.83946038509224
        }
      },
      {
        "id": 13668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83944665049607,
            21.026925414122843,
            105.83969877814059,
            21.02758455328799
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969877814059,
              21.026925414122843
            ],
            [
              105.83944665049607,
              21.027007407192308
            ],
            [
              105.83960890004907,
              21.027500639919296
            ],
            [
              105.83966436108844,
              21.027572101811955
            ],
            [
              105.8396690582143,
              21.027583368899535
            ],
            [
              105.83966955355095,
              21.02758455328799
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.7_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.11609",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 83,
          "chatLieu": "HDPE",
          "chieuDai": 96.163,
          "from_latitude": 21.026925414122843,
          "to_latitude": 21.02758455328799,
          "to_longitude": 105.83966955355095,
          "from_longitude": 105.83969877814059
        }
      },
      {
        "id": 13669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396910667866,
            21.02755019200689,
            105.83983821599412,
            21.02758699050032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983821599412,
              21.02755019200689
            ],
            [
              105.83978192661654,
              21.027566335842067
            ],
            [
              105.83977839252053,
              21.027563949555653
            ],
            [
              105.8397735447151,
              21.027567587639844
            ],
            [
              105.8396910667866,
              21.02758699050032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_30.4.1_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DODV.12017.1",
          "diaChiLapD": "Ngô Sĩ Liên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 17,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02755019200689,
          "to_latitude": 21.02758699050032,
          "to_longitude": 105.8396910667866,
          "from_longitude": 105.83983821599412
        }
      },
      {
        "id": 13670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632028418259,
            21.028247859808577,
            105.83633671278811,
            21.02826914026791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633671278811,
              21.02826914026791
            ],
            [
              105.83632028418259,
              21.028247859808577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.5_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11404",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": 2.91,
          "from_latitude": 21.02826914026791,
          "to_latitude": 21.028247859808577,
          "to_longitude": 105.83632028418259,
          "from_longitude": 105.83633671278811
        }
      },
      {
        "id": 13671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634274769713,
            21.028291359566936,
            105.83635952271617,
            21.028331127386576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83635952271617,
              21.028331127386576
            ],
            [
              105.83634274769713,
              21.028291359566936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.4_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11402.1",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028331127386576,
          "to_latitude": 21.028291359566936,
          "to_longitude": 105.83634274769713,
          "from_longitude": 105.83635952271617
        }
      },
      {
        "id": 13672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83626960203075,
            21.028142083362397,
            105.83631357864527,
            21.02821030604956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83626960203075,
              21.02814928582062
            ],
            [
              105.83628539744676,
              21.028143543894238
            ],
            [
              105.8362891034884,
              21.028142083362397
            ],
            [
              105.83631357864527,
              21.02821030604956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.5A_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11404.1",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 10.149,
          "from_latitude": 21.02814928582062,
          "to_latitude": 21.02821030604956,
          "to_longitude": 105.83631357864527,
          "from_longitude": 105.83626960203075
        }
      },
      {
        "id": 13673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83624585286836,
            21.027957130875567,
            105.83647317007781,
            21.028142083362397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362891034884,
              21.028142083362397
            ],
            [
              105.83626865164315,
              21.02810140009393
            ],
            [
              105.83624585286836,
              21.028048824776484
            ],
            [
              105.83647317007781,
              21.027957130875567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_44.5A_Văn Miếu",
          "maTaiSan": "04.O14.DODV.11404.2",
          "diaChiLapD": "Văn Miếu",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15.5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.028142083362397,
          "to_latitude": 21.027957130875567,
          "to_longitude": 105.83647317007781,
          "from_longitude": 105.8362891034884
        }
      },
      {
        "id": 13674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632908492355,
            21.027832812007013,
            105.83637367664747,
            21.027848772395824
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83632908492355,
              21.027848772395824
            ],
            [
              105.83637367664747,
              21.027832812007013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.2_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.13043",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 40,
          "chatLieu": "HDPE",
          "chieuDai": 4.961,
          "from_latitude": 21.027848772395824,
          "to_latitude": 21.027832812007013,
          "to_longitude": 105.83637367664747,
          "from_longitude": 105.83632908492355
        }
      },
      {
        "id": 13675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83636707030182,
            21.027835176567976,
            105.83640231000727,
            21.027911043673633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83636707030182,
              21.027835176567976
            ],
            [
              105.83639345793631,
              21.027891959323366
            ],
            [
              105.83640101364821,
              21.027892691338675
            ],
            [
              105.83639681070048,
              21.02789790534868
            ],
            [
              105.83640231000727,
              21.027911043673633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_49.2_Ngô Tất Tố",
          "maTaiSan": "04.O14.DODV.13043.1",
          "diaChiLapD": "Ngô Tất Tố",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": 9.936,
          "from_latitude": 21.027835176567976,
          "to_latitude": 21.027911043673633,
          "to_longitude": 105.83640231000727,
          "from_longitude": 105.83636707030182
        }
      },
      {
        "id": 13676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306207285669,
            21.019840980082996,
            105.83065990134581,
            21.019858337163175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8306207285669,
              21.019858337163175
            ],
            [
              105.83065080307479,
              21.019845248898466
            ],
            [
              105.83065990134581,
              21.019840980082996
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_96.2_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.12201.1",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019858337163175,
          "to_latitude": 21.019840980082996,
          "to_longitude": 105.83065990134581,
          "from_longitude": 105.8306207285669
        }
      },
      {
        "id": 13677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441699158057,
            21.020419171382198,
            105.83454683042193,
            21.020477000430564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83454683042193,
              21.02046042017695
            ],
            [
              105.83443659750019,
              21.020477000430564
            ],
            [
              105.83441699158057,
              21.020419171382198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12726",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 28,
          "chatLieu": "HDPE",
          "chieuDai": 18.325,
          "from_latitude": 21.02046042017695,
          "to_latitude": 21.020419171382198,
          "to_longitude": 105.83441699158057,
          "from_longitude": 105.83454683042193
        }
      },
      {
        "id": 13678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83452401898607,
            21.02036738730257,
            105.834641975625,
            21.020422782008612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83452401898607,
              21.02039686040297
            ],
            [
              105.8345289875767,
              21.02039524114009
            ],
            [
              105.8345360498643,
              21.020399786345685
            ],
            [
              105.83453804002559,
              21.020391798531595
            ],
            [
              105.83462286489258,
              21.02036738730257
            ],
            [
              105.834641975625,
              21.020422782008612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_107.10_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.12724.1",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 24,
          "chatLieu": "HDPE",
          "chieuDai": 26.599,
          "from_latitude": 21.02039686040297,
          "to_latitude": 21.020422782008612,
          "to_longitude": 105.834641975625,
          "from_longitude": 105.83452401898607
        }
      },
      {
        "id": 13679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444240206883,
            21.01969302552433,
            105.83485646835967,
            21.019907328780672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444240206883,
              21.01974107189197
            ],
            [
              105.83444304680972,
              21.019740939568095
            ],
            [
              105.83446360859456,
              21.019736136573123
            ],
            [
              105.83447098467035,
              21.019756479350235
            ],
            [
              105.83449009541174,
              21.019820324355685
            ],
            [
              105.83450015369526,
              21.019856315400805
            ],
            [
              105.83451222363009,
              21.019900130574467
            ],
            [
              105.83452161136618,
              21.019907328780672
            ],
            [
              105.83463694635299,
              21.01988416933464
            ],
            [
              105.83485646835967,
              21.019842623155494
            ],
            [
              105.83482629350911,
              21.01969302552433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_107.4_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DODV.13285",
          "diaChiLapD": "Ngõ Trung Tả, Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 75,
          "chatLieu": "HDPE",
          "chieuDai": 74.783,
          "from_latitude": 21.01974107189197,
          "to_latitude": 21.01969302552433,
          "to_longitude": 105.83482629350911,
          "from_longitude": 105.83444240206883
        }
      },
      {
        "id": 13680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83126110213767,
            21.021190566544902,
            105.83128466120257,
            21.02120039495954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83128466120257,
              21.021190566544902
            ],
            [
              105.83127696864625,
              21.021194272135233
            ],
            [
              105.83126110213767,
              21.02120039495954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_95.1_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DODV.13700",
          "diaChiLapD": "Tôn Đức Thắng",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10.49,
          "chatLieu": "HDPE",
          "chieuDai": 2.682,
          "from_latitude": 21.021190566544902,
          "to_latitude": 21.02120039495954,
          "to_longitude": 105.83126110213767,
          "from_longitude": 105.83128466120257
        }
      },
      {
        "id": 13681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8392086910705,
            21.022298913431506,
            105.83947774734152,
            21.022719658344123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8392086910705,
              21.02271829043422
            ],
            [
              105.8392144377492,
              21.022719658344123
            ],
            [
              105.83946131880786,
              21.022698563382868
            ],
            [
              105.83946014270205,
              21.022658924322563
            ],
            [
              105.83947774734152,
              21.02262282713915
            ],
            [
              105.83947472983759,
              21.022298913431506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.25.2_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13187.1",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": 71.036,
          "from_latitude": 21.02271829043422,
          "to_latitude": 21.022298913431506,
          "to_longitude": 105.83947472983759,
          "from_longitude": 105.8392086910705
        }
      },
      {
        "id": 13682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899937437944,
            21.021090344640477,
            105.83918312325753,
            21.021350874993605
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83899937437944,
              21.021090344640477
            ],
            [
              105.83905446975808,
              21.021156608808386
            ],
            [
              105.83909811604109,
              21.021213289336142
            ],
            [
              105.83910802141381,
              21.0212269418184
            ],
            [
              105.83912646159877,
              21.021253543644562
            ],
            [
              105.83914959565178,
              21.021284213980472
            ],
            [
              105.83918312325753,
              21.021350874993605
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.13.1_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13199",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 37,
          "chatLieu": "HDPE",
          "chieuDai": 34.718,
          "from_latitude": 21.021090344640477,
          "to_latitude": 21.021350874993605,
          "to_longitude": 105.83918312325753,
          "from_longitude": 105.83899937437944
        }
      },
      {
        "id": 13683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83910596519735,
            21.021211450165083,
            105.83912009129475,
            21.0212269418184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83911125325474,
              21.02121490549063
            ],
            [
              105.83911389561942,
              21.02121283755713
            ],
            [
              105.83911807964341,
              21.021211450165083
            ],
            [
              105.83912009129475,
              21.02121489275466
            ],
            [
              105.83910596519735,
              21.02122410775467
            ],
            [
              105.83910802141381,
              21.0212269418184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_12.13.1_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13199.1",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 3,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02121490549063,
          "to_latitude": 21.0212269418184,
          "to_longitude": 105.83910802141381,
          "from_longitude": 105.83911125325474
        }
      },
      {
        "id": 13684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83884558359816,
            21.020826896718297,
            105.83900561700483,
            21.02083229673346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83900561700483,
              21.02083229673346
            ],
            [
              105.83900287470973,
              21.020827397718328
            ],
            [
              105.83899578730357,
              21.02083127820815
            ],
            [
              105.83884558359816,
              21.020826896718297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_12.8.1_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DODV.13143.1",
          "diaChiLapD": "Ngõ 88, Trần Quý Cáp",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 7.47,
          "chatLieu": "HDPE",
          "chieuDai": 57.304,
          "from_latitude": 21.02083229673346,
          "to_latitude": 21.020826896718297,
          "to_longitude": 105.83884558359816,
          "from_longitude": 105.83900561700483
        }
      },
      {
        "id": 13685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83640024769068,
            21.020530206993868,
            105.8364356952912,
            21.02059363312785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364356952912,
              21.02059363312785
            ],
            [
              105.83640024769068,
              21.020530206993868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13303.1",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02059363312785,
          "to_latitude": 21.020530206993868,
          "to_longitude": 105.83640024769068,
          "from_longitude": 105.8364356952912
        }
      },
      {
        "id": 13686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634123908442,
            21.02047040540424,
            105.83651979160847,
            21.020555870052892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634123908442,
              21.020555870052892
            ],
            [
              105.83640024769068,
              21.020530206993843
            ],
            [
              105.83651979160847,
              21.02047040540424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13303.2",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 20.841,
          "from_latitude": 21.020555870052892,
          "to_latitude": 21.02047040540424,
          "to_longitude": 105.83651979160847,
          "from_longitude": 105.83634123908442
        }
      },
      {
        "id": 13687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365263114905,
            21.020475918151277,
            105.83662919175,
            21.02052426067468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83662919175,
              21.020475918151277
            ],
            [
              105.8365263114905,
              21.02052426067468
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.5_Văn Chương",
          "maTaiSan": "04.O14.DODV.13303.3",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020475918151277,
          "to_latitude": 21.02052426067468,
          "to_longitude": 105.8365263114905,
          "from_longitude": 105.83662919175
        }
      },
      {
        "id": 13688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83633143246394,
            21.02062808650084,
            105.836334387258,
            21.02063494851221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.836334387258,
              21.02063494851221
            ],
            [
              105.83633143246394,
              21.02062808650084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13306.2",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": 9.902,
          "from_latitude": 21.02063494851221,
          "to_latitude": 21.02062808650084,
          "to_longitude": 105.83633143246394,
          "from_longitude": 105.836334387258
        }
      },
      {
        "id": 13689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8361889401125,
            21.020598354922804,
            105.83640586376919,
            21.020680664435563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8361889401125,
              21.020680664435563
            ],
            [
              105.83633143246394,
              21.02062808650084
            ],
            [
              105.83640586376919,
              21.020598354922804
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13306.1",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": 9.902,
          "from_latitude": 21.020680664435563,
          "to_latitude": 21.020598354922804,
          "to_longitude": 105.83640586376919,
          "from_longitude": 105.8361889401125
        }
      },
      {
        "id": 13690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607561676804,
            21.020622766114016,
            105.83611983302146,
            21.020703243976413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611983302146,
              21.020703243976413
            ],
            [
              105.83611015020843,
              21.0206906792784
            ],
            [
              105.83607561676804,
              21.020622766114016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_119.4_Văn Chương",
          "maTaiSan": "04.O14.DODV.13306.1",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 72,
          "chatLieu": "HDPE",
          "chieuDai": 9.902,
          "from_latitude": 21.020703243976413,
          "to_latitude": 21.020622766114016,
          "to_longitude": 105.83607561676804,
          "from_longitude": 105.83611983302146
        }
      },
      {
        "id": 13691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83548273066494,
            21.020952781030584,
            105.83548762618759,
            21.020964757092198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83548273066494,
              21.020952781030584
            ],
            [
              105.8354838590118,
              21.02095440165397
            ],
            [
              105.83548762618759,
              21.020964757092198
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.5.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.13224.1",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": "",
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020952781030584,
          "to_latitude": 21.020964757092198,
          "to_longitude": 105.83548762618759,
          "from_longitude": 105.83548273066494
        }
      },
      {
        "id": 13692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83772046389709,
            21.020026436396474,
            105.8377613011499,
            21.020088926878284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83772046389709,
              21.020026436396474
            ],
            [
              105.83772642016766,
              21.020034241011007
            ],
            [
              105.8377613011499,
              21.020088926878284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.24.2_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13030.2",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.020026436396474,
          "to_latitude": 21.020088926878284,
          "to_longitude": 105.8377613011499,
          "from_longitude": 105.83772046389709
        }
      },
      {
        "id": 13693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8377757457191,
            21.019994540738193,
            105.83782156696634,
            21.02007852078749
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8377757457191,
              21.019994540738193
            ],
            [
              105.83778102151355,
              21.019999848308625
            ],
            [
              105.83782156696634,
              21.02007852078749
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_9.24.1_Văn Chương 2",
          "maTaiSan": "04.O14.DODV.13030.1",
          "diaChiLapD": "Văn Chương 2",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 5,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.019994540738193,
          "to_latitude": 21.02007852078749,
          "to_longitude": 105.83782156696634,
          "from_longitude": 105.8377757457191
        }
      },
      {
        "id": 13694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440359463874,
            21.02319001588366,
            105.83446645776246,
            21.023260716012416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83446645776246,
              21.02319001588366
            ],
            [
              105.83445750896493,
              21.02319043010652
            ],
            [
              105.83445301880839,
              21.023231182495525
            ],
            [
              105.83440359463874,
              21.023260716012416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_80.6.1_Văn Chương",
          "maTaiSan": "04.O14.DODV.12696.1",
          "diaChiLapD": "Văn Chương",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 10,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.02319001588366,
          "to_latitude": 21.023260716012416,
          "to_longitude": 105.83440359463874,
          "from_longitude": 105.83446645776246
        }
      },
      {
        "id": 13695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82111760726433,
            21.00719319081305,
            105.8211959540932,
            21.007249377226472
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8211959540932,
              21.007249377226472
            ],
            [
              105.82116823389728,
              21.00722918490521
            ],
            [
              105.82113738850828,
              21.00720696246602
            ],
            [
              105.82111760726433,
              21.00719319081305
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_KT96_phố Vĩnh Hồ",
          "maTaiSan": "04.O11.DODV.11245",
          "diaChiLapD": "phố Vĩnh Hồ",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4.2,
          "chatLieu": "HDPE",
          "chieuDai": 10.249,
          "from_latitude": 21.007249377226472,
          "to_latitude": 21.00719319081305,
          "to_longitude": 105.82111760726433,
          "from_longitude": 105.8211959540932
        }
      },
      {
        "id": 13696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81952504599082,
            21.004384845737718,
            105.8196626436832,
            21.004564659646483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81952504599082,
              21.00455808669571
            ],
            [
              105.81962127023624,
              21.004564659646483
            ],
            [
              105.81962931685946,
              21.004481089248717
            ],
            [
              105.81965773153311,
              21.004395875563148
            ],
            [
              105.8196626436832,
              21.004384845737718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63.22_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10747.1",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 38,
          "chatLieu": "HDPE",
          "chieuDai": 30.534,
          "from_latitude": 21.00455808669571,
          "to_latitude": 21.004384845737718,
          "to_longitude": 105.8196626436832,
          "from_longitude": 105.81952504599082
        }
      },
      {
        "id": 13697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81962127023624,
            21.004564659646483,
            105.81969771319282,
            21.004571545594843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81962127023624,
              21.004564659646483
            ],
            [
              105.81969771319282,
              21.004571545594843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63.22_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10747.2",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 4,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.004564659646483,
          "to_latitude": 21.004571545594843,
          "to_longitude": 105.81969771319282,
          "from_longitude": 105.81962127023624
        }
      },
      {
        "id": 13698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81950006296485,
            21.004286782699335,
            105.81950219797523,
            21.004294314891222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81950006296485,
              21.004294314891222
            ],
            [
              105.81950115755959,
              21.00429070819342
            ],
            [
              105.81950219797523,
              21.004286782699335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_A63_Thịnh Quang",
          "maTaiSan": "04.O11.DODV.10748",
          "diaChiLapD": "Thịnh Quang",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 0.78,
          "chatLieu": "HDPE",
          "chieuDai": 0.863,
          "from_latitude": 21.004294314891222,
          "to_latitude": 21.004286782699335,
          "to_longitude": 105.81950219797523,
          "from_longitude": 105.81950006296485
        }
      },
      {
        "id": 13699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363374680554,
            21.01904661257648,
            105.83654833502817,
            21.019058764014698
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363374680554,
              21.019058764014698
            ],
            [
              105.83654833502817,
              21.01904661257648
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_KT1.13_Khâm Thiên",
          "maTaiSan": "04.O16A.DODV.5177",
          "diaChiLapD": "Khâm Thiên",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 41,
          "chatLieu": "HDPE",
          "chieuDai": 21.962,
          "from_latitude": 21.019058764014698,
          "to_latitude": 21.01904661257648,
          "to_longitude": 105.83654833502817,
          "from_longitude": 105.8363374680554
        }
      },
      {
        "id": 13700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82314229278175,
            21.00903600957639,
            105.82314866702664,
            21.009045608951904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82314866702664,
              21.009045608951904
            ],
            [
              105.82314588208196,
              21.00904030241477
            ],
            [
              105.82314588208196,
              21.00904030241477
            ],
            [
              105.82314229278175,
              21.00903600957639
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_7.4_Tây Sơn",
          "maTaiSan": "04.O11.DODV.10373",
          "diaChiLapD": "Tây Sơn",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 1,
          "chatLieu": "HDPE",
          "chieuDai": 1.95,
          "from_latitude": 21.009045608951904,
          "to_latitude": 21.00903600957639,
          "to_longitude": 105.82314229278175,
          "from_longitude": 105.82314866702664
        }
      },
      {
        "id": 13701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82227719510564,
            21.009003063883572,
            105.82249969282795,
            21.009234084626375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82227719510564,
              21.009003063883572
            ],
            [
              105.82230272466902,
              21.00902989564553
            ],
            [
              105.82231747682057,
              21.009046484024047
            ],
            [
              105.82246155114225,
              21.009203105826145
            ],
            [
              105.82249969282795,
              21.009234084626375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_62_Thái Hà",
          "maTaiSan": "04.O11.DODV.10388",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 60,
          "chatLieu": "HDPE",
          "chieuDai": 34.534,
          "from_latitude": 21.009003063883572,
          "to_latitude": 21.009234084626375,
          "to_longitude": 105.82249969282795,
          "from_longitude": 105.82227719510564
        }
      },
      {
        "id": 13702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82202980980219,
            21.00880219648959,
            105.82213678562199,
            21.008876061820516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82211881026393,
              21.00885929106655
            ],
            [
              105.82213027628529,
              21.00884721913574
            ],
            [
              105.82213678562199,
              21.00885287169824
            ],
            [
              105.82213369597535,
              21.008856375792266
            ],
            [
              105.82212534727672,
              21.00886543033535
            ],
            [
              105.82211161717424,
              21.008876061820516
            ],
            [
              105.82202980980219,
              21.00880219648959
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_65A_Thái Hà",
          "maTaiSan": "04.O11.DODV.10395",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 18.192,
          "from_latitude": 21.00885929106655,
          "to_latitude": 21.00880219648959,
          "to_longitude": 105.82202980980219,
          "from_longitude": 105.82211881026393
        }
      },
      {
        "id": 13703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8220582271159,
            21.008876061820505,
            105.82218486020672,
            21.008999194923376
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82211161717424,
              21.008876061820505
            ],
            [
              105.82218486020672,
              21.008946591944987
            ],
            [
              105.82212885333352,
              21.008999194923376
            ],
            [
              105.8220582271159,
              21.00893459587005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_65A.4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10395.1",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 18.192,
          "from_latitude": 21.008876061820505,
          "to_latitude": 21.00893459587005,
          "to_longitude": 105.8220582271159,
          "from_longitude": 105.82211161717424
        }
      },
      {
        "id": 13704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82181432034209,
            21.008967573898946,
            105.82223446556887,
            21.009334705108877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82223446556887,
              21.008967573898946
            ],
            [
              105.8222331960627,
              21.00896866300251
            ],
            [
              105.82181432034209,
              21.009334705108877
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_64A.4_Thái Hà",
          "maTaiSan": "04.O11.DODV.10393",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 150,
          "chatLieu": "HDPE",
          "chieuDai": 59.667,
          "from_latitude": 21.008967573898946,
          "to_latitude": 21.009334705108877,
          "to_longitude": 105.82181432034209,
          "from_longitude": 105.82223446556887
        }
      },
      {
        "id": 13705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82148255937423,
            21.008589857861793,
            105.82171515086334,
            21.00884221081381
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82148255937423,
              21.00884221081381
            ],
            [
              105.82149738528167,
              21.00882536903513
            ],
            [
              105.82151008583259,
              21.008810904858016
            ],
            [
              105.82151378690754,
              21.008810674732686
            ],
            [
              105.82151351442027,
              21.008806935782978
            ],
            [
              105.82151635324783,
              21.008803647878814
            ],
            [
              105.82151929426772,
              21.008802686195335
            ],
            [
              105.82151987348442,
              21.0088011334607
            ],
            [
              105.82152083282298,
              21.008798558243974
            ],
            [
              105.82152913618212,
              21.008789298012566
            ],
            [
              105.82171515086334,
              21.008589857861793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_70_Thái Hà",
          "maTaiSan": "04.O11.DODV.10404",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "chieuDai": 37.266,
          "from_latitude": 21.00884221081381,
          "to_latitude": 21.008589857861793,
          "to_longitude": 105.82171515086334,
          "from_longitude": 105.82148255937423
        }
      },
      {
        "id": 13706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8209690430472,
            21.009713617862726,
            105.82114765934344,
            21.00980931066382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82114765934344,
              21.009713617862726
            ],
            [
              105.82105520900909,
              21.009806806770072
            ],
            [
              105.82100759979922,
              21.00980931066382
            ],
            [
              105.8209690430472,
              21.009777699007866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76B_Thái Hà",
          "maTaiSan": "04.O11.DODV.10498.1",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 95,
          "chatLieu": "HDPE",
          "chieuDai": 92.831,
          "from_latitude": 21.009713617862726,
          "to_latitude": 21.009777699007866,
          "to_longitude": 105.8209690430472,
          "from_longitude": 105.82114765934344
        }
      },
      {
        "id": 13707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82101229366725,
            21.009802588084327,
            105.82108638969429,
            21.009903832603047
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8210593942607,
              21.009802588084327
            ],
            [
              105.82108638969429,
              21.009827776875333
            ],
            [
              105.82104783294228,
              21.00986721318341
            ],
            [
              105.82101229366725,
              21.009903832603047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76B_Thái Hà",
          "maTaiSan": "04.O11.DODV.10498.2",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 15,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009802588084327,
          "to_latitude": 21.009903832603047,
          "to_longitude": 105.82101229366725,
          "from_longitude": 105.8210593942607
        }
      },
      {
        "id": 13708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82117669337198,
            21.009715274268604,
            105.82128856114552,
            21.009829341808068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82117669337198,
              21.009715274268604
            ],
            [
              105.82128856114552,
              21.009829341808068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_76B_Thái Hà",
          "maTaiSan": "04.O11.DODV.10498.3",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.009715274268604,
          "to_latitude": 21.009829341808068,
          "to_longitude": 105.82128856114552,
          "from_longitude": 105.82117669337198
        }
      },
      {
        "id": 13709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82152270490434,
            21.009510331895104,
            105.8217872377709,
            21.009788264342827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82152270490434,
              21.009510331895104
            ],
            [
              105.82167113273951,
              21.0096602278774
            ],
            [
              105.8217872377709,
              21.009788264342827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Thái Hà",
          "maTaiSan": "04.O11.DODV.10497.1",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": 31.223,
          "from_latitude": 21.009510331895104,
          "to_latitude": 21.009788264342827,
          "to_longitude": 105.8217872377709,
          "from_longitude": 105.82152270490434
        }
      },
      {
        "id": 13710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8216448863338,
            21.009616251194814,
            105.8216604757326,
            21.009633721871072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8216604757326,
              21.009616251194814
            ],
            [
              105.8216553532833,
              21.009622457514393
            ],
            [
              105.8216448863338,
              21.009633721871072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_76_Thái Hà",
          "maTaiSan": "04.O11.DODV.10497.2",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 36,
          "chatLieu": "HDPE",
          "chieuDai": 31.223,
          "from_latitude": 21.009616251194814,
          "to_latitude": 21.009633721871072,
          "to_longitude": 105.8216448863338,
          "from_longitude": 105.8216604757326
        }
      },
      {
        "id": 13711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82202796597413,
            21.01066618944927,
            105.8221966933562,
            21.010827689548318
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82202796597413,
              21.010827689548318
            ],
            [
              105.82217347580251,
              21.01066618944927
            ],
            [
              105.82218755741562,
              21.01067776988846
            ],
            [
              105.8221966933562,
              21.0106854819871
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_83_Thái Hà",
          "maTaiSan": "04.O11.DODV.10502",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 26.645,
          "from_latitude": 21.010827689548318,
          "to_latitude": 21.0106854819871,
          "to_longitude": 105.8221966933562,
          "from_longitude": 105.82202796597413
        }
      },
      {
        "id": 13712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82174611660682,
            21.010869519618748,
            105.82199599910389,
            21.011137644093044
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82199599910389,
              21.010869519618748
            ],
            [
              105.82186757024581,
              21.011008226602975
            ],
            [
              105.82174611660682,
              21.011137644093044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D50_103_Thái Hà",
          "maTaiSan": "04.O11.DODV.10507",
          "diaChiLapD": "Thái Hà",
          "maTuyen": "",
          "duongKinh": 50,
          "chieudaiQL": 58,
          "chatLieu": "HDPE",
          "chieuDai": 39.448,
          "from_latitude": 21.010869519618748,
          "to_latitude": 21.011137644093044,
          "to_longitude": 105.82174611660682,
          "from_longitude": 105.82199599910389
        }
      },
      {
        "id": 13713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82070840279636,
            21.010225822034972,
            105.8208318602676,
            21.010341389065783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8208318602676,
              21.010225822034972
            ],
            [
              105.8207358954392,
              21.01031165543615
            ],
            [
              105.82070840279636,
              21.010341389065783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10517.1",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010225822034972,
          "to_latitude": 21.010341389065783,
          "to_longitude": 105.82070840279636,
          "from_longitude": 105.8208318602676
        }
      },
      {
        "id": 13714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8207342190571,
            21.010274132395544,
            105.82086822425222,
            21.010394283611966
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82086822425222,
              21.010274132395544
            ],
            [
              105.82079423348003,
              21.010330434571294
            ],
            [
              105.8207342190571,
              21.010394283611966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_99_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DODV.10517.2",
          "diaChiLapD": "Ngõ 41, Thái Hà",
          "maTuyen": "",
          "duongKinh": 63,
          "chieudaiQL": 20,
          "chatLieu": "HDPE",
          "chieuDai": "",
          "from_latitude": 21.010274132395544,
          "to_latitude": 21.010394283611966,
          "to_longitude": 105.8207342190571,
          "from_longitude": 105.82086822425222
        }
      }
    ]
  }